import styled from 'styled-components';
import { Modal as BootstrapModal } from 'react-bootstrap';

const Modal = styled(BootstrapModal)`
  .modal-dialog {
    max-width: 800px !important;

    .modal-header {
      margin: 0 22px;
      padding: 16px 0;
    }

    .modal-body {
      max-height: calc(100vh - (1.75rem * 2) - 160px) !important;
      min-height: calc(65vh - (1.75rem * 2) - 160px) !important;
      margin-right: 20px;
      padding: 37px 8px 10px 17px !important;

      overflow-y: auto;
      ::-webkit-scrollbar {
        width: 9px;
      }

      overflow-x: hidden;
      ::-webkit-scrollbar {
        width: 9px;
      }

      ::-webkit-scrollbar-track {
        background: var(--sti-ck-colors-gray-100);
        border-radius: 5px;
      }

      ::-webkit-scrollbar-thumb {
        background: var(--white);
        border: 1px solid var(--sti-ck-colors-gray-50);
        border-radius: 5px;
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
      }

      ::-webkit-scrollbar-thumb:hover {
        background: var(--sti-ck-colors-gray-50);
      }
    }

    .modal-footer {
      margin: 0 22px;
      padding: 16px 0;
    }
  }

  div[class^='col'] .form-group {
    padding-left: 13px;
    padding-right: 13px;
  }

  div.form-row .form-group,
  div.row .form-group {
    margin-bottom: 27px;
  }
`;

export default Modal;
