import { Box, Flex, Icon, IconButton, Text } from '@chakra-ui/react';
import { FiMinus, FiPlus } from 'react-icons/fi';

import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';

import { NumberInput } from 'components/update/Input/NumberInput';
import { ItensConsignados } from 'pages/PDV/ProdutosConsignados/types/validationForms';

type InputAcrescimoDecrescimoConsignacao = {
  produto: ItensConsignados;
  quantidade: number[];
  index: number;
  handleDecrementarQuantidade(index: number): void;
  handleIncrementarQuantidade(index: number): void;
  hasVolumeUnitario: number;
};

export function InputQuantidadeAcrescimoDecrescimo({
  produto,
  quantidade,
  index,
  handleDecrementarQuantidade,
  handleIncrementarQuantidade,
  hasVolumeUnitario,
}: InputAcrescimoDecrescimoConsignacao) {
  const {
    casasDecimais: { casasDecimaisQuantidade },
  } = usePadronizacaoContext();

  return (
    <Box>
      <Flex justifyContent="right" alignItems="right" w="100%">
        <IconButton
          aria-label="Decrementar"
          icon={<Icon as={FiMinus} />}
          variant="outline"
          borderColor="gray.300"
          bg="white"
          _focus={{
            bg: 'white',
          }}
          _hover={{
            bg: 'white',
          }}
          _active={{
            bg: 'white',
          }}
          borderRadius="md"
          w="32px"
          onClick={() => handleDecrementarQuantidade(index)}
        />

        <Box>
          <NumberInput
            id="quantidade"
            name={`quantidade.${index}`}
            ml="2px"
            mr="2px"
            scale={hasVolumeUnitario ? 0 : casasDecimaisQuantidade}
            color="gray.600"
            max={produto.quantidadeConsignada}
            fontSize={{
              base: 'sm',
              md: 'md',
            }}
            w="80px"
          />
        </Box>

        <IconButton
          aria-label="Incrementar"
          icon={<Icon as={FiPlus} />}
          variant="outline"
          borderColor="gray.300"
          bg="white"
          className="teste"
          _focus={{
            bg: 'white',
          }}
          _hover={{
            bg: 'white',
          }}
          _active={{
            bg: 'white',
          }}
          borderRadius="md"
          w="32px"
          isDisabled={
            quantidade[index]
              ? produto.quantidadeConsignada === quantidade[index]
              : false
          }
          minW={{
            base: '1px',
            sm: 9,
          }}
          onClick={() => handleIncrementarQuantidade(index)}
        />
      </Flex>

      {quantidade[index] === 0 && (
        <Flex justifyContent="center" alignItems="center">
          <Text fontSize="10px" color="red">
            A quantidade não pode ser 0
          </Text>
        </Flex>
      )}
    </Box>
  );
}
