import { useState } from 'react';
import { Box, Button, Flex } from '@chakra-ui/react';
import { Container as ContainerDraggable } from 'react-smooth-dnd';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import style from './style.module.css';
import { useOrdenacaoMenu } from './hooks';
import CategoriaItem from '../../components/CategoriaItem';

type OrdenacaoMenuProps = {
  permiteAlterarTabRota: boolean;
  handleSetPermiteAlterarTabRota: (value: boolean) => void;
};

const OrdenacaoMenu = ({
  permiteAlterarTabRota,
  handleSetPermiteAlterarTabRota,
}: OrdenacaoMenuProps) => {
  const {
    isLoading,
    categorias,
    handleAtualizarOrdenacaoMenu,
    ordernarLista,
  } = useOrdenacaoMenu(permiteAlterarTabRota, handleSetPermiteAlterarTabRota);

  const [isDragging, setIsDragging] = useState(false);

  return (
    <>
      <Flex py="10px" justifyContent="flex-end">
        <Button
          variant="solid"
          width={{ base: 'max', md: '200px' }}
          color="black"
          colorScheme="secondary.400"
          borderRadius="full"
          onClick={handleAtualizarOrdenacaoMenu}
          isDisabled={isLoading}
        >
          Salvar
        </Button>
      </Flex>
      <Box position="relative">
        {isLoading && <LoadingPadrao />}
        {categorias.length > 0 ? (
          <ContainerDraggable
            dragBeginDelay={50}
            getChildPayload={(i) => i}
            animationDuration={200}
            dragClass={style.draggableGhost}
            style={{
              display: 'flex',
              width: 'full',
              minWidth: 'max',
              flexDirection: 'column',
              borderWidth: '1px',
              backgroundColor: 'white',
              borderColor: 'gray.200',
              borderRadius: '4px',
              overflow: 'hidden',
            }}
            onDrop={(result) => ordernarLista(result)}
            onDragStart={() => setIsDragging(true)}
            onDragEnd={() => setIsDragging(false)}
          >
            {categorias?.map(({ id, nome }, index) => {
              const ultimoItem = categorias.length === index + 1;

              return (
                <CategoriaItem
                  key={id}
                  nome={nome}
                  isDragging={isDragging}
                  ultimoItem={ultimoItem}
                />
              );
            })}
          </ContainerDraggable>
        ) : (
          <Box
            w="full"
            borderWidth="1px"
            backgroundColor="white"
            borderRadius="4px"
            py={3.5}
            px={8}
          >
            Nenhum resultado foi encontrado.
          </Box>
        )}
      </Box>
    </>
  );
};

export { OrdenacaoMenu };
