import React, { useState } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  useMediaQuery,
  useDisclosure,
  ModalHeader,
  Text,
  ModalFooter,
  HStack,
  Button,
  GridItem,
  Flex,
  ModalCloseButton,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { NumberInput } from 'components/update/Input/NumberInput';
import { SimpleCard } from 'components/update/Form/SimpleCard';

import { yupResolver, FormData } from './validationForm';

type ProdutoResponse = {
  quantidade: number;
  valorUnitarioEntrada: number;
  valorIpi: number;
  valorIcmsSt: number;
  valorFcpSt: number;
  custoAdicional: number;
  valorTotal: number;
};

type ModalEditarProdutoResponse = {
  produto: ProdutoResponse;
};

type ModalEditarProdutoProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalEditarProdutoResponse> & {
    nomeProduto: string;
    corDescricao?: string;
    tamanhoDescricao?: string;
    defaultValues: FormData;
    casasDecimaisQuantidade: number;
    casasDecimaisValor: number;
    volumeUnitario?: boolean;
    entradaRateiaIcmsSt: boolean;
  };

export const ModalEditarProduto = create<
  ModalEditarProdutoProps,
  ModalEditarProdutoResponse
>(
  ({
    onResolve,
    onReject,
    defaultValues,
    casasDecimaisQuantidade,
    casasDecimaisValor,
    volumeUnitario = false,
    nomeProduto,
    corDescricao,
    tamanhoDescricao,
    entradaRateiaIcmsSt,
    ...rest
  }) => {
    const formMethods = useForm<FormData>({
      resolver: yupResolver,
      defaultValues,
    });
    const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = formMethods.handleSubmit(async (data) => {
      setIsLoading(true);

      const produtoResponse: ProdutoResponse = {
        quantidade: data.quantidade,
        valorUnitarioEntrada: data.valorUnitario || 0,
        valorIpi: data.ipi || 0,
        valorIcmsSt: entradaRateiaIcmsSt ? 0 : data.icmsSt || 0,
        valorFcpSt: data.fcpSt || 0,
        custoAdicional: data.custoAdicional || 0,
        valorTotal: data.quantidade * data.valorUnitario || 0,
      };

      onResolve({ produto: produtoResponse });
    });

    function handleCancelar() {
      onReject();
    }

    return (
      <ModalPadraoChakra
        isCentered
        size={!isSmallerThan900 ? 'xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalContent
          marginBottom={{ base: 0, md: '3.75rem' }}
          marginTop={{ base: 0, md: '3.75rem' }}
          h="unset"
          maxW={{ base: '100%', md: '1200px' }}
        >
          {isLoading && <LoadingPadrao />}
          <ModalHeader
            mt={isSmallerThan900 ? 12 : undefined}
            mb={isSmallerThan900 ? 8 : undefined}
            px="0"
            mx={{ base: 6, md: 8 }}
            color="primary.50"
          >
            Editar produto
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p={{ base: 6, md: 8 }} py="0px !important">
            <FormProvider {...formMethods}>
              <SimpleCard
                as={HStack}
                justifyContent="space-between"
                boxShadow="none"
                bg="primary.500"
              >
                <Flex flexDir="column" w="50%">
                  <Text color="white" fontSize="2xs">
                    Produto:
                  </Text>
                  <Text
                    color="secondary.300"
                    fontSize="sm"
                    fontWeight="semibold"
                  >
                    {nomeProduto}
                  </Text>
                </Flex>
                {corDescricao && (
                  <Flex flexDir="column" w="20%">
                    <Text color="white" fontSize="2xs">
                      Cor:
                    </Text>
                    <Text
                      color="secondary.300"
                      fontSize="sm"
                      fontWeight="semibold"
                    >
                      {corDescricao}
                    </Text>
                  </Flex>
                )}
                {tamanhoDescricao && (
                  <Flex flexDir="column" w="15%">
                    <Text color="white" fontSize="2xs">
                      Tamanho:
                    </Text>
                    <Text
                      color="secondary.300"
                      fontSize="sm"
                      fontWeight="semibold"
                    >
                      {tamanhoDescricao}
                    </Text>
                  </Flex>
                )}
              </SimpleCard>
              <SimpleCard boxShadow="none">
                <SimpleGridForm>
                  <NumberInput
                    id="quantidade"
                    name="quantidade"
                    label="Quantidade"
                    placeholder={
                      volumeUnitario
                        ? '0'
                        : `0,${'0'.repeat(casasDecimaisQuantidade)}`
                    }
                    scale={volumeUnitario ? 0 : casasDecimaisQuantidade}
                    colSpan={3}
                  />

                  <NumberInput
                    id="valorUnitario"
                    name="valorUnitario"
                    label="Valor unitário"
                    leftElement="R$"
                    placeholder={`0,${'0'.repeat(casasDecimaisValor)}`}
                    scale={casasDecimaisValor}
                    colSpan={3}
                  />
                  <GridItem colSpan={6} />

                  <NumberInput
                    id="ipi"
                    name="ipi"
                    label="IPI"
                    leftElement="R$"
                    placeholder={`0,${'0'.repeat(casasDecimaisValor)}`}
                    scale={casasDecimaisValor}
                    colSpan={3}
                  />
                  <NumberInput
                    id="icmsSt"
                    name="icmsSt"
                    label="ICMS ST"
                    leftElement="R$"
                    helperText="Informe o valor do ICMS ST somente para este produto. Se preferir, é possível informar o valor total contido na nota fiscal de uma só vez. Para isso ignore este campo e preencha o valor completo na próxima etapa: “valores”"
                    placeholder={`0,${'0'.repeat(casasDecimaisValor)}`}
                    scale={casasDecimaisValor}
                    colSpan={3}
                    isDisabled={entradaRateiaIcmsSt}
                  />
                  <NumberInput
                    id="fcpSt"
                    name="fcpSt"
                    label="FCP ST"
                    leftElement="R$"
                    placeholder={`0,${'0'.repeat(casasDecimaisValor)}`}
                    scale={casasDecimaisValor}
                    colSpan={3}
                  />

                  <NumberInput
                    id="custoAdicional"
                    name="custoAdicional"
                    label="Custo adicional"
                    leftElement="R$"
                    helperText="Os valores deste campo não serão somados ao valor total da entrada, servindo apenas para compor o custo do produto."
                    placeholder={`0,${'0'.repeat(casasDecimaisValor)}`}
                    scale={casasDecimaisValor}
                    colSpan={3}
                  />
                </SimpleGridForm>
              </SimpleCard>
            </FormProvider>
          </ModalBody>
          <ModalFooter
            justifyContent="flex-end"
            borderTop="1px"
            borderColor="gray.100"
            mx={{ base: 6, md: 8 }}
            px="0"
          >
            <HStack spacing={6}>
              <Button
                borderRadius="md"
                color="gray.400"
                variant="outline"
                minW="96px"
                onClick={handleCancelar}
              >
                Cancelar
              </Button>
              <Button
                colorScheme="aquamarine"
                borderRadius="md"
                minW="100px"
                onClick={handleSubmit}
              >
                Confirmar
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
