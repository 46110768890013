import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';

import ProdutosFormularioProvider, {
  ProdutoRefProps,
  ProdutosFormularioContext,
} from 'store/Produtos/ProdutosFormulario';
import ConstanteRotas from 'constants/rotas';
import TipoProdutoEnum from 'constants/enum/tipoProduto';

import { ModalDesistencia } from 'components/Modal/ModalDesistencia';
import { ContainerListagem } from 'components/Layout/Listagem/containerListagem';

import { AlterarItem } from './AlterarItem';
import { useForm, yupResolver, defaultValues } from '../validationForm';

type TParams = { id: string };

interface AlterarProps extends RouteComponentProps<TParams> {
  cadastroExterno?: boolean;
  alterarSucesso?: () => void;
  cadastroCancelar?: () => void;
}

const Alterar = ({
  match,
  location,
  cadastroExterno = false,
  alterarSucesso,
  cadastroCancelar,
}: AlterarProps) => {
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();

  const isDuplicarProduto = location?.state as { isDuplicar: boolean };

  const formMethods = useForm({
    resolver: yupResolver,
    defaultValues,
    shouldUnregister: true,
  });

  const processRemovedProductRoute = () => {
    if (cadastroCancelar) {
      cadastroCancelar();
    } else {
      history.push(ConstanteRotas.PRODUTO);
    }
  };

  const handleSubmit = async (
    ref: React.RefObject<ProdutoRefProps>,
    campoObrigatorioVazio: boolean
  ) => {
    if (campoObrigatorioVazio) {
      toast.warning(
        'É preciso preencher todos os campos obrigatórios para mudar de etapa'
      );
      return;
    }
    if (ref.current?.onClick) {
      const sucess = await ref.current.onClick();

      if (sucess) {
        if (!cadastroExterno) {
          history.push(ConstanteRotas.PRODUTO);
        } else if (alterarSucesso) {
          alterarSucesso();
        }
      }
    } else if (cadastroExterno && alterarSucesso) {
      alterarSucesso();
    } else {
      history.push(ConstanteRotas.PRODUTO);
    }
  };

  const saveChanges = async (
    ref: React.RefObject<ProdutoRefProps>,
    campoObrigatorioVazio: boolean
  ) => {
    await ModalDesistencia({
      title: 'Salvar as alterações?',
      labelButtonConfirmar: 'Sim, salvar!',
      labelButtonCancelar: 'Sair sem salvar',
      textoMensagem:
        'Existem alterações na página que não foram salvas. Deseja salvar antes de fechar o cadastro?',
      callback: () => {
        handleSubmit(ref, campoObrigatorioVazio);
      },
      callbackCancel: () => {
        processRemovedProductRoute();
      },
    });
  };

  const handleCancelSubmit = (
    ref: React.RefObject<ProdutoRefProps>,
    campoObrigatorioVazio: boolean,
    idProduto: string,
    tipoProduto: number,
    formIsDirty: boolean
  ) => {
    const isProductSimple = tipoProduto === TipoProdutoEnum.PRODUTO_SIMPLES;

    if (!idProduto) {
      processRemovedProductRoute();
    } else if (formIsDirty && isProductSimple) {
      saveChanges(ref, campoObrigatorioVazio);
    } else {
      processRemovedProductRoute();
    }
  };

  return (
    <Box h="100%" minH="99vh" bg="gray.100">
      <FormProvider {...formMethods}>
        <ProdutosFormularioProvider
          action="alterar"
          cadastroExterno={cadastroExterno}
          isDuplicado={isDuplicarProduto?.isDuplicar || undefined}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          produtoId={cadastroExterno ? match?.params?.id : undefined}
        >
          <ProdutosFormularioContext.Consumer>
            {({
              ref,
              campoObrigatorioVazio,
              idProduto,
              tipoProduto,
              formIsDirty,
            }) => (
              <ContainerListagem
                formMethods={formMethods}
                onCancel={() =>
                  handleCancelSubmit(
                    ref,
                    campoObrigatorioVazio,
                    idProduto,
                    tipoProduto,
                    formIsDirty
                  )
                }
                isLoading={isLoading}
                isForm={false}
                maxWidth="full"
                iconSubmit={false}
                labelOnCancel="Sair do cadastro"
                containerPossuiFundo={false}
                onSubmit={() => handleSubmit(ref, campoObrigatorioVazio)}
              >
                <AlterarItem />
              </ContainerListagem>
            )}
          </ProdutosFormularioContext.Consumer>
        </ProdutosFormularioProvider>
      </FormProvider>
    </Box>
  );
};

export default Alterar;
