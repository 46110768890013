import React, { useEffect, useState } from 'react';
import { Menu, MenuProps } from '@chakra-ui/react';

import PortalFullscreen from 'components/PDV/Geral/PortalFullscreen';

interface MenuListasInterface extends Omit<MenuProps, 'children'> {
  MenuButton: () => JSX.Element;
  MenuList: () => JSX.Element;
  isDisabled?: boolean;
  children?: never; // apenas para proibir a passagem da prop
}

const MenuListas = ({
  MenuButton,
  MenuList,
  isDisabled,
  onClose,
  ...rest
}: MenuListasInterface) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isDisabled) {
      setIsOpen(false);
    }
  }, [isDisabled]);

  return (
    <Menu
      isLazy
      modifiers={[
        {
          name: 'eventListeners',
          options: { scroll: false },
        },
      ]}
      onOpen={() => setIsOpen(true)}
      onClose={() => {
        setIsOpen(false);

        if (onClose) onClose();
      }}
      closeOnSelect={false}
      {...rest}
    >
      <MenuButton />
      {isOpen && (
        <PortalFullscreen>
          <MenuList />
        </PortalFullscreen>
      )}
    </Menu>
  );
};

export default MenuListas;
