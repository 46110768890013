import Input from 'components/PDV/Input';
import InputCpfCnpj from 'components/PDV/InputCpfCnpj';
import { InputNumber } from 'components/PDV/InputNumber';
import CheckboxAtivoInativo from 'components/update/Checkbox/CheckboxAtivoInativo';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';

type UncontrolledForm = {
  readonly?: boolean;
};

const UncontrolledForm = ({ readonly = false }: UncontrolledForm) => {
  return (
    <SimpleGridForm>
      <Input
        id="nome"
        name="nome"
        label="Nome"
        placeholder="Informe o nome"
        colSpan={{ base: 12, md: 6 }}
        isDisabled={readonly}
        isRequired
      />
      <InputCpfCnpj
        id="cnpj"
        name="cnpj"
        label="CNPJ"
        placeholder="Informe o CNPJ"
        colSpan={{ base: 12, md: 4 }}
        isDisabled={readonly}
        asCnpj
        isRequired
      />
      <CheckboxAtivoInativo
        id="ativo"
        name="ativo"
        label="Status"
        colSpan={{ base: 12, md: 2 }}
        isDisabled={readonly}
        isRequired={false}
      />
      <InputNumber
        id="codigoSat"
        name="codigoSat"
        label="Código SAT"
        placeholder="Informe o código SAT"
        colSpan={{ base: 12, md: 3 }}
        maxLength={3}
        scale={0}
        textAlign="left"
        isDisabled={readonly}
      />
    </SimpleGridForm>
  );
};

export default UncontrolledForm;
