import { setDateMaxHours, setDateMinHours } from 'helpers/data/setHoursDate';

import { ProdutoSelecionadoProps } from 'pages/PDV/TrocarProdutos/Types/validationForm';

export type ModalTrocarProdutosPdv = {
  clienteNome: string;
  dataEmissao: Date;
  numeroOperacao: number;
  operacaoId: string;
  valorTotalOperacao: number;
  clienteId: string;
};

export type Produto = { id: string; value: string };

export type Options = {
  label: string;
  value: string | undefined;
};

export type OptionsClientes = {
  label: string;
  value: string | undefined;
  endereco?: string;
};

export type FormData = {
  clienteFornecedorId:
    | {
        label?: string;
        value?: string;
      }
    | string;
  produto: Produto | null;
  idVenda: string;
  dataVendaInicio: Date;
  dataVendaFim: Date;
};

export type ProdutoSelect = {
  nome: string;
  id: string;
};

export type SelectClienteProps = {
  id: string;
  nome: string;
  endereco: string;
  codigo: number;
  cpfCnpj: string;
};

export type ListaVendasPaginada = {
  total: number;
  registros: ModalTrocarProdutosPdv[];
};

export type ListaProdutoPaginadoTrocar = {
  total: number;
  numeroVenda: number;
  tabelaPreco: string;
  contaFinanceira: string;
  vendedor: string;
  registros: ProdutoSelecionadoProps[];
};

const dataAtual = new Date();
const backdate = new Date(dataAtual.setDate(dataAtual.getDate() - 30));

export const formDefaultValues = {
  clienteFornecedorId: '',
  produto: null,
  idVenda: '',
  dataVendaInicio: setDateMinHours(backdate),
  dataVendaFim: setDateMaxHours(new Date()),
  quantidadeControlada: [],
};
