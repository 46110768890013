import { setDateMaxHours, setDateMinHours } from 'helpers/data/setHoursDate';

export type FormData = {
  situacao: string;
  lojaOrigem: string;
  lojaDestino: string;
  identificador: string;
  dataEmissaoInicio: Date;
  dataEmissaoFim: Date;
};

export const formDefaultValues = (): FormData => ({
  situacao: '',
  identificador: '',
  lojaOrigem: '',
  lojaDestino: '',
  dataEmissaoInicio: setDateMinHours(new Date()),
  dataEmissaoFim: setDateMaxHours(new Date()),
});
