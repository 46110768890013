import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  ModalContent,
  ModalBody,
  Flex,
  Text,
  ScaleFade,
  ModalProps,
  useMediaQuery,
  HStack,
  Button,
  ModalFooter,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { toast } from 'react-toastify';
import { FormProvider } from 'react-hook-form';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import ManterFoco from 'components/Geral/ManterFoco';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { NumberInput } from 'components/update/Input/NumberInput';
import Textarea from 'components/PDV/Textarea';
import { DateInput } from 'components/update/Input/DateInput';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { useForm, yupResolver, FormData } from './validationForm';

type SelectOptions = {
  value: string;
  label: string;
};

type ListarSelectResponse = {
  id: string;
  nome: string;
};

interface ModalTransferenciaEntreContasResponse {
  sucesso: boolean;
}

type ModalTransferenciaEntreContasProps = InstanceProps<ModalTransferenciaEntreContasResponse> &
  Omit<ModalProps, 'children' | 'isOpen' | 'onClose'> & {
    contaFinanceiraOrigemId: string;
  };

export const ModalTransferenciaEntreContas = create<ModalTransferenciaEntreContasProps>(
  ({ onReject, onResolve, contaFinanceiraOrigemId, ...rest }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [contasFinanceiras, setContasFinanceiras] = useState<SelectOptions[]>(
      []
    );
    const [
      formasPagamentoRecebimento,
      setFormasPagamentoRecebimento,
    ] = useState<SelectOptions[]>([]);

    const formMethods = useForm<FormData>({
      resolver: yupResolver,
      defaultValues: {
        data: new Date(),
        contaFinanceiraDestinoId: '',
        formaPagamentoRecebimentoId: '',
        observacao: '',
        valor: 0,
      },
    });

    const latestProps = useRef({ setValue: formMethods.setValue });

    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    const handleCancelar = () => {
      setIsOpen(false);
      onReject({ sucesso: false });
    };

    const handleSubmit = formMethods.handleSubmit(async (data) => {
      setIsLoading(true);

      const response = await api.post<void, ResponseApi>(
        ConstanteEnderecoWebservice.EXTRATO_TRANSFERIR,
        { ...data, data: data.data.toISOString(), contaFinanceiraOrigemId }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso: string) => toast.warning(aviso));
        }

        if (response.sucesso) {
          setIsLoading(false);
          setIsOpen(false);
          onResolve({ sucesso: true });

          return;
        }
      }

      setIsLoading(false);
    });

    const getContasFinanceirasMenosCaixaAtual = useCallback(async () => {
      const response = await api.get<void, ResponseApi<ListarSelectResponse[]>>(
        ConstanteEnderecoWebservice.CONTA_FINANCEIRA_LISTAR_CONTAS_FINANCEIRAS_MENOS_CAIXAS,
        {
          params: { contaFinanceiraOrigemId },
        }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso: string) => toast.warning(aviso));
        }

        if (response.sucesso && response.dados) {
          return response.dados;
        }
      }
      return [];
    }, [contaFinanceiraOrigemId]);

    const getFormaPagamentoRecebimento = useCallback(async () => {
      const response = await api.get<void, ResponseApi<ListarSelectResponse[]>>(
        ConstanteEnderecoWebservice.FORMA_PAGAMENTO_LISTAR_SELECT_PAGAMENTO
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso: string) => toast.warning(aviso));
        }

        if (response.sucesso && response.dados) {
          return response.dados;
        }
      }
      return [];
    }, []);

    const getContaDestinoAndFormaPagamentoRecebimento = useCallback(async () => {
      setIsLoading(true);
      const [
        responseContaFinanceiraMenosAtual,
        responseFormaPagamentoRecebimento,
      ] = await Promise.all([
        getContasFinanceirasMenosCaixaAtual(),
        getFormaPagamentoRecebimento(),
      ]);

      if (responseContaFinanceiraMenosAtual) {
        setContasFinanceiras(
          responseContaFinanceiraMenosAtual.map((contaFinanceira) => ({
            label: contaFinanceira.nome,
            value: contaFinanceira.id,
          }))
        );

        if (responseContaFinanceiraMenosAtual.length === 1) {
          const {
            id: contaFinanceiraValue,
          } = responseContaFinanceiraMenosAtual[0];

          latestProps.current.setValue(
            'contaFinanceiraDestinoId',
            contaFinanceiraValue
          );
        }
      }
      if (responseFormaPagamentoRecebimento) {
        setFormasPagamentoRecebimento(
          responseFormaPagamentoRecebimento.map(
            (formaPagamentoRecebimento) => ({
              label: formaPagamentoRecebimento.nome,
              value: formaPagamentoRecebimento.id,
            })
          )
        );

        if (responseFormaPagamentoRecebimento.length === 1) {
          const {
            id: formaPagamentoRecebimentoValue,
          } = responseFormaPagamentoRecebimento[0];

          latestProps.current.setValue(
            'formaPagamentoRecebimentoId',
            formaPagamentoRecebimentoValue
          );
        }
      }
      setIsLoading(false);
    }, [getContasFinanceirasMenosCaixaAtual, getFormaPagamentoRecebimento]);

    useEffect(() => {
      latestProps.current = { setValue: formMethods.setValue };
    });

    useEffect(() => {
      getContaDestinoAndFormaPagamentoRecebimento();
    }, [getContaDestinoAndFormaPagamentoRecebimento]);

    return (
      <ModalPadraoChakra
        isCentered
        size={isLargerThan900 ? 'xl' : 'full'}
        autoFocus={false}
        {...rest}
        onClose={handleCancelar}
        isOpen={isOpen}
      >
        <ModalContent
          marginBottom={{ base: 0, sm: '3.75rem' }}
          marginTop={{ base: 0, sm: '3.75rem' }}
          w={{ base: 'full', md: '550px' }}
          bg="gray.50"
        >
          {isLoading && <LoadingPadrao />}

          <ManterFoco
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <ScaleFade initialScale={1.5} in={isOpen}>
              <FormProvider {...formMethods}>
                <ModalBody p={0} h="100%" borderRadius="md">
                  <Flex flexDirection="column" align="stretch" h="100%">
                    <Flex
                      px={{ base: '24px', md: '40px' }}
                      py={{ base: '40px', md: '24px' }}
                      h={{ base: '77px', md: '67px' }}
                    >
                      <Text
                        color="primary.50"
                        fontSize={{ base: '18px', md: '20px' }}
                      >
                        Transferência entre contas
                      </Text>
                    </Flex>

                    <Flex
                      justifyContent="center"
                      bg="primary.50"
                      px={8}
                      py={7}
                      h="88px"
                    >
                      <Flex
                        sx={{
                          '.chakra-form__error-message': {
                            color: 'white',
                          },
                          '[aria-invalid=true]': {
                            borderColor: 'white !important',
                            boxShadow: '0px 1px 0px 0px white !important',
                          },
                        }}
                      >
                        <NumberInput
                          id="valor"
                          name="valor"
                          scale={2}
                          max={999999999}
                          w="250px"
                          leftElement="R$"
                          size="md"
                          variant="flushed"
                          textAlign="right"
                          fontSize="28px"
                          required
                          leftElementColor="secondary.300"
                          autoFocus
                          _placeholder={{
                            color: 'secondary.700',
                          }}
                          color="secondary.300"
                          _focus={{
                            borderColor: 'secondary.300',
                          }}
                        />
                      </Flex>
                    </Flex>

                    <SimpleGridForm p={{ base: 4, sm: 6, md: 8 }}>
                      <DateInput
                        id="data"
                        name="data"
                        label="Data"
                        isRequired={false}
                        colSpan={6}
                        maxDate={new Date()}
                      />
                      <SelectPadrao
                        id="contaFinanceiraDestinoId"
                        name="contaFinanceiraDestinoId"
                        label="Conta de destino"
                        placeholder="Selecionar"
                        options={contasFinanceiras}
                        isLoading={isLoading}
                        colSpan={6}
                        rowStart={2}
                      />
                      <SelectPadrao
                        id="formaPagamentoRecebimentoId"
                        name="formaPagamentoRecebimentoId"
                        label="Forma"
                        placeholder="Selecionar"
                        options={formasPagamentoRecebimento}
                        isLoading={isLoading}
                        colSpan={6}
                        rowStart={2}
                      />
                      <Textarea
                        id="observacao"
                        name="observacao"
                        label="Observação"
                        placeholder="Informe o motivo"
                        h="120px"
                        colSpan={12}
                      />
                    </SimpleGridForm>
                  </Flex>
                </ModalBody>
                <ModalFooter
                  justifyContent="center"
                  p={{ base: 4, sm: 6, md: 8 }}
                  pt="0 !important"
                >
                  <HStack spacing={6}>
                    <Button
                      variant="outline"
                      size="sm"
                      color="gray.400"
                      onClick={handleCancelar}
                      w="96px"
                    >
                      Cancelar
                    </Button>
                    <Button
                      variant="solid"
                      colorScheme="secondary"
                      size="sm"
                      w="96px"
                      onClick={handleSubmit}
                    >
                      Confirmar
                    </Button>
                  </HStack>
                </ModalFooter>
              </FormProvider>
            </ScaleFade>
          </ManterFoco>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
