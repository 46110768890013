export async function getFileBase64(file: File) {
  const response = await new Promise<string | null>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string | null);
    reader.onerror = (error) => reject(error);
  });

  if (response) {
    const result = response.split(',')?.pop();

    if (result) {
      return result;
    }
  }

  return undefined;
}

export function base64ToBytes(base64: string | undefined) {
  if (!base64) {
    return new Uint8Array();
  }
  const binString = atob(base64);
  const bytes = new Uint8Array(binString.length);
  Array.from(binString).forEach((char, i) => {
    bytes[i] = char.charCodeAt(0);
  });
  return bytes;
}
