import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export type FormData = {
  nome: string;
  ativo: boolean;
};

const defaultValues = {
  nome: '',
  ativo: true,
} as FormData;

const schema = yup.object().shape({
  nome: yup.string().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  ativo: yup.boolean().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

const yupResolver = yupResolverInstance(schema);

export { yupResolver, defaultValues };
