import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Icon,
  Flex,
  Text,
  HStack,
  Button,
  GridItem,
  IconButton,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import auth from 'modules/auth';
import api, { ResponseApi } from 'services/api';
import ConstanteFuncionalidades from 'constants/permissoes';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import TipoProdutoEnum from 'constants/enum/tipoProduto';

import { GradeTamanhosIcon } from 'icons';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { NumberInput } from 'components/update/Input/NumberInput';
import { ModalGradeTamanhos } from 'components/update/Modal/ModalGradeTamanhos';
import CreatableSelectVirtualized from 'components/PDV/Select/CreatableSelectVirtualized';
import { PaginationData } from 'components/update/Pagination';
import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import { ProdutoTamanhoProps } from 'pages/EntradaMercadoria/EntradaManual/LancamentoProdutos/ModalAdicionarProduto';
import OptionType from 'types/optionType';

import { ModalCadastrarProduto } from '../../../../../EtapasCompartilhadas/ModalCadastrarProduto';
import { ModalCadastrarCor } from '../../../../../EtapasCompartilhadas/ModalCadastrarCor';
import { ModalCadastrarTamanho } from '../../../../../EtapasCompartilhadas/ModalCadastrarTamanho';
import { yupResolver, FormData, TamanhoQuantidade } from './validationForm';

type ListaIdDescricao = {
  id: string;
  descricao: string;
};

type ProdutoOption = {
  id: string;
  nome: string;
  referencia: string;
  volumeUnitario: boolean;
  tipoProduto: number;
};

type OptionsResponseProps = Pick<ProdutoOption, 'id' | 'nome'>;

type OptionsProps = {
  label: string;
  value: string;
};

export type ProdutoResponse = {
  produtoId: string;
  nomeProduto: string;
  referencia: string;
  corId?: string;
  corDescricao?: string;
  listaTamanhoIdQuantidade: {
    id: string;
    quantidade: number;
    descricaoTamanho: string;
  }[];
  custoAdicional: number;
};

type Product = {
  documentoFiscalItemId: string;
  descricaoProduto: string;
  quantidade: number;
  valorUnitario: number;
  valorTotal: number;
  cfop: string;
  codigoGTINEAN: string;
  ncm: string;
  codigoCest: string;
};

interface FormularioVincularVariacoesProps {
  casasDecimaisQuantidade: number;
  casasDecimaisValor: number;
  quantidadeProdutos: number;
  quantidadeItensEntradaMercadoria: number;
  onSubmit: (produto: ProdutoResponse) => void;
  product?: Product;
}

export function FormularioVincularVariacoes({
  casasDecimaisQuantidade,
  casasDecimaisValor,
  onSubmit,
  quantidadeProdutos,
  quantidadeItensEntradaMercadoria,
  product,
}: FormularioVincularVariacoesProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [totalRegistroColors, setTotalRegistroColors] = useState(0);
  const [totalRegistroSizes, setTotalRegistroSizes] = useState(0);
  const [totalRegistros, setTotalRegistros] = useState(0);

  const listSizesRef = useRef<OptionType<string>[]>([]);

  const { permitido: temPermissaoCadastrarProduto } = auth.possuiPermissao(
    ConstanteFuncionalidades.PRODUTO_CADASTRAR
  );
  const { permitido: temPermissaoCadastrarCor } = auth.possuiPermissao(
    ConstanteFuncionalidades.COR_CADASTRAR
  );
  const { permitido: temPermissaoCadastrarTamanho } = auth.possuiPermissao(
    ConstanteFuncionalidades.TAMANHO_CADASTRAR
  );

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
  });

  const [
    produtoWatch,
    listaTamanhoIdQuantidadeWatch,
    corWatch,
  ] = formMethods.watch(['produto', 'listaTamanhoIdQuantidade', 'cor']);

  const listTamanhoQuantidadeId = listaTamanhoIdQuantidadeWatch || [];
  const produtoTemVariacao =
    produtoWatch?.value.tipoProduto === TipoProdutoEnum.PRODUTO_VARIACAO;

  const produtoVolumeUnitario = produtoWatch?.value.volumeUnitario;
  const produtoId = produtoWatch ? produtoWatch?.value.id : '';

  const hasTamanhoOuCorEscolhida =
    !!listaTamanhoIdQuantidadeWatch?.[0]?.tamanho || corWatch;

  const temGradeLancada = listTamanhoQuantidadeId?.length > 1;
  const podeLancarGrade = !!produtoWatch;

  async function handlePushGradeModal() {
    if (podeLancarGrade) {
      const cor = formMethods.getValues('cor');

      const tamanhos = listSizesRef.current.map((tamanho) => {
        const tamanhoQuantidade = listTamanhoQuantidadeId.find(
          (tamanhoIdQuantidade) =>
            tamanhoIdQuantidade.tamanho?.value === tamanho.value
        );

        return {
          produtoCorTamanhoId: tamanho.value,
          tamanho: tamanho.label,
          padraoSistema: false,
          quantidade: tamanhoQuantidade?.quantidade || 0,
        };
      });

      const addedTamanhos = await ModalGradeTamanhos({
        produtoNome: produtoWatch.label,
        corDescricao: cor?.label,
        casasDecimaisQuantidade,
        volumeUnitario: produtoVolumeUnitario,
        tamanhos,
      });

      const newListaTamanhoIdQuantidade =
        addedTamanhos.length > 0
          ? addedTamanhos.map(
              (tamanho) =>
                ({
                  quantidade: tamanho.quantidade,
                  tamanho: {
                    value: tamanho.produtoCorTamanhoId,
                    label: tamanho.tamanhoDescricao,
                  },
                } as TamanhoQuantidade)
            )
          : undefined;

      formMethods.setValue(
        'listaTamanhoIdQuantidade',
        newListaTamanhoIdQuantidade
      );
    }
  }

  const getProdutoCorOptions = useCallback(
    async (inputValue: string, dataPagination: PaginationData) => {
      setIsLoading(true);

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<ProdutoOption>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.PRODUTO_COR_TAMANHO_LISTAR_SELECT_ENTRADA_MERCADORIA,
          dataPagination
        ),
        {
          params: { nomeSkuCodigoExternoBarrasGtinEan: inputValue },
        }
      );

      if (response?.avisos) {
        response.avisos.map((aviso: string) => toast.warning(aviso));
      }
      setTotalRegistros(response.dados.total || 0);

      if (response && response.sucesso && response.dados) {
        const data = response.dados.registros.map((produto) => ({
          label: produto.nome,
          value: { ...produto, coresOptions: [], tamanhosOptions: [] },
        }));

        setIsLoading(false);
        return data;
      }

      setIsLoading(false);
      return [];
    },
    []
  );

  const getProductSizes = useCallback(async (id: string) => {
    const response = await api.get<void, ResponseApi<ProdutoTamanhoProps[]>>(
      `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${id}/tamanhos`
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response?.sucesso && response?.dados) {
        const newSizes = response.dados.map((size) => ({
          label: size.descricao,
          value: size.id,
        }));

        return newSizes;
      }
    }

    return [];
  }, []);

  const getListColors = useCallback(
    async (inputValue: string, dataPagination: PaginationData) => {
      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<{ id: string; nome: string }>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.COR_SELECT_LISTAR_PAGINADO,
          dataPagination
        ),
        {
          params: { pesquisa: inputValue },
        }
      );

      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        setTotalRegistroColors(response?.dados?.total || 0);
        if (response?.sucesso && response?.dados) {
          const newOptions = response.dados.registros.map((color) => ({
            label: color.nome,
            value: color.id,
          }));

          return newOptions;
        }
      }

      return [];
    },
    []
  );

  const getListSizes = useCallback(
    async (inputValue: string, dataPagination: PaginationData) => {
      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<{ id: string; nome: string }>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.TAMANHO_SELECT_LISTAR_PAGINADO,
          dataPagination
        ),
        {
          params: { pesquisa: inputValue },
        }
      );

      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        setTotalRegistroSizes(response?.dados?.total || 0);
        if (response?.sucesso && response?.dados) {
          const newOptions = response.dados.registros.map((color) => ({
            label: color.nome,
            value: color.id,
          }));

          return newOptions;
        }
      }

      return [];
    },
    []
  );

  async function handleCadastrarProduto(inputValue: string) {
    if (!temPermissaoCadastrarProduto) {
      toast.warning(
        'Você não tem permissão para acessar essa função. Consulte o administrador da conta.'
      );

      return undefined;
    }

    setIsLoading(true);

    try {
      const { produto: newProduto } = await ModalCadastrarProduto({
        inputValue,
        product,
      });

      const response = await api.get<void, ResponseApi<ProdutoOption>>(
        ConstanteEnderecoWebservice.PRODUTO_COR_TAMANHO_OBTER_OPCAO_SELECT_ENTRADA_MERCADORIA,
        {
          params: { nomeSkuCodigoExternoBarrasGtinEan: newProduto.nome },
        }
      );

      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response?.sucesso && response?.dados) {
          const productIsTypeVariation =
            response.dados.tipoProduto === TipoProdutoEnum.PRODUTO_VARIACAO;

          const sizes = productIsTypeVariation
            ? await getProductSizes(response.dados.id)
            : [];
          listSizesRef.current = sizes;

          const productOption = {
            label: response.dados.nome,
            value: response.dados,
          };

          setIsLoading(false);
          return productOption;
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
    return undefined;
  }

  async function handleCadastrarCor(inputValue: string) {
    if (temPermissaoCadastrarCor) {
      const { cor: newCor } = await ModalCadastrarCor({
        inputValue,
        produtoId,
      });

      const newCorOption = {
        value: newCor.corId,
        label: newCor.corDescricao,
      };

      return newCorOption;
    }

    toast.warning(
      'Você não tem permissão para acessar essa função. Consulte o administrador da conta.'
    );

    return undefined;
  }

  async function handleCadastrarTamanho(inputValue: string) {
    if (temPermissaoCadastrarTamanho) {
      const { tamanho: newTamanho } = await ModalCadastrarTamanho({
        inputValue,
        produtoId,
      });

      const newTamanhoOption = {
        label: newTamanho.descricao,
        value: newTamanho.id,
      };

      listSizesRef.current = [...listSizesRef.current, newTamanhoOption];
      return newTamanhoOption;
    }

    toast.warning(
      'Você não tem permissão para acessar essa função. Consulte o administrador da conta.'
    );

    return undefined;
  }

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    const quantidadeEntradaProdutos = formMethods.watch(
      'listaTamanhoIdQuantidade.0.quantidade'
    );

    const listaTamanhoProdutos =
      listaTamanhoIdQuantidadeWatch?.reduce(
        (acc, curr) => acc + curr.quantidade,
        0
      ) || 0;

    // realiza a soma da quantidade total antes de enviar as informações se verdadeiro envia os dados
    let quantidadeTotal = 0;
    if (listaTamanhoProdutos) {
      quantidadeTotal = quantidadeItensEntradaMercadoria + listaTamanhoProdutos;
    } else {
      quantidadeTotal =
        quantidadeItensEntradaMercadoria + quantidadeEntradaProdutos;
    }

    if (
      quantidadeProdutos >= quantidadeEntradaProdutos &&
      quantidadeProdutos >= listaTamanhoProdutos &&
      quantidadeProdutos >= quantidadeTotal
    ) {
      onSubmit({
        produtoId: data.produto?.value.id || '',
        nomeProduto: data.produto?.label || '',
        referencia: data.produto?.value.referencia || '',
        custoAdicional: data.custoAdicional,
        corDescricao: data.cor?.label,
        corId: data.cor?.value,
        listaTamanhoIdQuantidade: (data.listaTamanhoIdQuantidade || []).map(
          (tamanhoQuantidade) => ({
            id: tamanhoQuantidade.tamanho?.value,
            quantidade: tamanhoQuantidade.quantidade,
            descricaoTamanho: tamanhoQuantidade.tamanho?.label,
          })
        ),
      });
      formMethods.reset({
        produto: null,
        cor: null,
        listaTamanhoIdQuantidade: [],
        custoAdicional: 0,
      });
    } else {
      formMethods.setFocus('listaTamanhoIdQuantidade.0.quantidade');
      toast.warning('A quantidade da entrada de produtos está incorreta.');
    }
  });

  const latestProps = useRef({ reset: formMethods.reset });
  useEffect(() => {
    latestProps.current = { reset: formMethods.reset };
  });

  useEffect(() => {
    if (!produtoWatch) {
      latestProps.current.reset();
    }
  }, [produtoWatch]);

  useEffect(() => {
    formMethods.setValue(
      'listaTamanhoIdQuantidade.0.quantidade',
      quantidadeProdutos
    );
  }, [quantidadeProdutos, formMethods]);

  return (
    <FormProvider {...formMethods}>
      <SimpleGridForm>
        <CreatableSelectVirtualized
          id="produto"
          name="produto"
          label="Para vincular, encontre o cadastro do produto:"
          placeholder="Digite o nome do produto existente no sistema ou cadastre um novo"
          helperText="A pesquisa de produto considera os campos Status, Descrição, Código SKU, GTIN/EAN, Código externo e Código de barras interno."
          handleGetOptions={getProdutoCorOptions}
          isLoading={isLoading}
          creatableInputTextPreffix="Cadastrar o produto"
          handleCreateOption={handleCadastrarProduto}
          onChangeSelect={async (
            option: OptionType<ProdutoOption> | undefined | null
          ) => {
            if (!option) {
              return;
            }

            listSizesRef.current = await getProductSizes(option.value.id);
          }}
          required
          asControlledByObject
          autoFocus
          isClearable
          disabled={!(quantidadeItensEntradaMercadoria < quantidadeProdutos)}
          colSpan={8}
          totalRegistros={totalRegistros}
        />
        {produtoWatch && (
          <>
            <NumberInput
              id="quantidade"
              name="listaTamanhoIdQuantidade.0.quantidade"
              errorText={
                (formMethods.formState.errors?.listaTamanhoIdQuantidade ||
                  [])[0]?.quantidade?.message
              }
              label="Quantidade"
              placeholder={
                produtoVolumeUnitario
                  ? '0'
                  : `0,${'0'.repeat(casasDecimaisQuantidade)}`
              }
              scale={
                produtoWatch && produtoVolumeUnitario
                  ? 0
                  : casasDecimaisQuantidade
              }
              isDisabled={!produtoWatch || temGradeLancada}
              colSpan={2}
            />
            <NumberInput
              id="custoAdicional"
              name="custoAdicional"
              label="Custo adicional"
              leftElement="R$"
              placeholder={`0,${'0'.repeat(casasDecimaisValor)}`}
              scale={casasDecimaisValor}
              isDisabled={!produtoWatch}
              colSpan={2}
            />
            {produtoTemVariacao && (
              <>
                <CreatableSelectVirtualized
                  id="cor"
                  name="cor"
                  label="Cor"
                  placeholder="Selecione"
                  creatableInputTextPreffix="Cadastrar a cor"
                  totalRegistros={totalRegistroColors}
                  handleGetOptions={getListColors}
                  handleCreateOption={handleCadastrarCor}
                  rowStart={2}
                  colSpan={4}
                  asControlledByObject
                  isClearable
                />

                <GridItem colSpan={4} rowStart={2}>
                  <HStack alignItems="flex-end" h="full">
                    {temGradeLancada ? (
                      <Flex
                        alignItems="center"
                        justifyContent="center"
                        bg="primary.800"
                        border="1px"
                        borderColor="gray.100"
                        w="full"
                        h="9"
                        borderRadius="md"
                        userSelect="none"
                      >
                        <Text color="white" fontSize="sm">
                          Tamanhos informados na grade
                        </Text>
                      </Flex>
                    ) : (
                      <CreatableSelectVirtualized
                        id="tamanho"
                        name="listaTamanhoIdQuantidade.0.tamanho"
                        label="Tamanho"
                        placeholder="Selecione"
                        creatableInputTextPreffix="Cadastrar o tamanho"
                        totalRegistros={totalRegistroSizes}
                        handleGetOptions={getListSizes}
                        handleCreateOption={handleCadastrarTamanho}
                        rowStart={2}
                        colSpan={4}
                        asControlledByObject
                        isClearable
                      />
                    )}

                    <IconButton
                      aria-label="Selecionar grade de tamanhos"
                      icon={<Icon as={GradeTamanhosIcon} fontSize="xl" />}
                      colorScheme="whiteAlpha"
                      color="gray.800"
                      bg="white"
                      _hover={{ bg: 'gray.100' }}
                      _active={{ bg: 'gray.100' }}
                      border="1px"
                      borderColor="gray.200"
                      borderRadius="md"
                      onClick={handlePushGradeModal}
                      isDisabled={!podeLancarGrade}
                    />
                  </HStack>
                </GridItem>
              </>
            )}
            <GridItem colStart={9} colSpan={4}>
              <Flex
                h="full"
                w="full"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Button
                  colorScheme="primary"
                  borderRadius="md"
                  w="120px"
                  isDisabled={produtoTemVariacao && !hasTamanhoOuCorEscolhida}
                  onClick={handleSubmit}
                >
                  Adicionar
                </Button>
              </Flex>
            </GridItem>
          </>
        )}
      </SimpleGridForm>
    </FormProvider>
  );
}
