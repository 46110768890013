import { useCallback, useState, useEffect, useMemo, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Box,
  Divider,
  Flex,
  GridItem,
  Icon,
  Checkbox,
  Text,
  useMediaQuery,
  IconButton,
  Collapse,
} from '@chakra-ui/react';
import { FiChevronLeft } from 'react-icons/fi';
import { toast } from 'react-toastify';

import auth from 'modules/auth';
import { enumIdentificacaoIntegracao } from 'constants/enum/enumIdentificacaoIntegracao';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';
import { moneyMask } from 'helpers/format/fieldsMasks';
import {
  formatQueryObject,
  formatQueryPagegTable,
} from 'helpers/format/formatQueryParams';
import { shallowEqual } from 'helpers/validation/shallowEqual';
import { EmissaoNotaFiscalProvider } from 'store/NotaFiscal/EmissaoNotaFiscal';
import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';
import { ImprimirPDF } from 'helpers/impressoes/imprimirPDF';
import { setDateMaxHours } from 'helpers/data/setHoursDate';

import { AvisoIcon, CompartilharMenuIcon, EditarMassaIcon } from 'icons';
import { ContainerHeader } from 'pages/Integracoes/LojaAplicativos/Layout/ContainerHeader';
import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import ModalConfirmacao from 'components/PDV/Modal/ModalConfirmacao';
import InputDateRange from 'components/PDV/InputDateRange';
import { SelectMulti } from 'components/Select/SelectMultiCheckbox';
import { BuscaAvancadaButton } from 'components/update/BuscaAvancadaButton';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { PaginationData } from 'components/update/Pagination';
import {
  PagedTable,
  PagedTableForwardRefData,
} from 'components/update/Table/PagedTable';
import { ModalEmissaoNotaFiscal } from 'components/Modal/ModalEmissaoNotaFiscal';
import { EmailModal } from 'components/PDV/CompartilharDocumentosFiscais/EmailModal';
import { TableHeader } from 'components/update/Table/VirtualizedInfiniteTable';
import { ModalEnviarCodigoRastreio } from 'components/Modal/ModalCodigoRastreio';

import { DrawerBuscaAvancada } from './DrawerBuscaAvancada';
import {
  FormData,
  formDefaultValues,
  ListVendasProps,
} from './validationForms';
import { VendaItem } from './components/VendaItem';
import { useVenda } from './hooks';

type ListarSelectSituacaoPedido = {
  id: string;
  nome: string;
};

type SituacaoPedido = {
  label: string;
  value: string;
};

export const VendasTray = () => {
  const [filtros, setFiltros] = useState<FormData>(formDefaultValues);
  const [listSituacaoPedido, setListSituacaoPedido] = useState<
    SituacaoPedido[]
  >([]);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [valorTotalPeriodo, setValorTotalPeriodo] = useState(0);
  const [hasFilters, setHasFilters] = useState(false);
  const [isOpenBuscaAvancada, setIsOpenBuscaAvancada] = useState(false);

  const pagedTableRef = useRef<PagedTableForwardRefData>(null);

  const onReload = () => {
    pagedTableRef.current?.reload();
  };

  const formMethods = useForm<FormData>({
    defaultValues: formDefaultValues,
  });

  const [isLargerThan1350] = useMediaQuery('(min-width: 1350px)');
  const [isLargerThan1000] = useMediaQuery('(min-width: 1000px)');
  const [isLargerThan1134] = useMediaQuery('(min-width: 1134px)');

  const {
    abriModalNotaFiscal,
    pendenciaNotaFiscal,
    handleGerarNotaFiscal,
    produtoEmitirNota,
    handleFecharModalNotaFiscal,
    handleVoltarPainelAdm,
    isMountedRef,
    pageIsLoaded,
    reloadList,
    listVendas,
    setListVendas,
    hasVendaSeleciona,
    vendasSelecionadas,
    amountVendaSelecionada,
    isVendaSemOperacao,
    hasNotaFiscalAutorizada,
    isCancelarMultiplas,
    modalConfirmacaoCancelarIsOpen,
    getSituacaoNotaFiscal,
    setModalConfirmacaoCancelarIsOpen,
    handleHabilitarAcaoEmMassa,
    handleAbrirModalCancelarVenda,
    handleAbrirModalCompartilhar,
    obterQuantidadePendencias,
    cancelarVenda,
    amountPendencia,
    isLoading,
    setIsLoading,
    isAcaoEmMassa,
    handleCancelarMultiplasVendas,
    handleToggleSelecionarTodasVendas,
    selecionarTodasVendas,
    handleToggleSelecionarVendas,
    handleLimparSelecao,
  } = useVenda(onReload);

  const { reset, getValues, setValue } = formMethods;

  const lojaId = auth.getLoja().id;

  function handlePushModalBuscaAvancada() {
    const {
      dataEmissaoInicio,
      dataEmissaoFim,
      integracaoSituacaoPedidoId,
    } = getValues();

    setValue('dataEmissaoFilterInicio', dataEmissaoInicio);
    setValue('dataEmissaoFilterFim', dataEmissaoFim);

    if (integracaoSituacaoPedidoId !== undefined) {
      setValue(
        'integracaoSituacaoPedidoIdFilter',
        integracaoSituacaoPedidoId || undefined
      );
    }

    setIsOpenBuscaAvancada(true);
  }

  function filtersSubmit(data: FormData) {
    setFiltros(data);
    const hasFiltersDirty = !shallowEqual(data, formDefaultValues);

    setHasFilters(hasFiltersDirty);
    reset(data);
  }

  function handleOpenModalCodigoRastreio(vendaItem: ListVendasProps[]) {
    ModalEnviarCodigoRastreio({
      listInfoRastreio: vendaItem,
      reloadList,
    });
  }

  const handleSubmit = formMethods.handleSubmit(filtersSubmit);

  const filterPagination = useCallback(() => {
    const filter = {
      ...filtros,
      integracao: enumIdentificacaoIntegracao.TRAY,
      lojaId,
      dataEmissaoInicio: setDateMaxHours(
        new Date(filtros.dataEmissaoInicio)
      ).toISOString(),
      dataEmissaoFim: setDateMaxHours(
        new Date(filtros.dataEmissaoFim)
      ).toISOString(),
    };

    delete filter.integracaoSituacaoPedidoIdFilter;
    delete filter.dataEmissaoFilterInicio;
    delete filter.dataEmissaoFilterFim;

    return Object.fromEntries(
      Object.entries(filter).filter(([, value]) => value !== undefined)
    ) as FormData;
  }, [filtros, lojaId]);

  const paginationHandle = useCallback(
    async (gridPaginadaConsulta: PaginationData) => {
      setIsLoading(true);

      const filteredData = filterPagination();

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<ListVendasProps>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.INTEGRACAO_TRAY_VENDAS_PEDIDO,
          gridPaginadaConsulta,
          {
            ...filteredData,
          }
        )
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((avisos) => toast.warning(avisos));
        }

        if (response.sucesso && isMountedRef.current) {
          setTotalRegistros(response.dados.total);
          setListVendas(
            response.dados.registros.map((item) => {
              const dataEmissao = new Date(item.dataEmissao);
              dataEmissao.setHours(dataEmissao.getHours() - 3);

              return {
                ...item,
                dataEmissao,
                isChecked: false,
              };
            })
          );
          setValorTotalPeriodo(response.dados.faturamento);
          setIsLoading(false);
        }
      }

      if (isMountedRef.current) {
        setIsLoading(false);

        if (!pageIsLoaded.current) {
          pageIsLoaded.current = true;
        }
      }
    },
    [filterPagination, isMountedRef, pageIsLoaded, setIsLoading, setListVendas]
  );

  const getSituacaoPedido = useCallback(async () => {
    const response = await api.get<
      void,
      ResponseApi<ListarSelectSituacaoPedido[]>
    >(ConstanteEnderecoWebservice.INTEGRACAO_TRAY_OPERACOES_VENDA, {
      params: {
        integracao: enumIdentificacaoIntegracao.TRAY,
      },
    });
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((avisos) => toast.warning(avisos));
      }

      if (response.sucesso) {
        setListSituacaoPedido(
          response.dados.map((item) => ({
            label: item.nome,
            value: item.id,
          }))
        );
      }
    }
  }, []);

  const filter = useMemo(() => {
    return {
      ...filterPagination(),
      identificacaoTipoOperacao: IdentificacaoTipoOperacaoEnum.VENDA,
      origem: enumIdentificacaoIntegracao.TRAY,
    };
  }, [filterPagination]);

  const handleImprimirRelatorio = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<void, ResponseApi<string | undefined>>(
      `${
        ConstanteEnderecoWebservice.PEDIDO_ORCAMENTO_VENDA
      }?${formatQueryObject(filter)}&identificacaoTipoOperacao=${
        IdentificacaoTipoOperacaoEnum.PEDIDO
      }`
    );
    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
        setIsLoading(false);
      }
      if (response?.sucesso && response?.dados) {
        ImprimirPDF(response.dados, 'relatorioVendasTray');
        setIsLoading(false);
      }
    }
    setIsLoading(false);
  }, [filter, setIsLoading]);

  const handleEnviarEmailRelatorio = useCallback(async () => {
    setIsLoading(true);

    await EmailModal({
      filtrosOperacoes: filter,
      operacoesSelecionadas: `&identificacaoTipoOperacao=${IdentificacaoTipoOperacaoEnum.PEDIDO}`,
    });
    setIsLoading(false);

    setIsLoading(false);
  }, [filter, setIsLoading]);

  useEffect(() => {
    getSituacaoPedido();
  }, [getSituacaoPedido]);

  useEffect(() => {
    obterQuantidadePendencias();
  }, [obterQuantidadePendencias]);

  useEffect(() => {
    getSituacaoNotaFiscal();
  }, [getSituacaoNotaFiscal]);

  return (
    <Box>
      <ContainerHeader h={isLargerThan1000 ? '64px' : '245px'}>
        <Flex w="full" pt="5px" h="auto" minH="64px" alignItems="center">
          <FormProvider {...formMethods}>
            <SimpleGridForm
              py={isLargerThan1000 ? undefined : '20px'}
              pr={isLargerThan1000 ? undefined : '20px'}
              w="full"
            >
              <GridItem colSpan={isLargerThan1000 ? 7 : 12}>
                <SimpleGridForm>
                  <GridItem
                    mb={isLargerThan1000 ? undefined : ['0', '-10px', '-10px']}
                    mr={
                      isLargerThan1000
                        ? isLargerThan1134
                          ? undefined
                          : '10px'
                        : undefined
                    }
                    colSpan={isLargerThan1000 ? 5 : 12}
                  >
                    <Flex alignItems="center">
                      <Box
                        mr="10px"
                        cursor="pointer"
                        onClick={handleVoltarPainelAdm}
                      >
                        <Icon
                          fontSize="25px"
                          color="white"
                          as={FiChevronLeft}
                        />
                      </Box>
                      <InputDateRange
                        borderColor="gray.100"
                        borderRadius="md"
                        name="dataEmissao"
                        onConfirm={handleSubmit}
                        startDateName="dataEmissaoInicio"
                        endDateName="dataEmissaoFim"
                      />
                    </Flex>
                  </GridItem>
                  <GridItem
                    ml={
                      isLargerThan1000
                        ? isLargerThan1134
                          ? undefined
                          : '-25px'
                        : undefined
                    }
                    mb={isLargerThan1000 ? undefined : ['0', '-10px', '-10px']}
                    colSpan={isLargerThan1000 ? 4 : 12}
                    w="full"
                  >
                    <Box
                      w="full"
                      minW={isLargerThan1134 ? '200px' : '180px'}
                      flexGrow={1}
                    >
                      <SelectMulti
                        name="integracaoSituacaoPedidoId"
                        textLabelSelectAll={
                          isLargerThan1134 ? 'Todas as vendas' : 'Todas vendas'
                        }
                        id="integracaoSituacaoPedidoId"
                        placeholder="Escolha "
                        variant="outline"
                        options={listSituacaoPedido}
                        isMulti
                        isSearchable={false}
                        closeMenuOnSelect={false}
                      />
                    </Box>
                  </GridItem>
                  <GridItem
                    display="flex"
                    justifyContent={isLargerThan1000 ? undefined : 'center'}
                    ml={
                      isLargerThan1000
                        ? isLargerThan1134 && !isLargerThan1350
                          ? '24px'
                          : '0px'
                        : undefined
                    }
                    colSpan={isLargerThan1000 ? 2 : 12}
                  >
                    <BuscaAvancadaButton
                      borderRadius="full"
                      color="white"
                      _hover={{
                        color: 'blue.900',
                        background: 'white',
                      }}
                      bg="transparent"
                      height="32px"
                      setIsModalBuscaAvancadaOpen={handlePushModalBuscaAvancada}
                      hasFilters={hasFilters}
                      padding="10px 24px"
                      justifyContent="center"
                      minW={['150px', '150px', '180', '188px']}
                      width={['full', '50%', '180', '188px']}
                    >
                      {hasFilters ? 'Filtro selecionado' : 'Busca avançada'}
                    </BuscaAvancadaButton>

                    <IconButton
                      ml="10px"
                      color="white"
                      _hover={{
                        color: 'blue.900',
                        background: 'white',
                      }}
                      bg="transparent"
                      onClick={handleHabilitarAcaoEmMassa}
                      aria-label="compartilhar"
                      fontSize="20px"
                      icon={<EditarMassaIcon />}
                    />

                    <IconButton
                      color="white"
                      _hover={{
                        color: 'blue.900',
                        background: 'white',
                      }}
                      onClick={() =>
                        handleAbrirModalCompartilhar(
                          handleEnviarEmailRelatorio,
                          handleImprimirRelatorio
                        )
                      }
                      bg="transparent"
                      aria-label="compartilhar"
                      fontSize="20px"
                      icon={<CompartilharMenuIcon />}
                    />
                  </GridItem>
                </SimpleGridForm>
              </GridItem>
              <GridItem
                mt={isLargerThan1000 ? undefined : '-10px'}
                colSpan={isLargerThan1000 ? 5 : 12}
              >
                <Flex
                  h="full"
                  alignItems="center"
                  pr={['2px', '30px']}
                  float={isLargerThan1000 ? 'right' : undefined}
                  direction={
                    isLargerThan1350
                      ? 'row'
                      : isLargerThan1000
                      ? 'column'
                      : 'row'
                  }
                  justifyContent={isLargerThan1000 ? 'flex-end' : 'center'}
                >
                  <Text
                    textAlign="center"
                    display="inline"
                    color="white"
                    fontSize="12px"
                  >
                    <Text mr="2px" display="inline">
                      Quantidade:
                    </Text>
                    <Text
                      fontSize="14px"
                      mr={isLargerThan1000 && !isLargerThan1350 ? '55px' : '0'}
                      display="inline"
                      color="secondary.300"
                    >
                      {totalRegistros}
                    </Text>
                  </Text>

                  <Divider
                    orientation="vertical"
                    mr={['5px', '20px']}
                    ml={['5px', '20px']}
                  />
                  <Text
                    textAlign="center"
                    display="inline"
                    color="white"
                    fontSize="12px"
                  >
                    <Text mr="2px" display="inline">
                      Total do período:
                    </Text>
                    <Text
                      fontSize="14px"
                      display="inline"
                      color="secondary.300"
                    >
                      {moneyMask(valorTotalPeriodo, true)}
                    </Text>
                  </Text>
                </Flex>
              </GridItem>
            </SimpleGridForm>
            <DrawerBuscaAvancada
              isOpen={isOpenBuscaAvancada}
              handleSubmitBuscaAVancada={filtersSubmit}
              onClose={() => setIsOpenBuscaAvancada(false)}
            />
          </FormProvider>
        </Flex>
      </ContainerHeader>
      {amountPendencia > 0 && (
        <ContainerHeader
          boxShadow="none"
          zIndex="1019"
          bg="yellow.400"
          h="auto"
          alignItems="center"
          py="5px"
        >
          <Icon cursor="pointer" fontSize="20px" as={AvisoIcon} />
          <Text pl="10px">
            Você tem{' '}
            <span style={{ fontWeight: 'bold' }}>
              {amountPendencia > 1
                ? `${amountPendencia} vendas com pendências`
                : `${amountPendencia} venda com pendência`}{' '}
            </span>
            de cadastro. Clique no número da venda para identificar e corrigir o
            problema.
          </Text>
        </ContainerHeader>
      )}
      {pendenciaNotaFiscal > 0 && (
        <ContainerHeader
          h="auto"
          py="5px"
          zIndex="1019"
          bg="red.100"
          alignItems="center"
          boxShadow="none"
        >
          <Icon cursor="pointer" fontSize="20px" as={AvisoIcon} />
          <Text pl="10px">
            Você tem{' '}
            <span style={{ fontWeight: 'bold' }}>
              {pendenciaNotaFiscal === 1
                ? `${pendenciaNotaFiscal} nota fiscal rejeitada.`
                : `${pendenciaNotaFiscal} notas fiscais rejeitadas.`}{' '}
            </span>
            Clique no número da venda para identificar e corrigir o problema.
          </Text>
        </ContainerHeader>
      )}
      <Collapse in={hasVendaSeleciona} animateOpacity>
        <Box mb="20px">
          <Flex
            zIndex="0"
            style={{
              backgroundBlendMode: 'multiply',
            }}
            pl={['24px', '44px', '44px']}
            bgGradient="linear-gradient(0deg, rgba(204, 204, 204, 0.5) 60%, #888888 100%, #888888 100%, #000 100%) "
            h="51px"
            alignItems="center"
          >
            <Checkbox
              mb="1px"
              bg="white"
              isChecked={selecionarTodasVendas}
              onChange={handleToggleSelecionarTodasVendas}
              colorScheme="primary"
              boxShadow="inset 0px 3px 6px #00000029"
            />

            <Text
              fontWeight="semibold"
              fontSize="16px"
              cursor="pointer"
              ml="15px"
              mr="24px"
              color="black"
            >
              {amountVendaSelecionada > 1
                ? `${amountVendaSelecionada} itens selecionados`
                : `${amountVendaSelecionada} item selecionado`}
            </Text>
            <Text
              fontWeight="semibold"
              fontSize="14px"
              cursor="pointer"
              onClick={handleLimparSelecao}
              mr={['28px', '48px', '48px']}
              color="red.500"
            >
              Limpar seleção
            </Text>
            <Text
              fontSize="14px"
              opacity={isCancelarMultiplas ? '1' : '0.5'}
              cursor={!isCancelarMultiplas ? 'not-allowed' : 'pointer'}
              onClick={() => {
                if (isCancelarMultiplas) {
                  handleCancelarMultiplasVendas();
                }
              }}
              mr="64px"
              color="black"
            >
              Cancelar
            </Text>

            <Text
              fontSize="14px"
              opacity={isVendaSemOperacao ? '0.5' : '1'}
              onClick={() => {
                if (!isVendaSemOperacao) {
                  handleGerarNotaFiscal(vendasSelecionadas);
                }
              }}
              cursor={isVendaSemOperacao ? 'not-allowed' : 'pointer'}
              mr="64px"
              color="black"
            >
              Emitir Nota Fiscal
            </Text>

            <Text
              fontSize="14px"
              opacity={!hasNotaFiscalAutorizada ? '0.5' : '1'}
              onClick={() => {
                if (hasNotaFiscalAutorizada) {
                  handleOpenModalCodigoRastreio(vendasSelecionadas);
                  handleOpenModalCodigoRastreio(vendasSelecionadas);
                }
              }}
              cursor={!hasNotaFiscalAutorizada ? 'not-allowed' : 'pointer'}
              color="black"
            >
              Código de Rastreio
            </Text>
          </Flex>
        </Box>
      </Collapse>
      <EmissaoNotaFiscalProvider reloadList={reloadList}>
        <ModalEmissaoNotaFiscal
          isOpen={abriModalNotaFiscal}
          onClose={handleFecharModalNotaFiscal}
          listNotaFiscal={produtoEmitirNota}
        />
      </EmissaoNotaFiscalProvider>

      <Box px="24px">
        <PagedTable
          ref={pagedTableRef}
          isLoading={isLoading}
          variant="card"
          bg="transparent"
          boxShadow="none"
          paddingRight="2px"
          paddingLeft="2px"
          pt="20px"
          pb="20px"
          paginationHasDivider={false}
          loadColumnsData={paginationHandle}
          itemsTotalCount={totalRegistros}
          defaultKeyOrdered="nome"
          tableHeaders={
            [
              isAcaoEmMassa
                ? {
                    key: '',
                    content: '',
                    isOrderable: false,
                    w: '1px',
                    pr: '0',
                  }
                : undefined,
              {
                key: 'data',
                content: 'Data / Hora',
                isOrderable: true,
              },
              { key: 'origem', content: 'Origem', isOrderable: false },

              {
                key: 'cliente',
                isOrderable: false,

                content: 'Cliente',
              },
              {
                key: 'situacao',
                isOrderable: false,

                content: 'Situação',
              },
              {
                key: 'valor',
                isOrderable: false,

                content: 'Valor',
              },
              {
                key: 'operacao',
                isOrderable: false,

                content: 'Operação',
              },
              { key: 'acoes', content: 'Ações', isOrderable: false, w: '1px' },
            ].filter(Boolean) as TableHeader[]
          }
          renderTableRows={(listVendas || []).map((vendaItem, index) => (
            <VendaItem
              handleGerarNotaFiscal={handleGerarNotaFiscal}
              handleOpenModalCodigoRastreio={handleOpenModalCodigoRastreio}
              handleToggleSelecionarVendas={handleToggleSelecionarVendas}
              index={index}
              onReload={onReload}
              isSelecionarVenda={isAcaoEmMassa}
              vendaItem={vendaItem}
              key={vendaItem.integracaoPedidoId}
              handleAbrirModalCancelarVenda={handleAbrirModalCancelarVenda}
            />
          ))}
        />
        <ModalConfirmacao
          isOpen={modalConfirmacaoCancelarIsOpen}
          setIsOpen={setModalConfirmacaoCancelarIsOpen}
          onConfirm={cancelarVenda}
          textoCabecalho="Deseja cancelar esta operação?"
          textoMensagem="Se continuar cancelará toda a operação, e esta ação não poderá ser desfeita. Deseja continuar?"
          textoCancelar="Fechar!"
        />
      </Box>
    </Box>
  );
};
