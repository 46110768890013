import React, {
  createContext,
  MutableRefObject,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import { useFormContext } from 'react-hook-form';

import obterPrecoProduto from 'helpers/api/TabelaPreco/obterPrecoProduto';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { OperacaoItemObter } from 'helpers/api/Operacao/obterOperacaoComItens';
import { TipoProduto } from 'constants/enum/tipoProduto';

import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import { PaginationData } from 'components/update/Pagination';
import OptionType from 'types/optionType';
import { useOperacaoContext } from './Operacao';

export type TamanhoOption = {
  id: string;
  nome: string;
  padraoSistema: boolean;
};

export type ProdutoOption = {
  nome: string;
  cor: string;
  foto: string;
  permiteAlterarValorNaVenda: boolean;
  volumeUnitario: boolean;
  itemConsignado: boolean;
  solicitarInformacaoComplementar: boolean;
  adicionarItemAutomaticamente: boolean;
  tamanhos: TamanhoOption[];
  tipoProduto: TipoProduto;
};

export interface ProdutoAdicionarAutomaticamenteProps {
  solicitarInformacaoComplementar: boolean;
  volumeUnitario: boolean;
  cor: string;
  tamanho: TamanhoOption;
}

export interface ProdutoLancamentoProps
  extends ProdutosLancamentoProps,
    ProdutoAdicionarAutomaticamenteProps {}

export type ProdutosLancamentoProps = {
  id: string;
  nome: string;
  produtoCorId: string;
  produtoCorTamanhoId: string;
  adicionarItemAutomaticamente: boolean;
};

export type ProdutoResponse = {
  id: string;
  nome: string;
};

type ProdutoInfo = {
  produtoId: string;
  volumeUnitario: boolean;
  solicitarInformacaoComplementar: boolean;
  permiteAlterarValorNaVenda: boolean;
  tamanhos?: TamanhosProduto[] | [];
};
type TamanhosProduto = {
  id: string;
  tamanho: string;
  padraoSistema: boolean;
  sku: string | null;
  codigoGTINEAN: string | null;
  codigoExterno: string | null;
  codigoBarrasInterno: string | null;
  sequenciaCodigoBarras: string | null;
  referencia: string | null;
};

export type GetPaginatedProductsOptionsParams = {
  search: string | undefined;
  dataPagination: PaginationData;
  forceEnter?: boolean;
  allowSearchWithLengthSmallerThanThree?: boolean;
  allowSearchOnlyWithNumbers?: boolean;
};

interface SelectProdutoContextProps {
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  getPaginatedProductsOptions: (
    params: GetPaginatedProductsOptionsParams
  ) => Promise<{ options: OptionType[] }>;
  onChange: (value: { label: string; value: string }) => void;
  options: OptionType<string>[];
  clearOptions: () => void;
  totalRegistros: number;
  currentPage: MutableRefObject<number>;
  onScannedCodeBar: (
    value: string | undefined
  ) => Promise<{ hasOptions: boolean }>;
}

export const SelectProdutoContext = createContext<SelectProdutoContextProps>(
  {} as SelectProdutoContextProps
);

interface SelectProdutoProviderProps {
  children: React.ReactNode;
  handleCreateOperacaoItem: (
    operacaoItem: OperacaoItemObter
  ) => void | Promise<void>;
  onChange: (value: { label: string; value: string }) => void;
}

export default function SelectProdutoProvider({
  children,
  handleCreateOperacaoItem,
  onChange,
}: SelectProdutoProviderProps): JSX.Element {
  const { getValues } = useFormContext();
  const { setOperacaoIsLoading } = useOperacaoContext();

  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<OptionType<string>[]>([]);
  const [totalRegistros, setTotalRegistros] = useState<number>(0);

  const currentPage = useRef(0);

  const handleAdicionarItemAutomaticamente = useCallback(
    async (produto: ProdutoLancamentoProps) => {
      setOperacaoIsLoading(true);
      const tabelaPrecoItem = getValues('tabelaPreco');

      const valorUnitarioItem = await obterPrecoProduto(
        produto.produtoCorTamanhoId,
        tabelaPrecoItem.value
      );

      if (valorUnitarioItem) {
        const newOperacaoItem = {
          produto: produto.nome,
          cor: produto.cor,
          solicitarInformacaoComplementar:
            produto.solicitarInformacaoComplementar,
          descontoItem: 0,
          quantidade: 1,
          valorUnitario: valorUnitarioItem,
          tamanho: produto.tamanho.padraoSistema
            ? null
            : produto?.tamanho?.nome,
          produtoCorTamanhoId: produto.produtoCorTamanhoId,
        } as OperacaoItemObter;

        handleCreateOperacaoItem(newOperacaoItem);
        setInputValue('');
      } else {
        toast.warning(
          'Produto sem valor unitário não pode ser adicionado automaticamente.'
        );
      }
      setOperacaoIsLoading(false);
    },
    [getValues, handleCreateOperacaoItem, setOperacaoIsLoading]
  );

  const checkIfAddItemAutomatically = useCallback(
    async (produto: ProdutoResponse, newInputValue: string) => {
      setOperacaoIsLoading(true);
      const response = await api.get<void, ResponseApi<ProdutoInfo>>(
        ConstanteEnderecoWebservice.OBTER_INFORMACOES_PRODUTO,
        {
          params: {
            produtoCorId: produto.id,
          },
        }
      );

      if (response.avisos) {
        response.avisos.forEach((avisos) => toast.warn(avisos));
      }
      if (response.sucesso && response.dados) {
        const { tamanhos } = response.dados;
        const productToAddAutomatically = tamanhos?.find(
          (tamanho) =>
            tamanho.sku === newInputValue ||
            tamanho.codigoBarrasInterno === newInputValue ||
            tamanho.sequenciaCodigoBarras === newInputValue ||
            tamanho.codigoGTINEAN === newInputValue ||
            tamanho.codigoExterno === newInputValue ||
            tamanho.referencia === newInputValue
        );

        if (productToAddAutomatically) {
          await handleAdicionarItemAutomaticamente({
            ...produto,
            adicionarItemAutomaticamente: true,
            nome: produto.nome.split('|')[0],
            produtoCorId: produto.id,
            solicitarInformacaoComplementar:
              response.dados.solicitarInformacaoComplementar,
            volumeUnitario: response.dados.volumeUnitario,
            cor: produto.nome.split('|')[1],
            produtoCorTamanhoId: productToAddAutomatically?.id || '',
            tamanho: {
              id: productToAddAutomatically?.id || '',
              nome: productToAddAutomatically?.tamanho || '',
              padraoSistema: productToAddAutomatically?.padraoSistema || false,
            },
          });
        }
      }
      setOperacaoIsLoading(false);
    },
    [handleAdicionarItemAutomaticamente, setOperacaoIsLoading]
  );

  const clearOptions = useCallback(() => {
    currentPage.current = 1;
    setOptions([]);
  }, []);

  const getPaginatedProductsOptions = useCallback(
    async ({
      search,
      dataPagination,
      forceEnter,
      allowSearchWithLengthSmallerThanThree = false,
      allowSearchOnlyWithNumbers = false,
    }: GetPaginatedProductsOptionsParams) => {
      if (!search || search?.length < 1) {
        setTotalRegistros(0);
        setOptions([]);
        return { options: [] };
      }

      const onlyLetters = /^[a-z]+$/i.test(search);
      const onlyNumbers = /^[0-9]+$/i.test(search);

      const shouldSearchWithLessThanThree =
        search.length > 3 || allowSearchWithLengthSmallerThanThree;

      if (onlyLetters && !shouldSearchWithLessThanThree) {
        setTotalRegistros(0);
        setOptions([]);
        return { options: [] };
      }

      if (onlyNumbers && !forceEnter && !allowSearchOnlyWithNumbers) {
        setTotalRegistros(0);
        setOptions([]);
        return { options: [] };
      }

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<ProdutoResponse>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.LISTAR_SELECT_PRODUTO_COR_PAGINADO,
          dataPagination
        ),
        {
          params: {
            nomeSkuCodigoExternoBarrasGtinEan: search,
          },
        }
      );

      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response?.sucesso && response?.dados) {
          const { registros } = response.dados;

          const dadosProduto = registros.map((data) => ({
            label: data.nome,
            value: data.id,
          }));

          if (registros.length === 0 && forceEnter) {
            toast.warning('Produto não foi encontrado.');

            setTotalRegistros(0);
            setOptions([]);
            return { options: [] };
          }

          if (registros.length === 1 && forceEnter) {
            const produto = registros[0];
            await checkIfAddItemAutomatically(produto, search);

            setTotalRegistros(0);
            setOptions([]);
            return { options: [] };
          }

          setTotalRegistros(response.dados.total);
          setOptions((prev) => [...prev, ...dadosProduto]);
          return { options: dadosProduto };
        }
      }

      setTotalRegistros(0);
      setOptions([]);
      return { options: [] };
    },
    [checkIfAddItemAutomatically]
  );

  const onScannedCodeBar = useCallback(
    async (codigoBarras) => {
      clearOptions();

      const { options: newOptions } = await getPaginatedProductsOptions({
        search: codigoBarras,
        dataPagination: {
          currentPage: 1,
          orderColumn: '',
          orderDirection: 'asc',
          pageSize: 20,
        },
        forceEnter: true,
      });

      return { hasOptions: newOptions.length > 1 };
    },
    [clearOptions, getPaginatedProductsOptions]
  );

  return (
    <SelectProdutoContext.Provider
      value={{
        inputValue,
        setInputValue,
        onChange,
        getPaginatedProductsOptions,
        options,
        clearOptions,
        totalRegistros,
        currentPage,
        onScannedCodeBar,
      }}
    >
      {children}
    </SelectProdutoContext.Provider>
  );
}

export function useSelectProdutoContext(): SelectProdutoContextProps {
  const context = useContext(SelectProdutoContext);

  if (!context)
    throw new Error(
      'useSelectProdutoContext must be used within a SelectProdutoProvider.'
    );

  return context;
}
