import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  ModalContent,
  Icon,
  ModalCloseButton,
  ModalBody,
  Flex,
  HStack,
  Box,
  Button,
  useMediaQuery,
  Text,
  Avatar,
  Divider,
  ModalFooter,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import RegraLimiteCreditoEnum from 'constants/enum/regraLimiteCredito';
import EstadosEnum from 'constants/enum/estados';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import {
  HistoricoConsultaPdv,
  ObservacaoConsulta,
  UserConsultaPdv,
} from 'icons';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { TabDadosCliente } from './DetalhesTabs/tabDadosCliente';
import { TabHistorico } from './DetalhesTabs/tabHistorico';
import {
  ClienteFornecedorInterface,
  PagamentosInterface,
} from './Types/validationForms';

interface ModalInformacoesClienteProps {
  isOpen: boolean;
  onClose: () => void;
  idCliente: string;
  openEditar: () => void;
}

const ModalInformacoesCliente = ({
  isOpen,
  onClose,
  idCliente,
  openEditar,
}: ModalInformacoesClienteProps) => {
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');
  const [cliente, setCliente] = useState({} as ClienteFornecedorInterface);
  const [isLoading, setIsLoading] = useState(false);
  const [corClassificacao, setCorClassificacao] = useState('');
  const emptyField = '- - -';

  const obterLogradouro = useMemo((): string => {
    let logradouroComNumero = '';

    if (cliente.logradouro) {
      logradouroComNumero = cliente.logradouro;
    }

    if (cliente.numero) {
      logradouroComNumero += `, ${cliente.numero}, `;
    }

    return logradouroComNumero;
  }, [cliente.logradouro, cliente.numero]);

  const obterSiglaDoEstado = (nomeEstado: string) => {
    const estados = Object.values(EstadosEnum?.properties);

    const estadoEncontrado = (estados || [])?.find(
      (estado) => estado?.nome === nomeEstado?.toUpperCase()
    );
    return estadoEncontrado ? estadoEncontrado?.sigla : '';
  };

  const obterBairroCidadeEstado = useMemo((): string => {
    let bairroCidadeEstado = '';

    if (cliente.bairro) {
      bairroCidadeEstado = `${cliente.bairro} - `;
    }

    if (cliente.cidadeNome) {
      bairroCidadeEstado += `${cliente.cidadeNome} - ${obterSiglaDoEstado(
        cliente.estadoNome
      )}`;
    }

    return bairroCidadeEstado;
  }, [cliente.bairro, cliente.cidadeNome, cliente.estadoNome]);

  const latestProps = useRef({ idCliente });

  const obterTelefoneCelularFormatado = () => {
    if (cliente.telefone && cliente.celular) {
      return `${cliente.telefone} | ${cliente.celular}`;
    }

    if (!cliente.telefone && !cliente.celular) return emptyField;

    return cliente.telefone ? cliente.telefone : cliente.celular;
  };
  const numeroTelefone = obterTelefoneCelularFormatado();
  useEffect(() => {
    latestProps.current = { idCliente };
  });

  useEffect(() => {
    const getClienteFornecedor = async () => {
      setIsLoading(true);

      const response = await api.get<
        void,
        ResponseApi<ClienteFornecedorInterface>
      >(
        `${ConstanteEnderecoWebservice.CLIENTE_FORNECEDOR_OBTER_INFORMACOES_PDV}/${latestProps.current.idCliente}`
      );

      setIsLoading(false);

      if (response.avisos) {
        return;
      }

      const newCliente = response.dados;
      newCliente.ultimasCompras.forEach(({ pagamentos }, index) => {
        newCliente.ultimasCompras[index].pagamentos = pagamentos.reduce(
          (prev, t) => {
            const currentValueIndex = prev.findIndex(
              (p) => p.identificadorAgrupamento === t.identificadorAgrupamento
            );

            if (currentValueIndex >= 0) {
              prev.splice(currentValueIndex, 1);
              prev.splice(currentValueIndex, 0, t);
            } else {
              prev.push(t);
            }

            return prev;
          },
          [] as PagamentosInterface[]
        );
      });

      setCliente(response.dados);

      const regraLimiteCredito = RegraLimiteCreditoEnum.properties.filter(
        (valor) =>
          valor.value === response.dados.categoriaClienteRegraLimiteCredito
      )[0];

      if (regraLimiteCredito) {
        setCorClassificacao(regraLimiteCredito.iconColor);
      }
    };

    getClienteFornecedor();
  }, []);

  return (
    <ModalPadraoChakra
      onClose={onClose}
      isOpen={isOpen}
      isCentered={isLargerThan900}
      size={!isLargerThan900 ? 'full' : '7xl'}
    >
      <ModalContent
        minH={!isLargerThan900 ? 'full' : '600px'}
        marginBottom={isLargerThan900 ? '3.75rem' : ''}
        marginTop={isLargerThan900 ? '3.75rem' : ''}
        bg="gray.50"
        overflowY={isLargerThan900 ? 'unset' : 'scroll'}
        maxW={!isLargerThan900 ? 'full' : '800px'}
      >
        {isLoading && <LoadingPadrao />}

        <ModalCloseButton color="white" />
        <ModalBody p={0} h="50%">
          <Flex
            h={isLargerThan900 ? '110px' : '230px'}
            pt={isLargerThan900 ? '40px' : '20px'}
            alignItems="center"
            borderRadius="5px 5px 0px 0px"
            direction={isLargerThan900 ? 'row' : 'column'}
            bgGradient="linear(to-r, primary.500, pink.800)"
          >
            <Flex
              w={isLargerThan900 ? '220px' : ''}
              pl={isLargerThan900 ? '58px' : ''}
              pr={isLargerThan900 ? '58px' : ''}
              justifyContent="center"
              alignItems={{ base: 'flex-end', md: 'center' }}
            >
              <Avatar
                src={cliente.foto}
                w={{ base: '100px', md: '108px' }}
                h={{ base: '100px', md: '108px' }}
              />
            </Flex>
            <Box
              justifyContent={{ base: 'center', md: 'flex-start' }}
              alignItems="center"
            >
              <Text
                mt={isLargerThan900 ? '' : '8px'}
                color="gray.50"
                fontSize="20px"
                textAlign={{ base: 'center', md: 'left' }}
              >
                {cliente.nome}
              </Text>
              <Flex
                mt={isLargerThan900 ? '10px' : ''}
                fontSize="14px"
                justifyContent="center"
                direction={isLargerThan900 ? 'row' : 'column'}
                alignItems="center"
              >
                <Text color="purple.100">
                  Cliente desde {cliente.anoCadastro}
                </Text>
                {isLargerThan900 && (
                  <Flex
                    fontSize="14px"
                    justifyContent="center"
                    alignItems="center"
                    color="primary.100"
                  >
                    <Divider
                      mr="10px"
                      mb="5px"
                      ml="10px"
                      h="15px"
                      orientation="vertical"
                    />
                  </Flex>
                )}
                <Text color="purple.100">
                  {cliente.quantidadeVendas} compras realizadas
                </Text>
              </Flex>
            </Box>
          </Flex>
          <Flex w="full">
            <Tabs
              align={isLargerThan900 ? 'start' : 'center'}
              w="full"
              mt="-2px"
              variant="enclosed"
            >
              <TabList
                pt="26px"
                bgGradient="linear(to-r, primary.500, pink.800)"
              >
                <Flex
                  w="full"
                  alignItems="center"
                  justifyContent={isLargerThan900 ? '' : 'center'}
                  color="gray.700"
                  fontSize="14px"
                >
                  {isLargerThan900 && (
                    <Box w={{ base: '100px', md: '220px' }} />
                  )}
                  <Tab
                    _selected={{ color: 'gray.700', bg: 'gray.50' }}
                    w={isLargerThan900 ? '160px' : '30%'}
                  >
                    {isLargerThan900 ? (
                      'Dados do cliente'
                    ) : (
                      <Icon boxSize="40px" as={UserConsultaPdv} />
                    )}
                  </Tab>
                  <Tab
                    _selected={{ color: 'gray.700', bg: 'gray.50' }}
                    w={isLargerThan900 ? '160px' : '30%'}
                  >
                    {isLargerThan900 ? (
                      'Histórico'
                    ) : (
                      <Icon
                        boxSize="40px"
                        mr="-10px"
                        as={HistoricoConsultaPdv}
                      />
                    )}
                  </Tab>
                  <Tab
                    _selected={{ color: 'gray.700', bg: 'gray.50' }}
                    w={isLargerThan900 ? '160px' : '30%'}
                  >
                    {isLargerThan900 ? (
                      'Observações'
                    ) : (
                      <Icon boxSize="40px" as={ObservacaoConsulta} />
                    )}
                  </Tab>
                </Flex>
              </TabList>
              <TabPanels overflowY={isLargerThan700 ? 'unset' : 'scroll'}>
                <TabPanel h="100%" bg="gray.50" borderRadius="0px">
                  <TabDadosCliente
                    obterLogradouro={obterLogradouro}
                    obterBairroCidadeEstado={obterBairroCidadeEstado}
                    corClassificacao={corClassificacao}
                    cliente={cliente}
                    numeroTelefone={numeroTelefone}
                  />
                </TabPanel>
                <TabPanel bg="gray.50" borderRadius="0px">
                  <TabHistorico cliente={cliente} />
                </TabPanel>
                <TabPanel bg="gray.50" borderRadius="0px">
                  <Flex borderRadius="5px" padding="10px" bg="white">
                    <Text fontSize="16px" color="primary.500">
                      {cliente.observacao ? cliente.observacao : emptyField}
                    </Text>
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </ModalBody>
        <ModalFooter
          pb="24px"
          pt="20px"
          justifyContent="center"
          alignItems="center"
        >
          <HStack spacing={6}>
            <Button
              variant="outline"
              size="sm"
              minWidth="130px"
              color="gray.400"
              onClick={openEditar}
            >
              Editar cadastro
            </Button>

            <Button
              variant="solid"
              minWidth="100px"
              colorScheme="secondary"
              size="sm"
              onClick={onClose}
            >
              Fechar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export default ModalInformacoesCliente;
