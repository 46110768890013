export type AmbienteFiscal = 1 | 2;

const AmbienteFiscalEnum = {
  Producao: 1,
  Homologacao: 2,

  properties: {
    1: { name: 'Produção', value: 1 },
    2: { name: 'Homologação', value: 2 },
  },
};

export default AmbienteFiscalEnum;
