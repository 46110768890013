import { useCallback, useState, useEffect } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  ModalFooter,
  Box,
  ModalHeader,
  useMediaQuery,
  Divider,
  GridItem,
  VStack,
  Grid,
  Checkbox,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteFuncionalidades from 'constants/permissoes';
import auth from 'modules/auth';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { ButtonDefault } from 'components/Button/ButtonChakra';
import { NumberInput } from 'components/update/Input/NumberInput';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';

type FormData = {
  markup: number | string;
  precoNovo: number;
  precoAtual: number;
  custo: number;
  tabelaPreco: { label: string; value: string };
};

type VariacaoResponseProps = {
  produtoCorTamanhoId: string;
  produto: string;
  cor: string;
  tamanho: string;
  imagem: string;
  isChecked: boolean;
};

export type Variacao = {
  produtoCorTamanho: {
    produtoCorTamanhoId: string;
    produto: string;
    cor: string;
    tamanho: string;
  };
  precoVenda: {
    precoVenda: number;
    markup: number;
  };
  tabelaPreco: string;
  produtoCorTamanhoId: string;
  tabelaPrecoId: string;
  isChecked: boolean;
};

export type VariacaoProps = Variacao[];

type Options = {
  label: string;
  value: string;
};

export type OptionResponseProps = {
  id: string;
  nome: string;
  padraoSistema: boolean;
};

type ModalAdicionarVariacaoProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<VariacaoProps> & {
    idProduto: string;
    precoAtual: number;
    custo: number;
    tabelaPreco: Options[];
    isAlterar?: boolean;
    dados?: Variacao | undefined;
  };

export const ModalAdicionarVariacao = create<
  ModalAdicionarVariacaoProps,
  VariacaoProps
>(
  ({
    onResolve,
    onReject,
    idProduto,
    isAlterar = false,
    custo,
    precoAtual,
    dados,
    tabelaPreco,
    ...rest
  }) => {
    const [listVariacao, setListVariacao] = useState<VariacaoResponseProps[]>(
      []
    );
    const [isLoading, setIsLoading] = useState(false);

    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    const valueMarkup =
      precoAtual > 0 && custo > 0 ? (precoAtual / custo - 1) * 100 : 0;

    const formMethods = useForm<FormData>({
      defaultValues: {
        custo,
        precoAtual,
        markup: valueMarkup.toFixed(2),
        precoNovo: 0,
      },
    });

    const possuiPermissaoVisualizarPrecoCusto = auth.possuiPermissao(
      ConstanteFuncionalidades.USUARIO_VISUALIZAR_PRECO_CUSTO
    ).permitido;

    const { handleSubmit: onSubmit, getValues, setValue, reset } = formMethods;

    const hasVariacaoSelecionada = listVariacao.some(
      (variacao) => variacao.isChecked
    );

    const handleSubmit = onSubmit((data) => {
      if (data?.tabelaPreco === undefined || data?.tabelaPreco === null) {
        toast.warning('Escolha uma tabela de preço');
        return;
      }
      setIsLoading(true);

      const valueData = listVariacao
        .filter((item) => item.isChecked)
        .map((variacao) => ({
          tabelaPreco: data?.tabelaPreco?.label,
          produtoCorTamanhoId: variacao.produtoCorTamanhoId,
          tabelaPrecoId: data.tabelaPreco.value,
          produtoCorTamanho: {
            produtoCorTamanhoId: variacao.produtoCorTamanhoId,
            produto: '',
            cor: variacao.cor,
            tamanho: variacao.tamanho,
          },
          precoVenda: {
            precoVenda: data.precoNovo,
            markup: data.markup,
          },
          isChecked: true,
        })) as VariacaoProps;

      onResolve(valueData);
      setIsLoading(false);
      onClose();
    });

    function handleToggleSelecionarVariacao(index: number) {
      if (isAlterar) {
        return;
      }

      setListVariacao((prev) =>
        prev.map((prevItem, prevIndex) => ({
          ...prevItem,
          isChecked:
            prevIndex === index ? !prevItem.isChecked : prevItem.isChecked,
        }))
      );
    }

    const getVariacoes = useCallback(async () => {
      const response = await api.get<
        void,
        ResponseApi<VariacaoResponseProps[]>
      >(
        `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/produtoCorTamanhos`
      );
      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }

        if (response.sucesso) {
          setListVariacao(response.dados);
        }
      }
    }, [idProduto]);

    const handleModificarMarkup = useCallback(() => {
      const precoVenda = getValues('precoNovo');

      if (precoVenda === 0) {
        return;
      }

      const newMarkup = (precoVenda / custo - 1) * 100;

      if (newMarkup === Infinity) {
        return;
      }

      setValue('markup', newMarkup.toFixed(2));
    }, [custo, getValues, setValue]);

    useEffect(() => {
      if (isAlterar) {
        reset({
          custo,
          markup: dados?.precoVenda?.markup,
          precoAtual,
          precoNovo: dados?.precoVenda.precoVenda,
          tabelaPreco: {
            label: dados?.tabelaPreco,
            value: dados?.tabelaPrecoId,
          },
        });
        setListVariacao([
          {
            cor: dados?.produtoCorTamanho.cor || '',
            imagem: '',
            isChecked: true,
            produto: '',
            produtoCorTamanhoId: dados?.produtoCorTamanhoId || '',
            tamanho: dados?.produtoCorTamanho.tamanho || '',
          },
        ]);
      } else {
        getVariacoes();
      }
    }, [getVariacoes, reset, isAlterar, dados, custo, precoAtual]);

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? '3xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
      >
        <ModalContent
          bg="gray.50"
          borderRadius={isLargerThan900 ? 'md' : '0'}
          marginBottom={isLargerThan900 ? '3.75rem' : '0'}
          marginTop={isLargerThan900 ? '3.75rem' : '0'}
          w={isLargerThan900 ? '800px' : 'full'}
          h={isLargerThan900 ? '560px' : 'full'}
        >
          <ModalHeader pt="16px" pb={['20px', '20px', '28px']} pl="24px">
            <Text color="primary.50" fontSize="16px">
              Adicionar variação com preço exclusivo
            </Text>

            <Divider mt="16px" />
          </ModalHeader>

          <FormProvider {...formMethods}>
            {isLoading && <LoadingPadrao />}
            <ModalBody pl="24px" pr="24px" mb="20px" pt="0" pb="0">
              <Flex direction={['column', 'column', 'row']}>
                <GridItem h="full" w={['full', 'full', '60%']} colSpan={12}>
                  <Text color="gray.700" fontSize="14px">
                    Selecione a variação
                  </Text>
                  <Box
                    borderWidth="1px"
                    borderRadius="5px"
                    borderColor="gray.200"
                    borderStyle="solid"
                    bg="gray.100"
                    p="16px"
                  >
                    <Grid
                      templateColumns="repeat(1, 1fr)"
                      rowGap="2px"
                      mr="-10px"
                      sx={{
                        '& > div': {
                          marginRight: '6px',
                        },
                        '&::-webkit-scrollbar': {
                          width: '4px',
                          height: '5px',
                          scrollbarWidth: 'thin',
                          position: 'absolute',
                          right: '-10px',
                        },
                        '&::-webkit-scrollbar-track': {
                          background: 'none',
                          borderRadius: '6px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          background: 'gray.200',
                          borderRadius: '6px',
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                          background: 'gray.100',
                        },
                      }}
                      gridGap="16px"
                      maxH="332px"
                      overflowY="auto"
                    >
                      {listVariacao.map(
                        ({ isChecked, cor, tamanho }, index) => {
                          const labelCor = cor
                            ? `${cor} ${tamanho ? '|' : ''}`
                            : '';
                          return (
                            <Grid
                              pl="16px"
                              as="div"
                              h="28px"
                              pt="4px"
                              pr="16px"
                              alignItems="baseline"
                              bg={isChecked ? 'purple.50' : 'white'}
                              w="98%"
                              display="flex"
                              borderRadius="5px"
                              borderWidth="1px"
                              borderColor="gray.200"
                              borderStyle="solid"
                            >
                              <Flex>
                                <Box>
                                  <Checkbox
                                    colorScheme="primary"
                                    isChecked={isChecked}
                                    onChange={() =>
                                      handleToggleSelecionarVariacao(index)
                                    }
                                    mr="12px"
                                    mb="0"
                                  />
                                </Box>
                                <Box>
                                  <Text
                                    ml="10px"
                                    alignItems="center"
                                    display="flex"
                                  >
                                    <Text color="teal.600" mr="5px">
                                      {labelCor}
                                    </Text>
                                    <Text color="pink.700">{tamanho}</Text>
                                  </Text>
                                </Box>
                              </Flex>
                            </Grid>
                          );
                        }
                      )}
                    </Grid>
                  </Box>
                </GridItem>
                <VStack
                  ml={['0', '0', '24px']}
                  spacing={['20px', '20px', '28px']}
                  flex="1"
                  mt={['20px', '20px', '5px']}
                >
                  <SelectPadrao
                    label="Selecione uma tabela de preços"
                    required
                    asControlledByObject
                    id="tabelaPreco"
                    helperText="O cadastro do produto armazena apenas um preço por produto, portanto o valor exclusivo da variação ficará salvo dentro da tabela de preços selecionada."
                    options={tabelaPreco}
                    placeholder="Selecione a tabela de preço"
                    name="tabelaPreco"
                  />
                  <NumberInput
                    w="full"
                    cursor="not-allowed"
                    colorLabel="gray.700"
                    _disabled={{
                      background: 'gray.50',
                      borderColor: 'gray.200',
                      color: 'gray.500',
                      opacity: 1,
                    }}
                    isDisabled
                    leftElementColor="black"
                    id="custo"
                    label="Custo"
                    editarFundoLeftElemento
                    bgLeftElement="gray.50"
                    leftElement="R$"
                    esconderValor={!possuiPermissaoVisualizarPrecoCusto}
                    scale={2}
                    colSpan={3}
                    name="custo"
                  />
                  <NumberInput
                    w="full"
                    cursor="not-allowed"
                    colorLabel="gray.700"
                    _disabled={{
                      background: 'gray.50',
                      borderColor: 'gray.200',
                      color: 'gray.500',
                      opacity: 1,
                    }}
                    isDisabled
                    leftElementColor="black"
                    id="precoAtual"
                    label="Preço atual do produto"
                    editarFundoLeftElemento
                    bgLeftElement="gray.50"
                    leftElement="R$"
                    scale={2}
                    colSpan={3}
                    name="precoAtual"
                  />
                  <NumberInput
                    w="full"
                    cursor="not-allowed"
                    colorLabel="gray.700"
                    _disabled={{
                      background: 'gray.50',
                      borderColor: 'gray.200',
                      color: 'gray.500',
                      opacity: 1,
                    }}
                    isDisabled={!hasVariacaoSelecionada}
                    leftElementColor="black"
                    id="precoNovo"
                    label="Preço novo exclusivo"
                    editarFundoLeftElemento
                    bgLeftElement="gray.50"
                    leftElement="R$"
                    scale={2}
                    onBlur={handleModificarMarkup}
                    colSpan={3}
                    name="precoNovo"
                  />
                  <NumberInput
                    w="full"
                    cursor="not-allowed"
                    colorLabel="gray.700"
                    _disabled={{
                      background: 'gray.50',
                      borderColor: 'gray.200',
                      color: 'gray.500',
                      opacity: 1,
                    }}
                    isDisabled
                    leftElementColor="black"
                    id="markup"
                    label="Markup"
                    editarFundoLeftElemento
                    bgLeftElement="gray.50"
                    leftElement="%"
                    scale={2}
                    colSpan={3}
                    name="markup"
                  />
                </VStack>
              </Flex>
            </ModalBody>
            <ModalFooter flexDirection="column">
              <Flex
                w="full"
                h="full"
                justifyContent="center"
                alignItems="baseline"
              >
                <Button
                  color="gray.300"
                  variant="outlineDefault"
                  borderRadius="20px"
                  borderColor="gray.300"
                  fontSize="sm"
                  lineHeight="0"
                  type="button"
                  h={isLargerThan900 ? '32px' : '40px'}
                  mr="24px"
                  onClick={() => onClose()}
                  w="96px"
                >
                  Cancelar
                </Button>
                <ButtonDefault
                  color="black"
                  variant="solid"
                  colorScheme="secondary"
                  lineHeight="0"
                  isDisabled={!hasVariacaoSelecionada}
                  borderRadius="20px"
                  fontSize="sm"
                  h={isLargerThan900 ? '32px' : '40px'}
                  width="182px"
                  onClick={() => handleSubmit()}
                  isLoading={isLoading}
                >
                  Confirmar novo produto
                </ButtonDefault>
              </Flex>
            </ModalFooter>
          </FormProvider>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
