import {
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
  useState,
  ReactNode,
  useRef,
  MutableRefObject,
} from 'react';

import { FormData } from 'pages/ContasPagar/Listar/validationForm';

interface ContextFiltros {
  setLastFilters: Dispatch<SetStateAction<FormData | undefined>>;
  lastFilters?: FormData;
  carregarDadosContexto: MutableRefObject<boolean>;
}

interface FiltrosProvider {
  children: ReactNode;
}

const ContextFiltrosContasPagar = createContext({} as ContextFiltros);

export const ContextFiltrosContasPagarProvider = ({
  children,
}: FiltrosProvider) => {
  const [lastFilters, setLastFilters] = useState<FormData>();
  const carregarDadosContexto = useRef(true);

  return (
    <ContextFiltrosContasPagar.Provider
      value={{
        lastFilters,
        setLastFilters,
        carregarDadosContexto,
      }}
    >
      {children}
    </ContextFiltrosContasPagar.Provider>
  );
};

export const useContextFiltrosContaspagar = () => {
  const context = useContext(ContextFiltrosContasPagar);

  if (!context)
    throw new Error(
      'useContextFiltrosContaspagar must be used within a useContextFiltrosContaspagar.'
    );

  return context;
};
