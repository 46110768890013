import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Col, Container } from 'react-bootstrap';
import { BootstrapNumberInput } from 'components/update/Input/BootstrapNumberInput';
import { useFormContext } from 'react-hook-form';
import { useNotaFiscalFormularioContext } from 'store/NotaFiscal/NotaFiscalFormulario';

interface UncontrolledFormInterface {
  readonly?: boolean;
  isVisualizar?: boolean;
}

export const Totais = forwardRef(
  ({ readonly, isVisualizar }: UncontrolledFormInterface) => {
    const { t } = useTranslation();

    const {
      control: parentControl,
      formState: { errors: parentErrors },
    } = useFormContext();

    const {
      calcularTotais,
      calcularTotalNota,
      calculoManualTotalNota,
      setCalculoManualTotalNota,
    } = useNotaFiscalFormularioContext();

    return (
      <Container fluid>
        <Form.Row>
          <Col md={3} xl={2}>
            <BootstrapNumberInput
              id="icmsFcpValorTotal"
              name="icmsFcpValorTotal"
              label={t('ICMS FCP')}
              precision={12}
              scale={2}
              defaultValue={0}
              leftElement="R$"
              control={parentControl}
              error={parentErrors.icmsFcpValorTotal}
              disabled
            />
          </Col>
          <Col md={3} xl={2}>
            <BootstrapNumberInput
              id="icmsStFcpValorTotal"
              name="icmsStFcpValorTotal"
              label={t('ICMS FCP ST')}
              precision={12}
              scale={2}
              defaultValue={0}
              leftElement="R$"
              control={parentControl}
              error={parentErrors.IcmsStFcpValorTotal}
              disabled
            />
          </Col>
          <Col md={3} xl={2}>
            <BootstrapNumberInput
              id="icmsStFcpRetidoValorTotal"
              name="icmsStFcpRetidoValorTotal"
              label={t('ICMS FCP ST retido')}
              precision={12}
              scale={2}
              defaultValue={0}
              leftElement="R$"
              control={parentControl}
              error={parentErrors.icmsStFcpRetidoValorTotal}
              disabled
            />
          </Col>
          <Col md={3} xl={2}>
            <BootstrapNumberInput
              id="pisValorTotal"
              name="pisValorTotal"
              label={t('PIS')}
              precision={12}
              scale={2}
              defaultValue={0}
              leftElement="R$"
              control={parentControl}
              error={parentErrors.pisValorTotal}
              disabled
            />
          </Col>
          <Col md={3} xl={2}>
            <BootstrapNumberInput
              id="cofinsValorTotal"
              name="cofinsValorTotal"
              label={t('Cofins')}
              precision={12}
              scale={2}
              defaultValue={0}
              leftElement="R$"
              control={parentControl}
              error={parentErrors.cofinsValorTotal}
              disabled
            />
          </Col>
          <Col md={3} xl={2}>
            <BootstrapNumberInput
              id="ipiDevolucaoValorTotal"
              name="ipiDevolucaoValorTotal"
              label={t('IPI devolução')}
              precision={12}
              scale={2}
              defaultValue={0}
              leftElement="R$"
              control={parentControl}
              error={parentErrors.ipiDevolucaoValorTotal}
              disabled
            />
          </Col>
          <Col md={3} xl={2}>
            <BootstrapNumberInput
              id="valorTotalTributos"
              name="valorTotalTributos"
              label={t('Total de tributos')}
              precision={12}
              scale={2}
              defaultValue={0}
              leftElement="R$"
              control={parentControl}
              error={parentErrors.valorTotalTributos}
              disabled
            />
          </Col>
          <Col md={3} xl={2}>
            <BootstrapNumberInput
              id="icmsDesoneradoValorTotal"
              name="icmsDesoneradoValorTotal"
              label={t('ICMS desonerado')}
              precision={12}
              scale={2}
              defaultValue={0}
              leftElement="R$"
              control={parentControl}
              error={parentErrors.icmsDesoneradoValorTotal}
              disabled
            />
          </Col>
          <Col md={3} xl={2}>
            <BootstrapNumberInput
              id="fcpUfDestinoValorTotal"
              name="fcpUfDestinoValorTotal"
              label={t('FCP UF destino')}
              precision={12}
              scale={2}
              defaultValue={0}
              leftElement="R$"
              control={parentControl}
              error={parentErrors.fcpUfDestinoValorTotal}
              disabled={isVisualizar}
            />
          </Col>
          <Col md={3} xl={2}>
            <BootstrapNumberInput
              id="icmsFcpDestinoValorTotal"
              name="icmsFcpDestinoValorTotal"
              label={t('ICMS FCP UF destino')}
              precision={12}
              scale={2}
              defaultValue={0}
              leftElement="R$"
              control={parentControl}
              error={parentErrors.icmsFcpDestinoValorTotal}
              disabled={isVisualizar}
            />
          </Col>
          <Col md={3} xl={2}>
            <BootstrapNumberInput
              id="icmsFcpRemetenteValorTotal"
              name="icmsFcpRemetenteValorTotal"
              label={t('ICMS FCP UF remetente')}
              precision={12}
              scale={2}
              defaultValue={0}
              leftElement="R$"
              control={parentControl}
              error={parentErrors.icmsFcpRemetenteValorTotal}
              disabled={isVisualizar}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col xl={12}>
            <hr className="my-auto flex-grow-1" />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col md={3} xl={2}>
            <BootstrapNumberInput
              id="icmsBaseCalculo"
              name="icmsBaseCalculo"
              label={t('Base de cálculo ICMS')}
              precision={12}
              scale={3}
              defaultValue={0}
              leftElement="%"
              control={parentControl}
              error={parentErrors.icmsBaseCalculo}
              disabled
            />
          </Col>
          <Col md={3} xl={2}>
            <BootstrapNumberInput
              id="icmsValor"
              name="icmsValor"
              label={t('ICMS valor')}
              precision={12}
              scale={2}
              defaultValue={0}
              leftElement="R$"
              control={parentControl}
              error={parentErrors.icmsValor}
              disabled
            />
          </Col>
          <Col md={3} xl={2}>
            <BootstrapNumberInput
              id="icmsStBaseCalculo"
              name="icmsStBaseCalculo"
              label={t('Base de cálculo ICMS ST')}
              precision={12}
              scale={3}
              defaultValue={0}
              leftElement="%"
              control={parentControl}
              error={parentErrors.icmsStBaseCalculo}
              disabled
            />
          </Col>
          <Col md={3} xl={2}>
            <BootstrapNumberInput
              id="icmsStValor"
              name="icmsStValor"
              label={t('Valor ICMS ST')}
              precision={12}
              scale={2}
              defaultValue={0}
              leftElement="R$"
              control={parentControl}
              error={parentErrors.icmsStValor}
              disabled
            />
          </Col>
          <Col md={3} xl={2}>
            <BootstrapNumberInput
              id="valorProdutos"
              name="valorProdutos"
              label={t('Total dos produtos')}
              precision={12}
              scale={2}
              defaultValue={0}
              leftElement="R$"
              control={parentControl}
              error={parentErrors.valorProdutos}
              disabled
            />
          </Col>
          <Col md={3} xl={2}>
            <BootstrapNumberInput
              id="valorImpostoImportacao"
              name="valorImpostoImportacao"
              label={t('Imp. importação')}
              precision={12}
              scale={2}
              defaultValue={0}
              leftElement="R$"
              control={parentControl}
              error={parentErrors.valorImpostoImportacao}
              disabled
            />
          </Col>
          <Col md={3} xl={2}>
            <BootstrapNumberInput
              id="valorFrete"
              name="valorFrete"
              label={t('Frete')}
              precision={12}
              scale={2}
              defaultValue={0}
              leftElement="R$"
              control={parentControl}
              error={parentErrors.valorFrete}
              onBlur={() => calcularTotais()}
              disabled={isVisualizar}
            />
          </Col>
          <Col md={3} xl={2}>
            <BootstrapNumberInput
              id="valorSeguro"
              name="valorSeguro"
              label={t('Seguro')}
              precision={12}
              scale={2}
              defaultValue={0}
              leftElement="R$"
              control={parentControl}
              error={parentErrors.valorSeguro}
              disabled
            />
          </Col>
          <Col md={3} xl={2}>
            <BootstrapNumberInput
              id="valorDespesas"
              name="valorDespesas"
              label={t('Outras despesas')}
              precision={12}
              scale={2}
              defaultValue={0}
              leftElement="R$"
              control={parentControl}
              error={parentErrors.valorDespesas}
              disabled
            />
          </Col>
          <Col md={3} xl={2}>
            <BootstrapNumberInput
              id="valorDesconto"
              name="valorDesconto"
              label={t('Desconto')}
              precision={12}
              scale={2}
              defaultValue={0}
              leftElement="R$"
              control={parentControl}
              error={parentErrors.valorDesconto}
              disabled
            />
          </Col>
          <Col md={3} xl={2}>
            <BootstrapNumberInput
              id="valorIpi"
              name="valorIpi"
              label={t('Total IPI')}
              precision={12}
              scale={2}
              defaultValue={0}
              leftElement="R$"
              control={parentControl}
              error={parentErrors.valorIpi}
              disabled
            />
          </Col>
          <Col md={3} xl={2}>
            <BootstrapNumberInput
              id="valorTotal"
              name="valorTotal"
              label={t('Total da nota')}
              precision={12}
              scale={2}
              defaultValue={0}
              leftElement="R$"
              control={parentControl}
              error={parentErrors.valorTotal}
              disabled={readonly || !calculoManualTotalNota || isVisualizar}
              linkAction={
                !readonly
                  ? {
                      label: !calculoManualTotalNota
                        ? 'Informar manualmente'
                        : 'Cálculo automático',
                      onClick: () => {
                        if (calculoManualTotalNota) {
                          calcularTotalNota();
                        }
                        setCalculoManualTotalNota(!calculoManualTotalNota);
                      },
                    }
                  : undefined
              }
              required
            />
          </Col>
        </Form.Row>
      </Container>
    );
  }
);
