import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import {
  SmartPosEtapasContext,
  SmartPosEtapasProvider,
} from 'store/SmartPos/SmartPosEtapasContext';
import ConstanteRotas from 'constants/rotas';
import auth from 'modules/auth';
import enumReferenciaServicoStargate from 'constants/enum/referenciaServicoStargate';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { StepsSmartPos } from './StepsSmartPos';
import { LayoutEtapasSmartPos } from './Layout';

export const SmartPosEtapas = () => {
  const history = useHistory();

  const possuiPermissaoSmartPos = auth.possuiServico(
    enumReferenciaServicoStargate.DISPOSITIVO_SMART_POS
  ).permitido;

  useEffect(() => {
    if (!possuiPermissaoSmartPos) {
      toast.warning('Você não tem permissão para acessar essa função');
      history.push(ConstanteRotas.DASHBOARD);
    }
  }, [history, possuiPermissaoSmartPos]);

  return (
    <>
      <SmartPosEtapasProvider>
        <SmartPosEtapasContext.Consumer>
          {({ activeStep, isLoading }) => (
            <LayoutEtapasSmartPos>
              {isLoading && <LoadingPadrao />}
              <StepsSmartPos activeStep={activeStep} />
            </LayoutEtapasSmartPos>
          )}
        </SmartPosEtapasContext.Consumer>
      </SmartPosEtapasProvider>
    </>
  );
};
