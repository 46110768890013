import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useMemo,
} from 'react';
import {
  GridItem,
  Box,
  Text,
  Button,
  Icon,
  Flex,
  Tr,
  Td,
  Stack,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import StatusConsultaEnum from 'constants/enum/statusConsulta';
import auth from 'modules/auth';
import OptionType from 'types/optionType';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';
import ConstanteFuncionalidades from 'constants/permissoes';

import { SalvarInserirNovoIcon } from 'icons';
import Input from 'components/PDV/Input';
import Select from 'components/PDV/Select/SelectPadrao';
import { SimpleCard } from 'components/update/Form/SimpleCard';
import {
  LoadMoreRowsParams,
  VirtualizedInfiniteTable,
  TableHeader,
} from 'components/update/Table/VirtualizedInfiniteTable';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import Textarea from 'components/PDV/Textarea';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { ModalAdicionarProduto } from 'components/update/Modal/ModalAdicionarProduto';
import { ModalEditarQuantidadeProduto } from 'components/update/Modal/ModalEditarQuantidadeProduto';
import { ModalDivergenciaEstoque } from 'components/update/Modal/ModalDivergenciaEstoque';

type OperacaoItemAlterado = {
  operacaoItemId: string;
  quantidade: number;
};

type OptionResponseType = { id: string; nome: string };

type ListaLocalEstoque = {
  id: string;
  nome: string;
  padraoSistema: boolean;
};

type LojaDestinoOptionType = {
  id: string;
  fantasia: string;
  listaLocalEstoque: ListaLocalEstoque[];
};

export type ProdutoQuantidade = {
  operacaoItemId?: string;
  produtoCorTamanhoId?: string;
  quantidade: number;
};

export type ForwardRefProps = {
  produtos: ProdutoQuantidade[];
  handleSetProdutosError: () => void;
  operacoesItemExcluir: string[];
  operacoesItemAlterar: OperacaoItemAlterado[];
};

type ProdutoPaginadoRegistro = {
  operacaoItemId: string;
  produtoNome: string;
  cor: string;
  tamanho: string;
  quantidade: number;
  produtoCorTamanhoId?: string;
};

type ProdutoPaginado = {
  totalProdutos: number;
  totalItens: number;
  registros: ProdutoPaginadoRegistro[];
};

type Produto = {
  operacaoItemId?: string;
  produtoCorTamanhoId?: string;
  produtoNome: string;
  tamanhoDescricao?: string;
  corDescricao?: string;
  quantidade: number;
};

type DivergenciaEstoque = {
  operacaoItemId: string;
  nome: string;
  quantidade: number;
  saldo: number;
  itemKit: boolean;
};

interface FormularioProps {
  transferenciaEstoqueId?: string;
  isReadonly?: boolean;
  isCadastrar?: boolean;
  setIsPrenvented?: (isPrevented: boolean) => void;
}

const produtosTableHeaders: TableHeader[] = [
  { key: 'produto', content: 'Produtos', width: '50%' },
  { key: 'tamanho', content: 'Tamanho', width: '25%' },
  {
    key: 'quantidade',
    content: 'Quantidade',
    isNumeric: true,
    width: '25%',
  },
  {
    key: 'acoes',
    content: 'Ações',
    textAlign: 'right',
    minWidth: '120px',
  },
];

const Formulario = forwardRef<ForwardRefProps, FormularioProps>(
  (
    {
      isReadonly = false,
      transferenciaEstoqueId,
      setIsPrenvented,
      isCadastrar,
    },
    ref
  ) => {
    const { casasDecimais } = usePadronizacaoContext();
    const { id: lojaId, fantasia: lojaFantasia } = auth.getLoja();
    const { setValue, watch, getValues } = useFormContext();

    const permissaoTransferenciaEstoqueTransferirEntreLojas = auth.possuiPermissao(
      ConstanteFuncionalidades.TRANSFERENCIA_ESTOQUE_TRANSFERIR_ENTRE_LOJAS
    );

    const permissaoTransferenciaEstoqueTransferirEntreEstoques = auth.possuiPermissao(
      ConstanteFuncionalidades.TRANSFERENCIA_ESTOQUE_TRANSFERIR_ENTRE_ESTOQUES
    );

    const [destinoErrorText, setDestinoErrorText] = useState<string>();

    const [
      isLocalEstoqueOrigemOptionsLoading,
      setIsLocalEstoqueOrigemOptionsLoading,
    ] = useState(true);
    const [localEstoqueOrigemOptions, setLocalEstoqueOrigemOptions] = useState<
      OptionType[]
    >([]);

    const [
      isLojaDestinoOptionsLoading,
      setIsLojaDestinoOptionsLoading,
    ] = useState(true);
    const [lojaDestinoOptions, setLojaDestinoOptions] = useState<OptionType[]>(
      []
    );

    const [itemsTotalCount, setItemsTotalCount] = useState(0);
    const [quantidadeTotal, setQuantidadeTotal] = useState(0);
    const [operacoesItemAlterar, setOperacoesItemAlterar] = useState<
      OperacaoItemAlterado[]
    >([]);
    const [operacoesItemExcluir, setOperacoesItemExcluir] = useState<string[]>(
      []
    );
    const [produtos, setProdutos] = useState<Produto[]>([]);
    const [produtosHasError, setProdutoHasError] = useState(false);
    const [isProdutosLoading, setIsProdutosLoading] = useState(false);

    const lojaDestino = watch('lojaDestino');
    const localEstoqueOrigem = watch('localEstoqueOrigem');

    const usuarioRecebimento = watch('usuarioRecebimento');
    const dataRecebimento = watch('dataRecebimento');

    const localEstoqueDestinoOptions = useMemo(() => {
      if (lojaDestino && lojaDestino.listaLocalEstoque) {
        return lojaDestino.listaLocalEstoque
          .filter(
            (localEstoque: ListaLocalEstoque) =>
              localEstoque.id !== localEstoqueOrigem?.value
          )
          .map((localEstoque: ListaLocalEstoque) => ({
            value: localEstoque.id,
            label: localEstoque.nome,
          }));
      }

      return [];
    }, [localEstoqueOrigem, lojaDestino]);

    function handleRemoverProduto(index: number) {
      ModalConfirmacaoExcluir({
        callback: async (ok: boolean) => {
          if (ok) {
            if (setIsPrenvented) {
              setIsPrenvented(true);
            }

            const newProdutos = [...produtos];

            const { quantidade, operacaoItemId } = produtos[index];

            newProdutos.splice(index, 1);

            setProdutos(newProdutos);

            setItemsTotalCount((prev) => prev - 1);

            setQuantidadeTotal((prev) => prev - quantidade);

            if (operacaoItemId) {
              setOperacoesItemExcluir((prev) => [...prev, operacaoItemId]);
            }
          }
        },
      });
    }

    async function handleAdicionarProduto() {
      const {
        produtos: newProduto,
        deveReiniciar,
      } = await ModalAdicionarProduto({
        casasDecimaisQuantidade: casasDecimais.casasDecimaisQuantidade,
      });

      if (setIsPrenvented) {
        setIsPrenvented(!!newProduto && (newProduto || []).length > 0);
      }

      setProdutos((prev) => [...newProduto, ...prev]);
      setItemsTotalCount((prev) => prev + newProduto.length);
      setQuantidadeTotal(
        (prev) =>
          prev + newProduto.reduce((acc, curr) => acc + curr.quantidade, 0)
      );

      if (deveReiniciar) {
        handleAdicionarProduto();
      }
    }

    function atualizarQuantidadeProduto(index: number, newQuantidade: number) {
      const { quantidade, operacaoItemId } = produtos[index];

      const newProdutos = [...produtos];

      const oldProduto = produtos[index];

      newProdutos.splice(index, 1, {
        ...oldProduto,
        quantidade: newQuantidade,
      });

      setProdutos(newProdutos);

      setQuantidadeTotal((prev) => prev - quantidade + newQuantidade);

      if (operacaoItemId) {
        setOperacoesItemAlterar((prev) => [
          ...(prev || []),
          { operacaoItemId, quantidade: newQuantidade },
        ]);
      }
    }

    async function handleEditarQuantidadeProduto(index: number) {
      const {
        quantidade,
        produtoNome,
        corDescricao,
        tamanhoDescricao,
      } = produtos[index];

      const { quantidade: newQuantidade } = await ModalEditarQuantidadeProduto({
        casasDecimaisQuantidade: casasDecimais.casasDecimaisQuantidade,
        nomeProduto: produtoNome,
        corDescricao,
        tamanhoDescricao,
        quantidadeAtual: quantidade,
        volumeUnitario: false,
      });

      atualizarQuantidadeProduto(index, newQuantidade);
    }

    function handleSetProdutosError() {
      setProdutoHasError(true);

      handleAdicionarProduto();
    }

    const loadMoreRows = useCallback(
      async ({
        currentPage,
        pageSize,
        orderColumn,
        orderDirection,
      }: LoadMoreRowsParams) => {
        if (transferenciaEstoqueId) {
          setIsProdutosLoading(true);

          const paginationData = {
            currentPage,
            pageSize,
            orderColumn,
            orderDirection,
          };

          const response = await api.get<void, ResponseApi<ProdutoPaginado>>(
            formatQueryPagegTable(
              ConstanteEnderecoWebservice.TRANSFERENCIA_ESTOQUE_LISTAR_ITENS_PAGINADO,
              paginationData
            ),
            { params: { operacaoTransferenciaId: transferenciaEstoqueId } }
          );

          if (response) {
            if (response.sucesso) {
              setProdutos((prev) => [
                ...prev,
                ...((response.dados.registros || []).map((registro) => ({
                  operacaoItemId: registro.operacaoItemId,
                  produtoNome: registro.produtoNome,
                  corDescricao: registro.cor,
                  tamanhoDescricao: registro.tamanho,
                  quantidade: registro.quantidade,
                  produtoCorTamanhoId: registro.produtoCorTamanhoId || '',
                })) as Produto[]),
              ]);

              setItemsTotalCount(response.dados.totalProdutos || 0);
              setQuantidadeTotal(response.dados.totalItens || 0);
            }
          }
        }

        setIsProdutosLoading(false);
      },
      [transferenciaEstoqueId]
    );

    async function alterarItensDivergenciaEstoque(
      itens: OperacaoItemAlterado[]
    ) {
      const response = await api.put<void, ResponseApi>(
        ConstanteEnderecoWebservice.TRANSFERENCIA_ESTOQUE_ALTERAR_ITENS_DIVERGENTES,
        itens
      );

      if (response && response.sucesso) {
        toast.success('A quantidade dos itens foram alteradas com sucesso.');
        setProdutos([]);

        await loadMoreRows({
          currentPage: 1,
          pageSize: 10,
          orderColumn: 'produto',
          orderDirection: 'asc',
        });

        setOperacoesItemAlterar([]);
        setOperacoesItemExcluir([]);
      }

      if (response.avisos) {
        response.avisos.map((aviso: string) => toast.warning(aviso));
      }
    }

    async function handleVerificarAlgumItemNaoPossuiEstoqueDisp() {
      const response = await api.get<void, ResponseApi<DivergenciaEstoque[]>>(
        ConstanteEnderecoWebservice.TRANSFERENCIA_ESTOQUE_LISTAR_DIVERGENCIAS_ESTOQUE,
        { params: { transferenciaEstoqueId } }
      );

      if (response && response.sucesso) {
        if (response.dados.length === 0) {
          toast.success(
            'Todos os itens informados possuem estoque disponível.'
          );
        } else {
          const { transferencias } = await ModalDivergenciaEstoque({
            casasDecimaisQuantidade: casasDecimais.casasDecimaisQuantidade,
            transferencias: response.dados as any,
          });

          setIsProdutosLoading(true);

          const itens = Array<OperacaoItemAlterado>();

          transferencias.forEach((transferencia) => {
            const index = produtos
              ?.map((prod) => prod.operacaoItemId)
              .indexOf(transferencia.operacaoItemId);

            if (index > -1 && !transferencia.itemKit) {
              itens.push({
                operacaoItemId: transferencia.operacaoItemId,
                quantidade: transferencia.quantidade,
              });
            }
          });

          if (itens.length > 0) {
            alterarItensDivergenciaEstoque(itens);
          }
          setIsProdutosLoading(false);
        }
      }
    }

    const latestProps = useRef({
      setValue,
      transferenciaEstoqueId,
      permissaoTransferenciaEstoqueTransferirEntreLojasPermitido:
        permissaoTransferenciaEstoqueTransferirEntreLojas.permitido,
      permissaoTransferenciaEstoqueTransferirEntreEstoquesPermitido:
        permissaoTransferenciaEstoqueTransferirEntreEstoques.permitido,
      lojaId,
      lojaFantasia,
    });

    useEffect(() => {
      latestProps.current = {
        setValue,
        transferenciaEstoqueId,
        permissaoTransferenciaEstoqueTransferirEntreLojasPermitido:
          permissaoTransferenciaEstoqueTransferirEntreLojas.permitido,
        permissaoTransferenciaEstoqueTransferirEntreEstoquesPermitido:
          permissaoTransferenciaEstoqueTransferirEntreEstoques.permitido,
        lojaId,
        lojaFantasia,
      };
    });

    useEffect(() => {
      async function loadDefaultLocalEstoque(options: any[]) {
        setIsLocalEstoqueOrigemOptionsLoading(true);

        const response = await api.get<void, ResponseApi<string>>(
          ConstanteEnderecoWebservice.LOCAL_ESTOQUE_OBTER_PADRAO_SISTEMA
        );

        if (response) {
          if (response.avisos) {
            response.avisos.map((aviso: string) => toast.warning(aviso));
          }
          if (response.sucesso && response.dados) {
            const padraoSistemaOptions = options.find(
              (option) => option.value === response.dados
            );

            latestProps.current.setValue(
              'localEstoqueOrigem',
              padraoSistemaOptions
            );

            setIsLocalEstoqueOrigemOptionsLoading(false);

            return response.dados;
          }

          latestProps.current.setValue('localEstoqueOrigem', null);
        }

        setIsLocalEstoqueOrigemOptionsLoading(false);

        return undefined;
      }

      async function loadLocalEstoqueOptions() {
        setIsLocalEstoqueOrigemOptionsLoading(true);

        const params = {
          statusConsulta: StatusConsultaEnum.ATIVOS,
          lojaId: latestProps.current.lojaId,
        };

        const response = await api.get<void, ResponseApi<OptionResponseType[]>>(
          ConstanteEnderecoWebservice.LOCAL_ESTOQUE_LISTAR_SELECT,
          { params }
        );

        if (response) {
          if (response.avisos) {
            response.avisos.map((aviso: string) => toast.warning(aviso));
          }

          if (response.sucesso && response.dados) {
            const newEstoqueOptions = response.dados.map((option) => ({
              value: option.id,
              label: option.nome,
            }));

            setLocalEstoqueOrigemOptions(newEstoqueOptions);

            setIsLocalEstoqueOrigemOptionsLoading(false);

            return newEstoqueOptions;
          }
        }

        setIsLocalEstoqueOrigemOptionsLoading(false);

        return [];
      }

      async function loadLocalEstoque() {
        if (!latestProps.current.transferenciaEstoqueId) {
          const options = await loadLocalEstoqueOptions();

          const padraoSistemaLocalEstoqueId = await loadDefaultLocalEstoque(
            options
          );

          if (
            !latestProps.current
              .permissaoTransferenciaEstoqueTransferirEntreLojasPermitido &&
            latestProps.current
              .permissaoTransferenciaEstoqueTransferirEntreEstoquesPermitido
          ) {
            latestProps.current.setValue('lojaDestino', {
              value: latestProps.current.lojaId,
              label: latestProps.current.lojaFantasia,
              listaLocalEstoque: options.map((option) => ({
                id: option.value,
                nome: option.label,
                padraoSistema: option.value === padraoSistemaLocalEstoqueId,
              })),
            });
          }
        }
      }

      loadLocalEstoque();
    }, []);

    useEffect(() => {
      async function loadLojasDestinoOptions() {
        setIsLojaDestinoOptionsLoading(true);

        const params = {
          statusConsulta: StatusConsultaEnum.ATIVOS,
          lojaId: latestProps.current.lojaId,
        };

        const response = await api.get<
          void,
          ResponseApi<LojaDestinoOptionType[]>
        >(ConstanteEnderecoWebservice.LOJA_LISTAR_TRANSFERENCIA_ESTOQUE, {
          params,
        });

        if (response) {
          if (response.avisos) {
            response.avisos.map((aviso: string) => toast.warning(aviso));
          }

          if (response.sucesso && response.dados) {
            const newEstoqueOptions = response.dados
              .filter(
                (option) =>
                  latestProps.current
                    .permissaoTransferenciaEstoqueTransferirEntreEstoquesPermitido ||
                  option.id !== latestProps.current.lojaId
              )
              .map((option) => ({
                value: option.id,
                label: option.fantasia,
                listaLocalEstoque: option.listaLocalEstoque,
              }));

            setLojaDestinoOptions(newEstoqueOptions);

            setIsLojaDestinoOptionsLoading(false);

            return newEstoqueOptions;
          }
        }

        setIsLojaDestinoOptionsLoading(false);

        return [];
      }

      if (
        !latestProps.current.transferenciaEstoqueId &&
        latestProps.current
          .permissaoTransferenciaEstoqueTransferirEntreLojasPermitido
      ) {
        loadLojasDestinoOptions();
      } else {
        setIsLojaDestinoOptionsLoading(false);
      }
    }, []);

    useEffect(() => {
      if (
        !latestProps.current.transferenciaEstoqueId &&
        lojaDestino &&
        lojaDestino.listaLocalEstoque
      ) {
        const locaisEstoqueComuns = lojaDestino.listaLocalEstoque.filter(
          (localEstoque: ListaLocalEstoque) => !localEstoque.padraoSistema
        );

        const localEstoqueDestinoPadraoSistema = lojaDestino.listaLocalEstoque.find(
          (localEstoque: ListaLocalEstoque) => localEstoque.padraoSistema
        );

        if (
          localEstoqueDestinoPadraoSistema &&
          localEstoqueDestinoPadraoSistema.id !== localEstoqueOrigem?.value
        ) {
          setValue('localEstoqueDestino', {
            value: localEstoqueDestinoPadraoSistema.id,
            label: localEstoqueDestinoPadraoSistema.nome,
          });
        } else if (
          locaisEstoqueComuns.length === 1 &&
          locaisEstoqueComuns[0].id !== localEstoqueOrigem?.value
        ) {
          setValue('localEstoqueDestino', {
            value: locaisEstoqueComuns[0].id,
            label: locaisEstoqueComuns[0].nome,
          });
        }
      }
    }, [lojaDestino, localEstoqueOrigem, setValue]);

    useEffect(() => {
      if (!latestProps.current.transferenciaEstoqueId) {
        const currentLocalEstoqueDestino = getValues('localEstoqueDestino');

        if (
          currentLocalEstoqueDestino &&
          localEstoqueOrigem &&
          currentLocalEstoqueDestino.value === localEstoqueOrigem.value
        ) {
          setValue('localEstoqueDestino', null);
        }
      }
    }, [getValues, localEstoqueOrigem, setValue]);

    useEffect(() => {
      if (!latestProps.current.transferenciaEstoqueId) {
        if (
          !latestProps.current
            .permissaoTransferenciaEstoqueTransferirEntreLojasPermitido &&
          !latestProps.current
            .permissaoTransferenciaEstoqueTransferirEntreEstoquesPermitido
        ) {
          setDestinoErrorText(
            'Seu usuário não possui permissão para transferir entre lojas e nem para transferir entre estoques, o que impossibilita o cadastro de qualquer tipo de transferência de estoque.'
          );
        } else if (
          !latestProps.current
            .permissaoTransferenciaEstoqueTransferirEntreLojasPermitido &&
          localEstoqueOrigemOptions.length <= 1
        ) {
          setDestinoErrorText(
            'Seu usuário não possui permissão para transferir entre lojas e sua loja possui apenas 1 local de estoque, o que impossibilita a transferência entre estoques.'
          );
        } else if (
          !isLojaDestinoOptionsLoading &&
          lojaDestinoOptions.length === 0
        ) {
          setDestinoErrorText(
            'Sua loja possui apenas 1 local de estoque e seu usuário apenas 1 loja, o que impossibilita a transferência entre lojas e entre estoques.'
          );
        }
      }
    }, [
      isLojaDestinoOptionsLoading,
      localEstoqueOrigemOptions,
      lojaDestinoOptions,
    ]);

    useImperativeHandle(ref, () => ({
      produtos,
      handleSetProdutosError,
      operacoesItemExcluir,
      operacoesItemAlterar,
    }));

    return (
      <SimpleCard>
        <SimpleGridForm>
          <GridItem colSpan={{ base: 12, md: 6 }}>
            <Text
              as="label"
              lineHeight="none"
              mb="1"
              fontSize="sm"
              fontWeight="semibold"
            >
              Origem
            </Text>
            <Box
              borderRadius="md"
              bg="gray.50"
              border="1px"
              borderColor="gray.100"
              p={{ base: 4, sm: 6, md: 8 }}
            >
              <SimpleGridForm>
                <GridItem colSpan={12}>
                  <Input
                    id="lojaOrigem"
                    name="lojaOrigem"
                    label="Loja"
                    placeholder="Loja de origem"
                    isDisabled
                    {...(isReadonly ? {} : { _disabled: { bg: 'gray.50' } })}
                  />
                </GridItem>
                <GridItem colSpan={12}>
                  <Select
                    id="localEstoqueOrigem"
                    name="localEstoqueOrigem"
                    label="Local do estoque"
                    placeholder="Selecionar"
                    asControlledByObject
                    options={localEstoqueOrigemOptions}
                    isLoading={isLocalEstoqueOrigemOptionsLoading}
                    isDisabled={
                      isReadonly ||
                      isLocalEstoqueOrigemOptionsLoading ||
                      localEstoqueOrigemOptions.length <= 1
                    }
                    required
                  />
                </GridItem>
              </SimpleGridForm>
            </Box>
          </GridItem>
          <GridItem colSpan={{ base: 12, md: 6 }}>
            <Text
              as="label"
              lineHeight="none"
              mb="1"
              fontSize="sm"
              fontWeight="semibold"
            >
              Destino
            </Text>
            <Box
              borderRadius="md"
              bg="gray.50"
              border="1px"
              borderColor="gray.100"
              p={{ base: 4, sm: 6, md: 8 }}
            >
              {destinoErrorText ? (
                <Text>{destinoErrorText}</Text>
              ) : (
                <SimpleGridForm>
                  <GridItem colSpan={12}>
                    <Select
                      id="lojaDestino"
                      name="lojaDestino"
                      label="Loja"
                      placeholder="Selecionar"
                      asControlledByObject
                      options={
                        localEstoqueOrigemOptions.length > 1
                          ? lojaDestinoOptions
                          : lojaDestinoOptions.filter(
                              (option) => option.value !== lojaId
                            )
                      }
                      isLoading={isLojaDestinoOptionsLoading}
                      isDisabled={
                        isReadonly ||
                        isLojaDestinoOptionsLoading ||
                        !permissaoTransferenciaEstoqueTransferirEntreLojas.permitido
                      }
                      required
                    />
                  </GridItem>
                  <GridItem colSpan={12}>
                    <Select
                      id="localEstoqueDestino"
                      name="localEstoqueDestino"
                      label="Local do estoque"
                      placeholder="Selecionar"
                      asControlledByObject
                      options={localEstoqueDestinoOptions}
                      isDisabled={
                        isReadonly ||
                        isLojaDestinoOptionsLoading ||
                        localEstoqueDestinoOptions.length === 0
                      }
                      required
                    />
                  </GridItem>
                </SimpleGridForm>
              )}
            </Box>
          </GridItem>
          <GridItem colSpan={12}>
            <Box w="full">
              <Text
                as="label"
                lineHeight="none"
                mb="1"
                fontSize="sm"
                fontWeight="semibold"
              >
                Lista de produtos
              </Text>
              <Box
                borderRadius="md"
                bg="gray.50"
                border="1px"
                borderColor="gray.100"
                p={{ base: 4, sm: 6, md: 8 }}
              >
                <Stack
                  direction={{ base: 'column-reverse', sm: 'row' }}
                  justifyContent={isReadonly ? 'flex-end' : 'space-between'}
                  mb="4"
                >
                  {!isReadonly && (
                    <Button
                      borderRadius="md"
                      colorScheme="secondary"
                      leftIcon={
                        <Icon as={SalvarInserirNovoIcon} fontSize="lg" />
                      }
                      onClick={handleAdicionarProduto}
                    >
                      Adicionar itens
                    </Button>
                  )}

                  <VStack alignItems="flex-end" spacing="1">
                    <HStack>
                      <Text lineHeight="none">Total de produtos:</Text>
                      <Text lineHeight="none" fontWeight="bold">
                        {itemsTotalCount.toLocaleString('pt-BR', {
                          minimumFractionDigits:
                            casasDecimais.casasDecimaisQuantidade,
                          maximumFractionDigits:
                            casasDecimais.casasDecimaisQuantidade,
                        })}
                      </Text>
                    </HStack>
                    <HStack>
                      <Text lineHeight="none">Quantidade de itens:</Text>
                      <Text lineHeight="none" fontWeight="bold">
                        {quantidadeTotal.toLocaleString('pt-BR', {
                          minimumFractionDigits:
                            casasDecimais.casasDecimaisQuantidade,
                          maximumFractionDigits:
                            casasDecimais.casasDecimaisQuantidade,
                        })}
                      </Text>
                    </HStack>
                  </VStack>
                </Stack>
                <VirtualizedInfiniteTable
                  isLoading={isProdutosLoading}
                  editarWidht
                  alterarBordaListagem="white"
                  withoutRowsMessage={
                    produtosHasError
                      ? `Adicione ao menos um produto para ${
                          transferenciaEstoqueId ? 'salvar' : 'cadastrar'
                        } a transferência.`
                      : 'Nenhum produto informado.'
                  }
                  orderColumn="produto"
                  tableHeaders={produtosTableHeaders}
                  rowRenderer={({
                    index,
                    style: { height, ...restStyle },
                    key,
                  }) => {
                    const produto = produtos[index];

                    if (!produto) {
                      return null;
                    }

                    return (
                      <Tr key={key} style={restStyle}>
                        <Td width={produtosTableHeaders[0].width}>
                          <Text lineHeight="0" opacity={0}>
                            {produtosTableHeaders[0].content}
                          </Text>

                          {`${produto.produtoNome} ${produto.corDescricao}`}
                        </Td>
                        <Td width={produtosTableHeaders[1].width}>
                          <Text lineHeight="0" opacity={0}>
                            {produtosTableHeaders[1].content}
                          </Text>

                          {produto.tamanhoDescricao || '-'}
                        </Td>
                        <Td width={produtosTableHeaders[2].width} isNumeric>
                          <Text lineHeight="0" opacity={0}>
                            {produtosTableHeaders[2].content}
                          </Text>

                          {produto.quantidade.toLocaleString('pt-BR', {
                            minimumFractionDigits:
                              casasDecimais.casasDecimaisQuantidade,
                            maximumFractionDigits:
                              casasDecimais.casasDecimaisQuantidade,
                          })}
                        </Td>
                        <Td minW={produtosTableHeaders[3].minWidth}>
                          <Flex justifyContent="flex-end">
                            <Text lineHeight="0" opacity={0}>
                              {produtosTableHeaders[3].content}
                            </Text>

                            <ActionsMenu
                              isDisabled={isReadonly}
                              items={
                                isReadonly
                                  ? []
                                  : [
                                      {
                                        content: 'Editar quantidade',
                                        onClick: () =>
                                          handleEditarQuantidadeProduto(index),
                                      },
                                      {
                                        content: 'Remover',
                                        onClick: () =>
                                          handleRemoverProduto(index),
                                      },
                                    ]
                              }
                            />
                          </Flex>
                        </Td>
                      </Tr>
                    );
                  }}
                  rowCount={itemsTotalCount}
                  isRowLoaded={({ index }) => !!produtos[index]}
                  loadMoreRows={loadMoreRows}
                />
                {!isReadonly && transferenciaEstoqueId && !isCadastrar && (
                  <Button
                    mt="4"
                    fontSize="sm"
                    fontWeight="semibold"
                    variant="link"
                    cursor="pointer"
                    onClick={handleVerificarAlgumItemNaoPossuiEstoqueDisp}
                  >
                    Clique aqui para verificar se algum item não possui estoque
                    disponível.
                  </Button>
                )}
              </Box>
            </Box>
          </GridItem>
          <GridItem colSpan={{ base: 12, md: 6 }} rowSpan={2}>
            <Textarea
              id="motivo"
              name="motivo"
              label="Motivo"
              placeholder="Descreva o motivo da transferência"
              rows={5}
              resize="none"
              isDisabled={isReadonly}
            />
          </GridItem>
          <GridItem colSpan={{ base: 12, sm: 6, md: 3 }}>
            <Input
              id="dataEmissao"
              name="dataEmissao"
              label="Data de transferência"
              placeholder="00/00/0000"
              isDisabled
              {...(isReadonly ? {} : { _disabled: { bg: 'gray.50' } })}
            />
          </GridItem>
          <GridItem colSpan={{ base: 12, sm: 6, md: 3 }}>
            <Input
              id="usuario"
              name="usuario"
              label="Usuário"
              placeholder="Nome do usuário"
              isDisabled
              {...(isReadonly ? {} : { _disabled: { bg: 'gray.50' } })}
            />
          </GridItem>
          {dataRecebimento && (
            <GridItem colSpan={{ base: 12, sm: 6, md: 3 }}>
              <Input
                id="dataRecebimento"
                name="dataRecebimento"
                label="Data de confirmação"
                placeholder="00/00/0000"
                isDisabled
                {...(isReadonly ? {} : { _disabled: { bg: 'gray.50' } })}
              />
            </GridItem>
          )}
          {usuarioRecebimento && (
            <GridItem colSpan={{ base: 12, sm: 6, md: 3 }}>
              <Input
                id="usuarioRecebimento"
                name="usuarioRecebimento"
                label="Usuário de confirmação"
                placeholder="Nome do usuário de confirmação"
                isDisabled
                {...(isReadonly ? {} : { _disabled: { bg: 'gray.50' } })}
              />
            </GridItem>
          )}
        </SimpleGridForm>
      </SimpleCard>
    );
  }
);

export default Formulario;
