import { Route } from 'react-router-dom';

import ConstanteRotas from 'constants/rotas';
import { TrayPainelControleProvider } from 'store/Tray/PainelControle';

import { VendasTray } from 'pages/Integracoes/LojaAplicativos/TrayCommerce/PainelAdministradorTray/VendasTray';
import { ConfiguracaoTray } from 'pages/Integracoes/LojaAplicativos/TrayCommerce/PainelAdministradorTray/ConfiguracaoTray';
import { PainelAdministradorTray } from 'pages/Integracoes/LojaAplicativos/TrayCommerce/PainelAdministradorTray';
import { TrayComercial } from 'pages/Integracoes/LojaAplicativos/TrayCommerce';
import { ProdutosTray } from 'pages/Integracoes/LojaAplicativos/TrayCommerce/PainelAdministradorTray/ProdutosTray';
import { FormaRecebimentoTray } from 'pages/Integracoes/LojaAplicativos/TrayCommerce/PainelAdministradorTray/FormaRecebimentoTray';
import { DetalhesIntegracaoTray } from 'pages/Integracoes/LojaAplicativos/TrayCommerce/DetalhesIntegracaoTray';

import LayoutGuard from './LayoutGuard';

export const PainelAdmTrayRoutes = [
  <Route path="/integracoes-tray">
    <LayoutGuard
      key="tela-comercial-tray"
      component={TrayComercial}
      path={['', ConstanteRotas.INTEGRACAO_TRAY_TELA_COMERCIAL]}
      exact
      breadcrumb={[
        {
          title: 'Integrações',
          path: ConstanteRotas.LOJA_APLICATIVOS,
        },
        {
          title: 'Tray Commerce',
        },
      ]}
      meta={{ auth: true }}
    />
    <LayoutGuard
      key="tela-comercial-tray"
      component={DetalhesIntegracaoTray}
      path={['', ConstanteRotas.INTEGRACAO_TRAY_DETALHES]}
      exact
      breadcrumb={[
        {
          title: 'Integrações',
          path: ConstanteRotas.LOJA_APLICATIVOS,
        },
        {
          title: 'Tray',
        },
      ]}
      meta={{ auth: true }}
    />
    <TrayPainelControleProvider>
      <LayoutGuard
        key="vendas-tray"
        component={VendasTray}
        path={['', ConstanteRotas.INTEGRACAO_TRAY_COMMERCE_VENDAS]}
        exact
        breadcrumb={[
          {
            title: 'Integrações',
            path: ConstanteRotas.LOJA_APLICATIVOS,
          },
          {
            title: 'Tray Commerce',
            path: ConstanteRotas.INTEGRACAO_TRAY_COMMERCE_PAINEL_ADM,
          },
          {
            title: 'Vendas',
          },
        ]}
        meta={{ auth: true }}
      />
      <LayoutGuard
        key="painel-comercial-tray"
        component={PainelAdministradorTray}
        path={ConstanteRotas.INTEGRACAO_TRAY_COMMERCE_PAINEL_ADM}
        exact
        breadcrumb={[
          {
            title: 'Integrações',
            path: ConstanteRotas.LOJA_APLICATIVOS,
          },
          {
            title: 'Tray Commerce',
          },
        ]}
        meta={{ auth: true }}
      />
      <LayoutGuard
        key="forma-recebimento-tray"
        component={FormaRecebimentoTray}
        path={ConstanteRotas.INTEGRACAO_TRAY_COMMERCE_FORMA_RECEBIMENTO}
        exact
        breadcrumb={[
          {
            title: 'Integrações',
            path: ConstanteRotas.LOJA_APLICATIVOS,
          },
          {
            title: 'Tray Commerce',
            path: ConstanteRotas.INTEGRACAO_TRAY_COMMERCE_PAINEL_ADM,
          },
          {
            title: 'Forma recebimento',
          },
        ]}
        meta={{ auth: true }}
      />
      <LayoutGuard
        key="configuracao-tray"
        component={ConfiguracaoTray}
        path={ConstanteRotas.INTEGRACAO_TRAY_COMMERCE_CONFIGURACAO}
        exact
        breadcrumb={[
          {
            title: 'Integrações',
            path: ConstanteRotas.LOJA_APLICATIVOS,
          },
          {
            title: 'Tray Commerce',
            path: ConstanteRotas.INTEGRACAO_TRAY_COMMERCE_PAINEL_ADM,
          },
          {
            title: 'Configuração',
          },
        ]}
        meta={{ auth: true }}
      />
      <LayoutGuard
        key="produto-tray"
        component={ProdutosTray}
        path={ConstanteRotas.INTEGRACAO_TRAY_COMMERCE_PRODUTO}
        exact
        breadcrumb={[
          {
            title: 'Integrações',
            path: ConstanteRotas.LOJA_APLICATIVOS,
          },
          {
            title: 'Tray Commerce',
            path: ConstanteRotas.INTEGRACAO_TRAY_COMMERCE_PAINEL_ADM,
          },
          {
            title: 'Produtos',
          },
        ]}
        meta={{ auth: true }}
      />
    </TrayPainelControleProvider>
  </Route>,
];
