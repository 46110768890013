import { SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';

interface ReloadRegistrationProps {
  setFormIsDirty?: React.Dispatch<SetStateAction<boolean>>;
}

function useReloadRegistration({ setFormIsDirty }: ReloadRegistrationProps) {
  const history = useHistory();

  const historyReload = (route: string) => {
    if (setFormIsDirty) setFormIsDirty(false);
    history.replace('/');
    history.replace(route);
  };
  return historyReload;
}
export default useReloadRegistration;
