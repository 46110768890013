import React, { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Col, Container, Button } from 'react-bootstrap';
import ButtonComOpcoes from 'components/Button/ButtonComOpcoes';
import InputPadrao from 'components/Input/InputPadrao';
import TextAreaPadrao from 'components/TextArea/TextAreaPadrao';
import TableCampoFormularioPadrao from 'components/Table/TableCampoFormularioPadrao';
import { useFormContext } from 'react-hook-form';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import BaseLegalModal from './BaseLegalModal';

interface UncontrolledFormInterface {
  readonly?: boolean;
  isVisualizar?: boolean;
}

export const DadosAdicionais = forwardRef(
  ({ readonly, isVisualizar }: UncontrolledFormInterface) => {
    const { t } = useTranslation();
    const [showBaseLegalModal, setShowBaseLegalModal] = useState(false);

    const {
      getValues: parentGetValues,
      setValue: parentSetValue,
      control: parentControl,
      register: parentRegister,
      formState: { errors: parentErrors },
    } = useFormContext();

    const removerItemList = (index: number) => {
      ModalConfirmacaoExcluir({
        callback: async (ok: boolean) => {
          if (ok) {
            const items = parentGetValues('basesLegais') as Array<any>;
            items.splice(index, 1);

            parentSetValue('basesLegais', [...items]);
          }
        },
        text: '',
      });
    };

    return (
      <>
        <Container fluid>
          <Form.Row>
            <Col xl={12}>
              {!isVisualizar && (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    variant="link"
                    style={{ fontSize: '14px' }}
                    onClick={() => setShowBaseLegalModal(true)}
                  >
                    Adicionar base legal
                  </Button>
                </div>
              )}

              <TableCampoFormularioPadrao
                fieldName="basesLegais"
                defaultColumnOrder=""
                headerColumns={[
                  {
                    name: 'baseLegal',
                    displayName: 'Base legal',
                    isOrderable: false,
                    width: '',
                  },
                  {
                    name: 'acoes',
                    displayName: isVisualizar ? '' : 'Ações',
                    isOrderable: false,
                    width: isVisualizar ? '0px' : '38px',
                  },
                ]}
                emptyValuesText="Nenhuma base legal adicionada para esta nota"
                control={parentControl}
                isLoading={false}
              >
                {({ tableValues }) => (
                  <>
                    {tableValues &&
                      tableValues.map((tableValue, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <tr key={index}>
                          <td>{tableValue.descricao}</td>
                          {!isVisualizar && (
                            <td>
                              <ButtonComOpcoes
                                id="mostrarMais"
                                dropdownItems={[
                                  {
                                    title: t('Remover'),
                                    onClick: () => removerItemList(index),
                                  },
                                ]}
                              />
                            </td>
                          )}
                        </tr>
                      ))}
                  </>
                )}
              </TableCampoFormularioPadrao>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xl={12}>
              <InputPadrao
                type="text"
                id="dadosAdicionaisTributos"
                name="dadosAdicionaisTributos"
                label="Dados adicionais dos tributos"
                defaultValue=""
                error={parentErrors.numero}
                disabled
                control={parentControl}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xl={12}>
              <TextAreaPadrao
                id="dadosAdicionais"
                label={t('Dados adicionais')}
                error={parentErrors.dadosAdicionais}
                disabled={readonly || isVisualizar}
                rows={5}
                {...parentRegister('dadosAdicionais')}
              />
            </Col>
          </Form.Row>
        </Container>
        <BaseLegalModal
          show={showBaseLegalModal}
          onHide={() => setShowBaseLegalModal(false)}
        />
      </>
    );
  }
);
