import { useState, useRef, useCallback } from 'react';
import {
  Box,
  Flex,
  GridItem,
  Icon,
  Text,
  Tr,
  Td,
  Collapse,
  Checkbox,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { FiChevronLeft } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import ConstanteRotas from 'constants/rotas';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { useTrayPainelControleContext } from 'store/Tray/PainelControle';
import { moneyMask } from 'helpers/format/fieldsMasks';
import { shallowEqual } from 'helpers/validation/shallowEqual';
import StatusConsultaEnum from 'constants/enum/statusConsulta';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import auth from 'modules/auth';

import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { ModalAlterarProduto } from 'pages/EntradaMercadoria/EtapasCompartilhadas/ModalAlterarProduto';
import { ModalDesistencia } from 'components/Modal/ModalDesistencia';
import { SearchInput } from 'components/update/Input/SearchInput';
import { BuscaAvancadaButton } from 'components/update/BuscaAvancadaButton';
import { ContainerHeader } from 'pages/Integracoes/LojaAplicativos/Layout/ContainerHeader';
import { PainelTrayIcon } from 'icons';
import {
  PagedTable,
  PagedTableForwardRefData,
} from 'components/update/Table/PagedTable';
import { PaginationData } from 'components/update/Pagination';
import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import { StatusCircle } from 'components/update/Table/StatusCircle';

import { DrawerBuscaAvancada } from './DrawerBuscaAvancada';
import { defaultValue, FiltrosProps } from './validationForms';

type PrecoProps = {
  produtoPrecoLojas: {
    lojaId: string;
    precoVenda: {
      precoVenda: number;
    };
  }[];
};

type ProdutoCorTamanhosEstoqueProps = {
  estoqueAtual: number;
};

type ProdutoCoresProps = {
  produtoCorTamanhos: {
    produtoCorTamanhoEstoques: ProdutoCorTamanhosEstoqueProps[];
  }[];
};

type ListarProdutosProps = {
  id: string;
  nome: string;
  precoVenda: number;
  estoque: number;
  referencia: string;
  tipoProduto: number;
  ativo: boolean;
  isChecked: boolean;
  precos: PrecoProps;
  produtoCores: ProdutoCoresProps[];
};

export const ProdutosTray = () => {
  const [listarProduto, setListarProduto] = useState<ListarProdutosProps[]>([]);
  const [isEdicaoEmMassa, setIsEdicaoEmMassa] = useState(false);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenBuscaAvancada, setIsOpenBuscaAvancada] = useState(false);
  const [selecionarTodosProdutos, setSelecionarTodosProdutos] = useState(false);
  const [filtros, setFiltros] = useState<FiltrosProps>(defaultValue);

  const { nomeCanalVenda } = useTrayPainelControleContext();

  const pageIsLoaded = useRef(false);
  const pagedTableRef = useRef<PagedTableForwardRefData>(null);

  const isMountedRef = useIsMountedRef();

  const { id: lojaId } = auth.getLoja();

  const formMethods = useForm<FiltrosProps>({
    defaultValues: {
      statusConsulta: StatusConsultaEnum.status[0].value,
    },
  });

  const {
    handleSubmit,
    formState: { isDirty },
  } = formMethods;

  const history = useHistory();

  const hasProdutoSelecionado = listarProduto.some(
    (produto) => produto.isChecked
  );

  const listProdutoSelecionado = listarProduto.filter(
    (produto) => produto.isChecked
  );

  const amountProdutoSelecionado = listProdutoSelecionado.length;

  const handleEditarProduto = async (id: string) => {
    const { sucesso } = await ModalAlterarProduto({
      valueIdProduto: id,
    });
    if (sucesso) {
      pagedTableRef.current?.reload();
    }
  };

  const excluirProduto = useCallback(async (produtoId: string) => {
    const response = await api.put<void, ResponseApi<boolean>>(
      ConstanteEnderecoWebservice.INTEGRACAO_TRAY_EXCLUIR_PRODUTO,
      {
        produtoId,
      }
    );
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((avisos) => toast.warning(avisos));
      }

      if (response.sucesso) {
        toast.success('Produto foi excluido com sucesso');
        pagedTableRef.current?.reload();
      }
    }
  }, []);

  const bloquearProduto = useCallback(async (produtoId: string) => {
    const response = await api.put<void, ResponseApi<boolean>>(
      ConstanteEnderecoWebservice.INTEGRACAO_TRAY_BLOQUEAR_PRODUTO,
      {
        produtoId,
      }
    );
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((avisos) => toast.warning(avisos));
      }

      if (response.sucesso) {
        toast.success('Produto foi bloqueado com sucesso');
        pagedTableRef.current?.reload();
      }
    }
  }, []);

  const handleExcluirProduto = useCallback(
    (produtoId: string) => {
      ModalDesistencia({
        callback: async () => {
          setIsLoading(true);

          await excluirProduto(produtoId);

          setIsLoading(false);
        },
        labelButtonConfirmar: 'Sim, excluir',
        title: 'Tem certeza?',
        labelButtonCancelar: 'Cancelar',
        colorButtonConfirm: 'black',
        textoMensagem:
          'Após realizar essa ação esse produto será removido da Tray',
      });
    },
    [excluirProduto]
  );

  const handleBloquearProduto = useCallback(
    (produtoId: string) => {
      ModalDesistencia({
        callback: async () => {
          setIsLoading(true);

          await bloquearProduto(produtoId);

          setIsLoading(false);
        },
        labelButtonConfirmar: 'Sim, bloquear',
        title: 'Tem certeza?',
        labelButtonCancelar: 'Cancelar',
        colorButtonConfirm: 'black',
        textoMensagem:
          'Após realizar essa ação esse produto será inativado da Tray',
      });
    },
    [bloquearProduto]
  );

  const handleToggleSelecionarTodosProdutos = useCallback(() => {
    setSelecionarTodosProdutos(!selecionarTodosProdutos);

    setListarProduto((prev) =>
      prev.map((itemProduto) => ({
        ...itemProduto,
        isChecked: !selecionarTodosProdutos,
      }))
    );
  }, [selecionarTodosProdutos]);

  const handleToggleLimparSelecao = useCallback(() => {
    setSelecionarTodosProdutos(false);

    setListarProduto((prev) =>
      prev.map((itemProduto) => ({
        ...itemProduto,
        isChecked: false,
      }))
    );
  }, []);

  const handleToggleSelecionarProduto = useCallback((index: number) => {
    setListarProduto((prev) =>
      prev.map((itemProduto, indexProduto) => ({
        ...itemProduto,
        isChecked:
          indexProduto === index
            ? !itemProduto.isChecked
            : itemProduto.isChecked,
      }))
    );
  }, []);

  const handleReset = handleSubmit((data) => {
    const filtersIsDirty = !shallowEqual(data, filtros || {});

    if (filtersIsDirty) {
      setFiltros(data);
    }
  });

  const filtersSubmit = useCallback((filters: FiltrosProps) => {
    setFiltros(filters);
  }, []);

  const paginationHandle = useCallback(
    async (gridPaginadaConsulta: PaginationData) => {
      setIsLoading(true);
      const response = await api.post<
        void,
        ResponseApi<GridPaginadaRetorno<ListarProdutosProps>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.INTEGRACAO_TRAY_OBTER_LISTA_PRODUTO_CADASTRADO_PAGINADA,
          gridPaginadaConsulta
        ),
        {
          ...filtros,
        }
      );

      if (response?.sucesso && isMountedRef.current) {
        setTotalRegistros(response.dados.total);
        setListarProduto(
          response.dados.registros.map((itemProduto) => ({
            ...itemProduto,
            isChecked: false,
          }))
        );
        setIsLoading(false);
      }

      if (isMountedRef.current) {
        setIsLoading(false);

        if (!pageIsLoaded.current) {
          pageIsLoaded.current = true;
        }
      }
    },
    [isMountedRef, filtros]
  );

  function handleVoltarPainelAdm() {
    history.push(ConstanteRotas.INTEGRACAO_TRAY_COMMERCE_PAINEL_ADM);
  }

  return (
    <FormProvider {...formMethods}>
      <Box mb="16px" borderTop="1px" borderColor="primary.50" />
      <SimpleGridForm pl={['24px', '24px', '44px']} pr="24px" mb="16px">
        <GridItem colSpan={[12, 12, 5]}>
          <Flex alignItems="center">
            <Flex cursor="pointer" mr="10px" onClick={handleVoltarPainelAdm}>
              <Icon fontSize="25px" as={FiChevronLeft} />
            </Flex>
            <SearchInput
              type="search"
              placeholder="Buscar produto "
              onEnterKeyPress={() => handleReset()}
              id="nomeReferencia"
              name="nomeReferencia"
              isRightElement
              isPostionCodigoBarrasRight
            />
          </Flex>
        </GridItem>
        <GridItem colSpan={[12, 12, 3, 2]}>
          <BuscaAvancadaButton
            borderRadius="full"
            height="32px"
            bg="none"
            hasIcon={false}
            color="gray.500"
            setIsModalBuscaAvancadaOpen={() => {
              setIsOpenBuscaAvancada(true);
            }}
            colorScheme={isDirty ? 'blue' : 'gray'}
            variant={isDirty ? 'solid' : 'outlineDefault'}
            hasFilters={isDirty}
            padding="10px 24px"
            justifyContent="center"
            minW={['150px', '150px', '180', '188px']}
            width={['full', 'full', '180', '188px']}
          >
            {isDirty ? 'Filtros selecionados' : 'Busca avançada'}
          </BuscaAvancadaButton>
        </GridItem>
        {/*   <GridItem colSpan={[12, 12, 2, 2]}>
          <Menu>
            {({ isOpen }) => (
              <>
                <MenuButton
                  variant="outlineDefault"
                  bg="transparent"
                  borderRadius="full"
                  padding="10px 8px"
                  height="32px"
                  justifyContent="center"
                  minW={['150px', '175px']}
                  width={['full', 'full', 'auto', '190px']}
                  borderWidth="1px"
                  borderColor="gray.100"
                  isActive={isOpen}
                  _active={{
                    color: 'white',
                    bg: 'gray.700',
                  }}
                  as={Button}
                  rightIcon={
                    isOpen ? (
                      <Icon as={RiArrowDropDownLine} h={7} w={7} />
                    ) : (
                      <Icon as={RiArrowDropUpLine} h={7} w={7} />
                    )
                  }
                  colorScheme="gray"
                  sx={{
                    '& span': {
                      display: 'flex',
                      alignItems: 'left',
                      justifyContent: 'space-between',
                      flex: 'none',
                    },
                  }}
                >
                  <Text
                    paddingLeft="2"
                    minW={['', '', '125px']}
                    width={['', '', '120px', '120px']}
                    textAlign="left"
                  >
                    Opções
                  </Text>
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={() => setIsEdicaoEmMassa(true)}>
                    <Icon as={FinanceiroAReceber} h={6} w={5} />
                    <Text ml="4" fontSize="sm">
                      Recebimento de contas
                    </Text>
                  </MenuItem>
                </MenuList>
              </>
            )}
          </Menu>
        </GridItem> */}
      </SimpleGridForm>
      <ContainerHeader
        mb={hasProdutoSelecionado ? undefined : '16px'}
        pl={['28px', '48px', '48px']}
        justifyContent="space-between"
        pr="24px"
        h={['80px', '56px', '56px']}
      >
        <Flex
          color="white"
          justifyContent={['center', 'center', 'space-between']}
          w="full"
          direction={['column', 'column', 'row']}
          alignItems="center"
        >
          <Flex
            alignItems={
              hasProdutoSelecionado ? ['', 'center', 'center'] : 'center'
            }
          >
            <Box cursor="pointer" mr={['5px', '10px', '10px']}>
              {isEdicaoEmMassa ? (
                <Checkbox
                  mb="0"
                  isChecked={selecionarTodosProdutos}
                  onChange={() => handleToggleSelecionarTodosProdutos()}
                  colorScheme="gray"
                  iconColor="white"
                />
              ) : (
                <Icon fontSize="25px" as={PainelTrayIcon} />
              )}
            </Box>
            {hasProdutoSelecionado ? (
              <Flex alignItems="center" direction={['column', 'row']}>
                <Text whiteSpace="nowrap" fontSize="16px">
                  {amountProdutoSelecionado}{' '}
                  {amountProdutoSelecionado > 1
                    ? 'Itens selecionados'
                    : 'Item selecionado'}
                </Text>
                <Text
                  whiteSpace="nowrap"
                  fontWeight="semibold"
                  fontSize="12px"
                  cursor="pointer"
                  ml={['0', '24px', '24px']}
                  textDecoration="underline"
                  color="red.200"
                  onClick={() => handleToggleLimparSelecao()}
                >
                  Limpar seleção
                </Text>
              </Flex>
            ) : (
              <Text fontSize="16px" ml={['0', '0', '10px']}>
                Produtos publicados
              </Text>
            )}
          </Flex>
          <Box>
            <Text fontSize="14px" textTransform="capitalize">
              {nomeCanalVenda}
            </Text>
          </Box>
        </Flex>
      </ContainerHeader>

      <Collapse in={hasProdutoSelecionado} animateOpacity>
        <Box mb="20px">
          <Flex
            zIndex="0"
            style={{
              backgroundBlendMode: 'multiply',
            }}
            pl={['24px', '44px', '44px']}
            bgGradient="linear-gradient(0deg, rgba(204, 204, 204, 0.2) 60%, #888888 100%, #888888 100%, #000 100%) "
            h="51px"
            alignItems="center"
          >
            <Text
              fontWeight="semibold"
              fontSize="16px"
              cursor="pointer"
              mr={['28px', '48px', '48px']}
              textDecoration="underline"
              color="black"
            >
              Remover
            </Text>
          </Flex>
        </Box>
      </Collapse>
      <DrawerBuscaAvancada
        currentFilters={filtros}
        filtersSubmit={filtersSubmit}
        filtrosReset={defaultValue}
        isOpen={isOpenBuscaAvancada}
        onClose={() => setIsOpenBuscaAvancada(false)}
      />
      <PagedTable
        ref={pagedTableRef}
        isLoading={isLoading}
        variant="card"
        bg="transparent"
        boxShadow="none"
        paddingRight="24px"
        paddingLeft="24px"
        pt={['10px', '10px', '']}
        pb={['10px', '10px', '']}
        paginationHasDivider={false}
        loadColumnsData={paginationHandle}
        itemsTotalCount={totalRegistros}
        defaultKeyOrdered="nome"
        tableHeaders={[
          {
            key: 'ativo',
            isOrderable: false,
            content: isEdicaoEmMassa ? (
              <></>
            ) : (
              <StatusCircle hasValue={false} />
            ),
            w: '1px',
            paddingRight: '0',
          },
          {
            key: 'nomeProduto',
            content: 'Nome do produto / Título do anúncio',
            isOrderable: false,
            paddingLeft: '3px',
          },
          { key: 'referencia', content: 'Referência', isOrderable: false },

          {
            key: 'preco-venda',
            isNumeric: true,
            isOrderable: false,

            content: 'Preço de venda',
          },
          { key: 'estoque', content: 'Estoque', isOrderable: false },
          { key: 'acoes', content: 'Ações', isOrderable: false, w: '1px' },
        ]}
        renderTableRows={(listarProduto || []).map((produto, index) => {
          const preco = produto.precos.produtoPrecoLojas.find(
            (precoItem) => precoItem.lojaId === lojaId
          );

          const estoqueAtual =
            produto?.produtoCores[0]?.produtoCorTamanhos[0]
              ?.produtoCorTamanhoEstoques[0]?.estoqueAtual || 0;

          const precoVenda = preco?.precoVenda.precoVenda;

          return (
            <>
              <Tr
                sx={{
                  boxShadow: '0px 0px 4px #00000029',
                  borderRadius: '6px',
                  '& > td': {
                    height: '30px',
                  },
                }}
              >
                <Td>
                  {isEdicaoEmMassa ? (
                    <Checkbox
                      colorScheme="primary"
                      isChecked={produto.isChecked}
                      onChange={() => handleToggleSelecionarProduto(index)}
                      mb="0"
                    />
                  ) : (
                    <StatusCircle isActive />
                  )}
                </Td>
                <Td paddingLeft="0" fontWeight="bold">
                  {produto.nome}
                </Td>
                <Td fontWeight="bold">{produto.referencia}</Td>
                <Td fontWeight="bold" isNumeric>
                  {moneyMask(precoVenda, true)}
                </Td>
                <Td>
                  <Text
                    fontSize="14px"
                    p="2px"
                    w="70px"
                    borderRadius="12px"
                    textAlign="center"
                  >
                    {estoqueAtual}
                  </Text>
                </Td>

                <Td>
                  <ActionsMenu
                    items={[
                      {
                        content: 'Editar',
                        onClick: () => {
                          handleEditarProduto(produto.id);
                        },
                      },
                      {
                        content: 'Bloquear',
                        onClick: () => {
                          handleBloquearProduto(produto.id);
                        },
                      },
                      {
                        content: 'Excluir',
                        onClick: () => {
                          handleExcluirProduto(produto.id);
                        },
                      },
                    ]}
                  />
                </Td>
              </Tr>
              <Box h="5px" />
            </>
          );
        })}
      />
    </FormProvider>
  );
};
