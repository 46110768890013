import React, { useCallback, useEffect, useRef } from 'react';

interface ClickForaPadraoProps {
  onClose: () => void;
  children: React.ReactNode;
}

export const ClickForaPadrao = ({
  onClose,
  children,
}: ClickForaPadraoProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const clickListener = useCallback(
    (e: MouseEvent) => {
      if (e.target && ref.current && !ref.current.contains(e.target as Node)) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    setTimeout(() => {
      document.addEventListener('click', clickListener);
    }, 250);

    return () => {
      document.removeEventListener('click', clickListener);
    };
  }, [clickListener]);

  return (
    <span
      style={{
        position: 'relative',
        overflow: 'auto',
        width: '100%',
      }}
      ref={ref}
    >
      {children}
    </span>
  );
};

export default ClickForaPadrao;
