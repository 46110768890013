import React, { useEffect, useRef } from 'react';
import {
  ModalContent,
  ModalBody,
  Flex,
  ScaleFade,
  ModalCloseButton,
  ModalHeader,
  FormLabel,
  ModalFooter,
  Button,
  Text,
  Divider,
  Box,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { statusOperacaoTransferenciaOptions } from 'constants/enum/statusOperacaoTransferencia';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import ManterFoco from 'components/Geral/ManterFoco';
import InputDateRange from 'components/PDV/InputDateRange';
import { FormProvider, useForm } from 'react-hook-form';
import InputInteger from 'components/PDV/InputInteger';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import { ObterLojasProps } from '../types';
import { FormData } from '../validationForm';

interface ModalProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  currentFilters: FormData;
  handleSubmitModalBuscaAvancada: (data: FormData) => void;
  handleReset: () => void;
  getLoja: ObterLojasProps[];
}

const ModalBuscaAvancadaTransferenciaEstoque = ({
  isOpen,
  setIsOpen,
  handleSubmitModalBuscaAvancada,
  currentFilters,
  handleReset,
  getLoja,
}: ModalProps) => {
  const formMethods = useForm<FormData>();
  const { t } = useTranslation();

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    handleSubmitModalBuscaAvancada(data);
  });

  const latestProps = useRef({ reset: formMethods.reset, currentFilters });
  useEffect(() => {
    latestProps.current = { reset: formMethods.reset, currentFilters };
  });

  useEffect(() => {
    if (isOpen) {
      latestProps.current.reset(latestProps.current.currentFilters);
    }
  }, [isOpen]);

  return (
    <ModalPadraoChakra
      onClose={() => {
        setIsOpen(false);
      }}
      isOpen={isOpen}
      isCentered
      size="2xl"
    >
      <ModalContent
        marginBottom={{ base: 0, sm: '3.75rem' }}
        marginTop={{ base: 0, sm: '3.75rem' }}
        h="unset"
        maxW="600px"
      >
        <ManterFoco
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <ScaleFade initialScale={1.5} in={isOpen}>
            <ModalBody p={0} h="100%">
              <Flex flexDirection="column" align="stretch" h="100%">
                <ModalContent p="3" m="2">
                  <ModalHeader>
                    <Text color="primary.50" fontSize="md">
                      Busca avançada
                    </Text>
                    <Text color="gray.700" fontSize="xs">
                      Utilize os filtros de busca para uma pesquisa específica.
                      Todos os campos são acumulativos.
                    </Text>
                  </ModalHeader>
                  <Divider
                    orientation="horizontal"
                    width="92%"
                    margin="0 auto"
                  />
                  <ModalCloseButton
                    fontSize="2xs"
                    border="none"
                    _hover={{ bg: 'none' }}
                    _focus={{ border: 'none' }}
                  />
                  <ModalBody pb="6" pt="6">
                    <FormProvider {...formMethods}>
                      <Box width={['100%', '100%', '100%', '100%']}>
                        <FormLabel>Período</FormLabel>
                        <InputDateRange
                          borderColor="gray.100"
                          borderRadius="md"
                          name="data"
                          startDateName="dataEmissaoInicio"
                          endDateName="dataEmissaoFim"
                          maxDate={new Date()}
                        />
                      </Box>

                      <Flex
                        flexDirection={['column', 'column', 'column', 'row']}
                        justifyContent="space-between"
                        pt="6"
                      >
                        <Box width={['100%', '100%', '100%', '48%']}>
                          <InputInteger
                            id="identificador"
                            name="identificador"
                            label={t('Identificador')}
                            placeholder="Número da movimentação"
                            borderColor="gray.100"
                            autoFocus
                          />
                        </Box>
                        <Box
                          width={['100%', '100%', '100%', '48%']}
                          pt={['6', '6', '6', '0']}
                        >
                          <SelectPadrao
                            id="situacao"
                            name="situacao"
                            label={t('Situação')}
                            placeholder="Selecione"
                            options={statusOperacaoTransferenciaOptions}
                            isClearable
                          />
                        </Box>
                      </Flex>
                      <Flex
                        flexDirection={['column', 'column', 'column', 'row']}
                        justifyContent="space-between"
                        pt={6}
                      >
                        <Box width={['100%', '100%', '100%', '48%']}>
                          <SelectPadrao
                            id="lojaOrigem"
                            name="lojaOrigem"
                            label={t('Loja de origem')}
                            placeholder="Selecione"
                            options={getLoja.map(({ id, fantasia }) => {
                              return (
                                {
                                  label: fantasia,
                                  value: id,
                                } || {}
                              );
                            })}
                            isClearable
                          />
                        </Box>
                        <Box
                          width={['100%', '100%', '100%', '48%']}
                          pt={['6', '6', '6', '0']}
                        >
                          <SelectPadrao
                            id="lojaDestino"
                            name="lojaDestino"
                            label={t('Loja de destino')}
                            placeholder="Selecione"
                            options={getLoja.map(({ id, fantasia }) => {
                              return (
                                {
                                  label: fantasia,
                                  value: id,
                                } || {}
                              );
                            })}
                            isClearable
                          />
                        </Box>
                      </Flex>
                    </FormProvider>
                  </ModalBody>
                  <Divider
                    orientation="horizontal"
                    width="92%"
                    margin="0 auto"
                    pt={6}
                  />

                  <ModalFooter mt={6}>
                    <Flex
                      width="100%"
                      justifyContent={[
                        'center',
                        'center',
                        'flex-end',
                        'flex-end',
                      ]}
                    >
                      <Button
                        width={['120px', '120px', '150px', '150px']}
                        id="cancelar"
                        name="cancelar"
                        colorScheme="gray.100"
                        variant="outline"
                        borderRadius="md"
                        fontSize="sm"
                        type="button"
                        onClick={() => {
                          setIsOpen(false);
                          handleReset();
                        }}
                        mr={6}
                        _hover={{ bg: 'gray.50' }}
                      >
                        Limpar filtros
                      </Button>
                      <Button
                        width={['120px', '120px', '150px', '150px']}
                        id="pesquisar"
                        name="pesquisar"
                        backgroundColor="aquamarine.600"
                        borderRadius="md"
                        fontSize="sm"
                        color="white"
                        variant="success"
                        type="button"
                        _hover={{ bg: 'aquamarine.700' }}
                        onClick={() => {
                          handleSubmit();
                          setIsOpen(false);
                        }}
                      >
                        Pesquisar
                      </Button>
                    </Flex>
                  </ModalFooter>
                </ModalContent>
              </Flex>
            </ModalBody>
          </ScaleFade>
        </ManterFoco>
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export default ModalBuscaAvancadaTransferenciaEstoque;
