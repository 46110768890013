import { pushExternalUrl } from 'helpers/layout/pushExternalUrl';

interface SendWhatsAppMessageProps {
  message: string;
  phoneNumber: string;
  contryCode?: number;
  target?: '_blank' | '_parent' | '_self' | '_top';
}

/**
 * Função utilizada para enviar mensagens pela API do WhatsApp
 * @param  {[string]} message Mensagem que será enviada ao número
 * @param  {[string]} phoneNumber Número que receberá a mensagem, deve vir com DDD e sem o código do país
 * @param  {[number]} contryCode Código do país referente ao número que receberá a mensagem, por padrão recebe 55 (Brasil)
 * @param  {[string]} target Referência a aba que a API do WhatsApp será aberta, por padrão uma nova aba é aberta (_blank)
 */

export function sendWhatsAppMessage({
  message,
  phoneNumber,
  contryCode = 55,
  target = '_blank',
}: SendWhatsAppMessageProps) {
  const url = `https://api.whatsapp.com/send?phone=${encodeURIComponent(
    `+${contryCode} ${phoneNumber}`
  )}&text=${encodeURIComponent(message)}`;

  pushExternalUrl(url, target);
}
