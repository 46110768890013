import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import SelectField from 'components/PDV/Select/SelectPadrao';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { toast } from 'react-toastify';
import OptionType from 'types/optionType';
import { useFormContext } from 'react-hook-form';
import { FiChevronDown } from 'react-icons/fi';
import { components } from 'react-select';
import { Icon } from 'components/Input/InputPesquisa/styles';
import { useMediaQuery } from '@chakra-ui/react';

export interface ForwardRefMethods {
  handleGetVendedorSelecionado: () => VendedorInterface | undefined;
  vendedores: OptionType[];
}

interface SelectVendedorProps {
  id: string;
  name: string;
  label: string;
  placeholder: string;
  required: boolean;
  autoFocus?: boolean;
  size?: string;
  variant?: string;
  onClick?: () => void;
  executeOnLoad?: () => void;
}

interface VendedorInterface {
  id: string;
  nome: string;
}

const SelectVendedor = forwardRef<ForwardRefMethods, SelectVendedorProps>(
  (
    {
      id,
      name,
      label,
      placeholder,
      required,
      autoFocus,
      size,
      variant,
      onClick,
      executeOnLoad,
    },
    ref
  ) => {
    const { getValues } = useFormContext();
    const [vendedores, setVendedores] = useState<OptionType[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    const handleGetVendedorSelecionado = useCallback(() => {
      const vendedorId = getValues(`${name}` as const);

      if (vendedores) {
        const vendedorSelecionado = vendedores.find(
          (v: any) => v.value === vendedorId
        );

        if (vendedorSelecionado) {
          return {
            id: vendedorSelecionado.value,
            nome: vendedorSelecionado.label,
          } as VendedorInterface;
        }
      }

      return undefined;
    }, [getValues, name, vendedores]);

    const handleGetVendedoresValues = useCallback(async () => {
      setIsLoading(true);

      const response = await api.get<void, ResponseApi<VendedorInterface[]>>(
        ConstanteEnderecoWebservice.VENDEDOR_LISTAR_SELECT_POR_LOJA
      );

      setIsLoading(false);

      if (response?.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }

      if (response?.sucesso) {
        setVendedores(
          response.dados.map((vendedor) => {
            return {
              label: vendedor.nome,
              value: vendedor.id,
            } as OptionType;
          })
        );
      }

      return undefined;
    }, []);

    useImperativeHandle(ref, () => ({
      handleGetVendedorSelecionado,
      vendedores,
    }));

    useEffect(() => {
      handleGetVendedoresValues();
    }, [handleGetVendedoresValues]);

    useEffect(() => {
      if (executeOnLoad && vendedores.length > 0) executeOnLoad();
    }, [executeOnLoad, vendedores]);

    const chakraComponentsCustom = {
      DropdownIndicator: ({ children, ...rest }: any) => (
        <components.DropdownIndicator {...rest}>
          <Icon
            as={FiChevronDown}
            style={{
              position: 'unset',
              color: 'unset',
              marginTop: 'unset',
              marginLeft: 'unset',
              fontSize: 'unset',
            }}
            onClick={onClick}
            onTouchEnd={onClick}
          />
        </components.DropdownIndicator>
      ),
    };

    return (
      <SelectField
        isLoading={isLoading}
        id={id}
        name={name}
        label={label}
        placeholder={placeholder}
        options={vendedores as any}
        variant={variant}
        size={size}
        required={required}
        autoFocus={autoFocus}
        colSpan={{ base: 2, md: 1 }}
        isSearchable={isLargerThan900}
        menuIsOpen={isLargerThan900 ? undefined : false}
        onFocus={onClick}
        components={chakraComponentsCustom}
      />
    );
  }
);

export default SelectVendedor;
