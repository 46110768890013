import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { OptionTypeBase, OptionsType } from 'react-select';

import api, { ResponseApi } from 'services/api';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import isPrenvent from 'helpers/layout/isPrenvent';
import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import TipoFormaPagamentoRecebimentoEnum from 'constants/enum/tipoFormaPagamentoRecebimento';
import FormaPagamentoFormularioProvider, {
  IconesStorage,
} from 'store/FormaPagamento/FormaPagamentoFormulario';
import useReloadRegistration from 'helpers/layout/useReloadRegistration';

import { ContainerListagem } from 'components/Layout/Listagem/containerListagem';

import { useForm, yupResolver } from '../validationForm';

// eslint-disable-next-line import/no-named-as-default
import UncontrolledForm from '..';

const Cadastrar = () => {
  const [iconesOptions, setIconesOptions] = useState<
    OptionsType<OptionTypeBase>
  >([]);
  const [urlPreviewList, setUrlPreviewList] = useState<IconesStorage[]>();
  const [showVencimento, setShowVencimento] = useState(false);
  const [urlPreview, setUrlPreview] = useState<string>('');

  const [isLoading, setIsLoading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);

  const history = useHistory();
  const isMountedRef = useIsMountedRef();

  const formMethods = useForm({
    resolver: yupResolver,
  });
  const { handleSubmit, reset, getValues, formState, setFocus } = formMethods;

  const historyReload = useReloadRegistration({ setFormIsDirty });
  isPrenvent(formIsDirty);

  const filtrandoValorUrl = (urlPreviewList || []).filter(
    (valorFiltrado) => valorFiltrado.url === urlPreview
  );
  const obterValorNomeIcone = filtrandoValorUrl.map(
    (obterNomeIcone) => obterNomeIcone.nome
  );
  const indiceIconeSelecionado = obterValorNomeIcone[0];

  async function handlePostFormaPagamento() {
    const data = getValues();

    const response = await api.post<void, ResponseApi>(
      ConstanteEnderecoWebservice.FORMA_PAGTO_CADASTRAR,
      {
        ...data,
        tipoFormaPagamentoRecebimento:
          TipoFormaPagamentoRecebimentoEnum.Pagamento,
        Icone: indiceIconeSelecionado || null,
      }
    );

    if (response.sucesso) {
      return true;
    }

    if (response.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    return false;
  }

  const onSubmit = handleSubmit(async () => {
    setIsLoading(true);

    const success = await handlePostFormaPagamento();

    if (success) {
      toast.success('O cadastro foi realizado com sucesso.');

      setFormIsDirty(false);

      history.push(ConstanteRotas.FORMA_PAGTO);
    }

    setIsLoading(false);
  });

  const onSubmitReset = handleSubmit(async () => {
    setIsLoading(true);

    const success = await handlePostFormaPagamento();

    if (success) {
      toast.success('O cadastro foi realizado com sucesso.');
      if (isMountedRef.current) setFocus('nome');

      historyReload(ConstanteRotas.FORMA_PAGTO_CADASTRAR);
    }

    setIsLoading(false);
  });

  useEffect(() => {
    reset({
      nome: '',
      ativo: true,
      lancarFatura: false,
    });
  }, [reset]);

  useEffect(() => {
    setFormIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  return (
    <ContainerListagem
      formMethods={formMethods}
      isLoading={isLoading}
      onSubmit={onSubmit}
      onSubmitReset={onSubmitReset}
      formIsDirty={formIsDirty}
      maxWidth="full"
    >
      <FormaPagamentoFormularioProvider
        urlPreview={urlPreview}
        setUrlPreview={setUrlPreview}
        showVencimento={showVencimento}
        setShowVencimento={setShowVencimento}
        iconesOptions={iconesOptions}
        setIconesOptions={setIconesOptions}
        urlPreviewList={urlPreviewList}
        setUrlPreviewList={setUrlPreviewList}
      >
        <UncontrolledForm />
      </FormaPagamentoFormularioProvider>
    </ContainerListagem>
  );
};

export default Cadastrar;
