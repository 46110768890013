import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

export interface InformacoesParcelaMovimentacaoFinanceira {
  numeroParcela: number;
  valor: number;
  acrescimo: number;
  dataVencimento: Date;
  transacaoId?: string;
}

export interface ParcelasMovimentacaoFinanceira {
  troco: number;
  parcelas: InformacoesParcelaMovimentacaoFinanceira[];
}

interface MovimentacaoFinanceiraCadastrar {
  operacaoId: string;
  formaPagamentoRecebimentoId: string;
  formaPagamentoRecebimentoParcelaId?: string;
  identificadorAgrupamento: string;
  planoContaId?: string;
  troco: number;
  parcelas: InformacoesParcelaMovimentacaoFinanceira[];
  tela: number;
  vales: {
    valeId: string;
    sequenciaOrdenacao: number;
  }[];
  contaFinanceiraId?: string;
  cnpjPagamento?: string;
  ufPagamento?: string;
}

const cadastrarMovimentacaoFinanceira = async (
  movimentacaoFinanceiraCadastrar: MovimentacaoFinanceiraCadastrar
): Promise<boolean> => {
  const response = await api.post<void, ResponseApi>(
    ConstanteEnderecoWebservice.MOVIMENTACAO_FINANCEIRA_CADASTRAR,
    {
      ...movimentacaoFinanceiraCadastrar,
    }
  );

  if (response) {
    if (response.avisos) {
      response.avisos.forEach((item: string) => toast.warning(item));
      return false;
    }
    if (response.sucesso) {
      return true;
    }
  }
  return false;
};

export default cadastrarMovimentacaoFinanceira;
