import React, { useMemo, useState, useCallback, useRef } from 'react';
import { Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SalvarInserirNovoIcon } from 'icons';
import { Icon } from '@chakra-ui/react';

import auth from 'modules/auth';
import api, { ResponseApi } from 'services/api';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';

import ConstanteFuncionalidades from 'constants/permissoes';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import MeioPagamentoEnum from 'constants/enum/fiscal/meioPagamento';

import SelectBusca from 'components/Select/SelectBusca';
import ManterFoco from 'components/Geral/ManterFoco';
import { ModalDesistencia } from 'components/Modal/ModalDesistencia';
import { ButtonFuncionalidade } from 'components/update/Button/ButtonFuncionalidade';
import { SearchInput } from 'components/update/Input/SearchInput';
import ValidarPermissao from 'components/Validacao/ValidarPermissao';
import { HeaderData } from 'components/Grid/Cabecalho';
import ButtonComOpcoes from 'components/Button/ButtonComOpcoes';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { ModalConfirmacaoInativar } from 'components/Modal/ModalConfirmacaoInativar';

import TipoFormaPagamentoRecebimentoEnum from 'constants/enum/tipoFormaPagamentoRecebimento';
import Paginacao, {
  ForwardRefMethod,
  GridPaginadaConsulta,
  GridPaginadaRetorno,
} from 'components/Grid/Paginacao';

import {
  AlignRight,
  ContentContainer,
  StatusIcon,
  LinkVisualizar,
  FiltrosCol,
} from 'styles';
import { getName } from 'helpers/enum/getName';
import { toast } from 'react-toastify';

interface FormaRecebimentoFiltros {
  [x: string]: any;
}

interface FormaRecebimento {
  id: string;
  nome: string;
  ativo: boolean;
  tipoFormaPagamentoRecebimento: string;
  meioPagamento: string | number;
  padraoSistema: boolean;
}

const Listar = () => {
  const history = useHistory();
  const isMountedRef = useIsMountedRef();
  const { t } = useTranslation();
  const filtrosAtuais = useRef<FormaRecebimentoFiltros>({
    ativo: true,
  } as FormaRecebimentoFiltros);
  const formMethods = useForm({
    defaultValues: filtrosAtuais.current,
  });
  const { control, getValues, setFocus } = formMethods;

  const [isLoading, setIsLoading] = useState(false);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [FormasRecebimentos, setFormasRecebimentos] = useState(
    [] as Array<FormaRecebimento>
  );

  const pageIsLoaded = useRef(false);
  const childRef = useRef<ForwardRefMethod>();

  const permissaoFormaRecebimentoAlterar = useMemo(
    () => auth.possuiPermissao(ConstanteFuncionalidades.FORMA_RECEB_ALTERAR),
    []
  );

  const handleHistoryPush = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history]
  );

  const handleReset = useCallback(
    (orderColumn: string) => {
      const filtros = getValues();

      if (JSON.stringify(filtros) !== JSON.stringify(filtrosAtuais.current)) {
        return childRef.current?.reset(orderColumn);
      }

      return () => {};
    },
    [getValues]
  );

  const handleRefresh = useCallback((newTotal?: number) => {
    return childRef.current?.refresh(newTotal);
  }, []);

  const paginationHandle = useCallback(
    async (gridPaginadaConsulta: GridPaginadaConsulta) => {
      setIsLoading(true);

      const filtros = getValues();

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<FormaRecebimento>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.FORMA_PAGTO_LISTAR_PAGINADO,
          gridPaginadaConsulta
        ),
        {
          params: {
            nome: filtros.nome,
            ativo: filtros.ativo,
            tipoFormaPagamentoRecebimento:
              TipoFormaPagamentoRecebimentoEnum.Recebimento,
          },
        }
      );

      if (response?.sucesso && isMountedRef.current) {
        setTotalRegistros(response.dados.total);

        setFormasRecebimentos(response.dados.registros);

        filtrosAtuais.current = filtros;
      }

      if (isMountedRef.current) {
        setIsLoading(false);

        if (!pageIsLoaded.current) {
          pageIsLoaded.current = true;

          setFocus('nome');
        }
      }
    },
    [getValues, isMountedRef, setFocus]
  );

  const updateStatus = useCallback(
    async (id: string, webService: string, status: boolean) => {
      const response = await api.put<void, ResponseApi>(
        `${webService}?id=${id}`
      );

      if (response) {
        if (response.avisos) {
          if (status === true) {
            const isSaldoEmAberto = response.avisos[0].includes(
              'forma-com-saldo'
            );
            ModalDesistencia({
              showCancelButton: false,
              title: isSaldoEmAberto
                ? 'Existe um saldo em aberto'
                : 'Não foi possível inativar',
              labelButtonConfirmar: 'Ok',
              textoMensagem: isSaldoEmAberto
                ? 'Para inativar uma forma de pagamento ou recebimento é necessário que o saldo dessa mesma forma esteja zerado em todos os caixas. Verifique se existe algum caixa com saldo positivo ou negativo utilizando essa forma, efetue o ajuste e retorne para confirmar a inativação'
                : response.avisos[0],
              callback: () => {},
            });
          } else {
            response.avisos.forEach((item: string) => toast.warning(item));
          }
        }
        if (response.sucesso) {
          toast.success(
            `O cadastro foi ${
              status === true ? 'inativado' : 'ativado'
            } com sucesso`
          );

          handleRefresh();
        }
      }
    },
    [handleRefresh]
  );

  const handleUpdateReceiptMethodStatus = useCallback(
    async (id: string, status: boolean) => {
      if (status === false) {
        updateStatus(
          id,
          ConstanteEnderecoWebservice.FORMA_PAGTO_ATIVAR,
          status
        );
      } else {
        ModalConfirmacaoExcluir({
          confirmButtonText: 'Sim, inativar',
          callback: async (ok: boolean) => {
            if (ok) {
              setIsLoading(true);
              updateStatus(
                id,
                ConstanteEnderecoWebservice.FORMA_PAGTO_INATIVAR,
                status
              );
              setIsLoading(false);
            }
          },
        });
      }
    },
    [updateStatus]
  );

  const excluirHandle = useCallback(
    async (forma_pagto_Id: string, ativo: boolean) => {
      ModalConfirmacaoExcluir({
        callback: async (ok: boolean) => {
          if (ok) {
            setIsLoading(true);

            const response = await api.delete<void, ResponseApi>(
              ConstanteEnderecoWebservice.FORMA_PAGTO_EXCLUIR,
              {
                params: { id: forma_pagto_Id },
              }
            );

            if (response?.sucesso) {
              toast.success(t('O cadastro foi removido com sucesso.'));

              handleRefresh(totalRegistros - 1);
            }

            if (response) {
              ModalConfirmacaoInativar({
                response,
                callbackAvisos: true,
                rotaWebService:
                  ConstanteEnderecoWebservice.FORMA_PAGTO_INATIVAR,
                id: forma_pagto_Id,
                ativo,
                callback: (okInativar: boolean) => {
                  if (okInativar) handleRefresh(totalRegistros - 1);
                },
              });
            }

            setIsLoading(false);
          }
        },
      });
    },
    [handleRefresh, t, totalRegistros]
  );

  const headerData: HeaderData = useMemo(() => {
    return {
      orderColumn: 'Nome',
      orderDirection: 'asc',
      columns: [
        {
          displayName: <StatusIcon style={{ color: 'var(--black)' }} />,
          name: 'Ativo',
          isOrderable: true,
          width: '37px',
          alignText: 'center',
        },
        {
          displayName: t('Nome'),
          name: 'Nome',
          isOrderable: true,
          width: 'auto',
        },
        {
          displayName: t('Meio de recebimento'),
          name: 'tipoFormaPagamentoRecebimento',
          isOrderable: true,
          width: '170px',
        },
        {
          displayName: t('Ações'),
          name: 'Acoes',
          isOrderable: false,
          width: '30px',
        },
      ],
    };
  }, [t]);

  const FormaRecebList = useMemo(() => {
    return (
      <Paginacao
        ref={childRef}
        total={totalRegistros}
        paginationHandle={paginationHandle}
        isLoading={isLoading}
        headerData={headerData}
      >
        {FormasRecebimentos.map((FormaRecebimento) => {
          const isFormaRecebimentoPadraoSistema =
            FormaRecebimento.padraoSistema;

          const meioPagamentoIsCheque =
            MeioPagamentoEnum.Cheque === FormaRecebimento.meioPagamento;

          const podeModificarFormaRecebimento =
            !isFormaRecebimentoPadraoSistema || meioPagamentoIsCheque;

          const items = !isFormaRecebimentoPadraoSistema
            ? [
                {
                  title: t('Editar'),
                  onClick: () => {
                    handleHistoryPush(
                      SubstituirParametroRota(
                        ConstanteRotas.FORMA_RECEB_ALTERAR,
                        'id',
                        FormaRecebimento.id
                      )
                    );
                  },
                  funcionalidadePermissao:
                    ConstanteFuncionalidades.FORMA_RECEB_ALTERAR,
                },
                {
                  title: t('Remover'),
                  onClick: () => {
                    excluirHandle(FormaRecebimento.id, FormaRecebimento.ativo);
                  },
                  funcionalidadePermissao:
                    ConstanteFuncionalidades.FORMA_RECEB_EXCLUIR,
                },
              ]
            : [
                {
                  title: FormaRecebimento.ativo ? 'Inativar' : 'Ativar',
                  onClick: () => {
                    handleUpdateReceiptMethodStatus(
                      FormaRecebimento.id,
                      FormaRecebimento.ativo
                    );
                  },
                },
              ];

          return (
            <tr key={FormaRecebimento.id}>
              <td style={{ textAlign: 'center' }}>
                <StatusIcon
                  color={
                    FormaRecebimento.ativo
                      ? 'var(--sti-ck-colors-green-500)'
                      : 'var(--sti-ck-colors-red-500'
                  }
                />
              </td>
              <td>
                <ValidarPermissao
                  funcionalidade={
                    ConstanteFuncionalidades.FORMA_RECEB_VISUALIZAR
                  }
                  tooltipWrap="hidden"
                >
                  {({ permitido }) =>
                    FormaRecebimento.padraoSistema ? (
                      FormaRecebimento.nome
                    ) : (
                      <LinkVisualizar
                        id="link-visualizar"
                        bloqueado={permitido ? 0 : 1}
                        to={
                          permitido
                            ? SubstituirParametroRota(
                                permissaoFormaRecebimentoAlterar.permitido
                                  ? ConstanteRotas.FORMA_RECEB_ALTERAR
                                  : ConstanteRotas.FORMA_RECEB_VISUALIZAR,
                                'id',
                                FormaRecebimento.id
                              )
                            : ''
                        }
                      >
                        {FormaRecebimento.nome}
                      </LinkVisualizar>
                    )
                  }
                </ValidarPermissao>
              </td>
              <td>
                {getName(MeioPagamentoEnum, FormaRecebimento.meioPagamento)}
              </td>
              <td>
                {podeModificarFormaRecebimento && (
                  <ButtonComOpcoes id="mostrarMais" dropdownItems={items} />
                )}
              </td>
            </tr>
          );
        })}
      </Paginacao>
    );
  }, [
    totalRegistros,
    paginationHandle,
    isLoading,
    headerData,
    FormasRecebimentos,
    t,
    permissaoFormaRecebimentoAlterar.permitido,
    handleHistoryPush,
    excluirHandle,
    handleUpdateReceiptMethodStatus,
  ]);

  return (
    <ContentContainer>
      <FormProvider {...formMethods}>
        <ManterFoco>
          <Row>
            <FiltrosCol xs={12} sm={5} lg={6}>
              <SearchInput
                type="search"
                onEnterKeyPress={() => handleReset('Nome')}
                placeholder={t('Buscar forma de recebimento por nome')}
                isDisabled={isLoading}
                id="nome"
                name="nome"
              />
            </FiltrosCol>
            <FiltrosCol xs={0}>
              <SelectBusca
                id="ativo"
                name="ativo"
                defaultValue
                isDisabled={isLoading}
                control={control}
                options={[
                  {
                    value: true,
                    label: t('Ativos'),
                  },
                  {
                    value: false,
                    label: t('Inativos'),
                  },
                  {
                    value: null,
                    label: t('Todos'),
                  },
                ]}
                onSelect={() => {
                  handleReset('Nome');
                }}
              />
            </FiltrosCol>
            <AlignRight as={FiltrosCol} xs={12} sm={4} md="auto">
              <ButtonFuncionalidade
                id="cadastrarNovo"
                name="cadastrarNovo"
                color="gray.700"
                borderRadius="md"
                colorScheme="secondary"
                minW={{ base: 'full', md: '250px' }}
                leftIcon={<Icon as={SalvarInserirNovoIcon} />}
                type="button"
                onClick={() =>
                  handleHistoryPush(ConstanteRotas.FORMA_RECEB_CADASTRAR)
                }
                funcionalidade={ConstanteFuncionalidades.FORMA_RECEB_CADASTRAR}
              >
                {t('Cadastrar Novo')}
              </ButtonFuncionalidade>
            </AlignRight>
          </Row>
        </ManterFoco>
      </FormProvider>
      {FormaRecebList}
    </ContentContainer>
  );
};

export default Listar;
