import React from 'react';
import { FlexProps } from '@chakra-ui/react';

import auth from 'modules/auth';
import TipoSistemaEnum from 'constants/enum/tipoSistema';

import { LoginBackgroundZendar } from './LoginBackgroundZendar';
import { LoginBackgroundPowerStock } from './LoginBackgroundPowerStock';
import { LoginBackgroundFomer } from './LoginBackgroundFomer';

interface ContainerProps extends FlexProps {
  children: React.ReactNode;
}

const Container = ({ children, ...rest }: ContainerProps) => {
  const sistema = auth.getSistema();
  const nomeDoSistema: number = sistema.value;

  const telasDeLogin = (sistemaAtual: number) => {
    switch (sistemaAtual) {
      case TipoSistemaEnum.ZENDAR:
        return LoginBackgroundZendar;
      case TipoSistemaEnum.POWERSTOCK:
        return LoginBackgroundPowerStock;
      case TipoSistemaEnum.FOMER:
        return LoginBackgroundFomer;
      case TipoSistemaEnum.FOMER_SISTEMA:
        return LoginBackgroundFomer;
      case TipoSistemaEnum.POWERCHEF:
        return LoginBackgroundPowerStock;

      default:
        return LoginBackgroundZendar;
    }
  };
  const TelaDeLoginAtual = telasDeLogin(nomeDoSistema);
  return <TelaDeLoginAtual {...rest}>{children}</TelaDeLoginAtual>;
};

export default Container;
