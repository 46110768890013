import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { VStack } from '@chakra-ui/react';

import ConstanteRotas from 'constants/rotas';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';

import Title from 'components/Autenticacao/Text/Title';
import Paragraph from 'components/Autenticacao/Text/Paragraph';
import ButtonSubmit from 'components/Autenticacao/Button/Submit';

export const SenhaRedefinidaSucesso = () => {
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const isMountedRef = useIsMountedRef();

  const handlePushLogin = () => {
    setIsLoading(true);

    history.push(ConstanteRotas.LOGIN);

    if (isMountedRef.current) setIsLoading(false);
  };

  return (
    <VStack spacing={9}>
      <VStack spacing={2} alignItems="flex-start" w="full">
        <Title>Senha redefinida!</Title>
        <Paragraph>
          Agora você pode usar sua nova senha para <br /> entrar na sua conta
        </Paragraph>
      </VStack>

      <ButtonSubmit
        type="button"
        isLoading={isLoading}
        isDisabled={isLoading}
        onClick={handlePushLogin}
      >
        Voltar para tela de acesso
      </ButtonSubmit>
    </VStack>
  );
};
