import { UseFormReturn } from 'react-hook-form';
import { ReactNode } from 'react';

import { setDateMinHours, setDateMaxHours } from 'helpers/data/setHoursDate';

import {
  ForwardRefData,
  LoadMoreRowsParams,
} from 'components/update/Table/VirtualizedInfiniteList';
import { GridPaginadaRetorno } from 'components/Grid/Paginacao';

export const formDefaultValues: FormData = {
  conciliado: null,
  dataCompensacaoInicio: null,
  dataCompensacaoFim: null,
  formaPagamentoBaixaId: null,
  codigoAutorizacao: null,
  dataEmissaoInicio: setDateMinHours(new Date()),
  dataEmissaoFim: setDateMaxHours(new Date()),
  dataPagamentoInicio: null,
  dataPagamentoFim: null,
  dataVencimentoInicio: null,
  dataVencimentoFim: null,
};

export const totalizadoresDefaultValues = {
  valorTotalLiquido: 0,
  valorTotalBruto: 0,
  totalTaxa: 0,
  quantidadeContas: 0,
};

export type FormData = {
  conciliado?: boolean | null;
  dataVencimentoInicio?: Date | null;
  dataVencimentoFim?: Date | null;
  dataEmissaoInicio?: Date | null;
  dataEmissaoFim?: Date | null;
  dataPagamentoInicio?: Date | null;
  dataPagamentoFim?: Date | null;
  dataCompensacaoInicio?: Date | null;
  dataCompensacaoFim?: Date | null;
  formaPagamentoBaixaId?: string | null;
  codigoAutorizacao?: string | null;
  taxa?: {
    [key: string]: number | string;
  };
  dataCompensacao?: {
    [key: string]: Date | string;
  };
  checkboxSelecionar?: {
    [key: string]: boolean;
  };
};

type Totalizadores = {
  valorTotalBruto: number;
  valorTotalLiquido: number;
  totalTaxa: number;
  quantidadeContas: number;
};

export type Conta = {
  conciliado: boolean;
  dataCompensacao: Date | string;
  dataEmissaoOperacao: Date;
  dataPagamento: Date;
  dataVencimento: Date;
  desconto: number;
  formaRecebimentoNome: string;
  idMovimentacaoFinanceiraBaixa: string;
  juros: number;
  lancamentoIdentificador: string;
  multa: number;
  parcelaExibicao: string;
  taxa: number;
  valor: number;
  valorLiquido: number;
  isChecked: boolean;
  regraMeioPagamento: number;
  dataAntecipacao: Date | string | null;
  antecipavel: boolean;
};

export type GridPaginadaRetornoConciliacao = GridPaginadaRetorno<Conta> &
  Totalizadores;

export type AntecipacaoRequest = {
  dataAntecipacao: string | Date;
  clienteFornecedorId: string;
  custoAntecipacao: number;
  contaFinanceiraId: string;
  formaPagamentoId: string;
};

export type ConciliacaoContasContextProps = {
  formMethods: UseFormReturn<FormData>;
  listContas: Conta[];
  totalizadoresListagem: {
    valorTotalBruto: number;
    valorTotalLiquido: number;
    totalTaxa: number;
    quantidadeContas: number;
  };
  isLoading: boolean;
  hasFilters: boolean;
  refVirtualizedTable: React.RefObject<ForwardRefData>;
  isListContaEmpty: boolean;
  hasSomenteContasConciliadas: boolean;
  isSelectedContasEmpty: boolean;
  isAllContasSelected: boolean;
  totalSelectedContas: number;
  todasContasPodemSerAntecipadas: boolean;
  totalTaxasFromSelectedContas: number;
  totalValorLiquidoFromSelectedContas: number;
  totalValorBrutoFromSelectedContas: number;
  fetchMoreRows: (
    endpoint: string,
    paginationData: LoadMoreRowsParams,
    params: { [key: string]: unknown }
  ) => Promise<
    | GridPaginadaRetornoConciliacao
    | {
        registros: Conta[];
        total: number;
        valorTotalBruto: number;
        totalTaxa: number;
        valorTotalLiquido: number;
      }
  >;
  resetPageAndReloadTable: () => void;
  openModalCompartilharRelatorios: () => Promise<void>;
  openModalAvisoCancelamentoAntecipacao: (id: string) => Promise<void>;
  clearFilters: () => void;
  loadMoreRows: (params: LoadMoreRowsParams) => Promise<void>;
  filtersSubmit: (data: FormData) => Promise<void>;
  openModalBuscaAvancada: () => Promise<void>;
  openModalAlterarEmMassa: () => Promise<void>;
  openModalAnteciparContas: () => Promise<void>;
  confirmarDataEmissao: () => void;
  handleConciliarEmMassa: () => Promise<void>;
  handleConciliarUmaConta: (conta: Conta) => Promise<void>;
  handleDesconciliar: (id: string) => Promise<void>;
  setListContas: React.Dispatch<React.SetStateAction<Conta[]>>;
};

export interface ConciliacaoContasProviderProps {
  children: ReactNode;
}
