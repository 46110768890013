import { VStack } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import TipoProdutoEnum from 'constants/enum/tipoProduto';
import {
  useProdutosFormularioContext,
  LojaProps,
  TabelaPrecoProps,
  KitProps,
} from 'store/Produtos/ProdutosFormulario';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteFuncionalidades from 'constants/permissoes';
import auth from 'modules/auth';

import { VariacaoProps } from 'components/Modal/ModalAdicionarVariacao';

import { PrecoPorLoja } from './PrecoPorLoja';
import { Container } from './Container';
import { PrecoVenda } from './PrecoVenda';
import { PrecoVariacao } from './PrecoVariacao';

type LojaResponseProps = {
  id: string;
  produtoId: string;
  lojaId: string;
  precoCompra: number;
  precoCusto: number;
  precoVenda: {
    precoVenda: number;
    markup: number;
  };
};

interface TabelaPrecoResponse extends LojaResponseProps {
  tabelaPrecoId: string;
}

export const Preco = () => {
  const [listDetalheLoja, setListDetalheLoja] = useState<LojaProps[]>([]);
  const [listDetalheTabelaPreco, setListDetalheTabelaPreco] = useState<
    TabelaPrecoProps[]
  >([]);
  const [kit, setKits] = useState<KitProps[]>([]);
  const [valoresIniciaisAlterados, setValoresIniciaisAlterados] = useState(
    false
  );

  const {
    tipoProduto,
    ref,
    idProduto,
    lojas,
    setIsLoading,
    tabelaPreco,
    setPrecoPorLoja,
    onChangeFormIsDirty,
    formFields: { replace },
  } = useProdutosFormularioContext();

  const {
    setValue,
    getValues,
    formState: { isDirty },
  } = useFormContext();

  const isVariacao = tipoProduto === TipoProdutoEnum.PRODUTO_VARIACAO;
  const isProdutoKit = tipoProduto === TipoProdutoEnum.PRODUTO_KIT;

  const { id } = auth.getLoja();

  const possuiPermissaoVisualizarPrecoCusto = auth.possuiPermissao(
    ConstanteFuncionalidades.USUARIO_VISUALIZAR_PRECO_CUSTO
  ).permitido;

  const getKits = useCallback(async () => {
    if (isProdutoKit === false) {
      return;
    }
    const response = await api.get<void, ResponseApi<KitProps[]>>(
      `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/kits`
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso && response.dados.length > 0) {
        setKits(response.dados);
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [idProduto, isProdutoKit, setIsLoading]);

  const possuiKit = kit.length > 0;

  const getListDetalheLoja = useCallback(async () => {
    const response = await api.get<void, ResponseApi<LojaResponseProps[]>>(
      `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/precos/lojas`
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso) {
        const { dados } = response;

        const newListLojas = dados.map((lojaItem) => {
          const detalheLoja = lojas.find((item) => item.id === lojaItem.lojaId);

          const data = {
            ...lojaItem,
            fantasia: detalheLoja?.fantasia,
            cidade: detalheLoja?.cidade,
            endereco: detalheLoja?.endereco,
          };

          return data;
        }) as LojaProps[];

        newListLojas.forEach((newValueData) => {
          const precoCompra = kit.reduce(
            (acc, curr) => acc + curr.precoOriginal,
            0
          );

          const precoCusto = kit.reduce(
            (acc, curr) => acc + curr.precoCusto * curr.quantidade,
            0
          );

          setValue(
            `valuePrecoCompra.${newValueData.lojaId}`,
            possuiKit
              ? precoCompra
              : newValueData.precoCompra !== 0
              ? newValueData.precoCompra
              : getValues()?.valorUnitario || 0
          );
          setValue(
            `valuePrecoCusto.${newValueData.lojaId}`,
            possuiKit
              ? precoCusto
              : newValueData.precoCusto !== 0
              ? newValueData.precoCusto
              : getValues()?.valorUnitario || 0
          );
        });

        setListDetalheLoja(newListLojas);
        return newListLojas;
      }

      return [];
    }
    return [];
  }, [idProduto, kit, lojas, possuiKit, getValues, setValue]);

  const getListDetalheTabelaPreco = useCallback(
    async (loja: LojaProps[]) => {
      const response = await api.get<void, ResponseApi<TabelaPrecoResponse[]>>(
        `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/precos/tabela-precos/produtos`
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }

        if (response.sucesso) {
          const dadosIsNull = response.dados === null;
          const newListTabelaPreco = tabelaPreco?.map((tabelaItem, index) => {
            const tabelaCadastrada = (response.dados || []).find(
              (itemCadastrado) => itemCadastrado.tabelaPrecoId === tabelaItem.id
            );

            const precoVenda = tabelaCadastrada?.precoVenda.precoVenda || 0;
            const precoCusto = tabelaCadastrada?.precoCusto || 0;

            const data = {
              cidade: '',
              endereco: '',
              fantasia: '',
              lojaId: `${id}${index}`,
              precoCompra: tabelaCadastrada?.precoCompra || 0,
              precoCusto,
              isTabelaPreco: true,
              precoVenda: {
                markup: (precoVenda / precoCusto - 1) * 100 || 0,
                precoVenda: precoVenda || 0,
              },
              produtoId: idProduto,
              tabelaPreco: tabelaItem.nome,
              tabelaPrecoId: tabelaItem.id,
              padraoSistema: tabelaItem.padraoSistema,
            } as TabelaPrecoProps;

            return dadosIsNull ? data : { ...data, id: tabelaCadastrada?.id };
          }) as TabelaPrecoProps[];

          const newValue = [
            ...loja.map((item) => ({
              ...item,
              isTabelaPreco: false,
              tabelaPreco: 'Padrão',
              padraoSistema: false,
            })),
            ...(newListTabelaPreco || []),
          ];

          setListDetalheTabelaPreco(newValue);
        }
      }
    },
    [idProduto, tabelaPreco, id]
  );

  const getVariacao = useCallback(async () => {
    const response = await api.get<void, ResponseApi<VariacaoProps>>(
      `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/precos/tabela-precos/produtoCorTamanhos`
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso) {
        const { dados } = response;

        const newListTabelaPreco = dados?.map((tabelaItem) => {
          const detalheLoja = tabelaPreco?.find(
            (item) => item?.id === tabelaItem?.tabelaPrecoId
          );

          const data = {
            ...tabelaItem,
            tabelaPreco: detalheLoja?.nome,
          };

          return data;
        }) as VariacaoProps;

        replace(newListTabelaPreco || []);
      }
    }
  }, [idProduto, tabelaPreco, replace]);

  const cadastrarLojas = useCallback(async () => {
    const listPrecoLoja = listDetalheLoja.map((lojaItem) => ({
      id: lojaItem.id,
      lojaId: lojaItem.lojaId,
      produtoId: lojaItem.produtoId,
      precoCompra: getValues(`valuePrecoCompra.${lojaItem.lojaId}`),
      precoCusto: getValues(`valuePrecoCusto.${lojaItem.lojaId}`),
      precoVenda: {
        precoVenda: getValues(`valuePrecoVenda.${lojaItem.lojaId}`),
        markup: getValues(`valueMarkup.${lojaItem.lojaId}`),
      },
    }));

    const response = await api.put<void, ResponseApi<boolean>>(
      `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/precos/lojas`,
      [...listPrecoLoja]
    );
    setPrecoPorLoja(
      listPrecoLoja.map((itemPrecoLoja) => ({
        id: itemPrecoLoja.id || '',
        lojaId: itemPrecoLoja.lojaId,
        markup: itemPrecoLoja.precoVenda.markup,
        precoCompra: itemPrecoLoja.precoCompra,
        precoCusto: itemPrecoLoja.precoCusto,
        precoVenda: itemPrecoLoja.precoVenda.precoVenda,
        produtoId: itemPrecoLoja.produtoId,
      }))
    );
    return response;
  }, [getValues, idProduto, listDetalheLoja, setPrecoPorLoja]);

  const cadastrarTabelaPreco = useCallback(async () => {
    const response = await api.put<void, ResponseApi<boolean>>(
      `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/precos/tabela-precos/produtos`,
      listDetalheTabelaPreco
        .filter((tabelaItem) => tabelaItem.tabelaPrecoId)
        .map((tabelaItem) => ({
          id: tabelaItem.id,
          tabelaPrecoId: tabelaItem.tabelaPrecoId,
          produtoId: tabelaItem.produtoId,
          precoVenda: {
            precoVenda: getValues(`valuePrecoVenda.${tabelaItem.lojaId}`),
            markup: getValues(`valueMarkup.${tabelaItem.lojaId}`),
          },
        }))
    );
    return response;
  }, [getValues, idProduto, listDetalheTabelaPreco]);

  const cadastrarProdutoCor = useCallback(async () => {
    if (!isVariacao) {
      return null;
    }
    const response = await api.put<void, ResponseApi<boolean>>(
      `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/precos/tabela-precos/produtoCorTamanhos`,
      (getValues('tabelaPrecoProdutoCorTamanhos') as VariacaoProps)?.map(
        (variacaoItem) => ({
          tabelaPrecoId: variacaoItem.tabelaPrecoId,
          produtoCorTamanhoId: variacaoItem.produtoCorTamanhoId,
          precoVenda: {
            precoVenda: variacaoItem.precoVenda.precoVenda,
            markup: variacaoItem.precoVenda.markup,
          },
          produtoCorTamanho: {
            produtoCorTamanhoId: variacaoItem.produtoCorTamanhoId,
            produto: variacaoItem.produtoCorTamanho.produto,
            cor: variacaoItem.produtoCorTamanho.cor,
            tamanho: variacaoItem.produtoCorTamanho.tamanho,
          },
        })
      )
    );
    return response;
  }, [getValues, isVariacao, idProduto]);

  const salvarProdutos = useCallback(async () => {
    if (isProdutoKit) {
      return true;
    }

    if (valoresIniciaisAlterados === false) {
      return true;
    }

    setIsLoading(true);
    return Promise.all([
      cadastrarLojas(),
      cadastrarTabelaPreco(),
      cadastrarProdutoCor(),
    ]).then(async ([responseLoja, responseTabelaPreco, responseProduto]) => {
      const valueLoja = await responseLoja;
      const valueTabelaPreco = await responseTabelaPreco;
      const valueProduto = await responseProduto;

      const response = [valueLoja, valueTabelaPreco, valueProduto];

      const sucesso = [] as boolean[];

      response.filter(Boolean).forEach((responseItem) => {
        if (responseItem?.avisos) {
          responseItem.avisos.forEach((item: string) => toast.warning(item));
          sucesso.push(false);
        }

        if (responseItem?.sucesso) {
          sucesso.push(true);
        } else {
          sucesso.push(false);
        }
      });

      const requisicaoFailed = sucesso.some(
        (responseItem) => responseItem === false
      );

      setIsLoading(false);

      return !requisicaoFailed;
    });
  }, [
    cadastrarLojas,
    valoresIniciaisAlterados,
    cadastrarProdutoCor,
    isProdutoKit,
    cadastrarTabelaPreco,
    setIsLoading,
  ]);

  useImperativeHandle(ref, () => ({
    onClick: () => salvarProdutos(),
  }));

  useEffect(() => {
    async function getDataPreco() {
      setIsLoading(true);

      const newLoja = await getListDetalheLoja();
      await getListDetalheTabelaPreco(newLoja || []);
      if (isVariacao) {
        await getVariacao();
      }

      setIsLoading(false);
    }
    getDataPreco();
  }, [
    getListDetalheLoja,
    isVariacao,
    getValues,
    getListDetalheTabelaPreco,
    getVariacao,
    setIsLoading,
  ]);

  useEffect(() => {
    listDetalheTabelaPreco.forEach((tabelaPrecoItem) => {
      const valueLojaAtual = listDetalheTabelaPreco.find(
        (item) => item.lojaId === id
      );

      const precoVenda = tabelaPrecoItem.precoVenda.precoVenda || 0;
      const precoCusto = valueLojaAtual?.precoCusto || 0;

      const valueMarkup =
        precoCusto > 0 && precoVenda > 0
          ? (precoVenda / precoCusto - 1) * 100
          : 0;

      const precoCustoTotal = kit.reduce(
        (acc, curr) => acc + curr.precoCusto * curr.quantidade,
        0
      );

      const precoVendaTotal = kit.reduce(
        (acc, curr) => acc + curr.valor * curr.quantidade,
        0
      );
      const markupTotal =
        precoCustoTotal > 0 ? (precoVendaTotal / precoCustoTotal - 1) * 100 : 0;

      setValue(
        `valueMarkup.${tabelaPrecoItem.lojaId}`,
        possuiKit
          ? markupTotal.toFixed(2)
          : tabelaPrecoItem.precoVenda.markup === Infinity
          ? valueMarkup.toFixed(2)
          : tabelaPrecoItem.precoVenda.markup
      );

      setValue(
        `valuePrecoVenda.${tabelaPrecoItem.lojaId}`,
        possuiKit
          ? precoVendaTotal.toFixed(2)
          : tabelaPrecoItem.precoVenda.precoVenda
      );
    });
  }, [id, kit, listDetalheTabelaPreco, possuiKit, setValue]);

  useEffect(() => {
    getKits();
  }, [getKits]);

  useEffect(() => {
    onChangeFormIsDirty(isDirty);
  }, [isDirty, onChangeFormIsDirty]);

  return (
    <VStack spacing="28px" w="full">
      {possuiPermissaoVisualizarPrecoCusto && (
        <Container bg="gray.50" title="CUSTO do produto">
          <PrecoPorLoja
            tabelaPreco={listDetalheTabelaPreco}
            lojasCadastradas={listDetalheLoja}
            setValoresIniciaisAlterados={setValoresIniciaisAlterados}
          />
        </Container>
      )}
      <Container bg="gray.50" title="Preço de VENDA">
        <PrecoVenda
          setValoresIniciaisAlterados={setValoresIniciaisAlterados}
          tabelaPreco={listDetalheTabelaPreco}
        />
      </Container>
      {isVariacao && (
        <Container bg="gray.50" title="Variações com preço especial">
          <PrecoVariacao
            setValoresIniciaisAlterados={setValoresIniciaisAlterados}
          />
        </Container>
      )}
    </VStack>
  );
};
