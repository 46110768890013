import { toast } from 'react-toastify';
import { HubConnection } from '@microsoft/signalr';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import getDateTimeDefaultInput from 'helpers/data/getDateTimeDefaultInput';
import TipoCertificadoEnum from 'constants/enum/fiscal/tipoCertificado';

import { ModalAtencao } from 'components/Modal/ModalAtencao';

import getHeaderChaveTemporaria from '../getHeaderChaveTemporaria';

interface CancelarOperacaoProps {
  id: string;
  motivoCancelamento?: string;
  motivoCancelamentoNotaFiscal?: string;
  tela: number;
}

const cancelarOperacao = async (
  operacaoCancelarViewModel: CancelarOperacaoProps,
  hubConnection: HubConnection,
  joinGroup: (group: string) => Promise<unknown>,
  onSuccess: () => void,
  onError: () => void,
  chavePermissaoTemporaria?: string
) => {
  const response = await api.put<void, ResponseApi<number | undefined>>(
    ConstanteEnderecoWebservice.PEDIDOORCAMENTOVENDA_CANCELAR,
    {
      dataCancelamentoNotafiscal: getDateTimeDefaultInput(new Date()),
      ...operacaoCancelarViewModel,
    },
    getHeaderChaveTemporaria(chavePermissaoTemporaria)
  );
  if (response) {
    if (response.sucesso) {
      if (
        response.dados &&
        operacaoCancelarViewModel.motivoCancelamentoNotaFiscal &&
        response.dados === Number(TipoCertificadoEnum.A3)
      ) {
        const timeout = window.setTimeout(
          () => {
            onError();
            ModalAtencao({
              title: 'A comunicação com o certificado digital A3 falhou',
              text:
                'Verifique se o certificado digital está conectado no computador e se o aplicativo Módulo desktop está sendo executado.',
            });
          },
          1000 * 60 // 1 minuto
        );

        await joinGroup(`${operacaoCancelarViewModel.id}_transmitindo-evento`);

        hubConnection.off('sucesso-transmissao-a3');
        hubConnection.on('sucesso-transmissao-a3', () => {
          window.clearTimeout(timeout);

          onSuccess();
        });

        hubConnection.off('rejeicao-transmissao-a3');
        hubConnection.on('rejeicao-transmissao-a3', (message: string) => {
          window.clearTimeout(timeout);

          onError();
          toast.warning(message);
        });

        return;
      }

      onSuccess();
    }

    if (response.avisos) {
      response.avisos.forEach((item: string) => toast.warning(item));

      onError();
    }
  }
};

export default cancelarOperacao;
