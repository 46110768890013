import React from 'react';
import {
  Box,
  Divider,
  Flex,
  Icon,
  IconButton,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalProps,
  Text,
} from '@chakra-ui/react';
import { FiChevronLeft } from 'react-icons/fi';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';

interface ModalAdicionarEditarItemProps extends ModalProps {
  asMobileView?: boolean;
  title: string;
}

export function ModalAdicionarEditarItem({
  isOpen,
  onClose,
  title,
  asMobileView = false,
  children,
  ...rest
}: ModalAdicionarEditarItemProps) {
  if (!asMobileView) {
    return <>{children}</>;
  }

  if (!isOpen) {
    return null;
  }

  return (
    <ModalPadraoChakra
      motionPreset="none"
      size="full"
      {...rest}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalContent
        margin="0"
        borderRadius="0"
        overflowX="hidden"
        overflowY="auto"
      >
        <ModalHeader p="0">
          <Flex alignItems="center" w="full" mx="4" my="1.5">
            <IconButton
              aria-label="Fechar"
              left=""
              variant="link"
              size="sm"
              colorScheme="blue"
              p="1"
              minW="6"
              icon={<Icon as={FiChevronLeft} boxSize="7" strokeWidth="1.5" />}
              zIndex="tooltip"
              onClick={onClose}
            />

            <Flex h="40px" maxW="calc(100% - 58px)" alignItems="center">
              <Text
                fontSize="md"
                px="1.5"
                lineHeight="none"
                fontWeight="normal"
                isTruncated
              >
                {title}
              </Text>
            </Flex>
          </Flex>

          <Box mx="4">
            <Divider
              borderBottomWidth="2px"
              borderColor="gray.100"
              transition="border-color .2s"
            />
          </Box>
        </ModalHeader>
        <ModalBody p="4">{children}</ModalBody>
      </ModalContent>
    </ModalPadraoChakra>
  );
}
