import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

const schema = yup.object().shape({
  numeroSerie: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .max(999, 'Valor máximo permitido é 999'),

  numeroInicial: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .min(1, 'O campo deve ser maior que 0')
    .max(999999999, 'Valor máximo permitido é 999999999'),

  numeroFinal: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .min(1, 'O campo deve ser maior que 0')
    .max(999999999, 'Valor máximo permitido é 9999999999')
    .test(
      'verificarMaiorMenorNumeroFinal',
      'O campo deve ser maior ou igual ao Número inicial',
      function verificarMaiorMenorNumeroFinal(this: yup.TestContext<any>) {
        const { numeroInicial, numeroFinal } = this.parent;
        if (numeroFinal && numeroFinal < numeroInicial) {
          return false;
        }
        return true;
      }
    ),
  motivo: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .min(15, 'O campo deve ter pelo menos 15 caracteres')
    .max(255, 'Este campo não aceita mais que 255 caracteres'),
  modeloFiscal: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);

export const useForm = useFormInstance;
