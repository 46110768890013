import { useRef } from 'react';
import {
  Text,
  TableContainer,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  Table,
  Box,
} from '@chakra-ui/react';

import { DecimalMask } from 'helpers/format/fieldsMasks';

export type ConferenciaCaixa = {
  formaRecebimentoId: string;
  formaRecebimentoNome: string;
  valor: number;
  valorConferido: number;
  diferenca: number;
  consideraSaldoInicial: true;
};

type ConferenciaCaixaValues = {
  conferenciaCaixaProps?: ConferenciaCaixa[];
};

type ConsideraSaldoInicialProps = {
  nome: string;
};

const TableResumoConferencia = ({
  conferenciaCaixaProps,
}: ConferenciaCaixaValues) => {
  const consideraSaldoInicial = useRef<ConsideraSaldoInicialProps | null>(null);

  return (
    <Box>
      <Text mt="2" fontSize="xs" fontWeight="gray.700">
        Conferência de Caixa:
      </Text>
      <TableContainer
        sx={{
          '& div, & table, & tr, & p': {
            color: 'black',
            fontWeight: '900',
            fontSize: '10px',
            fontFamily: 'arial',
          },
          '& div, & hr, & table, & tr, & p': {
            opacity: 'unset',
            fontSize: '10px',
            borderColor: 'black',
          },
        }}
        pt="1"
      >
        <Table
          sx={{
            '& tr, & th, & td, & tbody, & thead': {
              color: 'black',
              fontWeight: '900',
              fontFamily: 'arial',
              opacity: 'unset',
              borderColor: 'black',
            },
          }}
        >
          <Thead>
            <Tr
              sx={{
                '& > th + th': {
                  textAlign: 'center',
                  color: 'black',
                  fontWeight: '900',
                  borderColor: 'black',
                  padding: '1px',
                  fontSize: '2xs',
                },
                '& > th': {
                  fontSize: '2xs',
                  color: 'black',
                  fontWeight: '900',
                  borderColor: 'black',
                  margin: 0,
                  border: 0,
                  padding: '1px',
                },
              }}
            >
              <Th>
                <Text> Forma Recto</Text>
              </Th>
              <Th>
                <Text> Valor</Text>
              </Th>
              <Th>
                <Text> Informado</Text>
              </Th>
              <Th>
                <Text> Diferenca</Text>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {(conferenciaCaixaProps || [])
              .filter((item) => item?.valor !== 0)
              .map((item, index) => {
                if (item.consideraSaldoInicial) {
                  consideraSaldoInicial.current = {
                    nome: item.formaRecebimentoNome,
                  };
                }

                return (
                  <Tr
                    sx={{
                      '& > td + td': {
                        textAlign: 'center',
                        padding: '1px',
                        fontSize: '2xs',
                        fontWeight: 'extrabold',
                      },
                      '& > td': {
                        fontSize: '2xs',
                        padding: '1px',
                        fontWeight: 'extrabold',
                      },
                    }}
                    key={String(index)}
                  >
                    <Td>
                      <Text>{item.formaRecebimentoNome}</Text>
                    </Td>
                    <Td>{DecimalMask(item.valor, 2)}</Td>
                    <Td>{DecimalMask(item.valorConferido, 2)}</Td>
                    <Td>{DecimalMask(item.diferenca, 2)}</Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
      {conferenciaCaixaProps
        ?.filter((item) => item?.consideraSaldoInicial)
        .map((caixa) => (
          <Text
            fontSize="2xs"
            mt="2"
            borderTop="1px dashed"
            borderTopColor="black"
            paddingTop="2"
          >
            <Text as="span" mr="1" fontWeight="bold">
              {caixa.formaRecebimentoNome}:
            </Text>
            Saldo abertura + Total de movimentações
          </Text>
        ))}
    </Box>
  );
};

export default TableResumoConferencia;
