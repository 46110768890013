import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

const schema = yup.object().shape(
  {
    vencimento: yup
      .date()
      .nullable()
      .default(null)
      .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
    cnpjPagamentoAlterar: yup.string().when('ufPagamentoAlterar', {
      is: (ufPagamentoAlterar: string) =>
        ufPagamentoAlterar === '' ||
        ufPagamentoAlterar === null ||
        ufPagamentoAlterar === undefined,
      then: yup.string().nullable().notRequired(),
      otherwise: yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
    }),
    ufPagamentoAlterar: yup.string().when('cnpjPagamentoAlterar', {
      is: (cnpjPagamentoAlterar: string) =>
        cnpjPagamentoAlterar === '' ||
        cnpjPagamentoAlterar === null ||
        cnpjPagamentoAlterar === undefined,
      then: yup.string().nullable().notRequired(),
      otherwise: yup
        .string()
        .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
    }),
  },
  [['cnpjPagamentoAlterar', 'ufPagamentoAlterar']]
);

export const yupResolver = yupResolverInstance(schema);

export const useForm = useFormInstance;
