import React, { useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Prompt, useHistory } from 'react-router-dom';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteRotas from 'constants/rotas';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import isPrenvent from 'helpers/layout/isPrenvent';

import ManterFoco from 'components/Geral/ManterFoco';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { FormFooter } from 'components/update/Form/FormFooter';

import Formulario from '..';
import {
  yupResolver,
  FormData,
  CadastrarLocalEstoqueProps,
} from '../validationForm';

async function cadastrarLocalEstoque(data: FormData) {
  const response = await api.post<void, ResponseApi>(
    ConstanteEnderecoWebservice.LOCAL_ESTOQUE_CADASTRAR,
    {
      ...data,
    }
  );

  if (response) {
    if (response.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (response.sucesso) {
      return true;
    }
  }

  return false;
}

const Cadastrar = ({
  callbackCadastrarSucesso,
}: CadastrarLocalEstoqueProps) => {
  const history = useHistory();
  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues: {
      nome: '',
      ativo: true,
    },
  });
  const isMountedRef = useIsMountedRef();

  const { dirtyFields } = formMethods.formState;
  const isDirty =
    dirtyFields &&
    dirtyFields.constructor === Object &&
    Object.keys(dirtyFields).length > 0;

  const [isPrenvented, setIsPrenvented] = useState(false);
  isPrenvent(isPrenvented);

  const [isLoading, setIsLoading] = useState(false);

  function historyPush(path: string) {
    setIsPrenvented(false);

    history.push(path);
  }

  function historyReload() {
    setIsPrenvented(false);

    history.replace('/');
    history.replace(ConstanteRotas.LOCAL_ESTOQUE_CADASTRAR);
  }

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    setIsLoading(true);

    const success = await cadastrarLocalEstoque(data);

    if (success) {
      toast.success('O cadastro foi realizado com sucesso.');
      if (callbackCadastrarSucesso) {
        callbackCadastrarSucesso(data);
      } else {
        historyPush(ConstanteRotas.LOCAL_ESTOQUE);
      }
    }

    if (isMountedRef.current) setIsLoading(false);
  });

  const handleSubmitReset = formMethods.handleSubmit(async (data) => {
    setIsLoading(true);

    const success = await cadastrarLocalEstoque(data);

    if (success) {
      toast.success('O cadastro foi realizado com sucesso.');

      historyReload();

      setIsLoading(false);

      formMethods.setFocus('nome');
    }

    if (isMountedRef.current) setIsLoading(false);
  });

  useEffect(() => {
    setIsPrenvented(isDirty);
  }, [isDirty]);

  return (
    <Box maxW="1116px">
      <Prompt when={isPrenvented} message="" />
      <ManterFoco>
        {isLoading && <LoadingPadrao />}
        <FormProvider {...formMethods}>
          <Formulario />
        </FormProvider>

        <FormFooter
          onSubmit={handleSubmit}
          onSubmitReset={handleSubmitReset}
          isDisabled={isLoading}
        />
      </ManterFoco>
    </Box>
  );
};

export default Cadastrar;
