import { useEffect, useRef } from 'react';

function useIsMountedRef() {
  const isMountedRef = useRef<boolean | null>(null); // Importante o uso da ref no lugar de um state para previnir renderizações desnecessárias, visto os dados aqui citados não tem efeito visual na aplicação.

  useEffect(() => {
    isMountedRef.current = true; // Assim que o componente é montado, true é passado para a ref.

    return () => {
      isMountedRef.current = false; // Assim que o componente é desmontado, false é passado para a ref.
    };
  });

  return isMountedRef;
}

export default useIsMountedRef;
