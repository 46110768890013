import { useState } from 'react';
import {
  Flex,
  ModalBody,
  Text,
  Button,
  VStack,
  Icon,
  Box,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { WhatsAppModal } from 'components/PDV/CompartilharDocumentosFiscais/WhatsAppModal';
import { TextValor } from 'components/PDV/Text/TextValor';
import { CopiarIcon, WhatsAppZoopIcon, QRcodeZoopIcon } from 'icons';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { QrCodePix } from '../components/QrCodePix';
import { PaymentStatus } from '../components/PaymentStatus';
import { PixProps } from './types';

export const Pix = ({
  finalizarPagamento,
  handleCancelar,
  operacao: {
    messageTransacao,
    valor,
    qrCode,
    linkTransacao,
    isPagamentoNaTela,
    cliente,
  },
  screenResolution: { isLargerThan765, isLargerThan900 },
  transactionStatus,
}: PixProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showQrCode, setShowQrCode] = useState(isPagamentoNaTela);

  const backgroundModal = `linear-gradient(180deg, rgba(85,2,178,1) ${
    isLargerThan900 ? '42.5%' : '66.0%'
  }, rgba(232,232,232,1) 40%)`;

  const handleEnviarWhatsApp = async () => {
    if (cliente) {
      WhatsAppModal({
        cliente,
        messageLinkZoop: `Olá ${cliente.nome}! Segue o link para pagamento: ${linkTransacao}`,
      });
    }
  };

  const handleGerarVenda = async () => {
    setIsLoading(true);
    await finalizarPagamento();
    setIsLoading(false);
  };

  const handleShowQrCode = () => {
    setShowQrCode(true);
  };

  const handleCopyLink = () => {
    toast.success('Link copiado para a área de transferência');
    navigator.clipboard.writeText(linkTransacao);
  };

  const handleOpenLink = () => {
    window.open(linkTransacao, '_blank');
  };

  return (
    <ModalBody p="0">
      {isLoading && <LoadingPadrao />}
      {showQrCode ? (
        <>
          <Flex
            w="full"
            height={['full', '560px']}
            flexDir={['column', 'column', 'row']}
            alignItems="center"
            px="64px"
            pt={['64px', '64px', '0px']}
            borderRadius={['0px', '5px']}
            gap={['unset', '42px']}
            bg={backgroundModal}
          >
            <QrCodePix qrCode={qrCode} isLargerThan765={isLargerThan765} />
            <Flex
              w="full"
              alignItems="center"
              justifyContent="flex-start"
              flexDir="column"
              height="full"
              fontWeight="extrabold"
              pt={['60px', '148px']}
              gap="36px"
            >
              <TextValor
                fontSize="50px"
                fontWeight="bold"
                symbolFontSize="22px"
                symbolFontWeight="semibold"
                valor={valor || 0}
                color="secondary.300"
                casasDecimais={2}
              />
              <PaymentStatus
                paymentStatus={transactionStatus}
                messageTransacao={messageTransacao}
                handleCancelar={handleCancelar}
                finalizarPagamento={finalizarPagamento}
              />
            </Flex>
          </Flex>
        </>
      ) : (
        <Flex
          w="full"
          height={['full', '560px']}
          flexDir="column"
          alignItems="center"
          px={['12px', '24px', '56px']}
          pt="32px"
          borderRadius={['0px', '5px']}
          bg={backgroundModal}
        >
          <Flex
            justifyContent="space-between"
            w="full"
            color="white"
            align="center"
          >
            <Text fontSize="lg">Link de pagamento:</Text>
            <Text fontSize="sm">Pix - Zoop</Text>
          </Flex>
          <TextValor
            fontSize="50px"
            fontWeight="bold"
            symbolFontSize="22px"
            symbolFontWeight="semibold"
            valor={valor || 0}
            alignItems="center"
            color="secondary.300"
            casasDecimais={2}
            height="130px"
          />

          <Flex
            w="full"
            alignItems="flex-start"
            flexDir="column"
            padding={['24px', '40px']}
            height="258px"
            bg="white"
            borderRadius="12px"
            gap="2px"
          >
            <Text fontSize="md" color="gray.700">
              Copie e cole o link onde quiser:
            </Text>
            <Flex w="full" gap="8px">
              <Text
                bg="gray.50"
                fontSize="md"
                fontWeight="semibold"
                borderWidth="1px"
                borderColor="gray.200"
                borderRadius="5px"
                borderStyle="solid"
                color="violet.500"
                wordBreak="break-all"
                noOfLines={1}
                h="38px"
                w="full"
                px="12px"
                py="8px"
                cursor="pointer"
                onClick={handleOpenLink}
              >
                {linkTransacao}
              </Text>
              <Flex
                w="80px"
                h="38px"
                borderRadius="5px"
                bg="primary.50"
                align="center"
                justify="center"
                _hover={{ bg: 'primary.100' }}
                marginRight="5px"
                cursor="pointer"
                onClick={handleCopyLink}
              >
                <Icon
                  as={CopiarIcon}
                  width="32px"
                  height="24px"
                  color="white"
                />
              </Flex>
            </Flex>
            <Flex
              fontSize="sm"
              color="gray.700"
              mt={['24px', '12px']}
              w="full"
              gap="72px"
              justify="center"
            >
              <VStack
                cursor="pointer"
                onClick={handleEnviarWhatsApp}
                padding="8px"
                borderRadius="5px"
              >
                <Icon as={WhatsAppZoopIcon} boxSize="56px" />
                <Text>WhatsApp</Text>
              </VStack>
              <VStack
                cursor="pointer"
                padding="8px"
                borderRadius="5px"
                onClick={handleShowQrCode}
              >
                <Box
                  p="8px"
                  mb="4px"
                  boxShadow="0px 3px 6px #00000029"
                  borderRadius="8px"
                >
                  <Icon as={QRcodeZoopIcon} boxSize="36px" />
                </Box>
                <Text>QRCode</Text>
              </VStack>
            </Flex>
          </Flex>

          <Flex gap="24px">
            <Button
              variant="cancel"
              h="32px"
              mt="48px"
              width="100px"
              onClick={handleCancelar}
            >
              Cancelar
            </Button>

            <Button
              variant="solid"
              colorScheme="secondary"
              mt="48px"
              width="160px"
              height="32px"
              onClick={handleGerarVenda}
            >
              Gerar venda
            </Button>
          </Flex>
        </Flex>
      )}
    </ModalBody>
  );
};
