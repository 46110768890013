import React, { useRef, memo, useState, useCallback } from 'react';
import { Controller, FieldError } from 'react-hook-form';
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import {
  isDataURI,
  dataURItoBase64,
  formatBytes,
  byteToSize,
  calcDataUriBytesSize,
  testIfIsUrl,
} from 'helpers/validation/imagesAttachment';

import ContainerInputPadrao from 'components/Layout/CampoContainer/CampoContainerPadrao';

import { ArrastarImagemIcon } from 'icons';
import {
  ImagePickerContainer,
  ImagePreview,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputFile,
  DefaultContent,
  Modal,
  ModalBody,
  BreakpointsSizes,
  SpanLegenda,
  SpanLink,
} from './styles';

interface ImagePickerQuadradoProps {
  id: string;
  name: string;
  label: string;
  required?: boolean;
  height: BreakpointsSizes;
  width: BreakpointsSizes;
  control: any;
  error?: FieldError | null;
  readonly?: boolean;
  infoText?: string;
  widthCanvas: number;
  heightCanvas: number;
}

const ImagePickerQuadradoOuRetangular = ({
  id,
  name,
  label,
  required,
  height,
  width,
  control,
  error = null,
  readonly,
  infoText,
  widthCanvas,
  heightCanvas,
}: ImagePickerQuadradoProps) => {
  const { t } = useTranslation();

  const inputFileRef = useRef<HTMLInputElement>(null);

  const [showImageIsOpen, setShowImageIsOpen] = useState(false);

  const openUpload = () => {
    if (inputFileRef.current) inputFileRef.current.click();
  };

  const calculateAspectRatioFit = (
    srcWidth: number,
    srcHeight: number,
    maxWidth: number,
    maxHeight: number
  ) => {
    const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

    return { imageWidth: srcWidth * ratio, imageHeight: srcHeight * ratio };
  };

  const updatePreview = (file: File, onChange: (v: any) => void) => {
    const fileFormat = file.type.split('/')[1];

    if (fileFormat !== 'png' && fileFormat !== 'jpeg') {
      toast.warning(
        t(
          'A extensão do arquivo selecionado é inválida, este campo aceita somente arquivos .png e .jpg'
        )
      );

      return;
    }

    const reader = new FileReader();
    reader.onload = (readerEvent) => {
      const image = new Image();
      image.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = widthCanvas;
        canvas.height = heightCanvas;

        const ctx = canvas.getContext('2d');

        let offsetX = 0;
        let offsetY = 0;

        const { imageWidth, imageHeight } = calculateAspectRatioFit(
          image.width,
          image.height,
          widthCanvas,
          heightCanvas
        );

        offsetY = (heightCanvas - imageHeight) / 2;
        offsetX = (widthCanvas - imageWidth) / 2;

        if (ctx) {
          ctx.fillStyle = 'white';
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(image, offsetX, offsetY, imageWidth, imageHeight);
        }

        const dataUrlImage = canvas.toDataURL('image/jpeg');

        if (isDataURI(dataUrlImage)) {
          const bytesSize = calcDataUriBytesSize(dataUrlImage);

          if (byteToSize(bytesSize, 'MB') > 1) {
            toast.warning(
              t(
                'O arquivo selecionado é muito grande {{tamanhoArquivo}}, este campo aceita somente imagens de até 1MB',
                { tamanhoArquivo: formatBytes(bytesSize) }
              )
            );

            return;
          }

          onChange(dataURItoBase64(dataUrlImage));
        } else {
          onChange(null);
        }
      };

      image.src = readerEvent.target?.result?.toString() || '';
    };

    reader.readAsDataURL(file);
  };

  const formattedValue = useCallback((value: string) => {
    if (!testIfIsUrl(value)) return `data:image/jpeg;base64,${value}`;

    return value;
  }, []);

  const exibirImagem = (value: any) =>
    value ? (
      <ImagePreview src={formattedValue(value)} />
    ) : (
      <DefaultContent>
        <ArrastarImagemIcon style={{ fontSize: '40px' }} />
        <SpanLegenda>Arraste e solte seu arquivo aqui </SpanLegenda>
        <SpanLink>Escolher arquivo</SpanLink>
      </DefaultContent>
    );

  return (
    <ContainerInputPadrao
      id={id}
      label={label}
      error={error}
      required={required}
      infoText={infoText}
    >
      <Controller
        defaultValue={null}
        render={({ field }) => (
          <Dropzone
            accept="image/jpeg, image/png"
            onDrop={(acceptedFiles: any[]) => {
              if (!field.value) {
                if (!acceptedFiles || acceptedFiles.length === 0) {
                  return;
                }
                const file = acceptedFiles[0];

                if (file) updatePreview(file, field.onChange);
              }
            }}
          >
            {({ getRootProps, getInputProps, isDragActive }) => (
              <ImagePickerContainer
                {...getRootProps()}
                style={{
                  cursor: readonly ? '' : 'pointer',
                  filter:
                    isDragActive && !field.value
                      ? 'brightness(80%)'
                      : undefined,
                }}
                height={height}
                width={width}
              >
                {readonly ? (
                  exibirImagem(field.value)
                ) : (
                  <Dropdown id={id} ref={field.ref}>
                    <DropdownToggle>{exibirImagem(field.value)}</DropdownToggle>
                    <DropdownMenu>
                      {field.value ? (
                        <>
                          <DropdownItem
                            onClick={() => {
                              setShowImageIsOpen(true);
                            }}
                          >
                            {t('Ver imagem')}
                          </DropdownItem>
                          <DropdownItem onClick={openUpload}>
                            {t('Escolher outra imagem')}
                          </DropdownItem>

                          <DropdownItem onClick={() => field.onChange(null)}>
                            {t('Remover')}
                          </DropdownItem>
                        </>
                      ) : (
                        <>
                          <DropdownItem onClick={openUpload}>
                            {t('Escolher imagem')}
                          </DropdownItem>
                        </>
                      )}
                    </DropdownMenu>
                  </Dropdown>
                )}

                {field.value && (
                  <Modal
                    show={showImageIsOpen}
                    onHide={() => {
                      setShowImageIsOpen(false);
                    }}
                    centered
                  >
                    <ModalBody>
                      <ImagePreview
                        src={formattedValue(field.value)}
                        style={{ border: 'none', borderRadius: '0px' }}
                      />
                    </ModalBody>
                  </Modal>
                )}

                <InputFile
                  {...getInputProps()}
                  ref={inputFileRef}
                  tabIndex={-1}
                  type="file"
                  accept="image/jpeg, image/png"
                  onChange={(e: any) => {
                    const { files } = e.target;
                    if (!files || files.length === 0) {
                      return;
                    }
                    const file = files[0];

                    if (file) updatePreview(file, field.onChange);
                  }}
                />
              </ImagePickerContainer>
            )}
          </Dropzone>
        )}
        // onFocus={() => {
        //   imagePickerRef.current.focus();
        // }}
        name={`${name}` as const}
        control={control}
      />
    </ContainerInputPadrao>
  );
};

export default memo(ImagePickerQuadradoOuRetangular);
