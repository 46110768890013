import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';

import { VariacaoLinha } from 'components/Modal/ModalListarSaldoVariacoes';

import { TodosLocais, TodosTamanhos } from '../types';

type DadosPdvTabPanelLojaAtualProps = {
  variacoesEstoqueAgrupado: VariacaoLinha[];
  naoExisteVariacao: boolean;
  tamanhosAgrupados: string[];
  naoExisteTamanho: boolean;
};

export const DadosPdvTabPanelLojaAtual = ({
  variacoesEstoqueAgrupado,
  naoExisteVariacao,
  tamanhosAgrupados,
  naoExisteTamanho,
}: DadosPdvTabPanelLojaAtualProps) => {
  return (
    <Box>
      {variacoesEstoqueAgrupado.length > 0 ? (
        <Table mt="-10px">
          <Thead>
            <Tr>
              {naoExisteTamanho ? (
                <>
                  {naoExisteVariacao ? (
                    <Th fontSize="16px" color="aquamarine.600">
                      <Flex w="40px" justifyContent="flex-end">
                        Total
                      </Flex>
                    </Th>
                  ) : (
                    <>
                      <Th />
                      <Th
                        fontSize="16px"
                        alignItems="left"
                        color="aquamarine.600"
                      >
                        Total
                      </Th>
                    </>
                  )}
                </>
              ) : (
                <>
                  {naoExisteVariacao ? <></> : <Th />}
                  {tamanhosAgrupados.map((tamanho) => (
                    <Th
                      isNumeric
                      fontSize="16px"
                      color="primary.500"
                      key={tamanho}
                    >
                      {tamanho}
                    </Th>
                  ))}
                  <Th isNumeric fontSize="16px" color="aquamarine.600">
                    Total
                  </Th>
                </>
              )}
            </Tr>
          </Thead>
          <Tbody>
            {variacoesEstoqueAgrupado.map(({ cor, tamanhos, valorTamanho }) => {
              const totalValores = tamanhos.reduce(
                (acc, curr) => acc + (curr.quantidade || 0),
                0
              );

              const agruparTodosTamanhos = tamanhos.reduce((acc, curr) => {
                const itemAlreadyAddedIndex = acc.findIndex(
                  (item) => item.tamanho === curr.tamanho
                );

                if (itemAlreadyAddedIndex > -1) {
                  acc.splice(itemAlreadyAddedIndex, 1, {
                    ...acc[itemAlreadyAddedIndex],
                    locaisEstoque: [
                      ...acc[itemAlreadyAddedIndex].locaisEstoque,
                      {
                        quantidade: curr.quantidade,
                        localEstoque: curr.localEstoque,
                      },
                    ],
                  });
                } else {
                  acc.push({
                    tamanho: curr.tamanho,
                    locaisEstoque: [
                      {
                        quantidade: curr.quantidade,
                        localEstoque: curr.localEstoque,
                      },
                    ],
                  });
                }

                return acc;
              }, [] as TodosTamanhos[]);

              const agruparLocaisEstoque = tamanhos.reduce((acc, curr) => {
                const itemAlreadyAddedIndex = acc.findIndex(
                  (item) => item.localEstoque === curr.localEstoque
                );

                if (itemAlreadyAddedIndex > -1) {
                  acc.splice(itemAlreadyAddedIndex, 1, {
                    ...acc[itemAlreadyAddedIndex],
                    localEstoque: curr.localEstoque,
                    localQuantidade:
                      acc[itemAlreadyAddedIndex].localQuantidade +
                      curr.quantidade,
                  });
                } else {
                  acc.push({
                    localQuantidade: curr.quantidade,
                    localEstoque: curr.localEstoque,
                  });
                }

                return acc;
              }, [] as TodosLocais[]);

              return (
                <Tr key={`${cor}`}>
                  {cor ? (
                    <Td whiteSpace="nowrap" color="gray.700" fontSize="16px">
                      {cor || ''}
                    </Td>
                  ) : (
                    <></>
                  )}

                  {tamanhosAgrupados.map((tamanhoAgrupado) => {
                    const tamanho = agruparTodosTamanhos.find(
                      (val) => val.tamanho === tamanhoAgrupado
                    );

                    const quantidade = tamanho
                      ? tamanho.locaisEstoque.reduce(
                          (acc, curr) => acc + curr.quantidade,
                          0
                        )
                      : 0;

                    return (
                      <>
                        {valorTamanho ? (
                          <Tooltip
                            w="170px"
                            isDisabled={quantidade === 0}
                            bg="gray.900"
                            color="white"
                            hasArrow
                            placement="left"
                            label={
                              <Box padding="5px">
                                <Text>
                                  {tamanho?.locaisEstoque.map(
                                    (valorLocalEstoque) => (
                                      <Flex>
                                        <Text>
                                          {valorLocalEstoque.quantidade}
                                          {valorLocalEstoque.quantidade < 0
                                            ? ''
                                            : ' -'}
                                        </Text>
                                        <Text ml="5px">
                                          {valorLocalEstoque.localEstoque}
                                        </Text>
                                      </Flex>
                                    )
                                  )}
                                </Text>
                              </Box>
                            }
                          >
                            <Td isNumeric fontSize="16px">
                              {quantidade}
                            </Td>
                          </Tooltip>
                        ) : (
                          <></>
                        )}
                      </>
                    );
                  })}

                  <Tooltip
                    w="170px"
                    bg="gray.900"
                    color="white"
                    isDisabled={totalValores === 0}
                    hasArrow
                    placement="left"
                    label={
                      <Box padding="5px">
                        {agruparLocaisEstoque.map((valorLocalEstoque) => (
                          <Flex>
                            <Text>
                              {valorLocalEstoque.localQuantidade}{' '}
                              {valorLocalEstoque.localQuantidade < 0
                                ? ''
                                : ' -'}
                            </Text>
                            <Text ml="5px">
                              {valorLocalEstoque.localEstoque}
                            </Text>
                          </Flex>
                        ))}
                      </Box>
                    }
                  >
                    {naoExisteTamanho ? (
                      <Td
                        fontSize="16px"
                        fontWeight="bold"
                        color="aquamarine.600"
                      >
                        <Flex w="40px" justifyContent="right">
                          {totalValores}
                        </Flex>
                      </Td>
                    ) : (
                      <Td
                        isNumeric
                        fontSize="16px"
                        fontWeight="bold"
                        color="aquamarine.600"
                      >
                        {totalValores}
                      </Td>
                    )}
                  </Tooltip>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      ) : (
        <Box>Não existem registros para serem mostrados</Box>
      )}
    </Box>
  );
};
