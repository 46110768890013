import {
  ModalProps,
  ModalContent,
  ModalBody,
  useDisclosure,
  Text,
  Flex,
  ModalFooter,
  useMediaQuery,
} from '@chakra-ui/react';
import { useEffect, useState, useCallback } from 'react';
import { create, InstanceProps } from 'react-modal-promise';

import ModelosFiscaisEnum from 'constants/enum/fiscal/modelosFiscais';
import obterConfigEmitirNFe from 'helpers/api/Loja/obterConfigEmitirNFe';

import { ConfirmaAvisoIcon, NFeMenuIcon, NFCeMenuIcon } from 'icons';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';

import { OpcaoModal } from './OpcaoModal';

type PromiseModal = {
  finalizar: boolean;
  emitirNFe: boolean;
  emitirNFCe: boolean;
};

type ModalSucessoEmissaoProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<PromiseModal> & {
    possuiNFCe?: boolean;
    possuiNFe?: boolean;
    emissaoAtual: number;
  };

export const ModalSucessoEmissao = create<ModalSucessoEmissaoProps>(
  ({ onResolve, onReject, possuiNFCe, possuiNFe, emissaoAtual, ...rest }) => {
    const [podeEmitirNFe, setPodeEmitirNFe] = useState(false);

    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    const getPermissaoEmitirNFe = useCallback(async () => {
      const data = await obterConfigEmitirNFe();
      setPodeEmitirNFe(!!data);
    }, []);

    const naoPossuiNfeAndPodeEmitir = !possuiNFe && podeEmitirNFe;

    useEffect(() => {
      getPermissaoEmitirNFe();
    }, [getPermissaoEmitirNFe]);

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? '2xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <ModalContent
          marginTop={{ base: 0, sm: '3.75rem' }}
          h="400px"
          maxW="600px"
        >
          <ModalBody padding="0">
            <Flex flexDirection="column" align="stretch" h="100%">
              <Flex
                h="150px"
                bg="secondary.400"
                w="full"
                borderTopRadius="md"
                alignItems="center"
                justifyContent="flex-start"
              >
                <Text fontSize="20px" paddingLeft="24px" fontWeight="bold">
                  Emissão de{' '}
                  {emissaoAtual === ModelosFiscaisEnum.NFe ? 'NFe' : 'NFCe'}{' '}
                  realizada com sucesso!
                </Text>
              </Flex>

              <Flex
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
                height="250px"
              >
                <ModalFooter gap="12px">
                  <Flex gap={3}>
                    {!possuiNFCe && emissaoAtual !== ModelosFiscaisEnum.NFCe && (
                      <OpcaoModal
                        title="Emitir NFCe"
                        icon={NFCeMenuIcon}
                        onClick={() => {
                          onResolve({
                            finalizar: false,
                            emitirNFCe: true,
                            emitirNFe: false,
                          });
                        }}
                      />
                    )}
                    {naoPossuiNfeAndPodeEmitir &&
                      emissaoAtual !== ModelosFiscaisEnum.NFe && (
                        <OpcaoModal
                          title="Emitir NFe"
                          icon={NFeMenuIcon}
                          onClick={() => {
                            onResolve({
                              finalizar: false,
                              emitirNFCe: false,
                              emitirNFe: true,
                            });
                          }}
                        />
                      )}
                    <OpcaoModal
                      title="Finalizar"
                      icon={ConfirmaAvisoIcon}
                      onClick={() => {
                        onResolve({
                          finalizar: true,
                          emitirNFCe: false,
                          emitirNFe: false,
                        });
                      }}
                    />
                  </Flex>
                </ModalFooter>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
