import React, { useCallback, useRef, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import {
  Box,
  GridItem,
  Icon,
  Td,
  Tr,
  Text,
  Flex,
  Divider,
  HStack,
  useDisclosure,
  Tooltip,
  useMediaQuery,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import ConstanteFuncionalidades from 'constants/permissoes';
import api, { ResponseApi } from 'services/api';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { shallowEqual } from 'helpers/validation/shallowEqual';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import StatusOperacaoEnum from 'constants/enum/statusOperacao';
import { DecimalMask } from 'helpers/format/fieldsMasks';
import { useFileUpload } from 'helpers/layout/useFileUpload';
import { getFileBase64 } from 'helpers/data/getFileBase64';
import auth from 'modules/auth';
import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';
import { ImprimirPDF } from 'helpers/impressoes/imprimirPDF';

import {
  LancadoEstoqueIcon,
  NaoLancadoEstoqueIcon,
  LancadoFinanceiroIcon,
  NaoLancadoFinanceiroIcon,
  SalvarInserirNovoIcon,
} from 'icons';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import InputDateRange from 'components/PDV/InputDateRange';
import { ButtonFuncionalidade } from 'components/update/Button/ButtonFuncionalidade';
import { TextValor } from 'components/PDV/Text/TextValor';
import {
  PagedTable,
  PagedTableForwardRefData,
} from 'components/update/Table/PagedTable';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import { PaginationData } from 'components/update/Pagination';
import { BuscaAvancadaButton } from 'components/update/BuscaAvancadaButton';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { ModalCompartilhar } from 'components/Modal/ModalCompartilhar';
import { Link } from 'components/update/Link';

import { ModalErro } from 'components/Modal/ModalErro';
import { ModalConfirmacao } from 'components/Modal/ModalConfirmacao';
import { ModalImpressaoEtiquetaPersonalizada } from 'components/Modal/ModalImpressaoEtiquetaPersonalizada';
import { formatDate } from 'helpers/format/formatStringDate';
import { formDefaultValues, FormData } from './validationForm';
import { ModalBuscaAvancada } from './ModalBuscaAvancada';
import { ModalVinculandoXml } from './ModalVinculandoXml';

type ListaRegistros = {
  id: string;
  operacaoId: string;
  dataHoraEntrada: string;
  numeroOperacao: number;
  nomeFornecedor: string;
  fantasiaFornecedor: string;
  statusOperacao: number;
  numeroNFe: string;
  valorTotal: number;
  identificacaoTipoOperacao: number;
  finalizada: boolean;
};

type EntradaMercadoriaPaginacaoRetorno = {
  quantidadeOperacoes: number;
  valorTotalOperacoes: number;
  registros: ListaRegistros[];
};

interface ClienteProps {
  label: string;
  value: string;
  cliente: {
    id: string;
    descricao: string;
    descricaoEnderecoEntrega: string;
    descricaoEnderecoCobranca: string;
    estadoId: number;
    indicadorIe: number;
    regraLimiteCredito: number;
  };
}

interface BasesLegaisProps {
  id: string;
  descricao: string;
  ativo: boolean;
}

export interface InformacoesOperacaoProps {
  lojaId: string;
  operacaoId: string;
  clienteId: string;
  cliente: ClienteProps;
  valorProdutos: number;
  valorTotal: number;
  naturezaOperacao: string;
  basesLegais: BasesLegaisProps[];
}

const EntradaMercadoriaListar = () => {
  const history = useHistory();

  const permissaoEntradaMercadoriaAlterar = auth.possuiPermissao(
    ConstanteFuncionalidades.ENTRADA_MERCADORIA_ALTERAR
  );
  const permissaoEntradaMercadoriaVisualizar = auth.possuiPermissao(
    ConstanteFuncionalidades.ENTRADA_MERCADORIA_VISUALIZAR
  );

  const formMethods = useForm<FormData>({
    defaultValues: formDefaultValues,
  });

  const fornecedorIdWatch = formMethods.watch('fornecedorId.value');

  const {
    isOpen: isModalVinculandoXmlOpen,
    onClose: onModalVinculandoXmlClose,
    onOpen: onModalVinculandoXmlOpen,
  } = useDisclosure();

  const [currentFilters, setCurrentFilters] = useState<FormData>(
    formDefaultValues
  );

  const [hasFilters, setHasFilters] = useState(() => {
    const isEqual = shallowEqual(currentFilters, formDefaultValues);

    return !isEqual;
  });

  const [isLoading, setIsLoading] = useState(false);
  const [columnsData, setColumnsData] = useState<ListaRegistros[]>([]);
  const [itemsTotalCount, setItemsTotalCount] = useState(0);
  const [valorTotalOperacoes, setValorTotalOperacoes] = useState(0);

  const pagedTableRef = useRef<PagedTableForwardRefData>(null);
  const [isSmallerThan824] = useMediaQuery('(max-width: 824px)');

  async function vincularXML({ id }: any, file?: File) {
    if (file && file.type === 'text/xml') {
      const xmlBase64 = await getFileBase64(file);

      if (xmlBase64) {
        onModalVinculandoXmlOpen();

        const decodedXML = atob(xmlBase64);

        const response = await api.put<void, ResponseApi>(
          ConstanteEnderecoWebservice.ENTRADA_MERCADORIA_CANCELAR_VINCULAR_XML,
          {
            entradaMercadoriaId: id,
            xml: decodedXML,
          }
        );

        if (response) {
          if (response.avisos) {
            response.avisos.forEach((aviso) => toast.warning(aviso));
          }

          if (response.sucesso) {
            pagedTableRef.current?.reload();
          }
        }

        onModalVinculandoXmlClose();
      }
    }
  }

  const { handleSelectFile } = useFileUpload({
    onSelectFile: vincularXML,
    accept: 'text/xml',
  });

  function filtersSubmit(data: FormData) {
    const filtersIsDirty = !shallowEqual(data, currentFilters);

    if (filtersIsDirty) {
      formMethods.reset(data);

      setCurrentFilters(data);

      setHasFilters(true);
    }
  }

  const handleSubmit = formMethods.handleSubmit((data) => {
    filtersSubmit(data);
  });

  async function handlePushModalBuscaAvancada() {
    const data = await ModalBuscaAvancada({
      currentFilters,
    });

    filtersSubmit(data);
  }

  const loadColumnsData = useCallback(
    async (paginationData: PaginationData) => {
      setIsLoading(true);

      const {
        dataEmissaoInicio,
        dataEmissaoFim,
        dataHoraNFe,
        finalidade,
        identificador,
        situacao,
        dataEntradaFim,
        dataEntradaInicio,
        fornecedorId,
        ...restOfFilters
      } = currentFilters;

      const params = {
        fornecedorId: fornecedorIdWatch || '',
        dataEmissaoInicio: dataEmissaoInicio
          ? new Date(dataEmissaoInicio).toISOString()
          : '',
        dataEmissaoFim: dataEmissaoFim
          ? new Date(dataEmissaoFim).toISOString()
          : '',
        ...(dataHoraNFe
          ? { dataHoraNFe: new Date(dataHoraNFe).toISOString() }
          : {}),
        dataEntradaInicio: dataEntradaInicio
          ? dataEntradaInicio.toISOString()
          : '',
        dataEntradaFim: dataEntradaFim ? dataEntradaFim.toISOString() : '',
        ...(identificador ? { identificador } : {}),
        ...(finalidade ? { finalidade } : {}),
        ...(situacao ? { situacao } : {}),
        ...restOfFilters,
      };

      const response = await api.get<
        void,
        ResponseApi<EntradaMercadoriaPaginacaoRetorno>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.ENTRADA_MERCADORIA_LISTAR_PAGINADO,
          paginationData,
          params
        )
      );

      if (response) {
        if (response.sucesso) {
          setColumnsData(response.dados.registros || []);

          setItemsTotalCount(response.dados.quantidadeOperacoes || 0);
          setValorTotalOperacoes(response.dados.valorTotalOperacoes || 0);
        }
      }

      setIsLoading(false);
    },
    [currentFilters, fornecedorIdWatch]
  );

  function handlePushAlterar(entradaMercadoriaId: string) {
    history.push(
      SubstituirParametroRota(
        ConstanteRotas.ENTRADA_MERCADORIA_ENTRADA_MANUAL,
        'id?',
        entradaMercadoriaId
      )
    );
  }

  async function handleDuplicarEntrada(entradaMercadoriaId: string) {
    const response = await api.post<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.ENTRADA_MERCADORIA_DUPLICAR,
      {
        id: entradaMercadoriaId,
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        handlePushAlterar(response.dados);
      }
    }
  }

  function handlePushNovaEntrada() {
    history.push(
      SubstituirParametroRota(
        ConstanteRotas.ENTRADA_MERCADORIA_ENTRADA_MANUAL,
        'id',
        ''
      )
    );
  }

  const handleImprimirRelatorio = useCallback(
    async (id: string, usarNovoLayout: boolean) => {
      const response = await api.get<void, ResponseApi<string>>(
        ConstanteEnderecoWebservice.ENTRADA_MERCADORIA_IMPRESSAO,
        {
          params: {
            id,
            usarNovoLayout,
          },
        }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }
        if (response.sucesso) {
          ImprimirPDF(response.dados, 'relatorioEntradaMercadoria');
        }
      }
    },
    []
  );

  async function handleCancelarEntradaMercadoria(entradaMercadoriaId: string) {
    await ModalConfirmacaoExcluir({
      title: 'Deseja cancelar a entrada?',
      text:
        'Se continuar cancelará toda a entrada, e esta ação não poderá ser desfeita. Deseja continuar?',
      confirmButtonText: 'Sim, continuar!',
      callback: async (ok: boolean) => {
        if (ok) {
          const response = await api.post<void, ResponseApi<void>>(
            ConstanteEnderecoWebservice.ENTRADA_MERCADORIA_CANCELAR,
            {
              id: entradaMercadoriaId,
            }
          );

          if (response) {
            if (response.avisos) {
              response.avisos.forEach((aviso) => toast.warning(aviso));
            }

            if (response.sucesso) {
              pagedTableRef.current?.reload();
            }
          }
        }
      },
    });
  }

  async function handleEstornarEstoqueEntradaMercadoria(
    entradaMercadoriaId: string
  ) {
    ModalConfirmacao({
      cancelButtonText: 'Não, cancelar!',
      confirmButtonText: 'Sim, continuar!',
      title: 'Deseja estornar o estoque?',
      text:
        'Os itens serão removidos do seu estoque. Você tem certeza que deseja estornar? ** Obs: O calculo de custo médio de cada produto já foi atualizado e não voltará ao valor anterior.',
      callback: async (ok) => {
        if (ok) {
          const response = await api.post<void, ResponseApi<boolean>>(
            ConstanteEnderecoWebservice.ENTRADA_MERCADORIA_ESTORNAR_ESTOQUE,
            {
              id: entradaMercadoriaId,
            }
          );

          if (response) {
            if (response.avisos) {
              response.avisos.forEach((aviso) => toast.warning(aviso));
            } else if (!response.dados) {
              ModalErro({
                textoTitulo: 'Saldo de estoque negativo',
                textoMensagem:
                  'Não é possível estornar este lançamento de estoque pois o saldo de algum dos itens da lista de produtos ficará negativo.',
              });
            } else if (response.sucesso) {
              pagedTableRef.current?.reload();
            }
          }
        }
      },
    });
  }

  function handleLancarEstoque(entradaMercadoriaId: string) {
    history.push(
      SubstituirParametroRota(
        ConstanteRotas.ENTRADA_MERCADORIA_LANCAR_ESTOQUE,
        'id',
        entradaMercadoriaId
      )
    );
  }

  async function handleEstornarFinanceiroEntradaMercadoria(
    entradaMercadoriaId: string
  ) {
    ModalConfirmacao({
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim, estornar',
      title: 'Estornar financeiro',
      text:
        'A forma de pagamento será removida da sua movimentação de contas a pagar. Você tem certeza que deseja estornar?',
      callback: async (ok) => {
        if (ok) {
          const response = await api.put<void, ResponseApi<boolean>>(
            ConstanteEnderecoWebservice.ENTRADA_MERCADORIA_ESTORNAR_FINANCEIRO,
            {},
            { params: { id: entradaMercadoriaId } }
          );

          if (response) {
            if (response.avisos) {
              response.avisos.forEach((aviso) => toast.warning(aviso));
            }

            if (response.dados) {
              ModalErro({
                textoTitulo: 'O pagamento já foi efetuado',
                textoMensagem:
                  'Não é possível estornar este lançamento financeiro pois uma parte do pagamento já foi efetuada. Para conseguir estornar é preciso primeiramente cancelar o pagamento diretamente na tela de contas a pagar.',
                textoButton: 'Fechar',
              });
            } else if (response.sucesso) {
              pagedTableRef.current?.reload();
            }
          }
        }
      },
    });
  }

  function handleLancarFinanceiro(entradaMercadoriaId: string) {
    ModalConfirmacao({
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim, lançar',
      title: 'Lançar forma de pagamento',
      text:
        'As parcelas serão lançadas na movimentação de contas a pagar de acordo com valores e datas informadas na entrada de mercadorias. Você tem certeza que deseja lançar a forma de pagamento?',
      callback: async (ok) => {
        if (ok) {
          const response = await api.put<void, ResponseApi<boolean>>(
            ConstanteEnderecoWebservice.ENTRADA_MERCADORIA_LANCAR_FINANCEIRO,
            {},
            { params: { id: entradaMercadoriaId } }
          );

          if (response) {
            if (response.avisos) {
              response.avisos.forEach((aviso) => toast.warning(aviso));
            }

            if (response.sucesso) {
              pagedTableRef.current?.reload();
            }
          }
        }
      },
    });
  }

  function handlePushIniciarImportacao() {
    history.push(ConstanteRotas.ENTRADA_MERCADORIA_ENTRADA_IMPORTACAO_INICIAR);
  }

  function obterLinkVisualizar(id: string) {
    let href = ConstanteRotas.ENTRADA_MERCADORIA;

    if (permissaoEntradaMercadoriaAlterar.permitido) {
      href = SubstituirParametroRota(
        ConstanteRotas.ENTRADA_MERCADORIA_ENTRADA_MANUAL,
        'id?',
        id
      );
    } else if (permissaoEntradaMercadoriaVisualizar.permitido) {
      href = SubstituirParametroRota(
        ConstanteRotas.ENTRADA_MERCADORIA_ENTRADA_MANUAL_VISUALIZAR,
        'id',
        id
      );
    }

    return href;
  }

  function handleVisualizarOperacao(id: string) {
    const href = SubstituirParametroRota(
      ConstanteRotas.ENTRADA_MERCADORIA_ENTRADA_MANUAL_VISUALIZAR,
      'id',
      id
    );
    return href;
  }

  const handleImprimirImpressao = useCallback(
    (id: string) => {
      ModalCompartilhar({
        items: [
          {
            titulo: 'Imprimir relatório detalhado',
            onClickImpressao: (onClose) => {
              handleImprimirRelatorio(id, false);
              onClose();
            },
          },
          {
            titulo: 'Imprimir relatório com grade',
            onClickImpressao: (onClose) => {
              handleImprimirRelatorio(id, true);
              onClose();
            },
          },
        ],
      });
    },
    [handleImprimirRelatorio]
  );

  return (
    <SimpleGridForm
      gridGap={['12px', '12px', '38px']}
      gridColumnGap={['12px', '6px', '6px', '12px']}
    >
      <FormProvider {...formMethods}>
        <GridItem colSpan={[12, 12, 4, 4, 4, 4]}>
          <InputDateRange
            borderColor="gray.200"
            borderRadius="md"
            name="data"
            size="sm"
            isClearable
            placeholder="Selecione um período"
            startDateName="dataEntradaInicio"
            endDateName="dataEntradaFim"
            maxDate={new Date()}
            onConfirm={() => handleSubmit()}
          />
        </GridItem>
        <GridItem colSpan={[12, 8, 5, 5, 5, 4]}>
          <Flex
            flexDir={['column', 'row']}
            justifyContent={['center', 'start']}
            gap="8px"
            sx={{ '> div': { flex: 1 } }}
          >
            <BuscaAvancadaButton
              borderRadius="full"
              colorScheme={hasFilters ? 'blue' : 'gray'}
              variant={hasFilters ? 'solid' : 'outlineDefault'}
              bg="transparent"
              height="32px"
              borderColor={hasFilters ? 'blue.500' : 'gray.200'}
              setIsModalBuscaAvancadaOpen={handlePushModalBuscaAvancada}
              hasFilters={hasFilters}
              padding="10px 24px"
              justifyContent="center"
              width={['full', '50%', '155px', '188px']}
            />

            <ButtonFuncionalidade
              funcionalidade={
                ConstanteFuncionalidades.ENTRADA_MERCADORIA_CADASTRAR
              }
              borderRadius="full"
              colorScheme="gray"
              variant="outlineDefault"
              px="12px"
              bg="transparent"
              borderColor="gray.200"
              borderWidth="1px"
              borderStyle="solid"
              width={['full', 'full', '146px', '188px']}
              height="32px"
              type="button"
              iconSide="left"
              color="gray.700"
              leftIcon={
                <Icon as={SalvarInserirNovoIcon} fontSize="lg" boxSize="16px" />
              }
              onClick={handlePushIniciarImportacao}
            >
              Importar NFe
            </ButtonFuncionalidade>
          </Flex>
        </GridItem>
        <GridItem colSpan={[12, 4, 3, 3, 3, 4]} textAlign="right">
          <ButtonFuncionalidade
            colorScheme="secondary"
            funcionalidade={
              ConstanteFuncionalidades.ENTRADA_MERCADORIA_CADASTRAR
            }
            leftIcon={<Icon as={SalvarInserirNovoIcon} fontSize="lg" />}
            isDisabled={isLoading}
            onClick={handlePushNovaEntrada}
            borderRadius="full"
            id="novaEntrada"
            name="novaEntrada"
            iconSide="left"
            minW="145px"
            height="32px"
            width={['full', 'full', '160px']}
          >
            Nova entrada
          </ButtonFuncionalidade>
        </GridItem>
      </FormProvider>
      <GridItem colSpan={12}>
        <Flex
          h="auto"
          bg="gray.100"
          flexDirection={isSmallerThan824 ? 'column' : 'row'}
          borderTopRadius="md"
          alignItems="center"
          borderBottomRadius="md"
          mb="8"
          p="4"
          px={{ base: '10', md: '0' }}
        >
          <Flex
            w="2xs"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            mb={{ base: '2', md: '0' }}
          >
            <Text fontSize="xs">Total de operações</Text>
            <Text fontSize="lg" color="primary.50" fontWeight="semibold">
              {itemsTotalCount}
            </Text>
          </Flex>

          <Divider
            h={isSmallerThan824 ? '' : '12'}
            orientation={isSmallerThan824 ? 'horizontal' : 'vertical'}
          />

          <Flex
            w="2xs"
            h="45px"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            mt={{ base: '2', md: '0' }}
          >
            <Text fontSize="xs" mb="4px">
              Valor em operações
            </Text>
            <TextValor
              flex="1"
              valor={valorTotalOperacoes}
              casasDecimais={2}
              symbolFontSize="2xs"
              fontWeight="semibold"
              fontSize="lg"
              color="teal.500"
            />
          </Flex>
        </Flex>
        <Box borderRadius="md" boxShadow="md" position="relative">
          <PagedTable
            ref={pagedTableRef}
            boxShadow="none"
            borderTopRadius="none"
            sx={{
              '& tr > th:first-child': {
                borderTopLeftRadius: 'md',
              },
              '& tr > th:last-child': {
                borderTopRightRadius: 'md',
              },
            }}
            position="initial"
            isLoading={isLoading}
            loadColumnsData={loadColumnsData}
            itemsTotalCount={itemsTotalCount}
            defaultKeyOrdered="dataHoraEntrada"
            defaultOrderDirection="desc"
            tableHeaders={[
              {
                key: 'dataHoraEntrada',
                content: 'Data de entrada ',
                w: '200px',
              },
              {
                key: 'identificador',
                content: 'Identificador',
                w: '200px',
              },
              { key: 'fornecedor', content: 'Fornecedor', isOrderable: false },
              {
                key: 'situacao',
                content: 'Situação',
                isOrderable: false,
                w: '150px',
              },
              {
                key: 'valorTotal',
                content: 'Valor total',
                isOrderable: false,
                isNumeric: true,
                w: '1px',
              },
              {
                key: 'acoes',
                content: 'Ações',
                isOrderable: false,
                w: '200px',
                textAlign: 'right',
              },
            ]}
            renderTableRows={
              columnsData &&
              columnsData.map((columnData) => {
                const dataHoraEntrada = formatDate(columnData.dataHoraEntrada);

                const entradaCancelada =
                  columnData.statusOperacao === StatusOperacaoEnum.CANCELADA;

                const foiLancadoEstoque =
                  columnData.identificacaoTipoOperacao ===
                    IdentificacaoTipoOperacaoEnum.ENTRADA_MERCADORIA ||
                  columnData.identificacaoTipoOperacao ===
                    IdentificacaoTipoOperacaoEnum.COMPRA_MERCADORIA;

                const foiLancadoFinanceiro =
                  columnData.identificacaoTipoOperacao ===
                    IdentificacaoTipoOperacaoEnum.PAGAMENTO_COMPRA ||
                  columnData.identificacaoTipoOperacao ===
                    IdentificacaoTipoOperacaoEnum.COMPRA_MERCADORIA;

                const foiFinalizadaOperacao = columnData.finalizada;

                return (
                  <Tr
                    key={columnData.id}
                    color={entradaCancelada ? 'red.500' : undefined}
                  >
                    <Td>{dataHoraEntrada}</Td>
                    <Td>
                      <Link
                        id="link-visualizar"
                        href={obterLinkVisualizar(columnData.id)}
                      >
                        {String(columnData.numeroOperacao).padStart(10, '0')}
                      </Link>
                    </Td>
                    <Td lineHeight="none">
                      <Flex
                        h="1px"
                        flexDir="column"
                        justifyContent="center"
                        overflow="visible"
                      >
                        <Text>{columnData.nomeFornecedor}</Text>
                        {columnData.fantasiaFornecedor && (
                          <Text fontSize="2xs" mt="1">
                            {columnData.fantasiaFornecedor}
                          </Text>
                        )}
                      </Flex>
                    </Td>
                    <Td lineHeight="none">
                      <Flex
                        h="1px"
                        flexDir="column"
                        justifyContent="center"
                        overflow="visible"
                      >
                        {columnData.numeroNFe && (
                          <Text>{`NFe | ${columnData.numeroNFe}`}</Text>
                        )}
                        {entradaCancelada && (
                          <Text
                            fontSize={columnData.numeroNFe ? '2xs' : undefined}
                            mt="1"
                          >
                            Entrada cancelada
                          </Text>
                        )}
                        {!columnData.numeroNFe && !entradaCancelada && (
                          <Text>---</Text>
                        )}
                      </Flex>
                    </Td>
                    <Td isNumeric>
                      {DecimalMask(columnData.valorTotal, 2, 2)}
                    </Td>
                    <Td>
                      <HStack
                        justifyContent="flex-end"
                        spacing={{ base: '2', md: '3' }}
                      >
                        <Tooltip
                          shouldWrapChildren
                          hasArrow
                          label={
                            foiLancadoFinanceiro
                              ? 'Entrada lançada no financeiro'
                              : 'Não lançado no financeiro'
                          }
                          placement="right"
                          bg={
                            foiLancadoFinanceiro ? 'aquamarine.600' : 'gray.100'
                          }
                          color={foiLancadoFinanceiro ? 'white' : 'black'}
                        >
                          <Icon
                            color={
                              foiLancadoFinanceiro
                                ? 'aquamarine.600'
                                : 'gray.200'
                            }
                            as={
                              foiLancadoFinanceiro
                                ? LancadoFinanceiroIcon
                                : NaoLancadoFinanceiroIcon
                            }
                            fontSize="xl"
                          />
                        </Tooltip>
                        <Tooltip
                          shouldWrapChildren
                          hasArrow
                          label={
                            foiLancadoEstoque
                              ? 'Itens lançados no estoque'
                              : 'Não lançado no estoque'
                          }
                          placement="right"
                          bg={foiLancadoEstoque ? 'primary.50' : 'gray.100'}
                          color={foiLancadoEstoque ? 'white' : 'black'}
                        >
                          <Icon
                            color={
                              foiLancadoEstoque ? 'primary.50' : 'gray.200'
                            }
                            as={
                              foiLancadoEstoque
                                ? LancadoEstoqueIcon
                                : NaoLancadoEstoqueIcon
                            }
                            fontSize="xl"
                          />
                        </Tooltip>
                        <ActionsMenu
                          menuZIndex="modal"
                          items={[
                            ...(foiLancadoEstoque
                              ? [
                                  {
                                    icon: (
                                      <Icon
                                        as={NaoLancadoEstoqueIcon}
                                        fontSize="lg"
                                      />
                                    ),
                                    content: 'Estornar estoque',
                                    onClick: () => {
                                      handleEstornarEstoqueEntradaMercadoria(
                                        columnData.id
                                      );
                                    },
                                    funcionalidade:
                                      ConstanteFuncionalidades.ENTRADA_MERCADORIA_ESTORNAR_ESTOQUE,
                                  },
                                ]
                              : entradaCancelada
                              ? []
                              : [
                                  {
                                    icon: foiFinalizadaOperacao ? (
                                      <Icon
                                        as={LancadoEstoqueIcon}
                                        fontSize="lg"
                                      />
                                    ) : (
                                      <Tooltip
                                        hasArrow
                                        placement="left"
                                        shouldWrapChildren
                                        label="A entrada precisa estar finalizada para lançar o estoque"
                                      >
                                        <Icon
                                          as={LancadoEstoqueIcon}
                                          fontSize="lg"
                                          color="gray.300"
                                        />
                                      </Tooltip>
                                    ),
                                    _hover: {
                                      bg: 'none',
                                    },
                                    cursor: foiFinalizadaOperacao
                                      ? 'pointer'
                                      : 'not-allowed',
                                    onClick: () => {
                                      if (foiFinalizadaOperacao) {
                                        handleLancarEstoque(columnData.id);
                                      }
                                    },
                                    closeOnSelect: false,
                                    content: 'Lançar estoque',
                                    color: foiFinalizadaOperacao
                                      ? 'primary.50'
                                      : 'gray.300',
                                    funcionalidade:
                                      ConstanteFuncionalidades.ENTRADA_MERCADORIA_LANCAR_ESTOQUE,
                                  },
                                ]),
                            ...(foiLancadoFinanceiro
                              ? [
                                  {
                                    icon: (
                                      <Icon
                                        as={NaoLancadoFinanceiroIcon}
                                        fontSize="lg"
                                      />
                                    ),
                                    content: 'Estornar financeiro',
                                    onClick: () => {
                                      handleEstornarFinanceiroEntradaMercadoria(
                                        columnData.id
                                      );
                                    },
                                    funcionalidade:
                                      ConstanteFuncionalidades.ENTRADA_MERCADORIA_ESTORNAR_FINANCEIRO,
                                  },
                                ]
                              : entradaCancelada
                              ? []
                              : [
                                  {
                                    icon: foiFinalizadaOperacao ? (
                                      <Icon
                                        as={LancadoFinanceiroIcon}
                                        fontSize="lg"
                                      />
                                    ) : (
                                      <Tooltip
                                        hasArrow
                                        placement="left"
                                        shouldWrapChildren
                                        label="A entrada precisa estar finalizada para lançar o financeiro"
                                      >
                                        <Icon
                                          as={LancadoFinanceiroIcon}
                                          fontSize="lg"
                                          color="gray.300"
                                        />
                                      </Tooltip>
                                    ),
                                    _hover: {
                                      bg: 'none',
                                    },
                                    isFocusable: false,
                                    content: 'Lançar financeiro',
                                    cursor: foiFinalizadaOperacao
                                      ? 'pointer'
                                      : 'not-allowed',
                                    closeOnSelect: false,
                                    color: foiFinalizadaOperacao
                                      ? 'aquamarine.600'
                                      : 'gray.300',
                                    onClick: () => {
                                      if (foiFinalizadaOperacao) {
                                        handleLancarFinanceiro(columnData.id);
                                      }
                                    },
                                    funcionalidade:
                                      ConstanteFuncionalidades.ENTRADA_MERCADORIA_LANCAR_FINANCEIRO,
                                  },
                                ]),
                            ...(entradaCancelada
                              ? []
                              : [
                                  {
                                    content: <Divider />,
                                    isDisabled: true,
                                  },
                                ]),
                            ...(entradaCancelada
                              ? []
                              : [
                                  {
                                    content: foiLancadoFinanceiro
                                      ? 'Visualizar'
                                      : 'Editar',
                                    onClick: () =>
                                      foiLancadoFinanceiro
                                        ? history.push(
                                            handleVisualizarOperacao(
                                              columnData.id
                                            )
                                          )
                                        : handlePushAlterar(columnData.id),
                                    funcionalidade:
                                      ConstanteFuncionalidades.ENTRADA_MERCADORIA_ALTERAR,
                                  },
                                ]),
                            ...(!columnData.numeroNFe
                              ? [
                                  {
                                    content: 'Vincular XML',
                                    onClick: () => {
                                      handleSelectFile({ id: columnData.id });
                                    },
                                  },
                                ]
                              : []),
                            ...(entradaCancelada
                              ? []
                              : [
                                  {
                                    content: 'Cancelar',
                                    onClick: () => {
                                      handleCancelarEntradaMercadoria(
                                        columnData.id
                                      );
                                    },
                                    funcionalidade:
                                      ConstanteFuncionalidades.ENTRADA_MERCADORIA_CANCELAR,
                                  },
                                ]),
                            {
                              content: 'Duplicar',
                              onClick: () => {
                                handleDuplicarEntrada(columnData.id);
                              },
                              funcionalidade:
                                ConstanteFuncionalidades.ENTRADA_MERCADORIA_DUPLICAR,
                            },
                            {
                              content: 'Compartilhar',
                              onClick: () => {
                                handleImprimirImpressao(columnData.id);
                              },
                            },
                            ...(entradaCancelada
                              ? []
                              : [
                                  {
                                    content: 'Imprimir etiquetas',
                                    onClick: () => {
                                      ModalImpressaoEtiquetaPersonalizada({
                                        operacaoId: columnData.operacaoId,
                                      });
                                    },
                                    isDisabled: !foiFinalizadaOperacao,
                                  },
                                ]),
                          ]}
                        />
                      </HStack>
                    </Td>
                  </Tr>
                );
              })
            }
          />
        </Box>
      </GridItem>

      <ModalVinculandoXml isOpen={isModalVinculandoXmlOpen} />
    </SimpleGridForm>
  );
};

export default EntradaMercadoriaListar;
