import React, { useEffect, useRef } from 'react';

const TAB_KEY = 'Tab';
// const TAB_KEYCODE = 9;

const FOCUSABLE_ELEMENTS_SELECTOR =
  'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

interface ManterFocoProps {
  isLocked?: boolean;
  blockTab?: boolean;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

const ManterFoco = ({
  isLocked = true,
  blockTab = false,
  style,
  children,
}: ManterFocoProps) => {
  const rootNode = useRef() as React.MutableRefObject<HTMLDivElement>;
  const focusableItems = useRef<HTMLElement[]>();

  useEffect(() => {
    const updateFocusableItems = () => {
      if (!focusableItems.current) return;
      focusableItems.current = Array?.from(
        rootNode.current?.querySelectorAll(
          FOCUSABLE_ELEMENTS_SELECTOR
        ) as NodeListOf<HTMLElement>
      ).filter((HTMLel) => HTMLel.tabIndex !== -1);
    };

    const observer = new MutationObserver(() => {
      updateFocusableItems();
    });
    updateFocusableItems();

    // Observa a renderização de novos childrens para atualizar a lista de childrens focalizáveis
    observer.observe(rootNode.current, { childList: true });
    return () => {
      observer.disconnect();
    };
  }, [rootNode]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (!focusableItems.current) return;

      const { key, shiftKey } = event;
      const {
        length,
        0: firstItem,
        [length - 1]: lastItem,
      } = focusableItems.current;

      if (isLocked && key === TAB_KEY) {
        // Se existir apenas um children focalizável, bloqueia a tabulação
        if (length === 1 || blockTab) {
          event.preventDefault();
          return;
        }

        // Se o último children estiver focado ao dar TAB, passa o foco para o primeiro children
        if (!shiftKey && document.activeElement === lastItem) {
          event.preventDefault();
          firstItem.focus();

          return;
        }

        // Se o primeiro children estiver focado ao dar SHIFT + TAB, passa o foco para o último children
        if (shiftKey && document.activeElement === firstItem) {
          event.preventDefault();
          lastItem.focus();
        }
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [isLocked, focusableItems, blockTab]);

  return (
    <div ref={rootNode} style={style}>
      {children}
    </div>
  );
};

export default ManterFoco;
