import { Box, Divider, Flex, Grid, HStack, Spacer } from '@chakra-ui/react';
import { moneyMask } from 'helpers/format/fieldsMasks';
import React from 'react';
import { ProdutoServicoInterface } from 'types/ImpressaoNFCe';

interface ImpressaoInterface {
  nfceAutorizada: boolean;
  produtos: ProdutoServicoInterface[];
  valorProdutos: string;
}

const Produtos = ({
  nfceAutorizada,
  produtos,
  valorProdutos,
}: ImpressaoInterface) => {
  return (
    <Flex>
      {nfceAutorizada ? (
        <Grid templateRows="10px 10px auto" width="100%" marginTop="10px">
          <HStack justifyContent="space-between" fontWeight="bold" width="100%">
            <Flex>COD</Flex>
            <Divider orientation="vertical" />
            <Flex>DESCR</Flex>
            <Divider orientation="vertical" />
            <Flex>QTDE</Flex>
            <Divider orientation="vertical" />
            <Flex>UN</Flex>
            <Divider orientation="vertical" />
            <Flex whiteSpace="nowrap">VL UNIT</Flex>
            <Divider orientation="vertical" />
            <Flex whiteSpace="nowrap">VL TOTAL</Flex>
          </HStack>

          <Divider margin="3px 0px" />

          {produtos.map((p) => (
            <Grid key={p.numeroItem} width="100%">
              <Flex alignItems="flex-start">
                {p.produto.codigo} {p.produto.descricao}
              </Flex>
              <Flex>
                <Box marginLeft="10px">
                  {Number(p.produto.quantidade)} {p.produto.unidade} x{' '}
                  {moneyMask(p.produto.valorUnitario, false)}
                </Box>
                <Spacer />
                <Box>{moneyMask(p.produto.valorTotalBruto, false)}</Box>
              </Flex>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid fontWeight="bold" width="100%">
          <Divider margin="3px 0px" />

          <Flex className="textCenter" fontWeight="800">
            DADOS DO CUPOM FISCAL ELETRÔNICO CANCELADO
          </Flex>

          <Flex className="textCenter" marginTop="5px">
            VALOR TOTAL {moneyMask(valorProdutos, true)}
          </Flex>

          <Divider marginTop="3px" />
        </Grid>
      )}
    </Flex>
  );
};

export default Produtos;
