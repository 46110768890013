import { Icon, Tab, Flex } from '@chakra-ui/react';
import { FiChevronRight } from 'react-icons/fi';

type HeaderTabProps = {
  title: string;
  isSelectedTab: boolean;
};

export const HeaderTab = ({ title, isSelectedTab }: HeaderTabProps) => {
  return (
    <Tab
      w="full"
      h="64px"
      borderRadius="none"
      _selected={{
        background: 'gray.100',
      }}
    >
      <Flex w="full" justifyContent="space-between" alignItems="center">
        {title}
        {isSelectedTab && <Icon as={FiChevronRight} />}
      </Flex>
    </Tab>
  );
};
