import React from 'react';
import { useMediaQuery, Stack, Text } from '@chakra-ui/react';
import format from 'date-fns/format';
import ptBR from 'date-fns/locale/pt-BR';

import { capitalize } from 'helpers/format/stringFormats';

import CaixaAberto from 'components/PDV/Layout/Footer/CaixaAberto';

const Footer: React.FC = () => {
  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

  const dataAtual = capitalize(
    format(new Date(), "cccc, dd 'de' MMMM 'de' uuuu", {
      locale: ptBR,
    }),
    2
  );

  return (
    <Stack
      direction="row"
      align="center"
      justifyContent="center"
      h="100%"
      spacing={4}
    >
      <CaixaAberto />
      {isLargerThan700 && (
        <>
          <Text fontSize="sm" color="white">
            |
          </Text>
          <Text fontSize="sm" color="white">
            {dataAtual}
          </Text>
        </>
      )}
    </Stack>
  );
};

export default Footer;
