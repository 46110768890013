import {
  VStack,
  Text,
  Flex,
  Box,
  Button,
  HStack,
  Icon,
} from '@chakra-ui/react';
import { FiChevronLeft } from 'react-icons/fi';

import { formatDate } from 'helpers/format/formatStringDate';
import { useFrenteCaixaContext } from 'store/FrenteCaixa';
import { IdentificacaoEtapasFrenteCaixa } from 'constants/enum/identificacaoEtapasFrenteCaixa';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { DataIcon } from 'components/Geral/DataCriacaoAlteracao/styles';
import { ContainerHeader } from 'pages/Integracoes/LojaAplicativos/Layout/ContainerHeader';

import { Container } from '../components/Container';
import { useDados } from './hooks';
import { usePainelFrenteCaixa } from '../hooks';

export const PainelAdministradorFrenteCaixaAtivacao = () => {
  const { handleVoltarPainelAdm } = usePainelFrenteCaixa();
  const { infoIntegracao, integracaoConfiguracoes } = useFrenteCaixaContext();
  const {
    codigoAtivacao,
    exportarDados,
    isLoading,
    urlScript,
    handleBaixarScript,
    handleCopyActivationCode,
    gerarScript,
    amountDispositivo,
    activationCodeWasCopied,
  } = useDados();

  return (
    <>
      <ContainerHeader bg="#FF005A" gap={2} color="white" alignItems="center">
        <Box cursor="pointer" mr="10px" onClick={handleVoltarPainelAdm}>
          <Icon fontSize="25px" as={FiChevronLeft} />
        </Box>
        <Text fontSize="16px" fontWeight="semibold">
          Ativação
        </Text>
      </ContainerHeader>
      <Box position="relative" p={6}>
        {isLoading && <LoadingPadrao />}
        <SimpleGridForm minW="min">
          <Container colSpan={{ base: 12, md: 6 }} title="Código de ativação">
            <Flex gap="5px">
              {(codigoAtivacao?.listCodigoAtivacao || []).map((code) => (
                <VStack
                  alignItems="center"
                  justifyContent="center"
                  bg="white"
                  fontWeight="semibold"
                  borderColor="gray.200"
                  borderWidth="1px"
                  borderStyle="solid"
                  color="gray.900"
                  fontSize={['12px', '14px', '14px']}
                  textAlign="center"
                  boxSize={{ base: '30px', sm: '40px' }}
                  borderRadius="5px"
                >
                  <Text>{code}</Text>
                </VStack>
              ))}
              <Button
                h="2.5rem"
                size="sm"
                onClick={() => handleCopyActivationCode()}
                colorScheme={activationCodeWasCopied ? 'secondary' : 'primary'}
                borderRadius="md"
                w="70px"
                autoFocus
              >
                {activationCodeWasCopied ? 'Copiado!' : 'Copiar'}
              </Button>
            </Flex>
          </Container>
          <Container colSpan={{ base: 12, md: 6 }} title="Plano contratado">
            <HStack
              alignItems="center"
              h="full"
              gap="8px"
              justifyContent="center"
              bg="gray.50"
              p="16px"
              w="full"
            >
              <Text fontSize="14px">
                Seu plano possui{' '}
                <Text as="span" fontSize="24px" color="purple.500">
                  {amountDispositivo?.total}
                </Text>{' '}
                licenças para o Frente caixa.
              </Text>
            </HStack>
          </Container>
        </SimpleGridForm>
        <Flex
          mt="50px"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          gap={2}
        >
          {integracaoConfiguracoes?.IdentificacaoEtapas ===
            IdentificacaoEtapasFrenteCaixa.GUIA_INTEGRACAO && (
            <Button
              color="black"
              variant="outlineDefault"
              colorScheme="gray"
              borderRadius="16px"
              width={{ base: 'full', md: '150px' }}
              onClick={exportarDados}
            >
              Iniciar integração
            </Button>
          )}
          {urlScript && (
            <Button
              h="full"
              minH="36px"
              width={{ base: 'full', md: 'max' }}
              variant="outlineDefault"
              color="black"
              colorScheme="gray"
              borderRadius="16px"
              whiteSpace="normal"
              onClick={handleBaixarScript}
            >
              Gerar arquivo com dados para o Frente de Caixa
            </Button>
          )}
          <Button
            width={{ base: 'full', md: '120px' }}
            color="black"
            variant="solid"
            colorScheme="secondary.500"
            borderRadius="16px"
            isDisabled={
              integracaoConfiguracoes?.IdentificacaoEtapas !==
              IdentificacaoEtapasFrenteCaixa.FINALIZADO
            }
            onClick={gerarScript}
          >
            Gerar script
          </Button>
        </Flex>
        {integracaoConfiguracoes?.DataScript &&
          !infoIntegracao?.sincronizacaoHabilitada && (
            <Text mt="20px" float="right" display="flex">
              <DataIcon />
              <Text ml="7px">
                Último arquivo de dados gerado em{' '}
                {formatDate(integracaoConfiguracoes.DataScript)}
              </Text>
            </Text>
          )}
      </Box>
    </>
  );
};
