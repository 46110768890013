import { Route } from 'react-router-dom';

import ConstanteRotas from 'constants/rotas';

import { Temas } from 'pages/Integracoes/LojaAplicativos/AutoAtendimento/PainelAdministradorAutoAtendimento/Tema';

import LayoutGuard from './LayoutGuard';

export const AutoAtendimentoRoutes = [
  <Route path={ConstanteRotas.AUTO_ATENDIMENTO_TEMA} key="auto-atendimento">
    <LayoutGuard
      key={ConstanteRotas.AUTO_ATENDIMENTO_TEMA}
      path={ConstanteRotas.AUTO_ATENDIMENTO_TEMA}
      component={Temas}
      meta={{ auth: true }}
      breadcrumb={[
        {
          title: 'Integrações',
          path: ConstanteRotas.LOJA_APLICATIVOS,
        },
        {
          title: 'Auto Atendimento',
        },
        {
          title: 'Tema',
        },
      ]}
      exact
    />
  </Route>,
];
