import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { ResponseApi } from 'services/api';
import { sistemaApiKey } from 'services/sistemaApiKey';

export const useVincularFormaRecebimento = () => {
  const [listCredenciadoraCartao, setListCredenciadoraCartao] = useState<
    { label: string; value: string }[]
  >([]);

  const getCredenciadoraCartao = useCallback(async () => {
    const response = await sistemaApiKey().get<
      void,
      ResponseApi<{ descricao: string; id: string }[]>
    >(ConstanteEnderecoWebservice.PDV_AUTONOMO_LISTAR_CREDENCIAL_CARTAO);

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        const data = response.dados.map((item) => ({
          value: item.id,
          label: item.descricao,
        }));
        setListCredenciadoraCartao(data);
      }
    }
  }, []);

  useEffect(() => {
    getCredenciadoraCartao();
  }, [getCredenciadoraCartao]);

  return {
    listCredenciadoraCartao,
  };
};
