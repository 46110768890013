import { useCallback, useEffect, useState, useImperativeHandle } from 'react';
import { Text, VStack, Box } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';

import OptionType from 'types/optionType';
import {
  getLocalEstoque as getLocalEstoqueResponse,
  cadastrarLocalEstoqueTray,
  getLocalEstoquePadrao,
  getLocalEstoqueTray,
} from 'helpers/data/getLocalEstoque';
import { useTrayEtapasContext } from 'store/Tray';
import { IdentificacaoEtapasTray } from 'constants/enum/IdentificacaoEtapasTray';
import auth from 'modules/auth';
import PlanoContratacaoEnum from 'constants/enum/planoContratacao';

import { ConfiguracoesMinhasLojasIcon } from 'icons';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import { ModalCadastrarLocalEstoque } from 'components/Modal/ModalCadastrarLocalEstoque';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { FormData, yupResolver } from './validationForm';
import { Header } from '../Layout/Header';

export const LocalEstoque = () => {
  const [localEstoqueOptions, setLocalEstoqueOptions] = useState<OptionType[]>(
    []
  );

  const {
    setActiveStep,
    setIsLoading,
    isLoading,
    ref,
  } = useTrayEtapasContext();

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
  });

  const { handleSubmit, setValue } = formMethods;

  const planoIsAtual = auth.getPlano();

  const isPlanoStartOrPro =
    planoIsAtual === PlanoContratacaoEnum.START ||
    planoIsAtual === PlanoContratacaoEnum.PRO;

  const handleAvancar = handleSubmit(async ({ localEstoque }) => {
    setIsLoading(true);

    const response = await cadastrarLocalEstoqueTray(localEstoque);

    if (response) {
      setActiveStep(IdentificacaoEtapasTray.TABELA_PRECO);
    }

    setIsLoading(false);
  });

  const handleVoltar = () => {
    setActiveStep(IdentificacaoEtapasTray.VENDEDOR);
  };

  const getLocalEstoquePadraoSistema = useCallback(
    async (options: OptionType[]) => {
      setIsLoading(true);
      const localEstoqueCadastrado = await getLocalEstoqueTray();
      let valueLocalEstoque;

      if (localEstoqueCadastrado) {
        valueLocalEstoque = localEstoqueCadastrado;
      } else {
        const localEstoqueDefault = await getLocalEstoquePadrao(options);
        valueLocalEstoque = localEstoqueDefault?.value;
      }

      if (valueLocalEstoque) {
        setValue('localEstoque', valueLocalEstoque);
      }

      setIsLoading(false);
    },
    [setIsLoading, setValue]
  );

  const getLocalEstoque = useCallback(async () => {
    setIsLoading(true);
    const newLocalEstoqueOptions = await getLocalEstoqueResponse();

    setLocalEstoqueOptions(newLocalEstoqueOptions);

    setIsLoading(false);

    return newLocalEstoqueOptions;
  }, [setIsLoading]);

  const loadLocalEstoque = useCallback(async () => {
    setIsLoading(true);
    const options = await getLocalEstoque();
    getLocalEstoquePadraoSistema(options);
    setIsLoading(false);
  }, [getLocalEstoque, setIsLoading, getLocalEstoquePadraoSistema]);

  const handleAbrirModalCriarLocalEstoque = async () => {
    const {
      sucesso: newLocalEstoqueCriado,
    } = await ModalCadastrarLocalEstoque();
    if (newLocalEstoqueCriado) {
      setIsLoading(true);
      const optionsLocalEstoque = await getLocalEstoque();
      const newLocalEstoqueSelecionado = optionsLocalEstoque.find(
        (option) => option.label === newLocalEstoqueCriado.nome
      );
      if (newLocalEstoqueSelecionado) {
        setValue('localEstoque', newLocalEstoqueSelecionado.value);
      }
      setIsLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    handleAvancar,
    handleVoltar,
  }));

  useEffect(() => {
    loadLocalEstoque();
  }, [loadLocalEstoque]);

  useEffect(() => {
    if (isPlanoStartOrPro) {
      setActiveStep(IdentificacaoEtapasTray.TABELA_PRECO);
    }
  }, [isPlanoStartOrPro, setActiveStep]);

  return (
    <FormProvider {...formMethods}>
      {isLoading && <LoadingPadrao />}

      <Header
        title="Local de estoque"
        icon={ConfiguracoesMinhasLojasIcon}
        handleVoltar={handleVoltar}
      />
      <VStack alignItems="center" w="full" gap="24px" px="20px">
        <Box color="primary.50" fontSize="14px" letterSpacing="0px" w="full">
          <Text>
            Selecione um local existente ou crie um novo local de estoque. Se
            preferir, é possível realizar o controle de estoque separando um
            local exclusivo para os produtos que serão anunciados na Tray.
          </Text>
        </Box>

        <Box w="full" h="40px" textAlign="end">
          <SelectPadrao
            placeholder="Selecione um local de estoque"
            id="localEstoque"
            name="localEstoque"
            options={localEstoqueOptions}
            colSpan={12}
          />
          <Text
            textDecoration="underline"
            color="blue.700"
            margin="8px 0 0 auto"
            w="fit-content"
            fontSize="sm"
            letterSpacing="0px"
            cursor="pointer"
            onClick={() => handleAbrirModalCriarLocalEstoque()}
          >
            Criar novo local de estoque
          </Text>
        </Box>
      </VStack>
    </FormProvider>
  );
};
