import { Route } from 'react-router-dom';

import { ConstanteRotasAlternativas } from 'constants/rotas';
import { ZoopEtapasProvider } from 'store/Zoop/ZoopEtapasContext';

import { ZoopEtapas } from 'pages/Integracoes/LojaAplicativos/Zoop/Etapas';

import LayoutGuard from './LayoutGuard';

export const ZoopRoutes = [
  <Route
    path={ConstanteRotasAlternativas.ZOOP_CONFIGURACAO}
    key="zoop-configuracoes"
  >
    <ZoopEtapasProvider>
      <LayoutGuard
        key="zoop-configuracoes"
        component={ZoopEtapas}
        path={ConstanteRotasAlternativas.ZOOP_CONFIGURACAO}
        exact
        meta={{ auth: true }}
      />
    </ZoopEtapasProvider>
  </Route>,
];
