import React from 'react';
import { Route } from 'react-router-dom';

import { ListarCadastroProduto } from 'pages/Produtos/Listar';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';
import ProdutosFiltrosProvider from '../store/Produtos/ProdutosFiltros';

import ProdutosCadastrar from '../pages/Produtos/Formulario/Cadastrar';
import ProdutosAlterar from '../pages/Produtos/Formulario/Alterar';
import ProdutosVisualizar from '../pages/Produtos/Formulario/Visualizar';

const ProdutosRoutes = () => (
  <Route path={ConstanteRotas.PRODUTO}>
    <ProdutosFiltrosProvider>
      <LayoutGuard
        key="produtos-listar"
        permissaoFuncionalidade={ConstanteFuncionalidades.PRODUTO_LISTAR}
        component={ListarCadastroProduto}
        breadcrumb={[
          {
            title: 'Cadastros',
          },
          {
            title: 'Produtos',
          },
        ]}
        path={ConstanteRotas.PRODUTO}
        exact
        meta={{ auth: true }}
      />
      <LayoutGuard
        key="produtos-cadastrar"
        permissaoFuncionalidade={ConstanteFuncionalidades.PRODUTO_CADASTRAR}
        component={ProdutosCadastrar}
        breadcrumb={[
          {
            title: 'Cadastros',
          },
          {
            title: 'Produtos',
            path: ConstanteRotas.PRODUTO,
          },
          {
            title: 'Novo',
          },
        ]}
        path={[
          ConstanteRotas.PRODUTO_CADASTRAR,
          ConstanteRotas.PRODUTO_CADASTRAR_DUPLICAR,
        ]}
        exact
        meta={{ auth: true }}
      />
      <LayoutGuard
        key="produtos-alterar"
        permissaoFuncionalidade={ConstanteFuncionalidades.PRODUTO_ALTERAR}
        component={ProdutosAlterar}
        breadcrumb={[
          {
            title: 'Cadastros',
          },
          {
            title: 'Produtos',
            path: ConstanteRotas.PRODUTO,
          },
          {
            title: 'Editar',
          },
        ]}
        path={ConstanteRotas.PRODUTO_ALTERAR}
        exact
        meta={{ auth: true }}
      />

      <LayoutGuard
        key="produtos-visualizar"
        permissaoFuncionalidade={ConstanteFuncionalidades.PRODUTO_VISUALIZAR}
        component={ProdutosVisualizar}
        breadcrumb={[
          {
            title: 'Cadastros',
          },
          {
            title: 'Produtos',
            path: ConstanteRotas.PRODUTO,
          },
          {
            title: 'Visualizar',
          },
        ]}
        path={ConstanteRotas.PRODUTO_VISUALIZAR}
        exact
        meta={{ auth: true }}
      />
    </ProdutosFiltrosProvider>
  </Route>
);

export default ProdutosRoutes;
