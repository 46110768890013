import { useState, useCallback } from 'react';
import { Divider, Flex, Td, Tr, Icon, Text } from '@chakra-ui/react';
import { FiArrowUp, FiArrowDown } from 'react-icons/fi';

import { getName } from 'helpers/enum/getName';
import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';
import { moneyMask } from 'helpers/format/fieldsMasks';

import { TextValor } from 'components/PDV/Text/TextValor';

import { Observacao } from '../Observacao';
import { Movimentacao } from '../../validationForms';

type TableDetalhesControleCaixaProps = {
  columnData: Movimentacao;
  corLinha: string;
  dataEmissao: string;
  isEntrada: boolean;
};

export const TableDetalhes = ({
  columnData,
  corLinha,
  dataEmissao,
  isEntrada,
}: TableDetalhesControleCaixaProps) => {
  const [exibirFormaPagamento, setExibirFormaPagamento] = useState(false);

  const handleToggleExibirFormaPagamento = useCallback(() => {
    setExibirFormaPagamento(!exibirFormaPagamento);
  }, [exibirFormaPagamento]);

  const existemBaixas = columnData.baixas !== null;

  return (
    <>
      <Tr
        sx={{
          '& > td': {
            _after: {
              border: 'none',
            },
          },
        }}
        bg={columnData.cancelada ? 'red.50' : 'white'}
      >
        <Td
          cursor={existemBaixas ? 'pointer' : undefined}
          onClick={() => {
            if (existemBaixas) {
              handleToggleExibirFormaPagamento();
            }
          }}
          color={corLinha}
          width="200px"
        >
          <>
            {dataEmissao}{' '}
            {existemBaixas && (
              <Icon
                color="black"
                mt="-3px"
                ml="3px"
                as={exibirFormaPagamento ? FiArrowUp : FiArrowDown}
              />
            )}
          </>
        </Td>
        <Td width="120px" color={corLinha}>
          {columnData.numeroOperacao}
        </Td>
        <Td color={corLinha}>
          {getName(
            IdentificacaoTipoOperacaoEnum,
            columnData.identificacaoTipoOperacao
          )}
          {columnData.observacao && (
            <Observacao
              observacao={columnData.observacao}
              cancelada={columnData.cancelada}
              corLinha={corLinha}
            />
          )}
        </Td>
        <Td color={corLinha}>{columnData.clienteFornecedorNome}</Td>
        <Td color={corLinha}>{columnData.formaPagamentoRecebimentoNome}</Td>
        <Td color={corLinha} textAlign="center">
          {columnData.parcela}
        </Td>
        <Td isNumeric>
          <Flex alignItems="center" justifyContent="flex-end">
            <TextValor
              casasDecimais={2}
              valor={isEntrada ? columnData.valor : columnData.valor * -1}
              color={corLinha}
              fontSize="sm"
              fontWeight="regular"
              symbolFontSize="2xs"
              symbolFontWeight="regular"
            />
          </Flex>
        </Td>
      </Tr>
      {exibirFormaPagamento && existemBaixas && (
        <Tr
          sx={{
            '& > td': {
              paddingTop: '0',
              paddingBottom: '10px',
              fontSize: '12px',
              _after: {
                border: 'none',
              },
            },
          }}
        >
          <Td colSpan={1} />
          <Td colSpan={1} />
          <Td colSpan={1} />
          <Td colSpan={1} />
          <Td colSpan={1}>
            {columnData.baixas.map((formaPagamento) => (
              <Text>{formaPagamento.formaPagamentoRecebimentoNome}</Text>
            ))}
          </Td>
          <Td colSpan={1} />

          <Td isNumeric colSpan={1}>
            {columnData.baixas.map((formaPagamento) => (
              <Text>{moneyMask(formaPagamento.valor, true)}</Text>
            ))}
          </Td>
        </Tr>
      )}
      <Tr
        sx={{
          '& > td': {
            padding: '0',
            _after: {
              border: 'none',
            },
          },
        }}
        w="90%"
      >
        <Td pl="20px !important" pr="20px !important" colSpan={7}>
          <Divider w="100%" />
        </Td>
      </Tr>
    </>
  );
};
