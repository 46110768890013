import React, { useRef, useState } from 'react';
import {
  ModalContent,
  ModalBody,
  Flex,
  Button,
  Heading,
  Text,
  AlertDialogFooter,
  ScaleFade,
  Link,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  Tooltip,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { MdContentCopy } from 'react-icons/md';
import { create, InstanceProps } from 'react-modal-promise';

import TipoRejeicaoNotaFiscalEnum from 'constants/enum/fiscal/tipoRejeicaoNotaFiscal';
import { useAtendimentoChatContext } from 'store/AtendimentoChat';

import { AtencaoAvisoIcon } from 'icons';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import ManterFoco from 'components/Geral/ManterFoco';

interface ModalRejeicaoNotaFiscalProps extends InstanceProps<any> {
  Mensagem: string;
  LinkBaseConhecimento?: string;
  TipoRejeicao?: number;
  callback?: any;
}

const ModalRejeicaoNotaFiscalConst = ({
  Mensagem,
  LinkBaseConhecimento,
  TipoRejeicao,
  callback,
}: ModalRejeicaoNotaFiscalProps) => {
  const { t } = useTranslation();
  const { handleOpenMovideskChat } = useAtendimentoChatContext();

  const [isOpen, setIsOpen] = useState(true);
  const mensagemRef = useRef<HTMLInputElement | null>(null);

  const copiarConteudo = () => {
    mensagemRef.current?.select();
    document.execCommand('copy');
  };

  return (
    <ModalPadraoChakra
      onClose={() => {
        setIsOpen(false);
      }}
      isOpen={isOpen}
      isCentered
      size="xl"
      autoFocus={false}
    >
      <ModalContent
        marginBottom={{ base: 0, sm: '3.75rem' }}
        marginTop={{ base: 0, sm: '3.75rem' }}
        h="unset"
        maxW="600px"
      >
        <ManterFoco
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <ScaleFade initialScale={1.5} in={isOpen}>
            <ModalBody p={0} h="100%">
              <Flex flexDirection="column" align="stretch" h="100%">
                <Flex
                  justifyContent="center"
                  h="150px"
                  bg="orange.400"
                  style={{
                    borderTopLeftRadius: '5px',
                    borderTopRightRadius: '5px',
                  }}
                >
                  <AtencaoAvisoIcon
                    style={{
                      fontSize: '75px',
                      fontWeight: 'bolder',
                      marginTop: '35px',
                    }}
                  />
                </Flex>

                <Flex
                  justifyContent="flex-start"
                  flexDirection="column"
                  bg="gray.50"
                  px={8}
                  py={7}
                  h="250px"
                  style={{
                    borderBottomLeftRadius: '5px',
                    borderBottomRightRadius: '5px',
                  }}
                >
                  <Heading
                    id="tituloModal"
                    as="h2"
                    fontSize="2xl"
                    color="orange.500"
                    fontWeight="semibold"
                    textAlign="initial"
                  >
                    {t('Motivo da rejeição')}
                  </Heading>

                  {TipoRejeicao === TipoRejeicaoNotaFiscalEnum.SEFAZ ? (
                    <Text minH="50px" mt={15} fontSize="14px" color="gray.700">
                      {Mensagem}
                      {LinkBaseConhecimento && (
                        <Text>
                          Veja como resolver em nossa base de conhecimento{' '}
                          <Link
                            color="teal.600"
                            href={LinkBaseConhecimento}
                            isExternal
                          >
                            clicando aqui.
                          </Link>
                        </Text>
                      )}
                    </Text>
                  ) : (
                    <Text minH="50px" mt={15} fontSize="14px" color="gray.700">
                      A nota fiscal foi rejeitada. Entre em contato com o nosso
                      suporte{' '}
                      <Button
                        variant="link"
                        size="sm"
                        colorScheme="teal"
                        color="teal.600"
                        onClick={() => {
                          handleOpenMovideskChat();
                        }}
                      >
                        clicando aqui
                      </Button>{' '}
                      e passe as informações abaixo:
                      <InputGroup size="md" marginTop="5px">
                        <Input
                          type="text"
                          isReadOnly
                          value={Mensagem}
                          isTruncated
                          ref={mensagemRef}
                          border="dashed 2px"
                          _focus={{ border: 'dashed 2px gray.300' }}
                        />
                        <InputRightElement>
                          <Tooltip hasArrow label="Copiar" placement="top">
                            <IconButton
                              aria-label="Copiar"
                              icon={<MdContentCopy />}
                              variant="link"
                              borderRadius="md"
                              color="gray.300"
                              fontSize="lg"
                              _focus={{ border: 'none' }}
                              onClick={copiarConteudo}
                            />
                          </Tooltip>
                        </InputRightElement>
                      </InputGroup>
                    </Text>
                  )}

                  <AlertDialogFooter mt="12">
                    <Button
                      id="confirmModal"
                      bg="orange.400"
                      maxW="200px"
                      ml="auto"
                      autoFocus
                      _hover={{
                        bg: 'orange.200',
                      }}
                      _active={{
                        bg: 'orange.300',
                      }}
                      onClick={() => {
                        setIsOpen(false);
                        if (callback) {
                          callback(true);
                        }
                      }}
                      style={{
                        marginRight: 'auto',
                      }}
                    >
                      {t('Ok')}
                    </Button>
                  </AlertDialogFooter>
                </Flex>
              </Flex>
            </ModalBody>
          </ScaleFade>
        </ManterFoco>
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export const ModalRejeicaoNotaFiscal = create(ModalRejeicaoNotaFiscalConst);
