import React from 'react';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

import TamanhoListar from '../pages/Tamanhos/Listar';
import TamanhoCadastrar from '../pages/Tamanhos/Formulario/Cadastrar';
import TamanhoAlterar from '../pages/Tamanhos/Formulario/Alterar';
import TamanhoVisualizar from '../pages/Tamanhos/Formulario/Visualizar';

const CategoriaClientesRoutes = [
  <LayoutGuard
    key="tamanho-listar"
    permissaoFuncionalidade={ConstanteFuncionalidades.TAMANHO_LISTAR}
    component={TamanhoListar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Tamanhos',
      },
    ]}
    path={ConstanteRotas.TAMANHO}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="tamanho-cadastrar"
    permissaoFuncionalidade={ConstanteFuncionalidades.TAMANHO_CADASTRAR}
    component={TamanhoCadastrar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Tamanhos',
        path: ConstanteRotas.TAMANHO,
      },
      {
        title: 'Novo',
      },
    ]}
    path={ConstanteRotas.TAMANHO_CADASTRAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="tamanho-alterar"
    permissaoFuncionalidade={ConstanteFuncionalidades.TAMANHO_ALTERAR}
    component={TamanhoAlterar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Tamanhos',
        path: ConstanteRotas.TAMANHO,
      },
      {
        title: 'Editar',
      },
    ]}
    path={ConstanteRotas.TAMANHO_ALTERAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="tamanho-visualizar"
    permissaoFuncionalidade={ConstanteFuncionalidades.TAMANHO_VISUALIZAR}
    component={TamanhoVisualizar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Tamanhos',
        path: ConstanteRotas.TAMANHO,
      },
      {
        title: 'Visualizar',
      },
    ]}
    path={ConstanteRotas.TAMANHO_VISUALIZAR}
    exact
    meta={{ auth: true }}
  />,
];

export default CategoriaClientesRoutes;
