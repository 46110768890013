import React, { useState, memo, useCallback } from 'react';
import { Controller, Control, FieldError } from 'react-hook-form';
import BeatLoader from 'react-spinners/BeatLoader';
import { useToken } from '@chakra-ui/react';

import consultarViaCep, { CepResponse } from 'services/viacep';
import { cepMask } from 'helpers/format/fieldsMasks';

import ContainerInputPadrao from 'components/Layout/CampoContainer/CampoContainerPadrao';
import {
  InputControl,
  InputContainer,
  AppendContainer,
  SearchIcon,
  LoaderCss,
} from '../styles';

interface InputCepProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  name: string;
  label: string;
  infoText?: string;
  error?: FieldError | null;
  control: Control<Record<string, any>>;
  defaultValue?: string;
  getCepData: (data: CepResponse) => void;
}

const InputCep = ({
  id,
  name,
  label,
  error = null,
  required,
  infoText,
  control,
  defaultValue = '',
  getCepData,
  disabled,
  ...rest
}: InputCepProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const getData = useCallback(
    (sucesso: boolean, dados: any) => {
      if (sucesso) getCepData(dados);
    },
    [getCepData]
  );

  const handleGetCep = useCallback(
    async (unformattedValue: string) => {
      if (unformattedValue.length === 8) {
        setIsLoading(true);

        consultarViaCep(unformattedValue, getData);

        setIsLoading(false);
      }
    },
    [getData]
  );

  const getFormattedValue = useCallback(
    (value: string) => {
      const unformattedValue = value.replace(/\D/g, '');

      if (unformattedValue.length === 8) handleGetCep(unformattedValue);

      return cepMask(value);
    },
    [handleGetCep]
  );

  return (
    <ContainerInputPadrao
      id={id}
      label={label}
      error={error}
      required={required}
      infoText={infoText}
    >
      <Controller
        defaultValue={defaultValue}
        render={({ field }) => (
          <InputContainer>
            <InputControl
              inputMode="numeric"
              maxLength={9}
              id={id}
              ref={field.ref}
              value={field.value}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                const formattedValue = getFormattedValue(e.currentTarget.value);
                field.onChange(formattedValue);
              }}
              style={{ paddingRight: '40px' }}
              disabled={disabled || isLoading}
              {...rest}
            />
            <AppendContainer>
              {isLoading ? (
                <BeatLoader
                  size={5}
                  color="var(--sti-ck-colors-loading)"
                  css={LoaderCss.toString()}
                />
              ) : (
                <SearchIcon />
              )}
            </AppendContainer>
          </InputContainer>
        )}
        // onFocus={() => {
        //   inputRef.current.focus();
        // }}
        name={`${name}` as const}
        control={control}
      />
    </ContainerInputPadrao>
  );
};

export default memo(InputCep);
