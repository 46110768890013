import { useEffect, useState, useCallback, useRef } from 'react';
import { Box, useMediaQuery } from '@chakra-ui/react';
import { Prompt } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm, FormProvider } from 'react-hook-form';

import api, { ResponseApi } from 'services/api';
import auth from 'modules/auth';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import RodapeFormulario from 'components/Geral/RodapeFormulario';
import ManterFoco from 'components/Geral/ManterFoco';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { FormJurosMultas } from './Alterar/index';

type MultasJuros = {
  diasCarencia: number;
  tipoCobrancaMulta: number;
  tipoValorMulta: number;
  valorMulta: number;
  tipoCobrancaJuros: number;
  tipoValorJuros: number;
  valorJuros: number;
};

type FormData = {
  valorJuros: number;
  tipoValorJuros: number;
  tipoCobrancaJuros: number;
  valorMulta: number;
  tipoValorMulta: number;
  tipoCobrancaMulta: number;
  diasCarencia: number;
};

export const MultasJurosComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [isLargerThan1400] = useMediaQuery('(max-width: 1400px)');

  const { id } = auth.getLoja();

  const formMethods = useForm<FormData>({
    shouldUnregister: false,
    defaultValues: {
      diasCarencia: 0,
    },
  });

  const { handleSubmit, formState, setValue, reset } = formMethods;

  const latestProps = useRef({ setValue });

  useEffect(() => {
    latestProps.current = { setValue };
  });

  const handleGetMultasJuros = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<void, ResponseApi<MultasJuros>>(
      ConstanteEnderecoWebservice.PADRONIZACAO_MULTAS_JUROS_OBTER,
      {
        params: { lojaId: id },
      }
    );

    if (response?.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (response?.sucesso) {
      reset({
        ...response.dados,
      });
    }

    setIsLoading(false);
  }, [id, reset]);

  const onSubmit = handleSubmit(
    async ({
      diasCarencia,
      tipoCobrancaJuros,
      tipoCobrancaMulta,
      tipoValorJuros,
      tipoValorMulta,
      valorJuros,
      valorMulta,
    }) => {
      setIsLoading(true);

      const parameters = {
        lojaId: id,
        diasCarencia,
        tipoCobrancaMulta,
        tipoValorMulta,
        valorMulta,
        tipoCobrancaJuros,
        tipoValorJuros,
        valorJuros,
      };

      const response = await api.put<void, ResponseApi>(
        ConstanteEnderecoWebservice.PADRONIZACAO_MULTAS_JUROS_ALTERAR,
        parameters
      );

      if (response.sucesso) {
        toast.success('O cadastro foi alterado com sucesso.');

        handleGetMultasJuros();
      }

      if (response.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }

      setIsLoading(false);
    }
  );

  useEffect(() => {
    handleGetMultasJuros();
  }, [handleGetMultasJuros]);

  useEffect(() => {
    setFormIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  return (
    <Box w={isLargerThan1400 ? 'full' : '80%'}>
      <Prompt when={formIsDirty} message="" />
      <FormProvider {...formMethods}>
        <ManterFoco blockTab={isLoading}>
          <Box
            borderRadius="md"
            boxShadow="0px 0px 6px #00000034"
            bg="white"
            p="30px"
          >
            {isLoading && <LoadingPadrao />}
            <FormJurosMultas />
          </Box>
          <RodapeFormulario onSubmit={onSubmit} disabled={isLoading} />
        </ManterFoco>
      </FormProvider>
    </Box>
  );
};
