import { Stack, Button, Box } from '@chakra-ui/react';
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useState,
  useRef,
} from 'react';
import { useHistory } from 'react-router-dom';

import auth from 'modules/auth';
import PlanoContratacaoEnum from 'constants/enum/planoContratacao';
import ConstanteRotasPDV from 'constants/rotasPDV';
import ConstanteRotas from 'constants/rotas';

import { ModalBaixarContas } from 'pages/ContasReceber/Listar/ModalBaixarContasAReceber';
import {
  BaixarContaItem,
  BaixarContaList,
  enviarEmailReciboA4,
  imprimirPdfReciboA4,
} from 'pages/ContasReceber/Listar/Compartilhar';

import ImpressaoCupom80mm from 'components/Impressao/impressaoCupom80mm/ImpressaoCupom80mm';
import { ImpressaoCupom80mmRefElement } from 'components/Impressao/impressaoCupom80mm/types';
import { ModalCompartilhar } from 'components/Modal/ModalCompartilhar';
import ImpressaoCupom56mm from 'components/Impressao/impressaoCupom56mm/ImpressaoCupom56mm';
import { ImpressaoCupom56mmRefElement } from 'components/Impressao/impressaoCupom56mm/types';

import {
  MovimentacaoFinanceiraBaixa,
  ObterContasEmAbertoResponse,
} from './validationForm';
import { ModalBaixarValorEspecifico } from './modalBaixarValorEspecifico';

type FornecedorOptionResponse = {
  id: string;
  nome: string;
};

type BotoesParaEfetuarBaixaResponse = {
  isSmallerThan: boolean;
  informacoesRecebimentoContas: ObterContasEmAbertoResponse | undefined;
  clienteFornecedorId: string;
  informacoesCliente: FornecedorOptionResponse[] | undefined;
  casasDecimaisValor: number;
  setInformacoesRecebimentoContas: Dispatch<
    SetStateAction<ObterContasEmAbertoResponse | undefined>
  >;
  handleObterDetalhesRecebimentoContas(clienteFornecedorId: string): void;
  zerarMultaJuros?: MutableRefObject<boolean>;
  onZerarMultaEJuros?: () => void;
};

export function BotoesParaEfetuarBaixa({
  isSmallerThan,
  informacoesRecebimentoContas,
  setInformacoesRecebimentoContas,
  clienteFornecedorId,
  informacoesCliente,
  casasDecimaisValor,
  handleObterDetalhesRecebimentoContas,
  zerarMultaJuros,
  onZerarMultaEJuros,
}: BotoesParaEfetuarBaixaResponse) {
  const [valoresForamZerados, setValoresForamZerados] = useState(false);
  const [movimentacaoIdImpressao, setMovimentacaoIdImpressao] = useState<
    string[]
  >([]);

  const cupom56mmComponentToPrintRef = useRef<ImpressaoCupom56mmRefElement>(
    null
  );
  const cupom80mmComponentToPrintRef = useRef<ImpressaoCupom80mmRefElement>(
    null
  );

  const history = useHistory();
  const planoAtual = auth.getPlano();
  const planoStart = PlanoContratacaoEnum.START;

  const existeContaSelecionada = informacoesRecebimentoContas?.movimentacaoFinanceira?.some(
    (item) => item.selecionado
  );

  const listagemPossuiItens =
    (informacoesRecebimentoContas?.movimentacaoFinanceira || []).length > 0;

  const validacaoCliente = informacoesCliente?.filter(
    (cliente) => cliente?.id === clienteFornecedorId
  );
  const obtendoNomeCliente = validacaoCliente?.map((cliente) => cliente?.nome);
  const clienteIndex = obtendoNomeCliente ? obtendoNomeCliente[0] : [];

  const getPrintFunctions56mm = async () => {
    if (cupom56mmComponentToPrintRef.current) {
      await cupom56mmComponentToPrintRef.current.imprimirCupom56mm();
    }
  };

  const getPrintFunctions80mm = async () => {
    if (cupom80mmComponentToPrintRef.current) {
      await cupom80mmComponentToPrintRef.current.imprimirCupom80mm();
    }
  };

  const compartilharContaRecebida = (
    dados: BaixarContaItem | BaixarContaList
  ) => {
    const idMovimentacaoFinanceira = Array.isArray(dados)
      ? dados.map((conta) => conta.movimentacaoFinanceiraId)
      : [dados.movimentacaoFinanceiraId];
    setMovimentacaoIdImpressao(idMovimentacaoFinanceira);

    ModalCompartilhar({
      items: [
        {
          titulo: 'Recibo A4',
          onClickImpressao: async () => {
            await imprimirPdfReciboA4(idMovimentacaoFinanceira);
          },
          onClickEmail: async () => {
            await enviarEmailReciboA4(idMovimentacaoFinanceira);
          },
        },
        {
          titulo: 'Cupom 80mm',
          onClickImpressao: async () => {
            await getPrintFunctions80mm();
          },
        },
        {
          titulo: 'Cupom 56mm',
          onClickImpressao: async () => {
            await getPrintFunctions56mm();
          },
        },
      ],
    });
  };

  function handleZerarMultaJuros() {
    if (informacoesRecebimentoContas) {
      if (zerarMultaJuros && !zerarMultaJuros.current && onZerarMultaEJuros) {
        onZerarMultaEJuros();
      }
      setValoresForamZerados(true);
      setInformacoesRecebimentoContas((valorAnterior) =>
        valorAnterior
          ? {
              ...valorAnterior,
              movimentacaoFinanceira: (
                valorAnterior.movimentacaoFinanceira || []
              ).map((movimentacao) => ({
                ...movimentacao,
                multa: 0,
                juros: 0,
              })),
            }
          : undefined
      );
    }
  }

  async function handleAbrirModalBaixarValorEspecifico() {
    if (informacoesRecebimentoContas) {
      const somandoValoresItens = informacoesRecebimentoContas?.movimentacaoFinanceira.reduce(
        (acc, curr) =>
          acc +
          (curr?.valorOriginal || 0) +
          (curr?.juros || 0) +
          (curr?.multa || 0),
        0
      );

      const { sucesso } = await ModalBaixarValorEspecifico({
        casasDecimaisValor,
        clienteFornecedorId,
        clienteIndex,
        valorTotalBaixa: Number(somandoValoresItens.toFixed(2)),
        setInformacoesRecebimentoContas,
        informacoesRecebimentoContas,
        zerarMultaJuros: valoresForamZerados,
      });
      setValoresForamZerados(false);
      if (sucesso) {
        handleObterDetalhesRecebimentoContas(clienteFornecedorId);
      }
    }
  }
  async function handleBaixarConta(indexes: number[]) {
    if (informacoesRecebimentoContas?.movimentacaoFinanceira) {
      const { dataVencimento, parcela } =
        indexes.length === 1
          ? informacoesRecebimentoContas?.movimentacaoFinanceira[indexes[0]]
          : ({} as MovimentacaoFinanceiraBaixa);

      const obterMovimentacaoFinanceiraId = indexes.map(
        (index) =>
          informacoesRecebimentoContas?.movimentacaoFinanceira[index]
            .movimentacaoFinanceiraId
      );
      let multa = 0;
      let juros = 0;

      const { sucesso } = await ModalBaixarContas({
        baixarContaItems: indexes.map((index) => {
          const item =
            informacoesRecebimentoContas?.movimentacaoFinanceira[index];

          const valoresDoArray =
            informacoesRecebimentoContas?.movimentacaoFinanceira[index];

          const valorTotalMulta = [valoresDoArray].reduce(
            (acc, curr) => acc + (curr?.multa || 0),
            0
          );
          const valorTotalJuros = [valoresDoArray].reduce(
            (acc, curr) => acc + (curr?.juros || 0),
            0
          );
          const valorOriginal = [valoresDoArray].reduce(
            (acc, curr) => acc + (curr?.valorOriginal || 0),
            0
          );

          multa += item.multa;
          juros += item.juros;

          return {
            juros: valorTotalJuros,
            multa: valorTotalMulta,
            valor: valorOriginal,
            movimentacaoFinanceiraId: item.movimentacaoFinanceiraId,
          };
        }),
        dataVencimento,
        casasDecimaisValor,
        clienteFornecedorRazaoSocial: clienteIndex?.toString(),
        aparecerClienteFornecedorRazaoSocial: true,
        parcela,
        valorMultas: multa,
        valorJuros: juros,
        compartilharContaRecebida,
        clienteFornecedorId,
      });

      if (sucesso) {
        setInformacoesRecebimentoContas((valorAnterior) =>
          valorAnterior
            ? {
                ...valorAnterior,
                movimentacaoFinanceira: valorAnterior.movimentacaoFinanceira.map(
                  (movimentacao, index) => ({
                    ...movimentacao,
                    valorTotal:
                      obterMovimentacaoFinanceiraId[0] ===
                      (informacoesRecebimentoContas?.movimentacaoFinanceira[
                        index
                      ].movimentacaoFinanceiraId || 0)
                        ? 0
                        : movimentacao.valorTotal,
                    multa:
                      obterMovimentacaoFinanceiraId[0] ===
                      (informacoesRecebimentoContas?.movimentacaoFinanceira[
                        index
                      ].movimentacaoFinanceiraId || 0)
                        ? 0
                        : movimentacao.multa,
                    juros:
                      obterMovimentacaoFinanceiraId[0] ===
                      (informacoesRecebimentoContas?.movimentacaoFinanceira[
                        index
                      ].movimentacaoFinanceiraId || 0)
                        ? 0
                        : movimentacao.juros,
                    movimentacaoFinanceiraId:
                      obterMovimentacaoFinanceiraId[0] ===
                      (informacoesRecebimentoContas?.movimentacaoFinanceira[
                        index
                      ].movimentacaoFinanceiraId || 0)
                        ? ''
                        : movimentacao.movimentacaoFinanceiraId,
                    valorOriginal:
                      obterMovimentacaoFinanceiraId[0] ===
                      (informacoesRecebimentoContas?.movimentacaoFinanceira[
                        index
                      ].movimentacaoFinanceiraId || 0)
                        ? 0
                        : movimentacao.valorOriginal,
                  })
                ),
              }
            : undefined
        );

        handleObterDetalhesRecebimentoContas(clienteFornecedorId);
      }
    }
  }

  async function handleBaixarContasSelecionadas() {
    if (informacoesRecebimentoContas) {
      if (existeContaSelecionada === true) {
        const indexes = informacoesRecebimentoContas?.movimentacaoFinanceira
          .map(({ selecionado }, index) => ({ index, selecionado }))
          .filter((item) => item.selecionado)
          .map((item) => item.index);

        if (indexes.length > 0) {
          handleBaixarConta(indexes);
        }
      }
    }
  }

  useEffect(() => {
    const { pathname } = window.location;
    const rotaEstaNoPdv = pathname.includes('pdv');
    if (planoAtual === planoStart) {
      if (rotaEstaNoPdv) {
        history.push(ConstanteRotasPDV.PDV_HOME);
      } else {
        history.push(ConstanteRotas.DASHBOARD);
      }
    }
  }, [history, planoAtual, planoStart]);

  return (
    <Stack
      mt="22px"
      direction={{ base: 'column', sm: 'row' }}
      spacing={{ base: 3, sm: 6, md: 8 }}
      w="full"
      justifyContent="center"
    >
      {isSmallerThan ? (
        <>
          <Button
            size="sm"
            padding="4"
            disabled={!existeContaSelecionada}
            onClick={handleBaixarContasSelecionadas}
            w={['full', '117px']}
            bg={existeContaSelecionada ? 'aquamarine.600' : 'gray.100'}
            color="white"
            _hover={{
              bg: `${existeContaSelecionada ? 'aquamarine.600' : 'gray.100'}`,
            }}
            _active={{
              bg: `${existeContaSelecionada ? 'aquamarine.600' : 'gray.100'}`,
            }}
          >
            Baixar contas
          </Button>
          <Button
            size="sm"
            padding="4"
            disabled={!listagemPossuiItens}
            bg={listagemPossuiItens ? 'transparent' : 'gray.100'}
            color={listagemPossuiItens ? 'black' : 'white'}
            variant={listagemPossuiItens ? 'outline' : 'solid'}
            onClick={handleZerarMultaJuros}
            transition="all ease .5s"
            _hover={
              listagemPossuiItens
                ? { bg: 'red.500', color: 'white', border: '1px solid red' }
                : {}
            }
            _active={
              listagemPossuiItens
                ? {
                    bg: 'red.600',
                    color: 'white',
                    border: '1px solid red',
                  }
                : {}
            }
            w={['full', '155px']}
          >
            Zerar multas e juros
          </Button>
          <Button
            size="sm"
            padding="4"
            transition="all ease .5s"
            disabled={!listagemPossuiItens}
            bg={listagemPossuiItens ? 'transparent' : 'gray.100'}
            color={listagemPossuiItens ? 'black' : 'white'}
            variant={listagemPossuiItens ? 'outline' : 'solid'}
            onClick={handleAbrirModalBaixarValorEspecifico}
            _hover={
              listagemPossuiItens
                ? {
                    bg: 'primary.500',
                    color: 'white',
                    border: '1px solid primary',
                  }
                : {}
            }
            _active={
              listagemPossuiItens
                ? {
                    bg: 'primary.600',
                    color: 'white',
                    border: '1px solid primary',
                  }
                : {}
            }
            w={['full', '195px']}
          >
            Baixar um valor específico
          </Button>
        </>
      ) : (
        <>
          <Button
            size="sm"
            padding="4"
            onClick={handleZerarMultaJuros}
            isDisabled={!listagemPossuiItens}
            variant={listagemPossuiItens ? 'outlineDefault' : 'solid'}
            transition="all ease .5s"
            borderColor="gray.400"
            color={listagemPossuiItens ? 'black' : 'white'}
            colorScheme={listagemPossuiItens ? 'red' : 'gray'}
            w={['full', '155px']}
          >
            Zerar multas e juros
          </Button>
          <Button
            size="sm"
            padding="4"
            transition="all ease .5s"
            w={['full', '195px']}
            isDisabled={!listagemPossuiItens}
            variant={listagemPossuiItens ? 'outlineDefault' : 'solid'}
            borderColor="gray.400"
            color={listagemPossuiItens ? 'black' : 'white'}
            colorScheme={listagemPossuiItens ? 'primary' : 'gray'}
            onClick={handleAbrirModalBaixarValorEspecifico}
          >
            Baixar um valor específico
          </Button>
          <Button
            size="sm"
            padding="4"
            onClick={handleBaixarContasSelecionadas}
            w={['full', '117px']}
            isDisabled={!existeContaSelecionada}
            variant="solid"
            borderColor="gray.400"
            color="white"
            colorScheme={existeContaSelecionada ? 'aquamarine' : 'gray'}
          >
            Baixar contas
          </Button>
        </>
      )}
      {movimentacaoIdImpressao && (
        <>
          <Box display="none">
            <ImpressaoCupom80mm
              ref={cupom80mmComponentToPrintRef}
              movimentacaoFinanceiaId={movimentacaoIdImpressao}
            />
          </Box>
          <Box display="none">
            <ImpressaoCupom56mm
              ref={cupom56mmComponentToPrintRef}
              movimentacaoFinanceiaId={movimentacaoIdImpressao}
              maxWidth="56mm"
            />
          </Box>
        </>
      )}
    </Stack>
  );
}
