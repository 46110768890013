import { useState, useCallback, useEffect } from 'react';
import { Text, useToken, useMediaQuery } from '@chakra-ui/react';
import ReactApexChart from 'react-apexcharts';
import { toast } from 'react-toastify';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';
import { moneyMask } from 'helpers/format/fieldsMasks';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { FaturamentoVendedorResponse } from '../Types/validationsForm';

type GraficoFaturamentoVendedorProps = {
  mesAno: string;
};

export const GraficoFaturamentoVendedor = ({
  mesAno,
}: GraficoFaturamentoVendedorProps) => {
  const [faturamentoVendedor, setFaturamentoVendedor] = useState<
    FaturamentoVendedorResponse[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const colors: string[] = useToken('colors', [
    'blue.500',
    'orange.400',
    'secondary.500',
    'red.300',
    'purple.300',
  ]);
  const dataLabelsColor = useToken('colors', 'white');

  const obterFaturamentoPorVendedor = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<
      void,
      ResponseApi<FaturamentoVendedorResponse[]>
    >(ConstanteEnderecoWebservice.FATURAMENTO_POR_VENDEDOR_COMISSAO, {
      params: { periodo: mesAno },
    });

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        setFaturamentoVendedor(response.dados);
      }
    }

    setIsLoading(false);
  }, [mesAno]);

  const existeFaturamento = faturamentoVendedor.some(
    (valorFatura) => valorFatura.faturamento !== 0
  );

  useEffect(() => {
    obterFaturamentoPorVendedor();
  }, [obterFaturamentoPorVendedor]);

  return (
    <>
      {isLoading && <LoadingPadrao />}
      {existeFaturamento ? (
        <ReactApexChart
          options={{
            labels: (faturamentoVendedor || []).map(
              (vendedor) => vendedor.nome
            ),
            fill: {
              colors,
            },
            colors,

            legend: {
              position: isLargerThan900 ? 'left' : 'bottom',
              horizontalAlign: 'center',
              showForSingleSeries: true,
              floating: false,
              textAnchor: 'middle',
            },
            markers: {
              colors,
            },

            tooltip: {
              y: {
                formatter: (value: number) => {
                  return `${moneyMask(value, true)}`;
                },
              },
            },

            dataLabels: {
              style: {
                colors: [dataLabelsColor],
              },
            },
          }}
          series={(faturamentoVendedor || []).map(
            (vendedor) => vendedor.faturamento
          )}
          type="pie"
          width="100%"
          height={isLargerThan900 ? '200px' : '250px'}
        />
      ) : (
        <Text pl="18px" fontSize="16px">
          Nenhum valor a ser exibido
        </Text>
      )}
    </>
  );
};
