export type ClienteFornecedor = {
  value: string;
  label: string;
};

export type FormData = {
  clienteFornecedor: ClienteFornecedor | null;
  numeroOperacao: string | null;
};

export const formDefaultValues = {
  clienteFornecedor: null,
  numeroOperacao: null,
};
