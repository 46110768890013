import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

const schema = yup.object().shape({
  documentoImportacao: yup
    .string()
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  numeroAdicao: yup
    .number()
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  itemAdicao: yup
    .number()
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  codigoFabricante: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  descontoAdicao: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);

export const useForm = useFormInstance;
