/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-autofocus */
import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo,
  memo,
} from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';

import ConstanteFuncionalidades from 'constants/permissoes';
import auth from 'modules/auth';
import PlanoContaInterface from 'types/planoConta';
import TipoPlanoContaEnum from 'constants/enum/tipoPlanoConta';
import { usePlanoContasGeralContext } from 'store/PlanoContas/PlanoContasGeral';
import { usePlanoContasVisaoDREContext } from 'store/PlanoContas/PlanoContasVisaoDRE';

import ButtonComOpcoes from 'components/Button/ButtonComOpcoes';

import {
  Container,
  Card,
  SideContainer,
  ExpandirRecolherButton,
  ExpandirIcon,
  RecolherIcon,
  AddButton,
  AddIcon,
  NivelIcon,
  TagInativo,
  ItemText,
} from '../../styles';

interface ItemProps {
  item: PlanoContaInterface;
  paiIsAtivo: boolean;
  nivel: number;
  children: React.ReactNode;
}

export const ItemVisaoDre = memo(
  ({ item, paiIsAtivo, nivel, children }: ItemProps) => {
    const {
      handleGetSubNiveisPlanoConta,
      handleOpenModal,
      itemsExpandidos,
      handleDeletePlanoConta,
      handlePutPlanoConta,
      handleInativarPlanoConta,
    } = usePlanoContasGeralContext();
    const { handleMoverPlanoConta } = usePlanoContasVisaoDREContext();
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);
    const [firstRender, setFirstRender] = useState(false);

    const permissaoPlanoContaCadastrar = useMemo(
      () =>
        auth.possuiPermissao(ConstanteFuncionalidades.PLANO_CONTA_CADASTRAR),
      []
    );

    const permissaoPlanoContaAlterar = useMemo(
      () => auth.possuiPermissao(ConstanteFuncionalidades.PLANO_CONTA_ALTERAR),
      []
    );

    const haveChildrens = useMemo(
      () => nivel < 4 && handleGetSubNiveisPlanoConta(item.id) > 1,
      [handleGetSubNiveisPlanoConta, item.id, nivel]
    );

    const removerItemDropdownObjeto = useMemo(() => {
      if (!haveChildrens) {
        return [
          {
            title: t('Remover'),
            onClick: () => {
              handleDeletePlanoConta(item.id, item.ativo);
            },
            funcionalidadePermissao:
              ConstanteFuncionalidades.PLANO_CONTA_EXCLUIR,
          },
        ];
      }

      return [];
    }, [haveChildrens, t, handleDeletePlanoConta, item.id, item.ativo]);

    const ativarInativarDropdownObjeto = useMemo(() => {
      if (!item.ativo && paiIsAtivo) {
        return [
          {
            title: t('Ativar'),
            onClick: () => handlePutPlanoConta({ ...item, ativo: true }, true),
            funcionalidadePermissao:
              ConstanteFuncionalidades.PLANO_CONTA_ALTERAR,
          },
        ];
      }
      if (item.ativo) {
        return [
          {
            title: t('Inativar'),
            onClick: () => handleInativarPlanoConta(item.id, item.ativo),
            funcionalidadePermissao:
              ConstanteFuncionalidades.PLANO_CONTA_EXCLUIR,
          },
        ];
      }

      return [];
    }, [paiIsAtivo, handleInativarPlanoConta, handlePutPlanoConta, item, t]);

    const handleClose = useCallback(() => {
      if (haveChildrens) setIsOpen(false);
    }, [haveChildrens]);

    const ref = useRef() as React.RefObject<HTMLDivElement>;

    const [{ isDragging }, dragRef, preview] = useDrag({
      item: {
        type: 'CATEGORIAPRODUTO',
        planoConta: item,
        nivel,
        handleClose,
        width: ref.current ? ref.current.getBoundingClientRect().width || 0 : 0,
        canDrop: false,
        canDropCount: false,
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
      isDragging: (monitor) => item.id === monitor.getItem().planoConta.id,
    });

    const [, dropRef] = useDrop({
      accept: 'CATEGORIAPRODUTO',
      hover(
        draggedItem: {
          type: string;
          planoConta: PlanoContaInterface;
          handleClose: () => void;
          width: number;
          canDrop: boolean;
          canDropCount: boolean;
        },
        monitor
      ) {
        draggedItem.handleClose();

        if (!draggedItem.canDropCount) {
          draggedItem.canDropCount = true;
          setTimeout(() => {
            draggedItem.canDrop = true;
          }, 100);
        }

        if (draggedItem.canDrop) {
          draggedItem.canDropCount = false;
          draggedItem.canDrop = false;

          if (draggedItem.planoConta.id === item.id) return;
          if (haveChildrens) setIsOpen(true);

          const targetSize = ref.current
            ? ref.current.getBoundingClientRect()
            : undefined;

          if (targetSize) {
            draggedItem.width = targetSize.width;
            const targetCenter = (targetSize.bottom - targetSize.top) / 2;

            const draggedOffset = monitor.getClientOffset();
            if (draggedOffset) {
              const draggedTop = draggedOffset.y - targetSize.top;

              const draggedPaiId = draggedItem.planoConta.planoContaPaiId;
              let targetPaiId =
                draggedTop < targetCenter || nivel === 4
                  ? item.planoContaPaiId
                  : item.id;

              if (nivel === 2) {
                targetPaiId = item.id;
              }

              if (item.tipoPlanoConta !== TipoPlanoContaEnum.TOTALIZADOR) {
                targetPaiId = item.planoContaPaiId;
              }

              if (draggedPaiId === targetPaiId) {
                return;
              }

              const draggedItemSubNiveis = handleGetSubNiveisPlanoConta(
                draggedItem.planoConta.id
              );
              if (draggedItemSubNiveis > 1 && nivel + draggedItemSubNiveis > 4)
                return;

              handleMoverPlanoConta(
                draggedItem.planoConta.id,
                targetPaiId,
                draggedTop < targetCenter ? item.id : undefined
              );
              draggedItem.planoConta = {
                ...draggedItem.planoConta,
                planoContaPaiId: targetPaiId,
              };
            }
          }
        }
      },
      drop(draggedItem: { type: string; planoConta: PlanoContaInterface }) {
        handlePutPlanoConta(draggedItem.planoConta);
      },
    });

    if (nivel > 2) {
      dragRef(dropRef(ref));
    } else if (nivel === 2) {
      dropRef(ref);
    }

    useEffect(() => {
      if (nivel > 2) preview(getEmptyImage(), { captureDraggingState: true });
    }, [nivel, preview]);

    useEffect(() => {
      if (firstRender) setFirstRender(true);
    }, [firstRender]);

    useEffect(() => {
      if (!firstRender) setIsOpen(itemsExpandidos);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsExpandidos]);

    const formatarNomeCreditoDebito = (
      nivelAtual: number,
      itemAtual: PlanoContaInterface
    ) => {
      if (nivelAtual > 2) {
        if (itemAtual.tipoPlanoConta === TipoPlanoContaEnum.CREDITO) {
          return `+ ${itemAtual.nome}`;
        }

        if (itemAtual.tipoPlanoConta === TipoPlanoContaEnum.DEBITO) {
          return `- ${itemAtual.nome}`;
        }
      }

      return itemAtual.nome;
    };

    return (
      <>
        <Container nivel={nivel}>
          <NivelIcon />
          <Card
            ref={
              permissaoPlanoContaAlterar.permitido &&
              item.ativo &&
              !item.bloqueado
                ? ref
                : undefined
            }
            isDragging={isDragging}
            style={
              !permissaoPlanoContaAlterar.permitido ||
              nivel < 3 ||
              item.bloqueado
                ? { cursor: 'default' }
                : undefined
            }
            nivel={nivel}
            inativo={!item.ativo}
            id="planoConta-draggable"
          >
            <SideContainer>
              {haveChildrens && !isDragging && (
                <ExpandirRecolherButton
                  id={`expandir-planoConta-nivel${nivel}`}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  {isOpen ? <ExpandirIcon /> : <RecolherIcon />}
                </ExpandirRecolherButton>
              )}

              {!isDragging && (
                <ItemText id="planoConta-nome" data-item-name={item.nome}>
                  {formatarNomeCreditoDebito(nivel, item)}
                </ItemText>
              )}
            </SideContainer>
            <SideContainer>
              {!item.ativo && <TagInativo>{t('Inativo')}</TagInativo>}
              {nivel < 4 &&
                nivel > 1 &&
                !isDragging &&
                permissaoPlanoContaCadastrar.permitido &&
                item.tipoPlanoConta === TipoPlanoContaEnum.TOTALIZADOR &&
                item.ativo && (
                  <AddButton
                    id={`adicionar-planoConta-nivel${nivel + 1}`}
                    onClick={() => {
                      handleOpenModal(nivel < 3, undefined, item.id);
                    }}
                  >
                    <AddIcon />
                  </AddButton>
                )}

              {!isDragging && nivel > 2 && (
                <ButtonComOpcoes
                  id="mostrarMais"
                  data-mostrarmais-name={item.nome}
                  dropdownItems={[
                    {
                      title: t('Editar'),
                      onClick: () => {
                        handleOpenModal(nivel < 4, item, item.planoContaPaiId);
                      },
                      funcionalidadePermissao:
                        ConstanteFuncionalidades.PLANO_CONTA_ALTERAR,
                    },
                    ...removerItemDropdownObjeto,
                    ...ativarInativarDropdownObjeto,
                  ]}
                  iconHeight="18px"
                />
              )}
            </SideContainer>
          </Card>
        </Container>

        {isOpen && haveChildrens && children}
      </>
    );
  }
);
