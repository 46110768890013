import React from 'react';
import {
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Flex,
  Button,
  useMediaQuery,
  Heading,
  Text,
  Center,
  useDisclosure,
  ModalProps,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { useForm, FormProvider } from 'react-hook-form';

import { AtencaoAvisoIcon } from 'icons';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import ManterFoco from 'components/Geral/ManterFoco';
import Textarea from 'components/PDV/Textarea';

import { yupResolver, FormData } from './validationForm';

type ModalMotivoResponse = {
  motivo: string;
};

type ModalMotivoProps = Omit<ModalProps, 'children' | 'isOpen' | 'onClose'> &
  InstanceProps<ModalMotivoResponse> & {
    submitButtonText?: string;
    motivoInputLabel?: string;
    modalTitle?: string;
  };

export const ModalMotivo = create<ModalMotivoProps, ModalMotivoResponse>(
  ({
    onResolve,
    onReject,
    submitButtonText = 'Confirmar',
    motivoInputLabel = 'Antes de confirmar, informe o motivo.',
    modalTitle = 'Informe o motivo',
    ...rest
  }) => {
    const [menorQue700] = useMediaQuery('(max-width: 700px)');

    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const formMethods = useForm<FormData>({ resolver: yupResolver });

    const handleOnSubmit = formMethods.handleSubmit(async ({ motivo }) => {
      onResolve({ motivo });
    });

    return (
      <ModalPadraoChakra
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size="xl"
      >
        <ModalContent
          marginBottom={{ base: 0, sm: '3.75rem' }}
          marginTop={{ base: 0, sm: '3.75rem' }}
          h="unset"
          maxW={menorQue700 ? undefined : '600px'}
        >
          <ManterFoco
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <ModalCloseButton color="white" />
            <ModalBody p={0} h="100%">
              <FormProvider {...formMethods}>
                <Flex flexDirection="column" align="stretch" h="100%">
                  <Flex
                    justifyContent="center"
                    h="150px"
                    bg="red.400"
                    style={{
                      borderTopLeftRadius: '5px',
                      borderTopRightRadius: '5px',
                    }}
                  >
                    <AtencaoAvisoIcon
                      style={{
                        fontSize: '75px',
                        fontWeight: 'bolder',
                        marginTop: '35px',
                        color: 'white',
                      }}
                    />
                  </Flex>

                  <Flex
                    justifyContent="flex-start"
                    flexDirection="column"
                    bg="gray.50"
                    px={8}
                    py={7}
                  >
                    <Heading
                      as="h2"
                      fontSize="2xl"
                      color="red.500"
                      fontWeight="semibold"
                      textAlign="initial"
                    >
                      {modalTitle}
                    </Heading>

                    <Text mt={15} fontSize="sm" color="gray.700">
                      {motivoInputLabel}
                    </Text>

                    <Textarea
                      id="motivo"
                      name="motivo"
                      placeholder="Descreva o motivo"
                      maxLength={255}
                      mt={1}
                      mb={3}
                      bg="white"
                      autoFocus
                    />

                    <Center>
                      <Flex justifyContent="flex" mt="4">
                        <Button
                          variant="outline"
                          maxW="120px"
                          onClick={onClose}
                        >
                          Voltar
                        </Button>
                        <Button
                          variant="solid"
                          colorScheme="red"
                          maxW="200px"
                          onClick={handleOnSubmit}
                          ml="4"
                        >
                          {submitButtonText}
                        </Button>
                      </Flex>
                    </Center>
                  </Flex>
                </Flex>
              </FormProvider>
            </ModalBody>
          </ManterFoco>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
