import { useEffect, useState, useCallback, useRef } from 'react';
import {
  Box,
  FormLabel,
  Button,
  Flex,
  Link,
  useMediaQuery,
  Text,
  Tooltip,
  Tr,
  Td,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import getOptionsByEnum from 'helpers/format/getOptionsByEnum';
import { enumTipoPrecoRelatorioInventario } from 'constants/enum/tipoPrecoRelatorioInventario';
import StatusConsultaEnum from 'constants/enum/statusConsulta';
import auth from 'modules/auth';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import StatusRelatorioInventario from 'constants/enum/statusRelatorioInventario';
import api, { ResponseApi } from 'services/api';
import { formatDateHourMinute } from 'helpers/format/formatStringDate';

import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import { SimpleCard } from 'components/update/Form/SimpleCard';
import { Switch } from 'components/update/Switch';
import InputDateRange from 'components/PDV/InputDateRange';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import { InfoIcon } from 'icons';
import {
  PagedTable,
  PagedTableForwardRefData,
} from 'components/update/Table/PagedTable';
import { PaginationData } from 'components/update/Pagination';

import { formDefaultValues, FormData } from './validationsForm';

type ListarLocalEstoqueProps = { id: string; nome: string };

type RegistrosRelatorioInventario = {
  id: string;
  descricaoFiltros: FormData[];
  dataHoraEmissao: Date;
  status: number;
  arquivoStorage: string;
};

export function RelatorioInventario() {
  const [
    listagemRelatorioInventario,
    setListagemRelatorioInventario,
  ] = useState<RegistrosRelatorioInventario[]>();
  const [listarSelectLocalEstoque, setListarSelectLocalEstoque] = useState<
    ListarLocalEstoqueProps[]
  >([]);
  const [totalRegistros, setTotalRegistros] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [isLargerThan841] = useMediaQuery('(min-width: 841px)');
  const isMountedRef = useIsMountedRef();

  const formMethods = useForm<FormData>({
    defaultValues: formDefaultValues,
  });
  const pageIsLoaded = useRef(false);
  const pagedTableRef = useRef<PagedTableForwardRefData>(null);

  const [
    precoRelatorioInventario,
    localEstoque,
    dataHoraInicio,
    exibirMovimentacaoEstoque,
    totalizarGrade,
  ] = formMethods.watch([
    'precoRelatorioInventario',
    'localEstoque',
    'dataHoraInicio',
    'exibirMovimentacaoEstoque',
    'totalizarGrade',
  ]);
  const lojaId = auth.getLoja().id;
  const dataAtual = new Date();

  const posicaoDataFormatada = `${dataHoraInicio.getDate()}/${
    dataHoraInicio.getMonth() + 1
  }/${dataHoraInicio.getFullYear()}`;

  const dataAtualFormatada = `${dataAtual.getDate()}/${
    dataAtual.getMonth() + 1
  }/${dataAtual.getFullYear()}`;

  useEffect(() => {
    const params = {
      lojaId,
      statusConsulta: StatusConsultaEnum.ATIVOS,
    };

    async function listarLocalEstoque() {
      const response = await api.get<
        void,
        ResponseApi<ListarLocalEstoqueProps[]>
      >(ConstanteEnderecoWebservice.LOCAL_ESTOQUE_LISTAR_SELECT, {
        params,
      });

      if (response) {
        if (response.avisos) {
          response.avisos.map((item: string) => toast.warning(item));
        }

        if (response.sucesso) {
          setListarSelectLocalEstoque(response.dados);
        }
      }
    }
    listarLocalEstoque();
  }, [lojaId]);

  const paginationHandle = useCallback(
    async (gridPaginadaConsulta: PaginationData) => {
      setIsLoading(true);
      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<RegistrosRelatorioInventario>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.RELATORIO_DE_INVENTARIO_LISTAGEM,
          gridPaginadaConsulta
        )
      );

      if (response?.sucesso && isMountedRef.current) {
        setTotalRegistros(response.dados.total);
        setListagemRelatorioInventario(response.dados.registros);
        setIsLoading(false);
      }

      if (isMountedRef.current) {
        setIsLoading(false);

        if (!pageIsLoaded.current) {
          pageIsLoaded.current = true;
        }
      }
    },
    [isMountedRef]
  );

  const handleSubmit = formMethods.handleSubmit(async () => {
    if (posicaoDataFormatada === dataAtualFormatada) {
      setIsLoading(true);
      const response = await api.post<void, ResponseApi<string>>(
        ConstanteEnderecoWebservice.GERAR_RELATORIO_DE_INVENTARIO,
        {
          tipoPrecoRelatorioInventario: precoRelatorioInventario,
          exibirMovimentacaoEstoque,
          localEstoqueId: localEstoque,
          posicaoEstoqueDia: dataHoraInicio,
          totalizarGrade,
          lojaId,
        }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response) {
          if (response.avisos) {
            response.avisos.forEach((aviso) => toast.warning(aviso));
          }

          if (response.sucesso) {
            toast.success(
              'O relatório foi adicionado com sucesso! Acompanhe a listagem abaixo para fazer o download.'
            );
            pagedTableRef.current?.reload();
            setIsLoading(false);
          }
        }
      }
    } else {
      ModalConfirmacaoExcluir({
        title: 'Gerar o relatório?',
        text: `O processamento da posição do estoque no dia ${posicaoDataFormatada} pode levar alguns minutos, acompanhe a sua solicitação na listagem do histórico abaixo. Deseja continuar?`,
        confirmButtonText: 'Sim, continuar!',
        cancelButtonText: 'Cancelar',
        callback: async (ok: boolean) => {
          if (ok) {
            setIsLoading(true);
            const response = await api.post<void, ResponseApi<FormData>>(
              ConstanteEnderecoWebservice.GERAR_RELATORIO_DE_INVENTARIO,
              {
                tipoPrecoRelatorioInventario: precoRelatorioInventario,
                exibirMovimentacaoEstoque,
                localEstoque,
                posicaoEstoqueDia: dataHoraInicio,
                totalizarGrade,
                lojaId,
              }
            );

            if (response) {
              if (response.avisos) {
                response.avisos.forEach((aviso) => toast.warning(aviso));
              }

              if (response) {
                if (response.avisos) {
                  response.avisos.forEach((aviso) => toast.warning(aviso));
                }

                if (response.sucesso) {
                  setIsLoading(false);
                  pagedTableRef.current?.reload();
                  toast.success('Relatório gerado com sucesso');
                }
              }
            }
          }
        },
      });
    }
  });

  return (
    <>
      <FormProvider {...formMethods}>
        <SimpleCard mb="20px">
          <Box>
            <Flex direction={isLargerThan841 ? 'row' : 'column'}>
              <Box w="full" mb={isLargerThan841 ? 25 : 25} mr="40px">
                <SelectPadrao
                  label="Preço"
                  id="precoRelatorioInventario"
                  placeholder="Selecione um preço"
                  name="precoRelatorioInventario"
                  options={getOptionsByEnum(enumTipoPrecoRelatorioInventario)}
                />
              </Box>
              <Box mb={isLargerThan841 ? 0 : 25} w="full" mr="40px">
                <SelectPadrao
                  label="Local de estoque"
                  id="localEstoque"
                  placeholder="Selecione um estoque"
                  name="localEstoque"
                  options={(listarSelectLocalEstoque || []).map(
                    ({ id, nome }) => {
                      return (
                        {
                          label: nome,
                          value: id,
                        } || []
                      );
                    }
                  )}
                />
              </Box>
              <Box mb={isLargerThan841 ? 0 : 25} w="full">
                <FormLabel mt="-1" fontSize="sm" color="black" mb="0">
                  Posição do estoque no dia
                </FormLabel>
                <InputDateRange
                  borderColor="gray.100"
                  borderRadius="md"
                  placeholder="Selecione uma data"
                  id="posicaoData"
                  name="posicaoData"
                  startDateName="dataHoraInicio"
                  endDateName="dataHoraFim"
                  maxDate={new Date()}
                  cleanFiltersButtonText="Cancelar"
                />
              </Box>
            </Flex>
          </Box>

          <Flex
            flexFlow="row wrap"
            columnGap="6"
            rowGap="4"
            w={isLargerThan841 ? '1000px' : 'full'}
            alignItems="center"
            justifyItems="flex-start"
          >
            <Box display="inline-flex" alignItems="center">
              <Box>
                <Switch
                  alignSelf="center"
                  size="lg"
                  isRequired={false}
                  name="exibirMovimentacaoEstoque"
                  id="exibirMovimentacaoEstoque"
                  mb="0"
                />
              </Box>
              <FormLabel ml="4" mb="1">
                Listar somente produtos com movimentação de estoque
              </FormLabel>
            </Box>
            <Box display="inline-flex" alignItems="center">
              <Box>
                <Switch
                  isRequired={false}
                  size="lg"
                  name="totalizarGrade"
                  id="totalizarGrade"
                  mb="0"
                />
              </Box>
              <FormLabel ml="4" mb="1" mr="1">
                Totalizar grade
              </FormLabel>
              <Tooltip
                shouldWrapChildren
                gutter={10}
                hasArrow
                placement="auto"
                label={
                  <>
                    <Text>
                      Caso selecionado, a grade não será exibida, e os valores
                      serão totalizados em uma única linha para cada produto.
                    </Text>
                  </>
                }
                fontSize="sm"
              >
                <InfoIcon />
              </Tooltip>
            </Box>
          </Flex>

          <Flex
            mt={isLargerThan841 ? 0 : '10px'}
            justifyContent="flex-end"
            alignItems="right"
          >
            <Button
              onClick={handleSubmit}
              size="lg"
              my="4"
              borderRadius="5px"
              colorScheme="secondary"
              w={isLargerThan841 ? 200 : 'full'}
            >
              Gerar Relatório
            </Button>
          </Flex>
        </SimpleCard>
        <PagedTable
          ref={pagedTableRef}
          isLoading={isLoading}
          loadColumnsData={paginationHandle}
          itemsTotalCount={totalRegistros}
          defaultKeyOrdered="dataHoraEmissao"
          defaultOrderDirection="desc"
          tableHeaders={[
            {
              key: 'dataHoraEmissao',
              content: 'Data de emissão',
              w: '38px',
              isOrderable: true,
            },
            {
              key: 'Filtros',
              content: 'Filtros',
              w: 'auto',
              isOrderable: false,
            },
            {
              key: 'Status',
              content: 'Status',
              isOrderable: false,
              w: '200px',
            },
            {
              key: '',
              content: '',
              isOrderable: false,
              w: '100px',
            },
          ]}
          renderTableRows={(listagemRelatorioInventario || []).map(
            (relatorioInventario) => {
              let valorStatus = '';
              if (relatorioInventario.status) {
                if (
                  relatorioInventario.status ===
                  StatusRelatorioInventario.AGUARDANDO
                ) {
                  valorStatus = StatusRelatorioInventario.properties[1].name;
                } else if (
                  relatorioInventario.status ===
                  StatusRelatorioInventario.EM_PROCESSAMENTO
                ) {
                  valorStatus = StatusRelatorioInventario.properties[2].name;
                } else if (
                  relatorioInventario.status ===
                  StatusRelatorioInventario.CONCLUIDO
                ) {
                  valorStatus = StatusRelatorioInventario.properties[3].name;
                }
              }

              return (
                <Tr key={relatorioInventario.id}>
                  <Td>
                    {formatDateHourMinute(relatorioInventario?.dataHoraEmissao)}
                  </Td>
                  <Td
                    style={{
                      textAlign: 'left',
                    }}
                  >
                    {relatorioInventario.descricaoFiltros}
                  </Td>
                  <Td>{valorStatus}</Td>
                  {relatorioInventario.status ===
                  StatusRelatorioInventario.CONCLUIDO ? (
                    <>
                      <Td>
                        <Link
                          color="aquamarine.600"
                          href={relatorioInventario.arquivoStorage}
                          target="_blank"
                        >
                          Download
                        </Link>
                      </Td>
                    </>
                  ) : (
                    <Td />
                  )}
                </Tr>
              );
            }
          )}
        />
      </FormProvider>
    </>
  );
}
