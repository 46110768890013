import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Stack } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { GlobalHotKeys } from 'react-hotkeys';

import ConstanteRotasPDV from 'constants/rotasPDV';
import { useMenuContext } from 'store/PDV/Menu';
import ConstanteFuncionalidades from 'constants/permissoes';

import InputNumerico from 'components/Input/InputNumerico';
import LogAuditoriaTelaEnum from 'constants/enum/logAuditoriaTela';
import ModalAutorizacaoFuncionalidade from 'components/Modal/ModalAutorizacaoFuncionalidade';

const HeaderMenuOptions = () => {
  const {
    menuItems,
    modalAuthIsOpen,
    setChavePermissaoTemporaria,
    setModalAuthIsOpen,
  } = useMenuContext();

  const history = useHistory();

  const { control } = useFormContext();

  const openOperacoes = () => {
    history.push(ConstanteRotasPDV.PDV_CONSULTAR_OPERACOES);
  };

  const keyMap = {
    F4: ['f4'],
  };

  const handlersHotKeys = {
    F4: (event: any) => {
      event.preventDefault();
      openOperacoes();
    },
  };

  return (
    <>
      <InputNumerico
        type="hidden"
        id="identificacaoTipoOperacao"
        name="identificacaoTipoOperacao"
        control={control}
        label=""
        error={{} as any}
      />
      <GlobalHotKeys keyMap={keyMap} handlers={handlersHotKeys} />
      <Stack
        direction={{ base: 'column', md: 'row' }}
        align={{ base: 'start', md: 'center' }}
        spacing={{ base: 0, md: 12, lg: 20 }}
      >
        {menuItems.map((item) => item)}
      </Stack>

      <ModalAutorizacaoFuncionalidade
        isOpen={modalAuthIsOpen}
        setIsOpen={setModalAuthIsOpen}
        autorizado={(chave) => {
          setChavePermissaoTemporaria(chave);
        }}
        titulo={ConstanteFuncionalidades.PDV_LIBERAR_lOCAL_ESTOQUE.titulo}
        descricao={ConstanteFuncionalidades.PDV_LIBERAR_lOCAL_ESTOQUE.descricao}
        tela={LogAuditoriaTelaEnum.PDV}
        permissoes={[ConstanteFuncionalidades.PDV_LIBERAR_lOCAL_ESTOQUE.codigo]}
      />
    </>
  );
};

export default HeaderMenuOptions;
