import { useCallback, useEffect } from 'react';
import {
  Text,
  VStack,
  Box,
  HStack,
  Icon,
  useMediaQuery,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import {
  ContainerIntegracaoFixo,
  useSmartPosEtapasContext,
} from 'store/SmartPos/SmartPosEtapasContext';
import { IdentificacaoEtapasSmartPos } from 'constants/enum/IdentificacaoEtapasSmartPos';
import {
  activateSmartPos,
  cadastrarSenhaAdm,
  getImpressaoEscolhidaSmartPos,
  getSenhaAdm,
} from 'services/smartPos';
import { enumTipoImpressaoFichaSmartPos } from 'constants/enum/enumTipoImpressaoFichaSmartPos';

import { SenhaAdmIcon, SenhaConferidaIcon, SenhaErroIcon } from 'icons';
import { InputPin } from 'components/update/Input/InputPin';

import { HeaderSmartPos } from '../Layout/Header';
import { FooterSmartPos } from '../Layout/FooterSmartPos';
import { defaultValues, FormData } from './validationForms';

export const SenhaAdmnistrativa = () => {
  const {
    setActiveStep,
    animacaoLoading,
    setIsLoading,
  } = useSmartPosEtapasContext();

  const [isSmallerThan360] = useMediaQuery('(max-width: 360px)');

  const formMethods = useForm<FormData>({
    defaultValues,
  });

  const { watch, reset } = formMethods;

  const [senha, confirmarSenha] = watch(['senha', 'confirmarSenha']);

  const senhasEstaoPreenchidas =
    senha?.length === 6 && confirmarSenha?.length === 6;
  const senhaEstaInvalida = senhasEstaoPreenchidas && senha !== confirmarSenha;
  const senhaEstaCorreta = !senhaEstaInvalida && senhasEstaoPreenchidas;

  const handleAvancar = useCallback(async () => {
    setIsLoading(true);

    const response = await cadastrarSenhaAdm(senha);
    if (response) {
      const integrationActive = await activateSmartPos();
      if (integrationActive) {
        setActiveStep(IdentificacaoEtapasSmartPos.CODIGO_ATIVACAO);
      } else {
        toast.error('Não foi possível ativar a integração.');
      }
    }

    setIsLoading(false);
  }, [senha, setActiveStep, setIsLoading]);

  const handleVoltar = async () => {
    setIsLoading(true);
    const response = (await getImpressaoEscolhidaSmartPos()) as number;

    if (response === enumTipoImpressaoFichaSmartPos.NENHUM.value) {
      setActiveStep(IdentificacaoEtapasSmartPos.TIPO_IMPRESSAO);
      return;
    }
    setActiveStep(IdentificacaoEtapasSmartPos.TEXTO_PARA_IMPRESSAO);
  };

  useEffect(() => {
    const getSenha = async () => {
      const response = await getSenhaAdm();

      if (response !== null) {
        reset({
          senha: response,
          confirmarSenha: response,
        });

        animacaoLoading();
      }
      animacaoLoading();
    };
    getSenha();
  }, [reset, animacaoLoading]);

  return (
    <FormProvider {...formMethods}>
      <ContainerIntegracaoFixo>
        <HeaderSmartPos
          title="Senha administrativa"
          icon={SenhaAdmIcon}
          handleVoltar={handleVoltar}
        />
        <VStack px={['8px', '20px', '20px']} gap={['12px', '24px']}>
          <Box color="primary.50" fontSize="14px" letterSpacing="0px" w="full">
            <Text fontWeight="semibold">
              Informe uma{' '}
              <Text fontWeight="extrabold" display="inline-block">
                senha numérica
              </Text>{' '}
              de 6 dígitos. Essa senha servirá para que o administrador libere
              funções ou eventualmente efetue algum desbloqueio dentro do
              aplicativo. A senha poderá ser alterada no painel administrativo.
            </Text>
          </Box>
          <VStack
            justifyContent="center"
            px="6px"
            w="full"
            gap={['12px', '24px']}
          >
            <Box>
              <HStack alignItems="center">
                <InputPin
                  name="senha"
                  labelColor="primary.50"
                  borderColor="primary.50"
                  borderWidth="1px"
                  label="Senha:"
                  borderStyle="solid"
                  height={isSmallerThan360 ? '32px' : '40px'}
                  maxWidth={isSmallerThan360 ? '32px' : '40px'}
                  sx={{
                    _placeholderShown: {
                      bg: 'transparent',
                      borderColor: 'gray.200',
                    },
                  }}
                  bg="white"
                  _hover={{
                    borderColor: 'primary.50',
                  }}
                  _focus={{
                    borderColor: 'primary.50',
                    bg: 'white',
                  }}
                  isRequired={false}
                />
              </HStack>
            </Box>
            <Box>
              <HStack alignItems="center">
                <InputPin
                  name="confirmarSenha"
                  label="Confirmar senha:"
                  labelColor="primary.50"
                  borderColor={
                    senhaEstaInvalida
                      ? 'red.500'
                      : senhaEstaCorreta
                      ? 'aquamarine.600'
                      : 'primary.50'
                  }
                  borderWidth="1px"
                  borderStyle="solid"
                  sx={{
                    _placeholderShown: {
                      bg: 'transparent',
                      borderColor: 'gray.200',
                    },
                  }}
                  bg="white"
                  _hover={{
                    borderColor: senhaEstaInvalida
                      ? 'red.500'
                      : senhaEstaCorreta
                      ? 'aquamarine.600'
                      : 'primary.50',
                  }}
                  _focus={{
                    borderColor: 'primary.50',
                    bg: 'white',
                  }}
                  isRequired={false}
                  height={isSmallerThan360 ? '32px' : '40px'}
                  maxWidth={isSmallerThan360 ? '32px' : '40px'}
                />
              </HStack>
            </Box>
          </VStack>
          {senhaEstaInvalida && (
            <HStack alignItems="center">
              <Icon as={SenhaErroIcon} boxSize="20px" />
              <Text
                color="red.500"
                fontSize="xs"
                fontWeight="semibold"
                mt="20px"
                letterSpacing="0px"
              >
                Senha não confere
              </Text>
            </HStack>
          )}
          {senhaEstaCorreta && (
            <HStack>
              <Icon as={SenhaConferidaIcon} boxSize="20px" />
            </HStack>
          )}
        </VStack>
      </ContainerIntegracaoFixo>
      <FooterSmartPos
        handleAvancar={handleAvancar}
        isDisabledAvancar={!senhasEstaoPreenchidas || senhaEstaInvalida}
      />
    </FormProvider>
  );
};
