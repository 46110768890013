import React, { useCallback, useEffect, useState, forwardRef } from 'react';
import { NamedProps } from 'react-select';
import AsyncReactSelect from 'react-select/async';

import { useTheme } from '@chakra-ui/react';

import OptionType from 'types/optionType';
import { isNullOrWhitespace } from 'helpers/validation/isNullOrWhitespace';
import { useFullScreenContext } from 'store/FullScreen';

import {
  ChakraReactSelectContainer,
  chakraComponents,
  chakraStyles,
} from '../../ReactSelectIntegracao';

let inputTypingTimeout: NodeJS.Timeout;

export type AsyncSelectInputProps = Omit<NamedProps<OptionType>, 'ref'> & {
  handleGetOptions: (
    inputValue: string,
    forceEnter?: boolean
  ) => Promise<OptionType[] | undefined>;
  isDisabled?: boolean;
  withoutDefaultOptions?: boolean;
  variant?: string;
  size?: string;
  closeMenuOnSelect?: boolean;
  shouldAppearTheAddress?: boolean;
};

const AsyncSelectInput = forwardRef<
  AsyncReactSelect<OptionType>,
  AsyncSelectInputProps
>(
  (
    {
      handleGetOptions,
      id,
      isClearable = false,
      placeholder = '',
      noOptionsMessage = () => 'Não há valores disponíveis',
      loadingMessage = () => 'Carregando...',
      withoutDefaultOptions,
      variant,
      size,
      isDisabled,
      components,
      styles,
      onInputChange,
      closeMenuOnSelect = true,
      shouldAppearTheAddress,
      ...props
    },
    ref
  ) => {
    const { colors, radii } = useTheme();

    const { handleFullScreen } = useFullScreenContext();

    const placeholderColor = colors.gray[400];
    const [defaultOptions, setDefaultOptions] = useState<OptionType[]>([]);
    const [menuIsOpen, setMenuIsOpen] = useState<boolean | undefined>(
      undefined
    );

    const loadOptions = useCallback(
      async (
        inputValue: string,
        callback: (options: OptionType[]) => void,
        forceSeach?: boolean
      ) => {
        const promise = new Promise(() => {
          clearTimeout(inputTypingTimeout);

          inputTypingTimeout = global.setTimeout(
            async () => {
              if (!isNullOrWhitespace(inputValue) || forceSeach) {
                const options = await handleGetOptions(inputValue);

                if (options === undefined) {
                  setMenuIsOpen(false);
                } else {
                  setMenuIsOpen(undefined);
                }

                callback(options || []);
              } else {
                callback([]);
              }
            },
            forceSeach ? 0 : 500
          );
        });

        // eslint-disable-next-line consistent-return
        return promise;
      },
      [handleGetOptions]
    );

    useEffect(() => {
      if (!withoutDefaultOptions)
        loadOptions(
          '',
          (options) => {
            setDefaultOptions(options);
          },
          true
        );
    }, [loadOptions, withoutDefaultOptions]);

    return (
      <ChakraReactSelectContainer
        variant={variant}
        size={size}
        isDisabled={isDisabled}
      >
        <AsyncReactSelect
          ref={ref}
          isDisabled={isDisabled}
          placeholder={placeholder}
          isClearable={isClearable}
          closeMenuOnSelect={closeMenuOnSelect}
          noOptionsMessage={noOptionsMessage}
          loadingMessage={loadingMessage}
          className="react-select-container"
          classNamePrefix="react-select"
          inputId={id}
          id={`select-container-${id}`}
          loadOptions={loadOptions}
          defaultOptions={defaultOptions}
          menuPortalTarget={handleFullScreen.active ? null : document.body}
          onInputChange={(newValue: string, actionMeta: any) => {
            setMenuIsOpen(undefined);

            if (!newValue) {
              clearTimeout(inputTypingTimeout);
            }

            if (onInputChange) {
              onInputChange(newValue, actionMeta);
            }
          }}
          components={{ ...chakraComponents, ...components }}
          styles={{ ...chakraStyles(undefined), ...styles }}
          theme={(baseTheme) => ({
            ...baseTheme,
            borderRadius: radii.md,
            colors: {
              ...baseTheme.colors,
              neutral50: placeholderColor, // placeholder text color
              neutral40: placeholderColor, // noOptionsMessage color
            },
            spacing: {
              ...baseTheme.spacing,
            },
          })}
          menuPlacement="auto"
          {...props}
          menuIsOpen={menuIsOpen}
          shouldAppearTheAddress={shouldAppearTheAddress}
        />
      </ChakraReactSelectContainer>
    );
  }
);

export default AsyncSelectInput;
