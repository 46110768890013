import React, { useState, useEffect, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Prompt, useHistory } from 'react-router-dom';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteRotas from 'constants/rotas';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import isPrenvent from 'helpers/layout/isPrenvent';
import auth from 'modules/auth';

import ManterFoco from 'components/Geral/ManterFoco';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { FormFooter } from 'components/update/Form/FormFooter';

import Formulario from '..';
import { yupResolver, FormData } from '../validationForm';
import { ForwardRefProps } from '../types';

const Cadastrar = () => {
  const history = useHistory();
  const { nome: nomeUsuario } = auth.getUsuario();
  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues: {
      usuario: nomeUsuario,
      dataEmissao: new Date().toLocaleDateString('pt-BR'),
    },
  });
  const isMountedRef = useIsMountedRef();

  const formularioRef = useRef<ForwardRefProps>(null);

  const { dirtyFields } = formMethods.formState;
  const isDirty =
    dirtyFields &&
    dirtyFields.constructor === Object &&
    Object.keys(dirtyFields).length > 0;

  const [isPrenvented, setIsPrenvented] = useState(false);
  isPrenvent(isPrenvented);

  const [isLoading, setIsLoading] = useState(false);

  function historyPush(path: string) {
    setIsPrenvented(false);

    history.push(path);
  }

  function historyReload() {
    setIsPrenvented(false);

    history.replace('/');
    history.replace(ConstanteRotas.MOVIMENTACAO_ESTOQUE_CADASTRAR);
  }

  async function cadastrarMovimentacaoEstoque(data: FormData) {
    if (formularioRef.current) {
      if (formularioRef.current.produtos.length) {
        const { motivo, tipoOperacao, localEstoque } = data;

        const listaProdutoQuantidade = formularioRef.current.produtos.map(
          ({ produtoCorTamanhoId, quantidade }) => ({
            produtoCorTamanhoId,
            quantidade,
          })
        );

        const response = await api.post<void, ResponseApi>(
          ConstanteEnderecoWebservice.MOVIMENTACAO_ESTOQUE_CADASTRAR,
          {
            motivo,
            tipoOperacao,
            localEstoqueId: localEstoque.value,
            listaProdutoQuantidade,
          }
        );

        if (response) {
          if (response.avisos) {
            response.avisos.map((item: string) => toast.warning(item));
          }

          if (response.sucesso) {
            return true;
          }
        }
      } else {
        toast.warning(
          'Adicione ao menos um produto para cadastrar a movimentação.'
        );

        formularioRef.current.handleSetProdutosError();
      }
    }

    return false;
  }

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    setIsLoading(true);

    const success = await cadastrarMovimentacaoEstoque(data);

    if (success) {
      toast.success('O cadastro foi realizado com sucesso.');

      historyPush(ConstanteRotas.MOVIMENTACAO_ESTOQUE);
    }

    if (isMountedRef.current) setIsLoading(false);
  });

  const handleSubmitReset = formMethods.handleSubmit(async (data) => {
    setIsLoading(true);

    const success = await cadastrarMovimentacaoEstoque(data);

    if (success) {
      toast.success('O cadastro foi realizado com sucesso.');

      historyReload();

      setIsLoading(false);
    }

    if (isMountedRef.current) setIsLoading(false);
  });

  useEffect(() => {
    setIsPrenvented(isDirty);
  }, [isDirty]);

  return (
    <Box>
      <Prompt when={isPrenvented} message="" />
      <ManterFoco>
        {isLoading && <LoadingPadrao />}
        <FormProvider {...formMethods}>
          <Formulario ref={formularioRef} setIsPrenvented={setIsPrenvented} />
        </FormProvider>

        <FormFooter
          onSubmit={handleSubmit}
          onSubmitReset={handleSubmitReset}
          isDisabled={isLoading}
        />
      </ManterFoco>
    </Box>
  );
};

export default Cadastrar;
