import {
  Flex,
  Box,
  Button,
  Icon,
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Collapse,
} from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import {
  FiChevronDown,
  FiChevronLeft,
  FiChevronRight,
  FiChevronUp,
} from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import ConstanteRotas from 'constants/rotas';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { ContainerHeader } from 'pages/Integracoes/LojaAplicativos/Layout/ContainerHeader';

import { useConfiguracao } from './hooks';
import { DadosGerais } from './DadosGerais';
import { OpcoesFiscais } from './OpcoesFiscais';
import { OpcoesImpressao } from './OpcoesImpressao';
import { OrdenacaoMenu } from './OrdenacaoMenu';
import { usePainelFrenteCaixa } from '../hooks';

export const PainelAdministradorFrenteCaixaConfiguracao = () => {
  const { handleVoltarPainelAdm } = usePainelFrenteCaixa();
  const {
    isLargerThan900,
    isLoading,
    formMethods,
    selectedTab,
    mobileSelectedTab,
    handleSettingsUpdate,
    handleSelectedTab,
    handleMobileSelectedTab,
    permiteAlterarTabRota,
    handleSetPermiteAlterarTabRota,
    indexOrdenacaoMenu,
    indexAutoAtendimento,
  } = useConfiguracao();

  const history = useHistory();

  const tabs = [
    { label: 'Dados gerais', content: <DadosGerais /> },
    { label: 'Impressão', content: <OpcoesImpressao /> },
    { label: 'Fiscal', content: <OpcoesFiscais /> },
    {
      label: 'Ordenação do menu',
      content: (
        <OrdenacaoMenu
          permiteAlterarTabRota={permiteAlterarTabRota}
          handleSetPermiteAlterarTabRota={handleSetPermiteAlterarTabRota}
        />
      ),
    },
    { label: 'Tema Autoatendimento', content: null },
  ];

  const handleIrParaTemaAutoAtendimento = () => {
    history.push(ConstanteRotas.AUTO_ATENDIMENTO_TEMA);
  };

  return (
    <FormProvider {...formMethods}>
      <ContainerHeader bg="#FF005A" gap={2} color="white" alignItems="center">
        <Box cursor="pointer" mr="10px" onClick={handleVoltarPainelAdm}>
          <Icon fontSize="25px" as={FiChevronLeft} />
        </Box>
        <Text fontSize="16px" fontWeight="semibold">
          Configurações
        </Text>
      </ContainerHeader>
      <Box position="relative" p={6}>
        {isLoading && <LoadingPadrao />}
        {isLargerThan900 ? (
          <Tabs
            index={selectedTab}
            variant="clean"
            h="max"
            bg="white"
            borderRadius="base"
            boxShadow="0px 0px 6px #00000034"
            defaultIndex={selectedTab}
          >
            <Flex h="full">
              <TabList mt="16px" w="32%">
                <Box w="100%">
                  {tabs.map((tab, index) => {
                    const isSelectedTab = selectedTab === index;
                    return (
                      <Tab
                        key={tab.label}
                        w="full"
                        h="64px"
                        borderRadius="none"
                        _selected={{
                          background: 'gray.100',
                        }}
                        onClick={() => {
                          if (index === indexAutoAtendimento) {
                            handleIrParaTemaAutoAtendimento();
                          } else {
                            handleSelectedTab(index);
                          }
                        }}
                      >
                        <Flex
                          w="full"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Text textAlign="left">{tab.label}</Text>
                          {isSelectedTab && <Icon as={FiChevronRight} />}
                        </Flex>
                      </Tab>
                    );
                  })}
                </Box>
              </TabList>
              <TabPanels
                w="full"
                px={10}
                py={6}
                bg="gray.100"
                borderRadius="0 5px 0 0"
              >
                {tabs.map((tab) => (
                  <TabPanel key={tab.label} w="full" bg="gray.100" p={0}>
                    {tab.content}
                  </TabPanel>
                ))}
              </TabPanels>
            </Flex>
          </Tabs>
        ) : (
          <Box
            py={4}
            bg="white"
            borderRadius="base"
            boxShadow="0px 0px 6px #00000034"
          >
            {tabs.map((tab, index) => {
              const isSelectedTab = mobileSelectedTab === index;
              return (
                <Box key={tab.label} pb={isSelectedTab ? 5 : 0} bg="gray.50">
                  <Flex
                    w="full"
                    py={6}
                    px={4}
                    bg={isSelectedTab ? 'gray.50' : 'white'}
                    cursor="pointer"
                    alignItems="center"
                    justifyContent="space-between"
                    onClick={() => {
                      if (index === indexAutoAtendimento) {
                        handleIrParaTemaAutoAtendimento();
                      } else {
                        handleMobileSelectedTab(index);
                      }
                    }}
                  >
                    <Text fontSize="16px">{tab.label}</Text>
                    <Icon
                      fontSize="20px"
                      as={isSelectedTab ? FiChevronUp : FiChevronDown}
                    />
                  </Flex>
                  <Collapse in={isSelectedTab} animateOpacity>
                    <Box overflowX="auto" p={4}>
                      {tab.content}
                    </Box>
                  </Collapse>
                </Box>
              );
            })}
          </Box>
        )}
        {selectedTab !== indexOrdenacaoMenu &&
          mobileSelectedTab !== indexOrdenacaoMenu && (
            <Flex py="40px" justifyContent="center" alignItems="center">
              <Button
                variant="solid"
                width={{ base: 'max', md: '120px' }}
                color="black"
                colorScheme="secondary.400"
                borderRadius="full"
                onClick={handleSettingsUpdate}
              >
                Salvar
              </Button>
            </Flex>
          )}
      </Box>
    </FormProvider>
  );
};
