import React, { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Col, Container, Button } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import { moneyMask } from 'helpers/format/fieldsMasks';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';
import { useNotaFiscalFormularioContext } from 'store/NotaFiscal/NotaFiscalFormulario';

import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import TableCampoFormularioPadrao from 'components/Table/TableCampoFormularioPadrao';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';

import ProdutoModal from './ProdutosModal';

interface UncontrolledFormInterface {
  readonly?: boolean;
  isVisualizar?: boolean;
}

export const Produtos = forwardRef(
  ({ readonly, isVisualizar }: UncontrolledFormInterface) => {
    const { t } = useTranslation();
    const [showModalAdicionarProduto, setShowModalAdicionarProduto] = useState(
      false
    );

    const [
      indexProdutoSelecionado,
      setIndexProdutoSelecionado,
    ] = useState<number>(-1);

    const {
      getValues: parentGetValues,
      setValue: parentSetValue,
      control: parentControl,
      watch,
    } = useFormContext();

    const { casasDecimais } = usePadronizacaoContext();

    // variável é estritamente criada para enviar via prop as informações da operação fiscal selecionada
    const operacaoFiscalSelecionada = watch('operacaoFiscalSelecionada');

    const { calcularTotais } = useNotaFiscalFormularioContext();

    const removerItemList = (index: number) => {
      ModalConfirmacaoExcluir({
        callback: async (ok: boolean) => {
          if (ok) {
            const items = parentGetValues('documentoFiscalItens') as Array<any>;
            const documentoFiscalItemRemover = items[index];

            items.splice(index, 1);

            if (documentoFiscalItemRemover?.regraFiscal?.obj?.listaBaseLegal) {
              documentoFiscalItemRemover.regraFiscal.obj.listaBaseLegal.forEach(
                (baseLegalRemover: any) => {
                  const existeEmOutroProdutoAdicionado = items.some(
                    (documentoFiscalItemAdicionado) => {
                      return documentoFiscalItemAdicionado?.regraFiscal?.obj?.listaBaseLegal.some(
                        (baseLegalAdicionada: any) =>
                          baseLegalAdicionada.id === baseLegalRemover.id
                      );
                    }
                  );

                  if (!existeEmOutroProdutoAdicionado) {
                    const basesLegaisParent = parentGetValues(
                      'basesLegais'
                    )?.filter(
                      (baseLegal: any) => baseLegal?.id !== baseLegalRemover.id
                    );

                    parentSetValue('basesLegais', [...basesLegaisParent]);
                  }
                }
              );
            }

            for (let indexItem = 0; indexItem < items.length; indexItem += 1) {
              items[indexItem].numeroItem = indexItem + 1;
            }

            parentSetValue('documentoFiscalItens', [...items]);
            calcularTotais();
          }
        },
        text: '',
      });
    };

    const showEditar = (index: number) => {
      setIndexProdutoSelecionado(index);
      setShowModalAdicionarProduto(true);
    };

    const showAdicionar = () => {
      setIndexProdutoSelecionado(-1);
      setShowModalAdicionarProduto(true);
    };

    return (
      <>
        <Container fluid>
          <Form.Row>
            <Col xl={12}>
              {!isVisualizar && (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    variant="link"
                    style={{ fontSize: '14px' }}
                    onClick={showAdicionar}
                  >
                    Adicionar produtos
                  </Button>
                </div>
              )}
              <TableCampoFormularioPadrao
                fieldName="documentoFiscalItens"
                defaultColumnOrder=""
                headerColumns={[
                  {
                    name: 'codigo',
                    displayName: 'Código',
                    isOrderable: false,
                    width: '',
                  },
                  {
                    name: 'descricao',
                    displayName: 'Descrição',
                    isOrderable: false,
                    width: '',
                  },
                  {
                    name: 'cst',
                    displayName: 'CST',
                    isOrderable: false,
                    width: '',
                  },
                  {
                    name: 'cfop',
                    displayName: 'CFOP',
                    isOrderable: false,
                    width: '',
                  },
                  {
                    name: 'quantidade',
                    displayName: 'Quantidade',
                    isOrderable: false,
                    width: '',
                  },
                  {
                    name: 'vlrUnitario',
                    displayName: 'Vlr. unitário',
                    isOrderable: false,
                    width: '',
                  },
                  {
                    name: 'total',
                    displayName: 'Total',
                    isOrderable: false,
                    width: '',
                  },
                  {
                    name: 'acoes',
                    displayName: isVisualizar ? '' : 'Ações',
                    isOrderable: false,
                    width: isVisualizar ? '0px' : '38px',
                  },
                ]}
                emptyValuesText="Nenhum produto adicionado para esta nota"
                control={parentControl}
                isLoading={false}
              >
                {({ tableValues }) => (
                  <>
                    {tableValues &&
                      tableValues.map((tableValue, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <tr key={index}>
                          <td>{tableValue.numeroItem}</td>
                          <td>{tableValue.produto?.label}</td>
                          <td>{tableValue.cstCsosn}</td>
                          <td>{tableValue.cfop}</td>
                          <td>{tableValue.quantidade}</td>
                          <td>{moneyMask(tableValue.valorUnitario, false)}</td>
                          <td>{moneyMask(tableValue.valorTotal, false)}</td>
                          {!isVisualizar && (
                            <td>
                              <ActionsMenu
                                id="mostrarMais"
                                items={[
                                  {
                                    content: t('Editar'),
                                    onClick: () => showEditar(index),
                                    isDisabled: readonly,
                                  },
                                  {
                                    content: t('Remover'),
                                    onClick: () => removerItemList(index),
                                    isDisabled: readonly,
                                  },
                                ]}
                              />
                            </td>
                          )}
                        </tr>
                      ))}
                  </>
                )}
              </TableCampoFormularioPadrao>
            </Col>
          </Form.Row>
        </Container>

        {showModalAdicionarProduto && (
          <ProdutoModal
            indexProduto={indexProdutoSelecionado}
            show={showModalAdicionarProduto}
            onHide={(deveReiniciar = false) => {
              setShowModalAdicionarProduto(false);

              if (deveReiniciar) {
                setTimeout(() => {
                  setShowModalAdicionarProduto(true);
                }, 50);
              }
            }}
            operacaoFiscalSelecionada={operacaoFiscalSelecionada}
            casasDecimais={casasDecimais}
          />
        )}
      </>
    );
  }
);
