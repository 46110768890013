import { useState } from 'react';
import {
  Checkbox,
  Flex,
  Td,
  Tr,
  Text,
  Box,
  Image,
  Icon,
} from '@chakra-ui/react';

import { DecimalMask } from 'helpers/format/fieldsMasks';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';

import { CorReferenciaIcon, EditarIcon } from 'icons';

import EditarVariacaoDrawer from '../EditarVariacaoDrawer';

type ProdutoCorTamanho = {
  produtoCorTamanhoId: string;
  produto: string;
  cor: string;
  tamanho: string;
  imagem: string;
};

type Identificadores = {
  codigoGTINEAN: string;
  sku: string;
  sequenciaCodigoBarras: number;
  sequenciaCodigoBarrasHexaDecimal: string;
  codigoBarrasInterno: string;
  codigoExterno: string;
};

export type VariacoesResponseProps = {
  produtoCorTamanho: ProdutoCorTamanho;
  identificadores: Identificadores;
  estoque: number;
  ativo: boolean;
  padraoSistema: boolean;
  isChecked: boolean;
};

type VariacaoItemProps = {
  variacao: VariacoesResponseProps;
  handleToggleSelecionarVariacoes: (produtoCorTamanho: string) => void;
  todasVariacoesSelecionadas: boolean;
  atualizarVariacoes: () => void;
};

export const VariacaoItem = ({
  variacao,
  handleToggleSelecionarVariacoes,
  todasVariacoesSelecionadas,
  atualizarVariacoes,
}: VariacaoItemProps) => {
  const [onMouseOver, setOnMouseOver] = useState(false);
  const [editarVariacaoDrawerIsOpen, setEditarVariacaoDrawerIsOpen] = useState(
    false
  );

  const { casasDecimais } = usePadronizacaoContext();

  const exibirCheckbox =
    onMouseOver || todasVariacoesSelecionadas || variacao.isChecked;

  const labelCor = variacao.produtoCorTamanho?.cor
    ? `${variacao.produtoCorTamanho?.cor} ${
        variacao.produtoCorTamanho?.tamanho ? '|' : ''
      }`
    : '';

  return (
    <>
      <Tr
        onMouseOverCapture={() => setOnMouseOver(true)}
        onMouseLeave={() => setOnMouseOver(false)}
        sx={{
          boxShadow: '0px 0px 4px #00000029',
          borderRadius: '6px',
          '& > td': {
            height: '64px',
            bg: !variacao.ativo
              ? 'gray.50'
              : variacao.isChecked
              ? 'purple.50'
              : 'white',
          },
        }}
      >
        <Td w="400px" fontWeight="bold">
          <Flex alignItems="center" fontWeight="semibold" color="black">
            <Box w="32px">
              {exibirCheckbox ? (
                <Checkbox
                  mb="0px !important"
                  mr="5px"
                  mt="2px"
                  onChange={() =>
                    handleToggleSelecionarVariacoes(
                      variacao.produtoCorTamanho.produtoCorTamanhoId
                    )
                  }
                  isChecked={variacao.isChecked}
                  boxShadow="inset 0px 3px 6px #00000029"
                  colorScheme="primary"
                />
              ) : (
                <Flex
                  boxSize="32px"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="5px"
                  mr="12px"
                  borderWidth="1px"
                  boxShadow="0px 0px 4px #00000029"
                  borderColor="gray.200"
                >
                  {variacao.produtoCorTamanho.imagem ? (
                    <Image
                      alt="imagem da cores"
                      fontSize="10px"
                      boxSize="32px"
                      objectFit="cover"
                      whiteSpace="nowrap"
                      src={variacao.produtoCorTamanho.imagem}
                    />
                  ) : (
                    <Icon
                      as={CorReferenciaIcon}
                      boxSize="20px"
                      color="gray.400"
                    />
                  )}
                </Flex>
              )}
            </Box>
            <Text
              ml="10px"
              whiteSpace="nowrap"
              alignItems="center"
              display="flex"
            >
              <Text color="teal.600" mr="5px">
                {labelCor}
              </Text>
              <Text color="pink.700">
                {variacao.produtoCorTamanho?.tamanho}
              </Text>
              {variacao.ativo === false && (
                <Flex
                  ml="5px"
                  borderRadius="16px"
                  color="white"
                  bg="red.500"
                  w="60px"
                  h="16px"
                  justifyContent="center"
                  alignItems="center"
                >
                  Inativo
                </Flex>
              )}
            </Text>
          </Flex>
        </Td>
        <Td>
          <Text fontSize="14px">{variacao.identificadores.sku}</Text>
        </Td>
        <Td>{variacao.identificadores.codigoBarrasInterno}</Td>
        <Td isNumeric>{variacao.identificadores.codigoGTINEAN || '-'}</Td>
        <Td isNumeric>
          {DecimalMask(
            variacao.estoque,
            casasDecimais.casasDecimaisQuantidade,
            casasDecimais.casasDecimaisQuantidade
          )}
        </Td>
        <Td
          cursor="pointer"
          isNumeric
          onClick={() => setEditarVariacaoDrawerIsOpen(true)}
        >
          <Flex
            p="6px"
            align="center"
            justify="center"
            borderRadius="4px"
            bgColor={onMouseOver ? 'gray.100' : 'transparent'}
            color={onMouseOver ? 'black' : 'gray.700'}
          >
            <Icon as={EditarIcon} fontSize="16px" />
          </Flex>
        </Td>
      </Tr>
      <Box h="5px" />
      <EditarVariacaoDrawer
        atualizarVariacoes={atualizarVariacoes}
        show={editarVariacaoDrawerIsOpen}
        onHide={() => setEditarVariacaoDrawerIsOpen(false)}
        variacao={variacao}
      />
    </>
  );
};
