import styled from 'styled-components';
import { Button, Form } from 'react-bootstrap';
import { InfoIcon as ImportedInfoIcon } from 'icons';

export const Container = styled(Form.Group)`
  margin-bottom: 0px;
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
`;

export const Label = styled(Form.Label)`
  color: var(--black) !important;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.5;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;

  margin-bottom: 3px !important;
`;

export const MensagemValidacao = styled.span`
  position: absolute;

  color: var(--sti-ck-colors-red-400);
  text-align: left;
  font-size: 0.75rem;

  text-overflow: ellipsis;
  overflow: hidden;
  /* white-space: nowrap; */

  max-width: calc(100% - 24px);

  @media (min-width: 900px) {
    max-width: calc(100% - 30px);
  }
  @media (min-width: 1200px) {
    max-width: calc(100% - 34px);
  }
  @media (min-width: 1800px) {
    max-width: calc(100% - 44px);
  }
`;

export const InfoIcon = styled(ImportedInfoIcon)`
  color: var(--black);
  font-size: 0.9375rem;

  margin-left: 4px;
  margin-bottom: 7px;
`;

export const LinkAction = styled(Button).attrs(() => ({
  variant: 'link',
}))`
  font-size: 0.6875rem;
  color: var(--sti-ck-colors-blue-500) !important;
  text-decoration: none !important;

  float: right;
  padding: 0px;
`;
