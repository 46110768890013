import React, { useEffect, useMemo } from 'react';
import { Grid, GridItem, ResponsiveValue } from '@chakra-ui/react';

import useWindowSize from 'helpers/layout/useWindowSize';
import Header from './Header';
import NavHeader from './NavHeader';
import Footer from './Footer';

// default css 'overflow' propertie value is 'visible'
type Overflow = 'auto' | 'hidden' | 'scroll' | 'visible';

interface LayoutProps {
  children: React.ReactNode;
  NavHeaderContent?: React.ReactNode;
  FooterContent?: React.ReactNode;
  containerOverflow?: ResponsiveValue<Overflow>;
  bodyOverflow?: ResponsiveValue<Overflow>;
  isHeaderVisible?: boolean;
  background?: string;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  NavHeaderContent,
  FooterContent,
  containerOverflow = 'hidden',
  bodyOverflow = 'auto',
  background,
  isHeaderVisible = true,
}) => {
  const { height: windowHeight } = useWindowSize();

  const contentRowSpan = useMemo(() => {
    let rowSpan = 4;

    if (isHeaderVisible) {
      rowSpan -= 1;
    }

    if (FooterContent) {
      rowSpan -= 1;
    }

    if (NavHeaderContent) {
      rowSpan -= 1;
    }

    return rowSpan;
  }, [FooterContent, NavHeaderContent, isHeaderVisible]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--vh-pdv',
      `${windowHeight * 0.01}px`
    );
  }, [windowHeight]);

  return (
    <Grid
      templateRows={{ base: '50px auto 1fr auto', md: '40px auto 1fr auto' }}
      overflow={containerOverflow}
      bg="gray.50"
      h="calc(var(--vh-pdv, 1vh) * 100)"
    >
      {isHeaderVisible && <Header />}
      {NavHeaderContent && <NavHeader>{NavHeaderContent}</NavHeader>}
      <GridItem
        rowSpan={contentRowSpan}
        maxH="100%"
        bg={background}
        maxW="100vw"
        overflow={bodyOverflow}
      >
        {children}
      </GridItem>
      {FooterContent && <Footer>{FooterContent}</Footer>}
    </Grid>
  );
};

export default Layout;
