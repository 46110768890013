import { useContextConciliacaoContas } from 'store/Financeiro/ConciliacaoContas';

import { DefaultActions } from './components/DefaultActions';
import { SelectedItemsActions } from './components/SelectedItemsActions';

export const ActionsHeader = () => {
  const { isSelectedContasEmpty } = useContextConciliacaoContas();
  return (
    <>{isSelectedContasEmpty ? <DefaultActions /> : <SelectedItemsActions />}</>
  );
};
