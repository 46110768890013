import { Route } from 'react-router-dom';

import {
  SmartPosProvider,
  SmartPosContext,
} from 'store/SmartPos/SmartPosContext';
import ConstanteRotas from 'constants/rotas';

import { ListarSmartPOS } from 'pages/Vendas/SmartPos/Listar';
import { DetalhesIntegracaoSmartPos } from 'pages/Integracoes/LojaAplicativos/SmartPos/DetalhesIntegracaoSmartPos';

import LayoutGuard from './LayoutGuard';

export const SmartPosListarRoutes = [
  <Route path={ConstanteRotas.SMART_POS_LISTAGEM} key="smart-pos-listar">
    <SmartPosProvider>
      <SmartPosContext.Consumer>
        {({ activeStep }) => (
          <LayoutGuard
            key="smart-pos-listar"
            component={ListarSmartPOS}
            breadcrumb={[
              {
                title: 'Vendas',
              },
              {
                title: 'Smart POS',
              },
              {
                title: activeStep?.name,
              },
            ]}
            path={ConstanteRotas.SMART_POS_LISTAGEM}
            exact
            meta={{ auth: true }}
          />
        )}
      </SmartPosContext.Consumer>
    </SmartPosProvider>
  </Route>,
  <Route
    path={ConstanteRotas.SMART_POS_DETALHES_INTEGRACAO}
    key="smart-pos-listar"
  >
    <LayoutGuard
      key="smart-pos-detalhes"
      component={DetalhesIntegracaoSmartPos}
      breadcrumb={[
        {
          title: 'Integrações',
          path: ConstanteRotas.LOJA_APLICATIVOS,
        },
        {
          title: 'Smart POS',
        },
      ]}
      path={ConstanteRotas.SMART_POS_DETALHES_INTEGRACAO}
      exact
      meta={{ auth: true }}
    />
  </Route>,
];
