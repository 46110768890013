import React from 'react';
import { IconType as ReactIconsIconType } from 'react-icons/lib';
import { Box, TypographyProps, Flex, Text } from '@chakra-ui/react';

import { IconType } from 'icons';

interface SeparatorInterface {
  title?: string;
}

interface SeparatorChakraInterface extends SeparatorInterface {
  fontWeight?: TypographyProps['fontWeight'];
}

interface TitleNavBar {
  icon: IconType | ReactIconsIconType;
  title: string;
}

export const SeparatorChakra = ({ title }: SeparatorInterface) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="flex-start"
      padding="0 25px 0 15px"
      margin="7px 0"
      userSelect="none"
      cursor="default"
      _first={{ marginTop: '15px' }}
      _last={{ marginBottom: '15px' }}
    >
      {title && (
        <Text
          paddingRight="7px"
          fontSize="0.75rem"
          fontWeight="700"
          whiteSpace="nowrap"
          color="gray.500"
        >
          {title}
        </Text>
      )}
      <Box bg="gray.200" height="1px" width="100%" />
    </Flex>
  );
};

export const Separator = ({ title, fontWeight }: SeparatorChakraInterface) => {
  return (
    <Box
      display="flex"
      alignItems="flex-end"
      justifyContent="flex-start"
      pr={['0', '0', '24px']}
      margin="7px 0"
      userSelect="none"
      cursor="default"
      mt={15}
      lineHeight="10px"
      mb={15}
      _first={{ marginTop: '15px' }}
      _last={{ marginBottom: '15px' }}
    >
      {title && (
        <Text
          pr={4}
          fontSize="14px"
          fontWeight={fontWeight || '700'}
          whiteSpace="nowrap"
          color="black"
        >
          {title}
        </Text>
      )}
      <Box bg="gray.200" height="1px" width="100%" />
    </Box>
  );
};

export const TitleOptionsNav = ({ icon: Icon, title }: TitleNavBar) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="flex-start"
      padding="0 25px 0 15px"
      margin="7px 0"
      userSelect="none"
      cursor="default"
      _first={{ marginTop: '15px' }}
      _last={{ marginBottom: '15px' }}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        color="gray.500"
        fontSize="1.25rem"
        paddingRight="10px"
      >
        {Icon && <Icon />}
      </Flex>
      <Text
        paddingRight="7px"
        fontSize="0.75rem"
        fontWeight="700"
        whiteSpace="normal"
        color="gray.500"
      >
        {title}
      </Text>
    </Flex>
  );
};

export default Separator;
