import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

const schema = yup.object().shape({
  nome: yup
    .string()
    .trim()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .max(40, 'O campo do nome não pode conter mais que 40 caracteres.'),
  agencia: yup
    .string()
    .trim()
    .nullable()
    .max(10, 'O campo agência não pode conter mais que 10 caracteres.')
    .when('tipoContaFinanceira', (tipoContaFinanceira: number) => {
      if (tipoContaFinanceira === 2) {
        return yup
          .string()
          .nullable()
          .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO);
      }
      return yup.string().nullable().notRequired();
    }),
  numeroConta: yup
    .string()
    .trim()
    .max(40, 'O campo número da conta não pode conter mais que 40 caracteres.')
    .when('tipoContaFinanceira', (tipoContaFinanceira: number) => {
      if (tipoContaFinanceira === 2) {
        return yup
          .string()
          .nullable()
          .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO);
      }
      return yup.string().nullable().notRequired();
    }),
  codigoBanco: yup
    .string()
    .nullable()
    .when('tipoContaFinanceira', (tipoContaFinanceira: number) => {
      if (tipoContaFinanceira === 2) {
        return yup
          .string()
          .nullable()
          .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO);
      }
      return yup.string().nullable().notRequired();
    }),
  ativo: yup.boolean().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);

export const useForm = useFormInstance;
