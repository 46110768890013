import { useCallback, useEffect, useState } from 'react';
import { Box, Button, Flex } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import {
  cadastrarLocalEstoqueTray,
  getLocalEstoqueTray,
  getLocalEstoque as getLocalEstoqueResponse,
} from 'helpers/data/getLocalEstoque';
import { useTrayPainelControleContext } from 'store/Tray/PainelControle';

import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import Loading from 'components/Layout/Loading/LoadingPadrao';

type Options = {
  label: string;
  value: string;
};

export type OptionResponseProps = {
  id: string;
  nome: string;
};

export const LocalEstoque = () => {
  const { isLoading, setIsLoading } = useTrayPainelControleContext();
  const [localEstoque, setLocalEstoque] = useState<Options[]>([]);

  const formMethods = useForm();

  const { reset, handleSubmit: onSubmit } = formMethods;

  const getOptionsLocalEstoque = useCallback(async () => {
    const response = await getLocalEstoqueResponse();

    if (response.length > 0) {
      setLocalEstoque(response);
    }
  }, []);

  const getLocalEstoque = useCallback(async () => {
    setIsLoading(true);
    await getOptionsLocalEstoque();

    const response = await getLocalEstoqueTray();

    if (response !== null) {
      reset({ localEstoqueId: response });
    }

    setIsLoading(false);
  }, [getOptionsLocalEstoque, reset, setIsLoading]);

  const handleSubmit = onSubmit(async (data) => {
    setIsLoading(true);
    const response = await cadastrarLocalEstoqueTray(data.localEstoqueId || '');

    if (response !== null) {
      toast.success('O local de estoque foi alterado com sucesso');
    }
    setIsLoading(false);
  });

  useEffect(() => {
    getLocalEstoque();
  }, [getLocalEstoque]);

  return (
    <FormProvider {...formMethods}>
      {isLoading && <Loading />}
      <Box
        pt={['24px', '24px', '40px']}
        px={['0', '0', '20px']}
        w="full"
        minW="200px"
      >
        <SelectPadrao
          name="localEstoqueId"
          id="localEstoqueId"
          label="Local estoque"
          isLoading={isLoading}
          options={localEstoque}
          placeholder="Local de estoque"
        />

        <Flex mt="40px" w="full" justifyContent="center" alignItems="baseline">
          <Button
            variant="outlineDefault"
            borderRadius="16px"
            colorScheme="gray"
            mr="24px"
            w="96px"
            isLoading={isLoading}
            onClick={getLocalEstoque}
          >
            Cancelar
          </Button>
          <Button
            color="black"
            variant="solid"
            colorScheme="secondary.400"
            borderRadius="16px"
            width="120px"
            isLoading={isLoading}
            onClick={handleSubmit}
          >
            Confirmar
          </Button>
        </Flex>
      </Box>
    </FormProvider>
  );
};
