import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Icon,
  Text,
  Box,
  Flex,
  Collapse,
} from '@chakra-ui/react';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { ContainerHeader } from 'pages/Integracoes/LojaAplicativos/Layout/ContainerHeader';

import {
  FiChevronDown,
  FiChevronLeft,
  FiChevronRight,
  FiChevronUp,
} from 'react-icons/fi';
import { useGerenciadorImpressao } from './hooks';
import Managers from './components/Managers';
import Departaments from './components/Departaments';
import ManagerConfig from './components/ManagerConfig';
import { usePainelFrenteCaixa } from '../hooks';

const PainelAdministradorFrenteCaixaGerenciadorImpressao = () => {
  const {
    isLargerThan900,
    isLoading,
    openModalUpdateManager,
    openModalNewPrintManagers,
    removePrintManager,
    getListPrintManager,
    printManagerList,
    hasPrintManager,
    listPrintersDepartments,
    openModalNewPrintDepartaments,
    openModalUpdateDepartaments,
    removePrintDepartment,
    getListPrintersDepartments,
    hasPrinterDepartament,
    selectedTab,
    mobileSelectedTab,
    handleMobileSelectedTab,
    handleSelectedTab,
  } = useGerenciadorImpressao();
  const { handleVoltarPainelAdm } = usePainelFrenteCaixa();

  const tabs = [
    {
      label: 'Departamentos',
      content: (
        <Departaments
          listPrintersDepartments={listPrintersDepartments}
          openModalNewPrintDepartaments={openModalNewPrintDepartaments}
          openModalUpdateDepartaments={openModalUpdateDepartaments}
          removePrintDepartment={removePrintDepartment}
          getListPrintersDepartments={getListPrintersDepartments}
          hasPrinterDepartament={hasPrinterDepartament}
        />
      ),
    },
    {
      label: 'Gerenciadores',
      content: (
        <Managers
          openModalUpdateManager={openModalUpdateManager}
          openModalNewPrintManagers={openModalNewPrintManagers}
          removePrintManager={removePrintManager}
          getListPrintManager={getListPrintManager}
          printManagerList={printManagerList}
          hasPrintManager={hasPrintManager}
        />
      ),
    },
    {
      label: 'Selecionar impressoras',
      content: <ManagerConfig printManagerList={printManagerList} />,
    },
  ];

  return (
    <>
      <ContainerHeader bg="#FF005A" gap={2} color="white" alignItems="center">
        <Box cursor="pointer" mr="10px" onClick={handleVoltarPainelAdm}>
          <Icon fontSize="25px" as={FiChevronLeft} />
        </Box>
        <Text fontSize="16px" fontWeight="semibold">
          Gerenciador de impressão
        </Text>
      </ContainerHeader>
      <Box position="relative" p={6}>
        {isLoading && <LoadingPadrao />}
        {isLargerThan900 ? (
          <Tabs
            variant="clean"
            h="max"
            bg="white"
            borderRadius="base"
            boxShadow="0px 0px 6px #00000034"
            defaultIndex={selectedTab}
            onChange={(index) => handleSelectedTab(index)}
          >
            <Flex h="full">
              <TabList mt="16px" w="32%">
                <Box w="100%">
                  {tabs.map((tab, index) => {
                    const isSelectedTab = selectedTab === index;
                    return (
                      <Tab
                        key={tab.label}
                        w="full"
                        h="64px"
                        borderRadius="none"
                        _selected={{
                          background: 'gray.100',
                        }}
                      >
                        <Flex
                          w="full"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Text w="max">{tab.label}</Text>
                          {isSelectedTab && <Icon as={FiChevronRight} />}
                        </Flex>
                      </Tab>
                    );
                  })}
                </Box>
              </TabList>
              <TabPanels
                w="full"
                px={10}
                py={6}
                bg="gray.100"
                borderRadius="0 5px 0 0"
                minH="400px"
              >
                {tabs.map((tab) => (
                  <TabPanel w="full" bg="gray.100" p={0}>
                    {tab.content}
                  </TabPanel>
                ))}
              </TabPanels>
            </Flex>
          </Tabs>
        ) : (
          <Box
            py={4}
            bg="white"
            borderRadius="base"
            boxShadow="0px 0px 6px #00000034"
          >
            {tabs.map((tab, index) => {
              const isSelectedTab = mobileSelectedTab === index;
              return (
                <Box pb={isSelectedTab ? 5 : 0} bg="gray.50">
                  <Flex
                    w="full"
                    py={6}
                    px={4}
                    bg={isSelectedTab ? 'gray.50' : 'white'}
                    cursor="pointer"
                    alignItems="center"
                    justifyContent="space-between"
                    onClick={() => handleMobileSelectedTab(index)}
                  >
                    <Text fontSize="16px">{tab.label}</Text>
                    <Icon
                      fontSize="20px"
                      as={isSelectedTab ? FiChevronUp : FiChevronDown}
                    />
                  </Flex>
                  <Collapse in={isSelectedTab} animateOpacity>
                    <Box p={4}>{tab.content}</Box>
                  </Collapse>
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    </>
  );
};

export { PainelAdministradorFrenteCaixaGerenciadorImpressao };
