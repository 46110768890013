import { useState, useEffect, useMemo, memo } from 'react';
import { useTranslation } from 'react-i18next';

import ConstanteFuncionalidades from 'constants/permissoes';
import auth from 'modules/auth';
import PlanoContaInterface from 'types/planoConta';
import TipoPlanoContaEnum from 'constants/enum/tipoPlanoConta';
import { usePlanoContasGeralContext } from 'store/PlanoContas/PlanoContasGeral';

import ButtonComOpcoes from 'components/Button/ButtonComOpcoes';

import {
  Container,
  Card,
  SideContainer,
  ExpandirRecolherButton,
  ExpandirIcon,
  RecolherIcon,
  AddButton,
  AddIcon,
  NivelIcon,
  TagInativo,
  ItemText,
} from '../styles';

interface ItemProps {
  item: PlanoContaInterface;
  paiIsAtivo: boolean;
  nivel: number;
  children: React.ReactNode;
  tipoPlanoContaObrigatorio: number;
}

export const ItemVisaoCaixa = memo(
  ({
    item,
    paiIsAtivo,
    nivel,
    children,
    tipoPlanoContaObrigatorio,
  }: ItemProps) => {
    const {
      handleGetSubNiveisPlanoConta,
      handleOpenModal,
      handleDeletePlanoConta,
      handlePutPlanoConta,
      handleInativarPlanoConta,
      itemsExpandidos,
    } = usePlanoContasGeralContext();
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);
    const [firstRender, setFirstRender] = useState(false);

    const permissaoPlanoContaCadastrar = useMemo(
      () =>
        auth.possuiPermissao(ConstanteFuncionalidades.PLANO_CONTA_CADASTRAR),
      []
    );

    const haveChildrens = useMemo(
      () => nivel < 4 && handleGetSubNiveisPlanoConta(item.id) > 1,
      [handleGetSubNiveisPlanoConta, item.id, nivel]
    );

    const removerItemDropdownObjeto = useMemo(() => {
      if (!haveChildrens) {
        return [
          {
            title: t('Remover'),
            onClick: () => {
              handleDeletePlanoConta(item.id, item.ativo);
            },
            funcionalidadePermissao:
              ConstanteFuncionalidades.PLANO_CONTA_EXCLUIR,
          },
        ];
      }

      return [];
    }, [haveChildrens, t, handleDeletePlanoConta, item.id, item.ativo]);

    const ativarInativarDropdownObjeto = useMemo(() => {
      if (!item.ativo && paiIsAtivo) {
        return [
          {
            title: t('Ativar'),
            onClick: () => handlePutPlanoConta({ ...item, ativo: true }, true),
            funcionalidadePermissao:
              ConstanteFuncionalidades.PLANO_CONTA_ALTERAR,
          },
        ];
      }
      if (item.ativo) {
        return [
          {
            title: t('Inativar'),
            onClick: () => handleInativarPlanoConta(item.id, item.ativo),
            funcionalidadePermissao:
              ConstanteFuncionalidades.PLANO_CONTA_EXCLUIR,
          },
        ];
      }

      return [];
    }, [paiIsAtivo, handleInativarPlanoConta, handlePutPlanoConta, item, t]);

    useEffect(() => {
      if (firstRender) setFirstRender(true);
    }, [firstRender]);

    useEffect(() => {
      if (!firstRender) setIsOpen(itemsExpandidos);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsExpandidos]);

    const formatarNomeCreditoDebito = (
      nivelAtual: number,
      itemAtual: PlanoContaInterface
    ) => {
      if (nivelAtual > 1) {
        if (itemAtual.tipoPlanoConta === TipoPlanoContaEnum.CREDITO) {
          return `+ ${itemAtual.nome}`;
        }

        if (itemAtual.tipoPlanoConta === TipoPlanoContaEnum.DEBITO) {
          return `- ${itemAtual.nome}`;
        }
      }

      return itemAtual.nome;
    };

    return (
      <>
        <Container nivel={nivel} nivelCores={nivel + 1}>
          <NivelIcon />
          <Card
            style={{ cursor: 'default' }}
            nivel={nivel}
            nivelCores={nivel + 1}
            inativo={!item.ativo}
            id="planoConta-draggable"
          >
            <SideContainer>
              {haveChildrens && (
                <ExpandirRecolherButton
                  id={`expandir-planoConta-nivel${nivel}`}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  {isOpen ? <ExpandirIcon /> : <RecolherIcon />}
                </ExpandirRecolherButton>
              )}

              <ItemText id="planoConta-nome" data-item-name={item.nome}>
                {formatarNomeCreditoDebito(nivel, item)}
              </ItemText>
            </SideContainer>
            <SideContainer>
              {!item.ativo && <TagInativo>{t('Inativo')}</TagInativo>}
              {nivel < 4 &&
                nivel > 1 &&
                permissaoPlanoContaCadastrar.permitido &&
                item.tipoPlanoConta === TipoPlanoContaEnum.TOTALIZADOR &&
                item.ativo && (
                  <AddButton
                    id={`adicionar-planoConta-nivel${nivel + 1}`}
                    onClick={() => {
                      handleOpenModal(
                        nivel < 3,
                        undefined,
                        item.id,
                        tipoPlanoContaObrigatorio
                      );
                    }}
                  >
                    <AddIcon />
                  </AddButton>
                )}

              {nivel > 1 && (
                <ButtonComOpcoes
                  id="mostrarMais"
                  data-mostrarmais-name={item.nome}
                  dropdownItems={[
                    {
                      title: t('Editar'),
                      onClick: () => {
                        handleOpenModal(
                          nivel < 4,
                          item,
                          item.planoContaPaiId,
                          tipoPlanoContaObrigatorio
                        );
                      },
                      funcionalidadePermissao:
                        ConstanteFuncionalidades.PLANO_CONTA_ALTERAR,
                    },
                    ...removerItemDropdownObjeto,
                    ...ativarInativarDropdownObjeto,
                  ]}
                  iconHeight="18px"
                />
              )}
            </SideContainer>
          </Card>
        </Container>

        {isOpen && haveChildrens && children}
      </>
    );
  }
);
