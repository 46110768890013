import { useState, useCallback } from 'react';
import {
  ModalProps as ModalChakraProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  ModalFooter,
  ModalHeader,
  useMediaQuery,
  Divider,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { UncontrolledForm } from '../index';
import { FormData, defaultValues, yupResolver } from '../validationForm';

type ModalProps = {
  callback: (data: {
    impressoras: {
      impressoraId: string;
      imprimirAutomatico: boolean;
      quantidadeCopias: number;
      confirmarImpressao: boolean;
    }[];
    nome: string;
  }) => Promise<{ success: boolean }>;
};

type ModalNewPrintManagerProps = Omit<
  ModalChakraProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<void> &
  ModalProps;

export const ModalNewPrintManager = create<ModalNewPrintManagerProps>(
  ({ onResolve, onReject, callback, ...rest }) => {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
    const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

    const [isLoading, setIsLoading] = useState(false);

    const formMethods = useForm<FormData>({
      defaultValues,
      resolver: yupResolver,
    });

    const { handleSubmit, reset, setValue } = formMethods;

    const handleSave = useCallback(
      async (data: FormData) => {
        if (!data?.impressoras.length) {
          toast.warning('Adicione ao menos uma impressora');
          return;
        }

        setIsLoading(true);
        const { success } = await callback({
          impressoras: data.impressoras,
          nome: data.nome,
        });

        if (success) {
          onClose();
        }
        setIsLoading(false);
      },
      [callback, onClose]
    );

    const handleSaveAndReset = useCallback(
      async (data: FormData) => {
        if (!data?.impressoras.length) {
          toast.warning('Adicione ao menos uma impressora');
          return;
        }

        setIsLoading(true);
        const { success } = await callback({
          impressoras: data.impressoras,
          nome: data.nome,
        });

        if (success) {
          setValue('impressoras', []);
          reset();
        }
        setIsLoading(false);
      },
      [callback, reset, setValue]
    );

    return (
      <ModalPadraoChakra
        size={isLargerThan700 ? '4xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalContent bg="gray.50">
          <ModalHeader>
            <Text color="primary.50" fontSize="md">
              Gerenciador
            </Text>
            <Text color="gray.700" fontSize="xs">
              Informe o nome do gerenciador e as impressoras desejadas,
              configure suas opções de impressão e clique em &quot;Salvar&quot;
              para confirmar as alterações.
            </Text>
          </ModalHeader>
          <Divider
            orientation="horizontal"
            width="calc(100% - 48px)"
            margin="0 auto"
          />
          <ModalBody mt="12px">
            {isLoading && <LoadingPadrao />}
            <FormProvider {...formMethods}>
              <UncontrolledForm />
            </FormProvider>
          </ModalBody>
          <ModalFooter
            justifyContent="center"
            flexWrap="wrap"
            gap={6}
            py="24px"
          >
            <Button
              w={{ base: 'full', sm: 'min-content' }}
              colorScheme="gray"
              variant="outlineDefault"
              onClick={onClose}
            >
              Cancelar
            </Button>
            <Button
              w={{ base: 'full', sm: 'min-content' }}
              colorScheme="gray"
              variant="outlineDefault"
              onClick={handleSubmit(handleSave)}
              isDisabled={isLoading}
            >
              Salvar
            </Button>
            <Button
              w={{ base: 'full', sm: 'min-content' }}
              minW="225px"
              colorScheme="secondary"
              onClick={handleSubmit(handleSaveAndReset)}
              isDisabled={isLoading}
            >
              Salvar e inserir novo
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
