import { FiChevronLeft } from 'react-icons/fi';
import { Box, Button, Text, Icon, Flex, useMediaQuery } from '@chakra-ui/react';
import { AiOutlineMenu } from 'react-icons/ai';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';

import { ContainerHeader } from '../../Layout/ContainerHeader';
import { usePdvAutonomo } from './hooks';

export const PdvAutonomo = () => {
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const {
    handleVoltarPainelAdm,
    activeStep,
    handleUpdateStep,
    isLoading,
    valueTabs,
  } = usePdvAutonomo();

  return (
    <>
      {isLoading && <LoadingPadrao />}
      <ContainerHeader justifyContent="space-between" h="56px">
        <Flex color="white" alignItems="center">
          <Box cursor="pointer" mr="10px" onClick={handleVoltarPainelAdm}>
            <Icon fontSize="25px" as={FiChevronLeft} />
          </Box>
          <Text fontSize="16px" ml={['0', '0', '10px']}>
            PDV Offline
          </Text>
        </Flex>
      </ContainerHeader>
      {!isLoading && (
        <Box mt="20px" px="24px">
          {isLargerThan900 ? (
            <Flex mb="31px">
              {valueTabs.map((itemPdvAutonomo) => {
                return (
                  <Box mr={['', '', '2%', '5%']}>
                    <Button
                      h="32px"
                      fontWeight={
                        activeStep.value === itemPdvAutonomo.value
                          ? 'bold'
                          : 'normal'
                      }
                      letterSpacing={
                        activeStep.value !== itemPdvAutonomo.value
                          ? '0.290px'
                          : '0'
                      }
                      isDisabled={itemPdvAutonomo.isDisabled}
                      borderRadius="16px"
                      variant="linkDefault"
                      onClick={() => handleUpdateStep(itemPdvAutonomo)}
                      colorScheme="purple"
                    >
                      {itemPdvAutonomo.label}
                    </Button>
                  </Box>
                );
              })}
            </Flex>
          ) : (
            <Flex mb="31px">
              <ActionsMenu
                iconItem={AiOutlineMenu}
                sizeIcon="30px"
                items={valueTabs.map((itemPdvAutonomo) => ({
                  content: itemPdvAutonomo.label,
                  onClick: () => handleUpdateStep(itemPdvAutonomo),
                }))}
              />
            </Flex>
          )}
          {activeStep.content}
        </Box>
      )}
    </>
  );
};
