import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

type Option = {
  label: string;
  value: string;
};
export type FormData = {
  departamentoId: string | null;
  impressoraId: string | null;
  caminho: string;
  quantidadeLinhasFim: number;
  corteGuilhotina: number;
  tipoImpressao: number;
  tipoCupom: number;
};

const defaultValues = {
  departamentoId: null,
  impressoraId: null,
  caminho: '',
  quantidadeLinhasFim: 0,
  corteGuilhotina: 1,
  tipoImpressao: 2,
  tipoCupom: 1,
};

const schema = yup.object({
  departamentoId: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  impressoraId: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  caminho: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .uppercase(),
  quantidadeLinhasFim: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  corteGuilhotina: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  tipoImpressao: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  tipoCupom: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

const yupResolver = yupResolverInstance(schema);

export { yupResolver, defaultValues };
