import { Box, Text, BoxProps } from '@chakra-ui/react';
import { forwardRef } from 'react';

import { corCategoriaProdutoEnum } from 'constants/enum/nivelCategoriaProduto';

type CardProps = BoxProps & {
  isDragging?: boolean;
  nivel: number;
  height?: number;
  inativo?: boolean;
  isTyping?: boolean;
  children: React.ReactNode;
};

export const Card = forwardRef<HTMLDivElement, CardProps>(
  (
    { height = 30, nivel, isDragging, inativo, isTyping, children, ...rest },
    ref
  ) => {
    const bgColor = isDragging
      ? 'transparent'
      : corCategoriaProdutoEnum.properties[nivel - 1]?.backgroundColor;
    const textColor =
      corCategoriaProdutoEnum.properties[nivel - 1]?.foregroundColor;

    const inativoStyles = inativo
      ? {
          backgroundColor: isDragging ? 'transparent' : 'white',
          color: 'red.400',
        }
      : {};

    const typingStyles = isTyping
      ? {
          border: '2px solid blue.500',
        }
      : {};

    return (
      <Box
        display="flex"
        id="CATEGORIAPRODUTO"
        alignItems="center"
        justifyContent="space-between"
        cursor="grab"
        boxShadow="0px 1px 1px rgba(0, 0, 0, 0.25)"
        height={`${height}px`}
        width="100%"
        borderRadius="5px"
        ref={ref}
        padding="5px 15px"
        fontSize="0.8125rem"
        marginLeft="10px"
        backgroundColor={bgColor}
        color={textColor}
        borderColor={isDragging ? textColor : 'none'}
        border={isDragging ? `2px dashed` : 'none'}
        {...inativoStyles}
        {...typingStyles}
        {...rest}
      >
        <Text w="full">{children}</Text>
      </Box>
    );
  }
);
