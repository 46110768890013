const Drawer = {
  variants: {
    clickThrough: {
      overlay: {
        pointerEvents: 'none',
      },
      dialogContainer: {
        pointerEvents: 'none',
        background: 'transparent',
      },
      dialog: {
        pointerEvents: 'auto',
      },
    },
  },
};

export default Drawer;
