/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ChangeEvent } from 'react';

type Dict = {
  [key: string]: any;
};

type UseFileUploadProps = {
  accept: HTMLInputElement['accept'];
  onSelectFile: (anyProps: Dict, file?: File) => void | Promise<void>;
};

export function useFileUpload({ accept, onSelectFile }: UseFileUploadProps) {
  const onChange = (e: ChangeEvent<HTMLInputElement>, anyProps: Dict) => {
    const { files } = e.target;
    if (!files || files.length === 0) {
      return;
    }

    const newFile = files[0];

    if (newFile) {
      onSelectFile(anyProps, newFile);
    }
  };

  function handleSelectFile(anyProps: Dict) {
    const fileInput = document.createElement('input');

    fileInput.type = 'file';
    fileInput.style.display = 'none';

    fileInput.accept = accept;

    // @ts-ignore
    fileInput.addEventListener('change', (e) => onChange(e, anyProps));
    fileInput.click();

    // @ts-ignore
    fileInput.removeEventListener('change', (e) => onChange(e, anyProps));
    fileInput.remove();
  }

  return { handleSelectFile };
}
