import React from 'react';
import {
  Text,
  Icon,
  Avatar,
  Flex,
  Button,
  ButtonProps,
  Stack,
  useMediaQuery,
} from '@chakra-ui/react';

import { SelecionadoIcon } from 'icons';

type Vendedor = {
  foto: string;
  nome: string;
};

interface VendedorButtonProps extends ButtonProps {
  isSelected: boolean;
  vendedor: Vendedor;
}

const VendedorButton: React.FC<VendedorButtonProps> = ({
  isSelected,
  vendedor,
  ...rest
}) => {
  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');

  return (
    <Flex alignItems="center" justifyContent="center" w="100%" minW="1px">
      <Button
        position="relative"
        w={{ base: '100%', md: '150px' }}
        h={{ base: '55px', md: '150px' }}
        justifyContent={{ base: 'flex-start', md: 'center' }}
        borderRadius="lg"
        border="1px"
        borderColor="gray.100"
        boxShadow="md"
        bg={isSelected ? 'white' : 'gray.50'}
        _hover={{ bg: 'white' }}
        _active={{ bg: 'white' }}
        {...rest}
      >
        <Stack
          direction={{ base: 'row', md: 'column' }}
          spacing={4}
          alignItems="center"
          justifyContent={{ base: 'flex-start', md: 'center' }}
          h="100%"
          w="full"
        >
          <Avatar
            name={vendedor.foto ? vendedor.nome : undefined}
            src={vendedor.foto}
            size={isSmallerThan900 ? 'sm' : 'lg'}
          />
          <Text
            whiteSpace="normal"
            maxW={{
              base: isSelected ? 'calc(100% - 5rem)' : 'full',
              md: 'full',
            }}
            fontSize="xs"
            isTruncated={isSmallerThan900}
          >
            {vendedor.nome}
          </Text>
        </Stack>

        {isSelected && (
          <Icon
            as={SelecionadoIcon}
            position="absolute"
            top={{ base: '50%', md: 0 }}
            right={{ base: 4, md: 0 }}
            transform={{ base: 'translateY(-50%)', md: 'translate(50%, -50%)' }}
            w={5}
            h={5}
            color="primary.400"
          />
        )}
      </Button>
    </Flex>
  );
};

export default VendedorButton;
