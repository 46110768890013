import React, { ReactNode } from 'react';
import { Tooltip, TooltipProps, Box, Icon } from '@chakra-ui/react';

import { InfoIcon } from 'icons';

interface InfoTooltipProps extends Omit<TooltipProps, 'children'> {
  label: ReactNode;
}

export function InfoTooltip(props: InfoTooltipProps) {
  return (
    <Tooltip hasArrow textAlign="left" placement="right" {...props}>
      <Box tabIndex={0}>
        <Icon as={InfoIcon} w="15px" h="15px" color="black" />
      </Box>
    </Tooltip>
  );
}
