import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

import { LoginLogo } from 'icons/Identidade';

const Logo = (props: IconProps) => {
  return (
    <Icon
      color="secondary.300"
      h={{ base: 9, md: 8, xl: 9 }}
      w="full"
      mb={12}
      {...props}
      as={LoginLogo}
    />
  );
};

export default Logo;
