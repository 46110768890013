import React, { useCallback, useState, useRef, useEffect } from 'react';
import {
  Button,
  Box,
  Text,
  VStack,
  Tr,
  Td,
  Flex,
  useMediaQuery,
  Stack,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { useConferenciaEstoqueContainerContext } from 'store/ConferenciaEstoque/ConferenciaEstoqueContainer';
import { useConferenciaEstoqueEtapasContext } from 'store/ConferenciaEstoque/ConferenciaEstoqueEtapas';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';

import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import { ImprimirPDF } from 'helpers/impressoes/imprimirPDF';
import auth from 'modules/auth';
import ConstanteRotas from 'constants/rotas';
import ConstanteFuncionalidades from 'constants/permissoes';
import TipoConferenciaEnum from 'constants/enum/tipoConferencia';

import {
  LoadMoreRowsParams,
  VirtualizedInfiniteTable,
  TableHeader,
} from 'components/update/Table/VirtualizedInfiniteTable';
import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { Container, Body } from 'components/update/Steps/StepContent';
import { ModalCompartilhar } from 'components/Modal/ModalCompartilhar';
import { EmailModal } from 'components/PDV/CompartilharDocumentosFiscais/EmailModal';

import { ModalCorrigindoEstoque } from './ModalCorrigindoEstoque';

type ProdutoDivergenciaPaginadoRegistro = {
  codigoBarrasInterno: string;
  nome: string;
  cor?: string;
  tamanho?: string;
  quantidadeInformada: number;
  estoqueAtual: number;
  diferenca: number;
};

const produtosTableHeaders: TableHeader[] = [
  {
    key: 'codigoBarrasInterno',
    content: 'Código de barras',
    width: '8%',
    whiteSpace: 'nowrap',
  },
  { key: 'produto', content: 'Produtos', width: '30%' },
  {
    key: 'quantidadeConferencia',
    content: 'Quantidade conferência',
    isNumeric: true,
    width: '22%',
    whiteSpace: 'nowrap',
  },
  {
    key: 'quantidadeSistema',
    content: 'Quantidade sistema',
    isNumeric: true,
    width: '20%',
    whiteSpace: 'nowrap',
  },
  {
    key: 'diferenca',
    content: 'Diferença',
    isNumeric: true,
    width: '20%',
  },
];

export function RevisarDivergencias() {
  const history = useHistory();
  const { casasDecimais } = usePadronizacaoContext();

  const {
    conferenciaEstoqueId,
    descartarConferencia,
  } = useConferenciaEstoqueContainerContext();

  const {
    corrigirApenasItensConferidos,
    previousStep,
    nextStep,
    conferenciaEstoque,
  } = useConferenciaEstoqueEtapasContext();

  const [isLoading, setIsLoading] = useState(true);
  const [isCorrigindo, setIsCorrigindo] = useState(false);
  const [isSmallerThan1180] = useMediaQuery('(max-width: 1180px)');

  const possuiPermissaoAplicarCorrecoes = auth.possuiPermissao(
    ConstanteFuncionalidades.CONFERENCIA_ESTOQUE_APLICAR_CORRECAO
  ).permitido;

  const [produtosDivergencias, setProdutosDivergencias] = useState<
    ProdutoDivergenciaPaginadoRegistro[]
  >([]);
  const [existeDivergencia, setExisteDivergencia] = useState(true);
  const [itemsTotalCount, setItemsTotalCount] = useState(0);
  const [
    isProdutosDivergenciasLoading,
    setIsProdutosDivergenciasLoading,
  ] = useState(false);

  const [isLargerThan520] = useMediaQuery('(min-width: 520px)');

  function handleVoltar() {
    previousStep();
  }

  function handleDescartarConferencia() {
    descartarConferencia();
  }

  function handleSalvarSemCorrigir() {
    history.push(ConstanteRotas.CONFERENCIA_ESTOQUE);
  }

  const latestProps = useRef({ isLoading });
  useEffect(() => {
    latestProps.current = { isLoading };
  });

  const correcaoConferenciaEstoque = useCallback(async () => {
    setIsCorrigindo(true);

    const { userId: usuarioId } = auth.getDadosToken();

    const response = await api.put<void, ResponseApi<boolean>>(
      ConstanteEnderecoWebservice.CONFERENCIA_ESTOQUE_CORRECAO,
      { conferenciaEstoqueId, usuarioId, corrigirApenasItensConferidos }
    );

    if (response) {
      if (response.sucesso) {
        setIsCorrigindo(false);

        nextStep();

        return { sucesso: true };
      }
    }

    setIsCorrigindo(false);
    return { sucesso: false };
  }, [conferenciaEstoqueId, corrigirApenasItensConferidos, nextStep]);

  async function handleRealizarCorrecao() {
    if (!possuiPermissaoAplicarCorrecoes) {
      toast.warn(
        'Você não tem permissão para realizar essa ação. Consulte o administrador da conta.'
      );
      return;
    }
    const { sucesso } = await correcaoConferenciaEstoque();
    if (sucesso)
      history.push(
        existeDivergencia
          ? ConstanteRotas.CONFERENCIA_ESTOQUE_FINALIZAR
          : ConstanteRotas.CONFERENCIA_ESTOQUE
      );
  }

  const loadMoreRows = useCallback(
    async ({
      currentPage,
      pageSize,
      orderColumn,
      orderDirection,
    }: LoadMoreRowsParams) => {
      if (conferenciaEstoqueId) {
        setIsProdutosDivergenciasLoading(true);

        const paginationData = {
          currentPage,
          pageSize,
          orderColumn,
          orderDirection,
        };

        const response = await api.get<
          void,
          ResponseApi<GridPaginadaRetorno<ProdutoDivergenciaPaginadoRegistro>>
        >(
          formatQueryPagegTable(
            ConstanteEnderecoWebservice.CONFERENCIA_ESTOQUE_OBTER_DIVERGENCIAS,
            paginationData
          ),
          { params: { conferenciaEstoqueId, corrigirApenasItensConferidos } }
        );

        if (response) {
          if (response.sucesso) {
            if (
              latestProps.current.isLoading &&
              (!response.dados.registros ||
                (response.dados.registros &&
                  response.dados.registros.length === 0))
            ) {
              setExisteDivergencia(false);
              setIsLoading(false);
              return;
            }

            setIsLoading(false);
            setProdutosDivergencias((prev) => [
              ...prev,
              ...response.dados.registros,
            ]);

            setItemsTotalCount(response.dados.total || 0);
          }
        }
      }

      setIsLoading(false);

      setIsProdutosDivergenciasLoading(false);
    },
    [conferenciaEstoqueId, corrigirApenasItensConferidos]
  );

  const handleEnvioEmailRelatorio = useCallback(
    async (isCompleto: boolean) => {
      EmailModal({
        filtrosConferenciaEstoque: {
          conferenciaEstoqueId,
          corrigirApenasItensConferidos,
          estoqueCompleto: isCompleto,
        },
      });
    },
    [conferenciaEstoqueId, corrigirApenasItensConferidos]
  );
  const handleImprimirRelatorio = useCallback(
    async (isCompleto) => {
      const response = await api.get<void, ResponseApi<string>>(
        ConstanteEnderecoWebservice.CONFERENCIA_ESTOQUE_IMPRIMIR_RELATORIO,
        {
          params: {
            conferenciaEstoqueId,
            completo: isCompleto,
            corrigirApenasItensConferidos,
          },
        }
      );

      if (response?.sucesso) {
        ImprimirPDF(response.dados, 'relatorioConferenciaEstoque');
      }
      if (response?.avisos) {
        response.avisos.forEach((item: any) => toast.warning(item));
      }
    },
    [conferenciaEstoqueId, corrigirApenasItensConferidos]
  );

  const handleExportarAquivoCSV = useCallback(
    async (isCompleto: boolean) => {
      const response = await api.get<void, ResponseApi<string>>(
        ConstanteEnderecoWebservice.CONFERENCIA_ESTOQUE_IMPRIMIR_CSV,
        {
          params: {
            conferenciaEstoqueId,
            completo: isCompleto,
            apenasItensConferidos: corrigirApenasItensConferidos,
          },
        }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item) => toast.warning(item));
          return;
        }

        if (response.dados && response.sucesso) {
          const url = window.URL.createObjectURL(new Blob([response.dados]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `RelatorioConferenciaEstoque.csv`);
          link.click();
        }
      }
    },
    [conferenciaEstoqueId, corrigirApenasItensConferidos]
  );

  const handleCompartilharOperacao = useCallback(async () => {
    ModalCompartilhar({
      items: [
        {
          titulo: 'Relatório conferência com divergências',
          onClickImpressao: (onClose) => {
            handleImprimirRelatorio(false);
            onClose();
          },
          onClickEmail: (onClose) => {
            handleEnvioEmailRelatorio(false);
            onClose();
          },
          onClickDownload: (onClose) => {
            handleExportarAquivoCSV(false);
            onClose();
          },
        },
        {
          titulo: 'Relatório conferência completa',
          onClickImpressao: (onClose) => {
            handleImprimirRelatorio(true);
            onClose();
          },
          onClickEmail: (onClose) => {
            handleEnvioEmailRelatorio(true);
            onClose();
          },
          onClickDownload: (onClose) => {
            handleExportarAquivoCSV(true);
            onClose();
          },
        },
      ],
    });
  }, [
    handleImprimirRelatorio,
    handleExportarAquivoCSV,
    handleEnvioEmailRelatorio,
  ]);

  return (
    <Container>
      <Body
        editarManualmente={!existeDivergencia}
        pt={existeDivergencia ? undefined : '15px'}
        pb={existeDivergencia ? undefined : '10px'}
      >
        {isLoading && (
          <Box
            position="absolute"
            zIndex="banner"
            bg="white"
            left="0"
            top="0"
            minH="full"
            minW="full"
            borderBottomRadius="md"
          >
            <LoadingPadrao />
          </Box>
        )}
        <VStack
          spacing="1"
          alignItems="flex-start"
          mb={existeDivergencia ? '4' : undefined}
        >
          <Text color="primary.50" fontWeight="semibold" fontSize="lg">
            {existeDivergencia ? (
              <>
                {itemsTotalCount === 1
                  ? `Foi encontrado `
                  : `Foram encontrados `}
                <Text as="span" bg="secondary.400">
                  {itemsTotalCount === 1
                    ? `${itemsTotalCount} produto`
                    : `${itemsTotalCount} produtos`}
                </Text>{' '}
                com divergências no estoque.
              </>
            ) : (
              <>Nenhuma divergência foi encontrada.</>
            )}
          </Text>
          {existeDivergencia && (
            <Text fontSize="sm">
              Os itens listados apresentam diferença entre o sistema e a
              quantidade informada. Clique em “Realizar correção” para ZERAR
              todas as diferenças e atualizar o sistema com os valores
              informados na conferência.
            </Text>
          )}
        </VStack>
        {existeDivergencia && (
          <VirtualizedInfiniteTable
            widthSizeScroll="calc(100% - 5px)"
            alterarBordaListagem="white"
            isLoading={isProdutosDivergenciasLoading}
            withoutRowsMessage="Nenhuma divergência encontrada."
            orderColumn="produto"
            editarWidht
            editarLargura
            visibleItemsCount={6}
            boxShadow="none"
            tableHeaders={produtosTableHeaders}
            rowRenderer={({ index, style: { height, ...restStyle }, key }) => {
              const produto = produtosDivergencias[index];

              if (!produto) {
                return null;
              }
              return (
                <Tr key={key} style={restStyle} height={height}>
                  <Td width={produtosTableHeaders[0].width}>
                    <Text lineHeight="0" opacity={0}>
                      {produtosTableHeaders[0].content}
                    </Text>

                    {produto.codigoBarrasInterno}
                  </Td>
                  <Td width={produtosTableHeaders[1].width}>
                    <Text lineHeight="0" opacity={0}>
                      {produtosTableHeaders[1].content}
                    </Text>

                    <Flex
                      justifyContent="center"
                      flexDir="column"
                      maxH="1px"
                      overflow="visible"
                    >
                      <Text lineHeight="short">
                        {`${produto.nome}${
                          produto.cor ? ` ${produto.cor}` : ''
                        }`}
                      </Text>

                      {produto.tamanho && (
                        <Text
                          lineHeight="short"
                          fontSize="2xs"
                          color="pink.500"
                        >
                          {`Tamanho: ${produto.tamanho}`}
                        </Text>
                      )}
                    </Flex>
                  </Td>
                  <Td width={produtosTableHeaders[2].width} isNumeric>
                    <Text
                      ml={isLargerThan520 ? '' : '-30px'}
                      lineHeight="0"
                      opacity={0}
                      whiteSpace="nowrap"
                    >
                      {produtosTableHeaders[2].content}
                    </Text>

                    {produto.quantidadeInformada.toLocaleString('pt-BR', {
                      minimumFractionDigits:
                        casasDecimais.casasDecimaisQuantidade,
                      maximumFractionDigits:
                        casasDecimais.casasDecimaisQuantidade,
                    })}
                  </Td>
                  <Td width={produtosTableHeaders[3].width} isNumeric>
                    <Text lineHeight="0" opacity={0} whiteSpace="nowrap">
                      {produtosTableHeaders[3].content}
                    </Text>

                    {produto.estoqueAtual.toLocaleString('pt-BR', {
                      minimumFractionDigits:
                        casasDecimais.casasDecimaisQuantidade,
                      maximumFractionDigits:
                        casasDecimais.casasDecimaisQuantidade,
                    })}
                  </Td>
                  <Td
                    width={produtosTableHeaders[4].width}
                    textAlign="end"
                    isNumeric
                    color="red.500"
                  >
                    <Text lineHeight="0" opacity={0} whiteSpace="nowrap">
                      {produtosTableHeaders[4].content}
                    </Text>

                    {produto.quantidadeInformada > produto.estoqueAtual
                      ? '+'
                      : '-'}

                    {produto.diferenca.toLocaleString('pt-BR', {
                      minimumFractionDigits:
                        casasDecimais.casasDecimaisQuantidade,
                      maximumFractionDigits:
                        casasDecimais.casasDecimaisQuantidade,
                    })}
                  </Td>
                </Tr>
              );
            }}
            rowCount={itemsTotalCount}
            isRowLoaded={({ index }) => !!produtosDivergencias[index]}
            loadMoreRows={loadMoreRows}
          />
        )}
      </Body>
      <Stack
        direction={isSmallerThan1180 ? 'column' : 'row'}
        spacing={{ base: 2, sm: 4, md: 8 }}
        borderTop="1px"
        borderColor="gray.100"
        justifyContent="flex-end"
        py={4}
      >
        {existeDivergencia && (
          <Button
            marginRight="auto"
            isDisabled={isLoading || isCorrigindo}
            variant="outlineDefault"
            borderRadius="md"
            w="full"
            maxW={isSmallerThan1180 ? 'full' : '240px'}
            onClick={() => handleCompartilharOperacao()}
          >
            Exportar lista de divergências
          </Button>
        )}

        <Button
          isDisabled={isLoading || isCorrigindo}
          variant="cancel"
          colorScheme="gray"
          borderRadius="md"
          w="full"
          maxW={isSmallerThan1180 ? 'full' : '100px'}
          onClick={handleDescartarConferencia}
        >
          Descartar
        </Button>
        <Button
          isDisabled={
            isLoading ||
            isCorrigindo ||
            Number(conferenciaEstoque?.tipoConferencia) ===
              TipoConferenciaEnum.ZERAR_ESTOQUE
          }
          variant="outlineDefault"
          borderRadius="md"
          w="full"
          maxW={isSmallerThan1180 ? 'full' : '100px'}
          onClick={handleVoltar}
        >
          Voltar
        </Button>

        {existeDivergencia && (
          <Button
            isDisabled={isLoading || isCorrigindo}
            variant="outline"
            borderRadius="md"
            w="full"
            _hover={{
              bg: 'aquamarine.500',
              color: 'white',
              borderColor: 'aquamarine.500',
            }}
            _active={{
              bg: 'aquamarine.600',
              borderColor: 'aquamarine.600',
              boxShadow: 'none',
            }}
            maxW={isSmallerThan1180 ? 'full' : '160px'}
            onClick={handleSalvarSemCorrigir}
          >
            Salvar sem corrigir
          </Button>
        )}

        <Button
          isDisabled={isLoading || isCorrigindo}
          colorScheme="secondary"
          borderRadius="md"
          w="full"
          maxW={isSmallerThan1180 ? 'full' : '240px'}
          onClick={handleRealizarCorrecao}
        >
          {existeDivergencia ? 'Realizar correção dos saldos' : 'Finalizar'}
        </Button>
      </Stack>

      <ModalCorrigindoEstoque isOpen={isCorrigindo} onClose={() => {}} />
    </Container>
  );
}
