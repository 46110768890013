export const TipoCalculoEnum = {
  ACRESCIMO: 1,
  DESCONTO: 2,
  MARKUP: 3,

  properties: {
    1: { name: 'Acréscimo', value: 1 },
    2: { name: 'Desconto', value: 2 },
    3: { name: 'Markup', value: 3 },
  },
};
