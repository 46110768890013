import {
  ModalProps,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useMediaQuery,
  useDisclosure,
  ModalHeader,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { BrowserRouter, Route, RouteComponentProps } from 'react-router-dom';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import TranferenciaEstoqueVisualizar, {
  TransferenciaEstoqueTParams,
} from 'pages/TransferenciaEstoque/Formulario/Visualizar';
import VendasDetalhes, { VendasDetalhesTParams } from 'pages/Vendas/Detalhes';
import MovimentacaoEstoqueVisualizar, {
  VisualizarTParams,
} from 'pages/MovimentacaoEstoque/Formulario/Visualizar';
import { EntradaManual } from 'pages/EntradaMercadoria/EntradaManual';
import { EntradaMercadoriaDadosCadastroProvider } from 'store/EntradaMercadoria/EntradaMercadoriaDadosCadastro';
import { EntradaMercadoriaEtapasProvider } from 'store/EntradaMercadoria/EntradaMercadoriaEtapas';
import { useMemo } from 'react';
import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';
import PadronizacaoProvider, {
  CasasDecimais,
} from 'store/Padronizacao/Padronizacao';

type ModalDetalhesResponse = {
  detalhe: string;
  conferenciaEstoqueId: string;
};

type ModalDetalhesPedidoProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalDetalhesResponse> & {
    operacaoId: string;
    entradaMercadoriaId?: string;
    identificacaoTipoOperacao: number;
    casasDecimais: CasasDecimais;
  };

export const ModalVisualizarOperacao = create<
  ModalDetalhesPedidoProps,
  ModalDetalhesResponse
>(
  ({
    onResolve,
    onReject,
    casasDecimais,
    entradaMercadoriaId,
    operacaoId,
    identificacaoTipoOperacao,
    ...rest
  }) => {
    const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
    const Page = useMemo(() => {
      let NewPage = () => <div />;

      switch (identificacaoTipoOperacao) {
        case IdentificacaoTipoOperacaoEnum.DEVOLUCAO_COMPRA:
        case IdentificacaoTipoOperacaoEnum.BRINDE:
        case IdentificacaoTipoOperacaoEnum.PERDA:
        case IdentificacaoTipoOperacaoEnum.AJUSTE_ESTOQUE_SAIDA:
        case IdentificacaoTipoOperacaoEnum.AJUSTE_ESTOQUE_ENTRADA:
        case IdentificacaoTipoOperacaoEnum.CONFERENCIA_ESTOQUE_SAIDA:
        case IdentificacaoTipoOperacaoEnum.CONFERENCIA_ESTOQUE_ENTRADA:
          NewPage = () => (
            <MovimentacaoEstoqueVisualizar
              movimentacaoEstoqueId={operacaoId}
              {...({} as RouteComponentProps<VisualizarTParams>)}
            />
          );
          break;

        case IdentificacaoTipoOperacaoEnum.TRANSFERENCIA_PRODUTO_ENTRADA:
        case IdentificacaoTipoOperacaoEnum.TRANSFERENCIA_PRODUTO_SAIDA:
          NewPage = () => (
            <TranferenciaEstoqueVisualizar
              transferenciaEstoqueId={operacaoId}
              {...({} as RouteComponentProps<TransferenciaEstoqueTParams>)}
            />
          );
          break;

        case IdentificacaoTipoOperacaoEnum.VENDA:
        case IdentificacaoTipoOperacaoEnum.PEDIDO:
        case IdentificacaoTipoOperacaoEnum.ORCAMENTO:
        case IdentificacaoTipoOperacaoEnum.DEVOLUCAO:
        case IdentificacaoTipoOperacaoEnum.CONSIGNACAO:
          NewPage = () => (
            <VendasDetalhes
              IsCadastroExterno
              operacaoId={operacaoId}
              {...({} as RouteComponentProps<VendasDetalhesTParams>)}
            />
          );
          break;

        case IdentificacaoTipoOperacaoEnum.PEDIDO_COMPRA:
        case IdentificacaoTipoOperacaoEnum.ENTRADA_MERCADORIA:
        case IdentificacaoTipoOperacaoEnum.PAGAMENTO_COMPRA:
        case IdentificacaoTipoOperacaoEnum.COMPRA_MERCADORIA:
          NewPage = () => (
            <EntradaMercadoriaEtapasProvider>
              <EntradaMercadoriaDadosCadastroProvider
                defaultEntradaMercadoriaId={entradaMercadoriaId}
                isReadOnly
                IsCadastroExterno
              >
                <EntradaManual />
              </EntradaMercadoriaDadosCadastroProvider>
            </EntradaMercadoriaEtapasProvider>
          );
          break;

        default:
          NewPage = () => <div />;
          break;
      }

      return NewPage;
    }, [identificacaoTipoOperacao, operacaoId]);

    return (
      <ModalPadraoChakra
        size="full"
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalContent bg="gray.50">
          <ModalHeader
            mt={isSmallerThan900 ? 12 : undefined}
            mb={isSmallerThan900 ? 8 : undefined}
            borderBottom="1px"
            borderColor="gray.100"
            px="0"
            mx={{ base: 6, md: 8 }}
          >
            Detalhe do Pedido
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p={{ base: 6, md: 8 }}>
            <PadronizacaoProvider defaultCasasDecimaisValues={casasDecimais}>
              <BrowserRouter>
                <Route>
                  <Page />
                </Route>
              </BrowserRouter>
            </PadronizacaoProvider>
          </ModalBody>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
