import { useState, useEffect } from 'react';
import { Text, Box, Flex, useBreakpointValue } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import TipoRepasseComissaoEnum from 'constants/enum/tipoRegraPagComissao';
import { useMetasComissoesEtapasContext } from 'store/MetasComissoes/MetasComissoesEtapas';
import { IdentificacaoEtapasMetasComissoes } from 'constants/enum/identificacaoEtapasMetasComissoes';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteRotas from 'constants/rotas';

import { DetailedRadio } from 'components/update/Radio/DetailedRadio';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { FooterMetasComissoes } from '../FooterMetasComissoes';

export type RegraPagamentoResponse = {
  id: string;
  regraPagamentoComissao: number;
};

export const EscolherRegraPagComissao = () => {
  const [
    isLoadingEscolherRegraPagComissao,
    setIsLoadingEscolherRegraPagComissao,
  ] = useState(false);

  const isMobile = useBreakpointValue({
    sm: true,
    base: true,
    md: false,
    lg: false,
  });
  const {
    setActiveStep,
    obterValorMetaLoja,
    isLoading,
    animacaoLoading,
    metaGeral,
    setIsLoading,
  } = useMetasComissoesEtapasContext();

  const loading = isLoadingEscolherRegraPagComissao || isLoading;

  const { getValues, setValue, handleSubmit, watch } = useFormContext();
  const history = useHistory();

  const tipoRegraPagComissaoWatch = watch('tipoRegraPagComissao');

  const handleAvancar = handleSubmit(async ({ tipoRegraPagComissao }) => {
    setIsLoadingEscolherRegraPagComissao(true);
    const response = await api.patch<
      void,
      ResponseApi<RegraPagamentoResponse[]>
    >(ConstanteEnderecoWebservice.CADASTRAR_META_COMISSAO_REGRA_REPASSE, {
      id: obterValorMetaLoja?.id || '',
      regraPagamentoComissao: Number(tipoRegraPagComissao),
    });

    if (response) {
      if (response.avisos) {
        setIsLoadingEscolherRegraPagComissao(false);
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso) {
        setIsLoadingEscolherRegraPagComissao(false);
        setIsLoading(true);

        if (metaGeral?.valorMetaGeral === 0) {
          history.push(ConstanteRotas.METAS_E_COMISSOES);
        } else {
          setActiveStep(IdentificacaoEtapasMetasComissoes.PREMIACAO_POR_EQUIPE);
        }
      }
    }
    setIsLoadingEscolherRegraPagComissao(false);
  });

  const handleVoltar = () => {
    setActiveStep(IdentificacaoEtapasMetasComissoes.COMISSAO_VENDEDORES);
  };

  useEffect(() => {
    animacaoLoading();
  }, [animacaoLoading]);

  return (
    <>
      {loading && <LoadingPadrao />}
      <Box
        borderRadius="md"
        boxShadow="0px 0px 6px #00000034"
        w="full"
        bg="white"
        overflow="auto"
      >
        <Flex
          alignItems="center"
          borderRadius="5px 5px 0 0"
          bg="primary.500"
          h="48px"
        >
          <Text
            ml={isMobile ? '16px' : '40px'}
            fontSize="16px"
            color="secondary.300"
          >
            Regra de pagamento da comissão
          </Text>
        </Flex>
        <Box p={isMobile ? '16px' : '40px'}>
          <DetailedRadio
            w="full"
            name="tipoRegraPagComissao"
            defaultValue={String(getValues('tipoRegraPagComissao'))}
            onChange={(newValue) => setValue('tipoRegraPagComissao', newValue)}
            items={[
              {
                value: String(TipoRepasseComissaoEnum.FATURAMENTO),
                title: 'Repasse da comissão no FATURAMENTO da venda',
                subtitle:
                  'Assim que a venda é faturada no sistema o valor referente a comissão será creditado ao vendedor.',
              },
              {
                value: String(TipoRepasseComissaoEnum.RECEBIMENTO),
                title: 'Repasse da comissão após o RECEBIMENTO dos valores',
                subtitle:
                  'O valor referente a comissão será creditado ao vendedor somente após a confirmação do recebimento da venda ou de cada parcela.',
              },
            ]}
          />
        </Box>
      </Box>
      <FooterMetasComissoes
        handleAvancar={handleAvancar}
        handleVoltar={handleVoltar}
        isDisabledAvancar={tipoRegraPagComissaoWatch === '0'}
      />
    </>
  );
};
