import { useCallback, useEffect, useState } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  Divider,
  ModalFooter,
  Box,
  ModalHeader,
  useMediaQuery,
  GridItem,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { FiSlash, FiEdit } from 'react-icons/fi';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import { DateInput } from 'components/update/Input/DateInput';
import Input from 'components/PDV/Input';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { ButtonDefault } from 'components/Button/ButtonChakra';
import { ListVendasProps } from 'pages/Integracoes/LojaAplicativos/TrayCommerce/PainelAdministradorTray/VendasTray/validationForms';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';

import { yupResolver } from './validationForms';

type FormData = {
  codigoRastreio: string[];
  codigoRastreioSimples: string;
  dataEnvio: Date;
  isMultipleItens: boolean;
};

type ModalEnviarCodigoRastreioProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<FormData> & {
    listInfoRastreio: ListVendasProps[];
    reloadList: () => void;
  };

export const ModalEnviarCodigoRastreio = create<
  ModalEnviarCodigoRastreioProps,
  FormData
>(({ onResolve, onReject, id, reloadList, listInfoRastreio, ...rest }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSendCode, setIsSendCode] = useState(true);

  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues: {
      dataEnvio: new Date(),
      isMultipleItens: false,
    },
  });

  const isMultipleCodigo = listInfoRastreio.length > 1;

  const {
    handleSubmit: onSubmit,
    setValue,
    formState: { errors },
  } = formMethods;

  const handleUpdateSendCode = () => {
    setIsSendCode((prev) => !prev);
  };

  const handleFinalizar = useCallback(async () => {
    setIsLoading(true);

    const newListRastreio = listInfoRastreio.map((valueRastreio) => ({
      integracaoPedidoId: valueRastreio.integracaoPedidoId,
    }));

    const response = await api.put<void, ResponseApi<boolean>>(
      ConstanteEnderecoWebservice.INTEGRACAO_ALTERAR_RASTREIO_FINALIZAR,
      newListRastreio
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso) {
        toast.success('A venda foi finalizada com sucesso');
        reloadList();
        onClose();
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [listInfoRastreio, onClose, reloadList]);

  const handleEnviarCodigoRastreio = onSubmit(async (data) => {
    setIsLoading(true);
    const itemRastreio = listInfoRastreio[0];

    const newListRastreio = isMultipleCodigo
      ? listInfoRastreio.map((valueRastreio, index) => ({
          integracaoPedidoId: valueRastreio.integracaoPedidoId,
          dataEnvio: data.dataEnvio,
          codigoEnvio: data.codigoRastreio[index],
        }))
      : [
          {
            integracaoPedidoId: itemRastreio.integracaoPedidoId,
            codigoEnvio: data.codigoRastreioSimples,
            dataEnvio: data.dataEnvio,
          },
        ];

    const response = await api.put<void, ResponseApi<boolean>>(
      ConstanteEnderecoWebservice.INTEGRACAO_ALTERAR_RASTREIO,
      newListRastreio
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso) {
        reloadList();
        toast.success('Código enviado com sucesso');
        onClose();
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  });

  useEffect(() => {
    setValue('isMultipleItens', isMultipleCodigo);
  }, [isMultipleCodigo, setValue]);

  return (
    <ModalPadraoChakra
      isCentered={isLargerThan900}
      size={isLargerThan900 ? '5xl' : 'full'}
      {...rest}
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={false}
    >
      <ModalContent
        bg="gray.50"
        borderRadius={isLargerThan900 ? 'md' : '0'}
        marginBottom={isLargerThan900 ? '3.75rem' : '0'}
        marginTop={isLargerThan900 ? '3.75rem' : '0'}
        w={isLargerThan900 ? (isMultipleCodigo ? '1000px' : '640px') : 'full'}
        h={isLargerThan900 ? (isMultipleCodigo ? '560px' : '360px') : 'full'}
      >
        <FormProvider {...formMethods}>
          <ModalHeader
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            py="16px"
            pl="24px"
          >
            <Text color="primary.50" fontSize="16px">
              Enviar código de rastreio
            </Text>
            {isMultipleCodigo && (
              <Flex alignItems="center">
                <Text mr="8px" whiteSpace="nowrap" fontSize="12px">
                  Data de envio:
                </Text>
                <Box w="240px" mr="24px">
                  <DateInput name="dataEnvio" id="dataEnvio" />
                </Box>
                <Box
                  cursor="pointer"
                  onClick={handleUpdateSendCode}
                  mr="24px"
                  color="gray.500"
                >
                  {isSendCode ? <FiSlash /> : <FiEdit />}
                </Box>
              </Flex>
            )}
          </ModalHeader>

          {isLoading && <LoadingPadrao />}
          {isMultipleCodigo && (
            <Box mb="5" px="24px">
              <Divider />
            </Box>
          )}
          <ModalBody px="24px" mb="20px" pt="0" overflowY="auto" pb="0">
            {listInfoRastreio.map((itemRastreio, index) => (
              <Flex
                px="24px"
                key={itemRastreio.operacaoId}
                justifyContent={['center', 'space-between']}
                borderWidth="1px"
                borderColor="gray.200"
                minH="100px"
                h="auto"
                mb="4px"
                py={isMultipleCodigo ? ['10px', 'undefined'] : undefined}
                direction={['column', 'row']}
                alignItems={['left', 'center']}
                borderRadius="5px"
                bg="gray.100"
              >
                <Box>
                  <Flex alignItems="baseline">
                    <Text fontSize="12px" mr="5px" color="gray.700">
                      Cliente:
                    </Text>
                    <Text fontSize="14px" color="black">
                      {itemRastreio.cliente}
                    </Text>
                  </Flex>
                  <Flex alignItems="baseline">
                    <Text fontSize="12px" mr="5px" color="gray.700">
                      Endereço:
                    </Text>
                    <Text fontSize="14px" color="black">
                      {itemRastreio.endereco}
                    </Text>
                  </Flex>
                  <Flex alignItems="baseline">
                    <Text fontSize="12px" mr="5px" color="gray.700">
                      Origem:
                    </Text>
                    <Flex alignItems="baseline">
                      <Text fontSize="14px" color="black">
                        {itemRastreio.origem}
                      </Text>
                      <Text ml="15px" fontSize="12px" mr="5px" color="gray.700">
                        Identificador:
                      </Text>
                      <Text fontSize="14px" color="black">
                        {itemRastreio.identificacaoTipoOperacao || '-'}
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
                {isMultipleCodigo && (
                  <Box mt={['10px', 'undefined']} w="240px">
                    <Input
                      id={`codigoRastreio.${index}`}
                      name={`codigoRastreio.${index}`}
                      placeholder="Digite o Codigo"
                      isDisabled={!isSendCode}
                      errorText={
                        errors.codigoRastreio
                          ? errors.codigoRastreio[index]
                            ? errors.codigoRastreio[index].message
                            : ''
                          : ''
                      }
                      label={isLargerThan700 ? 'Código de rastreio' : ''}
                      _placeholder={{ color: 'gray.200' }}
                    />
                  </Box>
                )}
              </Flex>
            ))}
            {!isMultipleCodigo && (
              <SimpleGridForm pt="20px">
                <GridItem colSpan={[12, 12, 4]}>
                  <DateInput
                    name="dataEnvio"
                    label="Data de envio:"
                    id="dataEnvio"
                  />
                </GridItem>
                <GridItem colSpan={[12, 12, 8]}>
                  <Input
                    id="codigoRastreioSimples"
                    name="codigoRastreioSimples"
                    placeholder="Digite o Codigo"
                    label="Código de rastreio"
                    inputRightElement
                    isDisabled={!isSendCode}
                    icon={isSendCode ? FiSlash : FiEdit}
                    sizeIcon="15px"
                    onClickRightElement={handleUpdateSendCode}
                    _placeholder={{ color: 'gray.200' }}
                  />
                </GridItem>
              </SimpleGridForm>
            )}
          </ModalBody>
          <ModalFooter flexDirection="column">
            <Flex
              w="full"
              h="full"
              justifyContent="center"
              alignItems="baseline"
            >
              <Button
                variant="outlineDefault"
                colorScheme="gray"
                mr="24px"
                onClick={() => onClose()}
                w="96px"
              >
                Cancelar
              </Button>
              <ButtonDefault
                variant="solid"
                color={isSendCode ? 'white' : 'black'}
                colorScheme={isSendCode ? 'purple.500' : 'orange.400'}
                width={isSendCode ? '220px' : '297px'}
                onClick={
                  isSendCode ? handleEnviarCodigoRastreio : handleFinalizar
                }
                isLoading={isLoading}
              >
                {isSendCode
                  ? 'Confirmar e enviar'
                  : 'Finalizar venda sem código de rastreio'}
              </ButtonDefault>
            </Flex>
          </ModalFooter>
        </FormProvider>
      </ModalContent>
    </ModalPadraoChakra>
  );
});
