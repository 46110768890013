import { useCallback, useImperativeHandle, useState, useEffect } from 'react';
import { VStack, Text, Flex, Box, Icon, Tooltip } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { BsArrowRight } from 'react-icons/bs';

import { IdentificacaoEtapasTray } from 'constants/enum/IdentificacaoEtapasTray';
import { useTrayEtapasContext } from 'store/Tray';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { getTipoProdutoTray } from 'services/tray';

import { CadastrosIcon } from 'icons';

import { dataDefaultCadastro, TipoUtilizacaoCadastro } from './validationForms';
import { Header } from '../Layout/Header';

export const TipoCadastro = () => {
  const [listcadastro, setListcadastro] = useState(dataDefaultCadastro);

  const {
    ref,
    setActiveStep,
    setIsLoading,
    setIsProdutoSistema,
  } = useTrayEtapasContext();

  const handleAvancar = useCallback(
    (route: number) => {
      setActiveStep(route);
    },
    [setActiveStep]
  );

  const handleVoltar = useCallback(() => {
    setActiveStep(IdentificacaoEtapasTray.TABELA_PRECO);
  }, [setActiveStep]);

  const handleSelecionarCadastro = useCallback((index: number) => {
    setListcadastro((prev) =>
      prev.map((itemCadastro, indexCadastro) => ({
        ...itemCadastro,
        isChecked: index === indexCadastro,
      }))
    );
  }, []);

  const getObterTipoCadastro = useCallback(async () => {
    setIsLoading(true);

    const response = await getTipoProdutoTray();

    if (response !== null) {
      const data = dataDefaultCadastro.map((cadastroItem) => {
        return {
          ...cadastroItem,
          isChecked: cadastroItem.value === response.tipoCadastro,
        };
      });
      setListcadastro(data);
    }

    setIsLoading(false);
  }, [setIsLoading]);

  const handleSubmit = async () => {
    setIsLoading(true);

    const tipoCadastro = listcadastro.find(
      (itemCadastro) => itemCadastro.isChecked
    )?.value;

    const response = await api.put<void, ResponseApi<boolean>>(
      ConstanteEnderecoWebservice.INTEGRACAO_TRAY_TIPO_CADASTRO_ALTERAR,
      {
        tipoCadastro,
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
        setIsLoading(false);
      }

      if (response.sucesso) {
        let route;
        if (TipoUtilizacaoCadastro.PRODUTO_ZENDAR === tipoCadastro) {
          route = IdentificacaoEtapasTray.LISTA_PRODUTO_SISTEMA;
        } else {
          route = IdentificacaoEtapasTray.BUSCAR_PRODUTO;
        }

        handleAvancar(route);
      }
    }

    setIsLoading(false);
  };

  useImperativeHandle(ref, () => ({
    handleAvancar: handleSubmit,
    handleVoltar,
  }));

  useEffect(() => {
    setIsProdutoSistema(false);
    getObterTipoCadastro();
  }, [getObterTipoCadastro, setIsProdutoSistema]);

  return (
    <>
      <Header
        title="Cadastros"
        handleVoltar={handleVoltar}
        icon={CadastrosIcon}
      />

      <VStack fontSize="14px" w="full" alignItems="left" spacing="24px">
        {listcadastro.map((cadastro, index) => (
          <Flex
            borderWidth="1px"
            borderStyle="solid"
            opacity={cadastro.isDisable ? '0.6' : '1'}
            borderColor={
              cadastro.isChecked ? 'rgba(85, 2, 178, 0.5)' : 'gray.50'
            }
            _hover={{
              borderColor: cadastro.isDisable
                ? 'none'
                : 'rgba(85, 2, 178, 0.5)',
              transition: 'all 0.2s',
            }}
            transition="all 0.2s"
            bg={cadastro.isChecked ? 'blue.50' : ''}
            cursor={cadastro.isDisable ? 'not-allowed' : 'pointer'}
            pt="16px"
            pl={['10px', '10px', '30px']}
            pr={['10px', '10px', '30px']}
            pb="16px"
            w="full"
            onClick={() => {
              if (!cadastro.isDisable) {
                handleSelecionarCadastro(index);
              }
            }}
            justifyContent="flex-start"
          >
            <Box mr="9.5px">
              <Icon boxSize="26px" color="primary.50" as={BsArrowRight} />
            </Box>
            <Box>
              <Flex alignItems="baseline">
                <Box mr="10px">
                  <Text
                    color="primary.50"
                    fontSize="18px"
                    fontWeight="semibold"
                  >
                    {cadastro.title}
                  </Text>
                </Box>
                {cadastro.isRecomendado && (
                  <Tooltip
                    hasArrow
                    placement="right"
                    borderRadius="3px"
                    bgColor="#0F0F0F"
                    label="Os produtos cadastrados no sistema ficam organizados dentro da estrutura ideal para vendas e emissão fiscal. Cadastrar primeiro no sistema e depois enviar para a loja online é a forma mais indicada para manter os itens padronizados e garantir que todos os requisitos obrigatórios sejam preenchidos corretamente."
                  >
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      h="16px"
                      p="8px"
                      bg="primary.50"
                      color="white"
                      borderRadius="10px"
                    >
                      <Text fontSize="12px">Recomendado</Text>
                    </Flex>
                  </Tooltip>
                )}
              </Flex>
              <Text letterSpacing="0px" color="gray.700" fontSize="14px">
                {cadastro.description}
              </Text>
            </Box>
          </Flex>
        ))}
      </VStack>
    </>
  );
};
