import React, { useState, useMemo } from 'react';
import {
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Icon,
} from '@chakra-ui/react';
import { FiMoreVertical } from 'react-icons/fi';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { OperacaoItemObter } from 'helpers/api/Operacao/obterOperacaoComItens';
import { useLancamentoEditarItemContext } from 'store/PDV/LancamentoEditarItem';
import ConstanteFuncionalidades from 'constants/permissoes';
import LogAuditoriaTelaEnum from 'constants/enum/logAuditoriaTela';
import auth from 'modules/auth';
import { useOperacaoContext } from 'store/PDV/Operacao';

import PortalFullscreen from 'components/PDV/Geral/PortalFullscreen';
import ModalConfirmacao from 'components/PDV/Modal/ModalConfirmacao';
import ModalAutorizacaoFuncionalidade from 'components/Modal/ModalAutorizacaoFuncionalidade';

import { ModalInformacoesComplementares } from '../../ModalInformacoesComplementares';

interface MenuItemOpcoesInterface {
  operacaoItem: OperacaoItemObter;
}

const MenuItemOpcoes = ({ operacaoItem }: MenuItemOpcoesInterface) => {
  const { setOperacaoItemEditando } = useLancamentoEditarItemContext();
  const { setValue, getValues, watch } = useFormContext();
  const {
    handleExcluirItemOperacao,
    setOperacaoIsLoading,
  } = useOperacaoContext();

  const [
    modalAutorizacaoExcluirIsOpen,
    setModalAutorizacaoExcluirIsOpen,
  ] = useState(false);
  const [
    modalAutorizacaoAlterarIsOpen,
    setModalAutorizacaoAlterarIsOpen,
  ] = useState(false);
  const [modalConfirmacaoIsOpen, setModalConfirmacaoIsOpen] = useState(false);
  const permissaoAlterarItem = useMemo(
    () =>
      auth.possuiPermissao(ConstanteFuncionalidades.PDV_ALTERAR_ITEM.codigo),
    []
  );

  const possuiMovimentacaoFinanceira = watch('possuiMovimentacaoFinanceira');

  const handleEditar = (chave?: string) => {
    setOperacaoItemEditando({
      ...operacaoItem,
      chavePermissaoTemporariaAlterar: chave,
    });
  };

  const handleExcluir = async (chave: string) => {
    setOperacaoIsLoading(true);
    if (operacaoItem.id)
      if (await handleExcluirItemOperacao(operacaoItem.id, chave)) {
        const operacaoItens = getValues(
          'operacaoItens'
        ) as Array<OperacaoItemObter>;

        setValue(
          'operacaoItens',
          operacaoItens.filter((item) => item.id !== operacaoItem.id)
        );
      }
    setOperacaoIsLoading(false);
  };

  const handleVerificarPermissaoExcluir = () => {
    if (
      auth.possuiPermissao(
        ConstanteFuncionalidades.PDV_EXCLUIR_OPERACAO_ITEM.codigo
      ).permitido
    ) {
      handleExcluir('');
    } else {
      setModalAutorizacaoExcluirIsOpen(true);
    }
  };

  const handleEditarInformaçõesComplementares = async () => {
    const informacoesComplementares = await ModalInformacoesComplementares({
      defaultValue: operacaoItem.informacoesComplementares,
    });

    const response = await api.put<void, ResponseApi<boolean>>(
      ConstanteEnderecoWebservice.PEDIDOORCAMENTOVENDA_ITEM_ALTERAR_INFORMACOES_COMPLEMENTARES_ITEM,
      { operacaoItemId: operacaoItem.id, informacoesComplementares }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }

      if (response.sucesso) {
        const operacaoItens = getValues(
          'operacaoItens'
        ) as Array<OperacaoItemObter>;

        const newOperacaoItens = [...operacaoItens];

        const itemIndex = newOperacaoItens.findIndex(
          (item) => item.id === operacaoItem.id
        );

        newOperacaoItens.splice(itemIndex, 1, {
          ...operacaoItem,
          informacoesComplementares,
        });

        setValue('operacaoItens', newOperacaoItens);
      }
    }
  };

  return (
    <>
      <Menu
        isLazy
        modifiers={[
          {
            name: 'eventListeners',
            options: { scroll: false },
          },
        ]}
        closeOnSelect
      >
        <MenuButton
          as={IconButton}
          icon={<Icon as={FiMoreVertical} fontSize="xl" />}
          variant="ghost"
          borderRadius="md"
          size="sm"
          py={0}
          mr={1}
        />
        <PortalFullscreen>
          <MenuList>
            {operacaoItem.solicitarInformacaoComplementar && (
              <MenuItem
                onClick={() => {
                  handleEditarInformaçõesComplementares();
                }}
              >
                Informações complementares
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                if (possuiMovimentacaoFinanceira) {
                  toast.warning(
                    'Para alterar um item é necessário remover o pagamento adicionado.'
                  );
                  return;
                }

                if (permissaoAlterarItem.permitido) {
                  handleEditar();
                } else {
                  setModalAutorizacaoAlterarIsOpen(true);
                }
              }}
            >
              Editar
            </MenuItem>
            <MenuItem
              onClick={() => {
                if (possuiMovimentacaoFinanceira) {
                  toast.warning(
                    'Para remover um item é necessário remover o pagamento adicionado.'
                  );
                  return;
                }

                setModalConfirmacaoIsOpen(true);
              }}
            >
              Remover
            </MenuItem>
          </MenuList>
        </PortalFullscreen>
      </Menu>

      {modalAutorizacaoExcluirIsOpen && (
        <ModalAutorizacaoFuncionalidade
          isOpen={modalAutorizacaoExcluirIsOpen}
          setIsOpen={setModalAutorizacaoExcluirIsOpen}
          autorizado={handleExcluir}
          titulo={ConstanteFuncionalidades.PDV_EXCLUIR_OPERACAO_ITEM.titulo}
          descricao={
            ConstanteFuncionalidades.PDV_EXCLUIR_OPERACAO_ITEM.descricao
          }
          tela={LogAuditoriaTelaEnum.PDV}
          permissoes={[
            ConstanteFuncionalidades.PDV_EXCLUIR_OPERACAO_ITEM.codigo,
          ]}
        />
      )}

      {modalAutorizacaoAlterarIsOpen && (
        <ModalAutorizacaoFuncionalidade
          isOpen={modalAutorizacaoAlterarIsOpen}
          setIsOpen={setModalAutorizacaoAlterarIsOpen}
          autorizado={handleEditar}
          titulo={ConstanteFuncionalidades.PDV_ALTERAR_ITEM.titulo}
          descricao={ConstanteFuncionalidades.PDV_ALTERAR_ITEM.descricao}
          tela={LogAuditoriaTelaEnum.PDV}
          permissoes={[ConstanteFuncionalidades.PDV_ALTERAR_ITEM.codigo]}
        />
      )}
      {modalConfirmacaoIsOpen && (
        <ModalConfirmacao
          isOpen={modalConfirmacaoIsOpen}
          textoCabecalho="Confirmar"
          textoMensagem="O item será excluído, deseja continuar?"
          setIsOpen={setModalConfirmacaoIsOpen}
          onConfirm={handleVerificarPermissaoExcluir}
        />
      )}
    </>
  );
};

export default MenuItemOpcoes;
