/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { GuardProvider, GuardedRoute as Guard } from 'react-router-guards';
import {
  GuardToRoute,
  GuardFunctionRouteProps,
  Next,
  GuardFunction,
} from 'react-router-guards/dist/types';
import { configure } from 'react-hotkeys';

import ServiceWorkerWrapper from 'components/ServiceWorkerWrapper';
import PadronizacaoProvider from 'store/Padronizacao/Padronizacao';
import auth from '../modules/auth';
import ConstanteRotas, { ConstanteRotasAlternativas } from '../constants/rotas';
import ConstanteRotasPDV from '../constants/rotasPDV';
import SignalR from '../store/SignalR';
import AtendimentoChatProvider from '../store/AtendimentoChat';

import Layout from '../components/Layout';
import PaginaNaoExiste from '../pages/PaginaNaoExiste';
import LoadingPadrao from '../components/Layout/Loading/LoadingPadrao';
import { ModalDesistenciaCadastro } from '../components/Modal/ModalDesistenciaCadastro';

import AutenticacaoRoutes, { authRoutes } from './autenticacao.routes';
import DashboardRoutes from './dashboard.routes';
import VendedoresRoutes from './vendedores.routes';
import CategoriaClientesRoutes from './categoria-clientes.routes';
import ProdutosRoutes from './produtos.routes';
import ImportarProdutosRoutes from './importar-produtos.routes';
import TransportadorasRoutes from './transportadoras.routes';
import MarcasRoutes from './marcas.routes';
import CoresRoutes from './cores.routes';
import TamanhosRoutes from './tamanhos.routes';
import { LocalEstoqueRoutes } from './local-estoque.routes';
import { MovimentacaoEstoqueRoutes } from './movimentacao-estoque.routes';
import { TransferenciaEstoqueRoutes } from './transferencia-estoque.routes';
import { TabelaPrecosRoutes } from './tabela-precos.routes';
import { HistoricoProdutoRoutes } from './historico-produto.routes';
import { ConferenciaEstoqueRoutes } from './conferencia-estoque.routes';
import UnidadesMedidaRoutes from './unidades-medida.routes';
import HistoricoAcoesRoutes from './historico-acoes.routes';
import CategoriaProdutosRoutes from './categoria-produtos.routes';
import ClientesRoutes from './clientes.routes';
import ImportarClientesRoutes from './importar-clientes.routes';
import RegrasFiscaisRoutes from './regras-fiscais.routes';
import { EntradaMercadoriaRoutes } from './entrada-mercadoria.routes';
import { ExtratoRoutes } from './extrato.routes';
import { FaturasRoutes } from './faturas.routes';
import { ManifestacaoDestinatarioRoutes } from './manifestacao-destinatario.routes';
import Operacoes from './vendas.routes';
import VouchersRoutes from './vouchers.routes';
import FornecedoresRoutes from './fornecedores.routes';
import ImportarFornecedoresRoutes from './importar-fornecedores.routes';
import ContaFinanceiraRoutes from './conta-financeira.routes';
import PlanoContasRoutes from './plano-contas.routes';
import FormaPagamentoRoutes from './forma-pagamento.routes';
import FormaRecebimentoRoutes from './forma-recebimento.routes';
import NotasFiscaisRoutes from './notas-fiscais.routes';
import PerfisUsuariosRoutes from './perfil-usuarios.routes';
import UsuariosRoutes from './usuarios.routes';
import LojasRoutes from './lojas.routes';
import CamposPersonalizadosRoutes from './campos-personalizados.routes';
import PadronizacaoRoutes from './padronizacao.routes';
import DocumentosFiscaisExportacaoExternoRoutes, {
  documentosFiscaisExportacaoExternoRoutes,
} from './documento-fiscal-exportacao-externo.routes';
import { ConciliacaoDeContasRoutes } from './conciliar-baixas.routes';
import ControleCaixaRoutes from './controle-caixa.routes';
import ContaPagarRoutes from './contas-pagar.routes';
import ContaReceberRoutes from './contas-receber.routes';
import { RecebimentoContasRoutes } from './recebimento-contas.routes';
import { RelatorioProdutosPersonalizadosRoutes } from './relatorios-produtos-personalizados.routes';
import { RelatorioInventarioRoutes } from './relatorio-inventario.routes';
import RelatorioClientesPersonalizados from './relatorioPersonalizadoClientes.routes';
import MultasEJuros from './MultasEJuros.routes';
import { MetasComissoesRoutes } from './metas-comissoes.routes';
import { RelatorioVendasMetasComissoesRoutes } from './relatorio-vendas-metas-comissoes.routes';
import { RelatorioOperacoesRoutes } from './relatorio-operacoes.routes';
import { RelatorioDeCurvaABC } from './relatorioCurvaABC.routes';
import { RelatorioVendasRoutes } from './relatorio-vendas.routes';
import { RelatorioEstoqueRoutes } from './relatorio-estoque.routes';
import { DashboardGerencialRoutes } from './dashboard-gerencial.routes';
import { ZoopRoutes } from './zoop.routes';
import { ZoopGerencialRoutes } from './zoop-geral.routes';
import { RelatorioCatalogoProdutosRoutes } from './relatorio-catalogo-produto.routes';
import { RelatorioInformacoesClientes } from './relatorio-informacoes-clientes.routes';

import DocumentosFiscaisExportacaoRoutes from './documento-fiscal-exportacao.routes';

import CompartilharExterno from '../pages/PDV/CompartilharExterno';

import PDVRoutes from './pdv.routes';
import { EtiquetaPersonalizadaRoutes } from './etiquetas-personalizadas.routes';

import { HistoricoVendasMetasComissoesRoutes } from './historico-vendas-metas-comissoes.routes';
import { LojaAplicativoRoutes } from './loja-aplicativo.routes';
import { SmartPosRoutes } from './smart-pos.routes';
import { SmartPosListarRoutes } from './listar-smart-pos.routes';
import { PainelAdmTrayRoutes } from './tray.routes';
import { TrayEtapasRoutes } from './tray-etapas.routes';
import { PdvAutonomoRoutes } from './pdv-autonomo.routes';
import { ZoopConfigRoutes } from './zoop-config.routes';
import { PromocaoRoutes } from './promocao.routes';
import { PromocaoCadastrarRoutes } from './promocao-cadastrar.routes';
import { FrenteCaixaRoutes } from './frente-caixa.routes';
import { AutoAtendimentoRoutes } from './auto-atendimento.routes';
import { CredenciadoraCartaoRoutes } from './credenciadora-cartao.routes';

const autenticadoComRotaParaPaginaAutenticacao = (path: string) =>
  auth.autenticado() &&
  (path === ConstanteRotas.LOGIN ||
    path === ConstanteRotas.HOME ||
    path === ConstanteRotas.RECUPERAR_SENHA ||
    path === `${ConstanteRotas.REDEFINIR_SENHA}/:token` ||
    path === ConstanteRotas.SENHA_ENVIADA_COM_SUCESSO);

const requireLogin: GuardFunction = (
  to: GuardToRoute,
  from: GuardFunctionRouteProps | null,
  next: Next
) => {
  const dominio = window.location.host.split('/')[0].toLowerCase();

  if (dominio !== 'app') {
    if (to.meta.auth) {
      if (auth.autenticado()) {
        next();
      }
      next.redirect(ConstanteRotas.LOGIN);
      return;
    }

    if (autenticadoComRotaParaPaginaAutenticacao(to.location.pathname)) {
      next.redirect(ConstanteRotas.DASHBOARD);
      return;
    }
  }

  if (
    to.location.pathname === ConstanteRotas.LOGIN ||
    to.location.pathname === ConstanteRotas.HOME
  ) {
    auth.dominioExistente(to.location.pathname, next);
  } else {
    next();
  }
};

const Routes: React.FC = () => {
  useEffect(() => {
    configure({ ignoreTags: [] });
  }, []);

  return (
    <BrowserRouter
      getUserConfirmation={(message, callback) =>
        ModalDesistenciaCadastro({ textoMensagem: message, callback })
      }
    >
      <GuardProvider
        guards={[requireLogin]}
        loading={() => (
          <div
            style={{
              position: 'fixed',
              height: '100%',
              width: '100%',
              maxWidth: '9999px',
              top: 0,
              left: 0,
            }}
          >
            <LoadingPadrao />
          </div>
        )}
      >
        <ServiceWorkerWrapper />
        <Switch>
          <Route
            path={[
              ...authRoutes.map((authRoute) => authRoute.path),
              ...documentosFiscaisExportacaoExternoRoutes.map(
                (documentosFiscaisExportacaoExternoRoute) =>
                  documentosFiscaisExportacaoExternoRoute.path
              ),
              ConstanteRotas.COMPARTILHAR_EXTERNO,
              ...Object.values(ConstanteRotasPDV),
              ...Object.values(ConstanteRotas),
              ...Object.values(ConstanteRotasAlternativas),
              '',
            ]}
            exact
          >
            <SignalR>
              <Route
                key="autenticacao-routes"
                path={authRoutes.map((route) => route.path)}
                exact
              >
                <AutenticacaoRoutes />
              </Route>
              <Route
                key="documento-fiscal-exportacao-externo-routes"
                path={documentosFiscaisExportacaoExternoRoutes.map(
                  (route) => route.path
                )}
                exact
              >
                <DocumentosFiscaisExportacaoExternoRoutes />
              </Route>
              <Route
                key="compartilhar-notas-fiscais-externo"
                path={ConstanteRotas.COMPARTILHAR_EXTERNO}
                exact
                render={(props) => <CompartilharExterno {...props} />}
              />
              <Route
                path={[
                  ...Object.values(ConstanteRotasPDV),
                  ...Object.values(ConstanteRotas),
                  ...Object.values(ConstanteRotasAlternativas),
                  '',
                ].filter(
                  (route) =>
                    ![
                      ...authRoutes.map((authRoute) => authRoute.path),
                      ...documentosFiscaisExportacaoExternoRoutes.map(
                        (documentosFiscaisExportacaoExternoRoute) =>
                          documentosFiscaisExportacaoExternoRoute.path
                      ),
                      ConstanteRotas.COMPARTILHAR_EXTERNO,
                      '',
                    ].includes(route)
                )}
                exact
              >
                <AtendimentoChatProvider>
                  <PadronizacaoProvider>
                    <Route path={Object.values(ConstanteRotasPDV)}>
                      <PDVRoutes />
                    </Route>
                    <Route
                      exact
                      path={[
                        ...Object.values(ConstanteRotasAlternativas).filter(
                          (item) => item !== '/'
                        ),
                      ]}
                    >
                      <>
                        {HistoricoVendasMetasComissoesRoutes}
                        {RelatorioVendasMetasComissoesRoutes}
                        {SmartPosRoutes}
                        {TrayEtapasRoutes}
                        {ZoopRoutes}
                      </>
                    </Route>
                    <Route
                      exact
                      path={[
                        ...Object.values(ConstanteRotas).filter(
                          (item) => item !== '/'
                        ),
                      ]}
                    >
                      <Layout>
                        {DashboardRoutes}

                        {VendedoresRoutes}

                        <Operacoes />

                        {CategoriaClientesRoutes}

                        <ProdutosRoutes />

                        {ImportarProdutosRoutes}

                        {TransportadorasRoutes}

                        {MarcasRoutes}

                        {CoresRoutes}

                        {TamanhosRoutes}

                        {PromocaoRoutes}

                        <PromocaoCadastrarRoutes />

                        {LocalEstoqueRoutes}

                        <MovimentacaoEstoqueRoutes />

                        {TransferenciaEstoqueRoutes}

                        {TabelaPrecosRoutes}

                        {HistoricoProdutoRoutes}

                        <ConferenciaEstoqueRoutes />

                        {ContaFinanceiraRoutes}

                        {PlanoContasRoutes}

                        {FormaPagamentoRoutes}

                        {FormaRecebimentoRoutes}

                        {RegrasFiscaisRoutes}

                        {UnidadesMedidaRoutes}

                        {HistoricoAcoesRoutes}

                        {ZoopConfigRoutes}

                        {CategoriaProdutosRoutes}

                        {ClientesRoutes}

                        {ImportarClientesRoutes}

                        {FornecedoresRoutes}

                        {EtiquetaPersonalizadaRoutes}

                        {ImportarFornecedoresRoutes}

                        {NotasFiscaisRoutes}

                        {RelatorioInventarioRoutes}

                        {PerfisUsuariosRoutes}

                        {UsuariosRoutes}

                        {LojasRoutes}

                        {PadronizacaoRoutes}

                        {CamposPersonalizadosRoutes}

                        {DocumentosFiscaisExportacaoRoutes}

                        {EntradaMercadoriaRoutes}

                        {ExtratoRoutes}

                        {RecebimentoContasRoutes}

                        {FaturasRoutes}

                        {RelatorioInformacoesClientes}

                        {ContaPagarRoutes}

                        {ContaReceberRoutes}

                        {FrenteCaixaRoutes}

                        {AutoAtendimentoRoutes}

                        {ControleCaixaRoutes}

                        {ConciliacaoDeContasRoutes}

                        {ManifestacaoDestinatarioRoutes}

                        {RelatorioProdutosPersonalizadosRoutes}

                        {RelatorioClientesPersonalizados}

                        {RelatorioOperacoesRoutes}

                        {MultasEJuros}

                        {RelatorioDeCurvaABC}

                        {MetasComissoesRoutes}

                        {LojaAplicativoRoutes}

                        {VouchersRoutes}
                        {DashboardGerencialRoutes}

                        {SmartPosListarRoutes}

                        {ZoopGerencialRoutes}

                        {PdvAutonomoRoutes}
                        {RelatorioVendasRoutes}

                        {RelatorioEstoqueRoutes}

                        {RelatorioCatalogoProdutosRoutes}

                        {PainelAdmTrayRoutes}

                        {CredenciadoraCartaoRoutes}
                      </Layout>
                    </Route>
                  </PadronizacaoProvider>
                </AtendimentoChatProvider>
              </Route>
            </SignalR>
          </Route>

          <Guard key="404" path="*" component={PaginaNaoExiste} />
        </Switch>
      </GuardProvider>
    </BrowserRouter>
  );
};
export default Routes;
