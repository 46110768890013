import { useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';

import { ImprimirPDF } from 'helpers/impressoes/imprimirPDF';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';

import { FormCustomStandardClientsComponent } from 'components/update/FormCustomStandardClient';
import { ButtonCadastrarNovo } from 'components/Layout/ButtonCadastrarNovo';

import { FormData, yupResolver } from './validationForm';

type CamposPersonalizados = {
  campoPersonalizadoId: string;
  valor: string;
};

export const GerarRelatoriosPersonalizadosClientes = () => {
  const [isLoading, setIsLoading] = useState(false);
  const formMethods = useForm<FormData>({
    resolver: yupResolver,
  });
  const { control } = formMethods;

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    setIsLoading(true);
    const camposPerson = data.camposPersonalizados?.some(
      (item: CamposPersonalizados) => item?.valor
    );

    let camposPersonalizados = !camposPerson
      ? null
      : data.camposPersonalizados
          .filter((item: CamposPersonalizados) => item?.valor)
          .map((values) => ({
            ...values,
            valor: String(values?.valor),
          }));

    camposPersonalizados =
      camposPersonalizados?.length === 0 ? null : camposPersonalizados;

    const parameters = {
      idRelatorioPersonalizado: data.relatorio ?? null,
      nome: data.nome ?? null,
      apelido: data.apelido ?? null,
      categoria: data.categoria === '' ? null : data.categoria,
      cidade: data.cidade === '' ? null : data.cidade,
      email: data.email ?? null,
      observacao: data.observacao ?? null,
      mesNascimento: data.mesNascimento,
      camposPersonalizados,
    };

    const response = await api.post<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.CLIENTE_RELATORIO_PERSONALIZADO,
      parameters
    );

    if (response?.avisos) {
      response.avisos.forEach((avisos) => toast.warning(avisos));
    }

    if (response.sucesso && response.dados) {
      ImprimirPDF(response.dados, 'relatorioPersonalizados');
    }
    setIsLoading(false);
  });

  return (
    <FormProvider {...formMethods}>
      <FormCustomStandardClientsComponent
        type="Personalizados"
        control={control}
        Loading={isLoading}
      />
      <Flex
        justifyContent="end"
        alignItems="flex-end"
        mt={{ base: '6', md: '6', lg: '10' }}
      >
        <ButtonCadastrarNovo
          exibirIcone={false}
          onClick={handleSubmit}
          funcionalidade=""
          label="Gerar relatório"
        />
      </Flex>
    </FormProvider>
  );
};
