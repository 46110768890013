import React, { useState, useCallback, ReactNode } from 'react';
import { NamedProps } from 'react-select';
import { toast } from 'react-toastify';

import AsyncSelect from 'components/PDV/Select/AsyncSelectPadrao';
import { CampoPrototipoProps } from 'components/PDV/Geral/CampoPrototipo';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import OptionType from 'types/optionType';

export interface NCMInterface {
  descricao: string;
  codigo: string;
}

export type AsyncSelectInputProps = Omit<NamedProps<OptionType>, 'ref'> & {
  isDisabled?: boolean;
  withoutDefaultOptions?: boolean;
  variant?: string;
  size?: string;
  closeMenuOnSelect?: boolean;
  shouldAppearTheAddress?: boolean;
};

export type AsyncSelectFieldProps = Omit<AsyncSelectInputProps, 'disabled'> &
  Omit<CampoPrototipoProps, 'disabled'> & {
    onChangeEffect?: (option: OptionType | Array<OptionType> | null) => void;
    withoutDefaultOptions?: boolean;
    asControlledByObject?: boolean;
    onSelect?: () => void;
    topRightElement?: ReactNode;
    isLeitorCodigoBarras?: boolean;
    asMobileView?: boolean;
    width?: string;
    fontWeightLabel?: string;
    errorText?: string;
    errorPropName?: string;
    setValor?: React.Dispatch<React.SetStateAction<any>>;
  };

export const SelectNcm = ({
  id,
  name,
  label,
  required,
  components,
  asControlledByObject,
  defaultValue,
  styles,
  placeholder = 'Digite o nome da Nomenclatura Comum do Mercosul',
  isLoading: isLoadingProp,
  isDisabled,
  errorText,
  errorPropName,
  ...rest
}: AsyncSelectFieldProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const getOptionsNcm = useCallback(async (inputValue: string) => {
    setIsLoading(true);
    if (inputValue.length > 2) {
      const response = await api.get<void, ResponseApi<NCMInterface[]>>(
        ConstanteEnderecoWebservice.NCM_LISTAR_SELECT,
        {
          params: { descricaoCodigo: inputValue },
        }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }

        if (response.sucesso) {
          setIsLoading(false);
          return response?.dados.map((ncm: NCMInterface) => {
            return {
              label: `${ncm.codigo} - ${ncm.descricao}`,
              value: ncm.codigo,
            };
          });
        }
      }
      setIsLoading(false);
      return [];
    }

    setIsLoading(false);
    return [];
  }, []);

  return (
    <AsyncSelect
      {...rest}
      id={name}
      name={name}
      isLoading={isLoading}
      placeholder={placeholder}
      required
      label={label}
      asControlledByObject={asControlledByObject}
      handleGetOptions={getOptionsNcm}
      errorPropName={errorPropName}
      errorText={errorText}
    />
  );
};
