import React, { useMemo, useState, useCallback, useRef } from 'react';
import { Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Icon } from '@chakra-ui/react';

import auth from 'modules/auth';
import api, { ResponseApi } from 'services/api';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import getOptionsByEnum from 'helpers/format/getOptionsByEnum';
import StatusConsultaEnum from 'constants/enum/statusConsulta';

import ConstanteFuncionalidades from 'constants/permissoes';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import { SalvarInserirNovoIcon } from 'icons';
import { ButtonFuncionalidade } from 'components/update/Button/ButtonFuncionalidade';
import { SearchInput } from 'components/update/Input/SearchInput';
import SelectBusca from 'components/Select/SelectBusca';
import ManterFoco from 'components/Geral/ManterFoco';
import ValidarPermissao from 'components/Validacao/ValidarPermissao';
import { HeaderData } from 'components/Grid/Cabecalho';
import ButtonComOpcoes from 'components/Button/ButtonComOpcoes';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { ModalConfirmacaoInativar } from 'components/Modal/ModalConfirmacaoInativar';
import Paginacao, {
  ForwardRefMethod,
  GridPaginadaConsulta,
  GridPaginadaRetorno,
} from 'components/Grid/Paginacao';

import {
  AlignRight,
  ContentContainer,
  StatusIcon,
  LinkVisualizar,
  FiltrosCol,
} from 'styles';

interface RegrasFiscaisFiltros {
  nome: string;
  status: number;
}

interface RegraFiscal {
  id: string;
  nome: string;
  ativo: boolean;
}

const Listar = () => {
  const history = useHistory();
  const isMountedRef = useIsMountedRef();
  const { t } = useTranslation();

  const formMethods = useForm({
    defaultValues: { nome: '', status: StatusConsultaEnum.ATIVOS },
  });
  const { control, getValues, setFocus } = formMethods;

  const [isLoading, setIsLoading] = useState(false);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [regrasFiscais, setRegrasFiscais] = useState<RegraFiscal[]>([]);

  const pageIsLoaded = useRef(false);
  const childRef = useRef<ForwardRefMethod>();
  const filtrosAtuais = useRef<RegrasFiscaisFiltros>(
    {} as RegrasFiscaisFiltros
  );

  const permissaoRegraFiscalAlterar = useMemo(
    () => auth.possuiPermissao(ConstanteFuncionalidades.REGRA_FISCAL_ALTERAR),
    []
  );

  const handleHistoryPush = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history]
  );

  const handleReset = useCallback(
    (orderColumn: string) => {
      const filtros = getValues();

      if (JSON.stringify(filtros) !== JSON.stringify(filtrosAtuais.current)) {
        return childRef.current?.reset(orderColumn);
      }

      return () => {};
    },
    [getValues]
  );

  const handleRefresh = useCallback((newTotal?: number) => {
    return childRef.current?.refresh(newTotal);
  }, []);

  const paginationHandle = useCallback(
    async (gridPaginadaConsulta: GridPaginadaConsulta) => {
      setIsLoading(true);

      const filtros = getValues();

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<RegraFiscal>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.REGRA_FISCAL_LISTAR_PAGINADO,
          gridPaginadaConsulta
        ),
        { params: { nome: filtros.nome, status: filtros.status } }
      );

      if (response?.sucesso && isMountedRef.current) {
        setTotalRegistros(response.dados.total);
        setRegrasFiscais(response.dados.registros);

        filtrosAtuais.current = filtros;
      }

      if (isMountedRef.current) {
        setIsLoading(false);

        if (!pageIsLoaded.current) {
          pageIsLoaded.current = true;

          setFocus('nome');
        }
      }
    },
    [getValues, isMountedRef, setFocus]
  );

  const handleExcluirRegraFiscal = useCallback(
    async (regraFiscalId: string, ativo: boolean) => {
      ModalConfirmacaoExcluir({
        callback: async (ok: boolean) => {
          if (ok) {
            setIsLoading(true);

            const response = await api.delete<void, ResponseApi>(
              ConstanteEnderecoWebservice.REGRA_FISCAL_EXCLUIR,
              {
                params: { id: regraFiscalId },
              }
            );

            if (response?.sucesso) {
              toast.success(t('O cadastro foi removido com sucesso.'));

              handleRefresh(totalRegistros - 1);
            }

            if (response) {
              ModalConfirmacaoInativar({
                response,
                rotaWebService:
                  ConstanteEnderecoWebservice.REGRA_FISCAL_INATIVAR,
                id: regraFiscalId,
                ativo,
                callback: (okInativar: boolean) => {
                  if (okInativar) handleRefresh(totalRegistros - 1);
                },
              });
            }

            setIsLoading(false);
          }
        },
      });
    },
    [handleRefresh, t, totalRegistros]
  );

  const headerData: HeaderData = useMemo(() => {
    return {
      orderColumn: 'Nome',
      orderDirection: 'asc',
      columns: [
        {
          displayName: <StatusIcon style={{ color: 'var(--black)' }} />,
          name: 'Ativo',
          isOrderable: true,
          width: '37px',
          alignText: 'center',
        },
        {
          displayName: t('Nome'),
          name: 'Nome',
          isOrderable: true,
          width: 'auto',
        },
        {
          displayName: t('Ações'),
          name: 'Acoes',
          isOrderable: false,
          width: '38px',
        },
      ],
    };
  }, [t]);

  const RegrasFiscaisList = useMemo(() => {
    return (
      <Paginacao
        ref={childRef}
        total={totalRegistros}
        paginationHandle={paginationHandle}
        isLoading={isLoading}
        headerData={headerData}
      >
        {regrasFiscais.map((regraFiscal) => (
          <tr key={regraFiscal.id}>
            <td style={{ textAlign: 'center' }}>
              <StatusIcon
                color={
                  regraFiscal.ativo
                    ? 'var(--sti-ck-colors-green-500)'
                    : 'var(--sti-ck-colors-red-500'
                }
              />
            </td>
            <td>
              <ValidarPermissao
                funcionalidade={
                  ConstanteFuncionalidades.REGRA_FISCAL_VISUALIZAR
                }
                tooltipWrap="hidden"
              >
                {({ permitido }) => (
                  <LinkVisualizar
                    id="link-visualizar"
                    bloqueado={permitido ? 0 : 1}
                    to={
                      permitido
                        ? SubstituirParametroRota(
                            permissaoRegraFiscalAlterar.permitido
                              ? ConstanteRotas.REGRA_FISCAL_ALTERAR
                              : ConstanteRotas.REGRA_FISCAL_VISUALIZAR,
                            'id',
                            regraFiscal.id
                          )
                        : ''
                    }
                  >
                    {regraFiscal.nome}
                  </LinkVisualizar>
                )}
              </ValidarPermissao>
            </td>
            <td>
              <ButtonComOpcoes
                id="mostrarMais"
                dropdownItems={[
                  {
                    title: t('Editar'),
                    onClick: () => {
                      handleHistoryPush(
                        SubstituirParametroRota(
                          ConstanteRotas.REGRA_FISCAL_ALTERAR,
                          'id',
                          regraFiscal.id
                        )
                      );
                    },
                    funcionalidadePermissao:
                      ConstanteFuncionalidades.REGRA_FISCAL_ALTERAR,
                  },
                  {
                    title: t('Remover'),
                    onClick: () => {
                      handleExcluirRegraFiscal(
                        regraFiscal.id,
                        regraFiscal.ativo
                      );
                    },
                    funcionalidadePermissao:
                      ConstanteFuncionalidades.REGRA_FISCAL_EXCLUIR,
                  },
                ]}
              />
            </td>
          </tr>
        ))}
      </Paginacao>
    );
  }, [
    handleExcluirRegraFiscal,
    handleHistoryPush,
    headerData,
    isLoading,
    paginationHandle,
    permissaoRegraFiscalAlterar,
    t,
    totalRegistros,
    regrasFiscais,
  ]);

  return (
    <ContentContainer>
      <FormProvider {...formMethods}>
        <ManterFoco>
          <Row>
            <FiltrosCol xs={12} sm={5} lg={6}>
              <SearchInput
                type="search"
                onEnterKeyPress={() => handleReset('Nome')}
                placeholder={t('Buscar a regra fiscal pelo nome')}
                isDisabled={isLoading}
                id="nome"
                name="nome"
              />
            </FiltrosCol>
            <FiltrosCol xs={0}>
              <SelectBusca
                id="status"
                name="status"
                defaultValue={StatusConsultaEnum.ATIVOS}
                isDisabled={isLoading}
                control={control}
                options={getOptionsByEnum(StatusConsultaEnum)}
                onSelect={() => {
                  handleReset('Nome');
                }}
              />
            </FiltrosCol>
            <AlignRight as={FiltrosCol} xs={12} sm={4} md="auto">
              <ButtonFuncionalidade
                id="cadastrarNovo"
                name="cadastrarNovo"
                colorScheme="secondary"
                color="gray.700"
                borderRadius="md"
                minW={{ base: 'full', md: '250px' }}
                leftIcon={<Icon as={SalvarInserirNovoIcon} />}
                type="button"
                onClick={() =>
                  handleHistoryPush(ConstanteRotas.REGRA_FISCAL_CADASTRAR)
                }
                funcionalidade={ConstanteFuncionalidades.REGRA_FISCAL_CADASTRAR}
              >
                {t('Cadastrar Novo')}
              </ButtonFuncionalidade>
            </AlignRight>
          </Row>
        </ManterFoco>
      </FormProvider>
      {RegrasFiscaisList}
    </ContentContainer>
  );
};

export default Listar;
