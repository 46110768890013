import React from 'react';
import { PropsWithMeta } from 'react-router-guards/dist/types';
import { GuardedRoute as Guard, BaseGuardProps } from 'react-router-guards';
import { RouteProps } from 'react-router-dom';

import auth from 'modules/auth';
import { useLayoutContext, BreadcrumbInterface } from 'store/Layout';

import SemPermissao from 'pages/SemPermissao';
import SemPermissaoPlano from 'pages/SemPermissaoPlano';

export interface LayoutGuardInterface
  extends PropsWithMeta<BaseGuardProps & RouteProps> {
  permissaoFuncionalidade?: string;
  key: string;
  breadcrumb?: BreadcrumbInterface[];
  isDisabled?: boolean;
}

const LayoutGuard = ({
  permissaoFuncionalidade,
  breadcrumb,
  component,
  render,
  isDisabled = false,
  ...rest
}: LayoutGuardInterface) => {
  const { setBreadcrumb } = useLayoutContext();

  let Component:
    | typeof component
    | typeof SemPermissao
    | typeof SemPermissaoPlano;

  if (!auth.autenticado()) {
    Component = SemPermissao;
  } else if (permissaoFuncionalidade || isDisabled) {
    const permissao = auth.possuiPermissao(permissaoFuncionalidade || '');

    if (permissao.permitido) {
      Component = component;
    } else if (permissao.bloqueio === 'plano' || isDisabled) {
      // Component = SemPermissaoPlano;
      Component = SemPermissao;
    } else {
      Component = SemPermissao;
    }
  } else {
    Component = component;
  }

  return (
    <Guard
      render={(props) => {
        if (breadcrumb && breadcrumb.length > 0) {
          setBreadcrumb(breadcrumb);
        }

        if (render && Component === component) {
          return render(props);
        }

        if (Component) {
          return <Component {...props} />;
        }

        return null;
      }}
      {...rest}
    />
  );
};

export default LayoutGuard;
