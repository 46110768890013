import React from 'react';
import { GuardedRoute as Guard, GuardedRouteProps } from 'react-router-guards';
import ConstanteRotas from '../constants/rotas';
import DownloadExterno from '../pages/DocumentoFiscalExportacao/DownloadExterno';

interface GuardRoutesProps extends GuardedRouteProps {
  key: string;
  path: string;
}

export const documentosFiscaisExportacaoExternoRoutes: GuardRoutesProps[] = [
  {
    key: 'documento-fiscal-exportacao-externo',
    component: DownloadExterno,
    path: ConstanteRotas.DOCUMENTO_FISCAL_EXPORTACAO_DOWNLOAD_EXTERNO,
  },
];

const DocumentosFiscaisExportacaoExternoRoutes = () => {
  return (
    <>
      {documentosFiscaisExportacaoExternoRoutes.map(({ key, ...rest }) => (
        <Guard key={key} {...rest} />
      ))}
    </>
  );
};

export default DocumentosFiscaisExportacaoExternoRoutes;
