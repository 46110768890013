import {
  ModalContent,
  ModalBody,
  Flex,
  ModalProps,
  Button,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import VirtualizedList from 'components/update/VirtualizedList';
import {
  formatDate,
  formatDateHourMinute,
} from 'helpers/format/formatStringDate';
import { enumIdentificacaoSituacao } from 'constants/enum/enumIdentificacaoSituacao';

type ModalHistoricoAcoesProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalProps> & {
    listAcoes: {
      situacao: number;
      dataHora: string;
    }[];
  };

export const ModalHistoricoAcoes = create<ModalHistoricoAcoesProps>(
  ({ listAcoes }) => {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
    return (
      <ModalPadraoChakra
        onClose={() => {
          onClose();
        }}
        isOpen={isOpen}
        isCentered
        size="2xl"
        autoFocus={false}
      >
        <ModalContent
          background="gray.50"
          minH={['full', '528px']}
          width={['full', '640px']}
        >
          <ModalBody p={0}>
            <Flex flexDirection="column">
              <Text
                px="40px"
                pt="24px"
                pb="12px"
                color="primary.50"
                fontSize="20px"
                fontWeight="600"
              >
                Histórico de ações
              </Text>

              <Flex
                justifyContent="flex-start"
                flexDirection="column"
                pl="40px"
                pr="38px"
              >
                <VirtualizedList
                  containerHeight={300}
                  itemHeight={48}
                  noItemsMessage="Nenhuma ação realizada"
                  items={listAcoes}
                  colorBgGradient="white"
                  quantidadeItems={listAcoes?.length}
                  key="virtualizedListAdicionarTamanhos"
                  sx={{
                    maxHeight: '360px',
                  }}
                  render={(items) => (
                    <>
                      {items?.map((acao) => {
                        return (
                          <Flex
                            gap="64px"
                            height="48px"
                            bg="white"
                            borderRadius="5px"
                            boxShadow="0px 0px 4px #00000029"
                            mb="4px"
                            justify="flex-start"
                            align="center"
                            pl="24px"
                          >
                            <Text fontSize="14px" color="gray.700">
                              {formatDate(acao.dataHora)} -{' '}
                              {formatDateHourMinute(acao.dataHora).slice(11)}h
                            </Text>
                            <Text fontSize="14px" color="gray.700">
                              {acao.situacao ===
                              enumIdentificacaoSituacao.EM_ABERTO
                                ? 'Em aberto'
                                : enumIdentificacaoSituacao.options.find(
                                    (item) => item.value === acao.situacao
                                  )?.label}
                            </Text>
                          </Flex>
                        );
                      })}
                    </>
                  )}
                />
              </Flex>
            </Flex>
          </ModalBody>
          <Flex display="flex" justifyContent="center" gap="24px" mt="8px">
            <Button
              onClick={() => {
                onClose();
              }}
              variant="solid"
              color="white"
              colorScheme="purple"
              fontWeight="500"
              borderRadius="20px"
              height="40px"
              fontSize="16px"
              mb="40px"
              w={['full', '200px']}
            >
              Fechar
            </Button>
          </Flex>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
