import React from 'react';

import { IconType } from 'icons';
import GenIcon from 'icons/GenIcon';

export const ZendarFullLogo: IconType = ({ size, color, ...props }) => (
  <GenIcon viewBox="0 0 87.1 23.4" size={size} {...props}>
    <g style={{ fill: color || 'currentcolor' }}>
      <g>
        <path d="M22.6.8H19.2l-1.1,2h0L17.5,4h0L7,23.2h3.4L19.5,6.6A9.3,9.3,0,0,1,20.6,11a9.5,9.5,0,0,1-6.4,8.9l-.9,1.7A10.8,10.8,0,0,0,21.9,11a10.4,10.4,0,0,0-1.7-5.8Z" />
        <path d="M11.9,4.2,4.5,17.8,3.3,16.4,8.4,6.9H5.1l-3.3,6A12.3,12.3,0,0,1,1.6,11a9.6,9.6,0,0,1,9.5-9.5A9.1,9.1,0,0,1,16,2.9l.7-1.2A10.7,10.7,0,0,0,11.1.2,10.8,10.8,0,0,0,.3,11a10.9,10.9,0,0,0,.6,3.6h0a8.8,8.8,0,0,0,.9,2h0l.2.3h.1c0,.1.1.2.2.3h0l.3.4h0A13.1,13.1,0,0,0,5,20H5l.3.2H6l.3.2L7,19.5,15.3,4.2Z" />
      </g>
      <g>
        <path d="M26.9,7.5V6.6H36V9l-5.9,6.2h6.1v2.4H26.6V15.4l5.9-6.5H28.4A1.5,1.5,0,0,1,26.9,7.5Z" />
        <path d="M41.9,17.9c-3.3-.1-5-2.1-5-5.8S38.6,6.5,42,6.5s5.1,2.1,4.9,6.4H39.8c.1,1.7.8,2.5,2.2,2.5a2.4,2.4,0,0,0,1.8-.8,1.3,1.3,0,0,1,.9-.4h2.1C46.2,16.6,44.7,17.9,41.9,17.9Zm-2.1-7H44c-.2-1.4-.9-2.2-2.1-2.2S40,9.5,39.8,10.9Z" />
        <path d="M56.2,17.6c-.9-.1-1.3-.5-1.3-1.4V10.6c-.1-1.2-.7-1.7-2-1.7s-1.8.5-1.8,1.7v7H48.2V11.1c0-3.1,1.6-4.6,4.8-4.6s4.7,1.5,4.7,4.6v6.5Z" />
        <path d="M69.1,4.9v8c0,3.3-1.7,4.9-5.2,4.9s-5.1-1.9-5.1-5.6,1.4-5.6,4.4-5.7a3.3,3.3,0,0,1,3.1,1.7V3.5h1.4A1.4,1.4,0,0,1,69.1,4.9Zm-7.5,7.2q0,3.3,2.4,3.3c1.6,0,2.3-1.1,2.3-3.3S65.5,8.9,64,8.9,61.7,10,61.6,12.1Z" />
        <path d="M73.2,9.5H70.4c.4-2,1.8-3,4.4-3s4.6,1,4.6,3v3.7c0,3.1-1.8,4.4-4.8,4.6s-4.5-1-4.5-3.4,2-3.4,4.8-3.6c1.2-.2,1.8-.5,1.8-1.1s-.7-1-1.8-1S73.4,9,73.2,9.5Zm3.5,3.6v-1a7.1,7.1,0,0,1-2.2.6c-1.1.3-1.7.8-1.7,1.6a1.3,1.3,0,0,0,1.4,1.3A2.3,2.3,0,0,0,76.7,13.1Z" />
        <path d="M80.8,11.9c0-3.5,1.9-5.3,5.6-5.3h.4V9.5h-.9a2.1,2.1,0,0,0-2.3,2.4v5.7H80.8Z" />
      </g>
    </g>
  </GenIcon>
);

export const ZendarSymbol: IconType = ({ size, color, ...props }) => (
  <GenIcon viewBox="0 0 22.8 23.4" size={size} {...props}>
    <g style={{ fill: color || 'currentcolor' }}>
      <path d="M22.6.8H19.2l-1.1,2h0L17.4,4h0L6.9,23.2h3.4L19.4,6.6A9.3,9.3,0,0,1,20.5,11a9.4,9.4,0,0,1-6.4,9l-.9,1.6A10.8,10.8,0,0,0,21.9,11a10.6,10.6,0,0,0-1.7-5.8Z" />
      <path d="M11.9,4.2,4.4,17.9a15.8,15.8,0,0,1-1.2-1.5L8.4,6.9H5l-3.3,6A11.1,11.1,0,0,1,1.5,11,9.6,9.6,0,0,1,11,1.5a9.7,9.7,0,0,1,5,1.4l.6-1.2A10.5,10.5,0,0,0,11,.1,10.9,10.9,0,0,0,.1,11a10.5,10.5,0,0,0,.7,3.6h0a7.2,7.2,0,0,0,.9,2h0c.1.1.2.2.2.3H2c0,.1.1.2.2.3h0l.3.3h0A10.4,10.4,0,0,0,4.9,20h0l.3.2h.1c0,.1.1.1.2.2h.1c.1.1.2.1.2.2h.1l.3.2.7-1.2L15.3,4.2Z" />
    </g>
  </GenIcon>
);

export const ZendarPDVLogo: IconType = ({ size, color, ...props }) => (
  <GenIcon viewBox="0 0 139 22.2" size={size} {...props}>
    <g style={{ fill: color || 'currentcolor' }}>
      <g>
        <path d="M21.9.7H18.6l-1,1.9h0L17,3.8h0L7,22.1h3.2L18.9,6.2a9.2,9.2,0,0,1-3.8,12.3l-1.3.5L13,20.6A10.4,10.4,0,0,0,21,8.3a10.2,10.2,0,0,0-1.4-3.4Z" />
        <path d="M11.7,4,4.6,17a5.3,5.3,0,0,1-1.2-1.4l4.9-9H5.1L2,12.3A9.1,9.1,0,0,1,15.6,2.7l.6-1.1A10.3,10.3,0,0,0,2,5.1a10.5,10.5,0,0,0-.9,8.8h0A6.6,6.6,0,0,0,2,15.8H2l.2.2h0c.1.1.2.2.2.3h.1c.1.1.1.3.2.4h0A12.1,12.1,0,0,0,5.1,19h0l.2.2h.1l.2.2h.1l.2.2H6l.3.2.6-1.2L14.9,4Z" />
      </g>
      <g>
        <path d="M29.8,6.8V5.9h8.4V8.1l-5.4,5.8h5.6v2.3H29.5V14.1l5.5-6H31.1a1.3,1.3,0,0,1-1.3-1.3Z" />
        <path d="M43.8,16.4c-3.1-.1-4.7-1.9-4.7-5.4s1.6-5.2,4.7-5.2,4.8,2,4.6,6H41.8c.1,1.5.7,2.3,2,2.3a2.3,2.3,0,0,0,1.7-.7,1.2,1.2,0,0,1,.8-.4h1.9A4.1,4.1,0,0,1,43.8,16.4Zm-2-6.5h3.9c-.2-1.3-.8-2-1.9-2S42,8.6,41.8,9.9Z" />
        <path d="M57.1,16.2c-.9-.1-1.3-.5-1.3-1.3V9.6A1.6,1.6,0,0,0,54,8.1c-1.2,0-1.7.5-1.8,1.5v6.6H49.6v-6c0-3,1.5-4.4,4.4-4.4s4.4,1.4,4.4,4.4v6Z" />
        <path d="M69,4.3v7.4c0,3.1-1.6,4.6-4.8,4.6s-4.8-1.8-4.8-5.2,1.4-5.2,4.1-5.3a3.1,3.1,0,0,1,3,1.6V3h1.2A1.3,1.3,0,0,1,69,4.3ZM62.1,11c0,2.1.7,3.1,2.2,3.1s2.2-1,2.2-3.1-.8-2.9-2.2-2.9-2.1,1-2.2,2.9Z" />
        <path d="M72.9,8.6H70.3c.3-1.9,1.6-2.8,4-2.8s4.3.9,4.3,2.8v3.5c0,2.8-1.7,4.1-4.5,4.3s-4.1-1-4.1-3.2,1.8-3.2,4.4-3.4c1.1-.1,1.7-.5,1.7-1s-.6-.9-1.7-.9S73,8.1,72.9,8.6ZM76.1,12V11a6.6,6.6,0,0,1-2,.6c-1.1.2-1.6.7-1.6,1.5a1.3,1.3,0,0,0,1.1,1.2h.2a2.1,2.1,0,0,0,2.3-1.9V12Z" />
        <path d="M79.9,10.9c.1-3.3,1.8-5,5.3-5h.3V8.6h-.8a1.9,1.9,0,0,0-2.2,1.7v5.9H79.9Z" />
      </g>
      <g>
        <path d="M109.8,18.9a1.1,1.1,0,0,1-.9,1.3h-1.6V10.4c.1-3.1,1.7-4.6,4.8-4.6s4.8,1.8,4.8,5.2-1.4,5.3-4.1,5.4a3.5,3.5,0,0,1-3-1.6Zm2.2-4.8c1.4,0,2.2-1,2.2-3S113.5,8,112,8s-2.2,1-2.2,3.1.8,3,2.2,3Z" />
        <path d="M127.5,4.3v7.4c0,3.1-1.6,4.6-4.8,4.6s-4.8-1.8-4.8-5.2,1.4-5.2,4.1-5.3a3.1,3.1,0,0,1,3,1.6V3h1.3A1.2,1.2,0,0,1,127.5,4.3ZM120.6,11c0,2.1.7,3.1,2.2,3.1s2.2-1,2.2-3.1-.8-2.9-2.2-2.9-2.1,1-2.2,2.9Z" />
        <path d="M138.5,5.9l-3.6,10.3h-2.8L128.5,5.9h2.9l2,7.2,1.8-6.2a1.4,1.4,0,0,1,1.3-1Z" />
      </g>
      <path d="M97,.1h-.3c-.8,0-1.2.4-1.2,1.1V2.8h0V21.1h.3c.8,0,1.2-.4,1.2-1.1Z" />
    </g>
  </GenIcon>
);
