import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Flex, FormLabel, GridItem } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import StatusConsultaEnum from 'constants/enum/statusConsulta';
import TipoTabelaPrecoEnum from 'constants/enum/tipoTabelaPrecoEnum';
import api, { ResponseApi } from 'services/api';
import { enumCatalogoProdutos } from 'constants/enum/enumCatalogoProdutos';
import { ImprimirPDF } from 'helpers/impressoes/imprimirPDF';
import TipoFiltroProdutoEstoqueEnum from 'constants/enum/tipoFiltroProdutoEstoque';

import { SelectCategoria } from 'components/Select/SelectCategoria';
import { SelectMulti } from 'components/Select/SelectMultiCheckbox';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import Input from 'components/PDV/Input';
import {
  OptionsProps,
  OptionsResponseProps,
} from 'pages/Produtos/Listar/validationForm';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

type FormData = {
  cores?: string[];
  tipoRelatorio: number;
  tamanhos?: string[];
  marcas?: string[];
  categoriasProduto?: string[];
  tipoEstoque?: number;
  tags?: string[];
  nomeSkuCodigoExternoBarrasGtinEan?: string;
  statusConsulta?: number;
};

const formDefaultValue = {
  tipoRelatorio: enumCatalogoProdutos.CATALOGO_PRODUTOS,
  tipoEstoque: TipoFiltroProdutoEstoqueEnum.TODOS,
  statusConsulta: StatusConsultaEnum.ATIVOS,
  tabelaPrecoId: null,
};

export const CatalogoProdutos = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [cores, setCores] = useState<OptionsProps[]>([]);
  const [coresIsLoading, setCoresIsLoading] = useState(true);
  const [tamanhos, setTamanhos] = useState<OptionsProps[]>([]);
  const [tamanhosIsLoading, setTamanhosIsLoading] = useState(true);
  const [marcas, setMarcas] = useState<OptionsProps[]>([]);
  const [marcasIsLoading, setMarcasIsLoading] = useState(true);
  const [tags, setTags] = useState<OptionsProps[]>([]);
  const [tagsIsLoading, setTagsIsLoading] = useState(false);
  const [tabelaPrecoId, setTabelaPrecoId] = useState<OptionsProps[]>([]);

  const tabelaPrecoOpcaoSemPreco = [
    {
      label: 'Sem preço',
      value: null,
    },
  ];

  const formMethods = useForm<FormData>({
    defaultValues: formDefaultValue,
  });

  const dadosAPi = useCallback(
    (
      response: ResponseApi<OptionsResponseProps[]>,
      setStateValue: (value: React.SetStateAction<OptionsProps[]>) => void
    ) => {
      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }

        if (response.sucesso) {
          setStateValue(
            response.dados.map((item) => {
              return {
                label: item.nome,
                value: item.id,
              };
            })
          );
        }
      }
    },
    []
  );

  const getTags = useCallback(async () => {
    setTagsIsLoading(true);
    const response = await api.get<void, ResponseApi<OptionsResponseProps[]>>(
      ConstanteEnderecoWebservice.TAG_LISTAR_SELECT
    );

    await dadosAPi(response, setTags);
    setTagsIsLoading(false);
  }, [dadosAPi]);

  const getCores = useCallback(async () => {
    setCoresIsLoading(true);
    const response = await api.get<void, ResponseApi<OptionsResponseProps[]>>(
      ConstanteEnderecoWebservice.COR_LISTAR_SELECT,
      {
        params: {
          statusConsulta: StatusConsultaEnum.TODOS,
          listarPadraoSistema: true,
        },
      }
    );

    await dadosAPi(response, setCores);
    setCoresIsLoading(false);
  }, [dadosAPi]);

  const getTamanhos = useCallback(async () => {
    setTamanhosIsLoading(true);
    const response = await api.get<void, ResponseApi<OptionsResponseProps[]>>(
      ConstanteEnderecoWebservice.TAMANHO_LISTAR_SELECT,
      {
        params: {
          statusConsulta: StatusConsultaEnum.TODOS,
          listarPadraoSistema: true,
        },
      }
    );

    await dadosAPi(response, setTamanhos);
    setTamanhosIsLoading(false);
  }, [dadosAPi]);

  const getMarcas = useCallback(async () => {
    setMarcasIsLoading(true);
    const response = await api.get<void, ResponseApi<OptionsResponseProps[]>>(
      ConstanteEnderecoWebservice.MARCA_LISTAR_SELECT,
      {
        params: {
          statusconsulta: 1,
        },
      }
    );
    await dadosAPi(response, setMarcas);
    setMarcasIsLoading(false);
  }, [dadosAPi]);

  const getTabelasPreco = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get<void, ResponseApi<OptionsResponseProps[]>>(
      `${ConstanteEnderecoWebservice.TABELA_PRECO_LISTAR_TABELAS_PRECO}?tipo=${TipoTabelaPrecoEnum.COMUM}`
    );
    await dadosAPi(response, setTabelaPrecoId);
    setIsLoading(false);
  }, [dadosAPi]);

  const handleImprimirRelatorioCatalogoProdutos = formMethods.handleSubmit(
    async (filtros) => {
      setIsLoading(true);

      const response = await api.post<void, ResponseApi<string>>(
        ConstanteEnderecoWebservice.RELATORIO_CATALOGO_PRODUTOS,
        {
          ...filtros,
          categoriasProduto: filtros.categoriasProduto ?? [],
        }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }
        if (response.sucesso && response.dados) {
          ImprimirPDF(response.dados, 'relatorioCatalogoProdutos');
        }
      }

      setIsLoading(false);
    }
  );

  useEffect(() => {
    getCores();
  }, [getCores]);

  useEffect(() => {
    getMarcas();
  }, [getMarcas]);

  useEffect(() => {
    getTamanhos();
  }, [getTamanhos]);

  useEffect(() => {
    getTags();
  }, [getTags]);

  useEffect(() => {
    getTabelasPreco();
  }, [getTabelasPreco]);

  return (
    <FormProvider {...formMethods}>
      {isLoading && <LoadingPadrao />}
      <SimpleGridForm
        bg="white"
        p={['20px', '20px', '40px']}
        borderRadius="5px"
        boxShadow="0px 0px 6px #00000034"
      >
        <GridItem colSpan={[12, 6, 6, 3]}>
          <SelectPadrao
            name="tipoRelatorio"
            label="Relatório"
            id="relatorio"
            placeholder="Selecione o relatório"
            options={enumCatalogoProdutos.properties}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 6, 3]}>
          <SelectPadrao
            id="statusConsulta"
            name="statusConsulta"
            label="Status"
            placeholder="Selecione um status"
            options={StatusConsultaEnum.status}
            isClearable={false}
          />
        </GridItem>
        <GridItem colSpan={[12, 12, 12, 6]}>
          <FormLabel mb="1px" fontSize="sm" color="black" lineHeight="1.2">
            Categoria
          </FormLabel>
          <SelectCategoria name="categoriasProduto" />
        </GridItem>
        <GridItem colSpan={[12, 12, 12, 6]}>
          <Input
            mb="0.5"
            label="Produto"
            placeholder="Digite o nome ou referência"
            id="nomeSkuCodigoExternoBarrasGtinEan"
            name="nomeSkuCodigoExternoBarrasGtinEan"
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 6, 3]}>
          <SelectPadrao
            id="tipoEstoque"
            name="tipoEstoque"
            label="Estoque"
            placeholder="Selecione um tipo de estoque"
            isClearable={false}
            options={TipoFiltroProdutoEstoqueEnum.produtos}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 6, 3]}>
          <SelectPadrao
            id="tabelaPrecoId"
            name="tabelaPrecoId"
            label="Tabela de preço"
            placeholder="Selecione uma tabela de preço"
            options={tabelaPrecoId.concat(tabelaPrecoOpcaoSemPreco)}
            isClearable={false}
          />
        </GridItem>
        <GridItem colSpan={[12, 12, 12, 6]}>
          <SelectMulti
            name="cores"
            textLabelSelectAll="Todas as cores"
            id="cores"
            label="Cores"
            isLoading={coresIsLoading}
            placeholder="Digite a cor"
            variant="outline"
            options={cores}
            isMulti
            isSearchable={false}
            closeMenuOnSelect={false}
          />
        </GridItem>
        <GridItem colSpan={[12, 12, 12, 6]}>
          <SelectMulti
            name="tamanhos"
            textLabelSelectAll="Todos os tamanhos"
            isLoading={tamanhosIsLoading}
            id="tamanhos"
            label="Tamanhos"
            placeholder="Digite o tamanho"
            variant="outline"
            options={tamanhos}
            isMulti
            isSearchable={false}
            closeMenuOnSelect={false}
          />
        </GridItem>
        <GridItem colSpan={[12, 12, 12, 6]}>
          <SelectMulti
            name="marcas"
            textLabelSelectAll="Todas as marcas"
            id="marcas"
            label="Marcas"
            isLoading={marcasIsLoading}
            placeholder="Digite a marca"
            variant="outline"
            options={marcas}
            isMulti
            isSearchable={false}
            closeMenuOnSelect={false}
          />
        </GridItem>
        <GridItem colSpan={[12, 12, 12, 6]}>
          <SelectMulti
            name="tags"
            textLabelSelectAll="Todas as tags"
            id="tags"
            label="Tags"
            isLoading={tagsIsLoading}
            placeholder="Digite a tag"
            variant="outline"
            options={tags}
            isMulti
            isSearchable={false}
            closeMenuOnSelect={false}
          />
        </GridItem>
      </SimpleGridForm>
      <Flex
        mt={['20px', '20px', '40px']}
        justifyContent="flex-end"
        alignItems="center"
      >
        <Button
          h="39px"
          color="gray.700"
          colorScheme="secondary"
          borderRadius="md"
          isLoading={isLoading}
          onClick={() => handleImprimirRelatorioCatalogoProdutos()}
          width={{ base: 'full', md: '220px' }}
        >
          Gerar relatório
        </Button>
      </Flex>
    </FormProvider>
  );
};
