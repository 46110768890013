import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteRotas from 'constants/rotas';

import { FormData } from './validationForm';

const useCredenciadoraCartaoForm = () => {
  const history = useHistory();

  const codigoSatPadrao = '999';

  const obterCredenciadoraCartao = useCallback(async (id: string) => {
    const response = await api.get<void, ResponseApi<FormData>>(
      ConstanteEnderecoWebservice.CREDENCIADORA_CARTAO_OBTER,
      {
        params: { id },
      }
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response?.sucesso && response?.dados) {
        return response.dados;
      }
    }

    return undefined;
  }, []);

  const cadastrarCredenciadoraCartao = async (data: FormData) => {
    const response = await api.post<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.CREDENCIADORA_CARTAO_CADASTRAR,
      { ...data }
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response?.sucesso) {
        return { success: true };
      }
    }

    return { success: false };
  };

  const atualizarCredenciadoraCartao = async (id: string, data: FormData) => {
    const response = await api.put<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.CREDENCIADORA_CARTAO_ALTERAR,
      { ...data },
      {
        params: { id },
      }
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response?.sucesso) {
        return { success: true };
      }
    }

    return { success: false };
  };

  const redirecionarCredenciadoraCartaoListar = () => {
    history.push(ConstanteRotas.CREDENCIADORA_CARTAO);
  };

  return {
    obterCredenciadoraCartao,
    cadastrarCredenciadoraCartao,
    atualizarCredenciadoraCartao,
    redirecionarCredenciadoraCartaoListar,
    codigoSatPadrao,
  };
};

export { useCredenciadoraCartaoForm };
