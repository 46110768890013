import { useCallback, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';
import jsFileDownload from 'js-file-download';

import { OperacaoObterParaCompartilhar } from 'helpers/api/Operacao/obterOperacaoParaCompartilhar';
import api, { ResponseApi } from 'services/api';
import { InformacoesImpressaoNFCeInterface } from 'types/ImpressaoNFCe';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { StatusFiscais } from 'constants/enum/fiscal/statusFiscal';
import { downloadArquivosXML } from 'helpers/api/NotaFiscal/downloadArquivosXML';
import { ImprimirPDF } from 'helpers/impressoes/imprimirPDF';
import { enumTypeCarne } from 'constants/enum/enumTypeCarne';

import { ImpressaoNFCeRefElement } from 'components/Impressao/ImpressaoNFCe/ImpressaoNFCe';
import { ImpressaoCupomNaoFiscalRefElement } from 'components/Impressao/ImpressaoCupomNaoFiscal/ImpressaoCupomNaoFiscal';
import { WhatsAppModal } from 'components/PDV/CompartilharDocumentosFiscais/WhatsAppModal';
import { ImprimirVendaA4 } from 'components/PDV/CompartilharDocumentosFiscais/utils/CompartilharVendaA4';
import { EmailModal } from 'components/PDV/CompartilharDocumentosFiscais/EmailModal';
import { ImpressaoCarnePromissoriaRefElement } from 'components/Impressao/Carne/types';
import { ModalEscolherParcelasImpressaoCarne } from 'components/Modal/ModalEscolherParcelasImpressaoCarne';

type DocumentoFiscal = {
  id: string;
  numero: string;
  status: StatusFiscais;
};

export const useCompartilharOperacoes = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [typeCarne, setTypeCarne] = useState(enumTypeCarne.NORMAL);
  const isOpenModal = useRef(true);

  const impressaoCarneRef = useRef<ImpressaoCarnePromissoriaRefElement>(null);
  const impressaoCarneContainer = useRef<HTMLDivElement>(null);

  const openModalEscolherParcelasImpressaoCarne = useCallback(async () => {
    if (impressaoCarneRef.current) {
      setIsLoading(true);
      const dados = await impressaoCarneRef.current.fetchPrintInformation();
      setIsLoading(false);
      if (dados && dados.recebimentos) {
        if (dados?.recebimentos?.length === 1) {
          impressaoCarneRef.current.printParcelas(
            dados.recebimentos.map((item) => item.id)
          );
          return;
        }
        try {
          const { parcelas } = await ModalEscolherParcelasImpressaoCarne({
            parcelas: dados?.recebimentos || [],
            isLoading,
          });
          if (parcelas) {
            impressaoCarneRef.current.printParcelas(parcelas);
          }
        } catch {
          setIsLoading(false);
        }
      }
    }
  }, [isLoading]);

  const handleImpressaoCarne = useCallback(
    async (type: string) => {
      setTypeCarne(type);

      await openModalEscolherParcelasImpressaoCarne();
    },
    [openModalEscolherParcelasImpressaoCarne]
  );

  const handleOpenModalEmail = (
    operacao: OperacaoObterParaCompartilhar,
    notafiscal: DocumentoFiscal | undefined,
    hasOperacaoId: string | undefined
  ) => {
    EmailModal({
      cliente: operacao.cliente,
      documentoFiscalId: notafiscal?.id,
      operacaoId: hasOperacaoId,
    });
  };

  const handleOpenModalWhatsApp = (
    operacao: OperacaoObterParaCompartilhar,
    hasOperacaoId: string
  ) => {
    WhatsAppModal({
      cliente: operacao.cliente,
      operacaoId: hasOperacaoId,
    });
  };

  const cupomNaoFiscalRef = useRef<ImpressaoCupomNaoFiscalRefElement>(null);
  const cupomNaoFiscalContainerRef = useRef<HTMLDivElement>(null);
  const imprimirCupomNaoFiscal = useReactToPrint({
    content: () => cupomNaoFiscalContainerRef.current,
  });
  const handleImprimirCupomNaoFiscal = useCallback(async () => {
    setIsLoading(true);

    if (imprimirCupomNaoFiscal && cupomNaoFiscalRef.current) {
      await cupomNaoFiscalRef.current.obterInformacoesImpressao();

      imprimirCupomNaoFiscal();
    }

    setIsLoading(false);
  }, [imprimirCupomNaoFiscal]);

  const cupomFiscalRef = useRef<ImpressaoNFCeRefElement>(null);
  const cupomFiscalContainerRef = useRef<HTMLDivElement>(null);

  const imprimirCupomFiscal = useReactToPrint({
    content: () => cupomFiscalContainerRef.current,
  });

  const handleImprimirCupomFiscal = useCallback(async () => {
    setIsLoading(true);
    if (
      imprimirCupomFiscal &&
      cupomFiscalRef.current &&
      cupomFiscalContainerRef
    ) {
      const impressaoCarregada = await cupomFiscalRef.current.obterInformacoesImpressaoHandle();

      if (impressaoCarregada) {
        imprimirCupomFiscal();
      }
    }

    setIsLoading(false);
  }, [imprimirCupomFiscal]);

  const handleEnviarWhatsAppCupomFiscal = async (
    isNFCe: DocumentoFiscal,
    hasOperacaoId: string,
    operacao: OperacaoObterParaCompartilhar
  ) => {
    setIsLoading(true);
    const response = await api.get<
      void,
      ResponseApi<InformacoesImpressaoNFCeInterface>
    >(ConstanteEnderecoWebservice.NOTA_FISCAL_IMPRIMIR_NFCE, {
      params: { documentoFiscalId: isNFCe?.id },
    });
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }
      if (response.sucesso && response.dados) {
        WhatsAppModal({
          cliente: operacao.cliente,
          operacaoId: hasOperacaoId,
          linkQRcode: response.dados.xml.informacoesSuplementaresXml.qrCode,
        });
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const handleDownloadXMLCupomFiscal = async (isNFCe: DocumentoFiscal) => {
    setIsLoading(true);
    await downloadArquivosXML(isNFCe?.id || '', isNFCe?.numero || '');
    setIsLoading(false);
  };

  const handleImprimirDanfe = async (isNFe: DocumentoFiscal) => {
    setIsLoading(true);

    const response = await api.get<void, ResponseApi<string>>(
      `${ConstanteEnderecoWebservice.NOTA_FISCAL_DANFE}/${isNFe?.id}`
    );
    if (response) {
      if (response.sucesso) {
        jsFileDownload(
          Uint8Array.from(atob(response.dados), (c) => c.charCodeAt(0)),
          `Danfe_${isNFe?.numero}.pdf`
        );
      }

      if (response?.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }
      setIsLoading(false);
    }

    setIsLoading(false);
  };

  const handleDownloadXMLDanfe = async (isNFe: DocumentoFiscal) => {
    setIsLoading(true);
    await downloadArquivosXML(isNFe?.id || '', isNFe?.numero || '');
    setIsLoading(false);
  };

  const handleImprimirComprovanteA4 = async (hasOperacaoId: string) => {
    setIsLoading(true);
    await ImprimirVendaA4({ id: hasOperacaoId });
    setIsLoading(false);
  };

  const handleImpressaoMeiaFolha = async (hasOperacaoId: string) => {
    setIsLoading(true);
    const response = await api.get<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.IMPRESSAO_VENDA_MEIA_FOLHA,
      {
        params: { id: hasOperacaoId },
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((avisos) => toast.warning(avisos));
      }

      if (response.dados && response.sucesso) {
        ImprimirPDF(response.dados, 'relatorioDocFiscais');
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  return {
    isLoading,
    setIsLoading,
    typeCarne,
    isOpenModal,
    handleOpenModalWhatsApp,
    impressaoCarneRef,
    impressaoCarneContainer,
    cupomNaoFiscalRef,
    cupomNaoFiscalContainerRef,
    cupomFiscalRef,
    cupomFiscalContainerRef,
    handleImprimirCupomNaoFiscal,
    handleImprimirCupomFiscal,
    handleEnviarWhatsAppCupomFiscal,
    handleDownloadXMLCupomFiscal,
    handleDownloadXMLDanfe,
    handleImprimirDanfe,
    handleImprimirComprovanteA4,
    handleImpressaoMeiaFolha,
    handleOpenModalEmail,
    handleImpressaoCarne,
  };
};
