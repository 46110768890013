import React, { ChangeEvent, FocusEvent, useCallback, useState } from 'react';
import {
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  InputGroup,
  InputRightElement,
  Icon,
} from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';

import CampoContainer, {
  CampoContainerProps,
} from 'components/PDV/Geral/CampoContainer';

export type InputProps = Omit<ChakraInputProps, 'ref'> & CampoContainerProps;

const InputPdv = ({
  id,
  name,
  label,
  helperText,
  colSpan,
  colStart,
  colEnd,
  rowStart,
  rowEnd,
  rowSpan,
  borderRadius,
  w,
  maxW,
  minW,
  isRequired,
  actionLinkText,
  actionLinkOnClick,
  inputRightElement,
  isPassword = false,
  icon,
  bg = 'white',
  type,
  onChange,
  onBlur,
  ...rest
}: InputProps) => {
  const [valueIsVisible, setValueIsVisible] = useState(true);

  const {
    setValue,
    formState: { errors },
  } = useFormContext();

  const typeIsNumber = type === 'number';

  const exibirIcon = () => {
    if (isPassword) {
      return valueIsVisible ? AiOutlineEyeInvisible : AiOutlineEye;
    }
    return icon;
  };

  const formatValueInput = useCallback(
    (value: any) => {
      if (typeIsNumber) {
        return value && String(value)?.replace(/\D/g, '');
      }

      return value;
    },
    [typeIsNumber]
  );

  return (
    <Controller
      name={name}
      render={({ field }) => {
        return (
          <CampoContainer
            id={id}
            name={name}
            label={label}
            helperText={helperText}
            colSpan={colSpan}
            colStart={colStart}
            colEnd={colEnd}
            rowStart={rowStart}
            rowEnd={rowEnd}
            rowSpan={rowSpan}
            errorText={errors[name]?.message}
            isInvalid={errors[name]}
            w={w}
            borderRadius={borderRadius}
            maxW={maxW}
            minW={minW}
            isRequired={isRequired}
            actionLinkText={actionLinkText}
            actionLinkOnClick={actionLinkOnClick}
          >
            <InputGroup>
              <ChakraInput
                {...rest}
                id={id}
                bg={bg}
                onBlur={(e: FocusEvent<HTMLInputElement>) => {
                  if (onBlur) onBlur(e);
                  if (field.onBlur) {
                    field.onBlur();
                  }
                }}
                type={isPassword && valueIsVisible ? 'password' : 'undefined'}
                value={formatValueInput(field.value)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (onChange) onChange(e);
                  if (field.onChange) {
                    field.onChange(e);
                  }

                  setValue(name, formatValueInput(e.target.value));
                }}
              />
              {inputRightElement && (
                <InputRightElement>
                  <Icon
                    boxSize="22px"
                    cursor="pointer"
                    color="gray.600"
                    onClick={() => setValueIsVisible(!valueIsVisible)}
                    as={exibirIcon()}
                  />
                </InputRightElement>
              )}
            </InputGroup>
          </CampoContainer>
        );
      }}
    />
  );
};

export default InputPdv;
