import { useState, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import auth from 'modules/auth';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import { UncontrolledForm } from '..';
import { useDepartamentoForm } from '../hooks';
import {
  FormData,
  defaultValues,
  yupResolver,
  DepartamentoResponse,
} from '../validationForm';

const AlterarDepartamento = () => {
  const { id: idRota } = useParams<{ id: string }>();
  const { id: lojaId } = auth.getLoja();

  const { handleToDepartments } = useDepartamentoForm();

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues,
  });

  const { handleSubmit: onSubmit, reset } = formMethods;

  const [isLoading, setIsLoading] = useState(false);

  const updateDepartment = useCallback(
    async (data: FormData) => {
      const incorrectMainAccountRange =
        data.numeracaoInicialContaPrincipal > data.numeracaoFinalContaPrincipal;

      const incorrectSubAccountRange =
        data.numeracaoInicialSubConta > data.numeracaoFinalSubConta;

      if (incorrectMainAccountRange || incorrectSubAccountRange) {
        toast.warning(
          ` O valor inicial da ${
            incorrectMainAccountRange ? 'conta principal' : 'subconta'
          } deve ser equivalente ou menor que o valor final`
        );

        return { success: false };
      }

      const contasBloqueadas = data?.contasBloqueadas.map(
        (contaBloqueada) => contaBloqueada.value
      );

      const response = await api.put<void, ResponseApi>(
        `${ConstanteEnderecoWebservice.DEPARTAMENTO}/${idRota}`,
        { ...data, contasBloqueadas }
      );

      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response?.sucesso) {
          toast.success('Departamento alterado com sucesso.');
          return { success: true };
        }
      }

      return { success: false };
    },
    [idRota]
  );

  const handleSave = onSubmit(async (data) => {
    setIsLoading(true);

    const { success } = await updateDepartment(data);

    if (success) {
      handleToDepartments();
    }

    setIsLoading(false);
  });

  const getDepartment = useCallback(async () => {
    if (!idRota) {
      return;
    }

    setIsLoading(true);

    const response = await api.get<void, ResponseApi<DepartamentoResponse>>(
      `${ConstanteEnderecoWebservice.DEPARTAMENTO}/${idRota}`
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }
      if (response?.sucesso && response?.dados) {
        const { dados } = response;
        const { subConta, contaPrincipal } = dados;

        const listProducts = dados.produtosFavoritos.map(({ produto }) => ({
          id: produto.id,
          nome: produto.nome,
        }));

        const contasBloqueadas = dados.contasBloqueadas?.map(
          (contaBloqueada) => ({
            label: contaBloqueada.numeroConta.toString(),
            value: contaBloqueada.numeroConta,
          })
        );

        reset({
          lojaId,
          listProducts,
          contasBloqueadas,
          ativo: dados.informacoesBasicas.ativo,
          descricao: dados.descricao,
          descricaoContaPrincipal: contaPrincipal.descricao,
          numeracaoInicialContaPrincipal: contaPrincipal.numeracaoInicial,
          numeracaoFinalContaPrincipal: contaPrincipal.numeracaoFinal,
          descricaoSubConta: subConta?.descricao,
          numeracaoInicialSubConta: subConta?.numeracaoInicial || 0,
          numeracaoFinalSubConta: subConta?.numeracaoFinal || 0,
          exibicaoContas: dados.exibicaoContas,
          numeracaoContas: dados.numeracaoContas,
          utilizarSubConta: !!subConta,
          valorCouver: dados.valorCouver,
        });
      }
    }

    setIsLoading(false);
  }, [idRota, lojaId, reset]);

  useEffect(() => {
    getDepartment();
  }, [getDepartment]);

  return (
    <FormProvider {...formMethods}>
      <UncontrolledForm handleSave={handleSave} isLoading={isLoading} />
    </FormProvider>
  );
};

export { AlterarDepartamento };
