import { useState } from 'react';
import {
  Flex,
  Icon,
  Text,
  Box,
  Button,
  Divider,
  useMediaQuery,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { FiX } from 'react-icons/fi';

import { DecimalMask, moneyMask } from 'helpers/format/fieldsMasks';
import { formatDate } from 'helpers/format/formatStringDate';
import { useMetasComissoesHistoricoVendasContext } from 'store/MetasComissoes/MetasComissoesHistoricoVendas';

import { CompartilharMenuIcon, IconSeta } from 'icons';
import { MonthInput } from 'components/update/Input/MonthInput';

import { ListarHistoricoVendas } from '../ListarHistoricoVendas';
import { DetalhesVenda } from '../../Types/validationsForm';

type HistoricoVendedorSemPermissaoProps = {
  handleVoltarParaMetasComissoes: () => void;
  handleImprimirOperacao: () => void;
};

export const HistoricoVendedorSemPermissao = ({
  handleVoltarParaMetasComissoes,
  handleImprimirOperacao,
}: HistoricoVendedorSemPermissaoProps) => {
  const [listaDetalhesVenda, setListaDetalhesVenda] = useState<DetalhesVenda>(
    {} as DetalhesVenda
  );

  const { vendedorVinculado } = useMetasComissoesHistoricoVendasContext();

  const { watch } = useFormContext();

  const [isLargerThan1200] = useMediaQuery('(min-width: 1200px)');
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const mesAnoWatch = watch('mesAno');

  return (
    <>
      <Flex
        mb="13px"
        w="full"
        direction={isLargerThan900 ? 'row' : 'column'}
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex
          direction={isLargerThan900 ? 'row' : 'column'}
          justifyContent="left"
          alignItems="center"
          w={isLargerThan900 ? '' : 'full'}
        >
          <Flex
            w={isLargerThan900 ? '' : 'full'}
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex justifyContent="left" alignItems="center">
              <Icon
                color="primary.50"
                w={4}
                mr={isLargerThan900 ? '23px' : '10px'}
                cursor="pointer"
                fontWeight="extrabold"
                onClick={() => handleVoltarParaMetasComissoes()}
                h={4}
                as={IconSeta}
              />
              <Text fontSize="18px" color="primary.50">
                Histórico de vendas
              </Text>
            </Flex>
            {!isLargerThan900 && (
              <Icon
                as={FiX}
                w={4}
                h={4}
                cursor="pointer"
                onClick={() => handleVoltarParaMetasComissoes()}
              />
            )}
          </Flex>
          {!isLargerThan900 && (
            <Divider h="1px" mb="10px" mt="10px" bg="primary.50" />
          )}

          <Box
            mb={isLargerThan900 ? '' : '10px'}
            ml="40px"
            w={isLargerThan1200 ? '250px' : isLargerThan900 ? '200px' : 'full'}
          >
            <MonthInput
              name="mesAno"
              isRequired
              colSpan={{ base: 12, sm: 4, md: 4, lg: 3, xl: 2 }}
            />
          </Box>
        </Flex>
        <Flex
          w={isLargerThan900 ? '' : 'full'}
          justifyContent="right"
          alignItems="center"
        >
          <Button
            mr={isLargerThan1200 ? '32px' : isLargerThan900 ? '22px' : ''}
            w={isLargerThan900 ? '153px' : 'full'}
            variant="sucess"
            bg="primary.50"
            color="white"
            borderRadius="md"
            borderColor="primary.50"
            onClick={() => handleImprimirOperacao()}
            leftIcon={<Icon mr="5px" as={CompartilharMenuIcon} fontSize="md" />}
          >
            Compartilhar
          </Button>
          {isLargerThan900 && (
            <Icon
              as={FiX}
              w={4}
              h={4}
              cursor="pointer"
              onClick={() => handleVoltarParaMetasComissoes()}
            />
          )}
        </Flex>
      </Flex>
      <Divider h="1px" mb="30px" bg="primary.50" />
      <Flex
        direction={isLargerThan900 ? 'row' : 'column'}
        justifyContent={isLargerThan900 ? 'left' : 'flex-end'}
        alignItems={isLargerThan900 ? 'center' : 'left'}
        pl={isLargerThan900 ? '40px' : '1px'}
      >
        <Box mr="50px">
          <Flex
            mb={isLargerThan900 ? '13px' : ''}
            justifyContent="left"
            alignItems="center"
          >
            <Text mr="5px" fontSize="14px" color="gray.700">
              Quantidade de vendas:
            </Text>
            <Text fontSize="18px" color="black">
              {listaDetalhesVenda.quantidadeVendas
                ? DecimalMask(listaDetalhesVenda.quantidadeVendas, 0, 0)
                : '-'}
            </Text>
          </Flex>
          <Flex justifyContent="left" alignItems="center">
            <Text mr="5px" fontSize="14px" color="gray.700">
              Valor de vendas:
            </Text>
            <Text fontSize="18px" color="black">
              {moneyMask(
                listaDetalhesVenda.totalVendas
                  ? listaDetalhesVenda.totalVendas
                  : 0,
                true
              )}
            </Text>
          </Flex>
        </Box>
        {isLargerThan900 && (
          <Divider mr="40px" orientation="vertical" h="60px" />
        )}
        <Box>
          <Flex
            mb={isLargerThan900 ? '13px' : ''}
            justifyContent="left"
            alignItems="center"
          >
            <Text mr="5px" fontSize="14px" color="gray.700">
              Vendedor:
            </Text>
            <Text fontSize="18px" color="black">
              {vendedorVinculado.nome}
            </Text>
          </Flex>
          <Flex justifyContent="left" alignItems="center">
            <Text mr="5px" fontSize="14px" color="gray.700">
              Competência:
            </Text>
            <Text fontSize="18px" color="black">
              {formatDate(mesAnoWatch === undefined ? new Date() : mesAnoWatch)}{' '}
            </Text>
          </Flex>
        </Box>
        {isLargerThan900 && (
          <Divider ml="40px" mr="40px" orientation="vertical" h="60px" />
        )}
        <Flex justifyContent="left" mb="40px" alignItems="center">
          <Text mr="5px" fontSize="14px" color="gray.700">
            Total comissão:
          </Text>
          <Text fontSize="18px" color="black">
            {moneyMask(listaDetalhesVenda.totalComissao || 0, true)}
          </Text>
        </Flex>
      </Flex>
      <ListarHistoricoVendas
        mesAno={mesAnoWatch}
        setListaDetalhesVenda={setListaDetalhesVenda}
      />
    </>
  );
};
