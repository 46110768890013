const TipoCobrancaMultaJurosEnum = {
  POR_DIA: 1,
  POR_MES: 2,
  FIXA: 3,

  properties: {
    1: {
      name: 'Por Dia',
      value: 1,
    },
    2: {
      name: 'Por Mês',
      value: 2,
    },
    3: {
      name: 'Fixa',
      value: 3,
    },
  },
};

export default TipoCobrancaMultaJurosEnum;
