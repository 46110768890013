import { Icon, ButtonProps } from '@chakra-ui/react';

import { ButtonFuncionalidade } from 'components/update/Button/ButtonFuncionalidade';
import { SalvarInserirNovoIcon } from 'icons';

interface ButtonCadastrarNovoProps extends ButtonProps {
  funcionalidade: string;
  exibirIcone?: boolean;
  label?: string;
  iconSide?: 'left' | 'right';
  w?: string;
}

export const ButtonCadastrarNovo = ({
  funcionalidade,
  label,
  exibirIcone = true,
  h = '39px',
  w = '220px',
  colorScheme = 'secondary',
  borderRadius = 'md',
  variant = 'solid',
  iconSide = 'right',
  color = 'gray.700',
  ...rest
}: ButtonCadastrarNovoProps) => {
  return (
    <ButtonFuncionalidade
      id="cadastrarNovo"
      h={h}
      variant={variant}
      name="cadastrarNovo"
      color={color}
      colorScheme={colorScheme}
      borderRadius={borderRadius}
      width={{ base: 'full', md: w }}
      leftIcon={exibirIcone ? <Icon as={SalvarInserirNovoIcon} /> : undefined}
      type="button"
      funcionalidade={funcionalidade}
      iconSide={iconSide}
      {...rest}
    >
      {label || 'Cadastrar Novo'}
    </ButtonFuncionalidade>
  );
};
