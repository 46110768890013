import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import auth from 'modules/auth';
import api, { ResponseApi } from 'services/api';
import ConstanteRotas from 'constants/rotas';
import GenerosEnum from 'constants/enum/generos';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import FormularioUsuariosProvider from 'store/FormularioUsuarios';
import listarCategoriaPermissoes from 'helpers/api/PerfilUsuario/listarCategoriaPermissoes';

import { CategoriaPermissaoInterface } from 'components/Permissoes';
import { ContainerListagem } from 'components/Layout/Listagem/containerListagem';

import { useForm, yupResolver } from '../validationForm';
import UncontrolledForm, { UncontrolledFormRefInterface } from '..';
import { UsuarioProps, LojasProps, PermissoesProps } from '../Types';

const Cadastro = () => {
  const history = useHistory();

  const { id: lojaId } = auth.getLoja();

  const formMethods = useForm({
    resolver: yupResolver,
    shouldUnregister: false,
  });

  const { handleSubmit, watch, getValues, setValue, setFocus } = formMethods;
  const permissoeswatch = watch('permissoes');

  const [isLoading, setIsLoading] = useState(false);
  const [listaPermissoes, setListaPermissoes] = useState<PermissoesProps[]>([]);
  const [atualizarPermissoes, setAtualizarPermissoes] = useState(true);
  const [categoriasPermissao, setCategoriasPermissao] = useState<
    CategoriaPermissaoInterface[]
  >([]);

  const uncontrolledFormRef = useRef<UncontrolledFormRefInterface>();

  const getLojaPadrao = useCallback(() => {
    setValue('valueIdLojaPadrao', lojaId);
    setValue(`lojaSelecionada.${lojaId}`, true);
  }, [lojaId, setValue]);

  const getCategoriaPermissao = useCallback(async () => {
    const newCategoriasPermissao = await listarCategoriaPermissoes();

    if (newCategoriasPermissao) {
      setCategoriasPermissao(newCategoriasPermissao);
    }
  }, []);

  const atualizarValoresPermissao = useCallback(() => {
    return listaPermissoes.map((permissao, index) => {
      return {
        id: permissao.id,
        selecionado: permissoeswatch[index],
      };
    });
  }, [listaPermissoes, permissoeswatch]);

  const handleCadastroUsuario = useCallback(async () => {
    const data = getValues();
    const permissao = atualizarValoresPermissao();

    const { lojaSelecionada, localEstoqueId, vendedorId } = data;

    const listLojas: LojasProps[] | null = data.lojas?.map(
      ({ id: lojaItemId }: LojasProps) => {
        return lojaSelecionada
          ? {
              id: lojaItemId,
              vendedorId: vendedorId[lojaItemId],
              localEstoqueId: localEstoqueId[lojaItemId],
              selecionado: lojaSelecionada[lojaItemId] || false,
            }
          : null;
      }
    );

    const hasLojaSelecionada = listLojas?.some(
      ({ selecionado }) => selecionado === true
    );

    if (!hasLojaSelecionada) {
      uncontrolledFormRef.current?.focusTabLojas();
      toast.warning('Selecione ao menos uma loja para o usuário');

      return false;
    }

    const dadosCadastroUsuario: UsuarioProps = {
      Nome: data.nome,
      Ativo: data.ativo || false,
      Foto: data.foto,
      Login: data.login,
      LojaPadraoId: data.lojaPadraoId,
      Senha: data.senha,
      Confirmesenha: data.confirmesenha,
      Email: data.email,
      Telefone: data?.telefone?.trim(),
      Genero: data.genero || GenerosEnum.NAO_INFORMADO,
      PinUsuario: data.pinUsuario,
      Datanascimento: data?.dataNascimento === '' ? null : data?.dataNascimento,
      PerfilId: data.perfilusuario?.value || null,
      Permissoes: permissao,
      AbrirPdvAposLogin: data.abrirPdvAposLogin || false,
      TipoUsuario: data.tipoUsuario,
      Lojas: listLojas,
      descontoMaximoPermitido: data.descontoMaximoPermitido || 0,
    };

    const listLojasSelecionadas = listLojas?.filter(
      (lojaItem) => lojaItem.selecionado
    );

    const hasPermissaoSelecionada = permissao.some(
      (permissaoItem) => permissaoItem.selecionado
    );

    const hasVendedorNaoSelecionado = listLojasSelecionadas?.some(
      (lojaItem) => !lojaItem.vendedorId
    );

    const hasLocalEstoqueNaoSelecionado = listLojasSelecionadas?.some(
      (lojaItem) => !lojaItem.localEstoqueId
    );

    if (
      !(hasVendedorNaoSelecionado || hasLocalEstoqueNaoSelecionado) &&
      hasPermissaoSelecionada
    ) {
      const response = await api.post<void, ResponseApi>(
        ConstanteEnderecoWebservice.USUARIO_CADASTRAR,
        dadosCadastroUsuario
      );

      return response;
    }

    if (hasVendedorNaoSelecionado || hasLocalEstoqueNaoSelecionado) {
      uncontrolledFormRef.current?.focusTabLojas();
    } else {
      uncontrolledFormRef.current?.focusTabPerfil();
    }

    toast.warning(
      hasVendedorNaoSelecionado
        ? 'Selecione um vendedor para cada loja'
        : hasLocalEstoqueNaoSelecionado
        ? 'Selecione um local de estoque para cada loja'
        : 'Selecione ao menos uma uma permissão para o usuário'
    );

    return false;
  }, [atualizarValoresPermissao, getValues]);

  const handleOnInvalid = () => {
    uncontrolledFormRef.current?.focusTabDadosGerais();
  };

  const onSubmit = handleSubmit(async () => {
    setIsLoading(true);
    const response = await handleCadastroUsuario();

    if (response) {
      if (response.avisos)
        response.avisos.forEach((aviso) => toast.warning(aviso));
      if (response.sucesso) {
        toast.success('O cadastro foi realizado com sucesso.');
        history.push(ConstanteRotas.USUARIO);
      }
    }
    setIsLoading(false);
  }, handleOnInvalid);

  const onSubmitReset = handleSubmit(async () => {
    setIsLoading(true);
    const response = await handleCadastroUsuario();

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }
      if (response.sucesso) {
        toast.success('O cadastro foi realizado com sucesso.');

        if (uncontrolledFormRef.current) {
          uncontrolledFormRef.current.reset();
          uncontrolledFormRef.current.focusTabDadosGerais();
        }

        setFocus('nome');
        getLojaPadrao();
      }
    }
    setIsLoading(false);
  }, handleOnInvalid);

  useEffect(() => {
    if (atualizarPermissoes) {
      setIsLoading(true);
      categoriasPermissao.forEach((categoria) => {
        categoria.grupos.forEach((grupo) => {
          grupo.permissoes.forEach((permissao) => {
            setAtualizarPermissoes(false);
            setListaPermissoes((valorAnterior) => [
              ...valorAnterior,
              { id: permissao.id },
            ]);
          });
        });
      });
      setIsLoading(false);
    }
  }, [categoriasPermissao, atualizarPermissoes]);

  useEffect(() => {
    getCategoriaPermissao();
  }, [getCategoriaPermissao]);

  useEffect(() => {
    getLojaPadrao();
  }, [getLojaPadrao]);

  return (
    <ContainerListagem
      maxWidth="full"
      containerPossuiFundo={false}
      formMethods={formMethods}
      isLoading={isLoading}
      onSubmit={onSubmit}
      onSubmitReset={onSubmitReset}
    >
      <FormularioUsuariosProvider
        setCategorias={setCategoriasPermissao}
        categorias={categoriasPermissao}
      >
        <UncontrolledForm
          isLoading={isLoading}
          ref={uncontrolledFormRef}
          isAlteracao={false}
        />
      </FormularioUsuariosProvider>
    </ContainerListagem>
  );
};

export default Cadastro;
