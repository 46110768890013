import { useCallback, useEffect, useState } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Box,
  Button,
  useDisclosure,
  Text,
  Flex,
  ModalFooter,
  ModalHeader,
  useMediaQuery,
  Divider,
  VStack,
  Image as ImagemChakra,
  Icon,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';
import {
  FiDownload,
  FiChevronRight,
  FiSlack,
  FiChevronDown,
} from 'react-icons/fi';
import { CorReferenciaIcon } from 'icons';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { ButtonDefault } from 'components/Button/ButtonChakra';
import ImageUploader, {
  ListImagensProps,
} from 'components/ImagePicker/ImageUploader';
import { HexColorPicker } from 'components/update/HexColorPicker';
import { getVariacoesCores } from 'pages/Produtos/Formulario/FunctionsCadastroProduto/ObterCoresCadastradas';

type ModalAdicionarCorResolveProps = {
  linkImagem: string;
  isPrincipal: boolean;
  order: number;
  notDrag: boolean;
  idVariacao: string;
}[];

type VariacoesProps = {
  label: string;
  value: string;
  image: string;
  cor: string;
  isChecked: boolean;
  isColor: boolean;
  produtoCorId: string;
};

export type CoresImagemProps = {
  id: string;
  hexadecimal: string | null;
  imagem: string;
  produtoCorId: string;
};

type ModalAdicionarCorProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalAdicionarCorResolveProps> & {
    valueVariacao?: string;
    produtoId: string;
    onConfirm: (data: CoresImagemProps) => Promise<boolean>;
    atualizarVariacoes: () => void;
    tamanhoImagem?: number;
  };

const colorDefault = '#FFF';

export const ModalAdicionarCor = create<
  ModalAdicionarCorProps,
  ModalAdicionarCorResolveProps
>(
  ({
    onResolve,
    onReject,
    onConfirm,
    valueVariacao,
    tamanhoImagem = 100,
    produtoId,
    children,
    atualizarVariacoes,
    ...rest
  }) => {
    const [listVariacoes, setListVariacoes] = useState<VariacoesProps[]>([]);
    const [corSelecionada, setCorSelecionada] = useState('');
    const [imagem, setImagem] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isVariacaoAlterada, setIsVariacaoAlterada] = useState(false);

    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    const formMethods = useForm();

    const variacaoSelecionada = listVariacoes.find(
      (variacao) => variacao.isChecked
    );

    const handleSalvarImagem = (value: ListImagensProps) => {
      if (value.isForaPadrao) {
        toast.warning('A imagem está fora do padrão');
      } else {
        setImagem(value.linkImagem);
      }
    };

    const handleCadastrarImagemCor = useCallback(
      async (dataUrlImage: string, color?: string) => {
        setIsLoading(true);

        const isImageBase64 = dataUrlImage.includes('base64,');

        const response = await onConfirm({
          hexadecimal: color?.replace('#', '') || null,
          imagem: isImageBase64
            ? dataUrlImage.split('base64,')[1]
            : dataUrlImage,
          id: variacaoSelecionada?.value || '',
          produtoCorId: variacaoSelecionada?.produtoCorId || '',
        });
        return response;
      },
      [onConfirm, variacaoSelecionada]
    );

    const valueCor = useCallback(() => {
      if (corSelecionada !== '') {
        return corSelecionada;
      }

      if (corSelecionada === '' && variacaoSelecionada?.cor !== '') {
        return variacaoSelecionada?.cor || '';
      }

      return colorDefault;
    }, [corSelecionada, variacaoSelecionada])();

    const handleAlterarCor = useCallback(async () => {
      const canvas = document.createElement('canvas');
      canvas.width = tamanhoImagem;
      canvas.height = tamanhoImagem;

      const ctx = canvas.getContext('2d');

      if (ctx) {
        ctx.fillStyle = valueCor;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
      }

      const dataUrlImage = canvas.toDataURL('image/jpeg');

      const response = await handleCadastrarImagemCor(dataUrlImage, valueCor);

      if (response) {
        toast.success('A cor foi salva com sucesso');

        setListVariacoes((prev) =>
          prev.map((itemPrev) => ({
            ...itemPrev,
            cor:
              itemPrev.value === variacaoSelecionada?.value
                ? valueCor
                : itemPrev.cor,
          }))
        );
        setCorSelecionada('');
        setIsLoading(false);
      } else {
        toast.warning(
          'Não foi possível salvar a cor, por favor tente novamente'
        );
        setIsLoading(false);
      }
      setIsLoading(false);
    }, [
      valueCor,
      handleCadastrarImagemCor,
      tamanhoImagem,
      variacaoSelecionada,
    ]);

    const handleAplicarImagem = useCallback(async () => {
      const hasImagem = imagem === '' || imagem === null;
      const response = await handleCadastrarImagemCor(
        hasImagem ? variacaoSelecionada?.image || '' : imagem
      );

      if (response) {
        setListVariacoes((prev) =>
          prev.map((itemPrev) => ({
            ...itemPrev,
            image:
              variacaoSelecionada?.value === itemPrev.value && hasImagem
                ? itemPrev.image
                : imagem,
          }))
        );
        toast.success('A imagem foi salva com sucesso');
        setIsLoading(false);
      } else {
        toast.warning(
          'Não foi possível salvar a imagem, por favor tente novamente'
        );
        setIsLoading(false);
      }
      setImagem('');
      setIsLoading(false);
    }, [handleCadastrarImagemCor, variacaoSelecionada, imagem]);

    const handleSalvarAlteracoes = useCallback(async () => {
      if (isVariacaoAlterada === false) {
        return;
      }
      if (variacaoSelecionada?.isColor) {
        await handleAlterarCor();
      } else {
        await handleAplicarImagem();
      }
    }, [
      handleAlterarCor,
      handleAplicarImagem,
      isVariacaoAlterada,
      variacaoSelecionada,
    ]);

    const handleFechar = async () => {
      await handleSalvarAlteracoes();
      onClose();
      atualizarVariacoes();
    };

    const handleLimparCor = useCallback(() => {
      setListVariacoes((prev) =>
        prev.map((itemPrev) => ({
          ...itemPrev,
          cor:
            variacaoSelecionada?.value === itemPrev.value ? '' : itemPrev.cor,
        }))
      );
    }, [variacaoSelecionada]);

    const handleMudarOpcaoCorImagem = useCallback(() => {
      setListVariacoes((prev) =>
        prev.map((itemPrev) => ({
          ...itemPrev,
          image:
            variacaoSelecionada?.value === itemPrev.value ? '' : itemPrev.image,
          cor:
            variacaoSelecionada?.value === itemPrev.value ? '' : itemPrev.cor,
          isColor:
            variacaoSelecionada?.value === itemPrev.value
              ? !itemPrev.isColor
              : itemPrev.isColor,
        }))
      );
    }, [variacaoSelecionada]);

    const handleAlterarVariacao = useCallback(
      async (indexItem: number) => {
        await handleSalvarAlteracoes();
        setListVariacoes((prev) =>
          prev.map((itemPrev, index) => ({
            ...itemPrev,
            isChecked: indexItem === index,
          }))
        );
        setIsVariacaoAlterada(false);
      },
      [handleSalvarAlteracoes]
    );

    useEffect(() => {
      async function getCoresResponse() {
        setIsLoading(true);
        const response = await getVariacoesCores(produtoId);

        response.forEach(async (itemVariacao) => {
          const responseCor = await api.get<
            void,
            ResponseApi<{ id: string; hexadecimal: string; imagem: string }>
          >(
            `${ConstanteEnderecoWebservice.PRODUTO_CADASTRAR_COR}/${itemVariacao.corId}/imagem`
          );

          if (responseCor) {
            if (responseCor.avisos) {
              responseCor.avisos.forEach((item: string) => toast.warning(item));
            }
            if (responseCor.sucesso) {
              const { dados } = responseCor;

              const isNaoPossuiCor =
                dados.hexadecimal === null || dados.hexadecimal === '';
              const isPossuiImagem =
                dados.imagem === null || dados.imagem === '';

              setListVariacoes((prev) => [
                ...prev,
                {
                  cor:
                    dados.hexadecimal === null ? '' : `#${dados.hexadecimal}`,
                  image: dados.imagem || '',
                  isChecked: prev.length === 0,
                  isColor:
                    isPossuiImagem === true ? true : isNaoPossuiCor !== true,
                  label: itemVariacao.cor.descricao,
                  value: itemVariacao.cor.id,
                  produtoCorId: itemVariacao.id,
                } as VariacoesProps,
              ]);
            }
          }
        });
        setIsLoading(false);
      }
      getCoresResponse();
    }, [produtoId]);

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? '5xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={handleFechar}
        autoFocus={false}
      >
        <ModalContent
          bg="gray.50"
          overflowY="auto"
          borderRadius={isLargerThan900 ? 'md' : '0'}
          marginBottom={isLargerThan900 ? '3.75rem' : '0'}
          marginTop={isLargerThan900 ? '3.75rem' : '0'}
          w={isLargerThan900 ? '800px' : 'full'}
          h={isLargerThan900 ? '560px' : 'full'}
        >
          <ModalHeader pt="16px" pb="0" pl="0" pr="0">
            <Flex px="24px" justifyContent="space-between">
              <Text color="primary.50" fontSize="16px">
                {isLargerThan900
                  ? 'Informar referência visual de cor'
                  : 'Informar Cor'}
              </Text>
              <Text
                color="primary.50"
                cursor="pointer"
                display="flex"
                alignItems="center"
                onClick={() => handleMudarOpcaoCorImagem()}
                fontSize="16px"
              >
                <Flex ml="5px" align="center" gap="5px">
                  {variacaoSelecionada?.isColor ? (
                    <>
                      <FiDownload />
                      Inserir imagem
                    </>
                  ) : (
                    <>
                      <CorReferenciaIcon />
                      Selecionar cor
                    </>
                  )}
                </Flex>
              </Text>
            </Flex>

            <Divider color="gray.200" mt="16px" />
          </ModalHeader>

          <FormProvider {...formMethods}>
            {isLoading && <LoadingPadrao />}
            <ModalBody
              h={['', '', '400px']}
              pl={['0', '0', '24px']}
              pr="0"
              pt="0"
              pb="0"
            >
              <Flex direction={['column', 'column', 'row']} w="full" h="full">
                <VStack
                  mb={['20px', '20px', '']}
                  borderRightWidth="1px"
                  borderColor="gray.200"
                  w={['full', 'full', '390px']}
                  pr="24px"
                  h="full"
                  pl={['24px', '24px', '0', '']}
                  pt="24px"
                  spacing="16px"
                  overflowY="scroll"
                  maxH="405px"
                >
                  {listVariacoes.map((variacao, index) => (
                    <Flex
                      w="full"
                      onClick={() => handleAlterarVariacao(index)}
                      alignItems="center"
                      cursor="pointer"
                      justifyContent="space-between"
                    >
                      <Flex alignItems="center">
                        {variacao.cor && variacao.isColor && (
                          <Box
                            bg={variacao.cor ? variacao.cor : undefined}
                            boxSize="30px"
                            borderRadius="5px"
                            mr="12px"
                            borderWidth="1px"
                            borderColor="gray.200"
                          />
                        )}

                        {variacao.image && !variacao.isColor && (
                          <Box
                            boxSize="30px"
                            borderRadius="5px"
                            mr="12px"
                            borderWidth="1px"
                            borderColor="gray.200"
                          >
                            <ImagemChakra
                              w="full"
                              alt="imagem da cor"
                              h="full"
                              src={variacao.image}
                            />
                          </Box>
                        )}

                        {!variacao.image && !variacao.cor && (
                          <Flex
                            boxSize="30px"
                            justifyContent="center"
                            alignItems="center"
                            borderRadius="5px"
                            mr="12px"
                            borderWidth="1px"
                            borderColor="gray.200"
                          >
                            <Icon
                              as={CorReferenciaIcon}
                              boxSize="20px"
                              color="gray.400"
                            />
                          </Flex>
                        )}

                        <Text
                          ml="5px"
                          fontSize="16px"
                          textDecoration={
                            variacao.isChecked ? 'underline' : undefined
                          }
                          fontWeight={variacao.isChecked ? 'bold' : undefined}
                          color={variacao.isChecked ? 'violet.500' : 'black'}
                        >
                          {variacao.label}
                        </Text>
                      </Flex>
                      {variacao.isChecked && (
                        <Icon
                          fontSize="16px"
                          color="violet.500"
                          as={isLargerThan900 ? FiChevronRight : FiChevronDown}
                        />
                      )}
                    </Flex>
                  ))}
                </VStack>
                <Flex
                  pt="24px"
                  align="center"
                  bg={variacaoSelecionada?.isColor ? 'black' : 'white'}
                  justifyContent={
                    variacaoSelecionada?.isColor ? 'center' : 'start'
                  }
                  h="full"
                  w="full"
                >
                  {variacaoSelecionada?.isColor ? (
                    <>
                      {variacaoSelecionada?.cor ? (
                        <Box>
                          <Box
                            boxSize="200px"
                            bg={variacaoSelecionada?.cor}
                            mb="40px"
                          />
                          <Text
                            cursor="pointer"
                            onClick={() => handleLimparCor()}
                            textAlign="center"
                            color="white"
                            textDecoration="underline"
                          >
                            Alterar a referência da cor
                          </Text>
                        </Box>
                      ) : (
                        <HexColorPicker
                          colorDefault={variacaoSelecionada?.cor || undefined}
                          getColor={(color) => {
                            setCorSelecionada(color);
                            setIsVariacaoAlterada(true);
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <VStack
                      w="full"
                      alignItems="left"
                      px="24px"
                      h="full"
                      spacing="10px"
                    >
                      <Text>
                        → Utilize imagens com extensão JPG, JPEG ou PNG{' '}
                      </Text>
                      <Text>{`→ Tamanho máximo do arquivo de ${tamanhoImagem} kb`}</Text>
                      <Text>{`→ Resolução máxima de ${tamanhoImagem} x ${tamanhoImagem} px.`}</Text>
                      <Flex w="full" justifyContent="center">
                        <ImageUploader
                          totalBytes={tamanhoImagem}
                          valueTamanhoImg={tamanhoImagem}
                          imageDefault={variacaoSelecionada?.image || ''}
                          borderWidth={
                            variacaoSelecionada?.image ? '0px' : '1px'
                          }
                          borderStyle="dashed"
                          isAlterarImagem
                          borderColor="violet.500"
                          mr="6px"
                          handleSalvarImagem={(data) =>
                            handleSalvarImagem(data)
                          }
                          bg="white"
                          multiple={false}
                          borderRadius="2px"
                          boxSize="200px"
                          cursor="pointer"
                          mt="24px"
                          justifyContent="center"
                          alignItems="center"
                          color="gray.700"
                          deleteCallback={() => {
                            setIsVariacaoAlterada(true);
                            setListVariacoes((prev) =>
                              prev.map((itemPrev) => ({
                                ...itemPrev,
                                image:
                                  variacaoSelecionada?.value === itemPrev.value
                                    ? ''
                                    : itemPrev.image,
                              }))
                            );
                          }}
                        />
                      </Flex>
                      <Flex w="full" justifyContent="center">
                        <Button
                          mt="16px"
                          variant="solid"
                          borderRadius="20px"
                          colorScheme="violet.300"
                          isDisabled={
                            imagem === '' && variacaoSelecionada?.image === ''
                          }
                          h="32px"
                          onClick={() => handleAplicarImagem()}
                          w="136px"
                        >
                          Aplicar imagem
                        </Button>
                      </Flex>
                    </VStack>
                  )}
                </Flex>
              </Flex>
            </ModalBody>
            <ModalFooter
              pt="0"
              h={['', '', '78px']}
              px="0"
              flexDirection="column"
              mb={['10px', '10px', '20px']}
            >
              <Divider mb="30px" color="gray.200" mt="16px" />
              <Flex
                w="full"
                h="full"
                px="24px"
                justifyContent={['center', 'center', 'end']}
                alignItems="center"
              >
                <Flex>
                  <Button
                    variant="outlineDefault"
                    borderRadius="20px"
                    colorScheme="gray"
                    h="32px"
                    mr="24px"
                    onClick={() => onClose()}
                    w="96px"
                  >
                    Cancelar
                  </Button>
                  <ButtonDefault
                    color="white"
                    variant="solid"
                    colorScheme="aquamarine.600"
                    borderRadius="20px"
                    fontSize="sm"
                    h="32px"
                    width="120px"
                    onClick={handleFechar}
                    isLoading={isLoading}
                  >
                    Confirmar
                  </ButtonDefault>
                </Flex>
              </Flex>
            </ModalFooter>
          </FormProvider>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
