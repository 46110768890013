import React from 'react';

import CampoPersonalizadoInterface from 'types/campoPersonalizado';

import AccordionPadrao from 'components/Accordion/AccordionPadrao';
import { CamposPersonalizados } from 'components/update/CamposPersonalizados';

interface ContatosAdicionaisFormInterface {
  readonly?: boolean;
  camposPersonalizados: CampoPersonalizadoInterface[];
}

const CamposPersonalizadosForm = ({
  readonly,
  camposPersonalizados,
}: ContatosAdicionaisFormInterface) => {
  return (
    <AccordionPadrao title="Campos personalizados" defaultOpen={false}>
      <CamposPersonalizados
        readonly={readonly}
        camposPersonalizados={camposPersonalizados}
      />
    </AccordionPadrao>
  );
};

export default CamposPersonalizadosForm;
