import { useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { ResponseApi } from 'services/api';
import { sistemaApiKey } from 'services/sistemaApiKey';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import { ModalDesistenciaOrdenacaoMenu } from '../components/ModalDesistenciaOrdenacaoMenu';

type GeneralData = {
  acrescPorcent: boolean;
  exibirContasPorDepartamento: boolean;
  valorPorcent: number;
  configSenhaBalcao: number;
  qtdDigCodProdBalanca: number;
  tempoInatividadeMesa: number;
  diasBloqVenda: number;
  tempoMedioPreparo: number;
  tempoMedioEntrega: number;
  limiteQuantidadeVenda: boolean;
  gravarItensRemovidos: boolean;
  baixarMateriaPrimaVenda: boolean;
  pesquisaAutomaticaDeProdutos: boolean;
  cobrarCouver: boolean;
  emitirSomNotificacaoIfood: boolean;
  solicitarQtdPessoasMesa: boolean;
  valorProdProporcional: boolean;
};

type PrintData = {
  fechContaImprimirTotalizado: boolean;
  imprimirComplementoComValor: boolean;
  mostrarLimitesRelVendas: boolean;
  solicitarImpressaoEcologica: boolean;
  impressaoPorItensPDV: boolean;
  impressaoItensRemovidosVenda: boolean;
  imprimirObservacaoDoItem: boolean;
  imprimirComposicaoProduto: boolean;
  imprimirViaCupomNaoFiscal: boolean;
  imprimirIndividualmenteCozinha: boolean;
  imprimirDadosCompletosCliente: boolean;
  cabecalhoCupom: string;
  rodapeCupomParceiro: string;
  rodapeCupom: string;
  rodapeFicha: string;
};

type TaxData = {
  satImpressaoLocal: boolean;
  emitirEmContingencia: boolean;
  versaoDadosSAT: string;
  serieCertificado: string;
  nFeFormaEmissao: number;
  nfePLEvento: string;
  nfePLEPEC: string;
  nfePLNotaFiscal: string;
  assinaturaSatSTi3: string;
};

type FormData = GeneralData & PrintData & TaxData;

export const useConfiguracao = () => {
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const [isLoading, setIsLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [mobileSelectedTab, setMobileSelectedTab] = useState(-1);
  const [permiteAlterarTabRota, setPermiteAlterarTabRota] = useState(true);

  const indexOrdenacaoMenu = 3;
  const indexAutoAtendimento = 4;

  const formMethods = useForm<FormData>({
    defaultValues: {
      versaoDadosSAT: '0.07',
    },
  });
  const { reset, handleSubmit } = formMethods;

  const getSettings = useCallback(async () => {
    setIsLoading(true);
    const response = await sistemaApiKey().get<void, ResponseApi<FormData>>(
      ConstanteEnderecoWebservice.FRENTE_CAIXA_OBTER_CONFIGURACAO
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        reset(response.dados);
      }
    }
    setIsLoading(false);
  }, [reset]);

  const handleSetPermiteAlterarTabRota = (valor: boolean) => {
    setPermiteAlterarTabRota(valor);
  };

  const handleMobileSelectedTab = (index: number) => {
    if (!permiteAlterarTabRota && mobileSelectedTab === indexOrdenacaoMenu) {
      ModalDesistenciaOrdenacaoMenu({
        callback: () => {
          const newIndex = index !== mobileSelectedTab ? index : -1;

          setMobileSelectedTab(newIndex);
          setPermiteAlterarTabRota(true);
        },
      });

      return;
    }

    const newIndex = index !== mobileSelectedTab ? index : -1;
    setMobileSelectedTab(newIndex);
  };

  const handleSelectedTab = (index: number) => {
    if (!permiteAlterarTabRota && selectedTab === indexOrdenacaoMenu) {
      ModalDesistenciaOrdenacaoMenu({
        callback: () => {
          setSelectedTab(index);
          setPermiteAlterarTabRota(true);
        },
      });

      return;
    }

    setSelectedTab(index);
  };

  const handleSettingsUpdate = handleSubmit(async (data: any) => {
    setIsLoading(true);
    const response = await sistemaApiKey().post<void, ResponseApi<FormData>>(
      ConstanteEnderecoWebservice.FRENTE_CAIXA_ALTERAR_CONFIGURACAO,
      data
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }
      if (response.sucesso) {
        toast.success('Configuração alterada com sucesso');
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  });

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  return {
    isLargerThan900,
    selectedTab,
    mobileSelectedTab,
    handleSelectedTab,
    handleSettingsUpdate,
    handleMobileSelectedTab,
    formMethods,
    isLoading,
    permiteAlterarTabRota,
    handleSetPermiteAlterarTabRota,
    indexOrdenacaoMenu,
    indexAutoAtendimento,
  };
};
