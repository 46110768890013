export type StatusConsultaSimplificado = 1 | 3;

const StatusConsultaSimplificadoEnum = {
  ATIVOS: 1,
  // INATIVOS: 2,
  TODOS: 3,

  properties: {
    1: { name: 'Ativos', value: 1 },
    // 2: { name: 'Inativos', value: 2 },
    3: { name: 'Ativos e Inativos', value: 3 },
  },
};

export default StatusConsultaSimplificadoEnum;
