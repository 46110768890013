import React from 'react';
import { Box, Flex, FormLabel, GridItem, Text } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { TextArea } from 'components/update/TextArea';

import { LabelSelect } from '../components/LabelSelect';

export const TelaFinal = () => {
  const { watch } = useFormContext();
  const hasOrderCompletionMessageWatch = watch('hasOrderCompletionMessage');

  return (
    <GridItem
      colSpan={[12, 12, 6, 4, 4]}
      minW={['100%', '100%', '50%', '373px']}
    >
      <Text fontWeight="bold">Tela final</Text>
      <Flex
        bg="#E8E8E8"
        minH={['inherit', 'inherit', '280px']}
        padding="24px"
        pt="16px"
        borderRadius="5px"
        border="1px solid #CCCCCC"
        flexDir="column"
        gap="16px"
        height={['fit-content', 'full', 'full', 'full', 'fit-content']}
      >
        <LabelSelect
          name="hasOrderCompletionMessage"
          label="Confirmação do pedido:"
          selectWidth="142px"
          options={[
            {
              label: 'Mensagem',
              value: true,
            },
            {
              label: 'Imagem',
              value: false,
            },
          ]}
        />
        <Flex
          height="fit-content"
          flexWrap="wrap"
          w="full"
          justifyContent="space-between"
          align="baseline"
          flexDir="column"
        >
          <Flex>
            <FormLabel letterSpacing="0px" mr="2px">
              Personalize o texto da mensagem:
            </FormLabel>
          </Flex>
          <Box minW="full">
            <TextArea
              isDisabled={!hasOrderCompletionMessageWatch}
              _disabled={{
                backgroundColor: 'gray.200',
                borderColor: 'gray.300',
              }}
              w="full"
              name="orderCompletionMessage"
              id="orderCompletionMessage"
              placeholder="Digite o nome do cliente"
              label=""
              minH="128px"
            />
          </Box>
        </Flex>
      </Flex>
    </GridItem>
  );
};
