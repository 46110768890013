import {
  useState,
  useCallback,
  useEffect,
  useRef,
  useImperativeHandle,
  SetStateAction,
  Dispatch,
} from 'react';
import {
  Button,
  Icon,
  Checkbox,
  Flex,
  GridItem,
  Text,
  useToken,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useMediaQuery,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ActionMeta, OptionsType, OptionTypeBase } from 'react-select';

import { useProdutosFormularioContext } from 'store/Produtos/ProdutosFormulario';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteFuncionalidades from 'constants/permissoes';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import StatusConsultaEnum from 'constants/enum/statusConsulta';

import CreatableMultiSelect from 'components/update/Select/CreatableMultiSelect';
import ModalListarSaldoVariacoes from 'components/Modal/ModalListarSaldoVariacoes';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { BuscaIcon, CorReferenciaIcon } from 'icons';
import {
  ModalAdicionarCor,
  CoresImagemProps,
} from 'components/Modal/ModalAdicionarCor';
import {
  PagedTable,
  PagedTableForwardRefData,
} from 'components/update/Table/PagedTable';
import { PaginationData } from 'components/update/Pagination';
import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import Loading from 'components/Layout/Loading/LoadingPadrao';
import { FiFilter } from 'react-icons/fi';

import EditarDimensoesVariacaoModal from './EditarDimensoesVariacaoModal';
import EditarEstoqueVariacaoModal from './EditarEstoqueVariacaoModal';
import { VariacaoItem, VariacoesResponseProps } from './VariacaoItem';
import { useVariacoesCor, useVariacoesTamanho } from './hooks';
import { multiSelectComponents } from './components/MultiSelectComponents';

export type ProdutosVariacaoProps = {
  id: string;
  descricao: string;
  ativo: boolean;
  dataHoraCadastro: string;
  dataHoraUltimaAlteracao: string;
  padraoSistema: boolean;
  hexadecimal: string | null;
  imagem: string | null;
};

type TipoVariacao = 'cores' | 'tamanhos';

export const Variacoes = () => {
  const [listarVariacoes, setListarVariacoes] = useState<
    VariacoesResponseProps[]
  >([]);
  const [tamanhosOptions, setTamanhosOptions] = useState<
    OptionsType<OptionTypeBase>
  >([]);
  const [coresOptions, setCoresOptions] = useState<OptionsType<OptionTypeBase>>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [edicaoMassaAction, setEdicaoMassaAction] = useState<
    'dimensoes' | 'estoque' | undefined
  >();
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [todasVariacoesSelecionadas, setTodasVariacoesSelecionadas] = useState(
    false
  );
  const [modalIsOpenListarVariacoes, setModalIsOpenListarVariacoes] = useState(
    false
  );
  const [statusFiltro, setStatus] = useState(StatusConsultaEnum.ATIVOS);

  const pagedTableRef = useRef<PagedTableForwardRefData>(null);

  const atualizarVariacoes = useCallback(() => {
    pagedTableRef.current?.reload();
  }, []);

  const mostrarCoresAtivas = statusFiltro === StatusConsultaEnum.ATIVOS;

  const {
    removerVariacaoCor,
    buscarVariacoesDeCores,
    buscarCores,
    cadastrarCor,
    cadastrarVariacaoCor,
    ativarVariacaoCor,
  } = useVariacoesCor(atualizarVariacoes);

  const {
    removerVariacaoTamanho,
    buscarVariacoesDeTamanho,
    buscarTamanhos,
    cadastrarTamanho,
    cadastrarVariacaoTamanho,
    ativarVariacaoTamanho,
  } = useVariacoesTamanho(atualizarVariacoes);
  const pageIsLoaded = useRef(false);

  const isMountedRef = useIsMountedRef();

  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

  const {
    readonly,
    action,
    isDuplicado,
    ref,
    onChangeFormIsDirty,
    idProduto,
  } = useProdutosFormularioContext();

  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const [pink700, teal600, yellow500] = useToken('colors', [
    'pink.700',
    'teal.600',
    'yellow.500',
  ]);

  const CORES = 'cores';
  const TAMANHOS = 'tamanhos';

  const listVariacaoSelecionada = listarVariacoes.filter(
    (item) => item.isChecked
  );

  const amountVariacaoSelecionada = listVariacaoSelecionada.length;

  const listProdutoCorId = listVariacaoSelecionada.map(
    (item) => item.produtoCorTamanho.produtoCorTamanhoId
  );

  const handleToggleSelecionarTodasVariacoes = useCallback(() => {
    setListarVariacoes((prev) =>
      prev.map((prevItem) => ({
        ...prevItem,
        isChecked: !todasVariacoesSelecionadas,
      }))
    );

    setTodasVariacoesSelecionadas(!todasVariacoesSelecionadas);
  }, [todasVariacoesSelecionadas]);

  const handleToggleSelecionarVariacoes = (produtoCorTamanho: string) => {
    setListarVariacoes((prev) =>
      prev.map((prevItem) => ({
        ...prevItem,
        isChecked:
          prevItem.produtoCorTamanho.produtoCorTamanhoId === produtoCorTamanho
            ? !prevItem.isChecked
            : prevItem.isChecked,
      }))
    );
  };

  const handleBuscarTamanhosSistema = useCallback(async () => {
    const tamanhos = await buscarTamanhos();
    setTamanhosOptions(tamanhos);
  }, [buscarTamanhos]);

  const handleBuscarCoresSistema = useCallback(async () => {
    const cores = await buscarCores();
    setCoresOptions(cores);
  }, [buscarCores]);

  const buscarVariacoesTamanhoProduto = useCallback(async () => {
    setIsLoading(true);
    const tamanhos = await buscarVariacoesDeTamanho(idProduto);
    setValue('tamanhos', tamanhos);
    setIsLoading(false);
  }, [buscarVariacoesDeTamanho, idProduto, setValue]);

  const handleLimparSelecao = () => {
    setListarVariacoes((prev) =>
      prev.map((prevItem) => ({
        ...prevItem,
        isChecked: false,
      }))
    );
    setTodasVariacoesSelecionadas(false);
  };

  const handleAdicionarImagemCor = useCallback(
    async (data: CoresImagemProps) => {
      const response = await api.patch<void, ResponseApi>(
        `${ConstanteEnderecoWebservice.PRODUTO_CADASTRAR_COR}/${data.id}/imagem`,
        { ...data }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));

          return false;
        }
        if (response.sucesso) {
          return true;
        }
      }

      return false;
    },
    []
  );

  const adicionarVariacao = useCallback(
    async (variacaoAdicionada, tipoVariacao, cadastrarVariacaoFn) => {
      const valoresAtuais = watch()[tipoVariacao];
      const isVariacaoCadastrada = await cadastrarVariacaoFn(
        variacaoAdicionada,
        idProduto
      );
      const novaListaVariacoes = [...valoresAtuais];
      const indexVariacao = valoresAtuais.findIndex(
        (item: { value: string }) => item.value === variacaoAdicionada.value
      );
      if (!isVariacaoCadastrada) {
        toast.warning(
          `Não foi possível cadastrar  ${
            tipoVariacao === CORES ? 'essa cor' : 'esse tamanho'
          }, tente novamente`
        );

        novaListaVariacoes.splice(indexVariacao, 1);
        setValue(tipoVariacao, novaListaVariacoes);
      } else {
        toast.success(
          `Variação de ${
            tipoVariacao === CORES ? 'cor' : 'tamanho'
          } adicionada com sucesso!`
        );
        novaListaVariacoes[indexVariacao] = {
          ...variacaoAdicionada,
          ativo: true,
        };
        setValue(tipoVariacao, novaListaVariacoes);
      }
    },
    [watch, idProduto, setValue]
  );

  const removerVariacaoCorDoProduto = async (
    corRemovidaOption: OptionTypeBase
  ) => {
    const { cores } = watch();

    const isCorRemovida = await removerVariacaoCor(
      corRemovidaOption,
      idProduto
    );

    if (!isCorRemovida) {
      const foiInativado = isCorRemovida === null;
      if (foiInativado) {
        setValue('cores', [
          ...cores,
          {
            ...corRemovidaOption,
            ativo: false,
          },
        ]);
      } else {
        setValue('cores', [...cores, corRemovidaOption]);
      }
    } else {
      toast.success('Variação de cor removida com sucesso!');
    }
  };

  const removerVariacaoTamanhoDoProduto = async (
    tamanhoRemovido: OptionTypeBase
  ) => {
    const { tamanhos } = watch();

    const isTamanhoRemovido = await removerVariacaoTamanho(
      tamanhoRemovido,
      idProduto
    );

    if (!isTamanhoRemovido) {
      const foiInativado = isTamanhoRemovido === null;
      if (foiInativado) {
        setValue('tamanhos', [
          ...tamanhos,
          {
            ...tamanhoRemovido,
            ativo: false,
          },
        ]);
      } else {
        setValue('tamanhos', [...tamanhos, tamanhoRemovido]);
      }
    } else {
      toast.success('Variação de tamanho removida com sucesso!');
    }
  };

  const handleOnChangeVariacao = async (
    actionMeta: ActionMeta<OptionTypeBase>,
    tipoVariacao: TipoVariacao,
    cadastrarFunc: (
      item: OptionTypeBase,
      idProduto: string
    ) => Promise<boolean>,
    removerFunc: (item: OptionTypeBase) => Promise<void>
  ) => {
    setIsLoading(true);
    if (actionMeta.option && actionMeta.action === 'select-option') {
      await adicionarVariacao(actionMeta.option, tipoVariacao, cadastrarFunc);
    }
    if (
      actionMeta.action === 'pop-value' ||
      actionMeta.action === 'remove-value'
    ) {
      await removerFunc(actionMeta.removedValue as OptionTypeBase);
    }
    if ((actionMeta.action as string) === 'activate-value') {
      if (tipoVariacao === TAMANHOS) {
        const sucess = await ativarVariacaoTamanho(
          idProduto,
          actionMeta.option?.value
        );
        if (sucess) {
          toast.success(`Variação de tamanho ativada com sucesso!`);
          const novaListaVariacoes = [...watch()[tipoVariacao]];
          const indexVariacao = novaListaVariacoes.findIndex(
            (item: { value: string }) => item.value === actionMeta.option?.value
          );
          novaListaVariacoes[indexVariacao] = {
            ...actionMeta.option,
            ativo: true,
          };
          setValue(tipoVariacao, novaListaVariacoes);
        }
      } else {
        const sucess = await ativarVariacaoCor(
          idProduto,
          actionMeta.option?.value
        );
        if (sucess) {
          toast.success(`Variação de cor ativada com sucesso!`);
          const novaListaVariacoes = [...watch()[tipoVariacao]];
          const indexVariacao = novaListaVariacoes.findIndex(
            (item: { value: string }) => item.value === actionMeta.option?.value
          );
          novaListaVariacoes[indexVariacao] = {
            ...actionMeta.option,
            ativo: true,
          };
          setValue(tipoVariacao, novaListaVariacoes);
        }
      }
    }
    setIsLoading(false);
  };

  const handleOnChangeTamanhos = (actionMeta: ActionMeta<OptionTypeBase>) => {
    handleOnChangeVariacao(
      actionMeta,
      TAMANHOS,
      cadastrarVariacaoTamanho,
      removerVariacaoTamanhoDoProduto
    );
  };

  const handleOnChangeCores = (actionMeta: ActionMeta<OptionTypeBase>) => {
    handleOnChangeVariacao(
      actionMeta,
      CORES,
      cadastrarVariacaoCor,
      removerVariacaoCorDoProduto
    );
  };

  const handleCadastrarOpcao = useCallback(
    async (
      descricao: string,
      tipoVariacao: TipoVariacao,
      cadastrarFunc: (descricao: string) => Promise<OptionTypeBase | undefined>,
      setOptions: Dispatch<SetStateAction<OptionsType<OptionTypeBase>>>
    ) => {
      setIsLoading(true);
      const opcaoCadastrada = await cadastrarFunc(descricao);
      if (opcaoCadastrada) {
        setOptions((prev) => [...prev, opcaoCadastrada]);

        await adicionarVariacao(
          opcaoCadastrada,
          tipoVariacao,
          tipoVariacao === CORES
            ? cadastrarVariacaoCor
            : cadastrarVariacaoTamanho
        );

        setIsLoading(false);
        return opcaoCadastrada;
      }
      setIsLoading(false);
      return undefined;
    },
    [adicionarVariacao, cadastrarVariacaoCor, cadastrarVariacaoTamanho]
  );

  const handleCadastrarNovaCor = (descricao: string) =>
    handleCadastrarOpcao(descricao, CORES, cadastrarCor, setCoresOptions);

  const handleCadastrarNovoTamanho = (descricao: string) =>
    handleCadastrarOpcao(
      descricao,
      TAMANHOS,
      cadastrarTamanho,
      setTamanhosOptions
    );

  const paginationHandle = useCallback(
    async (gridPaginadaConsulta: PaginationData) => {
      setIsLoading(true);

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<VariacoesResponseProps>>
      >(
        formatQueryPagegTable(
          `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/produtoCorTamanhos/grade`,
          gridPaginadaConsulta,

          {
            id: idProduto,
            status: statusFiltro,
          }
        )
      );

      if (response?.sucesso && isMountedRef.current) {
        setTotalRegistros(response.dados.total);
        setListarVariacoes(
          response.dados.registros.map((itemVariacao) => ({
            ...itemVariacao,
            isChecked: false,
          }))
        );
        setIsLoading(false);
      }

      if (isMountedRef.current) {
        setIsLoading(false);

        if (!pageIsLoaded.current) {
          pageIsLoaded.current = true;
        }
      }
    },
    [statusFiltro, idProduto, isMountedRef]
  );

  const handleOnHideEdicaoMassaModal = () => {
    setEdicaoMassaAction(undefined);
  };

  const handleCadastrar = async () => {
    return true;
  };

  const getRequests = useCallback(async () => {
    setIsLoading(true);
    const [coresDaVariacao] = await Promise.all([
      buscarVariacoesDeCores(idProduto),
      handleBuscarCoresSistema(),
      buscarVariacoesTamanhoProduto(),
      handleBuscarTamanhosSistema(),
    ]);
    setValue('cores', coresDaVariacao);
    setIsLoading(false);
  }, [
    buscarVariacoesDeCores,
    buscarVariacoesTamanhoProduto,
    handleBuscarCoresSistema,
    handleBuscarTamanhosSistema,
    idProduto,
    setValue,
  ]);

  const buscarNovamenteVariacoesAtualizarListagem = useCallback(async () => {
    setIsLoading(true);
    await getRequests();
    atualizarVariacoes();
    setIsLoading(false);
  }, [atualizarVariacoes, getRequests]);

  useImperativeHandle(ref, () => ({
    onClick: () => handleCadastrar(),
  }));

  useEffect(() => {
    onChangeFormIsDirty(false);
  }, [onChangeFormIsDirty]);

  useEffect(() => {
    getRequests();
  }, [getRequests]);

  return (
    <>
      {isLoading && <Loading />}
      <SimpleGridForm>
        <GridItem colSpan={12}>
          <Flex w="100%" gap="8px" flex="1">
            <Flex
              sx={{
                '& > div': {
                  flex: '1',
                },
              }}
              flex="1"
            >
              <CreatableMultiSelect
                id="cores"
                tipoFiltro={statusFiltro}
                name="cores"
                label="Informe o nome da COR"
                placeholder="Digite a cor ou selecione uma existente"
                creatableInputTextPreffix="Adicionar a cor"
                control={control}
                isDisabledOption
                error={errors.cores}
                options={coresOptions}
                isClearable={false}
                onChange={(_, actionMeta) => handleOnChangeCores(actionMeta)}
                optionForegroundColor={yellow500}
                optionBackgroundColor={teal600}
                readonly={readonly}
                components={multiSelectComponents}
                isMulti
                required
                maxLength={50}
                createValue={async (inputValue: string) =>
                  handleCadastrarNovaCor(inputValue)
                }
                defaultValue={[]}
                funcionalidadeCadastrar={ConstanteFuncionalidades.COR_CADASTRAR}
                controlledByObject
              />
            </Flex>

            <Flex mt="22px">
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Filtro"
                  icon={mostrarCoresAtivas ? <BuscaIcon /> : <FiFilter />}
                  border="1px solid"
                  borderColor="gray.200"
                  color={mostrarCoresAtivas ? 'gray.500' : 'white'}
                  _hover={{
                    bg: mostrarCoresAtivas ? 'gray.50' : 'blue.400',
                  }}
                  _active={{
                    bg: mostrarCoresAtivas ? 'gray.50' : 'blue.400',
                  }}
                  bg={mostrarCoresAtivas ? 'white' : 'blue.500'}
                  borderRadius="5px"
                />

                <MenuList>
                  {StatusConsultaEnum.status.map((status) => (
                    <MenuItem
                      key={status.value}
                      onClick={() => setStatus(status.value)}
                    >
                      {status.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </Flex>
          </Flex>
        </GridItem>
        <GridItem colSpan={12}>
          <CreatableMultiSelect
            id="tamanhos"
            required
            name="tamanhos"
            label="Informe todos os TAMANHOS"
            placeholder="Digite o tamanho ou selecione um existente"
            creatableInputTextPreffix="Adicionar o tamanho"
            control={control}
            isMulti
            tipoFiltro={statusFiltro}
            isDisabledOption
            isClearable={false}
            error={errors.tamanhos}
            options={tamanhosOptions}
            onChange={(_, actionMeta) => handleOnChangeTamanhos(actionMeta)}
            optionForegroundColor={yellow500}
            optionBackgroundColor={pink700}
            readonly={readonly}
            maxLength={30}
            createValue={async (inputValue: string) =>
              handleCadastrarNovoTamanho(inputValue)
            }
            defaultValue={[]}
            funcionalidadeCadastrar={ConstanteFuncionalidades.TAMANHO_CADASTRAR}
            controlledByObject
            components={multiSelectComponents}
          />
        </GridItem>

        <GridItem
          bg="gray.50"
          h="auto"
          pb="12px"
          borderWidth="1px"
          borderRadius="4px"
          borderColor="gray.200"
          colSpan={12}
        >
          <Flex
            borderTopRadius="4px"
            bg="gray.100"
            h={['auto', 'auto', '48px']}
            px={['5px', '42px']}
            alignItems={['left', 'left', 'center']}
            flexDirection={['column', 'column', 'row']}
            justifyContent={['start', 'start', 'space-between']}
            marginBottom="3px"
            width="100%"
          >
            <Flex
              alignItems={['left', 'left', 'center']}
              color="black"
              fontWeight="600"
              flexDirection={['column', 'column', 'row']}
              overflow="hidden"
              ml="0"
              pt={['5px', '5px', '0']}
              pr={['0', '30px']}
            >
              <Flex
                h="full"
                alignItems="center"
                justifyContent={['start', 'start', 'space-between']}
                fontWeight="semibold"
                color="black"
                fontSize="16px"
                pl="5px"
              >
                <Checkbox
                  mb="0px !important"
                  mr="10px"
                  onChange={handleToggleSelecionarTodasVariacoes}
                  isChecked={todasVariacoesSelecionadas}
                  boxShadow="inset 0px 3px 6px #00000029"
                  colorScheme="primary"
                />
                <Text>
                  {todasVariacoesSelecionadas || amountVariacaoSelecionada > 0
                    ? `${
                        amountVariacaoSelecionada === 1
                          ? `${amountVariacaoSelecionada} item foi selecionado`
                          : `${amountVariacaoSelecionada} itens foram selecionados`
                      } `
                    : 'Variações'}{' '}
                </Text>
                {action !== 'cadastrar' && (
                  <Button
                    fontSize="sm"
                    variant="none"
                    _focus={{ border: 'none' }}
                    onClick={() => setModalIsOpenListarVariacoes(true)}
                  />
                )}
              </Flex>

              {(todasVariacoesSelecionadas ||
                amountVariacaoSelecionada > 0) && (
                <Flex
                  alignItems={['left', 'center']}
                  justifyContent={['flex-start', 'undefined']}
                  flexDirection={['column', 'row']}
                  pl={['0', '0', '20px']}
                >
                  <Button
                    h="25px"
                    mr={['0', '10px', '10px', '84px']}
                    fontSize="12px"
                    variant="linkDefault"
                    colorScheme="red"
                    onClick={handleLimparSelecao}
                  >
                    Limpar seleção
                  </Button>
                  <Button
                    h="25px"
                    display="flex"
                    justifyContent="flex-start"
                    mr={['10px', '10px', '10px', '72px']}
                    fontSize="12px"
                    onClick={() => {
                      setEdicaoMassaAction('dimensoes');
                    }}
                    variant="linkDefault"
                    colorScheme="blue"
                  >
                    Dimensões
                  </Button>
                  <Button
                    h="25px"
                    display="flex"
                    justifyContent="flex-start"
                    onClick={() => {
                      setEdicaoMassaAction('estoque');
                    }}
                    fontSize="12px"
                    variant="linkDefault"
                    colorScheme="blue"
                  >
                    Estoque
                  </Button>
                </Flex>
              )}
            </Flex>
            {!todasVariacoesSelecionadas && listarVariacoes?.length > 0 && (
              <Flex justifyContent={['start', 'start', 'end']}>
                <Button
                  h="32px"
                  fontSize="12px"
                  variant="linkDefault"
                  colorScheme="blue"
                  gap="5px"
                  letterSpacing="0px"
                  fontWeight="bold"
                  onClick={() =>
                    ModalAdicionarCor({
                      produtoId: idProduto,
                      atualizarVariacoes,
                      onConfirm: handleAdicionarImagemCor,
                    })
                  }
                >
                  <Icon as={CorReferenciaIcon} boxSize="20px" />
                  {isLargerThan700
                    ? 'Informar referência visual de cor'
                    : 'Informar referência de cor'}
                </Button>
              </Flex>
            )}
          </Flex>

          <PagedTable
            ref={pagedTableRef}
            variant="card"
            bg="transparent"
            boxShadow="none"
            paddingRight="24px"
            paddingLeft="24px"
            pt={['10px', '10px', '']}
            pb="10px"
            paginationHasDivider={false}
            loadColumnsData={paginationHandle}
            itemsTotalCount={totalRegistros}
            defaultKeyOrdered="nome"
            tableHeaders={[
              {
                key: 'grade',
                content: 'Grade de variações',
                isOrderable: false,
              },
              { key: 'codigo-sku', content: 'Código SKU', isOrderable: false },

              {
                key: 'codigo-etiqueta',
                isOrderable: false,
                w: '100px',
                content: 'Código da etiqueta',
              },
              {
                key: 'gtin/ean',
                content: <Flex justifyContent="flex-end">GTIN/EAN</Flex>,
                isOrderable: false,
              },
              {
                key: 'estoque',
                content: <Flex justifyContent="flex-end">Estoque</Flex>,
                isOrderable: false,
              },
              {
                key: 'acoes',
                content: '',
                isOrderable: false,
                w: '1px',
              },
            ]}
            renderTableRows={(listarVariacoes || []).map((variacao) => {
              return (
                <VariacaoItem
                  handleToggleSelecionarVariacoes={
                    handleToggleSelecionarVariacoes
                  }
                  atualizarVariacoes={buscarNovamenteVariacoesAtualizarListagem}
                  todasVariacoesSelecionadas={todasVariacoesSelecionadas}
                  variacao={variacao}
                />
              );
            })}
          />

          <EditarDimensoesVariacaoModal
            produtoCorTamanhosId={listProdutoCorId}
            show={edicaoMassaAction === 'dimensoes'}
            onHide={handleOnHideEdicaoMassaModal}
            atualizarVariacoes={atualizarVariacoes}
          />
          <EditarEstoqueVariacaoModal
            produtoCorTamanhosId={listProdutoCorId}
            show={edicaoMassaAction === 'estoque'}
            action={action || ''}
            isDuplicado={isDuplicado || false}
            onHide={handleOnHideEdicaoMassaModal}
            atualizarVariacoes={atualizarVariacoes}
          />
          <ModalListarSaldoVariacoes
            isOpen={modalIsOpenListarVariacoes}
            setIsOpen={setModalIsOpenListarVariacoes}
            idProduto={idProduto || ''}
          />
        </GridItem>
      </SimpleGridForm>
    </>
  );
};
