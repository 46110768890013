import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Flex,
  IconButton,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';
import { components } from 'react-select';
import { GlobalHotKeys } from 'react-hotkeys';
import { useFormContext } from 'react-hook-form';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import LogAuditoriaTelaEnum from 'constants/enum/logAuditoriaTela';
import ConstanteFuncionalidades from 'constants/permissoes';
import RegraLimiteCreditoEnum from 'constants/enum/regraLimiteCredito';
import PlanoContratacaoEnum from 'constants/enum/planoContratacao';
import { validarDiasAtraso } from 'helpers/validation/regraLimiteCreditoCliente';
import api, { ResponseApi } from 'services/api';
import auth from 'modules/auth';
import { useMenuContext } from 'store/PDV/Menu';
import { usePagamentoContext } from 'store/PDV/Pagamento';
import { Cliente } from 'store/PDV/Operacao';
import OptionType from 'types/optionType';

import { EditarClienteIcon, UsuariosPerfilUsuarioIcon } from 'icons';
import AsyncCreatableSelectField, {
  AsyncCreatableSelectFieldProps,
} from 'components/PDV/Select/AsyncCreatableSelectPadrao';
import ModalCadastrarCliente from 'components/PDV/Modal/ModalCadastrarCliente';
import ModalAlterarCliente from 'components/PDV/Modal/ModalAlterarCliente';
import ModalAutorizacaoFuncionalidade from 'components/Modal/ModalAutorizacaoFuncionalidade';
import ModalConfirmacao from 'components/PDV/Modal/ModalConfirmacao';
import ModalInformacoesCliente from 'components/PDV/Modal/ModalInformacoesCliente';

import { chakraComponents } from '../ReactSelectIntegracao';

interface SelectClienteProps
  extends Omit<
    AsyncCreatableSelectFieldProps,
    'handleGetOptions' | 'creatableInputTextPreffix' | 'handleCreateOption'
  > {
  id: string;
  name: string;
  label?: string;
  placeholder: string;
  required: boolean;
  autoFocus?: boolean;
  variant?: string;
  size?: string;
  habilitarMenu?: boolean;
  handleClienteSelect?: (
    cliente: Cliente,
    selecaoFinal?: boolean
  ) => Promise<void>;
  defaultValue?: any;
  show?: boolean;
  isTrocas?: boolean;
  width?: string;
  disabled?: boolean;
  blockMobileView?: boolean;
  shouldAppearTheAddress?: boolean;
  handleAtualizarClienteCadastrado?: (nameCliente: string) => void;
}

interface ClienteListarProps {
  id: string;
  nome: string;
  codigo: number;
  cpfCnpj: string;
  regraLimiteCredito: number;
  limiteCredito: number;
  regraLimiteCreditoDescricao: string;
  tabelaPrecoId: string;
  tabelaPrecoNome: string;
  endereco: string;
  padraoSistema: boolean;
}

interface TabelaAtualProps {
  name: string;
  value: string;
}

export interface SelectClienteRefInterface {
  setCliente: (cliente: any) => void;
  showEditarCliente: () => void;
  showInformacoesCliente: () => void;
  focus: () => void;
}

export default forwardRef<SelectClienteRefInterface, SelectClienteProps>(
  (props, ref) => {
    const {
      id,
      name,
      label,
      placeholder,
      required,
      autoFocus,
      variant,
      size,
      habilitarMenu = true,
      defaultValue,
      handleClienteSelect,
      handleAtualizarClienteCadastrado,
      show = true,
      width,
      disabled,
      blockMobileView = false,
      shouldAppearTheAddress,
      ...rest
    } = props;
    const { pathname } = window.location;
    const planoAtual = auth.getPlano();
    const planoStart = PlanoContratacaoEnum.START;
    const possuiPlanoProOuPrime = planoAtual !== planoStart;
    const routeIsPagamentoPDV = pathname.includes('pdv/pagamento');

    const { atualizarPrecoProdutos, tabelasPreco } = useMenuContext();
    const { handleAtualizarValores } = usePagamentoContext();
    const lastClient = useRef<ClienteListarProps>({} as ClienteListarProps);

    const isMobileBreakpoint = useBreakpointValue({ base: true, md: false });

    const asMobileView = isMobileBreakpoint || isMobile;

    const [modalCadastrarIsOpen, setModalCadastrarIsOpen] = useState(false);
    const [modalAlterarIsOpen, setModalAlterarIsOpen] = useState(false);
    const [modalConfirmacaoIsOpen, setModalConfirmacaoIsOpen] = useState(false);
    const [
      modalConfirmacaoTextoMensagem,
      setModalConfirmacaoTextoMensagem,
    ] = useState('');

    const [
      modalAutorizacaoDesbloquearClienteIsOpen,
      setModalAutorizacaoDesbloquearClienteIsOpen,
    ] = useState(false);

    const [
      modalAutorizacaoCadastrarClienteIsOpen,
      setModalAutorizacaoCadastrarClienteIsOpen,
    ] = useState(false);

    const [
      modalAutorizacaoAlterarClienteIsOpen,
      setModalAutorizacaoAlterarClienteIsOpen,
    ] = useState(false);

    const [chavePermissaoTemporaria, setChavePermissaoTemporaria] = useState(
      ''
    );

    const [colorSelectedItem, setColorSelectedItem] = useState('');
    const [nomeCliente, setNomeCliente] = useState('');
    const [idCliente, setIdCliente] = useState('');
    const [
      clienteSelecionadoBloqueado,
      setClienteSelecionadoBloqueado,
    ] = useState<any>();

    const possuiPermissaoVisualizarInfoCliente = auth.possuiPermissao(
      ConstanteFuncionalidades.PDV_VISUALIZAR_INFORMACOES_CLIENTE
    ).permitido;

    const selectOptionsRef = useRef([]) as React.MutableRefObject<OptionType[]>;

    const { setValue, getValues, watch, setFocus } = useFormContext();
    const clienteWatch = watch(`${name}` as const);
    const tabelaAtual: TabelaAtualProps = watch('tabelaPreco');
    const {
      isOpen: isOpenModalInformacoesCliente,
      onOpen: onOpenModalInformacoesCliente,
      onClose: onCloseModalInformacoesCliente,
    } = useDisclosure();

    const obterOptionCliente = (
      cliente: ClienteListarProps,
      exibirCpfCnpj: boolean,
      exibirOEndereco = false
    ) => {
      const cpfCnpj =
        exibirCpfCnpj && cliente.cpfCnpj ? ` - (${cliente.cpfCnpj})` : '';

      const item = exibirOEndereco
        ? {
            label: `${cliente.nome}${cpfCnpj}`,
            endereco: `Cód. ${cliente.codigo} - ${cliente.endereco}`,
            value: cliente,
          }
        : {
            label: `${cliente.codigo} - ${cliente.nome}${cpfCnpj}`,
            value: cliente,
          };

      return item;
    };

    const handleGetClientesOptions = async (value: string) => {
      if (value || asMobileView) {
        const response = await api.get<void, ResponseApi<ClienteListarProps[]>>(
          ConstanteEnderecoWebservice.CLIENTE_FORNECEDOR_LISTAR_SELECT_PDV,
          {
            params: { cpfCnpjNomeApelidoCodigoExterno: value },
          }
        );

        if (response) {
          if (response.avisos) {
            response.avisos.map((item: string) => toast.warning(item));
          }

          if (response.sucesso && response.dados) {
            return response.dados.map((cliente) =>
              obterOptionCliente(cliente, true, shouldAppearTheAddress)
            );
          }
        }
      }

      return [];
    };

    const handleAtualizarPrecoTelaPagamento = async () => {
      await handleAtualizarValores();
    };

    const openCadastrarCliente = async (nomeClienteNovo: string) => {
      setNomeCliente(nomeClienteNovo);

      if (
        auth.possuiPermissao(ConstanteFuncionalidades.CLIENTE_CADASTRAR.codigo)
          .permitido
      ) {
        setModalCadastrarIsOpen(true);
      } else {
        setModalAutorizacaoCadastrarClienteIsOpen(true);
      }
      return undefined;
    };

    const openAlterarCliente = async (idClienteValue: string) => {
      setIdCliente(idClienteValue);

      if (
        auth.possuiPermissao(ConstanteFuncionalidades.CLIENTE_ALTERAR.codigo)
          .permitido
      ) {
        setModalAlterarIsOpen(true);
      } else {
        setModalAutorizacaoAlterarClienteIsOpen(true);
      }
    };

    const updateClienteAndPrecoTelaPagamento = async (
      cliente: Cliente,
      selecaoFinal?: boolean
    ) => {
      if (handleClienteSelect) {
        await handleClienteSelect(cliente, selecaoFinal);
      }
      if (routeIsPagamentoPDV) {
        await handleAtualizarPrecoTelaPagamento();
      }
    };

    const onChangeEffect = async (
      option: any,
      selecaoFinal?: boolean
    ): Promise<boolean> => {
      const cliente = option.value as ClienteListarProps;

      if (show && lastClient.current !== cliente) {
        const possuiPermissaoDesbloquearCliente = auth.possuiPermissao(
          ConstanteFuncionalidades.PDV_LIBERAR_CLIENTE_BLOQUEADO.codigo
        ).permitido;

        if (
          cliente.regraLimiteCredito === RegraLimiteCreditoEnum.BLOQUEIO_TOTAL
        ) {
          setClienteSelecionadoBloqueado(cliente);
          if (!possuiPermissaoDesbloquearCliente) {
            setModalAutorizacaoDesbloquearClienteIsOpen(true);
          } else {
            setModalConfirmacaoTextoMensagem(
              `O cliente "${cliente.nome}" selecionado está bloqueado, deseja continuar?`
            );
            setModalConfirmacaoIsOpen(true);
          }
          lastClient.current = option.value;
          return false;
        }

        if (
          cliente.regraLimiteCredito ===
          RegraLimiteCreditoEnum.BLOQUEIO_DIAS_ATRASO
        ) {
          if (!(await validarDiasAtraso(cliente.id))) {
            setClienteSelecionadoBloqueado(cliente);
            if (!possuiPermissaoDesbloquearCliente) {
              setModalAutorizacaoDesbloquearClienteIsOpen(true);
            } else {
              setModalConfirmacaoTextoMensagem(
                `O cliente selecionado "${cliente.nome}" está bloqueado devido a contas em atraso, deseja continuar?`
              );
              setModalConfirmacaoIsOpen(true);
            }
            lastClient.current = option.value;
            return false;
          }
        }
      }
      if (!selecaoFinal && routeIsPagamentoPDV && possuiPlanoProOuPrime) {
        lastClient.current = option.value;
        setValue('tabelaPreco', {
          name: cliente.tabelaPrecoNome,
          value: cliente.tabelaPrecoId,
        });
        await updateClienteAndPrecoTelaPagamento(cliente, selecaoFinal);
      } else if (
        tabelaAtual &&
        cliente.tabelaPrecoId &&
        cliente.tabelaPrecoId !== tabelaAtual.value &&
        possuiPlanoProOuPrime
      ) {
        lastClient.current = option.value;
        await updateClienteAndPrecoTelaPagamento(cliente, selecaoFinal);
        await atualizarPrecoProdutos(cliente.tabelaPrecoId);
      } else if (cliente.tabelaPrecoId && possuiPlanoProOuPrime) {
        await atualizarPrecoProdutos(cliente.tabelaPrecoId);
      } else if (!cliente.tabelaPrecoId && tabelasPreco && tabelasPreco[0]) {
        let newTabelaPrecoId = tabelasPreco[0].id;
        lastClient.current = option.value;
        if (tabelaAtual) {
          newTabelaPrecoId = tabelaAtual.value;
        } else {
          setValue('tabelaPreco', {
            name: tabelasPreco[0].nome,
            value: tabelasPreco[0].id,
          });
        }
        await updateClienteAndPrecoTelaPagamento(cliente, selecaoFinal);
        await atualizarPrecoProdutos(newTabelaPrecoId);
      }
      if (handleClienteSelect) {
        await handleClienteSelect(cliente, selecaoFinal);
      }

      return true;
    };

    const latestProps = useRef({ setValue, onChangeEffect });

    useEffect(() => {
      latestProps.current = { setValue, onChangeEffect };
    });

    useEffect(() => {
      if (!possuiPlanoProOuPrime && tabelaAtual && tabelasPreco.length > 0) {
        if (tabelaAtual.value !== tabelasPreco[0].id) {
          setValue('tabelaPreco', {
            name: tabelasPreco[0].nome,
            value: tabelasPreco[0].id,
          });
          latestProps.current.setValue('tabelaPreco', {
            name: tabelasPreco[0].nome,
            value: tabelasPreco[0].id,
          });
        }
      }
    }, [possuiPlanoProOuPrime, setValue, tabelaAtual, tabelasPreco]);

    const atualizarClienteCallback = useCallback(
      async (novoCliente: ClienteListarProps) => {
        selectOptionsRef.current = await [
          obterOptionCliente(novoCliente, false),
        ];
        const { tabelaPrecoId, tabelaPrecoNome } = novoCliente;
        if (tabelaPrecoId && tabelaPrecoNome && possuiPlanoProOuPrime) {
          latestProps.current.setValue('tabelaPreco', {
            name: tabelaPrecoNome,
            value: tabelaPrecoId,
          });
          await atualizarPrecoProdutos(tabelaPrecoId);
        }
        latestProps.current.setValue('cliente', novoCliente);
        latestProps.current.onChangeEffect(selectOptionsRef.current[0]);

        if (handleAtualizarClienteCadastrado) {
          handleAtualizarClienteCadastrado(novoCliente.nome);
        }
      },
      [
        possuiPlanoProOuPrime,
        atualizarPrecoProdutos,
        handleAtualizarClienteCadastrado,
      ]
    );

    useImperativeHandle(ref, () => ({
      setCliente(cliente: ClienteListarProps) {
        atualizarClienteCallback(cliente);
      },
      showEditarCliente() {
        openAlterarCliente(getValues(`${name}` as const)?.id);
      },
      focus() {
        setFocus(`${name}` as const);
      },
      showInformacoesCliente() {
        onOpenModalInformacoesCliente();
      },
    }));

    const SingleValue = ({ hasValue, getValue, ...propsSinglevalue }: any) => {
      const cliente = hasValue
        ? (getValue()[0]?.value as ClienteListarProps)
        : ({} as ClienteListarProps);

      return (
        <components.SingleValue {...propsSinglevalue}>
          {cliente.nome}
        </components.SingleValue>
      );
    };

    const chakraComponentsCustom = {
      ...chakraComponents,
      DropdownIndicator: () => null,
      SingleValue,
      IndicatorSeparator: () => null,
    };

    const keyMap = {
      F3: ['f3'],
      F2: ['f2'],
    };

    const handlersHotKeys = {
      F3: (event: any) => {
        const cliente = getValues(`${name}` as const);
        if (!cliente) {
          return;
        }
        if (!possuiPermissaoVisualizarInfoCliente) {
          toast.warn(
            'Você não tem permissão para realizar essa ação. Consulte o administrador da conta.'
          );
          return;
        }
        event.preventDefault();
        onOpenModalInformacoesCliente();
      },
      F2: (event: any) => {
        event.preventDefault();
        if (!disabled) {
          setFocus(`${name}` as const);
        }
      },
    };

    const confirmarSelecionarClienteBloqueado = async () => {
      selectOptionsRef.current = [
        obterOptionCliente(clienteSelecionadoBloqueado, false),
      ];

      if (handleClienteSelect) {
        await handleClienteSelect(clienteSelecionadoBloqueado, true);
      }
      setValue(`${name}` as const, clienteSelecionadoBloqueado);
    };

    const openEditar = () => {
      onCloseModalInformacoesCliente();
      openAlterarCliente(getValues(`${name}` as const)?.id);
    };

    const handleClientSelectOnBlur = async () => {
      if (handleClienteSelect) {
        await handleClienteSelect(getValues(`${name}` as const));
      }
    };

    useEffect(() => {
      if (clienteWatch) {
        const item = RegraLimiteCreditoEnum.properties.filter(
          (valor) => valor.value === clienteWatch.regraLimiteCredito
        )[0];

        setColorSelectedItem(item?.iconColor);
      }
    }, [clienteWatch]);

    return (
      <>
        {show && (
          <>
            <GlobalHotKeys handlers={handlersHotKeys} keyMap={keyMap} />
            <InputGroup
              width={width}
              size={size}
              sx={{
                '& .react-select__control': { pr: 14 },
                '& .react-select__single-value': {
                  isTruncated: true,
                  display: 'block',
                  height: 'auto',
                  w: 'auto',
                  maxW: 'calc(100% - 60px)',
                },
                '& .react-select__placeholder': {
                  isTruncated: true,
                  display: 'block',
                  height: 'auto',
                  w: 'auto',
                  maxW: 'calc(100% - 60px)',
                },
              }}
            >
              <AsyncCreatableSelectField
                handleGetOptions={handleGetClientesOptions}
                handleCreateOption={openCadastrarCliente}
                onChangeEffect={(option: any) => onChangeEffect(option, true)}
                id={id}
                name={name}
                label={label}
                placeholder={placeholder}
                variant={variant}
                size={size}
                required={required}
                selectOptionsRef={selectOptionsRef}
                creatableInputTextPreffix="Cadastrar cliente"
                autoFocus={autoFocus}
                components={chakraComponentsCustom}
                openMenuOnClick={false}
                colorSelectedValue={colorSelectedItem}
                onBlur={handleClientSelectOnBlur}
                disabled={disabled}
                isOptionSelected={(option, value) => {
                  return option?.value?.id === value?.id;
                }}
                asMobileView={asMobileView && !blockMobileView}
                {...rest}
                shouldAppearTheAddress={shouldAppearTheAddress}
              />

              {habilitarMenu && getValues(`${name}` as const) && (
                <InputRightElement bottom="0" right="2" top="auto">
                  <Box
                    sx={{
                      '& > [data-popper-placement="left-start"]': {
                        transform: 'translate(-48px, 0px) !important',
                      },
                    }}
                  >
                    <Menu placement="left-start">
                      <MenuButton
                        as={IconButton}
                        borderRadius="md"
                        icon={
                          <EditarClienteIcon
                            fontSize={20}
                            color={colorSelectedItem}
                          />
                        }
                        variant="ghost"
                      />
                      <MenuList fontSize="sm">
                        <MenuGroup
                          title={
                            getValues(`${name}` as const)
                              ?.regraLimiteCreditoDescricao
                          }
                          as={() => (
                            <Flex
                              color={colorSelectedItem}
                              alignItems="center"
                              paddingLeft={4}
                              fontSize="14px"
                              gap="8px"
                            >
                              <UsuariosPerfilUsuarioIcon
                                width="14px"
                                height="14px"
                              />
                              <Text pt="2px">
                                {
                                  getValues(`${name}` as const)
                                    ?.regraLimiteCreditoDescricao
                                }
                              </Text>
                            </Flex>
                          )}
                        >
                          {possuiPermissaoVisualizarInfoCliente && (
                            <>
                              <MenuDivider w="88%" margin="auto" pt="8px" />
                              <MenuItem onClick={onOpenModalInformacoesCliente}>
                                Informações
                              </MenuItem>
                              <MenuItem onClick={openEditar}>
                                Editar cadastro
                              </MenuItem>
                            </>
                          )}
                        </MenuGroup>
                      </MenuList>
                    </Menu>
                  </Box>
                </InputRightElement>
              )}
            </InputGroup>
          </>
        )}

        {modalCadastrarIsOpen && (
          <ModalCadastrarCliente
            isOpen={modalCadastrarIsOpen}
            setIsOpen={setModalCadastrarIsOpen}
            nomeOuCpfCnpjCliente={nomeCliente}
            novoClienteCallback={atualizarClienteCallback}
          />
        )}
        <ModalAlterarCliente
          isOpen={modalAlterarIsOpen}
          setIsOpen={setModalAlterarIsOpen}
          clienteIdPdv={idCliente}
          alterarClienteCallback={atualizarClienteCallback}
          chavePermissaoTemporaria={chavePermissaoTemporaria}
        />
        {modalAutorizacaoDesbloquearClienteIsOpen && (
          <ModalAutorizacaoFuncionalidade
            isOpen={modalAutorizacaoDesbloquearClienteIsOpen}
            setIsOpen={setModalAutorizacaoDesbloquearClienteIsOpen}
            autorizado={confirmarSelecionarClienteBloqueado}
            titulo={
              ConstanteFuncionalidades.PDV_LIBERAR_CLIENTE_BLOQUEADO.titulo
            }
            descricao={
              ConstanteFuncionalidades.PDV_LIBERAR_CLIENTE_BLOQUEADO.descricao
            }
            tela={LogAuditoriaTelaEnum.PDV}
            permissoes={[
              ConstanteFuncionalidades.PDV_LIBERAR_CLIENTE_BLOQUEADO.codigo,
            ]}
          />
        )}

        {modalAutorizacaoAlterarClienteIsOpen && (
          <ModalAutorizacaoFuncionalidade
            isOpen={modalAutorizacaoAlterarClienteIsOpen}
            setIsOpen={setModalAutorizacaoAlterarClienteIsOpen}
            autorizado={(chave) => {
              setChavePermissaoTemporaria(chave);
              setModalAlterarIsOpen(true);
            }}
            titulo={ConstanteFuncionalidades.CLIENTE_ALTERAR.titulo}
            descricao={ConstanteFuncionalidades.CLIENTE_ALTERAR.descricao}
            tela={LogAuditoriaTelaEnum.PDV}
            permissoes={[ConstanteFuncionalidades.CLIENTE_ALTERAR.codigo]}
          />
        )}

        {modalAutorizacaoCadastrarClienteIsOpen && (
          <ModalAutorizacaoFuncionalidade
            isOpen={modalAutorizacaoCadastrarClienteIsOpen}
            setIsOpen={setModalAutorizacaoCadastrarClienteIsOpen}
            autorizado={(chave) => {
              setChavePermissaoTemporaria(chave);
              setModalCadastrarIsOpen(true);
            }}
            titulo={ConstanteFuncionalidades.CLIENTE_CADASTRAR.titulo}
            descricao={ConstanteFuncionalidades.CLIENTE_CADASTRAR.descricao}
            tela={LogAuditoriaTelaEnum.PDV}
            permissoes={[ConstanteFuncionalidades.CLIENTE_CADASTRAR.codigo]}
          />
        )}
        {modalConfirmacaoIsOpen && (
          <ModalConfirmacao
            isOpen={modalConfirmacaoIsOpen}
            textoCabecalho="Confirmar"
            textoMensagem={modalConfirmacaoTextoMensagem}
            setIsOpen={setModalConfirmacaoIsOpen}
            onConfirm={confirmarSelecionarClienteBloqueado}
          />
        )}
        {isOpenModalInformacoesCliente && (
          <ModalInformacoesCliente
            isOpen={isOpenModalInformacoesCliente}
            onClose={onCloseModalInformacoesCliente}
            idCliente={getValues(`${name}` as const)?.id}
            openEditar={openEditar}
          />
        )}
      </>
    );
  }
);
