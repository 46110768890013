import { toast } from 'react-toastify';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';

export type FormaRecebimentoProps = {
  nome: string;
  id: string;
  selecionado: boolean;
};

type ImpressaoSmartPosProps = {
  imprimirSenhaIdentificacao: boolean;
  mensagemFichaImpressao: string;
  mensagemCupomImpressao: string;
  tipoImpressao: number;
  tituloImpressao: string;
  imprimirViaProducao: boolean;
  logo?: string;
  alturaLogo?: string;
  larguraLogo?: string;
  escalaLogo?: number;
};

type ImpressaoProps = {
  mensagemFichaImpressao?: string;
  mensagemCupomImpressao?: string;
  tituloImpressao?: string;
  imprimirSenhaIdentificacao?: boolean;
  tipoImpressao?: number;
  imprimirViaProducao?: boolean;
  logo?: string;
  alturaLogo?: string;
  larguraLogo?: string;
  escalaLogo?: number;
};

type SmartPosDataProps = {
  id: string;
  dataAtivacao: Date;
  ativo: boolean;
  sincronizacaoHabilitada: boolean;
};

const getDadosSmartPos = async () => {
  const response = await api.get<void, ResponseApi<SmartPosDataProps>>(
    ConstanteEnderecoWebservice.OBTER_INTEGRACAO_SMART_POS
  );

  if (response) {
    if (response.sucesso && response.dados) {
      return response.dados;
    }
  }
  return null;
};

export const getImpressaoSmartPos = async () => {
  const response = await api.get<void, ResponseApi<ImpressaoSmartPosProps>>(
    ConstanteEnderecoWebservice.INTEGRACAO_OBTER_IMPRESSAO_SMART_POS
  );
  if (response) {
    if (response?.avisos) {
      response.avisos.forEach((aviso: string) => toast.warning(aviso));
      return null;
    }
    if (response.sucesso) {
      return response.dados;
    }
  }
  return null;
};

export const getImpressaoEscolhidaSmartPos = async () => {
  const response = await api.get<void, ResponseApi<number>>(
    ConstanteEnderecoWebservice.INTEGRACAO_OBTER_TIPO_IMPRESSAO_SMART_POS
  );
  if (response) {
    if (response?.avisos) {
      response.avisos.forEach((aviso: string) => toast.warning(aviso));
      return null;
    }
    if (response.sucesso) {
      return response.dados;
    }
  }
  return null;
};

export const cadastrarImpressaoSmartPos = async (data: ImpressaoProps) => {
  const response = await api.put<void, ResponseApi>(
    ConstanteEnderecoWebservice.INTEGRACAO_TEXTO_IMPRESSAO,
    data
  );
  if (response) {
    if (response?.avisos) {
      return false;
    }
    if (response.sucesso) {
      return true;
    }
  }
  return false;
};

export const cadastrarTextoImpressaoSmartPos = async (data: ImpressaoProps) => {
  const response = await api.put<void, ResponseApi>(
    ConstanteEnderecoWebservice.INTEGRACAO_ALTERAR_TEXTO_IMPRESSAO,
    data
  );
  if (response) {
    if (response?.avisos) {
      return false;
    }
    if (response.sucesso) {
      return true;
    }
  }
  return false;
};

export const getImagemSmartPos = async () => {
  const response = await api.get<void, ResponseApi<string>>(
    ConstanteEnderecoWebservice.OBTER_IMAGEM_SMART_POS
  );
  if (response) {
    if (response?.avisos) {
      response.avisos.forEach((aviso: string) => toast.warning(aviso));
      return null;
    }
    if (response.sucesso && response.dados) {
      return response.dados;
    }
  }
  return null;
};

export const cadastrarCategoriaSmartPos = async (
  categoriaSelecionada: string[]
) => {
  const response = await api.post<void, ResponseApi<boolean>>(
    ConstanteEnderecoWebservice.INTEGRACAO_CATEGORIA_PRODUTO_ENVIAR,
    categoriaSelecionada
  );
  if (response) {
    if (response.avisos) {
      response.avisos.forEach((item) => toast.warning(item));
      return false;
    }

    if (response.sucesso) {
      return true;
    }
  }
  return false;
};

export const getFormaRecebimentoSmartPos = async () => {
  const response = await api.get<void, ResponseApi<FormaRecebimentoProps[]>>(
    ConstanteEnderecoWebservice.INTEGRACAO_CAIXA_MOVEL_FORMA_RECEBIMENTO_LISTAR_SELECT
  );

  if (response) {
    if (response.avisos) {
      response.avisos.forEach((aviso) => toast.warning(aviso));
      return [];
    }

    if (response.sucesso && response.dados) {
      return response.dados;
    }
  }
  return [];
};

export const cadastrarFormaRecebimentoSmartPos = async (
  formasRecebimentoSelecionadas: string[]
) => {
  const response = await api.post<void, ResponseApi>(
    ConstanteEnderecoWebservice.INTEGRACAO_CAIXA_MOVEL_FORMA_RECEBIMENTO_ENVIAR_FORMAS_RECEBIMENTO,
    formasRecebimentoSelecionadas
  );

  if (response) {
    if (response?.avisos) {
      response.avisos.forEach((aviso: string) => toast.warning(aviso));
      return false;
    }

    if (response.sucesso) {
      return true;
    }
  }
  return false;
};

export const getSenhaAdm = async () => {
  const response = await api.get<void, ResponseApi<string>>(
    ConstanteEnderecoWebservice.INTEGRACAO_OBTER_SENHA_ADM
  );
  if (response) {
    if (response?.avisos) {
      response.avisos.forEach((aviso: string) => toast.warning(aviso));
      return null;
    }
    if (response.sucesso && response.dados) {
      return response.dados;
    }
  }
  return null;
};

export const cadastrarSenhaAdm = async (senha: string) => {
  const response = await api.put<void, ResponseApi>(
    ConstanteEnderecoWebservice.INTEGRACAO_CAIXA_MOVEL_ALTERAR_SENHA,
    {
      senhaAdministrativa: senha,
    }
  );

  if (response) {
    if (response?.avisos) {
      response.avisos.forEach((aviso: string) => toast.warning(aviso));
      return false;
    }

    if (response.sucesso) {
      return true;
    }
  }
  return false;
};

export const activateSmartPos = async () => {
  const integration = await getDadosSmartPos();
  if (integration?.id) {
    const response = await api.post<void, ResponseApi>(
      `${ConstanteEnderecoWebservice.INTEGRACAO_ATIVAR}/?integracaoId=${integration.id}`
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
        return false;
      }

      if (response.sucesso) {
        return true;
      }
    }
  } else {
    return true;
  }
  return false;
};

export async function getCodigoAtivacaoSmartPos() {
  const response = await api.get<void, ResponseApi<string>>(
    ConstanteEnderecoWebservice.INTEGRACAO_CAIXA_MOVEL_GERAR_PIN
  );
  if (response) {
    if (response?.avisos) {
      response.avisos.forEach((aviso: string) => toast.warning(aviso));
    }
    if (response.sucesso) {
      return response.dados?.split('');
    }
  }
  return [];
}

export const obterEtapaSmartPos = async () => {
  const response = await api.get<void, ResponseApi<number>>(
    ConstanteEnderecoWebservice.OBTER_ETAPA_SMART_POS
  );

  if (response) {
    if (response.sucesso) {
      return response.dados;
    }
    return null;
  }
  return null;
};
