import React from 'react';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

import CategoriasProduto from '../pages/CategoriasProduto';

const CategoriaProdutosRoutes = [
  <LayoutGuard
    key="categoria-produto"
    permissaoFuncionalidade={ConstanteFuncionalidades.CATEGORIA_PRODUTO_LISTAR}
    component={CategoriasProduto}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Categoria de Produtos',
      },
    ]}
    path={ConstanteRotas.CATEGORIA_PRODUTO}
    exact
    meta={{ auth: true }}
  />,
];

export default CategoriaProdutosRoutes;
