interface FormatOptionsClientProps {
  codigo?: number;
  nome?: string;
  cpfCnpj?: string;
  endereco?: string;
  id?: string;
}
export function formatOptionsSelectClient(
  { codigo, cpfCnpj, endereco, id, nome }: FormatOptionsClientProps,
  exibirCodigo = false
) {
  const option = {
    label: exibirCodigo
      ? `${codigo} - ${nome} ${String(cpfCnpj ? `-  (${cpfCnpj})` : '')}`
      : `${nome} ${String(cpfCnpj ? `- (${cpfCnpj})` : '')}`,
    endereco: exibirCodigo ? `${endereco}` : `Cód.  ${codigo} - ${endereco}`,
    value: id,
  };
  return option;
}
