import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory, Prompt } from 'react-router-dom';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import isPrenvent from 'helpers/layout/isPrenvent';
import ConstanteRotas from 'constants/rotas';
import MeioPagamentoEnum from 'constants/enum/fiscal/meioPagamento';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import TipoFormaPagamentoRecebimentoEnum from 'constants/enum/tipoFormaPagamentoRecebimento';
import useReloadRegistration from 'helpers/layout/useReloadRegistration';
import auth from 'modules/auth';

import ManterFoco from 'components/Geral/ManterFoco';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import RodapeFormulario from 'components/Geral/RodapeFormulario';
import { validacaoContaFinanceira } from 'pages/FormaRecebimento/naoPossuiContaFinanceira';

import { Card } from 'styles';
import { Container } from '../styles';
import { useForm, yupResolver } from '../validationForm';
import { StoneConfig, FormaPagamentoRecebimentoLojas } from '../Types';

// eslint-disable-next-line import/no-named-as-default
import UncontrolledForm from '..';

type FormaRecebimentoProps = {
  isRouteSmartPos?: boolean;
  callbackCadastrarSucesso?: (formaRecebimento: { [x: string]: any }) => void;
};

const Cadastrar = ({
  isRouteSmartPos = false,
  callbackCadastrarSucesso,
}: FormaRecebimentoProps) => {
  const history = useHistory();
  const isMountedRef = useIsMountedRef();

  const [isLoading, setIsLoading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);

  const [meioPagamento, setMeioPagamento] = useState(-1);
  const [contaFinanceiraLoja, setContaFinanceiraLoja] = useState<
    FormaPagamentoRecebimentoLojas[]
  >([]);

  const { id: idLojaAtual } = auth.getLoja();

  const {
    handleSubmit,
    setError,
    clearErrors,
    watch,
    register,
    control,
    reset,
    getValues,
    setValue,
    formState,
    setFocus,
  } = useForm({
    resolver: yupResolver,
  });

  const historyReload = useReloadRegistration({ setFormIsDirty });
  isPrenvent(formIsDirty);

  const naoPossuiContaFinanceira = validacaoContaFinanceira(meioPagamento);

  const contaFinanceira = watch('contaFinanceira');

  const listMeioPagamento = Object.entries(MeioPagamentoEnum.properties).map(
    (item) => {
      return {
        name: item[1].name,
        value: item[1].value,
      };
    }
  );

  const salvarConfigStone = async () => {
    const { serialStone, stoneCode } = getValues();

    const data: StoneConfig[] = [];

    Object?.keys(serialStone)?.forEach((lojaId) => {
      const stoneCodeLoja = stoneCode[lojaId];
      const listSerials = serialStone[lojaId];

      const caixas = Object?.keys(listSerials)?.map((idCaixa) => {
        return {
          id: idCaixa,
          serialStone: listSerials[idCaixa] || null,
        };
      });

      data.push({
        lojaId,
        stoneCode: stoneCodeLoja || null,
        listaIdNome: caixas,
      });
    });

    const response = await api.put<void, ResponseApi>(
      ConstanteEnderecoWebservice.CONTA_FINANCEIRA_ALTERAR_STONE,
      data
    );
    if (response) {
      if (response?.avisos) {
        response?.avisos?.forEach((aviso: string) => toast.warning(aviso));
      }

      if (response?.sucesso) {
        return true;
      }
    }

    return false;
  };

  async function handlePostFormaRecebimento() {
    const data = getValues();

    const isMeioPagamentoZoop =
      meioPagamento === MeioPagamentoEnum.PixEnvioZoop ||
      meioPagamento === MeioPagamentoEnum.BoletoZoop ||
      meioPagamento === MeioPagamentoEnum.PixPresencialZoop;

    if (
      meioPagamento !== MeioPagamentoEnum.CartaoCreditoStone &&
      meioPagamento !== MeioPagamentoEnum.CartaoDebitoStone
    ) {
      delete data.serialStone;
      delete data.stoneCode;
    }
    if (isMeioPagamentoZoop) {
      data.nome = listMeioPagamento.find(
        (item) => item.value === meioPagamento
      )?.name;
    }

    data.IntegracaoPagamento = data.IntegracaoPagamento
      ? data.IntegracaoPagamento
      : undefined;
    data.contaFinanceiraId = data.contaFinanceira || undefined;

    const dadosFormaPagamento = {
      ...data,
      tipoFormaPagamentoRecebimento:
        TipoFormaPagamentoRecebimentoEnum.Recebimento,
    };

    const valorContaFinanceiraLojaAtual: FormaPagamentoRecebimentoLojas = {
      contaFinanceiraId: contaFinanceira,
      lojaId: idLojaAtual,
    };

    const valoresContaFinanceira = [
      ...contaFinanceiraLoja,
      valorContaFinanceiraLojaAtual,
    ];

    const response = await api.post<void, ResponseApi>(
      ConstanteEnderecoWebservice.FORMA_PAGTO_CADASTRAR,
      naoPossuiContaFinanceira
        ? {
            ...dadosFormaPagamento,
          }
        : {
            ...dadosFormaPagamento,
            formaPagamentoRecebimentoLojas: valoresContaFinanceira,
          }
    );

    if (response.sucesso) {
      if (isRouteSmartPos && callbackCadastrarSucesso) {
        callbackCadastrarSucesso(dadosFormaPagamento);
      }
      if (
        meioPagamento === MeioPagamentoEnum.CartaoCreditoStone ||
        meioPagamento === MeioPagamentoEnum.CartaoDebitoStone
      ) {
        await salvarConfigStone();
      }
      return true;
    }

    if (response.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    return false;
  }

  const onSubmit = handleSubmit(async () => {
    setIsLoading(true);

    const success = await handlePostFormaRecebimento();

    if (success) {
      toast.success('O cadastro foi realizado com sucesso.');

      setFormIsDirty(false);

      history.push(ConstanteRotas.FORMA_RECEB);
    }

    setIsLoading(false);
  });

  const onSubmitReset = handleSubmit(async () => {
    setIsLoading(true);

    const success = await handlePostFormaRecebimento();

    if (success) {
      toast.success('O cadastro foi realizado com sucesso.');
      if (isMountedRef.current) setFocus('nome');

      historyReload(ConstanteRotas.FORMA_RECEB_CADASTRAR);
    }

    setIsLoading(false);
  });

  useEffect(() => {
    reset({
      descricao: '',
      ativo: true,
      documentoFiscalPreferencial: false,
    });
  }, [reset]);

  useEffect(() => {
    setFormIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  return (
    <Container>
      <Prompt when={formIsDirty} message="" />
      <Form>
        <ManterFoco blockTab={isLoading}>
          <Card>
            {isLoading && <LoadingPadrao />}
            <UncontrolledForm
              errors={formState.errors}
              setError={setError}
              clearErrors={clearErrors}
              watch={watch}
              control={control}
              setContaFinanceiraLoja={setContaFinanceiraLoja}
              register={register}
              getValues={getValues}
              setValue={setValue}
              meioPagamento={meioPagamento}
              setMeioPagamento={setMeioPagamento}
              isCadastrar
            />
          </Card>
          <RodapeFormulario
            onSubmit={onSubmit}
            onSubmitReset={onSubmitReset}
            disabled={isLoading}
          />
        </ManterFoco>
      </Form>
    </Container>
  );
};

export default Cadastrar;
