import { useForm as useFormInstance } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

const schema = yup.object().shape({
  usuario: yup
    .string()
    .default('')
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  senha: yup
    .string()
    .default('')
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);

export const useForm = useFormInstance;
