import { useCallback, useEffect, useState } from 'react';
import { Box, Circle, Flex, Text, useToken } from '@chakra-ui/react';
import ReactApexChart from 'react-apexcharts';
import { toast } from 'react-toastify';
import { renderToString } from 'react-dom/server';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';
import { moneyMask } from 'helpers/format/fieldsMasks';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { PerformanceVendedorResponse } from '../Types/validationsForm';

type GraficoPerformanceVendedorProps = {
  mesAno: string;
};

export const GraficoPerformanceVendedor = ({
  mesAno,
}: GraficoPerformanceVendedorProps) => {
  const [performanceVendedor, setPerformanceVendedor] = useState<
    PerformanceVendedorResponse[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  const colors: string[] = useToken('colors', [
    'blue.500',
    'orange.400',
    'secondary.500',
    'red.300',
    'purple.300',
  ]);
  const [gray100, black] = useToken('colors', ['gray.100', 'black']);

  const obterPerformanceVendedor = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get<
      void,
      ResponseApi<PerformanceVendedorResponse[]>
    >(ConstanteEnderecoWebservice.PERFORMANCE_POR_VENDEDOR, {
      params: { periodo: mesAno },
    });

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
        setIsLoading(false);
      }

      if (response.sucesso && response.dados) {
        setPerformanceVendedor(response.dados);
        setIsLoading(false);
      }
    }
    setIsLoading(false);
  }, [mesAno]);

  const customLegend = (dataPointIndex: number, todasCores: string[]) => {
    if (dataPointIndex === undefined) return '';
    const corAtual = todasCores[dataPointIndex];
    const { faturamento, porcentagemAtingida, meta } = performanceVendedor[
      dataPointIndex
    ];
    return renderToString(
      <Box
        pt="25px"
        pl="20px"
        pr="16px"
        borderRadius="base"
        w="220px"
        h="170px"
        bg="white"
      >
        <Flex justifyContent="left" alignItems="center">
          <Circle
            top="40%"
            transform="translateY(-120%)"
            bg={corAtual}
            mr="10px"
            size="10px"
          />
          <Box>
            <Text fontSize="14px" color={corAtual}>
              {moneyMask(faturamento, true)}
            </Text>
            <Text fontSize="14px" color={black}>
              Faturamento
            </Text>
          </Box>
        </Flex>
        <Flex mt="20px" justifyContent="left" alignItems="center">
          <Circle
            top="40%"
            transform="translateY(-120%)"
            bg={gray100}
            mr="10px"
            size="10px"
          />
          <Box>
            <Text fontSize="14px" color={black}>
              {moneyMask(meta, true)}
            </Text>
            <Text fontSize="14px" color={black}>
              Meta ({porcentagemAtingida}% atingida )
            </Text>
          </Box>
        </Flex>
      </Box>
    );
  };

  useEffect(() => {
    obterPerformanceVendedor();
  }, [obterPerformanceVendedor]);

  return (
    <>
      {isLoading && <LoadingPadrao />}
      {performanceVendedor.length > 0 ? (
        <ReactApexChart
          options={{
            yaxis: {
              show: true,
              labels: {
                formatter: (value) => {
                  if (value < 0) {
                    return `R$ -${moneyMask(Math.abs(value), false)}`;
                  }
                  return `${moneyMask(value, true)}`;
                },
                style: {
                  fontSize: '12px',
                },
              },
            },
            chart: {
              zoom: {
                enabled: false,
              },
              stacked: true,
              type: 'bar',
              toolbar: {
                show: false,
              },
            },
            plotOptions: {
              bar: {
                columnWidth: '45%',
                distributed: true,
              },
            },
            colors,

            responsive: [
              {
                breakpoint: 480,
                options: {
                  legend: {
                    position: 'bottom',
                    offsetX: -10,
                    offsetY: 0,
                  },
                },
              },
            ],

            tooltip: {
              enabled: true,
              theme: 'dark',
              marker: {
                show: false,
              },
              custom({ dataPointIndex, w }: any) {
                const todasCores: string[] = w?.config?.colors;
                return customLegend(dataPointIndex, todasCores);
              },
            },
            fill: {
              opacity: 1,
            },
            dataLabels: {
              enabled: false,
            },

            xaxis: {
              categories: performanceVendedor.map((vendedor) => vendedor.nome),
              labels: {
                style: {
                  colors: [black],
                  fontSize: '12px',
                },
              },
            },

            legend: {
              position: 'right',
              show: false,
              offsetX: 0,
              offsetY: 50,
            },
          }}
          series={[
            {
              name: 'Faturamento da meta',
              data: performanceVendedor.map(({ faturamento }) => ({
                x: 'Faturamento da meta',
                y: faturamento,
              })),
            },
            {
              name: 'Meta',
              data: performanceVendedor.map(({ valorRestante }) => ({
                x: 'Meta',
                y: valorRestante < 0 ? 0 : valorRestante,
                fillColor: gray100,
              })),
            },
          ]}
          type="bar"
          height="80%"
        />
      ) : (
        <Text pl="18px" fontSize="16px">
          Nenhum valor a ser exibido
        </Text>
      )}
    </>
  );
};
