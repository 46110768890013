import { useState, useCallback, useEffect } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  ModalFooter,
  VStack,
  ModalHeader,
  useMediaQuery,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { ButtonDefault } from 'components/Button/ButtonChakra';

import { CheckboxCard } from '../CheckboxCard';
import { FormData, LojaProps } from '../validationForm';

type ModalAplicarConfigLojasProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<FormData> & {
    aplicarConfigEmVariasLojas: (listLojas: LojaProps[]) => void;
    lojas: LojaProps[];
  };

export const ModalAplicarConfigLojas = create<
  ModalAplicarConfigLojasProps,
  FormData
>(({ onResolve, onReject, lojas, aplicarConfigEmVariasLojas, ...rest }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [listLojas, setListLojas] = useState(lojas);

  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const formMethods = useForm();

  const { getValues, setValue } = formMethods;

  const allLojasSelected = listLojas.every((loja) => loja.selecionada);

  const handleAplicarConfigLojas = useCallback(() => {
    setIsLoading(true);
    aplicarConfigEmVariasLojas(listLojas);
    setIsLoading(false);
    onClose();
  }, [aplicarConfigEmVariasLojas, listLojas, onClose]);

  const isSelectAll = getValues('selecionarTodasLojas');

  const handleToggleSelectAll = useCallback(() => {
    if (!isSelectAll) {
      setListLojas((prevLojas) => {
        return prevLojas.map((loja) => {
          setValue(`lojas.${loja.id}`, true);
          return {
            ...loja,
            selecionada: true,
          };
        });
      });
    } else {
      setListLojas((prevLojas) => {
        return prevLojas.map((loja) => {
          setValue(
            `lojas.${loja.id}`,
            loja.isLojaAtual ? loja.selecionada : false
          );
          return {
            ...loja,
            selecionada: loja.isLojaAtual ? loja.selecionada : false,
          };
        });
      });
    }
  }, [setValue, isSelectAll]);

  const handleToggleSelect = useCallback(
    (index: number, isLojaAtual: boolean) => {
      if (isLojaAtual) return;

      setListLojas((prev) =>
        prev.map((valueItem, indexItem) => ({
          ...valueItem,
          selecionada:
            index === indexItem
              ? !valueItem.selecionada
              : valueItem.selecionada,
        }))
      );
    },
    []
  );

  useEffect(() => {
    if (allLojasSelected) {
      setValue('selecionarTodasLojas', true);
    } else {
      setValue('selecionarTodasLojas', false);
    }
  }, [allLojasSelected, setValue]);

  return (
    <ModalPadraoChakra
      isCentered={isLargerThan900}
      size={isLargerThan900 ? '4xl' : 'full'}
      {...rest}
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={false}
    >
      <ModalContent
        bg="gray.50"
        borderRadius={['0', '0', 'md']}
        marginBottom={['0', '0', '3.75rem']}
        marginTop={['0', '0', '3.75rem']}
        w={['full', 'full', '800px']}
        h={['full', 'full', '480px']}
      >
        <ModalHeader pt="24px" pb="18px" pl="40px">
          <Text color="primary.50" fontSize="16px" fontWeight="bold">
            Selecionar lojas
          </Text>
        </ModalHeader>

        <FormProvider {...formMethods}>
          {isLoading && <LoadingPadrao />}
          <ModalBody pl="40px" pr="40px" mb="20px" pt="0" pb="0">
            <VStack spacing="4px" alignItems="flex-start">
              <CheckboxCard
                title="Selecionar todas as lojas"
                name="selecionarTodasLojas"
                height="40px"
                width="304px"
                onClick={() => handleToggleSelectAll()}
              />
              {(listLojas || []).map((loja, index) => (
                <CheckboxCard
                  title={loja.fantasia}
                  name={`lojas.${loja.id}`}
                  subtitle={loja.endereco}
                  onClick={() => {
                    handleToggleSelect(index, loja.isLojaAtual);
                  }}
                  onChange={() => {
                    handleToggleSelect(index, loja.isLojaAtual);
                  }}
                  isSelected={loja.selecionada}
                  isBlocked={loja.bloqueada}
                  isLojaAtual={loja.isLojaAtual}
                />
              ))}
            </VStack>
          </ModalBody>
          <ModalFooter flexDirection="column" mb="40px">
            <Flex
              w="full"
              h="full"
              justifyContent="center"
              alignItems="baseline"
            >
              <Button
                color="gray.300"
                variant="outlineDefault"
                borderRadius="20px"
                borderColor="gray.300"
                fontSize="sm"
                lineHeight="0"
                type="button"
                h={['40px', '40px', '32px']}
                mr="24px"
                onClick={() => onClose()}
                w="96px"
              >
                Cancelar
              </Button>
              <ButtonDefault
                color="black"
                variant="solid"
                colorScheme="secondary"
                lineHeight="0"
                borderRadius="20px"
                fontSize="sm"
                h={['40px', '40px', '32px']}
                width="120px"
                onClick={() => handleAplicarConfigLojas()}
                isLoading={isLoading}
              >
                Confirmar
              </ButtonDefault>
            </Flex>
          </ModalFooter>
        </FormProvider>
      </ModalContent>
    </ModalPadraoChakra>
  );
});
