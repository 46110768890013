import { Flex, GridItem, Text } from '@chakra-ui/react';

import { LabelSelect } from '../components/LabelSelect';
import { Cor } from '../components/Cor';

export const BarraInferior = () => {
  return (
    <GridItem
      colSpan={[12, 12, 6, 4, 4]}
      minW={['100%', '100%', '50%', '373px']}
    >
      <Text fontWeight="bold">Barra inferior para ítens adicionados</Text>
      <Flex
        bg="#E8E8E8"
        minH={['inherit', 'inherit', '493px']}
        padding="24px"
        pt="16px"
        borderRadius="5px"
        border="1px solid #CCCCCC"
        flexDir="column"
        gap="16px"
        height={['fit-content', 'full', 'full', 'full', 'fit-content']}
      >
        <Cor label="Cor de fundo" name="cartAreaBackgroundColor" />
        <LabelSelect
          label="Cor do texto e ícones"
          name="cartIconColor"
          options={[
            { label: 'Branco', value: '#FFF' },
            { label: 'Preto', value: '#000' },
          ]}
        />
        <Cor
          label="  Botão “confirmar” - cor do fundo"
          name="cartConfirmationButtonBackgroundColor"
        />
        <LabelSelect
          label="Botão “confirmar” - cor do texto"
          name="cartConfirmationButtonTextColor"
          options={[
            {
              label: 'Branco',
              value: '#FFF',
            },
            {
              label: 'Preto',
              value: '#000',
            },
          ]}
        />
        <LabelSelect
          label="Botão “confirmar” - ação clique"
          name="cartConfirmationButtonActionColor"
          showBorder={false}
          options={[
            {
              label: 'Claro',
              value: '#FFFFFF80',
            },
            {
              label: 'Escuro',
              value: '#00000080',
            },
          ]}
        />
      </Flex>
    </GridItem>
  );
};
