import { Tr, Tooltip, Text } from '@chakra-ui/react';

type ObservacaoProps = {
  cancelada?: boolean;
  corLinha?: string;
  observacao?: string;
};

export function Observacao({
  cancelada,
  corLinha,
  observacao,
}: ObservacaoProps) {
  const tamanhoCaracteresObservacao = observacao ? observacao.length > 100 : '';
  return (
    <Tr bg={cancelada ? 'red.50' : 'white'} color={corLinha} fontSize="smaller">
      <Tooltip
        hasArrow
        maxW="300px"
        bg="gray.900"
        borderRadius="md"
        placement="bottom"
        label={observacao}
        isDisabled={!tamanhoCaracteresObservacao}
      >
        <Text>
          {tamanhoCaracteresObservacao
            ? 'Ver observação'
            : `Obs: ${observacao}`}
        </Text>
      </Tooltip>
    </Tr>
  );
}
