import {
  useState,
  useRef,
  useImperativeHandle,
  useEffect,
  useCallback,
  useMemo,
  FocusEvent,
} from 'react';
import {
  Box,
  Button,
  Flex,
  FormLabel,
  GridItem,
  HStack,
  Icon,
  Text,
  Tooltip,
  Tag,
  useDisclosure,
} from '@chakra-ui/react';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { OptionTypeBase, OptionsType } from 'react-select';
import { FiAlertCircle } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import auth from 'modules/auth';
import { useProdutosFormularioContext } from 'store/Produtos/ProdutosFormulario';
import { useValidationFormDirtyFields } from 'hooks/useValidationFormDirtyFields';
import ProdutoCategoriasProdutoProvider, {
  ProdutoCategoriasProdutoContext,
} from 'store/Produtos/ProdutosCategoriasProduto';
import api, { ResponseApi } from 'services/api';
import TipoProdutoEnum from 'constants/enum/tipoProduto';
import PlanoContratacaoEnum from 'constants/enum/planoContratacao';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import NivelCategoriaProdutoEnum from 'constants/enum/nivelCategoriaProduto';
import ConstanteFuncionalidades from 'constants/permissoes';
import StatusConsultaEnum from 'constants/enum/statusConsulta';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';
import { venderEcommerce } from 'constants/enum/enumVenderEcommerce';

import Input from 'components/PDV/Input';
import {
  ConfiguracoesCategoriaProdutosIcon,
  InfoIcon,
  LancadoEstoqueIcon,
} from 'icons';
import CreatableSelect from 'components/PDV/Select/CreatableSelect';
import Select from 'components/PDV/Select/SelectPadrao';
import ModalListarSaldoVariacoes from 'components/Modal/ModalListarSaldoVariacoes';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { NumberInput } from 'components/update/Input/NumberInput';
import CheckboxAtivoInativo from 'components/update/Checkbox/CheckboxAtivoInativo';
import { InfoTooltip } from 'components/update/Tooltip/InfoTooltip';
import InputPdv from 'components/PDV/InputPdv';

import { ModalProdutoCodigoDuplicado } from '../components/ModalProdutoCodigoDuplicado';
import { CategoriasProdutoModal } from './CategoriasProdutoModal';
import useProdutoFormulario, { ProdutoCorTamanhoProps } from '../../hook';
import {
  shapePrincipal,
  schemaPrincipal,
  yupResolverDadosGerais,
  CamposObrigatoriosProps,
} from '../../validationForm';

type ProdutosProps = {
  label: string;
  value: number;
  planosPermitidos: number[];
};

type CategoriaProdutoResponseProps = {
  id: string;
  primeiroNivel: string;
  quartoNivel: string;
  segundoNivel: string;
  terceiroNivel: string;
  value?: string;
};

type ValoresMarcaCategoriaProps = {
  categoriaProdutoId?: string;
  marcaId?: string;
};

export type PrincipalProdutoProps = {
  ativo?: boolean;
  tipoProduto?: number;
  nome?: string;
  nomeAbreviado?: string;
  precoCusto?: number;
  precoVenda?: number | string;
  markup?: string | number;
  referencia?: string;
  foto?: string;
  venderEcommerce?: boolean;
  categoriaProdutoId?: string;
  marcaId?: string;
  unidadeMedidaId?: string;
  precoCompra: number;
  tags?: string[] | null;
  codigoGTINEAN?: string;
  estoqueMinimo?: number;
  dataHoraUltimaAlteracao: string;
  estoqueAtual?: number;
  estoqueInicial?: number;
  unidadeTributavel?: number;
  unidadeMedida?: OptionTypeBase | string;
  marca?: OptionTypeBase | string;
  informacoesVariacaoPadrao?: {
    codigoGTINEAN: string;
    codigoSKU: string;
    estoqueAtual: number;
    estoqueMinimo: number;
  };
  sku: string;
  categoriaProduto?:
    | OptionTypeBase
    | string
    | undefined
    | null
    | CategoriaProdutoResponseProps;
};

type PrecoProps = {
  id: string;
  produtoId: string;
  lojaId: string;
  precoCompra: number;
  precoCusto: number;
  precoVenda: {
    precoVenda: number;
    markup: number;
  };
};

let inputGtinEanTimeout: NodeJS.Timeout;

export const Principal = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [idProduto, setIdProduto] = useState<string>('');
  const [marcas, setMarcas] = useState<OptionsType<OptionTypeBase>>([]);
  const [marcasIsLoading, setMarcasIsLoading] = useState(true);
  const [tags, setTags] = useState<OptionsType<OptionTypeBase>>([]);
  const [tagsIsLoading, setTagsIsLoading] = useState(true);
  const [isOpenModalCategoria, setIsOpenModalCategoria] = useState(false);
  const [precoId, setPrecoId] = useState('');
  const [productsWithSameGtinEan, setProductsWithSameGtinEan] = useState<
    ProdutoCorTamanhoProps[]
  >([]);

  const {
    setValue: setValueParent,
    getValues: getValuesParents,
  } = useFormContext();

  const { isOpen, onClose, onOpen } = useDisclosure();

  const {
    action,
    readonly,
    unidadesMedidaOptions,
    unidadesMedidaOptionsIsLoading,
    tipoProduto,
    produtoId: idExterno,
    unidadeDefaultValue,
    isDuplicado,
    ref,
    setIsLoading,
    setNome,
    setCampoObrigatorioVazio,
    idProduto: id,
    setDataHoraUltimaAlteracao,
    setIdProduto: setProdutoId,
    setIsEcommerceAtivo,
    valueProdutoXlm,
    cadastroExterno,
    onChangeFormIsDirty,
    isLoading,
  } = useProdutosFormularioContext();

  const {
    updateProductGtinEan,
    getProductsWithSameGtinEan,
  } = useProdutoFormulario();

  const { id: idRota } = useParams<{ id: string }>();

  const {
    casasDecimais: { casasDecimaisQuantidade },
  } = usePadronizacaoContext();

  const formMethods = useForm<PrincipalProdutoProps>({
    resolver: yupResolverDadosGerais,
    defaultValues: {
      ativo: true,
      nome: getValuesParents()?.nome || getValuesParents()?.descricaoProduto,
      codigoGTINEAN: getValuesParents().codigoGTINEAN || '',
      precoCusto: getValuesParents().valorUnitario || 0,
    },
  });

  const {
    setFocus,
    getValues,
    setValue,
    reset,
    watch,
    handleSubmit,
    formState: { dirtyFields },
  } = formMethods;

  const isDirty = useValidationFormDirtyFields(dirtyFields);

  const [codigoGTINEANWatch, tipoProdutoWatch] = watch([
    'codigoGTINEAN',
    'tipoProduto',
  ]);

  const isProdutoKit = tipoProdutoWatch === TipoProdutoEnum.PRODUTO_KIT;
  const hasProductsWithSameEAN = productsWithSameGtinEan.length > 0;

  const onSubmit = handleSubmit(() => {});

  const valoresObrigatorios = useCallback(() => {
    const camposFormulario = Object.keys(shapePrincipal);
    const camposObrigatorios = [] as string[];

    camposFormulario.forEach((item) => {
      const schema = schemaPrincipal?.describe()?.fields[
        item
      ] as CamposObrigatoriosProps;

      if (
        schema.tests.some((itemSchema) => itemSchema.name.includes('required'))
      ) {
        camposObrigatorios.push(item);
      }
    });

    return camposObrigatorios;
  }, [])();

  const handleGetValueTipoProduto = (tipoProdutoValue: number) => {
    setValueParent('tipoProduto', tipoProdutoValue);

    const produtoIsVariation =
      tipoProdutoValue === TipoProdutoEnum.PRODUTO_VARIACAO;

    if (produtoIsVariation) {
      setValue('codigoGTINEAN', '');
    } else if (valueProdutoXlm && cadastroExterno && !codigoGTINEANWatch) {
      setValue('codigoGTINEAN', valueProdutoXlm.codigoGTINEAN);
    }
  };

  const campoObrigatorioVazio = valoresObrigatorios
    .map((item) => watch(`${item}` as any))
    .some((campo) => campo === undefined || campo === null || campo === '');

  const planoContratado = useMemo(() => auth.getPlano(), []);

  const getDadosCategoria = useCallback(async (idCategoria: string) => {
    const response = await api.get<
      void,
      ResponseApi<CategoriaProdutoResponseProps>
    >(
      `${ConstanteEnderecoWebservice.PRODUTOS_CATEGORIA_OBTER}/${idCategoria}/hierarquia`
    );
    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
        return null;
      }

      if (response.sucesso) {
        return response.dados;
      }

      return null;
    }
    return null;
  }, []);

  const handleGetMarcasOptions = useCallback(async () => {
    setMarcasIsLoading(true);

    const response = await api.get<void, ResponseApi<OptionTypeBase[]>>(
      ConstanteEnderecoWebservice.MARCA_LISTAR_SELECT,
      { params: { statusConsulta: StatusConsultaEnum.ATIVOS } }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
        setMarcasIsLoading(false);
        return [];
      }

      if (response.sucesso) {
        const data = response.dados.map((marca) => {
          return {
            label: marca.nome,
            value: marca.id,
          } as OptionTypeBase;
        });

        setMarcas(data);
        setMarcasIsLoading(false);
        return data;
      }
    }

    setMarcasIsLoading(false);
    return [];
  }, []);

  const obterPrecos = useCallback(async () => {
    const response = await api.get<void, ResponseApi<PrecoProps>>(
      `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${id}/precos`
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso && response.dados) {
        const {
          precoCompra,
          precoCusto,
          precoVenda,
          id: dataIdPreco,
        } = response.dados;

        setValue('precoCompra', precoCompra);
        setValue('precoCusto', precoCusto);
        setValue('precoVenda', precoVenda.precoVenda);
        setValue('precoVenda', precoVenda.precoVenda);
        setValue('markup', precoVenda.markup);
        setPrecoId(dataIdPreco);
      }
    }
  }, [id, setValue]);

  const alterarPreco = useCallback(async () => {
    const data = getValues();

    const response = await api.put<void, ResponseApi<PrecoProps>>(
      `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${id}/precos/${precoId}`,
      {
        precoCompra: data.precoCompra,
        precoCusto: data.precoCusto,
        precoVenda: {
          precoVenda: data.precoVenda,
          markup: data.markup,
        },
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }
    }
  }, [getValues, id, precoId]);

  const getTagOptions = useCallback(async () => {
    setTagsIsLoading(true);

    const response = await api.get<void, ResponseApi<OptionTypeBase[]>>(
      ConstanteEnderecoWebservice.TAG_LISTAR_SELECT,
      { params: { ativo: true } }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
        return [];
      }

      if (response.sucesso) {
        const data = response.dados.map((tag) => {
          return {
            label: tag.nome,
            value: tag.id,
          } as OptionTypeBase;
        });
        setTags(data);
        setTagsIsLoading(false);
        return data;
      }
    }

    setTagsIsLoading(false);
    return [];
  }, []);

  const getTags = useCallback(
    async (valueId: string) => {
      await getTagOptions();

      const response = await api.get<void, ResponseApi<{ tagId: string }[]>>(
        `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${valueId}/tags`
      );
      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
          return null;
        }

        if (response.sucesso) {
          return response.dados;
        }

        return null;
      }
      return null;
    },
    [getTagOptions]
  );

  const getDadosGerais = useCallback(async () => {
    setIsLoading(true);

    if (!id) {
      setIsLoading(false);
      return;
    }

    if (unidadesMedidaOptions.length === 0) {
      setIsLoading(false);
      return;
    }

    const response = await api.get<void, ResponseApi<PrincipalProdutoProps>>(
      `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${id}`
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso) {
        const {
          categoriaProdutoId,
          unidadeMedidaId,
          tipoProduto: dataTipoProduto,
          estoqueAtual,
          estoqueMinimo,
          marcaId,
          dataHoraUltimaAlteracao,
          informacoesVariacaoPadrao,
          venderEcommerce: isVenderEcommerce,
        } = response.dados;

        const tagsData = await getTags(id);
        const dataCategoria = await getDadosCategoria(categoriaProdutoId || '');
        const dataMarca = await handleGetMarcasOptions();

        const newMarca = dataMarca.find(
          (itemMarca) => itemMarca.value === marcaId
        );

        const newCategoria =
          dataCategoria !== null
            ? {
                value: dataCategoria?.id,
                primeiroNivel: dataCategoria?.primeiroNivel,
                segundoNivel: dataCategoria?.segundoNivel,
                terceiroNivel: dataCategoria?.terceiroNivel,
                quartoNivel: dataCategoria?.quartoNivel,
              }
            : null;

        const dateWithoutTimezone =
          new Date(dataHoraUltimaAlteracao).getTime() -
          new Date(dataHoraUltimaAlteracao).getTimezoneOffset() * 60000;

        setDataHoraUltimaAlteracao(new Date(dateWithoutTimezone).toISOString());
        setNome(response.dados.nome || '');
        reset({
          ...response.dados,
          categoriaProduto: newCategoria,
          tags: tagsData?.map((item) => item.tagId),
          unidadeMedida: unidadesMedidaOptions.find(
            (unidadeItem) => unidadeItem.value === unidadeMedidaId
          ),
          marca: newMarca,
          sku: informacoesVariacaoPadrao?.codigoSKU,
          codigoGTINEAN: informacoesVariacaoPadrao?.codigoGTINEAN,
          estoqueMinimo:
            estoqueMinimo || informacoesVariacaoPadrao?.estoqueMinimo || 0,
          estoqueInicial:
            estoqueAtual || informacoesVariacaoPadrao?.estoqueAtual || 0,
        });
        await obterPrecos();
        setIsEcommerceAtivo(
          isVenderEcommerce === undefined ? true : isVenderEcommerce
        );
        setValueParent('tipoProduto', dataTipoProduto);

        if (action === 'alterar') {
          setFocus('nome');
        }
      }
    }

    setIsLoading(false);
  }, [
    setIsLoading,
    id,
    unidadesMedidaOptions,
    getTags,
    getDadosCategoria,
    handleGetMarcasOptions,
    setDataHoraUltimaAlteracao,
    setNome,
    reset,
    obterPrecos,
    setIsEcommerceAtivo,
    setValueParent,
    action,
    setFocus,
  ]);

  const listDataForm = useCallback(() => {
    const data = getValues();

    const valoresPreco = id
      ? undefined
      : {
          precoCompra: data.precoCompra,
          precoCusto: data.precoCusto,
          precoVenda: {
            precoVenda: data.precoVenda,
            markup: data.markup,
          },
        };

    return {
      ...valoresPreco,
      ativo: data.ativo,
      tipoProduto: data.tipoProduto,
      nome: data.nome,
      nomeAbreviado: data.nomeAbreviado,
      referencia: data.referencia,
      foto: data.foto,
      venderEcommerce: data.venderEcommerce,
      categoriaProdutoId:
        typeof data?.categoriaProduto === 'string'
          ? data?.categoriaProduto
          : data?.categoriaProduto?.value,
      marcaId:
        typeof data?.marca === 'string' ? data?.marca : data?.marca?.value,
      unidadeMedidaId:
        typeof data?.unidadeMedida === 'string'
          ? data?.unidadeMedida
          : data?.unidadeMedida?.value,
      tags: data.tags || [],
      estoqueAtual: data.estoqueInicial,
      codigoGTINEAN: codigoGTINEANWatch,
      estoqueMinimo: data.estoqueMinimo,
    };
  }, [getValues, id, codigoGTINEANWatch]);

  const handleAlterar = useCallback(async () => {
    const dataApi = listDataForm();
    const response = await api.put<void, ResponseApi<string>>(
      `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${id}`,
      {
        ...dataApi,
        id,
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
        setIsLoading(false);
        return false;
      }

      if (response.sucesso) {
        if (!isProdutoKit) {
          await alterarPreco();
        }

        setIsLoading(false);
        return true;
      }
      setIsLoading(false);
      return false;
    }
    setIsLoading(false);
    return false;
  }, [alterarPreco, listDataForm, id, setIsLoading, isProdutoKit]);

  const handleCadastrar = useCallback(async () => {
    setIsLoading(true);

    if (campoObrigatorioVazio) {
      onSubmit();
      setIsLoading(false);
      return false;
    }

    if (isDirty === false) {
      setIsLoading(false);
      return true;
    }

    if (id) {
      const valoresAlterados = await handleAlterar();
      setIsLoading(false);
      return valoresAlterados;
    }

    const dataApi = listDataForm();

    const response = await api.post<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2,
      {
        ...dataApi,
      }
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
        setIsLoading(false);
        return false;
      }

      if (response.sucesso) {
        setValueParent('tipoProduto', dataApi?.tipoProduto);
        setNome(dataApi.nome || '');
        setProdutoId(response.dados);
        setIsLoading(false);
        return true;
      }
      setIsLoading(false);
      return false;
    }
    return false;
  }, [
    setIsLoading,
    campoObrigatorioVazio,
    id,
    listDataForm,
    onSubmit,
    handleAlterar,
    isDirty,
    setValueParent,
    setNome,
    setProdutoId,
  ]);

  const typeOfProduct = useCallback(() => {
    const valueProduct = [] as ProdutosProps[];

    TipoProdutoEnum.produto.forEach((produtoItem) => {
      const validandoPlano = produtoItem.planosPermitidos.some(
        (planoExistente) => planoExistente === planoContratado
      );

      if (validandoPlano) {
        valueProduct.push(produtoItem);
      }
    });

    return valueProduct;
  }, [planoContratado])();

  const possuiPermissaoCadastrarMarca = auth.possuiPermissao(
    ConstanteFuncionalidades.MARCA_CADASTRAR
  ).permitido;

  const possuiPermissaoCadastrarTag = auth.possuiPermissao(
    ConstanteFuncionalidades.PRODUTO_CADASTRAR_TAG
  ).permitido;

  const possuiPermissaoVisualizarPrecoCusto = auth.possuiPermissao(
    ConstanteFuncionalidades.USUARIO_VISUALIZAR_PRECO_CUSTO
  ).permitido;

  const handlePostMarca = useCallback(
    async (marcaNome: string) => {
      const response = await api.post<void, ResponseApi>(
        ConstanteEnderecoWebservice.MARCA_CADASTRAR,
        { nome: marcaNome, ativo: true }
      );

      if (response?.sucesso) {
        const marcaId = response.dados;
        const newMarca = { label: marcaNome, value: marcaId } as OptionTypeBase;

        setMarcas([...marcas, newMarca]);

        return newMarca;
      }

      if (response?.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      return undefined;
    },
    [marcas]
  );

  const handlePostTag = useCallback(
    async (tagNome: string) => {
      const response = await api.post<void, ResponseApi>(
        ConstanteEnderecoWebservice.TAG_CADASTRAR,
        { nome: tagNome, ativo: true }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }
        if (response.sucesso) {
          const tagId = response.dados;
          const newTag = { label: tagNome, value: tagId };

          setTags([...tags, newTag]);

          return newTag;
        }
      }

      return undefined;
    },
    [tags]
  );

  const handleOnUnidadeMedidaChange = (newValue: any) => {
    const unidadeTributavel = getValues('unidadeTributavel');

    if (!unidadeTributavel) {
      setValue('unidadeTributavel', newValue);
    }
  };

  const handleFecharModalCategoria = () => {
    setIsOpenModalCategoria(false);
  };

  const tipoAtualDoProduto = watch('tipoProduto');

  const handleModificarMarkup = useCallback(() => {
    const precoVenda = getValues('precoVenda');

    if (precoVenda === 0) {
      return;
    }

    const precoCusto = getValues('precoCusto');

    const newMarkup = ((Number(precoVenda) || 0) / (precoCusto || 0) - 1) * 100;

    if (newMarkup === Infinity) {
      return;
    }

    setValue('markup', (newMarkup || 0)?.toFixed(4));
  }, [getValues, setValue]);

  const handleModificarPrecoVenda = useCallback(() => {
    const markup = getValues('markup');

    const precoCusto = getValues('precoCusto');

    const newPrecoVenda = (
      (precoCusto || 0) *
      (1 + Number(markup) / 100)
    )?.toFixed(2);

    setValue('precoVenda', newPrecoVenda);
  }, [getValues, setValue]);

  const handleValidateProductsWithSameGtinEan = useCallback(
    async (event: FocusEvent<HTMLInputElement>) => {
      clearTimeout(inputGtinEanTimeout);

      inputGtinEanTimeout = global.setTimeout(async () => {
        const { value } = event.target;

        const validLengths = [8, 12, 13, 14];

        if (validLengths.includes(value.length)) {
          const products = await getProductsWithSameGtinEan(value);
          const filteredProducts = products.filter(
            (product) => product.produtoId !== id
          );

          setProductsWithSameGtinEan(filteredProducts);
          return;
        }

        setProductsWithSameGtinEan([]);
      }, 500);
    },
    [getProductsWithSameGtinEan, id]
  );

  const latestProps = useRef({ setValue });

  useImperativeHandle(ref, () => ({
    onClick: () => handleCadastrar(),
  }));

  useEffect(() => {
    if (!id && (action === 'cadastrar' || cadastroExterno)) {
      handleGetMarcasOptions();
      getTagOptions();
    }
  }, [action, cadastroExterno, getTagOptions, handleGetMarcasOptions, id]);

  useEffect(() => {
    latestProps.current = { setValue };
  });

  useEffect(() => {
    if (unidadesMedidaOptionsIsLoading) {
      latestProps.current.setValue(
        'unidadeMedida',
        unidadesMedidaOptions.filter(
          (obj) => obj.value === unidadeDefaultValue
        )[0]
      );
    }
  }, [
    unidadeDefaultValue,
    unidadesMedidaOptions,
    unidadesMedidaOptionsIsLoading,
  ]);

  useEffect(() => {
    if (tipoProduto === TipoProdutoEnum.PRODUTO_VARIACAO) {
      setProductsWithSameGtinEan([]);
    }
  }, [tipoProduto]);

  useEffect(() => {
    if (action !== 'alterar') {
      latestProps.current.setValue('estoqueInicial', 0);
    }
  }, [tipoAtualDoProduto, action]);

  useEffect(() => {
    setCampoObrigatorioVazio(campoObrigatorioVazio);
  }, [campoObrigatorioVazio, setCampoObrigatorioVazio]);

  useEffect(() => {
    if (idRota !== undefined || idExterno !== undefined) {
      setProdutoId(idRota || idExterno || '');
    }
  }, [idRota, setProdutoId, idExterno]);

  useEffect(() => {
    if (isProdutoKit) {
      if (id) {
        obterPrecos();
      }

      setValue(
        'unidadeMedida',
        unidadesMedidaOptions.filter(
          (obj) => obj.value === unidadeDefaultValue
        )[0]
      );
    }
  }, [
    id,
    isProdutoKit,
    obterPrecos,
    setValue,
    unidadeDefaultValue,
    unidadesMedidaOptions,
  ]);

  useEffect(() => {
    onChangeFormIsDirty(isDirty);
  }, [isDirty, onChangeFormIsDirty]);

  useEffect(() => {
    getDadosGerais();
  }, [getDadosGerais]);

  return (
    <FormProvider {...formMethods}>
      <SimpleGridForm mb="30px" gap={{ base: 6, md: 8 }}>
        <GridItem colSpan={{ base: 12, md: 9, lg: 9 }}>
          <SimpleGridForm gap={{ base: 6, md: 8 }}>
            <GridItem colSpan={{ base: 12, md: 8, lg: 8 }}>
              <Input
                type="text"
                id="nome"
                onBlur={() => {
                  setValueParent('nome', getValues('nome'));
                  setNome(getValues('nome') as string);
                }}
                name="nome"
                label="Nome do Produto"
                isRequired
                placeholder="Informe o nome completo do produto"
                maxLength={120}
                isDisabled={readonly}
                autoFocus
              />
            </GridItem>
            <GridItem colSpan={{ base: 12, md: 4, lg: 4 }}>
              <Input
                type="text"
                id="nomeAbreviado"
                name="nomeAbreviado"
                label="Descrição abreviada"
                placeholder="Digite o nome curto"
                maxLength={50}
                isDisabled={readonly}
              />
            </GridItem>
            <GridItem colSpan={{ base: 12, md: 4, lg: 4 }}>
              <Select
                id="tipoProduto"
                name="tipoProduto"
                label="Tipo"
                required
                onGetValue={(data) =>
                  handleGetValueTipoProduto(data.value as number)
                }
                valueDefault={TipoProdutoEnum.PRODUTO_SIMPLES}
                options={typeOfProduct}
                isDisabled={readonly}
              />
            </GridItem>
            <GridItem colSpan={{ base: 12, md: 8, lg: 8 }}>
              <ProdutoCategoriasProdutoProvider>
                <ProdutoCategoriasProdutoContext.Consumer>
                  {({
                    categoriasOptions,
                    isLoading: categoriasIsLoading,
                    handleGetCategoriaProdutoOptions,
                  }) => {
                    const handleOnSubmit = (categoriaSelecionada: string) => {
                      const categoriaProdutoOption = categoriasOptions.find(
                        (categoriaProduto) =>
                          categoriaProduto.value === categoriaSelecionada
                      );

                      setValue('categoriaProduto', categoriaProdutoOption);
                      setIsOpenModalCategoria(false);
                    };
                    return (
                      <>
                        <Flex
                          mb="-6px"
                          mt="-1px"
                          fontSize="sm"
                          color="black"
                          lineHeight="1.2"
                          justifyContent="space-between"
                          alignItems="baseline"
                        >
                          <FormLabel whiteSpace="nowrap">Categoria *</FormLabel>

                          <HStack spacing={2}>
                            <Text
                              color="blue.500"
                              fontSize="11px"
                              cursor="pointer"
                              onClick={() => handleGetCategoriaProdutoOptions()}
                            >
                              Atualizar categorias
                            </Text>
                            <Tooltip
                              hasArrow
                              placement="right"
                              p="10px"
                              bgColor="black"
                              label="Para cadastrar uma categoria acesse Configurações > Categoria de Produtos. Após realizar o cadastro clique em Atualizar categorias (botão que está aqui do lado) para que a opção seja exibida no cadastro de produtos."
                            >
                              <Flex mb="8px">
                                <InfoIcon />
                              </Flex>
                            </Tooltip>
                          </HStack>
                        </Flex>
                        <Flex>
                          <Button
                            w="25px"
                            mr="10px"
                            bg="gray.50"
                            borderRadius="4px"
                            borderWidth="1px"
                            borderStyle="solid"
                            borderColor="gray.200"
                            onClick={() => setIsOpenModalCategoria(true)}
                            disabled={readonly || categoriasIsLoading}
                          >
                            <Icon
                              fontSize="20px"
                              as={ConfiguracoesCategoriaProdutosIcon}
                            />
                          </Button>
                          <Select
                            id="categoriaProduto"
                            name="categoriaProduto"
                            required
                            asControlledByObject
                            placeholder="Informe a categoria ou sub-categoria"
                            options={[]}
                            optionsBase={categoriasOptions}
                            isLoading={categoriasIsLoading}
                            isDisabled={readonly}
                            isSearchable
                            filterOption={(option: any, rawInput: string) => {
                              if (rawInput && option.data) {
                                return (
                                  (option.data.primeiroNivel &&
                                    (option.data.primeiroNivel as string)
                                      .normalize('NFD')
                                      .replace(/[\u0300-\u036f]/g, '')
                                      .toLowerCase()
                                      .includes(
                                        rawInput
                                          .normalize('NFD')
                                          .replace(/[\u0300-\u036f]/g, '')
                                          .toLowerCase()
                                      )) ||
                                  (option.data.segundoNivel &&
                                    (option.data.segundoNivel as string)
                                      .normalize('NFD')
                                      .replace(/[\u0300-\u036f]/g, '')
                                      .toLowerCase()
                                      .includes(
                                        rawInput
                                          .normalize('NFD')
                                          .replace(/[\u0300-\u036f]/g, '')
                                          .toLowerCase()
                                      )) ||
                                  (option.data.terceiroNivel &&
                                    (option.data.terceiroNivel as string)
                                      .normalize('NFD')
                                      .replace(/[\u0300-\u036f]/g, '')
                                      .toLowerCase()
                                      .includes(
                                        rawInput
                                          .normalize('NFD')
                                          .replace(/[\u0300-\u036f]/g, '')
                                          .toLowerCase()
                                      )) ||
                                  (option.data.quartoNivel &&
                                    (option.data.quartoNivel as string)
                                      .normalize('NFD')
                                      .replace(/[\u0300-\u036f]/g, '')
                                      .toLowerCase()
                                      .includes(
                                        rawInput
                                          .normalize('NFD')
                                          .replace(/[\u0300-\u036f]/g, '')
                                          .toLowerCase()
                                      ))
                                );
                              }
                              return true;
                            }}
                            formatOptionLabel={(option: any) => {
                              return (
                                <Text as="span" fontSize="1em">
                                  <Text
                                    as="span"
                                    fontSize="1em"
                                    style={{
                                      color:
                                        NivelCategoriaProdutoEnum.properties[0]
                                          .foregroundColor,
                                    }}
                                  >
                                    {option.primeiroNivel}
                                  </Text>
                                  {option.segundoNivel && (
                                    <Text
                                      as="span"
                                      fontSize="1em"
                                      style={{
                                        color:
                                          NivelCategoriaProdutoEnum
                                            .properties[1].foregroundColor,
                                      }}
                                    >
                                      {`> ${option.segundoNivel}`}
                                    </Text>
                                  )}
                                  {option.terceiroNivel && (
                                    <Text
                                      as="span"
                                      fontSize="1em"
                                      style={{
                                        color:
                                          NivelCategoriaProdutoEnum
                                            .properties[2].foregroundColor,
                                      }}
                                    >
                                      {`> ${option.terceiroNivel}`}
                                    </Text>
                                  )}
                                  {option.quartoNivel && (
                                    <Text
                                      as="span"
                                      fontSize="1em"
                                      style={{
                                        color:
                                          NivelCategoriaProdutoEnum
                                            .properties[3].foregroundColor,
                                      }}
                                    >
                                      {`> ${option.quartoNivel}`}
                                    </Text>
                                  )}
                                </Text>
                              );
                            }}
                          />
                        </Flex>
                        <CategoriasProdutoModal
                          isOpen={isOpenModalCategoria}
                          onClose={handleFecharModalCategoria}
                          handleOnSubmit={handleOnSubmit}
                        />
                      </>
                    );
                  }}
                </ProdutoCategoriasProdutoContext.Consumer>
              </ProdutoCategoriasProdutoProvider>
            </GridItem>
            <GridItem colSpan={{ base: 12, md: 4, lg: 4 }}>
              <CreatableSelect
                id="marca"
                name="marca"
                label="Marca"
                asControlledByObject
                required
                creatableButtonShow={possuiPermissaoCadastrarMarca}
                placeholder="Selecione ou informe a marca"
                options={marcas.map((marcaItem) => ({
                  value: marcaItem.value,
                  label: marcaItem.label,
                }))}
                multiValueColor="var(--white)"
                handleCreateOption={async (inputValue) => {
                  if (possuiPermissaoCadastrarMarca) {
                    const newMarca = await handlePostMarca(inputValue);

                    if (newMarca) {
                      setValue('marca', newMarca);
                    }
                  }

                  return undefined;
                }}
                creatableInputTextPreffix={
                  possuiPermissaoCadastrarMarca ? 'Adicionar a marca' : ''
                }
                disabled={readonly}
                isLoading={marcasIsLoading}
              />
            </GridItem>
            <GridItem colSpan={{ base: 12, md: 4, lg: 4 }}>
              <Select
                id="unidadeMedida"
                name="unidadeMedida"
                label="Unidade de medida"
                asControlledByObject
                required
                valueDefault={unidadeDefaultValue}
                options={unidadesMedidaOptions}
                isDisabled={readonly || isProdutoKit}
                isLoading={unidadesMedidaOptionsIsLoading}
                onChange={handleOnUnidadeMedidaChange}
              />
            </GridItem>
            <GridItem colSpan={{ base: 12, md: 4, lg: 4 }}>
              <Select
                id="venderEcommerce"
                name="venderEcommerce"
                label="Vender no e-commerce"
                required
                valueDefault={false}
                onSelect={(option) => setIsEcommerceAtivo(option.value)}
                options={venderEcommerce.options}
              />
            </GridItem>
          </SimpleGridForm>
        </GridItem>
        <GridItem colSpan={{ base: 12, md: 3, lg: 3 }}>
          <Box mt="-5px">
            <Text fontSize="14px" fontWeight="semibold" color="black">
              Estoque
            </Text>
            <Box
              w="100%"
              h="210px"
              borderRadius="5px"
              overflowY="auto"
              px="32px"
              py="24px"
              borderColor="gray.200"
              bg="gray.50"
              borderWidth="1px"
            >
              <GridItem mb="28px" colSpan={{ base: 12, md: 3, lg: 3 }}>
                {action === 'alterar' &&
                  isDuplicado !== true &&
                  tipoProduto !== TipoProdutoEnum.PRODUTO_SIMPLES && (
                    <Button
                      h="0"
                      mx="0"
                      my="0"
                      pb="0"
                      px="0"
                      variant="none"
                      _focus={{ border: 'none' }}
                      onClick={() => {
                        setModalIsOpen(true);
                        setIdProduto(idExterno || idRota || '');
                      }}
                    >
                      <FormLabel>Saldo de estoque</FormLabel>
                      <Icon as={LancadoEstoqueIcon} mb="2" />
                    </Button>
                  )}
                {(action === 'cadastrar' || isDuplicado) && (
                  <Flex
                    mb="-6px"
                    mt="-1px"
                    fontSize="sm"
                    color="black"
                    lineHeight="1.2"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <FormLabel>Estoque inicial</FormLabel>
                    <Box mb="8px">
                      <InfoTooltip
                        bgColor="black"
                        label="Ao cadastrar o produto, será gerado automaticamente uma entrada de estoque com a quantidade informada no estoque inicial."
                      />
                    </Box>
                  </Flex>
                )}
                <NumberInput
                  id="estoqueInicial"
                  name="estoqueInicial"
                  precision={14}
                  colorLabel="white"
                  label={
                    action === 'alterar' &&
                    isDuplicado === false &&
                    tipoProduto === TipoProdutoEnum.PRODUTO_SIMPLES
                      ? 'Estoque inicial'
                      : undefined
                  }
                  scale={casasDecimaisQuantidade}
                  style={
                    action === 'alterar' ? { marginTop: '3px' } : undefined
                  }
                  defaultValue={0}
                  isDisabled={
                    readonly ||
                    tipoProduto === TipoProdutoEnum.PRODUTO_VARIACAO ||
                    planoContratado === PlanoContratacaoEnum.START ||
                    (action === 'alterar' && isDuplicado !== true) ||
                    isProdutoKit
                  }
                />
              </GridItem>
              <GridItem colSpan={{ base: 12, md: 3, lg: 3 }}>
                <NumberInput
                  id="estoqueMinimo"
                  name="estoqueMinimo"
                  label="Estoque mí­nimo"
                  precision={14}
                  scale={casasDecimaisQuantidade}
                  defaultValue={0}
                  isDisabled={
                    readonly ||
                    tipoProduto === TipoProdutoEnum.PRODUTO_VARIACAO ||
                    isProdutoKit
                  }
                />
              </GridItem>
            </Box>
          </Box>
        </GridItem>
        <GridItem colSpan={12}>
          <SimpleGridForm pt="0" gap={{ base: 6, md: 8 }}>
            <GridItem colSpan={[12, 12, 3]}>
              <NumberInput
                leftElementColor="gray.700"
                id="precoCompra"
                label="Preço de compra"
                editarFundoLeftElemento
                labelColor="gray.700"
                fontWeightLabel="bold"
                leftElementFontSize="xs"
                bgLeftElement="gray.50"
                leftElement="R$"
                scale={2}
                name="precoCompra"
                esconderValor={!possuiPermissaoVisualizarPrecoCusto}
                isDisabled={
                  isProdutoKit || !possuiPermissaoVisualizarPrecoCusto
                }
              />
            </GridItem>
            <GridItem colSpan={[12, 12, 3]}>
              <NumberInput
                leftElementColor="gray.700"
                id="precoCusto"
                label="Preço de custo"
                editarFundoLeftElemento
                labelColor="gray.700"
                fontWeightLabel="bold"
                leftElementFontSize="xs"
                bgLeftElement="gray.50"
                leftElement="R$"
                esconderValor={!possuiPermissaoVisualizarPrecoCusto}
                scale={2}
                onBlur={() => {
                  handleModificarMarkup();
                }}
                name="precoCusto"
                isDisabled={
                  isProdutoKit || !possuiPermissaoVisualizarPrecoCusto
                }
              />
            </GridItem>
            <GridItem colSpan={[12, 12, 3]}>
              <NumberInput
                leftElementColor="gray.700"
                id="markup"
                label="Markup"
                editarFundoLeftElemento
                labelColor="gray.700"
                fontWeightLabel="bold"
                leftElementFontSize="xs"
                bgLeftElement="gray.50"
                leftElement="%"
                scale={2}
                onBlur={() => {
                  handleModificarPrecoVenda();
                }}
                name="markup"
                isDisabled={isProdutoKit}
              />
            </GridItem>
            <GridItem colSpan={[12, 12, 3]}>
              <NumberInput
                leftElementColor="gray.700"
                id="precoVenda"
                label="Venda"
                editarFundoLeftElemento
                labelColor="gray.700"
                fontWeightLabel="bold"
                leftElementFontSize="xs"
                onBlur={() => {
                  handleModificarMarkup();
                }}
                bgLeftElement="gray.50"
                leftElement="R$"
                scale={2}
                name="precoVenda"
                isDisabled={isProdutoKit}
              />
            </GridItem>
            <GridItem colSpan={{ base: 12, md: 3, lg: 3 }}>
              <Input
                type="text"
                id="sku"
                name="sku"
                label="Código SKU"
                placeholder=""
                maxLength={100}
                isDisabled
              />
            </GridItem>
            <GridItem colSpan={{ base: 12, md: 3, lg: 3 }}>
              <Input
                type="text"
                id="referencia"
                name="referencia"
                label="Referência"
                placeholder="Informe o código"
                maxLength={60}
                isDisabled={readonly}
              />
            </GridItem>
            <GridItem colSpan={{ base: 12, md: 3, lg: 3 }}>
              <InputPdv
                id="codigoGTINEAN"
                name="codigoGTINEAN"
                type="number"
                label="GTIN/EAN"
                placeholder="Código de barras"
                borderColor={hasProductsWithSameEAN ? 'yellow.600' : 'inherit'}
                onChange={(event: FocusEvent<HTMLInputElement>) =>
                  handleValidateProductsWithSameGtinEan(event)
                }
                maxLength={14}
                isDisabled={
                  readonly || tipoProduto === TipoProdutoEnum.PRODUTO_VARIACAO
                }
              />
              {hasProductsWithSameEAN && (
                <Tag
                  position="absolute"
                  h="32px"
                  px="8px"
                  bg="yellow.500"
                  borderRadius="4px"
                >
                  <Flex gap={1}>
                    <Icon as={FiAlertCircle} />
                    <Text fontSize="14px" whiteSpace="nowrap">
                      Código já cadastrado.{' '}
                      <Box as="u" cursor="pointer" onClick={() => onOpen()}>
                        Ver produtos.
                      </Box>
                    </Text>
                  </Flex>
                </Tag>
              )}
            </GridItem>
            <GridItem
              colSpan={{ base: 12, md: 3, lg: 3 }}
              mt={{ base: hasProductsWithSameEAN ? 3 : 0, md: 0 }}
            >
              <CheckboxAtivoInativo
                id="ativo"
                name="ativo"
                label="Status"
                isDisabled={readonly}
              />
            </GridItem>
          </SimpleGridForm>
        </GridItem>
      </SimpleGridForm>
      <SimpleGridForm gap={{ base: 6, md: 8 }}>
        <GridItem colSpan={{ base: 12, md: 9, lg: 9 }}>
          <CreatableSelect
            id="tags"
            isMulti
            name="tags"
            label="TAGs"
            placeholder="Adicione TAGs para classificar o produto"
            creatableInputTextPreffix={
              possuiPermissaoCadastrarTag ? 'Adicionar a TAG' : ''
            }
            options={tags.map((tagItem) => ({
              value: tagItem.value,
              label: tagItem.label,
            }))}
            isLoading={tagsIsLoading}
            creatableButtonShow={possuiPermissaoCadastrarTag}
            handleCreateOption={async (inputValue: string) =>
              handlePostTag(inputValue)
            }
            disabled={readonly}
          />
        </GridItem>
      </SimpleGridForm>
      <ModalListarSaldoVariacoes
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        idProduto={idProduto}
      />
      <ModalProdutoCodigoDuplicado
        isOpen={isOpen}
        onClose={onClose}
        code={{
          type: 'GTIN/EAN',
          value: codigoGTINEANWatch || '',
        }}
        isLoading={isLoading}
        products={productsWithSameGtinEan}
        callback={async (products) => {
          setIsLoading(true);

          const listPromises = products.map((product) =>
            updateProductGtinEan(
              product.produtoCorTamanhoId,
              product?.codigo || ''
            )
          );

          const response = await Promise.all(listPromises).then((result) => {
            result.forEach(({ success, data }) => {
              if (success) {
                setProductsWithSameGtinEan((prev) =>
                  prev.filter(
                    (prevProduct) =>
                      prevProduct.produtoCorTamanhoId !==
                      data.produtoCorTamanhoId
                  )
                );

                toast.success('GTIN/EAN alterado com sucesso.');
              }
            });

            return { success: result.every(({ success }) => success) };
          });

          setIsLoading(false);

          if (response.success) {
            onClose();
          }
        }}
      />
    </FormProvider>
  );
};
