import React, { useCallback, useState } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Icon,
  Flex,
  useMediaQuery,
  useDisclosure,
  ModalHeader,
  VStack,
  Text,
  ModalFooter,
  HStack,
  Button,
  GridItem,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import TipoProdutoEnum from 'constants/enum/tipoProduto';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import AsyncSelect from 'components/PDV/Select/AsyncSelectPadrao';
import { Switch } from 'components/update/Switch';
import { SalvarInserirNovoIcon } from 'icons';

import { FormData, yupResolver, ListProdutosProps } from './validationForm';
import { InformPrices } from './Components/informPrices';
import { Variations } from './Components/variations';

type SelectProdutoCorResponse = {
  nome: string;
  id: string;
  ativo: boolean;
};

export type ProdutoResponse = {
  produtoNome: string;
  corDescricao?: string;
  tamanhoDescricao?: string;
  precoVenda: number;
  precoNovo: number;
  produtoCorTamanhoId?: string;
  quantidade?: number;
  descontoAcimaQuantidade: boolean;
};

type ModalAdicionarProdutoResponse = {
  deveReiniciar?: boolean;
  produto: ProdutoResponse[];
};

type ModalAdicionarProdutoProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalAdicionarProdutoResponse> & {
    casasDecimaisValor: number;
  };

export const ModalAdicionarProduto = create<
  ModalAdicionarProdutoProps,
  ModalAdicionarProdutoResponse
>(({ onResolve, onReject, casasDecimaisValor, ...rest }) => {
  const [currentProduct, setCurrentProduct] = useState<ListProdutosProps>();
  const [isLoading, setIsLoading] = useState(false);
  const [produtoCorIsLoading, setProdutoCorIsLoading] = useState(false);

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues: {
      descontoAcimaQuantidade: false,
    },
  });

  const { setValue, getValues } = formMethods;

  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const listVariations = currentProduct?.variacoes || [];

  const isHasVariation = listVariations.length > 0;

  const isProductVariation =
    currentProduct?.tipoProduto === TipoProdutoEnum.PRODUTO_VARIACAO;

  const allVariationSelected = listVariations.every(
    (itemVariation) => itemVariation.isChecked
  );

  function handleToggleSelectAllVariations(isChecked: boolean) {
    setCurrentProduct((prev) => {
      return prev
        ? {
            ...prev,
            variacoes: prev.variacoes.map((itemVariation) => ({
              ...itemVariation,
              isChecked,
            })),
          }
        : undefined;
    });
  }

  function handleToggleSelectVariation(indexSelected: number) {
    setCurrentProduct((prev) => {
      return prev
        ? {
            ...prev,
            variacoes: prev.variacoes.map((itemVariation, index) => ({
              ...itemVariation,
              isChecked:
                indexSelected === index
                  ? !itemVariation.isChecked
                  : itemVariation.isChecked,
            })),
          }
        : undefined;
    });
  }

  const getProdutos = useCallback(async (inputValue: string) => {
    setProdutoCorIsLoading(true);

    const response = await api.get<
      void,
      ResponseApi<SelectProdutoCorResponse[]>
    >(ConstanteEnderecoWebservice.PESQUISAR_PRODUTOS_PROMOCAO, {
      params: { pesquisa: inputValue },
    });

    if (response?.avisos) {
      response.avisos.forEach((aviso: string) => toast.warning(aviso));
    }

    if (response && response.sucesso && response.dados) {
      const data = response.dados.map((option) => ({
        label: option.nome,
        value: option.id,
      }));

      setProdutoCorIsLoading(false);

      return data;
    }

    setProdutoCorIsLoading(false);
    return [];
  }, []);

  const getDetailProduct = async (id: string) => {
    setIsLoading(true);
    const response = await api.get<void, ResponseApi<ListProdutosProps>>(
      `${ConstanteEnderecoWebservice.PESQUISAR_PRODUTOS_PROMOCAO}/${id}/variacoes`
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }

      if (response && response.sucesso && response.dados) {
        const data = response.dados;
        setCurrentProduct(data);

        setValue('precoCusto', data?.precos?.custo);
        setValue('preco', data?.precos?.venda);

        setIsLoading(false);
      }
    }
    setIsLoading(false);
  };

  const registerVariations = (
    preco: number,
    precoNovo: number,
    quantidade: number,
    descontoAcimaQuantidade: boolean
  ) => {
    return listVariations
      .filter((itemVariation) => itemVariation.isChecked)
      .map((itemVariation) => {
        const { produto, cor, tamanho, produtoCorTamanhoId } = itemVariation;
        return {
          produtoNome: produto,
          corDescricao: cor,
          tamanhoDescricao: tamanho,
          precoVenda: preco,
          precoNovo,
          produtoCorTamanhoId,
          quantidade,
          descontoAcimaQuantidade,
        };
      });
  };

  const handleSubmit = async (isReset: boolean) => {
    const data = getValues();

    if (data.precoVenda === 0) {
      toast.warning('Informe o valor do produto');
      return;
    }

    if (isProductVariation) {
      const listData = registerVariations(
        data.preco,
        data.precoVenda,
        data.quantidade,
        data.descontoAcimaQuantidade
      );

      if (listData.length === 0) {
        toast.warning('Selecione ao menos uma variação do produto');
        return;
      }

      onResolve({
        deveReiniciar: isReset,
        produto: listData,
      });

      return;
    }

    const { produto, cor, tamanho, produtoCorTamanhoId } = listVariations[0];

    const newValue = {
      produtoNome: produto,
      corDescricao: cor,
      tamanhoDescricao: tamanho,
      precoVenda: data.preco,
      precoNovo: data.precoVenda,
      produtoCorTamanhoId,
      quantidade: data.quantidade,
      descontoAcimaQuantidade: data.descontoAcimaQuantidade,
    };

    onResolve({
      deveReiniciar: isReset,
      produto: [newValue],
    });
  };

  return (
    <ModalPadraoChakra
      isCentered
      size={!isSmallerThan900 ? '3xl' : 'full'}
      {...rest}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalContent
        marginBottom={{ base: 0, md: '3.75rem' }}
        bg="gray.50"
        marginTop={{ base: 0, md: '3.75rem' }}
        maxW={{ base: '100%', md: '900px' }}
        maxH={{ base: '100%', md: '580px' }}
      >
        {isLoading && <LoadingPadrao />}
        <ModalHeader
          mt={isSmallerThan900 ? 12 : undefined}
          mb={isSmallerThan900 ? 8 : undefined}
          borderBottom="1px"
          borderColor="gray.100"
          px="0"
          mx={{ base: 6, md: 8 }}
        >
          <VStack alignItems="flex-start" spacing="1" lineHeight="1">
            <Text color="primary.500" fontSize={{ base: 'xl', md: 'md' }}>
              Adicionar produtos
            </Text>
          </VStack>
        </ModalHeader>
        <ModalBody p={{ base: 6, md: 8 }}>
          <FormProvider {...formMethods}>
            <Flex
              alignItems="baseline"
              flexDirection="column"
              w="full"
              h="full"
            >
              <SimpleGridForm w="full">
                <GridItem w="full" colSpan={[12, 12, 8]}>
                  <AsyncSelect
                    id="produtos"
                    name="produtos"
                    label="Descrição do produto"
                    placeholder="Digite o nome do produto"
                    isLoading={produtoCorIsLoading}
                    handleGetOptions={getProdutos}
                    asControlledByObject
                    onOptionSelect={(data) =>
                      getDetailProduct(data.value as string)
                    }
                    withoutDefaultOptions
                    autoFocus
                  />
                </GridItem>
                <GridItem w="full" colSpan={[12, 12, 4]}>
                  <Switch
                    id="descontoAcimaQuantidade"
                    name="descontoAcimaQuantidade"
                    size="md"
                    helperText="O preço promocional vai ser utilizado acima da quantidade informada "
                    label="Utilizar acima da quantidade"
                  />
                </GridItem>

                <InformPrices
                  isExistProduct={currentProduct}
                  casasDecimaisValor={casasDecimaisValor}
                />
                {isHasVariation && isProductVariation && (
                  <Variations
                    listVariations={listVariations}
                    allVariationSelected={allVariationSelected}
                    handleToggleSelectVariation={handleToggleSelectVariation}
                    handleToggleSelectAllVariations={
                      handleToggleSelectAllVariations
                    }
                  />
                )}
              </SimpleGridForm>
            </Flex>
          </FormProvider>
        </ModalBody>
        <ModalFooter justifyContent="flex-end" mx={{ base: 6, md: 8 }} px="0">
          <HStack
            flexDirection={{ base: 'column', md: 'row' }}
            justifyContent="center"
            alignItems="center"
            gap={6}
            flex="1"
          >
            <Button
              variant="outlineDefault"
              minW="100px"
              colorScheme="gray"
              w={{ base: 'full', md: '100px' }}
              onClick={onClose}
            >
              Cancelar
            </Button>
            <Button
              variant="outlineDefault"
              minW="100px"
              colorScheme="gray"
              w={{ base: 'full', md: '100px' }}
              onClick={() => handleSubmit(false)}
            >
              Confirmar
            </Button>
            <Button
              borderRadius="2xl"
              colorScheme="secondary"
              minW="225px"
              w={{ base: 'full', md: '225px' }}
              leftIcon={<Icon as={SalvarInserirNovoIcon} fontSize="lg" />}
              onClick={() => handleSubmit(true)}
            >
              Confirmar e inserir novo
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </ModalPadraoChakra>
  );
});
