import { ModalProps } from '@chakra-ui/react';
import { InstanceProps } from 'react-modal-promise';
import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

const schema = yup.object().shape({
  dataAntecipacao: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  clienteFornecedorId: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  formaPagamentoId: yup.object().shape({
    value: yup.string().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  }),
  contaFinanceiraId: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);

export type FormData = {
  taxaAntecipacao: number;
  dataAntecipacao: Date | string;
  clienteFornecedorId: string;
  formaPagamentoId: string;
  contaFinanceiraId: string;
  tipoTaxa: number;
};

export type ContasFinanceirasProps = {
  nome: string;
  id: string;
  padraoSistema: boolean;
}[];

export type FormaPagamentoResponse = {
  id: string;
  nome: string;
  lancarFatura: boolean;
  diaVencimento: number;
  contasFinanceiras: ContasFinanceirasProps;
};

export type FormaPagamentoProps = {
  lancarFatura: boolean;
  diaVencimento: number;
  contasFinanceiras: ContasFinanceirasProps;
  label: string;
  value: string;
};

export type ModalAnteciparContasProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<FormData> & {
    totalSelecionado: number;
    totalEmTaxas: number;
    valorOriginal: number;
  };

export type ClienteFornecedorOptionResponse = {
  id: string;
  nome: string;
  endereco: string;
  cpfCnpj: string;
  codigo: number;
};

export type HookAnteciparModalProps = {
  onResolve: (data: FormData) => void;
  valorOriginal: number;
};
