import { Flex, Text } from '@chakra-ui/react';
import React from 'react';

type SignatureInfoProps = {
  clienteNome: string;
  clienteCpf?: string;
  clienteEndereco: string;
  cidadeLoja: string;
  currentDateInWords: string;
};

export const SignatureInfo = ({
  clienteNome,
  clienteCpf,
  clienteEndereco,
  cidadeLoja,
  currentDateInWords,
}: SignatureInfoProps) => {
  return (
    <Flex border="1px solid #bbb" fontWeight="normal" letterSpacing="0">
      <Flex flexDir="column" w="60%">
        <Text py="4px" fontSize="10px" pl="4px">
          {clienteNome?.toUpperCase()}
        </Text>
        <Text
          borderTop="1px solid #bbb"
          borderBottom="1px solid #bbb"
          fontSize="10px"
          py="4px"
          pl="4px"
          minHeight="20px"
        >
          {`CPF: ${clienteCpf ? clienteCpf.toUpperCase() : '------'}`}
        </Text>
        <Text fontSize="10px" py="4px" pl="4px" minHeight="40px">
          {clienteEndereco.toUpperCase()}
        </Text>
      </Flex>
      <Flex borderLeft="1px solid #bbb" w="40%" flexDir="column">
        <Text borderBottom="1px solid #bbb" fontSize="10px" pl="4px" py="4px">
          {cidadeLoja?.toUpperCase()}, {currentDateInWords}
        </Text>
        <Flex h="full" justify="flex-start" pl="4px" align="flex-end">
          X
        </Flex>
      </Flex>
    </Flex>
  );
};
