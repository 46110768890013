import { Route } from 'react-router-dom';

import ConstanteRotas from 'constants/rotas';
import { ZoopProvider, ZoopContext } from 'store/Zoop/ZoopContext';

import { DetalhesIntegracaoZoop } from 'pages/Integracoes/LojaAplicativos/Zoop/Etapas/DetalhesIntegracaoZoop';
import { GeralZoop } from 'pages/Integracoes/LojaAplicativos/Zoop/PainelZoop/Geral';

import LayoutGuard from './LayoutGuard';

export const ZoopGerencialRoutes = [
  <Route path={ConstanteRotas.INTEGRACAO_ZOOP_GERAL} key="zoop_geral">
    <ZoopProvider>
      <ZoopContext.Consumer>
        {({ activeTab: activeStep }) => (
          <LayoutGuard
            key="zoop_geral"
            component={GeralZoop}
            breadcrumb={[
              {
                title: 'Vendas',
              },
              {
                title: `Zoop Recebimentos`,
              },
              {
                title: activeStep?.name,
              },
            ]}
            path={ConstanteRotas.INTEGRACAO_ZOOP_GERAL}
            exact
            meta={{ auth: true }}
          />
        )}
      </ZoopContext.Consumer>
    </ZoopProvider>
  </Route>,
  <Route path={ConstanteRotas.ZOOP_DETALHES_INTEGRACAO} key="zoop-detalhes">
    <LayoutGuard
      key="zoop-detalhes"
      component={DetalhesIntegracaoZoop}
      breadcrumb={[
        {
          title: 'Integrações',
          path: ConstanteRotas.LOJA_APLICATIVOS,
        },
        {
          title: 'Loja de aplicativos',
          path: ConstanteRotas.LOJA_APLICATIVOS,
        },

        {
          title: `Zoop Recebimentos`,
        },
      ]}
      path={ConstanteRotas.ZOOP_DETALHES_INTEGRACAO}
      exact
      meta={{ auth: true }}
    />
  </Route>,
];
