import { useImperativeHandle, useCallback } from 'react';
import { Flex, Text, Icon, Box } from '@chakra-ui/react';
import { FiChevronLeft } from 'react-icons/fi';

import { useTrayEtapasContext } from 'store/Tray';

import { IconType } from 'icons';

type HeaderSmartPosProps = {
  icon: IconType;
  iconSize?: number;
  title: string;
  handleVoltar?: () => void;
  colorIcon?: string;
};

export const Header = ({
  icon,
  title,
  iconSize = 40,
  handleVoltar,
  colorIcon = 'primary.50',
}: HeaderSmartPosProps) => {
  const { ref } = useTrayEtapasContext();

  const podeVoltar = useCallback(() => {
    if (ref.current?.handleVoltar && handleVoltar) {
      return true;
    }
    return false;
  }, [ref, handleVoltar])();

  useImperativeHandle(ref, () => ({
    handleVoltar,
  }));

  return (
    <Box>
      <Flex
        justifyContent="center"
        w="full"
        alignItems="center"
        cursor="pointer"
      >
        <Icon
          boxSize={`${iconSize}px`}
          size={iconSize}
          color={colorIcon}
          as={icon}
        />
      </Flex>

      <Flex
        borderBottomStyle="solid"
        mb={['13px', '13px', '24px']}
        borderBottomWidth="1px"
        borderBottomColor="gray.100"
        alignItems="baseline"
        justifyContent={!podeVoltar ? 'space-between' : 'center'}
      >
        {podeVoltar && (
          <Box
            ml="10px"
            h="full"
            boxSize={['20px', '20px', '']}
            onClick={handleVoltar}
            cursor="pointer"
          >
            <Icon
              color="black"
              display={['none', 'none', 'flex']}
              boxSize="30px"
              onClick={handleVoltar}
              cursor="pointer"
              as={FiChevronLeft}
            />
          </Box>
        )}

        <Box w="full">
          <Text
            textAlign="center"
            fontSize={['20px', '20px', '28px']}
            fontWeight="bold"
            marginTop={['8px', '8px', '12px']}
          >
            {title}
          </Text>
        </Box>

        {podeVoltar && <Box h="full" w="20px" cursor="pointer" />}
      </Flex>
    </Box>
  );
};
