import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import UncontrolledForm from '..';
import usePeriodoCaixaForm from '../hooks';
import { FormData, defaultValues, yupResolver } from '../validationForm';

const PeriodoCaixaAlterar = () => {
  const {
    obterPeriodoCaixa,
    atualizarPeriodoCaixa,
    redirecionarPeriodoCaixaListar,
  } = usePeriodoCaixaForm();

  const { id } = useParams<{ id: string }>();
  const formMethods = useForm<FormData>({
    defaultValues,
    resolver: yupResolver,
  });
  const { handleSubmit, reset } = formMethods;

  const [isLoading, setIsLoading] = useState(false);

  const handleSalvar = async (data: FormData) => {
    setIsLoading(true);

    const { success } = await atualizarPeriodoCaixa(id, data);

    if (success) {
      toast.success('Período do caixa alterado com sucesso');
      redirecionarPeriodoCaixaListar();
    }

    setIsLoading(false);
  };

  const resetForm = useCallback(async () => {
    setIsLoading(true);

    const cashPeriod = await obterPeriodoCaixa(id);

    if (cashPeriod) {
      reset(cashPeriod);
    }

    setIsLoading(false);
  }, [obterPeriodoCaixa, id, reset]);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  return (
    <FormProvider {...formMethods}>
      <UncontrolledForm
        isLoading={isLoading}
        handleSalvar={handleSubmit(handleSalvar)}
      />
    </FormProvider>
  );
};

export default PeriodoCaixaAlterar;
