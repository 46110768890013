import React, { useCallback, useRef, useState } from 'react';
import { Box, Icon, IconButton, Text } from '@chakra-ui/react';
import { HexColorPicker } from 'react-colorful';
import { useFormContext, useWatch } from 'react-hook-form';
import { FiChevronDown } from 'react-icons/fi';
import { toast } from 'react-toastify';

import './styles.css';

import Input from 'components/PDV/Input';

import { useClickOutside } from '../../hooks';

const style = {
  borderWidth: '3px',
  borderStyle: 'solid',
  borderRadius: '32px',
};

export const ColorPicker = ({ name }: { name: string }) => {
  const [isOpen, toggle] = useState(false);
  const cor = useWatch({ name });
  const formMethods = useFormContext();

  const divRef = useRef<HTMLDivElement>(null);

  const close = useCallback(() => toggle(false), []);
  useClickOutside(divRef, close);

  const calcularContrasteYIQ = useCallback((hexcolor: string) => {
    if (hexcolor?.toUpperCase() === '#FFF') {
      return 'black';
    }
    if (hexcolor?.toUpperCase() === '#000') {
      return 'white';
    }

    const r = parseInt(hexcolor?.substring(1, 3), 16);
    const g = parseInt(hexcolor?.substring(3, 5), 16);
    const b = parseInt(hexcolor?.substring(5, 7), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? 'black' : 'white';
  }, []);

  const validarHex = (valor: string): boolean => {
    const regexHex = /^#([0-9A-Fa-f]{3}){1,2}$/;
    return regexHex.test(valor);
  };

  const handlePaste = (
    e:
      | React.ClipboardEvent<HTMLInputElement>
      | React.ClipboardEvent<HTMLDivElement>
  ) => {
    e.preventDefault();
    let textoColado = e.clipboardData.getData('text');
    if (!textoColado.startsWith('#')) {
      textoColado = `#${textoColado}`;
    }
    if (!validarHex(textoColado)) {
      formMethods.setValue(name, '#FFFFFF');
      toast.warn('A cor informada não é um hexadecimal válido');
      return;
    }
    formMethods.setValue(name, textoColado?.toUpperCase());
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target?.value?.startsWith('#')) {
      e.target.value = `#${e.target.value}`;
    }
    e.target.value = e.target.value?.toUpperCase();

    formMethods.setValue(name, e.target.value?.toUpperCase());
  };

  const handleChangeColorPicker = (corNova: string) => {
    if (!validarHex(corNova)) {
      toast.warn('A cor informada não é um hexadecimal válido');
      formMethods.setValue(name, '#FFFFFF');
      return;
    }
    formMethods.setValue(name, corNova?.toUpperCase());
  };

  return (
    <div
      className="picker"
      tabIndex={0}
      role="button"
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          toggle(!isOpen);
        }
      }}
    >
      <div
        style={{
          ...style,
          borderColor: cor,
        }}
      >
        <div
          className="swatch"
          style={{
            backgroundColor: cor,
            borderColor: calcularContrasteYIQ(cor),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: '16px',
          }}
          role="button"
          tabIndex={-1}
          onKeyDown={() => {}}
          onClick={() => toggle(!isOpen)}
        >
          <Text color={calcularContrasteYIQ(cor) || 'black'} fontSize="12px">
            {cor}
          </Text>
          <IconButton
            aria-label="Abrir seletor de cor"
            icon={<Icon as={FiChevronDown} fontSize="lg" />}
            transform={isOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
            variant="link"
            color={calcularContrasteYIQ(cor) || 'black'}
            p={1}
            px={1}
            borderRadius="md"
            minW="1px"
            pointerEvents="none"
            boxShadow="none"
            tabIndex={-1}
            _focus={{ boxShadow: 'none' }}
          />
        </div>
      </div>

      {isOpen && (
        <div
          className="popover"
          ref={divRef}
          style={{
            backgroundColor: 'transparent',
            top: '45px',
            left: '-78px',
          }}
        >
          <HexColorPicker color={cor} onChange={handleChangeColorPicker} />
          <Box w="200px">
            <Input
              name={name}
              id={name}
              placeholder="#000000"
              fontSize="14px"
              borderRadius="0px"
              onPaste={handlePaste}
              onInput={handleInput}
              style={{
                boxShadow: 'none',
                border: 'none',
                width: '200px',
              }}
              maxLength={7}
              label=""
            />
          </Box>
        </div>
      )}
    </div>
  );
};
