/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */

type EnumType = {
  properties: {
    [key: number]: {
      // @ts-ignore
      [key: any]: string;
    };
  };
};

export function getOptionByEnum(
  { properties }: EnumType,
  enumValue: number,
  labelKey?: string,
  valueKey?: string
) {
  const propertie = Object.entries(properties).find(
    ([_, entry]: any) =>
      (valueKey ? entry[valueKey] : entry.value) === enumValue
  );

  if (!propertie) {
    return undefined;
  }

  const [, entry] = propertie;

  return {
    // @ts-ignore
    value: valueKey ? entry[valueKey] : entry.value,
    // @ts-ignore
    label: labelKey ? entry[labelKey] : entry.label || entry.name,
  };
}

function getOptionsByEnum(
  { properties }: EnumType,
  labelKey?: string,
  valueKey?: string,
  filterOptions?: number[]
) {
  const options = Object.entries(properties).map(([_, entry]: any) => ({
    value: valueKey ? entry[valueKey] : entry.value,
    label: labelKey ? entry[labelKey] : entry.label || entry.name,
  }));

  if (filterOptions) {
    return (options || []).filter(({ value }) => filterOptions.includes(value));
  }

  return options || [];
}

export default getOptionsByEnum;
