import React from 'react';
import { Flex, useMediaQuery } from '@chakra-ui/react';
import { Redirect } from 'react-router-dom';

import {
  ConferenciaEstoqueEtapasProvider,
  ConferenciaEstoqueEtapasContext,
} from 'store/ConferenciaEstoque/ConferenciaEstoqueEtapas';
import ConstanteRotas from 'constants/rotas';

import { Steps } from 'components/update/Steps';
import { SimpleCard } from 'components/update/Form/SimpleCard';

import { EscolherLocalEstoque } from './EscolherLocalEstoque';
import { EscolherFiltros } from './EscolherFiltros';
import { LancamentoProdutos } from './LancamentoProdutos';
import { EscolherTipoCorrecao } from './EscolherTipoCorrecao';
import { RevisarDivergencias } from './RevisarDivergencias';

export function EtapasConferencia() {
  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

  return (
    <SimpleCard p={0} bg="gray.50" boxShadow="0px 0px 6px #00000034">
      <ConferenciaEstoqueEtapasProvider>
        <ConferenciaEstoqueEtapasContext.Consumer>
          {({ activeStep }) => (
            <Flex
              w="full"
              flexDir="column"
              alignItems="center"
              pt={{ base: 4, sm: 6, md: 8 }}
            >
              <Steps
                w="100%"
                showLabel={isLargerThan700}
                maxW={{ base: '300px', sm: '500px', lg: '900px' }}
                colorScheme="primary"
                activeStep={activeStep}
                steps={[
                  {
                    label: 'Local de estoque',
                    content: <EscolherLocalEstoque />,
                  },
                  {
                    label: 'Filtros',
                    content: <EscolherFiltros />,
                  },
                  {
                    label: 'Produtos',
                    content: <LancamentoProdutos />,
                  },
                  {
                    label: 'Conferência',
                    content: <EscolherTipoCorrecao />,
                  },
                  {
                    label: 'Revisão',
                    content: <RevisarDivergencias />,
                  },
                ]}
              />
            </Flex>
          )}
        </ConferenciaEstoqueEtapasContext.Consumer>
      </ConferenciaEstoqueEtapasProvider>
    </SimpleCard>
  );
}
