import React, { useEffect, useRef, useState } from 'react';
import {
  GridItem,
  Button,
  Stack,
  Box,
  Text,
  useMediaQuery,
  Flex,
} from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import auth from 'modules/auth';
import ConstanteFuncionalidades from 'constants/permissoes';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { useEntradaMercadoriaDadosCadastroContext } from 'store/EntradaMercadoria/EntradaMercadoriaDadosCadastro';
import { useEntradaMercadoriaEtapasContext } from 'store/EntradaMercadoria/EntradaMercadoriaEtapas';
import TipoProdutoFiscalEnum from 'constants/enum/tipoProdutoFiscal';
import getOptionsByEnum from 'helpers/format/getOptionsByEnum';
import ConstanteRotas from 'constants/rotas';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import AsyncCreatableSelect from 'components/PDV/Select/AsyncCreatableSelectPadrao';
import Select from 'components/PDV/Select/SelectPadrao';
import { DateInput } from 'components/update/Input/DateInput';
import { CopyInput } from 'components/update/Input/CopyInput';
import Textarea from 'components/PDV/Textarea';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import InputInteger from 'components/PDV/InputInteger';
import {
  Container,
  Body,
  Footer,
  StepDescriptionAccordion,
} from 'components/update/Steps/StepContent';

import { ModalCadastrarTransportadora } from './ModalCadastrarTransportadora';
import { yupResolver, FormData } from './validationForm';

type TransportadoraOptionResponse = {
  id: string;
  nome: string;
};

type ObterInformacoesGeraisResponse = {
  finalidade: number;
  dataHoraEntrada: Date;
  transportadora: TransportadoraOptionResponse;
  observacao: string;
  numeroNFe: string;
  serieNFe: number;
  chaveAcessoNFe: string;
  dataHoraNFe?: Date;
};

interface InformacoesGeraisProps {
  isImportacao?: boolean;
}

export function InformacoesGerais({
  isImportacao = false,
}: InformacoesGeraisProps) {
  const history = useHistory();
  const {
    permitido: temPermissaoCadastrarTransportadora,
  } = auth.possuiPermissao(ConstanteFuncionalidades.TRANSPORTADORA_CADASTRAR);

  const { nextStep, previousStep } = useEntradaMercadoriaEtapasContext();
  const {
    entradaMercadoriaId,
    descartarEntradaMercadoria,
    voltarParaListagem,
    temPermissaoExcluir,
    isReadOnly,
    IsCadastroExterno,
    statusLancamentos,
    menuIsOpen,
  } = useEntradaMercadoriaDadosCadastroContext();

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues: {
      dataHoraEntrada: new Date(),
      finalidade: TipoProdutoFiscalEnum.MERCADORIA_PARA_REVENDA,
      chaveAcessoNFe: '',
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isSmallerThan980] = useMediaQuery('(max-width: 980px)');
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  function handleDescartarEntradaMercadoria() {
    descartarEntradaMercadoria();
  }

  function handleVoltar() {
    previousStep();
  }

  async function adicionarInformacoesGerais({
    transportadora,
    dataHoraEntrada,
    dataHoraNFe,
    numeroNFe,
    ...rest
  }: FormData) {
    if (entradaMercadoriaId) {
      setIsLoading(true);

      const response = await api.post<void, ResponseApi<string>>(
        ConstanteEnderecoWebservice.ENTRADA_MERCADORIA_ADICIONAR_INFORMACOES_GERAIS,
        {
          id: entradaMercadoriaId,
          transportadoraId: transportadora?.value,
          dataHoraEntrada: new Date(dataHoraEntrada).toISOString(),
          dataHoraNFe: dataHoraNFe ? new Date(dataHoraNFe).toISOString() : null,
          numeroNFe: numeroNFe || '',
          ...rest,
        }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.map((aviso: string) => toast.warning(aviso));
          setIsLoading(false);
        }

        if (response.sucesso) {
          setIsLoading(false);

          return true;
        }
      }
    }

    return false;
  }

  const handleSalvarRascunho = formMethods.handleSubmit(async (data) => {
    adicionarInformacoesGerais(data);

    voltarParaListagem();
  });

  const handleFinalizar = formMethods.handleSubmit(async (data) => {
    if (isReadOnly) {
      voltarParaListagem();
      return;
    }

    const sucesso = await adicionarInformacoesGerais(data);

    if (sucesso) {
      if (isImportacao) {
        history.push(
          ConstanteRotas.ENTRADA_MERCADORIA_ENTRADA_IMPORTACAO_FINALIZAR
        );
      } else {
        voltarParaListagem();
      }
    }
  });

  const handleAvancar = formMethods.handleSubmit(async (data) => {
    if (isReadOnly) {
      nextStep();
      return;
    }

    const sucesso = await adicionarInformacoesGerais(data);

    if (sucesso) {
      nextStep();
    }
  });

  async function handleCadastrarTransportadora(inputValue: string) {
    if (temPermissaoCadastrarTransportadora) {
      const { transportadora } = await ModalCadastrarTransportadora({
        inputValue,
      });

      return {
        value: transportadora.id,
        label: transportadora.nome,
      };
    }

    toast.warning(
      'Você não tem permissão para acessar essa função. Consulte o administrador da conta.'
    );

    return undefined;
  }

  async function getTransportadorasOptions(inputValue: string) {
    const response = await api.get<
      void,
      ResponseApi<TransportadoraOptionResponse[]>
    >(
      ConstanteEnderecoWebservice.TRANSPORTADORA_LISTAR_SELECT_ENTRADA_MERCADORIA,
      {
        params: { cpfCnpjNomeApelidoCodigoExterno: inputValue },
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.map((aviso: string) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        return response.dados.map((transportadora) => ({
          label: transportadora.nome,
          value: transportadora.id,
        }));
      }
    }

    return [];
  }

  const latestProps = useRef({ reset: formMethods.reset, voltarParaListagem });
  useEffect(() => {
    latestProps.current = { reset: formMethods.reset, voltarParaListagem };
  });

  useEffect(() => {
    async function obterInformacoesGerais() {
      if (entradaMercadoriaId) {
        setIsLoading(true);

        const response = await api.get<
          void,
          ResponseApi<ObterInformacoesGeraisResponse>
        >(
          ConstanteEnderecoWebservice.ENTRADA_MERCADORIA_OBTER_INFORMACOES_GERAIS,
          {
            params: {
              id: entradaMercadoriaId,
            },
          }
        );

        if (response) {
          if (response.avisos) {
            response.avisos.map((aviso: string) => toast.warning(aviso));
          }

          if (response.sucesso && response.dados) {
            const { transportadora, ...rest } = response.dados;

            latestProps.current.reset({
              transportadora:
                transportadora && transportadora.id
                  ? {
                      value: transportadora.id,
                      label: transportadora.nome,
                    }
                  : null,
              ...rest,
              chaveAcessoNFe: response.dados.chaveAcessoNFe || '',
              finalidade: TipoProdutoFiscalEnum.MERCADORIA_PARA_REVENDA,
            });
          } else {
            latestProps.current.voltarParaListagem();
          }
        }

        setIsLoading(false);
      }
    }

    obterInformacoesGerais();
  }, [entradaMercadoriaId]);

  return (
    <>
      {isLoading && <LoadingPadrao />}

      <Container mt="5">
        <StepDescriptionAccordion
          stepNumber={5}
          title="Informações gerais e detalhes da nota fiscal"
          description="Informe o motivo da entrada selecionando o campo “finalidade da entrada” . Adicione as observações necessárias e preencha os dados da nota fiscal."
        />

        <FormProvider {...formMethods}>
          <Body>
            <Flex direction={isSmallerThan980 ? 'column' : 'row'}>
              <GridItem
                mr="38px"
                w={isSmallerThan980 ? '100%' : '50%'}
                mb={isSmallerThan980 ? '38px' : '0'}
                colSpan={6}
              >
                <SimpleGridForm>
                  <GridItem colSpan={12}>
                    <Select
                      id="finalidade"
                      name="finalidade"
                      label="Finalidade da entrada"
                      placeholder="Selecione a finalidade da entrada"
                      options={getOptionsByEnum(TipoProdutoFiscalEnum)}
                      required
                      isDisabled={isReadOnly}
                    />
                  </GridItem>
                  <GridItem colSpan={12}>
                    <AsyncCreatableSelect
                      id="transportadora"
                      name="transportadora"
                      label="Transportadora"
                      isClearable
                      errorPropName="value"
                      placeholder="Selecione a transportadora ou cadastre uma nova"
                      handleGetOptions={getTransportadorasOptions}
                      creatableInputTextPreffix="Cadastrar a transportadora"
                      handleCreateOption={handleCadastrarTransportadora}
                      asControlledByObject
                      isDisabled={isReadOnly || isImportacao}
                    />
                  </GridItem>
                  <GridItem colSpan={12}>
                    <Textarea
                      id="observacao"
                      name="observacao"
                      label="Observações"
                      placeholder="Digite o texto"
                      h="120px"
                      isDisabled={isReadOnly}
                    />
                  </GridItem>
                </SimpleGridForm>
              </GridItem>
              <GridItem colSpan={6}>
                <SimpleGridForm>
                  <GridItem colSpan={{ base: 6 }}>
                    <DateInput
                      id="dataHoraEntrada"
                      name="dataHoraEntrada"
                      label="Data de entrada"
                      required
                      isDisabled={isReadOnly}
                    />
                  </GridItem>

                  <GridItem colSpan={12}>
                    <Box w="full">
                      <Text
                        as="label"
                        lineHeight="none"
                        mb="1"
                        fontSize="sm"
                        fontWeight="semibold"
                      >
                        Dados da nota fiscal
                      </Text>
                      <Box
                        borderRadius="md"
                        bg="gray.50"
                        border="1px"
                        borderColor="gray.100"
                        p={{ base: 4, sm: 6, md: 8 }}
                      >
                        <Box>
                          <Flex mb="30px">
                            <Box w="full" mr="38px">
                              <InputInteger
                                id="numeroNFe"
                                name="numeroNFe"
                                label="Número"
                                placeholder="000"
                                colSpan={4}
                                isDisabled={isReadOnly || isImportacao}
                              />
                            </Box>
                            <Box w="full" mr="38px">
                              <InputInteger
                                id="serieNFe"
                                name="serieNFe"
                                label="Série"
                                placeholder="000"
                                colSpan={4}
                                isDisabled={isReadOnly || isImportacao}
                              />
                            </Box>
                            <Box w="full">
                              <DateInput
                                id="dataHoraNFe"
                                name="dataHoraNFe"
                                label="Data"
                                isRequired={false}
                                colSpan={4}
                                isDisabled={isReadOnly || isImportacao}
                              />
                            </Box>
                          </Flex>
                          <CopyInput
                            id="chaveAcessoNFe"
                            name="chaveAcessoNFe"
                            label="Chave da NF-e"
                            placeholder="Informe a chave de acesso ao XML da nota fiscal"
                            colSpan={12}
                            isDisabled={isReadOnly || isImportacao}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </GridItem>
                </SimpleGridForm>
              </GridItem>
            </Flex>
          </Body>
        </FormProvider>
      </Container>
      <Footer
        justifyContent={isReadOnly ? 'space-between' : 'flex-end'}
        position={isLargerThan900 ? 'fixed' : 'relative'}
        bottom="0px"
        bg="gray.50"
        borderTop={isLargerThan900 ? '1px solid' : 'none'}
        borderColor="#5502B2"
        w={`calc(100% - ${menuIsOpen ? '210px' : '108px'})`}
        py="16px"
        px="48px"
      >
        <Button
          variant="outlineDefault"
          borderRadius="full"
          w="full"
          maxW={{ base: 'full', md: '160px' }}
          onClick={handleVoltar}
        >
          Voltar
        </Button>
        <Stack
          w="full"
          justifyContent="flex-end"
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: 2, sm: 4, md: 6 }}
        >
          {!isReadOnly && (
            <Button
              variant="outlineDefault"
              borderRadius="full"
              w="full"
              maxW={{ base: 'full', md: '160px' }}
              onClick={handleDescartarEntradaMercadoria}
              isDisabled={!temPermissaoExcluir}
            >
              Descartar
            </Button>
          )}

          {!isReadOnly && (
            <Button
              variant="outlineDefault"
              borderRadius="full"
              w="full"
              maxW={{ base: 'full', md: '160px' }}
              onClick={handleSalvarRascunho}
            >
              Salvar e sair
            </Button>
          )}

          {!IsCadastroExterno && (
            <>
              {statusLancamentos.foiLancadoEstoque ? (
                <Button
                  colorScheme="purple"
                  borderRadius="full"
                  w="full"
                  maxW={{ base: 'full', md: '160px' }}
                  onClick={handleAvancar}
                >
                  Avançar
                </Button>
              ) : (
                <Button
                  colorScheme="purple"
                  borderRadius="full"
                  w="full"
                  maxW={{
                    base: 'full',
                    md: isReadOnly ? '196px' : '160px',
                  }}
                  onClick={handleFinalizar}
                >
                  {isReadOnly ? 'Voltar para a listagem' : 'Finalizar'}
                </Button>
              )}
            </>
          )}
        </Stack>
      </Footer>
    </>
  );
}
