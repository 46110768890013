export type FinalidadesNotaFiscal = 1 | 2 | 3 | 4;

const FinalidadesNotaFiscalEnum = {
  NFeNormal: 1,
  NFeComplementar: 2,
  NFeAjuste: 3,
  DevolucaoMercadoria: 4,

  properties: {
    1: { name: 'Devolução de mercadoria', value: 4 },
    2: { name: 'NF-e complementar', value: 2 },
    3: { name: 'NF-e de ajuste', value: 3 },
    4: { name: 'NF-e normal', value: 1 },
  },
};

export default FinalidadesNotaFiscalEnum;
