import { IconType } from 'icons';
import GenIcon from 'icons/GenIcon';

export const FomerPDVLogo: IconType = ({ size, color, ...props }) => (
  <GenIcon viewBox="0 0 64.3 23.3" size={size} {...props}>
    <path
      fill="#fff"
      strokeWidth="0px"
      d="M17,6c-3.7,0-5.6,2.2-5.6,6.7s1.9,6.7,5.6,6.7,5.6-2.2,5.6-6.7-1.9-6.7-5.6-6.7ZM17,16.5c-1.6,0-2.4-1.3-2.4-3.8,0-2.6.8-3.9,2.5-3.9s2.4,1.2,2.4,3.8c0,2.6-.8,3.9-2.4,3.9Z"
    />
    <path
      fill="#fff"
      strokeWidth="0px"
      d="M37.5,6c-1.7,0-3,.3-3.9,1.1-.9-.7-2.2-1.1-3.9-1.1-3.7,0-5.6,1.9-5.6,5.6v7.8h3.3v-8.4c0-1.4.8-2,2.2-2s2.2.7,2.3,2v8.4h3.3v-8.4c0-1.4.8-2,2.2-2s2.2.7,2.3,2v6.8c0,1,.5,1.6,1.6,1.6h1.7v-7.7c0-3.7-1.8-5.6-5.6-5.6Z"
    />
    <path
      fill="#fff"
      strokeWidth="0px"
      d="M50.2,6c-3.8,0-5.7,2.2-5.7,6.6s1.9,6.7,5.7,6.8c3.1,0,4.8-1.5,5.4-4.2h-2.3c-.4,0-.8.1-1.1.5-.6.6-1.3.9-2,.9-1.5,0-2.4-1-2.5-3h8.1c.2-5-1.6-7.6-5.6-7.6ZM47.7,11.2c.2-1.7,1-2.6,2.4-2.6s2.1.9,2.3,2.6h-4.7Z"
    />
    <path
      fill="#fff"
      strokeWidth="0px"
      d="M63.7,6c-4.3,0-6.5,2.2-6.6,6.5v6.9h3.3v-7c0-1.9.9-2.9,2.7-2.9h1.1v-3.5h-.5Z"
    />
    <path
      fill="#fff"
      strokeWidth="0px"
      d="M.1,9.9v9.5h3.3v-6.7h4.5c1,0,1.8-.7,1.8-1.6v-1.1H3.4v-.2c0-1.8.9-2.7,2.7-2.7h4.6v-3.2h-3.9C2.4,3.9.2,5.9.1,9.9Z"
    />
  </GenIcon>
);

export const FomerSymbol: IconType = ({ size, color, ...props }) => (
  <GenIcon viewBox="0 0 27.7 23.3" size={size} {...props}>
    <path
      fill="#fff"
      strokeWidth="0px"
      d="M16,23.2c-3.3,0-6.4-1.4-8.6-3.8-.3-.4-.3-1,0-1.3.4-.3,1-.3,1.3,0,2.3,2.7,5.8,3.8,9.4,3,2.5-.6,4.7-2.1,6.1-4.3,1.4-2.2,1.8-4.8,1.3-7.3-1.2-5.2-6.4-8.5-11.6-7.4-3.7.8-6.5,3.7-7.4,7.4-.1.5-.6.8-1.1.7-.5-.1-.8-.6-.7-1.1C5.8,4.8,9.2,1.3,13.6.4c6.2-1.4,12.4,2.5,13.8,8.7.7,3,.1,6.1-1.5,8.7s-4.2,4.4-7.2,5.1c-.9.2-1.7.3-2.6.3Z"
    />
    <path
      fill="#fff"
      strokeWidth="0px"
      d="M15.5,14.6c-.4,0-.8-.3-.9-.8,0-.5.3-1,.8-1l4.6-.7c.5,0,1,.3,1,.8,0,.5-.3,1-.8,1l-4.6.7s0,0-.1,0Z"
    />
    <path
      fill="#fff"
      strokeWidth="0px"
      d="M16.6,11.6c-.4,0-.8-.3-.9-.8,0-.5.3-1,.8-1l3-.4c.5,0,1,.3,1,.8,0,.5-.3,1-.8,1l-3,.4s0,0-.1,0Z"
    />
    <path
      fill="#fff"
      strokeWidth="0px"
      d="M15.4,17.5c-1.2,0-1.8-.4-2.4-.8-.5-.4-1-.7-3.1-.3-.6,0-5.3.9-6.1,1-1.2,0-2.1-.2-2.8-.8-.8-.8-.9-1.8-.9-2,0-.2,0-1.3.8-2.1.4-.4,1.1-.9,2.4-.9h6.5c2.8,0,3.5-.6,3.8-4.2,0-1,0-1.3,0-1.3,0-.5.4-.9.9-.9h0c.5,0,.9.4.9.9,0,0,0,.3,0,1.3s0,0,0,0c-.3,4.4-1.6,5.9-5.6,6H3.2c-.5,0-.8.1-1,.3-.3.3-.3.8-.3.8,0,0,0,0,0,0h0s0,.4.3.7c.3.3.9.3,1.3.3.6,0,3.9-.6,6-1,2.7-.5,3.6,0,4.5.6.5.3.8.6,2,.4.5,0,1.3-.2,2.1-.3.8-.1,1.5-.3,2.1-.4.5,0,1,.3,1,.8,0,.5-.3,1-.8,1-.5,0-1.3.2-2,.3-.9.2-1.7.3-2.2.4-.3,0-.6,0-.9,0ZM14.4,7.3h0,0Z"
    />
  </GenIcon>
);

export const FomerFullLogo: IconType = ({ size, color, ...props }) => (
  <GenIcon
    viewBox="0 0 95.8 23.3"
    size={size}
    {...props}
    style={{
      marginRight: '12px',
    }}
  >
    <g>
      <path
        strokeWidth="0px"
        fill="#fff"
        d="M48.4,6c-3.7,0-5.6,2.2-5.6,6.7s1.9,6.7,5.6,6.7,5.6-2.2,5.6-6.7-1.9-6.7-5.6-6.7ZM48.4,16.5c-1.6,0-2.4-1.3-2.4-3.8,0-2.6.8-3.9,2.5-3.9s2.4,1.2,2.4,3.8c0,2.6-.8,3.9-2.4,3.9Z"
      />
      <path
        strokeWidth="0px"
        fill="#fff"
        d="M68.9,6c-1.7,0-3,.3-3.9,1.1-.9-.7-2.2-1.1-3.9-1.1-3.7,0-5.6,1.9-5.6,5.6v7.8h3.3v-8.4c0-1.4.8-2,2.2-2s2.2.7,2.3,2v8.4h3.3v-8.4c0-1.4.8-2,2.2-2s2.2.7,2.3,2v6.8c0,1,.5,1.6,1.6,1.6h1.7v-7.7c0-3.7-1.8-5.6-5.6-5.6Z"
      />
      <path
        strokeWidth="0px"
        fill="#fff"
        d="M81.6,6c-3.8,0-5.7,2.2-5.7,6.6s1.9,6.7,5.7,6.8c3.1,0,4.8-1.5,5.4-4.2h-2.3c-.4,0-.8.1-1.1.5-.6.6-1.3.9-2,.9-1.5,0-2.4-1-2.5-3h8.1c.2-5-1.6-7.6-5.6-7.6ZM79.2,11.2c.2-1.7,1-2.6,2.4-2.6s2.1.9,2.3,2.6h-4.7Z"
      />
      <path
        strokeWidth="0px"
        fill="#fff"
        d="M95.2,6c-4.3,0-6.5,2.2-6.6,6.5v6.9h3.3v-7c0-1.9.9-2.9,2.7-2.9h1.1v-3.5h-.5Z"
      />
      <path
        strokeWidth="0px"
        fill="#fff"
        d="M31.5,9.8v9.5h3.3v-6.7h4.5c1,0,1.8-.7,1.8-1.6v-1.1h-6.3v-.2c0-1.8.9-2.7,2.7-2.7h4.6v-3.2h-3.9c-4.3,0-6.5,2-6.6,6Z"
      />
    </g>
    <g>
      <path
        strokeWidth="0px"
        fill="#fff"
        d="M16,23.1c-3.3,0-6.4-1.4-8.5-3.8-.3-.4-.3-.9,0-1.3.4-.3.9-.3,1.3,0,2.3,2.7,5.8,3.8,9.4,3,2.5-.6,4.7-2.1,6.1-4.3,1.4-2.2,1.8-4.8,1.3-7.3-1.2-5.2-6.4-8.5-11.6-7.3-3.6.8-6.5,3.7-7.3,7.3-.1.5-.6.8-1.1.7-.5-.1-.8-.6-.7-1.1C5.8,4.8,9.2,1.4,13.5.4c6.2-1.4,12.3,2.5,13.7,8.7.7,3,.1,6.1-1.5,8.7s-4.2,4.4-7.2,5.1c-.9.2-1.7.3-2.6.3Z"
      />
      <path
        strokeWidth="0px"
        fill="#fff"
        d="M15.5,14.6c-.4,0-.8-.3-.9-.8,0-.5.3-1,.8-1l4.6-.7c.5,0,1,.3,1,.8,0,.5-.3,1-.8,1l-4.6.7s0,0-.1,0Z"
      />
      <path
        strokeWidth="0px"
        fill="#fff"
        d="M16.6,11.6c-.4,0-.8-.3-.9-.8,0-.5.3-1,.8-1l3-.4c.5,0,1,.3,1,.8,0,.5-.3,1-.8,1l-3,.4s0,0-.1,0Z"
      />
      <path
        strokeWidth="0px"
        fill="#fff"
        d="M15.3,17.4c-1.2,0-1.8-.4-2.4-.8-.5-.4-1-.7-3.1-.3-.6,0-5.3.9-6.1,1-1.2,0-2.1-.2-2.7-.8-.8-.8-.8-1.8-.8-2,0-.2,0-1.3.8-2.1.4-.4,1.1-.9,2.4-.9h6.5c2.8,0,3.5-.6,3.7-4.2,0-1,0-1.3,0-1.3,0-.5.4-.9.9-.9h0c.5,0,.9.4.9.9,0,0,0,.3,0,1.3s0,0,0,0c-.3,4.4-1.6,5.9-5.5,6H3.2c-.5,0-.8.1-1,.3-.3.3-.3.8-.3.8,0,0,0,0,0,0h0s0,.4.3.7c.3.3.9.3,1.3.3.6,0,3.9-.6,5.9-1,2.7-.5,3.6,0,4.5.6.5.3.8.5,2,.4.5,0,1.3-.2,2.1-.3.8-.1,1.5-.3,2.1-.3.5,0,1,.3,1,.8,0,.5-.3,1-.8,1-.5,0-1.3.2-2,.3-.9.2-1.7.3-2.2.4-.3,0-.6,0-.9,0ZM14.3,7.3h0,0Z"
      />
    </g>
  </GenIcon>
);

export const FomerLoginLogo: IconType = ({ size, color, ...props }) => (
  <GenIcon viewBox="0 0 104.5 30.7" size={size} {...props}>
    <g xmlns="http://www.w3.org/2000/svg">
      <path
        strokeWidth="0px"
        fill="#fff"
        d="M57.2,9.5c-3.7,0-5.6,2.2-5.6,6.7s1.9,6.7,5.6,6.7,5.6-2.2,5.6-6.7-1.9-6.7-5.6-6.7ZM57.1,19.9c-1.6,0-2.4-1.3-2.4-3.8,0-2.6.8-3.8,2.5-3.8s2.4,1.2,2.4,3.7c0,2.6-.8,3.9-2.4,3.9Z"
      />
      <path
        strokeWidth="0px"
        fill="#fff"
        d="M77.6,9.5c-1.7,0-3,.3-3.9,1.1-.9-.7-2.2-1.1-3.9-1.1-3.7,0-5.6,1.9-5.6,5.6v7.8h3.3v-8.4c0-1.4.8-2,2.2-2s2.2.7,2.3,2v8.4h3.3v-8.4c0-1.4.8-2,2.2-2s2.2.7,2.3,2v6.7c0,1,.5,1.6,1.6,1.6h1.7v-7.7c0-3.7-1.8-5.6-5.6-5.6Z"
      />
      <path
        strokeWidth="0px"
        fill="#fff"
        d="M90.2,9.5c-3.8,0-5.7,2.2-5.7,6.6s1.9,6.6,5.6,6.8c3.1,0,4.7-1.5,5.4-4.2h-2.3c-.4,0-.8.1-1.1.5-.6.6-1.3.9-2,.9-1.5,0-2.4-1-2.5-3h8c.2-5-1.6-7.5-5.6-7.5ZM87.8,14.7c.2-1.7,1-2.6,2.4-2.6s2.1.9,2.3,2.6h-4.7Z"
      />
      <path
        strokeWidth="0px"
        fill="#fff"
        d="M103.8,9.5c-4.3,0-6.4,2.1-6.5,6.5v6.9h3.2v-6.9c0-1.9.9-2.9,2.7-2.9h1.1v-3.5h-.5Z"
      />
      <path
        strokeWidth="0px"
        fill="#fff"
        d="M40.3,13.3v9.5h3.3v-6.7h4.5c.9,0,1.8-.7,1.8-1.6v-1h-6.3v-.2c0-1.8.9-2.7,2.7-2.7h4.6v-3.2h-3.9c-4.3,0-6.5,2-6.6,6Z"
      />
    </g>
    <g xmlns="http://www.w3.org/2000/svg">
      <path
        strokeWidth="0px"
        fill="#fff"
        d="M21.4,30.6c-4.3,0-8.4-1.8-11.3-5.1-.4-.5-.4-1.3.1-1.7.5-.4,1.3-.4,1.7.1,3.1,3.5,7.7,5,12.4,3.9,6.9-1.5,11.2-8.4,9.7-15.3-.7-3.3-2.8-6.2-5.6-8-2.9-1.8-6.3-2.4-9.6-1.7-4.8,1.1-8.6,4.9-9.7,9.7-.1.7-.8,1.1-1.4.9-.7-.1-1.1-.8-.9-1.4C7.9,6.3,12.4,1.8,18.2.5c4-.9,8-.2,11.4,2,3.4,2.2,5.8,5.6,6.7,9.5,1.8,8.2-3.3,16.3-11.5,18.2-1.1.3-2.3.4-3.4.4Z"
      />
      <path
        strokeWidth="0px"
        fill="#fff"
        d="M20.7,19.3c-.6,0-1.1-.4-1.2-1,0-.7.4-1.3,1-1.4l6-.9c.7,0,1.3.4,1.4,1s-.4,1.3-1,1.4l-6,.9c0,0-.1,0-.2,0Z"
      />
      <path
        strokeWidth="0px"
        fill="#fff"
        d="M22.2,15.4c-.6,0-1.1-.4-1.2-1,0-.7.4-1.3,1-1.4l4-.6c.7,0,1.3.4,1.4,1,0,.7-.4,1.3-1,1.4l-4,.6c0,0-.1,0-.2,0Z"
      />
      <path
        strokeWidth="0px"
        fill="#fff"
        d="M20.5,23.1c-1.5,0-2.3-.5-3.2-1-.7-.5-1.3-.9-4.1-.4,0,0,0,0,0,0-.7.1-7.3,1.2-8.4,1.3-1.6.1-2.8-.2-3.6-1.1C.1,20.9,0,19.6,0,19.3c0-.3,0-1.7,1-2.8.5-.5,1.5-1.2,3.1-1.2h8.9c3.7-.1,4.6-.8,4.9-5.6,0-1.3,0-1.7,0-1.7,0-.7.5-1.2,1.2-1.2h0c.7,0,1.2.5,1.2,1.2,0,0,0,.4,0,1.8s0,0,0,0c-.4,5.8-2.1,7.8-7.3,7.9H4.2c-.6,0-1.1.2-1.4.5-.4.4-.4,1.1-.4,1.1,0,0,0,0,0,.1h0s0,.6.4.9c.4.4,1.2.4,1.7.4.7,0,5.3-.8,8.1-1.3,3.5-.7,4.8-.1,6,.7.6.4,1,.7,2.6.5.6,0,1.7-.3,2.8-.5,1-.2,2-.4,2.8-.5.7,0,1.3.4,1.4,1,0,.7-.4,1.3-1,1.4-.7,0-1.7.3-2.7.4-1.1.2-2.2.4-2.9.5-.4,0-.8,0-1.1,0ZM19.2,9.7h0,0Z"
      />
    </g>
  </GenIcon>
);
