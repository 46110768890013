import { Grid, Flex } from '@chakra-ui/react';
import React from 'react';
import AmbienteFiscalEnum from 'constants/enum/fiscal/ambienteFiscal';

interface ImpressaoInterface {
  contingencia: boolean;
  tipoAmbiente: number;
  informacoesFisco?: string;
}

const MensagemFiscal = ({
  contingencia,
  tipoAmbiente,
  informacoesFisco,
}: ImpressaoInterface) => {
  return (
    <Grid>
      {informacoesFisco && <Flex>{informacoesFisco}</Flex>}
      {contingencia && (
        <Grid>
          <Flex fontWeight="900">EMITIDA EM CONTINGÊNCIA</Flex>
          <Flex>Pendente de Autorização</Flex>
        </Grid>
      )}
      {tipoAmbiente === AmbienteFiscalEnum.Homologacao && (
        <Flex fontWeight="900">
          EMITIDA EM AMBIENTE DE HOMOLOGAÇÃO – SEM VALOR FISCAL
        </Flex>
      )}
    </Grid>
  );
};

export default MensagemFiscal;
