import React, {
  useEffect,
  useState,
  useRef,
  ReactNode,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  useMediaQuery,
  Box,
  BoxProps,
  Table,
  Thead,
  Tr,
  Th,
  VStack,
  Td,
  TableColumnHeaderProps,
  Tbody,
  Icon,
  ThemingProps,
} from '@chakra-ui/react';
import { FiArrowDown } from 'react-icons/fi';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import OptionType from 'types/optionType';

import { Pagination, PaginationData } from '../Pagination';

export type PagedTableForwardRefData = {
  reload: () => Promise<void>;
};

interface TableHeader extends Omit<TableColumnHeaderProps, 'children'> {
  key: string;
  content: ReactNode;
  isOrderable?: boolean;
}

interface PagedTableProps extends BoxProps {
  isLoading?: boolean;
  hasLoadingSpinner?: boolean;
  loadColumnsData: (paginationData: PaginationData) => Promise<void>;
  itemsTotalCount: number;
  itensPerPage?: number;
  pageSizesOptions?: OptionType[];
  showItemsTotalCount?: boolean;
  alterarFooter?: boolean;
  editarTable?: boolean;
  defaultKeyOrdered: string;
  defaultOrderDirection?: 'asc' | 'desc';
  tableHeaders: TableHeader[];
  renderTableRows: ReactNode;
  withoutRowsMessage?: string;
  canResetOrderColumnWhenLoad?: boolean;
  variant?: ThemingProps['variant'];
  size?: ThemingProps['size'];
  paginationHasDivider?: boolean;
  editarCorBorda?: string;
  isTable?: boolean;
}

export const PagedTable = forwardRef<PagedTableForwardRefData, PagedTableProps>(
  (
    {
      isLoading = false,
      hasLoadingSpinner = true,
      defaultKeyOrdered,
      loadColumnsData,
      editarTable,
      itemsTotalCount,
      alterarFooter = false,
      showItemsTotalCount = true,
      editarCorBorda = 'gray.100',
      tableHeaders,
      renderTableRows,
      withoutRowsMessage = 'Nenhum resultado foi encontrado',
      canResetOrderColumnWhenLoad = true,
      defaultOrderDirection = 'asc',
      borderTopRadius = 'md',
      variant = 'filled',
      size,
      isTable = true,
      paddingRight,
      paddingLeft,
      paddingBottom,
      pt,
      pb,
      paginationHasDivider = true.valueOf,
      itensPerPage = 10,
      pageSizesOptions,
      ...rest
    },
    ref
  ) => {
    const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

    const [paginationData, setPaginationData] = useState<PaginationData>({
      currentPage: 1,
      orderColumn: defaultKeyOrdered,
      orderDirection: defaultOrderDirection,
      pageSize: itensPerPage,
    });

    const hasRows = itemsTotalCount > 0;

    function handleChangeCurrentPage(newCurrentPage: number) {
      setPaginationData((prev) => ({ ...prev, currentPage: newCurrentPage }));
    }

    function handleChangePageSize(newPageSize: number) {
      setPaginationData((prev) => ({
        ...prev,
        pageSize: newPageSize,
        currentPage: 1,
      }));
    }

    function handleChangeOrderColumn(newOrderColumn: string) {
      setPaginationData((prev) => ({
        ...prev,
        orderColumn: newOrderColumn,
        orderDirection:
          prev.orderColumn === newOrderColumn && prev.orderDirection === 'asc'
            ? 'desc'
            : 'asc',
      }));
    }

    const firstUpdate = useRef(true);

    async function reload() {
      await loadColumnsData(paginationData);
    }

    useImperativeHandle(ref, () => ({
      reload,
    }));

    useEffect(() => {
      if (firstUpdate.current) {
        firstUpdate.current = false;
      } else {
        setPaginationData((prev) => ({
          ...prev,
          currentPage: 1,
          orderColumn: canResetOrderColumnWhenLoad
            ? defaultKeyOrdered
            : prev.orderColumn,
          orderDirection: canResetOrderColumnWhenLoad
            ? defaultOrderDirection
            : prev.orderDirection,
        }));
      }
    }, [
      canResetOrderColumnWhenLoad,
      defaultKeyOrdered,
      defaultOrderDirection,
      loadColumnsData,
    ]);

    const latestProps = useRef({ loadColumnsData });
    useEffect(() => {
      latestProps.current = { loadColumnsData };
    });

    useEffect(() => {
      latestProps.current.loadColumnsData(paginationData);
    }, [paginationData]);

    useEffect(() => {
      const newLastPage =
        Math.ceil(itemsTotalCount / paginationData.pageSize) || 1;

      if (paginationData.currentPage > newLastPage) {
        setPaginationData((prev) => ({ ...prev, currentPage: newLastPage }));
      }
    }, [itemsTotalCount, paginationData]);

    return (
      <Box
        bg="white"
        borderRadius="md"
        boxShadow="md"
        position="relative"
        {...rest}
      >
        {isLoading && hasLoadingSpinner && <LoadingPadrao />}
        <Box
          paddingLeft={editarTable ? '20px' : paddingLeft}
          paddingRight={editarTable ? '20px' : paddingRight}
          paddingBottom={paddingBottom}
          pt={pt}
          pb={pb}
          borderTopRadius={borderTopRadius}
          overflow="auto"
        >
          {isTable ? (
            <Table variant={variant} size={size}>
              <Thead>
                <Tr>
                  {tableHeaders.map(
                    ({
                      content,
                      key,
                      isOrderable = true,
                      pr = '',
                      ...restOfHeader
                    }) => {
                      const isOrdered = key === paginationData.orderColumn;

                      return (
                        <Th
                          key={key}
                          whiteSpace="nowrap"
                          userSelect="none"
                          paddingRight={pr}
                          paddingTop={editarTable ? '20px' : ''}
                          paddingBottom={editarTable ? '5px' : ''}
                          {...restOfHeader}
                          fontWeight={isOrdered ? 'bold' : 'normal'}
                          cursor={
                            isOrderable && !isLoading ? 'pointer' : 'default'
                          }
                          onClick={
                            isOrderable && !isLoading
                              ? () => {
                                  handleChangeOrderColumn(key);
                                }
                              : undefined
                          }
                        >
                          {content}
                          {isOrdered && (
                            <Icon
                              as={FiArrowDown}
                              transform={`rotate(${
                                paginationData.orderDirection === 'asc'
                                  ? '0deg'
                                  : '-180deg'
                              })`}
                              transition="transform .3s"
                              ml="1"
                            />
                          )}
                        </Th>
                      );
                    }
                  )}
                </Tr>
              </Thead>
              <Tbody>
                {hasRows ? (
                  renderTableRows
                ) : (
                  <Tr>
                    <Td whiteSpace="nowrap" colSpan={9999}>
                      {withoutRowsMessage}
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          ) : (
            <>
              {hasRows ? (
                renderTableRows
              ) : (
                <VStack
                  alignItems="left"
                  textAlign="left"
                  borderRadius="5px"
                  whiteSpace="nowrap"
                  w="full"
                  bg="white"
                  color="black"
                  pl="12px"
                  pr="12px"
                  pt="15px"
                  pb="15px"
                  spacing="10px"
                >
                  <h2>{withoutRowsMessage}</h2>
                </VStack>
              )}
            </>
          )}
        </Box>
        {hasRows && (
          <Box
            mx={alterarFooter ? '' : { base: 5, md: 6, xl: 7 }}
            {...(paginationHasDivider
              ? { borderTop: '1px', borderColor: editarCorBorda }
              : {})}
          >
            <Pagination
              showItemsTotalCount={showItemsTotalCount}
              alterarFooter={alterarFooter}
              pageSize={paginationData.pageSize}
              handleChangePageSize={handleChangePageSize}
              currentPage={paginationData.currentPage}
              handleChangeCurrentPage={handleChangeCurrentPage}
              itemsTotalCount={itemsTotalCount}
              siblingsCount={isLargerThan700 ? 2 : 1}
              pageSizesOptions={pageSizesOptions}
            />
          </Box>
        )}
      </Box>
    );
  }
);
