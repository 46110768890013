import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import OptionType from 'types/optionType';

type Cor = {
  value: string;
  label: string;
};

type Tamanho = {
  value: string;
  label: string;
};

export type TamanhoQuantidade = {
  tamanho: Tamanho;
  quantidade: number;
};

export type Produto = {
  id: string;
  nome: string;
  referencia: string;
  tipoProduto: number;
  volumeUnitario: boolean;
};

export type FormData = {
  produto: OptionType<Produto> | null;
  cor: Cor | null;
  listaTamanhoIdQuantidade?: TamanhoQuantidade[];
  custoAdicional: number;
  quantidade: number;
};

const selectShape = {
  value: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(null),
  label: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(null),
};

const schema = yup.object().shape({
  produto: yup
    .object()
    .shape({
      value: yup
        .object()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .default(null),
      label: yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .default(null),
      coresOptions: yup
        .array()
        .of(yup.object().shape(selectShape))
        .nullable()
        .default(null),
      tamanhosOptions: yup
        .array()
        .of(yup.object().shape(selectShape))
        .nullable()
        .default(null),
    })
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(null),
  cor: yup.object().shape(selectShape).nullable().default(null),
  listaTamanhoIdQuantidade: yup
    .array()
    .of(
      yup.object().shape({
        tamanho: yup.object().shape(selectShape).nullable().default(null),
        quantidade: yup
          .number()
          .moreThan(0, ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
          .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
          .nullable()
          .default(null),
      })
    )
    .nullable()
    .default(null),
  custoAdicional: yup.number().nullable(),
});

export const yupResolver = yupResolverInstance(schema);
