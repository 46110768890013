import React from 'react';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

import UnidadesMedidaListar from '../pages/UnidadesMedida/Listar';
import UnidadesMedidaCadastrar from '../pages/UnidadesMedida/Formulario/Cadastrar';
import UnidadesMedidaAlterar from '../pages/UnidadesMedida/Formulario/Alterar';
import UnidadesMedidaVisualizar from '../pages/UnidadesMedida/Formulario/Visualizar';

const UnidadesMedidaRoutes = [
  <LayoutGuard
    key="unidades-medida-listar"
    permissaoFuncionalidade={ConstanteFuncionalidades.UNIDADE_MEDIDA_LISTAR}
    component={UnidadesMedidaListar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Unidades de Medida',
      },
    ]}
    path={ConstanteRotas.UNIDADE_MEDIDA}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="unidades-medida-cadastrar"
    permissaoFuncionalidade={ConstanteFuncionalidades.UNIDADE_MEDIDA_CADASTRAR}
    component={UnidadesMedidaCadastrar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Unidades de Medida',
        path: ConstanteRotas.UNIDADE_MEDIDA,
      },
      {
        title: 'Novo',
      },
    ]}
    path={ConstanteRotas.UNIDADE_MEDIDA_CADASTRAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="unidades-medida-alterar"
    permissaoFuncionalidade={ConstanteFuncionalidades.UNIDADE_MEDIDA_ALTERAR}
    component={UnidadesMedidaAlterar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Unidades de Medida',
        path: ConstanteRotas.UNIDADE_MEDIDA,
      },
      {
        title: 'Editar',
      },
    ]}
    path={ConstanteRotas.UNIDADE_MEDIDA_ALTERAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="unidades-medida-visualizar"
    permissaoFuncionalidade={ConstanteFuncionalidades.UNIDADE_MEDIDA_VISUALIZAR}
    component={UnidadesMedidaVisualizar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Unidades de Medida',
        path: ConstanteRotas.UNIDADE_MEDIDA,
      },
      {
        title: 'Visualizar',
      },
    ]}
    path={ConstanteRotas.UNIDADE_MEDIDA_VISUALIZAR}
    exact
    meta={{ auth: true }}
  />,
];

export default UnidadesMedidaRoutes;
