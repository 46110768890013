import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { toast } from 'react-toastify';
import api, { ResponseApi } from 'services/api';

export type Estoque = {
  nome: string;
  id: string;
  selecionado: boolean;
};

export async function ObterLocalDeEstoquePadrao() {
  const response = await api.get<void, ResponseApi<Estoque[]>>(
    ConstanteEnderecoWebservice.LOCAL_ESTOQUE_LISTAR_PDV
  );

  if (response.avisos) {
    response.avisos.map((item: string) => toast.warning(item));
  }

  if (response.sucesso && response.dados) {
    const estoquePadraoDoUsuario = response.dados.find(
      (stock) => stock.selecionado
    );
    return {
      estoques: response.dados,
      estoquePadraoDoUsuario,
    };
  }

  return {
    estoques: [],
    estoquePadraoDoUsuario: {} as Estoque,
  };
}
