/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useRef, useEffect, useCallback } from 'react';
import {
  useToken,
  Input,
  InputProps,
  Icon,
  InputGroup,
  InputRightElement,
  MenuButton,
  IconButton,
  MenuList,
  Button,
} from '@chakra-ui/react';
import { isExists, isBefore, isAfter } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { Calendar, OnChangeProps } from 'react-date-range';
import InputMask from 'react-input-mask';

import { CalendarioIcon } from 'icons';
import MenuListas from 'components/PDV/Menu/MenuListas';
import {
  formatDateHourMinute,
  formatDateHourMinuteSecond,
  formatDate,
} from 'helpers/format/formatStringDate';

export interface InputDateProps extends Omit<InputProps, 'value' | 'onChange'> {
  value: Date | null;
  onChange: (newValue: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  asButton?: boolean;
  typeOfFormattedDate?:
    | 'hourAndMinutesAndSeconds'
    | 'hourAndMinutes'
    | 'onlyDate';
}

const InputDate = ({
  isDisabled,
  minDate,
  maxDate,
  asButton,
  value,
  onChange,
  size,
  maxW,
  h,
  typeOfFormattedDate = 'onlyDate',
  ...inputProps
}: InputDateProps) => {
  const [primary500, gray50, gray100, gray700] = useToken('colors', [
    'primary.500',
    'gray.50',
    'gray.100',
    'gray.700',
  ]);

  const ref = useRef<any>(null);

  const handleGetFormattedDate = (
    date: Date,
    type: string = typeOfFormattedDate || 'hourAndMinutes'
  ) => {
    if (type === 'hourAndMinutesAndSeconds') {
      return formatDateHourMinuteSecond(date);
    }
    if (type === 'hourAndMinutes') {
      return formatDateHourMinute(date);
    }
    return formatDate(date);
  };

  const handleSetInputValue = useCallback(() => {
    if (ref.current && ref.current.setInputValue) {
      ref.current.setInputValue(value ? handleGetFormattedDate(value) : null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleGetDateValuesInput = (newDate: string) => {
    const [day, month, year] = newDate.split('/');

    return { year: Number(year), month: Number(month) - 1, day: Number(day) };
  };

  const handleGetDateValuesCalendar = (newDate: Date) => {
    const dateObj = new Date(newDate);

    const year = dateObj.getUTCFullYear();
    const month = dateObj.getUTCMonth();
    const day = dateObj.getUTCDate();

    return { year, month, day };
  };

  const handleOnChange = (year: number, month: number, day: number) => {
    let isValid = false;

    try {
      isValid = isExists(year, month, day);
    } catch (error) {
      isValid = false;
    }

    if (isValid) {
      let newDate = new Date(year, month, day, 0, 0, 0);

      if (minDate && isBefore(newDate, minDate)) {
        newDate = minDate;
      } else if (maxDate && isAfter(newDate, maxDate)) {
        newDate = maxDate;
      }

      onChange(newDate);
    } else {
      handleSetInputValue();
    }
  };

  const MenuCalendar = () => (
    <MenuListas
      isDisabled={isDisabled}
      MenuButton={() =>
        asButton ? (
          <MenuButton
            tabIndex={-1}
            as={Button}
            aria-label="Calendário"
            size={size}
            fontSize="sm"
            isDisabled={isDisabled}
            h={h}
            _disabled={{ opacity: '1 !important' }}
            bg="gray.50"
            _hover={{
              bg: isDisabled ? 'gray.50' : 'gray.100',
            }}
            borderRadius="md"
          >
            {value ? handleGetFormattedDate(value) : '00/00/0000'}
          </MenuButton>
        ) : (
          <MenuButton
            _focus={{
              outiline: 'none',
            }}
            tabIndex={-1}
            as={IconButton}
            aria-label="Calendário"
            size={size}
            variant="ghost"
            borderRadius="md"
            icon={<Icon as={CalendarioIcon} color="gray.700" />}
            isDisabled={isDisabled}
            h={h}
            w={h}
            _disabled={{ '*': { opacity: 1 } }}
          />
        )
      }
      MenuList={() => (
        <MenuList
          zIndex="popover"
          py={0}
          sx={{
            '& .rdrCalendarWrapper': {
              display: 'flex',
            },
            '& .rdrMonthAndYearWrapper': {
              pt: 0,
              borderTopRadius: 'md',
            },
            '& .rdrMonths': {
              borderBottomRadius: 'md',
            },
            '& .rdrMonthName': {
              color: primary500,
            },
            '& .rdrDateDisplayWrapper, & .rdrStaticRange, & .rdrDefinedRangesWrapper': {
              bg: gray50,
              borderColor: gray100,
            },
            '& .rdrDayToday > .rdrDayNumber > span::after': {
              bg: primary500,
            },
            '& .rdrNextPrevButton.rdrPprevButton': {
              bg: gray100,
              '& > i': {
                borderColor: `transparent ${gray700} transparent transparent`,
              },
            },
            '& .rdrNextPrevButton.rdrNextButton': {
              bg: gray100,
              '& > i': {
                borderColor: `transparent transparent transparent ${gray700}`,
              },
            },
          }}
        >
          <Calendar
            //  @ts-ignore
            showMonthAndYearPickers={false}
            weekdayDisplayFormat="EEEEEE"
            //  @ts-ignore
            date={value ? new Date(value) : null}
            onChange={(range: OnChangeProps) => {
              const dateObj = handleGetDateValuesCalendar(range as Date);

              handleOnChange(dateObj.year, dateObj.month, dateObj.day);
            }}
            locale={ptBR}
            rangeColors={primary500}
            color={primary500}
            maxDate={maxDate}
            minDate={minDate}
          />
        </MenuList>
      )}
    />
  );

  useEffect(() => {
    handleSetInputValue();
  }, [handleSetInputValue, value]);

  return asButton ? (
    <MenuCalendar />
  ) : (
    <InputGroup size={size} maxW={maxW}>
      <Input
        as={InputMask}
        size={size}
        ref={ref}
        mask="99/99/9999"
        maskChar="0"
        alwaysShowMask
        placeholder="00/00/0000"
        {...inputProps}
        isDisabled={isDisabled}
        h={h}
        onBlur={(e) => {
          const currentValue = e.currentTarget.value;
          const dateObj = handleGetDateValuesInput(currentValue);

          handleOnChange(dateObj.year, dateObj.month, dateObj.day);
        }}
      />
      <InputRightElement top="50%" transform="translateY(-50%)">
        <MenuCalendar />
      </InputRightElement>
    </InputGroup>
  );
};

export default InputDate;
