import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';

import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import api, { ResponseApi } from 'services/api';
import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import RegraLimiteCreditoEnum from 'constants/enum/regraLimiteCredito';

import { ContainerListagem } from 'components/Layout/Listagem/containerListagem';

import { useForm, yupResolver, FormData } from '../validationForm';
import { UncontrolledForm } from '..';

interface CategoriaClienteInterface {
  id: string;
  descricao: string;
  regraLimiteCredito: number;
  diasAtrasoBloqueio: number;
  ativo: boolean;
  dataHoraCadastro: string;
  dataHoraUltimaAlteracao: string;
}

type TParams = { id: string };

const Visualizar = () => {
  const history = useHistory();
  const isMountedRef = useIsMountedRef();
  const { id } = useParams<TParams>();

  const [isLoading, setIsLoading] = useState(true);

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
  });

  const { reset } = formMethods;

  const [dataHoraCadastro, setDataHoraCadastro] = useState('');
  const [dataHoraUltimaAlteracao, setDataHoraUltimaAlteracao] = useState('');

  const handleGetCategoriaCliente = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<
      void,
      ResponseApi<CategoriaClienteInterface>
    >(ConstanteEnderecoWebservice.CATEGORIA_CLIENTE_OBTER, {
      params: { id },
    });

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso && isMountedRef.current) {
        const obterRegraLimiteCreditoSalva = RegraLimiteCreditoEnum.properties.filter(
          (regraLimite) =>
            regraLimite.value === response.dados.regraLimiteCredito
        );
        reset({
          ...response.dados,
          regraLimiteCredito: {
            value: obterRegraLimiteCreditoSalva[0].value,
            label: obterRegraLimiteCreditoSalva[0].name,
            color: obterRegraLimiteCreditoSalva[0].iconColor,
          },
        });

        setDataHoraCadastro(response.dados.dataHoraCadastro);
        setDataHoraUltimaAlteracao(response.dados.dataHoraUltimaAlteracao);
      } else {
        history.push(ConstanteRotas.CATEGORIA_CLIENTE);
      }
    }

    if (isMountedRef.current) setIsLoading(false);
  }, [history, isMountedRef, id, reset]);

  useEffect(() => {
    setIsLoading(true);

    handleGetCategoriaCliente();
  }, [handleGetCategoriaCliente]);

  return (
    <ContainerListagem
      formMethods={formMethods}
      isLoading={isLoading}
      dataHoraUltimaAlteracao={dataHoraUltimaAlteracao}
      dataHoraCadastro={dataHoraCadastro}
      isVisualizar
    >
      <UncontrolledForm readonly />
    </ContainerListagem>
  );
};

export default Visualizar;
