import auth from 'modules/auth';

interface ImgsSistemasProps {
  [key: number]: string;
}

export const getImageBySystem = (tipo: ImgsSistemasProps) => {
  const sistema = auth.getSistema();
  const sistemaValue: number = sistema.value;

  return tipo[sistemaValue];
};
