import { Flex, Text, VStack } from '@chakra-ui/react';

interface HeaderProps {
  identificador: number;
  parcela: string;
}

const Header = ({ identificador, parcela }: HeaderProps) => {
  return (
    <Flex width="100%" justifyContent="space-between" pt="12px">
      <VStack>
        <Text>Venda: {identificador}</Text>
      </VStack>
      <VStack>
        <Text>Parcela: {parcela}</Text>
      </VStack>
    </Flex>
  );
};

export default Header;
