import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  useMediaQuery,
  ModalContent,
  ModalHeader,
  ModalBody,
  Grid,
  ModalFooter,
  Button,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import api, { ResponseApi } from 'services/api';
import auth from 'modules/auth';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteRotas from 'constants/rotas';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import ShadowScrollbar from 'components/PDV/Geral/ShadowScrollbar';
import ConditionalWrapper from 'components/Geral/ConditionalWrapper';
import { useSignalRContext } from 'store/SignalR';
import { MobileModalMenu } from 'components/PDV/Layout/MobileModalMenu';
import { toast } from 'react-toastify';
import ModalPadraoChakra from '../ModalPadraoChakra';
import LojaButton from './LojaButton';

type Loja = {
  id: string;
  fantasia: string;
  endereco: string;
  logradouro: string;
  numeroBairro: string;
  cidade: string;
  bloqueada: boolean;
};

export interface LojaStorage {
  id: string;
  fantasia: string;
}

interface ModalAlternarLojaProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: (newLojaStorage: LojaStorage) => void;
  subTittle?: string;
  asMobileView?: boolean;
}

const ModalAlternarLoja: React.FC<ModalAlternarLojaProps> = ({
  isOpen,
  onClose,
  onSubmit,
  subTittle,
  asMobileView = false,
}) => {
  const isMountedRef = useIsMountedRef();
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const { userId = undefined } = auth.getDadosToken() || {};
  const lojaStorage = auth.getLoja();
  const { sendMessageToGroup } = useSignalRContext();
  const [lojas, setLojas] = useState<Loja[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [lojaSelecionada, setLojaSelecionada] = useState<LojaStorage>(
    lojaStorage
  );

  const history = useHistory();

  const handleSetLojaSelecionada = useCallback((loja: LojaStorage) => {
    setLojaSelecionada(loja);
  }, []);
  const validandoPermissoesTrocaLoja = async () => {
    const response = await api.put<void, ResponseApi>(
      ConstanteEnderecoWebservice.AUTENTICACAO_REFRESH_TOKEN,
      {
        token: auth.getToken(),
        refreshToken: auth.getRefreshToken(),
        lojaId: auth.getLoja().id,
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        auth.setRefreshToken(response.dados);

        api.defaults.headers.common.Authorization = `Bearer ${auth.getToken()}`;
      }
    }
  };

  const handleSubmit = useCallback(
    async (forcarLojaSelecionadaId?: string) => {
      const lojaSelecionadaId = forcarLojaSelecionadaId || lojaSelecionada.id;

      const lojaSelecionadaFantasia =
        lojas?.find((loja) => {
          return loja.id === lojaSelecionadaId;
        })?.fantasia || lojaStorage.fantasia;

      const newLojaStorage = {
        id: lojaSelecionadaId || lojaStorage.id,
        fantasia: lojaSelecionadaFantasia,
      };

      auth.setLoja(newLojaStorage);
      await validandoPermissoesTrocaLoja();

      sendMessageToGroup(auth.getDadosToken().securitystamp, 'trocar-loja', '');

      if (onSubmit) {
        onSubmit(newLojaStorage);
      }
      history.push(ConstanteRotas.DASHBOARD);
      onClose();
      window.location.reload();
    },
    [
      lojaSelecionada.id,
      history,
      lojaStorage.fantasia,
      lojaStorage.id,
      lojas,
      onClose,
      onSubmit,
      sendMessageToGroup,
    ]
  );

  const latestProps = useRef({
    isMountedRef,
    isOpen,
    lojaStorage,
    onClose,
    userId,
  });
  useEffect(() => {
    latestProps.current = {
      isMountedRef,
      isOpen,
      lojaStorage,
      onClose,
      userId,
    };
  });

  useEffect(() => {
    const handleGetLojas = async () => {
      if (latestProps.current.userId) {
        setIsLoading(true);

        const response = await api.get<void, ResponseApi<Loja[]>>(
          ConstanteEnderecoWebservice.LOJA_LISTAR_LOJAUSUARIO,
          {
            params: {
              usuarioId: latestProps.current.userId,
            },
          }
        );

        if (!response) latestProps.current.onClose();

        if (response && latestProps.current.isMountedRef.current) {
          if (response.sucesso) {
            setLojas(response.dados);
          } else {
            latestProps.current.onClose();
          }
        }

        setIsLoading(false);
      }
    };

    if (isOpen) {
      handleGetLojas();
    } else {
      setLojaSelecionada(latestProps.current.lojaStorage);
      setIsLoading(true);
    }
  }, [isOpen]);

  if (asMobileView) {
    return (
      <MobileModalMenu isOpen={isOpen} onClose={onClose} title="Alternar loja">
        <VStack spacing={2}>
          {lojas.map((loja) => (
            <LojaButton
              loja={loja}
              isBlocked={loja.bloqueada}
              isSelected={lojaSelecionada.id === loja.id}
              autoFocus={lojaSelecionada.id === loja.id}
              isDisabled={loja.bloqueada}
              onClick={() => {
                handleSubmit(loja.id);
              }}
            />
          ))}
        </VStack>
      </MobileModalMenu>
    );
  }

  return (
    <ModalPadraoChakra
      isOpen={isOpen}
      onClose={onClose}
      size={isLargerThan900 ? 'xl' : 'full'}
      scrollBehavior={isLargerThan900 ? 'inside' : 'outside'}
      isCentered={isLargerThan900}
    >
      <ModalContent
        margin={0}
        maxW={isLargerThan900 ? 900 : undefined}
        borderRadius={isLargerThan900 ? 'md' : 0}
        bg="gray.50"
      >
        <ModalHeader
          color="primary.500"
          pt="24px"
          pr="24px"
          pb="0"
          pl="24px"
          fontSize="md"
        >
          Alternar loja
        </ModalHeader>

        {subTittle && (
          <Text ml="6" mb="2">
            {subTittle}
          </Text>
        )}
        <ConditionalWrapper
          condition={isLargerThan900}
          wrapper={(wrappedChildren: React.ReactNode) => (
            <ShadowScrollbar
              maxHeight={360}
              paddingHeight={300}
              shadowTopStyle={{
                background:
                  'linear-gradient(to bottom, rgba(245, 245, 245, 1) 0%, rgba(245, 245, 245, 0) 100%)',
                height: 30,
              }}
              shadowBottomStyle={{
                background:
                  'linear-gradient(to top, rgba(245, 245, 245, 1) 0%, rgba(245, 245, 245, 0) 100%)',
                height: 30,
              }}
            >
              {wrappedChildren}
            </ShadowScrollbar>
          )}
        >
          <ModalBody>
            {isLoading && <LoadingPadrao />}
            <Grid
              templateColumns={`repeat(${isLargerThan900 ? 2 : 1}, 1fr)`}
              gap={isLargerThan900 ? '16px 24px' : 2}
              w="100%"
            >
              {lojas.map((loja) => (
                <LojaButton
                  isDisabled={loja.bloqueada}
                  key={loja.id}
                  loja={loja}
                  isBlocked={loja.bloqueada}
                  isSelected={lojaSelecionada.id === loja.id}
                  autoFocus={lojaSelecionada.id === loja.id}
                  onClick={() => {
                    handleSetLojaSelecionada({
                      id: loja.id,
                      fantasia: loja.fantasia,
                    });
                  }}
                />
              ))}
            </Grid>
          </ModalBody>
        </ConditionalWrapper>
        <ModalFooter py={12} justifyContent="center">
          <HStack spacing={6}>
            <Button
              variant="outline"
              size="sm"
              color="gray.400"
              w="96px"
              h="32px"
              _hover={{
                bg: 'red.500',
                color: 'white',
                border: '1px solid',
                borderColor: 'red.500',
              }}
              _active={{
                bg: 'red.600',
                color: 'white',
                border: '1px solid',
                borderColor: 'red.600',
              }}
              _focus={{
                boxShadow: 'none',
                bg: 'red.600',
                color: 'white',
                border: '1px solid',
              }}
              onClick={onClose}
            >
              Cancelar
            </Button>

            <Button
              variant="solid"
              colorScheme="secondary"
              w="160px"
              h="32px"
              _active={{
                bg: 'secondary.600',
              }}
              _focus={{
                boxShadow: 'none',
                bg: 'secondary.600',
              }}
              onClick={() => handleSubmit()}
            >
              Confirmar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export default ModalAlternarLoja;
