import {
  ModalContent,
  ModalBody,
  Button,
  Flex,
  useMediaQuery,
  Box,
} from '@chakra-ui/react';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';

import { ExibirImpressao } from '../Components/ExibirImpressao';

type ModalImpressaoProps = {
  isTodasImpressoes: boolean;
  isFichaImpressao: boolean;
  imagem: string;
  isOpen: boolean;
  onClose: () => void;
};
export const ModalImpressao = ({
  isTodasImpressoes,
  isFichaImpressao,
  imagem = '',
  isOpen,
  onClose,
  ...rest
}: ModalImpressaoProps) => {
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  return (
    <ModalPadraoChakra
      isCentered={isLargerThan900}
      size="full"
      {...rest}
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={false}
    >
      <ModalContent bg="gray.200" w="full" h="full">
        <>
          <ModalBody
            justifyContent="center"
            alignItems="center"
            h="full"
            w="full"
            pl="24px"
            pr="24px"
            pt="0"
            pb="0"
          >
            <Flex h="full" w="full" justifyContent="center" alignItems="center">
              <Box>
                <Flex
                  mb="48px"
                  w="full"
                  justifyContent="center"
                  alignItems="center"
                >
                  <ExibirImpressao
                    isTodasImpressoes={isTodasImpressoes}
                    isFichaImpressao={isFichaImpressao}
                    imagem={imagem}
                  />
                </Flex>
                <Flex
                  mb="48px"
                  w="full"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button
                    w="220px"
                    colorScheme="gray.700"
                    variant="outlineDefault"
                    onClick={onClose}
                  >
                    Fechar Pré-visualização
                  </Button>
                </Flex>
              </Box>
            </Flex>
          </ModalBody>
        </>
      </ModalContent>
    </ModalPadraoChakra>
  );
};
