import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Text,
  Heading,
  Stack,
  VStack,
  Image,
  Button,
  Flex,
  useBreakpointValue,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { useConferenciaEstoqueContainerContext } from 'store/ConferenciaEstoque/ConferenciaEstoqueContainer';
import auth from 'modules/auth';
import api, { ResponseApi } from 'services/api';
import { getImageBySystem } from 'helpers/layout/getImageBySystem';
import StatusImportacaoConferencia from 'constants/enum/statusImportacaoConferencia';
import {
  retomarConferenciaEstoqueImgs,
  iniciarConferenciaEstoqueImgs,
} from 'constants/enum/enumsImgSistemas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import ConstanteFuncionalidades from 'constants/permissoes';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { ModalErro } from 'components/Modal/ModalErro';
import { ModalConfirmacao } from 'components/Modal/ModalConfirmacao';

type VerificarConferenciaResponse = {
  conferenciaId?: string;
  usuarioId?: string;
  nomeUsuario: string;
  statusImportacao?: number;
};

type ConferenciaAtiva = {
  conferenciaId: string;
  usuarioId: string;
};

export function IniciarConferencia() {
  const history = useHistory();

  const possuiPermissaoDescartarConferencia = auth.possuiPermissao(
    ConstanteFuncionalidades.CONFERENCIA_ESTOQUE_DESCARTAR
  ).permitido;

  const possuiPermissaoIniciarConferencia = auth.possuiPermissao(
    ConstanteFuncionalidades.CONFERENCIA_ESTOQUE_INICIAR
  ).permitido;

  const possuiPermissaoContinuarConferencia = auth.possuiPermissao(
    ConstanteFuncionalidades.CONFERENCIA_ESTOQUE_CONTINUAR
  ).permitido;

  const isAdministrador = auth.getIsAdministrador();
  const { userId } = auth.getDadosToken();

  const { iniciarConferencia } = useConferenciaEstoqueContainerContext();

  const buttonSize = useBreakpointValue({
    base: 'md',
    md: 'lg',
  });

  const [isLoading, setIsLoading] = useState(true);
  const [conferenciaAtiva, setConferenciaAtiva] = useState<ConferenciaAtiva>();

  const hasConferenciaAtiva =
    !!conferenciaAtiva && !!conferenciaAtiva.conferenciaId;

  async function handleIniciarNovaConferencia() {
    if (!possuiPermissaoIniciarConferencia && !isAdministrador) {
      toast.warn(
        'Você não tem permissão para realizar essa ação. Consulte o administrador da conta.'
      );
      return;
    }

    if (conferenciaAtiva) {
      if (!possuiPermissaoDescartarConferencia && !isAdministrador) {
        toast.warn(
          'Você não tem permissão para realizar essa ação. Consulte o administrador da conta.'
        );
        return;
      }
      ModalConfirmacaoExcluir({
        text:
          'Deseja descartar a conferência em andamento para iniciar uma nova? Não será possível desfazer esta ação.',
        confirmButtonText: 'Sim, descartar!',
        callback: async (ok: boolean) => {
          if (ok) {
            setIsLoading(true);

            const response = await api.delete<
              void,
              ResponseApi<VerificarConferenciaResponse>
            >(ConstanteEnderecoWebservice.CONFERENCIA_ESTOQUE_EXCLUIR, {
              params: { id: conferenciaAtiva.conferenciaId },
            });

            if (response) {
              if (response.avisos) {
                response.avisos.map((aviso: string) => toast.warning(aviso));
              }

              if (response.sucesso) {
                iniciarConferencia();

                return;
              }
            }

            setIsLoading(false);
          }
        },
      });
    } else {
      iniciarConferencia();
    }
  }

  function handleContinuarConferencia() {
    if (conferenciaAtiva) {
      if (!possuiPermissaoContinuarConferencia && !isAdministrador) {
        toast.warn(
          'Você não tem permissão para realizar essa ação. Consulte o administrador da conta.'
        );
        return;
      }
      history.push(
        SubstituirParametroRota(
          ConstanteRotas.CONFERENCIA_ESTOQUE_CONTINUAR,
          'id',
          conferenciaAtiva.conferenciaId
        )
      );
    }
  }

  const exibirMensagemStatusImportacao = useCallback(
    async (status: number, conferenciaId?: string) => {
      setIsLoading(true);
      switch (status) {
        case StatusImportacaoConferencia.FALHA:
          await ModalErro({
            textoTitulo: 'Houve uma falha na importação',
            textoMensagem:
              'A importação foi interrompida devido a uma falha. Alguns dos possíveis motivos são: O código do produto não existe no sistema; A quantidade informada não é maior que 0; O produto é do tipo Kit; Se foi informado algum filtro no passo anterior a importação, o produto não está vinculado com a categoria ou a marca.',
            textoButton: 'Continuar',
            callback: async (ok?: boolean) => {
              if (ok && conferenciaId) {
                setIsLoading(false);
                history.push(
                  SubstituirParametroRota(
                    ConstanteRotas.CONFERENCIA_ESTOQUE_CONTINUAR,
                    'id',
                    conferenciaId
                  )
                );
              }
            },
          });
          break;
        case StatusImportacaoConferencia.EM_ANDAMENTO:
          toast.warning(
            'Existe uma importação em andamento. Aguarde a conclusão para continuar ou iniciar uma nova conferência.'
          );
          history.push(ConstanteRotas.DASHBOARD);
          break;
        default:
          toast.warning(
            'Existe uma importação ainda não concluída. Aguarde a conclusão para continuar ou iniciar uma nova conferência.'
          );
          history.push(ConstanteRotas.DASHBOARD);
      }
      setIsLoading(false);
    },
    [history]
  );

  const verificarConferenciaExistente = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<
      void,
      ResponseApi<VerificarConferenciaResponse>
    >(
      ConstanteEnderecoWebservice.CONFERENCIA_ESTOQUE_VERIFICAR_CONFERENCIA_EXISTENTE
    );

    if (response) {
      if (response.avisos) {
        response.avisos.map((aviso: string) => toast.warning(aviso));
      }

      if (response.sucesso) {
        const {
          conferenciaId,
          usuarioId,
          nomeUsuario,
          statusImportacao,
        } = response.dados;

        if (conferenciaId && usuarioId) {
          setConferenciaAtiva({ conferenciaId, usuarioId });

          if (!isAdministrador && usuarioId !== userId) {
            toast.warning(
              `Existe uma conferência em andamento, para continuar ou recomeçar a conferência entre com o usuário ${nomeUsuario}, ou com o usuário administrador.`
            );

            history.push('/');
            return;
          }

          if ((usuarioId === userId || isAdministrador) && statusImportacao) {
            if (statusImportacao === StatusImportacaoConferencia.CONCLUIDA) {
              await ModalConfirmacao({
                title: 'Importação concluída!',
                text:
                  'Os códigos foram carregados com sucesso. Deseja continuar a conferência?',
                cancelButtonText: 'Não!',
                confirmButtonText: 'Sim, continuar!',
                callback: async (ok?: boolean) => {
                  if (ok && conferenciaId) {
                    history.push(
                      SubstituirParametroRota(
                        ConstanteRotas.CONFERENCIA_ESTOQUE_CONTINUAR,
                        'id',
                        conferenciaId
                      )
                    );
                  } else {
                    setIsLoading(false);
                  }
                },
              });
            } else {
              exibirMensagemStatusImportacao(statusImportacao, conferenciaId);
            }
          }
        }
      } else {
        history.push('/');
      }
    } else {
      history.push('/');
    }

    setIsLoading(false);
  }, [exibirMensagemStatusImportacao, history, isAdministrador, userId]);

  useEffect(() => {
    verificarConferenciaExistente();
  }, [verificarConferenciaExistente]);

  return (
    <Box
      px={{ base: 4, md: 10, lg: 16, xl: 24 }}
      py={{ base: 6, md: 8, lg: 20, xl: 24 }}
      bg="white"
      boxShadow="md"
      borderRadius="md"
      position="relative"
    >
      {isLoading && <LoadingPadrao />}
      <Stack
        alignItems="center"
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
      >
        <VStack spacing={{ base: 4, lg: 6 }} alignItems="flex-start">
          <Heading
            fontWeight="bold"
            color="primary.50"
            fontSize={{ base: 'xl', md: '2xl', xl: '3xl' }}
          >
            {hasConferenciaAtiva
              ? 'Vamos continuar?'
              : 'Hora de contar o estoque?'}
          </Heading>
          <Text
            fontWeight="regular"
            fontSize="sm"
            w={{
              base: 'full',
              md: '390px',
              lg: '520px',
              xl: '790px',
            }}
          >
            {hasConferenciaAtiva ? (
              <>
                Que bom que você voltou! Podemos continuar exatamente de onde
                paramos ou podemos começar tudo novamente.{' '}
                <strong>
                  Não é possível manter dois processos simultâneos,
                </strong>{' '}
                portanto se preferir começar novamente, a conferência existente
                será apagada.
              </>
            ) : (
              <>
                Estoque é coisa séria e claro que vamos te ajudar com isso!
                Nosso método consiste em{' '}
                <strong>5 passos guiados pelo sistema</strong> para que você
                faça a conferência de forma organizada e automatizada. E se
                precisar pausar, não tem problema! Tudo fica salvo para retomar
                quando quiser. Vamos lá?
              </>
            )}
          </Text>

          {hasConferenciaAtiva ? (
            <VStack spacing={5}>
              <Button
                colorScheme="secondary"
                size={buttonSize}
                w="full"
                maxW={{ base: 'full', md: '380px' }}
                onClick={handleContinuarConferencia}
              >
                Continuar com a conferência existente
              </Button>
              <Button
                variant="outline"
                size={buttonSize}
                w="full"
                maxW={{ base: 'full', md: '380px' }}
                onClick={handleIniciarNovaConferencia}
                id="cadastrarNovo"
              >
                Iniciar uma nova conferência
              </Button>
            </VStack>
          ) : (
            <Button
              colorScheme="secondary"
              size={buttonSize}
              w="full"
              maxW={{ base: 'full', md: '320px' }}
              onClick={handleIniciarNovaConferencia}
              id="cadastrarNovo"
            >
              Iniciar uma nova conferência
            </Button>
          )}
        </VStack>

        <Flex
          alignItems="center"
          justifyContent={{ base: 'center', md: 'flex-end' }}
          pt={{ base: 10, md: 0 }}
          px={{ base: 10, md: 0 }}
          h="full"
          w="full"
        >
          <Image
            src={
              hasConferenciaAtiva
                ? getImageBySystem(retomarConferenciaEstoqueImgs)
                : getImageBySystem(iniciarConferenciaEstoqueImgs)
            }
            alt={
              hasConferenciaAtiva
                ? 'Retomar conferência de estoque'
                : 'Iniciar conferência de estoque'
            }
            maxH={{ base: '176px', lg: '243px', xl: 'full' }}
          />
        </Flex>
      </Stack>
    </Box>
  );
}
