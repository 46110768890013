import { PropertiesEtiquetas } from 'pages/EtiquetasPersonalizadas/FormularioEtiquetas/validationForm';

export const propriedadesEtiquetas: PropertiesEtiquetas = {
  alturaEtiqueta: 0,
  tipoImpressora: '',
  etiquetaAtiva: true,
  comprimentoEtiqueta: 0,
  margemSuperior: 0,
  margemEsquerda: 0,
  numeroLinhas: 0,
  numeroColunas: 0,
  espacoColunas: 0,
  espacoLinhas: 0,
  propriedadesItensEtiqueta: [
    {
      name: 'Logo',
      id: 0,
      campo: 'LOGO',
      isChecked: false,
      tamanhoFonte: 9,
      negrito: false,
      sublinhado: false,
      italico: false,
      valorNegrito: '',
      valorSublinhado: '',
      valorItalico: '',
      eixoY: 0,
      eixoX: 300,
      eixoXemCm: 0,
      eixoYemCm: 0,
      quantidadeCaracteres: 0,
      tipoLogo: 'LOGO',
      quantidadeParcela: 1,
      tipoPreco: 'PRECO',
      tipoCodigoBarras: 'CODIGO_BARRAS',
    },
    {
      name: 'Nome fantasia',
      id: 1,
      campo: 'NOME_FANTASIA',
      isChecked: false,
      tamanhoFonte: 9,
      negrito: false,
      sublinhado: false,
      italico: false,
      valorNegrito: '',
      valorSublinhado: '',
      valorItalico: '',
      eixoY: 0,
      eixoX: 300,
      eixoXemCm: 0,
      eixoYemCm: 0,
      quantidadeCaracteres: 0,
      tipoLogo: 'LOGO',
      quantidadeParcela: 1,
      tipoPreco: 'PRECO',
      tipoCodigoBarras: 'CODIGO_BARRAS',
    },
    {
      name: 'Descrição',
      id: 2,
      campo: 'DESCRICAO_PRODUTO',
      isChecked: false,
      tamanhoFonte: 9,
      negrito: false,
      sublinhado: false,
      italico: false,
      valorNegrito: '',
      valorSublinhado: '',
      valorItalico: '',
      eixoY: 0,
      eixoX: 300,
      eixoXemCm: 0,
      eixoYemCm: 0,
      quantidadeCaracteres: 0,
      tipoLogo: 'LOGO',
      quantidadeParcela: 1,
      tipoPreco: 'PRECO',
      tipoCodigoBarras: 'CODIGO_BARRAS',
    },
    {
      name: 'Cor',
      id: 3,
      campo: 'COR',
      isChecked: false,
      tamanhoFonte: 9,
      negrito: false,
      sublinhado: false,
      italico: false,
      valorNegrito: '',
      valorSublinhado: '',
      valorItalico: '',
      eixoY: 0,
      eixoX: 300,
      eixoXemCm: 0,
      eixoYemCm: 0,
      quantidadeCaracteres: 0,
      tipoLogo: 'LOGO',
      quantidadeParcela: 1,
      tipoPreco: 'PRECO',
      tipoCodigoBarras: 'CODIGO_BARRAS',
    },
    {
      name: 'Tamanho',
      id: 4,
      campo: 'TAMANHO',
      isChecked: false,
      tamanhoFonte: 9,
      negrito: false,
      sublinhado: false,
      italico: false,
      valorNegrito: '',
      valorSublinhado: '',
      valorItalico: '',
      eixoY: 0,
      eixoX: 300,
      eixoXemCm: 0,
      eixoYemCm: 0,
      quantidadeCaracteres: 0,
      tipoLogo: 'LOGO',
      quantidadeParcela: 1,
      tipoPreco: 'PRECO',
      tipoCodigoBarras: 'CODIGO_BARRAS',
    },
    {
      name: 'Categoria',
      id: 5,
      campo: 'CATEGORIA',
      isChecked: false,
      tamanhoFonte: 9,
      negrito: false,
      sublinhado: false,
      italico: false,
      valorNegrito: '',
      valorSublinhado: '',
      valorItalico: '',
      eixoY: 0,
      eixoX: 300,
      eixoXemCm: 0,
      eixoYemCm: 0,
      quantidadeCaracteres: 0,
      tipoLogo: 'LOGO',
      quantidadeParcela: 1,
      tipoPreco: 'PRECO',
      tipoCodigoBarras: 'CODIGO_BARRAS',
    },
    {
      name: 'Marca',
      id: 6,
      campo: 'MARCA',
      isChecked: false,
      tamanhoFonte: 9,
      negrito: false,
      sublinhado: false,
      italico: false,
      valorNegrito: '',
      valorSublinhado: '',
      valorItalico: '',
      eixoY: 0,
      eixoX: 300,
      eixoXemCm: 0,
      eixoYemCm: 0,
      quantidadeCaracteres: 0,
      tipoLogo: 'LOGO',
      quantidadeParcela: 1,
      tipoPreco: 'PRECO',
      tipoCodigoBarras: 'CODIGO_BARRAS',
    },
    {
      name: 'Unidade medida',
      id: 7,
      campo: 'UNIDADE_MEDIDA',
      isChecked: false,
      tamanhoFonte: 9,
      negrito: false,
      sublinhado: false,
      italico: false,
      valorNegrito: '',
      valorSublinhado: '',
      valorItalico: '',
      eixoY: 0,
      eixoX: 300,
      eixoXemCm: 0,
      eixoYemCm: 0,
      quantidadeCaracteres: 0,
      tipoLogo: 'LOGO',
      quantidadeParcela: 1,
      tipoPreco: 'PRECO',
      tipoCodigoBarras: 'CODIGO_BARRAS',
    },
    {
      name: 'GTIN/EAN',
      id: 8,
      campo: 'GTIN_EAN',
      isChecked: false,
      tamanhoFonte: 9,
      negrito: false,
      sublinhado: false,
      italico: false,
      valorNegrito: '',
      valorSublinhado: '',
      valorItalico: '',
      eixoY: 0,
      eixoX: 300,
      eixoXemCm: 0,
      eixoYemCm: 0,
      quantidadeCaracteres: 0,
      tipoLogo: 'LOGO',
      quantidadeParcela: 1,
      tipoPreco: 'PRECO',
      tipoCodigoBarras: 'CODIGO_BARRAS',
    },
    {
      name: 'Código de barras',
      id: 9,
      campo: 'CODIGO_BARRAS',
      isChecked: false,
      tamanhoFonte: 9,
      negrito: false,
      sublinhado: false,
      italico: false,
      valorNegrito: '',
      valorSublinhado: '',
      valorItalico: '',
      tamanhoCodigoBarras: 40,
      eixoY: -20,
      eixoX: 300,
      eixoXemCm: 0,
      eixoYemCm: 0,
      quantidadeCaracteres: 0,
      tipoLogo: 'LOGO',
      quantidadeParcela: 1,
      tipoPreco: 'PRECO',
      tipoCodigoBarras: 'CODIGO_BARRAS',
    },
    {
      name: 'QrCode',
      id: 10,
      campo: 'QRCODE',
      isChecked: false,
      tamanhoFonte: 9,
      negrito: false,
      sublinhado: false,
      italico: false,
      valorNegrito: '',
      valorSublinhado: '',
      valorItalico: '',
      eixoY: 0,
      eixoX: 300,
      eixoXemCm: 0,
      eixoYemCm: 0,
      quantidadeCaracteres: 0,
      tipoLogo: 'LOGO',
      quantidadeParcela: 1,
      tipoPreco: 'PRECO',
      tipoCodigoBarras: 'CODIGO_BARRAS',
    },
    {
      name: 'Referência',
      id: 11,
      campo: 'REFERENCIA',
      isChecked: false,
      tamanhoFonte: 9,
      negrito: false,
      sublinhado: false,
      italico: false,
      valorNegrito: '',
      valorSublinhado: '',
      valorItalico: '',
      eixoY: 0,
      eixoX: 300,
      eixoXemCm: 0,
      eixoYemCm: 0,
      quantidadeCaracteres: 0,
      tipoLogo: 'LOGO',
      quantidadeParcela: 1,
      tipoPreco: 'PRECO',
      tipoCodigoBarras: 'CODIGO_BARRAS',
    },
    {
      name: 'Preço',
      id: 12,
      campo: 'PRECO',
      isChecked: false,
      tamanhoFonte: 9,
      negrito: false,
      sublinhado: false,
      italico: false,
      valorNegrito: '',
      valorSublinhado: '',
      valorItalico: '',
      eixoY: 0,
      eixoX: 300,
      eixoXemCm: 0,
      eixoYemCm: 0,
      quantidadeCaracteres: 0,
      tipoLogo: 'LOGO',
      quantidadeParcela: 1,
      tipoPreco: 'PRECO',
      tipoCodigoBarras: 'CODIGO_BARRAS',
    },
    {
      name: 'Texto fixo',
      id: 13,
      campo: 'TEXTO_FIXO',
      isChecked: false,
      tamanhoFonte: 9,
      negrito: false,
      sublinhado: false,
      italico: false,
      textoFixo: '',
      valorNegrito: '',
      valorSublinhado: '',
      valorItalico: '',
      eixoY: 0,
      eixoX: 300,
      eixoXemCm: 0,
      eixoYemCm: 0,
      quantidadeCaracteres: 0,
      tipoLogo: 'LOGO',
      quantidadeParcela: 1,
      tipoPreco: 'PRECO',
      tipoCodigoBarras: 'CODIGO_BARRAS',
    },
    {
      name: 'Nº código de barras',
      id: 14,
      campo: 'NUMERO_CODIGO_BARRAS',
      isChecked: false,
      tamanhoFonte: 9,
      negrito: false,
      sublinhado: false,
      italico: false,
      textoFixo: '',
      valorNegrito: '',
      valorSublinhado: '',
      valorItalico: '',
      eixoY: 0,
      eixoX: 300,
      eixoXemCm: 0,
      eixoYemCm: 0,
      quantidadeCaracteres: 13,
      tipoLogo: 'LOGO',
      quantidadeParcela: 1,
      tipoPreco: 'PRECO',
      tipoCodigoBarras: 'CODIGO_BARRAS',
    },

    {
      name: 'Texto fixo 2',
      id: 16,
      campo: 'TEXTO_FIXO_2',
      isChecked: false,
      tamanhoFonte: 9,
      negrito: false,
      sublinhado: false,
      italico: false,
      textoFixo: '',
      valorNegrito: '',
      valorSublinhado: '',
      valorItalico: '',
      eixoY: 0,
      eixoX: 300,
      eixoXemCm: 0,
      eixoYemCm: 0,
      quantidadeCaracteres: 0,
      tipoLogo: 'LOGO',
      quantidadeParcela: 1,
      tipoPreco: 'PRECO',
      tipoCodigoBarras: 'CODIGO_BARRAS',
    },
    {
      name: 'Preço 2',
      id: 17,
      campo: 'PRECO_2',
      isChecked: false,
      tamanhoFonte: 9,
      negrito: false,
      sublinhado: false,
      italico: false,
      textoFixo: '',
      valorNegrito: '',
      valorSublinhado: '',
      valorItalico: '',
      eixoY: 0,
      eixoX: 300,
      eixoXemCm: 0,
      eixoYemCm: 0,
      quantidadeCaracteres: 0,
      tipoLogo: 'LOGO',
      quantidadeParcela: 1,
      tipoPreco: 'PRECO',
      tipoCodigoBarras: 'CODIGO_BARRAS',
    },
    {
      name: 'Parcelamento',
      id: 18,
      campo: 'PARCELAMENTO',
      isChecked: false,
      tamanhoFonte: 9,
      negrito: false,
      sublinhado: false,
      italico: false,
      textoFixo: '',
      valorNegrito: '',
      valorSublinhado: '',
      valorItalico: '',
      eixoY: 0,
      eixoX: 300,
      eixoXemCm: 0,
      eixoYemCm: 0,
      quantidadeCaracteres: 0,
      tipoLogo: 'LOGO',
      quantidadeParcela: 1,
      tipoPreco: 'PRECO',
      tipoCodigoBarras: 'CODIGO_BARRAS',
    },
    {
      name: 'Código SKU',
      id: 20,
      campo: 'CODIGO_SKU',
      isChecked: false,
      tamanhoFonte: 9,
      negrito: false,
      sublinhado: false,
      italico: false,
      textoFixo: '',
      valorNegrito: '',
      valorSublinhado: '',
      valorItalico: '',
      eixoY: 0,
      eixoX: 300,
      eixoXemCm: 0,
      eixoYemCm: 0,
      quantidadeCaracteres: 0,
      tipoLogo: 'LOGO',
      quantidadeParcela: 1,
      tipoPreco: 'PRECO',
      tipoCodigoBarras: 'CODIGO_BARRAS',
    },
  ],
};

export const enumTipoEtiqueta = {
  LOGO: { name: 'LOGO', value: 0 },
  NOME: { name: 'NOME_FANTASIA', value: 1 },
  DESCRICAO_PRODUTO: { name: 'DESCRICAO_PRODUTO', value: 2 },
  COR: { name: 'COR', value: 3 },
  TAMANHO: { name: 'TAMANHO', value: 4 },
  CATEGORIA: { name: 'CATEGORIA', value: 5 },
  MARCA: { name: 'MARCA', value: 6 },
  UNIDADE_MEDIDA: { name: 'UNIDADE_MEDIDA', value: 7 },
  GTIN_EAN: { name: 'GTIN_EAN', value: 8 },
  CODIGO_BARRAS: { name: 'CODIGO_BARRAS', value: 9 },
  QRCODE: { name: 'QRCODE', value: 10 },
  REFERENCIA: { name: 'REFERENCIA', value: 11 },
  PRECO: { name: 'PRECO', value: 12 },
  TEXTO_FIXO: { name: 'TEXTO_FIXO', value: 13 },
  NUMERO_CODIGO_BARRAS: { name: 'NUMERO_CODIGO_BARRAS', value: 14 },
  TEXTO_FIXO_2: { name: 'TEXTO_FIXO_2', value: 16 },
  PRECO_2: { name: 'PRECO_2', value: 17 },
  PARCELAMENTO: { name: 'PARCELAMENTO', value: 18 },
  CODIGO_BARRAS_REDUZIDO: { name: 'CODIGO_BARRAS_REDUZIDO ', value: 19 },
  CODIGO_SKU: { name: 'CODIGO_SKU ', value: 20 },
};
