import React, { useEffect } from 'react';
import { HStack, Icon, Text, Spinner } from '@chakra-ui/react';

import { useInformacoesGeraisContext } from 'store/PDV/InformacoesGerais';

import { CaixaAbertoIcon } from 'icons';
import NavHeaderButton from 'components/PDV/Layout/NavHeader/Button';

interface CaixaAbertoProps {
  asNavHeaderButton?: boolean;
}

const CaixaAberto = ({ asNavHeaderButton }: CaixaAbertoProps) => {
  const {
    contaFinanceira,
    contaFinanceiraIsLoading,
    carregarInformacoes,
  } = useInformacoesGeraisContext();

  useEffect(() => {
    carregarInformacoes();
  }, [carregarInformacoes]);

  return asNavHeaderButton ? (
    <NavHeaderButton
      icon={CaixaAbertoIcon}
      title={
        contaFinanceiraIsLoading
          ? 'Carregando...'
          : contaFinanceira?.nome || 'Caixa Fechado'
      }
      titleColor={contaFinanceira ? 'secondary.500' : 'red.500'}
      colorScheme={contaFinanceira ? 'secondary' : 'red'}
      pointerEvents="none"
    />
  ) : (
    <HStack spacing="3">
      {contaFinanceiraIsLoading ? (
        <>
          <Spinner
            size="md"
            color="secondary.500"
            emptyColor="gray.300"
            speed="0.65s"
          />
          <Text fontSize="xs" color="secondary.500" lineHeight="none">
            Carregando...
          </Text>
        </>
      ) : (
        <>
          <Icon
            as={CaixaAbertoIcon}
            boxSize="6"
            color={contaFinanceira ? 'secondary.500' : 'red.500'}
          />
          <Text
            isTruncated
            maxW="170px"
            fontSize={{ base: 'md', md: 'sm' }}
            color={contaFinanceira ? 'secondary.500' : 'red.500'}
            lineHeight="none"
          >
            {contaFinanceira?.nome || 'Caixa Fechado'}
          </Text>
        </>
      )}
    </HStack>
  );
};

export default CaixaAberto;
