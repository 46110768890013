import { useState, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Flex } from '@chakra-ui/react';
import jsFileDownload from 'js-file-download';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import { ImprimirPDF } from 'helpers/impressoes/imprimirPDF';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { enumRelatorioEstoque } from 'constants/enum/enumRelatorioEstoque';

import { CustomFormStandardComponent } from 'components/update/CustomFormStandard';

import { validarTipoRelatorioEstoque } from './ValidarTipoRelatorioEstoque';
import { FormData, yupResolver, formDefaultValues } from './validationForms';

export const RelatorioEstoque = () => {
  const [isLoading, setIsLoading] = useState(false);

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues: formDefaultValues,
  });

  const { handleSubmit: onSubmit, watch } = formMethods;
  const tipoRelatorio = watch('tipoRelatorio');

  const isEstoqueSemPreco =
    tipoRelatorio === enumRelatorioEstoque.POSICAO_ESTOQUE;

  const isEstoquePrecoCusto =
    tipoRelatorio === enumRelatorioEstoque.POSICAO_ESTOQUE_PRECO_CUSTO;

  const listRelatoriosEstoque = enumRelatorioEstoque.properties.filter(
    (relatorioItem) => validarTipoRelatorioEstoque(relatorioItem)
  );

  const dataForm = useCallback((data: FormData) => {
    const listCamposPersonalizados = data.camposPersonalizados
      .filter((campoItem) => campoItem.valor)
      .map((campoPersonalizadoItem) => ({
        ...campoPersonalizadoItem,
        valor: String(campoPersonalizadoItem.valor),
      }));

    const camposPersonalizados =
      listCamposPersonalizados.length > 0 ? listCamposPersonalizados : null;

    return {
      ...data,
      camposPersonalizados,
    };
  }, []);

  const handleSubmit = onSubmit(async (data) => {
    setIsLoading(true);
    const valuesForm = dataForm(data);

    const enderecoWebservice = isEstoqueSemPreco
      ? ConstanteEnderecoWebservice.RELATORIO_ESTOQUE_SEM_PRECO
      : isEstoquePrecoCusto
      ? ConstanteEnderecoWebservice.RELATORIO_ESTOQUE_PRECO_CUSTO
      : ConstanteEnderecoWebservice.RELATORIO_ESTOQUE_PRECO_VENDA;

    const response = await api.post<void, ResponseApi<string>>(
      enderecoWebservice,
      valuesForm
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((avisos) => toast.warning(avisos));
      }

      if (response.sucesso && response.dados) {
        ImprimirPDF(response.dados, 'relatorioProdutoEstoque');
      }
    }
    setIsLoading(false);
  });

  const handleExportarAquivoCSV = onSubmit(async (data) => {
    const valuesForm = dataForm(data);

    const enderecoWebservice = isEstoqueSemPreco
      ? ConstanteEnderecoWebservice.RELATORIO_CSV_ESTOQUE_SEM_PRECO
      : isEstoquePrecoCusto
      ? ConstanteEnderecoWebservice.RELATORIO_CSV_ESTOQUE_PRECO_CUSTO
      : ConstanteEnderecoWebservice.RELATORIO_CSV_ESTOQUE_PRECO_VENDA;

    const response = await api.post<void, ResponseApi<string>>(
      enderecoWebservice,
      valuesForm
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item) => toast.warning(item));
      }

      if (response.sucesso && response.dados) {
        jsFileDownload(
          Uint8Array.from(atob(response.dados), (c) => c.charCodeAt(0)),
          'Relatorio_estoque.csv'
        );
      }
    }
  });

  return (
    <FormProvider {...formMethods}>
      <CustomFormStandardComponent
        type="Personalizados"
        isLoading={isLoading}
        optionsRelatorio={listRelatoriosEstoque}
      />
      <Flex
        flexDirection={{ base: 'column', md: 'row', lg: 'row' }}
        justifyContent="end"
      >
        <Button
          variant="outline"
          w={{ base: 'full', sm: '250px' }}
          h="10"
          borderRadius="md"
          color="gray.900"
          mr={{ base: '0', md: '6', lg: '10' }}
          mt={{ base: '6', md: '6', lg: '10' }}
          onClick={() => handleExportarAquivoCSV()}
        >
          Exportar arquivo CSV
        </Button>
        <Button
          variant="unstyled"
          w={{ base: 'full', sm: '250px' }}
          h="10"
          borderRadius="md"
          color="gray.900"
          bg="secondary.400"
          mt={{ base: '6', md: '6', lg: '10' }}
          onClick={() => handleSubmit()}
        >
          Gerar Relatório
        </Button>
      </Flex>
    </FormProvider>
  );
};
