import React from 'react';

import ConstanteRotas from 'constants/rotas';

import { RelatorioEstoque } from 'pages/Relatorio/Estoque';
import PermissaoRelatorioEstoque from 'pages/Relatorio/Estoque/PermissaoRelatorioEstoque';

import LayoutGuard from './LayoutGuard';

export const RelatorioEstoqueRoutes = [
  <LayoutGuard
    key="relatorio-estoque"
    component={RelatorioEstoque}
    permissaoFuncionalidade={PermissaoRelatorioEstoque()}
    breadcrumb={[
      {
        title: 'Relatórios',
      },
      {
        title: 'Estoque',
      },
    ]}
    path={ConstanteRotas.RELATORIO_ESTOQUE}
    exact
    meta={{ auth: true }}
  />,
];
