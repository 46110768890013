import { toast } from 'react-toastify';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import getHeaderChaveTemporaria from 'helpers/api/getHeaderChaveTemporaria';

const excluirOperacaoItem = async (
  id: string,
  tela: number,
  chave: string
): Promise<boolean> => {
  const response = await api.delete<void, ResponseApi>(
    ConstanteEnderecoWebservice.PEDIDOORCAMENTOVENDA_ITEM_EXCLUIR,
    {
      params: { id, tela },
      ...getHeaderChaveTemporaria(chave),
    }
  );

  if (response) {
    if (response.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (response.sucesso) {
      return true;
    }
  }

  return false;
};

export default excluirOperacaoItem;
