import jsFileDownload from 'js-file-download';

import ConstanteRotas from 'constants/rotas';
import { useSistemaAtual } from 'helpers/data/useSistemaAtual';

export async function ImprimirPDF(data: string, nameFile: string) {
  const { name, nomeExibicao } = useSistemaAtual();

  const tamanhoInfoCadastro = data.length;
  if (tamanhoInfoCadastro > 2000000 || window.screen.availWidth < 1200) {
    jsFileDownload(
      Uint8Array.from(atob(data), (c) => c.charCodeAt(0)),
      `${nameFile}.pdf`
    );
  } else {
    const abrirPdf = await window.open(ConstanteRotas.ABRIR_IMPRESSAO_PDF);
    setTimeout(() => {
      abrirPdf?.document.write(
        `
    <head>
      <title> ${nomeExibicao} Docs </title>
      <link rel="icon" href="https://${name}appprod.blob.core.windows.net/icones/${nomeExibicao?.toLowerCase()}/favicon.ico"/>
      <link rel="apple-touch-icon" href="https://${name}appprod.blob.core.windows.net/icones/${nomeExibicao?.toLowerCase()}/logo192.png" />
    </head>
    <iframe width='100%' height='100%' src='data:application/pdf;base64,${data}'></iframe>`
      );
      setTimeout(() => {
        abrirPdf?.stop();
      }, 50);
    }, 1000);
  }
}
