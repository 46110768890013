import React, { useCallback, useState, useEffect, useRef } from 'react';
import {
  Control,
  UseFormSetError,
  FieldError,
  ControllerRenderProps,
} from 'react-hook-form';
import BeatLoader from 'react-spinners/BeatLoader';
import { useToken } from '@chakra-ui/react';

import { cpfMask, cnpjMask } from 'helpers/format/fieldsMasks';
import { isValidCnpj } from 'helpers/validation/IsValidCpfCnpj';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import consultarReceitaWS, { CnpjResponse } from 'services/receitaws';

import {
  InputControl,
  InputContainer,
  AppendContainer,
  SearchIcon,
  LoaderCss,
} from '../styles';

export interface InputCpfCnpjProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  name: string;
  label?: string;
  infoText?: string;
  error?: FieldError | null;
  control: Control<Record<string, any>>;
  setError: UseFormSetError<Record<string, any>>;
  defaultValue?: string;
  onlyCpf?: boolean;
  onlyCnpj?: boolean;
  maskTypeChange?: (isCpf: boolean) => void;
  getCnpjData?: (data: CnpjResponse) => void;
  onChangeCnpj?: (value: string) => void;
  showSearchIcon?: boolean;
  deveOuvirAlteracoesFormulario?: boolean;
}

interface InputProps
  extends Omit<InputCpfCnpjProps, 'error' | 'defaultValue' | 'control'> {
  field: ControllerRenderProps<Record<string, any>, string>;
}

const Input = ({
  id,
  name,
  label,
  required,
  infoText,
  setError,
  onlyCpf,
  onlyCnpj,
  maskTypeChange,
  getCnpjData,
  disabled,
  onChangeCnpj,
  showSearchIcon = true,
  field,
  deveOuvirAlteracoesFormulario = false,
  ...rest
}: InputProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isCpf, setIsCpf] = useState(!onlyCnpj);
  const [valorInput, setValorInput] = useState<string>();

  const getData = useCallback(
    (sucesso: boolean, dados: any) => {
      if (getCnpjData) {
        if (!sucesso) setError(`${name}` as const, { message: dados });
        else getCnpjData(dados);
      }

      setIsLoading(false);
    },
    [getCnpjData, name, setError]
  );

  const handleGetCnpj = useCallback(
    async (value: string) => {
      if (isValidCnpj(value)) {
        if (getCnpjData) {
          setIsLoading(true);
          if (onChangeCnpj) {
            onChangeCnpj(value);
          }
          consultarReceitaWS(value, getData);
        }
      } else {
        setError(`${name}` as const, {
          message: ConstanteMensagemValidacao.CNPJ_INVALIDO,
        });
      }
    },
    [getCnpjData, onChangeCnpj, getData, setError, name]
  );

  const latestPropsGetFormattedValue = useRef({ valorInput });
  useEffect(() => {
    latestPropsGetFormattedValue.current = { valorInput };
  });

  const getFormattedValue = useCallback(
    (value: string) => {
      if (value !== latestPropsGetFormattedValue.current.valorInput) {
        setValorInput(value);
        setError(`${name}` as const, {});

        if (onlyCpf) return cpfMask(value);
        if (onlyCnpj) return cnpjMask(value);

        const unformattedValue = value.replace(/\D/g, '');

        if (unformattedValue.length <= 11) {
          setIsCpf(true);

          return cpfMask(value);
        }

        setIsCpf(false);

        if (unformattedValue.length === 14) handleGetCnpj(value);

        return cnpjMask(value);
      }

      return value;
    },
    [handleGetCnpj, name, onlyCnpj, onlyCpf, setError]
  );

  useEffect(() => {
    if (!onlyCpf && !onlyCnpj && maskTypeChange) maskTypeChange(isCpf);
  }, [isCpf, valorInput]); // eslint-disable-line react-hooks/exhaustive-deps

  const latestProps = useRef({ getFormattedValue });
  useEffect(() => {
    latestProps.current = { getFormattedValue };
  });

  useEffect(() => {
    if (deveOuvirAlteracoesFormulario) {
      latestProps.current.getFormattedValue(field.value);
    }
  }, [field.value, deveOuvirAlteracoesFormulario]);

  return (
    <InputContainer>
      <InputControl
        inputMode="numeric"
        maxLength={onlyCpf ? 14 : 18}
        id={id}
        ref={field.ref}
        value={field.value}
        onChange={(e: React.FormEvent<HTMLInputElement>) => {
          const formattedValue = getFormattedValue(e.currentTarget.value);

          field.onChange(formattedValue);
        }}
        style={isCpf ? undefined : { paddingRight: '40px' }}
        disabled={disabled || isLoading}
        {...rest}
      />
      {!isCpf && showSearchIcon && (
        <AppendContainer>
          {isLoading ? (
            <BeatLoader
              size={5}
              color="var(--sti-ck-colors-loading)"
              css={LoaderCss.toString()}
            />
          ) : (
            <SearchIcon />
          )}
        </AppendContainer>
      )}
    </InputContainer>
  );
};

export default Input;
