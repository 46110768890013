import React, { HTMLAttributes } from 'react';
import { DotLoader } from 'react-spinners';

import { Container, ContainerSpinner } from './styles';

interface LoadingProps extends HTMLAttributes<HTMLDivElement> {
  size?: number;
  opacity?: string;
}

const Loading = ({
  size = 30,
  color = 'var(--sti-ck-colors-loading)',
  opacity,
  ...rest
}: LoadingProps) => {
  return (
    <Container
      style={{
        opacity: opacity || '1',
      }}
      {...rest}
    >
      <ContainerSpinner>
        <DotLoader size={size} color={color} />
      </ContainerSpinner>
    </Container>
  );
};

export default Loading;
