import { Box, BoxProps, Flex } from '@chakra-ui/react';
import { useRef, useState } from 'react';

interface ContainerScrollProps extends BoxProps {
  children: React.ReactNode;
  spacing?: string;
  backgroundScroll?: string;
  colorScroll?: string;
  colorBgGradient?: string;
  itemHeight?: number;
  containerHeight?: number;
  amountItems?: number;
  hasItemsVisible?: boolean;
}

export const ContainerScroll = ({
  children,
  spacing = '5px',
  backgroundScroll = 'gray.100',
  colorScroll = 'gray.200',
  colorBgGradient = 'gray.100',
  itemHeight = 0,
  containerHeight = 0,
  amountItems = 0,
  left = '-10px',
  hasItemsVisible = false,
  ...rest
}: ContainerScrollProps) => {
  const [scrollTop, setScrollTop] = useState(0);

  const containerRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (containerRef.current) {
      setScrollTop(containerRef.current.scrollTop);
    }
  };

  // const isVisibleFirstElemento = scrollTop > 0 && true;

  const isVisibleLastElemento =
    scrollTop <
      amountItems * itemHeight - (containerHeight / itemHeight) * itemHeight &&
    true;

  return (
    <Box h="full" w="full" position="relative">
      {hasItemsVisible && (
        <>
          {/* <Box
            position="absolute"
            h="20px"
            w="full"
            bgGradient={`linear(to-b, ${colorBgGradient} 0%, rgba(0, 0, 0, 0) 100%)`}
            pointerEvents="none"
            zIndex="overlay"
            left={left}
            borderRadius="5px"
            transition="opacity 0.4s"
            opacity={isVisibleFirstElemento ? 1 : 0}
          /> */}
          <Box
            position="absolute"
            bottom={0}
            h="30px"
            left={left}
            w="full"
            bgGradient={`linear(to-t, ${colorBgGradient} 0%, rgba(0, 0, 0, 0) 100%)`}
            borderBottomRadius="md"
            pointerEvents="none"
            zIndex="overlay"
            transition="opacity 0.4s"
            opacity={isVisibleLastElemento ? 1 : 0}
          />
        </>
      )}

      <Box
        sx={{
          '&::-webkit-scrollbar': {
            width: '6px',
            height: '5px',
            scrollbarWidth: 'thin',
            background: backgroundScroll,
          },
          '&::-webkit-scrollbar-track': {
            background: backgroundScroll,
            borderRadius: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: colorScroll,
            borderRadius: '5px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: colorScroll,
          },
        }}
        ref={containerRef}
        onScroll={handleScroll}
        overflowY="auto"
        overflowX="hidden"
        w={`calc(100% - ${spacing})`}
        {...rest}
      >
        <Flex>
          {children}
          <Box w="8px" />
        </Flex>
      </Box>
    </Box>
  );
};
