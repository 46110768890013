import {
  ModalProps,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  ModalFooter,
  ModalBody,
  Text,
  Box,
  Button,
} from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';
import { create, InstanceProps } from 'react-modal-promise';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { NumberInput } from 'components/update/Input/NumberInput';

type PromiseModal = {
  success: boolean;
  valorMeta: number;
};

interface ModalValorMetasVendedores
  extends Omit<ModalProps, 'children' | 'isOpen' | 'onClose'>,
    InstanceProps<PromiseModal> {
  valorDaMetaAnterior: number;
  isMobile: boolean | undefined;
}

type FormData = {
  valorMeta: number;
};

function Modal({
  isOpen,
  onReject,
  onResolve,
  valorDaMetaAnterior,
  isMobile,
  ...rest
}: ModalValorMetasVendedores) {
  const formMethods = useForm<FormData>({
    defaultValues: {
      valorMeta: valorDaMetaAnterior,
    },
  });

  const handleSubmit = formMethods.handleSubmit(({ valorMeta }) => {
    onResolve({
      success: true,
      valorMeta,
    });
  });

  return (
    <ModalPadraoChakra
      isOpen={isOpen}
      onClose={onReject}
      isCentered
      {...rest}
      size={isMobile ? 'full' : 'lg'}
    >
      <FormProvider {...formMethods}>
        <ModalContent borderRadius={isMobile ? 'none' : 'md'}>
          <ModalCloseButton />
          <ModalHeader padding="4 6">
            <Text color="primary.50" fontSize="md" fontWeight="bold">
              Valor da meta para todos os vendedores
            </Text>
          </ModalHeader>
          <ModalBody px="0" maxH="160px">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              paddingY="6"
              bg="primary.400"
              minH="120px"
            >
              <Box>
                <NumberInput
                  name="valorMeta"
                  id="valorMeta"
                  w="240px"
                  editarFundoLeftElemento
                  bgLeftElement="gray.50"
                  leftElement="R$"
                  scale={2}
                  colSpan={3}
                />
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter justifyContent="center" gap="6" py="10">
            <Button
              variant="outline"
              onClick={() => {
                onReject();
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="solid"
              colorScheme="aquamarine"
              w="120px"
              onClick={handleSubmit}
            >
              Aplicar
            </Button>
          </ModalFooter>
        </ModalContent>
      </FormProvider>
    </ModalPadraoChakra>
  );
}

export const ModalValorMetasVendedores = create<
  ModalValorMetasVendedores,
  PromiseModal
>(Modal);
