import { Flex, GridItem, Text } from '@chakra-ui/react';

import { Cor } from '../components/Cor';
import { LabelSelect } from '../components/LabelSelect';

export const Produtos = () => {
  return (
    <GridItem
      colSpan={[12, 12, 6, 4, 4]}
      minW={['100%', '100%', '50%', '373px']}
    >
      <Text fontWeight="bold">Produtos</Text>
      <Flex
        bg="#E8E8E8"
        minH={['inherit', 'inherit', '280px']}
        padding="24px"
        pt="16px"
        borderRadius="5px"
        border="1px solid #CCCCCC"
        flexDir="column"
        gap="16px"
        height={['fit-content', 'full', 'full', 'full', 'fit-content']}
      >
        <Cor name="itemSelectionColor" label=" Cor do item selecionado" />
        <LabelSelect
          name="itemSelectionTextColor"
          label="Cor do texto para preço selecionado"
          options={[
            {
              label: 'Branco',
              value: '#FFFFFF',
            },
            {
              label: 'Preto',
              value: '#000000',
            },
          ]}
        />
      </Flex>
    </GridItem>
  );
};
