import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FormProvider } from 'react-hook-form';
import { Box } from '@chakra-ui/react';

import api, { ResponseApi } from 'services/api';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import isPrenvent from 'helpers/layout/isPrenvent';
import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import RegraLimiteCreditoEnum from 'constants/enum/regraLimiteCredito';

import { ContainerListagem } from 'components/Layout/Listagem/containerListagem';

import { useForm, yupResolver, FormData } from '../validationForm';
import { UncontrolledForm } from '..';

interface CategoriaClienteInterface {
  id: string;
  descricao: string;
  regraLimiteCredito: number;
  diasAtrasoBloqueio: number;
  ativo: boolean;
  dataHoraCadastro: string;
  dataHoraUltimaAlteracao: string;
}

type TParams = { id: string };

const Alterar = () => {
  const history = useHistory();
  const isMountedRef = useIsMountedRef();
  isPrenvent();

  const [isLoading, setIsLoading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);

  const [dataHoraCadastro, setDataHoraCadastro] = useState('');
  const [dataHoraUltimaAlteracao, setDataHoraUltimaAlteracao] = useState('');

  const { id } = useParams<TParams>();

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    shouldUnregister: false,
  });
  const { handleSubmit, reset, getValues, formState } = formMethods;

  const handleGetCategoriaCliente = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<
      void,
      ResponseApi<CategoriaClienteInterface>
    >(ConstanteEnderecoWebservice.CATEGORIA_CLIENTE_OBTER, {
      params: { id },
    });

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso && isMountedRef.current) {
        const obterRegraLimiteCreditoSalva = RegraLimiteCreditoEnum.properties.filter(
          (regraLimite) =>
            regraLimite.value === response.dados.regraLimiteCredito
        );
        reset({
          ...response.dados,
          regraLimiteCredito: {
            value: obterRegraLimiteCreditoSalva[0].value,
            label: obterRegraLimiteCreditoSalva[0].name,
            color: obterRegraLimiteCreditoSalva[0].iconColor,
          },
        });

        setDataHoraCadastro(response.dados.dataHoraCadastro);
        setDataHoraUltimaAlteracao(response.dados.dataHoraUltimaAlteracao);
      } else {
        history.push(ConstanteRotas.CATEGORIA_CLIENTE);
      }
    }

    if (isMountedRef.current) setIsLoading(false);
  }, [history, id, isMountedRef, reset]);

  const onSubmit = handleSubmit(async () => {
    setIsLoading(true);

    const data = getValues();

    const dadosEnviarApi = {
      ativo: data.ativo,
      id,
      descricao: data.descricao,
      diasAtrasoBloqueio:
        data.regraLimiteCredito.value ===
        RegraLimiteCreditoEnum.BLOQUEIO_DIAS_ATRASO
          ? data.diasAtrasoBloqueio
          : 0,
      regraLimiteCredito: data.regraLimiteCredito.value,
    };

    const response = await api.put<void, ResponseApi>(
      ConstanteEnderecoWebservice.CATEGORIA_CLIENTE_ALTERAR,
      dadosEnviarApi
    );

    if (response) {
      if (response.sucesso) {
        toast.success('O cadastro foi alterado com sucesso.');

        setFormIsDirty(false);

        history.push(ConstanteRotas.CATEGORIA_CLIENTE);

        return;
      }

      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }
    }

    if (isMountedRef.current) setIsLoading(false);
  });

  useEffect(() => {
    setIsLoading(true);

    handleGetCategoriaCliente();
  }, [handleGetCategoriaCliente]);

  useEffect(() => {
    setFormIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  return (
    <ContainerListagem
      formIsDirty={formIsDirty}
      formMethods={formMethods}
      isLoading={isLoading}
      onSubmit={onSubmit}
      dataHoraUltimaAlteracao={dataHoraUltimaAlteracao}
      dataHoraCadastro={dataHoraCadastro}
    >
      <UncontrolledForm />
    </ContainerListagem>
  );
};

export default Alterar;
