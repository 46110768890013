import React, { ReactNode } from 'react';
import { Box, ChakraProps } from '@chakra-ui/react';

interface BodyProps extends ChakraProps {
  children: ReactNode;
  editarManualmente?: boolean;
}

export function Body({ children, editarManualmente, ...rest }: BodyProps) {
  return (
    <Box
      {...rest}
      py={editarManualmente ? undefined : { base: 4, sm: 8, md: 10 }}
      w="full"
      h="full"
    >
      {children}
    </Box>
  );
}
