import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Flex,
  ModalCloseButton,
  useMediaQuery,
  useDisclosure,
  ModalHeader,
  Text,
  ModalFooter,
  HStack,
  Button,
  Box,
  Thead,
  Tr,
  Th,
  Tbody,
  Table,
  VStack,
  Td,
  SimpleGrid,
  Skeleton,
  Divider,
  GridItem,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { DecimalMask } from 'helpers/format/fieldsMasks';
import { ImprimirPDF } from 'helpers/impressoes/imprimirPDF';

import { TextValor } from 'components/PDV/Text/TextValor';
import { NumberInput } from 'components/update/Input/NumberInput';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { EmailModal } from 'components/PDV/CompartilharDocumentosFiscais/EmailModal';
import { MobileModalMenu } from 'components/PDV/Layout/MobileModalMenu';
import { ContainerScroll } from 'components/Layout/ContainerScroll';
import { ModalCompartilhar } from 'components/Modal/ModalCompartilhar';
import ImpressaoCupomFechamentoCaixa, {
  ImpressaoCupomFechamentoCaixaRefElement,
} from 'components/Impressao/impressaoFechamentoCaixa';

import { FormData } from './validationForm';
import { TotaisFechamentoCaixa } from './Totais';

type ObterFechamentoCaixaResponse = {
  contaFinanceiraNome: string;
  contaFinanceiraIcone: string;
  dataHoraUsuarioAbertura: string;
  dataHoraUsuarioFechamento: string;
  saldoInicial: number;
  totalEntradas: number;
  totalSaidas: number;
  saldoFinal: number;
  qtdVendasConcluidas: number;
  totalValorVendasConcluidas: number;
  qtdVendasCanceladas: number;
  totalValorVendasCanceladas: number;
  totalValorDevolucoes: number;
  totalValorTrocas: number;
  totalValorSangria: number;
  totalValorSuprimento: number;
  caixaConferencias: CaixaConferencias[];
  caixaMovimentacoes: CaixaMovimentacoes[];
  saidas: Saidas[];
};

type CaixaMovimentacoes = {
  dataEmissao: Date;
  tipoMovimentacao: number;
  numeroOperacao: string;
  formaRecebimentoNome: string;
  parcela: string;
  valor: number;
  acaoFinanceira: number;
  cancelada: boolean;
};

type CaixaConferencias = {
  formaRecebimentoNome: string;
  formaRecebimentoId: string;
  valor: number;
  valorConferido: number;
  diferenca: number;
  consideraSaldoInicial: boolean;
};

type Saidas = {
  formaRecebimentoNome: string;
  valor: number;
};

type FechamentoCaixa = {
  contaFinanceiraNome: string;
  contaFinanceiraIcone: string;
  dataHoraUsuarioAbertura: string;
  dataHoraUsuarioFechamento: string;
  saldoInicial: number;
  totalEntradas: number;
  totalSaidas: number;
  saldoFinal: number;
  qtdVendasConcluidas: number;
  totalValorVendasConcluidas: number;
  qtdVendasCanceladas: number;
  totalValorVendasCanceladas: number;
  totalValorDevolucoes: number;
  totalValorTrocas: number;
  totalValorSangria: number;
  totalValorSuprimento: number;
  caixaConferencias: CaixaConferencias[];
  caixaMovimentacoes: CaixaMovimentacoes[];
  saidas: Saidas[];
};

type ModalFechamentoCaixaResponse = {
  sucesso: boolean;
};

type ModalFechamentoCaixaProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalFechamentoCaixaResponse> & {
    casasDecimaisValor: number;
    idCaixaMovimentacao: string;
    tela: number;
  };

function obterValoresDiferencaPagamento(
  valorConferido: number[],
  pagamento: CaixaConferencias,
  index: number
) {
  const pagamentoValorConferido = valorConferido[index] || 0;
  const diferenca = pagamentoValorConferido - pagamento.valor;
  const temDiferenca = diferenca !== 0 && pagamentoValorConferido !== 0;

  return { diferenca, temDiferenca };
}

export const ModalFechamentoCaixa = create<
  ModalFechamentoCaixaProps,
  ModalFechamentoCaixaResponse
>(
  ({
    onResolve,
    onReject,
    casasDecimaisValor,
    idCaixaMovimentacao,
    tela,
    ...rest
  }) => {
    const formMethods = useForm<FormData>();
    const { watch, handleSubmit: onSubmit, setValue } = formMethods;

    const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const [isLoading, setIsLoading] = useState(false);
    const [fechamentoCaixa, setFechamentoCaixa] = useState<FechamentoCaixa>(
      {} as FechamentoCaixa
    );

    const latestProps = useRef({ setValue });
    const bobinaContainerRef = useRef<HTMLDivElement>(null);
    const bobinaComponentToPrintRef = useRef<ImpressaoCupomFechamentoCaixaRefElement>(
      null
    );

    const valorConferido = watch('valorConferido') || [];

    const textoSubtitulo = `${fechamentoCaixa?.contaFinanceiraNome} - Aberto em ${fechamentoCaixa?.dataHoraUsuarioAbertura}`;

    const impressaoBobinaComponentToPrint = bobinaComponentToPrintRef.current;
    const impressaoBobinaContainer = bobinaContainerRef.current;

    const aparecerDivisorTrocas =
      fechamentoCaixa?.totalValorDevolucoes > 0 ||
      fechamentoCaixa?.totalValorVendasCanceladas > 0;

    const listaFechamentoCaixa = (
      fechamentoCaixa?.caixaConferencias || []
    ).filter((caixa) => caixa.valor !== 0);

    const imprimirRelatorioBobina = useReactToPrint({
      content: () => impressaoBobinaContainer,
    });

    const handleCancelar = () => {
      onClose();
      onReject();
    };

    const handleSubmit = onSubmit(async () => {
      await ModalConfirmacaoExcluir({
        title: 'Tem certeza?',
        text:
          'O caixa será encerrado. Você tem certeza que deseja fechar o caixa?',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim, fechar o caixa!',
        callback: async (ok: boolean) => {
          if (ok) {
            const pagamentos = (fechamentoCaixa?.caixaConferencias || []).map(
              ({ formaRecebimentoId, valor, formaRecebimentoNome }) => {
                const valueIndex = listaFechamentoCaixa.findIndex(
                  (fechamentoCaixaItem) =>
                    fechamentoCaixaItem.formaRecebimentoId ===
                    formaRecebimentoId
                );

                return {
                  formaPagamentoRecebimentoId: formaRecebimentoId,
                  formaPagamentoRecebimentoNome: formaRecebimentoNome,
                  valorInformado: valorConferido[valueIndex],
                  valorTotalFormaPagamento: valor,
                };
              }
            );

            const response = await api.put<
              void,
              ResponseApi<ObterFechamentoCaixaResponse>
            >(ConstanteEnderecoWebservice.FECHAMENTO_CAIXA_FECHAR, {
              idCaixaMovimentacao,
              tela,
              pagamentos,
            });

            if (response) {
              if (response.avisos) {
                response.avisos.forEach((item: string) => toast.warning(item));
              }

              if (response.sucesso) {
                onResolve({ sucesso: true });
              }
            }
          }
        },
      });
    });

    const getFechamentoCaixa = useCallback(async () => {
      setIsLoading(true);

      const response = await api.get<
        void,
        ResponseApi<ObterFechamentoCaixaResponse>
      >(ConstanteEnderecoWebservice.CONTROLE_CAIXA_DETALHES, {
        params: { idCaixaMovimentacao },
      });

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }

        if (response.sucesso && response.dados) {
          setFechamentoCaixa(response.dados);
        }
      }

      setIsLoading(false);
    }, [idCaixaMovimentacao]);

    useEffect(() => {
      latestProps.current = { setValue };
    });

    useEffect(() => {
      getFechamentoCaixa();
    }, [getFechamentoCaixa]);

    if (isSmallerThan900) {
      return (
        <MobileModalMenu
          isOpen={isOpen}
          onClose={onClose}
          title="Fechamento do caixa"
          px="0"
        >
          <Text px="8" fontSize="sm">
            {textoSubtitulo}
          </Text>

          <TotaisFechamentoCaixa
            casasDecimaisValor={casasDecimaisValor}
            saldoInicial={fechamentoCaixa?.saldoInicial || 0}
            totalEntradas={fechamentoCaixa?.totalEntradas || 0}
            totalSaidas={fechamentoCaixa?.totalSaidas || 0}
          />

          {(fechamentoCaixa?.totalValorVendasCanceladas || 0) > 0 && (
            <Flex
              px="8"
              pt="4"
              pb={
                (fechamentoCaixa?.totalValorDevolucoes || 0) > 0
                  ? undefined
                  : '2'
              }
              justifyContent="flex-end"
            >
              <Text color="red.400" fontSize="4xs">
                Total de vendas canceladas:{' '}
                {`R$ ${DecimalMask(
                  fechamentoCaixa?.totalValorVendasCanceladas,
                  casasDecimaisValor,
                  casasDecimaisValor
                )}`}
              </Text>
            </Flex>
          )}
          <Flex px="8" pt="1" pb="2" justifyContent="flex-end">
            {(fechamentoCaixa?.totalValorDevolucoes || 0) > 0 && (
              <Text fontSize="4xs">
                Total de devoluções:{' '}
                {`R$ ${DecimalMask(
                  fechamentoCaixa?.totalValorDevolucoes,
                  casasDecimaisValor,
                  casasDecimaisValor
                )}`}
              </Text>
            )}
          </Flex>
          <Flex px="8" pt="1" pb="2" justifyContent="flex-end">
            {(fechamentoCaixa?.totalValorTrocas || 0) > 0 && (
              <Text fontSize="4xs">
                Total de Trocas:{' '}
                {`R$ ${DecimalMask(
                  fechamentoCaixa?.totalValorTrocas,
                  casasDecimaisValor,
                  casasDecimaisValor
                )}`}
              </Text>
            )}
          </Flex>

          <Box bg="gray.100" p="6">
            <FormProvider {...formMethods}>
              <VStack spacing="3">
                {listaFechamentoCaixa.length > 0 ? (
                  listaFechamentoCaixa.map((pagamento, index) => {
                    const {
                      diferenca,
                      temDiferenca,
                    } = obterValoresDiferencaPagamento(
                      valorConferido,
                      pagamento,
                      index
                    );

                    return (
                      <Box
                        w="full"
                        maxW="440px"
                        bg={temDiferenca ? 'red.50' : 'white'}
                        borderRadius="md"
                        overflow="hidden"
                        boxShadow="md"
                      >
                        <Box
                          w="full"
                          bg={temDiferenca ? 'red.50' : 'white'}
                          px={4}
                          py="2"
                          fontSize="lg"
                        >
                          {pagamento.formaRecebimentoNome}
                          {pagamento.consideraSaldoInicial && (
                            <Text
                              fontSize="x-small"
                              color="gray.400"
                              fontWeight="semibold"
                            >
                              Saldo abertura + Total de movimentações
                            </Text>
                          )}
                        </Box>
                        <Box mx="3" w="full">
                          <Divider />
                        </Box>
                        <SimpleGrid
                          p={6}
                          gridTemplateColumns="auto 1fr"
                          columnGap="2"
                          rowGap="3"
                        >
                          <GridItem colSpan={2}>
                            <NumberInput
                              label="Valor em caixa"
                              name={`valorConferido.${index}`}
                              leftElement="R$"
                              maxW="280px"
                            />
                          </GridItem>

                          <Flex alignItems="center" justifyContent="flex-end">
                            <Text color="gray.500" fontSize="xs">
                              Recebido no sistema:
                            </Text>
                          </Flex>
                          <Flex alignItems="center">
                            <TextValor
                              casasDecimais={casasDecimaisValor}
                              valor={pagamento.valor}
                              color="gray.700"
                              fontSize="md"
                              fontWeight="regular"
                              symbolFontSize="xs"
                            />
                          </Flex>

                          <Flex alignItems="center" justifyContent="flex-end">
                            <Text color="gray.500" fontSize="xs">
                              Diferença:
                            </Text>
                          </Flex>
                          <Flex alignItems="center">
                            <TextValor
                              casasDecimais={casasDecimaisValor}
                              valor={diferenca}
                              color="gray.700"
                              fontSize="md"
                              fontWeight="regular"
                              symbolFontSize="xs"
                            />
                          </Flex>
                        </SimpleGrid>
                      </Box>
                    );
                  })
                ) : (
                  <Box
                    bg="white"
                    w="full"
                    p={6}
                    borderRadius="md"
                    fontSize="sm"
                  >
                    Não foi encontrado nenhum pagamento.
                  </Box>
                )}
              </VStack>
            </FormProvider>
          </Box>
          <HStack spacing={6} my="6" justifyContent="center">
            <Button
              borderRadius="full"
              color="gray.400"
              variant="outline"
              minW="96px"
              onClick={handleCancelar}
            >
              Cancelar
            </Button>
            <Button
              colorScheme="aquamarine"
              borderRadius="full"
              minW="110px"
              onClick={handleSubmit}
            >
              Fechar caixa
            </Button>
          </HStack>
        </MobileModalMenu>
      );
    }

    return (
      <ModalPadraoChakra
        isCentered={!isSmallerThan900}
        size={!isSmallerThan900 ? 'xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior={isSmallerThan900 ? 'outside' : 'inside'}
      >
        <ModalContent
          marginBottom={{ base: 0, md: '3.75rem' }}
          marginTop={{ base: 0, md: '3.75rem' }}
          h="unset"
          maxW={{ base: '100%', md: '800px' }}
          bg="gray.50"
          borderRadius={isSmallerThan900 ? 0 : 'md'}
        >
          {isLoading && <LoadingPadrao />}
          <ModalHeader
            mt={isSmallerThan900 ? 12 : undefined}
            mb={isSmallerThan900 ? 8 : undefined}
            px="0"
            mx={0}
            pb="0"
          >
            <VStack
              alignItems="flex-start"
              spacing="1"
              lineHeight="1"
              mx={{ base: 6, md: 8 }}
            >
              <Text color="primary.500" fontSize={{ base: 'xl', md: 'md' }}>
                Fechamento do caixa
              </Text>
              <Skeleton isLoaded={!!fechamentoCaixa}>
                <Text color="gray.400" fontSize={{ base: 'sm', md: 'xs' }}>
                  {textoSubtitulo}
                </Text>
              </Skeleton>
            </VStack>
            <TotaisFechamentoCaixa
              casasDecimaisValor={casasDecimaisValor}
              saldoInicial={fechamentoCaixa?.saldoInicial || 0}
              totalEntradas={fechamentoCaixa?.totalEntradas || 0}
              totalSaidas={fechamentoCaixa?.totalSaidas || 0}
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={{ base: 6, md: 8 }} pt={0} px={0} maxH="400px">
            <FormProvider {...formMethods}>
              <Box
                mt={isSmallerThan900 ? '0' : { base: 6, md: 8 }}
                mx={{ base: 6, md: 8 }}
              >
                <Box display="flex" justifyContent="flex-end">
                  {fechamentoCaixa?.totalValorVendasCanceladas > 0 && (
                    <Text color="red.400" fontSize="4xs">
                      Total de vendas canceladas:{' '}
                      {`R$ ${DecimalMask(
                        fechamentoCaixa?.totalValorVendasCanceladas,
                        casasDecimaisValor,
                        casasDecimaisValor
                      )}`}
                    </Text>
                  )}

                  {fechamentoCaixa?.totalValorVendasCanceladas > 0 &&
                    fechamentoCaixa?.totalValorDevolucoes > 0 && (
                      <Divider
                        mr="10px"
                        ml="10px"
                        orientation="vertical"
                        h="19px"
                        w="1px"
                        bg="gray.100"
                      />
                    )}

                  {(fechamentoCaixa?.totalValorDevolucoes || 0) > 0 && (
                    <Text fontSize="4xs">
                      Total de devoluções:{' '}
                      {`R$ ${DecimalMask(
                        fechamentoCaixa?.totalValorDevolucoes,
                        casasDecimaisValor,
                        casasDecimaisValor
                      )}`}
                    </Text>
                  )}

                  {aparecerDivisorTrocas && (
                    <Divider
                      mr="10px"
                      ml="10px"
                      orientation="vertical"
                      h="19px"
                      w="1px"
                      bg="gray.100"
                    />
                  )}

                  {(fechamentoCaixa?.totalValorTrocas || 0) > 0 && (
                    <Text fontSize="4xs">
                      Total de Trocas:{' '}
                      {`R$ ${DecimalMask(
                        fechamentoCaixa?.totalValorTrocas,
                        casasDecimaisValor,
                        casasDecimaisValor
                      )}`}
                    </Text>
                  )}
                </Box>

                <ContainerScroll
                  maxH="250px"
                  mt={{ base: 2, sm: 4 }}
                  hasItemsVisible
                  itemHeight={52}
                  pb="7px"
                  colorBgGradient="gray.50"
                  backgroundScroll="gray.50"
                  amountItems={listaFechamentoCaixa.length}
                  containerHeight={250}
                >
                  <Table size="sm" variant="simple-card">
                    <Thead>
                      <Tr
                        sx={{
                          '& > th': {
                            borderBottom: 'none',
                            whiteSpace: 'nowrap',
                          },
                        }}
                      >
                        <Th>Meio de pagamento</Th>
                        <Th isNumeric>Recebido no sistema</Th>
                        <Th isNumeric>Valor informado</Th>
                        <Th isNumeric width="130px">
                          Diferença
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody
                      sx={{
                        '& td': { bg: 'white', whiteSpace: 'nowrap', py: 2 },
                      }}
                    >
                      {listaFechamentoCaixa.length > 0 ? (
                        listaFechamentoCaixa.map((pagamento, index) => {
                          const {
                            diferenca,
                            temDiferenca,
                          } = obterValoresDiferencaPagamento(
                            valorConferido,
                            pagamento,
                            index
                          );

                          return (
                            <Fragment key={pagamento.formaRecebimentoId}>
                              <Tr
                                sx={{
                                  '& > td': {
                                    bg: temDiferenca ? 'red.50' : 'white',
                                  },
                                }}
                              >
                                <Td>
                                  <Text>{pagamento.formaRecebimentoNome}</Text>
                                  {pagamento.consideraSaldoInicial && (
                                    <Text
                                      fontSize="smaller"
                                      color="gray.400"
                                      fontWeight="semibold"
                                    >
                                      Saldo abertura + Total de movimentações
                                    </Text>
                                  )}
                                </Td>
                                <Td isNumeric>
                                  <Flex justifyContent="flex-end">
                                    <TextValor
                                      casasDecimais={casasDecimaisValor}
                                      valor={pagamento.valor}
                                      color="gray.700"
                                      fontSize="sm"
                                      fontWeight="regular"
                                      symbolFontSize="2xs"
                                    />
                                  </Flex>
                                </Td>
                                <Td isNumeric>
                                  <Flex justifyContent="flex-end">
                                    <NumberInput
                                      name={`valorConferido.${index}`}
                                      leftElement="R$"
                                      size="sm"
                                      maxW="200px"
                                    />
                                  </Flex>
                                </Td>
                                <Td isNumeric>
                                  <Flex justifyContent="flex-end">
                                    <TextValor
                                      casasDecimais={casasDecimaisValor}
                                      valor={diferenca}
                                      color="gray.700"
                                      fontSize="sm"
                                      fontWeight="regular"
                                      symbolFontSize="2xs"
                                    />
                                  </Flex>
                                </Td>
                              </Tr>

                              <Box
                                h={
                                  index + 1 ===
                                  (fechamentoCaixa?.caixaConferencias || [])
                                    .length
                                    ? 0
                                    : 1
                                }
                                zIndex="hide"
                              />
                            </Fragment>
                          );
                        })
                      ) : (
                        <Tr>
                          <Td colSpan={4}>
                            Não foi encontrado nenhum pagamento.
                          </Td>
                        </Tr>
                      )}
                    </Tbody>
                  </Table>
                </ContainerScroll>
                {(fechamentoCaixa?.saidas || []).length > 0 && (
                  <Table
                    size="sm"
                    variant="simple-card"
                    mt={{ base: 2, sm: 4 }}
                  >
                    <Thead>
                      <Tr
                        sx={{
                          '& > th': {
                            borderBottom: 'none',
                            whiteSpace: 'nowrap',
                          },
                        }}
                      >
                        <Th>Saídas</Th>
                        <Th />
                        <Th />
                        <Th />
                      </Tr>
                    </Thead>
                    <Tbody
                      sx={{
                        '& td': { bg: 'white', whiteSpace: 'nowrap', py: 2 },
                      }}
                    >
                      {(fechamentoCaixa?.saidas || []).map((saida, index) => (
                        <Fragment key={saida.formaRecebimentoNome}>
                          <Tr h="50px" mt="20px">
                            <Td>
                              <Text color="red.200">
                                {saida.formaRecebimentoNome}
                              </Text>
                            </Td>
                            <Td />
                            <Td />
                            <Td isNumeric>
                              <Flex justifyContent="flex-end">
                                <TextValor
                                  casasDecimais={casasDecimaisValor}
                                  valor={saida.valor}
                                  color="red.200"
                                  fontSize="sm"
                                  fontWeight="regular"
                                  symbolFontSize="2xs"
                                />
                              </Flex>
                            </Td>
                          </Tr>
                          <Box
                            h={
                              index + 1 ===
                              (fechamentoCaixa?.saidas || []).length
                                ? 0
                                : 1
                            }
                            zIndex="hide"
                          />
                        </Fragment>
                      ))}
                    </Tbody>
                  </Table>
                )}
              </Box>
            </FormProvider>
          </ModalBody>
          <ModalFooter justifyContent="center" mx={{ base: 6, md: 8 }} px="0">
            <HStack spacing={6}>
              <Button
                borderRadius="full"
                color="gray.400"
                variant="outline"
                minW="96px"
                onClick={handleCancelar}
              >
                Cancelar
              </Button>
              <Button
                colorScheme="aquamarine"
                borderRadius="full"
                minW="110px"
                onClick={() => handleSubmit()}
              >
                Fechar caixa
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
