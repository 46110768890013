import React, { createContext, useState, useContext } from 'react';

interface ExtratoContextProps {
  idRota: string;
  setIdRota: React.Dispatch<React.SetStateAction<string>>;
  valueDataExtrato: DataExtrato | undefined;
  setValueDataExtrato: React.Dispatch<
    React.SetStateAction<DataExtrato | undefined>
  >;
}

export const ExtratoContext = createContext<ExtratoContextProps>(
  {} as ExtratoContextProps
);

interface ExtratoProviderProps {
  children: React.ReactNode;
}

type DataExtrato = {
  dataInicio: Date;
  dataFim: Date;
};

export function ExtratoProvider({
  children,
}: ExtratoProviderProps): JSX.Element {
  const [idRota, setIdRota] = useState('');
  const [valueDataExtrato, setValueDataExtrato] = useState<DataExtrato>();

  return (
    <ExtratoContext.Provider
      value={{
        setIdRota,
        valueDataExtrato,
        setValueDataExtrato,
        idRota,
      }}
    >
      {children}
    </ExtratoContext.Provider>
  );
}

export function useExtratoContext(): ExtratoContextProps {
  const context = useContext(ExtratoContext);

  if (!context)
    throw new Error(
      'useExtratoContext must be used within a MetasComissoesEtapasProvider.'
    );

  return context;
}
