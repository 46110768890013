export type ModelosFiscais = 65 | 55;

const ModelosFiscaisEnum = {
  NFCe: 65,
  NFe: 55,
  SAT: 59,

  properties: {
    65: { name: 'NFC-e', value: 65 },
    55: { name: 'NF-e', value: 55 },
    59: { name: 'SAT', value: 59 },
  },
};

export default ModelosFiscaisEnum;
