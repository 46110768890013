import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormContext } from 'react-hook-form';

import api, { ResponseApi } from 'services/api';
import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import useReloadRegistration from 'helpers/layout/useReloadRegistration';
import { usePromocaoContext } from 'store/Promocao';

import { FormData } from '../validationForms';
import { useFormularioPromocao } from '../hooks';

export const usePromocaoCadastrar = () => {
  const history = useHistory();

  const {
    isLoading,
    setIsLoading,
    formIsDirty,
    setFormIsDirty,
  } = usePromocaoContext();

  const {
    handleSubmit,
    formState: { isDirty },
    getValues,
  } = useFormContext<FormData>();

  const historyReload = useReloadRegistration({ setFormIsDirty });

  const { configuracaoPromocao, cadastrarProdutos } = useFormularioPromocao();

  const cadastrarPromocao = useCallback(async () => {
    const data = await configuracaoPromocao();
    const valuesForm = getValues();
    setFormIsDirty(false);

    if (data === false) {
      return false;
    }

    const response = await api.post<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.CADASTRAR_PROMOCAO,
      {
        ...data,
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
        return false;
      }

      if (response.sucesso) {
        if ((valuesForm.produtos || []).length > 0) {
          const listProdutos = valuesForm.produtos.map((itemProduto) => ({
            precoPromocao: itemProduto.precoNovo,
            produtoCorTamanhoId: itemProduto.produtoCorTamanhoId || '',
            quantidade: itemProduto.quantidade || 0,
            descontoAcimaQuantidade: itemProduto.descontoAcimaQuantidade,
          }));

          const sucessProdutosCadastrados = await cadastrarProdutos(
            response.dados,
            listProdutos
          );

          return sucessProdutosCadastrados;
        }
        return true;
      }
    }
    setIsLoading(false);
    return false;
  }, [
    cadastrarProdutos,
    configuracaoPromocao,
    getValues,
    setFormIsDirty,
    setIsLoading,
  ]);

  const onSubmit = handleSubmit(async () => {
    setIsLoading(true);

    const response = await cadastrarPromocao();

    if (response) {
      toast.success('Cadastro salvo com sucesso');
      history.push(ConstanteRotas.PROMOCAO);
    }

    setIsLoading(false);
  });

  const onSubmitReset = handleSubmit(async () => {
    setIsLoading(true);

    const response = await cadastrarPromocao();

    if (response) {
      toast.success('Cadastro salvo com sucesso');
      historyReload(ConstanteRotas.PROMOCAO_CADASTRAR);
    }

    setIsLoading(false);
  });

  useEffect(() => {
    setFormIsDirty(isDirty);
  }, [isDirty, setFormIsDirty]);

  return {
    isLoading,
    onSubmit,
    onSubmitReset,
    formIsDirty,
  };
};
