import { IconFrenteCaixaDetalhesIntegracao } from 'icons';

import { Container } from '../../Components/Layout/Container';

import { useFrenteCaixaData } from './hooks';

export const DetalhesIntegracaoFrenteCaixa = () => {
  const {
    listaItensInfo,
    dataAtivacaoFrenteCaixa,
    excluirIntegracao,
    isLoading,
  } = useFrenteCaixaData();

  return (
    <Container
      listItemsInfo={listaItensInfo}
      isLoading={isLoading}
      widthIcon={250}
      heightIcon={150}
      handleCancel={excluirIntegracao}
      icon={IconFrenteCaixaDetalhesIntegracao}
      date={dataAtivacaoFrenteCaixa || new Date()}
    />
  );
};
