export const getValorParcelas = (
  valorTotal: number,
  numeroDeParcelas: number
): Array<number> => {
  const valorTotalCentavos = Math.round(valorTotal * 100);
  const cadaParcelaCentavos = Math.floor(valorTotalCentavos / numeroDeParcelas);

  const restoDivisao =
    (valorTotalCentavos - cadaParcelaCentavos * numeroDeParcelas) / 100;
  const cadaParcela = cadaParcelaCentavos / 100;

  const parcelas = [];

  for (let parcela = 1; parcela <= numeroDeParcelas; parcela += 1) {
    parcelas.push(cadaParcela);
  }

  if (restoDivisao > 0) {
    let indiceParcela = 0;

    let valorSomaParcelas = cadaParcela * numeroDeParcelas;

    while (valorSomaParcelas < valorTotal) {
      if (!parcelas[indiceParcela]) {
        indiceParcela = 0;
      }

      parcelas[indiceParcela] = +(parcelas[indiceParcela] + 0.01).toFixed(2);

      valorSomaParcelas = +(valorSomaParcelas + 0.01).toFixed(2);
      indiceParcela += 1;
    }
  }

  return parcelas;
};
