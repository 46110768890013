import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HStack, Heading, Icon } from '@chakra-ui/react';
import { FiChevronRight } from 'react-icons/fi';

import { useLayoutContext } from 'store/Layout';

const Breadcrumb = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { breadcrumb } = useLayoutContext();

  return (
    <HStack h="full" spacing={1} maxWidth="calc(100% - 3rem)" overflow="auto">
      {breadcrumb &&
        breadcrumb.map((crumb, index) => {
          return (
            <HStack key={crumb.title} spacing={1}>
              {index > 0 && (
                <Icon
                  as={FiChevronRight}
                  fontSize="lg"
                  color="primary.400"
                  strokeWidth="0.1rem"
                />
              )}
              <Heading
                as="h2"
                fontSize="lg"
                fontWeight="normal"
                color="primary.400"
                cursor={crumb.path ? 'pointer' : 'default'}
                onClick={async () => {
                  if (crumb.path) {
                    history.push(crumb.path);
                  }
                }}
                whiteSpace="nowrap"
              >
                {t(crumb.title)}
              </Heading>
            </HStack>
          );
        })}
    </HStack>
  );
};

export default memo(Breadcrumb);
