import React from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';
import { AiOutlineLeft } from 'react-icons/ai';

import { getMovideskChatUrl } from 'services/movidesk';
import { useSistemaAtual } from 'helpers/data/useSistemaAtual';

type ChatMoviedeskProps = {
  voltarMenu: () => void;
};

export const ChatMoviedesk = ({ voltarMenu }: ChatMoviedeskProps) => {
  const chatUrl = getMovideskChatUrl();
  const { nomeExibicao } = useSistemaAtual();

  return (
    <>
      <Flex
        gap="14px"
        color="violet.500"
        fontSize="md"
        align="center"
        mb="12px"
        onClick={() => {
          voltarMenu();
        }}
        cursor="pointer"
      >
        <AiOutlineLeft />
        <Text fontSize="md" fontWeight="semibold" color="violet.500">
          Chat online
        </Text>
      </Flex>
      <Box
        borderRadius="md"
        height="500px"
        position="relative"
        sx={{
          '& iframe': {
            w: 'full',
            h: '500px',
            borderRadius: '6px',
          },
        }}
      >
        <Box
          position="absolute"
          h="62px"
          borderTopRadius="6px"
          px="16px"
          w="350px"
          bgColor="primary.700"
        >
          <Flex w="full" h="62px" align="center">
            <Box
              bgImage="url('/favicon.svg')"
              bgPosition="center"
              bgRepeat="no-repeat"
              bgSize="contain"
              h="100%"
              w="30px"
            />
            <Text color="white" fontSize="lg" fontWeight="bold" ml="12px">
              {nomeExibicao}
            </Text>
          </Flex>
        </Box>

        <iframe src={chatUrl} title="Atendimento por chat Movidesk" />
      </Box>
    </>
  );
};
