import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import {
  Card as BootstrapCard,
  Col as BootstrapCol,
  Button as BootstrapButton,
  Form as BootstrapForm,
} from 'react-bootstrap';
import { CircleIcon, UsuariosPerfilUsuarioIcon } from 'icons';

interface CardProps {
  isNotForm?: boolean;
}

export const Card = styled(BootstrapCard)<CardProps>`
  padding: 20px;

  @media (min-width: 900px) {
    padding: 25px;
  }
  @media (min-width: 1200px) {
    padding: 30px;
  }
  @media (min-width: 1800px) {
    padding: 35px;
  }

  border-radius: 5px;

  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
`;

export const BoxShadow = styled(BootstrapCard)<CardProps>`
  padding: 0px;
  border-radius: 5px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
`;

export const CardFieldContainer = styled(Card)`
  background-color: var(--sti-ck-colors-gray-50);
  box-shadow: none;
  border: 1px solid var(--sti-ck-colors-gray-100);

  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
`;

export const StatusIcon = styled(CircleIcon)`
  font-size: 0.4375rem;
`;

interface LinkVisualizarProps {
  bloqueado: 0 | 1;
}

export const LinkVisualizar = styled(Link)<LinkVisualizarProps>`
  width: auto;
  &:hover {
    text-decoration: underline !important;
  }

  ${({ bloqueado }) =>
    bloqueado &&
    css`
      text-decoration: none;
      pointer-events: none;
      cursor: default;
      color: var(--black);
    `}
`;

export const ModalTitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;

  flex-direction: column;

  width: 100%;
  height: 100%;
`;

export const ModalTitle = styled.div`
  height: 23px;

  color: var(--sti-ck-colors-primary-50);

  font-size: 1.0625rem;
  font-weight: 600;
`;

export const ModalSubtitle = styled.div`
  color: var(--sti-ck-colors-gray-700);

  font-size: 0.875rem;
  font-weight: 600;
`;

export const FormActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 700px) {
    flex-direction: row;
  }

  margin-top: 10px;
  margin-bottom: 55px;
`;

export const AlignRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const FiltrosCol = styled(BootstrapCol)`
  button.btn-primary,
  button.btn-secondary,
  button.btn-outline-secondary {
    width: 100%;
    max-width: 100%;

    @media (min-width: 700px) {
      width: 225px;
    }
  }

  div[class$='-container'],
  div[class*='-container '] {
    max-width: 100%;

    @media (min-width: 700px) {
      max-width: 180px;
    }
  }

  button#buscaAvancada-button {
    width: 100%;
    max-width: 100%;

    @media (min-width: 700px) {
      max-width: 230px;
    }

    div[class$='-container'],
    div[class*='-container '] {
      max-width: 100%;
    }
  }

  input,
  button {
    height: 40px;
    font-size: 0.9375rem;
  }

  .form-group {
    padding: 0px !important;
  }
`;

export const ContentContainer = styled.div``;

export const RegraLimiteCreditoIcon = styled(UsuariosPerfilUsuarioIcon)`
  font-size: 1.25rem;
  min-width: 20px;

  margin-right: 15px;
`;

export const ButtonLinkPadrao = styled(BootstrapButton).attrs(() => ({
  variant: 'link',
}))`
  font-size: 0.75rem;
  color: var(--sti-ck-colors-blue-500) !important;
  text-decoration: none !important;

  padding: 0px;
`;

export const Label = styled(BootstrapForm.Label)`
  color: var(--black) !important;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.5;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;

  margin-bottom: 0px;
`;
