import React, { useMemo, memo } from 'react';

import { usePlanoContasVisaoDREContext } from 'store/PlanoContas/PlanoContasVisaoDRE';

import { ItemVisaoDre } from '../Item';

interface ItemsContainerProps {
  paiId?: string;
  nivel?: number;
  paiIsAtivo?: boolean;
}

export const ItemsVisaoDreContainer = memo(
  ({ paiId, nivel = 1, paiIsAtivo = true }: ItemsContainerProps) => {
    const { handleGetPlanosContasPeloPaiId } = usePlanoContasVisaoDREContext();

    const items = useMemo(() => {
      return handleGetPlanosContasPeloPaiId(paiId);
    }, [handleGetPlanosContasPeloPaiId, paiId]);

    return (
      <>
        {items.map((item) => (
          <ItemVisaoDre
            key={item.id}
            paiIsAtivo={paiIsAtivo}
            nivel={nivel}
            item={item}
          >
            <ItemsVisaoDreContainer
              paiIsAtivo={item.ativo}
              paiId={item.id}
              nivel={nivel + 1}
            />
          </ItemVisaoDre>
        ))}
      </>
    );
  }
);
