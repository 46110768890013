import ConstanteFuncionalidades from 'constants/permissoes';
import auth from 'modules/auth';

export const permissaoListarOperacao = () => {
  const listaFuncionalidades = [
    ConstanteFuncionalidades.VENDASPEDIDOS_LISTAR,
    ConstanteFuncionalidades.CONSIGNACAO_LISTAR,
    ConstanteFuncionalidades.ORCAMENTO_LISTAR,
    ConstanteFuncionalidades.LISTAR_TROCA_DEVOLUCAO,
  ];

  let permissaoAtual = '';

  listaFuncionalidades.some((valorFuncionalidade) => {
    const possuiPermissao = auth.possuiPermissao(valorFuncionalidade);

    if (possuiPermissao.permitido) {
      permissaoAtual += valorFuncionalidade;
      return true;
    }
    return '';
  });

  return permissaoAtual;
};
