import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form } from 'react-bootstrap';
import { FieldErrors } from 'react-hook-form';

import SelectPadrao from 'components/Select/SelectPadrao';
import { BootstrapNumberInput } from 'components/update/Input/BootstrapNumberInput';
import ModalidadesBaseCalculoIcmsStEnum from 'constants/enum/fiscal/modalidadeBaseCalculoIcmsSt';
import { Label } from 'components/Layout/CampoContainer/CampoContainerPadrao/styles';

interface UncontrolledFormInterface {
  errors: FieldErrors;
  control: any;
  watch: any;
  setValue: any;
}

const ICMSST: React.FC<UncontrolledFormInterface> = ({
  errors,
  control,
  watch,
  setValue,
}) => {
  const { t } = useTranslation();

  const cstCsosnWatch = watch('cstCsosn');

  const configVisibilidade = {
    modalidadeIcmsSt: [10, 30, 70, 90, 201, 202, 203, 900],
    icmsStMva: [10, 30, 70, 90, 201, 202, 203, 900],
    icmsStReducaoBaseCalculo: [10, 30, 70, 90, 201, 202, 203, 900],
    icmsStBaseCalculo: [10, 30, 70, 90, 201, 202, 203, 900],
    icmsStAliquota: [10, 30, 70, 90, 201, 202, 203, 900],
    icmsStValor: [10, 30, 70, 90, 201, 202, 203, 900],

    fcpStBaseCalculo: [10, 30, 70, 90, 201, 202, 203, 900],
    fcpStAliquota: [10, 30, 70, 90, 201, 202, 203, 900],
    fcpStValor: [10, 30, 70, 90, 201, 202, 203, 900],

    icmsStRetidoBaseCalculo: [60, 500],
    aliquotaConsumidorFinal: [60, 500],
    icmsSubstituto: [60, 500],
    icmsStRetidoValor: [60, 500],

    fcpStRetidoBaseCalculo: [60, 500],
    fcpStRetidoAliquota: [60, 500],
    fcpStRetidoValor: [60, 500],
  } as any;

  const exibirCampo = (name: string): boolean => {
    const exibir = configVisibilidade[name]?.includes(cstCsosnWatch);

    if (!exibir) {
      setValue(name, 0);
    }

    return cstCsosnWatch !== undefined && exibir;
  };

  return (
    <>
      {exibirCampo('modalidadeIcmsSt') && (
        <Form.Row>
          <Col md={4} xl={4}>
            <SelectPadrao
              id="modalidadeIcmsSt"
              name="modalidadeIcmsSt"
              label={t('Modalidade cálculo ICMS ST')}
              noSelectedText={t('Clique aqui para selecionar.')}
              defaultValue={4}
              control={control}
              error={errors.modalidadeIcmsSt}
              options={Object.entries(
                ModalidadesBaseCalculoIcmsStEnum.properties
              ).map((value: any) => {
                return (
                  {
                    label: t(value[1].name),
                    value: value[1].value,
                  } || {}
                );
              })}
              readonly={false}
              required
              // onSelect={() => {
              //   onSelectModeloFiscal();
              // }}
            />
          </Col>
        </Form.Row>
      )}
      {exibirCampo('icmsStReducaoBaseCalculo') &&
        exibirCampo('icmsStMva') &&
        exibirCampo('icmsStBaseCalculo') &&
        exibirCampo('icmsStAliquota') &&
        exibirCampo('icmsStValor') && (
          <Form.Row>
            <Col md={3} xl={2}>
              <BootstrapNumberInput
                id="icmsStReducaoBaseCalculo"
                name="icmsStReducaoBaseCalculo"
                label={t('Redução BC ST')}
                precision={12}
                scale={2}
                defaultValue={0}
                leftElement="%"
                control={control}
                error={errors.icmsStReducaoBaseCalculo}
              />
            </Col>
            <Col md={3} xl={2}>
              <BootstrapNumberInput
                id="icmsStMva"
                name="icmsStMva"
                label={t('MVA')}
                precision={12}
                scale={2}
                defaultValue={0}
                leftElement="%"
                control={control}
                error={errors.icmsStMva}
              />
            </Col>
            <Col md={3} xl={2}>
              <BootstrapNumberInput
                id="icmsStBaseCalculo"
                name="icmsStBaseCalculo"
                label={t('Base de cálculo ST')}
                precision={12}
                scale={2}
                defaultValue={0}
                leftElement="R$"
                control={control}
                error={errors.icmsStBaseCalculo}
              />
            </Col>
            <Col md={3} xl={2}>
              <BootstrapNumberInput
                id="icmsStAliquota"
                name="icmsStAliquota"
                label={t('Alíquota ICMS ST')}
                precision={12}
                scale={2}
                defaultValue={0}
                leftElement="%"
                control={control}
                error={errors.icmsStAliquota}
              />
            </Col>
            <Col md={3} xl={2}>
              <BootstrapNumberInput
                id="icmsStValor"
                name="icmsStValor"
                label={t('Valor ICMS ST')}
                precision={12}
                scale={2}
                defaultValue={0}
                leftElement="R$"
                control={control}
                error={errors.icmsStValor}
              />
            </Col>
          </Form.Row>
        )}
      {exibirCampo('fcpStBaseCalculo') &&
        exibirCampo('fcpStAliquota') &&
        exibirCampo('fcpStValor') && (
          <Form.Row>
            <Col md={3} xl={2}>
              <BootstrapNumberInput
                id="fcpStBaseCalculo"
                name="fcpStBaseCalculo"
                label={t('Base de cálculo FCP ST')}
                precision={12}
                scale={2}
                defaultValue={0}
                leftElement="R$"
                control={control}
                error={errors.fcpStBaseCalculo}
              />
            </Col>
            <Col md={3} xl={2}>
              <BootstrapNumberInput
                id="fcpStAliquota"
                name="fcpStAliquota"
                label={t('Alíquota FCP ST')}
                precision={12}
                scale={2}
                defaultValue={0}
                leftElement="%"
                control={control}
                error={errors.fcpStAliquota}
              />
            </Col>
            <Col md={3} xl={2}>
              <BootstrapNumberInput
                id="fcpStValor"
                name="fcpStValor"
                label={t('Valor FCP ST')}
                precision={12}
                scale={2}
                defaultValue={0}
                leftElement="R$"
                control={control}
                error={errors.fcpStValor}
              />
            </Col>
          </Form.Row>
        )}

      {exibirCampo('icmsStRetidoBaseCalculo') &&
        exibirCampo('aliquotaConsumidorFinal') &&
        exibirCampo('icmsSubstituto') &&
        exibirCampo('icmsStRetidoValor') && (
          <>
            <Form.Row>
              <Col xl={12}>
                <div className="d-flex mt-1">
                  <Label>ICMS ST Retido anteriormente</Label>
                </div>
                <hr className="my-auto flex-grow-1" />
              </Col>
            </Form.Row>
            <Form.Row>
              <Col md={3} xl={2}>
                <BootstrapNumberInput
                  id="icmsStRetidoBaseCalculo"
                  name="icmsStRetidoBaseCalculo"
                  label={t('BC ST retido')}
                  precision={12}
                  scale={2}
                  defaultValue={0}
                  leftElement="R$"
                  control={control}
                  error={errors.icmsStRetidoBaseCalculo}
                />
              </Col>
              <Col md={3} xl={2}>
                <BootstrapNumberInput
                  id="aliquotaConsumidorFinal"
                  name="aliquotaConsumidorFinal"
                  label={t('Alíquota consumidor final')}
                  precision={12}
                  scale={2}
                  defaultValue={0}
                  leftElement="%"
                  control={control}
                  error={errors.aliquotaConsumidorFinal}
                />
              </Col>
              <Col md={3} xl={2}>
                <BootstrapNumberInput
                  id="icmsSubstituto"
                  name="icmsSubstituto"
                  label={t('Valor do ICMS substituto')}
                  precision={12}
                  scale={2}
                  defaultValue={0}
                  leftElement="R$"
                  control={control}
                  error={errors.icmsSubstituto}
                />
              </Col>
              <Col md={3} xl={2}>
                <BootstrapNumberInput
                  id="icmsStRetidoValor"
                  name="icmsStRetidoValor"
                  label={t('Valor ICMS ST retido')}
                  precision={12}
                  scale={2}
                  defaultValue={0}
                  leftElement="R$"
                  control={control}
                  error={errors.icmsStRetidoValor}
                />
              </Col>
            </Form.Row>
          </>
        )}

      {exibirCampo('fcpStRetidoBaseCalculo') &&
        exibirCampo('fcpStRetidoAliquota') &&
        exibirCampo('fcpStRetidoValor') && (
          <Form.Row>
            <Col md={3} xl={2}>
              <BootstrapNumberInput
                id="fcpStRetidoBaseCalculo"
                name="fcpStRetidoBaseCalculo"
                label={t('BC FCP ST retido')}
                precision={12}
                scale={2}
                defaultValue={0}
                leftElement="R$"
                control={control}
                error={errors.fcpStRetidoBaseCalculo}
              />
            </Col>
            <Col md={3} xl={2}>
              <BootstrapNumberInput
                id="fcpStRetidoAliquota"
                name="fcpStRetidoAliquota"
                label={t('Alíquota FCP ST retido')}
                precision={12}
                scale={2}
                defaultValue={0}
                leftElement="%"
                control={control}
                error={errors.fcpStRetidoAliquota}
              />
            </Col>
            <Col md={3} xl={2}>
              <BootstrapNumberInput
                id="fcpStRetidoValor"
                name="fcpStRetidoValor"
                label={t('Valor FCP ST retido')}
                precision={12}
                scale={2}
                defaultValue={0}
                leftElement="R$"
                control={control}
                error={errors.fcpStRetidoValor}
              />
            </Col>
          </Form.Row>
        )}
    </>
  );
};

export default ICMSST;
