import { Flex, Text, Icon, Box } from '@chakra-ui/react';
import { BsChevronLeft } from 'react-icons/bs';

import { IconType } from 'icons';
import { ClosedMenuLogo } from 'icons/Identidade';

type HeaderProps = {
  icon?: IconType;
  iconSize?: number;
  title: string;
  handleVoltar?: () => void;
  colorIcon?: string;
};

export const Header = ({
  icon,
  title,
  iconSize = 40,
  handleVoltar,
  colorIcon = 'primary.50',
}: HeaderProps) => {
  return (
    <Box>
      <Flex
        justifyContent="center"
        w="full"
        alignItems="center"
        cursor="pointer"
      >
        {icon ? (
          <Icon
            boxSize={`${iconSize}px`}
            size={iconSize}
            color={colorIcon}
            as={icon}
          />
        ) : (
          <ClosedMenuLogo color="primary.50" size={36} />
        )}
      </Flex>

      <Flex
        borderBottomStyle="solid"
        mb={['13px', '13px', '24px']}
        pb="8px"
        borderBottomWidth="1px"
        borderBottomColor="gray.200"
        alignItems="baseline"
        justifyContent="center"
      >
        {handleVoltar && (
          <Box
            ml="10px"
            h="full"
            boxSize="20px"
            zIndex="1"
            onClick={handleVoltar}
            cursor="pointer"
          >
            <Icon
              color="gray.700"
              display={['none', 'none', 'flex']}
              boxSize="20px"
              onClick={handleVoltar}
              cursor="pointer"
              as={BsChevronLeft}
            />
          </Box>
        )}

        <Box w="full">
          <Text
            textAlign="center"
            fontSize={['20px', '20px', '28px']}
            fontWeight="bold"
            marginTop="6px"
            color="black"
            marginLeft={handleVoltar ? '-30px' : 'unset'}
          >
            {title}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};
