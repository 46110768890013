import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useCallback, useRef, useState } from 'react';

import api, { ResponseApi } from 'services/api';
import { shallowEqual } from 'helpers/validation/shallowEqual';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import ConstanteFuncionalidades from 'constants/permissoes';
import auth from 'modules/auth';

import { PagedTableForwardRefData } from 'components/update/Table/PagedTable';
import { PaginationData } from 'components/update/Pagination';
import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';

import { defaultValues, FormData } from './validationForm';

type CredenciadoraCartao = {
  ativo: boolean;
  cnpj: string;
  codigoSat: string;
  dataHoraCadastro: string;
  dataHoraUltimaAlteracao: string;
  id: string;
  nome: string;
};

const useCredenciadoraCartaoListar = () => {
  const history = useHistory();
  const pagedTableRef = useRef<PagedTableForwardRefData>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [filtros, setFiltros] = useState<FormData>(defaultValues);
  const [credenciadoraCartaoLista, setCredenciadoraCartaoLista] = useState<
    CredenciadoraCartao[]
  >([]);

  const cadastrarCredenciadoraCartao = () => {
    history.push(ConstanteRotas.CREDENCIADORA_CARTAO_CADASTRAR);
  };

  const excluirCredenciadoraCartao = (id: string) => {
    ModalConfirmacaoExcluir({
      callback: async (confirm: boolean) => {
        if (!confirm) {
          return;
        }

        setIsLoading(true);

        const response = await api.delete<void, ResponseApi>(
          ConstanteEnderecoWebservice.CREDENCIADORA_CARTAO_EXCLUIR,
          {
            params: { id },
          }
        );

        if (response) {
          if (response?.avisos) {
            response.avisos.forEach((aviso) => toast.warning(aviso));
          }
          if (response?.sucesso) {
            toast.success('Credenciadora de cartão removida com sucesso');
            await pagedTableRef?.current?.reload();
          }
        }

        setIsLoading(false);
      },
    });
  };

  const obterCredenciadoraCartaoListaPaginada = useCallback(
    async (paginationData: PaginationData) => {
      setIsLoading(true);

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<CredenciadoraCartao>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.CREDENCIADORA_CARTAO_LISTAR_PAGINADO,
          paginationData
        ),
        {
          params: { ...filtros },
        }
      );

      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response?.sucesso && response?.dados) {
          setTotalRegistros(response.dados.total);
          setCredenciadoraCartaoLista(response.dados.registros);
        }
      }

      setIsLoading(false);
    },
    [filtros]
  );

  const redirecionarCredenciadoraCartaoAlterar = (id: string) => {
    history.push(
      SubstituirParametroRota(
        ConstanteRotas.CREDENCIADORA_CARTAO_ALTERAR,
        'id',
        id
      )
    );
  };

  const nomeLinkHref = useCallback((id: string) => {
    const permiteAlterar = auth.possuiPermissao(
      ConstanteFuncionalidades.CREDENCIADORA_CARTAO_ALTERAR
    ).permitido;

    const permiteVisualizar = auth.possuiPermissao(
      ConstanteFuncionalidades.CREDENCIADORA_CARTAO_VISUALIZAR
    ).permitido;

    if (permiteAlterar) {
      return SubstituirParametroRota(
        ConstanteRotas.CREDENCIADORA_CARTAO_ALTERAR,
        'id',
        id
      );
    }

    if (permiteVisualizar) {
      return SubstituirParametroRota(
        ConstanteRotas.CREDENCIADORA_CARTAO_VISUALIZAR,
        'id',
        id
      );
    }

    return '';
  }, []);

  const atualizarFiltros = (filtrosForm: FormData) => {
    if (!shallowEqual(filtrosForm, filtros)) {
      setFiltros(filtrosForm);
    }
  };

  return {
    isLoading,
    pagedTableRef,
    totalRegistros,
    atualizarFiltros,
    credenciadoraCartaoLista,
    cadastrarCredenciadoraCartao,
    obterCredenciadoraCartaoListaPaginada,
    redirecionarCredenciadoraCartaoAlterar,
    excluirCredenciadoraCartao,
    nomeLinkHref,
  };
};

export { useCredenciadoraCartaoListar };
