import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  ModalFooter,
  VStack,
  ModalHeader,
  useMediaQuery,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';

type ModalAjudaProps = Omit<ModalProps, 'children' | 'isOpen' | 'onClose'> &
  InstanceProps<ModalProps>;

export const ModalAjuda = create<ModalAjudaProps, ModalProps>(
  ({ onResolve, onReject, ...rest }) => {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? '3xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
      >
        <ModalContent
          bg="gray.50"
          borderRadius={isLargerThan900 ? 'md' : '0'}
          marginBottom={isLargerThan900 ? '3.75rem' : '0'}
          marginTop={isLargerThan900 ? '3.75rem' : '0'}
          w={isLargerThan900 ? '640px' : 'full'}
          h={isLargerThan900 ? '388px' : 'full'}
        >
          <ModalHeader pt="16px" pb="18px" pl="24px">
            <Text
              borderBottomWidth="1px"
              borderColor="gray.200"
              color="primary.50"
              fontSize="20px"
              pb="10px"
            >
              Requisitos para adicionar imagens
            </Text>
          </ModalHeader>

          <ModalBody pl="24px" pr="24px" mb="20px" pt="0" pb="0">
            <VStack
              color="black"
              fontSize="14px"
              w="full"
              alignItems="left"
              spacing="24px"
            >
              <Text mb="4px" textAlign="left" letterSpacing="0px">
                → Utilize imagens com extensão JPG, JPEG ou PNG
              </Text>
              <Text mb="4px" textAlign="left" letterSpacing="0px">
                → Tamanho máximo do arquivo de 350 kb
              </Text>
              <Text mb="4px" textAlign="left" letterSpacing="0px">
                → Resolução máxima de 1200 x 1200 px.
              </Text>
              <Text mb="4px" textAlign="left" letterSpacing="0px">
                → Para definir a ordem de exibição das imagens nos anúncios
                online, clique na imagem e arraste para a posição desejada.
              </Text>
            </VStack>
          </ModalBody>
          <ModalFooter flexDirection="column" mb="10px">
            <Flex
              w="full"
              h="full"
              justifyContent="center"
              alignItems="baseline"
            >
              <Button
                color="white"
                variant="solid"
                colorScheme="purple.500"
                lineHeight="0"
                borderRadius="20px"
                fontSize="sm"
                h="40px"
                width="120px"
                onClick={() => onClose()}
              >
                OK
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
