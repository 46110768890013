import React, {
  Dispatch,
  SetStateAction,
  useRef,
  useState,
  useCallback,
  memo,
} from 'react';
import Webcam from 'react-webcam';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Flex, Icon } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';

import {
  isDataURI,
  dataURItoBase64,
  formatBytes,
  byteToSize,
  calcDataUriBytesSize,
  testIfIsUrl,
} from 'helpers/validation/imagesAttachment';
import { useFullScreenContext } from 'store/FullScreen';

import { TrocarCameraIcon } from 'icons';
import ButtonPadrao from 'components/Button/ButtonPadrao';
import {
  Modal,
  ModalBody,
  CaptureButtonContainer,
  CaptureButton,
} from './styles';
import { ImagePreview } from '../styles';

interface WebcamModalPadraoProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  onClose?: () => void;
  onChange: (v: any) => void;
}

const WebcamModalPadrao = ({
  show,
  setShow,
  onChange,
  onClose,
}: WebcamModalPadraoProps) => {
  const [cameraFrontal, setCameraFrontal] = useState(!isMobile);

  const videoConstraints = {
    width: 500,
    height: 500,
    facingMode: cameraFrontal ? 'user' : { exact: 'environment' },
  };
  const { t } = useTranslation();

  const webcamRef = useRef(null);

  const [webcamImagePreview, setWebcamImagePreview] = useState('');

  const handleClose = useCallback(() => {
    setShow(false);

    if (onClose) {
      onClose();
    }

    setTimeout(() => {
      setWebcamImagePreview('');
    }, 200);
  }, [setShow, onClose]);

  const handleCapture = useCallback(() => {
    const dataUrlImage = ((webcamRef.current as unknown) as Webcam).getScreenshot(
      { height: 500, width: 500 }
    );

    if (dataUrlImage && isDataURI(dataUrlImage)) {
      const bytesSize = calcDataUriBytesSize(dataUrlImage);

      if (byteToSize(bytesSize, 'MB') > 1) {
        toast.warning(
          t(
            'O arquivo selecionado é muito grande {{tamanhoArquivo}}, este campo aceita somente imagens de até 1MB',
            { tamanhoArquivo: formatBytes(bytesSize) }
          )
        );

        return;
      }

      setWebcamImagePreview(dataURItoBase64(dataUrlImage));
    } else {
      handleClose();
    }
  }, [handleClose, t]);

  const formattedValue = useCallback((value: string) => {
    if (!testIfIsUrl(value)) return `data:image/jpeg;base64,${value}`;

    return value;
  }, []);

  const { handleFullScreen } = useFullScreenContext();

  return (
    <Modal
      show={show}
      onHide={() => {
        handleClose();
      }}
      centered
      container={handleFullScreen.node}
    >
      <Modal.Header closeButton>
        <Flex
          w="full"
          alignItems="center"
          justifyContent="center"
          ml={['0px', '6px']}
        >
          <Icon
            as={TrocarCameraIcon}
            boxSize="24px"
            onClick={() => setCameraFrontal((valorAnterior) => !valorAnterior)}
            color="white"
          />
        </Flex>
      </Modal.Header>
      <ModalBody>
        {webcamImagePreview ? (
          <ImagePreview
            src={formattedValue(webcamImagePreview)}
            style={{ border: 'none', borderRadius: '0px' }}
          />
        ) : (
          <Webcam
            style={{ maxWidth: 'calc(100vw - 10px)' }}
            height="500px"
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
          />
        )}
      </ModalBody>
      <Modal.Footer>
        <CaptureButtonContainer>
          {!webcamImagePreview && (
            <Flex>
              <CaptureButton type="button" onClick={() => handleCapture()} />
            </Flex>
          )}
          {webcamImagePreview && (
            <>
              <ButtonPadrao
                id="tirarOutraFoto"
                type="button"
                variant="outline"
                name="tirarOutraFoto"
                text={t('Tirar outra foto')}
                onClick={() => setWebcamImagePreview('')}
              />
              <ButtonPadrao
                id="confirmar"
                type="button"
                variant="success"
                name="confirmar"
                text={t('Confirmar')}
                onClick={() => {
                  onChange(webcamImagePreview);
                  handleClose();
                }}
                style={{ width: '120px' }}
              />
            </>
          )}
        </CaptureButtonContainer>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(WebcamModalPadrao);
