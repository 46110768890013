import React, { useEffect, useState, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory, RouteComponentProps } from 'react-router-dom';

import ConstanteRotas from 'constants/rotas';
import { obterTabelaPreco as apiObterTabelaPreco } from 'helpers/api/TabelaPreco/obterTabelaPreco';

import ManterFoco from 'components/Geral/ManterFoco';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { FormFooter } from 'components/update/Form/FormFooter';

import Formulario from '..';
import { yupResolver, FormData } from '../validationForm';

type TParams = { id: string };

const Visualizar = ({ match }: RouteComponentProps<TParams>) => {
  const history = useHistory();
  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues: {
      nome: '',
      ativo: true,
    },
  });

  const tabelaPrecoId = match.params.id;

  const [isLoading, setIsLoading] = useState(false);
  const [dataHoraCriacao, setDataHoraCriacao] = useState('');
  const [dataHoraUltimaAlteracao, setDataHoraUltimaAlteracao] = useState('');

  const latestProps = useRef({
    reset: formMethods.reset,
    tabelaPrecoId,
    history,
    getValues: formMethods.getValues,
  });
  useEffect(() => {
    latestProps.current = {
      reset: formMethods.reset,
      tabelaPrecoId,
      history,
      getValues: formMethods.getValues,
    };
  });

  useEffect(() => {
    async function obterTabelaPreco() {
      setIsLoading(true);

      const responseData = await apiObterTabelaPreco(
        latestProps.current.tabelaPrecoId
      );

      if (responseData) {
        setDataHoraCriacao(responseData.dataHoraCadastro || '');
        setDataHoraUltimaAlteracao(responseData.dataHoraUltimaAlteracao || '');

        setIsLoading(false);

        const lojasSelecionadas = latestProps.current.getValues(
          'lojasSelecionadas'
        );
        latestProps.current.reset({
          ...responseData,
          lojasSelecionadas: lojasSelecionadas || [],
        });
      } else {
        setIsLoading(false);

        latestProps.current.history.push(ConstanteRotas.TABELA_PRECOS);
      }
    }

    obterTabelaPreco();
  }, []);

  return (
    <Box>
      <ManterFoco>
        {isLoading && <LoadingPadrao />}
        <FormProvider {...formMethods}>
          <Formulario tabelaPrecoId={tabelaPrecoId} isReadonly />
        </FormProvider>

        <FormFooter
          isDisabled={isLoading}
          dates={{ dataHoraCriacao, dataHoraUltimaAlteracao }}
        />
      </ManterFoco>
    </Box>
  );
};

export default Visualizar;
