import { toast } from 'react-toastify';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { ImprimirPDF } from 'helpers/impressoes/imprimirPDF';
import api, { ResponseApi } from 'services/api';

import { ModalCompartilhar } from 'components/Modal/ModalCompartilhar';
import { EmailModal } from 'components/PDV/CompartilharDocumentosFiscais/EmailModal';

import { DadosRelatorio } from '../types';

export type BaixarContaItem = {
  movimentacaoFinanceiraId: string;
  valor: number;
  desconto?: number;
  multa: number;
  juros: number;
  operacaoId?: string;
};

export type BaixarContaList = BaixarContaItem[];

export const imprimirPdfReciboA4 = async (
  idMovimentacaoFinanceira: string[]
) => {
  const response = await api.post<void, ResponseApi<string>>(
    ConstanteEnderecoWebservice.CONTAS_RECEBER_RECIBO_A4,
    idMovimentacaoFinanceira
  );

  if (response) {
    if (response.sucesso && response.dados) {
      ImprimirPDF(response.dados, 'relatorioBaixaContasReceber');
    }

    if (response?.avisos) {
      response.avisos.forEach((aviso: string) => toast.warning(aviso));
    }
  }
};

export const enviarEmailReciboA4 = async (
  idMovimentacaoFinanceira: string[]
) => {
  EmailModal({
    idContas: idMovimentacaoFinanceira,
  });
};

export const envioEmailRelatorioPdf = async (dados: DadosRelatorio) => {
  EmailModal({
    filtroListaPaginada: {
      contasReceber: dados,
    },
  });
};

export const imprimirRelatorioPdf = async (dados: DadosRelatorio) => {
  const response = await api.get<void, ResponseApi<string>>(
    ConstanteEnderecoWebservice.CONTAS_RECEBER_RELATORIO_LISTAGEM,
    {
      params: dados,
    }
  );
  if (response) {
    if (response.sucesso && response.dados) {
      ImprimirPDF(response.dados, 'relatorioContasReceber');
    }

    if (response?.avisos) {
      response.avisos.forEach((item) => toast.warning(item));
    }
  }
};

export const compartilharRelatorio = (dados: DadosRelatorio) => {
  ModalCompartilhar({
    items: [
      {
        titulo: 'Relatório contas a receber',
        onClickImpressao: (onClose) => {
          imprimirRelatorioPdf(dados);
          onClose();
        },
        onClickEmail: (onClose) => {
          envioEmailRelatorioPdf(dados);
          onClose();
        },
      },
    ],
  });
};
