import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useState } from 'react';

import { FormData, yupResolver, defaultValues } from '../validationForm';
import { useIntermediadorForm } from '../hooks';
import UncontrolledForm from '..';

const IntermediadorCadastrar = () => {
  const {
    salvarIntermediador,
    redirecionarIntermediadorListar,
  } = useIntermediadorForm();

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues,
  });

  const { handleSubmit, reset } = formMethods;

  const [isLoading, setIsLoading] = useState(false);

  const handleSalvar = async (data: FormData) => {
    setIsLoading(true);

    const { success } = await salvarIntermediador(data);

    if (success) {
      toast.success('Intermediador cadastrado com sucesso');
      redirecionarIntermediadorListar();
    }

    setIsLoading(false);
  };

  const handleSalvarInserirNovo = async (data: FormData) => {
    setIsLoading(true);

    const { success } = await salvarIntermediador(data);

    if (success) {
      toast.success('Intermediador cadastrado com sucesso');
      reset();
    }

    setIsLoading(false);
  };

  return (
    <FormProvider {...formMethods}>
      <UncontrolledForm
        isLoading={isLoading}
        handleSalvar={handleSubmit(handleSalvar)}
        handleSalvarInserirNovo={handleSubmit(handleSalvarInserirNovo)}
      />
    </FormProvider>
  );
};

export { IntermediadorCadastrar };
