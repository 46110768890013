import jsonp from 'jsonp';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import { capitalize } from 'helpers/format/stringFormats';

export interface CnpjResponse {
  nome: string;
  fantasia: string;
  logradouro: string;
  numero: string;
  complemento: string;
  cep: string;
  bairro: string;
  municipio: string;
  email: string;
  telefone: string;
}

const consultarReceitaWS = (
  cnpj: string,
  getData: (sucesso: boolean, dados: any) => void
) => {
  jsonp(
    `https://www.receitaws.com.br/v1/cnpj/${cnpj.replace(/\D/g, '')}`,
    { timeout: 5000 },
    (err: any, data: any) => {
      if (err) getData(false, ConstanteMensagemValidacao.CNPJ_ERRO_CONSULTA);
      else if (data) {
        const { fantasia, nome, telefone } = data;

        const newData = {
          ...data,
          telefone: telefone ? telefone.split('/')[0] : undefined,
          nome: nome ? capitalize(nome) : undefined,
          fantasia: fantasia ? capitalize(fantasia) : undefined,
        };

        getData(true, newData);
      }
    }
  );
};

export default consultarReceitaWS;
