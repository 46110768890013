import { useState, useCallback, useEffect } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  ModalFooter,
  TabPanel,
  TabPanels,
  TabList,
  Tabs,
  ModalHeader,
  useMediaQuery,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import auth from 'modules/auth';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { HeaderTab } from './components/HeaderTab';
import { TabDadosCadastro } from './components/TabDadosCadastro';
import { TabProdutosFavoritos } from './components/TabProdutosFavoritos';

import {
  FormData,
  defaultValues,
  yupResolver,
  DepartamentoResponse,
} from './validationForm';

type ResolveModalProps = {
  success: boolean;
  newDepartmentId?: string;
};

type ModalCadastrarDepartamentoProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ResolveModalProps> & {
    id?: string;
    title?: string;
    descricao?: string;
  };

export const ModalCadastrarDepartamento = create<
  ModalCadastrarDepartamentoProps,
  ResolveModalProps
>(
  ({
    onResolve,
    onReject,
    id,
    title = 'Cadastro de departamento ',
    descricao,
    ...rest
  }) => {
    const { id: lojaId } = auth.getLoja();

    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
    const [isLoading, setIsLoading] = useState(false);

    const formMethods = useForm<FormData>({
      resolver: yupResolver,
      defaultValues: {
        ...defaultValues,
        descricao: descricao || defaultValues.descricao,
      },
    });

    const { handleSubmit: onSubmit, reset } = formMethods;

    const registerDepartment = useCallback(async (data: FormData) => {
      const contasBloqueadas = data?.contasBloqueadas.map(
        (contaBloqueada) => contaBloqueada.value
      );

      const response = await api.post<void, ResponseApi<string>>(
        ConstanteEnderecoWebservice.DEPARTAMENTO,
        { ...data, contasBloqueadas }
      );

      return response;
    }, []);

    const changeDepartment = useCallback(
      async (data: FormData) => {
        const contasBloqueadas = data?.contasBloqueadas.map(
          (contaBloqueada) => contaBloqueada.value
        );

        const response = await api.put<void, ResponseApi<string>>(
          `${ConstanteEnderecoWebservice.DEPARTAMENTO}/${id}`,
          { ...data, contasBloqueadas }
        );

        return response;
      },
      [id]
    );

    const handleSubmit = onSubmit(async (data) => {
      setIsLoading(true);

      let response: ResponseApi<string>;

      const incorrectMainAccountRange =
        data.numeracaoInicialContaPrincipal > data.numeracaoFinalContaPrincipal;

      const incorrectSubAccountRange =
        data.numeracaoInicialSubConta > data.numeracaoFinalSubConta;

      if (incorrectMainAccountRange || incorrectSubAccountRange) {
        toast.warning(
          ` O valor inicial da ${
            incorrectMainAccountRange ? 'conta principal' : 'subconta'
          } deve ser equivalente ou menor que o valor final`
        );

        setIsLoading(false);
        return;
      }

      if (id) {
        response = await changeDepartment(data);
      } else {
        response = await registerDepartment(data);
      }

      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response?.sucesso) {
          onClose();
          onResolve({ success: true, newDepartmentId: response.dados });
        }
      }

      setIsLoading(false);
    });

    const getDepartment = useCallback(async () => {
      if (!id) {
        return;
      }

      setIsLoading(true);

      const response = await api.get<void, ResponseApi<DepartamentoResponse>>(
        `${ConstanteEnderecoWebservice.DEPARTAMENTO}/${id}`
      );

      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }
        if (response?.sucesso && response?.dados) {
          const { dados } = response;
          const { subConta, contaPrincipal } = dados;

          const department = {
            ativo: dados.informacoesBasicas.ativo,
            descricao: dados.descricao,
            descricaoContaPrincipal: contaPrincipal.descricao,
            numeracaoInicialContaPrincipal: contaPrincipal.numeracaoInicial,
            numeracaoFinalContaPrincipal: contaPrincipal.numeracaoFinal,
            descricaoSubConta: subConta?.descricao,
            numeracaoInicialSubConta: subConta?.numeracaoInicial || 0,
            numeracaoFinalSubConta: subConta?.numeracaoFinal || 0,
            listProducts: dados.produtosFavoritos.map(({ produto }) => ({
              id: produto.id,
              nome: produto.nome,
            })),
            contasBloqueadas: dados.contasBloqueadas?.map((contaBloqueada) => ({
              label: contaBloqueada.numeroConta.toString(),
              value: contaBloqueada.numeroConta,
            })),
            exibicaoContas: dados.exibicaoContas,
            numeracaoContas: dados.numeracaoContas,
            utilizarSubConta: !!subConta,
            valorCouver: dados.valorCouver,
            lojaId,
            infoProdutos: null,
          } as any;

          reset(department);
        }
      }

      setIsLoading(false);
    }, [id, lojaId, reset]);

    useEffect(() => {
      getDepartment();
    }, [getDepartment]);

    return (
      <ModalPadraoChakra
        size={isLargerThan900 ? '6xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
        closeOnOverlayClick={false}
        scrollBehavior="outside"
      >
        <ModalContent
          borderRadius={isLargerThan900 ? 'md' : '0'}
          px={{ base: '8px', md: '40px' }}
          bg="gray.50"
          minH="full"
        >
          <ModalHeader pt={{ base: '12px', md: '24px' }}>
            <Text textColor="primary.50" mt={{ base: 4, md: 0 }}>
              {title}
            </Text>
          </ModalHeader>
          <FormProvider {...formMethods}>
            {isLoading && <LoadingPadrao />}
            <ModalBody overflow="auto" px="0">
              <Tabs variant="soft-rounded" position="relative" w="full">
                <TabList
                  h="50px"
                  mb="0"
                  bg="gray.50"
                  paddingTop="13px"
                  borderRadius="6px 6px 0 0"
                  border="none"
                >
                  <HeaderTab title="Dados do cadastro" />
                  <HeaderTab title="Produtos favoritos" />
                </TabList>
                <TabPanels pl="22px" position="relative">
                  <TabPanel>
                    <TabDadosCadastro />
                  </TabPanel>
                  <TabPanel>
                    <TabProdutosFavoritos />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </ModalBody>
            <ModalFooter
              justifyContent="center"
              flexWrap="wrap"
              gap={6}
              py="40px"
            >
              <Button
                w={{ base: 'full', sm: 'min-content' }}
                colorScheme="gray"
                variant="outlineDefault"
                onClick={onClose}
              >
                Cancelar
              </Button>
              <Button
                w={{ base: 'full', sm: 'min-content' }}
                minW="225px"
                colorScheme="secondary"
                onClick={handleSubmit}
                isDisabled={isLoading}
              >
                Confirmar
              </Button>
            </ModalFooter>
          </FormProvider>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
