import { CategoriaProdutoProps } from 'store/SmartPos/CategoriaProdutos';

export const handleGetCategoriasPeloPaiId = (
  categorias: CategoriaProdutoProps[],
  categoriaProdutoPaiId?: string
) => {
  return categorias
    .filter((categoriaProduto) =>
      !categoriaProdutoPaiId
        ? !categoriaProduto.categoriaProdutoPaiId ||
          categoriaProduto.categoriaProdutoPaiId ===
            '00000000-0000-0000-0000-000000000000'
        : categoriaProduto.categoriaProdutoPaiId === categoriaProdutoPaiId
    )
    .sort((a, b) => {
      if (a.categoria < b.categoria) {
        return -1;
      }
      if (a.categoria > b.categoria) {
        return 1;
      }
      return 0;
    });
};
