import { Flex, Text, Box, Divider, useMediaQuery } from '@chakra-ui/react';

import { DecimalMask, moneyMask } from 'helpers/format/fieldsMasks';

import { ResumoLojaProps } from '../../Types/validationsForm';

type ResumoVendedorIndividualProps = {
  listaResumoVendedor: ResumoLojaProps;
};

export const ResumoVendedorIndividual = ({
  listaResumoVendedor,
}: ResumoVendedorIndividualProps) => {
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  return (
    <Flex
      borderRadius="5px"
      boxShadow="0px 0px 6px #00000034"
      direction={isLargerThan900 ? 'row' : 'column'}
      justifyContent={isLargerThan900 ? 'left' : 'flex-end'}
      alignItems={isLargerThan900 ? 'center' : 'left'}
      pl={isLargerThan900 ? '40px' : '20px'}
      pt="29px"
      pb="24px"
      bg="white"
    >
      <Box mr="50px">
        <Flex
          mb={isLargerThan900 ? '13px' : '3px'}
          justifyContent="left"
          alignItems="center"
        >
          <Text mr="5px" fontSize="14px" color="gray.700">
            Competência:
          </Text>
          <Text fontSize="16px" color="black">
            {listaResumoVendedor.competência}
          </Text>
        </Flex>
        <Flex
          mb={isLargerThan900 ? '' : '3px'}
          justifyContent="left"
          alignItems="center"
        >
          <Text mr="5px" fontSize="14px" color="gray.700">
            Metas:
          </Text>
          <Text fontSize="16px" color="black">
            {moneyMask(listaResumoVendedor.meta, false)}
          </Text>
        </Flex>
      </Box>
      {isLargerThan900 && <Divider mr="40px" orientation="vertical" h="60px" />}
      <Box mr="40px">
        <Flex
          mb={isLargerThan900 ? '13px' : '3px'}
          justifyContent="left"
          alignItems="center"
        >
          <Text mr="5px" fontSize="14px" color="gray.700">
            Faturamento:
          </Text>
          <Text fontSize="16px" color="black">
            {moneyMask(listaResumoVendedor.faturamento, true)}
          </Text>
        </Flex>
        <Flex
          mb={isLargerThan900 ? '' : '3px'}
          justifyContent="left"
          alignItems="center"
        >
          <Text mr="5px" fontSize="14px" color="gray.700">
            Meta atingida:
          </Text>
          <Text fontSize="16px" color="black">
            {moneyMask(listaResumoVendedor.metaAtingida, false)} %
          </Text>
        </Flex>
      </Box>
      {isLargerThan900 && <Divider mr="40px" orientation="vertical" h="60px" />}
      <Box mr="50px">
        <Flex
          mb={isLargerThan900 ? '13px' : '3px'}
          justifyContent="left"
          alignItems="center"
        >
          <Text mr="5px" fontSize="14px" color="gray.700">
            Número de vendas:
          </Text>
          <Text fontSize="16px" color="black">
            {DecimalMask(listaResumoVendedor.quantidadeVendas, 0, 0)}
          </Text>
        </Flex>
        <Flex
          mb={isLargerThan900 ? '' : '3px'}
          justifyContent="left"
          alignItems="center"
        >
          <Text mr="5px" fontSize="14px" color="gray.700">
            Ticket médio:
          </Text>
          <Text fontSize="16px" color="black">
            {moneyMask(listaResumoVendedor.ticketMedio, true)}
          </Text>
        </Flex>
      </Box>
      {isLargerThan900 && <Divider mr="40px" orientation="vertical" h="60px" />}
      <Box mr="40px">
        <Flex
          mb={isLargerThan900 ? '13px' : '3px'}
          justifyContent="left"
          alignItems="center"
        >
          <Text mr="5px" fontSize="14px" color="gray.700">
            Produtos vendidos:
          </Text>
          <Text fontSize="16px" color="black">
            {DecimalMask(listaResumoVendedor.produtosVendidos, 0, 0)}
          </Text>
        </Flex>
        <Flex justifyContent="left" alignItems="center">
          <Text mr="5px" fontSize="14px" color="gray.700">
            Produtos por atendimento:
          </Text>
          <Text fontSize="16px" color="black">
            {DecimalMask(listaResumoVendedor.produtosPorVenda, 2, 2)}
          </Text>
        </Flex>
      </Box>
    </Flex>
  );
};
