import ConstanteRotas from 'constants/rotas';

import {
  ConfiguracoesFrenteCaixaIcon,
  AtivacaoFrenteCaixaIcon,
  DispotivosFrenteCaixaIcon,
  EntregadoresFrenteCaixaIcon,
  SetorEntregasFrenteCaixaIcon,
  GerenciadorImpressaoFrenteCaixaIcon,
  DepartamentoFrenteCaixaIcon,
  TefFrenteCaixaIcon,
  PeriodoCaixaFrenteCaixaIcon,
  RelatorioPersonalizadoFrenteCaixaIcon,
  IntermediadorFrenteCaixaIcon,
} from 'icons';

const enumPainelAdministradorFrenteCaixa = {
  options: [
    {
      icone: ConfiguracoesFrenteCaixaIcon,
      nome: 'Configurações',
      rota: ConstanteRotas.FRENTE_CAIXA_CONFIGURACAO,
      desabilitado: false,
    },
    {
      icone: DispotivosFrenteCaixaIcon,
      nome: 'Dispositivos',
      rota: ConstanteRotas.FRENTE_CAIXA_DISPOSITIVOS,
      desabilitado: false,
    },
    {
      icone: EntregadoresFrenteCaixaIcon,
      nome: 'Entregadores',
      rota: ConstanteRotas.FRENTE_CAIXA_ENTREGADORES,
      desabilitado: false,
    },
    {
      icone: SetorEntregasFrenteCaixaIcon,
      nome: 'Setor de entregas',
      rota: ConstanteRotas.FRENTE_CAIXA_SETORES_ENTREGA,
      desabilitado: false,
    },
    {
      icone: GerenciadorImpressaoFrenteCaixaIcon,
      nome: 'Gerenciador de impressão',
      rota: ConstanteRotas.FRENTE_CAIXA_GERENCIADORES_IMPRESSAO,
      desabilitado: false,
    },
    {
      icone: DepartamentoFrenteCaixaIcon,
      nome: 'Departamentos',
      rota: ConstanteRotas.FRENTE_CAIXA_DEPARTAMENTOS,
      desabilitado: false,
    },
    {
      icone: TefFrenteCaixaIcon,
      nome: 'TEF',
      rota: ConstanteRotas.FRENTE_CAIXA_FORMAS_RECEBIMENTO,
      desabilitado: false,
    },
    {
      icone: AtivacaoFrenteCaixaIcon,
      nome: 'Ativação',
      rota: ConstanteRotas.FRENTE_CAIXA_ATIVACAO,
      desabilitado: false,
    },
    {
      icone: IntermediadorFrenteCaixaIcon,
      nome: 'Intermediador',
      rota: ConstanteRotas.FRENTE_CAIXA_INTERMEDIADOR,
      desabilitado: false,
    },
    {
      icone: PeriodoCaixaFrenteCaixaIcon,
      nome: 'Período do caixa',
      rota: ConstanteRotas.FRENTE_CAIXA_PERIODO_CAIXA,
      desabilitado: false,
    },
    {
      icone: RelatorioPersonalizadoFrenteCaixaIcon,
      nome: 'Relatório personalizado',
      rota: ConstanteRotas.FRENTE_CAIXA_RELATORIO_PERSONALIZADO,
      desabilitado: false,
    },
  ],
};

export default enumPainelAdministradorFrenteCaixa;
