import React from 'react';
import {
  Textarea,
  TextareaProps as ChakraTextareaProps,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import CampoContainer, {
  CampoContainerProps,
} from 'components/PDV/Geral/CampoContainer';

export type TextareaProps = ChakraTextareaProps &
  CampoContainerProps & {
    ref?: React.LegacyRef<HTMLTextAreaElement>;
  };

const TextareaField = ({
  name,
  label,
  isDisabled,
  isRequired = false,
  helperText,
  id,
  colSpan,
  colStart,
  colEnd,
  rowSpan,
  rowStart,
  rowEnd,
  ...rest
}: TextareaProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <CampoContainer
      name={name}
      isRequired={isRequired}
      isDisabled={isDisabled}
      helperText={helperText}
      id={id}
      label={label}
      rowSpan={rowSpan}
      rowStart={rowStart}
      rowEnd={rowEnd}
      colSpan={colSpan}
      colStart={colStart}
      colEnd={colEnd}
      errorText={errors[name]?.message}
    >
      <Textarea {...rest} id={id} {...register(`${name}` as const)} />
    </CampoContainer>
  );
};

export default TextareaField;
