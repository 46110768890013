import React from 'react';
import { FormProvider } from 'react-hook-form';

import PlanoContasGeralProvider from 'store/PlanoContas/PlanoContasGeral';
import { Card } from 'styles';

import CardContent from './CardContent';
import { useForm, yupResolver } from './validationForm';

const PlanoContas = () => {
  const formMethods = useForm({
    resolver: yupResolver,
  });

  return (
    <Card style={{ maxWidth: '1166px' }}>
      <FormProvider {...formMethods}>
        <PlanoContasGeralProvider>
          <CardContent />
        </PlanoContasGeralProvider>
      </FormProvider>
    </Card>
  );
};

export default PlanoContas;
