import React from 'react';

import ConstanteFuncionalidades from 'constants/permissoes';
import ConstanteRotas from 'constants/rotas';

import { ListarManifestacaoDestinatario } from 'pages/ManifestacaoDestinatario/Listar';

import LayoutGuard from './LayoutGuard';

export const ManifestacaoDestinatarioRoutes = [
  <LayoutGuard
    key="manifestacao-destinatario-listar"
    permissaoFuncionalidade={
      ConstanteFuncionalidades.MANIFESTACAO_DESTINATARIO_VISUALIZAR
    }
    component={ListarManifestacaoDestinatario}
    breadcrumb={[
      {
        title: 'Fiscal',
      },
      {
        title: 'Manifestação do Destinatário',
      },
    ]}
    path={ConstanteRotas.MANIFESTACAO_DESTINATARIO}
    exact
    meta={{ auth: true }}
  />,
];
