import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { RegraFiscal } from 'types/regrasFiscais';

export async function obterRegraFiscal(id: string) {
  const response = await api.get<void, ResponseApi<RegraFiscal | undefined>>(
    ConstanteEnderecoWebservice.REGRA_FISCAL_OBTER,
    {
      params: { id },
    }
  );

  if (response) {
    if (response.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (response.sucesso && response.dados) {
      return response.dados;
    }
  }

  return undefined;
}
