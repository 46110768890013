import React, { useEffect, useState, useCallback } from 'react';
import {
  Flex,
  Grid,
  Button,
  useMediaQuery,
  VStack,
  Text,
  HStack,
  IconButton,
  Icon,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import Layout from 'components/PDV/Layout';
import ManterFoco from 'components/Geral/ManterFoco';
import { useHistory } from 'react-router-dom';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteRotasPDV from 'constants/rotasPDV';
import TipoContaFinanceiraEnum from 'constants/enum/tipoContaFinanceira';
import Loading from 'components/Layout/Loading/LoadingPadrao';
import ShadowScrollbar from 'components/PDV/Geral/ShadowScrollbar';
import ConditionalWrapper from 'components/Geral/ConditionalWrapper';
import { FiChevronLeft } from 'react-icons/fi';
import auth from 'modules/auth';
import { useInformacoesGeraisContext } from 'store/PDV/InformacoesGerais';

import ButtonAbrirPdv from './ButtonAbrirCaixa';

type ContaFinanceiraCofreCaixaViewModel = {
  id: string;
  nome: string;
  tipoContaFinanceira: number;
  caixaMovimentacao: ContaFinanceiraCofreCaixaMovimentacaoViewModel;
  serialPOS: string;
};

type ContaFinanceiraCofreCaixaMovimentacaoViewModel = {
  id: string;
  usuarioAberturaId: string;
  usuarioAberturaNome: string;
};

const AbrirCaixa = () => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const [caixas, setCaixas] = useState<ContaFinanceiraCofreCaixaViewModel[]>(
    []
  );

  const [
    caixaSelecionado,
    setCaixaSelecionado,
  ] = useState<ContaFinanceiraCofreCaixaViewModel>();

  const { setInfoCaixa } = useInformacoesGeraisContext();

  const abrirCaixa = async () => {
    setInfoCaixa(caixaSelecionado);
    history.push(ConstanteRotasPDV.PDV_ABERTURA_CAIXA);
  };
  const isVisibleScroll = isLargerThan900
    ? caixas.length > 4
    : caixas.length > 2;

  const consultarCaixas = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<
      void,
      ResponseApi<ContaFinanceiraCofreCaixaViewModel[]>
    >(
      ConstanteEnderecoWebservice.CONTA_FINANCEIRA_CONSULTAR_CONTAS_CAIXA_COFRE_POR_LOJA
    );

    if (response) {
      if (response.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }

      if (response.sucesso && response.dados) {
        const { id: usuarioId } = auth.getUsuario();

        const caixaAbertoUsuario = response.dados.filter(
          (item) => item.caixaMovimentacao?.usuarioAberturaId === usuarioId
        );

        if (caixaAbertoUsuario.length > 0) {
          toast.warning('Já existe um outro caixa aberto com o seu usuário.');
          history.push(ConstanteRotasPDV.PDV_HOME);
          return;
        }

        setCaixas(
          response.dados.filter(
            (item) =>
              item.tipoContaFinanceira !== TipoContaFinanceiraEnum.CONTA_COFRE
          )
        );
      }
    }
    setIsLoading(false);
  }, [history]);

  useEffect(() => {
    consultarCaixas();
  }, [consultarCaixas]);

  return (
    <Layout
      containerOverflow="auto"
      bodyOverflow="visible"
      isHeaderVisible={isLargerThan900}
    >
      {isLoading && <Loading />}
      <ManterFoco
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <Flex
          h="100%"
          w="100%"
          alignItems="center"
          justifyContent="center"
          p={{ base: 0, md: 6 }}
          bg="primary.800"
        >
          <VStack
            bg="gray.50"
            borderRadius={{ base: 'none', md: 'md' }}
            width={{ base: 'full', md: '640px' }}
            height={{
              base: 'full',
              md: caixas.length <= 4 ? '363px' : '420px',
            }}
            py="40px"
            px={{
              base: isVisibleScroll ? '19px' : '24px',
              md: isVisibleScroll ? '27px' : '32px',
            }}
            pt={{ base: '0px', md: '32px' }}
          >
            <HStack w="full">
              {isLargerThan900 ? (
                <Text color="primary.50" fontSize="18px" fontWeight="semibold">
                  Abrir caixa
                </Text>
              ) : (
                <Flex
                  h="56px"
                  w="full"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <IconButton
                    aria-label="Voltar"
                    icon={<Icon as={FiChevronLeft} boxSize="6" />}
                    variant="link"
                    color="gray.700"
                    onClick={() => {
                      history.push(ConstanteRotasPDV.PDV_ABRIR_PDV);
                    }}
                    minW="8"
                  />

                  <Text color="primary.50" fontSize="md" mr="2">
                    Abrir caixa
                  </Text>
                </Flex>
              )}
            </HStack>
            <HStack pt={1}>
              <Text color="gray.700" fontSize="14px">
                Selecione um caixa disponível. O caixa escolhido não poderá ser
                utilizado em outro dispositivo ou por outro usuário enquanto
                permanecer aberto.
              </Text>
            </HStack>
            <ConditionalWrapper
              condition
              wrapper={(wrappedChildren: React.ReactNode) => (
                <ShadowScrollbar
                  maxHeight={isLargerThan900 ? 200 : 280}
                  shadowTopStyle={{
                    background:
                      'linear-gradient(to bottom, rgba(245, 245, 245, 1) 0%, rgba(245, 245, 245, 0) 100%)',
                    height: 30,
                  }}
                  shadowBottomStyle={{
                    background:
                      'linear-gradient(to top, rgba(245, 245, 245, 1) 0%, rgba(245, 245, 245, 0) 100%)',
                    height: 30,
                  }}
                >
                  {wrappedChildren}
                </ShadowScrollbar>
              )}
            >
              <Flex
                justifyContent="center"
                pr={isVisibleScroll ? '10px' : undefined}
                width="100%"
              >
                <Grid
                  templateColumns={
                    isLargerThan900
                      ? `repeat(${
                          caixas.length >= 4 ? '4' : caixas.length
                        }, 1fr)`
                      : 'repeat(2, 1fr)'
                  }
                  gap={isLargerThan900 ? 8 : 6}
                  w="100%"
                  m="1"
                  mt="-10px"
                >
                  {caixas.map((caixa) => (
                    <ButtonAbrirPdv
                      caixaNome={caixa.nome}
                      usuarioAberturaNome={
                        caixa.caixaMovimentacao?.usuarioAberturaNome
                      }
                      onClick={() => {
                        setCaixaSelecionado(caixa);
                      }}
                      isSelected={caixaSelecionado?.id === caixa.id}
                      autoFocus={caixaSelecionado?.id === caixa.id}
                    />
                  ))}
                </Grid>
              </Flex>
            </ConditionalWrapper>

            <Flex w="full" justifyContent="center" pt={6}>
              <HStack spacing={6}>
                {isLargerThan900 && (
                  <Button
                    variant="outline"
                    size="sm"
                    color="gray.400"
                    onClick={() => {
                      history.push(ConstanteRotasPDV.PDV_ABRIR_PDV);
                    }}
                    minW="96px"
                  >
                    Voltar
                  </Button>
                )}
                <Button
                  variant="solid"
                  colorScheme="secondary"
                  size={isLargerThan900 ? 'sm' : 'lg'}
                  onClick={abrirCaixa}
                  disabled={!caixaSelecionado}
                  minW="96px"
                >
                  Abrir caixa
                </Button>
              </HStack>
            </Flex>
          </VStack>
        </Flex>
      </ManterFoco>
    </Layout>
  );
};

export default AbrirCaixa;
