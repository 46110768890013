import { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import { shallowEqual } from 'helpers/validation/shallowEqual';
import api, { ResponseApi } from 'services/api';

import { PagedTableForwardRefData } from 'components/update/Table/PagedTable';
import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import { PaginationData } from 'components/update/Pagination';

type DeliveryPersonProps = {
  id: string;
  nome: string;
  nomeEcommerce: string | null;
  ativo: boolean;
};

type FilterProps = {
  nome: string;
  status: 1 | 2 | 3;
};

const useDeliveryPerson = () => {
  const history = useHistory();

  const formMethods = useForm<FilterProps>({
    defaultValues: {
      nome: '',
      status: 1,
    },
  });

  const { handleSubmit } = formMethods;

  const [isLoading, setIsLoading] = useState(false);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [listDeliveryPerson, setListDeliveryPerson] = useState<
    DeliveryPersonProps[]
  >([]);
  const [currentFilters, setCurrentFilters] = useState<FilterProps>({
    nome: '',
    status: 1,
  });

  const pagedTableRef = useRef<PagedTableForwardRefData>(null);

  const filtersSubmit = handleSubmit((data) => {
    const filtersIsDirty = !shallowEqual(data, currentFilters);

    if (filtersIsDirty) {
      setCurrentFilters(data);
    }
  });

  const reloadList = async () => {
    if (pagedTableRef?.current?.reload) {
      await pagedTableRef.current.reload();
    }
  };

  const paginationHandle = useCallback(
    async (gridPaginadaConsulta: PaginationData) => {
      setIsLoading(true);

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<DeliveryPersonProps>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.ENTREGADOR,
          gridPaginadaConsulta,
          {
            ...currentFilters,
          }
        )
      );

      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        setTotalRegistros(response.dados?.total || 0);

        if (response?.sucesso && response?.dados) {
          setListDeliveryPerson(response.dados.registros);
        }
      }

      setIsLoading(false);
    },
    [currentFilters]
  );

  const handleRegisterDeliveryPerson = useCallback(() => {
    history.push(ConstanteRotas.FRENTE_CAIXA_ENTREGADOR_CADASTRAR);
  }, [history]);

  const handleUpdateDeliveryPerson = useCallback(
    (deliveryPersonId: string) => {
      history.push(
        SubstituirParametroRota(
          ConstanteRotas.FRENTE_CAIXA_ENTREGADOR_ALTERAR,
          'id',
          deliveryPersonId
        )
      );
    },
    [history]
  );

  const handleRemoveDeliveryPerson = useCallback(
    async (deliveryPersonId: string) => {
      const response = await api.delete<void, ResponseApi>(
        `${ConstanteEnderecoWebservice.ENTREGADOR}/${deliveryPersonId}`
      );

      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response?.sucesso) {
          await reloadList();
          toast.success('Entregador removido com sucesso');
        }
      }
    },
    []
  );

  return {
    formMethods,
    handleRegisterDeliveryPerson,
    handleUpdateDeliveryPerson,
    handleRemoveDeliveryPerson,
    paginationHandle,
    pagedTableRef,
    totalRegistros,
    filtersSubmit,
    listDeliveryPerson,
    isLoading,
  };
};

export default useDeliveryPerson;
