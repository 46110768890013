import React, { ReactNode } from 'react';
import { OptionsType, OptionTypeBase, ValueType } from 'react-select';

import OptionType from 'types/optionType';
import CampoPrototipo, {
  CampoPrototipoProps,
} from 'components/PDV/Geral/CampoPrototipo';

import SelectInput, {
  SelectInputProps,
  findOption,
  findOptions,
  getValue,
} from './Input';

export type OptionProps = {
  label: string;
  value: string | number;
};

export type SelectFieldProps = Omit<SelectInputProps, 'disabled'> &
  Omit<CampoPrototipoProps, 'disabled'> & {
    onChangeEffect?: (option: OptionType | Array<OptionType> | null) => void;
    onGetValue?: (option: OptionProps) => void;
    onSelect?: (option: OptionType | Array<OptionType> | null | any) => void;
    onClick?: () => void;
    asControlledByObject?: boolean;
    topRightElement?: ReactNode;
    shouldAppearTheAddress?: boolean;
    renderOptionDetails?: (data: any) => JSX.Element | null;
    handleChange?: () => void;
    color?: string;
    bgHelperText?: string;
    colorHelperText?: string;
    textLabelSelectAll?: string;
    optionsBase?: OptionsType<OptionTypeBase>;
    valueDefault?: any;
    fontWeightLabel?: string;
    cursor?: string;
    helperTextLabel?: string;
  };

const SelectField = ({
  name,
  label,
  color = 'black',
  bgHelperText = 'black',
  required = false,
  isDisabled,
  helperText,
  id,
  isMulti = false,
  optionsBase,
  options: optionDefault,
  onChangeEffect,
  onGetValue,
  onSelect,
  colSpan,
  handleChange,
  colStart,
  colEnd,
  rowSpan,
  rowStart,
  rowEnd,
  onClick,
  colorHelperText = 'white',
  asControlledByObject,
  topRightElement,
  errorPropName,
  errorText,
  valueDefault,
  helperTextLabel,
  shouldAppearTheAddress,
  renderOptionDetails,
  fontWeightLabel = 'semibold',
  labelColor,
  cursor = 'pointer',
  isLoading,
  ...selectProps
}: SelectFieldProps) => {
  const options = optionsBase || optionDefault;
  return (
    <CampoPrototipo
      cursor={cursor}
      name={name}
      defaultValue={valueDefault}
      isRequired={required}
      helperTextLabel={helperTextLabel}
      isDisabled={isDisabled}
      helperText={helperText}
      colorHelperText={colorHelperText}
      color={color}
      id={id}
      label={label}
      labelColor={labelColor}
      bgHelperText={bgHelperText}
      rowSpan={rowSpan}
      rowStart={rowStart}
      rowEnd={rowEnd}
      colSpan={colSpan}
      colStart={colStart}
      colEnd={colEnd}
      topRightElement={topRightElement}
      errorPropName={errorPropName}
      errorText={errorText}
      fontWeightLabel={fontWeightLabel}
    >
      {(
        { formState: { isSubmitting }, setValue },
        { value, ...fieldProps }
      ) => (
        <SelectInput
          isDisabled={isSubmitting || isDisabled}
          {...fieldProps}
          {...selectProps}
          id={id}
          isLoading={isLoading}
          isMulti={isMulti}
          options={options as any}
          value={
            // eslint-disable-next-line no-nested-ternary
            asControlledByObject
              ? value
              : isMulti
              ? findOptions(getValue(value, true), options as any)
              : findOption(
                  getValue(value, false),
                  options as any,
                  shouldAppearTheAddress
                )
          }
          onChange={async (
            option: ValueType<OptionType> | null | OptionProps | undefined
          ) => {
            const delay = (ms: number) =>
              new Promise((resolve) => setTimeout(resolve, ms));

            await delay(0);

            if (handleChange) {
              handleChange();
            }

            if (onGetValue) {
              onGetValue(option as OptionProps);
            }

            if (onChangeEffect) {
              onChangeEffect(option as OptionType | Array<OptionType> | null);
            }

            const onClear = () => {
              setValue(`${name}` as const, null, { shouldDirty: true });
            };

            if (!option) {
              onClear();
              return;
            }

            if (Array.isArray(option) && option.length === 0) {
              onClear();
              return;
            }

            if (Array.isArray(option)) {
              setValue(
                `${name}` as const,
                option.map((opt) => (asControlledByObject ? opt : opt.value)),
                { shouldDirty: true }
              );
              return;
            }

            setValue(
              `${name}` as const,
              asControlledByObject
                ? (option as OptionType)
                : (option as OptionType).value,
              {
                shouldDirty: true,
              }
            );

            if (onSelect) {
              onSelect(option as OptionType | Array<OptionType> | null);
            }
          }}
          selectRef={fieldProps.ref}
          onClick={onClick}
          renderOptionDetails={renderOptionDetails}
          shouldAppearTheAddress={shouldAppearTheAddress}
        />
      )}
    </CampoPrototipo>
  );
};

export default SelectField;
