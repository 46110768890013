import React from 'react';
import {
  InputGroup,
  InputLeftElement,
  Icon,
  useToken,
  InputRightElement,
} from '@chakra-ui/react';

import { LeitorCodigoBarrasIcon, LupaIcon } from 'icons';
import AsyncSelectPadrao, {
  AsyncSelectFieldProps,
} from 'components/PDV/Select/AsyncSelectPadrao';

interface FilterAsyncSelectProps extends AsyncSelectFieldProps {
  hasLupaIcon?: boolean;
  hideDropdown?: boolean;
  asMobileView?: boolean;
}

export const FilterAsyncSelect = ({
  styles,
  isLeitorCodigoBarras,
  hasLupaIcon = true,
  hideDropdown = false,
  asMobileView = false,
  ...rest
}: FilterAsyncSelectProps) => {
  const [size12] = useToken('sizes', ['12']);

  return (
    <InputGroup>
      {hasLupaIcon && (
        <InputLeftElement pointerEvents="none" mx="2.5">
          <Icon as={LupaIcon} />
        </InputLeftElement>
      )}
      {isLeitorCodigoBarras && (
        <InputRightElement pointerEvents="none" mx="2.5">
          <Icon as={LeitorCodigoBarrasIcon} />
        </InputRightElement>
      )}

      <AsyncSelectPadrao
        styles={{
          ...styles,
          ...(hasLupaIcon
            ? {
                valueContainer: (base: any) => ({
                  ...base,
                  paddingLeft: `${size12} !important`,
                }),
              }
            : {}),
          ...(isLeitorCodigoBarras || hideDropdown
            ? {
                dropdownIndicator: (base: any) => ({
                  ...base,
                  display: 'none',
                }),
              }
            : {}),
        }}
        asMobileView={asMobileView}
        {...rest}
      />
    </InputGroup>
  );
};
