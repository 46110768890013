import { useState, useEffect, useMemo, useRef } from 'react';
import {
  useMediaQuery,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  useToken,
  ModalFooter,
  HStack,
  Button,
  Stack,
} from '@chakra-ui/react';
import {
  addDays,
  endOfDay,
  startOfDay,
  differenceInCalendarDays,
} from 'date-fns';
import { defaultStaticRanges, DateRangePicker } from 'react-date-range';
import { pt } from 'date-fns/esm/locale';
import { useTranslation } from 'react-i18next';

import { setDateMaxHours, setDateMinHours } from 'helpers/data/setHoursDate';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import InputDate from 'components/PDV/InputDate';

export type Selection = {
  startDate: Date;
  endDate: Date;
  isDateStart?: boolean;
};

interface ModalDateRangeProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (newSelection?: Selection | undefined) => boolean;
  getCurrentSelection?: () => Selection;
  minDate?: Date;
  maxDate?: Date;
  cleanFiltersButtonText?: string;
  amountMonths?: number;
}

const ModalDateRange = ({
  isOpen,
  onClose,
  onSubmit,
  getCurrentSelection,
  cleanFiltersButtonText,
  minDate,
  amountMonths = 6,
  maxDate,
}: ModalDateRangeProps) => {
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const [isLessThan700] = useMediaQuery('(max-width: 700px)');
  const [primary500, gray50, gray100] = useToken('colors', [
    'primary.500',
    'gray.50',
    'gray.100',
  ]);

  const defineds = {
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
  };

  const buttonConfirm = useRef<HTMLButtonElement>(null);

  const [selection, setSelection] = useState({} as Selection);

  const lng = localStorage.getItem('language') || '';
  const { t } = useTranslation();

  const selectionRange = useMemo(() => ({ ...selection, key: 'selection' }), [
    selection,
  ]);

  const amountDaysInMonth = 30;

  const totalDays = amountDaysInMonth * amountMonths;

  function handleOnSubmit() {
    const dadosForamValidados = onSubmit(selection);
    if (dadosForamValidados) {
      onClose();
    }
  }

  function handleSetSelection({
    startDate: newStartDate,
    endDate: newEndDate,
    isDateStart = false,
  }: Selection) {
    if (isDateStart) {
      setSelection({
        startDate: setDateMinHours(newStartDate),
        endDate: setDateMaxHours(newStartDate),
      });
    } else {
      const sixMonthsPrevStartDate = startOfDay(new Date(newEndDate));
      let correctedStartDate = newStartDate;

      if (newStartDate < addDays(sixMonthsPrevStartDate, -180)) {
        correctedStartDate = new Date();
      }

      const startDate = setDateMinHours(correctedStartDate);

      const endDate = setDateMaxHours(newEndDate);

      setSelection({ startDate, endDate });
    }
  }

  const valueDateStart = new Date(selection?.startDate);
  const valueDataStartMinDate = new Date(selection?.startDate);

  const valueMaxDate = new Date(
    maxDate ||
      valueDateStart?.setMonth(valueDateStart?.getMonth() + amountMonths)
  );
  const valueMinDate = new Date(
    minDate ||
      valueDataStartMinDate?.setMonth(
        valueDataStartMinDate?.getMonth() - amountMonths
      )
  );

  useEffect(() => {
    if (isOpen && getCurrentSelection) {
      const newSelection = getCurrentSelection();

      setSelection(newSelection);
    }
  }, [getCurrentSelection, isOpen]);

  const defaultInputRanges = [
    {
      label: 'dias até hoje',
      range(value: number) {
        return {
          startDate: addDays(defineds.startOfToday, value * -1),
          endDate: defineds.endOfToday,
        };
      },
      getCurrentValue(range: any) {
        const daysDifference =
          differenceInCalendarDays(range.endDate, range.startDate) ?? '';
        const daysDifferenceSelection =
          differenceInCalendarDays(new Date(), range.endDate) ?? '';
        if (daysDifferenceSelection > totalDays) return '';
        return daysDifference.toString();
      },
    },
  ];

  return (
    <ModalPadraoChakra
      isOpen={isOpen}
      onClose={onClose}
      size={isLargerThan900 ? 'xl' : 'full'}
      scrollBehavior={isLargerThan900 ? 'inside' : 'outside'}
      isCentered={isLargerThan900}
    >
      <ModalContent
        margin={0}
        maxW={isLargerThan900 ? '650' : undefined}
        borderRadius={isLargerThan900 ? 'md' : 0}
        bg="gray.50"
      >
        <ModalHeader
          color="primary.500"
          mt={isLargerThan900 ? undefined : 12}
          mb={isLargerThan900 ? undefined : 8}
        >
          Selecione o período
        </ModalHeader>
        <ModalCloseButton
          mt={isLargerThan900 ? undefined : 14}
          mr={isLargerThan900 ? undefined : 6}
          _focus={{ border: 'none' }}
        />
        <ModalBody py={0}>
          <Flex justifyContent="center">
            <Stack
              direction={{ base: 'column', sm: 'row' }}
              justifyContent={{ base: 'center', sm: 'flex-end' }}
              alignItems="center"
              spacing={{ base: '4', sm: '6' }}
              mb="4"
              w={{ base: 'full', sm: '575px' }}
            >
              <InputDate
                id="dateInitial"
                autoFocus
                value={selection.startDate}
                maxDate={new Date(valueMaxDate)}
                onChange={(newStartDate) => {
                  handleSetSelection({
                    ...selection,
                    startDate: setDateMinHours(newStartDate),
                    isDateStart: true,
                  });
                }}
                w="full"
                maxW={{ base: '350px', sm: '164px' }}
                typeOfFormattedDate="onlyDate"
              />
              <InputDate
                value={selection.endDate}
                minDate={selection.startDate}
                onChange={(newEndDate) => {
                  const sixMonthsAfterStartDate = new Date(selection.startDate);
                  sixMonthsAfterStartDate.setMonth(
                    sixMonthsAfterStartDate.getMonth() + amountMonths
                  );

                  let correctedEndDate = newEndDate;

                  if (newEndDate > sixMonthsAfterStartDate) {
                    correctedEndDate = new Date(selection.startDate);
                  }

                  setSelection((prev) => ({
                    ...prev,
                    endDate: setDateMaxHours(correctedEndDate),
                  }));

                  if (
                    correctedEndDate.getDate() !==
                      selection.endDate.getDate() ||
                    correctedEndDate.getMonth() !==
                      selection.endDate.getMonth() ||
                    correctedEndDate.getFullYear() !==
                      selection.endDate.getFullYear()
                  ) {
                    buttonConfirm.current?.focus();
                  }
                }}
                w="full"
                maxW={{ base: '350px', sm: '164px' }}
                typeOfFormattedDate="onlyDate"
              />
            </Stack>
          </Flex>

          <Flex
            h="full"
            alignItems={isLessThan700 ? 'center' : ''}
            justifyContent="center"
            sx={{
              '& .rdrMonthName': {
                color: primary500,
              },
              '& .rdrDateDisplayWrapper, & .rdrStaticRange, & .rdrDefinedRangesWrapper': {
                bg: gray50,
                borderColor: gray100,
              },
              '& .rdrDayToday > .rdrDayNumber > span::after': {
                bg: primary500,
              },
              '.rdrDefinedRangesWrapper': {
                display: isLessThan700 && 'none',
              },
            }}
          >
            <DateRangePicker
              onChange={(range: any) => {
                if (range && range.selection) {
                  handleSetSelection(range.selection as Selection);
                }
              }}
              months={1}
              minDate={valueMinDate}
              maxDate={valueMaxDate}
              locale={lng === 'pt' ? pt : undefined}
              direction="vertical"
              ranges={[selectionRange]}
              scroll={{ enabled: true }}
              staticRanges={defaultStaticRanges.map((obj) => {
                const newObj = { ...obj, label: t(obj.label) };

                return newObj;
              })}
              inputRanges={defaultInputRanges.map((obj: any) => {
                return obj;
              })}
              rangeColors={primary500}
              color={primary500}
              showDateDisplay={false}
            />
          </Flex>
        </ModalBody>
        <ModalFooter
          justifyContent="center"
          borderTop="1px"
          borderColor="gray.100"
          mx={8}
        >
          <HStack spacing={6}>
            <Button
              onBlur={() => {
                document.getElementById('dateInitial')?.focus();
              }}
              variant="outline"
              size="sm"
              minW="100px"
              color="gray.400"
              onClick={() => {
                handleSetSelection({
                  endDate: new Date(),
                  startDate: new Date(),
                });
                document.getElementById('dateInitial')?.focus();
              }}
            >
              {cleanFiltersButtonText || 'Limpar'}
            </Button>
            <Button
              ref={buttonConfirm}
              variant="solid"
              colorScheme="secondary"
              size="sm"
              minW="100px"
              onClick={handleOnSubmit}
            >
              Confirmar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export default ModalDateRange;
