import { useState } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  ModalFooter,
  Box,
  ModalHeader,
  useMediaQuery,
  Divider,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { enumIdentificacaoSituacao } from 'constants/enum/enumIdentificacaoSituacao';

import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import Input from 'components/PDV/Input';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { ButtonDefault } from 'components/Button/ButtonChakra';

import { yupResolver } from './validationForms';

type FormData = {
  operacaoSistema: number;
  situacaoSite: SituacaoResponseProps;
};

type SituacaoResponseProps = {
  id: string;
  nome: string;
  situacaoSistema: null | number;
  padraoSistema?: boolean;
  integracaoSituacaoId: string;
};

type ModalValidarSituacaoProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<FormData> & {
    situacao: SituacaoResponseProps[];
    trayId: string;
    podeCancelar: boolean;
    atualizarSituacoes: (situacaoAlterada: SituacaoResponseProps) => void;
    voltarAoPainel?: () => void;
  };

export const ModalAlterarStatusTray = create<
  ModalValidarSituacaoProps,
  FormData
>(
  ({
    onResolve,
    onReject,
    trayId,
    podeCancelar,
    situacao,
    atualizarSituacoes,
    voltarAoPainel,
    ...rest
  }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [numeroSituacao, setNumeroSituacao] = useState<number>(0);

    const numeroDeSituacoesNaoCadastradas = situacao?.length;
    const possuiMaisDeUmaSituacao = numeroDeSituacoesNaoCadastradas > 1;
    const naoPossuiProximaSituacao =
      numeroSituacao === numeroDeSituacoesNaoCadastradas - 1;

    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    const formMethods = useForm<FormData>({
      resolver: yupResolver,
    });

    const { handleSubmit: onSubmit, watch } = formMethods;
    const fluxoWatch = watch('operacaoSistema');

    const handleSubmit = onSubmit(async (formData) => {
      setIsLoading(true);

      const data: SituacaoResponseProps = {
        id: situacao[numeroSituacao].id,
        nome: situacao[numeroSituacao].nome,
        situacaoSistema: formData.operacaoSistema,
        integracaoSituacaoId: situacao[numeroSituacao].integracaoSituacaoId,
      };

      const response = await api.post<void, ResponseApi>(
        `${ConstanteEnderecoWebservice.INTEGRACAO_TRAY_SITUACAO}/${trayId}`,
        [data]
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }

        if (response.sucesso) {
          atualizarSituacoes(data);
          toast.success('Situação vinculada com sucesso');

          if (naoPossuiProximaSituacao) {
            onClose();
          } else {
            setNumeroSituacao((prev) => prev + 1);
          }
          setIsLoading(false);
        }
      }
      setIsLoading(false);
    });

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? '3xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={podeCancelar}
        closeOnEsc={podeCancelar}
        autoFocus={false}
      >
        <ModalContent
          bg="gray.50"
          borderRadius={isLargerThan900 ? 'md' : '0'}
          marginBottom={isLargerThan900 ? '3.75rem' : '0'}
          marginTop={isLargerThan900 ? '3.75rem' : '0'}
          w={isLargerThan900 ? '640px' : 'full'}
          h={isLargerThan900 ? '422px' : 'full'}
        >
          <ModalHeader py="24px" pl="40px">
            <Flex justify="flex-start" w="full" align="baseline">
              <Text color="primary.50" fontSize="16px" fontWeight="bold">
                {podeCancelar
                  ? 'Alterar STATUS cadastrado na Tray'
                  : 'Novo STATUS cadastrado na Tray'}
              </Text>
              {numeroDeSituacoesNaoCadastradas > 1 && !podeCancelar && (
                <Text
                  color="gray.700"
                  fontSize="16px"
                  justifySelf="flex-end"
                  ml="auto"
                >
                  {' '}
                  Cadastrando
                  <Text
                    as="span"
                    mx="2px"
                    width="14px"
                    display="inline-block"
                    textAlign="center"
                    bg="secondary.300"
                  >
                    {' '}
                    {numeroSituacao + 1}{' '}
                  </Text>
                  de {numeroDeSituacoesNaoCadastradas}
                </Text>
              )}
            </Flex>

            {!podeCancelar && (
              <Text color="gray.700" fontSize="14px" mt="4px" mb="12px">
                Identifique para qual fluxo operacional a nova situação
                corresponde.
              </Text>
            )}
            <Divider
              borderColor="gray.200"
              mt={podeCancelar ? '18px' : '12px'}
            />
          </ModalHeader>
          <FormProvider {...formMethods}>
            {isLoading && <LoadingPadrao />}
            <ModalBody
              px="40px"
              mb={podeCancelar ? '27px' : '20px'}
              pt="0"
              overflowY="auto"
              pb="0"
            >
              <Flex direction="column" gap="20px" w="full">
                <Box
                  mr={['0', '0', '24px']}
                  mb={['30px', '24px', '0']}
                  width="full"
                >
                  <Input
                    label="Situação utilizada na Tray"
                    id="situacaoSite"
                    isDisabled
                    paddingLeft="24px"
                    _disabled={{ bgColor: 'gray.100', borderColor: 'gray.200' }}
                    height="56px"
                    defaultValue={situacao[numeroSituacao]?.nome?.toUpperCase()}
                    name="situacaoSite"
                  />
                </Box>
                <Box width="full">
                  <SelectPadrao
                    name="operacaoSistema"
                    placeholder="Selecione uma opção: Pedido, Venda ou Cancelamento"
                    label={
                      podeCancelar
                        ? 'Alterar para:'
                        : 'Identifique o fluxo operacional:'
                    }
                    id="operacaoSistema"
                    options={enumIdentificacaoSituacao.options}
                  />
                </Box>
              </Flex>
            </ModalBody>
            <ModalFooter flexDirection="column" pb="40px">
              <Flex
                w="full"
                h="full"
                justifyContent="center"
                alignItems="baseline"
                gap="24px"
              >
                <Button
                  variant="outlineDefault"
                  colorScheme="gray"
                  onClick={() => {
                    if (podeCancelar) {
                      onClose();
                    } else if (voltarAoPainel) {
                      voltarAoPainel();
                      onClose();
                    }
                  }}
                  w={podeCancelar ? '96px' : 'fit-content'}
                  height="32px"
                  fontSize="14px"
                >
                  {podeCancelar ? 'Cancelar' : 'Voltar ao painel'}
                </Button>
                <ButtonDefault
                  variant="solid"
                  colorScheme={fluxoWatch ? 'secondary.400' : 'gray'}
                  width={possuiMaisDeUmaSituacao ? '240px' : '160px'}
                  fontSize="14px"
                  color={fluxoWatch ? 'black' : 'white'}
                  fontWeight="500"
                  height="32px"
                  isDisabled={!fluxoWatch}
                  onClick={() => handleSubmit()}
                  isLoading={isLoading}
                >
                  {possuiMaisDeUmaSituacao && !naoPossuiProximaSituacao
                    ? 'Salvar e ir para a próxima'
                    : 'Salvar'}
                </ButtonDefault>
              </Flex>
            </ModalFooter>
          </FormProvider>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
