import React from 'react';
import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

export const Link = ({
  children,
  href,
  isExternal,
  ...rest
}: ChakraLinkProps) => {
  if (isExternal) {
    <ChakraLink href={href} isExternal {...rest}>
      {children}
    </ChakraLink>;
  }

  return (
    <ChakraLink as={RouterLink} to={href} {...rest}>
      {children}
    </ChakraLink>
  );
};
