import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react';

import { MeioPagamento } from 'constants/enum/fiscal/meioPagamento';

import { Parcela } from 'pages/PDV/Pagamento/SelecaoFormaPagamento/ListarParcelas';

interface FormaPagamento {
  id: string;
  nome: string;
  regraMeioPagamento: MeioPagamento;
}

type SelecaoFormaPagamentoStep =
  | 'selecionandoFormaPagamento'
  | 'selecionandoParcela'
  | 'informandoValor';

interface SelecaoFormaPagamentoContextProps {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;

  formaPagamentoStep: SelecaoFormaPagamentoStep;
  handleSelecionarFormaPagamento: (newFormaPagamento?: FormaPagamento) => void;
  handleSelecionarParcela: (newParcela?: Parcela) => void;
  handleChangeStep: (nextStep: SelecaoFormaPagamentoStep) => void;
  formaPagamento?: FormaPagamento;
  parcela?: Parcela;
}

export const SelecaoFormaPagamentoContext = createContext<SelecaoFormaPagamentoContextProps>(
  {} as SelecaoFormaPagamentoContextProps
);

interface SelecaoFormaPagamentoProviderProps {
  children: React.ReactNode;
  onModalAdicionarPagamentoClose: () => void;
}

export default function SelecaoFormaPagamentoProvider({
  children,
  onModalAdicionarPagamentoClose,
}: SelecaoFormaPagamentoProviderProps): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);

  const [
    formaPagamentoStep,
    setFormaPagamentoStep,
  ] = useState<SelecaoFormaPagamentoStep>('selecionandoFormaPagamento');
  const [formaPagamento, setFormaPagamento] = useState<FormaPagamento>();
  const [parcela, setParcela] = useState<Parcela>();

  const handleChangeStep = useCallback(
    (nextStep: SelecaoFormaPagamentoStep) => {
      setFormaPagamentoStep(nextStep);
    },
    []
  );

  const handleSelecionarFormaPagamento = useCallback(
    (newFormaPagamento?: FormaPagamento) => {
      setFormaPagamento(newFormaPagamento);

      if (newFormaPagamento) {
        handleChangeStep('selecionandoParcela');
      } else {
        onModalAdicionarPagamentoClose();
      }
    },
    [handleChangeStep, onModalAdicionarPagamentoClose]
  );

  const handleSelecionarParcela = useCallback(
    (newParcela?: Parcela) => {
      setParcela(newParcela);

      if (newParcela) {
        handleChangeStep('informandoValor');
      }
    },
    [handleChangeStep]
  );

  useEffect(() => {
    if (
      formaPagamentoStep !== 'selecionandoFormaPagamento' &&
      !formaPagamento
    ) {
      setFormaPagamentoStep('selecionandoFormaPagamento');
      setParcela(undefined);
    }
  }, [formaPagamento, formaPagamentoStep]);

  return (
    <SelecaoFormaPagamentoContext.Provider
      value={{
        isLoading,
        setIsLoading,
        formaPagamentoStep,
        handleSelecionarFormaPagamento,
        handleSelecionarParcela,
        handleChangeStep,
        formaPagamento,
        parcela,
      }}
    >
      {children}
    </SelecaoFormaPagamentoContext.Provider>
  );
}

export function useSelecaoFormaPagamentoContext(): SelecaoFormaPagamentoContextProps {
  const context = useContext(SelecaoFormaPagamentoContext);

  if (!context)
    throw new Error(
      'useSelecaoFormaPagamentoContext must be used within a SelecaoFormaPagamentoProvider.'
    );

  return context;
}
