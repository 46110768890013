import { useState, useRef, useEffect, useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Flex, GridItem, Text, Grid, useMediaQuery } from '@chakra-ui/react';

import getMaxDataNascimento from 'helpers/data/getMaxDataNascimento';
import getMinDataNascimento from 'helpers/data/getMinDataNascimento';
import GenerosEnum from 'constants/enum/generos';
import enumTipoUsuario from 'constants/enum/enumTipoUsuario';
import TelaInicialAposLogin from 'constants/enum/enumTelaInicialAposLogin';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';
import OptionType from 'types/optionType';

import InputTelefone from 'components/PDV/InputTelefonePdv';
import Input from 'components/PDV/Input';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import ImagePickerPadrao from 'components/ImagePicker/ImagePickerPadrao';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import CheckboxAtivoInativo from 'components/update/Checkbox/CheckboxAtivoInativo';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import InputSenhaUsuario from 'components/Input/InputSenhaUsuario';
import { NumberInput } from 'components/update/Input/NumberInput';
import { ModalAlterarSenha } from 'pages/Vendas/SmartPos/Listar/Components/Configuracao/ModalAlterarSenha';

import { ModalExplicacaoSenhaNumerica } from './components/ModalExplicacaoSenhaNumerica';

import { LojasProps } from '../../Types';

interface DadosGeraisInterface {
  readonly?: boolean;
  isLoading: boolean;
  isAlteracao?: boolean;
  isAdministrador?: boolean;
  isLicencaAtiva?: boolean;
  lojas?: LojasProps[];
}

const DadosGerais = ({
  readonly,
  isLoading: isLoadingParent,
  isAlteracao,
  isAdministrador,
  isLicencaAtiva,
  lojas,
}: DadosGeraisInterface) => {
  const [alterarSenha, setAlterarSenha] = useState(!isAlteracao);
  const [usuarioSemLicenca, setUsuarioSemLicenca] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const podeAlterarStatus = isLicencaAtiva || !usuarioSemLicenca;

  const imageRef = useRef<HTMLHeadingElement>(null);

  const isLargerThan800 = useMediaQuery('(min-width: 800px)');
  const [isLargerThan1200] = useMediaQuery('(max-width: 1200px)');

  const {
    formState: { errors },
    setValue,
    clearErrors,
  } = useFormContext();

  const [
    listLojasSelecionadasWatch,
    pinCadastradoWatch,
    valueIdLojaPadraoWatch,
  ] = useWatch({
    name: ['lojaSelecionada.', 'pinCadastrado', 'valueIdLojaPadrao'],
  });

  const isAlteracaoOuReadOnly = isAlteracao && !readonly;

  const listLojasSelecionadas = (lojas || []).filter((loja) => {
    const idsLojasSelecionadas = Object?.keys(
      listLojasSelecionadasWatch || []
    )?.filter((key) => listLojasSelecionadasWatch[key]);

    return idsLojasSelecionadas?.includes(loja.id);
  });

  useEffect(() => {
    if (listLojasSelecionadas?.length === 0 || !listLojasSelecionadas[0]?.id) {
      setValue('lojaPadraoId', null);
      return;
    }

    if (listLojasSelecionadas && valueIdLojaPadraoWatch) {
      const possuiLojaPadrao = (listLojasSelecionadas || []).some(
        (loja) => loja.id === valueIdLojaPadraoWatch
      );

      if (possuiLojaPadrao) {
        setValue('lojaPadraoId', valueIdLojaPadraoWatch);
        return;
      }

      setValue('valueIdLojaPadrao', listLojasSelecionadas[0].id);
    }
  }, [listLojasSelecionadas, setValue, valueIdLojaPadraoWatch]);

  const alterarPin = useCallback(
    (pin: string) => {
      setValue('pinUsuario', pin);
      setValue('pinCadastrado', true);
    },
    [setValue]
  );

  const handleAbrirModalAlterarSenha = () => {
    ModalAlterarSenha({ alterarSenhaAdm: alterarPin, numeroDeCamposDoPin: 4 });
  };

  const handleAbrirModalExplicacaoSenhaNumerica = async () => {
    try {
      setIsLoading(true);
      const success = await ModalExplicacaoSenhaNumerica();
      if (success) {
        handleAbrirModalAlterarSenha();
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const tratarDadosApi = useCallback(async (response) => {
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }

      if (response?.sucesso && response.dados) {
        return response.dados;
      }
    }
    return null;
  }, []);

  const getQntLicencas = useCallback(async () => {
    const response = await api.get<void, ResponseApi<number>>(
      ConstanteEnderecoWebservice.USUARIO_OBTER_QUANTIDADE_LICENCAS
    );

    return tratarDadosApi(response);
  }, [tratarDadosApi]);

  const getQntLicencasAtivas = useCallback(async () => {
    const response = await api.get<void, ResponseApi<number>>(
      ConstanteEnderecoWebservice.USUARIO_OBTER_QUANTIDADE_USUARIOS_ATIVOS
    );

    return tratarDadosApi(response);
  }, [tratarDadosApi]);

  const getInfoLicenca = useCallback(async () => {
    setIsLoading(true);
    const [responseLicencas, responseLicencasAtivas] = await Promise.all([
      getQntLicencas(),
      getQntLicencasAtivas(),
    ]);
    if (responseLicencas && responseLicencasAtivas) {
      setUsuarioSemLicenca(responseLicencas === responseLicencasAtivas);
    }
    setIsLoading(false);
  }, [getQntLicencas, getQntLicencasAtivas]);

  useEffect(() => {
    getInfoLicenca();
  }, [getInfoLicenca]);

  return (
    <>
      {(isLoading || isLoadingParent) && <LoadingPadrao />}
      <Flex direction="column" gap="28px">
        <Grid
          columnGap={['0', '24px']}
          rowGap="28px"
          height="min-content"
          templateColumns={
            isLargerThan800
              ? 'repeat(12, 1fr)'
              : '192px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr'
          }
        >
          <GridItem
            rowSpan={[2, 2, 3, 4, 4]}
            colSpan={[12, 1, 1]}
            height="full"
            ref={imageRef}
          >
            <ImagePickerPadrao
              id="foto"
              name="foto"
              label="Imagem"
              fontWeightLabel="bold"
              required={false}
              background="white"
              h={{
                base: `${
                  imageRef.current ? imageRef.current.offsetWidth : 0
                }px`,
                sm: '112px',
                md: '192px',
                lg: '272px',
              }}
              w={{ base: 'full', sm: '112px', md: '192px', lg: '272px' }}
              error={errors.foto}
              readonly={readonly}
            />
          </GridItem>
          <GridItem colSpan={[12, 11, 11, 8, 8]}>
            <Input
              type="text"
              id="nome"
              name="nome"
              label="Nome"
              fontWeightLabel="bold"
              autoFocus={!isLoadingParent}
              placeholder="Informe o nome completo do usuário"
              maxLength={120}
              size="sm"
              isRequired
              isDisabled={readonly}
            />
          </GridItem>

          <GridItem colSpan={[12, 11, 4, 3, 3]}>
            <InputTelefone
              id="telefone"
              isDisabled={readonly}
              name="telefone"
              label="Telefone"
              fontWeightLabel="bold"
              size="sm"
              placeholder="(00) 0000-0000"
            />
          </GridItem>

          <GridItem colSpan={[12, 12, 7, 5, 5]}>
            <Input
              type="text"
              id="email"
              name="email"
              label="E-mail"
              fontWeightLabel="bold"
              placeholder="Digite um e-mail"
              maxLength={100}
              size="sm"
              isRequired
              isDisabled={readonly || isAdministrador}
            />
          </GridItem>

          <GridItem colSpan={[12, 7, 4, 3, 3]}>
            <SelectPadrao
              id="genero"
              name="genero"
              label="Gênero"
              placeholder="Selecione"
              fontWeightLabel="bold"
              isDisabled={readonly}
              options={Object.entries(GenerosEnum.properties).map(
                (value: any) => {
                  return {
                    label: value[1].name,
                    value: value[1].value,
                  };
                }
              )}
              size="sm"
            />
          </GridItem>
          <GridItem colSpan={[12, 5, 7, 3, 3]}>
            <Input
              type="date"
              id="dataNascimento"
              name="dataNascimento"
              label="Data de nascimento"
              fontWeightLabel="bold"
              max={getMaxDataNascimento()}
              min={getMinDataNascimento()}
              isDisabled={readonly}
              size="sm"
            />
          </GridItem>
          <GridItem colSpan={[12, 7, 5, 8, 8]}>
            <SelectPadrao
              id="lojaPadraoId"
              name="lojaPadraoId"
              label="Loja padrão para login"
              placeholder="Selecione"
              isDisabled={readonly}
              required
              fontWeightLabel="bold"
              onSelect={(newLojaPadrao) => {
                setValue(
                  'valueIdLojaPadrao',
                  (newLojaPadrao as OptionType)?.value
                );
              }}
              size="sm"
              options={listLojasSelecionadas?.map((lojaItem) => {
                return {
                  label: lojaItem.fantasia,
                  value: lojaItem.id,
                };
              })}
            />
          </GridItem>

          <GridItem colSpan={[12, 5, 7, 3, 3]}>
            <SelectPadrao
              id="abrirPdvAposLogin"
              name="abrirPdvAposLogin"
              label="Tela inicial após login"
              placeholder="Selecione"
              isDisabled={readonly}
              fontWeightLabel="bold"
              size="sm"
              options={Object.entries(TelaInicialAposLogin.properties).map(
                (value) => {
                  return {
                    label: value[1].name,
                    value: value[1].value,
                  };
                }
              )}
            />
          </GridItem>
          <GridItem colSpan={[12, 12, 5, 5, 5]}>
            <SelectPadrao
              id="tipoUsuario"
              name="tipoUsuario"
              label="Tipo de acesso ao sistema"
              size="sm"
              placeholder="Selecione"
              fontWeightLabel="bold"
              isDisabled={readonly}
              valueDefault={enumTipoUsuario.SISTEMA}
              options={enumTipoUsuario.options}
            />
          </GridItem>

          <GridItem colSpan={[12, 4, 4, 3, 3]}>
            <NumberInput
              id="descontoMaximoPermitido"
              name="descontoMaximoPermitido"
              label="Desconto máximo"
              fontWeightLabel="bold"
              placeholder="Digite um desconto máximo permitido para o usuário"
              precision={5}
              bgLeftElement="gray.50"
              leftElement="%"
              editarFundoLeftElemento
              leftElementHeight="30px"
              leftElementFontSize="xs"
              leftElementColor="gray.700"
              size="sm"
              max={100}
              helperText="O valor informado servirá como limite máximo de desconto que esse usuário poderá aplicar no momento da venda. Ao manter o valor zerado, nenhum valor será considerado como limite."
              isDisabled={readonly}
            />
          </GridItem>
          <GridItem colSpan={[12, 3, 3, 3, 3]}>
            <CheckboxAtivoInativo
              isRequired={false}
              id="ativo"
              name="ativo"
              label="Status"
              fontWeightLabel="bold"
              alignItems="flex-start"
              boxSize="6"
              size="sm"
              width="152px"
              isDisabled={readonly || isAdministrador || !podeAlterarStatus}
              textInativo="SEM LICENÇA"
            />
          </GridItem>
        </Grid>

        <Grid
          columnGap={['0px', '24px']}
          rowGap="28px"
          height="min-content"
          templateColumns="272px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"
        >
          <GridItem colSpan={[12, 12, 4, 9, 9]}>
            <Text
              letterSpacing="0"
              fontSize="sm"
              fontWeight="bold"
              userSelect="none"
            >
              Acesso ao sistema
            </Text>
            <Grid
              gap="24px"
              bg="gray.50"
              p="24px"
              pb="32px"
              borderWidth="1px"
              borderRadius="5px"
              borderColor="gray.200"
              templateColumns={`repeat(${isLargerThan1200 ? 4 : 3},1fr)`}
            >
              <GridItem colSpan={[4, 4, 4, 1, 1]}>
                <Input
                  type="text"
                  id="login"
                  name="login"
                  label="Login de usuário"
                  fontWeightLabel="bold"
                  placeholder="Digite o login de acesso"
                  maxLength={50}
                  size="sm"
                  isRequired
                  isDisabled={readonly}
                  autoComplete="off"
                />
              </GridItem>

              <GridItem colSpan={[4, 2, 2, 1, 1]}>
                <InputSenhaUsuario
                  name="senha"
                  label="Senha"
                  placeholder={
                    readonly || !alterarSenha ? '********' : 'Digite uma senha'
                  }
                  colorSchemeLinkText="purple"
                  actionLinkText={
                    isAlteracaoOuReadOnly
                      ? alterarSenha
                        ? 'Não alterar senha'
                        : 'Alterar senha'
                      : ''
                  }
                  actionLinkOnClick={() => {
                    clearErrors('senha');
                    setValue('senha', '');

                    clearErrors('confirmesenha');
                    setValue('confirmesenha', '');

                    setAlterarSenha(!alterarSenha);
                  }}
                  size="sm"
                  isDisabled={readonly || (isAlteracao && !alterarSenha)}
                />
              </GridItem>

              <GridItem
                colSpan={[4, 2, 2, 1, 1]}
                sx={{
                  '.chakra-form__error-message': {
                    display: errors?.senha ? 'none' : 'block',
                  },
                }}
              >
                <InputSenhaUsuario
                  name="confirmesenha"
                  label="Confirme a senha"
                  placeholder={
                    readonly || !alterarSenha
                      ? '********'
                      : 'Digite novamente a senha'
                  }
                  colorSchemeLinkText="purple"
                  size="sm"
                  isDisabled={readonly || (isAlteracao && !alterarSenha)}
                />
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem colSpan={[12, 12, 8, 3, 3]} height="full">
            <Text letterSpacing="0" fontSize="sm" fontWeight="bold">
              Senha Numérica
            </Text>
            {pinCadastradoWatch ? (
              <SimpleGridForm
                gridGap="24px"
                p="24px"
                pb="32px"
                columns={1}
                bg="gray.50"
                borderWidth="1px"
                borderRadius="5px"
                borderColor="gray.200"
              >
                <Input
                  actionLinkText="Alterar senha"
                  actionLinkOnClick={() => handleAbrirModalAlterarSenha()}
                  name="pinUsuario"
                  label="Senha"
                  placeholder="*****"
                  colorSchemeLinkText="purple"
                  fontWeightLabel="bold"
                  isPassword
                  bg="gray.50"
                  size="sm"
                  isDisabled
                  inputRightElement
                />
              </SimpleGridForm>
            ) : (
              <Flex
                h="calc(100% - 21px)"
                minH="108px"
                bg="gray.50"
                borderWidth="1px"
                borderRadius="5px"
                pl={['40px', '40px', '0']}
                borderColor="gray.200"
                alignItems="center"
                justifyContent={['flex-start', 'flex-start', 'center']}
              >
                <Text
                  fontSize="sm"
                  color="blue.500"
                  onClick={handleAbrirModalExplicacaoSenhaNumerica}
                  cursor="pointer"
                >
                  Cadastrar senha numérica
                </Text>
              </Flex>
            )}
          </GridItem>
        </Grid>
      </Flex>
    </>
  );
};

export default DadosGerais;
