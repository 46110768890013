export type LogAuditoriaTela =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23
  | 24;

const LogAuditoriaTelaEnum = {
  TODAS: 0,
  VENDEDOR: 1,
  PRODUTO: 2,
  CATEGORIA_CLIENTE: 3,
  TRANSPORTADORA: 4,
  COR: 5,
  TAMANHO: 6,
  UNIDADE_MEDIDA: 7,
  MARCA: 8,
  CLIENTE: 9,
  FORNECEDOR: 10,
  CAMPO_PERSONALIZADO: 11,
  CATEGORIA_PRODUTO: 12,
  FORMA_PAGAMENTO: 13,
  NOTA_FISCAL: 14,
  PERFIL_USUARIO: 15,
  USUARIO: 16,
  PLANO_CONTA: 17,
  PADRONIZACAO: 18,
  LOJA: 19,
  PDV: 20,
  EXPORTACAO_FISCAL: 21,
  CONTA_FINANCEIRA: 22,
  IMPORTAR_CLIENTE: 23,
  REGRA_FISCAL: 24,
  CONTROLE_CAIXA: 41,

  properties: {
    0: { name: 'Todas', value: 0 },
    1: { name: 'Vendedor', value: 1 },
    2: { name: 'Produto', value: 2 },
    3: { name: 'Categoria de Cliente', value: 3 },
    4: { name: 'Transportadora', value: 4 },
    5: { name: 'Cor', value: 5 },
    6: { name: 'Tamanho', value: 6 },
    7: { name: 'Unidade de Medida', value: 7 },
    8: { name: 'Marca', value: 8 },
    9: { name: 'Cliente', value: 9 },
    10: { name: 'Fornecedor', value: 10 },
    11: { name: 'Campo Personalizado', value: 11 },
    12: { name: 'Categoria de Produto', value: 12 },
    13: { name: 'Forma de Pagamento', value: 13 },
    14: { name: 'Nota Fiscal', value: 14 },
    15: { name: 'Perfil de Usuário', value: 15 },
    16: { name: 'Usuário', value: 16 },
    17: { name: 'Plano de Conta', value: 17 },
    18: { name: 'Padronização', value: 18 },
    19: { name: 'Loja', value: 19 },
    20: { name: 'PDV', value: 20 },
    21: { name: 'Exportação Fiscal', value: 21 },
    22: { name: 'Conta Financeira', value: 22 },
    23: { name: 'Importar Cliente', value: 23 },
    24: { name: 'Regra Fiscal', value: 24 },
    41: { name: 'Controle de Caixa', value: 41 },
  },
};

export default LogAuditoriaTelaEnum;
