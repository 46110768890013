import { VStack, Text, Box, Link } from '@chakra-ui/react';
import { useImperativeHandle, useCallback } from 'react';

import { IdentificacaoEtapasTray } from 'constants/enum/IdentificacaoEtapasTray';
import { useTrayEtapasContext } from 'store/Tray';

import { MenuIntegracoes } from 'icons';

import { Header } from '../Layout/Header';

export const GuiaIntegracao = () => {
  const { ref, setActiveStep } = useTrayEtapasContext();

  const handleAvancar = useCallback(() => {
    setActiveStep(IdentificacaoEtapasTray.PORTAL_CLIENTE);
  }, [setActiveStep]);

  useImperativeHandle(ref, () => ({
    handleAvancar,
  }));

  return (
    <>
      <Header title="Guia de integração" icon={MenuIntegracoes} />
      <VStack
        w="full"
        pl={['10px', '10px', '20px']}
        pr={['10px', '10px', '20px']}
        color="primary.50"
        fontSize="14px"
        alignItems="left"
        spacing={['10px', '10px', '24px']}
      >
        <Text textAlign={['center', 'left', 'left']} letterSpacing="0px">
          Siga as instruções do nosso guia de integração para configurar passo a
          passo a sincronização entre sua loja virtual e o sistema.
        </Text>
        <Text textAlign={['center', 'left', 'left']} letterSpacing="0px">
          O primeiro passo é ter um plano contratado na plataforma. Se você já
          tem uma conta na Tray, clique em “iniciar Guia de Integração”.
        </Text>

        <Box>
          <Text textAlign={['center', 'left', 'left']} fontWeight="bold">
            Se você ainda não tem um plano contratado na TRAY
          </Text>
          <Text textAlign={['center', 'left', 'left']} letterSpacing="0px">
            Se você ainda não tem um plano contratado na TRAY É obrigatório
            contratar um plano na plataforma antes de iniciar o guia. A
            integração com o sistema depende de dados personalizados que serão
            encontrados assim que você receber o acesso ao portal do cliente
            Tray. Faça a contratação antes de iniciar o processo.
          </Text>
        </Box>

        <Text
          w="full"
          textAlign={['center', 'left', 'left']}
          letterSpacing="0px"
        >
          Acesse o{' '}
          <Link
            href="https://www.tray.com.br/"
            target="_blank"
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
          >
            site da Tray
          </Link>{' '}
          para conhecer preços e contratar um plano. Depois de contratar,
          retorne até aqui para iniciar a configuração.
        </Text>
      </VStack>
    </>
  );
};
