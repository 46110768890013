import { useCallback } from 'react';

import VirtualizedList from 'components/update/VirtualizedList';

type OptionsProps = { label: string; value: string }[];

type MenuList = {
  isLoading: boolean;
  inputValue: string;
  listOptions: OptionsProps;
  totalRegistros: number;
  children: ChildrenProps[] | ChildrenProps;
  updateOptionsListSelect: (inputValue: string) => Promise<void>;
};

type ChildrenProps = {
  props: {
    value: string;
  };
};

export const MenuList = ({
  isLoading,
  totalRegistros,
  listOptions,
  children,
  inputValue,
  updateOptionsListSelect,
}: MenuList) => {
  const missingOptionsLoad = listOptions.length < totalRegistros;

  const listRenderVirtualized = (options: OptionsProps) => {
    const listInfoSelect = children as ChildrenProps[];

    if (!Array.isArray(listInfoSelect)) {
      return [];
    }

    const newOption = [...listInfoSelect]
      .map((itemChildren) => {
        const itemVirtualized = options?.find(
          (item) => item?.value === itemChildren?.props?.value
        );

        if (itemVirtualized) {
          return itemChildren;
        }

        return undefined;
      })
      .filter(Boolean);

    return newOption;
  };

  const getNewOptions = useCallback(
    async (isObserver: boolean) => {
      if (isObserver && missingOptionsLoad) {
        await updateOptionsListSelect(inputValue);
      }
    },
    [inputValue, missingOptionsLoad, updateOptionsListSelect]
  );

  return (
    <VirtualizedList
      containerHeight={200}
      itemHeight={32}
      sx={{
        borderWidth: '1px',
        borderColor: 'gray.100',
        borderRadius: '6px',
        boxShadow: '0px 0px 6px #00000034',
        maxH: '200px',
        overflowY: 'auto',
        opacity: isLoading && missingOptionsLoad ? 0.5 : 1,
      }}
      colorBgGradient="gray.50"
      handleOnClick={async (_, observer) => {
        await getNewOptions(observer);
      }}
      marginBottom={5}
      items={listOptions}
      quantidadeItems={listOptions?.length}
      key="virtualizedList"
      render={listRenderVirtualized}
    />
  );
};
