import React, { memo } from 'react';
import { Controller } from 'react-hook-form';

import ContainerInputPadrao from 'components/Layout/CampoContainer/CampoContainerPadrao';
import { SwitchContainer, SwitchIcon, HiddenCheckbox } from './styles';

interface CheckBoxDefaultProps {
  id: string;
  name: string;
  label: string;
  control: any;
  readonly?: boolean;
  campoDinamicoId: any;
  defaultValue?: any;
  handleChange?: (id: string) => any;
  className?: string;
}

const CheckBoxDefaultMappedField = ({
  id,
  name,
  label,
  control,
  readonly,
  campoDinamicoId,
  defaultValue,
  handleChange,
  className,
  ...rest
}: CheckBoxDefaultProps) => {
  return (
    <ContainerInputPadrao id={id} label={label} error={undefined}>
      <Controller
        defaultValue={
          defaultValue || {
            id: campoDinamicoId,
            selecionado: false,
          }
        }
        render={({ field }) => (
          <SwitchContainer
            id={id}
            className={className}
            value={field.value?.selecionado}
            style={{
              cursor: readonly ? '' : 'pointer',
            }}
            onClick={() => {
              if (readonly) return;
              field.onChange({
                id: campoDinamicoId,
                selecionado: !field.value?.selecionado,
              });
              if (handleChange) {
                handleChange(id);
              }
            }}
            onChange={(newValue: React.ChangeEvent<HTMLInputElement>) => {
              field.onChange({
                id: campoDinamicoId,
                selecionado: newValue.target.checked,
              });
            }}
            {...rest}
          >
            <SwitchIcon />

            <HiddenCheckbox
              id={`hidden-checkbox-${id}`}
              checked={field.value?.selecionado || false}
              onChange={() => {}}
            />
          </SwitchContainer>
        )}
        name={`${name}` as const}
        control={control}
      />
    </ContainerInputPadrao>
  );
};

export default memo(CheckBoxDefaultMappedField);
