import TipoOperacaoEnum from 'constants/enum/tipoOperacao';

export type ListarHistoricoProdutoProps = {
  operacaoId: string;
  dataHoraEmissao: string;
  tamanho: string;
  identificacaoTipoOperacao: number;
  numeroOperacao: number;
  quantidade: number;
  nomeCliente: string;
  tipoAcaoEstoque: number;
  entradaMercadoriaId: string;
};

type Tamanho = {
  id: string;
  descricao: string;
};

type ProdutoCor = {
  value: string;
  label: string;
  tamanhos: Tamanho[];
};

export type ListSelectProps = {
  id: string;
  nome: string;
  value: string;
  label: string;
};
export type FiltersData = {
  localEstoqueId: string | null;
  produtoCorId: ProdutoCor | null;
  produtoCorTamanhoId: string | null;
  tipoOperacaoEstoque: number;
  dataHoraInicio?: Date;
  dataHoraFim?: Date;
};

export const formDefaultValues = (): FiltersData => ({
  localEstoqueId: null,
  produtoCorId: null,
  produtoCorTamanhoId: null,
  tipoOperacaoEstoque: TipoOperacaoEnum.TODOS,
  dataHoraInicio: undefined,
  dataHoraFim: undefined,
});
