/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import { ButtonProps, Button } from '@chakra-ui/react';

import TipoValorEnum from 'constants/enum/tipoValor';
import CampoPrototipo, { CampoPrototipoProps } from '../Geral/CampoPrototipo';

export type ButtonSelectProps = Omit<ButtonProps, 'children'> &
  Omit<CampoPrototipoProps, 'children'> & {
    widthContainer?: string;
    isRequired?: boolean;
  };

const ButtonItemDesconto = ({
  id,
  name,
  label,
  defaultValue,
  widthContainer = 'full',
  isRequired = false,
  rightIcon,
  ...buttonProps
}: ButtonSelectProps) => {
  return (
    <CampoPrototipo
      id={id}
      name={name}
      label={label}
      defaultValue={defaultValue}
      width={widthContainer}
      isRequired={isRequired}
    >
      {({ setValue }, { value }) => {
        return (
          <Button
            {...buttonProps}
            borderRadius="md"
            _disabled={{ opacity: 0.9 }}
            _hover={{ _disabled: { opacity: 0.9 } }}
            rightIcon={rightIcon}
            onClick={() => {
              setValue(
                `${name}` as const,
                value === TipoValorEnum.REAIS
                  ? TipoValorEnum.PORCENTAGEM
                  : TipoValorEnum.REAIS
              );
            }}
          >
            {value === TipoValorEnum.REAIS
              ? TipoValorEnum.properties[1].name
              : TipoValorEnum.properties[2].name}
          </Button>
        );
      }}
    </CampoPrototipo>
  );
};

export default ButtonItemDesconto;
