import React, { useCallback, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Icon,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
  useMediaQuery,
} from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FiAlertCircle } from 'react-icons/fi';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import { TipoAcaoEnum } from 'constants/enum/tipoAcao';
import { formatDateHourMinute } from 'helpers/format/formatStringDate';
import ConstanteRotas from 'constants/rotas';
import { DecimalMask } from 'helpers/format/fieldsMasks';

import { SimpleCard } from 'components/update/Form/SimpleCard';
import { TextValor } from 'components/PDV/Text/TextValor';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { PagedTable } from 'components/update/Table/PagedTable';
import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import { PaginationData } from 'components/update/Pagination';

import { TableDetalhes } from './TableDetalhesControleCaixa';
import { Movimentacao } from '../validationForms';
import { ModalDetalheEvento } from './ModalDetalheEvento';

type RouteParams = {
  id: string;
};

type CaixaMovimentacoes = {
  dataEmissao: Date;
  tipoMovimentacao: number;
  numeroOperacao: string;
  formaRecebimentoNome: string;
  parcela: string;
  valor: number;
  acaoFinanceira: number;
  cancelada: boolean;
};

type Saidas = {
  formaRecebimentoNome: string;
  valor: number;
};

type CaixaConferencias = {
  formaRecebimentoNome: string;
  formaRecebimentoNomeId: string;
  valor: number;
  valorConferido: number;
  diferenca: number;
  consideraSaldoInicial: boolean;
};

type ControleCaixa = {
  contaFinanceiraNome: string;
  contaFinanceiraIcone: string;
  dataHoraUsuarioAbertura: Date;
  dataHoraUsuarioFechamento: string;
  saldoInicial: number;
  totalEntradas: number;
  totalSaidas: number;
  saldoFinal: number;
  qtdVendasConcluidas: number;
  totalValorVendasConcluidas: number;
  qtdVendasCanceladas: number;
  totalValorDevolucoes: number;
  totalValorTrocas: number;
  totalValorVendasCanceladas: number;
  totalValorSangria: number;
  totalValorSuprimento: number;
  caixaConferencias: CaixaConferencias[];
  caixaMovimentacoes: CaixaMovimentacoes[];
  saidas: Saidas[];
  possuiEventos: boolean;
};

export function ControleCaixaDetalhes() {
  const history = useHistory();
  const { id } = useParams<RouteParams>();

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const [isLargerThan1200] = useMediaQuery('(min-width: 1200px)');

  const [isLoading, setIsLoading] = useState(false);
  const [controleCaixa, setControleCaixa] = useState<ControleCaixa>(
    {} as ControleCaixa
  );
  const isCaixaAberto = !controleCaixa.dataHoraUsuarioFechamento;
  const hasDiferencaValores = (controleCaixa.caixaConferencias || []).some(
    (formaPagamento) => formaPagamento.diferenca > 0
  );

  const [isItemsLoading, setIsItemsLoading] = useState(false);
  const [columnsData, setColumnsData] = useState<Movimentacao[]>([]);
  const [itemsCount, setItemsCount] = useState(0);

  function handleFechar() {
    history.push(ConstanteRotas.CONTROLE_CAIXA);
  }

  const loadColumnsData = useCallback(
    async (paginationData: PaginationData) => {
      setIsItemsLoading(true);

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<Movimentacao>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.CONTROLE_CAIXA_LISTAR_MOVIMENTACAO,
          paginationData,
          { id }
        )
      );

      if (response) {
        if (response.sucesso) {
          setColumnsData(response.dados.registros || []);
          setItemsCount(response.dados.total || 0);
        }
      }

      setIsItemsLoading(false);
    },
    [id]
  );

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const response = await api.get<void, ResponseApi<ControleCaixa>>(
        ConstanteEnderecoWebservice.CONTROLE_CAIXA_DETALHES,
        {
          params: { idCaixaMovimentacao: id },
        }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.map((aviso) => toast.warning(aviso));
        }

        if (response.sucesso && response.dados) {
          setControleCaixa(response.dados);
        }
      }

      setIsLoading(false);
    })();
  }, [id]);

  const listaControCaixa = (controleCaixa.caixaConferencias || []).filter(
    (caixa) => caixa.valor !== 0
  );

  return (
    <SimpleCard p="0">
      {isLoading && <LoadingPadrao />}

      <Flex
        px="6"
        py="5"
        alignItems={{ base: 'flex-start', sm: 'center' }}
        justifyContent="space-between"
        flexDir={{ base: 'column', sm: 'row' }}
      >
        <Flex alignItems="center" mb={{ base: '4', sm: '0' }}>
          <Avatar src={controleCaixa.contaFinanceiraIcone} boxSize="12" />

          <Text fontSize="xl" fontWeight="bold" ml="2.5">
            {controleCaixa.contaFinanceiraNome}
          </Text>
        </Flex>

        <Stack
          direction={{ base: 'column', lg: 'row' }}
          spacing={{ base: '1', lg: '4' }}
          alignItems={{ base: 'flex-end', lg: 'center' }}
        >
          {controleCaixa.dataHoraUsuarioAbertura && (
            <Text fontSize="sm">
              Aberto{' '}
              <Text as="span" color="primary.50">
                {controleCaixa.dataHoraUsuarioAbertura}
              </Text>
            </Text>
          )}

          {controleCaixa.dataHoraUsuarioFechamento &&
            controleCaixa.dataHoraUsuarioFechamento && (
              <>
                {isLargerThan1200 && <Divider orientation="vertical" h="4" />}

                <Text fontSize="sm">
                  Fechado{' '}
                  <Text as="span" color="primary.50">
                    {controleCaixa.dataHoraUsuarioFechamento}
                  </Text>
                </Text>
              </>
            )}
        </Stack>
      </Flex>

      <Stack
        spacing="4"
        direction={{ base: 'column', md: 'row' }}
        justifyContent={{ base: 'center', xl: 'center' }}
        alignItems="center"
        py="7"
        px="8"
        bg="gray.50"
      >
        <Flex
          w="full"
          maxW="280px"
          alignItems="center"
          justifyContent="center"
          flexDir="column"
        >
          <Text>Saldo abertura</Text>
          <TextValor
            casasDecimais={2}
            valor={controleCaixa.saldoInicial}
            color="gray.700"
            fontSize="xl"
            fontWeight="semibold"
            symbolFontSize="xs"
            symbolFontWeight="semibold"
          />
        </Flex>

        <Divider
          orientation={isLargerThan900 ? 'vertical' : 'horizontal'}
          h={{ base: '1px', md: '14' }}
          w={{ base: 'full', md: '1px' }}
        />

        <Flex
          w="full"
          maxW="280px"
          alignItems="center"
          justifyContent="center"
          flexDir="column"
        >
          <Text>Entradas</Text>
          <TextValor
            casasDecimais={2}
            valor={controleCaixa.totalEntradas}
            color="aquamarine.600"
            fontSize="xl"
            fontWeight="semibold"
            symbolFontSize="xs"
            symbolFontWeight="semibold"
          />
        </Flex>

        <Divider
          orientation={isLargerThan900 ? 'vertical' : 'horizontal'}
          h={{ base: '1px', md: '14' }}
          w={{ base: 'full', md: '1px' }}
        />

        <Flex
          w="full"
          maxW="280px"
          alignItems="center"
          justifyContent="center"
          flexDir="column"
        >
          <Text>Saídas</Text>
          <TextValor
            casasDecimais={2}
            valor={controleCaixa.totalSaidas}
            color="red.400"
            fontSize="xl"
            fontWeight="semibold"
            symbolFontSize="xs"
            symbolFontWeight="semibold"
          />
        </Flex>

        <Divider
          orientation={isLargerThan900 ? 'vertical' : 'horizontal'}
          h={{ base: '1px', md: '14' }}
          w={{ base: 'full', md: '1px' }}
        />

        <Flex
          w="full"
          maxW="280px"
          alignItems="center"
          justifyContent="center"
          flexDir="column"
        >
          <Flex>
            <Flex alignItems="baseline">
              <Text>Saldo no caixa</Text>
              {controleCaixa.possuiEventos && (
                <Icon
                  onClick={() =>
                    ModalDetalheEvento({
                      idConta: id,
                    })
                  }
                  cursor="pointer"
                  color="red.500"
                  ml="7px"
                  as={FiAlertCircle}
                />
              )}
            </Flex>
          </Flex>
          <TextValor
            casasDecimais={2}
            valor={
              controleCaixa.saldoInicial +
                controleCaixa.totalEntradas -
                controleCaixa.totalSaidas || 0
            }
            color="blue.700"
            fontSize="xl"
            fontWeight="semibold"
            symbolFontSize="xs"
            symbolFontWeight="semibold"
          />
        </Flex>
      </Stack>

      {!isLoading &&
        (isCaixaAberto || (!isCaixaAberto && hasDiferencaValores)) && (
          <Flex
            alignItems="center"
            py="3"
            px="8"
            bg={isCaixaAberto ? 'secondary.300' : 'red.500'}
            color={isCaixaAberto ? 'primary.700' : 'white'}
          >
            <Icon as={FiAlertCircle} boxSize="4" />

            <Text lineHeight="none" ml="2" fontSize="sm" color="currentColor">
              {isCaixaAberto
                ? 'O caixa está aberto.'
                : 'O caixa foi fechado com diferença de valores. Verifique as formas de pagamento para conferir a diferença.'}
            </Text>
          </Flex>
        )}
      <Box mt="3px">
        {controleCaixa.qtdVendasCanceladas > 0 && (
          <Flex
            alignItems="center"
            justifyContent="left"
            py="3"
            px="8"
            bg="yellow.500"
            color="black"
          >
            <Icon as={FiAlertCircle} boxSize="4" />

            <Text lineHeight="none" ml="2" fontSize="sm" color="currentColor">
              Total de vendas canceladas:{' '}
              {`R$ ${DecimalMask(
                controleCaixa.totalValorVendasCanceladas || 0,
                2,
                2
              )}`}
            </Text>
          </Flex>
        )}
        {controleCaixa.totalValorDevolucoes > 0 && (
          <Flex
            alignItems="center"
            justifyContent="left"
            py="3"
            px="8"
            mt="1"
            bg="orange.100"
            color="black"
          >
            <Icon as={FiAlertCircle} boxSize="4" />

            <Text lineHeight="none" ml="2" fontSize="sm" color="currentColor">
              Total de devoluções:{' '}
              {`R$ ${DecimalMask(
                controleCaixa.totalValorDevolucoes || 0,
                2,
                2
              )}`}
            </Text>
          </Flex>
        )}
        {controleCaixa.totalValorTrocas > 0 && (
          <Flex
            alignItems="center"
            justifyContent="left"
            py="3"
            px="8"
            mt="1"
            bg="blue.100"
            color="black"
          >
            <Icon as={FiAlertCircle} boxSize="4" />

            <Text lineHeight="none" ml="2" fontSize="sm" color="currentColor">
              Total de Trocas:{' '}
              {`R$ ${DecimalMask(controleCaixa.totalValorTrocas || 0, 2, 2)}`}
            </Text>
          </Flex>
        )}

        <Box p="6" mt="5px">
          {listaControCaixa.length === 0 ? (
            <></>
          ) : (
            <>
              <Text
                as="label"
                color={!isCaixaAberto && hasDiferencaValores ? 'red' : 'black'}
                lineHeight="none"
                mb="1"
                fontSize="sm"
              >
                Total por forma de pagamento
              </Text>

              <SimpleGrid
                templateColumns={{
                  base: '1fr',
                  md: 'repeat(2, 1fr)',
                  lg: 'repeat(3, 1fr)',
                }}
                py="4"
                rowGap={4}
                borderRadius="md"
                border="1px"
                borderColor="gray.100"
              >
                {listaControCaixa.map((formaPagamento, index) => {
                  const hasDiferenca = formaPagamento.diferenca !== 0;
                  const columnsCount = isLargerThan1200 ? 3 : 2;
                  const hasDivider =
                    isLargerThan900 && (index + 1) % columnsCount !== 0;

                  return (
                    <Tooltip
                      isDisabled={!hasDiferenca}
                      hasArrow
                      h={{ base: '24', md: '10' }}
                      maxW="none"
                      bg="gray.900"
                      borderRadius="md"
                      placement="bottom"
                      label={
                        <Stack
                          direction={{ base: 'column', md: 'row' }}
                          h="full"
                          alignItems="center"
                          justifyContent="center"
                          spacing={{ base: '2', md: '12' }}
                          px={{ base: '1', sm: '3' }}
                        >
                          <Flex alignItems="center">
                            <Text fontSize="xs" color="white">
                              Valor recebido:
                            </Text>

                            <TextValor
                              casasDecimais={2}
                              valor={formaPagamento.valor}
                              color="secondary.300"
                              fontSize="xs"
                              fontWeight="semibold"
                              symbolFontSize="2xs"
                              symbolFontWeight="semibold"
                              ml="1"
                            />
                          </Flex>

                          <Flex alignItems="center">
                            <Text fontSize="xs" color="white">
                              Valor conferido:
                            </Text>

                            <TextValor
                              casasDecimais={2}
                              valor={formaPagamento.valorConferido}
                              color="secondary.300"
                              fontSize="xs"
                              fontWeight="semibold"
                              symbolFontSize="2xs"
                              symbolFontWeight="semibold"
                              ml="1"
                            />
                          </Flex>

                          <Flex alignItems="center">
                            <Text fontSize="xs" color="white">
                              Diferença:
                            </Text>

                            <TextValor
                              casasDecimais={2}
                              valor={formaPagamento.diferenca}
                              color="secondary.300"
                              fontSize="xs"
                              fontWeight="semibold"
                              symbolFontSize="2xs"
                              symbolFontWeight="semibold"
                              ml="1"
                            />
                          </Flex>
                        </Stack>
                      }
                    >
                      <HStack
                        h="6"
                        justifyContent="space-between"
                        alignItems="center"
                        px={{ base: '4', sm: '10' }}
                        borderRight={hasDivider ? '1px' : 'none'}
                        borderColor="gray.100"
                      >
                        <Box>
                          <Text
                            fontSize="sm"
                            mt={
                              formaPagamento.consideraSaldoInicial
                                ? '10px'
                                : '0'
                            }
                            color={
                              !isCaixaAberto && hasDiferencaValores
                                ? 'red'
                                : 'black'
                            }
                          >
                            {`${formaPagamento.formaRecebimentoNome}${
                              hasDiferenca ? '*' : ''
                            }`}
                          </Text>
                          {formaPagamento.consideraSaldoInicial && (
                            <Text
                              mb="10px"
                              mt="-5px"
                              fontSize="smaller"
                              color="gray.400"
                              fontWeight="semibold"
                            >
                              Saldo abertura + Total de movimentações
                            </Text>
                          )}
                        </Box>

                        <TextValor
                          casasDecimais={2}
                          marginTop={
                            formaPagamento.consideraSaldoInicial ? '10px' : '0'
                          }
                          valor={formaPagamento.valor}
                          color={
                            !isCaixaAberto && hasDiferencaValores
                              ? 'red'
                              : 'black'
                          }
                          fontSize="sm"
                          fontWeight="semibold"
                          symbolFontSize="2xs"
                          symbolFontWeight="semibold"
                        />
                      </HStack>
                    </Tooltip>
                  );
                })}
              </SimpleGrid>
            </>
          )}
          {(controleCaixa.saidas || []).length > 0 && (
            <Box mt="10px">
              <Text
                as="label"
                color="black"
                lineHeight="none"
                mb="1"
                fontSize="sm"
              >
                Saídas
              </Text>

              <SimpleGrid
                templateColumns={{
                  base: '1fr',
                  md: 'repeat(2, 1fr)',
                  lg: 'repeat(3, 1fr)',
                }}
                py="4"
                borderRadius="md"
                border="1px"
                borderColor="gray.100"
              >
                {(controleCaixa.saidas || []).map((saida, index) => {
                  const columnsCount = isLargerThan1200 ? 3 : 2;
                  const hasDivider =
                    isLargerThan900 && (index + 1) % columnsCount !== 0;
                  return (
                    <HStack
                      h="6"
                      justifyContent="space-between"
                      alignItems="center"
                      px={{ base: '4', sm: '10' }}
                      borderRight={hasDivider ? '1px' : 'none'}
                      borderColor="gray.100"
                    >
                      <Box>
                        <Text fontSize="sm">{saida.formaRecebimentoNome}</Text>
                      </Box>

                      <TextValor
                        casasDecimais={2}
                        valor={saida.valor}
                        color="black"
                        fontSize="sm"
                        fontWeight="semibold"
                        symbolFontSize="2xs"
                        symbolFontWeight="semibold"
                      />
                    </HStack>
                  );
                })}
              </SimpleGrid>
            </Box>
          )}

          <Text as="label" lineHeight="none" mb="1" mt="6" fontSize="sm">
            Movimentações
          </Text>

          <Box borderRadius="md" border="1px" borderColor="gray.100">
            <PagedTable
              isLoading={isItemsLoading && !isLoading}
              loadColumnsData={loadColumnsData}
              itemsTotalCount={itemsCount}
              paginationHasDivider={false}
              defaultKeyOrdered="dataEmissao"
              defaultOrderDirection="desc"
              tableHeaders={[
                {
                  key: 'dataEmissao',
                  content: 'Data/Hora',
                },
                {
                  key: 'numero',
                  content: 'Número',
                  isOrderable: false,
                },
                {
                  key: 'tipoOperacao',
                  content: 'Tipo de operação',
                  isOrderable: false,
                },
                {
                  key: 'cliente',
                  content: 'Cliente',
                  isOrderable: false,
                },
                {
                  key: 'formaPagamento',
                  content: 'Forma de pagamento',
                  isOrderable: false,
                },
                {
                  key: 'numeroParcelas',
                  content: 'Nº de parcelas',
                  isOrderable: false,
                  textAlign: 'center',
                },
                {
                  key: 'valor',
                  content: 'Valor',
                  isOrderable: false,
                  isNumeric: true,
                },
              ]}
              renderTableRows={
                columnsData &&
                columnsData.map((columnData) => {
                  const dataEmissao = formatDateHourMinute(
                    columnData.dataEmissao
                  );

                  const isEntrada =
                    columnData.acaoFinanceira === TipoAcaoEnum.ENTRADA;

                  let corLinha = 'gray.700';

                  if (isEntrada && columnData.cancelada) {
                    corLinha = 'red.600';
                  } else if (!isEntrada) {
                    corLinha = 'red.600';
                  }

                  return (
                    <TableDetalhes
                      columnData={columnData}
                      isEntrada={isEntrada}
                      corLinha={corLinha}
                      dataEmissao={dataEmissao}
                    />
                  );
                })
              }
              boxShadow="none"
            />
          </Box>
        </Box>
      </Box>

      <Stack
        direction={{ base: 'column', md: 'row' }}
        justifyContent="center"
        p="6"
        pt="0"
        spacing={{ base: '3', md: '6' }}
      >
        <Button
          size="sm"
          colorScheme="aquamarine"
          w="full"
          maxW={{ base: 'full', md: '96px' }}
          onClick={handleFechar}
        >
          Voltar
        </Button>
      </Stack>
    </SimpleCard>
  );
}
