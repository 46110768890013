const FormasImportacaoEnum = {
  ContaPropria: 1,
  ContaOrdem: 2,
  Encomenda: 3,

  properties: {
    1: { name: 'Conta e ordem', value: 1 },
    2: { name: 'Conta própria', value: 2 },
    3: { name: 'Encomenda', value: 3 },
  },
};

export default FormasImportacaoEnum;
