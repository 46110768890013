import { useCallback, useEffect } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  ModalFooter,
  VStack,
  ModalHeader,
  useMediaQuery,
  Icon,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';
import { FiChevronLeft } from 'react-icons/fi';

import { moneyMask } from 'helpers/format/fieldsMasks';
import formatData from 'helpers/format/formatData';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { ButtonDefault } from 'components/Button/ButtonChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { ParcelaCheckbox } from './components/ParcelaCheckbox';

type Parcelas = {
  dataVencimento: Date;
  descricao: string;
  id: string;
  parcela: string;
  valor: number;
  selecionada?: boolean;
};

type ResponseModal = {
  parcelas: string[];
};

type ModalEscolherParcelasImpressaoCarneProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ResponseModal> & {
    parcelas: Parcelas[];
    isPdv?: boolean;
    isLoading?: boolean;
  };

export const ModalEscolherParcelasImpressaoCarne = create<
  ModalEscolherParcelasImpressaoCarneProps,
  any
>(({ onResolve, onReject, parcelas, isLoading, isPdv, ...rest }) => {
  const { isOpen } = useDisclosure({ defaultIsOpen: true });

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const formMethods = useForm({
    defaultValues: {
      selecionarTodasParcelas: parcelas?.length === 1,
      parcelas: Object.fromEntries(
        parcelas.map((parcela) => [parcela.id, parcelas?.length === 1])
      ),
    },
  });

  const { getValues, setValue, watch } = formMethods;

  const allParcelsSelected = Object.values(watch('parcelas'))?.every(Boolean);
  const selectedParcels = Object.keys(watch('parcelas'))?.filter((id) =>
    watch(`parcelas.${id}`)
  );
  const isSelectAll = getValues('selecionarTodasParcelas');
  const hasSelectedParcelas = selectedParcels?.length > 0;

  const handleToggleSelectAll = useCallback(() => {
    parcelas.forEach((parcela) => {
      setValue(`parcelas.${parcela.id}`, !isSelectAll);
    });
  }, [isSelectAll, parcelas, setValue]);

  const handleToggleSelect = useCallback(
    (id: string, previousValue: boolean) => {
      setValue(`parcelas.${id}`, !previousValue);
    },
    [setValue]
  );

  const handleImprimir = useCallback(() => {
    onResolve({ parcelas: selectedParcels });
  }, [onResolve, selectedParcels]);

  useEffect(() => {
    setValue('selecionarTodasParcelas', allParcelsSelected);
  }, [allParcelsSelected, setValue]);

  return (
    <ModalPadraoChakra
      isCentered={isLargerThan900}
      size={isLargerThan900 ? '3xl' : 'full'}
      {...rest}
      isOpen={isOpen}
      onClose={onReject}
      autoFocus={false}
      hasOverlay={!!isPdv}
      motionPreset={isPdv ? 'scale' : 'none'}
    >
      <ModalContent
        bg="gray.50"
        borderRadius={isLargerThan900 ? 'md' : '0'}
        marginBottom={isLargerThan900 ? '3.75rem' : '0'}
        marginTop={isLargerThan900 ? '3.75rem' : '0'}
        w={['full', 'full', '640px']}
        h={['full', 'full', '480px']}
      >
        <ModalHeader
          pt="16px"
          alignItems="center"
          display="flex"
          pb="28px"
          px={['20px', '20px', '40px']}
        >
          {!isPdv && (
            <Icon
              onClick={() => onReject()}
              cursor="pointer"
              fontSize="25px"
              color="primary.50"
              mr="20px"
              as={FiChevronLeft}
            />
          )}
          <Text color="primary.50" fontSize="20px">
            Selecione as parcelas
          </Text>
        </ModalHeader>

        <FormProvider {...formMethods}>
          <ModalBody
            px={['20px', '20px', '40px']}
            mb="20px"
            py="0"
            height={['calc(100% - 30%)', 'calc(100% - 30%)', 'full']}
          >
            {isLoading && <LoadingPadrao />}
            <VStack
              spacing="4px"
              alignItems="flex-start"
              gap="4px"
              maxHeight={['calc(100% - 60px)', 'calc(100% - 40px)', '300px']}
              minHeight={['calc(100% - 60px)', 'calc(100% - 40px)', '300px']}
              overflow="scroll"
            >
              <ParcelaCheckbox
                name="selecionarTodasParcelas"
                height="40px"
                mb="12px"
                width="full"
                onClick={() => handleToggleSelectAll()}
              >
                <VStack spacing="0px" alignItems="flex-start">
                  <Text
                    fontSize="md"
                    fontWeight="semibold"
                    userSelect="none"
                    letterSpacing="0px"
                  >
                    Selecionar todas as parcelas
                  </Text>
                </VStack>
              </ParcelaCheckbox>

              {(parcelas || []).map((parcela) => {
                const isChecked = watch(`parcelas.${parcela.id}`);

                return (
                  <ParcelaCheckbox
                    name={`parcelas.${parcela.id}`}
                    height={['80px', '65px']}
                    width="full"
                    onClick={() => handleToggleSelect(parcela.id, isChecked)}
                  >
                    <VStack spacing="0px" alignItems="flex-start" w="full">
                      <Flex w="full" align="center">
                        <Text
                          fontSize="xs"
                          fontWeight="bold"
                          userSelect="none"
                          letterSpacing="0px"
                        >
                          {parcela.descricao}
                        </Text>
                      </Flex>
                      <Flex w="full" justify="space-between" align="flex-start">
                        <Text fontSize="xs">
                          {`${formatData(parcela.dataVencimento)} - Parcela: ${
                            parcela.parcela
                          }`}
                        </Text>
                      </Flex>
                    </VStack>
                    <Text
                      fontSize="sm"
                      fontWeight="bold"
                      userSelect="none"
                      letterSpacing="0px"
                      ml="auto"
                      pr="12px"
                    >
                      {moneyMask(parcela.valor, true)}
                    </Text>
                  </ParcelaCheckbox>
                );
              })}
            </VStack>
          </ModalBody>
          <ModalFooter flexDirection="column" mb="40px">
            <Flex
              w="full"
              h="full"
              justifyContent="center"
              alignItems="baseline"
            >
              <Button
                color="gray.300"
                variant="outlineDefault"
                borderRadius="20px"
                borderColor="gray.300"
                fontSize="sm"
                lineHeight="0"
                type="button"
                h={['40px', '40px', '32px']}
                mr="24px"
                onClick={() => onReject()}
                w="96px"
              >
                {isPdv ? 'Fechar' : 'Voltar'}
              </Button>
              <ButtonDefault
                color="black"
                variant="solid"
                colorScheme="secondary"
                lineHeight="0"
                borderRadius="20px"
                fontSize="sm"
                h={['40px', '40px', '32px']}
                width="120px"
                isDisabled={!hasSelectedParcelas}
                onClick={handleImprimir}
              >
                Imprimir
              </ButtonDefault>
            </Flex>
          </ModalFooter>
        </FormProvider>
      </ModalContent>
    </ModalPadraoChakra>
  );
});
