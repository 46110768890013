import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';

export const shapeEditarDimensoesVariacaoModal = {
  largura: yup.number().default(0),
  altura: yup.number().default(0),
  profundidade: yup.number().default(0),
  pesoLiquido: yup.number().default(0),
  pesoBruto: yup.number().default(0),
};

const schemaEditarDimensoesVariacaoModal = yup
  .object()
  .shape(shapeEditarDimensoesVariacaoModal);

export const yupResolver = yupResolverInstance(
  schemaEditarDimensoesVariacaoModal
);

export const defaultValues = schemaEditarDimensoesVariacaoModal.default();

export const useForm = useFormInstance;
