import React, { useMemo } from 'react';
import { Flex, Text, VStack } from '@chakra-ui/react';
import formatUTCToLocateDateTime from 'helpers/format/formatUTCToLocateDateTime';
import statusOperacao from 'constants/enum/statusOperacao';
import identificacaoTipoOperacao from 'constants/enum/identificacaoTipoOperacao';
import { getOptionByEnum } from 'helpers/format/getOptionsByEnum';

interface Vendas {
  numeroOperacao: number;
  dataEmissao: string;
  status: number;
  identificaoOperacao: number;
}

const Vendas = ({
  numeroOperacao,
  dataEmissao,
  status,
  identificaoOperacao,
}: Vendas) => {
  const identificacaoTipoOperacaoLabel = useMemo(() => {
    let newIdentificacaoTipoOperacaoLabel = '';

    switch (identificaoOperacao) {
      case identificacaoTipoOperacao.VENDA:
        newIdentificacaoTipoOperacaoLabel = getOptionByEnum(
          identificacaoTipoOperacao,
          identificacaoTipoOperacao.VENDA
        )?.label;
        break;
      case identificacaoTipoOperacao.PEDIDO:
        newIdentificacaoTipoOperacaoLabel = getOptionByEnum(
          identificacaoTipoOperacao,
          identificacaoTipoOperacao.PEDIDO
        )?.label;
        break;
      case identificacaoTipoOperacao.ORCAMENTO:
        newIdentificacaoTipoOperacaoLabel = getOptionByEnum(
          identificacaoTipoOperacao,
          identificacaoTipoOperacao.ORCAMENTO
        )?.label;
        break;

      case identificacaoTipoOperacao.CONSIGNACAO:
        newIdentificacaoTipoOperacaoLabel = getOptionByEnum(
          identificacaoTipoOperacao,
          identificacaoTipoOperacao.CONSIGNACAO
        )?.label;
        break;

      default:
        break;
    }

    return newIdentificacaoTipoOperacaoLabel;
  }, [identificaoOperacao]);

  return (
    <>
      <Text fontSize="sm" my={2} fontWeight="800" textAlign="center">
        CUPOM PARA TROCA
      </Text>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        mt={1}
        py="8px"
        borderTop="2px solid"
        borderColor="gray.700"
      >
        <VStack>
          {status === 2 ? (
            <Text fontSize="xs" color="black" fontWeight="900">
              {identificacaoTipoOperacaoLabel}: {numeroOperacao}
              <Text>{statusOperacao.properties[3].label.toUpperCase()}</Text>
            </Text>
          ) : (
            <Text fontSize="xs" color="black" fontWeight="900">
              {identificacaoTipoOperacaoLabel}: {numeroOperacao}
            </Text>
          )}
        </VStack>
        <VStack>
          <Text fontSize="xs">{formatUTCToLocateDateTime(dataEmissao)}</Text>
        </VStack>
      </Flex>
    </>
  );
};

export default Vendas;
