import React, { useState, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';

import ProdutosFormularioProvider from 'store/Produtos/ProdutosFormulario';

import { ContainerListagem } from 'components/Layout/Listagem/containerListagem';

import { useForm, yupResolver, defaultValues } from '../validationForm';
import UncontrolledForm from '..';

type TParams = { id: string };

const Visualizar = ({ match }: RouteComponentProps<TParams>) => {
  const [isLoading, setIsLoading] = useState(true);

  const formMethods = useForm({
    resolver: yupResolver,
    defaultValues,
  });

  const produtoIdRef = useRef(match.params.id);

  return (
    <Box h="100%" minH="99vh" bg="gray.100">
      <FormProvider {...formMethods}>
        <ProdutosFormularioProvider
          action="visualizar"
          cadastroExterno={false}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          produtoId={produtoIdRef.current}
          readonly
        >
          <ContainerListagem
            formMethods={formMethods}
            isLoading={isLoading}
            maxWidth="full"
            isForm={false}
            containerPossuiFundo={false}
            isVisualizar
          >
            <UncontrolledForm />
          </ContainerListagem>
        </ProdutosFormularioProvider>
      </FormProvider>
    </Box>
  );
};

export default Visualizar;
