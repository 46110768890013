import {
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useMediaQuery,
  Text,
  Button,
  Flex,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import Textarea from 'components/PDV/Textarea';
import { MobileModalMenu } from 'components/PDV/Layout/MobileModalMenu';

interface ModalInformacoesComplementaresProps extends InstanceProps<string> {
  defaultValue: string;
}

const ModalInformacoesComplementaresContent = ({
  onResolve,
  defaultValue,
}: ModalInformacoesComplementaresProps) => {
  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');

  const formMethods = useForm({
    defaultValues: { informacoesComplementares: defaultValue },
  });

  const { handleSubmit } = formMethods;
  const onSubmit = handleSubmit(({ informacoesComplementares }) => {
    onResolve(informacoesComplementares || '');
  });

  return (
    <FormProvider {...formMethods}>
      <Flex flexDirection="column" as="form" p={{ base: '3', md: '8' }} gap="8">
        {!isSmallerThan900 && (
          <Text as="h2" color="primary.50" fontSize="lg">
            Informações complementares
          </Text>
        )}

        <Textarea
          name="informacoesComplementares"
          placeholder="Informe o texto complementar para acrescentar na descrição do produto."
          maxLength={500}
          bg="white"
          minHeight="180px"
        />

        <Flex alignItems="center" justifyContent="center">
          <Button
            type="submit"
            onClick={onSubmit}
            colorScheme="secondary"
            minW="100px"
          >
            Confirmar
          </Button>
        </Flex>
      </Flex>
    </FormProvider>
  );
};

const ModalInformacoesComplementaresComponent = ({
  onResolve,
  onReject,
  isOpen,
  ...rest
}: ModalInformacoesComplementaresProps) => {
  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');

  if (isSmallerThan900) {
    return (
      <MobileModalMenu
        isOpen={isOpen}
        onClose={onResolve}
        title="Informações complementares"
      >
        <ModalInformacoesComplementaresContent
          onResolve={onResolve}
          isOpen={isOpen}
          onReject={onReject}
          {...rest}
        />
      </MobileModalMenu>
    );
  }

  return (
    <ModalPadraoChakra
      isCentered
      size={!isSmallerThan900 ? 'xl' : 'full'}
      isOpen={isOpen}
      onClose={onResolve}
    >
      <ModalContent
        marginBottom={{ base: 0, md: '3.75rem' }}
        marginTop={{ base: 0, md: '3.75rem' }}
        h="unset"
        maxW={{ base: '100%', md: '700px' }}
      >
        <ModalCloseButton right="30px" top="25px" />
        <ModalBody p={0}>
          <ModalInformacoesComplementaresContent
            onResolve={onResolve}
            isOpen={isOpen}
            onReject={onReject}
            {...rest}
          />
        </ModalBody>
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export const ModalInformacoesComplementares = create(
  ModalInformacoesComplementaresComponent
);
