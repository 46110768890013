import React, { useState, useEffect } from 'react';
import {
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  InputGroup,
  InputRightElement,
  Icon,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';

import CampoContainer, {
  CampoContainerProps,
} from 'components/PDV/Geral/CampoContainer';

export type InputProps = Omit<ChakraInputProps, 'ref'> &
  CampoContainerProps & {
    helperTextLabel?: string;
    onClickRightElement?: () => void;
    colorRightElement?: string;
    onEnterKeyPress?: () => void;
    sizeIcon?: string;
  };

const Input = ({
  id,
  name,
  onEnterKeyPress,
  label,
  helperText,
  colSpan,
  colStart,
  colEnd,
  rowStart,
  rowEnd,
  rowSpan,
  borderRadius,
  w,
  maxW,
  minW,
  isRequired,
  sizeIcon = '22px',
  actionLinkText,
  actionLinkOnClick,
  helperTextLabel,
  colorSchemeLinkText,
  inputRightElement,
  isPassword = false,
  icon,
  color = 'black',
  bg = 'white',
  fontWeightLabel,
  type,
  errorText,
  defaultValue,
  onBlur,
  onClickRightElement,
  labelColor,
  helperTextIconBg,
  helperTextIconColor,
  _autofill = {
    WebkitTextFillColor: 'black',
    color: 'black',
    transition: 'background-color 5000s ease-in-out 0s',
  },
  colorRightElement = 'gray.600',
  ...rest
}: InputProps) => {
  const [valueIsVisible, setValueIsVisible] = useState(true);

  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  const exibirIcon = () => {
    if (isPassword) {
      return valueIsVisible ? AiOutlineEyeInvisible : AiOutlineEye;
    }
    return icon;
  };

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, name]);

  return (
    <CampoContainer
      id={id}
      name={name}
      label={label}
      helperText={helperText}
      colSpan={colSpan}
      colStart={colStart}
      colEnd={colEnd}
      rowStart={rowStart}
      rowEnd={rowEnd}
      rowSpan={rowSpan}
      helperTextLabel={helperTextLabel}
      errorText={errorText || errors[name]?.message}
      isInvalid={errorText || errors[name]}
      w={w}
      borderRadius={borderRadius}
      maxW={maxW}
      minW={minW}
      isRequired={isRequired}
      actionLinkText={actionLinkText}
      labelColor={labelColor}
      helperTextIconBg={helperTextIconBg}
      helperTextIconColor={helperTextIconColor}
      actionLinkOnClick={actionLinkOnClick}
      colorSchemeLinkText={colorSchemeLinkText}
      fontWeightLabel={fontWeightLabel}
    >
      <InputGroup onBlur={onBlur}>
        <ChakraInput
          {...rest}
          id={id}
          bg={bg}
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter' && onEnterKeyPress) {
              e.currentTarget.value = e.currentTarget.value.trim();

              onEnterKeyPress();
            }
          }}
          defaultValue={defaultValue}
          color={color}
          sx={{
            '::-ms-reveal': {
              display: 'none',
            },
          }}
          _autofill={_autofill}
          type={isPassword && valueIsVisible ? 'password' : type}
          {...register(`${name}` as const)}
        />
        {inputRightElement && (
          <InputRightElement>
            <Icon
              boxSize={sizeIcon}
              cursor="pointer"
              color={colorRightElement}
              onClick={() => {
                if (isPassword) {
                  setValueIsVisible(!valueIsVisible);
                }
                if (onClickRightElement) {
                  onClickRightElement();
                }
              }}
              as={exibirIcon()}
            />
          </InputRightElement>
        )}
      </InputGroup>
    </CampoContainer>
  );
};

export default Input;
