import { enumStatusCredenciamento } from 'constants/enum/enumStatusCredenciamento';
import { useZoopStatusActions } from 'hooks/useZoopStatusActions';

import { IconZoop } from 'icons';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { Container } from '../../../Components/Layout/Container';
import { useZoopData } from './hooks';

export const DetalhesIntegracaoZoop = () => {
  const { listItemsInfo, isLoading, dadosCredenciamento } = useZoopData();
  const { handleCredenciamentoStatus } = useZoopStatusActions();

  const textStatus = handleCredenciamentoStatus(
    dadosCredenciamento?.statusCredenciamento ||
      enumStatusCredenciamento.PENDING
  );

  return (
    <>
      {isLoading && <LoadingPadrao />}
      <Container
        listItemsInfo={listItemsInfo}
        widthIcon={200}
        heightIcon={165}
        icon={IconZoop}
        date={dadosCredenciamento?.dateCredenciamento}
        status={textStatus}
      />
    </>
  );
};
