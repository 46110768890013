import { Text, VStack, Button, Flex, Box, GridItem } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';

import { FinanceiroAReceber } from 'icons';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import Input from 'components/PDV/Input';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';

import useBankAccount from './hooks';

import { Header } from '../Layout/Header';
import { ContainerIntegracaoFixo } from '../Layout/Container';
import { styleSelects } from '../Credenciamento/validationForms';

export const ContaBancaria = () => {
  const {
    isLoading,
    listBankAccounts,
    listBank,
    selectedBankAccountId,
    updateBankAccountInfo,
    formMethods,
    handleEnviar,
    handlePreencherDepois,
    isLargerThan700,
    isPlanoStart,
  } = useBankAccount();

  return (
    <ContainerIntegracaoFixo height={isLargerThan700 ? '100vh' : '100%'}>
      <Header title="Conta bancária" icon={FinanceiroAReceber} />
      <VStack
        pl={['10px', '10px', '20px']}
        pr={['10px', '10px', '20px']}
        lineHeight="1.8"
        color="primary.50"
        fontSize="14px"
        spacing={['10px', '10px', '24px']}
        mb="18px"
        alignItems="flex-start"
      >
        <Text textAlign={['center', 'left', 'left']} letterSpacing="0px">
          Você poderá utilizar a integração mesmo sem uma conta bancária quando
          o credenciamento for aprovado. No entanto, os valores não cairão
          automaticamente na sua conta!{' '}
        </Text>

        <Text
          textAlign={['center', 'left', 'left']}
          letterSpacing="0px"
          fontWeight="bold"
        >
          Atenção: A conta bancária deve pertencer ao mesmo CNPJ utilizado no
          credenciamento.
        </Text>
      </VStack>
      <FormProvider {...formMethods}>
        {isLoading && <LoadingPadrao />}
        <Box padding="20px" pb="16px" borderRadius="5px">
          <SimpleGridForm spacing="24px">
            {!isPlanoStart && (
              <GridItem colSpan={[12]}>
                <SelectPadrao
                  placeholder="Selecione uma conta bancária"
                  id="nomeConta"
                  name="nomeConta"
                  isLoading={isLoading}
                  options={listBankAccounts}
                  label="Conta bancária"
                  styles={styleSelects}
                  onSelect={(option) => updateBankAccountInfo(option.value)}
                  isSearchable={false}
                  size="lg"
                />
              </GridItem>
            )}

            {(!!selectedBankAccountId || isPlanoStart) && (
              <>
                <GridItem colSpan={[12]}>
                  <SelectPadrao
                    id="codigoBanco"
                    name="codigoBanco"
                    label="Banco"
                    placeholder="Selecione o banco"
                    options={listBank}
                  />
                </GridItem>

                <GridItem colSpan={[6]}>
                  <Input
                    type="text"
                    id="agencia"
                    name="agencia"
                    label="Agência bancária"
                    placeholder="Digite o número da agência bancária"
                    size="lg"
                    borderColor="gray.200"
                  />
                </GridItem>
                <GridItem colSpan={[6]}>
                  <Input
                    type="text"
                    id="numeroConta"
                    name="numeroConta"
                    label="Número de conta"
                    placeholder="Digite o número da conta bancária"
                    size="lg"
                    borderColor="gray.200"
                  />
                </GridItem>
              </>
            )}
          </SimpleGridForm>
        </Box>
      </FormProvider>
      <Flex justifyContent="center" alignItems="center" mt="48px" gap="12px">
        <Button
          w="fit-content"
          colorScheme="gray"
          onClick={handlePreencherDepois}
          variant="solid"
        >
          Vincular depois
        </Button>
        <Button
          w="200px"
          colorScheme="purple"
          onClick={handleEnviar}
          variant="solid"
        >
          Enviar
        </Button>
      </Flex>
    </ContainerIntegracaoFixo>
  );
};
