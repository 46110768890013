import React, { useRef } from 'react';
import { toast } from 'react-toastify';
import { useFormContext } from 'react-hook-form';
import {
  Box,
  Button,
  Flex,
  FormLabel,
  GridItem,
  Text,
  Icon,
} from '@chakra-ui/react';

import { ListImagensProps } from 'components/ImagePicker/ImageUploader';
import { LixeiraIcon } from 'icons';

export const Imagens = ({ salvarImagem }: any) => {
  const formMethods = useFormContext();
  const { watch, setValue } = formMethods;
  const homeImgUrl = watch('homeImgUrl');
  const bannerImgUrl = watch('bannerImgUrl');
  const finalImgUrl = watch('finalImgUrl');
  const hasOrderCompletionMessage = watch('hasOrderCompletionMessage');

  const inputFileImagemTelaConclusaoRef = useRef<HTMLInputElement>(null);
  const inputFileImagemTelaInicialRef = useRef<HTMLInputElement>(null);
  const inputFileImagemCardapioRef = useRef<HTMLInputElement>(null);

  const removerImagem = (prop: string) => {
    setValue(prop, '');
  };

  const handleSalvarImagem = async (value: ListImagensProps, prop: string) => {
    if (value.isForaPadrao) {
      toast.warning('A imagem está fora do padrão');
    } else {
      await salvarImagem(prop, value.linkImagem);
    }
  };

  const onloadImage = (
    file: File,
    isForaPadrao: boolean,
    tamanhoImagem: { widthImage: number; heightImage: number },
    prop: string
  ) => {
    const reader = new FileReader();
    reader.onload = async () => {
      await handleSalvarImagem(
        {
          isForaPadrao,
          linkImagem: reader.result as string,
          tamanhoImagem: {
            widthImage: tamanhoImagem.widthImage,
            heightImage: tamanhoImagem.heightImage,
          },
        },
        prop
      );
    };
    reader.readAsDataURL(file);
  };

  const convertFileInJPEG = (
    image: HTMLImageElement,
    file: File,
    tamanhos: {
      widthImage: number;
      heightImage: number;
    },
    prop: string
  ) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = image.width;
    canvas.height = image.height;

    ctx?.drawImage(image, 0, 0, image.width, image.height);

    canvas.toBlob(
      (blob) => {
        if (blob === null) {
          return;
        }

        const jpegFile = new File(
          [blob],
          file.name.replace(/\.[^/.]+$/, '.jpeg'),
          {
            type: 'image/jpeg',
            lastModified: Date.now(),
          }
        );

        const tamanhoImagem = {
          heightImage: canvas.height,
          widthImage: canvas.width,
        };
        if (
          image.width === tamanhos.widthImage &&
          image.height === tamanhos.heightImage
        ) {
          onloadImage(jpegFile, false, tamanhoImagem, prop);
        } else {
          onloadImage(jpegFile, true, tamanhoImagem, prop);
        }
      },
      'image/jpeg',
      0.9
    );
  };

  const handleFile = (
    file: File,
    prop: string,
    tamanhoImagem: {
      widthImage: number;
      heightImage: number;
    }
  ) => {
    if (file) {
      const image = new Image();
      image.onload = () => {
        convertFileInJPEG(image, file, tamanhoImagem, prop);
      };

      image.src = URL.createObjectURL(file);
    }
  };

  return (
    <GridItem colSpan={12}>
      <Text fontWeight="bold">Imagens</Text>
      <Flex
        bg="#E8E8E8"
        minH="192px"
        padding="24px"
        pt="20px"
        borderRadius="5px"
        flexDir="column"
        gap="16px"
        fit-content={['full', 'full', 'full', 'full', 'fit-content']}
        border="1px solid #CCCCCC"
      >
        <Flex
          pb="16px"
          borderBottom="1px solid #ccc"
          height="fit-content"
          flexWrap="wrap"
          w="full"
          justify="flex-start"
          align="baseline"
        >
          <Flex w="30%">
            <FormLabel letterSpacing="0px" mr="2px">
              Imagem para tela inicial (Resolução 1080x1920px)
            </FormLabel>
          </Flex>
          <Box mr="10px">
            <Button
              variant="solid"
              colorScheme={homeImgUrl ? 'secondary' : 'blue'}
              w="132px"
              height="24px"
              fontSize="14px"
              fontWeight="normal"
              onClick={() => {
                if (inputFileImagemTelaInicialRef?.current?.click) {
                  inputFileImagemTelaInicialRef.current.click();
                }
              }}
            >
              {homeImgUrl ? 'Alterar imagem' : 'Inserir imagem'}
            </Button>
            <input
              tabIndex={-1}
              onChange={(event) => {
                const { files } = event.target;

                if (!files || files.length === 0) {
                  return;
                }

                const newFiles = Array.from({ length: files.length });

                newFiles.forEach((_, index) => {
                  handleFile(files[index], 'homeImgUrl', {
                    widthImage: 1080,
                    heightImage: 1920,
                  });
                });
              }}
              style={{
                display: 'none',
              }}
              multiple={false}
              ref={inputFileImagemTelaInicialRef}
              type="file"
              accept="image/webp, image/avif, image/png, image/jpeg, image/jpg"
            />
          </Box>
          {homeImgUrl && (
            <Flex gap="8px" justify="center">
              <Text color="black" fontWeight="bold">
                Imagem adicionada
              </Text>
              <Icon
                as={LixeiraIcon}
                boxSize="16px"
                cursor="pointer"
                onClick={() => {
                  removerImagem('homeImgUrl');
                }}
              />
            </Flex>
          )}
        </Flex>
        <Flex
          pb="16px"
          borderBottom="1px solid #ccc"
          height="fit-content"
          flexWrap="wrap"
          w="full"
          justify="flex-start"
          align="baseline"
        >
          <Flex w="30%">
            <FormLabel letterSpacing="0px" mr="2px">
              Imagem para capa do cardápio (Resolução 1080x320px){' '}
            </FormLabel>
          </Flex>
          <Box mr="10px">
            <Button
              variant="solid"
              colorScheme={bannerImgUrl ? 'secondary' : 'blue'}
              w="132px"
              height="24px"
              fontSize="14px"
              fontWeight="normal"
              onClick={() => {
                if (inputFileImagemCardapioRef?.current?.click) {
                  inputFileImagemCardapioRef.current.click();
                }
              }}
            >
              {bannerImgUrl ? 'Alterar imagem' : 'Inserir imagem'}
            </Button>
            <input
              tabIndex={-1}
              onChange={(event) => {
                const { files } = event.target;

                if (!files || files.length === 0) {
                  return;
                }

                const newFiles = Array.from({ length: files.length });

                newFiles.forEach((_, index) => {
                  handleFile(files[index], 'bannerImgUrl', {
                    widthImage: 1080,
                    heightImage: 320,
                  });
                });
              }}
              style={{
                display: 'none',
              }}
              multiple={false}
              ref={inputFileImagemCardapioRef}
              type="file"
              accept="image/webp, image/avif, image/png, image/jpeg, image/jpg"
            />
          </Box>
          {bannerImgUrl && (
            <Flex gap="8px" justify="center">
              <Text color="black" fontWeight="bold">
                Imagem adicionada
              </Text>
              <Icon
                as={LixeiraIcon}
                boxSize="16px"
                cursor="pointer"
                onClick={() => {
                  removerImagem('bannerImgUrl');
                }}
              />
            </Flex>
          )}
        </Flex>
        {!hasOrderCompletionMessage && (
          <Flex
            height="fit-content"
            w="full"
            justify="flex-start"
            align="baseline"
          >
            <Flex w="30%">
              <FormLabel letterSpacing="0px" mr="2px">
                Imagem para tela de conclusão do pedido (Resolução 1080x1920px){' '}
              </FormLabel>
            </Flex>
            <Box mr="10px">
              <Button
                variant="solid"
                colorScheme={finalImgUrl ? 'secondary' : 'blue'}
                w="132px"
                height="24px"
                fontSize="14px"
                fontWeight="normal"
                onClick={() => {
                  if (inputFileImagemTelaConclusaoRef?.current?.click) {
                    inputFileImagemTelaConclusaoRef.current.click();
                  }
                }}
              >
                {finalImgUrl ? 'Alterar imagem' : 'Inserir imagem'}
              </Button>

              <input
                tabIndex={-1}
                onChange={(event) => {
                  const { files } = event.target;

                  if (!files || files.length === 0) {
                    return;
                  }

                  const newFiles = Array.from({ length: files.length });

                  newFiles.forEach((_, index) => {
                    handleFile(files[index], 'finalImgUrl', {
                      widthImage: 1080,
                      heightImage: 1920,
                    });
                  });
                }}
                style={{
                  display: 'none',
                }}
                multiple={false}
                ref={inputFileImagemTelaConclusaoRef}
                type="file"
                accept="image/webp, image/avif, image/png, image/jpeg, image/jpg"
              />
            </Box>
            {finalImgUrl && (
              <Flex gap="8px" justify="center">
                <Text color="black" fontWeight="bold">
                  Imagem adicionada
                </Text>
                <Icon
                  as={LixeiraIcon}
                  boxSize="16px"
                  cursor="pointer"
                  onClick={() => {
                    removerImagem('finalImgUrl');
                  }}
                />
              </Flex>
            )}
          </Flex>
        )}
      </Flex>
    </GridItem>
  );
};
