import { useCallback, useEffect } from 'react';
import { VStack, Flex, Text, Box } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useSmartPosEtapasContext } from 'store/SmartPos/SmartPosEtapasContext';
import { IdentificacaoEtapasSmartPos } from 'constants/enum/IdentificacaoEtapasSmartPos';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import { LogoStone } from 'icons';
import Input from 'components/PDV/Input';
import TextareaField from 'components/PDV/Textarea';
import InputCpfCnpj from 'components/PDV/InputCpfCnpj';
import InputTelefone from 'components/PDV/InputTelefonePdv';

import { FooterSmartPos } from '../../Layout/FooterSmartPos';
import { HeaderSmartPos } from '../../Layout/Header';
import { yupResolver, FormData } from './validationForm';

export const FormStone = () => {
  const formMethods = useForm<FormData>({
    resolver: yupResolver,
  });

  const { handleSubmit, reset } = formMethods;

  const { setActiveStep } = useSmartPosEtapasContext();

  const handleEnviar = handleSubmit(async (data) => {
    const response = await api.post<void, ResponseApi>(
      ConstanteEnderecoWebservice.INTEGRACAO_SMART_POS_ENVIAR_DADOS_STONE,
      {
        ...data,
      }
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }

      if (response.sucesso) {
        toast.success('Formulário enviado com sucesso');
        setActiveStep(IdentificacaoEtapasSmartPos.FOMULARIO_STONE_SUCESSO);
      }
    }
  });

  const obterCadastro = useCallback(async () => {
    const response = await api.get<void, ResponseApi<FormData>>(
      ConstanteEnderecoWebservice.INTEGRACAO_SMART_POS_OBTER_STONE
    );

    if (response) {
      if (response.sucesso) {
        reset(response.dados);
      }
    }
  }, [reset]);

  const handleVoltar = () => {
    setActiveStep(IdentificacaoEtapasSmartPos.GUIA_INTEGRACAO);
  };

  useEffect(() => {
    obterCadastro();
  }, [obterCadastro]);

  return (
    <FormProvider {...formMethods}>
      <Box mb="24px">
        <HeaderSmartPos
          title="Formulário Stone"
          icon={LogoStone}
          colorIcon="aquamarine.600"
          handleVoltar={() => handleVoltar()}
        />
        <VStack
          pl={['0', '0', '20px']}
          pr={['0', '0', '20px']}
          w="full"
          alignItems="center"
        >
          <Flex flexDir="column" gap="28px" justifyContent="center">
            <Text color="primary.50" fontSize="sm" letterSpacing="0px">
              Preencha os dados abaixo para receber um contato comercial direto
              da Stone. O acordo com a operadora é direto com a sua empresa
              (independente do nosso aplicativo), com total liberdade para você
              negociar os melhores planos e tarifas.
            </Text>
            <Input
              id="cliente"
              name="cliente"
              placeholder="Nome da pessoa responsável pela decisão comercial"
              label="Cliente"
              border="1px solid"
              borderColor="gray.100"
              _placeholder={{ color: 'gray.200' }}
            />
            <Input
              id="estabelecimento"
              name="estabelecimento"
              placeholder="Nome da empresa que irá utilizar a maquininha"
              label="Estabelecimento"
              border="1px solid"
              borderColor="gray.100"
              _placeholder={{ color: 'gray.200' }}
            />
            <InputCpfCnpj
              id="cpfCnpj"
              name="cpfCnpj"
              placeholder="CNPJ ou CPF do estabelecimento que iremos atender"
              label="CNPJ / CPF"
              border="1px solid"
              borderColor="gray.100"
              _placeholder={{ color: 'gray.200' }}
            />
            <InputTelefone
              id="telefone"
              name="telefone"
              placeholder="(00) 00000-0000"
              label="Telefone"
              border="1px solid"
              borderColor="gray.100"
              _placeholder={{ color: 'gray.200' }}
            />
            <TextareaField
              name="observacao"
              id="observacao"
              placeholder="Acrescente observações relevantes para o contato inicial."
              label="Observação"
              border="1px solid"
              borderColor="gray.100"
              minH="112px"
              maxH="230px"
              _placeholder={{ color: 'gray.200' }}
            />
          </Flex>
        </VStack>
      </Box>
      <FooterSmartPos mb="24px" handleAvancar={handleEnviar} />
    </FormProvider>
  );
};
