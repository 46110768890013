import { Box, Flex, Text, Button, useMediaQuery, Icon } from '@chakra-ui/react';
import { FiChevronLeft } from 'react-icons/fi';

import AsyncSelect from 'components/PDV/Select/AsyncSelectPadrao';
import InputDateRange from 'components/PDV/InputDateRange';
import Input from 'components/PDV/Input';
import { SelectVirtualized } from 'components/PDV/Select/SelectVirtualized';
import { PaginationData } from 'components/update/Pagination';

import { Options, OptionsClientes } from '../validationForms';

type RealizarPesquisaVendasProps = {
  filtersSubmit: (
    e?: React.BaseSyntheticEvent<any, any> | undefined
  ) => Promise<void>;
  getProdutoOptions: (
    inputValue: string,
    dataPagination: PaginationData
  ) => Promise<Options[]>;
  totalRegistros: number;
  getClientes(inputValue: string): Promise<OptionsClientes[]>;
  handlePesquisarProduto: () => Promise<void>;
  onClose: () => void;
  w?: string;
  tamanhoComponente: string;
};

export const RealizarPesquisaDasVendas = ({
  filtersSubmit,
  getProdutoOptions,
  totalRegistros,
  handlePesquisarProduto,
  onClose,
  w,
  tamanhoComponente,
  getClientes,
}: RealizarPesquisaVendasProps) => {
  const [isMobile] = useMediaQuery('(max-width: 900px)');
  return (
    <Box
      borderRadius="5px 0 0 5px"
      padding="20px"
      bg="gray.50"
      h="full"
      w={tamanhoComponente}
    >
      {!isMobile ? (
        <Box mb="32px">
          <Text fontSize="16px" w="90%">
            Encontre a venda e informe o produto que será devolvido:
          </Text>
        </Box>
      ) : (
        <Flex
          w="full"
          justifyContent="space-around"
          alignItems="center"
          mb="32px"
        >
          <Flex w="40px" justifyContent="left" alignItems="center">
            <Icon
              fontSize="20px"
              color="black"
              cursor="pointer"
              as={FiChevronLeft}
              onClick={onClose}
            />
          </Flex>
          <Text fontSize="16px" w="90%">
            Encontre a venda e informe o produto que será devolvido:
          </Text>
        </Flex>
      )}
      <Box mb="16px" w={w}>
        <SelectVirtualized
          totalRegistros={totalRegistros}
          id="produto"
          name="produto"
          placeholder="Produto"
          handleGetOptions={getProdutoOptions}
          helperText="A pesquisa de produto considera os campos Status, Descrição, Código SKU, GTIN/EAN, Código externo e Código de barras interno."
          withoutDefaultOptions
          required
          asControlledByObject
          isClearable
          colSpan={2}
        />
      </Box>
      <Box mb="16px" w={w}>
        <AsyncSelect
          id="clienteFornecedorId"
          name="clienteFornecedorId"
          colSpan={12}
          placeholder="Cliente"
          required
          withoutDefaultOptions
          isClearable
          asControlledByObject
          handleGetOptions={getClientes}
          shouldAppearTheAddress
        />
      </Box>
      <Box mb="16px" w={w}>
        <Input
          id="idVenda"
          name="idVenda"
          colSpan={12}
          placeholder="ID da venda"
        />
      </Box>
      <Box mb="16px" w={w}>
        <InputDateRange
          name="dataVenda"
          placeholder="Selecione a data da venda"
          startDateName="dataVendaInicio"
          endDateName="dataVendaFim"
          w="full"
          maxDate={new Date()}
          borderColor="gray.100"
          borderRadius="md"
          onConfirm={filtersSubmit}
        />
      </Box>
      <Box w={w}>
        <Button
          colorScheme="primary"
          borderRadius="20px"
          w="full"
          onClick={() => handlePesquisarProduto()}
        >
          Pesquisar
        </Button>
      </Box>
    </Box>
  );
};
