import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { FieldValues, UseFormSetValue } from 'react-hook-form';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import LogAuditoriaTelaEnum from 'constants/enum/logAuditoriaTela';
import auth from 'modules/auth';
import { SubstituirParametroRota } from 'constants/rotas';
import ConstanteRotasPDV from 'constants/rotasPDV';

import {
  ClienteProps,
  ItensConsignados,
  ProdutosConsignadosProps,
} from 'pages/PDV/ProdutosConsignados/types/validationForms';

import { useInformacoesGeraisContext } from './InformacoesGerais';

type ProdutosDevolucao = { id: string; quantidade: number };

type CodigoBarrasRef = {
  focus: () => void;
};

interface ProdutosConsignadosContextProps {
  produtosConsignados: ItensConsignados[];
  handleAtualizarDadosParaDevolucao: (quantidade: number[]) => Promise<void>;
  setProdutosConsignadosJaForamDevolvidos: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  isLoading: boolean;
  produtosConsignadosJaForamDevolvidos: boolean;
  clienteFornecedor: ClienteProps | undefined;
  setValorTotalConsignacao: React.Dispatch<React.SetStateAction<number>>;
  produtosSelecionados: number;
  setClienteFornecedor: React.Dispatch<
    React.SetStateAction<ClienteProps | undefined>
  >;
  handleListarProdutosConsignados: (
    clienteFornecedorId: string
  ) => Promise<void>;
  setProdutosConsignados: React.Dispatch<
    React.SetStateAction<ItensConsignados[]>
  >;
  setExisteCodigoBarras: React.Dispatch<React.SetStateAction<boolean>>;
  existeCodigoBarras: boolean;
  handleDevolverProduto: () => Promise<void>;
  handleAtualizarDadosParaVenda: (quantidade: number[]) => Promise<void>;
  setCodigoBarrasVersaoMobile: React.Dispatch<React.SetStateAction<string>>;
  codigoBarrasVersaoMobile: string;
  existeProdutosNoClienteSelecionado: boolean;
  setExisteProdutosNoClienteSelecionado: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  selecionarTodosProdutos: boolean;
  handleToggleSelecionarProdutoTroca(index: number): void;
  handleToggleSelecionarTodosItens(
    setValue: UseFormSetValue<FieldValues>
  ): void;
  codigoBarrasRef: React.MutableRefObject<CodigoBarrasRef>;
}

const ProdutosConsignadosContext = createContext<ProdutosConsignadosContextProps>(
  {} as ProdutosConsignadosContextProps
);

interface ProdutosConsignadosProviderProps {
  children: React.ReactNode;
}

export default function InformacoesGeraisProvider({
  children,
}: ProdutosConsignadosProviderProps): JSX.Element {
  const [produtosConsignados, setProdutosConsignados] = useState<
    ItensConsignados[]
  >([]);
  const [produtosQueSeraoDevolvidos, setProdutosQueSeraoDevolvidos] = useState<
    ProdutosDevolucao[]
  >([]);
  const [produtosQueSeraoVendidos, setProdutosQueSeraoVendidos] = useState<
    ProdutosDevolucao[]
  >([]);

  const [clienteFornecedor, setClienteFornecedor] = useState<ClienteProps>();
  const [codigoBarrasVersaoMobile, setCodigoBarrasVersaoMobile] = useState('');
  const [valorTotalConsignacao, setValorTotalConsignacao] = useState(0);

  const [
    produtosConsignadosJaForamDevolvidos,
    setProdutosConsignadosJaForamDevolvidos,
  ] = useState(false);
  const [selecionarTodosProdutos, setSelecionarTodosProdutos] = useState(false);
  const [vendaEstaSendoProcessada, setVendaEstaSendoProcessada] = useState(
    false
  );
  const [
    produtosConsignadosEstaAtualizado,
    setProdutosConsignadosEstaAtualizado,
  ] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [existeCodigoBarras, setExisteCodigoBarras] = useState(false);
  const [
    existeProdutosNoClienteSelecionado,
    setExisteProdutosNoClienteSelecionado,
  ] = useState(false);
  const codigoBarrasRef = useRef<CodigoBarrasRef>({} as CodigoBarrasRef);

  const lojaId = auth.getLoja().id;
  const usuarioId = auth.getUsuario().id;

  const {
    vendedorPdv,
    localDeEstoqueSelecionado,
    contaFinanceira,
    informacoesTabelaPreco,
  } = useInformacoesGeraisContext();

  const history = useHistory();

  const produtosSelecionados = produtosConsignados.filter(
    (itemSelecionado) => itemSelecionado.isChecked
  ).length;

  function handleToggleSelecionarProdutoTroca(index: number) {
    const itensConsignadosSelecionados = [...produtosConsignados];
    const item = itensConsignadosSelecionados[index];
    itensConsignadosSelecionados.splice(index, 1, {
      ...item,
      isChecked: !item.isChecked,
      produtoSelecionado: !item.isChecked,
    });
    setProdutosConsignados(itensConsignadosSelecionados);
  }

  function handleToggleSelecionarTodosItens(
    setValue: UseFormSetValue<FieldValues>
  ) {
    setProdutosConsignados((valorAnterior) =>
      valorAnterior.map((item) => {
        return {
          ...item,
          isChecked: !selecionarTodosProdutos,
          produtoSelecionado: !selecionarTodosProdutos,
        };
      })
    );
    produtosConsignados.forEach((produtoItem, index) => {
      setValue(
        `quantidade.${index}`,
        !selecionarTodosProdutos ? produtoItem.quantidadeConsignada : 1
      );
    });

    setSelecionarTodosProdutos(!selecionarTodosProdutos);
  }

  const handleListarProdutosConsignados = useCallback(
    async (clienteFornecedorId: string) => {
      setIsLoading(true);
      const response = await api.get<
        void,
        ResponseApi<ProdutosConsignadosProps[]>
      >(ConstanteEnderecoWebservice.LISTAR_PRODUTOS_CONSIGNADOS, {
        params: {
          clienteFornecedorId,
        },
      });
      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }
        if (response.sucesso) {
          await response.dados.forEach((item) => {
            setProdutosConsignados((valoresJaAdicionados) => [
              ...valoresJaAdicionados,
              ...(item.itensConsignados || []).map((valorConsignado) => ({
                ...valorConsignado,
                isChecked: false,
                produtoSelecionado: false,
                numeroConsignacao: item.numeroConsignacao,
                dataHoraConsignacao: item.dataHoraConsignacao,
                contaFinanceira: item.contaFinanceira,
                vendedor: item.vendedor,
                tabelaPreco: item.tabelaPreco,
              })),
            ]);
          });
          setExisteProdutosNoClienteSelecionado(true);
          setIsLoading(false);
        }
      }
      setIsLoading(false);
    },
    []
  );

  const handleAtualizarDadosParaDevolucao = useCallback(
    async (quantidade: number[]) => {
      const produtosEscolhidosParaDevolucao = [...produtosConsignados];
      await setProdutosConsignadosEstaAtualizado(true);
      await setProdutosQueSeraoDevolvidos(() =>
        produtosEscolhidosParaDevolucao
          .map((item, index) => ({
            ...item,
            quantidadeConsignada: quantidade[index],
          }))
          .filter((item) => item.isChecked)
          .map((item) => {
            const itensDevolucao = {
              id: item.operacaoItemId,
              quantidade: item.quantidadeConsignada,
            };

            return itensDevolucao;
          })
      );
    },
    [produtosConsignados]
  );

  const handleAtualizarDadosParaVenda = useCallback(
    async (quantidade: number[]) => {
      const produtosEscolhidosParaVendas = [...produtosConsignados];
      await setVendaEstaSendoProcessada(true);
      await setProdutosQueSeraoVendidos(() =>
        produtosEscolhidosParaVendas
          .map((item, index) => ({
            ...item,
            quantidadeConsignada: quantidade[index],
          }))
          .filter((item) => item.isChecked)
          .map((item) => {
            const itensVendidos = {
              id: item.operacaoItemId,
              quantidade: item.quantidadeConsignada,
            };

            return itensVendidos;
          })
      );
    },
    [produtosConsignados]
  );

  const handleDevolverProduto = useCallback(async () => {
    setIsLoading(true);
    const response = await api.post<
      void,
      ResponseApi<ProdutosConsignadosProps[]>
    >(
      ConstanteEnderecoWebservice.DEVOLVER_PRODUTOS_CONSIGNADOS,
      produtosQueSeraoDevolvidos
    );

    if (response?.avisos) {
      response?.avisos.forEach((item: string) => toast.warning(item));
    }
    if (response?.sucesso) {
      toast.success('Devolução realizada com sucesso');

      const valorTotalQuantidadeDevolvida = produtosQueSeraoDevolvidos.reduce(
        (acc, curr) => acc + curr.quantidade,
        0
      );
      const valorTotalQuantidadeConsignada = produtosConsignados.reduce(
        (acc, curr) => acc + curr.quantidadeConsignada,
        0
      );

      if (
        produtosQueSeraoDevolvidos.length === produtosConsignados.length &&
        valorTotalQuantidadeDevolvida === valorTotalQuantidadeConsignada
      ) {
        history.push(ConstanteRotasPDV.PDV_HOME);
      } else {
        await setProdutosConsignados([]);
        await setSelecionarTodosProdutos(false);
        await handleListarProdutosConsignados(clienteFornecedor?.value || '');
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [
    produtosQueSeraoDevolvidos,
    produtosConsignados,
    history,
    handleListarProdutosConsignados,
    clienteFornecedor?.value,
  ]);

  const handleVenderProduto = useCallback(async () => {
    setIsLoading(true);
    const informacoesConsignacao = {
      identificacaoTipoOperacao: 2,
      dataCompetencia: new Date(),
      dataEmissao: new Date(),
      vendedorId: vendedorPdv.id,
      clienteFornecedorId: clienteFornecedor?.value,
      lojaId,
      usuarioId,
      valorTotal:
        Math.round((valorTotalConsignacao + Number.EPSILON) * 100) / 100,
      localEstoqueId: localDeEstoqueSelecionado.id,
      caixaMovimentacaoId: contaFinanceira?.id,
      tabelaPrecoId: informacoesTabelaPreco?.id,
      tela: LogAuditoriaTelaEnum.PDV,
    };

    const response = await api.post<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.VENDER_PRODUTOS_CONSIGNADOS,
      {
        operacao: informacoesConsignacao,
        listaIdQuantidade: produtosQueSeraoVendidos,
      }
    );

    if (response?.avisos) {
      response?.avisos.forEach((item: string) => toast.warning(item));
    }
    if (response?.sucesso) {
      setIsLoading(false);
      history.push(
        SubstituirParametroRota(
          ConstanteRotasPDV.PDV_LANCAMENTO_ID,
          'id?',
          response.dados
        )
      );
    }
    setIsLoading(false);
  }, [
    vendedorPdv,
    clienteFornecedor,
    history,
    lojaId,
    usuarioId,
    valorTotalConsignacao,
    localDeEstoqueSelecionado,
    contaFinanceira,
    informacoesTabelaPreco,
    produtosQueSeraoVendidos,
  ]);

  useEffect(() => {
    const devolverProdutosConsignados = async () => {
      if (
        produtosConsignadosEstaAtualizado &&
        !produtosConsignadosJaForamDevolvidos &&
        produtosQueSeraoDevolvidos.length > 0
      ) {
        await handleDevolverProduto();
        setProdutosConsignadosEstaAtualizado(false);
        setProdutosQueSeraoDevolvidos([]);
      }
    };
    devolverProdutosConsignados();
  }, [
    handleDevolverProduto,
    produtosConsignadosJaForamDevolvidos,
    produtosConsignadosEstaAtualizado,
    produtosQueSeraoDevolvidos,
  ]);

  useEffect(() => {
    const venderProdutosConsignados = async () => {
      if (produtosQueSeraoVendidos.length > 0 && vendaEstaSendoProcessada) {
        setVendaEstaSendoProcessada(false);
        await handleVenderProduto();
        setProdutosQueSeraoVendidos([]);
      }
    };
    venderProdutosConsignados();
  }, [handleVenderProduto, vendaEstaSendoProcessada, produtosQueSeraoVendidos]);

  useEffect(() => {
    if (!clienteFornecedor) {
      setProdutosConsignados([]);
      setProdutosQueSeraoDevolvidos([]);
      setSelecionarTodosProdutos(false);
      setProdutosQueSeraoVendidos([]);
      setProdutosConsignadosJaForamDevolvidos(false);
      setExisteProdutosNoClienteSelecionado(false);
    }
  }, [clienteFornecedor]);

  return (
    <ProdutosConsignadosContext.Provider
      value={{
        produtosConsignados,
        handleToggleSelecionarProdutoTroca,
        setExisteProdutosNoClienteSelecionado,
        handleToggleSelecionarTodosItens,
        selecionarTodosProdutos,
        isLoading,
        codigoBarrasRef,
        setCodigoBarrasVersaoMobile,
        codigoBarrasVersaoMobile,
        handleAtualizarDadosParaDevolucao,
        handleAtualizarDadosParaVenda,
        handleListarProdutosConsignados,
        setProdutosConsignadosJaForamDevolvidos,
        clienteFornecedor,
        setClienteFornecedor,
        existeProdutosNoClienteSelecionado,
        produtosConsignadosJaForamDevolvidos,
        setExisteCodigoBarras,
        handleDevolverProduto,
        existeCodigoBarras,
        produtosSelecionados,
        setProdutosConsignados,
        setValorTotalConsignacao,
      }}
    >
      {children}
    </ProdutosConsignadosContext.Provider>
  );
}

export function useProdutosConsignadosContext(): ProdutosConsignadosContextProps {
  const context = useContext(ProdutosConsignadosContext);

  if (!context)
    throw new Error(
      'useProdutosConsignadosContext must be used within a InformacoesGeraisProvider.'
    );

  return context;
}
