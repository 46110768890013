import React, { useEffect, useState, useMemo, useRef } from 'react';
import {
  Box,
  Heading,
  Button,
  VStack,
  Text,
  Flex,
  HStack,
  useMediaQuery,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  MenuButton,
  Stack,
  Divider,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { isExists, lastDayOfMonth } from 'date-fns';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import useWindowSize from 'helpers/layout/useWindowSize';
import { useEditarParcelasContext } from 'store/PDV/EditarParcelas';
import { DecimalMask } from 'helpers/format/fieldsMasks';
import MeioPagamentoEnum from 'constants/enum/fiscal/meioPagamento';
import { usePagamentoContext } from 'store/PDV/Pagamento';

import ShadowScrollbar, {
  ShadowScrollbarForwardRefProps,
} from 'components/PDV/Geral/ShadowScrollbar';
import ConditionalWrapper from 'components/Geral/ConditionalWrapper';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import InputDate from 'components/PDV/InputDate';
import MenuDia from 'components/PDV/Menu/MenuDia';
import { MobileModalMenu } from 'components/PDV/Layout/MobileModalMenu';

interface Parcela {
  id: string;
  numeroParcela: number;
  valor: number;
  dataVencimento: Date;
}

type ValorParcela = { valor: number };

interface HeaderButtonsProps {
  isLoading: boolean;
  hasParcelas: boolean;
  parcelasBiggerThan1: boolean;
  handlePadronizarDia: (selectedDay: number) => void;
  handleArredondarValores: () => void;
  podeAlterarDataVencimento?: boolean;
}

const HeaderButtons = ({
  isLoading,
  hasParcelas,
  parcelasBiggerThan1,
  handlePadronizarDia,
  handleArredondarValores,
  podeAlterarDataVencimento,
}: HeaderButtonsProps) => {
  if (!isLoading && hasParcelas) {
    return (
      <Stack
        direction={{ base: 'column', md: 'row' }}
        w={{ base: 'full', md: 'auto' }}
        spacing={{ base: '2', md: 6 }}
        alignItems={{ base: 'flex-start', md: 'center' }}
      >
        {podeAlterarDataVencimento && (
          <MenuDia
            MenuButton={() => (
              <MenuButton
                as={Button}
                variant="link"
                colorScheme="pink"
                size="xs"
              >
                Padronizar o mesmo dia de vencimento
              </MenuButton>
            )}
            OnSelectDay={(selectedDay) => {
              handlePadronizarDia(selectedDay);
            }}
          />
        )}
        {parcelasBiggerThan1 && (
          <Button
            variant="link"
            colorScheme="blue"
            size="xs"
            onClick={() => {
              handleArredondarValores();
            }}
          >
            Arredondar valores
          </Button>
        )}
      </Stack>
    );
  }

  return null;
};

interface ValorFinalProps {
  formattedValor: string;
}

const ValorFinal = ({ formattedValor }: ValorFinalProps) => {
  return (
    <HStack w="full" justifyContent="flex-end" color="gray.500">
      <Text fontSize={{ base: 'xs', xl: 'sm' }}>VALOR FINAL:</Text>
      <HStack spacing={1}>
        <Text fontSize={{ base: 'xs', xl: 'sm' }}>R$</Text>
        <Text
          fontSize={{ base: 'lg', lg: 'xl', xl: '2xl' }}
          fontWeight="extrabold"
        >
          {formattedValor}
        </Text>
      </HStack>
    </HStack>
  );
};

interface TableParcelasProps {
  hasParcelas: boolean;
  parcelas: Parcela[];
  isLoading: boolean;
  podeAlterarDataVencimento?: boolean;
  handleChangeDataVencimento: (
    parcelaId: string,
    newDataVencimento: Date
  ) => void;
  handleGetMinDateParcela: (parcelaIndex: number) => Date | undefined;
  handleGetMaxDateParcela: (parcelaIndex: number) => Date | undefined;
}

const TableParcelas = ({
  hasParcelas,
  parcelas,
  isLoading,
  podeAlterarDataVencimento,
  handleChangeDataVencimento,
  handleGetMinDateParcela,
  handleGetMaxDateParcela,
}: TableParcelasProps) => {
  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');

  return (
    <Table size="sm" variant="simple-card">
      <Tbody>
        {hasParcelas &&
          parcelas.map((parcela, index) => (
            <>
              <Box key={`${parcela.id}-box-1`} h={0.5} zIndex="hide" />
              <Tr
                key={parcela.id}
                sx={{ background: 'var(--white) !important' }}
              >
                <Td
                  minW="100px"
                  whiteSpace="nowrap"
                  fontSize="sm"
                  color="primary.50"
                >{`Parcela ${String(parcela.numeroParcela).padStart(
                  2,
                  '0'
                )}`}</Td>
                <Td w="100%" whiteSpace="nowrap">
                  <InputDate
                    isDisabled={!podeAlterarDataVencimento}
                    size="sm"
                    variant="filled"
                    bg="gray.50"
                    _hover={{
                      bg: podeAlterarDataVencimento ? 'gray.100' : 'gray.50',
                    }}
                    _disabled={{
                      '*': { opacity: 1 },
                    }}
                    maxW="150px"
                    py={1}
                    my={-2}
                    h={7}
                    value={parcela.dataVencimento}
                    onChange={(newDate: Date) => {
                      handleChangeDataVencimento(parcela.id, newDate);
                    }}
                    minDate={handleGetMinDateParcela(index)}
                    maxDate={handleGetMaxDateParcela(index)}
                    asButton={isSmallerThan900}
                  />
                </Td>
                <Td w="auto" whiteSpace="nowrap" isNumeric>
                  <Flex
                    minW={{ base: '1px', md: '115px' }}
                    bg={{ base: 'white', md: 'gray.50' }}
                    borderRadius="md"
                    alignItems="center"
                    justifyContent="flex-end"
                    px={3}
                    py={1}
                    my={-2}
                    h={7}
                    fontSize="sm"
                    fontWeight="bold"
                    color="gray.700"
                  >
                    {DecimalMask(parcela.valor, 2)}
                  </Flex>
                </Td>
              </Tr>
              <Box
                key={`${parcela.id}-box-2`}
                h={index + 1 === parcelas.length ? 1 : 0.5}
                zIndex="hide"
              />
            </>
          ))}
        {!isLoading && !hasParcelas && (
          <Tr>
            <Td colSpan={7}>
              Não encontramos nenhuma parcela nesta movimentação financeira.
            </Td>
          </Tr>
        )}
      </Tbody>
    </Table>
  );
};

interface FooterButtonsProps {
  handleCancelar?: () => void;
  handlePutParcelas: () => void;
}

const FooterButtons = ({
  handleCancelar,
  handlePutParcelas,
}: FooterButtonsProps) => {
  return (
    <HStack w="full" spacing={6} justifyContent="center">
      {handleCancelar && (
        <Button variant="outline" onClick={handleCancelar}>
          Cancelar
        </Button>
      )}
      <Button colorScheme="secondary" onClick={handlePutParcelas}>
        Confirmar
      </Button>
    </HStack>
  );
};

const EditarParcelas = () => {
  const {
    movimentacaoFinanceiraEditando,
    handleSetMovimentacaoFinanceiraEditando,
  } = useEditarParcelasContext();
  const { height: windowHeight } = useWindowSize();
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const {
    movimentacoesFinanceiras = [],
    setMovimentacoesFinanceiras,
  } = usePagamentoContext();

  const [isLoading, setIsLoading] = useState(false);
  const [parcelas, setParcelas] = useState<Parcela[]>([]);

  const shadowScrollbarRef = useRef<ShadowScrollbarForwardRefProps>(null);

  const naoPodeAlterarDataVencimento = useMemo(
    () =>
      movimentacaoFinanceiraEditando &&
      (movimentacaoFinanceiraEditando.regraMeioPagamento ===
        MeioPagamentoEnum.CartaoDebitoStone ||
        movimentacaoFinanceiraEditando.regraMeioPagamento ===
          MeioPagamentoEnum.PixPresencialZoop ||
        movimentacaoFinanceiraEditando.regraMeioPagamento ===
          MeioPagamentoEnum.PixEnvioZoop ||
        movimentacaoFinanceiraEditando.regraMeioPagamento ===
          MeioPagamentoEnum.ValePresente),
    [movimentacaoFinanceiraEditando]
  );

  const handleSetMovimentacaoFinanceiraDataVencimento = (
    primeiraDataVencimento: Date
  ) => {
    const movimentacaoIndex = movimentacoesFinanceiras.findIndex(
      (movimentacao: any) =>
        movimentacao.identificacaoAgrupamento ===
        movimentacaoFinanceiraEditando?.identificacaoAgrupamento
    );

    const newMovimentacoesFinanceiras = [...movimentacoesFinanceiras];

    newMovimentacoesFinanceiras[movimentacaoIndex] = {
      ...newMovimentacoesFinanceiras[movimentacaoIndex],
      dataVencimento: primeiraDataVencimento,
    };

    setMovimentacoesFinanceiras(newMovimentacoesFinanceiras);
  };

  const handleCancelar = () => {
    handleSetMovimentacaoFinanceiraEditando(undefined);
  };

  const handlePutParcelas = async () => {
    setIsLoading(true);

    if (movimentacaoFinanceiraEditando) {
      const response = await api.put<void, ResponseApi>(
        ConstanteEnderecoWebservice.MOVIMENTACAO_FINANCEIRA_ALTERAR_VALOR_VENCIMENTO,
        parcelas.map((parcela) => ({
          id: parcela.id,
          valor: Math.round((parcela.valor + Number.EPSILON) * 100) / 100,
          dataVencimento: parcela.dataVencimento,
        }))
      );

      if (response) {
        if (response.avisos) {
          response.avisos.map((item: string) => toast.warning(item));
        }

        if (response.sucesso) {
          setIsLoading(false);
          handleSetMovimentacaoFinanceiraEditando(undefined);
          handleSetMovimentacaoFinanceiraDataVencimento(
            parcelas[0].dataVencimento
          );

          return;
        }
      }
    }

    setIsLoading(false);
  };

  const handleGetMinDateParcela = (parcelaIndex: number) => {
    if (parcelaIndex === 0) {
      return undefined;
    }

    const dataVencimentoAnterior = parcelas[parcelaIndex - 1].dataVencimento;

    return new Date(dataVencimentoAnterior);
  };

  const handleGetMaxDateParcela = (parcelaIndex: number) => {
    if (parcelaIndex + 1 === parcelas.length) {
      return undefined;
    }

    const proximaDataVencimento = parcelas[parcelaIndex + 1].dataVencimento;

    return new Date(proximaDataVencimento);
  };

  const handleChangeDataVencimento = (
    parcelaId: string,
    newDataVencimento: Date
  ) => {
    const parcelaIndex = parcelas.findIndex(
      (parcela) => parcela.id === parcelaId
    );

    const newParcelas = [...parcelas];

    newParcelas[parcelaIndex] = {
      ...newParcelas[parcelaIndex],
      dataVencimento: newDataVencimento,
    };

    setParcelas(newParcelas);
  };

  const handlePadronizarDia = (day: number) => {
    const newParcelas = [] as Parcela[];

    let previousMonth = 0;
    let previousYear: number;

    parcelas.forEach((parcela, index) => {
      let isValid = false;

      const dateObj = new Date(parcela.dataVencimento);
      let year = previousYear ?? dateObj.getUTCFullYear();
      let month = dateObj.getUTCMonth();

      if (index === 0) {
        previousMonth = month;
        previousYear = year;
      } else if (previousMonth < 11) {
        month = previousMonth + 1;
        previousMonth = month;
      } else {
        month = 0;
        previousMonth = month;

        year += 1;
        previousYear = year;
      }

      try {
        isValid = isExists(year, month, day);
      } catch (error) {
        isValid = false;
      }

      const lastDay = lastDayOfMonth(new Date(parcela.dataVencimento));
      lastDay.setHours(0, 0, 0, 0);

      newParcelas.push({
        ...parcela,
        dataVencimento: isValid ? new Date(year, month, day, 0, 0, 0) : lastDay,
      });
    });

    setParcelas(newParcelas);
  };

  const handleArredondarValores = () => {
    const newValorParcelas = [] as ValorParcela[];

    let decimalSum = 0;

    parcelas.forEach((parcela, index) => {
      const floatValue = Math.floor(parcela.valor);

      decimalSum += parcela.valor - floatValue;

      newValorParcelas.push({
        valor:
          index + 1 === parcelas.length ? floatValue + decimalSum : floatValue,
      });
    });

    setParcelas((parcelasJaAdicionadas) =>
      parcelasJaAdicionadas.map((parcela, index) => ({
        ...parcela,
        valor: newValorParcelas.sort(
          (primeiraParcela, segundaParcela) =>
            segundaParcela.valor - primeiraParcela.valor
        )[index].valor,
      }))
    );
  };

  const latestProps = useRef({ movimentacaoFinanceiraEditando });
  useEffect(() => {
    latestProps.current = { movimentacaoFinanceiraEditando };
  });

  useEffect(() => {
    const handleGetParcelas = async () => {
      setIsLoading(true);

      if (latestProps.current.movimentacaoFinanceiraEditando) {
        const response = await api.get<void, ResponseApi<Parcela[]>>(
          ConstanteEnderecoWebservice.MOVIMENTACAO_FINANCEIRA_OBTER_PARCELAS,
          {
            params: {
              identificadorAgrupamento:
                latestProps.current.movimentacaoFinanceiraEditando
                  .identificacaoAgrupamento,
            },
          }
        );

        if (response) {
          if (response.avisos) {
            response.avisos.map((item: string) => toast.warning(item));
          }

          if (response.sucesso && response.dados) {
            setParcelas(response.dados);
            setIsLoading(false);

            return;
          }
        }
      }

      setIsLoading(false);
      setParcelas([]);
    };

    handleGetParcelas();
  }, []);

  useEffect(() => {
    if (shadowScrollbarRef.current)
      shadowScrollbarRef.current.handleUpdateScrollbar();
  }, [parcelas]);

  if (!isLargerThan900) {
    return (
      <MobileModalMenu
        title={movimentacaoFinanceiraEditando?.descricaoFormaPagto || ''}
        onClose={() => handleSetMovimentacaoFinanceiraEditando(undefined)}
        isOpen={!!movimentacaoFinanceiraEditando}
        pt="0px !important"
      >
        <Divider borderColor="gray.100" />
        <VStack spacing={4} mt="4">
          <HeaderButtons
            isLoading={isLoading}
            hasParcelas={parcelas && parcelas.length > 0}
            parcelasBiggerThan1={parcelas.every((parcela) => parcela.valor > 1)}
            podeAlterarDataVencimento={!naoPodeAlterarDataVencimento}
            handleArredondarValores={handleArredondarValores}
            handlePadronizarDia={handlePadronizarDia}
          />
          <Box w="full" h="full">
            <TableParcelas
              hasParcelas={parcelas && parcelas.length > 0}
              parcelas={parcelas}
              isLoading={isLoading}
              podeAlterarDataVencimento={!naoPodeAlterarDataVencimento}
              handleChangeDataVencimento={handleChangeDataVencimento}
              handleGetMinDateParcela={handleGetMinDateParcela}
              handleGetMaxDateParcela={handleGetMaxDateParcela}
            />
          </Box>
          <ValorFinal
            formattedValor={DecimalMask(
              movimentacaoFinanceiraEditando?.valor,
              2
            )}
          />
        </VStack>

        <Flex py="6">
          <FooterButtons handlePutParcelas={handlePutParcelas} />
        </Flex>
      </MobileModalMenu>
    );
    // return (
    //   <ModalPadraoChakra
    //     onClose={() => handleSetMovimentacaoFinanceiraEditando(undefined)}
    //     isOpen={!!movimentacaoFinanceiraEditando}
    //     isCentered
    //     size="full"
    //     scrollBehavior="inside"
    //   >
    //     <ModalContent m={0} borderRadius="none">
    //       <ModalHeader color="primary.50">
    //         {movimentacaoFinanceiraEditando?.descricaoFormaPagto}
    //       </ModalHeader>
    //       <ModalBody />
    //       <ModalFooter>
    //         <FooterButtons
    //           handleCancelar={handleCancelar}
    //           handlePutParcelas={handlePutParcelas}
    //         />
    //       </ModalFooter>
    //     </ModalContent>
    //   </ModalPadraoChakra>
    // );
  }

  return (
    <Flex
      w="full"
      h="full"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="space-between"
      py={{ base: 4, md: 5 }}
      px={{ base: 4, md: 8, xl: 12 }}
    >
      {isLoading && <LoadingPadrao />}
      <Box w="full" h="full">
        <HStack
          spacing={4}
          mb={4}
          justifyContent="space-between"
          alignItems="center"
        >
          <Heading
            as="h4"
            fontSize={{ base: 'xs', xl: 'md' }}
            mb={0}
            lineHeight="tall"
          >
            {movimentacaoFinanceiraEditando?.descricaoFormaPagto}
          </Heading>

          <HeaderButtons
            isLoading={isLoading}
            hasParcelas={parcelas && parcelas.length > 0}
            parcelasBiggerThan1={parcelas.every((parcela) => parcela.valor > 1)}
            podeAlterarDataVencimento={!naoPodeAlterarDataVencimento}
            handleArredondarValores={handleArredondarValores}
            handlePadronizarDia={handlePadronizarDia}
          />
        </HStack>

        <Flex
          flexDirection="column"
          alignItems="center"
          w="calc(100% + 24px)"
          ml="-6px"
          h="full"
        >
          <Table
            size="sm"
            variant="simple-card"
            sx={{
              'th:first-child': { pl: 'calc(1.5rem + 6px)' },
              'th:last-child': { pr: 'calc(1.5rem + 18px)' },
            }}
          >
            <Thead>
              <Tr>
                <Th borderBottom={0} minW="115px">
                  Parcela
                </Th>
                <Th borderBottom={0} w="100%">
                  Vencimento
                </Th>
                <Th borderBottom={0} w="auto" isNumeric>
                  Valor
                </Th>
              </Tr>
            </Thead>
          </Table>
          <ConditionalWrapper
            condition={isLargerThan900}
            wrapper={(wrappedChildren) => (
              <ShadowScrollbar
                ref={shadowScrollbarRef}
                renderViewStyle={{ paddingLeft: '6px', paddingRight: '6px' }}
                containerStyle={{ width: '100%' }}
                style={{ width: 'calc(100% - 12px)' }}
                maxHeight={windowHeight - 450}
                paddingTop="0"
                shadowTopStyle={{
                  background:
                    'transparent linear-gradient(180deg, var(--sti-ck-colors-gray-50)  0%,  transparent 100%) 0% 0% no-repeat padding-box',
                  height: 30,
                }}
                shadowBottomStyle={{
                  background:
                    'transparent linear-gradient(180deg, transparent 0%, var(--sti-ck-colors-gray-50) 100%) 0% 0% no-repeat padding-box',
                  height: 30,
                }}
              >
                {wrappedChildren}
              </ShadowScrollbar>
            )}
          >
            <TableParcelas
              hasParcelas={parcelas && parcelas.length > 0}
              parcelas={parcelas}
              isLoading={isLoading}
              podeAlterarDataVencimento={!naoPodeAlterarDataVencimento}
              handleChangeDataVencimento={handleChangeDataVencimento}
              handleGetMinDateParcela={handleGetMinDateParcela}
              handleGetMaxDateParcela={handleGetMaxDateParcela}
            />
          </ConditionalWrapper>
        </Flex>
      </Box>

      <VStack w="full" alignItems="center" spacing={6}>
        <ValorFinal
          formattedValor={DecimalMask(movimentacaoFinanceiraEditando?.valor, 2)}
        />

        <FooterButtons
          handleCancelar={handleCancelar}
          handlePutParcelas={handlePutParcelas}
        />
      </VStack>
    </Flex>
  );
};

export default EditarParcelas;
