import { Box, Text, BoxProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

type ContainerProps = BoxProps & {
  children: React.ReactNode;
  title: string;
  bg?: string;
};

export const Container = ({
  children,
  title,
  h,
  px = '32px',
  bg = 'gray.100',
  ...rest
}: ContainerProps) => {
  return (
    <Box h="full" w="full">
      <Text fontSize="14px" fontWeight="semibold" color="black">
        {title}
      </Text>
      <Box
        borderRadius="5px"
        overflowY="auto"
        px={px}
        h={h}
        py="24px"
        borderColor="gray.200"
        borderWidth="1px"
        bg={bg}
        {...rest}
      >
        {children}
      </Box>
    </Box>
  );
};
