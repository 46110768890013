import React, { ReactNode, useState } from 'react';
import {
  ModalContent,
  ModalBody,
  ModalFooter,
  Flex,
  Heading,
  Text,
  AlertDialogFooter,
  ScaleFade,
  ModalProps,
  Button,
} from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';
import { AtencaoAvisoIcon } from 'icons';
import { create, InstanceProps } from 'react-modal-promise';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import ManterFoco from 'components/Geral/ManterFoco';
import { ButtonFechar } from './styles';

interface ModalErroProps
  extends InstanceProps<FormData>,
    Omit<ModalProps, 'children' | 'isOpen' | 'onClose'> {
  textoMensagem: string | ReactNode;
  textoTitulo: string;
  textoButton?: string;
  callback?: (value: boolean) => Promise<void>;
}

const ModalErroConst = ({
  textoTitulo,
  textoMensagem,
  textoButton,
  callback,
}: ModalErroProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);

  return (
    <ModalPadraoChakra
      onClose={() => {
        setIsOpen(false);
        if (callback) {
          callback(false);
        }
      }}
      isOpen={isOpen}
      isCentered
      size="xl"
      autoFocus={false}
    >
      <ModalContent
        marginBottom={{ base: 0, sm: '3.75rem' }}
        marginTop={{ base: 0, sm: '3.75rem' }}
        h="unset"
        bg="gray.50"
        maxW="600px"
      >
        <ManterFoco
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <ScaleFade initialScale={1.5} in={isOpen}>
            <ModalBody p={0} h="100%">
              <Flex flexDirection="column" align="stretch" h="100%">
                <Flex
                  justifyContent="center"
                  h="150px"
                  bg="red.500"
                  style={{
                    borderTopLeftRadius: '5px',
                    borderTopRightRadius: '5px',
                  }}
                >
                  <AtencaoAvisoIcon
                    style={{
                      fontSize: '75px',
                      fontWeight: 'bolder',
                      marginTop: '35px',
                      color: 'white',
                    }}
                  />
                </Flex>

                <Flex
                  justifyContent="flex-start"
                  flexDirection="column"
                  bg="gray.50"
                  px={8}
                  py={7}
                  h="225px"
                  style={{
                    borderBottomLeftRadius: '5px',
                    borderBottomRightRadius: '5px',
                  }}
                >
                  <Heading
                    as="h2"
                    fontSize="2xl"
                    color="red.500"
                    fontWeight="semibold"
                    textAlign="initial"
                  >
                    {t(textoTitulo)}
                  </Heading>
                  <Text mt={15} fontSize="14px" color="gray.700">
                    {textoMensagem ||
                      t('Todos os dados que você preencheu serão cancelados.')}
                  </Text>
                </Flex>
              </Flex>
            </ModalBody>
            <ModalFooter>
              <AlertDialogFooter>
                <Flex
                  w="100%"
                  alignItems="center"
                  justifyContent="center"
                  mt={textoButton ? '5' : ''}
                >
                  {textoButton && (
                    <Button
                      id="cancelModal"
                      variant="outlineDefault"
                      color="gray.400"
                      borderColor="gray.400"
                      maxW="180px"
                      mr="4"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      Voltar
                    </Button>
                  )}
                  <Button
                    id="cancelModal"
                    color="white"
                    bg="red.500"
                    colorScheme="red"
                    minW={textoButton ? '32' : ''}
                    margin={textoButton ? '0' : ''}
                    variant="cancel"
                    onClick={() => {
                      setIsOpen(false);
                      if (callback) {
                        callback(true);
                      }
                    }}
                  >
                    {textoButton || 'Ok'}
                  </Button>
                </Flex>
              </AlertDialogFooter>
            </ModalFooter>
          </ScaleFade>
        </ManterFoco>
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export const ModalErro = create<ModalErroProps>(ModalErroConst);
