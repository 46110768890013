import React from 'react';
import { Route } from 'react-router-dom';

import auth from 'modules/auth';
import OperacoesFiltroProvider from 'store/Operacoes/OperacoesFiltros';
import OperacoesEmitirNotaProvider from 'store/Operacoes/OperacoesEmitirNota';
import ConstanteRotas from 'constants/rotas';
import ConstanteFuncionalidades from 'constants/permissoes';

import { NFe } from 'pages/Vendas/EmitirNotaFiscal/NFe';
import { NFCe } from 'pages/Vendas/EmitirNotaFiscal/NFCe';
import { permissaoListarOperacao } from '../pages/Vendas/Operacoes/PermissaoListarOperacao';
import { ListarOperacoes } from '../pages/Vendas/Operacoes/Listar';
import OperacoesDetalhes from '../pages/Vendas/Detalhes';

import LayoutGuard from './LayoutGuard';

const OperacoesListar = () => {
  return <ListarOperacoes />;
};

const possuiPermissaoOperacoes = permissaoListarOperacao();
const hasPermissaoNotaFiscal = auth.possuiPermissao(
  ConstanteFuncionalidades.NOTA_FISCAL_EMITIR
).permitido;

const OperacoesRoutes = () => (
  <Route
    path={[
      ConstanteRotas.OPERACOES,
      ConstanteRotas.OPERACOES_EMITIR_NOTA_FISCAL_NFE,
      ConstanteRotas.OPERACOES_EMITIR_NOTA_FISCAL_NFCE,
      ConstanteRotas.OPERACOES_DETALHE,
    ]}
    exact
  >
    <OperacoesFiltroProvider>
      <OperacoesEmitirNotaProvider>
        <LayoutGuard
          key="operacoes-listar"
          component={OperacoesListar}
          breadcrumb={[
            {
              title: 'Vendas',
            },
            {
              title: 'Operações',
            },
          ]}
          path={ConstanteRotas.OPERACOES}
          permissaoFuncionalidade={possuiPermissaoOperacoes}
          isDisabled={!possuiPermissaoOperacoes}
          exact
          meta={{ auth: true }}
        />
        <LayoutGuard
          key="operacoes-emitir-nota-fiscal-nfe"
          component={NFe}
          breadcrumb={[
            {
              title: 'Vendas',
            },
            {
              title: 'Operações',
              path: ConstanteRotas.OPERACOES,
            },
            {
              title: 'Emitir NFe',
            },
          ]}
          path={ConstanteRotas.OPERACOES_EMITIR_NOTA_FISCAL_NFE}
          permissaoFuncionalidade={ConstanteFuncionalidades.NOTA_FISCAL_EMITIR}
          isDisabled={!hasPermissaoNotaFiscal}
          exact
          meta={{ auth: true }}
        />
        <LayoutGuard
          key="operacoes-emitir-nota-fiscal-nfce"
          component={NFCe}
          breadcrumb={[
            {
              title: 'Vendas',
            },
            {
              title: 'Operações',
              path: ConstanteRotas.OPERACOES,
            },
            {
              title: 'Emitir NFCe',
            },
          ]}
          path={ConstanteRotas.OPERACOES_EMITIR_NOTA_FISCAL_NFCE}
          permissaoFuncionalidade={ConstanteFuncionalidades.NOTA_FISCAL_EMITIR}
          isDisabled={!hasPermissaoNotaFiscal}
          exact
          meta={{ auth: true }}
        />
      </OperacoesEmitirNotaProvider>
      <LayoutGuard
        key="operacoes-detalhes"
        component={OperacoesDetalhes}
        breadcrumb={[
          {
            title: 'Vendas',
          },
          {
            title: 'Operações',
            path: ConstanteRotas.OPERACOES,
          },
          {
            title: 'Detalhamento',
          },
        ]}
        path={ConstanteRotas.OPERACOES_DETALHE}
        permissaoFuncionalidade={possuiPermissaoOperacoes}
        isDisabled={!possuiPermissaoOperacoes}
        exact
        meta={{ auth: true }}
      />
    </OperacoesFiltroProvider>
  </Route>
);

export default OperacoesRoutes;
