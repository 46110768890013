import { Prompt } from 'react-router-dom';

import UncontrolledForm from '../..';
import { useCadastroItem } from './hooks';

type CadastroItemProps = {
  deleteProduct: (produtoId: string) => Promise<void>;
};

export const CadastroItem = ({ deleteProduct }: CadastroItemProps) => {
  const { validatePrompt, handleExitRegistration } = useCadastroItem(
    deleteProduct
  );

  return (
    <>
      <Prompt
        when={validatePrompt}
        message={(location) => {
          handleExitRegistration(location.pathname);

          return false;
        }}
      />

      <UncontrolledForm />
    </>
  );
};
