import { useImperativeHandle, useEffect, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Flex,
  GridItem,
  HStack,
  Text,
  VStack,
  Icon,
  Button,
} from '@chakra-ui/react';
import { IoIosArrowDown } from 'react-icons/io';
import { toast } from 'react-toastify';

import { useZoopContext } from 'store/Zoop/ZoopContext';
import {
  enumTiposDeJuros,
  enumTiposDeMulta,
} from 'constants/enum/enumConfigJurosMultaZoop';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import getOptionsByEnum from 'helpers/format/getOptionsByEnum';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import { InputNumber } from 'components/update/Input/InputNumber';
import TextArea from 'components/PDV/Textarea';
import { AtencaoAvisoIcon } from 'icons';

import { Container } from '../Layout/Container';

type Configuracoes = {
  tipoMulta: number;
  valorJuros: number;
  valorMulta: number;
  diasJuros: number;
  diasMulta: number;
  limitePagamento: number;
  instrucaoPagamento: string | null;
};

export const Configuracoes = () => {
  const formMethods = useForm({
    defaultValues: {
      tipoJuros: enumTiposDeJuros.PORCENTAGEM_DIARIA,
      tipoMulta: enumTiposDeMulta.PORCENTAGEM,
      valorJuros: 0,
      valorMulta: 0,
      diasJuros: 0,
      diasMulta: 0,
      limitePagamento: 0,
      instrucaoPagamento: '',
    },
  });

  const { getValues, reset, setValue, watch } = formMethods;

  const {
    ref,
    infoCredenciamento,
    setIsLoading,
    isLoading,
    obterCredenciamento,
  } = useZoopContext();

  const hasConfigSalvas = !!infoCredenciamento?.instrucaoPagamentoViewModel;

  const handleSalvar = async () => {
    setIsLoading(true);
    const data = getValues();
    const response = await api.post<void, ResponseApi>(
      ConstanteEnderecoWebservice.CONFIG_COBRANCAS_BOLETO,
      data
    );
    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }
      if (response?.sucesso) {
        toast.success('Configurações salvas com sucesso');
      }
    }
    await obterCredenciamento();
    setIsLoading(false);
  };

  useImperativeHandle(ref, () => ({
    handleSalvar,
  }));

  const [tipoMulta, tipoJuros, valorJuros] = watch([
    'tipoMulta',
    'tipoJuros',
    'valorJuros',
  ]);

  const updateTipoMulta = useCallback(() => {
    if (tipoMulta === enumTiposDeMulta.PORCENTAGEM) {
      setValue('tipoMulta', enumTiposDeMulta.REAIS);
    } else {
      setValue('tipoMulta', enumTiposDeMulta.PORCENTAGEM);
    }
  }, [setValue, tipoMulta]);

  useEffect(() => {
    if (
      (tipoJuros === enumTiposDeJuros.PORCENTAGEM_MENSAL ||
        tipoJuros === enumTiposDeJuros.PORCENTAGEM_DIARIA) &&
      valorJuros > 100
    ) {
      setValue('valorJuros', 100);
    }
  }, [setValue, tipoJuros, valorJuros]);

  useEffect(() => {
    if (hasConfigSalvas) {
      reset({
        ...infoCredenciamento?.instrucaoPagamentoViewModel,
      });
    }
  }, [reset, hasConfigSalvas, infoCredenciamento?.instrucaoPagamentoViewModel]);

  return (
    <Container p="28px" h="auto" w="full">
      {isLoading && <LoadingPadrao />}
      <FormProvider {...formMethods}>
        <SimpleGridForm gridGap="24px" w="full">
          <GridItem colSpan={[12, 12, 4, 4]}>
            <Text fontWeight="bold" fontSize="sm" lineHeight="1" mb="4px">
              Informações de multa
            </Text>
            <VStack
              gap="24px"
              bg="white"
              p="40px 30px"
              borderRadius="6px"
              borderColor="gray.200"
              borderWidth="1px"
              borderStyle="solid"
              height="220px"
            >
              <Flex width="full" gap="24px">
                <Flex flexDir="column" cursor="pointer">
                  <Text
                    fontWeight="semibold"
                    fontSize="sm"
                    lineHeight="1"
                    mb="4px"
                  >
                    Multa
                  </Text>
                  <Button
                    rightIcon={<IoIosArrowDown />}
                    minW="60px"
                    maxW="60px"
                    bg="white"
                    fontSize={{ base: 'xs', xl: 'md' }}
                    borderColor="gray.200"
                    borderWidth="1px"
                    borderStyle="solid"
                    width="60px"
                    borderRadius="md"
                    onClick={updateTipoMulta}
                    _hover={{ backgroundColor: 'none' }}
                  >
                    {tipoMulta === enumTiposDeMulta.PORCENTAGEM ? '%' : 'R$'}
                  </Button>
                </Flex>
                <InputNumber
                  id="valorMulta"
                  name="valorMulta"
                  mt="18px"
                  isRequired={false}
                  max={
                    tipoMulta === enumTiposDeMulta.PORCENTAGEM ? 100 : 999999999
                  }
                  size="md"
                  fontSize={{ base: 'sm', md: 'md' }}
                />
              </Flex>
              <InputNumber
                name="diasMulta"
                label="Dias até cobrança"
                scale={0}
                helperText="Informe após quantos dias a cobrança de multa será aplicada"
              />
            </VStack>
          </GridItem>
          <GridItem colSpan={[12, 12, 8, 5]}>
            <Text fontWeight="bold" fontSize="sm" lineHeight="1" mb="4px">
              Informações de juros
            </Text>
            <VStack
              gap="24px"
              bg="white"
              p="40px 30px"
              height={['fit-content', 'fit-content', '220px']}
              borderRadius="6px"
              borderColor="gray.200"
              borderWidth="1px"
              borderStyle="solid"
            >
              <HStack
                gap="24px"
                spacing="0px"
                width="full"
                flexWrap={['wrap', 'unset']}
              >
                <Flex flexDir="column" cursor="pointer">
                  <Text
                    fontWeight="semibold"
                    fontSize="sm"
                    lineHeight="1"
                    mb="4px"
                  >
                    Juros
                  </Text>
                  <Button
                    minW="60px"
                    maxW="60px"
                    bg="white"
                    fontSize={{ base: 'xs', xl: 'md' }}
                    borderColor="gray.200"
                    borderWidth="1px"
                    borderStyle="solid"
                    width="60px"
                    borderRadius="md"
                    lineHeight="1"
                    _hover={{ backgroundColor: 'none' }}
                  >
                    %
                  </Button>
                </Flex>

                <InputNumber
                  id="valorJuros"
                  name="valorJuros"
                  mt="18px"
                  isRequired={false}
                  max={100}
                  size="md"
                  fontSize={{ base: 'sm', md: 'md' }}
                />
              </HStack>
              <Flex w="full" gap="24px" flexWrap={['wrap', 'wrap', 'unset']}>
                <SelectPadrao
                  id="tipoJuros"
                  options={getOptionsByEnum(enumTiposDeJuros)}
                  name="tipoJuros"
                  label="Tipo de juros"
                  isSearchable={false}
                />
                <InputNumber
                  name="diasJuros"
                  label="Dias até cobrança"
                  scale={0}
                  maxW={['full', 'fit-content', '160px']}
                  helperText="Informe após quantos dias a cobrança de juros será aplicada"
                />
              </Flex>
            </VStack>
          </GridItem>
          <GridItem colSpan={[12, 12, 4, 3]}>
            <Text fontWeight="bold" fontSize="sm" lineHeight="1" mb="4px">
              Outras informações
            </Text>
            <VStack
              bg="white"
              py="40px"
              px="30px"
              borderRadius="6px"
              borderColor="gray.200"
              borderWidth="1px"
              borderStyle="solid"
              align="start"
              gap="0px"
              height={['145px', '145px', '145px', '220px']}
              spacing="0px"
            >
              <Text fontWeight="bold" fontSize="sm" lineHeight="1" mb="4px">
                Limite pagamento
              </Text>
              <HStack align="flex-end" gap="6px" spacing="0px">
                <InputNumber name="limitePagamento" scale={0} />
                <Text>dias</Text>
              </HStack>
            </VStack>
          </GridItem>
          <GridItem colSpan={[12, 12, 8, 12]}>
            <TextArea
              name="instrucaoPagamento"
              label="Instrução de pagamento"
              fontWeightLabel="bold"
              placeholder="Digite a instrução de pagamento"
              size="md"
              minH="145px"
              helperText="Acrescente instruções para o cliente de como fazer o pagamento."
            />
          </GridItem>
        </SimpleGridForm>
        <Flex gap="8px" align="center" mt="12px">
          <Icon as={AtencaoAvisoIcon} boxSize="18px" stroke="black" />
          <Text w="full" fontSize="sm" fontWeight="bold">
            As configurações acima serão aplicadas somente nos boletos gerados
            usando a integração da Zoop Recebimentos.
          </Text>
        </Flex>
      </FormProvider>
    </Container>
  );
};
