import { FiXCircle } from 'react-icons/fi';
import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Icon,
  Text,
  Grid,
  GridItem,
  Circle,
  useToken,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';

import api, { ResponseApi } from 'services/api';
import { moneyMask } from 'helpers/format/fieldsMasks';
import { useTrayPainelControleContext } from 'store/Tray/PainelControle';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { painelAdministradorTrayEnum } from 'constants/enum/PainelAdministradorTrayEnum';
import { enumIdentificacaoIntegracao } from 'constants/enum/enumIdentificacaoIntegracao';

import {
  AvisoIcon,
  DashboardFaturamento,
  DashboardProdutosVendidos,
  DashboardTicketMedio,
  DashboardTotalVendas,
  PainelTrayIcon,
} from 'icons';

import { ContainerHeader } from '../../Layout/ContainerHeader';

type Totalizadores = {
  descricaoProdutoMaisVendido: string;
  faturamento: number;
  ticketMedio: number;
  quantidadeVendas: number;
};

export const PainelAdministradorTray = () => {
  const [amountPendencia, setAmountPendencia] = useState(0);
  const [totalizadores, setTotalizadores] = useState<Totalizadores>();

  const blue800 = useToken('colors', 'blue.800');

  const {
    nomeCanalVenda,
    dadosTray,
    getTray,
    setIsLoading,
    isLoadingTotalizadores,
    getTotalizador,
    mesAtual,
  } = useTrayPainelControleContext();
  const history = useHistory();

  const buscarTotalizadores = useCallback(async () => {
    const response = await getTotalizador();
    if (response) {
      setTotalizadores(response);
    }
  }, [getTotalizador]);

  const getPendencia = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get<void, ResponseApi<number>>(
      ConstanteEnderecoWebservice.INTEGRACAO_TRAY_QUANTIDADE_PENDENCIA,
      {
        params: {
          identificacaoIntegracao: enumIdentificacaoIntegracao.TRAY,
        },
      }
    );

    if (response) {
      if (response.sucesso) {
        setAmountPendencia(response.dados);
      }
    }
    setIsLoading(false);
  }, [setIsLoading]);

  useEffect(() => {
    buscarTotalizadores();
  }, [buscarTotalizadores]);

  useEffect(() => {
    getPendencia();
  }, [getPendencia]);

  useEffect(() => {
    getTray();
  }, [getTray]);

  return (
    <>
      <ContainerHeader h={['160px', '160px', '64px']}>
        <Flex
          w="full"
          direction={['column', 'column', 'row']}
          color="white"
          justifyContent={['center', 'center', 'space-between']}
          alignItems="center"
          h={['160px', '160px', '64px']}
          pb={['20px', '20px', '0']}
        >
          <Flex direction={['column', 'column', 'row']} alignItems="center">
            <Box>
              <Icon boxSize="30px" as={PainelTrayIcon} />
            </Box>
            <Text ml={['0', '0', '10px']}>Tray Commerce</Text>
            <Text mx={['0', '0', '3px']} display={['none', 'none', 'inline']}>
              |
            </Text>
            <Text textTransform="capitalize">{nomeCanalVenda}</Text>
          </Flex>
          <Flex
            direction={['column', 'column', 'row']}
            pr={['0', '0', '10px']}
            alignItems="center"
          >
            <Flex alignItems="center">
              <Circle
                size="2"
                bg={
                  dadosTray?.sincronizacaoHabilitada
                    ? 'secondary.300'
                    : 'red.400'
                }
              />
              <Text display="inline" ml="5px">
                Integração sincronizada
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </ContainerHeader>
      {!dadosTray?.sincronizacaoHabilitada && (
        <Flex pl="24px" bg="red.400" alignItems="center" color="white" h="51px">
          <FiXCircle size={18} color="white" />
          <Text pl="10px">
            Sincronização de vendas e estoque não foi iniciada. Se as etapas de
            integração foram concluídas, acesse o menu configurações para
            iniciar.
          </Text>
        </Flex>
      )}
      {amountPendencia > 0 && (
        <ContainerHeader
          boxShadow="none"
          zIndex="1019"
          bg="yellow.400"
          h="auto"
          alignItems="center"
          py="5px"
        >
          <Icon cursor="pointer" fontSize="20px" as={AvisoIcon} />
          <Text pl="10px">
            Você tem{' '}
            <span style={{ fontWeight: 'bold' }}>
              {amountPendencia > 1
                ? `${amountPendencia} vendas com pendências`
                : `${amountPendencia} venda com pendência`}{' '}
              de cadastro.
            </span>{' '}
            Clique no número da venda para identificar e corrigir o problema.
          </Text>
        </ContainerHeader>
      )}
      <Box mt="24px" px="24px">
        <Flex
          px="20px"
          borderBottomWidth="1px"
          justifyContent={['flex-start', 'flex-start', 'space-between']}
          alignItems={['left', 'left', 'center']}
          pb="24px"
          direction={['column', 'column', 'row']}
        >
          <Flex alignItems="center">
            <Box mr="5px">
              <Icon boxSize="33px" as={DashboardTotalVendas} />
            </Box>
            <Box>
              <Text ml="5px">Número de vendas</Text>
              <Text color="blue.800" fontWeight="bold" ml="5px">
                {isLoadingTotalizadores ? (
                  <BeatLoader size="6" color={blue800} />
                ) : (
                  totalizadores?.quantidadeVendas || 0
                )}
              </Text>
            </Box>
          </Flex>
          <Flex pt={['24px', '24px', '0']} alignItems="center">
            <Box mr="5px">
              <Icon boxSize="33px" as={DashboardFaturamento} />
            </Box>
            <Box>
              <Text ml="5px">Valor total em vendas</Text>
              <Text color="blue.800" fontWeight="bold" ml="5px">
                {isLoadingTotalizadores ? (
                  <BeatLoader size="6" color={blue800} />
                ) : (
                  moneyMask(totalizadores?.faturamento || 0, true)
                )}
              </Text>
            </Box>
          </Flex>
          <Flex pt={['24px', '24px', '0']} alignItems="center">
            <Box mr="5px">
              <Icon boxSize="33px" as={DashboardTicketMedio} />
            </Box>
            <Box>
              <Text ml="5px">Ticket médio</Text>
              <Text color="blue.800" fontWeight="bold" ml="5px">
                {isLoadingTotalizadores ? (
                  <BeatLoader size="6" color={blue800} />
                ) : (
                  moneyMask(totalizadores?.ticketMedio || 0, true)
                )}
              </Text>
            </Box>
          </Flex>
          <Flex pt={['24px', '24px', '0']} alignItems="center">
            <Box mr="5px">
              <Icon boxSize="33px" as={DashboardProdutosVendidos} />
            </Box>
            <Box>
              <Text ml="5px">Produto mais vendido</Text>
              <Text color="blue.800" fontWeight="bold" ml="5px">
                {isLoadingTotalizadores ? (
                  <BeatLoader size="6" color={blue800} />
                ) : (
                  totalizadores?.descricaoProdutoMaisVendido || '-'
                )}
              </Text>
            </Box>
          </Flex>
          <Flex />
        </Flex>
        <Text
          mb="60px"
          w="full"
          fontSize="12px"
          textAlign={['center', 'center', 'right']}
        >
          Informações referentes ao mês de {mesAtual?.label}
        </Text>

        <Flex mt="60px" justifyContent="center">
          <Grid
            templateColumns={[
              'repeat(1, 1fr)',
              'repeat(2, 1fr)',
              'repeat(3, 1fr)',
            ]}
            columnGap="16px"
            gap={6}
          >
            {painelAdministradorTrayEnum.option.map((trayOptions) => (
              <GridItem
                w="240px"
                key={trayOptions.rota}
                h="108px"
                opacity={trayOptions.isDisabled ? 0.3 : 1}
                transition="all 0.2s"
                boxShadow="0px 0px 4px #00000029"
                _hover={{
                  boxShadow: '0px 0px 4px rgba(85, 2, 178, 0.5)',
                }}
                bg="white"
                cursor={trayOptions.isDisabled ? 'not-allowed' : 'pointer'}
                borderRadius="5px"
                position="relative"
                onClick={() => {
                  if (!trayOptions.isDisabled) {
                    history.push(trayOptions.rota);
                  }
                }}
              >
                <Flex h="full" justifyContent="center" alignItems="center">
                  <Box>
                    <Flex h="full" justifyContent="center" alignItems="center">
                      <Icon fontSize="45px" as={trayOptions.icone} />
                    </Flex>
                    <Text textAlign="center" fontSize="16px">
                      {trayOptions.name}
                    </Text>
                  </Box>
                </Flex>
              </GridItem>
            ))}
          </Grid>
        </Flex>
      </Box>
    </>
  );
};
