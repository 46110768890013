export const operacaoTefOptions = [
  {
    label: 'Débito',
    value: '2',
  },
  {
    label: 'Crédito',
    value: '3',
  },
  {
    label: 'Pix',
    value: '4',
  },
];

export const parcelamentoTefOptions = [
  {
    label: 'Escolher no menu',
    value: '0',
  },
  {
    label: 'Pelo estabelecimento',
    value: '2',
  },
  {
    label: 'Pela administradora',
    value: '3',
  },
];

export const tipoCartaoOptions = [
  {
    label: 'Escolher no menu',
    value: '0',
  },
  {
    label: 'Magnético / Chip',
    value: '1',
  },
  {
    label: 'Não Magnético',
    value: '2',
  },
];
