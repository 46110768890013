import { useCallback, useEffect, useState } from 'react';
import { VStack, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import ConstanteRotas from 'constants/rotas';
import ConstanteRotasPDV from 'constants/rotasPDV';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import auth from 'modules/auth';
import api, { ResponseApi } from 'services/api';

import Input from 'components/Autenticacao/Input';
import ButtonSubmit from 'components/Autenticacao/Button/Submit';
import ButtonLink from 'components/Autenticacao/Button/Link';
import { ModalAtencao } from 'components/Modal/ModalAtencao';

import { useForm, yupResolver } from './validationForm';

type FormData = {
  usuario: string;
  senha: string;
};

interface RetornoLogin {
  token: string;
  refreshToken: string;
  validadeToken: Date;
  validadeRefreshToken: Date;
  loja: Loja;
  qtdLojas: number;
  possuiOutraSessaoAtiva: boolean;
  abrirPdvAposLogin: boolean;
  permissoes: Permissoes[];
}

interface Loja {
  id: string;
  fantasia: string;
}

interface Permissoes {
  funcionalidade: string;
  permissao: boolean;
  planos: number[];
}

export const Login = () => {
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const isMountedRef = useIsMountedRef();

  const {
    handleSubmit,
    setFocus,
    control,
    formState: { isValid, errors },
  } = useForm({
    resolver: yupResolver,
    mode: 'onChange',
  });

  const formIsInvalid = !isValid;

  const { hostname } = window.location;
  const nomeConta = hostname.split('.')[0];

  const tratarResponse = (response: ResponseApi<RetornoLogin>) => {
    if (response) {
      if (response.sucesso) {
        const {
          loja,
          permissoes,
          abrirPdvAposLogin,
          qtdLojas,
        } = response.dados;

        auth.setToken(response.dados);
        auth.setLoja(loja);
        auth.setPermissoes(permissoes);
        auth.setQtdLojas(qtdLojas);

        if (abrirPdvAposLogin) {
          history.push(ConstanteRotasPDV.PDV_HOME);
        } else {
          history.push(ConstanteRotas.DASHBOARD);
        }
      }

      if (response.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }
    }
  };

  const handlePushRecuperarSenha = () => {
    setIsLoading(true);

    history.push(ConstanteRotas.RECUPERAR_SENHA);

    if (isMountedRef.current) setIsLoading(false);
  };

  const handlePushAcesso = () => {
    setIsLoading(true);

    const urlApp = auth.getUrlApp();
    if (urlApp) window.location.replace(urlApp);

    if (isMountedRef.current) setIsLoading(false);
  };

  const onSubmit = handleSubmit<FormData>(async (data) => {
    setIsLoading(true);

    const ultimaLoja = auth.getLoja();

    let dataLogin = {
      ...data,
      executarLogOffSessaoParelela: false,
      lojaPadraoId: ultimaLoja.id ? ultimaLoja.id : null,
    };

    let response = await api.post<void, ResponseApi<RetornoLogin>>(
      ConstanteEnderecoWebservice.AUTENTICACAO_LOGIN,
      dataLogin
    );
    if (response) {
      if (response.sucesso) {
        if (response.dados.possuiOutraSessaoAtiva) {
          ModalAtencao({
            title: 'Encerrar sessão anterior?',
            text:
              'Existe uma sessão aberta desse usuário a partir de outro navegador. Clique em "Continuar" para confirmar a abertura desta nova sessão e encerrar a anterior.',
            showCancelButton: true,
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar',
            focusCancel: true,
            callback: async (ok?: boolean) => {
              if (ok) {
                setIsLoading(true);

                dataLogin = {
                  ...data,
                  executarLogOffSessaoParelela: true,
                  lojaPadraoId: dataLogin.lojaPadraoId,
                };

                response = await api.post<void, ResponseApi<RetornoLogin>>(
                  ConstanteEnderecoWebservice.AUTENTICACAO_LOGIN,
                  dataLogin
                );

                tratarResponse(response);
                window.location.reload();
              }
            },
          });
        } else {
          tratarResponse(response);
          window.location.reload();
        }
      } else {
        tratarResponse(response);
      }
    }
    if (isMountedRef.current) setIsLoading(false);
  });

  const verificarVersaoBancoDados = useCallback(async () => {
    setIsLoading(true);

    const dominio = window.location.host.split('/')[0].toLowerCase();

    const result = await api.get<void, ResponseApi<boolean>>(
      ConstanteEnderecoWebservice.CLIENTE_MULTIEMPRESA_VALIDAR_VERSAO_BANCO_DADOS,
      {
        params: { dominio },
      }
    );

    setIsLoading(false);

    if (result.sucesso && !result.dados) {
      toast.warning(
        'Estamos realizando uma atualização em seu aplicativo. Aguarde alguns instantes e tente novamente.'
      );
    } else {
      setIsLoading(false);
      setFocus('usuario');
    }
  }, [setFocus]);

  useEffect(() => {
    verificarVersaoBancoDados();
  }, [verificarVersaoBancoDados]);

  return (
    <VStack spacing={9}>
      <VStack as="form" w="full" spacing={8} onSubmit={onSubmit}>
        <VStack
          spacing={1}
          w="full"
          bg={{ base: '#00000030', md: '#00000030' }}
          borderRadius="2px"
          py={{ base: 3.5, md: 3.5 }}
          px={{ base: 6, md: 6 }}
          alignItems="flex-start"
        >
          <Text fontSize="14px" color="white">
            Bem-vindo a sua conta
          </Text>
          <Text
            fontSize="16px"
            color="login.text"
            fontWeight="semibold"
            textTransform="capitalize"
          >
            {nomeConta}
          </Text>
        </VStack>

        <VStack gap="50px" w="full">
          <Input
            id="usuario"
            name="usuario"
            label="Login"
            defaultValue=""
            autoFocus
            control={control}
            errorText={errors?.usuario?.message ?? ''}
            isInvalid={!!errors?.usuario?.message}
            isDisabled={isLoading}
            maxLength={256}
          />

          <Input
            id="senha"
            name="senha"
            type="password"
            label="Senha"
            defaultValue=""
            control={control}
            errorText={errors?.senha?.message ?? ''}
            isInvalid={!!errors?.senha?.message}
            isDisabled={isLoading}
            maxLength={50}
          />
        </VStack>

        <ButtonSubmit
          id="btnEntrar"
          isLoading={isLoading}
          isDisabled={isLoading || formIsInvalid}
          isInvalid={formIsInvalid}
        >
          Entrar
        </ButtonSubmit>
      </VStack>

      <VStack spacing={3.5}>
        <ButtonLink
          id="btnEsqueciSenha"
          onClick={handlePushRecuperarSenha}
          isDisabled={isLoading}
        >
          Esqueci minha senha
        </ButtonLink>

        <ButtonLink onClick={handlePushAcesso} isDisabled={isLoading}>
          Entrar com outra conta
        </ButtonLink>
      </VStack>
    </VStack>
  );
};
