import {
  Box,
  useMediaQuery,
  Text,
  useToken,
  VStack,
  Flex,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { renderToString } from 'react-dom/server';

import { moneyMask } from 'helpers/format/fieldsMasks';

export interface DashboardInicialDetalhesPorDiaInterface {
  dia: number;
  valorTotal: number;
  valorTotalDevolucao: number;
}

export interface DashboardInicialTotalizadoresMesAtualInterface {
  totalVendas: number;
  ticketMedio: number;
  produtosVendidos: number;
  produtosPorAtendimento: number;
  faturamento: number;
  dias: DashboardInicialDetalhesPorDiaInterface[];
}

interface MesAtualInterface {
  dias: DashboardInicialDetalhesPorDiaInterface[] | undefined;
}

const MesAtual = ({ dias }: MesAtualInterface) => {
  const [
    infoGraficoHorizontalState,
    setInfoGraficoHorizontalState,
  ] = useState<any>(undefined);

  const [infoGraficoVerticalState, setInfoGraficoVerticalState] = useState<any>(
    undefined
  );

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const [
    primary50,
    indigo300,
    indigo100,
    gray700,
    gray300,
    gray900,
    secondary300,
  ] = useToken('colors', [
    'primary.50',
    'indigo.300',
    'indigo.100',
    'gray.700',
    'gray.300',
    'gray.600',
    'secondary.300',
  ]);

  const customLegend = useCallback(
    (dataPointIndex: any, seriesIndex: number) => {
      if (dataPointIndex === undefined) return '';

      const dia = (dias
        ? dias[dataPointIndex]
        : []) as DashboardInicialDetalhesPorDiaInterface;

      const isVendas = seriesIndex === 0;

      return renderToString(
        <VStack
          borderRadius="md"
          boxShadow="md"
          bg={gray900}
          color="white"
          py="10"
          alignItems="flex-start"
          minWidth="250px"
        >
          <Flex pl="20">
            {`Dia ${dia.dia}`}
            <Text pl="5px" color={isVendas ? secondary300 : indigo100}>
              {isVendas ? 'R$' : 'R$ -'}{' '}
              {moneyMask(
                isVendas ? dia.valorTotal : dia.valorTotalDevolucao,
                false
              )}
            </Text>
          </Flex>
        </VStack>
      );
    },
    [dias, gray900, secondary300, indigo100]
  );

  const carregarGraficoVertical = useCallback(() => {
    return {
      series: [
        {
          name: 'Vendas',
          data: dias?.map((item) => item.valorTotal),
        },
      ],
      options: {
        chart: {
          zoom: {
            enabled: false,
          },
          type: 'bar',
          id: 'venda-mes-atual-vertical',
          toolbar: {
            show: false,
          },
          foreColor: gray700,
          fontFamily: 'Nunito, sans-serif',
          fontSize: '12px',
        },
        title: {
          align: 'left',
          margin: 30,
          style: {
            fontSize: '14px',
            color: primary50,
            fontWeight: 'normal',
          },
        },

        fill: {
          opacity: 1,
        },

        dataLabels: {
          enabled: false,
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
        },

        xaxis: {
          categories: dias?.map((item) => item.dia.toString().padStart(2, '0')),
          labels: {
            show: true,
            style: {
              colors: [gray700],
            },
            formatter: (value: any) => value,
            rotate: 0,
            rotateAlways: false,
          },
        },
        grid: {
          borderColor: gray300,
        },
        annotations: {
          yaxis: [
            {
              y: 0,
              strokeDashArray: 0,
              borderColor: primary50,
              borderWidth: 1,
            },
          ],
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          title: {
            style: {
              color: gray700,
            },
          },
          labels: {
            show: true,
            style: {
              colors: [gray700],
            },
            formatter: (v: any) => {
              if (v < 0) {
                return `R$ -${moneyMask(Math.abs(v), false)}`;
              }
              return moneyMask(v, true);
            },
          },
          fill: {
            opacity: 1,
          },
          lines: {
            show: true,
          },
        },
        tooltip: {
          enabled: true,
          theme: 'dark',
          marker: {
            show: false,
          },
          custom({ dataPointIndex, seriesIndex }: any) {
            return customLegend(dataPointIndex, seriesIndex);
          },
        },
        plotOptions: {
          bar: {
            distributed: false,
            columnWidth: '80%',

            colors: {
              ranges: [
                {
                  from: -Infinity,
                  to: 0,
                  color: indigo300,
                },
                {
                  from: 0,
                  to: Infinity,
                  color: primary50,
                },
              ],
            },
          },
          columnWidth: '200px',
        },
        noData: {
          text: 'Não existem dados para serem exibidos',
          style: {
            color: primary50,
          },
        },
      },
    } as any;
  }, [dias, gray700, primary50, gray300, indigo300, customLegend]);

  const carregarGraficoHorizontal = useCallback(() => {
    return {
      series: [
        {
          name: 'Vendas',
          data: dias?.map((item) => item.valorTotal),
        },
      ],

      options: {
        chart: {
          zoom: {
            enabled: false,
          },
          type: 'bar',
          id: 'venda-mes-atual-horizontal',
          toolbar: {
            show: false,
          },
          foreColor: gray700,
          fontFamily: 'Nunito, sans-serif',
          fontSize: '12px',
        },
        title: {
          align: 'left',
          margin: 24,
          offsetX: 8,
          offsetY: 0,
          style: {
            fontSize: '14px',
            color: primary50,
            fontWeight: 'normal',
          },
        },
        colors: [primary50, indigo300],
        annotations: {
          xaxis: [
            {
              x: 0,
              strokeDashArray: 0,
              borderColor: primary50,
              borderWidth: 1,
            },
          ],
        },
        fill: {
          opacity: 1,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: dias?.map((item) => item.dia.toString().padStart(2, '0')),
          labels: {
            show: true,
            style: {
              colors: [gray700],
            },
            rotate: -30,
            rotateAlways: true,
            formatter: (v: any) => moneyMask(v, true),
          },
          lines: {
            show: false,
          },
        },
        grid: {
          borderColor: gray300,
          padding: {
            bottom: 20,
          },
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        yaxis: {
          tickAmount: 8,
          axisBorder: {
            show: false,
          },
          title: {
            style: {
              color: gray700,
            },
          },
          labels: {
            show: true,
            style: {
              colors: [gray700],
            },
            formatter: (v: any) => {
              return v;
            },
            offsetY: 4,
          },
          fill: {
            opacity: 1,
          },
          lines: {
            show: true,
          },
        },
        tooltip: {
          enabled: true,
          theme: 'dark',
          marker: {
            show: false,
          },
          custom({ dataPointIndex, seriesIndex }: any) {
            return customLegend(dataPointIndex, seriesIndex);
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: '80%',
            colors: {
              ranges: [
                {
                  from: -Infinity,
                  to: 0,
                  color: indigo300,
                },
                {
                  from: 0,
                  to: Infinity,
                  color: primary50,
                },
              ],
            },
          },
          columnWidth: '200px',
        },
        noData: {
          text: 'Não existem dados para serem exibidos',
          style: {
            color: primary50,
          },
        },
      },
    } as any;
  }, [indigo300, customLegend, dias, gray300, gray700, primary50]);
  // eslint-disable-next-line no-console

  const carregarGrafico = useCallback(() => {
    if (isLargerThan900) {
      setInfoGraficoVerticalState(carregarGraficoVertical());
      setInfoGraficoHorizontalState(null);
    } else {
      setInfoGraficoHorizontalState(carregarGraficoHorizontal());
      setInfoGraficoVerticalState(null);
    }
  }, [isLargerThan900, carregarGraficoVertical, carregarGraficoHorizontal]);

  useEffect(() => {
    carregarGrafico();
  }, [carregarGrafico]);

  return (
    <Box
      boxShadow="lg"
      borderRadius="md"
      height="full"
      maxH={{ base: '930px', md: '327px' }}
      bg="white"
      pt="10px"
      px="2"
      position="relative"
      mt={{ base: '24px', md: '32px', lg: '40px' }}
    >
      <Text
        mt="5px"
        ml="15px"
        top="2px"
        position="absolute"
        fontSize="14px"
        color={primary50}
        fontWeight="normal"
      >
        Faturamento
      </Text>
      {infoGraficoVerticalState && isLargerThan900 && (
        <ReactApexChart
          options={infoGraficoVerticalState.options}
          series={infoGraficoVerticalState.series}
          type={infoGraficoVerticalState.options.chart.type}
          height="100%"
          style={{
            marginTop: '0px',
          }}
        />
      )}

      {infoGraficoHorizontalState && !isLargerThan900 && (
        <ReactApexChart
          options={infoGraficoHorizontalState.options}
          series={infoGraficoHorizontalState.series}
          type={infoGraficoHorizontalState.options.chart.type}
          height="923px"
        />
      )}
    </Box>
  );
};

export default MesAtual;
