import { useCallback, useImperativeHandle, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { cadastrarCategoriaSmartPos } from 'services/smartPos';
import { CategoriaProdutoProps } from 'store/SmartPos/CategoriaProdutos';
import { useSmartPosContext } from 'store/SmartPos/SmartPosContext';

import Loading from 'components/Layout/Loading/LoadingPadrao';
import { CategoriaProduto as CategoriaProdutoSmartPos } from 'components/SmartPos/CategoriaProdutoItem';

import { Container } from '../Layout/Container';

export const CategoriaProduto = () => {
  const [categoriaSelecionada, setCategoriaSelecionada] = useState<string[]>(
    []
  );

  const [isLoading, setIsLoading] = useState(false);
  const [voltarAlteracoes, setVoltarAlteracoes] = useState(false);

  const { ref, setIsStepAlterada, isStepAlterada } = useSmartPosContext();

  const obterCategoriaSelecionada = useCallback(
    (categorias: CategoriaProdutoProps[]) => {
      const filtrandoCategoriasSelecionadas = categorias.filter(
        (categoria) => categoria.isChecked
      );

      const categoriasSelecionadas = filtrandoCategoriasSelecionadas.map(
        (categoria) => categoria.id
      );
      setCategoriaSelecionada(categoriasSelecionadas);
    },
    []
  );

  const categoriaIsAlterada = (categoria: boolean) => {
    setIsStepAlterada(categoria);
  };

  const cadastrarCategoriasSelecionadas = useCallback(async () => {
    setIsLoading(true);

    const response = await cadastrarCategoriaSmartPos(categoriaSelecionada);

    if (response) {
      toast.success('Cadastrado com sucesso');
      setIsStepAlterada(false);
    }

    setIsLoading(false);
  }, [categoriaSelecionada, setIsStepAlterada]);

  useImperativeHandle(ref, () => ({
    handleSalvar: () => {
      cadastrarCategoriasSelecionadas();
    },
    handleCancelar: () => {
      setVoltarAlteracoes(!voltarAlteracoes);
    },
  }));

  return (
    <Container>
      {isLoading && <Loading />}

      <Box w={['full', 'full', 'full', '70%']} overflowX="scroll">
        <Box ml="-10px" w={['800px', '800px', 'full']}>
          <CategoriaProdutoSmartPos
            obterCategoriaSelecionada={obterCategoriaSelecionada}
            voltarAlteracoes={voltarAlteracoes}
            isSelecionarTodos
            categoriaIsAlterada={categoriaIsAlterada}
          />
        </Box>
      </Box>
    </Container>
  );
};
