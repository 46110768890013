import { GridItem, Button, Icon, Box, Text, Tr, Td } from '@chakra-ui/react';

import { SalvarInserirNovoIcon } from 'icons';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { PagedTable } from 'components/update/Table/PagedTable';
import {
  ActionsMenu,
  ActionMenuItem,
} from 'components/update/Table/ActionsMenu';

import { useFichaTecnica } from './hooks';

export const FichaTecnica = () => {
  const {
    handleExcluirTamanho,
    totalRegistros,
    isLoading,
    listProdutos,
    handleEditarTamanho,
    pagedTableRef,
    handleAbrirModalCadastrarProduto,
    paginationHandle,
    isDefaultSize,
    formatDecimalPlaces,
    readonly,
  } = useFichaTecnica();

  return (
    <SimpleGridForm>
      <GridItem colSpan={12}>
        <Button
          type="button"
          variant="solid"
          colorScheme="blue.700"
          leftIcon={<Icon as={SalvarInserirNovoIcon} />}
          marginBottom="5px"
          maxWidth="225px"
          maxHeight="34px"
          w="100%"
          onClick={() => handleAbrirModalCadastrarProduto()}
          fontWeight="normal"
        >
          Adicionar produtos
        </Button>
        <Box
          p={6}
          borderRadius="5px"
          overflowY="auto"
          bg="gray.50"
          borderWidth="1px"
          borderColor="gray.200"
        >
          <PagedTable
            ref={pagedTableRef}
            isLoading={isLoading}
            variant="card"
            bg="transparent"
            boxShadow="none"
            paddingRight="5px"
            paddingLeft="5px"
            pb="10px"
            paginationHasDivider={false}
            loadColumnsData={paginationHandle}
            itemsTotalCount={totalRegistros}
            defaultKeyOrdered="nome"
            tableHeaders={[
              {
                key: 'produto',
                content: 'Produto',
                isOrderable: false,
              },
              {
                key: 'quantidade',
                content: isDefaultSize ? 'Quantidade' : undefined,
                isOrderable: false,
                isNumeric: true,
              },
              { key: 'acoes', content: 'Ações', isOrderable: false, w: '1px' },
            ]}
            renderTableRows={(listProdutos || []).map((produto) => {
              const productSizes = produto.produtoTamanhosFichaTecnica;
              const infoDefaultSize = isDefaultSize ? productSizes[0] : null;

              const {
                id: productItemId,
                materiaPrimaFinal: finalRawMaterial,
                produtoItem: { nome: productItemDescription },
              } = produto;

              return (
                <>
                  <Tr>
                    <Td whiteSpace="nowrap">
                      <Text
                        w="min"
                        cursor="pointer"
                        onClick={() =>
                          handleEditarTamanho({
                            productSizes,
                            productItemDescription,
                            productItemId,
                            finalRawMaterial,
                          })
                        }
                        _hover={{ textDecor: 'underline' }}
                      >
                        {productItemDescription}
                      </Text>
                    </Td>
                    {isDefaultSize ? (
                      <Td isNumeric>
                        <Text>
                          {formatDecimalPlaces(
                            infoDefaultSize?.quantidade || 0
                          )}
                        </Text>
                      </Td>
                    ) : (
                      <Td />
                    )}
                    <Td>
                      <ActionsMenu
                        isDisabled={readonly}
                        items={
                          [
                            {
                              content: 'Editar',
                              onClick: () =>
                                handleEditarTamanho({
                                  productSizes,
                                  productItemDescription,
                                  productItemId,
                                  finalRawMaterial,
                                }),
                            },
                            {
                              content: 'Remover',
                              onClick: () => handleExcluirTamanho(produto.id),
                            },
                          ].filter(Boolean) as ActionMenuItem[]
                        }
                      />
                    </Td>
                  </Tr>
                  <Box h="5px" />
                </>
              );
            })}
          />
        </Box>
      </GridItem>
    </SimpleGridForm>
  );
};
