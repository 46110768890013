import { useDisclosure } from '@chakra-ui/react';
import ConstanteFuncionalidades from 'constants/permissoes';
import auth from 'modules/auth';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import ModalAutorizacaoFuncionalidade from 'components/Modal/ModalAutorizacaoFuncionalidade';
import AcrescimosDescontosModal from 'pages/PDV/Pagamento/InternalHeader/AcrescimosDescontosModal';
import LogAuditoriaTelaEnum from 'constants/enum/logAuditoriaTela';
import { usePagamentoContext } from './Pagamento';

interface AcrescimosDescontosContextProps {
  handleOpenAcrescimosDescontosModal: () => void;
  acrescimosDescontosValor: number;
  corAcrescimoDesconto: string;
}

export const AcrescimosDescontosContext = createContext<AcrescimosDescontosContextProps>(
  {} as AcrescimosDescontosContextProps
);

interface AcrescimosDescontosProviderProps {
  children: React.ReactNode;
}

export default function AcrescimosDescontosProvider({
  children,
}: AcrescimosDescontosProviderProps): JSX.Element {
  const {
    isOpen: isModalAcrescimosDescontosOpen,
    onClose: onModalAcrescimosDescontosClose,
    onOpen: onModalAcrescimosDescontosOpen,
  } = useDisclosure();

  const {
    movimentacoesFinanceiras,
    totais: {
      valorTotalAcrescimo = 0,
      valorTotalOutrasDespesas = 0,
      valorTotalFrete = 0,
      valorTotalDescontoAdicional = 0,
      valorTotalDescontoItem = 0,
    } = {},
  } = usePagamentoContext();

  const [
    modalAutorizacaoDescontoAcrescimoIsOpen,
    setModalAutorizacaoDescontoAcrescimoIsOpen,
  ] = useState(false);
  const [
    chaveAutorizacaoAcrescimosDescontos,
    setChaveAutorizacaoAcrescimosDescontos,
  ] = useState<string>();

  const permissaoConcederAcrescimosDescontos = useMemo(
    () =>
      auth.possuiPermissao(
        ConstanteFuncionalidades.PDV_CONCEDER_ACRESCIMOS_DESCONTOS.codigo
      ),
    []
  );

  const handleOpenAcrescimosDescontosModal = (chave?: string) => {
    if (permissaoConcederAcrescimosDescontos.permitido || chave) {
      setChaveAutorizacaoAcrescimosDescontos(chave);

      onModalAcrescimosDescontosOpen();
    } else {
      setModalAutorizacaoDescontoAcrescimoIsOpen(true);
    }
  };

  const acrescimosDescontosValor =
    valorTotalAcrescimo +
    valorTotalOutrasDespesas +
    valorTotalFrete -
    (valorTotalDescontoAdicional + valorTotalDescontoItem);

  const corAcrescimoDesconto = (() => {
    let cor = 'white';

    if (acrescimosDescontosValor < 0) {
      cor = 'red.500';
    } else if (acrescimosDescontosValor > 0) {
      cor = 'blue.300';
    }

    return cor;
  })();

  useEffect(() => {
    if (!isModalAcrescimosDescontosOpen) {
      setChaveAutorizacaoAcrescimosDescontos(undefined);
    }
  }, [isModalAcrescimosDescontosOpen]);

  return (
    <AcrescimosDescontosContext.Provider
      value={{
        handleOpenAcrescimosDescontosModal,
        corAcrescimoDesconto,
        acrescimosDescontosValor,
      }}
    >
      {children}

      <AcrescimosDescontosModal
        isOpen={isModalAcrescimosDescontosOpen}
        onClose={onModalAcrescimosDescontosClose}
        isReadOnly={
          !!movimentacoesFinanceiras && movimentacoesFinanceiras.length > 0
        }
        chavePermissaoTemporaria={chaveAutorizacaoAcrescimosDescontos}
      />

      <ModalAutorizacaoFuncionalidade
        isOpen={modalAutorizacaoDescontoAcrescimoIsOpen}
        setIsOpen={setModalAutorizacaoDescontoAcrescimoIsOpen}
        autorizado={handleOpenAcrescimosDescontosModal}
        titulo={
          ConstanteFuncionalidades.PDV_CONCEDER_ACRESCIMOS_DESCONTOS.titulo
        }
        descricao={
          ConstanteFuncionalidades.PDV_CONCEDER_ACRESCIMOS_DESCONTOS.descricao
        }
        tela={LogAuditoriaTelaEnum.PDV}
        permissoes={[
          ConstanteFuncionalidades.PDV_CONCEDER_ACRESCIMOS_DESCONTOS.codigo,
        ]}
      />
    </AcrescimosDescontosContext.Provider>
  );
}

export function useAcrescimosDescontosContext(): AcrescimosDescontosContextProps {
  const context = useContext(AcrescimosDescontosContext);

  if (!context)
    throw new Error(
      'useAcrescimosDescontosContext must be used within a AcrescimosDescontosProvider.'
    );

  return context;
}
