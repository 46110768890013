import { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { FormProvider, useForm } from 'react-hook-form';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import { UncontrolledForm } from '..';
import { useDeliveryPersonForm } from '../hooks';
import { FormData, defaultValues, yupResolver } from '../validationForm';

const CadastrarEntregador = () => {
  const { redirectToDeliveryPersonList } = useDeliveryPersonForm();

  const formMethods = useForm<FormData>({
    defaultValues,
    resolver: yupResolver,
  });

  const { handleSubmit, reset } = formMethods;

  const [isLoading, setIsLoading] = useState(false);

  const registerDeliveryPerson = useCallback(async (data: FormData) => {
    const response = await api.post<void, ResponseApi>(
      ConstanteEnderecoWebservice.ENTREGADOR,
      {
        ...data,
      }
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response?.sucesso) {
        toast.success('Entregador adicionado com sucesso');
        return { success: true };
      }
    }

    return { success: false };
  }, []);

  const handleSave = handleSubmit(async (data) => {
    setIsLoading(true);
    const { success } = await registerDeliveryPerson(data);

    if (success) {
      redirectToDeliveryPersonList();
    }
    setIsLoading(false);
  });

  const handleSaveAndReset = handleSubmit(async (data) => {
    setIsLoading(true);
    const { success } = await registerDeliveryPerson(data);

    if (success) {
      reset();
    }
    setIsLoading(false);
  });

  return (
    <FormProvider {...formMethods}>
      <UncontrolledForm
        handleSave={handleSave}
        handleSaveAndReset={handleSaveAndReset}
        isLoading={isLoading}
      />
    </FormProvider>
  );
};

export { CadastrarEntregador };
