import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import { useFormContext, useWatch } from 'react-hook-form';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteFuncionalidades from 'constants/permissoes';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import { UsuariosEnum } from 'constants/enum/usuariosEnum';

import { TabForwardRefData, TabPadrao } from 'components/TabPadrao';
import DadosGerais from 'pages/Usuarios/Formulario/TabsContent/DadosGerais';
import PermissoesTab from 'pages/Usuarios/Formulario/TabsContent/Permissoes';
import Lojas, {
  LojasInterface,
} from 'pages/Usuarios/Formulario/TabsContent/Lojas';

import { defaultValues } from './validationForm';

interface UncontrolledFormInterface {
  readonly?: boolean;
  isLoading: boolean;
  isAlteracao: boolean;
  usuarioId?: string;
  isAdministrador?: boolean;
  isLicencaAtiva?: boolean;
  setListaVendedores?: React.Dispatch<React.SetStateAction<LojasInterface[]>>;
}

export interface UncontrolledFormRefInterface {
  lojas: LojasInterface[];
  setLojasModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  focusTabDadosGerais: () => void;
  focusTabLojas: () => void;
  focusTabPerfil: () => void;
  reset: () => void;
}

const UncontrolledForm = forwardRef(
  (
    {
      readonly,
      isLoading: isLoadingForm,
      isAlteracao,
      usuarioId,
      isAdministrador,
      isLicencaAtiva,
      setListaVendedores,
    }: UncontrolledFormInterface,
    ref
  ) => {
    const isMountedRef = useIsMountedRef();
    const tabRef = useRef<TabForwardRefData>(null);

    const [lojas, setLojas] = useState<LojasInterface[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const listLojasSelecionadas = useWatch({
      name: `lojaSelecionada.`,
    });

    const { setValue, reset } = useFormContext();

    const getLojas = useCallback(async () => {
      setIsLoading(true);
      const response = await api.get<void, ResponseApi<LojasInterface[]>>(
        ConstanteEnderecoWebservice.LOJA_LISTAR_COM_ENDERECO_E_LOCAL_ESTOQUE
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }

        if (response.sucesso && response.dados && isMountedRef.current) {
          setLojas(response.dados);
          setValue('lojas', response.dados);
        }
      }
      setIsLoading(false);
    }, [isMountedRef, setValue]);

    const getLocalEstoquePadrao = useCallback(() => {
      lojas.forEach((lojaItem) => {
        const storeIsSelected =
          (listLojasSelecionadas || [])[lojaItem?.id] || false;

        if (isAlteracao && storeIsSelected) return;

        const localEstoquePadraoId = lojaItem?.locaisEstoque.find(
          (localItem) => localItem?.padraoSistema
        )?.localEstoqueId;

        setValue(`localEstoqueId.${lojaItem.id}`, localEstoquePadraoId);
      });
    }, [isAlteracao, listLojasSelecionadas, lojas, setValue]);

    useImperativeHandle(ref, () => ({
      usuarioId,
      lojas,
      focusTabDadosGerais: () => {
        tabRef?.current?.handleAtualizarTab(UsuariosEnum.DADOS_GERAIS);
      },
      focusTabLojas: () => {
        tabRef?.current?.handleAtualizarTab(UsuariosEnum.LOJAS);
      },
      focusTabPerfil: () => {
        tabRef?.current?.handleAtualizarTab(UsuariosEnum.PERMISSOES);
      },
      reset: () => {
        const locaisEstoquePadrao: Record<string, string> = {};

        lojas.forEach((lojaItem) => {
          const localEstoquePadraoId = lojaItem?.locaisEstoque.find(
            (localItem) => localItem?.padraoSistema
          )?.localEstoqueId;

          locaisEstoquePadrao[
            `localEstoqueId.${lojaItem.id}`
          ] = `${localEstoquePadraoId}`;
        });

        reset({
          ...defaultValues,
          ...locaisEstoquePadrao,
          lojas,
        });
      },
    }));

    useEffect(() => {
      getLocalEstoquePadrao();
    }, [getLocalEstoquePadrao]);

    useEffect(() => {
      if (isAlteracao) {
        if (usuarioId) {
          getLojas();
        }
      } else {
        getLojas();
      }
    }, [getLojas, usuarioId, isAlteracao]);

    useEffect(() => {
      if (setListaVendedores && lojas) {
        setListaVendedores(lojas);
      }
    }, [lojas, setListaVendedores]);

    return (
      <>
        <TabPadrao
          ref={tabRef}
          tabs={[
            {
              eventKey: UsuariosEnum.DADOS_GERAIS,
              title: 'Dados gerais',
              tabWidth: '178px',
              content: (
                <DadosGerais
                  readonly={readonly}
                  isLoading={Boolean(isLoading || isLoadingForm)}
                  lojas={lojas}
                  isAlteracao={isAlteracao}
                  isLicencaAtiva={isLicencaAtiva}
                  isAdministrador={isAdministrador}
                />
              ),
            },
            {
              eventKey: UsuariosEnum.LOJAS,
              title: 'Lojas',
              tabWidth: '178px',
              funcionalidade: isAdministrador
                ? undefined
                : isAlteracao
                ? ConstanteFuncionalidades.USUARIO_ALTERAR_LOJAS
                : undefined,
              content: (
                <Lojas
                  readonly={readonly || isAdministrador}
                  lojas={lojas}
                  usuarioLogado={usuarioId}
                />
              ),
            },
            {
              eventKey: UsuariosEnum.PERMISSOES,
              title: 'Permissões',
              tabWidth: '178px',
              disabled: isAdministrador,
              funcionalidade: isAlteracao
                ? ConstanteFuncionalidades.USUARIO_ALTERAR_PERMISSOES
                : undefined,
              content: <PermissoesTab readonly={readonly} />,
            },
          ]}
        />
      </>
    );
  }
);

export default UncontrolledForm;
