import { useCallback, useEffect, useState } from 'react';
import { Text, VStack, Box, Flex, Icon, useMediaQuery } from '@chakra-ui/react';
import { BsArrowRight } from 'react-icons/bs';
import { toast } from 'react-toastify';

import {
  ContainerIntegracaoFixo,
  useSmartPosEtapasContext,
} from 'store/SmartPos/SmartPosEtapasContext';
import { IdentificacaoEtapasSmartPos } from 'constants/enum/IdentificacaoEtapasSmartPos';
import { ItensImpressao } from 'constants/tipoImpressaoSmartPos';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { getImpressaoEscolhidaSmartPos } from 'services/smartPos';

import { ImpressaoIcon } from 'icons';

import { FooterSmartPos } from '../Layout/FooterSmartPos';
import { HeaderSmartPos } from '../Layout/Header';

type ListTipoImpressaoProps = {
  title: string;
  subTitle?: string;
  isChecked: boolean;
  value: number;
};

export const TipoImpressao = () => {
  const [listTipoImpressao, setListTipoImpressao] = useState<
    ListTipoImpressaoProps[]
  >(ItensImpressao.properties);
  const {
    setActiveStep,
    animacaoLoading,
    setIsLoading,
  } = useSmartPosEtapasContext();

  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    const impressaoSelecionada = listTipoImpressao.find(
      (itemImpressao) => itemImpressao.isChecked
    )?.value;
    const response = await api.put<void, ResponseApi>(
      ConstanteEnderecoWebservice.INTEGRACAO_ALTERAR_TIPO_IMPRESSAO,
      {
        tipoImpressao: impressaoSelecionada,
      }
    );
    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
        setIsLoading(false);
      }
      if (response.sucesso) {
        const isNaoUtilizarImpressao = listTipoImpressao.find(
          (tipoImpressao) =>
            tipoImpressao.value === ItensImpressao.NAO_UTILIZAR_IMPRESSAO
        );

        if (isNaoUtilizarImpressao?.isChecked) {
          setActiveStep(IdentificacaoEtapasSmartPos.SENHA_ADM);
        } else {
          setActiveStep(IdentificacaoEtapasSmartPos.IMAGEM_PARA_IMPRESSAO);
        }
      }
    }
    setIsLoading(false);
  }, [listTipoImpressao, setActiveStep, setIsLoading]);

  const handleSelecionarItemImpressao = useCallback(
    (indexSelecionado: number) => {
      setListTipoImpressao((prev) =>
        prev.map((itemImpressao, indexImpressao) => ({
          ...itemImpressao,
          isChecked: indexSelecionado === indexImpressao,
        }))
      );
    },
    []
  );

  const isItemImpressaoSelecionado = listTipoImpressao.some(
    (itemImpressao) => itemImpressao.isChecked
  );

  const handleVoltar = () => {
    setActiveStep(IdentificacaoEtapasSmartPos.CATEGORIA_PRODUTO);
  };

  useEffect(() => {
    setIsLoading(true);
    const getImpressao = async () => {
      const response = await getImpressaoEscolhidaSmartPos();

      if (response !== null) {
        setListTipoImpressao((prev) =>
          prev.map((itemPrev) => ({
            ...itemPrev,
            isChecked: itemPrev.value === response,
          }))
        );
        animacaoLoading();
      }
      animacaoLoading();
    };
    getImpressao();
  }, [animacaoLoading, setIsLoading]);

  return (
    <>
      <ContainerIntegracaoFixo height={isLargerThan700 ? '100vh' : '100%'}>
        <HeaderSmartPos
          title="Tipo de impressão"
          handleVoltar={handleVoltar}
          icon={ImpressaoIcon}
        />
        <Box>
          <VStack
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing="24px"
          >
            {listTipoImpressao.map((tipoImpressao, index) => (
              <Flex
                borderWidth="1px"
                borderStyle="solid"
                borderColor={tipoImpressao.isChecked ? 'purple.50' : 'gray.50'}
                _hover={{
                  borderColor: 'rgba(85, 2, 178, 0.5)',
                  transition: 'all 0.2s',
                }}
                transition="all 0.2s"
                bg={tipoImpressao.isChecked ? 'purple.50' : ''}
                cursor="pointer"
                pt="16px"
                pl={['10px', '10px', '30px']}
                pr={['10px', '10px', '30px']}
                pb="16px"
                w="full"
                onClick={() => handleSelecionarItemImpressao(index)}
                justifyContent="flex-start"
              >
                <Box mr="9.5px">
                  <Icon boxSize="26px" color="primary.50" as={BsArrowRight} />
                </Box>
                <Box>
                  <Text color="primary.50" fontSize="18px">
                    {tipoImpressao.title}
                  </Text>
                  <Text letterSpacing="0px" color="gray.700" fontSize="14px">
                    {tipoImpressao.subTitle}
                  </Text>
                </Box>
              </Flex>
            ))}
          </VStack>
        </Box>
      </ContainerIntegracaoFixo>
      <FooterSmartPos
        mt={['20px', '0', '0']}
        mb={['20px', '0', '0']}
        handleAvancar={handleSubmit}
        isDisabledAvancar={!isItemImpressaoSelecionado}
      />
    </>
  );
};
