import { useCallback, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteRotas from 'constants/rotas';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import api, { ResponseApi } from 'services/api';

import { EtiquetasPersonalizadas } from '../index';
import { PropertiesEtiquetas } from '../validationForm';

type VisualizarEtiquetaProps = {
  id: string;
  nome: string;
  configuracoes: string;
  ativo: boolean;
};

type RouteParams = {
  id: string;
};

export function VisualizarEtiqueta() {
  const [
    obterInformacoesEtiqueta,
    setObterInformacoesEtiqueta,
  ] = useState<PropertiesEtiquetas>();
  const [obterNomeEtiqueta, setObterNomeEtiqueta] = useState<string>('');
  const [obterIdEtiqueta, setObterIdEtiqueta] = useState<string>('');

  const history = useHistory();
  const isMountedRef = useIsMountedRef();
  const { id } = useParams<RouteParams>();

  const handleGetContaFinanceira = useCallback(async () => {
    const response = await api.get<void, ResponseApi<VisualizarEtiquetaProps>>(
      ConstanteEnderecoWebservice.ETIQUETA_PERSONALIZADA_OBTER,
      {
        params: { id },
      }
    );

    if (response?.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (response?.sucesso && isMountedRef.current) {
      let listaInformacoesVisualizar = null;

      try {
        listaInformacoesVisualizar = JSON.parse(
          response.dados.configuracoes.toString() || ''
        );
      } catch (e) {
        listaInformacoesVisualizar = null;
      }
      setObterInformacoesEtiqueta(listaInformacoesVisualizar);
      setObterNomeEtiqueta(response.dados.nome);
      setObterIdEtiqueta(response?.dados.id);
    } else {
      history.push(ConstanteRotas.CONTA_FINANCEIRA);
    }
  }, [history, isMountedRef, id]);

  useEffect(() => {
    handleGetContaFinanceira();
  }, [handleGetContaFinanceira]);

  return (
    <EtiquetasPersonalizadas
      isReadonly
      obterNomeEtiqueta={obterNomeEtiqueta}
      obterIdEtiqueta={obterIdEtiqueta}
      listaInformacoesVisualizar={obterInformacoesEtiqueta}
    />
  );
}
