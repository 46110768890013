const OperacoesPresencaCompradorEnum = {
  NaoSeAplica: 0,
  OperacaoPresencial: 1,
  OperacaoPelaInternet: 2,
  OperacaoPorTeleAtendimento: 3,
  OperacaoEntregaDomicilio: 4,
  OperacaoPresencialForaEstabelecimento: 5,
  OperacaoNaoPresencial: 9,

  properties: {
    1: { name: 'Não se aplica', value: 0 },
    2: { name: 'NFC-e em operação com entrega a domicílio', value: 4 },
    3: { name: 'Operação não presencial, outros', value: 9 },
    4: { name: 'Operação não presencial, pela internet', value: 2 },
    5: { name: 'Operação não presencial, teleatendimento', value: 3 },
    6: { name: 'Operação presencial', value: 1 },
    7: { name: 'Operação presencial, fora do estabelecimento', value: 5 },
  },
};

export default OperacoesPresencaCompradorEnum;
