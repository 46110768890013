import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Icon,
  Flex,
  Heading,
  Text,
  Button,
  VStack,
  useMediaQuery,
  HStack,
  useToken,
} from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { create, InstanceProps } from 'react-modal-promise';
import { toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import { CompartilharEmailIcon } from 'icons';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import CreatableSelect from 'components/PDV/Select/CreatableSelect';
import { ModalAtencao } from 'components/Modal/ModalAtencao';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { useForm, yupResolver, emailIsValid } from './validationForm';

type FiltersCurvaABC = {
  periodoVendasInicio: string;
  periodoVendasFim: string;
  agregador: number;
  ordenacao: number;
};

type FormData = {
  emails: string[];
};

interface ContatoAdicional {
  nome: string;
  email?: string;
}

interface Cliente {
  email?: string;
  contatosAdicionais: ContatoAdicional[];
}

type FiltrosProps = {
  [x: string]: any;
};
interface EmailModalProps
  extends Omit<ModalProps, 'children' | 'isOpen' | 'onClose'>,
    InstanceProps<any> {
  cliente?: Cliente;
  documentoFiscalId?: string;
  operacaoId?: string;
  idContas?: string[];
  descricao?: string;
  filtroListaPaginada?: any;
  identificacaoTipoOperacao?: string | null;
  status?: string | null;
  dataEmissaoFim?: Date | null;
  dataEmissaoInicio?: Date | null;
  lojaId?: string | null;
  caixaMovimentacao?: {
    caixaMovimentacaoId: string;
    listarMovimentacoes: boolean;
  };
  filtrosNotaFiscal?: FiltrosProps;
  filtrosConferenciaEstoque?: {
    conferenciaEstoqueId?: string;
    estoqueCompleto: boolean;
    corrigirApenasItensConferidos: boolean;
  };
  filtrosOperacoes?: FiltrosProps;
  operacoesSelecionadas?: string;
  curvaABC?: FiltersCurvaABC;
  id?: string;
  getEmail?: (emails: string[]) => void;
}

const EmailModalComponent = ({
  cliente,
  descricao,
  documentoFiscalId,
  getEmail,
  operacaoId,
  filtrosOperacoes,
  operacoesSelecionadas,
  filtrosNotaFiscal,
  id,
  filtrosConferenciaEstoque,
  idContas,
  filtroListaPaginada,
  caixaMovimentacao,
  curvaABC,
  ...props
}: EmailModalProps) => {
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const formMethods = useForm({ resolver: yupResolver });

  const [createdEmailOptions, setCreatedEmailOptions] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const primary50 = useToken('colors', 'primary.50');

  const emailOptions = useMemo(() => {
    const newOptions = [];

    if (cliente?.email) {
      newOptions.push({ value: cliente?.email, label: cliente?.email });
    }

    if (cliente?.contatosAdicionais && cliente?.contatosAdicionais.length > 0) {
      newOptions.push(
        ...cliente?.contatosAdicionais
          .filter((contatoAdicional) => contatoAdicional.email)
          .map((contatoAdicional) => ({
            value: contatoAdicional.email,
            label: `${contatoAdicional.email}${
              contatoAdicional.nome ? ` - ${contatoAdicional.nome}` : ''
            }`,
          }))
      );
    }

    return newOptions;
  }, [cliente]);

  const handleSubmit = formMethods.handleSubmit<FormData>(
    async ({ emails }) => {
      if (emails && emails.length > 0) {
        setIsLoading(true);

        let response;

        if (getEmail) {
          getEmail(emails);

          setIsLoading(false);
          setIsOpen(false);

          return;
        }

        if (operacaoId) {
          response = await api.post<void, ResponseApi<boolean>>(
            `${ConstanteEnderecoWebservice.PEDIDOORCAMENTOVENDA_ENVIAR_EMAIL_CUPOM_NAO_FISCAL}/${operacaoId}`,
            emails
          );
        } else if (idContas) {
          const envioDeEmails = {
            idContas,
            emails,
          };

          response = await api.post<void, ResponseApi<boolean>>(
            ConstanteEnderecoWebservice.CONTAS_RECEBER_ENVIAR_EMAIL,
            envioDeEmails
          );
        } else if (filtrosConferenciaEstoque) {
          response = await api.get<void, ResponseApi<boolean>>(
            ConstanteEnderecoWebservice.CONFERENCIA_ESTOQUE_IMPRIMIR_EMAIL,
            {
              params: {
                conferenciaEstoqueId:
                  filtrosConferenciaEstoque.conferenciaEstoqueId,
                completo: filtrosConferenciaEstoque.estoqueCompleto,
                corrigirApenasItensConferidos:
                  filtrosConferenciaEstoque.corrigirApenasItensConferidos,
                emails,
              },
            }
          );
        } else if (filtroListaPaginada?.contasPagar) {
          response = await api.post<void, ResponseApi<boolean>>(
            `${
              ConstanteEnderecoWebservice.CONTAS_PAGAR_RELATORIO_EMAIL
            }?${new URLSearchParams(
              Object(filtroListaPaginada.contasPagar)
            ).toString()}`,
            emails
          );
        } else if (filtroListaPaginada?.contasReceber) {
          response = await api.post<void, ResponseApi<boolean>>(
            `${
              ConstanteEnderecoWebservice.CONTAS_RECEBER_RELATORIO_EMAIL
            }?${new URLSearchParams(
              Object(filtroListaPaginada.contasReceber)
            ).toString()}`,
            emails
          );
        } else if (filtrosOperacoes && operacoesSelecionadas) {
          response = await api.post<void, ResponseApi<boolean>>(
            `${
              ConstanteEnderecoWebservice.ENVIAR_EMAIL_RELATORIO_LISTAGEM_OPERACOES
            }?${new URLSearchParams(
              Object(filtrosOperacoes)
            )}${operacoesSelecionadas}`,
            emails
          );
        } else if (filtrosNotaFiscal) {
          response = await api.post<void, ResponseApi<boolean>>(
            `${
              ConstanteEnderecoWebservice.NOTA_FICAL_IMPRIMIR_EMAIL
            }?${new URLSearchParams(Object(filtrosNotaFiscal))}`,
            emails
          );
        } else if (id) {
          response = await api.post<void, ResponseApi<boolean>>(
            `${
              ConstanteEnderecoWebservice.ENVIAR_RELATORIO_EMAIL_DETALHES_FATURA
            }?id=${Object(id)}`,
            emails
          );
        } else if (caixaMovimentacao) {
          response = await api.post<void, ResponseApi<boolean>>(
            `${
              ConstanteEnderecoWebservice.FECHAMENTO_CAIXA_ENVIAR_EMAIL
            }?${new URLSearchParams(Object(caixaMovimentacao)).toString()}`,
            emails
          );
        } else if (curvaABC) {
          const emailsCadastrados = (() => {
            let urlEmails = '';
            emails.forEach((email) => {
              urlEmails += `&emails=${email}`;
            });
            return urlEmails;
          })();

          response = await api.get<void, ResponseApi<boolean>>(
            `${
              ConstanteEnderecoWebservice.RELATORIO_CURVA_ABC_ENVIAR_POR_EMAIL
            }?${new URLSearchParams(
              Object(curvaABC)
            ).toString()}${emailsCadastrados}`
          );
        } else {
          response = await api.post<void, ResponseApi<boolean>>(
            `${ConstanteEnderecoWebservice.NOTA_FISCAL_ENVIAR_EMAIL}/${documentoFiscalId}`,
            emails
          );
        }
        if (response?.sucesso) {
          toast.success('O e-mail foi enviado com sucesso.');
        }

        if (response?.avisos) {
          ModalAtencao({
            text: response.avisos[0],
            width: 800,
          });
        }

        setIsLoading(false);
        setIsOpen(false);
      }
    }
  );

  const latestProps = useRef({
    reset: formMethods.reset,
  });
  useEffect(() => {
    latestProps.current = {
      reset: formMethods.reset,
    };
  });

  useEffect(() => {
    if (emailOptions.length > 0 && isOpen) {
      latestProps.current.reset({ emails: [emailOptions[0].value] });
    } else {
      latestProps.current.reset();
      setCreatedEmailOptions([]);
    }
  }, [emailOptions, isOpen]);

  const trapFocusRef = useRef<HTMLInputElement>(null);

  return (
    <ModalPadraoChakra
      isCentered
      size={isLargerThan900 ? 'xl' : 'full'}
      {...props}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      initialFocusRef={trapFocusRef}
      autoFocus={false}
    >
      <ModalContent
        marginBottom={{ base: 0, md: '3.75rem' }}
        marginTop={{ base: 0, md: '3.75rem' }}
        h="unset"
        maxW={{ base: '100%', md: '600px' }}
      >
        {isLoading && <LoadingPadrao />}
        <ModalBody p={0}>
          <Flex
            alignItems="center"
            justifyContent="center"
            bg="primary.50"
            h="135px"
            borderTopRadius="md"
          >
            <Icon as={CompartilharEmailIcon} boxSize="50px" color="white" />
          </Flex>
          <Flex flexDirection="column" h="full" px={8} pt={7} pb={12}>
            <FormProvider {...formMethods}>
              <Heading
                as="h1"
                fontSize="xl"
                color="primary.50"
                fontWeight="semibold"
                mb={4}
              >
                Compartilhar por e-mail
              </Heading>

              <Text color="gray.700" fontSize="sm">
                Digite o endereço ou selecione um contato existente. É possível
                selecionar 2 ou mais endereços ao mesmo tempo.
              </Text>

              <VStack
                as="form"
                spacing={7}
                mt={9}
                onSubmit={handleSubmit}
                w="full"
                maxW="420px"
                alignSelf="center"
              >
                <CreatableSelect
                  id="emails"
                  name="emails"
                  label="E-mails"
                  autoFocus={!isMobile}
                  options={[...(createdEmailOptions || []), ...emailOptions]}
                  isMulti
                  multiValueBg={primary50}
                  multiValueColor="var(--white)"
                  handleCreateOption={async (inputValue) => {
                    let success = false;

                    try {
                      success = emailIsValid(inputValue);
                    } catch (e) {
                      success = false;
                    }

                    if (success) {
                      const createdOption = {
                        label: inputValue,
                        value: inputValue,
                      };

                      setCreatedEmailOptions((prev) => [
                        ...prev,
                        createdOption,
                      ]);

                      return createdOption;
                    }
                    toast.warn(
                      'E-mail informado não está em um formato válido.'
                    );

                    return undefined;
                  }}
                  creatableInputTextPreffix="Enviar para "
                />

                <HStack spacing="6" w="full" justifyContent="center">
                  <Button
                    variant="outline"
                    w="full"
                    maxW={{ base: 'full', md: '130px' }}
                    onClick={() => setIsOpen(false)}
                  >
                    Cancelar
                  </Button>

                  <Button
                    type="submit"
                    colorScheme="secondary"
                    w="full"
                    maxW={{ base: 'full', md: '120px' }}
                  >
                    Enviar
                  </Button>
                </HStack>
              </VStack>
            </FormProvider>
          </Flex>
        </ModalBody>
      </ModalContent>
    </ModalPadraoChakra>
  );
};
export const EmailModal = create<EmailModalProps>(EmailModalComponent);
