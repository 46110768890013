import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  ModalFooter,
  Box,
  ModalHeader,
  useMediaQuery,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { StatusPesquisaClientesFornecedor } from 'constants/enum/statusPesquisaClientesFornecedor';
import { formatOptionsSelectClient } from 'helpers/format/formatSelectClient';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import AsyncSelect from 'components/PDV/Select/AsyncSelectPadrao';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { ClienteProps } from 'pages/PDV/ProdutosConsignados/types/validationForms';

type ModalProdutosConsignacaoProps = {
  cliente: string;
};

export type SelectClienteProps = {
  id: string;
  nome: string;
  endereco: string;
  codigo: number;
  cpfCnpj: string;
};

type ModalConsignacaoProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalProdutosConsignacaoProps> & {
    setClienteConsignacao: React.Dispatch<
      React.SetStateAction<ClienteProps | undefined>
    >;
    setAbrirModalAutomaticamente: React.Dispatch<React.SetStateAction<boolean>>;
    setExisteCodigoBarras: React.Dispatch<React.SetStateAction<boolean>>;
    handleListarItensConsignados: (
      clienteFornecedorId: string
    ) => Promise<void>;
    handleOnClose?: () => void;
  };

export const ModalConsignacao = create<
  ModalConsignacaoProps,
  ModalProdutosConsignacaoProps
>(
  ({
    onResolve,
    onReject,
    setClienteConsignacao,
    setExisteCodigoBarras,
    handleOnClose,
    setAbrirModalAutomaticamente,
    handleListarItensConsignados,
    ...rest
  }) => {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
    const [cliente, setCliente] = useState<ClienteProps>();
    const [isLoading, setIsLoading] = useState(false);

    const isMountedRef = useIsMountedRef();

    const formMethods = useForm<ModalProdutosConsignacaoProps>();

    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    async function obterClientes(inputValue: string) {
      const response = await api.get<void, ResponseApi<SelectClienteProps[]>>(
        ConstanteEnderecoWebservice.CLIENTE_FORNECEDOR_LISTAR_SELECT,
        {
          params: {
            cpfCnpjNomeApelidoCodigoExterno: inputValue,
            filtroTipoCadastroPessoa: StatusPesquisaClientesFornecedor.CLIENTES,
            listarPadraoSistema: false,
          },
        }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response.sucesso && response.dados) {
          return response.dados.map((clientes) => ({
            ...formatOptionsSelectClient(clientes),
          }));
        }
      }

      return [];
    }

    const handlePesquisarCliente = useCallback(async () => {
      setIsLoading(true);
      await setAbrirModalAutomaticamente(false);
      await setExisteCodigoBarras(false);
      await setClienteConsignacao(cliente);
      await handleListarItensConsignados(cliente?.value || ' ');

      onClose();
      if (isMountedRef.current) setIsLoading(false);
    }, [
      setAbrirModalAutomaticamente,
      setExisteCodigoBarras,
      setClienteConsignacao,
      cliente,
      handleListarItensConsignados,
      onClose,
      isMountedRef,
    ]);

    const handleVoltarPdv = async () => {
      await onClose();
      if (handleOnClose) {
        handleOnClose();
      }
    };

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? '3xl' : 'full'}
        {...rest}
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        autoFocus
      >
        <ModalContent
          bg="gray.50"
          borderRadius={isLargerThan900 ? 'md' : '0'}
          marginBottom={isLargerThan900 ? '3.75rem' : '0'}
          marginTop={isLargerThan900 ? '3.75rem' : '0'}
          w={isLargerThan900 ? '600px' : 'full'}
          h={isLargerThan900 ? '220px' : 'full'}
        >
          <ModalHeader p="0">
            <Flex
              justifyContent={isLargerThan900 ? 'start' : 'space-between'}
              mb="32px"
              mt="24px"
              w="90%"
              ml={isLargerThan900 ? '5%' : '4%'}
            >
              <Text fontSize="16px" fontWeight="semibold">
                Selecione o cliente para buscar os produtos consignados:
              </Text>
            </Flex>
          </ModalHeader>
          {isLoading && <LoadingPadrao />}
          <FormProvider {...formMethods}>
            <ModalBody p="0">
              <Box w="full">
                <Flex
                  pr={isLargerThan900 ? '0' : '4%'}
                  pl={isLargerThan900 ? '0' : '4%'}
                  justifyContent="center"
                  alignItems="center"
                  mb="16px"
                >
                  <AsyncSelect
                    id="cliente"
                    name="cliente"
                    width={isLargerThan900 ? '67%' : 'full'}
                    placeholder="Cliente"
                    required
                    setValor={setCliente}
                    withoutDefaultOptions
                    isClearable
                    asControlledByObject
                    shouldAppearTheAddress
                    handleGetOptions={obterClientes}
                  />
                </Flex>
              </Box>
            </ModalBody>
            <ModalFooter h={isLargerThan900 ? '120px' : '800px'}>
              <Flex
                w="full"
                h="full"
                justifyContent="center"
                alignItems="baseline"
              >
                <Button
                  id="cancelar"
                  name="cancelar"
                  color="gray.300"
                  variant="outline"
                  borderRadius="20px"
                  fontSize="sm"
                  type="button"
                  h="40px"
                  mr="24px"
                  onClick={() => {
                    handleVoltarPdv();
                  }}
                  _hover={{ bg: 'gray.50' }}
                  w="120px"
                >
                  Cancelar
                </Button>
                <Button
                  id="pesquisar"
                  name="pesquisar"
                  variant={!cliente ? 'outline' : 'solid'}
                  isDisabled={!cliente || isLoading}
                  borderRadius="20px"
                  fontSize="sm"
                  type="button"
                  h="40px"
                  colorScheme={!cliente ? 'gray' : 'primary'}
                  onClick={() => handlePesquisarCliente()}
                  w="256px"
                >
                  Pesquisar
                </Button>
              </Flex>
            </ModalFooter>
          </FormProvider>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
