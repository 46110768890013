import React, { useEffect, useRef, useState, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  ModalContent,
  ModalBody,
  Tabs,
  TabList,
  Flex,
  Button,
  Text,
  TabPanels,
  Tab,
  TabPanel,
  useMediaQuery,
  Box,
  ModalFooter,
  CloseButton,
  ModalProps,
  useDisclosure,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { create, InstanceProps } from 'react-modal-promise';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import auth from 'modules/auth';
import { CasasDecimais } from 'store/Padronizacao/Padronizacao';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { FilterAsyncSelect } from 'components/update/Select/FilterAsyncSelect';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { ModalListarVariacoesPdvMobile } from './modalListarSaldoVariacaoPdvMobile';
import {
  VariacaoLinha,
  VariacaoTodosEstoquesProps,
  VariacaoLojaProps,
} from './types';
import { DadosPdvTabPanelLojaAtual } from './DadosTabPanels/lojaAtual';
import { DadosPdvTabPanelEstoqueGeral } from './DadosTabPanels/estoqueGeral';
import { DadosTabPanelProdutosConsignados } from '../ModalListarSaldoVariacoes/DadosTabPanels/produtosConsignados';

type Cores = {
  id: string;
  descricao: string;
};

type CoresProps = {
  value: string;
  label: string;
};

type FornecedorOptionResponse = {
  id: string;
  nome: string;
  listaCores: Cores[];
};

type VariacaoStatusEstiloTamanho = {
  tamanhoComponente: string;
  tamanhoListagem: string;
};

type ProdutoProps = { label: string; value: string; cores: CoresProps[] };

type ListarSaldoVariacoesProps = {
  localEstoque: string;
  cor: string;
  tamanho: string;
  saldo: number;
  lojaId: string;
  loja: string;
  produtoCorId: string;
  produtoCorTamanhoId: string;
  localEstoqueId: string;
};

export type ItensConsignados = {
  data: Date;
  variacao: string;
  cliente: string;
  telefone: string;
  quantidade: number;
};

type ListarSaldoVariacaoResponse = {
  saldoVariacoes: ListarSaldoVariacoesProps[];
  itensConsignados: ItensConsignados[];
};

type ModalListarSaldoVariacoesProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<any> & {
    casasDecimais: CasasDecimais;
  };

const ModalListarVariacoesPdv = create<ModalListarSaldoVariacoesProps>(
  ({ casasDecimais }) => {
    const [variacoesEstoqueAgrupado, setVariacoesEstoqueAgrupado] = useState<
      VariacaoLinha[]
    >([]);
    const [exibirTodasAsLojas, setExibirTodasAsLojas] = useState<
      VariacaoLojaProps[]
    >([]);
    const [produtosConsignados, setProdutosConsignados] = useState<
      ItensConsignados[]
    >([]);
    const [variacaoTodosEstoques, setVariacaoTodosEstoques] = useState<
      VariacaoTodosEstoquesProps[]
    >([]);
    const [tamanhosAgrupados, setTamanhosAgrupados] = useState<string[]>([]);
    const [
      tamanhosAgrupadosTodasLojas,
      setTamanhosAgrupadosTodasLojas,
    ] = useState<string[]>([]);
    const [cores, setCores] = useState<CoresProps[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
    const { fantasia: lojaFantasia = '' } = auth.getLoja();
    const formMethods = useForm<{
      idProduto: ProdutoProps;
      corId: string;
      lojaEscolhida: number;
    }>({
      defaultValues: {
        corId: '',
        lojaEscolhida: 1,
      },
    });
    const idProduto: ProdutoProps = formMethods.watch(
      'idProduto'
    ) as ProdutoProps;
    const lojaEscolhida = formMethods.watch('lojaEscolhida');
    const corId = formMethods.watch('corId');
    const [isLargerThan899] = useMediaQuery('(max-width: 899px)');

    const latestProps = useRef({
      reset: formMethods.reset,
      variacoesEstoqueAgrupado,
    });

    const naoExisteTamanho = variacoesEstoqueAgrupado.some(
      ({ valorTamanho }) => valorTamanho === ''
    );

    const naoExisteTamanhoTodasLojas = exibirTodasAsLojas.some(
      ({ valorTamanho }) => valorTamanho === ''
    );

    const naoExisteCorTodasLojas = exibirTodasAsLojas.some(
      ({ cor }) => cor === ''
    );

    const naoExisteVariacao = variacoesEstoqueAgrupado.some(
      (item) => item.cor === ''
    );

    const naoExisteCores = variacoesEstoqueAgrupado.map((item) => item.cor)
      .length;

    const informacoesEstoqueGeral = useMemo(() => {
      const arrayNovo = [] as VariacaoTodosEstoquesProps[];

      variacaoTodosEstoques.forEach((valor) => {
        if (valor.loja !== lojaFantasia) {
          arrayNovo.push(valor);
        }
      });
      return arrayNovo;
    }, [lojaFantasia, variacaoTodosEstoques]);

    const informacoesTamanhoEstlo: {
      [key: string]: VariacaoStatusEstiloTamanho;
    } = {
      tamanhoPequeno: {
        tamanhoComponente: '280px',
        tamanhoListagem: '99%',
      },

      tamanhoMedio: {
        tamanhoComponente: '250px',
        tamanhoListagem: '92%',
      },

      tamanhoGrande: {
        tamanhoComponente: '280px',
        tamanhoListagem: 'full',
      },
    };

    const informacaoListandoTamanhoEstilo = (() => {
      if (variacoesEstoqueAgrupado.length < 4) {
        return informacoesTamanhoEstlo.tamanhoPequeno;
      }

      if (
        variacoesEstoqueAgrupado.length > 3 &&
        variacoesEstoqueAgrupado.length < 7
      ) {
        return informacoesTamanhoEstlo.tamanhoMedio;
      }

      if (variacoesEstoqueAgrupado.length > 6) {
        return informacoesTamanhoEstlo.tamanhoGrande;
      }

      return informacoesTamanhoEstlo.tamanhoPequeno;
    })();

    useEffect(() => {
      if (!idProduto?.value) {
        return;
      }
      setIsLoading(true);
      const params = {
        produtoId: idProduto?.value,
        corId,
      };

      function tratarTodosEstoques(dadosLoja: ListarSaldoVariacoesProps[]) {
        const newTodasVariacoes = dadosLoja.reduce((acc, curr) => {
          acc.push({
            loja: curr.loja,
            saldo: curr.saldo,
          });

          return acc;
        }, [] as VariacaoTodosEstoquesProps[]);

        setVariacaoTodosEstoques(newTodasVariacoes);
      }

      function tratarExibicaoTodasLojas(
        dadosLoja: ListarSaldoVariacoesProps[]
      ) {
        const newTodasVariacoes = dadosLoja.reduce((acc, curr) => {
          const currCorIndex = acc.findIndex(
            (val) => val.produtoCorId === curr.produtoCorId
          );

          if (currCorIndex > -1) {
            acc.splice(currCorIndex, 1, {
              ...acc[currCorIndex],
              tamanhos: [
                ...acc[currCorIndex].tamanhos,
                {
                  loja: curr.loja,
                  tamanho: curr.tamanho,
                  quantidade: curr.saldo,
                  localEstoque: curr.localEstoque,
                  locaisEstoque: [
                    {
                      localEstoque: curr.localEstoque,
                      quantidade: curr.saldo,
                    },
                  ],
                  locaisLoja: [
                    {
                      loja: curr.loja,
                      saldoLoja: curr.saldo,
                    },
                  ],
                },
              ],
              lojas: [
                ...acc[currCorIndex].lojas,
                {
                  saldoLoja: curr.saldo,
                  loja: curr.loja,
                  tamanho: curr.tamanho,
                  todasLojas: [
                    {
                      loja: curr.loja,
                      saldoLoja: curr.saldo,
                    },
                  ],
                },
              ],
            });
          } else {
            acc.push({
              cor: curr.cor,
              lojaId: curr.lojaId,
              lojas: [
                {
                  saldoLoja: curr.saldo,
                  loja: curr.loja,
                  tamanho: curr.tamanho,
                  todasLojas: [
                    {
                      loja: curr.loja,
                      saldoLoja: curr.saldo,
                    },
                  ],
                },
              ],
              produtoCorId: curr.produtoCorId,
              valorTamanho: curr.tamanho,
              saldo: curr.saldo,
              produtoCorTamanhoId: curr.produtoCorTamanhoId,
              tamanhos: [
                {
                  localEstoque: curr.localEstoque,
                  tamanho: curr.tamanho,
                  loja: curr.loja,
                  quantidade: curr.saldo,
                  locaisEstoque: [
                    {
                      localEstoque: curr.localEstoque,
                      quantidade: curr.saldo,
                    },
                  ],
                  locaisLoja: [
                    {
                      loja: curr.loja,
                      saldoLoja: curr.saldo,
                    },
                  ],
                },
              ],
            });
          }

          return acc;
        }, [] as VariacaoLojaProps[]);
        setExibirTodasAsLojas(newTodasVariacoes);
      }

      function tratarEstoqueLojaAtual(dadosLoja: ListarSaldoVariacoesProps[]) {
        const newTodasVariacoes = dadosLoja.reduce((acc, curr) => {
          const currCorIndex = acc.findIndex(
            (val) => val.produtoCorId === curr.produtoCorId
          );

          const validandoIdLoja = curr.loja;
          if (validandoIdLoja === lojaFantasia) {
            if (currCorIndex > -1) {
              acc.splice(currCorIndex, 1, {
                ...acc[currCorIndex],
                tamanhos: [
                  ...acc[currCorIndex].tamanhos,
                  {
                    tamanho: curr.tamanho,
                    quantidade: curr.saldo,
                    localEstoque: curr.localEstoque,
                    locaisEstoque: [
                      {
                        localEstoque: curr.localEstoque,
                        quantidade: curr.saldo,
                      },
                    ],
                  },
                ],
              });
            } else {
              acc.push({
                cor: curr.cor,
                lojaId: curr.lojaId,
                loja: curr.loja,
                produtoCorId: curr.produtoCorId,
                valorTamanho: curr.tamanho,
                saldo: curr.saldo,
                produtoCorTamanhoId: curr.produtoCorTamanhoId,
                tamanhos: [
                  {
                    localEstoque: curr.localEstoque,
                    tamanho: curr.tamanho,
                    quantidade: curr.saldo,
                    locaisEstoque: [
                      {
                        localEstoque: curr.localEstoque,
                        quantidade: curr.saldo,
                      },
                    ],
                  },
                ],
              });
            }
          }

          return acc;
        }, [] as VariacaoLinha[]);
        setVariacoesEstoqueAgrupado(newTodasVariacoes);
      }

      async function getListarVariacoes() {
        const response = await api.get<
          void,
          ResponseApi<ListarSaldoVariacaoResponse>
        >(ConstanteEnderecoWebservice.PRODUTO_OBTER_SALDO_VARIACOES, {
          params,
        });

        if (response) {
          if (response.avisos) {
            response.avisos.map((item: string) => toast.warning(item));
            setIsLoading(false);
          }

          if (response.sucesso) {
            tratarEstoqueLojaAtual(response.dados.saldoVariacoes);
            tratarTodosEstoques(response.dados.saldoVariacoes);
            tratarExibicaoTodasLojas(response.dados.saldoVariacoes);
            setProdutosConsignados(response.dados.itensConsignados);

            const responseCores = await api.get<void, ResponseApi<Cores[]>>(
              ConstanteEnderecoWebservice.PRODUTO_LISTAR_CORES,
              {
                params: {
                  produtoId: idProduto?.value,
                },
              }
            );

            if (responseCores) {
              if (responseCores.avisos) {
                responseCores.avisos.forEach((item: string) =>
                  toast.warning(item)
                );
              }
              if (responseCores.sucesso) {
                setCores(
                  responseCores.dados.map((coresItem) => ({
                    label: coresItem.descricao,
                    value: coresItem.id,
                  }))
                );
              }
            }
            setIsLoading(false);
          }
        }
        setIsLoading(false);
      }
      getListarVariacoes();
    }, [idProduto, corId, lojaFantasia]);

    useEffect(() => {
      latestProps.current = {
        reset: formMethods.reset,
        variacoesEstoqueAgrupado,
      };
    });

    async function getProdutosOptions(inputValue: string) {
      if (!inputValue) {
        return [];
      }
      const response = await api.get<
        void,
        ResponseApi<FornecedorOptionResponse[]>
      >(ConstanteEnderecoWebservice.LISTAR_SELECT_PRODUTO, {
        params: { nomeSkuCodigoExternoBarrasGtinEan: inputValue },
      });

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response.sucesso && response.dados) {
          return response.dados.map((fornecedor) => ({
            label: fornecedor.nome,
            value: fornecedor.id,
            cores: (fornecedor.listaCores || []).map((c) => ({
              label: c.descricao,
              value: c.id,
            })),
          }));
        }
      }
      return [];
    }

    useEffect(() => {
      if (variacoesEstoqueAgrupado && variacoesEstoqueAgrupado.length > 0) {
        const tamanhosPorLinha = variacoesEstoqueAgrupado
          .reduce((acc, curr) => {
            curr.tamanhos.forEach((tamanho) => {
              acc.push(tamanho.tamanho);
            });

            return acc;
          }, [] as string[])
          .filter((item, pos, self) => self.indexOf(item) === pos);

        setTamanhosAgrupados([...tamanhosPorLinha]);
      }
    }, [variacoesEstoqueAgrupado]);

    useEffect(() => {
      if (exibirTodasAsLojas && exibirTodasAsLojas.length > 0) {
        const tamanhosPorLinha = exibirTodasAsLojas
          .reduce((acc, curr) => {
            curr.tamanhos.forEach((tamanho) => {
              acc.push(tamanho.tamanho);
            });

            return acc;
          }, [] as string[])
          .filter((item, pos, self) => self.indexOf(item) === pos);

        setTamanhosAgrupadosTodasLojas([...tamanhosPorLinha]);
      }
    }, [exibirTodasAsLojas]);

    useEffect(() => {
      if (idProduto?.value) {
        formMethods.setValue('corId', '');
        formMethods.setValue('lojaEscolhida', 1);
      }
    }, [idProduto, formMethods]);

    return (
      <ModalPadraoChakra
        onClose={onClose}
        isOpen={isOpen}
        isCentered={!isLargerThan899}
        size={!isLargerThan899 ? '4xl' : 'full'}
      >
        <ModalContent>
          {isLoading && <LoadingPadrao />}
          <FormProvider {...formMethods}>
            {isLargerThan899 ? (
              <ModalListarVariacoesPdvMobile
                idProduto={idProduto?.value}
                cores={cores || []}
                produtosConsignados={produtosConsignados}
                onClose={onClose}
                casasDecimais={casasDecimais}
                setExibirTodasAsLojas={setExibirTodasAsLojas}
                getProdutosOptions={getProdutosOptions}
                naoExisteCores={cores?.length}
                variacoesEstoqueAgrupado={variacoesEstoqueAgrupado}
                lojaFantasia={lojaFantasia}
                informacoesEstoqueGeral={informacoesEstoqueGeral}
                lojaEscolhida={lojaEscolhida}
                exibirTodasAsLojas={exibirTodasAsLojas}
                setVariacoesEstoqueAgrupado={setVariacoesEstoqueAgrupado}
              />
            ) : (
              <ModalBody p={0} h="100%" w="100%">
                <Box
                  w="full"
                  bg="primary.400"
                  h="130px"
                  padding="20px"
                  borderTopRadius="6px"
                  justifyContent="space-between"
                >
                  <Flex justifyContent="space-between">
                    <Text fontSize="18px" color="#A8E303">
                      Consulta de estoque
                    </Text>
                    <CloseButton
                      color="gray.100"
                      _hover={{ color: 'gray.50' }}
                      size="md"
                      p={1}
                      onClick={onClose}
                    />
                  </Flex>
                  <Flex mt="20px" justifyContent="center" alignItems="center">
                    <FilterAsyncSelect
                      id="idProduto"
                      name="idProduto"
                      required
                      asControlledByObject
                      placeholder="Informe o produto ou utilize o leitor de códigos"
                      handleGetOptions={getProdutosOptions}
                      autoFocus
                      colSpan={6}
                    />

                    {cores?.length === 0 ? (
                      <></>
                    ) : (
                      <Box ml="30px" w="400px">
                        <SelectPadrao
                          id="corId"
                          name="corId"
                          placeholder="Selecione uma cor"
                          options={cores || []}
                          isClearable
                        />
                      </Box>
                    )}
                  </Flex>
                </Box>
                {idProduto?.value ? (
                  <Flex
                    padding="20px"
                    w="full"
                    h={informacaoListandoTamanhoEstilo.tamanhoComponente}
                  >
                    <Tabs
                      border="1px solid #00000034"
                      variant="enclosed"
                      position="relative"
                      borderRadius="6px"
                      w="full"
                    >
                      <TabList
                        borderBlockEnd="1px solid #5502b260"
                        mb="0"
                        paddingTop="13px"
                        pl="23px"
                        h="50px"
                        bg="#00000034"
                      >
                        <Tab
                          border="1px solid #5502b260"
                          color="black"
                          w="200px"
                          _before={{
                            boxShadow: 'none !important',
                          }}
                          _after={{
                            boxShadow: 'none !important',
                          }}
                          fontSize="sm"
                        >
                          {lojaFantasia}
                        </Tab>
                        {informacoesEstoqueGeral.length === 0 ? (
                          <></>
                        ) : (
                          <Tab
                            border="1px solid #5502b260"
                            color="black"
                            fontSize="sm"
                            w="240px"
                            _after={{
                              boxShadow: 'none !important',
                            }}
                            _before={{
                              boxShadow: 'none !important',
                            }}
                            _active={{
                              boxShadow: 'none !important',
                            }}
                          >
                            Estoque geral (todas as lojas)
                          </Tab>
                        )}
                        <Tab
                          border="1px solid #5502b260"
                          color="black"
                          w="200px"
                          _before={{
                            boxShadow: 'none !important',
                          }}
                          _after={{
                            boxShadow: 'none !important',
                          }}
                          fontSize="sm"
                        >
                          Consignação
                        </Tab>
                      </TabList>

                      <TabPanels
                        position="relative"
                        h={informacaoListandoTamanhoEstilo.tamanhoListagem}
                        boxShadow="none"
                      >
                        <TabPanel
                          overflowY={
                            variacoesEstoqueAgrupado.length > 2
                              ? 'scroll'
                              : 'hidden'
                          }
                          h="78%"
                        >
                          <DadosPdvTabPanelLojaAtual
                            naoExisteVariacao={naoExisteVariacao}
                            tamanhosAgrupados={tamanhosAgrupados}
                            variacoesEstoqueAgrupado={variacoesEstoqueAgrupado}
                            naoExisteTamanho={naoExisteTamanho}
                          />
                        </TabPanel>
                        {informacoesEstoqueGeral.length > 0 && (
                          <TabPanel
                            overflowY={
                              exibirTodasAsLojas.length > 3
                                ? 'scroll'
                                : 'hidden'
                            }
                            h="78%"
                          >
                            <DadosPdvTabPanelEstoqueGeral
                              exibirTodasAsLojas={exibirTodasAsLojas}
                              naoExisteTamanhoTodasLojas={
                                naoExisteTamanhoTodasLojas
                              }
                              naoExisteCorTodasLojas={naoExisteCorTodasLojas}
                              tamanhosAgrupadosTodasLojas={
                                tamanhosAgrupadosTodasLojas
                              }
                            />
                          </TabPanel>
                        )}
                        <TabPanel
                          overflowY={
                            produtosConsignados.length > 2 ? 'scroll' : 'hidden'
                          }
                          h="78%"
                        >
                          <DadosTabPanelProdutosConsignados
                            casasDecimais={casasDecimais}
                            produtosConsignados={produtosConsignados}
                          />
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                  </Flex>
                ) : (
                  <></>
                )}
              </ModalBody>
            )}
            <ModalFooter mb="10px">
              <Flex w="full" justifyContent="center" alignItems="center">
                <Button
                  id="cancelar"
                  name="cancelar"
                  colorScheme="gray.700"
                  variant="outlineDefault"
                  type="button"
                  w="155px"
                  onClick={() => onClose()}
                >
                  Fechar consulta
                </Button>
              </Flex>
            </ModalFooter>
          </FormProvider>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);

export default ModalListarVariacoesPdv;
