import { enumStatusCredenciamento } from 'constants/enum/enumStatusCredenciamento';

export const useZoopStatusActions = () => {
  const avisoCredenciamentoPending = () => {
    return `Seu credenciamento ainda está em análise pela instituição financeira. Aguarde a confirmação para realizar transações com a Zoop Recebimentos.`;
  };

  const avisoCredenciamentoRejected = () => {
    return 'Infelizmente seu credenciamento não foi aprovado pela instituição financeira. Entre em contato com para maiores informações.';
  };

  const avisoCredenciamentoEnabled = () => {
    return `Seu credenciamento foi aprovado e você já pode realizar transações com a Zoop Recebimentos.`;
  };

  const handleCredenciamentoStatus = (status: string) => {
    const statusFunctions = {
      [enumStatusCredenciamento.DELETED]: avisoCredenciamentoRejected,
      [enumStatusCredenciamento.DENIED]: avisoCredenciamentoRejected,
      [enumStatusCredenciamento.DISABLED]: avisoCredenciamentoRejected,
      [enumStatusCredenciamento.REJECTED]: avisoCredenciamentoRejected,
      [enumStatusCredenciamento.PENDING]: avisoCredenciamentoPending,
      [enumStatusCredenciamento.ACTIVE]: avisoCredenciamentoEnabled,
      [enumStatusCredenciamento.ENABLED]: avisoCredenciamentoEnabled,
    };

    const handleStatus = statusFunctions[status] || (() => {});

    return handleStatus();
  };

  return {
    handleCredenciamentoStatus,
  };
};
