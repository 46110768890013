import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import ConstanteRotas from 'constants/rotas';

const SemPermissao = () => {
  const { t } = useTranslation();
  const dominio = window.location.host.split('/')[0].toLowerCase();

  useEffect(() => {
    if (dominio !== 'app') {
      toast.warning(
        t(
          'Você não tem permissão para acessar essa função. Consulte o administrador da conta.'
        )
      );
    }
  }, [dominio, t]);

  if (dominio === 'app') {
    return null;
  }

  return <Redirect to={ConstanteRotas.DASHBOARD} />;
};

export default SemPermissao;
