const TipoIntegracaoPagamentoEnum = {
  NENHUM: 0,
  TEF: 1,
  POS: 2,

  properties: {
    1: {
      name: 'Nenhum',
      value: null,
    },
    2: {
      name:
        'Pagamento Integrado com o sistema(Ex.: Equip. TEF, Com. Eletrônico)',
      value: 1,
    },
    3: {
      name: 'Pagamento não integrado com o sistema (Ex.: Equipamento POS)',
      value: 2,
    },
  },
};

export default TipoIntegracaoPagamentoEnum;
