import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

interface DeleteMovimentacaoFinanceiraProps {
  identificadorAgrupamento?: string;
  operacaoId?: string;
}

export const deleteMovimentacaoFinanceira = async (
  props: DeleteMovimentacaoFinanceiraProps
): Promise<boolean> => {
  const response = await api.delete<void, ResponseApi<boolean>>(
    ConstanteEnderecoWebservice.MOVIMENTACAO_FINANCEIRA_EXCLUIR,
    {
      params: {
        ...props,
      },
    }
  );

  if (response) {
    if (response.avisos) {
      response.avisos.forEach((item: string) => toast.warning(item));
    } else {
      toast.success('Movimentação financeira excluída com sucesso!');
      return true;
    }
  }

  return false;
};
