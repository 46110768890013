import { Flex, Text, Icon, Button, useMediaQuery } from '@chakra-ui/react';
import { BiHelpCircle } from 'react-icons/bi';

import { useTrayEtapasContext } from 'store/Tray';
import { IdentificacaoEtapasTray } from 'constants/enum/IdentificacaoEtapasTray';

import { BuscaAvancadaButton } from 'components/update/BuscaAvancadaButton';

import { ModalAjuda } from '../../../ProdutoSistema/ModalAjuda';

export const HeaderProdutoSistema = () => {
  const {
    ref,
    activeStep,
    setIsOpenBuscaAvancada,
    listProdutoLocalStorage,
    hasFiltros,
  } = useTrayEtapasContext();

  const amountProdutosLocalStorage = listProdutoLocalStorage().length;

  const isProdutosLancados = amountProdutosLocalStorage > 0;

  const isProdutoSistema =
    activeStep === IdentificacaoEtapasTray.LISTA_PRODUTO_SISTEMA;

  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

  return (
    <>
      <Flex
        mr={['10px', '10px', '10px', '0']}
        flex={2}
        mb={['10px', '10px', '0']}
        justifyContent="center"
        alignItems="center"
      >
        <Text whiteSpace="nowrap">
          Total de produtos salvos: {amountProdutosLocalStorage}
        </Text>
      </Flex>
      {isProdutoSistema && (
        <BuscaAvancadaButton
          borderRadius="full"
          bg="transparent"
          height="32px"
          mb={['10px', '10px', '0']}
          colorScheme={hasFiltros ? 'blue' : 'gray'}
          variant={hasFiltros ? 'solid' : 'outlineDefault'}
          hasFilters={hasFiltros}
          setIsModalBuscaAvancadaOpen={() => {
            setIsOpenBuscaAvancada(true);
          }}
          padding="10px 24px"
          justifyContent="center"
          minW={['155px', '155px', '155px', '188px']}
          width={['full', '50%', '155px', '188px']}
        >
          {hasFiltros ? 'Filtros selecionados' : 'Busca avançada'}
        </BuscaAvancadaButton>
      )}
      <Button
        w={['full', 'full', '120px']}
        variant="solid"
        h="32px"
        mb={['10px', '10px', '0']}
        isDisabled={!!(!isProdutosLancados && isProdutoSistema)}
        colorScheme="purple.500"
        onClick={() => {
          if (ref.current?.handleAvancar) {
            ref.current?.handleAvancar();
          }
        }}
        fontSize="12px"
      >
        Avançar
      </Button>
      {isLargerThan700 && (
        <Icon
          cursor="pointer"
          boxSize="18px"
          color="gray.500"
          onClick={() => {
            ModalAjuda();
          }}
          as={BiHelpCircle}
        />
      )}
    </>
  );
};
