import React from 'react';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

import LocalEstoqueListar from '../pages/LocalEstoque/Listar';
import LocalEstoqueCadastrar from '../pages/LocalEstoque/Formulario/Cadastrar';
import LocalEstoqueAlterar from '../pages/LocalEstoque/Formulario/Alterar';
import LocalEstoqueVisualizar from '../pages/LocalEstoque/Formulario/Visualizar';

export const LocalEstoqueRoutes = [
  <LayoutGuard
    key="local-estoque-listar"
    permissaoFuncionalidade={ConstanteFuncionalidades.LOCAL_ESTOQUE_LISTAR}
    component={LocalEstoqueListar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Local do Estoque',
      },
    ]}
    path={ConstanteRotas.LOCAL_ESTOQUE}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="local-estoque-cadastrar"
    permissaoFuncionalidade={ConstanteFuncionalidades.LOCAL_ESTOQUE_CADASTRAR}
    component={LocalEstoqueCadastrar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Local do Estoque',
        path: ConstanteRotas.LOCAL_ESTOQUE,
      },
      {
        title: 'Novo',
      },
    ]}
    path={ConstanteRotas.LOCAL_ESTOQUE_CADASTRAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="local-estoque-alterar"
    permissaoFuncionalidade={ConstanteFuncionalidades.LOCAL_ESTOQUE_ALTERAR}
    component={LocalEstoqueAlterar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Local do Estoque',
        path: ConstanteRotas.LOCAL_ESTOQUE,
      },
      {
        title: 'Editar',
      },
    ]}
    path={ConstanteRotas.LOCAL_ESTOQUE_ALTERAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="local-estoque-visualizar"
    permissaoFuncionalidade={ConstanteFuncionalidades.LOCAL_ESTOQUE_VISUALIZAR}
    component={LocalEstoqueVisualizar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Local do Estoque',
        path: ConstanteRotas.LOCAL_ESTOQUE,
      },
      {
        title: 'Visualizar',
      },
    ]}
    path={ConstanteRotas.LOCAL_ESTOQUE_VISUALIZAR}
    exact
    meta={{ auth: true }}
  />,
];
