import React from 'react';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

import PerfisUsuariosListar from '../pages/PerfisUsuarios/Listar';
import PerfisUsuariosCadastrar from '../pages/PerfisUsuarios/Formulario/Cadastrar';
import PerfisUsuariosAlterar from '../pages/PerfisUsuarios/Formulario/Alterar';
import PerfisUsuariosVisualizar from '../pages/PerfisUsuarios/Formulario/Visualizar';

const PerfisUsuariosRoutes = [
  <LayoutGuard
    key="perfil-usuario-listar"
    permissaoFuncionalidade={ConstanteFuncionalidades.PERFIL_USUARIO_LISTAR}
    component={PerfisUsuariosListar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Perfil de Usuários',
      },
    ]}
    path={ConstanteRotas.PERFIL_USUARIO}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="perfil-usuario-cadastrar"
    permissaoFuncionalidade={ConstanteFuncionalidades.PERFIL_USUARIO_CADASTRAR}
    component={PerfisUsuariosCadastrar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Perfil de Usuários',
        path: ConstanteRotas.PERFIL_USUARIO,
      },
      {
        title: 'Novo',
      },
    ]}
    path={ConstanteRotas.PERFIL_USUARIO_CADASTRAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="perfil-usuario-alterar"
    permissaoFuncionalidade={ConstanteFuncionalidades.PERFIL_USUARIO_ALTERAR}
    component={PerfisUsuariosAlterar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Perfil de Usuários',
        path: ConstanteRotas.PERFIL_USUARIO,
      },
      {
        title: 'Editar',
      },
    ]}
    path={ConstanteRotas.PERFIL_USUARIO_ALTERAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="perfil-usuario-visualizar"
    permissaoFuncionalidade={ConstanteFuncionalidades.PERFIL_USUARIO_VISUALIZAR}
    component={PerfisUsuariosVisualizar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Perfil de Usuários',
        path: ConstanteRotas.PERFIL_USUARIO,
      },
      {
        title: 'Visualizar',
      },
    ]}
    path={ConstanteRotas.PERFIL_USUARIO_VISUALIZAR}
    exact
    meta={{ auth: true }}
  />,
];

export default PerfisUsuariosRoutes;
