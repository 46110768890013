import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import api, { ResponseApi } from 'services/api';
import { ProdutosResponseProps, usePromocaoContext } from 'store/Promocao';

import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import { PaginationData } from 'components/update/Pagination';

export const useProdutoPaginado = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [listarProdutos, setListarProdutos] = useState<ProdutosResponseProps[]>(
    []
  );

  const { pagedTableRef } = usePromocaoContext();

  const { id: idRota } = useParams<{ id: string }>();

  const paginationHandle = useCallback(
    async (gridPaginadaConsulta: PaginationData) => {
      setIsLoading(true);
      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<ProdutosResponseProps>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.CADASTRAR_PRODUTO_PROMOCAO.replace(
            'id',
            idRota
          ),
          gridPaginadaConsulta
        )
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }
        if (response.sucesso) {
          setTotalRegistros(response.dados.total);
          setListarProdutos(response.dados.registros);
          setIsLoading(false);
        }
      }
    },
    [idRota]
  );

  return {
    listarProdutos,
    totalRegistros,
    isLoading,
    paginationHandle,
    pagedTableRef,
  };
};
