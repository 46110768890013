import styled from 'styled-components';
import { Container as ContainerImportado } from 'react-bootstrap';
import ButtonComOpcoes from 'components/Button/ButtonComOpcoes';

export const Container = styled(ContainerImportado)`
  min-width: 100%;
  padding: 30px;
`;

export const ButtonComOpcoesCliente = styled(ButtonComOpcoes)`
  @media (max-width: 700px) {
    padding-top: 15px !important;
  }
`;

export const ColEndereco = styled.div`
  background-color: var(--sti-ck-colors-gray-50);
  padding: 20px;
  border: 1px solid var(--sti-ck-colors-gray-100);
  border-radius: 5px;
`;

export const SpanLogradouro = styled.div`
  color: var(--black);
  font-weight: 600;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  display: flex;
  margin-bottom: 5px;
`;

export const SpanCep = styled.div`
  color: var(--sti-ck-colors-gray-300);
  font-family: 'Nunito', sans-serif;
  font-size: 12px;
`;

export const Cobranca = styled.div`
  color: var(--white);
  width: 80px;
  height: 20px;
  text-align: center;
  padding-top: 2px;
  border-radius: 15px;
  margin-left: 10px;
  background-color: var(--sti-ck-colors-orange-500);
  font-family: 'Nunito', sans-serif;
  line-break: auto;
  font-size: 11px;
`;

export const Entrega = styled.div`
  color: var(--white);
  width: 70px;
  height: 20px;
  text-align: center;
  padding-top: 2px;
  margin-left: 10px;
  border-radius: 15px;
  line-break: auto;
  background-color: var(--sti-ck-colors-blue-500);
  font-family: 'Nunito', sans-serif;
  font-size: 11px;
`;
