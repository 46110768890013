/* eslint-disable react/no-array-index-key */
/* eslint-disable radix */
import { Box, Divider, Flex, Grid, Spacer } from '@chakra-ui/react';
import React from 'react';

import { moneyMask } from 'helpers/format/fieldsMasks';
import MeioPagamentoFiscalEnum from 'constants/enum/fiscal/meioPagamentoFiscal';
import { getName } from 'helpers/enum/getName';
import { PagInterface, ValoresTotaisICMSInterface } from 'types/ImpressaoNFCe';

interface ImpressaoInterface {
  valoresTotais: ValoresTotaisICMSInterface;
  pagamento: PagInterface;
  qtdItens: number;
}

const Totais = ({ valoresTotais, pagamento, qtdItens }: ImpressaoInterface) => {
  const troco = Number(pagamento.vTroco);
  const desconto = Number(valoresTotais.valorDesconto);
  const acrescimos =
    Number(valoresTotais.valorOutrasDespesas) +
    Number(valoresTotais.valorSeguro) +
    Number(valoresTotais.valorFrete);

  return (
    <Grid>
      <Divider margin="3px 0px" />
      <Flex>
        <Box>QTD. TOTAL DE ITENS</Box>
        <Spacer />
        <Box>{qtdItens}</Box>
      </Flex>
      <Flex>
        <Box>VALOR TOTAL R$</Box>
        <Spacer />
        <Box>{moneyMask(valoresTotais.valorProduto, false)}</Box>
      </Flex>
      {acrescimos > 0 && (
        <Flex>
          <Box>ACRÉSCIMOS R$</Box>
          <Spacer />
          <Box>{moneyMask(acrescimos, false)}</Box>
        </Flex>
      )}
      {desconto > 0 && (
        <Flex>
          <Box>DESCONTO R$</Box>
          <Spacer />
          <Box>-{moneyMask(desconto, false)}</Box>
        </Flex>
      )}
      {(acrescimos > 0 || desconto > 0) && (
        <Flex fontWeight="bold">
          <Box>VALOR A PAGAR R$</Box>
          <Spacer />
          <Box>{moneyMask(valoresTotais.valorNFe, false)}</Box>
        </Flex>
      )}

      <Flex marginTop="5px">
        <Box>FORMA PAGTO</Box>
        <Spacer />
        <Box>VALOR PAGO R$</Box>
      </Flex>

      {pagamento.grupoFormaPagamento.map((p, index) => (
        <Flex key={index}>
          <Box>{getName(MeioPagamentoFiscalEnum, p.tipoPagamento)}</Box>
          <Spacer />
          <Box>{moneyMask(p.valorPagamento, false)}</Box>
        </Flex>
      ))}
      {troco > 0 && (
        <Flex>
          <Box>Troco R$</Box>
          <Spacer />
          <Box>{moneyMask(troco, false)}</Box>
        </Flex>
      )}
    </Grid>
  );
};

export default Totais;
