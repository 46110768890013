import { Button, Flex, Text } from '@chakra-ui/react';
import Lottie from 'lottie-react';

import PagamentoAprovado from 'assets/animations/pix-confirm.json';
import PagamentoPix from 'assets/animations/phone-scanning.json';
import { EnumStatusPagamentos } from 'constants/enum/enumPagamentos';

type PaymentStatusProps = {
  paymentStatus: string;
  messageTransacao: string;
  handleCancelar: () => Promise<void>;
  finalizarPagamento: () => Promise<void>;
};

export const PaymentStatus = ({
  paymentStatus,
  messageTransacao,
  handleCancelar,
  finalizarPagamento,
}: PaymentStatusProps) => {
  return (
    <Flex
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      py="32px"
    >
      {paymentStatus === EnumStatusPagamentos.PENDING && (
        <>
          <Lottie
            animationData={PagamentoPix}
            loop
            style={{
              width: '98px',
              height: '98px',
            }}
          />
          <Text
            color="black"
            fontWeight="semibold"
            fontSize="md"
            mt="12px"
            textAlign={['center', 'center', 'start']}
          >
            {messageTransacao}
          </Text>
          <Flex gap="24px">
            <Button
              variant="cancel"
              size="sm"
              maxW={{ base: '120px', sm: '100px' }}
              minW="75px"
              w="full"
              mt="48px"
              onClick={handleCancelar}
            >
              Cancelar
            </Button>
          </Flex>
        </>
      )}
      {(paymentStatus === EnumStatusPagamentos.PAID ||
        paymentStatus === EnumStatusPagamentos.SUCCEEDED) && (
        <>
          <Lottie
            animationData={PagamentoAprovado}
            loop
            style={{
              width: '112px',
              height: '112px',
            }}
            onLoopComplete={finalizarPagamento}
          />
          <Text
            color="aquamarine.700"
            fontSize="md"
            mt="8px"
            w="230px"
            textAlign="center"
          >
            {messageTransacao}
          </Text>
        </>
      )}
    </Flex>
  );
};
