import { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import { setDateMaxHours, setDateMinHours } from 'helpers/data/setHoursDate';
import { shallowEqual } from 'helpers/validation/shallowEqual';

import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import { PaginationData } from 'components/update/Pagination';
import { PagedTableForwardRefData } from 'components/update/Table/PagedTable';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { ModalConfirmacaoInativar } from 'components/Modal/ModalConfirmacaoInativar';

type ListarPromocaoProps = {
  id: string;
  ativo: boolean;
  nome: string;
};

type FormData = {
  nome: string;
  vigenciaInicio: Date;
  vigenciaFim: Date;
  status: number;
};

const defaultValues = {
  nome: '',
  vigenciaInicio: setDateMinHours(new Date()),
  vigenciaFim: setDateMaxHours(new Date()),
  status: 1,
};

export const useListarPromocao = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [listarPromocao, setListarPromocao] = useState<ListarPromocaoProps[]>(
    []
  );
  const [currentFilters, setCurrentFilters] = useState<FormData>(defaultValues);

  const isMountedRef = useIsMountedRef();
  const pageIsLoaded = useRef(false);
  const pagedTableRef = useRef<PagedTableForwardRefData>(null);

  const history = useHistory();

  const formMethods = useForm<FormData>({
    defaultValues,
  });

  const filtersSubmit = formMethods.handleSubmit((data) => {
    const filtersIsDirty = !shallowEqual(data, currentFilters || {});

    if (filtersIsDirty) {
      setCurrentFilters(data);
    }
  });

  const handleCadastrarPromocao = () => {
    history.push(ConstanteRotas.PROMOCAO_CADASTRAR);
  };

  const getNomeLinkHref = useCallback((id: string) => {
    return SubstituirParametroRota(ConstanteRotas.PROMOCAO_ALTERAR, 'id', id);
  }, []);

  const paginationHandle = useCallback(
    async (gridPaginadaConsulta: PaginationData) => {
      setIsLoading(true);
      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<ListarPromocaoProps>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.CADASTRAR_PROMOCAO,
          gridPaginadaConsulta
        ),
        {
          params: {
            ...currentFilters,
          },
        }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }
        if (response.sucesso && isMountedRef.current) {
          setTotalRegistros(response.dados.total);
          setListarPromocao(response.dados.registros);
          setIsLoading(false);
        }
      }

      if (isMountedRef.current) {
        setIsLoading(false);

        if (!pageIsLoaded.current) {
          pageIsLoaded.current = true;
        }
      }
    },
    [isMountedRef, currentFilters]
  );

  const excluirHandle = useCallback(
    async (promocaoId: string, ativo: boolean) => {
      ModalConfirmacaoExcluir({
        callback: async (ok: boolean) => {
          if (ok) {
            setIsLoading(true);

            const response = await api.delete<void, ResponseApi>(
              `${ConstanteEnderecoWebservice.CADASTRAR_PROMOCAO}/${promocaoId}`
            );

            if (response?.sucesso) {
              toast.success('O cadastro foi removido com sucesso.');

              pagedTableRef?.current?.reload();
            }

            if (response) {
              ModalConfirmacaoInativar({
                response,
                rotaWebService: ConstanteEnderecoWebservice.MARCA_INATIVAR,
                id: promocaoId,
                ativo,
                callback: (okInativar: boolean) => {
                  if (okInativar) pagedTableRef?.current?.reload();
                },
              });
            }

            setIsLoading(false);
          }
        },
      });
    },
    []
  );

  return {
    isLoading,
    totalRegistros,
    setListarPromocao,
    filtersSubmit,
    formMethods,
    pagedTableRef,
    getNomeLinkHref,
    paginationHandle,
    listarPromocao,
    excluirHandle,
    handleCadastrarPromocao,
    history,
    defaultValues,
  };
};
