import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Stack,
  Button,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Icon,
  useToken,
} from '@chakra-ui/react';
import { FiCheck, FiX } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { ValueType } from 'react-select';

import { useImportarContext } from 'store/ImportarContext';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import Select, { findOption } from 'components/PDV/Select/SelectPadrao/Input';

interface ColunaSistemaOption {
  label: string;
  value: number;
}

interface Coluna {
  id: string;
  numeroColunaPlanilha: number;
  nomeColunaPlanilha: string;
  numeroColunaSistema: number;
  nomeColunaSistema: string;
  descricaoColunaSistema: string;
  exemplo: string;
  ignorar: boolean;
}

type OptionsColumns = {
  nomeColunaSistema: string;
  numeroColunaSistema: number;
};

export function SegundaEtapa() {
  const [fontSizeXS] = useToken('fontSizes', ['xs']);
  const [selectBorderRadius] = useToken('radii', ['md']);
  const {
    handleCancelar,
    nextStep,
    tipoImportacao,
    arquivo,
  } = useImportarContext();

  const [colunas, setColunas] = useState<Coluna[]>([]);
  const [colunasSistemaOptions, setColunasSistemaOptions] = useState<
    ColunaSistemaOption[]
  >([]);

  const [columnsOptionsSistema, setColumnsOptionsSistema] = useState<
    ColunaSistemaOption[]
  >([]);

  const [isLoading, setIsLoading] = useState(false);

  function handleChangeNumeroColunaSistema(
    newNumeroColunaSistema: number,
    index: number
  ) {
    const newImportarColunas = [...colunas];

    const currentColuna = newImportarColunas[index];

    newImportarColunas.splice(index, 1);
    newImportarColunas.splice(index, 0, {
      ...currentColuna,
      numeroColunaSistema: newNumeroColunaSistema,
    });

    setColunas(newImportarColunas);
  }

  function handleToggleIgnorar(index: number) {
    const newImportarColunas = [...colunas];

    const currentColuna = newImportarColunas[index];

    newImportarColunas.splice(index, 1);

    newImportarColunas.splice(index, 0, {
      ...currentColuna,
      ignorar: !currentColuna.ignorar,
    });

    setColunas(newImportarColunas);
  }

  async function convertToBase64(file: File) {
    const response = await new Promise<string | null>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string | null);
      reader.onerror = (error) => reject(error);
    });

    if (response) {
      const result = response.split(',')?.pop();

      return result;
    }

    return undefined;
  }

  async function handlePostImportacao() {
    setIsLoading(true);

    if (arquivo) {
      const fileBase64 = await convertToBase64(arquivo);

      const response = await api.post<void, ResponseApi<any>>(
        ConstanteEnderecoWebservice.IMPORTAR_CADASTRAR,
        {
          fileBase64,
          nomeArquivo: arquivo.name,
          combinacaoCampos: colunas,
          tipo: tipoImportacao,
        }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.map((item: string) => toast.warning(item));
        }

        if (response.sucesso) {
          nextStep();
        }
      }
    }

    setIsLoading(false);
  }

  const latestProps = useRef({ tipoImportacao, arquivo });
  useEffect(() => {
    latestProps.current = { tipoImportacao, arquivo };
  });

  useEffect(() => {
    async function listarColunas() {
      setIsLoading(true);

      if (latestProps.current.arquivo) {
        const fileBase64 = await convertToBase64(latestProps.current.arquivo);

        const response = await api.put<void, ResponseApi<Coluna[]>>(
          ConstanteEnderecoWebservice.IMPORTAR_LISTAR_COLUNAS,
          {
            fileBase64,
            tipo: latestProps.current.tipoImportacao,
          }
        );

        if (response) {
          if (response.avisos) {
            response.avisos.map((item: string) => toast.warning(item));
          }

          if (response.sucesso && response.dados) {
            const newColunasSistemaOptions: ColunaSistemaOption[] = response.dados.map(
              (coluna) => ({
                value: coluna.numeroColunaSistema,
                label: coluna.nomeColunaSistema,
              })
            );
            setColunasSistemaOptions(newColunasSistemaOptions);

            setColunas(response.dados);
          }
        }
      }

      setIsLoading(false);
    }

    listarColunas();
  }, []);

  useEffect(() => {
    async function getColunasImpotacao() {
      setIsLoading(true);
      const response = await api.get<void, ResponseApi<OptionsColumns[]>>(
        ConstanteEnderecoWebservice.IMPORTAR_LISTAR_COLUNAS_PARA_IMPORTACAO,
        {
          params: { tipoImportacao: latestProps.current.tipoImportacao },
        }
      );

      const optionsValues = response.dados.map(
        (item) =>
          ({
            label: item.nomeColunaSistema,
            value: item.numeroColunaSistema,
          } as ColunaSistemaOption)
      );
      setColumnsOptionsSistema(optionsValues);

      setIsLoading(false);
    }
    getColunasImpotacao();
  }, []);

  return (
    <Box w="full">
      {isLoading && <LoadingPadrao />}
      <Box
        bg="white"
        boxShadow="md"
        borderRadius="md"
        overflowY="visible"
        border="1px"
        borderColor="gray.100"
      >
        <Table variant="filled">
          <Thead>
            <Tr>
              <Th w="1px" />
              <Th w="30%">Coluna no seu arquivo</Th>
              <Th w="40%">Campo no Sistema</Th>
              <Th w="30%">Pré-visualização</Th>
              <Th textAlign="center" w="1">
                Ação
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {colunas
              .filter(({ nomeColunaPlanilha }) => !!nomeColunaPlanilha)
              .map((coluna, index) => (
                <Tr key={coluna.id}>
                  <Td>
                    <Icon
                      as={coluna.ignorar ? FiX : FiCheck}
                      boxSize="4"
                      color={coluna.ignorar ? 'red.500' : 'green.500'}
                    />
                  </Td>
                  <Td>{coluna.nomeColunaPlanilha}</Td>
                  <Td>
                    <Box
                      minW="150px"
                      maxW="290px"
                      opacity={coluna.ignorar ? 0.3 : 1}
                      sx={{
                        '& .react-select__control': {
                          borderRadius: `${selectBorderRadius} !important`,
                          cursor: 'pointer',
                        },
                        '& .react-select__menu > div': {
                          display: 'flex',
                          flexDir: 'column',
                        },
                      }}
                    >
                      <Select
                        size="xs"
                        options={columnsOptionsSistema}
                        isDisabled={coluna.ignorar}
                        menuPlacement="auto"
                        value={
                          coluna.numeroColunaSistema !== undefined
                            ? findOption(
                                coluna.numeroColunaSistema,
                                columnsOptionsSistema
                              )
                            : null
                        }
                        defaultValue={
                          coluna.nomeColunaPlanilha === coluna.nomeColunaSistema
                            ? findOption(
                                coluna.numeroColunaSistema,
                                colunasSistemaOptions
                              )
                            : undefined
                        }
                        onChange={(
                          option:
                            | ValueType<ColunaSistemaOption>
                            | null
                            | undefined
                        ) => {
                          if (option) {
                            const newColunaSistema = (option as ColunaSistemaOption)
                              .value;

                            handleChangeNumeroColunaSistema(
                              newColunaSistema,
                              index
                            );
                          }
                        }}
                        styles={{
                          valueContainer: (base: any) => ({
                            ...base,
                            fontSize: fontSizeXS,
                          }),
                          input: (provided: any) => ({
                            ...provided,
                            color: 'inherit',
                            lineHeight: 1,
                            margin: 0,
                            padding: 0,
                          }),
                        }}
                      />
                    </Box>
                  </Td>
                  <Td>
                    <Box opacity={coluna.ignorar ? 0.3 : 1}>
                      {coluna.exemplo}
                    </Box>
                  </Td>
                  <Td>
                    <Button
                      borderRadius="md"
                      minW={{ base: '10px', md: '120px' }}
                      size="xs"
                      variant={coluna.ignorar ? 'solid' : 'outline'}
                      colorScheme={coluna.ignorar ? 'aquamarine' : 'gray'}
                      onClick={() => handleToggleIgnorar(index)}
                    >
                      {coluna.ignorar ? 'Habilitar' : 'Ignorar'}
                    </Button>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </Box>

      <Stack
        direction={{ base: 'column', sm: 'row' }}
        mt="8"
        spacing="6"
        w="full"
        justifyContent="space-between"
      >
        <Button
          variant="outline"
          borderRadius="md"
          fontSize="sm"
          minW="175px"
          onClick={handleCancelar}
        >
          Cancelar
        </Button>

        <Button
          colorScheme="primary"
          borderRadius="md"
          fontSize="sm"
          minW="175px"
          onClick={handlePostImportacao}
        >
          Salvar e avançar
        </Button>
      </Stack>
    </Box>
  );
}
