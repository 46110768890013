import styled from 'styled-components';
import ModalPadrao from 'components/Modal/ModalPadrao';

export const Modal = styled(ModalPadrao)`
  min-width: 90% !important;

  .modal-lg {
    min-width: 90% !important;
  }
`;

export const ContainerTab = styled.div`
  margin-top: 20px;
`;
