/* eslint-disable @typescript-eslint/ban-ts-comment */

import React, { useMemo } from 'react';
import {
  Box,
  Stack,
  Text,
  Menu as ChakraMenu,
  MenuButtonProps as ChakraMenuButtonProps,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Flex,
  Button,
  Divider,
} from '@chakra-ui/react';
import { FiChevronDown, FiCheck } from 'react-icons/fi';
import { IconType as ReactIconsIconType } from 'react-icons/lib';
// @ts-ignore
import NotificationBadge, { Effect } from 'react-notification-badge';

import { IconType } from 'icons';

import { MobileMenuItem } from '../Mobile/MenuItem';

type Item = {
  value: string | number;
  name: string;
  labelForMenuButton?: string;
  iconForMenuButton?: IconType | ReactIconsIconType;
  menuColor?: string;
  onClick?: () => void;
  isDisabled?: boolean;
  closeMobileMenuOnClick?: boolean;
};

interface MenuProps extends ChakraMenuButtonProps {
  icon: IconType | ReactIconsIconType;
  title: string;
  items: Item[];
  value?: string | number;
  setValue?: (newValue: string | number) => void;
  subtitle?: string;
  subtitleColor?: string;
  badgeCount?: number;
  asAccordion?: boolean;
}

const Menu: React.FC<MenuProps> = ({
  icon,
  title,
  items,
  value,
  setValue,
  subtitle,
  subtitleColor = 'teal.400',
  badgeCount,
  asAccordion,
  color = 'gray.100',
  ...rest
}) => {
  const selectedItem = useMemo(
    () => items.find((item) => item.value === value),
    [items, value]
  );

  if (asAccordion) {
    return (
      <MobileMenuItem
        icon={selectedItem?.iconForMenuButton || icon}
        title={selectedItem?.labelForMenuButton || selectedItem?.name || title}
        subtitle={subtitle || badgeCount ? `${badgeCount}` : undefined}
        color={color}
      >
        {({ onClose }) => (
          <>
            <Divider borderColor="pdv.divider" />
            <Stack
              direction="column"
              align="flex-start"
              justifyContent="flex-start"
              h="100%"
              w="100%"
              spacing="0"
            >
              {items.map(({ closeMobileMenuOnClick = true, ...item }) => (
                <>
                  <Button
                    px={10}
                    py="4"
                    marginTop="0px !important"
                    color="white"
                    _hover={{
                      bg: 'pdv.menuButton',
                    }}
                    _focus={{
                      bg: 'pdv.menuButton',
                    }}
                    _active={{
                      bg: 'pdv.menuButtonHover',
                    }}
                    _expanded={{
                      bg: 'pdv.menuButton',
                    }}
                    borderRadius={0}
                    variant="ghost"
                    fontSize="sm"
                    w="100%"
                    h="auto"
                    justifyContent="flex-start"
                    position="relative"
                    isDisabled={item.isDisabled}
                    onClick={() => {
                      if (setValue) {
                        setValue(item.value);
                      }

                      if (item.onClick) {
                        item.onClick();
                      }

                      if (closeMobileMenuOnClick) {
                        onClose();
                      }
                    }}
                  >
                    {value === item.value && (
                      <Icon
                        as={FiCheck}
                        boxSize="4"
                        position="absolute"
                        left="4"
                      />
                    )}

                    {item.name}
                  </Button>
                  <Divider borderColor="pdv.divider" />
                </>
              ))}
            </Stack>
          </>
        )}
      </MobileMenuItem>
    );
  }

  return (
    <ChakraMenu colorScheme="system">
      <MenuButton
        position="relative"
        color={selectedItem?.menuColor || 'gray.300'}
        variant="ghost"
        fontSize="xs"
        height={{ base: '70px', md: 'auto' }}
        width={{ base: '100%', md: undefined }}
        _expanded={{ color: selectedItem?.menuColor || 'white' }}
        p={0}
        {...rest}
      >
        <Stack
          direction="column"
          align="center"
          justifyContent="center"
          h="100%"
          w="100%"
          spacing={2}
        >
          <Box position="relative">
            <Icon as={selectedItem?.iconForMenuButton || icon} fontSize="3xl" />

            {badgeCount && badgeCount > 0 ? (
              <Box
                position="absolute"
                bottom={0}
                right={0}
                transform="translate(50%, 25%)"
                h="22px"
                w="22px"
                borderRadius="full"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <NotificationBadge count={badgeCount} effect={Effect.SCALE} />
              </Box>
            ) : null}
          </Box>

          <Flex
            position="relative"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Flex alignItems="center" justifyContent="center">
              <Text fontWeight="normal" pr={1} fontSize="sm">
                {selectedItem?.labelForMenuButton ||
                  selectedItem?.name ||
                  title}
              </Text>

              <Icon as={FiChevronDown} />
            </Flex>

            {subtitle && (
              <Text
                position="absolute"
                bottom={0}
                fontWeight="normal"
                fontSize="2xs"
                color={subtitleColor}
                transform="translateY(100%)"
                pt={1}
              >
                {subtitle}
              </Text>
            )}
          </Flex>
        </Stack>
      </MenuButton>
      <MenuList zIndex="modal">
        {items.map((item) => (
          <MenuItem
            pl={value === item.value ? '0.8rem' : undefined}
            isDisabled={item.isDisabled}
            onClick={() => {
              if (setValue) {
                setValue(item.value);
              }

              if (item.onClick) {
                item.onClick();
              }
            }}
          >
            {value === item.value && (
              <Icon as={FiCheck} marginRight="0.8rem" w="0.75rem" />
            )}
            {item.name}
          </MenuItem>
        ))}
      </MenuList>
    </ChakraMenu>
  );
};

export default Menu;
