import { useCallback, useState } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Icon,
  Flex,
  ModalFooter,
  Box,
  ModalHeader,
  useMediaQuery,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FiTrash2 } from 'react-icons/fi';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import VirtualizedList from 'components/update/VirtualizedList';

type ProdutoSelecionado = {
  produtoId: string;
  listaProdutoCorTamanhoId: string[];
  descricao: string;
};

type ModalProdutosSelecionadosProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalProps> & {
    handleEnviarProdutos: () => void;
    listProdutos: ProdutoSelecionado[];
    reload: () => void;
  };

export const ModalProdutosSelecionados = create<
  ModalProdutosSelecionadosProps,
  ModalProps
>(
  ({
    onResolve,
    onReject,
    reload,
    handleEnviarProdutos,
    listProdutos: produtos,
    ...rest
  }) => {
    const [listProdutos, setListProdutos] = useState(produtos);

    const { isOpen, onClose: handleClose } = useDisclosure({
      defaultIsOpen: true,
    });

    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    const onClose = () => {
      reload();
      handleClose();
    };

    const handleRemoverProduto = useCallback(
      (id: string) => {
        const newListProdutos = [...listProdutos];

        const index = newListProdutos.findIndex(
          (itemProduto) => itemProduto.produtoId === id
        );

        newListProdutos.splice(index, 1);
        setListProdutos(newListProdutos);

        localStorage.setItem(
          'listarProdutoStorage',
          JSON.stringify(newListProdutos)
        );
      },
      [listProdutos]
    );

    const handleConfirmar = () => {
      handleClose();
      handleEnviarProdutos();
    };

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? '3xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
      >
        <ModalContent
          bg="gray.50"
          borderRadius={isLargerThan900 ? 'md' : '0'}
          marginBottom={isLargerThan900 ? '3.75rem' : '0'}
          marginTop={isLargerThan900 ? '3.75rem' : '0'}
          w={isLargerThan900 ? '880px' : 'full'}
          h={isLargerThan900 ? '580px' : 'full'}
        >
          <ModalHeader
            display="flex"
            justifyContent="space-between"
            pt="16px"
            pb="28px"
            pl="24px"
          >
            <Text color="primary.50" fontSize="20px">
              Produtos selecionados
            </Text>
            <Text fontSize="14px">
              {`${listProdutos.length}${
                listProdutos.length === 1
                  ? ' item selecionado'
                  : ' itens selecionados'
              }`}
            </Text>
          </ModalHeader>

          <ModalBody pl="24px" pr="24px" mb="20px" pt="0" pb="0">
            <VirtualizedList
              containerHeight={384}
              itemHeight={48}
              colorBgGradient="gray.50"
              items={listProdutos}
              tamanhoPagina={8}
              marginBottom={4}
              quantidadeItems={listProdutos.length}
              key="virtualizedProdutosSelecionadosTray"
              render={(items, height, mb) => (
                <>
                  {items.map((produtoItem) => {
                    return (
                      <>
                        <Flex
                          h={`calc(${height} - ${mb})`}
                          borderRadius="5px"
                          boxShadow="0px 0px 4px #00000029"
                          bg="white"
                          px="24px"
                          alignItems="center"
                          justifyContent="space-between"
                          _hover={{
                            boxShadow: '0px 0px 4px rgba(85, 2, 178, 0.5)',
                          }}
                        >
                          <Text color="black">{produtoItem.descricao}</Text>
                          <Icon
                            onClick={() =>
                              handleRemoverProduto(produtoItem.produtoId)
                            }
                            cursor="pointer"
                            boxSize="17px"
                            color="gray.700"
                            as={FiTrash2}
                          />
                        </Flex>
                        <Box h="4px" />
                      </>
                    );
                  })}
                </>
              )}
            />
          </ModalBody>
          <ModalFooter flexDirection="column" mb="10px">
            <Flex
              w="full"
              h="full"
              justifyContent="center"
              alignItems="baseline"
            >
              <Button
                variant="outlineDefault"
                colorScheme="gray"
                lineHeight="0"
                borderRadius="20px"
                fontSize="sm"
                h="40px"
                width="120px"
                mr="24px"
                onClick={() => {
                  reload();
                  onClose();
                }}
              >
                Cancelar
              </Button>
              <Button
                color="white"
                variant="solid"
                colorScheme="purple.500"
                lineHeight="0"
                borderRadius="20px"
                fontSize="sm"
                h="40px"
                width="200px"
                onClick={() => handleConfirmar()}
              >
                Confirmar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
