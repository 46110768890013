import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormContext } from 'react-hook-form';

import api, { ResponseApi } from 'services/api';
import isPrenvent from 'helpers/layout/isPrenvent';
import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { usePromocaoContext } from 'store/Promocao';

import { FormData } from '../validationForms';
import { listUsoPromocao, useFormularioPromocao } from '../hooks';

type PeriodoVigencia = {
  periodoInicio: string;
  periodoFim: string;
};

type HorarioVigencia = {
  periodoInicio: string;
  periodoFim: string;
};

type TabelaPreco = {
  id: string;
  nome: string;
  ativo: boolean;
};

type InformacaoCadastro = {
  ativo: boolean;
  dataHoraCadastro: string;
  dataHoraUltimaAlteracao: string;
};

type PromocaoResponseProps = {
  id: string;
  nome: string;
  periodoVigencia: PeriodoVigencia;
  horarioVigencia: HorarioVigencia;
  diasDaSemana: number[];
  telasUsoPromocao: number[];
  tabelaPreco: TabelaPreco;
  lojasId: string[];
  informacaoCadastro: InformacaoCadastro;
};

export const usePromocaoAlterar = () => {
  const [dataHoraCadastro, setDataHoraCadastro] = useState('');
  const [dataHoraUltimaAlteracao, setDataHoraUltimaAlteracao] = useState('');

  const { configuracaoPromocao } = useFormularioPromocao();

  const {
    listLojas,
    isLoading,
    setIsLoading,
    formIsDirty,
    setFormIsDirty,
  } = usePromocaoContext();

  const history = useHistory();

  const { id: idRota } = useParams<{ id: string }>();

  const {
    handleSubmit,
    formState: { isDirty },
    getValues,
    reset,
    setValue,
  } = useFormContext<FormData>();

  isPrenvent(formIsDirty);

  const formatDate = (date: string) => {
    const currentDate = new Date(date);

    const currentHours = currentDate.getHours();

    const dateUtc = 3;

    currentDate.setHours(currentHours - dateUtc);

    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    return `${currentYear}-${
      currentMonth < 10 ? `0${currentMonth}` : currentMonth
    }-${currentDay < 10 ? `0${currentDay}` : currentDay}`;
  };

  const formatDateHours = (date: string) => {
    const currentDate = new Date(date);

    const currentHours = currentDate.getHours();

    const dateUtc = 3;

    currentDate.setDate(currentHours - dateUtc);

    const hoursDate = `${String(currentDate.getHours()).padStart(
      2,
      '0'
    )}:${String(currentDate.getMinutes()).padStart(2, '0')}`;

    return hoursDate;
  };

  const resetValueFormulario = useCallback(
    (data: PromocaoResponseProps) => {
      const {
        diasDaSemana,
        lojasId,
        telasUsoPromocao,
        nome,
        informacaoCadastro,
        horarioVigencia,
        periodoVigencia,
      } = data;
      const formData = getValues();

      const listDiasDaSemana = formData?.diasDaSemana.map((itemDias) => {
        const diaCadastrado = diasDaSemana.find(
          (cadastroDiasItem) => cadastroDiasItem === itemDias.value
        );

        return {
          ...itemDias,
          ativo: diaCadastrado !== undefined,
        };
      });

      reset({
        nome,
        periodoVigenciaInicio: formatDate(periodoVigencia.periodoInicio),
        periodoVigenciaFim: formatDate(periodoVigencia.periodoFim),
        horarioVigenciaFim: formatDateHours(horarioVigencia.periodoFim),
        horarioVigenciaInicio: formatDateHours(horarioVigencia.periodoInicio),
        ativo: informacaoCadastro.ativo,
        diasDaSemana: listDiasDaSemana,
      });

      listUsoPromocao.forEach((itemUsoPromocao, index) => {
        const itemSelected = telasUsoPromocao.find(
          (currentItemPromoca) => currentItemPromoca === itemUsoPromocao.value
        );

        if (itemSelected !== undefined) {
          setValue(`telasUsoPromocao.${index}`, true);
        }
      });

      listLojas.forEach((itemLojas, indexLoja) => {
        const lojaSelected = lojasId.find(
          (currentLojaItem) => currentLojaItem === itemLojas.id
        );
        if (lojaSelected) {
          setValue(`lojas.${indexLoja}`, itemLojas.id);
        }
      });
      setDataHoraUltimaAlteracao(informacaoCadastro.dataHoraUltimaAlteracao);
      setDataHoraCadastro(informacaoCadastro.dataHoraCadastro);
      setIsLoading(false);
    },
    [getValues, setIsLoading, listLojas, reset, setValue]
  );

  const getPromocao = useCallback(async () => {
    if (listLojas.length === 0) {
      return;
    }

    setIsLoading(true);
    const response = await api.get<void, ResponseApi<PromocaoResponseProps>>(
      `${ConstanteEnderecoWebservice.CADASTRAR_PROMOCAO}/${idRota}`
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso) {
        resetValueFormulario(response.dados);
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [idRota, listLojas, resetValueFormulario, setIsLoading]);

  const onSubmit = handleSubmit(async () => {
    setIsLoading(true);
    setFormIsDirty(false);
    const data = await configuracaoPromocao();

    if (data === false) {
      setIsLoading(false);
      return;
    }

    const response = await api.put<void, ResponseApi<string>>(
      `${ConstanteEnderecoWebservice.CADASTRAR_PROMOCAO}/${idRota}`,
      {
        ...data,
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso) {
        toast.success('Cadastro salvo com sucesso');
        history.push(ConstanteRotas.PROMOCAO);
      }
    }
    setIsLoading(false);
  });

  useEffect(() => {
    setFormIsDirty(isDirty);
  }, [isDirty, setFormIsDirty]);

  useEffect(() => {
    getPromocao();
  }, [getPromocao]);

  return {
    isLoading,
    onSubmit,
    formIsDirty,
    dataHoraCadastro,
    dataHoraUltimaAlteracao,
  };
};
