import React, { useEffect, useState, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory, RouteComponentProps } from 'react-router-dom';

import ConstanteRotas from 'constants/rotas';
import { obterTransferenciaEstoque as apiObterTransferenciaEstoque } from 'helpers/api/TransferenciaEstoque/obterTransferenciaEstoque';

import ManterFoco from 'components/Geral/ManterFoco';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { FormFooter } from 'components/update/Form/FormFooter';

import Formulario from '..';
import { yupResolver, FormData } from '../validationForm';

export type TransferenciaEstoqueTParams = { id: string };

type TranferenciaEstoqueVisualizar = RouteComponentProps<TransferenciaEstoqueTParams> & {
  transferenciaEstoqueId?: string;
};

const Visualizar = ({
  transferenciaEstoqueId: propsTransferenciaEstoqueId,
  match,
}: TranferenciaEstoqueVisualizar) => {
  const history = useHistory();
  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues: {
      usuario: '',
      dataEmissao: new Date().toLocaleString('pt-BR'),
    },
  });

  const [dataHoraCriacao, setDataHoraCriacao] = useState('');
  const [dataHoraUltimaAlteracao, setDataHoraUltimaAlteracao] = useState('');

  const transferenciaEstoqueId =
    propsTransferenciaEstoqueId || match?.params.id;

  const [isLoading, setIsLoading] = useState(false);

  const latestProps = useRef({
    reset: formMethods.reset,
    transferenciaEstoqueId,
    history,
  });
  useEffect(() => {
    latestProps.current = {
      reset: formMethods.reset,
      transferenciaEstoqueId,
      history,
    };
  });

  useEffect(() => {
    async function obterTransferenciaEstoque() {
      setIsLoading(true);

      const responseData = await apiObterTransferenciaEstoque(
        latestProps.current.transferenciaEstoqueId
      );

      if (responseData) {
        const {
          lojaOrigemFantasia,
          localEstoqueOrigemOpcaoSelect,
          lojaDestinoOpcaoSelect,
          localEstoqueDestinoOpcaoSelect,
          motivo,
          usuarioNome,
          dataHoraEmissao,
          dataHoraEnvio,
          usuarioRecebimentoNome,
          dataHoraRecebimento,
        } = responseData;

        setDataHoraCriacao(dataHoraEmissao);
        setDataHoraUltimaAlteracao(dataHoraEnvio || '');

        latestProps.current.reset({
          usuario: usuarioNome,
          motivo,
          dataEmissao: new Date(dataHoraEmissao).toLocaleString('pt-BR'),
          usuarioRecebimento: usuarioRecebimentoNome,
          dataRecebimento: dataHoraRecebimento
            ? new Date(dataHoraRecebimento).toLocaleString('pt-BR')
            : undefined,
          lojaOrigem: lojaOrigemFantasia,
          localEstoqueOrigem: {
            value: localEstoqueOrigemOpcaoSelect.id,
            label: localEstoqueOrigemOpcaoSelect.nome,
          },
          lojaDestino: {
            value: lojaDestinoOpcaoSelect.id,
            label: lojaDestinoOpcaoSelect.nome,
          },
          localEstoqueDestino: {
            value: localEstoqueDestinoOpcaoSelect.id,
            label: localEstoqueDestinoOpcaoSelect.nome,
          },
        });

        setIsLoading(false);
      } else {
        setIsLoading(false);

        latestProps.current.history.push(ConstanteRotas.TRANSFERENCIA_ESTOQUE);
      }
    }

    obterTransferenciaEstoque();
  }, []);

  return (
    <Box>
      <ManterFoco>
        {isLoading && <LoadingPadrao />}
        <FormProvider {...formMethods}>
          <Formulario
            isReadonly
            transferenciaEstoqueId={transferenciaEstoqueId}
            isCadastrar={false}
          />
        </FormProvider>

        <FormFooter
          isDisabled={isLoading}
          dates={{ dataHoraCriacao, dataHoraUltimaAlteracao }}
        />
      </ManterFoco>
    </Box>
  );
};

export default Visualizar;
