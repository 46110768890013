import React, { useMemo } from 'react';
import { Icon, Tooltip, Box } from '@chakra-ui/react';

import auth, { PossuiPermissaoResponse } from 'modules/auth';

import ConditionalWrapper from 'components/Geral/ConditionalWrapper';
import { SemPermissaoIcon, SemPermissaoPlanoIcon } from 'icons';

// const FOCUSABLE_ELEMENTS_SELECTOR = 'button, [href], input, select, textarea';

interface BloqueadoIconeProps {
  bloqueioPlanoIconeStyle?: React.CSSProperties;
  bloqueioPermissaoIconeStyle?: React.CSSProperties;
}

interface ChildWithFuncProps extends PossuiPermissaoResponse {
  BloqueadoIcone?: (props: BloqueadoIconeProps) => JSX.Element;
}

type ChildWithFuncType = (props: ChildWithFuncProps) => JSX.Element | string;

interface ValidarPermissaoProps {
  funcionalidade?: string;
  children: ChildWithFuncType;
  tooltipWrap: 'hidden' | 'container' | 'icon';
}

/**
 * Função utilizada para formatar a url de paginação
 * @param  {[string] | [PossuiPermissaoResponse]} funcionalidade Recebe o string da funcionalidade
 * @param  {['hidden' | 'container' | 'icon']} tooltipWrap Recebe o lugar o qual o tooltip de informação da permissão será atrelado, caso hidden, ele não aparecerá
 * @return {[JSX.Element]} Caso permitido, retorna apenas o children recebido. Caso não permitido, retorna os componentes de permissão atrelados ao children
 */
const ValidarPermissao = ({
  funcionalidade,
  children,
  tooltipWrap,
}: ValidarPermissaoProps) => {
  const permissao = useMemo(
    () => (funcionalidade ? auth.possuiPermissao(funcionalidade) : undefined),
    [funcionalidade]
  );

  if (!permissao || permissao.permitido)
    return <>{(children as ChildWithFuncType)({ permitido: true })}</>;

  return (
    <ConditionalWrapper
      condition={tooltipWrap === 'container'}
      wrapper={(wrappedChildren: React.ReactNode) => (
        <Tooltip
          hasArrow
          p="10px"
          placement="right"
          borderRadius="6px"
          fontSize="12px"
          label={
            permissao.bloqueio === 'plano'
              ? 'Esta funcionalidade não está disponível neste plano, faça um upgrade.'
              : 'Você não tem permissão para acessar essa função. Consulte o administrador da conta.'
          }
        >
          <Box>{wrappedChildren}</Box>
        </Tooltip>
      )}
    >
      {(children as ChildWithFuncType)({
        permitido: false,
        bloqueio: permissao.bloqueio,
        BloqueadoIcone: ({
          bloqueioPlanoIconeStyle,
          bloqueioPermissaoIconeStyle,
        }) => (
          <ConditionalWrapper
            condition={tooltipWrap === 'icon'}
            wrapper={(wrappedChildren: React.ReactNode) => (
              <Tooltip
                hasArrow
                placement="right"
                fontSize="12px"
                p="10px"
                borderRadius="6px"
                label={
                  permissao.bloqueio === 'plano'
                    ? 'Esta funcionalidade não está disponível neste plano, faça um upgrade.'
                    : 'Você não tem permissão para acessar essa função. Consulte o administrador da conta.'
                }
              >
                <Box>{wrappedChildren}</Box>
              </Tooltip>
            )}
          >
            {permissao.bloqueio === 'plano' ? (
              <Icon
                as={SemPermissaoPlanoIcon}
                maxW="1em"
                minH="1em"
                fontSize="1.25rem"
                color="white"
                id="icone-bloqueio-permissao"
                style={bloqueioPlanoIconeStyle}
              />
            ) : (
              <Icon
                as={SemPermissaoIcon}
                maxW="1em"
                minH="1em"
                fontSize="1.25rem"
                color="white"
                id="icone-bloqueio-permissao"
                style={bloqueioPermissaoIconeStyle}
              />
            )}
          </ConditionalWrapper>
        ),
      })}
    </ConditionalWrapper>
  );
};

export default ValidarPermissao;
