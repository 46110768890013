import React from 'react';
import { Grid, HStack, Tabs, useMediaQuery } from '@chakra-ui/react';

import FinalizarProvider, { FinalizarContext } from 'store/PDV/Finalizar';

import Layout from 'components/PDV/Layout';

import { isMobile } from 'react-device-detect';
import Menu from './Menu';
import TabsContent from './TabsContent';
import { FinalizarCommonFooterButtons } from './Compartilhar';

const Finalizar = () => {
  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');

  return (
    <Layout>
      <FinalizarProvider>
        <FinalizarContext.Consumer>
          {({ tabIndex, handleTabsChange }) => (
            <Tabs isLazy isFitted index={tabIndex} h="full">
              <Grid
                templateColumns={
                  isMobile
                    ? '1fr'
                    : {
                        base: '1fr',
                        md: '370px 1fr',
                        lg: '450px 1fr',
                      }
                }
                templateRows={
                  isMobile ? 'auto 1fr' : { base: 'auto 1fr', md: '1fr' }
                }
                h="full"
              >
                <Menu tabIndex={tabIndex} handleTabsChange={handleTabsChange} />

                <TabsContent asModal={isSmallerThan900 || isMobile} />

                {(isSmallerThan900 || isMobile) && (
                  <HStack
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={3}
                    py={8}
                    h="full"
                  >
                    <FinalizarCommonFooterButtons />
                  </HStack>
                )}
              </Grid>
            </Tabs>
          )}
        </FinalizarContext.Consumer>
      </FinalizarProvider>
    </Layout>
  );
};

export default Finalizar;
