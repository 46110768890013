import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Text,
  Flex,
  VStack,
  Checkbox,
  FlexProps,
  Icon,
} from '@chakra-ui/react';

import { NaoSelecionadoIcon } from 'icons';

interface CheckboxCardProps extends FlexProps {
  title: string;
  name: string;
  subtitle?: string;
  isBlocked?: boolean;
  isSelected?: boolean;
  isLojaAtual?: boolean;

  fontSizeTitle?: string;
  fontSizeSubtitle?: string;
}

export const CheckboxCard = ({
  title,
  subtitle,
  name,
  isBlocked = false,
  isSelected = false,
  isLojaAtual = false,
  fontSizeTitle = 'sm',
  fontSizeSubtitle = 'xs',
  onClick,
  onChange,
  height = '64px',
  width = 'full',
  ...rest
}: CheckboxCardProps) => {
  const formMethods = useFormContext();
  const { setValue } = formMethods;

  const isChecked = isSelected || formMethods.watch(name);

  return (
    <>
      {isBlocked ? (
        <Flex
          alignItems="center"
          padding="12px"
          gap="10px"
          width={width}
          height={height}
          borderRadius="md"
          border="1px solid"
          borderColor="gray.200"
          transition="all 0.2s"
          bg="gray.100"
          cursor="not-allowed"
          _hover={{
            borderColor: 'gray.200',
            transition: 'all 0.2s',
          }}
          {...rest}
        >
          <Icon as={NaoSelecionadoIcon} boxSize="20px" color="gray.400" />
          <VStack spacing="0px" alignItems="flex-start">
            <Text fontSize="sm" fontWeight="semibold" userSelect="none">
              {title}
            </Text>
            {subtitle && (
              <Text
                fontSize="xs"
                fontWeight="semibold"
                userSelect="none"
                color="gray.500"
              >
                {subtitle}
              </Text>
            )}
          </VStack>
        </Flex>
      ) : (
        <Flex
          {...rest}
          alignItems="center"
          padding="12px"
          gap="4px"
          cursor="pointer"
          width={width}
          height={height}
          borderRadius="md"
          border="1px solid"
          borderColor="gray.200"
          transition="all 0.2s"
          bg={isChecked ? 'purple.50' : 'white'}
          _hover={{
            borderColor: isLojaAtual ? 'gray.200' : 'rgba(85, 2, 178, 0.5)',
            transition: 'all 0.2s',
          }}
          _active={{ bg: isLojaAtual ? 'purple.50' : 'white' }}
          onClick={(e) => {
            if (onClick) {
              setValue(name, !isChecked);
              onClick(e);
            } else {
              setValue(name, !isChecked);
            }
          }}
        >
          <Checkbox
            colorScheme="primary"
            mb="0px"
            mr="6px"
            name={name}
            boxShadow="inset 0px 3px 6px #00000029"
            onChange={onChange}
            isChecked={isChecked}
            pointerEvents="none"
          />
          <VStack spacing="0px" alignItems="flex-start">
            <Text
              fontSize={fontSizeTitle}
              fontWeight="semibold"
              userSelect="none"
              letterSpacing="0px"
            >
              {title}
            </Text>
            {subtitle && (
              <Text
                fontSize={fontSizeSubtitle}
                fontWeight="semibold"
                userSelect="none"
                color="gray.500"
              >
                {subtitle}
              </Text>
            )}
          </VStack>
        </Flex>
      )}
    </>
  );
};
