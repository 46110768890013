import React, { useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, GridItem, Link, Td, Tr } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import auth from 'modules/auth';
import api, { ResponseApi } from 'services/api';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import ConstanteFuncionalidades from 'constants/permissoes';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { shallowEqual } from 'helpers/validation/shallowEqual';
import TipoContaFinanceiraEnum from 'constants/enum/tipoContaFinanceira';

import { SearchInput } from 'components/update/Input/SearchInput';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { ModalConfirmacaoInativar } from 'components/Modal/ModalConfirmacaoInativar';
import {
  GridPaginadaConsulta,
  GridPaginadaRetorno,
} from 'components/Grid/Paginacao';
import {
  PagedTable,
  PagedTableForwardRefData,
} from 'components/update/Table/PagedTable';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { FilterSelect } from 'components/update/Select/FilterSelect';
import { ButtonCadastrarNovo } from 'components/Layout/ButtonCadastrarNovo';
import { StatusCircle } from 'components/update/Table/StatusCircle';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';

interface ContaFinanceiraFiltros {
  nome: string;
  ativo: boolean;
}

interface ContaFinanceira {
  id: string;
  tipo: string;
  tipoContaFinanceira: number;
  nome: string;
  ativo: boolean;
  padraoSistema: boolean;
}

const formDefaultValues = {
  nome: '',
  ativo: true,
};

const Listar = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [currentFilters, setCurrentFilters] = useState<ContaFinanceiraFiltros>(
    formDefaultValues
  );
  const [contasFinanceiras, setContasFinanceiras] = useState(
    [] as Array<ContaFinanceira>
  );

  const history = useHistory();
  const isMountedRef = useIsMountedRef();

  const formMethods = useForm({
    defaultValues: formDefaultValues,
  });
  const { handleSubmit, setFocus } = formMethods;

  const pageIsLoaded = useRef(false);
  const childRef = useRef<PagedTableForwardRefData>(null);

  const permissaoContaAlterar = auth.possuiPermissao(
    ConstanteFuncionalidades.CONTA_FINANCEIRA_ALTERAR
  );

  const permissaoContaVisualizar = auth.possuiPermissao(
    ConstanteFuncionalidades.CONTA_FINANCEIRA_VISUALIZAR
  );

  const handleHistoryPush = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history]
  );

  const handleReset = handleSubmit((data) => {
    const filtersIsDirty = !shallowEqual(data, currentFilters || {});

    if (filtersIsDirty) {
      setCurrentFilters(data);
    }
  });

  const handleRefresh = useCallback(() => {
    return childRef.current?.reload();
  }, []);

  const getNomeLinkHref = useCallback(
    (id: string) => {
      let href = '';

      if (permissaoContaAlterar.permitido) {
        href = SubstituirParametroRota(
          ConstanteRotas.CONTA_FINANCEIRA_ALTERAR,
          'id',
          id
        );
      } else if (permissaoContaVisualizar.permitido) {
        href = SubstituirParametroRota(
          ConstanteRotas.CONTA_FINANCEIRA_VISUALIZAR,
          'id',
          id
        );
      }

      return href;
    },
    [permissaoContaAlterar, permissaoContaVisualizar]
  );

  const paginationHandle = useCallback(
    async (gridPaginadaConsulta: GridPaginadaConsulta) => {
      setIsLoading(true);

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<ContaFinanceira>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.CONTA_FINANCEIRA_LISTAR_PAGINADO,
          gridPaginadaConsulta
        ),
        { params: { nome: currentFilters.nome, ativo: currentFilters.ativo } }
      );

      if (response?.sucesso && isMountedRef.current) {
        setTotalRegistros(response.dados.total);
        setContasFinanceiras(response.dados.registros);
      }

      if (isMountedRef.current) {
        setIsLoading(false);

        if (!pageIsLoaded.current) {
          pageIsLoaded.current = true;

          setFocus('nome');
        }
      }
    },
    [currentFilters, isMountedRef, setFocus]
  );

  const excluirHandle = useCallback(
    async (contaId: string, ativo: boolean) => {
      ModalConfirmacaoExcluir({
        callback: async (ok: boolean) => {
          if (ok) {
            setIsLoading(true);

            const response = await api.delete<void, ResponseApi>(
              ConstanteEnderecoWebservice.CONTA_FINANCEIRA_EXCLUIR,
              {
                params: { id: contaId },
              }
            );

            if (response?.sucesso) {
              toast.success('O cadastro foi removido com sucesso.');

              handleRefresh();
            }

            if (response) {
              ModalConfirmacaoInativar({
                response,
                rotaWebService:
                  ConstanteEnderecoWebservice.CONTA_FINANCEIRA_INATIVAR,
                id: contaId,
                ativo,
                callback: (okInativar: boolean) => {
                  if (okInativar) handleRefresh();
                },
              });
            }

            setIsLoading(false);
          }
        },
      });
    },
    [handleRefresh]
  );

  return (
    <SimpleGridForm gap={{ base: '10px', sm: '10px', md: 8 }}>
      <FormProvider {...formMethods}>
        <GridItem colSpan={{ base: 12, md: 5, lg: 6 }}>
          <SearchInput
            type="search"
            onEnterKeyPress={() => handleReset()}
            placeholder="Buscar conta por nome"
            isDisabled={isLoading}
            id="nome"
            name="nome"
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, md: 2, lg: 2 }}>
          <Box w={{ base: 'full', md: '190px', lg: '190px' }}>
            <FilterSelect
              id="ativo"
              name="ativo"
              isDisabled={isLoading}
              options={[
                {
                  value: true,
                  label: 'Ativos',
                },
                {
                  value: false,
                  label: 'Inativos',
                },
                {
                  value: null,
                  label: 'Todos',
                },
              ]}
              onSelect={() => {
                handleReset();
              }}
            />
          </Box>
        </GridItem>
        <GridItem
          colSpan={{ base: 12, md: 5, lg: 4 }}
          display={{ base: '', sm: '', md: 'flex' }}
          justifyContent={{ base: 'flex-start', sm: 'flex-end' }}
        >
          <ButtonCadastrarNovo
            onClick={() =>
              handleHistoryPush(ConstanteRotas.CONTA_FINANCEIRA_CADASTRAR)
            }
            funcionalidade={ConstanteFuncionalidades.CONTA_FINANCEIRA_CADASTRAR}
          />
        </GridItem>
      </FormProvider>
      <GridItem mt={['10px', '10px', '-10px']} colSpan={12}>
        <PagedTable
          ref={childRef}
          itemsTotalCount={totalRegistros}
          loadColumnsData={paginationHandle}
          isLoading={isLoading}
          tableHeaders={[
            {
              content: <StatusCircle hasValue={false} />,
              key: 'Ativo',
              isOrderable: true,
              width: '37px',
            },
            {
              content: 'Nome',
              key: 'Nome',
              isOrderable: true,
              width: 'auto',
            },
            {
              content: 'Tipo',
              key: 'Tipo',
              isOrderable: false,
              width: '170px',
            },
            {
              content: 'Ações',
              key: 'Acoes',
              isOrderable: false,
              width: '38px',
            },
          ]}
          defaultKeyOrdered="nome"
          renderTableRows={contasFinanceiras.map((conta) => (
            <Tr key={conta.id}>
              <Td style={{ textAlign: 'center' }}>
                <StatusCircle isActive={conta.ativo} />
              </Td>
              <Td>
                <Link id="link-visualizar" href={getNomeLinkHref(conta.id)}>
                  {conta.nome}
                </Link>
              </Td>
              <Td>{conta.tipo}</Td>
              <Td>
                <ActionsMenu
                  id="mostrarMais"
                  items={
                    conta.padraoSistema ||
                    conta.tipoContaFinanceira ===
                      TipoContaFinanceiraEnum.CAIXA_MOVEL
                      ? [
                          {
                            content: 'Editar',
                            onClick: () => {
                              handleHistoryPush(
                                SubstituirParametroRota(
                                  ConstanteRotas.CONTA_FINANCEIRA_ALTERAR,
                                  'id',
                                  conta.id
                                )
                              );
                            },
                            funcionalidade:
                              ConstanteFuncionalidades.CONTA_FINANCEIRA_ALTERAR,
                          },
                        ]
                      : [
                          {
                            content: 'Editar',
                            onClick: () => {
                              handleHistoryPush(
                                SubstituirParametroRota(
                                  ConstanteRotas.CONTA_FINANCEIRA_ALTERAR,
                                  'id',
                                  conta.id
                                )
                              );
                            },
                            funcionalidade:
                              ConstanteFuncionalidades.CONTA_FINANCEIRA_ALTERAR,
                          },

                          {
                            content: 'Remover',
                            onClick: () => {
                              excluirHandle(conta.id, conta.ativo);
                            },
                            funcionalidade:
                              ConstanteFuncionalidades.CONTA_FINANCEIRA_EXCLUIR,
                          },
                        ]
                  }
                />
              </Td>
            </Tr>
          ))}
        />
      </GridItem>
    </SimpleGridForm>
  );
};

export default Listar;
