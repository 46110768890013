import React from 'react';
import { Modal, ModalOverlay, ModalProps } from '@chakra-ui/react';

import PortalFullscreen from 'components/PDV/Geral/PortalFullscreen';

interface PropsModalPadraoChakra extends ModalProps {
  children: any;
  hasOverlay?: boolean;
  appendToParentPortal?: boolean;
}
const ModalPadraoChakra = ({
  children,
  hasOverlay = true,
  appendToParentPortal = true,
  ...props
}: PropsModalPadraoChakra) => {
  return (
    <PortalFullscreen appendToParentPortal={appendToParentPortal}>
      <Modal autoFocus allowPinchZoom {...props}>
        {hasOverlay && <ModalOverlay />}
        {children}
      </Modal>
    </PortalFullscreen>
  );
};

export default ModalPadraoChakra;
