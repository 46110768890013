import { useEffect } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import Lottie from 'lottie-react';

import Foguete from 'assets/animations/foguete.json';

type ReprocessandoPendenciaProps = {
  finalizarPendenciaVenda: () => Promise<void>;
};

export const ReprocessandoPendencia = ({
  finalizarPendenciaVenda,
}: ReprocessandoPendenciaProps) => {
  useEffect(() => {
    async function reprocessando() {
      setTimeout(async () => {
        await finalizarPendenciaVenda();
      }, 2000);
    }
    reprocessando();
  }, [finalizarPendenciaVenda]);

  return (
    <Flex
      bg="gray.100"
      w="full"
      borderTopRadius="6px"
      h="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Flex alignItems="center" justifyContent="center">
        <Box>
          <Lottie
            animationData={Foguete}
            loop
            style={{
              width: '230px',
              height: '230px',
            }}
          />
        </Box>
        <Box>
          <Text color="gray.700" fontSize="20px">
            Processando correções…
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};
