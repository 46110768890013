import React, { memo } from 'react';
import { Controller } from 'react-hook-form';

import ContainerInputPadrao from 'components/Layout/CampoContainer/CampoContainerPadrao';
import { SwitchContainer, SwitchIcon, HiddenCheckbox } from './styles';

interface CheckBoxDefaultProps {
  id: string;
  name: string;
  label?: string;
  defaultValue?: any;
  control: any;
  onChange?: (item: boolean) => void;
  readonly?: boolean;
}

const CheckBoxDefault = ({
  id,
  name,
  label,
  control,
  readonly,
  defaultValue,
  onChange,
}: CheckBoxDefaultProps) => {
  return (
    <ContainerInputPadrao id={id} label={label} error={undefined}>
      <Controller
        defaultValue={defaultValue || null}
        render={({ field }) => (
          <SwitchContainer
            readonly={readonly}
            id={id}
            value={field.value}
            onClick={() => {
              if (readonly) return;
              field.onChange(!field.value);
              if (onChange) {
                onChange(!field.value);
              }
            }}
          >
            <SwitchIcon />

            <HiddenCheckbox
              id={`hidden-checkbox-${id}`}
              checked={field.value}
            />
          </SwitchContainer>
        )}
        name={`${name}` as const}
        control={control}
      />
    </ContainerInputPadrao>
  );
};

export default memo(CheckBoxDefault);
