import React, { useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import {
  GridItem,
  Tr,
  Td,
  Link,
  Flex,
  Box,
  useMediaQuery,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';

import auth from 'modules/auth';
import api, { ResponseApi } from 'services/api';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import ConstanteFuncionalidades from 'constants/permissoes';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { shallowEqual } from 'helpers/validation/shallowEqual';

import { FilterSelect } from 'components/update/Select/FilterSelect';
import ManterFoco from 'components/Geral/ManterFoco';
import { StatusCircle } from 'components/update/Table/StatusCircle';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { ModalConfirmacaoInativar } from 'components/Modal/ModalConfirmacaoInativar';
import { SearchInput } from 'components/update/Input/SearchInput';
import {
  PagedTable,
  PagedTableForwardRefData,
} from 'components/update/Table/PagedTable';
import {
  GridPaginadaConsulta,
  GridPaginadaRetorno,
} from 'components/Grid/Paginacao';
import { ButtonCadastrarNovo } from 'components/Layout/ButtonCadastrarNovo';

interface UnidadesMedidaFiltros {
  ativo: boolean;
  descricao: string;
}

interface UnidadeMedida {
  id: string;
  descricao: string;
  sigla: string;
  ativo: boolean;
}

const formDefaultValues = {
  ativo: true,
  descricao: '',
};

const Listar = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [unidadesMedida, setUnidadesMedida] = useState(
    [] as Array<UnidadeMedida>
  );
  const [currentFilters, setCurrentFilters] = useState<UnidadesMedidaFiltros>(
    formDefaultValues
  );

  const history = useHistory();

  const formMethods = useForm({
    defaultValues: formDefaultValues,
  });
  const { setFocus } = formMethods;

  const isMountedRef = useIsMountedRef();
  const pageIsLoaded = useRef(false);
  const childRef = useRef<PagedTableForwardRefData>(null);

  const [isLargerThan900] = useMediaQuery('(max-width: 900px)');
  const [isLargerThan1200] = useMediaQuery('(max-width: 1200px)');

  const permissaoUnidadeMedidaAlterar = auth.possuiPermissao(
    ConstanteFuncionalidades.UNIDADE_MEDIDA_ALTERAR
  );
  const permissaoUnidadeMedidaVisualizar = auth.possuiPermissao(
    ConstanteFuncionalidades.UNIDADE_MEDIDA_VISUALIZAR
  );

  const handleAlterarVisualizar = useCallback(
    (id: string) => {
      let href = '';

      if (permissaoUnidadeMedidaAlterar.permitido) {
        href = SubstituirParametroRota(
          ConstanteRotas.UNIDADE_MEDIDA_ALTERAR,
          'id',
          id
        );
      } else if (permissaoUnidadeMedidaVisualizar.permitido) {
        href = SubstituirParametroRota(
          ConstanteRotas.UNIDADE_MEDIDA_VISUALIZAR,
          'id',
          id
        );
      }

      return href;
    },
    [permissaoUnidadeMedidaAlterar, permissaoUnidadeMedidaVisualizar]
  );

  const handleHistoryPush = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history]
  );

  const handleReset = formMethods.handleSubmit((data) => {
    const filtersIsDirty = !shallowEqual(data, currentFilters || {});

    if (filtersIsDirty) {
      setCurrentFilters(data);
    }
  });

  const handleRefresh = useCallback(() => {
    return childRef.current?.reload();
  }, []);

  const paginationHandle = useCallback(
    async (gridPaginadaConsulta: GridPaginadaConsulta) => {
      setIsLoading(true);

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<UnidadeMedida>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.UNIDADE_MEDIDA_LISTAR_PAGINADO,
          gridPaginadaConsulta
        ),
        {
          params: {
            descricao: currentFilters.descricao,
            ativo: currentFilters.ativo,
          },
        }
      );

      if (response?.sucesso && isMountedRef.current) {
        setTotalRegistros(response.dados.total);
        setUnidadesMedida(response.dados.registros);
      }

      if (isMountedRef.current) {
        setIsLoading(false);

        if (!pageIsLoaded.current) {
          pageIsLoaded.current = true;

          setFocus('descricao');
        }
      }
    },
    [currentFilters, isMountedRef, setFocus]
  );

  const excluirHandle = useCallback(
    async (unidadeMedidaId: string, ativo: boolean) => {
      ModalConfirmacaoExcluir({
        callback: async (ok: boolean) => {
          if (ok) {
            setIsLoading(true);

            const response = await api.delete<void, ResponseApi>(
              ConstanteEnderecoWebservice.UNIDADE_MEDIDA_EXCLUIR,
              {
                params: { id: unidadeMedidaId },
              }
            );

            if (response?.sucesso) {
              toast.success('O cadastro foi removido com sucesso.');

              handleRefresh();
            }

            if (response) {
              ModalConfirmacaoInativar({
                response,
                rotaWebService:
                  ConstanteEnderecoWebservice.UNIDADE_MEDIDA_INATIVAR,
                id: unidadeMedidaId,
                ativo,
                callback: (okInativar: boolean) => {
                  if (okInativar) handleRefresh();
                },
              });
            }

            setIsLoading(false);
          }
        },
      });
    },
    [handleRefresh]
  );

  return (
    <Box>
      <FormProvider {...formMethods}>
        <ManterFoco>
          <Flex flexDirection={!isLargerThan1200 ? 'row' : 'column'} w="full">
            <Flex
              direction={{ base: 'column', sm: 'column', md: 'row', xl: 'row' }}
              w={isLargerThan900 ? 'full' : '100%'}
            >
              <Box w={isLargerThan900 ? 'full' : '70%'} mr="40px">
                <SearchInput
                  type="search"
                  onEnterKeyPress={() => handleReset()}
                  placeholder="Buscar unidade de medida por descrição"
                  isDisabled={isLoading}
                  id="descricao"
                  name="descricao"
                />
              </Box>
              <Box
                mb={['10px', '10px']}
                mt={isLargerThan900 ? '10px' : undefined}
                w={isLargerThan900 ? 'full' : '200px'}
                mr={!isLargerThan1200 ? '40px' : undefined}
              >
                <FilterSelect
                  id="ativo"
                  name="ativo"
                  isDisabled={isLoading}
                  options={[
                    {
                      value: true,
                      label: 'Ativos',
                    },
                    {
                      value: false,
                      label: 'Inativos',
                    },
                    {
                      value: null,
                      label: 'Todos',
                    },
                  ]}
                  onSelect={() => {
                    handleReset();
                  }}
                />
              </Box>
            </Flex>
            <Flex
              width={!isLargerThan1200 ? 'calc(100% - 70%)' : '100%'}
              flexDirection={!isLargerThan1200 ? 'row' : 'column'}
              justifyContent="flex-end"
            >
              <ButtonCadastrarNovo
                onClick={() =>
                  handleHistoryPush(ConstanteRotas.UNIDADE_MEDIDA_CADASTRAR)
                }
                funcionalidade={
                  ConstanteFuncionalidades.UNIDADE_MEDIDA_CADASTRAR
                }
              />
            </Flex>
          </Flex>
        </ManterFoco>
        <GridItem colSpan={12} mt="16px">
          <PagedTable
            ref={childRef}
            itemsTotalCount={totalRegistros}
            loadColumnsData={paginationHandle}
            isLoading={isLoading}
            defaultKeyOrdered="Descricao"
            tableHeaders={[
              {
                content: <StatusCircle hasValue={false} />,
                key: 'Ativo',
                isOrderable: true,
                width: '37px',
              },
              {
                content: 'Descrição',
                key: 'Descricao',
                isOrderable: true,
                width: 'auto',
              },
              {
                content: 'Sigla',
                key: 'Sigla',
                isOrderable: true,
                width: '100px',
              },
              {
                content: 'Ações',
                key: 'Acoes',
                isOrderable: false,
                width: '38px',
              },
            ]}
            renderTableRows={unidadesMedida.map((unidadeMedida) => (
              <Tr key={unidadeMedida.id}>
                <Td textAlign="center">
                  <StatusCircle isActive={unidadeMedida.ativo} />
                </Td>

                <Td>
                  <Link href={handleAlterarVisualizar(unidadeMedida.id)}>
                    {unidadeMedida.descricao}
                  </Link>
                </Td>
                <Td>{unidadeMedida.sigla}</Td>
                <Td>
                  <ActionsMenu
                    id="mostrarMais"
                    items={[
                      {
                        content: 'Editar',
                        onClick: () => {
                          handleHistoryPush(
                            SubstituirParametroRota(
                              ConstanteRotas.UNIDADE_MEDIDA_ALTERAR,
                              'id',
                              unidadeMedida.id
                            )
                          );
                        },
                        funcionalidade:
                          ConstanteFuncionalidades.UNIDADE_MEDIDA_ALTERAR,
                      },
                      {
                        content: 'Remover',
                        onClick: () => {
                          excluirHandle(unidadeMedida.id, unidadeMedida.ativo);
                        },
                        funcionalidade:
                          ConstanteFuncionalidades.UNIDADE_MEDIDA_EXCLUIR,
                      },
                    ]}
                  />
                </Td>
              </Tr>
            ))}
          />
        </GridItem>
      </FormProvider>
    </Box>
  );
};

export default Listar;
