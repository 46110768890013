import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import { isValidCnpj } from 'helpers/validation/IsValidCpfCnpj';
import PlanoContratacaoEnum from 'constants/enum/planoContratacao';
import auth from 'modules/auth';

const isPlanoStart = auth.getPlano() === PlanoContratacaoEnum.START;

const schema = yup.object().shape({
  nome: yup
    .string()
    .when('regraMeioPagamento', (regraMeioPagamento: string) => {
      if (
        regraMeioPagamento === '24' ||
        regraMeioPagamento === '25' ||
        regraMeioPagamento === '26'
      ) {
        return yup.string().nullable().notRequired();
      }
      return yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .max(40, ConstanteMensagemValidacao.MAX_LENGTH_40);
    }),
  regraMeioPagamento: yup
    .string()
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  cnpjCredenciadora: yup
    .string()
    .nullable()
    .test(
      'cnpjCredenciadora',
      ConstanteMensagemValidacao.CNPJ_INVALIDO,
      (value) => {
        if (value) return isValidCnpj(value);

        return true;
      }
    )
    .max(18, ConstanteMensagemValidacao.MAX_LENGTH_18),
  documentoFiscalPreferencial: yup.boolean(),
  credenciadoraCartaoId: yup.string().nullable(),
  contaFinanceira: yup
    .string()
    .nullable()
    .when('regraMeioPagamento', (regraMeioPagamento: string) => {
      if (isPlanoStart) {
        return yup.string().nullable().notRequired();
      }
      if (
        regraMeioPagamento === '' ||
        regraMeioPagamento === '1' ||
        regraMeioPagamento === '2' ||
        regraMeioPagamento === '6' ||
        regraMeioPagamento === '8' ||
        regraMeioPagamento === '18' ||
        regraMeioPagamento === '28'
      ) {
        return yup.string().nullable().notRequired();
      }
      return yup
        .string()
        .nullable()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO);
    }),
});

export const yupResolver = yupResolverInstance(schema);

export const useForm = useFormInstance;
