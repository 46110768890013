import React, { useCallback, useEffect } from 'react';
import {
  useMediaQuery,
  Box,
  Stack,
  Icon,
  Text,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Flex,
  useDisclosure,
  Avatar,
  MenuGroup,
  MenuDivider,
  Accordion,
  HStack,
  ModalContent,
  ModalHeader,
  ModalBody,
  useToken,
} from '@chakra-ui/react';
import { FiChevronLeft, FiMenu } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { GlobalHotKeys } from 'react-hotkeys';

import auth from 'modules/auth';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { useFullScreenContext } from 'store/FullScreen';
import ConstanteRotasPDV from 'constants/rotasPDV';
import ConstanteRotas from 'constants/rotas';
import { useSignalRContext } from 'store/SignalR';
import handleSairDevolucao from 'store/PDV/SairDevolucao';
import { useInformacoesGeraisContext } from 'store/PDV/InformacoesGerais';

import {
  ExpandirFullscreenIcon,
  ExpandirFullscreenFomerIcon,
  RecolherFullscreenFomerIcon,
  RecolherFullscreenIcon,
  NotificacoesIcon,
  LogoutIcon,
  AlternarLojaIcon,
  AlternarLojaFomerIcon,
  SetaVoltarIcon,
  ConfiguracoesUsuariosIcon,
  AtalhosIcon,
  NotificacoesFomerIcon,
} from 'icons';
import { PDVHeaderLogo } from 'icons/Identidade';
import ModalAlternarLoja from 'components/PDV/Modal/ModalAlternarLoja';
import ModalAtalhos from 'components/PDV/Modal/ModalAtalhos';
import { Ajuda } from 'components/Layout/Header/Ajuda';

import { useMenuContext } from 'store/PDV/Menu';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { isIOS } from 'react-device-detect';

import WrapperButton from './WrapperButton';

const Header: React.FC = () => {
  const { menuItems } = useMenuContext();
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const headerIconColor = useToken('colors', 'pdv.headerIconColor');
  const isPdv = window?.location?.pathname?.includes('pdv');
  const iconRecolher = isPdv
    ? RecolherFullscreenFomerIcon
    : RecolherFullscreenIcon;
  const iconExpandir = isPdv
    ? ExpandirFullscreenFomerIcon
    : ExpandirFullscreenIcon;

  const { handleFullScreen } = useFullScreenContext();

  const history = useHistory();
  const { detalhesTroca } = useInformacoesGeraisContext();

  const { hubConnection } = useSignalRContext();

  const { fantasia: lojaFantasia = '' } = auth.getLoja();
  const { nome: nomeUsuario, foto: fotoUsuario } = auth.getUsuario();
  const qtdLojas = auth.getQtdLojas();

  const {
    isOpen: isMenuUsuarioWrapperOpen,
    onOpen: onMenuUsuarioWrapperOpen,
    onClose: onMenuUsuarioWrapperClose,
  } = useDisclosure();

  const {
    isOpen: isModalAlternarLojaOpen,
    onOpen: onModalAlternarLojaOpen,
    onClose: onModalAlternarLojaClose,
  } = useDisclosure();

  const {
    isOpen: isModalAtalhosOpen,
    onOpen: onModalAtalhosOpen,
    onClose: onModalAtalhosClose,
  } = useDisclosure();

  const {
    isOpen: isMenuWrapperOpen,
    onOpen: onMenuWrapperOpen,
    onClose: onMenuWrapperClose,
  } = useDisclosure();

  async function logOff() {
    await api.post<void, ResponseApi>(
      ConstanteEnderecoWebservice.AUTENTICACAO_LOGOFF
    );

    auth.clearTokenAndRedirect();
  }

  function handleVoltarParaDashboard() {
    history.push(ConstanteRotas.DASHBOARD, null);
  }

  const handleChecarRota = useCallback(
    (handleOperacao: () => void) => {
      const { pathname } = window.location;
      if (pathname.includes('finalizar')) {
        handleOperacao();
      } else {
        handleSairDevolucao({
          handleOperacao,
          detalhesTroca,
        });
      }
    },
    [detalhesTroca]
  );

  function toggleFullScreen() {
    if (!handleFullScreen.active) {
      handleFullScreen.enter();
    } else {
      handleFullScreen.exit();
    }
  }

  const keyMap = {
    F1: ['f1'],
    F11: ['f11'],
  };

  const handlersHotKeys = {
    F1: (event: any) => {
      event.preventDefault();
      onModalAtalhosOpen();
    },
    F11: (event: any) => {
      event.preventDefault();
      toggleFullScreen();
    },
  };

  useEffect(() => {
    hubConnection.on('trocar-loja', () => {
      const { pathname } = window.location;

      if (pathname.indexOf('pdv') !== -1) {
        history.push(ConstanteRotasPDV.PDV_HOME, null);
      } else {
        history.push(ConstanteRotas.DASHBOARD, null);
      }
      history.go(0);
    });
  }, [history, hubConnection]);

  return (
    <>
      <GlobalHotKeys handlers={handlersHotKeys} keyMap={keyMap} />
      <Box bg="pdv.header" w="100%" h={{ base: '50px', md: '40px' }} px={6}>
        <Stack
          direction="row"
          align="center"
          justify="space-between"
          spacing={2}
          h="100%"
        >
          <HStack spacing="5">
            {!isLargerThan900 && (menuItems || []).length > 0 && (
              <>
                <IconButton
                  aria-label="Abrir menu"
                  icon={
                    <Icon
                      as={FiMenu}
                      boxSize="5"
                      stroke={isPdv ? headerIconColor : 'black'}
                    />
                  }
                  onClick={onMenuWrapperOpen}
                  variant="ghost"
                  size="xs"
                  _active={{ color: 'gray.600' }}
                  _hover={{ color: 'gray.600' }}
                />

                <ModalPadraoChakra
                  motionPreset="none"
                  size="full"
                  onClose={onMenuWrapperClose}
                  isOpen={isMenuWrapperOpen}
                >
                  <ModalContent
                    margin="0"
                    borderRadius="0"
                    overflowX="hidden"
                    overflowY="auto"
                    h="full"
                    bg="pdv.nav_header"
                  >
                    <ModalHeader p="0">
                      <Flex h="56px" alignItems="center" pl="6" pr="6">
                        <IconButton
                          aria-label="Voltar"
                          icon={<Icon as={FiChevronLeft} boxSize="6" />}
                          variant="link"
                          color="white"
                          onClick={onMenuWrapperClose}
                          minW="8"
                        />
                      </Flex>
                    </ModalHeader>
                    <ModalBody
                      py="0"
                      px="5"
                      sx={{
                        '& > *': { borderColor: 'pdv.modalUsuarioDivider' },
                      }}
                    >
                      <Accordion allowToggle>
                        <Stack
                          direction={{ base: 'column', md: 'row' }}
                          align={{ base: 'start', md: 'center' }}
                          spacing={{ base: 0, md: 12, lg: 20 }}
                        >
                          {(menuItems || []).map((item) => item)}
                        </Stack>
                      </Accordion>
                    </ModalBody>
                  </ModalContent>
                </ModalPadraoChakra>
              </>
            )}

            <PDVHeaderLogo />
          </HStack>

          <Stack direction="row" align="center" spacing={2}>
            {isLargerThan900 && (
              <>
                {lojaFantasia && (
                  <Text fontSize="xs" color={headerIconColor}>
                    {lojaFantasia}
                  </Text>
                )}
                {!navigator.userAgent.match(/iPhone/i) && (
                  <IconButton
                    aria-label="Toggle fullscreen"
                    icon={
                      handleFullScreen.active ? (
                        <RecolherFullscreenIcon
                          strokeWidth="1.2px"
                          stroke={isPdv ? headerIconColor : 'black'}
                        />
                      ) : (
                        <ExpandirFullscreenIcon
                          strokeWidth="1.2px"
                          stroke={isPdv ? headerIconColor : 'black'}
                        />
                      )
                    }
                    variant="link"
                    fontSize="md"
                    borderRadius="md"
                    px={1}
                    py={1}
                    minW={7}
                    onClick={toggleFullScreen}
                  />
                )}

                <IconButton
                  aria-label="Atalhos"
                  icon={
                    <AtalhosIcon strokeWidth="1.2px" stroke={headerIconColor} />
                  }
                  variant="link"
                  borderRadius="md"
                  fontSize="xl"
                  px={1}
                  py={1}
                  minW={7}
                  onClick={onModalAtalhosOpen}
                />
                <Ajuda />
                <IconButton
                  aria-label="Notifications"
                  icon={
                    isPdv ? (
                      <NotificacoesFomerIcon
                        strokeWidth="1.2px"
                        stroke={headerIconColor}
                      />
                    ) : (
                      <NotificacoesIcon />
                    )
                  }
                  variant="link"
                  fontSize="xl"
                  borderRadius="md"
                  px={1}
                  py={1}
                  minW={7}
                  isDisabled
                />
                <Menu colorScheme="systemHeader">
                  <MenuButton
                    as={Avatar}
                    src={fotoUsuario}
                    w="24px"
                    h="24px"
                    cursor="pointer"
                    border="1px"
                    borderColor="primary.50"
                  />
                  <MenuList>
                    <MenuGroup
                      title={nomeUsuario}
                      as={() => (
                        <Flex
                          alignItems="center"
                          paddingLeft={9}
                          paddingBottom={1}
                          paddingTop={1}
                          paddingRight={3}
                          gap="4px"
                        >
                          <Icon
                            as={ConfiguracoesUsuariosIcon}
                            fontSize="xl"
                            strokeWidth="1.2px"
                            marginEnd="0.75rem"
                          />
                          <Text maxW="150px" isTruncated>
                            {nomeUsuario}
                          </Text>
                        </Flex>
                      )}
                    >
                      <MenuDivider borderColor="pdv.modalUsuarioDivider" />
                      <MenuItem
                        icon={
                          <Icon
                            as={SetaVoltarIcon}
                            fontSize="xl"
                            strokeWidth="1.2px"
                          />
                        }
                        onClick={() =>
                          handleChecarRota(handleVoltarParaDashboard)
                        }
                        gap="4px"
                      >
                        Voltar para o {auth.getSistema().nomeExibicao}
                      </MenuItem>
                      {qtdLojas > 1 && (
                        <MenuItem
                          icon={
                            <Icon
                              as={
                                isPdv ? AlternarLojaFomerIcon : AlternarLojaIcon
                              }
                              fontSize="xl"
                              strokeWidth="1.2px"
                            />
                          }
                          onClick={() =>
                            handleChecarRota(onModalAlternarLojaOpen)
                          }
                          gap="4px"
                        >
                          Alternar loja
                        </MenuItem>
                      )}
                      <MenuItem
                        icon={
                          <Icon
                            as={LogoutIcon}
                            fontSize="lg"
                            strokeWidth="1.2px"
                          />
                        }
                        onClick={() => handleChecarRota(logOff)}
                        gap="4px"
                      >
                        Sair
                      </MenuItem>
                    </MenuGroup>
                  </MenuList>
                </Menu>
              </>
            )}
            {!isLargerThan900 && (
              <>
                <Avatar
                  src={fotoUsuario}
                  size="xs"
                  cursor="pointer"
                  onClick={onMenuUsuarioWrapperOpen}
                  border="1px"
                  borderColor="primary.50"
                />

                <ModalPadraoChakra
                  motionPreset="none"
                  size="full"
                  onClose={onMenuUsuarioWrapperClose}
                  isOpen={isMenuUsuarioWrapperOpen}
                >
                  <ModalContent
                    margin="0"
                    borderRadius="0"
                    overflowX="hidden"
                    overflowY="auto"
                    h="full"
                    bg="pdv.header"
                  >
                    <ModalHeader p="0">
                      <Flex
                        h="56px"
                        alignItems="center"
                        justifyContent="space-between"
                        pl="6"
                        pr="6"
                      >
                        <IconButton
                          aria-label="Voltar"
                          icon={<Icon as={FiChevronLeft} boxSize="6" />}
                          variant="link"
                          color="pdv.headerTextColor"
                          onClick={onMenuUsuarioWrapperClose}
                          minW="8"
                        />

                        <Text color="pdv.headerTextColor" fontSize="sm" mr="2">
                          {lojaFantasia}
                        </Text>
                      </Flex>
                    </ModalHeader>
                    <ModalBody
                      py="0"
                      px="5"
                      sx={{
                        '& > *': { borderColor: 'pdv.modalUsuarioDivider' },
                      }}
                    >
                      {!isIOS && (
                        <WrapperButton
                          icon={
                            handleFullScreen.active
                              ? iconRecolher
                              : iconExpandir
                          }
                          title={
                            handleFullScreen.active
                              ? 'Sair do modo tela cheia'
                              : 'Entrar no modo tela cheia'
                          }
                          onClick={toggleFullScreen}
                        />
                      )}
                      <WrapperButton
                        icon={AtalhosIcon}
                        title="Atalhos"
                        onClick={onModalAtalhosOpen}
                        hasArrow
                      />
                      <Ajuda asWrapperButton asPDVView />
                      <WrapperButton
                        icon={isPdv ? NotificacoesFomerIcon : NotificacoesIcon}
                        title="Notificações"
                        isDisabled
                        hasArrow
                      />
                      <WrapperButton
                        icon={SetaVoltarIcon}
                        title={`Voltar para ${auth.getSistema().nomeExibicao}`}
                        onClick={handleVoltarParaDashboard}
                      />
                      <WrapperButton
                        icon={isPdv ? AlternarLojaFomerIcon : AlternarLojaIcon}
                        title="Alternar loja"
                        onClick={onModalAlternarLojaOpen}
                        hasArrow
                      />
                      <WrapperButton
                        icon={LogoutIcon}
                        title="Sair"
                        onClick={() => logOff()}
                      />
                    </ModalBody>
                  </ModalContent>
                </ModalPadraoChakra>
              </>
            )}
            <ModalAlternarLoja
              isOpen={isModalAlternarLojaOpen}
              onClose={onModalAlternarLojaClose}
              onSubmit={() => {}}
              subTittle="Ao trocar a loja você será redirecionado para a tela inicial do PDV"
              asMobileView={!isLargerThan900}
            />
            <ModalAtalhos
              isOpen={isModalAtalhosOpen}
              onClose={onModalAtalhosClose}
              asMobileView={!isLargerThan900}
            />
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default Header;
