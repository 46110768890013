import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Box,
  GridItem,
  Text,
  Flex,
  Icon,
  Divider,
  Accordion,
  AccordionPanel,
  AccordionIcon,
  AccordionButton,
  Tooltip,
  AccordionItem,
} from '@chakra-ui/react';

import { InfoIcon } from 'icons';
import TabsLink from 'components/Tabs/TabsLink';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { Switch } from 'components/update/Switch';

import PerfilUsuarioPersonalizadoOpcaoConstante from 'constants/perfilUsuarioPersonalizadoOpcao';

import { ModalConfirmacaoAlteracaoPerfil } from './ModalConfirmacaoAlteracaoPerfil';

interface PermissaoPropsProps {
  categorias: Array<CategoriaPermissaoInterface>;
  readonly?: boolean;
  handleAlterado?: boolean;
}

export interface PermissaoCadastroInterface {
  index: string;
  id: string;
  nome: string;
  descricao: string;
  selecionado: boolean;
}

export interface PermissaoGrupoInterface {
  id: string;
  descricao: string;
  permissoes: Array<PermissaoCadastroInterface>;
}

export interface CategoriaPermissaoInterface {
  id: string;
  descricao: string;
  grupos: Array<PermissaoGrupoInterface>;
  servico: number | null;
}

const Permissoes = ({
  categorias,
  readonly,
  handleAlterado,
}: PermissaoPropsProps) => {
  const { setValue, getValues } = useFormContext();

  const handleChangeGrupo = (grupo: PermissaoGrupoInterface) => {
    const grupoValue = getValues(`grupo${grupo.id}`);

    grupo.permissoes.forEach((permissao) => {
      setValue(`permissoes.${permissao.index}`, grupoValue || false);
    });
  };

  const handleChangePermissao = (grupo: PermissaoGrupoInterface) => {
    let todosSelecionados = true;

    grupo.permissoes.forEach((permissao) => {
      if (!getValues(`permissoes.${permissao.index}`)) {
        todosSelecionados = false;
      }
    });

    setValue(`grupo${grupo.id}`, todosSelecionados);
  };

  useEffect(() => {
    categorias.forEach((categoria) => {
      categoria.grupos.forEach((grupo) => {
        const todosSelecionados = grupo.permissoes.some(
          (item) => item.selecionado === false
        );

        setValue(`grupo${grupo.id}`, !todosSelecionados);
        grupo.permissoes.forEach((permissao) => {
          setValue(`permissoes.${permissao.index}`, permissao.selecionado);
        });
      });
    });
  }, [categorias, setValue]);

  return (
    <Accordion defaultIndex={[0]} allowMultiple>
      {categorias?.map((categoria) => {
        return (
          <AccordionItem mb="30px">
            <Text>
              <AccordionButton h="40px">
                <Box flex="1" textAlign="left">
                  <Text fontSize="14px">{categoria.descricao}</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Text>
            <AccordionPanel pb={4}>
              <SimpleGridForm>
                <GridItem colSpan={12}>
                  <Box>
                    <TabsLink
                      id="perfil-usuario-tabs"
                      isLoading={false}
                      tabs={categoria.grupos.map((grupo) => {
                        return {
                          eventKey: grupo.descricao,
                          title: grupo.descricao,
                          content: (
                            <Box margin="0px 0px" padding="0">
                              <Box>
                                <Box
                                  overflow="unset"
                                  border="1px"
                                  borderColor="gray.100"
                                  borderRadius="5px"
                                  boxShadow="0px 0px 6px 0px rgba(0, 0, 0, 0.2)"
                                >
                                  <Box bg="gray.50" minWidth="100%" h="42px">
                                    <Flex
                                      pl="20px"
                                      alignItems="center"
                                      justifyContent="flex-start"
                                    >
                                      <Box mt="12px">
                                        <Switch
                                          size="md"
                                          id={`grupo${grupo.id}`}
                                          name={`grupo${grupo.id}`}
                                          label=""
                                          isDisabled={readonly}
                                          onChange={() => {
                                            if (handleAlterado) {
                                              const perfilusuario = getValues(
                                                'perfilusuario'
                                              );

                                              if (!perfilusuario) {
                                                setValue(
                                                  'perfilusuario',
                                                  PerfilUsuarioPersonalizadoOpcaoConstante.value
                                                );
                                                handleChangeGrupo(grupo);
                                              } else if (
                                                perfilusuario.value !==
                                                PerfilUsuarioPersonalizadoOpcaoConstante.value
                                              ) {
                                                ModalConfirmacaoAlteracaoPerfil(
                                                  {
                                                    callback: (ok: boolean) => {
                                                      if (ok) {
                                                        setValue(
                                                          'perfilusuario',
                                                          PerfilUsuarioPersonalizadoOpcaoConstante.value
                                                        );
                                                        handleChangeGrupo(
                                                          grupo
                                                        );
                                                      } else {
                                                        setValue(
                                                          `grupo${grupo.id}`,
                                                          !getValues(
                                                            `grupo${grupo.id}`
                                                          )
                                                        );
                                                      }
                                                    },
                                                  }
                                                );
                                              } else {
                                                handleChangeGrupo(grupo);
                                              }
                                            } else {
                                              handleChangeGrupo(grupo);
                                            }
                                          }}
                                        />
                                      </Box>
                                      <Box>
                                        <Text
                                          as="span"
                                          pl="10px"
                                          color="gray.800"
                                          fontWeight="bold"
                                        >
                                          {grupo.descricao}
                                        </Text>
                                      </Box>
                                    </Flex>
                                  </Box>
                                  <Box>
                                    <Box>
                                      <SimpleGridForm>
                                        <GridItem colSpan={12}>
                                          <Box
                                            pt="30px"
                                            pb="30px"
                                            pl="38px"
                                            pr="38px"
                                          >
                                            {grupo.permissoes.map(
                                              (permissao) => (
                                                <>
                                                  <Flex
                                                    key={permissao.id}
                                                    height="35px"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                  >
                                                    <Flex>
                                                      {permissao.nome}{' '}
                                                      {permissao.descricao && (
                                                        <Tooltip
                                                          placement="right"
                                                          hasArrow
                                                          label={
                                                            permissao.descricao
                                                          }
                                                        >
                                                          <Flex alignItems="center">
                                                            <Icon
                                                              as={InfoIcon}
                                                              ml="2px"
                                                            />
                                                          </Flex>
                                                        </Tooltip>
                                                      )}
                                                    </Flex>
                                                    <Box mt="12px">
                                                      <Switch
                                                        id={`permissoes.${permissao.index}`}
                                                        name={`permissoes.${permissao.index}`}
                                                        label=""
                                                        size="md"
                                                        onChange={() => {
                                                          if (handleAlterado) {
                                                            const perfilusuario = getValues(
                                                              'perfilusuario'
                                                            );

                                                            if (
                                                              !perfilusuario
                                                            ) {
                                                              setValue(
                                                                'perfilusuario',
                                                                PerfilUsuarioPersonalizadoOpcaoConstante.value
                                                              );
                                                            } else if (
                                                              perfilusuario.value !==
                                                              PerfilUsuarioPersonalizadoOpcaoConstante.value
                                                            ) {
                                                              ModalConfirmacaoAlteracaoPerfil(
                                                                {
                                                                  callback: (
                                                                    ok: boolean
                                                                  ) => {
                                                                    if (ok) {
                                                                      setValue(
                                                                        'perfilusuario',
                                                                        PerfilUsuarioPersonalizadoOpcaoConstante.value
                                                                      );
                                                                      handleChangePermissao(
                                                                        grupo
                                                                      );
                                                                    } else {
                                                                      // eslint-disable-line

                                                                      setValue(
                                                                        `permissoes.${permissao.index}`,
                                                                        !getValues(
                                                                          `permissoes.${permissao.index}`
                                                                        )
                                                                      );
                                                                    }
                                                                  },
                                                                }
                                                              );
                                                            } else {
                                                              handleChangePermissao(
                                                                grupo
                                                              );
                                                            }
                                                          } else {
                                                            handleChangePermissao(
                                                              grupo
                                                            );
                                                          }
                                                        }}
                                                        isDisabled={readonly}
                                                      />
                                                    </Box>
                                                  </Flex>
                                                  <Divider />
                                                </>
                                              )
                                            )}
                                          </Box>
                                        </GridItem>
                                      </SimpleGridForm>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          ),
                        };
                      })}
                    />
                  </Box>
                </GridItem>
              </SimpleGridForm>
            </AccordionPanel>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};

export default Permissoes;
