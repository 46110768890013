import { useCallback } from 'react';
import { Flex, useToken, FlexProps } from '@chakra-ui/react';

import NivelCategoriaProdutoEnum from 'constants/enum/nivelCategoriaProduto';

type NivelCategoriaProps = {
  PRIMEIRO: number;
  SEGUNDO: number;
  TERCEIRO: number;
  QUARTO: number;
  properties: {
    value: number;
    backgroundColor: string;
    foregroundColor: string;
    color: string;
  }[];
};

interface CardProps extends FlexProps {
  height?: number;
  nivel: number;
  isDragging?: boolean;
  inativo?: boolean;
  isTyping?: boolean;
  children: React.ReactNode;
  isSmartPos?: boolean;
  nivelCategoriaProduto?: NivelCategoriaProps;
}

export const CardCategoriaProduto = ({
  height,
  nivel,
  isDragging,
  inativo,
  isTyping,
  children,
  isSmartPos,
  h = '56px',
  bg,
  nivelCategoriaProduto = NivelCategoriaProdutoEnum,
  ...rest
}: CardProps) => {
  const blue500 = useToken('colors', 'blue.500');

  const backgroundColor = useCallback(() => {
    if (inativo) {
      return isDragging ? 'transparent' : '';
    }
    return isDragging
      ? 'transparent'
      : nivel === 1
      ? 'white'
      : nivelCategoriaProduto.properties[nivel - 1].backgroundColor;
  }, [inativo, isDragging, nivel, nivelCategoriaProduto])();

  const foregroundColor = useCallback(() => {
    if (inativo) {
      return isDragging
        ? nivelCategoriaProduto.properties[nivel - 1].foregroundColor
        : 'white';
    }
    return nivelCategoriaProduto.properties[nivel - 1].foregroundColor;
  }, [inativo, isDragging, nivel, nivelCategoriaProduto])();

  const border = useCallback(() => {
    if (isTyping) {
      return `2px solid ${blue500}`;
    }

    return isDragging ? `2px dashed ${foregroundColor}` : 'none';
  }, [blue500, foregroundColor, isDragging, isTyping])();

  return (
    <>
      {isSmartPos ? (
        <Flex
          mb="4px"
          alignItems="center"
          justifyContent="space-between"
          borderRadius="5px"
          pl={['16px', '16px', '32px']}
          pr={['16px', '16px', '32px']}
          bg={bg}
          h={h}
          width="100%"
          {...rest}
        >
          {children}
        </Flex>
      ) : (
        <Flex
          alignItems="center"
          justifyContent="space-between"
          cursor="pointer"
          boxShadow="0px 1px 1px #00000029"
          height={height ? `${height}px` : '30px'}
          width="100%"
          borderRadius="5px"
          padding="5px 15px"
          fontSize="0.8125rem"
          color={foregroundColor}
          backgroundColor={backgroundColor}
          border={border}
          marginLeft="10px"
          mt="2px"
          {...rest}
        >
          {children}
        </Flex>
      )}
    </>
  );
};
