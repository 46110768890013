export const enumSituacaoBaixa = {
  TODAS: null,
  CONCILIADAS: true,
  NAO_CONCILIADAS: false,

  properties: [
    {
      label: 'Conciliadas',
      value: true,
    },
    {
      label: 'Não conciliadas',
      value: false,
    },
    {
      label: 'Todas',
      value: null,
    },
  ],
};
