import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';
import ConstanteRotas, { ConstanteRotasAlternativas } from 'constants/rotas';
import { obterEtapaSmartPos } from 'services/smartPos';
import { IdentificacaoEtapasSmartPos } from 'constants/enum/IdentificacaoEtapasSmartPos';

import { ModalConfirmarAcao } from 'components/update/Modal/ModalConfirmarAcao';
import { ModalRemoverDadosIntegracao } from 'components/update/Modal/ModalRemoverDadosIntegracao';

export type DadosSmartPosProps = {
  id: string;
  dataAtivacao: Date;
  ativo: boolean;
  sincronizacaoHabilitada: boolean;
};

export const useSmartPosData = () => {
  const [smartPosDateActivated, setSmartPosDateActivated] = useState<Date>(
    new Date()
  );
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const listItemsInfoActive = [
    'Para acessar o painel de configurações da integração, clique em: Vendas > SmartPos',
    'Para reset da configuração ou para excluir esta integração, clique em “cancelar integração” no canto superior direito da página.',
    'Para outras dúvidas ou problemas, acesse nossa central de ajuda ou entre em contato com nossa equipe técnica.',
  ];

  const listItemsInfoNotActive = [
    'Para ativar a integração, clique em “Ativar integração” no canto superior direito da página.',
    'Para outras dúvidas ou problemas, acesse nossa central de ajuda ou entre em contato com nossa equipe técnica.',
  ];

  const cancelIntregation = async () => {
    ModalRemoverDadosIntegracao({
      callback: async () => {
        setIsLoading(true);
        const response = await api.delete<void, ResponseApi>(
          ConstanteEnderecoWebservice.INTEGRACAO_SMART_POS_DELETAR
        );
        if (response) {
          if (response.avisos) {
            response.avisos.forEach((aviso: string) => toast.warning(aviso));
          }
          if (response.sucesso) {
            history.push(ConstanteRotas.LOJA_APLICATIVOS);
          }
          setIsLoading(false);
        }
        setIsLoading(false);
      },
    });
  };

  const confirmCancellation = () => {
    ModalConfirmarAcao({
      mainText: 'Excluir ou Reiniciar',
      submitButtonText: 'Continuar',
      secondaryText:
        'Todos os dados armazenados e todo o processo de configuração serão excluídos. Essa ação não poderá ser desfeita. Deseja continuar?',
      variant: 'danger',
      callbackSubmit: cancelIntregation,
    });
  };

  const getDadosSmartPos = useCallback(async () => {
    const response = await api.get<void, ResponseApi<DadosSmartPosProps>>(
      ConstanteEnderecoWebservice.OBTER_INTEGRACAO_SMART_POS
    );

    if (response) {
      if (response.sucesso && response.dados) {
        return response.dados;
      }
    }
    return null;
  }, []);

  const getSmartPos = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get<void, ResponseApi<DadosSmartPosProps>>(
      ConstanteEnderecoWebservice.OBTER_INTEGRACAO_SMART_POS
    );
    if (response) {
      if (response.sucesso && response.dados) {
        setIsActive(response.dados.ativo);
        if (response.dados.dataAtivacao && response.dados.ativo) {
          setSmartPosDateActivated(response.dados.dataAtivacao);
        } else {
          const configEtapas = await obterEtapaSmartPos();

          if (configEtapas !== IdentificacaoEtapasSmartPos.CODIGO_ATIVACAO) {
            setIsLoading(false);
            history.push(ConstanteRotasAlternativas.SMART_POS_ETAPA);
          }
        }
      } else if (!response.sucesso) {
        history.push(ConstanteRotasAlternativas.SMART_POS_ETAPA);
      }
    }
    setIsLoading(false);
  }, [history]);

  const handleActive = useCallback(async () => {
    setIsLoading(true);
    const dadosIntegracao = await getDadosSmartPos();

    if (dadosIntegracao) {
      const response = await api.post<void, ResponseApi>(
        ConstanteEnderecoWebservice.INTEGRACAO_ATIVAR,
        { integracaoId: dadosIntegracao?.id }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso: string) => toast.warning(aviso));
        }
        if (response.sucesso) {
          setIsActive(true);
          setSmartPosDateActivated(new Date());
        }
      }
      setIsLoading(false);
      return;
    }
    toast.error('Não foi possível ativar a integração.');

    setIsLoading(false);
  }, [getDadosSmartPos]);

  useEffect(() => {
    getSmartPos();
  }, [getSmartPos]);

  return {
    smartPosDateActivated,
    listItemsInfoActive,
    listItemsInfoNotActive,
    handleCancel: confirmCancellation,
    handleActive,
    isActive,
    isLoading,
  };
};
