import { useCallback, useState, useEffect } from 'react';
import api, { ResponseApi } from 'services/api';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import OptionType from 'types/optionType';

import { FormData } from './validationForm';

type VendedorProps = {
  ativo: boolean;
  id: string;
  nome: string;
  nomeEcommerce: string | null;
};

const useIntermediadorForm = () => {
  const history = useHistory();

  const [vendedoresLista, setVendedoresLista] = useState<OptionType[]>([]);

  const listarVendedoresSelect = useCallback(async () => {
    const response = await api.get<void, ResponseApi<VendedorProps[]>>(
      ConstanteEnderecoWebservice.VENDEDOR_LISTAR_SELECT_POR_LOJA
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response?.sucesso && response?.dados) {
        setVendedoresLista(
          response.dados.map(({ nome }) => ({
            label: nome,
            value: nome,
          }))
        );

        return;
      }
    }

    setVendedoresLista([]);
  }, []);

  const obterIntermediador = useCallback(async (intermediadorId: string) => {
    const response = await api.get<void, ResponseApi<FormData>>(
      ConstanteEnderecoWebservice.FRENTE_CAIXA_INTERMEDIADOR_OBTER,
      {
        params: { id: intermediadorId },
      }
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response?.sucesso && response?.dados) {
        return response.dados;
      }
    }

    return undefined;
  }, []);

  const atualizarIntermediador = async (
    intermediadorId: string,
    data: FormData
  ) => {
    const response = await api.put<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.FRENTE_CAIXA_INTERMEDIADOR_ALTERAR,
      { ...data },
      {
        params: { id: intermediadorId },
      }
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response?.sucesso) {
        return { success: true };
      }
    }

    return { success: false };
  };

  const salvarIntermediador = async (data: FormData) => {
    const response = await api.post<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.FRENTE_CAIXA_INTERMEDIADOR_CADASTRAR,
      { ...data }
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response?.sucesso) {
        return { success: true };
      }
    }

    return { success: false };
  };

  const redirecionarIntermediadorListar = () => {
    history.push(ConstanteRotas.FRENTE_CAIXA_INTERMEDIADOR);
  };

  useEffect(() => {
    listarVendedoresSelect();
  }, [listarVendedoresSelect]);

  return {
    obterIntermediador,
    atualizarIntermediador,
    salvarIntermediador,
    redirecionarIntermediadorListar,
    vendedoresLista,
  };
};

export { useIntermediadorForm };
