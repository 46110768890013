import { useCallback, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { useFormContext, useFieldArray, Control } from 'react-hook-form';

import { usePromocaoContext } from 'store/Promocao';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import auth from 'modules/auth';
import enumReferenciaServicoStargate from 'constants/enum/referenciaServicoStargate';

import {
  FormData,
  DiasSemanaProps,
  ProdutoPromocaoProps,
} from './validationForms';

export const listUsoPromocao = [
  {
    label: 'Balcão',
    value: 4,
  },

  {
    label: 'Delivery',
    value: 2,
  },
  {
    label: 'Mesa',
    value: 1,
  },
];

export const useFormularioPromocao = () => {
  const [isDiasSelecionados, setIsDiasSelecionados] = useState(true);

  const buttonRef = useRef<HTMLButtonElement>(null);

  const {
    setValue,
    control,
    handleSubmit,
    watch,
    getValues,
  } = useFormContext();
  const { listLojas } = usePromocaoContext();

  const { fields, update, replace } = useFieldArray<FormData, 'diasDaSemana'>({
    control: control as Control<FormData>,
    name: 'diasDaSemana',
  });

  const [lojasWatch, diasDaSemanaWatch] = watch(['lojas', 'diasDaSemana']);

  const hasLojasSelecionadas = (lojasWatch || []).filter(Boolean).length > 0;

  const hasDiasAtivos = (diasDaSemanaWatch || []).some(
    (itemDiaSemana: DiasSemanaProps) => itemDiaSemana.ativo
  );

  const possuiServicoFrenteCaixa = auth.possuiServico(
    enumReferenciaServicoStargate.DISPOSITIVO_FRENTE_CAIXA
  ).permitido;

  const handleAdicionarDiasSemana = (index: number) => {
    const newListFieldsDiasSemana = fields[index];

    update(index, {
      ...newListFieldsDiasSemana,
      ativo: !newListFieldsDiasSemana.ativo,
    });
  };

  const diasSemanaSelecionados = fields.every(
    (diaItem) => diaItem.ativo === true
  );

  const handleSelecionarLojas = (
    isChecked: boolean,
    indexPromocao: number,
    idLoja: string
  ) => {
    if (isChecked) {
      setValue(`lojas.${indexPromocao}`, idLoja);
    } else {
      setValue(`lojas.${indexPromocao}`, undefined);
    }
  };

  const handleAdicionarTodosDiasSemana = () => {
    setIsDiasSelecionados((prev) => !prev);

    const listDiasAdicionados = fields.map((itemField) => ({
      ...itemField,
      ativo: isDiasSelecionados,
    }));

    replace(listDiasAdicionados);
  };

  const formatHourUtcTime = (hour: string) => {
    const currentDate = new Date();

    const numberHour = Number(hour.split(':')[0]);

    const newHour = hour.slice(0, 5);

    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    const valueDay = numberHour >= 21 ? currentDay - 1 : currentDay;

    const dateValue = `${currentYear}-${
      currentMonth < 10 ? `0${currentMonth}` : currentMonth
    }-${valueDay < 10 ? `0${valueDay}` : valueDay}`;

    const valueDate = `${dateValue}T` as string;

    const horaLocal = new Date(`${valueDate}${newHour}:00`).toISOString();

    return horaLocal;
  };

  const formatPeriodo = (date: string) => {
    const currentDate = new Date(date);

    const day = currentDate.getDate();

    currentDate.setDate(day + 1);

    currentDate.setHours(0, 0, 0, 0);

    return currentDate;
  };

  const validandoItensObrigatorios = useCallback(async () => {
    let hasErrors = false;

    const onSubmit = handleSubmit(
      () => {},
      () => {
        hasErrors = true;
      }
    );
    await onSubmit();

    return hasErrors;
  }, [handleSubmit]);

  const cadastrarProdutos = useCallback(
    async (id: string, data: ProdutoPromocaoProps[]) => {
      const response = await api.post<void, ResponseApi<string>>(
        ConstanteEnderecoWebservice.CADASTRAR_PRODUTO_PROMOCAO.replace(
          'id',
          id
        ),
        data
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
          return false;
        }

        if (response.sucesso) {
          return true;
        }
      }
      return false;
    },
    []
  );

  const configuracaoPromocao = useCallback(async () => {
    const hasErrors = await validandoItensObrigatorios();

    if (hasErrors) {
      toast.warning(
        'Você precisa preencher os campos obrigatórios antes de informar os produtos'
      );
      return false;
    }

    if (!hasLojasSelecionadas) {
      toast.warning(
        'Você precisa selecionar ao menos uma loja para participar da promoção'
      );
      return false;
    }

    if (!hasDiasAtivos) {
      toast.warning('Você precisa selecionar ao menos um dia na semana');
      return false;
    }

    const data = getValues() as FormData;

    const newListDiasSemana = data.diasDaSemana
      .filter((itemDiaSemana) => itemDiaSemana.ativo)
      .map((itemDiaSemana) => itemDiaSemana.value);

    const lojas = data.lojas.filter(Boolean);

    const telasUsoPromocao =
      data?.telasUsoPromocao?.length > 0
        ? listUsoPromocao.map((itemUsoPromocao, index) => {
            const isTelaAtiva = data.telasUsoPromocao[index];

            if (!isTelaAtiva) {
              return undefined;
            }

            return itemUsoPromocao.value;
          })
        : [];

    const horarioVigenciaFim = formatHourUtcTime(
      String(data.horarioVigenciaFim)
    );
    const horarioVigenciaInicio = formatHourUtcTime(
      String(data.horarioVigenciaInicio)
    );

    return {
      ...data,
      lojas,
      telasUsoPromocao: telasUsoPromocao.filter(
        (value) => value !== undefined && value !== null
      ),
      diasDaSemana: newListDiasSemana,
      periodoVigenciaInicio: formatPeriodo(data.periodoVigenciaInicio),
      periodoVigenciaFim: formatPeriodo(data.periodoVigenciaFim),
      horarioVigenciaFim,
      horarioVigenciaInicio,
    };
  }, [
    hasDiasAtivos,
    hasLojasSelecionadas,
    validandoItensObrigatorios,
    getValues,
  ]);

  return {
    diasSemanaSelecionados,
    handleAdicionarDiasSemana,
    fields,
    handleAdicionarTodosDiasSemana,
    buttonRef,
    listLojas,
    cadastrarProdutos,
    listUsoPromocao,
    handleSelecionarLojas,
    possuiServicoFrenteCaixa,
    configuracaoPromocao,
  };
};
