import { useForm } from 'react-hook-form';
import { useState, useCallback } from 'react';

import TipoValorEnum from 'constants/enum/tipoValor';

import { HookAlterarEmMassaModalProps } from './types';

export const useAlterarEmMassaModal = ({
  onResolve,
  totalBruto,
  totalSelecionado,
}: HookAlterarEmMassaModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [tipoTaxa, setTipoTaxa] = useState<number>(TipoValorEnum.REAIS);
  const [alterarDataCompensacao, setAlterarDataCompensacao] = useState(false);

  const formMethods = useForm();

  const { handleSubmit: onSubmit, reset, watch, setValue } = formMethods;

  const [taxaEmMassaWatch, alterarTaxaWatch] = watch([
    'taxaEmMassa',
    'alterarTaxa',
  ]);

  const totalBrutoFormatado = totalBruto.toFixed(2);

  const handleSubmit = onSubmit((data) => {
    setIsLoading(true);
    onResolve({
      taxaEmMassa: data.alterarTaxa ? data.taxaEmMassa : null,
      dataCompensacaoEmMassa: alterarDataCompensacao
        ? data.dataCompensacaoEmMassa
        : null,
      tipoTaxa,
    });
    setIsLoading(false);
  });

  const handleAlterarTipoTaxa = () => {
    setValue('taxaEmMassa', 0);
    setValue('totalLiquido', totalBrutoFormatado);
    setTipoTaxa(
      tipoTaxa === TipoValorEnum.REAIS
        ? TipoValorEnum.PORCENTAGEM
        : TipoValorEnum.REAIS
    );
  };

  const handleToggleDataCompensacao = () => {
    setAlterarDataCompensacao(!alterarDataCompensacao);
  };

  const handleToggleTaxa = () => {
    setValue('taxaEmMassa', 0);
    setValue('totalLiquido', totalBrutoFormatado);
  };

  const handleOnBlurTaxaEmMassa = () => {
    if (tipoTaxa === TipoValorEnum.REAIS) {
      setValue(
        'totalLiquido',
        (totalBruto - taxaEmMassaWatch * totalSelecionado).toFixed(2)
      );
    } else {
      setValue(
        'totalLiquido',
        (totalBruto - (totalBruto * taxaEmMassaWatch) / 100).toFixed(2)
      );
    }
  };

  const resetToDefaultValues = useCallback(() => {
    reset({
      taxaEmMassa: 0,
      dataCompensacaoEmMassa: new Date(),
      totalBruto: totalBrutoFormatado,
      totalLiquido: totalBrutoFormatado,
      alterarTaxa: false,
    });
  }, [reset, totalBrutoFormatado]);

  return {
    isLoading,
    formMethods,
    tipoTaxa,
    alterarTaxaWatch,
    alterarDataCompensacao,
    resetToDefaultValues,
    handleAlterarTipoTaxa,
    handleToggleTaxa,
    handleToggleDataCompensacao,
    handleOnBlurTaxaEmMassa,
    handleSubmit,
  };
};
