import {
  Box,
  Flex,
  useMediaQuery,
  Icon,
  Text,
  VStack,
  Image,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { FiChevronLeft } from 'react-icons/fi';

import BannerPdvoffline from 'assets/images/integracoes/pdv_offline/01.png';
import ConstanteRotas from 'constants/rotas';

export const PdvOfflineComercial = () => {
  const history = useHistory();

  const handleVoltar = () => {
    history.push(ConstanteRotas.LOJA_APLICATIVOS);
  };

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  return (
    <Box
      bg="white"
      boxShadow="0px 0px 6px #00000034"
      pt="2.9%"
      pl="5.4%"
      pr="5.4%"
      pb="100px"
      borderRadius="5px"
    >
      <Flex mb="16px" justifyContent="flex-start" alignItems="center">
        <Box onClick={() => handleVoltar()} cursor="pointer" mr="16px">
          <Icon color="gray.500" boxSize="25px" as={FiChevronLeft} />
        </Box>
        <Box onClick={() => handleVoltar()} cursor="pointer">
          <Text fontSize="14px" color="gray.500">
            Voltar
          </Text>
        </Box>
      </Flex>
      <Flex
        mb="83px"
        direction={['column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box w={['full', 'full', '550px', '550px', '884px']}>
          <Text
            lineHeight={['', '', '40px']}
            fontSize={['25px', '25px', '32px']}
            letterSpacing="0"
            color="primary.50"
          >
            <span
              style={{
                fontWeight: 'bold',
              }}
            >
              {' '}
              PDV Offline:
            </span>{' '}
            Simples, rápido, independente de conexão com a internet e totalmente
            integrado com a retaguarda online.
          </Text>
          {!isLargerThan900 && (
            <Flex
              mt="20px"
              w="full"
              justifyContent="center"
              alignItems="center"
            >
              <Image w="426px" alt="banner Tray" src={BannerPdvoffline} />
            </Flex>
          )}
          <VStack
            spacing="24px"
            w={['', '', '', '', '750px']}
            mb="43px"
            mt="24px"
          >
            <Text
              lineHeight="20px"
              letterSpacing="0"
              fontSize="16px"
              color="gray.700"
            >
              Um PDV que consegue trabalhar tanto de maneira online como
              offline! Ele guarda as informações e envia para o servidor quando
              a conexão voltar. Sem risco de perder uma venda porque está sem
              internet!
            </Text>
            <Text
              lineHeight="20px"
              letterSpacing="0"
              fontSize="16px"
              color="gray.700"
            >
              Realize as principais operações de gestão de caixa: abertura e
              fechamento, sangria e suprimento, consulta de produtos, consulta
              de vendas realizadas e integração com o serviço TEF, permitindo
              que você utilize o serviço garantindo ainda mais agilidade e
              segurança para sua loja.
            </Text>
          </VStack>
        </Box>
        {isLargerThan900 && (
          <Box>
            <Image w="426px" alt="banner Tray" src={BannerPdvoffline} />
          </Box>
        )}
      </Flex>

      <Box>
        <Text
          letterSpacing="0"
          fontSize={['25px', '25px', '32px']}
          color="primary.50"
          fontWeight="extrabold"
        >
          Principais vantagens:
        </Text>
        <Text
          letterSpacing="0"
          lineHeight={['', '', '40px']}
          fontSize={['25px', '25px', '32px']}
          color="primary.50"
        >
          Não é apenas a velocidade que se destaca no nosso PDV Offline. Conheça
          outros 15 benefícios que ele entrega para sua loja e seu cliente:
        </Text>

        <Box mt={['23px', '23px', '33px']}>
          <UnorderedList
            fontSize={['16px', '16px', '20px']}
            lineHeight={['25px', '25px', '40px']}
            color="gray.700"
          >
            <ListItem>
              Online e Offline: Realize as operações de caixa com ou sem
              internet, sem perder nenhuma venda;
            </ListItem>
            <ListItem>
              Retaguarda online: Totalmente integrado com a retaguarda do
              sistema;
            </ListItem>
            <ListItem>
              Agilidade: Caixa mais rápido para o seu negócio;
            </ListItem>
            <ListItem>
              Recebimento via TEF: Para que suas vendas no cartão sejam
              totalmente integradas com o sistema;
            </ListItem>
            <ListItem>
              Recebimento via PIX: O sistema gera o QRcode e mostra na tela para
              que o cliente efetue o pagamento (serviço contratado
              separadamente, consulte as condições);
            </ListItem>
            <ListItem>
              Gestão financeira: Todas as transações são registradas e
              repassadas ao controle financeiro do sistema;
            </ListItem>
            <ListItem>
              Controle de estoque: Baixa automática na retaguarda após conexão
              entre o caixa e o servidor;
            </ListItem>
            <ListItem>Abertura e fechamento de caixas;</ListItem>
            <ListItem>Integração com balanças;</ListItem>
            <ListItem>Emissão de NFC-e;</ListItem>
            <ListItem>Consulta de preços;</ListItem>
            <ListItem>
              Histórico de transações: Consulte as vendas realizadas por
              período;
            </ListItem>
            <ListItem>Impressão de fichas;</ListItem>
            <ListItem>Descontos e Promoções;</ListItem>
            <ListItem>
              Personalização com a sua marca: O PDV exibe o nome e logo da sua
              empresa na tela principal;
            </ListItem>
          </UnorderedList>
        </Box>
      </Box>

      <Box mt={['40px', '120px']}>
        <Text color="primary.50" fontWeight="bold" fontSize="32px">
          {' '}
          Como contratar:{' '}
        </Text>
        <Flex
          h={['auto', 'auto', '94px']}
          p="30px"
          borderRadius="10px"
          bg="secondary.200"
          fontSize="20px"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Box>
            <Text fontWeight="bold">
              O módulo de PDV Offline está disponível para os planos PRO e
              PRIME.
            </Text>
            <Text>
              Para saber sobre contratação ou outros detalhes de performance,
              entre em contato.
            </Text>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};
