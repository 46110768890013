import React from 'react';
import { Route } from 'react-router-dom';

import { MetasComissoesHistoricoVendasProvider } from 'store/MetasComissoes/MetasComissoesHistoricoVendas';

import { HistoricoVendasMetasComissoes } from 'pages/Vendas/MetasComissoes/HistoricoVendas';

import LayoutGuard from './LayoutGuard';
import { ConstanteRotasAlternativas } from '../constants/rotas';

export const HistoricoVendasMetasComissoesRoutes = [
  <Route
    path={ConstanteRotasAlternativas.HISTORICO_VENDAS_METAS_COMISSOES}
    key="historico-vendas"
  >
    <MetasComissoesHistoricoVendasProvider>
      <LayoutGuard
        key="historico-vendas"
        component={HistoricoVendasMetasComissoes}
        path={ConstanteRotasAlternativas.HISTORICO_VENDAS_METAS_COMISSOES}
        exact
        meta={{ auth: true }}
      />
    </MetasComissoesHistoricoVendasProvider>
  </Route>,
];
