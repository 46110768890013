import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import auth from 'modules/auth';
import ConstanteRotas, { ConstanteRotasAlternativas } from 'constants/rotas';
import ConstanteFuncionalidades from 'constants/permissoes';
import { enumEtapasZoop } from 'constants/enum/enumEtapasZoop';
import { useZoopEtapasContext } from 'store/Zoop/ZoopEtapasContext';

import { ZoopComercial } from '../../TelaComercial';

export const Comercial = () => {
  const history = useHistory();
  const { activeStep } = useZoopEtapasContext();

  const permissaoCredenciamentoZoop = auth.possuiPermissao(
    ConstanteFuncionalidades.CREDENCIAR_ZOOP
  ).permitido;

  const handleCredenciamento = useCallback(() => {
    history.push(ConstanteRotasAlternativas.ZOOP_CONFIGURACAO);
  }, [history]);

  const handleStepChange = useCallback(() => {
    if (!permissaoCredenciamentoZoop) {
      toast.warn('Você não possui permissão para acessar essa funcionalidade');
      history.push(ConstanteRotas.LOJA_APLICATIVOS);
      return;
    }

    if (
      activeStep === enumEtapasZoop.CONTA_BANCARIA ||
      activeStep === enumEtapasZoop.GUIA_INTEGRAÇÃO
    ) {
      handleCredenciamento();
    }
  }, [activeStep, handleCredenciamento, history, permissaoCredenciamentoZoop]);

  useEffect(() => {
    handleStepChange();
  }, [handleStepChange]);

  return <ZoopComercial />;
};
