import styled from 'styled-components';
import { Card as BootstrapCard } from 'react-bootstrap';

export const Card = styled(BootstrapCard)`
  .card-body {
    padding: 20px;

    @media (min-width: 900px) {
      padding: 25px;
    }
    @media (min-width: 1200px) {
      padding: 30px;
    }
    @media (min-width: 1800px) {
      padding: 35px;
    }
  }

  border-radius: 5px !important;
  border: 1px solid var(--sti-ck-colors-gray-100) !important;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);

  overflow: unset !important;
`;

export const CardHeader = styled(BootstrapCard.Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;

  min-height: 35px;
  max-height: 35px;

  background-color: var(--sti-ck-colors-gray-50);
`;

export const Title = styled.div`
  font-size: 0.8125rem;
  font-weight: bold;

  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  padding-right: 0.75rem;

  user-select: none;
`;

export const ToggleIcon = styled.div`
  font-size: 0.5rem;

  user-select: none;
`;
