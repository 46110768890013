import { useState } from 'react';
import {
  ModalProps,
  ModalContent,
  Box,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  ModalFooter,
  ModalHeader,
  useMediaQuery,
  Checkbox,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm, useFieldArray } from 'react-hook-form';

import { LojaProps } from 'store/Produtos/ProdutosFormulario';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { ButtonDefault } from 'components/Button/ButtonChakra';
import { NumberInput } from 'components/update/Input/NumberInput';

export type FormData = {
  precoCompra: number;
  precoCusto: number;
  lojas: {
    id: string;
    endereco: string;
    fantasia: string;
    isChecked: boolean;
  }[];
};

type ModalPadronizarValoresProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<FormData> & {
    padronizarValores: (values: FormData) => void;
    lojasCadastradas: LojaProps[];
    casasDecimaisValor: number;
  };

export const ModalPadronizarValores = create<
  ModalPadronizarValoresProps,
  FormData
>(
  ({
    onResolve,
    onReject,
    casasDecimaisValor,
    children,
    lojasCadastradas,
    padronizarValores,
    ...rest
  }) => {
    const [isLoading, setIsLoading] = useState(false);

    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    const valueDefaultLojas = lojasCadastradas.map((lojaItem) => ({
      ...lojaItem,
      id: lojaItem.id,
      isChecked: false,
    }));

    const formMethods = useForm<FormData>({
      defaultValues: {
        lojas: valueDefaultLojas,
      },
    });

    const { control, handleSubmit: onSubmit } = formMethods;

    const { fields, update, replace } = useFieldArray<FormData>({
      control,
      name: 'lojas',
    });

    const isTodasLojasSelecionadas = fields.every(
      (fieldItem) => fieldItem.isChecked === true
    );

    const hasLojaSelecionada = fields.some(
      (fieldItem) => fieldItem.isChecked === true
    );

    const handleSubmit = onSubmit((data) => {
      setIsLoading(true);
      padronizarValores(data);
      setIsLoading(false);
      onClose();
    });

    const handleToggleSelecionarLojas = (index: number, isChecked: boolean) => {
      const newListFields = fields[index];

      update(index, {
        ...newListFields,
        isChecked,
      });
    };

    const handleToggleSelecionarTodasLojas = (isChecked: boolean) => {
      const newListLojas = fields.map((itemField) => ({
        ...itemField,
        isChecked,
      }));
      replace(newListLojas);
    };

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? '3xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
      >
        <ModalContent
          bg="gray.50"
          borderRadius={isLargerThan900 ? 'md' : '0'}
          marginBottom={isLargerThan900 ? '3.75rem' : '0'}
          marginTop={isLargerThan900 ? '3.75rem' : '0'}
          w={isLargerThan900 ? '800px' : 'full'}
          h={isLargerThan900 ? '500px' : 'full'}
        >
          <ModalHeader pt="16px" pb="28px" pl="24px">
            <Text color="primary.50" fontSize="16px">
              Padronizar custo
            </Text>
          </ModalHeader>

          <FormProvider {...formMethods}>
            {isLoading && <LoadingPadrao />}
            <ModalBody overflowX="auto" px="24px" py="0" mb="20px">
              <Box alignItems="center" mb="24px" w="full">
                <>
                  <Flex
                    bg="gray.100"
                    borderWidth="1px"
                    borderColor="gray.200"
                    p="15px"
                    borderRadius="6px"
                    mb="10px"
                  >
                    <Box
                      pl={['0', '25px', '25px']}
                      mr={['0', '0', '24px']}
                      mb={['10px', '10px', '0']}
                      w={['full', 'full', '200px']}
                    >
                      <NumberInput
                        leftElementColor="gray.700"
                        id="precoCompra"
                        label="Preço de compra"
                        editarFundoLeftElemento
                        labelColor="gray.700"
                        fontWeightLabel="bold"
                        leftElementFontSize="xs"
                        bgLeftElement="gray.50"
                        leftElement="R$"
                        scale={2}
                        name="precoCompra"
                      />
                    </Box>
                    <Box w={['full', 'full', '200px']}>
                      <NumberInput
                        leftElementColor="gray.700"
                        id="precoCusto"
                        scale={casasDecimaisValor}
                        label="Preço de custo"
                        editarFundoLeftElemento
                        labelColor="gray.700"
                        fontWeightLabel="bold"
                        bgLeftElement="gray.50"
                        leftElementFontSize="xs"
                        leftElement="R$"
                        name="precoCusto"
                      />
                    </Box>
                  </Flex>
                  <Flex
                    bg={isTodasLojasSelecionadas ? 'secondary.300' : 'white'}
                    h="56px"
                    py="19px"
                    justifyContent="flex-start"
                    alignItems="center"
                    boxShadow="0px 0px 4px #00000029"
                    px="24px"
                    borderRadius="5px"
                    mb="5px"
                  >
                    <Checkbox
                      mb="0"
                      colorScheme="primary"
                      mr="10px"
                      onChange={(event) =>
                        handleToggleSelecionarTodasLojas(event.target.checked)
                      }
                    />
                    <Text>Aplicar em todas as lojas</Text>
                  </Flex>
                  {fields.map((itemLoja, index) => (
                    <Flex
                      bg={itemLoja.isChecked ? 'secondary.300' : 'white'}
                      h="56px"
                      py="19px"
                      justifyContent="flex-start"
                      alignItems="center"
                      boxShadow="0px 0px 4px #00000029"
                      px="24px"
                      borderRadius="5px"
                      mb="5px"
                    >
                      <Checkbox
                        mb="0"
                        isChecked={itemLoja.isChecked}
                        colorScheme="primary"
                        onChange={(event) =>
                          handleToggleSelecionarLojas(
                            index,
                            event.target.checked
                          )
                        }
                        mr="10px"
                      />
                      <Text>
                        {itemLoja.fantasia} ({itemLoja.endereco} )
                      </Text>
                    </Flex>
                  ))}
                </>
              </Box>
            </ModalBody>
            <ModalFooter flexDirection="column" mb="20px">
              <Flex
                w="full"
                h="full"
                justifyContent="center"
                alignItems="baseline"
              >
                <Button
                  color="gray.300"
                  variant="outlineDefault"
                  borderRadius="20px"
                  borderColor="gray.300"
                  fontSize="sm"
                  lineHeight="0"
                  type="button"
                  h={isLargerThan900 ? '32px' : '40px'}
                  mr="24px"
                  onClick={() => onClose()}
                  w="96px"
                >
                  Cancelar
                </Button>
                <ButtonDefault
                  color="black"
                  variant="solid"
                  colorScheme="secondary"
                  lineHeight="0"
                  isDisabled={!hasLojaSelecionada}
                  borderRadius="20px"
                  fontSize="sm"
                  h={isLargerThan900 ? '32px' : '40px'}
                  width="120px"
                  onClick={() => handleSubmit()}
                  isLoading={isLoading}
                >
                  Confirmar
                </ButtonDefault>
              </Flex>
            </ModalFooter>
          </FormProvider>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
