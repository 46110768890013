import { toast } from 'react-toastify';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';
import { InfoCredenciamento } from 'store/Zoop/types';

export const getCredenciamentoZoop = async () => {
  const response = await api.get<void, ResponseApi<InfoCredenciamento>>(
    ConstanteEnderecoWebservice.OBTER_CREDENCIAMENTO
  );

  if (response) {
    return response;
  }
  return false;
};

export const getStatusCredenciamentoZoop = async () => {
  const response = await getCredenciamentoZoop();
  if (response) {
    if (response?.avisos) {
      response.avisos.forEach((aviso) => toast.warning(aviso));
    }
    if (response.sucesso && response.dados) {
      const { credenciamentoSolicitado, statusCredenciamento } = response.dados;
      return { statusCredenciamento, credenciamentoSolicitado };
    }
  }
  return false;
};
