import React, { SyntheticEvent, memo } from 'react';

import { TableHead, Title, AscIcon, DescIcon } from './styles';

export interface ColumnHeaderType
  extends React.DetailedHTMLProps<
    React.ThHTMLAttributes<HTMLTableHeaderCellElement>,
    HTMLTableHeaderCellElement
  > {
  name: string;
  displayName: string | JSX.Element;
  isOrderable: boolean;
  width: string;
  minWidth?: string;
  alignText?: 'center' | 'right';
  orderColumnCompareFn?: (a: any, b: any, order: 'asc' | 'desc') => number;
  hidden?: boolean;
}

interface HeaderDataType {
  orderColumn: string;
  orderDirection: string;
  setOrder: (
    event: SyntheticEvent,
    newOrderColumn: string,
    newOrderColumnCompareFn?: (a: any, b: any, order: 'asc' | 'desc') => number
  ) => void;
  columns: Array<ColumnHeaderType>;
}

const TableHeader = ({
  orderColumn,
  orderDirection,
  setOrder,
  columns,
}: HeaderDataType) => {
  return (
    <TableHead>
      <tr>
        {columns
          .filter(({ hidden }) => !hidden)
          .map(
            ({
              alignText,
              name,
              width,
              minWidth,
              isOrderable,
              orderColumnCompareFn,
              displayName,
              style,
              ...rest
            }) => {
              let justifyContent;

              if (alignText === 'center') justifyContent = 'center';
              else if (alignText === 'right') justifyContent = 'flex-end';
              else justifyContent = 'flex-start';

              return (
                <th
                  key={name}
                  style={{
                    ...style,
                    width,
                    minWidth,
                  }}
                  {...rest}
                >
                  <span
                    role="presentation"
                    onClick={(event) =>
                      isOrderable && setOrder(event, name, orderColumnCompareFn)
                    }
                    style={{
                      cursor: isOrderable ? 'pointer' : 'not-allowed',
                      userSelect: 'none',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent,
                      fontWeight: name === orderColumn ? 700 : 600,
                    }}
                  >
                    <Title style={{ justifyContent }}>
                      {displayName}
                      {name === orderColumn &&
                        (orderDirection === 'asc' ? <AscIcon /> : <DescIcon />)}
                    </Title>
                  </span>
                </th>
              );
            }
          )}
      </tr>
    </TableHead>
  );
};

export default memo(TableHeader);
