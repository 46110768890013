import { toast } from 'react-toastify';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Control, useFieldArray, useFormContext } from 'react-hook-form';

import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { ProdutosResponseProps, usePromocaoContext } from 'store/Promocao';

import {
  ModalAdicionarProduto,
  ProdutoResponse,
} from '../ModalAdicionarProduto';
import { ModalAlterarProduto } from '../ModalAlterarProduto';
import { useFormularioPromocao } from '../../hooks';
import { FormData, Produto } from '../../validationForms';

export const useProdutos = (isAlterar?: boolean) => {
  const { pagedTableRef } = usePromocaoContext();

  const buttonRef = useRef<HTMLButtonElement>(null);

  const { casasDecimais } = usePadronizacaoContext();
  const { cadastrarProdutos } = useFormularioPromocao();

  const { control } = useFormContext<FormData>();

  const { id: idRota } = useParams<{ id: string }>();

  const { fields, remove, append, update } = useFieldArray<
    FormData,
    'produtos'
  >({
    control: control as Control<FormData>,
    name: 'produtos',
  });

  const acionarButtonAdicionarProduto = () => {
    if (buttonRef?.current) {
      buttonRef?.current?.click();
    }
  };

  const openModalAdicionarProduto = async () => {
    const response = await ModalAdicionarProduto({
      casasDecimaisValor: casasDecimais.casasDecimaisValor,
    });

    return response;
  };

  const adicionarProdutoPaginado = async (newProduto: ProdutoResponse[]) => {
    const listProdutos = newProduto.map((itemProduto) => ({
      precoPromocao: itemProduto.precoNovo,
      produtoCorTamanhoId: itemProduto.produtoCorTamanhoId || '',
      quantidade: itemProduto.quantidade || 1,
      descontoAcimaQuantidade: itemProduto.descontoAcimaQuantidade,
    }));

    await cadastrarProdutos(idRota || '', listProdutos);

    if (pagedTableRef?.current?.reload) {
      pagedTableRef.current.reload();
    }
  };

  const adicionarProduto = (newProduto: ProdutoResponse[]) => {
    let countItems = 0;
    newProduto.forEach((item) => {
      const isProductAlreadyExist = fields.some(
        (itemField) =>
          itemField.produtoCorTamanhoId === item.produtoCorTamanhoId
      );

      if (isProductAlreadyExist) {
        countItems += 1;
      } else {
        append(item);
      }
    });
    if (countItems !== 0) {
      toast.warning(
        `Você tentou adicionar ${
          countItems === 1
            ? '1 produto que já existia '
            : `${countItems} produtos que já existiam`
        } na lista`
      );
    }
  };

  const handleAdicionarProduto = async () => {
    const {
      produto: newProduto,
      deveReiniciar,
    } = await openModalAdicionarProduto();

    if (isAlterar) {
      await adicionarProdutoPaginado(newProduto);
    } else {
      adicionarProduto(newProduto);
    }

    if (deveReiniciar) {
      acionarButtonAdicionarProduto();
    }
  };

  const handleEditarProduto = async (data: Produto, index: number) => {
    const {
      precoVenda,
      descontoAcimaQuantidade,
      quantidade,
    } = await ModalAlterarProduto({
      nomeProduto: data.produtoNome,
      precoVendaAtual: data.precoNovo,
      descontoAcimaQuantidade: data.descontoAcimaQuantidade,
      casasDecimaisValor: casasDecimais.casasDecimaisValor,
      quantidade: data.quantidade || 1,
    });

    update(index, {
      ...data,
      descontoAcimaQuantidade,
      precoNovo: precoVenda,
      quantidade,
    });
  };

  const handleExcluirProduto = (index: number) => {
    remove(index);
  };

  const handleEditarProdutoPaginado = async (data: ProdutosResponseProps) => {
    const {
      produtoCorTamanho,
      precoPromocao,
      descontoAcimaQuantidade,
      quantidade,
    } = data;

    const modalResponse = await ModalAlterarProduto({
      nomeProduto: produtoCorTamanho.produto,
      precoVendaAtual: precoPromocao,
      casasDecimaisValor: casasDecimais.casasDecimaisValor,
      descontoAcimaQuantidade,
      quantidade,
    });

    const response = await api.put<void, ResponseApi<string>>(
      `${ConstanteEnderecoWebservice.CADASTRAR_PRODUTO_PROMOCAO.replace(
        'id',
        idRota
      )}/${produtoCorTamanho.produtoCorTamanhoId}`,
      {
        produtoCorTamanhoId: produtoCorTamanho.produtoCorTamanhoId,
        precoPromocao: modalResponse.precoVenda,
        descontoAcimaQuantidade: modalResponse.descontoAcimaQuantidade,
        quantidade: modalResponse.quantidade,
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso) {
        if (pagedTableRef?.current?.reload) {
          pagedTableRef.current.reload();
        }
      }
    }
  };

  const handleExcluirProdutoPaginado = async (produtoCorTamanhoId: string) => {
    const response = await api.delete<void, ResponseApi<string>>(
      `${ConstanteEnderecoWebservice.CADASTRAR_PRODUTO_PROMOCAO.replace(
        'id',
        idRota
      )}/${produtoCorTamanhoId}`
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso) {
        if (pagedTableRef?.current?.reload) {
          pagedTableRef.current.reload();
        }
      }
    }
  };

  return {
    handleAdicionarProduto,
    buttonRef,
    handleExcluirProdutoPaginado,
    handleEditarProdutoPaginado,
    handleEditarProduto,
    handleExcluirProduto,
  };
};
