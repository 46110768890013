import React, { ReactNode, memo } from 'react';
import { Controller, FieldError } from 'react-hook-form';
import { InputGroup } from 'react-bootstrap';

import ContainerInputPadrao, {
  LinkActionInterface,
} from 'components/Layout/CampoContainer/CampoContainerPadrao';
import { InputControl } from 'components/Input/styles';

interface BootstrapNumberInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  name: string;
  defaultValue?: number;
  rightElement?: ReactNode;
  scale?: number;
  precision?: number;
  saveAs?: (valueAsNumber?: number, valueAsString?: string) => any;
  getValueAsString?: (value: any) => string;
  canBeUndefined?: boolean;
  label?: string;
  maxValue?: number;
  leftElement?: string;
  control: any;
  error?: FieldError | null;
  infoText?: string;
  appendButton?: any;
  linkAction?: LinkActionInterface;
  min?: number;
  max?: number;
}

export const BootstrapNumberInput = memo(
  ({
    id,
    name,
    label,
    required,
    scale = 2,
    precision,
    maxValue,
    defaultValue = 0,
    leftElement,
    appendButton,
    control,
    error = null,
    infoText,
    linkAction,
    onChange: inputOnChange,
    onFocus: inputOnFocus,
    onBlur: inputOnBlur,
    canBeUndefined = false,
    getValueAsString,
    saveAs,
    min,
    max,
    placeholder,
    ...rest
  }: BootstrapNumberInputProps) => {
    const getPlaceholder = () => {
      if (!canBeUndefined) {
        return '';
      }

      if (placeholder) {
        return placeholder;
      }

      const placeholderValue = '0'.repeat(precision || scale + 1);

      return scale > 0
        ? `${placeholderValue.substring(
            0,
            placeholderValue.length - scale
          )},${placeholderValue.slice(scale * -1)}`
        : placeholderValue;
    };

    const format = (value: any) => {
      let valueAsString = '';

      try {
        if (
          canBeUndefined &&
          ((!value && value !== 0) ||
            (getValueAsString &&
              !getValueAsString(value) &&
              getValueAsString(value) !== '0'))
        ) {
          return undefined;
        }

        valueAsString = getValueAsString
          ? getValueAsString(value)
          : Number(value || 0).toString();
      } catch {
        if (canBeUndefined) {
          return undefined;
        }

        valueAsString = '0';
      }

      if (scale > 0) {
        const [integerPart, decimalPart = ''] = (valueAsString || '').split(
          '.'
        );

        const integerPartMaxLength = precision
          ? precision - scale
          : integerPart.length;

        if (decimalPart) {
          valueAsString = `${integerPart
            .slice(0, integerPartMaxLength)
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.')},${decimalPart.padEnd(
            scale,
            '0'
          )}`;
        } else {
          valueAsString = `${integerPart
            .slice(0, integerPartMaxLength)
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.')},${'0'.repeat(scale)}`;
        }
      } else {
        valueAsString = valueAsString.replace(/\D/g, '');

        if (precision) {
          valueAsString = valueAsString.slice(0, precision);
        }
      }

      return valueAsString;
    };

    const parse = (valueAsString: string) => {
      if (!valueAsString) {
        if (canBeUndefined) {
          return saveAs ? saveAs() : undefined;
        }

        return saveAs ? saveAs(0, '0') : 0;
      }

      let valueAsNumber = 0;

      let parsedValueAsString = valueAsString.replace(/\D/g, '');

      if (precision) {
        parsedValueAsString = parsedValueAsString
          .replace(/^0+/, '')
          .slice(0, precision);
      }

      if (scale > 0) {
        parsedValueAsString = parsedValueAsString.padStart(scale + 1, '0');

        parsedValueAsString = `${parsedValueAsString.substring(
          0,
          parsedValueAsString.length - scale
        )}.${parsedValueAsString.slice(scale * -1)}`;
      }

      try {
        valueAsNumber = Number(parsedValueAsString);
      } catch {
        valueAsNumber = 0;
      }

      if (min && valueAsNumber < min) {
        valueAsNumber = min;
      } else if (max && valueAsNumber > max) {
        valueAsNumber = max;
      }

      return saveAs
        ? saveAs(valueAsNumber, valueAsNumber.toString())
        : valueAsNumber;
    };

    return (
      <ContainerInputPadrao
        id={id}
        label={label}
        error={error}
        required={required}
        infoText={infoText}
        linkAction={linkAction}
      >
        <InputGroup>
          {leftElement && (
            <InputGroup.Prepend>
              <InputGroup.Text>{leftElement}</InputGroup.Text>
            </InputGroup.Prepend>
          )}
          <Controller
            defaultValue={defaultValue}
            render={({ field }) => (
              <InputControl
                inputMode="numeric"
                id={id}
                ref={field.ref}
                value={format(field.value)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const inputValue = e.target.value;

                  field.onChange(parse(inputValue));

                  if (inputOnChange) inputOnChange(e);
                }}
                onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                  e.currentTarget.select();

                  if (inputOnChange) inputOnChange(e);
                }}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                  field.onBlur();

                  if (inputOnBlur) inputOnBlur(e);
                }}
                {...rest}
                placeholder={getPlaceholder()}
              />
            )}
            name={`${name}` as const}
            control={control}
          />
          {appendButton && (
            <InputGroup.Append>
              <InputGroup.Text style={{ background: 'unset' }}>
                {appendButton}
              </InputGroup.Text>
            </InputGroup.Append>
          )}
        </InputGroup>
      </ContainerInputPadrao>
    );
  }
);
