export const enumTabsZoop = {
  TRANSACOES: {
    name: 'Transações',
    value: 0,
  },
  CONFIGURACOES: {
    name: 'Configurações',
    value: 1,
  },

  CONTA_BANCARIA: {
    name: 'Conta bancária',
    value: 2,
  },

  itensZoop: [
    {
      name: 'Transações',
      value: 0,
    },
    {
      name: 'Configurações',
      value: 1,
    },
    {
      name: 'Conta bancária',
      value: 2,
    },
  ],
};
