import React from 'react';
import {
  Stack,
  HStack,
  Text,
  useMediaQuery,
  useToken,
  Box,
} from '@chakra-ui/react';
import { ValueType } from 'react-select';

import OptionType from 'types/optionType';

import SelectPadrao from 'components/PDV/Select/SelectPadrao/Input';

import { PaginationItem } from './PaginationItem';

const pageSizeOptionsDefault = [
  { label: '10', value: 10 },
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

interface PageSizesSelectProps {
  pageSize: number;
  handleChangePageSize: (newPageSize: number) => void;
  pageSizesOptions?: OptionType[];
}

const PageSizesSelect = ({
  handleChangePageSize,
  pageSize,
  pageSizesOptions,
}: PageSizesSelectProps) => {
  const [fontSizeSM] = useToken('fontSizes', ['sm']);
  const [radiiMd] = useToken('radii', ['md']);

  return (
    <SelectPadrao
      size="xs"
      options={pageSizesOptions || pageSizeOptionsDefault}
      value={(pageSizesOptions || pageSizeOptionsDefault).find(
        (option) => option.value === pageSize
      )}
      onChange={(option: ValueType<OptionType>) => {
        if (option) {
          handleChangePageSize((option as OptionType).value);
        }
      }}
      styles={{
        container: (base: any) => ({
          ...base,
          cursor: 'pointer',
        }),
        control: (base: any) => ({ ...base, borderRadius: radiiMd }),
        indicatorSeparator: (base: any) => ({ ...base, display: 'none' }),
        dropdownIndicator: (base: any) => ({ ...base, display: 'none' }),
        valueContainer: (base: any) => ({
          ...base,
        }),
        singleValue: (base: any) => ({
          ...base,
          position: 'relative',
          maxWidth: '100%',
          top: 'auto',
          transform: 'none',
          fontSize: fontSizeSM,
        }),
      }}
      isClearable={false}
      isSearchable={false}
      menuPlacement="auto"
    />
  );
};

function generatePagesArray(from: number, to: number) {
  return [...new Array(to - from)]
    .map((_, index) => from + index + 1)
    .filter((page) => page > 0);
}

export type PaginationData = {
  currentPage: number;
  pageSize: number;
  orderColumn: string;
  orderDirection: 'asc' | 'desc';
};

interface PaginationProps {
  pageSize: number;
  pageSizesOptions?: OptionType[];
  handleChangePageSize: (newPageSize: number) => void;
  currentPage: number;
  alterarFooter?: boolean;
  handleChangeCurrentPage: (newCurrentPage: number) => void;
  itemsTotalCount: number;
  showItemsTotalCount: boolean;
  siblingsCount?: number;
  asSiblingsCountFixed?: boolean;
}

export const Pagination = ({
  pageSize,
  handleChangePageSize,
  currentPage,
  handleChangeCurrentPage,
  itemsTotalCount,
  alterarFooter,
  showItemsTotalCount = true,
  siblingsCount = 2,
  asSiblingsCountFixed = true,
  pageSizesOptions = pageSizeOptionsDefault,
}: PaginationProps) => {
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const lastPage = Math.ceil(itemsTotalCount / pageSize);

  let previousPagesFromSiblings = currentPage - 1 - siblingsCount;

  if (lastPage < currentPage + siblingsCount && asSiblingsCountFixed) {
    previousPagesFromSiblings -= currentPage + siblingsCount - lastPage;
  }

  const previousPages =
    currentPage > 1
      ? generatePagesArray(
          Math.max(previousPagesFromSiblings, 0),
          currentPage - 1
        )
      : [];

  let nextPagesToSiblings = currentPage + siblingsCount;

  if (currentPage <= siblingsCount && asSiblingsCountFixed) {
    nextPagesToSiblings += siblingsCount + 1 - currentPage;
  }

  const nextPages =
    currentPage < lastPage
      ? generatePagesArray(currentPage, Math.min(nextPagesToSiblings, lastPage))
      : [];

  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      justifyContent={{ base: 'center', md: 'space-between' }}
      alignItems="center"
      spacing={{ base: '2', md: '4', lg: '7' }}
      w="full"
      py="5"
    >
      {isLargerThan900 && (
        <HStack spacing={2}>
          <Text color="gray.700">Mostrando</Text>

          <PageSizesSelect
            pageSize={pageSize}
            pageSizesOptions={pageSizesOptions}
            handleChangePageSize={handleChangePageSize}
          />

          <Text color="gray.700">resultados por página</Text>
        </HStack>
      )}

      <HStack
        spacing="0"
        h={isLargerThan900 ? 'full' : 'auto'}
        mb={{ base: 4, md: 0 }}
      >
        <PaginationItem
          onClick={() => handleChangeCurrentPage(1)}
          isDisabled={currentPage === 1}
        >
          Início
        </PaginationItem>
        <PaginationItem
          onClick={() => handleChangeCurrentPage(currentPage - 1)}
          isDisabled={currentPage === 1}
        >
          &laquo;
        </PaginationItem>

        {previousPages.length > 0 &&
          previousPages.map((page) => (
            <PaginationItem
              key={page}
              onClick={() => handleChangeCurrentPage(page)}
            >
              {page}
            </PaginationItem>
          ))}

        <PaginationItem isCurrent>{currentPage}</PaginationItem>

        {nextPages.length > 0 &&
          nextPages.map((page) => (
            <PaginationItem
              key={page}
              onClick={() => handleChangeCurrentPage(page)}
            >
              {page}
            </PaginationItem>
          ))}

        <PaginationItem
          onClick={() => handleChangeCurrentPage(currentPage + 1)}
          isDisabled={currentPage === lastPage}
        >
          &raquo;
        </PaginationItem>
        <PaginationItem
          onClick={() => handleChangeCurrentPage(lastPage)}
          isDisabled={currentPage === lastPage}
        >
          Última
        </PaginationItem>
      </HStack>

      {!isLargerThan900 && itemsTotalCount && (
        <HStack spacing={2}>
          <Text color="gray.700">Mostrando</Text>

          <PageSizesSelect
            pageSize={pageSize}
            pageSizesOptions={pageSizesOptions}
            handleChangePageSize={handleChangePageSize}
          />

          <Text color="gray.700">resultados por página</Text>
        </HStack>
      )}

      {showItemsTotalCount ? (
        <Text fontSize="xs" color="gray.700">
          {!itemsTotalCount
            ? 'Não foram encontrados resultados'
            : itemsTotalCount > 1
            ? `Foram encontrados ${itemsTotalCount} resultados`
            : 'Foi encontrado 1 resultado'}
        </Text>
      ) : (
        <>{!alterarFooter && <Box />}</>
      )}
    </Stack>
  );
};
