import {
  Box,
  Flex,
  useMediaQuery,
  Icon,
  Text,
  VStack,
  Image,
  AspectRatio,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { FiChevronLeft } from 'react-icons/fi';

import BannerTray from 'assets/images/integracoes/tray/01.png';
import LogoTray from 'assets/images/integracoes/tray/02.jpeg';
import EcommerceTray from 'assets/images/integracoes/tray/03.png';
import SobreTray from 'assets/images/integracoes/tray/04.png';
import ConstanteRotas from 'constants/rotas';

export const TrayComercial = () => {
  const history = useHistory();

  const handleVoltar = () => {
    history.push(ConstanteRotas.LOJA_APLICATIVOS);
  };

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const [isLargerThan1380] = useMediaQuery('(min-width: 1380px)');

  return (
    <Box
      bg="white"
      boxShadow="0px 0px 6px #00000034"
      pt="2.9%"
      pl="5.4%"
      pr="5.4%"
      pb="100px"
      borderRadius="5px"
    >
      <Flex mb="16px" justifyContent="flex-start" alignItems="center">
        <Box onClick={() => handleVoltar()} cursor="pointer" mr="16px">
          <Icon color="gray.500" boxSize="25px" as={FiChevronLeft} />
        </Box>
        <Box onClick={() => handleVoltar()} cursor="pointer">
          <Text fontSize="14px" color="gray.500">
            Voltar
          </Text>
        </Box>
      </Flex>
      <Flex
        mb="83px"
        direction={['column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box w={['full', 'full', '550px', '550px', '884px']}>
          <Text
            lineHeight={['', '', '40px']}
            fontSize={['25px', '25px', '32px']}
            letterSpacing="0"
            color="primary.50"
          >
            <span
              style={{
                fontWeight: 'bold',
              }}
            >
              {' '}
              Tray:
            </span>{' '}
            Anuncie seus produtos em mais de 30 marketplaces com atualização do
            sistema em tempo real.
          </Text>
          {!isLargerThan900 && (
            <Flex
              mt="20px"
              w="full"
              justifyContent="center"
              alignItems="center"
            >
              <Image w="426px" alt="banner Tray" src={BannerTray} />
            </Flex>
          )}
          <VStack
            spacing="24px"
            w={['', '', '', '', '750px']}
            mb="43px"
            mt="24px"
          >
            <Text
              lineHeight="20px"
              letterSpacing="0"
              fontSize="16px"
              color="gray.700"
            >
              Conte com uma vitrine completa, anunciando os produtos no seu
              próprio e-commerce e também nas maiores plataformas do país. A
              Tray é um hub de integrações que proporciona a você vender na
              internet tanto pelo seu próprio site quanto por grandes
              marketplaces.
            </Text>
            <Text
              lineHeight="20px"
              letterSpacing="0"
              fontSize="16px"
              color="gray.700"
            >
              Importe todos os produtos da sua loja para o sistema ou envie de
              forma simples para publicar na Tray. Atualize e modifique
              cadastros, estoque, financeiro, status do pedido e nota fiscal
              tudo pelo sistema. Todas as etapas da venda podem ser gerenciadas,
              agilizando o envio dos pedidos e otimizando os processos internos.
            </Text>
          </VStack>
        </Box>
        {isLargerThan900 && (
          <Box>
            <Image w="426px" alt="banner Tray" src={BannerTray} />
          </Box>
        )}
      </Flex>
      <Flex
        borderRadius="13px"
        h={['auto', 'auto', '338px']}
        p="30px"
        bg="gray.100"
        direction={['column-reverse', 'column-reverse', 'row']}
        justifyContent="center"
        alignItems="center"
      >
        <AspectRatio w={['full', 'full', '400px']} h="225px" ratio={1}>
          <iframe
            title="Sti3"
            src="https://www.youtube.com/embed/v5SpC5P9jDE?"
            allowFullScreen
          />
        </AspectRatio>
        <Box
          ml={['0', '0', '90px']}
          mb={['20px', '20px', '0']}
          w={['full', 'full', '320px']}
        >
          <Text
            letterSpacing="0"
            lineHeight={['', '', '40px']}
            fontSize={['25px', '25px', '32px']}
            color="gray.800"
          >
            Economize tempo sincronizando as vendas online e{' '}
            <span
              style={{
                fontWeight: 'bold',
              }}
            >
              gerenciando tudo no sistema.
            </span>{' '}
          </Text>
        </Box>
      </Flex>
      <Box mb="100px" mt="90px">
        <Text
          letterSpacing="0"
          fontSize={['25px', '25px', '32px']}
          color="primary.50"
          fontWeight="extrabold"
        >
          Formatos para integração:
        </Text>
        <Text
          letterSpacing="0"
          lineHeight={['', '', '40px']}
          fontSize={['25px', '25px', '32px']}
          color="primary.50"
        >
          Independente se você já vende online ou está começando do zero,
          Gerenciar produtos e anúncios é simples, rápido e seguro.
        </Text>
        <Flex
          flexDirection={['column', 'column', 'row']}
          pl={['0', '0', '40px']}
          mt={['0', '0', '40px']}
        >
          <Flex
            justifyContent="center"
            w="full"
            mt="30px"
            mb="11px"
            display={['flex', 'flex', 'none']}
          >
            <Text color="gray.700" fontWeight="extrabold" fontSize="24px">
              Já sou cliente Tray e quero importar meus produtos
            </Text>
          </Flex>
          <Flex
            w={['full', 'full', '220px']}
            justifyContent={['center', 'center', 'flex-start']}
            mb={['15px', '15px', '0']}
          >
            <Image boxSize="180px" borderRadius="24px" src={LogoTray} />
          </Flex>
          <Flex
            w={['full', 'full', 'calc(100% - 220px)']}
            justifyContent="center"
            alignItems="center"
          >
            <Box w="full">
              <Text
                display={['none', 'none', 'flex']}
                color="gray.700"
                fontWeight="extrabold"
                fontSize="24px"
              >
                Já sou cliente Tray e quero importar meus produtos
              </Text>
              <Text color="gray.700" fontSize="20px">
                Se você já está vendendo online através da Tray, é possível
                importar rapidamente todos os produtos para o sistema e
                cadastrá-los automaticamente, sem perder a relevância dos
                anúncios que já estão publicados e sem perder tempo cadastrando
                todos os produtos novamente. Tudo isso em apenas alguns cliques!
              </Text>
            </Box>
          </Flex>
        </Flex>
        <Flex
          justifyContent="center"
          w="full"
          mt="10px"
          mb="11px"
          display={['flex', 'flex', 'none']}
        >
          <Text color="gray.700" fontWeight="extrabold" fontSize="24px">
            Criando seu e-commerce do zero
          </Text>
        </Flex>
        <Flex
          pl={['0', '0', '40px']}
          flexDirection={['column', 'column', 'row']}
          mt={['0', '0', '43px']}
        >
          <Flex
            w={['full', 'full', '220px']}
            alignItems="center"
            justifyContent={['center', 'center', 'flex-start']}
          >
            <Flex
              boxSize="180px"
              borderRadius="24px"
              bg="#FFC04B"
              justifyContent="center"
              alignItems="center"
            >
              <Box h="106px">
                <Image
                  alt="imagem carrinho Tray"
                  w="180px"
                  h="120px"
                  src={EcommerceTray}
                />
              </Box>
            </Flex>
          </Flex>
          <Flex
            mt={['15px', '15px', '0']}
            w={['full', 'full', 'calc(100% - 220px)']}
            justifyContent="center"
            alignItems="center"
          >
            <Box w="full">
              <Text
                display={['none', 'none', 'flex']}
                color="gray.700"
                fontWeight="extrabold"
                fontSize="24px"
              >
                Criando seu e-commerce do zero
              </Text>
              <Text color="gray.700" fontSize="20px">
                Após ativar a conta na Tray, você envia os produtos cadastrados
                no sistema. De forma simples e rápida os ítens são sincronizados
                com a tray (e todos os marketplaces utilizados através dela). Os
                produtos permanecem organizados dentro da estrutura ideal para
                integração, com todas as informações relevantes e de fácil
                edição.
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Box>

      <Box>
        <Text
          letterSpacing="0"
          fontSize={['25px', '25px', '32px']}
          color="primary.50"
          fontWeight="extrabold"
        >
          Principais vantagens:
        </Text>
        <Text
          letterSpacing="0"
          lineHeight={['', '', '40px']}
          fontSize={['25px', '25px', '32px']}
          color="primary.50"
        >
          A integração entre o sistema e a Tray viabiliza a gestão das vendas em
          um único painel. São muitos benefícios:
        </Text>

        <Box mt={['23px', '23px', '33px']}>
          <UnorderedList
            fontSize={['16px', '16px', '20px']}
            lineHeight={['25px', '25px', '40px']}
            color="gray.700"
          >
            <ListItem>
              Atualização de estoque em tempo real, para envio e baixa das
              quantidades;
            </ListItem>
            <ListItem>
              Possibilidade para gerenciar um estoque exclusivo para loja
              virtual;{' '}
            </ListItem>
            <ListItem>
              Importação de produtos em poucos cliques da loja virtual para o
              sistema;
            </ListItem>
            <ListItem>
              Publicação de produtos na loja virtual direto do sistema, com
              cadastro padronizado e organizado;
            </ListItem>
            <ListItem>
              Atualização de preços na loja virtual direto pelo sistema, com
              preço exclusivo para venda online;
            </ListItem>
            <ListItem>
              Atualização de status do pedido sincronizada com o sistema;
            </ListItem>
            <ListItem>Envio do código de rastreio para a plataforma;</ListItem>
            <ListItem>
              Cadastro automático do cliente ao receber pedidos;
            </ListItem>
            <ListItem>
              Conciliação financeira automática no sistema com valores, tarifas
              e contas bancárias;
            </ListItem>
            <ListItem>
              Emissão da nota fiscal e envio automático para a loja virtual;
            </ListItem>
            <ListItem>Importação do valor do frete nos pedidos;</ListItem>
            <ListItem>
              Cálculo de meta da loja virtual e comissão de venda para
              vendedores;
            </ListItem>
            <ListItem>Mapeamento do marketplace que originou a venda;</ListItem>
          </UnorderedList>
        </Box>
      </Box>
      <Box mt="119px">
        <Text
          letterSpacing="0"
          fontSize={['25px', '25px', '32px']}
          color="primary.50"
          fontWeight="extrabold"
        >
          Sobre a Tray:
        </Text>
        <Text
          letterSpacing="0"
          lineHeight={['', '', '40px']}
          fontSize={['25px', '25px', '32px']}
          color="primary.50"
        >
          Tudo o que você precisa para sua loja virtual com integração de vendas
          para mais de 30 marketplaces.
        </Text>

        <Flex direction={isLargerThan1380 ? 'row' : 'column'} mt="24px">
          <VStack
            spacing="24px"
            mb={isLargerThan1380 ? '0' : '20px'}
            w={isLargerThan1380 ? '70%' : 'full'}
          >
            <Text letterSpacing="0" fontSize="20px" color="gray.700">
              A Tray é uma vitrine completa para vendas online que possibilita
              tanto criar sua própria loja virtual quanto anunciar seus produtos
              nos maiores marketplaces do mercado (funcionando como um Hub) com
              o intuito de integrar e ampliar os canais de venda.
            </Text>
            <Text letterSpacing="0" fontSize="20px" color="gray.700">
              A plataforma oferece mais de 40 modelos para aplicar no design da
              sua própria loja virtual e mais de 30 integrações com
              marketplaces. O principal objetivo da Tray é facilitar seu dia a
              dia para que você possa se preocupar com a única coisa que
              importa: vender.
            </Text>
          </VStack>

          <Flex w="full" justifyContent="center" alignItems="center">
            <Image
              w={isLargerThan1380 ? '80%' : ''}
              alt="app smart pos"
              src={SobreTray}
            />
          </Flex>
        </Flex>
      </Box>
      <Box mt={['40px', '120px']}>
        <Flex
          h={['auto', 'auto', '94px']}
          p="30px"
          borderRadius="10px"
          bg="secondary.200"
          fontSize="20px"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Box>
            <Text fontWeight="bold">
              A integração com a Tray está disponível para os planos PRO e
              PRIME.
            </Text>
            <Text>
              Para saber sobre preço, contratação ou outros detalhes de
              performance, entre em contato.
            </Text>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};
