import React, { useCallback, useState } from 'react';
import {
  ModalContent,
  ModalBody,
  Flex,
  Button,
  Heading,
  Text,
  AlertDialogFooter,
  ScaleFade,
} from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';
import { AtencaoAvisoIcon } from 'icons';
import { create } from 'react-modal-promise';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { ModalDesistencia } from 'components/Modal/ModalDesistencia';
import ManterFoco from 'components/Geral/ManterFoco';
import i18n from 'i18n';
import api, { ResponseApi } from 'services/api';
import { toast } from 'react-toastify';
import TipoAvisoConstante from 'constants/tipoAvisos';
import { ButtonFechar } from './styles';

const ModalConfirmacaoInativarConst = ({
  response,
  rotaWebService,
  id,
  ativo,
  callback,
  callbackAvisos,
  isLocalEstoque = false,
  text = i18n.t(
    'O registro está sendo usado e não pode ser excluído, deseja inativá-lo?'
  ),
  title = i18n.t('Registro em uso!'),
  successText = i18n.t('O registro foi inativado com sucesso!'),
}: any) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);

  const handleConfirmed = useCallback(async () => {
    const responseInativar = await api.put<void, ResponseApi>(
      rotaWebService,
      null,
      { params: { id } }
    );

    if (
      (responseInativar.avisos || []).length > 0 &&
      callbackAvisos &&
      ativo === true
    ) {
      const isSaldoEmAberto = responseInativar.avisos[0].includes(
        'forma-com-saldo'
      );

      ModalDesistencia({
        showCancelButton: false,
        title: isSaldoEmAberto
          ? 'Existe um saldo em aberto'
          : 'Não foi possível inativar',
        labelButtonConfirmar: 'Ok',
        textoMensagem: isSaldoEmAberto
          ? 'Para inativar uma forma de pagamento ou recebimento é necessário que o saldo dessa mesma forma esteja zerado em todos os caixas. Verifique se existe algum caixa com saldo positivo ou negativo utilizando essa forma, efetue o ajuste e retorne para confirmar a inativação'
          : responseInativar.avisos[0],
        callback: () => {},
      });
    }

    if (responseInativar?.sucesso) {
      toast.success(successText);
    }

    if (callback) callback(true);
  }, [ativo, callback, callbackAvisos, id, rotaWebService, successText]);

  if (
    (response?.tiposAviso &&
      response.tiposAviso.find(
        (tipoAviso: string) =>
          tipoAviso === TipoAvisoConstante.EXCLUIR_REGISTRO_FK
      )) ||
    !response
  ) {
    return (
      <ModalPadraoChakra
        onClose={() => {
          setIsOpen(false);
        }}
        isOpen={isOpen}
        isCentered
        size="xl"
        autoFocus={false}
      >
        <ModalContent
          marginBottom={{ base: 0, sm: '3.75rem' }}
          marginTop={{ base: 0, sm: '3.75rem' }}
          h="unset"
          maxW="600px"
        >
          <ManterFoco
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <ScaleFade initialScale={1.5} in={isOpen}>
              <ModalBody p={0} h="100%">
                <Flex flexDirection="column" align="stretch" h="100%">
                  <Flex
                    justifyContent="center"
                    h="150px"
                    bg="orange.400"
                    style={{
                      borderTopLeftRadius: '5px',
                      borderTopRightRadius: '5px',
                    }}
                  >
                    <AtencaoAvisoIcon
                      style={{
                        fontSize: '75px',
                        fontWeight: 'bolder',
                        marginTop: '35px',
                      }}
                    />
                  </Flex>

                  <Flex
                    justifyContent="flex-start"
                    flexDirection="column"
                    bg="gray.50"
                    px={8}
                    py={7}
                    h="225px"
                    style={{
                      borderBottomLeftRadius: '5px',
                      borderBottomRightRadius: '5px',
                    }}
                  >
                    {ativo && (
                      <Heading
                        as="h2"
                        fontSize="2xl"
                        color="orange.500"
                        fontWeight="semibold"
                        textAlign="initial"
                      >
                        {t(title)}
                      </Heading>
                    )}

                    {!ativo && (
                      <Heading
                        as="h2"
                        fontSize="2xl"
                        color="orange.500"
                        fontWeight="semibold"
                        textAlign="initial"
                      >
                        {t('Registro em uso!')}
                      </Heading>
                    )}

                    {ativo && (
                      <Text mt={15} fontSize="14px" color="gray.700">
                        {text
                          ? t(text)
                          : t(
                              'Os dados referentes a este registro serão inativados.'
                            )}
                      </Text>
                    )}

                    {!ativo && (
                      <Text mt={15} fontSize="14px" color="gray.700">
                        {t(
                          'O registro está sendo usado e não pode ser excluído.'
                        )}
                      </Text>
                    )}

                    {ativo && (
                      <AlertDialogFooter>
                        <ButtonFechar
                          id="cancelModal"
                          variant="ghost"
                          color="gray.300"
                          border="1px"
                          borderColor="gray.200"
                          _hover={{
                            borderColor: 'gray.700',
                            color: 'gray.700',
                          }}
                          onClick={() => {
                            setIsOpen(false);
                          }}
                        >
                          Cancelar
                        </ButtonFechar>
                        <Button
                          id="confirmModal"
                          bg="orange.400"
                          _hover={{
                            bg: 'orange.200',
                          }}
                          _active={{
                            bg: 'orange.300',
                          }}
                          onClick={() => {
                            setIsOpen(false);
                            if (isLocalEstoque && callback) {
                              callback(true);
                              return;
                            }
                            handleConfirmed();
                          }}
                          style={{
                            width: '120px',
                            marginTop: '40px',
                            marginRight: 'auto',
                          }}
                        >
                          Sim, inativar!
                        </Button>
                      </AlertDialogFooter>
                    )}
                    {!ativo && (
                      <AlertDialogFooter>
                        <Button
                          id="cancelModal"
                          bg="orange.400"
                          _hover={{
                            bg: 'orange.200',
                          }}
                          _active={{
                            bg: 'orange.300',
                          }}
                          onClick={() => {
                            setIsOpen(false);
                          }}
                          style={{
                            width: '120px',
                            marginTop: '40px',
                            marginRight: 'auto',
                            marginLeft: 'auto',
                          }}
                        >
                          Ok
                        </Button>
                      </AlertDialogFooter>
                    )}
                  </Flex>
                </Flex>
              </ModalBody>
            </ScaleFade>
          </ManterFoco>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
  return null;
};

export const ModalConfirmacaoInativar = create(ModalConfirmacaoInativarConst);
