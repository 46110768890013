import { useListPermissoesValidar } from 'hooks/useListPermissoesValidar';
import ConstanteFuncionalidades from 'constants/permissoes';

const PermissaoRelatorioProdutosVendidos = () => {
  const listFuncionalidades = [
    ConstanteFuncionalidades.RELATORIO_VENDAS_POR_DIA,
    ConstanteFuncionalidades.RELATORIO_VENDA_SIMPLIFICADAS,
  ];

  const validacaoFuncionalidade = useListPermissoesValidar(listFuncionalidades);

  return validacaoFuncionalidade;
};

export default PermissaoRelatorioProdutosVendidos;
