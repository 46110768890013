import { enumTabsZoop } from 'constants/enum/enumTabsZoop';
import { TabSelecionadaProps } from 'store/Zoop/types';

import { Configuracoes } from '../components/Configuracoes';
import { Transacoes } from '../components/Transacoes';

type TabsZoopProps = {
  activeTab: TabSelecionadaProps;
};

export const TabsZoop = ({ activeTab }: TabsZoopProps) => {
  switch (activeTab.value) {
    case enumTabsZoop.TRANSACOES.value:
      return <Transacoes />;
    case enumTabsZoop.CONFIGURACOES.value:
      return <Configuracoes />;

    default:
      return null;
  }
};
