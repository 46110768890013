import {
  VStack,
  Text,
  Flex,
  Box,
  Button,
  HStack,
  Link,
} from '@chakra-ui/react';

import { usePdvAutonomoContext } from 'store/PdvAutonomo';
import { formatDate } from 'helpers/format/formatStringDate';
import { IdentificacaoEtapasPdvOffline } from 'constants/enum/identificacaoEtapasPdvOffline';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { DataIcon } from 'components/Geral/DataCriacaoAlteracao/styles';

import { Container } from '../components/Container';
import { useDados } from './hooks';

export const Dados = () => {
  const {
    codigoAtivacao,
    exportarDados,
    isLoading,
    urlScript,
    handleBaixarScript,
    gerarScript,
    amountDispositivo,
  } = useDados();

  const { infoIntegracao, dataConfiguracao } = usePdvAutonomoContext();

  return (
    <>
      {isLoading && <LoadingPadrao />}
      <SimpleGridForm>
        <Container colSpan={[12, 5, 5]} title="Código de ativação">
          <Flex>
            {(codigoAtivacao?.listCodigoAtivacao || []).map((code) => (
              <VStack
                alignItems="center"
                justifyContent="center"
                bg="white"
                fontWeight="semibold"
                borderColor="gray.200"
                borderWidth="1px"
                borderStyle="solid"
                color="gray.900"
                fontSize={['12px', '14px', '14px']}
                textAlign="center"
                boxSize="40px"
                ml="5px"
                borderRadius="5px"
              >
                <Text>{code}</Text>
              </VStack>
            ))}
          </Flex>
        </Container>
        <Container colSpan={[12, 7, 7]} title="Plano contratado">
          <HStack
            alignItems="center"
            h="full"
            gap="8px"
            justifyContent="center"
            bg="gray.50"
            p="16px"
            w="full"
          >
            <Text fontSize="14px">
              Seu plano possui{' '}
              <Text as="span" fontSize="24px" color="purple.500">
                {amountDispositivo?.total}
              </Text>{' '}
              licenças para o PDV Offline. No momento estão ativas{' '}
              <Text as="span" fontSize="24px" color="purple.500">
                {amountDispositivo?.ativo}/{amountDispositivo?.total}
              </Text>
              .
            </Text>
          </HStack>
        </Container>
      </SimpleGridForm>

      <Flex mt="25px" justifyContent="flex-end" w="full">
        <Link
          color="blue.600"
          href="https://sti3cloudstorage.blob.core.windows.net/pdvautonomo/setup/PDVOfflineSetup.exe"
          target="_blank"
        >
          Download do instalador do PDV Offline
        </Link>
      </Flex>

      <Flex mt="50px" justifyContent="center" alignItems="center">
        {dataConfiguracao?.IdentificacaoEtapas ===
          IdentificacaoEtapasPdvOffline.GUIA_INTEGRACAO && (
          <Box mr="20px">
            <Button
              color="black"
              variant="outlineDefault"
              colorScheme="gray.500"
              borderRadius="16px"
              width="150px"
              onClick={exportarDados}
            >
              Iniciar integração
            </Button>
          </Box>
        )}
        {urlScript && (
          <Box mr="20px">
            <Button
              variant="outlineDefault"
              colorScheme="gray.500"
              borderRadius="16px"
              width="300px"
              onClick={handleBaixarScript}
            >
              Gerar arquivo com dados para o PDV
            </Button>
          </Box>
        )}
        <Button
          color="black"
          variant="solid"
          colorScheme="secondary.500"
          borderRadius="16px"
          isDisabled={
            dataConfiguracao?.IdentificacaoEtapas !==
            IdentificacaoEtapasPdvOffline.FINALIZADO
          }
          width="120px"
          onClick={gerarScript}
        >
          Gerar script
        </Button>
      </Flex>

      {dataConfiguracao && !infoIntegracao?.sincronizacaoHabilitada && (
        <Text mt="20px" float="right" display="flex">
          <DataIcon />
          <Text ml="7px">
            Último arquivo de dados gerado em{' '}
            {formatDate(dataConfiguracao?.DataScript as Date)}
          </Text>
        </Text>
      )}
    </>
  );
};
