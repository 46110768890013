import React, { useCallback, useEffect, useState } from 'react';
import {
  ModalBody,
  ModalContent,
  ModalHeader,
  VStack,
  Text,
  ModalProps,
  useDisclosure,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';

import {
  exportarDocumentoFiscal,
  ProcessandoDownloadProps,
} from 'pages/DocumentoFiscalExportacao/hooks';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';

import DownloadDocumentosComponent from '../../DownloadDocumentosComponent';

interface ModalDownloadProps
  extends Omit<ModalProps, 'children' | 'isOpen' | 'onClose'>,
    InstanceProps<void> {
  id: string;
  hashIdentificador: string;
  title?: string;
}

const ModalDownloadComponent = ({
  id,
  hashIdentificador,
  title = 'Download dos arquivos fiscais',
}: ModalDownloadProps) => {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const [downloadData, setDownloadData] = useState<ProcessandoDownloadProps>();
  const [processingDownload, setProcessingDownload] = useState(false);

  const getDownload = useCallback(async () => {
    setProcessingDownload(true);
    if (id && hashIdentificador) {
      const response = await exportarDocumentoFiscal({
        id,
        hashIdentificador,
        isDownloadExterno: false,
      });

      if (response) {
        setDownloadData(response);
      }
    }

    onClose();
    setProcessingDownload(false);
  }, [hashIdentificador, id, onClose]);

  useEffect(() => {
    getDownload();
  }, [getDownload]);

  return (
    <>
      <ModalPadraoChakra isOpen={isOpen} onClose={onClose} size="lg" isCentered>
        <ModalContent>
          <ModalHeader>
            <Text color="primary.500">{title}</Text>
          </ModalHeader>
          <ModalBody mt="10px">
            <VStack>
              <DownloadDocumentosComponent
                processingDownload={processingDownload}
                waitingResponseDownload={downloadData?.waitingResponseDownload}
                downloadPercentage={downloadData?.downloadPercentage}
                valueTotalFiles={downloadData?.valueTotalFiles}
              />
            </VStack>
          </ModalBody>
        </ModalContent>
      </ModalPadraoChakra>
    </>
  );
};

export const ModalDownload = create<ModalDownloadProps>(ModalDownloadComponent);
