const TipoTabelaPrecoEnum = {
  COMUM: 1,
  PROMOCIONAL: 2,
  INTEGRACAO: 3,

  properties: [
    { name: 'Comum', value: 1 },
    { name: 'Promocional', value: 2 },
    { name: 'Integração', value: 3 },
  ],
};

export default TipoTabelaPrecoEnum;
