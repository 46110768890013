import React from 'react';
import { Td, Tr, Th, Text, VStack } from '@chakra-ui/react';

import { TextValor } from 'components/PDV/Text/TextValor';

import Card from '../Card';
import Table from '../Table';

interface AcrescimosProps {
  acrescimos: number;
  outrasDespesas: number;
  valorFrete: number;
  totalAcrescimos: number;
}

const Acrescimos = ({
  acrescimos,
  outrasDespesas,
  valorFrete,
  totalAcrescimos,
}: AcrescimosProps) => {
  return (
    <Card title="Acréscimos">
      <Table
        tbodyContent={
          <>
            <Tr>
              <Td>
                <Text
                  color="black"
                  fontWeight="semibold"
                  fontSize="sm"
                  py="0px"
                  pl="8px"
                >
                  Acréscimos financeiros
                </Text>
              </Td>
              <Td isNumeric>
                <TextValor
                  valor={acrescimos}
                  casasDecimais={2}
                  symbolFontSize="2xs"
                  fontSize="sm"
                  fontWeight="semibold"
                  color="black"
                  justifyContent="flex-end"
                />
              </Td>
            </Tr>
            <Tr>
              <Td>
                <Text
                  color="black"
                  fontWeight="semibold"
                  fontSize="sm"
                  py="0px"
                  pl="8px"
                >
                  Outras despesas
                </Text>
              </Td>
              <Td isNumeric>
                <TextValor
                  valor={outrasDespesas}
                  casasDecimais={2}
                  symbolFontSize="2xs"
                  fontSize="sm"
                  fontWeight="semibold"
                  color="black"
                  justifyContent="flex-end"
                />
              </Td>
            </Tr>
            <Tr>
              <Td>
                <Text
                  fontWeight="semibold"
                  color="black"
                  fontSize="sm"
                  py="0px"
                  pl="8px"
                >
                  Valor do frete
                </Text>
              </Td>
              <Td isNumeric>
                <TextValor
                  valor={valorFrete}
                  casasDecimais={2}
                  symbolFontSize="2xs"
                  fontSize="sm"
                  fontWeight="semibold"
                  color="black"
                  justifyContent="flex-end"
                />
              </Td>
            </Tr>
          </>
        }
        tfootContent={
          <Tr>
            <Th py="5" isNumeric colSpan={2}>
              <VStack alignItems="flex-end">
                <Text
                  lineHeight="none"
                  fontSize="xs"
                  color="black"
                  fontWeight="semibold"
                  letterSpacing="0px"
                >
                  Acréscimos
                </Text>
                <TextValor
                  marginTop="2px"
                  valor={totalAcrescimos}
                  casasDecimais={2}
                  symbolFontWeight="bold"
                  symbolFontSize="2xs"
                  fontSize="sm"
                  color="black"
                />
              </VStack>
            </Th>
          </Tr>
        }
      />
    </Card>
  );
};

export default Acrescimos;
