import React, { useState, useRef, ChangeEvent } from 'react';
import {
  Button,
  Icon,
  Flex,
  Tooltip,
  Box,
  useMediaQuery,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { useConferenciaEstoqueEtapasContext } from 'store/ConferenciaEstoque/ConferenciaEstoqueEtapas';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';
import ConstanteRotas from 'constants/rotas';

import { CarregarArquivoColetorIcon, InfoIcon } from 'icons';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

type CadastrarItensPorCodigoResponse = {
  quantidadeAdicionados: number;
  quantidadeNaoEncontrados: number;
};

interface CarregarArquivoColetorProps {
  conferenciaEstoqueId?: string;
  recarregarListagem: () => void;
}

type CodigoBarraInternoQuantidade = {
  codigoBarrasInterno: string;
  quantidade: number;
};

export function CarregarArquivoColetor({
  conferenciaEstoqueId,
  recarregarListagem,
}: CarregarArquivoColetorProps) {
  const {
    casasDecimais: { casasDecimaisQuantidade },
  } = usePadronizacaoContext();
  const { conferenciaEstoque } = useConferenciaEstoqueEtapasContext();

  const inputRef = useRef<HTMLInputElement>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');

  const history = useHistory();

  function handleCarregarArquivo() {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }

  async function cadastrarItensPorCodigo(
    codigosBarraInternoQuantidade: CodigoBarraInternoQuantidade[]
  ) {
    setIsLoading(true);

    const response = await api.post<
      void,
      ResponseApi<CadastrarItensPorCodigoResponse>
    >(
      ConstanteEnderecoWebservice.CONFERENCIA_ESTOQUE_CADASTRAR_ITENS_POR_CODIGO,
      {
        conferenciaEstoqueId,
        codigosBarraInternoQuantidade,
        listaMarcas: (conferenciaEstoque?.listaMarcas || []).map(
          (marca) => marca.value
        ),
        listaCategorias: (conferenciaEstoque?.listaCategorias || []).map(
          (categoria) => categoria.value
        ),
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.map((aviso: string) => toast.warning(aviso));
      }
      if (response.sucesso) {
        toast.info('A importação está em andamento em segundo plano!');
        history.push(ConstanteRotas.DASHBOARD);
      }
    }

    setIsLoading(false);
  }

  function lerArquivoColetor(arquivo: File) {
    const reader = new FileReader();

    reader.onload = () => {
      const text = reader.result;

      if (text) {
        const contemPontoVirgula = text.toString().includes(';');

        const codigosBarras = (text || '')
          .toString()
          .split(contemPontoVirgula ? ';' : '\r\n')
          .filter((val) => val);

        const codigosBarraInternoQuantidade = codigosBarras.map(
          (codigoBarrasInterno) =>
            ({
              codigoBarrasInterno,
              quantidade: 1,
            } as CodigoBarraInternoQuantidade)
        );

        if (codigosBarraInternoQuantidade.length > 0) {
          cadastrarItensPorCodigo(codigosBarraInternoQuantidade);
        } else {
          toast.warning('O arquivo está em uma estrutura inválida.');
        }
      }
    };

    reader.readAsText(arquivo);
  }

  function lerArquivoCsv(arquivo: File) {
    const reader = new FileReader();

    reader.onload = () => {
      const text = reader.result;

      if (text) {
        const linhas = text.toString().split('\r\n');
        let produtosSemQuantidade = false;
        let codigoBarraIncorreto = false;

        const codigosBarraInternoQuantidade = linhas.reduce(
          (acc, curr, currIndex) => {
            const [codigoBarrasInterno, , , , , novaQuantidade] = curr.split(
              ';'
            );
            if (currIndex === 0 || !codigoBarrasInterno || !novaQuantidade) {
              return acc;
            }
            try {
              const quantidade = parseFloat(
                Number(novaQuantidade.replace(',', '')).toFixed(
                  casasDecimaisQuantidade
                )
              );

              const codigoBarras = codigoBarrasInterno?.replace(/\D/g, '');

              if (!codigoBarras) codigoBarraIncorreto = true;
              if (!quantidade || quantidade < 1) produtosSemQuantidade = true;

              if (codigoBarras && quantidade > 0) {
                acc.push({
                  codigoBarrasInterno: codigoBarras,
                  quantidade,
                });
              }
            } catch {
              return acc;
            }

            return acc;
          },
          [] as CodigoBarraInternoQuantidade[]
        );

        if (codigosBarraInternoQuantidade.length > 0) {
          cadastrarItensPorCodigo(codigosBarraInternoQuantidade);
        } else if (produtosSemQuantidade) {
          toast.warning(
            'O arquivo não possui produtos com uma nova quantidade informada ou ela está zerada. Verifique o arquivo e tente novamente.'
          );
        } else if (codigoBarraIncorreto) {
          toast.warning(
            'O arquivo possui produtos sem código de barras. Verifique o arquivo e tente novamente.'
          );
        } else {
          toast.warning(
            'O arquivo está em uma estrutura inválida.  Verifique o arquivo e tente novamente.'
          );
        }
      }
    };

    reader.readAsText(arquivo);
  }

  async function lerAquivo(arquivo: File) {
    const fileType = arquivo.name.split('.')[
      arquivo.name.split('.').length - 1
    ];

    if (fileType === 'txt') {
      lerArquivoColetor(arquivo);
    } else if (fileType === 'csv') {
      lerArquivoCsv(arquivo);
    } else {
      toast.warning('O arquivo está em um formato inválido.');
    }
  }

  return (
    <>
      {isLoading && <LoadingPadrao />}
      <Flex w="full">
        <Box mt="-18px" w={isSmallerThan900 ? 'full' : '200px'}>
          <Tooltip
            hasArrow
            bg="black"
            placement="right"
            label={
              <>
                <p>
                  Os arquivos aceitos são: .csv que foi gerado no passo 2 e .txt
                  gerado pelo coletor.
                </p>
                <p>
                  O formato aceito do arquivo gerado pelo coletor é um código
                  embaixo do outro ou separados por ; (ponto e virgula)
                </p>
              </>
            }
            fontSize="sm"
          >
            <Flex mb="1" justifyContent="flex-end">
              <InfoIcon />
            </Flex>
          </Tooltip>
          <Button
            variant="outlineDefault"
            borderRadius="md"
            w="full"
            leftIcon={<Icon as={CarregarArquivoColetorIcon} fontSize="lg" />}
            onClick={handleCarregarArquivo}
          >
            Importar produtos
          </Button>
        </Box>

        <input
          ref={inputRef}
          accept=".txt, .csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          type="file"
          value=""
          style={{ display: 'none' }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const { files } = e.target;
            if (!files || files.length === 0) {
              return;
            }

            const newFile = files[0];

            if (newFile) {
              lerAquivo(newFile);
            }
          }}
        />
      </Flex>
    </>
  );
}
