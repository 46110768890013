import { CurvaABCEnum, TipoRelatorioCurvaABC } from 'constants/enum/curvaABC';
import ConstanteFuncionalidades from 'constants/permissoes';
import auth from 'modules/auth';

import { TableHeader as TableHeaderProps } from './ListarProdutosCurvaAbc';

interface HeaderProps {
  tipoDeRelatorio: number;
  ordenacao: number;
  isResponsiveVersion?: boolean;
}

export function TableHeaderFunction({
  ordenacao,
  tipoDeRelatorio,
  isResponsiveVersion,
}: HeaderProps) {
  let propriedadesTableHeader = {
    width: isResponsiveVersion ? '100px' : '170px',
    px: '1',
    isNumeric: true,
  } as TableHeaderProps;

  const cabecalho = ((): TableHeaderProps[] => {
    const possuiPermissaoVisualizarPrecoCusto = auth.possuiPermissao(
      ConstanteFuncionalidades.USUARIO_VISUALIZAR_PRECO_CUSTO
    ).permitido;

    if (tipoDeRelatorio === TipoRelatorioCurvaABC.PRODUTO) {
      propriedadesTableHeader = {
        ...propriedadesTableHeader,
        width: isResponsiveVersion ? '100px' : '120px',
      };
      return [
        {
          ...propriedadesTableHeader,
          content: 'Quantidade',
          key: 'quantidade',
        },
        {
          ...propriedadesTableHeader,
          content: 'Valor unit. (Médio)',
          key: 'vlrUnit',
        },
        possuiPermissaoVisualizarPrecoCusto
          ? {
              ...propriedadesTableHeader,
              content: 'Custo (Médio)',
              key: 'custo',
            }
          : {
              ...propriedadesTableHeader,
              content: null,
              key: 'custo',
            },
        {
          ...propriedadesTableHeader,
          content: 'Lucro',
          key: 'lucro',
        },
        {
          ...propriedadesTableHeader,
          content: '% Lucro',
          key: 'porcentagemLucro',
        },
        {
          ...propriedadesTableHeader,
          content: 'Total faturamento',
          key: 'totalFaturamento',
        },
      ];
    }
    if (
      tipoDeRelatorio === TipoRelatorioCurvaABC.CLIENTE &&
      ordenacao === CurvaABCEnum.ITENS_VENDIDOS
    ) {
      return [
        {
          ...propriedadesTableHeader,
          content: 'Valor total de vendas',
          key: 'vlrTotalVendas',
        },
        {
          ...propriedadesTableHeader,
          content: 'Qtd. vendas',
          key: 'qtdVendas',
        },
        {
          ...propriedadesTableHeader,
          content: 'Itens vendidos',
          key: 'vlrTotal',
        },
      ];
    }
    if (
      tipoDeRelatorio === TipoRelatorioCurvaABC.CLIENTE &&
      (ordenacao === CurvaABCEnum.PORCENTAGEM_LUCRO_CLIENTE ||
        ordenacao === CurvaABCEnum.VALOR_LUCRO_CLIENTE ||
        ordenacao === CurvaABCEnum.VALOR_TOTAL_VENDAS)
    ) {
      propriedadesTableHeader = {
        ...propriedadesTableHeader,
        width: isResponsiveVersion ? '80px' : '160px',
      };
      return [
        {
          ...propriedadesTableHeader,
          content: 'Total de custo',
          key: 'totalCusto',
        },
        {
          ...propriedadesTableHeader,
          content: 'Total de vendas',
          key: 'totalDeVendas',
        },
        {
          ...propriedadesTableHeader,
          content: '%Lucro',
          key: 'lucro',
        },
        {
          ...propriedadesTableHeader,
          content: 'Valor total lucro',
          key: 'vlrTotalDeLucro',
        },
      ];
    }
    if (
      tipoDeRelatorio === TipoRelatorioCurvaABC.CLIENTE &&
      ordenacao === CurvaABCEnum.QUANTIDADE_VENDAS
    ) {
      return [
        {
          ...propriedadesTableHeader,
          content: 'Valor total de Vendas',
          key: 'vlrTotalVendas',
        },
        {
          ...propriedadesTableHeader,
          content: 'Qtd. vendas',
          key: 'qtdVendas',
        },
        {
          ...propriedadesTableHeader,
          content: 'Itens vendidos',
          key: 'itensVendidos',
        },
      ];
    }
    return [];
  })();

  const Header: TableHeaderProps[] = [
    {
      content: tipoDeRelatorio === 0 ? 'Descrição' : 'Nome',
      key: 'nome',
      width: 'auto',
    },
    ...cabecalho,
    {
      ...propriedadesTableHeader,
      content: '% Porcentagem',
      key: 'porcentagem',
    },
    {
      ...propriedadesTableHeader,
      content: '% Acumulado',
      key: 'acumulado',
      paddingRight: '8',
    },
  ];

  return Header;
}
