import { Flex, Text } from '@chakra-ui/react';

type InfoBlockProps = {
  title: string;
  value: string | number;
};

export const InfoBlock = ({ title, value }: InfoBlockProps) => {
  return (
    <Flex gap="5px">
      <Text fontWeight="extrabold">{title}</Text>
      <Text>{value}</Text>
    </Flex>
  );
};
