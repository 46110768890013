import { OrcamentosIcon, ConsignacaoIcon } from 'icons';

export type IdentificacaoTipoOperacao =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17;

const IdentificacaoTipoOperacaoEnum = {
  VENDA: 1,
  PEDIDO: 2,
  ORCAMENTO: 3,
  COMPRA: 4,
  DEVOLUCAO: 5,
  PEDIDO_COMPRA: 6,
  TRANSFERENCIA_PRODUTO_SAIDA: 7,
  TRANSFERENCIA_PRODUTO_ENTRADA: 8,
  DEVOLUCAO_COMPRA: 9,
  BRINDE: 10,
  PERDA: 11,
  AJUSTE_ESTOQUE_SAIDA: 12,
  AJUSTE_ESTOQUE_ENTRADA: 13,
  CONTAS_PAGAR: 14,
  CONTAS_RECEBER: 15,
  TRANSFERENCIA_DINHEIRO_SAIDA: 16,
  TRANSFERENCIA_DINHEIRO_ENTRADA: 17,
  TRANSFERENCIA_PRODUTO_RASCUNHO: 18,
  ENTRADA_MERCADORIA: 19,
  PAGAMENTO_COMPRA: 20,
  COMPRA_MERCADORIA: 21,
  CONFERENCIA_ESTOQUE_SAIDA: 22,
  CONFERENCIA_ESTOQUE_ENTRADA: 23,
  AJUSTE_SALDO_SAIDA: 24,
  AJUSTE_SALDO_ENTRADA: 25,
  CONSIGNACAO: 26,

  properties: {
    1: { value: 1, name: 'Venda' },
    2: { value: 2, name: 'Pedido', labelForMenuButton: 'Pedido' },
    3: {
      value: 3,
      name: 'Orçamento',
      iconForMenuButton: OrcamentosIcon,
      menuColor: 'pink.500',
    },
    4: { value: 4, name: 'Compra' },
    5: { value: 5, name: 'Devolução' },
    6: { value: 6, name: 'Pedido de Compra' },
    7: { value: 7, name: 'Transferência Produto Saída' },
    8: { value: 8, name: 'Transferência Produto Entrada' },
    9: { value: 9, name: 'Devolução de Compra' },
    10: { value: 10, name: 'Brinde' },
    11: { value: 11, name: 'Perda' },
    12: { value: 12, name: 'Ajuste Estoque Saída' },
    13: { value: 13, name: 'Ajuste Estoque Entrada' },
    14: { value: 14, name: 'Contas a Pagar' },
    15: { value: 15, name: 'Contas a Receber' },
    16: { value: 16, name: 'Transferência Dinheiro Saída' },
    17: { value: 17, name: 'Transferência Dinheiro Entrada' },
    18: { value: 18, name: 'Transferência Produto Rascunho' },
    19: { value: 19, name: 'Entrada Mercadoria' },
    20: { value: 20, name: 'Pagamento Compra' },
    21: { value: 21, name: 'Compra Mercadoria' },
    22: { value: 22, name: 'Conferência Estoque Saída' },
    23: { value: 23, name: 'Conferência Estoque Entrada' },
    24: { value: 24, name: 'Ajuste saldo saída' },
    25: { value: 25, name: 'Ajuste saldo entrada' },
    26: { value: 26, name: 'Consignação', iconForMenuButton: ConsignacaoIcon },
  },
};

export default IdentificacaoTipoOperacaoEnum;

export const identificacaoTipoOperacaoOptions = [
  { label: 'Pedidos', value: IdentificacaoTipoOperacaoEnum.PEDIDO },
  { label: 'Orçamentos', value: IdentificacaoTipoOperacaoEnum.ORCAMENTO },
  { label: 'Vendas', value: IdentificacaoTipoOperacaoEnum.VENDA },
  { label: 'Consignações', value: IdentificacaoTipoOperacaoEnum.CONSIGNACAO },
  { label: 'Devoluções', value: IdentificacaoTipoOperacaoEnum.DEVOLUCAO },
  { label: 'Todos', value: null },
];

export const identificacaoOperacaoOptions = [
  {
    label: 'Conferência Estoque Entrada',
    value: IdentificacaoTipoOperacaoEnum.CONFERENCIA_ESTOQUE_ENTRADA,
  },
  {
    label: 'Conferência Estoque Saída',
    value: IdentificacaoTipoOperacaoEnum.CONFERENCIA_ESTOQUE_SAIDA,
  },
  {
    label: 'Devolução de Compra',
    value: IdentificacaoTipoOperacaoEnum.DEVOLUCAO,
  },
  { label: 'Brinde', value: IdentificacaoTipoOperacaoEnum.BRINDE },
  { label: 'Perda', value: IdentificacaoTipoOperacaoEnum.PERDA },
  {
    label: 'Estoque Saída',
    value: IdentificacaoTipoOperacaoEnum.AJUSTE_ESTOQUE_SAIDA,
  },
  {
    label: 'Estoque Entrada',
    value: IdentificacaoTipoOperacaoEnum.AJUSTE_ESTOQUE_ENTRADA,
  },
];
