import { useCallback } from 'react';
import { Box, Checkbox, Td, Tr, Text, Flex, Icon } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import StatusFiscaisEnum from 'constants/enum/fiscal/statusFiscal';
import { moneyMask } from 'helpers/format/fieldsMasks';
import {
  formatDate,
  formatDateHourMinute,
} from 'helpers/format/formatStringDate';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { enumIdentificacaoIntegracao } from 'constants/enum/enumIdentificacaoIntegracao';

import {
  ActionMenuItem,
  ActionsMenu,
} from 'components/update/Table/ActionsMenu';
import { ModalConfirmarAcao } from 'components/update/Modal/ModalConfirmarAcao';
import { ModalConfirmar } from 'components/Modal/ModalConfirmar';
import { AvisoIcon, NotaFiscalEmitidaIcon } from 'icons';

import { ListVendasProps } from '../../validationForms';
import { useVendaItem } from './hooks';
import { useVenda } from '../../hooks';
import { ModalReprocessandoVenda } from '../ModalReprocessandoVenda';
import { ModalHistoricoAcoes } from '../ModaHistoricoAcoes';

type ItemComDataHora = {
  situacao: number;
  dataHora: string; // Formato ISO, ex: "2024-09-06T18:22:46.919Z"
};

type VendaItemProps = {
  handleGerarNotaFiscal: (data: ListVendasProps[]) => void;
  vendaItem: ListVendasProps;
  handleAbrirModalCancelarVenda: (vendaItem: ListVendasProps) => void;
  isSelecionarVenda: boolean;
  handleToggleSelecionarVendas: (index: number) => void;
  index: number;
  handleOpenModalCodigoRastreio(vendaItem: ListVendasProps[]): void;
  onReload: () => void;
};

export const VendaItem = ({
  handleGerarNotaFiscal,
  vendaItem,
  handleAbrirModalCancelarVenda,
  isSelecionarVenda,
  handleToggleSelecionarVendas,
  index,
  onReload,
  handleOpenModalCodigoRastreio,
}: VendaItemProps) => {
  const { casasDecimais } = usePadronizacaoContext();

  const { openPendences, openPendenceNotaFiscal } = useVenda(
    onReload,
    Number(vendaItem.numeroVenda),
    () => handleAbrirModalCancelarVenda(vendaItem)
  );

  const {
    backgroundVenda,
    handleCompartilharOperacao,
    isVenda,
    isOperacaoCancelada,
    listaMovimentacaoFinanceira,
    hasPendencia,
    listVendaItem,
    isNotaFiscalRejeitada,
    isNotaFiscalAltorizada,
    itemNotaFiscal,
    isCancelPedido,
    itemVerDetalhe,
  } = useVendaItem(vendaItem, casasDecimais, handleGerarNotaFiscal);

  const ordenarPorDataHora = (lista: ItemComDataHora[]): ItemComDataHora[] => {
    return [...lista].sort((a, b) => {
      const dataA = new Date(a.dataHora).getTime();
      const dataB = new Date(b.dataHora).getTime();
      return dataB - dataA;
    });
  };

  const possuiNotaFiscalEmitida = vendaItem.numeroNotaFiscal !== null;
  const possuiAlerta =
    !possuiNotaFiscalEmitida &&
    !isOperacaoCancelada &&
    vendaItem.possuiAlertaPlataforma;

  const reprocessarDadosVenda = async (venda: ListVendasProps) => {
    const {
      INTEGRACAO_PEDIDO_TRAY_DESVINCULAR_OPERACAO,
      INTEGRACAO_PEDIDO_TRAY_DESVINCULAR_PEDIDO,
    } = ConstanteEnderecoWebservice;
    let url: string;

    if (venda.operacaoId) {
      url = INTEGRACAO_PEDIDO_TRAY_DESVINCULAR_OPERACAO.replace(
        '{operacaoId}',
        venda.operacaoId
      ).replace(
        '{identificacaoIntegracao}',
        enumIdentificacaoIntegracao.TRAY.toString()
      );
    } else {
      url = INTEGRACAO_PEDIDO_TRAY_DESVINCULAR_PEDIDO.replace(
        '{integracaoPedidoId}',
        venda.integracaoPedidoId
      ).replace(
        '{identificacaoIntegracao}',
        enumIdentificacaoIntegracao.TRAY.toString()
      );
    }

    const response = await api.post<void, ResponseApi<boolean>>(url);

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso) {
        await ModalReprocessandoVenda({
          callback: async () => {
            window.location.reload();
          },
        });
      }
    }
  };

  const abrirModalConfirmacao = async (venda: ListVendasProps) => {
    ModalConfirmar({
      callback: async () => {
        await reprocessarDadosVenda(venda);
      },
      labelButtonConfirmar: 'Reprocessar dados da venda',
      title: 'Você têm certeza?',
      labelButtonCancelar: 'Cancelar',
      colorButtonConfirm: 'white',
      isConfirmar: true,
      textToConfirm: 'REPROCESSAR',
      colorScheme: 'red',
      colorTitle: 'red.500',
      widthButtonConfirm: '240px',
      heightModal: '360px',
      textoMensagem:
        'As informações de estoque e os dados financeiro dessa venda serão \nexcluídos, até que o sistema receba a nova atualização referente ao pedido.',
    });
  };

  const handleExibirHistoricoDeAcoes = useCallback(
    async (venda: ListVendasProps) => {
      const response = await api.get<
        void,
        ResponseApi<
          {
            situacao: number;
            dataHora: string;
          }[]
        >
      >(ConstanteEnderecoWebservice.INTEGRACAO_HISTORICO_ACOES_LOG, {
        params: {
          idPedidoPlataforma: venda.numeroPedido,
        },
      });
      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }
        if (response.sucesso && response.dados) {
          const dados = response.dados.map((item) => ({
            situacao: item.situacao,
            dataHora: item.dataHora,
          }));
          ModalHistoricoAcoes({
            listAcoes: ordenarPorDataHora(dados),
          });
        }
      }
    },
    []
  );

  const handleConfirmarAlteracaoDeDadosNaVenda = (venda: ListVendasProps) => {
    ModalConfirmarAcao({
      mainText: 'Reprocessar os dados da venda',
      submitButtonText: 'Continuar',
      secondaryText:
        'O sistema não permite que uma venda seja alterada. Caso algum ítem ou informação tenha sido alterado na plataforma de origem, é necessário reprocessar os dados desta venda para que o sistema sincronize com as novas informações do pedido. Deseja continuar?',
      variant: 'danger',
      callbackSubmit: () => {
        abrirModalConfirmacao(venda);
      },
    });
  };

  const handlePendences = async () => {
    if (!hasPendencia) {
      openPendenceNotaFiscal(vendaItem.motivoNotaFiscal || '', () =>
        handleGerarNotaFiscal([vendaItem])
      );
    } else {
      await openPendences(listVendaItem, vendaItem.integracaoPedidoId);
    }
  };

  return (
    <>
      <Tr
        sx={{
          boxShadow: '0px 0px 4px #00000029',
          borderRadius: '6px',

          '& > td': {
            height: '64px',
            bg: backgroundVenda(),
          },
        }}
      >
        {isSelecionarVenda && (
          <Td paddingRight="0">
            {!isOperacaoCancelada && (
              <Checkbox
                isChecked={vendaItem.isChecked}
                mb="1px"
                onChange={() => handleToggleSelecionarVendas(index)}
                colorScheme="primary"
              />
            )}
          </Td>
        )}
        <Td>
          <Box>
            <Text
              color={isOperacaoCancelada ? 'red.600' : 'black'}
              fontWeight="600"
              fontSize="14px"
            >
              {formatDate(vendaItem.dataEmissao)}
            </Text>
            <Text fontSize="12px" color="gray.700" fontWeight="300">
              {formatDateHourMinute(vendaItem.dataEmissao).slice(11)}h
            </Text>
          </Box>
        </Td>
        <Td>
          <Box>
            <Text
              color={isOperacaoCancelada ? 'red.600' : 'black'}
              fontWeight="600"
              fontSize="14px"
            >
              {vendaItem.origem}
            </Text>
            <Text fontSize="12px" color="gray.700" fontWeight="300">
              {vendaItem.numeroPedido}
            </Text>
          </Box>
        </Td>
        <Td>
          <Box>
            <Text
              color={isOperacaoCancelada ? 'red.600' : 'black'}
              fontWeight="600"
              fontSize="14px"
            >
              {vendaItem.cliente}
            </Text>
            <Text fontSize="12px" color="gray.700" fontWeight="300">
              {vendaItem?.apelido}
            </Text>
          </Box>
        </Td>
        <Td>
          <Flex h="full" justifyContent="flex-start" alignItems="left">
            {vendaItem.statusNotaFiscal === StatusFiscaisEnum.Autorizada && (
              <Flex
                display="grid"
                alignItems="center"
                justifyContent="center"
                h="full"
              >
                <Icon
                  cursor="pointer"
                  color="gray.700"
                  mr="5px"
                  mb="3px"
                  fontSize="30px"
                  as={NotaFiscalEmitidaIcon}
                />
              </Flex>
            )}

            <Flex
              display="grid"
              alignItems="center"
              justifyContent="center"
              h={
                vendaItem.statusNotaFiscal === StatusFiscaisEnum.Autorizada
                  ? undefined
                  : 'full'
              }
            >
              <Box>
                <Text
                  color={isOperacaoCancelada ? 'white' : 'black'}
                  textAlign="left"
                  borderRadius="16px"
                  w={isOperacaoCancelada ? '70px' : undefined}
                  fontSize={isOperacaoCancelada ? '12px' : '14px'}
                  fontWeight={isOperacaoCancelada ? '300' : '600'}
                  py={isOperacaoCancelada ? '1px' : undefined}
                  px={isOperacaoCancelada ? '5px' : undefined}
                  bg={isOperacaoCancelada ? 'red.500' : undefined}
                >
                  {isOperacaoCancelada ? 'Cancelada' : vendaItem.situacao}
                </Text>
                {vendaItem.statusNotaFiscal ===
                  StatusFiscaisEnum.Autorizada && (
                  <Text
                    textAlign="left"
                    fontSize="12px"
                    color="gray.700"
                    fontWeight="300"
                  >
                    NFe | {vendaItem.numeroNotaFiscal}
                  </Text>
                )}
              </Box>
            </Flex>
          </Flex>
        </Td>
        <Td color={isOperacaoCancelada ? 'red.600' : undefined}>
          {moneyMask(vendaItem.valorTotal, true)}
        </Td>
        <Td>
          <Box>
            <Text color={isOperacaoCancelada ? 'red.600' : undefined}>
              {vendaItem.numeroVenda}
            </Text>
            {listaMovimentacaoFinanceira.length > 0 ? (
              <>
                {listaMovimentacaoFinanceira.length === 1 ? (
                  <Text fontSize="12px" color="gray.700" fontWeight="300">
                    {listaMovimentacaoFinanceira[0]?.descricaoFormaPagto} |{' '}
                    {
                      listaMovimentacaoFinanceira[0]?.descricaoParcelas.split(
                        'em '
                      )[1]
                    }
                  </Text>
                ) : (
                  <Text fontSize="12px" color="gray.700" fontWeight="300">
                    Ver pagamentos
                  </Text>
                )}
              </>
            ) : (
              <>-</>
            )}
          </Box>
        </Td>
        <Td>
          {!isCancelPedido && (
            <>
              {(hasPendencia || isNotaFiscalRejeitada) &&
              !isOperacaoCancelada ? (
                <Box onClick={handlePendences}>
                  <Icon cursor="pointer" fontSize="20px" as={AvisoIcon} />
                </Box>
              ) : (
                <ActionsMenu
                  items={
                    isOperacaoCancelada
                      ? [itemVerDetalhe]
                      : ([
                          itemVerDetalhe,
                          possuiAlerta && {
                            content: 'Histórico de ações',
                            onClick: () => {
                              handleExibirHistoricoDeAcoes(vendaItem);
                            },
                          },
                          {
                            content: 'Alterar dados da venda',
                            onClick: () => {
                              handleConfirmarAlteracaoDeDadosNaVenda(vendaItem);
                            },
                          },
                          {
                            content: 'Compartilhar',
                            onClick: () => {
                              handleCompartilharOperacao(vendaItem.operacaoId);
                            },
                          },
                          !isVenda && {
                            content: 'Cancelar',
                            onClick: () => {
                              handleAbrirModalCancelarVenda(vendaItem);
                            },
                          },

                          isVenda &&
                          isNotaFiscalAltorizada &&
                          vendaItem.codigoRastreioEnviado === false
                            ? {
                                content: 'Código de rastreio',
                                onClick: () =>
                                  handleOpenModalCodigoRastreio([vendaItem]),
                              }
                            : undefined,
                          vendaItem.operacaoId &&
                          isVenda &&
                          !isNotaFiscalAltorizada &&
                          !isOperacaoCancelada
                            ? itemNotaFiscal
                            : undefined,
                        ].filter(Boolean) as ActionMenuItem[])
                  }
                />
              )}
            </>
          )}
        </Td>
      </Tr>
      <Box h="5px" />
    </>
  );
};
