import {
  IconButton,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Accordion,
  useToken,
} from '@chakra-ui/react';

import { useAtendimentoChatContext } from 'store/AtendimentoChat';
import auth from 'modules/auth';
import TipoSistemaEnum from 'constants/enum/tipoSistema';

import { DuvidasIcon, DuvidasIconFomer } from 'icons';
import PortalFullscreen from 'components/PDV/Geral/PortalFullscreen';
import WrapperButton from 'components/PDV/Layout/Header/WrapperButton';
import { MobileModalMenu } from 'components/PDV/Layout/MobileModalMenu';

import { AccordionWrapperButton } from './AccordionWrapperButton';
import { OpcoesAjuda } from './Ajuda/Opcoes';

interface AjudaProps {
  asWrapperButton?: boolean;
  asPDVView?: boolean;
}

export const Ajuda = ({
  asWrapperButton = false,
  asPDVView = false,
}: AjudaProps) => {
  const sistema = auth.getSistema();
  const isClienteSTi3 =
    sistema.value === TipoSistemaEnum.ZENDAR ||
    sistema.value === TipoSistemaEnum.FOMER ||
    sistema.value === TipoSistemaEnum.FOMER_SISTEMA;

  const isPdv = window?.location?.pathname?.includes('/pdv');
  const isFomer =
    sistema.value === TipoSistemaEnum.FOMER ||
    sistema.value === TipoSistemaEnum.FOMER_SISTEMA;

  const {
    isMenuOpen,
    onMenuClose,
    onMenuOpen,
    chatAccordionIndex,
  } = useAtendimentoChatContext();

  if (asWrapperButton) {
    if (asPDVView) {
      return (
        <>
          <WrapperButton
            icon={isPdv && isFomer ? DuvidasIconFomer : DuvidasIcon}
            title="Ajuda"
            onClick={onMenuOpen}
            hasArrow
            isDisabled={false}
          />

          <MobileModalMenu
            title="Ajuda"
            isOpen={isMenuOpen}
            onClose={onMenuClose}
          >
            <OpcoesAjuda />
          </MobileModalMenu>
        </>
      );
    }

    return (
      <>
        <Accordion allowToggle>
          <AccordionWrapperButton
            icon={isPdv && isFomer ? DuvidasIconFomer : DuvidasIcon}
            title="Ajuda"
            isDisabled={false}
          >
            {({ isExpanded }) => {
              if (isExpanded && !isMenuOpen) {
                onMenuOpen();
              } else if (!isExpanded && isMenuOpen) {
                onMenuClose();
              }

              return <OpcoesAjuda />;
            }}
          </AccordionWrapperButton>
        </Accordion>
      </>
    );
  }

  return (
    <Popover
      isOpen={isMenuOpen}
      onOpen={onMenuOpen}
      onClose={onMenuClose}
      placement="bottom-end"
      closeOnBlur={chatAccordionIndex !== 0}
    >
      <PopoverTrigger>
        <IconButton
          aria-label="Ajuda"
          icon={<Icon as={isPdv && isFomer ? DuvidasIconFomer : DuvidasIcon} />}
          variant="link"
          colorScheme="primary"
          fontSize="xl"
          px={1}
          py={1}
          minW={7}
          borderRadius="md"
          isDisabled={false}
        />
      </PopoverTrigger>
      <PortalFullscreen>
        <PopoverContent
          maxH="calc(100vh - 50px)"
          overflow="auto"
          minW="400px"
          bg="gray.50"
          borderRadius="5px"
          _focus={{ boxShadow: '4px 6px 25px 8px #00000029' }}
          borderWidth="1px"
          borderColor="gray.100"
          borderStyle="solid"
          boxShadow="4px 6px 25px 8px #00000029"
          pb={isClienteSTi3 ? '48px' : '12px'}
        >
          <PopoverBody p="6">
            <OpcoesAjuda />
          </PopoverBody>
        </PopoverContent>
      </PortalFullscreen>
    </Popover>
  );
};
