import { useState } from 'react';
import { toast } from 'react-toastify';
import { FormProvider, useForm } from 'react-hook-form';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import { UncontrolledForm } from '..';
import { useDeliverySectorForm } from '../hooks';
import { defaultValues, FormData, yupResolver } from '../validationForm';

const CadastrarSetorEntrega = () => {
  const { handleToDeliverySector } = useDeliverySectorForm();

  const [isLoading, setIsLoading] = useState(false);

  const formMethods = useForm<FormData>({
    defaultValues,
    resolver: yupResolver,
  });

  const { handleSubmit: onSubmit, reset } = formMethods;

  const registerDeliverySector = async (data: FormData) => {
    const newValueLojas = data.lojas as string[];

    const lojas = newValueLojas.filter(Boolean);

    if (lojas?.length === 0) {
      toast.warning('Selecione ao menos uma loja para o setor de entrega');

      return { success: false };
    }

    const newData = {
      ...data,
      lojas,
    };

    const response = await api.post<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.SETOR_ENTREGA,
      {
        ...newData,
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }
      if (response.sucesso) {
        toast.success('Setor de entrega adicionado com sucesso');
        return { success: true };
      }
    }
    return { success: false };
  };

  const handleSave = onSubmit(async (data) => {
    setIsLoading(true);

    const { success } = await registerDeliverySector(data);

    if (success) {
      handleToDeliverySector();
    }

    setIsLoading(false);
  });

  const handleSaveAndReset = onSubmit(async (data) => {
    setIsLoading(true);

    const { success } = await registerDeliverySector(data);

    if (success) {
      reset(defaultValues);
    }

    setIsLoading(false);
  });

  return (
    <FormProvider {...formMethods}>
      <UncontrolledForm
        handleSave={handleSave}
        handleSaveAndReset={handleSaveAndReset}
        isLoading={isLoading}
      />
    </FormProvider>
  );
};

export { CadastrarSetorEntrega };
