import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import { isValidCnpj, isValidCpf } from 'helpers/validation/IsValidCpfCnpj';
import OperacaoIntermediadorEnum from 'constants/enum/fiscal/operacaoIntermediador';

const schema = (cpfCnpjIsRequired: boolean) =>
  yup.object().shape({
    cpfCnpj: cpfCnpjIsRequired
      ? yup
          .string()
          .test(
            'cpfCnpj',
            ConstanteMensagemValidacao.CNPJ_INVALIDO,
            (value) => {
              if (value) {
                if (value.length > 14) return isValidCnpj(value);
              }

              return true;
            }
          )
          .test('cpfCnpj', ConstanteMensagemValidacao.CPF_INVALIDO, (value) => {
            if (value) {
              if (value.length <= 14) return isValidCpf(value);
            }

            return true;
          })
          .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
      : yup
          .string()
          .test(
            'cpfCnpj',
            ConstanteMensagemValidacao.CNPJ_INVALIDO,
            (value) => {
              if (value) {
                if (value.length > 14) return isValidCnpj(value);
              }

              return true;
            }
          )
          .test('cpfCnpj', ConstanteMensagemValidacao.CPF_INVALIDO, (value) => {
            if (value) {
              if (value.length <= 14) return isValidCpf(value);
            }

            return true;
          }),
    operacaoComIntermediador: yup.number().required(),
    cnpjIntermediador: yup.string().when('operacaoComIntermediador', {
      is: (val) =>
        val === OperacaoIntermediadorEnum.OperacaoEmSitePlataformaTerceiros,
      then: yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .test('cpfCnpj', ConstanteMensagemValidacao.CNPJ_INVALIDO, (value) => {
          if (value) {
            if (value.length > 14) return isValidCnpj(value);
          }

          return true;
        }),
      otherwise: yup.string().nullable().default(null),
    }),
    identificacaoNoIntermediador: yup
      .string()
      .when('operacaoComIntermediador', {
        is: (val) =>
          val === OperacaoIntermediadorEnum.OperacaoEmSitePlataformaTerceiros,
        then: yup
          .string()
          .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
        otherwise: yup.string().nullable().default(null),
      }),
  });

export const yupResolver = (cpfCnpjIsRequired: boolean) =>
  yupResolverInstance(schema(cpfCnpjIsRequired));

export const useForm = useFormInstance;
