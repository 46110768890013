import { Button, ButtonProps } from '@chakra-ui/react';

export interface ButtonDefaultProps extends ButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
}

export const ButtonDefault = ({
  children,
  id,
  variant = 'solid',
  colorScheme,
  isDisabled,
  rightIcon,
  leftIcon,
  width,
  minWidth,
  color = 'black',
  onClick,
  isLoading,
  size,
  ...rest
}: ButtonDefaultProps) => {
  return (
    <Button
      {...rest}
      id={id}
      onClick={(event) => {
        if (event.detail === 1 && onClick) {
          onClick();
        }
      }}
      minWidth={minWidth}
      size={size}
      w={width}
      isLoading={isLoading}
      disabled={isDisabled || isLoading}
      isDisabled={isDisabled || isLoading}
      variant={variant}
      colorScheme={colorScheme}
      rightIcon={rightIcon}
      leftIcon={leftIcon}
      color={color}
    >
      {children}
    </Button>
  );
};
