import { TableHeader } from 'components/update/Table/VirtualizedInfiniteTable';

export const produtosTableHeaders: TableHeader[] = [
  {
    key: 'produtos',
    content: '',
    lineHeight: 'none',
    verticalAlign: 'bottom',
    width: '26%',
    paddingX: '4px',
  },
  {
    key: 'custoAtual',
    content: 'Custo atual',
    lineHeight: 'none',
    verticalAlign: 'bottom',
    isNumeric: true,
    width: '14%',
    paddingX: '4px',
  },
  {
    key: 'markupAtual',
    content: 'Markup atual',
    lineHeight: 'none',
    verticalAlign: 'bottom',
    isNumeric: true,
    width: '12%',
    paddingX: '4px',
  },
  {
    key: 'valorVendaAtual',
    content: 'Valor venda',
    lineHeight: 'none',
    verticalAlign: 'bottom',
    width: '12%',
    isNumeric: true,
    minWidth: '70px',
    paddingX: '4px',
  },
  {
    key: 'custoMedioNovo',
    content: 'Custo méd. novo',
    lineHeight: 'none',
    verticalAlign: 'bottom',
    width: '14%',
    isNumeric: true,
    minWidth: '90px',
    paddingX: '4px',
  },
  {
    key: 'markupNovo',
    content: 'Markup novo',
    lineHeight: 'none',
    verticalAlign: 'bottom',
    width: '12%',
    paddingX: '4px',
  },
  {
    key: 'precoVendaNovo',
    content: 'Preço de venda novo',
    lineHeight: 'none',
    verticalAlign: 'bottom',
    width: '12%',
    paddingX: '4px',
  },
];
