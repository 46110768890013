import { Box, BoxProps, Divider } from '@chakra-ui/react';

interface CardHeaderProps extends BoxProps {
  children: React.ReactNode;
}

export const CardHeader = ({
  children,

  ...rest
}: CardHeaderProps) => {
  return (
    <Box
      borderTopRadius="6px"
      pt="16px"
      pb="20px"
      alignItems="center"
      justifyContent="space-between"
      {...rest}
    >
      {children}
      <Divider mt="16px" />
    </Box>
  );
};
