import { toast } from 'react-toastify';
import { useCallback, useState, useEffect, FocusEvent } from 'react';
import {
  Flex,
  Box,
  Text,
  Grid,
  GridItem,
  useMediaQuery,
  HStack,
  Icon,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { enumIdentificacaoIntegracao } from 'constants/enum/enumIdentificacaoIntegracao';
import { useProdutosFormularioContext } from 'store/Produtos/ProdutosFormulario';
import { useValidationFormDirtyFields } from 'hooks/useValidationFormDirtyFields';

import Input from 'components/PDV/Input';
import { Switch } from 'components/update/Switch';
import { CadeadoIcon, PainelTrayIcon } from 'icons';
import { TextArea } from 'components/update/TextArea';
import TextEditor from 'components/update/TextEditor';
import { ModalAtencao } from 'components/Modal/ModalAtencao';

import { useProdutoEcommerce } from '../../hooks';

export const TrayEcommerce = () => {
  const [configTray, setConfigTray] = useState<{
    id: string;
    categoria: string;
  }>();

  const {
    watch,
    setValue,
    getValues,
    formState: { dirtyFields },
  } = useFormContext();

  const {
    isLargerThan900,
    salvarDadosEcommerces,
    obterDadosFormularioEcommerces,
  } = useProdutoEcommerce();

  const isDirty = useValidationFormDirtyFields(dirtyFields);
  const { idProduto } = useProdutosFormularioContext();

  const tituloProdutoTray = watch(`titulo-${enumIdentificacaoIntegracao.TRAY}`);
  const identificacaoTray = enumIdentificacaoIntegracao.TRAY;

  const obterDadosTray = useCallback(async () => {
    const response = await api.get<
      void,
      ResponseApi<{ id: string; categoria: string }>
    >(
      `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/ecommerces/${enumIdentificacaoIntegracao.TRAY}`
    );

    if (response) {
      if (response.sucesso) {
        setConfigTray(response.dados);
      }
    }
  }, [idProduto]);

  const salvarDados = useCallback(async () => {
    if (isDirty === false) {
      return { sucesso: true };
    }

    if (!tituloProdutoTray) {
      toast.warning('Informe o título do produto antes de publicá-lo na Tray.');
      return { sucesso: false };
    }

    const data = obterDadosFormularioEcommerces(idProduto, getValues);
    const response = await salvarDadosEcommerces(idProduto, data);

    if (response) {
      if (response?.avisos) {
        ModalAtencao({
          title: 'Atenção',
          text: response?.avisos[0] || '',
          showCancelButton: false,
          confirmButtonText: 'Ok',
        });

        return { sucesso: false };
      }

      if (response?.sucesso) {
        return { sucesso: true };
      }
    }

    return { sucesso: false };
  }, [
    isDirty,
    tituloProdutoTray,
    obterDadosFormularioEcommerces,
    idProduto,
    getValues,
    salvarDadosEcommerces,
  ]);

  const alterarStatus = useCallback(
    async (publicar: boolean) => {
      const { sucesso } = await salvarDados();

      if (!sucesso) {
        setValue(`anunciado-${identificacaoTray}`, false);
        return;
      }

      const response = await api.put<void, ResponseApi<boolean>>(
        ConstanteEnderecoWebservice.INTEGRACAO_ATIVAR_PRODUTO_TRAY,
        {
          produtoId: idProduto,
          publicar,
        }
      );

      if (response) {
        if (response?.avisos) {
          ModalAtencao({
            title: 'Atenção',
            text: response?.avisos[0] || '',
            showCancelButton: false,
            confirmButtonText: 'Ok',
          });

          setValue(`anunciado-${identificacaoTray}`, false);
        }
      }
    },
    [salvarDados, idProduto, setValue, identificacaoTray]
  );

  useEffect(() => {
    obterDadosTray();
  }, [obterDadosTray]);

  return (
    <>
      {isLargerThan900 && (
        <HStack color="#000082">
          <Box bgColor="currentcolor" p="5px" borderRadius="5px">
            <Icon as={PainelTrayIcon} boxSize="25px" />
          </Box>
          <Box fontSize="20px" fontWeight="semibold">
            Tray Commerce
          </Box>
        </HStack>
      )}
      <Box w="full">
        <Flex w="full" justifyContent="flex-end">
          <Text
            display="flex"
            fontSize="14px"
            color="gray.500"
            fontWeight="semibold"
            alignItems="baseline"
          >
            ID do produto na Tray:{' '}
            <span
              style={{
                marginLeft: '5px',
                marginRight: '5px',
                fontWeight: 'bold',
                color: 'black',
              }}
            >
              {configTray?.id}
            </span>
            <Icon as={CadeadoIcon} fontSize="14px" />
          </Text>
        </Flex>
        <Box
          borderRadius="5px"
          overflowY="auto"
          pl={['10px', '20px', '40px']}
          h="48px"
          py="14px"
          borderColor="gray.200"
          bg="gray.100"
          borderWidth="1px"
          overflow="hidden"
        >
          <Flex justifyContent="flex-start">
            <Box mr="5px">
              <Switch
                onChange={(e) => alterarStatus(e.target.checked)}
                size="md"
                name={`anunciado-${identificacaoTray}`}
                id={`anunciado-${identificacaoTray}`}
              />
            </Box>
            <Text whiteSpace="nowrap" color="gray.700" fontSize="14px">
              Anúncio publicado e ativo na plataforma
            </Text>
          </Flex>
        </Box>
      </Box>
      <Flex direction={['column', 'column', 'row']} my="28px" w="full">
        <Box
          mb={['24px', '24px', '0']}
          mr={['0', '0', '24px']}
          w={['full', 'full', '50%']}
        >
          <Input
            id={`campo1-${identificacaoTray}`}
            name={`campo1-${identificacaoTray}`}
            label="Característica para variação Cor"
            maxLength={100}
            placeholder="Digite a característica"
            onBlur={(event: FocusEvent<HTMLInputElement>) =>
              setValue(`campo1-${identificacaoTray}`, event.target.value.trim())
            }
          />
        </Box>
        <Box w={['full', 'full', '50%']}>
          <Input
            id={`campo2-${identificacaoTray}`}
            name={`campo2-${identificacaoTray}`}
            label="Característica para variação Tamanho"
            maxLength={100}
            placeholder="Digite a característica"
            onBlur={(event: FocusEvent<HTMLInputElement>) =>
              setValue(`campo2-${identificacaoTray}`, event.target.value.trim())
            }
          />
        </Box>
      </Flex>
      <Flex my="28px">
        <Box w="full">
          <Input
            id={`titulo-${identificacaoTray}`}
            name={`titulo-${identificacaoTray}`}
            label="Título"
            maxLength={100}
            placeholder="Digite o nome do produto para aparecer no anúncio"
          />
        </Box>
        {/* <Flex ml="4px" flex="1" alignItems="flex-end">
          <IconButton
            aria-label="Selecionar grade de tamanhos"
            icon={<Icon as={FiCopy} fontSize="xl" />}
            colorScheme="whiteAlpha"
            color="gray.800"
            bg="white"
            w="32px"
            _hover={{ bg: 'gray.100' }}
            _active={{ bg: 'gray.100' }}
            border="1px"
            borderColor="gray.200"
            borderRadius="md"
          />
        </Flex> */}
      </Flex>
      <TextEditor name={`descricao-${identificacaoTray}`} />
      <Flex my="28px">
        <Box w="100%">
          <Input
            id={`itensInclusos-${identificacaoTray}`}
            name={`itensInclusos-${identificacaoTray}`}
            label="Itens inclusos"
            placeholder="Informe os itens"
          />
        </Box>
        {/* <Flex ml="4px" flex="1" alignItems="flex-end">
          <IconButton
            aria-label="Selecionar grade de tamanhos"
            icon={<Icon as={FiCopy} fontSize="xl" />}
            colorScheme="whiteAlpha"
            color="gray.800"
            bg="white"
            w="32px"
            _hover={{ bg: 'gray.100' }}
            _active={{ bg: 'gray.100' }}
            border="1px"
            borderColor="gray.200"
            borderRadius="md"
          />
        </Flex> */}
      </Flex>
      <Grid
        mb="28px"
        templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
        gap={6}
      >
        <GridItem w="100%">
          <TextArea
            name={`tempoGarantia-${identificacaoTray}`}
            id={`tempoGarantia-${identificacaoTray}`}
            label="Tempo de garantia"
            placeholder="Descreva a garantia"
          />
        </GridItem>
        <GridItem w="100%">
          <TextArea
            name={`disponibilidadeEntrega-${identificacaoTray}`}
            id={`disponibilidadeEntrega-${identificacaoTray}`}
            label="Disponibilidade de entrega"
            placeholder="Descreva o seu prazo de entrega"
          />
        </GridItem>
      </Grid>
      <Box w="full">
        <Text fontSize="14px" fontWeight="semibold">
          Estrutura de categorias específica da plataforma
        </Text>
        <Box
          borderRadius="5px"
          overflow="hidden"
          px="12px"
          py="7px"
          h="32px"
          borderColor="gray.200"
          bg="gray.100"
          borderWidth="1px"
        >
          {configTray?.categoria || 'SEM CATEGORIA'}
        </Box>
      </Box>
    </>
  );
};
