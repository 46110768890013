import React, { useCallback, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { GridItem, Tr, Td, Box, Link } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteRotas from 'constants/rotas';
import ConstanteFuncionalidades from 'constants/permissoes';
import { SubstituirParametroRota } from 'constants/signalRGroups';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import TipoCadastroCampoPersonalizadoEnum from 'constants/enum/tipoCadastroCampoPersonalizado';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import { shallowEqual } from 'helpers/validation/shallowEqual';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import auth from 'modules/auth';

import { SearchInput } from 'components/update/Input/SearchInput';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import { StatusCircle } from 'components/update/Table/StatusCircle';
import { FilterSelect } from 'components/update/Select/FilterSelect';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { ButtonCadastrarNovo } from 'components/Layout/ButtonCadastrarNovo';
import { ModalConfirmacaoInativar } from 'components/Modal/ModalConfirmacaoInativar';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import {
  GridPaginadaConsulta,
  GridPaginadaRetorno,
} from 'components/Grid/Paginacao';
import {
  PagedTable,
  PagedTableForwardRefData,
} from 'components/update/Table/PagedTable';

interface CamposPersonalizadosFiltro {
  nome: string;
  ativo: boolean;
}

interface CamposPersonalizados {
  id: string;
  nome: string;
  tipoCadastro: number;
  ativo: boolean;
}

export const ListarCampoPersonalizado = () => {
  const formDefaultValues = {
    ativo: true,
    nome: '',
  };

  const [
    currentFilters,
    setCurrentFilters,
  ] = useState<CamposPersonalizadosFiltro>(formDefaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [camposPersonalizados, setCamposPersonalizados] = useState<
    CamposPersonalizados[]
  >([]);
  const history = useHistory();

  const formMethods = useForm({
    defaultValues: formDefaultValues,
  });

  const { handleSubmit, setFocus } = formMethods;

  const permissaoCampoPersonalizadoAlterar = auth.possuiPermissao(
    ConstanteFuncionalidades.CAMPO_PERSONALIZADO_ALTERAR
  );

  const permissaoCampoPersonalizadoVisualizar = auth.possuiPermissao(
    ConstanteFuncionalidades.CAMPO_PERSONALIZADO_VISUALIZAR
  );

  const handleReset = handleSubmit((data) => {
    const filtersIsDirty = !shallowEqual(data, currentFilters || {});

    if (filtersIsDirty) {
      setCurrentFilters(data);
    }
  });

  const pageIsLoaded = useRef(false);
  const childRef = useRef<PagedTableForwardRefData>(null);
  const isMountedRef = useIsMountedRef();

  const getNomeLinkHref = useCallback(
    (id: string) => {
      let href = '';

      if (permissaoCampoPersonalizadoAlterar.permitido) {
        href = SubstituirParametroRota(
          ConstanteRotas.CAMPO_PERSONALIZADO_ALTERAR,
          'id',
          id
        );
      } else if (permissaoCampoPersonalizadoVisualizar.permitido) {
        href = SubstituirParametroRota(
          ConstanteRotas.CAMPO_PERSONALIZADO_VISUALIZAR,
          'id',
          id
        );
      }

      return href;
    },
    [permissaoCampoPersonalizadoAlterar, permissaoCampoPersonalizadoVisualizar]
  );

  const handleRefresh = useCallback(() => {
    return childRef.current?.reload();
  }, []);

  const paginationHandle = useCallback(
    async (gridPaginadaConsulta: GridPaginadaConsulta) => {
      setIsLoading(true);
      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<CamposPersonalizados>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.CAMPO_PERSONALIZADO_LISTAR_PAGINADO,
          gridPaginadaConsulta
        ),
        { params: currentFilters }
      );

      if (response) {
        if (response?.sucesso && isMountedRef.current) {
          setTotalRegistros(response.dados.total);
          setCamposPersonalizados(response.dados.registros);
        }
      }

      if (isMountedRef.current) {
        setIsLoading(false);

        if (!pageIsLoaded.current) {
          pageIsLoaded.current = true;
          setFocus('nome');
        }
      }
    },
    [currentFilters, isMountedRef, setFocus]
  );

  const handleHistoryPush = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history]
  );

  const excluirHandle = useCallback(
    async (campoPersonalizadoId: string, ativo: boolean) => {
      ModalConfirmacaoExcluir({
        callback: async (ok: boolean) => {
          if (ok) {
            setIsLoading(true);

            const response = await api.delete<void, ResponseApi>(
              ConstanteEnderecoWebservice.CAMPO_PERSONALIZADO_EXCLUIR,
              {
                params: { id: campoPersonalizadoId },
              }
            );

            if (response) {
              if (response && response?.sucesso) {
                toast.success('O cadastro foi removido com sucesso.');
                handleRefresh();
              }

              ModalConfirmacaoInativar({
                response,
                rotaWebService:
                  ConstanteEnderecoWebservice.CAMPO_PERSONALIZADO_INATIVAR,
                id: campoPersonalizadoId,
                ativo,
                callback: (okInativar: boolean) => {
                  if (okInativar) handleRefresh();
                },
              });
            }

            setIsLoading(false);
          }
        },
      });
    },
    [handleRefresh]
  );

  return (
    <>
      <SimpleGridForm gap={[2, 5, 5]}>
        <FormProvider {...formMethods}>
          <GridItem colSpan={[12, 6, 6]}>
            <SearchInput
              placeholder="Buscar campos personalizado por nome"
              id="nome"
              maxLength={40}
              onEnterKeyPress={() => handleReset()}
              isDisabled={isLoading}
              name="nome"
            />
          </GridItem>
          <GridItem
            colSpan={[12, 3, 2]}
            display="flex"
            justifyContent="flex-start"
          >
            <Box w={['full', 'full', '64%']} minW="160px">
              <FilterSelect
                isSearchable={false}
                id="ativo"
                name="ativo"
                isDisabled={isLoading}
                options={[
                  {
                    value: true,
                    label: 'Ativos',
                  },
                  {
                    value: false,
                    label: 'Inativos',
                  },
                  {
                    value: null,
                    label: 'Todos',
                  },
                ]}
                onSelect={() => handleReset()}
              />
            </Box>
          </GridItem>
          <GridItem
            colSpan={[12, 12, 4]}
            display="flex"
            justifyContent={['flex-end', 'flex-start', 'flex-end']}
          >
            <ButtonCadastrarNovo
              onClick={() =>
                handleHistoryPush(ConstanteRotas.CAMPO_PERSONALIZADO_CADASTRAR)
              }
              funcionalidade={
                ConstanteFuncionalidades.CAMPO_PERSONALIZADO_CADASTRAR
              }
            />
          </GridItem>
        </FormProvider>
        <GridItem colSpan={12}>
          <PagedTable
            ref={childRef}
            loadColumnsData={paginationHandle}
            itemsTotalCount={totalRegistros}
            isLoading={isLoading}
            defaultKeyOrdered="nome"
            tableHeaders={[
              {
                key: 'Ativo',
                content: <StatusCircle hasValue={false} />,
                isOrderable: true,
                width: '37px',
              },
              {
                key: 'Nome',
                content: 'Nome do campo',
                isOrderable: true,
                width: 'auto',
              },
              {
                key: 'TipoCadastro',
                content: 'Página de exibição',
                isOrderable: true,
                width: 'auto',
              },
              {
                key: 'Acoes',
                content: 'Ações',
                isOrderable: false,
                width: '38px',
              },
            ]}
            renderTableRows={camposPersonalizados.map((campoPersonalizado) => (
              <Tr key={campoPersonalizado.id}>
                <Td>
                  <StatusCircle isActive={campoPersonalizado.ativo} />
                </Td>
                <Td>
                  <Link
                    id="link-visualizar"
                    href={getNomeLinkHref(campoPersonalizado.id)}
                  >
                    {campoPersonalizado.nome}
                  </Link>
                </Td>
                <Td>
                  {
                    TipoCadastroCampoPersonalizadoEnum.options.find(
                      (campoPersonalizadoItem) =>
                        campoPersonalizadoItem.value ===
                        campoPersonalizado.tipoCadastro
                    )?.name
                  }
                </Td>
                <Td>
                  <ActionsMenu
                    id="mostrarMais"
                    items={[
                      {
                        content: 'Editar',
                        onClick: () =>
                          handleHistoryPush(
                            SubstituirParametroRota(
                              ConstanteRotas.CAMPO_PERSONALIZADO_ALTERAR,
                              'id',
                              campoPersonalizado.id
                            )
                          ),
                        funcionalidade:
                          ConstanteFuncionalidades.CAMPO_PERSONALIZADO_ALTERAR,
                      },
                      {
                        content: 'Visualizar',
                        onClick: () =>
                          handleHistoryPush(
                            SubstituirParametroRota(
                              ConstanteRotas.CAMPO_PERSONALIZADO_VISUALIZAR,
                              'id',
                              campoPersonalizado.id
                            )
                          ),
                        funcionalidade:
                          ConstanteFuncionalidades.CAMPO_PERSONALIZADO_VISUALIZAR,
                      },
                      {
                        content: 'Remover',
                        onClick: () =>
                          excluirHandle(
                            campoPersonalizado.id,
                            campoPersonalizado.ativo
                          ),
                        funcionalidade:
                          ConstanteFuncionalidades.CAMPO_PERSONALIZADO_EXCLUIR,
                      },
                    ]}
                  />
                </Td>
              </Tr>
            ))}
          />
        </GridItem>
      </SimpleGridForm>
    </>
  );
};
