import {
  Text,
  Flex,
  Box,
  FormLabel,
  GridItem,
  Icon,
  Button,
  SimpleGrid,
} from '@chakra-ui/react';
import { FiChevronLeft } from 'react-icons/fi';
import { useFormContext } from 'react-hook-form';

import Input from 'components/PDV/Input';
import CheckboxAtivoInativo from 'components/update/Checkbox/CheckboxAtivoInativo';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { Switch } from 'components/update/Switch';
import { NumberInput } from 'components/update/Input/NumberInput';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { ContainerHeader } from 'pages/Integracoes/LojaAplicativos/Layout/ContainerHeader';

import { SalvarInserirNovoIcon } from 'icons';
import { useDeliverySectorForm } from './hooks';

type UncontrolledFormProps = Partial<{
  handleSave: () => Promise<void>;
  handleSaveAndReset: () => Promise<void>;
  isLoading: boolean;
}>;

const UncontrolledForm = ({
  handleSave,
  handleSaveAndReset,
  isLoading,
}: UncontrolledFormProps) => {
  const { listLojas, handleToDeliverySector } = useDeliverySectorForm();
  const { setValue } = useFormContext();

  const handleSelecionarLojas = (
    isChecked: boolean,
    indexPromocao: number,
    idLoja: string
  ) => {
    if (isChecked) {
      setValue(`lojas.${indexPromocao}`, idLoja);
    } else {
      setValue(`lojas.${indexPromocao}`, undefined);
    }
  };

  return (
    <>
      <ContainerHeader bg="#FF005A" gap={2} color="white" alignItems="center">
        <Box cursor="pointer" mr="10px" onClick={handleToDeliverySector}>
          <Icon fontSize="25px" as={FiChevronLeft} />
        </Box>
        <Text fontSize="16px" fontWeight="semibold">
          Setor de entrega
        </Text>
      </ContainerHeader>
      <Box position="relative" p={6}>
        {isLoading && <LoadingPadrao />}
        <SimpleGridForm gap={{ base: 6, md: 8 }}>
          <GridItem colSpan={{ base: 12, md: 6, lg: 5 }}>
            <Input
              name="descricao"
              id="descricao"
              placeholder="Informe a descrição"
              label="Descrição"
              maxLength={30}
            />
          </GridItem>
          <GridItem colSpan={{ base: 12, md: 4, lg: 5 }}>
            <NumberInput
              leftElementColor="gray.700"
              id="valor"
              label="Valor"
              editarFundoLeftElemento
              labelColor="black"
              fontWeightLabel="bold"
              bgLeftElement="gray.50"
              leftElement="R$"
              scale={2}
              name="valor"
            />
          </GridItem>
          <GridItem colSpan={{ base: 12, md: 2 }}>
            <CheckboxAtivoInativo id="ativo" name="ativo" label="Status" />
          </GridItem>
          <GridItem colSpan={12}>
            <FormLabel>Lojas habilitadas</FormLabel>
            <Box overflowX="auto" pb={{ base: 2, md: 1 }}>
              <SimpleGrid
                p={4}
                minW="min"
                bg="gray.100"
                spacing={4}
                borderRadius="base"
                borderWidth="1px"
                borderColor="gray.200"
                templateColumns="repeat(auto-fill, minmax(300px, 1fr))"
              >
                {listLojas.map((lojaItem, index) => (
                  <Flex
                    key={lojaItem.id}
                    bg="white"
                    h={{ base: 'full', md: '112px' }}
                    py="10px"
                    px="24px"
                    borderRadius="5px"
                    boxShadow="0px 0px 4px #00000029"
                  >
                    <Flex justifyContent="space-between" w="full">
                      <Box>
                        <FormLabel>{lojaItem.razaoSocial}</FormLabel>
                        <Text fontSize="12px">
                          {lojaItem?.endereco.logradouro},{' '}
                          {lojaItem?.endereco.numero} -{' '}
                          {lojaItem?.endereco.bairro} -{' '}
                          {lojaItem?.endereco.cidade} -{' '}
                          {lojaItem?.endereco.estado}
                        </Text>
                      </Box>
                      <Flex>
                        <Switch
                          size="md"
                          label=""
                          change={(event) =>
                            handleSelecionarLojas(event, index, lojaItem.id)
                          }
                          id={`lojas.${index}`}
                          name={`lojas.${index}`}
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                ))}
              </SimpleGrid>
            </Box>
          </GridItem>
        </SimpleGridForm>
        <Flex p="40px" gap={6} align="center" justify="center" flexWrap="wrap">
          {handleSaveAndReset && (
            <Button
              w={{ base: 'full', sm: 'min-content' }}
              minW="max"
              colorScheme="gray"
              variant="outlineDefault"
              leftIcon={<Icon as={SalvarInserirNovoIcon} fontSize="lg" />}
              onClick={handleSaveAndReset}
            >
              Salvar e inserir novo
            </Button>
          )}
          {handleSave && (
            <Button
              w={{ base: 'full', sm: 'min-content' }}
              minW="225px"
              colorScheme="secondary"
              onClick={handleSave}
              isDisabled={isLoading}
            >
              Salvar
            </Button>
          )}
        </Flex>
      </Box>
    </>
  );
};

export { UncontrolledForm };
