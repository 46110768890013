import { useEffect, useRef, useState } from 'react';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Box } from '@chakra-ui/react';

import { TipoOcorrenciaEnum } from 'constants/enum/tipoOcorrencia';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import isPrenvent from 'helpers/layout/isPrenvent';
import { formatDateToNow } from 'helpers/format/formatData';
import TipoContaEnum from 'constants/enum/tipoContaEnum';

import ManterFoco from 'components/Geral/ManterFoco';
import Loading from 'components/Layout/Loading/LoadingPadrao';
import { FormFooter } from 'components/update/Form/FormFooter';

import { FormularioLancamentoContas } from './Form';
import { FormData, yupResolver } from './Form/validationForm';

type PlanoContaResponse = {
  id: string;
  nome: string;
  tipoPlanoConta: number;
};

type FormaPagamentoResponse = {
  id: string;
  nome: string;
  lancarFatura: boolean;
};

type ClienteFornecedorResponse = {
  id: string;
  nome: string;
};

type ContaFinanceiraResponse = {
  id: string;
  nome: string;
};

type LancamentoFinanceiroObterBaixaViewModel = {
  juros: number;
  multa: number;
  desconto: number;
  valorTotalBaixa: number;
  dataBaixa: Date;
  contaFinanceira?: ContaFinanceiraResponse;
};

type ParcelaResponse = {
  numeroParcela: string;
  dataVencimento: Date;
  formaPagamento: FormaPagamentoResponse;
  valor: number;
  possuiBaixa: string;
};

type LancamentoFinanceiroResponse = {
  planoConta: PlanoContaResponse;
  valorTotal: number;
  formaPagamento: FormaPagamentoResponse;
  clienteFornecedor: ClienteFornecedorResponse;
  dataVencimento?: Date;
  dataCompetencia?: Date;
  numeroDocumento: string;
  dataLancamento: Date;
  historico: string;
  parcelas: ParcelaResponse[];
  movimentacaoFinanceiraBaixa: LancamentoFinanceiroObterBaixaViewModel;
};

type AlterarLancamentoContasTParams = {
  id: string;
};

interface AlterarLancamentoContasProps {
  rotaRetorno: string;
}

export function AlterarLancamentoContas({
  rotaRetorno,
}: AlterarLancamentoContasProps) {
  const history = useHistory();
  const { id } = useParams<AlterarLancamentoContasTParams>();

  const isReadOnly = history.location.pathname.includes('visualizar');

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues: {
      tipoOcorrencia: TipoOcorrenciaEnum.UNICA,
    },
  });

  const {
    formState: { errors },
  } = formMethods;

  const formaPagamento = formMethods.watch('formaPagamento');

  const [isLoading, setIsLoading] = useState(false);

  const [isPrenvented, setIsPrenvented] = useState(false);
  isPrenvent(isPrenvented);

  const [podeAlterarParcelas, setPodeAlterarParcelas] = useState(true);

  const [isAccountPaid, setIsAccountPaid] = useState<boolean>(false);

  const nameCurrentOperation = window.location.pathname.includes(
    'contas-receber'
  )
    ? TipoContaEnum.CONTA_RECEBER
    : TipoContaEnum.CONTA_PAGAR;

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    setIsLoading(true);

    const {
      clienteFornecedor,
      formaPagamento: dataFormaPagamento,
      planoConta: dataPlanoConta,
      contaFinanceira,
      tipoOcorrencia: typeOfOccurence,
      numeroDocumento,
      dataLancamento,
      historico,
      dataVencimento,
      dataCompetencia,
      juros,
      multa,
      qtdParcela,
      desconto,
      repeticoes,
      tipoCompetencia,
      formaRecebimento,
      valorTotal,
      baixarConta,
      dataBaixa,
      valorTotalBaixa,
    } = data;

    const parcelasContas = !data?.parcelas
      ? null
      : Object.entries(data.parcelas).map((item) => ({
          parcela: Number(
            item[1].parcela.slice(0, item[1].parcela.indexOf('/'))
          ),
          parcelaExibicao: item[1].parcela,
          vencimento: item[1].vencimento,
          valor: item[1].valor,
        }));

    let sendFinancialOperation = {
      operacaoId: id,
      tipoOcorrencia: typeOfOccurence,
      planoContaId: dataPlanoConta.value,
      formaPagamentoId: dataFormaPagamento.value,
      clienteFornecedorId: clienteFornecedor.value,
      valorTotal,
      numeroDocumento,
      dataLancamento: formatDateToNow(dataLancamento),
      historico,
      lancarFatura: formaPagamento.lancarFatura,
      contaUnica: {},
      contaParcelada: {},
      contaRepetir: {},
      contaFatura: {},
    };

    if (typeOfOccurence === TipoOcorrenciaEnum.UNICA) {
      sendFinancialOperation = {
        ...sendFinancialOperation,
        contaUnica: {
          dataVencimento,
          dataCompetencia,
          baixa: baixarConta
            ? {
                juros,
                multa,
                desconto,
                valorTotalBaixa,
                formaPagamentoRecebimentoId: formaRecebimento?.value,
                qtdParcela: qtdParcela?.qtdParcelas || 1,
                FormaPagamentoRecebimentoParcelaId: qtdParcela?.value,
                contaFinanceiraId: contaFinanceira?.value,
                dataBaixa,
              }
            : null,
        },
      };
    } else if (
      typeOfOccurence === TipoOcorrenciaEnum.PARCELADA &&
      !formaPagamento?.lancarFatura
    ) {
      sendFinancialOperation = {
        ...sendFinancialOperation,
        contaParcelada: {
          dataCompetencia,
          planoContaId: dataPlanoConta.value,
          parcelas: parcelasContas,
        },
      };
    } else if (typeOfOccurence === TipoOcorrenciaEnum.REPETIR) {
      sendFinancialOperation = {
        ...sendFinancialOperation,
        contaRepetir: {
          repeticoes,
          tipoCompetencia,
          dataVencimento,
          valor: valorTotal,
        },
      };
    } else if (
      formaPagamento?.lancarFatura &&
      typeOfOccurence === TipoOcorrenciaEnum.PARCELADA
    ) {
      sendFinancialOperation = {
        ...sendFinancialOperation,
        contaFatura: {
          qtdParcelas: repeticoes,
          tipoCompetencia: typeOfOccurence,
          dataVencimento,
          valor: valorTotal,
        },
      };
    }

    const response = await api.put<void, ResponseApi>(
      ConstanteEnderecoWebservice.LANCAMENTO_FINANCEIRO_ALTERAR,
      sendFinancialOperation
    );

    if (response) {
      if (response.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }

      if (response.sucesso) {
        toast.success('O cadastro foi alterado com sucesso.');

        setIsPrenvented(false);
        history.push(rotaRetorno);
      }
    }

    setIsLoading(false);
  });

  useEffect(() => {
    setIsPrenvented(formMethods.formState.isDirty);
  }, [formMethods.formState.isDirty]);

  const latestProps = useRef({
    reset: formMethods.reset,
    getValues: formMethods.getValues,
    setFocus: formMethods.setFocus,
    setValue: formMethods.setValue,
  });

  useEffect(() => {
    latestProps.current = {
      reset: formMethods.reset,
      getValues: formMethods.getValues,
      setFocus: formMethods.setFocus,
      setValue: formMethods.setValue,
    };
  });

  useEffect(() => {
    async function obterLancamentoFinanceiro() {
      setIsLoading(true);

      const response = await api.get<
        void,
        ResponseApi<LancamentoFinanceiroResponse>
      >(ConstanteEnderecoWebservice.LANCAMENTO_FINANCEIRO_OBTER, {
        params: {
          id,
        },
      });

      if (!response.sucesso) {
        history.push(rotaRetorno);
      }

      if (response) {
        if (response.avisos) {
          response.avisos.map((item: string) => toast.warning(item));
        }

        if (response.sucesso && response.dados) {
          const {
            parcelas,
            planoConta: planoContaResponse,
            valorTotal,
            formaPagamento: formaPagamentoResponse,
            clienteFornecedor: clienteFornecedorResponse,
            dataVencimento: dataVencimentoResponse,
            dataCompetencia: dataCompetenciaResponse,
            numeroDocumento,
            dataLancamento: dataLancamentoResponse,
            historico,
            movimentacaoFinanceiraBaixa,
          } = response.dados;

          latestProps.current.reset({
            tipoOcorrencia:
              (parcelas || []).length > 1
                ? TipoOcorrenciaEnum.PARCELADA
                : TipoOcorrenciaEnum.UNICA,
            planoConta: {
              label: planoContaResponse.nome,
              value: planoContaResponse.id,
              tipoPlanoConta: planoContaResponse.tipoPlanoConta,
            },
            valorTotal,
            formaPagamento: {
              label: formaPagamentoResponse.nome,
              value: formaPagamentoResponse.id,
              lancarFatura: formaPagamentoResponse.lancarFatura,
            },
            clienteFornecedor: {
              label: clienteFornecedorResponse.nome,
              value: clienteFornecedorResponse.id,
            },
            dataVencimento: dataVencimentoResponse
              ? new Date(dataVencimentoResponse)
              : undefined,
            dataCompetencia: dataCompetenciaResponse
              ? new Date(dataCompetenciaResponse)
              : undefined,
            numeroDocumento,
            dataLancamento: new Date(dataLancamentoResponse),
            historico,
            baixarConta: !!movimentacaoFinanceiraBaixa,
            juros: movimentacaoFinanceiraBaixa?.juros || 0,
            multa: movimentacaoFinanceiraBaixa?.multa || 0,
            desconto: movimentacaoFinanceiraBaixa?.desconto || 0,
            valorTotalBaixa: movimentacaoFinanceiraBaixa?.valorTotalBaixa || 0,
            dataBaixa: movimentacaoFinanceiraBaixa?.dataBaixa
              ? new Date(movimentacaoFinanceiraBaixa.dataBaixa)
              : undefined,
            contaFinanceira: movimentacaoFinanceiraBaixa?.contaFinanceira
              ? {
                  label: movimentacaoFinanceiraBaixa.contaFinanceira.nome,
                  value: movimentacaoFinanceiraBaixa.contaFinanceira.id,
                }
              : undefined,

            parcelas: (parcelas || []).map((parcela) => ({
              parcela: parcela.numeroParcela,
              valor: parcela.valor,
              vencimento: parcela.dataVencimento,
            })),
          });

          setPodeAlterarParcelas(
            !(parcelas || []).some((parcela) => parcela.possuiBaixa)
          );

          setIsAccountPaid(Boolean(movimentacaoFinanceiraBaixa));

          setIsLoading(false);
        }
      }
    }

    obterLancamentoFinanceiro();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, id, rotaRetorno]);

  return (
    <Box>
      <Prompt when={isPrenvented} message="" />

      <ManterFoco>
        {isLoading && <Loading />}

        <FormProvider {...formMethods}>
          <FormularioLancamentoContas
            estaAlterando
            podeAlterarParcelas={podeAlterarParcelas}
            errors={errors}
            nameCurrentOperation={nameCurrentOperation}
            isReadOnly={isReadOnly}
          />

          <FormFooter
            onSubmit={handleSubmit}
            isDisabled={isLoading || isReadOnly}
            isAccountPaid={isAccountPaid}
          />
        </FormProvider>
      </ManterFoco>
    </Box>
  );
}
