import React, { createContext, useState, useContext, useCallback } from 'react';
import { useDisclosure } from '@chakra-ui/react';

interface AtendimentoChatContextProps {
  isMenuOpen: boolean;
  onMenuOpen: () => void;
  onMenuClose: () => void;
  chatAccordionIndex?: number;
  setChatAccordionIndex: (newValue: number) => void;
  handleOpenMovideskChat: () => void;
}

const AtendimentoChatContext = createContext<AtendimentoChatContextProps>(
  {} as AtendimentoChatContextProps
);

interface AtendimentoChatProviderProps {
  children: React.ReactNode;
}

export default function AtendimentoChatProvider({
  children,
}: AtendimentoChatProviderProps): JSX.Element {
  const {
    isOpen: isMenuOpen,
    onOpen: onMenuOpen,
    onClose: onMenuClose,
  } = useDisclosure();

  const [chatAccordionIndex, setChatAccordionIndex] = useState<number>();

  const handleOpenMovideskChat = useCallback(() => {
    setChatAccordionIndex(0);

    onMenuOpen();
  }, [onMenuOpen]);

  return (
    <AtendimentoChatContext.Provider
      value={{
        isMenuOpen,
        onMenuOpen,
        onMenuClose,
        chatAccordionIndex,
        setChatAccordionIndex,
        handleOpenMovideskChat,
      }}
    >
      {children}
    </AtendimentoChatContext.Provider>
  );
}

export function useAtendimentoChatContext(): AtendimentoChatContextProps {
  const context = useContext(AtendimentoChatContext);

  if (!context)
    throw new Error(
      'useAtendimentoChatContext must be used within a AtendimentoChatProvider.'
    );

  return context;
}
