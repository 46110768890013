import { useCallback } from 'react';
import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  Flex,
  Box,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import {
  TabelaPrecoProps,
  useProdutosFormularioContext,
} from 'store/Produtos/ProdutosFormulario';
import auth from 'modules/auth';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';
import TipoProdutoEnum from 'constants/enum/tipoProduto';

import { NumberInput } from 'components/update/Input/NumberInput';

import { ModalPadronizarValores, FormData } from './ModalPadronizarValores';

export type OptionResponseProps = {
  id: string;
  nome: string;
};

type PrecoVendaProps = {
  tabelaPreco: TabelaPrecoProps[];
  setValoresIniciaisAlterados: React.Dispatch<React.SetStateAction<boolean>>;
};

export const PrecoVenda = ({
  tabelaPreco,
  setValoresIniciaisAlterados,
}: PrecoVendaProps) => {
  const { tipoProduto } = useProdutosFormularioContext();
  const { casasDecimais } = usePadronizacaoContext();
  const { setValue, getValues, watch } = useFormContext();

  const { id } = auth.getLoja();

  const lojaAtual = tabelaPreco.find((tabelaItem) => tabelaItem.lojaId === id);
  const listaTabelaPrecoSemPadraoSistema = tabelaPreco.filter(
    (tabelaItem) => !tabelaItem.padraoSistema
  );

  const isProdutoKit = tipoProduto === TipoProdutoEnum.PRODUTO_KIT;

  const handleModificarMarkup = useCallback(
    (lojaId: string, isTabelaPreco: boolean) => {
      const precoVenda = getValues(`valuePrecoVenda.${lojaId}`);

      if (precoVenda === 0) {
        return;
      }

      let precoCusto = 0;

      if (isTabelaPreco) {
        precoCusto = getValues(`valuePrecoCusto.${lojaAtual?.lojaId}`);
      } else {
        precoCusto = getValues(`valuePrecoCusto.${lojaId}`);
      }

      const newMarkup = (precoVenda / precoCusto - 1) * 100;

      if (newMarkup === Infinity) {
        return;
      }

      setValue(`valueMarkup.${lojaId}`, (newMarkup || 0)?.toFixed(4));
    },
    [getValues, lojaAtual, setValue]
  );

  const handleModificarPrecoVenda = useCallback(
    (lojaId: string, isTabelaPreco: boolean) => {
      const markup = getValues(`valueMarkup.${lojaId}`);
      let precoCusto = 0;

      if (isTabelaPreco) {
        precoCusto = getValues(`valuePrecoCusto.${lojaAtual?.lojaId}`);
      } else {
        precoCusto = getValues(`valuePrecoCusto.${lojaId}`);
      }

      if (precoCusto === 0 || markup === 0) {
        return;
      }

      const newPrecoVenda = (precoCusto * (1 + markup / 100))?.toFixed(2);

      setValue(`valuePrecoVenda.${lojaId}`, newPrecoVenda);
    },
    [getValues, lojaAtual, setValue]
  );

  const atualizarValoresPadronizados = useCallback(
    (data: FormData) => {
      setValoresIniciaisAlterados(true);
      listaTabelaPrecoSemPadraoSistema.forEach((item, index) => {
        const isPadronizarValores =
          data.tabelaPrecoPadronizada[index]?.isChecked;

        if (isPadronizarValores !== true) {
          return;
        }

        if (data.markup !== 0) {
          setValue(`valueMarkup.${item.lojaId}`, data.markup?.toFixed(4));
          handleModificarPrecoVenda(item.lojaId, item.isTabelaPreco);
        }
        if (data.precoVenda !== 0) {
          setValue(
            `valuePrecoVenda.${item.lojaId}`,
            data.precoVenda?.toFixed(2)
          );
          handleModificarMarkup(item.lojaId, item.isTabelaPreco);
        }
      });
    },
    [
      listaTabelaPrecoSemPadraoSistema,
      setValue,
      handleModificarMarkup,
      setValoresIniciaisAlterados,
      handleModificarPrecoVenda,
    ]
  );

  const handlePadronizarValores = () => {
    const precoCusto = getValues(`valuePrecoCusto.${lojaAtual?.lojaId}`) || 0;

    ModalPadronizarValores({
      padronizarValores: (data) => atualizarValoresPadronizados(data),
      casasDecimaisValor: casasDecimais.casasDecimaisValor,
      precoCusto,
      listTabelaPreco: listaTabelaPrecoSemPadraoSistema,
    });
  };

  return (
    <>
      <Table variant="none" minW="800px">
        <Thead>
          <Tr
            sx={{
              '& > th': {
                color: 'gray.500',
                fontWeight: 'bold',
                paddingTop: '0 !important',
                paddingBottom: '0',
                fontSize: '12px',
                paddingLeft: '0 !important',
              },
            }}
          >
            <Th w="40%">
              <Flex justifyContent="flex-start">Tabela de preços</Flex>
            </Th>
            <Th w="20%">
              <Flex justifyContent="flex-start">Markup</Flex>
            </Th>
            <Th w="20%">
              <Flex justifyContent="flex-start">Preço de venda</Flex>
            </Th>
            {listaTabelaPrecoSemPadraoSistema.length > 0 && !isProdutoKit && (
              <Th w="20%">
                <Flex
                  cursor="pointer"
                  onClick={handlePadronizarValores}
                  textDecoration="underline"
                  justifyContent="flex-end"
                  color="blue.500"
                  whiteSpace="nowrap"
                >
                  Padronizar preço
                </Flex>
              </Th>
            )}
          </Tr>
          <Tr>
            <Th
              paddingBottom="0 !important"
              paddingTop="5px"
              borderBottomWidth="1px"
              borderColor="gray.200"
              colSpan={4}
            />
          </Tr>
        </Thead>
        <Tbody>
          {listaTabelaPrecoSemPadraoSistema.map((itemTabelaPreco) => (
            <>
              <Tr
                key={itemTabelaPreco.id}
                sx={{
                  '& > td': {
                    paddingRight: '4',
                    paddingLeft: 0,
                  },
                }}
              >
                <Td w="50%" paddingLeft="0 !important">
                  <Box>
                    <Text fontWeight="semibold">
                      {itemTabelaPreco.tabelaPreco}
                    </Text>
                    <Text fontSize="12px">{itemTabelaPreco.fantasia}</Text>
                  </Box>
                </Td>
                <Td w="25%">
                  <NumberInput
                    w="96%"
                    defaultValue={itemTabelaPreco.precoVenda.markup}
                    bgLeftElement="gray.50"
                    leftElementColor="gray.700"
                    leftElementFontSize="xs"
                    leftElement="%"
                    scale={4}
                    editarFundoLeftElemento
                    precision={12}
                    onBlur={() => {
                      handleModificarPrecoVenda(
                        itemTabelaPreco.lojaId,
                        itemTabelaPreco.isTabelaPreco
                      );
                      setValoresIniciaisAlterados(true);
                    }}
                    isDisabled={isProdutoKit}
                    name={`valueMarkup.${itemTabelaPreco.lojaId}`}
                    id={`valueMarkup.${itemTabelaPreco.lojaId}`}
                  />
                </Td>
                <Td w="25%">
                  <NumberInput
                    w="96%"
                    precision={12}
                    defaultValue={itemTabelaPreco.precoVenda.precoVenda}
                    bgLeftElement="gray.50"
                    leftElement="R$"
                    leftElementColor="gray.700"
                    leftElementFontSize="xs"
                    scale={casasDecimais.casasDecimaisValor}
                    onBlur={() => {
                      handleModificarMarkup(
                        itemTabelaPreco.lojaId,
                        itemTabelaPreco.isTabelaPreco
                      );
                      setValoresIniciaisAlterados(true);
                    }}
                    isDisabled={isProdutoKit}
                    editarFundoLeftElemento
                    name={`valuePrecoVenda.${itemTabelaPreco.lojaId}`}
                    id={`valuePrecoVenda.${itemTabelaPreco.lojaId}`}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td
                  paddingBottom="0 !important"
                  paddingTop="5px"
                  borderBottomWidth="1px"
                  borderColor="gray.200"
                  colSpan={4}
                />
              </Tr>
            </>
          ))}
        </Tbody>
      </Table>
    </>
  );
};
