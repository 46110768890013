import React from 'react';
import { Icon, Text, Button, ButtonProps, HStack } from '@chakra-ui/react';
import { IconType as ReactIconsIconType } from 'react-icons/lib';

import { IconType } from 'icons';
import { FiChevronRight } from 'react-icons/fi';

interface WrapperButtonProps extends ButtonProps {
  icon: IconType | ReactIconsIconType;
  title: string;
  hasArrow?: boolean;
}

const WrapperButton: React.FC<WrapperButtonProps> = ({
  title,
  icon,
  hasArrow = false,
  ...rest
}) => {
  const isPdv = window?.location?.pathname?.includes('pdv');
  return (
    <Button
      variant="ghost"
      colorScheme="secondary"
      color={isPdv ? 'pdv.headerIconColor' : '#3d1174'}
      fontSize="xs"
      width="100%"
      h="54px"
      p="0"
      py={4}
      borderRadius={0}
      borderTopStyle="solid"
      borderTopWidth="1px"
      borderColor="pdv.modalUsuarioDivider"
      _focus={{ boxShadow: 'none', color: 'pdv.modalUsuarioDivider' }}
      _hover={{ boxShadow: 'none', color: 'pdv.modalUsuarioDivider' }}
      {...rest}
    >
      <HStack
        align="flex-end"
        justifyContent="flex-start"
        w="full"
        spacing={3}
        gap="12px"
      >
        <Icon as={icon} boxSize="5" />

        <Text fontSize="sm" fontWeight="normal">
          {title}
        </Text>
      </HStack>

      {hasArrow && <Icon as={FiChevronRight} boxSize="4" />}
    </Button>
  );
};

export default WrapperButton;
