const UnidadeFiscalEnum = {
  AMPOLA: 1,
  BALDE: 2,
  BANDEJ: 3,
  BARRA: 4,
  BISNAG: 5,
  BLOCO: 6,
  BOBINA: 7,
  BOMB: 8,
  CAPS: 9,
  CART: 10,
  CENTO: 11,
  CJ: 12,
  CM: 13,
  CM2: 14,
  CX: 15,
  CX2: 16,
  CX3: 17,
  CX5: 18,
  CX10: 19,
  CX15: 20,
  CX20: 21,
  CX25: 22,
  CX50: 23,
  CX100: 24,
  DISP: 25,
  DUZIA: 26,
  EMBAL: 27,
  FARDO: 28,
  FOLHA: 29,
  FRASCO: 30,
  GALAO: 31,
  GF: 32,
  GRAMAS: 33,
  JOGO: 34,
  KG: 35,
  KIT: 36,
  LATA: 37,
  LITRO: 38,
  M: 39,
  M2: 40,
  M3: 41,
  MILHEI: 42,
  ML: 43,
  MWH: 44,
  PACOTE: 45,
  PALETE: 46,
  PARES: 47,
  PC: 48,
  POTE: 49,
  K: 50,
  RESMA: 51,
  ROLO: 52,
  SACO: 53,
  SACOLA: 54,
  TAMBOR: 55,
  TANQUE: 56,
  TON: 57,
  TUBO: 58,
  UNID: 59,
  VASIL: 60,
  VIDRO: 61,

  properties: [
    { name: 'AMPOLA', value: 1 },
    { name: 'BALDE', value: 2 },
    { name: 'BANDEJ', value: 3 },
    { name: 'BARRA', value: 4 },
    { name: 'BISNAG', value: 5 },
    { name: 'BLOCO', value: 6 },
    { name: 'BOBINA', value: 7 },
    { name: 'BOMB', value: 8 },
    { name: 'CAPS', value: 9 },
    { name: 'CART', value: 10 },
    { name: 'CENTO', value: 11 },
    { name: 'CJ', value: 12 },
    { name: 'CM', value: 13 },
    { name: 'CM2', value: 14 },
    { name: 'CX', value: 15 },
    { name: 'CX2', value: 16 },
    { name: 'CX3', value: 17 },
    { name: 'CX5', value: 18 },
    { name: 'CX10', value: 19 },
    { name: 'CX15', value: 20 },
    { name: 'CX20', value: 21 },
    { name: 'CX25', value: 22 },
    { name: 'CX50', value: 23 },
    { name: 'CX100', value: 24 },
    { name: 'DISP', value: 25 },
    { name: 'DUZIA', value: 26 },
    { name: 'EMBAL', value: 27 },
    { name: 'FARDO', value: 28 },
    { name: 'FOLHA', value: 29 },
    { name: 'FRASCO', value: 30 },
    { name: 'GALAO', value: 31 },
    { name: 'GF', value: 32 },
    { name: 'GRAMAS', value: 33 },
    { name: 'JOGO', value: 34 },
    { name: 'KG', value: 35 },
    { name: 'KIT', value: 36 },
    { name: 'LATA', value: 37 },
    { name: 'LITRO', value: 38 },
    { name: 'M', value: 39 },
    { name: 'M2', value: 40 },
    { name: 'M3', value: 41 },
    { name: 'MILHEI', value: 42 },
    { name: 'ML', value: 43 },
    { name: 'MWH', value: 44 },
    { name: 'PACOTE', value: 45 },
    { name: 'PALETE', value: 46 },
    { name: 'PARES', value: 47 },
    { name: 'PC', value: 48 },
    { name: 'POTE', value: 49 },
    { name: 'K', value: 50 },
    { name: 'RESMA', value: 51 },
    { name: 'ROLO', value: 52 },
    { name: 'SACO', value: 53 },
    { name: 'SACOLA', value: 54 },
    { name: 'TAMBOR', value: 55 },
    { name: 'TANQUE', value: 56 },
    { name: 'TON', value: 57 },
    { name: 'TUBO', value: 58 },
    { name: 'UNID', value: 59 },
    { name: 'VASIL', value: 60 },
    { name: 'VIDRO', value: 61 },
  ],
};

export default UnidadeFiscalEnum;
