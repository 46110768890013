import React from 'react';
import { IconType as ReactIconsIconType } from 'react-icons/lib';
import { Link } from 'react-router-dom';
import {
  useToken,
  Icon as IconChakra,
  Button,
  Flex,
  Text,
} from '@chakra-ui/react';

import { useNavMenuContext } from 'store/NavMenu';

import { IconType, SemPermissaoIcon, SemPermissaoPlanoIcon } from 'icons';
import ValidarPermissao from 'components/Validacao/ValidarPermissao';

interface NavItemInterface {
  submenuKey: string;
  title: string;
  icon?: IconType | ReactIconsIconType;
  path: string;
  funcionalidade?: string;
  isDisabled?: boolean;
  handleClick?: () => void;
  blockTipoPermissao?: boolean;
}

const NavItem = ({
  submenuKey,
  title,
  icon: Icon,
  path,
  funcionalidade,
  isDisabled = false,
  handleClick,
  blockTipoPermissao = false,
}: NavItemInterface) => {
  const { setOpenMenu } = useNavMenuContext();
  const primary900 = useToken('colors', 'primary.900');

  return (
    <ValidarPermissao funcionalidade={funcionalidade} tooltipWrap="icon">
      {({ permitido, BloqueadoIcone }) => {
        const validatePermissao = () => {
          if (isDisabled) {
            return false;
          }
          if (permitido) {
            return true;
          }

          return false;
        };

        const isPermitido = validatePermissao();
        const blocked = !permitido || isDisabled;

        return (
          <Link
            to={isPermitido ? path : '#'}
            onClick={(e) => {
              if (handleClick && !isDisabled) {
                handleClick();
              }

              if (!permitido || isDisabled) {
                e.preventDefault();
              }
            }}
          >
            <Button
              variant="linkDefault"
              colorScheme="none"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="230px"
              minH="40px"
              pr="5px"
              borderRadius="none"
              bg={blocked ? 'rgba(0, 0, 0, 0.05)' : undefined}
              color={blocked ? 'primary.900' : 'primary.700'}
              padding="0 18px 0 25px"
              cursor={blocked ? 'default' : 'pointer'}
              userSelect={blocked ? 'none' : undefined}
              _hover={{
                color: !blocked ? 'primary.50' : undefined,
              }}
              id={submenuKey}
              onClick={
                isPermitido
                  ? () => {
                      setOpenMenu('');
                    }
                  : undefined
              }
            >
              <Flex
                alignItems="center"
                justifyContent="flex-start"
                height="100%"
                width="100%"
              >
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  fontSize="1.4375rem"
                  paddingRight="12px"
                  minW="24px"
                >
                  {Icon ? <Icon /> : null}
                </Flex>
                <Text
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  textAlign="left"
                  marginLeft={!Icon ? '12px' : ''}
                  fontSize="0.75rem"
                  mt="5px"
                >
                  {title}
                </Text>
              </Flex>

              {isDisabled && (
                <IconChakra
                  maxWidth="1em"
                  minHeight="1em"
                  fontSize="1.02rem"
                  as={
                    blockTipoPermissao
                      ? SemPermissaoIcon
                      : SemPermissaoPlanoIcon
                  }
                />
              )}

              {BloqueadoIcone && (
                <BloqueadoIcone
                  bloqueioPermissaoIconeStyle={{
                    fontSize: '1rem',
                    color: `${primary900}`,
                  }}
                  bloqueioPlanoIconeStyle={{
                    fontSize: '1rem',
                    color: `${primary900}`,
                    cursor: 'pointer',
                  }}
                />
              )}
            </Button>
          </Link>
        );
      }}
    </ValidarPermissao>
  );
};

export default NavItem;
