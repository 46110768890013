import { useCallback, useState, useEffect } from 'react';
import {
  Box,
  Button,
  Icon,
  MenuList,
  Menu,
  MenuButton,
  MenuItem,
  Flex,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { FiChevronDown } from 'react-icons/fi';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import ConstanteRotas from 'constants/rotas';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { moneyMask } from 'helpers/format/fieldsMasks';

import { MonthInput } from 'components/update/Input/MonthInput';
import AsyncSelect from 'components/PDV/Select/AsyncSelectPadrao';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { GraficoStatusMeta } from '../../GraficosMetasComissoes/graficoStatusMeta';
import { GraficoFaturamentoVendedor } from '../../GraficosMetasComissoes/graficoFaturamentoPorVendedor';
import { GraficoPerformanceVendedor } from '../../GraficosMetasComissoes/graficoPerformanceVendedor';
import { DesempenhoIndividualPorVendedor } from '../DesempenhoIndividualPorVendedor';
import { GraficoMetaGeralDaLoja } from '../../GraficosMetasComissoes/graficoMetaGeralDaLoja';

type TotalizadoresProps = {
  porcentagemAtingida: number;
  metaLoja: number;
  valorRestante: number;
};

type VendedorResponse = { id: string; nome: string };

type ComissaoVendedoresComPermissaoProps = {
  handleAbrirHistoricoVendas: () => void;
  handleAbrirRelatorioVendas: () => void;
};

export const ComissaoVendedoresComPermissao = ({
  handleAbrirHistoricoVendas,
  handleAbrirRelatorioVendas,
}: ComissaoVendedoresComPermissaoProps) => {
  const [totalizadores, setTotalizadores] = useState<TotalizadoresProps>(
    {} as TotalizadoresProps
  );

  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const { watch } = useFormContext();
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

  const [mesAnoWatch, vendedorWatch] = watch(['mesAno', 'vendedor']);

  const handleEditarMetasComissoes = () => {
    history.push(ConstanteRotas.CADASTRAR_METAS_E_COMISSOES);
  };

  const metaUltrapassada = totalizadores.porcentagemAtingida > 100;
  const obterTotalizadores = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<void, ResponseApi<TotalizadoresProps>>(
      ConstanteEnderecoWebservice.TOTALIZADORES_META_COMISSAO,
      {
        params: { periodo: mesAnoWatch },
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        setTotalizadores(response.dados);
      }
    }

    setIsLoading(false);
  }, [mesAnoWatch]);

  const handleGetVendedores = useCallback(async () => {
    const response = await api.get<void, ResponseApi<VendedorResponse[]>>(
      ConstanteEnderecoWebservice.VENDEDOR_LISTAR_SELECT_POR_LOJA_POR_PERIODO,
      {
        params: { periodo: mesAnoWatch },
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        return response.dados.map((vendedor) => ({
          label: vendedor.nome,
          value: vendedor.id,
        }));
      }
    }

    return [];
  }, [mesAnoWatch]);

  const valorMeta = totalizadores?.porcentagemAtingida
    ? totalizadores?.porcentagemAtingida
    : 0;

  useEffect(() => {
    obterTotalizadores();
  }, [obterTotalizadores]);

  return (
    <Box>
      {isLoading && <LoadingPadrao />}
      <Flex
        direction={isLargerThan900 ? 'row' : 'column'}
        justifyContent="space-between"
      >
        <Box
          mb={isLargerThan900 ? '' : '10px'}
          w={isLargerThan900 ? '260px' : 'full'}
        >
          <MonthInput
            name="mesAno"
            valuePadding="15px"
            isRequired
            colSpan={{ base: 12, sm: 4, md: 4, lg: 3, xl: 2 }}
          />
        </Box>
        <Flex direction={isLargerThan900 ? 'row' : 'column'}>
          <Menu>
            <MenuButton
              variant="ghost"
              borderRadius="md"
              bg="white"
              w={isLargerThan900 ? '160px' : 'full'}
              _hover={{
                background: 'white',
              }}
              _active={{
                background: 'white',
              }}
              color="gray.700"
              border="1px solid"
              borderColor="gray.200"
              as={Button}
              rightIcon={<Icon as={FiChevronDown} fontSize="xl" />}
            >
              Relatórios
            </MenuButton>
            <MenuList borderRadius="17px">
              <MenuItem onClick={() => handleAbrirHistoricoVendas()}>
                Histórico de vendas
              </MenuItem>
              <MenuItem onClick={() => handleAbrirRelatorioVendas()}>
                Relatório de comissões
              </MenuItem>
            </MenuList>
          </Menu>
          <Button
            mt={isLargerThan900 ? '' : '10px'}
            borderRadius="md"
            onClick={() => handleEditarMetasComissoes()}
            ml={isLargerThan900 ? '24px' : undefined}
            bg="blue.600"
            color="white"
            variant="success"
          >
            Editar metas e comissões
          </Button>
        </Flex>
      </Flex>

      <Flex
        direction={isLargerThan900 ? 'row' : 'column'}
        w="full"
        mt={isLargerThan900 ? '24px' : '12px'}
      >
        <Box
          mb={isLargerThan900 ? undefined : '12px'}
          mr="1.5%"
          w={isLargerThan900 ? '14.2%' : 'full'}
        >
          <Box
            boxShadow="0px 0px 6px #00000034"
            borderRadius="5px"
            h="160px"
            w="full"
            bg="white"
          >
            <Text
              pl={isLargerThan900 ? '24px' : undefined}
              pt="24px"
              pb="20px"
              textAlign={isLargerThan900 ? 'left' : 'center'}
              fontSize="16px"
              color="primary.50"
            >
              Status da meta
            </Text>
            <Flex alignItems="center" justifyContent="center">
              <GraficoStatusMeta valorMeta={valorMeta} />
            </Flex>
          </Box>
          <Box
            borderRadius="5px"
            mt={isLargerThan900 ? '16px' : '12px'}
            bg="aquamarine.300"
            w="full"
            h="58px"
          >
            <Text pt="11px" textAlign="center" fontSize="12px" color="black">
              Meta da loja
            </Text>
            <Text textAlign="center" fontSize="14px" fontWeight="bold">
              {moneyMask(totalizadores.metaLoja, true)}
            </Text>
          </Box>
          <Box
            borderRadius="5px"
            mt={isLargerThan900 ? '16px' : '12px'}
            bg="yellow.600"
            w="full"
            h="58px"
          >
            <Text pt="11px" textAlign="center" fontSize="12px" color="black">
              {!metaUltrapassada
                ? 'Falta para 100%'
                : `${(totalizadores.porcentagemAtingida - 100).toFixed(
                    2
                  )}% acima da meta`}
            </Text>
            <Text textAlign="center" fontSize="14px" fontWeight="bold">
              {moneyMask(
                metaUltrapassada
                  ? Math.abs(totalizadores.valorRestante)
                  : totalizadores.valorRestante,
                true
              )}
            </Text>
          </Box>
        </Box>

        <GraficoMetaGeralDaLoja mesAno={mesAnoWatch} />
      </Flex>
      <Flex
        direction={isLargerThan900 ? 'row' : 'column'}
        mt={isLargerThan900 ? '24px' : '12px'}
      >
        <Box
          pt="25px"
          pl="10px"
          boxShadow="0px 0px 6px #00000034"
          borderRadius="md"
          bg="white"
          maxW={isLargerThan900 ? '37%' : '100%'}
          w={isLargerThan900 ? '37%' : 'full'}
          mb={isLargerThan900 ? undefined : '12px'}
          h="304px"
        >
          <Flex>
            <Text pl="18px" fontSize="16px" color="primary.50">
              Faturamento por vendedor
            </Text>
          </Flex>
          <GraficoFaturamentoVendedor mesAno={mesAnoWatch} />
          <Box pl="18px" fontSize="12px" mt="20px">
            * Vendedores inativos
          </Box>
        </Box>
        <Box
          pt="25px"
          pl="10px"
          ml={isLargerThan900 ? '1.5%' : undefined}
          boxShadow="0px 0px 6px #00000034"
          borderRadius="md"
          bg="white"
          w={isLargerThan900 ? '61.5%' : 'full'}
          h="304px"
        >
          <Flex>
            <Text pl="18px" fontSize="16px" color="primary.50">
              Performance de metas para cada vendedor
            </Text>
          </Flex>
          <GraficoPerformanceVendedor mesAno={mesAnoWatch} />
          <Box pl="18px" fontSize="12px">
            * Vendedores inativos
          </Box>
        </Box>
      </Flex>
      <Box
        mb="16px"
        mt={isLargerThan900 ? '24px' : '12px'}
        w={isLargerThan900 ? '37%' : 'full'}
      >
        <Text color="primary.50" fontSize="16px">
          Analisar desempenho individual:
        </Text>
        <AsyncSelect
          id="vendedor"
          name="vendedor"
          placeholder="Informe o vendedor"
          handleGetOptions={handleGetVendedores}
          asControlledByObject
          isClearable
        />
      </Box>
      {vendedorWatch && (
        <Box
          borderRadius="5px"
          bg="gray.100"
          h="auto"
          p={['24px', '24px', '15px', '24px']}
        >
          <DesempenhoIndividualPorVendedor
            mesAno={mesAnoWatch}
            vendedor={vendedorWatch}
          />
          {!isLargerThan700 && <Box h="50px" />}
        </Box>
      )}
    </Box>
  );
};
