import { useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { sistemaApiKey } from 'services/sistemaApiKey';

import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import { PaginationData } from 'components/update/Pagination';
import { PagedTableForwardRefData } from 'components/update/Table/PagedTable';

type ListarDispositivosProps = {
  id: string;
  apelido: string;
  identificador: string;
  ativo: boolean;
};

export const useDispositivo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [listarDispositivos, setListarDispositivos] = useState<
    ListarDispositivosProps[]
  >([]);

  const isMountedRef = useIsMountedRef();
  const pageIsLoaded = useRef(false);
  const pagedTableRef = useRef<PagedTableForwardRefData>(null);

  const paginationHandle = useCallback(
    async (gridPaginadaConsulta: PaginationData) => {
      setIsLoading(true);
      const response = await sistemaApiKey().get<
        void,
        ResponseApi<GridPaginadaRetorno<ListarDispositivosProps>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.FRENTE_CAIXA_OBTER_DISPOSITIVO,
          gridPaginadaConsulta
        )
      );

      if (response?.sucesso && isMountedRef.current) {
        setTotalRegistros(response.dados.total);
        setListarDispositivos(response.dados.registros);
        setIsLoading(false);
      }

      if (isMountedRef.current) {
        setIsLoading(false);

        if (!pageIsLoaded.current) {
          pageIsLoaded.current = true;
        }
      }
    },
    [isMountedRef]
  );

  const alterarStatusDispositivo = useCallback(
    async (id: string, isInativar: boolean) => {
      const response = await sistemaApiKey().put<void, ResponseApi<FormData>>(
        `${
          isInativar
            ? ConstanteEnderecoWebservice.FRENTE_CAIXA_INATIVAR
            : ConstanteEnderecoWebservice.FRENTE_CAIXA_ATIVAR
        }?id=${id}`
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso: string) => toast.warning(aviso));
        }
        if (response.sucesso) {
          pagedTableRef.current?.reload();
        }
      }
    },
    []
  );

  return {
    alterarStatusDispositivo,
    isLoading,
    totalRegistros,
    listarDispositivos,
    pagedTableRef,
    paginationHandle,
  };
};
