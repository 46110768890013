import React, { ReactNode } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
} from 'react-hook-form';

type ControlledListProps = BoxProps & {
  name: string;
  renderList: (field: ControllerRenderProps<FieldValues, string>) => ReactNode;
};

const ControlledList = ({ name, renderList, ...rest }: ControlledListProps) => {
  return (
    <Controller
      name={name}
      render={({ field }) => <Box {...rest}>{renderList(field)}</Box>}
    />
  );
};

export default ControlledList;
