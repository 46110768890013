import React from 'react';
import {
  Flex,
  Box,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  useMultiStyleConfig,
  Text,
  useToken,
} from '@chakra-ui/react';

import CampoPrototipo, {
  CampoPrototipoProps,
} from 'components/PDV/Geral/CampoPrototipo';

export type CheckboxAtivoInativoProps = Omit<
  ChakraInputProps,
  'ref' | 'variant' | 'colorScheme'
> &
  CampoPrototipoProps & {
    textAtivo?: string;
    textInativo?: string;
  };

const CheckboxAtivoInativo = ({
  id,
  name,
  label,
  helperText,
  colSpan,
  colStart,
  colEnd,
  rowStart,
  rowEnd,
  rowSpan,
  width = '120px',
  maxW,
  minW,
  isRequired,
  isDisabled,
  size,
  boxSize = '7',
  fontWeightLabel = 'semibold',
  textAtivo = 'ATIVO',
  textInativo = 'INATIVO',
  ...rest
}: CheckboxAtivoInativoProps) => {
  const styles = useMultiStyleConfig('Input', { size });
  const [size1, size7] = useToken('sizes', ['1', '7']);

  return (
    <CampoPrototipo
      name={name}
      isRequired={isRequired}
      isDisabled={isDisabled}
      helperText={helperText}
      id={id}
      label={label}
      fontWeightLabel={fontWeightLabel}
      rowSpan={rowSpan}
      rowStart={rowStart}
      rowEnd={rowEnd}
      colSpan={colSpan}
      colStart={colStart}
      colEnd={colEnd}
    >
      {(_, { value, onChange }) => (
        <Flex
          onClick={isDisabled ? undefined : () => onChange(!value)}
          __css={styles.field}
          cursor={isDisabled ? 'blocked' : 'pointer'}
          border="none"
          bg={value ? 'purple.500' : 'red.400'}
          width={width}
          p="1"
          display="flex"
          position="relative"
          opacity={isDisabled ? 0.9 : 1}
        >
          <Box
            w={boxSize}
            h={boxSize}
            position="absolute"
            transition="all .2s"
            top="1"
            transform={
              value
                ? `translateX(calc(${width} - ${size7} - (${size1} * 2)))`
                : `none`
            }
            borderRadius="md"
            bg="white"
          />

          <Flex
            alignItems="center"
            justifyContent="center"
            w={value ? `calc(${width} - ${size7} - (${size1} * 2))` : 'full'}
            pl={value ? '0' : `calc(${size7} + (${size1} * 2))`}
            pr="1"
            transition="padding-left .2s"
          >
            <Text
              color="white"
              userSelect="none"
              fontSize="sm"
              fontWeight="light"
              textTransform="uppercase"
            >
              {value ? textAtivo : textInativo}
            </Text>
          </Flex>

          <ChakraInput
            {...rest}
            id={id}
            type="checkbox"
            checked={value}
            display="none"
          />
        </Flex>
      )}
    </CampoPrototipo>
  );
};

export default CheckboxAtivoInativo;
