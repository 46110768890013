import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

const schema = yup.object().shape({
  codigoRastreio: yup
    .array()
    .when('isMultipleItens', (isMultipleItens: boolean) => {
      if (isMultipleItens === false) {
        return yup.array().notRequired();
      }

      return yup
        .array()
        .of(yup.string().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO))
        .nullable()
        .default([])
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO);
    }),
  codigoRastreioSimples: yup
    .string()
    .when('isMultipleItens', (isMultipleItens: boolean) => {
      if (isMultipleItens === true) {
        return yup.string();
      }
      return yup
        .string()
        .nullable()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO);
    }),
});

export const yupResolver = yupResolverInstance(schema);
