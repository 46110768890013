import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

function formatData(date: any) {
  return new Date(date).toLocaleDateString('pt-br', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });
}

export function formatDateToNow(DateNow: Date) {
  const date = DateNow;
  const currentDate = new Date();

  date.setHours(
    currentDate.getHours(),
    currentDate.getMinutes(),
    currentDate.getSeconds()
  );

  return date.toISOString().replace(/\.\d{3}Z$/, '');
}

export function formatFullDate(value: Date) {
  if (value) {
    const date = new Date(value);

    const ano = date.getFullYear();
    const mes = date.getMonth() + 1;
    const day = date.getDate();

    return `${String(ano).padStart(4, '0')}-${String(mes).padStart(
      2,
      '0'
    )}-${String(day).padStart(2, '0')}`;
  }

  return '';
}

export const formatCurrentDateToWords = () => {
  const currentDate = new Date();

  const date = parseISO(currentDate.toISOString());
  return format(date, "dd 'de' MMMM 'de' yyyy", { locale: ptBR });
};

export const formatDateToWords = (dateString: string) => {
  const date = parseISO(dateString);
  const day = format(date, 'd', { locale: ptBR });
  const month = format(date, 'MMMM', { locale: ptBR });
  const year = format(date, 'yyyy', { locale: ptBR });

  return `${day} dia(s) do mês de ${month} de ${year}`;
};

export default formatData;
