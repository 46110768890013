import { FormProvider, useForm } from 'react-hook-form';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from 'react-virtualized';
import {
  Box,
  Button,
  Flex,
  GridItem,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri';

import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';

import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import {
  CalendarioDataCadastroIcon,
  CompartilharImprimirIcon,
  GradeTamanhosIcon,
  OutrasDespesasIcon,
  LixeiraIcon,
  OpcoesIcon,
  LupaIcon,
} from 'icons';
import { SelectVirtualized } from 'components/PDV/Select/SelectVirtualized';
import InputInteger from 'components/PDV/InputInteger';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { TextValor } from 'components/PDV/Text/TextValor';

import { FormData, formDefaultValues, yupResolver } from './validationForm';
import { useImprimirEtiquetas } from './hooks';

export function ImprimirEtiquetas() {
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const formMethods = useForm<FormData>({
    defaultValues: formDefaultValues,
    resolver: yupResolver,
  });
  const { casasDecimais } = usePadronizacaoContext();

  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: isLargerThan900 ? 50 : 120,
  });

  const {
    etiquetasProperties,
    optionsEtiquetas,
    totalRegistros,
    noProductSelected,
    listaTamanhos,
    listProductsToPrint,
    isLoading,
    isPrintingListEmpty,
    isTipoImpressoraNotSelected,
    selectedProduct,
    selectedEtiquetaIsTypeLaser,
    showFieldSize,
    isLoadingProduto,
    getProdutoCor,
    handleAbrirModalBuscarProduto,
    handleDeleteProduct,
    handleDeleteAllProducts,
    handleDecreaseProductQuantity,
    handleIncrementProductQuantity,
    handleResetPropertiesEtiquetas,
    handleAddProduct,
    handleModalGradeTamanhos,
    handleSubmitPrint,
  } = useImprimirEtiquetas({ formMethods });

  return (
    <>
      {isLoading && <LoadingPadrao />}

      <FormProvider {...formMethods}>
        <SimpleGridForm gap={6} columns={[8, 8, 8, 7, 8]}>
          <GridItem colSpan={[8, 4, 4, 2, 2]} mb="12px">
            <SelectPadrao
              id="valorEtiqueta"
              name="valorEtiqueta"
              handleChange={() => {
                handleResetPropertiesEtiquetas();
              }}
              label="Selecione a etiqueta"
              placeholder="Selecione uma etiqueta"
              options={(optionsEtiquetas || []).map(({ nome, id }) => {
                return (
                  {
                    label: nome,
                    value: id,
                  } || []
                );
              })}
            />
          </GridItem>

          {selectedEtiquetaIsTypeLaser && (
            <>
              <GridItem colSpan={[4, 2, 2, 1, 1]}>
                <Box>
                  <InputInteger
                    color="black"
                    colorScheme="secondary.300"
                    disabled={isTipoImpressoraNotSelected}
                    helperText="Informe qual linha vai começar a impressão"
                    id="linhaInicial"
                    label="Linha inicial"
                    max={etiquetasProperties?.numeroLinhas}
                    min={1}
                    name="linhaInicial"
                    scale={0}
                    isNumber
                    textAlign="left"
                  />
                </Box>
              </GridItem>
              <GridItem colSpan={[4, 2, 2, 1, 1]}>
                <Box>
                  <InputInteger
                    color="black"
                    colorScheme="secondary.300"
                    disabled={isTipoImpressoraNotSelected}
                    helperText="Informe qual Coluna vai começar a impressão"
                    id="colunaInicial"
                    label="Coluna inicial"
                    max={etiquetasProperties?.numeroColunas}
                    min={1}
                    name="colunaInicial"
                    scale={0}
                    isNumber
                    textAlign="left"
                  />
                </Box>
              </GridItem>
            </>
          )}
        </SimpleGridForm>
        <SimpleGridForm gap={6} columns={[8, 8, 8, 7, 8]} mt="18px">
          <GridItem colSpan={[8, 4, 4, 2, 2]}>
            <SelectVirtualized
              asControlledByObject
              autoFocus
              totalRegistros={totalRegistros}
              handleGetOptions={getProdutoCor}
              helperText="A pesquisa de produto considera os campos Status, Descrição, Código SKU, GTIN/EAN, Código externo e Código de barras interno."
              id="infoProdutos"
              isClearable
              label="Descrição do produto"
              name="infoProdutos"
              placeholder="Selecione o produto"
              required
              withoutDefaultOptions
              isLoading={isLoadingProduto}
            />
          </GridItem>
          {showFieldSize && (
            <>
              <GridItem colSpan={[8, 4, 4, 2, 2]}>
                <Flex alignItems="flex-end" gap="8px">
                  <Box
                    sx={{
                      width: 'calc(100% - 50px)',
                    }}
                  >
                    <SelectPadrao
                      id="tamanho"
                      label="Tamanho"
                      name="tamanho"
                      placeholder="Selecione um tamanho"
                      options={
                        selectedProduct
                          ? (listaTamanhos || []).map(({ nome, id }) => {
                              return (
                                {
                                  label: nome,
                                  value: id,
                                } || []
                              );
                            })
                          : []
                      }
                    />
                  </Box>

                  <IconButton
                    _active={{ bg: 'gray.100' }}
                    _hover={{ bg: 'gray.100' }}
                    aria-label="Selecionar grade de tamanhos"
                    bg="white"
                    border="1px"
                    borderColor="gray.200"
                    borderRadius="md"
                    color="gray.800"
                    colorScheme="whiteAlpha"
                    icon={<Icon as={GradeTamanhosIcon} fontSize="xl" />}
                    onClick={() => handleModalGradeTamanhos()}
                  />
                </Flex>
              </GridItem>
            </>
          )}
          <GridItem colSpan={[8, 4, 4, 1, 1]}>
            <Flex alignItems="flex-end" gap="8px">
              <Box
                sx={{
                  width: 'calc(100% - 50px)',
                }}
              >
                <InputInteger
                  color="black"
                  colorScheme="secondary.300"
                  id="quantidade"
                  isDisabled={noProductSelected}
                  label="Quantidade"
                  name="quantidade"
                  scale={0}
                  max={999}
                  min={1}
                  textAlign="left"
                  isNumber
                />
              </Box>

              <IconButton
                _disabled={{ bg: 'gray.100' }}
                _hover={
                  noProductSelected || isLoadingProduto
                    ? {
                        bg: 'gray.100',
                      }
                    : { bg: 'secondary.400' }
                }
                aria-label="Adicionar"
                bg={
                  noProductSelected || isLoadingProduto
                    ? 'gray.100'
                    : 'secondary.500'
                }
                borderRadius="md"
                icon={<Icon as={OutrasDespesasIcon} fontSize="xl" />}
                isDisabled={noProductSelected || isLoadingProduto}
                onClick={handleAddProduct}
              />
            </Flex>
          </GridItem>
          <GridItem
            colSpan={[8, showFieldSize ? 4 : 8, showFieldSize ? 4 : 8, 1, 1]}
            placeSelf="end stretch"
          >
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    variant="outlineDefault"
                    bg="transparent"
                    borderRadius="full"
                    padding="10px 16px"
                    height="36px"
                    justifyContent="center"
                    minW="175px"
                    width={['full', '190px']}
                    borderWidth="1px"
                    color="gray.700"
                    isActive={isOpen}
                    _active={{
                      color: 'white',
                      bg: 'gray.700',
                    }}
                    as={Button}
                    rightIcon={
                      isOpen ? (
                        <Icon as={RiArrowDropDownLine} h={7} w={7} />
                      ) : (
                        <Icon as={RiArrowDropUpLine} h={7} w={7} />
                      )
                    }
                    leftIcon={
                      <Icon
                        as={OpcoesIcon}
                        color={isOpen ? 'white' : 'black'}
                      />
                    }
                    colorScheme="gray"
                    sx={{
                      '& span': {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flex: 'none',
                      },
                    }}
                  >
                    <Text paddingLeft="2" textAlign="center">
                      Opções gerais
                    </Text>
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      onClick={() => handleAbrirModalBuscarProduto(false)}
                      icon={
                        <Icon as={CalendarioDataCadastroIcon} fontSize="xl" />
                      }
                    >
                      <Text fontSize="sm">Buscar produtos por data</Text>
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleAbrirModalBuscarProduto(true)}
                      icon={<Icon as={LupaIcon} fontSize="xl" />}
                    >
                      <Text fontSize="sm">Buscar produtos por nome</Text>
                    </MenuItem>
                  </MenuList>
                </>
              )}
            </Menu>
          </GridItem>
          <GridItem
            colSpan={[8, 8, 8, showFieldSize ? 1 : 3, showFieldSize ? 2 : 4]}
            placeSelf={[
              'end stretch',
              'end stretch',
              'end stretch',
              'end',
              'end',
            ]}
          >
            <Button
              h="36px"
              width={[
                'full',
                'full',
                'full',
                showFieldSize ? '120px' : '180px',
                '200px',
              ]}
              name="cadastrarNovo"
              color={isPrintingListEmpty ? 'gray.700' : 'white'}
              borderRadius="full"
              leftIcon={<Icon as={CompartilharImprimirIcon} boxSize="18px" />}
              type="button"
              isLoading={isLoading}
              isDisabled={isPrintingListEmpty}
              colorScheme={isPrintingListEmpty ? 'gray' : 'aquamarine'}
              variant="solid"
              onClick={() => {
                handleSubmitPrint();
              }}
            >
              Imprimir
            </Button>
          </GridItem>
        </SimpleGridForm>

        <Flex mt="22px" flexDir="column">
          <Flex
            color="black"
            flexDirection="row"
            alignItems="center"
            height="40px"
            w="full"
            bg="gray.200"
            borderTopRadius="lg"
            p="10px"
            pr="16px"
            pl="26px"
          >
            <Text w={isLargerThan900 ? '45%' : '90%'}>Produto</Text>
            <Text w="10%" textAlign={isLargerThan900 ? 'start' : 'center'}>
              {isLargerThan900 ? (
                'Tamanho'
              ) : (
                <IconButton
                  aria-label="Excluir todos os produtos"
                  variant="link"
                  color="red.500"
                  p="0.5"
                  minW="1px"
                  mr="10px"
                  borderRadius="md"
                  icon={<Icon as={LixeiraIcon} />}
                  onClick={
                    isPrintingListEmpty ? () => {} : handleDeleteAllProducts
                  }
                />
              )}
            </Text>
            {isLargerThan900 && (
              <>
                <Text w="15%">Quantidade</Text>
                <Text w="20%" textAlign="start" pl={['0px', '0px', '24px']}>
                  Preço venda
                </Text>
                <Text
                  w="10%"
                  textAlign="end"
                  mr="6px"
                  onClick={
                    isPrintingListEmpty ? () => {} : handleDeleteAllProducts
                  }
                  cursor="pointer"
                  color="red.500"
                  textDecoration="underline"
                >
                  Excluir tudo
                </Text>
              </>
            )}
          </Flex>
          {!isPrintingListEmpty ? (
            <Box h="620px" position="relative">
              <AutoSizer>
                {({ width, height }) => (
                  <List
                    width={width}
                    height={height}
                    rowHeight={cache.rowHeight}
                    deferredMeasurementCache={cache}
                    rowCount={listProductsToPrint?.length}
                    rowRenderer={({ index, key, parent, style }) => {
                      const product = listProductsToPrint[index];

                      return (
                        <CellMeasurer
                          key={key}
                          cache={cache}
                          parent={parent}
                          columnIndex={0}
                          rowIndex={index}
                        >
                          <Box style={style}>
                            <Flex
                              key={product.id}
                              height={isLargerThan900 ? '50px' : '120px'}
                              w="full"
                              mb="5px"
                              p="10px"
                              pl="26px"
                              pr="16px"
                              bg="white"
                              color="black"
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="space-between"
                              borderTopRadius={index > 0 ? 'lg' : 'none'}
                              borderBottomRadius="lg"
                            >
                              {(listProductsToPrint || []).length > 1 && (
                                <Flex>
                                  <Box
                                    bg="black"
                                    padding="0"
                                    style={{ right: '10px' }}
                                  />
                                </Flex>
                              )}
                              <Flex
                                flexDirection="row"
                                alignItems="center"
                                w="full"
                                wrap="wrap"
                              >
                                <Box
                                  w={isLargerThan900 ? '45%' : '90%'}
                                  fontWeight="semibold"
                                >
                                  {product.produto}
                                </Box>
                                <Flex
                                  w="10%"
                                  fontWeight="semibold"
                                  alignItems="center"
                                  justifyContent={
                                    isLargerThan900 ? 'flex-start' : 'flex-end'
                                  }
                                >
                                  <Text fontWeight="semibold">
                                    {product.padraoSistema
                                      ? '-'
                                      : product.tamanho}
                                  </Text>
                                </Flex>
                                <Box
                                  w={isLargerThan900 ? '15%' : '90%'}
                                  mt="5px"
                                >
                                  <Flex
                                    alignItems="flex-end"
                                    justifyContent="flex-start"
                                    gap="4px"
                                  >
                                    <Flex gap="2px">
                                      <Button
                                        height="36px"
                                        bg="white"
                                        border="1px solid"
                                        borderRadius="md"
                                        borderColor="gray.200"
                                        onClick={() => {
                                          handleDecreaseProductQuantity(
                                            product.id
                                          );
                                        }}
                                      >
                                        -
                                      </Button>
                                      <InputInteger
                                        maxW="80px"
                                        color="black"
                                        sx={{
                                          input: {
                                            textAlign: 'center',
                                            paddingLeft: '0px',
                                            paddingRight: '0px',
                                          },
                                        }}
                                        colorScheme="secondary.300"
                                        id="quantidadePorProduto"
                                        name={`quantidadePorProduto.${product.id}`}
                                        scale={0}
                                        isNumber
                                        px="0px"
                                        max={999}
                                        min={1}
                                      />
                                      <Button
                                        height="36px"
                                        bg="white"
                                        border="1px solid"
                                        borderRadius="md"
                                        borderColor="gray.200"
                                        onClick={() =>
                                          handleIncrementProductQuantity(
                                            product.id
                                          )
                                        }
                                      >
                                        +
                                      </Button>
                                    </Flex>
                                  </Flex>
                                </Box>
                                <Flex
                                  w={isLargerThan900 ? '20%' : '10%'}
                                  fontWeight="semibold"
                                  alignItems="center"
                                  pl={['0px', '0px', '24px']}
                                  justifyContent={
                                    isLargerThan900 ? 'flex-start' : 'center'
                                  }
                                >
                                  <TextValor
                                    casasDecimais={
                                      casasDecimais.casasDecimaisValor
                                    }
                                    valor={product.precoVenda || 0}
                                    color="black"
                                    fontSize="sm"
                                    fontWeight="normal"
                                    symbolFontSize="x-small"
                                  />
                                </Flex>
                                <Box
                                  w={isLargerThan900 ? '10%' : '100%'}
                                  mt="5px"
                                >
                                  <Flex
                                    justifyContent="flex-end"
                                    alignItems="center"
                                  >
                                    <IconButton
                                      mr={isLargerThan900 ? '20px' : '0px'}
                                      aria-label="Remover produto"
                                      variant="link"
                                      colorScheme="red"
                                      p="0.5"
                                      minW="1px"
                                      borderRadius="md"
                                      icon={<Icon as={LixeiraIcon} />}
                                      onClick={() =>
                                        handleDeleteProduct(product.id)
                                      }
                                    />
                                  </Flex>
                                </Box>
                              </Flex>
                            </Flex>
                          </Box>
                        </CellMeasurer>
                      );
                    }}
                  />
                )}
              </AutoSizer>
            </Box>
          ) : (
            <Flex
              justifyContent="flex-start"
              alignItems="center"
              boxShadow="md"
              borderBottomRadius="lg"
              p="10px"
              pl="26px"
              bg="white"
              h="50px"
            >
              Nenhum produto informado
            </Flex>
          )}
        </Flex>
      </FormProvider>
    </>
  );
}
