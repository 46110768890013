import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';
import { Button } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import ModelosFiscaisEnum from 'constants/enum/fiscal/modelosFiscais';
import { AmbienteFiscal } from 'constants/enum/fiscal/ambienteFiscal';
import StatusFiscaisEnum from 'constants/enum/fiscal/statusFiscal';
import PlanoContratacaoEnum from 'constants/enum/planoContratacao';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import gerarNotaFiscal from 'helpers/api/Operacao/gerarNotaFiscal';
import formatUTCToLocateDateTimeInput from 'helpers/format/formatUTCToLocateDateTimeInput';
import obterAmbienteFiscal from 'helpers/api/Loja/obterAmbienteFiscal';
import { getCurrentDateWithoutSeconds } from 'helpers/data/getCurrentDateWithoutSeconds';
import obterNotaFiscalAlterar, {
  DocumentoFiscal,
} from 'helpers/api/Operacao/obterNotaFiscalAlterar';
import auth from 'modules/auth';
import api, { ResponseApi } from 'services/api';
import { useSignalRContext } from 'store/SignalR';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';
import { useOperacoesEmitirNotaContext } from 'store/Operacoes/OperacoesEmitirNota';

import { RejeicaoInterface } from 'components/EmitirNotaFiscal/BoxRejeicao';
import { EmitirNFe, EmitirNFeData } from 'components/EmitirNotaFiscal/NFe';
import {
  ModalTransmissaoNota,
  ModalTransmissaoNotaResponse,
} from 'components/EmitirNotaFiscal/ModalTransmissaoNota';
import { formattedValueCpfCnpj } from 'helpers/format/formattedValueCpfCnpj';

import { ModalSucessoEmissao } from '../ModalSucessoEmissao';

type TParams = { id: string };

export const NFe = () => {
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [documentoFiscal, setDocumentoFiscal] = useState<DocumentoFiscal>();
  const [
    rejeicaoNotaFiscal,
    setRejeicaoNotaFiscal,
  ] = useState<RejeicaoInterface>();
  const [ambienteFiscal, setAmbienteFiscal] = useState<AmbienteFiscal>();

  const isMountedRef = useIsMountedRef();

  const { casasDecimais } = usePadronizacaoContext();
  const { hubConnection, joinGroup, exitGroup } = useSignalRContext();
  const {
    operacao,
    handlePushVoltar,
    handleObterOperacao,
  } = useOperacoesEmitirNotaContext();

  const history = useHistory();
  const { id: operacaoId } = useParams<TParams>();
  const possuiNFe =
    operacao?.NFe && operacao?.NFe?.status === StatusFiscaisEnum.Autorizada;

  const planoAtual = auth.getPlano();
  const isPlanoTeste = planoAtual === PlanoContratacaoEnum.TESTE;

  const handleEmitirNFCe = useCallback(() => {
    history.push(
      SubstituirParametroRota(
        ConstanteRotas.OPERACOES_EMITIR_NOTA_FISCAL_NFCE,
        'id',
        operacaoId
      )
    );
  }, [history, operacaoId]);

  const transmitirDocumentoFiscal = useCallback(
    async (documentoFiscalId: string) => {
      const {
        success,
        rejeicao: newRejeicao,
      }: ModalTransmissaoNotaResponse = await ModalTransmissaoNota({
        documentoFiscalId,
        hubConnection,
        joinGroup,
        exitGroup,
      });

      if (success) {
        const { finalizar, emitirNFCe } = await ModalSucessoEmissao({
          possuiNFe: true,
          possuiNFCe: !!operacao?.NFCe,
          emissaoAtual: ModelosFiscaisEnum.NFe,
        });
        if (finalizar) handlePushVoltar();
        if (emitirNFCe) handleEmitirNFCe();
      } else {
        setRejeicaoNotaFiscal(newRejeicao);
      }
    },
    [
      exitGroup,
      handleEmitirNFCe,
      handlePushVoltar,
      hubConnection,
      joinGroup,
      operacao,
    ]
  );

  const obterTodasInformações = useCallback(async () => {
    if (operacaoId) {
      setIsLoading(true);

      const newAmbienteFiscal = await obterAmbienteFiscal();
      setAmbienteFiscal(newAmbienteFiscal);

      const newDocumentoFiscal = await obterNotaFiscalAlterar(
        operacaoId,
        ModelosFiscaisEnum.NFe
      );
      setDocumentoFiscal(newDocumentoFiscal);

      setIsLoading(false);
    }
  }, [operacaoId]);

  const handleSubmit: SubmitHandler<EmitirNFeData> = async ({
    cnpjIntermediador,
    identificacaoNoIntermediador,
    dataEmissao,
    dataSaida,
    modalidadeFrete,
    dadosAdicionais,
    numeroPedidoExterno,
    transportadora,
    placaTransportadora,
    localEmbarque,
    ufEmbarque,
    ufVeiculoTransportadora,
    pesoLiquido,
    pesoBruto,
    quantidadeVolumeTransportadora,
    especieVolumeTransportadora,
    marcaVolumeTransportadora,
    numeracaoVolumeTransportadora,
    operacaoComIntermediador,
  }) => {
    if (operacaoId) {
      setIsDisabledSubmit(true);
      setIsLoading(true);

      const documentoFiscalId = await gerarNotaFiscal(
        {
          operacaoId,
          modeloFiscal: ModelosFiscaisEnum.NFe,
          dataEmissao: formatUTCToLocateDateTimeInput(dataEmissao),
          dataSaida: dataSaida
            ? formatUTCToLocateDateTimeInput(dataSaida)
            : null,
          modalidadeFrete,
          dadosAdicionais,
          numeroPedidoExterno,
          transportadoraId: transportadora?.value,
          placaTransportadora,
          localEmbarque,
          ufEmbarque,
          ufVeiculoTransportadora,
          pesoLiquido,
          pesoBruto,
          quantidadeVolumeTransportadora,
          especieVolumeTransportadora,
          marcaVolumeTransportadora,
          numeracaoVolumeTransportadora,
          operacaoComIntermediador,
          cnpjIntermediador,
          identificacaoNoIntermediador,
        },
        (aviso) => {
          try {
            const newRejeicao = JSON.parse(aviso) as RejeicaoInterface;

            setRejeicaoNotaFiscal(newRejeicao);
          } catch {
            setRejeicaoNotaFiscal({ Mensagem: aviso });
          }
        }
      );

      if (documentoFiscalId) {
        await transmitirDocumentoFiscal(documentoFiscalId);
      }

      setIsLoading(false);
      setIsDisabledSubmit(false);
    }
  };

  const handleSubmitAlterar: SubmitHandler<EmitirNFeData> = async ({
    cnpjIntermediador,
    identificacaoNoIntermediador,
    dataEmissao,
    modalidadeFrete,
    dadosAdicionais,
    numeroPedidoExterno,
    transportadora,
    placaTransportadora,
    localEmbarque,
    ufEmbarque,
    ufVeiculoTransportadora,
    pesoLiquido,
    pesoBruto,
    quantidadeVolumeTransportadora,
    especieVolumeTransportadora,
    marcaVolumeTransportadora,
    numeracaoVolumeTransportadora,
    operacaoComIntermediador,
    dataSaida,
  }) => {
    setIsDisabledSubmit(true);
    setIsLoading(true);
    if (documentoFiscal) {
      const data = {
        id: documentoFiscal.id,
        status: StatusFiscaisEnum.EmDigitacao,
        dataEmissao: formatUTCToLocateDateTimeInput(dataEmissao),
        dataSaida: dataSaida ? formatUTCToLocateDateTimeInput(dataSaida) : null,
        modalidadeFrete,
        dadosAdicionais,
        numeroPedidoExterno,
        transportadoraId: transportadora?.value,
        placaTransportadora,
        localEmbarque,
        ufEmbarque,
        ufVeiculoTransportadora,
        pesoLiquido,
        pesoBruto,
        quantidadeVolumeTransportadora,
        especieVolumeTransportadora,
        marcaVolumeTransportadora,
        numeracaoVolumeTransportadora,
        operacaoComIntermediador,
        cnpjIntermediador,
        identificacaoNoIntermediador,
      };

      const response = await api.put<void, ResponseApi>(
        ConstanteEnderecoWebservice.NOTA_FISCAL_ALTERAR_PDV,
        data
      );

      if (response.sucesso) {
        await transmitirDocumentoFiscal(documentoFiscal.id);
      }

      if (response.avisos) {
        try {
          const newRejeicao = JSON.parse(
            response.avisos[0]
          ) as RejeicaoInterface;

          setRejeicaoNotaFiscal(newRejeicao);
        } catch {
          setRejeicaoNotaFiscal({ Mensagem: response.avisos[0] });
        }
      }
    }

    setIsLoading(false);
    setIsDisabledSubmit(false);
  };

  useEffect(() => {
    obterTodasInformações();
  }, [obterTodasInformações]);

  useEffect(() => {
    handleObterOperacao();
  }, [handleObterOperacao]);

  useEffect(() => {
    if (possuiNFe && !isMountedRef.current) {
      toast.warn('Já existe uma NF-e para essa operação');
      handlePushVoltar();
    }
  }, [handlePushVoltar, isMountedRef, possuiNFe]);

  useEffect(() => {
    if (isPlanoTeste) {
      history.push(ConstanteRotas.DASHBOARD);
    }
  }, [history, isPlanoTeste]);

  return (
    <>
      <EmitirNFe
        ambienteFiscal={ambienteFiscal}
        defaultValues={
          documentoFiscal
            ? {
                numeroPedidoExterno: documentoFiscal.numeroPedidoExterno,
                dataEmissao: getCurrentDateWithoutSeconds(),
                dataSaida: null,

                operacaoComIntermediador:
                  documentoFiscal.operacaoComIntermediador,
                cnpjIntermediador: documentoFiscal.cnpjIntermediador
                  ? formattedValueCpfCnpj(
                      documentoFiscal.cnpjIntermediador,
                      false,
                      true
                    )
                  : undefined,
                identificacaoNoIntermediador:
                  documentoFiscal.identificacaoNoIntermediador,

                modalidadeFrete: documentoFiscal.modalidadeFrete,
                transportadora: documentoFiscal.transportadoraOpcaoSelect
                  ? {
                      value: documentoFiscal.transportadoraOpcaoSelect.id,
                      label: documentoFiscal.transportadoraOpcaoSelect.nome,
                    }
                  : undefined,
                placaTransportadora: documentoFiscal.placaTransportadora,
                ufVeiculoTransportadora:
                  documentoFiscal.ufVeiculoTransportadora,
                pesoLiquido: documentoFiscal.pesoLiquido,
                pesoBruto: documentoFiscal.pesoBruto,
                quantidadeVolumeTransportadora:
                  documentoFiscal.quantidadeVolumeTransportadora,
                especieVolumeTransportadora:
                  documentoFiscal.especieVolumeTransportadora,
                marcaVolumeTransportadora:
                  documentoFiscal.marcaVolumeTransportadora,
                numeracaoVolumeTransportadora:
                  documentoFiscal.numeracaoVolumeTransportadora,

                dadosAdicionais: documentoFiscal.dadosAdicionais,
              }
            : undefined
        }
        casasDecimaisQuantidade={casasDecimais.casasDecimaisQuantidade}
        onSubmit={documentoFiscal ? handleSubmitAlterar : handleSubmit}
        rejeicaoNotaFiscal={rejeicaoNotaFiscal}
        isLoading={isLoading}
        isDisabledSubmit={isDisabledSubmit}
      >
        <Button
          size="sm"
          w="full"
          minW="96px"
          maxW={['full', 'full', '120px']}
          variant="outline"
          onClick={handlePushVoltar}
        >
          Voltar
        </Button>
      </EmitirNFe>
    </>
  );
};
