import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
body {
  font-family: 'Nunito', sans-serif;
  overflow: auto;
  background-color: var(--sti-ck-colors-gray-50);
}

/* Para cores que irão usar transparência, gerar uma variavel em rgb
para que a sintaxe "rgba(var(--cor-rgb), 0.5)" possa ser usada.

Para transformar uma cor HEX em RGB, use o https://www.google.com/search?q=color+picker.
Cole o hex no primeiro campo da ferramenta e copie o RGB abaixo. */

:root {
  --layout-menus-height: 46px;

  --slide-menu-width: 150px;

  --slide-menu-padding: 11px;

  --transparent: transparent;

  --current: currentColor;

  --white: #ffffff;
  --white-alpha-50: rgba(255, 255, 255, 0.04);
  --white-alpha-100: rgba(255, 255, 255, 0.06);
  --white-alpha-200: rgba(255, 255, 255, 0.08);
  --white-alpha-300: rgba(255, 255, 255, 0.16);
  --white-alpha-400: rgba(255, 255, 255, 0.24);
  --white-alpha-500: rgba(255, 255, 255, 0.36);
  --white-alpha-600: rgba(255, 255, 255, 0.48);
  --white-alpha-700: rgba(255, 255, 255, 0.64);
  --white-alpha-800: rgba(255, 255, 255, 0.80);
  --white-alpha-900: rgba(255, 255, 255, 0.92);

  --black: #000000;
  --black-alpha-50: rgba(0, 0, 0, 0.04);
  --black-alpha-100: rgba(0, 0, 0, 0.06);
  --black-alpha-200: rgba(0, 0, 0, 0.08);
  --black-alpha-300: rgba(0, 0, 0, 0.16);
  --black-alpha-400: rgba(0, 0, 0, 0.24);
  --black-alpha-500: rgba(0, 0, 0, 0.36);
  --black-alpha-600: rgba(0, 0, 0, 0.48);
  --black-alpha-700: rgba(0, 0, 0, 0.64);
  --black-alpha-800: rgba(0, 0, 0, 0.80);
  --black-alpha-900: rgba(0, 0, 0, 0.92);

  --focus-shadow: 0 0 0 0.1875rem rgba(0, 0, 0, 0.04);

  --dashed-border-bg: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23CCCCCCFF' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}


button {
  &:active,
  &:focus,
  &:focus-within,
  &:visited {
    outline: none;
    box-shadow: none;
  }
}

.title-logo {
  margin: 0;
  color: var(--black);
}

.title-logo span {
  font-weight: 800;
}


.Toastify__toast-container--top-right {
  margin-top: var(--layout-menus-height);
}

.Toastify__toast-body {
  white-space: pre-line;
}

.Toastify__toast--warning { background-color: var(--sti-ck-colors-orange-400); color:var(--black) }
.Toastify__toast--error { background-color: var(--sti-ck-colors-red-400); }
.Toastify__toast--success { background-color: #A8E303; color:var(--black) }

button {
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
  opacity: 0.6;
}

button.btn-outline-secondary {
  color: var(--sti-ck-colors-gray-700);
  border-color: var(--sti-ck-colors-gray-700);

  &:hover,
  &:focus,
  &:active,
  &:visited {
    background: transparent !important;
    color: var(--sti-ck-colors-gray-700) !important;
  }
}

.form-control {
  height: 35px;
  min-height: 35px;
  resize: vertical;

  border-color: var(--sti-ck-colors-gray-100);

  background-color: var(--white);
}

.form-control:focus {
  border-color: var(--sti-ck-colors-primary-50);
  box-shadow: var(--focus-shadow);
}

.input-group {
  height: 35px;

  .form-control {
    height: 35px !important;
  }
}

.form-group > .dropdown {
  height: 35px;

  > button {
    height: 35px;
  }
}

@media (max-width: 1290px) {
  body {
    overflow: auto;
  }
}

.swal2-actions {
  margin-top: 40px;
}

.btn-modal-confirm {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 17px !important;

  font-size: 1rem;

  height: 40px;

  box-shadow: var(--focus-shadow);

  & ~ button {
    margin-left: 40px;
  }

  &:active,
  &:focus {
    box-shadow: var(--focus-shadow);
  }
}

.btn-modal-cancel {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 17px !important;

  font-size: 1rem;

  height: 40px;

  box-shadow: 0px 7px 6px 0px rgba(255, 26, 26, 0.5);

  &:active,
  &:focus {
    box-shadow: 0px 7px 6px 0px rgba(255, 26, 26, 0.5);
  }
}

.input-group-prepend {
  width: 35px;

  > .input-group-text {
    width: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 0.6875rem;
  }
}

.swal2-shown #root > .fullscreen > div:first-child,
.fullscreen.modal-open > div:first-child {
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -o-filter: blur(1px);
  -ms-filter: blur(1px);
  filter: blur(1px);
}

.opacity-ghost{
  background: var(--orange-50) !important;
  transform: rotate(0.5deg);
  margin-top: 5px;
}

.dropPlaceholder{
  background-color:rgba(150,150,200,.1);
  border:1px dashed #abc;

  margin-bottom: 5px;
}

.smooth-dnd-draggable-wrapper {
  overflow: unset !important;
  display: flex !important;
}

.tooltip-inner {
  text-align: left;
  max-width: 210px;
}

.dataAlteracaoCriacaoTooltip > .tooltip-inner {
  max-width: 260px;
}

.alterarProdutoDuranteVendaCadastroTooltip > .tooltip-inner {
  max-width: 500px;
}

.row,
.form-row {
  display: flex;
  flex-wrap: wrap;

  margin-right: -12px;
  margin-left: -12px;
  margin: -12px;

  @media (min-width: 900px) {
    margin-right: -15px;
    margin-left: -15px;
    margin: -15px;
  }
  @media (min-width: 1200px) {
    margin-right: -17px;
    margin-left: -17px;
    margin: -17px;
  }
  @media (min-width: 1800px) {
    margin-right: -22px;
    margin-left: -22px;
    margin: -22px;
  }
}

.row + .row,
.row + .form-row,
.form-row + .form-row,
.form-row + .row {
  margin-top: 12px;

  @media (min-width: 900px) {
    margin-top: 15px;
  }
  @media (min-width: 1200px) {
    margin-top: 17px;
  }
  @media (min-width: 1800px) {
    margin-top: 22px;
  }
}

.no-gutters {
  margin: 0px !important;

  & > .col,
  & > [class*='col-'] {
    padding: 0px !important;
  }
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;

  padding-right: 12px;
  padding-left: 12px;
  padding: 12px;

  @media (min-width: 900px) {
    padding-right: 15px;
    padding-left: 15px;
    padding: 15px;
  }
  @media (min-width: 1200px) {
    padding-right: 17px;
    padding-left: 17px;
    padding: 17px;
  }
  @media (min-width: 1800px) {
    padding-right: 22px;
    padding-left: 22px;
    padding: 22px;
  }
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 700px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 900px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1800px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

/* .modal-body {
  .col {
    padding-top: 0;
  }
} */

.react-select__single-value, .react-select__placeholder {
  line-height: 1em;

  height: 100%;
  display: flex;
  align-items: center;
}

.react-select__option--is-focused {
  background-color: var(--sti-ck-colors-gray-50) !important;
}

.btn-outline-blue {
  color: var(--sti-ck-colors-blue-500);
  background-color: transparent;
  border-color: var(--sti-ck-colors-blue-500);
}

.btn-outline-blue:hover {
  box-shadow: 0 4px 20px 1px rgba(12, 97, 255, 0.3),
      0 1px 4px rgba(12, 97, 255, 0.3);
}

.btn-outline-blue.disabled,
.btn-outline-blue:disabled {
  filter: brightness(90%);
}

.btn-outline-blue:not(:disabled):not(.disabled):active,
.btn-outline-blue:not(:disabled):not(.disabled).active,
.show > .btn-outline-blue.dropdown-toggle {
  box-shadow: 0 0 0 0.2rem rgba(12, 97, 255, 0.3) !important;
}

.btn-outline-blue:not(:disabled):not(.disabled):active:focus,
.btn-outline-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(24, 150, 227, 0.5);
}

.arrow-top {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--sti-ck-colors-gray-50);
}

.btn-success {
  color: var(--white);
  background-color: var(--sti-ck-colors-aquamarine-600) !important;
  border-color: var(--sti-ck-colors-aquamarine-600) !important;
}

.btn-success:hover {
  color: var(--white);
  background-color: var(--sti-ck-colors-aquamarine-600) !important;
  border-color: var(--sti-ck-colors-aquamarine-600) !important;
}

.btn-success:focus,
.btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 179, 137, 0.5) !important;
  background-color: var(--sti-ck-colors-aquamarine-600) !important;
  border-color: var(--sti-ck-colors-aquamarine-600) !important;
}

.btn-success.disabled,
.btn-success:disabled {
  color: var(--white);
  background-color: var(--sti-ck-colors-aquamarine-600) !important;
  border-color: var(--sti-ck-colors-aquamarine-600) !important;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: var(--white);
  background-color: var(--sti-ck-colors-aquamarine-600) !important;
  border-color: var(--sti-ck-colors-aquamarine-600) !important;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 179, 137, 0.5) !important;
}

.btn-secondary {
  color: var(--sti-ck-colors-primary-700) !important;
  background-color: var(--sti-ck-colors-secondary-400) !important;
  border-color: var(--sti-ck-colors-secondary-400) !important;
}

.btn-secondary:hover {
  color: var(--sti-ck-colors-primary-700) !important;
  background-color: var(--sti-ck-colors-secondary-400) !important;
  border-color: var(--sti-ck-colors-secondary-400) !important;
}

.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(168, 227, 3, 0.5) !important;
  background-color: var(--sti-ck-colors-secondary-400) !important;
  border-color: var(--sti-ck-colors-secondary-400) !important;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: var(--sti-ck-colors-primary-700) !important;
  background-color: var(--sti-ck-colors-secondary-400) !important;
  border-color: var(--sti-ck-colors-secondary-400) !important;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: var(--sti-ck-colors-primary-700) !important;
  background-color: var(--sti-ck-colors-secondary-400) !important;
  border-color: var(--sti-ck-colors-secondary-400) !important;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(168, 227, 3, 0.5) !important;
}

.modal-backdrop-overflow-chakra {
  z-index: 1400;
}


input:-internal-edge-excel-like-autofill-previewed {
  color: white !important;
}
`;
