import { useCallback, useEffect, useState } from 'react';
import { Box, ModalProps } from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';

import obterOperacaoParaCompartilhar, {
  OperacaoObterParaCompartilhar,
} from 'helpers/api/Operacao/obterOperacaoParaCompartilhar';
import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';
import { enumTypeCarne } from 'constants/enum/enumTypeCarne';
import StatusFiscaisEnum from 'constants/enum/fiscal/statusFiscal';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import ImpressaoCupomNaoFiscal from 'components/Impressao/ImpressaoCupomNaoFiscal/ImpressaoCupomNaoFiscal';
import ImpressaoNFCe from 'components/Impressao/ImpressaoNFCe/ImpressaoNFCe';
import ImpressaoCarnePromissoria from 'components/Impressao/Carne';

import { ModalCompartilhar, ItemsProps } from '..';
import { useCompartilharOperacoes } from './hooks';

type CompartilharOperacaoProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalProps> & {
    operacaoId?: string;
    isOpen: boolean;
    hasComprovanteValidadeFiscal?: boolean;
    hasComprovanteA4?: boolean;
    hasComprovanteMeiaFolha?: boolean;
    hasDanfe?: boolean;
    hasCupomFiscal?: boolean;
    itemsImpressao?: ItemsProps[];
    isVisibleWhatsApp?: boolean;
  };

export const CompartilharOperacao = create<
  CompartilharOperacaoProps,
  ModalProps
>(
  ({
    operacaoId,
    isVisibleWhatsApp = true,
    hasComprovanteValidadeFiscal = true,
    hasComprovanteA4 = true,
    hasComprovanteMeiaFolha = true,
    hasDanfe = true,
    isOpen = false,
    hasCupomFiscal = true,
    itemsImpressao = [],
  }) => {
    const [
      valueOperacao,
      setValueOperacao,
    ] = useState<OperacaoObterParaCompartilhar>(
      {} as OperacaoObterParaCompartilhar
    );

    const {
      isLoading,
      impressaoCarneContainer,
      impressaoCarneRef,
      cupomNaoFiscalRef,
      cupomNaoFiscalContainerRef,
      cupomFiscalRef,
      cupomFiscalContainerRef,
      isOpenModal,
      typeCarne,
      setIsLoading,
      handleImpressaoCarne,
      handleImprimirCupomNaoFiscal,
      handleImprimirCupomFiscal,
      handleEnviarWhatsAppCupomFiscal,
      handleDownloadXMLCupomFiscal,
      handleImprimirComprovanteA4,
      handleImprimirDanfe,
      handleImpressaoMeiaFolha,
      handleDownloadXMLDanfe,
      handleOpenModalEmail,
      handleOpenModalWhatsApp,
    } = useCompartilharOperacoes();

    const openModalCompartilhar = useCallback(
      async (operacao: OperacaoObterParaCompartilhar) => {
        const isNFe = operacao?.NFe;
        const isNFCe = operacao?.NFCe;

        const hasOperacaoId = operacaoId || '';
        const isVenda =
          operacao?.identificacaoTipoOperacao ===
          IdentificacaoTipoOperacaoEnum.VENDA;

        const podeCompartilharNFe =
          operacao &&
          isNFe &&
          (isNFe.status === StatusFiscaisEnum.Autorizada ||
            isNFe.status === StatusFiscaisEnum.Cancelada);

        const podeCompartilharNFCe =
          operacao &&
          isNFCe &&
          (isNFCe?.status === StatusFiscaisEnum.Autorizada ||
            isNFCe?.status === StatusFiscaisEnum.Cancelada);

        const optionsImpressao = [
          hasComprovanteValidadeFiscal && {
            titulo: 'Comprovante sem validade fiscal',
            onClickImpressao: (onClose) => {
              onClose();
              handleImprimirCupomNaoFiscal();
            },
            onClickEmail: (onClose) => {
              handleOpenModalEmail(operacao, undefined, hasOperacaoId);
              onClose();
            },
            onClickWhatsApp: isVisibleWhatsApp
              ? (onClose) => {
                  onClose();
                  handleOpenModalWhatsApp(operacao, hasOperacaoId);
                }
              : undefined,
          },
          hasComprovanteA4 && {
            titulo: 'Comprovante sem validade fiscal A4',
            onClickDirect: (onClose) => {
              handleImprimirComprovanteA4(hasOperacaoId);
              onClose();
            },
          },

          hasComprovanteMeiaFolha && {
            titulo: 'Impressão meia folha',
            onClickDirect: (onClose) => {
              handleImpressaoMeiaFolha(hasOperacaoId);
              onClose();
            },
          },
          isNFe &&
            hasDanfe &&
            podeCompartilharNFe && {
              titulo: 'Danfe',
              onClickImpressao: (onClose) => {
                onClose();
                handleImprimirDanfe(isNFe);
              },
              onClickEmail: (onClose) => {
                onClose();
                handleOpenModalEmail(operacao, isNFe, undefined);
              },
              onClickDownload: (onClose) => {
                onClose();
                handleDownloadXMLDanfe(isNFe);
              },
            },
          isNFCe &&
            podeCompartilharNFCe &&
            hasCupomFiscal && {
              titulo: 'Cupom fiscal',
              onClickImpressao: (onClose) => {
                onClose();
                handleImprimirCupomFiscal();
              },
              onClickEmail: (onClose) => {
                onClose();
                handleOpenModalEmail(operacao, isNFCe, undefined);
              },
              onClickWhatsApp: isVisibleWhatsApp
                ? (onClose) => {
                    onClose();
                    handleEnviarWhatsAppCupomFiscal(
                      isNFCe,
                      hasOperacaoId,
                      operacao
                    );
                  }
                : undefined,
              onClickDownload: (onClose) => {
                onClose();
                handleDownloadXMLCupomFiscal(isNFCe);
              },
            },
          isVenda && {
            titulo: 'Impressão carnê normal',
            onClickDirect: async () => {
              await handleImpressaoCarne(enumTypeCarne.NORMAL);
            },
          },
          isVenda && {
            titulo: 'Impressão carnê promissória',
            onClickDirect: async () => {
              await handleImpressaoCarne(enumTypeCarne.PROMISSORIA);
            },
          },
          ...itemsImpressao,
        ] as ItemsProps[];

        await ModalCompartilhar({
          items: optionsImpressao.filter(Boolean),
        });
      },
      [
        operacaoId,
        hasComprovanteValidadeFiscal,
        isVisibleWhatsApp,
        hasComprovanteA4,
        hasComprovanteMeiaFolha,
        hasDanfe,
        hasCupomFiscal,
        itemsImpressao,
        handleImprimirCupomNaoFiscal,
        handleOpenModalEmail,
        handleOpenModalWhatsApp,
        handleImprimirComprovanteA4,
        handleImpressaoMeiaFolha,
        handleImprimirDanfe,
        handleDownloadXMLDanfe,
        handleImprimirCupomFiscal,
        handleEnviarWhatsAppCupomFiscal,
        handleDownloadXMLCupomFiscal,
        handleImpressaoCarne,
      ]
    );

    useEffect(() => {
      async function handleObterOperacaoParaCompartilhar() {
        setIsLoading(true);

        if (operacaoId && isOpen && isOpenModal.current) {
          const newOperacao = await obterOperacaoParaCompartilhar(operacaoId);

          if (newOperacao) {
            openModalCompartilhar(newOperacao);
            setValueOperacao(newOperacao);
            isOpenModal.current = false;
          }
        }

        setIsLoading(false);
      }

      handleObterOperacaoParaCompartilhar();

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, operacaoId]);

    return (
      <>
        {isLoading && <LoadingPadrao />}
        <Box display="none">
          <ImpressaoCupomNaoFiscal
            ref={cupomNaoFiscalRef}
            containerRef={cupomNaoFiscalContainerRef}
            operacaoId={operacaoId}
          />

          <ImpressaoNFCe
            ref={cupomFiscalRef}
            containerRef={cupomFiscalContainerRef}
            documentoFiscalId={valueOperacao?.NFCe?.id || ''}
            operacao={{
              id: operacaoId || '',
              numeroOperacao: valueOperacao?.numeroOperacao,
            }}
          />
          <ImpressaoCarnePromissoria
            operacaoId={operacaoId}
            ref={impressaoCarneRef}
            componentRef={impressaoCarneContainer}
            type={typeCarne}
          />
        </Box>
      </>
    );
  }
);
