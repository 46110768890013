import { Text, Flex, Tr, Td, Checkbox } from '@chakra-ui/react';

import currency from 'currency.js';

import {
  ForwardRefData,
  LoadMoreRowsParams,
  TableHeader,
  VirtualizedInfiniteTable,
} from 'components/update/Table/VirtualizedInfiniteTable';
import { ProdutoSelecionadoProps } from 'pages/PDV/TrocarProdutos/Types/validationForm';

type EscolherProdutosParaDevolucaoProps = {
  isLoading: boolean;
  produtoRef: React.RefObject<ForwardRefData>;
  listaProduto: ProdutoSelecionadoProps[];
  itemsTotalCount: number;
  loadMoreRowsProduct: ({
    currentPage,
    pageSize,
    orderColumn,
    orderDirection,
  }: LoadMoreRowsParams) => Promise<void>;
  handleToggleSelecionarTodosItens(): void;
  selecionarTodosProdutos: boolean;
  handleToggleSelecionarProdutoTroca(index: number): void;
};

export const EscolherProdutosParaDevolucao = ({
  isLoading,
  produtoRef,
  listaProduto,
  itemsTotalCount,
  loadMoreRowsProduct,
  handleToggleSelecionarTodosItens,
  selecionarTodosProdutos,
  handleToggleSelecionarProdutoTroca,
}: EscolherProdutosParaDevolucaoProps) => {
  const produtosTableHeader: TableHeader[] = [
    {
      key: 'select',
      content: (
        <Checkbox
          onChange={() => handleToggleSelecionarTodosItens()}
          colorScheme="primary"
          isChecked={selecionarTodosProdutos}
          mb="0"
        />
      ),
      width: '40px',
      whiteSpace: 'nowrap',
    },
    { key: 'produto', content: 'Produto', width: '62%' },
    {
      key: 'quantidade',
      content: 'Quantidade',
      width: '250px',
      isNumeric: true,

      whiteSpace: 'nowrap',
    },
    {
      key: 'valor',
      content: 'Valor unitário',
      isNumeric: true,
      whiteSpace: 'nowrap',
    },
  ];
  return (
    <VirtualizedInfiniteTable
      ref={produtoRef}
      boxShadow="null"
      alterarBordaListagem="white"
      isUpdateWidthTable
      paddingRight="10px"
      color="gray.300"
      isMobileProdutoTroca
      isEdicaoManual
      variant="outline"
      isLoading={isLoading}
      withoutRowsMessage="Adicione ao menos um produto para avançar na conferência."
      orderColumn="Nome"
      visibleItemsCount={7}
      pageSize={20}
      tableHeaders={produtosTableHeader}
      rowRenderer={({ index, style: { height, ...restStyle }, key }) => {
        const produto = listaProduto[index];

        if (!produto) {
          return null;
        }

        return (
          <>
            <Tr position="relative" key={key} style={restStyle}>
              <Td>
                <Checkbox
                  position="absolute"
                  top="3"
                  left="1"
                  colorScheme="primary"
                  onChange={() => handleToggleSelecionarProdutoTroca(index)}
                  isChecked={produto.isChecked}
                  mb="0"
                />
              </Td>

              <Td width="66%">
                <Flex ml="7px" position="absolute" left="12" top="3" w="full">
                  <Text fontSize="14px">{produto.nome}</Text>
                </Flex>
              </Td>
              <Td width={produtosTableHeader[2].width}>
                <Text lineHeight="0" opacity={0}>
                  {produtosTableHeader[2].content}
                </Text>
                <Text fontSize="14px">{produto.quantidade}</Text>
              </Td>
              <Td width={produtosTableHeader[3].width} isNumeric>
                <Text
                  position="absolute"
                  top="3"
                  right="6"
                  w="130px"
                  fontSize="14px"
                >
                  {currency(produto.valor).format({
                    precision: 2,
                    separator: '.',
                    decimal: ',',
                    symbol: 'R$ ',
                  })}
                </Text>
              </Td>
            </Tr>
          </>
        );
      }}
      rowCount={itemsTotalCount}
      isRowLoaded={({ index }) => !!listaProduto[index]}
      loadMoreRows={loadMoreRowsProduct}
    />
  );
};
