import { useCallback, useRef } from 'react';
import {
  List,
  AutoSizer,
  CellMeasurerCache,
  CellMeasurer,
} from 'react-virtualized';
import { Box, BoxProps, Flex, Checkbox, Text } from '@chakra-ui/react';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { ProductProps } from './ModalEtapa/Cadastrar/validationForm';

type ListProductsVirtualizedProps = Omit<BoxProps, 'children'> & {
  listProducts: ProductProps[];
  setListProducts: (products: ProductProps[]) => void;
  isLoading?: boolean;
};

const ListProductsVirtualized = ({
  listProducts,
  setListProducts,
  isLoading,
  ...rest
}: ListProductsVirtualizedProps) => {
  const isAllSelected = listProducts.every(({ isSelected }) => isSelected);

  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 35,
    })
  );

  const toogleAll = useCallback(
    (value: boolean) => {
      const newProducts = listProducts.map((product) => ({
        ...product,
        isSelected: value,
      }));

      setListProducts(newProducts);
    },
    [listProducts, setListProducts]
  );

  const toogleProductIsSelected = useCallback(
    (index: number) => {
      const newProducts = listProducts.map((product, i) => {
        if (i === index) {
          return {
            ...product,
            isSelected: !product.isSelected,
          };
        }
        return product;
      });

      setListProducts(newProducts);
    },
    [listProducts, setListProducts]
  );

  return (
    <Box
      w="full"
      h="350px"
      borderWidth="1px"
      borderColor="gray.200"
      borderRadius="5px"
      py={1}
      sx={{
        '& .list-component::-webkit-scrollbar-thumb': {
          background: 'gray.100',
        },
        '& .list-component::-webkit-scrollbar-thumb:hover': {
          background: 'gray.200',
        },
      }}
      {...rest}
    >
      <Flex
        w="full"
        h="35px"
        px={2}
        gap={2}
        align="center"
        cursor="pointer"
        onClick={() => toogleAll(!isAllSelected)}
      >
        <Checkbox
          mb="0"
          colorScheme="primary"
          isChecked={isAllSelected}
          pointerEvents="none"
        />
        <Text>Selecionar todos os produtos</Text>
      </Flex>
      <Box h="300px">
        {isLoading && <LoadingPadrao />}
        <AutoSizer>
          {({ width, height }) => (
            <List
              className="list-component"
              width={width}
              height={height}
              rowHeight={cache.current.rowHeight}
              deferredMeasurementCache={cache.current}
              rowCount={listProducts.length}
              rowRenderer={({ index, key, parent, style }) => {
                const product = listProducts[index];

                const { isSelected } = product;

                return (
                  <CellMeasurer
                    key={key}
                    cache={cache.current}
                    parent={parent}
                    columnIndex={0}
                    rowIndex={index}
                  >
                    <Box style={style}>
                      <Flex
                        w="99%"
                        p={1}
                        mb="3px"
                        px={2}
                        gap={2}
                        borderRadius="5px"
                        align="center"
                        borderWidth="1px"
                        cursor="pointer"
                        bg={isSelected ? 'purple.100' : 'white'}
                        borderColor={isSelected ? 'purple.300' : 'gray.200'}
                        onClick={() => toogleProductIsSelected(index)}
                      >
                        <Checkbox
                          m="0"
                          colorScheme="primary"
                          isChecked={product.isSelected}
                          pointerEvents="none"
                        />
                        <Text>{product.nome}</Text>
                      </Flex>
                    </Box>
                  </CellMeasurer>
                );
              }}
            />
          )}
        </AutoSizer>
      </Box>
    </Box>
  );
};

export default ListProductsVirtualized;
