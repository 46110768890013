import { toast } from 'react-toastify';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';
import auth from 'modules/auth';
import StatusConsultaEnum from 'constants/enum/statusConsulta';
import OptionType from 'types/optionType';

import { LocaisEstoqueResponse } from 'pages/Integracoes/LojaAplicativos/SmartPos/Etapas/LocalEstoque/validationForm';

export const getLocalEstoque = async () => {
  const lojaId = auth.getLoja().id;

  const params = {
    statusConsulta: StatusConsultaEnum.ATIVOS,
    lojaId,
  };

  const response = await api.get<void, ResponseApi<LocaisEstoqueResponse[]>>(
    ConstanteEnderecoWebservice.LOCAL_ESTOQUE_LISTAR_SELECT,
    { params }
  );

  if (response) {
    if (response.avisos) {
      response.avisos.forEach((aviso: string) => toast.warning(aviso));
    }

    if (response.sucesso && response.dados) {
      const newLocalEstoqueOptions = response.dados.map((option) => ({
        value: option.id,
        label: option.nome,
      }));

      return newLocalEstoqueOptions;
    }
  }
  return [];
};

export const getLocalEstoquePadrao = async (options: OptionType[]) => {
  const response = await api.get<void, ResponseApi<string>>(
    ConstanteEnderecoWebservice.LOCAL_ESTOQUE_OBTER_PADRAO_SISTEMA
  );
  if (response) {
    if (response.avisos) {
      response.avisos.forEach((aviso: string) => toast.warning(aviso));
    }
    if (response.sucesso && response.dados) {
      const localEstoqueDefaultValue = options.find(
        (option) => option.value === response.dados
      );
      if (localEstoqueDefaultValue) {
        return localEstoqueDefaultValue;
      }
    }
  }
  return undefined;
};
export const getLocalEstoqueSmartPos = async () => {
  const response = await api.get<void, ResponseApi<string>>(
    ConstanteEnderecoWebservice.INTEGRACAO_OBTER_LOCAL_ESTOQUE
  );
  if (response) {
    if (response.avisos) {
      response.avisos.forEach((aviso: string) => toast.warning(aviso));
    }
    if (response.sucesso && response.dados) {
      return response.dados;
    }
  }
  return undefined;
};

export const getLocalEstoqueTray = async () => {
  const response = await api.get<void, ResponseApi<string>>(
    ConstanteEnderecoWebservice.INTEGRACAO_TRAY_OBTER_LOCAL_ESTOQUE
  );
  if (response) {
    if (response.avisos) {
      response.avisos.forEach((aviso: string) => toast.warning(aviso));
    }
    if (response.sucesso && response.dados) {
      return response.dados;
    }
  }
  return undefined;
};

export const cadastrarLocalEstoque = async (localEstoque: string) => {
  const response = await api.put<void, ResponseApi>(
    `${ConstanteEnderecoWebservice.INTEGRACAO_CADASTRAR_LOCAL_ESTOQUE}?localEstoqueId=${localEstoque}`
  );
  if (response) {
    if (response?.avisos) {
      response.avisos.forEach((aviso: string) => toast.warning(aviso));
      return false;
    }
    if (response.sucesso) {
      return true;
    }
  }
  return false;
};

export const cadastrarLocalEstoqueTray = async (localEstoque: string) => {
  const response = await api.put<void, ResponseApi>(
    `${ConstanteEnderecoWebservice.INTEGRACAO_TRAY_ALTERAR_LOCAL_ESTOQUE}/${localEstoque}`
  );
  if (response) {
    if (response?.avisos) {
      response.avisos.forEach((aviso: string) => toast.warning(aviso));
      return false;
    }
    if (response.sucesso) {
      return true;
    }
  }
  return false;
};
