import {
  GridItem,
  Grid,
  Text,
  Flex,
  useMediaQuery,
  Box,
} from '@chakra-ui/react';

import TipoValorEnum from 'constants/enum/tipoValor';
import getOptionsByEnum from 'helpers/format/getOptionsByEnum';
import TipoCobrancaMultaJurosEnum from 'constants/enum/tipoCobrancaMultaJuros';

import { NumberInput } from 'components/update/Input/NumberInput';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';

interface UncontrolledFormInterface {
  readonly?: boolean;
}

export const FormJurosMultas = ({ readonly }: UncontrolledFormInterface) => {
  const [isLargerThan900] = useMediaQuery('(max-width: 900px)');

  const tipoCobrancaMulta = Object.entries(
    TipoCobrancaMultaJurosEnum.properties
  ).map((obj: any) => {
    return {
      label: obj[1].name,
      value: obj[1].value,
    };
  });

  const tipoCobrancaJuros = Object.entries(
    TipoCobrancaMultaJurosEnum.properties
  ).map((obj: any) => {
    return {
      label: obj[1].name,
      value: obj[1].value,
    };
  });

  return (
    <Box w="full">
      <Flex w="full">
        <NumberInput
          id="diasCarencia"
          name="diasCarencia"
          label="Dias de carência"
          scale={0}
          isDisabled={readonly}
          colorScheme="secondary.300"
          color="black"
        />
      </Flex>

      <Flex mb="5px" mt="15px">
        <Text fontWeight="bold">Multa</Text>
      </Flex>
      <Grid
        templateColumns={`repeat(${isLargerThan900 ? 1 : 3}, 1fr)`}
        gap={isLargerThan900 ? '30px' : '30px'}
      >
        <GridItem>
          <SelectPadrao
            id="tipoCobrancaMulta"
            name="tipoCobrancaMulta"
            label="Tipo de cobrança"
            colSpan={3}
            required
            isDisabled={readonly}
            options={tipoCobrancaMulta}
          />
        </GridItem>
        <GridItem>
          <SelectPadrao
            id="tipoValorMulta"
            name="tipoValorMulta"
            label="Cobrar em"
            required
            isDisabled={readonly}
            options={getOptionsByEnum(TipoValorEnum)}
          />
        </GridItem>
        <GridItem>
          <NumberInput
            id="valorMulta"
            name="valorMulta"
            label="Valor"
            precision={5}
            scale={2}
            isDisabled={readonly}
          />
        </GridItem>
      </Grid>
      <Flex mb="5px" mt="15px">
        <Text fontWeight="bold">Juros</Text>
      </Flex>
      <Grid
        templateColumns={`repeat(${isLargerThan900 ? 1 : 3}, 1fr)`}
        gap={isLargerThan900 ? '30px' : '30px'}
      >
        <GridItem>
          <SelectPadrao
            id="tipoCobrancaJuros"
            name="tipoCobrancaJuros"
            label="Tipo de cobrança"
            isDisabled={readonly}
            required
            options={tipoCobrancaJuros}
          />
        </GridItem>
        <GridItem>
          <SelectPadrao
            id="tipoValorJuros"
            name="tipoValorJuros"
            label="Cobrar em"
            required
            isDisabled={readonly}
            options={getOptionsByEnum(TipoValorEnum)}
          />
        </GridItem>
        <GridItem>
          <NumberInput
            id="valorJuros"
            name="valorJuros"
            label="Valor"
            precision={5}
            scale={2}
            isDisabled={readonly}
          />
        </GridItem>
      </Grid>
    </Box>
  );
};
