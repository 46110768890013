import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import UncontrolledForm from '..';
import usePeriodoCaixaForm from '../hooks';
import { FormData, defaultValues, yupResolver } from '../validationForm';

const PeriodoCaixaCadastrar = () => {
  const {
    salvarPeriodoCaixa,
    redirecionarPeriodoCaixaListar,
  } = usePeriodoCaixaForm();

  const formMethods = useForm<FormData>({
    defaultValues,
    resolver: yupResolver,
  });
  const { handleSubmit, reset } = formMethods;

  const [isLoading, setIsLoading] = useState(false);

  const handleSalvar = async (data: FormData) => {
    setIsLoading(true);

    const { success } = await salvarPeriodoCaixa(data);

    if (success) {
      toast.success('Período do caixa cadastrado com sucesso');
      redirecionarPeriodoCaixaListar();
    }

    setIsLoading(false);
  };

  const handleSalvarInserirNovo = async (data: FormData) => {
    setIsLoading(true);

    const { success } = await salvarPeriodoCaixa(data);

    if (success) {
      toast.success('Período do caixa cadastrado com sucesso');
      reset();
    }

    setIsLoading(false);
  };

  return (
    <FormProvider {...formMethods}>
      <UncontrolledForm
        isLoading={isLoading}
        handleSalvar={handleSubmit(handleSalvar)}
        handleSalvarInserirNovo={handleSubmit(handleSalvarInserirNovo)}
      />
    </FormProvider>
  );
};

export default PeriodoCaixaCadastrar;
