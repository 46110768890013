import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Icon,
  Flex,
  Box,
  useMediaQuery,
  useDisclosure,
  ModalHeader,
  Checkbox,
  VStack,
  Text,
  ModalFooter,
  HStack,
  Grid,
  Button,
  GridItem,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FieldArrayWithId, FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import TipoProdutoEnum from 'constants/enum/tipoProduto';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import AsyncSelect from 'components/PDV/Select/AsyncSelectPadrao';
import { SalvarInserirNovoIcon } from 'icons';

import {
  FormData,
  ProdutoCor,
  ProdutoTamanhoCorProps,
  ProdutoTamanhoCorResponseProps,
  yupResolver,
} from './validationForm';
import { CamposInformarValoresTabelaPreco } from './Components/camposInformarValores';
import { TabelaPrecoItensProps } from '../../validationForm';

type SelectProdutoCorTamanhoResponse = {
  id: string;
  descricao: string;
  padraoSistema?: boolean;
};

type FieldArrayType = {
  tabelaPrecoProdutoCorTamanhos: TabelaPrecoItensProps[];
};

type SelectProdutoCorResponse = {
  nome: string;
  id: string;
  cor?: string;
  tamanho: string;
  precoCusto: number;
  precoVenda: number;
  listaTamanho: SelectProdutoCorTamanhoResponse[];
};

type ProdutoResponse = {
  produtoNome: string;
  corDescricao?: string;
  tamanhoDescricao?: string;
  precoVenda: number;
  precoCusto: number;
  markup: number;
  produtoCorTamanhoId?: string;
};

type ModalAdicionarProdutoResponse = {
  deveReiniciar?: boolean;
  produto: ProdutoResponse[];
};

type ModalAdicionarProdutoProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalAdicionarProdutoResponse> & {
    casasDecimaisValor: number;
    isAlterar?: boolean;
    isAddingToVariationList?: boolean;
    fields?: FieldArrayWithId<
      FieldArrayType,
      'tabelaPrecoProdutoCorTamanhos',
      'id'
    >[];
  };

export const ModalAdicionarProduto = create<
  ModalAdicionarProdutoProps,
  ModalAdicionarProdutoResponse
>(
  ({
    onResolve,
    onReject,
    casasDecimaisValor,
    isAlterar = false,
    isAddingToVariationList = false,
    fields,
    ...rest
  }) => {
    const [listProdutoVariacao, setListProdutoVariacao] = useState<
      ProdutoTamanhoCorProps[]
    >([]);
    const [selecionarTodosProduto, setSelecionarTodosProduto] = useState(false);

    const formMethods = useForm<FormData>({
      resolver: yupResolver,
    });
    const [produtoSelecionado, setProdutoSelecionado] = useState<ProdutoCor>();
    const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const [isLoading, setIsLoading] = useState(false);
    const [produtoCorIsLoading, setProdutoCorIsLoading] = useState(false);

    const produtoCor: ProdutoCor = formMethods.watch('produtoCor');
    const [precoVendaWatch, precoCustoWatch] = formMethods.watch([
      'precoVenda',
      'precoCusto',
    ]);

    const markup =
      precoVendaWatch && precoCustoWatch
        ? (precoVendaWatch / precoCustoWatch - 1) * 100
        : 0;

    const isExisteVariacao =
      listProdutoVariacao.some(
        (variacaoItem) => variacaoItem?.cor || variacaoItem?.tamanho
      ) && isAddingToVariationList;

    const produtoVariacaoSelecionado = listProdutoVariacao.filter((produto) =>
      isExisteVariacao ? produto.isChecked === true : produto
    );

    const isProdutoSelecionado = isExisteVariacao
      ? produtoVariacaoSelecionado.length > 0
      : true;

    const listProducts = produtoVariacaoSelecionado.map((itemProduto) => {
      return {
        produtoNome: itemProduto.label,
        corDescricao: itemProduto.cor,
        tamanhoDescricao: itemProduto.tamanho,
        precoVenda: precoVendaWatch,
        precoCusto: itemProduto.precoCusto,
        markup:
          itemProduto.precoNovo && itemProduto.precoCusto
            ? (itemProduto.precoNovo / itemProduto.precoCusto - 1) * 100
            : 0,
        produtoCorTamanhoId: itemProduto.value,
      };
    });

    const validateProduto = useCallback(() => {
      if (!isProdutoSelecionado) {
        toast.warning('Selecione ao menos um produto');
        setIsLoading(false);
        return false;
      }

      if (precoVendaWatch === 0) {
        toast.warning('O preço novo não pode ser zero');
        setIsLoading(false);
        return false;
      }
      setIsLoading(false);
      return true;
    }, [isProdutoSelecionado, precoVendaWatch]);

    const newProdutoSelecionado = {
      produtoNome: produtoCor?.label,
      corDescricao: '',
      tamanhoDescricao: '',
      precoVenda: precoVendaWatch,
      precoCusto: precoCustoWatch,
      markup:
        precoVendaWatch && precoCustoWatch
          ? (precoVendaWatch / precoCustoWatch - 1) * 100
          : 0,
      produtoCorTamanhoId: produtoCor?.value,
    };

    const handleSubmit = formMethods.handleSubmit(async () => {
      setIsLoading(true);

      const produtoPodeAdicionar = validateProduto();

      if (!produtoPodeAdicionar) {
        return;
      }

      const newListProduct =
        listProducts.length > 0 && isAddingToVariationList
          ? listProducts
          : [newProdutoSelecionado];

      if (fields) {
        onResolve({ produto: newListProduct });
        setIsLoading(false);
      } else {
        onResolve({ produto: newListProduct });
        setIsLoading(false);
      }
    });

    const handleSubmitReset = formMethods.handleSubmit(async () => {
      setIsLoading(true);
      const produtoPodeAdicionar = validateProduto();

      if (!produtoPodeAdicionar) {
        return;
      }

      const newListProduct =
        listProducts.length > 0 ? listProducts : [newProdutoSelecionado];

      onResolve({ deveReiniciar: true, produto: newListProduct });
      setIsLoading(false);
    });

    const getProdutoCorOptions = useCallback(async (inputValue: string) => {
      setProdutoCorIsLoading(true);

      const response = await api.get<
        void,
        ResponseApi<SelectProdutoCorResponse[]>
      >(ConstanteEnderecoWebservice.PRODUTO_LISTAR_SELECT, {
        params: {
          produtoNomeReferencia: inputValue,
          tipoProduto: isAddingToVariationList
            ? TipoProdutoEnum.PRODUTO_VARIACAO
            : null,
        },
      });

      if (response?.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }

      if (response && response.sucesso && response.dados) {
        const data = response.dados.map((option) => ({
          label: option.nome,
          value: option.id,
        }));

        setProdutoCorIsLoading(false);

        return data;
      }

      setProdutoCorIsLoading(false);
      return [];
    }, []);

    function handleToggleSelecionarVariacao(index: number) {
      const novoArray = [...listProdutoVariacao];

      const item = novoArray[index];

      novoArray.splice(index, 1, {
        ...item,
        isChecked: !item.isChecked,
      });

      setListProdutoVariacao(novoArray);

      if (novoArray.every((produtoItem) => produtoItem.isChecked === true)) {
        setSelecionarTodosProduto(true);
      } else {
        setSelecionarTodosProduto(false);
      }
    }

    function handleToggleSelecionarTodasVariacao() {
      setSelecionarTodosProduto(!selecionarTodosProduto);

      setListProdutoVariacao((prev) =>
        prev.map((itemJaFoiAdicionado) => ({
          ...itemJaFoiAdicionado,
          isChecked: !selecionarTodosProduto,
        }))
      );
    }

    const getCorTamanhoProduto = useCallback(async (inputValue: string) => {
      setIsLoading(true);
      const response = await api.get<
        void,
        ResponseApi<ProdutoTamanhoCorResponseProps[]>
      >(ConstanteEnderecoWebservice.TABELA_PRECO_OBTER_PRODUTO_COR_TAMANHO, {
        params: {
          produtoId: inputValue,
        },
      });

      if (response?.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }

      if (response && response.sucesso && response.dados) {
        setListProdutoVariacao(
          response.dados.map((produtoItem) => ({
            ...produtoItem,
            label: produtoItem.nome,
            value: produtoItem.id,
            precoNovo: 0,
            isChecked: false,
          }))
        );
        setIsLoading(false);
      }
      setIsLoading(false);
    }, []);

    const latestProps = useRef({
      setValue: formMethods.setValue,
      clearErrors: formMethods.clearErrors,
    });
    useEffect(() => {
      latestProps.current = {
        setValue: formMethods.setValue,
        clearErrors: formMethods.clearErrors,
      };
    });

    useEffect(() => {
      if (produtoCor?.value) {
        latestProps.current.setValue('precoVenda', 0);
      }
    }, [produtoCor]);

    useEffect(() => {
      if (produtoCor?.value) {
        getCorTamanhoProduto(produtoCor?.value);
      }
      setSelecionarTodosProduto(false);
    }, [getCorTamanhoProduto, produtoCor]);

    useEffect(() => {
      if (listProdutoVariacao && listProdutoVariacao[0]) {
        latestProps.current.setValue(
          'preco',
          listProdutoVariacao[0].precoVenda
        );
        latestProps.current.setValue(
          'precoCusto',
          listProdutoVariacao[0].precoCusto
        );
      }
    }, [produtoCor, listProdutoVariacao]);

    return (
      <ModalPadraoChakra
        isCentered
        size={!isSmallerThan900 ? '3xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalContent
          marginBottom={{ base: 0, md: '3.75rem' }}
          bg="gray.50"
          marginTop={{ base: 0, md: '3.75rem' }}
          maxW={{ base: '100%', md: '900px' }}
          maxH={{ base: '100%', md: '580px' }}
        >
          {isLoading && <LoadingPadrao />}
          <ModalHeader
            mt={isSmallerThan900 ? 12 : undefined}
            mb={isSmallerThan900 ? 8 : undefined}
            borderBottom="1px"
            borderColor="gray.100"
            px="0"
            mx={{ base: 6, md: 8 }}
          >
            <VStack alignItems="flex-start" spacing="1" lineHeight="1">
              <Text color="primary.500" fontSize={{ base: 'xl', md: 'md' }}>
                Excessões na tabela de preços
              </Text>
            </VStack>
          </ModalHeader>
          <ModalBody p={{ base: 6, md: 8 }}>
            <FormProvider {...formMethods}>
              <Flex
                alignItems="baseline"
                flexDirection="column"
                w="full"
                h="full"
              >
                <SimpleGridForm w="full">
                  <GridItem w="full" colSpan={12}>
                    <AsyncSelect
                      id="produtoCor"
                      name="produtoCor"
                      label="Descrição do produto"
                      placeholder="Digite o nome do produto e selecione a cor"
                      required
                      setValor={setProdutoSelecionado}
                      isLoading={produtoCorIsLoading}
                      handleGetOptions={getProdutoCorOptions}
                      asControlledByObject
                      withoutDefaultOptions
                      autoFocus
                    />
                  </GridItem>

                  <CamposInformarValoresTabelaPreco
                    markup={markup}
                    produtoSelecionado={produtoSelecionado}
                    casasDecimaisValor={casasDecimaisValor}
                  />
                  {produtoCor?.value && isExisteVariacao && (
                    <GridItem w="full" colSpan={12}>
                      <Text color="gray.700" fontSize="14px">
                        Selecione para qual variação será aplicado o novo preço:
                      </Text>
                      <Box
                        borderWidth="1px"
                        borderRadius="5px"
                        borderColor="gray.200"
                        borderStyle="solid"
                        p="16px"
                      >
                        <Grid
                          templateColumns={[
                            'repeat(1, 1fr)',
                            'repeat(1, 1fr)',
                            'repeat(2, 1fr)',
                          ]}
                          mb="2px"
                          rowGap="2px"
                          gridGap="16px"
                        >
                          <GridItem
                            h="28px"
                            display="flex"
                            pl="16px"
                            pt="3px"
                            alignItems="baseline"
                            bg={
                              selecionarTodosProduto ? 'secondary.300' : 'white'
                            }
                            w="100%"
                            borderRadius="5px"
                            borderWidth="1px"
                            borderColor="gray.200"
                            borderStyle="solid"
                          >
                            <Box>
                              <Checkbox
                                isChecked={selecionarTodosProduto}
                                colorScheme="primary"
                                onChange={() =>
                                  handleToggleSelecionarTodasVariacao()
                                }
                                mr="12px"
                                mb="0"
                              />
                            </Box>
                            <Box>
                              <Text color="gray.700" fontSize="14px">
                                Selecionar todas variações
                              </Text>
                            </Box>
                          </GridItem>
                        </Grid>
                        <Grid
                          templateColumns={[
                            'repeat(1, 1fr)',
                            'repeat(1, 1fr)',
                            'repeat(2, 1fr)',
                          ]}
                          rowGap="2px"
                          mr="-10px"
                          sx={{
                            '& > div': {
                              marginRight: '6px',
                            },
                            '&::-webkit-scrollbar': {
                              width: '4px',
                              height: '5px',
                              scrollbarWidth: 'thin',
                              position: 'absolute',
                              right: '-10px',
                            },
                            '&::-webkit-scrollbar-track': {
                              background: 'none',
                              borderRadius: '6px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                              background: 'gray.100',
                              borderRadius: '6px',
                            },
                            '&::-webkit-scrollbar-thumb:hover': {
                              background: 'gray.100',
                            },
                          }}
                          gridGap="16px"
                          maxH="120px"
                          overflowY="auto"
                        >
                          {listProdutoVariacao.map((produtoVariacao, index) => {
                            const nameProduto = `${
                              produtoVariacao?.cor || produtoVariacao?.tamanho
                                ? ''
                                : ''
                            }  ${
                              produtoVariacao?.cor
                                ? `${produtoVariacao?.cor} ${
                                    produtoVariacao?.cor &&
                                    produtoVariacao?.tamanho
                                      ? ' |'
                                      : ''
                                  }`
                                : ''
                            } ${produtoVariacao?.tamanho}`;

                            return (
                              <Box>
                                <Grid
                                  pl="16px"
                                  as="div"
                                  h="28px"
                                  pt="4px"
                                  pr="16px"
                                  alignItems="baseline"
                                  bg={
                                    produtoVariacao.isChecked
                                      ? 'purple.50'
                                      : 'white'
                                  }
                                  w="100%"
                                  display="flex"
                                  justifyContent={
                                    produtoVariacao.precoNovo > 0
                                      ? 'space-between'
                                      : 'flex-start'
                                  }
                                  borderRadius="5px"
                                  borderWidth="1px"
                                  borderColor="gray.200"
                                  borderStyle="solid"
                                >
                                  <Flex>
                                    <Box>
                                      <Checkbox
                                        colorScheme="primary"
                                        isChecked={produtoVariacao.isChecked}
                                        onChange={() =>
                                          handleToggleSelecionarVariacao(index)
                                        }
                                        mr="12px"
                                        mb="0"
                                      />
                                    </Box>
                                    <Box>
                                      <Text color="gray.700" fontSize="14px">
                                        {nameProduto.slice(
                                          0,
                                          !isSmallerThan900 ? 35 : 60
                                        )}
                                        {nameProduto.length >
                                        (!isSmallerThan900 ? 35 : 60)
                                          ? '...'
                                          : ''}
                                      </Text>
                                    </Box>
                                  </Flex>
                                </Grid>
                              </Box>
                            );
                          })}
                        </Grid>
                      </Box>
                    </GridItem>
                  )}
                </SimpleGridForm>
              </Flex>
            </FormProvider>
          </ModalBody>
          <ModalFooter justifyContent="flex-end" mx={{ base: 6, md: 8 }} px="0">
            <HStack
              flexDirection={{ base: 'column', md: 'row' }}
              justifyContent="center"
              alignItems="center"
              gap={6}
              flex="1"
            >
              <Button
                variant="outlineDefault"
                minW="100px"
                colorScheme="gray"
                w={{ base: 'full', md: '100px' }}
                onClick={onClose}
              >
                Cancelar
              </Button>
              <Button
                variant="outlineDefault"
                minW="100px"
                colorScheme="gray"
                w={{ base: 'full', md: '100px' }}
                onClick={handleSubmit}
              >
                Confirmar
              </Button>
              <Button
                borderRadius="2xl"
                colorScheme="secondary"
                minW="225px"
                w={{ base: 'full', md: '225px' }}
                leftIcon={<Icon as={SalvarInserirNovoIcon} fontSize="lg" />}
                onClick={handleSubmitReset}
              >
                Confirmar e inserir novo
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
