export const TipoImpressoraEnum = {
  ARGOX: 'ARGOX',
  LASER: 'LASER',
  ZEBRA: 'ZEBRA',

  properties: [
    { label: 'ARGOX', value: 'ARGOX' },
    { label: 'LASER', value: 'LASER' },
    { label: 'ZEBRA', value: 'ZEBRA' },
  ],
};
