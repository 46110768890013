import { useFormContext } from 'react-hook-form';
import {
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Text,
  Thead,
  Tr,
  useMediaQuery,
  Flex,
} from '@chakra-ui/react';

import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import { Checkbox } from 'components/update/Checkbox';

import {
  parcelamentoTefOptions,
  operacaoTefOptions,
  tipoCartaoOptions,
} from './validationForms';
import { useFormaRecebimento } from '../hooks';
import { FormData } from '../validationForms';

export const VincularFormaRecebimento = () => {
  const formMethods = useFormContext<FormData>();
  const [isSmaller1540] = useMediaQuery('(max-width: 1540px)');
  const { listPagamentoCartao } = useFormaRecebimento(formMethods);

  return (
    <Box mt="24px" borderWidth="1px" borderRadius="5px" borderColor="gray.100">
      <Box
        overflow="auto"
        borderRadius="5px"
        boxShadow="0px 0px 4px #0000003E"
        pb="12px"
        bg="white"
      >
        <Table minW="800px" variant="simple" colorScheme="blackAlpha" size="sm">
          <Thead>
            <Tr
              sx={{
                '& th': {
                  bg: 'gray.50',
                  fontSize: '12px',
                  color: 'gray.700',
                  fontWeight: 'normal',
                  paddingBottom: '2px',
                },
              }}
            >
              <Th minW="250px">Forma de recebimento</Th>
              <Th
                minW={['100px', '100px', '100px', 'unset']}
                w="10%"
                textAlign="center"
              >
                Utiliza TEF
              </Th>
              <Th minW={['300px', '300px', '300px', 'unset']} w="30%">
                Operação
              </Th>
              <Th minW={['300px', '300px', '300px', 'unset']} w="30%">
                Parcelamento
              </Th>
              <Th minW={['220px', '220px', '250px', 'unset']} w="20%">
                Tipo
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {listPagamentoCartao.map((formaRecebimentoItem, index) => {
              return (
                <Tr
                  sx={{
                    '& td': {
                      fontSize: '14px',
                    },
                  }}
                  bg="white"
                >
                  <Td color="black">
                    <Text fontWeight="semibold">
                      {formaRecebimentoItem.label}
                    </Text>
                  </Td>
                  <Td>
                    <Flex justify="center">
                      <Box>
                        <Checkbox name={`tef.${index}`} />
                      </Box>
                    </Flex>
                  </Td>
                  <Td>
                    <SelectPadrao
                      name={`operacaoTEF.${index}`}
                      id={`operacaoTEF.${index}`}
                      options={operacaoTefOptions}
                      placeholder="Selecione"
                      isClearable
                    />
                  </Td>
                  <Td>
                    <SelectPadrao
                      name={`parcelamentoTEF.${index}`}
                      id={`parcelamentoTEF.${index}`}
                      options={parcelamentoTefOptions}
                      placeholder={
                        isSmaller1540 ? 'Selecione' : 'Selecione o parcelamento'
                      }
                      isClearable
                    />
                  </Td>
                  <Td>
                    <SelectPadrao
                      name={`tipoCartao.${index}`}
                      id={`tipoCartao.${index}`}
                      options={tipoCartaoOptions}
                      placeholder="Selecione"
                      isClearable
                    />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};
