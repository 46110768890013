export const enumIdentificacaoIntegracao = {
  TODAS: 0,
  TRAY: 1,
  CAIXA_MOVEL: 2,
  PDV_AUTONOMO: 5,
  FRENTE_CAIXA: 6,
};

export const enumIndentificacaoAplicativos = {
  DASHBOARD_GERENCIAL: 0,
  TRAY: 1,
  CAIXA_MOVEL: 2,
};
