import React, { createContext, useContext, useState, useCallback } from 'react';

import { OperacaoMovimentacaoFinanceira } from 'helpers/api/Operacao/obterOperacaoComPagamentos';

interface EditarParcelasContextProps {
  movimentacaoFinanceiraEditando?: OperacaoMovimentacaoFinanceira;
  handleSetMovimentacaoFinanceiraEditando: (
    newMovimentacaoFinanceiraEditando?: OperacaoMovimentacaoFinanceira
  ) => void;
}

export const EditarParcelasContext = createContext<EditarParcelasContextProps>(
  {} as EditarParcelasContextProps
);

interface EditarParcelasProviderProps {
  children: React.ReactNode;
}

export default function EditarParcelasProvider({
  children,
}: EditarParcelasProviderProps): JSX.Element {
  const [
    movimentacaoFinanceiraEditando,
    setMovimentacaoFinanceiraEditando,
  ] = useState<OperacaoMovimentacaoFinanceira>();

  const handleSetMovimentacaoFinanceiraEditando = useCallback(
    (newMovimentacaoFinanceiraEditando?: OperacaoMovimentacaoFinanceira) => {
      setMovimentacaoFinanceiraEditando(newMovimentacaoFinanceiraEditando);
    },
    []
  );

  return (
    <EditarParcelasContext.Provider
      value={{
        movimentacaoFinanceiraEditando,
        handleSetMovimentacaoFinanceiraEditando,
      }}
    >
      {children}
    </EditarParcelasContext.Provider>
  );
}

export function useEditarParcelasContext(): EditarParcelasContextProps {
  const context = useContext(EditarParcelasContext);

  if (!context)
    throw new Error(
      'useEditarParcelasContext must be used within a EditarParcelasProvider.'
    );

  return context;
}
