import ConstanteFuncionalidades from 'constants/permissoes';
import ConstanteRotas from 'constants/rotas';

import { RelatorioCurvaABC } from 'pages/CurvaABC';

import LayoutGuard from './LayoutGuard';

export const RelatorioDeCurvaABC = [
  <LayoutGuard
    key="gerar-relatorios-curva-abc"
    component={RelatorioCurvaABC}
    path={ConstanteRotas.RELATORIO_CURVA_ABC}
    permissaoFuncionalidade={ConstanteFuncionalidades.RELATORIO_CURVA_ABC}
    breadcrumb={[
      {
        title: 'Relatórios',
      },
      {
        title: 'Curva ABC',
      },
    ]}
    exact
    meta={{ auth: true }}
  />,
];
