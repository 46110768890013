import styled, { css } from 'styled-components';
import { Dropdown as BootstrapDropdown } from 'react-bootstrap';
import { FiMoreVertical } from 'react-icons/fi';

export const ShowMoreIcon = styled(FiMoreVertical)`
  font-size: 1.4375rem;

  margin: 5px;
`;

export const Dropdown = styled(BootstrapDropdown)`
  > button,
  & > button.btn-primary,
  &.show > button,
  &.dropdown > button,
  &.show > .dropdown-toggle,
  &.show > button.btn-primary {
    border-color: transparent;
    background-color: transparent;

    color: var(--black);

    :hover,
    :active {
      border-color: transparent;
      background: transparent;

      box-shadow: none;
    }

    :not(:disabled):hover {
      color: var(--sti-ck-colors-gray-400);
    }
  }

  > button:focus,
  & > button.btn-primary:focus,
  &.show > button:focus,
  &.dropdown > button:focus,
  &.show > .dropdown-toggle:focus,
  &.show > .btn-primary:focus {
    border-color: transparent;
    background: transparent;

    box-shadow: none !important;
  }

  &.show > button,
  &.show > .dropdown-toggle,
  &.show > .btn-primary {
    color: var(--sti-ck-colors-gray-400);
  }

  > button.btn-primary:not(:disabled):not(.disabled):active {
    border-color: transparent;
    background-color: transparent;

    color: var(--sti-ck-colors-gray-400);
  }
`;

interface DropdownItemProps {
  bloqueado?: 0 | 1;
}

export const DropdownItem = styled(BootstrapDropdown.Item)<DropdownItemProps>`
  position: relative;

  padding-left: 40px;

  color: var(--black) !important;

  &.active,
  &:active {
    background-color: var(--sti-ck-colors-gray-100);
  }

  &:hover {
    background-color: var(--sti-ck-colors-gray-100);
  }

  ${({ bloqueado }) =>
    bloqueado &&
    css`
      color: var(--sti-ck-colors-gray-100) !important;
      background-color: var(--white) !important;

      cursor: default !important;
      user-select: none;
    `}
`;

export const DropdownItemDisabled = styled(BootstrapDropdown.Item)`
  filter: blur(0.6px);
  padding-left: 40px;

  color: var(--black) !important;

  background-color: rgba(0, 0, 0, 0.2);

  &.active,
  &:active {
    background-color: rgba(0, 0, 0, 0.2);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }

  cursor: unset;
`;

export const DropdownToggle = styled(BootstrapDropdown.Toggle)`
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding: 0px !important;

  &:hover {
    color: var(--sti-ck-colors-gray-100);
  }

  &:after,
  &:before {
    display: none !important;
  }
`;
