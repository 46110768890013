import React, { useState, useEffect, useRef } from 'react';
import { Stack, Button, useMediaQuery } from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { useEntradaMercadoriaDadosCadastroContext } from 'store/EntradaMercadoria/EntradaMercadoriaDadosCadastro';
import { useEntradaMercadoriaEtapasContext } from 'store/EntradaMercadoria/EntradaMercadoriaEtapas';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import {
  Container,
  Body,
  Footer,
  StepDescriptionAccordion,
} from 'components/update/Steps/StepContent';
import { SimpleCard } from 'components/update/Form/SimpleCard';
import { NumberInput } from 'components/update/Input/NumberInput';

import { InformarValorICMSSTModal } from './InformarValorICMSSTModal';
import { yupResolver, FormData } from './validationForm';

interface ObterValoresResponse {
  valorTotalItensSemDesconto: number;
  valorTotalFcpSt: number;
  valorTotalIpi: number;
  valorTotalIcmsSt: number;
  ratearIcmsSt: boolean;
  valorTotalOutrasDespesas: number;
  valorTotalDescontoAdicional: number;
  valorTotalCustoAdicional: number;
  valorTotalFrete: number;
  valorTotal: number;
  bloquearAlteracao: boolean;
}

interface DefinirValoresProps {
  isImportacao?: boolean;
}

export function DefinirValores({ isImportacao = false }: DefinirValoresProps) {
  const {
    descartarEntradaMercadoria,
    entradaMercadoriaId,
    voltarParaListagem,
    temPermissaoExcluir,
    IsCadastroExterno,
    isReadOnly,
    statusLancamentos: { foiLancadoEstoque, foiLancadoFinanceiro },
    menuIsOpen,
  } = useEntradaMercadoriaDadosCadastroContext();
  const { nextStep, previousStep } = useEntradaMercadoriaEtapasContext();
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const { casasDecimais } = usePadronizacaoContext();

  const [isLoading, setIsLoading] = useState(false);

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
  });

  const valoresWatch = formMethods.watch([
    'valorTotalOutrasDespesas',
    'valorTotalFrete',
    'valorTotalDescontoAdicional',
    'valorTotalIcmsSt',
  ]);

  function handleDescartarEntradaMercadoria() {
    descartarEntradaMercadoria();
  }

  function handleVoltar() {
    previousStep();
  }

  async function adicionarValores(data: FormData) {
    if (entradaMercadoriaId) {
      setIsLoading(true);

      const response = await api.put<void, ResponseApi<string>>(
        ConstanteEnderecoWebservice.ENTRADA_MERCADORIA_ADICIONAR_VALORES,
        {
          id: entradaMercadoriaId,
          frete: data.valorTotalFrete,
          outrasDespesas: data.valorTotalOutrasDespesas,
          descontos: data.valorTotalDescontoAdicional,
        }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.map((aviso: string) => toast.warning(aviso));
        }

        if (response.sucesso) {
          return true;
        }
      }
    }

    return false;
  }

  const handleSalvarRascunho = formMethods.handleSubmit(async (data) => {
    if (isImportacao) {
      voltarParaListagem();
      return;
    }

    const sucesso = await adicionarValores(data);

    if (sucesso) {
      voltarParaListagem();
    }
  });

  async function handleInformarValorIcmsSt() {
    if (entradaMercadoriaId) {
      const valorIcmsSt = formMethods.getValues('valorTotalIcmsSt');

      const { valorIcmsSt: newValorIcmsSt } = await InformarValorICMSSTModal({
        valorIcmsSt,
        casasDecimaisValor: casasDecimais.casasDecimaisValor,
        entradaMercadoriaId,
      });

      formMethods.setValue('valorTotalIcmsSt', newValorIcmsSt);
    }
  }

  const handleAvancar = formMethods.handleSubmit(async (data) => {
    if (isReadOnly || isImportacao) {
      nextStep();
      return;
    }
    if (data?.valorTotal < 0) {
      toast.warn('O valor total de entrada não pode ser negativo');
      return;
    }
    const sucesso = await adicionarValores(data);

    if (sucesso) {
      setIsLoading(false);

      nextStep();
    }
  });

  const latestProps = useRef({
    reset: formMethods.reset,
    voltarParaListagem,
    getValues: formMethods.getValues,
    setValue: formMethods.setValue,
  });
  useEffect(() => {
    latestProps.current = {
      reset: formMethods.reset,
      voltarParaListagem,
      getValues: formMethods.getValues,
      setValue: formMethods.setValue,
    };
  });

  useEffect(() => {
    const {
      valorTotalItensSemDesconto,
      valorTotalFcpSt,
      valorTotalIpi,
      valorTotalIcmsSt,
      valorTotalOutrasDespesas,
      valorTotalDescontoAdicional,
      // valorTotalCustoAdicional, valor comentado para se caso seja necessário a soma novamente, o campo seja apenas valido novamente.
      valorTotalFrete,
    } = latestProps.current.getValues();

    const newValorTotal =
      valorTotalItensSemDesconto +
      valorTotalFcpSt +
      valorTotalIpi +
      valorTotalIcmsSt +
      valorTotalOutrasDespesas +
      // valorTotalCustoAdicional + valor comentado para se caso seja necessário a soma novamente, o campo seja apenas valido novamente.
      valorTotalFrete -
      valorTotalDescontoAdicional;

    latestProps.current.setValue('valorTotal', +newValorTotal.toFixed(2));
  }, [valoresWatch]);

  useEffect(() => {
    async function obterValores() {
      if (entradaMercadoriaId) {
        setIsLoading(true);

        const response = await api.get<void, ResponseApi<ObterValoresResponse>>(
          ConstanteEnderecoWebservice.ENTRADA_MERCADORIA_OBTER_VALORES,
          {
            params: {
              id: entradaMercadoriaId,
            },
          }
        );

        if (response) {
          if (response.avisos) {
            response.avisos.map((aviso: string) => toast.warning(aviso));
          }

          if (response.sucesso && response.dados) {
            latestProps.current.reset({
              ...response.dados,
              valorTotalItensSemDesconto: +response.dados.valorTotalItensSemDesconto.toFixed(
                2
              ),
            });
          } else {
            latestProps.current.voltarParaListagem();
          }
        }

        setIsLoading(false);
      }
    }

    obterValores();
  }, [entradaMercadoriaId]);

  return (
    <>
      {isLoading && <LoadingPadrao />}

      <Container mt="5">
        <StepDescriptionAccordion
          stepNumber={3}
          title="Valores"
          description="Insira os dados para despesas, descontos e frete. Se optou por informar o valor total do ICMS ST de uma só vez, clique em “informar valor total”."
        />

        <FormProvider {...formMethods}>
          <Body>
            <SimpleCard
              bg="gray.50"
              border="1px"
              borderColor="gray.100"
              boxShadow="none"
              sx={{
                '& label': {
                  opacity: '1 !important',
                },
              }}
            >
              <SimpleGridForm>
                <NumberInput
                  id="valorTotalItensSemDesconto"
                  name="valorTotalItensSemDesconto"
                  label="Valor total de produtos"
                  leftElement="R$"
                  placeholder={`0,${'0'.repeat(2)}`}
                  scale={2}
                  isDisabled
                  colSpan={3}
                />
                <NumberInput
                  id="valorTotalIcmsSt"
                  name="valorTotalIcmsSt"
                  label="Total ICMS ST"
                  leftElement="R$"
                  actionLinkText={
                    isReadOnly || isImportacao ? undefined : 'Informar valor'
                  }
                  actionLinkOnClick={
                    isReadOnly || isImportacao
                      ? () => {}
                      : () => handleInformarValorIcmsSt()
                  }
                  helperText="Se optou por informar o valor total do ICMS ST de uma só vez, clique em “informar valor”, preencha o valor contido na nota e selecione os itens que serão incluídos na divisão. O valor do ICMS ST será dividido automaticamente entre os itens."
                  placeholder={`0,${'0'.repeat(2)}`}
                  scale={2}
                  isDisabled
                  colSpan={3}
                />
                <NumberInput
                  id="valorTotalFcpSt"
                  name="valorTotalFcpSt"
                  label="Total FCP ST"
                  leftElement="R$"
                  placeholder={`0,${'0'.repeat(2)}`}
                  scale={2}
                  isDisabled
                  colSpan={3}
                />
                <NumberInput
                  id="valorTotalIpi"
                  name="valorTotalIpi"
                  label="Total IPI"
                  leftElement="R$"
                  placeholder={`0,${'0'.repeat(2)}`}
                  scale={2}
                  isDisabled
                  colSpan={3}
                />
                <NumberInput
                  id="valorTotalFrete"
                  name="valorTotalFrete"
                  label="Frete"
                  leftElement="R$"
                  placeholder={`0,${'0'.repeat(2)}`}
                  scale={2}
                  colSpan={3}
                  isDisabled={
                    isReadOnly ||
                    isImportacao ||
                    foiLancadoEstoque ||
                    foiLancadoFinanceiro
                  }
                />
                <NumberInput
                  id="valorTotalOutrasDespesas"
                  name="valorTotalOutrasDespesas"
                  label="Outras despesas"
                  leftElement="R$"
                  placeholder={`0,${'0'.repeat(2)}`}
                  scale={2}
                  colSpan={3}
                  isDisabled={
                    isReadOnly ||
                    isImportacao ||
                    foiLancadoEstoque ||
                    foiLancadoFinanceiro
                  }
                />
                <NumberInput
                  id="valorTotalDescontoAdicional"
                  name="valorTotalDescontoAdicional"
                  label="Descontos"
                  leftElement="R$"
                  placeholder={`0,${'0'.repeat(2)}`}
                  scale={2}
                  colSpan={3}
                  isDisabled={
                    isReadOnly ||
                    isImportacao ||
                    foiLancadoEstoque ||
                    foiLancadoFinanceiro
                  }
                />
                <NumberInput
                  id="valorTotalCustoAdicional"
                  name="valorTotalCustoAdicional"
                  label="Custo adicional"
                  helperText="Os valores deste campo não serão somados ao valor total da entrada, servindo apenas para compor o custo do produto."
                  leftElement="R$"
                  placeholder={`0,${'0'.repeat(2)}`}
                  scale={2}
                  isDisabled
                  colSpan={3}
                />
                <NumberInput
                  id="valorTotal"
                  name="valorTotal"
                  label="Valor total da entrada"
                  leftElement="R$"
                  placeholder={`0,${'0'.repeat(2)}`}
                  scale={2}
                  isDisabled
                  _disabled={{
                    opacity: 1,
                    bg: 'secondary.300',
                  }}
                  colStart={10}
                  colSpan={3}
                />
              </SimpleGridForm>
            </SimpleCard>
          </Body>
        </FormProvider>
      </Container>
      <Footer
        justifyContent={isReadOnly ? 'space-between' : 'flex-end'}
        position={isLargerThan900 ? 'fixed' : 'relative'}
        bottom="0px"
        bg="gray.50"
        borderTop={isLargerThan900 ? '1px solid' : 'none'}
        borderColor="#5502B2"
        w={`calc(100% - ${menuIsOpen ? '210px' : '108px'})`}
        py="16px"
        px="48px"
      >
        <Button
          variant="outlineDefault"
          borderRadius="full"
          w="full"
          maxW={{ base: 'full', md: '160px' }}
          onClick={handleVoltar}
        >
          Voltar
        </Button>

        <Stack
          w="full"
          justifyContent="flex-end"
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: 2, sm: 4, md: 6 }}
        >
          {!IsCadastroExterno && (
            <>
              {isReadOnly ? (
                <Button
                  variant="outlineDefault"
                  borderRadius="full"
                  w="full"
                  maxW={{ base: 'full', md: '196px' }}
                  onClick={voltarParaListagem}
                >
                  Voltar para a listagem
                </Button>
              ) : (
                <Button
                  variant="outlineDefault"
                  borderRadius="full"
                  w="full"
                  maxW={{ base: 'full', md: '160px' }}
                  onClick={handleDescartarEntradaMercadoria}
                  isDisabled={!temPermissaoExcluir}
                >
                  Descartar
                </Button>
              )}
            </>
          )}

          {!isReadOnly && (
            <Button
              variant="outlineDefault"
              borderRadius="full"
              w="full"
              maxW={{ base: 'full', md: '160px' }}
              onClick={handleSalvarRascunho}
            >
              Salvar e sair
            </Button>
          )}

          <Button
            colorScheme="purple"
            borderRadius="full"
            w="full"
            maxW={{ base: 'full', md: '160px' }}
            onClick={handleAvancar}
          >
            Avançar
          </Button>
        </Stack>
      </Footer>
    </>
  );
}
