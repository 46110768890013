import { useCallback, useEffect, useState } from 'react';
import {
  DrawerHeader,
  DrawerBody,
  DrawerContent,
  Icon,
  DrawerCloseButton,
  GridItem,
  FormLabel,
  Flex,
  Button,
  useMediaQuery,
} from '@chakra-ui/react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import TipoFiltroProdutoEstoqueEnum from 'constants/enum/tipoFiltroProdutoEstoque';
import StatusConsultaEnum from 'constants/enum/statusConsulta';

import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import Input from 'components/PDV/Input';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import DrawerPadraoChakra from 'components/PDV/Drawer/DrawerPadraoChakra';
import { LupaIcon } from 'icons';
import { SelectMulti } from 'components/Select/SelectMultiCheckbox';
import {
  OptionsProps,
  OptionsResponseProps,
} from 'pages/Produtos/Listar/validationForm';
import { SelectCategoria } from 'components/Select/SelectCategoria';

import { FiltrosProps } from '../validationForms';

type DrawerBuscaAvancadaProps = {
  currentFilters: FiltrosProps;
  filtersSubmit: (filters: FiltrosProps) => void;
  filtrosReset: FiltrosProps;
  onClose: () => void;
  isOpen: boolean;
};

export const DrawerBuscaAvancada = ({
  onClose,
  isOpen,
  currentFilters,
  filtersSubmit,
  filtrosReset,
}: DrawerBuscaAvancadaProps) => {
  const [cores, setCores] = useState<OptionsProps[]>([]);
  const [coresIsLoading, setCoresIsLoading] = useState(true);
  const [tamanhos, setTamanhos] = useState<OptionsProps[]>([]);
  const [tamanhosIsLoading, setTamanhosIsLoading] = useState(true);
  const [marcas, setMarcas] = useState<OptionsProps[]>([]);
  const [marcasIsLoading, setMarcasIsLoading] = useState(true);
  const [tags, setTags] = useState<OptionsProps[]>([]);
  const [tagsIsLoading, setTagsIsLoading] = useState(true);

  const [isLargerThan1000] = useMediaQuery('(min-width: 1000px)');

  const { setValue, handleSubmit: submit, reset } = useFormContext();

  const dadosAPi = useCallback(
    (
      response: ResponseApi<OptionsResponseProps[]>,
      setStateValue: (value: React.SetStateAction<OptionsProps[]>) => void
    ) => {
      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }

        if (response.sucesso) {
          setStateValue(
            response.dados.map((tamanho) => {
              return {
                label: tamanho.nome,
                value: tamanho.id,
              };
            })
          );
          setValue('cores', currentFilters.cores);
          setValue('tamanhos', currentFilters.tamanhos);
          setValue('categoriasProduto', currentFilters.categoriasProduto);
          setValue('marcas', currentFilters.marcas);
          setValue('tags', currentFilters.tags);
        }
      }
    },
    [currentFilters, setValue]
  );

  const getCores = useCallback(async () => {
    setCoresIsLoading(true);
    const response = await api.get<void, ResponseApi<OptionsResponseProps[]>>(
      ConstanteEnderecoWebservice.COR_LISTAR_SELECT,
      {
        params: {
          statusConsulta: StatusConsultaEnum.TODOS,
          listarPadraoSistema: true,
        },
      }
    );

    await dadosAPi(response, setCores);
    setCoresIsLoading(false);
  }, [dadosAPi]);

  const getTamanhos = useCallback(async () => {
    setTamanhosIsLoading(true);
    const response = await api.get<void, ResponseApi<OptionsResponseProps[]>>(
      ConstanteEnderecoWebservice.TAMANHO_LISTAR_SELECT,
      {
        params: {
          statusConsulta: StatusConsultaEnum.TODOS,
          listarPadraoSistema: true,
        },
      }
    );

    await dadosAPi(response, setTamanhos);
    setTamanhosIsLoading(false);
  }, [dadosAPi]);

  const getMarcas = useCallback(async () => {
    setMarcasIsLoading(true);
    const response = await api.get<void, ResponseApi<OptionsResponseProps[]>>(
      ConstanteEnderecoWebservice.MARCA_LISTAR_SELECT,
      {
        params: {
          statusconsulta: 1,
        },
      }
    );
    await dadosAPi(response, setMarcas);
    setMarcasIsLoading(false);
  }, [dadosAPi]);

  const getTags = useCallback(async () => {
    setTagsIsLoading(true);
    const response = await api.get<void, ResponseApi<OptionsResponseProps[]>>(
      ConstanteEnderecoWebservice.TAG_LISTAR_SELECT
    );
    await dadosAPi(response, setTags);
    setTagsIsLoading(false);
  }, [dadosAPi]);

  const handleSubmit = submit((data: FiltrosProps) => {
    filtersSubmit(data as FiltrosProps);

    onClose();
  });

  const handleLimparPesquisa = () => {
    reset(filtrosReset);
    filtersSubmit(filtrosReset);
    onClose();
  };

  useEffect(() => {
    getCores();
  }, [getCores]);

  useEffect(() => {
    getTags();
  }, [getTags]);

  useEffect(() => {
    getMarcas();
  }, [getMarcas]);

  useEffect(() => {
    getTamanhos();
  }, [getTamanhos]);

  return (
    <DrawerPadraoChakra
      isOverlay={false}
      size={isLargerThan1000 ? ' lg' : 'full'}
      onClose={onClose}
      isOpen={isOpen}
    >
      <DrawerContent
        style={{
          width: isLargerThan1000 ? '620px' : '',
        }}
        bg="gray.50"
        overflow="hidden"
        px="24px"
        boxShadow="-6px 0px 20px #00000029"
      >
        <DrawerCloseButton fontSize="8px" color="violet.500" />
        <DrawerHeader
          px="0"
          borderBottomWidth="1px"
          fontSize="16px"
          color="violet.500"
        >
          Busca avançada
        </DrawerHeader>

        <DrawerBody overflowY="auto" mt="28px" px="2px">
          <SimpleGridForm gap={5}>
            <GridItem colSpan={12}>
              <Input
                id="nomeReferencia"
                name="nomeReferencia"
                placeholder="Digite o nome ou a referência"
                label="Descrição do produto"
                _placeholder={{ color: 'gray.200' }}
              />
            </GridItem>
            <GridItem colSpan={[12, 12, 6]}>
              <Input
                id="codigoSku"
                name="codigoSku"
                placeholder="Digite o código"
                label="Código SKU"
                _placeholder={{ color: 'gray.200' }}
              />
            </GridItem>
            <GridItem colSpan={[12, 12, 6]}>
              <Input
                id="codigoBarras"
                name="codigoBarras"
                placeholder="Digite o código da etiqueta"
                label="Código de barras"
                _placeholder={{ color: 'gray.200' }}
              />
            </GridItem>
            <GridItem colSpan={{ base: 12, md: 6, lg: 6 }}>
              <SelectPadrao
                label="Estoque"
                required
                id="tipoEstoque"
                placeholder="Clique aqui para selecionar."
                defaultValue={TipoFiltroProdutoEstoqueEnum.produtos[0]}
                name="tipoEstoque"
                options={TipoFiltroProdutoEstoqueEnum.produtos}
              />
            </GridItem>
            <GridItem colSpan={{ base: 12, md: 6, lg: 6 }}>
              <SelectPadrao
                label="Status"
                required
                id="statusConsulta"
                placeholder="Clique aqui para selecionar."
                valueDefault={StatusConsultaEnum.status[0].value}
                name="statusConsulta"
                options={StatusConsultaEnum.status}
              />
            </GridItem>

            <GridItem colSpan={12}>
              <SelectMulti
                name="cores"
                label="Cores"
                changeBackgroundOption
                optionBackgroundColor="teal.600"
                optionColor="yellow.400"
                textLabelSelectAll="Todas as cores"
                id="cores"
                isLoading={coresIsLoading}
                placeholder="Digite o nome da cor e clique para selecionar"
                variant="outline"
                options={cores}
                isMulti
                isSearchable={false}
                closeMenuOnSelect={false}
              />
            </GridItem>
            <GridItem colSpan={12}>
              <SelectMulti
                label="Tamanhos"
                name="tamanhos"
                textLabelSelectAll="Todas os tamanhos"
                isLoading={tamanhosIsLoading}
                id="tamanhos"
                changeBackgroundOption
                optionBackgroundColor="pink.500"
                optionColor="yellow.400"
                placeholder="Digite o tamanho e clique para selecionar"
                variant="outline"
                options={tamanhos}
                isMulti
                isSearchable={false}
                closeMenuOnSelect={false}
              />
            </GridItem>
            <GridItem colSpan={12}>
              <FormLabel mb="1px" fontSize="sm" color="black" lineHeight="1.2">
                Categoria
              </FormLabel>
              <SelectCategoria
                name="categoriasProduto"
                placeholder="Digite o nome da categoria e clique para selecionar"
              />
            </GridItem>
            <GridItem colSpan={12}>
              <SelectMulti
                name="marcas"
                label="Marca"
                textLabelSelectAll="Todas as marcas"
                id="marcas"
                isLoading={marcasIsLoading}
                placeholder="Digite o nome da marca e clique para selecionar"
                variant="outline"
                options={marcas}
                isMulti
                isSearchable={false}
                closeMenuOnSelect={false}
              />
            </GridItem>
            <GridItem colSpan={12}>
              <SelectMulti
                name="tags"
                label="Tags"
                isLoading={tagsIsLoading}
                textLabelSelectAll="Todas os tags"
                id="tags"
                placeholder="Digite a tag e clique para selecionar"
                variant="outline"
                options={tags}
                isMulti
                isSearchable={false}
                closeMenuOnSelect={false}
              />
            </GridItem>
          </SimpleGridForm>
          <Flex
            mt="40px"
            w="full"
            justifyContent="center"
            alignItems="baseline"
          >
            <Button
              variant="outlineDefault"
              leftIcon={<Icon as={AiOutlineCloseCircle} />}
              borderRadius="16px"
              colorScheme="gray"
              mr="24px"
              onClick={handleLimparPesquisa}
              w="150px"
            >
              Limpar filtros
            </Button>
            <Button
              color="black"
              leftIcon={<Icon as={LupaIcon} />}
              variant="solid"
              onClick={handleSubmit}
              colorScheme="secondary"
              borderRadius="16px"
              width="132px"
            >
              Pesquisar
            </Button>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </DrawerPadraoChakra>
  );
};
