import React, {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@chakra-ui/react';

import { useLayoutContext } from 'store/Layout';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteRotas from 'constants/rotas';
import auth from 'modules/auth';
import ConstanteFuncionalidades from 'constants/permissoes';

import { ResolucaoNaoCompativel } from 'pages/ResolucaoNaoCompativel';
import SemPermissaoPlano from 'pages/SemPermissaoPlano';
import SemPermissao from 'pages/SemPermissao';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';

interface ObterStatusLancamentosResponse {
  foiLancadoEstoque: boolean;
  foiLancadoFinanceiro: boolean;
  foiFinalizada: boolean;
}

interface EntradaMercadoriaDadosCadastroContextProps {
  entradaMercadoriaId?: string;
  setEntradaMercadoriaId: Dispatch<SetStateAction<string | undefined>>;
  descartarEntradaMercadoria: () => Promise<void>;
  voltarParaListagem: () => void;
  temPermissaoExcluir: boolean;
  isReadOnly: boolean;
  IsCadastroExterno: boolean;
  isStatusLancamentosLoading: boolean;
  statusLancamentos: ObterStatusLancamentosResponse;
  menuIsOpen: boolean;
}

export const EntradaMercadoriaDadosCadastroContext = createContext<EntradaMercadoriaDadosCadastroContextProps>(
  {} as EntradaMercadoriaDadosCadastroContextProps
);

interface EntradaMercadoriaDadosCadastroProviderProps {
  children: React.ReactNode;
  defaultEntradaMercadoriaId?: string;
  isReadOnly?: boolean;
  IsCadastroExterno?: boolean;
}

export function EntradaMercadoriaDadosCadastroProvider({
  children,
  IsCadastroExterno = false,
  defaultEntradaMercadoriaId,
  isReadOnly = false,
}: EntradaMercadoriaDadosCadastroProviderProps): JSX.Element {
  const history = useHistory();
  const { menuIsOpen } = useLayoutContext();

  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

  const { permitido: temPermissaoExcluir } = useMemo(
    () =>
      auth.possuiPermissao(ConstanteFuncionalidades.ENTRADA_MERCADORIA_EXCLUIR),
    []
  );

  const [entradaMercadoriaId, setEntradaMercadoriaId] = useState(
    defaultEntradaMercadoriaId
  );

  const [isStatusLancamentosLoading, setStatusLancamentosLoading] = useState(
    false
  );
  const [
    statusLancamentos,
    setStatusLancamentos,
  ] = useState<ObterStatusLancamentosResponse>({
    foiLancadoEstoque: false,
    foiLancadoFinanceiro: false,
    foiFinalizada: false,
  });

  const voltarParaListagem = useCallback(() => {
    history.push(ConstanteRotas.ENTRADA_MERCADORIA);
  }, [history]);

  const descartarEntradaMercadoria = useCallback(async () => {
    if (entradaMercadoriaId && temPermissaoExcluir) {
      await ModalConfirmacaoExcluir({
        title: 'Descartar entrada',
        text:
          'Todos os dados informados serão perdidos. Você tem certeza que deseja descartar esta entrada?',
        confirmButtonText: 'Sim, descartar!',
        callback: async (ok: boolean) => {
          if (ok) {
            const response = await api.delete<void, ResponseApi>(
              ConstanteEnderecoWebservice.ENTRADA_MERCADORIA_EXCLUIR,
              { params: { id: entradaMercadoriaId } }
            );

            if (response) {
              if (response.avisos) {
                response.avisos.map((aviso: string) => toast.warning(aviso));
              }

              if (response.sucesso) {
                history.push(ConstanteRotas.ENTRADA_MERCADORIA);
              }
            }
          }
        },
      });
    } else {
      history.push(ConstanteRotas.ENTRADA_MERCADORIA);
    }
  }, [entradaMercadoriaId, history, temPermissaoExcluir]);

  useEffect(() => {
    async function obterStatusLancamentos() {
      if (entradaMercadoriaId) {
        setStatusLancamentosLoading(true);

        const response = await api.get<
          void,
          ResponseApi<ObterStatusLancamentosResponse>
        >(
          ConstanteEnderecoWebservice.ENTRADA_MERCADORIA_OBTER_STATUS_LANCAMENTOS,
          {
            params: {
              id: entradaMercadoriaId,
            },
          }
        );

        if (response) {
          if (response.avisos) {
            response.avisos.map((aviso: string) => toast.warning(aviso));
          }

          if (response.sucesso && response.dados) {
            setStatusLancamentos(response.dados);
          }
        }

        setStatusLancamentosLoading(false);
      }
    }

    obterStatusLancamentos();
  }, [entradaMercadoriaId]);

  if (!isLargerThan700) {
    return (
      <ResolucaoNaoCompativel
        handleVoltar={() => {
          history.push(ConstanteRotas.ENTRADA_MERCADORIA);
        }}
      />
    );
  }

  if (isReadOnly) {
    const {
      permitido: temPermissaoVisualizar,
      bloqueio: tipoBloqueioPermissaoVisualizar,
    } = auth.possuiPermissao(
      ConstanteFuncionalidades.ENTRADA_MERCADORIA_VISUALIZAR
    );

    if (!temPermissaoVisualizar) {
      return tipoBloqueioPermissaoVisualizar === 'plano' ? (
        <SemPermissaoPlano />
      ) : (
        <SemPermissao />
      );
    }
  } else if (!entradaMercadoriaId) {
    const {
      permitido: temPermissaoCadastrar,
      bloqueio: tipoBloqueioPermissaoCadastrar,
    } = auth.possuiPermissao(
      ConstanteFuncionalidades.ENTRADA_MERCADORIA_CADASTRAR
    );

    if (!temPermissaoCadastrar) {
      return tipoBloqueioPermissaoCadastrar === 'plano' ? (
        <SemPermissaoPlano />
      ) : (
        <SemPermissao />
      );
    }
  } else {
    const {
      permitido: temPermissaoAlterar,
      bloqueio: tipoBloqueioPermissaoAlterar,
    } = auth.possuiPermissao(
      ConstanteFuncionalidades.ENTRADA_MERCADORIA_ALTERAR
    );

    if (!temPermissaoAlterar) {
      return tipoBloqueioPermissaoAlterar === 'plano' ? (
        <SemPermissaoPlano />
      ) : (
        <SemPermissao />
      );
    }
  }

  return (
    <EntradaMercadoriaDadosCadastroContext.Provider
      value={{
        entradaMercadoriaId,
        IsCadastroExterno,
        setEntradaMercadoriaId,
        descartarEntradaMercadoria,
        voltarParaListagem,
        temPermissaoExcluir,
        isReadOnly,
        isStatusLancamentosLoading,
        statusLancamentos,
        menuIsOpen,
      }}
    >
      {children}
    </EntradaMercadoriaDadosCadastroContext.Provider>
  );
}

export function useEntradaMercadoriaDadosCadastroContext(): EntradaMercadoriaDadosCadastroContextProps {
  const context = useContext(EntradaMercadoriaDadosCadastroContext);

  if (!context)
    throw new Error(
      'useEntradaMercadoriaDadosCadastroContext must be used within a EntradaMercadoriaDadosCadastroProvider.'
    );

  return context;
}
