/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { Flex, HStack, Icon, IconButton, Text } from '@chakra-ui/react';
import { FiCheck } from 'react-icons/fi';
import { toast } from 'react-toastify';
import format from 'date-fns/format';
import { ptBR } from 'date-fns/locale';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { TipoMovimentacaoDesmembramentoEnum } from 'constants/enum/tipoMovimentacaoDesmembramento';
import { DecimalMask } from 'helpers/format/fieldsMasks';

import { TextValor } from 'components/PDV/Text/TextValor';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';

type DesmembramentoFinanceiro = {
  planoContaNome: string;
  clienteFornecedorNome: string;
  competencia: Date;
  valor: number;
  historico: string;
  movimentacaoFinanceiraBaixaId: string;
  conciliado: boolean;
  parcela: string;

  visualmenteConciliado: boolean;
};

interface ItemDesmembramentoFinanceiroProps {
  desmembramentoFinanceiro: DesmembramentoFinanceiro;
  isLast: boolean;
  casasDecimaisValor: number;
  faturaId: string;
  toggleDesmembramentoConciliado: () => void;
  toggleDesmembramentoVisualmenteConciliado: () => void;
  obterFatura: () => void;
}

export function ItemDesmembramentoFinanceiro({
  desmembramentoFinanceiro,
  isLast,
  casasDecimaisValor,
  faturaId,
  toggleDesmembramentoConciliado,
  toggleDesmembramentoVisualmenteConciliado,
  obterFatura,
}: ItemDesmembramentoFinanceiroProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [isVisualmenteConciliado, setIsVisualmenteConciliado] = useState(false);

  async function requestConciliacao(conciliado: boolean) {
    setIsLoading(true);

    const response = await api.put<void, ResponseApi>(
      ConstanteEnderecoWebservice.FATURA_CONCILIACAO,
      {
        id: desmembramentoFinanceiro.movimentacaoFinanceiraBaixaId,
        conciliado,
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.map((aviso) => toast.warning(aviso));
      }

      if (response.sucesso) {
        toggleDesmembramentoConciliado();
      }
    }

    setIsLoading(false);
  }

  async function handleConciliar() {
    await requestConciliacao(true);
  }

  async function handleDesconciliar() {
    await requestConciliacao(false);
  }

  function handleToogleVisualmenteConciliado() {
    toggleDesmembramentoVisualmenteConciliado();
  }

  async function handleExcluir() {
    await ModalConfirmacaoExcluir({
      title: 'Remover lançamento',
      text:
        'O registro será excluído e o valor será subtraído no total da fatura. Você tem certeza que deseja remover?',
      confirmButtonText: 'Sim, remover',
      callback: async (ok: boolean) => {
        if (ok) {
          setIsLoading(true);

          const response = await api.delete<void, ResponseApi>(
            ConstanteEnderecoWebservice.FATURA_EXCLUIR_MOVIMENTACAO_FINANCEIRA,
            {
              params: {
                movimentacaoFinanceiraBaixaId:
                  desmembramentoFinanceiro.movimentacaoFinanceiraBaixaId,
              },
            }
          );

          if (response) {
            if (response.avisos) {
              response.avisos.map((aviso) => toast.warning(aviso));
            }

            if (response.sucesso) {
              obterFatura();
            }
          }

          setIsLoading(false);
        }
      },
    });
  }

  async function handleMoverDesmembramentoFinanceiro(
    moverFatura: number,
    valor: string
  ) {
    setIsLoading(true);

    const response = await api.put<void, ResponseApi>(
      ConstanteEnderecoWebservice.FATURA_MOVER_MOVIMENTACAO_FINANCEIRA,
      {
        movimentacaoFinanceiraBaixaId:
          desmembramentoFinanceiro.movimentacaoFinanceiraBaixaId,
        moverFatura,
        faturaId,
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.map((aviso) => toast.warning(aviso));
      }

      if (response.sucesso) {
        if (moverFatura === TipoMovimentacaoDesmembramentoEnum.ANTERIOR) {
          toast.success(
            `O lançamento de ${valor} foi transferido para a fatura anterior.`
          );
        } else {
          toast.success(
            `O lançamento de ${valor} foi transferido para a próxima fatura.`
          );
        }

        obterFatura();
      }
    }

    setIsLoading(false);
  }

  useEffect(() => {
    setIsVisualmenteConciliado(desmembramentoFinanceiro.visualmenteConciliado);
  }, [desmembramentoFinanceiro.visualmenteConciliado]);

  return (
    <Flex
      h="72px"
      borderBottom={isLast ? 'none' : '1px'}
      borderColor="gray.100"
      alignItems="center"
      position="relative"
      bg={
        isVisualmenteConciliado || desmembramentoFinanceiro.conciliado
          ? 'orange.50'
          : 'white'
      }
    >
      <IconButton
        aria-label="Conciliar"
        variant="unstyled"
        h="5"
        w="5"
        minW="0px"
        py="0px !important"
        px="0px !important"
        bg={desmembramentoFinanceiro.conciliado ? 'orange.500' : 'white'}
        borderRadius="full"
        position="absolute"
        top="50%"
        transform="translateY(-50%)"
        left="-2.5"
        display="flex"
        alignItems="center"
        justifyContent="center"
        border={desmembramentoFinanceiro.conciliado ? 'none' : '1px'}
        borderColor="gray.100"
        icon={
          desmembramentoFinanceiro.conciliado ? (
            <Icon as={FiCheck} color="white" boxSize="3" display="flex" />
          ) : undefined
        }
        onClick={
          desmembramentoFinanceiro.conciliado
            ? handleDesconciliar
            : handleConciliar
        }
        isLoading={isLoading}
        _loading={{
          color: 'orange.500',
          opacity: 1,
          bg: 'white',
          border: 'none',
        }}
      />

      <HStack
        w="full"
        h="full"
        spacing="20"
        justifyContent="space-between"
        pl="6"
        cursor={desmembramentoFinanceiro.conciliado ? 'default' : 'pointer'}
        onClick={
          desmembramentoFinanceiro.conciliado
            ? undefined
            : handleToogleVisualmenteConciliado
        }
      >
        <Flex
          flexDir="column"
          minW={{ base: '200px', lg: '300px', xl: '400px' }}
        >
          <Flex justifyContent="space-between">
            <Flex w={{ base: '200px', lg: '300px', xl: '400px' }}>
              <Text fontSize="sm">
                {desmembramentoFinanceiro.planoContaNome}
              </Text>
            </Flex>

            <Text fontSize="sm" w={{ base: '102px', lg: '300px', xl: '400px' }}>
              {desmembramentoFinanceiro.clienteFornecedorNome}
            </Text>
          </Flex>

          {desmembramentoFinanceiro.historico && (
            <Text fontSize="xs" color="gray.400" mt="1">
              {`Histórico: ${desmembramentoFinanceiro.historico}`}
            </Text>
          )}
        </Flex>

        <Flex
          flexDir="column"
          w={{ base: '110px' }}
          minW={{ base: '50px', lg: '70px', xl: '150px' }}
        >
          <Text fontSize="sm" whiteSpace="nowrap">{`Competência: ${format(
            new Date(desmembramentoFinanceiro.competencia),
            'MM/yyyy',
            {
              locale: ptBR,
            }
          )}`}</Text>

          {desmembramentoFinanceiro.parcela && (
            <Text fontSize="xs" color="gray.400" mt="1">
              {`Parcela: ${desmembramentoFinanceiro.parcela}`}
            </Text>
          )}
        </Flex>

        <Flex
          flexDir="column"
          alignItems="flex-end"
          w={{ base: '30px' }}
          minW={{ base: '50px', lg: '70px', xl: '150px' }}
        >
          <TextValor
            valor={desmembramentoFinanceiro.valor}
            casasDecimais={casasDecimaisValor}
            fontSize="sm"
            fontWeight="regular"
            color="gray.700"
          />
        </Flex>
      </HStack>

      <Flex flexDir="column" w={{ base: '60px', lg: '80px' }} px="6">
        <ActionsMenu
          menuZIndex="tooltip"
          items={[
            {
              content: 'Mover para próxima fatura',
              onClick: () => {
                handleMoverDesmembramentoFinanceiro(
                  TipoMovimentacaoDesmembramentoEnum.PROXIMO,
                  `R$ ${DecimalMask(
                    desmembramentoFinanceiro.valor,
                    casasDecimaisValor,
                    casasDecimaisValor
                  )}`
                );
              },
              _hover: {
                bg: 'gray.100',
              },
            },
            {
              content: 'Mover para anterior',
              onClick: () => {
                handleMoverDesmembramentoFinanceiro(
                  TipoMovimentacaoDesmembramentoEnum.ANTERIOR,
                  `R$ ${DecimalMask(
                    desmembramentoFinanceiro.valor,
                    casasDecimaisValor,
                    casasDecimaisValor
                  )}`
                );
              },
              _hover: {
                bg: 'gray.100',
              },
            },
            {
              content: desmembramentoFinanceiro.conciliado
                ? 'Desconciliar'
                : 'Conciliar',
              onClick: desmembramentoFinanceiro.conciliado
                ? handleDesconciliar
                : handleConciliar,
              _hover: {
                bg: 'gray.100',
              },
            },
            {
              content: 'Excluir',
              onClick: handleExcluir,
              _hover: {
                bg: 'gray.100',
              },
            },
          ]}
        />
      </Flex>
    </Flex>
  );
}
