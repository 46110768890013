import React, { useCallback, useEffect, useState } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Icon,
  Flex,
  Text,
  HStack,
  Box,
  useDisclosure,
  useToken,
} from '@chakra-ui/react';
import { DotLoader } from 'react-spinners';
import { create, InstanceProps } from 'react-modal-promise';
import { toast } from 'react-toastify';
import { HubConnection } from '@microsoft/signalr';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import { EmitindoNotaIcon } from 'icons';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import TipoCertificadoEnum from 'constants/enum/fiscal/tipoCertificado';
import { RejeicaoInterface } from '../BoxRejeicao';

export type ModalTransmissaoNotaResponse = {
  success: boolean;
  rejeicao?: RejeicaoInterface;
  emitiuAmbasNotas?: boolean;
};

interface ModalTransmissaoNotaProps
  extends Omit<ModalProps, 'children' | 'isOpen' | 'onClose'>,
    InstanceProps<ModalTransmissaoNotaResponse> {
  title?: string;
  documentoFiscalId: string;
  hubConnection: HubConnection;
  joinGroup: (group: string) => Promise<unknown>;
  exitGroup: (group: string) => Promise<void>;
}

const ModalTransmissaoNotaComponent = ({
  title = 'Nota Fiscal',
  documentoFiscalId,
  hubConnection,
  joinGroup,
  exitGroup,
  onResolve,
  ...props
}: ModalTransmissaoNotaProps) => {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const [message, setMessage] = useState<string>();

  const tratarAviso = useCallback(
    (aviso: string) => {
      try {
        const rejeicao = JSON.parse(aviso) as RejeicaoInterface;

        onResolve({
          success: false,
          rejeicao,
        });
      } catch {
        onResolve({
          success: false,
          rejeicao: { Mensagem: aviso },
        });
      }
    },
    [onResolve]
  );

  useEffect(() => {
    const transmitirNotaFiscal = async () => {
      setMessage('Enviando a nota fiscal para a SEFAZ...');

      joinGroup(`${documentoFiscalId}_transmitindo-nota`);

      hubConnection.on('alterar-status-transmissao', (messageHub: string) => {
        setMessage(messageHub);
      });

      const response = await api.post<void, ResponseApi<number | undefined>>(
        `${ConstanteEnderecoWebservice.NOTA_FISCAL_TRANSMITIR}/${documentoFiscalId}`
      );

      if (response) {
        if (response.sucesso) {
          if (
            response.dados &&
            response.dados === Number(TipoCertificadoEnum.A3)
          ) {
            const timeout = window.setTimeout(
              () => {
                onResolve({
                  success: false,
                  rejeicao: {
                    Mensagem:
                      'A comunicação com o certificado digital A3 falhou, verifique se o certificado digital está conectado no computador e se o aplicativo Módulo desktop está sendo executado.',
                  },
                });
              },
              1000 * 60 // 1 minuto
            );

            hubConnection.off('sucesso-transmissao-a3');
            hubConnection.on('sucesso-transmissao-a3', () => {
              window.clearTimeout(timeout);

              toast.success('A nota fiscal foi transmitida com sucesso.');
              onClose();
              onResolve({ success: true });
            });

            hubConnection.off('rejeicao-transmissao-a3');
            hubConnection.on(
              'rejeicao-transmissao-a3',
              (messageHub: string) => {
                window.clearTimeout(timeout);

                tratarAviso(messageHub);
              }
            );

            return;
          }

          if (response.dados === null) {
            onClose();
            onResolve({ success: response.sucesso, emitiuAmbasNotas: false });
          } else {
            toast.success('A nota fiscal foi transmitida com sucesso.');
            onClose();
            onResolve({ success: response.sucesso, emitiuAmbasNotas: true });
          }
        }

        if (response.avisos) {
          tratarAviso(response.avisos[0]);
        }
      }
    };

    transmitirNotaFiscal();
  }, [
    documentoFiscalId,
    hubConnection,
    joinGroup,
    onClose,
    onResolve,
    tratarAviso,
  ]);

  return (
    <ModalPadraoChakra
      isCentered
      size="xl"
      {...props}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalContent
        marginBottom={{ base: 0, sm: '3.75rem' }}
        marginTop={{ base: 0, sm: '3.75rem' }}
        h="unset"
        maxW="550px"
        mx={4}
      >
        <ModalBody px={9} py={16}>
          <Flex alignItems="center" justifyContent="space-between" h="full">
            <HStack spacing={6} mr={6}>
              <Icon as={EmitindoNotaIcon} boxSize={16} color="gray.700" />
              <Flex flexDirection="column">
                <Text fontSize="xl" fontWeight="bold" color="gray.700">
                  {title}
                </Text>
                <Text fontSize="lg" color="primary.50">
                  {message}
                </Text>
              </Flex>
            </HStack>

            <Box position="relative">
              <DotLoader size={24} color="var(--sti-ck-colors-loading)" />
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export const ModalTransmissaoNota = create<ModalTransmissaoNotaProps>(
  ModalTransmissaoNotaComponent
);
