import { useCallback, useEffect, useState } from 'react';
import {
  ModalContent,
  Button,
  useDisclosure,
  Flex,
  ModalFooter,
  Box,
  ModalHeader,
  useMediaQuery,
  GridItem,
  Text,
  Icon,
  IconButton,
  Tabs,
  FormLabel,
  TabList,
  Tab,
  ModalBody,
  TabPanels,
  TabPanel,
  Tooltip,
} from '@chakra-ui/react';
import { create } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import enumReferenciaServicoStargate from 'constants/enum/referenciaServicoStargate';
import auth from 'modules/auth';

import ImageUploader from 'components/ImagePicker/ImageUploader';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import Input from 'components/PDV/Input';
import CheckboxAtivoInativo from 'components/update/Checkbox/CheckboxAtivoInativo';
import AsyncSelect from 'components/PDV/Select/AsyncSelectPadrao';
import AsyncCreatableSelect from 'components/PDV/Select/AsyncCreatableSelectPadrao';
import { ButtonDefault } from 'components/Button/ButtonChakra';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { Checkbox } from 'components/update/Checkbox';
import { InfoIcon, LixeiraIcon, OutrasDespesasIcon } from 'icons';

import {
  ModalAdicionarDescricaoProps,
  FormData,
  Option,
  ResponseApiAdd,
  ResponseApiRemove,
} from './types';
import { useCategory, useComplementos, useObservacoes } from './hooks';

export const ModalEditarCategoria = create<
  ModalAdicionarDescricaoProps,
  FormData
>(({ onResolve, onReject, categoriaProduto, ...rest }) => {
  const [isLoading, setIsLoading] = useState({
    loading: false,
    loadingComplements: false,
    loadingObservacoes: false,
  });

  const [selectedComplement, setSelectedComplement] = useState<Option | null>(
    null
  );

  const [listComplements, setListComplements] = useState<Option[]>([]);
  const [listObservacoes, setListObservacoes] = useState<Option[]>([]);

  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const formMethods = useForm<FormData>({
    defaultValues: {
      ...categoriaProduto,
      complemento: null,
      observacoes: null,
    },
  });

  const { submitChanges } = useCategory();
  const {
    loadProductComplementOptions,
    addComplement,
    removeComplement,
    getComplementosFromCategory,
  } = useComplementos();
  const {
    loadObservationsOptions,
    addObservacao,
    removeObservacao,
    createNewObservation,
    getObservationsFromCategory,
  } = useObservacoes();

  const { id: categoriaId } = categoriaProduto;

  const hasFrenteCaixaService = auth.possuiServico(
    enumReferenciaServicoStargate.DISPOSITIVO_FRENTE_CAIXA
  ).permitido;
  const isSubCategory = !!categoriaProduto?.categoriaProdutoPaiId; // Caso haja categoriaProdutoPaiId quer dizer que a categoria é subcategoria e não categoria principal
  const showFrenteCaixaOptions = hasFrenteCaixaService;

  const { handleSubmit: onSubmit, setValue, watch } = formMethods;

  const selectedObservacao = watch('observacoes');

  const handleSubmit = onSubmit(async (data) => {
    setIsLoading((prev) => ({ ...prev, loading: true }));
    const success = await submitChanges(data, categoriaProduto, isSubCategory);
    setIsLoading((prev) => ({ ...prev, loading: false }));
    if (success) {
      onResolve();
      onClose();
    }
  });

  const handleLoadOptionsProductsComplements = useCallback(
    async (inputValue: string) => {
      setIsLoading((prev) => ({ ...prev, loadingComplements: true }));

      const response = await loadProductComplementOptions(inputValue);

      setIsLoading((prev) => ({ ...prev, loadingComplements: false }));

      return response;
    },
    [loadProductComplementOptions]
  );

  const handleLoadOptionsObservations = useCallback(
    async (inputValue: string) => {
      setIsLoading((prev) => ({ ...prev, loadingObservacoes: true }));

      const response = await loadObservationsOptions(inputValue);

      setIsLoading((prev) => ({ ...prev, loadingObservacoes: false }));

      return response;
    },
    [loadObservationsOptions]
  );

  const fetchComplementosByCategory = useCallback(async () => {
    setIsLoading((prev) => ({ ...prev, loading: true }));
    const complementos = await getComplementosFromCategory(categoriaId);

    if (complementos) {
      setListComplements(
        complementos.map((complemento) => ({
          label: complemento.nomeProduto,
          value: complemento.id,
          produtoId: complemento.produtoId,
        }))
      );
    }
    setIsLoading((prev) => ({ ...prev, loading: false }));
  }, [categoriaId, getComplementosFromCategory]);

  const fetchObservationsByCategory = useCallback(async () => {
    setIsLoading((prev) => ({ ...prev, loading: true }));
    const observations = await getObservationsFromCategory(categoriaId);

    if (observations) {
      setListObservacoes(
        observations.map((observation) => ({
          label: observation.observacao,
          value: observation.id,
          produtoObservacaoId: observation.produtoObservacaoId,
        }))
      );
    }
    setIsLoading((prev) => ({ ...prev, loading: false }));
  }, [categoriaId, getObservationsFromCategory]);

  const handleUpdateComplementsList = async (
    action: 'add' | 'remove',
    id: string
  ) => {
    setIsLoading((prev) => ({ ...prev, loading: true }));

    let response: {
      success: boolean;
      id?: string;
    };

    const actionCallback = action === 'add' ? addComplement : removeComplement;

    if (action === 'add') {
      if (!selectedComplement) return;
      const complementAlreadyAdded = listComplements?.find(
        (c) => c.produtoId === id
      );

      if (complementAlreadyAdded) {
        toast.warn('Complemento já adicionado');
        setIsLoading((prev) => ({ ...prev, loading: false }));
        return;
      }

      response = await (actionCallback as ResponseApiAdd)(categoriaId, id);

      if (response && response.success && response.id) {
        setListComplements((prev) => [
          ...prev,
          {
            label: selectedComplement.label,
            value: response.id || '',
            produtoId: id,
          },
        ]);

        setValue('complemento', null);
      }
    } else if (action === 'remove') {
      response = await (actionCallback as ResponseApiRemove)(id);

      if (response.success) {
        setListComplements((prev) =>
          prev.filter((complement) => complement.value !== id)
        );
      }
    }
    setIsLoading((prev) => ({ ...prev, loading: false }));
  };

  const handleUpdateObservationsList = async (
    action: 'add' | 'remove',
    id: string
  ) => {
    setIsLoading((prev) => ({ ...prev, loading: true }));

    let response: {
      success: boolean;
      id?: string;
    };

    const actionCallback = action === 'add' ? addObservacao : removeObservacao;

    if (action === 'add') {
      if (!selectedObservacao) return;

      const observationAlreadyAdded = listObservacoes?.find(
        (o) => o.produtoObservacaoId === id
      );

      if (observationAlreadyAdded) {
        toast.warn('Observação já adicionada');
        setIsLoading((prev) => ({ ...prev, loading: false }));
        return;
      }

      response = await (actionCallback as ResponseApiAdd)(categoriaId, id);

      if (response && response.success && response.id) {
        setListObservacoes((prev) => [
          ...prev,
          {
            label: selectedObservacao.label,
            value: response.id || '',
            produtoObservacaoId: id,
          },
        ]);

        setValue('observacoes', null);
      }
    } else if (action === 'remove') {
      response = await (actionCallback as ResponseApiRemove)(id);

      if (response && response.success) {
        setListObservacoes((prev) =>
          prev.filter((observation) => observation.value !== id)
        );
      }
    }
    setIsLoading((prev) => ({ ...prev, loading: false }));
  };

  const handleCreateNewObservacao = async (observacao: string) => {
    setIsLoading((prev) => ({ ...prev, loading: true }));
    const newObservation = await createNewObservation(observacao);
    setIsLoading((prev) => ({ ...prev, loading: false }));
    return newObservation;
  };

  const handleUpdateImage = (value: {
    linkImagem: string;
    isForaPadrao: boolean;
  }) => {
    if (value.isForaPadrao) {
      toast.error('A imagem deve ter no máximo 200KB');
      return;
    }
    setValue('foto', value.linkImagem);
  };

  useEffect(() => {
    if (showFrenteCaixaOptions) {
      fetchComplementosByCategory();
      fetchObservationsByCategory();
    }
  }, [
    fetchComplementosByCategory,
    fetchObservationsByCategory,
    showFrenteCaixaOptions,
  ]);

  return (
    <ModalPadraoChakra
      isCentered={isLargerThan900}
      size={isLargerThan900 ? '2xl' : 'full'}
      {...rest}
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={false}
    >
      <FormProvider {...formMethods}>
        <ModalContent bg="gray.50" height="fit-content">
          <ModalHeader pt="16px" pl="24px">
            <Text color="primary.50" fontSize="16px">
              Editar categoria
            </Text>
          </ModalHeader>
          <ModalBody height="600px" px="0px">
            {isLoading.loading && <LoadingPadrao />}
            <Flex w="full" height="full">
              <Tabs
                variant="line"
                position="relative"
                borderRadius="6px"
                w="full"
                px="24px"
              >
                {showFrenteCaixaOptions && (
                  <TabList>
                    <>
                      <Tab
                        color="black"
                        fontSize="sm"
                        w="120px"
                        boxShadow="none"
                        _focus={{ boxShadow: 'none' }}
                        _selected={{
                          color: 'primary.500',
                          borderColor: '#5502b260',
                          fontWeight: 'bold',
                        }}
                      >
                        Geral
                      </Tab>
                      <Tab
                        color="black"
                        fontSize="sm"
                        w="120px"
                        boxShadow="none"
                        _focus={{ boxShadow: 'none' }}
                        _selected={{
                          color: 'primary.500',
                          borderColor: '#5502b260',
                          fontWeight: 'bold',
                        }}
                      >
                        Complementos
                      </Tab>

                      <Tab
                        boxShadow="none"
                        w="120px"
                        _focus={{ boxShadow: 'none' }}
                        _selected={{
                          color: 'primary.500',
                          borderColor: '#5502b260',
                          fontWeight: 'bold',
                        }}
                        color="black"
                        fontSize="sm"
                      >
                        Observações
                      </Tab>
                    </>
                  </TabList>
                )}

                <TabPanels position="relative" boxShadow="none">
                  <TabPanel
                    bg="white"
                    border="1px solid #ddd"
                    padding="24px"
                    borderRadius="md"
                    height={
                      showFrenteCaixaOptions
                        ? '["fit-content","420px"]'
                        : 'fit-content'
                    }
                    mt={showFrenteCaixaOptions ? '16px' : '0px'}
                  >
                    <Flex
                      flexDir="column"
                      justifyContent="center"
                      align="flex-start"
                      gap="24px"
                      w="full"
                    >
                      <Flex
                        justifyContent="flex-start"
                        w="full"
                        align="flex-start"
                        gap="24px"
                      >
                        <Box>
                          <Flex w="full">
                            <FormLabel mb="0px">Imagem</FormLabel>
                            <Text ml="auto" display="inline">
                              <Tooltip
                                hasArrow
                                maxW="500px"
                                placement="right"
                                label="As imagens devem ter no máximo 200KB"
                                ml={1}
                                p="10px"
                                bg="black"
                                borderRadius="6px"
                                tabIndex={-1}
                              >
                                <Box tabIndex={-1}>
                                  <Icon as={InfoIcon} w="15px" h="15px" />
                                </Box>
                              </Tooltip>
                            </Text>
                          </Flex>
                          <ImageUploader
                            handleSalvarImagem={(value) => {
                              handleUpdateImage(value);
                            }}
                            totalBytes={200}
                            multiple={false}
                            isAlterarImagem
                            borderWidth="1px"
                            borderColor="gray.200"
                            bg="white"
                            borderRadius="6px"
                            deleteCallback={() => setValue('foto', '')}
                            imageDefault={categoriaProduto.foto}
                            boxSize="120px"
                            cursor="pointer"
                            justifyContent="center"
                            transition="border-color 0.2s"
                            alignItems="center"
                            fontSizeActionText="sm"
                            fontSizeInstructionText="xs"
                            color="gray.700"
                            sizeIcon="24px"
                          />
                        </Box>
                        <Flex
                          height="136px"
                          mt="4px"
                          w="full"
                          flexDir="column"
                          justify="space-between"
                        >
                          <Input
                            name="nome"
                            label="Nome"
                            placeholder="Digite o nome da categoria"
                            isRequired
                            w="full"
                          />
                          <Box alignSelf="flex-end">
                            <CheckboxAtivoInativo
                              id="ativo"
                              isRequired={false}
                              w="fit-content"
                              name="ativo"
                              label="Status"
                            />
                          </Box>
                        </Flex>
                      </Flex>
                    </Flex>
                    {showFrenteCaixaOptions && (
                      <SimpleGridForm mt="12px">
                        <GridItem colSpan={[12, 6]}>
                          <FormLabel>Configurações:</FormLabel>
                          <Flex
                            flexDir="column"
                            gap="16px"
                            w="100%"
                            borderRadius="5px"
                            px="16px"
                            py="24px"
                            borderColor="gray.200"
                            bg="gray.50"
                            borderWidth="1px"
                            height="170px"
                          >
                            <Flex
                              flexDir="row"
                              gap="12px"
                              align="flex-end"
                              height="24px"
                            >
                              <Checkbox
                                id="sincronizarNoAplicativo"
                                name="sincronizarNoAplicativo"
                                boxShadow="inset 0px 3px 6px #00000029"
                                bg="white"
                                w="fit-content"
                              />
                              <FormLabel
                                fontSize="sm"
                                lineHeight="1"
                                color="black"
                                cursor="pointer"
                                htmlFor="sincronizarNoAplicativo"
                                userSelect="none"
                              >
                                Sincronizar com aplicativos
                              </FormLabel>
                            </Flex>
                            <Flex
                              flexDir="row"
                              gap="12px"
                              align="flex-end"
                              height="24px"
                            >
                              <Checkbox
                                id="ocultarNoAplicativo"
                                name="ocultarNoAplicativo"
                                boxShadow="inset 0px 3px 6px #00000029"
                                bg="white"
                                w="fit-content"
                              />
                              <FormLabel
                                fontSize="sm"
                                lineHeight="1"
                                color="black"
                                cursor="pointer"
                                htmlFor="ocultarNoAplicativo"
                                userSelect="none"
                              >
                                Ocultar nos aplicativos
                              </FormLabel>
                            </Flex>
                            <Flex
                              flexDir="row"
                              gap="12px"
                              align="flex-start"
                              height="fit-content"
                            >
                              <Checkbox
                                id="permiteFracionamento"
                                name="permiteFracionamento"
                                boxShadow="inset 0px 3px 6px #00000029"
                                bg="white"
                                w="fit-content"
                              />
                              <FormLabel
                                fontSize="sm"
                                lineHeight="1"
                                color="black"
                                cursor="pointer"
                                htmlFor="permiteFracionamento"
                                userSelect="none"
                              >
                                Permitir fracionamento dos produtos
                              </FormLabel>
                            </Flex>
                          </Flex>
                        </GridItem>
                        <GridItem colSpan={[12, 6]}>
                          <FormLabel>Utilizar em:</FormLabel>
                          <Flex
                            flexDir="column"
                            gap="16px"
                            w="100%"
                            borderRadius="5px"
                            px="16px"
                            py="24px"
                            height="170px"
                            borderColor="gray.200"
                            bg="gray.50"
                            borderWidth="1px"
                          >
                            <Flex
                              flexDir="row"
                              gap="12px"
                              align="flex-end"
                              height="24px"
                            >
                              <Checkbox
                                id="controleDeMesas"
                                name="controleDeMesas"
                                boxShadow="inset 0px 3px 6px #00000029"
                                bg="white"
                                w="fit-content"
                              />
                              <FormLabel
                                fontSize="sm"
                                lineHeight="1"
                                color="black"
                                cursor="pointer"
                                htmlFor="controleDeMesas"
                                userSelect="none"
                              >
                                Controle de mesas
                              </FormLabel>
                            </Flex>
                            <Flex
                              flexDir="row"
                              gap="12px"
                              align="flex-end"
                              height="24px"
                            >
                              <Checkbox
                                id="delivery"
                                name="delivery"
                                boxShadow="inset 0px 3px 6px #00000029"
                                bg="white"
                                w="fit-content"
                              />
                              <FormLabel
                                fontSize="sm"
                                lineHeight="1"
                                color="black"
                                cursor="pointer"
                                htmlFor="delivery"
                                userSelect="none"
                              >
                                Delivery
                              </FormLabel>
                            </Flex>
                            <Flex
                              flexDir="row"
                              gap="12px"
                              align="flex-end"
                              height="24px"
                            >
                              <Checkbox
                                w="fit-content"
                                id="vendaBalcao"
                                name="vendaBalcao"
                                boxShadow="inset 0px 3px 6px #00000029"
                                bg="white"
                              />
                              <FormLabel
                                fontSize="sm"
                                lineHeight="1"
                                color="black"
                                cursor="pointer"
                                htmlFor="vendaBalcao"
                                userSelect="none"
                              >
                                Venda balcão
                              </FormLabel>
                            </Flex>
                          </Flex>
                        </GridItem>
                      </SimpleGridForm>
                    )}
                  </TabPanel>
                  <TabPanel
                    height="420px"
                    mt="16px"
                    padding="0px"
                    borderRadius="md"
                  >
                    <Flex
                      flexDir="row"
                      align="center"
                      borderRadius="md"
                      gap="12px"
                      padding="24px"
                      bg="white"
                    >
                      <AsyncSelect
                        id="complemento"
                        name="complemento"
                        label="Complemento"
                        placeholder="Selecione ou informe um complemento"
                        required
                        setValor={setSelectedComplement}
                        handleGetOptions={handleLoadOptionsProductsComplements}
                        asControlledByObject
                        withoutDefaultOptions
                        isLoading={isLoading?.loadingComplements}
                        autoFocus
                      />
                      <IconButton
                        _disabled={{ bg: 'gray.300' }}
                        _hover={
                          !selectedComplement || isLoading?.loadingComplements
                            ? {
                                bg: 'gray.300',
                              }
                            : { bg: 'secondary.400' }
                        }
                        aria-label="Adicionar"
                        bg={!selectedComplement ? 'gray.300' : 'secondary.500'}
                        _active={{
                          bg:
                            !selectedComplement || isLoading?.loadingComplements
                              ? 'gray.300'
                              : 'secondary.500',
                        }}
                        mt="16px"
                        borderRadius="md"
                        icon={<Icon as={OutrasDespesasIcon} fontSize="xl" />}
                        isDisabled={
                          !selectedComplement || isLoading?.loadingComplements
                        }
                        onClick={
                          !selectedComplement || isLoading?.loadingComplements
                            ? () => {}
                            : () =>
                                handleUpdateComplementsList(
                                  'add',
                                  selectedComplement?.value
                                )
                        }
                      />
                    </Flex>
                    <Flex
                      flexDir="column"
                      gap="8px"
                      maxH="300px"
                      align="flex-start"
                      mt="12px"
                      pb="4px"
                      pr="4px"
                      overflowY="auto"
                    >
                      {listComplements?.length > 0 ? (
                        listComplements?.map((item) => (
                          <Flex
                            pl="24px"
                            pr="20px"
                            py="12px"
                            borderRadius="md"
                            bg="white"
                            height="50px"
                            justifyContent="space-between"
                            alignItems="center"
                            w="full"
                          >
                            <Text>{item.label}</Text>
                            <IconButton
                              aria-label="Remover"
                              bg="transparent"
                              _hover={{
                                ...{
                                  '& svg': { color: 'red.500' },
                                },
                              }}
                              borderRadius="4px"
                              icon={
                                <Icon
                                  as={LixeiraIcon}
                                  transition="all 0.2s"
                                  color="black"
                                  fontSize="lg"
                                />
                              }
                              onClick={() =>
                                handleUpdateComplementsList(
                                  'remove',
                                  item.value
                                )
                              }
                            />
                          </Flex>
                        ))
                      ) : (
                        <Flex
                          px="24px"
                          py="12px"
                          borderRadius="md"
                          bg="white"
                          height="50px"
                          justifyContent="flex-start"
                          alignItems="center"
                          userSelect="none"
                          w="full"
                        >
                          Nenhum produto foi adicionado
                        </Flex>
                      )}
                    </Flex>
                  </TabPanel>
                  <TabPanel
                    height="420px"
                    mt="16px"
                    padding="0px"
                    borderRadius="md"
                  >
                    <Flex
                      flexDir="row"
                      align="center"
                      borderRadius="md"
                      gap="12px"
                      padding="24px"
                      bg="white"
                    >
                      <AsyncCreatableSelect
                        id="observacoes"
                        name="observacoes"
                        label="Observação"
                        placeholder="Selecione ou informe uma observação"
                        required
                        handleGetOptions={handleLoadOptionsObservations}
                        isLoading={isLoading?.loadingObservacoes}
                        withoutDefaultOptions
                        creatableInputTextPreffix="Cadastrar observação"
                        handleCreateOption={handleCreateNewObservacao}
                        asControlledByObject
                        autoFocus
                        isClearable
                      />

                      <IconButton
                        _disabled={{ bg: 'gray.300' }}
                        _hover={
                          !selectedObservacao || isLoading?.loadingObservacoes
                            ? {
                                bg: 'gray.300',
                              }
                            : { bg: 'secondary.400' }
                        }
                        aria-label="Adicionar"
                        bg={
                          !selectedObservacao || isLoading?.loadingObservacoes
                            ? 'gray.300'
                            : 'secondary.500'
                        }
                        _active={{
                          bg:
                            !selectedObservacao || isLoading?.loadingObservacoes
                              ? 'gray.300'
                              : 'secondary.500',
                        }}
                        mt="16px"
                        borderRadius="md"
                        icon={<Icon as={OutrasDespesasIcon} fontSize="xl" />}
                        isDisabled={
                          !selectedObservacao || isLoading?.loadingObservacoes
                        }
                        onClick={
                          !selectedObservacao || isLoading?.loadingObservacoes
                            ? () => {}
                            : () =>
                                handleUpdateObservationsList(
                                  'add',
                                  selectedObservacao?.value
                                )
                        }
                      />
                    </Flex>
                    <Flex
                      flexDir="column"
                      gap="8px"
                      maxH="300px"
                      align="flex-start"
                      mt="12px"
                      pb="4px"
                      pr="4px"
                      overflowY="auto"
                    >
                      {listObservacoes?.length > 0 ? (
                        listObservacoes?.map((item) => (
                          <Flex
                            pl="24px"
                            pr="20px"
                            py="12px"
                            borderRadius="md"
                            bg="white"
                            height="50px"
                            justifyContent="space-between"
                            alignItems="center"
                            w="full"
                          >
                            <Text>{item.label}</Text>
                            <IconButton
                              aria-label="Remover"
                              bg="transparent"
                              _hover={{
                                ...{
                                  '& svg': { color: 'red.500' },
                                },
                              }}
                              borderRadius="4px"
                              icon={
                                <Icon
                                  as={LixeiraIcon}
                                  transition="all 0.2s"
                                  color="black"
                                  fontSize="lg"
                                />
                              }
                              onClick={() =>
                                handleUpdateObservationsList(
                                  'remove',
                                  item.value
                                )
                              }
                            />
                          </Flex>
                        ))
                      ) : (
                        <Flex
                          px="24px"
                          py="12px"
                          borderRadius="md"
                          bg="white"
                          height="50px"
                          justifyContent="flex-start"
                          alignItems="center"
                          userSelect="none"
                          w="full"
                        >
                          Nenhuma observação foi adicionada
                        </Flex>
                      )}
                    </Flex>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Flex>
          </ModalBody>
          <ModalFooter flexDirection="column">
            <Flex
              w="full"
              h="full"
              justifyContent="center"
              gap="24px"
              alignItems="baseline"
            >
              <Button
                variant="outlineDefault"
                borderRadius="16px"
                fontSize="sm"
                h={isLargerThan900 ? '32px' : '40px'}
                onClick={() => onClose()}
                w="96px"
              >
                Cancelar
              </Button>
              <ButtonDefault
                variant="solid"
                color="white"
                borderRadius="16px"
                colorScheme="aquamarine.600"
                h={isLargerThan900 ? '32px' : '40px'}
                width="120px"
                onClick={() => handleSubmit()}
                isLoading={isLoading.loading}
              >
                Salvar
              </ButtonDefault>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </FormProvider>
    </ModalPadraoChakra>
  );
});
