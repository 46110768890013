import React, { useEffect, useState, useCallback } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory, Prompt, RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import isPrenvent from 'helpers/layout/isPrenvent';
import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import MeioPagamentoEnum from 'constants/enum/fiscal/meioPagamento';

import RodapeFormulario from 'components/Geral/RodapeFormulario';
import ManterFoco from 'components/Geral/ManterFoco';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { validacaoContaFinanceira } from 'pages/FormaRecebimento/naoPossuiContaFinanceira';

import { Card } from 'styles';
import TipoFormaPagamentoRecebimentoEnum from 'constants/enum/tipoFormaPagamentoRecebimento';
import auth from 'modules/auth';

import { FormaPagamentoRecebimentoLojas, StoneConfig } from '../Types';
import { useForm, yupResolver } from '../validationForm';
import { Container } from '../styles';

// eslint-disable-next-line import/no-named-as-default
import UncontrolledForm from '..';

type Parcelas = {
  acrescimoPorcentagem: number;
  acrescimoValor: number;
  descricao: string;
  id: string;
  intervaloDias: number;
  qtdeParcela: number;
  taxaTransacaoPorcentagem: number;
  taxaTransacaoValor: number;
};

type ContaFinanceira = {
  id: string;
  nome: string;
};

type FormaPagamentoResponse = {
  ativo: boolean;
  chefMobile: boolean;
  autoAtendimento: boolean;
  cnpjCredenciadora: string | null;
  contaFinanceiraOpcaoSelect: ContaFinanceira | null;
  dataHoraCadastro: string;
  dataHoraUltimaAlteracao: string;
  diaVencimento: number;
  diasCarencia: number;
  formaPagamentoRecebimentoLojas: FormaPagamentoRecebimentoLojas[];
  icone: null | string;
  id: string;
  integracaoPagamento: null | number;
  lancarFatura: boolean;
  nome: string;
  parcelas: Parcelas[];
  regraMeioPagamento: number;
  tipoCobrancaJuros: number;
  tipoCobrancaMulta: number;
  tipoFormaPagamentoRecebimento: number;
  tipoValorJuros: number;
  tipoValorMulta: number;
  valorJuros: number;
  valorMulta: number;
};

type TParams = { id: string };

const Alterar = ({ match }: RouteComponentProps<TParams>) => {
  const history = useHistory();
  const isMountedRef = useIsMountedRef();
  isPrenvent();

  const [isLoading, setIsLoading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);

  const [dataHoraCadastro, setDataHoraCadastro] = useState('');
  const [dataHoraUltimaAlteracao, setDataHoraUltimaAlteracao] = useState('');
  const [
    formaPagamentoRecebimentoLoja,
    setFormaPagamentoRecebimentoLoja,
  ] = useState<FormaPagamentoRecebimentoLojas[]>([]);

  const [contaFinanceiraLoja, setContaFinanceiraLoja] = useState<
    FormaPagamentoRecebimentoLojas[]
  >([]);

  const [meioPagamento, setMeioPagamento] = useState(-1);

  const naoPossuiContaFinanceira = validacaoContaFinanceira(meioPagamento);

  const {
    handleSubmit,
    setError,
    clearErrors,
    watch,
    register,
    control,
    reset,
    getValues,
    setValue,
    formState,
  } = useForm({
    resolver: yupResolver,
    shouldUnregister: false,
  });

  const { id: idLojaAtual } = auth.getLoja();

  const contaFinanceiraWatch = watch('contaFinanceira');

  const listMeioPagamento = Object.entries(MeioPagamentoEnum.properties).map(
    (item) => {
      return {
        name: item[1].name,
        value: item[1].value,
      };
    }
  );

  const handleGetFormaPagamento = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<void, ResponseApi<FormaPagamentoResponse>>(
      ConstanteEnderecoWebservice.FORMA_PAGTO_OBTER,
      {
        params: { id: match.params.id },
      }
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response?.sucesso && isMountedRef.current) {
        setFormaPagamentoRecebimentoLoja(
          response.dados.formaPagamentoRecebimentoLojas
        );

        reset({
          ...response.dados,
          IntegracaoPagamento: response.dados.integracaoPagamento,
          cnpjCredenciadora: response.dados.cnpjCredenciadora,
        });

        setMeioPagamento(response.dados.regraMeioPagamento);
        const contaFinanceiraValue = response.dados.formaPagamentoRecebimentoLojas.find(
          (conta) => conta.lojaId === idLojaAtual
        );
        setValue('contaFinanceira', contaFinanceiraValue?.contaFinanceiraId);

        if (response.dados.parcelas) {
          setValue('parcelas', response.dados.parcelas);
        }

        setDataHoraCadastro(response.dados.dataHoraCadastro);
        setDataHoraUltimaAlteracao(response.dados.dataHoraUltimaAlteracao);
      } else {
        history.push(ConstanteRotas.FORMA_RECEB);
      }
    }

    if (isMountedRef.current) setIsLoading(false);
  }, [history, idLojaAtual, isMountedRef, match.params.id, reset, setValue]);

  const salvarConfigStone = async () => {
    const { serialStone, stoneCode } = getValues();

    const data: StoneConfig[] = [];

    Object?.keys(serialStone)?.forEach((lojaId) => {
      const stoneCodeLoja = stoneCode[lojaId];
      const listSerials = serialStone[lojaId];

      const caixas = Object?.keys(listSerials)?.map((idCaixa) => {
        return {
          id: idCaixa,
          serialStone: listSerials[idCaixa] || null,
        };
      });

      data.push({
        lojaId,
        stoneCode: stoneCodeLoja || null,
        listaIdNome: caixas,
      });
    });

    const response = await api.put<void, ResponseApi>(
      ConstanteEnderecoWebservice.CONTA_FINANCEIRA_ALTERAR_STONE,
      data
    );
    if (response) {
      if (response?.avisos) {
        response?.avisos?.forEach((aviso: string) => toast.warning(aviso));
      }

      if (response?.sucesso) {
        return true;
      }
    }

    return false;
  };

  const onSubmit = handleSubmit(async () => {
    setIsLoading(true);

    const data = getValues();
    const isMeioPagamentoZoop =
      meioPagamento === MeioPagamentoEnum.PixEnvioZoop ||
      meioPagamento === MeioPagamentoEnum.BoletoZoop ||
      meioPagamento === MeioPagamentoEnum.PixPresencialZoop;

    if (isMeioPagamentoZoop) {
      data.nome = listMeioPagamento.find(
        (item) => item.value === meioPagamento
      )?.name;
    }

    data.IntegracaoPagamento = data.IntegracaoPagamento
      ? data.IntegracaoPagamento
      : null;
    data.cnpjCredenciadora = data.cnpjCredenciadora
      ? data.cnpjCredenciadora
      : null;
    data.contaFinanceiraId = data.contaFinanceira || undefined;

    const newContasFinanceirasLoja = (contaFinanceiraLoja.length > 1
      ? contaFinanceiraLoja
      : formaPagamentoRecebimentoLoja
    ).map((contaFinanceira) => ({
      ...contaFinanceira,
      contaFinanceiraId:
        contaFinanceira.lojaId === idLojaAtual
          ? data.contaFinanceira
          : contaFinanceira.contaFinanceiraId,
    }));
    delete data.formaPagamentoRecebimentoLojas;
    const dadosFormaPagamento = {
      ...data,
      tipoFormaPagamentoRecebimento:
        TipoFormaPagamentoRecebimentoEnum.Recebimento,
    };

    const valorContaFinanceiraLojaAtual: FormaPagamentoRecebimentoLojas = {
      contaFinanceiraId: contaFinanceiraWatch,
      lojaId: idLojaAtual,
    };

    const valoresContaFinanceira = [
      ...newContasFinanceirasLoja,
      valorContaFinanceiraLojaAtual,
    ];

    const response = await api.put<void, ResponseApi>(
      ConstanteEnderecoWebservice.FORMA_PAGTO_ALTERAR,
      naoPossuiContaFinanceira
        ? {
            ...dadosFormaPagamento,
          }
        : {
            ...dadosFormaPagamento,
            formaPagamentoRecebimentoLojas: valoresContaFinanceira,
          }
    );

    if (response.sucesso) {
      if (
        meioPagamento === MeioPagamentoEnum.CartaoCreditoStone ||
        meioPagamento === MeioPagamentoEnum.CartaoDebitoStone
      ) {
        await salvarConfigStone();
      }
      toast.success('O cadastro foi alterado com sucesso.');

      setFormIsDirty(false);

      history.push(ConstanteRotas.FORMA_RECEB);

      return;
    }

    if (response.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (isMountedRef.current) setIsLoading(false);
  });

  useEffect(() => {
    setIsLoading(true);

    handleGetFormaPagamento();
  }, [handleGetFormaPagamento]);

  useEffect(() => {
    setFormIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  return (
    <Container>
      <Prompt when={formIsDirty} message="" />
      <Form>
        <ManterFoco blockTab={isLoading}>
          <Card>
            {isLoading && <LoadingPadrao />}
            <UncontrolledForm
              errors={formState.errors}
              control={control}
              formaPagamentoRecebimentoLoja={formaPagamentoRecebimentoLoja}
              watch={watch}
              setContaFinanceiraLoja={setContaFinanceiraLoja}
              register={register}
              getValues={getValues}
              setValue={setValue}
              setError={setError}
              clearErrors={clearErrors}
              meioPagamento={meioPagamento}
              setMeioPagamento={setMeioPagamento}
            />
          </Card>
          <RodapeFormulario
            dataHoraCriacao={dataHoraCadastro}
            dataHoraUltimaAlteracao={dataHoraUltimaAlteracao}
            onSubmit={onSubmit}
            disabled={isLoading}
          />
        </ManterFoco>
      </Form>
    </Container>
  );
};

export default Alterar;
