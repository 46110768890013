import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FiChevronRight } from 'react-icons/fi';
import {
  Text,
  Box,
  Flex,
  useMediaQuery,
  Button,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Avatar,
  Icon,
  Divider,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { moneyMask } from 'helpers/format/fieldsMasks';
import { useMetasComissoesEtapasContext } from 'store/MetasComissoes/MetasComissoesEtapas';
import { IdentificacaoEtapasMetasComissoes } from 'constants/enum/identificacaoEtapasMetasComissoes';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import { LixeiraIcon, SalvarInserirNovoIcon, AplicarLote } from 'icons';
import { NumberInput } from 'components/update/Input/NumberInput';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { FooterMetasComissoes } from '../FooterMetasComissoes';
import { ModalAplicarRegraComissaoParaTodos } from './ModalAplicarComissao';
import { ComissaoVendedoresMobile } from './Mobile/comissaoVendedoresMobile';
import {
  FaixaComissaoProps,
  ComissaoDosVendedoresProps,
  ModalAplicarComissaoProps,
} from '../../Types/validationsForm';
import { ExibirMetasFixas } from './Components/ExibirMetasFixas';

type CampoQuandoAlcancarPorcentagemProps = {
  porcentagemAlcanceMetas: number | string;
  id: string;
  vendedorId: string;
};

type PercentualComissaoProps = {
  percentualComissao: number | string;
  id: string;
  vendedorId: string;
};

export const EscolherComissaoVendedores = () => {
  const [listaFaixasComissao, setListaFaixasComissao] = useState<
    FaixaComissaoProps[]
  >([]);
  const [
    valoresCampoQuandoAlcancarPorcentagem,
    setValoresCampoQuandoAlcancarPorcentagem,
  ] = useState<CampoQuandoAlcancarPorcentagemProps[]>([]);
  const [
    valoresCampoPercentualComissao,
    setValoresCampoPercentualComissao,
  ] = useState<PercentualComissaoProps[]>([]);
  const [comissaoDosVendedores, setComissaoDosVendedores] = useState<
    ComissaoDosVendedoresProps[]
  >([]);

  const [geradorIdFaixasComissao, setGeradorIdFaixasComissao] = useState(0);

  const [obterIdVendedor, setOperacaoIdVendedor] = useState(
    comissaoDosVendedores[0]?.vendedorId
  );
  const [
    obterValorMetaTotalVendedor,
    setObterValorMetaTotalVendedor,
  ] = useState(0);
  const [existeMetaIgual, setExisteMetaIgual] = useState(false);
  const [isLoadingEscolherComissao, setIsLoadingEscolherComissao] = useState(
    false
  );

  const [isLargerThan1200] = useMediaQuery('(min-width: 1200px)');
  const [isLargerThan1100] = useMediaQuery('(min-width: 1100px)');
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const {
    setActiveStep,
    lojaId,
    isLoading,
    setIsLoading,
    animacaoLoading,
  } = useMetasComissoesEtapasContext();
  const { setValue, getValues } = useFormContext();

  const loading = isLoading || isLoadingEscolherComissao;

  const gerarId = Math.floor(Date.now() * Math.random()).toString(36);

  const validandoValorObterIdVendedor =
    obterIdVendedor === undefined
      ? comissaoDosVendedores[0]?.vendedorId
      : obterIdVendedor;

  const handleVoltar = () => {
    setActiveStep(IdentificacaoEtapasMetasComissoes.ESCOLHER_META_VENDEDORES);
  };

  const vendedoresSemMeta = comissaoDosVendedores.every(
    (vendedor) => vendedor.valorMeta === 0
  );

  const handleAdicionarOutraFaixa = useCallback(async () => {
    await setGeradorIdFaixasComissao((valorAnterior) => valorAnterior + 1);

    await setListaFaixasComissao((faixaJaAdicionada) => {
      let novaFaixa = {} as FaixaComissaoProps;

      novaFaixa = {
        id: gerarId,
        idVendedor: validandoValorObterIdVendedor,
        quandoAlcancarPorcentagem: 0,
        valorDaMetaComAlcancePercent: 0,
        percentualComissao: 0,
      };

      setValue(`quandoAlcancar.${gerarId}`, 0);
      setValue(`percentualComissao.${gerarId}`, 0);
      return [...faixaJaAdicionada, novaFaixa];
    });
  }, [gerarId, setValue, validandoValorObterIdVendedor]);

  const handleExcluirFaixa = useCallback(
    async (id: string) => {
      const novasFaixasComissao = [...listaFaixasComissao];
      const novaListaValorAlcanceMetas = [
        ...valoresCampoQuandoAlcancarPorcentagem,
      ];
      const novaListaValoresPercentComissao = [
        ...valoresCampoPercentualComissao,
      ];

      const listaValorAlcanceMetasAtualizada = await novaListaValorAlcanceMetas.filter(
        (item) => item.id !== id
      );
      const listaPercentualComissao = await novaListaValoresPercentComissao.filter(
        (item) => item.id !== id
      );
      const novaListaFaixa = await novasFaixasComissao.filter(
        (item) => item.id !== id
      );
      setValue(`quandoAlcancar.${id}`, 0);
      setValue(`percentualComissao.${id}`, 0);
      setListaFaixasComissao(novaListaFaixa);
      setValoresCampoPercentualComissao(listaPercentualComissao);
      setValoresCampoQuandoAlcancarPorcentagem(
        listaValorAlcanceMetasAtualizada
      );
      await novaListaFaixa
        .filter(
          (faixaComissao) =>
            faixaComissao.idVendedor === validandoValorObterIdVendedor
        )
        .forEach((faixaComissao) => {
          setValue(
            `quandoAlcancar.${faixaComissao.id}`,
            faixaComissao.quandoAlcancarPorcentagem
          );
          setValue(
            `percentualComissao.${faixaComissao.id}`,
            faixaComissao.percentualComissao
          );
        });
    },
    [
      listaFaixasComissao,
      setValue,
      validandoValorObterIdVendedor,
      valoresCampoPercentualComissao,
      valoresCampoQuandoAlcancarPorcentagem,
    ]
  );

  const handleAtualizarDadosVendedores = useCallback(
    async (idVendedor: string, metaMensal: number) => {
      await setOperacaoIdVendedor(idVendedor);

      await setObterValorMetaTotalVendedor(metaMensal);
      await setListaFaixasComissao((faixaJaAdicionada) =>
        faixaJaAdicionada.map((faixa) => {
          return {
            ...faixa,
            valorDaMetaComAlcancePercent:
              (metaMensal * Number(faixa.quandoAlcancarPorcentagem)) / 100,
          };
        })
      );
      await listaFaixasComissao
        .filter((faixaComissao) => faixaComissao.idVendedor === idVendedor)
        .forEach((faixaComissao) => {
          setValue(
            `quandoAlcancar.${faixaComissao.id}`,
            faixaComissao.quandoAlcancarPorcentagem
          );
          setValue(
            `percentualComissao.${faixaComissao.id}`,
            faixaComissao.percentualComissao
          );
        });
    },
    [listaFaixasComissao, setValue]
  );

  const listaAtualizadaFaixasComissao = listaFaixasComissao.filter(
    (faixaComissao) =>
      faixaComissao.idVendedor === validandoValorObterIdVendedor
  );

  const handleAlterarValorCampoAlcanceMeta = useCallback(
    (valorId: string, valorAlcance: number | string, index: number) => {
      const novoValorAlcanceMetas = [...valoresCampoQuandoAlcancarPorcentagem];
      novoValorAlcanceMetas.splice(index, 1, {
        porcentagemAlcanceMetas: valorAlcance,
        id: valorId,
        vendedorId: validandoValorObterIdVendedor,
      });
      setListaFaixasComissao((faixaComissao) =>
        faixaComissao.map((faixa) => ({
          ...faixa,
          quandoAlcancarPorcentagem:
            faixa.id === valorId
              ? valorAlcance
              : faixa.quandoAlcancarPorcentagem,
          valorDaMetaComAlcancePercent:
            faixa.id === valorId
              ? (obterValorMetaTotalVendedor * Number(valorAlcance)) / 100
              : faixa.valorDaMetaComAlcancePercent,
        }))
      );
      setValoresCampoQuandoAlcancarPorcentagem(() =>
        novoValorAlcanceMetas.map((alcanceMetas) => ({
          ...alcanceMetas,
          porcentagemAlcanceMetas:
            valorId === alcanceMetas.id
              ? valorAlcance
              : alcanceMetas.porcentagemAlcanceMetas,
        }))
      );
    },
    [
      obterValorMetaTotalVendedor,
      validandoValorObterIdVendedor,
      valoresCampoQuandoAlcancarPorcentagem,
    ]
  );

  function handleAlterarValorCampoPercentualComissao(
    valorId: string,
    valorPercentComissao: number | string,
    index: number
  ) {
    const novoValorPercentComissao = [...valoresCampoPercentualComissao];
    novoValorPercentComissao.splice(index, 1, {
      percentualComissao: valorPercentComissao,
      id: valorId,
      vendedorId: validandoValorObterIdVendedor,
    });

    setListaFaixasComissao((faixaComissao) =>
      faixaComissao.map((faixa) => ({
        ...faixa,
        percentualComissao:
          faixa.id === valorId
            ? valorPercentComissao
            : faixa.percentualComissao,
      }))
    );

    setValoresCampoPercentualComissao(() =>
      novoValorPercentComissao.map((percentualComissao) => ({
        ...percentualComissao,
        percentualComissao:
          valorId === percentualComissao.id
            ? valorPercentComissao
            : percentualComissao.percentualComissao,
      }))
    );
  }

  const valoresMetasEstaoZerados = listaFaixasComissao.some(
    (item) => item.quandoAlcancarPorcentagem === 0
  );

  const handleCriarComissaoParaTodosVendedores = useCallback(
    async (
      valorComissaoPadrao: number,
      novaFaixaComissao: ModalAplicarComissaoProps[]
    ) => {
      const faixas = [...novaFaixaComissao];

      const listaFaixaComissao = await faixas.slice(0, -1);
      await setListaFaixasComissao([]);
      await setValoresCampoQuandoAlcancarPorcentagem([]);
      await setValoresCampoQuandoAlcancarPorcentagem([]);

      comissaoDosVendedores.forEach(async (comissaoVendedores, index) => {
        await setValue(
          `comissaoPadraoVendedores.${comissaoVendedores.vendedorId}`,
          valorComissaoPadrao
        );
        if (comissaoVendedores.valorMeta !== 0) {
          await setValue('quandoAlcancarTotal', 100);
          await setValue(
            `percentualComissaoTotal.${comissaoVendedores.vendedorId}`,
            novaFaixaComissao[novaFaixaComissao.length - 1]
              .percentualComissao || 0
          );

          const novoId = await gerarId;
          await setListaFaixasComissao((prev) => {
            const valorNovaFaixaComissao = listaFaixaComissao.map(
              (faixa, faixaIndex) => {
                setValue(
                  `quandoAlcancar.${`${novoId}${index}${faixaIndex}${faixa.id}`}`,
                  faixa.quandoAlcancarPorcentagem
                );
                setValue(
                  `percentualComissao.${`${novoId}${index}${faixaIndex}${faixa.id}`}`,
                  faixa.percentualComissao
                );
                return {
                  ...faixa,
                  id: `${novoId}${index}${faixaIndex}${faixa.id}`,
                  idVendedor: comissaoVendedores.vendedorId,
                  valorDaMetaComAlcancePercent:
                    (obterValorMetaTotalVendedor *
                      Number(faixa.quandoAlcancarPorcentagem)) /
                    100,
                };
              }
            );
            return [...prev, ...(valorNovaFaixaComissao || [])];
          });

          await setValoresCampoQuandoAlcancarPorcentagem((valorAnterior) => {
            const novaComissao: CampoQuandoAlcancarPorcentagemProps[] = listaFaixaComissao.map(
              (comissao, comissaoIndex) => {
                const valorAplicarComissaoTodos = {
                  porcentagemAlcanceMetas: comissao.quandoAlcancarPorcentagem,
                  id: `${novoId}${index}${comissaoIndex}${comissao.id}`,
                  vendedorId: comissaoVendedores.vendedorId,
                };
                return valorAplicarComissaoTodos;
              }
            );

            return [...valorAnterior, ...(novaComissao || [])];
          });
          await setValoresCampoPercentualComissao((valorAnterior) => {
            const novaPorcentagemComissao: PercentualComissaoProps[] = listaFaixaComissao.map(
              (comissao, comissaoIndex) => {
                const valorAplicarComissaoTodos = {
                  percentualComissao: comissao.percentualComissao,
                  id: `${novoId}${index}${comissaoIndex}${comissao.id}`,
                  vendedorId: comissaoVendedores.vendedorId,
                };
                return valorAplicarComissaoTodos;
              }
            );

            return [...valorAnterior, ...(novaPorcentagemComissao || [])];
          });
        }
      });

      await setGeradorIdFaixasComissao(
        (valorAnterior) => valorAnterior + listaFaixaComissao.length
      );
    },
    [comissaoDosVendedores, setValue, gerarId, obterValorMetaTotalVendedor]
  );

  const handleAvancar = useCallback(async () => {
    setIsLoadingEscolherComissao(true);
    const newComissaoVendedores = [...comissaoDosVendedores];

    const dadosEnviarApi = newComissaoVendedores.map((comissao) => {
      const primeiroValorFaixa: FaixaComissaoProps[] = [
        {
          idVendedor: comissao.vendedorId,
          id: `percentualComissaoTotal.${comissao.vendedorId}`,
          valorDaMetaComAlcancePercent: 0,
          quandoAlcancarPorcentagem: 100,
          percentualComissao: getValues(
            `percentualComissaoTotal.${comissao.vendedorId}`
          ),
        },
      ];
      const adicionarNovoValorFaixa = [
        ...primeiroValorFaixa,
        ...listaFaixasComissao,
      ];
      return {
        ...comissao,
        lojaId,
        comissaoPadrao: getValues(
          `comissaoPadraoVendedores.${comissao.vendedorId}`
        ),
        vendedorFaixasComissao:
          comissao.valorMeta === 0
            ? []
            : adicionarNovoValorFaixa
                .filter(
                  (filtrarVendedor) =>
                    filtrarVendedor.idVendedor === comissao.vendedorId
                )
                .map((faixas) => ({
                  percentualMeta: faixas.quandoAlcancarPorcentagem,
                  percentualComissao: faixas.percentualComissao,
                })),
      };
    });

    const response = await api.post<
      void,
      ResponseApi<ComissaoDosVendedoresProps[]>
    >(
      ConstanteEnderecoWebservice.CADASTRAR_VENDEDORES_METAS_COM_FAIXA,
      dadosEnviarApi
    );
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
        setIsLoadingEscolherComissao(false);
      }
      if (response.sucesso) {
        setIsLoadingEscolherComissao(false);
        setIsLoading(true);
        setActiveStep(IdentificacaoEtapasMetasComissoes.REGRA_PAGAMENTO);
      }
    }
    setIsLoadingEscolherComissao(false);
  }, [
    comissaoDosVendedores,
    setIsLoading,
    getValues,
    listaFaixasComissao,
    lojaId,
    setActiveStep,
  ]);

  useEffect(() => {
    setIsLoadingEscolherComissao(true);
    async function getComissaoVendedor() {
      const response = await api.get<
        void,
        ResponseApi<ComissaoDosVendedoresProps[]>
      >(ConstanteEnderecoWebservice.LISTAR_VENDEDORES_METAS_COM_FAIXA);
      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
          setIsLoadingEscolherComissao(false);
        }

        if (response.sucesso && response.dados) {
          setIsLoadingEscolherComissao(false);
          setComissaoDosVendedores(response.dados);
          animacaoLoading();
        }
      }
      setIsLoadingEscolherComissao(false);
      animacaoLoading();
    }
    getComissaoVendedor();
  }, [animacaoLoading]);

  useEffect(() => {
    const valoresQuandoAlcancarMeta = listaAtualizadaFaixasComissao.map(
      (item) => item.quandoAlcancarPorcentagem
    );

    const existeMetasIguais = valoresQuandoAlcancarMeta.some((item) => {
      return (
        valoresQuandoAlcancarMeta.indexOf(item) !==
        valoresQuandoAlcancarMeta.lastIndexOf(item)
      );
    });

    setExisteMetaIgual(existeMetasIguais);
  }, [listaAtualizadaFaixasComissao]);

  useEffect(() => {
    if (existeMetaIgual) {
      toast.warning('Esse vendedor já possui uma meta com esse valor');
    }
  }, [existeMetaIgual]);

  useEffect(() => {
    if (comissaoDosVendedores.length > 0) {
      setIsLoadingEscolherComissao(true);
      comissaoDosVendedores.forEach((comissao) => {
        if (
          (comissao.vendedorFaixasComissao || []).length !== 0 &&
          comissao.valorMeta !== 0
        ) {
          setValue(
            `comissaoPadraoVendedores.${comissao.vendedorId}`,
            comissao.comissaoPadrao
          );
          setValue(
            `percentualComissaoTotal.${comissao.vendedorId}`,
            comissao.vendedorFaixasComissao[0].percentualComissao
          );

          comissao.vendedorFaixasComissao.splice(0, 1);
          setValoresCampoQuandoAlcancarPorcentagem((prev) => {
            const valorNovaFaixaComissao: CampoQuandoAlcancarPorcentagemProps[] = comissao.vendedorFaixasComissao.map(
              (faixa) => {
                return {
                  exibirItemParaTodosVendedores: false,
                  porcentagemAlcanceMetas: faixa.percentualMeta,
                  vendedorId: comissao.vendedorId,
                  id: faixa.id,
                };
              }
            );
            return [...prev, ...(valorNovaFaixaComissao || [])];
          });
          setValoresCampoPercentualComissao((prev) => {
            const valorNovaFaixaComissao: PercentualComissaoProps[] = comissao.vendedorFaixasComissao.map(
              (faixa) => {
                return {
                  exibirItemParaTodosVendedores: false,
                  percentualComissao: faixa.percentualComissao,
                  vendedorId: comissao.vendedorId,
                  id: faixa.id,
                };
              }
            );
            return [...prev, ...(valorNovaFaixaComissao || [])];
          });

          setListaFaixasComissao((prev) => {
            comissao.vendedorFaixasComissao.forEach((faixaComissao) => {
              setValue(
                `quandoAlcancar.${faixaComissao.id}`,
                faixaComissao.percentualMeta
              );
              setValue(
                `percentualComissao.${faixaComissao.id}`,
                faixaComissao.percentualComissao
              );
            });

            const valorNovaFaixaComissao: FaixaComissaoProps[] = comissao.vendedorFaixasComissao.map(
              (faixa) => {
                return {
                  idVendedor: comissao.vendedorId,
                  exibirItemParaTodosVendedores: false,
                  quandoAlcancarPorcentagem: faixa.percentualMeta,
                  percentualComissao: faixa.percentualComissao,
                  id: faixa.id,
                  valorDaMetaComAlcancePercent:
                    (comissao.valorMeta * Number(faixa.percentualMeta)) / 100,
                };
              }
            );
            return [...prev, ...(valorNovaFaixaComissao || [])];
          });

          setGeradorIdFaixasComissao(
            (valorAnterior) =>
              valorAnterior + comissao.vendedorFaixasComissao.length
          );
        } else {
          setValue(`percentualComissaoTotal.${comissao.vendedorId}`, 1);
        }
      });
      setIsLoadingEscolherComissao(false);
    }
  }, [comissaoDosVendedores, setValue]);

  useEffect(() => {
    if (comissaoDosVendedores.length > 0) {
      setIsLoadingEscolherComissao(true);
      comissaoDosVendedores.forEach((comissao) => {
        if (comissao?.vendedorFaixasComissao === null) {
          setValue(`percentualComissaoTotal.${comissao.vendedorId}`, 1);
        }
      });
      setIsLoadingEscolherComissao(false);
    }
  }, [comissaoDosVendedores, setValue]);

  useEffect(() => {
    setObterValorMetaTotalVendedor(comissaoDosVendedores[0]?.valorMeta);
  }, [comissaoDosVendedores]);

  return (
    <>
      {loading && <LoadingPadrao />}
      <Box
        borderRadius="md"
        boxShadow="0px 0px 6px #00000034"
        w="full"
        bg="white"
        overflow="hidden"
      >
        <Flex
          alignItems="center"
          borderRadius="5px 5px 0 0"
          justifyContent="space-between"
          bg="primary.500"
          h="48px"
          pl="24px"
          pr="24px"
        >
          <Text fontSize="md" color="secondary.300">
            Comissão dos vendedores
          </Text>
          <Button
            variant="ghost"
            _active={{
              color: 'yellow.500',
            }}
            letterSpacing="0.235px"
            bg="transparent"
            p="1"
            color="yellow.500"
            fontSize="sm"
            fontWeight="normal"
            _hover={{
              textDecoration: 'underline',
              fontWeight: 'bold',
              letterSpacing: 0,
            }}
            onClick={() =>
              ModalAplicarRegraComissaoParaTodos({
                handleCriarComissaoParaTodosVendedores,
                vendedoresSemMeta,
                geradorIdFaixasComissao,
                validandoValorObterIdVendedor,
                validandoValorMetaTotalVendedor: obterValorMetaTotalVendedor,
              })
            }
          >
            {isLargerThan900 ? (
              'Aplicar a mesma regra de comissão para todos'
            ) : (
              <Icon as={AplicarLote} w={6} h={6} />
            )}
          </Button>
        </Flex>
        {isLargerThan1100 ? (
          <Box h="full">
            <Tabs h="full" variant="clean">
              <Flex h="full">
                <TabList mt="16px" w="32%">
                  <Box w="100%">
                    {comissaoDosVendedores.map((comissaoVendedor) => (
                      <Tab
                        key={comissaoVendedor.vendedorId}
                        onClick={() =>
                          handleAtualizarDadosVendedores(
                            comissaoVendedor.vendedorId,
                            comissaoVendedor.valorMeta
                          )
                        }
                        h="64px"
                        isDisabled={valoresMetasEstaoZerados || existeMetaIgual}
                        w="100%"
                      >
                        <Flex
                          w="full"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Flex justifyContent="center" alignItems="center">
                            <Avatar
                              boxSize="30px"
                              mr="10px"
                              ml="10px"
                              name={comissaoVendedor.foto}
                              src={comissaoVendedor.foto}
                            />
                            <Text>{comissaoVendedor.vendedor}</Text>
                          </Flex>
                          <Icon as={FiChevronRight} />
                        </Flex>
                      </Tab>
                    ))}
                  </Box>
                </TabList>

                <TabPanels
                  sx={{
                    '&::-webkit-scrollbar': {
                      height: '0',
                      width: '0',
                    },
                    '& .virtualized_List::-webkit-scrollbar': {
                      height: '0',
                      width: '0',
                    },
                  }}
                  overflowY={
                    listaAtualizadaFaixasComissao.length > 2
                      ? 'scroll'
                      : 'hidden'
                  }
                  ml="-1px"
                  borderRadius="0 5px 0 0"
                  bg="gray.50"
                  w="full"
                >
                  {comissaoDosVendedores.map((comissaoVendedores) => (
                    <TabPanel
                      key={comissaoVendedores.vendedorId}
                      pl={isLargerThan1200 ? '35px' : ''}
                      mt="6px"
                      h="98%"
                      w="full"
                      bg="gray.50"
                    >
                      <ExibirMetasFixas comissaoVendedor={comissaoVendedores} />
                      {listaAtualizadaFaixasComissao.map(
                        (faixaComissao, index) => {
                          return (
                            <>
                              <Flex
                                ml={isLargerThan1200 ? '20px' : ''}
                                mt="15px"
                                mb="15px"
                                color="gray.700"
                                alignItems="center"
                                justifyContent="left"
                              >
                                <Text mr="10px" fontSize="16px">
                                  Quando alcançar:
                                </Text>

                                <Box w="100px" mr="10px">
                                  <NumberInput
                                    id="quandoAlcancar"
                                    name={`quandoAlcancar.${faixaComissao.id}`}
                                    mr="2px"
                                    rightElementPorcentagem="%"
                                    scale={2}
                                    color="gray.600"
                                    onValueChange={(valueAsString) =>
                                      handleAlterarValorCampoAlcanceMeta(
                                        faixaComissao.id,
                                        valueAsString,
                                        index
                                      )
                                    }
                                    fontSize={{
                                      base: 'sm',
                                      md: 'md',
                                    }}
                                  />
                                </Box>

                                <Text mr="10px" fontSize="16px">
                                  <strong>
                                    {moneyMask(
                                      faixaComissao.valorDaMetaComAlcancePercent,
                                      true
                                    )}
                                  </strong>
                                  , o percentual da comissão será de
                                </Text>
                                <Box w="100px">
                                  <NumberInput
                                    widhtPorcentagem="20"
                                    h="40px"
                                    id={`percentualComissao.${faixaComissao.id}`}
                                    rightElementPorcentagem="%"
                                    name={`percentualComissao.${faixaComissao.id}`}
                                    scale={2}
                                    onValueChange={(valorPercentComissao) =>
                                      handleAlterarValorCampoPercentualComissao(
                                        faixaComissao.id,
                                        valorPercentComissao,
                                        index
                                      )
                                    }
                                  />
                                </Box>

                                <Icon
                                  boxSize="19px"
                                  cursor="pointer"
                                  ml="20px"
                                  onClick={() =>
                                    handleExcluirFaixa(faixaComissao.id)
                                  }
                                  as={LixeiraIcon}
                                />
                              </Flex>

                              <Divider w="full" />
                            </>
                          );
                        }
                      )}
                      <Button
                        color="blue.700"
                        ml="20px"
                        mt="15px"
                        mb="20px"
                        isDisabled={
                          existeMetaIgual || obterValorMetaTotalVendedor === 0
                        }
                        onClick={() => handleAdicionarOutraFaixa()}
                        leftIcon={
                          <Icon as={SalvarInserirNovoIcon} fontSize="lg" />
                        }
                        variant="link"
                      >
                        Adicionar outra faixa
                      </Button>
                    </TabPanel>
                  ))}
                </TabPanels>
              </Flex>
            </Tabs>
          </Box>
        ) : (
          <ComissaoVendedoresMobile
            existeMetaIgual={existeMetaIgual}
            obterValorMetaTotalVendedor={obterValorMetaTotalVendedor}
            comissaoDosVendedores={comissaoDosVendedores}
            handleAlterarValorCampoPercentualComissao={
              handleAlterarValorCampoPercentualComissao
            }
            handleAlterarValorCampoAlcanceMeta={
              handleAlterarValorCampoAlcanceMeta
            }
            handleExcluirFaixa={handleExcluirFaixa}
            obterIdVendedor={validandoValorObterIdVendedor}
            handleAdicionarOutraFaixa={handleAdicionarOutraFaixa}
            listaFaixasComissao={listaFaixasComissao}
            handleAtualizarDadosVendedores={handleAtualizarDadosVendedores}
          />
        )}
      </Box>

      <FooterMetasComissoes
        handleAvancar={handleAvancar}
        isDisabledAvancar={valoresMetasEstaoZerados || existeMetaIgual}
        handleVoltar={handleVoltar}
      />
    </>
  );
};
