import ConstanteFuncionalidades from 'constants/permissoes';
import auth from 'modules/auth';

const possuiPermissaoVisualizarPrecoCusto = auth.possuiPermissao(
  ConstanteFuncionalidades.USUARIO_VISUALIZAR_PRECO_CUSTO
).permitido;

interface EnumRelatorioProdutosPersonalizadoProperty {
  id: number;
  label: string;
  value: number;
  name: string;
  isChecked: boolean;
}

interface EnumRelatorioProdutosPersonalizado {
  FOTO: number;
  DESCRICAO: number;
  DESCRICAO_ABREVIADA: number;
  GTIN_EAN: number;
  REFERENCIA: number;
  TIPO: number;
  CATEGORIA: number;
  MARCA: number;
  ESTOQUE_MINIMO: number;
  PRECO_COMPRA: number;
  PRECO_CUSTO: number;
  MARKUP: number;
  PRECO_VENDA: number;
  NCM: number;
  CEST: number;
  UNIDADE_MEDIDA: number;
  TAGS: number;
  ESTOQUE_ATUAL: number;
  SKU: number;
  REGRA_FISCAL: number;
  CODIGO_BARRAS_INTERNO: number;
  properties: EnumRelatorioProdutosPersonalizadoProperty[];
}

const enumCompletoRelatorioProdutosPersonalizado: EnumRelatorioProdutosPersonalizado = {
  FOTO: 1,
  DESCRICAO: 2,
  DESCRICAO_ABREVIADA: 3,
  GTIN_EAN: 4,
  REFERENCIA: 5,
  TIPO: 6,
  CATEGORIA: 7,
  MARCA: 8,
  ESTOQUE_MINIMO: 9,
  PRECO_COMPRA: 10,
  PRECO_CUSTO: 11,
  MARKUP: 12,
  PRECO_VENDA: 13,
  NCM: 14,
  CEST: 15,
  UNIDADE_MEDIDA: 16,
  TAGS: 17,
  ESTOQUE_ATUAL: 18,
  SKU: 19,
  REGRA_FISCAL: 20,
  CODIGO_BARRAS_INTERNO: 21,

  properties: [
    { id: 1, label: 'Foto', value: 1, name: 'FOTO', isChecked: false },
    {
      id: 2,
      label: 'Descrição',
      value: 2,
      name: 'DESCRICAO',
      isChecked: false,
    },
    {
      id: 3,
      label: 'Descrição Abreviada',
      value: 3,
      name: 'DESCRICAO_ABREVIADA',
      isChecked: false,
    },
    { id: 4, label: 'GTIN EAN', value: 4, name: 'GTIN_EAN', isChecked: false },
    {
      id: 5,
      label: 'Referência',
      value: 5,
      name: 'REFERENCIA',
      isChecked: false,
    },
    { id: 6, label: 'Tipo', value: 6, name: 'TIPO', isChecked: false },
    {
      id: 7,
      label: 'Categoria',
      value: 7,
      name: 'CATEGORIA',
      isChecked: false,
    },
    { id: 8, label: 'Marca', value: 8, name: 'MARCA', isChecked: false },
    {
      id: 9,
      label: 'Estoque mínimo',
      value: 9,
      name: 'ESTOQUE_MINIMO',
      isChecked: false,
    },
    {
      id: 10,
      label: 'Preço compra',
      value: 10,
      name: 'PRECO_COMPRA',
      isChecked: false,
    },
    {
      id: 11,
      label: 'Preço de custo',
      value: 11,
      name: 'PRECO_CUSTO',
      isChecked: false,
    },
    { id: 12, label: 'Markup', value: 12, name: 'MARKUP', isChecked: false },
    {
      id: 13,
      label: 'Preço de venda',
      value: 13,
      name: 'PRECO_VENDA',
      isChecked: false,
    },
    { id: 14, label: 'NCM', value: 14, name: 'NCM', isChecked: false },
    { id: 15, label: 'CEST', value: 15, name: 'CEST', isChecked: false },
    {
      id: 16,
      label: 'Unidade de medida',
      value: 16,
      name: 'UNIDADE_MEDIDA',
      isChecked: false,
    },
    { id: 17, label: 'TAGS', value: 17, name: 'TAGS', isChecked: false },
    {
      id: 18,
      label: 'Estoque atual',
      value: 18,
      name: 'ESTOQUE_ATUAL',
      isChecked: false,
    },
    { id: 19, label: 'SKU', value: 19, name: 'SKU', isChecked: false },
    {
      id: 20,
      label: 'Regra fiscal',
      value: 20,
      name: 'REGRA_FISCAL',
      isChecked: false,
    },
    {
      id: 21,
      label: 'Código de barras interno',
      value: 21,
      name: 'CODIGO_BARRAS_INTERNO',
      isChecked: false,
    },
  ],
};

function filtrarEnumRelatorioProdutosPorPermissao(
  enumRelatorio: EnumRelatorioProdutosPersonalizado
): EnumRelatorioProdutosPersonalizado {
  const enumFiltrado = {
    ...enumRelatorio,
    properties: [...enumRelatorio.properties],
  };

  if (!possuiPermissaoVisualizarPrecoCusto) {
    enumFiltrado.properties = enumFiltrado.properties.filter(
      (property) =>
        property.value !== enumFiltrado.PRECO_CUSTO &&
        property.value !== enumFiltrado.PRECO_COMPRA
    );
  }

  return enumFiltrado;
}

export const enumRelatorioProdutosPersonalizado = filtrarEnumRelatorioProdutosPorPermissao(
  enumCompletoRelatorioProdutosPersonalizado
);
