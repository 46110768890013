import { useState } from 'react';
import { Button, Flex } from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import { ImprimirPDF } from 'helpers/impressoes/imprimirPDF';
import { enumRelatorioProdutosPersonalizadoPadrao } from 'constants/enum/RelatoriosPadrao';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import StatusConsultaEnum from 'constants/enum/statusConsulta';
import TipoFiltroProdutoEstoqueEnum from 'constants/enum/tipoFiltroProdutoEstoque';

import { CustomFormStandardComponent } from 'components/update/CustomFormStandard';

import { FormData, yupResolver } from './validationForm';

type CamposPersonalizados = {
  campoPersonalizadoId: string;
  valor: string;
};

export const RelatorioProdutosVendidos = () => {
  const [isLoading, setIsLoading] = useState(false);
  const formMethods = useForm<FormData>({
    defaultValues: {
      statusConsulta: StatusConsultaEnum.TODOS,
      tipoEstoque: TipoFiltroProdutoEstoqueEnum.TODOS,
    },
    resolver: yupResolver,
  });

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    setIsLoading(true);
    const camposPerson = data.camposPersonalizados
      ? data.camposPersonalizados.some(
          (item: CamposPersonalizados) => item.valor !== null || undefined
        )
      : false;

    let camposPersonalizados = !camposPerson
      ? null
      : data.camposPersonalizados
          .filter((item: CamposPersonalizados) => item.valor)
          .map((values) => ({
            ...values,
            valor: String(values.valor),
          }));

    camposPersonalizados =
      camposPersonalizados?.length === 0 ? null : camposPersonalizados;

    const dados = {
      ...data,
      tipoRelatorio: enumRelatorioProdutosPersonalizadoPadrao.PRODUTO_COM_PRECO,
      camposPersonalizados,
    };
    const response = await api.post<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.PRODUTO_RELATORIO_PRODUTOS_COM_PRECO,
      dados
    );

    if (response?.avisos) {
      response.avisos.forEach((avisos) => toast.warning(avisos));
    }

    if (response.sucesso && response.dados) {
      ImprimirPDF(response.dados, 'relatorioProdutoComPreco');
    }
    setIsLoading(false);
  });

  return (
    <FormProvider {...formMethods}>
      <CustomFormStandardComponent
        type="ProdutosComPreco"
        isLoading={isLoading}
      />
      <Flex justifyContent="end">
        <Button
          variant="unstyled"
          w={{ base: 'full', sm: '250px' }}
          h="10"
          borderRadius="md"
          color="gray.900"
          bg="secondary.400"
          mt={{ base: '6', md: '6', lg: '10' }}
          onClick={handleSubmit}
        >
          Gerar Relatório
        </Button>
      </Flex>
    </FormProvider>
  );
};

export default RelatorioProdutosVendidos;
