import React, { useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Box, GridItem, Tr, Td, Link } from '@chakra-ui/react';

import auth from 'modules/auth';
import api, { ResponseApi } from 'services/api';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import ConstanteFuncionalidades from 'constants/permissoes';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { shallowEqual } from 'helpers/validation/shallowEqual';

import { FilterSelect } from 'components/update/Select/FilterSelect';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { ModalConfirmacaoInativar } from 'components/Modal/ModalConfirmacaoInativar';
import { SearchInput } from 'components/update/Input/SearchInput';
import { StatusCircle } from 'components/update/Table/StatusCircle';
import {
  PagedTable,
  PagedTableForwardRefData,
} from 'components/update/Table/PagedTable';
import {
  GridPaginadaConsulta,
  GridPaginadaRetorno,
} from 'components/Grid/Paginacao';
import { ButtonCadastrarNovo } from 'components/Layout/ButtonCadastrarNovo';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';

interface PerfisFiltros {
  ativo: boolean;
  descricao: string;
}

interface PerfilUsuario {
  id: string;
  descricao: string;
  ativo: boolean;
  administrador: boolean;
}

const formDefaultValues = {
  ativo: true,
  descricao: '',
};

const Listar = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [perfis, setPerfis] = useState([] as Array<PerfilUsuario>);
  const [currentFilters, setCurrentFilters] = useState<PerfisFiltros>(
    formDefaultValues
  );

  const formMethods = useForm({
    defaultValues: formDefaultValues,
  });
  const { setFocus } = formMethods;

  const history = useHistory();
  const isMountedRef = useIsMountedRef();

  const pageIsLoaded = useRef(false);
  const childRef = useRef<PagedTableForwardRefData>(null);

  const permissaoPerfilAlterar = auth.possuiPermissao(
    ConstanteFuncionalidades.PERFIL_USUARIO_ALTERAR
  );
  const permissaoPerfilAlterarVisualizar = auth.possuiPermissao(
    ConstanteFuncionalidades.PERFIL_USUARIO_VISUALIZAR
  );

  const handleHistoryPush = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history]
  );

  const handleAlterarVisualizar = useCallback(
    (id: string) => {
      let href = '';

      if (permissaoPerfilAlterar.permitido) {
        href = SubstituirParametroRota(
          ConstanteRotas.PERFIL_USUARIO_ALTERAR,
          'id',
          id
        );
      } else if (permissaoPerfilAlterarVisualizar.permitido) {
        href = SubstituirParametroRota(
          ConstanteRotas.PERFIL_USUARIO_VISUALIZAR,
          'id',
          id
        );
      }

      return href;
    },
    [permissaoPerfilAlterar, permissaoPerfilAlterarVisualizar]
  );

  const handleReset = formMethods.handleSubmit((data) => {
    const filtersIsDirty = !shallowEqual(data, currentFilters || {});

    if (filtersIsDirty) {
      setCurrentFilters(data);
    }
  });

  const handleRefresh = useCallback(() => {
    return childRef.current?.reload();
  }, []);

  const paginationHandle = useCallback(
    async (gridPaginadaConsulta: GridPaginadaConsulta) => {
      setIsLoading(true);

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<PerfilUsuario>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.PERFIL_USUARIO_LISTAR_PAGINADO,
          gridPaginadaConsulta
        ),
        {
          params: {
            descricao: currentFilters.descricao,
            ativo: currentFilters.ativo,
          },
        }
      );

      if (response?.sucesso && isMountedRef.current) {
        setTotalRegistros(response.dados.total);
        setPerfis(response.dados.registros);
      }

      if (isMountedRef.current) {
        setIsLoading(false);

        if (!pageIsLoaded.current) {
          pageIsLoaded.current = true;

          setFocus('descricao');
        }
      }
    },
    [currentFilters, isMountedRef, setFocus]
  );

  const excluirHandle = useCallback(
    async (perfilId: string, ativo: boolean) => {
      ModalConfirmacaoExcluir({
        callback: async (ok: boolean) => {
          if (ok) {
            setIsLoading(true);

            const response = await api.delete<void, ResponseApi>(
              ConstanteEnderecoWebservice.PERFIL_USUARIO_EXCLUIR,
              {
                params: { id: perfilId },
              }
            );

            if (response?.sucesso) {
              toast.success('O cadastro foi removido com sucesso.');

              handleRefresh();
            }

            if (response) {
              ModalConfirmacaoInativar({
                response,
                rotaWebService:
                  ConstanteEnderecoWebservice.PERFIL_USUARIO_INATIVAR,
                id: perfilId,
                ativo,
                callback: (okInativar: boolean) => {
                  if (okInativar) handleRefresh();
                },
              });
            }

            setIsLoading(false);
          }
        },
      });
    },
    [handleRefresh]
  );

  return (
    <SimpleGridForm gap={{ base: '10px', sm: '10px', md: 8 }}>
      <FormProvider {...formMethods}>
        <GridItem colSpan={{ base: 12, md: 5, lg: 6 }}>
          <SearchInput
            type="search"
            placeholder="Buscar perfil de usuário por descrição"
            onEnterKeyPress={() => handleReset()}
            isDisabled={isLoading}
            id="descricao"
            name="descricao"
          />
        </GridItem>

        <GridItem p="0" colSpan={{ base: 12, md: 3, lg: 2 }}>
          <Box w={{ base: 'full', md: '190px', lg: '190px' }}>
            <FilterSelect
              id="ativo"
              name="ativo"
              isDisabled={isLoading}
              options={[
                {
                  value: true,
                  label: 'Ativos',
                },
                {
                  value: false,
                  label: 'Inativos',
                },
                {
                  value: null,
                  label: 'Todos',
                },
              ]}
              onSelect={() => {
                handleReset();
              }}
            />
          </Box>
        </GridItem>
        <GridItem
          justifyContent={{ base: 'flex-start', sm: 'flex-end' }}
          display={{ base: '', sm: '', md: 'flex' }}
          colSpan={{ base: 12, md: 4, lg: 4 }}
        >
          <ButtonCadastrarNovo
            onClick={() =>
              handleHistoryPush(ConstanteRotas.PERFIL_USUARIO_CADASTRAR)
            }
            funcionalidade={ConstanteFuncionalidades.PERFIL_USUARIO_CADASTRAR}
          />
        </GridItem>

        <GridItem mt={['10px', '10px', '-10px']} colSpan={12}>
          <PagedTable
            ref={childRef}
            itemsTotalCount={totalRegistros}
            loadColumnsData={paginationHandle}
            isLoading={isLoading}
            defaultKeyOrdered="Descricao"
            defaultOrderDirection="asc"
            tableHeaders={[
              {
                content: <StatusCircle hasValue={false} />,
                key: 'Ativo',
                isOrderable: true,
                width: '37px',
              },
              {
                content: 'Descrição',
                key: 'Descricao',
                isOrderable: true,
                width: 'auto',
              },
              {
                content: 'Ações',
                key: 'Acoes',
                isOrderable: false,
                width: '38px',
              },
            ]}
            renderTableRows={perfis.map((perfil) => (
              <Tr key={perfil.id}>
                <Td style={{ textAlign: 'center' }}>
                  <StatusCircle isActive={perfil.ativo} />
                </Td>
                <Td>
                  {!perfil.administrador ? (
                    <Link
                      id="link-visualizar"
                      href={handleAlterarVisualizar(perfil.id)}
                    >
                      {perfil.descricao}
                    </Link>
                  ) : (
                    <>{perfil.descricao}</>
                  )}
                </Td>
                <Td>
                  {!perfil.administrador && (
                    <ActionsMenu
                      id="mostrarMais"
                      items={[
                        {
                          content: 'Editar',
                          onClick: () => {
                            handleHistoryPush(
                              SubstituirParametroRota(
                                ConstanteRotas.PERFIL_USUARIO_ALTERAR,
                                'id',
                                perfil.id
                              )
                            );
                          },
                          funcionalidade:
                            ConstanteFuncionalidades.PERFIL_USUARIO_ALTERAR,
                        },
                        {
                          content: 'Remover',
                          onClick: () => {
                            excluirHandle(perfil.id, perfil.ativo);
                          },
                          funcionalidade:
                            ConstanteFuncionalidades.PERFIL_USUARIO_EXCLUIR,
                        },
                      ]}
                    />
                  )}
                </Td>
              </Tr>
            ))}
          />
        </GridItem>
      </FormProvider>
    </SimpleGridForm>
  );
};

export default Listar;
