import { createContext, useCallback, useContext, useState } from 'react';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { ResponseApi } from 'services/api';
import { sistemaApiKey } from 'services/sistemaApiKey';

type IntegracaoConfiguracoeProps = {
  IdentificacaoEtapas: number;
  DataScript: Date;
  DataHoraUltimoBackup: null | Date;
  UrlScript: null | string;
};

type InfoIntegracaoProps = {
  ativo: boolean;
  configuracoes: string;
  dataAtivacao: string;
  id: string;
  identificacaoIntegracao: number;
  localEstoqueId: string;
  sincronizacaoHabilitada: boolean;
  tabelaPrecoId: null | string;
  usuarioId: string;
  vendedorId: null | string;
};

type FrenteCaixaProviderProps = {
  children: React.ReactNode;
};

type FrenteCaixaContextProps = {
  infoIntegracao: InfoIntegracaoProps | undefined;
  getIntegracao: (cadastrarIntegracao: () => Promise<void>) => Promise<void>;
  integracaoConfiguracoes: IntegracaoConfiguracoeProps | undefined;
};

export const FrenteCaixaContext = createContext<FrenteCaixaContextProps>(
  {} as FrenteCaixaContextProps
);

export function FrenteCaixaProvider({
  children,
}: FrenteCaixaProviderProps): JSX.Element {
  const [infoIntegracao, setInfoIntegracao] = useState<InfoIntegracaoProps>();

  const integracaoConfiguracoes = infoIntegracao?.configuracoes
    ? (JSON.parse(infoIntegracao.configuracoes) as IntegracaoConfiguracoeProps)
    : undefined;

  const getIntegracao = useCallback(
    async (cadastrarIntegracao: () => Promise<void>) => {
      const response = await sistemaApiKey().get<
        void,
        ResponseApi<InfoIntegracaoProps>
      >(ConstanteEnderecoWebservice.FRENTE_CAIXA_OBTER_INTEGRACAO);

      if (response) {
        if (response.sucesso) {
          setInfoIntegracao(response.dados);
        } else {
          await cadastrarIntegracao();
        }
      }
    },
    []
  );

  return (
    <FrenteCaixaContext.Provider
      value={{
        infoIntegracao,
        getIntegracao,
        integracaoConfiguracoes,
      }}
    >
      {children}
    </FrenteCaixaContext.Provider>
  );
}

export function useFrenteCaixaContext(): FrenteCaixaContextProps {
  const context = useContext(FrenteCaixaContext);

  if (!context)
    throw new Error(
      'FrenteCaixaContext must be used within a FrenteCaixaProvider.'
    );

  return context;
}
