export type StatusOperacao = 1 | 2;

const StatusOperacaoEnum = {
  EFETUADA: 1,
  CANCELADA: 2,
  TODOS: null,

  properties: {
    1: { label: 'Efetuada', value: 1 },
    3: { label: 'Cancelada', value: 2 },
  },
};

export default StatusOperacaoEnum;

export const statusOperacaoOptions = [
  { label: 'Canceladas', value: StatusOperacaoEnum.CANCELADA },
  { label: 'Efetuadas', value: StatusOperacaoEnum.EFETUADA },
  { label: 'Todos', value: null },
];

export const statusOperacao = [
  { label: 'Canceladas', value: StatusOperacaoEnum.CANCELADA },
  { label: 'Efetuadas', value: StatusOperacaoEnum.EFETUADA },
];
