import React, { useEffect, useState, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  Box,
  Flex,
  Button,
  useMediaQuery,
  Text,
  Stack,
} from '@chakra-ui/react';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';
import jsFileDownload from 'js-file-download';

import { OperacaoObterParaCompartilhar } from 'helpers/api/Operacao/obterOperacaoParaCompartilhar';
import useWindowSize from 'helpers/layout/useWindowSize';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { useSistemaAtual } from 'helpers/data/useSistemaAtual';

import Loading from 'components/Layout/Loading/LoadingPadrao';
import ShadowScrollbar, {
  ShadowScrollbarForwardRefProps,
} from 'components/PDV/Geral/ShadowScrollbar';
import ImpressaoCupomNaoFiscal, {
  ImpressaoCupomNaoFiscalRefElement,
} from 'components/Impressao/ImpressaoCupomNaoFiscal/ImpressaoCupomNaoFiscal';
import ImpressaoNFCe, {
  ImpressaoNFCeRefElement,
} from 'components/Impressao/ImpressaoNFCe/ImpressaoNFCe';
import { LoginBackgroundZendar } from 'components/Autenticacao/Layout/LoginBackgroundZendar';
import { LoginBackgroundPowerStock } from 'components/Autenticacao/Layout/LoginBackgroundPowerStock';
import { LoginBackgroundFomer } from 'components/Autenticacao/Layout/LoginBackgroundFomer';

type MatchParams = { id: string };

const CompartilharExterno = ({
  match: {
    params: { id: operacaoId },
  },
}: RouteComponentProps<MatchParams>) => {
  const { value: system } = useSistemaAtual();
  const [operacao] = useState<OperacaoObterParaCompartilhar>();
  const [processandoDownload, setProcessandoDownload] = useState(false);
  const [
    hasCupomNaoFiscalComLinkExpirado,
    setHasCupomNaoFiscalComLinkExpirado,
  ] = useState(false);
  const shadowScrollbarRef = useRef<ShadowScrollbarForwardRefProps>(null);

  // Cupom Fiscal
  const cupomFiscalComponentToPrintRef = useRef<ImpressaoNFCeRefElement>(null);
  const cupomFiscalComponentToPrintContainerRef = useRef<HTMLDivElement>(null);

  const { height: windowHeight } = useWindowSize();

  const systemBackground = [
    LoginBackgroundZendar,
    LoginBackgroundPowerStock,
    LoginBackgroundFomer,
  ];

  const Background = systemBackground[system];
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const imprimirCupomFiscal = useReactToPrint({
    content: () => cupomFiscalComponentToPrintContainerRef.current,
  });

  useEffect(() => {
    async function obterCupomFiscal() {
      if (imprimirCupomFiscal && cupomFiscalComponentToPrintRef.current) {
        await cupomFiscalComponentToPrintRef.current.obterInformacoesImpressaoHandle();
      }
    }
    obterCupomFiscal();
  }, [imprimirCupomFiscal]);

  const modalImpressaoNFCe = React.useCallback(async () => {
    if (imprimirCupomFiscal) imprimirCupomFiscal();
  }, [imprimirCupomFiscal]);

  const latestProps = useRef({ operacaoId });
  useEffect(() => {
    latestProps.current = { operacaoId };
  });

  // Danfe
  const downloadDanfe = async () => {
    setProcessandoDownload(true);

    if (operacao && operacao.NFe) {
      const response = await api.get<void, ResponseApi<string>>(
        `${ConstanteEnderecoWebservice.NOTA_FISCAL_DANFE}/${operacao.NFe.id}`
      );

      if (response.sucesso) {
        jsFileDownload(
          Uint8Array.from(atob(response.dados), (c) => c.charCodeAt(0)),
          `Danfe_${operacao.NFe.numero}.pdf`
        );
      }

      if (response?.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }
    }

    setProcessandoDownload(false);
  };

  // Cupom Não Fiscal
  const cupomNaoFiscalComponentToPrintRef = useRef<ImpressaoCupomNaoFiscalRefElement>(
    null
  );
  const cupomNaoFiscalComponentToPrintContainerRef = useRef<HTMLDivElement>(
    null
  );

  const imprimirCupomNaoFiscal = useReactToPrint({
    content: () => cupomNaoFiscalComponentToPrintContainerRef.current,
  });

  useEffect(() => {
    async function obterCupomNaoFiscal() {
      if (imprimirCupomNaoFiscal && cupomNaoFiscalComponentToPrintRef.current) {
        const hasLinkExpirado = await cupomNaoFiscalComponentToPrintRef.current.obterInformacoesImpressao();

        setHasCupomNaoFiscalComLinkExpirado(hasLinkExpirado);
      }
    }
    obterCupomNaoFiscal();
  }, [imprimirCupomNaoFiscal]);

  const modalImpressaoCupomNaoFiscal = React.useCallback(async () => {
    if (imprimirCupomNaoFiscal) imprimirCupomNaoFiscal();
  }, [imprimirCupomNaoFiscal]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--vh-compartilhar',
      `${windowHeight * 0.01}px`
    );
  }, [windowHeight]);

  return (
    <>
      {hasCupomNaoFiscalComLinkExpirado ? (
        <Background minW="full">
          <Flex
            w="100%"
            minW={{ base: '265px', sm: '300px', xl: '350px' }}
            direction="column"
            justifyContent="space-between"
            pt={3}
            pb={3}
          >
            <Stack>
              <Text
                color="white"
                fontSize={['6xl', '6xl', '7xl']}
                fontWeight="black"
                mb="24px"
                textAlign="center"
              >
                Oooops!
              </Text>
              <Flex direction="column">
                <Text
                  color="purple.50"
                  fontSize="md"
                  lineHeight="30px"
                  fontWeight="normal"
                >
                  A página que você aterrissou não está mais disponível. Se você
                  digitou o endereço corretamente, o link pode ter expirado ou
                  perdeu a validade. Sentimos muito.
                </Text>

                <Text
                  color="purple.50"
                  fontSize="xs"
                  mt="24px"
                  lineHeight="taller"
                  fontWeight="normal"
                  textAlign="justify"
                >
                  Para acessar as informações dessa operação solicite um novo
                  link à loja.
                </Text>
              </Flex>
            </Stack>
          </Flex>
        </Background>
      ) : (
        <Flex
          w="100%"
          h="calc(var(--vh-compartilhar, 1vh) * 100)"
          background="primary.100"
        >
          <Flex
            w="50%"
            minW="280px"
            marginLeft="auto"
            marginRight="auto"
            marginTop="5%"
            direction="column"
            alignItems="center"
            background="white"
          >
            <Flex
              w="70%"
              direction={['column', 'column', 'row']}
              justifyContent="flex-end"
              alignItems={['center', 'center', 'flex-start']}
              mb="1"
              mt="4"
              mr="5"
            >
              {processandoDownload && <Loading />}

              {operacao?.NFCe && (
                <Button
                  variant="outline"
                  size="sm"
                  w="full"
                  minW="96px"
                  maxW="120px"
                  mb="1"
                  onClick={modalImpressaoNFCe}
                >
                  NFC-e
                </Button>
              )}

              {!processandoDownload && operacao?.NFe && (
                <Button
                  variant="outline"
                  size="sm"
                  w="full"
                  minW="96px"
                  maxW="120px"
                  ml="1"
                  mb="1"
                  onClick={downloadDanfe}
                >
                  Danfe
                </Button>
              )}

              <Button
                variant="solid"
                colorScheme="secondary"
                size="sm"
                w="full"
                minW="96px"
                maxW="120px"
                ml="1"
                onClick={modalImpressaoCupomNaoFiscal}
              >
                Imprimir
              </Button>
            </Flex>
            {operacao && operacao.NFCe && (
              <Box display="none">
                <ImpressaoNFCe
                  ref={cupomFiscalComponentToPrintRef}
                  containerRef={cupomFiscalComponentToPrintContainerRef}
                  documentoFiscalId={operacao.NFCe.id}
                />
              </Box>
            )}
            <Box width="70%" minW="270px">
              <ShadowScrollbar
                maxHeight={
                  isLargerThan900 ? windowHeight - 160 : windowHeight - 80
                }
                paddingTop="0"
                shadowTopStyle={{
                  background:
                    'transparent linear-gradient(180deg, var(--white)  0%,  #FFFFFF00 100%) 0% 0% no-repeat padding-box',
                  height: 30,
                }}
                shadowBottomStyle={{
                  background:
                    'transparent linear-gradient(180deg, #FFFFFF00 0%, var(--white) 100%) 0% 0% no-repeat padding-box',
                  height: 30,
                }}
                ref={shadowScrollbarRef}
              >
                <ImpressaoCupomNaoFiscal
                  ref={cupomNaoFiscalComponentToPrintRef}
                  containerRef={cupomNaoFiscalComponentToPrintContainerRef}
                  operacaoId={operacaoId}
                />
              </ShadowScrollbar>
            </Box>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default CompartilharExterno;
