import { forwardRef } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

export const SimpleCard = forwardRef<HTMLDivElement, BoxProps>(
  ({ children, ...rest }, ref) => {
    return (
      <Box
        ref={ref}
        p={{ base: 4, sm: 6, md: 8 }}
        bg="white"
        boxShadow="md"
        borderRadius="md"
        {...rest}
      >
        {children}
      </Box>
    );
  }
);
