import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { toast } from 'react-toastify';

import { enumIdentificacaoIntegracao } from 'constants/enum/enumIdentificacaoIntegracao';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { sistemaApiKey } from 'services/sistemaApiKey';
import { ResponseApi } from 'services/api';

import { InfoIntegracaoProps } from 'pages/Integracoes/LojaAplicativos/PdvAutonomo/PainelAdministrador/validationForms';

type PdvAutonomoProviderProps = {
  children: React.ReactNode;
};

type PdvAutonomoContextProps = {
  infoIntegracao: InfoIntegracaoProps | undefined;
  isLoading: boolean;
  dataConfiguracao?: IntegracaoConfiguracoesProps;
};

type IntegracaoConfiguracoesProps = {
  IdentificacaoEtapas: number;
  DataScript: Date;
  DataHoraUltimoBackup: null | Date;
  UrlScript: null | string;
};

export const PdvAutonomoContext = createContext<PdvAutonomoContextProps>(
  {} as PdvAutonomoContextProps
);

export function PdvAutonomoProvider({
  children,
}: PdvAutonomoProviderProps): JSX.Element {
  const [infoIntegracao, setInfoIntegracao] = useState<InfoIntegracaoProps>();
  const [isLoading, setIsLoading] = useState(false);

  const dataPdvConfig = infoIntegracao?.configuracoes
    ? (JSON.parse(
        infoIntegracao?.configuracoes
      ) as IntegracaoConfiguracoesProps)
    : undefined;

  const dataConfiguracao = dataPdvConfig;

  const cadastrarIntegracao = useCallback(async () => {
    const response = await sistemaApiKey().post<void, ResponseApi<FormData>>(
      ConstanteEnderecoWebservice.PDV_AUTONOMO_CADASTRAR_INTEGRACAO,
      {
        identificacaoIntegracao: enumIdentificacaoIntegracao.PDV_AUTONOMO,
      }
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response?.sucesso) {
        return response.sucesso;
      }
    }

    return false;
  }, []);

  const obterIntegracaoCadastrada = useCallback(async () => {
    const response = await sistemaApiKey().get<
      void,
      ResponseApi<InfoIntegracaoProps>
    >(ConstanteEnderecoWebservice.PDV_AUTONOMO_OBTER_INTEGRACAO);

    return !!response?.sucesso;
  }, []);

  const obterInfoIntegracao = useCallback(async () => {
    const response = await sistemaApiKey().get<
      void,
      ResponseApi<InfoIntegracaoProps>
    >(ConstanteEnderecoWebservice.PDV_AUTONOMO_OBTER_INTEGRACAO);

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response?.sucesso && response?.dados) {
        setInfoIntegracao(response.dados);
      }
    }
  }, []);

  const obterIntegracao = useCallback(async () => {
    setIsLoading(true);
    const integracaoCadastrada = await obterIntegracaoCadastrada();

    if (!integracaoCadastrada) {
      const sucesso = await cadastrarIntegracao();

      if (sucesso) {
        await obterInfoIntegracao();
        setIsLoading(false);
        return;
      }
    }

    await obterInfoIntegracao();
    setIsLoading(false);
  }, [cadastrarIntegracao, obterInfoIntegracao, obterIntegracaoCadastrada]);

  useEffect(() => {
    obterIntegracao();
  }, [obterIntegracao]);

  return (
    <PdvAutonomoContext.Provider
      value={{
        infoIntegracao,
        dataConfiguracao,
        isLoading,
      }}
    >
      {children}
    </PdvAutonomoContext.Provider>
  );
}

export function usePdvAutonomoContext(): PdvAutonomoContextProps {
  const context = useContext(PdvAutonomoContext);

  if (!context)
    throw new Error(
      'PdvAutonomoContext must be used within a PdvAutonomoProvider.'
    );

  return context;
}
