import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import isPrenvent from 'helpers/layout/isPrenvent';
import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import useReloadRegistration from 'helpers/layout/useReloadRegistration';

import { ContainerListagem } from 'components/Layout/Listagem/containerListagem';

import { useForm, yupResolver, FormData } from '../validationForm';
import { UncontrolledForm } from '..';

interface CadastroProps {
  cadastroExterno?: boolean;
  descricaoTamanho?: string;
  onSubmitExterno?: (data: FormData) => Promise<boolean>;
}

const Cadastro: React.FC<CadastroProps> = ({
  cadastroExterno = false,
  descricaoTamanho = '',
  onSubmitExterno,
}) => {
  const history = useHistory();
  const isMountedRef = useIsMountedRef();

  const [isLoading, setIsLoading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues: {
      descricao: descricaoTamanho,
      ativo: true,
    },
  });
  const { handleSubmit, reset, getValues, formState, setFocus } = formMethods;

  const historyReload = useReloadRegistration({ setFormIsDirty });
  isPrenvent(formIsDirty);

  async function handlePostTamanho(data: FormData) {
    const response = await api.post<void, ResponseApi>(
      ConstanteEnderecoWebservice.TAMANHO_CADASTRAR,
      data
    );

    if (response.sucesso) {
      return true;
    }

    if (response.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    return false;
  }

  const onSubmit = handleSubmit(async () => {
    setIsLoading(true);

    const data = getValues();

    const success = onSubmitExterno
      ? await onSubmitExterno(data)
      : await handlePostTamanho(data);

    if (success) {
      toast.success('O cadastro foi realizado com sucesso.');

      setFormIsDirty(false);

      if (!cadastroExterno) {
        history.push(ConstanteRotas.TAMANHO);
      }
    }

    setIsLoading(false);
  });

  const onSubmitReset = handleSubmit(async () => {
    setIsLoading(true);

    const data = getValues();

    const success = await handlePostTamanho(data);

    if (success) {
      toast.success('O cadastro foi realizado com sucesso.');
      if (isMountedRef.current) setFocus('descricao');

      historyReload(ConstanteRotas.TAMANHO_CADASTRAR);
    }

    setIsLoading(false);
  });

  const latestProps = useRef({ descricaoTamanho });
  useEffect(() => {
    latestProps.current = { descricaoTamanho };
  });

  useEffect(() => {
    reset({
      descricao: latestProps.current.descricaoTamanho,
      ativo: true,
    });
  }, [reset]);

  useEffect(() => {
    setFormIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  return (
    <ContainerListagem
      formMethods={formMethods}
      isLoading={isLoading}
      onSubmit={onSubmit}
      onSubmitReset={onSubmitReset}
      formIsDirty={formIsDirty}
    >
      <UncontrolledForm />
    </ContainerListagem>
  );
};

export default Cadastro;
