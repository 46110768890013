import { toast } from 'react-toastify';
import axios from 'axios';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

export interface ExportarDocumentoProps {
  id: string;
  hashIdentificador: string;
  isDownloadExterno: boolean;
  periodo?: string;
}

interface DocumentoFiscalExportacaoProps {
  nome: string;
  arquivoSincronizado: boolean;
  arquivo: string;
  url: string;
}

export interface ProcessandoDownloadProps {
  downloadPercentage: number;
  valueTotalFiles: number;
  waitingResponseDownload: boolean;
}

const obterRequestAxios = async (url: string, nome: string) => {
  const res = await axios.get(url, {
    responseType: 'blob',
  });

  const blob = new Blob([res.data], { type: 'application/zip' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  const fileName = `${nome}`;
  link.download = fileName;
  link.click();
};

const baixarDadosXm = async (dados: DocumentoFiscalExportacaoProps[]) => {
  const axiosList = [] as any[];

  await dados.forEach(({ url, nome }) => {
    axiosList.push(obterRequestAxios(url, nome));
  });

  await Promise.all(axiosList);
};

export const exportarDocumentoFiscal = async ({
  id,
  hashIdentificador,
  isDownloadExterno,
  periodo,
}: ExportarDocumentoProps): Promise<ProcessandoDownloadProps> => {
  const downloadPercentage = 0;
  const valueTotalFiles = 0;
  let waitingResponseDownload = true;

  const response = await api.get<
    void,
    ResponseApi<DocumentoFiscalExportacaoProps[]>
  >(
    `${ConstanteEnderecoWebservice.DOCUMENTO_FISCAL_EXPORTACAO_LISTAR_ARQUIVOS}/${id}/download?hash=${hashIdentificador}`
  );

  waitingResponseDownload = false;

  if (response) {
    if (response?.avisos) {
      if (isDownloadExterno) {
        toast.warning(
          `Os arquivos fiscais de ${periodo} ainda não foram exportados. Tente novamente mais tarde.`
        );
      } else {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }
    }

    if (response?.sucesso && response?.dados) {
      try {
        const { dados } = response;

        await baixarDadosXm(dados);

        toast.success('Download gerado com sucesso.');
      } catch (error) {
        toast.warning(
          isDownloadExterno
            ? `Os arquivos fiscais de ${periodo} ainda não foram exportados. Tente novamente mais tarde.`
            : 'Ocorreu um erro inesperado ao processar o download.'
        );
      }
    }
  }

  return {
    valueTotalFiles,
    downloadPercentage,
    waitingResponseDownload,
  };
};
