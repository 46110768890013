import React from 'react';
import { useTranslation } from 'react-i18next';
import { SalvarInserirNovoIcon, SalvarConfirmarIcon } from 'icons';
import ButtonPadrao from 'components/Button/ButtonPadrao';
import { Col, Form, Modal } from 'react-bootstrap';
import SelectPadrao from 'components/Select/SelectPadrao';
import InputPadrao from 'components/Input/InputPadrao';
import EstadosEnum from 'constants/enum/estados';
import ViasTransporteEnum from 'constants/enum/fiscal/viaTransporte';
import FormasImportacaoEnum from 'constants/enum/fiscal/formaImportacao';
import InputCpfCnpj from 'components/Input/InputCpfCnpj';
import { BootstrapNumberInput } from 'components/update/Input/BootstrapNumberInput';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import ModalPadrao from 'components/Modal/ModalPadrao';
import { useForm, yupResolver } from './validationForm';

interface UncontrolledFormInterface {
  show: boolean;
  onHide: () => void;
}

const DocumentoImportacaoModal: React.FC<UncontrolledFormInterface> = ({
  show,
  onHide,
}) => {
  const { t } = useTranslation();
  const { setValue, getValues: parentGetValues } = useFormContext();

  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
    reset,
    setFocus,
  } = useForm({
    resolver: yupResolver,
    shouldUnregister: true,
  });

  const handleAddItem = (data: any) => {
    const itemsAdicionados = parentGetValues('documentoFiscalImportacoes');

    if (
      itemsAdicionados.some(
        (item: any) => item.numeroDocumento === data.numeroDocumento
      )
    ) {
      toast.warning(
        t('Já foi adicionado um item com o número do documento informado.')
      );
      return false;
    }

    setValue('documentoFiscalImportacoes', [...itemsAdicionados, data]);

    return true;
  };

  const onSubmitReset = handleSubmit(async (data) => {
    const success = await handleAddItem(data);

    if (success) {
      reset({
        viaTransporte: '',
        formaImportacao: '',
        ufAdquirente: '',
        ufDesembaraco: '',
      });
    }
  });

  const onSubmit = handleSubmit(async (data) => {
    const success = await handleAddItem(data);

    if (success) {
      onHide();
    }
  });

  return (
    <>
      {show && (
        <ModalPadrao
          isOpen={show}
          handleOnHide={onHide}
          title={t('Adicionar documento de importação')}
          subtitle={t('Informe os dados de documento de importação da nota.')}
          onEntered={() => {
            setFocus('numeroDocumento');
          }}
          maxWidth={800}
          size="lg"
        >
          <Form>
            <Modal.Body>
              <Form.Row>
                <Col md={3} xl={4}>
                  <InputPadrao
                    type="text"
                    id="numeroDocumento"
                    label={t('Número do documento')}
                    defaultValue=""
                    maxLength={12}
                    error={errors.numeroDocumento}
                    disabled={false}
                    required
                    control={control}
                  />
                </Col>
                <Col md={6} xl={4}>
                  <InputPadrao
                    type="text"
                    id="codigoExportador"
                    label={t('Código do exportador')}
                    defaultValue=""
                    maxLength={60}
                    error={errors.codigoExportador}
                    disabled={false}
                    required
                    control={control}
                  />
                </Col>
                <Col md={3} xl={4}>
                  <InputPadrao
                    type="date"
                    id="dataRegistro"
                    label="Data do registro"
                    max={new Date().toISOString().slice(0, 10)}
                    error={errors.dataRegistro}
                    required
                    control={control}
                  />
                </Col>
                <Col md={3} xl={4}>
                  <InputPadrao
                    type="date"
                    id="dataDesembaraco"
                    label="Data do desembaraço"
                    max={new Date().toISOString().slice(0, 10)}
                    error={errors.dataDesembaraco}
                    required
                    control={control}
                  />
                </Col>
                <Col md={6} xl={4}>
                  <SelectPadrao
                    id="viaTransporte"
                    name="viaTransporte"
                    label={t('Via de transporte internacional')}
                    placeholder="Selecione"
                    defaultValue={null}
                    control={control}
                    error={errors.viaTransporte}
                    options={Object.entries(ViasTransporteEnum.properties).map(
                      (value: any) => {
                        return (
                          {
                            label: t(value[1].name),
                            value: value[1].value,
                          } || {}
                        );
                      }
                    )}
                    readonly={false}
                    required
                  />
                </Col>
                <Col md={3} xl={4}>
                  <BootstrapNumberInput
                    id="valorAfrmm"
                    name="valorAfrmm"
                    label={t('Valor AFRMM')}
                    precision={13}
                    scale={2}
                    defaultValue={0}
                    leftElement="R$"
                    control={control}
                    error={errors.valorAfrmm}
                    disabled={false}
                    required
                  />
                </Col>
                <Col md={3} xl={4}>
                  <SelectPadrao
                    id="formaImportacao"
                    name="formaImportacao"
                    label={t('Forma de importação')}
                    placeholder="Selecione"
                    defaultValue={null}
                    control={control}
                    error={errors.formaImportacao}
                    options={Object.entries(
                      FormasImportacaoEnum.properties
                    ).map((value: any) => {
                      return (
                        {
                          label: t(value[1].name),
                          value: value[1].value,
                        } || {}
                      );
                    })}
                    readonly={false}
                    required
                  />
                </Col>
                <Col md={3} xl={4}>
                  <SelectPadrao
                    id="ufAdquirente"
                    name="ufAdquirente"
                    label={t('UF adquirente')}
                    defaultValue=""
                    placeholder="Selecione"
                    control={control}
                    error={errors.ufAdquirente}
                    options={Object.entries(EstadosEnum.properties).map(
                      (value: any) => {
                        return (
                          {
                            label: t(value[1].sigla),
                            value: value[1].sigla,
                          } || {}
                        );
                      }
                    )}
                    readonly={false}
                    required={false}
                  />
                </Col>
                <Col md={6} xl={4}>
                  <InputCpfCnpj
                    id="cnpjAdquirente"
                    name="cnpjAdquirente"
                    label={t('CNPJ adquirente')}
                    control={control}
                    autoFocus
                    error={errors.cnpjAdquirente}
                    disabled={false}
                    setError={() => {}}
                    onlyCnpj
                    showSearchIcon={false}
                    required={false}
                  />
                </Col>
                <Col md={9} xl={8}>
                  <InputPadrao
                    type="text"
                    id="localDesembaraco"
                    label={t('Local do desembaraço aduaneiro')}
                    defaultValue=""
                    maxLength={60}
                    error={errors.localDesembaraco}
                    disabled={false}
                    required
                    control={control}
                  />
                </Col>
                <Col md={3} xl={4}>
                  <SelectPadrao
                    id="ufDesembaraco"
                    name="ufDesembaraco"
                    label={t('UF do desembaraço')}
                    defaultValue=""
                    placeholder="Selecione"
                    control={control}
                    error={errors.ufDesembaraco}
                    options={Object.entries(EstadosEnum.properties).map(
                      (value: any) => {
                        return (
                          {
                            label: t(value[1].sigla),
                            value: value[1].sigla,
                          } || {}
                        );
                      }
                    )}
                    readonly={false}
                    required
                  />
                </Col>
              </Form.Row>
            </Modal.Body>

            <Modal.Footer>
              <ButtonPadrao
                id="adicionarInserirNovo"
                type="button"
                variant="outline-secondary"
                name="salvarInserirNovo"
                text={t('Adicionar e inserir novo')}
                icon={SalvarInserirNovoIcon}
                disabled={false}
                onClick={onSubmitReset}
                style={{ height: '35px', width: '100%', maxWidth: '225px' }}
              />
              <ButtonPadrao
                id="adicionarDocumentoReferenciado"
                name="adicionarDocumentoReferenciado"
                text={t('Adicionar')}
                icon={SalvarConfirmarIcon}
                type="button"
                variant="success"
                onClick={onSubmit}
                style={{ height: '35px', width: '100%', maxWidth: '120px' }}
              />
            </Modal.Footer>
          </Form>
        </ModalPadrao>
      )}
    </>
  );
};

export default DocumentoImportacaoModal;
