export type StatusRelatorio = 1 | 2 | 3;

const StatusRelatorioInventario = {
  AGUARDANDO: 1,
  EM_PROCESSAMENTO: 2,
  CONCLUIDO: 3,

  properties: {
    1: { name: 'Aguardando', value: 1 },
    2: { name: 'Em processamento', value: 2 },
    3: { name: 'Concluído', value: 3 },
  },
};

export default StatusRelatorioInventario;
