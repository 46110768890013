import React, { useMemo, useEffect } from 'react';
import {
  useMediaQuery,
  Divider,
  HStack,
  Text,
  VStack,
  Center,
  Icon,
  Button,
  Flex,
} from '@chakra-ui/react';
import { GlobalHotKeys } from 'react-hotkeys';

import { usePagamentoContext } from 'store/PDV/Pagamento';
import { useAcrescimosDescontosContext } from 'store/PDV/AcrescimosDescontos';

import { DescontosIcon, PedidosIcon } from 'icons';
import HeaderContainer from 'components/PDV/Header/Container';
import HeaderMenuOptions from 'components/PDV/Header/MenuOptions';
import OperacaoPedidoObservacaoCliente from 'components/PDV/Header/OperacaoPedidoObservacaoCliente';
import { TextValor } from 'components/PDV/Text/TextValor';
import TipoValorEnum from 'constants/enum/tipoValor';

const MenuDivider = () => (
  <Center h="65px">
    <Divider orientation="vertical" />
  </Center>
);

const InternalHeader: React.FC = () => {
  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');
  const isLargerThan900 = !isSmallerThan900;

  const {
    aparecerValorAcrescimoHeaderPdv,
    setValorTotalVenda,
    totais: {
      valorTotalItensSemDesconto = 0,
      tipoDescontoAdicional,
      valorTotalItensComDesconto = 0,
      valorTotalAcrescimo = 0,
      valorDescontoAdicional = 0,
      valorTotalFrete = 0,
      valorTotalOutrasDespesas = 0,
    } = {},
  } = usePagamentoContext();

  const validandoValorAcrescimo =
    valorTotalAcrescimo < 0 ? 0 : valorTotalAcrescimo;

  const {
    handleOpenAcrescimosDescontosModal,
    corAcrescimoDesconto,
    acrescimosDescontosValor,
  } = useAcrescimosDescontosContext();

  const somandoValoresDoPagamento = Number(
    valorTotalItensComDesconto +
      validandoValorAcrescimo +
      valorTotalFrete +
      valorTotalOutrasDespesas
  );

  const calculandoPorcentagemDesconto = useMemo(() => {
    if (!valorDescontoAdicional) {
      return somandoValoresDoPagamento;
    }

    return (
      somandoValoresDoPagamento -
      (valorDescontoAdicional / 100) * valorTotalItensComDesconto
    );
  }, [
    valorDescontoAdicional,
    somandoValoresDoPagamento,
    valorTotalItensComDesconto,
  ]);

  const valorTotalAPagar = () => {
    if (tipoDescontoAdicional === TipoValorEnum.PORCENTAGEM) {
      return calculandoPorcentagemDesconto;
    }
    return somandoValoresDoPagamento - valorDescontoAdicional;
  };
  const totalAPagar = valorTotalAPagar();

  useEffect(() => {
    setValorTotalVenda(totalAPagar);
  }, [setValorTotalVenda, totalAPagar]);

  const keyMap = {
    F8: ['f8'],
  };
  const handlersHotKeys = {
    F8: (event: any) => {
      event.preventDefault();
      handleOpenAcrescimosDescontosModal();
    },
  };

  return (
    <>
      <GlobalHotKeys keyMap={keyMap} handlers={handlersHotKeys} />
      <Flex
        flexDirection="column"
        w="full"
        h={{
          base: '125px',
          md: '100px',
        }}
      >
        <HeaderContainer>
          <HStack
            spacing={{ base: 8, lg: 10, xl: 12 }}
            w="full"
            justifyContent="flex-start"
          >
            {isLargerThan900 && (
              <>
                <VStack spacing={2}>
                  <Text color="white" fontSize="sm" lineHeight="none">
                    Total a pagar:
                  </Text>
                  <TextValor valor={totalAPagar} />
                </VStack>
                <MenuDivider />
                <VStack alignItems="flex-start">
                  <HStack justifyContent="space-between" w="full" color="white">
                    <HStack>
                      <Icon as={PedidosIcon} h={5} w={5} />
                      <Text fontSize="sm">Valor do pedido</Text>
                    </HStack>
                    <TextValor
                      color="white"
                      valor={valorTotalItensSemDesconto}
                      fontSize="md"
                    />
                  </HStack>
                  <HStack justifyContent="space-between" w="full" color="white">
                    <HStack>
                      <Icon as={DescontosIcon} h={5} w={5} />
                      <Button
                        variant="link"
                        colorScheme="white"
                        size="sm"
                        onClick={() => {
                          handleOpenAcrescimosDescontosModal();
                        }}
                      >
                        Acréscimos e descontos
                      </Button>
                    </HStack>
                    <TextValor
                      color={
                        aparecerValorAcrescimoHeaderPdv
                          ? 'white'
                          : corAcrescimoDesconto
                      }
                      valor={acrescimosDescontosValor}
                      fontSize="md"
                    />
                  </HStack>
                </VStack>
                <MenuDivider />
              </>
            )}
            <OperacaoPedidoObservacaoCliente w="auto" />
          </HStack>
          {isSmallerThan900 && <HeaderMenuOptions />}
        </HeaderContainer>
        {isSmallerThan900 && (
          <Flex alignItems="center" w="full" h="50px" px={6} bg="primary.50">
            <HStack alignItems="baseline">
              <Text color="white" fontSize="xs" lineHeight="none">
                Total a pagar:
              </Text>
              <TextValor
                valor={totalAPagar}
                symbolFontSize="xs"
                fontSize="lg"
              />
            </HStack>
          </Flex>
        )}
      </Flex>
    </>
  );
};

export default InternalHeader;
