import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

type MovimentacaoEstoque = {
  tipoOperacao: number;
  localEstoqueOpcaoSelect: { id: string; nome: string };
  usuarioId: string;
  usuarioNome: string;
  motivo: string;
  dataEmissao: string;
  dataHoraUltimaAlteracao: string;
};

export async function obterMovimentacaoEstoque(operacaoId: string) {
  const response = await api.get<
    void,
    ResponseApi<MovimentacaoEstoque | undefined>
  >(ConstanteEnderecoWebservice.MOVIMENTACAO_ESTOQUE_OBTER, {
    params: { operacaoId },
  });

  if (response) {
    if (response.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (response.sucesso && response.dados) {
      return response.dados;
    }
  }

  return undefined;
}
