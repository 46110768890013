import { useEffect, useState } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  ModalHeader,
  Text,
  Flex,
  useMediaQuery,
  Divider,
  ModalFooter,
  FormLabel,
  Button,
  GridItem,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { create, InstanceProps } from 'react-modal-promise';
import { toast } from 'react-toastify';

import TipoSituacaoBaixa from 'constants/enum/situacaoBaixa';
import TipoPlanoContaEnum from 'constants/enum/tipoPlanoConta';
import getOptionsByEnum from 'helpers/format/getOptionsByEnum';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { StatusPesquisaClientesFornecedor } from 'constants/enum/statusPesquisaClientesFornecedor';
import { formatOptionsSelectClient } from 'helpers/format/formatSelectClient';

import InputInteger from 'components/PDV/InputInteger';
import ManterFoco from 'components/Geral/ManterFoco';
import InputDateRange from 'components/PDV/InputDateRange';
import Input from 'components/PDV/Input';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import AsyncSelect from 'components/PDV/Select/AsyncSelectPadrao';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';

import { FormData } from '../validationForm';

type ClientesOptionResponse = {
  id: string;
  nome: string;
  endereco: string;
  codigo: number;
  cpfCnpj: string;
};

type FormasPagamentoOptionResponse = {
  id: string;
  nome: string;
};

type PlanoContasOptionResponse = {
  id: string;
  nome: string;
};

type Option = {
  label: string;
  value: string;
};

type ModalDetalhesPedidoProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<FormData> & {
    currentFilters?: FormData;
    filtersSubmit?: () => void;
  };

export const ModalBuscaAvancada = create<ModalDetalhesPedidoProps, FormData>(
  ({ onResolve, filtersSubmit, onReject, currentFilters, ...rest }) => {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    const formMethods = useForm<FormData>({
      defaultValues: currentFilters,
    });

    const location = window.location.pathname;
    const typeOfPlanoConta = location.includes('contas-pagar')
      ? TipoPlanoContaEnum.DEBITO
      : TipoPlanoContaEnum.CREDITO;

    const [
      isPlanosContasOptionsLoading,
      setIsPlanosContasOptionsLoading,
    ] = useState(false);
    const [planosContasOptions, setPlanosContasOptions] = useState<Option[]>(
      []
    );

    const [
      isFormasPagamentoOptionsLoading,
      setIsFormasPagamentoOptionsLoading,
    ] = useState(false);
    const [formasPagamentoOptions, setFormasPagamentoOptions] = useState<
      Option[]
    >([]);

    const handleSubmit = formMethods.handleSubmit((data) => {
      onResolve(data);
    });

    async function getClientesOptions(inputValue: string) {
      const response = await api.get<
        void,
        ResponseApi<ClientesOptionResponse[]>
      >(ConstanteEnderecoWebservice.CLIENTE_FORNECEDOR_LISTAR_SELECT, {
        params: {
          cpfCnpjNomeApelidoCodigoExterno: inputValue,
          filtroTipoCadastroPessoa: StatusPesquisaClientesFornecedor.CLIENTES,
        },
      });

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response.sucesso && response.dados) {
          return response.dados.map((client) => {
            const option = formatOptionsSelectClient(client);
            return option;
          });
        }
      }

      return [];
    }

    useEffect(() => {
      async function getContasPagarOptions() {
        setIsPlanosContasOptionsLoading(true);

        const response = await api.get<
          void,
          ResponseApi<PlanoContasOptionResponse[]>
        >(ConstanteEnderecoWebservice.PLANO_CONTA_LISTAR_SELECT, {
          params: {
            tipoPlanoConta: typeOfPlanoConta,
          },
        });

        if (response) {
          if (response.avisos) {
            response.avisos.forEach((aviso) => toast.warning(aviso));
          }

          if (response.sucesso && response.dados) {
            setPlanosContasOptions(
              response.dados.map((planoConta) => ({
                label: planoConta.nome,
                value: planoConta.id,
              }))
            );
          }
        }

        setIsPlanosContasOptionsLoading(false);
      }

      getContasPagarOptions();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      async function getFormasRecebimentosOptions() {
        setIsFormasPagamentoOptionsLoading(true);

        const response = await api.get<
          void,
          ResponseApi<FormasPagamentoOptionResponse[]>
        >(
          ConstanteEnderecoWebservice.FORMA_PAGAMENTO_RECEBIMENTO_LISTAR_RECEBIMENTOS
        );

        if (response) {
          if (response.avisos) {
            response.avisos.forEach((aviso) => toast.warning(aviso));
          }

          if (response.sucesso && response.dados) {
            setFormasPagamentoOptions(
              response.dados.map((formaPagamento) => ({
                label: formaPagamento.nome,
                value: formaPagamento.id,
              }))
            );
          }
        }

        setIsFormasPagamentoOptionsLoading(false);
      }

      getFormasRecebimentosOptions();
    }, []);

    return (
      <ModalPadraoChakra
        size={isLargerThan900 ? '2xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalContent
          bg="gray.50"
          marginBottom={{ base: 0, sm: '3.75rem' }}
          marginTop={{ base: 0, sm: '3.75rem' }}
          w="2xl"
        >
          <ManterFoco
            style={{
              width: '2xl',
            }}
          >
            <ModalBody
              borderRadius="5px"
              w={isLargerThan900 ? '2xl' : 'full'}
              p={0}
            >
              <Flex flexDirection="column" align="stretch">
                <ModalHeader>
                  <Text color="primary.50" fontSize="md">
                    Busca avançada
                  </Text>
                  <Text color="gray.700" fontSize="xs">
                    Utilize os filtros de busca para uma pesquisa específica.
                    Todos os campos são acumulativos.
                  </Text>
                </ModalHeader>
                <Divider orientation="horizontal" width="92%" margin="0 auto" />
                <ModalCloseButton
                  fontSize="2xs"
                  border="none"
                  _hover={{ bg: 'none' }}
                  _focus={{ border: 'none' }}
                />
                <ModalBody pb="4" pt="5">
                  <SimpleGridForm>
                    <FormProvider {...formMethods}>
                      <AsyncSelect
                        name="clienteFornecedorId"
                        autoFocus
                        label="Nome do cliente"
                        isClearable
                        placeholder="Selecione a razão social ou nome fantasia"
                        asControlledByObject
                        handleGetOptions={getClientesOptions}
                        colSpan={12}
                        shouldAppearTheAddress
                      />

                      <GridItem colSpan={{ base: 12, lg: 6 }}>
                        <FormLabel mb="0" fontSize="sm" color="black">
                          Data de vencimento
                        </FormLabel>
                        <InputDateRange
                          isClearable
                          id="dataVencimento"
                          name="dataVencimento"
                          borderColor="gray.100"
                          borderRadius="md"
                          placeholder="dd/mm/aaaa"
                          startDateName="dataVencimentoInicio"
                          endDateName="dataVencimentoFim"
                        />
                      </GridItem>
                      <GridItem colSpan={{ base: 12, lg: 6 }}>
                        <FormLabel mb="0" fontSize="sm" color="black">
                          Data de emissão
                        </FormLabel>
                        <InputDateRange
                          isClearable
                          id="dataEmissao"
                          name="dataEmissao"
                          borderColor="gray.100"
                          borderRadius="md"
                          placeholder="dd/mm/aaaa"
                          startDateName="dataEmissaoInicio"
                          endDateName="dataEmissaoFim"
                        />
                      </GridItem>

                      <GridItem colSpan={{ base: 12, lg: 6 }}>
                        <FormLabel mb="0" fontSize="sm" color="black">
                          Data do recebimento
                        </FormLabel>
                        <InputDateRange
                          isClearable
                          id="dataPagamento"
                          name="dataRecebimento"
                          borderColor="gray.100"
                          borderRadius="md"
                          placeholder="dd/mm/aaaa"
                          startDateName="dataPagamentoInicio"
                          endDateName="dataPagamentoFim"
                        />
                      </GridItem>

                      <GridItem colSpan={{ base: 12, lg: 6 }}>
                        <FormLabel mb="0" fontSize="sm" color="black">
                          Data da compensação
                        </FormLabel>
                        <InputDateRange
                          isClearable
                          name="dataCompensacao"
                          id="dataCompensacao"
                          borderRadius="md"
                          borderColor="gray.100"
                          placeholder="dd/mm/aaaa"
                          startDateName="dataCompensacaoInicio"
                          endDateName="dataCompensacaoFim"
                        />
                      </GridItem>

                      <GridItem colSpan={{ base: 12, lg: 12 }}>
                        <SelectPadrao
                          label="Plano de contas"
                          id="planoContasId"
                          isClearable
                          placeholder="Selecione um plano de contas"
                          name="planoContasId"
                          options={planosContasOptions}
                          isLoading={isPlanosContasOptionsLoading}
                        />
                      </GridItem>

                      <GridItem colSpan={{ base: 12, lg: 6 }}>
                        <SelectPadrao
                          label="Forma de recebimento na emissão"
                          id="formaPagamentoEmissaoId"
                          isClearable
                          placeholder="Selecione o recebimento"
                          name="formaPagamentoEmissaoId"
                          options={formasPagamentoOptions}
                          isLoading={isFormasPagamentoOptionsLoading}
                        />
                      </GridItem>
                      <GridItem colSpan={{ base: 12, lg: 6 }}>
                        <SelectPadrao
                          label="Forma de recebimento na baixa"
                          id="formaPagamentoBaixaId"
                          placeholder="Selecione o recebimento"
                          isClearable
                          name="formaPagamentoBaixaId"
                          options={formasPagamentoOptions}
                          isLoading={isFormasPagamentoOptionsLoading}
                        />
                      </GridItem>

                      <GridItem colSpan={{ base: 12, lg: 6 }}>
                        <InputInteger
                          label="Identificador"
                          id="identificador"
                          maxLength={13}
                          name="identificador"
                          placeholder="Digite o número"
                          borderColor="gray.100"
                        />
                      </GridItem>
                      <GridItem colSpan={{ base: 12, lg: 6 }}>
                        <InputInteger
                          label="Número do documento"
                          id="numeroDocumento"
                          maxLength={20}
                          name="numeroDocumento"
                          placeholder="Digite o número"
                          borderColor="gray.100"
                        />
                      </GridItem>

                      <GridItem colSpan={{ base: 12, lg: 6 }}>
                        <SelectPadrao
                          label="Situação"
                          id="situacao"
                          placeholder="Selecione uma situação"
                          name="situacao"
                          options={getOptionsByEnum(TipoSituacaoBaixa).filter(
                            (properties) =>
                              properties.value >= 1 && properties.value <= 3
                          )}
                        />
                      </GridItem>

                      <GridItem colSpan={{ base: 12, lg: 6 }}>
                        <Input
                          label="Histórico"
                          maxLength={500}
                          borderColor="gray.100"
                          borderRadius="md"
                          placeholder="Digite o motivo ou detalhes importantes do lançamento"
                          name="historico"
                        />
                      </GridItem>
                    </FormProvider>
                  </SimpleGridForm>
                  <Divider orientation="horizontal" width="100%" mt="8" />
                </ModalBody>

                <ModalFooter>
                  <GridItem
                    w="full"
                    display="flex"
                    justifyContent={['flex-start', 'flex-end']}
                    colSpan={{ base: 12, lg: 12 }}
                  >
                    <Button
                      id="cancelar"
                      name="cancelar"
                      colorScheme="gray.100"
                      variant="outline"
                      borderRadius="md"
                      fontSize="sm"
                      type="button"
                      w="24"
                      h="8"
                      mr={6}
                      _hover={{ bg: 'gray.50' }}
                      onClick={onClose}
                    >
                      Voltar
                    </Button>
                    <Button
                      id="pesquisar"
                      name="pesquisar"
                      w="24"
                      h="8"
                      backgroundColor="aquamarine.600"
                      borderRadius="md"
                      fontSize="sm"
                      color="white"
                      variant="success"
                      type="button"
                      _hover={{ bg: 'aquamarine.700' }}
                      onClick={handleSubmit}
                    >
                      Pesquisar
                    </Button>
                  </GridItem>
                </ModalFooter>
              </Flex>
            </ModalBody>
          </ManterFoco>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
