export type TipoOperacaoEnum = 1 | 2 | 3;

const TipoOperacaoEnum = {
  TODOS: 1,
  ENTRADA: 2,
  SAIDA: 3,

  properties: {
    1: { label: 'Todos', value: 1 },
    2: { label: 'Entrada', value: 2 },
    3: { label: 'Saída', value: 3 },
  },
};

export default TipoOperacaoEnum;

export const tipoOperacaoOptions = [
  { label: 'Todos', value: TipoOperacaoEnum.TODOS },
  { label: 'Entrada', value: TipoOperacaoEnum.ENTRADA },
  { label: 'Saída', value: TipoOperacaoEnum.SAIDA },
];
