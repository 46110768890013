export type CamposPersonalizadosProps = {
  campoPersonalizadoId: string;
  valor: number | string | null;
};

export type FiltrosProps = {
  nomeReferencia: string;
  sku: string;
  codigoBarras: string;
  tipoEstoque: number;
  statusConsulta: number;
  cores: null | string[];
  tamanhos: null | string[];
  categoriasProduto: null | string[];
  marcas: null | string[];
  tags: null | string[];

  camposPersonalizados?: CamposPersonalizadosProps[];
};

export const defaultValue = {
  camposPersonalizados: [],
  categoriasProduto: null,
  codigoBarras: '',
  cores: null,
  marcas: null,
  nomeReferencia: '',
  sku: '',
  statusConsulta: 1,
  tags: null,
  tamanhos: null,
  tipoEstoque: 1,
  referencia: null,
};
