import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export type FormData = {
  tabelaPreco: string | null;
};

const schema = yup.object().shape({
  tabelaPreco: yup
    .string()
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const TabelaPrecoValue = {
  CRIAR_TABELA_PRECO: 0,
  SELECIONAR_TABELA_EXISTENTE: 1,
};

export const dataDefaultTabelaPreco = [
  {
    title: 'Criar uma tabela de preços exclusiva para a Tray',
    isDisable: false,
    isRecomendado: true,
    isChecked: false,
    value: TabelaPrecoValue.CRIAR_TABELA_PRECO,
    description:
      'Utilizar uma tabela de preços exclusiva para a integração com a Tray permite que você altere o valor da venda de qualquer produto de forma específica para os anúncios, acrescentando sua margem sem alterar o preço praticado na venda física ou em outras plataformas.',
  },

  {
    title: 'Selecionar uma tabela de preços já existente no sistema',
    isRecomendado: false,
    isDisable: false,
    value: TabelaPrecoValue.SELECIONAR_TABELA_EXISTENTE,
    description:
      'Se você já trabalha com uma tabela de preços específica para venda online, poderá utilizar a mesma, no entanto não será possível diferenciar o preço de venda entre as plataformas.',
  },
];

export const yupResolver = yupResolverInstance(schema);
