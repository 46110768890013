import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { DndProvider } from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';

import { usePlanoContasGeralContext } from 'store/PlanoContas/PlanoContasGeral';
import PlanoContasVisaoDREProvider from 'store/PlanoContas/PlanoContasVisaoDRE';
import PlanoContasVisaoCaixaProvider from 'store/PlanoContas/PlanoContasVisaoCaixa';
import ConstanteFuncionalidades from 'constants/permissoes';
import StatusConsultaSimplificadoEnum from 'constants/enum/statusConsultaSimplificado';
import auth from 'modules/auth';

import SelectBusca from 'components/Select/SelectBusca';
import Loading from 'components/Layout/Loading/LoadingPadrao';

import TipoPlanoContaEnum from 'constants/enum/tipoPlanoConta';
import AdicionarPlanoContaModal from '../AdicionarPlanoContaModal';

import { ItemsVisaoDreContainer } from '../VisaoDRE/ItemsContainer';
import ItemVisaoDreDragLayer from '../VisaoDRE/ItemDragLayer';

import { ItemsVisaoCaixaContainer } from '../VisaoCaixa/ItemsContainer';

import {
  TitlesContainer,
  Title,
  Separator,
  ActionsContainer,
  ActionButton,
  ActionButtonText,
  RecolherNiveisIcon,
  ExpandirNiveisIcon,
} from './styles';
import { ItemInicialVisaoCaixa } from '../VisaoCaixa/ItemInicial';

const CardContent = () => {
  const { t } = useTranslation();
  const {
    isLoading,
    itemsExpandidos,
    setItemsExpandidos,
    handleGetPlanosConta,
    handleCloseModal,
    modalIsOpen,
    currentPlanoConta,
    visaoPlanoContas,
    handleToggleVisaoPlanoContas,
  } = usePlanoContasGeralContext();
  const { control, getValues } = useFormContext();

  const permissaoPlanoContasAlterar = useMemo(
    () => auth.possuiPermissao(ConstanteFuncionalidades.PLANO_CONTA_ALTERAR),
    []
  );

  return (
    <>
      {isLoading && <Loading />}
      <Row>
        <Col xs={0}>
          <TitlesContainer>
            <Title>
              {visaoPlanoContas === 'caixa'
                ? 'A estrutura está agrupada em Receitas e Despesas. É possível incluir novas contas clicando no botão "mais" no canto direito.'
                : t(
                    'A estrutura possui 4 níveis, sendo que os dois primeiros são fixos. É possível editar novas contas apenas nos níveis 3 e 4, clicando no botão "mais" no canto direito. Clique, segure e arraste as TAGs para movê-las entre os últimos níveis.'
                  )}
            </Title>
          </TitlesContainer>
        </Col>

        <Col
          xs={12}
          sm="auto"
          style={{
            minWidth: '230px',
          }}
        >
          <SelectBusca
            id="statusConsulta"
            name="statusConsulta"
            defaultValue={StatusConsultaSimplificadoEnum.ATIVOS}
            isDisabled={isLoading}
            control={control}
            options={Object.entries(
              StatusConsultaSimplificadoEnum.properties
            ).map((value: any) => {
              return (
                {
                  label: t(value[1].name),
                  value: value[1].value,
                } || {}
              );
            })}
            controlWidth="100%"
            controlHeight="35px"
            singleValueFontSize="13px"
            onSelect={() => {
              const statusConsulta =
                getValues('statusConsulta') ||
                StatusConsultaSimplificadoEnum.ATIVOS;
              handleGetPlanosConta(statusConsulta);
            }}
          />
        </Col>
      </Row>
      <Separator />
      <ActionsContainer>
        <ActionButton
          id="mudarVisualizacao"
          onClick={() => handleToggleVisaoPlanoContas()}
        >
          <ActionButtonText>{`Alternar para: ${
            visaoPlanoContas === 'caixa' ? 'Visão DRE' : 'Visão caixa'
          }`}</ActionButtonText>
        </ActionButton>

        <ActionButton
          id="expandirRecolher"
          onClick={() => setItemsExpandidos(!itemsExpandidos)}
        >
          {itemsExpandidos ? <RecolherNiveisIcon /> : <ExpandirNiveisIcon />}
          <ActionButtonText>
            {itemsExpandidos ? 'Recolher níveis' : 'Expandir níveis'}
          </ActionButtonText>
        </ActionButton>
      </ActionsContainer>
      {visaoPlanoContas === 'dre' ? (
        <PlanoContasVisaoDREProvider>
          <DndProvider options={HTML5toTouch}>
            <ItemsVisaoDreContainer />
            {permissaoPlanoContasAlterar.permitido ? (
              <ItemVisaoDreDragLayer />
            ) : null}
          </DndProvider>
        </PlanoContasVisaoDREProvider>
      ) : (
        <PlanoContasVisaoCaixaProvider>
          <ItemInicialVisaoCaixa title="Receitas" haveChildrens>
            <ItemsVisaoCaixaContainer
              tipoPlanoConta={TipoPlanoContaEnum.CREDITO}
            />
          </ItemInicialVisaoCaixa>
          <ItemInicialVisaoCaixa title="Despesas" haveChildrens>
            <ItemsVisaoCaixaContainer
              tipoPlanoConta={TipoPlanoContaEnum.DEBITO}
            />
          </ItemInicialVisaoCaixa>
        </PlanoContasVisaoCaixaProvider>
      )}
      <AdicionarPlanoContaModal
        show={modalIsOpen}
        onHide={handleCloseModal}
        planoConta={currentPlanoConta}
      />
    </>
  );
};

export default CardContent;
