import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';

import {
  TodosLocais,
  TodosTamanhos,
  VariacaoLinha,
} from 'components/Modal/ModalListarSaldoVariacaoPdv/types';

type DadosTabPanelLojaAtualProps = {
  variacoesEstoqueAgrupado: VariacaoLinha[];
  naoExisteTamanho: boolean;
  naoExisteCor: boolean;
  tamanhosAgrupados: string[];
};

export const DadosTabPanelLojaAtual = ({
  variacoesEstoqueAgrupado,
  naoExisteTamanho,
  naoExisteCor,
  tamanhosAgrupados,
}: DadosTabPanelLojaAtualProps) => {
  return (
    <Box>
      {variacoesEstoqueAgrupado.length > 0 ? (
        <Table mt="-10px">
          <Thead>
            <Tr>
              {naoExisteTamanho ? (
                <>
                  {naoExisteCor ? (
                    <Th fontSize="16px" color="aquamarine.600">
                      <Flex w="40px" justifyContent="flex-end">
                        Total
                      </Flex>
                    </Th>
                  ) : (
                    <>
                      <Th />
                      <Th
                        fontSize="16px"
                        alignItems="left"
                        color="aquamarine.600"
                        isNumeric
                      >
                        Total
                      </Th>
                    </>
                  )}
                </>
              ) : (
                <>
                  {naoExisteCor ? <></> : <Th />}
                  {tamanhosAgrupados.map((tamanho) => (
                    <Th
                      isNumeric
                      fontSize="16px"
                      color="primary.500"
                      key={tamanho}
                    >
                      {tamanho}
                    </Th>
                  ))}
                  <Th isNumeric fontSize="16px" color="aquamarine.600">
                    Total
                  </Th>
                </>
              )}
            </Tr>
          </Thead>
          <Tbody>
            {variacoesEstoqueAgrupado.map(({ cor, tamanhos, valorTamanho }) => {
              const totalValores = tamanhos.reduce(
                (acc, curr) => acc + (curr.quantidade || 0),
                0
              );

              const agruparTodosTamanhos = tamanhos.reduce((acc, curr) => {
                const itemAlreadyAddedIndex = acc.findIndex(
                  (item) => item.tamanho === curr.tamanho
                );

                if (itemAlreadyAddedIndex > -1) {
                  acc.splice(itemAlreadyAddedIndex, 1, {
                    ...acc[itemAlreadyAddedIndex],
                    locaisEstoque: [
                      ...acc[itemAlreadyAddedIndex].locaisEstoque,
                      {
                        quantidade: curr.quantidade,
                        localEstoque: curr.localEstoque,
                      },
                    ],
                  });
                } else {
                  acc.push({
                    tamanho: curr.tamanho,
                    locaisEstoque: [
                      {
                        quantidade: curr.quantidade,
                        localEstoque: curr.localEstoque,
                      },
                    ],
                  });
                }

                return acc;
              }, [] as TodosTamanhos[]);

              const agruparLocaisEstoque = tamanhos.reduce((acc, curr) => {
                const itemAlreadyAddedIndex = acc.findIndex(
                  (item) => item.localEstoque === curr.localEstoque
                );

                if (itemAlreadyAddedIndex > -1) {
                  acc.splice(itemAlreadyAddedIndex, 1, {
                    ...acc[itemAlreadyAddedIndex],
                    localEstoque: curr.localEstoque,
                    localQuantidade:
                      acc[itemAlreadyAddedIndex].localQuantidade +
                      curr.quantidade,
                  });
                } else {
                  acc.push({
                    localQuantidade: curr.quantidade,
                    localEstoque: curr.localEstoque,
                  });
                }

                return acc;
              }, [] as TodosLocais[]);
              return (
                <Tr key={`${cor}`}>
                  {cor ? (
                    <Td whiteSpace="nowrap" color="gray.700" fontSize="16px">
                      {cor || ''}
                    </Td>
                  ) : (
                    <></>
                  )}

                  {tamanhosAgrupados.map((tamanhoAgrupado) => {
                    const tamanho = agruparTodosTamanhos.find(
                      (val) => val.tamanho === tamanhoAgrupado
                    );

                    const quantidade = tamanho
                      ? tamanho.locaisEstoque.reduce(
                          (acc, curr) => acc + curr.quantidade,
                          0
                        )
                      : 0;

                    return (
                      <>
                        {valorTamanho ? (
                          <Td isNumeric fontSize="16px">
                            <Flex justify="end" w="full">
                              <Tooltip
                                w="180px"
                                isDisabled={quantidade === 0}
                                bg="gray.900"
                                color="white"
                                hasArrow
                                borderRadius="6px"
                                placement="left"
                                label={
                                  <Box padding="5px">
                                    <Text>
                                      {tamanho?.locaisEstoque.map(
                                        (valorLocalEstoque, index) => (
                                          <Box
                                            borderBottomWidth={
                                              index + 1 ===
                                              (tamanho?.locaisEstoque || [])
                                                .length
                                                ? 0
                                                : '1px'
                                            }
                                            borderColor="white"
                                            mb="8px"
                                            pb="8px"
                                          >
                                            <Flex align="baseline" gap="6px">
                                              <Text fontSize="2xs">
                                                ESTOQUE:
                                              </Text>
                                              <Text fontSize="sm">
                                                {valorLocalEstoque.localEstoque}
                                              </Text>
                                            </Flex>
                                            <Flex align="baseline" gap="6px">
                                              <Text fontSize="2xs">
                                                QUANTIDADE:{' '}
                                              </Text>
                                              <Text
                                                fontSize="sm"
                                                color={
                                                  valorLocalEstoque.quantidade <=
                                                  0
                                                    ? 'red.400'
                                                    : 'secondary.200'
                                                }
                                                ml="5px"
                                              >
                                                {valorLocalEstoque.quantidade}
                                              </Text>
                                            </Flex>
                                          </Box>
                                        )
                                      )}
                                    </Text>
                                  </Box>
                                }
                              >
                                <Text w="fit-content">{quantidade}</Text>
                              </Tooltip>
                            </Flex>
                          </Td>
                        ) : (
                          <></>
                        )}
                      </>
                    );
                  })}
                  <Td
                    fontSize="16px"
                    fontWeight="bold"
                    isNumeric
                    color="aquamarine.600"
                  >
                    <Flex
                      w={naoExisteCor && naoExisteTamanho ? '40px' : 'full'}
                      justify="flex-end"
                    >
                      <Tooltip
                        w="180px"
                        bg="gray.900"
                        color="white"
                        isDisabled={totalValores === 0}
                        hasArrow
                        borderRadius="6px"
                        placement="left"
                        px="24px"
                        py="12px"
                        label={
                          <Box padding="5px">
                            {agruparLocaisEstoque.map(
                              (valorLocalEstoque, index) => (
                                <Box
                                  borderBottomWidth={
                                    index + 1 ===
                                    (agruparLocaisEstoque || []).length
                                      ? 0
                                      : '1px'
                                  }
                                  borderColor="white"
                                  mb="8px"
                                  pb="8px"
                                >
                                  <Flex align="baseline" gap="6px">
                                    <Text fontSize="2xs">ESTOQUE:</Text>
                                    <Text fontSize="sm">
                                      {valorLocalEstoque.localEstoque}
                                    </Text>
                                  </Flex>
                                  <Flex align="baseline" gap="6px">
                                    <Text fontSize="2xs">QUANTIDADE: </Text>
                                    <Text
                                      fontSize="sm"
                                      color={
                                        valorLocalEstoque.localQuantidade <= 0
                                          ? 'red.400'
                                          : 'secondary.200'
                                      }
                                    >
                                      {valorLocalEstoque.localQuantidade}
                                    </Text>
                                  </Flex>
                                </Box>
                              )
                            )}
                          </Box>
                        }
                      >
                        <Text
                          w="fit-content"
                          fontSize="16px"
                          fontWeight="bold"
                          color="aquamarine.600"
                        >
                          {totalValores}
                        </Text>
                      </Tooltip>
                    </Flex>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      ) : (
        <Box>Não existem registros para serem mostrados</Box>
      )}
    </Box>
  );
};
