import React, { useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, GridItem, Link, Td, Tr } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import auth from 'modules/auth';
import api, { ResponseApi } from 'services/api';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import ConstanteFuncionalidades from 'constants/permissoes';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { shallowEqual } from 'helpers/validation/shallowEqual';

import { SearchInput } from 'components/update/Input/SearchInput';
import {
  PagedTable,
  PagedTableForwardRefData,
} from 'components/update/Table/PagedTable';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { ModalConfirmacaoInativar } from 'components/Modal/ModalConfirmacaoInativar';
import {
  GridPaginadaConsulta,
  GridPaginadaRetorno,
} from 'components/Grid/Paginacao';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { ButtonCadastrarNovo } from 'components/Layout/ButtonCadastrarNovo';
import { StatusCircle } from 'components/update/Table/StatusCircle';
import { FilterSelect } from 'components/update/Select/FilterSelect';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';

interface MarcasFiltros {
  ativo: boolean;
  nome: string;
}

interface Marca {
  id: string;
  nome: string;
  ativo: boolean;
}

const formDefaultValues = {
  nome: '',
  ativo: true,
};

const Listar = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [marcas, setMarcas] = useState([] as Array<Marca>);
  const [currentFilters, setCurrentFilters] = useState<MarcasFiltros>(
    formDefaultValues
  );

  const history = useHistory();
  const isMountedRef = useIsMountedRef();

  const formMethods = useForm({
    defaultValues: formDefaultValues,
  });

  const { setFocus, handleSubmit } = formMethods;

  const pageIsLoaded = useRef(false);
  const childRef = useRef<PagedTableForwardRefData>(null);

  const permissaoMarcaAlterar = auth.possuiPermissao(
    ConstanteFuncionalidades.MARCA_ALTERAR
  );

  const permissaoMarcaVisualizar = auth.possuiPermissao(
    ConstanteFuncionalidades.MARCA_VISUALIZAR
  );

  const handleHistoryPush = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history]
  );

  const getNomeLinkHref = useCallback(
    (id: string) => {
      let href = '';

      if (permissaoMarcaAlterar.permitido) {
        href = SubstituirParametroRota(ConstanteRotas.MARCA_ALTERAR, 'id', id);
      } else if (permissaoMarcaVisualizar.permitido) {
        href = SubstituirParametroRota(
          ConstanteRotas.MARCA_VISUALIZAR,
          'id',
          id
        );
      }

      return href;
    },
    [permissaoMarcaAlterar, permissaoMarcaVisualizar]
  );

  const handleReset = handleSubmit((data) => {
    const filtersIsDirty = !shallowEqual(data, currentFilters || {});

    if (filtersIsDirty) {
      setCurrentFilters(data);
    }
  });

  const handleRefresh = useCallback(() => {
    return childRef.current?.reload();
  }, []);

  const paginationHandle = useCallback(
    async (gridPaginadaConsulta: GridPaginadaConsulta) => {
      setIsLoading(true);

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<Marca>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.MARCA_LISTAR_PAGINADO,
          gridPaginadaConsulta
        ),
        { params: { nome: currentFilters.nome, ativo: currentFilters.ativo } }
      );

      if (response?.sucesso && isMountedRef.current) {
        setTotalRegistros(response.dados.total);
        setMarcas(response.dados.registros);
      }

      if (isMountedRef.current) {
        setIsLoading(false);

        if (!pageIsLoaded.current) {
          pageIsLoaded.current = true;

          setFocus('nome');
        }
      }
    },
    [currentFilters, isMountedRef, setFocus]
  );

  const excluirHandle = useCallback(
    async (marcaId: string, ativo: boolean) => {
      ModalConfirmacaoExcluir({
        callback: async (ok: boolean) => {
          if (ok) {
            setIsLoading(true);

            const response = await api.delete<void, ResponseApi>(
              ConstanteEnderecoWebservice.MARCA_EXCLUIR,
              {
                params: { id: marcaId },
              }
            );

            if (response?.sucesso) {
              toast.success('O cadastro foi removido com sucesso.');

              handleRefresh();
            }

            if (response) {
              ModalConfirmacaoInativar({
                response,
                rotaWebService: ConstanteEnderecoWebservice.MARCA_INATIVAR,
                id: marcaId,
                ativo,
                callback: (okInativar: boolean) => {
                  if (okInativar) handleRefresh();
                },
              });
            }

            setIsLoading(false);
          }
        },
      });
    },
    [handleRefresh]
  );

  return (
    <SimpleGridForm gap={{ base: '10px', sm: '10px', md: 8 }}>
      <FormProvider {...formMethods}>
        <GridItem colSpan={{ base: 12, md: 5, lg: 6 }}>
          <SearchInput
            type="search"
            placeholder="Buscar marca por nome"
            onEnterKeyPress={() => handleReset()}
            isDisabled={isLoading}
            id="nome"
            name="nome"
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, md: 2, lg: 2 }}>
          <Box w={{ base: 'full', md: '190px', lg: '190px' }}>
            <FilterSelect
              id="ativo"
              name="ativo"
              isDisabled={isLoading}
              options={[
                {
                  value: true,
                  label: 'Ativos',
                },
                {
                  value: false,
                  label: 'Inativos',
                },
                {
                  value: null,
                  label: 'Todos',
                },
              ]}
              onSelect={() => {
                handleReset();
              }}
            />
          </Box>
        </GridItem>
        <GridItem
          colSpan={{ base: 12, md: 5, lg: 4 }}
          display={{ base: '', sm: '', md: 'flex' }}
          justifyContent={{ base: 'flex-start', sm: 'flex-end' }}
        >
          <ButtonCadastrarNovo
            onClick={() => handleHistoryPush(ConstanteRotas.MARCA_CADASTRAR)}
            funcionalidade={ConstanteFuncionalidades.MARCA_CADASTRAR}
          />
        </GridItem>

        <GridItem mt={['10px', '10px', '-10px']} colSpan={12}>
          <PagedTable
            ref={childRef}
            itemsTotalCount={totalRegistros}
            loadColumnsData={paginationHandle}
            defaultKeyOrdered="nome"
            isLoading={isLoading}
            tableHeaders={[
              {
                content: <StatusCircle hasValue={false} />,
                key: 'Ativo',
                isOrderable: true,
                width: '37px',
              },
              {
                content: 'Nome',
                key: 'Nome',
                isOrderable: true,
                width: 'auto',
              },
              {
                content: 'Ações',
                key: 'Acoes',
                isOrderable: false,
                width: '38px',
              },
            ]}
            renderTableRows={marcas.map((marca) => (
              <Tr key={marca.id}>
                <Td style={{ textAlign: 'center' }}>
                  <StatusCircle isActive={marca.ativo} />
                </Td>
                <Td>
                  <Link id="link-visualizar" href={getNomeLinkHref(marca.id)}>
                    {marca.nome}
                  </Link>
                </Td>
                <Td>
                  <ActionsMenu
                    id="mostrarMais"
                    items={[
                      {
                        content: 'Editar',
                        onClick: () => {
                          handleHistoryPush(
                            SubstituirParametroRota(
                              ConstanteRotas.MARCA_ALTERAR,
                              'id',
                              marca.id
                            )
                          );
                        },
                        funcionalidade: ConstanteFuncionalidades.MARCA_ALTERAR,
                      },
                      {
                        content: 'Remover',
                        onClick: () => {
                          excluirHandle(marca.id, marca.ativo);
                        },
                        funcionalidade: ConstanteFuncionalidades.MARCA_EXCLUIR,
                      },
                    ]}
                  />
                </Td>
              </Tr>
            ))}
          />
        </GridItem>
      </FormProvider>
    </SimpleGridForm>
  );
};

export default Listar;
