import { useState } from 'react';
import { VStack, useMediaQuery } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import auth from 'modules/auth';
import { hideEmail } from 'helpers/format/email';
import api, { ResponseApi } from 'services/api';

import Title from 'components/Autenticacao/Text/Title';
import Paragraph from 'components/Autenticacao/Text/Paragraph';
import Input from 'components/Autenticacao/Input';
import ButtonSubmit from 'components/Autenticacao/Button/Submit';
import ButtonLink from 'components/Autenticacao/Button/Link';

import { useForm, yupResolver } from './validationForm';

type FormData = {
  email: string;
};

export const RecuperarDominio = () => {
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const isMountedRef = useIsMountedRef();

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const {
    handleSubmit,
    control,
    formState: { isValid, errors },
  } = useForm({
    resolver: yupResolver,
    mode: 'onChange',
  });

  const formIsInvalid = !isValid;

  const onSubmit = handleSubmit<FormData>(async (data) => {
    setIsLoading(true);

    const response = await api.post<void, ResponseApi>(
      `${ConstanteEnderecoWebservice.CLIENTE_MULTIEMPRESA_RECUPERAR_DOMINIO}${data.email}`
    );

    if (response) {
      if (response.sucesso) {
        const hiddenEmail = hideEmail(data.email);

        history.push(ConstanteRotas.DOMINIO_ENVIADO_COM_SUCESSO, {
          email: hiddenEmail,
        });

        return;
      }

      if (response.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }
    }
    if (isMountedRef.current) setIsLoading(false);
  });

  const handlePushAcesso = () => {
    setIsLoading(true);

    const urlApp = auth.getUrlApp();
    if (urlApp) window.location.replace(urlApp);

    if (isMountedRef.current) setIsLoading(false);
  };

  return (
    <VStack spacing={9}>
      <VStack spacing={2} alignItems="flex-start" w="full">
        <Title>Não sabe o nome da conta?</Title>
        <Paragraph>
          O nome da conta é o caminho personalizado
          {!isLargerThan900 && <br />} e {isLargerThan900 && <br />}
          exclusivo para acessar sua conta
          <br />
          <i>
            (ex: <strong>nomedasuaconta</strong>
            {`${window.location.host.replace('app', '')}`}).
          </i>{' '}
          Para <br /> saber o seu endereço, informe abaixo o
          {!isLargerThan900 && <br />} e-mail {isLargerThan900 && <br />}
          do administrador da conta.
        </Paragraph>
      </VStack>
      <VStack as="form" w="full" spacing={8} onSubmit={onSubmit}>
        <Input
          id="email"
          name="email"
          type="email"
          label="E-mail do administrador"
          defaultValue=""
          autoFocus
          control={control}
          errorText={errors?.email?.message ?? ''}
          isInvalid={!!errors?.email?.message}
          isDisabled={isLoading}
          maxLength={256}
        />

        <ButtonSubmit
          isLoading={isLoading}
          isDisabled={isLoading || formIsInvalid}
          isInvalid={formIsInvalid || isLoading}
        >
          Enviar
        </ButtonSubmit>
      </VStack>

      <ButtonLink onClick={handlePushAcesso} isDisabled={isLoading}>
        Voltar para tela de acesso
      </ButtonLink>
    </VStack>
  );
};
