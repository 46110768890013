import React, { useState } from 'react';
import { Button } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';

import OperacaoIntermediadorEnum from 'constants/enum/fiscal/operacaoIntermediador';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ModelosFiscaisEnum from 'constants/enum/fiscal/modelosFiscais';
import ConstanteRotasPDV from 'constants/rotasPDV';
import StatusFiscaisEnum from 'constants/enum/fiscal/statusFiscal';
import gerarNotaFiscal from 'helpers/api/Operacao/gerarNotaFiscal';
import { getCurrentDateWithoutSeconds } from 'helpers/data/getCurrentDateWithoutSeconds';
import formatUTCToLocateDateTimeInput from 'helpers/format/formatUTCToLocateDateTimeInput';
import api, { ResponseApi } from 'services/api';
import { useOperacaoContext } from 'store/PDV/Operacao';
import { useFinalizarContext } from 'store/PDV/Finalizar';
import { useSignalRContext } from 'store/SignalR';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';

import { RejeicaoInterface } from 'components/EmitirNotaFiscal/BoxRejeicao';
import { EmitirNFe, EmitirNFeData } from 'components/EmitirNotaFiscal/NFe';
import { formattedValueCpfCnpj } from 'helpers/format/formattedValueCpfCnpj';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import {
  ModalTransmissaoNota,
  ModalTransmissaoNotaResponse,
} from 'components/EmitirNotaFiscal/ModalTransmissaoNota';

const NFe = () => {
  const history = useHistory();
  const { operacaoId } = useOperacaoContext();
  const { casasDecimais } = usePadronizacaoContext();
  const {
    operacao,
    handleObterOperacao,
    handleTabsChange,
    setDefaultModeloFiscal,
    deveMostrarTelaEmitirNFCe,
    ambienteFiscal,
    informacoesLoja,
  } = useFinalizarContext();
  const { hubConnection, joinGroup, exitGroup } = useSignalRContext();
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rejeicao, setRejeicao] = useState<RejeicaoInterface>();

  const documentoFiscalExistente = !!operacao && !!operacao.NFe;
  const paisIdLoja = informacoesLoja?.paisId;
  const paisIdCliente = operacao?.cliente?.paisId;

  const isCustomerFromDifferentCountry =
    paisIdCliente && paisIdLoja ? paisIdCliente !== paisIdLoja : false;

  const handlePushNovoPedido = () => {
    history.push(ConstanteRotasPDV.PDV_HOME);
  };

  const handlePushConsultar = () => {
    history.push(ConstanteRotasPDV.PDV_CONSULTAR_OPERACOES);
  };

  const transmitirDocumentoFiscal = async (documentoFiscalId: string) => {
    const {
      success,
      emitiuAmbasNotas,
      rejeicao: newRejeicao,
    }: ModalTransmissaoNotaResponse = await ModalTransmissaoNota({
      documentoFiscalId,
      hubConnection,
      joinGroup,
      exitGroup,
    });

    if (success) {
      handleTabsChange(emitiuAmbasNotas || !deveMostrarTelaEmitirNFCe ? 0 : 1);
    } else {
      setRejeicao(newRejeicao);
    }

    await handleObterOperacao();
  };

  const handleSubmitAlterar: SubmitHandler<EmitirNFeData> = async ({
    cnpjIntermediador,
    identificacaoNoIntermediador,
    dataEmissao,
    modalidadeFrete,
    dadosAdicionais,
    numeroPedidoExterno,
    transportadora,
    placaTransportadora,
    localEmbarque,
    ufEmbarque,
    ufVeiculoTransportadora,
    pesoLiquido,
    pesoBruto,
    quantidadeVolumeTransportadora,
    especieVolumeTransportadora,
    marcaVolumeTransportadora,
    numeracaoVolumeTransportadora,
    operacaoComIntermediador,
    dataSaida,
  }) => {
    setIsDisabledSubmit(true);
    setIsLoading(true);

    if (operacao && operacao.NFe) {
      const dataPut = {
        id: operacao.NFe.id,
        status: StatusFiscaisEnum.EmDigitacao,
        dataEmissao: formatUTCToLocateDateTimeInput(dataEmissao),
        dataSaida: dataSaida ? formatUTCToLocateDateTimeInput(dataSaida) : null,
        modalidadeFrete,
        dadosAdicionais,
        numeroPedidoExterno,
        transportadoraId: transportadora?.value,
        placaTransportadora,
        localEmbarque,
        ufEmbarque,
        ufVeiculoTransportadora,
        pesoLiquido,
        pesoBruto,
        quantidadeVolumeTransportadora,
        especieVolumeTransportadora,
        marcaVolumeTransportadora,
        numeracaoVolumeTransportadora,
        operacaoComIntermediador,
        cnpjIntermediador:
          operacaoComIntermediador ===
          OperacaoIntermediadorEnum.SemIntermediador
            ? null
            : cnpjIntermediador,
        identificacaoNoIntermediador,
      };

      const response = await api.put<void, ResponseApi>(
        ConstanteEnderecoWebservice.NOTA_FISCAL_ALTERAR_PDV,
        dataPut
      );

      if (response.sucesso) {
        setDefaultModeloFiscal('NFe');
        await transmitirDocumentoFiscal(operacao.NFe.id);
      }

      if (response.avisos) {
        try {
          const newRejeicao = JSON.parse(
            response.avisos[0]
          ) as RejeicaoInterface;

          setRejeicao(newRejeicao);
        } catch {
          setRejeicao({ Mensagem: response.avisos[0] });
        }
      }
    }

    setIsLoading(false);
    setIsDisabledSubmit(false);
  };

  const handleSubmit: SubmitHandler<EmitirNFeData> = async ({
    cnpjIntermediador,
    identificacaoNoIntermediador,
    dataEmissao,
    modalidadeFrete,
    dadosAdicionais,
    numeroPedidoExterno,
    transportadora,
    placaTransportadora,
    localEmbarque,
    ufEmbarque,
    ufVeiculoTransportadora,
    pesoLiquido,
    pesoBruto,
    quantidadeVolumeTransportadora,
    especieVolumeTransportadora,
    marcaVolumeTransportadora,
    numeracaoVolumeTransportadora,
    operacaoComIntermediador,
    dataSaida,
  }) => {
    if (operacaoId) {
      setIsDisabledSubmit(true);
      setIsLoading(true);

      const documentoFiscalId = await gerarNotaFiscal(
        {
          operacaoId,
          modeloFiscal: ModelosFiscaisEnum.NFe,
          dataEmissao: formatUTCToLocateDateTimeInput(dataEmissao),
          modalidadeFrete,
          dadosAdicionais,
          numeroPedidoExterno,
          transportadoraId: transportadora?.value,
          placaTransportadora,
          localEmbarque,
          ufEmbarque,
          ufVeiculoTransportadora,
          pesoLiquido,
          pesoBruto,
          quantidadeVolumeTransportadora,
          especieVolumeTransportadora,
          marcaVolumeTransportadora,
          numeracaoVolumeTransportadora,
          operacaoComIntermediador,
          cnpjIntermediador:
            operacaoComIntermediador ===
            OperacaoIntermediadorEnum.SemIntermediador
              ? null
              : cnpjIntermediador,
          identificacaoNoIntermediador,
          dataSaida: dataSaida
            ? formatUTCToLocateDateTimeInput(dataSaida)
            : null,
        },
        (aviso) => {
          try {
            const newRejeicao = JSON.parse(aviso) as RejeicaoInterface;

            setRejeicao(newRejeicao);
          } catch {
            setRejeicao({ Mensagem: aviso });
          }
        }
      );

      if (documentoFiscalId) {
        setDefaultModeloFiscal('NFe');
        await transmitirDocumentoFiscal(documentoFiscalId);
      }

      setIsLoading(false);
      setIsDisabledSubmit(false);
    }
  };

  return (
    <>
      {isLoading && <LoadingPadrao />}

      <EmitirNFe
        ambienteFiscal={ambienteFiscal}
        isPdv
        pesoProdutos={{
          pesoLiquido: operacao?.pesoLiquido || 0,
          pesoBruto: operacao?.pesoBruto || 0,
        }}
        isCustomerFromDifferentCountry={isCustomerFromDifferentCountry}
        defaultValues={
          operacao && operacao.NFe
            ? {
                id: operacao.NFe.id,
                numeroPedidoExterno: operacao.NFe.numeroPedidoExterno,
                dataEmissao: getCurrentDateWithoutSeconds(),
                dataSaida: null,
                operacaoComIntermediador: operacao.NFe.operacaoComIntermediador,
                cnpjIntermediador: operacao.NFe.cnpjIntermediador
                  ? formattedValueCpfCnpj(
                      operacao.NFe.cnpjIntermediador,
                      false,
                      true
                    )
                  : undefined,
                identificacaoNoIntermediador:
                  operacao.NFe.identificacaoNoIntermediador,

                modalidadeFrete: operacao.NFe.modalidadeFrete,
                transportadora: operacao.NFe.transportadoraOpcaoSelect
                  ? {
                      value: operacao.NFe.transportadoraOpcaoSelect.id,
                      label: operacao.NFe.transportadoraOpcaoSelect.nome,
                    }
                  : undefined,
                placaTransportadora: operacao.NFe.placaTransportadora,
                localEmbarque: operacao.NFe.localEmbarque,
                ufEmbarque: operacao.NFe.ufEmbarque,
                ufVeiculoTransportadora: operacao.NFe.ufVeiculoTransportadora,
                pesoLiquido: operacao.NFe.pesoLiquido,
                pesoBruto: operacao.NFe.pesoBruto,
                quantidadeVolumeTransportadora:
                  operacao.NFe.quantidadeVolumeTransportadora,
                especieVolumeTransportadora:
                  operacao.NFe.especieVolumeTransportadora,
                marcaVolumeTransportadora:
                  operacao.NFe.marcaVolumeTransportadora,
                numeracaoVolumeTransportadora:
                  operacao.NFe.numeracaoVolumeTransportadora,

                dadosAdicionais: operacao.NFe.dadosAdicionais,
              }
            : undefined
        }
        casasDecimaisQuantidade={casasDecimais.casasDecimaisQuantidade}
        onSubmit={documentoFiscalExistente ? handleSubmitAlterar : handleSubmit}
        rejeicaoNotaFiscal={rejeicao}
        isLoading={isLoading}
        isDisabledSubmit={isDisabledSubmit}
        statusNfe={operacao?.NFe?.status}
        setRejeicaoNotaFiscal={setRejeicao}
      >
        <Button
          size="sm"
          w="full"
          minW="96px"
          maxW={{ base: 'full', sm: '120px' }}
          variant="outline"
          onClick={handlePushConsultar}
        >
          Consultar
        </Button>
        <Button
          size="sm"
          w="full"
          minW="96px"
          maxW={{ base: 'full', sm: '120px' }}
          variant="outline"
          onClick={handlePushNovoPedido}
        >
          Novo pedido
        </Button>
      </EmitirNFe>
    </>
  );
};

export default NFe;
