import {
  Box,
  Button,
  Grid,
  HStack,
  Text,
  VStack,
  Stack,
  Icon,
} from '@chakra-ui/react';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import api, { ResponseApi } from 'services/api';
import CountUp from 'react-countup';
import { moneyMask } from 'helpers/format/fieldsMasks';
import {
  DashboardFaturamento,
  DashboardProdutosAtendimento,
  DashboardProdutosVendidos,
  DashboardTicketMedio,
  DashboardTotalVendas,
} from 'icons';
import auth from 'modules/auth';
import ConstanteFuncionalidades from 'constants/permissoes';
import ExpiredStorage from 'expired-storage';
import Loading from 'components/Layout/Loading/LoadingPadrao';
import { useSignalRContext } from 'store/SignalR';
import MesAtual, {
  DashboardInicialDetalhesPorDiaInterface,
  DashboardInicialTotalizadoresMesAtualInterface,
} from './MesAtual';
import UltimosDozeMeses, {
  DashboardInicialDetalhesPorMesInterface,
  DashboardInicialTotalizadoresPorMesInterface,
} from './UltimosDozeMeses';

interface CardTotalizadorInterface {
  titulo: string;
  valor?: number;
  descricaoValor?: string;
  formatarValor: boolean;
  asDecimal: boolean;
  bg: string;
  icon: any;
}

const Dashboard = () => {
  const {
    setHubConnectionLogoff,
    setHubConnectionLogoffSair,
  } = useSignalRContext();

  const expiredStorage = useMemo(() => new ExpiredStorage(), []);

  const localStorageUltimosDozeMeses = `dashboard-ultimos-doze-meses${
    auth.getLoja().id
  }`;
  const localStorageMesAtual = `dashboard-mes-atual${auth.getLoja().id}`;
  const localStorageUltimaSincronizacao = `dashboard-ultima-sincronizacao${
    auth.getLoja().id
  }`;

  const [dataUltimaSincronizacao, setDataUltimaSincronizacao] = useState(
    expiredStorage.getItem(localStorageUltimaSincronizacao)
  );

  const [showMesAtual, setshowMesAtual] = useState(true);
  const [loading, setLoading] = useState(false);

  const [totalVendas, setTotalVendas] = useState(0);
  const [ticketMedio, setTicketMedio] = useState(0);
  const [produtosVendidos, setProdutosVendidos] = useState(0);
  const [produtosPorAtendimento, setProdutosPorAtendimento] = useState(0);
  const [faturamento, setFaturamento] = useState(0);

  const [dadosGraficoMesAtual, setDadosGraficoMesAtual] = useState<
    DashboardInicialDetalhesPorDiaInterface[] | undefined
  >();

  const [
    dadosGraficoUltimosDozeMeses,
    setDadosGraficoUltimosDozeMeses,
  ] = useState<DashboardInicialDetalhesPorMesInterface[] | undefined>();

  const CardTotalizador = ({
    titulo,
    valor,
    formatarValor,
    asDecimal,
    descricaoValor,
    bg,
    icon,
  }: CardTotalizadorInterface) => (
    <Box
      borderRadius="md"
      width="full"
      minHeight="80px"
      boxShadow="md"
      bg={bg}
      color="white"
    >
      <Grid
        templateColumns={{ base: '1fr 3fr', xl: '1fr 2fr' }}
        templateRows="1fr"
        height="full"
      >
        <Stack alignItems="center" justifyContent="center">
          <Icon
            as={icon}
            h={{ base: '32px', md: '22px', xl: '32px' }}
            w="full"
          />
        </Stack>
        <VStack justifyContent="center" alignItems="flex-start" spacing="0">
          <Text>{titulo}</Text>
          <CountUp
            formattingFn={(v) =>
              formatarValor ? moneyMask(v, false) : v.toLocaleString('pt-BR')
            }
            start={0}
            end={valor || 0}
            delay={0}
            duration={1}
            decimals={asDecimal ? 2 : undefined}
          >
            {({ countUpRef }) => (
              <HStack alignItems="baseline" spacing="1">
                {formatarValor && <Text fontSize="x-small">R$</Text>}
                <Text fontSize="lg" ref={countUpRef} />
                {descricaoValor && <Text fontSize="lg">{descricaoValor}</Text>}
              </HStack>
            )}
          </CountUp>
        </VStack>
      </Grid>
    </Box>
  );

  const setDadosCards = (dados: any) => {
    setTotalVendas(dados.totalVendas);
    setTicketMedio(dados.ticketMedio);
    setProdutosVendidos(dados.produtosVendidos);
    setProdutosPorAtendimento(dados.produtosPorAtendimento);
    setFaturamento(dados.faturamento);
  };

  const atualizarDataSincronizacao = () => {
    const dateNow = new Date();
    const ultimaSincronizacao = `${dateNow
      .toLocaleDateString()
      .substring(0, 5)} às ${dateNow.toLocaleTimeString().substring(0, 5)}h`;

    expiredStorage.setItem(
      localStorageUltimaSincronizacao,
      ultimaSincronizacao,
      18000
    );

    setDataUltimaSincronizacao(ultimaSincronizacao);
  };

  const validarUsuarioLogado = useCallback(async () => {
    await api.get<void, ResponseApi>(
      ConstanteEnderecoWebservice.AUTENTICACAO_VALIDAR_USUARIO_LOGADO
    );
  }, []);

  const carregarDadosMesAtual = useCallback(async () => {
    expiredStorage.clearExpired();

    const dadosStorage = expiredStorage.getJson(localStorageMesAtual);

    if (!dadosStorage) {
      setLoading(true);

      const response = await api.get<
        void,
        ResponseApi<DashboardInicialTotalizadoresMesAtualInterface>
      >(ConstanteEnderecoWebservice.DASHBOARD_MES_ATUAL);

      setLoading(false);

      if (response?.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }

      if (response?.sucesso) {
        const { dados } = response;

        setDadosGraficoMesAtual(dados.dias);
        setDadosCards(dados);

        expiredStorage.setJson(localStorageMesAtual, dados, 1800);
        atualizarDataSincronizacao();
      }
    } else {
      setDadosGraficoMesAtual(dadosStorage.dias);
      setDadosCards(dadosStorage);
    }
  }, [expiredStorage]);

  const carregarUltimosDozeMeses = useCallback(async () => {
    expiredStorage.clearExpired();

    const dadosStorage = expiredStorage.getJson(localStorageUltimosDozeMeses);

    if (!dadosStorage) {
      setLoading(true);

      const response = await api.get<
        void,
        ResponseApi<DashboardInicialTotalizadoresPorMesInterface>
      >(ConstanteEnderecoWebservice.DASHBOARD_OBTER_ULTIMOS_DOZE_MESES);

      setLoading(false);

      if (response?.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }

      if (response?.sucesso) {
        const { dados } = response;

        setDadosGraficoUltimosDozeMeses(dados.meses);
        setDadosCards(dados);

        expiredStorage.setJson(localStorageUltimosDozeMeses, dados, 1800);
        atualizarDataSincronizacao();
      }
    } else {
      setDadosGraficoUltimosDozeMeses(dadosStorage.meses);
      setDadosCards(dadosStorage);
    }
  }, [expiredStorage]);

  useEffect(() => {
    validarUsuarioLogado();
    carregarDadosMesAtual();
  }, [carregarDadosMesAtual, validarUsuarioLogado]);

  const possuiPermissao = useMemo(
    () =>
      auth.possuiPermissao(ConstanteFuncionalidades.DASHBOARD_VISUALIZAR)
        .permitido,
    []
  );

  const sincronizar = () => {
    expiredStorage.removeItem(localStorageUltimosDozeMeses);
    expiredStorage.removeItem(localStorageMesAtual);

    setDadosGraficoMesAtual(undefined);
    setDadosGraficoUltimosDozeMeses(undefined);

    if (showMesAtual) {
      carregarDadosMesAtual();
    } else {
      carregarUltimosDozeMeses();
    }
  };

  useEffect(() => {
    setHubConnectionLogoff();
    setHubConnectionLogoffSair();
  }, [setHubConnectionLogoff, setHubConnectionLogoffSair]);

  return possuiPermissao ? (
    <>
      {loading && <Loading />}
      <Box height="full">
        <Grid height="full" templateRows="1fr auto 8fr">
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems={{ base: 'unset', md: 'center' }}
            mb="24px"
          >
            <Text fontSize="sm" pr="2" color="gray.700">
              Período
            </Text>
            <HStack
              justifyContent="space-between"
              borderRadius="full"
              backgroundColor="white"
              height="32px"
              width={{ base: 'full', md: '312px' }}
            >
              <Button
                onClick={() => {
                  setshowMesAtual(true);
                  carregarDadosMesAtual();
                }}
                backgroundColor={showMesAtual ? 'primary.400' : 'white'}
                color={showMesAtual ? 'white' : 'primary.400'}
                width={{ base: '139px', md: '152px' }}
                _hover={{}}
                borderWidth="0"
                height="24px"
                fontWeight="normal"
                fontSize="sm"
              >
                Mês atual
              </Button>
              <Button
                onClick={() => {
                  setshowMesAtual(false);
                  carregarUltimosDozeMeses();
                }}
                backgroundColor={!showMesAtual ? 'primary.400' : 'white'}
                color={!showMesAtual ? 'white' : 'primary.400'}
                minWidth="139px"
                _hover={{}}
                borderWidth="0"
                height="24px"
                fontWeight="normal"
                fontSize="sm"
              >
                Últimos 12 meses
              </Button>
            </HStack>
            <HStack
              justifyContent={{ base: 'flex-end', md: 'flex-start' }}
              pl="2"
            >
              <Text fontSize="xs" color="gray.700">
                {`Dados de ${dataUltimaSincronizacao}.`}
              </Text>
              <Button
                variant="link"
                cursor="pointer"
                fontSize="sm"
                color="gray.700"
                onClick={sincronizar}
              >
                Atualize agora
              </Button>
            </HStack>
          </Stack>
          <Grid
            gap={{ base: 2, md: 6 }}
            templateColumns={{ base: 'repeat(1, auto)', md: 'repeat(5, 1fr)' }}
            w="full"
          >
            <CardTotalizador
              titulo="Faturamento"
              valor={faturamento}
              formatarValor
              asDecimal
              bg="#77BC1F"
              icon={DashboardFaturamento}
            />
            <CardTotalizador
              titulo="Total de vendas"
              valor={totalVendas}
              formatarValor={false}
              asDecimal={false}
              bg="orange.500"
              icon={DashboardTotalVendas}
            />
            <CardTotalizador
              titulo="Ticket médio"
              valor={ticketMedio}
              formatarValor
              asDecimal
              bg="purple.500"
              icon={DashboardTicketMedio}
            />
            <CardTotalizador
              titulo="Produtos vendidos"
              valor={produtosVendidos}
              formatarValor={false}
              asDecimal={false}
              bg="teal.600"
              icon={DashboardProdutosVendidos}
            />
            <CardTotalizador
              titulo="Por atendimento"
              valor={produtosPorAtendimento}
              formatarValor={false}
              asDecimal
              bg="pink.500"
              descricaoValor={
                produtosPorAtendimento === 1 ? 'produto' : 'produtos'
              }
              icon={DashboardProdutosAtendimento}
            />
          </Grid>

          {showMesAtual ? (
            <MesAtual dias={dadosGraficoMesAtual} />
          ) : (
            <UltimosDozeMeses
              meses={dadosGraficoUltimosDozeMeses}
              loading={loading}
            />
          )}
        </Grid>
      </Box>
    </>
  ) : (
    <></>
  );
};

export default Dashboard;
