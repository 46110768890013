export const optionsVersaoSistema = [
  {
    label: '0.07',
    value: '0.07',
  },
  {
    label: '0.08',
    value: '0.08',
  },
];

type ListaFormaPagamentoRecebimento = {
  id: string;
  tef: boolean;
  credenciadoraCartaoId: string;
  operacaoTefId: string;
  parcelamentoTefId: string;
  tipoCartaoTefId: string;
}[];

export type FormData = {
  cabecalhoCupom: string;
  urlScript?: string;
  rodapeCupom: string;
  qtdDigCodProdBalanca: number;
  impressaoPorItensPDV: boolean;
  nFeFormaEmissao: number;
  diasBloqVenda: number;
  mostrarLimitesRelVendas: boolean;
  usaSaldoDiaSeguinte: boolean;
  bloquearCliAtraso: boolean;
  intervalo: number;
  vendaSolicitarCpf: boolean;
  rodapeCupomParceiro: string;
  limiteQuantidadeVenda: number;
  aceitarCodigoInternoPDV: boolean;
  imprimirDadosCompletosCliente: boolean;
  imprimirViaCupomNaoFiscal: boolean;
  agruparItensImpressaoVenda: boolean;
  vendaObrigatorioCPF: boolean;
  exibirDescansoDeTelaNoPDV: boolean;
  solicitarImpressaoEcologica: boolean;
  sangriaAposDinheiroPDV: number;
  decimaisDireitaPDV: boolean;
  pdvManterFocoNaTela: boolean;
  impressaoDescontoVenda: boolean;
  timerAposVendaPDV: number;
  idProdutoGenerico: number;
  considerarCodigoBarraBalanca: boolean;
  nfePLNotaFiscal: string;
  nfePLEPEC: string;
  nfePLEvento: string;
  versaoDadosSAT: string;
  listaFormaPagamentoRecebimento: ListaFormaPagamentoRecebimento;
};
