import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import { isValidCnpj } from 'helpers/validation/IsValidCpfCnpj';

export type FormData = {
  nome: string;
  cnpj: string;
  codigoSat: string;
};

const defaultValues = {
  nome: '',
  cnpj: '',
  codigoSat: '',
  ativo: true,
} as FormData;

const schema = yup.object().shape({
  ativo: yup.boolean(),
  nome: yup.string().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  cnpj: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .test('cnpj', ConstanteMensagemValidacao.CNPJ_INVALIDO, (value) => {
      if (value) {
        return isValidCnpj(value);
      }

      return false;
    }),
  codigoSat: yup.string().nullable(),
});

const yupResolver = yupResolverInstance(schema);

export { yupResolver, defaultValues };
