import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  ReactNode,
  memo,
  useCallback,
} from 'react';
import {
  List,
  AutoSizer,
  InfiniteLoader,
  ListRowRenderer,
  Index,
  ListRowProps,
} from 'react-virtualized';
import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Box,
  TableColumnHeaderProps,
  useMediaQuery,
} from '@chakra-ui/react';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

export type ForwardRefData = {
  refresh: () => Promise<void>;
  resetPage: () => void;
};

export interface TableColumn
  extends Omit<TableColumnHeaderProps, 'children' | 'minW' | 'maxW' | 'w'> {
  key: string;
  content: ReactNode;
}

export interface LoadMoreRowsParams {
  currentPage: number;
  pageSize: number;
  orderColumn: string;
  orderDirection: 'asc' | 'desc';
}

interface VirtualizedInfiniteListProps {
  columns: TableColumn[];
  backgroundColor?: string;
  isLoading?: boolean;
  isRowLoaded: (params: Index) => boolean;
  loadMoreRows: (params: LoadMoreRowsParams) => Promise<void>;
  rowRenderer: ListRowRenderer;
  totalRowCount: number;
  visibleRowCount?: number;
  rowHeight?: number;
  emptyRowsMessage?: string;
  pageSize?: number;
  orderColumn: string;
  orderDirection?: 'asc' | 'desc';
  tableVariant?: string;
  tableSize?: string;
  borderGradientColor?: string;
}

const VirtualizedInfiniteList = memo(
  forwardRef<ForwardRefData, VirtualizedInfiniteListProps>(
    (
      {
        columns,
        backgroundColor = 'white',
        isLoading = false,
        isRowLoaded,
        loadMoreRows,
        totalRowCount,
        rowRenderer,
        visibleRowCount = 5,
        rowHeight = 60,
        emptyRowsMessage = 'Nenhum resultado foi encontrado',
        pageSize = 25,
        orderColumn,
        orderDirection = 'asc',
        tableVariant = 'filled',
        tableSize,
        borderGradientColor = 'gray.100',
      },
      ref
    ) => {
      const isFetchingItems = useRef(false);
      const currentPage = useRef(1);

      const tableHeadElementWidth = document?.getElementsByTagName('thead')[0];
      const [isSmaller1250] = useMediaQuery('(min-width: 1250px)');

      const height = Math.min(totalRowCount, visibleRowCount) * rowHeight;

      const createRows = useCallback(
        (props: ListRowProps) => {
          return rowRenderer(props);
        },
        [rowRenderer]
      );

      const fetchInitialRows = useCallback(async () => {
        await loadMoreRows({
          currentPage: currentPage.current,
          pageSize,
          orderColumn,
          orderDirection,
        });
      }, [loadMoreRows, orderColumn, orderDirection, pageSize]);

      async function refresh() {
        currentPage.current = 1;
        await loadMoreRows({
          currentPage: currentPage.current,
          pageSize,
          orderColumn,
          orderDirection,
        });
      }

      const resetPage = () => {
        currentPage.current = 1;
      };

      const loadMoreRowsCallback = useCallback(async () => {
        const loadedRowCount = currentPage.current * pageSize;

        if (loadedRowCount >= totalRowCount) return;

        isFetchingItems.current = true;
        currentPage.current += 1;

        await loadMoreRows({
          currentPage: currentPage.current,
          pageSize,
          orderColumn,
          orderDirection,
        });

        isFetchingItems.current = false;
      }, [loadMoreRows, pageSize, orderColumn, orderDirection, totalRowCount]);

      useEffect(() => {
        fetchInitialRows();
      }, [fetchInitialRows]);

      useImperativeHandle(ref, () => ({
        refresh,
        resetPage,
      }));

      return (
        <Box
          position="relative"
          height="full"
          bg={backgroundColor}
          boxShadow="md"
          borderRadius="md"
          sx={{
            '& tr > td': { height: `${rowHeight}px`, whiteSpace: 'normal' },
          }}
        >
          {isLoading && <LoadingPadrao />}
          <Table
            variant={tableVariant}
            size={tableSize}
            width="calc(100% - 21px)"
          >
            <Thead>
              <Tr>
                {columns.map(({ content, key, ...rest }) => (
                  <Th key={key} {...rest}>
                    {content}
                  </Th>
                ))}
              </Tr>
            </Thead>

            {!isLoading && totalRowCount === 0 && (
              <Tbody>
                <Tr>
                  <Td height={`${rowHeight}px`} colSpan={columns.length}>
                    {emptyRowsMessage}
                  </Td>
                </Tr>
              </Tbody>
            )}
          </Table>
          <Box
            height={`${height}px`}
            bg={backgroundColor}
            width={
              isSmaller1250
                ? 'calc(100% - 21px)'
                : tableHeadElementWidth?.clientWidth
            }
            minH="1px"
            position="relative"
          >
            {!isLoading &&
              totalRowCount !== 0 &&
              totalRowCount > visibleRowCount && (
                <Box
                  position="absolute"
                  bottom={0}
                  left={0}
                  h="25px"
                  w="full"
                  bgGradient={`linear(to-t, ${borderGradientColor} 0%, rgba(0, 0, 0, 0) 100%)`}
                  borderBottomRadius="md"
                  pointerEvents="none"
                  zIndex="docked"
                  transition="opacity 0.4s"
                />
              )}
            <InfiniteLoader
              isRowLoaded={isRowLoaded}
              threshold={Math.floor(pageSize / 2)}
              loadMoreRows={loadMoreRowsCallback}
              rowCount={totalRowCount}
            >
              {({ onRowsRendered, registerChild }) => (
                <AutoSizer>
                  {({ width, height: autoSizerHeight }) => (
                    <List
                      ref={registerChild}
                      width={width}
                      height={autoSizerHeight}
                      rowCount={totalRowCount}
                      rowHeight={rowHeight}
                      rowRenderer={(props) => {
                        const rows = createRows(props);
                        return (
                          <Table
                            w="full"
                            variant={tableVariant}
                            size={tableSize}
                            key={props.key}
                          >
                            <Tbody>{rows}</Tbody>
                          </Table>
                        );
                      }}
                      onRowsRendered={onRowsRendered}
                    />
                  )}
                </AutoSizer>
              )}
            </InfiniteLoader>
          </Box>
        </Box>
      );
    }
  )
);

export default VirtualizedInfiniteList;
