import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export type FormData = {
  siteStatus: number;
  campoConfirmar: string;
};

export const formDefaultValues = {
  siteStatus: 1,
};

const schema = yup.object().shape({
  siteStatus: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);
