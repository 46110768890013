import React, { useEffect, useRef, useState } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Icon,
  Flex,
  useMediaQuery,
  useDisclosure,
  ModalHeader,
  Text,
  ModalFooter,
  HStack,
  Button,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Divider,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { FiArrowRight, FiCheck } from 'react-icons/fi';
import { IoMdAlert } from 'react-icons/io';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { DecimalMask } from 'helpers/format/fieldsMasks';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { NumberInput } from 'components/update/Input/NumberInput';
import { SimpleCard } from 'components/update/Form/SimpleCard';
import InputInteger from 'components/PDV/InputInteger';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';

import {
  FormularioVincularVariacoes,
  ProdutoResponse,
} from './FormularioVincularVariacoes';
import { yupResolver, FormData, ItemVinculacao } from './validationForm';

type ItemIdDescricao = {
  id: string;
  descricao: string;
  referencia: string;
};

type ItemObter = {
  produto: ItemIdDescricao;
  cor: ItemIdDescricao;
  tamanho: ItemIdDescricao;
  quantidade: number;
  valorUnitario: number;
  custoAdicional: number;
};

type ItemVinculadoObter = {
  quantidadeEntrada: number;
  valorUnitarioEntrada: number;
  cfopEntrada: string;
  itens: ItemObter[];
};

type ItemVinculacaoExibir = {
  produtoId: string;
  nomeProduto: string;
  referencia?: string;
  corId?: string;
  corDescricao?: string;
  tamanhoId: string;
  tamanhoDescricao: string;
  quantidade: number;

  indexProduto: number;
  indexTamanho: number;
};

type ModalVincularProdutosResponse = {
  success: boolean;
  quantidade: number;
  valorUnitario: number;
  cfop: string;
};

type ModalVincularProdutoProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalVincularProdutosResponse> & {
    casasDecimaisQuantidade: number;
    casasDecimaisValor: number;
    totalProdutos: number;
    produto: {
      documentoFiscalItemId: string;
      descricaoProduto: string;
      quantidade: number;
      valorUnitario: number;
      valorTotal: number;
      cfop: string;
      codigoCest: string;
      codigoGTINEAN: string;
      ncm: string;
    };
    entradaMercadoriaId: string;
    isEdicao?: boolean;
  };

export const ModalVincularProduto = create<ModalVincularProdutoProps>(
  ({
    onResolve,
    onReject,
    casasDecimaisQuantidade,
    casasDecimaisValor,
    totalProdutos,
    produto,
    entradaMercadoriaId,
    isEdicao = false,
    ...rest
  }) => {
    const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
    const formMethods = useForm<FormData>({
      resolver: yupResolver,
      defaultValues: {
        quantidade: produto.quantidade,
        valorUnitario: produto.valorUnitario,
        cfop: produto.cfop,
      },
    });

    const isFirstRender = useRef<boolean>(true);

    const quantidade = formMethods.watch('quantidade') || 0;
    const valorUnitario = formMethods.watch('valorUnitario') || 0;
    const itensVinculacao: Array<ItemVinculacao> =
      formMethods.watch('itensVinculacao') || [];

    const itensVinculacaoExibir = itensVinculacao.reduce(
      (acc, curr, indexProduto) => {
        curr.listaTamanhoIdQuantidade.forEach((item, indexTamanho) => {
          acc.push({
            nomeProduto: curr.nomeProduto,
            referencia: curr.referencia,
            produtoId: curr.produtoId,
            tamanhoDescricao: item.descricaoTamanho,
            tamanhoId: item.id,
            quantidade: item.quantidade,
            corDescricao: curr.corDescricao,
            corId: curr.corId,
            indexProduto,
            indexTamanho,
          });
        });

        return acc;
      },
      [] as ItemVinculacaoExibir[]
    );

    const quantidadeTotal = itensVinculacaoExibir.reduce((acc, curr) => {
      return acc + curr.quantidade;
    }, 0);

    const isQuantidadeValida = quantidadeTotal === quantidade;

    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = formMethods.handleSubmit(async (data) => {
      setIsLoading(true);

      const response = await api.post<void, ResponseApi<string>>(
        ConstanteEnderecoWebservice.ENTRADA_MERCADORIA_VINCULAR_ITEM,
        {
          entradaMercadoriaId,
          documentoFiscalItemId: produto.documentoFiscalItemId,
          quantidadeEntrada: data.quantidade,
          ValorUnitarioEntrada: data.valorUnitario,
          CfopEntrada: data.cfop,
          listaItensParaVinculacao: data.itensVinculacao.map((item) => {
            const hasOnlyOneVariation =
              item.listaTamanhoIdQuantidade.length === 1 &&
              data.itensVinculacao.length === 1;

            const gtinEanValue = produto?.codigoGTINEAN;

            return {
              produtoId: item.produtoId,
              corId: item.corId,
              custoAdicional: item.custoAdicional,
              listaTamanhoIdQuantidade: item.listaTamanhoIdQuantidade,
              codigoGTINEAN:
                gtinEanValue && hasOnlyOneVariation ? gtinEanValue : undefined,
              valorUnitario: Number(
                (produto.valorTotal + item.custoAdicional) / data.quantidade
              ),
            };
          }),
        }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.map((aviso: string) => toast.warning(aviso));
        }

        onResolve({
          success: response.sucesso,
          quantidade: data.quantidade,
          valorUnitario: data.valorUnitario,
          cfop: data.cfop,
        });
      }
    });

    function handleVoltar() {
      onReject();
    }

    function onSubmitFormularioVincularVariacoes(
      novoItemVinculacao: ProdutoResponse
    ) {
      formMethods.setValue('itensVinculacao', [
        ...itensVinculacao,
        novoItemVinculacao,
      ]);
    }

    function handleRemoverItem(indexProduto: number, indexTamanho: number) {
      const novosItensVinculacao = [...itensVinculacao];
      const novoItemVinculacao = novosItensVinculacao[indexProduto];

      novoItemVinculacao.listaTamanhoIdQuantidade.splice(indexTamanho, 1);

      if (novoItemVinculacao.listaTamanhoIdQuantidade.length === 0) {
        novosItensVinculacao.splice(indexProduto, 1);
      }

      formMethods.setValue('itensVinculacao', novosItensVinculacao);
    }

    const latestProps = useRef({
      setValue: formMethods.setValue,
      valorTotal: produto.valorTotal,
      reset: formMethods.reset,
      documentoFiscalItemId: produto.documentoFiscalItemId,
      onReject,
      casasDecimais: casasDecimaisQuantidade,
    });
    useEffect(() => {
      latestProps.current = {
        setValue: formMethods.setValue,
        valorTotal: produto.valorTotal,
        reset: formMethods.reset,
        documentoFiscalItemId: produto.documentoFiscalItemId,
        casasDecimais: casasDecimaisQuantidade,
        onReject,
      };
    });

    useEffect(() => {
      if (!isFirstRender.current) {
        latestProps.current.setValue(
          'valorUnitario',
          Number(
            (latestProps.current.valorTotal / (quantidade || 1)).toFixed(6)
          )
        );
      }
      if (isFirstRender.current) isFirstRender.current = false;
    }, [quantidade]);

    useEffect(() => {
      async function obterItemVinculado() {
        if (latestProps.current.documentoFiscalItemId) {
          setIsLoading(true);

          const response = await api.get<void, ResponseApi<ItemVinculadoObter>>(
            ConstanteEnderecoWebservice.ENTRADA_MERCADORIA_OBTER_ITENS_VINCULADOS,
            {
              params: {
                documentoFiscalItemId:
                  latestProps.current.documentoFiscalItemId,
              },
            }
          );

          if (response) {
            if (response.avisos) {
              response.avisos.map((aviso: string) => toast.warning(aviso));
            }

            if (response.sucesso && response.dados) {
              const novosItensVinculacao = response.dados.itens.reduce(
                (acc, curr) => {
                  const indexProdutoCorExists = acc.findIndex(
                    (item) =>
                      item.produtoId === curr.produto.id &&
                      item.corId === curr.cor.id
                  );

                  if (indexProdutoCorExists === -1) {
                    acc.push({
                      custoAdicional: curr.custoAdicional,
                      produtoId: curr.produto.id,
                      nomeProduto: curr.produto.descricao,
                      referencia: curr.produto.referencia,
                      corId: curr.cor.id,
                      corDescricao: curr.cor.descricao,
                      listaTamanhoIdQuantidade: [
                        {
                          id: curr.tamanho.id,
                          descricaoTamanho: curr.tamanho.descricao,
                          quantidade: curr.quantidade,
                        },
                      ],
                    });
                  } else {
                    const item = acc[indexProdutoCorExists];

                    acc.splice(indexProdutoCorExists, 1, {
                      ...item,
                      listaTamanhoIdQuantidade: [
                        ...item.listaTamanhoIdQuantidade,
                        {
                          id: curr.tamanho.id,
                          descricaoTamanho: curr.tamanho.descricao,
                          quantidade: curr.quantidade,
                        },
                      ],
                    });
                  }

                  return acc;
                },
                [] as Array<ItemVinculacao>
              );

              const newFormValues: FormData = {
                quantidade: response.dados.quantidadeEntrada,
                valorUnitario: response.dados.valorUnitarioEntrada,
                cfop: response.dados.cfopEntrada,
                itensVinculacao: novosItensVinculacao,
              };

              latestProps.current.reset(newFormValues);

              setIsLoading(false);
              return;
            }
          }

          latestProps.current.onReject();
        }
      }

      if (isEdicao) {
        obterItemVinculado();
      }
    }, [isEdicao]);

    return (
      <ModalPadraoChakra
        isCentered
        size={!isSmallerThan900 ? 'xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
      >
        <ModalContent
          marginBottom={{ base: 0, md: '3.75rem' }}
          marginTop={{ base: 0, md: '3.75rem' }}
          h="unset"
          maxW={{ base: '100%', md: '1200px' }}
        >
          {isLoading && <LoadingPadrao />}
          <ModalHeader
            mt={isSmallerThan900 ? 12 : undefined}
            mb={isSmallerThan900 ? 8 : undefined}
            px="0"
            mx={{ base: 6, md: 8 }}
            color="primary.50"
            fontSize="md"
          >
            {isEdicao
              ? 'Alterando a vinculação do produto'
              : 'Vinculando o produto'}
          </ModalHeader>
          <ModalBody p={{ base: 6, md: 8 }} pt="0px !important">
            <FormProvider {...formMethods}>
              <Accordion allowToggle>
                <VStack spacing={{ base: 6, md: 8 }} alignItems="stretch">
                  <SimpleCard
                    boxShadow="none"
                    bg="primary.500"
                    sx={{
                      '& label.chakra-form__label, & .chakra-form__required-indicator': {
                        color: 'secondary.300',
                      },
                    }}
                  >
                    <HStack justifyContent="space-between" spacing="20">
                      <VStack alignItems="flex-start" lineHeight="none">
                        <Text color="white" fontSize="2xs">
                          Produto:
                        </Text>
                        <Text color="secondary.300" fontSize="sm">
                          {produto.descricaoProduto}
                        </Text>
                      </VStack>

                      <HStack spacing="20">
                        <VStack alignItems="flex-start" lineHeight="none">
                          <Text color="white" fontSize="2xs">
                            Quantidade:
                          </Text>
                          <Text color="secondary.300" fontSize="sm">
                            {DecimalMask(quantidade, 4, 4)}
                          </Text>
                        </VStack>

                        <VStack alignItems="flex-start" lineHeight="none">
                          <Text
                            color="white"
                            fontSize="2xs"
                            whiteSpace="nowrap"
                          >
                            Valor unitário:
                          </Text>
                          <Text color="secondary.300" fontSize="sm">
                            {valorUnitario.toLocaleString('pt-BR', {
                              minimumIntegerDigits: 1,
                              maximumFractionDigits: 6,
                              minimumFractionDigits: 2,
                            })}
                          </Text>
                        </VStack>

                        <VStack alignItems="flex-start" lineHeight="none">
                          <Text
                            color="white"
                            fontSize="2xs"
                            whiteSpace="nowrap"
                          >
                            Valor total:
                          </Text>
                          <Text color="secondary.300" fontSize="sm">
                            {DecimalMask(
                              produto.valorTotal,
                              casasDecimaisValor,
                              casasDecimaisValor
                            )}
                          </Text>
                        </VStack>
                      </HStack>
                    </HStack>
                  </SimpleCard>

                  <AccordionItem border="none">
                    <h2>
                      <AccordionButton px={{ base: 4, sm: 6, md: 8 }} h="10">
                        <Box
                          flex="1"
                          textAlign="left"
                          fontSize="sm"
                          fontWeight="bold"
                        >
                          Converter unidade de medida e valor unitário
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      p={{ base: 4, sm: 6, md: 8 }}
                      pt="0 !important"
                      bg="gray.50"
                    >
                      <Box
                        border="1px"
                        borderColor="gray.100"
                        borderRadius="md"
                      >
                        <Table variant="filled" size="sm">
                          <Thead
                            sx={{ '& > tr > th': { py: 1, color: 'gray.500' } }}
                          >
                            <Tr>
                              <Th w="250px" />
                              <Th isNumeric w="1px" whiteSpace="nowrap">
                                Informações da Nota Fiscal
                              </Th>
                              <Th w="100px" />
                              <Th isNumeric w="250px" whiteSpace="nowrap">
                                Dados para vincular ao sistema
                              </Th>
                              <Th />
                            </Tr>
                          </Thead>
                          <Tbody>
                            <Tr>
                              <Td color="primary.50" fontSize="sm">
                                Quantidade
                              </Td>
                              <Td isNumeric fontSize="sm" fontWeight="bold">
                                {DecimalMask(produto.quantidade, 4, 4)}
                              </Td>
                              <Td>
                                <Flex
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <Icon as={FiArrowRight} />
                                </Flex>
                              </Td>
                              <Td isNumeric w="250px">
                                <NumberInput
                                  flex="1"
                                  id="quantidade"
                                  name="quantidade"
                                  w="full"
                                  placeholder={`0,${'0'.repeat(4)}`}
                                  scale={4}
                                  width="160px"
                                  isDisabled={itensVinculacao.length > 0}
                                />
                              </Td>
                              <Td />
                            </Tr>
                            <Tr>
                              <Td color="primary.50" fontSize="sm">
                                Valor unitário
                              </Td>
                              <Td isNumeric fontSize="sm" fontWeight="bold">
                                {`R$
                                  ${produto.valorUnitario.toLocaleString(
                                    'pt-BR',
                                    {
                                      minimumIntegerDigits: 1,
                                      maximumFractionDigits: 6,
                                      minimumFractionDigits: 2,
                                    }
                                  )}`}
                              </Td>
                              <Td>
                                <Flex
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <Icon as={FiArrowRight} />
                                </Flex>
                              </Td>
                              <Td isNumeric w="250px">
                                <NumberInput
                                  flex="1"
                                  id="valorUnitario"
                                  name="valorUnitario"
                                  leftElement="R$"
                                  placeholder={`0,${'0'.repeat(6)}`}
                                  scale={6}
                                  width="160px"
                                  isDisabled
                                />
                              </Td>
                              <Td />
                            </Tr>
                          </Tbody>
                        </Table>
                      </Box>
                    </AccordionPanel>
                  </AccordionItem>

                  <SimpleCard bg="gray.50">
                    <FormularioVincularVariacoes
                      casasDecimaisQuantidade={casasDecimaisQuantidade}
                      casasDecimaisValor={casasDecimaisValor}
                      onSubmit={onSubmitFormularioVincularVariacoes}
                      quantidadeProdutos={quantidade}
                      quantidadeItensEntradaMercadoria={quantidadeTotal}
                      product={produto}
                    />

                    {itensVinculacaoExibir.length > 0 && (
                      <>
                        <Divider orientation="horizontal" my="6" />

                        <Box
                          bg="white"
                          border="1px"
                          borderColor="gray.100"
                          borderRadius="md"
                          px={{ base: 4, sm: 6, md: 8 }}
                          pt="2"
                        >
                          <Table size="sm">
                            <Thead>
                              <Tr>
                                <Th>Produto</Th>
                                <Th>Referência</Th>
                                <Th w="100px">Cor</Th>
                                <Th w="100px">Tamanho</Th>
                                <Th w="100px" isNumeric>
                                  Quantidade
                                </Th>
                                <Th w="1px" />
                              </Tr>
                            </Thead>
                            <Tbody>
                              {itensVinculacaoExibir.map((item) => (
                                <Tr
                                  key={`${item.produtoId}-${item.corId}-${item.tamanhoId}`}
                                >
                                  <Td>{item.nomeProduto}</Td>
                                  <Td>{item.referencia || '---'}</Td>
                                  <Td>{item.corDescricao || '---'}</Td>
                                  <Td>{item.tamanhoDescricao || '---'}</Td>
                                  <Td isNumeric>
                                    {DecimalMask(item.quantidade, 4, 4)}
                                  </Td>
                                  <Td>
                                    <ActionsMenu
                                      items={[
                                        {
                                          content: 'Remover',
                                          onClick: () =>
                                            handleRemoverItem(
                                              item.indexProduto,
                                              item.indexTamanho
                                            ),
                                        },
                                      ]}
                                      menuZIndex="modal"
                                    />
                                  </Td>
                                </Tr>
                              ))}
                            </Tbody>
                            <Tfoot>
                              <Td />
                              <Td colSpan={3} isNumeric>
                                Quantidade total:
                              </Td>
                              <Td>
                                <Flex
                                  justifyContent="space-between"
                                  alignItems="center"
                                  bg={
                                    isQuantidadeValida
                                      ? 'secondary.300'
                                      : 'red.400'
                                  }
                                  color={
                                    isQuantidadeValida ? 'inherit' : 'white'
                                  }
                                  w="full"
                                  py="1"
                                  px="3"
                                  borderRadius="full"
                                >
                                  <Text fontWeight="bold">
                                    {DecimalMask(quantidadeTotal, 4, 4)}
                                  </Text>

                                  <Icon
                                    as={
                                      isQuantidadeValida ? FiCheck : IoMdAlert
                                    }
                                    boxSize="3"
                                    ml="3"
                                  />
                                </Flex>
                              </Td>
                              <Td />
                            </Tfoot>
                          </Table>
                        </Box>
                      </>
                    )}
                  </SimpleCard>

                  <AccordionItem border="none">
                    <h2>
                      <AccordionButton px={{ base: 4, sm: 6, md: 8 }} h="10">
                        <Box
                          flex="1"
                          textAlign="left"
                          fontSize="sm"
                          fontWeight="bold"
                        >
                          Converter o CFOP de entrada
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      p={{ base: 4, sm: 6, md: 8 }}
                      pt="0 !important"
                      bg="gray.50"
                    >
                      <Box
                        border="1px"
                        borderColor="gray.100"
                        borderRadius="md"
                      >
                        <Table variant="filled" size="sm">
                          <Thead
                            sx={{ '& > tr > th': { py: 1, color: 'gray.500' } }}
                          >
                            <Tr>
                              <Th w="250px" />
                              <Th isNumeric w="1px" whiteSpace="nowrap">
                                Informações da Nota Fiscal
                              </Th>
                              <Th w="100px" />
                              <Th isNumeric w="175px" whiteSpace="nowrap">
                                Novo CFOP para entrada no sistema
                              </Th>
                              <Th />
                            </Tr>
                          </Thead>
                          <Tbody>
                            <Tr>
                              <Td color="primary.50" fontSize="sm">
                                CFOP do produto
                              </Td>
                              <Td isNumeric fontSize="sm" fontWeight="bold">
                                {produto.cfop}
                              </Td>
                              <Td>
                                <Flex
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <Icon as={FiArrowRight} />
                                </Flex>
                              </Td>
                              <Td isNumeric>
                                <InputInteger
                                  id="cfop"
                                  name="cfop"
                                  placeholder="00000"
                                  width="160px"
                                  maxLength={5}
                                />
                              </Td>
                              <Td />
                            </Tr>
                          </Tbody>
                        </Table>
                      </Box>
                    </AccordionPanel>
                  </AccordionItem>
                </VStack>
              </Accordion>
            </FormProvider>
          </ModalBody>
          <ModalFooter
            justifyContent="flex-end"
            borderTop="1px"
            borderColor="gray.100"
            mx={{ base: 6, md: 8 }}
            px="0"
          >
            <HStack spacing={6}>
              <Button
                borderRadius="md"
                color="gray.400"
                variant="outline"
                minW="96px"
                onClick={handleVoltar}
              >
                Voltar
              </Button>
              <Button
                colorScheme="aquamarine"
                borderRadius="md"
                minW="120px"
                onClick={handleSubmit}
                isDisabled={!isQuantidadeValida && quantidade > 0}
              >
                Confirmar
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
