import {
  Table,
  Th,
  Tr,
  Td,
  Thead,
  Tbody,
  Text,
  Box,
  TableContainer,
} from '@chakra-ui/react';

import { DecimalMask } from 'helpers/format/fieldsMasks';

import { MovimentacaoFinanceiraBaixa } from '../types';

interface TableProps {
  movimentacaoFinanceiraBaixa: MovimentacaoFinanceiraBaixa[];
}

const TableResumo = ({ movimentacaoFinanceiraBaixa }: TableProps) => {
  return (
    <Box alignContent="center">
      <TableContainer
        sx={{
          '& div, & table, & tr, & p': {
            color: 'black',
            fontWeight: '900',
            fontSize: '10px',
            fontFamily: 'arial',
          },
          '& div, & hr, & table, & tr, & p': {
            opacity: 'unset',
            fontSize: '10px',
            borderColor: 'black',
          },
        }}
        margin="0 auto"
        mt={1}
      >
        <Table>
          <Thead>
            <Tr
              sx={{
                '& > th': {
                  color: 'gray.700',
                  fontSize: '2xs',
                  textAlign: 'center',
                  padding: '2px 0 2px 2px',
                },
              }}
              borderBottom="1px solid"
              borderColor="gray.700"
            >
              <Th w="40%">
                <Text textAlign="start">Receb.</Text>
              </Th>
              <Th w="30%">
                <Text>Forma</Text>
              </Th>
              <Th>
                <Text textAlign="end">Valor</Text>
              </Th>
            </Tr>
          </Thead>
          <Tbody borderBottom="1px solid" borderColor="gray.700">
            {(movimentacaoFinanceiraBaixa || [])?.map((element) => (
              <>
                <Tr
                  sx={{
                    '& > td': {
                      fontSize: '2xs',
                      textAlign: 'center',
                      padding: '0 0 2px 2px',
                    },
                    '& > td: last-child': {
                      textAlign: 'center',
                    },
                  }}
                >
                  <Td>
                    <Text textAlign="start">
                      {new Date(element?.dataPagamento).toLocaleDateString(
                        'pt-BR',
                        {
                          day: '2-digit',
                          month: '2-digit',
                          year: '2-digit',
                        }
                      )}
                    </Text>
                  </Td>
                  <Td>
                    <Text>{element?.formaPagamento}</Text>
                  </Td>
                  <Td>
                    <Text textAlign="end">
                      {DecimalMask(element.totalRecebido, 2)}
                    </Text>
                  </Td>
                </Tr>
              </>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TableResumo;
