import React from 'react';
import { Accordion } from 'react-bootstrap';

import ConditionalWrapper from 'components/Geral/ConditionalWrapper';

import { Card, CardHeader, Title, ToggleIcon } from './styles';

export interface ToggleRefType {
  handleOpenAccordion: () => void;
  handleCloseAccordion: () => void;
}

interface AccordionPadraoProps {
  title: string;
  children: string | React.ReactNode;
  defaultOpen?: boolean;
  alreadyHasParentAccordion?: boolean;
  toggleRef?: React.MutableRefObject<ToggleRefType>;
  id?: string;
}

const AccordionPadrao = ({
  title,
  children,
  defaultOpen,
  alreadyHasParentAccordion,
  toggleRef,
  id,
}: AccordionPadraoProps) => {
  return (
    <ConditionalWrapper
      condition={!alreadyHasParentAccordion}
      wrapper={(wrappedChildren: React.ReactNode) => (
        <Accordion
          defaultActiveKey={
            defaultOpen ? `accordion-${title.toLowerCase()}` : ''
          }
        >
          {wrappedChildren}
        </Accordion>
      )}
    >
      <Card id={id}>
        <Accordion.Toggle
          id={`accordion-toggle-${title.replace(' ', '')}`}
          ref={(e: HTMLDivElement) => {
            if (toggleRef && e) {
              const refValue = {
                handleOpenAccordion: () => {
                  const nextSibling = e.nextElementSibling;

                  if (nextSibling) {
                    if (!nextSibling.className.includes('show')) e.click();
                  }
                },
                handleCloseAccordion: () => {
                  const nextSibling = e.nextElementSibling;

                  if (nextSibling) {
                    if (nextSibling.className.includes('show')) e.click();
                  }
                },
              };

              // eslint-disable-next-line no-param-reassign
              toggleRef.current = refValue;
            }
          }}
          as={CardHeader}
          eventKey={`accordion-${title.toLowerCase()}`}
        >
          <Title>{title}</Title>
          <ToggleIcon>▼</ToggleIcon>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={`accordion-${title.toLowerCase()}`}>
          <Card.Body>{children}</Card.Body>
        </Accordion.Collapse>
      </Card>
    </ConditionalWrapper>
  );
};

export default AccordionPadrao;
