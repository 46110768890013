import { Text, VStack, Flex, Icon } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { CopiarIcon, WhatsAppZoopIcon, PagamentosBoletoIcon } from 'icons';
import { WhatsAppModal } from 'components/PDV/CompartilharDocumentosFiscais/WhatsAppModal';

import { CompartilharLinkProps } from './types';

export const CompartilharLink = ({
  linkTransacao,
  cliente,
}: CompartilharLinkProps) => {
  const handleCopyLink = () => {
    toast.success('Link copiado para a área de transferência');
    navigator.clipboard.writeText(linkTransacao);
  };

  const handleOpenLink = () => {
    window.open(linkTransacao, '_blank');
  };

  const handleEnviarWhatsApp = async () => {
    if (cliente) {
      WhatsAppModal({
        cliente,
        messageLinkZoop: `Olá ${cliente.nome}! Segue o link para pagamento: ${linkTransacao}`,
      });
    }
  };

  const handleVisualizarBoleto = () => {
    window.open(linkTransacao, '_blank');
  };

  return (
    <Flex
      w="full"
      alignItems="flex-start"
      justify="flex-start"
      flexDir="column"
      padding="32px"
      height="258px"
      bg="white"
      borderRadius="12px"
      gap="2px"
    >
      <Text fontSize="md" color="gray.700">
        Copie e cole o link onde quiser:
      </Text>
      <Flex w={['full', 'full', '700px']} gap="8px" align="center">
        <Text
          bg="gray.50"
          fontSize="md"
          fontWeight="semibold"
          borderWidth="1px"
          borderColor="gray.200"
          borderRadius="5px"
          borderStyle="solid"
          color="violet.500"
          wordBreak="break-all"
          noOfLines={1}
          w="full"
          px="12px"
          py="8px"
          h="38px"
          maxH="38px"
          cursor="pointer"
          onClick={handleOpenLink}
        >
          {linkTransacao}
        </Text>
        <Flex
          w="80px"
          h="38px"
          maxH="38px"
          borderRadius="5px"
          bg="primary.50"
          align="center"
          justify="center"
          _hover={{ bg: 'primary.100' }}
          marginRight="5px"
          cursor="pointer"
          onClick={handleCopyLink}
        >
          <Icon as={CopiarIcon} width="32px" height="24px" color="white" />
        </Flex>
      </Flex>
      <Flex
        fontSize="sm"
        color="gray.700"
        mt="12px"
        w="full"
        gap="48px"
        justify="flex-start"
      >
        <VStack
          cursor="pointer"
          padding="8px"
          borderRadius="5px"
          onClick={handleEnviarWhatsApp}
        >
          <Icon as={WhatsAppZoopIcon} boxSize="56px" />
          <Text>WhatsApp</Text>
        </VStack>
        <VStack
          cursor="pointer"
          padding="8px"
          borderRadius="5px"
          onClick={handleVisualizarBoleto}
        >
          <Icon as={PagamentosBoletoIcon} boxSize="56px" />
          <Text>Visualizar</Text>
        </VStack>
      </Flex>
    </Flex>
  );
};
