import { Flex, Checkbox, VStack, Text, Box } from '@chakra-ui/react';

import { Switch } from 'components/update/Switch';

interface CheckBoxLojaProps {
  id: string;
  labelNome: string;
  labelEndereco: string;
  labelMaxWidth?: string;
  onChange: () => void;
  name: string;
  readOnly?: boolean;
  isChecked: boolean;
  isCheckedBox?: boolean;
  onClick?: () => void;
}

const CheckBoxLoja = ({
  id,
  name,
  labelNome,
  labelEndereco,
  labelMaxWidth,
  readOnly,
  isChecked,
  isCheckedBox = false,
  onChange,
  onClick,
}: CheckBoxLojaProps) => {
  return (
    <>
      <Flex gap="8px" alignItems="flex-start" w="95%">
        {isCheckedBox ? (
          <Checkbox
            id={id}
            name={name}
            w="16px"
            h="16px"
            boxShadow="inset #00000029 0px 3px 6px"
            colorScheme="purple"
            bg="white"
            borderRadius="1px"
            isChecked={isChecked}
            onChange={onChange}
            readOnly={readOnly}
          />
        ) : (
          <Box>
            <Switch
              id={id}
              name={name}
              onChange={() => onChange()}
              size="md"
              isDisabled={readOnly}
            />
          </Box>
        )}
        <VStack
          align="start"
          w="full"
          onClick={isCheckedBox ? onClick : () => {}}
          cursor={isCheckedBox ? 'pointer' : 'default'}
          spacing="0px"
          gap="2px"
        >
          <Text
            color="black"
            size="14px"
            fontWeight="semibold"
            overflow="hidden"
            w="full"
          >
            {labelNome}
          </Text>
          <Text color="gray.500" size="12px" overflow="hidden" w="full">
            {labelEndereco}
          </Text>
        </VStack>
      </Flex>
    </>
  );
};

export default CheckBoxLoja;
