import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import { isValidCnpj, isValidCpf } from 'helpers/validation/IsValidCpfCnpj';

export type FormData = {
  cliente: string;
  estabelecimento: string;
  cpfCnpj: string;
  telefone: string;
  observacao: string;
};

export const defaultValues = {
  cliente: '',
  estabelecimento: '',
  cpfCnpj: '',
  telefone: '',
  observacao: '',
};

const schema = yup.object().shape({
  cliente: yup.string().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  estabelecimento: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  cpfCnpj: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .test('cpfCnpj', ConstanteMensagemValidacao.CNPJ_INVALIDO, (value) => {
      if (value) {
        if (value.length > 14) return isValidCnpj(value);
      }
      return true;
    })
    .test('cpfCnpj', ConstanteMensagemValidacao.CPF_INVALIDO, (value) => {
      if (value) {
        if (value.length <= 14) return isValidCpf(value);
      }
      return true;
    }),
  telefone: yup.string().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  observacao: yup.string().nullable(),
});

export const yupResolver = yupResolverInstance(schema);
