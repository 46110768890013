import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

export type TabelaPrecoProdutoCorTamanho = {
  produtoCorTamanhoId: string;
  produtoNome: string;
  corDescricao?: string;
  tamanhoDescricao?: string;
  precoVenda: number;
  precoCusto: number;
  markup: number;
};

export type TabelaPreco = {
  nome: string;
  ativo: boolean;
  tipoCalculo: number;
  percentual: number;
  padronizarPreco: boolean;
  padraoSistema: boolean;
  arredondamentoAcima: number;
  arredondamentoAbaixo: number;
  tabelaPrecoProdutoCorTamanhos?: TabelaPrecoProdutoCorTamanho[];
  dataHoraCadastro: string;
  dataHoraUltimaAlteracao: string;
};

export async function obterTabelaPreco(id: string) {
  const response = await api.get<void, ResponseApi<TabelaPreco | undefined>>(
    ConstanteEnderecoWebservice.TABELA_PRECO_OBTER,
    {
      params: { id },
    }
  );

  if (response) {
    if (response.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (response.sucesso && response.dados) {
      return response.dados;
    }
  }

  return undefined;
}
