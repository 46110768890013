import {
  Box,
  Flex,
  useMediaQuery,
  Icon,
  Text,
  VStack,
  Image,
  UnorderedList,
  Button,
  ListItem,
  GridItem,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { FiChevronLeft } from 'react-icons/fi';

import BannerZoop from 'assets/images/integracoes/zoop/01.png';
import SobreZoop from 'assets/images/integracoes/zoop/02.png';
import ConstanteRotas, { ConstanteRotasAlternativas } from 'constants/rotas';

import {
  IconEscanearQRCodeZoop,
  IconTelaVendasZoop,
  IconConfirmacaoZoop,
  IconPagamentoZoop,
  IconDescricaoPedido,
  IconLinkZoop,
} from 'icons';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';

export const ZoopComercial = () => {
  const history = useHistory();

  const handleVoltar = () => {
    history.push(ConstanteRotas.LOJA_APLICATIVOS);
  };

  const handleNavigateToZoopConfiguration = () => {
    history.push(ConstanteRotasAlternativas.ZOOP_CONFIGURACAO);
  };

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const [isLargerThan1380] = useMediaQuery('(min-width: 1380px)');

  return (
    <Box
      bg="white"
      boxShadow="0px 0px 6px #00000034"
      pt="2.9%"
      pl="5.4%"
      pr="5.4%"
      pb="100px"
      borderRadius="5px"
    >
      <Flex mb="16px" justifyContent="flex-start" alignItems="center">
        <Box onClick={() => handleVoltar()} cursor="pointer" mr="16px">
          <Icon color="gray.500" boxSize="25px" as={FiChevronLeft} />
        </Box>
        <Box onClick={() => handleVoltar()} cursor="pointer">
          <Text fontSize="14px" color="gray.500">
            Voltar
          </Text>
        </Box>
      </Flex>
      <Flex
        mb="83px"
        direction={['column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box w={['full', 'full', '550px', '550px', '884px']}>
          <Text
            lineHeight={['', '', '40px']}
            fontSize={['25px', '25px', '32px']}
            letterSpacing="0"
            color="primary.50"
          >
            <span
              style={{
                fontWeight: 'bold',
              }}
            >
              {' '}
              Zoop serviços financeiros:
            </span>{' '}
            Realize a emissão de boletos direto no sistema e receba por PIX com
            confirmação imediata na tela de vendas.
          </Text>
          {!isLargerThan900 && (
            <Flex
              mt="20px"
              w="full"
              justifyContent="center"
              alignItems="center"
            >
              <Image w="426px" alt="banner Tray" src={BannerZoop} />
            </Flex>
          )}
          <VStack
            spacing="24px"
            w={['', '', '', '', '750px']}
            mb="43px"
            mt="24px"
          >
            <Text
              lineHeight="20px"
              letterSpacing="0"
              fontSize="16px"
              color="gray.700"
            >
              Venda na loja ou na internet e receba os pagamentos por PIX e
              boleto gerados diretamente na tela de venda do sistema. Os valores
              recebidos são conciliados automaticamente mantendo sua empresa
              organizada e seu caixa devidamente atualizado.
            </Text>
            <Text
              lineHeight="20px"
              letterSpacing="0"
              fontSize="16px"
              color="gray.700"
            >
              O serviço está disponível tanto para venda presencial quanto para
              venda online, com envio de links de pagamento aos seus clientes.
              Utilize a integração com a Zoop para agilizar o recebimento de
              suas vendas e garantir ainda mais segurança ao seu fluxo de caixa.
            </Text>
          </VStack>
        </Box>
        {isLargerThan900 && (
          <Box>
            <Image w="426px" alt="banner Tray" src={BannerZoop} />
          </Box>
        )}
      </Flex>

      <Box mb="100px">
        <Text
          letterSpacing="0"
          fontSize={['25px', '25px', '32px']}
          color="primary.50"
          fontWeight="extrabold"
        >
          Como funciona
        </Text>
        <Text
          letterSpacing="0"
          lineHeight={['', '', '40px']}
          fontSize={['25px', '25px', '32px']}
          color="primary.50"
        >
          Agilize suas vendas de formas simples, direto na tela do sistema.
        </Text>

        <Text
          letterSpacing="0"
          mt={['30px', '30px', '50px']}
          lineHeight={['', '', '40px']}
          fontSize={['25px', '25px', '32px']}
          color="blue.900"
        >
          - Venda por Pix presencial:
        </Text>

        <SimpleGridForm>
          <GridItem colSpan={[12, 12, 3]}>
            <Box>
              <Flex justifyContent="center" alignItems="center" w="full">
                <Icon
                  pl="20px"
                  pt="10px"
                  boxSize="130px"
                  as={IconTelaVendasZoop}
                />
              </Flex>
              <Text fontSize="16px" textAlign="center" color="gray.700">
                Na tela de vendas, selecione a forma de recebimento com o nome
                da Zoop
              </Text>
            </Box>
          </GridItem>
          <GridItem colSpan={[12, 12, 3]}>
            <Box>
              <Flex justifyContent="center" alignItems="center" w="full">
                <Icon
                  pl="20px"
                  pt="10px"
                  boxSize="130px"
                  as={IconEscanearQRCodeZoop}
                />
              </Flex>
              <Text fontSize="16px" textAlign="center" color="gray.700">
                Cliente escaneia o QRCode na tela e faz o pagamento pelo app do
                banco.
              </Text>
            </Box>
          </GridItem>
          <GridItem colSpan={[12, 12, 3]}>
            <Box>
              <Flex justifyContent="center" alignItems="center" w="full">
                <Icon
                  pl="20px"
                  pt="10px"
                  boxSize="130px"
                  as={IconConfirmacaoZoop}
                />
              </Flex>
              <Text fontSize="16px" textAlign="center" color="gray.700">
                Sistema exibe a confirmação do pagamento em tempo real na tela
                do operador de caixa.
              </Text>
            </Box>
          </GridItem>
          <GridItem colSpan={[12, 12, 3]}>
            <Box>
              <Flex justifyContent="center" alignItems="center" w="full">
                <Icon
                  pl="20px"
                  pt="10px"
                  boxSize="130px"
                  as={IconPagamentoZoop}
                />
              </Flex>
              <Text fontSize="16px" textAlign="center" color="gray.700">
                Você recebe o pagamento direto na sua conta bancária, com todos
                os pagamentos registrados no sistema e conciliação automática.
              </Text>
            </Box>
          </GridItem>
        </SimpleGridForm>

        <Text
          letterSpacing="0"
          mt={['30px', '30px', '50px']}
          lineHeight={['', '', '40px']}
          fontSize={['25px', '25px', '32px']}
          color="blue.900"
        >
          - Boleto ou Pix para envio por link de pagamento:
        </Text>

        <SimpleGridForm>
          <GridItem colSpan={[12, 12, 3]}>
            <Box>
              <Flex justifyContent="center" alignItems="center" w="full">
                <Icon
                  pl="20px"
                  pt="10px"
                  boxSize="130px"
                  as={IconTelaVendasZoop}
                />
              </Flex>
              <Text fontSize="16px" textAlign="center" color="gray.700">
                Na tela de vendas, selecione a forma de recebimento com o nome
                da Zoop
              </Text>
            </Box>
          </GridItem>
          <GridItem colSpan={[12, 12, 3]}>
            <Box>
              <Flex justifyContent="center" alignItems="center" w="full">
                <Icon pl="20px" pt="10px" boxSize="130px" as={IconLinkZoop} />
              </Flex>
              <Text fontSize="16px" textAlign="center" color="gray.700">
                Compartilhe o Link de pagamento online com o seu cliente. Envie
                pelo WhatsApp, E-mail, Facebook, Instagram ou mensagem de texto.
              </Text>
            </Box>
          </GridItem>
          <GridItem colSpan={[12, 12, 3]}>
            <Box>
              <Flex justifyContent="center" alignItems="center" w="full">
                <Icon
                  pl="20px"
                  pt="10px"
                  boxSize="130px"
                  as={IconDescricaoPedido}
                />
              </Flex>
              <Text fontSize="16px" textAlign="center" color="gray.700">
                O cliente recebe toda a descrição do pedido, Efetua o pagamento
                de forma rápida, segura e de onde ele estiver.
              </Text>
            </Box>
          </GridItem>
          <GridItem colSpan={[12, 12, 3]}>
            <Box>
              <Flex justifyContent="center" alignItems="center" w="full">
                <Icon
                  pl="20px"
                  pt="10px"
                  boxSize="130px"
                  as={IconPagamentoZoop}
                />
              </Flex>
              <Text fontSize="16px" textAlign="center" color="gray.700">
                Você recebe o valor direto na sua conta bancária, com todos os
                pagamentos registrados no sistema e conciliação automática.
              </Text>
            </Box>
          </GridItem>
        </SimpleGridForm>
      </Box>

      <Box>
        <Text
          letterSpacing="0"
          fontSize={['25px', '25px', '32px']}
          color="primary.50"
          fontWeight="extrabold"
        >
          Principais vantagens:
        </Text>
        <Text
          letterSpacing="0"
          lineHeight={['', '', '40px']}
          fontSize={['25px', '25px', '32px']}
          color="primary.50"
        >
          A integração entre o sistema e a Zoop permite que você receba através
          de seus posts, e-mail ou mensagens. Resumindo: Utilize para qualquer
          venda da sua loja, presencial ou online.
        </Text>

        <Box mt={['23px', '23px', '33px']}>
          <UnorderedList
            fontSize={['16px', '16px', '20px']}
            lineHeight={['25px', '25px', '40px']}
            color="gray.700"
          >
            <ListItem>
              Não precisa acessar um site externo: Você gerencia tudo direto
              pelo sistema;
            </ListItem>
            <ListItem>
              Pix Presencial: Para pagamento no ato da compra;
            </ListItem>
            <ListItem>
              Pix Envio: Para enviar um link de pagamento nas vendas online ou
              delivery;
            </ListItem>
            <ListItem>
              Confirmação do pagamento em tempo real: Direto na tela do sistema,
              não precisa acessar a conta no banco;
            </ListItem>
            <ListItem>Excelentes taxas, sem tarifa de adesão;</ListItem>
            <ListItem>
              Leitura do QRCode direto na tela, gerado pelo sistema no valor
              exato da venda;
            </ListItem>
            <ListItem>
              Link de pagamento: Compartilhe o link como você preferir, por
              WhatsApp ou qualquer outra mensagem;
            </ListItem>
            <ListItem>
              Cliente recebe o link com descrição do pedido e instruções de como
              efetuar o pagamento;
            </ListItem>
            <ListItem>
              Conciliação de valores automatica: Não precisa inserir dados
              manuais, o próprio sistema identifica o pagamento;
            </ListItem>
            <ListItem>
              Visualização de todas as transações realizadas: Histórico,
              re-envio do link e várias outras facilidades;
            </ListItem>
            <ListItem>
              Segurança e garantia dos pagamentos: com supervisão do BACEN;
            </ListItem>
          </UnorderedList>
        </Box>
      </Box>

      <Box mt="119px">
        <Text
          letterSpacing="0"
          fontSize={['25px', '25px', '32px']}
          color="primary.50"
          fontWeight="extrabold"
        >
          Sobre a Zoop:
        </Text>
        <Text
          letterSpacing="0"
          lineHeight={['', '', '40px']}
          fontSize={['25px', '25px', '32px']}
          color="primary.50"
        >
          Somos a fintech líder em tecnologia para serviços financeiros
          embarcados no Brasil.
        </Text>

        <Flex direction={isLargerThan1380 ? 'row' : 'column'} mt="24px">
          <VStack
            spacing="24px"
            mb={isLargerThan1380 ? '0' : '20px'}
            w={isLargerThan1380 ? '70%' : 'full'}
          >
            <Text letterSpacing="0" fontSize="20px" color="gray.700">
              A Zoop é uma fintech que atua na intermediação entre sua loja e o
              cliente, oferecendo formas de pagamento sem burocracias, sem
              barreiras tecnológicas e livre de obstáculos jurídicos.
            </Text>
            <Text letterSpacing="0" fontSize="20px" color="gray.700">
              Potencialize o volume de transações e crie novas linhas de receita
              na sua empresa com soluções completas, integradas ao sistema de
              gestão e fáceis de gerir e implantar.
            </Text>
            <Text letterSpacing="0" fontSize="20px" color="gray.700">
              A Zoop é uma empresa com intuito de democratizar o acesso a
              serviços financeiros, auxiliando as empresas em sua jornada de
              crescimento.
            </Text>
          </VStack>

          <Flex w="full" justifyContent="center" alignItems="center">
            <Image
              w={isLargerThan1380 ? '80%' : ''}
              alt="app smart pos"
              src={SobreZoop}
            />
          </Flex>
        </Flex>
      </Box>

      <Box mt={['40px', '120px']}>
        <Text color="primary.50" fontWeight="bold" fontSize="32px">
          {' '}
          Como contratar:{' '}
        </Text>
        <Flex
          h={['auto', 'auto', '94px']}
          p="30px"
          borderRadius="10px"
          bg="secondary.200"
          fontSize="20px"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Box>
            <Text fontWeight="bold">
              A integração com a Zoop está disponível para todos os planos.
            </Text>
            <Text>
              Para saber sobre preço, tarifas, contratação ou outros detalhes de
              performance, entre em contato.
            </Text>
          </Box>
        </Flex>
        <Flex w="full" justify="center">
          <Button
            variant="solid"
            colorScheme="primary"
            padding="24px"
            mt="48px"
            fontSize="lg"
            onClick={handleNavigateToZoopConfiguration}
          >
            Quero credenciar agora mesmo
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};
