import React from 'react';
import { Text, TextProps } from '@chakra-ui/react';

const Paragraph = ({ children, ...rest }: TextProps) => {
  return (
    <Text
      fontWeight="300"
      color="white"
      fontSize="16px"
      letterSpacing="normal"
      {...rest}
    >
      {children}
    </Text>
  );
};

export default Paragraph;
