import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import { isValidCpf, isValidCnpj } from 'helpers/validation/IsValidCpfCnpj';

const schema = yup.object().shape({
  cpfCnpj: yup
    .string()
    .nullable()
    .test('cpfCnpj', ConstanteMensagemValidacao.CNPJ_INVALIDO, (value) => {
      if (value) {
        if (value.length > 14) return isValidCnpj(value);
      }

      return true;
    })
    .test('cpfCnpj', ConstanteMensagemValidacao.CPF_INVALIDO, (value) => {
      if (value) {
        if (value.length <= 14) return isValidCpf(value);
      }

      return true;
    }),
  nome: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .max(60, ConstanteMensagemValidacao.MAX_LENGTH_60),
  apelido: yup
    .string()
    .nullable()
    .max(60, ConstanteMensagemValidacao.MAX_LENGTH_60),
  email: yup
    .string()
    .nullable()
    .email(ConstanteMensagemValidacao.EMAIL_INVALIDO)
    .max(60, ConstanteMensagemValidacao.MAX_LENGTH_60),
  contato: yup
    .string()
    .nullable()
    .max(30, ConstanteMensagemValidacao.MAX_LENGTH_30),
  telefone: yup
    .string()
    .nullable()
    .nullable()
    .notRequired()
    .test('telefone', ConstanteMensagemValidacao.TELEFONE_INVALIDO, (value) => {
      if (value) {
        const testSchema = yup.string().min(14).max(15);

        return testSchema.isValidSync(value);
      }

      return true;
    }),
  rgie: yup
    .string()
    .nullable()
    .max(20, ConstanteMensagemValidacao.MAX_LENGTH_20),
  rntc: yup
    .string()
    .nullable()
    .max(60, ConstanteMensagemValidacao.MAX_LENGTH_20),
  cep: yup
    .string()
    .nullable()
    .test('cep', ConstanteMensagemValidacao.CEP_INVALIDO, (value) => {
      if (value) {
        const testSchema = yup.string().min(9).max(9);

        return testSchema.isValidSync(value);
      }

      return true;
    }),
  logradouro: yup
    .string()
    .nullable()
    .max(60, ConstanteMensagemValidacao.MAX_LENGTH_60),
  numero: yup
    .string()
    .nullable()
    .max(60, ConstanteMensagemValidacao.MAX_LENGTH_60),
  complemento: yup
    .string()
    .nullable()
    .max(60, ConstanteMensagemValidacao.MAX_LENGTH_60),
  bairro: yup
    .string()
    .nullable()
    .max(60, ConstanteMensagemValidacao.MAX_LENGTH_60),
  cidade: yup
    .object()
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  pais: yup
    .object()
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  observacao: yup.string().nullable(),
  ativo: yup.boolean(),
});

export const defaultValues = {
  cpfCnpj: null,
  nome: null,
  apelido: null,
  email: null,
  contato: null,
  telefone: null,
  rgie: null,
  rntc: null,
  cep: null,
  logradouro: null,
  numero: null,
  complemento: null,
  bairro: null,
  cidade: null,
  estado: null,
  pais: null,
  observacao: null,
  ativo: true,
};

export const formDefaultValue = {
  nome: '',
  foto: null,
  telefone: null,
  email: null,
  comissao: 0,
  metaMensal: 0,
  ativo: true,
  dataNascimento: undefined,
  genero: undefined,
};

export type CidadeProps = {
  value: string;
  label: string;
};

export type PaisProps = {
  value: string;
  label: string;
};

export type FormData = {
  nome: string;
  id: string;
  cidadeId?: string;
  paisId?: string;
  foto: string | null;
  telefone: string | null;
  email: string | null;
  comissao: number;
  metaMensal: number;
  ativo: boolean;
  cpfCnpj: string;
  apelido: string;
  contato: string;
  rgie: string;
  rntc: string;
  cep: string;
  logradouro: string;
  numero: string;
  complemento: string;
  bairro: string;
  observacao: string;
  cidade: CidadeProps;
  pais: PaisProps;
};

export const yupResolver = yupResolverInstance(schema);

export const useForm = useFormInstance;
