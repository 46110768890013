import React, { useMemo, memo } from 'react';
import {
  Stack,
  HStack,
  VStack,
  Text,
  Center,
  Divider as ChakraDivider,
  useMediaQuery,
} from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';

import { usePagamentoContext } from 'store/PDV/Pagamento';
import TipoValorEnum, { TipoValor } from 'constants/enum/tipoValor';

import { TextValor, TextValorProps } from 'components/PDV/Text/TextValor';

export const CalcularValorTotalDescontoAdicional = (
  tipoDescontoAdicional: TipoValor,
  valorDescontoAdicional: number,
  valorTotalItensComDesconto: number
) => {
  if (tipoDescontoAdicional === TipoValorEnum.PORCENTAGEM) {
    return (valorDescontoAdicional / 100) * valorTotalItensComDesconto;
  }
  return valorDescontoAdicional;
};

const Divider = () => (
  <Center h="35px">
    <ChakraDivider orientation="vertical" />
  </Center>
);

interface ValorProps extends TextValorProps {
  title: string;
}

const Valor = ({ title, ...rest }: ValorProps) => (
  <VStack spacing={1} w={{ base: 'full', md: 'auto' }}>
    <Text
      fontSize="2xs"
      textTransform="uppercase"
      fontWeight="semibold"
      color="gray.300"
    >
      {title}
    </Text>
    <TextValor color="gray.700" fontSize="lg" {...rest} />
  </VStack>
);

const Totalizadores = () => {
  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');
  const {
    totais: {
      valorTotalItensSemDesconto = 0,
      valorTotalItensComDesconto = 0,
    } = {},
  } = usePagamentoContext();
  const { control } = useFormContext();

  const descontoItens = useWatch({
    control,
    name: 'descontoItens',
    defaultValue: 0,
  });
  const descontoAdicional = useWatch({
    control,
    name: 'descontoAdicional',
    defaultValue: 0,
  });
  const tipoDescontoAdicional = useWatch({
    control,
    name: 'tipoDescontoAdicional',
    defaultValue: TipoValorEnum.REAIS,
  });
  const acrescimos = useWatch({
    control,
    name: 'acrescimos',
    defaultValue: 0,
  });
  const outrasDespesas = useWatch({
    control,
    name: 'outrasDespesas',
    defaultValue: 0,
  });
  const valorFrete = useWatch({
    control,
    name: 'valorFrete',
    defaultValue: 0,
  });

  const totalAcrescimos = useMemo(
    () => acrescimos + outrasDespesas + valorFrete,
    [acrescimos, outrasDespesas, valorFrete]
  );

  const totalDescontoAdicional = useMemo(
    () =>
      CalcularValorTotalDescontoAdicional(
        tipoDescontoAdicional as TipoValor,
        descontoAdicional,
        valorTotalItensComDesconto
      ),
    [descontoAdicional, tipoDescontoAdicional, valorTotalItensComDesconto]
  );

  const totalDescontos = useMemo(() => totalDescontoAdicional + descontoItens, [
    descontoItens,
    totalDescontoAdicional,
  ]);

  const totalPagar = useMemo(
    () => valorTotalItensSemDesconto + (totalAcrescimos - totalDescontos),
    [totalAcrescimos, totalDescontos, valorTotalItensSemDesconto]
  );

  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      spacing={4}
      px={{ base: 0, md: 4 }}
      mt={5}
    >
      <HStack
        w="full"
        justifyContent="space-around"
        px={{ base: 4, md: 0 }}
        pb={{ base: 1, md: 0 }}
      >
        <Valor title="Pedido" valor={valorTotalItensSemDesconto} />
        <Divider />
        <Valor title="Acréscimos" valor={totalAcrescimos} color="blue.700" />
        {!isSmallerThan900 && <Divider />}
      </HStack>
      {isSmallerThan900 && <ChakraDivider />}
      <HStack w="full" justifyContent="space-around" px={{ base: 4, md: 0 }}>
        <Valor title="Descontos" valor={totalDescontos} color="red.500" />
        <Divider />
        <Valor title="Total a pagar" valor={totalPagar} />
      </HStack>
      {isSmallerThan900 && <ChakraDivider />}
    </Stack>
  );
};

export default memo(Totalizadores);
