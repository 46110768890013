import StatusOperacaoEnum from 'constants/enum/statusOperacao';
import { setDateMaxHours, setDateMinHours } from 'helpers/data/setHoursDate';

export const defaultValues = {
  numeroOperacao: '',
  cliente: null,
  identificacaoTipoOperacao: null,
  filtrarPeloCaixaAtual: true,
  status: StatusOperacaoEnum.EFETUADA,
  dataEmissaoInicio: setDateMinHours(new Date()),
  dataEmissaoFim: setDateMaxHours(new Date()),
};

export type FormData = {
  numeroOperacao: string;
  cliente: {
    label: string;
    value: string;
  } | null;
  identificacaoTipoOperacao: number | null;
  filtrarPeloCaixaAtual: boolean;
  status: number;
  dataEmissaoInicio: Date;
  dataEmissaoFim: Date;
};
