import { GridItem, Box, FormLabel, Flex } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { useCallback, useEffect, useState } from 'react';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';
import StatusConsultaEnum from 'constants/enum/statusConsulta';

import { Switch } from 'components/update/Switch';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import CreatableSelect from 'components/PDV/Select/CreatableSelect';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { numeroCopiasOptions } from '../../validationForm';

export type Option = {
  label: string;
  value: string;
};

export const Printer = () => {
  const [printers, setPrinters] = useState<Option[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadPrinters = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get<
      void,
      ResponseApi<
        {
          id: string;
          nome: string;
        }[]
      >
    >(ConstanteEnderecoWebservice.IMPRESSORA, {
      params: { statusConsulta: StatusConsultaEnum.ATIVOS },
    });

    if (response?.avisos) {
      response.avisos.forEach((aviso: string) => toast.warning(aviso));
    }

    if (response && response.sucesso && response.dados) {
      const data = response.dados.map((option) => ({
        label: option.nome,
        value: option.id,
      }));
      setPrinters(data);

      setIsLoading(false);
      return;
    }

    setPrinters([]);
    setIsLoading(false);
  }, []);

  const createNewPrinter = async (inputValue: string) => {
    const response = await api.post<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.IMPRESSORA,
      {
        nome: inputValue,
        ativo: true,
      }
    );

    if (response?.avisos) {
      response.avisos.forEach((aviso: string) => toast.warning(aviso));
    }

    if (response && response.sucesso && response.dados) {
      const data = {
        label: inputValue,
        value: response.dados,
      };

      return data;
    }

    return {} as Option;
  };

  const handleCreateNewPrinter = async (inputValue: string) => {
    const newPrinter = await createNewPrinter(inputValue);
    setPrinters((prevState) => [...prevState, newPrinter]);
    return newPrinter;
  };

  useEffect(() => {
    loadPrinters();
  }, [loadPrinters]);

  return (
    <>
      {isLoading && <LoadingPadrao />}
      <GridItem colSpan={[12, 8]}>
        <CreatableSelect
          id="impressora"
          name="impressora"
          label="Impressora"
          placeholder="Selecione ou informe a impressora"
          required
          options={printers}
          isLoading={isLoading}
          creatableInputTextPreffix="Cadastrar impressora: "
          handleCreateOption={handleCreateNewPrinter}
          asControlledByObject
          isClearable
        />
      </GridItem>
      <GridItem colSpan={[12, 4]}>
        <Flex flexDir="column" gap="8px">
          <FormLabel mb="0" fontSize="sm" color="black" lineHeight="1.2">
            Imprimir automático
          </FormLabel>
          <Box>
            <Switch
              size="md"
              id="imprimirAutomatico"
              name="imprimirAutomatico"
            />
          </Box>
        </Flex>
      </GridItem>
      <GridItem colSpan={[12, 8]}>
        <SelectPadrao
          id="quantidadeCopias"
          name="quantidadeCopias"
          isSearchable={false}
          label="Nº de cópias"
          options={numeroCopiasOptions}
          placeholder="Selecione a quantidade de cópias"
        />
      </GridItem>
      <GridItem colSpan={[6, 4]}>
        <Flex flexDir="column" gap="8px">
          <FormLabel mb="0" fontSize="sm" color="black" lineHeight="1.4">
            Confirmar impressão
          </FormLabel>
          <Box>
            <Switch
              size="md"
              id="confirmarImpressao"
              name="confirmarImpressao"
            />
          </Box>
        </Flex>
      </GridItem>
    </>
  );
};
