import { useCallback, useEffect } from 'react';
import {
  ModalProps as ModalChakraProps,
  ModalContent,
  ModalBody,
  Box,
  Button,
  Text,
  Flex,
  ModalFooter,
  ModalHeader,
  useMediaQuery,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';

import Input from 'components/PDV/Input';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { ProdutoCorTamanhoProps } from '../../../hook';

type FormData = {
  codigos: string[];
};

type ProductProps = ProdutoCorTamanhoProps & {
  codigo?: string;
};

type CodeType = 'GTIN/EAN' | 'Código Externo';

type CodeProps = {
  type: CodeType;
  value: string;
};

type ModalProps = {
  title?: string;
  code: CodeProps;
  products: ProductProps[];
  isLoading?: boolean;
  callback: (products: ProductProps[]) => Promise<void>;
};

type ModalProdutoCodigoDuplicadoProps = Omit<ModalChakraProps, 'children'> &
  ModalProps;

export const ModalProdutoCodigoDuplicado = ({
  title = 'Editar códigos repetidos no sistema',
  code,
  products,
  isLoading,
  callback,
  isOpen,
  onClose,
  ...rest
}: ModalProdutoCodigoDuplicadoProps) => {
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const formMethods = useForm<FormData>();
  const {
    handleSubmit,
    reset,
    formState: { isDirty: formIsDirty, dirtyFields },
  } = formMethods;

  const handleConfirmUpdate = useCallback(
    async (data: FormData) => {
      const newProducts = products.map((product, index) => ({
        ...product,
        codigo: data.codigos[index],
      }));

      const productsWithNewCode = newProducts.filter(
        (_, index) => dirtyFields.codigos && dirtyFields.codigos[index]
      );

      callback(productsWithNewCode);
    },
    [callback, dirtyFields.codigos, products]
  );

  useEffect(() => {
    reset({ codigos: products.map(() => code.value) });
  }, [code, products, reset]);

  return (
    <ModalPadraoChakra
      isCentered={isLargerThan900}
      size={isLargerThan900 ? '3xl' : 'full'}
      scrollBehavior={isLargerThan900 ? 'inside' : 'outside'}
      {...rest}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalContent px={{ base: '16px', md: '40px' }} bg="gray.50">
        <ModalHeader px={0} pt={6} pb={4}>
          <Text textColor="primary.50" fontSize="16px" fontWeight="semibold">
            {title}
          </Text>
        </ModalHeader>
        <ModalBody p={0} position="relative">
          {isLoading && <LoadingPadrao />}
          <FormProvider {...formMethods}>
            <Box
              py="22px"
              px={5}
              bg="gray.100"
              borderWidth="1px"
              borderColor="gray.200"
              borderRadius="5px"
              overflowX="auto"
            >
              <Box minW="400px">
                <Flex
                  w="full"
                  px={4}
                  mb={1}
                  justifyContent="space-between"
                  borderBottomWidth="1px"
                  borderBottomColor="gray.200"
                  textColor="gray.500"
                >
                  <Text w={{ base: '50%', md: '70%' }}>Produto</Text>
                  <Text textAlign="left" w={{ base: '50%', md: '30%' }}>
                    {code.type}
                  </Text>
                </Flex>
                <UnorderedList ml={0}>
                  {products?.map(({ produtoCorTamanhoId, nome }, index) => (
                    <Flex
                      key={produtoCorTamanhoId}
                      w="full"
                      py="10px"
                      px={4}
                      pl={8}
                      justifyContent="space-between"
                      align="center"
                      borderBottomWidth="1px"
                      borderBottomColor="gray.200"
                    >
                      <ListItem
                        w={{ base: '50%', md: '70%' }}
                        fontSize="14px"
                        textColor="black"
                        cursor="default"
                      >
                        {nome}
                      </ListItem>
                      <Box w={{ base: '50%', md: '30%' }}>
                        <Input name={`codigos.${index}`} />
                      </Box>
                    </Flex>
                  ))}
                </UnorderedList>
              </Box>
            </Box>
          </FormProvider>
        </ModalBody>
        <ModalFooter justifyContent="center" flexWrap="wrap" gap={6} py="50px">
          <Button
            w={{ base: 'full', sm: 'min-content' }}
            colorScheme="gray"
            variant="outline"
            onClick={onClose}
            isDisabled={isLoading}
          >
            Cancelar
          </Button>
          <Button
            w={{ base: 'full', sm: 'min-content' }}
            variant="solid"
            color={formIsDirty ? 'current' : 'white'}
            colorScheme={formIsDirty ? 'secondary' : 'gray'}
            isDisabled={!formIsDirty || isLoading}
            onClick={handleSubmit(handleConfirmUpdate)}
          >
            Confirmar alterações
          </Button>
        </ModalFooter>
      </ModalContent>
    </ModalPadraoChakra>
  );
};
