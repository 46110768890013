import React, { useEffect, useState, useMemo, useRef } from 'react';
import {
  Box,
  Heading,
  Grid,
  Button,
  Stack,
  Icon,
  Text,
  Flex,
  Tooltip,
  useMediaQuery,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';

import auth from 'modules/auth';
import MeioPagamentoEnum, {
  MeioPagamento,
} from 'constants/enum/fiscal/meioPagamento';
import useWindowSize from 'helpers/layout/useWindowSize';
import { useSelecaoFormaPagamentoContext } from 'store/PDV/SelecaoFormaPagamento';
import { useFormasPagamentoContext } from 'store/PDV/FormasPagamento';
import { useOperacaoContext } from 'store/PDV/Operacao';
import ConstanteFuncionalidades from 'constants/permissoes';
import LogAuditoriaTelaEnum from 'constants/enum/logAuditoriaTela';
import { usePagamentoContext } from 'store/PDV/Pagamento';
import RegraLimiteCreditoEnum from 'constants/enum/regraLimiteCredito';
import TipoContaFinanceiraEnum from 'constants/enum/tipoContaFinanceira';
import { useInformacoesGeraisContext } from 'store/PDV/InformacoesGerais';
import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';

import ShadowScrollbar, {
  ShadowScrollbarForwardRefProps,
} from 'components/PDV/Geral/ShadowScrollbar';
import ConditionalWrapper from 'components/Geral/ConditionalWrapper';
import ModalAutorizacaoFuncionalidade from 'components/Modal/ModalAutorizacaoFuncionalidade';
import ModalConfirmacao from 'components/PDV/Modal/ModalConfirmacao';
import { ModalAtencao } from 'components/Modal/ModalAtencao';

type FormaPagamento = {
  id: string;
  nome: string;
  regraMeioPagamento: MeioPagamento;
};

const ListarOpcoesFormasPagamento = () => {
  const {
    clienteNaoPodeComprarNoCreditoLoja,
    setClienteNaoPodeComprarNoCreditoLoja,
    informacoesCliente,
    tipoOperacaoAtual,
    setOperacaoIsLoading,
  } = useOperacaoContext();
  const { handleSelecionarFormaPagamento } = useSelecaoFormaPagamentoContext();

  const {
    contaFinanceira,
    possuiCadastroOutrosCaixas,
  } = useInformacoesGeraisContext();

  const { formasPagamento } = useFormasPagamentoContext();
  const { height: windowHeight } = useWindowSize();
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const [chavePermissaoTemporaria, setChavePermissaoTemporaria] = useState('');
  const [
    modalAutorizarFuncionalidadeIsOpen,
    setModalAutorizarFuncionalidadeIsOpen,
  ] = useState(false);
  const [liberarClienteBloqueado, setLiberarClienteBloqueado] = useState(false);
  const [modalConfirmacaoIsOpen, setModalConfirmacaoIsOpen] = useState<boolean>(
    false
  );
  const [
    modalConfirmacaoTextoMensagem,
    setModalConfirmacaoTextoMensagem,
  ] = useState('');

  const refFormaPagamento = useRef<any>(null);

  const {
    valorTotalVenda,
    valorFaltaPagar,
    movimentacoesFinanceiras,
  } = usePagamentoContext();

  const possuiPermissaoLiberarClienteBloqueado = auth.possuiPermissao(
    ConstanteFuncionalidades.PDV_LIBERAR_CLIENTE_BLOQUEADO.codigo
  ).permitido;

  const shadowScrollbarRef = useRef<ShadowScrollbarForwardRefProps>(null);
  const isPedidoOuVenda =
    tipoOperacaoAtual === IdentificacaoTipoOperacaoEnum.VENDA ||
    tipoOperacaoAtual === IdentificacaoTipoOperacaoEnum.PEDIDO;

  const hasMovimentacoesFinanceirasComFormaPagamentoZoop = movimentacoesFinanceiras?.some(
    (movimentacao) => {
      if (
        movimentacao.regraMeioPagamento === MeioPagamentoEnum.BoletoZoop ||
        movimentacao.regraMeioPagamento === MeioPagamentoEnum.PixEnvioZoop ||
        movimentacao.regraMeioPagamento === MeioPagamentoEnum.PixPresencialZoop
      ) {
        return true;
      }
      return false;
    }
  );

  const visibleFormasPagamento = useMemo(() => {
    return formasPagamento;
  }, [formasPagamento]);

  const desabilitarPagamentos = () => {
    if (valorTotalVenda !== 0 && valorFaltaPagar === 0) {
      return true;
    }
    if (
      valorTotalVenda === 0 &&
      valorFaltaPagar === 0 &&
      movimentacoesFinanceiras?.length === 1
    ) {
      return true;
    }

    return false;
  };

  const validacaoPermitirSomenteUmaFormaZoopPorVenda = (
    formaPagamento: FormaPagamento
  ) => {
    setOperacaoIsLoading(true);
    ModalAtencao({
      title: 'Importante',
      text:
        'Cada método de pagamento desta integração pode ser utilizado apenas uma vez por venda. Deseja prosseguir?',
      showCancelButton: true,
      confirmButtonText: 'Sim, prosseguir',
      cancelButtonText: 'Voltar',
      focusCancel: false,
      callback: async (ok?: boolean) => {
        if (ok) {
          handleSelecionarFormaPagamento(formaPagamento);
        }
      },
    });
    setOperacaoIsLoading(false);
  };

  const validarClienteParaBoletoZoop = async () => {
    setOperacaoIsLoading(true);
    const idCliente = informacoesCliente?.id;

    const response = await api.get<void, ResponseApi<boolean>>(
      `${ConstanteEnderecoWebservice.VALIDAR_CLIENTE_BOLETO_PDV}/${idCliente}`
    );
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }
      if (response.sucesso) {
        setOperacaoIsLoading(false);
        return response.sucesso;
      }
    }
    setOperacaoIsLoading(false);
    return false;
  };

  useEffect(() => {
    if (shadowScrollbarRef.current)
      shadowScrollbarRef.current.handleUpdateScrollbar();
  }, [formasPagamento]);

  useEffect(() => {
    if (chavePermissaoTemporaria !== '' || liberarClienteBloqueado) {
      handleSelecionarFormaPagamento(refFormaPagamento.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chavePermissaoTemporaria, liberarClienteBloqueado]);

  useEffect(() => {
    if (
      informacoesCliente?.regraLimiteCredito ===
      RegraLimiteCreditoEnum.BLOQUEIO_CREDIARIO
    ) {
      setClienteNaoPodeComprarNoCreditoLoja(true);
    } else {
      setClienteNaoPodeComprarNoCreditoLoja(false);
    }
  }, [
    informacoesCliente?.regraLimiteCredito,
    setClienteNaoPodeComprarNoCreditoLoja,
  ]);

  return (
    <>
      <ConditionalWrapper
        condition={isLargerThan900}
        wrapper={(wrappedChildren) => (
          <ShadowScrollbar
            width="100%"
            maxHeight={windowHeight - 215}
            paddingTop="0"
            shadowTopStyle={{
              background:
                'transparent linear-gradient(180deg, var(--sti-ck-colors-gray-50)  0%,  #FFFFFF00 100%) 0% 0% no-repeat padding-box',
              height: 30,
            }}
            shadowBottomStyle={{
              background:
                'transparent linear-gradient(180deg, #FFFFFF00 0%, var(--sti-ck-colors-gray-50) 100%) 0% 0% no-repeat padding-box',
              height: 30,
            }}
          >
            {wrappedChildren}
          </ShadowScrollbar>
        )}
      >
        <Box py={{ base: 0, md: 5 }} px={{ base: 0, md: 8, xl: 12 }}>
          {visibleFormasPagamento && visibleFormasPagamento.length > 0 && (
            <>
              <Heading
                as="h4"
                fontSize={{ base: 'sm', md: 'xs', xl: 'md' }}
                mb={4}
                lineHeight="tall"
              >
                Selecione uma forma de recebimento
              </Heading>

              <Grid
                templateColumns={{
                  base: '1fr 1fr 1fr',
                  sm: '1fr 1fr 1fr 1fr',
                }}
                columnGap={{ base: 4, md: 6, lg: 8 }}
                rowGap={{ base: 4, md: 6, lg: 7 }}
              >
                {visibleFormasPagamento.map((formaPagamento) => {
                  const meioPagamento =
                    MeioPagamentoEnum.properties[
                      formaPagamento.regraMeioPagamento
                    ];

                  const pagamentoUsandoBoletoSistema =
                    formaPagamento.regraMeioPagamento ===
                    MeioPagamentoEnum.BoletoBancario;

                  const isPaymentMethodStone =
                    formaPagamento.regraMeioPagamento ===
                      MeioPagamentoEnum.CartaoDebitoStone ||
                    formaPagamento.regraMeioPagamento ===
                      MeioPagamentoEnum.CartaoCreditoStone;

                  const isPaymentMethodValePresente =
                    formaPagamento.regraMeioPagamento ===
                    MeioPagamentoEnum.ValePresente;

                  const validatePagamento =
                    (isPaymentMethodValePresente || isPaymentMethodStone) &&
                    contaFinanceira?.tipoContaFinanceira ===
                      TipoContaFinanceiraEnum.CONTA_COFRE &&
                    possuiCadastroOutrosCaixas;

                  const isPaymentMethodDisabled =
                    desabilitarPagamentos() || validatePagamento;

                  const isMeioPagamentoZoop =
                    formaPagamento.regraMeioPagamento ===
                      MeioPagamentoEnum.BoletoZoop ||
                    formaPagamento.regraMeioPagamento ===
                      MeioPagamentoEnum.PixEnvioZoop ||
                    formaPagamento.regraMeioPagamento ===
                      MeioPagamentoEnum.PixPresencialZoop;

                  if (
                    (isMeioPagamentoZoop && !isPedidoOuVenda) ||
                    (hasMovimentacoesFinanceirasComFormaPagamentoZoop &&
                      isMeioPagamentoZoop)
                  ) {
                    return null;
                  }

                  return (
                    <Tooltip
                      hasArrow
                      label={
                        validatePagamento ? 'O caixa precisa ser aberto.' : ''
                      }
                      closeOnClick={false}
                      placement="top"
                    >
                      <Flex
                        key={formaPagamento.id}
                        w="full"
                        justifyContent="center"
                      >
                        <Button
                          key={formaPagamento.id}
                          position="relative"
                          isDisabled={isPaymentMethodDisabled}
                          h={{ base: '90px', xl: '120px' }}
                          w={{
                            base: 'full',
                            md: '100px',
                            lg: '135px',
                            xl: '200px',
                          }}
                          justifyContent="center"
                          borderRadius="lg"
                          border="1px"
                          borderColor="gray.100"
                          boxShadow="md"
                          bg="white"
                          _hover={
                            isPaymentMethodDisabled
                              ? { bg: 'gray.200' }
                              : { bg: 'white', color: 'gray.400' }
                          }
                          _active={
                            isPaymentMethodDisabled
                              ? { bg: 'gray.200' }
                              : { bg: 'white', color: 'gray.400' }
                          }
                          color="gray.700"
                          py={2}
                          onClick={async () => {
                            if (
                              formaPagamento.regraMeioPagamento ===
                              MeioPagamentoEnum.CreditoLoja
                            ) {
                              if (informacoesCliente?.padraoSistema) {
                                toast.warn(
                                  'A forma de recebimento não é válida para esse cliente.'
                                );
                                return;
                              }
                              if (!clienteNaoPodeComprarNoCreditoLoja) {
                                handleSelecionarFormaPagamento(formaPagamento);
                              } else if (
                                possuiPermissaoLiberarClienteBloqueado
                              ) {
                                setModalConfirmacaoTextoMensagem(
                                  `O cliente ${informacoesCliente?.nome} pertence a categoria "Bloqueio de crediário". Deseja adicionar o pagamento mesmo assim?`
                                );
                                setModalConfirmacaoIsOpen(true);
                                refFormaPagamento.current = formaPagamento;
                              } else {
                                setModalAutorizarFuncionalidadeIsOpen(true);
                                refFormaPagamento.current = formaPagamento;
                              }
                            } else if (isPaymentMethodStone) {
                              if (!contaFinanceira?.serialPOS) {
                                toast.warn(
                                  'Não existe uma maquininha com o serviço Connect Stone vinculada a esse caixa. Finalize o pagamento manual ou selecione outra forma.',
                                  {
                                    autoClose: 20000,
                                  }
                                );
                              }
                              handleSelecionarFormaPagamento(formaPagamento);
                            } else if (isMeioPagamentoZoop) {
                              const isFormaPagamentoBoletoZoop =
                                formaPagamento.regraMeioPagamento ===
                                MeioPagamentoEnum.BoletoZoop;

                              if (isFormaPagamentoBoletoZoop) {
                                const clienteIsValid = await validarClienteParaBoletoZoop();
                                if (!clienteIsValid) {
                                  return;
                                }
                              }
                              validacaoPermitirSomenteUmaFormaZoopPorVenda(
                                formaPagamento
                              );
                            } else if (
                              pagamentoUsandoBoletoSistema &&
                              informacoesCliente?.padraoSistema
                            ) {
                              toast.warn(
                                'A forma de recebimento não é válida para esse cliente.'
                              );
                            } else {
                              handleSelecionarFormaPagamento(formaPagamento);
                            }
                          }}
                        >
                          <Stack
                            direction="column"
                            spacing={2}
                            alignItems="center"
                            justifyContent="center"
                            h="100%"
                            w="full"
                          >
                            <Icon
                              as={
                                meioPagamento?.icon
                                  ? meioPagamento.icon
                                  : undefined
                              }
                              h={{ base: '30px', md: '40px', xl: '50px' }}
                              w="auto"
                            />

                            <Text
                              noOfLines={2}
                              whiteSpace="normal"
                              maxW="full"
                              fontSize={{ base: '2xs', xl: 'xs' }}
                              textTransform="uppercase"
                            >
                              {formaPagamento.nome}
                            </Text>
                          </Stack>
                        </Button>
                      </Flex>
                    </Tooltip>
                  );
                })}
              </Grid>
            </>
          )}
        </Box>
      </ConditionalWrapper>
      <ModalAutorizacaoFuncionalidade
        isOpen={
          modalAutorizarFuncionalidadeIsOpen &&
          !possuiPermissaoLiberarClienteBloqueado &&
          clienteNaoPodeComprarNoCreditoLoja
        }
        setIsOpen={setModalAutorizarFuncionalidadeIsOpen}
        autorizado={(chave) => {
          setChavePermissaoTemporaria(chave);
        }}
        titulo={ConstanteFuncionalidades.PDV_LIBERAR_CLIENTE_BLOQUEADO.titulo}
        descricao="Não é possível continuar a operação porque o limite de crédito foi atingido. Insira os dados de um usuário com permissão para liberar a forma de recebimento."
        tela={LogAuditoriaTelaEnum.PDV}
        permissoes={[
          ConstanteFuncionalidades.PDV_LIBERAR_CLIENTE_BLOQUEADO.codigo,
        ]}
      />
      <ModalConfirmacao
        isOpen={modalConfirmacaoIsOpen}
        textoCabecalho="Pagamento crédito loja"
        textoMensagem={modalConfirmacaoTextoMensagem}
        setIsOpen={setModalConfirmacaoIsOpen}
        onConfirm={() => setLiberarClienteBloqueado(true)}
      />
    </>
  );
};

export default ListarOpcoesFormasPagamento;
