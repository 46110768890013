import { Box, BoxProps } from '@chakra-ui/react';

type ContainerProps = BoxProps & {
  isOpen: boolean;
  menuIsOpen?: boolean;
  children: React.ReactNode;
  id?: string;
};

export const Container = ({
  isOpen,
  children,
  menuIsOpen,
  id,
  ...rest
}: ContainerProps) => {
  return (
    <Box
      id={id}
      type="button"
      cursor="pointer"
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      width={menuIsOpen ? 'full' : '46px'}
      minH="46px"
      left="0"
      bg="none"
      borderColor="menu.borderButton"
      borderBottomWidth="1px"
      borderStyle="solid"
      color={isOpen ? 'menu.hoverText' : 'white'}
      padding="0 11px"
      transition="color 0.2s, background-color 0.2s, width 0.2s"
      _hover={{
        bgColor: 'rgba(0, 0, 0, 0.025)',
        color: 'menu.hoverText',
      }}
      bgColor={isOpen ? 'rgba(0, 0, 0, 0.025)' : undefined}
      {...rest}
    >
      {children}
    </Box>
  );
};
