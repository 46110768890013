import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
  useRef,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';
import { OptionTypeBase } from 'react-select';
import { toast } from 'react-toastify';
import {
  useFieldArray,
  UseFieldArrayReturn,
  useFormContext,
} from 'react-hook-form';

import api, { ResponseApi } from 'services/api';
import TipoProdutoEnum from 'constants/enum/tipoProduto';
import TipoTabelaPrecoEnum from 'constants/enum/tipoTabelaPrecoEnum';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { getCadastroFiscalProdutos } from 'helpers/data/getCadastroFiscalProdutos';

import StatusConsultaEnum from 'constants/enum/statusConsulta';
import CampoPersonalizadoInterface from 'types/campoPersonalizado';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import {
  OptionResponseProps,
  VariacaoProps,
} from 'components/Modal/ModalAdicionarVariacao';
import { ListImagensResponseProps } from 'pages/Produtos/Formulario/TabsContent/Imagens/ListItemImagem';
import { ProdutoPrecoLoja } from 'types/produto';
import { ProdutosVariacaoProps } from 'pages/Produtos/Formulario/TabsContent/Variacoes';
import { getVariacoesCores } from 'pages/Produtos/Formulario/FunctionsCadastroProduto/ObterCoresCadastradas';
import { InformacoesProdutos } from 'pages/Produtos/Formulario/Types';
import { StepProps } from 'pages/Produtos/Formulario/TabsContent/ProdutoEtapa/hooks';
import { GridPaginadaRetorno } from 'components/Grid/Paginacao';

export type KitProps = {
  id: string;
  produtoDescricao: string;
  corDescricao: string;
  tamanhoDescricao: string;
  produtoCorTamanhoPrincipalId: string;
  produtoCorTamanhoItemId: string;
  quantidade: number;
  valor: number;
  precoCusto: number;
  precoOriginal: number;
  produtoCorTamanho: {
    produtoCorTamanhoId: string;
    produto: string;
    cor: string;
    tamanho: string;
    imagem: string;
  };
  produtoCorTamanhoKitPrecoLojas: {
    produtoCorTamanhoKitId: string;
    lojaId: string;
    precoVenda: {
      precoVenda: number;
      markup: number;
    };
  }[];
};

export type SelectProps = {
  label: string;
  value: unknown;
  volumeUnitario?: boolean;
};

type LojasProps = {
  id: string;
  lojaRazao: string;
};

type PrecoPorLojaProps = {
  id: string;
  lojaId: string;
  lojaRazao: string;
  precoCusto?: number;
  precoCompra?: number;
  markup?: number | string;
  precoVenda?: number;
};

export type LojaProps = {
  id?: string;
  produtoId: string;
  lojaId: string;
  fantasia: string;
  cidade: string;
  endereco: string;
  precoCompra: number;
  precoCusto: number;
  precoVenda: {
    precoVenda: number;
    markup: number;
  };
};

export interface TabelaPrecoProps extends LojaProps {
  tabelaPrecoId?: string;
  tabelaPreco: string;
  isTabelaPreco: boolean;
  padraoSistema: boolean;
}

export type InformacoesModalPrecoPorLoja = {
  lojas: LojasProps[];
  precoPorLoja: PrecoPorLojaProps[];
  valoresAlterados: boolean;
  tipoProduto?: number;
};

type LojaEnderecoProps = {
  fantasia: string;
  cidade: string;
  endereco: string;
  id: string;
};

export interface ProdutoRefProps {
  onClick: () => Promise<boolean>;
}
interface ProdutosFormularioContextParentProps {
  isLoading: boolean;
  readonly?: boolean;
  action: 'cadastrar' | 'alterar' | 'visualizar';
  cadastroExterno: boolean;
  camposPersonalizados?: CampoPersonalizadoInterface[];
  setImagemFoiAlterada?: React.Dispatch<React.SetStateAction<boolean>>;
  produtoId?: string;
  isDuplicado?: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  valueProdutoXlm?: InformacoesProdutos;
}

export declare type TipoProdutoType = 1 | 2 | 3;

export type FieldArrayType = {
  tabelaPrecoProdutoCorTamanhos: VariacaoProps;
};
interface ProdutosFormularioContextProps
  extends ProdutosFormularioContextParentProps {
  unidadesMedidaOptions: SelectProps[];
  unidadesMedidaOptionsIsLoading: boolean;
  tipoProduto: number;
  nome: string;
  setNome: React.Dispatch<React.SetStateAction<string>>;
  unidadeDefaultValue?: string;
  ref: React.RefObject<ProdutoRefProps>;
  idProduto: string;
  setCampoObrigatorioVazio: React.Dispatch<React.SetStateAction<boolean>>;
  campoObrigatorioVazio: boolean;
  lojas: LojaEnderecoProps[];
  tabelaPreco: OptionResponseProps[];
  setIdProduto: React.Dispatch<React.SetStateAction<string>>;
  listImagens: ListImagensResponseProps[];
  setListImagens: React.Dispatch<
    React.SetStateAction<ListImagensResponseProps[]>
  >;
  setDataHoraUltimaAlteracao: React.Dispatch<React.SetStateAction<string>>;
  dataHoraUltimaAlteracao: string;
  isEcommerceAtivo: boolean;
  setIsEcommerceAtivo: React.Dispatch<React.SetStateAction<boolean>>;
  precoPorLoja: ProdutoPrecoLoja[];
  inserirImagemPrincipal: (newListImagens: ListImagensResponseProps[]) => void;
  setPrecoPorLoja: React.Dispatch<React.SetStateAction<ProdutoPrecoLoja[]>>;
  listKits: KitProps[];
  setListKits: React.Dispatch<React.SetStateAction<KitProps[]>>;
  hasVariation: () => Promise<boolean>;
  handleAtualizarImagemPrincipal: (
    itemImagem: ListImagensResponseProps
  ) => Promise<void>;
  formFields: UseFieldArrayReturn<
    FieldArrayType,
    'tabelaPrecoProdutoCorTamanhos',
    'id'
  >;
  formIsDirty: boolean;
  onChangeFormIsDirty: (value: boolean) => void;
  hasKit: () => Promise<boolean>;
  hasNcm: boolean;
  setHasNcm: React.Dispatch<React.SetStateAction<boolean>>;
  hasTechnicalSheet: () => Promise<boolean>;
  hasStep: () => Promise<boolean>;
}

export const ProdutosFormularioContext = createContext<ProdutosFormularioContextProps>(
  {} as ProdutosFormularioContextProps
);

interface ProdutosFormularioProviderProps
  extends ProdutosFormularioContextParentProps {
  children: ReactNode;
}

export default function ProdutosFormularioProvider({
  children,
  action,
  isLoading,
  readonly,
  cadastroExterno,
  camposPersonalizados,
  setImagemFoiAlterada,
  produtoId,
  isDuplicado,
  setIsLoading,
  valueProdutoXlm,
}: ProdutosFormularioProviderProps): JSX.Element {
  const { watch, setValue, getValues } = useFormContext();

  const ref = useRef<ProdutoRefProps>(null);

  const [nome, setNome] = useState('');
  const [idProduto, setIdProduto] = useState('');
  const [tabelaPreco, setTabelaPreco] = useState<OptionResponseProps[]>([]);
  const [campoObrigatorioVazio, setCampoObrigatorioVazio] = useState(true);
  const [produtoLoading, setProdutoLoading] = useState(false);
  const [lojas, setLojas] = useState<LojaEnderecoProps[]>([]);
  const [listKits, setListKits] = useState<KitProps[]>([]);
  const [precoPorLoja, setPrecoPorLoja] = useState<ProdutoPrecoLoja[]>([]);
  const [dataHoraUltimaAlteracao, setDataHoraUltimaAlteracao] = useState('');
  const [unidadeDefaultValue, setUnidadeDefaultValue] = useState('');
  const [isEcommerceAtivo, setIsEcommerceAtivo] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [listImagens, setListImagens] = useState<ListImagensResponseProps[]>(
    []
  );
  const [hasNcm, setHasNcm] = useState(false);
  const [unidadesMedidaOptions, setUnidadesMedidaOptions] = useState<
    SelectProps[]
  >([]);
  const [
    unidadesMedidaOptionsIsLoading,
    setUnidadesMedidaOptionsIsLoading,
  ] = useState(true);

  const tipoProduto = watch('tipoProduto') as number;

  const formFields = useFieldArray<FieldArrayType>({
    name: 'tabelaPrecoProdutoCorTamanhos',
  });

  const getLojas = useCallback(async () => {
    setProdutoLoading(true);
    const responseLojas = await api.get<void, ResponseApi<LojaEnderecoProps[]>>(
      ConstanteEnderecoWebservice.LISTAR_LOJA_COM_CONTAS_FINANCEIRAS
    );

    if (responseLojas) {
      if (responseLojas.avisos) {
        responseLojas.avisos.forEach((item: string) => toast.warning(item));
      }

      if (responseLojas.sucesso) {
        setLojas(responseLojas.dados);
        setProdutoLoading(false);
      }
      setProdutoLoading(false);
    }
    setProdutoLoading(false);
  }, []);

  const tamanhosCadastrados = useCallback(async () => {
    if (!idProduto) {
      return null;
    }
    const response = await api.get<void, ResponseApi<ProdutosVariacaoProps[]>>(
      `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/tamanhos`
    );

    return response;
  }, [idProduto]);

  const getDataNcm = useCallback(async () => {
    if (!idProduto) {
      return;
    }
    setIsLoading(true);
    const response = await getCadastroFiscalProdutos(idProduto);

    if (response) {
      if (
        valueProdutoXlm &&
        (valueProdutoXlm.ncm || valueProdutoXlm.codigoCest) &&
        !response?.dados?.ncmLabel
      ) {
        const dataForms = getValues();
        const codigoCestApenasNumeros =
          dataForms?.codigoCest?.replace(/\D/g, '') ?? '';
        const responseCadastroFiscal = await api.patch<
          void,
          ResponseApi<boolean>
        >(
          `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/fiscais`,
          {
            ...response.dados,
            produtoId: idProduto,
            codigoNcm: dataForms?.codigoNcm?.value,
            ncmLabel: dataForms?.codigoNcm?.label,
            codigoCest: codigoCestApenasNumeros,
          }
        );
        if (responseCadastroFiscal && responseCadastroFiscal.sucesso) {
          setHasNcm(true);
        }
      }

      if (response.sucesso && response.dados.ncmLabel) {
        setHasNcm(true);
      }
    }
  }, [idProduto, setIsLoading, valueProdutoXlm, getValues]);

  const getTabelaPreco = useCallback(async () => {
    setProdutoLoading(true);
    const response = await api.get<void, ResponseApi<OptionResponseProps[]>>(
      ConstanteEnderecoWebservice.TABELA_PRECO_LISTAR_POR_LOJA
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso) {
        setTabelaPreco(response.dados);
        setProdutoLoading(false);
      }
      setProdutoLoading(false);
    }
    setProdutoLoading(false);
  }, []);

  const handleGetUnidadesMedidaOptions = useCallback(async () => {
    setUnidadesMedidaOptionsIsLoading(true);

    const response = await api.get<void, ResponseApi<OptionTypeBase[]>>(
      ConstanteEnderecoWebservice.UNIDADE_MEDIDA_LISTAR_SELECT,
      { params: { statusConsulta: StatusConsultaEnum.ATIVOS } }
    );

    if (response?.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (response?.sucesso) {
      setUnidadesMedidaOptions(
        response.dados.map((unidadeMedida) => {
          if (unidadeMedida.nome.toUpperCase().includes('UNIDADE')) {
            setValue('unidadeTributavel', unidadeMedida.id);
            setUnidadeDefaultValue(unidadeMedida.id);
          }
          return {
            label: unidadeMedida.nome,
            value: unidadeMedida.id,
            volumeUnitario: unidadeMedida.volumeUnitario,
          };
        })
      );
    } else setUnidadesMedidaOptions([]);

    setUnidadesMedidaOptionsIsLoading(false);
  }, [setValue]);

  const handleAtualizarImagemPrincipal = useCallback(
    async (itemImagem: ListImagensResponseProps) => {
      const response = await api.patch<void, ResponseApi<boolean>>(
        `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/produto-cores/${itemImagem.produtoCorId}/imagens/${itemImagem.id}/principal`
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }

        if (response.sucesso) {
          setListImagens((prev) => {
            return prev.map((item) => ({
              ...item,
              principal: item.imagem === itemImagem.imagem,
            }));
          });
          toast.success('Imagem principal alterada com sucesso');
        }
      }
    },
    [idProduto, setListImagens]
  );

  const inserirImagemPrincipal = useCallback(
    (newListImagens: ListImagensResponseProps[]) => {
      const hasImagemPrincipal = newListImagens.some(
        (imagemItem) => imagemItem.principal
      );

      if (!hasImagemPrincipal && newListImagens.length > 0) {
        handleAtualizarImagemPrincipal(newListImagens[0]);
      }
    },
    [handleAtualizarImagemPrincipal]
  );

  const hasVariation = useCallback(async () => {
    if (!idProduto) {
      return false;
    }
    const cores = await getVariacoesCores(idProduto);

    const response = await tamanhosCadastrados();

    const newCores = (cores || []).map((itemCores) => ({
      value: itemCores.cor.id,
      label: itemCores.cor.descricao,
      ativo: itemCores.ativo,
    }));

    const newTamanhos = (response?.dados || [])
      .filter((item) => item.padraoSistema === false)
      .map((itemTamanho) => ({
        value: itemTamanho.id,
        label: itemTamanho.descricao,
      }));

    return newTamanhos.length > 0 || newCores.length > 0;
  }, [idProduto, tamanhosCadastrados]);

  const onChangeFormIsDirty = useCallback((value: boolean) => {
    setFormIsDirty(value);
  }, []);

  const hasKit = useCallback(async () => {
    if (!idProduto) {
      return false;
    }

    const response = await api.get<void, ResponseApi<KitProps[]>>(
      `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/kits`
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }
      if (response.sucesso && response.dados) {
        const newKits = (response.dados || []).map((kitItem) => ({
          id: kitItem.id,
        }));

        return newKits.length > 0;
      }
    }

    return false;
  }, [idProduto]);

  const hasStep = useCallback(async () => {
    if (!idProduto) {
      return false;
    }

    const response = await api.get<void, ResponseApi<StepProps[]>>(
      ConstanteEnderecoWebservice.PRODUTO_ETAPA.replace('id', idProduto)
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }
      if (response?.sucesso && response?.dados) {
        return response.dados?.length > 0;
      }
    }

    return false;
  }, [idProduto]);

  const hasTechnicalSheet = useCallback(async () => {
    if (!idProduto) {
      return false;
    }

    const response = await api.get<void, ResponseApi<GridPaginadaRetorno>>(
      ConstanteEnderecoWebservice.PRODUTOS_FICHA_TECNICA.replace(
        'id',
        idProduto
      )
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }
      if (response?.sucesso && response?.dados) {
        return response.dados.total > 0;
      }
    }

    return false;
  }, [idProduto]);

  useEffect(() => {
    handleGetUnidadesMedidaOptions();
  }, [handleGetUnidadesMedidaOptions]);

  useEffect(() => {
    getLojas();
  }, [getLojas]);

  useEffect(() => {
    getTabelaPreco();
  }, [getTabelaPreco]);

  useEffect(() => {
    if (tipoProduto === TipoProdutoEnum.PRODUTO_KIT) {
      setTabelaPreco([]);
    }
  }, [tipoProduto]);

  useEffect(() => {
    getDataNcm();
  }, [getDataNcm]);

  return (
    <ProdutosFormularioContext.Provider
      value={{
        isLoading,
        idProduto,
        dataHoraUltimaAlteracao,
        setDataHoraUltimaAlteracao,
        lojas,
        readonly,
        action,
        unidadesMedidaOptions,
        unidadesMedidaOptionsIsLoading,
        hasVariation,
        tipoProduto,
        valueProdutoXlm,
        campoObrigatorioVazio,
        setIdProduto,
        setCampoObrigatorioVazio,
        isEcommerceAtivo,
        setIsEcommerceAtivo,
        hasNcm,
        setHasNcm,
        cadastroExterno,
        formFields,
        handleAtualizarImagemPrincipal,
        camposPersonalizados,
        setImagemFoiAlterada,
        setIsLoading,
        listKits,
        setListKits,
        inserirImagemPrincipal,
        listImagens,
        setListImagens,
        produtoId,
        precoPorLoja,
        setPrecoPorLoja,
        ref,
        nome,
        setNome,
        unidadeDefaultValue,
        isDuplicado,
        tabelaPreco,
        formIsDirty,
        onChangeFormIsDirty,
        hasTechnicalSheet,
        hasStep,
        hasKit,
      }}
    >
      {(isLoading || produtoLoading) && <LoadingPadrao />}
      {children}
    </ProdutosFormularioContext.Provider>
  );
}

export function useProdutosFormularioContext(): ProdutosFormularioContextProps {
  const context = useContext(ProdutosFormularioContext);

  if (!context)
    throw new Error(
      'useProdutosFormularioContext must be used within a ProdutosFormularioProvider.'
    );

  return context;
}
