import { useCallback, useEffect } from 'react';
import { Text, Box, Flex, useMediaQuery } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { useMetasComissoesEtapasContext } from 'store/MetasComissoes/MetasComissoesEtapas';
import { IdentificacaoEtapasMetasComissoes } from 'constants/enum/identificacaoEtapasMetasComissoes';
import api, { ResponseApi } from 'services/api';

import { NumberInput } from 'components/update/Input/NumberInput';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { FooterMetasComissoes } from '../FooterMetasComissoes';

export const EscolherValorMeta = () => {
  const {
    setActiveStep,
    obterValorMetaGeral,
    setObterValorMetaLoja,
    isLoading,
    obterValorMetaLoja,
    atualizarValoresRegraPagamento,
    setIsLoading,
  } = useMetasComissoesEtapasContext();
  const { watch, setValue } = useFormContext();
  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

  const valorMetaGeral = watch('valorMetaGeral');

  const cadastrarValorMetaGeral = useCallback(async () => {
    const response = await api.post<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.CADASTRAR_VALOR_META_GERAL,
      {
        valorMetaGeral,
      }
    );
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
        return false;
      }
      if (response.sucesso) {
        setObterValorMetaLoja(() => ({
          valorMetaGeral,
          id: response.dados,
        }));
        return true;
      }
    }
    return false;
  }, [setObterValorMetaLoja, valorMetaGeral]);

  const alterarValorMetaGeral = useCallback(async () => {
    const response = await api.patch<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.ALTERAR_VALOR_META_GERAL,
      {
        id: obterValorMetaLoja?.id,
        valorMetaGeral,
      }
    );
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
        return false;
      }
      if (response.sucesso) {
        return true;
      }
    }
    return false;
  }, [obterValorMetaLoja, valorMetaGeral]);

  const handleRegraRepasse = useCallback(async () => {
    const valueRegraRepasse = await atualizarValoresRegraPagamento();

    setValue('tipoRegraPagComissao', valueRegraRepasse);
  }, [atualizarValoresRegraPagamento, setValue]);

  const handleAvancar = async () => {
    await setIsLoading(true);

    let sucesso = false;

    if (obterValorMetaLoja === undefined) {
      const cadastrarSucesso = await cadastrarValorMetaGeral();
      sucesso = cadastrarSucesso;
    } else {
      const alterarSucesso = await alterarValorMetaGeral();
      sucesso = alterarSucesso;
    }

    if (sucesso) {
      handleRegraRepasse();
      await obterValorMetaGeral();
      setActiveStep(IdentificacaoEtapasMetasComissoes.ESCOLHER_META_VENDEDORES);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setValue('valorMetaGeral', obterValorMetaLoja?.valorMetaGeral || 0);
  }, [obterValorMetaLoja, setValue]);

  return (
    <>
      {isLoading && <LoadingPadrao />}
      <Box
        borderRadius="md"
        boxShadow="0px 0px 6px #00000034"
        w="full"
        h={isLargerThan700 ? '255px' : '300px'}
        bg="white"
      >
        <Flex
          alignItems="center"
          borderRadius="5px 5px 0 0"
          bg="primary.500"
          h="48px"
        >
          <Text ml="24px" fontSize="16px" color="secondary.300">
            Meta geral da loja
          </Text>
        </Flex>
        <Box
          mt="22px"
          pl={isLargerThan700 ? '24px' : '10px'}
          pr={isLargerThan700 ? '24px' : '10px'}
        >
          <Text mb="6px" color="purple.500" fontSize="14px">
            Informe o valor que será utilizado como meta geral de vendas para
            esta loja. (meta mensal, somando todos os vendedores).
          </Text>
          <Flex
            w="full"
            h="120px"
            bg="gray.50"
            borderRadius="5px"
            alignItems="center"
            border="1px solid #CCCCCC"
            pl={isLargerThan700 ? '40px' : '10px'}
            pr={isLargerThan700 ? '0' : '10px'}
          >
            <NumberInput
              w="240px"
              id="valorMetaGeral"
              editarFundoLeftElemento
              bgLeftElement="gray.50"
              leftElement="R$"
              scale={2}
              colSpan={3}
              name="valorMetaGeral"
            />
          </Flex>
        </Box>
      </Box>
      <FooterMetasComissoes
        isDisabledAvancar={isLoading}
        handleAvancar={handleAvancar}
      />
    </>
  );
};
