import React from 'react';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

import UsuarioListar from '../pages/Usuarios/Listar';
import UsuarioCadastrar from '../pages/Usuarios/Formulario/Cadastrar';
import UsuarioAlterar from '../pages/Usuarios/Formulario/Alterar';
import UsuarioVisualizar from '../pages/Usuarios/Formulario/Visualizar';

const UsuarioRoutes = [
  <LayoutGuard
    key="usuario-listar"
    permissaoFuncionalidade={ConstanteFuncionalidades.USUARIO_LISTAR}
    component={UsuarioListar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Usuário',
      },
    ]}
    path={ConstanteRotas.USUARIO}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="usuario-cadastrar"
    permissaoFuncionalidade={ConstanteFuncionalidades.USUARIO_CADASTRAR}
    component={UsuarioCadastrar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Usuário',
        path: ConstanteRotas.USUARIO,
      },
      {
        title: 'Novo',
      },
    ]}
    path={ConstanteRotas.USUARIO_CADASTRAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="usuario-alterar"
    permissaoFuncionalidade={ConstanteFuncionalidades.USUARIO_ALTERAR}
    component={UsuarioAlterar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Usuário',
        path: ConstanteRotas.USUARIO,
      },
      {
        title: 'Editar',
      },
    ]}
    path={ConstanteRotas.USUARIO_ALTERAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="usuario-visualizar"
    permissaoFuncionalidade={ConstanteFuncionalidades.USUARIO_VISUALIZAR}
    component={UsuarioVisualizar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Usuário',
        path: ConstanteRotas.USUARIO,
      },
      {
        title: 'Visualizar',
      },
    ]}
    path={ConstanteRotas.USUARIO_VISUALIZAR}
    exact
    meta={{ auth: true }}
  />,
];

export default UsuarioRoutes;
