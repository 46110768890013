import { ZoopEtapasProvider } from 'store/Zoop/ZoopEtapasContext';

import { Comercial } from './Etapas/Comercial';

export const Zoop = () => {
  return (
    <ZoopEtapasProvider>
      <Comercial />
    </ZoopEtapasProvider>
  );
};
