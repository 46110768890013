import styled from 'styled-components';

import { SalvarInserirNovoIcon } from 'icons';

export const CreatableInputContainer = styled.div`
  color: var(--sti-ck-colors-blue-500);
  margin: -8px -12px -8px -40px;
  padding: 8px 12px 8px 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const CreatableInputIcon = styled(SalvarInserirNovoIcon)`
  margin-right: 5px;
`;

export const CreatableInputText = styled.div`
  height: 100%;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  line-height: 0.95;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const control = (base: any, state: any) => ({
  ...base,
  cursor: 'pointer',
  borderRadius: 3,
  border: state.isFocused
    ? '1px solid var(--sti-ck-colors-primary-50)'
    : '1px solid var(--sti-ck-colors-gray-100)',
  '&:hover': {
    borderColor: state.isFocused
      ? 'var(--sti-ck-colors-primary-50)'
      : 'var(--sti-ck-colors-gray-100)',
  },
  boxShadow: state.isFocused ? 'var(--focus-shadow)' : 'unset',
  display: 'flex',
  alignItems: 'center',
  minHeight: '35px',
});

export const input = (base: any) => ({
  ...base,
});

export const menu = (base: any) => ({
  ...base,
  borderRadius: '0 0 3px 3px',
  hyphens: 'auto',
  marginTop: 1,
  textAlign: 'left',
  wordWrap: 'break-word',
  zIndex: 2,
});

export const option = (base: any, state: any) => ({
  ...base,
  cursor: 'pointer',
  position: 'relative',
  paddingLeft: '40px',
  color: 'var(--black)',
  backgroundColor: 'var(--white)',
  '&:hover': {
    backgroundColor: 'var(--sti-ck-colors-gray-50)',
  },
  '&:after': {
    content: state.isSelected ? '""' : 'none',
    position: 'absolute',
    left: '18px',
    top: '50%',
    transform: 'translateY(-50%) rotate(45deg)',
    width: '5px',
    height: '10px',
    border: 'solid var(--black)',
    borderWidth: '0 1px 1px 0',
  },
});

export const indicatorsContainer = (base: any) => ({
  ...base,
  maxHeight: '33px',
});

export const clearIndicator = (base: any) => ({
  ...base,
  cursor: 'pointer',
});

export const dropdownIndicator = (base: any) => ({
  ...base,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const indicatorSeparator = (base: any) => ({
  ...base,
  display: 'none',
  width: '0',
});

export const loadingIndicator = (base: any) => ({
  ...base,
  color: 'var(--sti-ck-colors-primary-50)',
});

export const container = (base: any) => ({
  ...base,
  maxHeight: '35px',
});

export const valueContainer = (base: any) => ({
  ...base,
});

export const selectStyles = {
  control,
  input,
  menu,
  option,
  indicatorsContainer,
  clearIndicator,
  dropdownIndicator,
  indicatorSeparator,
  loadingIndicator,
  container,
  valueContainer,
};
