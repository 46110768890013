import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Button,
  Divider,
  Flex,
  Icon,
  Box,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';

import { useProdutoCategoriasProdutoContext } from 'store/Produtos/ProdutosCategoriasProduto';

import { SalvarConfirmarIcon } from 'icons';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';

import ItemsContainer from './ItemsContainer';

interface ModalCategoriasProdutoResponse {
  isOpen: boolean;
  onClose: () => void;
  handleOnSubmit: (categoriaSelecionada: string) => void;
}

export const CategoriasProdutoModal = ({
  isOpen = false,
  onClose,
  handleOnSubmit,
}: ModalCategoriasProdutoResponse) => {
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const formMethods = useForm();

  const {
    categoriaSelecionada,
    resetCategoriaSelecionada,
  } = useProdutoCategoriasProdutoContext();

  useEffect(() => {
    resetCategoriaSelecionada();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <ModalPadraoChakra
      size="full"
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={false}
    >
      <ModalContent
        bg="gray.50"
        borderRadius={isLargerThan900 ? 'md' : '0'}
        marginBottom={isLargerThan900 ? '3.75rem' : '0'}
        marginTop={isLargerThan900 ? '3.75rem' : '0'}
        w={isLargerThan900 ? '680px' : 'full'}
        h={isLargerThan900 ? undefined : 'full'}
      >
        <ModalHeader>
          <Text color="primary.50" fontSize="md">
            Selecionar categoria
          </Text>
          <Text color="gray.700" fontSize="xs">
            Para confirmar clique na categoria, que seja de último nível,
            desejada e então clique no botão de selecionar.
          </Text>
          <ModalCloseButton />
          <Divider mt="20px" />
        </ModalHeader>
        <ModalBody>
          <FormProvider {...formMethods}>
            <Box w="full">
              <ItemsContainer nivel={1} />
            </Box>
          </FormProvider>
        </ModalBody>
        <ModalFooter>
          <Box w="full">
            <Divider mb="20px" />
            <Flex
              alignItems="center"
              w="full"
              justifyContent={['center', 'center', 'flex-end']}
              direction={['column', 'column', 'row']}
            >
              <Button
                id="fecharCategoriasProdutoModal"
                name="fechar"
                variant="outline"
                borderRadius="4px"
                onClick={onClose}
                mr={['0', '0', '10px']}
                mb={['10px', '10px', '0']}
                w={['full', 'full', '120px']}
              >
                Fechar
              </Button>

              <Button
                id="selecionarCategoriasProdutoModal"
                name="selecionar"
                borderRadius="4px"
                leftIcon={<Icon as={SalvarConfirmarIcon} />}
                colorScheme="aquamarine"
                variant="solid"
                onClick={() => handleOnSubmit(categoriaSelecionada)}
                w={['full', 'full', '140px']}
              >
                Selecionar
              </Button>
            </Flex>
          </Box>
        </ModalFooter>
      </ModalContent>
    </ModalPadraoChakra>
  );
};
