import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import React from 'react';

interface ModalAlertaProps {
  textoConfirmar?: string;
  textoCabecalho?: string;
  textoMensagem?: string;
  onConfirm?: () => void;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalAlerta = ({
  textoConfirmar = 'OK',
  textoCabecalho = 'Aviso',
  textoMensagem,
  onConfirm,
  isOpen,
  setIsOpen,
}: ModalAlertaProps) => {
  const cancelRef = React.useRef() as any;

  return (
    <AlertDialog
      allowPinchZoom
      leastDestructiveRef={cancelRef}
      isOpen={isOpen}
      onClose={() => {}}
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {textoCabecalho}
          </AlertDialogHeader>

          <AlertDialogBody>{textoMensagem}</AlertDialogBody>

          <AlertDialogFooter>
            <Button
              colorScheme="green"
              onClick={() => {
                if (onConfirm) onConfirm();

                setIsOpen(false);
              }}
              ml={3}
            >
              {textoConfirmar}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default ModalAlerta;
