import React from 'react';
import { Text, Button } from '@chakra-ui/react';

import { SimpleCard } from 'components/update/Form/SimpleCard';

interface ResolucaoNaoCompativelProps {
  handleVoltar: () => void;
  width?: number;
  height?: number;
  content?: string;
}

export function ResolucaoNaoCompativel({
  handleVoltar,
  width = 800,
  height = 600,
  content,
}: ResolucaoNaoCompativelProps) {
  return (
    <SimpleCard>
      <Text fontSize="lg" fontWeight="semibold" color="primary.50">
        Resolução não compatível
      </Text>
      <Text fontSize="sm" mt="2">
        {content ||
          `Para fazer o lançamento de produtos no estoque acesse esta tela através
        de um dispositivo com resolução mínima de ${width}x${height} pixels.`}
      </Text>

      <Button
        minW="96px"
        colorScheme="aquamarine"
        borderRadius="md"
        mt="6"
        onClick={handleVoltar}
      >
        Voltar
      </Button>
    </SimpleCard>
  );
}
