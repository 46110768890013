import {
  PagamentosDinheiroIcon,
  PagamentosChequeIcon,
  PagamentosLinkIcon,
  PagamentosCartaoCreditoIcon,
  PagamentosCartaoDebitoIcon,
  PagamentosCrediarioIcon,
  PagamentosValeRefeicaoIcon,
  PagamentosValePresenteIcon,
  PagamentosBoletoIcon,
  PagamentosDepositoIcon,
  PagamentosPixIcon,
  PixZoopIcon,
  BoletoZoopIcon,
  PagamentosTransferenciaBancariaIcon,
  PagamentosPontoFidelidadeIcon,
  ConnectStoneCreditoIcon,
  ConnectStoneDebitoIcon,
  PagamentosValeAlimentacaoIcon,
} from 'icons';

export type MeioPagamento =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 11
  | 12
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23
  | 24
  | 25
  | 26
  | 27
  | 28
  | 29
  | 30
  | 31;

const MeioPagamentoEnum = {
  Dinheiro: 1,
  Cheque: 2,
  CartaoCreditoLink: 3,
  CartaoCreditoMaquina: 4,
  CartaoDebito: 5,
  CreditoLoja: 6,
  ValeAlimentacao: 7,
  ValePresente: 8,
  BoletoBancario: 9,
  DepositoBancario: 11,
  PixPorLink: 12,
  TransferenciaBancaria: 17,
  ProgramaFidelidade: 18,
  CartaoCreditoSmartPos: 19,
  CartaoDebitoSmartPos: 20,
  PixSmartPos: 21,
  CartaoCreditoStone: 22,
  CartaoDebitoStone: 23,
  PixPresencialZoop: 24,
  PixEnvioZoop: 25,
  BoletoZoop: 26,
  PixEstatico: 27,
  CreditoEmLoja: 28,
  ValeRefeicao: 29,
  ValeAlimentacaoSmartPos: 30,
  ValeRefeicaoSmartPos: 31,

  properties: {
    1: { name: 'Dinheiro', value: 1, icon: PagamentosDinheiroIcon },
    2: { name: 'Cheque', value: 2, icon: PagamentosChequeIcon },
    3: { name: 'Cartão de crédito link', value: 3, icon: PagamentosLinkIcon },
    4: {
      name: 'Cartão de crédito máquina',
      value: 4,
      icon: PagamentosCartaoCreditoIcon,
    },
    5: { name: 'Cartão de débito', value: 5, icon: PagamentosCartaoDebitoIcon },
    6: {
      name: 'Crediários e cartão da loja',
      value: 6,
      icon: PagamentosCrediarioIcon,
    },
    7: {
      name: 'Vale alimentação',
      value: 7,
      icon: PagamentosValeAlimentacaoIcon,
    },
    8: { name: 'Vale presente', value: 8, icon: PagamentosValePresenteIcon },
    9: { name: 'Boleto bancário', value: 9, icon: PagamentosBoletoIcon },
    11: { name: 'Depósito bancário', value: 11, icon: PagamentosDepositoIcon },
    12: {
      name: 'Pagamento instantâneo (PIX)',
      value: 12,
      icon: PagamentosPixIcon,
    },
    17: {
      name: 'Transferência bancária',
      value: 17,
      icon: PagamentosTransferenciaBancariaIcon,
    },
    18: {
      name: 'Programa de fidelidade, cashback, crédito virtual',
      value: 18,
      icon: PagamentosPontoFidelidadeIcon,
    },
    19: {
      name: 'Cartão de crédito - SmartPOS',
      value: 19,
      icon: PagamentosCartaoCreditoIcon,
    },
    20: {
      name: 'Cartão de débito - SmartPOS',
      value: 20,
      icon: PagamentosCartaoDebitoIcon,
    },
    21: {
      name: 'PIX - SmartPOS',
      value: 21,
      icon: PagamentosPixIcon,
    },
    22: {
      name: 'Connect Stone - Cartão de crédito',
      value: 22,
      icon: ConnectStoneCreditoIcon,
    },
    23: {
      name: 'Connect Stone - Cartão de débito',
      value: 23,
      icon: ConnectStoneDebitoIcon,
    },
    24: {
      name: `PIX Presencial - Zoop`,
      value: 24,
      icon: PixZoopIcon,
    },
    25: {
      name: `PIX Envio - Zoop`,
      value: 25,
      icon: PixZoopIcon,
    },
    26: {
      name: `Boleto - Zoop`,
      value: 26,
      icon: BoletoZoopIcon,
    },
    27: {
      name: `Pagamento instantâneo (PIX Estático)`,
      value: 27,
      icon: PagamentosPixIcon,
    },
    28: {
      name: `Crédito em loja`,
      value: 28,
      icon: PagamentosCrediarioIcon,
    },
    29: {
      name: `Vale refeição`,
      value: 29,
      icon: PagamentosValeRefeicaoIcon,
    },
    30: {
      name: `Vale alimentação - SmartPOS`,
      value: 30,
      icon: PagamentosValeAlimentacaoIcon,
    },
    31: {
      name: `Vale refeição - SmartPOS`,
      value: 31,
      icon: PagamentosValeRefeicaoIcon,
    },
  },
};

export const MeioPagamentosTEF = {
  CartaoCreditoLink: 3,
  CartaoCreditoMaquina: 4,
  CartaoDebito: 5,
  PixPorLink: 12,
};

export const meioPagamentoOptions = Object.entries(
  MeioPagamentoEnum.properties
).map((valueNameObject) => {
  return {
    label: valueNameObject[1].name,
    value: valueNameObject[1].value,
  };
});

export default MeioPagamentoEnum;
