import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  memo,
  useRef,
} from 'react';
import { toast } from 'react-toastify';
import { Input, Button, Icon, Text } from '@chakra-ui/react';
import { FiCheck, FiXCircle } from 'react-icons/fi';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';
import { useCategoriasProdutoContext } from 'store/CategoriasProduto';

import ClickForaPadrao from 'components/Layout/ClickFora/ClickForaPadrao';
import ManterFoco from 'components/Geral/ManterFoco';

import { SideContainer } from '../components/SideContainer';
import { CategoryProps } from '../ModalEditarCategoria/types';

interface ItemAdicionandoContentProps {
  categoriaProdutoPaiId?: string;
  ativo: boolean;
  setAdicionando: Dispatch<SetStateAction<boolean>>;
}

export const ItemAdicionandoContent = memo(
  ({
    categoriaProdutoPaiId,
    ativo,
    setAdicionando,
  }: ItemAdicionandoContentProps) => {
    const {
      setIsLoading,
      categoriasProduto,
      setCategoriasProduto,
    } = useCategoriasProdutoContext();

    const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

    const handlePostCategoriaProduto = useCallback(
      async (nome: string) => {
        setIsLoading(true);

        const data = {
          nome,
          categoriaProdutoPaiId,
          ativo,
        };

        const response = await api.post<void, ResponseApi>(
          ConstanteEnderecoWebservice.CATEGORIA_PRODUTO_CADASTRAR,
          data
        );

        if (response.sucesso) {
          setAdicionando(false);

          if (response.dados && typeof response.dados === 'string') {
            const novaCategoria: CategoryProps = {
              id: response.dados,
              nome: data.nome,
              categoriaProdutoPaiId: data.categoriaProdutoPaiId,
              ativo: data.ativo,
            };
            setCategoriasProduto([novaCategoria, ...categoriasProduto]);
          }
        }

        if (response.avisos) {
          response.avisos.map((aviso: string) => toast.warning(aviso));
        }

        setIsLoading(false);
      },
      [
        ativo,
        categoriaProdutoPaiId,
        categoriasProduto,
        setAdicionando,
        setCategoriasProduto,
        setIsLoading,
      ]
    );

    const handleSubmit = useCallback(() => {
      const inputValue = inputRef.current.value;

      if (!inputValue) {
        toast.warning(
          '  O nome da categoria de produto precisa ser preenchido.'
        );

        return;
      }

      handlePostCategoriaProduto(inputValue);
    }, [handlePostCategoriaProduto, inputRef]);

    return (
      <ClickForaPadrao onClose={() => setAdicionando(false)}>
        <ManterFoco style={{ display: 'flex', width: '100%' }}>
          <SideContainer style={{ width: '100%' }}>
            <Input
              ref={inputRef}
              variant="unstyled"
              id="categoriaProduto-cadastro-nome"
              maxLength={40}
              onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') handleSubmit();
              }}
              autoFocus
            />
          </SideContainer>

          <SideContainer>
            <Button
              variant="linkDefault"
              fontSize="12px"
              mt="2px"
              colorScheme="none"
              id="salvar"
              color="black"
              onClick={() => handleSubmit()}
            >
              <Icon mr="5px" as={FiCheck} fontSize="16px" />
              <Text whiteSpace="nowrap">Enter para confirmar</Text>
            </Button>

            <Button
              variant="linkDefault"
              mt="2px"
              colorScheme="none"
              onClick={() => setAdicionando(false)}
            >
              <Icon color="red.400" as={FiXCircle} fontSize="18px" />
            </Button>
          </SideContainer>
        </ManterFoco>
      </ClickForaPadrao>
    );
  }
);

interface ItemEditandoContentProps {
  categoriaProduto: CategoryProps;
  setEditando: Dispatch<SetStateAction<boolean>>;
  handlePutCategoriaProduto: (
    categoriaProduto: CategoryProps,
    getCategorias?: boolean
  ) => Promise<void>;
}

export const ItemEditandoContent = memo(
  ({
    categoriaProduto,
    setEditando,
    handlePutCategoriaProduto,
  }: ItemEditandoContentProps) => {
    const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

    const handleSubmit = useCallback(() => {
      const inputValue = inputRef.current.value;

      if (!inputValue) {
        toast.warning('O nome da categoria de produto precisa ser preenchido.');

        return;
      }

      if (inputValue !== categoriaProduto.nome)
        handlePutCategoriaProduto(
          {
            ...categoriaProduto,
            nome: inputValue,
          },
          true
        );

      setEditando(false);
    }, [categoriaProduto, handlePutCategoriaProduto, setEditando, inputRef]);

    return (
      <ClickForaPadrao onClose={() => setEditando(false)}>
        <ManterFoco style={{ display: 'flex', width: '100%' }}>
          <SideContainer width="100%">
            <Input
              ref={inputRef}
              id="categoriaProduto-cadastro-nome"
              maxLength={30}
              variant="unstyled"
              defaultValue={categoriaProduto.nome}
              onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') handleSubmit();
              }}
              autoFocus
            />
          </SideContainer>

          <SideContainer>
            <Button
              variant="linkDefault"
              fontSize="12px"
              mt="2px"
              colorScheme="none"
              id="salvar"
              color="black"
              onClick={() => handleSubmit()}
            >
              <Icon mr="5px" as={FiCheck} fontSize="16px" />
              <Text whiteSpace="nowrap">Enter para confirmar</Text>
            </Button>

            <Button
              variant="linkDefault"
              mt="2px"
              colorScheme="none"
              onClick={() => setEditando(false)}
            >
              <Icon color="red.400" as={FiXCircle} fontSize="18px" />
            </Button>
          </SideContainer>
        </ManterFoco>
      </ClickForaPadrao>
    );
  }
);
