export const IndicadorIeEnum = {
  CONTRIBUINTE_ICMS: 1,
  CONTRIBUINTE_ISENTO_ICMS: 2,
  NAO_CONTRIBUINTE: 9,

  properties: {
    1: { name: 'Brinde', value: 1 },
    2: { name: 'Transferência produto saída', value: 2 },
    9: { name: 'Devolução de compra', value: 9 },
  },
};
