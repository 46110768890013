import { useCallback, useImperativeHandle, useEffect } from 'react';
import { VStack, Text } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';

import { IdentificacaoEtapasTray } from 'constants/enum/IdentificacaoEtapasTray';
import { useTrayEtapasContext } from 'store/Tray';
import { getCanalVendasTray, getCadastrarCanalVendasTray } from 'services/tray';

import { ConfiguracoesMinhasLojasIcon } from 'icons';
import Input from 'components/PDV/Input';

import { yupResolver, FormData } from './validationForms';
import { Header } from '../Layout/Header';

export const CanalVendas = () => {
  const { ref, setActiveStep, setIsLoading } = useTrayEtapasContext();

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
  });

  const { handleSubmit: onSubmit, reset } = formMethods;

  const handleAvancar = useCallback(() => {
    setActiveStep(IdentificacaoEtapasTray.VENDEDOR);
  }, [setActiveStep]);

  const handleVoltar = useCallback(() => {
    setActiveStep(IdentificacaoEtapasTray.AUTENTICACAO);
  }, [setActiveStep]);

  const getCanalVendas = useCallback(async () => {
    setIsLoading(true);

    const response = await getCanalVendasTray<FormData>();

    if (response !== null) {
      reset(response);
      setIsLoading(false);
    }

    setIsLoading(false);
  }, [setIsLoading, reset]);

  const handleSubmit = onSubmit(async (data) => {
    setIsLoading(true);

    const response = await getCadastrarCanalVendasTray(data);

    if (response !== null) {
      if (response === true) {
        handleAvancar();
      }
    }

    setIsLoading(false);
  });

  useImperativeHandle(ref, () => ({
    handleAvancar: handleSubmit,
    handleVoltar,
  }));

  useEffect(() => {
    getCanalVendas();
  }, [getCanalVendas]);

  return (
    <>
      <Header
        title="Canal de venda"
        handleVoltar={handleVoltar}
        icon={ConfiguracoesMinhasLojasIcon}
      />
      <FormProvider {...formMethods}>
        <VStack
          pl={['10px', '10px', '20px']}
          pr={['10px', '10px', '20px']}
          color="primary.50"
          fontSize="14px"
          w="full"
          alignItems="left"
          spacing="24px"
        >
          <Text mb="4px" textAlign="left" letterSpacing="0px">
            Para identificar esta conta, informe o nome da sua loja.
          </Text>

          <Input
            id="nomeCanalVenda"
            name="nomeCanalVenda"
            label="Nome do canal de venda"
            colSpan={12}
            placeholder="Ex: Belíssima Calçados"
          />
        </VStack>
      </FormProvider>
    </>
  );
};
