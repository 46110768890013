import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import { setDateMaxHours } from 'helpers/data/setHoursDate';

export type FormData = {
  data: Date;
  valor: number;
  contaFinanceiraDestinoId: string;
  formaPagamentoRecebimentoId: string;
  observacao?: string;
};

const schema = yup.object().shape({
  data: yup
    .date()
    .max(setDateMaxHours(new Date()), ConstanteMensagemValidacao.DATA_ATUAL)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  valor: yup
    .number()
    .moreThan(0, ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  contaFinanceiraDestinoId: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  formaPagamentoRecebimentoId: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  observacao: yup.string().nullable(),
});

export const yupResolver = yupResolverInstance(schema);

export const defaultValues = { ...schema.default(), valorTotal: 0 } as Record<
  string,
  any
>;

export const useForm = useFormInstance;
