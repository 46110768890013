import React, { useState } from 'react';
import { Button } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteRotasPDV from 'constants/rotasPDV';
import { useFinalizarContext } from 'store/PDV/Finalizar';
import gerarNotaFiscal from 'helpers/api/Operacao/gerarNotaFiscal';
import { useOperacaoContext } from 'store/PDV/Operacao';
import ModelosFiscaisEnum from 'constants/enum/fiscal/modelosFiscais';
import StatusFiscaisEnum from 'constants/enum/fiscal/statusFiscal';
import formatUTCToLocateDateTimeInput from 'helpers/format/formatUTCToLocateDateTimeInput';
import { useSignalRContext } from 'store/SignalR';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { EmitirNFCe, EmitirNFCeData } from 'components/EmitirNotaFiscal/NFCe';
import { formattedValueCpfCnpj } from 'helpers/format/formattedValueCpfCnpj';
import {
  ModalTransmissaoNota,
  ModalTransmissaoNotaResponse,
} from 'components/EmitirNotaFiscal/ModalTransmissaoNota';
import { RejeicaoInterface } from 'components/EmitirNotaFiscal/BoxRejeicao';
import ModalidadesFreteEnum from 'constants/enum/fiscal/modalidadeFrete';

const NFCe = () => {
  const history = useHistory();
  const {
    operacao,
    informacoesLoja,
    handleObterOperacao,
    handleTabsChange,
    setDefaultModeloFiscal,
    deveMostrarTelaEmitirNFe,
    temNFeAutorizada,
    ambienteFiscal,
  } = useFinalizarContext();
  const { operacaoId } = useOperacaoContext();
  const { hubConnection, joinGroup, exitGroup } = useSignalRContext();

  const cpfCnpjIsRequired =
    !!informacoesLoja &&
    informacoesLoja.nfCeExigirCNPJCPF &&
    !!operacao &&
    operacao.valorTotal >= informacoesLoja.nfCeValorExigenciaCNPJCPF;

  const [isLoading, setIsLoading] = useState(false);
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(false);
  const [rejeicao, setRejeicao] = useState<RejeicaoInterface>();

  const documentoFiscalExistente = !!operacao && !!operacao.NFCe;

  const transmitirDocumentoFiscal = async (documentoFiscalId: string) => {
    const {
      success,
      emitiuAmbasNotas,
      rejeicao: newRejeicao,
    }: ModalTransmissaoNotaResponse = await ModalTransmissaoNota({
      documentoFiscalId,
      hubConnection,
      joinGroup,
      exitGroup,
    });

    if (success) {
      handleTabsChange(emitiuAmbasNotas || !deveMostrarTelaEmitirNFe ? 0 : 2);
    } else {
      setRejeicao(newRejeicao);
    }

    await handleObterOperacao();
  };

  const handleSubmitAlterar: SubmitHandler<EmitirNFCeData> = async ({
    cpfCnpj,
    cnpjIntermediador,
    identificacaoNoIntermediador,
    operacaoComIntermediador,
  }) => {
    setIsLoading(true);
    setIsDisabledSubmit(true);
    if (operacao && operacao.NFCe) {
      const dataPut = {
        id: operacao.NFCe.id,
        status: StatusFiscaisEnum.EmDigitacao,
        cpfCnpj,
        operacaoComIntermediador,
        cnpjIntermediador,
        identificacaoNoIntermediador,
        dataEmissao: formatUTCToLocateDateTimeInput(new Date().toString()),
      };

      const response = await api.put<void, ResponseApi>(
        ConstanteEnderecoWebservice.NOTA_FISCAL_ALTERAR_PDV,
        dataPut
      );

      if (response.sucesso) {
        setDefaultModeloFiscal('NFCe');
        await transmitirDocumentoFiscal(operacao.NFCe.id);
      }

      if (response.avisos) {
        try {
          const newRejeicao = JSON.parse(
            response.avisos[0]
          ) as RejeicaoInterface;

          setRejeicao(newRejeicao);
        } catch {
          setRejeicao({ Mensagem: response.avisos[0] });
        }
      }
    }

    setIsLoading(false);
    setIsDisabledSubmit(false);
  };

  const handleSubmit: SubmitHandler<EmitirNFCeData> = async ({
    cpfCnpj,
    cnpjIntermediador,
    identificacaoNoIntermediador,
    operacaoComIntermediador,
  }) => {
    if (operacaoId) {
      setIsLoading(true);
      setIsDisabledSubmit(true);
      const documentoFiscalId = await gerarNotaFiscal(
        {
          operacaoId,
          cpfCnpj,
          modeloFiscal: ModelosFiscaisEnum.NFCe,
          operacaoComIntermediador,
          cnpjIntermediador,
          identificacaoNoIntermediador,
          dataEmissao: formatUTCToLocateDateTimeInput(new Date().toString()),
          modalidadeFrete: ModalidadesFreteEnum.SemFrete,
        },
        (aviso) => {
          try {
            const newRejeicao = JSON.parse(aviso) as RejeicaoInterface;

            setRejeicao(newRejeicao);
          } catch {
            setRejeicao({ Mensagem: aviso });
          }
        }
      );

      if (documentoFiscalId) {
        setDefaultModeloFiscal('NFCe');
        await transmitirDocumentoFiscal(documentoFiscalId);
      }

      setIsLoading(false);
      setIsDisabledSubmit(false);
    }
  };

  const handlePushNovoPedido = () => {
    history.push(ConstanteRotasPDV.PDV_HOME);
  };

  const handlePushConsultar = () => {
    history.push(ConstanteRotasPDV.PDV_CONSULTAR_OPERACOES);
  };

  return (
    <>
      {isLoading && <LoadingPadrao />}

      <EmitirNFCe
        ambienteFiscal={ambienteFiscal}
        isPdv
        defaultValues={
          operacao && operacao.NFCe
            ? {
                id: operacao?.NFCe?.id || '',
                cpfCnpj: operacao.NFCe.cpfCnpj
                  ? formattedValueCpfCnpj(operacao.NFCe.cpfCnpj)
                  : undefined,
                operacaoComIntermediador:
                  operacao.NFCe.operacaoComIntermediador,
                cnpjIntermediador: operacao.NFCe.cnpjIntermediador
                  ? formattedValueCpfCnpj(
                      operacao.NFCe.cnpjIntermediador,
                      false,
                      true
                    )
                  : undefined,
                identificacaoNoIntermediador:
                  operacao.NFCe.identificacaoNoIntermediador,
              }
            : {
                cpfCnpj:
                  operacao && operacao.cliente.cpfCnpj
                    ? operacao.cliente.cpfCnpj
                    : undefined,
              }
        }
        cpfCnpjIsRequired={cpfCnpjIsRequired}
        onSubmit={documentoFiscalExistente ? handleSubmitAlterar : handleSubmit}
        isDisabledSubmit={isDisabledSubmit}
        rejeicaoNotaFiscal={rejeicao}
        setRejeicaoNotaFiscal={setRejeicao}
        isLoading={isLoading}
        hasNfeAutorizada={temNFeAutorizada}
        statusNfce={operacao?.NFCe?.status}
      >
        <Button
          size="sm"
          w="full"
          minW="96px"
          maxW={{ base: 'full', sm: '120px' }}
          variant="outline"
          onClick={handlePushConsultar}
        >
          Consultar
        </Button>
        <Button
          size="sm"
          w="full"
          minW="96px"
          maxW={{ base: 'full', sm: '120px' }}
          onClick={handlePushNovoPedido}
          {...(temNFeAutorizada
            ? {
                color: 'secondary.300',
                colorScheme: 'primary',
                variant: 'solid',
              }
            : { variant: 'outline' })}
        >
          Novo pedido
        </Button>
      </EmitirNFCe>
    </>
  );
};

export default NFCe;
