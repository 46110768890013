import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';
import {
  KitProps,
  useProdutosFormularioContext,
} from 'store/Produtos/ProdutosFormulario';
import ConstanteFuncionalidades from 'constants/permissoes';
import auth from 'modules/auth';

import { ColumnHeaderType } from 'components/update/Table/TableCampoFormularioPadrao/TableHeader';

export const useKit = () => {
  const [itemKitIndex, setItemKitIndex] = useState<number | undefined>(
    undefined
  );
  const [itemKitModalIsOpen, setItemKitModalIsOpen] = useState(false);

  const possuiPermissaoVisualizarPrecoCusto = auth.possuiPermissao(
    ConstanteFuncionalidades.USUARIO_VISUALIZAR_PRECO_CUSTO
  ).permitido;

  const {
    isLoading,
    readonly,
    idProduto,
    setIsLoading,
    setListKits,
  } = useProdutosFormularioContext();

  const { control, getValues, setValue, watch } = useFormContext();

  const produtoCorTamanhoKitItensWatch = watch(
    'produtoCorTamanhoKitItens'
  ) as KitProps[];

  const modalSubtitle = () => {
    if (itemKitIndex !== undefined) {
      const produtoItemKit = produtoCorTamanhoKitItensWatch[
        itemKitIndex
      ] as KitProps;

      return `${produtoItemKit.produtoCorTamanho.produto} | ${produtoItemKit.produtoCorTamanho.cor} | ${produtoItemKit.produtoCorTamanho.tamanho}`;
    }

    return 'Selecione o produto com a cor desejada para obter a grade de tamanhos deste.';
  };

  const orderColumn = (a: KitProps, b: KitProps, order: 'asc' | 'desc') => {
    const compareStringA = `${a.produtoDescricao} ${a.corDescricao} ${a.tamanhoDescricao}`;

    const compareStringB = `${b.produtoDescricao} ${b.corDescricao} ${b.tamanhoDescricao}`;

    if (order === 'asc') {
      if (compareStringA < compareStringB) return -1;

      if (compareStringA > compareStringB) return 1;

      return 0;
    }

    if (compareStringB < compareStringA) return -1;

    if (compareStringB > compareStringA) return 1;

    return 0;
  };

  const headerColumns = [
    {
      name: 'descricao',
      displayName: 'Descrição',
      isOrderable: true,
      width: '60%',
      minWidth: '350px',
      orderColumnCompareFn: orderColumn,
    },
    {
      name: 'quantidade',
      displayName: 'Quantidade',
      isOrderable: true,
      width: '10%',
      minWidth: '120px',
      alignText: 'right',
    },
    possuiPermissaoVisualizarPrecoCusto
      ? {
          name: 'precoCusto',
          displayName: 'Custo',
          isOrderable: true,
          width: '10%',
          minWidth: '100px',
          alignText: 'right',
        }
      : ({} as ColumnHeaderType),
    {
      name: 'precoTotal',
      displayName: 'Preço',
      isOrderable: true,
      width: '10%',
      minWidth: '100px',
      alignText: 'right',
    },
    {
      name: 'markup',
      displayName: 'Markup',
      isOrderable: true,
      width: '10%',
      minWidth: '100px',
      alignText: 'right',
    },
    {
      name: 'acoes',
      displayName: 'Ações',
      isOrderable: false,
      width: '38px',
      alignText: 'center',
    },
  ] as ColumnHeaderType[];

  const getKits = useCallback(async () => {
    const response = await api.get<void, ResponseApi<KitProps[]>>(
      `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/kits`
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso && response.dados.length > 0) {
        setValue('produtoCorTamanhoKitItens', response.dados);
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [idProduto, setIsLoading, setValue]);

  const handleRemoverProdutoKit = useCallback(
    async (idKit: string, index: number) => {
      const response = await api.delete<void, ResponseApi<KitProps[]>>(
        `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/kits/${idKit}`
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }

        if (response.sucesso) {
          const items = getValues(
            'produtoCorTamanhoKitItens'
          ) as Array<KitProps>;
          items.splice(index, 1);

          setValue('produtoCorTamanhoKitItens', [...items]);
        }
      }
    },
    [getValues, idProduto, setValue]
  );

  const handleFecharModal = () => {
    setItemKitIndex(undefined);
    setItemKitModalIsOpen(false);
  };

  const handleAbrirModalKit = (value: number | undefined) => {
    setItemKitIndex(value);
    setItemKitModalIsOpen(true);
  };

  useEffect(() => {
    getKits();
  }, [getKits]);

  useEffect(() => {
    setListKits(produtoCorTamanhoKitItensWatch || []);
  }, [produtoCorTamanhoKitItensWatch, setListKits]);

  return {
    handleAbrirModalKit,
    handleFecharModal,
    handleRemoverProdutoKit,
    orderColumn,
    modalSubtitle,
    control,
    setItemKitIndex,
    getKits,
    isLoading,
    headerColumns,
    readonly,
    itemKitIndex,
    itemKitModalIsOpen,
  };
};
