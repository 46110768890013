import { Box, Text, Divider } from '@chakra-ui/react';

import { DecimalMask } from 'helpers/format/fieldsMasks';

interface CabecalhoProps {
  contaFinanceiraNome: string;
  dataHoraUsuarioAbertura: string;
  dataHoraUsuarioFechamento: string;
  saldoInicial: number;
  totalEntradas: number;
  totalSaidas: number;
  saldoFinal: number;
}

type InformacoesCabecalho = {
  informacoesCabecalhoProps?: CabecalhoProps;
};

const Cabecalho = ({ informacoesCabecalhoProps }: InformacoesCabecalho) => {
  return (
    <>
      <Box>
        <Text
          fontSize="xs"
          color="black"
          fontWeight="900"
          lineHeight="1.6"
          mb="1"
        >
          Fechamento de caixa
        </Text>
        <Divider w="full" color="gray.700" />
        <Text
          color="black"
          fontWeight="900"
          fontSize="2xs"
        >{`Caixa: ${informacoesCabecalhoProps?.contaFinanceiraNome}`}</Text>
        <Text color="black" fontWeight="900" fontSize="2xs">
          Abertura: {informacoesCabecalhoProps?.dataHoraUsuarioAbertura}
        </Text>
        <Text color="black" fontWeight="900" fontSize="2xs">
          Fechamento: {informacoesCabecalhoProps?.dataHoraUsuarioFechamento}
        </Text>
      </Box>
      <Box marginY="1">
        <Text color="black" fontWeight="900" fontSize="xs">
          {' '}
          Resumo do Caixa:{' '}
        </Text>
      </Box>
      <Box
        borderBottom="1px solid"
        borderBottomColor="gray.700"
        paddingBottom="2"
      >
        <Text color="black" fontWeight="900" fontSize="2xs">
          Saldo Inicial:{' '}
          {DecimalMask(informacoesCabecalhoProps?.saldoInicial, 2)}
        </Text>
        <Text color="black" fontWeight="900" fontSize="2xs">
          Entradas: {DecimalMask(informacoesCabecalhoProps?.totalEntradas, 2)}
        </Text>
        <Text color="black" fontWeight="900" fontSize="2xs">
          Saídas: {DecimalMask(informacoesCabecalhoProps?.totalSaidas, 2)}
        </Text>
        <Text color="black" fontWeight="900" fontSize="2xs">
          Saldo Final: {DecimalMask(informacoesCabecalhoProps?.saldoFinal, 2)}
        </Text>
      </Box>
    </>
  );
};

export default Cabecalho;
