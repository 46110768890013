import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

const schema = yup.object().shape({
  csc: yup
    .string()
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .max(36, 'O campo de CSC não pode conter mais que 36 caracteres.'),
  token: yup
    .string()
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .max(8, 'O campo de Token não pode conter mais que 8 caracteres.'),
});

export const yupResolver = yupResolverInstance(schema);

export const useForm = useFormInstance;
