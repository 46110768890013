const ConstanteSignalRGroups = {
  PDV_PEDIDOSABERTOS: 'Pdv-PedidosAbertos-{0}',
  NOTIFICACAO_IMPORTACAO_CONFERENCIA: 'importacao-conferencia-estoque-{0}',
};

export default ConstanteSignalRGroups;

export const SubstituirParametroRota = (
  route: string,
  paramName: string,
  value: string
) => {
  return route.replace(`:${paramName}`, value);
};
