import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Divider,
  Flex,
  HStack,
  Icon,
  Td,
  Text,
  Tr,
  useMediaQuery,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import {
  StatusManifestoEnum,
  statusManifestoOptions,
} from 'constants/enum/fiscal/statusManifesto';
import { PeriodoEnum } from 'constants/enum/periodo';
import getOptionsByEnum from 'helpers/format/getOptionsByEnum';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';
import { getName } from 'helpers/enum/getName';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';
import {
  formatDate,
  formatDateHourMinute,
} from 'helpers/format/formatStringDate';
import TiposEventosEnum from 'constants/enum/fiscal/tipoEvento';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';

import { NFeIcon, ImportarXMLIcon, FiscalExportarXmlIcon } from 'icons';
import { ResolucaoNaoCompativel } from 'pages/ResolucaoNaoCompativel';
import { SimpleCard } from 'components/update/Form/SimpleCard';
import Select from 'components/PDV/Select/SelectPadrao';
import {
  PagedTable,
  PagedTableForwardRefData,
} from 'components/update/Table/PagedTable';
import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import { PaginationData } from 'components/update/Pagination';
import { ModalAtencao } from 'components/Modal/ModalAtencao';
import Loading from 'components/Layout/Loading/LoadingPadrao';
import { ModalMotivo } from 'components/update/Modal/ModalMotivo';
import {
  ActionMenuItem,
  ActionsMenu,
} from 'components/update/Table/ActionsMenu';
import jsFileDownload from 'js-file-download';

type DocumentoFiscalManifestoXml = {
  id: string;
  documentoFiscalManifestoId: string;
  tipoEvento?: number;
  nomeArquivo: string;
  arquivo: string;
  arquivoSincronizado: boolean;
};

type DocumentoFiscalManifesto = {
  id: string;
  chaveAcesso: string;
  numero: string;
  emissor: string;
  dataEmissao: Date;
  valor: number;
  situacao: number;
  notaImportada: boolean;

  documentoFiscalManifestoXml: DocumentoFiscalManifestoXml[];
};

type ConsultarNotasResponse = {
  falhaConsulta: boolean;
  dataHoraConsulta: Date;
};

type BaixarXmlResponse = {
  nomeArquivo: string;
  arquivoSincronizado: boolean;
  arquivo: string;
  url: string;
};

export function ListarManifestacaoDestinatario() {
  const history = useHistory();

  const [isLargerThan800] = useMediaQuery('(min-width: 800px)');

  const { casasDecimais } = usePadronizacaoContext();

  const formMethods = useForm({
    defaultValues: { situacao: null, periodo: PeriodoEnum.HOJE },
  });

  const [situacao, periodo] = formMethods.watch(['situacao', 'periodo']);

  const pagedTableRef = useRef<PagedTableForwardRefData>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [itemsTotalCount, setItemsTotalCount] = useState(0);
  const [items, setItems] = useState<DocumentoFiscalManifesto[]>([]);

  const [isConsultaLoading, setIsConsultaLoading] = useState(false);
  const [foiConsultado, setFoiConsultado] = useState(false);

  const loadColumnsData = useCallback(
    async (paginationData: PaginationData) => {
      if (foiConsultado) {
        setIsLoading(true);

        const params = {
          status: situacao || '',
          periodo,
        };

        const response = await api.get<
          void,
          ResponseApi<GridPaginadaRetorno<DocumentoFiscalManifesto>>
        >(
          formatQueryPagegTable(
            ConstanteEnderecoWebservice.MANIFESTO_DESTINATARIO_LISTAR_PAGINADO,
            paginationData,
            params
          )
        );

        if (response) {
          if (response.avisos) {
            response.avisos.map((item: string) => toast.warning(item));
          }

          if (response.sucesso) {
            setItemsTotalCount(response.dados.total || 0);

            setItems(response.dados.registros || []);
          }
        }

        setIsLoading(false);
      }
    },
    [periodo, situacao, foiConsultado]
  );

  function handleVoltar() {
    history.push(ConstanteRotas.DASHBOARD);
  }

  async function acaoManifesto(documentoFiscalId: string, tipoEvento: number) {
    let nameEvento = '';

    switch (tipoEvento) {
      case TiposEventosEnum.CIENCIA_OPERACAO:
        nameEvento = 'ciencia';
        break;

      case TiposEventosEnum.CONFIRMACAO_OPERACAO:
        nameEvento = 'confirmacao';
        break;

      case TiposEventosEnum.DESCONHECIMENTO_OPERACAO:
        nameEvento = 'desconhecimento';
        break;

      case TiposEventosEnum.OPERACAO_NAO_REALIZADA:
        nameEvento = 'nao-realizada';
        break;

      default:
        nameEvento = '';
    }

    const response = await api.post<void, ResponseApi<ConsultarNotasResponse>>(
      `${ConstanteEnderecoWebservice.MANIFESTO_DESTINATARIO_EVENTOS}${nameEvento}/${documentoFiscalId}`
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso && pagedTableRef.current) {
        pagedTableRef.current.reload();
      }
    }
  }

  async function handleCienciaEmissao(documentoFiscalId: string) {
    acaoManifesto(documentoFiscalId, TiposEventosEnum.CIENCIA_OPERACAO);
  }

  async function handleConfirmacaoOperacao(documentoFiscalId: string) {
    acaoManifesto(documentoFiscalId, TiposEventosEnum.CONFIRMACAO_OPERACAO);
  }

  async function handleOperacaoNaoRealizada(documentoFiscalId: string) {
    acaoManifesto(documentoFiscalId, TiposEventosEnum.OPERACAO_NAO_REALIZADA);
  }

  async function handleDesconhecimentoOperacao(documentoFiscalId: string) {
    const { motivo } = await ModalMotivo({
      modalTitle: 'Informe o motivo do desconhecimento',
      motivoInputLabel:
        'Antes de confirmar, informe o motivo do desconhecimento.',
    });

    if (motivo) {
      acaoManifesto(
        documentoFiscalId,
        TiposEventosEnum.DESCONHECIMENTO_OPERACAO
      );
    }
  }

  async function handleImportarXml(documentoFiscalManifestoId: string) {
    const response = await api.post<void, ResponseApi<string | undefined>>(
      ConstanteEnderecoWebservice.MANIFESTO_DESTINATARIO_IMPORTAR_XML,
      {},
      { params: { documentoFiscalManifestoId } }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }

      if (response.sucesso && response.dados) {
        history.push(
          SubstituirParametroRota(
            ConstanteRotas.ENTRADA_MERCADORIA_ENTRADA_IMPORTACAO_CONTINUAR,
            'id',
            response.dados
          )
        );
      }
    }
  }

  async function handleBaixarXml(documentoFiscalManifestoId: string) {
    const response = await api.get<void, ResponseApi<BaixarXmlResponse>>(
      ConstanteEnderecoWebservice.MANIFESTO_DESTINATARIO_BAIXAR_XML,
      { params: { documentoFiscalManifestoId } }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }

      if (response.sucesso && response.dados) {
        jsFileDownload(
          Uint8Array.from(atob(response.dados.arquivo), (c) => c.charCodeAt(0)),
          response.dados.nomeArquivo
        );
      }
    }
  }

  useEffect(() => {
    async function consultarNotas() {
      setIsConsultaLoading(true);

      const response = await api.post<
        void,
        ResponseApi<ConsultarNotasResponse>
      >(ConstanteEnderecoWebservice.MANIFESTO_DESTINATARIO_CONSULTA_NOTAS);

      if (response) {
        if (response.avisos) {
          response.avisos.map((item: string) => toast.warning(item));
        }

        if (response.dados && response.dados.falhaConsulta) {
          ModalAtencao({
            text: `Não foi possível consultar as notas no webservice da sefaz, a última consulta foi ${formatDateHourMinute(
              response.dados.dataHoraConsulta
            )}`,
          });
        }
      }

      setIsConsultaLoading(false);
      setFoiConsultado(true);
    }

    consultarNotas();
  }, []);

  return isLargerThan800 ? (
    <SimpleCard>
      {isConsultaLoading && <Loading />}
      <FormProvider {...formMethods}>
        <Flex justifyContent="space-between">
          <Flex alignItems="center">
            <Icon as={NFeIcon} w="90px" h="36px" />

            <Divider orientation="vertical" h="36px" ml="6" />

            <Text ml="6">
              Para tomar ciência da emissão, confirmar ou desconhecer uma nota
              fiscal clique no botão de ações localizado no final da linha.
            </Text>
          </Flex>

          <HStack spacing="6" ml={{ base: '12', lg: '24' }}>
            <Box w={{ base: '160px', lg: '200px' }}>
              <Select name="situacao" options={statusManifestoOptions} />
            </Box>
            <Box w={{ base: '160px', lg: '200px' }}>
              <Select name="periodo" options={getOptionsByEnum(PeriodoEnum)} />
            </Box>
          </HStack>
        </Flex>

        <Divider orientation="horizontal" my={{ base: 4, sm: 6, md: 8 }} />

        <PagedTable
          ref={pagedTableRef}
          isLoading={isLoading}
          loadColumnsData={loadColumnsData}
          itemsTotalCount={itemsTotalCount}
          defaultKeyOrdered="dataEmissao"
          defaultOrderDirection="desc"
          tableHeaders={[
            {
              key: 'emissor',
              content: ' Emissor:',
              isOrderable: false,
            },
            { key: 'numero', content: 'Numero da nota' },
            { key: 'dataEmissao', content: 'Data da emissão', order: 'desc' },
            { key: 'valor', content: 'Valor', isOrderable: false },
            { key: 'situacao', content: 'Situação', isOrderable: false },
            { key: 'acoes', content: 'Ações', isOrderable: false, w: '1px' },
          ]}
          renderTableRows={items.map((item, index) => {
            let corLinha = 'gray.50';

            if (item.situacao === StatusManifestoEnum.PENDENTE) {
              corLinha = 'orange.400';
            } else if (
              item.situacao === StatusManifestoEnum.DESCONHECIMENTO ||
              item.situacao === StatusManifestoEnum.NFE_NAO_REALIZADA
            ) {
              corLinha = 'red.50';
            }

            const actionsMenuItems: ActionMenuItem[] = [];

            if (item.situacao === StatusManifestoEnum.PENDENTE) {
              actionsMenuItems.push({
                content: 'Ciência da emissão',
                onClick: () => {
                  handleCienciaEmissao(item.id);
                },
              });
            }

            if (item.situacao === StatusManifestoEnum.CIENCIA_EMISSAO) {
              actionsMenuItems.push({
                content: 'Confirmação da operação',
                onClick: () => {
                  handleConfirmacaoOperacao(item.id);
                },
              });

              actionsMenuItems.push({
                content: 'Operação não realizada',
                onClick: () => {
                  handleOperacaoNaoRealizada(item.id);
                },
              });
            }

            if (
              item.situacao === StatusManifestoEnum.CIENCIA_EMISSAO ||
              item.situacao === StatusManifestoEnum.PENDENTE
            ) {
              actionsMenuItems.push({
                content: 'Desconhecimento da operação',
                onClick: () => {
                  handleDesconhecimentoOperacao(item.id);
                },
              });
            }

            if (
              item.situacao === StatusManifestoEnum.CIENCIA_EMISSAO ||
              item.situacao === StatusManifestoEnum.NFE_CONFIRMADA
            ) {
              actionsMenuItems.push({
                content: <Divider />,
                isDisabled: true,
              });
            }

            if (
              item.situacao === StatusManifestoEnum.CIENCIA_EMISSAO ||
              item.situacao === StatusManifestoEnum.NFE_CONFIRMADA ||
              !item.notaImportada
            ) {
              if (item.situacao !== StatusManifestoEnum.PENDENTE) {
                actionsMenuItems.push({
                  icon: <Icon as={ImportarXMLIcon} fontSize="lg" />,
                  content: 'Importar XML',
                  onClick: () => {
                    handleImportarXml(item.id);
                  },
                });
              }
            }

            if (
              item.situacao === StatusManifestoEnum.CIENCIA_EMISSAO ||
              item.situacao === StatusManifestoEnum.NFE_CONFIRMADA
            ) {
              if (item.situacao !== StatusManifestoEnum.PENDENTE) {
                actionsMenuItems.push({
                  icon: <Icon as={FiscalExportarXmlIcon} fontSize="lg" />,
                  content: 'Baixar arquivo XML',
                  onClick: () => {
                    handleBaixarXml(item.id);
                  },
                });
              }
            }

            return (
              <Fragment key={item.id}>
                <Tr
                  boxShadow="none !important"
                  sx={{ '& > td': { bg: corLinha } }}
                  h="56px"
                >
                  <Td>{item.emissor}</Td>
                  <Td>{item.numero}</Td>
                  <Td>{formatDate(item.dataEmissao)}</Td>
                  <Td>
                    {`R$ ${item.valor.toLocaleString('pt-BR', {
                      minimumFractionDigits: casasDecimais.casasDecimaisValor,
                      maximumFractionDigits: casasDecimais.casasDecimaisValor,
                    })}`}
                  </Td>
                  <Td>{getName(StatusManifestoEnum, item.situacao)}</Td>
                  <Td>
                    <ActionsMenu items={actionsMenuItems} />
                  </Td>
                </Tr>

                <Box h={index + 1 === items.length ? 0 : 2} zIndex="hide" />
              </Fragment>
            );
          })}
          boxShadow="none"
          variant="simple-card"
          size="sm"
          sx={{ '& tr > th': { borderBottom: 'none' } }}
          paginationHasDivider={false}
        />
      </FormProvider>
    </SimpleCard>
  ) : (
    <ResolucaoNaoCompativel
      content="Para consultar o servidor da SEFAZ e verificar as notas fiscais é necessário utilizar um dispositivo com resolução mínima de 800x600 pixels."
      handleVoltar={handleVoltar}
    />
  );
}
