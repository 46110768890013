import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import TipoContaFinanceiraEnum from 'constants/enum/tipoContaFinanceira';
import React, {
  createContext,
  useContext,
  SetStateAction,
  Dispatch,
  useEffect,
} from 'react';
import { OptionsType, OptionTypeBase } from 'react-select';
import { toast } from 'react-toastify';
import api, { ResponseApi } from 'services/api';

export interface IconesStorage {
  nome: string;
  url: string;
}

interface FormaPagamentoContextProps {
  urlPreview: string;
  setUrlPreview: Dispatch<SetStateAction<string>>;
  showVencimento: boolean;
  setShowVencimento: Dispatch<SetStateAction<boolean>>;
  urlPreviewList: IconesStorage[] | undefined;
  setUrlPreviewList: Dispatch<SetStateAction<IconesStorage[] | undefined>>;
  iconesOptions: OptionsType<OptionTypeBase>;
  setIconesOptions: Dispatch<SetStateAction<OptionsType<OptionTypeBase>>>;
}

export const FormaPagamentoContext = createContext<FormaPagamentoContextProps>(
  {} as FormaPagamentoContextProps
);

interface FormaPagamentoFormularioProviderProps
  extends FormaPagamentoContextProps {
  children: React.ReactNode;
}

export default function FormaPagamentoFormularioProvider({
  children,
  urlPreview,
  setUrlPreview,
  showVencimento,
  setShowVencimento,
  urlPreviewList,
  setUrlPreviewList,
  iconesOptions,
  setIconesOptions,
}: FormaPagamentoFormularioProviderProps): JSX.Element {
  useEffect(() => {
    async function buscarIcones() {
      const response = await api.get<void, ResponseApi<IconesStorage[]>>(
        ConstanteEnderecoWebservice.CONTA_FINANCEIRA_LISTAR_ICONES,
        {
          params: {
            tipoConta: TipoContaFinanceiraEnum.BANCO,
          },
        }
      );

      if (response?.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }

      if (response?.sucesso) {
        setIconesOptions(
          response.dados.map((icones: IconesStorage) => {
            return {
              label: icones.nome,
              value: icones.nome,
            } as OptionTypeBase;
          })
        );
        setUrlPreviewList(response.dados);
      }
      return false;
    }

    buscarIcones();
  }, []);

  return (
    <FormaPagamentoContext.Provider
      value={{
        urlPreview,
        setUrlPreview,
        showVencimento,
        setShowVencimento,
        urlPreviewList,
        setUrlPreviewList,
        iconesOptions,
        setIconesOptions,
      }}
    >
      {children}
    </FormaPagamentoContext.Provider>
  );
}

export function useFormaPagamentoContext(): FormaPagamentoContextProps {
  const context = useContext(FormaPagamentoContext);

  if (!context)
    throw new Error(
      'useFormaPagamentoContext must be used within a FormaPagamentoContextProps.'
    );

  return context;
}
