const ConsignacaoTipoRelatorioEnum = {
  LISTAGEM_PRODUTOS: 1,
  AGRUPADO_POR_CLIENTES: 2,

  properties: [
    { name: 'Listagem de produtos', value: 1 },
    { name: 'Agrupado por cliente', value: 2 },
  ],
};

export default ConsignacaoTipoRelatorioEnum;
