import styled from 'styled-components';
import { Button } from 'react-bootstrap';

import { AppendContainer } from '../styles';

export const AppendContainerWhats = styled(AppendContainer)`
  width: 40px;
  top: 10px;

  font-size: 1rem;
`;

export const WhatsButton = styled(Button).attrs(() => ({ variant: 'link' }))`
  font-size: 0.6875rem;
  color: var(--sti-ck-colors-blue-500) !important;
  text-decoration: none !important;

  float: right;
  padding: 0px;
`;
