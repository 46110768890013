import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export type FormData = {
  nomeCanalVenda: string;
};

const schema = yup.object().shape({
  nomeCanalVenda: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);
