import { toast } from 'react-toastify';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { OperacaoItemCadastrar } from '../OperacaoItem/cadastrarOperacaoItem';

interface OperacaoCadastrar {
  vendedorId: string;
  clienteFornecedorId: string;
  lojaId: string;
  usuarioId?: string;
  tela: number;
  identificacaoTipoOperacao: number;
  observacao: string;
  caixaMovimentacaoId: string;
  tabelaPrecoId: string;
  devolucaoId: string | null;
  operacaoItem?: OperacaoItemCadastrar;
  localEstoqueId?: string;
}

interface OperacaoRetornoCadastro {
  id: string;
  numeroOperacao: number;
}

const cadastrarOperacao = async (
  operacaoCadastrar: OperacaoCadastrar
): Promise<OperacaoRetornoCadastro | undefined> => {
  const response = await api.post<void, ResponseApi<OperacaoRetornoCadastro>>(
    ConstanteEnderecoWebservice.PEDIDOORCAMENTOVENDA_CADASTRAR,
    {
      ...operacaoCadastrar,
    }
  );

  if (response) {
    if (response.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (response.sucesso && response.dados) {
      return response.dados;
    }
  }

  return undefined;
};

export default cadastrarOperacao;
