import { Flex, Box, Tr, Td, Icon, Text } from '@chakra-ui/react';
import { FiChevronLeft } from 'react-icons/fi';
import { FormProvider } from 'react-hook-form';

import getOptionsByEnum from 'helpers/format/getOptionsByEnum';
import StatusConsultaEnum from 'constants/enum/statusConsulta';

import { ButtonCadastrarNovo } from 'components/Layout/ButtonCadastrarNovo';
import { SearchInput } from 'components/update/Input/SearchInput';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import { PagedTable } from 'components/update/Table/PagedTable';
import { StatusCircle } from 'components/update/Table/StatusCircle';
import { FilterSelect } from 'components/update/Select/FilterSelect';
import { ContainerHeader } from 'pages/Integracoes/LojaAplicativos/Layout/ContainerHeader';

import { useSetoresEntrega } from './hooks';
import { usePainelFrenteCaixa } from '../../hooks';

export const ListarSetoresEntrega = () => {
  const {
    formMethods,
    pagedTableRef,
    listSetoresEntrega,
    totalRegistros,
    isLoading,
    paginationHandle,
    filtersSubmit,
    handleRemoverSetor,
    handleRegisterDeliverySector,
    handleUpdateDeliverySector,
  } = useSetoresEntrega();
  const { handleVoltarPainelAdm } = usePainelFrenteCaixa();

  return (
    <FormProvider {...formMethods}>
      <ContainerHeader bg="#FF005A" gap={2} color="white" alignItems="center">
        <Box cursor="pointer" mr="10px" onClick={handleVoltarPainelAdm}>
          <Icon fontSize="25px" as={FiChevronLeft} />
        </Box>
        <Text fontSize="16px" fontWeight="semibold">
          Setor de entrega
        </Text>
      </ContainerHeader>
      <Box position="relative" p={6}>
        <Flex
          justifyContent="space-between"
          direction={['column', 'column', 'row']}
        >
          <Flex mb={['20px', '20px', '0']} flex={2}>
            <SearchInput
              type="search"
              placeholder="Buscar por setor de entrega"
              id="pesquisa"
              name="pesquisa"
              onEnterKeyPress={() => {
                filtersSubmit();
              }}
            />
          </Flex>
          <Flex mb={['20px', '20px', '0']} ml={['0', '0', '24px']} flex={1}>
            <FilterSelect
              id="status"
              name="status"
              options={getOptionsByEnum(StatusConsultaEnum)}
              onSelect={() => {
                filtersSubmit();
              }}
              isDisabled={isLoading}
            />
          </Flex>
          <Flex justifyContent="flex-end" flex={2}>
            <ButtonCadastrarNovo
              onClick={handleRegisterDeliverySector}
              funcionalidade=""
            />
          </Flex>
        </Flex>
        <Box>
          <PagedTable
            ref={pagedTableRef}
            isLoading={isLoading}
            variant="card"
            bg="transparent"
            boxShadow="none"
            paddingRight="2px"
            paddingLeft="2px"
            pb="10px"
            pt={['10px', '10px', '']}
            paginationHasDivider={false}
            loadColumnsData={paginationHandle}
            itemsTotalCount={totalRegistros}
            defaultKeyOrdered="nome"
            tableHeaders={[
              {
                key: 'ativo',
                isOrderable: false,
                content: <StatusCircle hasValue={false} />,
                w: '1px',
                paddingRight: '0',
              },
              {
                key: 'nome',
                content: 'Nome',
                isOrderable: false,
              },

              { key: 'acoes', content: 'Ações', isOrderable: false, w: '1px' },
            ]}
            renderTableRows={(listSetoresEntrega || []).map((itemSetores) => {
              const actionMenu = [
                {
                  content: 'Editar',
                  onClick: () => handleUpdateDeliverySector(itemSetores.id),
                },
                {
                  content: 'Remover',
                  onClick: () => handleRemoverSetor(itemSetores.id),
                },
              ];

              return (
                <>
                  <Tr
                    sx={{
                      boxShadow: '0px 0px 4px #00000029',
                      borderRadius: '6px',
                      '& > td': {
                        height: '30px',
                        bg: 'white',
                      },
                    }}
                  >
                    <Td>
                      <StatusCircle isActive />
                    </Td>
                    <Td fontWeight="bold" whiteSpace="nowrap">
                      <Text
                        w="min"
                        cursor="pointer"
                        onClick={() => {
                          handleUpdateDeliverySector(itemSetores.id);
                        }}
                        _hover={{ textDecor: 'underline' }}
                      >
                        {itemSetores.nome}
                      </Text>
                    </Td>
                    <Td>
                      <ActionsMenu items={actionMenu} />
                    </Td>
                  </Tr>
                  <Box h="5px" />
                </>
              );
            })}
          />
        </Box>
      </Box>
    </FormProvider>
  );
};
