import { useCallback, useEffect } from 'react';
import { Text, VStack, Box, useMediaQuery } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import {
  ContainerIntegracaoFixo,
  useSmartPosEtapasContext,
} from 'store/SmartPos/SmartPosEtapasContext';
import { IdentificacaoEtapasSmartPos } from 'constants/enum/IdentificacaoEtapasSmartPos';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import auth from 'modules/auth';

import { PagamentosLinkIcon } from 'icons';
import Input from 'components/PDV/Input';
import InputCpfCnpj from 'components/PDV/InputCpfCnpj';

import { FooterSmartPos } from '../Layout/FooterSmartPos';
import { HeaderSmartPos } from '../Layout/Header';
import { FormData, yupResolver } from './validationForms';

export const Autenticacao = () => {
  const { setActiveStep, setIsLoading } = useSmartPosEtapasContext();

  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
  });

  const { handleSubmit, reset } = formMethods;

  const lojaId = auth.getLoja().id;

  const handleAvancar = handleSubmit(async ({ codigoCliente, cpfCnpj }) => {
    setIsLoading(true);
    const response = await api.post<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.AUTENCICACAO_DADOS_STONE,
      {
        codigoCliente,
        cpfCnpj,
        loja: lojaId,
      }
    );
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item) => toast.warning(item));
        setIsLoading(false);
      }

      if (response.sucesso) {
        setActiveStep(IdentificacaoEtapasSmartPos.LOCAL_ESTOQUE);
      }
    }
    setIsLoading(false);
  });

  const obterAutenticacao = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get<void, ResponseApi<FormData>>(
      ConstanteEnderecoWebservice.AUTENCICACAO_OBTER_DADOS_STONE
    );
    if (response) {
      if (response.sucesso) {
        reset(response.dados);
        setIsLoading(false);
      }
    }
    setIsLoading(false);
  }, [setIsLoading, reset]);

  const handleVoltar = () => {
    setActiveStep(IdentificacaoEtapasSmartPos.GUIA_INTEGRACAO);
  };

  useEffect(() => {
    obterAutenticacao();
  }, [obterAutenticacao]);

  return (
    <FormProvider {...formMethods}>
      <ContainerIntegracaoFixo height={isLargerThan700 ? '100vh' : '100%'}>
        <HeaderSmartPos
          title="Autenticação"
          handleVoltar={handleVoltar}
          icon={PagamentosLinkIcon}
        />
        <Box pl={['10px', '10px', '20px']} pr={['10px', '10px', '20px']}>
          <VStack mb="24px" color="primary.50" fontSize="14px" spacing="24px">
            <Text
              textAlign={isLargerThan700 ? undefined : 'center'}
              letterSpacing="0px"
            >
              Informe o seu código do cliente Stone. O Stonecode é o código que
              identifica sua conta Stone. Para encontrar o código abra o
              aplicativo Stone, clique em Perfil e vá em Planos e Tarifas. Seu
              stonecode aparecerá logo abaixo do nome do seu estabelecimento.
            </Text>
          </VStack>
          <VStack spacing="24px">
            <Input
              name="codigoCliente"
              label="Codigo Cliente"
              placeholder="Inserir seu Stonecode"
              colSpan={12}
            />
            <InputCpfCnpj
              id="cpfCnpj"
              name="cpfCnpj"
              label="CNPJ / CPF"
              w="full"
              colSpan={12}
              placeholder="000.000.000-00"
            />
          </VStack>
        </Box>
      </ContainerIntegracaoFixo>
      <FooterSmartPos
        mt={['20px', '0', '0']}
        mb={['20px', '0', '0']}
        handleAvancar={handleAvancar}
      />
    </FormProvider>
  );
};
