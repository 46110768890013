import { VStack, Text, Box, Link } from '@chakra-ui/react';
import { useCallback, useImperativeHandle } from 'react';

import auth from 'modules/auth';
import { useTrayEtapasContext } from 'store/Tray';
import { capitalize } from 'helpers/format/stringFormats';
import { IdentificacaoEtapasTray } from 'constants/enum/IdentificacaoEtapasTray';

import { TrayIconPortal } from 'icons';
import { InfoTooltip } from 'components/update/Tooltip/InfoTooltip';

import { Header } from '../Layout/Header';

export const PortalCliente = () => {
  const { name: nomeSistema } = auth.getSistema();
  const { ref, setActiveStep } = useTrayEtapasContext();

  const handleAvancar = useCallback(() => {
    setActiveStep(IdentificacaoEtapasTray.AUTENTICACAO);
  }, [setActiveStep]);

  const handleVoltar = () => {
    setActiveStep(IdentificacaoEtapasTray.GUIA_INTEGRACAO);
  };

  useImperativeHandle(ref, () => ({
    handleAvancar,
    handleVoltar,
  }));

  return (
    <>
      <Header
        title="Portal do cliente"
        handleVoltar={handleVoltar}
        icon={TrayIconPortal}
      />
      <VStack
        pl={['10px', '10px', '20px']}
        pr={['10px', '10px', '20px']}
        color="primary.50"
        fontSize="14px"
        w="full"
        alignItems="left"
        spacing={['10px', '10px', '24px']}
      >
        <Text textAlign="left" w="full" letterSpacing="0px">
          Siga as etapas abaixo para autorizar a comunicação da Tray com o
          sistema {capitalize(nomeSistema)}:
        </Text>
        <Box pl="40px">
          <Text mb="4px" textAlign="left" letterSpacing="0px">
            <span style={{ fontWeight: 'bold', marginRight: '3px' }}>1)</span>{' '}
            Acesse o{' '}
            <Link
              href="https://www.tray.com.br/"
              target="_blank"
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              portal do cliente Tray;
            </Link>
          </Text>
          <Text mb="4px" textAlign="left" letterSpacing="0px">
            <span style={{ fontWeight: 'bold', marginRight: '3px' }}>2)</span>{' '}
            No menu lateral do portal Tray, selecione a opção “Meus
            aplicativos”;
          </Text>
          <Text mb="4px" textAlign="left" letterSpacing="0px">
            <span style={{ fontWeight: 'bold', marginRight: '3px' }}>3)</span>{' '}
            Clique no botão “Instalar novos aplicativos”;
          </Text>
          <Text mb="4px" textAlign="left" letterSpacing="0px">
            <span style={{ fontWeight: 'bold', marginRight: '3px' }}>4)</span>{' '}
            Digite PowerStock Cloud no campo “nome do aplicativo” e clique no
            botão “filtro”;
          </Text>
          <Text mb="4px" textAlign="left" letterSpacing="0px">
            <span style={{ fontWeight: 'bold', marginRight: '3px' }}>5)</span>Ao
            aparecer o logo do app PowerStock Cloud, clique em “Instalar
            aplicativo”;
          </Text>
          <Text mb="4px" textAlign="left" display="inline" letterSpacing="0px">
            <span style={{ fontWeight: 'bold', marginRight: '3px' }}>6)</span>{' '}
            Clique em AUTORIZAR para que o aplicativo tenha permissão de acesso
            e comunicação com o seu portal da Tray;
            <span style={{ display: 'inline-block', marginLeft: '5px' }}>
              <InfoTooltip
                bgColor="#0F0F0F"
                placement="auto-start"
                label="Em alguns casos a Tray pode apresentar uma tela solicitando nome de usuário e senha. Para esta situação simplesmente ignore e feche a tela. Trata-se de uma solicitação equivocada. Se a tela não apareceu para você, siga adiante normalmente e verifique a instalação conforme o passo 7."
              />
            </span>
          </Text>
          <Text mb="4px" textAlign="left" letterSpacing="0px">
            <span style={{ fontWeight: 'bold', marginRight: '3px' }}>7)</span>{' '}
            Pronto! Para verificar se o aplicativo foi autorizado, clique
            novamente no menu lateral do Portal Tray e selecione “meus
            aplicativos”. Se o app estiver na sua lista ele foi instalado
            corretamente. Não feche o portal, vamos utilizá-lo novamente a
            seguir.
          </Text>
        </Box>

        <Text w="full" textAlign="left">
          Se todos os itens foram confirmados, clique em “avançar” para seguir
          com a integração.
        </Text>
      </VStack>
    </>
  );
};
