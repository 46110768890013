import { useCallback, useEffect, useState } from 'react';
import { Box, Button, Flex } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import {
  buscarTabelaPrecoTrayAtiva,
  abrirModalConfirmacaoAtualizacaoTabelaPrecos,
} from 'services/tray';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import TipoTabelaPrecoEnum from 'constants/enum/tipoTabelaPrecoEnum';
import { useTrayPainelControleContext } from 'store/Tray/PainelControle';

import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import Loading from 'components/Layout/Loading/LoadingPadrao';

type Options = {
  label: string;
  value: string;
};

export type OptionResponseProps = {
  id: string;
  nome: string;
};

export const TabelaPreco = () => {
  const {
    isLoading,
    setIsLoading,
    avisoProcessoEmAndamento,
  } = useTrayPainelControleContext();

  const [tabelaPreco, setTabelaPreco] = useState<Options[]>([]);

  const formMethods = useForm();

  const { reset, handleSubmit: onSubmit } = formMethods;

  const buscarTabelasPrecoIntegracao = useCallback(async () => {
    const response = await api.get<void, ResponseApi<OptionResponseProps[]>>(
      `${ConstanteEnderecoWebservice.TABELA_PRECO_LISTAR_TABELAS_PRECO}?tipo=${TipoTabelaPrecoEnum.INTEGRACAO}`
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso && response.dados) {
        setTabelaPreco(
          response.dados.map((tabelasPreco) => {
            return {
              label: tabelasPreco.nome,
              value: tabelasPreco.id,
            } as Options;
          })
        );
      }
    }
  }, []);

  const preencherDadosTabelaPreco = useCallback(async () => {
    setIsLoading(true);
    await buscarTabelasPrecoIntegracao();
    const response = await buscarTabelaPrecoTrayAtiva();

    if (response !== null) {
      reset({ tabelaPreco: response });
    }

    setIsLoading(false);
  }, [buscarTabelasPrecoIntegracao, reset, setIsLoading]);

  const handleSubmit = onSubmit(async (data) => {
    setIsLoading(true);
    await abrirModalConfirmacaoAtualizacaoTabelaPrecos({
      tabelaPreco: data.tabelaPreco || '',
    });

    setIsLoading(false);
  });

  useEffect(() => {
    preencherDadosTabelaPreco();
  }, [preencherDadosTabelaPreco]);

  return (
    <FormProvider {...formMethods}>
      {isLoading && <Loading />}
      <Box
        pt={['24px', '24px', '40px']}
        px={['0', '0', '20px']}
        w="full"
        minW="200px"
      >
        <SelectPadrao
          name="tabelaPreco"
          id="tabelaPreco"
          label="Tabela de preços"
          isLoading={isLoading}
          isDisabled={avisoProcessoEmAndamento?.exibirAviso}
          options={tabelaPreco}
          placeholder="Selecione uma tabela de preço"
        />
        {!avisoProcessoEmAndamento?.exibirAviso && (
          <Flex
            mt="66px"
            w="full"
            justifyContent="center"
            alignItems="baseline"
          >
            <Button
              variant="outlineDefault"
              borderRadius="16px"
              colorScheme="gray"
              mr="24px"
              w="96px"
              height="32px"
              isLoading={isLoading}
              onClick={preencherDadosTabelaPreco}
            >
              Cancelar
            </Button>
            <Button
              color="black"
              variant="solid"
              colorScheme="secondary.400"
              borderRadius="16px"
              width="120px"
              height="32px"
              isLoading={isLoading}
              onClick={handleSubmit}
            >
              Confirmar
            </Button>
          </Flex>
        )}
      </Box>
    </FormProvider>
  );
};
