const baseStyleContainer = {
  border: '1px solid #cccccc',
  boxShadow: 'md',
  borderRadius: 'md',
  overflow: 'hidden',
};

const baseStyleButton = {
  transitionProperty: 'common',
  transitionDuration: 'normal',
  fontSize: 'xs',
  fontWeight: 'semibold',
  bg: 'gray.50',
  _focus: {
    boxShadow: 'none',
  },
  _hover: {
    bg: 'gray.50',
  },
  _disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
  },
  px: 4,
  py: 2,
};

const baseStylePanel = {
  p: { base: 6, md: 8 },
};

const baseStyle = {
  container: baseStyleContainer,
  button: baseStyleButton,
  panel: baseStylePanel,
};

const Accordion = {
  baseStyle,
};

export default Accordion;
