import React from 'react';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

import TransportadorasListar from '../pages/Transportadoras/Listar';
import TransportadorasCadastrar from '../pages/Transportadoras/Formulario/Cadastrar';
import TransportadorasAlterar from '../pages/Transportadoras/Formulario/Alterar';
import TransportadorasVisualizar from '../pages/Transportadoras/Formulario/Visualizar';

const TransportadorasRoutes = [
  <LayoutGuard
    key="transportadoras-listar"
    permissaoFuncionalidade={ConstanteFuncionalidades.TRANSPORTADORA_LISTAR}
    component={TransportadorasListar}
    breadcrumb={[
      {
        title: 'Cadastros',
      },
      {
        title: 'Transportadoras',
      },
    ]}
    path={ConstanteRotas.TRANSPORTADORA}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="transportadoras-cadastrar"
    permissaoFuncionalidade={ConstanteFuncionalidades.TRANSPORTADORA_CADASTRAR}
    component={TransportadorasCadastrar}
    breadcrumb={[
      {
        title: 'Cadastros',
      },
      {
        title: 'Transportadoras',
        path: ConstanteRotas.TRANSPORTADORA,
      },
      {
        title: 'Novo',
      },
    ]}
    path={ConstanteRotas.TRANSPORTADORA_CADASTRAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="transportadoras-alterar"
    permissaoFuncionalidade={ConstanteFuncionalidades.TRANSPORTADORA_ALTERAR}
    component={TransportadorasAlterar}
    breadcrumb={[
      {
        title: 'Cadastros',
      },
      {
        title: 'Transportadoras',
        path: ConstanteRotas.TRANSPORTADORA,
      },
      {
        title: 'Editar',
      },
    ]}
    path={ConstanteRotas.TRANSPORTADORA_ALTERAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="transportadoras-visualizar"
    permissaoFuncionalidade={ConstanteFuncionalidades.TRANSPORTADORA_VISUALIZAR}
    component={TransportadorasVisualizar}
    breadcrumb={[
      {
        title: 'Cadastros',
      },
      {
        title: 'Transportadoras',
        path: ConstanteRotas.TRANSPORTADORA,
      },
      {
        title: 'Visualizar',
      },
    ]}
    path={ConstanteRotas.TRANSPORTADORA_VISUALIZAR}
    exact
    meta={{ auth: true }}
  />,
];

export default TransportadorasRoutes;
