import {
  Text,
  Td,
  Tr,
  HStack,
  SlideFade,
  Divider,
  VStack,
  Badge,
} from '@chakra-ui/react';

import { formatDate } from 'helpers/format/formatStringDate';
import { moneyMask } from 'helpers/format/fieldsMasks';

import { InformacoesVoucherProps } from '../validationForms';

export function InformacoesVoucher({
  isOpen,
  vouchers,
}: InformacoesVoucherProps) {
  return (
    <Tr borderTop="none !important" border="1px solid" borderColor="gray.100">
      <Td colSpan={7} pt="0" pb="10px" mb="40px" border="none">
        <Divider orientation="horizontal" color="gray.100" mt="15px" />
        <SlideFade in={isOpen}>
          <VStack alignItems="flex-start" mt="10px" ml="25px">
            <Badge>Usado em</Badge>
            {vouchers.vendasComVale?.map((voucher) => (
              <HStack>
                <Text fontSize="small" fontWeight="bold">
                  {formatDate(voucher.dataEmissao)}
                </Text>
                <Badge colorScheme="purple">Venda</Badge>
                <Text fontSize="small" fontWeight="bold" color="purple.500">
                  {voucher.numeroVenda}
                </Text>
                <Divider orientation="vertical" height="20px" />
                <Badge colorScheme="red">Valor usado:</Badge>
                <Text fontSize="small" fontWeight="semibold" color="red.500">
                  {moneyMask(voucher.valor, true)}
                </Text>
                <Divider orientation="vertical" height="20px" />
              </HStack>
            ))}
          </VStack>
        </SlideFade>
      </Td>
    </Tr>
  );
}
