import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';
import { ProdutoProps } from '../../validationForm';

type ListProdutoProps = {
  nome: string;
  id: string;
};

type OptionProps = {
  label: string;
  value: string;
};

export const useTabProdutoFavorito = () => {
  const [optionsLoading, setOptionsLoading] = useState(false);

  const { watch, setValue } = useFormContext();

  const productItem = watch('productItem') as OptionProps;
  const listProducts = watch('listProducts') as Pick<
    ProdutoProps,
    'id' | 'nome'
  >[];

  const getProdutoCorOptions = useCallback(async (inputValue: string) => {
    setOptionsLoading(true);

    const response = await api.get<void, ResponseApi<ListProdutoProps[]>>(
      ConstanteEnderecoWebservice.PRODUTO_LISTAR_SELECT,
      {
        params: { produtoNomeReferencia: inputValue },
      }
    );

    if (response?.avisos) {
      response.avisos.forEach((aviso: string) => toast.warning(aviso));
    }

    if (response && response.sucesso && response.dados) {
      const data = response.dados.map((option) => ({
        label: option.nome,
        value: option.id,
      }));

      setOptionsLoading(false);

      return data;
    }

    setOptionsLoading(false);
    return [];
  }, []);

  const handleCadastrarProduto = () => {
    const data = {
      id: productItem?.value,
      nome: productItem?.label,
    };

    if (data.id && data.nome) {
      const productAlreadyAdded = listProducts.some(
        (produto) => produto.id === data.id
      );

      if (productAlreadyAdded) {
        toast.warning('Esse produto já foi adicionado');
        return;
      }

      const newListProducts = [...listProducts, data];

      setValue('listProducts', newListProducts);
      setValue('productItem', null);
      return;
    }

    toast.warning('Selecione ao menos um produto para adicionar');
  };

  const handleRemoverProduto = (index: number) => {
    const newListProducts = [...listProducts];

    newListProducts.splice(index, 1);

    setValue('listProducts', newListProducts);
  };

  useEffect(() => {
    const productsId = listProducts?.map((product) => product.id);

    setValue('produtosFavoritos', productsId);
  }, [listProducts, setValue]);

  return {
    getProdutoCorOptions,
    handleCadastrarProduto,
    listProducts,
    optionsLoading,
    handleRemoverProduto,
    productItem,
  };
};
