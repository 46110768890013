import React, {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
} from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { getCredenciamentoZoop } from 'services/zoop';
import { enumStatusCredenciamento } from 'constants/enum/enumStatusCredenciamento';
import { enumEtapasZoop } from 'constants/enum/enumEtapasZoop';
import ConstanteRotas from 'constants/rotas';

interface ZoopEtapasContextProps {
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  dadosCredenciamento?: DadosCredenciamento;
}

export const ZoopEtapasContext = createContext<ZoopEtapasContextProps>(
  {} as ZoopEtapasContextProps
);

interface ZoopEtapasProviderProps {
  children: React.ReactNode;
}

type DadosCredenciamento = {
  dateCredenciamento?: Date;
  credenciamentoSolicitado: boolean;
  credenciamentoAprovado: boolean;
  statusCredenciamento: string;
};

export function ZoopEtapasProvider({
  children,
}: ZoopEtapasProviderProps): JSX.Element {
  const [activeStep, setActiveStep] = useState(enumEtapasZoop.GUIA_INTEGRAÇÃO);
  const [isLoading, setIsLoading] = useState(false);
  const [
    dadosCredenciamento,
    setDadosCredenciamento,
  ] = useState<DadosCredenciamento>();
  const history = useHistory();

  const obterCredenciamento = useCallback(async () => {
    setIsLoading(true);
    const response = await getCredenciamentoZoop();

    if (response) {
      if (response?.sucesso && response?.dados) {
        if (response?.avisos) {
          response.avisos?.forEach((aviso) => toast.warning(aviso));
        }
        const {
          credenciamentoSolicitado,
          credenciamentoAprovado,
          dataCredenciamento,
          contaBancariaVinculada,
          statusCredenciamento,
        } = response?.dados;

        if (credenciamentoSolicitado && contaBancariaVinculada) {
          setDadosCredenciamento({
            dateCredenciamento: dataCredenciamento,
            credenciamentoSolicitado,
            credenciamentoAprovado,
            statusCredenciamento:
              statusCredenciamento || enumStatusCredenciamento.PENDING,
          });
          history.push(ConstanteRotas.ZOOP_DETALHES_INTEGRACAO);
        } else if (credenciamentoSolicitado && !contaBancariaVinculada) {
          setActiveStep(enumEtapasZoop.CONTA_BANCARIA);
        }
      } else {
        setActiveStep(enumEtapasZoop.GUIA_INTEGRAÇÃO);
      }
    } else {
      setActiveStep(enumEtapasZoop.GUIA_INTEGRAÇÃO);
    }
    setIsLoading(false);
  }, [history]);

  useEffect(() => {
    obterCredenciamento();
  }, [obterCredenciamento]);

  return (
    <ZoopEtapasContext.Provider
      value={{
        activeStep,
        setActiveStep,
        isLoading,
        setIsLoading,
        dadosCredenciamento,
      }}
    >
      {children}
    </ZoopEtapasContext.Provider>
  );
}

export function useZoopEtapasContext(): ZoopEtapasContextProps {
  const context = useContext(ZoopEtapasContext);

  if (!context)
    throw new Error(
      'ZoopEtapasContext must be used within a ZoopEtapasProvider.'
    );

  return context;
}
