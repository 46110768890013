type AccessibleColor = {
  color?: string;
  background?: string;
  borderColor?: string;
  focusBackground?: string;
  activeBackground?: string;
};

/** Accessible color overrides for less accessible colors. */
const accessibleColorMap: { [key: string]: AccessibleColor } = {
  white: {
    color: 'black',
    background: 'white',
    borderColor: 'rgba(0, 0, 0, 0.1)',
    focusBackground: 'rgba(0, 0, 0, 0.1)',
    activeBackground: 'rgba(0, 0, 0, 0.1)',
  },
  system: {
    background: 'pdv.menuButton',
    activeBackground: 'pdv.menuButton',
    borderColor: 'pdv.menuBorder',
    focusBackground: 'pdv.focus',
  },
  systemHeader: {
    background: 'pdv.header',
    color: 'pdv.headerTextColor',
    borderColor: 'transparent',
    focusBackground: 'pdv.modalHover',
  },
  secondary: {
    color: 'black',
  },
};

type Dict = Record<string, any>;

const Menu = {
  parts: ['item', 'command', 'list', 'button', 'groupTitle', 'divider'],

  baseStyle: ({ colorScheme }: Dict) => {
    const {
      color = 'white',
      background = `${colorScheme}.500`,
      borderColor = `${colorScheme}.600`,
      focusBackground = `${colorScheme}.600`,
      activeBackground = `${colorScheme}.600`,
    } = accessibleColorMap[colorScheme] || {};

    return {
      button: {
        color,
      },
      item: {
        color,
        px: '2.35rem',
        transition: 'background 50ms ease-in 0s, filter 50ms ease-in 0s',
        _focus: {
          bg: focusBackground,
          _disabled: { filter: 'none' },
        },
        _active: {
          bg: activeBackground,
          _disabled: { filter: 'none' },
        },
        _expanded: {
          bg: focusBackground,
          _disabled: { filter: 'none' },
        },
      },
      list: {
        color,
        bg: background,
        borderColor,
        boxShadow: '0px 0px 4px 0px #00000029',
      },
    };
  },

  defaultProps: {
    colorScheme: 'white',
  },
};

export default Menu;
