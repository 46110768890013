import React, { useState, useCallback, useRef, useEffect } from 'react';
import { GridItem, Td, Tr, Box, Flex, useMediaQuery } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import ConstanteFuncionalidades from 'constants/permissoes';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { formatDateHourMinuteSecond } from 'helpers/format/formatStringDate';
import auth from 'modules/auth';

import { LupaIcon } from 'icons';
import { BuscaAvancadaButton } from 'components/update/BuscaAvancadaButton';
import InputDateRange from 'components/PDV/InputDateRange';
import { SearchInput } from 'components/update/Input/SearchInput';
import {
  GridPaginadaConsulta,
  GridPaginadaRetorno,
} from 'components/Grid/Paginacao';
import {
  PagedTable,
  PagedTableForwardRefData,
} from 'components/update/Table/PagedTable';

import { BuscaAvancadaModal } from './BuscaAvancadaModal';
import { VisualizarDetalhesModal } from './ModalVisualizar';
import {
  UsuarioInterface,
  LogAuditoriaFiltros,
  LogAuditoria,
} from '../Types/validationForms';

const formDefaultValues = {
  dataInicio: undefined,
  dataFim: undefined,
  tela: 0,
  operacao: 0,
  usuario: '',
  descricao: '',
};

const Listar = () => {
  const isMountedRef = useIsMountedRef();
  const formMethods = useForm<LogAuditoriaFiltros>({
    defaultValues: formDefaultValues,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [logsAuditoria, setLogsAuditoria] = useState<LogAuditoria[]>([]);
  const [usuarios, setUsuarios] = useState<UsuarioInterface[]>([]);

  const pageIsLoaded = useRef(false);
  const childRef = useRef<PagedTableForwardRefData>(null);
  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');

  const [filtrosAtuais, setFiltrosAtuais] = useState<LogAuditoriaFiltros>();
  const [filtrosAvancadosAtivos, setfiltrosAvancadosAtivos] = useState(false);

  const handleGetUsuarioValues = useCallback(async () => {
    const response = await api.get<void, ResponseApi<UsuarioInterface[]>>(
      ConstanteEnderecoWebservice.LOG_AUDITORIA_LISTAR_USUARIOS
    );

    if (response?.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (response?.sucesso) {
      setUsuarios(response.dados);
    }
  }, []);

  const paginationHandle = useCallback(
    async (gridPaginadaConsulta: GridPaginadaConsulta) => {
      setIsLoading(true);

      let filtros = filtrosAtuais
        ? { ...filtrosAtuais }
        : formMethods.getValues();

      if (filtros.dataFim) {
        filtros = {
          ...filtros,
          dataFim: filtros.dataFim,
        };
      }

      if (filtros.dataInicio) {
        filtros = {
          ...filtros,
          dataInicio: filtros.dataInicio,
        };
      }

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<LogAuditoria>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.LOG_AUDITORIA_LISTAR_PAGINADO,
          gridPaginadaConsulta
        ),
        {
          params: filtros,
        }
      );

      if (response?.sucesso && isMountedRef.current) {
        setTotalRegistros(response.dados.total);
        setLogsAuditoria(response.dados.registros);
      }

      if (isMountedRef.current) {
        setIsLoading(false);

        if (!pageIsLoaded.current) {
          pageIsLoaded.current = true;

          formMethods.setFocus('descricao');
        }
      }
    },
    [isMountedRef, formMethods, filtrosAtuais]
  );

  const handleReset = useCallback(() => {
    const filtros = filtrosAtuais;

    if (JSON.stringify(filtros) !== JSON.stringify(filtrosAtuais)) {
      pageIsLoaded.current = true;
      return childRef.current?.reload();
    }

    return () => {};
  }, [filtrosAtuais]);

  const handleHide = useCallback(
    (willReset?: boolean) => {
      if (willReset) {
        setfiltrosAvancadosAtivos(false);
        setFiltrosAtuais(formDefaultValues);
        formMethods.reset();
      }
    },
    [formMethods]
  );

  const handleSubmit = useCallback(
    (novosFiltros: LogAuditoriaFiltros) => {
      handleReset();

      setFiltrosAtuais(novosFiltros);

      formMethods.setValue('descricao', '');
      formMethods.setValue('tela', novosFiltros.tela);
      formMethods.setValue('operacao', novosFiltros.operacao);
      formMethods.setValue('usuario', novosFiltros.usuario);
      formMethods.setValue('dataInicio', novosFiltros.dataInicio);
      formMethods.setValue('dataFim', novosFiltros.dataFim);

      setfiltrosAvancadosAtivos(true);
    },
    [formMethods, handleReset]
  );

  const handleAbrirModal = useCallback(() => {
    const filtros = filtrosAtuais
      ? { ...filtrosAtuais }
      : formMethods.getValues();

    BuscaAvancadaModal({
      usuarios,
      filtros,
      onHide: handleHide,
      onSubmit: handleSubmit,
    });
  }, [filtrosAtuais, formMethods, handleHide, handleSubmit, usuarios]);

  const permissaoAlterarVisualizarHistoricoAcoes = auth.possuiPermissao(
    ConstanteFuncionalidades.HISTORICO_ACOES_VISUALIZAR
  );

  const handleSubmitFiltros = formMethods.handleSubmit((data) => {
    handleReset();
    formMethods.setValue('descricao', '');

    setFiltrosAtuais(data);

    setfiltrosAvancadosAtivos(true);
  });

  useEffect(() => {
    handleGetUsuarioValues();
  }, [handleGetUsuarioValues]);

  return (
    <Box>
      <FormProvider {...formMethods}>
        <Flex direction={isSmallerThan900 ? 'column' : 'row'}>
          <Box
            mb={isSmallerThan900 ? '15px' : undefined}
            w={isSmallerThan900 ? 'full' : '40%'}
            mr={isSmallerThan900 ? undefined : '30px'}
          >
            <SearchInput
              placeholder="Buscar log por descrição ou usuário"
              id="descricao"
              isDisabled={isLoading}
              onEnterKeyPress={() => setFiltrosAtuais(formMethods.getValues())}
              {...formMethods.register('descricao')}
            />
          </Box>

          <Box
            w={isSmallerThan900 ? 'full' : '300px'}
            mr={isSmallerThan900 ? undefined : '30px'}
            mb={isSmallerThan900 ? '15px' : undefined}
          >
            <InputDateRange
              borderColor="gray.100"
              borderRadius="md"
              placeholder="Selecione um período"
              onConfirm={handleSubmitFiltros}
              startDateName="dataInicio"
              endDateName="dataFim"
              maxDate={new Date()}
            />
          </Box>
          <Box>
            <BuscaAvancadaButton
              setIsModalBuscaAvancadaOpen={handleAbrirModal}
              hasFilters={filtrosAvancadosAtivos}
              isDisabled={isLoading}
              borderRadius="5px"
              width={isSmallerThan900 ? 'full' : '200px'}
            />
          </Box>
        </Flex>

        <GridItem mt="25px" colSpan={12}>
          <PagedTable
            ref={childRef}
            defaultOrderDirection="desc"
            defaultKeyOrdered="DataHora"
            itemsTotalCount={totalRegistros}
            loadColumnsData={paginationHandle}
            isLoading={isLoading}
            tableHeaders={[
              {
                content: 'Data',
                key: 'DataHora',
                isOrderable: true,
                width: '200px',
              },
              {
                content: 'Usuário',
                key: 'Usuario',
                isOrderable: false,
                width: '100px',
              },
              {
                content: 'Operação',
                key: 'Operacao',
                isOrderable: false,
                width: '50px',
              },
              {
                content: 'Tela',
                key: 'Tela',
                isOrderable: false,
                width: '20px',
              },
              {
                content: 'Descrição',
                key: 'Descricao',
                isOrderable: false,
                width: 'auto',
              },
            ]}
            renderTableRows={logsAuditoria.map((logAuditoria) => (
              <Tr key={logAuditoria.id}>
                <Td>
                  <Flex alignItems="center" h="100%">
                    {permissaoAlterarVisualizarHistoricoAcoes.permitido && (
                      <Box>
                        <LupaIcon
                          style={{
                            fontSize: ' 1.375rem',
                            cursor: 'pointer',
                            marginRight: '10px',
                          }}
                          onClick={async () => {
                            await VisualizarDetalhesModal({
                              informacaoDetalhe: logAuditoria,
                            });
                          }}
                        />
                      </Box>
                    )}

                    {formatDateHourMinuteSecond(logAuditoria.dataHoraFormatada)}
                  </Flex>
                </Td>
                <Td>{logAuditoria.usuario}</Td>
                <Td>{logAuditoria.descricaoOperacao}</Td>
                <Td>{logAuditoria.descricaoTela}</Td>
                <Td>
                  <Box
                    style={
                      permissaoAlterarVisualizarHistoricoAcoes.permitido
                        ? {
                            cursor: 'pointer',
                            marginLeft: 0,
                            paddingLeft: 0,
                            marginRight: 0,
                            paddingRight: 0,
                          }
                        : {
                            cursor: 'unset',
                            marginLeft: 0,
                            paddingLeft: 0,
                            marginRight: 0,
                            paddingRight: 0,
                          }
                    }
                    onClick={async () => {
                      if (permissaoAlterarVisualizarHistoricoAcoes.permitido) {
                        await VisualizarDetalhesModal({
                          informacaoDetalhe: logAuditoria,
                        });
                      }
                    }}
                  >
                    {logAuditoria.descricao}
                  </Box>
                </Td>
              </Tr>
            ))}
          />
        </GridItem>
      </FormProvider>
    </Box>
  );
};

export default Listar;
