import React, { useCallback, useState } from 'react';
import {
  ModalProps as ModalChakraProps,
  ModalContent,
  ModalBody,
  Icon,
  Flex,
  useMediaQuery,
  useDisclosure,
  ModalHeader,
  VStack,
  Text,
  ModalFooter,
  HStack,
  Button,
  GridItem,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import { SalvarInserirNovoIcon } from 'icons';
import { Switch } from 'components/update/Switch';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import AsyncSelect from 'components/PDV/Select/AsyncSelectPadrao';

import { Variations, TamanhoProps } from '../../variations';
import { FormData, yupResolver } from './validationForm';

type SelectProdutoCorResponse = {
  id: string;
  nome: string;
  ativo: boolean;
  nomeEcommerce: null | string;
};

export type ProdutoResponse = {
  tamanhos: Omit<TamanhoProps, 'nome'>[];
  produtoPrincipalId: string;
  produtoItemId: string;
  materiaPrimaFinal: boolean;
};

type ModalProps = {
  casasDecimais: number;
  listSizes: TamanhoProps[];
  idProduto: string;
  isDefaultSize: boolean;
  callback: (data: ProdutoResponse) => Promise<{ success: boolean }>;
};

type ModalResponse = {
  deveReiniciar?: boolean;
};

type ModalProdutoAdicionarProps = Omit<
  ModalChakraProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalResponse> &
  ModalProps;

export const ModalProdutoAdicionar = create<
  ModalProdutoAdicionarProps,
  ModalResponse
>(
  ({
    onResolve,
    onReject,
    casasDecimais,
    listSizes,
    idProduto,
    callback,
    isDefaultSize,
    ...rest
  }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [produtoCorIsLoading, setProdutoCorIsLoading] = useState(false);

    const formMethods = useForm<FormData>({
      resolver: yupResolver,
      defaultValues: {
        materiaPrimaFinal: true,
      },
    });

    const { handleSubmit: onSubmit, watch } = formMethods;

    const { product: watchProduct } = watch();

    const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const getProdutos = useCallback(async (inputValue: string) => {
      setProdutoCorIsLoading(true);

      const response = await api.get<
        void,
        ResponseApi<SelectProdutoCorResponse[]>
      >(ConstanteEnderecoWebservice.LISTAR_SELECT_FICHA_TECNICA, {
        params: { pesquisa: inputValue },
      });

      if (response?.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }

      if (response && response.sucesso && response.dados) {
        const data = response.dados.map((option) => ({
          label: option.nome,
          value: option.id,
        }));

        setProdutoCorIsLoading(false);

        return data;
      }

      setProdutoCorIsLoading(false);
      return [];
    }, []);

    const dadosProduto = (valueForm: FormData): ProdutoResponse => {
      const tamanhos = listSizes.map((size, index) => ({
        id: size.id,
        quantidade: valueForm?.quantidade[index] || 0,
      }));

      return {
        tamanhos,
        produtoPrincipalId: idProduto,
        produtoItemId: valueForm?.product?.value || '',
        materiaPrimaFinal: valueForm.materiaPrimaFinal,
      };
    };

    const handleSubmit = onSubmit(async (data) => {
      setIsLoading(true);

      if (!data.product) {
        toast.warning('Selecione ao menos um produto para adicionar');

        setIsLoading(false);
        return;
      }

      const produtos = dadosProduto(data);
      const { success } = await callback(produtos);

      if (success) {
        onResolve({
          deveReiniciar: false,
        });
      }

      setIsLoading(false);
    });

    const handleInserirNovo = onSubmit(async (data) => {
      setIsLoading(true);

      if (!data.product) {
        toast.warning('Selecione ao menos um produto para adicionar');

        setIsLoading(false);
        return;
      }

      const produtos = dadosProduto(data);
      const { success } = await callback(produtos);

      if (success) {
        onResolve({
          deveReiniciar: true,
        });
      }

      setIsLoading(false);
    });

    return (
      <ModalPadraoChakra
        isCentered
        size={!isSmallerThan900 ? '3xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalContent px={{ base: '8px', md: '16px' }} bg="gray.50">
          {isLoading && <LoadingPadrao />}
          <ModalHeader pt={{ base: '12px', md: '24px' }}>
            <VStack alignItems="flex-start" spacing="1" lineHeight="1">
              <Text color="primary.500" mt={{ base: 4, md: 0 }}>
                Adicionar produtos
              </Text>
            </VStack>
          </ModalHeader>
          <ModalBody p={{ base: 6, md: 8 }}>
            <FormProvider {...formMethods}>
              <Flex
                alignItems="baseline"
                flexDirection="column"
                w="full"
                h="full"
              >
                <SimpleGridForm w="full">
                  <GridItem w="full" colSpan={{ base: 12, md: 9 }}>
                    <AsyncSelect
                      id="product"
                      name="product"
                      label="Descrição do produto"
                      placeholder="Digite o nome do produto"
                      handleGetOptions={getProdutos}
                      isLoading={produtoCorIsLoading}
                      asControlledByObject
                      withoutDefaultOptions
                      autoFocus
                    />
                  </GridItem>
                  {watchProduct && (
                    <>
                      <GridItem colSpan={{ base: 12, md: 3 }}>
                        <Switch
                          id="materiaPrimaFinal"
                          name="materiaPrimaFinal"
                          label="Matéria prima final"
                          size="md"
                        />
                      </GridItem>
                      <Variations
                        listSizes={listSizes}
                        decimalPlaces={casasDecimais}
                        isDefaultSize={isDefaultSize}
                      />
                    </>
                  )}
                </SimpleGridForm>
              </Flex>
            </FormProvider>
          </ModalBody>
          <ModalFooter
            justifyContent="flex-end"
            mx={{ base: 6, md: 8 }}
            py="40px"
          >
            <HStack
              flexDirection={{ base: 'column', md: 'row' }}
              justifyContent="center"
              alignItems="center"
              gap={6}
              flex="1"
            >
              <Button
                variant="outlineDefault"
                minW="100px"
                colorScheme="gray"
                w={{ base: 'full', md: '100px' }}
                onClick={onClose}
              >
                Cancelar
              </Button>
              <Button
                variant="outlineDefault"
                minW="100px"
                colorScheme="gray"
                w={{ base: 'full', md: '100px' }}
                onClick={handleSubmit}
              >
                Confirmar
              </Button>
              <Button
                borderRadius="2xl"
                colorScheme="secondary"
                minW="225px"
                w={{ base: 'full', md: '225px' }}
                leftIcon={<Icon as={SalvarInserirNovoIcon} fontSize="lg" />}
                onClick={handleInserirNovo}
              >
                Confirmar e inserir novo
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
