import { ElementType, useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionPanel,
  AccordionItem,
  Box,
  Button,
  ButtonProps,
  Grid,
  HStack,
  Icon,
  TabPanel,
  TabPanels,
  Tabs,
  TabsProps as ChakraTabsProps,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FiChevronRight, FiChevronDown } from 'react-icons/fi';

interface OptionButtonProps extends Omit<ButtonProps, 'children'> {
  icon?: ElementType;
  title: string;
  isActive?: boolean;
  isSecondary?: boolean;
  hasArrow?: boolean;
}

const OptionButton = ({
  icon,
  title,
  isActive,
  isSecondary = false,
  hasArrow = false,
  ...rest
}: OptionButtonProps) => (
  <Button
    variant="unstyled"
    display="flex"
    borderRadius="none"
    justifyContent="space-between"
    w="full"
    h="54px"
    boxShadow="none !important"
    bg={isActive ? 'gray.100' : 'transparent'}
    _hover={{
      textDecoration: 'underline',
      color: 'primary.50',
      '& > div': { color: 'primary.50' },
    }}
    p={isSecondary ? 8 : 6}
    py={0}
    {...rest}
  >
    <HStack spacing={4} color="gray.700">
      {icon && <Icon as={icon} boxSize={6} />}

      <Text fontSize="md" color="currentColor">
        {title}
      </Text>
    </HStack>

    {!isSecondary && (
      <Icon
        as={FiChevronRight}
        color="gray.300"
        boxSize={4}
        ml={8}
        opacity={hasArrow ? '1' : '0'}
      />
    )}
  </Button>
);

export interface OptionsProps {
  title: string;
  onClick: () => void;
  isDisabled?: boolean;
  isHidden?: boolean;
}

interface TabsProps {
  icon: ElementType;
  title: string;
  onClick?: () => void;
  options?: OptionsProps[];
  isDisabled?: boolean;
  isHidden?: boolean;
}

export interface TabsMenuProps
  extends Omit<ChakraTabsProps, 'children' | 'index'> {
  title?: string;
  tabs: TabsProps[];
  defaultIndex?: number;
  asAccordion?: boolean;
  onClick?(): void;
}

const TabsMenu = ({
  title,
  tabs,
  defaultIndex = 0,
  asAccordion,
  ...rest
}: TabsMenuProps) => {
  const [tabIndex, setTabIndex] = useState(defaultIndex);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  if (asAccordion) {
    return (
      <Accordion
        allowToggle
        index={tabIndex}
        defaultIndex={defaultIndex}
        bg="primary.50"
        p={0}
      >
        {tabs.map(
          (
            {
              title: tabTitle,
              icon,
              onClick,
              options,
              isDisabled: tabIsDisabled,
              isHidden: tabsIsHidden,
            },
            index
          ) =>
            tabsIsHidden ? null : (
              <AccordionItem
                key={title}
                boxShadow="none"
                border="none"
                borderRadius="none"
                sx={{ '&  button.chakra-button': { mb: '4' } }}
              >
                <AccordionButton
                  display="flex"
                  justifyContent="space-between"
                  px={10}
                  py={4}
                  _expanded={{
                    bg: 'primary.50',
                    border: 'none',
                    '& > svg': { transform: 'rotate(180deg)' },
                  }}
                  _hover={{
                    bg: 'primary.50',
                  }}
                  onClick={() => {
                    handleTabsChange(tabIndex === index ? -1 : index);
                    if (onClick) onClick();
                  }}
                  disabled={tabIsDisabled}
                  bg="transparent"
                  boxShadow="none"
                  color="white"
                  borderBottom="1px"
                  borderColor="pdv.divider"
                >
                  <HStack spacing={4} color="white">
                    {icon && <Icon as={icon} boxSize={6} />}

                    <Text fontSize="md">{tabTitle}</Text>
                  </HStack>

                  {options && (
                    <Icon
                      as={FiChevronDown}
                      color="gray.300"
                      boxSize={4}
                      ml={8}
                      transition="all"
                    />
                  )}
                </AccordionButton>
                <AccordionPanel bg="white" p={0} px="4" borderRadius="none">
                  {options && (
                    <VStack spacing={0} pt="4" px="14">
                      {options.map(
                        (
                          {
                            title: optionTitle,
                            onClick: optionOnClick,
                            isDisabled: optionIsDisabled,
                            isHidden: optionIsHidden,
                          },
                          optionIndex
                        ) =>
                          optionIsHidden ? null : (
                            <Button
                              key={optionTitle}
                              variant="link"
                              textDecoration="none"
                              _active={{ textDecoration: 'none' }}
                              _focus={{ textDecoration: 'none' }}
                              borderRadius="none"
                              w="full"
                              justifyContent="flex-start"
                              onClick={optionOnClick}
                              mt={0}
                              mb={4}
                              isDisabled={optionIsDisabled}
                              autoFocus={optionIndex === 0}
                            >
                              {optionTitle}
                            </Button>
                          )
                      )}
                    </VStack>
                  )}
                </AccordionPanel>
              </AccordionItem>
            )
        )}
      </Accordion>
    );
  }

  return (
    <Tabs w="fit-content" index={tabIndex} defaultIndex={tabIndex} {...rest}>
      <Grid templateColumns="auto 1fr">
        <Box>
          {title && (
            <Text fontSize="sm" color="primary.50" mb={4}>
              {title}
            </Text>
          )}
          {tabs.map(
            (
              {
                title: tabTitle,
                icon,
                onClick,
                isDisabled: tabIsDisabled,
                isHidden,
                options,
              },
              index
            ) => (
              <OptionButton
                key={tabTitle}
                icon={icon}
                title={tabTitle}
                onClick={() => {
                  handleTabsChange(index);
                  if (onClick) onClick();
                }}
                isActive={tabIndex === index}
                isDisabled={tabIsDisabled}
                visibility={isHidden ? 'hidden' : 'visible'}
                hasArrow={(options || []).length > 0}
              />
            )
          )}
        </Box>
        <TabPanels sx={{ '& > div': { p: 0 } }}>
          {tabs.map(({ title: tabTitle, options }) => (
            <TabPanel
              key={tabTitle}
              h="full"
              display="flex"
              flexDirection="column"
            >
              {options && (
                <>
                  <Text
                    opacity={0}
                    fontSize="sm"
                    color="primary.50"
                    px={8}
                    mb={4}
                  >
                    {tabTitle}
                  </Text>

                  <Box bg="gray.100" h="full">
                    {options.map(
                      (
                        {
                          title: optionTitle,
                          onClick,
                          isDisabled: optionIsDisabled,
                          isHidden: optionIsHidden,
                        },
                        optionIndex
                      ) =>
                        optionIsHidden ? null : (
                          <OptionButton
                            key={optionTitle}
                            title={optionTitle}
                            onClick={onClick}
                            isSecondary
                            isDisabled={optionIsDisabled}
                            autoFocus={optionIndex === 0}
                          />
                        )
                    )}
                  </Box>
                </>
              )}
            </TabPanel>
          ))}
        </TabPanels>
      </Grid>
    </Tabs>
  );
};

export default TabsMenu;
