import {
  useEffect,
  useState,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
} from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Box } from '@chakra-ui/react';

import { TipoOcorrenciaEnum } from 'constants/enum/tipoOcorrencia';
import TipoContaEnum from 'constants/enum/tipoContaEnum';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import isPrenvent from 'helpers/layout/isPrenvent';
import useReloadRegistration from 'helpers/layout/useReloadRegistration';
import { formatDateToNow } from 'helpers/format/formatData';
import { SubstituirParametroRota } from 'constants/rotas';

import ManterFoco from 'components/Geral/ManterFoco';
import Loading from 'components/Layout/Loading/LoadingPadrao';
import { FormFooter } from 'components/update/Form/FormFooter';

import { FormularioLancamentoContas } from './Form';
import { FormData, yupResolver } from './Form/validationForm';

interface CadastrarLancamentoContasProps {
  rotaAtual: string;
  idRota?: string;
  rotaRetorno: string;
}

export type TipoOperacaoProps = {
  name: string;
};

const CadastrarLancamento: ForwardRefRenderFunction<
  TipoOperacaoProps,
  CadastrarLancamentoContasProps
> = ({ rotaAtual, rotaRetorno, idRota }, ref) => {
  const history = useHistory();

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues: {
      tipoOcorrencia: TipoOcorrenciaEnum.UNICA,
      dataCompetencia: new Date(),
      dataLancamento: new Date(),
      dataBaixa: new Date(),
      dataVencimento: new Date(),
      faturaCartaoCredito: new Date(),
      repeticoes: 1,
    },
  });

  const {
    formState: { errors },
  } = formMethods;

  const [parcelas, tipoOcorrencia] = formMethods.watch([
    'parcelas',
    'tipoOcorrencia',
  ]);

  const formaPagamento = formMethods.watch('formaPagamento');

  const parcelasSaoValidas =
    tipoOcorrencia !== TipoOcorrenciaEnum.PARCELADA ||
    (parcelas && parcelas.length > 0) ||
    formaPagamento?.lancarFatura;

  const [isLoading, setIsLoading] = useState(false);

  const [isPrenvented, setIsPrenvented] = useState(false);
  isPrenvent(isPrenvented);

  const historyReload = useReloadRegistration({
    setFormIsDirty: setIsPrenvented,
  });

  const location = window.location.pathname;
  const nameCurrentOperation = location.includes('contas-receber')
    ? TipoContaEnum.CONTA_RECEBER
    : TipoContaEnum.CONTA_PAGAR;

  useImperativeHandle(ref, () => ({
    name: nameCurrentOperation,
  }));

  async function postLancamentoContas(data: FormData) {
    const {
      clienteFornecedor,
      formaPagamento: dataFormaPagamento,
      planoConta: dataPlanoConta,
      contaFinanceira,
      tipoOcorrencia: typeOfOccurence,
      numeroDocumento,
      formaRecebimento,
      qtdParcela,
      dataLancamento,
      historico,
      dataVencimento,
      dataCompetencia,
      juros,
      multa,
      desconto,
      repeticoes,
      tipoCompetencia,
      valorTotal,
      baixarConta,
      dataBaixa,
      faturaCartaoCredito,
      valorTotalBaixa,
    } = data;

    const ParcelasContas = !data?.parcelas
      ? null
      : Object.entries(data.parcelas).map((item) => ({
          parcela: Number(
            item[1].parcela.slice(0, item[1].parcela.indexOf('/'))
          ),
          parcelaExibicao: item[1].parcela,
          vencimento: item[1].vencimento,
          valor: item[1].valor,
        }));

    let sendFinancialOperation = {
      tipoOcorrencia: typeOfOccurence,
      planoContaId: dataPlanoConta.value,
      formaPagamentoId: dataFormaPagamento.value,
      clienteFornecedorId: clienteFornecedor.value,
      valorTotal,
      numeroDocumento,
      dataLancamento: formatDateToNow(dataLancamento),
      historico,
      lancarFatura: formaPagamento?.lancarFatura,
      contaUnica: {},
      contaParcelada: {},
      contaRepetir: {},
      contaFatura: {},
    };

    if (typeOfOccurence === TipoOcorrenciaEnum.UNICA) {
      sendFinancialOperation = {
        ...sendFinancialOperation,
        contaUnica: {
          dataVencimento,
          dataCompetencia,
          baixa: baixarConta
            ? {
                juros,
                multa,
                desconto,
                valorTotalBaixa,
                contaFinanceiraId: contaFinanceira?.value,
                formaPagamentoRecebimentoId: formaRecebimento?.value,
                qtdParcela: qtdParcela?.qtdParcelas || 1,
                FormaPagamentoRecebimentoParcelaId: qtdParcela?.value,
                dataBaixa,
              }
            : null,
        },
      };
    } else if (
      typeOfOccurence === TipoOcorrenciaEnum.PARCELADA &&
      !formaPagamento?.lancarFatura
    ) {
      sendFinancialOperation = {
        ...sendFinancialOperation,
        contaParcelada: {
          dataCompetencia,
          planoContaId: dataPlanoConta.value,
          parcelas: ParcelasContas,
        },
      };
    } else if (typeOfOccurence === TipoOcorrenciaEnum.REPETIR) {
      sendFinancialOperation = {
        ...sendFinancialOperation,
        contaRepetir: {
          repeticoes,
          tipoCompetencia,
          dataVencimento,
          valor: valorTotal,
        },
      };
    } else if (
      formaPagamento?.lancarFatura &&
      typeOfOccurence === TipoOcorrenciaEnum.PARCELADA
    ) {
      sendFinancialOperation = {
        ...sendFinancialOperation,
        contaFatura: {
          qtdParcelas: repeticoes,
          tipoCompetencia,
          dataVencimento: faturaCartaoCredito,
          valor: valorTotal,
        },
      };
    }
    const response = await api.post<void, ResponseApi>(
      ConstanteEnderecoWebservice.LANCAMENTO_FINANCEIRO_CADASTRAR,
      {
        ...sendFinancialOperation,
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }

      if (response.sucesso) {
        return true;
      }
    }

    return false;
  }

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    setIsLoading(true);

    const sucesso = await postLancamentoContas(data);

    if (sucesso) {
      toast.success('O cadastro foi realizado com sucesso.');

      setIsPrenvented(false);
      if (idRota) {
        history.push(SubstituirParametroRota(rotaRetorno, 'id', idRota));
      } else {
        history.push(rotaRetorno);
      }
    }

    setIsLoading(false);
  });

  const handleSubmitReset = formMethods.handleSubmit(async (data) => {
    setIsLoading(true);
    const sucesso = await postLancamentoContas(data);

    if (sucesso) {
      toast.success('O cadastro foi realizado com sucesso.');
      historyReload(rotaAtual);
    }

    setIsLoading(false);
  });

  useEffect(() => {
    setIsPrenvented(formMethods.formState.isDirty);
  }, [formMethods.formState.isDirty]);

  return (
    <Box>
      <Prompt when={isPrenvented} message="" />
      <ManterFoco>
        {isLoading && <Loading />}

        <FormProvider {...formMethods}>
          <FormularioLancamentoContas
            nameCurrentOperation={nameCurrentOperation}
            errors={errors}
          />

          <FormFooter
            onSubmit={handleSubmit}
            onSubmitReset={handleSubmitReset}
            isDisabled={!parcelasSaoValidas}
            isLoading={isLoading}
          />
        </FormProvider>
      </ManterFoco>
    </Box>
  );
};

export const CadastrarLancamentoContas = forwardRef(CadastrarLancamento);
