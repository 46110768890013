import React, { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import {
  ModalContent,
  ModalHeader,
  Text,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  Divider,
  useMediaQuery,
  GridItem,
  Box,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { useProdutosFormularioContext } from 'store/Produtos/ProdutosFormulario';

import { NumberInput } from 'components/update/Input/NumberInput';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';

import { Container } from '../../Preco/Container';
import { useForm, yupResolver, defaultValues } from './validationForm';

interface EditarDimensoesVariacaoModalProps {
  show: boolean;
  onHide: () => void;
  produtoCorTamanhosId: string[];
  atualizarVariacoes: () => void;
}

const EditarDimensoesVariacaoModal = ({
  show,
  onHide,
  produtoCorTamanhosId,
  atualizarVariacoes,
}: EditarDimensoesVariacaoModalProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const { idProduto } = useProdutosFormularioContext();

  const formMethods = useForm({
    resolver: yupResolver,
    defaultValues,
  });

  const { handleSubmit: onSubmit, reset } = formMethods;

  const handleSubmit = onSubmit(async (data) => {
    setIsLoading(true);
    const response = await api.patch<void, ResponseApi>(
      `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/produtoCorTamanhos/caracteristicas`,
      {
        produtoCorTamanhosId,
        caracteristicas: {
          ...data,
        },
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso) {
        toast.success('As dimensões foram salvas com sucesso ');
        reset(defaultValues);
        onHide();
        atualizarVariacoes();
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  });

  return (
    <ModalPadraoChakra
      isOpen={show}
      closeOnOverlayClick={false}
      onClose={onHide}
      isCentered={isLargerThan900}
      size={isLargerThan900 ? '3xl' : 'full'}
    >
      <ModalContent
        bg="gray.50"
        overflowY="auto"
        borderRadius={isLargerThan900 ? 'md' : '0'}
        marginBottom={isLargerThan900 ? '3.75rem' : '0'}
        marginTop={isLargerThan900 ? '3.75rem' : '0'}
        w={isLargerThan900 ? '900px' : 'full'}
        h={isLargerThan900 ? undefined : 'full'}
      >
        <ModalHeader>
          <Text color="primary.50" fontSize="md">
            Dimensões
          </Text>
          <Text color="gray.700" fontSize="xs">
            As informações serão aplicadas para todas as variações selecionadas.
          </Text>
          <ModalCloseButton />
          <Divider mt="10px" />
        </ModalHeader>
        <ModalBody>
          <FormProvider {...formMethods}>
            <SimpleGridForm>
              <GridItem colSpan={[12, 12, 5]}>
                <Container bg="gray.50" title="Informações para nota fiscal:">
                  <Box mb="30px" w="full">
                    <NumberInput
                      id="pesoLiquido"
                      name="pesoLiquido"
                      label="Peso líquido"
                      editarFundoLeftElemento
                      leftElementFontSize="12px"
                      bgLeftElement="gray.50"
                      leftElement="KG"
                      precision={12}
                      scale={3}
                      defaultValue={0}
                    />
                  </Box>
                  <Box w="full">
                    <NumberInput
                      id="pesoBruto"
                      name="pesoBruto"
                      editarFundoLeftElemento
                      leftElementFontSize="12px"
                      bgLeftElement="gray.50"
                      label="Peso bruto"
                      leftElement="KG"
                      precision={12}
                      scale={3}
                      defaultValue={0}
                    />
                  </Box>
                </Container>
              </GridItem>
              <GridItem colSpan={[12, 12, 7]}>
                <Container
                  bg="gray.50"
                  title="Características da embalagem para cálculo de frete:"
                >
                  <SimpleGridForm>
                    <GridItem colSpan={[12, 12, 6]}>
                      <NumberInput
                        id="largura"
                        name="largura"
                        label="Largura"
                        autoFocus
                        leftElement="CM"
                        editarFundoLeftElemento
                        leftElementFontSize="12px"
                        bgLeftElement="gray.50"
                        precision={7}
                        scale={2}
                        defaultValue={0}
                      />
                    </GridItem>
                    <GridItem colSpan={[12, 12, 6]}>
                      <NumberInput
                        id="altura"
                        name="altura"
                        label="Altura"
                        editarFundoLeftElemento
                        leftElementFontSize="12px"
                        bgLeftElement="gray.50"
                        leftElement="CM"
                        precision={7}
                        scale={2}
                        defaultValue={0}
                      />
                    </GridItem>
                    <GridItem colSpan={[12, 12, 6]}>
                      <NumberInput
                        id="profundidade"
                        name="profundidade"
                        label="Profundidade"
                        editarFundoLeftElemento
                        leftElementFontSize="12px"
                        bgLeftElement="gray.50"
                        leftElement="CM"
                        precision={7}
                        scale={2}
                        defaultValue={0}
                      />
                    </GridItem>
                    <GridItem colSpan={{ base: 12, md: 12, lg: 6 }}>
                      <NumberInput
                        id="pesoEmbalagem"
                        name="pesoEmbalagem"
                        label="Peso da embalagem"
                        precision={7}
                        scale={2}
                        leftElement="KG"
                        leftElementFontSize="12px"
                        editarFundoLeftElemento
                        bgLeftElement="gray.50"
                      />
                    </GridItem>
                  </SimpleGridForm>
                </Container>
              </GridItem>
            </SimpleGridForm>
          </FormProvider>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outlineDefault"
            colorScheme="gray.300"
            borderRadius="16px"
            onClick={onHide}
            mr="15px"
            w="96px"
            isLoading={isLoading}
          >
            Fechar
          </Button>

          <Button
            variant="solid"
            borderRadius="16px"
            w="96px"
            isLoading={isLoading}
            colorScheme="aquamarine.600"
            onClick={handleSubmit}
          >
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export default EditarDimensoesVariacaoModal;
