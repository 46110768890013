import React, { ChangeEvent, useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { cpfMask, cnpjMask } from 'helpers/format/fieldsMasks';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import { isValidCnpj } from 'helpers/validation/IsValidCpfCnpj';
import consultarReceitaWS, { CnpjResponse } from 'services/receitaws';

import Input, { InputProps } from '../Input';

interface InputCpfCnpjProps extends InputProps {
  asCpf?: boolean;
  asCnpj?: boolean;
  maskTypeChange?: (isCpf: boolean) => void;
  getCnpjData?: (data: CnpjResponse) => void;
}

const InputCpfCnpj = ({
  asCpf,
  asCnpj,
  onChange,
  maskTypeChange,
  isDisabled,
  name,
  getCnpjData,
  ...rest
}: InputCpfCnpjProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const { setError } = useFormContext();

  const getTypeMask = (value: boolean) => {
    if (maskTypeChange) {
      maskTypeChange(value);
    }
  };

  const clearError = useCallback(() => {
    setError(`${name}` as const, {
      message: '',
    });
  }, [name, setError]);

  const getData = useCallback(
    (sucesso: boolean, dados: CnpjResponse | string) => {
      if (getCnpjData) {
        if (!sucesso)
          setError(`${name}` as const, { message: dados as string });
        else {
          getCnpjData(dados as CnpjResponse);
          clearError();
        }
      }

      setIsLoading(false);
    },
    [getCnpjData, name, clearError, setError]
  );

  const handleGetCnpj = useCallback(
    async (value: string) => {
      if (isValidCnpj(value) && getCnpjData) {
        setIsLoading(true);

        consultarReceitaWS(value, getData);
      } else {
        setError(`${name}` as const, {
          message: ConstanteMensagemValidacao.CNPJ_INVALIDO,
        });
      }
    },
    [getCnpjData, getData, setError, name]
  );

  const getFormattedValue = (
    value: string,
    isCpf?: boolean,
    isCnpj?: boolean
  ) => {
    if (isCpf) return cpfMask(value);
    if (isCnpj) return cnpjMask(value);

    const unformattedValue = value.replace(/\D/g, '');

    if (unformattedValue.length <= 11) {
      getTypeMask(true);
      return cpfMask(value);
    }

    getTypeMask(false);
    if (getCnpjData) {
      handleGetCnpj(value);
    }
    return cnpjMask(value);
  };

  return (
    <Input
      {...rest}
      name={name}
      onInput={(e: React.FormEvent<HTMLInputElement>) => {
        if (
          e.currentTarget.value !== undefined ||
          e.currentTarget.value !== null
        ) {
          e.currentTarget.value = getFormattedValue(
            e.currentTarget.value,
            asCpf,
            asCnpj
          );
        }
      }}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        e.currentTarget.value = getFormattedValue(
          e.currentTarget.value,
          asCpf,
          asCnpj
        );

        if (onChange) onChange(e);
      }}
      isDisabled={isLoading || isDisabled}
      maxLength={asCpf ? 14 : 18}
    />
  );
};

export default InputCpfCnpj;
