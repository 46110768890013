import { useCallback, useEffect, useState } from 'react';
import { FiXCircle as LimparIcon } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { FormProvider, useForm } from 'react-hook-form';
import {
  FormLabel,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalProps,
  useDisclosure,
  ModalHeader,
  Text,
  ModalCloseButton,
  Button,
  Icon,
  Box,
  Flex,
  useMediaQuery,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';

import LogAuditoriaOperacaoEnum from 'constants/enum/logAuditoriaOperacao';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import InputDateRange from 'components/PDV/InputDateRange';
import { LupaIcon } from 'icons';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';

import {
  UsuarioInterface,
  LogAuditoriaFiltros,
} from '../../Types/validationForms';

interface AuditoriaDescricaoTelas {
  codigo: number;
  nome: string;
}

type ModalBuscaAvancadaProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<null> & {
    onHide: (willReset?: boolean) => void;
    onSubmit: (novosFiltros: any) => void;
    usuarios: UsuarioInterface[];
    filtros: LogAuditoriaFiltros;
  };

export const BuscaAvancadaModal = create<ModalBuscaAvancadaProps>(
  ({ onResolve, onReject, onHide, usuarios, filtros, onSubmit, ...rest }) => {
    const { onClose, isOpen } = useDisclosure({ defaultIsOpen: true });
    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    const formMethods = useForm({
      defaultValues: filtros,
    });

    const [logDescricaoTelas, setLogDescricaoTelas] = useState<
      AuditoriaDescricaoTelas[]
    >([]);

    const handlePesquisar = useCallback(() => {
      const data = formMethods.getValues();

      onSubmit({ ...data });
      onClose();
    }, [formMethods, onSubmit, onClose]);

    useEffect(() => {
      const listarAuditoriaDescricaoTelas = async () => {
        const response = await api.get<
          void,
          ResponseApi<AuditoriaDescricaoTelas[]>
        >(ConstanteEnderecoWebservice.LOG_AUDITORIA_LISTAR_DESCRICAO_TELAS);
        if (response) {
          if (response.avisos) {
            response.avisos.forEach((aviso) => toast.warning(aviso));
          }

          if (response.dados && response.sucesso) {
            setLogDescricaoTelas(response.dados);
          }
        }
      };
      listarAuditoriaDescricaoTelas();
    }, []);

    useEffect(() => {
      formMethods.setValue('dataInicio', filtros.dataInicio);
      formMethods.setValue('dataFim', filtros.dataFim);
    }, [filtros, formMethods]);

    return (
      <ModalPadraoChakra
        size="3xl"
        {...rest}
        isOpen={isOpen}
        isCentered={isLargerThan900}
        onClose={onClose}
      >
        <ModalContent
          bg="gray.50"
          marginBottom={{ base: 0, sm: '3.75rem' }}
          marginTop={{ base: 0, sm: '3.75rem' }}
          w={isLargerThan900 ? '900px' : 'full'}
          h={isLargerThan900 ? '450px' : 'full'}
        >
          <ModalHeader>
            <Text color="primary.50" fontSize="md">
              Busca avançada
            </Text>
            <Text color="gray.700" fontSize="xs">
              Utilize os filtros de busca para uma pesquisa específica. Todos os
              campos são acumulativos.
            </Text>
          </ModalHeader>

          <ModalCloseButton
            fontSize="2xs"
            border="none"
            _hover={{ bg: 'none' }}
            _focus={{ border: 'none' }}
          />
          <ModalBody p="0" pr="23px" pl="23px">
            <FormProvider {...formMethods}>
              <Box pb="20px">
                <FormLabel mb="0" fontSize="sm" color="black">
                  Período
                </FormLabel>
                <InputDateRange
                  borderColor="gray.100"
                  borderRadius="md"
                  id="dataHora"
                  name="dataHora"
                  placeholder="dd/mm/aaaa"
                  startDateName="dataInicio"
                  endDateName="dataFim"
                  isClearable
                  maxDate={new Date()}
                />
              </Box>

              <Box pb="20px">
                <SelectPadrao
                  id="tela"
                  name="tela"
                  required
                  label="Tela"
                  placeholder="Todas"
                  isClearable
                  options={logDescricaoTelas.map(({ codigo, nome }) => {
                    return (
                      {
                        label: nome,
                        value: codigo,
                      } || {}
                    );
                  })}
                />
              </Box>

              <Box pb="20px">
                <SelectPadrao
                  id="operacao"
                  name="operacao"
                  required
                  label="Operação"
                  options={Object.entries(
                    LogAuditoriaOperacaoEnum.properties
                  ).map((value: any) => {
                    return (
                      {
                        label: value[1].name,
                        value: value[1].value,
                      } || {}
                    );
                  })}
                />
              </Box>
              <Box pb="20px">
                <SelectPadrao
                  id="usuarioId"
                  name="usuarioId"
                  label="Usuário"
                  required
                  placeholder="Digite o usuário"
                  options={Object.entries(usuarios).map((value: any) => {
                    return (
                      {
                        label: value[1].nome,
                        value: value[1].id,
                      } || {}
                    );
                  })}
                />
              </Box>
            </FormProvider>
          </ModalBody>
          <ModalFooter>
            <Flex w="full" alignItems="center" justifyContent="center">
              <Button
                id="limparFiltros"
                name="limparFiltros"
                variant="outline"
                _hover={{
                  background: 'none',
                }}
                leftIcon={<Icon as={LimparIcon} />}
                h="35px"
                w="150px"
                mr="30px"
                borderRadius="md"
                type="button"
                onClick={() => {
                  onHide(true);
                  onClose();
                }}
              >
                Limpar filtros
              </Button>

              <Button
                id="pesquisar"
                color="white"
                borderRadius="md"
                name="pesquisar"
                variant=""
                bg="aquamarine.600"
                leftIcon={<Icon as={LupaIcon} />}
                w="150px"
                h="35px"
                type="button"
                onClick={() => handlePesquisar()}
              >
                Pesquisar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
