import React, { ReactNode } from 'react';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  FormControlProps,
  Flex,
  GridItem,
  Tooltip,
  Box,
  Icon,
  Text,
  Button,
  FormErrorMessageProps,
  TypographyProps,
} from '@chakra-ui/react';

import GridItemProps from 'types/gridItemProps';

import { InfoIcon, IconType } from 'icons';

export interface CampoContainerProps
  extends Omit<FormControlProps, 'helperText' | 'errorText' | 'id' | 'label'>,
    GridItemProps {
  name: string;
  id?: string;
  label?: ReactNode | string;
  labelColor?: string;
  helperText?: ReactNode | string;
  errorText?: ReactNode | string;
  actionLinkText?: string;
  actionLinkOnClick?: () => void;
  topRightElement?: ReactNode;
  color?: string;
  bgHelperText?: string;
  errorMessageStyleProps?: FormErrorMessageProps;
  colorHelperText?: string;
  colorSchemeLinkText?: string;
  inputRightElement?: boolean;
  isPassword?: boolean;
  icon?: IconType;
  fontWeightLabel?: TypographyProps['fontWeight'];
  helperTextLabel?: string;
  helperTextIconColor?: string;
  helperTextIconBg?: string;
  fontSizeLabel?: string;
}

const CampoContainer = ({
  errorText,
  helperText,
  color = 'black',
  labelColor = 'black',
  bgHelperText = 'black',
  colorHelperText = 'white',
  fontWeightLabel = 'semibold',
  fontSizeLabel = 'sm',
  actionLinkText,
  actionLinkOnClick,
  topRightElement,
  label,
  children,
  id,
  isRequired,
  errorMessageStyleProps,
  helperTextLabel = undefined,
  colorSchemeLinkText = 'blue',
  helperTextIconColor = 'white',
  helperTextIconBg = 'black',
  ...props
}: CampoContainerProps) => {
  const isMetaComissao = window.location.pathname.includes('metas-comissoes');

  return (
    <FormControl
      as={GridItem}
      position="relative"
      id={id}
      data-testid={id}
      isRequired={isRequired}
      isInvalid={!!errorText}
      {...props}
    >
      {label && (
        <Flex
          mb="1"
          justifyContent={
            actionLinkText || topRightElement ? 'flex-start' : 'space-between'
          }
          alignItems="center"
        >
          <FormLabel
            mb="0"
            lineHeight="1"
            color={labelColor}
            whiteSpace={
              actionLinkText || topRightElement ? 'nowrap' : undefined
            }
            display="flex"
            fontWeight={fontWeightLabel}
            fontSize={fontSizeLabel}
          >
            <Text display="inline"> {label}</Text>
            {helperTextLabel && (
              <Text ml="5px" display="inline">
                <Tooltip
                  hasArrow
                  bg={bgHelperText}
                  maxW={isMetaComissao ? '500px' : '300px'}
                  w={isMetaComissao ? '450px' : ''}
                  color={colorHelperText}
                  placement="right"
                  label={helperTextLabel}
                  ml={1}
                  p="10px"
                  borderRadius="6px"
                  tabIndex={-1}
                >
                  <Box tabIndex={-1}>
                    <Icon as={InfoIcon} w="15px" h="15px" color={color} />
                  </Box>
                </Tooltip>
              </Text>
            )}
          </FormLabel>
          {helperText && (
            <Flex
              maxH={isMetaComissao ? '' : '10px'}
              alignItems="center"
              sx={{ '& > div': { display: 'flex' } }}
            >
              <Tooltip
                hasArrow
                bg={bgHelperText}
                maxW={isMetaComissao ? '500px' : '300px'}
                w={isMetaComissao ? '450px' : ''}
                h={isMetaComissao ? '178px' : ''}
                color={colorHelperText}
                textAlign="left"
                placement="right"
                label={helperText}
                ml={1}
                tabIndex={-1}
              >
                <Box tabIndex={-1}>
                  <InfoIcon
                    height="15px"
                    width="15px"
                    color={color}
                    bg={helperTextIconBg}
                    fill={helperTextIconColor}
                  />
                </Box>
              </Tooltip>
            </Flex>
          )}
          {actionLinkText && (
            <Flex ml={2} w="full" justifyContent="flex-end">
              <Button
                variant="link"
                size="xs"
                lineHeight="0"
                boxShadow="none !important"
                colorScheme={colorSchemeLinkText}
                textDecoration="none"
                _hover={{ textDecoration: 'none' }}
                justifySelf="flex-end"
                onClick={actionLinkOnClick}
              >
                {actionLinkText}
              </Button>
            </Flex>
          )}
          {topRightElement && (
            <Flex ml={2} w="full" justifyContent="flex-end">
              {topRightElement}
            </Flex>
          )}
        </Flex>
      )}
      <Box
        borderWidth={errorText ? '1px' : '0px'}
        borderRadius="6px"
        borderColor={errorText ? 'red.500' : 'gray.200'}
      >
        {children}
      </Box>

      {errorText && (
        <FormErrorMessage
          position="absolute"
          pt="1"
          bottom="-3"
          lineHeight="0px"
          whiteSpace="nowrap"
          {...errorMessageStyleProps}
        >
          {errorText}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

export default CampoContainer;
