import React, { useState } from 'react';
import {
  Box,
  HStack,
  Stack,
  Button,
  Text,
  Circle,
  useToken,
} from '@chakra-ui/react';
import { PulseLoader } from 'react-spinners';

import { useImportarContext } from 'store/ImportarContext';
import api, { ResponseApi } from 'services/api';
import TipoImportacaoEnum from 'constants/enum/tipoImportacao';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import ModalAuxiliarImportacaoProdutos from 'components/Modal/ModalAuxiliarImportacaoProdutos';
import ModalAuxiliarImportacaoClientes from 'components/Modal/ModalAuxiliarImportacaoClientes';
import ModalAuxiliarImportacaoFornecedores from 'components/Modal/ModalAuxiliarImportacaoFornecedores';
import { DropFileInput } from './DropFileInput';

export function PrimeiraEtapa() {
  const {
    handleCancelar,
    nextStep,
    nomeTipoImportacao,
    tipoImportacao,
    arquivo,
    setArquivo,
  } = useImportarContext();

  const [planilhaModeloIsLoading, setPlanilhaModeloIsLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const blue500 = useToken('colors', 'blue.500');

  async function handleDownloadPlanilhaModelo() {
    setPlanilhaModeloIsLoading(true);

    const response = await api.get<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.IMPORTAR_DOWNLOAD_CSV_EXEMPLO,
      {
        params: { tipoImportacao },
      }
    );

    const url = window.URL.createObjectURL(new Blob([response.dados]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `${nomeTipoImportacao.toLowerCase()}-planilha-modelo.csv`
    );
    link.click();

    setPlanilhaModeloIsLoading(false);
  }

  const OpenModalAjudaImportacao = () => {
    setModalIsOpen(!modalIsOpen);
  };

  return (
    <>
      <Box w="full">
        <Box h="250px">
          <DropFileInput file={arquivo} setFile={setArquivo} />
        </Box>

        <Stack
          direction={{ base: 'column', sm: 'row' }}
          mt="8"
          spacing="6"
          w="full"
          justifyContent="space-between"
        >
          <Button
            variant="outline"
            borderRadius="md"
            fontSize="sm"
            minW="175px"
            onClick={handleCancelar}
          >
            Cancelar
          </Button>

          <Button
            colorScheme="primary"
            borderRadius="md"
            fontSize="sm"
            minW="175px"
            onClick={nextStep}
            isDisabled={!arquivo}
          >
            Avançar
          </Button>
        </Stack>

        <HStack
          alignItems="center"
          spacing="3"
          mt="8"
          py="3"
          px="6"
          borderRadius="md"
          bg="gray.50"
        >
          <Circle bg="blue.500" size="6" color="white">
            ?
          </Circle>

          <Text fontSize="sm">
            Precisa de ajuda para importar seu arquivo? Faça o download da{' '}
            <Button
              variant="link"
              size="sm"
              colorScheme="blue"
              onClick={handleDownloadPlanilhaModelo}
              isDisabled={planilhaModeloIsLoading}
            >
              planilha modelo.
            </Button>
          </Text>
          {planilhaModeloIsLoading && <PulseLoader size="5" color={blue500} />}
        </HStack>
        <HStack
          alignItems="center"
          spacing="3"
          mt="3"
          py="3"
          px="6"
          borderRadius="md"
          bg="gray.50"
        >
          <Circle bg="blue.500" size="6" color="white">
            ?
          </Circle>

          <Text fontSize="sm">
            Dúvidas em como preencher a planilha?{' '}
            <Button
              variant="link"
              size="sm"
              colorScheme="blue"
              onClick={OpenModalAjudaImportacao}
              isDisabled={planilhaModeloIsLoading}
            >
              Clique aqui para ver como preencher os campos.
            </Button>
          </Text>
          {planilhaModeloIsLoading && <PulseLoader size="5" color={blue500} />}
        </HStack>
      </Box>

      {tipoImportacao === TipoImportacaoEnum.Clientes && (
        <ModalAuxiliarImportacaoClientes
          isOpen={modalIsOpen}
          onClose={() => setModalIsOpen(false)}
        />
      )}

      {tipoImportacao === TipoImportacaoEnum.Fornecedores && (
        <ModalAuxiliarImportacaoFornecedores
          isOpen={modalIsOpen}
          onClose={() => setModalIsOpen(false)}
        />
      )}

      {tipoImportacao === TipoImportacaoEnum.Produtos && (
        <ModalAuxiliarImportacaoProdutos
          isOpen={modalIsOpen}
          onClose={() => setModalIsOpen(false)}
        />
      )}
    </>
  );
}
