import React from 'react';
import {
  Text,
  Icon,
  Flex,
  Button,
  ButtonProps,
  Stack,
  useMediaQuery,
  Box,
} from '@chakra-ui/react';

import { NaoSelecionadoIcon, SelecionadoIcon } from 'icons';

type Estoque = {
  nome: string;
};

interface VendedorButtonProps extends ButtonProps {
  isSelected: boolean;
  estoque: Estoque;
}

const EstoqueButton: React.FC<VendedorButtonProps> = ({
  isSelected,
  estoque,
  ...rest
}) => {
  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');

  return (
    <Flex alignItems="center" justifyContent="center" w="100%" minW="1px">
      <Button
        position="relative"
        w={{ base: '100%', md: '150px' }}
        h={{ base: '55px', md: '150px' }}
        justifyContent={{ base: 'flex-start', md: 'center' }}
        borderRadius="lg"
        border="1px"
        borderColor="gray.100"
        boxShadow="md"
        bg={isSelected ? 'white' : 'gray.50'}
        _hover={{ bg: 'white' }}
        _active={{ bg: 'white' }}
        {...rest}
      >
        <Stack
          direction={{ base: 'row', md: 'column' }}
          spacing={4}
          alignItems="center"
          justifyContent={{ base: 'flex-start', md: 'center' }}
          h="100%"
          w="full"
        >
          <Box
            order={{ base: '2', md: '0' }}
            flex={{ base: '1', md: '0' }}
            display="flex"
            justifyContent={{ base: 'flex-end', md: '' }}
            mr={{ base: '2', md: '0' }}
          >
            <Icon
              as={isSelected ? SelecionadoIcon : NaoSelecionadoIcon}
              ml={{ base: 'auto', md: '0' }}
              w={5}
              h={5}
              color={isSelected ? 'primary.400' : 'gray.200'}
            />
          </Box>
          <Text
            whiteSpace="normal"
            maxW={{
              base: isSelected ? 'calc(100% - 5rem)' : 'full',
              md: 'full',
            }}
            fontSize="xs"
            isTruncated={isSmallerThan900}
          >
            {estoque.nome}
          </Text>
        </Stack>
      </Button>
    </Flex>
  );
};

export default EstoqueButton;
