import { Box, GridItem, HStack, Icon, Text, Flex } from '@chakra-ui/react';
import { FiChevronLeft } from 'react-icons/fi';

import { useEmissaoNotaFiscalContext } from 'store/NotaFiscal/EmissaoNotaFiscal';
import ModalidadesFreteEnum from 'constants/enum/fiscal/modalidadeFrete';
import EstadosEnum from 'constants/enum/estados';

import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import Input from 'components/PDV/Input';
import { NumberInput } from 'components/update/Input/NumberInput';
import InputInteger from 'components/PDV/InputInteger';

import { CardHeader } from '../Components/CardHeader';
import { NotaFiscalExibirTela } from '../validation';

type DadosTransportadoraProps = {
  isMultipleVendas: boolean;
};

export const DadosTransportadora = ({
  isMultipleVendas,
}: DadosTransportadoraProps) => {
  const { handlePrevTela } = useEmissaoNotaFiscalContext();

  return (
    <>
      <CardHeader mb="0" px="40px">
        <HStack spacing="20px">
          <Icon
            as={FiChevronLeft}
            cursor="pointer"
            onClick={() => handlePrevTela(NotaFiscalExibirTela.TELA_INICIAL)}
            boxSize="24px"
            mr="20px"
          />

          <Text color="black" fontWeight="semibold" fontSize="20px">
            Editar dados de transporte
          </Text>
        </HStack>
      </CardHeader>
      {isMultipleVendas && (
        <Flex alignItems="center" px="40px" bg="yellow.500" h="40px">
          <span
            style={{
              fontWeight: 'bold',
              marginRight: '5px',
            }}
          >
            Aviso:
          </span>{' '}
          As informações para quantidade, espécie e número serão aplicadas para
          todas as notas.
        </Flex>
      )}
      <SimpleGridForm mt="28px" px="40px">
        <GridItem colSpan={12}>
          <Box w={['full', 'full', '48.5%']}>
            <SelectPadrao
              name="modalidadeFrete"
              required
              id="modalidadeFrete"
              placeholder="Selecione"
              label="Modalidade do frete"
              valueDefault={0}
              options={ModalidadesFreteEnum.options}
            />
          </Box>
        </GridItem>
        <GridItem colSpan={[12, 12, 2]}>
          <Input
            name="placaTransportadora"
            maxLength={7}
            placeholder="XXX-0000"
            id="placaTransportadora"
            label="Placa do veículo"
          />
        </GridItem>
        <GridItem colSpan={[12, 12, 4]}>
          <SelectPadrao
            name="ufVeiculoTransportadora"
            id="ufVeiculoTransportadora"
            placeholder="Selecione"
            label="UF do veículo"
            options={Object.entries(EstadosEnum.properties).map((value) => {
              return (
                {
                  label: value[1].sigla,
                  value: value[1].sigla,
                } || {}
              );
            })}
          />
        </GridItem>
        <GridItem colSpan={[12, 12, 3]}>
          <NumberInput
            id="pesoLiquido"
            name="pesoLiquido"
            label="Peso líquido"
            isDisabled
            leftElement="KG"
            editarFundoLeftElemento
            bgLeftElement="gray.50"
            precision={12}
            scale={3}
            defaultValue={0}
          />
        </GridItem>
        <GridItem colSpan={[12, 12, 3]}>
          <NumberInput
            id="pesoBruto"
            name="pesoBruto"
            isDisabled
            editarFundoLeftElemento
            label="Peso bruto"
            leftElement="KG"
            bgLeftElement="gray.200"
            precision={12}
            scale={3}
            defaultValue={0}
          />
        </GridItem>
        <GridItem colSpan={[12, 12, 2]}>
          <InputInteger
            color="black"
            colorScheme="secondary.300"
            id="quantidadeVolumeTransportadora"
            label="Quantidade"
            name="quantidadeVolumeTransportadora"
            scale={0}
            max={999}
            min={1}
            textAlign="left"
            isNumber
          />
        </GridItem>
        <GridItem colSpan={[12, 12, 4]}>
          <Input
            name="especieVolumeTransportadora"
            maxLength={7}
            placeholder="Informe a espécie"
            id="especieVolumeTransportadora"
            label="Espécie"
          />
        </GridItem>
        <GridItem colSpan={[12, 12, 6]}>
          <NumberInput
            id="numeracaoVolumeTransportadora"
            name="numeracaoVolumeTransportadora"
            label="Número"
            precision={12}
            scale={3}
            defaultValue={0}
          />
        </GridItem>
        <GridItem colSpan={[12, 12, 6]}>
          <Input
            name="marcaVolumeTransportadora"
            maxLength={7}
            placeholder="----"
            id="marcaVolumeTransportadora"
            isDisabled
            label="Marca"
          />
        </GridItem>
      </SimpleGridForm>
    </>
  );
};
