import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

export const EnumRelatorioVendas = {
  VENDAS_FORMA_RECEBIMENTO: 0,
  VENDAS_POR_DIA: 1,
  VENDAS_SIMPLIFICADAS: 2,
  VENDAS_TOTALIZADAS_VENDEDORES: 4,
  VENDAS_TOTALIZADAS_PRODUTOS: 5,

  properties: [
    { label: 'Vendas por forma de recebimento', value: 0 },
    {
      label: 'Vendas por dia',
      value: 1,
    },
    {
      label: 'Vendas simplificadas',
      value: 2,
    },
    {
      label: 'Vendas totalizadas por vendedores',
      value: 4,
    },
    {
      label: 'Vendas totalizadas por produtos',
      value: 5,
    },
  ],
};

export const EnumApisRelatorioVendas = {
  [EnumRelatorioVendas.VENDAS_FORMA_RECEBIMENTO]:
    ConstanteEnderecoWebservice.RELATORIO_VENDAS_POR_FORMA_RECEBIMENTO,
  [EnumRelatorioVendas.VENDAS_POR_DIA]:
    ConstanteEnderecoWebservice.RELATORIO_VENDAS_POR_DIA,
  [EnumRelatorioVendas.VENDAS_SIMPLIFICADAS]:
    ConstanteEnderecoWebservice.RELATORIO_VENDAS_SIMPLIFICADAS,
  [EnumRelatorioVendas.VENDAS_TOTALIZADAS_VENDEDORES]:
    ConstanteEnderecoWebservice.RELATORIO_VENDAS_TOTALIZADAS_VENDEDORES,
  [EnumRelatorioVendas.VENDAS_TOTALIZADAS_PRODUTOS]:
    ConstanteEnderecoWebservice.RELATORIO_VENDAS_TOTALIZADAS_PRODUTOS,
};
