import React, { useMemo } from 'react';
import { Flex, Text, VStack } from '@chakra-ui/react';
import formatUTCToLocateDateTime from 'helpers/format/formatUTCToLocateDateTime';
import statusOperacao from 'constants/enum/statusOperacao';
import identificacaoTipoOperacao from 'constants/enum/identificacaoTipoOperacao';
import { getOptionByEnum } from 'helpers/format/getOptionsByEnum';

interface Vendas {
  numeroOperacao: number;
  dataEmissao: string;
  clienteNome: string;
  clienteEndereco: string;
  status: number;
  identificaoOperacao: number;
}

const Vendas = ({
  numeroOperacao,
  dataEmissao,
  clienteNome,
  clienteEndereco,
  status,
  identificaoOperacao,
}: Vendas) => {
  const identificacaoTipoOperacaoLabel = useMemo(() => {
    let newIdentificacaoTipoOperacaoLabel = '';

    switch (identificaoOperacao) {
      case identificacaoTipoOperacao.VENDA:
        newIdentificacaoTipoOperacaoLabel = getOptionByEnum(
          identificacaoTipoOperacao,
          identificacaoTipoOperacao.VENDA
        )?.label;
        break;
      case identificacaoTipoOperacao.PEDIDO:
        newIdentificacaoTipoOperacaoLabel = getOptionByEnum(
          identificacaoTipoOperacao,
          identificacaoTipoOperacao.PEDIDO
        )?.label;
        break;
      case identificacaoTipoOperacao.ORCAMENTO:
        newIdentificacaoTipoOperacaoLabel = getOptionByEnum(
          identificacaoTipoOperacao,
          identificacaoTipoOperacao.ORCAMENTO
        )?.label;
        break;

      case identificacaoTipoOperacao.CONSIGNACAO:
        newIdentificacaoTipoOperacaoLabel = getOptionByEnum(
          identificacaoTipoOperacao,
          identificacaoTipoOperacao.CONSIGNACAO
        )?.label;
        break;

      default:
        break;
    }

    return newIdentificacaoTipoOperacaoLabel;
  }, [identificaoOperacao]);

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        mt={3}
        borderBottom="2px solid"
        borderColor="gray.700"
      >
        <VStack>
          {status === 2 ? (
            <Text>
              {identificacaoTipoOperacaoLabel}: {numeroOperacao}
              <Text>{statusOperacao.properties[3].label.toUpperCase()}</Text>
            </Text>
          ) : (
            <Text>
              {identificacaoTipoOperacaoLabel}: {numeroOperacao}
            </Text>
          )}
        </VStack>
        <VStack>
          <Text fontSize="sm">{formatUTCToLocateDateTime(dataEmissao)}</Text>
        </VStack>
      </Flex>
      <Flex
        mt={3}
        pb={3}
        alignItems="flex-start"
        borderBottom="2px solid"
        borderColor="gray.700"
        direction="column"
      >
        <VStack>
          <Text>2 - {clienteNome}</Text>
        </VStack>
        {clienteEndereco && (
          <VStack>
            <Text>{clienteEndereco}</Text>
          </VStack>
        )}
      </Flex>
    </>
  );
};

export default Vendas;
