export const enumFiltrarCaixaAtual = {
  CAIXA_ATUAL: true,
  TODOS: false,

  properties: [
    { label: 'Caixa Atual', value: true },
    { label: 'Todos', value: false },
  ],
};

export default enumFiltrarCaixaAtual;
