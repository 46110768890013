import { GerarRelatorioCadastroGeralClientes } from 'pages/Relatorio/CadastroCompleto';
import { GerarRelatoriosPersonalizadosClientes } from 'pages/Relatorio/Personalizados';
import { ListarRelatoriosClientes } from 'pages/Relatorio/CadastroRelatorios';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

const RelatorioClientesPersonalizados = [
  <LayoutGuard
    key="gerar-relatorios-clientes"
    permissaoFuncionalidade={
      ConstanteFuncionalidades.RELATORIO_CLIENTE_PERSONALIZADO
    }
    component={GerarRelatoriosPersonalizadosClientes}
    breadcrumb={[
      {
        title: 'Relatórios',
      },
      {
        title: 'Personalizados',
      },
    ]}
    path={ConstanteRotas.RELATORIO_CLIENTES}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="cadastrar-relatorios-clientes"
    permissaoFuncionalidade={
      ConstanteFuncionalidades.RELATORIO_CADASTRO_COMPLETO_CLIENTE
    }
    component={GerarRelatorioCadastroGeralClientes}
    breadcrumb={[
      {
        title: 'Relatórios',
      },
      {
        title: 'Cadastro Completo',
      },
    ]}
    path={ConstanteRotas.RELATORIO_CADASTRO_COMPLETO}
    exact
    meta={{ auth: true }}
  />,

  <LayoutGuard
    key="cadastrar-relatorios-clientes"
    permissaoFuncionalidade={
      ConstanteFuncionalidades.RELATORIO_PERSONALIZADO_CLIENTES_CADASTRAR
    }
    component={ListarRelatoriosClientes}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Relatório Personalizado',
      },
    ]}
    path={ConstanteRotas.RELATORIO_CADASTRO_CLIENTES}
    exact
    meta={{ auth: true }}
  />,
];

export default RelatorioClientesPersonalizados;
