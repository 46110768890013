import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import { LupaIcon } from 'icons';

export const Container = styled.div`
  color: var(--white);
`;

export const Icon = styled(LupaIcon)`
  position: absolute;

  color: var(--sti-ck-colors-gray-100);
  font-size: 1.375rem;

  margin-top: 9px;
  margin-left: 20px;
`;

export const Input = styled(Form.Control)`
  padding-left: 53px;

  font-size: 0.9375rem;

  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
`;

export const LabelInputFile = styled.label`
  padding: 5px;
  background: var(--sti-ck-colors-blue-500);
  text-align: left;
  letter-spacing: 0px;
  color: var(--white) !important;
  opacity: 1;
  border-radius: 5px;
  border: 1px solid var(--sti-ck-colors-blue-500);
  min-width: 125px;
  text-align: center;
`;

export const SpanFileName = styled.span`
  padding: 7px;
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0px;
  color: var(--sti-ck-colors-gray-700);
  opacity: 1;
`;
