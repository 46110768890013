import { useEffect, useState, useRef, useMemo } from 'react';
import { toast } from 'react-toastify';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  Divider,
  Box,
  Stack,
  HStack,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Tfoot,
  Link,
  useMediaQuery,
  Skeleton,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';

import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { DecimalMask } from 'helpers/format/fieldsMasks';
import api, { ResponseApi } from 'services/api';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { formatDate } from 'helpers/format/formatStringDate';
import { FormData } from '../validationForm';
import { InformacaoStatusVencimento } from '../types';

type MovimentacaoFinanceiraBaixa = {
  dataPagamento: Date;
  formaPagamento: string;
  contaFinanceira: string;
  desconto: number;
  multaJuros: number;
  valor: number;
  totalRecebido: number;
  taxaTransacao: number;
  compensacao: Date;
  usuarioBaixa: string;
};

type ObterDetalhesContasAReceberResponse = {
  dataVencimento: Date;
  dataEmissao: Date;
  clienteFornecedorRazaoSocial: string;
  clienteFornecedorNomeFantasia: string;
  parcela: string;
  planoContas: string;
  competencia: Date;
  historico: string;
  operacaoId: string;
  tipoOperacao: number;
  numeroDocumento: string;
  identificador: number;
  numeroNotaFiscal: number;
  modeloNotaFiscal: string;
  dataPagamento: Date;
  valorRecebido: number;
  valorOriginal: number;
  formaRecebimento: string;
  movimentacaoFinanceiraBaixa: MovimentacaoFinanceiraBaixa[];
};

type ModalDetalhesPedidoProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<FormData> & {
    id: string;
    informacaoStatusVencimento: InformacaoStatusVencimento;
    casasDecimaisValor: number;
    valorAReceber?: number;
  };

type TypeOfOperationProps =
  | {
      name: string;
      value: number;
    }[]
  | null;

export const ModalDetalheContasAReceber = create<
  ModalDetalhesPedidoProps,
  FormData
>(
  ({
    onResolve,
    id,
    informacaoStatusVencimento,
    casasDecimaisValor,
    valorAReceber,
    onReject,
    ...rest
  }) => {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const TypeOfOperation = useRef<TypeOfOperationProps>(null);
    const [
      isInformacoesContasAReceberLoading,
      setIsInformacoesContasAReceberLoading,
    ] = useState(false);
    const [
      informacoesContasAReceber,
      setInformacoesContasAReceber,
    ] = useState<ObterDetalhesContasAReceberResponse>();

    const [isLargerThan699] = useMediaQuery('(min-width: 699px)');
    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    useEffect(() => {
      async function obterDetalhesContasAReceber() {
        setIsInformacoesContasAReceberLoading(true);

        const response = await api.get<
          void,
          ResponseApi<ObterDetalhesContasAReceberResponse>
        >(ConstanteEnderecoWebservice.CONTAS_RECEBER_OBTER_DETALHE, {
          params: { movimentacaoFinanceiraId: id },
        });

        if (response) {
          if (response.avisos) {
            response.avisos.map((item: string) => toast.warning(item));
          }

          if (response.sucesso && response.dados) {
            setInformacoesContasAReceber(response.dados);
            const typeOfOperation = Object.entries(
              IdentificacaoTipoOperacaoEnum.properties
            )
              .map((item) => ({
                name: item[1].name,
                value: item[1].value,
              }))
              .filter((item) => item.value === response.dados?.tipoOperacao);
            TypeOfOperation.current = typeOfOperation;
          }
        }
        setIsInformacoesContasAReceberLoading(false);
      }

      obterDetalhesContasAReceber();
    }, [id]);

    function handlePushVerDetalheConta() {
      let href = '';
      if (
        informacoesContasAReceber?.tipoOperacao ===
          IdentificacaoTipoOperacaoEnum.DEVOLUCAO_COMPRA ||
        informacoesContasAReceber?.tipoOperacao ===
          IdentificacaoTipoOperacaoEnum.BRINDE ||
        informacoesContasAReceber?.tipoOperacao ===
          IdentificacaoTipoOperacaoEnum.PERDA ||
        informacoesContasAReceber?.tipoOperacao ===
          IdentificacaoTipoOperacaoEnum.BRINDE ||
        informacoesContasAReceber?.tipoOperacao ===
          IdentificacaoTipoOperacaoEnum.AJUSTE_ESTOQUE_SAIDA ||
        informacoesContasAReceber?.tipoOperacao ===
          IdentificacaoTipoOperacaoEnum.AJUSTE_ESTOQUE_ENTRADA ||
        informacoesContasAReceber?.tipoOperacao ===
          IdentificacaoTipoOperacaoEnum.CONFERENCIA_ESTOQUE_SAIDA ||
        informacoesContasAReceber?.tipoOperacao ===
          IdentificacaoTipoOperacaoEnum.CONFERENCIA_ESTOQUE_ENTRADA
      ) {
        href = SubstituirParametroRota(
          ConstanteRotas.MOVIMENTACAO_ESTOQUE_VISUALIZAR,
          'id',
          informacoesContasAReceber?.operacaoId
        );
      } else if (
        informacoesContasAReceber?.tipoOperacao ===
        IdentificacaoTipoOperacaoEnum.CONTAS_RECEBER
      ) {
        href = SubstituirParametroRota(
          ConstanteRotas.CONTAS_RECEBER_VISUALIZAR,
          'id',
          informacoesContasAReceber?.operacaoId
        );
      } else if (
        informacoesContasAReceber?.tipoOperacao ===
          IdentificacaoTipoOperacaoEnum.VENDA ||
        informacoesContasAReceber?.tipoOperacao ===
          IdentificacaoTipoOperacaoEnum.PEDIDO ||
        informacoesContasAReceber?.tipoOperacao ===
          IdentificacaoTipoOperacaoEnum.ORCAMENTO
      ) {
        href = SubstituirParametroRota(
          ConstanteRotas.OPERACOES_DETALHE,
          'id',
          informacoesContasAReceber?.operacaoId
        );
      } else if (
        informacoesContasAReceber?.tipoOperacao ===
          IdentificacaoTipoOperacaoEnum.PEDIDO_COMPRA ||
        informacoesContasAReceber?.tipoOperacao ===
          IdentificacaoTipoOperacaoEnum.ENTRADA_MERCADORIA ||
        informacoesContasAReceber?.tipoOperacao ===
          IdentificacaoTipoOperacaoEnum.PAGAMENTO_COMPRA ||
        informacoesContasAReceber?.tipoOperacao ===
          IdentificacaoTipoOperacaoEnum.COMPRA_MERCADORIA
      ) {
        href = SubstituirParametroRota(
          ConstanteRotas.ENTRADA_MERCADORIA_ENTRADA_MANUAL_VISUALIZAR,
          'id',
          informacoesContasAReceber?.operacaoId
        );
      }
      return href;
    }

    const somarValoresMovimentacoesFinanceirasBaixa = useMemo(() => {
      const totalRecived = informacoesContasAReceber?.movimentacaoFinanceiraBaixa.reduce(
        (acc, curr) => acc + (curr?.totalRecebido || 0),
        0
      );

      const value = informacoesContasAReceber?.movimentacaoFinanceiraBaixa.reduce(
        (acc, curr) => acc + (curr?.valor || 0),
        0
      );

      const multaEJuros = informacoesContasAReceber?.movimentacaoFinanceiraBaixa.reduce(
        (acc, curr) => acc + (curr?.multaJuros || 0),
        0
      );

      const desconto = informacoesContasAReceber?.movimentacaoFinanceiraBaixa.reduce(
        (acc, curr) => acc + (curr?.desconto || 0),
        0
      );

      const taxaTransacao = informacoesContasAReceber?.movimentacaoFinanceiraBaixa.reduce(
        (acc, curr) => acc + (curr?.taxaTransacao || 0),
        0
      );

      return {
        totalRecived,
        value,
        multaEJuros,
        desconto,
        taxaTransacao,
      };
    }, [informacoesContasAReceber]);

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? '6xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
      >
        <ModalContent bg="gray.50" width={['full', 'full', 'full', '1000px']}>
          <Flex flexDirection="column">
            {isInformacoesContasAReceberLoading && <LoadingPadrao />}
            <ModalBody pb="4" pt="5">
              <Stack
                width="100%"
                spacing={{ base: '2', lg: '4' }}
                direction={{ base: 'column', md: 'row' }}
                justifyContent={{ base: 'center', xl: 'flex-start' }}
                alignItems="center"
                minH={{ base: '20', md: '16' }}
                px="8"
                bg={informacaoStatusVencimento.corFundo}
                borderRadius="md"
              >
                <Flex w="100%" justifyContent="space-between">
                  <Box>
                    <Text color="black">Cliente</Text>
                    {informacoesContasAReceber?.clienteFornecedorNomeFantasia ? (
                      <Text color={informacaoStatusVencimento.corFonte}>
                        {
                          informacoesContasAReceber?.clienteFornecedorRazaoSocial
                        }{' '}
                        - (
                        {
                          informacoesContasAReceber?.clienteFornecedorNomeFantasia
                        }
                        )
                      </Text>
                    ) : (
                      <Text color={informacaoStatusVencimento.corFonte}>
                        {
                          informacoesContasAReceber?.clienteFornecedorRazaoSocial
                        }
                      </Text>
                    )}
                  </Box>
                  <Box>
                    <Text color="black">
                      {informacaoStatusVencimento.corFundo === 'aquamarine.50'
                        ? 'Valor recebido'
                        : 'Valor a receber'}
                    </Text>
                    <Flex justifyContent="right" alignItems="right">
                      <Skeleton isLoaded={!isInformacoesContasAReceberLoading}>
                        <Text color="black" fontWeight="bold">
                          {informacaoStatusVencimento.corFundo ===
                          'aquamarine.50'
                            ? `R$ ${DecimalMask(
                                informacoesContasAReceber?.valorRecebido,
                                casasDecimaisValor,
                                casasDecimaisValor
                              )}`
                            : `R$ ${DecimalMask(
                                valorAReceber,
                                casasDecimaisValor,
                                casasDecimaisValor
                              )}`}
                        </Text>
                      </Skeleton>
                    </Flex>
                  </Box>
                </Flex>
              </Stack>
              <Flex mt="10px" mb="30px">
                <HStack
                  display={['colum', 'flex']}
                  w="100%"
                  mb={['0', '24px']}
                  pl="2px"
                  fontSize="xs"
                >
                  <Box h={['33%', '60%']} w={['98%', '66%']}>
                    <Flex mb={['5px', '3']}>
                      <Text fontSize="xs" mr="2">
                        Data de vencimento:
                      </Text>
                      <Text fontWeight="bold">
                        {informacoesContasAReceber && (
                          <>
                            {formatDate(
                              informacoesContasAReceber?.dataVencimento || ''
                            )}
                          </>
                        )}
                      </Text>
                    </Flex>
                    <Flex mb={['5px', '3']}>
                      <Text fontSize="xs" mr="2">
                        Valor original:
                      </Text>
                      <Text fontWeight="bold">
                        {DecimalMask(
                          informacoesContasAReceber?.valorOriginal,
                          casasDecimaisValor,
                          casasDecimaisValor
                        )}
                      </Text>
                    </Flex>
                    <Flex mb={['5px', '3']}>
                      <Text fontSize="xs" mr="2">
                        Forma de recebimento
                      </Text>
                      <Text fontWeight="bold">
                        {informacoesContasAReceber?.formaRecebimento}
                      </Text>
                    </Flex>
                    <Flex h={['60px', '0']} mb={['5px', '3']}>
                      <Text fontSize="xs" mr="2">
                        Parcela
                      </Text>
                      <Text fontWeight="bold">
                        {informacoesContasAReceber?.parcela}
                      </Text>
                    </Flex>
                  </Box>
                  {isLargerThan699 ? (
                    <Flex width={['0', '2rem']} height={['0', '60%']}>
                      <Divider
                        color="gray.200"
                        orientation={
                          isLargerThan699 ? 'vertical' : 'horizontal'
                        }
                        w={['0', '10']}
                        h={['0', '6.5rem']}
                      />
                    </Flex>
                  ) : (
                    <></>
                  )}

                  <Box h={['33%', '66%']} w={['98%', '63%']}>
                    <Flex mt="2px" mb={['5px', '3']}>
                      <Text ml={{ base: '-7px' }} fontSize="xs" mr="2">
                        Data da emissão:
                      </Text>

                      <Text fontWeight="bold">
                        {informacoesContasAReceber && (
                          <>
                            {formatDate(
                              informacoesContasAReceber?.dataEmissao || ''
                            )}
                          </>
                        )}
                      </Text>
                    </Flex>
                    <Flex mb={['5px', '3']}>
                      <Text ml={{ base: '-7px' }} fontSize="xs" mr="2">
                        Plano de contas:
                      </Text>
                      <Text fontWeight="bold">
                        {informacoesContasAReceber?.planoContas}
                      </Text>
                    </Flex>

                    <Flex mb={['5px', '3']}>
                      <Text ml={{ base: '-7px' }} fontSize="xs" mr="2">
                        Competência:
                      </Text>
                      <Text fontWeight="bold">
                        {informacoesContasAReceber && (
                          <>
                            {formatDate(
                              informacoesContasAReceber?.competencia || ''
                            )}
                          </>
                        )}
                      </Text>
                    </Flex>

                    <Flex position="absolute">
                      <Text ml={{ base: '-7px' }} fontSize="xs" mr="2">
                        Histórico:
                      </Text>
                      <Text fontWeight="bold">
                        {informacoesContasAReceber?.historico || '---'}
                      </Text>
                    </Flex>
                  </Box>
                  {isLargerThan699 ? (
                    <Flex
                      justifyContent="flex-start"
                      width={['0', '2rem']}
                      height={['0', '60%']}
                    >
                      <Divider
                        color="gray.200"
                        orientation={
                          isLargerThan699 ? 'vertical' : 'horizontal'
                        }
                        w={['0', '10']}
                        h={['0', '4.7rem']}
                      />
                    </Flex>
                  ) : (
                    <Box />
                  )}

                  <Box h={['33%', '60%']} w={['98%', '58%']}>
                    <Flex mb={['5px', '3']}>
                      <Text ml={{ base: '-7px' }} fontSize="xs" mr="2">
                        Número do documento:
                      </Text>
                      <Text
                        textDecoration={
                          informacoesContasAReceber?.numeroDocumento
                            ? 'underline'
                            : 'none'
                        }
                      >
                        {informacoesContasAReceber?.numeroDocumento || '---'}
                      </Text>
                    </Flex>
                    <Flex mb={['0', '3']}>
                      <Text ml={{ base: '-7px' }} fontSize="xs" mr="2">
                        Identificador:
                      </Text>
                      <Link
                        id="link-visualizar"
                        href={handlePushVerDetalheConta()}
                        target="_blank"
                      >
                        {`${TypeOfOperation?.current?.[0].name} ${informacoesContasAReceber?.identificador}`}
                      </Link>
                    </Flex>
                  </Box>
                </HStack>
              </Flex>

              <Box
                maxW="full"
                overflow="auto"
                border="1px"
                borderColor="gray.100"
                borderRadius="md"
                maxH="180px"
              >
                <Table
                  boxShadow="md"
                  p="6"
                  rounded="md"
                  variant=""
                  bg="white"
                  borderRadius="md"
                  sx={{
                    '& th, & td': {
                      position: 'relative',
                      _after: {
                        content: '" "',
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        h: 'full',
                        w: 'full',
                        borderBottom: '1px',
                        borderColor: 'gray.100',
                      },
                    },
                    '& th:first-child, & td:first-child': {
                      _after: {
                        left: 3,
                      },
                    },
                    '& th:last-child, & td:last-child': {
                      _after: {
                        right: 3,
                        left: 'auto',
                      },
                    },
                  }}
                >
                  <Thead color="gray.700">
                    <Tr>
                      <Th whiteSpace="nowrap">Recebimento</Th>
                      <Th whiteSpace="nowrap">Forma</Th>
                      <Th whiteSpace="nowrap">Conta financeira</Th>
                      <Th textAlign="right" whiteSpace="nowrap">
                        Valor original
                      </Th>
                      <Th textAlign="right" whiteSpace="nowrap">
                        Multa e juros
                      </Th>
                      <Th textAlign="right" whiteSpace="nowrap">
                        Desconto
                      </Th>
                      <Th textAlign="right" whiteSpace="nowrap">
                        Total recebido
                      </Th>
                      <Th textAlign="right" whiteSpace="nowrap">
                        Taxa transação
                      </Th>
                      <Th whiteSpace="nowrap">Compensação</Th>
                      <Th whiteSpace="nowrap">Operador</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {informacoesContasAReceber?.movimentacaoFinanceiraBaixa.map(
                      (movimentacaoFinanceiraBaixa) => {
                        return (
                          <Tr>
                            <Td>
                              <Text>
                                {movimentacaoFinanceiraBaixa.dataPagamento
                                  ? formatDate(
                                      movimentacaoFinanceiraBaixa.dataPagamento
                                    )
                                  : '---'}
                              </Text>
                            </Td>
                            <Td>
                              <Text>
                                {movimentacaoFinanceiraBaixa.formaPagamento
                                  ? movimentacaoFinanceiraBaixa.formaPagamento
                                  : '---'}
                              </Text>
                            </Td>
                            <Td>
                              <Text>
                                {movimentacaoFinanceiraBaixa.contaFinanceira
                                  ? movimentacaoFinanceiraBaixa.contaFinanceira
                                  : '---'}
                              </Text>
                            </Td>
                            <Td textAlign="right">
                              <Text>
                                {movimentacaoFinanceiraBaixa.valor
                                  ? `R$ ${DecimalMask(
                                      movimentacaoFinanceiraBaixa.valor,
                                      casasDecimaisValor,
                                      casasDecimaisValor
                                    )}`
                                  : '---'}
                              </Text>
                            </Td>
                            <Td textAlign="right">
                              <Text>
                                {movimentacaoFinanceiraBaixa.multaJuros
                                  ? `R$ ${DecimalMask(
                                      movimentacaoFinanceiraBaixa.multaJuros,
                                      casasDecimaisValor,
                                      casasDecimaisValor
                                    )}`
                                  : '---'}
                              </Text>
                            </Td>
                            <Td textAlign="right">
                              <Text>
                                {movimentacaoFinanceiraBaixa.desconto
                                  ? `R$ ${DecimalMask(
                                      movimentacaoFinanceiraBaixa.desconto,
                                      casasDecimaisValor,
                                      casasDecimaisValor
                                    )}`
                                  : '---'}
                              </Text>
                            </Td>
                            <Td textAlign="right">
                              <Text>
                                {movimentacaoFinanceiraBaixa.totalRecebido
                                  ? `R$ ${DecimalMask(
                                      movimentacaoFinanceiraBaixa.totalRecebido,
                                      casasDecimaisValor,
                                      casasDecimaisValor
                                    )}`
                                  : '---'}
                              </Text>
                            </Td>
                            <Td textAlign="right">
                              <Text>
                                {movimentacaoFinanceiraBaixa.taxaTransacao
                                  ? `R$ ${DecimalMask(
                                      movimentacaoFinanceiraBaixa.taxaTransacao,
                                      casasDecimaisValor,
                                      casasDecimaisValor
                                    )}`
                                  : '---'}
                              </Text>
                            </Td>

                            <Td>
                              <Text>
                                {movimentacaoFinanceiraBaixa.compensacao
                                  ? formatDate(
                                      movimentacaoFinanceiraBaixa.compensacao
                                    )
                                  : '---'}
                              </Text>
                            </Td>
                            <Td>
                              <Text>
                                {movimentacaoFinanceiraBaixa?.usuarioBaixa}
                              </Text>
                            </Td>
                          </Tr>
                        );
                      }
                    )}
                  </Tbody>
                  <Tfoot
                    sx={{
                      '& th, & td': {
                        height: '35px',
                        _after: {
                          content: '" "',
                          position: 'absolute',
                          left: 0,
                          top: 0,
                          h: 'full',
                          w: 'full',
                          borderBottom: '0',
                          borderColor: 'white',
                        },
                      },
                      '& th:first-child, & td:first-child': {
                        _after: {
                          left: 3,
                        },
                      },
                      '& th:last-child, & td:last-child': {
                        _after: {
                          right: 3,
                          left: 'auto',
                        },
                      },
                    }}
                  >
                    <Tr>
                      {somarValoresMovimentacoesFinanceirasBaixa.totalRecived ===
                      0 ? (
                        <Td colSpan={9} fontSize="12px">
                          Nenhum resultado foi encontrado.
                        </Td>
                      ) : (
                        <>
                          <Td />

                          <Td />
                          <Td />
                          <Td
                            textAlign="right"
                            whiteSpace="nowrap"
                            fontWeight="bold"
                          >
                            <Box>
                              {somarValoresMovimentacoesFinanceirasBaixa.value ? (
                                <>
                                  <Text>Valor original</Text>
                                  <Text>{`R$ ${DecimalMask(
                                    somarValoresMovimentacoesFinanceirasBaixa.value,
                                    casasDecimaisValor,
                                    casasDecimaisValor
                                  )}`}</Text>
                                </>
                              ) : null}
                            </Box>
                          </Td>
                          <Td
                            textAlign="right"
                            whiteSpace="nowrap"
                            fontWeight="bold"
                          >
                            <Box>
                              {somarValoresMovimentacoesFinanceirasBaixa.multaEJuros ? (
                                <>
                                  <Text>Multa e juros</Text>
                                  <Text>
                                    {`R$ ${DecimalMask(
                                      somarValoresMovimentacoesFinanceirasBaixa.multaEJuros,
                                      casasDecimaisValor,
                                      casasDecimaisValor
                                    )}`}
                                  </Text>
                                </>
                              ) : null}
                            </Box>
                          </Td>
                          <Td textAlign="right" fontWeight="bold">
                            <Box>
                              {somarValoresMovimentacoesFinanceirasBaixa.desconto ? (
                                <>
                                  <Text>Desconto</Text>
                                  <Text>
                                    {`R$ ${DecimalMask(
                                      somarValoresMovimentacoesFinanceirasBaixa.desconto,
                                      casasDecimaisValor,
                                      casasDecimaisValor
                                    )}`}
                                  </Text>
                                </>
                              ) : null}
                            </Box>
                          </Td>
                          <Td
                            textAlign="right"
                            whiteSpace="nowrap"
                            fontWeight="bold"
                          >
                            <Box>
                              {somarValoresMovimentacoesFinanceirasBaixa.totalRecived ? (
                                <>
                                  <Text>Total recebido</Text>
                                  <Text>
                                    {`R$ ${DecimalMask(
                                      somarValoresMovimentacoesFinanceirasBaixa.totalRecived,
                                      casasDecimaisValor,
                                      casasDecimaisValor
                                    )}`}
                                  </Text>
                                </>
                              ) : null}
                            </Box>
                          </Td>
                          <Td textAlign="right" fontWeight="bold">
                            <Box>
                              {somarValoresMovimentacoesFinanceirasBaixa.taxaTransacao ? (
                                <>
                                  <Text>Taxa operação</Text>
                                  <Text>
                                    {`R$ ${DecimalMask(
                                      somarValoresMovimentacoesFinanceirasBaixa.taxaTransacao,
                                      casasDecimaisValor,
                                      casasDecimaisValor
                                    )}`}
                                  </Text>
                                </>
                              ) : null}
                            </Box>
                          </Td>
                          <Td />
                        </>
                      )}
                    </Tr>
                  </Tfoot>
                </Table>
              </Box>

              <Flex alignItems="center" justifyContent="center" mt="8" mb="4">
                <Button
                  w="96px"
                  onClick={onClose}
                  colorScheme="gray"
                  variant="outlineDefault"
                >
                  Fechar
                </Button>
              </Flex>
            </ModalBody>
          </Flex>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
