export type Quantidade = {
  quantidade: number;
  operacaoItemId: string;
  id?: string;
};

export type OperacaoProps = {
  id: string;
  type: 'devolucaoDeDinheiro' | 'gerarVoucher' | 'trocarProduto' | null;
};

export type ClienteListarProps = {
  id: string;
  nome: string;
  codigo: number;
  cpfCnpj: string;
  regraLimiteCredito: number;
  limiteCredito: number;
  regraLimiteCreditoDescricao: string;
  tabelaPrecoId: string;
  tabelaPrecoNome: string;
};

export type FormData = {
  quantidade: number[];
  quantidadeFields: Quantidade[];
  cliente: ClienteListarProps;
};

export type ListaTrocaProps = {
  numeroVenda: number;
  idOperacao: string;
  nomeCliente: string;
  idCliente: string;
};

export type ProdutoSelecionadoProps = {
  nome: string;
  produtoCorTamanhoId: string;
  quantidade: number;
  valor: number;
  isChecked: boolean;
  desconto: number;
  acrescimo: number;
  operacaoItemId: string;
  volumeUnitario: boolean;
};

export type EditarItens = {
  display: string;
  tamanho: string;
  aparecerItem: boolean;
};

export type InformacaoAdicionalDoItemProps = {
  tabelaPreco: string;
  contaFinanceira: string;
  vendedor: string;
  numeroVenda: number;
};

export const formDefaultValues = {
  quantidade: [1],
};
