import { Flex, Text } from '@chakra-ui/react';
import React from 'react';

import BarcodeComponent from 'components/CodigoBarras';

type OperationNumberProps = {
  numeroOperacao: number;
};

export const OperationNumber = ({ numeroOperacao }: OperationNumberProps) => (
  <Flex w="full" gap="2px">
    <Flex
      flexDir="column"
      align="center"
      border="1px solid #bbb"
      mt="2px"
      px="4px"
      pb="2px"
      gap="12px"
      minWidth="40%"
      pt="4px"
    >
      <Text
        bg="gray.100"
        fontWeight="extrabold"
        padding="4px"
        fontSize="2xs"
        w="full"
        textAlign="center"
      >
        LANÇAMENTO
      </Text>
      <Text fontSize="sm" textAlign="center" w="full" lineHeight="1.2">
        {numeroOperacao}
      </Text>
    </Flex>
    <Flex
      minWidth="60%"
      align="center"
      justify="center"
      border="1px solid #bbb"
      mt="2px"
    >
      <BarcodeComponent
        value={numeroOperacao?.toString()}
        options={{
          background: 'transparent',
          height: 36,
          font: 'Nunito, sans-serif',
          width: 1.8,
          lineColor: '#151515',
          margin: 4,
          fontSize: 12,
          flat: true,
        }}
      />
    </Flex>
  </Flex>
);
