import React from 'react';
import { IconType as ReactIconsIconType } from 'react-icons/lib';
import { useMediaQuery, Tooltip, Text, Flex } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { useNavMenuContext } from 'store/NavMenu';
import { useLayoutContext } from 'store/Layout';

import { IconType } from 'icons';

import { Container } from './components/container';

interface NavItemInterface {
  menuKey?: string;
  title: string;
  icon: IconType | ReactIconsIconType;
  path?: string;
}

const NavItem = ({ menuKey, title, icon: Icon, path }: NavItemInterface) => {
  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');
  const { setMenuIsOpen } = useLayoutContext();

  const {
    openMenuKey,
    setOpenMenu,
    handleHistoryPush,
    navMenuIsOpen,
    setNavMenuIsOpen,
  } = useNavMenuContext();

  const isOpen = openMenuKey === menuKey;

  function handleSelectMenu() {
    if (isSmallerThan900) {
      setNavMenuIsOpen(false);
      setMenuIsOpen(false);
    }

    handleHistoryPush(path);
    setOpenMenu(isOpen ? '' : menuKey);
  }

  return (
    <Tooltip
      hasArrow
      label={title}
      placement="right"
      bg="menu.hoverText"
      color="primary.800"
      borderWidth="1px"
      borderStyle="solid"
      isDisabled={navMenuIsOpen || isOpen}
    >
      <Link
        to={path || '#'}
        onClick={(e) => {
          if (!path) {
            e.preventDefault();
          }
        }}
      >
        <Container
          id={menuKey}
          isOpen={menuKey ? isOpen : false}
          menuIsOpen={navMenuIsOpen}
          onClick={!path ? handleSelectMenu : () => {}}
        >
          <Flex
            justifyContent="center"
            fontSize="1.5rem"
            minWidth="1.5rem"
            pointerEvents="none"
            alignItems="center"
          >
            <Icon />
          </Flex>
          <Text
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            fontSize="0.75rem"
            ml="10px"
            minWidth="100px"
          >
            {title}
          </Text>
        </Container>
      </Link>
    </Tooltip>
  );
};

export default NavItem;
