const OperacoesFiscaisEnum = {
  Entrada: 0,
  Saida: 1,

  properties: {
    1: { name: 'Entrada', value: 0 },
    2: { name: 'Saída', value: 1 },
  },
};

export default OperacoesFiscaisEnum;
