import { useRef, useEffect } from 'react';
import { useMediaQuery, Box } from '@chakra-ui/react';

import useWindowSize from 'helpers/layout/useWindowSize';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';
import { useProdutosConsignadosContext } from 'store/PDV/ProdutosConsignados';

import ShadowScrollbar, {
  ShadowScrollbarForwardRefProps,
} from 'components/PDV/Geral/ShadowScrollbar';

import { TableDadosConsignacao } from './ItensTableDadosConsignacao';

type ListarProdutosConsignadosProps = {
  quantidade: number[];
};

export const ListarProdutosConsignados = ({
  quantidade,
}: ListarProdutosConsignadosProps) => {
  const { casasDecimais } = usePadronizacaoContext();

  const { height: windowHeight } = useWindowSize();
  const shadowScrollbarRef = useRef<ShadowScrollbarForwardRefProps>(null);

  const { produtosConsignados } = useProdutosConsignadosContext();
  const [isLargerThan900] = useMediaQuery('(max-width: 900px)');

  const heightTela = isLargerThan900 ? 370 : 210;

  useEffect(() => {
    setTimeout(() => {
      if (shadowScrollbarRef.current)
        shadowScrollbarRef.current.handleUpdateScrollbar();
    }, 200);
  }, [produtosConsignados]);

  return (
    <>
      <ShadowScrollbar
        width="100%"
        maxHeight={windowHeight - heightTela}
        paddingTop="0"
        shadowTopStyle={{
          background:
            'transparent linear-gradient(180deg, var(--#F5F5F5)  0%,  #FFFFFF00 100%) 0% 0% no-repeat padding-box',
          height: 30,
        }}
        shadowBottomStyle={{
          background:
            'transparent linear-gradient(180deg, #FFFFFF00 0%, var(--#F5F5F5) 100%) 0% 0% no-repeat padding-box',
          height: 30,
        }}
        ref={shadowScrollbarRef}
      >
        <Box w="calc(100% - 15px)">
          <TableDadosConsignacao
            casasDecimais={casasDecimais}
            quantidade={quantidade}
          />
        </Box>
      </ShadowScrollbar>
    </>
  );
};
