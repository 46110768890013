import { Icon, Box, HStack, Tooltip, Text, Flex } from '@chakra-ui/react';

import formatDateHourMinute from 'helpers/format/formatUTCToLocateDateTime';

import { CalendarioDataCadastroIcon } from 'icons';

type DataExibindoCriacaoAlteracao = {
  dataHoraCriacao: string;
  dataHoraUltimaAlteracao: string;
};

export const DataExibindoCriacaoAlteracao = ({
  dataHoraCriacao,
  dataHoraUltimaAlteracao,
}: DataExibindoCriacaoAlteracao) => {
  const transformandoDataHoraCriacao = dataHoraCriacao
    ? formatDateHourMinute(dataHoraCriacao)
    : undefined;
  const transformandoDataHotaUltimaAlteracao = dataHoraUltimaAlteracao
    ? formatDateHourMinute(dataHoraUltimaAlteracao)
    : undefined;

  return (
    <Flex mt="10px" justifyContent="flex-end">
      <HStack spacing="1">
        <Text fontSize="2sx">
          {transformandoDataHoraCriacao?.split(' ')[0]}
        </Text>

        <Tooltip
          hasArrow
          textAlign="left"
          placement="left"
          label={
            <>
              {`Data do Cadastro: ${transformandoDataHoraCriacao}`}
              <br />
              {`Última Alteração: ${transformandoDataHotaUltimaAlteracao}`}
            </>
          }
        >
          <Box tabIndex={0}>
            <Icon as={CalendarioDataCadastroIcon} boxSize="4" />
          </Box>
        </Tooltip>
      </HStack>
    </Flex>
  );
};
