import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import auth from 'modules/auth';
import { ResponseApi } from 'services/api';
import { sistemaApiKey } from 'services/sistemaApiKey';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

type CodigoAtivacaoProps = {
  listCodigoAtivacao: string[];
  pin: string;
};

type QuantidadeDispositivoProps = {
  total: number;
  ativo: boolean;
};

let activationCodeTimeout: NodeJS.Timeout;

export const useDados = () => {
  const [codigoAtivacao, setCodigoAtivacao] = useState<CodigoAtivacaoProps>();
  const [activationCodeWasCopied, setActivationCodeWasCopied] = useState(false);
  const [urlScript, setUrlScript] = useState<string | undefined>();
  const [
    amountDispositivo,
    setAmountDispositivo,
  ] = useState<QuantidadeDispositivoProps>();
  const [isLoading, setIsLoading] = useState(false);

  const idUsuarioLogado = auth.getDadosToken().userId;

  const getPin = useCallback(async () => {
    const response = await sistemaApiKey().get<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.FRENTE_CAIXA_GERAR_PIN
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }
      if (response.sucesso) {
        const { dados } = response;

        setCodigoAtivacao({
          pin: dados,
          listCodigoAtivacao: Array.from(dados),
        });
      }
    }
  }, []);

  const getAmountDispositivo = useCallback(async () => {
    const response = await sistemaApiKey().get<
      void,
      ResponseApi<QuantidadeDispositivoProps>
    >(ConstanteEnderecoWebservice.FRENTE_CAIXA_QUANTIDADE_DISPOSITIVO);

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }
      if (response.sucesso) {
        const { dados } = response;

        setAmountDispositivo(dados);
      }
    }
  }, []);

  const exportarDados = useCallback(async () => {
    setIsLoading(true);
    const response = await sistemaApiKey().post<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.FRENTE_CAIXA_EXPORTAR,
      {
        userId: idUsuarioLogado,
        pin: codigoAtivacao?.pin,
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }
      if (response.sucesso) {
        toast.success('A exportação dos dados foi enviado para processamento.');
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [codigoAtivacao, idUsuarioLogado]);

  const gerarScript = useCallback(async () => {
    setIsLoading(true);
    const response = await sistemaApiKey().post<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.FRENTE_CAIXA_GERAR_SCRIPT,
      {
        userId: idUsuarioLogado,
        pin: codigoAtivacao?.pin,
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }
      if (response.sucesso) {
        toast.success('A geração de  script foi enviado para processamento.');
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [codigoAtivacao, idUsuarioLogado]);

  const getScript = useCallback(async () => {
    const response = await sistemaApiKey().get<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.FRENTE_CAIXA_BAIXAR_SCRIPT
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }
      if (response.sucesso) {
        setUrlScript(response.dados);
      }
    }
  }, []);

  const handleBaixarScript = () => {
    if (!urlScript) {
      return;
    }
    window.open(urlScript);
  };

  const handleCopyActivationCode = () => {
    if (!codigoAtivacao?.pin) {
      return;
    }

    clearTimeout(activationCodeTimeout);

    setActivationCodeWasCopied(true);
    navigator.clipboard.writeText(codigoAtivacao.pin);

    activationCodeTimeout = global.setTimeout(() => {
      setActivationCodeWasCopied(false);
    }, 2000);
  };

  useEffect(() => {
    const getRequests = async () => {
      setIsLoading(true);
      await Promise.all([getPin(), getScript(), getAmountDispositivo()]);
      setIsLoading(false);
    };
    getRequests();
  }, [getAmountDispositivo, getPin, getScript]);

  return {
    codigoAtivacao,
    gerarScript,
    handleBaixarScript,
    handleCopyActivationCode,
    amountDispositivo,
    urlScript,
    isLoading,
    exportarDados,
    activationCodeWasCopied,
  };
};
