import React, { useMemo } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  useDisclosure,
  ModalHeader,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { BrowserRouter, Route } from 'react-router-dom';

import PadronizacaoProvider from 'store/Padronizacao/Padronizacao';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import CadastrarProduto, {
  ProdutosProps,
} from 'pages/Produtos/Formulario/Cadastrar';
import { ProdutoProps } from 'types/produto';

type ModalCadastrarProdutoResponse = {
  produto: any;
};

type Product = {
  documentoFiscalItemId: string;
  descricaoProduto: string;
  quantidade: number;
  valorUnitario: number;
  valorTotal: number;
  cfop: string;
  codigoGTINEAN: string;
  ncm: string;
  codigoCest: string;
};

type ModalCadastrarProdutoProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalCadastrarProdutoResponse> & {
    inputValue: string;
    listProducts?: ProdutosProps;
    product?: Product;
  };

export const ModalCadastrarProduto = create<
  ModalCadastrarProdutoProps,
  ModalCadastrarProdutoResponse
>(({ onResolve, onReject, inputValue, listProducts, product, ...rest }) => {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const cadastrarProduto = useMemo(
    () => (
      <PadronizacaoProvider>
        <BrowserRouter>
          <Route
            render={(props) => (
              <CadastrarProduto
                {...props}
                cadastroExterno
                produtos={product}
                listProducts={listProducts}
                nomeProduto={product?.descricaoProduto ?? inputValue}
                cadastroSucessoCallback={(produto: ProdutoProps) => {
                  onResolve({ produto });
                }}
                cadastroCancelar={() => {
                  onReject();
                  onClose();
                }}
              />
            )}
          />
        </BrowserRouter>
      </PadronizacaoProvider>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputValue, product]
  );

  return (
    <ModalPadraoChakra size="full" {...rest} isOpen={isOpen} onClose={onClose}>
      <ModalContent bg="gray.50">
        <ModalHeader
          bg="primary.50"
          color="secondary.300"
          px={{ base: 6, md: 8 }}
          pb="5px"
        >
          Cadastrar Produto
        </ModalHeader>

        <ModalBody p="0">{cadastrarProduto}</ModalBody>
      </ModalContent>
    </ModalPadraoChakra>
  );
});
