import { Text, GridItem } from '@chakra-ui/react';

import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';

import { DecimalMask } from 'helpers/format/fieldsMasks';

type ResumoCaixaValue = {
  qtdVendasConcluidas: number;
  qtdVendasCanceladas: number;
  totalValorSangria: number;
  totalValorSuprimento: number;
  totalValorVendasConcluidas: number;
  totalValorDevolucoes: number;
  saldo: number;
};

type ResumoCaixaProps = {
  resumoCaixa?: ResumoCaixaValue;
};

const ResumoCaixa = ({ resumoCaixa }: ResumoCaixaProps) => {
  const saldo = resumoCaixa
    ? resumoCaixa?.totalValorVendasConcluidas -
      resumoCaixa?.totalValorDevolucoes
    : 0;

  return (
    <>
      <SimpleGridForm
        spacingY="1"
        borderBottom="1px solid"
        borderBottomColor="gray.700"
        paddingTop="4"
        paddingBottom="2"
        sx={{
          '& div, & p': {
            color: 'black',
            fontWeight: '900',
            fontSize: '10px',
            fontFamily: 'arial',
          },
          '& p': {
            color: 'black',
            fontWeight: '900',
            fontSize: '10px',
            fontFamily: 'arial',
          },
          '& div, & hr, & p': {
            opacity: 'unset',
            fontSize: '10px',
            borderColor: 'black',
          },
        }}
      >
        <GridItem colSpan={6}>
          <Text fontWeight="extrabold" color="gray.700">
            Qtd vendas concluídas:
          </Text>
        </GridItem>
        <GridItem colSpan={6}>
          <Text fontWeight="extrabold" align="right">
            {resumoCaixa?.qtdVendasConcluidas}
          </Text>
        </GridItem>
        <GridItem colSpan={6}>
          <Text fontWeight="extrabold" color="gray.700">
            Qtd vendas canceladas:
          </Text>
        </GridItem>
        <GridItem colSpan={6}>
          <Text fontWeight="extrabold" align="right">
            {resumoCaixa?.qtdVendasCanceladas}
          </Text>
        </GridItem>
        <GridItem colSpan={6}>
          <Text>Total suprimentos:</Text>
        </GridItem>
        <GridItem colSpan={6}>
          <Text align="right">
            {DecimalMask(resumoCaixa?.totalValorSuprimento, 2)}
          </Text>
        </GridItem>
        <GridItem colSpan={6}>
          <Text>Total sangria:</Text>
        </GridItem>
        <GridItem colSpan={6}>
          <Text align="right">
            {DecimalMask(resumoCaixa?.totalValorSangria, 2)}
          </Text>
        </GridItem>
        <GridItem colSpan={6}>
          <Text fontWeight="extrabold" color="gray.700">
            (+) Total vendas:
          </Text>
        </GridItem>
        <GridItem colSpan={6}>
          <Text fontWeight="extrabold" align="right">
            {DecimalMask(resumoCaixa?.totalValorVendasConcluidas, 2)}
          </Text>
        </GridItem>
        <GridItem colSpan={6}>
          <Text fontWeight="extrabold" color="gray.700">
            (-) Total devoluções:
          </Text>
        </GridItem>
        <GridItem colSpan={6}>
          <Text fontWeight="extrabold" align="right">
            {DecimalMask(resumoCaixa?.totalValorDevolucoes, 2)}
          </Text>
        </GridItem>

        <GridItem colSpan={6}>
          <Text fontWeight="extrabold" color="gray.700">
            (=) Saldo:
          </Text>
        </GridItem>
        <GridItem colSpan={6}>
          <Text fontWeight="extrabold" align="right">
            {DecimalMask(saldo, 2)}
          </Text>
        </GridItem>
      </SimpleGridForm>
    </>
  );
};

export default ResumoCaixa;
