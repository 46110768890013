import React from 'react';
import { Tr, Th, Td, Text, VStack, HStack, Accordion } from '@chakra-ui/react';

import { TextValor } from 'components/PDV/Text/TextValor';

import Card from '../Card';
import Table from '../Table';
import TableRowFormaPagamento from './TableRowFormaPagamento';

type Parcela = {
  numeroParcela: number;
  dataVencimento: Date;
  valor: number;
};

type MovimentacaoFinanceira = {
  descricaoFormaPagto: string;
  descricaoParcelas: string;
  dataVencimento: Date;
  valor: number;
  parcelas: Parcela[];
  cupomTroca: boolean;
};

interface FormaPagamentoProps {
  movimentacoesFinanceiras: MovimentacaoFinanceira[];

  troco: number;
  totalPago: number;

  isLoading: boolean;
}

const FormaPagamento = ({
  movimentacoesFinanceiras,
  troco,
  totalPago,
  isLoading,
}: FormaPagamentoProps) => {
  return (
    <Card title="Forma de recebimento" padding="12px 24px 12px 24px">
      <Accordion allowToggle>
        <Table
          theadContent={
            <Tr>
              <Th w="30%">Forma</Th>
              <Th w="30%">Condição</Th>
              <Th w="20%">Vencimento</Th>
              <Th w="20%" isNumeric>
                Valor total
              </Th>
              <Th minW="80px" />
            </Tr>
          }
          tbodyContent={
            <>
              {movimentacoesFinanceiras &&
                movimentacoesFinanceiras.map((movimentacaoFinanceira) => (
                  <TableRowFormaPagamento
                    descricaoFormaPagto={
                      movimentacaoFinanceira.descricaoFormaPagto
                    }
                    descricaoParcelas={movimentacaoFinanceira.descricaoParcelas}
                    dataVencimento={movimentacaoFinanceira.dataVencimento}
                    valor={movimentacaoFinanceira.valor}
                    parcelas={movimentacaoFinanceira.parcelas}
                    cupomTroca={movimentacaoFinanceira.cupomTroca}
                  />
                ))}

              {!isLoading && movimentacoesFinanceiras.length === 0 && (
                <Tr>
                  <Td colSpan={5}>
                    Não foi encontrado nenhuma forma de pagamento.
                  </Td>
                </Tr>
              )}
            </>
          }
          tfootContent={
            <Tr>
              <Th py="5" colSpan={4}>
                <HStack justifyContent="flex-end" gap="72px" pr="20px">
                  <VStack alignItems="flex-end">
                    <Text
                      lineHeight="none"
                      fontSize="xs"
                      color="black"
                      fontWeight="semibold"
                      letterSpacing="0px"
                    >
                      Troco
                    </Text>
                    <TextValor
                      valor={troco}
                      casasDecimais={2}
                      symbolFontWeight="bold"
                      symbolFontSize="2xs"
                      fontSize="sm"
                      color="black"
                    />
                  </VStack>

                  <VStack alignItems="flex-end">
                    <Text
                      lineHeight="none"
                      fontSize="xs"
                      color="black"
                      fontWeight="semibold"
                      letterSpacing="0px"
                    >
                      Valor total pago
                    </Text>
                    <TextValor
                      valor={totalPago}
                      casasDecimais={2}
                      symbolFontWeight="bold"
                      symbolFontSize="2xs"
                      fontSize="sm"
                      color="blue.500"
                    />
                  </VStack>
                </HStack>
              </Th>
            </Tr>
          }
        />
      </Accordion>
    </Card>
  );
};

export default FormaPagamento;
