import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

const shapeRegraFiscalExcecao = {
  tipoPlanoConta: yup
    .number()
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(null),
  nome: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .max(60, ConstanteMensagemValidacao.MAX_LENGTH_60)
    .default(undefined),
  exibirDre: yup
    .boolean()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(true),
  exibirFluxoDeCaixa: yup
    .boolean()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(true),
};

export const schemaRegraFiscalExcecao = yup
  .object()
  .shape(shapeRegraFiscalExcecao);

export const yupResolver = yupResolverInstance(schemaRegraFiscalExcecao);

export const defaultValues = schemaRegraFiscalExcecao.default();

export const useForm = useFormInstance;
