import React from 'react';
import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import { RelatorioInventario } from '../pages/Relatorio/RelatorioInventario';

export const RelatorioInventarioRoutes = [
  <LayoutGuard
    key="relatorio-inventario-listar"
    component={RelatorioInventario}
    breadcrumb={[
      {
        title: 'Relatórios',
      },
      {
        title: 'Inventário',
      },
    ]}
    path={ConstanteRotas.RELATORIO_INVENTARIO}
    exact
    meta={{ auth: true }}
  />,
];
