import { VStack, Text, Flex } from '@chakra-ui/react';

import ContatoEmpresa from 'constants/contato';

interface OutrasInformacoesProps {
  usuarioNome: string;
  vendedorNome: string;
}

export const OutrasInformacoes = ({
  usuarioNome,
  vendedorNome,
}: OutrasInformacoesProps) => {
  return (
    <>
      <Flex
        direction="column"
        alignItems="flex-start"
        pt={2}
        pb={2}
        gap="3px"
        borderBottom="2px solid"
        borderColor="gray.700"
      >
        <VStack>
          <Text color="black" fontWeight="900" fontSize="x-small">
            Operador(a): {usuarioNome}
          </Text>
        </VStack>
        <VStack>
          <Text color="black" fontWeight="900" fontSize="x-small">
            Vendedor(a): {vendedorNome}
          </Text>
        </VStack>
      </Flex>
      <Text
        textAlign="right"
        fontStyle="italic"
        fontSize="2xs"
        fontWeight="bold"
        color="gray.900"
        mt={2}
      >
        {ContatoEmpresa.CONTATO}
      </Text>
    </>
  );
};
