export const enumIdentificacaoSituacao = {
  EM_ABERTO: 0,
  PEDIDO: 1,
  VENDA: 2,
  CANCELAMENTO: 3,

  options: [
    {
      label: 'Pedido',
      value: 1,
    },
    {
      label: 'Venda',
      value: 2,
    },
    {
      label: 'Cancelamento',
      value: 3,
    },
  ],
};

export const situacaoOperacaoSistema = {
  EM_ABERTO: 0,
  PENDENTE: 1,
  FINALIZADO: 2,
  CANCELADO: 3,
  options: [
    {
      label: 'Em aberto',
      value: 0,
    },
    {
      label: 'Pendente',
      value: 1,
    },
    {
      label: 'Finalizado',
      value: 2,
    },
    {
      label: 'Cancelado',
      value: 3,
    },
  ],
};
