/* eslint-disable import/named */
import {
  useEffect,
  useState,
  useCallback,
  useRef,
  useLayoutEffect,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import isPrenvent from 'helpers/layout/isPrenvent';
import ConstanteRotas from 'constants/rotas';
import GenerosEnum from 'constants/enum/generos';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import PerfilUsuarioPersonalizadoOpcaoConstante from 'constants/perfilUsuarioPersonalizadoOpcao';
import FormularioUsuariosProvider from 'store/FormularioUsuarios';
import listarCategoriaPermissoes from 'helpers/api/PerfilUsuario/listarCategoriaPermissoes';
import auth from 'modules/auth';

import { CategoriaPermissaoInterface } from 'components/Permissoes';
import { ContainerListagem } from 'components/Layout/Listagem/containerListagem';
import { LojasInterface } from 'pages/Usuarios/Formulario/TabsContent/Lojas';

import { useForm, yupResolver } from '../validationForm';
import { ModalInterromperAcesso } from './components/ModalInterromperAcesso';
import UncontrolledForm, { UncontrolledFormRefInterface } from '..';
import { LojasProps, PermissoesProps, UsuarioProps } from '../Types';

type TParams = { id: string };

const Alterar = () => {
  const history = useHistory();
  const isMountedRef = useIsMountedRef();
  isPrenvent();

  const { id: idRota } = useParams<TParams>();

  const formMethods = useForm({
    resolver: yupResolver,
    shouldUnregister: false,
  });

  const { handleSubmit, reset, getValues, setValue } = formMethods;

  const [usuarioId, setUsuarioId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isAdministrador, setIsAdministrador] = useState(false);
  const [isLicencaAtiva, setIsLicencaAtiva] = useState(false);
  const [dataHoraCadastro, setDataHoraCadastro] = useState('');
  const [dataHoraUltimaAlteracao, setDataHoraUltimaAlteracao] = useState('');
  const [listaVendedores, setListaVendedores] = useState<LojasInterface[]>([]);
  const [listaPermissoes, setListaPermissoes] = useState<PermissoesProps[]>([]);
  const [atualizarPermissoes, setAtualizarPermissoes] = useState(true);
  const [categoriasPermissao, setCategoriasPermissao] = useState<
    CategoriaPermissaoInterface[]
  >([]);

  const uncontrolledFormRef = useRef<UncontrolledFormRefInterface>();

  const getUsuario = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get<void, ResponseApi<any>>(
      ConstanteEnderecoWebservice.USUARIO_OBTER,
      {
        params: { id: idRota },
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados && isMountedRef.current) {
        const newCategoriasPermissao = await listarCategoriaPermissoes();

        const {
          id,
          dataHoraCadastro: newDataHoraCadastro,
          dataHoraUltimaAlteracao: newDataHoraUltimaAlteracao,
          perfilOpcaoSelect,
          grupos,
          permissoes,
          dataNascimento,
          lojaPadraoId,
          ativo,
          tipoUsuario,
          descontoMaximoPermitido,
          ...defaultValues
        } = response.dados;

        setIsAdministrador(response.dados.administrador);
        setIsLicencaAtiva(ativo);
        setUsuarioId(id);
        setDataHoraCadastro(newDataHoraCadastro);
        setDataHoraUltimaAlteracao(newDataHoraUltimaAlteracao);

        defaultValues.perfilusuario =
          perfilOpcaoSelect?.id ||
          PerfilUsuarioPersonalizadoOpcaoConstante.value;

        defaultValues.dataNascimento = dataNascimento?.split('T')[0];

        defaultValues.permissoes = []; // apenas inicializa o array

        if (newCategoriasPermissao) {
          newCategoriasPermissao.forEach((categoria) => {
            categoria.grupos.forEach((grupo) => {
              let todoSelecionados = true;

              grupo.permissoes.forEach((permissao) => {
                defaultValues.permissoes[permissao.index] =
                  permissao.selecionado;

                if (permissoes) {
                  const permissaoValue = permissoes.find(
                    (p: any) => p.id === permissao.id
                  );
                  if (permissaoValue) {
                    defaultValues.permissoes[permissao.index] =
                      permissaoValue.selecionado;
                  } else {
                    todoSelecionados = false;
                  }
                } else {
                  todoSelecionados = false;
                }
              });

              defaultValues[`grupo${grupo.id}`] = todoSelecionados;
            });
          });
        }

        setCategoriasPermissao(newCategoriasPermissao || []);

        reset({
          ...defaultValues,
          ativo,
          tipoUsuario,
          descontoMaximoPermitido,
          valueIdLojaPadrao: lojaPadraoId,
          pinCadastrado: !!defaultValues.pinUsuario,
        });

        defaultValues.lojas.forEach((loja: LojasProps) => {
          setValue(`lojaSelecionada.${loja.id}`, loja.selecionado);
          setValue(`localEstoqueId.${loja.id}`, loja.localEstoqueId);
          setValue(`vendedorId.${loja.id}`, loja.vendedorId);
        });
      } else {
        history.push(ConstanteRotas.USUARIO);
      }
    }

    if (isMountedRef.current) setIsLoading(false);
  }, [history, isMountedRef, idRota, reset, setValue]);

  const atualizarValoresPermissao = useCallback(() => {
    const permissoeswatch = getValues('permissoes');

    return listaPermissoes.map((permissao, index) => {
      return {
        id: permissao.id,
        selecionado: permissoeswatch[index],
      };
    });
  }, [listaPermissoes, getValues]);

  const handleAlterarUsuario = useCallback(async () => {
    const data = getValues();
    const permissao = atualizarValoresPermissao();

    const { lojaSelecionada, localEstoqueId, vendedorId } = data;

    const listLojas: LojasProps[] | null = data.lojas?.map(
      ({ id: lojaItemId }: LojasProps) => {
        return lojaSelecionada
          ? {
              id: lojaItemId,
              vendedorId: vendedorId[lojaItemId],
              localEstoqueId: localEstoqueId[lojaItemId],
              selecionado: lojaSelecionada[lojaItemId] || false,
            }
          : null;
      }
    );

    const hasLojaSelecionada = listLojas?.some(
      ({ selecionado }) => selecionado === true
    );

    if (!hasLojaSelecionada) {
      uncontrolledFormRef.current?.focusTabLojas();
      toast.warning('Selecione ao menos uma loja para o usuário');

      return false;
    }

    const dadosCadastroUsuario: UsuarioProps = {
      Id: usuarioId,
      Nome: data?.nome,
      Ativo: data?.ativo,
      Foto: data?.foto,
      Login: data?.login,
      LojaPadraoId: data?.lojaPadraoId,
      Senha: data?.senha,
      Confirmesenha: data?.confirmesenha,
      Email: data?.email,
      Telefone: data?.telefone?.trim(),
      Genero: data?.genero ? data?.genero : GenerosEnum.NAO_INFORMADO,
      PinUsuario: data?.pinUsuario,
      Datanascimento: data?.dataNascimento || null,
      PerfilId: data?.perfilusuario || null,
      Permissoes: permissao,
      AbrirPdvAposLogin: data?.abrirPdvAposLogin,
      TipoUsuario: data.tipoUsuario,
      Lojas: listLojas,
      descontoMaximoPermitido: data.descontoMaximoPermitido,
    };

    const listLojasSelecionadas = listLojas?.filter(
      (loja: LojasProps) => loja.selecionado
    );

    const hasPermissaoSelecionada = permissao.some(
      (permissaoItem) => permissaoItem.selecionado
    );

    const hasVendedorNaoSelecionado = listLojasSelecionadas?.some(
      (lojaItem) => !lojaItem.vendedorId
    );

    const hasLocalEstoqueNaoSelecionado = listLojasSelecionadas?.some(
      (lojaItem) => !lojaItem.localEstoqueId
    );

    if (
      !(hasVendedorNaoSelecionado || hasLocalEstoqueNaoSelecionado) &&
      hasPermissaoSelecionada
    ) {
      const response = await api.put<void, ResponseApi>(
        ConstanteEnderecoWebservice.USUARIO_ALTERAR,
        dadosCadastroUsuario
      );

      return response;
    }

    if (hasVendedorNaoSelecionado || hasLocalEstoqueNaoSelecionado) {
      uncontrolledFormRef.current?.focusTabLojas();
    } else {
      uncontrolledFormRef.current?.focusTabPerfil();
    }

    toast.warning(
      hasVendedorNaoSelecionado
        ? 'Selecione um vendedor para cada loja'
        : hasLocalEstoqueNaoSelecionado
        ? 'Selecione um local de estoque para cada loja'
        : 'Selecione ao menos uma permissão para o usuário'
    );

    return false;
  }, [atualizarValoresPermissao, getValues, usuarioId]);

  async function logOff() {
    await api.post<void, ResponseApi>(
      ConstanteEnderecoWebservice.AUTENTICACAO_LOGOFF
    );

    auth.clearTokenAndRedirect();
  }

  async function logoutUser(userId: string) {
    await api.post<void, ResponseApi>(
      `${ConstanteEnderecoWebservice.AUTENTICACAO_LOGOUT_USER}/${userId}`
    );
  }

  const handleOnInvalid = () => {
    uncontrolledFormRef.current?.focusTabDadosGerais();
  };

  const onSubmit = handleSubmit(async () => {
    setIsLoading(true);

    const response = await handleAlterarUsuario();

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }
      if (response.sucesso) {
        toast.success('Cadastro de usuário alterado com sucesso');
        const isCurrentUserBeingEdited =
          usuarioId === auth.getDadosToken().userId;
        try {
          const success = await ModalInterromperAcesso({
            isCurrentUser: isCurrentUserBeingEdited,
          });
          if (success) {
            if (isCurrentUserBeingEdited) {
              await logOff();
            } else {
              await logoutUser(usuarioId);
              history.push(ConstanteRotas.USUARIO);
            }
          }
        } catch (error) {
          setIsLoading(false);
          history.push(ConstanteRotas.USUARIO);
        }
      }
    }
    setIsLoading(false);
  }, handleOnInvalid);

  useLayoutEffect(() => {
    setIsLoading(true);

    getUsuario();
  }, [getUsuario]);

  useEffect(() => {
    if (atualizarPermissoes) {
      categoriasPermissao.forEach((categoria) => {
        categoria.grupos.forEach((grupo) => {
          grupo.permissoes.forEach((permissao) => {
            setAtualizarPermissoes(false);
            setListaPermissoes((valorAnterior) => [
              ...valorAnterior,
              { id: permissao.id },
            ]);
          });
        });
      });
    }
  }, [atualizarPermissoes, categoriasPermissao]);

  useEffect(() => {
    reset({
      descricao: '',
      permissoes: [],
    });
  }, [reset]);

  return (
    <ContainerListagem
      maxWidth="full"
      formMethods={formMethods}
      isLoading={isLoading}
      onSubmit={onSubmit}
      containerPossuiFundo={false}
      dataHoraUltimaAlteracao={dataHoraUltimaAlteracao}
      dataHoraCadastro={dataHoraCadastro}
    >
      <FormularioUsuariosProvider
        setCategorias={setCategoriasPermissao}
        categorias={categoriasPermissao}
      >
        <UncontrolledForm
          isLoading={isLoading}
          ref={uncontrolledFormRef}
          usuarioId={usuarioId}
          isAdministrador={isAdministrador}
          isAlteracao
          setListaVendedores={setListaVendedores}
          isLicencaAtiva={isLicencaAtiva}
        />
      </FormularioUsuariosProvider>
    </ContainerListagem>
  );
};

export default Alterar;
