import { useCallback, useState } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  ModalFooter,
  Box,
  useMediaQuery,
  keyframes,
  Divider,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';

import { reprocessarIntegracao } from 'services/tray';
import { enumIdentificacaoIntegracao } from 'constants/enum/enumIdentificacaoIntegracao';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { ListaIntegracaoPendenciaPedidoProps } from 'pages/Integracoes/LojaAplicativos/TrayCommerce/PainelAdministradorTray/VendasTray/validationForms';

import { useModalPendencia } from './hooks';
import { PendenciaItem } from './components/PendenciaItem';
import { ReprocessandoPendencia } from './components/ReprocessandoPendencia';
import { CardHeader } from './components/CardHeader';

type ResolveProps = {
  sucess: boolean;
};

type ModalPendenciaProps = Omit<ModalProps, 'children' | 'isOpen' | 'onClose'> &
  InstanceProps<ResolveProps> & {
    pendencias: ListaIntegracaoPendenciaPedidoProps[];
    numeroVenda: number;
    handleCancelarVenda: () => void;
    reloadList: () => void;
    trayId: string;
    integracaoPedidoId: string;
  };

export const ModalPendencia = create<ModalPendenciaProps>(
  ({
    onResolve,
    onReject,
    trayId,
    pendencias,
    handleCancelarVenda,
    numeroVenda,
    reloadList,
    integracaoPedidoId,
    ...rest
  }) => {
    const [listPendencia, setListPendencia] = useState<
      ListaIntegracaoPendenciaPedidoProps[]
    >(pendencias);
    const [isLoading, setIsLoading] = useState(false);
    const [clienteSistemaId, setClienteSistemaId] = useState<
      string | undefined
    >('');

    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    const finalizarPendenciaVenda = useCallback(async () => {
      await reprocessarIntegracao(
        enumIdentificacaoIntegracao.TRAY,
        integracaoPedidoId,
        clienteSistemaId
      );
      reloadList();
      onClose();
    }, [clienteSistemaId, onClose, integracaoPedidoId, reloadList]);

    const {
      handleSubmit,
      optionsPendences,
      animate,
      isReprocessandoPendencia,
    } = useModalPendencia(
      listPendencia,
      setListPendencia,
      setClienteSistemaId,
      onClose,
      isOpen,
      setIsLoading,
      finalizarPendenciaVenda
    );

    const animationKeyframes = keyframes`
    0% { transform: translateX(0); }
    50% { transform: translateX(1000px); opacity: 0}
    51% {transform: translateY(1400px);}
    55% { transform: translateX(1px); }
    100% { transform: translateX(0);}
    `;

    const animation = `${animationKeyframes} 1s ease-in-out`;

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? '4xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
      >
        <ModalContent
          bg="gray.50"
          borderRadius={isLargerThan900 ? 'md' : '0'}
          marginBottom={isLargerThan900 ? '3.75rem' : '0'}
          marginTop={isLargerThan900 ? '3.75rem' : '0'}
          w={isLargerThan900 ? '800px' : 'full'}
          h={isLargerThan900 ? '460px' : 'full'}
        >
          {isLoading && <LoadingPadrao />}
          <ModalBody
            pl={isReprocessandoPendencia ? '0' : '24px'}
            pr={isReprocessandoPendencia ? '0' : '24px'}
            pb="20px"
            pt="0"
            h="100%"
            overflowY="auto"
            overflow={animate ? 'hidden' : 'auto'}
          >
            <Box
              w="full"
              h="full"
              overflowX={animate ? 'hidden' : 'auto'}
              animation={animate ? animation : 'none'}
            >
              {isReprocessandoPendencia ? (
                <ReprocessandoPendencia
                  finalizarPendenciaVenda={finalizarPendenciaVenda}
                />
              ) : (
                <>
                  <CardHeader>
                    <Text
                      color="primary.50"
                      fontWeight="semibold"
                      fontSize="16px"
                    >
                      {numeroVenda > 0
                        ? `Venda ${numeroVenda} com pendência`
                        : 'Venda pendente'}
                    </Text>
                    <Text color="gray.700" fontSize="12px">
                      Clique no ícone de ações para solucionar cada pendência.
                      Para validar as correções clique em “confirmar”.
                    </Text>
                  </CardHeader>
                  {listPendencia
                    .filter((item) => item.clienteFornecedorId === null)
                    .map((pendencia) => (
                      <PendenciaItem
                        itemPendencia={pendencia}
                        optionsPendences={optionsPendences}
                        trayId={trayId}
                        key={pendencia.id}
                      />
                    ))}
                </>
              )}
            </Box>
          </ModalBody>
          <ModalFooter
            pt={isReprocessandoPendencia ? '0px' : undefined}
            flexDirection="column"
            overflow="hidden"
          >
            <Box
              overflowX={animate ? 'hidden' : 'auto'}
              animation={animate ? animation : 'none'}
              w="full"
            >
              {!isReprocessandoPendencia && <Divider mb="24px" />}
              <Flex w="full" justifyContent="center" alignItems="baseline">
                {isReprocessandoPendencia ? (
                  <Button
                    variant="outlineDefault"
                    colorScheme="gray"
                    mr="24px"
                    onClick={() => {
                      onClose();
                    }}
                    w="120px"
                  >
                    {isReprocessandoPendencia ? 'Cancelar' : 'Fechar'}
                  </Button>
                ) : (
                  <>
                    <Button
                      variant="cancelOutline"
                      mr="24px"
                      color="red.300"
                      onClick={() => {
                        handleCancelarVenda();
                        onClose();
                      }}
                      w="140px"
                    >
                      Cancelar venda
                    </Button>
                    <Button
                      variant="outlineDefault"
                      colorScheme="gray"
                      mr="24px"
                      onClick={() => {
                        onClose();
                      }}
                      w="120px"
                    >
                      Fechar
                    </Button>
                    <Button
                      variant="solid"
                      colorScheme="primary.50"
                      width="220px"
                      onClick={() => {
                        handleSubmit();
                      }}
                      isLoading={isLoading}
                    >
                      Confirmar correções
                    </Button>
                  </>
                )}
              </Flex>
            </Box>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
