import { useEffect, useRef, useState } from 'react';
import { Text, Td, Tr, Icon, useDisclosure, Box } from '@chakra-ui/react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useReactToPrint } from 'react-to-print';

import { formatDate } from 'helpers/format/formatStringDate';
import { moneyMask } from 'helpers/format/fieldsMasks';

import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import { ModalCompartilhar } from 'components/Modal/ModalCompartilhar';
import {
  ImpressaoVoucherDeTroca,
  ImpressaoVouhcerRefElement,
} from 'components/Impressao/imprimirVouhcer';

import { InformacoesVoucher } from '../InformacoesVoucher';
import { ListarVendasVoucherProps } from '../validationForms';

export function ListarVendasVoucher({ vouchers }: ListarVendasVoucherProps) {
  const [vendaSelecionada, setVendaSelecionada] = useState(false);
  const { isOpen, onToggle } = useDisclosure();
  const totalVendasVoucher = vouchers.vendasComVale;

  const handleExibirVendas = () => {
    if (totalVendasVoucher?.length !== 0) {
      setVendaSelecionada(!vendaSelecionada);
      onToggle();
    }
  };

  const impressaoVoucherContainerRef = useRef<HTMLDivElement>(null);
  const impressaoVoucherComponentToPrintRef = useRef<ImpressaoVouhcerRefElement>(
    null
  );

  const voucher = useReactToPrint({
    content: () => impressaoVoucherContainerRef.current,
  });

  function imprimirVoucher() {
    ModalCompartilhar({
      items: [
        {
          titulo: 'Imprimir voucher',
          onClickImpressao: async (onClose) => {
            await impressaoVoucherComponentToPrintRef.current?.obterInformacoesImpressao();

            if (voucher) {
              setTimeout(() => {
                voucher();
              }, 100);
            }
            onClose();
          },
        },
      ],
    });
  }

  useEffect(() => {
    if (vendaSelecionada) {
      setVendaSelecionada(false);
      onToggle();
    }
  }, [totalVendasVoucher]);

  return (
    <>
      <Tr
        border="1px solid black"
        borderColor="gray.100"
        borderBottom={vendaSelecionada ? 'none !important' : ''}
        key={vouchers.id}
        cursor="pointer"
      >
        {vouchers.vendasComVale?.length !== 0 ? (
          <Td
            onClick={() => handleExibirVendas()}
            _after={{ border: 'none' }}
            w="145px"
            p="0"
            color={totalVendasVoucher.length !== 0 ? 'purple.400' : ''}
            backgroundColor={totalVendasVoucher.length !== 0 ? 'purple.50' : ''}
            borderTopRadius="0 !important"
            borderBottomRadius="0 !important"
          >
            <Text fontWeight="bold">
              <Icon
                color="purple.400"
                mr="3"
                as={vendaSelecionada ? FiChevronUp : FiChevronDown}
              />
              {formatDate(vouchers.dataDevolucao)}
            </Text>
          </Td>
        ) : (
          <Td
            _after={{ border: 'none' }}
            onClick={() => handleExibirVendas()}
            w="145px"
          >
            <Text fontWeight="bold" ml="25px">
              {formatDate(vouchers.dataDevolucao)}
            </Text>
          </Td>
        )}
        <Td
          _after={{ border: 'none' }}
          onClick={() => handleExibirVendas()}
          color={totalVendasVoucher.length !== 0 ? 'purple.400' : ''}
          backgroundColor={totalVendasVoucher.length !== 0 ? 'purple.50' : ''}
        >
          {vouchers.nomeCliente}
        </Td>
        <Td
          onClick={() => handleExibirVendas()}
          _after={{ border: 'none' }}
          textColor="primary.500"
          fontWeight="bold"
          w="128px"
          textAlign="center"
          color={totalVendasVoucher.length !== 0 ? 'purple.400' : ''}
          backgroundColor={totalVendasVoucher.length !== 0 ? 'purple.50' : ''}
        >
          {vouchers.numeroDevolucao}
        </Td>
        <Td
          onClick={() => handleExibirVendas()}
          _after={{ border: 'none' }}
          w="108px"
          textAlign="center"
          color={totalVendasVoucher.length !== 0 ? 'purple.400' : ''}
          backgroundColor={totalVendasVoucher.length !== 0 ? 'purple.50' : ''}
        >
          {vouchers.identificador}
        </Td>
        <Td
          onClick={() => handleExibirVendas()}
          _after={{ border: 'none' }}
          fontWeight="bold"
          isNumeric
          color={totalVendasVoucher.length !== 0 ? 'purple.400' : ''}
          backgroundColor={totalVendasVoucher.length !== 0 ? 'purple.50' : ''}
        >
          {moneyMask(vouchers.valorOriginal, true)}
        </Td>
        <Td
          onClick={() => handleExibirVendas()}
          _after={{ border: 'none' }}
          fontWeight="bold"
          isNumeric
          color={vouchers.saldo <= 0 ? 'red.500' : 'teal.500'}
          backgroundColor={totalVendasVoucher.length !== 0 ? 'purple.50' : ''}
        >
          {moneyMask(vouchers.saldo, true)}
        </Td>
        <Td
          _after={{ border: 'none' }}
          color={totalVendasVoucher.length !== 0 ? 'purple.400' : ''}
          backgroundColor={totalVendasVoucher.length !== 0 ? 'purple.50' : ''}
          borderTopRadius="0 !important"
          borderBottomRadius="0 !important"
        >
          {vouchers.saldo > 0 && (
            <ActionsMenu
              items={[
                {
                  content: 'Imprimir',
                  onClick: () => {
                    imprimirVoucher();
                  },
                },
              ]}
            />
          )}
        </Td>
      </Tr>
      <Box display="none">
        <ImpressaoVoucherDeTroca
          containerRef={impressaoVoucherContainerRef}
          ref={impressaoVoucherComponentToPrintRef}
          voucherId={vouchers.operacaoId}
        />
      </Box>

      {vendaSelecionada && totalVendasVoucher.length > 0 && (
        <InformacoesVoucher vouchers={vouchers} isOpen={isOpen} />
      )}
    </>
  );
}
