import React from 'react';
import { Text, VStack, useMediaQuery, Button, Flex } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import ConstanteRotas from 'constants/rotas';

import { ConfirmarIcon } from 'icons';

import { Header } from '../Layout/Header';
import { ContainerIntegracaoFixo } from '../Layout/Container';

export const CredenciamentoPendente = () => {
  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

  const history = useHistory();

  const handleFechar = () => {
    history.push(ConstanteRotas.LOJA_APLICATIVOS);
  };

  const handleIrParaPainelZoop = () => {
    history.push(ConstanteRotas.INTEGRACAO_ZOOP_GERAL);
  };

  return (
    <>
      <ContainerIntegracaoFixo height={isLargerThan700 ? '100vh' : '100%'}>
        <Header title="Credenciamento solicitado!" icon={ConfirmarIcon} />
        <VStack
          pl={['10px', '10px', '20px']}
          pr={['10px', '10px', '20px']}
          lineHeight="1.8"
          w="full"
          color="primary.50"
          fontSize="14px"
          spacing={['10px', '10px', '24px']}
        >
          <Text textAlign="left" letterSpacing="0px">
            Seu credenciamento foi enviado com sucesso! Agora é só aguardar a
            análise e aprovação. Assim que finalizado, você poderá acessar todas
            as funcionalidades da Zoop Recebimentos.
          </Text>
        </VStack>
        <Flex justifyContent="center" alignItems="center" mt="112px" gap="24px">
          <Button
            cursor="pointer"
            colorScheme="gray"
            onClick={handleFechar}
            variant="solid"
          >
            Finalizar
          </Button>
          <Button
            cursor="pointer"
            colorScheme="purple"
            onClick={handleIrParaPainelZoop}
            variant="solid"
          >
            Ir para painel Zoop
          </Button>
        </Flex>
      </ContainerIntegracaoFixo>
    </>
  );
};
