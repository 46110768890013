import React, { createContext, useContext } from 'react';
import {
  FullScreen,
  FullScreenHandle,
  useFullScreenHandle,
} from 'react-full-screen';

import { Container as ContainerModal } from 'react-modal-promise';

interface FullScreenContextProps {
  handleFullScreen: FullScreenHandle;
}

const FullScreenContext = createContext<FullScreenContextProps>(
  {} as FullScreenContextProps
);

interface FullScreenProviderProps {
  children: React.ReactNode;
}

export default function FullScreenProvider({
  children,
}: FullScreenProviderProps): JSX.Element {
  const handleFullScreen = useFullScreenHandle();

  return (
    <FullScreenContext.Provider value={{ handleFullScreen }}>
      <FullScreen
        className="fullscreen-container-div"
        handle={handleFullScreen}
      >
        {children}
        <ContainerModal />
      </FullScreen>
    </FullScreenContext.Provider>
  );
}

export function useFullScreenContext(): FullScreenContextProps {
  const context = useContext(FullScreenContext);

  if (!context)
    throw new Error(
      'useFullScreenContext must be used within a FullScreenProvider.'
    );

  return context;
}
