import { ContainerListagem } from 'components/Layout/Listagem/containerListagem';

import { Formulario } from '..';
import { usePromocaoAlterar } from '../Alterar/hooks';

export const Visualizar = () => {
  const {
    isLoading,
    dataHoraCadastro,
    dataHoraUltimaAlteracao,
  } = usePromocaoAlterar();

  return (
    <ContainerListagem
      isForm={false}
      dataHoraCadastro={dataHoraCadastro}
      dataHoraUltimaAlteracao={dataHoraUltimaAlteracao}
      isLoading={isLoading}
      maxWidth="100%"
    >
      <Formulario isReadOnly />
    </ContainerListagem>
  );
};
