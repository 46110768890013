import React, { useMemo, useState, useCallback, useRef } from 'react';
import { Icon, Flex } from '@chakra-ui/react';
import { Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SalvarInserirNovoIcon } from 'icons';

import auth from 'modules/auth';
import api, { ResponseApi } from 'services/api';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';

import ConstanteFuncionalidades from 'constants/permissoes';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import ManterFoco from 'components/Geral/ManterFoco';
import ValidarPermissao from 'components/Validacao/ValidarPermissao';
import { HeaderData } from 'components/Grid/Cabecalho';
import ButtonComOpcoes from 'components/Button/ButtonComOpcoes';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { ModalErro } from 'components/Modal/ModalErro';
import Paginacao, {
  ForwardRefMethod,
  GridPaginadaConsulta,
  GridPaginadaRetorno,
} from 'components/Grid/Paginacao';

import {
  AlignRight,
  ContentContainer,
  LinkVisualizar,
  FiltrosCol,
} from 'styles';
import { toast } from 'react-toastify';

import { SearchInput } from 'components/update/Input/SearchInput';
import { ButtonFuncionalidade } from 'components/update/Button/ButtonFuncionalidade';

interface LojaFiltros {
  [x: string]: any;
}

interface Loja {
  id: string;
  fantasia: string;
  razaoSocial: string;
  localidade: string;
}

const Listar = () => {
  const history = useHistory();
  const isMountedRef = useIsMountedRef();
  const { t } = useTranslation();
  const filtrosAtuais = useRef<LojaFiltros>();
  const formMethods = useForm({
    defaultValues: filtrosAtuais.current,
  });

  const { getValues, setFocus } = formMethods;

  const lojaLogadaId = auth.getLoja().id;
  const [isLoading, setIsLoading] = useState(false);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [lojas, setLojas] = useState([] as Array<Loja>);

  const pageIsLoaded = useRef(false);
  const childRef = useRef<ForwardRefMethod>();

  const permissaoLojaAlterar = useMemo(
    () => auth.possuiPermissao(ConstanteFuncionalidades.LOJA_ALTERAR),
    []
  );

  const handleHistoryPush = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history]
  );

  const handleReset = useCallback(
    (orderColumn: string) => {
      const filtros = getValues();

      if (JSON.stringify(filtros) !== JSON.stringify(filtrosAtuais.current)) {
        return childRef.current?.reset(orderColumn);
      }

      return () => {};
    },
    [getValues]
  );

  const handleRefresh = useCallback((newTotal?: number) => {
    return childRef.current?.refresh(newTotal);
  }, []);

  const paginationHandle = useCallback(
    async (gridPaginadaConsulta: GridPaginadaConsulta) => {
      setIsLoading(true);

      const filtros = getValues();

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<Loja>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.LOJA_LISTAR_PAGINADO,
          gridPaginadaConsulta
        ),
        { params: { fantasia: filtros.fantasia } }
      );

      if (response?.sucesso && isMountedRef.current) {
        setTotalRegistros(response.dados.total);
        setLojas(response.dados.registros);

        filtrosAtuais.current = filtros;
      }

      if (isMountedRef.current) {
        setIsLoading(false);

        if (!pageIsLoaded.current) {
          pageIsLoaded.current = true;

          setFocus('fantasia');
        }
      }
    },
    [getValues, isMountedRef, setFocus]
  );

  const excluirHandle = useCallback(
    async (lojaId: string) => {
      ModalConfirmacaoExcluir({
        callback: async (ok: boolean) => {
          if (ok) {
            setIsLoading(true);

            const response = await api.delete<void, ResponseApi>(
              ConstanteEnderecoWebservice.LOJA_EXCLUIR,
              {
                params: { id: lojaId },
              }
            );

            if (response?.sucesso) {
              toast.success(t('O cadastro foi removido com sucesso.'));

              handleRefresh(totalRegistros - 1);
            } else {
              ModalErro({
                textoTitulo: 'Não foi possivel remover a loja',
                textoMensagem:
                  'O registro está sendo usado e não pode ser excluído.',
              });
            }

            setIsLoading(false);
          }
        },
      });
    },
    [handleRefresh, t, totalRegistros]
  );

  const headerData: HeaderData = useMemo(() => {
    return {
      orderColumn: 'fantasia',
      orderDirection: 'asc',
      columns: [
        {
          displayName: t('Fantasia'),
          name: 'fantasia',
          isOrderable: true,
          width: 'auto',
        },
        {
          displayName: t('Razão social'),
          name: 'razao',
          isOrderable: false,
          width: 'auto',
        },
        {
          displayName: t('Localidade'),
          name: 'localidade',
          isOrderable: false,
          width: '150px',
        },
        {
          displayName: t('Ações'),
          name: 'Acoes',
          isOrderable: false,
          width: '38px',
        },
      ],
    };
  }, [t]);

  const LojasList = useMemo(() => {
    return (
      <Paginacao
        ref={childRef}
        total={totalRegistros}
        paginationHandle={paginationHandle}
        isLoading={isLoading}
        headerData={headerData}
      >
        {lojas.map((loja) => {
          let dropDownItens = [
            {
              title: t('Editar'),
              onClick: () => {
                handleHistoryPush(
                  SubstituirParametroRota(
                    ConstanteRotas.LOJA_ALTERAR,
                    'id',
                    loja.id
                  )
                );
              },
              funcionalidadePermissao: ConstanteFuncionalidades.LOJA_ALTERAR,
            },
            //
          ];

          if (loja.id !== lojaLogadaId) {
            dropDownItens = [
              ...dropDownItens,
              {
                title: t('Remover'),
                onClick: () => {
                  excluirHandle(loja.id);
                },
                funcionalidadePermissao: ConstanteFuncionalidades.LOJA_EXCLUIR,
              },
            ];
          }

          return (
            <tr key={loja.id}>
              <td>
                <ValidarPermissao
                  funcionalidade={ConstanteFuncionalidades.LOJA_VISUALIZAR}
                  tooltipWrap="hidden"
                >
                  {({ permitido }) => (
                    <LinkVisualizar
                      id="link-visualizar"
                      bloqueado={permitido ? 0 : 1}
                      to={
                        permitido
                          ? SubstituirParametroRota(
                              permissaoLojaAlterar.permitido
                                ? ConstanteRotas.LOJA_ALTERAR
                                : ConstanteRotas.LOJA_VISUALIZAR,
                              'id',
                              loja.id
                            )
                          : ''
                      }
                    >
                      {loja.fantasia}
                    </LinkVisualizar>
                  )}
                </ValidarPermissao>
              </td>
              <td>{loja.razaoSocial}</td>
              <td>{loja.localidade}</td>
              <td>
                <ButtonComOpcoes
                  id="mostrarMais"
                  dropdownItems={dropDownItens}
                />
              </td>
            </tr>
          );
        })}
      </Paginacao>
    );
  }, [
    totalRegistros,
    paginationHandle,
    isLoading,
    headerData,
    lojas,
    t,
    lojaLogadaId,
    handleHistoryPush,
    excluirHandle,
    permissaoLojaAlterar.permitido,
  ]);

  return (
    <ContentContainer>
      <FormProvider {...formMethods}>
        <ManterFoco>
          <Row>
            <FiltrosCol xs={12} sm={5} lg={6}>
              <SearchInput
                type="search"
                placeholder={t('Buscar loja por nome fantasia')}
                maxLength={40}
                onEnterKeyPress={() => handleReset('fantasia')}
                isDisabled={isLoading}
                id="fantasia"
                name="fantasia"
              />
            </FiltrosCol>
            <AlignRight as={FiltrosCol} sm={0}>
              <Flex
                width="100%"
                flexDirection={['column', 'row', 'row']}
                justifyContent="flex-end"
              >
                <ButtonFuncionalidade
                  id="cadastrarNovo"
                  name="cadastrarNovo"
                  colorScheme="secondary"
                  color="gray.700"
                  borderRadius="md"
                  minW={{ base: 'full', md: '250px' }}
                  leftIcon={<Icon as={SalvarInserirNovoIcon} />}
                  type="button"
                  onClick={() =>
                    handleHistoryPush(ConstanteRotas.LOJA_CADASTRAR)
                  }
                  funcionalidade={ConstanteFuncionalidades.LOJA_CADASTRAR}
                >
                  {t('Cadastrar Novo')}
                </ButtonFuncionalidade>
              </Flex>
            </AlignRight>
          </Row>
        </ManterFoco>
        {LojasList}
      </FormProvider>
    </ContentContainer>
  );
};

export default Listar;
