import React from 'react';
import { Text, Flex, Container, LayoutProps } from '@chakra-ui/react';

import { moneyMask } from 'helpers/format/fieldsMasks';

import { Cabecalho } from './Divisoes/cabecalho';
import { Informacoes } from './Divisoes/informacoes';

interface ImpressaoSangriaSuprimento80mm {
  maxWidth?: LayoutProps['maxW'];
  containerRef?: React.RefObject<HTMLDivElement>;
  conteudoImpressao?: ConteudoImpressao;
}

interface ConteudoImpressao {
  loja: string;
  forma: string;
  caixa: string;
  destinoOuOrigem: string;
  usuario: string;
  observacao: string;
  valor: number;
  tipoOperacao: number;
  dataCadastro: Date;
}

const ImpressaoSangriaSuprimento80mm = ({
  maxWidth,
  containerRef,
  conteudoImpressao,
}: ImpressaoSangriaSuprimento80mm) => {
  if (conteudoImpressao === undefined) return null;

  return (
    <Container
      sx={{
        '& div,': {
          color: 'black',
          fontWeight: '900',
          fontSize: '10px',
          fontFamily: 'arial',
        },
        '& div, & hr': {
          opacity: 'unset',
          borderColor: 'black',
        },
      }}
      minW="100%"
      ref={containerRef}
    >
      <Flex minW="58mm" maxW={maxWidth} direction="column" h="auto" pt="3">
        <Cabecalho
          nomeFantasia={conteudoImpressao.loja}
          tipoOperacao={conteudoImpressao.tipoOperacao}
        />
        <Informacoes
          forma={conteudoImpressao.forma}
          caixa={conteudoImpressao.caixa}
          dataCadastro={conteudoImpressao.dataCadastro}
          observacao={conteudoImpressao.observacao}
          usuario={conteudoImpressao.usuario}
          destinoOuOrigem={conteudoImpressao.destinoOuOrigem}
          tipoOperacao={conteudoImpressao.tipoOperacao}
          key={conteudoImpressao.usuario}
        />
        <Flex mt="1" pt={2} pb={2} alignItems="center" justifyContent="center">
          <Text fontSize="sm">
            VALOR: {moneyMask(conteudoImpressao.valor, true)}
          </Text>
        </Flex>
      </Flex>
    </Container>
  );
};

export default ImpressaoSangriaSuprimento80mm;
