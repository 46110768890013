import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import OptionType from 'types/optionType';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';

import { MenuList } from './components/MenuList';
import {
  SelectVirtualizedProps,
  valuePaginationDefault,
} from './validationForms';

let inputTypingTimeout: NodeJS.Timeout;

export type OptionProps = OptionType & {
  isTooltip: boolean;
  labelTooltip: string;
};

export const SelectVirtualized = ({
  components,
  totalRegistros = 0,
  autoFocus,
  handleGetOptions,
  placeholder,
  isClearable,
  ...rest
}: SelectVirtualizedProps) => {
  const [valueInput, setValueInput] = useState('');
  const [listOptions, setOptions] = useState<OptionProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const currentPage = useRef(0);
  const listOptionsRef = useRef<OptionProps[]>([]);
  const isLoadingRef = useRef(false);

  const missingOptionsLoad = listOptions.length < totalRegistros;

  const getValueInput = (inputValue: string) => {
    setValueInput(inputValue);
  };

  const clearList = useCallback(() => {
    currentPage.current = 1;
    setOptions([]);
  }, []);

  const loadOptions = useCallback(
    async (inputValue: string) => {
      setIsLoading(true);
      isLoadingRef.current = true;

      const promise = await new Promise(() => {
        clearTimeout(inputTypingTimeout);

        currentPage.current += 1;

        inputTypingTimeout = global.setTimeout(async () => {
          const options = await handleGetOptions(inputValue, {
            ...valuePaginationDefault,
            currentPage: currentPage.current,
            pageSize: valuePaginationDefault.pageSize,
          });

          const newListOption = options.map((itemOption) => {
            const currentLabel = itemOption?.label;

            const amountCaracteresLabel = currentLabel?.length;

            const maxCaracteres = 76;

            const exceededMaxCharacters = amountCaracteresLabel > maxCaracteres;

            const slicedLabel = currentLabel?.slice(0, maxCaracteres);

            const valueLabelFormating =
              slicedLabel.charAt(0).toUpperCase() +
              slicedLabel.slice(1).toLowerCase();

            return {
              ...itemOption,
              label: exceededMaxCharacters ? valueLabelFormating : currentLabel,
              labelTooltip: currentLabel,
              value: itemOption.value,
              isTooltip: exceededMaxCharacters,
            };
          });

          setOptions((prev) => [...prev, ...newListOption]);
          isLoadingRef.current = false;
          setIsLoading(false);
        }, 500);
      });

      return promise;
    },
    [handleGetOptions]
  );

  const updateOptionsListSelect = useCallback(
    async (inputValue: string) => {
      loadOptions(inputValue);
    },
    [loadOptions]
  );

  const componentsSelect = useMemo(() => {
    return {
      ...components,
      MenuList: ({ children }: any) =>
        MenuList({
          isLoading: isLoadingRef.current,
          children,
          listOptions: listOptionsRef.current,
          totalRegistros,
          inputValue: valueInput,
          updateOptionsListSelect,
        }),
    };
  }, [components, totalRegistros, updateOptionsListSelect, valueInput]);

  useEffect(() => {
    const getOptions = () => {
      if (valueInput?.length > 0) {
        updateOptionsListSelect(valueInput);

        clearList();
      }
    };
    getOptions();
  }, [clearList, updateOptionsListSelect, valueInput]);

  useEffect(() => {
    listOptionsRef.current = listOptions;
  }, [listOptions]);

  return (
    <SelectPadrao
      onBlur={clearList}
      autoFocus={autoFocus}
      isClearable={isClearable}
      isLoading={isLoading && missingOptionsLoad}
      placeholder={placeholder}
      onInputChange={getValueInput}
      filterOption={() => true}
      components={componentsSelect}
      options={listOptions}
      {...rest}
    />
  );
};
