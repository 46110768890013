import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import isPrenvent from 'helpers/layout/isPrenvent';
import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import useReloadRegistration from 'helpers/layout/useReloadRegistration';

import { ContainerListagem } from 'components/Layout/Listagem/containerListagem';

import { useForm, yupResolver } from '../validationForm';
import UncontrolledForm from '..';

const Cadastrar = () => {
  const history = useHistory();
  const isMountedRef = useIsMountedRef();

  const [isLoading, setIsLoading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);

  const defaultValues = {
    nome: '',
    ativo: true,
  };

  const formMethods = useForm({
    resolver: yupResolver,
    defaultValues,
  });

  const { handleSubmit, getValues, formState, setFocus } = formMethods;

  const historyReload = useReloadRegistration({ setFormIsDirty });
  isPrenvent(formIsDirty);

  async function handlePostMarca() {
    const data = getValues();

    const response = await api.post<void, ResponseApi>(
      ConstanteEnderecoWebservice.MARCA_CADASTRAR,
      data
    );

    if (response.sucesso) {
      return true;
    }

    if (response.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    return false;
  }

  const onSubmit = handleSubmit(async () => {
    setIsLoading(true);

    const success = await handlePostMarca();

    if (success) {
      toast.success('O cadastro foi realizado com sucesso.');

      setFormIsDirty(false);

      history.push(ConstanteRotas.MARCA);
    }

    setIsLoading(false);
  });

  const onSubmitReset = handleSubmit(async () => {
    setIsLoading(true);

    const success = await handlePostMarca();

    if (success) {
      toast.success('O cadastro foi realizado com sucesso.');
      if (isMountedRef.current) setFocus('nome');

      historyReload(ConstanteRotas.MARCA_CADASTRAR);
    }

    setIsLoading(false);
  });

  useEffect(() => {
    setFormIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  return (
    <ContainerListagem
      formMethods={formMethods}
      isLoading={isLoading}
      onSubmit={onSubmit}
      onSubmitReset={onSubmitReset}
      formIsDirty={formIsDirty}
    >
      <UncontrolledForm />
    </ContainerListagem>
  );
};

export default Cadastrar;
