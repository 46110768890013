import { useEffect, useRef, useState, useCallback } from 'react';
import {
  Button,
  Divider,
  Heading,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalProps,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  Checkbox,
  Box,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { InstanceProps, create } from 'react-modal-promise';
import { useForm, FormProvider } from 'react-hook-form';

import { enumRelatorioProdutosPersonalizado } from 'constants/enum/RelatorioPersonalizado';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';

import Input from 'components/PDV/Input';
import SelectPadraoPdv from 'components/PDV/Select/SelectPadrao';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';

import {
  RelatorioProps,
  RelatorioProperties,
  yupResolver,
  FormData,
} from './validationForm';

type ModalRelatoriosResponse = {
  sucesso: boolean;
};

type CampoOrdenacaoProps = {
  CampoOrdenacao: number;
};

type ObterRelatorioPersonalizado = {
  settings?: {
    Campos?: { Campos: Array<number>; CamposOrdenacao: CampoOrdenacaoProps[] };
    nome: string;
    id: string;
  };
  changeSettings?: boolean;
};

type ModalRelatorioPersonalizadoProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalRelatoriosResponse> &
  ObterRelatorioPersonalizado;

const ModalCadastoRelatorioProdutosPersonalizado = ({
  settings,
  onResolve,
}: ModalRelatorioPersonalizadoProps) => {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const [isLoading, setIsLoading] = useState(false);

  const formMethods = useForm<FormData>({
    defaultValues: {
      nomeRelatorio: settings?.nome ?? '',
      camposOrdenacao:
        settings?.Campos?.CamposOrdenacao?.map((item) => item.CampoOrdenacao) ||
        [],
    },
    resolver: yupResolver,
  });

  const { watch, setValue } = formMethods;

  const camposOrdenacaoWatch = watch('camposOrdenacao');

  const [
    camposRelatorioPersonalizado,
    setCamposRelatoriPersonalizado,
  ] = useState<RelatorioProps>(
    enumRelatorioProdutosPersonalizado as RelatorioProps
  );

  const latestProps = useRef({ camposRelatorioPersonalizado });

  useEffect(() => {
    latestProps.current = { camposRelatorioPersonalizado };
  });

  const existeCamposSelecionado = camposRelatorioPersonalizado.properties.some(
    (campo) => campo.isChecked === true
  );

  const relatorioSelecionado = camposRelatorioPersonalizado?.properties.filter(
    (config) => config.isChecked === true
  );

  const updateValueCampoOrdenacao = useCallback(
    (relatorio: RelatorioProperties[]) => {
      if (!camposOrdenacaoWatch) {
        return;
      }

      const itemCampoOrdenacao = [] as number[];

      camposOrdenacaoWatch.forEach((campoOrdenacaoItem) => {
        const categoriaSelecionada = relatorio
          .filter((item) => item.isChecked === true)
          .some((relatorioItem) => relatorioItem.value === campoOrdenacaoItem);

        if (categoriaSelecionada) {
          itemCampoOrdenacao.push(campoOrdenacaoItem);
        }
      });

      setValue('camposOrdenacao', itemCampoOrdenacao);
    },
    [camposOrdenacaoWatch, setValue]
  );

  const toggleSelectSettings = (index: number) => {
    const data = camposRelatorioPersonalizado.properties;
    const item = data[index];

    data.splice(index, 1, {
      ...item,
      isChecked: !item.isChecked,
    });

    setCamposRelatoriPersonalizado({
      properties: data,
    });

    updateValueCampoOrdenacao(data);
  };

  useEffect(() => {
    function getConfiguracaoRelatorio() {
      const getConfig: RelatorioProperties[] = [];
      if (settings?.Campos) {
        latestProps.current.camposRelatorioPersonalizado.properties.forEach(
          (item) => {
            let i = 0;
            while (i < Number(settings.Campos?.Campos.length)) {
              if (item.value === settings.Campos?.Campos[i]) {
                return getConfig.push({
                  ...item,
                  isChecked: !item.isChecked,
                });
              }
              // eslint-disable-next-line no-plusplus
              i++;
            }
            return getConfig.push(item);
          }
        );

        setCamposRelatoriPersonalizado({
          properties: getConfig,
        });
      } else {
        setIsLoading(true);
        latestProps.current.camposRelatorioPersonalizado.properties.forEach(
          (item) => {
            return getConfig.push(item);
          }
        );

        setCamposRelatoriPersonalizado({
          properties: getConfig,
        });

        setIsLoading(false);
      }
    }
    getConfiguracaoRelatorio();
  }, [settings]);

  const handleSubmit = formMethods.handleSubmit(
    async ({ nomeRelatorio, camposOrdenacao }) => {
      setIsLoading(true);
      const configRelatorioPerson = camposRelatorioPersonalizado?.properties.filter(
        (config) => config.isChecked === true
      );

      const getOptionsCheck = configRelatorioPerson?.map((item) => item.value);

      const currentConfig = {
        nome: nomeRelatorio,
        configuracoes: {
          campos: getOptionsCheck,
          camposOrdenacao: (camposOrdenacao || []).map(
            (itemOrdenacao, index) => ({
              campoOrdenacao: itemOrdenacao,
              sequenciaOrdenacao: index,
            })
          ),
        },
        ativo: true,
      };

      const changeSettings = {
        nome: nomeRelatorio,
        configuracoes: {
          campos: getOptionsCheck,
          camposOrdenacao: (camposOrdenacao || []).map(
            (itemOrdenacao, index) => ({
              campoOrdenacao: itemOrdenacao,
              sequenciaOrdenacao: index,
            })
          ),
        },
        ativo: true,
      };

      let response;

      if (!settings) {
        response = await api.post<
          void,
          ResponseApi<ObterRelatorioPersonalizado>
        >(
          ConstanteEnderecoWebservice.RELATORIOS_PERSONALIZADOS_CADASTRAR,
          currentConfig
        );
      } else {
        response = await api.put<
          void,
          ResponseApi<ObterRelatorioPersonalizado>
        >(
          ConstanteEnderecoWebservice.REALTORIO_PERSONALIZADOS_ALTERAR,
          changeSettings,
          {
            params: { idImpressao: settings?.id },
          }
        );
      }

      if (response.avisos) {
        response.avisos.forEach((avisos) => toast.warning(avisos));
      }

      onResolve({ sucesso: response.sucesso });

      if (response.sucesso) {
        const aviso = !settings
          ? 'Relatório cadastrado com sucesso!'
          : 'Relatório alterado com sucesso!';
        toast.success(aviso);
        onClose();
      }
      setIsLoading(false);
    }
  );

  return (
    <ModalPadraoChakra
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="3xl"
      scrollBehavior="inside"
    >
      <ModalContent bg="gray.50" h="500px" marginX="4">
        {isLoading && <LoadingPadrao />}
        <ModalHeader pb="0" pt="6">
          <Heading as="h1" fontSize="md" color="primary.50">
            {settings ? 'Editar relatório' : 'Cadastrar relatório'}
          </Heading>
          <Heading as="h3" fontSize="xs" my="2" color="gray.700">
            {settings
              ? 'Aqui você pode alterar os campos que serão exibidos no relatório '
              : 'Escolha os campos que deseja para que seja criado o relatório'}
          </Heading>
          <Divider my="3" />
        </ModalHeader>
        <ModalCloseButton />
        <FormProvider {...formMethods}>
          <ModalBody pb="4" pt="0">
            <Box>
              <Box marginTop="2" marginBottom="5" px="1px">
                <Input
                  minW="261px"
                  id="nomeRelatorio"
                  name="nomeRelatorio"
                  label="Nome"
                  placeholder="Digite o nome do relatório"
                />
              </Box>
              <Box>
                <SelectPadraoPdv
                  name="camposOrdenacao"
                  label="Campo de ordenação padrão"
                  id="camposOrdenacao"
                  placeholder="Digite os campos"
                  variant="outline"
                  options={relatorioSelecionado.filter(
                    (relatorioProdutoItem) =>
                      relatorioProdutoItem?.value !==
                      enumRelatorioProdutosPersonalizado.FOTO
                  )}
                  isMulti
                  isSearchable={false}
                />
              </Box>
              <Box
                mt="20px"
                borderWidth="1px"
                borderStyle="solid"
                borderColor="gray.100"
                rounded="md"
              >
                <Table variant="filled">
                  <Thead>
                    <Tr>
                      <Th>Descrição</Th>
                      <Th isNumeric>Ativo?</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {camposRelatorioPersonalizado.properties.map(
                      (item, index) => (
                        <Tr key={item.id}>
                          <Td>{item.label}</Td>
                          <Td isNumeric>
                            <Checkbox
                              position="relative"
                              colorScheme="primary"
                              top="1"
                              id={item.name}
                              name={item.name}
                              onChange={() => toggleSelectSettings(index)}
                              isChecked={item.isChecked}
                            />
                          </Td>
                        </Tr>
                      )
                    )}
                  </Tbody>
                </Table>
              </Box>
            </Box>
          </ModalBody>
        </FormProvider>
        <ModalFooter my="4">
          <Stack
            justifyContent="right"
            alignItems="center"
            w="full"
            spacing={5}
            direction={{ base: 'column', md: 'row', lg: 'row' }}
          >
            <Button
              onClick={onClose}
              variant="outlineDefault"
              colorScheme="gray"
              w={{ base: 'full', md: '140px', lg: '110px' }}
            >
              Cancelar
            </Button>
            <Button
              onClick={handleSubmit}
              variant="solid"
              colorScheme="aquamarine"
              isDisabled={!existeCamposSelecionado}
              w={{ base: 'full', md: '150px', lg: '150px' }}
            >
              {settings?.Campos ? 'Editar relatório' : 'Cadastrar'}
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export const ModalRelatoriosProdutosPersonalizados = create<ModalRelatorioPersonalizadoProps>(
  ModalCadastoRelatorioProdutosPersonalizado
);
