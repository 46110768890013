import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import auth from 'modules/auth';
import { setDateMinHours, setDateMaxHours } from 'helpers/data/setHoursDate';
import { IdentificacaoIntegracao } from 'constants/enum/IdentificacaoIntegracao';
import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';
import PlanoContratacaoEnum from 'constants/enum/planoContratacao';
import ConstanteFuncionalidades from 'constants/permissoes';

export type ClienteId = {
  label: string | null;
  value: string | null;
  endereco: string | null;
};

export type FormData = {
  clienteId: ClienteId | null;
  status: number | null;
  numeroOperacao?: number | string;
  numeroPedidoPlataforma?: number | string;
  filtrarPeloCaixaAtual?: boolean;
  dataEmissaoInicio: Date;
  dataEmissaoFim: Date;
  possuiDevolucao: boolean;
  identificacaoTipoOperacao: number[] | [];
  origem?: number | null;
  contasFinanceirasId?: string[];
  isTodosCaixasSelecionados?: boolean;
};

export interface DocumentosFiscalProps {
  id: string;
  modeloFiscal: number;
  numeroFiscal: string;
  status: number;
}

export interface OperacaoProps {
  cliente: string;
  fantasia: string;
  modeloNumeroFiscais: Array<DocumentosFiscalProps>;
  id: string;
  identificacaoTipoOperacao: number;
  numeroOperacao: number;
  dataEmissao: string;
  origemEnum: number;
  status: number;
  valorTotal: number;
  possuiDevolucao: boolean;
  operacaoOrigemDevolucao: null;
  origem: string | null;
  contasFinanceirasId?: string[];
}

const planoAtual = auth.getPlano();

export const temPlanoStart = PlanoContratacaoEnum.START === planoAtual;

export const possuiPermissaoListarDevolucoes = auth.possuiPermissao(
  ConstanteFuncionalidades.LISTAR_TROCA_DEVOLUCAO
).permitido;
export const possuiPermissaoListarVendasPedidos = auth.possuiPermissao(
  ConstanteFuncionalidades.VENDASPEDIDOS_LISTAR
).permitido;
export const possuiPermissaoListarConsignacao = auth.possuiPermissao(
  ConstanteFuncionalidades.CONSIGNACAO_LISTAR
).permitido;
export const possuiPermissaoListarOrcamento = auth.possuiPermissao(
  ConstanteFuncionalidades.ORCAMENTO_LISTAR
).permitido;

export const getCamposDefaultIdentificacaoTipoOperacao = () => {
  const valoresVendasPedidos = [IdentificacaoTipoOperacaoEnum.VENDA];

  if (
    possuiPermissaoListarDevolucoes &&
    possuiPermissaoListarVendasPedidos &&
    !temPlanoStart
  ) {
    return [...valoresVendasPedidos, IdentificacaoTipoOperacaoEnum.DEVOLUCAO];
  }
  if (possuiPermissaoListarVendasPedidos) {
    return valoresVendasPedidos;
  }
  if (possuiPermissaoListarDevolucoes && !temPlanoStart) {
    return [IdentificacaoTipoOperacaoEnum.DEVOLUCAO];
  }

  return [];
};

export const defaultValues = {
  clienteId: null,
  status: null,
  dataEmissaoInicio: setDateMinHours(new Date()),
  dataEmissaoFim: setDateMaxHours(new Date()),
  filtrarPeloCaixaAtual: true,
  possuiDevolucao: false,
  identificacaoTipoOperacao: getCamposDefaultIdentificacaoTipoOperacao(),
  origem: IdentificacaoIntegracao.TODAS,
  isTodosCaixasSelecionados: false,
  numeroOperacao: '',
  numeroVendaIntegracao: '',
} as FormData;

const schema = yup.object().shape({
  clienteId: yup
    .object()
    .shape({
      label: yup.string().nullable(),
      value: yup.string().nullable(),
      endereco: yup.string().nullable(),
    })
    .nullable(),
  status: yup.number().nullable(),
  numeroOperacao: yup.number(),
  numeroVendaIntegracao: yup.number(),
  filtrarPeloCaixaAtual: yup.boolean(),
  dataEmissaoInicio: yup.date().transform((value) => setDateMinHours(value)),
  dataEmissaoFim: yup.date().transform((value) => setDateMaxHours(value)),
  possuiDevolucao: yup.boolean(),
  identificacaoTipoOperacao: yup.array().of(yup.number()),
  origem: yup.number().nullable(),
  contasFinanceirasId: yup.array().of(yup.string()),
  isTodosCaixasSelecionados: yup.boolean(),
});

export const yupResolver = yupResolverInstance(schema);
