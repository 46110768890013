import React, {
  ForwardRefRenderFunction,
  useImperativeHandle,
  forwardRef,
  useState,
  useCallback,
} from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { enumTypeCarne } from 'constants/enum/enumTypeCarne';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import {
  ImpressaoCarnePromissoriaProps,
  ImpressaoCarnePromissoriaRefElement,
  ImpressaoProps,
} from './types';

import { Carne } from './components/Carne';
import { InfoMulta } from './components/Carne/InfoMulta';
import { Promissoria } from './components/Promissoria';

const ImpressaoCarnePromissoria: ForwardRefRenderFunction<
  ImpressaoCarnePromissoriaRefElement,
  ImpressaoCarnePromissoriaProps
> = ({ operacaoId, componentRef, type }, ref) => {
  const [carneData, setCarneData] = useState<ImpressaoProps>();
  const [isLoading, setIsLoading] = useState(false);

  const printCarne = useReactToPrint({
    content: () => componentRef.current,
  });

  const fetchCarneData = useCallback(async () => {
    const response = await api.get<void, ResponseApi<ImpressaoProps>>(
      ConstanteEnderecoWebservice.PEDIDOORCAMENTOVENDA_OBTER_PARA_IMPRIMIR_CARNE,
      {
        params: { id: operacaoId },
      }
    );
    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }
      if (response?.sucesso) {
        setCarneData(response.dados);
        return response.dados;
      }
    }
    return undefined;
  }, [operacaoId]);

  const handlePrint = async () => {
    setIsLoading(true);
    const dadosApi = await fetchCarneData();
    setIsLoading(false);
    return dadosApi;
  };

  useImperativeHandle(ref, () => ({
    fetchPrintInformation: handlePrint,
    printParcelas: (idParcelas) => {
      if (carneData) {
        const parcelasSelecionadas = carneData.recebimentos.filter((parcela) =>
          idParcelas.includes(parcela.id)
        );
        setCarneData({
          ...carneData,
          recebimentos: parcelasSelecionadas,
        });
        if (printCarne) printCarne();
      }
    },
  }));

  const calculateTranslation = () => {
    const totalParcelas = (carneData?.recebimentos || [])?.length;
    if (totalParcelas === 1) {
      return {
        translateX: '-42%',
        translateY: '-140%',
      };
    }
    if (totalParcelas === 2) {
      return {
        translateX: '-30%',
        translateY: '-45%',
      };
    }
    return {
      translateX: '-16%',
      translateY: '-15%',
    };
  };

  const parcelasPerPageCarneNormal = 6;
  const totalGroupsCarneNormal = Math.ceil(
    (carneData?.recebimentos || [])?.length / parcelasPerPageCarneNormal
  );

  const parcelasPerPagePromissoria = 3;
  const totalGroupsPromissoria = Math.ceil(
    (carneData?.recebimentos || [])?.length / parcelasPerPagePromissoria
  );

  const renderParcelasCarne = (index: number) => {
    const startIndex = index * parcelasPerPageCarneNormal;
    const parcelaAtual = (carneData?.recebimentos || [])?.slice(
      startIndex,
      startIndex + parcelasPerPageCarneNormal
    );

    return (
      <>
        {carneData &&
          parcelaAtual?.map(
            ({ dataVencimento, parcela, valor: valorParcela }) => (
              <Flex flexDir="column" maxW="540px" minW="540px">
                <Flex>
                  <Carne
                    codigoCliente={carneData?.clienteCodigo}
                    numeroOperacao={carneData?.numeroOperacao}
                    dataEmissao={carneData?.dataEmissao}
                    dataVencimento={dataVencimento}
                    parcela={parcela}
                    valorParcela={valorParcela}
                    valorTotal={carneData?.valorTotal}
                    clienteNome={carneData?.clienteNome}
                    nomeFantasia={carneData?.nomeFantasia}
                    usuarioNome={carneData?.usuarioNome}
                    vendedorNome={carneData?.vendedorNome}
                  />
                  <Carne
                    codigoCliente={carneData?.clienteCodigo}
                    numeroOperacao={carneData?.numeroOperacao}
                    dataEmissao={carneData?.dataEmissao}
                    dataVencimento={dataVencimento}
                    parcela={parcela}
                    valorParcela={valorParcela}
                    valorTotal={carneData?.valorTotal}
                    clienteNome={carneData?.clienteNome}
                    nomeFantasia={carneData?.nomeFantasia}
                    usuarioNome={carneData?.usuarioNome}
                    vendedorNome={carneData?.vendedorNome}
                    showBarcode
                  />
                </Flex>
                <InfoMulta />
              </Flex>
            )
          )}
        {index < totalGroupsCarneNormal - 1 && (
          <Box height="100%" width="585px" />
        )}
      </>
    );
  };

  const renderParcelasPromissoria = (index: number) => {
    const startIndex = index * 3;
    const parcelaAtual = (carneData?.recebimentos || [])?.slice(
      startIndex,
      startIndex + parcelasPerPagePromissoria
    );
    return (
      <>
        {carneData &&
          parcelaAtual?.map(
            ({ dataVencimento, parcela, valor: valorParcela }) => (
              <Promissoria
                clienteEndereco={carneData?.clienteEndereco}
                clienteNome={carneData?.clienteNome}
                cnpjLoja={carneData?.cnpjLoja}
                dataEmissao={carneData?.dataEmissao}
                enderecoLoja={carneData?.enderecoLoja}
                nomeFantasia={carneData?.nomeFantasia}
                numeroOperacao={carneData?.numeroOperacao}
                dataVencimento={dataVencimento}
                parcela={parcela}
                valorParcela={valorParcela}
                clienteCodigo={carneData?.clienteCodigo}
                cidadeLoja={carneData?.cidadeLoja}
                inscricaoEstadualLoja={carneData?.inscriçãoEstadualLoja}
                clienteCpf={carneData?.clienteCPF}
                telefoneLoja={carneData?.telefoneLoja}
              />
            )
          )}
        {index < totalGroupsPromissoria - 1 && (
          <Box height="68px" width="100%" />
        )}
      </>
    );
  };

  return (
    <Box ref={componentRef} minH="100%">
      {isLoading && <LoadingPadrao />}
      <>
        {type === enumTypeCarne.NORMAL ? (
          <Flex
            flexDir="column"
            justifyContent="flex-start"
            flexWrap="wrap-reverse"
            transform={`
            rotate(270deg)
            translateX(${calculateTranslation().translateX})
            translateY(${calculateTranslation().translateY})
            `}
            maxH="750px"
            minW="1000px"
          >
            <>
              {Array.from({ length: totalGroupsCarneNormal }, (_, index) =>
                renderParcelasCarne(index)
              )}
            </>
          </Flex>
        ) : (
          <>
            {Array.from({ length: totalGroupsPromissoria }, (_, index) =>
              renderParcelasPromissoria(index)
            )}
          </>
        )}
      </>
    </Box>
  );
};

export default forwardRef(ImpressaoCarnePromissoria);
