import { useEffect, useState, useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import { NumberInput } from 'components/update/Input/NumberInput';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import Select from 'components/PDV/Select/SelectPadrao';
import { DateInput } from 'components/update/Input/DateInput';

import { Parcelas } from './validationForm';

type Option = { label: string; value: string; padraoSistema: boolean };

type OptionFormaPagamento = {
  label: string;
  value: string;
  contaFinanceiraId?: string;
  parcelas?: Parcelas[];
};

type CaixaAberto = {
  id: string;
  nome: string;
};

type ContaFinanceira = {
  id: string;
  nome: string;
  padraoSistema: boolean;
};

interface BaixarContaFormProps {
  contasFinanceirasOptions: Option[];
  isContasFinanceirasOptionsLoading: boolean;
  isFormaRecebimentoOptionsLoading: boolean;
  isReadOnly?: boolean;
  isContasReceber: boolean;
  getFormaRecebimentoOptions: () => Promise<OptionFormaPagamento[]>;
}

export function BaixarContaForm({
  contasFinanceirasOptions,
  isContasFinanceirasOptionsLoading,
  isFormaRecebimentoOptionsLoading,
  getFormaRecebimentoOptions,
  isReadOnly,
  isContasReceber,
}: BaixarContaFormProps) {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const [formaRecebimentoOptions, setFormaRecebimentoOptions] = useState<
    OptionFormaPagamento[]
  >([]);
  const [caixaAberto, setCaixaAberto] = useState<CaixaAberto>();

  const [
    valorTotal = 0,
    juros = 0,
    multa = 0,
    desconto = 0,
    formaRecebimentoWatch,
  ] = watch(['valorTotal', 'juros', 'multa', 'desconto', 'formaRecebimento']);

  const dataLancamento = useWatch({ name: 'dataLancamento' });

  const parcelas = useCallback(() => {
    const todasParcelas: Parcelas[] = formaRecebimentoWatch?.parcelas;
    return formaRecebimentoWatch?.parcelas ? todasParcelas : [];
  }, [formaRecebimentoWatch])();

  const contaFinanceiraPagamento = useCallback(() => {
    if (formaRecebimentoWatch?.contasFinanceiras === undefined) {
      return [];
    }
    if ((formaRecebimentoWatch?.contasFinanceiras || []).length > 0) {
      return formaRecebimentoWatch?.contasFinanceiras;
    }

    return [];
  }, [formaRecebimentoWatch])();

  const updateValueContaFinanceira = useCallback(() => {
    (contaFinanceiraPagamento || []).forEach(
      (itemContaFinanceira: ContaFinanceira) => {
        if (itemContaFinanceira.padraoSistema) {
          setValue('contaFinanceira', {
            label: itemContaFinanceira.nome,
            value: itemContaFinanceira.id,
          });
        }
      }
    );
  }, [contaFinanceiraPagamento, setValue]);

  const getContaFinanceira = useCallback(() => {
    if (isContasReceber) {
      const valorContaFinanceira = contasFinanceirasOptions.filter(
        (contaFinanceira) =>
          contaFinanceira.value === formaRecebimentoWatch.contaFinanceiraId
      );
      if (
        valorContaFinanceira &&
        valorContaFinanceira[0] &&
        valorContaFinanceira[0].value
      ) {
        setValue('contaFinanceira', valorContaFinanceira[0]);
      }
    } else if (
      formaRecebimentoWatch.contasFinanceiras[0] &&
      formaRecebimentoWatch.contasFinanceiras[0].nome
    ) {
      updateValueContaFinanceira();
    }
  }, [
    contasFinanceirasOptions,
    formaRecebimentoWatch,
    isContasReceber,
    setValue,
    updateValueContaFinanceira,
  ]);

  useEffect(() => {
    async function getFormaRecebimento() {
      const formaRecebimento = await getFormaRecebimentoOptions();

      setFormaRecebimentoOptions(formaRecebimento);
    }
    getFormaRecebimento();
  }, [getFormaRecebimentoOptions]);

  useEffect(() => {
    setValue('valorTotalBaixa', valorTotal + juros + multa - desconto);
  }, [desconto, juros, multa, setValue, valorTotal]);

  useEffect(() => {
    async function obterCaixaAberto() {
      const response = await api.get<void, ResponseApi<CaixaAberto>>(
        ConstanteEnderecoWebservice.OBTER_CAIXA_ABERTO_USUARIO_LOGADO
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((avisos) => toast.warning(avisos));
        }
        if (response.sucesso && response.dados) {
          setCaixaAberto(response.dados);
        }
      }
    }
    obterCaixaAberto();
  }, []);

  useEffect(() => {
    if (formaRecebimentoWatch?.contaFinanceiraId && formaRecebimentoWatch) {
      getContaFinanceira();
    } else if (contaFinanceiraPagamento.length > 0 && formaRecebimentoWatch) {
      getContaFinanceira();
    } else if (caixaAberto) {
      if (caixaAberto.id) {
        const atualizandoValor = () => {
          setValue('contaFinanceira', {
            label: caixaAberto.nome,
            value: caixaAberto.id,
          });
        };

        if (isContasReceber) {
          atualizandoValor();
        } else {
          updateValueContaFinanceira();
        }
      }
    }
  }, [
    caixaAberto,
    contaFinanceiraPagamento,
    contasFinanceirasOptions,
    formaRecebimentoWatch,
    getContaFinanceira,
    isContasReceber,
    setValue,
    updateValueContaFinanceira,
  ]);

  useEffect(() => {
    if (parcelas.length > 0) {
      setValue('qtdParcela', {
        label: parcelas[0].descricao,
        value: parcelas[0].id,
        qtdParcelas: parcelas[0].qtdParcelas,
      });
    } else {
      setValue('qtdParcela', undefined);
    }
  }, [parcelas, setValue]);

  return (
    <SimpleGridForm mt={{ base: 3, sm: 6, md: 8 }}>
      <NumberInput
        name="juros"
        label="Juros"
        leftElement="R$"
        colSpan={{ base: 12, sm: 3 }}
        isDisabled={isReadOnly}
      />

      <NumberInput
        name="multa"
        label="Multa"
        leftElement="R$"
        colSpan={{ base: 12, sm: 3 }}
        isDisabled={isReadOnly}
      />

      <NumberInput
        name="desconto"
        label="Descontos"
        leftElement="R$"
        colSpan={{ base: 12, sm: 3 }}
        isDisabled={isReadOnly}
      />

      <NumberInput
        name="valorTotalBaixa"
        label="Valor total da baixa"
        leftElement="R$"
        colSpan={{ base: 12, sm: 3 }}
        bg="secondary.300"
        isDisabled
        _disabled={{ opacity: 1, bg: 'secondary.300' }}
      />

      <DateInput
        name="dataBaixa"
        label="Data da baixa"
        colSpan={{ base: 12, sm: 3 }}
        minDate={dataLancamento}
        isDisabled={isReadOnly}
      />

      <Select
        id="formaRecebimento"
        name="formaRecebimento"
        label="Forma de recebimento"
        errorText={errors.formaRecebimento?.message}
        required
        isLoading={isFormaRecebimentoOptionsLoading}
        errorPropName="value"
        onSelect={() => {
          setValue('contaFinanceira', null);
        }}
        placeholder="Selecione a forma de recebimento"
        options={formaRecebimentoOptions}
        asControlledByObject
        colSpan={
          formaRecebimentoWatch?.parcelas
            ? { base: 12, sm: 6, lg: 3 }
            : { base: 12, sm: 9, lg: 6 }
        }
        isDisabled={isReadOnly}
      />

      {parcelas.length > 0 && (
        <Select
          id="qtdParcela"
          name="qtdParcela"
          label="Parcelas"
          required
          errorPropName="value"
          placeholder="Selecione a parcela"
          options={parcelas.map((parcela) => ({
            label: parcela.descricao,
            value: parcela.id,
            qtdParcelas: parcela.qtdParcelas,
          }))}
          asControlledByObject
          colSpan={{ base: 12, sm: 3, lg: 3 }}
          isDisabled={isReadOnly}
        />
      )}

      <Select
        id="contaFinanceira"
        name="contaFinanceira"
        label="Conta financeira"
        errorText={errors.contaFinanceira?.message}
        required
        errorPropName="value"
        placeholder="Selecione a conta"
        isLoading={isContasFinanceirasOptionsLoading}
        options={
          isContasReceber
            ? contasFinanceirasOptions
            : contaFinanceiraPagamento.map(
                (contaFinanceira: ContaFinanceira) => ({
                  label: contaFinanceira.nome,
                  value: contaFinanceira.id,
                })
              )
        }
        asControlledByObject
        colSpan={{ base: 12, sm: 12, md: 12, lg: 3 }}
        isDisabled={isReadOnly || isContasReceber}
      />
    </SimpleGridForm>
  );
}
