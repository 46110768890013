import React, { useCallback } from 'react';
import { Text, VStack, useMediaQuery } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import ConstanteRotas from 'constants/rotas';
import { useSistemaAtual } from 'helpers/data/useSistemaAtual';
import { useZoopEtapasContext } from 'store/Zoop/ZoopEtapasContext';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { Header } from '../Layout/Header';
import { ContainerIntegracaoFixo } from '../Layout/Container';
import { Credenciamento } from '../Credenciamento';

export const GuiaIntegracao = () => {
  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');
  const { isLoading } = useZoopEtapasContext();
  const { nomeExibicao } = useSistemaAtual();

  const history = useHistory();

  const handleVoltar = useCallback(() => {
    history.push(ConstanteRotas.LOJA_APLICATIVOS);
  }, [history]);

  return (
    <>
      {isLoading && <LoadingPadrao />}
      <ContainerIntegracaoFixo height={isLargerThan700 ? '100vh' : '100%'}>
        <Header title="Credenciamento" handleVoltar={handleVoltar} />
        <VStack
          pl={['10px', '10px', '20px']}
          pr={['10px', '10px', '20px']}
          lineHeight="1.8"
          color="primary.50"
          fontSize="14px"
          spacing={['10px', '10px', '24px']}
          mb="18px"
        >
          <Text textAlign={['center', 'left', 'left']} letterSpacing="0px">
            Preencha os dados abaixo para solicitar o credenciamento direto com
            a operadora financeira. A aprovação ou não do credenciamento é
            realizada diretamente pela operadora, seguindo seus próprios
            critérios. O Sistema {nomeExibicao} não tem participação nesse
            processo de aprovação mas faremos todo o possível para contribuir
            positivamente.
          </Text>
        </VStack>
        <Credenciamento />
      </ContainerIntegracaoFixo>
    </>
  );
};
