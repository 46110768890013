import { useCallback, memo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Flex, Icon, Tooltip, Text, Tr, Td, Box, Link } from '@chakra-ui/react';
import { useReactToPrint } from 'react-to-print';

import duplicarOperacao from 'helpers/api/Operacao/duplicarOperacao';
import { getName } from 'helpers/enum/getName';
import { moneyMask } from 'helpers/format/fieldsMasks';
import { formatDateHourMinute } from 'helpers/format/formatStringDate';
import api, { ResponseApi } from 'services/api';
import { useSignalRContext } from 'store/SignalR';
import { useOperacoesFiltroContext } from 'store/Operacoes/OperacoesFiltros';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ModelosFiscaisEnum from 'constants/enum/fiscal/modelosFiscais';
import StatusFiscaisEnum from 'constants/enum/fiscal/statusFiscal';
import TipoCertificadoEnum from 'constants/enum/fiscal/tipoCertificado';
import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';
import StatusOperacaoEnum from 'constants/enum/statusOperacao';
import ConstanteFuncionalidades from 'constants/permissoes';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import ConstanteRotasPDV from 'constants/rotasPDV';
import auth from 'modules/auth';
import PlanoContratacaoEnum from 'constants/enum/planoContratacao';
import { IdentificacaoIntegracao } from 'constants/enum/IdentificacaoIntegracao';

import { DevolverVendaOperacao } from 'icons';
import { RejeicaoInterface } from 'components/EmitirNotaFiscal/BoxRejeicao';
import { ModalAtencao } from 'components/Modal/ModalAtencao';
import {
  ActionMenuItem,
  ActionsMenu,
} from 'components/update/Table/ActionsMenu';
import { ModalCompartilhar } from 'components/Modal/ModalCompartilhar';
import { ModalRejeicaoNotaFiscal } from 'components/Modal/ModalRejeicaoNotaFiscal';
import { ModalRelatorioDespacho } from 'components/Modal/ModalRelatorioDespacho';
import ImpressaoDevolucaoDinheiro, {
  ImpressaoDevolucaoDinheiroProps,
} from 'components/Impressao/impressaoDevolucaoDinheiro';
import { CompartilharOperacao } from 'components/Modal/ModalCompartilhar/CompartilharOperacao';

import { OperacaoProps, DocumentosFiscalProps } from '../validationForm';
import { ModalEscolherModeloFiscal } from '../ModalEscolherModeloFiscal';

interface OperacaoItensProps {
  operacao: OperacaoProps;
  podeEmitirNFe: boolean;
  podeEmitirNFCe: boolean;
  index: number;
  handleResetTableOrder: () => Promise<void> | undefined;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  handleModalConfirmacaoCancelarIsOpen: (value: boolean) => void;
  handleHasNotaFiscal: (value: boolean) => void;
  handleOperacaoId: (value: string) => void;
  handleIsVenda: (value: boolean) => void;
}

const OperacoesItens = ({
  operacao,
  podeEmitirNFe,
  podeEmitirNFCe,
  handleHasNotaFiscal,
  handleResetTableOrder,
  setIsLoading,
  handleModalConfirmacaoCancelarIsOpen,
  handleOperacaoId,
  handleIsVenda,
  index,
}: OperacaoItensProps) => {
  const history = useHistory();
  const { hubConnection, joinGroup } = useSignalRContext();
  const { carregarDataContextRef } = useOperacoesFiltroContext();

  const planoAtual = auth.getPlano();

  const isPlanoTeste = PlanoContratacaoEnum.TESTE;

  const possuiNFe = operacao?.modeloNumeroFiscais?.some((documentoFiscal) => {
    return (
      documentoFiscal?.modeloFiscal === ModelosFiscaisEnum.NFe &&
      documentoFiscal?.status === StatusFiscaisEnum.Autorizada
    );
  });

  const possuiNFCe = operacao?.modeloNumeroFiscais?.some((documentoFiscal) => {
    return (
      documentoFiscal?.modeloFiscal === ModelosFiscaisEnum.NFCe &&
      documentoFiscal?.status === StatusFiscaisEnum.Autorizada
    );
  });

  const naoPossuiNfeAndPodeEmitir = !possuiNFe && podeEmitirNFe;
  const naoPossuiNfceAndPodeEmitir = !possuiNFCe && podeEmitirNFCe;

  const handleConsultaProtocoloNotaFiscal = useCallback(
    async (documentoFiscalId: string) => {
      setIsLoading(true);

      const response = await api.post<void, ResponseApi<number | undefined>>(
        `${ConstanteEnderecoWebservice.NOTA_FISCAL_CONSULTAR_PROTOCOLO}/${documentoFiscalId}`
      );
      if (response) {
        if (response.avisos) {
          try {
            const rejeicao = JSON.parse(
              response.avisos[0]
            ) as RejeicaoInterface;

            ModalRejeicaoNotaFiscal(rejeicao);
          } catch {
            toast.warning(response.avisos[0]);
          }
        }

        if (response.sucesso) {
          if (
            response.dados &&
            response.dados === Number(TipoCertificadoEnum.A3)
          ) {
            const timeout = window.setTimeout(
              async () => {
                setIsLoading(false);
                ModalAtencao({
                  title: 'A comunicação com o certificado digital A3 falhou',
                  text:
                    'Verifique se o certificado digital está conectado no computador e se o aplicativo Módulo desktop está sendo executado.',
                });

                handleResetTableOrder();
              },
              1000 * 60 // 1 minuto
            );

            await joinGroup(
              `${documentoFiscalId}_transmitindo-consultar-protocolo`
            );

            hubConnection.off('sucesso-transmissao-a3');
            hubConnection.on('sucesso-transmissao-a3', async () => {
              window.clearTimeout(timeout);

              setIsLoading(false);
              toast.success('A nota fiscal foi autorizada com sucesso.');
              handleResetTableOrder();
            });

            hubConnection.off('rejeicao-transmissao-a3');
            hubConnection.on(
              'rejeicao-transmissao-a3',
              async (message: string) => {
                window.clearTimeout(timeout);

                setIsLoading(false);

                try {
                  const rejeicao = JSON.parse(message) as RejeicaoInterface;

                  ModalRejeicaoNotaFiscal(rejeicao);
                } catch {
                  toast.warning(message);
                }

                handleResetTableOrder();
              }
            );

            return;
          }

          toast.success('A nota fiscal foi autorizada com sucesso.');
        }
      }
      handleResetTableOrder();
    },
    [handleResetTableOrder, hubConnection, joinGroup, setIsLoading]
  );

  const handleDuplicar = async () => {
    setIsLoading(true);
    const retorno = await duplicarOperacao(operacao.id || '');

    if (retorno) {
      history.push(
        SubstituirParametroRota(
          ConstanteRotasPDV.PDV_LANCAMENTO_ID,
          'id?',
          retorno
        )
      );
      return;
    }
    setIsLoading(false);
  };

  const tipoOperacao = useCallback(() => {
    const possuiNfeProcessamento = operacao?.modeloNumeroFiscais?.some(
      (documentoFiscal: DocumentosFiscalProps) =>
        documentoFiscal.modeloFiscal === ModelosFiscaisEnum.NFe &&
        documentoFiscal.status === StatusFiscaisEnum.EmProcessamento
    );
    return {
      devolucao:
        operacao.identificacaoTipoOperacao ===
        IdentificacaoTipoOperacaoEnum.DEVOLUCAO,
      venda:
        operacao.identificacaoTipoOperacao ===
        IdentificacaoTipoOperacaoEnum.VENDA,
      pedido:
        operacao.identificacaoTipoOperacao ===
        IdentificacaoTipoOperacaoEnum.PEDIDO,
      consignacao:
        operacao.identificacaoTipoOperacao ===
        IdentificacaoTipoOperacaoEnum.CONSIGNACAO,
      orcamento:
        operacao.identificacaoTipoOperacao ===
        IdentificacaoTipoOperacaoEnum.ORCAMENTO,
      statusCancelada: operacao.status === StatusOperacaoEnum.CANCELADA,
      statusEfetuada: operacao.status === StatusOperacaoEnum.EFETUADA,
      temNFeEmProcessamento: possuiNfeProcessamento,
    };
  }, [operacao])();
  const {
    devolucao,
    venda,
    consignacao,
    orcamento,
    pedido,
    statusEfetuada,
    statusCancelada,
    temNFeEmProcessamento,
  } = tipoOperacao;

  const permissaoVerDetalhes = () => {
    switch (operacao.identificacaoTipoOperacao) {
      case IdentificacaoTipoOperacaoEnum.CONSIGNACAO:
        return ConstanteFuncionalidades.CONSIGNACAO_VISUALIZAR;
      case IdentificacaoTipoOperacaoEnum.DEVOLUCAO:
        return ConstanteFuncionalidades.VISUALIZAR_TROCA_DEVOLUCAO;
      case IdentificacaoTipoOperacaoEnum.ORCAMENTO:
        return ConstanteFuncionalidades.ORCAMENTO_VISUALIZAR;
      default:
        return ConstanteFuncionalidades.VENDASPEDIDOS_VISUALIZAR;
    }
  };

  const { permitido } = auth.possuiPermissao(permissaoVerDetalhes());

  const operacaoVendaOuDevolucao = venda || devolucao;
  const operacaoDevolucaoOuConsignacao = consignacao || devolucao;

  const handleCancelar = useCallback(() => {
    const { id, modeloNumeroFiscais } = operacao;

    const hasNotaFiscal: boolean =
      modeloNumeroFiscais && modeloNumeroFiscais?.length > 0;

    handleOperacaoId(id);
    handleIsVenda(venda || false);
    handleHasNotaFiscal(hasNotaFiscal);
    handleModalConfirmacaoCancelarIsOpen(true);
  }, [
    operacao,
    venda,
    handleHasNotaFiscal,
    handleIsVenda,
    handleModalConfirmacaoCancelarIsOpen,
    handleOperacaoId,
  ]);

  const impressaoDevolucaoDinheiroContainerRef = useRef<HTMLDivElement>(null);
  const imprimirDevolucaoDinheiro = useReactToPrint({
    content: () => impressaoDevolucaoDinheiroContainerRef.current,
  });
  const impressaoDevolucaoDinheiro = useRef<ImpressaoDevolucaoDinheiroProps>(
    null
  );

  const handleImprimirDevolucao = useCallback(async () => {
    setIsLoading(true);

    if (imprimirDevolucaoDinheiro && impressaoDevolucaoDinheiro.current) {
      await impressaoDevolucaoDinheiro.current.obterInformacoesImpressao();
      imprimirDevolucaoDinheiro();
    }
    setIsLoading(false);
  }, [imprimirDevolucaoDinheiro, setIsLoading]);

  const handleCompartilharOperacao = useCallback(
    (operacaoId: string, isDevolucao: boolean) => {
      if (isDevolucao) {
        ModalCompartilhar({
          items: [
            {
              titulo: 'Imprimir o comprovante de devolução',
              onClickImpressao: async (onClose) => {
                await handleImprimirDevolucao();
                onClose();
              },
            },
          ],
        });
      } else {
        CompartilharOperacao({
          isOpen: true,
          operacaoId,
          isVisibleWhatsApp: !operacaoDevolucaoOuConsignacao,
        });
      }
    },

    [operacaoDevolucaoOuConsignacao, handleImprimirDevolucao]
  );

  const getValidandoVisualizarDetalhes = useCallback(() => {
    return SubstituirParametroRota(
      ConstanteRotas.OPERACOES_DETALHE,
      'id',
      operacao.id
    );
  }, [operacao.id]);

  const emitirNFe = useCallback(() => {
    history.push(
      SubstituirParametroRota(
        ConstanteRotas.OPERACOES_EMITIR_NOTA_FISCAL_NFE,
        'id',
        operacao.id
      )
    );
  }, [history, operacao.id]);

  const emitirNFCe = useCallback(() => {
    history.push(
      SubstituirParametroRota(
        ConstanteRotas.OPERACOES_EMITIR_NOTA_FISCAL_NFCE,
        'id',
        operacao.id
      )
    );
  }, [history, operacao.id]);

  const abrirModalEscolherModeloFiscal = useCallback(async () => {
    const podeEmitirAmbos = podeEmitirNFe && podeEmitirNFCe;

    if (podeEmitirAmbos) {
      if (!possuiNFe && possuiNFCe) emitirNFe();
      if (!possuiNFCe && possuiNFe) emitirNFCe();

      if (!possuiNFe && !possuiNFCe) {
        const { modeloFiscal, sucesso } = await ModalEscolherModeloFiscal();

        if (sucesso) {
          if (modeloFiscal === ModelosFiscaisEnum.NFe) {
            emitirNFe();
          } else if (modeloFiscal === ModelosFiscaisEnum.NFCe) {
            emitirNFCe();
          }
        }
      }

      return;
    }

    if (podeEmitirNFCe && !possuiNFCe) {
      emitirNFCe();
    }

    if (podeEmitirNFe && !possuiNFe) {
      emitirNFe();
    }
  }, [
    emitirNFCe,
    emitirNFe,
    podeEmitirNFCe,
    podeEmitirNFe,
    possuiNFCe,
    possuiNFe,
  ]);

  const dropDownItens = ((): ActionMenuItem[] => {
    const isCancelTray = !(
      tipoOperacao.venda && IdentificacaoIntegracao.TRAY === operacao.origemEnum
    );

    const acoesValidadas = [
      {
        content: 'Ver detalhes',
        onClick: () => {
          carregarDataContextRef.current = true;
          history.push(
            SubstituirParametroRota(
              ConstanteRotas.OPERACOES_DETALHE,
              'id',
              operacao.id
            )
          );
        },
        funcionalidade: permissaoVerDetalhes(),
      },

      {
        content: 'Compartilhar',
        onClick: () => {
          handleCompartilharOperacao(operacao.id, devolucao);
        },
      },
      ...(venda
        ? [
            {
              content: 'Etiqueta de despacho',
              onClick: () => {
                ModalRelatorioDespacho({ operacaoId: operacao.id });
              },
            },
          ]
        : []),

      ...(!devolucao
        ? [
            {
              content: 'Duplicar',
              onClick: () => {
                handleDuplicar();
              },
            },
          ]
        : []),
      ...(statusEfetuada && isCancelTray
        ? [
            {
              content: 'Cancelar',
              onClick: () => {
                handleCancelar();
              },
              funcionalidade: ConstanteFuncionalidades.VENDASPEDIDOS_LISTAR,
            },
          ]
        : []),
    ];

    if (podeEmitirNFe && venda && temNFeEmProcessamento) {
      const nfeEmProcessamento = operacao.modeloNumeroFiscais.find(
        (documentoFiscal) =>
          documentoFiscal.modeloFiscal === ModelosFiscaisEnum.NFe &&
          documentoFiscal.status === StatusFiscaisEnum.EmProcessamento
      );

      if (nfeEmProcessamento) {
        return [
          ...acoesValidadas,
          {
            content: 'Verificar situação',
            onClick: () => {
              handleConsultaProtocoloNotaFiscal(nfeEmProcessamento.id);
            },
          },
        ];
      }
    }
    if (
      (naoPossuiNfeAndPodeEmitir || naoPossuiNfceAndPodeEmitir) &&
      planoAtual !== isPlanoTeste &&
      venda &&
      statusEfetuada &&
      !(possuiNFe && possuiNFCe)
    ) {
      return [
        ...acoesValidadas,
        {
          content: 'Emitir nota fiscal',
          onClick: () => {
            carregarDataContextRef.current = true;
            abrirModalEscolherModeloFiscal();
          },
        },
      ];
    }
    return acoesValidadas;
  })();

  const dateHours = formatDateHourMinute(operacao.dataEmissao).slice(11);
  const date = formatDateHourMinute(operacao.dataEmissao).slice(0, 11);

  return (
    <>
      <Box display="none">
        <ImpressaoDevolucaoDinheiro
          ref={impressaoDevolucaoDinheiro}
          containerRef={impressaoDevolucaoDinheiroContainerRef}
          operacaoId={operacao.id}
        />
      </Box>
      <Box h={index === 0 ? '0' : '2'} />
      <Tr
        fontSize="sm"
        key={operacao.id}
        minH="60px"
        height="60px"
        sx={{
          '& > td': {
            bg: devolucao ? 'red.50' : 'white',
            color: devolucao ? 'red.700' : 'gray.700',
            fontWeight: 'normal',
            fontSize: '14px',
          },
          '& + div': {
            h: '4px',
          },
        }}
      >
        <Td>
          <Box>
            <Text>{date}</Text>
            <Text as="span" color="gray.700" fontSize="12px">
              {dateHours}
            </Text>
          </Box>
        </Td>
        <Td>
          <Box>
            <Text>
              {getName(
                IdentificacaoTipoOperacaoEnum,
                operacao.identificacaoTipoOperacao
              )}
            </Text>
            <Text as="span" color="gray.700" fontSize="12px">
              {operacao.origem}
            </Text>
          </Box>
        </Td>
        <Td>
          <Link href={permitido ? getValidandoVisualizarDetalhes() : undefined}>
            <Box w="auto">
              <Box>{operacao.numeroOperacao}</Box>
              {operacao.possuiDevolucao && (
                <Flex>
                  <Tooltip
                    shouldWrapChildren
                    hasArrow
                    pt="16px"
                    pb="16px"
                    pl="25px"
                    pr="25px"
                    label="Venda com itens devolvidos"
                    placement="auto"
                    gutter={15}
                  >
                    <Icon as={DevolverVendaOperacao} w={5} h={5} />
                  </Tooltip>
                </Flex>
              )}
            </Box>
          </Link>
        </Td>
        <Td>
          <Text>{operacao.cliente}</Text>
          <Text>
            <Text
              as="span"
              style={{
                textAlign: 'left',
                font: 'normal normal normal 12px Nunito',
                letterSpacing: '0px',
                opacity: 1,
              }}
            >
              {operacao.fantasia}
            </Text>
          </Text>
        </Td>
        {statusCancelada && (
          <Td>
            <Text>
              {consignacao || venda || devolucao ? 'Cancelada' : 'Cancelado'}
            </Text>
          </Td>
        )}
        {operacaoVendaOuDevolucao && statusEfetuada && (
          <Td>
            {operacao.modeloNumeroFiscais.length > 0 ? (
              operacao.modeloNumeroFiscais
                .filter(
                  (documentoFiscal: DocumentosFiscalProps) =>
                    documentoFiscal.status !== StatusFiscaisEnum.EmProcessamento
                )
                .map((documentofiscal: DocumentosFiscalProps) => {
                  return (
                    <Text>
                      {getName(
                        ModelosFiscaisEnum,
                        documentofiscal.modeloFiscal
                      )}{' '}
                      | {documentofiscal.numeroFiscal}
                    </Text>
                  );
                })
            ) : (
              <Text color="black" fontWeight="normal">
                —————
              </Text>
            )}
          </Td>
        )}
        {(consignacao || pedido || orcamento) && statusEfetuada && (
          <Td>
            <Text color="black" fontWeight="normal">
              —————
            </Text>
          </Td>
        )}

        <Td background="red" textAlign="right">
          <Flex fontWeight="medium" justifyContent="flex-end">
            <Text as="span" mr="1" fontSize="10px">
              R$
            </Text>
            <Text>{moneyMask(operacao.valorTotal, false)}</Text>
          </Flex>
        </Td>
        <Td>
          <ActionsMenu
            items={dropDownItens}
            isDisabled={dropDownItens.length === 0}
          />
        </Td>
      </Tr>
    </>
  );
};

export default memo(OperacoesItens);
