import React from 'react';
import {
  Stack,
  Button,
  Icon,
  Text,
  Tooltip,
  HStack,
  Box,
  LayoutProps,
} from '@chakra-ui/react';

import formatDateHourMinute from 'helpers/format/formatUTCToLocateDateTime';

import {
  SalvarConfirmarIcon,
  SalvarInserirNovoIcon,
  CalendarioDataCadastroIcon,
} from 'icons';

export type FormFooterDates = {
  dataHoraCriacao?: string;
  dataHoraUltimaAlteracao?: string;
};

interface FormFooterProps {
  onSubmit?: () => void;
  textSubmit?: string;
  onSubmitReset?: () => void;
  textSubmitReset?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  dates?: FormFooterDates;
  withoutIcons?: boolean;
  submitButtonMaxWidth?: LayoutProps['maxW'];
  submitResetButtonMaxWidth?: LayoutProps['maxW'];
  isAccountPaid?: boolean;
}

export const FormFooter = ({
  onSubmit,
  onSubmitReset,
  isDisabled = false,
  isLoading = false,
  dates = {},
  textSubmit = 'Salvar',
  textSubmitReset = 'Salvar e inserir novo',
  withoutIcons = false,
  submitButtonMaxWidth = { base: 'full', sm: '175px' },
  submitResetButtonMaxWidth = { base: 'full', sm: '225px' },
  isAccountPaid,
}: FormFooterProps) => {
  const { dataHoraCriacao, dataHoraUltimaAlteracao } = dates;

  const shouldDisableButtonSave = isDisabled || isAccountPaid;
  const dataCriacao = dataHoraCriacao
    ? formatDateHourMinute(dataHoraCriacao)
    : undefined;
  const dataUltimaAlteracao = dataHoraUltimaAlteracao
    ? formatDateHourMinute(dataHoraUltimaAlteracao)
    : undefined;

  return (
    <Box position="relative">
      {dataCriacao && dataUltimaAlteracao && (
        <Box position="absolute" top="2" right="0">
          <HStack spacing="1">
            <Text fontSize="2sx">{dataCriacao.split(' ')[0]}</Text>

            <Tooltip
              hasArrow
              textAlign="left"
              placement="left"
              label={
                <>
                  {`Data do Cadastro: ${dataCriacao}`}
                  <br />
                  {`Última Alteração: ${dataUltimaAlteracao}`}
                </>
              }
            >
              <Box tabIndex={0}>
                <Icon as={CalendarioDataCadastroIcon} boxSize="4" />
              </Box>
            </Tooltip>
          </HStack>
        </Box>
      )}

      {(onSubmitReset || onSubmit) && (
        <Stack
          direction={{ base: 'column', sm: 'row' }}
          justifyContent="center"
          pt={{ base: 6, sm: 8, md: 10 }}
          spacing={{ base: 4, sm: 6, md: 8 }}
        >
          {onSubmitReset && (
            <Button
              id="salvarInserirNovo"
              name="salvarInserirNovo"
              variant="outlineDefault"
              colorScheme="gray"
              fontWeight="normal"
              w="full"
              maxW={submitResetButtonMaxWidth}
              px="6"
              borderRadius="full"
              leftIcon={
                withoutIcons ? undefined : (
                  <Icon as={SalvarInserirNovoIcon} fontSize="lg" />
                )
              }
              isLoading={isLoading}
              isDisabled={isDisabled}
              onClick={(event) => {
                if (event.detail === 1 && onSubmitReset) {
                  onSubmitReset();
                }
              }}
            >
              {textSubmitReset}
            </Button>
          )}
          {onSubmit && (
            <Button
              id="salvar"
              name="salvar"
              colorScheme={shouldDisableButtonSave ? 'gray' : 'aquamarine'}
              fontWeight="normal"
              variant="solid"
              w="full"
              maxW={submitButtonMaxWidth}
              px="6"
              isLoading={isLoading}
              borderRadius="full"
              leftIcon={
                withoutIcons ? undefined : (
                  <Icon as={SalvarConfirmarIcon} fontSize="lg" />
                )
              }
              isDisabled={shouldDisableButtonSave}
              onClick={(event) => {
                if (event.detail === 1 && onSubmit) {
                  onSubmit();
                }
              }}
            >
              {textSubmit}
            </Button>
          )}
        </Stack>
      )}
    </Box>
  );
};
