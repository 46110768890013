import { useEffect } from 'react';
import { Box, Button, GridItem, Icon } from '@chakra-ui/react';

import {
  KitProps,
  useProdutosFormularioContext,
} from 'store/Produtos/ProdutosFormulario';

import { SalvarInserirNovoIcon } from 'icons';
import { TableCampoFormularioPadrao } from 'components/update/Table/TableCampoFormularioPadrao';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';

import { ModalItemKit } from './ModalItemKit';
import { KitFooter } from './KitFooter';
import { KitItem } from './KitItem';
import { useKit } from './hooks';

export const Kits = () => {
  const { onChangeFormIsDirty } = useProdutosFormularioContext();
  const {
    handleAbrirModalKit,
    handleFecharModal,
    handleRemoverProdutoKit,
    headerColumns,
    modalSubtitle,
    control,
    setItemKitIndex,
    getKits,
    isLoading,
    readonly,
    itemKitIndex,
    itemKitModalIsOpen,
  } = useKit();

  useEffect(() => {
    onChangeFormIsDirty(false);
  }, [onChangeFormIsDirty]);

  return (
    <SimpleGridForm>
      <GridItem colSpan={12}>
        {!readonly && (
          <Button
            type="button"
            variant="solid"
            colorScheme="blue.700"
            leftIcon={<Icon as={SalvarInserirNovoIcon} />}
            marginBottom="5px"
            maxWidth="225px"
            maxHeight="34px"
            w="100%"
            fontWeight="normal"
            onClick={() => handleAbrirModalKit(undefined)}
          >
            Adicionar produtos
          </Button>
        )}
        <Box
          borderRadius="5px"
          overflowY="auto"
          px="24px"
          py="40px"
          borderColor="gray.200"
          bg="gray.50"
          borderWidth="1px"
        >
          <TableCampoFormularioPadrao
            fieldName="produtoCorTamanhoKitItens"
            defaultColumnOrder="descricao"
            bgHeader="gray.50"
            borderWidth="0"
            headerColumns={headerColumns}
            tableFootContent={({ tableValues }) => (
              <KitFooter tableValues={tableValues} />
            )}
            emptyValuesText="Nenhum item adicionado para este kit"
            control={control}
            isLoading={isLoading}
          >
            {({ tableValues: newTableValues }) => {
              const tableValues = newTableValues as KitProps[];
              return (
                <>
                  {tableValues &&
                    tableValues.map((tableValue, index) => (
                      <KitItem
                        key={tableValue.id}
                        handleRemoverProdutoKit={handleRemoverProdutoKit}
                        readonly={readonly || false}
                        handleAbrirModalKit={handleAbrirModalKit}
                        tableValue={tableValue}
                        index={index}
                      />
                    ))}
                </>
              );
            }}
          </TableCampoFormularioPadrao>
        </Box>

        <ModalItemKit
          show={itemKitModalIsOpen}
          onHide={handleFecharModal}
          onSaveAndInsertNew={() => {
            setItemKitIndex(undefined);
          }}
          getKits={getKits}
          modalSubtitle={modalSubtitle()}
          valueKitIndex={itemKitIndex}
        />
      </GridItem>
    </SimpleGridForm>
  );
};
