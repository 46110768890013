import { useEffect, useCallback } from 'react';

import auth from 'modules/auth';

export default (isReloadPrevented = true) => {
  const preventReload = useCallback(
    (e: BeforeUnloadEvent) => {
      const isAuthenticated = auth.autenticado();

      if (isReloadPrevented && isAuthenticated) {
        e.preventDefault();
        e.returnValue = '';
      }
    },
    [isReloadPrevented]
  );

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      window.addEventListener('beforeunload', preventReload);

      return () => {
        window.removeEventListener('beforeunload', preventReload);
      };
    }

    return () => {};
  }, [preventReload]);
};
