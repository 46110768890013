import { IdentificacaoEtapasSmartPos } from 'constants/enum/IdentificacaoEtapasSmartPos';

import { GuiaIntegracao } from '../GuiaIntegracao';
import { Autenticacao } from '../Autenticacao';
import { FormStone } from '../Stone/FormStone';
import { SucessoFormStone } from '../Stone/SucessoFormStone';
import { TextoImpressao } from '../TextoImpressao';
import { CategoriaProduto } from '../CategoriaProduto';
import { TipoImpressao } from '../TipoImpressao';
import { CodigoAtivacao } from '../CodigoAtivacao';
import { ImagemImpressao } from '../ImagemImpressao';
import { LocalEstoque } from '../LocalEstoque';
import { SenhaAdmnistrativa } from '../SenhaAdministrativa';
import { FormaRecebimento } from '../FormaRecebimento';

type StepsSmartPosProps = {
  activeStep: number;
};

export const StepsSmartPos = ({ activeStep }: StepsSmartPosProps) => {
  switch (activeStep) {
    case IdentificacaoEtapasSmartPos.GUIA_INTEGRACAO:
      return <GuiaIntegracao />;
    case IdentificacaoEtapasSmartPos.AUTENTICACAO:
      return <Autenticacao />;
    case IdentificacaoEtapasSmartPos.LOCAL_ESTOQUE:
      return <LocalEstoque />;
    case IdentificacaoEtapasSmartPos.FORMA_RECEBIMENTO:
      return <FormaRecebimento />;
    case IdentificacaoEtapasSmartPos.CATEGORIA_PRODUTO:
      return <CategoriaProduto />;
    case IdentificacaoEtapasSmartPos.TIPO_IMPRESSAO:
      return <TipoImpressao />;
    case IdentificacaoEtapasSmartPos.IMAGEM_PARA_IMPRESSAO:
      return <ImagemImpressao />;
    case IdentificacaoEtapasSmartPos.TEXTO_PARA_IMPRESSAO:
      return <TextoImpressao />;
    case IdentificacaoEtapasSmartPos.SENHA_ADM:
      return <SenhaAdmnistrativa />;
    case IdentificacaoEtapasSmartPos.CODIGO_ATIVACAO:
      return <CodigoAtivacao />;
    case IdentificacaoEtapasSmartPos.FORMULARIO_STONE:
      return <FormStone />;
    case IdentificacaoEtapasSmartPos.FOMULARIO_STONE_SUCESSO:
      return <SucessoFormStone />;

    default:
      return null;
  }
};
