import { Flex, Text } from '@chakra-ui/react';

type ClientProps = {
  clienteNome: string;
  codigoCliente: number;
};

export const Client = ({ clienteNome, codigoCliente }: ClientProps) => (
  <Flex flexDir="column" fontWeight="extrabold" minH="50px">
    <Text textAlign="start" fontSize="14px">
      {codigoCliente}
    </Text>
    <Text fontWeight="normal" fontSize="10px">
      {clienteNome?.toUpperCase()}
    </Text>
  </Flex>
);
