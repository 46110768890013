import { Flex, Text, Box, Icon } from '@chakra-ui/react';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';

import { TamanhoTodasLojas, VariacaoLojaProps } from '../types';
import { DetalhesEstoqueGeralMobile } from './DetalhesEstoque/detalhesEstoqueGeral';

type DadosPdvEstoqueGeralMobileProps = {
  exibirTodasAsLojas: VariacaoLojaProps[];
  handleToggleItemSelecionadoTodasLojas(index: number): void;
};

export const DadosPdvEstoqueGeralMobile = ({
  exibirTodasAsLojas,
  handleToggleItemSelecionadoTodasLojas,
}: DadosPdvEstoqueGeralMobileProps) => {
  return (
    <Box w="full" mt="-10px">
      {exibirTodasAsLojas.length > 0 ? (
        <>
          {exibirTodasAsLojas.map(({ cor, tamanhos }, index) => {
            const totalValores = tamanhos.reduce(
              (acc, curr) => acc + (curr.quantidade || 0),
              0
            );

            const agruparTodosTamanhos = tamanhos.reduce((acc, curr) => {
              const itemAlreadyAddedIndex = acc.findIndex(
                (item) => item.tamanho === curr.tamanho
              );

              if (itemAlreadyAddedIndex > -1) {
                acc.splice(itemAlreadyAddedIndex, 1, {
                  ...acc[itemAlreadyAddedIndex],
                  locaisEstoque: [
                    ...acc[itemAlreadyAddedIndex].locaisEstoque,
                    {
                      quantidade: curr.quantidade,
                      localEstoque: curr.localEstoque,
                    },
                  ],
                  locaisLoja: [
                    ...acc[itemAlreadyAddedIndex].locaisLoja,
                    {
                      saldoLoja: curr.quantidade,
                      loja: curr.loja,
                    },
                  ],
                });
              } else {
                acc.push({
                  tamanho: curr.tamanho,
                  loja: curr.loja,
                  selecionado: curr.selecionado,
                  quantidade: curr.quantidade,
                  localEstoque: curr.localEstoque,
                  locaisEstoque: [
                    {
                      quantidade: curr.quantidade,
                      localEstoque: curr.localEstoque,
                    },
                  ],
                  locaisLoja: [
                    {
                      saldoLoja: curr.quantidade,
                      loja: curr.loja,
                    },
                  ],
                });
              }

              return acc;
            }, [] as TamanhoTodasLojas[]);

            const existeItemSelecionado =
              exibirTodasAsLojas[index].tamanhos[index]?.selecionado;

            return (
              <Box
                mt="10px"
                w="full"
                borderRadius="4px"
                padding="10px"
                border="1px solid gray"
                key={`${cor}`}
              >
                <Box>
                  {cor && (
                    <Text color="primary.50" fontSize="16px">
                      {cor || ''}
                    </Text>
                  )}
                  <Box>
                    <Flex justifyContent="space-between">
                      <Text fontSize="12px">
                        Ver tamanhos{' '}
                        <Icon
                          color="black"
                          mt="-3px"
                          onClick={() =>
                            handleToggleItemSelecionadoTodasLojas(index)
                          }
                          as={existeItemSelecionado ? FiArrowUp : FiArrowDown}
                        />
                      </Text>
                      <Text fontSize="12px">Total: {totalValores}</Text>
                    </Flex>
                    {agruparTodosTamanhos
                      .filter(
                        (tamanhoSelecionado) => tamanhoSelecionado.selecionado
                      )
                      .map((itemTamanho) => (
                        <DetalhesEstoqueGeralMobile itemTamanho={itemTamanho} />
                      ))}
                  </Box>
                </Box>
              </Box>
            );
          })}
        </>
      ) : (
        <Box>Não existem registros para serem mostrados</Box>
      )}
    </Box>
  );
};
