import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form } from 'react-bootstrap';
import { FieldErrors } from 'react-hook-form';

import SelectPadrao from 'components/Select/SelectPadrao';
import { BootstrapNumberInput } from 'components/update/Input/BootstrapNumberInput';
import ModalidadesBaseCalculoIcmsEnum from 'constants/enum/fiscal/modalidadeBaseCalculoIcms';
import MotivosDesoneracaoIcmsEnum from 'constants/enum/fiscal/motivoDesoneracaoIcms';
import { Label } from 'components/Layout/CampoContainer/CampoContainerPadrao/styles';
import { useNotaFiscalFormularioContext } from 'store/NotaFiscal/NotaFiscalFormulario';

import { configVisibilidade } from '../validationForm';

interface UncontrolledFormInterface {
  errors: FieldErrors;
  control: any;
  watch: any;
  setValue: any;
  alteracao: boolean;
}

const ICMS: React.FC<UncontrolledFormInterface> = ({
  errors,
  control,
  watch,
  setValue,
  alteracao,
}) => {
  const { t } = useTranslation();
  const { lojaInformacoesFiscais } = useNotaFiscalFormularioContext();

  const cstCsosnWatch = watch('cstCsosn');

  const exibirCampo = (name: string): boolean => {
    const exibir = configVisibilidade[name]?.includes(cstCsosnWatch);

    if (!exibir) {
      setValue(name, 0);
    }

    return cstCsosnWatch !== undefined && exibir;
  };

  useEffect(() => {
    if (!alteracao && exibirCampo('icmsAproveitamentoAliquota')) {
      setValue(
        'icmsAproveitamentoAliquota',
        lojaInformacoesFiscais?.icmsAproveitamentoAliquota
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cstCsosnWatch]);

  return (
    <>
      {exibirCampo('modalidadeIcms') && (
        <Form.Row>
          <Col md={4} xl={2}>
            <SelectPadrao
              id="modalidadeIcms"
              name="modalidadeIcms"
              label={t('Modalidade cálculo ICMS')}
              noSelectedText={t('Clique aqui para selecionar.')}
              defaultValue={3}
              control={control}
              error={errors.modalidadeIcms}
              options={Object.entries(
                ModalidadesBaseCalculoIcmsEnum.properties
              ).map((value: any) => {
                return (
                  {
                    label: t(value[1].name),
                    value: value[1].value,
                  } || {}
                );
              })}
              readonly={false}
              required
            />
          </Col>
        </Form.Row>
      )}
      <Form.Row>
        {exibirCampo('icmsReducaoBaseCalculo') && (
          <Col md={3} xl={2}>
            <BootstrapNumberInput
              id="icmsReducaoBaseCalculo"
              name="icmsReducaoBaseCalculo"
              label={t('Redução BC')}
              precision={12}
              scale={2}
              defaultValue={0}
              leftElement="%"
              control={control}
              error={errors.icmsReducaoBaseCalculo}
            />
          </Col>
        )}
        {exibirCampo('icmsBaseCalculo') && (
          <Col md={3} xl={2}>
            <BootstrapNumberInput
              id="icmsBaseCalculo"
              name="icmsBaseCalculo"
              label={t('Base de cálculo')}
              precision={12}
              scale={2}
              defaultValue={0}
              leftElement="R$"
              control={control}
              error={errors.icmsBaseCalculo}
            />
          </Col>
        )}
        {exibirCampo('icmsAliquota') && (
          <Col md={3} xl={2}>
            <BootstrapNumberInput
              id="icmsAliquota"
              name="icmsAliquota"
              label={t('Alíquota ICMS')}
              precision={12}
              scale={2}
              defaultValue={0}
              leftElement="%"
              control={control}
              error={errors.icmsAliquota}
            />
          </Col>
        )}
        {exibirCampo('icmsValor') && (
          <Col md={3} xl={2}>
            <BootstrapNumberInput
              id="icmsValor"
              name="icmsValor"
              label={t('Valor ICMS')}
              precision={12}
              scale={2}
              defaultValue={0}
              leftElement="R$"
              control={control}
              error={errors.icmsValor}
            />
          </Col>
        )}
      </Form.Row>
      {exibirCampo('fcpBaseCalculo') &&
        exibirCampo('fcpAliquota') &&
        exibirCampo('fcpValor') && (
          <Form.Row>
            <Col md={3} xl={2}>
              <BootstrapNumberInput
                id="fcpBaseCalculo"
                name="fcpBaseCalculo"
                label={t('Base de cálculo FCP')}
                precision={12}
                scale={2}
                defaultValue={0}
                leftElement="R$"
                control={control}
                error={errors.fcpBaseCalculo}
              />
            </Col>
            <Col md={3} xl={2}>
              <BootstrapNumberInput
                id="fcpAliquota"
                name="fcpAliquota"
                label={t('Alíquota FCP')}
                precision={12}
                scale={2}
                defaultValue={0}
                leftElement="%"
                control={control}
                error={errors.fcpAliquota}
              />
            </Col>
            <Col md={3} xl={2}>
              <BootstrapNumberInput
                id="fcpValor"
                name="fcpValor"
                label={t('Valor FCP')}
                precision={12}
                scale={2}
                defaultValue={0}
                leftElement="R$"
                control={control}
                error={errors.fcpValor}
              />
            </Col>
          </Form.Row>
        )}
      {exibirCampo('icmsValorOperacao') &&
        exibirCampo('icmsDiferidoPercentual') &&
        exibirCampo('icmsDiferidoValor') && (
          <Form.Row>
            <Col md={3} xl={2}>
              <BootstrapNumberInput
                id="icmsValorOperacao"
                name="icmsValorOperacao"
                label={t('Valor do ICMS operação')}
                precision={12}
                scale={2}
                defaultValue={0}
                leftElement="R$"
                control={control}
                error={errors.icmsValorOperacao}
              />
            </Col>
            <Col md={3} xl={2}>
              <BootstrapNumberInput
                id="icmsDiferidoPercentual "
                name="icmsDiferidoPercentual"
                label={t('Percentual do diferimento')}
                precision={12}
                scale={2}
                defaultValue={0}
                leftElement="%"
                control={control}
                error={errors.icmsDiferidoPercentual}
              />
            </Col>
            <Col md={3} xl={2}>
              <BootstrapNumberInput
                id="icmsDiferidoValor"
                name="icmsDiferidoValor"
                label={t('Valor do ICMS diferido')}
                precision={12}
                scale={2}
                defaultValue={0}
                leftElement="R$"
                control={control}
                error={errors.icmsDiferidoValor}
              />
            </Col>
          </Form.Row>
        )}
      {exibirCampo('icmsAproveitamentoAliquota') &&
        exibirCampo('icmsAproveitamentoValor') && (
          <>
            <Form.Row>
              <Col xl={12}>
                <div className="d-flex mt-1">
                  <Label>
                    Informações de aproveitamento de crédito de ICMS
                  </Label>
                </div>
                <hr className="my-auto flex-grow-1" />
              </Col>
            </Form.Row>
            <Form.Row>
              <Col md={3} xl={2}>
                <BootstrapNumberInput
                  id="icmsAproveitamentoAliquota"
                  name="icmsAproveitamentoAliquota"
                  label={t('Alíquota para cálculo')}
                  precision={12}
                  scale={2}
                  defaultValue={0}
                  leftElement="%"
                  control={control}
                  error={errors.icmsAproveitamentoAliquota}
                />
              </Col>
              <Col md={3} xl={2}>
                <BootstrapNumberInput
                  id="icmsAproveitamentoValor"
                  name="icmsAproveitamentoValor"
                  label={t('Valor do crédito de ICMS')}
                  precision={12}
                  scale={2}
                  defaultValue={0}
                  leftElement="R$"
                  control={control}
                  error={errors.icmsAproveitamentoValor}
                />
              </Col>
            </Form.Row>
          </>
        )}
      {exibirCampo('icmsDesoneradoValor') &&
        exibirCampo('icmsMotivoDesoneracao') && (
          <>
            <Form.Row>
              <Col xl={12}>
                <div className="d-flex mt-1">
                  <Label>ICMS desonerado</Label>
                </div>
                <hr className="my-auto flex-grow-1" />
              </Col>
            </Form.Row>
            <Form.Row>
              <Col md={3} xl={2}>
                <BootstrapNumberInput
                  id="icmsDesoneradoValor"
                  name="icmsDesoneradoValor"
                  label={t('Valor ICMS desonerado')}
                  precision={12}
                  scale={2}
                  defaultValue={0}
                  leftElement="R$"
                  control={control}
                  error={errors.icmsDesoneradoValor}
                />
              </Col>

              <Col md={6} xl={4}>
                <SelectPadrao
                  id="icmsMotivoDesoneracao"
                  name="icmsMotivoDesoneracao"
                  label={t('Motivo de desoneração de ICMS')}
                  noSelectedText={t('Clique aqui para selecionar.')}
                  defaultValue={null}
                  control={control}
                  error={errors.icmsMotivoDesoneracao}
                  options={Object.entries(
                    MotivosDesoneracaoIcmsEnum.properties
                  ).map((value: any) => {
                    return (
                      {
                        label: t(value[1].name),
                        value: value[1].value,
                      } || {}
                    );
                  })}
                  readonly={false}
                  required={false}
                />
              </Col>
            </Form.Row>
          </>
        )}
      {exibirCampo('icmsEfetivoReducao') &&
        exibirCampo('icmsEfetivoBaseCalculo') &&
        exibirCampo('icmsEfetivoAliquota') &&
        exibirCampo('icmsEfetivoValor') && (
          <>
            <Form.Row>
              <Col xl={12}>
                <div className="d-flex mt-1">
                  <Label>Informações de ICMS efetivo</Label>
                </div>
                <hr className="my-auto flex-grow-1" />
              </Col>
            </Form.Row>

            <Form.Row>
              <Col md={3} xl={2}>
                <BootstrapNumberInput
                  id="icmsEfetivoReducao"
                  name="icmsEfetivoReducao"
                  label={t('Redução BC')}
                  precision={12}
                  scale={2}
                  defaultValue={0}
                  leftElement="%"
                  control={control}
                  error={errors.icmsEfetivoReducao}
                />
              </Col>

              <Col md={3} xl={2}>
                <BootstrapNumberInput
                  id="icmsEfetivoBaseCalculo"
                  name="icmsEfetivoBaseCalculo"
                  label={t('Base de cálculo')}
                  precision={12}
                  scale={2}
                  defaultValue={0}
                  leftElement="R$"
                  control={control}
                  error={errors.icmsEfetivoBaseCalculo}
                />
              </Col>

              <Col md={3} xl={2}>
                <BootstrapNumberInput
                  id="icmsEfetivoAliquota"
                  name="icmsEfetivoAliquota"
                  label={t('Alíquota ICMS')}
                  precision={12}
                  scale={2}
                  defaultValue={0}
                  leftElement="%"
                  control={control}
                  error={errors.icmsEfetivoAliquota}
                />
              </Col>

              <Col md={3} xl={2}>
                <BootstrapNumberInput
                  id="icmsEfetivoValor"
                  name="icmsEfetivoValor"
                  label={t('Valor ICMS')}
                  precision={12}
                  scale={2}
                  defaultValue={0}
                  leftElement="R$"
                  control={control}
                  error={errors.icmsEfetivoValor}
                />
              </Col>
            </Form.Row>
          </>
        )}
    </>
  );
};

export default ICMS;
