import React from 'react';
import { Button } from '@chakra-ui/react';

import { IdentificacaoEtapasTray } from 'constants/enum/IdentificacaoEtapasTray';
import { useTrayEtapasContext } from 'store/Tray';

export const Footer = () => {
  const { activeStep, ref } = useTrayEtapasContext();

  const isTabelaPreco =
    activeStep === IdentificacaoEtapasTray.CADASTRAR_TABELA_PRECO;

  const isBuscarProduto = activeStep === IdentificacaoEtapasTray.BUSCAR_PRODUTO;

  const labelButton = () => {
    switch (activeStep) {
      case IdentificacaoEtapasTray.GUIA_INTEGRACAO:
        return 'Iniciar Guia de Integração';
      case IdentificacaoEtapasTray.IMPORTANDO:
        return 'Sair do guia de integração';
      case IdentificacaoEtapasTray.CADASTRAR_TABELA_PRECO:
        return 'Criar tabela de preços';
      case IdentificacaoEtapasTray.ENVIANDO_ZENDAR:
        return 'Sair do guia de integração';
      case IdentificacaoEtapasTray.BUSCAR_PRODUTO:
        return 'Pesquisar produtos';

      default:
        return 'Avançar';
    }
  };

  return (
    <>
      {ref?.current?.handleAvancar && (
        <>
          {isTabelaPreco || isBuscarProduto ? (
            <Button
              w="240px"
              variant="solid"
              colorScheme="aquamarine.600"
              onClick={() => {
                if (ref?.current?.handleAvancar) {
                  ref?.current?.handleAvancar();
                }
              }}
            >
              {labelButton()}
            </Button>
          ) : (
            <Button
              variant="solid"
              colorScheme="purple"
              minW="140px"
              onClick={() => {
                if (ref?.current?.handleAvancar) {
                  ref?.current?.handleAvancar();
                }
              }}
            >
              {labelButton()}
            </Button>
          )}
        </>
      )}
    </>
  );
};
