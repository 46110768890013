import { useEffect, useState } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Flex,
  ModalFooter,
  Box,
  ModalHeader,
  useMediaQuery,
  Divider,
  Text,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { ButtonDefault } from 'components/Button/ButtonChakra';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';

type FormData = {
  formaRecebimento: string;
};

type FormaRecebimentoResponse = {
  id: string;
  nome: string;
};

type ModalAdicionarDescricaoProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<FormData> & {
    descricao: string;
    siteId: string;
    getFormasPagamentoTray: () => Promise<void>;
  };

export const ModalAdicionarDescricao = create<
  ModalAdicionarDescricaoProps,
  FormData
>(
  ({
    onResolve,
    onReject,
    descricao,
    getFormasPagamentoTray,
    siteId,
    ...rest
  }) => {
    const [formaRecebimento, setFormaRecebimento] = useState<
      FormaRecebimentoResponse[]
    >([]);
    const [isLoading, setIsLoading] = useState(false);

    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    const formMethods = useForm<FormData>();

    const { handleSubmit: onSubmit } = formMethods;

    const handleSubmit = onSubmit(async (data) => {
      setIsLoading(true);
      const response = await api.put<
        void,
        ResponseApi<FormaRecebimentoResponse[]>
      >(ConstanteEnderecoWebservice.INTEGRACAO_TRAY_VINCULAR_FORMA_PAGAMENTO, [
        { siteId, zendarId: data.formaRecebimento },
      ]);

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
          setIsLoading(false);
        }

        if (response.sucesso) {
          getFormasPagamentoTray();
          onClose();
          toast.success('A forma de recebimento foi vinculada com sucesso');
          setIsLoading(false);
        }
        setIsLoading(false);
      }
      setIsLoading(false);
    });

    useEffect(() => {
      async function obterValoresFormaRecebimento() {
        setIsLoading(true);
        const response = await api.get<
          void,
          ResponseApi<FormaRecebimentoResponse[]>
        >(
          ConstanteEnderecoWebservice.FORMA_RECEBIMENTO_LISTAR_SELECT_RECEBIMENTO
        );

        if (response) {
          if (response.avisos) {
            response.avisos.forEach((aviso) => toast.warning(aviso));
          }

          if (response.sucesso && response.dados) {
            setFormaRecebimento(response.dados);
          }
        }
      }

      obterValoresFormaRecebimento();
      setIsLoading(false);
    }, []);

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? '3xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
      >
        <ModalContent
          bg="gray.50"
          borderRadius={isLargerThan900 ? 'md' : '0'}
          marginBottom={isLargerThan900 ? '3.75rem' : '0'}
          marginTop={isLargerThan900 ? '3.75rem' : '0'}
          w={isLargerThan900 ? '623px' : 'full'}
          h={isLargerThan900 ? '320px' : 'full'}
        >
          <ModalHeader pt="16px" pb="28px" pl="24px">
            <Text color="primary.50" fontSize="16px">
              Alterar forma vinculada
            </Text>
            <Text color="gray.700" fontSize="12px">
              Informe uma nova forma de recebimento
            </Text>

            <Divider mt="16px" />
          </ModalHeader>

          <FormProvider {...formMethods}>
            {isLoading && <LoadingPadrao />}
            <ModalBody pl="24px" pr="24px" mb="20px" py="0">
              <Box>
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  h="full"
                  w="full"
                  mb="24px"
                >
                  <Text mr="10px">Forma recebimento utilizada no site</Text>
                  <Text mr="10px">Forma recebimento utilizada no sistema</Text>
                </Flex>
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  h="full"
                  w="full"
                >
                  <Text mr="10px"> • {descricao}</Text>
                  <Box w={['full', 'full', '300px']}>
                    <SelectPadrao
                      id="formaRecebimento"
                      name="formaRecebimento"
                      asControlledByObject={false}
                      colSpan={12}
                      options={formaRecebimento.map((item) => ({
                        value: item.id,
                        label: item.nome,
                      }))}
                      placeholder="Forma de recebimento"
                    />
                  </Box>
                </Flex>
              </Box>
            </ModalBody>
            <ModalFooter flexDirection="column">
              <Flex
                w="full"
                h="full"
                justifyContent="center"
                alignItems="baseline"
              >
                <Button
                  variant="cancel"
                  borderRadius="16px"
                  fontSize="sm"
                  h={isLargerThan900 ? '32px' : '40px'}
                  mr="24px"
                  onClick={() => onClose()}
                  w="96px"
                >
                  Cancelar
                </Button>
                <ButtonDefault
                  variant="solid"
                  color="white"
                  borderRadius="16px"
                  colorScheme="aquamarine.600"
                  h={isLargerThan900 ? '32px' : '40px'}
                  width="120px"
                  onClick={() => handleSubmit()}
                  isLoading={isLoading}
                >
                  Confirmar
                </ButtonDefault>
              </Flex>
            </ModalFooter>
          </FormProvider>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
