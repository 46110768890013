import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

interface CabecalhoProps {
  nomeFantasia: string;
  cnpjTelefone: string;
  enderecoLoja: string;
}

const Cabecalho = ({
  nomeFantasia,
  cnpjTelefone,
  enderecoLoja,
}: CabecalhoProps) => {
  return (
    <>
      <Flex
        pt={1}
        pb={1}
        pl={1}
        pr={1}
        alignItems="center"
        justifyContent="center"
        border="2px solid"
        borderColor="gray.700"
        direction="column"
      >
        <Flex>
          <Text fontSize="md">{nomeFantasia}</Text>
        </Flex>
        <Flex>
          <Text whiteSpace="nowrap" fontSize="xs">
            {cnpjTelefone}
          </Text>
        </Flex>
        <Flex>
          <Text fontSize="xs">{enderecoLoja}</Text>
        </Flex>
      </Flex>
    </>
  );
};

export default Cabecalho;
