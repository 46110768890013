import { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import { shallowEqual } from 'helpers/validation/shallowEqual';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';

import { PagedTableForwardRefData } from 'components/update/Table/PagedTable';
import { PaginationData } from 'components/update/Pagination';
import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';

export type RelatorioPersonalizadoProps = {
  id: string;
  ativo: boolean;
  descricao: string;
  caminhoArquivo: string;
  nomeArquivo: string;
  tipo: string;
  dataHoraCadastro: string;
  dataHoraUltimaAlteracao: string;
};

export type FiltrosProps = { descricao: string; ativo: boolean | string };

const useRelatorioPersonalizadoListar = () => {
  const history = useHistory();
  const pagedTableRef = useRef<PagedTableForwardRefData>(null);

  const [filtros, setFiltros] = useState<FiltrosProps>({
    descricao: '',
    ativo: true,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [
    relatorioPersonalizadoLista,
    setRelatorioPersonalizadoLista,
  ] = useState<RelatorioPersonalizadoProps[]>([]);

  const alterarRelatorioPersonalizado = (relatorioPersonalizadoId: string) => {
    history.push(
      SubstituirParametroRota(
        ConstanteRotas.FRENTE_CAIXA_RELATORIO_PERSONALIZADO_ALTERAR,
        'id',
        relatorioPersonalizadoId
      )
    );
  };

  const cadastrarRelatorioPersonalizado = () => {
    history.push(ConstanteRotas.FRENTE_CAIXA_RELATORIO_PERSONALIZADO_CADASTRAR);
  };

  const removerRelatorioPersonalizado = (relatorioPersonalizadoId: string) => {
    ModalConfirmacaoExcluir({
      callback: async (confirmado: boolean) => {
        if (!confirmado) {
          return;
        }

        setIsLoading(true);

        const response = await api.delete<void, ResponseApi>(
          ConstanteEnderecoWebservice.FRENTE_CAIXA_RELATORIO_PERSONALIZADO_EXCLUIR,
          {
            params: { id: relatorioPersonalizadoId },
          }
        );

        if (response) {
          if (response?.avisos) {
            response.avisos.forEach((aviso) => toast.warning(aviso));
          }
          if (response?.sucesso) {
            toast.success('Relatório personalizado removido com sucesso');
            await pagedTableRef?.current?.reload();
          }
        }

        setIsLoading(false);
      },
    });
  };

  const obterRelatorioPersonalizadoListaPaginada = useCallback(
    async (paginationData: PaginationData) => {
      setIsLoading(true);

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<RelatorioPersonalizadoProps>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.FRENTE_CAIXA_RELATORIO_PERSONALIZADO_LISTAR_PAGINADO,
          paginationData
        ),
        {
          params: { ...filtros },
        }
      );

      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response?.sucesso && response?.dados) {
          setTotalRegistros(response.dados?.total);
          setRelatorioPersonalizadoLista(response.dados?.registros);
        }
      }

      setIsLoading(false);
    },
    [filtros]
  );

  const atualizarFiltros = (filtrosForm: FiltrosProps) => {
    if (!shallowEqual(filtrosForm, filtros)) {
      setFiltros(filtrosForm);
    }
  };

  return {
    atualizarFiltros,
    obterRelatorioPersonalizadoListaPaginada,
    alterarRelatorioPersonalizado,
    removerRelatorioPersonalizado,
    cadastrarRelatorioPersonalizado,
    relatorioPersonalizadoLista,
    pagedTableRef,
    totalRegistros,
    isLoading,
  };
};

export default useRelatorioPersonalizadoListar;
