import { StyleFunctionProps } from '@chakra-ui/theme-tools';
import { StepsStyleConfig } from 'chakra-ui-steps';

export const Steps = {
  ...StepsStyleConfig,
  baseStyle: (props: StyleFunctionProps) => ({
    ...StepsStyleConfig.baseStyle(props),
    step: {
      ...StepsStyleConfig.baseStyle(props).step,
      position: 'relative',
      '& > div > div:first-child': { bg: `${props.colorScheme}.500` },
      '& > div:first-child': {
        flexDirection: 'column',
        '& > div:not(:first-child)': {
          alignItems: 'center',
          position: 'absolute',
          whiteSpace: 'nowrap',
          w: '8',
          bottom: 0,
          left: '0',
          transform: 'translateY(calc(1.5em + 0.875rem))',
          '& > span': {
            m: '0',
            fontWeight: 'semibold',
          },
        },
      },
      '& > div:last-child': {
        h: '6px',
        borderTopWidth: '6px',
        mr: '0',
      },
    },
  }),
};
