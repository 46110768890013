import React, { forwardRef, useRef, useEffect } from 'react';
import { Form, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FieldErrors, UseFormRegister } from 'react-hook-form';

import InputPadrao from 'components/Input/InputPadrao';
import InputTelefoneWhatsapp from 'components/Input/InputTelefoneWhatsapp';
import InputNull from 'components/Input/InputNull';

interface ContatoAdicionalInterface {
  errors: FieldErrors;
  register: UseFormRegister<Record<string, any>>;
  readonly?: boolean;
  control: any;
  setValue: (name: string, value: any) => void;
  getValue: (name: string) => any;
  isWhats?: boolean;
  indexContato: number;
}

const ContatoAdicional = forwardRef(
  ({
    errors,
    register,
    readonly,
    control,
    setValue,
    getValue,
    isWhats,
    indexContato,
  }: ContatoAdicionalInterface) => {
    const { t } = useTranslation();

    const firstInputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

    useEffect(() => {
      const firstInput = document.getElementById('ContatoNome');
      if (firstInput) firstInput.focus();

      if (indexContato !== -1) {
        const data = getValue('contatosAdicionais');

        setValue('idContatoAdicional', data[indexContato].id);
        setValue('ContatoNome', data[indexContato].Nome);
        setValue('ContatoTelefone', data[indexContato].Telefone);
        setValue('ContatoEmail', data[indexContato].Email);
        setValue('ContatoTelefoneWhats', data[indexContato].WhatsApp);
      } else {
        setValue('idContatoAdicional', null);
      }
    }, [firstInputRef, getValue, setValue, indexContato]);

    return (
      <>
        <Form.Row
          style={{
            paddingLeft: '15px',
            paddingRight: '15px',
            paddingBottom: '15px',
          }}
        >
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <InputPadrao
              id="ContatoNome"
              label={t('Nome')}
              error={errors.ContatoNome}
              defaultValue=""
              maxLength={60}
              readOnly={readonly}
              placeholder={t('Digite o nome')}
              autoFocus
              control={control}
            />
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <InputNull
              type="text"
              id="ContatoEmail"
              name="ContatoEmail"
              label={t('E-mail')}
              placeholder={t('Digite o endereço de e-mail')}
              maxLength={80}
              control={control}
              error={errors.ContatoEmail}
              disabled={readonly}
            />
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <InputTelefoneWhatsapp
              id="ContatoTelefone"
              name="ContatoTelefone"
              label={t('Telefone')}
              control={control}
              defaultValue=""
              error={errors.ContatoTelefone}
              readOnly={readonly}
              placeholder="(00) 0000-0000"
              whatsApp={isWhats}
            />
          </Col>
        </Form.Row>
      </>
    );
  }
);

export default ContatoAdicional;
