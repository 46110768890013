export const venderEcommerce = {
  SIM_LIBERADO: 0,
  NAO_BLOQUEADO: 1,

  options: [
    {
      label: 'Sim, liberado',
      value: true,
    },
    {
      label: 'Não, bloqueado',
      value: false,
    },
  ],
};
