import { GridItem, Text, Box, Flex } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useFormContext } from 'react-hook-form';

import api, { ResponseApi } from 'services/api';
import StatusConsultaEnum from 'constants/enum/statusConsulta';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import Input from 'components/PDV/Input';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import CreatableSelect from 'components/PDV/Select/CreatableSelect';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { ModalCadastrarDepartamento } from '../../components/ModalCadastrarDepartamento';

export type Option = {
  label: string;
  value: string;
};

const UncontrolledForm = () => {
  const [printers, setPrinters] = useState<Option[]>([]);
  const [departments, setDepartments] = useState<Option[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { watch } = useFormContext();

  const tipoImpressao = watch('tipoImpressao');

  const loadPrinters = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get<
      void,
      ResponseApi<
        {
          id: string;
          nome: string;
        }[]
      >
    >(ConstanteEnderecoWebservice.IMPRESSORA, {
      params: { statusConsulta: StatusConsultaEnum.ATIVOS },
    });

    if (response?.avisos) {
      response.avisos.forEach((aviso: string) => toast.warning(aviso));
    }

    if (response && response.sucesso && response.dados) {
      const data = response.dados.map((option) => ({
        label: option.nome,
        value: option.id,
      }));
      setPrinters(data);

      setIsLoading(false);
      return;
    }

    setPrinters([]);
    setIsLoading(false);
  }, []);

  const loadDepartments = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<
      void,
      ResponseApi<
        {
          id: string;
          nome: string;
        }[]
      >
    >(ConstanteEnderecoWebservice.DEPARTAMENTO_LISTAR_SELECT, {
      params: { statusConsulta: StatusConsultaEnum.ATIVOS },
    });

    if (response?.avisos) {
      response.avisos.forEach((aviso: string) => toast.warning(aviso));
    }

    if (response && response.sucesso && response.dados) {
      const data = response.dados.map((option) => ({
        label: option.nome,
        value: option.id,
      }));

      setDepartments(data);
      setIsLoading(false);
      return;
    }

    setDepartments([]);
    setIsLoading(false);
  }, []);

  const loadPrintAndDepartments = useCallback(async () => {
    await Promise.all([loadDepartments(), loadPrinters()]);
  }, [loadDepartments, loadPrinters]);

  const handleCreateNewPrinter = async (inputValue: string) => {
    const response = await api.post<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.IMPRESSORA,
      {
        nome: inputValue,
        ativo: true,
      }
    );

    if (response?.avisos) {
      response.avisos.forEach((aviso: string) => toast.warning(aviso));
    }

    if (response && response.sucesso && response.dados) {
      const newPrinter = {
        label: inputValue,
        value: response.dados,
      };
      setPrinters((prevState) => [...prevState, newPrinter]);
      return newPrinter;
    }

    return {} as Option;
  };

  const handleCreateNewDepartments = async (inputValue: string) => {
    const { success, newDepartmentId } = await ModalCadastrarDepartamento({
      descricao: inputValue,
    });

    if (!success || !newDepartmentId) return {} as Option;

    const newDepartment = {
      label: inputValue,
      value: newDepartmentId,
    };
    setDepartments((prevState) => [...prevState, newDepartment]);
    return newDepartment;
  };

  useEffect(() => {
    loadPrintAndDepartments();
  }, [loadPrintAndDepartments]);

  return (
    <SimpleGridForm gap={{ base: 6, md: 8 }}>
      {isLoading && <LoadingPadrao />}
      <GridItem colSpan={[12, 6]}>
        <CreatableSelect
          id="departamentoId"
          name="departamentoId"
          label="Departamento"
          placeholder="Selecione ou informe o departamento"
          required
          isLoading={isLoading}
          options={departments}
          creatableInputTextPreffix="Cadastrar departamento: "
          handleCreateOption={handleCreateNewDepartments}
          isClearable
        />
      </GridItem>
      <GridItem colSpan={[12, 6]}>
        <CreatableSelect
          id="impressoraId"
          name="impressoraId"
          label="Impressora"
          placeholder="Selecione ou informe a impressora"
          required
          options={printers}
          isLoading={isLoading}
          creatableInputTextPreffix="Cadastrar impressora: "
          handleCreateOption={handleCreateNewPrinter}
          isClearable
        />
      </GridItem>
      <GridItem colSpan={[12]}>
        <Input
          id="caminho"
          name="caminho"
          textTransform="uppercase"
          _placeholder={{ textTransform: 'none' }}
          label="Caminho"
          placeholder="Informe o caminho da impressora"
          isRequired
        />
      </GridItem>
      <GridItem colSpan={[12, 6, 8]}>
        <Text fontSize="14px" h="24px" fontWeight="semibold" color="black">
          Configurações
        </Text>
        <Flex
          w="100%"
          flexDir="column"
          borderRadius="5px"
          justify="center"
          p="24px"
          borderColor="gray.200"
          bg="white"
          gap="32px"
          h="182px"
          borderWidth="1px"
        >
          <Flex
            justifyContent="space-between"
            align={['flex-start', 'flex-start', 'center']}
            flexDir={['column', 'column', 'row']}
          >
            <Text fontWeight="semibold" fontSize="sm">
              Imprimir linhas ao final do relatório
            </Text>
            <Box w={['100%', '100%', '200px']}>
              <SelectPadrao
                id="quantidadeLinhasFim"
                name="quantidadeLinhasFim"
                options={new Array(11).fill(0).map((_, index) => ({
                  label: index.toString(),
                  value: index,
                }))}
                required
              />
            </Box>
          </Flex>
          <Flex
            justifyContent="space-between"
            align={['flex-start', 'flex-start', 'center']}
            flexDir={['column', 'column', 'row']}
          >
            <Text fontWeight="semibold" fontSize="sm">
              Configuração do corte guilhotina
            </Text>
            <Box w={['100%', '100%', '200px']}>
              <SelectPadrao
                id="corteGuilhotina"
                name="corteGuilhotina"
                options={new Array(3).fill(0).map((_, index) => ({
                  label: (index + 1).toString(),
                  value: index + 1,
                }))}
                required
              />
            </Box>
          </Flex>
        </Flex>
      </GridItem>
      <GridItem colSpan={[12, 4, 4]} mt={['0px', '24px', '24px']}>
        <Flex
          w="100%"
          flexDir="column"
          gap="14px"
          borderRadius="5px"
          p="20px"
          borderColor="gray.200"
          bg="white"
          height={tipoImpressao === 2 ? '182px' : '105px'}
          borderWidth="1px"
        >
          <SelectPadrao
            id="tipoImpressao"
            name="tipoImpressao"
            label="Tipo de impressão"
            options={[
              { label: 'Laser', value: 0 },
              { label: 'Cupom', value: 2 },
            ]}
            required
          />
          {tipoImpressao === 2 && (
            <SelectPadrao
              id="tipoCupom"
              name="tipoCupom"
              label="Cupom"
              options={[
                { label: 'Windows', value: 1 },
                { label: 'Crystal', value: 2 },
              ]}
              required
            />
          )}
        </Flex>
      </GridItem>
    </SimpleGridForm>
  );
};

export { UncontrolledForm };
