import React from 'react';
import { Step as ChakraStep, Steps as ChakraSteps } from 'chakra-ui-steps';
import { BoxProps } from '@chakra-ui/react';

import { StepButton } from './StepButton';

export type Step = {
  label: string;
  content: React.ReactNode;
  isCompletedStep?: boolean;
};

interface StepsProps extends BoxProps {
  activeStep: number;
  steps: Step[];
  colorScheme?: string;
  showLabel?: boolean;
}

export function Steps({
  activeStep,
  steps,
  colorScheme = 'primary',
  showLabel = true,
  ...rest
}: StepsProps) {
  return (
    <ChakraSteps
      mb="14"
      activeStep={activeStep}
      colorScheme={colorScheme}
      size="sm"
      {...rest}
      responsive={false}
    >
      {steps.map(({ label, content, isCompletedStep }, index) => {
        const isActive = activeStep === index;

        return (
          <ChakraStep
            label={showLabel ? label : ''}
            key={label}
            icon={() => (
              <StepButton
                index={index}
                isActive={isActive}
                colorScheme={colorScheme}
              />
            )}
            position="relative"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            isCompletedStep={isCompletedStep}
          >
            {content}
          </ChakraStep>
        );
      })}
    </ChakraSteps>
  );
}
