import React, { useMemo } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  useDisclosure,
  ModalHeader,
  ModalCloseButton,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { BrowserRouter, match, Route } from 'react-router-dom';

import PadronizacaoProvider from 'store/Padronizacao/Padronizacao';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import Alterar from 'pages/Produtos/Formulario/Alterar';

type ModalResolve = {
  sucesso: boolean;
};

type TParams = { id: string };

type ModalAlterarProdutosResponseProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalResolve> & {
    valueIdProduto: string;
  };

export const ModalAlterarProduto = create<
  ModalAlterarProdutosResponseProps,
  ModalResolve
>(({ onResolve, onReject, valueIdProduto, ...rest }) => {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const handleAlterarProdutoModal = () => {
    onResolve({ sucesso: true });
  };

  const valueMatch = {
    params: {
      id: valueIdProduto,
    },
  } as match<TParams>;

  const valueLocation = {
    state: undefined,
  } as any;

  const AlterarProduto = useMemo(
    () => (
      <PadronizacaoProvider>
        <BrowserRouter>
          <Route
            render={() => (
              <Alterar
                history={{} as any}
                cadastroCancelar={() => {
                  onClose();
                }}
                cadastroExterno
                alterarSucesso={handleAlterarProdutoModal}
                location={valueLocation}
                match={valueMatch}
              />
            )}
          />
        </BrowserRouter>
      </PadronizacaoProvider>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [valueIdProduto]
  );

  return (
    <ModalPadraoChakra size="full" {...rest} isOpen={isOpen} onClose={onClose}>
      <ModalContent bg="gray.50">
        <ModalCloseButton color="white" />
        <ModalHeader
          bg="violet.500"
          color="secondary.300"
          px={{ base: 6, md: 8 }}
          pb="0"
        >
          Alterar Produto
        </ModalHeader>

        <ModalBody p="0">{AlterarProduto}</ModalBody>
      </ModalContent>
    </ModalPadraoChakra>
  );
});
