import axios, { AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';
import auth from 'modules/auth';
import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { LOOKUPLOCALSTORAGE } from 'i18n';

type FailedRequestList = {
  onResolve(token: string): void;
  onReject(error: any): void;
};

const cancelRepeatRequestsMessage = 'Cancel repeated request';

let isMakingTheRequest = false;
let failedRequestList: FailedRequestList[] = [];
export interface ResponseApi<T = unknown> {
  sucesso: boolean;
  avisos: Array<string>;
  tiposAviso: Array<string>;
  erros: Array<string>;
  dados: T;
  status?: number;
}

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    HTTP_REFERER_MULTIEMPRESA: '',
    'Cache-Control': 'no-cache',
  },
});

api.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    try {
      const originalRequest = error.config;
      if (error.response?.status === 401 && !originalRequest.retry) {
        originalRequest.retry = true;

        if (!isMakingTheRequest) {
          isMakingTheRequest = true;

          api
            .put<void, ResponseApi>(
              ConstanteEnderecoWebservice.AUTENTICACAO_REFRESH_TOKEN,
              {
                token: auth.getToken(),
                refreshToken: auth.getRefreshToken(),
                lojaId: auth.getLoja().id,
              }
            )
            .then((res) => {
              if (res.sucesso && res.dados) {
                auth.setRefreshToken(res.dados);

                api.defaults.headers.common.Authorization = `Bearer ${auth.getToken()}`;
                failedRequestList.forEach((req) => {
                  req.onResolve(auth.getToken());
                });
                failedRequestList = [];
              } else {
                // refresh token inválido.
                auth.clearTokenAndRedirect();
              }
            })
            .catch((err) => {
              failedRequestList.forEach((req) => {
                req.onReject(err);
                failedRequestList = [];
              });
            })
            .finally(() => {
              isMakingTheRequest = false;
            });
        }

        return new Promise((success, onReject) => {
          failedRequestList.push({
            onResolve: (token) => {
              api.defaults.headers.common.Authorization = `Bearer ${token}`;
              success(api(originalRequest));
            },
            onReject: (err) => {
              onReject(err);
            },
          });
        });
      }

      if (error.response?.status === 423 && !originalRequest.retry) {
        originalRequest.retry = true;

        auth.clearTokenAndRedirect();

        return {
          data: {
            sucesso: false,
          },
        };
      }

      if (error.response?.status === 405 && !originalRequest.retry) {
        originalRequest.retry = true;
        const isDashboard = window.location.pathname.includes(
          ConstanteRotas.DASHBOARD
        );
        if (!isDashboard) {
          toast.warning(
            'Você não tem permissão para acessar essa função. Consulte o administrador da conta.'
          );
        }

        return api(originalRequest);
      }

      if (error.response?.status === 403) {
        originalRequest.retry = true;
        const response = {
          data: {
            sucesso: false,
            status: 403,
          },
        };
        return response.data;
      }
      if (
        error.response?.status === 400 ||
        (error.response?.status >= 500 && error.response?.status <= 510)
      ) {
        originalRequest.retry = true;
        toast.error('Ocorreu um erro inesperado.', {
          toastId: 'erroInesperadoToast',
        });

        return {
          data: {
            sucesso: false,
          },
        };
      }

      return null;
    } catch (err) {
      if (error?.message !== cancelRepeatRequestsMessage) {
        toast.error('Ocorreu um erro inesperado.', {
          toastId: 'erroInesperadoToast',
        });
      }

      // eslint-disable-next-line no-console
      console.error(err);
      return {
        data: {
          sucesso: false,
        },
      };
    }
  }
);

api.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = auth.getToken();
  const configNew = config;

  const dominio = window.location.host.split('/')[0].toLowerCase();

  configNew.headers['Access-Control-Allow-Origin'] = '*';
  configNew.headers.HTTP_REFERER_MULTIEMPRESA = dominio;
  configNew.headers['timezone-offset'] =
    (new Date().getTimezoneOffset() / 60) * -1;

  if (token) {
    configNew.headers.Authorization = `Bearer ${token}`;
  }

  const loja = auth.getLoja();
  if (loja && loja.id) {
    configNew.headers.lojaid = loja.id;
  }

  const sistema = auth.getSistema();
  if (sistema) {
    configNew.headers.sistema = sistema.name;
  }

  const language = localStorage.getItem(LOOKUPLOCALSTORAGE);

  if (language) {
    configNew.headers.Language = language;
  }

  return configNew;
});

export default api;
