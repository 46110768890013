export type StatusImportacaoConferencia = 1 | 2 | 3 | 4;

const StatusImportacaoConferencia = {
  NAO_INICIADA: 1,
  EM_ANDAMENTO: 2,
  CONCLUIDA: 3,
  FALHA: 4,

  properties: {
    1: { name: 'Não iniciada', value: 1 },
    2: { name: 'Em andamento', value: 2 },
    3: { name: 'Concluída', value: 3 },
    4: { name: 'Falha', value: 4 },
  },
};

export default StatusImportacaoConferencia;
