import React, { useEffect } from 'react';
import { HStack, Button, useMediaQuery, Grid } from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { GlobalHotKeys } from 'react-hotkeys';
import { toast } from 'react-toastify';

import ConstanteRotasPDV from 'constants/rotasPDV';
import { OperacaoItemObter } from 'helpers/api/Operacao/obterOperacaoComItens';
import { SubstituirParametroRota } from 'constants/rotas';
import { useOperacaoContext } from 'store/PDV/Operacao';
import { useInformacoesGeraisContext } from 'store/PDV/InformacoesGerais';
import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';

import CaixaAberto from 'components/PDV/Layout/Footer/CaixaAberto';
import SelecionarVendedor from 'components/PDV/Layout/Footer/SelecionarVendedor';
import { SelecionarLocalEstoque } from 'components/PDV/Layout/Footer/SelecionarLocalDeEstoque';

interface FooterProps {
  handleCancelar: () => void;
}

const Footer = ({ handleCancelar }: FooterProps) => {
  const { watch, getValues } = useFormContext();
  const operacaoItens = watch('operacaoItens') as OperacaoItemObter[];

  const history = useHistory();
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const {
    handleChangeVendedor,
    operacaoId,
    handleChangeLocalEstoque,
  } = useOperacaoContext();

  const {
    locaisDeEstoque,
    localDeEstoqueSelecionado,
    localEstoquePadraoDoUsuario,
    handleSetInformacoesLocalEstoque,
    onChangeOperacaoItem,
  } = useInformacoesGeraisContext();

  const { id: idRouter } = useParams<{ id: string }>();

  const identificacaoTipoOperacao = getValues('identificacaoTipoOperacao');

  useEffect(() => {
    if (operacaoItens) onChangeOperacaoItem(operacaoItens.length);
  }, [operacaoItens, onChangeOperacaoItem]);

  const handlePagamento = async () => {
    const listOfOperations = (await watch(
      'operacaoItens'
    )) as OperacaoItemObter[];
    const validandoExistenciaProdutoUnitarioZerado = listOfOperations.filter(
      (valor) => valor.valorUnitario === 0
    );
    const existeProdutoValorUnitarioZerado =
      validandoExistenciaProdutoUnitarioZerado.length > 0;

    if (listOfOperations?.length > 0) {
      if (!existeProdutoValorUnitarioZerado) {
        if (
          identificacaoTipoOperacao ===
          IdentificacaoTipoOperacaoEnum.CONSIGNACAO
        ) {
          history.push(
            SubstituirParametroRota(
              ConstanteRotasPDV.PDV_FINALIZAR,
              'id',
              operacaoId as string
            )
          );
        } else {
          history.push(
            SubstituirParametroRota(
              ConstanteRotasPDV.PDV_PAGAMENTO,
              'id',
              operacaoId as string
            )
          );
        }
      } else {
        toast.warning('Não pode existir um produto com o valor zerado');
      }
    }
  };

  const keyMap = {
    F9: ['f9'],
  };

  const handlersHotKeys = {
    F9: (event: any) => {
      if (idRouter) {
        event.preventDefault();
        handlePagamento();
      }
    },
  };

  return (
    <>
      <GlobalHotKeys keyMap={keyMap} handlers={handlersHotKeys} />
      <HStack
        spacing={6}
        justifyContent="space-between"
        h="full"
        px={{ base: 6, lg: 12 }}
      >
        <Grid
          templateColumns={isLargerThan900 ? 'auto 1fr' : '1fr'}
          w="full"
          gap="2"
        >
          {isLargerThan900 && (
            <HStack spacing={{ base: 8, lg: 16 }} align="center" w="full">
              <CaixaAberto />
              <SelecionarVendedor
                name="vendedor"
                onSubmit={(newVendedor) => {
                  handleChangeVendedor(newVendedor);
                }}
              />
              {localEstoquePadraoDoUsuario.current.id !==
                localDeEstoqueSelecionado.id && (
                <SelecionarLocalEstoque
                  localDeEstoqueSelecionado={localDeEstoqueSelecionado}
                  isDisabled={operacaoItens?.length > 0}
                  listarEstoques={locaisDeEstoque}
                  onSubmit={async (localEstoque) => {
                    if (operacaoId) {
                      await handleChangeLocalEstoque(localEstoque.id);
                    }
                    handleSetInformacoesLocalEstoque(localEstoque);
                  }}
                />
              )}
            </HStack>
          )}

          <HStack
            spacing={{ base: 4, lg: 6 }}
            justifyContent={{ base: 'center', md: 'flex-end' }}
            w="full"
          >
            {isLargerThan900 && (
              <Button
                variant="cancel"
                size="sm"
                maxW="100px"
                minW="75px"
                w="full"
                colorScheme="gray"
                onClick={handleCancelar}
              >
                Cancelar
              </Button>
            )}
            <Button
              colorScheme="secondary"
              size={isLargerThan900 ? 'sm' : 'lg'}
              maxW="140px"
              minW="100px"
              disabled={!operacaoItens || operacaoItens?.length <= 0}
              w="full"
              bg="secondary.400"
              onClick={handlePagamento}
              _disabled={{
                opacity: 1,
                bg: 'pdv.textColor',
                border: 'none',
                color: 'pdv.buttonPagamento',
              }}
              _hover={{
                bg: 'secondary.300',
                _disabled: { bg: 'pdv.textColor' },
              }}
            >
              {identificacaoTipoOperacao ===
              IdentificacaoTipoOperacaoEnum.CONSIGNACAO
                ? 'Finalizar'
                : 'Pagamento'}
            </Button>
          </HStack>
        </Grid>
      </HStack>
    </>
  );
};

export default Footer;
