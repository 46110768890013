import React from 'react';
import {
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
} from '@chakra-ui/react';

import CampoPrototipo, {
  CampoPrototipoProps,
} from 'components/PDV/Geral/CampoPrototipo';

export type CheckboxProps = Omit<
  ChakraCheckboxProps,
  'ref' | 'variant' | 'colorScheme'
> &
  CampoPrototipoProps;

export function Checkbox({
  id,
  name,
  label,
  helperText,
  colSpan,
  colStart,
  colEnd,
  rowStart,
  rowEnd,
  rowSpan,
  w,
  maxW,
  minW,
  isRequired,
  isDisabled,
  size,
  onChange,
  ...rest
}: CheckboxProps) {
  return (
    <CampoPrototipo
      name={name}
      isRequired={isRequired}
      isDisabled={isDisabled}
      helperText={helperText}
      id={id}
      label={label}
      rowSpan={rowSpan}
      rowStart={rowStart}
      rowEnd={rowEnd}
      colSpan={colSpan}
      colStart={colStart}
      colEnd={colEnd}
      w={w}
    >
      {(_, { value, onChange: fieldOnChange, ...fieldRest }) => (
        <ChakraCheckbox
          isChecked={!!value}
          colorScheme="primary"
          onChange={(e) => {
            fieldOnChange(e);

            if (onChange) {
              onChange(e);
            }
          }}
          lineHeight="none"
          size={size}
          id={id}
          {...fieldRest}
          {...rest}
        />
      )}
    </CampoPrototipo>
  );
}
