import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import isPrenvent from 'helpers/layout/isPrenvent';
import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import PerfilUsuarioPersonalizadoOpcaoConstante from 'constants/perfilUsuarioPersonalizadoOpcao';
import FormularioUsuariosProvider from 'store/FormularioUsuarios';
import listarCategoriaPermissoes from 'helpers/api/PerfilUsuario/listarCategoriaPermissoes';

import { CategoriaPermissaoInterface } from 'components/Permissoes';
import { ContainerListagem } from 'components/Layout/Listagem/containerListagem';

import { LojasProps } from '../Types';
import { useForm, yupResolver } from '../validationForm';
import UncontrolledForm, { UncontrolledFormRefInterface } from '..';

type TParams = { id: string };

const Alterar = () => {
  const history = useHistory();
  const isMountedRef = useIsMountedRef();
  isPrenvent();

  const formMethods = useForm({
    resolver: yupResolver,
    shouldUnregister: false,
  });

  const { reset, formState, setValue } = formMethods;

  const { id: idRota } = useParams<TParams>();

  const [isLoading, setIsLoading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [dataHoraCadastro, setDataHoraCadastro] = useState('');
  const [dataHoraUltimaAlteracao, setDataHoraUltimaAlteracao] = useState('');
  const [usuarioId, setUsuarioId] = useState('');
  const [categoriasPermissao, setCategoriasPermissao] = useState<
    CategoriaPermissaoInterface[]
  >([]);

  const lojasControlledListRef = useRef<UncontrolledFormRefInterface>();

  const latestProps = useRef({
    history,
    isMountedRef,
    matchId: idRota,
    reset,
  });
  useEffect(() => {
    latestProps.current = {
      history,
      isMountedRef,
      matchId: idRota,
      reset,
    };
  });

  useLayoutEffect(() => {
    const getUsuario = async () => {
      setIsLoading(true);

      const response = await api.get<void, ResponseApi<any>>(
        ConstanteEnderecoWebservice.USUARIO_OBTER,
        {
          params: { id: latestProps.current.matchId },
        }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }

        if (response.sucesso && latestProps.current.isMountedRef.current) {
          const newCategoriasPermissao = await listarCategoriaPermissoes();

          const {
            id,
            dataHoraCadastro: newDataHoraCadastro,
            dataHoraUltimaAlteracao: newDataHoraUltimaAlteracao,
            perfilOpcaoSelect,
            grupos,
            permissoes,
            dataNascimento,
            lojaPadraoId,
            ...defaultValues
          } = response.dados;

          setUsuarioId(id);
          setDataHoraCadastro(newDataHoraCadastro);
          setDataHoraUltimaAlteracao(newDataHoraUltimaAlteracao);

          defaultValues.perfilusuario =
            perfilOpcaoSelect?.id ||
            PerfilUsuarioPersonalizadoOpcaoConstante.value;

          defaultValues.dataNascimento = dataNascimento?.split('T')[0];
          defaultValues.permissoes = []; // apenas inicializa o array

          if (newCategoriasPermissao) {
            newCategoriasPermissao.forEach((categoria) => {
              categoria.grupos.forEach((grupo) => {
                let todoSelecionados = true;

                grupo.permissoes.forEach((permissao) => {
                  const permissaoValue = permissoes.find(
                    (p: any) => p.id === permissao.id
                  );

                  if (permissaoValue) {
                    defaultValues.permissoes[permissao.index] =
                      permissaoValue.selecionado;

                    if (!permissaoValue.selecionado) {
                      todoSelecionados = false;
                    }
                  } else {
                    todoSelecionados = false;
                  }
                });

                defaultValues[`grupo${grupo.id}`] = todoSelecionados;
              });
            });
          }

          setCategoriasPermissao(newCategoriasPermissao || []);
          latestProps.current.reset({
            ...defaultValues,
            valueIdLojaPadrao: lojaPadraoId,
            pinCadastrado: !!defaultValues.pinUsuario,
          });

          defaultValues.lojas.forEach((loja: LojasProps) => {
            setValue(`lojaSelecionada.${loja.id}`, loja.selecionado);
            setValue(`localEstoqueId.${loja.id}`, loja.localEstoqueId);
            setValue(`vendedorId.${loja.id}`, loja.vendedorId);
          });
        } else {
          latestProps.current.history.push(ConstanteRotas.USUARIO);
        }
      }

      if (latestProps.current.isMountedRef.current) setIsLoading(false);
    };

    setIsLoading(true);

    getUsuario();
  }, [setValue]);

  useEffect(() => {
    setFormIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  useEffect(() => {
    reset({
      descricao: '',
      ativo: true,
      permissoes: [],
    });
  }, [reset]);

  return (
    <ContainerListagem
      maxWidth="full"
      formMethods={formMethods}
      isLoading={isLoading}
      formIsDirty={formIsDirty}
      dataHoraUltimaAlteracao={dataHoraUltimaAlteracao}
      dataHoraCadastro={dataHoraCadastro}
      containerPossuiFundo={false}
      isVisualizar
    >
      <FormularioUsuariosProvider categorias={categoriasPermissao}>
        <UncontrolledForm
          isLoading={isLoading}
          ref={lojasControlledListRef}
          usuarioId={usuarioId}
          isAlteracao
          readonly
        />
      </FormularioUsuariosProvider>
    </ContainerListagem>
  );
};

export default Alterar;
