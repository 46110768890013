type ShallowEqualObject = Record<string, any>;

export function shallowEqual(
  object1: ShallowEqualObject,
  object2: ShallowEqualObject
): boolean {
  if (object1 === object2) {
    return true;
  }

  if (!object1 || !object2) {
    return false;
  }

  const keys1 = Object.keys(object1).sort();
  const keys2 = Object.keys(object2).sort();

  if (keys1.length !== keys2.length) {
    return false;
  }

  const isEqual = keys1.every((key) => {
    let object1Value = object1[key];

    if (object1Value instanceof Date) {
      object1Value = object1Value.toDateString();
    }

    let object2Value = object2[key];

    if (object2Value instanceof Date) {
      object2Value = object2Value.toDateString();
    }

    return object1Value === object2Value;
  });

  return isEqual;
}
