import StatusOperacaoEnum from 'constants/enum/statusOperacao';
import { setDateMaxHours, setDateMinHours } from 'helpers/data/setHoursDate';
import { TipoLancamentoEnum } from 'constants/enum/tipoLancamento';

type FornecedorId = {
  value: string;
  label: string;
};

export type FormData = {
  dataEmissaoInicio?: Date;
  dataEmissaoFim?: Date;
  dataEntradaInicio: Date;
  dataEntradaFim: Date;
  fornecedorId: FornecedorId | string;
  numeroNFe: string;
  dataHoraNFe?: Date;
  identificador: number | null;
  finalidade: number | null;
  situacao: number;
  statusLancamentoEstoque: number;
  statusLancamentoFinanceiro: number;
};

export const formDefaultValues = {
  dataEntradaInicio: setDateMinHours(new Date()),
  dataEntradaFim: setDateMaxHours(new Date()),
  fornecedorId: '',
  numeroNFe: '',
  dataHoraNFe: undefined,
  identificador: null,
  finalidade: null,
  situacao: StatusOperacaoEnum.EFETUADA,
  statusLancamentoEstoque: TipoLancamentoEnum.TODOS,
  statusLancamentoFinanceiro: TipoLancamentoEnum.TODOS,
};
