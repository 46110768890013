import { Flex, Text } from '@chakra-ui/react';
import QRCode from 'qrcode.react';

type QrCodePixProps = {
  qrCode: string;
  isLargerThan765: boolean;
};

export const QrCodePix = ({ qrCode, isLargerThan765 }: QrCodePixProps) => (
  <Flex flexDir="column" align="center">
    <Text mb="16px" fontSize="lg" color="white">
      Escaneie o
      <Text as="span" fontWeight="extrabold">
        {' '}
        QR Code{' '}
      </Text>
      para pagar com Pix:
    </Text>
    <Flex
      alignItems="center"
      justifyContent="center"
      bg="white"
      boxSize={isLargerThan765 ? '365px' : '265px'}
      borderRadius="12px"
      flexDir="column"
    >
      <QRCode value={qrCode} size={isLargerThan765 ? 280 : 200} />
    </Flex>
  </Flex>
);
