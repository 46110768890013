import { IconType } from 'icons';
import GenIcon from 'icons/GenIcon';

export const PowerStockPDVLogo: IconType = ({ size, color, ...props }) => (
  <GenIcon viewBox="0 0 146.2 22" size={size} {...props}>
    <g style={{ fill: color || 'currentcolor' }}>
      <g>
        <polygon fill="#fcd27c" points=".2 9.3 2.1 7.9 2.8 10.2 .2 13 .2 9.3" />
        <polygon fill="#f69f56" points="4.6 6 5.5 7.3 2.8 10.2 2.1 7.9 4.6 6" />
        <polygon fill="#ea3b5a" points="11.9 .2 2.8 3.6 5.5 7.3 11.9 .2" />
        <polygon fill="#f26c3f" points="2.1 7.9 1 4.1 2.8 3.6 4.6 6 2.1 7.9" />
        <polygon fill="#f6905b" points=".2 4.2 1 4.1 2.1 7.9 .2 9.3 .2 4.2" />
        <polygon
          fill="#d71572"
          points=".2 .2 11.9 .2 2.8 3.6 1 4.1 .2 4.2 .2 .2"
        />
        <polygon fill="#2f51a0" points="12.5 9 13.1 8.3 17.8 7 21.6 9 12.5 9" />
        <polygon fill="#3878bb" points="21.8 5.8 20.9 6.2 21.8 8 21.8 5.8" />
        <polygon
          fill="#52b8e9"
          points="21.8 3.5 21.8 5.9 20.9 6.2 20 4.5 21.8 3.5"
        />
        <polygon
          fill="#91d5ec"
          points="21.8 1.2 21.8 3.5 20 4.5 19.3 3.2 21.8 1.2"
        />
        <polygon
          fill="#5c95b4"
          points="21.8 .2 21.8 1.2 19.3 3.2 19.1 2.8 21.8 .2"
        />
        <polygon fill="#3863ac" points="17.8 7 16.7 6.5 13.5 8.2 17.8 7" />
        <polygon
          fill="#428ecc"
          points="20 4.5 16.7 6.5 18 7.1 20.9 6.2 20 4.5"
        />
        <polygon
          fill="#459fd8"
          points="19.3 3.2 15.9 6.1 16.7 6.5 20 4.5 19.3 3.2"
        />
        <polygon
          fill="#3766ae"
          points="20.9 6.2 21.8 8 21.8 9 17.8 7 20.9 6.2"
        />
        <polygon fill="#4c76a6" points="19.1 2.8 19.3 3.2 13.1 8.3 19.1 2.8" />
        <polygon fill="#149ba8" points=".2 13 3.9 15 6.9 13.9 .2 13" />
        <polygon fill="#005272" points="8.4 14.1 .2 13 9.5 13 8.4 14.1" />
        <polygon fill="#5dc1a8" points=".2 16.5 3.9 15 .2 13 .2 16.5" />
        <polygon
          fill="#129095"
          points="3.9 15 6.2 16.2 8.4 14.1 6.9 13.9 3.9 15"
        />
        <polygon
          fill="#46286a"
          points="4.3 21.8 14.3 15.1 13.9 14.7 4.3 21.8"
        />
        <polygon
          fill="#77308e"
          points="17.7 11.8 18.1 12.6 14.3 15.1 13.8 14.7 17.7 11.8"
        />
        <polygon
          fill="#9c5ba3"
          points="21.8 9 21.8 10 18.1 12.6 17.7 11.8 21.8 9"
        />
        <polygon
          fill="#bc7eb5"
          points="21.8 12.8 18.9 14.1 18.1 12.6 21.8 10 21.8 12.8"
        />
        <polygon
          fill="#7e529f"
          points="18 18.5 20.6 18.1 18.6 13.7 15 15.8 18 18.5"
        />
        <polygon
          fill="#9359a3"
          points="21.8 12.1 21.8 21.8 20.6 18.1 18.7 13.7 21.8 12.1"
        />
        <polygon
          fill="#77469a"
          points="15 15.8 18.7 13.7 18.1 12.6 4.3 21.8 15 15.8"
        />
        <polygon fill="#4a2e84" points="18 18.5 4.3 21.8 21.8 21.8 18 18.5" />
        <polygon fill="#6b4599" points="15 15.8 18 18.5 4.3 21.8 15 15.8" />
        <polygon fill="#ec355e" points=".2 .2 2.8 3.6 1 4.2 .2 4.2 .2 .2" />
        <polygon
          fill="#b2daab"
          points=".2 18.4 2.1 17.3 2.7 19.4 .2 21.8 .2 18.4"
        />
        <polygon
          fill="#66c3a9"
          points=".2 16.4 .2 18.4 2.1 17.3 1.6 15.9 .2 16.4"
        />
        <polygon
          fill="#66c2a2"
          points="5 15.6 2.1 17.3 2.7 19.4 6.2 16.2 5 15.6"
        />
        <polygon
          fill="#49beae"
          points="3.9 15 5 15.6 2.1 17.3 1.6 15.9 3.9 15"
        />
        <polygon fill="#f05b5d" points="1 4.2 .2 1.7 .2 4.5 1 4.2" />
        <polygon
          fill="#3473b6"
          points="16.7 6.5 15.9 6.1 13.1 8.3 13.5 8.2 16.7 6.5"
        />
        <polygon fill="#28418a" points="15.9 6.1 15.7 5.9 13.1 8.3 15.9 6.1" />
        <polygon
          fill="#694a9c"
          points="21.8 21.8 18 18.5 20.5 17.8 21.8 20.7 21.8 21.8"
        />
        <polygon
          fill="#5e4698"
          points="20.5 17.8 21.8 17.6 21.8 20.7 20.5 17.8"
        />
        <polygon
          fill="#9e5fa5"
          points="14.3 15.1 15 15.8 18.7 13.7 18.1 12.6 14.3 15.1"
        />
        <polygon
          fill="#1ea3a2"
          points="5 15.6 7.7 14 8.4 14.1 6.2 16.2 5 15.6"
        />
        <polygon fill="#00687e" points="9.5 13 8.4 14.1 7.7 14 9.2 13 9.5 13" />
        <polygon fill="#224b63" points="6.9 13.9 9 13 9.3 13 7.7 14 6.9 13.9" />
        <polygon
          fill="#7fc9a6"
          points="1.6 15.9 .7 13.3 .2 13 .2 16.4 1.6 15.9"
        />
        <polygon fill="#ee5a55" points="4.6 6 11.9 .2 5.5 7.3 4.6 6" />
        <polygon
          fill="#fff"
          points="11.9 .2 .2 13 9.5 13 .2 21.8 4.3 21.8 21.8 9 12.5 9 21.8 .2 11.9 .2"
        />
      </g>
      <g>
        <path
          fill="#202020"
          d="M39.9,8.8c0,3.3-2.5,4.9-6.1,4.9h-1.7v4.8h-3.6V4.1h5c4,0,6.4,1.5,6.4,4.7Zm-3.7,0c0-1.5-.9-2.3-2.7-2.3h-1.3v4.7h1.4c1.6,0,2.6-.6,2.6-2.5Z"
        />
        <path
          fill="#202020"
          d="M55.1,11.3c0,4.7-2.6,7.6-6.9,7.6s-6.9-2.8-6.9-7.6,2.6-7.5,6.9-7.5,6.9,2.7,6.9,7.5Zm-10,0c0,3.7,1.1,5.1,3.1,5.1s3.1-1.4,3.1-5.1-1.1-5-3.1-5-3.1,1.4-3.1,5Z"
        />
        <path
          fill="#202020"
          d="M71.8,18.5h-4.7l-1.9-11-2,11h-4.6l-2.5-14.4h3.6l1.5,11.8,2.1-11.8h3.8l2,11.8,1.8-11.8h3.5l-2.6,14.4Z"
        />
        <path
          fill="#202020"
          d="M85.3,6.5h-5.4v3.4h4.7v2.4h-4.7v3.7h5.8v2.5h-9.4V4.1h9.4l-.4,2.4Z"
        />
        <path
          fill="#202020"
          d="M93.2,13h-1.4v5.5h-3.6V4.1h5c4.2,0,6.3,1.4,6.3,4.4s-1,3-2.9,3.9l3.9,6.1h-4.1l-3.1-5.5Zm-1.4-2.3h1.5c1.6,0,2.4-.6,2.4-2.2s-.9-2-2.7-2h-1.3v4.2Z"
        />
        <path
          fill="#202020"
          d="M118.8,8.6c0,3.3-2.5,4.6-5.8,4.6h-2v5.3h-1.9V4.2h3.9c3.6,0,5.8,1.4,5.8,4.4Zm-2,0c0-2.2-1.5-3-3.8-3h-2v6.1h1.9c2.3,0,3.9-.7,3.9-3.1Z"
        />
        <path
          fill="#202020"
          d="M132.7,11.3c0,5.9-3.9,7.2-7.1,7.2h-3.5V4.2h3.1c3.8,0,7.5,1.1,7.5,7.1Zm-8.8-5.6v11.4h1.8c2.4,0,4.9-1,4.9-5.8s-2.6-5.6-5.1-5.6h-1.6Z"
        />
        <path
          fill="#202020"
          d="M141.1,18.5h-2.1l-4.9-14.3h2.1l3.9,12.4,3.9-12.4h1.9l-4.9,14.3Z"
        />
      </g>
    </g>
  </GenIcon>
);

export const PowerStockFullLogo: IconType = ({ size, color, ...props }) => (
  <GenIcon viewBox="0 0 164.6 22" size={size} {...props}>
    <g>
      <polygon fill="#fcd27c" points=".2 9.3 2.1 7.9 2.8 10.2 .2 13 .2 9.3" />
      <polygon fill="#f69f56" points="4.6 6 5.5 7.3 2.8 10.2 2.1 7.9 4.6 6" />
      <polygon fill="#ea3b5a" points="11.9 .2 2.8 3.6 5.5 7.3 11.9 .2" />
      <polygon fill="#f26c3f" points="2.1 7.9 1 4.1 2.8 3.6 4.6 6 2.1 7.9" />
      <polygon fill="#f6905b" points=".2 4.2 1 4.1 2.1 7.9 .2 9.3 .2 4.2" />
      <polygon
        fill="#d71572"
        points=".2 .2 11.9 .2 2.8 3.6 1 4.1 .2 4.2 .2 .2"
      />
      <polygon fill="#2f51a0" points="12.5 9 13.1 8.3 17.8 7 21.6 9 12.5 9" />
      <polygon fill="#3878bb" points="21.8 5.8 20.9 6.2 21.8 8 21.8 5.8" />
      <polygon
        fill="#52b8e9"
        points="21.8 3.5 21.8 5.9 20.9 6.2 20 4.5 21.8 3.5"
      />
      <polygon
        fill="#91d5ec"
        points="21.8 1.2 21.8 3.5 20 4.5 19.3 3.2 21.8 1.2"
      />
      <polygon
        fill="#5c95b4"
        points="21.8 .2 21.8 1.2 19.3 3.2 19.1 2.8 21.8 .2"
      />
      <polygon fill="#3863ac" points="17.8 7 16.7 6.5 13.5 8.2 17.8 7" />
      <polygon fill="#428ecc" points="20 4.5 16.7 6.5 18 7.1 20.9 6.2 20 4.5" />
      <polygon
        fill="#459fd8"
        points="19.3 3.2 15.9 6.1 16.7 6.5 20 4.5 19.3 3.2"
      />
      <polygon fill="#3766ae" points="20.9 6.2 21.8 8 21.8 9 17.8 7 20.9 6.2" />
      <polygon fill="#4c76a6" points="19.1 2.8 19.3 3.2 13.1 8.3 19.1 2.8" />
      <polygon fill="#149ba8" points=".2 13 3.9 15 6.9 13.9 .2 13" />
      <polygon fill="#005272" points="8.4 14.1 .2 13 9.5 13 8.4 14.1" />
      <polygon fill="#5dc1a8" points=".2 16.5 3.9 15 .2 13 .2 16.5" />
      <polygon
        fill="#129095"
        points="3.9 15 6.2 16.2 8.4 14.1 6.9 13.9 3.9 15"
      />
      <polygon fill="#46286a" points="4.3 21.8 14.3 15.1 13.9 14.7 4.3 21.8" />
      <polygon
        fill="#77308e"
        points="17.7 11.8 18.1 12.6 14.3 15.1 13.8 14.7 17.7 11.8"
      />
      <polygon
        fill="#9c5ba3"
        points="21.8 9 21.8 10 18.1 12.6 17.7 11.8 21.8 9"
      />
      <polygon
        fill="#bc7eb5"
        points="21.8 12.8 18.9 14.1 18.1 12.6 21.8 10 21.8 12.8"
      />
      <polygon
        fill="#7e529f"
        points="18 18.5 20.6 18.1 18.6 13.7 15 15.8 18 18.5"
      />
      <polygon
        fill="#9359a3"
        points="21.8 12.1 21.8 21.8 20.6 18.1 18.7 13.7 21.8 12.1"
      />
      <polygon
        fill="#77469a"
        points="15 15.8 18.7 13.7 18.1 12.6 4.3 21.8 15 15.8"
      />
      <polygon fill="#4a2e84" points="18 18.5 4.3 21.8 21.8 21.8 18 18.5" />
      <polygon fill="#6b4599" points="15 15.8 18 18.5 4.3 21.8 15 15.8" />
      <polygon fill="#ec355e" points=".2 .2 2.8 3.6 1 4.2 .2 4.2 .2 .2" />
      <polygon
        fill="#b2daab"
        points=".2 18.4 2.1 17.3 2.7 19.4 .2 21.8 .2 18.4"
      />
      <polygon
        fill="#66c3a9"
        points=".2 16.4 .2 18.4 2.1 17.3 1.6 15.9 .2 16.4"
      />
      <polygon
        fill="#66c2a2"
        points="5 15.6 2.1 17.3 2.7 19.4 6.2 16.2 5 15.6"
      />
      <polygon fill="#49beae" points="3.9 15 5 15.6 2.1 17.3 1.6 15.9 3.9 15" />
      <polygon fill="#f05b5d" points="1 4.2 .2 1.7 .2 4.5 1 4.2" />
      <polygon
        fill="#3473b6"
        points="16.7 6.5 15.9 6.1 13.1 8.3 13.5 8.2 16.7 6.5"
      />
      <polygon fill="#28418a" points="15.9 6.1 15.7 5.9 13.1 8.3 15.9 6.1" />
      <polygon
        fill="#694a9c"
        points="21.8 21.8 18 18.5 20.5 17.8 21.8 20.7 21.8 21.8"
      />
      <polygon
        fill="#5e4698"
        points="20.5 17.8 21.8 17.6 21.8 20.7 20.5 17.8"
      />
      <polygon
        fill="#9e5fa5"
        points="14.3 15.1 15 15.8 18.7 13.7 18.1 12.6 14.3 15.1"
      />
      <polygon fill="#1ea3a2" points="5 15.6 7.7 14 8.4 14.1 6.2 16.2 5 15.6" />
      <polygon fill="#00687e" points="9.5 13 8.4 14.1 7.7 14 9.2 13 9.5 13" />
      <polygon fill="#224b63" points="6.9 13.9 9 13 9.3 13 7.7 14 6.9 13.9" />
      <polygon
        fill="#7fc9a6"
        points="1.6 15.9 .7 13.3 .2 13 .2 16.4 1.6 15.9"
      />
      <polygon fill="#ee5a55" points="4.6 6 11.9 .2 5.5 7.3 4.6 6" />
      <polygon
        fill="#fff"
        points="11.9 .2 .2 13 9.5 13 .2 21.8 4.3 21.8 21.8 9 12.5 9 21.8 .2 11.9 .2"
      />
    </g>
    <g>
      <path
        fill="#fff"
        d="M39.9,8.8c0,3.3-2.5,4.9-6.1,4.9h-1.7v4.8h-3.6V4.2h5c4,0,6.4,1.5,6.4,4.7Zm-3.7,0c0-1.5-.9-2.3-2.7-2.3h-1.3v4.7h1.4c1.6,0,2.6-.6,2.6-2.4Z"
      />
      <path
        fill="#fff"
        d="M55.1,11.3c0,4.7-2.6,7.5-6.9,7.5s-6.9-2.8-6.9-7.5,2.6-7.5,6.9-7.5,6.9,2.7,6.9,7.5Zm-10,0c0,3.6,1.1,5,3.1,5s3.1-1.4,3.1-5-1.1-5-3.1-5-3.1,1.4-3.1,5Z"
      />
      <path
        fill="#fff"
        d="M71.8,18.5h-4.7l-1.9-10.9-2,10.9h-4.6l-2.5-14.3h3.6l1.5,11.8,2.1-11.8h3.8l2,11.8,1.8-11.8h3.5l-2.6,14.3Z"
      />
      <path
        fill="#fff"
        d="M85.3,6.6h-5.4v3.4h4.7v2.4h-4.7v3.7h5.8v2.4h-9.4V4.2h9.4l-.4,2.4Z"
      />
      <path
        fill="#fff"
        d="M93.2,13h-1.4v5.5h-3.6V4.2h5c4.2,0,6.3,1.4,6.3,4.4s-1,3-2.9,3.9l3.9,6.1h-4.1l-3.1-5.5Zm-1.4-2.3h1.5c1.6,0,2.4-.6,2.4-2.2s-.9-2-2.7-2h-1.3v4.1Z"
      />
      <path
        fill="#fff"
        d="M111.2,5.6l-1,1.1c-1.2-.9-2.2-1.2-3.5-1.2s-2.8,.7-2.8,2.1,.6,1.9,3.4,2.6c2.5,.7,4.5,1.6,4.5,4.3s-2,4.2-5.3,4.2-3.9-.7-5.1-1.8l1.1-1.1c1.1,.9,2.3,1.5,4,1.5s3.4-.9,3.4-2.6-.8-2.1-3.4-2.9c-3-.8-4.5-1.9-4.5-4.1s1.9-3.7,4.7-3.7,3.4,.6,4.6,1.6Z"
      />
      <path
        fill="#fff"
        d="M123.5,5.8h-4.3v12.7h-1.9V5.8h-4.4v-1.5h10.8l-.2,1.5Z"
      />
      <path
        fill="#fff"
        d="M137.5,11.4c0,4.7-2.6,7.3-6.4,7.3s-6.4-2.6-6.4-7.3,2.6-7.4,6.4-7.4,6.4,2.6,6.4,7.4Zm-10.7,0c0,4.1,1.8,5.8,4.3,5.8s4.3-1.7,4.3-5.9-1.7-5.9-4.3-5.9-4.3,1.7-4.3,5.9Z"
      />
      <path
        fill="#fff"
        d="M150.8,5.4l-1,1.2c-1-.7-1.9-1.1-3-1.1-2.4,0-4.4,1.5-4.4,5.9s1.9,5.8,4.3,5.8,2.4-.6,3.3-1.2l1,1.2c-.8,.8-2.3,1.6-4.3,1.6-3.7,0-6.3-2.6-6.3-7.4s2.8-7.4,6.3-7.4,2.9,.5,4.1,1.4Z"
      />
      <path
        fill="#fff"
        d="M155.8,18.5h-1.9V4.3h1.9v14.2Zm2.3-7.7l6.3,7.7h-2.4l-6.2-7.6,5.9-6.6h2.2l-5.9,6.5Z"
      />
    </g>
  </GenIcon>
);
export const PowerStockSymbol: IconType = ({ size, color, ...props }) => (
  <GenIcon viewBox="0 0 24 16" size={size} {...props}>
    <g>
      <polygon fill="#fcd27c" points=".2 9.3 2.1 7.9 2.8 10.2 .2 13 .2 9.3" />
      <polygon fill="#f69f56" points="4.6 6 5.5 7.3 2.8 10.2 2.1 7.9 4.6 6" />
      <polygon fill="#ea3b5a" points="11.9 .2 2.8 3.6 5.5 7.3 11.9 .2" />
      <polygon fill="#f26c3f" points="2.1 7.9 1 4.1 2.8 3.6 4.6 6 2.1 7.9" />
      <polygon fill="#f6905b" points=".2 4.2 1 4.1 2.1 7.9 .2 9.3 .2 4.2" />
      <polygon
        fill="#d71572"
        points=".2 .2 11.9 .2 2.8 3.6 1 4.1 .2 4.2 .2 .2"
      />
      <polygon fill="#2f51a0" points="12.5 9 13.1 8.3 17.8 7 21.6 9 12.5 9" />
      <polygon fill="#3878bb" points="21.8 5.8 20.9 6.2 21.8 8 21.8 5.8" />
      <polygon
        fill="#52b8e9"
        points="21.8 3.5 21.8 5.9 20.9 6.2 20 4.5 21.8 3.5"
      />
      <polygon
        fill="#91d5ec"
        points="21.8 1.2 21.8 3.5 20 4.5 19.3 3.2 21.8 1.2"
      />
      <polygon
        fill="#5c95b4"
        points="21.8 .2 21.8 1.2 19.3 3.2 19.1 2.8 21.8 .2"
      />
      <polygon fill="#3863ac" points="17.8 7 16.7 6.5 13.5 8.2 17.8 7" />
      <polygon fill="#428ecc" points="20 4.5 16.7 6.5 18 7.1 20.9 6.2 20 4.5" />
      <polygon
        fill="#459fd8"
        points="19.3 3.2 15.9 6.1 16.7 6.5 20 4.5 19.3 3.2"
      />
      <polygon fill="#3766ae" points="20.9 6.2 21.8 8 21.8 9 17.8 7 20.9 6.2" />
      <polygon fill="#4c76a6" points="19.1 2.8 19.3 3.2 13.1 8.3 19.1 2.8" />
      <polygon fill="#149ba8" points=".2 13 3.9 15 6.9 13.9 .2 13" />
      <polygon fill="#005272" points="8.4 14.1 .2 13 9.5 13 8.4 14.1" />
      <polygon fill="#5dc1a8" points=".2 16.5 3.9 15 .2 13 .2 16.5" />
      <polygon
        fill="#129095"
        points="3.9 15 6.2 16.2 8.4 14.1 6.9 13.9 3.9 15"
      />
      <polygon fill="#46286a" points="4.3 21.8 14.3 15.1 13.9 14.7 4.3 21.8" />
      <polygon
        fill="#77308e"
        points="17.7 11.8 18.1 12.6 14.3 15.1 13.8 14.7 17.7 11.8"
      />
      <polygon
        fill="#9c5ba3"
        points="21.8 9 21.8 10 18.1 12.6 17.7 11.8 21.8 9"
      />
      <polygon
        fill="#bc7eb5"
        points="21.8 12.8 18.9 14.1 18.1 12.6 21.8 10 21.8 12.8"
      />
      <polygon
        fill="#7e529f"
        points="18 18.5 20.6 18.1 18.6 13.7 15 15.8 18 18.5"
      />
      <polygon
        fill="#9359a3"
        points="21.8 12.1 21.8 21.8 20.6 18.1 18.7 13.7 21.8 12.1"
      />
      <polygon
        fill="#77469a"
        points="15 15.8 18.7 13.7 18.1 12.6 4.3 21.8 15 15.8"
      />
      <polygon fill="#4a2e84" points="18 18.5 4.3 21.8 21.8 21.8 18 18.5" />
      <polygon fill="#6b4599" points="15 15.8 18 18.5 4.3 21.8 15 15.8" />
      <polygon fill="#ec355e" points=".2 .2 2.8 3.6 1 4.2 .2 4.2 .2 .2" />
      <polygon
        fill="#b2daab"
        points=".2 18.4 2.1 17.3 2.7 19.4 .2 21.8 .2 18.4"
      />
      <polygon
        fill="#66c3a9"
        points=".2 16.4 .2 18.4 2.1 17.3 1.6 15.9 .2 16.4"
      />
      <polygon
        fill="#66c2a2"
        points="5 15.6 2.1 17.3 2.7 19.4 6.2 16.2 5 15.6"
      />
      <polygon fill="#49beae" points="3.9 15 5 15.6 2.1 17.3 1.6 15.9 3.9 15" />
      <polygon fill="#f05b5d" points="1 4.2 .2 1.7 .2 4.5 1 4.2" />
      <polygon
        fill="#3473b6"
        points="16.7 6.5 15.9 6.1 13.1 8.3 13.5 8.2 16.7 6.5"
      />
      <polygon fill="#28418a" points="15.9 6.1 15.7 5.9 13.1 8.3 15.9 6.1" />
      <polygon
        fill="#694a9c"
        points="21.8 21.8 18 18.5 20.5 17.8 21.8 20.7 21.8 21.8"
      />
      <polygon
        fill="#5e4698"
        points="20.5 17.8 21.8 17.6 21.8 20.7 20.5 17.8"
      />
      <polygon
        fill="#9e5fa5"
        points="14.3 15.1 15 15.8 18.7 13.7 18.1 12.6 14.3 15.1"
      />
      <polygon fill="#1ea3a2" points="5 15.6 7.7 14 8.4 14.1 6.2 16.2 5 15.6" />
      <polygon fill="#00687e" points="9.5 13 8.4 14.1 7.7 14 9.2 13 9.5 13" />
      <polygon fill="#224b63" points="6.9 13.9 9 13 9.3 13 7.7 14 6.9 13.9" />
      <polygon
        fill="#7fc9a6"
        points="1.6 15.9 .7 13.3 .2 13 .2 16.4 1.6 15.9"
      />
      <polygon fill="#ee5a55" points="4.6 6 11.9 .2 5.5 7.3 4.6 6" />
      <polygon
        fill="#fff"
        points="11.9 .2 .2 13 9.5 13 .2 21.8 4.3 21.8 21.8 9 12.5 9 21.8 .2 11.9 .2"
      />
    </g>
  </GenIcon>
);
