import api, { ResponseApi } from 'services/api';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCallback } from 'react';

import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import { FormData } from './validationForm';

const usePeriodoCaixaForm = () => {
  const history = useHistory();

  const obterPeriodoCaixa = useCallback(async (periodoCaixaId: string) => {
    const response = await api.get<void, ResponseApi<FormData>>(
      ConstanteEnderecoWebservice.FRENTE_CAIXA_PERIODO_CAIXA_OBTER,
      {
        params: {
          id: periodoCaixaId,
        },
      }
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response?.sucesso && response?.dados) {
        return response.dados;
      }
    }

    return undefined;
  }, []);

  const atualizarPeriodoCaixa = async (
    periodoCaixaId: string,
    data: FormData
  ) => {
    const response = await api.put<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.FRENTE_CAIXA_PERIODO_CAIXA_ALTERAR,
      { ...data },
      {
        params: {
          id: periodoCaixaId,
        },
      }
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response?.sucesso) {
        return { success: true };
      }
    }

    return { success: false };
  };

  const salvarPeriodoCaixa = async (data: FormData) => {
    const response = await api.post<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.FRENTE_CAIXA_PERIODO_CAIXA_CADASTRAR,
      { ...data }
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response?.sucesso) {
        return { success: true };
      }
    }

    return { success: false };
  };

  const redirecionarPeriodoCaixaListar = () => {
    history.push(ConstanteRotas.FRENTE_CAIXA_PERIODO_CAIXA);
  };

  return {
    obterPeriodoCaixa,
    atualizarPeriodoCaixa,
    salvarPeriodoCaixa,
    redirecionarPeriodoCaixaListar,
  };
};

export default usePeriodoCaixaForm;
