export const tipoPendenciaCadastro = {
  CLIENTE: 0,
  PRODUTO: 1,
  FORMA_RECEBIMENTO: 2,
  SITUACAO: 3,
  PAGAMENTO: 4,
  MARKETPLACE: 5,
};

export const tipoPendenciaIntegracao = {
  CADASTROS_VINCULO: 0,
  NCM: 1,
  CADASTRO_PLATAFORMA: 2,
  VINCULO: 3,
  SUPORTE: 4,
  CADASTRO_INATIVO: 5,
};

export const pendenciaIntegracao = {
  CPF_DUPLICADO: 0,
  INTERMEDIADOR: 1,
  SITUACAO: 2,
  PRODUTO_NAO_CADASTRADO: 3,
  FORMA_RECEBIMENTO: 4,
  NCM: 5,
  CADASTRO_INATIVO: 6,
  SUPORTE: 7,
};
