import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

const obterConfigEmitirNFe = async (): Promise<boolean | undefined> => {
  const response = await api.get<void, ResponseApi<boolean>>(
    ConstanteEnderecoWebservice.LOJA_OBTER_CONFIG_EMITIR_NFE
  );

  if (response?.avisos) {
    response.avisos.map((item: string) => toast.warning(item));
  }

  if (response?.sucesso) {
    return response.dados;
  }

  return undefined;
};

export default obterConfigEmitirNFe;
