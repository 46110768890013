import styled from 'styled-components';
import { IoMdArrowUp, IoMdArrowDown } from 'react-icons/io';

import { IoMdArrowDownRight } from 'icons';

export const TitlesContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  height: 100%;

  font-size: 0.8125rem;
  font-weight: 600;

  min-width: 180px;
`;

export const Title = styled.p`
  margin: 0;
`;

export const Separator = styled.span`
  height: 1px;
  background-color: var(--sti-ck-colors-gray-100);

  margin: 30px 0;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 5px;
`;

export const ActionButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  background: none;

  min-width: 105px;

  font-size: 0.75rem;
`;

export const ActionButtonText = styled.span``;

export const RecolherNiveisIcon = styled(IoMdArrowUp)`
  margin-right: 5px;
`;

export const ExpandirNiveisIcon = styled(IoMdArrowDown)`
  margin-right: 5px;
`;

export const NivelIcon = styled(IoMdArrowDownRight)`
  font-size: 1rem;
  margin-bottom: 10px;
  margin-right: 7px;
`;
