import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export type FormData = {
  quantidade: number;
  valorUnitario: number;
  ipi: number;
  icmsSt: number;
  fcpSt: number;
  custoAdicional: number;
};

const schema = yup.object().shape({
  quantidade: yup
    .number()
    .moreThan(0, ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  valorUnitario: yup.number().nullable(),
  ipi: yup.number().nullable(),
  icmsSt: yup.number().nullable(),
  fcpSt: yup.number().nullable(),
  custoAdicional: yup.number().nullable(),
});

export const yupResolver = yupResolverInstance(schema);
