import React, { useEffect, useRef, useState, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  ModalContent,
  ModalBody,
  Tabs,
  TabList,
  Flex,
  Button,
  Text,
  TabPanels,
  Tab,
  TabPanel,
  useMediaQuery,
  Box,
  ModalFooter,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import auth from 'modules/auth';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';

import { VariacaoLojaProps } from '../ModalListarSaldoVariacaoPdv/types';
import { DadosTabPanelLojaAtual } from './DadosTabPanels/lojaAtual';
import { DadosTabPanelEstoqueGeral } from './DadosTabPanels/estoqueGeral';
import { DadosTabPanelProdutosConsignados } from './DadosTabPanels/produtosConsignados';

type LocalEstoque = {
  localEstoque: string;
  quantidade: number;
};

type Tamanho = {
  tamanho: string;
  quantidade: number;
  localEstoque: string;
  locaisEstoque: LocalEstoque[];
};

export type VariacaoLinha = {
  cor: string;
  tamanhos: Tamanho[];
  valorTamanho: string;
  lojaId: string;
  loja: string;
  produtoCorId: string;
  produtoCorTamanhoId: string;
  saldo: number;
};

type VariacaoStatusEstiloTamanho = {
  tamanhoComponente: string;
  tamanhoListagem: string;
  tamanhoElemento: string;
};

export type ListarSaldoVariacoesProps = {
  localEstoque: string;
  cor: string;
  tamanho: string;
  saldo: number;
  lojaId: string;
  loja: string;
  produtoCorId: string;
  produtoCorTamanhoId: string;
  localEstoqueId: string;
};

export type ItensConsignados = {
  data: Date;
  variacao: string;
  cliente: string;
  telefone: string;
  quantidade: number;
};

type ListarSaldoVariacaoResponse = {
  saldoVariacoes: ListarSaldoVariacoesProps[];
  itensConsignados: ItensConsignados[];
};

type VariacaoTodosEstoquesProps = {
  saldo: number;
  loja: string;
};

type ModalListarSaldoVariacoesProps = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  idProduto: string;
};

const ModalListarSaldoVariacoes = ({
  isOpen,
  setIsOpen,
  idProduto,
}: ModalListarSaldoVariacoesProps) => {
  const [variacoesEstoqueAgrupado, setVariacoesEstoqueAgrupado] = useState<
    VariacaoLinha[]
  >([]);
  const [exibirTodasAsLojas, setExibirTodasAsLojas] = useState<
    VariacaoLojaProps[]
  >([]);
  const [variacaoTodosEstoques, setVariacaoTodosEstoques] = useState<
    VariacaoTodosEstoquesProps[]
  >([]);
  const [variacoesFiltradas, setVariacoesFiltradas] = useState<VariacaoLinha[]>(
    []
  );
  const [produtosConsignados, setProdutosConsignados] = useState<
    ItensConsignados[]
  >([]);
  const [tamanhosAgrupados, setTamanhosAgrupados] = useState<string[]>([]);
  const [
    tamanhosAgrupadosTodasLojas,
    setTamanhosAgrupadosTodasLojas,
  ] = useState<string[]>([]);

  const { fantasia: lojaFantasia = '' } = auth.getLoja();
  const formMethods = useForm();

  const [isLargerThan899] = useMediaQuery('(max-width: 899px)');

  const latestProps = useRef({ reset: formMethods.reset, variacoesFiltradas });
  const handleReset = () => {
    setVariacoesFiltradas([]);
    formMethods.reset();
  };

  const naoExisteTamanho = variacoesEstoqueAgrupado.every(
    ({ valorTamanho }) => valorTamanho === ''
  );

  const naoExisteTamanhoTodasLojas = exibirTodasAsLojas.every(
    ({ valorTamanho }) => valorTamanho === ''
  );

  const naoExisteCor = variacoesEstoqueAgrupado.every(
    (item) => item.cor === ''
  );

  const naoExisteCorTodasLojas = exibirTodasAsLojas.every(
    ({ cor }) => cor === ''
  );

  const informacoesEstoqueGeral = useMemo(() => {
    const arrayNovo = [] as VariacaoTodosEstoquesProps[];

    variacaoTodosEstoques.forEach((valor) => {
      if (valor.loja !== lojaFantasia) {
        arrayNovo.push(valor);
      }
    });
    return arrayNovo;
  }, [lojaFantasia, variacaoTodosEstoques]);

  const informacoesTamanhoEstilo: {
    [key: string]: VariacaoStatusEstiloTamanho;
  } = {
    tamanhoPequeno: {
      tamanhoComponente: '350px',
      tamanhoListagem: '86%',
      tamanhoElemento: '15%',
    },

    tamanhoMedio: {
      tamanhoComponente: '300px',
      tamanhoListagem: '92%',
      tamanhoElemento: '17%',
    },

    tamanhoGrande: {
      tamanhoComponente: '380px',
      tamanhoListagem: '90%',
      tamanhoElemento: '60%',
    },
  };

  const informacaoListandoTamanhoEstilo = (() => {
    if (variacoesEstoqueAgrupado.length < 4) {
      return informacoesTamanhoEstilo.tamanhoPequeno;
    }

    if (
      variacoesEstoqueAgrupado.length > 3 &&
      variacoesEstoqueAgrupado.length < 7
    ) {
      return informacoesTamanhoEstilo.tamanhoMedio;
    }

    if (variacoesEstoqueAgrupado.length > 6) {
      return informacoesTamanhoEstilo.tamanhoGrande;
    }

    return informacoesTamanhoEstilo.tamanhoPequeno;
  })();

  useEffect(() => {
    const params = {
      produtoId: idProduto,
    };

    function tratarTodosEstoques(dadosLoja: ListarSaldoVariacoesProps[]) {
      const newTodasVariacoes = dadosLoja.reduce((acc, curr) => {
        acc.push({
          loja: curr.loja,
          saldo: curr.saldo,
        });

        return acc;
      }, [] as VariacaoTodosEstoquesProps[]);

      setVariacaoTodosEstoques(newTodasVariacoes);
    }

    function tratarEstoqueLojaAtual(dadosLoja: ListarSaldoVariacoesProps[]) {
      const newTodasVariacoes = dadosLoja.reduce((acc, curr) => {
        const currCorIndex = acc.findIndex(
          (val) => val.produtoCorId === curr.produtoCorId
        );

        const validandoIdLoja = curr.loja;
        if (validandoIdLoja === lojaFantasia) {
          if (currCorIndex > -1) {
            acc.splice(currCorIndex, 1, {
              ...acc[currCorIndex],
              tamanhos: [
                ...acc[currCorIndex].tamanhos,
                {
                  tamanho: curr.tamanho,
                  quantidade: curr.saldo,
                  localEstoque: curr.localEstoque,
                  locaisEstoque: [
                    {
                      localEstoque: curr.localEstoque,
                      quantidade: curr.saldo,
                    },
                  ],
                },
              ],
            });
          } else {
            acc.push({
              cor: curr.cor,
              lojaId: curr.lojaId,
              loja: curr.loja,
              produtoCorId: curr.produtoCorId,
              valorTamanho: curr.tamanho,
              saldo: curr.saldo,
              produtoCorTamanhoId: curr.produtoCorTamanhoId,
              tamanhos: [
                {
                  localEstoque: curr.localEstoque,
                  tamanho: curr.tamanho,
                  quantidade: curr.saldo,
                  locaisEstoque: [
                    {
                      localEstoque: curr.localEstoque,
                      quantidade: curr.saldo,
                    },
                  ],
                },
              ],
            });
          }
        }

        return acc;
      }, [] as VariacaoLinha[]);
      setVariacoesEstoqueAgrupado(newTodasVariacoes);
    }

    function tratarExibicaoTodasLojas(dadosLoja: ListarSaldoVariacoesProps[]) {
      const newTodasVariacoes = dadosLoja.reduce((acc, curr) => {
        const currCorIndex = acc.findIndex(
          (val) => val.produtoCorId === curr.produtoCorId
        );

        if (currCorIndex > -1) {
          acc.splice(currCorIndex, 1, {
            ...acc[currCorIndex],
            tamanhos: [
              ...acc[currCorIndex].tamanhos,
              {
                loja: curr.loja,
                tamanho: curr.tamanho,
                quantidade: curr.saldo,
                localEstoque: curr.localEstoque,
                locaisEstoque: [
                  {
                    localEstoque: curr.localEstoque,
                    quantidade: curr.saldo,
                  },
                ],
                locaisLoja: [
                  {
                    loja: curr.loja,
                    saldoLoja: curr.saldo,
                  },
                ],
              },
            ],
            lojas: [
              ...acc[currCorIndex].lojas,
              {
                saldoLoja: curr.saldo,
                loja: curr.loja,
                tamanho: curr.tamanho,
                todasLojas: [
                  {
                    loja: curr.loja,
                    saldoLoja: curr.saldo,
                  },
                ],
              },
            ],
          });
        } else {
          acc.push({
            cor: curr.cor,
            lojaId: curr.lojaId,
            lojas: [
              {
                saldoLoja: curr.saldo,
                loja: curr.loja,
                tamanho: curr.tamanho,
                todasLojas: [
                  {
                    loja: curr.loja,
                    saldoLoja: curr.saldo,
                  },
                ],
              },
            ],
            produtoCorId: curr.produtoCorId,
            valorTamanho: curr.tamanho,
            saldo: curr.saldo,
            produtoCorTamanhoId: curr.produtoCorTamanhoId,
            tamanhos: [
              {
                localEstoque: curr.localEstoque,
                tamanho: curr.tamanho,
                loja: curr.loja,
                quantidade: curr.saldo,
                locaisEstoque: [
                  {
                    localEstoque: curr.localEstoque,
                    quantidade: curr.saldo,
                  },
                ],
                locaisLoja: [
                  {
                    loja: curr.loja,
                    saldoLoja: curr.saldo,
                  },
                ],
              },
            ],
          });
        }

        return acc;
      }, [] as VariacaoLojaProps[]);
      setExibirTodasAsLojas(newTodasVariacoes);
    }

    async function getListarVariacoes() {
      if (isOpen) {
        const response = await api.get<
          void,
          ResponseApi<ListarSaldoVariacaoResponse>
        >(ConstanteEnderecoWebservice.PRODUTO_OBTER_SALDO_VARIACOES, {
          params,
        });

        if (response) {
          if (response.avisos) {
            response.avisos.map((item: string) => toast.warning(item));
          }

          if (response.sucesso) {
            tratarEstoqueLojaAtual(response.dados.saldoVariacoes);
            tratarExibicaoTodasLojas(response.dados.saldoVariacoes);
            tratarTodosEstoques(response.dados.saldoVariacoes);
            setProdutosConsignados(response.dados.itensConsignados);
          }
        }
      }
    }
    getListarVariacoes();
  }, [idProduto, isOpen, lojaFantasia]);
  useEffect(() => {
    latestProps.current = { reset: formMethods.reset, variacoesFiltradas };
  });

  useEffect(() => {
    if (variacoesEstoqueAgrupado && variacoesEstoqueAgrupado.length > 0) {
      const tamanhosPorLinha = variacoesEstoqueAgrupado
        .reduce((acc, curr) => {
          curr.tamanhos.forEach((tamanho) => {
            acc.push(tamanho.tamanho);
          });

          return acc;
        }, [] as string[])
        .filter((item, pos, self) => self.indexOf(item) === pos);

      setTamanhosAgrupados([...tamanhosPorLinha]);
    }
  }, [variacoesEstoqueAgrupado]);

  useEffect(() => {
    if (exibirTodasAsLojas && exibirTodasAsLojas.length > 0) {
      const tamanhosPorLinha = exibirTodasAsLojas
        .reduce((acc, curr) => {
          curr.tamanhos.forEach((tamanho) => {
            acc.push(tamanho.tamanho);
          });

          return acc;
        }, [] as string[])
        .filter((item, pos, self) => self.indexOf(item) === pos);

      setTamanhosAgrupadosTodasLojas([...tamanhosPorLinha]);
    }
  }, [exibirTodasAsLojas]);

  useEffect(() => {
    if (isOpen) {
      latestProps.current.reset(latestProps.current.variacoesFiltradas);
    }
  }, [isOpen]);

  useEffect(() => {
    setVariacoesFiltradas([...variacoesEstoqueAgrupado]);
  }, [variacoesEstoqueAgrupado]);

  return (
    <ModalPadraoChakra
      onClose={() => {
        setIsOpen(false);
        handleReset();
      }}
      isOpen={isOpen}
      isCentered={!isLargerThan899}
      size="5xl"
    >
      <ModalContent h="530px" mx="6" my="6">
        <ModalBody h="100%" w="100%" pt="6" pr="6" pl="5">
          <Flex justifyContent="space-between">
            <Box>
              <Text
                mb="15px"
                fontSize="16px"
                fontWeight="bold"
                color="primary.50"
              >
                Consulta de estoque
              </Text>
            </Box>
          </Flex>
          <FormProvider {...formMethods}>
            <Flex
              w="full"
              h={informacaoListandoTamanhoEstilo.tamanhoComponente}
            >
              <Tabs variant="enclosed" position="relative" w="full">
                <TabList
                  paddingTop="13px"
                  pl="23px"
                  h="50px"
                  borderRadius="6px 6px 0 0"
                  borderBlockEnd="1px solid #5502b260"
                  mb="0"
                  bg="#00000034"
                >
                  <Tab
                    border="1px solid #5502b260"
                    color="black"
                    w={isLargerThan899 ? 250 : 200}
                    _before={{
                      boxShadow: 'none !important',
                    }}
                    _after={{
                      boxShadow: 'none !important',
                    }}
                    fontSize="sm"
                  >
                    {lojaFantasia}
                  </Tab>
                  {informacoesEstoqueGeral.length === 0 ? (
                    <></>
                  ) : (
                    <Tab
                      border="1px solid #5502b260"
                      color="black"
                      fontSize="sm"
                      w={isLargerThan899 ? 260 : 240}
                      _after={{
                        boxShadow: 'none !important',
                      }}
                      _before={{
                        boxShadow: 'none !important',
                      }}
                      _active={{
                        boxShadow: 'none !important',
                      }}
                    >
                      {isLargerThan899
                        ? 'Estoque geral'
                        : 'Estoque geral (todas as lojas)'}
                    </Tab>
                  )}
                  <Tab
                    border="1px solid #5502b260"
                    color="black"
                    w={isLargerThan899 ? 250 : 200}
                    _before={{
                      boxShadow: 'none !important',
                    }}
                    _after={{
                      boxShadow: 'none !important',
                    }}
                    fontSize="sm"
                  >
                    Consignação
                  </Tab>
                </TabList>

                <TabPanels
                  borderRight="1px solid #00000034"
                  borderLeft="1px solid #00000034"
                  borderBottom="1px solid #00000034"
                  borderRadius="0 0 6px 6px"
                  h={informacaoListandoTamanhoEstilo.tamanhoListagem}
                  position="relative"
                >
                  <TabPanel
                    overflowY={
                      variacoesEstoqueAgrupado.length > 4 ? 'scroll' : 'hidden'
                    }
                    maxH={informacaoListandoTamanhoEstilo.tamanhoListagem}
                  >
                    <DadosTabPanelLojaAtual
                      variacoesEstoqueAgrupado={variacoesEstoqueAgrupado}
                      naoExisteTamanho={naoExisteTamanho}
                      naoExisteCor={naoExisteCor}
                      tamanhosAgrupados={tamanhosAgrupados}
                    />
                  </TabPanel>
                  {informacoesEstoqueGeral.length > 0 && (
                    <TabPanel
                      overflowY={
                        exibirTodasAsLojas.length > 4 ? 'scroll' : 'hidden'
                      }
                      maxH={informacaoListandoTamanhoEstilo.tamanhoListagem}
                    >
                      <DadosTabPanelEstoqueGeral
                        exibirTodasAsLojas={exibirTodasAsLojas}
                        naoExisteTamanhoTodasLojas={naoExisteTamanhoTodasLojas}
                        naoExisteCorTodasLojas={naoExisteCorTodasLojas}
                        tamanhosAgrupadosTodasLojas={
                          tamanhosAgrupadosTodasLojas
                        }
                      />
                    </TabPanel>
                  )}
                  <TabPanel
                    overflowY={
                      produtosConsignados.length > 2 ? 'scroll' : 'hidden'
                    }
                    maxH={informacaoListandoTamanhoEstilo.tamanhoListagem}
                  >
                    <DadosTabPanelProdutosConsignados
                      produtosConsignados={produtosConsignados}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Flex>
          </FormProvider>
        </ModalBody>
        <ModalFooter>
          <Flex w="full" justifyContent="center" alignItems="center">
            <Button
              id="cancelar"
              name="cancelar"
              colorScheme="gray.700"
              variant="outlineDefault"
              type="button"
              w="155px"
              onClick={() => {
                setIsOpen(false);
                handleReset();
              }}
            >
              Fechar consulta
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export default ModalListarSaldoVariacoes;
