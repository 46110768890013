/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { components } from 'react-select';
import {
  Tag,
  Tooltip,
  TagCloseButton,
  TagLabel,
  Box,
  StylesProvider,
  useMultiStyleConfig,
  useStyles,
  Icon,
  Text,
  Flex,
} from '@chakra-ui/react';
import { FiCheck, FiChevronDown } from 'react-icons/fi';

import StatusConsultaEnum from 'constants/enum/statusConsulta';

export const multiSelectComponents = {
  ValueContainer: ({ children, ...props }: any) => {
    const { selectProps } = props;
    const { tipoFiltro, value } = selectProps;
    const possuiAoMenosUmAtivo =
      Array.isArray(value) && value?.some((item: any) => item.ativo);

    const todosItensSaoAtivos =
      Array.isArray(value) && value?.every((item: any) => item.ativo);

    if (
      !possuiAoMenosUmAtivo &&
      tipoFiltro === StatusConsultaEnum.ATIVOS &&
      Array.isArray(value) &&
      value.length > 0
    ) {
      return (
        <components.ValueContainer {...props}>
          {children}
          <Text fontSize="sm" color="gray.400">
            Não possui variações ativas
          </Text>
        </components.ValueContainer>
      );
    }
    if (
      tipoFiltro === StatusConsultaEnum.INATIVOS &&
      todosItensSaoAtivos &&
      Array.isArray(value) &&
      value.length > 0
    ) {
      return (
        <components.ValueContainer {...props}>
          {children}
          <Text fontSize="sm" color="gray.400">
            Não possui variações inativas
          </Text>
        </components.ValueContainer>
      );
    }

    return (
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    );
  },
  MultiValueContainer: ({
    children,
    innerRef,
    innerProps,
    data,
    ...rest
  }: any) => {
    return (
      <Tag
        ref={innerRef}
        {...innerProps}
        m="0.125rem"
        cursor="pointer"
        onClick={(e) => {
          if (data?.ativo === false) {
            e.preventDefault();
            rest?.selectProps?.onChange(rest?.selectProps?.value, {
              action: 'activate-value',
              option: data,
            });
            e.stopPropagation();
          }
        }}
      >
        {children}
      </Tag>
    );
  },
  IndicatorSeparator: () => null,
  DropdownIndicator: ({ children, ...rest }: any) => (
    <components.DropdownIndicator {...rest}>
      <Icon as={FiChevronDown} />
    </components.DropdownIndicator>
  ),
  MultiValue: ({ children, ...props }: any) => {
    const { selectProps, data } = props;
    const { tipoFiltro } = selectProps;
    const { ativo } = data;

    switch (tipoFiltro) {
      case StatusConsultaEnum.ATIVOS:
        if (!ativo) {
          return null;
        }
        return (
          <components.MultiValue {...props}>{children}</components.MultiValue>
        );
      case StatusConsultaEnum.INATIVOS:
        if (ativo) {
          return null;
        }
        return (
          <components.MultiValue {...props}>{children}</components.MultiValue>
        );
      default:
        return (
          <components.MultiValue {...props}>{children}</components.MultiValue>
        );
    }
  },
  MultiValueLabel: ({ children, innerRef, innerProps }: any) => {
    return (
      <TagLabel ref={innerRef} {...innerProps}>
        {children}
      </TagLabel>
    );
  },
  MultiValueRemove: ({
    children,
    innerRef,
    innerProps,
    data: { ativo },
  }: any) => {
    if (!ativo) {
      return null;
    }

    return (
      <TagCloseButton
        ref={innerRef}
        {...innerProps}
        opacity="1"
        fontSize="14px"
      >
        {children}
      </TagCloseButton>
    );
  },
  Menu: ({ children, ...props }: any) => {
    const menuStyles = useMultiStyleConfig('Menu', {});
    return (
      <components.Menu {...props}>
        <StylesProvider value={menuStyles}>{children}</StylesProvider>
      </components.Menu>
    );
  },
  MenuList: ({ innerRef, innerProps, children, maxHeight }: any) => {
    const { list } = useStyles();

    return (
      <Box
        sx={{
          ...list,
          maxH: `${maxHeight}px`,
          overflowY: 'auto',
        }}
        ref={innerRef}
        {...innerProps}
      >
        {children}
      </Box>
    );
  },
  GroupHeading: ({ innerProps, children }: any) => {
    const { groupTitle } = useStyles();
    return (
      <Box sx={groupTitle} {...innerProps}>
        {children}
      </Box>
    );
  },
  Option: ({
    innerRef,
    innerProps,
    children,
    isFocused,
    isDisabled,
    isSelected,
    data,
    selectProps,
  }: any) => {
    const { item } = useStyles();

    const tamanhoIconeSelecionado = '3';

    const { shouldAppearTheAddress, renderOptionDetails } = selectProps;

    return (
      <Flex
        sx={{
          ...item,
          paddingRight: renderOptionDetails ? '8px' : item.paddingRight,
          w: '100%',
          textAlign: 'left',
          paddingLeft: isSelected
            ? '0.8rem'
            : `calc(1.6rem + ${tamanhoIconeSelecionado})`,
          // @ts-ignore
          bg: isFocused ? item._focus.bg : 'transparent',
          // @ts-ignore
          ...(isDisabled && item._disabled),
        }}
        className="react-select__option"
        ref={innerRef}
        {...innerProps}
        {...(isDisabled && { disabled: true })}
        justifyContent="space-between"
        gap="12px"
        mb="4px"
      >
        <Tooltip
          label={data?.labelTooltip}
          placement="right-start"
          p="10px"
          borderRadius="5px"
          isDisabled={!data?.isTooltip}
        >
          <Box as="button" textAlign="left">
            {isSelected && (
              <Icon
                as={FiCheck}
                marginRight="3"
                w={tamanhoIconeSelecionado}
                top={shouldAppearTheAddress ? '3' : ''}
              />
            )}

            {children}
          </Box>
        </Tooltip>
      </Flex>
    );
  },
};
