import {
  Table,
  Thead,
  Tr,
  Td,
  Th,
  Flex,
  Divider,
  Text,
  Tbody,
  Tfoot,
} from '@chakra-ui/react';

import { DecimalMask, moneyMask } from 'helpers/format/fieldsMasks';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';

import { InputQuantidadeAcrescimoDecrescimo } from 'components/PDV/TrocarProdutosComponents/InputQuantidadeAcresimoDecrescimo';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';

import { ProdutoSelecionadoProps } from '../Types/validationForm';

type ListarItensTrocasProdutoProps = {
  produtosTroca: ProdutoSelecionadoProps[];
  handleExcluirProduto(index: number): void;
  quantidade: number[];
  handleDecrementarQuantidade(index: number): void;
  handleIncrementarQuantidade(index: number): void;
  valorTotalQuantidade: number[];
};

export function ListarItensTrocasProduto({
  produtosTroca,
  quantidade,
  handleDecrementarQuantidade,
  handleIncrementarQuantidade,
  handleExcluirProduto,
  valorTotalQuantidade,
}: ListarItensTrocasProdutoProps) {
  const { casasDecimais } = usePadronizacaoContext();

  return (
    <Table
      boxShadow="md"
      rounded="md"
      variant=" "
      bg="white"
      borderRadius="5px"
    >
      <Thead whiteSpace="nowrap" fontSize="xs" color="gray.400">
        <Tr position="relative">
          <Th>Descrição</Th>

          <>
            <Th w="12%">
              <Flex justifyContent="right" alignItems="right">
                <Text>Quantidade</Text>
              </Flex>
            </Th>

            <Th w="10%">
              <Flex justifyContent="right" alignItems="right">
                <Text>Valor unitário</Text>
              </Flex>
            </Th>
            <Th w="10%">
              <Flex justifyContent="right" alignItems="right">
                <Text>Desconto</Text>
              </Flex>
            </Th>
            <Th w="10%">
              <Flex justifyContent="right" alignItems="right">
                <Text>Acréscimo</Text>
              </Flex>
            </Th>
          </>

          <Th w="10%">
            <Flex justifyContent="right" alignItems="right">
              <Text>Valor total</Text>
            </Flex>
          </Th>
          <Th w="1%" p={0} />
        </Tr>
        <Tr>
          <Th padding="0" colSpan={7}>
            <Flex justifyContent="center" alignItems="center">
              <Divider w="98%" />
            </Flex>
          </Th>
        </Tr>
      </Thead>
      <Tbody fontSize="16px">
        {produtosTroca.map((produto, index) => {
          const quantidadePossuiDecimal = produto.quantidade % 1 !== 0;

          const valorTotal =
            (produto.valor - produto.desconto) * quantidade[index];

          const valorTotalDesconto = produto.desconto * quantidade[index];

          const valorTotalAcrescimo = produto.acrescimo * quantidade[index];
          return (
            <>
              <Tr>
                <Td>{produto.nome}</Td>

                <Td w="12%">
                  {quantidadePossuiDecimal ? (
                    <Flex justifyContent="right" alignItems="right">
                      <Text>
                        {DecimalMask(
                          produto.quantidade,
                          casasDecimais.casasDecimaisQuantidade
                        )}
                      </Text>
                    </Flex>
                  ) : (
                    <>
                      {produto.quantidade > 1 ? (
                        <Flex justifyContent="right" alignItems="right">
                          <InputQuantidadeAcrescimoDecrescimo
                            handleDecrementarQuantidade={
                              handleDecrementarQuantidade
                            }
                            handleIncrementarQuantidade={
                              handleIncrementarQuantidade
                            }
                            produto={produto}
                            quantidade={quantidade}
                            index={index}
                          />
                        </Flex>
                      ) : (
                        <Flex justifyContent="right" alignItems="right">
                          <Text>
                            {DecimalMask(
                              produto.quantidade,
                              casasDecimais.casasDecimaisQuantidade
                            )}
                          </Text>
                        </Flex>
                      )}
                    </>
                  )}
                </Td>
                <Td w="14%">
                  <Flex justifyContent="right" alignItems="right">
                    <Text>
                      {`R$
                          ${DecimalMask(
                            produto.valor,
                            casasDecimais.casasDecimaisValor,
                            casasDecimais.casasDecimaisValor
                          )}
                          `}
                    </Text>
                  </Flex>
                </Td>
                <Td w="11%">
                  <Flex justifyContent="right" alignItems="right">
                    <Text>{moneyMask(valorTotalDesconto, true)}</Text>
                  </Flex>
                </Td>
                <Td w="11%">
                  <Flex justifyContent="right" alignItems="right">
                    <Text>{moneyMask(valorTotalAcrescimo, true)}</Text>
                  </Flex>
                </Td>

                <Td minW="11%" w="11%">
                  <Flex justifyContent="right" alignItems="right">
                    <Text>{moneyMask(valorTotal, true)}</Text>
                  </Flex>
                </Td>
                <Td w="1%" minW="20px !important">
                  <Flex justifyContent="right">
                    <ActionsMenu
                      items={[
                        {
                          content: 'Remover',
                          onClick: () => handleExcluirProduto(index),
                        },
                      ]}
                      menuZIndex="modal"
                    />
                  </Flex>
                </Td>
              </Tr>
              <Tr>
                <Td padding="0" colSpan={7}>
                  <Flex justifyContent="center" alignItems="center">
                    <Divider w="98%" />
                  </Flex>
                </Td>
              </Tr>
            </>
          );
        })}
      </Tbody>
      <Tfoot>
        <Td colSpan={2}>
          <Flex justifyContent="right" alignItems="right">
            <Text fontSize="sm" w="140px">
              Quantidade de itens:
            </Text>

            <Text fontWeight="bold" fontSize="md">
              {DecimalMask(
                valorTotalQuantidade.reduce((acc, curr) => acc + curr, 0),
                casasDecimais.casasDecimaisQuantidade,
                casasDecimais.casasDecimaisQuantidade
              )}
            </Text>
          </Flex>
        </Td>
        <Td colSpan={3}>
          <Flex justifyContent="right" alignItems="right">
            <Text mr="5px" fontSize="sm">
              Valor devolvido:
            </Text>

            <Text fontWeight="bold" fontSize="md">
              {moneyMask(
                produtosTroca.reduce((acc, curr, index) => {
                  const valorCadaProduto =
                    (curr.valor - curr.desconto) * quantidade[index];
                  return valorCadaProduto + acc;
                }, 0),
                true
              )}
            </Text>
          </Flex>
        </Td>
      </Tfoot>
    </Table>
  );
}
