const enumTipoUsuario = {
  SISTEMA: 1,
  COMANDA: 2,
  AUTOATENDIMENTO: 3,
  FRENTE_DE_CAIXA: 4,
  PDV_OFFLINE: 5,

  properties: {
    0: {
      name: 'Sistema Geral',
      value: 1,
    },
    1: {
      name: 'Comanda Garçom',
      value: 2,
    },
    2: {
      name: 'Auto Atendimento',
      value: 3,
    },
    3: {
      name: 'Frente de Caixa',
      value: 4,
    },
    4: {
      name: 'PDV Offline',
      value: 5,
    },
  },

  options: [
    {
      label: 'Sistema Geral (inclui todos os aplicativos)',
      value: 1,
    },
    {
      label: 'Comanda Garçom',
      value: 2,
    },
    {
      label: 'Auto Atendimento',
      value: 3,
    },
    {
      label: 'Frente de Caixa',
      value: 4,
    },
    {
      label: 'PDV Offline',
      value: 5,
    },
  ],
};

export default enumTipoUsuario;
