import React, { ReactNode } from 'react';
import {
  Box,
  Flex,
  Text,
  useRadio,
  UseRadioProps,
  Circle,
  Divider,
  Collapse,
  useMediaQuery,
  Icon,
} from '@chakra-ui/react';
import { MdKeyboardArrowRight } from 'react-icons/md';

export type DetailedRadioItemType = {
  value: string;
  title: string;
  subtitle?: ReactNode;
  activeContent?: ReactNode;
  isDisabled?: boolean;
};

interface DetailedRadioItemProps extends UseRadioProps {
  item: DetailedRadioItemType;
  radioProps: UseRadioProps;
  colorScheme: string;
}

export function DetailedRadioItem({
  item,
  radioProps,
  colorScheme,
}: DetailedRadioItemProps) {
  const {
    getInputProps,
    getCheckboxProps,
    state: { isChecked },
  } = useRadio({ ...radioProps, isDisabled: item.isDisabled });

  const inputProps = getInputProps();
  const checkboxProps = getCheckboxProps();

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  return (
    <Box
      borderWidth="1px"
      bg={isChecked ? `${colorScheme}.50` : 'transparent'}
      borderColor={isChecked ? `${colorScheme}.500` : 'gray.100'}
      borderRadius="md"
      transition="border-color .2s, background .2s"
      opacity={item.isDisabled && !isChecked ? 0.5 : 1}
    >
      <Flex
        as="label"
        position="relative"
        flexDir="column"
        justifyContent="center"
        pb="4"
        pt={{ base: '10', md: '4' }}
        pl={{ base: '4', md: '16' }}
        pr={{ base: '4', md: '14' }}
        mb="0"
        {...checkboxProps}
        cursor={
          // eslint-disable-next-line no-nested-ternary
          item.isDisabled ? (isChecked ? 'default' : 'not-allowed') : 'pointer'
        }
      >
        <input {...inputProps} />

        <Circle
          position="absolute"
          size="3"
          left={{ base: '50%', md: '8' }}
          top={{ base: '5', md: '50%' }}
          bg={isChecked ? `${colorScheme}.500` : 'gray.50'}
          border="1px"
          borderColor={isChecked ? `${colorScheme}.100` : 'gray.100'}
          boxShadow={isChecked ? 'none' : 'inner'}
          transform="translate(-50%, -50%)"
          transition="background .2s, border-color .2s"
        />

        <Text
          color={`${colorScheme}.500`}
          fontSize="lg"
          fontWeight="semibold"
          mb="1"
          userSelect="none"
        >
          {item.title}
        </Text>
        <Text color="gray.700" fontSize="sm" userSelect="none">
          {item.subtitle}
        </Text>
        {isLargerThan900 && (
          <Icon
            as={MdKeyboardArrowRight}
            position="absolute"
            w="22px"
            h="22px"
            right={{ base: '50%', md: '8' }}
            top={{ base: '5', md: '40%' }}
            color={`${colorScheme}.500`}
          />
        )}
      </Flex>

      <Box
        overflow="visible !important"
        as={Collapse}
        in={isChecked && !!item.activeContent}
        animateOpacity
        unmountOnExit
      >
        <Box pl={{ base: '4', md: '16' }} pr={{ base: '4', md: '14' }} pb="8">
          <Divider mb="8" />

          <Box>{item.activeContent}</Box>
        </Box>
      </Box>
    </Box>
  );
}
