import styled from 'styled-components';
import { Modal as BootstrapModal } from 'react-bootstrap';

export const Modal = styled(BootstrapModal)`
  .modal-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    background-color: var(--sti-ck-colors-gray-700);
    height: 20px;
    width: 500px;
    max-width: calc(100vw - 10px);

    border: none;

    > button {
      padding: 0px;
      margin: 0px;

      > span {
        color: var(--white);
      }
    }
  }

  .modal-footer {
    background-color: var(--sti-ck-colors-gray-700);
    height: 50px;
    width: 500px;
    max-width: calc(100vw - 10px);

    border: none;

    overflow: hidden;
  }
`;

export const ModalBody = styled(BootstrapModal.Body)`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0px;
  width: 500px;
  max-width: calc(100vw - 10px);

  overflow: hidden;

  background-color: var(--white);
`;

export const CaptureButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
`;

export const CaptureButton = styled.button`
  background: rgb(88, 206, 83);
  background: radial-gradient(
    circle,
    rgba(88, 206, 83, 1) 0%,
    rgba(76, 182, 72, 1) 100%
  );

  border: 1px solid var(--white);
  border-radius: 50%;

  height: 25px;
  width: 25px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1.5625rem;
`;
