import styled, { css } from 'styled-components';
import {
  Dropdown as BootstrapDropdown,
  Modal as BootstrapModal,
} from 'react-bootstrap';

export interface BreakpointsSizes {
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
}

interface ImagePickerContainerProps {
  height: BreakpointsSizes;
  width: BreakpointsSizes;
  readonly?: boolean;
  isDragActive?: boolean;
}

export const ImagePickerContainer = styled.div<ImagePickerContainerProps>`
  ${({ height }) => css`
    height: ${height.xs};

    @media (min-width: 700px) {
      height: ${height.sm};
    }
    @media (min-width: 900px) {
      height: ${height.md};
    }
    @media (min-width: 1200px) {
      height: ${height.lg};
    }
    @media (min-width: 1800px) {
      height: ${height.xl};
    }
  `}

  ${({ width }) => css`
    width: ${width.xs};

    @media (min-width: 700px) {
      width: ${width.sm};
    }
    @media (min-width: 900px) {
      width: ${width.md};
    }
    @media (min-width: 1200px) {
      width: ${width.lg};
    }
    @media (min-width: 1800px) {
      width: ${width.xl};
    }
  `}

    ${({ readonly }) =>
    readonly &&
    css`
      cursor: pointer;
    `}

    filter: none;
  ${({ isDragActive }) =>
    isDragActive &&
    css`
      filter: brightness(80%);
    `}
  transition: filter 0.2s ease;

  position: relative;

  background: var(--sti-ck-colors-gray-50);
  border-radius: 4px;
`;

export const ImagePreview = styled.img`
  background-color: transparent;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: contain;
  width: 100%;
  height: 100%;

  border-radius: 4px;
  border: 1px solid var(--sti-ck-colors-gray-100);
`;

export const Dropdown = styled(BootstrapDropdown)`
  position: absolute;

  display: flex;
  align-items: center;

  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  border: none;

  &.show > button > img {
    filter: blur(1px);

    border-color: var(--sti-ck-colors-primary-50);
  }
`;

export const DropdownToggle = styled(BootstrapDropdown.Toggle)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  border: none !important;
  box-shadow: none !important;

  background-color: var(--sti-ck-colors-gray-50) !important;

  z-index: 1;

  padding-right: 0 !important;

  &:after {
    content: none;
  }
`;

export const DropdownMenu = styled(BootstrapDropdown.Menu)`
  position: relative !important;

  width: calc(100% - 8px);
  max-width: 250px;

  left: 50% !important;
  margin-right: -50% !important;
  transform: translate(-50%, 0px) !important;

  z-index: 2;

  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
`;

export const DropdownItem = styled(BootstrapDropdown.Item)`
  position: relative;
  padding-left: 30px;
  font-size: 0.875rem;

  ${({ selected }) =>
    selected &&
    `
    cursor: default;

    &:after {
      content: '';
      position: absolute;

      left: 18px;
      top: 10px;
      width: 5px;
      height: 10px;
      border: solid black;
      border-width: 0 1px 1px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  `}

  &.active,
  &:active {
    background-color: var(--sti-ck-colors-gray-50);

    :after {
      border-color: var(--white);
    }
  }
`;

export const InputFile = styled.input`
  display: none;
  opacity: 0;
`;

export const DefaultContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background-image: var(--dashed-border-bg);
  border-radius: 5px;

  height: 100%;
`;

export const Modal = styled(BootstrapModal)`
  .modal-content {
    width: 250px;
    height: 250px;
  }

  .modal-dialog {
    min-width: 250px;
    max-width: 250px;

    margin: 1.75rem auto !important;
  }
`;

export const ModalBody = styled(BootstrapModal.Body)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;

  padding: 0px;

  overflow: hidden;
`;

export const SpanLegenda = styled.span`
  color: var(--sti-ck-colors-gray-700);
  white-space: normal;
  font-size: 11px;
`;

export const SpanLink = styled.span`
  text-align: left;
  text-decoration: underline;
  letter-spacing: 0px;
  color: var(--sti-ck-colors-blue-500);
  opacity: 1;
  font-size: 11px;
`;
