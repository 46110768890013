import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export type FormData = {
  produtoCor: {
    label: string;
    value: string;
    tamanhos: any[];
    volumeUnitario: boolean;
    produtoKit: boolean;
  } | null;
  produtoCorTamanho: {
    label: string;
    value: string;
    codigoBarrasInterno: string;
    padraoSistema: boolean;
  } | null;
  quantidade: number;
};

const schema = yup.object().shape({
  produtoCor: yup
    .object()
    .shape({
      value: yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
      label: yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
    })
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  produtoCorTamanho: yup
    .object()
    .shape({
      value: yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
      label: yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
    })
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  quantidade: yup
    .number()
    .default(0)
    .test(
      'quantidade',
      ConstanteMensagemValidacao.CAMPO_OBRIGATORIO,
      (value) => {
        if (value && value > 0) {
          return true;
        }
        return false;
      }
    ),
});

export const yupResolver = yupResolverInstance(schema);
