export type OperacaoCamposAlterar = 0 | 1 | 2 | 3;

const OperacaoCamposAlterarEnum = {
  CLIENTE: 0,
  VENDEDOR: 1,
  OBSERVACAO: 2,
  TIPO_OPERACAO: 3,
  LOCAL_ESTOQUE: 4,
};

export default OperacaoCamposAlterarEnum;
