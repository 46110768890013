export const TipoLancamentoEnum = {
  NAO_LANCADO: 1,
  CONFIRMADO: 2,
  TODOS: 3,

  properties: {
    1: { label: 'Não lançado', value: 1 },
    2: { label: 'Confirmado', value: 2 },
    3: { label: 'Todos', value: 3 },
  },
};
