import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Flex,
  GridItem,
  Icon,
  Text,
  Table,
  Tr,
  Th,
  Td,
  Thead,
  Tbody,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { FiChevronLeft } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import ConstanteRotas from 'constants/rotas';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { useTrayPainelControleContext } from 'store/Tray/PainelControle';

import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { SearchInput } from 'components/update/Input/SearchInput';
import { ContainerHeader } from 'pages/Integracoes/LojaAplicativos/Layout/ContainerHeader';
import { PainelTrayIcon } from 'icons';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { ModalAdicionarDescricao } from './ModalAlterarFormaRecebimento';

type FormaRecebimentoResponse = {
  id: string;
  nome: string;
  contaFinanceiraId: string;
};

type CaixaAberto = {
  id: string;
  nome: string;
};

export type FormaRecebimentoTrayProps = {
  zendarId: string;
  siteId: string;
  nome: string;
};

export const FormaRecebimentoTray = () => {
  const [listFormaRecebimento, setListFormaRecebimento] = useState<
    FormaRecebimentoTrayProps[]
  >([]);
  const [formaRecebimentoSistema, setFormaRecebimentoSistema] = useState<
    FormaRecebimentoResponse[]
  >([]);
  const [caixaAberto, setCaixaAberto] = useState<CaixaAberto>(
    {} as CaixaAberto
  );

  const [contaFinanceira, setContaFinanceira] = useState<CaixaAberto[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { nomeCanalVenda } = useTrayPainelControleContext();

  const history = useHistory();

  const formMethods = useForm();

  const { getValues } = formMethods;

  function handleVoltarPainelAdm() {
    history.push(ConstanteRotas.INTEGRACAO_TRAY_COMMERCE_PAINEL_ADM);
  }

  const getFormasPagamentoTray = useCallback(
    async (nome?: string) => {
      setIsLoading(true);
      const response = await api.get<
        void,
        ResponseApi<FormaRecebimentoTrayProps[]>
      >(ConstanteEnderecoWebservice.INTEGRACAO_TRAY_OBTER_FORMA_PAGAMENTO, {
        params: { nome },
      });
      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }

        if (response.sucesso) {
          setListFormaRecebimento(response.dados);
        }
        setIsLoading(false);
      }
    },
    [setIsLoading]
  );

  const getObterCaixaAberto = useCallback(async () => {
    const response = await api.get<void, ResponseApi<CaixaAberto>>(
      ConstanteEnderecoWebservice.OBTER_CAIXA_ABERTO_USUARIO_LOGADO
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((avisos) => toast.warning(avisos));
      }
      if (response.sucesso && response.dados) {
        setCaixaAberto(response.dados);
      }
    }
  }, []);

  useEffect(() => {
    async function obterValoresFormaRecebimento() {
      const response = await api.get<
        void,
        ResponseApi<FormaRecebimentoResponse[]>
      >(
        ConstanteEnderecoWebservice.FORMA_RECEBIMENTO_LISTAR_SELECT_RECEBIMENTO
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response.sucesso && response.dados) {
          setFormaRecebimentoSistema(response.dados);
        }
      }
    }

    obterValoresFormaRecebimento();
  }, []);

  useEffect(() => {
    async function obterValoresFormaRecebimento() {
      const response = await api.get<void, ResponseApi<CaixaAberto[]>>(
        ConstanteEnderecoWebservice.CONTA_FINANCEIRA_LISTAR_CONTAS_FINANCEIRAS_MENOS_CAIXAS
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response.sucesso && response.dados) {
          setContaFinanceira(response.dados);
        }
      }
    }

    obterValoresFormaRecebimento();
  }, []);

  useEffect(() => {
    getObterCaixaAberto();
  }, [getObterCaixaAberto]);

  useEffect(() => {
    getFormasPagamentoTray();
  }, [getFormasPagamentoTray]);

  return (
    <FormProvider {...formMethods}>
      {isLoading && <LoadingPadrao />}
      <Box mb="16px" borderTop="1px" borderColor="primary.50" />
      <SimpleGridForm pl={['24px', '24px', '40px']} pr="24px" mb="16px">
        <GridItem colSpan={[12, 12, 5]}>
          <Flex alignItems="center">
            <Flex cursor="pointer" mr="10px">
              <Icon
                fontSize="25px"
                onClick={handleVoltarPainelAdm}
                as={FiChevronLeft}
              />
            </Flex>
            <SearchInput
              type="search"
              placeholder="Buscar forma de recebimento"
              onEnterKeyPress={() => getFormasPagamentoTray(getValues('nome'))}
              isDisabled={isLoading}
              id="nome"
              name="nome"
            />
          </Flex>
        </GridItem>
      </SimpleGridForm>
      <ContainerHeader
        mb="10px"
        pl={['24px', '24px', '40px']}
        justifyContent="space-between"
        pr="24px"
        h={['80px', '56px', '56px']}
      >
        <Flex
          color="white"
          justifyContent={['center', 'center', 'space-between']}
          w="full"
          direction={['column', 'column', 'row']}
          alignItems="center"
        >
          <Flex alignItems="center">
            <Box cursor="pointer" mr={['5px', '10px', '10px']}>
              <Icon fontSize="25px" as={PainelTrayIcon} />
            </Box>

            <Text fontSize="16px" ml={['0', '0', '10px']}>
              Formas de Recebimento
            </Text>
          </Flex>
          <Box>
            <Text fontSize="14px" textTransform="capitalize">
              {' '}
              {nomeCanalVenda}
            </Text>
          </Box>
        </Flex>
      </ContainerHeader>
      <Box px="24px" mb="20px" overflowX="auto">
        <Table variant="card" minW="730px" w="full">
          <Thead>
            <Tr
              sx={{
                '& th': {
                  color: 'gray.500',
                  fontSize: '10px',
                  paddingBottom: '0',
                },
              }}
            >
              <Th>Descrição utilizada no site</Th>
              <Th>Forma de recebimento cadastrada no sistema</Th>
              <Th>Conta financeira</Th>
              <Th isNumeric>Ações</Th>
            </Tr>
          </Thead>
          <Tbody>
            {listFormaRecebimento?.map(({ siteId, nome, zendarId }) => {
              const formaRecebimento = zendarId
                ? formaRecebimentoSistema.find(
                    (itemRecebimento) => itemRecebimento.id === zendarId
                  )
                : formaRecebimentoSistema?.find((itemRecebimento) =>
                    itemRecebimento?.nome
                      ?.normalize('NFD')
                      .replace(/[\u0300-\u036f]/g, '')
                      .toLowerCase()
                      .includes(
                        nome
                          ?.normalize('NFD')
                          .replace(/[\u0300-\u036f]/g, '')
                          .toLowerCase()
                      )
                  );

              const contaFinanceiraValue = contaFinanceira.find(
                (itemFinanciero) =>
                  itemFinanciero?.id === formaRecebimento?.contaFinanceiraId
              );

              return (
                <>
                  <Tr
                    sx={{
                      '& > td': {
                        height: '48px',
                      },
                    }}
                  >
                    <Td>{nome}</Td>
                    <Td>{formaRecebimento?.nome || '---'}</Td>
                    <Td>{contaFinanceiraValue?.nome || caixaAberto?.nome}</Td>
                    <Td isNumeric>
                      <Flex w="full" justifyContent="flex-end">
                        <ActionsMenu
                          items={[
                            {
                              content: 'Alterar forma vinculada',
                              onClick: () => {
                                ModalAdicionarDescricao({
                                  descricao: nome,
                                  siteId,
                                  getFormasPagamentoTray,
                                });
                              },
                            },
                          ]}
                        />
                      </Flex>
                    </Td>
                  </Tr>
                  <Box h="5px" />
                </>
              );
            })}
          </Tbody>
        </Table>
      </Box>
    </FormProvider>
  );
};
