import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';
import { Button, Flex } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import ModalidadesFreteEnum from 'constants/enum/fiscal/modalidadeFrete';
import ModelosFiscaisEnum from 'constants/enum/fiscal/modelosFiscais';
import { AmbienteFiscal } from 'constants/enum/fiscal/ambienteFiscal';
import StatusFiscaisEnum from 'constants/enum/fiscal/statusFiscal';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import PlanoContratacaoEnum from 'constants/enum/planoContratacao';
import gerarNotaFiscal from 'helpers/api/Operacao/gerarNotaFiscal';
import formatUTCToLocateDateTimeInput from 'helpers/format/formatUTCToLocateDateTimeInput';
import obterAmbienteFiscal from 'helpers/api/Loja/obterAmbienteFiscal';
import obterNotaFiscalAlterar, {
  DocumentoFiscal,
} from 'helpers/api/Operacao/obterNotaFiscalAlterar';
import auth from 'modules/auth';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import api, { ResponseApi } from 'services/api';
import { useSignalRContext } from 'store/SignalR';
import { useOperacoesEmitirNotaContext } from 'store/Operacoes/OperacoesEmitirNota';

import { RejeicaoInterface } from 'components/EmitirNotaFiscal/BoxRejeicao';
import {
  ModalTransmissaoNota,
  ModalTransmissaoNotaResponse,
} from 'components/EmitirNotaFiscal/ModalTransmissaoNota';
import { EmitirNFCe, EmitirNFCeData } from 'components/EmitirNotaFiscal/NFCe';
import { formattedValueCpfCnpj } from 'helpers/format/formattedValueCpfCnpj';

import { ModalSucessoEmissao } from '../ModalSucessoEmissao';

type TParams = { id: string };

export const NFCe = () => {
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [documentoFiscal, setDocumentoFiscal] = useState<DocumentoFiscal>();
  const [
    rejeicaoNotaFiscal,
    setRejeicaoNotaFiscal,
  ] = useState<RejeicaoInterface>();
  const [ambienteFiscal, setAmbienteFiscal] = useState<AmbienteFiscal>();

  const isMountedRef = useIsMountedRef();

  const { hubConnection, joinGroup, exitGroup } = useSignalRContext();
  const {
    operacao,
    informacoesLoja,
    temNFeAutorizada,
    handlePushVoltar,
    handleObterOperacao,
  } = useOperacoesEmitirNotaContext();

  const history = useHistory();
  const { id: operacaoId } = useParams<TParams>();

  const planoAtual = auth.getPlano();
  const isPlanoTeste = planoAtual === PlanoContratacaoEnum.TESTE;
  const possuiNFCe =
    operacao?.NFCe && operacao?.NFCe?.status === StatusFiscaisEnum.Autorizada;

  const cpfCnpjIsRequired =
    !!informacoesLoja &&
    informacoesLoja.nfCeExigirCNPJCPF &&
    !!operacao &&
    operacao.valorTotal >= informacoesLoja.nfCeValorExigenciaCNPJCPF;

  const documentoFiscalExistente = !!operacao && !!operacao.NFCe;

  const defaultValues = {
    cpfCnpj: documentoFiscal?.cpfCnpj
      ? formattedValueCpfCnpj(documentoFiscal.cpfCnpj)
      : undefined,
    operacaoComIntermediador: documentoFiscal?.operacaoComIntermediador,
    cnpjIntermediador: documentoFiscal?.cnpjIntermediador
      ? formattedValueCpfCnpj(documentoFiscal?.cnpjIntermediador, false, true)
      : undefined,
    identificacaoNoIntermediador: documentoFiscal?.identificacaoNoIntermediador,
  };

  const handleEmitirNFe = useCallback(() => {
    history.push(
      SubstituirParametroRota(
        ConstanteRotas.OPERACOES_EMITIR_NOTA_FISCAL_NFE,
        'id',
        operacaoId
      )
    );
  }, [history, operacaoId]);

  const transmitirDocumentoFiscal = useCallback(
    async (documentoFiscalId: string) => {
      const {
        success,
        rejeicao: newRejeicao,
      }: ModalTransmissaoNotaResponse = await ModalTransmissaoNota({
        documentoFiscalId,
        hubConnection,
        joinGroup,
        exitGroup,
      });

      if (success) {
        const { finalizar, emitirNFe } = await ModalSucessoEmissao({
          possuiNFe: !!operacao?.NFe,
          possuiNFCe: true,
          emissaoAtual: ModelosFiscaisEnum.NFCe,
        });
        if (finalizar) handlePushVoltar();
        if (emitirNFe) handleEmitirNFe();
      } else {
        setRejeicaoNotaFiscal(newRejeicao);
      }
    },
    [
      exitGroup,
      handleEmitirNFe,
      handlePushVoltar,
      hubConnection,
      joinGroup,
      operacao,
    ]
  );

  const obterTodasInformações = useCallback(async () => {
    if (operacaoId) {
      setIsLoading(true);

      const newAmbienteFiscal = await obterAmbienteFiscal();
      setAmbienteFiscal(newAmbienteFiscal);

      const newDocumentoFiscal = await obterNotaFiscalAlterar(
        operacaoId,
        ModelosFiscaisEnum.NFe
      );
      setDocumentoFiscal(newDocumentoFiscal);

      setIsLoading(false);
    }
  }, [operacaoId]);

  const handleSubmit: SubmitHandler<EmitirNFCeData> = async ({
    cpfCnpj,
    cnpjIntermediador,
    identificacaoNoIntermediador,
    operacaoComIntermediador,
  }) => {
    if (operacaoId) {
      setIsDisabledSubmit(true);
      setIsLoading(true);

      const documentoFiscalId = await gerarNotaFiscal(
        {
          operacaoId,
          cpfCnpj,
          modeloFiscal: ModelosFiscaisEnum.NFCe,
          operacaoComIntermediador,
          cnpjIntermediador,
          identificacaoNoIntermediador,
          dataEmissao: formatUTCToLocateDateTimeInput(new Date().toString()),
          modalidadeFrete: ModalidadesFreteEnum.SemFrete,
        },
        (aviso) => {
          try {
            const newRejeicao = JSON.parse(aviso) as RejeicaoInterface;

            setRejeicaoNotaFiscal(newRejeicao);
          } catch {
            setRejeicaoNotaFiscal({ Mensagem: aviso });
          }
        }
      );

      if (documentoFiscalId) {
        await transmitirDocumentoFiscal(documentoFiscalId);
      }

      setIsLoading(false);
      setIsDisabledSubmit(false);
    }
  };

  const handleSubmitAlterar: SubmitHandler<EmitirNFCeData> = async ({
    cpfCnpj,
    cnpjIntermediador,
    identificacaoNoIntermediador,
    operacaoComIntermediador,
  }) => {
    setIsLoading(true);
    setIsDisabledSubmit(true);
    if (operacao && operacao.NFCe) {
      const data = {
        id: operacao.NFCe.id,
        status: StatusFiscaisEnum.EmDigitacao,
        cpfCnpj,
        operacaoComIntermediador,
        cnpjIntermediador,
        identificacaoNoIntermediador,
        dataEmissao: formatUTCToLocateDateTimeInput(new Date().toString()),
      };

      const response = await api.put<void, ResponseApi>(
        ConstanteEnderecoWebservice.NOTA_FISCAL_ALTERAR_PDV,
        data
      );

      if (response) {
        if (response?.sucesso) {
          await transmitirDocumentoFiscal(operacao.NFCe.id);
        }

        if (response?.avisos) {
          try {
            const newRejeicao = JSON.parse(
              response?.avisos[0]
            ) as RejeicaoInterface;

            setRejeicaoNotaFiscal(newRejeicao);
          } catch {
            setRejeicaoNotaFiscal({ Mensagem: response?.avisos[0] });
          }
        }
      }
    }

    setIsLoading(false);
    setIsDisabledSubmit(false);
  };

  useEffect(() => {
    obterTodasInformações();
  }, [obterTodasInformações]);

  useEffect(() => {
    handleObterOperacao();
  }, [handleObterOperacao]);

  useEffect(() => {
    if (possuiNFCe && !isMountedRef.current) {
      toast.warn('Já existe uma NFC-e para essa operação');
      handlePushVoltar();
    }
  }, [handlePushVoltar, isMountedRef, possuiNFCe]);

  useEffect(() => {
    if (isPlanoTeste) {
      history.push(ConstanteRotas.DASHBOARD);
    }
  }, [history, isPlanoTeste]);

  return (
    <>
      <EmitirNFCe
        ambienteFiscal={ambienteFiscal}
        defaultValues={documentoFiscal ? defaultValues : undefined}
        cpfCnpjIsRequired={cpfCnpjIsRequired}
        onSubmit={documentoFiscalExistente ? handleSubmitAlterar : handleSubmit}
        isDisabledSubmit={isDisabledSubmit}
        rejeicaoNotaFiscal={rejeicaoNotaFiscal}
        isLoading={isLoading}
        hasNfeAutorizada={temNFeAutorizada}
      >
        <Flex
          justifyContent="center"
          alignItems="center"
          padding={temNFeAutorizada ? '12px' : '0px'}
        >
          <Button
            size="sm"
            w="full"
            minW="96px"
            maxW={['full', 'full', '120px']}
            variant="outline"
            onClick={handlePushVoltar}
          >
            Voltar
          </Button>
        </Flex>
      </EmitirNFCe>
    </>
  );
};
