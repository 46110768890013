import { useFormContext } from 'react-hook-form';

import { Sliders } from 'components/Sliders';
import { ImpressaoCupomSmartPos } from 'components/SmartPos/ImpressaoCupom';
import { ImpressaoFicha } from 'components/SmartPos/ImpressaoFicha';

type ExibirImpressaoProps = {
  isTodasImpressoes: boolean;
  isFichaImpressao: boolean;
  bg?: string;
  imagem?: string;
  isImprimirSenha?: boolean;
};

export const ExibirImpressao = ({
  isTodasImpressoes,
  isFichaImpressao,
  bg,
  imagem,
  isImprimirSenha,
}: ExibirImpressaoProps) => {
  const { watch } = useFormContext();

  const [tituloWatch, mensagemWatch, mensagemCupomWatch, scaleImg] = watch([
    'titulo',
    'mensagem',
    'mensagemCupom',
    'scaleImg',
  ]);

  const scaleImgEmPorcentagem = scaleImg ? `${Math.round(scaleImg)}%` : '100%';

  return (
    <Sliders
      bg={bg}
      slide={[
        {
          content: (
            <ImpressaoFicha
              imagem={imagem}
              tituloWatch={tituloWatch}
              mensagemWatch={mensagemWatch}
              scaleImg={scaleImgEmPorcentagem}
            />
          ),
          key: 'impresaoFicha',
          isHidden: !(isTodasImpressoes || isFichaImpressao),
        },
        {
          content: (
            <ImpressaoCupomSmartPos
              imagem={imagem}
              tituloWatch={tituloWatch}
              isImprimirSenha={isImprimirSenha}
              mensagemWatch={
                isTodasImpressoes || !isFichaImpressao
                  ? mensagemCupomWatch
                  : mensagemWatch
              }
              scaleImg={scaleImgEmPorcentagem}
            />
          ),
          key: 'impresaoCupom',
          isHidden: !(isTodasImpressoes || !isFichaImpressao),
        },
      ]}
    />
  );
};
