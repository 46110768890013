import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { IdentificacaoTipoOperacao } from 'constants/enum/identificacaoTipoOperacao';
import { ModelosFiscais } from 'constants/enum/fiscal/modelosFiscais';
import { StatusFiscais } from 'constants/enum/fiscal/statusFiscal';

export interface DocumentoFiscal {
  id: string;
  modeloFiscal: ModelosFiscais;
  status: StatusFiscais;
  numero: string;
  dataEmissao: Date;

  cpfCnpj?: string;
  transportadoraOpcaoSelect?: { id: string; nome: string };

  dadosAdicionais: string;
  dadosAdicionaisTributos?: string;
  numeroPedidoExterno: string;
  modalidadeFrete: number;
  placaTransportadora: string;
  ufVeiculoTransportadora: string;
  pesoLiquido: number;
  pesoBruto: number;
  quantidadeVolumeTransportadora: number;
  especieVolumeTransportadora: string;
  marcaVolumeTransportadora: string;
  numeracaoVolumeTransportadora: string;
  localEmbarque?: string;
  ufEmbarque?: string;

  operacaoComIntermediador: number;
  cnpjIntermediador: string;
  identificacaoNoIntermediador: string;
}

interface ContatoAdicional {
  email: string;
  nome: string;
  telefone: string;
}

interface Cliente {
  celular?: string;
  email?: string;
  nome: string;
  cpfCnpj?: string;
  telefone?: string;
  contatosAdicionais: ContatoAdicional[];
  paisId?: number;
}

export interface OperacaoObterParaFinalizar {
  numeroOperacao: number;
  valorTotal: number;
  identificacaoTipoOperacao: IdentificacaoTipoOperacao;
  cliente: Cliente;
  documentosFiscais: DocumentoFiscal[];
  pesoLiquido?: number;
  pesoBruto?: number;
  boletoLink?: boolean;
  pixLink?: boolean;
}

const obterOperacaoParaFinalizar = async (
  id: string
): Promise<OperacaoObterParaFinalizar | undefined> => {
  const response = await api.get<void, ResponseApi<OperacaoObterParaFinalizar>>(
    ConstanteEnderecoWebservice.PEDIDOORCAMENTOVENDA_OBTER_PARA_FINALIZAR,
    { params: { id } }
  );

  if (response?.avisos) {
    response.avisos.map((item: string) => toast.warning(item));
  }

  if (response?.sucesso) {
    return response.dados;
  }

  return undefined;
};

export default obterOperacaoParaFinalizar;
