import React, { useEffect, useState } from 'react';
import {
  Menu,
  MenuProps,
  MenuList,
  Grid,
  Button,
  Box,
  Text,
} from '@chakra-ui/react';

import PortalFullscreen from 'components/PDV/Geral/PortalFullscreen';

const days = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
];

interface MenuDiaInterface extends Omit<MenuProps, 'children'> {
  MenuButton: () => JSX.Element;
  OnSelectDay: (selectedDay: number) => void;
  isDisabled?: boolean;
  children?: never; // apenas para proibir a passagem da prop
}

const MenuDia = ({
  MenuButton,
  OnSelectDay,
  isDisabled,
  ...rest
}: MenuDiaInterface) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isDisabled) {
      setIsOpen(false);
    }
  }, [isDisabled]);

  return (
    <Menu
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      isOpen={isOpen}
      {...rest}
    >
      <MenuButton />
      {isOpen && (
        <PortalFullscreen>
          <MenuList zIndex="modal" p={0}>
            <Box bg="primary.500" px={6} py={3}>
              <Text color="secondary.500">Selecione o dia</Text>
            </Box>
            <Grid
              py={2}
              px={6}
              gap={2}
              templateColumns="repeat(7, 1fr)"
              w="full"
              h="full"
            >
              {days.map((day) => (
                <Button
                  key={day}
                  variant="ghost"
                  size="xs"
                  onClick={() => {
                    OnSelectDay(day);
                    setIsOpen(false);
                  }}
                >
                  {day}
                </Button>
              ))}
            </Grid>
          </MenuList>
        </PortalFullscreen>
      )}
    </Menu>
  );
};

export default MenuDia;
