import { useHistory } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';

type Lojas = {
  id: string;
  razaoSocial: string;
  fantasia: string;
  endereco: {
    logradouro: string;
    bairro: string;
    numero: string;
    cep: string;
    cidade: string;
    estado: string;
    uf: string;
  };
};

const useDeliverySectorForm = () => {
  const history = useHistory();

  const [listLojas, setListLojas] = useState<Lojas[]>([]);

  const handleToDeliverySector = () => {
    history.push(ConstanteRotas.FRENTE_CAIXA_SETORES_ENTREGA);
  };

  const getLojas = useCallback(async () => {
    const response = await api.get<void, ResponseApi<Lojas[]>>(
      ConstanteEnderecoWebservice.LISTAR_LOJA_ENDERECO
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso) {
        setListLojas(response.dados);
      }
    }
  }, []);

  useEffect(() => {
    getLojas();
  }, [getLojas]);

  return {
    listLojas,
    handleToDeliverySector,
  };
};

export { useDeliverySectorForm };
