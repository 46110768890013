import React from 'react';
import {
  Button,
  ButtonProps,
  Flex,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';

const ButtonLink = ({ children, ...rest }: ButtonProps) => {
  const size = useBreakpointValue({ base: 'xs', xl: 'sm' });

  return (
    <Flex gap="2px" align="center">
      <Button
        variant="link"
        size={size}
        color="purple.50"
        _hover={{ color: 'aquamarine.300' }}
        _active={{ color: 'aquamarine.400' }}
        textDecor="underline"
        fontSize="14px"
        p={1}
        {...rest}
      >
        {children}
      </Button>
      <Text as="span" fontSize="14px" color="white">
        &gt;
      </Text>
    </Flex>
  );
};

export default ButtonLink;
