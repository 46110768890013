import React, { createContext, useContext, useCallback } from 'react';

import PlanoContaInterface from 'types/planoConta';

import { usePlanoContasGeralContext } from './PlanoContasGeral';

interface PlanoContasVisaoCaixaContextProps {
  obterPlanoContasPeloTipo: (
    tipoPlanoConta: number,
    paiId?: string,
    nivel?: number
  ) => PlanoContaInterface[];
}

const PlanoContasVisaoCaixaContext = createContext<PlanoContasVisaoCaixaContextProps>(
  {} as PlanoContasVisaoCaixaContextProps
);

interface PlanoContasProviderProps {
  children: React.ReactNode;
}

export default function PlanoContasProvider({
  children,
}: PlanoContasProviderProps): JSX.Element {
  const { planosConta } = usePlanoContasGeralContext();

  const obterPlanoContasPeloTipo = useCallback(
    (tipoPlanoConta: number, paiId?: string, nivel = 2) => {
      return planosConta
        .filter(
          (planoConta) =>
            (!paiId &&
              planosConta.some(
                (p) =>
                  p.planoContaPaiId === planoConta.id &&
                  p.tipoPlanoConta === tipoPlanoConta &&
                  (p.indice.match(/\./g) || []).length > 2
              )) ||
            ((!paiId || planoConta.planoContaPaiId === paiId) &&
              planoConta.tipoPlanoConta === tipoPlanoConta &&
              (planoConta.indice.match(/\./g) || []).length === nivel)
        )
        .sort((a, b) => {
          if (
            Number(a.indice.replaceAll('.', '')) <
            Number(b.indice.replaceAll('.', ''))
          ) {
            return -1;
          }
          if (
            Number(a.indice.replaceAll('.', '')) >
            Number(b.indice.replaceAll('.', ''))
          ) {
            return 1;
          }
          return 0;
        });
    },
    [planosConta]
  );

  return (
    <PlanoContasVisaoCaixaContext.Provider
      value={{
        obterPlanoContasPeloTipo,
      }}
    >
      {children}
    </PlanoContasVisaoCaixaContext.Provider>
  );
}

export function usePlanoContasVisaoCaixaContext(): PlanoContasVisaoCaixaContextProps {
  const context = useContext(PlanoContasVisaoCaixaContext);

  if (!context)
    throw new Error(
      'usePlanoContasVisaoCaixaContext must be used within a PlanoContasProvider.'
    );

  return context;
}
