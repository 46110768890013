import { useCallback, useEffect, useImperativeHandle } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';
import { useProdutosFormularioContext } from 'store/Produtos/ProdutosFormulario';
import { useValidationFormDirtyFields } from 'hooks/useValidationFormDirtyFields';

type FoodFields = {
  produtoId: string;
  utilizarBalanca: boolean;
  exportarBalanca: boolean;
  usarComoComplemento: boolean;
  produtoCombo: boolean;
  cobrarTaxaServico: boolean;
  baixarSaldoMateriaPrima: boolean;
  composicaoProduto: string;
  diasParaValidade: number;
  imagemCardapio: string;
  imagemDestaque: string;
  precoCombo: number;
  utilizarPrecoDosItensEtapa: boolean;
  gerenciadorImpressao: {
    label: string;
    value: string;
  };
};

type GerenciadorResponse = {
  gerenciadorImpressao: {
    gerenciadorImpressaoId: string;
    gerenciadorImpressao: string;
  };
};

type FoodResponse = FoodFields & GerenciadorResponse;

export const useFood = () => {
  const { setIsLoading, idProduto, ref } = useProdutosFormularioContext();
  const {
    casasDecimais: { casasDecimaisValor },
  } = usePadronizacaoContext();

  const formMethods = useForm<FoodFields>({
    defaultValues: {
      cobrarTaxaServico: true,
    },
  });

  const {
    reset,
    getValues,
    formState: { dirtyFields },
    setValue,
    control,
  } = formMethods;

  const foodFieldsIsDirty = useValidationFormDirtyFields(dirtyFields);

  const getFoodFields = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get<void, ResponseApi<FoodResponse>>(
      ConstanteEnderecoWebservice.PRODUTOS_INFORMACOES_FOOD.replace(
        'id',
        idProduto
      )
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response?.sucesso && response?.dados) {
        const printManager = response.dados?.gerenciadorImpressao;
        reset(response.dados);

        if (printManager) {
          setValue('gerenciadorImpressao', {
            label: printManager?.gerenciadorImpressao || '',
            value: printManager?.gerenciadorImpressaoId || '',
          });
        }
      }
    }
    setIsLoading(false);
  }, [idProduto, reset, setIsLoading, setValue]);

  const updateFoodFields = useCallback(async () => {
    if (foodFieldsIsDirty === false) {
      return true;
    }

    setIsLoading(true);

    const data = getValues();

    const images = {
      imagemDestaque: data.imagemDestaque?.includes('base64,')
        ? data.imagemDestaque.split('base64,')[1]
        : data.imagemDestaque,
      imagemCardapio: data.imagemCardapio?.includes('base64,')
        ? data.imagemCardapio.split('base64,')[1]
        : data.imagemCardapio,
    };

    const response = await api.patch<void, ResponseApi<boolean>>(
      ConstanteEnderecoWebservice.PRODUTOS_INFORMACOES_FOOD.replace(
        'id',
        idProduto
      ),
      {
        ...data,
        ...images,
        produtoId: idProduto,
        gerenciadorImpressaoId: data.gerenciadorImpressao?.value,
      }
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));

        setIsLoading(false);
        return false;
      }

      if (response?.sucesso) {
        setIsLoading(false);
        return true;
      }
    }

    setIsLoading(false);
    return false;
  }, [getValues, idProduto, foodFieldsIsDirty, setIsLoading]);

  const getPrintManager = useCallback(
    async (inputValue: string) => {
      setIsLoading(true);
      const response = await api.get<
        void,
        ResponseApi<{ id: string; nome: string }[]>
      >(ConstanteEnderecoWebservice.GERENCIADOR_IMPRESSAO_SELECT, {
        params: { pesquisa: inputValue || '' },
      });

      if (response?.sucesso && response?.dados) {
        const newListPrintManagers = response.dados.map((item) => ({
          label: item.nome,
          value: item.id,
        }));
        setIsLoading(false);
        return newListPrintManagers;
      }
      setIsLoading(false);
      return [];
    },
    [setIsLoading]
  );

  useImperativeHandle(ref, () => ({
    onClick: () => updateFoodFields(),
  }));

  useEffect(() => {
    getFoodFields();
  }, [getFoodFields]);

  return {
    formMethods,
    casasDecimaisValor,
    getPrintManager,
    control,
  };
};
