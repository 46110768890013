import { useToken } from '@chakra-ui/react';
import React from 'react';

import TipoSistemaEnum from 'constants/enum/tipoSistema';
import auth from 'modules/auth';

import { IconType } from 'icons';

import { ZendarFullLogo, ZendarSymbol } from './zendar';
import { PowerStockFullLogo, PowerStockSymbol } from './powerstock';
import { FomerFullLogo, FomerSymbol, FomerLoginLogo } from './fomer';

export const LoginLogo = () => {
  const sistema = auth.getSistema() || '';
  const secondary400 = useToken('colors', 'secondary.400');

  switch (sistema.value) {
    case TipoSistemaEnum.ZENDAR:
      return (
        <ZendarFullLogo
          style={{
            color: `${secondary400}`,
            alignSelf: 'center',
            width: '200px',
            height: '40px',
            margin: '1rem 24px 3rem 0',
          }}
        />
      );
    case TipoSistemaEnum.POWERSTOCK:
      return (
        <PowerStockFullLogo
          style={{
            alignSelf: 'center',
            width: '200px',
            height: '40px',
            margin: '1rem 24px 3rem 0',
          }}
        />
      );
    case TipoSistemaEnum.FOMER:
      return (
        <FomerLoginLogo
          size={250}
          style={{
            alignSelf: 'center',
            width: '200px',
            height: '40px',
            margin: '0 32px 3rem 0',
          }}
        />
      );
    case TipoSistemaEnum.FOMER_SISTEMA:
      return (
        <FomerLoginLogo
          size={250}
          style={{
            alignSelf: 'center',
            width: '200px',
            height: '40px',
            margin: '0 32px 3rem 0',
          }}
        />
      );
    case TipoSistemaEnum.POWERCHEF:
      return (
        <PowerStockFullLogo
          style={{
            alignSelf: 'center',
            width: '200px',
            height: '40px',
            margin: '1rem 24px 3rem 0',
          }}
        />
      );

    default:
      return (
        <ZendarFullLogo
          style={{
            color: `${secondary400}`,
            alignSelf: 'center',
            width: '200px',
            height: '40px',
            margin: '1rem 24px 3rem 0',
          }}
        />
      );
  }
};

export const OpenMenuLogo = () => {
  const sistema = auth.getSistema() || '';
  const secondary400 = useToken('colors', 'secondary.400');

  switch (sistema.value) {
    case TipoSistemaEnum.ZENDAR:
      return (
        <ZendarFullLogo
          style={{
            fill: `${secondary400}`,
            color: `${secondary400}`,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            fontSize: 0,
            marginLeft: 0,
            opacity: 1,
            width: '96px',
            height: '44px',
            transition: 'all 0.2s ease',
          }}
        />
      );
    case TipoSistemaEnum.POWERSTOCK:
      return <PowerStockFullLogo size={120} height={40} />;
    case TipoSistemaEnum.FOMER:
      return <FomerFullLogo size={120} height={22} />;
    case TipoSistemaEnum.FOMER_SISTEMA:
      return <FomerFullLogo size={120} height={22} />;
    case TipoSistemaEnum.POWERCHEF:
      return <PowerStockFullLogo size={120} height={40} />;

    default:
      return (
        <ZendarFullLogo
          style={{
            fill: `${secondary400}`,
            color: `${secondary400}`,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            fontSize: 0,
            marginLeft: 0,
            opacity: 1,
            transition: 'all 0.2s ease',
            width: '96px',
            height: '44px',
          }}
        />
      );
  }
};

export const ClosedMenuLogo: IconType = ({ color, size }) => {
  const secondary400 = useToken('colors', 'secondary.400');
  const sistema = auth.getSistema() || '';

  switch (sistema.value) {
    case TipoSistemaEnum.ZENDAR:
      return (
        <ZendarSymbol
          color={color || secondary400}
          style={{
            fontSize: size || '1.5rem',
            minWidth: size || '1.5rem',
          }}
        />
      );
    case TipoSistemaEnum.POWERSTOCK:
      return <PowerStockSymbol width="48px" height="48px" />;
    case TipoSistemaEnum.FOMER:
      return <FomerSymbol width="48px" height="48px" />;
    case TipoSistemaEnum.FOMER_SISTEMA:
      return <FomerSymbol width="48px" height="48px" />;
    case TipoSistemaEnum.POWERCHEF:
      return <PowerStockSymbol width="48px" height="48px" />;

    default:
      return (
        <ZendarSymbol
          color={color || secondary400}
          style={{
            fontSize: size || '1.5rem',
            minWidth: size || '1.5rem',
          }}
        />
      );
  }
};

export const PDVHeaderLogo = () => {
  const sistema = auth.getSistema() || '';

  switch (sistema.value) {
    case TipoSistemaEnum.ZENDAR:
      return <ZendarFullLogo size={100} />;
    case TipoSistemaEnum.POWERSTOCK:
      return <PowerStockFullLogo size={130} />;
    case TipoSistemaEnum.FOMER:
      return <FomerFullLogo size={130} height={22} />;
    case TipoSistemaEnum.FOMER_SISTEMA:
      return <FomerFullLogo size={130} height={22} />;
    case TipoSistemaEnum.POWERCHEF:
      return <PowerStockFullLogo size={130} />;

    default:
      return <ZendarFullLogo size={100} />;
  }
};
