import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Text,
  Stack,
  VStack,
  HStack,
  IconButton,
  Icon,
  Button,
  useBreakpointValue,
  Divider,
  Flex,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import currency from 'currency.js';

import { useOperacaoContext } from 'store/PDV/Operacao';
import { useEditarParcelasContext } from 'store/PDV/EditarParcelas';
import { DecimalMask } from 'helpers/format/fieldsMasks';
import useWindowSize from 'helpers/layout/useWindowSize';
import { usePagamentoContext } from 'store/PDV/Pagamento';
import { SubstituirParametroRota } from 'constants/rotas';
import ConstanteRotasPDV from 'constants/rotasPDV';
import MeioPagamentoEnum from 'constants/enum/fiscal/meioPagamento';
import { useInformacoesGeraisContext } from 'store/PDV/InformacoesGerais';

import { LixeiraIcon, SalvarInserirNovoIcon } from 'icons';
import ConditionalWrapper from 'components/Geral/ConditionalWrapper';
import ShadowScrollbar, {
  ShadowScrollbarForwardRefProps,
} from 'components/PDV/Geral/ShadowScrollbar';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import ModalConfirmacao from 'components/PDV/Modal/ModalConfirmacao';
import { ModalConfirmarExcluirStone } from 'components/PDV/Modal/ModalConfirmarExcluirStone';
import { TextValor } from 'components/PDV/Text/TextValor';

import { useListagemMovimentacaoFinanceira } from './hooks';
import { ContainerMovimentacoesFinanceiras } from './ContainerMovimentacoesFinanceiras';

import MobileInternalHeader from '../../Mobile/InternalHeader';

interface ListagemMovimentacoesFinanceirasProps {
  onModalAdicionarPagamentoOpen: () => void;
}

export const ListagemMovimentacoesFinanceiras = ({
  onModalAdicionarPagamentoOpen,
}: ListagemMovimentacoesFinanceirasProps) => {
  const history = useHistory();

  const asMobileView = useBreakpointValue({ base: true, md: false });

  const { operacaoId } = useOperacaoContext();
  const { movimentacaoFinanceiraEditando } = useEditarParcelasContext();
  const { height: windowHeight } = useWindowSize();
  const {
    totais,
    setTotais,
    movimentacoesFinanceiras,
    setMovimentacoesFinanceiras,
    valorRestante,
    valorPago,
    acrescimoFoiLimpo,
    setAcrescimoFoiLimpo,
    setAparecerValorAcrescimoHeaderPdv,
    troco,
    valorFaltaPagar,
    somarValorTotalPagoSemAcrescimo,
    totais: { valorTotal = 0 } = {},
  } = usePagamentoContext();
  const { contaFinanceira } = useInformacoesGeraisContext();
  const {
    removeByIdentificadorAgrupamento,
    removeAll,
  } = useListagemMovimentacaoFinanceira();

  const [isLoading, setIsLoading] = useState(false);
  const [modalConfirmacaoIsOpen, setModalConfirmacaoIsOpen] = useState(false);

  const valorTroco = (movimentacoesFinanceiras || []).reduce(
    (previousValue, currentValue) => previousValue + currentValue.troco,
    0
  );

  const isMeioPagamentoStone =
    MeioPagamentoEnum.CartaoDebitoStone || MeioPagamentoEnum.CartaoCreditoStone;

  const shadowScrollbarRef = useRef<ShadowScrollbarForwardRefProps>(null);

  const hasMovimentacaoComPagamentoStone = (
    movimentacoesFinanceiras || []
  )?.some((movimentacao) => {
    return movimentacao?.regraMeioPagamento === isMeioPagamentoStone;
  });

  async function handleVoltar() {
    if (operacaoId) {
      history.push(
        SubstituirParametroRota(
          ConstanteRotasPDV.PDV_LANCAMENTO_ID,
          'id?',
          operacaoId
        )
      );
    }
  }

  const handleDeleteAllMovimentacoesFinanceiras = async () => {
    setIsLoading(true);

    const success = await removeAll({ operacaoId: operacaoId || '' });

    if (success) {
      if (totais) {
        setTotais({
          ...totais,
          valorTotalAcrescimo: 0,
          valorTotal:
            (totais?.valorTotal || 0) - (totais?.valorTotalAcrescimo || 0),
        });
      }
      setMovimentacoesFinanceiras([]);
    }
    setAcrescimoFoiLimpo(false);
    setAparecerValorAcrescimoHeaderPdv(false);
    setIsLoading(false);
  };

  const handleDeleteMovimentacaoFinanceira = async (
    identificadorAgrupamento: string
  ) => {
    setIsLoading(true);

    const success = await removeByIdentificadorAgrupamento({
      identificadorAgrupamento,
    });

    if (success && movimentacoesFinanceiras) {
      const movimentacaoFinanceiraExcluidaIndex = movimentacoesFinanceiras.findIndex(
        (movimentacaoFinanceira) =>
          movimentacaoFinanceira.identificacaoAgrupamento ===
          identificadorAgrupamento
      );

      const newMovimentacoesFinanceiras = [...movimentacoesFinanceiras];
      newMovimentacoesFinanceiras.splice(
        movimentacaoFinanceiraExcluidaIndex,
        1
      );

      if (totais) {
        setTotais({
          ...totais,
          valorTotalAcrescimo: acrescimoFoiLimpo
            ? 0
            : (totais?.valorTotalAcrescimo || 0) -
              movimentacoesFinanceiras[movimentacaoFinanceiraExcluidaIndex]
                .acrescimo,
          valorTotal:
            (totais?.valorTotal || 0) -
            movimentacoesFinanceiras[movimentacaoFinanceiraExcluidaIndex]
              .acrescimo,
        });
      }

      setMovimentacoesFinanceiras([...newMovimentacoesFinanceiras]);
    }

    setIsLoading(false);
  };

  const arredondarValorTroco = () => {
    const result = Math.round((valorTroco + Number.EPSILON) * 100) / 100;
    return result;
  };
  const valorTrocoArredondado = arredondarValorTroco();

  useEffect(() => {
    setTimeout(() => {
      if (shadowScrollbarRef.current)
        shadowScrollbarRef.current.handleUpdateScrollbar();
    }, 200);
  }, [movimentacoesFinanceiras]);

  return (
    <Box>
      {asMobileView && (
        <MobileInternalHeader
          label="Total a pagar:"
          value={valorTotal}
          handleVoltar={handleVoltar}
        />
      )}
      <Box
        pl={{ base: 6, md: 8, xl: 12 }}
        py={5}
        maxH="100%"
        position="relative"
      >
        {isLoading && <LoadingPadrao />}

        <HStack
          spacing={2}
          mb={{ base: 5, md: 3 }}
          pr={{ base: 6, md: 8, xl: 12 }}
          w="full"
          justifyContent="space-between"
        >
          {!asMobileView && (
            <HStack
              spacing={2}
              w="full"
              fontWeight="semibold"
              lineHeight="none"
            >
              <Text fontSize={{ base: 'xs', lg: 'sm', xl: 'md' }}>
                Falta pagar:
              </Text>
              <Text
                fontSize={{ base: 'sm', lg: 'md', xl: 'lg' }}
                color="red.500"
              >
                {currency(valorFaltaPagar).format({
                  precision: 2,
                  separator: '.',
                  decimal: ',',
                  symbol: 'R$ ',
                })}
              </Text>
            </HStack>
          )}

          {asMobileView && (
            <Button
              variant="link"
              colorScheme="purple"
              p="1"
              py="0"
              fontWeight="normal"
              leftIcon={<Icon as={SalvarInserirNovoIcon} boxSize="5" />}
              onClick={onModalAdicionarPagamentoOpen}
              isDisabled={valorRestante <= 0}
            >
              Adicionar pagamento
            </Button>
          )}

          {movimentacoesFinanceiras && movimentacoesFinanceiras.length > 0 && (
            <>
              <IconButton
                aria-label="Remover tudo"
                variant="link"
                icon={<Icon as={LixeiraIcon} fontSize="xl" />}
                size="sm"
                justifySelf="flex-end"
                onClick={() => {
                  if (
                    hasMovimentacaoComPagamentoStone &&
                    contaFinanceira &&
                    contaFinanceira?.serialPOS
                  ) {
                    ModalConfirmarExcluirStone({
                      confirmaExclusao: () => {
                        setModalConfirmacaoIsOpen(true);
                      },
                    });
                  } else {
                    setModalConfirmacaoIsOpen(true);
                  }
                }}
                isDisabled={!!movimentacaoFinanceiraEditando}
              />

              {modalConfirmacaoIsOpen && (
                <ModalConfirmacao
                  isOpen={modalConfirmacaoIsOpen}
                  setIsOpen={setModalConfirmacaoIsOpen}
                  textoCabecalho="Confirmar"
                  textoMensagem="Todas as movimentações financeiras serão excluídas, deseja continuar?"
                  onConfirm={() => {
                    handleDeleteAllMovimentacoesFinanceiras();
                  }}
                />
              )}
            </>
          )}
        </HStack>
        {movimentacoesFinanceiras && movimentacoesFinanceiras.length > 0 && (
          <VStack spacing={3} w="full" sx={{ '& > div': { w: 'full' } }}>
            <ConditionalWrapper
              condition
              wrapper={(wrappedChildren) => (
                <ShadowScrollbar
                  ref={shadowScrollbarRef}
                  width="100%"
                  paddingRight="8px"
                  maxHeight={windowHeight - 375}
                  paddingTop="0"
                  shadowTopStyle={{
                    background: `transparent linear-gradient(180deg, ${
                      asMobileView
                        ? 'var(--sti-ck-colors-gray-50)'
                        : 'var(--sti-ck-colors-gray-100)'
                    }  0%,  #FFFFFF00 100%) 0% 0% no-repeat padding-box`,
                    height: 30,
                  }}
                  shadowBottomStyle={{
                    background: `transparent linear-gradient(180deg, #FFFFFF00 0%, ${
                      asMobileView
                        ? 'var(--sti-ck-colors-gray-50)'
                        : 'var(--sti-ck-colors-gray-100)'
                    } 100%) 0% 0% no-repeat padding-box`,
                    height: 30,
                  }}
                >
                  <Box pl="2px" px="2px" pr={{ base: 6, md: 8, xl: 12 }}>
                    {wrappedChildren}
                  </Box>
                </ShadowScrollbar>
              )}
            >
              <VStack
                w="full"
                boxShadow={{
                  base: '0px 0px 4px 0px #00000029',
                  md: 'none',
                }}
                borderRadius="md"
                spacing={{ base: '0', md: '2' }}
                bg={{ base: 'white', md: 'transparent' }}
              >
                {movimentacoesFinanceiras.map(
                  (movimentacaoFinanceira, index) => (
                    <ContainerMovimentacoesFinanceiras
                      key={movimentacaoFinanceira.identificacaoAgrupamento}
                      movimentacaoFinanceira={movimentacaoFinanceira}
                      index={index}
                      handleDeleteMovimentacaoFinanceira={
                        handleDeleteMovimentacaoFinanceira
                      }
                    />
                  )
                )}
                {asMobileView && (
                  <>
                    <Box px="4" w="full">
                      <Divider borderColor="gray.100" />
                    </Box>
                    <Flex w="full" justifyContent="flex-end" py="3" px="9">
                      <Text mr="1" fontSize="xs">
                        Total pago:
                      </Text>
                      <TextValor
                        valor={
                          acrescimoFoiLimpo
                            ? somarValorTotalPagoSemAcrescimo
                            : valorPago
                        }
                        fontSize="md"
                        fontWeight="semibold"
                        color="blue.700"
                        symbolFontSize="xs"
                      />
                    </Flex>
                  </>
                )}
              </VStack>
            </ConditionalWrapper>

            {asMobileView && valorPago < valorTotal && (
              <Box pr={{ base: 6, md: 8, xl: 12 }}>
                <Flex
                  w="full"
                  justifyContent="flex-end"
                  py="2"
                  px="9"
                  bg="red.500"
                  borderRadius="md"
                >
                  <Text mr="1" fontSize="xs" color="white">
                    Falta pagar:
                  </Text>
                  <TextValor
                    valor={valorFaltaPagar}
                    fontSize="md"
                    fontWeight="semibold"
                    color="white"
                    symbolFontSize="xs"
                  />
                </Flex>
              </Box>
            )}

            {asMobileView && troco > 0 && (
              <Box pr={{ base: 6, md: 8, xl: 12 }}>
                <Flex
                  w="full"
                  justifyContent="flex-end"
                  py="2"
                  px="9"
                  bg="yellow.500"
                  borderRadius="md"
                >
                  <Text mt="3px" mr="1" fontSize="xs" color="black">
                    Troco:
                  </Text>
                  <Text
                    mt="3px"
                    mr="5px"
                    fontWeight="semibold"
                    fontSize="xs"
                    color="black"
                  >
                    R$
                  </Text>
                  <Text fontSize="md" fontWeight="semibold" color="black">
                    {currency(valorTrocoArredondado).format({
                      precision: 2,
                      separator: '.',
                      decimal: ',',
                      symbol: '',
                    })}
                  </Text>
                </Flex>
              </Box>
            )}

            {!asMobileView && (
              <Box pr={{ base: 6, md: 8, xl: 12 }}>
                <Box
                  bg="gray.200"
                  px={6}
                  py={3}
                  boxShadow="md"
                  borderRadius="md"
                  w="full"
                  h={{ base: '90px', md: '70px' }}
                >
                  <Stack
                    direction={{ base: 'column-reverse', sm: 'row' }}
                    h="full"
                    w="full"
                    justifyContent={{ base: 'center', sm: 'space-around' }}
                    alignItems={{ base: 'flex-end', sm: 'center' }}
                    spacing={1}
                  >
                    <HStack>
                      <Text
                        fontSize={{ base: 'xs', xl: 'sm' }}
                        color="gray.500"
                      >
                        TROCO:
                      </Text>
                      <HStack spacing={1} color="pink.500">
                        <Text fontSize={{ base: 'xs', xl: 'sm' }}>R$</Text>
                        <Text
                          fontSize={{ base: 'lg', lg: 'xl', xl: '2xl' }}
                          fontWeight="extrabold"
                        >
                          {currency(valorTrocoArredondado).format({
                            precision: 2,
                            separator: '.',
                            decimal: ',',
                            symbol: '',
                          })}
                        </Text>
                      </HStack>
                    </HStack>
                    <HStack>
                      <Text
                        fontSize={{ base: 'xs', xl: 'sm' }}
                        color="gray.500"
                      >
                        TOTAL PAGO:
                      </Text>
                      <HStack spacing={1} color="blue.500">
                        <Text fontSize={{ base: 'xs', xl: 'sm' }}>R$</Text>
                        <Text
                          fontSize={{ base: 'lg', lg: 'xl', xl: '2xl' }}
                          fontWeight="extrabold"
                        >
                          {acrescimoFoiLimpo
                            ? DecimalMask(somarValorTotalPagoSemAcrescimo, 2)
                            : DecimalMask(valorPago, 2)}
                        </Text>
                      </HStack>
                    </HStack>
                  </Stack>
                </Box>
              </Box>
            )}
          </VStack>
        )}
      </Box>
    </Box>
  );
};
