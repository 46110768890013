import React, { useEffect, useState } from 'react';
import {
  ModalProps as ModalChakraProps,
  ModalContent,
  ModalBody,
  Flex,
  useMediaQuery,
  useDisclosure,
  ModalHeader,
  Text,
  ModalFooter,
  HStack,
  Button,
  GridItem,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';

import { Switch } from 'components/update/Switch';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';

import { FormData, yupResolver } from './validationForm';
import { Variations, TamanhoProps } from '../../variations';

export type ProdutoResponse = {
  tamanhos: Omit<TamanhoProps, 'nome'>[];
  produtoPrincipalId: string;
  produtoItemId: string;
  materiaPrimaFinal: boolean;
};

type SizeProps = TamanhoProps & {
  quantidade: number;
};

type ModalProps = {
  title?: string;
  subtitle?: string;
  productMainId: string;
  productItemId: string;
  finalRawMaterial: boolean;
  listSizes: SizeProps[];
  decimalPlaces: number;
  isDefaultSize: boolean;
  callback: (data: ProdutoResponse) => Promise<{ success: boolean }>;
};

type ModalProdutoAlterarProps = Omit<
  ModalChakraProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<void> &
  ModalProps;

export const ModalProdutoAlterar = create<ModalProdutoAlterarProps>(
  ({
    onResolve,
    onReject,
    title = 'Alterar produto',
    subtitle,
    productMainId,
    productItemId,
    finalRawMaterial,
    isDefaultSize,
    listSizes,
    decimalPlaces,
    callback,
    ...rest
  }) => {
    const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const [isLoading, setIsLoading] = useState(false);

    const formMethods = useForm<FormData>({
      resolver: yupResolver,
    });

    const { handleSubmit: onSubmit, reset } = formMethods;

    const dadosProduto = (valueForm: FormData): ProdutoResponse => {
      const tamanhos = listSizes.map(({ id }, index) => ({
        id,
        quantidade: valueForm?.quantidade[index],
      }));

      return {
        produtoPrincipalId: productMainId,
        produtoItemId: productItemId,
        materiaPrimaFinal: valueForm.materiaPrimaFinal,
        tamanhos,
      };
    };

    const handleSubmit = onSubmit(async (data) => {
      setIsLoading(true);

      const produto = dadosProduto(data);
      const { success } = await callback(produto);

      if (success) {
        onClose();
      }

      setIsLoading(false);
    });

    useEffect(() => {
      const defaultQuantity = listSizes.map(({ quantidade }) => quantidade);

      reset({
        materiaPrimaFinal: finalRawMaterial,
        quantidade: defaultQuantity,
      });
    }, [finalRawMaterial, listSizes, reset]);

    return (
      <ModalPadraoChakra
        isCentered
        size={!isSmallerThan900 ? '3xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalContent px={{ base: '8px', md: '16px' }} bg="gray.50">
          {isLoading && <LoadingPadrao />}
          <ModalHeader pt={{ base: '12px', md: '24px' }}>
            <Text color="primary.500" mt={{ base: 4, md: 0 }}>
              {title}
            </Text>
            {subtitle && (
              <Text textColor="gray.400" fontSize="sm">
                {subtitle}
              </Text>
            )}
          </ModalHeader>
          <ModalBody p={{ base: 6, md: 8 }}>
            <FormProvider {...formMethods}>
              <Flex
                alignItems="baseline"
                flexDirection="column"
                w="full"
                h="full"
              >
                <SimpleGridForm w="full">
                  <GridItem colSpan={{ base: 12, md: 3 }}>
                    <Switch
                      id="materiaPrimaFinal"
                      name="materiaPrimaFinal"
                      label="Matéria prima final"
                      size="md"
                    />
                  </GridItem>
                  <Variations
                    listSizes={listSizes}
                    decimalPlaces={decimalPlaces}
                    isDefaultSize={isDefaultSize}
                  />
                </SimpleGridForm>
              </Flex>
            </FormProvider>
          </ModalBody>
          <ModalFooter
            justifyContent="flex-end"
            mx={{ base: 6, md: 8 }}
            py="40px"
          >
            <HStack
              flexDirection={{ base: 'column', md: 'row' }}
              justifyContent="center"
              alignItems="center"
              gap={6}
              flex="1"
            >
              <Button
                id="cancelar"
                name="cancelar"
                variant="outlineDefault"
                colorScheme="gray"
                minW="min-content"
                width={{ base: 'full', sm: '100px' }}
                fontWeight="normal"
                onClick={onClose}
              >
                Cancelar
              </Button>
              <Button
                id="salvar"
                name="salvar"
                minW="min-content"
                colorScheme="secondary"
                width={{ base: 'full', sm: '160px' }}
                fontWeight="normal"
                onClick={handleSubmit}
              >
                Confirmar
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
