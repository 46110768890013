import { useCallback, useEffect, useState } from 'react';
import { Button, Flex, FormLabel, GridItem } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import { setDateMaxHours, setDateMinHours } from 'helpers/data/setHoursDate';
import { ImprimirPDF } from 'helpers/impressoes/imprimirPDF';
import { formatOptionsSelectClient } from 'helpers/format/formatSelectClient';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import {
  EnumApisRelatorioVendas,
  EnumRelatorioVendas,
} from 'constants/enum/enumRelatorioVendas';
import { StatusPesquisaClientesFornecedor } from 'constants/enum/statusPesquisaClientesFornecedor';
import { IdentificacaoIntegracaoOptions } from 'constants/enum/IdentificacaoIntegracao';
import { statusOperacaoOptions as listStatus } from 'constants/enum/statusOperacao';

import InputDateRange from 'components/PDV/InputDateRange';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import AsyncSelect from 'components/PDV/Select/AsyncSelectPadrao';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';

import {
  OptionsVendedores,
  SelectClienteProps,
  OptionResponseVendedores,
  formDefaultValues,
  FormData,
  yupResolver,
} from './validationForms';
import { validarTipoRelatorioVendas } from './ValidarTipoRelatorioVendas';

export const RelatorioVendas = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [listaVendedores, setListaVendedores] = useState<OptionsVendedores[]>(
    []
  );

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues: formDefaultValues,
  });

  const { watch, handleSubmit, setValue } = formMethods;
  const tipoRelatorio = watch('tipoRelatorio');

  const isRelatorioVendaSimplificada =
    tipoRelatorio === EnumRelatorioVendas.VENDAS_SIMPLIFICADAS;

  const listRelatoriosVendas = EnumRelatorioVendas.properties.filter(
    (relatorioItem) => validarTipoRelatorioVendas(relatorioItem)
  );

  const getClientes = useCallback(async (inputValue?: string) => {
    const response = await api.get<void, ResponseApi<SelectClienteProps[]>>(
      ConstanteEnderecoWebservice.CLIENTE_FORNECEDOR_LISTAR_SELECT,
      {
        params: {
          filtroTipoCadastroPessoa: StatusPesquisaClientesFornecedor.CLIENTES,
          cpfCnpjNomeApelidoCodigoExterno: inputValue,
        },
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        const dados = response.dados.map((cliente) => {
          const option = formatOptionsSelectClient(cliente);
          return option;
        });
        return dados;
      }
    }

    return [];
  }, []);

  const getListaDeVendedores = useCallback(async () => {
    const response = await api.get<
      void,
      ResponseApi<OptionResponseVendedores[]>
    >(ConstanteEnderecoWebservice.VENDEDOR_LISTAR_SELECT_POR_LOJA);

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item) => toast.warn(item));
      }

      if (response.sucesso && response.dados) {
        setListaVendedores(
          response.dados.map((dataVendedores) => {
            return {
              label: dataVendedores.nome,
              value: dataVendedores.id,
            };
          })
        );
      }
    }
  }, []);

  const handleImprimirRelatorioVenda = handleSubmit(async (data) => {
    setIsLoading(true);

    const enderecoWebservice =
      tipoRelatorio === EnumRelatorioVendas.VENDAS_FORMA_RECEBIMENTO ||
      tipoRelatorio
        ? EnumApisRelatorioVendas[tipoRelatorio]
        : '';

    const response = await api.post<void, ResponseApi<string>>(
      enderecoWebservice,
      {
        ...data,
        dataEmissaoFim: setDateMaxHours(data?.dataEmissaoFim || new Date()),
        clienteFornecedorId: data.clienteFornecedorId?.value,
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }
      if (response.sucesso && response.dados) {
        ImprimirPDF(response.dados, 'relatorioVendas');
      }
    }

    setIsLoading(false);
  });

  useEffect(() => {
    getListaDeVendedores();
  }, [getListaDeVendedores]);

  useEffect(() => {
    if (!isRelatorioVendaSimplificada) {
      setValue('statusConsulta', undefined);
    }
  }, [isRelatorioVendaSimplificada, setValue]);

  return (
    <FormProvider {...formMethods}>
      {isLoading && <LoadingPadrao />}
      <SimpleGridForm
        bg="white"
        pt={['20px', '20px', '40px']}
        pl={['20px', '20px', '40px']}
        pb={['20px', '20px', '40px']}
        pr={['20px', '20px', '40px']}
        borderRadius="5px"
        boxShadow="0px 0px 6px #00000034"
      >
        <GridItem colSpan={{ base: 12, md: 4, lg: 4, xl: 4 }}>
          <SelectPadrao
            placeholder="Selecione um relatório"
            id="tipoRelatorio"
            name="tipoRelatorio"
            required
            label="Relatório"
            options={listRelatoriosVendas}
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, md: 4, lg: 4, xl: 4 }}>
          <FormLabel mb="0" fontSize="sm" color="black" lineHeight="1.2">
            Período *
          </FormLabel>
          <InputDateRange
            borderColor="gray.100"
            borderRadius="md"
            placeholder="dd/mm/aaaa"
            startDateName="dataEmissaoInicio"
            endDateName="dataEmissaoFim"
            maxDate={new Date()}
            inputValueIsSmaller={false}
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, sm: 6, lg: 4 }}>
          <AsyncSelect
            id="clienteFornecedorId"
            name="clienteFornecedorId"
            placeholder="Informe o cliente"
            label="Cliente"
            handleGetOptions={getClientes}
            asControlledByObject
            isClearable
            shouldAppearTheAddress
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, sm: 6, lg: 6 }}>
          <SelectPadrao
            id="vendedorId"
            name="vendedorId"
            label="Vendedor"
            isClearable
            placeholder="Selecione um vendedor"
            options={listaVendedores}
          />
        </GridItem>
        {isRelatorioVendaSimplificada && (
          <GridItem colSpan={{ base: 12, sm: 6, lg: 6 }}>
            <SelectPadrao
              id="statusConsulta"
              name="statusConsulta"
              label="Status"
              isSearchable={false}
              options={listStatus}
              isClearable
            />
          </GridItem>
        )}
        <GridItem colSpan={[12, 12, 4]}>
          <SelectPadrao
            label="Origem"
            id="origem"
            isSearchable={false}
            name="origem"
            options={IdentificacaoIntegracaoOptions}
          />
        </GridItem>
      </SimpleGridForm>
      <Flex
        mt={['20px', '20px', '40px']}
        justifyContent="flex-end"
        alignItems="center"
      >
        <Button
          h="39px"
          color="gray.700"
          colorScheme="secondary"
          borderRadius="md"
          isLoading={isLoading}
          onClick={() => handleImprimirRelatorioVenda()}
          width={{ base: 'full', md: '220px' }}
        >
          Gerar relatório
        </Button>
      </Flex>
    </FormProvider>
  );
};
