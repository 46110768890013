import { useCallback, useEffect, useState } from 'react';
import { Box, Flex, useMediaQuery, Button } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import { MonthInput } from 'components/update/Input/MonthInput';

import { Vendedor, VendedorVinculado } from '../../Types/validationsForm';
import { DesempenhoIndividualPorVendedor } from '../DesempenhoIndividualPorVendedor';
import { GraficoMetaGeralDaLoja } from '../../GraficosMetasComissoes/graficoMetaGeralDaLoja';

type ComissaoVendedorSemPermissaoProps = {
  handleAbrirHistoricoVendas: () => void;
  handleAbrirRelatorioVendas: () => void;
};

export const ComissaoVendedorSemPermissao = ({
  handleAbrirRelatorioVendas,
  handleAbrirHistoricoVendas,
}: ComissaoVendedorSemPermissaoProps) => {
  const [vendedorVinculado, setVendedorVinculado] = useState<Vendedor>(
    {} as Vendedor
  );

  const { watch } = useFormContext();
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

  const mesAnoWatch = watch('mesAnoUsuarioSemPermissao');

  const obterVendedorVinculado = useCallback(async () => {
    const response = await api.get<void, ResponseApi<VendedorVinculado>>(
      ConstanteEnderecoWebservice.OBTER_VENDEDOR_VINCULADO
    );
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
        return false;
      }
      if (response.sucesso && response.dados) {
        setVendedorVinculado(() => ({
          id: response.dados.id,
          nome: response.dados.nome,
          label: '',
          value: '',
        }));
        return true;
      }
    }
    return false;
  }, []);

  useEffect(() => {
    obterVendedorVinculado();
  }, [obterVendedorVinculado]);

  return (
    <Box>
      <Flex
        direction={isLargerThan900 ? 'row' : 'column'}
        mb="24px"
        justifyContent="space-between"
      >
        <Box
          mb={isLargerThan900 ? '' : '10px'}
          w={isLargerThan900 ? '260px' : 'full'}
        >
          <MonthInput
            name="mesAnoUsuarioSemPermissao"
            isRequired
            valuePadding="15px"
            colSpan={{ base: 12, sm: 4, md: 4, lg: 3, xl: 2 }}
          />
        </Box>

        <Flex direction={isLargerThan900 ? 'row' : 'column'}>
          <Button
            w={isLargerThan900 ? '173px' : 'full'}
            variant="success"
            borderRadius="md"
            mb={isLargerThan900 ? '' : '10px'}
            bg="white"
            onClick={() => handleAbrirHistoricoVendas()}
            border="1px solid"
            borderColor="gray.100"
          >
            Histórico de vendas
          </Button>
          <Button
            w={isLargerThan900 ? '196px' : 'full'}
            variant="success"
            bg="white"
            ml={isLargerThan900 ? '24px' : undefined}
            onClick={() => handleAbrirRelatorioVendas()}
            border="1px solid"
            borderColor="gray.100"
            borderRadius="md"
          >
            Relatório de comissões
          </Button>
        </Flex>
      </Flex>
      <DesempenhoIndividualPorVendedor
        vendedor={vendedorVinculado}
        mesAno={mesAnoWatch}
        naoPossuiPermissao
      />
      {!isLargerThan700 && <Box h="50px" />}
      <GraficoMetaGeralDaLoja usuarioNaoPossuiPermissao mesAno={mesAnoWatch} />
    </Box>
  );
};
