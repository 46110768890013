import { useCallback } from 'react';
import { Box, useMediaQuery } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import auth from 'modules/auth';
import ConstanteRotas from 'constants/rotas';
import { setDateMaxHours, setDateMinHours } from 'helpers/data/setHoursDate';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteFuncionalidades from 'constants/permissoes';
import { useMetasComissoesRelatorioVendasContext } from 'store/MetasComissoes/MetasComissoesRelatorioVendas';
import { ImprimirPDF } from 'helpers/impressoes/imprimirPDF';
import { formatEmailsToSend } from 'helpers/format/email';

import { ModalCompartilhar } from 'components/Modal/ModalCompartilhar';
import { EmailModal } from 'components/PDV/CompartilharDocumentosFiscais/EmailModal';

import { RelatorioVendedorComPermissao } from './RelatorioVendedorComPermissao';

type VendedoresProps = { value: string; label: string };

export type FormData = {
  todosVendedores: VendedoresProps | null;
  mesAno: Date;
};

export type FiltrosMetasComissoesRelatorioResumoLojasProps = {
  mesAno: Date;
  vendedorId: string | undefined;
};

export const formDefaultValues = {
  todosVendedores: null,
  dataRelatorioVendasInicio: setDateMinHours(new Date()),
  dataRelatorioVendasFim: setDateMaxHours(new Date()),
  dataRelatorioVendas: new Date(),
  mesAno: new Date(),
};

export const RelatorioVendasMetasComissoes = () => {
  const history = useHistory();
  const {
    registroPaginacao,
    vendedorVinculado,
  } = useMetasComissoesRelatorioVendasContext();

  const possuiPermissaoMetaComissao = auth.possuiPermissao(
    ConstanteFuncionalidades.METAS_COMISSAO
  ).permitido;

  const [isLargerThan1200] = useMediaQuery('(min-width: 1200px)');
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const formMethods = useForm<FormData>({
    defaultValues: { mesAno: new Date() },
  });

  const { getValues, watch } = formMethods;
  const vendedorWatch = watch('todosVendedores');

  const handleVoltarParaMetasComissoes = () => {
    history.push(ConstanteRotas.METAS_E_COMISSOES);
  };

  const vendedorEscolhido = possuiPermissaoMetaComissao
    ? vendedorWatch?.value
    : vendedorVinculado.id;

  const filtrosPaginacao = useCallback(() => {
    return {
      paginaAtual: registroPaginacao.currentPage,
      tamanhoPagina: registroPaginacao.pageSize,
      campoOrdenacao: registroPaginacao.orderColumn,
      direcaoOrdenacao: registroPaginacao.orderDirection,
      mesAno: getValues().mesAno,
      vendedorId: vendedorEscolhido,
    };
  }, [
    getValues,
    registroPaginacao.currentPage,
    registroPaginacao.orderColumn,
    registroPaginacao.orderDirection,
    registroPaginacao.pageSize,
    vendedorEscolhido,
  ])();

  const handleImprimirResumoVendedor = useCallback(async () => {
    const response = await api.get<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.RELATORIO_METAS_COMISSAO_IMPRIMIR_RESUMO_VENDEDOR,
      {
        params: filtrosPaginacao,
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((avisos) => toast.warning(avisos));
      }

      if (response.sucesso && response.dados) {
        ImprimirPDF(response.dados, 'relatorioResumoVendedorMetaComissao');
      }
    }
  }, [filtrosPaginacao]);

  const handleImprimirResumoLojas = useCallback(async () => {
    const params = {
      paginaAtual: filtrosPaginacao.paginaAtual,
      tamanhoPagina: filtrosPaginacao.tamanhoPagina,
      campoOrdenacao: filtrosPaginacao.campoOrdenacao,
      direcaoOrdenacao: filtrosPaginacao.direcaoOrdenacao,
      mesAno: setDateMaxHours(filtrosPaginacao.mesAno).toISOString(),
    };

    const response = await api.get<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.RELATORIO_METAS_COMISSAO_IMPRIMIR_RESUMO_LOJA,
      {
        params,
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((avisos) => toast.warning(avisos));
      }

      if (response.sucesso && response.dados) {
        ImprimirPDF(response.dados, 'relatorioResumoLojaMetaComissao');
      }
    }
  }, [filtrosPaginacao]);

  const handleImprimirHistoricoComissao = useCallback(async () => {
    const params = {
      paginaAtual: filtrosPaginacao.paginaAtual,
      tamanhoPagina: filtrosPaginacao.tamanhoPagina,
      campoOrdenacao: filtrosPaginacao.campoOrdenacao,
      direcaoOrdenacao: filtrosPaginacao.direcaoOrdenacao,
      mesAno: setDateMaxHours(filtrosPaginacao.mesAno).toISOString(),
    };

    const response = await api.get<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.RELATORIO_METAS_COMISSAO_HISTORICO_COMISSAO_IMPRIMIR,
      {
        params,
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((avisos) => toast.warning(avisos));
      }

      if (response.sucesso && response.dados) {
        ImprimirPDF(response.dados, 'relatórioHistóricoComissao');
      }
    }
  }, [filtrosPaginacao]);

  const sendEmailResumoVendedor = useCallback(
    async (emails: string[]) => {
      const {
        campoOrdenacao,
        direcaoOrdenacao,
        mesAno,
        paginaAtual,
        tamanhoPagina,
      } = filtrosPaginacao;

      const filters = {
        paginaAtual,
        tamanhoPagina,
        campoOrdenacao,
        direcaoOrdenacao,
        mesAno: setDateMaxHours(mesAno).toISOString(),
        vendedorId: vendedorEscolhido,
      };
      const response = await api.get<void, ResponseApi<boolean>>(
        `${
          ConstanteEnderecoWebservice.RELATORIO_METAS_COMISSAO_EMAIL_RESUMO_VENDEDOR
        }?${new URLSearchParams(
          Object(filters)
        ).toString()}${formatEmailsToSend(emails)}`
      );
      if (response) {
        if (response?.sucesso) {
          toast.success('O e-mail foi enviado com sucesso.');
        }
        if (response?.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }
      }
    },
    [filtrosPaginacao, vendedorEscolhido]
  );

  const sendEmailResumoLoja = useCallback(
    async (emails: string[]) => {
      const filters = {
        paginaAtual: filtrosPaginacao.paginaAtual,
        tamanhoPagina: filtrosPaginacao.tamanhoPagina,
        campoOrdenacao: filtrosPaginacao.campoOrdenacao,
        direcaoOrdenacao: filtrosPaginacao.direcaoOrdenacao,
        mesAno: setDateMaxHours(filtrosPaginacao.mesAno).toISOString(),
      };

      const response = await api.get<void, ResponseApi<boolean>>(
        `${
          ConstanteEnderecoWebservice.RELATORIO_METAS_COMISSAO_EMAIL_RESUMO_LOJA
        }?${new URLSearchParams(
          Object(filters)
        ).toString()}${formatEmailsToSend(emails)}`
      );

      if (response) {
        if (response?.sucesso) {
          toast.success('O e-mail foi enviado com sucesso.');
        }
        if (response?.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }
      }
    },
    [filtrosPaginacao]
  );

  const sendEmailHistoricoComissao = useCallback(
    async (emails: string[]) => {
      const filters = {
        paginaAtual: filtrosPaginacao.paginaAtual,
        tamanhoPagina: filtrosPaginacao.tamanhoPagina,
        campoOrdenacao: filtrosPaginacao.campoOrdenacao,
        direcaoOrdenacao: filtrosPaginacao.direcaoOrdenacao,
        mesAno: setDateMaxHours(filtrosPaginacao.mesAno).toISOString(),
      };

      const response = await api.get<void, ResponseApi<boolean>>(
        `${
          ConstanteEnderecoWebservice.RELATORIO_METAS_COMISSAO_HISTORICO_COMISSAO_EMAIL
        }?${new URLSearchParams(
          Object(filters)
        ).toString()}${formatEmailsToSend(emails)}`
      );

      if (response) {
        if (response?.sucesso) {
          toast.success('O e-mail foi enviado com sucesso.');
        }
        if (response?.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }
      }
    },
    [filtrosPaginacao]
  );

  const handleEnviarEmailResumoVendedor = useCallback(async () => {
    await EmailModal({
      getEmail: async (emails: string[]) => {
        sendEmailResumoVendedor(emails);
      },
    });
  }, [sendEmailResumoVendedor]);

  const handleEnviarEmailResumoLojas = useCallback(async () => {
    await EmailModal({
      getEmail: async (emails: string[]) => {
        sendEmailResumoLoja(emails);
      },
    });
  }, [sendEmailResumoLoja]);

  const handleEnviarEmailHistoricoComissao = useCallback(async () => {
    await EmailModal({
      getEmail: async (emails: string[]) => {
        sendEmailHistoricoComissao(emails);
      },
    });
  }, [sendEmailHistoricoComissao]);

  const handleImprimirOperacao = useCallback(() => {
    ModalCompartilhar({
      items: vendedorEscolhido
        ? [
            {
              titulo: 'Relatório resumo do vendedor',
              onClickImpressao: (onClose) => {
                handleImprimirResumoVendedor();
                onClose();
              },
              onClickEmail: (onClose) => {
                handleEnviarEmailResumoVendedor();
                onClose();
              },
            },
          ]
        : [
            {
              titulo: 'Relatório resumo da loja',
              onClickImpressao: (onClose) => {
                handleImprimirResumoLojas();
                onClose();
              },
              onClickEmail: (onClose) => {
                handleEnviarEmailResumoLojas();
                onClose();
              },
            },
            {
              titulo: 'Relatório histórico de comissão',
              onClickImpressao: (onClose) => {
                handleImprimirHistoricoComissao();
                onClose();
              },
              onClickEmail: (onClose) => {
                handleEnviarEmailHistoricoComissao();
                onClose();
              },
            },
          ],
    });
  }, [
    handleEnviarEmailHistoricoComissao,
    handleEnviarEmailResumoLojas,
    handleEnviarEmailResumoVendedor,
    handleImprimirResumoLojas,
    handleImprimirResumoVendedor,
    handleImprimirHistoricoComissao,
    vendedorEscolhido,
  ]);

  return (
    <FormProvider {...formMethods}>
      <Box
        bg="gray.50"
        overflowX="scroll"
        sx={{
          '&::-webkit-scrollbar': {
            height: '0',
            width: '0',
          },
          '& .virtualized_List::-webkit-scrollbar': {
            height: '0',
            width: '0',
          },
        }}
        pb="30px"
        h="100vh"
        pt="24px"
        pl={isLargerThan1200 ? '40px' : isLargerThan900 ? '24px' : '16px'}
        pr={isLargerThan1200 ? '40px' : isLargerThan900 ? '24px' : '16px'}
      >
        <RelatorioVendedorComPermissao
          handleVoltarParaMetasComissoes={handleVoltarParaMetasComissoes}
          handleImprimirOperacao={handleImprimirOperacao}
        />
      </Box>
    </FormProvider>
  );
};
