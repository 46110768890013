import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ResponseApi } from 'services/api';
import { sistemaApiKey } from 'services/sistemaApiKey';
import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { enumIdentificacaoIntegracao } from 'constants/enum/enumIdentificacaoIntegracao';
import { useFrenteCaixaContext } from 'store/FrenteCaixa';

export const usePainelFrenteCaixa = () => {
  const { getIntegracao } = useFrenteCaixaContext();

  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  function handleVoltarPainelAdm() {
    history.push(ConstanteRotas.FRENTE_CAIXA_PAINEL);
  }

  const cadastrarIntegracao = useCallback(async () => {
    const response = await sistemaApiKey().post<void, ResponseApi<FormData>>(
      ConstanteEnderecoWebservice.FRENTE_CAIXA_CADASTRAR_INTEGRACAO,
      {
        identificacaoIntegracao: enumIdentificacaoIntegracao.FRENTE_CAIXA,
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }
      if (response.sucesso) {
        setIsLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    const getConfigFrenteCaixa = async () => {
      setIsLoading(true);
      await getIntegracao(cadastrarIntegracao);
      setIsLoading(false);
    };

    getConfigFrenteCaixa();
  }, [cadastrarIntegracao, getIntegracao]);

  return {
    handleVoltarPainelAdm,
    isLoading,
  };
};
