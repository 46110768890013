import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SalvarConfirmarIcon } from 'icons';
import ButtonPadrao from 'components/Button/ButtonPadrao';
import { Col, Form, Modal } from 'react-bootstrap';
import TextAreaPadrao from 'components/TextArea/TextAreaPadrao';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { toast } from 'react-toastify';

import TipoEventoEnum from 'constants/enum/fiscal/tipoEvento';
import ModalPadrao from 'components/Modal/ModalPadrao';
import formatUTCToLocateDateTime from 'helpers/format/formatUTCToLocateDateTime';
import cancelarOperacao from 'helpers/api/Operacao/cancelarOperacao';
import LogAuditoriaTelaEnum from 'constants/enum/logAuditoriaTela';
import { useSignalRContext } from 'store/SignalR';
import TipoCertificadoEnum from 'constants/enum/fiscal/tipoCertificado';
import { ModalAtencao } from 'components/Modal/ModalAtencao';
import getDateTimeDefaultInput from 'helpers/data/getDateTimeDefaultInput';
import { useForm, yupResolver } from './validationForm';

interface CancelarNotaModalPrps {
  show: boolean;
  onHide: () => void;
  documentoFiscalId: string;
  operacaoId?: string;
}

const CancelarModal: React.FC<CancelarNotaModalPrps> = ({
  show,
  onHide,
  documentoFiscalId,
  operacaoId,
}) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    formState: { errors },
    getValues,
    register,
    setFocus,
  } = useForm({
    resolver: yupResolver,
    shouldUnregister: true,
  });

  const [isLoading, setIsLoading] = useState(false);
  const { hubConnection, joinGroup } = useSignalRContext();

  const handlePostCancelar = async () => {
    const data = getValues();

    const response = await api.post<void, ResponseApi<number | undefined>>(
      ConstanteEnderecoWebservice.NOTA_FISCAL_EVENTO,
      {
        documentoFiscalId,
        tipoEvento: TipoEventoEnum.Cancelamento,
        ...data,
        dataEmissao: getDateTimeDefaultInput(new Date()),
      }
    );

    if (response.sucesso) {
      if (response.dados && response.dados === Number(TipoCertificadoEnum.A3)) {
        const timeout = window.setTimeout(
          () => {
            setIsLoading(false);
            ModalAtencao({
              title: 'A comunicação com o certificado digital A3 falhou',
              text:
                'Verifique se o certificado digital está conectado no computador e se o aplicativo Módulo desktop está sendo executado.',
            });
          },
          1000 * 60 // 1 minuto
        );

        await joinGroup(`${documentoFiscalId}_transmitindo-evento`);

        hubConnection.off('sucesso-transmissao-a3');
        hubConnection.on('sucesso-transmissao-a3', () => {
          window.clearTimeout(timeout);

          setIsLoading(false);
          toast.success(t('O cancelamento foi realizado com sucesso.'));
          onHide();
        });

        hubConnection.off('rejeicao-transmissao-a3');
        hubConnection.on('rejeicao-transmissao-a3', (message: string) => {
          window.clearTimeout(timeout);

          setIsLoading(false);
          toast.warning(message);
        });

        return;
      }

      toast.success(t('O cancelamento foi realizado com sucesso.'));
      onHide();
    }

    setIsLoading(false);

    if (response.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }
  };

  const onSuccessCancelarOperacao = () => {
    setIsLoading(false);
    toast.success(t('O cancelamento foi realizado com sucesso.'));
    onHide();
  };

  const onSubmit = handleSubmit(async () => {
    setIsLoading(true);

    if (operacaoId) {
      const data = getValues();
      await cancelarOperacao(
        {
          id: operacaoId,
          tela: LogAuditoriaTelaEnum.NOTA_FISCAL,
          motivoCancelamento: data.motivo,
          motivoCancelamentoNotaFiscal: data.motivo,
        },
        hubConnection,
        joinGroup,
        onSuccessCancelarOperacao,
        () => {
          setIsLoading(false);
        },
        ''
      );
    } else {
      await handlePostCancelar();
    }
  });

  return (
    <ModalPadrao
      isOpen={show}
      handleOnHide={onHide}
      title={t('Cancelar nota')}
      subtitle={t(
        'Informe o motivo de cancelamento da nota, precisa ter no mínimo 15 caracteres.'
      )}
      onEntered={() => setFocus('motivo')}
      size="lg"
      minWidth="40%"
    >
      <Form>
        {isLoading && <LoadingPadrao />}
        <Modal.Body>
          <Form.Row>
            <Col xs={0}>
              <TextAreaPadrao
                id="motivo"
                label={t('Motivo')}
                placeholder={t('Digite o motivo')}
                error={errors.motivo}
                disabled={false}
                maxLength={255}
                rows={10}
                required
                {...register('motivo')}
              />
            </Col>
          </Form.Row>
        </Modal.Body>

        <Modal.Footer>
          <ButtonPadrao
            id="fechar"
            name="fechar"
            text={t('Fechar')}
            type="button"
            variant="outline-secondary"
            onClick={onHide}
            style={{ height: '35px', width: '100%', maxWidth: '100px' }}
          />
          <ButtonPadrao
            id="salvarInutilizar"
            name="salvar"
            text={t('Salvar')}
            icon={SalvarConfirmarIcon}
            type="button"
            variant="success"
            onClick={() => {
              onSubmit();
            }}
            style={{ height: '35px', width: '100%', maxWidth: '120px' }}
          />
        </Modal.Footer>
      </Form>
    </ModalPadrao>
  );
};

export default CancelarModal;
