import React, { cloneElement } from 'react';
import { useDisclosure, Button } from '@chakra-ui/react';

import { MobileSelectWrapperChildren } from './types';
import { MobileSelectWrapperModal } from './MobileSelectWrapperModal';

type MobileSelectWrapper = {
  children: MobileSelectWrapperChildren;
  asMobileView?: boolean;
  showButton?: boolean;
  autoFocus?: boolean;
};

export function MobileSelectWrapper({
  children,
  asMobileView = false,
  showButton = true,
  autoFocus = true,
}: MobileSelectWrapper) {
  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: !showButton,
  });

  if (!asMobileView) {
    return children({ onClose });
  }

  return (
    <>
      {showButton && (
        <Button
          variant="unstyled"
          w="full"
          h="auto"
          onClick={onOpen}
          sx={{
            '& .react-select__custom-container': {
              pointerEvents: 'none',
            },
          }}
          position="relative"
          borderRadius="md"
        >
          {cloneElement(children({ onClose }), {
            isSearchable: false,
            menuIsOpen: false,
            tabIndex: '-1',
          })}
        </Button>
      )}

      <MobileSelectWrapperModal
        isOpen={isOpen}
        onClose={onClose}
        asMobileView={asMobileView}
        autoFocus={autoFocus}
      >
        {children}
      </MobileSelectWrapperModal>
    </>
  );
}
