import { Flex, Text, Box } from '@chakra-ui/react';

interface DevolucaoProps {
  numeroOperacao: number;
  numeroOperacaoOrigem: number;
}

const Devolucao = ({
  numeroOperacao,
  numeroOperacaoOrigem,
}: DevolucaoProps) => {
  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        mt={3}
        borderBottom="2px solid"
        borderColor="gray.700"
      >
        <Box>
          <Text color="black" fontWeight="900" fontSize="2xs">
            {`Devolução: ${numeroOperacao}${
              numeroOperacaoOrigem
                ? `- Referente à venda: ${numeroOperacaoOrigem}`
                : ''
            }`}
          </Text>
        </Box>
      </Flex>
    </>
  );
};

export default Devolucao;
