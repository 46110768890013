import React, { useMemo, useState } from 'react';
import {
  Box,
  Grid,
  HStack,
  Text,
  Icon,
  Button,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { FiXCircle } from 'react-icons/fi';

import PagamentoProvider, { PagamentoContext } from 'store/PDV/Pagamento';
import SelecaoFormaPagamentoProvider, {
  SelecaoFormaPagamentoContext,
} from 'store/PDV/SelecaoFormaPagamento';
import FormasPagamentoProvider, {
  FormasPagamentoContext,
} from 'store/PDV/FormasPagamento';
import EditarParcelasProvider, {
  EditarParcelasContext,
} from 'store/PDV/EditarParcelas';
import MenuContextProvider from 'store/PDV/Menu';
import { useOperacaoContext } from 'store/PDV/Operacao';
import AcrescimosDescontosProvider, {
  AcrescimosDescontosContext,
} from 'store/PDV/AcrescimosDescontos';
import { useSignalRContext } from 'store/SignalR';
import LogAuditoriaTelaEnum from 'constants/enum/logAuditoriaTela';
import auth from 'modules/auth';
import ConstanteFuncionalidades from 'constants/permissoes';
import { useInformacoesGeraisContext } from 'store/PDV/InformacoesGerais';

import { DescontosIcon, PedidosIcon } from 'icons';
import Layout from 'components/PDV/Layout';
import Overlay from 'components/PDV/Geral/Overlay';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import Observacoes from 'components/PDV/Header/Observacoes';
import InternalHeaderItemSimples from 'components/PDV/Layout/NavHeader/ItemSimples';
import CaixaAberto from 'components/PDV/Layout/Footer/CaixaAberto';
import SelecionarVendedor from 'components/PDV/Layout/Footer/SelecionarVendedor';
import { TextValor } from 'components/PDV/Text/TextValor';
import NavHeaderButton from 'components/PDV/Layout/NavHeader/Button';
import ModalAutorizacaoFuncionalidade from 'components/Modal/ModalAutorizacaoFuncionalidade';
import ModalConfirmacao from 'components/PDV/Modal/ModalConfirmacao';
import CadastroCliente from 'components/PDV/Header/CadastroCliente';

import PagamentoInternalHeader from './InternalHeader';
import PagamentoFooter from './Footer';
import SelecaoFormaPagamento from './SelecaoFormaPagamento';
import { ListagemMovimentacoesFinanceiras } from './MovimentacoesFinanceiras/ListagemMovimentacoesFinanceiras';
import EditarParcelas from './EditarParcelas';
import { ModalAdicionarPagamento } from './Mobile/ModalAdicionarPagamento';

interface LeftSideContainerProps {
  children: React.ReactNode;
  isModal: boolean;
}

const LeftSideContainer = ({ children, isModal }: LeftSideContainerProps) => (
  <Box
    bg="gray.50"
    h={{ base: 'calc(100% - 50px)', md: 'full' }}
    maxH={{ base: 'none', md: '100%' }}
    minW={{
      base: 'full',
      md: '550px',
      lg: '700px',
      xl: '995px',
    }}
    maxW={{
      base: 'full',
      md: '550px',
      lg: '700px',
      xl: '995px',
    }}
    overflow={{ base: 'visible', md: 'auto' }}
    position="relative"
    zIndex={isModal ? 'modal' : 'auto'}
  >
    {children}
  </Box>
);

const Pagamento = () => {
  const asMobileView = useBreakpointValue({ base: true, md: false });

  const { hubConnection, joinGroup } = useSignalRContext();
  const {
    isOpen: isModalAdicionarPagamentoOpen,
    onOpen: onModalAdicionarPagamentoOpen,
    onClose: onModalAdicionarPagamentoClose,
  } = useDisclosure();

  const { handleChangeVendedor, handleCancelarOperacao } = useOperacaoContext();

  const {
    localEstoquePadraoDoUsuario,
    handleSetInformacoesLocalEstoque,
    onChangeOperacaoItem,
    detalhesTroca,
  } = useInformacoesGeraisContext();

  const permissaoCancelarOperacao = useMemo(
    () => auth.possuiPermissao(ConstanteFuncionalidades.PDV_CANCELAR.codigo),
    []
  );

  const [
    modalAutorizacaoCancelarIsOpen,
    setModalAutorizacaoCancelarIsOpen,
  ] = useState(false);
  const [
    modalConfirmacaoCancelarIsOpen,
    setModalConfirmacaoCancelarIsOpen,
  ] = useState(false);

  const handleConfirmarPermissaoCancelar = (
    chavePermissaoTemporaria: string
  ) => {
    handleCancelarOperacao(hubConnection, joinGroup, chavePermissaoTemporaria);
  };

  const cancelarOperacao = () => {
    if (permissaoCancelarOperacao.permitido) {
      handleCancelarOperacao(hubConnection, joinGroup);
      handleSetInformacoesLocalEstoque(localEstoquePadraoDoUsuario.current);
      onChangeOperacaoItem(0);
    } else {
      setModalAutorizacaoCancelarIsOpen(true);
    }
  };

  const handleCancelar = () => {
    setModalConfirmacaoCancelarIsOpen(true);
  };

  return (
    <PagamentoProvider
      onModalAdicionarPagamentoOpen={onModalAdicionarPagamentoOpen}
    >
      <PagamentoContext.Consumer>
        {({
          totais: { valorTotal = 0, valorTotalItensSemDesconto = 0 } = {},
        }) => (
          <AcrescimosDescontosProvider>
            <AcrescimosDescontosContext.Consumer>
              {({
                corAcrescimoDesconto,
                handleOpenAcrescimosDescontosModal,
                acrescimosDescontosValor,
              }) => (
                <MenuContextProvider
                  asAccordion={asMobileView}
                  renderDefaultItems={false}
                  items={[
                    ...(asMobileView
                      ? []
                      : [
                          <InternalHeaderItemSimples>
                            <HStack h="full" w="full" spacing={2}>
                              <Text fontWeight="normal" fontSize="sm">
                                Total a pagar:
                              </Text>

                              <TextValor
                                valor={valorTotal}
                                symbolFontSize="sm"
                                fontSize="xl"
                              />
                            </HStack>
                          </InternalHeaderItemSimples>,
                        ]),
                    <InternalHeaderItemSimples>
                      <HStack
                        justifyContent="space-between"
                        h="full"
                        w="full"
                        spacing={2}
                      >
                        <HStack spacing={{ base: 3, md: 4 }} color="gray.100">
                          <Icon
                            as={PedidosIcon}
                            fontSize={{ base: 'xl', md: '3xl' }}
                          />

                          <Text fontWeight="normal" fontSize="sm">
                            Valor do pedido
                          </Text>
                        </HStack>

                        <TextValor
                          color="white"
                          valor={valorTotalItensSemDesconto}
                          symbolFontSize="xs"
                          fontSize="md"
                        />
                      </HStack>
                    </InternalHeaderItemSimples>,
                    <InternalHeaderItemSimples>
                      <HStack
                        justifyContent="space-between"
                        h="full"
                        w="full"
                        spacing={2}
                      >
                        <HStack spacing={{ base: 3, md: 4 }}>
                          <Icon
                            as={DescontosIcon}
                            fontSize={{ base: 'xl', md: '3xl' }}
                          />

                          <Button
                            variant="link"
                            colorScheme="white"
                            color="gray.100"
                            size="sm"
                            onClick={handleOpenAcrescimosDescontosModal}
                            textDecoration="none"
                            _hover={{ textDecoration: 'none' }}
                          >
                            Acréscimos e descontos
                          </Button>
                        </HStack>

                        <TextValor
                          color={corAcrescimoDesconto}
                          valor={
                            acrescimosDescontosValor < 0
                              ? acrescimosDescontosValor
                              : acrescimosDescontosValor
                          }
                          symbolFontSize="xs"
                          fontSize="md"
                        />
                      </HStack>
                    </InternalHeaderItemSimples>,

                    ...(asMobileView ? [<CadastroCliente />] : []),
                    <Observacoes asNavHeaderButton />,
                    <CaixaAberto asNavHeaderButton />,
                    <SelecionarVendedor
                      name="vendedor"
                      asNavHeaderButton
                      onSubmit={(newVendedor) => {
                        handleChangeVendedor(newVendedor);
                      }}
                    />,

                    ...(asMobileView
                      ? [
                          <NavHeaderButton
                            icon={FiXCircle}
                            title="Cancelar"
                            onClick={handleCancelar}
                          />,
                        ]
                      : []),
                  ]}
                >
                  <Layout
                    NavHeaderContent={
                      !asMobileView ? <PagamentoInternalHeader /> : null
                    }
                    FooterContent={
                      <PagamentoFooter handleCancelar={handleCancelar} />
                    }
                  >
                    <Grid
                      h="full"
                      templateColumns={{ base: '1fr', md: 'auto 1fr' }}
                    >
                      <FormasPagamentoProvider>
                        <FormasPagamentoContext.Consumer>
                          {({ isLoading: formasPagamentoIsLoading }) => (
                            <EditarParcelasProvider>
                              <EditarParcelasContext.Consumer>
                                {({ movimentacaoFinanceiraEditando }) => (
                                  <>
                                    {movimentacaoFinanceiraEditando ? (
                                      <>
                                        {!asMobileView && <Overlay />}
                                        <LeftSideContainer
                                          isModal={!asMobileView}
                                        >
                                          <EditarParcelas />
                                        </LeftSideContainer>
                                      </>
                                    ) : (
                                      <SelecaoFormaPagamentoProvider
                                        onModalAdicionarPagamentoClose={
                                          onModalAdicionarPagamentoClose
                                        }
                                      >
                                        <SelecaoFormaPagamentoContext.Consumer>
                                          {({ formaPagamento, isLoading }) => (
                                            <ModalAdicionarPagamento
                                              handleCancelar={handleCancelar}
                                              isOpen={
                                                isModalAdicionarPagamentoOpen
                                              }
                                              onClose={
                                                onModalAdicionarPagamentoClose
                                              }
                                              asMobileView={asMobileView}
                                            >
                                              {formaPagamento &&
                                                !asMobileView && <Overlay />}
                                              <LeftSideContainer
                                                isModal={
                                                  !!formaPagamento &&
                                                  !asMobileView
                                                }
                                              >
                                                {(isLoading ||
                                                  formasPagamentoIsLoading) && (
                                                  <LoadingPadrao />
                                                )}
                                                <SelecaoFormaPagamento />
                                              </LeftSideContainer>
                                            </ModalAdicionarPagamento>
                                          )}
                                        </SelecaoFormaPagamentoContext.Consumer>
                                      </SelecaoFormaPagamentoProvider>
                                    )}
                                    <Box
                                      bg={{ base: 'gray.50', md: 'gray.100' }}
                                    >
                                      <ListagemMovimentacoesFinanceiras
                                        onModalAdicionarPagamentoOpen={
                                          onModalAdicionarPagamentoOpen
                                        }
                                      />
                                    </Box>
                                  </>
                                )}
                              </EditarParcelasContext.Consumer>
                            </EditarParcelasProvider>
                          )}
                        </FormasPagamentoContext.Consumer>
                      </FormasPagamentoProvider>
                    </Grid>

                    {modalAutorizacaoCancelarIsOpen && (
                      <ModalAutorizacaoFuncionalidade
                        isOpen={modalAutorizacaoCancelarIsOpen}
                        setIsOpen={setModalAutorizacaoCancelarIsOpen}
                        autorizado={handleConfirmarPermissaoCancelar}
                        titulo={ConstanteFuncionalidades.PDV_CANCELAR.titulo}
                        descricao={
                          ConstanteFuncionalidades.PDV_CANCELAR.descricao
                        }
                        tela={LogAuditoriaTelaEnum.PDV}
                        permissoes={[
                          ConstanteFuncionalidades.PDV_CANCELAR.codigo,
                        ]}
                      />
                    )}
                    {modalConfirmacaoCancelarIsOpen && (
                      <ModalConfirmacao
                        isOpen={modalConfirmacaoCancelarIsOpen}
                        setIsOpen={setModalConfirmacaoCancelarIsOpen}
                        onConfirm={cancelarOperacao}
                        textoCabecalho="Deseja cancelar esta operação?"
                        textoMensagem={
                          detalhesTroca.idOperacao !== ''
                            ? 'Somente a troca será cancelada, a devolução continuará no sistema. Deseja continuar?'
                            : 'Continuar, cancelará toda a operação e esta ação não pode ser desfeita. Deseja continuar?'
                        }
                        textoCancelar="Não, voltar!"
                      />
                    )}
                  </Layout>
                </MenuContextProvider>
              )}
            </AcrescimosDescontosContext.Consumer>
          </AcrescimosDescontosProvider>
        )}
      </PagamentoContext.Consumer>
    </PagamentoProvider>
  );
};

export default Pagamento;
