import { useMediaQuery } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import auth from 'modules/auth';
import ConstanteRotas from 'constants/rotas';
import TipoSistemaEnum from 'constants/enum/tipoSistema';
import { pushExternalUrl } from 'helpers/layout/pushExternalUrl';

import { IconesFrenteCaixaComercial } from 'icons';

const useFrenteCaixaComercial = () => {
  const history = useHistory();
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const isZendar = auth.getSistema().value === TipoSistemaEnum.ZENDAR;

  const redirectToAppStore = () => {
    history.push(ConstanteRotas.LOJA_APLICATIVOS);
  };

  const sendWhatsappMessage = () => {
    const phone = 551434113320;
    const url = `https://api.whatsapp.com/send?phone=${phone}`;

    pushExternalUrl(url, '_blank');
  };

  const segments = [
    {
      icon: IconesFrenteCaixaComercial.Restaurante,
      text: 'Restaurante',
    },
    { icon: IconesFrenteCaixaComercial.Pizzaria, text: 'Pizzaria' },
    { icon: IconesFrenteCaixaComercial.Lanchonete, text: 'Lanchonete' },
    { icon: IconesFrenteCaixaComercial.Hamburgueria, text: 'Hamburgueria' },
    { icon: IconesFrenteCaixaComercial.Bar, text: 'Bar' },
    { icon: IconesFrenteCaixaComercial.Padaria, text: 'Padaria' },
    { icon: IconesFrenteCaixaComercial.Choperia, text: 'Choperia' },
    { icon: IconesFrenteCaixaComercial.Doceria, text: 'Doceria' },
    { icon: IconesFrenteCaixaComercial.Cafe, text: 'Café' },
    { icon: IconesFrenteCaixaComercial.Sorveteria, text: 'Sorveteria' },
  ];

  return {
    isLargerThan900,
    isZendar,
    segments,
    sendWhatsappMessage,
    redirectToAppStore,
  };
};

export default useFrenteCaixaComercial;
