import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form } from 'react-bootstrap';
import { FieldErrors } from 'react-hook-form';

import { BootstrapNumberInput } from 'components/update/Input/BootstrapNumberInput';
import { Label } from 'components/Layout/CampoContainer/CampoContainerPadrao/styles';

interface UncontrolledFormInterface {
  errors: FieldErrors;
  control: any;
}

const OutrosValores: React.FC<UncontrolledFormInterface> = ({
  errors,
  control,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Form.Row>
        <Col xl={12}>
          <div className="d-flex mt-1">
            <Label>Informações da CIDE</Label>
          </div>
          <hr className="my-auto flex-grow-1" />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col md={3} xl={2}>
          <BootstrapNumberInput
            id="cideBaseCalculo"
            name="cideBaseCalculo"
            label={t('Base de cálculo CIDE')}
            precision={12}
            scale={2}
            defaultValue={0}
            leftElement="R$"
            control={control}
            error={errors.cideBaseCalculo}
          />
        </Col>
        <Col md={3} xl={2}>
          <BootstrapNumberInput
            id="cideAliquota"
            name="cideAliquota"
            label={t('Alíquota CIDE')}
            precision={12}
            scale={2}
            defaultValue={0}
            leftElement="%"
            control={control}
            error={errors.cideAliquota}
          />
        </Col>
        <Col md={3} xl={2}>
          <BootstrapNumberInput
            id="cideValor"
            name="cideValor"
            label={t('Valor CIDE')}
            precision={12}
            scale={2}
            defaultValue={0}
            leftElement="%"
            control={control}
            error={errors.cideValor}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xl={12}>
          <div className="d-flex mt-1">
            <Label>Informações para combustíveis líquidos</Label>
          </div>
          <hr className="my-auto flex-grow-1" />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col md={4} xl={4}>
          <BootstrapNumberInput
            id="quantidadeFaturada"
            name="quantidadeFaturada"
            label={t('Quantidade faturada à temperatura ambiente')}
            precision={12}
            scale={2}
            defaultValue={0}
            control={control}
            error={errors.quantidadeFaturada}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xl={12}>
          <div className="d-flex mt-1">
            <Label>Informações do ICMS interestadual</Label>
          </div>
          <hr className="my-auto flex-grow-1" />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col md={3} xl={2}>
          <BootstrapNumberInput
            id="icmsUfDestinoBaseCalculo"
            name="icmsUfDestinoBaseCalculo"
            label={t('Base de cálculo ICMS UF destino')}
            precision={12}
            scale={2}
            leftElement="R$"
            defaultValue={0}
            control={control}
            error={errors.quantidadeFaturada}
          />
        </Col>
        <Col md={3} xl={2}>
          <BootstrapNumberInput
            id="icmsUfDestinoPercentual"
            name="icmsUfDestinoPercentual"
            label={t('Alíquota ICMS UF destino')}
            precision={12}
            scale={2}
            leftElement="%"
            defaultValue={0}
            control={control}
            error={errors.icmsUfDestinoPercentual}
          />
        </Col>
        <Col md={3} xl={2}>
          <BootstrapNumberInput
            id="icmsUfDestinoValor"
            name="icmsUfDestinoValor"
            label={t('Valor ICMS UF destino')}
            precision={12}
            scale={2}
            leftElement="R$"
            defaultValue={0}
            control={control}
            error={errors.icmsUfDestinoValor}
          />
        </Col>
        <Col md={3} xl={2}>
          <BootstrapNumberInput
            id="fcpUfDestinoBaseCalculo"
            name="fcpUfDestinoBaseCalculo"
            label={t('Base de cálculo FCP UF destino')}
            precision={12}
            scale={2}
            leftElement="R$"
            defaultValue={0}
            control={control}
            error={errors.fcpUfDestinoBaseCalculo}
          />
        </Col>
        <Col md={3} xl={2}>
          <BootstrapNumberInput
            id="fcpUfDestinoPercentual"
            name="fcpUfDestinoPercentual"
            label={t('Alíquota FCP UF destino')}
            precision={12}
            scale={2}
            leftElement="%"
            defaultValue={0}
            control={control}
            error={errors.fcpUfDestinoPercentual}
          />
        </Col>
        <Col md={3} xl={2}>
          <BootstrapNumberInput
            id="fcpUfDestinoValor"
            name="fcpUfDestinoValor"
            label={t('Valor FCP UF destino')}
            precision={12}
            scale={2}
            leftElement="R$"
            defaultValue={0}
            control={control}
            error={errors.fcpUfDestinoValor}
          />
        </Col>
        <Col md={3} xl={2}>
          <BootstrapNumberInput
            id="icmsAliquotaInterestadual"
            name="icmsAliquotaInterestadual"
            label={t('Alíquota interestadual')}
            precision={12}
            scale={2}
            leftElement="%"
            defaultValue={0}
            control={control}
            error={errors.icmsAliquotaInterestadual}
          />
        </Col>
      </Form.Row>
    </>
  );
};

export default OutrosValores;
