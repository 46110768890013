export const ProdutosEnum = {
  PRINCIPAL: 0,
  VARIACOES: 1,
  CONFIGURACOES_FOOD: 2,
  FICHA_TECNICA: 3,
  PRODUTO_ETAPA: 4,
  KITS: 5,
  PRECO: 6,
  IMAGEM: 7,
  FISCAIS: 8,
  GERAL: 9,
  ECOMMERCE: 10,
};
