import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Modal } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import getOptionsByEnum from 'helpers/format/getOptionsByEnum';
import EstadosEnum from 'constants/enum/estados';

import ButtonPadrao from 'components/Button/ButtonPadrao';
import InputNull from 'components/Input/InputNull';
import { SalvarConfirmarIcon } from 'icons';
import ModalPadrao from 'components/Modal/ModalPadrao';
import InputCpfCnpj from 'components/Input/InputCpfCnpj';
import SelectPadrao from 'components/Select/SelectPadrao';

import { useForm, yupResolver } from './validationForm';

interface UncontrolledFormInterface {
  show: boolean;
  onHide: () => void;
  indexItem: number;
}

const PagamentoModalAlterar: React.FC<UncontrolledFormInterface> = ({
  show,
  onHide,
  indexItem,
}) => {
  const { t } = useTranslation();

  const {
    setValue: parentSetValue,
    getValues: parentGetValues,
  } = useFormContext();

  const {
    formState: { errors },
    control,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    setFocus,
  } = useForm({
    resolver: yupResolver,
  });

  const alterarItem = (data: any) => {
    const { documentoFiscalPagamentos } = parentGetValues();
    documentoFiscalPagamentos[indexItem].vencimento = data.vencimento;
    documentoFiscalPagamentos[indexItem].ufPagamento = data.ufPagamentoAlterar;
    documentoFiscalPagamentos[indexItem].cnpjPagamento =
      data.cnpjPagamentoAlterar;

    parentSetValue('documentoFiscalPagamentos', [...documentoFiscalPagamentos]);
  };

  const onSubmit = handleSubmit(async (data) => {
    await alterarItem(data);
    onHide();
  });

  useEffect(() => {
    if (show) {
      const { documentoFiscalPagamentos } = parentGetValues();
      const {
        vencimento,
        ufPagamento,
        cnpjPagamento,
      } = documentoFiscalPagamentos[indexItem];
      clearErrors();

      setValue('vencimento', new Date(vencimento).toISOString().split('T')[0]);
      setValue('ufPagamentoAlterar', ufPagamento);
      setValue('cnpjPagamentoAlterar', cnpjPagamento);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <>
      {show && (
        <ModalPadrao
          isOpen={show}
          handleOnHide={onHide}
          title={t('Alterar dados de pagamento')}
          subtitle={t('Informe os dados de pagamento da nota.')}
          onEntered={() => setFocus('vencimento')}
          size="lg"
          minWidth="400px"
        >
          <Form>
            <Modal.Body>
              <Form.Row>
                <Col md={6} xl={6}>
                  <InputCpfCnpj
                    control={control}
                    error={errors.cnpjPagamentoAlterar}
                    setError={setError}
                    name="cnpjPagamentoAlterar"
                    id="cnpjPagamentoAlterar"
                    label="CNPJ do estabelecimento"
                    placeholder="000.000.000-00"
                    required={false}
                  />
                </Col>
                <Col md={6} xl={6}>
                  <SelectPadrao
                    placeholder="Selecione o UF"
                    id="ufPagamentoAlterar"
                    name="ufPagamentoAlterar"
                    colSpan={12}
                    options={getOptionsByEnum(EstadosEnum, 'sigla', 'sigla')}
                    label="UF do CNPJ do estabelecimento"
                    helperText="Informe a Unidade Federativa (UF) do CNPJ do estabelecimento onde o pagamento será processado, transacionado ou recebido."
                    size="lg"
                    defaultValue={null}
                    control={control}
                    error={errors.ufPagamentoAlterar}
                    readonly={false}
                    required={false}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Col md={6} xl={6}>
                  <InputNull
                    type="date"
                    id="vencimento"
                    dateLimiter
                    name="vencimento"
                    label={t('Vencimento')}
                    control={control}
                    error={errors.vencimento}
                    disabled={false}
                    required
                  />
                </Col>
              </Form.Row>
            </Modal.Body>
            <Modal.Footer>
              <ButtonPadrao
                id="alterarPagamento"
                name="alterarPagamento"
                text={t('Alterar')}
                icon={SalvarConfirmarIcon}
                type="button"
                variant="success"
                onClick={onSubmit}
                style={{ height: '35px', width: '100%', maxWidth: '120px' }}
              />
            </Modal.Footer>
          </Form>
        </ModalPadrao>
      )}
    </>
  );
};

export default PagamentoModalAlterar;
