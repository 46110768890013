import React, { useEffect, useState, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useHistory, RouteComponentProps, Prompt } from 'react-router-dom';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteRotas from 'constants/rotas';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import isPrenvent from 'helpers/layout/isPrenvent';
import { obterMovimentacaoEstoque as apiObterMovimentacaoEstoque } from 'helpers/api/MovimentacaoEstoque/obterMovimentacaoEstoque';

import ManterFoco from 'components/Geral/ManterFoco';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { FormFooter } from 'components/update/Form/FormFooter';

import Formulario from '..';
import { yupResolver, FormData } from '../validationForm';
import { ForwardRefProps } from '../types';

type TParams = { id: string };

const Alterar = ({ match }: RouteComponentProps<TParams>) => {
  const history = useHistory();
  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues: {
      usuario: '',
      dataEmissao: new Date().toLocaleString('pt-BR'),
    },
  });
  const isMountedRef = useIsMountedRef();

  const [dataHoraCriacao, setDataHoraCriacao] = useState('');
  const [dataHoraUltimaAlteracao, setDataHoraUltimaAlteracao] = useState('');

  const formularioRef = useRef<ForwardRefProps>(null);

  const { dirtyFields } = formMethods.formState;
  const isDirty =
    dirtyFields &&
    dirtyFields.constructor === Object &&
    Object.keys(dirtyFields).length > 0;

  const [isPrenvented, setIsPrenvented] = useState(false);
  isPrenvent(isPrenvented);

  const movimentacaoEstoqueId = match.params.id;

  const [isLoading, setIsLoading] = useState(false);

  function historyPush(path: string) {
    setIsPrenvented(false);

    history.push(path);
  }

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    if (formularioRef.current) {
      if (formularioRef.current.produtos.length) {
        setIsLoading(true);

        const { motivo } = data;

        const listaProdutoQuantidade = formularioRef.current.produtos
          .filter((produto) => !produto.operacaoItemId)
          .map(({ produtoCorTamanhoId, quantidade }) => ({
            produtoCorTamanhoId,
            quantidade,
          }));

        const response = await api.put<void, ResponseApi>(
          ConstanteEnderecoWebservice.MOVIMENTACAO_ESTOQUE_ALTERAR,
          {
            operacaoId: movimentacaoEstoqueId,
            motivo,
            listaProdutoQuantidade,
            listaOperacoesItemExcluir:
              formularioRef.current.operacoesItemExcluir,
          }
        );

        if (response) {
          if (response.avisos) {
            response.avisos.map((item: string) => toast.warning(item));
          }

          if (response.sucesso) {
            toast.success('O cadastro foi alterado com sucesso.');

            historyPush(ConstanteRotas.MOVIMENTACAO_ESTOQUE);
          }
        }
      } else {
        toast.warning(
          'Adicione ao menos um produto para salvar a movimentação.'
        );

        formularioRef.current.handleSetProdutosError();
      }
    }

    if (isMountedRef.current) setIsLoading(false);
  });

  const latestProps = useRef({
    reset: formMethods.reset,
    movimentacaoEstoqueId,
    historyPush,
    setFocus: formMethods.setFocus,
  });
  useEffect(() => {
    latestProps.current = {
      reset: formMethods.reset,
      movimentacaoEstoqueId,
      historyPush,
      setFocus: formMethods.setFocus,
    };
  });

  useEffect(() => {
    async function obterMovimentacaoEstoque() {
      setIsLoading(true);
      const responseData = await apiObterMovimentacaoEstoque(
        latestProps.current.movimentacaoEstoqueId
      );
      if (responseData) {
        const {
          dataEmissao,
          dataHoraUltimaAlteracao: newDataHoraUltimaAlteracao,
          usuarioNome,
          tipoOperacao,
          motivo,
          localEstoqueOpcaoSelect,
        } = responseData;

        setDataHoraCriacao(dataEmissao);
        setDataHoraUltimaAlteracao(newDataHoraUltimaAlteracao);

        latestProps.current.reset({
          usuario: usuarioNome,
          tipoOperacao,
          motivo,
          dataEmissao: new Date(dataEmissao).toLocaleString('pt-BR'),
          localEstoque: {
            value: localEstoqueOpcaoSelect.id,
            label: localEstoqueOpcaoSelect.nome,
          },
        });
        setIsLoading(false);
        latestProps.current.setFocus('tipoOperacao');
      } else {
        setIsLoading(false);
        latestProps.current.historyPush(ConstanteRotas.MOVIMENTACAO_ESTOQUE);
      }
    }

    obterMovimentacaoEstoque();
  }, []);

  useEffect(() => {
    setIsPrenvented(isDirty);
  }, [isDirty]);

  return (
    <Box>
      <Prompt when={isPrenvented} message="" />
      <ManterFoco>
        {isLoading && <LoadingPadrao />}
        <FormProvider {...formMethods}>
          <Formulario
            ref={formularioRef}
            movimentacaoEstoqueId={movimentacaoEstoqueId}
            setIsPrenvented={setIsPrenvented}
          />
        </FormProvider>

        <FormFooter
          onSubmit={handleSubmit}
          isDisabled={isLoading}
          dates={{ dataHoraCriacao, dataHoraUltimaAlteracao }}
        />
      </ManterFoco>
    </Box>
  );
};

export default Alterar;
