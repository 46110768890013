import React, { useEffect, useRef, useState } from 'react';
import { Controller, Control } from 'react-hook-form';
import {
  FormControl,
  FormLabel,
  Input as ChakraInput,
  InputGroup as ChakraInputGroup,
  InputRightElement as ChakraInputRightElement,
  InputProps as ChakraInputProps,
  TypographyProps,
  useToken,
  useBreakpointValue,
  IconButton,
  Icon,
  Tooltip,
  TooltipProps,
} from '@chakra-ui/react';
import { FiEye, FiEyeOff } from 'react-icons/fi';

import { useFullScreenContext } from 'store/FullScreen';

export const InputTooltip = ({ children, ...rest }: TooltipProps) => {
  const { handleFullScreen } = useFullScreenContext();

  return (
    <Tooltip
      hasArrow
      portalProps={{ containerRef: handleFullScreen.node }}
      textAlign="left"
      placement="bottom-start"
      p={6}
      fontSize="sm"
      fontWeight="normal"
      borderRadius="base"
      maxW="full"
      bg="red.300"
      color="white"
      {...rest}
    >
      {children}
    </Tooltip>
  );
};

interface InputProps extends Omit<ChakraInputProps, 'placeholder'> {
  id: string;
  name: string;
  label: string;
  control: Control<Record<string, any>> | Control<any>;
  errorText?: string;
  tooltipErrorProps?: Omit<TooltipProps, 'children'>;
  labelFontSize?: TypographyProps['fontSize'];
  autoFillBoxShadow?: string;
  considerColorInFocus?: boolean;
}

const Input = ({
  id,
  name,
  label,
  control,
  defaultValue,
  errorText,
  tooltipErrorProps,
  focusBorderColor = 'aquamarine.400',
  considerColorInFocus,
  errorBorderColor = 'red.300',
  isInvalid,
  isDisabled,
  isReadOnly,
  type: inputType,
  autoComplete,
  color = 'white',
  autoFillBoxShadow,
  size: inputSize,
  onChange: inputOnChange,
  ...rest
}: InputProps) => {
  const [fontSizeXS] = useToken('fontSizes', ['xs']);
  const breakpointSize = useBreakpointValue({ base: 'sm', xl: 'md' });
  const [isFocus, setIsFocus] = useState(false);

  const firstRenderRef = useRef(true);
  const inputRef = useRef<HTMLInputElement>();

  const asPassword = inputType === 'password';
  const type = asPassword ? 'text' : inputType;

  const [textIsShowing, setTextIsShowing] = useState(!asPassword);

  useEffect(() => {
    if (inputRef.current && !firstRenderRef.current) {
      inputRef.current.setSelectionRange(
        inputRef.current.value.length,
        inputRef.current.value.length
      );
      inputRef.current.focus();
    }

    firstRenderRef.current = false;
  }, [textIsShowing]);

  return (
    <Controller
      defaultValue={defaultValue}
      render={({ field }) => {
        const hasValue = !!field.value;

        const labelLineHeightSize = `calc(1.5 * ${fontSizeXS})`; // FormLabel line-height support

        const handleTogglePasswordVisibility = () => {
          setTextIsShowing(!textIsShowing);
        };

        return (
          <InputTooltip
            isDisabled={!isInvalid}
            aria-label={errorText}
            label={errorText}
            {...tooltipErrorProps}
          >
            <FormControl
              sx={{
                'input:-webkit-autofill ~ label': {
                  top: !isFocus && !hasValue ? '-20px' : '0',
                },
              }}
              isInvalid={isInvalid}
              isDisabled={isDisabled}
              isReadOnly={isReadOnly}
            >
              <ChakraInputGroup
                size={inputSize || breakpointSize}
                mt={labelLineHeightSize}
              >
                <ChakraInput
                  variant="flushed"
                  color={color}
                  onFocus={() => {
                    setIsFocus(true);
                  }}
                  onBlur={() => {
                    setIsFocus(false);
                  }}
                  borderColor={color}
                  fontSize="16px"
                  _focus={{
                    borderColor: isInvalid
                      ? errorBorderColor
                      : focusBorderColor,
                  }}
                  sx={{
                    '::-ms-reveal': {
                      display: 'none',
                    },
                  }}
                  _autofill={{
                    WebkitTextFillColor: color,
                    boxShadow: '0 0 0px 100px transparent inset',
                    transition: 'background-color 5000s ease-in-out 0s',
                  }}
                  onChange={(e) => {
                    field.onChange(e);

                    if (inputOnChange) {
                      inputOnChange(e);
                    }
                  }}
                  {...rest}
                  id={id}
                  ref={(e: any) => {
                    inputRef.current = e;
                    field.ref(e);
                  }}
                  value={field.value}
                  type={textIsShowing ? type : 'password'}
                  focusBorderColor={focusBorderColor}
                  errorBorderColor={errorBorderColor}
                  autoComplete={
                    autoComplete || (asPassword ? 'off' : autoComplete)
                  }
                />
                {asPassword && (
                  <ChakraInputRightElement
                    top="50%"
                    transform="translateY(-60%)"
                  >
                    <IconButton
                      borderRadius="md"
                      aria-label="Alterar visibilidade da senha"
                      icon={
                        <Icon
                          as={textIsShowing ? FiEyeOff : FiEye}
                          fontSize="lg"
                        />
                      }
                      size={inputSize || breakpointSize}
                      variant="ghost"
                      color={color}
                      onClick={handleTogglePasswordVisibility}
                      tabIndex={-1}
                    />
                  </ChakraInputRightElement>
                )}
                <FormLabel
                  htmlFor={name}
                  position="absolute"
                  color={isInvalid ? errorBorderColor : color}
                  fontSize={hasValue ? '14px' : '16px' || breakpointSize}
                  fontWeight="normal"
                  m={0}
                  mr={hasValue ? 3 : 0}
                  transform={hasValue ? 'translateY(-100%)' : 'translateY(0%)'}
                  _focus={{
                    color: isInvalid
                      ? errorBorderColor
                      : considerColorInFocus
                      ? color
                      : focusBorderColor,
                    fontSize: '14px',
                    mr: 3,
                    transform: 'translateY(-100%)',
                  }}
                >
                  {label}
                </FormLabel>
              </ChakraInputGroup>
            </FormControl>
          </InputTooltip>
        );
      }}
      name={`${name}` as const}
      control={control}
    />
  );
};
export default Input;
