import { useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import ConstanteRotas from 'constants/rotas';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import { FormData } from './validationForm';

const useRelatorioPersonalizadoForm = () => {
  const history = useHistory();

  const inputFileRef = useRef<HTMLInputElement>(null);

  const obterRelatorioPersonalizado = useCallback(
    async (relatorioPersonalizadoId: string) => {
      const response = await api.get<void, ResponseApi<FormData>>(
        ConstanteEnderecoWebservice.FRENTE_CAIXA_RELATORIO_PERSONALIZADO_OBTER,
        {
          params: { id: relatorioPersonalizadoId },
        }
      );

      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response?.sucesso && response?.dados) {
          return response.dados;
        }
      }

      return undefined;
    },
    []
  );

  const atualizarRelatorioPersonalizado = async (
    relatorioPersonalizadoId: string,
    data: FormData
  ) => {
    const response = await api.put<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.FRENTE_CAIXA_RELATORIO_PERSONALIZADO_ALTERAR,
      { ...data },
      {
        params: { id: relatorioPersonalizadoId },
      }
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response?.sucesso) {
        return { success: true };
      }
    }

    return { success: false };
  };

  const salvarRelatorioPersonalizado = async (data: FormData) => {
    const response = await api.post<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.FRENTE_CAIXA_RELATORIO_PERSONALIZADO_CADASTRAR,
      { ...data }
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response?.sucesso) {
        return { success: true };
      }
    }

    return { success: false };
  };

  const redirecionarRelatorioPersonalizadoListar = () => {
    history.push(ConstanteRotas.FRENTE_CAIXA_RELATORIO_PERSONALIZADO);
  };

  return {
    obterRelatorioPersonalizado,
    atualizarRelatorioPersonalizado,
    salvarRelatorioPersonalizado,
    redirecionarRelatorioPersonalizadoListar,
    inputFileRef,
  };
};

export default useRelatorioPersonalizadoForm;
