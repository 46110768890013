export const enumTiposDeJuros = {
  PORCENTAGEM_DIARIA: 1,
  PORCENTAGEM_MENSAL: 2,

  properties: {
    1: { name: 'Porcentagem diária', value: 1 },
    2: { name: 'Porcentagem mensal', value: 2 },
  },
};

export const enumTiposDeMulta = {
  REAIS: 0,
  PORCENTAGEM: 1,

  properties: {
    0: { name: 'R$', value: 0 },
    1: { name: '%', value: 1 },
  },
};
