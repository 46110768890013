import React from 'react';
import { Textarea, InputProps } from '@chakra-ui/react';
import { useFormContext, Controller } from 'react-hook-form';

import CampoPersonalizadoInterface from 'types/campoPersonalizado';

import CampoContainer from 'components/PDV/Geral/CampoContainer';

interface SearchInputProps extends InputProps {
  name: string;
  campoPersonalizado?: CampoPersonalizadoInterface;
  label?: string;
  helperText?: string;
  helperTextLabel?: string;
}

export const TextArea = ({
  id,
  name,
  label,
  helperText,
  helperTextLabel,
  isDisabled,
  campoPersonalizado,
  placeholder,
  minH = '80px',
  _disabled,
}: SearchInputProps) => {
  const {
    setValue,
    formState: { errors },
  } = useFormContext();

  const errorText = errors[name]?.message;

  return (
    <CampoContainer
      id={id}
      name={name}
      helperTextLabel={helperTextLabel}
      label={label}
      errorText={errorText}
      helperText={helperText}
    >
      <Controller
        name={`${name}` as const}
        render={({ field: { onChange, value } }) => {
          const newValue = campoPersonalizado ? value?.valor : value;
          return (
            <Textarea
              placeholder={placeholder}
              value={newValue}
              isDisabled={isDisabled}
              minH={minH}
              _disabled={_disabled}
              onChange={onChange}
              onBlur={(e) => {
                if (campoPersonalizado) {
                  setValue(name, {
                    campoPersonalizadoId: campoPersonalizado.id,
                    valor: e.target.value,
                  });
                } else {
                  setValue(name, e.target.value);
                }
              }}
            />
          );
        }}
      />
    </CampoContainer>
  );
};
