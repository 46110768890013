import React from 'react';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

import CoresListar from '../pages/Cores/Listar';
import CoresCadastrar from '../pages/Cores/Formulario/Cadastrar';
import CoresAlterar from '../pages/Cores/Formulario/Alterar';
import CoresVisualizar from '../pages/Cores/Formulario/Visualizar';

const CoresRoutes = [
  <LayoutGuard
    key="cores-listar"
    permissaoFuncionalidade={ConstanteFuncionalidades.COR_LISTAR}
    component={CoresListar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Cores',
      },
    ]}
    path={ConstanteRotas.COR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="cores-cadastrar"
    permissaoFuncionalidade={ConstanteFuncionalidades.COR_CADASTRAR}
    component={CoresCadastrar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Cores',
        path: ConstanteRotas.COR,
      },
      {
        title: 'Novo',
      },
    ]}
    path={ConstanteRotas.COR_CADASTRAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="cores-alterar"
    permissaoFuncionalidade={ConstanteFuncionalidades.COR_ALTERAR}
    component={CoresAlterar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Cores',
        path: ConstanteRotas.COR,
      },
      {
        title: 'Editar',
      },
    ]}
    path={ConstanteRotas.COR_ALTERAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="cores-visualizar"
    permissaoFuncionalidade={ConstanteFuncionalidades.COR_VISUALIZAR}
    component={CoresVisualizar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Cores',
        path: ConstanteRotas.COR,
      },
      {
        title: 'Visualizar',
      },
    ]}
    path={ConstanteRotas.COR_VISUALIZAR}
    exact
    meta={{ auth: true }}
  />,
];

export default CoresRoutes;
