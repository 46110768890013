import React from 'react';
import {
  Text,
  ButtonProps,
  useMediaQuery,
  Checkbox,
  Icon,
  Button,
  Flex,
  VStack,
} from '@chakra-ui/react';

import { NaoSelecionadoIcon } from 'icons';

type Loja = {
  fantasia: string;
  cidade?: string;
  logradouro?: string;
  numeroBairro?: string;
  endereco?: string;
};

interface LojaButtonProps extends ButtonProps {
  isSelected: boolean;
  isBlocked: boolean;
  loja: Loja;
}

const LojaButton = ({
  isSelected,
  isBlocked,
  loja,
  ...rest
}: LojaButtonProps) => {
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  return (
    <Button
      bg="none"
      w={isLargerThan900 ? '414px' : 'full'}
      h={isLargerThan900 ? '88px' : '96px'}
      padding="0px"
      borderRadius="md"
      display="flex"
      alignItems="flex-start"
      textAlign="left"
      flexWrap="wrap"
      whiteSpace="normal"
      _focus={{ boxShadow: 'none' }}
      {...rest}
    >
      <Flex
        cursor={isBlocked ? 'not-allowed' : 'pointer'}
        h="full"
        w="full"
        alignItems="center"
        borderRadius="md"
        border={isBlocked ? 'none' : '1px solid'}
        borderColor={isSelected ? 'purple.50' : 'gray.50'}
        boxShadow="0px 0px 4px #00000029"
        transition="all 0.2s"
        bg={isSelected ? 'purple.50' : isBlocked ? 'gray.50' : 'white'}
        _hover={{
          borderWidth: '1px',
          borderStyle: 'solid',
          border: `${isBlocked ? 'none' : 'undefined'}`,
          borderColor: 'rgba(85, 2, 178, 0.5)',
          transition: 'all 0.2s',
        }}
        _active={{ bg: 'white' }}
        flexWrap="wrap"
      >
        <Flex alignItems="center" padding="16px" gap="16px" maxW="100%">
          {isBlocked ? (
            <Icon as={NaoSelecionadoIcon} boxSize="20px" color="gray.400" />
          ) : (
            <Checkbox
              colorScheme="primary"
              mb="0px"
              isChecked={isSelected}
              boxShadow="inset 0px 3px 6px #00000029"
            />
          )}
          <VStack flexWrap="wrap" direction="column">
            <Text fontSize="sm" fontWeight="semibold" w="100%">
              {loja.fantasia}
            </Text>
            {isLargerThan900 ? (
              <Flex
                fontSize="xs"
                color="gray.400"
                alignItems="flex-start"
                w="100%"
                style={{ marginTop: '1px' }}
              >
                <Text>
                  {loja.logradouro} {loja.numeroBairro} {loja.cidade}
                </Text>
              </Flex>
            ) : (
              <Flex
                fontSize="xs"
                color="gray.400"
                alignItems="flex-start"
                w="100%"
                style={{ marginTop: '1px' }}
              >
                {loja.endereco && (
                  <Text>
                    {loja.endereco}
                    {loja.cidade
                      ? `${loja.endereco ? ' - ' : ''}${loja.cidade}`
                      : ''}
                  </Text>
                )}
              </Flex>
            )}
          </VStack>
        </Flex>
      </Flex>
    </Button>
  );
};

export default LojaButton;
