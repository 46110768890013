import React from 'react';
import {
  Switch as ChakraSwitch,
  SwitchProps as ChakraSwitchProps,
} from '@chakra-ui/react';

import CampoPrototipo, {
  CampoPrototipoProps,
} from 'components/PDV/Geral/CampoPrototipo';

export type SwitchProps = Omit<ChakraSwitchProps, 'ref' | 'isChecked'> &
  CampoPrototipoProps & {
    change?: ((event: boolean) => void) | undefined;
    campoPersonalizado?: boolean;
  };

export const Switch = ({
  id,
  name,
  label,
  helperText,
  campoPersonalizado = false,
  colSpan,
  colStart,
  colEnd,
  rowStart,
  rowEnd,
  rowSpan,
  maxW,
  minW,
  isRequired = false,
  isDisabled,
  change,
  onChange: switchOnChange,
  colorScheme = 'primary',
  size = 'lg',
  w,
  width,
  ...rest
}: SwitchProps) => {
  return (
    <CampoPrototipo
      name={name}
      isRequired={isRequired}
      isDisabled={isDisabled}
      helperText={helperText}
      id={id}
      label={label}
      rowSpan={rowSpan}
      rowStart={rowStart}
      rowEnd={rowEnd}
      colSpan={colSpan}
      colStart={colStart}
      colEnd={colEnd}
      w={w}
      width={width}
    >
      {(_, { value: valueForm, onChange }) => {
        const value = campoPersonalizado ? valueForm?.valor : valueForm;
        return (
          <ChakraSwitch
            isChecked={!!value}
            onChange={(e) => {
              onChange(e.currentTarget.checked);

              if (switchOnChange) {
                switchOnChange(e);
              }
              if (change) {
                change(e.target.checked);
              }
            }}
            size={size}
            colorScheme={colorScheme}
            isDisabled={isDisabled}
            id={id}
            {...rest}
          />
        );
      }}
    </CampoPrototipo>
  );
};
