import React, { memo } from 'react';
import { Controller } from 'react-hook-form';

import ContainerInputPadrao from 'components/Layout/CampoContainer/CampoContainerPadrao';

import Input, { InputCpfCnpjProps } from './Input';

const InputCpfCnpj = ({
  id,
  name,
  label,
  error,
  required,
  infoText,
  control,
  defaultValue,
  ...rest
}: InputCpfCnpjProps) => {
  return (
    <ContainerInputPadrao
      id={id}
      label={label}
      error={error}
      required={required}
      infoText={infoText}
    >
      <Controller
        defaultValue={defaultValue}
        render={({ field }) => (
          <Input
            id={id}
            name={name}
            label={label}
            required={required}
            infoText={infoText}
            field={field}
            {...rest}
          />
        )}
        name={`${name}` as const}
        control={control}
      />
    </ContainerInputPadrao>
  );
};

export default memo(InputCpfCnpj);
