import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import AsyncSelect from 'react-select/async';
import { OptionTypeBase, Props as SelectProps } from 'react-select';
import { Controller, FieldError, UseFormGetValues } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import ContainerInputPadrao from 'components/Layout/CampoContainer/CampoContainerPadrao';

import { selectStyles } from '../styles';

export interface SelectCidadeRefInterface {
  handleSetDefaultOptions: () => void;
}

export interface ListaCidadesInterface {
  id: number;
  nome: string;
  codigoIBGE: string;
  estadoNome: string;
  estadoSigla: string;
  paisId?: number;
}

interface SelectCidadeProps extends SelectProps<OptionTypeBase> {
  id: string;
  name: string;
  label: string;
  required?: boolean;
  infoText?: string;
  control: any;
  error?: FieldError | null;
  getSelectedCidade?: (cidadeId: number) => void;
  getValue: UseFormGetValues<any>;
  paisKey?: string;
  readonly?: boolean;
}

const SelectCidade = forwardRef<SelectCidadeRefInterface, SelectCidadeProps>(
  (
    {
      id,
      name,
      label,
      required,
      infoText,
      control,
      error = null,
      placeholder = 'Digite a cidade',
      isLoading: isLoadingProp,
      getSelectedCidade,
      getValue,
      paisKey = 'pais',
      isDisabled,
      ...rest
    },
    ref
  ) => {
    const [isLoading, setIsLoading] = useState(false);
    const [defaultOptions, setDefaultOptions] = useState<OptionTypeBase[]>([]);
    const currentInputValue = useRef('');

    const loadOptions = useCallback(
      async (inputValue: string, callback?: any, canSearchForce?: boolean) => {
        if (isDisabled) {
          callback([]);
          return;
        }

        setIsLoading(true);

        const promise = new Promise(() => {
          setTimeout(
            async () => {
              if (currentInputValue.current === inputValue || canSearchForce) {
                let paisId = getValue(paisKey);

                if (typeof paisId !== typeof 0) {
                  if (paisId && paisId.value) paisId = paisId.value;
                }

                const response = await api.get<
                  void,
                  ResponseApi<ListaCidadesInterface[]>
                >(ConstanteEnderecoWebservice.CIDADE_LISTAR_CACHE, {
                  params: {
                    nome: inputValue,
                    paisId,
                  },
                });

                if (response?.avisos) {
                  response.avisos.map((item: string) => toast.warning(item));
                }

                if (response?.sucesso) {
                  if (
                    response?.dados.length === 1 &&
                    !inputValue &&
                    paisId &&
                    getSelectedCidade
                  ) {
                    const firstCidade = response?.dados[0];
                    getSelectedCidade(firstCidade.id);
                  }

                  callback(
                    response?.dados.map((cidade: ListaCidadesInterface) => {
                      return {
                        label: `${cidade.nome} - ${cidade.estadoSigla}`,
                        value: cidade.id,
                        paisId: cidade.paisId,
                      };
                    })
                  );

                  setIsLoading(false);
                  return;
                }
              }

              callback([]);
              setIsLoading(false);
            },
            canSearchForce ? 0 : 500
          );
        });

        // eslint-disable-next-line consistent-return
        return promise;
      },
      [isDisabled, getValue, paisKey, getSelectedCidade]
    );

    const handleSetDefaultOptions = useCallback(() => {
      loadOptions(
        '',
        (value: OptionTypeBase[]) => {
          setDefaultOptions(value);
        },
        true
      );
    }, [loadOptions]);

    useImperativeHandle(ref, () => ({
      handleSetDefaultOptions,
    }));

    useEffect(() => {
      handleSetDefaultOptions();
    }, [handleSetDefaultOptions]);

    return (
      <ContainerInputPadrao
        id={id}
        label={label}
        error={error}
        required={required}
        infoText={infoText}
      >
        <Controller
          defaultValue=""
          render={({ field }) => (
            <AsyncSelect
              id={id}
              loadOptions={loadOptions}
              ref={field.ref}
              classNamePrefix="react-select"
              isClearable
              isLoading={isLoadingProp || isLoading}
              onInputChange={(newValue: string) => {
                currentInputValue.current = newValue;
              }}
              onChange={(newValue: any) => {
                field.onChange(newValue);

                if (getSelectedCidade)
                  getSelectedCidade(newValue ? newValue.value : undefined);
              }}
              defaultOptions={defaultOptions}
              value={field.value || null}
              noOptionsMessage={() => 'Digite o nome da cidade desejada'}
              loadingMessage={() => 'Carregando...'}
              placeholder={placeholder}
              styles={{
                ...selectStyles,
                indicatorSeparator: (base: any) => ({
                  ...base,
                }),
              }}
              isDisabled={isDisabled}
              {...rest}
            />
          )}
          // onFocus={() => {
          //   selectRef.current.focus();
          // }}
          name={`${name}` as const}
          control={control}
        />
      </ContainerInputPadrao>
    );
  }
);

export default SelectCidade;
