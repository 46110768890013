import {
  createContext,
  useContext,
  MutableRefObject,
  Dispatch,
  SetStateAction,
  useState,
  useRef,
  ReactNode,
} from 'react';
import { toast } from 'react-toastify';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import { FormData } from 'pages/ContasReceber/Listar/validationForm';
import api, { ResponseApi } from 'services/api';

interface ContextFiltersProps {
  setLastFilters: Dispatch<SetStateAction<FormData | undefined>>;
  lastFilters?: FormData;
  carregarDadosContexto: MutableRefObject<boolean>;
  fetchTotalPerPeriod(dataForm: FormData): void;
  totalPerPeriod: TotalPerPeriod;
}

interface ContextFiltrosProvider {
  children: ReactNode;
}

type TotalPerPeriod = {
  quantidadeContas: number;
  valorTotalDescontos: number;
  valorTotalMultasJuros: number;
  valorTotalReceber: number;
};

const ContextFiltrosContasReceber = createContext({} as ContextFiltersProps);

export const ContextFiltrosProvider = ({
  children,
}: ContextFiltrosProvider) => {
  const [lastFilters, setLastFilters] = useState<FormData>();
  const [totalPerPeriod, setTotalPerPeriod] = useState<TotalPerPeriod>(
    {} as TotalPerPeriod
  );
  const carregarDadosContexto = useRef(true);

  async function fetchTotalPerPeriod(dataForm: FormData) {
    const filtersPerPeriod = {
      ...dataForm,
      identificador: dataForm?.identificador || 0,
      dataVencimentoInicio: dataForm?.dataVencimentoInicio?.toISOString() || '',
      dataVencimentoFim: dataForm?.dataVencimentoFim?.toISOString() || '',
      dataEmissaoInicio: dataForm?.dataEmissaoInicio
        ? dataForm.dataEmissaoInicio.toISOString()
        : '',
      dataEmissaoFim: dataForm?.dataEmissaoFim
        ? dataForm?.dataEmissaoFim.toISOString()
        : '',
      dataPagamentoInicio: dataForm?.dataPagamentoInicio
        ? dataForm.dataPagamentoInicio.toISOString()
        : '',
      dataPagamentoFim: dataForm?.dataPagamentoFim
        ? dataForm.dataPagamentoFim?.toISOString()
        : '',
      clienteFornecedorId: dataForm?.clienteFornecedorId?.value ?? '',
      planoContasId: dataForm?.planoContasId ?? '',
      situacao: dataForm?.situacao || '',
      dataCompensacaoFim: dataForm?.dataCompensacaoFim?.toISOString() ?? '',
      dataCompensacaoInicio:
        dataForm?.dataCompensacaoInicio?.toISOString() ?? '',
    };

    const response = await api.get<void, ResponseApi<TotalPerPeriod>>(
      ConstanteEnderecoWebservice.CONTAS_RECEBER_OBTER_TOTALIZADORES,
      {
        params: filtersPerPeriod,
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item) => toast.warning(item));
      }

      if (response.sucesso) {
        setTotalPerPeriod(response.dados);
      }
    }
  }

  return (
    <ContextFiltrosContasReceber.Provider
      value={{
        lastFilters,
        setLastFilters,
        carregarDadosContexto,
        totalPerPeriod,
        fetchTotalPerPeriod,
      }}
    >
      {children}
    </ContextFiltrosContasReceber.Provider>
  );
};

export const useContextFiltrosContasReceber = () => {
  const context = useContext(ContextFiltrosContasReceber);

  if (!context) {
    throw new Error(
      'useContextFiltrosContasReceber must be used within a useContextFiltrosContaspagar.'
    );
  }

  return context;
};
