import React, { createContext, useState, useContext, useCallback } from 'react';

interface MetasComissoesCadastroContextProps {
  periodoMinimoParaFiltrar: string;
  valorMetaGeral: number;
  setValorMetaGeral: React.Dispatch<React.SetStateAction<number>>;
  setPeriodoMetaCadastrada: React.Dispatch<React.SetStateAction<Date>>;
  utilizaMetaGeral: boolean;
  setUtilizaMetaGeral: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MetasComissoesCadastroContext = createContext<MetasComissoesCadastroContextProps>(
  {} as MetasComissoesCadastroContextProps
);

interface MetasComissoesCadastroProviderProps {
  children: React.ReactNode;
}

export function MetasComissoesCadastroProvider({
  children,
}: MetasComissoesCadastroProviderProps): JSX.Element {
  const [periodoMetaCadastrada, setPeriodoMetaCadastrada] = useState(
    new Date()
  );
  const [valorMetaGeral, setValorMetaGeral] = useState(0);
  const [utilizaMetaGeral, setUtilizaMetaGeral] = useState(false);

  const periodoMinimoParaFiltrar = useCallback(() => {
    const periodo = new Date(periodoMetaCadastrada);
    const periodoMes = periodo.getMonth() + 1;
    return `${periodo.getFullYear()}-${periodoMes > 9 ? '' : 0}${periodoMes}`;
  }, [periodoMetaCadastrada])();

  return (
    <MetasComissoesCadastroContext.Provider
      value={{
        valorMetaGeral,
        setValorMetaGeral,
        utilizaMetaGeral,
        setUtilizaMetaGeral,
        periodoMinimoParaFiltrar,
        setPeriodoMetaCadastrada,
      }}
    >
      {children}
    </MetasComissoesCadastroContext.Provider>
  );
}

export function useMetasComissoesCadastroContext(): MetasComissoesCadastroContextProps {
  const context = useContext(MetasComissoesCadastroContext);

  if (!context)
    throw new Error(
      'useMetasComissoesCadastroContext must be used within a MetasComissoesEtapasProvider.'
    );

  return context;
}
