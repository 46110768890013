export const EnumFormaPagamentoPremiacaoMetas = {
  PERCENTUAL: 1,
  VALOR_FIXO: 2,
  NAO_POSSUI_BONIFICACAO: 3,

  properties: [
    { name: 'Percentual sobre o valor faturado pelo vendedor', value: 1 },
    { name: 'Valor fixo para todos os vendedores', value: 2 },
    { name: 'Não informar bonificação', value: 3 },
  ],
};
