import { Box, Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react';

import { ActionsMenu } from 'components/update/Table/ActionsMenu';

import { useProdutoItem } from './hooks';

export const ProdutoItem = () => {
  const {
    casasDecimais,
    listProdutos,
    handleEditarProduto,
    handleExcluirProduto,
  } = useProdutoItem();

  return (
    <Box overflow="auto" pb="10px" px={1}>
      <Table variant="card">
        <Thead>
          <Tr>
            <Th w="45%" pb="5px" whiteSpace="nowrap" userSelect="none">
              Produto
            </Th>
            <Th w="25%" pb="5px" whiteSpace="nowrap" userSelect="none">
              Tamanho
            </Th>
            <Th
              w="25%"
              isNumeric
              pb="5px"
              whiteSpace="nowrap"
              userSelect="none"
            >
              Quantidade
            </Th>
            <Th
              isNumeric
              pb="5px"
              w="10%"
              whiteSpace="nowrap"
              userSelect="none"
            >
              Preço original
            </Th>

            <Th
              isNumeric
              pb="5px"
              w="10%"
              whiteSpace="nowrap"
              userSelect="none"
            >
              Preço promoção
            </Th>
            <Th w="1px" pb="5px" whiteSpace="nowrap" userSelect="none">
              Ações
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {(listProdutos || [])?.length > 0 ? (
            (listProdutos || [])?.map((produtoItem, index) => (
              <>
                <Tr>
                  <Td
                    w="45%"
                    whiteSpace="nowrap"
                  >{`${produtoItem.produtoNome} ${produtoItem.corDescricao}`}</Td>
                  <Td w="25%">{produtoItem.tamanhoDescricao || '-'}</Td>
                  <Td w="25%" isNumeric>
                    {produtoItem.quantidade || 0}
                  </Td>
                  <Td isNumeric w="10%">
                    {produtoItem?.precoVenda?.toLocaleString('pt-BR', {
                      minimumFractionDigits: casasDecimais.casasDecimaisValor,
                      maximumFractionDigits: casasDecimais.casasDecimaisValor,
                    })}
                  </Td>
                  <Td isNumeric w="10%">
                    {produtoItem?.precoNovo?.toLocaleString('pt-BR', {
                      minimumFractionDigits: casasDecimais.casasDecimaisValor,
                      maximumFractionDigits: casasDecimais.casasDecimaisValor,
                    })}
                  </Td>

                  <Td w="1px">
                    <ActionsMenu
                      items={[
                        {
                          content: 'Editar',
                          onClick: () =>
                            handleEditarProduto(produtoItem, index),
                        },
                        {
                          content: 'Remover',
                          onClick: () => handleExcluirProduto(index),
                        },
                      ]}
                    />
                  </Td>
                </Tr>
                <Box h="5px" />
              </>
            ))
          ) : (
            <Tr>
              <Td whiteSpace="nowrap" colSpan={9999}>
                Nenhum produto adicionado.
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  );
};
