import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { Button, Flex, GridItem } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { addMonths } from 'date-fns';

import { getValorParcelas } from 'helpers/data/getValorParcelas';
import { getNonWeekendDate } from 'helpers/data/getNonWeekendDate';

import { NumberInput } from 'components/update/Input/NumberInput';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { DateInput } from 'components/update/Input/DateInput';

import { FormData, yupResolver } from './validationForm';

export type ParcelaGerada = {
  parcela: string;
  vencimento: Date;
  valor: number;
};

interface GerarParcelasForm {
  onSubmit: (parcelas: ParcelaGerada[], quantidadeParcelas: number) => void;
  isReadOnly?: boolean;
}

export function GerarParcelasForm({ onSubmit, isReadOnly }: GerarParcelasForm) {
  const { getValues, watch } = useFormContext();

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues: {
      quantidadeParcelas: 1,
    },
  });

  const parcelasWatch: ParcelaGerada[] = watch('parcelas') || [];

  const handleSubmit = formMethods.handleSubmit(
    ({ quantidadeParcelas, dataVencimento }) => {
      const valorTotal = getValues('valorTotal');
      const formaPagamento = getValues('formaPagamento');

      if (valorTotal && formaPagamento) {
        const valorParcelas = getValorParcelas(valorTotal, quantidadeParcelas);

        const parcelas: ParcelaGerada[] = Array.from(
          { length: quantidadeParcelas },
          (_, index) => {
            const date = addMonths(new Date(dataVencimento), index);
            const newDateVencimento = getNonWeekendDate(date);

            return {
              parcela: `${index + 1}/${quantidadeParcelas}`,
              vencimento: newDateVencimento,
              valor: valorParcelas[index],
            };
          }
        );

        onSubmit(parcelas, quantidadeParcelas);
      } else {
        toast.warning(
          'Informe o valor total e a forma de pagamento para poder gerar parcelas.'
        );
      }
    }
  );

  useEffect(() => {
    formMethods.setValue(
      'dataVencimento',
      parcelasWatch[0]?.vencimento || new Date()
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...formMethods}>
      <SimpleGridForm>
        <NumberInput
          id="quantidadeParcelas"
          name="quantidadeParcelas"
          label="Quantidade de parcelas"
          scale={0}
          min={1}
          defaultValue={0}
          max={360}
          colSpan={{ base: 12, sm: 4, lg: 3 }}
          isDisabled={isReadOnly}
        />

        <DateInput
          id="dataVencimento"
          name="dataVencimento"
          label="Data de vencimento da 1ª parcela"
          colSpan={{ base: 12, sm: 4, lg: 3 }}
          isDisabled={isReadOnly}
        />

        <GridItem colSpan={{ base: 12, sm: 4, lg: 6 }}>
          <Flex h="full" alignItems="flex-end">
            <Button
              colorScheme="purple"
              onClick={handleSubmit}
              isDisabled={isReadOnly}
            >
              Gerar parcelas
            </Button>
          </Flex>
        </GridItem>
      </SimpleGridForm>
    </FormProvider>
  );
}
