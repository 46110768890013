import { Flex, Button } from '@chakra-ui/react';

type BotoesFooterProps = {
  handleSubmit: () => void;
  onClose: () => void;
  mt?: string;
  h?: string;
};

export const BotoesFooterDevolucao = ({
  handleSubmit,
  onClose,
  mt,
  h,
}: BotoesFooterProps) => {
  return (
    <Flex mt={mt} h={h} w="full" alignItems="center" justifyContent="center">
      <Button
        id="cancelar"
        name="cancelar"
        colorScheme="gray.100"
        variant="outline"
        borderRadius="20px"
        fontSize="sm"
        type="button"
        w="96px"
        h="32px"
        mr={6}
        _hover={{ bg: 'gray.50' }}
        onClick={onClose}
      >
        Cancelar
      </Button>

      <Button
        id="confirmar"
        name="confirmar"
        w="140px"
        h="32px"
        backgroundColor="secondary.400"
        borderRadius="20px"
        fontSize="sm"
        color="black"
        variant="success"
        type="button"
        onClick={() => {
          handleSubmit();
        }}
        _hover={{ bg: 'asecondary.500' }}
      >
        Confirmar
      </Button>
    </Flex>
  );
};
