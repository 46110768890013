import { useCallback } from 'react';
import { toast } from 'react-toastify';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';
import {
  isDataURI,
  calcDataUriBytesSize,
  byteToSize,
  formatBytes,
  dataURItoBase64,
} from 'helpers/validation/imagesAttachment';
import { CategoriaProdutoType } from 'store/CategoriasProduto';

import { Option, FormData } from './types';

export const useCategory = () => {
  const editCategory = async (data: FormData) => {
    const response = await api.put<void, ResponseApi>(
      ConstanteEnderecoWebservice.CATEGORIA_PRODUTO_ALTERAR,
      { ...data, nivel: 1, ultimoNivel: false }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
        return false;
      }
      if (response.sucesso) {
        toast.success('Categoria alterada com sucesso');
        return true;
      }
    }
    return false;
  };

  const submitChanges = async (
    data: FormData,
    categoriaProduto: CategoriaProdutoType,
    isSubCategory: boolean
  ) => {
    let categoriaData;
    let success = false;

    if (isDataURI(data?.foto)) {
      const bytesSize = calcDataUriBytesSize(data?.foto);

      if (byteToSize(bytesSize, 'MB') > 1) {
        toast.warning(
          `O arquivo selecionado é muito grande ${formatBytes(
            bytesSize
          )}, este campo aceita somente imagens de até 1MB`
        );
        return success;
      }

      categoriaData = {
        ...data,
        id: categoriaProduto.id,
        foto: dataURItoBase64(data?.foto),
      };
    } else {
      categoriaData = {
        ...data,
        id: categoriaProduto.id,
      };
    }
    if (isSubCategory) {
      success = await editCategory({
        ...categoriaData,
        categoriaProdutoPaiId: categoriaProduto.categoriaProdutoPaiId,
      });
    } else {
      success = await editCategory({
        ...categoriaData,
        nivel: 1,
        ultimoNivel: false,
      });
    }
    return success;
  };

  return { editCategory, submitChanges };
};

export const useComplementos = () => {
  const getComplementosFromCategory = useCallback(async (id: string) => {
    const response = await api.get<
      void,
      ResponseApi<
        {
          nomeProduto: string;
          id: string;
          produtoId: string;
        }[]
      >
    >(ConstanteEnderecoWebservice.COMPLEMENTO_OBTER, {
      params: {
        categoriaProdutoId: id,
      },
    });
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }
      if (response.dados) {
        return response.dados;
      }
    }
    return [] as {
      nomeProduto: string;
      id: string;
      produtoId: string;
    }[];
  }, []);

  const removeComplement = async (id: string) => {
    const response = await api.delete<void, ResponseApi>(
      ConstanteEnderecoWebservice.COMPLEMENTO_EXCLUIR,
      {
        params: {
          id,
        },
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }
      if (response.sucesso) {
        toast.success('Complemento removido com sucesso');
        return {
          success: true,
        };
      }
    }
    return {
      success: false,
    };
  };

  const addComplement = async (
    idCategoria: string,
    idProduct: string
  ): Promise<{ success: boolean; id?: string }> => {
    const response = await api.post<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.COMPLEMENTO_CADASTRAR,
      {
        produtoId: idProduct,
        categoriaProdutoId: idCategoria,
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }
      if (response.sucesso && response.dados) {
        toast.success('Complemento adicionado com sucesso');
        return {
          success: true,
          id: response.dados,
        };
      }
    }
    return {
      success: false,
    };
  };

  const loadProductComplementOptions = async (inputValue: string) => {
    const response = await api.get<
      void,
      ResponseApi<
        {
          id: string;
          nome: string;
        }[]
      >
    >(ConstanteEnderecoWebservice.PRODUTO_LISTAR_SELECT, {
      params: { produtoNomeReferencia: inputValue },
    });

    if (response?.avisos) {
      response.avisos.forEach((aviso: string) => toast.warning(aviso));
    }

    if (response && response.sucesso && response.dados) {
      const data = response.dados.map((option) => ({
        label: option.nome,
        value: option.id,
      }));

      return data;
    }

    return [];
  };

  return {
    getComplementosFromCategory,
    loadProductComplementOptions,
    removeComplement,
    addComplement,
  };
};

export const useObservacoes = () => {
  const createNewObservation = async (nomeObservacao: string) => {
    const response = await api.post<
      void,
      ResponseApi<{ observacaoId: string; nome: string }>
    >(ConstanteEnderecoWebservice.OBSERVACAO_CADASTRAR, {
      nome: nomeObservacao,
      ativo: true,
    });

    if (response?.avisos) {
      response.avisos.forEach((aviso: string) => toast.warning(aviso));
    }

    if (response && response.sucesso && response.dados) {
      const data = {
        label: nomeObservacao,
        value: response.dados,
      };

      return data;
    }

    return {} as Option;
  };

  const addObservacao = async (idProduct: string, idObservacao: string) => {
    const response = await api.post<void, ResponseApi>(
      ConstanteEnderecoWebservice.OBSERVACAO_VINCULAR,
      {
        categoriaProdutoId: idProduct,
        produtoObservacaoId: idObservacao,
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }
      if (response.sucesso) {
        toast.success('Observação adicionada com sucesso');
        return {
          success: true,
          id: response.dados,
        };
      }
    }
    return {
      success: false,
    };
  };

  const removeObservacao = async (idObservacao: string) => {
    const response = await api.delete<void, ResponseApi>(
      `${ConstanteEnderecoWebservice.OBSERVACAO_EXCLUIR}/${idObservacao}`
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }
      if (response.sucesso) {
        toast.success('Observação removida com sucesso');
        return {
          success: true,
        };
      }
    }
    return {
      success: false,
    };
  };

  const loadObservationsOptions = async (inputValue: string) => {
    const response = await api.get<
      void,
      ResponseApi<
        {
          id: string;
          nome: string;
        }[]
      >
    >(ConstanteEnderecoWebservice.OBSERVACAO_LISTAR_SELECT, {
      params: { nomeObservacao: inputValue },
    });

    if (response?.avisos) {
      response.avisos.forEach((aviso: string) => toast.warning(aviso));
    }

    if (response && response.sucesso && response.dados) {
      const data = response.dados.map((option) => ({
        label: option.nome,
        value: option.id,
      }));

      return data;
    }

    return [];
  };

  const getObservationsFromCategory = useCallback(async (id: string) => {
    const response = await api.get<
      void,
      ResponseApi<
        {
          observacao: string;
          id: string;
          produtoObservacaoId: string;
        }[]
      >
    >(`${ConstanteEnderecoWebservice.OBSERVACAO_OBTER}/${id}/observacao`);
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }
      if (response.dados) {
        return response.dados;
      }
    }
    return [] as {
      observacao: string;
      id: string;
      produtoObservacaoId: string;
    }[];
  }, []);

  return {
    createNewObservation,
    loadObservationsOptions,
    removeObservacao,
    addObservacao,
    getObservationsFromCategory,
  };
};
