import { useEffect } from 'react';
import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import Lottie from 'lottie-react';

import Foguete from 'assets/animations/foguete.json';
import { useSignalRContext } from 'store/SignalR';
import { useEmissaoNotaFiscalContext } from 'store/NotaFiscal/EmissaoNotaFiscal';

import { NotaFiscalExibirTela } from '../validation';

type EmitindoNotaFiscalProps = {
  isMultipleVendas: boolean;
  amountVendas: number;
  onClose: (shouldReloadList?: boolean) => void;
};

export const EmitindoNotaFiscal = ({
  isMultipleVendas,
  amountVendas,
  onClose,
}: EmitindoNotaFiscalProps) => {
  const { handleNextTela } = useEmissaoNotaFiscalContext();
  const {
    notificarNotaFiscal,
    statusNotaFiscal,
    avisoNotaFiscal,
  } = useSignalRContext();

  const notasEmitidas = notificarNotaFiscal?.mensagem;

  useEffect(() => {
    const closeModalTimeout = window.setTimeout(() => {
      onClose(true);
    }, 45 * 1000);

    if (statusNotaFiscal) {
      window.clearTimeout(closeModalTimeout);

      if (
        statusNotaFiscal?.quantidadeRejeitada > 0 ||
        statusNotaFiscal.status?.includes('Rejeitada')
      ) {
        if (
          isMultipleVendas &&
          statusNotaFiscal?.quantidadeRejeitada !== amountVendas
        ) {
          handleNextTela(NotaFiscalExibirTela.NOTA_POSSUI_REJEICAO);
        } else {
          handleNextTela(NotaFiscalExibirTela.NOTA_REJEITADA);
        }
      } else {
        handleNextTela(NotaFiscalExibirTela.NOTA_APROVADA);
      }
    } else if (avisoNotaFiscal) {
      window.clearTimeout(closeModalTimeout);

      handleNextTela(NotaFiscalExibirTela.NOTA_REJEITADA);
    }

    return () => {
      window.clearTimeout(closeModalTimeout);
    };
  }, [
    handleNextTela,
    isMultipleVendas,
    amountVendas,
    statusNotaFiscal,
    avisoNotaFiscal,
    onClose,
  ]);

  return (
    <Box h="full" w="full">
      {isMultipleVendas && (
        <Flex
          pt="38px"
          pr="48px"
          w="full"
          justifyContent="flex-end"
          color="gray.700"
        >
          <Tooltip
            hasArrow
            placement="left-start"
            borderRadius="5px"
            label={
              <Box px="15px" py="15px">
                Sim, a emissão da nota fiscal acontece em segundo plano no
                sistema. Dependendo do número de notas a conclusão pode ser
                demorada. Se preferir, você pode fechar esta tela e continuar
                utilizando o sistema normalmente. Enviaremos uma notificação
                assim que o processo estiver concluído.
              </Box>
            }
          >
            <Box>
              <Text textDecoration="underline">Posso fechar a janela?</Text>
            </Box>
          </Tooltip>
        </Flex>
      )}
      <Flex
        bg="gray.100"
        w="full"
        h={isMultipleVendas ? '80%' : '100%'}
        justifyContent="center"
        alignItems="center"
      >
        <Flex alignItems="center" justifyContent="center">
          <Box>
            <Lottie
              animationData={Foguete}
              loop
              style={{
                width: '230px',
                height: '230px',
              }}
            />
          </Box>
          <Box>
            <Text color="gray.700" fontSize="20px">
              Emitindo Nota Fiscal Eletrônica…{' '}
            </Text>
            {isMultipleVendas && (
              <Text color="violet.700" fontSize="20px">
                {notasEmitidas
                  ? `${notasEmitidas} de ${amountVendas}`
                  : `0 de ${amountVendas}`}
              </Text>
            )}
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};
