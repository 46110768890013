import React, { useMemo } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useMediaQuery,
  useDisclosure,
  ModalHeader,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { BrowserRouter, Route } from 'react-router-dom';

import PadronizacaoProvider from 'store/Padronizacao/Padronizacao';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import CadastrarVendedor from 'pages/Vendedores/Formulario/Cadastrar';

type ModalCadastrarVendedorResponse = {
  vendedor: any;
};

type ModalCadastrarVendedorProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalCadastrarVendedorResponse>;

export const ModalCadastrarVendedor = create<
  ModalCadastrarVendedorProps,
  ModalCadastrarVendedorResponse
>(({ onResolve, onReject, ...rest }) => {
  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const cadastrarVendedor = useMemo(
    () => (
      <PadronizacaoProvider>
        <BrowserRouter>
          <Route
            render={() => (
              <CadastrarVendedor
                cadastroExterno
                cadastroSucessoCallback={(vendedor) => {
                  onResolve({ vendedor });
                }}
              />
            )}
          />
        </BrowserRouter>
      </PadronizacaoProvider>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <ModalPadraoChakra size="full" {...rest} isOpen={isOpen} onClose={onClose}>
      <ModalContent mx="6" my="6">
        <ModalHeader
          mt={isSmallerThan900 ? 12 : undefined}
          mb={isSmallerThan900 ? 8 : undefined}
          borderBottom="1px"
          borderColor="gray.100"
          px="0"
          mx={{ base: 6, md: 8 }}
        >
          Cadastrar vendedor
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p={{ base: 6, md: 8 }}>{cadastrarVendedor}</ModalBody>
      </ModalContent>
    </ModalPadraoChakra>
  );
});
