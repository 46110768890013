import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';

import {
  LocaisLoja,
  TamanhoTodasLojas,
  VariacaoLojaProps,
} from 'components/Modal/ModalListarSaldoVariacaoPdv/types';

type DadosTabPanelEstoqueGeralProps = {
  exibirTodasAsLojas: VariacaoLojaProps[];
  naoExisteTamanhoTodasLojas: boolean;
  naoExisteCorTodasLojas: boolean;
  tamanhosAgrupadosTodasLojas: string[];
};

export const DadosTabPanelEstoqueGeral = ({
  exibirTodasAsLojas,
  naoExisteTamanhoTodasLojas,
  naoExisteCorTodasLojas,
  tamanhosAgrupadosTodasLojas,
}: DadosTabPanelEstoqueGeralProps) => {
  return (
    <Box>
      <Table mt="-10px">
        <Thead>
          <Tr>
            {naoExisteTamanhoTodasLojas ? (
              <>
                {naoExisteCorTodasLojas ? (
                  <Th fontSize="16px" color="aquamarine.600">
                    <Flex w="40px" justifyContent="flex-end">
                      Total
                    </Flex>
                  </Th>
                ) : (
                  <>
                    <Th />
                    <Th
                      fontSize="16px"
                      alignItems="left"
                      color="aquamarine.600"
                      isNumeric
                    >
                      Total
                    </Th>
                  </>
                )}
              </>
            ) : (
              <>
                {naoExisteCorTodasLojas ? <></> : <Th />}
                {tamanhosAgrupadosTodasLojas.map((tamanho) => (
                  <Th
                    isNumeric
                    fontSize="16px"
                    color="primary.500"
                    key={tamanho}
                  >
                    {tamanho}
                  </Th>
                ))}
                <Th isNumeric fontSize="16px" color="aquamarine.600">
                  Total
                </Th>
              </>
            )}
          </Tr>
        </Thead>
        <Tbody>
          {exibirTodasAsLojas.map(({ cor, tamanhos, valorTamanho, lojas }) => {
            const somandoValores = tamanhos.reduce(
              (acc, curr) => acc + (curr.quantidade || 0),
              0
            );

            const agruparTodosTamanhos = tamanhos.reduce((acc, curr) => {
              const itemAlreadyAddedIndex = acc.findIndex(
                (item) => item.tamanho === curr.tamanho
              );

              if (itemAlreadyAddedIndex > -1) {
                acc.splice(itemAlreadyAddedIndex, 1, {
                  ...acc[itemAlreadyAddedIndex],
                  locaisEstoque: [
                    ...acc[itemAlreadyAddedIndex].locaisEstoque,
                    {
                      quantidade: curr.quantidade,
                      localEstoque: curr.localEstoque,
                    },
                  ],
                  locaisLoja: [
                    ...acc[itemAlreadyAddedIndex].locaisLoja,
                    {
                      saldoLoja: curr.quantidade,
                      loja: curr.loja,
                    },
                  ],
                });
              } else {
                acc.push({
                  tamanho: curr.tamanho,
                  loja: curr.loja,
                  quantidade: curr.quantidade,
                  localEstoque: curr.localEstoque,
                  locaisEstoque: [
                    {
                      quantidade: curr.quantidade,
                      localEstoque: curr.localEstoque,
                    },
                  ],
                  locaisLoja: [
                    {
                      saldoLoja: curr.quantidade,
                      loja: curr.loja,
                    },
                  ],
                });
              }

              return acc;
            }, [] as TamanhoTodasLojas[]);

            const agruparLocaisLoja = lojas.reduce((acc, curr) => {
              const itemAlreadyAddedIndex = acc.findIndex(
                (item) => item.loja === curr.loja
              );

              if (itemAlreadyAddedIndex > -1) {
                acc.splice(itemAlreadyAddedIndex, 1, {
                  ...acc[itemAlreadyAddedIndex],
                  loja: curr.loja,
                  saldoLoja:
                    acc[itemAlreadyAddedIndex].saldoLoja + curr.saldoLoja,
                });
              } else {
                acc.push({
                  saldoLoja: curr.saldoLoja,
                  loja: curr.loja,
                });
              }

              return acc;
            }, [] as LocaisLoja[]);

            return (
              <Tr key={`${cor}`}>
                {cor ? (
                  <Td whiteSpace="nowrap" color="gray.700" fontSize="16px">
                    {cor || ''}
                  </Td>
                ) : (
                  <></>
                )}

                {tamanhosAgrupadosTodasLojas.map((tamanhoAgrupado) => {
                  const tamanho = agruparTodosTamanhos.find(
                    (val) => val.tamanho === tamanhoAgrupado
                  );

                  const quantidade = tamanho
                    ? tamanho.locaisEstoque.reduce(
                        (acc, curr) => acc + curr.quantidade,
                        0
                      )
                    : 0;

                  const filtrandoValores = tamanho?.locaisLoja.reduce(
                    (acc, curr) => {
                      const filtroExiste = acc.findIndex(
                        (valor) => valor.loja === curr.loja
                      );

                      if (filtroExiste > -1) {
                        acc.splice(filtroExiste, 1, {
                          ...acc[filtroExiste],
                          loja: curr.loja,
                          saldoLoja:
                            acc[filtroExiste].saldoLoja + curr.saldoLoja,
                        });
                      } else {
                        acc.push({
                          saldoLoja: curr.saldoLoja,
                          loja: curr.loja,
                        });
                      }

                      return acc;
                    },
                    [] as LocaisLoja[]
                  );

                  return (
                    <>
                      {valorTamanho ? (
                        <Td isNumeric fontSize="16px">
                          <Flex justify="end" w="full">
                            <Tooltip
                              isDisabled={quantidade === 0}
                              bg="gray.900"
                              color="white"
                              minW="245px"
                              borderRadius="6px"
                              hasArrow
                              placement="left"
                              label={
                                <Box padding="5px">
                                  <Text>
                                    {(filtrandoValores || []).map(
                                      (valorTodasLojas, index) => (
                                        <Box
                                          borderBottomWidth={
                                            index + 1 ===
                                            (filtrandoValores || []).length
                                              ? 0
                                              : '1px'
                                          }
                                          borderColor="white"
                                          mb="8px"
                                          pb="8px"
                                        >
                                          <Flex align="baseline" gap="6px">
                                            <Text fontSize="2xs">LOJA:</Text>
                                            <Text fontSize="sm">
                                              {valorTodasLojas.loja}
                                            </Text>
                                          </Flex>
                                          <Flex align="baseline" gap="6px">
                                            <Text fontSize="2xs">
                                              QUANTIDADE:{' '}
                                            </Text>
                                            <Text
                                              fontSize="sm"
                                              color={
                                                valorTodasLojas.saldoLoja <= 0
                                                  ? 'red.400'
                                                  : 'secondary.200'
                                              }
                                              ml="5px"
                                            >
                                              {valorTodasLojas.saldoLoja}
                                            </Text>
                                          </Flex>
                                        </Box>
                                      )
                                    )}
                                  </Text>
                                </Box>
                              }
                            >
                              <Text w="fit-content">{quantidade}</Text>
                            </Tooltip>
                          </Flex>
                        </Td>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
                <Td
                  fontSize="16px"
                  fontWeight="bold"
                  color="aquamarine.600"
                  isNumeric
                >
                  <Flex
                    w={
                      naoExisteCorTodasLojas && naoExisteTamanhoTodasLojas
                        ? '40px'
                        : 'full'
                    }
                    justify="flex-end"
                  >
                    <Tooltip
                      bg="gray.900"
                      color="white"
                      borderRadius="6px"
                      isDisabled={somandoValores === 0}
                      hasArrow
                      placement="left"
                      label={
                        <Box padding="5px">
                          {agruparLocaisLoja.map(
                            (valorTotalTodasLojas, index) => (
                              <Box
                                borderBottomWidth={
                                  index + 1 === (agruparLocaisLoja || []).length
                                    ? 0
                                    : '1px'
                                }
                                borderColor="white"
                                mb="8px"
                                pb="8px"
                              >
                                <Flex align="baseline" gap="6px">
                                  <Text fontSize="2xs">LOJA:</Text>
                                  <Text fontSize="sm">
                                    {valorTotalTodasLojas.loja}
                                  </Text>
                                </Flex>
                                <Flex align="baseline" gap="6px">
                                  <Text fontSize="2xs">QUANTIDADE: </Text>
                                  <Text
                                    fontSize="sm"
                                    color={
                                      valorTotalTodasLojas.saldoLoja <= 0
                                        ? 'red.400'
                                        : 'secondary.200'
                                    }
                                    ml="5px"
                                  >
                                    {valorTotalTodasLojas.saldoLoja}
                                  </Text>
                                </Flex>
                              </Box>
                            )
                          )}
                        </Box>
                      }
                    >
                      <Text
                        w="fit-content"
                        fontSize="16px"
                        fontWeight="bold"
                        color="aquamarine.600"
                      >
                        {somandoValores}
                      </Text>
                    </Tooltip>
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
};
