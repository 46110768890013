import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { getVendedorTray, getCadastrarVendedorTray } from 'services/tray';
import { useTrayPainelControleContext } from 'store/Tray/PainelControle';

import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import { ModalCadastrarVendedor } from 'components/Modal/ModalCadastrarVendedores';
import Loading from 'components/Layout/Loading/LoadingPadrao';

type VendedoresProps = {
  label: string;
  value: string;
};

interface VendedorInterface {
  id: string;
  nome: string;
}

export const Vendedor = () => {
  const [vendedores, setVendedores] = useState<VendedoresProps[]>([]);
  const { isLoading, setIsLoading } = useTrayPainelControleContext();

  const formMethods = useForm();

  const { setValue, reset, handleSubmit: onSubmit } = formMethods;

  const handleGetVendedoresValues = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<void, ResponseApi<VendedorInterface[]>>(
      ConstanteEnderecoWebservice.VENDEDOR_LISTAR_SELECT_POR_LOJA
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso) {
        const dados = response.dados.map((vendedor) => {
          return {
            label: vendedor.nome,
            value: vendedor.id,
          };
        });
        setVendedores(dados);
        setIsLoading(false);
        return dados;
      }
    }
    return [];
  }, [setIsLoading]);

  const handleAbrirModalCadastrarVendedor = async () => {
    const dadosVendedor = await ModalCadastrarVendedor();

    if (dadosVendedor) {
      const obterVendedor = await handleGetVendedoresValues();

      const newVendedor = obterVendedor.find(
        (vendedor) => vendedor.label === dadosVendedor.vendedor.nome
      );

      if (newVendedor) {
        setValue('vendedor', newVendedor.value);
      }
    }
  };

  const getObterVendedor = useCallback(async () => {
    setIsLoading(true);
    await handleGetVendedoresValues();

    const response = await getVendedorTray();

    if (response !== null) {
      reset({
        vendedor: response,
      });
    }

    setIsLoading(false);
  }, [handleGetVendedoresValues, reset, setIsLoading]);

  const handleSubmit = onSubmit(async (data) => {
    setIsLoading(true);
    const response = await getCadastrarVendedorTray({
      vendedor: data.vendedor,
    });
    if (response !== null) {
      toast.success('O vendedor foi alterado com sucesso');
    }
    setIsLoading(false);
  });

  useEffect(() => {
    getObterVendedor();
  }, [getObterVendedor]);

  return (
    <FormProvider {...formMethods}>
      {isLoading && <Loading />}
      <Box
        pt={['24px', '24px', '40px']}
        px={['0', '0', '20px']}
        w="full"
        minW="200px"
      >
        <SelectPadrao
          name="vendedor"
          helperTextLabel="Informe um vendedor para vincular com as vendas online. Todas as vendas integradas ao sistema serão contabilizadas para o nome selecionado. Se preferir, cadastre um novo vendedor exclusivo para as vendas da integração."
          id="vendedor"
          label="Vendedor"
          options={vendedores}
          isLoading={isLoading}
          placeholder=""
        />
        <Text
          mt="40px"
          onClick={handleAbrirModalCadastrarVendedor}
          textDecoration="underline"
          fontSize="14px"
          color="blue.700"
          cursor="pointer"
        >
          Cadastrar novo vendedor
        </Text>

        <Flex mt="40px" w="full" justifyContent="center" alignItems="baseline">
          <Button
            variant="outlineDefault"
            borderRadius="16px"
            colorScheme="gray"
            mr="24px"
            w="96px"
            isLoading={isLoading}
            onClick={getObterVendedor}
          >
            Cancelar
          </Button>
          <Button
            color="black"
            isLoading={isLoading}
            onClick={handleSubmit}
            variant="solid"
            colorScheme="secondary.400"
            borderRadius="16px"
            width="120px"
          >
            Confirmar
          </Button>
        </Flex>
      </Box>
    </FormProvider>
  );
};
