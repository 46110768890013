import { useCallback, useState, useEffect } from 'react';
import { Box, GridItem, Text, Flex, Button } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useZoopEtapasContext } from 'store/Zoop/ZoopEtapasContext';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { enumEtapasZoop } from 'constants/enum/enumEtapasZoop';
import auth from 'modules/auth';
import getMaxDataNascimento from 'helpers/data/getMaxDataNascimento';
import getMinDataNascimento from 'helpers/data/getMinDataNascimento';
import { handleGetCidade } from 'helpers/data/getCidadeObterCache';
import { CepResponse } from 'services/viacep';
import EstadosEnum from 'constants/enum/estados';
import getOptionsByEnum from 'helpers/format/getOptionsByEnum';

import InputCpfCnpj from 'components/PDV/InputCpfCnpj';
import InputTelefone from 'components/PDV/InputTelefonePdv';
import Input from 'components/PDV/Input';
import { NumberInput } from 'components/update/Input/NumberInput';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import { InputCep } from 'components/update/Input/InputCep';
import AsyncSelect from 'components/PDV/Select/AsyncSelectPadrao';

import {
  Categoria,
  CategoriaOptions,
  City,
  CredenciamentoData,
  Options,
  ResponseDadosLoja,
  styleSelects,
  yupResolver,
} from './validationForms';

export const Credenciamento = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState<CategoriaOptions[]>([]);
  const { setActiveStep } = useZoopEtapasContext();

  const formMethods = useForm({
    resolver: yupResolver,
  });

  const { handleSubmit, setValue, getValues, reset, watch } = formMethods;

  const { id: idStore } = auth.getLoja();

  const watchCategoria = watch('categoria');

  const getSiglaEstado = useCallback((estadoNome: string) => {
    const estados = Object.values(EstadosEnum?.properties);
    const estadoEncontrado = (estados || [])?.find(
      (estado) => estado?.nome === estadoNome?.toUpperCase()
    );
    const siglaEstado = estadoEncontrado ? estadoEncontrado?.sigla : '';

    return siglaEstado;
  }, []);

  const getActivities = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get<void, ResponseApi<Categoria[]>>(
      ConstanteEnderecoWebservice.LISTAR_ATIVIDADES
    );

    if (response) {
      if (response?.sucesso && response?.dados) {
        const { dados } = response;

        const listOptions = dados.map((item, index) => {
          return {
            label: item.categoria,
            value: index,
            atividades: item.atividades.map((atividade, atividadeIndex) => {
              return {
                label: atividade.descricao,
                value: `${atividadeIndex}-${atividade.code}`,
              };
            }),
          };
        });

        setCategories(listOptions);
      }
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }
    }
    setIsLoading(false);
  }, []);

  const getStoreInfo = useCallback(async () => {
    setIsLoading(true);
    if (idStore) {
      const response = await api.get<void, ResponseApi<ResponseDadosLoja>>(
        ConstanteEnderecoWebservice.LOJA_OBTER,
        {
          params: {
            id: idStore,
          },
        }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response.sucesso && response.dados) {
          const {
            cidadeNome,
            cidadeId,
            paisId,
            paisNome,
            estadoNome,
          } = response.dados;

          reset(response.dados);

          if (paisId && paisNome) {
            setValue('pais', {
              label: paisNome,
              value: paisId,
            });
          } else {
            setValue('pais', {
              label: 'Brasil',
              value: 1,
            });
          }

          setValue('cidade', {
            label: cidadeNome,
            value: cidadeId,
          });
          const siglaEstado = getSiglaEstado(estadoNome);
          setValue('estado', siglaEstado);
        }
      }
    }
    setIsLoading(false);
  }, [getSiglaEstado, idStore, reset, setValue]);

  const getCities = useCallback(async (valueInput: string) => {
    setIsLoading(true);
    const response = await api.get<void, ResponseApi<City[]>>(
      ConstanteEnderecoWebservice.CIDADE_LISTAR_CACHE,
      {
        params: { nome: valueInput },
      }
    );

    if (response?.avisos) {
      response.avisos.forEach((aviso: string) => toast.warning(aviso));
    }

    if (response?.sucesso) {
      setIsLoading(false);
      return response.dados.map((cidade) => {
        return {
          label: cidade.nome,
          value: cidade.id,
        } as Options;
      });
    }
    setIsLoading(false);
    return [];
  }, []);

  const handleAvancar = handleSubmit(async (data) => {
    setIsLoading(true);

    const siglaEstado = getSiglaEstado(data?.estadoNome);

    if (!siglaEstado)
      toast.warning(
        'O estado informado não é válido! Verifique se o nome está correto e tente novamente.'
      );

    const credenciamento: CredenciamentoData = {
      owner: {
        first_name: data?.nome,
        last_name: data?.sobrenome,
        email: data?.emailProprietario,
        phone_number: data?.celular?.trim(),
        taxpayer_id: data?.cpf,
        birthdate: data?.dataNascimento,
      },
      business_name: data?.razaoSocial,
      business_phone: data?.telefone.trim(),
      business_email: data?.email,
      ein: data?.cnpj,
      statement_descriptor: data?.fantasia,
      revenue: data?.faturamento.toString(),
      business_address: {
        line1: data?.logradouro,
        line2: data?.numero?.toString(),
        line3: data?.complemento,
        neighborhood: data?.bairro,
        city: data?.cidadeNome,
        state: siglaEstado,
        postal_code: data?.cep,
        country_code: 'BR',
      },
      owner_address: {
        line1: data?.logradouro,
        line2: data?.numero?.toString(),
        line3: data?.complemento,
        neighborhood: data?.bairro,
        city: data?.cidadeNome,
        state: siglaEstado,
        postal_code: data?.cep,
        country_code: 'BR',
      },
      mcc: data.mcc.replace(/.*[-]/, ''), // Remove o hifen e os caracteres antes dele (ex: 1-1234) retorna o código da atividade (ex: 1234)
    };

    const response = await api.post<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.CREDENCIAR_ZOOP,
      credenciamento
    );
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso) {
        toast.success(
          'Seu credenciamento foi enviado! Para acessar a integração no menu, é necessário fazer o login novamente.',
          {
            autoClose: 30000,
          }
        );
        setActiveStep(enumEtapasZoop.CONTA_BANCARIA);
      }
    }
    setIsLoading(false);
  });

  const fillCityInfo = useCallback(async () => {
    const selectedCity = getValues('cidade');

    const cityInfo = await handleGetCidade(
      selectedCity.value,
      '',
      selectedCity.label
    );

    if (cityInfo) {
      setValue('cidade', {
        label: cityInfo.nome,
        value: cityInfo.id,
      });
      const siglaEstado = getSiglaEstado(cityInfo.estadoNome);
      setValue('estado', siglaEstado);
    } else {
      setValue('cidade', null);
      setValue('estado', null);
    }
  }, [getSiglaEstado, getValues, setValue]);

  const fillCepInfo = async (data: CepResponse) => {
    const cidade = await getCities(data?.localidade);
    setValue('cidade', cidade[0]);
    await fillCityInfo();
    setValue('logradouro', data?.logradouro);
    setValue('bairro', data?.bairro);
    setValue('numero', null);
    setValue('complemento', data?.complemento);
  };

  useEffect(() => {
    getStoreInfo();
  }, [getStoreInfo]);

  useEffect(() => {
    getActivities();
  }, [getActivities]);

  return (
    <FormProvider {...formMethods}>
      {isLoading && <LoadingPadrao />}
      <Box padding="20px" pb="16px" borderRadius="5px">
        <SimpleGridForm spacing="24px">
          <GridItem colSpan={[12]}>
            <Input
              type="text"
              id="nome"
              name="nome"
              label="Nome do responsável"
              autoFocus={!isLoading}
              placeholder="Nome da pessoa responsável pela decisão comercial"
              maxLength={120}
              size="lg"
              borderColor="gray.200"
            />
          </GridItem>

          <GridItem colSpan={[12]}>
            <Input
              type="text"
              id="sobrenome"
              name="sobrenome"
              label="Sobrenome"
              placeholder="Informe o sobrenome completo"
              maxLength={120}
              size="lg"
              borderColor="gray.200"
            />
          </GridItem>

          <GridItem colSpan={[12]}>
            <Input
              type="text"
              id="emailProprietario"
              name="emailProprietario"
              label="E-mail"
              placeholder="Informe seu e-mail principal"
              maxLength={100}
              size="lg"
              borderColor="gray.200"
            />
          </GridItem>
          <GridItem colSpan={[12, 6]}>
            <InputCpfCnpj
              id="cpf"
              name="cpf"
              placeholder="000.000.000-00"
              label="CPF"
              asCpf
              size="lg"
              borderColor="gray.200"
            />
          </GridItem>

          <GridItem colSpan={[12, 6]}>
            <Input
              type="date"
              id="dataNascimento"
              name="dataNascimento"
              label="Data de nascimento"
              borderColor="gray.200"
              max={getMaxDataNascimento()}
              min={getMinDataNascimento()}
              size="lg"
            />
          </GridItem>

          <GridItem colSpan={12}>
            <Text fontSize="sm" color="black" fontWeight="semibold">
              Dados da empresa
            </Text>
            <SimpleGridForm
              bg="gray.100"
              padding="34px 24px"
              borderRadius="12px"
            >
              <GridItem colSpan={[12]}>
                <Input
                  id="razaoSocial"
                  name="razaoSocial"
                  placeholder="Nome da empresa que irá utilizar o serviço"
                  label="Razão social"
                  size="lg"
                  borderColor="gray.200"
                />
              </GridItem>
              <GridItem colSpan={[12, 6]}>
                <Input
                  id="fantasia"
                  name="fantasia"
                  placeholder="Digite o nome fantasia"
                  label="Nome fantasia"
                  size="lg"
                  helperText="Este é o nome que você vê na sua fatura associado a transação. É importante que seja algo que você reconheça facilmente para evitar problemas com chargebacks"
                  borderColor="gray.200"
                />
              </GridItem>

              <GridItem colSpan={[12, 6]}>
                <InputCpfCnpj
                  id="cnpj"
                  name="cnpj"
                  placeholder="00.000.000/0000-00"
                  label="CNPJ"
                  asCnpj
                  size="lg"
                  borderColor="gray.200"
                />
              </GridItem>
              <GridItem colSpan={[12, 6]}>
                <Input
                  type="text"
                  id="email"
                  name="email"
                  label="E-mail"
                  placeholder="Informe o e-mail principal da loja"
                  maxLength={80}
                  size="lg"
                  borderColor="gray.200"
                />
              </GridItem>
              <GridItem colSpan={[12, 6]}>
                <NumberInput
                  id="faturamento"
                  name="faturamento"
                  scale={2}
                  size="lg"
                  label="Faturamento"
                  placeholder="Informe o faturamento da loja"
                  color="gray.700"
                  prefix="R$"
                  fontSize="3xl"
                  maxH="40px"
                />
              </GridItem>

              <GridItem colSpan={[12, 6]}>
                <InputTelefone
                  inputMode="numeric"
                  type="tel"
                  size="lg"
                  id="telefone"
                  name="telefone"
                  label="Telefone"
                  placeholder="(00) 0000-0000"
                  maxLength={15}
                  borderColor="gray.200"
                />
              </GridItem>

              <GridItem colSpan={[12, 6]}>
                <InputTelefone
                  inputMode="numeric"
                  type="tel"
                  id="celular"
                  name="celular"
                  label="Celular"
                  size="lg"
                  borderColor="gray.200"
                  placeholder="(00) 0000-0000"
                />
              </GridItem>

              <GridItem colSpan={[12]}>
                <SelectPadrao
                  name="categoria"
                  size="lg"
                  styles={styleSelects}
                  id="categoria"
                  label="Categoria"
                  placeholder="Selecione uma categoria"
                  options={categories}
                  onSelect={(option) => {
                    setValue(
                      'mcc',
                      (option as CategoriaOptions)?.atividades[0]?.value
                    );
                  }}
                />
              </GridItem>
              <GridItem colSpan={[12]}>
                <SelectPadrao
                  name="mcc"
                  size="lg"
                  styles={styleSelects}
                  id="mcc"
                  label="Atividade"
                  placeholder="Selecione uma atividade"
                  options={categories[watchCategoria]?.atividades || []}
                />
              </GridItem>
              <GridItem colSpan={[12]}>
                <InputCep
                  id="cep"
                  name="cep"
                  label="CEP"
                  placeholder="00.000-000"
                  getCepData={fillCepInfo}
                  borderColor="gray.200"
                />
              </GridItem>
              <GridItem colSpan={[12]}>
                <Input
                  type="text"
                  id="logradouro"
                  name="logradouro"
                  label="Logradouro"
                  placeholder="Nome da rua, avenida, etc"
                  size="lg"
                  borderColor="gray.200"
                />
              </GridItem>
              <GridItem colSpan={[6]}>
                <Input
                  type="text"
                  id="numero"
                  name="numero"
                  label="Número"
                  placeholder="Número"
                  size="lg"
                  borderColor="gray.200"
                />
              </GridItem>
              <GridItem colSpan={[6]}>
                <Input
                  type="text"
                  id="complemento"
                  name="complemento"
                  label="Complemento"
                  placeholder="Se houver complemento"
                  size="lg"
                  borderColor="gray.200"
                />
              </GridItem>
              <GridItem colSpan={[12]}>
                <Input
                  type="text"
                  id="bairro"
                  name="bairro"
                  label="Bairro"
                  placeholder="Informe o bairro"
                  maxLength={80}
                  size="lg"
                  borderColor="gray.200"
                />
              </GridItem>

              <GridItem colSpan={[12]}>
                <AsyncSelect
                  placeholder="Selecione a cidade"
                  id="cidade"
                  isClearable
                  name="cidade"
                  isLoading={isLoading}
                  label="Cidade"
                  styles={styleSelects}
                  size="lg"
                  onSelect={() => {
                    fillCityInfo();
                  }}
                  handleGetOptions={getCities}
                  asControlledByObject
                />
              </GridItem>
              <GridItem colSpan={[6]}>
                <SelectPadrao
                  placeholder="Selecione o UF"
                  id="estado"
                  name="estado"
                  isLoading={isLoading}
                  options={getOptionsByEnum(EstadosEnum, 'sigla', 'sigla')}
                  label="Estado"
                  styles={styleSelects}
                  size="lg"
                />
              </GridItem>
              <GridItem colSpan={[6]}>
                <SelectPadrao
                  name="pais"
                  size="lg"
                  styles={styleSelects}
                  id="pais"
                  label="País"
                  placeholder="Selecione um país"
                  options={[]}
                  isDisabled
                  asControlledByObject
                />
              </GridItem>
            </SimpleGridForm>
          </GridItem>
        </SimpleGridForm>
      </Box>
      <Flex justifyContent="center" alignItems="center" mt="24px">
        <Button
          w="320px"
          colorScheme="purple"
          onClick={handleAvancar}
          variant="solid"
        >
          Avançar
        </Button>
      </Flex>
    </FormProvider>
  );
};
