import { useCallback, useImperativeHandle, useEffect } from 'react';
import { VStack, Text, Flex } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { IdentificacaoEtapasTray } from 'constants/enum/IdentificacaoEtapasTray';
import { useTrayEtapasContext } from 'store/Tray';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import StatusConsultaEnum from 'constants/enum/statusConsulta';

import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import { ModalConfirmar } from 'components/Modal/ModalConfirmar';
import { ModalDesistencia } from 'components/Modal/ModalDesistencia';
import { ImportarProdutosIcon } from 'icons';

import { Header } from '../Layout/Header';
import { yupResolver, FormData, formDefaultValues } from './validationForms';
import {
  ModalBuscarProduto,
  DadosProps,
  InformacaoProdutoProps,
} from './ModalBuscarProduto';

export const BuscarProduto = () => {
  const { ref, setActiveStep, setIsLoading } = useTrayEtapasContext();

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues: formDefaultValues,
  });

  const { handleSubmit: onSubmit, reset } = formMethods;

  const validandoReferenciaEan = async (ignorarReferencia: boolean) => {
    const response = await api.put<void, ResponseApi<boolean>>(
      `${ConstanteEnderecoWebservice.INTEGRACAO_TRAY_IMPORTAR_PRODUTOS_SITE}${ignorarReferencia}`
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }
      if (response.sucesso) {
        setActiveStep(IdentificacaoEtapasTray.IMPORTANDO);
      }
    }
  };

  const validandoProduto = async (ignorarReferenciaEan: boolean) => {
    ModalDesistencia({
      callback: async () => {
        await validandoReferenciaEan(ignorarReferenciaEan);
      },
      labelButtonConfirmar: 'Iniciar importação',
      title: 'Foram encontrados produtos com o código Ean duplicados',
      labelButtonCancelar: 'Cancelar',
      textoMensagem:
        'Ao confirmar essa ação, os produtos que possuem códigos EAN iguais podem acabar perdendo o valor do código EAN associado a eles.',
    });
  };

  const handleConfirmarExportacao = async (
    infoProduto: InformacaoProdutoProps
  ) => {
    ModalConfirmar({
      callback: async () => {
        if (infoProduto.ignorarReferenciaEan) {
          validandoProduto(infoProduto.ignorarReferenciaEan);
        } else {
          validandoReferenciaEan(infoProduto.ignorarReferenciaEan);
        }
      },
      labelButtonConfirmar: 'Iniciar importação',
      title:
        infoProduto.quantidadeProduto === 1
          ? `${infoProduto.quantidadeProduto} produto encontrado!`
          : `${infoProduto.quantidadeProduto} produtos encontrados!`,
      labelButtonCancelar: 'Pesquisar novamente',
      colorButtonConfirm: 'white',
      isConfirmar: true,
      textoMensagem:
        'Ao confirmar esta etapa, todos os produtos serão cadastrados automaticamente no sistema e esta ação não poderá ser desfeita.',
    });
  };

  const handleVoltar = useCallback(() => {
    setActiveStep(IdentificacaoEtapasTray.TIPO_CADASTRO);
  }, [setActiveStep]);

  const getObterBuscaProduto = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<void, ResponseApi<FormData>>(
      ConstanteEnderecoWebservice.INTEGRACAO_TRAY_BUSCAR_PRODUTO_OBTER
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
        setIsLoading(false);
      }

      if (response.sucesso && response.dados) {
        reset({
          ...response.dados,
          siteStatus:
            response.dados.siteStatus === 0
              ? StatusConsultaEnum.ATIVOS
              : response.dados.siteStatus,
        } as FormData);
      }
    }

    setIsLoading(false);
  }, [setIsLoading, reset]);

  const handleSubmit = onSubmit(async (data) => {
    const dados = {
      siteStatus: data.siteStatus,
    } as DadosProps;

    const { sucesso, infoProduto } = await ModalBuscarProduto({
      dados,
    });

    if (sucesso && infoProduto !== undefined && infoProduto !== null) {
      handleConfirmarExportacao(infoProduto);
    }
  });

  useImperativeHandle(ref, () => ({
    handleAvancar: handleSubmit,
    handleVoltar,
  }));

  useEffect(() => {
    getObterBuscaProduto();
  }, [getObterBuscaProduto]);

  return (
    <>
      <Header
        title="Buscar produtos do site"
        handleVoltar={handleVoltar}
        icon={ImportarProdutosIcon}
      />
      <FormProvider {...formMethods}>
        <VStack
          pl={['10px', '10px', '20px']}
          pr={['10px', '10px', '20px']}
          fontSize="14px"
          w="full"
          alignItems="left"
          spacing="24px"
        >
          <Text
            mb="22px"
            color="primary.50"
            textAlign="left"
            letterSpacing="0px"
          >
            É possível importar apenas os produtos que estão ativos atualmente
            ou todos os produtos cadastrados.
          </Text>
          <Flex alignItems="center" whiteSpace="nowrap" justifyContent="center">
            <Text w="30px" mr="24px">
              Status:
            </Text>
            <Flex width={['full', 'full', '200px']}>
              <SelectPadrao
                id="siteStatus"
                name="siteStatus"
                asControlledByObject={false}
                colSpan={12}
                valueDefault={StatusConsultaEnum.ATIVOS}
                options={StatusConsultaEnum.status}
                placeholder="Selecione o status"
              />
            </Flex>
          </Flex>
        </VStack>
      </FormProvider>
    </>
  );
};
