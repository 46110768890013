export const optionsNfePlNotaFiscal = [
  {
    label: 'PL_009_V4_00_NT_2018_005_v1.20a',
    value: 'PL_009_V4_00_NT_2018_005_v1.20a',
  },
  {
    label: 'PL_009_V4_2016_002_v160b',
    value: 'PL_009_V4_2016_002_v160b',
  },
  {
    label: 'PL_009_V4_00_NT_2019_001_v1.20a',
    value: 'PL_009_V4_00_NT_2019_001_v1.20a',
  },
  {
    label: 'PL_009_V4_00_NT_2020_005_v1.10',
    value: 'PL_009_V4_00_NT_2020_005_v1.10',
  },
  {
    label: 'PL_009c_V4_00_NT_2020_006_v1.20',
    value: 'PL_009c_V4_00_NT_2020_006_v1.20',
  },
  {
    label: 'PL_009k_NT2023_001_v1.20',
    value: 'PL_009k_NT2023_001_v1.20',
  },
];
