import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';

import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';

import { ContainerListagem } from 'components/Layout/Listagem/containerListagem';

import {
  useForm,
  yupResolver,
  FormData,
  formDefaultValue,
} from '../validationForm';

import UncontrolledForm, { UncontrolledFormRefInterface } from '..';

type TParams = { id: string };

const Visualizar = ({ match }: RouteComponentProps<TParams>) => {
  const [isLoading, setIsLoading] = useState(true);
  const [dataHoraCadastro, setDataHoraCadastro] = useState('');
  const [dataHoraUltimaAlteracao, setDataHoraUltimaAlteracao] = useState('');

  const uncontrolledFormRef = useRef<UncontrolledFormRefInterface>();

  const history = useHistory();
  const isMountedRef = useIsMountedRef();

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues: formDefaultValue,
  });

  const { reset, getValues } = formMethods;

  const handleGetTransportadora = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<void, ResponseApi<any>>(
      ConstanteEnderecoWebservice.TRANSPORTADORA_OBTER,
      {
        params: { id: match.params.id },
      }
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response?.sucesso && isMountedRef.current) {
        reset({
          ...response.dados,
        });

        if (uncontrolledFormRef.current) {
          await uncontrolledFormRef.current.setCidade(response.dados.cidadeId);

          if (!getValues('pais')) {
            await uncontrolledFormRef.current.setPais(response.dados.paisId);
          }

          const unformattedCpfCnpj = response.dados.cpfCnpj?.replace(/\D/g, '');
          if (unformattedCpfCnpj?.length <= 11) {
            uncontrolledFormRef.current.setIsCpf(true);
          } else {
            uncontrolledFormRef.current.setIsCpf(false);
          }
        }

        setDataHoraCadastro(response.dados.dataHoraCadastro);
        setDataHoraUltimaAlteracao(response.dados.dataHoraUltimaAlteracao);
      } else {
        history.push(ConstanteRotas.TRANSPORTADORA);
      }
    }

    if (isMountedRef.current) setIsLoading(false);
  }, [getValues, history, isMountedRef, match.params.id, reset]);

  useEffect(() => {
    setIsLoading(true);

    handleGetTransportadora();
  }, [handleGetTransportadora]);

  return (
    <ContainerListagem
      maxWidth="100%"
      formMethods={formMethods}
      isLoading={isLoading}
      dataHoraUltimaAlteracao={dataHoraUltimaAlteracao}
      dataHoraCadastro={dataHoraCadastro}
    >
      <UncontrolledForm ref={uncontrolledFormRef} readonly />
    </ContainerListagem>
  );
};

export default Visualizar;
