import React from 'react';
import { HStack, Icon, Text, Button, useDisclosure } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { FiChevronDown } from 'react-icons/fi';

import { CadastrosVendedoresIcon } from 'icons';
import ModalSelecionarVendedor, {
  VendedorSelecionado,
} from 'components/PDV/Modal/ModalSelecionarVendedor';
import NavHeaderButton from 'components/PDV/Layout/NavHeader/Button';

interface SelecionarVendedorProps {
  name: string;
  onSubmit: (newVendedor: VendedorSelecionado) => void;
  asNavHeaderButton?: boolean;
}

const SelecionarVendedor = ({
  name,
  onSubmit,
  asNavHeaderButton,
}: SelecionarVendedorProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { watch } = useFormContext();

  const vendedor = watch(`${name}` as const);

  return (
    <>
      {asNavHeaderButton ? (
        <NavHeaderButton
          icon={CadastrosVendedoresIcon}
          title={vendedor?.nome || ''}
          titleColor="orange.500"
          colorScheme="orange"
          hasIndicator
          onClick={onOpen}
        />
      ) : (
        <Button variant="ghost" colorScheme="orange" onClick={onOpen}>
          <HStack spacing={2}>
            <Icon as={CadastrosVendedoresIcon} fontSize="2xl" />

            <HStack spacing={1} maxW="190px">
              {vendedor && (
                <Text fontSize="sm" isTruncated>
                  {vendedor.nome}
                </Text>
              )}
              <Icon as={FiChevronDown} />
            </HStack>
          </HStack>
        </Button>
      )}

      <ModalSelecionarVendedor
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={onSubmit}
        getCurrentVendedor={() => {
          const { id: vendedorId = undefined } = vendedor || {};

          return vendedorId;
        }}
        asMobileView={asNavHeaderButton}
      />
    </>
  );
};

export default SelecionarVendedor;
