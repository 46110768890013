import { Flex, Icon, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

import { CategoriaProdutoType } from 'store/CategoriasProduto';
import { useProdutoCategoriasProdutoContext } from 'store/Produtos/ProdutosCategoriasProduto';

import { IoMdArrowDownRight } from 'icons';
import { ContainerCategoriaProduto } from 'components/CategoriaProdutos/Container';
import { CardCategoriaProduto } from 'components/CategoriaProdutos/Card';

interface ItemProps {
  nivel: number;
  item: CategoriaProdutoType;
  selecionavel: boolean;
  children: React.ReactNode;
}

const Item = ({ nivel, item, selecionavel, children }: ItemProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const {
    categoriaSelecionada,
    setCategoriaSelecionada,
  } = useProdutoCategoriasProdutoContext();

  return (
    <>
      <ContainerCategoriaProduto nivel={nivel}>
        <Icon fontSize="md" as={IoMdArrowDownRight} />
        <CardCategoriaProduto
          id="categoria-produto-container"
          nivel={nivel}
          style={{
            border:
              categoriaSelecionada === item.id && selecionavel
                ? 'solid 2px var(--sti-ck-colors-blue-500)'
                : undefined,
            cursor: selecionavel ? 'pointer' : 'default',
          }}
          onClick={
            selecionavel ? () => setCategoriaSelecionada(item.id) : undefined
          }
        >
          <Flex alignItems="center" flexDirection="row">
            {!selecionavel && (
              <Flex
                alignItems="center"
                bg="black"
                color="white"
                justifyContent="center"
                cursor="pointer"
                height="18px"
                width="18px"
                marginRight="6px"
                borderRadius="4px"
                onClick={() => setIsOpen(!isOpen)}
              >
                {isOpen ? (
                  <Icon as={IoIosArrowUp} fontSize="12px" />
                ) : (
                  <Icon as={IoIosArrowDown} fontSize="12px" />
                )}
              </Flex>
            )}

            <Text
              textOverflow="ellipsis"
              overflow="overflow"
              whiteSpace="nowrap"
              id="categoria-produto-nome"
            >
              {item.nome}
            </Text>
          </Flex>
        </CardCategoriaProduto>
      </ContainerCategoriaProduto>

      {isOpen && !selecionavel && children}
    </>
  );
};

export default Item;
