import { useCallback, useEffect, useState } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  ModalFooter,
  Box,
  ModalHeader,
  useMediaQuery,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { ButtonDefault } from 'components/Button/ButtonChakra';

import { yupResolver } from './validationForms';

type FormaRecebimentoProps = {
  id: string;
  nome: string;
  contaFinanceiraId: string;
};

type ListFormaRecebimentoProps = {
  value: string;
  label: string;
  contaFinanceiraId: string;
};

type OptionContaFinanceira = {
  label: string;
  value: string;
  lancarFatura?: boolean;
  padraoSistema: boolean;
};

type FormData = {
  contaFinanceira: string | null;
  formaRecebimento: ListFormaRecebimentoProps;
};

type ContaFinanceiraOptionResponse = {
  id: string;
  nome: string;
  padraoSistema: boolean;
};

type CaixaAberto = {
  id: string;
  nome: string;
};

type ModalFormaRecebimentoProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<FormData> & {
    id: string;
    formaRecebimento: string;
    atualizarPendencia: (id: string) => void;
    siteId: string;
  };

export const ModalFormaRecebimento = create<
  ModalFormaRecebimentoProps,
  FormData
>(
  ({
    onResolve,
    onReject,
    id,
    formaRecebimento,
    siteId,
    atualizarPendencia,
    ...rest
  }) => {
    const [listFormaRecebimento, setListFormaRecebimento] = useState<
      ListFormaRecebimentoProps[]
    >([]);
    const [contasFinanceirasOptions, setContasFinanceirasOptions] = useState<
      OptionContaFinanceira[]
    >([]);
    const [caixaAberto, setCaixaAberto] = useState<CaixaAberto>(
      {} as CaixaAberto
    );
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingContaFinanceira, setIsLoadingContaFinanceira] = useState(
      false
    );
    const [isLoadingFormaRecebimento, setIsLoadingFormaRecebimento] = useState(
      false
    );

    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    const formMethods = useForm<FormData>({
      resolver: yupResolver,
    });

    const { handleSubmit: onSubmit, setValue } = formMethods;

    const handleSubmit = onSubmit(async (data) => {
      setIsLoading(true);
      const response = await api.put<void, ResponseApi<boolean>>(
        ConstanteEnderecoWebservice.INTEGRACAO_TRAY_VINCULAR_FORMA_PAGAMENTO,
        [{ siteId, zendarId: data.formaRecebimento?.value }]
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
          setIsLoading(false);
        }

        if (response.sucesso) {
          atualizarPendencia(id);
          onClose();
          toast.success('A forma de recebimento foi vinculada com sucesso');
          setIsLoading(false);
        }
        setIsLoading(false);
      }
      setIsLoading(false);
    });

    const getFormaPagamentoRecebimentoOption = useCallback(async () => {
      setIsLoadingFormaRecebimento(true);
      const response = await api.get<
        void,
        ResponseApi<FormaRecebimentoProps[]>
      >(ConstanteEnderecoWebservice.FORMA_RECEBIMENTO_LISTAR_SELECT_BAIXA);

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response.sucesso) {
          const data = response.dados.map((formaPagamentoItem) => ({
            value: formaPagamentoItem.id,
            label: formaPagamentoItem.nome,
            contaFinanceiraId: formaPagamentoItem.contaFinanceiraId,
          }));
          setListFormaRecebimento(data);
        }
        setIsLoadingFormaRecebimento(false);
      }
      setIsLoadingFormaRecebimento(false);
    }, []);

    const getObterCaixaAberto = useCallback(async () => {
      const response = await api.get<void, ResponseApi<CaixaAberto>>(
        ConstanteEnderecoWebservice.OBTER_CAIXA_ABERTO_USUARIO_LOGADO
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((avisos) => toast.warning(avisos));
        }
        if (response.sucesso && response.dados) {
          setCaixaAberto(response.dados);
        }
      }
    }, []);

    const getContasFinanceirasOptions = useCallback(async () => {
      setIsLoadingContaFinanceira(true);
      const response = await api.get<
        void,
        ResponseApi<ContaFinanceiraOptionResponse[]>
      >(
        ConstanteEnderecoWebservice.CONTA_FINANCEIRA_LISTAR_CONTAS_FINANCEIRAS_MENOS_CAIXAS
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso: string) => toast.warning(aviso));
        }

        if (response.sucesso && response.dados) {
          const newContasFinanceirasOptions = response.dados.map((item) => ({
            label: item.nome,
            value: item.id,
            padraoSistema: item.padraoSistema,
          }));

          setContasFinanceirasOptions(newContasFinanceirasOptions);
        }
        setIsLoadingContaFinanceira(false);
      }
      setIsLoadingContaFinanceira(false);
    }, []);

    const handleAlterarContaFinanceira = useCallback(
      (valueFormaRecebimento: ListFormaRecebimentoProps) => {
        const { contaFinanceiraId } = valueFormaRecebimento;

        setValue(
          'contaFinanceira',
          contaFinanceiraId === null && caixaAberto?.id
            ? caixaAberto?.id
            : contaFinanceiraId
        );
      },
      [caixaAberto, setValue]
    );

    useEffect(() => {
      getObterCaixaAberto();
      getFormaPagamentoRecebimentoOption();
    }, [getFormaPagamentoRecebimentoOption, getObterCaixaAberto]);

    useEffect(() => {
      getContasFinanceirasOptions();
    }, [getContasFinanceirasOptions]);

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? '3xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
      >
        <ModalContent
          bg="gray.50"
          borderRadius={isLargerThan900 ? 'md' : '0'}
          marginBottom={isLargerThan900 ? '3.75rem' : '0'}
          marginTop={isLargerThan900 ? '3.75rem' : '0'}
          w={isLargerThan900 ? '700px' : 'full'}
          h={isLargerThan900 ? '356px' : 'full'}
        >
          <ModalHeader pt="16px" pb="24px" pl="24px">
            <Text color="primary.50" fontSize="16px">
              Vincular forma de recebimento
            </Text>
          </ModalHeader>

          <FormProvider {...formMethods}>
            {isLoading && <LoadingPadrao />}
            <ModalBody
              px="24px"
              mb={isLargerThan900 ? '20px' : '20px'}
              pt="0"
              overflowY="auto"
              pb="0"
            >
              <Box
                pt="14px"
                pb="14px"
                px="24px"
                borderWidth="1px"
                borderColor="gray.200"
                h="auto"
                minH="64px"
                borderRadius="5px"
                bg="gray.100"
              >
                <Text fontSize="12px" color="gray.500">
                  Forma de recebimento utilizada no site:
                </Text>
                <Text fontWeight="semibold" fontSize="14px" color="black">
                  {formaRecebimento}
                </Text>
              </Box>
              <Flex mt="40px" direction={['column', 'column', 'row']}>
                <Box
                  mr={['0', '0', '24px']}
                  mb={['30px', '24px', '0']}
                  width={['full', 'full', '428px']}
                >
                  <SelectPadrao
                    label="Selecione uma forma de recebimento cadastrada no sistema"
                    id="formaRecebimento"
                    isLoading={isLoadingFormaRecebimento}
                    placeholder="Selecione a forma"
                    name="formaRecebimento"
                    options={listFormaRecebimento}
                    onSelect={(
                      valueFormaRecebimento: ListFormaRecebimentoProps
                    ) => {
                      handleAlterarContaFinanceira(valueFormaRecebimento);
                    }}
                    colSpan={8}
                    asControlledByObject
                  />
                </Box>
                <Box width={['full', 'full', '200px']}>
                  <SelectPadrao
                    label="Conta financeira"
                    isLoading={isLoadingContaFinanceira}
                    id="contaFinanceira"
                    placeholder="Conta financeira"
                    name="contaFinanceira"
                    isDisabled
                    options={[
                      ...contasFinanceirasOptions,
                      ...[{ label: caixaAberto?.nome, value: caixaAberto?.id }],
                    ]}
                  />
                </Box>
              </Flex>
            </ModalBody>
            <ModalFooter flexDirection="column">
              <Flex
                w="full"
                h="full"
                justifyContent="center"
                alignItems="baseline"
              >
                <Button
                  variant="outlineDefault"
                  colorScheme="gray"
                  mr="24px"
                  onClick={() => onClose()}
                  w="140px"
                >
                  Cancelar
                </Button>
                <ButtonDefault
                  variant="solid"
                  colorScheme="secondary.400"
                  width="160px"
                  onClick={() => handleSubmit()}
                  isLoading={isLoading}
                >
                  Confirmar vínculo
                </ButtonDefault>
              </Flex>
            </ModalFooter>
          </FormProvider>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
