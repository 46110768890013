import { Text, Flex, GridItem, Td, Tr, Box, Icon } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { FiChevronLeft } from 'react-icons/fi';

import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { SearchInput } from 'components/update/Input/SearchInput';
import { FilterSelect } from 'components/update/Select/FilterSelect';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import { PagedTable } from 'components/update/Table/PagedTable';
import { ButtonCadastrarNovo } from 'components/Layout/ButtonCadastrarNovo';
import { StatusCircle } from 'components/update/Table/StatusCircle';
import { ContainerHeader } from 'pages/Integracoes/LojaAplicativos/Layout/ContainerHeader';

import useRelatorioPersonalizadoListar, { FiltrosProps } from './hooks';
import { usePainelFrenteCaixa } from '../../hooks';

const RelatorioPersonalizadoListar = () => {
  const { handleVoltarPainelAdm } = usePainelFrenteCaixa();
  const {
    atualizarFiltros,
    obterRelatorioPersonalizadoListaPaginada,
    alterarRelatorioPersonalizado,
    removerRelatorioPersonalizado,
    cadastrarRelatorioPersonalizado,
    relatorioPersonalizadoLista,
    totalRegistros,
    pagedTableRef,
    isLoading,
  } = useRelatorioPersonalizadoListar();

  const formMethods = useForm<FiltrosProps>({
    defaultValues: {
      descricao: '',
      ativo: true,
    },
  });

  const handleAtualizarFiltros = () => {
    const filtersForm = formMethods.getValues();

    atualizarFiltros(filtersForm);
  };

  return (
    <FormProvider {...formMethods}>
      <ContainerHeader bg="#FF005A" gap={2} color="white" alignItems="center">
        <Box cursor="pointer" mr="10px" onClick={handleVoltarPainelAdm}>
          <Icon fontSize="25px" as={FiChevronLeft} />
        </Box>
        <Text fontSize="16px" fontWeight="semibold">
          Relatório personalizado
        </Text>
      </ContainerHeader>
      <Box position="relative" p={6}>
        <SimpleGridForm>
          <GridItem colSpan={12}>
            <Flex
              justifyContent="space-between"
              direction={['column', 'column', 'row']}
              gap={6}
            >
              <Flex flex={2}>
                <SearchInput
                  id="descricao"
                  name="descricao"
                  placeholder="Buscar relatório personalizado pela descrição"
                  onEnterKeyPress={() => handleAtualizarFiltros()}
                />
              </Flex>
              <Flex flex={1}>
                <FilterSelect
                  id="ativo"
                  name="ativo"
                  options={[
                    { label: 'Ativos', value: true },
                    { label: 'Inativos', value: false },
                    { label: 'Todos', value: null },
                  ]}
                  onSelect={() => handleAtualizarFiltros()}
                  isDisabled={isLoading}
                />
              </Flex>
              <Flex justifyContent="flex-end" flex={2}>
                <ButtonCadastrarNovo
                  funcionalidade=""
                  onClick={() => cadastrarRelatorioPersonalizado()}
                />
              </Flex>
            </Flex>
          </GridItem>
          <GridItem colSpan={12}>
            <PagedTable
              ref={pagedTableRef}
              loadColumnsData={obterRelatorioPersonalizadoListaPaginada}
              itemsTotalCount={totalRegistros}
              defaultKeyOrdered="descricao"
              isLoading={isLoading}
              variant="card"
              pb="10px"
              pt={['10px', '10px', '']}
              bg="transparent"
              boxShadow="none"
              paddingRight="2px"
              paddingLeft="2px"
              paginationHasDivider={false}
              tableHeaders={[
                {
                  content: <StatusCircle hasValue={false} />,
                  key: 'Ativo',
                  isOrderable: false,
                  w: '1px',
                  paddingRight: '0',
                },
                {
                  key: 'descricao',
                  content: 'Descrição',
                  w: 'full',
                  isOrderable: true,
                },
                { key: 'acoes', content: 'Ações', w: '1px' },
              ]}
              renderTableRows={relatorioPersonalizadoLista?.map(
                (relatorioPersonalizado) => (
                  <>
                    <Tr
                      key={relatorioPersonalizado?.id}
                      sx={{
                        boxShadow: '0px 0px 4px #00000029',
                        borderRadius: '6px',
                        '& > td': {
                          height: '30px',
                          bg: 'white',
                        },
                      }}
                    >
                      <Td>
                        <StatusCircle
                          isActive={relatorioPersonalizado?.ativo}
                        />
                      </Td>
                      <Td>
                        <Text
                          w="max"
                          cursor="pointer"
                          onClick={() =>
                            alterarRelatorioPersonalizado(
                              relatorioPersonalizado?.id
                            )
                          }
                          _hover={{
                            textDecor: 'underline',
                          }}
                        >
                          {relatorioPersonalizado?.descricao}
                        </Text>
                      </Td>
                      <Td>
                        <ActionsMenu
                          items={[
                            {
                              content: 'Alterar',
                              onClick: () =>
                                alterarRelatorioPersonalizado(
                                  relatorioPersonalizado?.id
                                ),
                            },
                            {
                              content: 'Remover',
                              onClick: () =>
                                removerRelatorioPersonalizado(
                                  relatorioPersonalizado?.id
                                ),
                            },
                          ]}
                        />
                      </Td>
                    </Tr>
                    <Box h="5px" />
                  </>
                )
              )}
            />
            <Flex />
          </GridItem>
        </SimpleGridForm>
      </Box>
    </FormProvider>
  );
};

export default RelatorioPersonalizadoListar;
