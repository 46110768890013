import { Box, Flex, Icon, Text } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import {
  ForwardRefData,
  LoadMoreRowsParams,
} from 'components/update/Table/VirtualizedInfiniteTable';
import { PaginationData } from 'components/update/Pagination';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { ProdutoSelecionadoProps } from 'pages/PDV/TrocarProdutos/Types/validationForm';

import { ModalTrocarProdutosPdv, OptionsClientes } from '../validationForms';
import { BotoesFooterDevolucao } from '../BotoesFooter';
import { RealizarPesquisaDasVendas } from '../RealizarPesquisa';
import { EscolherProdutosParaDevolucaoMobile } from '../ComponentsMobile/escolherProdutosParaDevolucaoMobile';
import { EscolherVendaParaDevolucaoMobile } from '../ComponentsMobile/escolherVendasParaDevolucaoMobile';

type ModalMobileTrocarProdutoProps = {
  getProdutoOptions: (
    inputValue: string,
    dataPagination: PaginationData
  ) => Promise<
    {
      label: string;
      value: string;
    }[]
  >;
  totalRegistros: number;
  getClientes(inputValue: string): Promise<OptionsClientes[]>;
  filtersSubmit: (
    e?: React.BaseSyntheticEvent<any, any> | undefined
  ) => Promise<void>;
  handlePesquisarProduto: () => Promise<void>;
  aparecerVendas: boolean;
  isLoading: boolean;
  setVendas: React.Dispatch<React.SetStateAction<ModalTrocarProdutosPdv[]>>;
  vendaRef: React.RefObject<ForwardRefData>;
  produtoRef: React.RefObject<ForwardRefData>;
  vendas: ModalTrocarProdutosPdv[];
  itemsTotalCount: number;
  loadMoreRowsProduct: ({
    currentPage,
    pageSize,
    orderColumn,
    orderDirection,
  }: LoadMoreRowsParams) => Promise<void>;
  listaProduto: ProdutoSelecionadoProps[];
  setProdutosTroca: React.Dispatch<
    React.SetStateAction<ProdutoSelecionadoProps[]>
  >;
  handleToggleSelecionarProdutoTroca(index: number): void;
  numeroVenda: number;
  handleToggleSelecionarTodosItens(): void;
  setListaProduto: React.Dispatch<
    React.SetStateAction<ProdutoSelecionadoProps[]>
  >;
  selecionarTodosProdutos: boolean;
  handleSubmit: () => void;
  limparForm?: () => void;
  produtosTroca?: ProdutoSelecionadoProps[];
  exibirVendasModal: boolean;
  onClose: () => void;
  setVendaSelecionada: React.Dispatch<React.SetStateAction<boolean>>;
  loadMoreRows: ({
    currentPage,
    pageSize,
    orderColumn,
    orderDirection,
  }: LoadMoreRowsParams) => Promise<void>;
  handleExibirProdutoTroca: (
    descProduto: ModalTrocarProdutosPdv
  ) => Promise<void>;
};

export const MobileModalTrocarProdutos = ({
  getProdutoOptions,
  totalRegistros,
  aparecerVendas,
  vendas,
  setListaProduto,
  loadMoreRows,
  setProdutosTroca,
  itemsTotalCount,
  limparForm,
  loadMoreRowsProduct,
  handleToggleSelecionarProdutoTroca,
  setVendas,
  produtoRef,
  setVendaSelecionada,
  handleSubmit,
  exibirVendasModal,
  produtosTroca,
  onClose,
  numeroVenda,
  listaProduto,
  handleToggleSelecionarTodosItens,
  selecionarTodosProdutos,
  isLoading,
  vendaRef,
  handleExibirProdutoTroca,
  filtersSubmit,
  handlePesquisarProduto,
  getClientes,
}: ModalMobileTrocarProdutoProps) => {
  const [exibirPesquisaVendas, setExibirPesquisaVendas] = useState(false);
  const [exibirProdutosTroca, setExibirProdutosTroca] = useState(false);

  const handlePesquisarVendas = async () => {
    await setVendas([]);
    handlePesquisarProduto();
    setExibirPesquisaVendas(true);
  };

  const handlePesquisarProdutosTroca = useCallback(
    (itens: ModalTrocarProdutosPdv) => {
      handleExibirProdutoTroca(itens);
      setExibirProdutosTroca(true);
    },
    [handleExibirProdutoTroca]
  );

  function handleNaoExistemVendas() {
    setExibirProdutosTroca(false);
    setExibirPesquisaVendas(false);
    setVendaSelecionada(false);
    setProdutosTroca([]);
    setListaProduto([]);
    if (limparForm) {
      limparForm();
    }
  }

  function handleExisteVendas() {
    setExibirProdutosTroca(false);
    setExibirPesquisaVendas(true);
    setVendaSelecionada(false);
    setProdutosTroca([]);
    setListaProduto([]);
    if (limparForm) {
      limparForm();
    }
  }

  function handleAbrirProdutosTrocaMobile() {
    const existemProdutosSelecionados = listaProduto.filter(
      (item) => item.isChecked
    );

    if (existemProdutosSelecionados.length > 0) {
      ModalConfirmacaoExcluir({
        title: 'Você quer escolher outra venda?',
        text: `Se você prosseguir com essa ação, os produtos selecionados serão cancelados`,
        confirmButtonText: 'Sim, continuar!',
        cancelButtonText: 'Cancelar',
        callback: async (ok: boolean) => {
          if (ok) {
            if (vendas.length === 0) {
              handleNaoExistemVendas();
            } else {
              handleExisteVendas();
            }
          }
        },
      });
    } else if (vendas.length === 0) {
      handleNaoExistemVendas();
    } else {
      handleExisteVendas();
    }
  }

  useEffect(() => {
    if (produtosTroca) {
      const atualizarValoresProduto = () => {
        setExibirProdutosTroca(exibirVendasModal);
        setExibirPesquisaVendas(exibirVendasModal);
      };
      atualizarValoresProduto();
    }
  }, [produtosTroca, exibirVendasModal, setListaProduto]);

  return (
    <Flex h="full" w="full">
      {exibirPesquisaVendas ? (
        <>
          {exibirProdutosTroca ? (
            <Box w="full">
              <Box padding="20px" h="calc(100% - 100px)" w="100%">
                <Flex mb="5px" justifyContent="space-between" w="full">
                  <Flex>
                    <Icon
                      fontSize="20px"
                      color="black"
                      cursor="pointer"
                      onClick={() => {
                        handleAbrirProdutosTrocaMobile();
                      }}
                      as={FiChevronLeft}
                    />
                  </Flex>
                  <Flex>
                    <Text fontSize="16px" color="primary.50">
                      Venda nº {numeroVenda}
                    </Text>
                  </Flex>
                </Flex>
                <Box w="100%" mt="15px">
                  <EscolherProdutosParaDevolucaoMobile
                    isLoading={isLoading}
                    produtoRef={produtoRef}
                    listaProduto={listaProduto}
                    itemsTotalCount={itemsTotalCount}
                    loadMoreRowsProduct={loadMoreRowsProduct}
                    handleToggleSelecionarTodosItens={
                      handleToggleSelecionarTodosItens
                    }
                    selecionarTodosProdutos={selecionarTodosProdutos}
                    handleToggleSelecionarProdutoTroca={
                      handleToggleSelecionarProdutoTroca
                    }
                  />
                </Box>
              </Box>

              <BotoesFooterDevolucao
                h="100px"
                mt={isLoading ? '-50px' : '0'}
                handleSubmit={handleSubmit}
                onClose={onClose}
              />
            </Box>
          ) : (
            <Box bg="gray.50" padding="20px" w="100%">
              <Flex mb="5px" justifyContent="space-between" w="full">
                <Flex>
                  <Icon
                    fontSize="20px"
                    color="black"
                    cursor="pointer"
                    onClick={() => setExibirPesquisaVendas(false)}
                    as={FiChevronLeft}
                  />
                </Flex>
                <Text fontSize="16px" color="primary.50">
                  Resultados da pesquisa
                </Text>
              </Flex>
              {aparecerVendas && (
                <EscolherVendaParaDevolucaoMobile
                  isLoading={isLoading}
                  vendaRef={vendaRef}
                  vendas={vendas}
                  handlePesquisarProdutosTroca={handlePesquisarProdutosTroca}
                  itemsTotalCount={itemsTotalCount}
                  loadMoreRows={loadMoreRows}
                  FiChevronRight={FiChevronRight}
                />
              )}
            </Box>
          )}
        </>
      ) : (
        <RealizarPesquisaDasVendas
          filtersSubmit={filtersSubmit}
          getProdutoOptions={getProdutoOptions}
          totalRegistros={totalRegistros}
          getClientes={getClientes}
          handlePesquisarProduto={handlePesquisarVendas}
          onClose={onClose}
          w="100%"
          tamanhoComponente="100%"
        />
      )}
    </Flex>
  );
};
