import React, { useEffect } from 'react';
import { ValueType } from 'react-select';
import { useFormContext } from 'react-hook-form';

import OptionType from 'types/optionType';
import CampoPrototipo, {
  CampoPrototipoProps,
} from 'components/PDV/Geral/CampoPrototipo';

import CreatableSelectInput, { CreatableSelectInputProps } from './Input';

export type ActionMetaProps = {
  action: string;
  name: string;
};

export type CreatableSelectFieldProps = Omit<
  CreatableSelectInputProps,
  'isDisabled' | 'onCreateOption' | 'createdOptions'
> &
  CampoPrototipoProps & {
    handleCreateOption: (
      inputValue: string
    ) => Promise<OptionType | undefined> | void | OptionType;
    onChangeEffect?: (
      option: OptionType | Array<OptionType> | null
    ) => Promise<boolean>;
    colorSelectedValue?: string;
    asControlledByObject?: boolean;
    onActionMeta?: (action: ActionMetaProps) => void;
    onChangeSelect?: (option: OptionType | null | undefined) => void;
  };

const CreatableSelectField = ({
  handleCreateOption,
  name,
  onActionMeta,
  label,
  required = false,
  disabled,
  helperText,
  id,
  isMulti = false,
  onChangeEffect,
  isLoading,
  colSpan,
  colStart,
  colEnd,
  rowSpan,
  rowStart,
  rowEnd,
  components,
  colorSelectedValue,
  options,
  asControlledByObject = false,
  errorPropName,
  errorText,
  defaultValue,
  onChangeSelect,
  ...selectProps
}: CreatableSelectFieldProps) => {
  const { setValue: setValueParent } = useFormContext();

  useEffect(() => {
    if (defaultValue) {
      setValueParent(name, defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, name]);

  return (
    <CampoPrototipo
      name={name}
      isRequired={required}
      isDisabled={disabled}
      helperText={helperText}
      id={id}
      label={label}
      rowSpan={rowSpan}
      rowStart={rowStart}
      rowEnd={rowEnd}
      colSpan={colSpan}
      colStart={colStart}
      colEnd={colEnd}
      errorPropName={errorPropName}
      errorText={errorText}
    >
      {(
        { formState: { isSubmitting }, setValue, clearErrors },
        { value, ...fieldProps }
      ) => (
        <CreatableSelectInput
          selectRef={fieldProps.ref}
          components={components}
          isDisabled={isSubmitting || disabled}
          {...fieldProps}
          {...selectProps}
          options={options}
          id={id}
          isMulti={isMulti}
          onCreateOption={async (inputValue: string) => {
            const createdOption = await handleCreateOption(inputValue);

            if (createdOption) {
              if (isMulti) {
                setValue(`${name}` as const, [
                  ...(value ?? []),
                  asControlledByObject ? createdOption : createdOption.value,
                ]);
              } else {
                setValue(
                  `${name}` as const,
                  asControlledByObject ? createdOption : createdOption.value
                );
              }
            }
          }}
          isLoading={isLoading}
          value={
            // eslint-disable-next-line no-nested-ternary
            asControlledByObject
              ? value
              : options
              ? (options as any[]).filter((selectedOption) => {
                  return Array.isArray(value)
                    ? value.some((v) => v === selectedOption.value)
                    : selectedOption.value === value;
                })
              : null
          }
          onChange={async (
            option: ValueType<OptionType> | null | undefined,
            actionMeta: any
          ) => {
            const onClear = () => {
              setValue(`${name}` as const, null, { shouldDirty: true });
            };

            if (onChangeSelect) {
              onChangeSelect(option as OptionType);
            }

            if (onChangeEffect) {
              if (
                !(await onChangeEffect(
                  option as OptionType | Array<OptionType> | null
                ))
              ) {
                // onClear();
                return;
              }
            }

            if (onActionMeta) onActionMeta(actionMeta);

            if (option) {
              clearErrors(`${name}` as const);
            }

            if (!option) {
              onClear();
              return;
            }

            if (Array.isArray(option) && option.length === 0) {
              onClear();
              return;
            }

            if (Array.isArray(option)) {
              setValue(
                `${name}` as const,
                asControlledByObject ? option : option.map((opt) => opt.value),
                { shouldDirty: true }
              );

              return;
            }

            setValue(
              `${name}` as const,
              asControlledByObject ? option : (option as OptionType).value,
              {
                shouldDirty: true,
              }
            );
          }}
          colorSelectedValue={colorSelectedValue}
        />
      )}
    </CampoPrototipo>
  );
};

export default CreatableSelectField;
