import { useEffect } from 'react';
import {
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  ModalFooter,
  ModalHeader,
  useMediaQuery,
  Divider,
  GridItem,
} from '@chakra-ui/react';
import { create } from 'react-modal-promise';
import { FormProvider } from 'react-hook-form';

import { formatFullDate } from 'helpers/format/formatData';
import TipoValorEnum from 'constants/enum/tipoValor';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { ButtonDefault } from 'components/Button/ButtonChakra';
import { NumberInput } from 'components/update/Input/NumberInput';
import { DateInput } from 'components/update/Input/DateInput';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { Switch } from 'components/update/Switch';

import { FormData, ModalAlterarEmMassaProps } from './types';
import { useAlterarEmMassaModal } from './hooks';

export const ModalAlterarEmMassa = create<ModalAlterarEmMassaProps, FormData>(
  ({
    onResolve,
    onReject,
    totalSelecionado,
    totalBruto,
    totalEmTaxas,
    ...rest
  }) => {
    const { isOpen } = useDisclosure({ defaultIsOpen: true });
    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    const {
      formMethods,
      isLoading,
      tipoTaxa,
      resetToDefaultValues,
      alterarTaxaWatch,
      handleSubmit,
      alterarDataCompensacao,
      handleOnBlurTaxaEmMassa,
      handleToggleDataCompensacao,
      handleToggleTaxa,
      handleAlterarTipoTaxa,
    } = useAlterarEmMassaModal({
      onResolve,
      totalBruto,
      totalSelecionado,
    });
    const defaultDate = formatFullDate(new Date());
    const maxTaxa =
      tipoTaxa === TipoValorEnum.REAIS ? totalBruto / totalSelecionado : 100;

    const totalIsMoreThanOne = totalSelecionado > 1;

    const handleConfirmModal = () => {
      if (!alterarTaxaWatch && !alterarDataCompensacao) {
        onReject();
      } else {
        handleSubmit();
      }
    };

    useEffect(() => {
      if (!isOpen) return;
      resetToDefaultValues();
    }, [isOpen, resetToDefaultValues]);

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size="md"
        {...rest}
        isOpen={isOpen}
        onClose={() => onReject()}
        autoFocus={false}
      >
        <ModalContent
          bg="gray.50"
          borderRadius={isLargerThan900 ? 'md' : '0'}
          marginBottom={isLargerThan900 ? '3.75rem' : '0'}
          marginTop={isLargerThan900 ? '3.75rem' : '0'}
          h={isLargerThan900 ? '420px' : 'full'}
        >
          <ModalHeader pt="16px" pb="28px" pl="24px">
            <Text color="primary.50" fontSize="16px">
              Alterar {totalIsMoreThanOne ? 'em massa' : 'conta'}
            </Text>

            <Divider mt="16px" />
          </ModalHeader>

          <FormProvider {...formMethods}>
            {isLoading && <LoadingPadrao />}
            <ModalBody pl="24px" pr="24px" pt="0" pb="0" height="full">
              <SimpleGridForm>
                <DateInput
                  name="dataCompensacaoEmMassa"
                  id="dataCompensacaoEmMassa"
                  size="md"
                  defaultValue={defaultDate}
                  height="36px"
                  isRequired={false}
                  isDisabled={!alterarDataCompensacao}
                  label="Data de compensação"
                  helperText="A data informada será aplicada para cada conta selecionada"
                  colSpan={{ base: 12, lg: 12 }}
                  actionLinkText={
                    alterarDataCompensacao ? 'Não alterar data' : 'Alterar data'
                  }
                  actionLinkOnClick={handleToggleDataCompensacao}
                />
                <GridItem colSpan={2}>
                  <Switch
                    size="md"
                    label="Taxa"
                    id="alterarTaxa"
                    mt="8px"
                    helperText="A taxa informada será aplicada individualmente para cada conta selecionada"
                    name="alterarTaxa"
                    fontWeight="bold"
                    onChange={handleToggleTaxa}
                  />
                </GridItem>
                <GridItem colSpan={10}>
                  <Flex w="full" justifyContent="flex-end" mb="9px">
                    <Button
                      variant="link"
                      size="xs"
                      boxShadow="none !important"
                      colorScheme="blue"
                      textDecoration="none"
                      _hover={{ textDecoration: 'none' }}
                      lineHeight="8px"
                      justifySelf="flex-end"
                      onClick={handleAlterarTipoTaxa}
                    >
                      {`Alterar usando ${
                        tipoTaxa === TipoValorEnum.PORCENTAGEM
                          ? 'valor'
                          : 'porcentagem'
                      }`}{' '}
                    </Button>
                  </Flex>

                  <NumberInput
                    id="taxaEmMassa"
                    leftElement={tipoTaxa === TipoValorEnum.REAIS ? 'R$' : '%'}
                    leftElementFontSize="xs"
                    editarFundoLeftElemento
                    height="36px"
                    isDisabled={!alterarTaxaWatch}
                    onBlur={handleOnBlurTaxaEmMassa}
                    bgLeftElement="gray.50"
                    scale={tipoTaxa === TipoValorEnum.REAIS ? 2 : 0}
                    max={maxTaxa}
                    name="taxaEmMassa"
                  />
                </GridItem>
                <NumberInput
                  isRequired={false}
                  label="Valor bruto"
                  name="totalBruto"
                  leftElement="R$"
                  leftElementFontSize="xs"
                  disabled
                  height="36px"
                  isReadOnly
                  colSpan={{ base: 12, lg: 6 }}
                />
                <NumberInput
                  label="Valor líquido"
                  bg="secondary.300"
                  name="totalLiquido"
                  border="gray.100"
                  leftElement="R$"
                  disabled
                  isReadOnly
                  leftElementFontSize="xs"
                  height="36px"
                  isRequired={false}
                  colSpan={{ base: 12, lg: 6 }}
                />
              </SimpleGridForm>
            </ModalBody>
            <ModalFooter flexDirection="column" pb="20px">
              <Divider mt="16px" mb="16px" />
              <Flex
                w="full"
                h="full"
                justifyContent="center"
                gap="24px"
                alignItems="baseline"
              >
                <Button
                  color="gray.300"
                  variant="outlineDefault"
                  borderRadius="20px"
                  borderColor="gray.300"
                  fontSize="sm"
                  type="button"
                  height="32px"
                  onClick={() => onReject()}
                  w="96px"
                >
                  Cancelar
                </Button>
                <ButtonDefault
                  color="black"
                  variant="solid"
                  colorScheme="secondary"
                  borderRadius="20px"
                  fontSize="sm"
                  h="32px"
                  width="160px"
                  onClick={handleConfirmModal}
                  isLoading={isLoading}
                >
                  Confirmar alteração
                </ButtonDefault>
              </Flex>
            </ModalFooter>
          </FormProvider>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
