import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export const schema = yup.object().shape({
  telefone: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .test('telefone', ConstanteMensagemValidacao.TELEFONE_INVALIDO, (value) => {
      if (value) {
        const testSchema = yup.string().min(14).max(15);

        return testSchema.isValidSync(value);
      }

      return true;
    }),
});

export const yupResolver = yupResolverInstance(schema);

export const useForm = useFormInstance;
