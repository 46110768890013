import {
  GridItem,
  Flex,
  FormLabel,
  SimpleGrid,
  Box,
  Text,
} from '@chakra-ui/react';

import Select from 'components/PDV/Select/SelectPadrao';
import Input from 'components/PDV/Input';
import { NumberInput } from 'components/update/Input/NumberInput';
import { Switch } from 'components/update/Switch';
import { InputNumber } from 'components/update/Input/InputNumber';
import CreatableMultiSelect from 'components/update/Select/CreatableMultiSelect';
import CheckBoxAtivoInativo from 'components/update/Checkbox/CheckboxAtivoInativo';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';

import { Container } from '../Container';
import { useTabDadosCadastro } from './hooks';

export const TabDadosCadastro = () => {
  const {
    handleAdicionarContaBloqueada,
    utilizarSubConta,
    EnumNumeracaoContas,
    EnumExibicaoContasDepartamento,
  } = useTabDadosCadastro();

  return (
    <SimpleGridForm>
      <GridItem colSpan={[12, 12, 6]}>
        <Input
          name="descricao"
          id="descricao"
          placeholder="Informe a descrição"
          label="Descrição"
        />
      </GridItem>
      <GridItem colSpan={[12, 9, 4]}>
        <NumberInput
          id="valorCouver"
          name="valorCouver"
          label="Valor couvert"
          leftElementColor="gray.700"
          bgLeftElement="gray.50"
          leftElement="R$"
          editarFundoLeftElemento
        />
      </GridItem>
      <GridItem colSpan={[12, 2, 2]}>
        <CheckBoxAtivoInativo
          label="Status"
          id="ativo"
          name="ativo"
          isRequired={false}
        />
      </GridItem>
      <GridItem colSpan={[12, 12, 6]}>
        <Container
          w="full"
          h="auto"
          px={['10px', '32px']}
          bg="gray.50"
          title="Numeração das contas"
        >
          <Select
            id="numeracaoContas"
            name="numeracaoContas"
            required
            placeholder="Selecione"
            options={EnumNumeracaoContas.options}
          />
        </Container>
      </GridItem>
      <GridItem colSpan={[12, 12, 6]}>
        <Container
          w="full"
          h="auto"
          px={['10px', '32px']}
          bg="gray.50"
          title="Exibição"
        >
          <Select
            id="exibicaoContas"
            name="exibicaoContas"
            required
            placeholder="Selecione"
            options={EnumExibicaoContasDepartamento.options}
          />
        </Container>
      </GridItem>
      <GridItem colSpan={[12, 12, 6]}>
        <Container
          h="full"
          w="full"
          px="15px"
          bg="gray.50"
          title="Exibição das contas"
        >
          <Input
            name="descricaoContaPrincipal"
            id="descricaoContaPrincipal"
            placeholder="Informe a descrição"
            label="Descrição da conta"
          />
          <Box mt="20px">
            <Text mb="5px" fontSize="14px" fontWeight="semibold" color="black">
              Intervalo de numeração
            </Text>
            <Flex
              justifyContent="center"
              alignItems={{ base: 'flex-start', sm: 'center' }}
              flexDir={{ base: 'column', sm: 'row' }}
              gap={{ base: 0, sm: '10px' }}
            >
              <Text>De</Text>
              <InputNumber
                id="numeracaoInicialContaPrincipal"
                name="numeracaoInicialContaPrincipal"
                placeholder=" "
                scale={0}
                mb="5px"
              />
              <Text>Até</Text>
              <InputNumber
                id="numeracaoFinalContaPrincipal"
                name="numeracaoFinalContaPrincipal"
                placeholder=" "
                scale={0}
              />
            </Flex>
          </Box>
        </Container>
      </GridItem>
      <GridItem mt={['20px', '20px', '0']} colSpan={[12, 12, 6]}>
        <Container h="full" w="full" px="15px" bg="gray.50" title="Subconta">
          <SimpleGridForm gap={{ base: 3, sm: 6 }}>
            <GridItem colSpan={{ base: 12, md: 4 }}>
              <Flex
                w="full"
                justify="space-between"
                flexDir={{ base: 'row', md: 'column' }}
              >
                <FormLabel whiteSpace="nowrap">Utilizar Subconta</FormLabel>
                <Box>
                  <Switch
                    id="utilizarSubConta"
                    name="utilizarSubConta"
                    size="md"
                  />
                </Box>
              </Flex>
            </GridItem>
            <GridItem colSpan={{ base: 12, md: 8 }}>
              <Input
                id="descricaoSubConta"
                name="descricaoSubConta"
                isDisabled={!utilizarSubConta}
                placeholder="Informe a descrição"
                label="Descrição da Subconta"
              />
            </GridItem>
            <GridItem colSpan={12}>
              <Text
                mb="5px"
                fontSize="14px"
                fontWeight="semibold"
                color="black"
              >
                Intervalo de numeração
              </Text>
              <Flex
                justifyContent="center"
                alignItems={{ base: 'flex-start', sm: 'center' }}
                flexDir={{ base: 'column', sm: 'row' }}
                gap={{ base: 0, sm: '10px' }}
              >
                <Text>De</Text>
                <InputNumber
                  id="numeracaoFinalSubConta"
                  name="numeracaoInicialSubConta"
                  placeholder=" "
                  scale={0}
                  mb="5px"
                  isDisabled={!utilizarSubConta}
                />
                <Text>Até</Text>
                <InputNumber
                  name="numeracaoFinalSubConta"
                  id="numeracaoFinalSubConta"
                  placeholder=" "
                  scale={0}
                  isDisabled={!utilizarSubConta}
                />
              </Flex>
            </GridItem>
          </SimpleGridForm>
        </Container>
      </GridItem>
      <GridItem mt={['20px', '20px']} colSpan={[12, 12, 12]}>
        <Container
          w="full"
          h="full"
          px="15px"
          bg="gray.50"
          title="Contas bloqueadas"
          overflow="visible"
        >
          <CreatableMultiSelect
            id="contasBloqueadas"
            name="contasBloqueadas"
            creatableInputTextPreffix="Cadastrar conta"
            createValue={handleAdicionarContaBloqueada}
            noOptionsMessage={() => 'Digite o número da conta'}
            optionForegroundColor="#000"
            optionBackgroundColor="#ccc"
            placeholder="Digite a conta"
            closeMenuOnSelect={false}
            maxLength={30}
            controlledByObject
            options={[]}
            isMulti
          />
        </Container>
      </GridItem>
    </SimpleGridForm>
  );
};
