import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldErrors, useFormContext, UseFormRegister } from 'react-hook-form';

import { SalvarInserirNovoIcon, SalvarConfirmarIcon } from 'icons';
import ButtonPadrao from 'components/Button/ButtonPadrao';
import { Col, Form, Modal } from 'react-bootstrap';
import InputNumerico from 'components/Input/InputNumerico';
import InputPadrao from 'components/Input/InputPadrao';
import { BootstrapNumberInput } from 'components/update/Input/BootstrapNumberInput';
import SelectPadrao from 'components/Select/SelectPadrao';
import { OptionTypeBase } from 'react-select';
import ModalPadrao from 'components/Modal/ModalPadrao';
import { setFocusFieldError } from 'helpers/validation/focusFieldError';
import { useForm, yupResolver } from './validationForm';

interface UncontrolledFormInterface {
  errors: FieldErrors;
  register: UseFormRegister<Record<string, any>>;
  control: any;
  show: boolean;
  getValues: any;
  setValue: any;
  onHide: () => void;
}

const DocumentoFiscalModal: React.FC<UncontrolledFormInterface> = ({
  getValues: parentGetValues,
  setValue: parentSetValue,
  show,
  onHide,
}) => {
  const { t } = useTranslation();

  const { getValues: principalParentGetValues } = useFormContext();

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    reset,
    setFocus,
    setValue,
  } = useForm({
    resolver: yupResolver,
    shouldFocusError: false,
    shouldUnregister: true,
  });

  const [documentoImportacaoSelect, setDocumentoImportacaoSelect] = useState<
    OptionTypeBase[]
  >([]);

  useEffect(() => {
    const documentoFiscalImportacoes = principalParentGetValues(
      'documentoFiscalImportacoes'
    );

    setDocumentoImportacaoSelect(
      documentoFiscalImportacoes.map((item: any) => {
        return {
          label: `DI: ${item.numeroDocumento} | Código do Exportador: ${
            item.codigoExportador
          } | Data de Registro: ${new Date(
            item.dataRegistro
          ).toLocaleDateString()}`,
          value: item.numeroDocumento,
        };
      })
    );

    setValue(
      'documentoImportacao',
      documentoFiscalImportacoes[0]?.numeroDocumento
    );
  }, [principalParentGetValues]);

  const handleAddItem = (data: any) => {
    const itemsAdicionados = parentGetValues('adicoes') || [];

    parentSetValue('adicoes', [...itemsAdicionados, { ...data }]);

    return true;
  };

  const setFocusHandle = (errorList: any) => {
    setFocusFieldError(
      [
        'documentoImportacao',
        'numeroAdicao',
        'itemAdicao',
        'codigoFabricante',
        'descontoAdicao',
      ],
      errorList
    );
  };

  const onSubmitReset = handleSubmit(async (data) => {
    const success = await handleAddItem(data);

    if (success) {
      reset({});
    }
  }, setFocusHandle);

  const onSubmit = handleSubmit(async (data) => {
    const success = await handleAddItem(data);

    if (success) {
      onHide();
    }
  }, setFocusHandle);

  return (
    <>
      {show && (
        <ModalPadrao
          isOpen={show}
          handleOnHide={onHide}
          title={t('Adicionar documento fiscal de importação')}
          subtitle={t('Informe os dados de documento fiscal da importação.')}
          onEntered={() => setFocus('documentoImportacao')}
          size="lg"
          minWidth="40%"
          style={{ zIndex: 1050 }}
        >
          <Form>
            <Modal.Body>
              <Form.Row>
                <Col md={12} xl={8}>
                  <SelectPadrao
                    id="documentoImportacao"
                    name="documentoImportacao"
                    label={t('Documento de importação')}
                    noSelectedText={t('Clique aqui para selecionar.')}
                    defaultValue={null}
                    control={control}
                    error={errors.documentoImportacao}
                    options={documentoImportacaoSelect}
                    readonly={false}
                    required
                    autoFocus
                  />
                </Col>
                <Col md={3} xl={4}>
                  <InputNumerico
                    type="text"
                    id="numeroAdicao"
                    name="numeroAdicao"
                    label={t('Número adição')}
                    required
                    maxLength={3}
                    error={errors.numeroAdicao}
                    control={control}
                  />
                </Col>
                <Col md={3} xl={4}>
                  <InputNumerico
                    id="itemAdicao"
                    name="itemAdicao"
                    label={t('Item adição')}
                    required
                    maxLength={3}
                    error={errors.itemAdicao}
                    disabled={false}
                    control={control}
                  />
                </Col>
                <Col md={6} xl={4}>
                  <InputPadrao
                    type="text"
                    id="codigoFabricante"
                    label={t('Código do fabricante')}
                    required
                    maxLength={60}
                    error={errors.codigoFabricante}
                    control={control}
                  />
                </Col>
                <Col md={4} xl={4}>
                  <BootstrapNumberInput
                    id="descontoAdicao"
                    name="descontoAdicao"
                    label={t('Desconto adição')}
                    precision={12}
                    scale={2}
                    defaultValue={0}
                    leftElement="R$"
                    control={control}
                    error={errors.descontoAdicao}
                  />
                </Col>
              </Form.Row>
            </Modal.Body>

            <Modal.Footer>
              <ButtonPadrao
                id="adicionarInserirNovo"
                type="button"
                variant="outline-secondary"
                name="salvarInserirNovo"
                text={t('Adicionar e inserir novo')}
                icon={SalvarInserirNovoIcon}
                disabled={false}
                onClick={onSubmitReset}
                style={{ height: '35px', width: '100%', maxWidth: '225px' }}
              />
              <ButtonPadrao
                id="adicionarDocumentoReferenciado"
                name="adicionarDocumentoReferenciado"
                text={t('Adicionar')}
                icon={SalvarConfirmarIcon}
                type="button"
                variant="success"
                onClick={onSubmit}
                style={{ height: '35px', width: '100%', maxWidth: '120px' }}
              />
            </Modal.Footer>
          </Form>
        </ModalPadrao>
      )}
    </>
  );
};

export default DocumentoFiscalModal;
