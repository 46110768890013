import styled from 'styled-components';
import { Container as ContainerImportado } from 'react-bootstrap';
import ButtonComOpcoes from 'components/Button/ButtonComOpcoes';

export const Container = styled(ContainerImportado)`
  min-width: 100%;
  padding: 30px;
`;

export const ButtonComOpcoesContato = styled(ButtonComOpcoes)`
  @media (max-width: 700px) {
    padding-top: 15px !important;
  }
`;

export const ColContato = styled.div`
  background-color: var(--sti-ck-colors-gray-50);
  padding: 20px;
  border: 1px solid var(--sti-ck-colors-gray-100);
  border-radius: 5px;
`;

export const SpanNome = styled.div`
  color: var(--black);
  font-weight: 600;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  display: flex;
  margin-bottom: 5px;
`;

export const SpanEmail = styled.div`
  color: var(--sti-ck-colors-gray-300);
  font-family: 'Nunito', sans-serif;
  font-size: 12px;
`;
