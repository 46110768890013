export const EnumNumeracaoContas = {
  MANUAL: 1,
  AUTOMATICA: 2,

  options: [
    {
      label:
        'Utilizar o número da conta selecionada na tela ou o número informado pelo usuário',
      value: 1,
    },
    {
      label: 'Utilizar o número da conta automático',
      value: 2,
    },
  ],
};
