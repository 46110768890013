import React from 'react';
import { Box, FlexProps } from '@chakra-ui/react';

interface ContainerProps extends FlexProps {
  children: React.ReactNode;
}

export const Container = ({
  children,
  bg = 'gray.50',
  pl = '2%',
  pr = '2%',
  pt = '40px',
  ...rest
}: ContainerProps) => {
  return (
    <Box
      bg={bg}
      boxShadow="0px 0px 6px #00000034"
      borderRadius="5px"
      pl={pl}
      pr={pr}
      pt={pt}
      w="full"
      h="auto"
      pb="60px"
      ml="2px"
      {...rest}
    >
      {children}
    </Box>
  );
};
