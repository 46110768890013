import { Text, Box, Flex, useMediaQuery, Divider } from '@chakra-ui/react';

import { moneyMask } from 'helpers/format/fieldsMasks';

import { ComissaoDosVendedoresProps } from 'pages/Vendas/MetasComissoes/Types/validationsForm';
import { NumberInput } from 'components/update/Input/NumberInput';

type ExibirMetasFixasProps = {
  comissaoVendedor: ComissaoDosVendedoresProps;
};

export const ExibirMetasFixas = ({
  comissaoVendedor,
}: ExibirMetasFixasProps) => {
  const [isLargerThan1200] = useMediaQuery('(min-width: 1200px)');
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  return (
    <>
      {!isLargerThan900 && <Divider w="full" mb="20px" />}
      <Flex ml={isLargerThan1200 ? '20px' : ''} mb="20px" color="pink.600">
        <Text fontWeight="bold" fontSize="16px" mr="10px">
          Meta mensal:
        </Text>
        <Text fontWeight="bold" fontSize="16px">
          {moneyMask(comissaoVendedor.valorMeta, true)}
        </Text>
      </Flex>
      <Divider w="full" />
      <Flex
        alignItems="center"
        justifyContent="left"
        ml={isLargerThan1200 ? '20px' : ''}
        mt="15px"
        mb="15px"
        w="100%"
        color="gray.700"
      >
        <Text fontSize="16px" mr="10px">
          Comissão padrão:
        </Text>
        <Box>
          <NumberInput
            widhtPorcentagem="20"
            w="full"
            h="40px"
            id={`comissaoPadraoVendedores.${comissaoVendedor.vendedorId}`}
            rightElementPorcentagem="%"
            name={`comissaoPadraoVendedores.${comissaoVendedor.vendedorId}`}
            scale={2}
          />
        </Box>
      </Flex>
      <Divider w="full" />

      <Flex
        alignItems={isLargerThan900 ? 'center' : 'left'}
        direction={isLargerThan900 ? 'row' : 'column'}
        justifyContent="left"
        ml={isLargerThan1200 ? '20px' : ''}
        mt="15px"
        w="full"
        mb="15px"
        color="gray.700"
      >
        <Flex
          alignItems="center"
          justifyContent={isLargerThan900 ? 'center' : 'left'}
        >
          <Text mr="10px" fontSize="16px">
            Quando alcançar:
          </Text>

          <Box mr="10px" w="100px">
            <NumberInput
              id="quandoAlcancarTotal"
              rightElementPorcentagem="%"
              name="quandoAlcancarTotal"
              scale={0}
              isDisabled
              defaultValue={100}
            />
          </Box>
        </Flex>
        <Flex
          alignItems="center"
          justifyContent={isLargerThan900 ? 'center' : 'left'}
        >
          <Text mr="10px" fontSize="16px">
            <strong>{moneyMask(comissaoVendedor.valorMeta, true)}</strong>, o
            percentual da comissão será de
          </Text>
          <Box w="100px">
            <NumberInput
              widhtPorcentagem="20"
              h="40px"
              w="full"
              isDisabled={comissaoVendedor.valorMeta === 0}
              id={`percentualComissaoTotal.${comissaoVendedor.vendedorId}`}
              rightElementPorcentagem="%"
              name={`percentualComissaoTotal.${comissaoVendedor.vendedorId}`}
              scale={2}
            />
          </Box>
        </Flex>
      </Flex>
      <Divider w="full" />
    </>
  );
};
