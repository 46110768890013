import React from 'react';

import { Route } from 'react-router-dom';
import ClienteFornecedorFiltroProvider from 'store/ClienteFornecedor/ClienteFornecedorFiltros';
import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

import FornecedoresListar from '../pages/ClientesFornecedores/Listar';
import FornecedoresCadastrar from '../pages/ClientesFornecedores/Formulario/Cadastrar';
import FornecedoresAlterar from '../pages/ClientesFornecedores/Formulario/Alterar';
import FornecedoresVisualizar from '../pages/ClientesFornecedores/Formulario/Visualizar';

const FornecedoresRoutes = [
  <Route path={ConstanteRotas.FORNECEDOR}>
    <ClienteFornecedorFiltroProvider>
      <LayoutGuard
        key="fornecedores-listar"
        permissaoFuncionalidade={ConstanteFuncionalidades.FORNECEDOR_LISTAR}
        component={FornecedoresListar}
        breadcrumb={[
          {
            title: 'Cadastros',
          },
          {
            title: 'Fornecedores',
          },
        ]}
        path={ConstanteRotas.FORNECEDOR}
        exact
        meta={{ auth: true }}
      />{' '}
    </ClienteFornecedorFiltroProvider>
  </Route>,
  <LayoutGuard
    key="fornecedores-cadastrar"
    permissaoFuncionalidade={ConstanteFuncionalidades.FORNECEDOR_CADASTRAR}
    component={FornecedoresCadastrar}
    breadcrumb={[
      {
        title: 'Cadastros',
      },
      {
        title: 'Fornecedores',
        path: ConstanteRotas.FORNECEDOR,
      },
      {
        title: 'Novo',
      },
    ]}
    path={ConstanteRotas.FORNECEDOR_CADASTRAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="fornecedores-alterar"
    permissaoFuncionalidade={ConstanteFuncionalidades.FORNECEDOR_ALTERAR}
    component={FornecedoresAlterar}
    breadcrumb={[
      {
        title: 'Cadastros',
      },
      {
        title: 'Fornecedores',
        path: ConstanteRotas.FORNECEDOR,
      },
      {
        title: 'Editar',
      },
    ]}
    path={ConstanteRotas.FORNECEDOR_ALTERAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="fornecedores-visualizar"
    permissaoFuncionalidade={ConstanteFuncionalidades.FORNECEDOR_VISUALIZAR}
    component={FornecedoresVisualizar}
    breadcrumb={[
      {
        title: 'Cadastros',
      },
      {
        title: 'Fornecedores',
        path: ConstanteRotas.FORNECEDOR,
      },
      {
        title: 'Visualizar',
      },
    ]}
    path={ConstanteRotas.FORNECEDOR_VISUALIZAR}
    exact
    meta={{ auth: true }}
  />,
];

export default FornecedoresRoutes;
