import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import { MetaGeralProps } from 'pages/Vendas/MetasComissoes/Types/validationsForm';

export const obterMetaGeral = async (mesAno?: string) => {
  const response = await api.get<void, ResponseApi<MetaGeralProps>>(
    ConstanteEnderecoWebservice.OBTER_VALOR_META_GERAL,
    {
      params: {
        periodo: mesAno,
      },
    }
  );

  if (response) {
    if (response.avisos) {
      response.avisos.forEach((aviso) => toast.warning(aviso));
    }

    if (response.sucesso && response.dados) {
      return response.dados;
    }
  }
  return {} as MetaGeralProps;
};
