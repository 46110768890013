/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {
  useMediaQuery,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Flex,
  Heading,
} from '@chakra-ui/react';

import ModalPadraoChakra from '../../PDV/Modal/ModalPadraoChakra';

interface ModalAuxiliarImportacaoProdutosProps {
  isOpen: boolean;
  onClose: () => void;
}

const ModalAuxiliarImportacaoProdutos = ({
  isOpen,
  onClose,
}: ModalAuxiliarImportacaoProdutosProps) => {
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const emptyField = '---';

  return (
    <ModalPadraoChakra
      isOpen={isOpen}
      onClose={onClose}
      size={isLargerThan900 ? 'xl' : 'full'}
      scrollBehavior={isLargerThan900 ? 'inside' : 'outside'}
      isCentered={isLargerThan900}
    >
      <ModalContent
        margin={0}
        maxW={isLargerThan900 ? 1200 : undefined}
        borderRadius={isLargerThan900 ? 'md' : 0}
        bg="gray.50"
        h="unset"
      >
        <ModalHeader
          color="primary.500"
          mt={isLargerThan900 ? undefined : 12}
          mb={isLargerThan900 ? undefined : 8}
        >
          Ajuda
        </ModalHeader>
        <ModalCloseButton
          mt={isLargerThan900 ? undefined : 14}
          mr={isLargerThan900 ? undefined : 6}
        />
        <ModalBody pb={6}>
          <Heading as="h2" fontSize="md" mb={5}>
            Ajuda na importação de produtos
          </Heading>
          <Flex maxW="100%" overflowX={isLargerThan900 ? 'unset' : 'scroll'}>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th width="200px">Campo</Th>
                  <Th>Tipo</Th>
                  <Th>Tamanho</Th>
                  <Th width="150px">Conteúdo Aceito</Th>
                  <Th>Obrigatório</Th>
                  <Th>Observação</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Nome</Td>
                  <Td>alfanumérico</Td>
                  <Td>120</Td>
                  <Td>{emptyField}</Td>
                  <Td>Sim</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Tipo Produto</Td>
                  <Td>numérico</Td>
                  <Td>1, 2, 3</Td>
                  <Td>Produto_simples, Produto_variacao, Produto_kit</Td>
                  <Td>{emptyField}</Td>
                  <Td>
                    Produto_simples = 1, Produto_variacao = 2, Produto_kit = 3
                  </Td>
                </Tr>
                <Tr>
                  <Td>Referência</Td>
                  <Td>alfanumérico</Td>
                  <Td>60</Td>
                  <Td>{emptyField}</Td>
                  <Td>Sim</Td>
                  <Td>
                    É o código de identificação do PRODUTO no sistema anterior
                  </Td>
                </Tr>
                <Tr>
                  <Td>Cor</Td>
                  <Td>alfanumérico</Td>
                  <Td>50</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Tamanho</Td>
                  <Td>alfanumérico</Td>
                  <Td>15</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Nome abreviado</Td>
                  <Td>alfanumérico</Td>
                  <Td>50</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>GTIN/EAN</Td>
                  <Td>alfanumérico</Td>
                  <Td>14</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Categoria nível 1</Td>
                  <Td>alfanumérico</Td>
                  <Td>40</Td>
                  <Td>{emptyField}</Td>
                  <Td>Sim</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Categoria nível 2</Td>
                  <Td>alfanumérico</Td>
                  <Td>40</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Categoria nível 3</Td>
                  <Td>alfanumérico</Td>
                  <Td>40</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Categoria nível 4</Td>
                  <Td>alfanumérico</Td>
                  <Td>40</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Marca</Td>
                  <Td>alfanumérico</Td>
                  <Td>40</Td>
                  <Td>{emptyField}</Td>
                  <Td>Sim</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Unidade de Medida</Td>
                  <Td>alfanumérico</Td>
                  <Td>6</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>Se não for informado, a unidade ficará como UN</Td>
                </Tr>
                <Tr>
                  <Td>Estoque</Td>
                  <Td>numérico</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Estoque mínimo</Td>
                  <Td>numérico</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Preço de compra</Td>
                  <Td>numérico</Td>
                  <Td>14,4</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Preço de custo</Td>
                  <Td>numérico</Td>
                  <Td>14,4</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Markup</Td>
                  <Td>numérico</Td>
                  <Td>14,4</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Preço de venda</Td>
                  <Td>numérico</Td>
                  <Td>14,4</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Tags</Td>
                  <Td>alfanumérico</Td>
                  <Td>{emptyField}</Td>
                  <Td>Concatenar com ,</Td>
                  <Td>{emptyField}</Td>
                  <Td>Ex: fitness, adidas, ...</Td>
                </Tr>
                <Tr>
                  <Td>Altura</Td>
                  <Td>numérico</Td>
                  <Td>7,2</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Largura</Td>
                  <Td>numérico</Td>
                  <Td>7,2</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Profundidade</Td>
                  <Td>numérico</Td>
                  <Td>7,2</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Peso líquido</Td>
                  <Td>numérico</Td>
                  <Td>12,3</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Peso bruto</Td>
                  <Td>numérico</Td>
                  <Td>12,3</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Código externo</Td>
                  <Td>alfanumérico</Td>
                  <Td>60</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Controla estoque</Td>
                  <Td>alfanumérico</Td>
                  <Td>{emptyField}</Td>
                  <Td>Sim, Não, S, N, 1 e 0</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Permite alterar valor na venda</Td>
                  <Td>alfanumérico</Td>
                  <Td>{emptyField}</Td>
                  <Td>Sim, Não, S, N, 1 e 0</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Regra fiscal</Td>
                  <Td>alfanumérico</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>Não</Td>
                  <Td>
                    Preencher com a descrição igual está no cadastro da regra
                    fiscal
                  </Td>
                </Tr>
                <Tr>
                  <Td>Tipo produto fiscal</Td>
                  <Td>numérico</Td>
                  <Td>0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 16 e 99</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>
                    Mercadoria_para_revenda = 0, Materia_Prima = 1, Embalagem =
                    2, Produto_em_Processo = 3, Produto_Acabado = 4, Subproduto
                    = 5, Produto_Intermediario = 6, Material_de_Uso_e_Consumo =
                    7, Ativo_Imobilizado = 8, Servicos = 9, Outros_insumos = 10,
                    Outras = 99, Garrafa = 16
                  </Td>
                </Tr>
                <Tr>
                  <Td>Origem da mercadoria</Td>
                  <Td>numérico</Td>
                  <Td>0, 1, 2, 3, 4, 5, 6, 7 e 8</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>
                    NACIONAL_EXCETO_CODIGOS_3_4_5_8 = 0,
                    ESTRANGEIRA_IMPORTACAO_DIRETA = 1,
                    ESTRANGEIRA_MERCADO_INTERNO = 2,
                    NACIONAL_IMPORTACAO_SUPERIOR_40_PORCENTO = 3,
                    NACIONAL_PRODUCAO_CONFORME_PROCESSOS = 4,
                    NACIONAL_IMPORTACAO_INFERIOR_40_PORCENTO = 5,
                    ESTRANGEIRA_IMPORTACAO_DIRETA_SEM_SIMILAR_NACIONAL = 6,
                    ESTRANGEIRA_MERCADO_INTERNO_SEM_SIMILAR_NACIONAL = 7,
                    NACIONAL_IMPORTACAO_SUPERIOR_70_PORCENTO = 8
                  </Td>
                </Tr>
                <Tr>
                  <Td>NCM</Td>
                  <Td>numérico</Td>
                  <Td>10</Td>
                  <Td>0000.00.00 ou 00000000</Td>
                  <Td>Sim</Td>
                  <Td>
                    Se o produto não tiver NCM, informar 0000.00.00 na planilha
                  </Td>
                </Tr>
                <Tr>
                  <Td>Cest</Td>
                  <Td>numérico</Td>
                  <Td>9</Td>
                  <Td>00.000.00 ou 0000000</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Aliquota ICMS</Td>
                  <Td>numérico</Td>
                  <Td>5,2</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Redução da base de cálculo ICMS</Td>
                  <Td>numérico</Td>
                  <Td>5,2</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Código benefício fiscal</Td>
                  <Td>alfanumérico</Td>
                  <Td>10</Td>
                  <Td>Precisa ter 8 ou 10 caracteres</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Fundo de combate a pobreza - FCP</Td>
                  <Td>numérico</Td>
                  <Td>5,2</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Valor BC - ST Retido</Td>
                  <Td>numérico</Td>
                  <Td>13,2</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Valor ICMS - ST Retido</Td>
                  <Td>numérico</Td>
                  <Td>13,2</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Valor BC - FCP - ST Retido</Td>
                  <Td>numérico</Td>
                  <Td>13,2</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Valor ICMS - FCP - ST Retido</Td>
                  <Td>numérico</Td>
                  <Td>13,2</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Aliquota PIS</Td>
                  <Td>numérico</Td>
                  <Td>5,2</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Aliquota COFINS</Td>
                  <Td>numérico</Td>
                  <Td>5,2</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Unidade de medida tributável</Td>
                  <Td>alfanumérico</Td>
                  <Td>6</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>Se não for informado, a unidade ficará como UN</Td>
                </Tr>
                <Tr>
                  <Td>Fator de conversão</Td>
                  <Td>alfanumérico</Td>
                  <Td>1</Td>
                  <Td>* ou /</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Quantidade conversão</Td>
                  <Td>numérico</Td>
                  <Td>9,4</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Indicador escala relevante</Td>
                  <Td>numérico</Td>
                  <Td>0, 1 e 2</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>NENHUM = 0, SIM = 1, NAO = 2</Td>
                </Tr>
                <Tr>
                  <Td>Cnpj do fabricante</Td>
                  <Td>numérico</Td>
                  <Td>18</Td>
                  <Td>Números com e sem máscara</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Código ANP</Td>
                  <Td>alfanumérico</Td>
                  <Td>9</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Codif</Td>
                  <Td>alfanumérico</Td>
                  <Td>21</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Percentual de GLP</Td>
                  <Td>numérico</Td>
                  <Td>7,4</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Percentual de GLCNn</Td>
                  <Td>numérico</Td>
                  <Td>7,4</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Percentual de GLCNi</Td>
                  <Td>numérico</Td>
                  <Td>7,4</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Valor de Partida GLP</Td>
                  <Td>numérico</Td>
                  <Td>7,4</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Data hora cadastro</Td>
                  <Td>alfanumérico</Td>
                  <Td>19</Td>
                  <Td>00/00/0000 00:00:00</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Ativo</Td>
                  <Td>alfanumérico</Td>
                  <Td>{emptyField}</Td>
                  <Td>Sim, Não, S, N, 1 e 0</Td>
                  <Td>{emptyField}</Td>
                  <Td>Se não for informado, o cadastro ficará ativo</Td>
                </Tr>
              </Tbody>
            </Table>
          </Flex>
        </ModalBody>
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export default ModalAuxiliarImportacaoProdutos;
