import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Icon,
  Flex,
  useMediaQuery,
  useDisclosure,
  ModalHeader,
  Text,
  ModalFooter,
  HStack,
  Button,
  GridItem,
  IconButton,
  Collapse,
  ModalCloseButton,
  Box,
  Thead,
  Tr,
  Table,
  Th,
  Td,
  Divider,
  Tbody,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import auth from 'modules/auth';
import ConstanteFuncionalidades from 'constants/permissoes';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import TipoProdutoEnum from 'constants/enum/tipoProduto';
import { DecimalMask } from 'helpers/format/fieldsMasks';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';

import { GradeTamanhosIcon, SalvarInserirNovoIcon } from 'icons';
import CreatableSelectVirtualized from 'components/PDV/Select/CreatableSelectVirtualized';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { NumberInput } from 'components/update/Input/NumberInput';
import { ModalGradeTamanhos } from 'components/update/Modal/ModalGradeTamanhos';
import { SimpleCard } from 'components/update/Form/SimpleCard';
import CreatableSelect from 'components/PDV/Select/CreatableSelect';
import CampoContainer from 'components/PDV/Geral/CampoContainer';
import { ModalAtencao } from 'components/Modal/ModalAtencao';
import { PaginationData } from 'components/update/Pagination';
import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import { VariacoesCoresProps } from 'pages/Produtos/Formulario/FunctionsCadastroProduto/ObterCoresCadastradas';

import OptionType from 'types/optionType';
import { ModalCadastrarProduto } from '../../../EtapasCompartilhadas/ModalCadastrarProduto';
import { ModalCadastrarCor } from '../../../EtapasCompartilhadas/ModalCadastrarCor';
import { ModalCadastrarTamanho } from '../../../EtapasCompartilhadas/ModalCadastrarTamanho';
import {
  yupResolver,
  FormData,
  ListaProdutoCorProps,
  TamanhoQuantidade,
  TamanhoProps,
  ProdutoOptionProps,
} from './validationForm';

type ListaIdDescricao = {
  id: string;
  descricao: string;
};

export type ProdutoTamanhoProps = {
  ativo: true;
  dataHoraCadastro: string;
  dataHoraUltimaAlteracao: string;
  descricao: string;
  id: string;
  padraoSistema: boolean;
  sequenciaOrdenacao: number;
};

export type ProdutoResponse = {
  produtoId: string;
  nomeProduto: string;
  corId?: string;
  corDescricao?: string;
  listaTamanhoIdQuantidade: {
    id: string;
    quantidade: number;
    descricaoTamanho: string;
  }[];
  valorUnitarioEntrada: number;
  valorIpi: number;
  valorIcmsSt: number;
  valorFcpSt: number;
  custoAdicional: number;
  volumeUnitario: boolean;
};

type Lojas = {
  lojaId: string;
  precoCompra: string;
};

type ModalAdicionarProdutoResponse = {
  deveReiniciar?: boolean;
  produto: ProdutoResponse;
};

type ModalAdicionarProdutoProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalAdicionarProdutoResponse> & {
    casasDecimaisQuantidade: number;
    casasDecimaisValor: number;
    entradaRateiaIcmsSt: boolean;
    adicionarProduto: (newProduto: ProdutoResponse) => void;
  };

export const ModalAdicionarProduto = create<
  ModalAdicionarProdutoProps,
  ModalAdicionarProdutoResponse
>(
  ({
    onResolve,
    onReject,
    casasDecimaisQuantidade,
    casasDecimaisValor,
    entradaRateiaIcmsSt,
    adicionarProduto,
    ...rest
  }) => {
    const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const { permitido: temPermissaoCadastrarProduto } = auth.possuiPermissao(
      ConstanteFuncionalidades.PRODUTO_CADASTRAR
    );
    const { permitido: temPermissaoCadastrarCor } = auth.possuiPermissao(
      ConstanteFuncionalidades.COR_CADASTRAR
    );
    const { permitido: temPermissaoCadastrarTamanho } = auth.possuiPermissao(
      ConstanteFuncionalidades.TAMANHO_CADASTRAR
    );

    const formMethods = useForm<FormData>({
      resolver: yupResolver,
      defaultValues: {
        cor: null,
      },
    });

    const { watch, setValue } = formMethods;

    const [isLoading, setIsLoading] = useState(false);
    const [isProdutosLoading, setIsProdutosLoading] = useState(false);
    const [produtoCor, setProdutoCor] = useState<ListaProdutoCorProps[]>([]);
    const [totalRegistros, setTotalRegistros] = useState(0);

    const { listaTamanhoIdQuantidade, cor: corWatch } = watch();
    const watchProduto: OptionType<ProdutoOptionProps> = watch('produto');

    const productValue = watchProduto?.value;

    const temGradeLancada = (listaTamanhoIdQuantidade || []).length > 1;
    const podeLancarGrade =
      !!productValue?.coresOptions && productValue?.coresOptions.length > 0;

    const { id: idLojaAtual } = auth.getLoja();

    const handleLimpar = useCallback(() => {
      formMethods.setValue('listaTamanhoIdQuantidade.0.quantidade', 1);
      formMethods.setValue('listaTamanhoIdQuantidade.0.tamanho', null as any);
      formMethods.setValue('listaTamanhoIdQuantidade', []);
    }, [formMethods]);

    const handleAdicionarProdutoCor = formMethods.handleSubmit(async (data) => {
      if (
        formMethods.getValues('listaTamanhoIdQuantidade.0.quantidade') === 0
      ) {
        toast.warning('É preciso informar uma quantidade');
        return false;
      }

      const newListProducts = [] as ListaProdutoCorProps[];

      await data.listaTamanhoIdQuantidade?.forEach((listItens) => {
        const values = {
          cor: data.cor,
          quantidade: listItens.quantidade,
          tamanho: listItens.tamanho
            ? [
                {
                  ...listItens.tamanho,
                  quantidade: listItens.quantidade,
                },
              ]
            : [],
        };

        newListProducts.push(values);
      });

      setProdutoCor((valoresJaAdicionados) => [
        ...valoresJaAdicionados,
        ...newListProducts,
      ]);
      formMethods.setValue('cor', null as any);
      handleLimpar();
      return true;
    });

    const resetarValores = useCallback(() => {
      formMethods.setValue('cor', null as any);
      formMethods.setValue('valorUnitario', 0);
      formMethods.setValue('ipi', 0);
      formMethods.setValue('icmsSt', 0);
      formMethods.setValue('fcpSt', 0);
      formMethods.setValue('custoAdicional', 0);
      setProdutoCor([]);
    }, [formMethods]);

    const quantidadeMaiorQueZero = (data: FormData) => {
      const isQuantidadeValida = data.listaTamanhoIdQuantidade?.every(
        (element) => element.quantidade > 0
      );
      if (isQuantidadeValida) return true;

      toast.warning(
        'Campo quantidade deve ser maior que 0. Por favor altere o valor do campo quantidade.'
      );
      return false;
    };

    async function handlePushGradeModal() {
      if (podeLancarGrade) {
        const cor = formMethods.getValues('cor');

        const tamanhos = productValue?.tamanhosOptions.map((tamanho) => {
          const tamanhoQuantidade = (listaTamanhoIdQuantidade || []).find(
            (tamanhoIdQuantidade) =>
              tamanhoIdQuantidade.tamanho?.value === tamanho.value
          );

          return {
            produtoCorTamanhoId: tamanho.value,
            tamanho: tamanho.label,
            padraoSistema: false,
            quantidade: tamanhoQuantidade?.quantidade || 0,
          };
        });

        const addedTamanhos = await ModalGradeTamanhos({
          produtoNome: watchProduto.label,
          corDescricao: cor?.label,
          casasDecimaisQuantidade,
          volumeUnitario: watchProduto.value.volumeUnitario,
          tamanhos,
        });

        const newListaTamanhoIdQuantidade =
          addedTamanhos.length > 0
            ? addedTamanhos.map(
                (tamanho) =>
                  ({
                    quantidade: tamanho.quantidade,
                    tamanho: {
                      value: tamanho.produtoCorTamanhoId,
                      label: tamanho.tamanhoDescricao,
                    },
                  } as TamanhoQuantidade)
              )
            : undefined;
        if (addedTamanhos.length === 1) {
          const tamanhoSelecionado = addedTamanhos[0];

          formMethods.setValue('listaTamanhoIdQuantidade.0.tamanho', {
            value: tamanhoSelecionado?.produtoCorTamanhoId,
            label: tamanhoSelecionado?.tamanhoDescricao || '',
          });
          formMethods.setValue(
            'listaTamanhoIdQuantidade.0.quantidade',
            tamanhoSelecionado.quantidade
          );
        }
        formMethods.setValue(
          'listaTamanhoIdQuantidade',
          newListaTamanhoIdQuantidade
        );
      }
    }

    const listProdutoCor = useCallback(() => {
      const items = [] as ListaProdutoCorProps[];

      const valueProduto = produtoCor.reduce((acc, curr) => {
        const itemAlreadyAddedIndex = acc.findIndex(
          (item) => item.cor?.value === curr.cor?.value
        );

        if (itemAlreadyAddedIndex > -1) {
          acc.splice(itemAlreadyAddedIndex, 1, {
            ...acc[itemAlreadyAddedIndex],
            cor: curr.cor,
            quantidade: acc[itemAlreadyAddedIndex].quantidade + curr.quantidade,
            tamanho: [...acc[itemAlreadyAddedIndex].tamanho, ...curr.tamanho],
          });
        } else {
          acc.push({
            cor: curr.cor,
            quantidade: curr.quantidade,
            tamanho: curr.tamanho,
          });
        }

        return acc;
      }, [] as ListaProdutoCorProps[]);

      valueProduto.forEach((produtoItem) => {
        const newTamanhos = produtoItem.tamanho.reduce((acc, curr) => {
          const itemAlreadyAddedIndex = acc.findIndex(
            (item) => item.value === curr.value
          );

          if (itemAlreadyAddedIndex > -1) {
            acc.splice(itemAlreadyAddedIndex, 1, {
              ...acc[itemAlreadyAddedIndex],
              value: curr.value,
              quantidade:
                acc[itemAlreadyAddedIndex].quantidade + curr.quantidade,
              label: curr.label,
            });
          } else {
            acc.push({
              value: curr.value,
              quantidade: curr.quantidade,
              label: curr.label,
            });
          }

          return acc;
        }, [] as TamanhoProps[]);

        items.push({
          ...produtoItem,
          tamanho: newTamanhos,
        });
      });

      return items;
    }, [produtoCor])();

    const getListSizes = useCallback(async (id: string) => {
      setIsProdutosLoading(true);

      const response = await api.get<void, ResponseApi<ProdutoTamanhoProps[]>>(
        `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${id}/tamanhos`
      );

      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response?.sucesso && response?.dados) {
          const newSizes = response.dados
            .filter(({ padraoSistema }) => !padraoSistema)
            .map((size) => ({
              label: size.descricao,
              value: size.id,
            }));

          setIsProdutosLoading(false);
          return newSizes;
        }
      }

      setIsProdutosLoading(false);
      return [];
    }, []);

    const getListColors = useCallback(async (id: string) => {
      setIsProdutosLoading(true);

      const response = await api.get<void, ResponseApi<VariacoesCoresProps[]>>(
        `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${id}/cores`
      );

      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response?.sucesso && response?.dados) {
          const newColors = response.dados
            .filter(({ cor }) => !cor.padraoSistema)
            .map(({ cor }) => ({
              label: cor.descricao,
              value: cor.id,
            }));

          setIsProdutosLoading(false);
          return newColors;
        }
      }

      setIsProdutosLoading(false);
      return [];
    }, []);

    const getListProducts = useCallback(
      async (
        inputValue: string,
        dataPagination: PaginationData
      ): Promise<OptionType<ProdutoOptionProps>[]> => {
        setIsProdutosLoading(true);

        const response = await api.get<
          void,
          ResponseApi<GridPaginadaRetorno<ProdutoOptionProps>>
        >(
          formatQueryPagegTable(
            ConstanteEnderecoWebservice.PRODUTO_COR_TAMANHO_LISTAR_SELECT_ENTRADA_MERCADORIA,
            dataPagination
          ),
          {
            params: { nomeSkuCodigoExternoBarrasGtinEan: inputValue },
          }
        );

        if (response) {
          if (response?.avisos) {
            response.avisos.forEach((aviso) => toast.warning(aviso));
          }
          setTotalRegistros(response.dados.total || 0);

          if (response?.sucesso && response?.dados) {
            const data = response.dados.registros.map((produto) => ({
              label: produto.nome,
              value: { ...produto, coresOptions: [], tamanhosOptions: [] },
            }));

            setIsProdutosLoading(false);
            return data;
          }
        }

        setIsProdutosLoading(false);
        return [];
      },
      []
    );

    async function handleCadastrarProduto(inputValue: string) {
      if (temPermissaoCadastrarProduto) {
        const { produto: newProduto } = await ModalCadastrarProduto({
          inputValue,
        });

        let precoCompra = 0;
        const obterPrecoCompra: Lojas[] = newProduto?.produtoPrecoLojas?.filter(
          (loja: Lojas) => loja.lojaId === idLojaAtual
        );

        if (obterPrecoCompra.length > 0) {
          const precoCompraLojaAtual = obterPrecoCompra[0].precoCompra;
          precoCompra = Number(precoCompraLojaAtual);
        } else {
          precoCompra = 0;
        }

        const response = await api.get<void, ResponseApi<ProdutoOptionProps>>(
          ConstanteEnderecoWebservice.PRODUTO_COR_TAMANHO_OBTER_OPCAO_SELECT_ENTRADA_MERCADORIA,
          {
            params: { nomeSkuCodigoExternoBarrasGtinEan: newProduto.nome },
          }
        );

        if (response) {
          if (response?.avisos) {
            response.avisos.forEach((aviso) => toast.warning(aviso));
          }

          if (response?.sucesso && response?.dados) {
            const productIsTypeVariation =
              response.dados.tipoProduto === TipoProdutoEnum.PRODUTO_VARIACAO;

            formMethods.setValue('valorUnitario', precoCompra);

            const newProduct = {
              label: response.dados.nome,
              value: {
                ...response.dados,
                coresOptions: [],
                tamanhosOptions: [],
              },
            };

            if (!productIsTypeVariation) {
              setIsProdutosLoading(false);

              return newProduct;
            }

            const [coresOptions, tamanhosOptions] = await Promise.all([
              getListColors(response.dados.id),
              getListSizes(response.dados.id),
            ]);

            setIsProdutosLoading(false);

            return {
              ...newProduct,
              value: { ...newProduct.value, coresOptions, tamanhosOptions },
            };
          }
        }
      } else {
        toast.warning(
          'Você não tem permissão para acessar essa função. Consulte o administrador da conta.'
        );
      }

      setIsProdutosLoading(false);
      return undefined;
    }

    async function handleCadastrarCor(inputValue: string) {
      if (temPermissaoCadastrarCor) {
        const { cor: newColor } = await ModalCadastrarCor({
          inputValue,
          produtoId: watchProduto.value.id,
        });

        const colorOption = {
          value: newColor.corId,
          label: newColor.corDescricao,
        };

        setValue('produto.value.coresOptions', [
          ...productValue?.coresOptions,
          colorOption,
        ]);
        return colorOption;
      }

      toast.warning(
        'Você não tem permissão para acessar essa função. Consulte o administrador da conta.'
      );

      return undefined;
    }

    async function handleCadastrarTamanho(inputValue: string) {
      if (temPermissaoCadastrarTamanho) {
        const { tamanho: newSize } = await ModalCadastrarTamanho({
          inputValue,
          produtoId: watchProduto.value.id,
        });

        const sizeOption = {
          value: newSize.id,
          label: newSize.descricao,
        };

        setValue('produto.value.tamanhosOptions', [
          ...productValue?.tamanhosOptions,
          sizeOption,
        ]);

        return sizeOption;
      }

      toast.warning(
        'Você não tem permissão para acessar essa função. Consulte o administrador da conta.'
      );

      return undefined;
    }

    const onChangeSelectedProduct = useCallback(
      async (option: OptionType<ProdutoOptionProps> | undefined | null) => {
        if (option) {
          const { value } = option;

          const productIsTypeVariation =
            value.tipoProduto === TipoProdutoEnum.PRODUTO_VARIACAO;

          if (productIsTypeVariation) {
            const [colors, sizes] = await Promise.all([
              getListColors(value.id),
              getListSizes(value.id),
            ]);

            setValue('produto.value.coresOptions', colors);
            setValue('produto.value.tamanhosOptions', sizes);
          }
        }

        resetarValores();
        handleLimpar();
      },
      [getListColors, getListSizes, handleLimpar, resetarValores, setValue]
    );

    function submit(data: FormData, deveReiniciar = false) {
      setIsLoading(true);

      const existeCoresTamanhos =
        data.produto.value.coresOptions.length > 0 ||
        data.produto.value.tamanhosOptions.length > 0;

      if (data.listaTamanhoIdQuantidade) {
        const produtoResponse: ProdutoResponse = {
          nomeProduto: watchProduto.label,
          produtoId: data.produto.value.id,
          corId: data.cor?.value,
          corDescricao: data.cor?.label,
          listaTamanhoIdQuantidade: data.listaTamanhoIdQuantidade.map(
            (tamanhoQuantidade) => ({
              id: tamanhoQuantidade.tamanho?.value,
              quantidade: tamanhoQuantidade.quantidade,
              descricaoTamanho: tamanhoQuantidade.tamanho?.label,
            })
          ),
          valorUnitarioEntrada: data.valorUnitario || 0,
          valorIpi: data.ipi || 0,
          valorIcmsSt: entradaRateiaIcmsSt ? 0 : data.icmsSt || 0,
          valorFcpSt: data.fcpSt || 0,
          custoAdicional: data.custoAdicional || 0,
          volumeUnitario: watchProduto.value.volumeUnitario,
        };

        if (existeCoresTamanhos && deveReiniciar) {
          handleAdicionarProdutoCor();
          if (
            formMethods.getValues('listaTamanhoIdQuantidade.0.quantidade') > 0
          ) {
            adicionarProduto(produtoResponse);
            toast.success('Produto adicionado com sucesso');
          }

          setIsLoading(false);
        } else {
          onResolve({ produto: produtoResponse, deveReiniciar });
        }
      }
    }

    const handleSubmit = formMethods.handleSubmit(async (data) => {
      const podeAdicionarProduto = quantidadeMaiorQueZero(data);
      if (podeAdicionarProduto) {
        submit(data);
      }
    });

    const handleSubmitReset = formMethods.handleSubmit(async (data) => {
      const podeAdicionarProduto = quantidadeMaiorQueZero(data);
      if (podeAdicionarProduto) {
        submit(data, true);
      }
    });

    const latestProps = useRef({
      reset: formMethods.reset,
    });

    useEffect(() => {
      latestProps.current = {
        reset: formMethods.reset,
      };
    });

    useEffect(() => {
      if (corWatch) {
        handleLimpar();
      }
    }, [corWatch, handleLimpar]);

    useEffect(() => {
      async function handleAddProdutoKit() {
        await ModalAtencao({
          text: 'Este tipo de produto não pode ser adicionado nesta operação.',
          closeOnOverlayClick: false,
          callback: async (ok: boolean) => {
            if (ok) {
              latestProps.current.reset({ produto: '' as any });
            }
          },
        });
      }

      if (!watchProduto) {
        latestProps.current.reset();
      }

      if (productValue?.tipoProduto === TipoProdutoEnum.PRODUTO_KIT) {
        handleAddProdutoKit();
      }
    }, [productValue, watchProduto]);

    const erroTextTamanho: any = (formMethods.formState.errors
      ?.listaTamanhoIdQuantidade || [])[0]?.tamanho;

    return (
      <ModalPadraoChakra
        isCentered
        size={!isSmallerThan900 ? 'xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalContent
          marginBottom={{ base: 0, md: '3.75rem' }}
          marginTop={{ base: 0, md: '3.75rem' }}
          h="unset"
          bg="gray.50"
          maxW={{ base: '100%', md: '1200px' }}
        >
          {isLoading && <LoadingPadrao />}
          <ModalHeader
            mt={isSmallerThan900 ? 12 : undefined}
            mb={isSmallerThan900 ? 8 : undefined}
            px="0"
            mx={{ base: 6, md: 8 }}
            color="primary.50"
          >
            Adicionando produto
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p={{ base: 6, md: 8 }} py="0px !important">
            <FormProvider {...formMethods}>
              <SimpleCard
                boxShadow="none"
                bg="primary.500"
                sx={{
                  '& label.chakra-form__label, & .chakra-form__required-indicator': {
                    color: 'secondary.300',
                  },
                }}
              >
                <SimpleGridForm>
                  <GridItem colSpan={12}>
                    <CreatableSelectVirtualized
                      id="produto"
                      name="produto"
                      label="Descrição do produto"
                      placeholder="Digite o nome do produto existente no sistema ou cadastre um novo"
                      handleGetOptions={getListProducts}
                      helperText="A pesquisa de produto considera os campos Status, Descrição, Código SKU, GTIN/EAN, Código externo e Código de barras interno."
                      isLoading={isProdutosLoading}
                      creatableInputTextPreffix="Cadastrar o produto"
                      handleCreateOption={handleCadastrarProduto}
                      onChangeSelect={onChangeSelectedProduct}
                      required
                      asControlledByObject
                      autoFocus
                      isClearable
                      totalRegistros={totalRegistros}
                    />
                  </GridItem>
                </SimpleGridForm>
              </SimpleCard>
              <Collapse
                in={
                  !!watchProduto &&
                  productValue?.tipoProduto !== TipoProdutoEnum.PRODUTO_KIT
                }
                animateOpacity
                unmountOnExit
              >
                <SimpleCard background="gray.50" boxShadow="none">
                  <SimpleGridForm>
                    {productValue?.coresOptions.length > 0 && (
                      <CreatableSelect
                        id="cor"
                        name="cor"
                        label="Cor"
                        placeholder="Selecione a cor ou adicione uma nova"
                        creatableInputTextPreffix="Cadastrar a cor"
                        colSpan={[12, 4, 4, 4]}
                        handleCreateOption={handleCadastrarCor}
                        options={productValue?.coresOptions}
                        disabled={isLoading || isProdutosLoading}
                        asControlledByObject
                        required
                      />
                    )}
                    {productValue?.tamanhosOptions.length > 0 && (
                      <GridItem
                        mt={temGradeLancada ? '18px' : undefined}
                        colSpan={[12, 4, 4, 4]}
                      >
                        <HStack alignItems="stretch">
                          {temGradeLancada ? (
                            <Flex
                              alignItems="center"
                              justifyContent="center"
                              bg="primary.800"
                              border="1px"
                              borderColor="gray.100"
                              w="full"
                              h="36px"
                              borderRadius="md"
                              userSelect="none"
                            >
                              <Text color="white" fontSize="sm">
                                Tamanhos informados na grade
                              </Text>
                            </Flex>
                          ) : (
                            <CreatableSelect
                              id="tamanho"
                              name="listaTamanhoIdQuantidade.0.tamanho"
                              label="Tamanho"
                              placeholder="Informe o tamanho"
                              creatableInputTextPreffix="Cadastrar o tamanho"
                              handleCreateOption={handleCadastrarTamanho}
                              options={productValue?.tamanhosOptions}
                              disabled={isLoading || isProdutosLoading}
                              errorText={erroTextTamanho?.message}
                              asControlledByObject
                              required
                            />
                          )}

                          <Flex ml="23px" flex="1" alignItems="flex-end">
                            <IconButton
                              aria-label="Selecionar grade de tamanhos"
                              icon={
                                <Icon as={GradeTamanhosIcon} fontSize="xl" />
                              }
                              colorScheme="whiteAlpha"
                              color="gray.800"
                              bg="white"
                              borderRadius="md"
                              _hover={{ bg: 'gray.100' }}
                              _active={{ bg: 'gray.100' }}
                              border="1px"
                              borderColor="gray.200"
                              onClick={handlePushGradeModal}
                              isDisabled={!podeLancarGrade}
                            />
                          </Flex>
                        </HStack>
                      </GridItem>
                    )}

                    {temGradeLancada ? (
                      <GridItem colSpan={[12, 4, 4, 4]}>
                        <CampoContainer
                          id="quantidade"
                          name="quantidade"
                          label="Quantidade"
                          display="flex"
                          flexDirection="column"
                        >
                          <Flex
                            alignItems="center"
                            justifyContent="center"
                            bg="gray.50"
                            border="1px"
                            borderColor="gray.100"
                            w="full"
                            h="36px"
                            borderRadius="md"
                            userSelect="none"
                          >
                            <Text fontSize="sm">
                              Quantidade informada na grade
                            </Text>
                          </Flex>
                        </CampoContainer>
                      </GridItem>
                    ) : (
                      <NumberInput
                        id="quantidade"
                        name="listaTamanhoIdQuantidade.0.quantidade"
                        errorText={
                          (formMethods.formState.errors
                            ?.listaTamanhoIdQuantidade || [])[0]?.quantidade
                            ?.message
                        }
                        label="Quantidade"
                        placeholder={
                          watchProduto?.value.volumeUnitario
                            ? '0'
                            : `0,${'0'.repeat(casasDecimaisQuantidade)}`
                        }
                        scale={
                          watchProduto && watchProduto.value.volumeUnitario
                            ? 0
                            : casasDecimaisQuantidade
                        }
                        isDisabled={!watchProduto || temGradeLancada}
                        colSpan={[12, 4, 4, 4]}
                      />
                    )}

                    <NumberInput
                      id="valorUnitario"
                      name="valorUnitario"
                      label="Valor unitário"
                      bgLeftElement="gray.50"
                      editarFundoLeftElemento
                      leftElement="R$"
                      placeholder={`0,${'0'.repeat(casasDecimaisValor)}`}
                      scale={casasDecimaisValor}
                      isDisabled={!watchProduto}
                      colSpan={[6, 4, 3]}
                    />

                    <NumberInput
                      id="ipi"
                      name="ipi"
                      bgLeftElement="gray.50"
                      editarFundoLeftElemento
                      label="IPI"
                      leftElement="R$"
                      placeholder={`0,${'0'.repeat(2)}`}
                      scale={2}
                      isDisabled={!watchProduto}
                      colSpan={[6, 4, 2]}
                    />
                    <NumberInput
                      id="icmsSt"
                      name="icmsSt"
                      bgLeftElement="gray.50"
                      editarFundoLeftElemento
                      label="ICMS ST"
                      leftElement="R$"
                      helperText="Informe o valor do ICMS ST somente para este produto. Se preferir, é possível informar o valor total contido na nota fiscal de uma só vez. Para isso ignore este campo e preencha o valor completo na próxima etapa: “valores”"
                      placeholder={`0,${'0'.repeat(2)}`}
                      scale={2}
                      isDisabled={!watchProduto || entradaRateiaIcmsSt}
                      colSpan={[6, 4, 2]}
                    />
                    <NumberInput
                      id="fcpSt"
                      name="fcpSt"
                      bgLeftElement="gray.50"
                      editarFundoLeftElemento
                      label="FCP ST"
                      leftElement="R$"
                      placeholder={`0,${'0'.repeat(2)}`}
                      scale={2}
                      isDisabled={!watchProduto}
                      colSpan={[6, 4, 2]}
                    />

                    <NumberInput
                      id="custoAdicional"
                      name="custoAdicional"
                      bgLeftElement="gray.50"
                      editarFundoLeftElemento
                      label="Custo adicional"
                      leftElement="R$"
                      helperText="Os valores deste campo não serão somados ao valor total da entrada, servindo apenas para compor o custo do produto."
                      placeholder={`0,${'0'.repeat(2)}`}
                      scale={2}
                      isDisabled={!watchProduto}
                      colSpan={[12, 4, 3]}
                    />

                    {listProdutoCor.length > 0 && (
                      <GridItem colSpan={12}>
                        <Box
                          overflowY={
                            listProdutoCor.length > 5 ? 'scroll' : 'hidden'
                          }
                          pl="10px"
                          pr="10px"
                          borderRadius="6px"
                          bg="white"
                        >
                          <Table variant="">
                            <Thead>
                              <Tr
                                sx={{
                                  th: {
                                    paddingBottom: '0px',
                                  },
                                }}
                              >
                                <Th>Cor adicionada</Th>
                                <Th>
                                  <Flex justifyContent="flex-end">
                                    Quantidade
                                  </Flex>
                                </Th>
                              </Tr>
                              <Tr>
                                <Th
                                  pt="5px"
                                  pl="10px"
                                  pr="10px"
                                  pb="5px"
                                  colSpan={5}
                                >
                                  <Divider />
                                </Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {listProdutoCor.map((produtoItem, index) => (
                                <>
                                  <Tr>
                                    <Td color="primary.50">
                                      {produtoItem?.cor?.label || '---'}
                                    </Td>
                                    <Td isNumeric>
                                      <Flex justifyContent="flex-end">
                                        {DecimalMask(
                                          produtoItem.quantidade,
                                          casasDecimaisQuantidade
                                        )}
                                      </Flex>
                                    </Td>
                                  </Tr>
                                  {listProdutoCor.length !== index + 1 && (
                                    <Tr>
                                      <Td
                                        pt="0px"
                                        pl="10px"
                                        pr="10px"
                                        pb="5px"
                                        colSpan={5}
                                      >
                                        <Divider />
                                      </Td>
                                    </Tr>
                                  )}
                                </>
                              ))}
                            </Tbody>
                          </Table>
                        </Box>
                      </GridItem>
                    )}
                  </SimpleGridForm>
                </SimpleCard>
              </Collapse>
            </FormProvider>
          </ModalBody>
          <ModalFooter
            p={{ base: 6, md: 8 }}
            justifyContent={['center', 'center', 'flex-end']}
            borderTop="1px"
            borderColor="gray.100"
            mx={{ base: 0, md: 8 }}
            px="0"
          >
            <Flex
              w="full"
              justifyContent={['center', 'center', 'flex-end']}
              direction={['column-reverse', 'column-reverse', 'row']}
            >
              <Button
                color="gray.400"
                variant="outline"
                mt={['10px', '10px', '0']}
                mr={['0', '0', '20px']}
                borderRadius="md"
                w={['full', 'full', '100px']}
                onClick={handleSubmit}
              >
                Confirmar
              </Button>
              <Button
                colorScheme="aquamarine"
                borderRadius="md"
                w={['full', 'full', '225px']}
                leftIcon={<Icon as={SalvarInserirNovoIcon} fontSize="lg" />}
                onClick={handleSubmitReset}
              >
                Confirmar e inserir novo
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
