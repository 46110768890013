import { useLocation } from 'react-router-dom';
import React, { useMemo } from 'react';
import { Box } from '@chakra-ui/react';

import LayoutContextProvider from 'store/Layout';
import auth from 'modules/auth';
import TipoSistemaEnum from 'constants/enum/tipoSistema';
import ConstanteRotas from 'constants/rotas';

import { ContainerAvisoCertificado } from 'components/ContainerAvisoCertificado';

import Header from './Header';
import Menu from './Menu';

interface LayoutProps {
  children: React.ReactNode;
}

type Location = {
  pathname: string;
};

const Layout = ({ children }: LayoutProps) => {
  const content = useMemo(() => <> {children} </>, [children]);

  const location = useLocation<Location>();

  const sistema = auth.getSistema();

  const paginasSemBordaPadding = [
    ConstanteRotas.INTEGRACAO_TRAY_COMMERCE_VENDAS,
    ConstanteRotas.INTEGRACAO_TRAY_COMMERCE_PAINEL_ADM,
    ConstanteRotas.INTEGRACAO_TRAY_COMMERCE_FORMA_RECEBIMENTO,
    ConstanteRotas.INTEGRACAO_TRAY_COMMERCE_PRODUTO,
    ConstanteRotas.INTEGRACAO_TRAY_COMMERCE_CONFIGURACAO,
    ConstanteRotas.INTEGRACAO_TRAY_COMMERCE_CATEGORIAS,
    ConstanteRotas.PRODUTO_CADASTRAR,
    ConstanteRotas.PRODUTO_ALTERAR,
    ConstanteRotas.PDV_OFFLINE,
    ConstanteRotas.FRENTE_CAIXA_ATIVACAO,
    ConstanteRotas.FRENTE_CAIXA_CONFIGURACAO,
    ConstanteRotas.FRENTE_CAIXA_DEPARTAMENTOS,
    ConstanteRotas.FRENTE_CAIXA_PAINEL,
    ConstanteRotas.FRENTE_CAIXA_DADOS,
    ConstanteRotas.FRENTE_CAIXA_DISPOSITIVOS,
    ConstanteRotas.FRENTE_CAIXA_ENTREGADORES,
    ConstanteRotas.FRENTE_CAIXA_FORMAS_RECEBIMENTO,
    ConstanteRotas.FRENTE_CAIXA_GERENCIADORES_IMPRESSAO,
    ConstanteRotas.FRENTE_CAIXA_SETORES_ENTREGA,
    ConstanteRotas.AUTO_ATENDIMENTO_TEMA,
    ConstanteRotas.FRENTE_CAIXA_INTERMEDIADOR,
    ConstanteRotas.FRENTE_CAIXA_PERIODO_CAIXA,
    ConstanteRotas.FRENTE_CAIXA_RELATORIO_PERSONALIZADO,
  ];

  const hasPaddingOrBorder = paginasSemBordaPadding.some((route) =>
    location.pathname.includes(route.replaceAll('/:id', ''))
  );

  const isRouteDashboard = location.pathname === ConstanteRotas.DASHBOARD;
  const isClienteSTi3 =
    sistema.value === TipoSistemaEnum.ZENDAR ||
    sistema.value === TipoSistemaEnum.FOMER ||
    sistema.value === TipoSistemaEnum.FOMER_SISTEMA;

  return (
    <LayoutContextProvider>
      {isRouteDashboard && isClienteSTi3 && <ContainerAvisoCertificado />}
      <Box
        display="grid"
        gridTemplateColumns="auto 1fr"
        gridTemplateRows="46px 1fr"
        gridTemplateAreas="'NM HE' 'NM CN'"
        height="100vh"
        overflow="hidden"
        sx={{
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Header hasBorder={!hasPaddingOrBorder} />
        <Menu />
        <Box
          gridArea="CN"
          backgroundColor="gray.50"
          height="100%"
          overflowX="hidden"
          overflowY="hidden"
          maxH="calc(100vh - 46px)"
          p={
            !hasPaddingOrBorder
              ? ['0 5px 0 15px', '0 5px 0 20px', '0 5px 0 25px', '0 5px 0 28px']
              : undefined
          }
        >
          <Box
            pl={!hasPaddingOrBorder ? '3px' : undefined}
            py={
              !hasPaddingOrBorder ? ['15px', '20px', '25px', '28px'] : undefined
            }
            maxH="calc(100vh - 46px)"
            gridArea="CN"
            backgroundColor="gray.50"
            height="100%"
            overflowX="hidden"
            overflowY="auto"
          >
            <Box
              pr={
                !hasPaddingOrBorder
                  ? ['10px', '15px', '20px', '20px']
                  : undefined
              }
            >
              {content}
            </Box>
          </Box>
        </Box>
      </Box>
    </LayoutContextProvider>
  );
};

export default Layout;
