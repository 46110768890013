import React from 'react';
import { Text, Icon, Flex, Button, Stack } from '@chakra-ui/react';

import { NaoSelecionadoIcon, SelecionadoIcon } from 'icons';

type ButtonAbrirCaixaProps = {
  caixaNome: string;
  usuarioAberturaNome?: string;
  onClick: () => void;
  isSelected: boolean;
  autoFocus: boolean;
};

const ButtonAbrirCaixa: React.FC<ButtonAbrirCaixaProps> = ({
  caixaNome,
  usuarioAberturaNome,
  isSelected,
  onClick,
  autoFocus,
  ...rest
}) => {
  return (
    <Flex alignItems="center" w="100%">
      <Button
        position="relative"
        w="120px"
        h="120px"
        justifyContent={{ base: 'flex-start', md: 'center' }}
        borderRadius="lg"
        border="1px"
        borderColor="gray.100"
        boxShadow="md"
        bg="white"
        _hover={{ bg: 'white' }}
        _active={{ bg: 'white' }}
        onClick={onClick}
        autoFocus={autoFocus}
        disabled={!!usuarioAberturaNome}
        {...rest}
      >
        <Stack
          direction="column"
          spacing={4}
          alignItems="center"
          justifyContent="center"
          h="100%"
          w="full"
        >
          <Icon
            as={isSelected ? SelecionadoIcon : NaoSelecionadoIcon}
            w="20px"
            h="20px"
            color={isSelected ? 'primary.500' : 'gray.400'}
          />

          <Text
            whiteSpace="normal"
            maxW={{
              base: 'full',
              md: 'full',
            }}
            fontSize="sm"
            color="gray.700"
          >
            {caixaNome}
            <Text
              whiteSpace="normal"
              maxW={{
                base: 'full',
                md: 'full',
              }}
              fontSize="x-small"
              color="gray.500"
            >
              - {usuarioAberturaNome || 'Fechado'} -
            </Text>
          </Text>
        </Stack>
      </Button>
    </Flex>
  );
};

export default ButtonAbrirCaixa;
