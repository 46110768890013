import React from 'react';
import {
  Button,
  HStack,
  ModalCloseButton,
  Text,
  useMediaQuery,
  ModalProps,
  ModalContent,
  ModalHeader,
  useDisclosure,
  ModalBody,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';

type ModalObservacoesProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalProps> & {
    observacao?: string;
  };
export const ModalObservacoes = create<ModalObservacoesProps>(
  ({ observacao }) => {
    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const handleConfimarObservacao = () => {
      onClose();
    };

    return (
      <ModalPadraoChakra
        size={isLargerThan900 ? '2xl' : 'full'}
        scrollBehavior={isLargerThan900 ? 'inside' : 'outside'}
        isCentered={isLargerThan900}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalContent
          margin={0}
          maxW={isLargerThan900 ? '600px' : undefined}
          borderRadius={isLargerThan900 ? 'md' : 0}
          bg="white"
          p="4"
        >
          <ModalHeader
            color="primary.500"
            mt={isLargerThan900 ? undefined : 4}
            mb={isLargerThan900 ? 4 : 4}
          >
            Observação
          </ModalHeader>
          <ModalCloseButton
            mt={isLargerThan900 ? 6 : 10}
            mr={isLargerThan900 ? 4 : 6}
          />
          <ModalBody>
            <Text>{observacao}</Text>
            <HStack py={6} spacing={6} justifyContent="center">
              <Button
                variant="solid"
                colorScheme="secondary"
                size="sm"
                minW="100px"
                onClick={handleConfimarObservacao}
              >
                Confirmar
              </Button>
            </HStack>
          </ModalBody>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
