import React from 'react';
import { Button, Flex, Icon, Text } from '@chakra-ui/react';

import { useNavMenuContext } from 'store/NavMenu';
import { useLayoutContext } from 'store/Layout';

import {
  HomeIcon,
  CadastrosIcon,
  DashboardIcon,
  EstoqueIcon,
  FinanceiroIcon,
  FiscalIcon,
  RelatoriosIcon,
  MenuIntegracoes,
  ConfiguracoesIcon,
  MenuIcon,
} from 'icons';
import { ClosedMenuLogo, OpenMenuLogo } from 'icons/Identidade';

import NavItem from './NavItem';

const NavMenu = () => {
  const { setMenuIsOpen } = useLayoutContext();
  const { navMenuIsOpen, setNavMenuIsOpen } = useNavMenuContext();

  function handleToggleOpenMenu() {
    setNavMenuIsOpen(!navMenuIsOpen);
    setMenuIsOpen(!navMenuIsOpen);
  }

  return (
    <Flex
      gridArea="NM"
      display="flex"
      flexDirection="column"
      width={navMenuIsOpen ? '150px' : '46px'}
      background="menu.bg"
      boxShadow="3px 0px 6px rgba(0, 0, 0, 0.48)"
      transition="width 0.2s"
      zIndex={1025}
      height="100%"
      userSelect="none"
      overflowX="hidden"
      overflowY="auto"
    >
      <Text
        display="flex"
        alignItems="center"
        minHeight="45px"
        pointerEvents="none"
        w="100%"
        overflow="hidden"
        justifyContent="center"
        padding="0 11px"
        bg="transparent"
        borderBottom="1px"
        borderColor="menu.border"
      >
        {!navMenuIsOpen ? <ClosedMenuLogo /> : <OpenMenuLogo />}
      </Text>

      <Button
        variant="linkDefault"
        colorScheme="none"
        h="40px"
        display="flex"
        alignItems="center"
        minHeight="46px"
        w="100%"
        borderRadius="0"
        justifyContent="flex-start"
        onClick={handleToggleOpenMenu}
        id="menuPrincipalBtn"
        bg="menu.bgButton"
      >
        <Icon as={MenuIcon} color="white" fontSize="1.5rem" />
      </Button>

      <Flex
        direction="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        h="full"
        w="full"
        id="sti3-navmenu-container"
      >
        <NavItem title="Inicial" icon={HomeIcon} path="/dashboard" />

        <NavItem menuKey="cadastros" title="Cadastros" icon={CadastrosIcon} />
        <NavItem menuKey="vendas" title="Vendas" icon={DashboardIcon} />
        <NavItem menuKey="estoque" title="Estoque" icon={EstoqueIcon} />
        <NavItem
          menuKey="financeiro"
          title="Financeiro"
          icon={FinanceiroIcon}
        />
        <NavItem menuKey="fiscal" title="Fiscal" icon={FiscalIcon} />
        <NavItem
          menuKey="relatorios"
          title="Relatórios"
          icon={RelatoriosIcon}
        />
        <NavItem
          menuKey="integracoes"
          title="Integrações"
          icon={MenuIntegracoes}
        />
        <NavItem
          menuKey="configuracoes"
          title="Configurações"
          icon={ConfiguracoesIcon}
        />
      </Flex>
    </Flex>
  );
};

export default NavMenu;
