import {
  ModalProps,
  ModalContent,
  ModalBody,
  useDisclosure,
  Flex,
  useMediaQuery,
  Box,
  Text,
  ModalHeader,
  Divider,
  Icon,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { AiOutlineRight, AiOutlineLeft } from 'react-icons/ai';

import { enumTabsZoop } from 'constants/enum/enumTabsZoop';
import { TabSelecionadaProps } from 'store/Zoop/types';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';

type MenuMobileProps = Omit<ModalProps, 'children' | 'isOpen' | 'onClose'> &
  InstanceProps<ModalProps> & {
    setActiveTab: React.Dispatch<React.SetStateAction<TabSelecionadaProps>>;
    activeTab: TabSelecionadaProps;
    tabsDisponiveis: TabSelecionadaProps[];
    goToContaBancaria: () => void;
  };
export const MenuMobile = create<MenuMobileProps, ModalProps>(
  ({
    onResolve,
    onReject,
    activeTab,
    setActiveTab,
    tabsDisponiveis,
    goToContaBancaria,
    ...rest
  }) => {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size="full"
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
      >
        <ModalContent zIndex="9999" bg="gray.50" w="full" h="full">
          <ModalHeader>
            <Icon
              onClick={onClose}
              cursor="pointer"
              color="blue.600"
              as={AiOutlineLeft}
            />
          </ModalHeader>
          <ModalBody
            justifyContent="center"
            alignItems="center"
            h="full"
            w="full"
            pl="24px"
            pr="24px"
            pt="0"
          >
            <Flex h="full" w="full">
              <Box h="full" w="full">
                {enumTabsZoop.itensZoop.map((itemZoop, index) => {
                  return (
                    <>
                      <Divider mb="10px" mt="10px" />
                      <Flex
                        pl="20px"
                        pr="20px"
                        cursor="pointer"
                        w="full"
                        justifyContent="space-between"
                        alignItems="center"
                        onClick={() => {
                          if (
                            itemZoop.value === enumTabsZoop.CONTA_BANCARIA.value
                          ) {
                            goToContaBancaria();
                            onClose();
                            return;
                          }
                          setActiveTab(itemZoop);
                          onClose();
                        }}
                      >
                        <Text fontSize="16px">{itemZoop.name}</Text>
                        {activeTab.value === index && (
                          <Icon as={AiOutlineRight} />
                        )}
                      </Flex>
                      {index + 1 === enumTabsZoop.itensZoop.length && (
                        <Divider mb="10px" mt="10px" />
                      )}
                    </>
                  );
                })}
              </Box>
            </Flex>
          </ModalBody>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
