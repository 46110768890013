export type SituacaoBaixa = 1 | 2 | 3;

const TipoSituacaoBaixa = {
  TODAS: 1,
  CONTAS_BAIXADAS: 2,
  CONTAS_EM_ABERTO: 3,

  properties: {
    1: { name: 'Todas', value: 1 },
    2: { name: 'Contas baixadas', value: 2 },
    3: { name: 'Contas em aberto', value: 3 },
    4: { name: 'À receber', value: 4 },
    5: { name: 'Recebida em aberto', value: 5 },
    6: { name: 'À compensar', value: 6 },
    7: { name: 'Compensada', value: 7 },
  },
};
export default TipoSituacaoBaixa;
