import { useState } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  useDisclosure,
  Flex,
  useMediaQuery,
  ModalCloseButton,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { isMobile } from 'react-device-detect';

import ModelosFiscaisEnum from 'constants/enum/fiscal/modelosFiscais';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import TabButton from 'components/PDV/Layout/MenuLateral/TabButton';
import { NFCeMenuIcon, NFeMenuIcon } from 'icons';

type PromiseModal = {
  modeloFiscal: number | null;
  sucesso: boolean;
};

type ModalEscolherModeloFiscalProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<PromiseModal>;

export const ModalEscolherModeloFiscal = create<ModalEscolherModeloFiscalProps>(
  ({ onResolve, onReject, ...rest }) => {
    const [isLoading, setIsLoading] = useState(false);

    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    const emitirNota = (modeloFiscal: number) => {
      setIsLoading(true);
      onResolve({ modeloFiscal, sucesso: true });
      onClose();
      setIsLoading(false);
    };

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        size={isMobile ? 'full' : '2xl'}
      >
        {isLoading && <LoadingPadrao />}
        <ModalContent
          bg="primary.500"
          borderRadius={['0px', '0px', 'md']}
          marginTop={['0px', '0px', '3.75rem']}
          marginBottom={['0px', '0px', '3.75rem']}
          w={['full', 'full', '640px']}
          h={['full', 'full', '300px']}
        >
          <ModalCloseButton color="purple.200" />
          <ModalBody px="40px" mb="20px" pt="0" pb="0">
            <Flex
              w="full"
              alignItems="center"
              justifyContent="center"
              h="full"
              flexDir="column"
              gap="24px"
            >
              <TabButton
                icon={NFCeMenuIcon}
                title="NFC-e"
                subtitle="Emitir nota fiscal do consumidor"
                isFirst
                onClick={() => {
                  emitirNota(ModelosFiscaisEnum.NFCe);
                }}
                isActive
              />
              <TabButton
                icon={NFeMenuIcon}
                title="NF-e"
                subtitle="Emitir nota fiscal eletrônica"
                onClick={() => {
                  emitirNota(ModelosFiscaisEnum.NFe);
                }}
                isActive={false}
              />
            </Flex>
          </ModalBody>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
