import { HStack, Icon, Text, Flex, GridItem, Box } from '@chakra-ui/react';
import { FiChevronLeft } from 'react-icons/fi';

import { useEmissaoNotaFiscalContext } from 'store/NotaFiscal/EmissaoNotaFiscal';
import getOptionsByEnum from 'helpers/format/getOptionsByEnum';
import OperacaoIntermediadorEnum from 'constants/enum/fiscal/operacaoIntermediador';

import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import { DateInput } from 'components/update/Input/DateInput';
import InputCpfCnpj from 'components/PDV/InputCpfCnpj';
import Input from 'components/PDV/Input';
import TextArea from 'components/PDV/Textarea';

import { NotaFiscalExibirTela } from '../validation';
import { CardHeader } from '../Components/CardHeader';

type DadosNotaProps = {
  isMultipleVendas: boolean;
};

export const DadosNota = ({ isMultipleVendas }: DadosNotaProps) => {
  const { handlePrevTela } = useEmissaoNotaFiscalContext();
  return (
    <>
      <CardHeader mb="0" px="40px">
        <HStack spacing="20px">
          <Icon
            as={FiChevronLeft}
            cursor="pointer"
            onClick={() => handlePrevTela(NotaFiscalExibirTela.TELA_INICIAL)}
            boxSize="24px"
            mr="20px"
          />

          <Text color="black" fontWeight="semibold" fontSize="20px">
            Editar dados gerais da nota
          </Text>
        </HStack>
      </CardHeader>
      {isMultipleVendas && (
        <Flex alignItems="center" px="40px" bg="yellow.500" h="40px">
          <span
            style={{
              fontWeight: 'bold',
              marginRight: '5px',
            }}
          >
            Aviso:
          </span>{' '}
          As informações preenchidas como dados adicionais serão aplicadas para
          todas as notas.
        </Flex>
      )}
      <SimpleGridForm mt="28px" px="40px">
        <GridItem colSpan={12}>
          <Box maxWidth={['full', 'full', '65.6%']}>
            <SelectPadrao
              name="intermediador"
              id="intermediador"
              placeholder="Selecione"
              label="Intermediador"
              options={getOptionsByEnum(OperacaoIntermediadorEnum)}
              colSpan={7}
            />
          </Box>
        </GridItem>
        <GridItem colSpan={[12, 12, 4]}>
          <DateInput
            name="dataEmissao"
            label="Data de emissão"
            id="dataEmissao"
            maxDate={new Date()}
          />
        </GridItem>
        <GridItem colSpan={[12, 12, 4]}>
          <InputCpfCnpj
            id="cpfCnpj"
            name="cpfCnpj"
            label="CNPJ do intermediador"
            w="full"
            placeholder="000.000.000-00"
          />
        </GridItem>
        <GridItem colSpan={[12, 12, 4]}>
          <Input
            name="pedidoExterno"
            placeholder="---"
            isDisabled
            id="pedidoExterno"
            label="Número do pedido externo"
          />
        </GridItem>
        <GridItem colSpan={12}>
          <TextArea
            id="dadosAdicionais"
            name="dadosAdicionais"
            label="Dados adicionais"
          />
        </GridItem>
      </SimpleGridForm>
    </>
  );
};
