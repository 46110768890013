import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export type CamposPersonalizados = {
  campoPersonalizadoId: string;
  valor: string;
};

type ClienteSelectProps = {
  label: string;
  value: string;
};

export type FormData = {
  tipoRelatorio: number | null;
  nomeSkuCodigoExternoBarrasGtinEan: string;
  dataEmissaoInicio: Date;
  dataEmissaoFim: Date;
  tipoEstoque: number;
  statusConsulta: number;
  cores: string;
  tamanhos: string;
  categoriasProduto: string;
  marcas: string;
  tags: string;
  camposPersonalizados: CamposPersonalizados[];
  vendedorId: string;
  clienteFornecedorId: ClienteSelectProps | null;
};

const schema = yup.object().shape({
  tipoRelatorio: yup
    .number()
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);
