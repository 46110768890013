import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import { isValidCnpj, isValidCpf } from 'helpers/validation/IsValidCpfCnpj';
import getMinDataNascimento from 'helpers/data/getMinDataNascimento';

export const maxDataAtual = () => {
  const date = new Date();
  return date.toISOString().slice(0, 10);
};

const schemaCliente = yup.object().shape({
  nome: yup
    .string()
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .max(60, ConstanteMensagemValidacao.MAX_LENGTH_60),
  cpfCnpj: yup
    .string()
    .nullable()
    .test('cpfCnpj', ConstanteMensagemValidacao.CNPJ_INVALIDO, (value) => {
      if (value) {
        if (value.length > 14) return isValidCnpj(value);
      }

      return true;
    })
    .test('cpfCnpj', ConstanteMensagemValidacao.CPF_INVALIDO, (value) => {
      if (value) {
        if (value.length <= 14) return isValidCpf(value);
      }

      return true;
    }),
  cidade: yup
    .string()
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  pais: yup
    .string()
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  email: yup
    .string()
    .nullable()
    .email(ConstanteMensagemValidacao.EMAIL_INVALIDO),
  telefone: yup
    .string()
    .nullable()
    .notRequired()
    .test('telefone', ConstanteMensagemValidacao.TELEFONE_INVALIDO, (value) => {
      if (value) {
        const testSchema = yup.string().min(14).max(15);

        return testSchema.isValidSync(value);
      }

      return true;
    }),
  dataNascimento: yup
    .date()
    .nullable()
    .default(null)
    .test(
      'dataNascimento',
      ConstanteMensagemValidacao.IDADE_MAXIMA_110,
      (value) => {
        if (value) {
          const testSchema = yup.date().min(getMinDataNascimento());

          return testSchema.isValidSync(value);
        }

        return true;
      }
    )
    .test('dataNascimento', ConstanteMensagemValidacao.DATA_ATUAL, (value) => {
      if (value) {
        const testSchema = yup.date().max(maxDataAtual());

        return testSchema.isValidSync(value);
      }

      return true;
    }),

  celular: yup
    .string()
    .nullable()
    .notRequired()
    .test('telefone', ConstanteMensagemValidacao.TELEFONE_INVALIDO, (value) => {
      if (value) {
        const testSchema = yup.string().min(14).max(15);

        return testSchema.isValidSync(value);
      }

      return true;
    }),
  emailNfe: yup
    .string()
    .nullable()
    .email(ConstanteMensagemValidacao.EMAIL_INVALIDO),
  categoriaCliente: yup
    .object()
    .shape({
      value: yup.string().nullable().default(null),
      label: yup.string().nullable().default(null),
    })
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  cep: yup
    .string()
    .nullable()
    .test('cep', ConstanteMensagemValidacao.CEP_INVALIDO, (value) => {
      if (value) {
        const testSchema = yup.string().min(9).max(9);

        return testSchema.isValidSync(value);
      }

      return true;
    }),
});

const schemaFornecedor = yup.object().shape({
  nome: yup
    .string()
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .max(60, ConstanteMensagemValidacao.MAX_LENGTH_60),
  cpfCnpj: yup
    .string()
    .nullable()
    .test('cpfCnpj', ConstanteMensagemValidacao.CNPJ_INVALIDO, (value) => {
      if (value) {
        if (value.length > 14) return isValidCnpj(value);
      }

      return true;
    })
    .test('cpfCnpj', ConstanteMensagemValidacao.CPF_INVALIDO, (value) => {
      if (value) {
        if (value.length <= 14) return isValidCpf(value);
      }

      return true;
    }),
  telefone: yup
    .string()
    .nullable()
    .notRequired()
    .test('telefone', ConstanteMensagemValidacao.TELEFONE_INVALIDO, (value) => {
      if (value) {
        const testSchema = yup.string().min(14).max(15);

        return testSchema.isValidSync(value);
      }

      return true;
    }),
  celular: yup
    .string()
    .nullable()
    .notRequired()
    .test('telefone', ConstanteMensagemValidacao.TELEFONE_INVALIDO, (value) => {
      if (value) {
        const testSchema = yup.string().min(14).max(15);

        return testSchema.isValidSync(value);
      }

      return true;
    }),
  cidade: yup
    .string()
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  pais: yup
    .string()
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  email: yup
    .string()
    .nullable()
    .email(ConstanteMensagemValidacao.EMAIL_INVALIDO),
  emailNfe: yup
    .string()
    .nullable()
    .email(ConstanteMensagemValidacao.EMAIL_INVALIDO),
  cep: yup
    .string()
    .nullable()
    .test('cep', ConstanteMensagemValidacao.CEP_INVALIDO, (value) => {
      if (value) {
        const testSchema = yup.string().min(9).max(9);

        return testSchema.isValidSync(value);
      }

      return true;
    }),
});

export const yupResolverCliente = yupResolverInstance(schemaCliente);

export const yupResolverFornecedor = yupResolverInstance(schemaFornecedor);

export const useForm = useFormInstance;
