import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import ModelosFiscaisExportacaoEnum from 'constants/enum/fiscal/modelosFiscaisExportacao';

export type FormData = {
  email: string;
  modeloFiscal: number;
  mes: number;
  ano: number;
};

const today = new Date();

today.setMonth(today.getMonth() - 1);

const defaultMonth = today.getMonth() + 1;
const defaultYear = today.getFullYear();

export const defaultValues = {
  mes: defaultMonth,
  ano: defaultYear,
  modeloFiscal: ModelosFiscaisExportacaoEnum.TODOS,
};

const schema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  mes: yup
    .number()
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  ano: yup
    .number()
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);
