import { useFormContext } from 'react-hook-form';
import { Box, Table, Tbody, Td, Th, Text, Thead, Tr } from '@chakra-ui/react';

import SelectPadrao from 'components/PDV/Select/SelectPadrao';

import {
  parcelamentoTefOptions,
  operacaoTefOptions,
  tipoCartaoOptions,
} from './validationForms';
import { useFormaRecebimento } from '../hooks';
import { FormData } from '../validationForms';
import { useVincularFormaRecebimento } from './hooks';

export const VincularFormaRecebimento = () => {
  const formMethods = useFormContext<FormData>();

  const { listPagamentoCartao, tefWatch } = useFormaRecebimento(formMethods);

  const { listCredenciadoraCartao } = useVincularFormaRecebimento();

  return (
    <Box
      mt="24px"
      borderWidth="1px"
      borderRadius="5px"
      borderColor="gray.200"
      bg="gray.50"
    >
      <Box p="24px" overflow="auto" borderRadius="5px" bg="gray.50">
        <Table minW="800px" variant="simple" size="sm">
          <Thead>
            <Tr
              sx={{
                '& th': {
                  fontSize: '12px',
                  color: 'gray.500',
                  paddingBottom: '2px',
                },
              }}
            >
              <Th>Forma de recebimento</Th>
              <Th isNumeric>Credênciadora cartão</Th>
              <Th w="18%" isNumeric>
                Operação Tef
              </Th>
              <Th w="21%" isNumeric>
                Parcelamento Tef
              </Th>
              <Th w="21%" isNumeric>
                Tipo cartão
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {listPagamentoCartao.map((formaRecebimentoItem, index) => {
              return (
                <Tr
                  sx={{
                    '& td': {
                      fontSize: '12px',
                    },
                  }}
                >
                  <Td color="black">
                    <li>
                      <Text ml="-6px" display="inline">
                        {formaRecebimentoItem.label}
                      </Text>
                    </li>
                  </Td>
                  <Td>
                    <SelectPadrao
                      isDisabled={!tefWatch}
                      name={`credenciadoraCartaoId.${index}`}
                      id={`credenciadoraCartaoId.${index}`}
                      options={listCredenciadoraCartao}
                    />
                  </Td>
                  <Td>
                    <SelectPadrao
                      isDisabled={!tefWatch}
                      name={`operacaoTEF.${index}`}
                      id={`operacaoTEF.${index}`}
                      options={operacaoTefOptions}
                    />
                  </Td>
                  <Td>
                    <SelectPadrao
                      isDisabled={!tefWatch}
                      name={`parcelamentoTEF.${index}`}
                      id={`parcelamentoTEF.${index}`}
                      options={parcelamentoTefOptions}
                    />
                  </Td>
                  <Td>
                    <SelectPadrao
                      isDisabled={!tefWatch}
                      name={`tipoCartao.${index}`}
                      id={`tipoCartao.${index}`}
                      options={tipoCartaoOptions}
                    />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};
