import {
  useImperativeHandle,
  useCallback,
  useState,
  useRef,
  useEffect,
} from 'react';
import {
  Box,
  Flex,
  Icon,
  Text,
  Checkbox,
  useMediaQuery,
} from '@chakra-ui/react';
import { GradeTamanhosIcon, ListaProdutosIcon } from 'icons';
import { FormProvider, useForm } from 'react-hook-form';
import { FiChevronLeft } from 'react-icons/fi';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import { useTrayEtapasContext } from 'store/Tray';
import { shallowEqual } from 'helpers/validation/shallowEqual';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { enumPossuiVariacao } from 'constants/enum/enumPossuiVariacao';
import { IdentificacaoEtapasTray } from 'constants/enum/IdentificacaoEtapasTray';

import Loading from 'components/Layout/Loading/LoadingPadrao';
import { SearchInput } from 'components/update/Input/SearchInput';
import VirtualizedList, {
  PaginationVirtualizedListProps,
  VirtualizedListRefProps,
} from 'components/update/VirtualizedList';

import { ModalSelecionarVariacao } from './ModalSelecionarVariacao';
import { DrawerBuscaAvancada } from '../../PainelAdministradorTray/ProdutosTray/DrawerBuscaAvancada';
import {
  FiltrosProps,
  defaultValue,
} from '../../PainelAdministradorTray/ProdutosTray/validationForms';
import { ModalEnviarProdutos } from './ModalEnviarProdutos';

type ProdutoCoresProps = {
  produtoCorTamanhos: {
    produtoCorTamanho: {
      produtoCorTamanhoId: string;
    };
  }[];
};

type ListarProdutoProps = {
  ativo: boolean;
  id: string;
  nome: string;
  tipoProduto: number;
  listaProdutoCorTamanhoId: string[];
  produtoCores: ProdutoCoresProps[];
  isChecked: boolean;
};

export type ProdutoSelecionado = {
  produtoId: string;
  listaProdutoCorTamanhoId: string[];
  descricao: string;
};

export const ProdutoSistema = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [filtros, setFiltros] = useState<FiltrosProps>(defaultValue);
  const [listarProdutos, setListarProduto] = useState<ListarProdutoProps[]>([]);
  const [listProdutosSelecionados, setListProdutosSelecionados] = useState<
    ProdutoSelecionado[]
  >([]);
  const [selecionarTodosProdutos, setSelecionarTodosProdutos] = useState(false);

  const refProdutos = useRef<VirtualizedListRefProps>(
    {} as VirtualizedListRefProps
  );

  const {
    ref,
    setActiveStep,
    isOpenBuscaAvancada,
    setIsOpenBuscaAvancada,
    setIsProdutoSelecionado,
    setHasFiltros,
    setIsProdutoSistema,
    listStorage,
    listProdutoLocalStorage,
  } = useTrayEtapasContext();

  const formMethods = useForm();

  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

  const {
    formState: { isDirty },
    handleSubmit,
  } = formMethods;

  const amountProdutosSelecionados = listProdutosSelecionados.length;

  const handleLimparSelecao = () => {
    setListProdutosSelecionados([]);
    setListarProduto((prev) =>
      prev.map((item) => ({
        ...item,
        isChecked: false,
      }))
    );
  };

  const reload = () => {
    refProdutos.current.reload();
  };

  const filtersSubmit = useCallback((filters: FiltrosProps) => {
    setFiltros(filters);
    setListarProduto([]);
    reload();
  }, []);

  const handleVoltar = useCallback(() => {
    setActiveStep(IdentificacaoEtapasTray.TIPO_CADASTRO);
  }, [setActiveStep]);

  const exportarProduto = useCallback(() => {
    const produtosSalvos = listProdutoLocalStorage();

    localStorage.setItem(
      'listarProdutoStorage',
      JSON.stringify(
        produtosSalvos.length > 0
          ? [...listProdutosSelecionados, ...produtosSalvos]
          : listProdutosSelecionados
      )
    );
    handleLimparSelecao();

    toast.success('A exportação dos produtos foram realizadas com sucesso');

    setListarProduto((prev) => [
      ...(prev
        .map((itemPrev) => {
          const preodutoFoiAdicionado = listProdutosSelecionados.some(
            (item) => item.produtoId === itemPrev.id
          );

          if (!preodutoFoiAdicionado) {
            return {
              ...itemPrev,
            };
          }
          return undefined;
        })
        .filter(Boolean) as ListarProdutoProps[]),
    ]);
  }, [listProdutosSelecionados, listProdutoLocalStorage]);

  const handleExportarProduto = useCallback(
    async (notificar: boolean) => {
      setIsLoading(true);
      const response = await api.put<void, ResponseApi<boolean>>(
        ConstanteEnderecoWebservice.INTEGRACOES_TRAY_EXPORTAR_PRODUTO,
        {
          listaProduto: listProdutoLocalStorage().map((item) => ({
            produtoId: item.produtoId,
            listaProdutoCorTamanhoId: item.listaProdutoCorTamanhoId,
          })),
          notificar,
        }
      );
      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }

        if (response.sucesso) {
          localStorage.removeItem('listarProdutoStorage');
          if (notificar) {
            setIsProdutoSistema(true);
            setActiveStep(IdentificacaoEtapasTray.ENVIANDO_ZENDAR);
          } else {
            exportarProduto();
          }
          setIsLoading(false);
        }
        setIsLoading(false);
      }

      setIsLoading(false);
    },
    [
      exportarProduto,
      listProdutoLocalStorage,
      setActiveStep,
      setIsProdutoSistema,
    ]
  );

  const adicionarItens = useCallback(
    async (dataPagination: PaginationVirtualizedListProps) => {
      setIsLoading(true);

      const response = await api.post<
        void,
        ResponseApi<{ registros: ListarProdutoProps[] }>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.INTEGRACAO_TRAY_OBTER_LISTA_PRODUTO_PAGINADA,
          {
            currentPage: dataPagination.paginaAtual,
            orderColumn: 'Nome',
            orderDirection: dataPagination.direcaoOrdenacao,
            pageSize: dataPagination.tamanhoPagina,
          }
        ),
        {
          ...filtros,

          nomeReferencia: filtros.nomeReferencia,
        }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }

        if (response.sucesso && response.dados.registros.length > 0) {
          setListarProduto((prev) => [
            ...prev,
            ...(response.dados.registros
              .map((itemProduto) => {
                const produtoStorage = listProdutoLocalStorage();

                const produtoJaExiste = prev.some(
                  (itemPrev) => itemPrev.id === itemProduto.id
                );

                const isProdutoLocalStorage = produtoStorage.some(
                  (item) => item.produtoId === itemProduto.id
                );

                const produtoFoiSelecionado = listProdutosSelecionados.some(
                  (item) => item.produtoId === itemProduto.id
                );

                if (!isProdutoLocalStorage && !produtoJaExiste) {
                  const listaProdutoCorTamanhoId = [] as string[];

                  if (itemProduto.tipoProduto === enumPossuiVariacao.VARIACAO) {
                    (itemProduto.produtoCores || []).forEach(
                      (itemProdutoCor) => {
                        (itemProdutoCor.produtoCorTamanhos || []).forEach(
                          (itemProdutoCorTamanho) => {
                            listaProdutoCorTamanhoId.push(
                              itemProdutoCorTamanho.produtoCorTamanho
                                .produtoCorTamanhoId
                            );
                          }
                        );
                      }
                    );
                  }

                  return {
                    ...itemProduto,
                    listaProdutoCorTamanhoId,
                    isChecked: produtoFoiSelecionado,
                  };
                }

                return undefined;
              })
              .filter(Boolean) as ListarProdutoProps[]),
          ]);
        }

        setIsLoading(false);
      }
      setIsLoading(false);
    },
    [filtros, listProdutoLocalStorage, listProdutosSelecionados]
  );

  const handleAbrirVariacoes = useCallback(
    async (produto: ListarProdutoProps) => {
      const data = listProdutosSelecionados.find(
        (item) => item.produtoId === produto.id
      );

      const response = await ModalSelecionarVariacao({
        id: produto.id,
        nomeProduto: produto.nome,
        dataVariacoes: data?.listaProdutoCorTamanhoId,
      });

      setListProdutosSelecionados((prev) =>
        prev.map((item) => ({
          ...item,
          listaProdutoCorTamanhoId:
            produto.id === item.produtoId
              ? response
              : item.listaProdutoCorTamanhoId,
        }))
      );
    },
    [listProdutosSelecionados]
  );

  const handleToggleSelecionarProduto = (item: ListarProdutoProps) => {
    const newListProduto = [...listProdutosSelecionados];

    setListarProduto((prev) =>
      prev.map((produto) => ({
        ...produto,
        isChecked:
          produto.id === item.id ? !produto.isChecked : produto.isChecked,
      }))
    );

    const produtoJaExiste = newListProduto.some(
      (produto) => produto.produtoId === item.id
    );

    if (produtoJaExiste) {
      const index = newListProduto.findIndex(
        (produto) => produto.produtoId === item.id
      );

      newListProduto.splice(index, 1);
      setListProdutosSelecionados(newListProduto);
    } else {
      setListProdutosSelecionados((prev) => [
        ...prev,
        {
          listaProdutoCorTamanhoId: item.listaProdutoCorTamanhoId,
          produtoId: item.id,
          descricao: item.nome,
        },
      ]);
    }
  };

  function handleToggleSelecionarTodosProdutos() {
    setListarProduto((prev) =>
      prev.map((produto) => ({
        ...produto,
        isChecked: !selecionarTodosProdutos,
      }))
    );
    if (!selecionarTodosProdutos) {
      setListProdutosSelecionados(
        listarProdutos.map((itemProduto) => ({
          listaProdutoCorTamanhoId: itemProduto.listaProdutoCorTamanhoId,
          produtoId: itemProduto.id,
          descricao: itemProduto.nome,
        }))
      );
    } else {
      setListProdutosSelecionados([]);
    }
    setSelecionarTodosProdutos(!selecionarTodosProdutos);
  }

  const handleReset = handleSubmit((data) => {
    const filtersIsDirty = !shallowEqual(data, filtros || {});

    if (filtersIsDirty) {
      setFiltros((prev) => ({
        ...prev,
        nomeReferencia: data?.nomeReferencia || '',
      }));
      setListarProduto([]);
      refProdutos?.current?.reload();
    }
  });

  const handleEnviarProdutos = () => {
    handleExportarProduto(true);
  };

  useImperativeHandle(ref, () => ({
    handleConfirmarProdutoSelecionado: () => exportarProduto(),
    handleAvancar: () => {
      ModalEnviarProdutos({
        amountProdutos: listProdutoLocalStorage().length,
        handleEnviarProdutos: () => handleEnviarProdutos(),
        listProdutos: listProdutoLocalStorage(),
        reload,
      });
    },
  }));

  useEffect(() => {
    setIsProdutoSelecionado(amountProdutosSelecionados > 0);
  }, [amountProdutosSelecionados, setIsProdutoSelecionado]);

  useEffect(() => {
    setHasFiltros(isDirty);
  }, [isDirty, setHasFiltros]);

  useEffect(() => {
    reload();
  }, []);

  return (
    <FormProvider {...formMethods}>
      {isLoading && <Loading />}
      <Flex
        mb="24px"
        h="50px"
        borderBottomWidth="1px"
        borderColor="gray.200"
        justifyContent="space-between"
        w="full"
        alignItems="center"
      >
        {amountProdutosSelecionados > 0 ? (
          <Flex
            flexDirection={['column', 'row']}
            justifyContent="center"
            alignItems="baseline"
          >
            <Flex justifyContent="center" alignItems="baseline">
              <Box ml={['', '20px']} mr={['10px', '10px', '20px']}>
                <Checkbox
                  onChange={handleToggleSelecionarTodosProdutos}
                  isChecked={selecionarTodosProdutos}
                  colorScheme="primary"
                />
              </Box>
              <Text
                textAlign="center"
                fontSize={['20px', '20px', '28px']}
                fontWeight="bold"
                whiteSpace="nowrap"
              >
                {amountProdutosSelecionados === 1
                  ? `${amountProdutosSelecionados} item selecionado`
                  : `${amountProdutosSelecionados} itens selecionados`}
              </Text>
            </Flex>
            <Text
              textAlign="center"
              fontSize="14px"
              ml="24px"
              mb={['10px', '']}
              cursor="pointer"
              color="red.500"
              onClick={handleLimparSelecao}
              textDecoration="underline"
              whiteSpace="nowrap"
            >
              Limpar seleção
            </Text>
          </Flex>
        ) : (
          <Flex alignItems="center">
            {listStorage === null && (
              <Box
                h="full"
                boxSize={['20px', '20px', '']}
                onClick={handleVoltar}
                cursor="pointer"
              >
                <Icon
                  color="black"
                  display={['none', 'none', 'flex']}
                  boxSize="25px"
                  onClick={handleVoltar}
                  cursor="pointer"
                  as={FiChevronLeft}
                />
              </Box>
            )}
            <Flex>
              <Flex
                ml={['', '', '10px']}
                mr="5px"
                justifyContent="center"
                w="full"
                alignItems="center"
                cursor="pointer"
              >
                <Icon
                  boxSize="40px"
                  size={40}
                  color="primary.50"
                  as={ListaProdutosIcon}
                />
              </Flex>
              <Text
                textAlign="center"
                fontSize={['20px', '20px', '28px']}
                fontWeight="bold"
                whiteSpace="nowrap"
                marginTop={['8px', '8px', '12px']}
              >
                Lista de produtos
              </Text>
            </Flex>
          </Flex>
        )}
      </Flex>
      <Flex w={['100%', '100%', '80%']} mb="16px">
        <SearchInput
          isRightElement
          isPostionCodigoBarrasRight
          type="search"
          placeholder="Buscar o produto por Nome ou Referência"
          onEnterKeyPress={() => handleReset()}
          isDisabled={isLoading}
          id="nomeReferencia"
          name="nomeReferencia"
        />
      </Flex>

      <Box minW="700px" w="full" overflowX="auto">
        <VirtualizedList
          containerHeight={isLargerThan700 ? 490 : 270}
          ref={refProdutos}
          itemHeight={48}
          colorBgGradient="gray.50"
          items={listarProdutos}
          handleOnClick={adicionarItens}
          tamanhoPagina={25}
          quantidadeItems={listarProdutos.length}
          key="virtualizedListTray"
          render={(items, height) => (
            <>
              {items.map((produto) => {
                return (
                  <Flex
                    color="black"
                    justifyContent="space-between"
                    alignItems="center"
                    mb="5px"
                    h={height}
                    bg={produto.isChecked ? 'purple.50' : 'white'}
                    p="10px"
                    pl="20px"
                    pr={['10px', '20px', '20px']}
                    borderRadius="6px"
                    key={produto.id}
                    _hover={{
                      boxShadow: '0px 0px 4px rgba(85, 2, 178, 0.5)',
                    }}
                  >
                    <Flex justifyContent="center" alignItems="center">
                      <Checkbox
                        mb="0"
                        isChecked={produto.isChecked}
                        onChange={() => {
                          handleToggleSelecionarProduto(produto);
                        }}
                        colorScheme="primary"
                      />
                      <Box>
                        <Text
                          fontSize="14px"
                          mt="3px"
                          ml="10px"
                          color="gray.700"
                        >
                          {produto.nome}
                        </Text>
                      </Box>
                    </Flex>
                    {produto.tipoProduto === enumPossuiVariacao.VARIACAO && (
                      <Flex
                        justifyContent="center"
                        w="24px"
                        h="24px"
                        alignItems="center"
                        cursor={produto.isChecked ? 'pointer' : 'not-allowed'}
                        _hover={{ bg: 'gray.100' }}
                        _active={{ bg: 'gray.100' }}
                        borderRadius="5px"
                        p="5px"
                        onClick={() => {
                          if (produto.isChecked) {
                            handleAbrirVariacoes(produto);
                          }
                        }}
                        borderColor="gray.600"
                        borderWidth="1px"
                      >
                        <Icon as={GradeTamanhosIcon} fontSize="15px" />
                      </Flex>
                    )}
                  </Flex>
                );
              })}
            </>
          )}
        />
      </Box>

      <DrawerBuscaAvancada
        currentFilters={filtros}
        filtersSubmit={filtersSubmit}
        filtrosReset={defaultValue}
        isOpen={isOpenBuscaAvancada}
        onClose={() => setIsOpenBuscaAvancada(false)}
      />
    </FormProvider>
  );
};
