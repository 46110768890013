import React, { useEffect, useState, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import {
  useBreakpointValue,
  useMediaQuery,
  Box,
  Flex,
  IconButton,
  Icon,
  useDisclosure,
  Text,
} from '@chakra-ui/react';
import { FiXCircle } from 'react-icons/fi';

import PlanoContratacaoEnum from 'constants/enum/planoContratacao';
import ConstanteRotasPDV from 'constants/rotasPDV';
import auth from 'modules/auth';
import MenuContextProvider from 'store/PDV/Menu';
import { useProdutosConsignadosContext } from 'store/PDV/ProdutosConsignados';

import Layout from 'components/PDV/Layout';
import NavHeaderButton from 'components/PDV/Layout/NavHeader/Button';
import { ModalConsignacao } from 'components/PDV/Modal/ModalConsignacao';
import { LeitorCodigoBarrasIcon } from 'icons';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { InternalHeaderProdutosConsignados } from './InternalHeader';
import { ListarProdutosConsignados } from './ListarItens';
import { ConsignacaoFooter } from './Footer';
import { ClienteProps, formDefaultValues } from './types/validationForms';
import { CategoriaClienteIcon } from '../Home/styles';
import { ModalCodigoBarras } from '../Lancamento/AdicionarEditarItem/ModalCodigoBarras';

type FormData = {
  quantidade: number[];
  codigoBarras: string | null;
};

export const ProdutosConsignados = () => {
  const [abrirModalAutomaticamente, setAbrirModalAutomaticamente] = useState(
    true
  );
  const [clienteConsignacao, setClienteConsignacao] = useState<ClienteProps>();
  const [sairConsignacao, setSairConsignacao] = useState(false);

  const [isLargerThan900] = useMediaQuery('(max-width: 900px)');

  const planoAtual = auth.getPlano();
  const planoStart = PlanoContratacaoEnum.START;

  const formMethods = useForm<FormData>({
    defaultValues: formDefaultValues,
  });
  const history = useHistory();
  const isInternalHeaderVisible = useBreakpointValue({ base: false, md: true });

  const {
    produtosConsignados,
    setClienteFornecedor,
    handleListarProdutosConsignados,
    isLoading,
    produtosConsignadosJaForamDevolvidos,
    setCodigoBarrasVersaoMobile,
    existeProdutosNoClienteSelecionado,
    setExisteProdutosNoClienteSelecionado,
    setProdutosConsignados,
    setExisteCodigoBarras,
  } = useProdutosConsignadosContext();

  const {
    isOpen: isModalCodigoBarrasOpen,
    onOpen: onModalCodigoBarrasOpen,
    onClose: onModalCodigoBarrasClose,
  } = useDisclosure();

  const valorQuantidadeProduto = useCallback(() => {
    return formMethods.watch('quantidade') || [];
  }, [formMethods]);

  const quantidade = valorQuantidadeProduto();

  const handleVoltarParaPdv = useCallback(async () => {
    await setSairConsignacao(true);
    await setProdutosConsignados([]);

    history.push(ConstanteRotasPDV.PDV_HOME);
  }, [history, setProdutosConsignados]);

  const handleOnClose = useCallback(() => {
    handleVoltarParaPdv();
  }, [handleVoltarParaPdv]);

  const handleListarItensConsignados = useCallback(
    async (clienteFornecedorId: string) => {
      await handleListarProdutosConsignados(clienteFornecedorId);
    },
    [handleListarProdutosConsignados]
  );

  useEffect(() => {
    const { pathname } = window.location;
    if (
      pathname === ConstanteRotasPDV.PDV_PRODUTOS_CONSIGNADOS &&
      abrirModalAutomaticamente &&
      planoAtual !== planoStart
    ) {
      ModalConsignacao({
        setClienteConsignacao,
        setExisteCodigoBarras,
        handleListarItensConsignados,
        setAbrirModalAutomaticamente,
        handleOnClose,
      });
    }
  }, [
    abrirModalAutomaticamente,
    planoAtual,
    planoStart,
    setExisteCodigoBarras,
    handleOnClose,
    handleListarItensConsignados,
  ]);

  useEffect(() => {
    const { pathname } = window.location;
    if (
      !sairConsignacao &&
      produtosConsignados.length === 0 &&
      pathname === ConstanteRotasPDV.PDV_PRODUTOS_CONSIGNADOS &&
      !produtosConsignadosJaForamDevolvidos &&
      clienteConsignacao &&
      existeProdutosNoClienteSelecionado
    ) {
      setAbrirModalAutomaticamente(true);
      setExisteProdutosNoClienteSelecionado(false);
      toast.warning('O cliente não possui nenhum item consignado.');
    }
  }, [
    clienteConsignacao,
    sairConsignacao,
    produtosConsignadosJaForamDevolvidos,
    existeProdutosNoClienteSelecionado,
    setExisteProdutosNoClienteSelecionado,
    produtosConsignados.length,
  ]);

  useEffect(() => {
    setClienteFornecedor(clienteConsignacao);
  }, [clienteConsignacao, setClienteFornecedor]);

  return (
    <FormProvider {...formMethods}>
      <MenuContextProvider
        alterarItensQueSeraoMostrados
        items={[
          <NavHeaderButton
            icon={CategoriaClienteIcon}
            titleColor="secondary.400"
            title={clienteConsignacao?.label || ''}
            onClick={() => {}}
          />,
          <NavHeaderButton
            icon={FiXCircle}
            title="Cancelar consignação"
            onClick={async () => {
              await handleVoltarParaPdv();
            }}
          />,
        ]}
        asAccordion={!isInternalHeaderVisible}
      >
        <Layout
          background="gray.50"
          bodyOverflow="hidden"
          FooterContent={
            !isLargerThan900 && (
              <ConsignacaoFooter
                handleVoltarParaPdv={handleVoltarParaPdv}
                quantidade={quantidade}
                clienteConsignacao={clienteConsignacao}
              />
            )
          }
        >
          <InternalHeaderProdutosConsignados
            quantidade={quantidade}
            handleVoltarParaPdv={handleVoltarParaPdv}
          />

          {isLargerThan900 && (
            <Flex
              justifyContent="left"
              onClick={onModalCodigoBarrasOpen}
              w="200px"
              mt="17px"
              alignItems="center"
              cursor="pointer"
              ml="24px"
            >
              <IconButton
                aria-label="Leitor de códigos de barras"
                icon={<Icon as={LeitorCodigoBarrasIcon} boxSize="6" />}
                variant="link"
                minW="0"
                color="purple.500"
                p="0"
                mr="10px"
                borderRadius="sm"
              />
              <Text
                textDecoration="underline"
                color="purple.500"
                fontSize="16px"
                letterSpacing="1px"
                display="inline-block"
                _hover={{
                  textShadow: '0 0 .01px black',
                }}
              >
                Ler código de barras
              </Text>
            </Flex>
          )}

          <Box
            mt="17px"
            bg={isLargerThan900 ? 'white' : ''}
            h="full"
            overflow="hidden"
            borderColor="gray.100"
          >
            <>
              {isLoading && <LoadingPadrao />}
              {produtosConsignados.length > 0 ? (
                <>
                  <ListarProdutosConsignados quantidade={quantidade} />
                  {isLargerThan900 && (
                    <ConsignacaoFooter
                      quantidade={quantidade}
                      handleVoltarParaPdv={handleVoltarParaPdv}
                      clienteConsignacao={clienteConsignacao}
                    />
                  )}
                </>
              ) : (
                <Flex pt="10px" pb="10px" bg="white">
                  <Text pl="25px">
                    Não foi possível encontrar produtos consignados
                  </Text>
                </Flex>
              )}
            </>
          </Box>
          <ModalCodigoBarras
            isOpen={isModalCodigoBarrasOpen}
            onClose={onModalCodigoBarrasClose}
            onCodigoBarrasScanned={async (codigoBarras) => {
              await setCodigoBarrasVersaoMobile('');
              await setCodigoBarrasVersaoMobile(codigoBarras);

              onModalCodigoBarrasClose();
            }}
          />
        </Layout>
      </MenuContextProvider>
    </FormProvider>
  );
};
