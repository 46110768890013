import React from 'react';
import {
  Divider,
  Text,
  Flex,
  VStack,
  useMediaQuery,
  Grid,
  GridItem,
} from '@chakra-ui/react';

import { CasasDecimais } from 'store/Padronizacao/Padronizacao';
import { DecimalMask } from 'helpers/format/fieldsMasks';

import { Produto } from '../validationForm';

interface ProdutoInfoProps {
  produto: Produto;
  casasDecimais: CasasDecimais;
}

export const ProdutoInfo = ({ produto, casasDecimais }: ProdutoInfoProps) => {
  const [isLargerThan1200] = useMediaQuery('(min-width: 1200px)');

  const { casasDecimaisQuantidade, casasDecimaisValor } = casasDecimais;

  return (
    <Flex flexDirection="column" h="full" borderBottomRadius="md" px="5">
      <Divider borderColor="gray.100" />
      <Grid
        templateColumns={{
          base: '1fr auto 1fr auto 1fr',
          lg: '1fr auto 1fr auto 1fr auto 1fr auto 1fr',
        }}
        templateRows={{ base: '1fr auto 1fr', md: '1fr' }}
        columnGap={{ base: '2', md: '4', lg: '6' }}
        rowGap={3}
        px="1"
        w="full"
        lineHeight="none"
        py={5}
        fontSize="xs"
        fontWeight="light"
      >
        <VStack w="150%" mr="20px" alignItems="flex-start">
          <Text as="span">
            Custo total do produto desta entrada:{' '}
            <Text
              as="span"
              bg="yellow.300"
              fontWeight="bold"
              display="inline-block"
              mt={{ base: '1', md: '1', lg: '0' }}
            >
              {`R$ ${DecimalMask(produto.custoTotal, 2, 2)}`}
            </Text>
          </Text>
          <Text as="span">
            Quantidade:{' '}
            <Text as="span" fontWeight="bold">
              {DecimalMask(
                produto.quantidadeTotal,
                casasDecimaisQuantidade,
                casasDecimaisQuantidade
              )}
            </Text>
          </Text>
        </VStack>
        <Divider
          orientation="vertical"
          h="10"
          ml="45px"
          borderColor="gray.100"
        />
        <VStack alignItems="flex-start">
          <Text>
            Valor do produto:{' '}
            <Text as="span" fontWeight="bold">
              {`R$ ${DecimalMask(
                produto.valorUnitarioEntrada,
                casasDecimaisValor,
                casasDecimaisValor
              )}`}
            </Text>
          </Text>

          <Text>
            Frete:{' '}
            <Text as="span" fontWeight="bold">
              {`R$ ${DecimalMask(produto.freteDistribuido, 2, 2)}`}
            </Text>
          </Text>
        </VStack>
        <Divider orientation="vertical" h="10" borderColor="gray.100" />
        <VStack w="full" alignItems="flex-start">
          <Text>
            Outras despesas:{' '}
            <Text as="span" fontWeight="bold">
              {`R$ ${DecimalMask(produto.outrasDespesasDistribuido, 2, 2)}`}
            </Text>
          </Text>

          <Text>
            Descontos:{' '}
            <Text as="span" fontWeight="bold">
              {`R$ ${DecimalMask(produto.descontoDistribuido, 2, 2)}`}
            </Text>
          </Text>
        </VStack>

        <GridItem colSpan={isLargerThan1200 ? 1 : 5}>
          <Divider
            orientation={isLargerThan1200 ? 'vertical' : 'horizontal'}
            h={isLargerThan1200 ? '10' : '1px'}
            borderColor="gray.100"
          />
        </GridItem>

        <VStack w="full" alignItems="flex-start">
          <Text>
            Custo adicional:{' '}
            <Text as="span" fontWeight="bold">
              {`R$ ${DecimalMask(produto.custoAdicional, 2, 2)}`}
            </Text>
          </Text>

          <Text>
            ICMS ST:{' '}
            <Text as="span" fontWeight="bold">
              {`R$ ${DecimalMask(produto.valorIcmsSt, 2, 2)}`}
            </Text>
          </Text>
        </VStack>
        <Divider orientation="vertical" h="10" borderColor="gray.100" />
        <VStack w="50%" alignItems="flex-start">
          <Text>
            IPI:{' '}
            <Text as="span" fontWeight="bold">
              {`R$ ${DecimalMask(produto.valorIpi, 2, 2)}`}
            </Text>
          </Text>

          <Text>
            FCP ST:{' '}
            <Text as="span" fontWeight="bold">
              {`R$ ${DecimalMask(produto.valorFcpSt, 2, 2)}`}
            </Text>
          </Text>
        </VStack>
        <GridItem colSpan={{ base: 5, lg: 9 }}>
          <Flex my="8px">
            <Text>{`Custo: ${produto.descricaoCustoProduto}`}</Text>
          </Flex>
          <Flex>
            <Text>
              Custo por item: R${' '}
              {DecimalMask(
                produto.custoTotalItem,
                casasDecimaisValor,
                casasDecimaisValor
              )}
            </Text>
          </Flex>
        </GridItem>
      </Grid>
    </Flex>
  );
};
