import { toast } from 'react-toastify';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';

export type VariacoesCoresProps = {
  id: string;
  produtoId: string;
  ativo: boolean;
  corId: string;
  cor: {
    id: string;
    descricao: string;
    ativo: boolean;
    dataHoraCadastro: string;
    dataHoraUltimaAlteracao: string;
    padraoSistema: boolean;
    hexadecimal: string | null;
    imagem: string | null;
  };
  produtoCorImagens: null | string;
  produtoCorTamanhos: null | string;
};

export const getVariacoesCores = async (
  idProduto: string,
  isPadraoSistema?: boolean
) => {
  const response = await api.get<void, ResponseApi<VariacoesCoresProps[]>>(
    `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/cores`
  );

  if (response) {
    if (response.avisos) {
      response.avisos.forEach((item: string) => toast.warning(item));
      return [];
    }

    if (response.sucesso) {
      return isPadraoSistema
        ? response.dados
        : response.dados.filter(
            (itemCores) => itemCores.cor.padraoSistema === false
          );
    }
    return [];
  }
  return [];
};
