import ConstanteFuncionalidades from 'constants/permissoes';
import auth from 'modules/auth';

interface TipoPrecoProperties {
  name: string;
  value: number;
}

interface TipoPrecoRelatorioInventario {
  PRECO_CUSTO: number;
  PRECO_VENDA: number;
  SEM_VALOR: number;
  properties: {
    [key: number]: TipoPrecoProperties;
  };
}

const possuiPermissaoVisualizarPrecoCusto = auth.possuiPermissao(
  ConstanteFuncionalidades.USUARIO_VISUALIZAR_PRECO_CUSTO
).permitido;

const TipoPrecoRelatorioInventario: TipoPrecoRelatorioInventario = {
  PRECO_CUSTO: 1,
  PRECO_VENDA: 2,
  SEM_VALOR: 3,

  properties: {
    1: { name: 'Preço de custo', value: 1 },
    2: { name: 'Preço de venda', value: 2 },
    3: { name: 'Sem valor', value: 3 },
  },
};

function filtrarPropriedadesPorPermissao(
  tipoPreco: TipoPrecoRelatorioInventario
): TipoPrecoRelatorioInventario {
  const tipoPrecoFiltrado = {
    ...tipoPreco,
    properties: { ...tipoPreco.properties },
  };

  if (!possuiPermissaoVisualizarPrecoCusto) {
    delete tipoPrecoFiltrado.properties[tipoPreco.PRECO_CUSTO];
  }

  return tipoPrecoFiltrado;
}

export const enumTipoPrecoRelatorioInventario = filtrarPropriedadesPorPermissao(
  TipoPrecoRelatorioInventario
);
