import ReactApexChart from 'react-apexcharts';
import { useMediaQuery, useToken, Text, Flex, Circle } from '@chakra-ui/react';
import { renderToString } from 'react-dom/server';

import { moneyMask } from 'helpers/format/fieldsMasks';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { PerformanceVendasPessoalProps } from '../Types/validationsForm';

type GraficoPerformanceVendasPessoalProps = {
  performanceVendasPessoal: PerformanceVendasPessoalProps;
  isLoading: boolean;
};

export const GraficoPerformanceVendasPessoal = ({
  performanceVendasPessoal,
  isLoading,
}: GraficoPerformanceVendasPessoalProps) => {
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const colors = useToken('colors', [
    'secondary.300',
    'primary.50',
    'orange.500',
  ]);
  const [
    primary50,
    orage500,
    secondary700,
    secondary300,
    aquamarine200,
    aquamarine100,
    white,
  ] = useToken('colors', [
    'primary.50',
    'orange.500',
    'secondary.700',
    'secondary.300',
    'aquamarine.200',
    'aquamarine.100',
    'white',
  ]);

  const valuesAnnotation = [
    ...performanceVendasPessoal.faixasMetaComissao,
    ...performanceVendasPessoal.faixasMetaComissao.map((faixa) => ({
      ...faixa,
      id: '',
      isValorMetadeOuTotalMeta: true,
      percentualComissao: faixa.percentualComissao,
      percentualMeta: faixa.percentualMeta,
    })),
  ];

  const customLegend = () => {
    return renderToString(
      <Flex
        boxShadow="0px 0px 25px #00000029;"
        bg={`${white}`}
        w="200px"
        h="51px"
        alignItems="center"
        justifyContent="center"
      >
        <Circle
          background={`transparent linear-gradient(90deg, ${aquamarine200} 0%, ${secondary300} 100%) 0% 0% no-repeat padding-box`}
          size="14px"
          mr="8px"
        />
        <Text color={`${secondary700}`} fontSize="14px">
          {moneyMask(performanceVendasPessoal.faturamento, true)}
        </Text>
      </Flex>
    );
  };

  return (
    <>
      {isLoading && <LoadingPadrao />}
      <ReactApexChart
        options={{
          chart: {
            height: 350,
            type: 'bar',
            toolbar: {
              show: false,
            },
          },
          tooltip: {
            enabled: true,
            fixed: {
              enabled: true,
              position: 'center',
              offsetY: 60,
              offsetX: 60,
            },
            custom() {
              return customLegend();
            },
            theme: 'dark',
            marker: {
              show: false,
            },
          },
          colors,
          plotOptions: {
            bar: {
              horizontal: !!isLargerThan900,
              barHeight: '100%',
              columnWidth: '100%',
              distributed: true,
            },
          },
          yaxis: {
            labels: {
              show: !isLargerThan900,
            },
          },

          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,

              type: isLargerThan900 ? 'horizontal' : 'vertical',
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              colorStops: [
                {
                  offset: isLargerThan900 ? 0 : 30,
                  color: isLargerThan900 ? aquamarine200 : secondary300,
                  opacity: 1,
                },
                {
                  offset: isLargerThan900 ? 30 : 40,
                  color: aquamarine100,
                  opacity: 1,
                },
                {
                  offset: 100,
                  color: isLargerThan900 ? secondary300 : aquamarine200,
                  opacity: 1,
                },
              ],
            },
          },

          annotations: {
            yaxis: isLargerThan900
              ? []
              : (valuesAnnotation || []).map((faixas) => ({
                  y:
                    (performanceVendasPessoal.meta * faixas.percentualMeta) /
                    100,
                  borderColor: 'none',
                  label: {
                    borderColor: 'none',
                    style: {
                      color: faixas.isValorMetadeOuTotalMeta
                        ? primary50
                        : orage500,
                      background: 'none',
                      fontSize: '12.5px',
                    },
                    offsetY: faixas.isValorMetadeOuTotalMeta ? 20 : 5,
                    orientation: 'vertical',
                    text: `${
                      faixas.isValorMetadeOuTotalMeta
                        ? faixas.percentualMeta
                        : faixas.percentualComissao
                    }%`,
                  },
                })),
            xaxis: isLargerThan900
              ? (valuesAnnotation || []).map((faixas) => ({
                  x:
                    (performanceVendasPessoal.meta * faixas.percentualMeta) /
                    100,
                  borderColor: 'none',
                  label: {
                    borderColor: 'none',
                    style: {
                      color: faixas.isValorMetadeOuTotalMeta
                        ? primary50
                        : orage500,
                      background: 'none',
                      fontSize: '12.5px',
                    },
                    orientation: 'horizontal',
                    offsetX: faixas.isValorMetadeOuTotalMeta ? -20 : 0,
                    offsetY: faixas.isValorMetadeOuTotalMeta ? 30 : 98,
                    text: `${
                      faixas.isValorMetadeOuTotalMeta
                        ? faixas.percentualMeta
                        : faixas.percentualComissao
                    }%`,
                  },
                }))
              : [],
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            position: 'top',
            horizontalAlign: 'right',
            show: !!isLargerThan900,
          },
          xaxis: {
            categories: ['Faturamento', 'Meta', 'Comissão'],
            labels: {
              show: !!isLargerThan900,
              formatter(value) {
                return `${moneyMask(value, true)}`;
              },
            },
          },
          grid: {
            position: 'front',
            borderColor: primary50,
            xaxis: {
              lines: {
                show: false,
              },
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
        }}
        series={[
          {
            data: [performanceVendasPessoal].map((performanceVendas) => ({
              y: isLargerThan900
                ? performanceVendas.faturamento
                : performanceVendas.faturamento,
              x: isLargerThan900 ? '' : performanceVendas.faturamento,
              goals: (performanceVendas.faixasMetaComissao || []).map(
                (faixas) => {
                  const valorPercentMeta =
                    (performanceVendas.meta * faixas.percentualMeta) / 100;

                  return {
                    name: 'Faixa de cada meta',
                    value: valorPercentMeta,
                    strokeWidth: 5,
                    strokeHeight: 0,
                    strokeColor: primary50,
                  };
                }
              ),
            })),
          },
        ]}
        type="bar"
        style={{
          marginTop: isLargerThan900 ? '-28px' : '',
          marginLeft: '-24px',
        }}
        height={isLargerThan900 ? '77%' : '80%'}
      />
    </>
  );
};
