import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { moneyMask } from 'helpers/format/fieldsMasks';

interface Produtos {
  produto: Array<Itens>;
}

interface Itens {
  nome: string;
  quantidade: number;
  valorUnitario: number;
  desconto: number;
  valorTotal: number;
}

const Produtos = ({ produto }: Produtos) => {
  return (
    <Flex
      direction="column"
      pb={2}
      borderBottom="2px solid"
      borderColor="gray.700"
    >
      {produto?.map((item) => (
        <Flex w="100%" mt={2} direction="column">
          <Flex alignItems="flex-start">
            <Text fontWeight="semibold" fontSize="x-small">
              {item.nome}
            </Text>
          </Flex>
          <Flex justifyContent="space-between">
            <Text color="black" fontWeight="900" fontSize="x-small" ml={1.5}>
              {item.quantidade} x {moneyMask(item.valorUnitario, false)}
            </Text>
            <Text color="black" fontWeight="900" fontSize="x-small">
              {moneyMask(item.valorTotal, false)}
            </Text>
          </Flex>
          {item.desconto > 0 && (
            <Flex justifyContent="space-between">
              <Flex
                w="160px"
                justifyContent="space-between"
                fontWeight="bold"
                fontSize="x-small"
                ml={1.5}
              >
                <Text color="black" as="span">
                  Desconto
                </Text>
                <Text color="black" as="span">
                  -{moneyMask(item.desconto, false)}
                </Text>
              </Flex>
              <Text color="black" fontWeight="900" fontSize="x-small">
                {moneyMask(item.desconto, false)}
              </Text>
            </Flex>
          )}
        </Flex>
      ))}
    </Flex>
  );
};

export default Produtos;
