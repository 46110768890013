import {
  Tr,
  Text,
  Accordion,
  GridItem,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  Td,
  AccordionPanel,
  Box,
  Flex,
} from '@chakra-ui/react';
import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useImperativeHandle,
} from 'react';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { OptionTypeBase, OptionsType } from 'react-select';
import { toast } from 'react-toastify';

import { useProdutosFormularioContext } from 'store/Produtos/ProdutosFormulario';
import IndicadorEscalaRelevanteEnum from 'constants/enum/indicadorEscalaRelevante';
import TipoProdutoFiscalEnum from 'constants/enum/tipoProdutoFiscal';
import OrigemMercadoriaEnum from 'constants/enum/origemMercadoria';
import EstadosEnum from 'constants/enum/estados';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';
import { capitalize } from 'helpers/format/stringFormats';
import auth from 'modules/auth';
import { useValidationFormDirtyFields } from 'hooks/useValidationFormDirtyFields';

import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { ToggleRefType } from 'components/Accordion/AccordionPadrao';
import InputCpfCnpj from 'components/PDV/InputCpfCnpj';
import { TableCampoFormularioPadrao } from 'components/update/Table/TableCampoFormularioPadrao';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import { SelectNcm } from 'components/update/Select/SelectNCM';
import Select from 'components/PDV/Select/SelectPadrao';
import { NumberInput } from 'components/update/Input/NumberInput';
import Input from 'components/PDV/Input';

import ExecucaoEstadoICMSModal from './ExecucaoEstadoICMSModal';
import {
  CamposObrigatoriosProps,
  FiscalProps,
  schemaFiscais,
  SelectItemProps,
  shapeFiscais,
} from '../../validationForm';

type LojaPradraoProps = {
  regraFiscalPadraoId: string;
  aliquotaPadraoPIS: number;
  aliquotaPadraoCofins: number;
};

const fatorConversaoOptions = [
  { label: 'Multiplicação (x)', value: '*' },
  { label: 'Divisão (÷)', value: '/' },
];

export const Fiscais = () => {
  const [
    execucaoEstadoICMSModalIsOpen,
    setExecucaoEstadoICMSModalIsOpen,
  ] = useState(false);
  const [
    execucaoEstadoICMSModalAcao,
    setExecucaoEstadoICMSModalAcao,
  ] = useState<'editar' | 'duplicar' | 'cadastrar'>('cadastrar');
  const [
    execucaoEstadoICMSModalItemIndex,
    setExecucaoEstadoICMSModalItemIndex,
  ] = useState(0);
  const [regraFiscalOptions, setRegraFiscalOptions] = useState<
    OptionsType<OptionTypeBase>
  >([]);
  const [
    regraFiscalOptionsIsLoading,
    setRegraFiscalOptionsIsLoading,
  ] = useState(true);
  const [anpOptions, setAnpOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [anpOptionsIsLoading, setAnpOptionsIsLoading] = useState(true);
  const [hasExcecaoEstado, setHasExcecaoEstado] = useState(false);

  const icmsAccordionToggleRef = useRef() as React.MutableRefObject<ToggleRefType>;
  const informacoesAdicionaisAccordionToggleRef = useRef() as React.MutableRefObject<ToggleRefType>;

  const {
    isLoading,
    readonly,
    unidadeDefaultValue,
    unidadesMedidaOptions,
    unidadesMedidaOptionsIsLoading,
    action,
    ref,
    setIsLoading,
    setCampoObrigatorioVazio,
    idProduto,
    setHasNcm,
    onChangeFormIsDirty,
  } = useProdutosFormularioContext();

  const { getValues: getValuesParents } = useFormContext();

  const formMethods = useForm();

  const {
    reset,
    handleSubmit,
    watch,
    setValue,
    setError,
    control,
    getValues,
    formState: { errors, dirtyFields },
  } = formMethods;

  const latestProps = useRef({ setValue });

  const formIsDirty = useValidationFormDirtyFields(dirtyFields);

  const isDirty = hasExcecaoEstado || formIsDirty;
  const { id: idLoja } = auth.getLoja();

  const [indicadorEscalaRelevante, fatorConversao, codigoNcm] = watch([
    'indicadorEscalaRelevante',
    'fatorConversao',
    'codigoNcm',
  ]);

  const handleAbrirModalCadastrar = () => {
    setExecucaoEstadoICMSModalAcao('cadastrar');
    setExecucaoEstadoICMSModalIsOpen(true);
    setHasExcecaoEstado(true);
  };

  const handleAbrirModalEditar = (index: number) => {
    setExecucaoEstadoICMSModalItemIndex(index);
    setExecucaoEstadoICMSModalAcao('editar');
    setExecucaoEstadoICMSModalIsOpen(true);
  };

  const handleAbrirModalDuplicar = (index: number) => {
    setExecucaoEstadoICMSModalItemIndex(index);
    setExecucaoEstadoICMSModalAcao('duplicar');
    setExecucaoEstadoICMSModalIsOpen(true);
  };

  const handleRemoverExcecaoEstadoICMS = (index: number) => {
    const items = getValues('produtoRegraFiscalExcecoes') as Array<any>;
    items.splice(index, 1);

    setValue('produtoRegraFiscalExcecoes', [...items]);
  };

  const handleGetRegraFiscalOptions = async () => {
    setRegraFiscalOptionsIsLoading(true);

    const response = await api.get<void, ResponseApi<OptionTypeBase[]>>(
      ConstanteEnderecoWebservice.REGRA_FISCAL_LISTAR_SELECT
    );

    if (response?.avisos) {
      response.avisos.forEach((item: string) => toast.warning(item));
    }

    if (response?.sucesso) {
      setRegraFiscalOptions(
        response.dados.map((regraFiscal) => {
          return {
            label: regraFiscal.nome,
            value: regraFiscal.id,
          } as OptionTypeBase;
        })
      );
    } else setRegraFiscalOptions([]);

    setRegraFiscalOptionsIsLoading(false);
  };

  const handleGetAnpOptions = async () => {
    setAnpOptionsIsLoading(true);

    const response = await api.get<void, ResponseApi<OptionTypeBase[]>>(
      ConstanteEnderecoWebservice.ANP_LISTAR_SELECT
    );

    if (response?.avisos) {
      response.avisos.forEach((item: string) => toast.warning(item));
    }

    if (response?.sucesso) {
      setAnpOptions(
        response.dados.map((anp) => {
          return {
            label: `${anp.codigo} - ${anp.descricao}`,
            value: anp.id,
          };
        })
      );
    } else setAnpOptions([]);

    setAnpOptionsIsLoading(false);
  };

  const valoresObrigatorios = useCallback(() => {
    const camposFormulario = Object.keys(shapeFiscais);
    const camposObrigatorios = [] as string[];

    camposFormulario.forEach((item) => {
      const schema = schemaFiscais?.describe()?.fields[
        item
      ] as CamposObrigatoriosProps;

      if (
        schema.tests.some((itemSchema) => itemSchema.name.includes('required'))
      ) {
        camposObrigatorios.push(item);
      }
    });

    return camposObrigatorios;
  }, [])();

  const campoObrigatorioVazio = valoresObrigatorios
    .map((item) => watch(`${item}` as any))
    .some((campo) => campo === undefined || campo === null || campo === '');

  const salvarProdutos = useCallback(async () => {
    setIsLoading(true);
    const data = getValues();
    const { codigoCest } = data;
    if (codigoCest && codigoCest.length < 7) {
      setError(
        'codigoCest',
        {
          type: 'minLength',
          message: 'O código CEST deve ter no mínimo 7 dígitos',
        },
        {
          shouldFocus: true,
        }
      );
      setIsLoading(false);
      return false;
    }

    if (campoObrigatorioVazio) {
      valoresObrigatorios.forEach((item) => {
        const campo = watch(`${item}`);
        if (campo === undefined || campo === null || campo === '') {
          setError(
            `${item}`,
            { type: 'required', message: 'Campo obrigatório' },
            {
              shouldFocus: true,
            }
          );
        }
      });
      handleSubmit(() => {});
      setIsLoading(false);
      return false;
    }

    if (isDirty === false) {
      return true;
    }

    const unidadeMedida = unidadesMedidaOptions.find(
      ({ value }) => value === data.unidadeTributavel
    );
    const codigoCestApenasNumeros = data.codigoCest?.replace(/\D/g, '') ?? '';

    const response = await api.patch<void, ResponseApi<boolean>>(
      `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/fiscais`,
      {
        ...data,
        produtoId: idProduto,
        codigoCest: codigoCestApenasNumeros,
        codigoNcm: data.codigoNcm?.value,
        ncmLabel: data.codigoNcm?.label,
        regraFiscalId: data.regraFiscal,
        unidadeTributavelId: data.unidadeTributavel,
        unidadeTributavelOpcaoSelect: {
          id: unidadeMedida?.value,
          nome: unidadeMedida?.label,
        },
        codigoAnp: String(
          data.codigoAnp === 'null' || data.codigoAnp === null
            ? ''
            : data.codigoAnp
        ),
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
        setIsLoading(false);
        return false;
      }

      if (response.sucesso) {
        setIsLoading(false);

        return true;
      }
      setIsLoading(false);
      return false;
    }
    setIsLoading(false);
    return false;
  }, [
    setIsLoading,
    getValues,
    campoObrigatorioVazio,
    isDirty,
    unidadesMedidaOptions,
    idProduto,
    valoresObrigatorios,
    handleSubmit,
    watch,
    setError,
  ]);

  const handleGetRegraFiscalLoja = useCallback(async () => {
    const response = await api.get<void, ResponseApi<LojaPradraoProps>>(
      ConstanteEnderecoWebservice.LOJA_OBTER_REGRAFISCAL_LOJA,
      {
        params: { idLoja },
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso) {
        if (action !== 'alterar') {
          latestProps.current.setValue(
            'regraFiscal',
            response.dados?.regraFiscalPadraoId
          );
        }
        latestProps.current.setValue(
          'pisAliquota',
          response.dados?.aliquotaPadraoPIS
        );
        latestProps.current.setValue(
          'cofinsAliquota',
          response.dados?.aliquotaPadraoCofins
        );
      }
    }
  }, [action, idLoja]);

  const getDadosFiscais = useCallback(async () => {
    setIsLoading(true);
    await handleGetRegraFiscalOptions();
    await handleGetAnpOptions();
    await handleGetRegraFiscalLoja();

    const response = await api.get<void, ResponseApi<FiscalProps>>(
      `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/fiscais`
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
        setIsLoading(false);
      }

      if (response.sucesso) {
        const codigoCest = response.dados.codigoCest
          ? response.dados.codigoCest
          : getValuesParents()?.codigoCest
          ? getValuesParents()?.codigoCest
          : null;

        const codigoCestApenasNumeros = codigoCest?.replace(/\D/g, '') ?? '';

        reset({
          ...response.dados,
          produtoId: idProduto,
          codigoCest: codigoCestApenasNumeros,
          unidadeTributavel: response.dados.unidadeTributavelId || 0,
          codigoNcm:
            response.dados.ncmLabel === null
              ? getValuesParents()?.codigoNcm
                ? getValuesParents()?.codigoNcm
                : null
              : ({
                  value: response?.dados?.codigoNcm || '',
                  label: response?.dados?.ncmLabel || '',
                } as SelectItemProps),
          regraFiscal: response.dados.regraFiscalId,
          codigoAnp: String(
            response.dados.codigoAnp === 'null' ||
              response.dados.codigoAnp === null
              ? ''
              : response.dados.codigoAnp
          ),
        });
        setIsLoading(false);
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [
    getValuesParents,
    handleGetRegraFiscalLoja,
    idProduto,
    reset,
    setIsLoading,
  ]);

  const handleGetNomeEstado = useCallback((estadoId: number) => {
    const estadoPropertie = Object.entries(EstadosEnum.properties).find(
      ([, { id }]) => id === estadoId
    );

    if (estadoPropertie && estadoPropertie[1]) {
      const estadoNome = estadoPropertie[1].nome;

      if (estadoNome) {
        return capitalize(estadoNome, 2);
      }
    }

    return '';
  }, []);

  const updateValueRegraFiscal = (value: string) => {
    setValue('regraFiscal', value);
  };

  useEffect(() => {
    if (indicadorEscalaRelevante !== IndicadorEscalaRelevanteEnum.NAO) {
      setValue('cnpjFabricante', null);
    }
  }, [indicadorEscalaRelevante, setValue]);

  useEffect(() => {
    if (errors.regraFiscal || errors.codigoBeneficioFiscal) {
      icmsAccordionToggleRef.current.handleOpenAccordion();
    } else if (
      errors.unidadeTributavel ||
      errors.fatorConversao ||
      errors.qtdeConversao
    ) {
      informacoesAdicionaisAccordionToggleRef?.current?.handleOpenAccordion();
    }
  }, [errors]);

  useEffect(() => {
    latestProps.current = { setValue };
  });

  useEffect(() => {
    if (unidadesMedidaOptionsIsLoading) {
      latestProps.current.setValue(
        'unidadeTributavel',
        unidadesMedidaOptions.filter(
          (obj) => obj.value === unidadeDefaultValue
        )[0]
      );
    }
  }, [
    unidadeDefaultValue,
    unidadesMedidaOptions,
    unidadesMedidaOptionsIsLoading,
  ]);

  useEffect(() => {
    setCampoObrigatorioVazio(campoObrigatorioVazio);
  }, [campoObrigatorioVazio, setCampoObrigatorioVazio]);

  useEffect(() => {
    getDadosFiscais();
  }, [getDadosFiscais]);

  useEffect(() => {
    onChangeFormIsDirty(isDirty);
  }, [isDirty, onChangeFormIsDirty]);

  useImperativeHandle(ref, () => ({
    onClick: () => salvarProdutos(),
  }));

  return (
    <FormProvider {...formMethods}>
      <SimpleGridForm>
        <GridItem colSpan={{ base: 12, md: 4 }}>
          <Select
            id="tipoProdutoFiscal"
            name="tipoProdutoFiscal"
            label="Tipo"
            required
            valueDefault={TipoProdutoFiscalEnum.MERCADORIA_PARA_REVENDA}
            options={Object.entries(TipoProdutoFiscalEnum.properties).map(
              (value: any) => ({
                label: value[1].name,
                value: value[1].value,
              })
            )}
            isDisabled={readonly}
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, md: 8 }}>
          <Select
            id="cstOrigem"
            name="cstOrigem"
            label="Origem"
            required
            valueDefault={0}
            options={Object.entries(OrigemMercadoriaEnum.properties).map(
              (value: any) => ({
                label: value[1].name,
                value: value[1].value,
              })
            )}
            isDisabled={readonly}
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, md: 8 }}>
          <SelectNcm
            id="codigoNcm"
            name="codigoNcm"
            label="NCM - nomenclatura comum do mercosul"
            placeholder="Digite o código ou texto da classificação NCM"
            isDisabled={readonly}
            required
            autoFocus
            onSelect={() => {
              setHasNcm(true);
            }}
            asControlledByObject
            errorText={errors?.codigoNcm?.message}
            errorPropName="codigoNcm"
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, md: 4 }}>
          <Input
            id="codigoCest"
            name="codigoCest"
            type="text"
            label="CEST"
            placeholder="Digite o código"
            maxLength={7}
            helperText="O campo do CEST pode ser deixado em branco se o produto não o possuir. Se possuir, o código precisa ter no mínimo 7 dígitos."
            isDisabled={readonly || !codigoNcm}
          />
        </GridItem>

        <GridItem colSpan={{ base: 12, md: 8 }}>
          <Select
            id="regraFiscal"
            name="regraFiscal"
            label="Regra fiscal ICMS"
            required
            options={regraFiscalOptions.map((regraFiscalItem) => ({
              label: regraFiscalItem?.label,
              value: regraFiscalItem?.value,
            }))}
            onSelect={(event) => updateValueRegraFiscal(event.value as string)}
            isDisabled={regraFiscalOptionsIsLoading || readonly}
            isLoading={regraFiscalOptionsIsLoading}
          />
        </GridItem>

        <GridItem colSpan={12}>
          <Accordion allowToggle>
            <AccordionItem mb="30px">
              <Text>
                <AccordionButton>
                  <Box as="span" flex="1" fontWeight="bold" textAlign="left">
                    ICMS
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </Text>
              <AccordionPanel>
                <SimpleGridForm>
                  <GridItem colSpan={{ base: 12, md: 6, lg: 3 }}>
                    <NumberInput
                      id="icmsAliquota"
                      name="icmsAliquota"
                      label="Alíquota"
                      leftElement="%"
                      precision={5}
                      scale={2}
                      defaultValue={0}
                      editarFundoLeftElemento
                      bgLeftElement="gray.50"
                      isDisabled={readonly}
                    />
                  </GridItem>
                  <GridItem colSpan={{ base: 12, md: 6, lg: 3 }}>
                    <NumberInput
                      id="icmsReducaoBaseCalculo"
                      name="icmsReducaoBaseCalculo"
                      label="Redução da base de cálculo"
                      leftElement="%"
                      precision={5}
                      scale={2}
                      defaultValue={0}
                      editarFundoLeftElemento
                      bgLeftElement="gray.50"
                      isDisabled={readonly}
                    />
                  </GridItem>
                  <GridItem colSpan={{ base: 12, md: 6, lg: 3 }}>
                    <Input
                      name="codigoBeneficioFiscal"
                      label="Código do benefício fiscal"
                      placeholder="Digite o código"
                      maxLength={10}
                      isDisabled={readonly}
                    />
                  </GridItem>
                  <GridItem colSpan={{ base: 12, md: 6, lg: 3 }}>
                    <NumberInput
                      id="fcpAliquota"
                      name="fcpAliquota"
                      label="Fundo de combate a pobreza"
                      leftElement="%"
                      precision={5}
                      scale={2}
                      defaultValue={0}
                      editarFundoLeftElemento
                      bgLeftElement="gray.50"
                      isDisabled={readonly}
                    />
                  </GridItem>
                  <GridItem colSpan={{ base: 12, md: 6, lg: 3 }}>
                    <NumberInput
                      id="icmsStRetidoBaseCalculo"
                      name="icmsStRetidoBaseCalculo"
                      label="Valor BC - ST retido"
                      leftElement="R$"
                      precision={13}
                      scale={2}
                      defaultValue={0}
                      editarFundoLeftElemento
                      bgLeftElement="gray.50"
                      isDisabled={readonly}
                    />
                  </GridItem>
                  <GridItem colSpan={{ base: 12, md: 6, lg: 3 }}>
                    <NumberInput
                      id="icmsStRetidoValor"
                      name="icmsStRetidoValor"
                      label="Valor ICMS - ST retido"
                      leftElement="R$"
                      precision={13}
                      scale={2}
                      defaultValue={0}
                      editarFundoLeftElemento
                      bgLeftElement="gray.50"
                      isDisabled={readonly}
                    />
                  </GridItem>
                  <GridItem colSpan={{ base: 12, md: 6, lg: 3 }}>
                    <NumberInput
                      id="fcpStRetidoBaseCalculo"
                      name="fcpStRetidoBaseCalculo"
                      label="Valor BC - FCP - ST retido"
                      leftElement="R$"
                      precision={13}
                      scale={2}
                      defaultValue={0}
                      editarFundoLeftElemento
                      bgLeftElement="gray.50"
                      isDisabled={readonly}
                    />
                  </GridItem>
                  <GridItem colSpan={{ base: 12, md: 6, lg: 3 }}>
                    <NumberInput
                      id="fcpStRetidoValor"
                      name="fcpStRetidoValor"
                      label="Valor ICMS - FCP - ST retido"
                      leftElement="R$"
                      precision={13}
                      scale={2}
                      defaultValue={0}
                      editarFundoLeftElemento
                      bgLeftElement="gray.50"
                      isDisabled={readonly}
                    />
                  </GridItem>

                  <GridItem colSpan={12}>
                    <Flex
                      alignItems="flex-end"
                      justifyContent="space-between"
                      mb="3px"
                      w="full"
                    >
                      <Flex
                        alignItems="center"
                        fontSize="0.8125rem"
                        fontWeight="bold"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        whiteSpace="nowrap"
                        maxWidth="100%"
                      >
                        Exceção por Estado
                      </Flex>

                      {!readonly && (
                        <Text
                          color="blue.500"
                          cursor="pointer"
                          onClick={handleAbrirModalCadastrar}
                        >
                          Adicionar nova exceção
                        </Text>
                      )}
                    </Flex>
                    <TableCampoFormularioPadrao
                      fieldName="produtoRegraFiscalExcecoes"
                      defaultColumnOrder="estadoOrigemId"
                      headerColumns={[
                        {
                          name: 'estadoOrigemId',
                          displayName: 'UF origem',
                          isOrderable: true,
                          width: '16.66%',
                          orderColumnCompareFn: (
                            a: any,
                            b: any,
                            order: 'asc' | 'desc'
                          ) => {
                            const estadoNomeA = handleGetNomeEstado(
                              a.estadoOrigemId
                            );
                            const estadoNomeB = handleGetNomeEstado(
                              b.estadoOrigemId
                            );

                            if (order === 'asc') {
                              if (estadoNomeA < estadoNomeB) return -1;

                              if (estadoNomeA > estadoNomeB) return 1;

                              return 0;
                            }

                            if (estadoNomeB < estadoNomeA) return -1;

                            if (estadoNomeB > estadoNomeA) return 1;

                            return 0;
                          },
                        },
                        {
                          name: 'estadoDestinoId',
                          displayName: 'UF destino',
                          isOrderable: true,
                          width: '16.66%',
                          orderColumnCompareFn: (
                            a: any,
                            b: any,
                            order: 'asc' | 'desc'
                          ) => {
                            const estadoNomeA = handleGetNomeEstado(
                              a.estadoDestinoId
                            );
                            const estadoNomeB = handleGetNomeEstado(
                              b.estadoDestinoId
                            );

                            if (order === 'asc') {
                              if (estadoNomeA < estadoNomeB) return -1;

                              if (estadoNomeA > estadoNomeB) return 1;

                              return 0;
                            }

                            if (estadoNomeB < estadoNomeA) return -1;

                            if (estadoNomeB > estadoNomeA) return 1;

                            return 0;
                          },
                        },
                        {
                          name: 'aliquotaIcms',
                          displayName: 'Alíquota',
                          isOrderable: true,
                          width: '16.66%',
                        },
                        {
                          name: 'reducaoBaseCalculo',
                          displayName: 'Redução BC',
                          isOrderable: true,
                          width: '16.66%',
                        },
                        {
                          name: 'porcentagemFCP',
                          displayName: '% Fundo C.P.',
                          isOrderable: true,
                          width: '16.66%',
                        },
                        {
                          name: 'codigoBeneficioFiscal',
                          displayName: 'Cód. benef. fiscal',
                          isOrderable: true,
                          width: '16.66%',
                        },
                        {
                          name: 'acoes',
                          displayName: 'Ações',
                          isOrderable: false,
                          width: '38px',
                        },
                      ]}
                      emptyValuesText="Nenhuma exceção adicionada para este produto"
                      control={control as any}
                      isLoading={isLoading}
                    >
                      {({ tableValues }) => (
                        <>
                          {tableValues &&
                            tableValues.map((tableValue, index) => (
                              <>
                                <Box h="2px" />
                                <Tr
                                  sx={{
                                    boxShadow: '0px 0px 4px #00000029',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                    _hover: {
                                      boxShadow:
                                        '0px 0px 4px rgba(85, 2, 178, 0.5)',
                                    },
                                    '& td': {
                                      height: '64px !important',
                                      background: 'white',
                                    },
                                  }}
                                  key={tableValue.estadoOrigemId}
                                >
                                  <Td borderStartRadius="5px">
                                    {handleGetNomeEstado(
                                      tableValue.estadoOrigemId
                                    )}
                                  </Td>
                                  <Td>
                                    {handleGetNomeEstado(
                                      tableValue.estadoDestinoId
                                    )}
                                  </Td>
                                  <Td pl="10px">
                                    <Flex w="full" justifyContent="flex-start">
                                      {`${(
                                        tableValue.aliquotaIcms || 0
                                      ).toLocaleString('locale', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}%`}
                                    </Flex>
                                  </Td>
                                  <Td>
                                    {`${(
                                      tableValue.reducaoBaseCalculo || 0
                                    ).toLocaleString('locale', {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}%`}
                                  </Td>
                                  <Td>
                                    {`${(
                                      tableValue.porcentagemFCP || 0
                                    ).toLocaleString('locale', {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}%`}
                                  </Td>
                                  <Td>{tableValue.codigoBeneficioFiscal}</Td>
                                  <Td borderEndRadius="5px">
                                    <ActionsMenu
                                      id="produtoRegraFiscalExcecoesMostrarMais"
                                      isDisabled={readonly}
                                      items={[
                                        {
                                          content: 'Editar',
                                          onClick: () => {
                                            handleAbrirModalEditar(index);
                                          },
                                        },
                                        {
                                          content: 'Remover',
                                          onClick: () => {
                                            handleRemoverExcecaoEstadoICMS(
                                              index
                                            );
                                          },
                                        },
                                        {
                                          content: 'Duplicar',
                                          onClick: () => {
                                            handleAbrirModalDuplicar(index);
                                          },
                                        },
                                      ]}
                                    />
                                  </Td>
                                </Tr>
                              </>
                            ))}
                        </>
                      )}
                    </TableCampoFormularioPadrao>

                    <ExecucaoEstadoICMSModal
                      show={execucaoEstadoICMSModalIsOpen}
                      onHide={() => setExecucaoEstadoICMSModalIsOpen(false)}
                      acao={execucaoEstadoICMSModalAcao}
                      itemIndex={execucaoEstadoICMSModalItemIndex}
                    />
                  </GridItem>
                </SimpleGridForm>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem mb="30px">
              <Text>
                <AccordionButton>
                  <Box as="span" flex="1" fontWeight="bold" textAlign="left">
                    PIS e COFINS
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </Text>
              <AccordionPanel>
                <SimpleGridForm>
                  <GridItem colSpan={{ base: 12, md: 6, lg: 3 }}>
                    <NumberInput
                      id="pisAliquota"
                      name="pisAliquota"
                      label="Alíquota PIS"
                      leftElement="%"
                      editarFundoLeftElemento
                      bgLeftElement="gray.50"
                      precision={5}
                      scale={2}
                      defaultValue={0}
                      disabled={readonly}
                    />
                  </GridItem>
                  <GridItem colSpan={{ base: 12, md: 6, lg: 3 }}>
                    <NumberInput
                      id="cofinsAliquota"
                      name="cofinsAliquota"
                      label="Alíquota COFINS"
                      leftElement="%"
                      editarFundoLeftElemento
                      bgLeftElement="gray.50"
                      precision={5}
                      scale={2}
                      defaultValue={0}
                      disabled={readonly}
                    />
                  </GridItem>
                </SimpleGridForm>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem mb="30px">
              <Text>
                <AccordionButton>
                  <Box as="span" flex="1" fontWeight="bold" textAlign="left">
                    Informações adicionais
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </Text>
              <AccordionPanel>
                <SimpleGridForm>
                  <GridItem colSpan={{ base: 12, md: 9, lg: 8 }}>
                    <Flex
                      alignItems="center"
                      fontSize="0.8125rem"
                      fontWeight="bold"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      maxWidth="100%"
                    >
                      Unidade tributável e fator de conversão *
                    </Flex>
                    <Box
                      p="30px"
                      borderWidth="1px"
                      borderColor="gray.100"
                      borderStyle="solid"
                      bg="gray.50"
                      borderRadius="5px"
                      boxShadow="0px 0px 6px 0px rgba(0, 0, 0, 0.2)"
                    >
                      <SimpleGridForm>
                        <GridItem colSpan={{ base: 12, md: 4 }}>
                          <Select
                            id="unidadeTributavel"
                            name="unidadeTributavel"
                            required
                            valueDefault={unidadeDefaultValue}
                            options={unidadesMedidaOptions}
                            isDisabled={
                              readonly || unidadesMedidaOptionsIsLoading
                            }
                            isLoading={unidadesMedidaOptionsIsLoading}
                          />
                        </GridItem>
                        <GridItem colSpan={{ base: 12, md: 4 }}>
                          <Select
                            id="fatorConversao"
                            name="fatorConversao"
                            required
                            valueDefault="*"
                            options={fatorConversaoOptions}
                            isDisabled={readonly}
                          />
                        </GridItem>
                        <GridItem colSpan={{ base: 12, md: 4 }}>
                          <NumberInput
                            id="qtdeConversao"
                            name="qtdeConversao"
                            leftElement={
                              (fatorConversao &&
                                (fatorConversao === '/' ? '÷' : 'x')) ||
                              '?'
                            }
                            precision={9}
                            scale={4}
                            defaultValue={0}
                            editarFundoLeftElemento
                            bgLeftElement="gray.50"
                            isDisabled={readonly || !fatorConversao}
                          />
                        </GridItem>
                      </SimpleGridForm>
                    </Box>
                  </GridItem>
                  <GridItem colSpan={{ base: 12, md: 5, sm: 4, lg: 6 }}>
                    <Flex
                      alignItems="center"
                      fontSize="0.8125rem"
                      fontWeight="bold"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      maxWidth="100%"
                    >
                      Indicador de produção em escala relevante
                    </Flex>
                    <Box
                      p="30px"
                      borderWidth="1px"
                      borderColor="gray.100"
                      borderStyle="solid"
                      bg="gray.50"
                      borderRadius="5px"
                      boxShadow="0px 0px 6px 0px rgba(0, 0, 0, 0.2)"
                    >
                      <SimpleGridForm>
                        <GridItem colSpan={{ base: 12, md: 4 }}>
                          <Select
                            id="indicadorEscalaRelevante"
                            name="indicadorEscalaRelevante"
                            required
                            valueDefault={0}
                            options={Object.entries(
                              IndicadorEscalaRelevanteEnum.properties
                            ).map((value: any) => ({
                              label: value[1].name,
                              value: value[1].value,
                            }))}
                            isDisabled={readonly}
                          />
                        </GridItem>
                        <GridItem colSpan={{ base: 12, md: 4 }}>
                          <InputCpfCnpj
                            type="text"
                            id="cnpjFabricante"
                            name="cnpjFabricante"
                            placeholder="CNPJ do fabricante"
                            isDisabled={
                              indicadorEscalaRelevante !==
                              IndicadorEscalaRelevanteEnum.NAO
                            }
                            asCnpj
                          />
                        </GridItem>
                      </SimpleGridForm>
                    </Box>
                  </GridItem>
                </SimpleGridForm>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem mb="30px">
              <Text>
                <AccordionButton>
                  <Box as="span" flex="1" fontWeight="bold" textAlign="left">
                    Derivados do petróleo
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </Text>
              <AccordionPanel>
                <SimpleGridForm>
                  <GridItem colSpan={{ base: 12, md: 4 }}>
                    <Select
                      id="codigoAnp"
                      name="codigoAnp"
                      placeholder="Selecione"
                      label="Código e descrição ANP"
                      options={anpOptions}
                      isDisabled={anpOptionsIsLoading}
                      isLoading={anpOptionsIsLoading}
                    />
                  </GridItem>
                  <GridItem colSpan={{ base: 12, md: 4 }}>
                    <Input
                      name="codif"
                      id="codif"
                      label="Código de autorização do CODIF"
                      placeholder="Digite o código"
                      maxLength={21}
                      isDisabled={readonly}
                    />
                  </GridItem>

                  <GridItem colSpan={{ base: 12, md: 4 }}>
                    <NumberInput
                      id="percentualGLP"
                      name="percentualGLP"
                      label="Percentual de GLP"
                      editarFundoLeftElemento
                      bgLeftElement="gray.50"
                      leftElement="%"
                      precision={7}
                      scale={4}
                      defaultValue={0}
                      isDisabled={readonly}
                    />
                  </GridItem>
                  <GridItem colSpan={{ base: 12, md: 4 }}>
                    <NumberInput
                      id="percentualGasNacional"
                      name="percentualGasNacional"
                      label="Percentual de GLCNn"
                      leftElement="%"
                      editarFundoLeftElemento
                      bgLeftElement="gray.50"
                      precision={7}
                      scale={4}
                      defaultValue={0}
                      isDisabled={readonly}
                    />
                  </GridItem>
                  <GridItem colSpan={{ base: 12, md: 4 }}>
                    <NumberInput
                      id="percentualGasImportado"
                      name="percentualGasImportado"
                      label="Percentual de GLCNi"
                      leftElement="%"
                      precision={7}
                      scale={4}
                      defaultValue={0}
                      editarFundoLeftElemento
                      bgLeftElement="gray.50"
                      isDisabled={readonly}
                    />
                  </GridItem>
                  <GridItem colSpan={{ base: 12, md: 4 }}>
                    <NumberInput
                      id="valorPartidaGLP"
                      name="valorPartidaGLP"
                      label="Valor de partida GLP"
                      leftElement="R$"
                      precision={7}
                      scale={4}
                      defaultValue={0}
                      editarFundoLeftElemento
                      bgLeftElement="gray.50"
                      isDisabled={readonly}
                    />
                  </GridItem>
                </SimpleGridForm>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </GridItem>
      </SimpleGridForm>
    </FormProvider>
  );
};
