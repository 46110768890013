import {
  Box,
  Flex,
  Icon,
  Text,
  Tr,
  Td,
  Tooltip,
  Checkbox,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { DecimalMask } from 'helpers/format/fieldsMasks';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';
import ConstanteFuncionalidades from 'constants/permissoes';
import { setDateMinHours } from 'helpers/data/setHoursDate';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';

import {
  ActionMenuItem,
  ActionsMenu,
} from 'components/update/Table/ActionsMenu';

import { ContaPaga, ContaVenceHoje, ContaVencida, ContaPagar } from 'icons';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';

import { formatDate } from 'helpers/format/formatStringDate';
import { Conta } from '../validationForm';
import { ModalDetalhesContaPagar } from '../ModalDetalhesContaPagar';
import { InformacaoStatusVencimento } from '../types';
import { ModalVerFatura } from '../MoodalVerFatura';

const informacoesStatusVencimento: {
  [key: string]: InformacaoStatusVencimento;
} = {
  contaPagar: {
    corFundo: 'white',
    corFonte: 'gray.700',
    corTooltip: 'gray.700',
    icone: ContaPagar,
    tamanhoTooltip: '32',
    descricaoConta: 'Conta a pagar',
  },
  contaPaga: {
    corFonte: 'gray.700',
    corFundo: 'aquamarine.50',
    corTooltip: 'aquamarine.700',
    icone: ContaPaga,
    descricaoConta: 'Conta paga',
    tamanhoTooltip: '28',
  },
  vencimentoHoje: {
    corFundo: 'white',
    corFonte: 'gray.700',
    corTooltip: 'orange.500',
    icone: ContaVenceHoje,
    descricaoConta: 'Vencimento hoje!',
    tamanhoTooltip: '36',
  },
  contaVencida: {
    corFundo: 'red.50',
    corFonte: 'gray.700',
    corTooltip: 'red.600',
    tamanhoTooltip: '28',
    icone: ContaVencida,
    descricaoConta: 'Conta vencida',
  },
};

type ContasPagarItemProps = {
  item: Conta;
  index: number;
  handleBaixarConta: () => void;
  handleToggleLinhaProduto: (index: number) => void;
  handleEditarConta: (id: string) => void;
  handleExcluirConta: () => void;
  getTotaisItem: (
    index: number
  ) => {
    valorTotalMulta: number;
    valorTotalJuros: number;
    valorTotalDescontos: number;
  };
  handleCancelarBaixa: (movimentacaoFinanceiraId: string) => Promise<void>;
};

export function ContasPagarItem({
  item,
  index,
  handleToggleLinhaProduto,
  handleEditarConta,
  handleBaixarConta,
  handleExcluirConta,
  getTotaisItem,
  handleCancelarBaixa,
}: ContasPagarItemProps) {
  const history = useHistory();

  const { casasDecimais } = usePadronizacaoContext();

  async function handlePushVerFatura(id?: string) {
    if (item.faturaId) {
      const { sucesso } = await ModalVerFatura({ idFatura: id });
      if (sucesso) {
        history.replace(ConstanteRotas.CONTAS_PAGAR);
      }
    }
  }

  function handlePushVerCompra() {
    if (item.entradaMercadoriaId) {
      const rotaEntradaMercadoria = SubstituirParametroRota(
        ConstanteRotas.ENTRADA_MERCADORIA_ENTRADA_MANUAL_VISUALIZAR,
        'id',
        item.entradaMercadoriaId
      );
      window.open(`${rotaEntradaMercadoria}`, '_blank');
    }
  }

  const totais = getTotaisItem(index);

  const deveAparecerTooltip =
    totais.valorTotalJuros ||
    totais.valorTotalMulta ||
    totais.valorTotalDescontos;

  const totalAPagar =
    totais.valorTotalJuros +
    totais.valorTotalMulta -
    totais.valorTotalDescontos;
  const valorDaConta = item.valor + totalAPagar;

  const contaEstaPaga =
    item.movimentacaoFinanceiraBaixa &&
    item.movimentacaoFinanceiraBaixa.length > 0 &&
    item.movimentacaoFinanceiraBaixa.some(
      (movimentacao) => movimentacao.dataPagamento
    );

  const dataHoje = new Date();
  const dataHojeSemHoras = setDateMinHours(new Date());

  const dataVencimento = new Date(item.dataVencimento);
  const dataVencimentoSemHoras = setDateMinHours(new Date(item.dataVencimento));

  const informacaoStatusVencimento = (() => {
    if (contaEstaPaga) {
      return informacoesStatusVencimento.contaPaga;
    }

    if (dataVencimentoSemHoras.getTime() === dataHojeSemHoras.getTime()) {
      return informacoesStatusVencimento.vencimentoHoje;
    }

    if (dataVencimento < dataHoje) {
      return informacoesStatusVencimento.contaVencida;
    }

    return informacoesStatusVencimento.contaPagar;
  })();

  async function handleAbrirModalDetalhe() {
    await ModalDetalhesContaPagar({
      id: item.movimentacaoFinanceiraId,
      informacaoStatusVencimento,
      casasDecimaisValor: 2,
      faturaId: item?.faturaId,
    });
  }

  async function handleCancelarBaixarConta() {
    ModalConfirmacaoExcluir({
      title: 'Cancelar a baixa?',
      text:
        'A baixa do pagamento será cancelada e a conta voltará a constar como um lançamento financeiro em aberto. Você tem certeza que deseja cancelar?',
      confirmButtonText: 'Sim, cancelar!',
      cancelButtonText: 'Cancelar',
      callback: async (ok: boolean) => {
        if (ok) {
          handleCancelarBaixa(item.movimentacaoFinanceiraId);
        }
      },
    });
  }

  const dropDownItens = ((): ActionMenuItem[] => {
    const baixarContaItem = {
      content: 'Baixar conta',
      onClick: () => {
        handleBaixarConta();
      },
      funcionalidade: ConstanteFuncionalidades.CONTAS_PAGAR_BAIXA,
    };

    const cancelarBaixaItem = {
      content: 'Cancelar baixa',
      onClick: () => {
        handleCancelarBaixarConta();
      },
      funcionalidade: ConstanteFuncionalidades.CONTAS_PAGAR_CANCELARBAIXA,
    };
    const verDetalhesContaItem = {
      content: 'Ver detalhes',
      onClick: handleAbrirModalDetalhe,
    };

    const baixarContaCancelarBaixaItem =
      item.tipoOperacao === IdentificacaoTipoOperacaoEnum.CONTAS_PAGAR ||
      IdentificacaoTipoOperacaoEnum.PAGAMENTO_COMPRA ||
      IdentificacaoTipoOperacaoEnum.COMPRA_MERCADORIA
        ? [...(contaEstaPaga ? [cancelarBaixaItem] : [baixarContaItem])]
        : contaEstaPaga
        ? []
        : [baixarContaItem];

    const removerItem =
      (contaEstaPaga &&
        item.tipoOperacao === IdentificacaoTipoOperacaoEnum.CONTAS_PAGAR) ||
      item?.faturaId
        ? []
        : [
            {
              content: 'Remover',
              onClick: () => {
                handleExcluirConta();
              },
              funcionalidade: ConstanteFuncionalidades.CONTAS_PAGAR_EXCLUIR,
            },
          ];

    if (item.faturaId) {
      return [
        ...baixarContaCancelarBaixaItem,
        {
          content: 'Ver fatura',
          onClick: () => {
            handlePushVerFatura(item.faturaId);
          },
          funcionalidade: ConstanteFuncionalidades.FATURA_VISUALIZAR,
        },
        verDetalhesContaItem,
        ...removerItem,
      ];
    }

    if (item.entradaMercadoriaId) {
      return [
        ...baixarContaCancelarBaixaItem,
        {
          content: 'Ver compra',
          onClick: handlePushVerCompra,
          funcionalidade:
            ConstanteFuncionalidades.ENTRADA_MERCADORIA_VISUALIZAR,
        },
        verDetalhesContaItem,
      ];
    }

    if (item.movimentacaoFinanceiraBaixa.length > 0) {
      return [
        ...baixarContaCancelarBaixaItem,
        verDetalhesContaItem,
        ...removerItem,
      ];
    }

    return [
      ...baixarContaCancelarBaixaItem,
      {
        content: 'Editar',
        onClick: () => {
          handleEditarConta(item.operacaoId);
        },
        funcionalidade: ConstanteFuncionalidades.CONTAS_PAGAR_ALTERAR,
      },
      verDetalhesContaItem,
      ...removerItem,
    ];
  })();

  return (
    <>
      <Box h={index === 0 ? '1' : '1'} />

      <Tr
        boxShadow="none !important"
        sx={{
          '& > td': {
            bg: informacaoStatusVencimento.corFundo,
          },
        }}
      >
        <Td>
          <Checkbox
            isDisabled={contaEstaPaga}
            isChecked={item.selecionado}
            onChange={() => handleToggleLinhaProduto(index)}
            boxShadow="inset 0px 3px 6px #00000029"
            colorScheme="primary"
            bg="white"
            mb="0"
          />
        </Td>

        <Td>
          <Text fontSize="sm" color={informacaoStatusVencimento.corFonte}>
            {formatDate(item.dataVencimento)}
          </Text>

          <Text mt="1" fontSize="xs">
            Parcela {item.parcela}
          </Text>
        </Td>

        <Td whiteSpace="nowrap">
          <Text fontSize="sm" color={informacaoStatusVencimento.corFonte}>
            {item.fornecedorRazaoSocial}
          </Text>

          <Text mt="1" fontSize="xs">
            {item.fornecedorNomeFantasia}
          </Text>
        </Td>

        <Td whiteSpace="nowrap">
          <Text fontSize="sm" color={informacaoStatusVencimento.corFonte}>
            {item.planoContas}
          </Text>

          <Text mt="1" fontSize="xs">
            {item.formaPagamento}
          </Text>
        </Td>

        <Tooltip
          isDisabled={!deveAparecerTooltip}
          borderRadius="md"
          w="44"
          alignItems="center"
          h="28"
          display="flex"
          justifyContent="left"
          placement="left-start"
          hasArrow
          fontSize="2xs"
          bg="gray.900"
          color="white"
          label={
            <Box ml="2">
              <Flex alignItems="center">
                <Text mr="2">Valor original:</Text>
                <Text fontSize="xs">
                  {DecimalMask(
                    item.valor,
                    casasDecimais.casasDecimaisValor,
                    casasDecimais.casasDecimaisValor
                  )}
                </Text>
              </Flex>
              <Flex mt="1" alignItems="center">
                <Text mr="2">Desconto:</Text>
                <Text fontSize="xs" color="yellow.100">
                  {DecimalMask(
                    totais.valorTotalDescontos,
                    casasDecimais.casasDecimaisValor,
                    casasDecimais.casasDecimaisValor
                  )}
                </Text>
              </Flex>
              <Flex mt="1" alignItems="center">
                <Text mr="2">Multa:</Text>
                <Text fontSize="xs" color="red.100">
                  {DecimalMask(
                    totais.valorTotalMulta,
                    casasDecimais.casasDecimaisValor,
                    casasDecimais.casasDecimaisValor
                  )}
                </Text>
              </Flex>
              <Flex mt="1" alignItems="center">
                <Text mr="2">Juros:</Text>
                <Text fontSize="xs" color="red.100">
                  {DecimalMask(
                    totais.valorTotalJuros,
                    casasDecimais.casasDecimaisValor,
                    casasDecimais.casasDecimaisValor
                  )}
                </Text>
              </Flex>
            </Box>
          }
        >
          <Td
            whiteSpace="nowrap"
            color={informacaoStatusVencimento.corFonte}
            fontSize="sm"
            background="red"
            isNumeric
          >
            <Text fontWeight={deveAparecerTooltip ? 'bold' : 'regular'}>
              {`R$ ${DecimalMask(valorDaConta, 2, 2)}`}
            </Text>
          </Td>
        </Tooltip>

        <Tooltip
          borderRadius="md"
          w={informacaoStatusVencimento.tamanhoTooltip}
          alignItems="center"
          h="40px"
          display="flex"
          justifyContent="center"
          placement="left"
          hasArrow
          fontSize="sm"
          bg={informacaoStatusVencimento.corTooltip}
          label={informacaoStatusVencimento.descricaoConta}
        >
          <Td fontSize="25px">
            <Icon as={informacaoStatusVencimento.icone} borderColor="white" />
          </Td>
        </Tooltip>

        <Td>
          <ActionsMenu items={dropDownItens} />
        </Td>
      </Tr>
    </>
  );
}
