import React, { memo } from 'react';
import { Circle, SquareProps } from '@chakra-ui/react';

interface StatusCircleProps extends SquareProps {
  hasValue?: boolean;
  isActive?: boolean;
}

export const StatusCircle = memo(
  ({ hasValue = true, isActive = false, ...rest }: StatusCircleProps) => {
    if (!hasValue) {
      return <Circle size="2" bg="black" {...rest} />;
    }

    return (
      <Circle size="2" bg={isActive ? 'green.500' : 'red.400'} {...rest} />
    );
  }
);
