import React, {
  useMemo,
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import { Badge } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import jsFileDownload from 'js-file-download';
import {
  Button,
  Box,
  Flex,
  GridItem,
  Icon,
  useMediaQuery,
  useToken,
} from '@chakra-ui/react';

import auth from 'modules/auth';
import api, { ResponseApi } from 'services/api';
import { ImprimirPDF } from 'helpers/impressoes/imprimirPDF';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import { getName } from 'helpers/enum/getName';
import obterConfigEmitirNFe from 'helpers/api/Loja/obterConfigEmitirNFe';
import { downloadArquivosXML } from 'helpers/api/NotaFiscal/downloadArquivosXML';
import { formatDateHourMinute } from 'helpers/format/formatStringDate';
import { useSignalRContext } from 'store/SignalR';
import ConstanteFuncionalidades from 'constants/permissoes';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import ConstanteRotasPDV from 'constants/rotasPDV';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ModelosFiscaisEnum from 'constants/enum/fiscal/modelosFiscais';
import { TipoRejeicaoNotaFiscal } from 'constants/enum/fiscal/tipoRejeicaoNotaFiscal';
import TipoCertificadoEnum from 'constants/enum/fiscal/tipoCertificado';
import StatusFiscaisEnum from 'constants/enum/fiscal/statusFiscal';
import { setDateMaxHours } from 'helpers/data/setHoursDate';
import AmbienteFiscalEnum from 'constants/enum/fiscal/ambienteFiscal';

import ManterFoco from 'components/Geral/ManterFoco';
import { RejeicaoInterface } from 'components/EmitirNotaFiscal/BoxRejeicao';
import { HeaderData } from 'components/Grid/Cabecalho';
import ButtonComOpcoes from 'components/Button/ButtonComOpcoes';
import { EmailModal } from 'components/PDV/CompartilharDocumentosFiscais/EmailModal';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { ModalConfirmacao } from 'components/Modal/ModalConfirmacao';
import Paginacao, {
  ForwardRefMethod,
  GridPaginadaConsulta,
  GridPaginadaRetorno,
} from 'components/Grid/Paginacao';
import { ModalAtencao } from 'components/Modal/ModalAtencao';
import ValidarPermissao from 'components/Validacao/ValidarPermissao';
import { BuscaAvancadaButton } from 'components/update/BuscaAvancadaButton';
import InputDateRange from 'components/PDV/InputDateRange';
import { CompartilharMenuIcon, InfoIcon } from 'icons';
import { ModalRejeicaoNotaFiscal } from 'components/Modal/ModalRejeicaoNotaFiscal';
import { ModalRelatorioDespacho } from 'components/Modal/ModalRelatorioDespacho';
import { ModalCompartilhar } from 'components/Compartilhar';
import { ButtonFuncionalidade } from 'components/update/Button/ButtonFuncionalidade';
import { ModalCompartilhar as Compartilhar } from 'components/Modal/ModalCompartilhar';
import { ButtonCadastrarNovo } from 'components/Layout/ButtonCadastrarNovo';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { LinkVisualizar } from 'styles';

import InutilizarModal from '../Formulario/InutilizarModal';
import CancelarModal from '../Formulario/CancelarModal';
import CartaCorrecaoModal from '../Formulario/CartaCorrecaoModal';
import BuscaAvancadaModal from './BuscaAvancadaModal';
import ModalTransmissaoNota from '../ModalTransmissaoNota';

interface NotasfiscaisFiltros {
  [x: string]: any;
}

interface NotaFiscal {
  id: string;
  cliente: string;
  status: number;
  numero: string;
  ambiente: number;
  mensagem: string;
  linkBaseConhecimento: string;
  tipoRejeicao: TipoRejeicaoNotaFiscal;
  mensagemEmail: string;
  statusDescricao: boolean;
  dataEmissao: any;
  modeloFiscal: number;
  operacao: string;
  numeroOperacao: string;
  operacaoId: string;
  volume: number;
  possuiCartaCorrecao: boolean;
}

const defaultValues = {
  numero: '',
  descricao: '',
  dataInicio: null,
  dataFim: null,
  status: null,
  modelo: ModelosFiscaisEnum.NFe,
};

const Listar = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [notasFiscais, setNotasFiscais] = useState([] as Array<NotaFiscal>);
  const [showInutilizarNota, setShowInutilizarNota] = useState(false);
  const [showCancelarNota, setShowCancelarNota] = useState(false);
  const [showCartaCorrecao, setShowCartaCorrecao] = useState(false);
  const [filtrosAtuais, setFiltrosAtuais] = useState<NotasfiscaisFiltros>(
    defaultValues
  );
  const [possuiConfigEmitirNFe, setPossuiConfigEmitirNFe] = useState(false);
  const [filtrosAvancadosAtivos, setfiltrosAvancadosAtivos] = useState(false);
  const [buscaAvancadaModalIsOpen, setBuscaAvancadaModalIsOpen] = useState(
    false
  );
  const [
    documentoFiscalIdSelecionado,
    setDocumentoFiscalIdSelecionado,
  ] = useState('');
  const [statusAlteracao, setStatusAlteracao] = useState(
    'Iniciando alteração de status da nota fiscal...'
  );
  const [titleModalTransmitirNota, setTitleModalTransmitirNota] = useState(
    'Alteração de status da nota fiscal'
  );
  const [showModalTransmissao, setShowModalTransmissao] = useState(false);

  const childRef = useRef<ForwardRefMethod>(null);

  const history = useHistory();

  const isMountedRef = useIsMountedRef();

  const orange500 = useToken('colors', 'orange.500');

  const formMethods = useForm();

  const { hubConnection, joinGroup } = useSignalRContext();

  const [isSmallerThan1200] = useMediaQuery('(max-width: 1200px)');
  const [isBiggerThan700] = useMediaQuery('(min-width: 700px)');

  const lojaId = auth.getLoja().id;
  const isTablet = isBiggerThan700 && isSmallerThan1200;

  const checarOpcaoEmitirNFeEstaHabilitada = useCallback(async () => {
    const podeEmitirNFe = await obterConfigEmitirNFe();

    if (podeEmitirNFe) {
      setPossuiConfigEmitirNFe(true);
    }
  }, []);

  const handleFiltros = useCallback(() => {
    let filtros = formMethods.getValues();

    filtros = { ...filtros, ...filtrosAtuais };

    if (filtros.dataFim) {
      const dataFimPartes = filtros.dataFim;

      filtros = {
        ...filtros,
        dataFim: setDateMaxHours(new Date(dataFimPartes)).toISOString(),
      };
    }

    if (filtros.dataInicio) {
      const dataInicioPartes = filtros.dataInicio;

      filtros = {
        ...filtros,
        dataInicio: dataInicioPartes.toISOString(),
      };
    }
    return filtros;
  }, [filtrosAtuais, formMethods]);

  const handleReset = useCallback(
    (orderColumn: string) => {
      const filtros = handleFiltros();

      if (JSON.stringify(filtros) !== JSON.stringify(filtrosAtuais)) {
        return childRef.current?.reset(orderColumn);
      }

      return () => {};
    },
    [filtrosAtuais, handleFiltros]
  );

  const handleHistoryPush = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history]
  );

  const handleRefresh = useCallback((newTotal?: number) => {
    return childRef.current?.refresh(newTotal);
  }, []);

  const paginationHandle = useCallback(
    async (gridPaginadaConsulta: GridPaginadaConsulta) => {
      setIsLoading(true);

      const filtros = handleFiltros();

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<NotaFiscal>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.NOTA_FISCAL_LISTAR_PAGINADO,
          gridPaginadaConsulta
        ),
        {
          params: filtros,
        }
      );

      if (response?.sucesso && isMountedRef.current) {
        setTotalRegistros(response.dados.total);
        setNotasFiscais(response.dados.registros);
      }

      if (isMountedRef.current) {
        setIsLoading(false);
      }
    },
    [handleFiltros, isMountedRef]
  );

  const excluirHandle = useCallback(
    async (id: string) => {
      ModalConfirmacaoExcluir({
        callback: async (ok: boolean) => {
          if (ok) {
            setIsLoading(true);

            const response = await api.delete<void, ResponseApi>(
              ConstanteEnderecoWebservice.NOTA_FISCAL_EXCLUIR,
              {
                params: { id },
              }
            );

            if (response?.sucesso) {
              toast.success('A nota fiscal foi removida com sucesso.');

              handleRefresh(totalRegistros - 1);
            }

            setIsLoading(false);
          }
        },
      });
    },
    [handleRefresh, totalRegistros]
  );

  const tratarAviso = useCallback(
    (aviso: string, statusNota?: number) => {
      try {
        const rejeicao = JSON.parse(aviso) as RejeicaoInterface;

        ModalRejeicaoNotaFiscal({
          ...rejeicao,
          callback: () => {
            if (statusNota === StatusFiscaisEnum.Autorizada) {
              handleRefresh();
            }
            history.push(ConstanteRotas.NOTA_FISCAL);
          },
        });
      } catch {
        ModalAtencao({
          text: aviso,
          width: 800,
          callback: () => {
            if (statusNota === StatusFiscaisEnum.Autorizada) {
              handleRefresh();
            }
            history.push(ConstanteRotas.NOTA_FISCAL);
          },
        });
      }
    },
    [handleRefresh, history]
  );

  const obterStatusNotaFiscal = useCallback(async (id: string) => {
    const response = await api.get<void, ResponseApi<number>>(
      ConstanteEnderecoWebservice.NOTA_FISCAL_OBTER_STATUS,
      {
        params: { id },
      }
    );
    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }
      if (response?.sucesso) {
        return response?.dados;
      }
    }
    return undefined;
  }, []);

  const handleAlterarStatusRetorno = useCallback(
    async (id: string) => {
      setIsLoading(true);
      let notaSendoTransmitida = false;

      joinGroup(`${id}_transmitindo-nota`);

      hubConnection.on('alterar-status-transmissao', (message: string) => {
        if (message) {
          notaSendoTransmitida = true;
          setStatusAlteracao(message);
          setShowModalTransmissao(true);
          setTitleModalTransmitirNota('Transmitindo nota fiscal');
        }
      });

      const response = await api.put<void, ResponseApi>(
        ConstanteEnderecoWebservice.NOTA_FISCAL_ALTERAR_STATUS_RETORNO,
        null,
        { params: { id } }
      );

      if (response) {
        if (response?.avisos) {
          if (!notaSendoTransmitida) {
            response.avisos.forEach((aviso: string) => toast.warning(aviso));
          } else if (!response.sucesso) {
            setShowModalTransmissao(false);
            const statusNota = await obterStatusNotaFiscal(id);
            tratarAviso(response.avisos[0], statusNota);
          }
        }

        if (response?.sucesso) {
          if (
            notaSendoTransmitida &&
            response.dados === Number(TipoCertificadoEnum.A3)
          ) {
            const timeout = window.setTimeout(
              () => {
                setShowModalTransmissao(false);
                ModalAtencao({
                  title: 'A comunicação com o certificado digital A3 falhou',
                  text:
                    'Verifique se o certificado digital está conectado no computador e se o aplicativo Módulo desktop está sendo executado.',
                });

                handleRefresh();
              },
              1000 * 60 // 1 minuto
            );

            hubConnection.off('sucesso-transmissao-a3');
            hubConnection.on('sucesso-transmissao-a3', () => {
              window.clearTimeout(timeout);

              setShowModalTransmissao(false);
              toast.success('A nota fiscal foi transmitida com sucesso.');
              handleRefresh();
            });

            hubConnection.off('rejeicao-transmissao-a3');
            hubConnection.on('rejeicao-transmissao-a3', (message: string) => {
              window.clearTimeout(timeout);
              setShowModalTransmissao(false);
              tratarAviso(message);

              handleRefresh();
            });
          }
          if (notaSendoTransmitida) {
            setShowModalTransmissao(false);
            toast.success('A nota fiscal foi transmitida com sucesso.');
            handleRefresh();
          } else {
            toast.success('O status da nota fiscal foi alterada com sucesso.');
            handleRefresh();
          }
        }
      }

      setIsLoading(false);
    },
    [
      handleRefresh,
      hubConnection,
      joinGroup,
      obterStatusNotaFiscal,
      tratarAviso,
    ]
  );

  const duplicarHandle = useCallback(
    async (id: string) => {
      await ModalConfirmacao({
        cancelButtonText: 'Não, cancelar!',
        confirmButtonText: 'Sim, continuar!',
        title: 'Deseja duplicar a nota?',
        text:
          'A nota será duplicada e adicionada a listagem, deseja continuar?',
        callback: async (ok: boolean) => {
          if (ok) {
            setIsLoading(true);
            const response = await api.post<void, ResponseApi<string>>(
              `${ConstanteEnderecoWebservice.NOTA_FISCAL_DUPLICAR}/${id}  `
            );
            if (response) {
              if (response?.avisos) {
                response.avisos.forEach((item: string) => toast.warning(item));
              }

              if (response?.sucesso) {
                toast.success('A nota fiscal foi duplicada com sucesso.');
                handleRefresh(totalRegistros + 1);
                history.push(
                  SubstituirParametroRota(
                    ConstanteRotas.NOTA_FISCAL_ALTERAR,
                    'id',
                    response.dados
                  )
                );
              }
            }
            setIsLoading(false);
          }
        },
      });
    },
    [handleRefresh, history, totalRegistros]
  );

  const handleCadastrarNovaNF = async () => {
    if (possuiConfigEmitirNFe) {
      handleHistoryPush(ConstanteRotas.NOTA_FISCAL_CADASTRAR);
    } else {
      toast.warning(
        'Para gerar nota fiscal é necessário habilitar a opção Emitir NFe no cadastro da loja.'
      );
    }
  };

  const downloadXmlHandle = async (
    documentoFiscalId: string,
    numero: string
  ) => {
    setIsLoading(true);
    await downloadArquivosXML(documentoFiscalId, numero);

    setIsLoading(false);
  };

  const danfeHandle = async (documentoFiscalId: string, numero: string) => {
    setIsLoading(true);

    const response = await api.get<void, ResponseApi<string>>(
      `${ConstanteEnderecoWebservice.NOTA_FISCAL_DANFE}/${documentoFiscalId}`
    );

    if (response.sucesso) {
      jsFileDownload(
        Uint8Array.from(atob(response.dados), (c) => c.charCodeAt(0)),
        numero ? `Danfe_${numero}.pdf` : `Danfe.pdf`
      );
    }

    if (response?.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    setIsLoading(false);
  };

  const verificarSituacaoHandle = async (documentoFiscalId: string) => {
    setIsLoading(true);

    const response = await api.post<void, ResponseApi<number | undefined>>(
      `${ConstanteEnderecoWebservice.NOTA_FISCAL_CONSULTAR_PROTOCOLO}/${documentoFiscalId}`
    );

    if (response.sucesso) {
      if (response.dados && response.dados === Number(TipoCertificadoEnum.A3)) {
        const timeout = window.setTimeout(
          () => {
            setIsLoading(false);
            ModalAtencao({
              title: 'A comunicação com o certificado digital A3 falhou',
              text:
                'Verifique se o certificado digital está conectado no computador e se o aplicativo Módulo desktop está sendo executado.',
            });
          },
          1000 * 60 // 1 minuto
        );

        await joinGroup(
          `${documentoFiscalId}_transmitindo-consultar-protocolo`
        );

        hubConnection.off('sucesso-transmissao-a3');
        hubConnection.on('sucesso-transmissao-a3', () => {
          window.clearTimeout(timeout);

          setIsLoading(false);
          toast.success('A situação da nota foi consultada com sucesso.');
          handleRefresh();
        });

        hubConnection.off('rejeicao-transmissao-a3');
        hubConnection.on('rejeicao-transmissao-a3', (message: string) => {
          window.clearTimeout(timeout);

          setIsLoading(false);
          toast.warning(message);
        });

        return;
      }

      toast.success('A situação da nota foi consultada com sucesso.');
    }

    handleRefresh();

    if (response.avisos) {
      ModalAtencao({
        text: response.avisos[0],
        width: 800,
      });
    }
  };

  const enviarEmailHandle = useCallback(
    async (documentoFiscalId: string) => {
      setIsLoading(true);

      const response = await api.post<void, ResponseApi<boolean>>(
        `${ConstanteEnderecoWebservice.NOTA_FISCAL_ENVIAR_EMAIL}/${documentoFiscalId}`,
        []
      );

      handleRefresh();
      if (response.avisos) {
        ModalAtencao({
          text: response.avisos[0],
          width: 800,
        });
      } else if (response.sucesso) {
        toast.success('O e-mail foi enviado com sucesso.');
      }
    },
    [handleRefresh]
  );

  const handleImprimirRelatorioNotaFiscal = useCallback(async () => {
    const filtros = handleFiltros();

    const response = await api.get<void, ResponseApi<any | undefined>>(
      ConstanteEnderecoWebservice.NOTA_FICAL_IMPRIMIR_RELATORIO_LISTAGEM,
      {
        params: filtros,
      }
    );

    if (response.sucesso && response.dados) {
      ImprimirPDF(response.dados, 'relatorioNotaFiscal');
    }

    if (response?.avisos) {
      response.avisos.forEach((item: string) => toast.warning(item));
    }

    setIsLoading(false);
  }, [handleFiltros]);

  async function handleImprimirCartaCorrecao(id: string) {
    setIsLoading(true);
    const response = await api.get<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.NOTA_FISCAL_IMPRIMIR_CARTA_CORRECAO,
      {
        params: { documentoFiscalId: id },
      }
    );

    if (response.avisos) {
      response.avisos.forEach((avisos) => toast.warning(avisos));
    }

    if (response.dados && response.sucesso) {
      ImprimirPDF(response.dados, 'relatorioCartaCorrecao');
    }
    setIsLoading(false);
  }

  const handleEnviarEmailNotaFiscal = useCallback(async () => {
    const filtrosData = handleFiltros();
    const filtros = {
      Numero: filtrosAtuais?.numero || '',
      Descricao: filtrosAtuais?.descricao || '',
      Status: filtrosAtuais.status || '',
      DataFim: filtrosData.dataFim || '',
      DataInicio: filtrosData.dataInicio || '',
      Modelo: filtrosAtuais.modelo || '',
      LojaId: lojaId,
    };
    EmailModal({
      filtrosNotaFiscal: filtros,
    });
  }, [handleFiltros, filtrosAtuais, lojaId]);

  const handleImprimirOperacao = useCallback(() => {
    Compartilhar({
      items: [
        {
          titulo: 'Imprimir relatório nota fiscal',
          onClickImpressao: (onClose) => {
            handleImprimirRelatorioNotaFiscal();
            onClose();
          },
          onClickEmail: (onClose) => {
            handleEnviarEmailNotaFiscal();
            onClose();
          },
        },
      ],
    });
  }, [handleImprimirRelatorioNotaFiscal, handleEnviarEmailNotaFiscal]);

  const handleEmitirCupomFiscal = (id: string) => {
    history.push(
      SubstituirParametroRota(ConstanteRotasPDV.PDV_FINALIZAR, 'id', id)
    );
  };

  const handleAbrirModalCompartilhar = useCallback(
    async (
      id: string,
      numeroNota: string,
      permiteCompartilharPorImpressao: boolean,
      compartilharPorXml: boolean,
      notaAutorizada: boolean,
      operacaoId: string,
      modeloNotaFiscal: number,
      notaFiscalStatus: number,
      possuiCartaCorrecao: boolean
    ) => {
      const exibirToast = () => {
        toast.warning('Você não possui permissão para realizar essa ação');
      };
      await ModalCompartilhar({
        operacaoId,
        sharedPerDepartment: {
          relatorioListarNotaFiscal: {
            impressao: () =>
              permiteCompartilharPorImpressao
                ? danfeHandle(id, numeroNota)
                : exibirToast(),
            email: () =>
              notaAutorizada ? enviarEmailHandle(id) : exibirToast(),
            xml: () =>
              compartilharPorXml
                ? downloadXmlHandle(id, numeroNota)
                : exibirToast(),
            cartaCorrecao: () => handleImprimirCartaCorrecao(id),
          },
        },
        sharedWhatsApp: false,
        mostrarCompartilharPorXml: compartilharPorXml,
        mostrarCompartilharPorEmail: notaAutorizada,
        mostrarCompartilharPorImpressao: permiteCompartilharPorImpressao,
        usuarioPodeExportarArquivoCSV: false,
        hasOperacao: !!operacaoId,
        possuiCartaCorrecao,
        modeloNotaFiscal,
        notaFiscalStatus,
      });
    },
    [enviarEmailHandle]
  );

  const headerData: HeaderData = useMemo(() => {
    return {
      orderColumn: 'DataEmissao',
      orderDirection: 'desc',
      columns: [
        {
          displayName: 'Data de emissão',
          name: 'DataEmissao',
          isOrderable: true,
          width: '200px',
        },
        {
          displayName: 'Modelo',
          name: 'ModeloFiscal',
          isOrderable: true,
          width: '75px',
        },
        {
          displayName: 'Número',
          name: 'Numero',
          isOrderable: true,
          width: '200px',
        },
        {
          displayName: 'Cliente',
          name: 'Cliente',
          isOrderable: false,
          width: 'auto',
        },
        {
          displayName: 'Status',
          name: 'Status',
          isOrderable: false,
          width: '200px',
        },
        {
          displayName: 'Operação',
          name: 'operacao',
          isOrderable: false,
          width: '150px',
        },
        {
          displayName: 'Ações',
          name: 'Acoes',
          isOrderable: false,
          width: '38px',
        },
      ],
    };
  }, []);

  const NotasList = useMemo(() => {
    return (
      <Paginacao
        ref={childRef}
        total={totalRegistros}
        paginationHandle={paginationHandle}
        isLoading={isLoading}
        headerData={headerData}
      >
        {notasFiscais.map((notaFiscal) => {
          const { possuiCartaCorrecao } = notaFiscal;

          const notaEmDigitacao =
            notaFiscal.status === StatusFiscaisEnum.EmDigitacao;
          const notaAutorizada =
            notaFiscal.status === StatusFiscaisEnum.Autorizada;
          const notaRejeitada =
            notaFiscal.status === StatusFiscaisEnum.Rejeitada;
          const notaCancelada =
            notaFiscal.status === StatusFiscaisEnum.Cancelada;
          const notaInutilizada =
            notaFiscal.status === StatusFiscaisEnum.Inutilizada;
          const notaEmProcessamento =
            notaFiscal.status === StatusFiscaisEnum.EmProcessamento;
          const notaEmUsoDenegado =
            notaFiscal.status === StatusFiscaisEnum.UsoDenegado;
          const notaRetornoIndisponivel =
            notaFiscal.status === StatusFiscaisEnum.RetornoIndisponivel;

          const notaNFe = notaFiscal.modeloFiscal === ModelosFiscaisEnum.NFe;
          const notaNFCe = notaFiscal.modeloFiscal === ModelosFiscaisEnum.NFCe;

          const permiteCompartilharDanfe =
            notaNFe && (notaAutorizada || notaEmDigitacao);
          const permiteCompartilharPorXml =
            notaRejeitada || notaInutilizada || notaCancelada || notaAutorizada;
          const permiteCompartilharCupomFiscal = notaNFCe && notaAutorizada;
          const permitirEmitirCupomFiscal =
            notaNFCe && (notaEmDigitacao || notaRejeitada);
          const naoPermiteCompartilhar =
            (notaNFCe && notaEmDigitacao && !possuiCartaCorrecao) ||
            notaEmProcessamento ||
            notaEmUsoDenegado ||
            notaRetornoIndisponivel;

          const permiteCompartilharPorImpressao =
            permiteCompartilharDanfe ||
            permiteCompartilharCupomFiscal ||
            possuiCartaCorrecao;

          return (
            <tr key={notaFiscal.id}>
              <td>{formatDateHourMinute(notaFiscal.dataEmissao)}</td>
              <td>
                {notaFiscal.modeloFiscal &&
                  `${getName(ModelosFiscaisEnum, notaFiscal.modeloFiscal)}`}
              </td>
              <td>
                {notaFiscal.numero}
                {notaFiscal.numero &&
                  notaFiscal.ambiente === AmbienteFiscalEnum.Homologacao && (
                    <Badge
                      style={{
                        marginLeft: '10px',
                        background: 'var(--sti-ck-colors-red-300)',
                        color: 'var(--white)',
                        fontSize: '11px',
                      }}
                    >
                      Homologação
                    </Badge>
                  )}
              </td>
              <td>
                {notaEmDigitacao || notaRejeitada ? (
                  <ValidarPermissao
                    funcionalidade={ConstanteFuncionalidades.NOTA_FISCAL_EMITIR}
                    tooltipWrap="hidden"
                  >
                    {({ permitido }) => (
                      <LinkVisualizar
                        id="link-visualizar"
                        bloqueado={permitido ? 0 : 1}
                        to={
                          permitido
                            ? SubstituirParametroRota(
                                ConstanteRotas.NOTA_FISCAL_ALTERAR,
                                'id',
                                notaFiscal.id
                              )
                            : ''
                        }
                      >
                        {notaFiscal.cliente}
                      </LinkVisualizar>
                    )}
                  </ValidarPermissao>
                ) : (
                  notaFiscal.cliente
                )}
              </td>
              <td
                style={{
                  color: notaRejeitada
                    ? 'var(--sti-ck-colors-red-300)'
                    : 'unset',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {notaFiscal.statusDescricao}
                {notaRejeitada && notaFiscal.mensagem && (
                  <InfoIcon
                    color="var(--sti-ck-colors-red-300)"
                    size={21}
                    style={{ marginLeft: '5px' }}
                    cursor="pointer"
                    onClick={() =>
                      ModalRejeicaoNotaFiscal({
                        Mensagem: notaFiscal.mensagem,
                        LinkBaseConhecimento: notaFiscal.linkBaseConhecimento,
                        TipoRejeicao: notaFiscal.tipoRejeicao,
                      })
                    }
                  />
                )}
                {notaAutorizada && notaFiscal.mensagemEmail && (
                  <InfoIcon
                    color={orange500}
                    size={21}
                    style={{ marginLeft: '10px' }}
                    cursor="pointer"
                    onClick={() =>
                      ModalAtencao({
                        title: 'E-mail',
                        text: notaFiscal.mensagemEmail,
                        width: 800,
                      })
                    }
                  />
                )}
              </td>
              <td>
                {notaFiscal.numeroOperacao &&
                  `${notaFiscal.numeroOperacao} | ${notaFiscal.operacao}`}
              </td>
              <td>
                <ButtonComOpcoes
                  id="mostrarMais"
                  dropdownItems={[
                    {
                      hidden: !notaRetornoIndisponivel,
                      title: 'Visualizar',
                      onClick: () => {
                        handleHistoryPush(
                          SubstituirParametroRota(
                            ConstanteRotas.NOTA_FISCAL_VISUALIZAR,
                            'id',
                            notaFiscal.id
                          )
                        );
                      },
                    },
                    {
                      hidden: !((notaEmDigitacao || notaRejeitada) && notaNFe),
                      title: 'Editar',
                      onClick: () => {
                        handleHistoryPush(
                          SubstituirParametroRota(
                            ConstanteRotas.NOTA_FISCAL_ALTERAR,
                            'id',
                            notaFiscal.id
                          )
                        );
                      },
                      funcionalidadePermissao:
                        ConstanteFuncionalidades.NOTA_FISCAL_EMITIR,
                    },
                    {
                      hidden: !(notaEmDigitacao || notaRejeitada),
                      title: 'Remover',
                      onClick: () => {
                        excluirHandle(notaFiscal.id);
                      },
                      funcionalidadePermissao:
                        ConstanteFuncionalidades.NOTA_FISCAL_EMITIR,
                    },
                    {
                      hidden: !notaRetornoIndisponivel,
                      title: 'Alterar status retorno',
                      onClick: () => {
                        handleAlterarStatusRetorno(notaFiscal.id);
                      },
                      funcionalidadePermissao:
                        ConstanteFuncionalidades.NOTA_FISCAL_EMITIR,
                    },
                    {
                      hidden: !(notaAutorizada && notaNFe),
                      title: 'Carta de correção',
                      onClick: () => {
                        setDocumentoFiscalIdSelecionado(notaFiscal.id);
                        setShowCartaCorrecao(true);
                      },
                    },
                    {
                      hidden: !notaNFe || notaRetornoIndisponivel,
                      title: 'Etiqueta de despacho',
                      onClick: () => {
                        ModalRelatorioDespacho({
                          documentoFiscalId: notaFiscal.id,
                          volume:
                            notaFiscal.volume === 0 ? 1 : notaFiscal.volume,
                        });
                      },
                    },
                    {
                      hidden: !(
                        notaEmDigitacao ||
                        notaAutorizada ||
                        notaCancelada ||
                        notaRejeitada
                      ),
                      title: 'Duplicar',
                      onClick: () => {
                        duplicarHandle(notaFiscal.id);
                      },
                      funcionalidadePermissao:
                        ConstanteFuncionalidades.NOTA_FISCAL_EMITIR,
                    },
                    {
                      hidden: !(
                        notaFiscal.status === StatusFiscaisEnum.Autorizada
                      ),
                      title: 'Cancelar',
                      onClick: () => {
                        setDocumentoFiscalIdSelecionado(notaFiscal.id);
                        setShowCancelarNota(true);
                      },
                    },
                    {
                      hidden: naoPermiteCompartilhar,
                      title: 'Compartilhar',
                      onClick: () => {
                        handleAbrirModalCompartilhar(
                          notaFiscal.id,
                          notaFiscal.numero,
                          permiteCompartilharPorImpressao,
                          permiteCompartilharPorXml,
                          notaAutorizada,
                          notaFiscal.operacaoId,
                          notaFiscal.modeloFiscal,
                          notaFiscal.status,
                          possuiCartaCorrecao
                        );
                      },
                    },
                    {
                      hidden: !notaEmProcessamento,

                      title: 'Verificar situação',
                      onClick: () => {
                        verificarSituacaoHandle(notaFiscal.id);
                      },
                    },
                    {
                      hidden: !permitirEmitirCupomFiscal,
                      title: 'Emitir cupom fiscal',
                      onClick: () => {
                        handleEmitirCupomFiscal(notaFiscal.operacaoId);
                      },
                    },
                  ]}
                />
              </td>
            </tr>
          );
        })}
      </Paginacao>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    totalRegistros,
    paginationHandle,
    isLoading,
    headerData,
    notasFiscais,
    handleHistoryPush,
    excluirHandle,
  ]);

  const onHideInutilizarNota = () => {
    setShowInutilizarNota(false);
    handleRefresh();
  };

  const onHideCancelarNota = () => {
    setShowCancelarNota(false);
    handleRefresh();
  };

  const onHideCartaCorrecao = () => {
    setShowCartaCorrecao(false);
    handleRefresh();
  };

  const handleAbrirModal = useCallback(() => {
    setBuscaAvancadaModalIsOpen(true);
  }, []);

  const handleSubmitFiltros = formMethods.handleSubmit((data) => {
    handleReset('DataEmissao');
    setFiltrosAtuais(data);
  });

  useEffect(() => {
    checarOpcaoEmitirNFeEstaHabilitada();
  }, [checarOpcaoEmitirNFeEstaHabilitada]);

  return (
    <FormProvider {...formMethods}>
      <>
        <ManterFoco>
          <SimpleGridForm
            gridGap={['12px', '16px', '16px', '16px', '24px']}
            mb="24px"
          >
            <GridItem colSpan={[12, 6, 4, 4, 4, 4]} minW="250px">
              <InputDateRange
                borderColor="gray.100"
                borderRadius="md"
                endDateName="dataFim"
                maxDate={new Date()}
                onConfirm={handleSubmitFiltros}
                placeholder="Selecione um período"
                startDateName="dataInicio"
              />
            </GridItem>
            <GridItem colSpan={[12, 3, 5, 5, 5, 4]}>
              <Flex
                flexDir={['column', 'row']}
                justifyContent={['center', 'start']}
                gap="8px"
              >
                <BuscaAvancadaButton
                  borderRadius="full"
                  colorScheme={filtrosAvancadosAtivos ? 'blue' : 'gray'}
                  variant={filtrosAvancadosAtivos ? 'solid' : 'outlineDefault'}
                  bg="transparent"
                  height="36px"
                  borderWidth={['1px', '0px', '0px', '1px']}
                  width={['100%', 'auto']}
                  setIsModalBuscaAvancadaOpen={handleAbrirModal}
                  hasFilters={filtrosAvancadosAtivos}
                  justifyContent="center"
                  px={isTablet ? '0px' : '12px'}
                  hasText={!isTablet}
                  sx={{
                    span: {
                      marginRight: isTablet ? '0px' : '8px',
                    },
                  }}
                >
                  {isTablet
                    ? ''
                    : filtrosAvancadosAtivos
                    ? 'Filtro selecionado'
                    : 'Busca avançada'}
                </BuscaAvancadaButton>

                <Button
                  borderRadius="full"
                  variant="outlineDefault"
                  colorScheme="gray"
                  borderColor="gray.100"
                  borderWidth={['1px', '0px', '0px', '1px']}
                  leftIcon={
                    <Icon as={CompartilharMenuIcon} mr="0px" boxSize="18px" />
                  }
                  height="36px"
                  px={isTablet ? '0px' : '12px'}
                  onClick={handleImprimirOperacao}
                  sx={{
                    span: {
                      marginRight: isTablet ? '0px' : '8px',
                    },
                  }}
                >
                  {isTablet ? '' : 'Compartilhar'}
                </Button>
                <ButtonFuncionalidade
                  borderRadius="full"
                  colorScheme="gray"
                  variant="outlineDefault"
                  borderWidth={['1px', '0px', '0px', '1px']}
                  borderColor="gray.100"
                  funcionalidade={ConstanteFuncionalidades.NOTA_FISCAL_EMITIR}
                  id="inutilizarNota"
                  px={isTablet ? '0px' : '12px'}
                  leftIcon={
                    <Icon
                      as={IoIosCloseCircleOutline}
                      fontSize="lg"
                      mr="0px"
                      boxSize="26px"
                    />
                  }
                  width={isTablet ? 'auto' : 'full'}
                  height="36px"
                  name="inutilizarNota"
                  onClick={() => setShowInutilizarNota(true)}
                  type="button"
                  iconSide="left"
                  showTooltipIcon={!isTablet}
                  sx={{
                    span: {
                      marginRight: isTablet ? '0px' : '8px',
                    },
                  }}
                >
                  {isTablet ? '' : 'Inutilizar'}
                </ButtonFuncionalidade>
              </Flex>
            </GridItem>
            <GridItem colSpan={[12, 3, 3, 3, 3, 4]}>
              <Flex justifyContent="flex-end">
                <Box width={['full', 'full', '160px', '160px']}>
                  <ButtonCadastrarNovo
                    borderRadius="full"
                    colorScheme={possuiConfigEmitirNFe ? 'secondary' : 'gray'}
                    funcionalidade={ConstanteFuncionalidades.NOTA_FISCAL_EMITIR}
                    id="cadastrarNovo"
                    exibirIcone={!isTablet}
                    maxW={['full', 'full', '160px', '160px']}
                    name="cadastrarNovo"
                    iconSide="left"
                    minW="145px"
                    height="36px"
                    onClick={() => handleCadastrarNovaNF()}
                  >
                    Cadastrar Novo
                  </ButtonCadastrarNovo>
                </Box>
              </Flex>
            </GridItem>
          </SimpleGridForm>
        </ManterFoco>
        <BuscaAvancadaModal
          show={buscaAvancadaModalIsOpen}
          filtros={filtrosAtuais}
          onHide={(willReset?: boolean) => {
            setBuscaAvancadaModalIsOpen(false);

            if (willReset) {
              setfiltrosAvancadosAtivos(false);
              formMethods.reset({ ...defaultValues });
              setFiltrosAtuais({ ...defaultValues });
            }
          }}
          onSubmit={(novosFiltros) => {
            handleReset('DataEmissao');
            setfiltrosAvancadosAtivos(true);

            formMethods.reset({ ...novosFiltros });
            setFiltrosAtuais(novosFiltros);

            setBuscaAvancadaModalIsOpen(false);
          }}
        />
        {NotasList}
      </>
      <InutilizarModal
        show={showInutilizarNota}
        onHide={onHideInutilizarNota}
      />
      <CancelarModal
        show={showCancelarNota}
        onHide={onHideCancelarNota}
        documentoFiscalId={documentoFiscalIdSelecionado}
      />
      <CartaCorrecaoModal
        show={showCartaCorrecao}
        onHide={onHideCartaCorrecao}
        documentoFiscalId={documentoFiscalIdSelecionado}
      />
      <ModalTransmissaoNota
        show={showModalTransmissao}
        message={statusAlteracao}
        title={titleModalTransmitirNota}
      />
    </FormProvider>
  );
};

export default Listar;
