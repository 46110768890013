import axios from 'axios';
import { toast } from 'react-toastify';
import jsFileDownload from 'js-file-download';
import JSZip from 'jszip';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

type DocumentoFiscalArquivoXml = {
  nomeArquivo: string;
  arquivoSincronizado: boolean;
  arquivo: string;
  url: string;
};

async function processFiles(
  response: ResponseApi<DocumentoFiscalArquivoXml[]>,
  zip: JSZip,
  documentNumber: string
) {
  const filePromises = response.dados.map(
    async ({ arquivo, nomeArquivo, arquivoSincronizado, url }) => {
      if (arquivoSincronizado) {
        const res = await axios.get<Blob>(url, { responseType: 'blob' });
        const data = new Blob([res.data], { type: 'application/xml' });
        zip.file(nomeArquivo, data);
      } else {
        zip.file(
          nomeArquivo,
          Uint8Array.from(atob(arquivo), (c) => c.charCodeAt(0))
        );
      }
    }
  );

  await Promise.all(filePromises);

  const content = await zip.generateAsync({ type: 'blob' });
  jsFileDownload(content, `ArquivosNotaFiscal_${documentNumber}.zip`);
}

export async function downloadArquivosXML(
  documentoFiscalId: string,
  numero: string
) {
  const response = await api.get<
    void,
    ResponseApi<Array<DocumentoFiscalArquivoXml>>
  >(ConstanteEnderecoWebservice.NOTA_FISCAL_LISTAR_ARQUIVOS_XML, {
    params: { documentoFiscalId },
  });

  if (response) {
    if (response.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (response.sucesso && response.dados.length > 0) {
      const zip = JSZip();

      if (response.dados.length === 1) {
        const {
          arquivo,
          nomeArquivo,
          arquivoSincronizado,
          url,
        } = response.dados[0];

        if (!arquivoSincronizado) {
          jsFileDownload(
            Uint8Array.from(atob(arquivo), (c) => c.charCodeAt(0)),
            nomeArquivo
          );
        } else {
          const res = await axios.get(url, {
            responseType: 'blob',
          });
          jsFileDownload(res.data, nomeArquivo);
        }
      } else {
        processFiles(response, zip, numero);
      }
    }
  }
}
