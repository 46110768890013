import React, { useState, useCallback, useRef } from 'react';
import { Box, GridItem, Td, Tr, Link } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm, FormProvider } from 'react-hook-form';

import auth from 'modules/auth';
import ConstanteFuncionalidades from 'constants/permissoes';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import { shallowEqual } from 'helpers/validation/shallowEqual';
import api, { ResponseApi } from 'services/api';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';

import {
  GridPaginadaConsulta,
  GridPaginadaRetorno,
} from 'components/Grid/Paginacao';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { ModalConfirmacaoInativar } from 'components/Modal/ModalConfirmacaoInativar';
import { SearchInput } from 'components/update/Input/SearchInput';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { FilterSelect } from 'components/update/Select/FilterSelect';
import { ButtonCadastrarNovo } from 'components/Layout/ButtonCadastrarNovo';
import {
  PagedTable,
  PagedTableForwardRefData,
} from 'components/update/Table/PagedTable';
import { StatusCircle } from 'components/update/Table/StatusCircle';

type FormData = { ativo: boolean; nome: string };

const formDefaultValues = {
  ativo: true,
  nome: '',
};

interface Transportadora {
  id: string;
  nome: string;
  apelido: string;
  telefone: string;
  ativo: boolean;
}

const Listar = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentFilters, setCurrentFilters] = useState<FormData>(
    formDefaultValues
  );
  const [transportadoras, setTransportadoras] = useState(
    [] as Array<Transportadora>
  );
  const [totalRegistros, setTotalRegistros] = useState(0);

  const pageIsLoaded = useRef(false);
  const childRef = useRef<PagedTableForwardRefData>(null);

  const formMethods = useForm<FormData>({
    defaultValues: formDefaultValues,
  });

  const { handleSubmit, setFocus } = formMethods;

  const history = useHistory();
  const isMountedRef = useIsMountedRef();

  const permissaoTransportadoraAlterar = auth.possuiPermissao(
    ConstanteFuncionalidades.TRANSPORTADORA_ALTERAR
  );

  const permissaoTransportadoraVisualizar = auth.possuiPermissao(
    ConstanteFuncionalidades.TRANSPORTADORA_VISUALIZAR
  );

  const handleHistoryPush = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history]
  );

  const getNomeLinkHref = useCallback(
    (id: string) => {
      let href = '';

      if (permissaoTransportadoraAlterar.permitido) {
        href = SubstituirParametroRota(
          ConstanteRotas.TRANSPORTADORA_ALTERAR,
          'id',
          id
        );
      } else if (permissaoTransportadoraVisualizar.permitido) {
        href = SubstituirParametroRota(
          ConstanteRotas.TRANSPORTADORA_VISUALIZAR,
          'id',
          id
        );
      }

      return href;
    },
    [permissaoTransportadoraAlterar, permissaoTransportadoraVisualizar]
  );

  const paginationHandle = useCallback(
    async (gridPaginadaConsulta: GridPaginadaConsulta) => {
      setIsLoading(true);

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<Transportadora>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.TRANSPORTADORA_LISTAR_PAGINADO,
          gridPaginadaConsulta
        ),
        { params: { ...currentFilters } }
      );

      if (response?.sucesso && isMountedRef.current) {
        setTotalRegistros(response.dados.total);
        setTransportadoras(response.dados.registros);
      }

      if (isMountedRef.current) {
        setIsLoading(false);

        if (!pageIsLoaded.current) {
          pageIsLoaded.current = true;

          setFocus('nome');
        }
      }
    },
    [currentFilters, isMountedRef, setFocus]
  );

  const handleRefresh = useCallback(() => {
    return childRef.current?.reload();
  }, []);

  const handleReset = handleSubmit((data) => {
    const filtersIsDirty = !shallowEqual(data, currentFilters || {});

    if (filtersIsDirty) {
      setCurrentFilters(data);
    }
  });

  const excluirHandle = useCallback(
    async (transportadoraId: string, ativo: boolean) => {
      ModalConfirmacaoExcluir({
        callback: async (ok: boolean) => {
          if (ok) {
            setIsLoading(true);

            const response = await api.delete<void, ResponseApi>(
              ConstanteEnderecoWebservice.TRANSPORTADORA_EXCLUIR,
              {
                params: { id: transportadoraId },
              }
            );

            if (response?.sucesso) {
              toast.success('O cadastro foi removido com sucesso.');

              handleRefresh();
            }

            if (response) {
              ModalConfirmacaoInativar({
                response,
                rotaWebService:
                  ConstanteEnderecoWebservice.TRANSPORTADORA_INATIVAR,
                id: transportadoraId,
                ativo,
                callback: (okInativar: boolean) => {
                  if (okInativar) handleRefresh();
                },
              });
            }

            setIsLoading(false);
          }
        },
      });
    },
    [handleRefresh]
  );

  const headerData = [
    {
      content: <StatusCircle hasValue={false} />,
      key: 'Ativo',
      isOrderable: true,
      width: '37px',
    },
    {
      content: `Razão social / Nome`,
      key: 'Nome',
      isOrderable: true,
    },
    {
      content: 'Nome fantasia / Apelido',
      key: 'Apelido',
      isOrderable: true,
    },
    {
      content: 'Telefone',
      key: 'Telefone',
      isOrderable: true,
    },
    {
      content: 'Ações',
      key: 'Acoes',
      isOrderable: false,
      width: '38px',
    },
  ];

  return (
    <SimpleGridForm gap={{ base: '10px', sm: '10px', md: 8 }}>
      <FormProvider {...formMethods}>
        <GridItem colSpan={{ base: 12, md: 5, lg: 6 }}>
          <SearchInput
            type="search"
            placeholder="Buscar transportadora por razão social ou fantasia"
            onEnterKeyPress={() => handleReset()}
            isDisabled={isLoading}
            id="nome"
            name="nome"
          />
        </GridItem>

        <GridItem colSpan={{ base: 12, md: 2, lg: 2 }}>
          <Box w={{ base: 'full', md: '190px', lg: '190px' }}>
            <FilterSelect
              id="ativo"
              name="ativo"
              isDisabled={isLoading}
              options={[
                {
                  value: true,
                  label: 'Ativos',
                },
                {
                  value: false,
                  label: 'Inativos',
                },
                {
                  value: null,
                  label: 'Todos',
                },
              ]}
              onSelect={() => {
                handleReset();
              }}
            />
          </Box>
        </GridItem>

        <GridItem
          colSpan={{ base: 12, md: 5, lg: 4 }}
          display={{ base: '', sm: '', md: 'flex' }}
          justifyContent={{ base: 'flex-start', sm: 'flex-end' }}
        >
          <ButtonCadastrarNovo
            onClick={() =>
              handleHistoryPush(ConstanteRotas.TRANSPORTADORA_CADASTRAR)
            }
            funcionalidade={ConstanteFuncionalidades.TRANSPORTADORA_CADASTRAR}
          />
        </GridItem>

        <GridItem mt={['10px', '10px', '-10px']} colSpan={12}>
          <PagedTable
            ref={childRef}
            defaultKeyOrdered="Nome"
            itemsTotalCount={totalRegistros}
            loadColumnsData={paginationHandle}
            isLoading={isLoading}
            tableHeaders={headerData}
            renderTableRows={transportadoras.map((transportadora) => (
              <Tr key={transportadora.id}>
                <Td style={{ textAlign: 'center' }}>
                  <StatusCircle isActive={transportadora.ativo} />
                </Td>
                <Td>
                  <Link
                    id="link-visualizar"
                    href={getNomeLinkHref(transportadora.id)}
                  >
                    {transportadora.nome}
                  </Link>
                </Td>
                <Td>{transportadora.apelido}</Td>
                <Td>{transportadora.telefone}</Td>
                <Td>
                  <ActionsMenu
                    items={[
                      {
                        content: 'Editar',
                        onClick: () => {
                          handleHistoryPush(
                            SubstituirParametroRota(
                              ConstanteRotas.TRANSPORTADORA_ALTERAR,
                              'id',
                              transportadora.id
                            )
                          );
                        },
                        funcionalidade:
                          ConstanteFuncionalidades.TRANSPORTADORA_ALTERAR,
                      },
                      {
                        content: 'Remover',
                        onClick: () => {
                          excluirHandle(
                            transportadora.id,
                            transportadora.ativo
                          );
                        },
                        funcionalidade:
                          ConstanteFuncionalidades.TRANSPORTADORA_EXCLUIR,
                      },
                    ]}
                  />
                </Td>
              </Tr>
            ))}
          />
        </GridItem>
      </FormProvider>
    </SimpleGridForm>
  );
};

export default Listar;
