import React, {
  createContext,
  useContext,
  ReactNode,
  SetStateAction,
  Dispatch,
} from 'react';
import { CertificadoDigitalRetorno, TabelaPrecoInteface } from 'types/loja';

type LojaContextProps = {
  bloqueioInicial: boolean;
  setBloqueioInicial: Dispatch<SetStateAction<boolean>>;
  bloqueioValorCpf: boolean;
  setBloqueioValorCpf: Dispatch<SetStateAction<boolean>>;
  isSimplesNacional: boolean;
  setIsSimplesNacional: Dispatch<SetStateAction<boolean>>;
  tabelaPrecoLoja?: TabelaPrecoInteface[];
  certificadoDigital: CertificadoDigitalRetorno;
  setCertificadoDigital: Dispatch<SetStateAction<CertificadoDigitalRetorno>>;
  NFeReadOnly: boolean;
  setNFeReadOnly: Dispatch<SetStateAction<boolean>>;
  NFCeReadOnly: boolean;
  setNFCeReadOnly: Dispatch<SetStateAction<boolean>>;
};

const LojaContext = createContext<LojaContextProps>({} as LojaContextProps);

interface LojaFormularioProviderProps extends LojaContextProps {
  children: ReactNode;
}

export default function LojaFormularioProvider({
  children,
  bloqueioInicial,
  setBloqueioInicial,
  bloqueioValorCpf,
  setBloqueioValorCpf,
  isSimplesNacional,
  setIsSimplesNacional,
  tabelaPrecoLoja,
  certificadoDigital,
  setCertificadoDigital,
  NFeReadOnly,
  setNFeReadOnly,
  NFCeReadOnly,
  setNFCeReadOnly,
}: LojaFormularioProviderProps): JSX.Element {
  return (
    <LojaContext.Provider
      value={{
        bloqueioInicial,
        setBloqueioInicial,
        bloqueioValorCpf,
        setBloqueioValorCpf,
        isSimplesNacional,
        setIsSimplesNacional,
        tabelaPrecoLoja,
        certificadoDigital,
        setCertificadoDigital,
        NFeReadOnly,
        setNFeReadOnly,
        NFCeReadOnly,
        setNFCeReadOnly,
      }}
    >
      {children}
    </LojaContext.Provider>
  );
}

export function useLojaFormularioContext(): LojaContextProps {
  const context = useContext(LojaContext);

  if (!context)
    throw new Error(
      'useLojaFormularioContext must be used within a LojaFormularioProvider.'
    );

  return context;
}
