import React, { useCallback, useState, useMemo, useEffect } from 'react';
import {
  Button,
  Box,
  Tr,
  Td,
  Text,
  Icon,
  Stack,
  Divider,
  HStack,
  Flex,
  useMediaQuery,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

import { useEntradaMercadoriaEtapasContext } from 'store/EntradaMercadoria/EntradaMercadoriaEtapas';
import { useEntradaMercadoriaDadosCadastroContext } from 'store/EntradaMercadoria/EntradaMercadoriaDadosCadastro';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';
import { DecimalMask } from 'helpers/format/fieldsMasks';

import { SalvarInserirNovoIcon } from 'icons';
import {
  Container,
  Body,
  Footer,
  StepDescriptionAccordion,
} from 'components/update/Steps/StepContent';
import {
  LoadMoreRowsParams,
  VirtualizedInfiniteTable,
  TableHeader,
} from 'components/update/Table/VirtualizedInfiniteTable';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import { InfoTooltip } from 'components/update/Tooltip/InfoTooltip';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { ModalEditarProduto } from './ModalEditarProduto';
import {
  ModalAdicionarProduto,
  ProdutoResponse,
} from './ModalAdicionarProduto';
import {
  ObterInformacoesProdutosResponse,
  Produto,
  ProdutoPaginadoRetorno,
  AdicionarProdutoRetorno,
  InformacoesRodape,
} from './types';

const cache = new CellMeasurerCache({
  defaultHeight: 65,
  minHeight: 52,
  fixedWidth: true,
});

export function LancamentoProdutos() {
  const { casasDecimais } = usePadronizacaoContext();
  const { nextStep, previousStep } = useEntradaMercadoriaEtapasContext();
  const {
    entradaMercadoriaId,
    descartarEntradaMercadoria,
    voltarParaListagem,
    temPermissaoExcluir,
    isReadOnly,
    IsCadastroExterno,
    statusLancamentos: { foiLancadoEstoque, foiLancadoFinanceiro },
    menuIsOpen,
  } = useEntradaMercadoriaDadosCadastroContext();

  const [isLoading, setIsLoading] = useState(false);
  const [entradaRateiaIcmsSt, setEntradaRateiaIcmsSt] = useState(false);
  const [produtos, setProdutos] = useState<Produto[]>([]);
  const [informacoesRodape, setInformacoesRodape] = useState<InformacoesRodape>(
    { quantidadeItens: 0, totalProdutos: 0, valorTotalProdutos: 0 }
  );
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const heightTable = () => {
    const itemOpenInTable = produtos?.filter((produto) => produto.isOpen);
    const numberOfItensOpenInList = itemOpenInTable.length;
    const heightOfAllItensOpen = numberOfItensOpenInList * 112;
    const heightOfAllItensClosed =
      (produtos?.length - numberOfItensOpenInList) * 64;
    return heightOfAllItensOpen + heightOfAllItensClosed;
  };

  function handleToggleLinhaProduto(index: number) {
    const produtosAtualizados = [...produtos];
    const produtoAtualizado = produtosAtualizados[index];

    produtosAtualizados.splice(index, 1, {
      ...produtoAtualizado,
      isOpen: !produtoAtualizado.isOpen,
    });

    setProdutos(produtosAtualizados);
  }

  // Variável para validação do ActionMenu e botão Adicionar Produtos
  const podeAlteraItens = foiLancadoEstoque || foiLancadoFinanceiro;

  const adicionarProduto = useCallback(
    async (newProduto: ProdutoResponse) => {
      const response = await api.post<
        void,
        ResponseApi<AdicionarProdutoRetorno[]>
      >(ConstanteEnderecoWebservice.ENTRADA_MERCADORIA_ADICIONAR_ITENS, {
        id: entradaMercadoriaId,
        ...newProduto,
      });

      if (response) {
        if (response.avisos) {
          response.avisos.map((aviso: string) => toast.warning(aviso));
        }

        if (response.sucesso && response.dados) {
          const newProdutos = (newProduto.listaTamanhoIdQuantidade || [{}]).map(
            (tamanhoQuantidade) => {
              const entradaMercadoriaItemId = response.dados.find(
                (dado) =>
                  dado.tamanhoId === tamanhoQuantidade.id ||
                  (!dado.tamanhoId && !tamanhoQuantidade.id)
              )?.id;

              const quantidade = tamanhoQuantidade.quantidade || 0;

              return {
                isOpen: false,
                entradaMercadoriaItemId,
                custoAdicional: newProduto.custoAdicional,
                nomeProduto: newProduto.nomeProduto,
                corDescricao: newProduto.corDescricao,
                tamanhoDescricao: tamanhoQuantidade.descricaoTamanho,
                quantidade,
                valorFcpSt: newProduto.valorFcpSt,
                valorIcmsSt: newProduto.valorIcmsSt,
                valorIpi: newProduto.valorIpi,
                valorUnitarioEntrada: newProduto.valorUnitarioEntrada,
                valorTotal: quantidade * newProduto.valorUnitarioEntrada,
                volumeUnitario: newProduto.volumeUnitario,
              } as Produto;
            }
          );

          setProdutos((prev) => [...newProdutos, ...prev]);

          const quantidadeAdicionada =
            newProduto.listaTamanhoIdQuantidade.length > 0
              ? newProduto.listaTamanhoIdQuantidade.reduce(
                  (acc, curr) => acc + curr.quantidade,
                  0
                )
              : 1;

          setInformacoesRodape((prev) => ({
            totalProdutos:
              prev.totalProdutos +
              (newProduto.listaTamanhoIdQuantidade.length || 1),
            quantidadeItens: prev.quantidadeItens + quantidadeAdicionada,
            valorTotalProdutos:
              prev.valorTotalProdutos +
              newProduto.valorUnitarioEntrada * quantidadeAdicionada,
          }));
        }
      }
    },
    [entradaMercadoriaId]
  );

  const handleAdicionarProduto = useCallback(async () => {
    if (entradaMercadoriaId) {
      const {
        produto: produtoModal,
        deveReiniciar,
      } = await ModalAdicionarProduto({
        casasDecimaisQuantidade: casasDecimais.casasDecimaisQuantidade,
        casasDecimaisValor: casasDecimais.casasDecimaisValor,
        entradaRateiaIcmsSt,
        adicionarProduto,
      });

      if (produtoModal) {
        adicionarProduto(produtoModal);
      }

      if (deveReiniciar) {
        handleAdicionarProduto();
      }
    }
  }, [
    adicionarProduto,
    casasDecimais,
    entradaMercadoriaId,
    entradaRateiaIcmsSt,
  ]);

  async function handleEditarProduto(index: number) {
    const produto = produtos[index];

    if (produto.entradaMercadoriaItemId) {
      const { produto: newProduto } = await ModalEditarProduto({
        nomeProduto: produto.nomeProduto,
        corDescricao: produto.corDescricao,
        tamanhoDescricao: produto.tamanhoDescricao,
        casasDecimaisQuantidade: casasDecimais.casasDecimaisQuantidade,
        casasDecimaisValor: casasDecimais.casasDecimaisValor,
        volumeUnitario: produto.volumeUnitario,
        defaultValues: {
          custoAdicional: produto.custoAdicional,
          fcpSt: produto.valorFcpSt,
          icmsSt: produto.valorIcmsSt,
          ipi: produto.valorIpi,
          quantidade: produto.quantidade,
          valorUnitario: produto.valorUnitarioEntrada,
        },
        entradaRateiaIcmsSt,
      });

      const response = await api.put<void, ResponseApi>(
        ConstanteEnderecoWebservice.ENTRADA_MERCADORIA_ALTERAR_ITEM,
        {
          id: produto.entradaMercadoriaItemId,
          quantidadeEntrada: newProduto.quantidade,
          valorUnitarioEntrada: newProduto.valorUnitarioEntrada,
          valorIpi: newProduto.valorIpi,
          valorIcmsSt: newProduto.valorIcmsSt,
          valorFcpSt: newProduto.valorFcpSt,
          custoAdicional: newProduto.custoAdicional,
        }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.map((aviso: string) => toast.warning(aviso));
        }

        if (response.sucesso) {
          const newProdutos = [...produtos];

          newProdutos.splice(index, 1, {
            ...produto,
            ...newProduto,
          });

          setProdutos(newProdutos);

          const prevValorTotalProduto =
            produto.valorUnitarioEntrada * produto.quantidade;
          const valorTotalProduto =
            newProduto.valorUnitarioEntrada * newProduto.quantidade;

          setInformacoesRodape((prev) => ({
            totalProdutos: prev.totalProdutos,
            quantidadeItens:
              prev.quantidadeItens - produto.quantidade + newProduto.quantidade,
            valorTotalProdutos:
              prev.valorTotalProdutos -
              prevValorTotalProduto +
              valorTotalProduto,
          }));
        }
      }
    }
  }

  async function handleRemoverProduto(index: number) {
    ModalConfirmacaoExcluir({
      title: 'Remover produto',
      text:
        'Você tem certeza que deseja remover este produto da sua entrada manual?',
      submitText: 'Sim, remover',
      cancelText: 'Voltar',
      callback: async (ok: boolean) => {
        if (ok) {
          const { quantidade, entradaMercadoriaItemId, valorTotal } = produtos[
            index
          ];

          if (entradaMercadoriaItemId) {
            const response = await api.delete<void, ResponseApi>(
              ConstanteEnderecoWebservice.ENTRADA_MERCADORIA_REMOVER_ITEM,
              { params: { id: entradaMercadoriaItemId } }
            );

            if (response) {
              if (response.avisos) {
                response.avisos.map((aviso: string) => toast.warning(aviso));
              }

              if (response.sucesso) {
                const newProdutos = [...produtos];

                newProdutos.splice(index, 1);

                setProdutos(newProdutos);

                setInformacoesRodape((prev) => ({
                  totalProdutos: prev.totalProdutos - 1,
                  quantidadeItens: prev.quantidadeItens - quantidade,
                  valorTotalProdutos: prev.valorTotalProdutos - valorTotal,
                }));
              }
            }
          }
        }
      },
    });
  }

  const produtosTableHeaders: TableHeader[] = useMemo(
    () => [
      {
        key: 'produto',
        content: podeAlteraItens ? (
          <></>
        ) : (
          <Button
            size="xs"
            borderRadius="md"
            colorScheme="secondary"
            leftIcon={<Icon as={SalvarInserirNovoIcon} fontSize="sm" />}
            minW="164px"
            onClick={handleAdicionarProduto}
            style={{ marginLeft: '2px' }}
          >
            Adicionar itens
          </Button>
        ),
        paddingLeft: '0px !important',
        width: '40%',
      },
      {
        key: 'quantidade',
        content: 'Quantidade',
        isNumeric: true,
        width: '20%',
        lineHeight: 'none',
        verticalAlign: 'bottom',
      },
      {
        key: 'valorUnitario',
        content: 'Valor unitário',
        width: '20%',
        lineHeight: 'none',
        verticalAlign: 'bottom',
        isNumeric: true,
      },
      {
        key: 'valorTotal',
        content: 'Valor total',
        width: '20%',
        lineHeight: 'none',
        verticalAlign: 'bottom',
        isNumeric: true,
      },
      {
        key: 'acoes',
        content: 'Ações',
        textAlign: 'center',
        lineHeight: 'none',
        verticalAlign: 'bottom',
      },
    ],
    [handleAdicionarProduto, podeAlteraItens]
  );

  function getDynamicHeight(index: number, marginSize: number) {
    const produto = produtos[index];

    const isLastItem = index === produtos.length - 1;

    const closedProdutoHeight = 56 + (isLastItem ? 0 : marginSize);
    const openedProdutoHeight = 104 + (isLastItem ? 0 : marginSize); // 56px da linha e 48px da Box na linha aberta

    const produtoHeight = produto?.isOpen
      ? openedProdutoHeight
      : closedProdutoHeight;

    return produtoHeight;
  }

  const loadMoreRows = useCallback(
    async ({
      currentPage,
      pageSize,
      orderColumn,
      orderDirection,
    }: LoadMoreRowsParams) => {
      if (entradaMercadoriaId) {
        setIsLoading(true);

        const paginationData = {
          currentPage,
          pageSize,
          orderColumn,
          orderDirection,
        };

        const response = await api.get<
          void,
          ResponseApi<ProdutoPaginadoRetorno>
        >(
          formatQueryPagegTable(
            ConstanteEnderecoWebservice.ENTRADA_MERCADORIA_LISTAR_ITENS_PAGINADOS,
            paginationData
          ),
          { params: { id: entradaMercadoriaId } }
        );

        if (response) {
          if (response.sucesso) {
            setProdutos((prev) => [
              ...prev,
              ...(response.dados.registros || []).map(
                (registro) => ({ ...registro, isOpen: false } as Produto)
              ),
            ]);
            setInformacoesRodape({
              quantidadeItens: response.dados.totalItens,
              totalProdutos: response.dados.totalProdutos,
              valorTotalProdutos: response.dados.valorTotal,
            });
          }
        }
      }

      setIsLoading(false);
    },
    [entradaMercadoriaId]
  );

  useEffect(() => {
    async function obterInformacoesProdutos() {
      if (entradaMercadoriaId) {
        setIsLoading(true);

        const response = await api.get<
          void,
          ResponseApi<ObterInformacoesProdutosResponse>
        >(
          ConstanteEnderecoWebservice.ENTRADA_MERCADORIA_OBTER_INFORMACOES_PRODUTOS,
          {
            params: {
              id: entradaMercadoriaId,
            },
          }
        );

        if (response) {
          if (response.avisos) {
            response.avisos.map((aviso: string) => toast.warning(aviso));
          }

          if (response.sucesso && response.dados) {
            setEntradaRateiaIcmsSt(response.dados.ratearIcmsSt);
          }
        }

        setIsLoading(false);
      }
    }

    obterInformacoesProdutos();
  }, [entradaMercadoriaId]);

  return (
    <>
      {isLoading && <LoadingPadrao />}

      <Container mt="5">
        <StepDescriptionAccordion
          stepNumber={2}
          title="Lista de produtos"
          description="Clique em “adicionar itens” para formar a lista de produtos. Caso exista um novo produto será preciso cadastrá-lo na própria tela de seleção."
        />

        <Body>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            borderRadius="md"
            border="1px"
            bg="gray.50"
            borderColor="gray.100"
            minH="390px"
            py={{ base: 4, sm: 6, md: 8 }}
            pl={{ base: 4, sm: 6, md: 8 }}
            pr={{ base: '6px', sm: '14px', md: '22px' }}
            sx={{
              '& table': { bg: 'gray.50' },
              '& thead > tr > th': {
                bg: 'gray.50',
                border: 'none',
              },
              '& tbody > tr': {
                boxShadow: 'none',
                borderRadius: 'md',
                ...(informacoesRodape.totalProdutos > 0
                  ? { border: '1px', borderColor: 'gray.100' }
                  : {
                      '& > td': {
                        position: 'relative',
                        _before: {
                          content: '""',
                          position: 'absolute',
                          h: 'full',
                          w: 'full',
                          top: 0,
                          left: 0,
                          borderRadius: 'md',
                        },
                      },
                    }),
              },
              '& tbody > tr > td': {
                bg: 'white',
                lineHeight: 'none',
                _before: {
                  border:
                    informacoesRodape.totalProdutos > 0
                      ? 'none !important'
                      : '1px',
                  borderColor: 'gray.100',
                },
              },
            }}
          >
            <VirtualizedInfiniteTable
              variant="simple-card"
              paddingRight="10px"
              size="sm"
              isUpdateWidthTable
              bg="gray.50"
              colorFundo="gray.50"
              alterarBordaListagem="gray.50"
              boxShadow="none"
              withoutRowsMessage="Nenhum produto adicionado."
              orderColumn="nomeProduto"
              tableHeaders={produtosTableHeaders}
              itemHeight={56}
              visibleItemsCount={informacoesRodape?.quantidadeItens}
              dynamicHeight={({ index }) => getDynamicHeight(index, 5)}
              rowRenderer={({
                index,
                style: { height, ...restStyle },
                key,
                parent,
              }) => {
                const produto = produtos[index];

                if (!produto) {
                  return null;
                }

                return (
                  <CellMeasurer
                    cache={cache}
                    columnIndex={1}
                    key={key}
                    parent={parent}
                    rowIndex={index}
                  >
                    {({ registerChild, measure }) => (
                      <Tr
                        ref={(e) => {
                          if (e && registerChild) {
                            registerChild(e);
                          }
                        }}
                        style={restStyle}
                        h={`${getDynamicHeight(index, 0)}px !important`}
                        sx={
                          produto.isOpen
                            ? {
                                '& > td': {
                                  marginBottom: '5px',
                                  borderBottomRadius: '0px !important',
                                },
                              }
                            : {}
                        }
                      >
                        <Td
                          width={produtosTableHeaders[0].width}
                          cursor="pointer"
                          userSelect="none"
                          onClick={() => {
                            measure();
                            handleToggleLinhaProduto(index);
                          }}
                        >
                          <Icon
                            as={produto.isOpen ? FiChevronUp : FiChevronDown}
                            mr="1"
                          />

                          {`${produto.nomeProduto}${
                            produto.corDescricao
                              ? ` ${produto.corDescricao}`
                              : ''
                          }${
                            produto.tamanhoDescricao
                              ? ` ${produto.tamanhoDescricao}`
                              : ''
                          }`}
                        </Td>
                        <Td width={produtosTableHeaders[1].width} isNumeric>
                          <Text lineHeight="0" opacity={0}>
                            {produtosTableHeaders[1].content}
                          </Text>

                          {DecimalMask(produto.quantidade, 4, 4)}
                        </Td>
                        <Td width={produtosTableHeaders[2].width} isNumeric>
                          <Text lineHeight="0" opacity={0}>
                            {produtosTableHeaders[2].content}
                          </Text>

                          {DecimalMask(
                            produto.valorUnitarioEntrada,
                            casasDecimais.casasDecimaisValor
                          )}
                        </Td>
                        <Td width={produtosTableHeaders[3].width} isNumeric>
                          <Text lineHeight="0" opacity={0}>
                            {produtosTableHeaders[3].content}
                          </Text>

                          {DecimalMask(produto.valorTotal, 2, 2)}
                        </Td>
                        <Td width={produtosTableHeaders[4].width}>
                          <Text lineHeight="0" opacity={0}>
                            {produtosTableHeaders[4].content}
                          </Text>

                          <ActionsMenu
                            isDisabled={
                              foiLancadoEstoque ||
                              isReadOnly ||
                              produto.bloquearAlteracao
                            }
                            items={[
                              {
                                content: 'Editar',
                                onClick: () => {
                                  handleEditarProduto(index);
                                },
                              },
                              {
                                content: 'Remover',
                                onClick: () => {
                                  handleRemoverProduto(index);
                                },
                              },
                            ]}
                          />
                        </Td>

                        {produto.isOpen && (
                          <Box
                            h="48px"
                            borderBottomRadius="md"
                            bg="white"
                            px="5"
                          >
                            <Divider />

                            <HStack
                              spacing="6"
                              px="5"
                              h="full"
                              lineHeight="none"
                              fontSize="xs"
                            >
                              <Flex>
                                <Text fontWeight="light">ICMS ST:</Text>
                                <Text ml="2" fontWeight="bold">
                                  <Text as="span" fontSize="2xs" mr="0.5">
                                    R$
                                  </Text>
                                  {DecimalMask(produto.valorIcmsSt, 2, 2)}
                                </Text>
                              </Flex>
                              <Divider orientation="vertical" h="6" />
                              <Flex>
                                <Text fontWeight="light">IPI:</Text>
                                <Text ml="2" fontWeight="bold">
                                  <Text as="span" fontSize="2xs" mr="0.5">
                                    R$
                                  </Text>
                                  {DecimalMask(produto.valorIpi, 2, 2)}
                                </Text>
                              </Flex>
                              <Divider orientation="vertical" h="6" />
                              <Flex>
                                <Text fontWeight="light">FCP ST:</Text>
                                <Text ml="2" fontWeight="bold">
                                  <Text as="span" fontSize="2xs" mr="0.5">
                                    R$
                                  </Text>
                                  {DecimalMask(produto.valorFcpSt, 2, 2)}
                                </Text>
                              </Flex>
                              <Divider orientation="vertical" h="6" />
                              <Flex h="center" alignItems="center">
                                <Text fontWeight="light">Custo adicional:</Text>
                                <Text ml="2" fontWeight="bold">
                                  <Text as="span" fontSize="2xs" mr="0.5">
                                    R$
                                  </Text>
                                  {DecimalMask(produto.custoAdicional, 2, 2)}
                                </Text>

                                <Box ml="2">
                                  <InfoTooltip label="Os valores deste campo não serão somados ao valor total da entrada, servindo apenas para compor o custo do produto." />
                                </Box>
                              </Flex>
                            </HStack>
                          </Box>
                        )}
                      </Tr>
                    )}
                  </CellMeasurer>
                );
              }}
              rowCount={informacoesRodape.totalProdutos}
              isRowLoaded={({ index }) => !!produtos[index]}
              loadMoreRows={loadMoreRows}
              heightTable={heightTable()}
            />

            <Flex
              pl="10px"
              alignItems="center"
              justifyContent="space-between"
              mt={{ base: 4, sm: 6, md: 8 }}
              fontSize="sm"
            >
              <HStack spacing="6" h="full" lineHeight="none">
                <Flex>
                  <Text fontWeight="light">Total de produtos:</Text>
                  <Text ml="2" fontWeight="bold">
                    {DecimalMask(
                      informacoesRodape.totalProdutos,
                      casasDecimais.casasDecimaisQuantidade,
                      casasDecimais.casasDecimaisQuantidade
                    )}
                  </Text>
                </Flex>
                <Divider orientation="vertical" h="6" />
                <Flex>
                  <Text fontWeight="light">Quantidade de itens:</Text>
                  <Text ml="2" fontWeight="bold">
                    {DecimalMask(
                      informacoesRodape.quantidadeItens,
                      casasDecimais.casasDecimaisQuantidade,
                      casasDecimais.casasDecimaisQuantidade
                    )}
                  </Text>
                </Flex>
              </HStack>

              <Flex>
                <Text fontWeight="light">Valor total de produtos:</Text>
                <Text ml="2" fontWeight="bold">
                  <Text as="span" fontSize="xs" mr="0.5">
                    R$
                  </Text>
                  {DecimalMask(informacoesRodape.valorTotalProdutos, 2, 2)}
                </Text>
              </Flex>
            </Flex>
          </Box>
        </Body>
      </Container>
      <Footer
        justifyContent="space-between"
        position={isLargerThan900 ? 'fixed' : 'relative'}
        bottom="0px"
        bg="gray.50"
        borderTop={isLargerThan900 ? '1px solid' : 'none'}
        borderColor="#5502B2"
        w={`calc(100% - ${menuIsOpen ? '210px' : '108px'})`}
        py="16px"
        px="48px"
      >
        <Button
          variant="outlineDefault"
          borderRadius="full"
          w="full"
          maxW={{ base: 'full', md: '160px' }}
          onClick={previousStep}
        >
          Voltar
        </Button>
        <Stack
          w="full"
          justifyContent="flex-end"
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: 2, sm: 4, md: 6 }}
        >
          {!IsCadastroExterno && (
            <>
              {isReadOnly ? (
                <Button
                  variant="outlineDefault"
                  borderRadius="full"
                  w="full"
                  maxW={{ base: 'full', md: '196px' }}
                  onClick={voltarParaListagem}
                >
                  Voltar para a listagem
                </Button>
              ) : (
                <Button
                  variant="outlineDefault"
                  borderRadius="full"
                  w="full"
                  maxW={{ base: 'full', md: '160px' }}
                  onClick={descartarEntradaMercadoria}
                  isDisabled={!temPermissaoExcluir}
                >
                  Descartar
                </Button>
              )}
            </>
          )}

          {!isReadOnly && (
            <Button
              variant="outlineDefault"
              borderRadius="full"
              w="full"
              maxW={{ base: 'full', md: '160px' }}
              onClick={voltarParaListagem}
            >
              Salvar e sair
            </Button>
          )}

          <Button
            colorScheme="purple"
            borderRadius="full"
            w="full"
            maxW={{ base: 'full', md: '160px' }}
            onClick={nextStep}
            isDisabled={produtos.length === 0}
          >
            Avançar
          </Button>
        </Stack>
      </Footer>
    </>
  );
}
