import React, {
  useCallback,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import {
  UseFormGetValues,
  FieldErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';
import { toast } from 'react-toastify';
import { GridItem } from '@chakra-ui/react';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { handleGetCidade } from 'helpers/data/getCidadeObterCache';
import api, { ResponseApi } from 'services/api';
import { CepResponse } from 'services/viacep';

import InputPadrao from 'components/Input/InputPadrao';
import InputCep from 'components/Input/InputCep';
import SelectCidade, {
  SelectCidadeRefInterface,
} from 'components/Select/SelectCidade';
import SelectPais, { PaisInterface } from 'components/Select/SelectPais';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';

export type EnderecoCompletoRef = {
  setCidade: (cidadeId?: number, codigoIBGE?: string) => void;
  setPais: (paisId: number) => void;
  getCepData: (data: CepResponse) => void;
};

interface EnderecoCompletoInterface {
  errors: FieldErrors;
  setError: UseFormSetError<Record<string, any>>;
  register?: UseFormRegister<Record<string, any>>;
  readonly?: boolean;
  control: any;
  setValue: UseFormSetValue<any>;
  getValue: UseFormGetValues<any>;
  cepCampoId?: string;
  logradouroCampoId?: string;
  numeroCampoId?: string;
  complementoCampoId?: string;
  bairroCampoId?: string;
  cidadeCampoId?: string;
  estadoCampoId?: string;
  paisCampoId?: string;
  cepCampoRequired?: boolean;
  logradouroCampoRequired?: boolean;
  numeroCampoRequired?: boolean;
  complementoCampoRequired?: boolean;
  bairroCampoRequired?: boolean;
  cidadeCampoRequired?: boolean;
  estadoCampoRequired?: boolean;
  paisCampoRequired?: boolean;
  paisCampoIsDisabled?: boolean;
  padding?: string;
}

const EnderecoCompleto = forwardRef<
  EnderecoCompletoRef,
  EnderecoCompletoInterface
>(
  (
    {
      errors,
      setError,
      register,
      control,
      setValue,
      getValue,
      readonly,
      cepCampoId,
      logradouroCampoId,
      numeroCampoId,
      complementoCampoId,
      bairroCampoId,
      cidadeCampoId,
      estadoCampoId,
      paisCampoId,
      cepCampoRequired,
      logradouroCampoRequired,
      numeroCampoRequired,
      complementoCampoRequired,
      bairroCampoRequired,
      cidadeCampoRequired,
      estadoCampoRequired,
      paisCampoRequired,
      paisCampoIsDisabled,
      padding,
    },
    ref
  ) => {
    const selectCidadeRef = useRef<SelectCidadeRefInterface>(null);

    const handleGetPais = useCallback(async (paisId: number) => {
      const response = await api.get<void, ResponseApi<PaisInterface>>(
        ConstanteEnderecoWebservice.PAIS_OBTER_CACHE,
        { params: { id: paisId } }
      );

      if (response?.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }

      if (response?.sucesso) {
        return response?.dados;
      }

      return {} as PaisInterface;
    }, []);

    const setPais = useCallback(
      async (paisId: number) => {
        const pais = paisId ? await handleGetPais(paisId) : undefined;

        if (pais) {
          setError(`${paisCampoId || 'pais'}` as const, {});
          setValue(`${paisCampoId || 'pais'}` as const, {
            label: pais.nome,
            value: pais.id,
          });
        }

        if (selectCidadeRef.current)
          selectCidadeRef.current.handleSetDefaultOptions();
      },
      [handleGetPais, paisCampoId, setError, setValue]
    );

    const setCidade = useCallback(
      async (cidadeId?: number, codigoIBGE?: string, nomeCidade?: string) => {
        const cidade =
          cidadeId || codigoIBGE
            ? await handleGetCidade(cidadeId, codigoIBGE, nomeCidade)
            : undefined;

        if (cidade) {
          setError(`${cidadeCampoId || 'cidade'}` as const, { message: '' });
          setValue(cidadeCampoId || 'cidade', {
            label: `${cidade.nome} - ${cidade.estadoSigla}`,
            value: cidade.id,
            paisId: cidade.paisId,
          });
          setError(`${estadoCampoId || 'estado'}` as const, { message: '' });
          setValue(estadoCampoId || 'estado', cidade.estadoNome);

          if (cidade.paisId) {
            setPais(cidade.paisId);
          }
        } else {
          setValue(cidadeCampoId || 'cidade', null);
          setValue(estadoCampoId || 'estado', null);
        }
      },
      [setError, cidadeCampoId, setValue, estadoCampoId, setPais]
    );

    const getSelectedCidade = useCallback(
      (cidadeId: number) => {
        setCidade(cidadeId);
      },
      [setCidade]
    );

    const getCepData = useCallback(
      (data: CepResponse) => {
        if (data.bairro) {
          setError(`${bairroCampoId || 'bairro'}` as const, { message: '' });
          setValue(bairroCampoId || 'bairro', data.bairro);
        }

        if (data.complemento) {
          setError(`${complementoCampoId || 'complemento'}` as const, {
            message: '',
          });
          setValue(complementoCampoId || 'complemento', data.complemento);
        }

        if (data.ibge) {
          setCidade(undefined, data.ibge.toString(), data.localidade);
        }

        if (data.logradouro) {
          setError(`${logradouroCampoId || 'logradouro'}` as const, {
            message: '',
          });
          setValue(logradouroCampoId || 'logradouro', data.logradouro);
        }
      },
      [
        bairroCampoId,
        complementoCampoId,
        logradouroCampoId,
        setCidade,
        setError,
        setValue,
      ]
    );

    const getSelectedPais = useCallback(
      (paisId?: number) => {
        const cidade = getValue(cidadeCampoId || 'cidade');

        if (
          (cidade &&
            (cidade.paisId || paisId === 1) &&
            cidade.paisId !== paisId) ||
          !paisId
        ) {
          setValue(cidadeCampoId || 'cidade', null);
          setValue(estadoCampoId || 'estado', null);
        }

        if (selectCidadeRef.current)
          selectCidadeRef.current.handleSetDefaultOptions();
      },
      [cidadeCampoId, getValue, setValue, estadoCampoId]
    );

    useImperativeHandle(ref, () => ({
      setCidade,
      setPais,
      getCepData,
    }));

    return (
      <>
        <SimpleGridForm gap={['8px', '12px', '32px']} padding={padding}>
          <GridItem colSpan={[12, 6, 6, 4, 3]}>
            <InputCep
              type="text"
              id={cepCampoId || 'cep'}
              name={cepCampoId || 'cep'}
              label="CEP"
              placeholder="Digite o CEP"
              getCepData={getCepData}
              control={control}
              error={errors[cepCampoId || 'cep']}
              disabled={readonly}
              defaultValue=""
              required={cepCampoRequired}
            />
          </GridItem>
          <GridItem colSpan={[12, 6, 6, 8, 5]}>
            <InputPadrao
              type="text"
              id={logradouroCampoId || 'logradouro'}
              name={logradouroCampoId || 'logradouro'}
              label="Logradouro"
              placeholder="Rua, avenida, etc"
              maxLength={60}
              error={errors[logradouroCampoId || 'logradouro']}
              disabled={readonly}
              defaultValue=""
              required={logradouroCampoRequired}
              control={control}
            />
          </GridItem>
          <GridItem colSpan={[12, 6, 6, 4, 2]}>
            <InputPadrao
              type="text"
              id={numeroCampoId || 'numero'}
              name={numeroCampoId || 'numero'}
              label="Número"
              placeholder="Digite o número"
              maxLength={10}
              error={errors[numeroCampoId || 'numero']}
              disabled={readonly}
              defaultValue=""
              required={numeroCampoRequired}
              control={control}
            />
          </GridItem>
          <GridItem colSpan={[12, 6, 6, 4, 2]}>
            <InputPadrao
              type="text"
              id={complementoCampoId || 'complemento'}
              name={complementoCampoId || 'complemento'}
              label="Complemento"
              placeholder="Digite o complemento"
              maxLength={50}
              error={errors[complementoCampoId || 'complemento']}
              disabled={readonly}
              defaultValue=""
              required={complementoCampoRequired}
              control={control}
            />
          </GridItem>

          <GridItem colSpan={[12, 6, 6, 4, 3]}>
            <InputPadrao
              type="text"
              name={bairroCampoId || 'bairro'}
              id={bairroCampoId || 'bairro'}
              label="Bairro"
              placeholder="Digite o bairro"
              maxLength={50}
              error={errors[bairroCampoId || 'bairro']}
              disabled={readonly}
              defaultValue=""
              required={bairroCampoRequired}
              control={control}
            />
          </GridItem>
          <GridItem colSpan={[12, 6, 6, 4, 5]}>
            <SelectCidade
              type="text"
              id={cidadeCampoId || 'cidade'}
              name={cidadeCampoId || 'cidade'}
              label="Cidade"
              required={cidadeCampoRequired}
              placeholder="Digite a cidade"
              control={control}
              error={
                errors[cidadeCampoId || 'cidade']?.value ||
                errors[cidadeCampoId || 'cidade']
              }
              isDisabled={readonly}
              getSelectedCidade={getSelectedCidade}
              getValue={getValue}
              defaultValue=""
              paisKey={paisCampoId || 'pais'}
              ref={selectCidadeRef}
            />
          </GridItem>
          <GridItem colSpan={[12, 6, 6, 4, 2]}>
            <InputPadrao
              type="text"
              name={estadoCampoId || 'estado'}
              id={estadoCampoId || 'estado'}
              label="Estado"
              required={estadoCampoRequired}
              maxLength={50}
              error={errors[estadoCampoId || 'estado']}
              disabled
              control={control}
            />
          </GridItem>
          <GridItem colSpan={[12, 6, 6, 4, 2]}>
            <SelectPais
              type="text"
              id={paisCampoId || 'pais'}
              name={paisCampoId || 'pais'}
              label="País"
              required={paisCampoRequired}
              placeholder="Digite o país"
              control={control}
              error={
                errors[paisCampoId || 'pais']?.value ||
                errors[paisCampoId || 'pais']
              }
              isDisabled={readonly || paisCampoIsDisabled}
              getSelectedPais={getSelectedPais}
            />
          </GridItem>
        </SimpleGridForm>
      </>
    );
  }
);

export default EnderecoCompleto;
