/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {
  useMediaQuery,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Flex,
  Heading,
} from '@chakra-ui/react';

import ModalPadraoChakra from '../../PDV/Modal/ModalPadraoChakra';

interface ModalAuxiliarImportacaoFornecedoresProps {
  isOpen: boolean;
  onClose: () => void;
}

const ModalAuxiliarImportacaoFornecedores = ({
  isOpen,
  onClose,
}: ModalAuxiliarImportacaoFornecedoresProps) => {
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const emptyField = '---';

  return (
    <ModalPadraoChakra
      isOpen={isOpen}
      onClose={onClose}
      size={isLargerThan900 ? 'xl' : 'full'}
      scrollBehavior={isLargerThan900 ? 'inside' : 'outside'}
      isCentered={isLargerThan900}
    >
      <ModalContent
        margin={0}
        maxW={isLargerThan900 ? 1000 : undefined}
        borderRadius={isLargerThan900 ? 'md' : 0}
        bg="gray.50"
      >
        <ModalHeader
          color="primary.500"
          mt={isLargerThan900 ? undefined : 12}
          mb={isLargerThan900 ? undefined : 8}
        >
          Ajuda
        </ModalHeader>
        <ModalCloseButton
          mt={isLargerThan900 ? undefined : 14}
          mr={isLargerThan900 ? undefined : 6}
        />
        <ModalBody pb={6}>
          <Heading as="h2" fontSize="md" mb={5}>
            Ajuda na importação de fornecedores
          </Heading>
          <Flex maxW="100%" overflowX={isLargerThan900 ? 'unset' : 'scroll'}>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>Campo</Th>
                  <Th>Tipo</Th>
                  <Th>Tamanho</Th>
                  <Th>Conteúdo Aceito</Th>
                  <Th>Obrigatório</Th>
                  <Th>Observação</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Código Externo</Td>
                  <Td>alfanumérico</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>É o código de identificação no sistema anterior</Td>
                </Tr>
                <Tr>
                  <Td>Nome / Razão Social</Td>
                  <Td>alfanumérico</Td>
                  <Td>60</Td>
                  <Td>{emptyField}</Td>
                  <Td>Sim</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Apelido / Nome Fantasia</Td>
                  <Td>alfanumérico</Td>
                  <Td>60</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Cpf / Cnpj</Td>
                  <Td>numérico</Td>
                  <Td>18</Td>
                  <Td>Números com e sem máscara</Td>
                  <Td>{emptyField}</Td>
                  <Td>Não pode se repetir</Td>
                </Tr>
                <Tr>
                  <Td>Gênero</Td>
                  <Td>alfanumérico</Td>
                  <Td>{emptyField}</Td>
                  <Td>Masculino, Feminino, F e M</Td>
                  <Td>{emptyField}</Td>
                  <Td>
                    Se não foi preenchido, será salvo o tipo "Não informado"
                  </Td>
                </Tr>
                <Tr>
                  <Td>Data Nascimento / Data Abertura</Td>
                  <Td>numérico</Td>
                  <Td>10</Td>
                  <Td>00/00/0000</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>RG / IE</Td>
                  <Td>alfanumérico</Td>
                  <Td>20</Td>
                  <Td>"Isento" e números com e sem máscara</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Isento</Td>
                  <Td>alfanumérico</Td>
                  <Td>{emptyField}</Td>
                  <Td>Sim, Não, S, N, 1 e 0</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Site / Perfil</Td>
                  <Td>alfanumérico</Td>
                  <Td>60</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Celular</Td>
                  <Td>numérico</Td>
                  <Td>15</Td>
                  <Td>Números com e sem máscara (00) 00000-0000</Td>
                  <Td>{emptyField}</Td>
                  <Td>Precisa informar o DDD + número do celular</Td>
                </Tr>
                <Tr>
                  <Td>Celular WhatsApp</Td>
                  <Td>alfanumérico</Td>
                  <Td>{emptyField}</Td>
                  <Td>Sim, Não, S, N, 1 e 0</Td>
                  <Td>{emptyField}</Td>
                  <Td>Usado para identificar se o celular é WhatsApp</Td>
                </Tr>
                <Tr>
                  <Td>Telefone</Td>
                  <Td>numérico</Td>
                  <Td>14</Td>
                  <Td>Números com e sem máscara (00) 0000-0000</Td>
                  <Td>{emptyField}</Td>
                  <Td>Precisa informar o DDD + número do telefone</Td>
                </Tr>
                <Tr>
                  <Td>Telefone WhatsApp</Td>
                  <Td>alfanumérico</Td>
                  <Td>{emptyField}</Td>
                  <Td>Sim, Não, S, N, 1 e 0</Td>
                  <Td>{emptyField}</Td>
                  <Td>Usado para identificar se o telefone é WhatsApp</Td>
                </Tr>
                <Tr>
                  <Td>Email</Td>
                  <Td>alfanumérico</Td>
                  <Td>80</Td>
                  <Td>O conteúdo precisa ter "@" e ".com"</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Email NFe</Td>
                  <Td>alfanumérico</Td>
                  <Td>80</Td>
                  <Td>O conteúdo precisa ter "@" e ".com"</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Observação</Td>
                  <Td>alfanumérico</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Ativo</Td>
                  <Td>alfanumérico</Td>
                  <Td>{emptyField}</Td>
                  <Td>Sim, Não, S, N, 1 e 0</Td>
                  <Td>{emptyField}</Td>
                  <Td>Se não for preenchido, será salvo como ativo</Td>
                </Tr>
                <Tr>
                  <Td>CEP</Td>
                  <Td>numérico</Td>
                  <Td>9</Td>
                  <Td>Números com e sem máscara</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Logradouro</Td>
                  <Td>alfanumérico</Td>
                  <Td>60</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Número</Td>
                  <Td>alfanumérico</Td>
                  <Td>60</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Complemento</Td>
                  <Td>alfanumérico</Td>
                  <Td>60</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Bairro</Td>
                  <Td>alfanumérico</Td>
                  <Td>60</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>Cidade</Td>
                  <Td>alfanumérico</Td>
                  <Td>{emptyField}</Td>
                  <Td>Nome da cidade ou código IBGE</Td>
                  <Td>Sim</Td>
                  <Td>{emptyField}</Td>
                </Tr>
                <Tr>
                  <Td>UF</Td>
                  <Td>alfanumérico</Td>
                  <Td>2</Td>
                  <Td>Sigla do Estado</Td>
                  <Td>{emptyField}</Td>
                  <Td>{emptyField}</Td>
                </Tr>
              </Tbody>
            </Table>
          </Flex>
        </ModalBody>
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export default ModalAuxiliarImportacaoFornecedores;
