import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  CSSProperties,
} from 'react';
import {
  Scrollbars,
  ScrollbarProps,
  positionValues,
} from 'react-custom-scrollbars';

import useWindowSize from 'helpers/layout/useWindowSize';

export interface ShadowScrollbarForwardRefProps {
  handleUpdateScrollbar: () => void;
}

interface ShadowScrollbarProps extends Omit<ScrollbarProps, 'ref'> {
  maxHeight: number;
  paddingHeight?: number;
  shadowTopStyle?: CSSProperties;
  shadowBottomStyle?: CSSProperties;
  renderViewStyle?: CSSProperties;
  containerStyle?: CSSProperties;
  paddingTop?: string;
  paddingRight?: string;
}

const ShadowScrollbar = forwardRef<
  ShadowScrollbarForwardRefProps,
  ShadowScrollbarProps
>(
  (
    {
      children,
      maxHeight,
      paddingHeight = 0,
      shadowTopStyle,
      shadowBottomStyle,
      renderViewStyle,
      containerStyle,
      paddingTop = '19px',
      paddingRight = '2px',
      ...rest
    },
    ref
  ) => {
    const { height: windowHeight } = useWindowSize();

    const scrollbarsRef = useRef<Scrollbars>(null);
    const shadowTopRef = useRef<HTMLDivElement>(null);
    const shadowBottomRef = useRef<HTMLDivElement>(null);

    function handleUpdate(scrollValues: positionValues) {
      const { scrollTop, scrollHeight, clientHeight } = scrollValues;

      const shadowTopOpacity = (1 / 20) * Math.min(scrollTop, 20);
      const bottomScrollTop = scrollHeight - clientHeight;
      const shadowBottomOpacity =
        (1 / 20) *
        (bottomScrollTop - Math.max(scrollTop, bottomScrollTop - 20));

      if (shadowTopRef.current) {
        shadowTopRef.current.style.opacity = String(shadowTopOpacity);

        shadowTopRef.current.style.display =
          shadowTopOpacity === 0 ? 'none' : 'unset';
      }
      if (shadowBottomRef.current) {
        shadowBottomRef.current.style.opacity = String(shadowBottomOpacity);

        shadowBottomRef.current.style.display =
          shadowBottomOpacity === 0 ? 'none' : 'unset';
      }
    }

    function handleUpdateScrollbar() {
      if (scrollbarsRef.current) {
        const scrollValues = scrollbarsRef.current.getValues();

        handleUpdate(scrollValues);
      }
    }

    useImperativeHandle(ref, () => ({
      handleUpdateScrollbar,
    }));

    return (
      <div
        style={{
          position: 'relative',
          paddingRight,

          ...containerStyle,
        }}
      >
        <Scrollbars
          ref={scrollbarsRef}
          onUpdate={handleUpdate}
          renderView={(props) => (
            <div
              {...props}
              style={{
                ...props.style,
                right: '0',
                overflowX: 'hidden',
                paddingTop,
                paddingBottom: `calc(${props.style.marginBottom}px * -1)`,
                ...renderViewStyle,
              }}
            />
          )}
          autoHeight
          autoHeightMax={
            windowHeight < maxHeight + paddingHeight
              ? windowHeight - paddingHeight
              : maxHeight
          }
          {...rest}
        >
          {children}
        </Scrollbars>

        {/* <div
          ref={shadowTopRef}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: 15,
            background:
              'linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%)',
            transition: 'opacity 0.2s',
            pointerEvents: 'none',
            ...shadowTopStyle,
          }}
        /> */}
        <div
          ref={shadowBottomRef}
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: 15,
            background:
              'linear-gradient(to top, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%)',
            transition: 'opacity 0.2s',
            pointerEvents: 'none',
            ...shadowBottomStyle,
          }}
        />
      </div>
    );
  }
);

export default ShadowScrollbar;
