import { Flex, GridItem, Box, FormLabel } from '@chakra-ui/react';

import { Switch } from 'components/update/Switch';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import { NumberInput } from 'components/update/Input/NumberInput';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';

import InputMinutos from '../../components/InputMinutos';
import { ModalTempoMedioPreparo } from '../../components/ModalTempoMedioPreparo';

const DadosGerais = () => {
  return (
    <SimpleGridForm>
      <GridItem colSpan={{ base: 12, lg: 6 }}>
        <SimpleGridForm w="full">
          <GridItem colSpan={12}>
            <Flex>
              <Box mr="10px">
                <Switch
                  size="md"
                  label=""
                  id="acrescPorcent"
                  name="acrescPorcent"
                />
              </Box>
              <Flex>
                <FormLabel>Acrescentar porcentagem na venda</FormLabel>
              </Flex>
            </Flex>
          </GridItem>
          <GridItem colSpan={12}>
            <NumberInput
              label="Porcentagem de acréscimo"
              id="valorPorcent"
              scale={0}
              name="valorPorcent"
            />
          </GridItem>
          <GridItem colSpan={12}>
            <NumberInput
              label="Quantidade de dígitos do código do produto na etiqueta de balança"
              id="qtdDigCodProdBalanca"
              scale={0}
              name="qtdDigCodProdBalanca"
            />
          </GridItem>
          <GridItem colSpan={12}>
            <NumberInput
              label="Dias de atraso até bloquear a venda"
              id="diasBloqVenda"
              scale={0}
              name="diasBloqVenda"
            />
          </GridItem>
          <GridItem colSpan={12}>
            <NumberInput
              id="limiteQuantidadeVenda"
              name="limiteQuantidadeVenda"
              label="Quantidade máxima de itens lançados de uma só vez na venda"
              scale={0}
            />
          </GridItem>
          <GridItem colSpan={12}>
            <Flex>
              <Box mr="10px">
                <Switch
                  size="md"
                  label=""
                  id="gravarItensRemovidos"
                  name="gravarItensRemovidos"
                />
              </Box>
              <Flex>
                <FormLabel>Registrar os itens removidos da tela</FormLabel>
              </Flex>
            </Flex>
          </GridItem>
          <GridItem colSpan={12}>
            <Flex>
              <Box mr="10px">
                <Switch
                  size="md"
                  label=""
                  id="baixarMateriaPrimaVenda"
                  name="baixarMateriaPrimaVenda"
                />
              </Box>
              <Flex>
                <FormLabel>Baixar matérias-primas na venda</FormLabel>
              </Flex>
            </Flex>
          </GridItem>
          <GridItem colSpan={12}>
            <Flex>
              <Box mr="10px">
                <Switch
                  size="md"
                  label=""
                  id="pesquisaAutomaticaDeProdutos"
                  name="pesquisaAutomaticaDeProdutos"
                />
              </Box>
              <Flex>
                <FormLabel>
                  Pesquisar produtos automaticamente na tela de consulta
                </FormLabel>
              </Flex>
            </Flex>
          </GridItem>
        </SimpleGridForm>
      </GridItem>
      <GridItem colSpan={{ base: 12, lg: 6 }} title="">
        <SimpleGridForm w="full">
          <GridItem colSpan={12}>
            <Flex>
              <Box mr="10px">
                <Switch
                  size="md"
                  label=""
                  id="exibirContasPorDepartamento"
                  name="exibirContasPorDepartamento"
                />
              </Box>
              <Flex>
                <FormLabel>
                  Exibir contas conforme a configuração do departamento
                </FormLabel>
              </Flex>
            </Flex>
          </GridItem>
          <GridItem colSpan={12}>
            <SelectPadrao
              id="configSenhaBalcao"
              name="configSenhaBalcao"
              label="Configuração para senha balcão"
              placeholder="Selecione"
              options={[
                {
                  label: 'Não utilizar senha balcão',
                  value: 0,
                },
                {
                  label: 'Utilizar senha sequêncial',
                  value: 1,
                },
                {
                  label: 'Utilizar senha aléatória',
                  value: 2,
                },
                {
                  label: 'Informar senha ao concluir a venda',
                  value: 3,
                },
                {
                  label: 'Informar nome ao concluir a venda',
                  value: 4,
                },
              ]}
            />
          </GridItem>
          <GridItem colSpan={12}>
            <InputMinutos
              id="tempoInatividadeMesa"
              name="tempoInatividadeMesa"
              label=" Tempo de inatividade da mesa"
              max={1000}
              scale={0}
            />
          </GridItem>
          <GridItem colSpan={12}>
            <InputMinutos
              id="tempoMedioPreparo"
              name="tempoMedioPreparo"
              label="Tempo médio de preparo"
              actionLinkText="Como funciona?"
              actionLinkOnClick={() => {
                ModalTempoMedioPreparo();
              }}
              max={1000}
              precision={0}
              scale={0}
            />
          </GridItem>
          <GridItem colSpan={12}>
            <InputMinutos
              id="tempoMedioEntrega"
              name="tempoMedioEntrega"
              label="Tempo médio de entrega"
              max={1000}
              scale={0}
            />
          </GridItem>
          <GridItem colSpan={12}>
            <Flex>
              <Box mr="10px">
                <Switch
                  size="md"
                  label=""
                  id="cobrarCouver"
                  name="cobrarCouver"
                />
              </Box>
              <Flex>
                <FormLabel>Cobrar couvert</FormLabel>
              </Flex>
            </Flex>
          </GridItem>
          <GridItem colSpan={12}>
            <Flex>
              <Box mr="10px">
                <Switch
                  size="md"
                  label=""
                  id="valorProdProporcional"
                  name="valorProdProporcional"
                />
              </Box>
              <Flex>
                <FormLabel>
                  Calcular média dos valores de produtos fracionados
                </FormLabel>
              </Flex>
            </Flex>
          </GridItem>
          <GridItem colSpan={12}>
            <Flex>
              <Box mr="10px">
                <Switch
                  size="md"
                  label=""
                  id="emitirSomNotificacaoIfood"
                  name="emitirSomNotificacaoIfood"
                />
              </Box>
              <Flex>
                <FormLabel>
                  Emitir alerta sonoro ao receber pedidos das integrações
                </FormLabel>
              </Flex>
            </Flex>
          </GridItem>
          <GridItem colSpan={12}>
            <Flex>
              <Box mr="10px">
                <Switch
                  size="md"
                  label=""
                  id="solicitarQtdPessoasMesa"
                  name="solicitarQtdPessoasMesa"
                />
              </Box>
              <Flex>
                <FormLabel>Solicitar quantidade de pessoas na mesa</FormLabel>
              </Flex>
            </Flex>
          </GridItem>
        </SimpleGridForm>
      </GridItem>
    </SimpleGridForm>
  );
};

export { DadosGerais };
