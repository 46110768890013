import {
  Text,
  TableContainer,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  Table,
} from '@chakra-ui/react';

import { DecimalMask } from 'helpers/format/fieldsMasks';

type Saidas = {
  formaRecebimentoNome: string;
  valor: number;
};

type ResumoSaidasProps = {
  saidas?: Saidas[];
};

const ResumoSaidas = ({ saidas }: ResumoSaidasProps) => {
  return (
    <>
      <Text mt="2" fontSize="xs" fontWeight="bold">
        Saídas:
      </Text>
      <TableContainer pt="1">
        <Table>
          <Thead>
            <Tr
              sx={{
                '& > th + th': {
                  textAlign: 'center',
                  color: 'gray.700',
                  padding: '1px',
                  fontSize: '2xs',
                },
                '& > th': {
                  fontSize: '2xs',
                  margin: 0,
                  border: 0,
                  padding: '1px',
                },
              }}
            >
              <Th> Forma Recebimento</Th>
              <Th />
              <Th />
              <Th> Valor</Th>
            </Tr>
          </Thead>
          <Tbody>
            {(saidas || []).map((saida, index) => (
              <Tr
                sx={{
                  '& > td + td': {
                    textAlign: 'center',
                    padding: '1px',
                    fontSize: '2xs',
                  },
                  '& > td': {
                    fontSize: '2xs',
                    padding: '1px',
                  },
                }}
                key={String(index)}
              >
                <Td>{saida.formaRecebimentoNome}</Td>
                <Td />
                <Td />
                <Td>{DecimalMask(saida.valor, 2)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ResumoSaidas;
