import React from 'react';
import { Route } from 'react-router-dom';

import ConstanteRotas from 'constants/rotas';
import { PromocaoProvider } from 'store/Promocao';

import { Cadastrar } from 'pages/Promocao/Formulario/Cadastrar';
import { Alterar } from 'pages/Promocao/Formulario/Alterar';
import { Visualizar } from 'pages/Promocao/Formulario/Visualizar';
import ConstanteFuncionalidades from 'constants/permissoes';

import LayoutGuard from './LayoutGuard';

export const PromocaoCadastrarRoutes = () => (
  <Route path="/promocao">
    <PromocaoProvider>
      <LayoutGuard
        permissaoFuncionalidade={ConstanteFuncionalidades.PROMOCAO_CONFIGURAR}
        key="promocao-listar"
        component={Cadastrar}
        breadcrumb={[
          {
            title: 'Configurações',
          },
          {
            title: 'Promoção',
            path: ConstanteRotas.PROMOCAO,
          },
          {
            title: 'Novo',
          },
        ]}
        path={ConstanteRotas.PROMOCAO_CADASTRAR}
        exact
        meta={{ auth: true }}
      />
      <LayoutGuard
        key="promocao-listar"
        permissaoFuncionalidade={ConstanteFuncionalidades.PROMOCAO_CONFIGURAR}
        component={Alterar}
        breadcrumb={[
          {
            title: 'Configurações',
          },
          {
            title: 'Promoção',
            path: ConstanteRotas.PROMOCAO,
          },
          {
            title: 'Alterar',
          },
        ]}
        path={ConstanteRotas.PROMOCAO_ALTERAR}
        exact
        meta={{ auth: true }}
      />

      <LayoutGuard
        key="promocao-listar"
        component={Visualizar}
        permissaoFuncionalidade={ConstanteFuncionalidades.PROMOCAO_CONFIGURAR}
        breadcrumb={[
          {
            title: 'Configurações',
          },
          {
            title: 'Promoção',
            path: ConstanteRotas.PROMOCAO,
          },
          {
            title: 'Visualizar',
          },
        ]}
        path={ConstanteRotas.PROMOCAO_VISUALIZAR}
        exact
        meta={{ auth: true }}
      />
    </PromocaoProvider>
  </Route>
);
