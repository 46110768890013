import React, { useRef } from 'react';
import { Transition } from 'react-transition-group';
import { Box, Flex } from '@chakra-ui/react';

import { useNavMenuContext } from 'store/NavMenu';

import ClickForaMenu from 'components/Layout/ClickFora/ClickForaMenu';

interface SubmenuInterface {
  menuKey: string;
  children: React.ReactNode;
}

const Submenu = ({ menuKey, children }: SubmenuInterface) => {
  const { openMenuKey } = useNavMenuContext();

  const transitionRef = useRef(null);

  return (
    <Transition
      nodeRef={transitionRef}
      in={openMenuKey === menuKey}
      timeout={200}
      unmountOnExit
      mountOnEnter
    >
      {(state) => (
        <ClickForaMenu>
          <Flex
            position="absolute"
            pt="15px"
            height="100vh"
            w="240px"
            direction="column"
            bg="gray.100"
            boxShadow="3px 0px 6px #00000080"
            transition="opacity 0.2s, transform 0.2s, left 0.2s"
            opacity={state === 'entered' ? '1' : '0'}
            transform={`translateX(${state === 'entered' ? '0px' : '-5px'})`}
            overflow="hidden"
            id="sti3-navsubmenu-container"
            ref={transitionRef}
          >
            <Box
              sx={{
                '&::-webkit-scrollbar': {
                  width: '6px',
                  height: '5px',
                  scrollbarWidth: 'thin',
                  background: 'gray.100',
                },
                '&::-webkit-scrollbar-track': {
                  background: 'gray.100',
                  borderRadius: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: 'gray.200',
                  borderRadius: '5px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: 'gray.200',
                },
              }}
              overflowY="auto"
              overflowX="hidden"
              h="full"
              w="calc(100% - 5px)"
            >
              {children}
            </Box>
          </Flex>
        </ClickForaMenu>
      )}
    </Transition>
  );
};

export default Submenu;
