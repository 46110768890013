import { Flex, GridItem } from '@chakra-ui/react';

import { ButtonDefault } from 'components/Button/ButtonChakra';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { NumberInput } from 'components/update/Input/NumberInput';
import { ModalDigitarConfirmacao } from 'components/Modal/ModalDigitarConfirmacao';

import usePadronizacao from './hooks';

export const UncontrolledForm = () => {
  const { userIsAdmin, handleRemoveAccountData } = usePadronizacao();

  return (
    <SimpleGridForm>
      <GridItem colSpan={{ base: 12, md: 6, lg: 4 }}>
        <NumberInput
          id="casasDecimaisValor"
          name="casasDecimaisValor"
          label="Casas decimais para valor"
          defaultValue={2}
          scale={0}
          required
          placeholder="Informe a quantidade de casas decimais"
          maxLength={1}
          max={6}
        />
      </GridItem>
      <GridItem colSpan={{ base: 12, md: 6, lg: 4 }}>
        <NumberInput
          id="casasDecimaisQuantidade"
          name="casasDecimaisQuantidade"
          label="Casas decimais para quantidade"
          defaultValue={2}
          scale={0}
          max={6}
          required
          placeholder="Informe a quantidade de casas decimais"
        />
      </GridItem>
      <GridItem colSpan={{ base: 12, md: 6, lg: 4 }} as={Flex} alignItems="end">
        {userIsAdmin && (
          <ButtonDefault
            colorScheme="red"
            color="white"
            width={{ base: 'full', md: 'max-content' }}
            onClick={() => {
              ModalDigitarConfirmacao({
                confirmationText: 'QUERO EXCLUIR TODOS OS MEUS DADOS',
                callback: (onClose) => {
                  handleRemoveAccountData();
                  onClose();
                },
              });
            }}
          >
            Limpar base de dados
          </ButtonDefault>
        )}
      </GridItem>
    </SimpleGridForm>
  );
};
