const MotivosDesoneracaoIcmsEnum = {
  UsoAgropecuaria: 3,
  Outros: 9,
  OrgaoFomentoDesenvolvimentoAgropecuario: 12,

  properties: {
    1: { name: 'Órgão de fomento e desenvolvimento agropecuário', value: 12 },
    2: { name: 'Outros', value: 9 },
    3: { name: 'Uso na agropecuária', value: 3 },
  },
};

export default MotivosDesoneracaoIcmsEnum;
