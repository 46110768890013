import styled, { css } from 'styled-components';
import { Nav as BootstrapNav, Card as BootstrapCard } from 'react-bootstrap';

interface CardHeaderProps {
  variant: 'tabs' | 'combo';
}

export const CardHeader = styled(BootstrapCard.Header)<CardHeaderProps>`
  display: flex;
  align-items: flex-end;

  ${({ variant }) =>
    variant === 'tabs'
      ? css`
          min-height: 55px;
          max-height: 55px;
        `
      : css`
          min-height: 45px;
          max-height: 45px;
        `}
  padding: 0px;
  border-bottom: 0px;

  background-color: var(--sti-ck-colors-gray-100);
`;

export const Nav = styled(BootstrapNav).attrs(() => ({ variant: 'tabs' }))`
  position: relative;
  flex-wrap: nowrap;

  margin: 0px;
  padding: 0px 10px;
  width: 100%;

  border-width: 0.5px;
  border-color: var(--sti-ck-colors-primary-50);

  & > .nav-item {
    height: 40px;
    padding: 0px;
    margin-right: 5px;

    width: 150px;
  }
  & > .nav-item > .nav-link {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    text-overflow: ellipsis;
    white-space: nowrap;

    max-width: 100%;
    height: 100%;
    padding: 0px 10px;
    border-radius: 5px 5px 0px 0px;

    &:hover {
      background-color: rgba(255, 255, 255, 0.15);
    }

    :before,
    :after {
      opacity: 0;
      box-shadow: none;
      content: ' ';

      transition: opacity 0.2s, box-shadow 0.2s;
    }

    transition: background-color 0.2s;
  }

  & > .nav-item > .nav-link.disabled {
    background-color: rgba(0, 0, 0, 0.025) !important;

    cursor: default;
  }

  & > .nav-item > .nav-link.active {
    background-color: var(--white);

    border-width: 0.5px;
    border-color: var(--sti-ck-colors-primary-50)
      var(--sti-ck-colors-primary-50) var(--white);
    box-shadow: 0px 3px 0px 0px var(--white);

    :before,
    :after {
      opacity: 1;
      position: absolute;
      bottom: -0.5px;
      transform: translateY(-0.5px);
      width: 5px;
      height: 5px;
      content: ' ';
      border: 0.5px solid var(--sti-ck-colors-primary-50);

      background-color: var(--sti-ck-colors-gray-100);
    }
    :before {
      left: -5.1px;
      border-bottom-right-radius: 5px;
      border-width: 0 0.5px 0.5px 0;
      box-shadow: 2px 2px 0px 2px var(--white);
    }
    :after {
      right: -5.1px;
      border-bottom-left-radius: 5px;
      border-width: 0 0 0.5px 0.5px;
      box-shadow: -2px 2px 0px 2px var(--white);
    }
  }
`;

export const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  padding: 10px 20px;
`;

export const Titulo = styled.div`
  display: flex;
`;
