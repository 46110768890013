import React from 'react';
import { Button, ButtonProps, useToken } from '@chakra-ui/react';
import { PropagateLoader } from 'react-spinners';

interface ButtonSubmitProps extends ButtonProps {
  isLoading?: boolean;
  isInvalid?: boolean;
}

const ButtonSubmit = ({
  children,
  isLoading,
  isInvalid,
  ...rest
}: ButtonSubmitProps) => {
  const loadingColor = useToken('colors', ['login.loading']);

  return (
    <Button
      type="submit"
      size="md"
      fontSize="16px"
      color="login.buttonText"
      height="40px"
      w="full"
      background="login.normal"
      _disabled={{
        bg: isInvalid ? 'login.disabled' : 'login.hover',
      }}
      _active={{ bg: 'login.active' }}
      _hover={{
        _disabled: {
          bg: isInvalid ? 'login.disabled' : 'login.hover',
        },
      }}
      sx={{ '& > div': { mb: '6px', mr: '6px' } }} // Support for PropagateLoader boxSize
      {...rest}
    >
      {isLoading ? (
        <PropagateLoader size={6} color={loadingColor} loading={isLoading} />
      ) : (
        <>{children}</>
      )}
    </Button>
  );
};

export default ButtonSubmit;
