import { Flex, FlexProps } from '@chakra-ui/react';

type ActionContainerProps = FlexProps & {
  children: React.ReactNode;
};

export const ActionContainer = ({
  children,
  ...props
}: ActionContainerProps) => {
  return (
    <Flex
      alignItems="center"
      mb="5px"
      justifyContent="space-between"
      {...props}
    >
      {children}
    </Flex>
  );
};
