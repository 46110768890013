import { useRef, useState, useCallback } from 'react';
import {
  Flex,
  Box,
  Button,
  Link,
  Tr,
  Td,
  GridItem,
  useMediaQuery,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteFuncionalidades from 'constants/permissoes';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import api, { ResponseApi } from 'services/api';
import { shallowEqual } from 'helpers/validation/shallowEqual';
import auth from 'modules/auth';
import getOptionsByEnum from 'helpers/format/getOptionsByEnum';
import StatusConsultaEnum from 'constants/enum/statusConsulta';

import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import { SearchInput } from 'components/update/Input/SearchInput';
import {
  PagedTable,
  PagedTableForwardRefData,
} from 'components/update/Table/PagedTable';
import { PaginationData } from 'components/update/Pagination';
import { StatusCircle } from 'components/update/Table/StatusCircle';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { ModalConfirmacaoInativar } from 'components/Modal/ModalConfirmacaoInativar';
import { FilterSelect } from 'components/update/Select/FilterSelect';
import { ButtonCadastrarNovo } from 'components/Layout/ButtonCadastrarNovo';

type ListarEtiquetasRegistrosProps = {
  id: string;
  nome: string;
  ativo: boolean;
};

type FormData = {
  nome: string;
  etiquetaAtiva: number;
};

const formDefaultValues: FormData = {
  nome: '',
  etiquetaAtiva: StatusConsultaEnum.ATIVOS,
};

export function ListarEtiquetas() {
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [listarRegistrosEtiquestas, setListarRegistrosEtiquestas] = useState<
    ListarEtiquetasRegistrosProps[]
  >();

  const [currentFilters, setCurrentFilters] = useState<FormData>(
    formDefaultValues
  );
  const [isLoading, setIsLoading] = useState(false);

  const [isLargerThan1200] = useMediaQuery('(max-width: 1200px)');
  const [isLargerThan900] = useMediaQuery('(max-width: 900px)');

  const formMethods = useForm<FormData>({
    defaultValues: formDefaultValues,
  });

  const history = useHistory();

  const isMountedRef = useIsMountedRef();
  const pageIsLoaded = useRef(false);
  const pagedTableRef = useRef<PagedTableForwardRefData>(null);

  const permissaoEtiquetaPersonalizadaAlterar = auth.possuiPermissao(
    ConstanteFuncionalidades.ETIQUETAS_PERSONALIZADAS_ALTERAR
  );
  const permissaoEtiquetaPersonalizadaVisualizar = auth.possuiPermissao(
    ConstanteFuncionalidades.ETIQUETAS_PERSONALIZADAS_VISUALIZAR
  );

  const filtersSubmit = formMethods.handleSubmit((data) => {
    const filtersIsDirty = !shallowEqual(data, currentFilters || {});

    if (filtersIsDirty) {
      setCurrentFilters(data);
    }
  });

  const getNomeLinkHref = useCallback(
    (id: string) => {
      let href = '';

      if (permissaoEtiquetaPersonalizadaAlterar.permitido) {
        href = SubstituirParametroRota(
          ConstanteRotas.ETIQUETA_ALTERAR,
          'id',
          id
        );
      } else if (permissaoEtiquetaPersonalizadaVisualizar.permitido) {
        href = SubstituirParametroRota(
          ConstanteRotas.ETIQUETA_VISUALIZAR,
          'id',
          id
        );
      }

      return href;
    },
    [
      permissaoEtiquetaPersonalizadaVisualizar,
      permissaoEtiquetaPersonalizadaAlterar,
    ]
  );

  const etiquetaAtiva = formMethods.watch('etiquetaAtiva');

  const paginationHandle = useCallback(
    async (gridPaginadaConsulta: PaginationData) => {
      setIsLoading(true);
      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<ListarEtiquetasRegistrosProps>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.ETIQUETAS_PERSONALIZADAS_LISTAR_PAGINADO,
          gridPaginadaConsulta
        ),
        { params: { nome: currentFilters.nome, statusConsulta: etiquetaAtiva } }
      );

      if (response?.sucesso && isMountedRef.current) {
        setTotalRegistros(response.dados.total);
        setListarRegistrosEtiquestas(response.dados.registros);
        setIsLoading(false);
      }

      if (isMountedRef.current) {
        setIsLoading(false);

        if (!pageIsLoaded.current) {
          pageIsLoaded.current = true;
        }
      }
    },
    [isMountedRef, etiquetaAtiva, currentFilters]
  );

  const handleExcluirEtiqueta = useCallback(
    async (id: string) => {
      ModalConfirmacaoExcluir({
        callback: async (ok: boolean) => {
          if (ok) {
            setIsLoading(true);

            const response = await api.delete<void, ResponseApi>(
              ConstanteEnderecoWebservice.ETIQUETAS_PERSONALIZADAS_EXCLUIR,
              {
                params: { id },
              }
            );

            if (response?.sucesso) {
              toast.success('O cadastro foi removido com sucesso.');

              pagedTableRef.current?.reload();
            }

            if (response) {
              ModalConfirmacaoInativar({
                response,
                rotaWebService:
                  ConstanteEnderecoWebservice.ETIQUETA_PERSONALIZADA_INATIVAR,
                id,
                callback: (okInativar: boolean) => {
                  if (okInativar) pagedTableRef.current?.reload();
                },
              });
            }

            setIsLoading(false);
          }
        },
      });
    },
    [pagedTableRef]
  );

  function handleNovaEtiqueta() {
    history.push(ConstanteRotas.ETIQUETAS_PERSONALIZADAS_CADASTRAR);
  }

  return (
    <>
      <FormProvider {...formMethods}>
        <Flex
          direction={isLargerThan1200 ? 'column' : 'row'}
          w="full"
          justifyContent="space-between"
        >
          <Flex
            direction={{ base: 'column', sm: 'column', md: 'row', xl: 'row' }}
            w={isLargerThan900 ? 'full' : '100%'}
          >
            <Box w={isLargerThan900 ? 'full' : '70%'} mr="40px">
              <SearchInput
                name="nome"
                id="nome"
                placeholder="Buscar etiqueta pelo nome"
                onEnterKeyPress={() => {
                  filtersSubmit();
                }}
                autoFocus
              />
            </Box>
            <Box
              mt={isLargerThan900 ? '10px' : 0}
              w={isLargerThan900 ? 'full' : '200px'}
            >
              <FilterSelect
                id="etiquetaAtiva"
                name="etiquetaAtiva"
                options={getOptionsByEnum(StatusConsultaEnum)}
                onSelect={() => {
                  filtersSubmit();
                }}
                isDisabled={isLoading}
              />
            </Box>
          </Flex>
          <Flex
            mt={isLargerThan1200 ? '10px' : 0}
            direction={{ base: 'column', sm: 'column', md: 'row', xl: 'row' }}
          >
            <Box w="full" ml={isLargerThan1200 ? 0 : '40px'} mr="40px">
              <Button
                borderRadius="full"
                w={isLargerThan1200 ? 'full' : '190px'}
                h="39px"
                variant="outlineDefault"
                colorScheme="gray"
                onClick={() => {
                  history.push(ConstanteRotas.ETIQUETAS_IMPRIMIR);
                }}
              >
                Imprimir etiquetas
              </Button>
            </Box>
            <Box mt={isLargerThan900 ? '10px' : 0} w="full">
              <ButtonCadastrarNovo
                variant="solid"
                colorScheme="secondary"
                borderRadius="full"
                funcionalidade={
                  ConstanteFuncionalidades.ETIQUETAS_PERSONALIZADAS_CADASTRAR
                }
                onClick={() => handleNovaEtiqueta()}
              >
                Cadastrar Novo
              </ButtonCadastrarNovo>
            </Box>
          </Flex>
        </Flex>
        <GridItem mt="22px" colSpan={12}>
          <PagedTable
            ref={pagedTableRef}
            isLoading={isLoading}
            loadColumnsData={paginationHandle}
            itemsTotalCount={totalRegistros}
            defaultKeyOrdered="nome"
            tableHeaders={[
              {
                key: 'ativo',
                content: <StatusCircle hasValue={false} />,
                w: '1px',
              },
              { key: 'nome', content: 'Nome' },
              { key: 'acoes', content: 'Ações', isOrderable: false, w: '1px' },
            ]}
            renderTableRows={(listarRegistrosEtiquestas || []).map(
              (registroEtiqueta) => (
                <Tr>
                  <Td>
                    <StatusCircle isActive={registroEtiqueta.ativo} />
                  </Td>
                  <Td>
                    <Link
                      id="link-visualizar"
                      href={getNomeLinkHref(registroEtiqueta.id)}
                    >
                      {registroEtiqueta.nome}
                    </Link>
                  </Td>
                  <Td>
                    <ActionsMenu
                      items={[
                        {
                          content: 'Editar',
                          onClick: () => {
                            history.push(
                              SubstituirParametroRota(
                                ConstanteRotas.ETIQUETA_ALTERAR,
                                'id',
                                registroEtiqueta.id
                              )
                            );
                          },
                          funcionalidade:
                            ConstanteFuncionalidades.ETIQUETAS_PERSONALIZADAS_ALTERAR,
                        },
                        {
                          content: 'Remover',
                          onClick: () => {
                            handleExcluirEtiqueta(registroEtiqueta.id);
                          },
                          funcionalidade:
                            ConstanteFuncionalidades.ETIQUETAS_PERSONALIZADAS_EXCLUIR,
                        },
                      ]}
                    />
                  </Td>
                </Tr>
              )
            )}
          />
        </GridItem>
      </FormProvider>
    </>
  );
}
