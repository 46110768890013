import { Flex, HStack, Button, FlexProps } from '@chakra-ui/react';

import { IdentificacaoEtapasSmartPos } from 'constants/enum/IdentificacaoEtapasSmartPos';
import { useSmartPosEtapasContext } from 'store/SmartPos/SmartPosEtapasContext';

interface FooterSmartPosProps extends FlexProps {
  handleClienteStone?: () => void;
  handleAvancar: () => void;
  handleSair?: () => void;
  isDisabledAvancar?: boolean;
}

export const FooterSmartPos = ({
  handleClienteStone,
  handleAvancar,
  handleSair,
  isDisabledAvancar,
  ...rest
}: FooterSmartPosProps) => {
  const { activeStep } = useSmartPosEtapasContext();

  const exibirButtons = () => {
    switch (activeStep) {
      case IdentificacaoEtapasSmartPos.GUIA_INTEGRACAO:
        return (
          <HStack
            flexDirection={['column', 'column', 'row']}
            w="full"
            justifyContent="center"
            spacing={['0', '0', '24px']}
          >
            <Button
              onClick={handleClienteStone}
              colorScheme="aquamarine"
              w="200px"
              variant="linkDefault"
              mb={['8px', '8px', '0']}
            >
              Quero ser cliente Stone
            </Button>
            <Button
              mt={['24px', '24px', '0']}
              onClick={handleAvancar}
              w="200px"
              colorScheme="purple"
              variant="solid"
            >
              Iniciar configuração
            </Button>
          </HStack>
        );
      case IdentificacaoEtapasSmartPos.FORMULARIO_STONE:
        return (
          <Button
            w="120px"
            onClick={handleAvancar}
            colorScheme="purple"
            variant="solid"
            h="40px"
          >
            Enviar
          </Button>
        );
      case IdentificacaoEtapasSmartPos.FOMULARIO_STONE_SUCESSO:
        return (
          <HStack
            flexDirection={['column-reverse', 'column-reverse', 'row']}
            w="full"
            justifyContent="center"
            spacing={['0', '0', '24px']}
          >
            <Button
              w="180px"
              h="40px"
              onClick={handleSair}
              colorScheme="gray"
              variant="outlineDefault"
              mt={['8px', '8px', '0']}
            >
              Sair e aguardar
            </Button>
            <Button
              onClick={handleAvancar}
              w="200px"
              colorScheme="purple"
              variant="solid"
              h="40px"
            >
              Iniciar configuração
            </Button>
          </HStack>
        );
      case IdentificacaoEtapasSmartPos.CODIGO_ATIVACAO:
        return (
          <Button
            w="160px"
            colorScheme="purple"
            onClick={handleAvancar}
            variant="solid"
          >
            Finalizar
          </Button>
        );

      default:
        return (
          <Button
            w="160px"
            colorScheme={isDisabledAvancar ? 'blackAlpha' : 'purple'}
            onClick={handleAvancar}
            isDisabled={isDisabledAvancar}
            variant="solid"
          >
            Avançar
          </Button>
        );
    }
  };

  return (
    <Flex
      bg="gray.50"
      flexDirection={['column', 'column', 'row']}
      justifyContent="center"
      w="full"
      alignItems="center"
      {...rest}
    >
      {exibirButtons()}
    </Flex>
  );
};
