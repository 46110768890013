import React from 'react';
import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';
import DocumentoFiscalExportacaoListar from '../pages/DocumentoFiscalExportacao/Listar';

const DocumentosFiscaisExportacaoRoutes = [
  <LayoutGuard
    key="documento-fiscal-exportacao-listar"
    permissaoFuncionalidade={
      ConstanteFuncionalidades.DOCUMENTO_FISCAL_EXPORTACAO_LISTAR
    }
    component={DocumentoFiscalExportacaoListar}
    breadcrumb={[
      {
        title: 'Fiscal',
      },
      {
        title: 'Exportar XML',
      },
    ]}
    path={ConstanteRotas.DOCUMENTO_FISCAL_EXPORTACAO}
    exact
    meta={{ auth: true }}
  />,
];

export default DocumentosFiscaisExportacaoRoutes;
