import { useEffect } from 'react';
import { Flex, Text, Box, Icon } from '@chakra-ui/react';
import { FiArrowLeft } from 'react-icons/fi';
import { useFormContext } from 'react-hook-form';

import getOptionsByEnum from 'helpers/format/getOptionsByEnum';
import { CasasDecimais } from 'store/Padronizacao/Padronizacao';

import { FilterAsyncSelect } from 'components/update/Select/FilterAsyncSelect';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';

import { VariacaoLinha, VariacaoLojaProps } from './types';
import { DadosLojaAtualPdvMobile } from './DadosTabPanelsMobile/lojaAtualMobile';
import { DadosPdvEstoqueGeralMobile } from './DadosTabPanelsMobile/estoqueGeralMobile';
import { DadosProdutosConsignadosPdvMobile } from './DadosTabPanelsMobile/produtosConsignadosMobile';
import { ItensConsignados } from '../ModalListarSaldoVariacoes';

type VariacaoTodosEstoquesProps = {
  saldo: number;
  loja: string;
};

type GetProdutosOptionsProps = {
  label: string;
  value: string;
  cores: {
    label: string;
    value: string;
  }[];
};

type CoresProps = {
  value: string;
  label: string;
};

type ModalListarVariacoesMobileProps = {
  produtosConsignados: ItensConsignados[];
  cores: CoresProps[];
  getProdutosOptions(inputValue: string): Promise<GetProdutosOptionsProps[]>;
  naoExisteCores: number;
  casasDecimais: CasasDecimais;
  variacoesEstoqueAgrupado: VariacaoLinha[];
  lojaEscolhida: number;
  lojaFantasia: string;
  exibirTodasAsLojas: VariacaoLojaProps[];
  idProduto: any;
  onClose: () => void;
  informacoesEstoqueGeral: VariacaoTodosEstoquesProps[];
  setExibirTodasAsLojas: React.Dispatch<
    React.SetStateAction<VariacaoLojaProps[]>
  >;
  setVariacoesEstoqueAgrupado: React.Dispatch<
    React.SetStateAction<VariacaoLinha[]>
  >;
};

export const ModalListarVariacoesPdvMobile = ({
  idProduto,
  cores,
  getProdutosOptions,
  produtosConsignados,
  naoExisteCores,
  casasDecimais,
  variacoesEstoqueAgrupado,
  lojaEscolhida,
  onClose,
  lojaFantasia,
  exibirTodasAsLojas,
  informacoesEstoqueGeral,
  setExibirTodasAsLojas,
  setVariacoesEstoqueAgrupado,
}: ModalListarVariacoesMobileProps) => {
  const selecionandoLoja = {
    properties: [
      {
        name: lojaFantasia,
        value: 1,
      },
      informacoesEstoqueGeral.length > 0 && {
        name: 'Estoque geral (todas as lojas)',
        value: 2,
      },
      {
        name: 'Consignação',
        value: 3,
      },
    ],
  };

  const { setValue } = useFormContext();

  function handleToggleItemSelecionado(index: number) {
    const itensSelecionados = [...variacoesEstoqueAgrupado];
    const itemSelecionado = itensSelecionados[index];

    itensSelecionados.splice(index, 1, {
      ...itemSelecionado,
      tamanhos: itemSelecionado.tamanhos.map((obterValor) => ({
        ...obterValor,
        selecionado: !obterValor.selecionado,
      })),
    });

    setVariacoesEstoqueAgrupado(itensSelecionados);
  }

  function handleToggleItemSelecionadoTodasLojas(index: number) {
    const itensSelecionados = [...exibirTodasAsLojas];
    const itemSelecionado = itensSelecionados[index];

    itensSelecionados.splice(index, 1, {
      ...itemSelecionado,
      tamanhos: itemSelecionado.tamanhos.map((obterValor) => ({
        ...obterValor,
        selecionado: !obterValor.selecionado,
      })),
    });

    setExibirTodasAsLojas(itensSelecionados);
  }

  useEffect(() => {
    if (informacoesEstoqueGeral.length === 0) {
      setValue('lojaEscolhida', 1);
    }
  }, [informacoesEstoqueGeral, setValue]);

  return (
    <Box w="full">
      <Box padding="20px">
        <Flex alignItems="center" justifyContent="space-between">
          <Icon onClick={onClose} color="black" w={6} h={6} as={FiArrowLeft} />
          <Text fontSize="18px" color="primary.400">
            Consulta de estoque
          </Text>
        </Flex>
      </Box>
      <Box
        w="full"
        bg="primary.400"
        padding="20px"
        justifyContent="space-between"
      >
        <Box mt="20px" justifyContent="center" alignItems="center">
          <FilterAsyncSelect
            id="idProduto"
            name="idProduto"
            required
            asControlledByObject
            placeholder="Informe o produto"
            handleGetOptions={getProdutosOptions}
            autoFocus
            colSpan={6}
          />
          {naoExisteCores === 0 ? (
            <></>
          ) : (
            <>
              {(cores || []).length === 0 ? (
                <></>
              ) : (
                <Box mt="10px">
                  <SelectPadrao
                    id="corId"
                    name="corId"
                    placeholder="Selecionar uma cor"
                    options={cores || []}
                    isClearable
                  />
                </Box>
              )}
            </>
          )}
          {idProduto ? (
            <Box mt="10px">
              <SelectPadrao
                id="lojaEscolhida"
                placeholder="Selecione uma loja"
                name="lojaEscolhida"
                options={getOptionsByEnum(selecionandoLoja)}
              />
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </Box>

      <Flex padding="20px">
        {lojaEscolhida === 1 && (
          <DadosLojaAtualPdvMobile
            variacoesEstoqueAgrupado={variacoesEstoqueAgrupado}
            handleToggleItemSelecionado={handleToggleItemSelecionado}
          />
        )}
        {lojaEscolhida === 2 && (
          <DadosPdvEstoqueGeralMobile
            exibirTodasAsLojas={exibirTodasAsLojas}
            handleToggleItemSelecionadoTodasLojas={
              handleToggleItemSelecionadoTodasLojas
            }
          />
        )}
        {lojaEscolhida === 3 && (
          <DadosProdutosConsignadosPdvMobile
            casasDecimais={casasDecimais}
            produtosConsignados={produtosConsignados}
          />
        )}
      </Flex>
    </Box>
  );
};
