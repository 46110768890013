import React from 'react';
import { Box, Tr, Td, Text, Link, GridItem } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';

import ConstanteFuncionalidades from 'constants/permissoes';

import { ButtonCadastrarNovo } from 'components/Layout/ButtonCadastrarNovo';
import { SearchInput } from 'components/update/Input/SearchInput';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import { PagedTable } from 'components/update/Table/PagedTable';
import { StatusCircle } from 'components/update/Table/StatusCircle';
import { FilterSelect } from 'components/update/Select/FilterSelect';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';

import { useCredenciadoraCartaoListar } from './hooks';
import { FormData, defaultValues } from './validationForm';

const CredenciadoraCartaoListar = () => {
  const {
    isLoading,
    pagedTableRef,
    totalRegistros,
    atualizarFiltros,
    cadastrarCredenciadoraCartao,
    credenciadoraCartaoLista,
    obterCredenciadoraCartaoListaPaginada,
    redirecionarCredenciadoraCartaoAlterar,
    nomeLinkHref,
    excluirCredenciadoraCartao,
  } = useCredenciadoraCartaoListar();

  const formMethods = useForm<FormData>({
    defaultValues,
  });

  const handleAtualizarFiltros = () => {
    const filtrosForm = formMethods.getValues();

    atualizarFiltros(filtrosForm);
  };

  return (
    <FormProvider {...formMethods}>
      <SimpleGridForm>
        <GridItem colSpan={{ base: 12, md: 6 }}>
          <SearchInput
            id="nome"
            name="nome"
            placeholder="Buscar credenciadora de cartão por nome"
            onEnterKeyPress={() => handleAtualizarFiltros()}
            isDisabled={isLoading}
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, md: 3, lg: 3 }}>
          <Box width={{ base: 'full', md: '190px' }}>
            <FilterSelect
              name="ativo"
              options={[
                { label: 'Ativos', value: true },
                { label: 'Inativos', value: false },
                { label: 'Todos', value: null },
              ]}
              onSelect={() => handleAtualizarFiltros()}
              isDisabled={isLoading}
            />
          </Box>
        </GridItem>
        <GridItem
          colSpan={{ base: 12, md: 3, lg: 3 }}
          display="flex"
          justifyContent="right"
        >
          <ButtonCadastrarNovo
            funcionalidade={
              ConstanteFuncionalidades.CREDENCIADORA_CARTAO_CADASTRAR
            }
            borderRadius="full"
            height="36px"
            width={['full', '', '']}
            px="20px"
            onClick={() => cadastrarCredenciadoraCartao()}
          />
        </GridItem>
        <GridItem colSpan={12}>
          <PagedTable
            ref={pagedTableRef}
            defaultKeyOrdered="nome"
            isLoading={isLoading}
            loadColumnsData={obterCredenciadoraCartaoListaPaginada}
            itemsTotalCount={totalRegistros}
            tableHeaders={[
              {
                key: 'ativo',
                isOrderable: false,
                content: <StatusCircle hasValue={false} />,
                w: '1px',
              },
              {
                key: 'nome',
                content: 'Nome',
                isOrderable: true,
              },
              {
                key: 'cnpj',
                content: 'CNPJ',
                isOrderable: true,
              },
              { key: 'acoes', content: 'Ações', isOrderable: false, w: '1px' },
            ]}
            renderTableRows={credenciadoraCartaoLista?.map(
              ({ id, nome, cnpj, ativo }) => {
                const actionMenu = [
                  {
                    content: 'Editar',
                    onClick: () => redirecionarCredenciadoraCartaoAlterar(id),
                    funcionalidade:
                      ConstanteFuncionalidades.CREDENCIADORA_CARTAO_ALTERAR,
                  },
                  {
                    content: 'Remover',
                    onClick: () => excluirCredenciadoraCartao(id),
                    funcionalidade:
                      ConstanteFuncionalidades.CREDENCIADORA_CARTAO_EXCLUIR,
                  },
                ];

                return (
                  <Tr>
                    <Td>
                      <StatusCircle isActive={ativo} />
                    </Td>
                    <Td fontWeight="bold" whiteSpace="nowrap">
                      <Link href={nomeLinkHref(id)}>{nome}</Link>
                    </Td>
                    <Td fontWeight="bold" whiteSpace="nowrap">
                      <Text>{cnpj}</Text>
                    </Td>
                    <Td>
                      <ActionsMenu items={actionMenu} />
                    </Td>
                  </Tr>
                );
              }
            )}
          />
        </GridItem>
      </SimpleGridForm>
    </FormProvider>
  );
};

export { CredenciadoraCartaoListar };
