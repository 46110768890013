import { CreatableSelectFieldProps } from 'components/PDV/Select/CreatableSelect';
import { PaginationData } from 'components/update/Pagination';

import OptionType from 'types/optionType';

export type CreatableSelectVirtualizedProps = CreatableSelectFieldProps & {
  totalRegistros: number;
  handleGetOptions: (
    inputValue: string,
    dataPagination: PaginationData
  ) => Promise<OptionType[]>;
};

export const valuePaginationDefault = {
  currentPage: 1,
  orderColumn: '',
  orderDirection: 'asc',
  pageSize: 20,
} as PaginationData;
