import { Flex, Text } from '@chakra-ui/react';

type PaymentDetailsProps = {
  codigoCliente: number;
  dataVencimento: string;
  valorParcela: string;
  dataPagamentoPorExtenso: string;
};

export const PaymentDetails = ({
  codigoCliente,
  dataVencimento,
  valorParcela,
  dataPagamentoPorExtenso,
}: PaymentDetailsProps) => (
  <Flex
    border="1px solid #bbb"
    fontWeight="extrabold"
    flexDir="column"
    minH="50px"
    px="4px"
  >
    <Flex align="center" gap="4px" justify="space-between">
      <Flex gap="5px" align="baseline">
        <Text fontSize="10px">FICHA</Text>
        <Text fontSize="12px">{codigoCliente}</Text>
      </Flex>
      <Flex gap="5px" align="baseline">
        <Text fontSize="10px">VENCIMENTO</Text>
        <Text fontSize="12px">{dataVencimento}</Text>
      </Flex>
      <Flex gap="5px" align="baseline">
        <Text fontSize="10px">VALOR</Text>
        <Text fontSize="12px">{valorParcela}</Text>
      </Flex>
    </Flex>
    <Flex flexWrap="wrap" gap="3px">
      <Text fontWeight="normal" fontSize="10px">
        Ao(s) {dataPagamentoPorExtenso}, pagarei por esta única via de
      </Text>
      <Text fontSize="10px">NOTA PROMISSÓRIA</Text>
    </Flex>
  </Flex>
);
