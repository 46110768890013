import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  ReactNode,
  useEffect,
  useCallback,
} from 'react';
import { toast } from 'react-toastify';
import api, { ResponseApi } from 'services/api';

export interface CasasDecimais {
  casasDecimaisValor: number;
  casasDecimaisQuantidade: number;
}

interface PadronizacaoContextProps {
  casasDecimais: CasasDecimais;
  setCasasDecimais: Dispatch<SetStateAction<CasasDecimais>>;
  obterCasasDecimais: () => Promise<CasasDecimais | undefined>;
}

const PadronizacaoContext = createContext<PadronizacaoContextProps>(
  {} as PadronizacaoContextProps
);

interface PadronizacaoProviderProps {
  children: ReactNode;
  defaultCasasDecimaisValues?: CasasDecimais;
}

export default function PadronizacaoProvider({
  children,
  defaultCasasDecimaisValues,
}: PadronizacaoProviderProps): JSX.Element {
  const [casasDecimais, setCasasDecimais] = useState<CasasDecimais>({
    casasDecimaisValor: 0,
    casasDecimaisQuantidade: 0,
  });

  const obterCasasDecimais = async (): Promise<CasasDecimais | undefined> => {
    const response = await api.get<void, ResponseApi<CasasDecimais>>(
      ConstanteEnderecoWebservice.PADRONIZACAO_OBTER_CASAS_DECIMAIS
    );

    if (response) {
      if (response.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }

      if (response.sucesso && response.dados) {
        return response.dados;
      }
    }

    return undefined;
  };

  const preencherDadosPadronizacao = useCallback(async () => {
    if (defaultCasasDecimaisValues) {
      setCasasDecimais(defaultCasasDecimaisValues);
    } else {
      const casasDecimaisPadrao = await obterCasasDecimais();

      if (casasDecimaisPadrao) setCasasDecimais(casasDecimaisPadrao);
    }
  }, [defaultCasasDecimaisValues]);

  useEffect(() => {
    preencherDadosPadronizacao();
  }, [preencherDadosPadronizacao]);

  return (
    <PadronizacaoContext.Provider
      value={{
        casasDecimais,
        setCasasDecimais,
        obterCasasDecimais,
      }}
    >
      {children}
    </PadronizacaoContext.Provider>
  );
}

export function usePadronizacaoContext(): PadronizacaoContextProps {
  const context = useContext(PadronizacaoContext);

  if (!context)
    throw new Error(
      'usePadronizacaoContext must be used within a PadronizacaoProvider.'
    );

  return context;
}
