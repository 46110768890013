import { Divider, Flex, Text } from '@chakra-ui/react';

import { moneyMask } from 'helpers/format/fieldsMasks';
import formatData from 'helpers/format/formatData';

import BarcodeComponent from 'components/CodigoBarras';

import { InfoBlock } from './InfoBlock';

type CarneProps = {
  clienteNome: string;
  codigoCliente: number;
  dataEmissao: Date;
  dataVencimento: Date;
  nomeFantasia: string;
  numeroOperacao: number;
  usuarioNome: string;
  parcela: string;
  valorTotal: number;
  valorParcela: number;
  vendedorNome: string;
  showBarcode?: boolean;
};

export const Carne = ({
  clienteNome,
  codigoCliente,
  dataEmissao,
  dataVencimento,
  nomeFantasia,
  numeroOperacao,
  usuarioNome,
  parcela,
  valorTotal,
  valorParcela,
  vendedorNome,
  showBarcode = false,
}: CarneProps) => {
  return (
    <>
      <Flex
        flexDir="column"
        border="1px solid black"
        minW={showBarcode ? '55%' : '45%'}
      >
        <Flex h="24px" w="full" pl="6px" mb="4px" bg="black" align="center">
          <Text
            color="white"
            fontSize="12px"
            fontWeight="extrabold"
            noOfLines={1}
          >
            {nomeFantasia?.toUpperCase()}
          </Text>
        </Flex>

        <Flex px="12px" gap="2px" flexDir="column">
          <Text fontSize="2xs" mb="6px">
            {codigoCliente} - {clienteNome?.toUpperCase()}
          </Text>
          <Divider
            sx={{
              borderStyle: 'dashed',
              borderColor: 'black',
            }}
          />
          <Flex justify="space-between" align="center">
            <InfoBlock title="VENDA:" value={numeroOperacao} />
            <InfoBlock title="PARCELA:" value={parcela} />
          </Flex>
          <Flex>
            <Flex flexDir="column">
              <InfoBlock title="EMISSÃO:" value={formatData(dataEmissao)} />
              <InfoBlock title="VENCTO:" value={formatData(dataVencimento)} />
            </Flex>
            {showBarcode && (
              <Flex align="center" justify="flex-end" w="full">
                <BarcodeComponent
                  value={numeroOperacao?.toString()}
                  options={{
                    background: 'transparent',
                    height: 30,
                    font: 'Nunito, sans-serif',
                    width: 1.6,
                    lineColor: '#151515',
                    margin: 2,
                    fontSize: 12,
                    flat: true,
                  }}
                />
              </Flex>
            )}
          </Flex>
          <InfoBlock title="VALOR:" value={moneyMask(valorParcela, true)} />
          <Divider sx={{ borderStyle: 'dashed', borderColor: 'black' }} />
          <InfoBlock
            title="TOTAL DA COMPRA:"
            value={moneyMask(valorTotal, true)}
          />
          <Divider sx={{ borderStyle: 'dashed', borderColor: 'black' }} />
          <InfoBlock title="OPERADOR(a):" value={usuarioNome?.toUpperCase()} />
          <InfoBlock title="VENDEDOR(a):" value={vendedorNome?.toUpperCase()} />
        </Flex>
      </Flex>
    </>
  );
};
