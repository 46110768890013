import React from 'react';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

import { MultasJurosComponent } from '../pages/MultasEJuros';

const MultasEJuros = [
  <LayoutGuard
    key="padronizacao-alterar"
    permissaoFuncionalidade={ConstanteFuncionalidades.MULTA_JUROS_ALTERAR}
    component={MultasJurosComponent}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Multas e juros',
      },
    ]}
    path={ConstanteRotas.MULTAS_JUROS}
    exact
    meta={{ auth: true }}
  />,
];

export default MultasEJuros;
