import { Box, Flex, Td, Tr } from '@chakra-ui/react';

import { KitProps } from 'store/Produtos/ProdutosFormulario';
import { DecimalMask, moneyMask } from 'helpers/format/fieldsMasks';
import ConstanteFuncionalidades from 'constants/permissoes';
import auth from 'modules/auth';

type KitFooterProps = {
  tableValues: KitProps[];
};

export const KitFooter = ({ tableValues }: KitFooterProps) => {
  const possuiPermissaoVisualizarPrecoCusto = auth.possuiPermissao(
    ConstanteFuncionalidades.USUARIO_VISUALIZAR_PRECO_CUSTO
  ).permitido;

  const quantidadeTotal = tableValues.reduce(
    (acc, curr) => acc + (curr.quantidade || 0),
    0
  );
  const valorTotal = tableValues.reduce(
    (acc, curr) => acc + (curr.quantidade * curr.valor || 0),
    0
  );
  const precoCustoTotal = tableValues.reduce(
    (acc, curr) => acc + (curr.quantidade * curr.precoCusto || 0),
    0
  );
  const markupTotal =
    precoCustoTotal > 0 ? (valorTotal / precoCustoTotal - 1) * 100 : 0;

  return (
    <Tr
      sx={{
        height: '60px',
        '& td': {
          paddingTop: '16px',
          paddingBottom: '5px',
          verticalAlign: 'top',
        },
      }}
    >
      <Td>
        <Flex justifyContent="right" alignItems="center">
          <Box fontSize="14px" fontWeight="semibold">
            Resumo do Kit:
          </Box>
        </Flex>
      </Td>
      <Td pr="12px" isNumeric>
        <Flex
          alignItems="flex-end"
          justifyContent="center"
          flexDirection="column"
        >
          <Box fontSize="14px" fontWeight="semibold">
            {DecimalMask(quantidadeTotal, 2, 2)}
          </Box>
          <Box whiteSpace="nowrap" color="gray.700" fontSize="12px">
            Quantidade
          </Box>
        </Flex>
      </Td>
      {possuiPermissaoVisualizarPrecoCusto ? (
        <Td pr="12px" isNumeric>
          <Flex
            alignItems="flex-end"
            justifyContent="center"
            flexDirection="column"
          >
            <Box fontSize="14px" fontWeight="semibold">
              <span style={{ fontSize: '10px' }}>R$</span>{' '}
              {moneyMask(precoCustoTotal, false)}
            </Box>
            <Box whiteSpace="nowrap" color="gray.700" fontSize="12px">
              Custo
            </Box>
          </Flex>
        </Td>
      ) : (
        <Td />
      )}
      <Td pr="12px" isNumeric>
        <Flex
          alignItems="flex-end"
          justifyContent="center"
          flexDirection="column"
        >
          <Box color="blue.700" fontSize="14px" fontWeight="semibold">
            <span style={{ fontSize: '10px' }}>R$</span>{' '}
            {moneyMask(valorTotal, false)}
          </Box>
          <Box whiteSpace="nowrap" color="gray.700" fontSize="12px">
            Valor final
          </Box>
        </Flex>
      </Td>
      <Td pr="12px" isNumeric>
        <Flex
          alignItems="flex-end"
          justifyContent="center"
          flexDirection="column"
        >
          <Box fontSize="14px" fontWeight="semibold">{`${(
            markupTotal || 0
          ).toLocaleString('locale', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 4,
          })}%`}</Box>
          <Box color="gray.700" fontSize="12px">
            Markup final
          </Box>
        </Flex>
      </Td>
      <Td />
    </Tr>
  );
};
