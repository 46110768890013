import React from 'react';
import { Box, Flex, useMediaQuery } from '@chakra-ui/react';

import Input from 'components/PDV/Input';
import CheckboxAtivoInativo from 'components/update/Checkbox/CheckboxAtivoInativo';

interface UncontrolledFormInterface {
  readonly?: boolean;
}

export const UncontrolledForm = ({ readonly }: UncontrolledFormInterface) => {
  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');

  return (
    <Flex direction={isSmallerThan900 ? 'column' : 'row'}>
      <Box
        w={isSmallerThan900 ? 'full' : '92%'}
        mr={isSmallerThan900 ? undefined : '30px'}
        mb={isSmallerThan900 ? '20px' : undefined}
      >
        <Input
          label="Descrição"
          isRequired
          maxLength={30}
          isDisabled={readonly}
          borderRadius="md"
          placeholder="Digite um nome para diferenciar a cor"
          name="descricao"
          autoFocus
        />
      </Box>

      <Box>
        <CheckboxAtivoInativo
          id="ativo"
          name="ativo"
          label="Status"
          defaultChecked={false}
          isDisabled={readonly}
          isRequired
        />
      </Box>
    </Flex>
  );
};
