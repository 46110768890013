import React from 'react';
import {
  DrawerHeader,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  Text,
  DrawerCloseButton,
  Flex,
  Button,
  VStack,
  Divider,
  useMediaQuery,
} from '@chakra-ui/react';

import auth from 'modules/auth';

import {
  LancarEstoqueAplicarVariasLojasIcon,
  LancarEstoquePadronizarMarkupIcon,
} from 'icons';
import DrawerPadraoChakra from 'components/PDV/Drawer/DrawerPadraoChakra';

import { CheckboxCard } from '../CheckboxCard';
import { LojaProps } from '../validationForm';

type DrawerEditarConfigProps = {
  onClose: () => void;
  isOpen: boolean;
  hasVariasLojasDisponiveis: boolean;
  lojasSelecionadas?: LojaProps[];
  handleAbrirModalPadronizarMarkup: () => void;
  handleAbrirModalAplicarConfigLojas: () => void;
  handleRecalcularTodosValores: () => void;
};

export const DrawerEditarConfig = ({
  onClose,
  isOpen,
  lojasSelecionadas,
  hasVariasLojasDisponiveis,
  handleAbrirModalPadronizarMarkup,
  handleAbrirModalAplicarConfigLojas,
  handleRecalcularTodosValores,
}: DrawerEditarConfigProps) => {
  const { id: lojaAtualId } = auth?.getLoja();

  const [isLargerThan1200] = useMediaQuery('(min-width: 1200px)');

  return (
    <DrawerPadraoChakra
      isOpen={isOpen}
      placement={isLargerThan1200 ? 'right' : 'bottom'}
      onClose={onClose}
      isOverlay={false}
      closeOnOverlayClick={false}
      trapFocus={false}
      blockScrollOnMount={false}
      variant="clickThrough"
    >
      <DrawerContent
        bg="gray.50"
        boxShadow="-6px 0px 20px #00000029"
        maxW={['200px', '200px', '200px', '320px']}
        px={['4px', '4px', '4px', '8px']}
      >
        <DrawerCloseButton
          color="violet.500"
          boxSize="8px"
          mt="16px"
          mr={['0px', '0px', '0px', '8px']}
          sx={{
            svg: {
              width: '9px',
              height: '9px',
            },
          }}
          _focus={{
            boxShadow: 'none',
          }}
        />
        <DrawerHeader
          fontSize="md"
          color="violet.500"
          px="8px"
          letterSpacing="0px"
        >
          Editar configurações
        </DrawerHeader>

        <DrawerBody px="8px">
          <Text fontSize="xs" color="gray.500" mb="4px">
            Opções
          </Text>
          <Flex
            gap="4px"
            alignItems="flex-start"
            direction={['row', 'row', 'row', 'column']}
          >
            <CheckboxCard
              height={['80px', '80px', '80px', '64px']}
              name="manterPrecoVenda"
              title="Manter o preço de venda atual"
              onClick={() => handleRecalcularTodosValores()}
            />
            <CheckboxCard
              height={['80px', '80px', '80px', '64px']}
              name="manterCustoAtual"
              title="Manter o custo atual"
              onClick={() => handleRecalcularTodosValores()}
            />
            <CheckboxCard
              height={['80px', '80px', '80px', '64px']}
              name="desconsiderarDescontos"
              title="Desconsiderar descontos para o custo médio"
              onClick={() => handleRecalcularTodosValores()}
            />
            <VStack
              mt={['6px', '6px', '6px', '25px']}
              justifyContent="flex-start"
              alignItems="flex-start"
              px={['0px', '0px', '24x']}
            >
              <Button
                _hover={{ bg: 'gray.100' }}
                bg="transparent"
                color="gray.700"
                px={['4px', '4px', '4px', '14px']}
                height="32px"
                fontSize="sm"
                leftIcon={<LancarEstoquePadronizarMarkupIcon size={18} />}
                onClick={handleAbrirModalPadronizarMarkup}
              >
                Padronizar o markup
              </Button>
              {hasVariasLojasDisponiveis && (
                <Button
                  bg="transparent"
                  color="gray.700"
                  _hover={{ bg: 'gray.100' }}
                  height="32px"
                  px={['4px', '4px', '4px', '14px']}
                  leftIcon={<LancarEstoqueAplicarVariasLojasIcon size={18} />}
                  fontSize="sm"
                  letterSpacing="0px"
                  onClick={handleAbrirModalAplicarConfigLojas}
                >
                  Aplicar em outras lojas
                </Button>
              )}
            </VStack>
          </Flex>
          {hasVariasLojasDisponiveis &&
            (lojasSelecionadas || [])?.filter((loja) => loja.id !== lojaAtualId)
              ?.length > 0 && (
              <Flex
                fontSize="2xs"
                color="gray.700"
                px={['8px', '8px', '8px', '42px']}
                w="full"
                alignItems="flex-start"
                direction={['row', 'row', 'row', 'column']}
              >
                <Text mt="16px" fontWeight="bold">
                  Lojas selecionadas:
                </Text>

                <Flex
                  mt="16px"
                  gap="4px"
                  alignItems={['center', 'center', 'center', 'flex-start']}
                  ml="20px"
                  direction={['row', 'row', 'row', 'column']}
                >
                  {(lojasSelecionadas || []).map((loja) => (
                    <>
                      <Text key={loja.id}> {loja.fantasia}</Text>

                      {!isLargerThan1200 && (
                        <Divider
                          ml="4px"
                          borderColor="gray.700"
                          w="10px"
                          height="16px"
                          orientation="vertical"
                        />
                      )}
                    </>
                  ))}
                </Flex>
              </Flex>
            )}
        </DrawerBody>
        <DrawerFooter />
      </DrawerContent>
    </DrawerPadraoChakra>
  );
};
