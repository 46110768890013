import { useState, useCallback, useEffect } from 'react';
import { useMediaQuery } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import { FormData } from './validationForm';
import { PrintManagerConfigProps } from '../../types';

const useManagerConfig = () => {
  const formMethods = useForm();

  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

  const [isLoading, setIsLoading] = useState(false);
  const [printManagerConfigList, setPrintManagerConfigList] = useState<
    PrintManagerConfigProps[]
  >([]);

  const getPrintManagerConfigList = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get<
      void,
      ResponseApi<PrintManagerConfigProps[]>
    >(ConstanteEnderecoWebservice.GERENCIADOR_IMPRESSAO_CONFIGURACAO);

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response?.sucesso && response?.dados) {
        setPrintManagerConfigList(response.dados);
      }
    }
    setIsLoading(false);
  }, []);

  const updatePrintManagerConfig = useCallback(async (data: FormData) => {
    setIsLoading(true);

    const listManagers = data.gerenciador
      ?.filter(Boolean)
      .map(({ tipoImpressao, value }: any) => ({
        tipoImpressao,
        gerenciadorImpressaoId: value,
      }));

    const response = await api.put<void, ResponseApi>(
      ConstanteEnderecoWebservice.GERENCIADOR_IMPRESSAO_CONFIGURACAO,
      listManagers
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response?.sucesso) {
        toast.success('Configurações alteradas com sucesso');
      }
    }

    setIsLoading(false);
  }, []);

  useEffect(() => {
    getPrintManagerConfigList();
  }, [getPrintManagerConfigList]);

  return {
    formMethods,
    isLoading,
    isLargerThan700,
    printManagerConfigList,
    updatePrintManagerConfig,
  };
};

export { useManagerConfig };
