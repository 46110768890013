import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import MeioPagamentoEnum from 'constants/enum/fiscal/meioPagamento';

const schema = yup.object().shape({
  lancarFatura: yup.boolean(),
  nome: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .max(40, ConstanteMensagemValidacao.MAX_LENGTH_40),
  regraMeioPagamento: yup
    .string()
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  diaVencimento: yup
    .string()
    .nullable()
    .test(
      'diaVencimento',
      ConstanteMensagemValidacao.DIA_DENTRO_MES,
      function verificarDiaInformado() {
        const { regraMeioPagamento, diaVencimento } = this.parent;

        if (
          regraMeioPagamento ===
            MeioPagamentoEnum.CartaoCreditoLink.toString() ||
          regraMeioPagamento ===
            MeioPagamentoEnum.CartaoCreditoMaquina.toString()
        ) {
          if (diaVencimento < 1 || diaVencimento > 28) {
            return false;
          }
        }
        return true;
      }
    )
    .notRequired(),
});

export const yupResolver = yupResolverInstance(schema);

export const useForm = useFormInstance;
