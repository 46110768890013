import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Flex,
  Text,
  Box,
  Button,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { moneyMask } from 'helpers/format/fieldsMasks';
import { useProdutosFormularioContext } from 'store/Produtos/ProdutosFormulario';
import auth from 'modules/auth';

import { SalvarInserirNovoIcon } from 'icons';
import { ModalAdicionarVariacao } from 'components/Modal/ModalAdicionarVariacao';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';

type PrecoVariacaoProps = {
  setValoresIniciaisAlterados: React.Dispatch<React.SetStateAction<boolean>>;
};

export const PrecoVariacao = ({
  setValoresIniciaisAlterados,
}: PrecoVariacaoProps) => {
  const {
    formFields: { fields, append, remove, update },
    idProduto,
    tabelaPreco,
  } = useProdutosFormularioContext();
  const { getValues } = useFormContext();

  const { id } = auth.getLoja();

  const handleAdicionarVariacao = async (
    isAlterarModal?: boolean,
    index?: number
  ) => {
    const precoAtual = getValues(`valuePrecoVenda.${id}`);
    const custo = getValues(`valuePrecoCusto.${id}`);
    const dados = fields[index || 0];
    const newData = await ModalAdicionarVariacao({
      idProduto,
      precoAtual,
      custo,
      tabelaPreco: tabelaPreco.map((item) => ({
        value: item.id,
        label: item.nome,
      })),
      isAlterar: isAlterarModal || false,
      dados,
    });

    if (isAlterarModal) {
      setValoresIniciaisAlterados(true);
      const dadosModal = newData[0];
      update(index || 0, {
        ...dadosModal,
      });
    } else {
      setValoresIniciaisAlterados(true);
      append(newData);
    }
  };

  const handleRemoverVariacao = (index: number) => {
    const newListVariacao = [...fields];
    setValoresIniciaisAlterados(true);
    newListVariacao.splice(index, 1);

    remove(index);
  };

  return (
    <>
      {fields.length > 0 ? (
        <Table variant="none" minW="900px">
          <Thead>
            <Tr
              sx={{
                '& > th': {
                  color: 'gray.500',
                  fontWeight: 'bold',
                  paddingTop: '0 !important',
                  paddingBottom: '0',
                  fontSize: '12px',
                  paddingLeft: '0 !important',
                },
              }}
            >
              <Th w="40%">
                <Flex mb="3px" justifyContent="flex-start">
                  <Button
                    h="28px"
                    w="172px"
                    colorScheme="blue.700"
                    variant="solid"
                    fontSize="14px"
                    onClick={() => handleAdicionarVariacao(false, 0)}
                    leftIcon={<SalvarInserirNovoIcon />}
                  >
                    Adicionar variação
                  </Button>
                </Flex>
              </Th>
              <Th whiteSpace="nowrap" w="20%">
                <Flex justifyContent="flex-end">Preço especial</Flex>
              </Th>
              <Th whiteSpace="nowrap" w="17%">
                <Flex justifyContent="flex-end">Markup especial</Flex>
              </Th>
              <Th whiteSpace="nowrap" w="23%">
                <Flex justifyContent="center">Tabela</Flex>
              </Th>
              <Th w="1%" />
            </Tr>
          </Thead>
          <Tbody>
            {fields.map((variacaoItem, index) => (
              <>
                <Tr
                  sx={{
                    boxShadow: '0px 0px 4px #00000029',
                    borderRadius: '6px',
                    _hover: {
                      boxShadow: '0px 0px 4px rgba(85, 2, 178, 0.5)',
                    },
                    '& > td': {
                      height: '52px !important',
                    },
                  }}
                >
                  <Td
                    borderStartRadius="6px"
                    bg="white"
                    display="flex"
                    fontSize="14px"
                  >
                    <Text color="teal.600">
                      {variacaoItem.produtoCorTamanho.cor}
                    </Text>
                    {variacaoItem.produtoCorTamanho.cor &&
                      variacaoItem.produtoCorTamanho.tamanho && (
                        <Text mx="5px">|</Text>
                      )}
                    <Text color="pink.600">
                      {variacaoItem.produtoCorTamanho.tamanho}
                    </Text>
                  </Td>
                  <Td bg="white" isNumeric>
                    <Flex justifyContent="flex-end">
                      {moneyMask(variacaoItem.precoVenda.precoVenda, true)}
                    </Flex>
                  </Td>
                  <Td bg="white" isNumeric>
                    <Flex justifyContent="flex-end">
                      {moneyMask(variacaoItem.precoVenda.markup, false)}%
                    </Flex>
                  </Td>
                  <Td bg="white">
                    <Flex justifyContent="center">
                      {variacaoItem.tabelaPreco}
                    </Flex>
                  </Td>
                  <Td borderEndRadius="6px" bg="white" isNumeric>
                    <ActionsMenu
                      items={[
                        {
                          content: 'Editar',
                          onClick: () => handleAdicionarVariacao(true, index),
                        },
                        {
                          content: 'Remover',
                          onClick: () => handleRemoverVariacao(index),
                        },
                      ]}
                    />
                  </Td>
                </Tr>
                <Box h="4px" />
              </>
            ))}
          </Tbody>
        </Table>
      ) : (
        <Button
          h="28px"
          w="172px"
          variant="solid"
          fontSize="14px"
          fontWeight="normal"
          colorScheme="blue.700"
          onClick={() => handleAdicionarVariacao(false, 0)}
          leftIcon={<SalvarInserirNovoIcon />}
        >
          Adicionar variação
        </Button>
      )}
    </>
  );
};
