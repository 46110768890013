import React, { useEffect, useState, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useHistory, RouteComponentProps, Prompt } from 'react-router-dom';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteRotas from 'constants/rotas';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import isPrenvent from 'helpers/layout/isPrenvent';
import { obterTransferenciaEstoque as apiObterTransferenciaEstoque } from 'helpers/api/TransferenciaEstoque/obterTransferenciaEstoque';
import StatusOperacaoTransferenciaEnum from 'constants/enum/statusOperacaoTransferencia';

import { ModalConfirmacao } from 'components/Modal/ModalConfirmacao';
import { ModalSucesso } from 'components/Modal/ModalSucesso';
import ManterFoco from 'components/Geral/ManterFoco';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { FormFooter } from 'components/update/Form/FormFooter';

import auth from 'modules/auth';
import Formulario, { ForwardRefProps } from '..';
import { yupResolver, FormData } from '../validationForm';

type TParams = { id: string };

const Alterar = ({ match }: RouteComponentProps<TParams>) => {
  const history = useHistory();
  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues: {
      usuario: '',
      dataEmissao: new Date().toLocaleString('pt-BR'),
    },
  });
  const isMountedRef = useIsMountedRef();

  const [dataHoraCriacao, setDataHoraCriacao] = useState('');
  const [dataHoraUltimaAlteracao, setDataHoraUltimaAlteracao] = useState('');

  const formularioRef = useRef<ForwardRefProps>(null);

  const { dirtyFields } = formMethods.formState;
  const isDirty =
    dirtyFields &&
    dirtyFields.constructor === Object &&
    Object.keys(dirtyFields).length > 0;

  const [isPrenvented, setIsPrenvented] = useState(false);
  isPrenvent(isPrenvented);

  const transferenciaEstoqueId = match.params.id;

  const [isLoading, setIsLoading] = useState(false);
  const lojaDestino = formMethods.watch('lojaDestino');
  const { id: lojaId } = auth.getLoja();
  const isTranferenciaEntreEstoque =
    lojaDestino && lojaDestino.value === lojaId;

  function historyPush(path: string) {
    setIsPrenvented(false);

    history.push(path);
  }

  async function alterarTransferenciaEstoque(
    data: FormData,
    isRascunho?: boolean
  ) {
    if (formularioRef.current) {
      if (formularioRef.current.produtos.length) {
        const { motivo } = data;

        const listaItemAdicionar = formularioRef.current.produtos
          .filter((produto) => !produto.operacaoItemId)
          .map(({ produtoCorTamanhoId, quantidade }) => ({
            produtoCorTamanhoId,
            quantidade,
          }));

        const listaItensRemover = formularioRef.current.operacoesItemExcluir.map(
          (itemExcluir) => ({
            operacaoId: transferenciaEstoqueId,
            operacaoItemId: itemExcluir,
          })
        );

        const listaItensAlterar = formularioRef.current.operacoesItemAlterar.filter(
          (itemAlterar) =>
            !listaItensRemover.some(
              (itemRemover) =>
                itemRemover.operacaoItemId === itemAlterar.operacaoItemId
            )
        );

        const response = await api.put<void, ResponseApi>(
          ConstanteEnderecoWebservice.TRANSFERENCIA_ESTOQUE_ALTERAR,
          {
            operacaoTransferenciaId: transferenciaEstoqueId,
            motivo,
            listaItemAdicionar,
            listaItensRemover,
            listaItensAlterar,
            status: isRascunho
              ? StatusOperacaoTransferenciaEnum.RASCUNHO
              : StatusOperacaoTransferenciaEnum.PENDENTE,
          }
        );

        if (response) {
          if (response.avisos) {
            response.avisos.map((item: string) => toast.warning(item));
          }

          if (response.sucesso) {
            return true;
          }
        }
      } else {
        toast.warning(
          'Adicione ao menos um produto para salvar a movimentação.'
        );

        formularioRef.current.handleSetProdutosError();
      }
    }

    return false;
  }

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    await ModalConfirmacao({
      callback: async (ok: boolean) => {
        if (ok) {
          setIsLoading(true);

          const success = await alterarTransferenciaEstoque(data);

          if (success) {
            toast.success('O cadastro foi alterado com sucesso.');

            historyPush(ConstanteRotas.TRANSFERENCIA_ESTOQUE);
          }

          if (isMountedRef.current) setIsLoading(false);
        }
      },
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Enviar',
      title: 'Enviar transferência',
      text: (
        <>
          Esta ação depende de uma confirmação de recebimento do local de
          destino para ser finalizada. Para sua segurança, os itens serão{' '}
          <strong>baixados no estoque de origem</strong> e ficarão com entrada
          pendente no estoque de destino até a confirmação de recebimento da
          operação.
        </>
      ),
    });
  });

  const handleSubmitReset = formMethods.handleSubmit(async (data) => {
    setIsLoading(true);

    const success = await alterarTransferenciaEstoque(data, true);

    if (success) {
      await ModalSucesso({
        titulo: 'Salvo como rascunho!',
        textoMensagem:
          'A transferência foi salva e você pode retomá-la quando quiser. Os estoques não serão modificados até que seja finalizado o processo.',
        autoClose: false,
        onConfirm: historyPush(ConstanteRotas.TRANSFERENCIA_ESTOQUE),
      });

      historyPush(ConstanteRotas.TRANSFERENCIA_ESTOQUE);
    }

    if (isMountedRef.current) setIsLoading(false);
  });

  const latestProps = useRef({
    reset: formMethods.reset,
    transferenciaEstoqueId,
    historyPush,
    setFocus: formMethods.setFocus,
  });
  useEffect(() => {
    latestProps.current = {
      reset: formMethods.reset,
      transferenciaEstoqueId,
      historyPush,
      setFocus: formMethods.setFocus,
    };
  });

  useEffect(() => {
    async function obterTransferenciaEstoque() {
      setIsLoading(true);

      const responseData = await apiObterTransferenciaEstoque(
        latestProps.current.transferenciaEstoqueId
      );

      if (responseData) {
        const {
          lojaOrigemFantasia,
          localEstoqueOrigemOpcaoSelect,
          lojaDestinoOpcaoSelect,
          localEstoqueDestinoOpcaoSelect,
          motivo,
          usuarioNome,
          dataHoraEmissao,
          dataHoraEnvio,
        } = responseData;

        setDataHoraCriacao(dataHoraEmissao);
        setDataHoraUltimaAlteracao(dataHoraEnvio || '');

        latestProps.current.reset({
          usuario: usuarioNome,
          motivo,
          dataEmissao: new Date(dataHoraEmissao).toLocaleString('pt-BR'),
          lojaOrigem: lojaOrigemFantasia,
          localEstoqueOrigem: {
            value: localEstoqueOrigemOpcaoSelect.id,
            label: localEstoqueOrigemOpcaoSelect.nome,
          },
          lojaDestino: {
            value: lojaDestinoOpcaoSelect.id,
            label: lojaDestinoOpcaoSelect.nome,
          },
          localEstoqueDestino: {
            value: localEstoqueDestinoOpcaoSelect.id,
            label: localEstoqueDestinoOpcaoSelect.nome,
          },
        });

        setIsLoading(false);
      } else {
        setIsLoading(false);

        latestProps.current.historyPush(ConstanteRotas.TRANSFERENCIA_ESTOQUE);
      }
    }

    obterTransferenciaEstoque();
  }, []);

  useEffect(() => {
    setIsPrenvented(isDirty);
  }, [isDirty]);

  return (
    <Box>
      <Prompt when={isPrenvented} message="" />
      <ManterFoco>
        {isLoading && <LoadingPadrao />}
        <FormProvider {...formMethods}>
          <Formulario
            ref={formularioRef}
            transferenciaEstoqueId={transferenciaEstoqueId}
            setIsPrenvented={setIsPrenvented}
            isCadastrar={false}
          />
        </FormProvider>

        <FormFooter
          onSubmit={handleSubmit}
          onSubmitReset={handleSubmitReset}
          isDisabled={isLoading}
          dates={{ dataHoraCriacao, dataHoraUltimaAlteracao }}
          {...(isTranferenciaEntreEstoque
            ? {}
            : {
                textSubmit: 'Salvar e enviar',
                textSubmitReset: 'Salvar rascunho',
                submitResetButtonMaxWidth: { base: 'full', sm: '175px' },
                withoutIcons: true,
              })}
        />
      </ManterFoco>
    </Box>
  );
};

export default Alterar;
