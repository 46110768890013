import { useCallback, useRef, useState } from 'react';
import {
  Flex,
  Td,
  Tr,
  Text,
  useMediaQuery,
  HStack,
  VStack,
  Box,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { useFormContext } from 'react-hook-form';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import api, { ResponseApi } from 'services/api';
import EnumStatus from 'constants/enum/enumStatus';

import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import { PaginationData } from 'components/update/Pagination';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import {
  PagedTable,
  PagedTableForwardRefData,
} from 'components/update/Table/PagedTable';
import { BuscaIcon } from 'icons';
import { ModalDesistencia } from 'components/Modal/ModalDesistencia';

import { Container } from '../Layout/Container';
import { ModalAdicionarDescricao } from './ModalAdicionarDescricao';

type ListarDispositivosProps = {
  id: string;
  apelido: string;
  identificador: string;
  ativo: boolean;
};

export const Dispositivos = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [listarDispositivos, setListarDispositivos] = useState<
    ListarDispositivosProps[]
  >();

  const isMountedRef = useIsMountedRef();
  const pageIsLoaded = useRef(false);
  const pagedTableRef = useRef<PagedTableForwardRefData>(null);

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const { watch, setValue } = useFormContext();

  const [nomeWatch, ativoWatch] = watch(['nome', 'ativo']);

  const handleAtualizarStatus = useCallback(
    (value: boolean | null | string) => {
      setValue('ativo', value);
    },
    [setValue]
  );

  const desabilitarLicenca = useCallback(async (id: string) => {
    const response = await api.put<void, ResponseApi>(
      `${ConstanteEnderecoWebservice.INTEGRACAO_SMART_POS_DESABILITAR_LICENCA}?id=${id}`
    );
    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
        return false;
      }
      if (response.sucesso) {
        toast.success('A alteração foi realizada com sucesso');
        pagedTableRef.current?.reload();
      }
      setIsLoading(false);
    }
    setIsLoading(false);
    return false;
  }, []);

  const handleDesabilitarLicenca = async (id: string) => {
    ModalDesistencia({
      title: 'Desativar licença?',
      labelButtonCancelar: 'Cancelar',
      labelButtonConfirmar: 'Sim, desativar',
      textoMensagem:
        'O Smart POS não poderá mais se comunicar com o sistema para informar vendas e pagamentos. Para ativar novamente a licença será necessário refazer o login na própria maquininha. \nDeseja desativar a licença?',
      callback: () => desabilitarLicenca(id),
    });
  };

  const handleAlterarLicenca = async (id: string, apelido: string) => {
    const response = await api.put<void, ResponseApi>(
      ConstanteEnderecoWebservice.INTEGRACAO_SMART_POS_ADICIONAR_NOME,
      {
        id,
        apelido,
      }
    );
    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
        return false;
      }
      if (response.sucesso) {
        toast.success('A alteração foi realizada com sucesso');
        pagedTableRef.current?.reload();
      }
    }
    return false;
  };

  const handleAbriModalAlterarDescricao = useCallback(
    async (id: string, identificador: string) => {
      const { descricao } = await ModalAdicionarDescricao({ identificador });

      if (descricao) {
        handleAlterarLicenca(id, descricao);
      }
    },
    []
  );

  const paginationHandle = useCallback(
    async (gridPaginadaConsulta: PaginationData) => {
      setIsLoading(true);
      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<ListarDispositivosProps>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.INTEGRACAO_SMART_POS_LISTAR_DISPOSITIVO,
          gridPaginadaConsulta,

          { nome: nomeWatch, ativo: ativoWatch }
        )
      );

      if (response?.sucesso && isMountedRef.current) {
        setTotalRegistros(response.dados.total);
        setListarDispositivos(response.dados.registros);
        setIsLoading(false);
      }

      if (isMountedRef.current) {
        setIsLoading(false);

        if (!pageIsLoaded.current) {
          pageIsLoaded.current = true;
        }
      }
    },
    [ativoWatch, isMountedRef, nomeWatch]
  );

  return (
    <Container pl="0" pt="30px" pr="0">
      <PagedTable
        ref={pagedTableRef}
        isLoading={isLoading}
        variant="card"
        bg="transparent"
        boxShadow="none"
        paddingRight="24px"
        paddingLeft="24px"
        pt={['10px', '10px', '']}
        paginationHasDivider={false}
        loadColumnsData={paginationHandle}
        itemsTotalCount={totalRegistros}
        defaultKeyOrdered="nome"
        isTable={isLargerThan900}
        tableHeaders={[
          {
            key: 'pdv',
            content: 'PDV',
            isOrderable: false,
          },
          { key: 'pos-serial', content: 'POS serial', isOrderable: false },

          {
            key: 'status-licenca',
            isOrderable: false,
            w: '100px',
            content: (
              <Flex justifyContent="center" alignItems="center">
                <Text mr="5px">Status da licença</Text>

                <ActionsMenu
                  iconItem={BuscaIcon}
                  items={[
                    {
                      content: 'Ativas',
                      onClick: () => {
                        handleAtualizarStatus(EnumStatus.ATIVOS);
                      },
                    },
                    {
                      content: 'Inativas',
                      onClick: () => {
                        handleAtualizarStatus(EnumStatus.INATIVOS);
                      },
                    },
                    {
                      content: 'Todas',
                      onClick: () => {
                        handleAtualizarStatus(EnumStatus.TODOS);
                      },
                    },
                  ]}
                />
              </Flex>
            ),
          },
          { key: 'acoes', content: 'Ações', isOrderable: false, w: '1px' },
        ]}
        renderTableRows={(listarDispositivos || []).map(
          (registroDispositivo) => {
            const valueStatus = EnumStatus.options.find(
              (itemStatus) => itemStatus.value === registroDispositivo.ativo
            );

            const adicionarNomePdv = [
              {
                content: 'Adicionar nome no PDV',
                onClick: () =>
                  handleAbriModalAlterarDescricao(
                    registroDispositivo.id,
                    registroDispositivo.identificador
                  ),
              },
            ];

            const actionsItens =
              valueStatus?.value === EnumStatus.ATIVOS
                ? [
                    ...adicionarNomePdv,
                    {
                      content: 'Desativar licença',
                      onClick: () =>
                        handleDesabilitarLicenca(registroDispositivo.id),
                    },
                  ]
                : adicionarNomePdv;

            return (
              <>
                {isLargerThan900 ? (
                  <>
                    <Tr
                      sx={{
                        boxShadow: '0px 0px 4px #00000029',
                        borderRadius: '6px',
                        '& > td': {
                          height: '30px',
                          bg:
                            valueStatus?.value === EnumStatus.ATIVOS
                              ? 'white'
                              : 'gray.50',
                        },
                      }}
                    >
                      <Td w="400px" fontWeight="bold">
                        {registroDispositivo.apelido}
                      </Td>
                      <Td fontWeight="bold">
                        {registroDispositivo.identificador}
                      </Td>
                      <Td>
                        <Text
                          fontSize="14px"
                          color="white"
                          p="2px"
                          w="70px"
                          borderRadius="12px"
                          textAlign="center"
                          bg={
                            valueStatus?.value === EnumStatus.ATIVOS
                              ? 'aquamarine.600'
                              : 'red.500'
                          }
                        >
                          {valueStatus?.label}
                        </Text>
                      </Td>

                      <Td>
                        <ActionsMenu items={actionsItens} />
                      </Td>
                    </Tr>
                    <Box h="5px" />
                  </>
                ) : (
                  <>
                    <VStack
                      alignItems="left"
                      textAlign="left"
                      borderRadius="5px"
                      w="full"
                      bg={
                        valueStatus?.value === EnumStatus.ATIVOS
                          ? 'white'
                          : 'gray.50'
                      }
                      pl="12px"
                      pr="12px"
                      pt="15px"
                      pb="15px"
                      spacing="10px"
                      boxShadow="0px 0px 4px #00000029"
                    >
                      <HStack alignItems="baseline">
                        <Text fontSize="10px" color="gray.500">
                          PDV:
                        </Text>
                        <Text fontSize="14px" color="black" fontWeight="bold">
                          {registroDispositivo.apelido}
                        </Text>
                      </HStack>
                      <HStack alignItems="baseline">
                        <Text fontSize="10px" color="gray.500">
                          POS serial:
                        </Text>
                        <Text fontSize="14px" color="black" fontWeight="bold">
                          {registroDispositivo.identificador}
                        </Text>
                      </HStack>
                      <HStack
                        justifyContent="space-between"
                        alignItems="baseline"
                      >
                        <HStack>
                          <Text fontSize="10px" color="gray.500">
                            Status da licença:
                          </Text>
                          <Text
                            fontSize="14px"
                            color="white"
                            p="2px"
                            pl="10px"
                            pr="10px"
                            borderRadius="12px"
                            bg={
                              valueStatus?.value === EnumStatus.ATIVOS
                                ? 'aquamarine.600'
                                : 'red.500'
                            }
                          >
                            {valueStatus?.label}
                          </Text>
                        </HStack>
                        <ActionsMenu items={actionsItens} />
                      </HStack>
                    </VStack>
                    <Box h="5px" />
                  </>
                )}
              </>
            );
          }
        )}
      />
    </Container>
  );
};
