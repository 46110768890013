import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

import { shapeEditarDimensoesVariacaoModal } from '../EditarDimensoesVariacaoModal/validationForm';
import { shapeEditarGTINEANVariacaoModal } from '../EditarGTINEANVariacaoModal/validationForm';
import { shapeEditarEstoqueVariacaoModal } from '../EditarEstoqueVariacaoModal/validationForm';

const shapeEditarVariacaoModal = {
  codigoExterno: yup
    .string()
    .nullable()
    .max(60, ConstanteMensagemValidacao.MAX_LENGTH_60)
    .default(null),
  ativo: yup
    .boolean()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(true),
  imagem: yup.string(),
  sku: yup.number(),
  codigoBarrasInterno: yup.number(),
  pesoEmbalagem: yup.number(),
};

export const schemaVariacao = yup.object().shape({
  ...shapeEditarVariacaoModal,
  ...shapeEditarEstoqueVariacaoModal,
  ...shapeEditarGTINEANVariacaoModal,
  ...shapeEditarDimensoesVariacaoModal,
});

export const yupResolver = yupResolverInstance(schemaVariacao);

export const defaultValues = schemaVariacao.default();

export const useForm = useFormInstance;
