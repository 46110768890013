import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

type DuplicarOperacaoProps = {
  dadosSucesso: string;
  erro: null | number;
};

const duplicarOperacao = async (id: string): Promise<string | undefined> => {
  const response = await api.post<void, ResponseApi<DuplicarOperacaoProps>>(
    `${ConstanteEnderecoWebservice.PEDIDOORCAMENTOVENDA_DUPLICAR}/${id}`,
    id
  );

  if (response) {
    if (response?.avisos) {
      response.avisos.forEach((aviso: string) => toast.warning(aviso));
    }
    if (response?.sucesso && response?.dados) {
      const { dadosSucesso } = response.dados;
      if (dadosSucesso) {
        return dadosSucesso;
      }
    }
  }

  return undefined;
};

export default duplicarOperacao;
