/* eslint-disable react/no-unused-prop-types */
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { toast } from 'react-toastify';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { useFormContext } from 'react-hook-form';

interface LojaInformacoesFiscais {
  regimeTributario: number;
  icmsAproveitamentoAliquota: number;
  nFeNumeroSerie: number;
  nFCeNumeroSerie: number;
  codigoEstado: any;
  estadoId: number;
  paisId: number;
}
type PesoBrutoLiquidoProps = { pesoLiquido: number; pesoBruto: number };
interface NotaFiscalFormularioContextProps {
  lojaInformacoesFiscais: LojaInformacoesFiscais;
  tipoAmbienteFiscal?: number;
  calcularTotalNota: () => void;
  calcularTotais: () => void;
  calculoManualTotalNota: boolean;
  setCalculoManualTotalNota: React.Dispatch<React.SetStateAction<boolean>>;
  readonly: boolean;
  alteracaoCarregada: boolean;
}

const NotaFiscalFormularioContext = createContext<NotaFiscalFormularioContextProps>(
  {} as NotaFiscalFormularioContextProps
);

interface SignalRProviderProps {
  children: React.ReactNode | undefined;
  readonly: boolean;
  alteracaoCarregada: boolean;
}

interface NotaFiscalTotaisCalculoInterface {
  icmsBaseCalculo: number;
  icmsValor: number;
  icmsStBaseCalculo: number;
  icmsStValor: number;
  valorProdutos: number;
  valorImpostoImportacao: number;
  valorSeguro: number;
  valorDespesas: number;
  valorDesconto: number;
  valorIpi: number;
  icmsFcpValorTotal: number;
  icmsStFcpValorTotal: number;
  icmsStFcpRetidoValorTotal: number;
  pisValorTotal: number;
  cofinsValorTotal: number;
  ipiDevolucaoValorTotal: number;
  valorTotalTributos: number;
  icmsDesoneradoValorTotal: number;
  pesoLiquidoTotal: number;
  pesoBrutoTotal: number;
}

export default function NotaFiscalFormularioProvider({
  children,
  readonly,
  alteracaoCarregada,
}: SignalRProviderProps): JSX.Element {
  const [
    lojaInformacoesFiscais,
    setLojaInformacoesFiscais,
  ] = useState<LojaInformacoesFiscais>({} as LojaInformacoesFiscais);

  const [tipoAmbienteFiscal, setTipoAmbienteFiscal] = useState<number>();

  const [calculoManualTotalNota, setCalculoManualTotalNota] = useState(false);

  const {
    getValues: parentGetValues,
    setValue: parentSetValue,
  } = useFormContext();

  const realizarRateioFrete = () => {
    const { valorFrete, documentoFiscalItens } = parentGetValues();

    const quantidadeItens = documentoFiscalItens.length;

    const restoDivisao = ((valorFrete * 100) % quantidadeItens) / 100;
    const cadaParcela = Math.floor((valorFrete * 100) / quantidadeItens) / 100;

    const parcelas = [];

    for (let parcela = 1; parcela <= quantidadeItens; parcela += 1) {
      parcelas.push(cadaParcela);
    }

    if (restoDivisao > 0) {
      let indiceParcela = 0;

      let valorSomaParcelas = cadaParcela * quantidadeItens;

      while (valorSomaParcelas < valorFrete) {
        if (!parcelas[indiceParcela]) {
          indiceParcela = 0;
        }

        parcelas[indiceParcela] = parseFloat(
          (parcelas[indiceParcela] + 0.01).toFixed(2)
        );

        valorSomaParcelas += 0.01;
        indiceParcela += 1;
      }
    }

    for (let index = 0; index < documentoFiscalItens.length; index += 1) {
      documentoFiscalItens[index].valorFrete = parcelas[index];
    }

    parentSetValue('documentoFiscalItens', [...documentoFiscalItens]);
  };

  const calcularTotalNota = () => {
    const {
      valorProdutos,
      valorFrete,
      valorSeguro,
      valorDespesas,
      icmsStValor,
      valorIpi,
      valorDesconto,
      icmsDesoneradoValorTotal,
      ipiDevolucaoValorTotal,
    } = parentGetValues();

    const valorTotal =
      valorProdutos +
      valorFrete +
      valorSeguro +
      valorDespesas +
      icmsStValor +
      valorIpi +
      ipiDevolucaoValorTotal -
      valorDesconto -
      icmsDesoneradoValorTotal;

    parentSetValue('valorTotal', valorTotal);
  };

  const calcularTotais = useCallback(async () => {
    const itens = parentGetValues('documentoFiscalItens') as Array<any>;

    const response = await api.post<
      void,
      ResponseApi<NotaFiscalTotaisCalculoInterface>
    >(
      ConstanteEnderecoWebservice.NOTA_FISCAL_CALCULAR_TOTAIS,
      itens.map((item) => ({
        produtoCorTamanhoId: item.produto.value,
        codigoEstado: lojaInformacoesFiscais?.codigoEstado,
        icmsBaseCalculo: item.icmsBaseCalculo,
        icmsValor: item.icmsValor,
        icmsStBaseCalculo: item.icmsStBaseCalculo,
        icmsStValor: item.icmsStValor,
        valorTotal: item.valorTotal,
        impostoImportacao: item.impostoImportacao,
        valorSeguro: item.valorSeguro,
        valorDesconto: item.valorDesconto,
        valorOutrasDespesas: item.valorOutrasDespesas,
        ipiValor: item.ipiValor,
        fcpValor: item.fcpValor,
        fcpStValor: item.fcpStValor,
        fcpStRetidoValor: item.fcpStRetidoValor,
        pisValor: item.pisValor,
        cofinsValor: item.cofinsValor,
        ipiValorDevolvido: item.ipiValorDevolvido,
        icmsDesoneradoValor: item.icmsDesoneradoValor,
        indicadorValorTotalNota: item.indicadorValorTotalNota,
        calcularTributosMunEstFed: item.calcularTributosMunEstFed,
        pesoLiquido: item.pesoLiquido ? item.pesoLiquido * item?.quantidade : 0,
        pesoBruto: item.pesoBruto ? item.pesoBruto * item?.quantidade : 0,
        icmsReducaoBaseCalculo: item?.icmsReducaoBaseCalculo,
        icmsAliquota: item.icmsAliquota,
      }))
    );

    if (response.sucesso) {
      const {
        icmsBaseCalculo,
        icmsValor,
        icmsStBaseCalculo,
        icmsStValor,
        valorProdutos,
        valorImpostoImportacao,
        valorSeguro,
        valorDespesas,
        valorDesconto,
        valorIpi,
        icmsFcpValorTotal,
        icmsStFcpValorTotal,
        icmsStFcpRetidoValorTotal,
        pisValorTotal,
        cofinsValorTotal,
        ipiDevolucaoValorTotal,
        valorTotalTributos,
        icmsDesoneradoValorTotal,
        pesoBrutoTotal,
        pesoLiquidoTotal,
      } = response.dados;

      parentSetValue('icmsBaseCalculo', icmsBaseCalculo);
      parentSetValue('icmsValor', icmsValor);
      parentSetValue('icmsStBaseCalculo', icmsStBaseCalculo);
      parentSetValue('icmsStValor', icmsStValor);
      parentSetValue('valorProdutos', valorProdutos);
      parentSetValue('valorImpostoImportacao', valorImpostoImportacao);
      parentSetValue('valorSeguro', valorSeguro);
      parentSetValue('valorDespesas', valorDespesas);
      parentSetValue('valorDesconto', valorDesconto);
      parentSetValue('valorIpi', valorIpi);
      parentSetValue('icmsFcpValorTotal', icmsFcpValorTotal);
      parentSetValue('icmsStFcpValorTotal', icmsStFcpValorTotal);
      parentSetValue('icmsStFcpRetidoValorTotal', icmsStFcpRetidoValorTotal);
      parentSetValue('pisValorTotal', pisValorTotal);
      parentSetValue('cofinsValorTotal', cofinsValorTotal);
      parentSetValue('ipiDevolucaoValorTotal', ipiDevolucaoValorTotal);
      parentSetValue('valorTotalTributos', valorTotalTributos);
      parentSetValue('icmsDesoneradoValorTotal', icmsDesoneradoValorTotal);
      parentSetValue('pesoLiquido', pesoLiquidoTotal);
      parentSetValue('pesoBruto', pesoBrutoTotal);

      if (!calculoManualTotalNota) {
        calcularTotalNota();
      }

      realizarRateioFrete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lojaInformacoesFiscais]);

  const handleGetLojaInformacoesFiscais = useCallback(async () => {
    const response = await api.get<void, ResponseApi<LojaInformacoesFiscais>>(
      ConstanteEnderecoWebservice.LOJA_OBTER_INFORMACOES_FISCAIS
    );

    if (response.sucesso) {
      setLojaInformacoesFiscais(response.dados);
    }

    if (response.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }
  }, []);

  const handleGetAmbienteFiscal = useCallback(async () => {
    const response = await api.get<void, ResponseApi<number>>(
      ConstanteEnderecoWebservice.LOJA_OBTER_TIPO_AMBIENTE_FISCAL
    );

    if (response.sucesso) {
      setTipoAmbienteFiscal(response.dados);
    }

    if (response.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }
  }, []);

  useEffect(() => {
    handleGetLojaInformacoesFiscais();
  }, [handleGetLojaInformacoesFiscais]);

  useEffect(() => {
    handleGetAmbienteFiscal();
  }, [handleGetAmbienteFiscal]);

  return (
    <NotaFiscalFormularioContext.Provider
      value={{
        lojaInformacoesFiscais,
        tipoAmbienteFiscal,
        calcularTotalNota,
        readonly,
        calcularTotais,
        setCalculoManualTotalNota,
        calculoManualTotalNota,
        alteracaoCarregada,
      }}
    >
      {children}
    </NotaFiscalFormularioContext.Provider>
  );
}

export function useNotaFiscalFormularioContext(): NotaFiscalFormularioContextProps {
  const context = useContext(NotaFiscalFormularioContext);

  if (!context)
    throw new Error(
      'useNotaFiscalFormularioContext must be used within a NotaFiscalFormularioProvider.'
    );

  return context;
}
