import { Checkbox } from '@chakra-ui/react';

export const ContaCheckbox = ({
  idMovimentacaoFinanceiraBaixa,
  contaIsChecked,
  possuiPermissaoAlterarListagem,
  toggleSelect,
}: {
  idMovimentacaoFinanceiraBaixa: string;
  contaIsChecked: boolean;
  possuiPermissaoAlterarListagem: boolean;
  toggleSelect: (id: string) => void;
}) => (
  <Checkbox
    onChange={() => toggleSelect(idMovimentacaoFinanceiraBaixa)}
    isChecked={contaIsChecked}
    colorScheme="primary"
    isDisabled={!possuiPermissaoAlterarListagem}
    mb="0"
    bg="white"
    boxShadow="inset 0px 3px 6px #00000029"
  />
);
