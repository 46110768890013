import React from 'react';
import {
  Flex,
  Heading,
  Text,
  VStack,
  useMediaQuery,
  GridItem,
  Divider,
  Icon,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { FiChevronLeft } from 'react-icons/fi';
import format from 'date-fns/format';
import ptBR from 'date-fns/locale/pt-BR';

import ModelosFiscaisEnum from 'constants/enum/fiscal/modelosFiscais';
import StatusOperacaoEnum from 'constants/enum/statusOperacao';
import ConstanteRotas from 'constants/rotas';
import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';
import { getName } from 'helpers/enum/getName';

import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { TextValor } from 'components/PDV/Text/TextValor';

import DetailsRow from './DetailsRow';

type ModeloNumeroFiscal = {
  modeloFiscal: number;
  numeroFiscal: string;
};

type Operacao = {
  numeroOperacao: number;
  identificacaoTipoOperacao: number;
  dataEmissao: Date;
  status: number;
  modeloNumeroFiscais?: ModeloNumeroFiscal[];
  cliente: string;
  endereco?: string;
  telefone?: string;
  email?: string;
  vendedorNome: string;
  caixa: string;
  tabelaPreco: string;
  quantidade: number;
  observacao: string;
  motivoCancelamento: string;
  origem: string;
  origemPlataforma: {
    dispositivoId: string | null;
    idPedidoPlataforma: number;
    origemEnum: number;
  };
  subtotal: number;
  totalAcrescimos: number;
  totalDescontos: number;
  valorTotal: number;
  troco: number;
};

interface HeaderProps {
  operacao?: Operacao;
  IsCadastroExterno?: boolean;
}

function formatDate(date: Date) {
  const formattedDate = format(new Date(date), 'dd/MM/yyyy', {
    locale: ptBR,
  });

  return formattedDate;
}

const Header = ({ operacao, IsCadastroExterno = false }: HeaderProps) => {
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const history = useHistory();

  const operacaoCancelada = operacao?.status === StatusOperacaoEnum.CANCELADA;

  const operacaoPedidoOuOrcamento =
    operacao?.identificacaoTipoOperacao ===
      IdentificacaoTipoOperacaoEnum.ORCAMENTO ||
    operacao?.identificacaoTipoOperacao ===
      IdentificacaoTipoOperacaoEnum.PEDIDO;

  const textoSituacaoCancelada = operacaoPedidoOuOrcamento
    ? 'Cancelado'
    : 'Cancelada';

  const textoSituacaoEfetuada = operacaoPedidoOuOrcamento
    ? 'Efetuado'
    : 'Efetuada';

  const nomeOperacao =
    operacao && operacao?.identificacaoTipoOperacao
      ? getName(
          IdentificacaoTipoOperacaoEnum,
          operacao.identificacaoTipoOperacao
        )
      : '';

  if (!operacao) {
    return null;
  }

  return (
    <Flex
      borderTopRadius="md"
      flexDirection="column"
      bg="gray.100"
      px={['8px', '12px', '24px']}
      pt={['16px', '18px', '32px', '32px']}
      alignItems="flex-start"
    >
      <Flex gap="8px" alignItems="center" mb="8px">
        {!IsCadastroExterno && (
          <Icon
            color={operacaoCancelada ? 'red.500' : 'gray.700'}
            boxSize="24px"
            onClick={() => {
              history.push(operacao && ConstanteRotas.OPERACOES);
            }}
            cursor="pointer"
            mb="2px"
            as={FiChevronLeft}
          />
        )}
        <Heading
          as="h2"
          fontSize="lg"
          color={operacaoCancelada ? 'red.500' : 'gray.700'}
          fontWeight="semibold"
        >
          {`${nomeOperacao} nº ${operacao?.numeroOperacao}`}
        </Heading>
      </Flex>
      <VStack
        gap="16px"
        alignItems="flex-start"
        w="full"
        bg="white"
        padding="24px"
        pl={['12px', '12px', '24px', '24px']}
        borderRadius="5px"
        position="relative"
      >
        <Flex
          bg={operacaoCancelada ? 'red.500' : 'aquamarine.600'}
          fontSize="sm"
          borderRadius="md"
          minWidth={['full', '320px', '400px', '480px']}
          minHeight={['64px', '40px']}
          color="white"
          fontWeight="normal"
          justifyContent="center"
          alignItems="center"
          position={['relative', 'absolute']}
          right={['0', '24px']}
          top={['0', '-20px']}
          flexWrap="wrap"
          padding="8px"
          gap="4px"
        >
          Situação:
          <Text
            fontWeight="semibold"
            fontSize="md"
            textTransform="capitalize"
            color={operacaoCancelada ? 'white' : 'secondary.300'}
          >
            {operacaoCancelada ? textoSituacaoCancelada : textoSituacaoEfetuada}
          </Text>
          {operacao?.modeloNumeroFiscais?.map((modeloNumeroFiscal) => {
            const { modeloFiscal, numeroFiscal } = modeloNumeroFiscal;
            const modeloNotaFiscal = getName(ModelosFiscaisEnum, modeloFiscal);
            return (
              <Text
                fontWeight="semibold"
                fontSize="md"
                color={operacaoCancelada ? 'white' : 'secondary.300'}
              >
                {modeloNotaFiscal} | {numeroFiscal}
              </Text>
            );
          })}
        </Flex>
        <SimpleGridForm mt="24px" w="full" gridGap="12px 12px">
          <DetailsRow
            width={['50%', '34%', '26%']}
            title="Data"
            value={formatDate(operacao.dataEmissao)}
            colSpan={[6, 6, 3]}
          />
          <GridItem colSpan={3}>
            <Flex direction="column">
              <Text
                fontSize="sm"
                color="gray.500"
                lineHeight="shorter"
                fontWeight="normal"
                my="3px"
              >
                Valor
              </Text>
              <TextValor
                valor={operacao?.valorTotal}
                casasDecimais={2}
                fontWeight="semibold"
                symbolFontSize="xs"
                fontSize="md"
                color="gray.700"
              />
            </Flex>
          </GridItem>
          <DetailsRow
            title="Motivo do cancelamento"
            value={operacao?.motivoCancelamento}
            colSpan={[12, 12, 6]}
            noOfLines={isLargerThan900 ? 1 : undefined}
          />
          <GridItem colSpan={12}>
            <Divider borderColor="gray.200" borderBottomWidth="1px" />
          </GridItem>
          <DetailsRow
            title="Origem"
            value={operacao?.origem}
            colSpan={[6, 4, 3]}
          />
          <DetailsRow
            title="Caixa"
            value={operacao?.caixa}
            colSpan={[6, 4, 3]}
          />
          <DetailsRow
            title="Tabela de preços"
            value={operacao.tabelaPreco}
            colSpan={[12, 4, 3]}
          />
          <DetailsRow
            title="Vendedor"
            value={operacao.vendedorNome}
            colSpan={[12, 4, 3]}
          />
          <GridItem colSpan={12}>
            <Divider borderColor="gray.200" borderBottomWidth="1px" />
          </GridItem>
          <DetailsRow
            title="Cliente"
            value={operacao.cliente}
            colSpan={[12, 9]}
          />
          <DetailsRow title="E-mail" value={operacao.email} colSpan={[12, 3]} />
          <GridItem colSpan={12}>
            <Divider borderColor="gray.200" borderBottomWidth="1px" />
          </GridItem>
          <DetailsRow
            title="Telefone"
            value={operacao?.telefone}
            colSpan={[12, 3]}
          />
          <DetailsRow
            title="Endereço"
            value={operacao?.endereco}
            colSpan={[12, 9]}
          />
          <GridItem colSpan={12}>
            <Divider borderColor="gray.200" borderBottomWidth="1px" />
          </GridItem>
          <DetailsRow
            title="Observações"
            value={operacao?.observacao}
            colSpan={12}
          />
        </SimpleGridForm>
      </VStack>
    </Flex>
  );
};

export default Header;
