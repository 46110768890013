import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { dataURItoBase64 } from 'helpers/validation/imagesAttachment';
import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';

type AutoAtendimentoProps = {
  customTheme: {
    mainColor: string;
    menuBackgroundColor: string;
    menuTextColor: string;
    menuActionColor: string;
    menuSeparatorColor: string;
    cartIconColor: string;
    cartAreaBackgroundColor: string;
    cartConfirmationButtonBackgroundColor: string;
    cartConfirmationButtonTextColor: string;
    cartConfirmationButtonActionColor: string;
    forwardButtonBackgroundColor: string;
    forwardButtonTextColor: string;
    forwardButtonActionColor: string;
    navigationButtonBackgroundColor: string;
    navigationButtonTextColor: string;
    itemSelectionColor: string;
    itemSelectionTextColor: string;
    fontFamily: string;
    homeImgUrl: string | null;
    bannerImgUrl: string | null;
    finalImgUrl: string | null;
  };
  settings: {
    paperSize: string;
    requestCPF: boolean;
    askWhereToConsume: boolean;
    hasOrderCompletionMessage: boolean;
    orderCompletionMessage: string;
  };
};

export const useTemas = ({ formMethods }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [cadastrado, setCadastrado] = useState(false);
  const { setValue } = formMethods;

  const history = useHistory();

  function voltarParaConfiguracoesFrenteCaixa() {
    history.push(ConstanteRotas.FRENTE_CAIXA_CONFIGURACAO);
  }

  const salvarTema = useCallback(
    async (
      data: AutoAtendimentoProps,
      cadastradoDados?: boolean,
      configInicial?: boolean
    ) => {
      setIsLoading(true);

      const rotaApi = cadastradoDados
        ? ConstanteEnderecoWebservice.AUTO_ATENDIMENTO_ALTERAR
        : ConstanteEnderecoWebservice.AUTO_ATENDIMENTO_CADASTRAR;

      const metodo = cadastradoDados ? 'put' : 'post';
      const { customTheme, settings } = data;

      const dataApi = {
        customTheme: {
          mainColor: customTheme.mainColor,
          menuBackgroundColor: customTheme.menuBackgroundColor,
          menuTextColor: customTheme.menuTextColor,
          menuActionColor: customTheme.menuActionColor,
          menuSeparatorColor: customTheme.menuSeparatorColor,
          cartIconColor: customTheme.cartIconColor,
          cartAreaBackgroundColor: customTheme.cartAreaBackgroundColor,
          cartConfirmationButtonBackgroundColor:
            customTheme.cartConfirmationButtonBackgroundColor,
          cartConfirmationButtonTextColor:
            customTheme.cartConfirmationButtonTextColor,
          cartConfirmationButtonActionColor:
            customTheme.cartConfirmationButtonActionColor,
          forwardButtonBackgroundColor:
            customTheme.forwardButtonBackgroundColor,
          forwardButtonTextColor: customTheme.forwardButtonTextColor,
          forwardButtonActionColor: customTheme.forwardButtonActionColor,
          navigationButtonBackgroundColor:
            customTheme.navigationButtonBackgroundColor,
          navigationButtonTextColor: customTheme.navigationButtonTextColor,
          itemSelectionColor: customTheme.itemSelectionColor,
          itemSelectionTextColor: customTheme.itemSelectionTextColor,
          fontFamily: customTheme.fontFamily,
          homeImgUrl: customTheme.homeImgUrl
            ? 'autoatendimento/homeImgUrl.jpeg'
            : null,
          bannerImgUrl: customTheme.bannerImgUrl
            ? 'autoatendimento/bannerImgUrl.jpeg'
            : null,
          finalImgUrl:
            customTheme.finalImgUrl && !settings.hasOrderCompletionMessage
              ? 'autoatendimento/finalImgUrl.jpeg'
              : null,
        },
        settings: {
          requestCPF: settings.requestCPF,
          askWhereToConsume: settings.askWhereToConsume,
          hasOrderCompletionMessage: settings.hasOrderCompletionMessage,
          orderCompletionMessage: settings.orderCompletionMessage,
          paperSize: settings.paperSize,
        },
      };

      const response = await api[metodo]<void, ResponseApi<string>>(
        rotaApi,
        dataApi
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso) => toast.error(aviso));
        }
        if (response.sucesso) {
          setCadastrado(true);
          if (!configInicial) {
            toast.success('Tema salvo com sucesso!');
          }
        }
      }

      setIsLoading(false);
    },
    []
  );

  const buscarConfigSalva = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<void, ResponseApi<AutoAtendimentoProps>>(
      ConstanteEnderecoWebservice.AUTO_ATENDIMENTO_OBTER_CONFIGURACAO
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.error(aviso));
      }
      if (response.sucesso && response.dados) {
        const { reset } = formMethods;
        const { customTheme, settings } = response.dados;
        const newData = {
          ...customTheme,
          ...settings,
        };
        reset(newData);
      }
    }

    setIsLoading(false);
  }, [formMethods]);

  const buscarTemaSalvo = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<
      void,
      ResponseApi<{
        ativo: boolean;
        configuracoes: string;
      }>
    >(ConstanteEnderecoWebservice.AUTO_ATENDIMENTO_OBTER);

    if (response) {
      if (response.avisos) {
        const dadosIniciais = {
          customTheme: {
            mainColor: '#FFFFFF',
            menuBackgroundColor: '#FFFFFF',
            menuTextColor: '#FFFFFF',
            menuActionColor: '#00000080',
            menuSeparatorColor: '#00000080',
            cartIconColor: '#FFFFFF',
            cartAreaBackgroundColor: '#FFFFFF',
            cartConfirmationButtonBackgroundColor: '#FFFFFF',
            cartConfirmationButtonTextColor: '#FFFFFF',
            cartConfirmationButtonActionColor: '#00000080',
            forwardButtonBackgroundColor: '#FFFFFF',
            forwardButtonTextColor: '#FFFFFF',
            forwardButtonActionColor: '#00000080',
            navigationButtonBackgroundColor: '#FFFFFF',
            navigationButtonTextColor: '#FFFFFF',
            itemSelectionColor: '#FFFFFF',
            itemSelectionTextColor: '#FFFFFF',
            fontFamily: 'KoHo',
            homeImgUrl: null,
            bannerImgUrl: null,
            finalImgUrl: null,
          },
          settings: {
            paperSize: '57',
            requestCPF: true,
            askWhereToConsume: true,
            hasOrderCompletionMessage: true,
            orderCompletionMessage: '',
          },
        };
        await salvarTema(dadosIniciais, false, true);
        setCadastrado(true);
      }
      if (response.sucesso && response.dados) {
        if (response.dados.ativo) {
          await buscarConfigSalva();
        }
        setCadastrado(true);
      }
    }

    setIsLoading(false);
  }, [buscarConfigSalva, salvarTema]);

  const salvarImagem = useCallback(
    async (
      caminhoImagem: 'homeImgUrl' | 'bannerImgUrl' | 'finalImgUrl',
      arquivoImagem: string
    ) => {
      const tipoImagem =
        caminhoImagem === 'homeImgUrl'
          ? 'CaminhoImagemHome'
          : caminhoImagem === 'bannerImgUrl'
          ? 'CaminhoImagemBanner'
          : 'CaminhoImagemFinal';
      setIsLoading(true);

      const response = await api.put<void, ResponseApi<string>>(
        ConstanteEnderecoWebservice.AUTO_ATENDIMENTO_ALTERAR_IMAGEM,
        {
          tipoImagem,
          arquivoImagem: dataURItoBase64(arquivoImagem),
        }
      );
      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso) => toast.error(aviso));
          setValue(caminhoImagem, '');
        }
        if (response.sucesso) {
          setValue(caminhoImagem, arquivoImagem);
          toast.success('Imagem salva com sucesso!');
        }
      }

      setIsLoading(false);
    },
    [setValue]
  );

  return {
    isLoading,
    setIsLoading,
    voltarParaConfiguracoesFrenteCaixa,
    buscarTemaSalvo,
    salvarTema,
    cadastrado,
    salvarImagem,
  };
};

export const useClickOutside = (ref: any, handler: any) => {
  useEffect(() => {
    let startedInside = false;
    let startedWhenMounted = false;

    const listener = (event: any) => {
      if (startedInside || !startedWhenMounted) return;
      if (!ref.current || ref.current.contains(event.target)) return;

      handler(event);
    };

    const validateEventStart = (event: any) => {
      startedWhenMounted = ref.current;
      startedInside = ref.current && ref.current.contains(event.target);
    };

    document.addEventListener('mousedown', validateEventStart);
    document.addEventListener('touchstart', validateEventStart);
    document.addEventListener('click', listener);

    return () => {
      document.removeEventListener('mousedown', validateEventStart);
      document.removeEventListener('touchstart', validateEventStart);
      document.removeEventListener('click', listener);
    };
  }, [ref, handler]);
};
