import React from 'react';
import { Box, Flex, Button, VStack, Icon, Text } from '@chakra-ui/react';
import { FiCheckCircle } from 'react-icons/fi';

import { useImportarContext } from 'store/ImportarContext';

export function TerceiraEtapa() {
  const { arquivo, handleFinalizar } = useImportarContext();

  return (
    <Box w="full" py="4">
      <VStack spacing="6">
        <Icon as={FiCheckCircle} boxSize="20" color="aquamarine.600" />

        <VStack>
          <Text fontSize="2xl" fontWeight="semibold">
            Em processamento!
          </Text>
          <Text fontSize="lg">{`O arquivo "${arquivo?.name}" está em processamento.`}</Text>
        </VStack>

        <Flex w="full" justifyContent="center">
          <Button
            colorScheme="aquamarine"
            borderRadius="md"
            fontSize="sm"
            minW="175px"
            onClick={handleFinalizar}
          >
            Finalizar
          </Button>
        </Flex>
      </VStack>
    </Box>
  );
}
