import React, { useState } from 'react';
import { Tab } from 'react-bootstrap';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import ValidarPermissao from 'components/Validacao/ValidarPermissao';
import TabsPadraoNav, { TabNavType } from './TabsPadraoNav';
import { Card } from './styles';

interface TabType extends TabNavType {
  content: string | React.ReactNode;
  onClick?: () => void;
}

interface TabsPadraoProps {
  id: string;
  tabs: TabType[];
  defaultActiveKey?: string;
  isLoading?: boolean;
  unmountOnClose?: boolean;
}

const TabsPadrao = ({
  id,
  tabs,
  defaultActiveKey,
  isLoading,
  unmountOnClose,
}: TabsPadraoProps) => {
  const [activeKey, setActiveKey] = useState(
    defaultActiveKey || tabs[0].eventKey
  );

  return (
    <Card>
      {isLoading && <LoadingPadrao />}
      <Tab.Container
        id={id}
        activeKey={activeKey}
        onSelect={(k: any) => {
          if (k) setActiveKey(k);

          const tabProps = tabs.filter((tab) => tab.eventKey === k)[0];

          if (tabProps) {
            const { onClick = undefined } = tabProps;
            if (onClick) onClick();
          }
        }}
      >
        <TabsPadraoNav
          tabs={tabs}
          activeKey={activeKey}
          setActiveKey={setActiveKey}
        />
        <Card.Body>
          <Tab.Content>
            {tabs.map(
              ({ eventKey, content, hidden }) =>
                !hidden &&
                ((unmountOnClose && activeKey === eventKey) ||
                  !unmountOnClose) && (
                  <Tab.Pane key={eventKey} eventKey={eventKey}>
                    {content}
                  </Tab.Pane>
                )
            )}
          </Tab.Content>
        </Card.Body>
      </Tab.Container>
    </Card>
  );
};

export default TabsPadrao;
