import React, { useState, useRef, memo, useEffect } from 'react';
import { useBreakpointValue, useToken } from '@chakra-ui/react';

import ValidarPermissao from 'components/Validacao/ValidarPermissao';
import TabsDropdown from './TabsDropdown';

import { CardHeader, Nav, DropdownContainer, Titulo } from './styles';

export interface TabNavType {
  eventKey: string;
  title: string;
  disabled?: boolean;
  hidden?: boolean;
  tabWidth?: string | number;
  refNavLink?: React.MutableRefObject<HTMLAnchorElement>;
  funcionalidade?: string;
}

interface TabsPadraoNavProps {
  tabs: TabNavType[];
  activeKey: string;
  setActiveKey: React.Dispatch<React.SetStateAction<string>>;
}

const TabsPadraoNav = ({
  tabs,
  activeKey,
  setActiveKey,
}: TabsPadraoNavProps) => {
  const [variant, setVariant] = useState<'tabs' | 'combo'>('tabs');

  const primary900 = useToken('colors', 'primary.900');

  const containerRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const tabsRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const isResponsiveVersion = useBreakpointValue({
    base: true,
    md: false,
    lg: false,
  });

  useEffect(() => {
    if (isResponsiveVersion) {
      setVariant('combo');
    } else {
      setVariant('tabs');
    }
  }, [isResponsiveVersion]);

  return (
    <CardHeader ref={containerRef} variant={variant}>
      {variant === 'tabs' && (
        <Nav ref={tabsRef}>
          {tabs.map(
            ({
              eventKey,
              disabled,
              title,
              hidden,
              tabWidth,
              refNavLink,
              funcionalidade,
            }) =>
              !hidden && (
                <Nav.Item
                  key={eventKey}
                  style={tabWidth ? { width: tabWidth } : undefined}
                >
                  <ValidarPermissao
                    funcionalidade={funcionalidade}
                    tooltipWrap="icon"
                  >
                    {({ permitido, BloqueadoIcone }) => (
                      <Nav.Link
                        eventKey={eventKey}
                        disabled={permitido ? disabled : true}
                        ref={refNavLink}
                      >
                        <Titulo>
                          {BloqueadoIcone && (
                            <BloqueadoIcone
                              bloqueioPermissaoIconeStyle={{
                                fontSize: '1rem',
                                color: primary900,
                              }}
                              bloqueioPlanoIconeStyle={{
                                fontSize: '1rem',
                                color: primary900,
                                cursor: 'pointer',
                              }}
                            />
                          )}
                          {title}
                        </Titulo>
                      </Nav.Link>
                    )}
                  </ValidarPermissao>
                </Nav.Item>
              )
          )}
        </Nav>
      )}

      {variant === 'combo' && (
        <DropdownContainer>
          <TabsDropdown
            id="tabs"
            dropdownItems={tabs
              .filter((tab) => !tab.hidden)
              .map(({ eventKey, disabled, title }) => {
                return {
                  title,
                  disabled,
                  selected: eventKey === activeKey,
                  onClick: () => setActiveKey(eventKey),
                };
              })}
          />
        </DropdownContainer>
      )}
    </CardHeader>
  );
};

export default memo(TabsPadraoNav);
