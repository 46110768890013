import React, { useCallback } from 'react';
import {
  Button,
  DrawerHeader,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  useMediaQuery,
  SimpleGrid,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';

import { updateInformacoesFiscalNaMovimentacaoFinanceira } from 'helpers/api/MovimentacaoFinanceira/updateFinancialInformation';
import getOptionsByEnum from 'helpers/format/getOptionsByEnum';
import EstadosEnum from 'constants/enum/estados';

import DrawerPadraoChakra from 'components/PDV/Drawer/DrawerPadraoChakra';
import InputCpfCnpj from 'components/PDV/InputCpfCnpj';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';

type DrawerInformacoesFiscaisProps = {
  onClose: () => void;
  isOpen: boolean;
  identificadorAgrupamento: string;
};

export const DrawerInformacoesFiscais = ({
  onClose,
  isOpen,
  identificadorAgrupamento,
}: DrawerInformacoesFiscaisProps) => {
  const [isLargerThan1200] = useMediaQuery('(min-width: 1200px)');
  const formMethods = useForm();
  const { watch } = formMethods;

  const [cnpjPagamento, ufPagamento] = watch(['cnpjPagamento', 'ufPagamento']);
  const ufNullEmptyOrUndefined =
    ufPagamento === null || ufPagamento === '' || ufPagamento === undefined;
  const cnpjPagamentoWithoutMask = cnpjPagamento?.replace(/\D/g, '');
  const isButtonDisabled =
    cnpjPagamentoWithoutMask?.length < 14 || ufNullEmptyOrUndefined;

  const submitFiscalInformation = useCallback(async () => {
    const response = await updateInformacoesFiscalNaMovimentacaoFinanceira({
      cnpjPagamento,
      ufPagamento,
      identificadorAgrupamento,
    });
    if (response) {
      onClose();
    }
  }, [cnpjPagamento, identificadorAgrupamento, onClose, ufPagamento]);

  return (
    <DrawerPadraoChakra
      isOpen={isOpen}
      placement={isLargerThan1200 ? 'left' : 'bottom'}
      onClose={onClose}
      isOverlay
      trapFocus={false}
      blockScrollOnMount={false}
    >
      <DrawerContent
        bg="gray.50"
        boxShadow="-6px 0px 20px #00000029"
        maxW="500px"
        px={['4px', '4px', '4px', '8px']}
      >
        <DrawerHeader
          fontSize="md"
          color="violet.500"
          px="8px"
          letterSpacing="0px"
        >
          Editar informações de pagamento
        </DrawerHeader>
        <FormProvider {...formMethods}>
          <DrawerBody px="8px" gap="24px">
            <SimpleGrid columns={12} gap={6}>
              <InputCpfCnpj
                id="cnpjPagamento"
                name="cnpjPagamento"
                label="CNPJ do estabelecimento"
                w="full"
                asCnpj
                colSpan={12}
                helperText="Informe o CNPJ do estabelecimento onde o pagamento será processado, transacionado ou recebido quando a emissão do documento fiscal ocorrer em um estabelecimento distinto."
                placeholder="000.000.000-00"
              />
              <SelectPadrao
                placeholder="Selecione o UF"
                id="ufPagamento"
                name="ufPagamento"
                isClearable
                colSpan={12}
                options={getOptionsByEnum(EstadosEnum, 'sigla', 'sigla')}
                label="UF do CNPJ do estabelecimento"
                helperText="Informe a Unidade Federativa (UF) do CNPJ do estabelecimento onde o pagamento será processado, transacionado ou recebido."
                size="lg"
              />
            </SimpleGrid>
          </DrawerBody>
        </FormProvider>
        <DrawerFooter alignItems="center" justifyContent="center">
          <Button
            mr="24px"
            onClick={onClose}
            variant="cancelOutline"
            minW="100px"
          >
            Cancelar
          </Button>
          <Button
            minW="150px"
            colorScheme="secondary"
            isDisabled={isButtonDisabled}
            onClick={submitFiscalInformation}
          >
            Salvar
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </DrawerPadraoChakra>
  );
};
