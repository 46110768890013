import React, { useMemo, useState, useCallback, useRef } from 'react';
import { Icon } from '@chakra-ui/react';
import { Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SalvarInserirNovoIcon } from 'icons';

import auth from 'modules/auth';
import api, { ResponseApi } from 'services/api';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import { useClienteFornecedorFiltroContext } from 'store/ClienteFornecedor/ClienteFornecedorFiltros';
import ConstanteFuncionalidades from 'constants/permissoes';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import TipoCadastroPessoaEnum from 'constants/enum/tipoCadastroPessoa';
import { ButtonFuncionalidade } from 'components/update/Button/ButtonFuncionalidade';

import { SearchInput } from 'components/update/Input/SearchInput';
import SelectBusca from 'components/Select/SelectBusca';
import ManterFoco from 'components/Geral/ManterFoco';
import ValidarPermissao from 'components/Validacao/ValidarPermissao';
import { HeaderData } from 'components/Grid/Cabecalho';
import ButtonComOpcoes from 'components/Button/ButtonComOpcoes';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { ModalConfirmacaoInativar } from 'components/Modal/ModalConfirmacaoInativar';
import { ModalAnonimo } from 'components/Modal/ModalAnonimo';

import Paginacao, {
  ForwardRefMethod,
  GridPaginadaConsulta,
  GridPaginadaRetorno,
} from 'components/Grid/Paginacao';

import {
  AlignRight,
  ContentContainer,
  StatusIcon,
  LinkVisualizar,
  FiltrosCol,
} from 'styles';

import { toast } from 'react-toastify';

interface ClientesFornecedoresFiltros {
  [x: string]: any;
}

interface ClienteFornecedor {
  id: string;
  nome: string;
  cpfCnpj: string;
  telefone: string;
  cidadeUf: string;
  ativo: boolean;
  padraoSistema: boolean;
}

interface ListarProps {
  rotaCliente?: boolean;
}

const Listar = ({ rotaCliente }: ListarProps) => {
  const history = useHistory();
  const isMountedRef = useIsMountedRef();
  const {
    contextFiltros,
    setContextFiltros,
  } = useClienteFornecedorFiltroContext();
  const { t } = useTranslation();
  const filtrosAtuais = useRef<ClientesFornecedoresFiltros>({
    ativo: true,
  } as ClientesFornecedoresFiltros);

  const formMethods = useForm({
    shouldUnregister: false,
    defaultValues: contextFiltros || filtrosAtuais.current,
  });

  const { control, getValues, setFocus } = formMethods;

  const [isLoading, setIsLoading] = useState(false);
  const [totalRegistros, setTotalRegistros] = useState(0);

  const [clientesFornecedores, setClientesFornecedores] = useState(
    [] as Array<ClienteFornecedor>
  );

  const pageIsLoaded = useRef(false);
  const childRef = useRef<ForwardRefMethod>();

  const validacaoPermissaoAlterar = useMemo(
    () =>
      auth.possuiPermissao(
        rotaCliente
          ? ConstanteFuncionalidades.CLIENTE_ALTERAR.codigo
          : ConstanteFuncionalidades.FORNECEDOR_ALTERAR
      ),
    [rotaCliente]
  );

  const permissaoVisualizar = useMemo(() => {
    return rotaCliente
      ? ConstanteFuncionalidades.CLIENTE_VISUALIZAR
      : ConstanteFuncionalidades.FORNECEDOR_VISUALIZAR;
  }, [rotaCliente]);

  const permissaoAlterar = useMemo(() => {
    return rotaCliente
      ? ConstanteFuncionalidades.CLIENTE_ALTERAR.codigo
      : ConstanteFuncionalidades.FORNECEDOR_ALTERAR;
  }, [rotaCliente]);

  const permissaoExcluir = useMemo(() => {
    return rotaCliente
      ? ConstanteFuncionalidades.CLIENTE_EXCLUIR
      : ConstanteFuncionalidades.FORNECEDOR_EXCLUIR;
  }, [rotaCliente]);

  const rotaAlterar = useMemo(() => {
    return rotaCliente
      ? ConstanteRotas.CLIENTE_ALTERAR
      : ConstanteRotas.FORNECEDOR_ALTERAR;
  }, [rotaCliente]);

  const rotaVisualizar = useMemo(() => {
    return rotaCliente
      ? ConstanteRotas.CLIENTE_VISUALIZAR
      : ConstanteRotas.FORNECEDOR_VISUALIZAR;
  }, [rotaCliente]);

  const rotaCadastrar = useMemo(() => {
    return rotaCliente
      ? ConstanteRotas.CLIENTE_CADASTRAR
      : ConstanteRotas.FORNECEDOR_CADASTRAR;
  }, [rotaCliente]);

  const handleHistoryPush = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history]
  );

  const handleReset = useCallback(
    (orderColumn: string) => {
      const filtros = getValues();

      if (JSON.stringify(filtros) !== JSON.stringify(filtrosAtuais.current)) {
        return childRef.current?.reset(orderColumn);
      }

      return () => {};
    },
    [getValues]
  );

  const handleRefresh = useCallback((newTotal?: number) => {
    return childRef.current?.refresh(newTotal);
  }, []);

  const paginationHandle = useCallback(
    async (gridPaginadaConsulta: GridPaginadaConsulta) => {
      setIsLoading(true);

      const filtros = getValues();

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<ClienteFornecedor>>
      >(
        formatQueryPagegTable(
          rotaCliente
            ? ConstanteEnderecoWebservice.CLIENTE_LISTAR_PAGINADO
            : ConstanteEnderecoWebservice.FORNECEDOR_LISTAR_PAGINADO,
          gridPaginadaConsulta
        ),
        {
          params: {
            cpfCnpjNomeApelidoCodigoExterno: filtros.nomeCpfCnpjCodigoExterno,
            tipoCadastroPessoa: rotaCliente
              ? TipoCadastroPessoaEnum.CLIENTE
              : TipoCadastroPessoaEnum.FORNECEDOR,
            ativo: filtros.ativo,
          },
        }
      );

      if (response?.sucesso && isMountedRef.current) {
        setTotalRegistros(response.dados.total);
        setClientesFornecedores(response.dados.registros);
        filtrosAtuais.current = filtros;
        setContextFiltros(filtros);
      }

      if (isMountedRef.current) {
        setIsLoading(false);

        if (!pageIsLoaded.current) {
          pageIsLoaded.current = true;

          setFocus('nomeCpfCnpjCodigoExterno');
        }
      }
    },
    [getValues, isMountedRef, setFocus, rotaCliente, setContextFiltros]
  );

  const AnonimoHandle = useCallback(
    async (clienteFornecedorId: string) => {
      ModalAnonimo({
        callback: async (ok: boolean) => {
          if (ok) {
            setIsLoading(true);

            const response = await api.put<void, ResponseApi>(
              ConstanteEnderecoWebservice.CLIENTE_ANONIMIZAR,
              null,
              { params: { id: clienteFornecedorId } }
            );

            if (response?.sucesso) {
              toast.success(t('O cliente foi anonimizado com sucesso.'));

              handleRefresh(totalRegistros - 1);
            }
            setIsLoading(false);
          }
        },
      });
    },
    [handleRefresh, t, totalRegistros]
  );

  const excluirHandle = useCallback(
    async (clienteFornecedorId: string, ativo: boolean) => {
      ModalConfirmacaoExcluir({
        callback: async (ok: boolean) => {
          if (ok) {
            setIsLoading(true);

            const response = await api.delete<void, ResponseApi>(
              rotaCliente
                ? ConstanteEnderecoWebservice.CLIENTE_EXCLUIR
                : ConstanteEnderecoWebservice.FORNECEDOR_EXCLUIR,
              {
                params: { id: clienteFornecedorId },
              }
            );

            if (response?.sucesso) {
              toast.success(t('O cadastro foi removido com sucesso.'));

              handleRefresh(totalRegistros - 1);
            }

            if (response) {
              ModalConfirmacaoInativar({
                response,
                rotaWebService: rotaCliente
                  ? ConstanteEnderecoWebservice.CLIENTE_INATIVAR
                  : ConstanteEnderecoWebservice.FORNECEDOR_INATIVAR,
                id: clienteFornecedorId,
                ativo,
                callback: (okInativar: boolean) => {
                  if (okInativar) handleRefresh(totalRegistros - 1);
                },
              });
            }

            setIsLoading(false);
          }
        },
      });
    },
    [handleRefresh, t, totalRegistros, rotaCliente]
  );

  const headerData: HeaderData = useMemo(() => {
    return {
      orderColumn: 'Nome',
      orderDirection: 'asc',
      columns: [
        {
          displayName: <StatusIcon style={{ color: 'var(--black)' }} />,
          name: 'Ativo',
          isOrderable: true,
          width: '37px',
          alignText: 'center',
        },
        {
          displayName: t('Nome'),
          name: 'Nome',
          isOrderable: true,
          width: 'auto',
        },
        {
          displayName: t('CPF/CNPJ'),
          name: 'CpfCnpj',
          isOrderable: false,
          width: '150px',
        },
        {
          displayName: t('Telefone'),
          name: 'Telefone',
          isOrderable: false,
          width: '150px',
        },
        {
          displayName: t('Localidade'),
          name: 'Localidade',
          isOrderable: false,
          width: '150px',
        },
        {
          displayName: t('Ações'),
          name: 'Acoes',
          isOrderable: false,
          width: '38px',
        },
      ],
    };
  }, [t]);

  const ClienteFornecedorList = useMemo(() => {
    return (
      <Paginacao
        ref={childRef}
        total={totalRegistros}
        paginationHandle={paginationHandle}
        isLoading={isLoading}
        headerData={headerData}
      >
        {clientesFornecedores.map((clienteFornecedor) => (
          <tr key={clienteFornecedor.id}>
            <td style={{ textAlign: 'center' }}>
              <StatusIcon
                color={
                  clienteFornecedor.ativo
                    ? 'var(--sti-ck-colors-green-500)'
                    : 'var(--sti-ck-colors-red-500)'
                }
              />
            </td>
            <td>
              <ValidarPermissao
                funcionalidade={permissaoVisualizar}
                tooltipWrap="hidden"
              >
                {({ permitido }) => (
                  <LinkVisualizar
                    id="link-visualizar"
                    bloqueado={permitido ? 0 : 1}
                    to={
                      permitido
                        ? SubstituirParametroRota(
                            validacaoPermissaoAlterar.permitido
                              ? rotaAlterar
                              : rotaVisualizar,
                            'id',
                            clienteFornecedor.id
                          )
                        : ''
                    }
                  >
                    {clienteFornecedor.nome}
                  </LinkVisualizar>
                )}
              </ValidarPermissao>
            </td>
            <td>{clienteFornecedor.cpfCnpj}</td>
            <td>{clienteFornecedor.telefone}</td>
            <td>{clienteFornecedor.cidadeUf}</td>
            <td>
              {/* eslint-disable-next-line no-nested-ternary */}
              {rotaCliente ? (
                clienteFornecedor.padraoSistema ? (
                  <ButtonComOpcoes
                    id="mostrarMais"
                    dropdownItems={[
                      {
                        title: t('Editar'),
                        onClick: () => {
                          handleHistoryPush(
                            SubstituirParametroRota(
                              rotaAlterar,
                              'id',
                              clienteFornecedor.id
                            )
                          );
                        },
                        funcionalidadePermissao: permissaoAlterar,
                      },
                    ]}
                  />
                ) : (
                  <ButtonComOpcoes
                    id="mostrarMais"
                    dropdownItems={[
                      {
                        title: t('Editar'),
                        onClick: () => {
                          handleHistoryPush(
                            SubstituirParametroRota(
                              rotaAlterar,
                              'id',
                              clienteFornecedor.id
                            )
                          );
                        },
                        funcionalidadePermissao: permissaoAlterar,
                      },
                      {
                        title: t('Anonimizar(LGPD)'),
                        onClick: () => {
                          AnonimoHandle(clienteFornecedor.id);
                        },
                        funcionalidadePermissao: permissaoAlterar,
                      },
                      {
                        title: t('Remover'),
                        onClick: () => {
                          excluirHandle(
                            clienteFornecedor.id,
                            clienteFornecedor.ativo
                          );
                        },
                        funcionalidadePermissao: permissaoExcluir,
                      },
                    ]}
                  />
                )
              ) : (
                <ButtonComOpcoes
                  id="mostrarMais"
                  dropdownItems={[
                    {
                      title: t('Editar'),
                      onClick: () => {
                        handleHistoryPush(
                          SubstituirParametroRota(
                            rotaAlterar,
                            'id',
                            clienteFornecedor.id
                          )
                        );
                      },
                      funcionalidadePermissao: permissaoExcluir,
                    },

                    {
                      title: t('Remover'),
                      onClick: () => {
                        excluirHandle(
                          clienteFornecedor.id,
                          clienteFornecedor.ativo
                        );
                      },
                      funcionalidadePermissao: permissaoExcluir,
                    },
                  ]}
                />
              )}
            </td>
          </tr>
        ))}
      </Paginacao>
    );
  }, [
    excluirHandle,
    handleHistoryPush,
    headerData,
    isLoading,
    paginationHandle,
    validacaoPermissaoAlterar,
    t,
    totalRegistros,
    clientesFornecedores,
    permissaoVisualizar,
    permissaoAlterar,
    permissaoExcluir,
    rotaAlterar,
    rotaVisualizar,
    AnonimoHandle,
    rotaCliente,
  ]);

  return (
    <FormProvider {...formMethods}>
      <ContentContainer>
        <ManterFoco>
          <Row>
            <FiltrosCol xs={12} sm={5} md={6}>
              <SearchInput
                type="search"
                placeholder={t(
                  rotaCliente
                    ? 'Buscar cliente por nome, apelido, CPF, CNPJ ou código externo'
                    : 'Buscar fornecedor por nome, apelido, CPF, CNPJ ou código externo'
                )}
                onEnterKeyPress={() => handleReset('Nome')}
                isDisabled={isLoading}
                name="nomeCpfCnpjCodigoExterno"
                id="nomeCpfCnpjCodigoExterno"
              />
            </FiltrosCol>
            <FiltrosCol xs={0}>
              <SelectBusca
                id="ativo"
                name="ativo"
                defaultValue
                isDisabled={isLoading}
                control={control}
                options={[
                  {
                    value: true,
                    label: t('Ativos'),
                  },
                  {
                    value: false,
                    label: t('Inativos'),
                  },
                  {
                    value: null,
                    label: t('Todos'),
                  },
                ]}
                onSelect={() => {
                  handleReset('Nome');
                }}
              />
            </FiltrosCol>
            <AlignRight as={FiltrosCol} xs={12} sm={4} md="auto">
              <ButtonFuncionalidade
                id="cadastrarNovo"
                name="cadastrarNovo"
                colorScheme="secondary"
                color="gray.700"
                borderRadius="md"
                minW={{ base: 'full', md: '250px' }}
                leftIcon={<Icon as={SalvarInserirNovoIcon} />}
                type="button"
                onClick={() => handleHistoryPush(rotaCadastrar)}
                funcionalidade={
                  rotaCliente
                    ? ConstanteFuncionalidades.CLIENTE_CADASTRAR.codigo
                    : ConstanteFuncionalidades.FORNECEDOR_CADASTRAR
                }
              >
                {t('Cadastrar Novo')}
              </ButtonFuncionalidade>
            </AlignRight>
          </Row>
        </ManterFoco>
        {ClienteFornecedorList}
      </ContentContainer>
    </FormProvider>
  );
};

export default Listar;
