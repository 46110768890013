import { FiAlertCircle } from 'react-icons/fi';
import {
  Box,
  Flex,
  GridItem,
  Icon,
  Tag,
  Text,
  Tooltip as TooltipChakra,
  useDisclosure,
} from '@chakra-ui/react';
import React, {
  useCallback,
  FocusEvent,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';

import auth from 'modules/auth';
import { useProdutosFormularioContext } from 'store/Produtos/ProdutosFormulario';
import TipoProdutoEnum from 'constants/enum/tipoProduto';
import ConstanteFuncionalidades from 'constants/permissoes';
import PlanoContratacaoEnum from 'constants/enum/planoContratacao';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { useValidationFormDirtyFields } from 'hooks/useValidationFormDirtyFields';
import TipoCadastroCampoPersonalizadoEnum from 'constants/enum/tipoCadastroCampoPersonalizado';

import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { Switch } from 'components/update/Switch';
import { CamposPersonalizados } from 'components/update/CamposPersonalizados';
import { SemPermissaoIcon, InfoIcon, InfoIconCircular } from 'icons';
import Input from 'components/PDV/InputPdv';
import { NumberInput } from 'components/update/Input/NumberInput';
import CampoPersonalizadoValorInterface from 'types/campoPersonalizadoValor';
import CampoPersonalizadoInterface from 'types/campoPersonalizado';

import { Container } from '../Preco/Container';
import { ModalProdutoCodigoDuplicado } from '../components/ModalProdutoCodigoDuplicado';
import useProdutoFormulario, { ProdutoCorTamanhoProps } from '../../hook';

type CaracteristicasProps = {
  altura: number;
  largura: number;
  profundidade: number;
  pesoLiquido: number;
  pesoBruto: number;
  pesoEmbalagem: number;
};

type CampoPersonalizadoProps = {
  campoPersonalizadoId: string;
  valor: number;
  produtoId: string;
};

type GeralProps = {
  informacoesAdicionais: {
    controlaEstoque: boolean;
    permiteAlteraValorNaVenda: boolean;
    solicitarInformacaoComplementarNoPdv: boolean;
    utilizarBalanca: boolean;
    exportarBalanca: boolean;
  };
  codigoExterno: string;
  caracteristicas: CaracteristicasProps;
  codigoBarrasInterno: string;
  camposPersonalizados: CampoPersonalizadoProps[];
};

let inputExternalCodeTimeout: NodeJS.Timeout;

export const Geral = () => {
  const [camposPersonalizados, setCamposPersonalizados] = useState<
    CampoPersonalizadoInterface[]
  >([]);
  const [
    productsWithSameExternalCode,
    setProductsWithSameExternalCode,
  ] = useState<ProdutoCorTamanhoProps[]>([]);

  const hasProductsWithSameExternalCode =
    productsWithSameExternalCode.length > 0;

  const {
    readonly,
    tipoProduto,
    idProduto,
    ref,
    isLoading,
    setIsLoading,
    onChangeFormIsDirty,
  } = useProdutosFormularioContext();

  const {
    updateProductExternalCode,
    getProductsWithSameExternalCode,
  } = useProdutoFormulario();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const formMethods = useForm();

  const planoAtual = auth.getPlano();

  const permiteControlarEstoque = planoAtual !== PlanoContratacaoEnum.START;

  const {
    setValue,
    reset,
    getValues,
    watch,
    formState: { dirtyFields },
  } = formMethods;

  const { codigoExterno } = watch();

  const isDirty = useValidationFormDirtyFields(dirtyFields);

  const possuiPermissaoAlterarPrecoVenda = auth.possuiPermissao(
    ConstanteFuncionalidades.PRODUTO_ALTERAR_PRECO_NA_VENDA
  ).permitido;

  const produtoTipoVariacao = tipoProduto === TipoProdutoEnum.PRODUTO_VARIACAO;
  const produtoTipoKit = tipoProduto === TipoProdutoEnum.PRODUTO_KIT;

  const camposPersonalizadosProdutos = useCallback(
    async (campoPersonalizadoCadastrado: CampoPersonalizadoProps[]) => {
      const response = await api.get<
        void,
        ResponseApi<CampoPersonalizadoInterface[]>
      >(ConstanteEnderecoWebservice.CAMPO_PERSONALIZADO_LISTARPORTIPOCADASTRO, {
        params: { tipoCadastro: TipoCadastroCampoPersonalizadoEnum.PRODUTO },
      });

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }

        if (response.sucesso) {
          const data = response.dados.map((campoItem, index) => {
            const campoCadastrado = (campoPersonalizadoCadastrado || []).find(
              (campoJaCadastrado) =>
                campoJaCadastrado.campoPersonalizadoId === campoItem.id
            );

            setValue(`camposPersonalizados.${index}`, {
              campoPersonalizadoId: campoItem.id,
              valor: campoCadastrado?.valor || '',
            });

            return {
              ...campoItem,
              valor: campoCadastrado?.valor || '',
            };
          });

          setCamposPersonalizados(data);
          setIsLoading(false);
        }
        setIsLoading(false);
      }
      setIsLoading(false);
    },
    [setIsLoading, setValue]
  );

  const getInfoAdcional = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get<void, ResponseApi<GeralProps>>(
      `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/informacoes-adicionais`
    );
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso && response.dados) {
        const { dados } = response;
        reset({
          outrasInformacoesLargura: dados?.caracteristicas?.largura || 0,
          outrasInformacoesAltura: dados?.caracteristicas?.altura || 0,
          outrasInformacoesProfundidade:
            dados?.caracteristicas?.profundidade || 0,
          outrasInformacoesPesoLiquido:
            dados?.caracteristicas?.pesoLiquido || 0,
          outrasInformacoesPesoBruto: dados?.caracteristicas?.pesoBruto || 0,
          outrasInformacoesPesoEmbalagem:
            dados?.caracteristicas?.pesoEmbalagem || 0,
          controlaEstoque: dados?.informacoesAdicionais.controlaEstoque,
          utilizarBalanca: dados?.informacoesAdicionais.utilizarBalanca,
          exportarBalanca: dados?.informacoesAdicionais.exportarBalanca,
          permiteAlteraValorNaVenda: produtoTipoKit
            ? false
            : dados?.informacoesAdicionais.permiteAlteraValorNaVenda || false,
          solicitarInformacaoComplementarNoPdv:
            dados?.informacoesAdicionais.solicitarInformacaoComplementarNoPdv ||
            false,
          codigoExterno: dados?.codigoExterno || null,
          codigoBarrasInterno: dados?.codigoBarrasInterno || null,
        });

        await camposPersonalizadosProdutos(dados.camposPersonalizados);
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [
    setIsLoading,
    idProduto,
    reset,
    produtoTipoKit,
    camposPersonalizadosProdutos,
  ]);

  const salvarProdutos = useCallback(async () => {
    if (isDirty === false) {
      return true;
    }
    setIsLoading(true);
    const data = getValues();
    const response = await api.patch<void, ResponseApi<boolean>>(
      `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/informacoes-adicionais`,
      {
        produtoId: idProduto,
        codigoExterno: data.codigoExterno,
        informacoesAdicionais: {
          produtoId: idProduto,
          controlaEstoque: data.controlaEstoque,
          permiteAlteraValorNaVenda: data.permiteAlteraValorNaVenda,
          solicitarInformacaoComplementarNoPdv:
            data.solicitarInformacaoComplementarNoPdv,
          utilizarBalanca: data.utilizarBalanca,
          exportarBalanca: data.exportarBalanca,
        },
        caracteristicas: {
          altura: data.outrasInformacoesAltura,
          largura: data.outrasInformacoesLargura,
          profundidade: data.outrasInformacoesProfundidade,
          pesoLiquido: data.outrasInformacoesPesoLiquido,
          pesoBruto: data.outrasInformacoesPesoBruto,
          pesoEmbalagem: data.outrasInformacoesPesoEmbalagem,
        },
        camposPersonalizados: (data.camposPersonalizados as CampoPersonalizadoValorInterface[])
          ?.filter(Boolean)
          ?.filter(
            ({
              campoPersonalizadoId,
              valor,
            }: CampoPersonalizadoValorInterface) =>
              campoPersonalizadoId &&
              valor !== undefined &&
              valor !== 'undefined' &&
              valor !== null &&
              valor !== 'null' &&
              String(valor)
          )
          ?.map(
            ({
              campoPersonalizadoId,
              valor,
            }: CampoPersonalizadoValorInterface) => ({
              campoPersonalizadoId,
              produtoId: idProduto,
              valor: String(valor),
            })
          ),
      }
    );
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
        setIsLoading(false);
        return false;
      }

      if (response.sucesso) {
        setIsLoading(false);
        return true;
      }
    }
    setIsLoading(false);
    return false;
  }, [getValues, idProduto, isDirty, setIsLoading]);

  const handleValidateProductsWithExternalCode = useCallback(
    async (event: FocusEvent<HTMLInputElement>) => {
      clearTimeout(inputExternalCodeTimeout);

      inputExternalCodeTimeout = global.setTimeout(async () => {
        const { value } = event.target;

        if (value) {
          const products = await getProductsWithSameExternalCode(value);
          const filteredProducts = products.filter(
            (product) => product.produtoId !== idProduto
          );

          setProductsWithSameExternalCode(filteredProducts);
          return;
        }

        setProductsWithSameExternalCode([]);
      }, 500);
    },
    [getProductsWithSameExternalCode, idProduto]
  );

  useImperativeHandle(ref, () => ({
    onClick: () => salvarProdutos(),
  }));

  useEffect(() => {
    getInfoAdcional();
  }, [getInfoAdcional]);

  useEffect(() => {
    onChangeFormIsDirty(isDirty);
  }, [isDirty, onChangeFormIsDirty]);

  return (
    <FormProvider {...formMethods}>
      <SimpleGridForm>
        {!produtoTipoVariacao && (
          <>
            <GridItem colSpan={[12, 12, 12, 3]}>
              <Container
                bg="gray.50"
                title="Informações para nota fiscal:"
                pb={9}
              >
                <Box mb="30px" w="full">
                  <NumberInput
                    w="full"
                    id="outrasInformacoesPesoLiquido"
                    name="outrasInformacoesPesoLiquido"
                    label="Peso líquido"
                    leftElement="KG"
                    precision={12}
                    scale={3}
                    leftElementFontSize="12px"
                    editarFundoLeftElemento
                    bgLeftElement="gray.50"
                    isDisabled={readonly || produtoTipoVariacao}
                  />
                </Box>
                <Box w="full">
                  <NumberInput
                    id="outrasInformacoesPesoBruto"
                    name="outrasInformacoesPesoBruto"
                    label="Peso bruto"
                    leftElementFontSize="12px"
                    editarFundoLeftElemento
                    bgLeftElement="gray.50"
                    leftElement="KG"
                    precision={12}
                    scale={3}
                    isDisabled={readonly || produtoTipoVariacao}
                  />
                </Box>
              </Container>
            </GridItem>
            <GridItem colSpan={[12, 12, 12, 5]}>
              <Container
                pb={9}
                bg="gray.50"
                title="Características da embalagem para cálculo de frete:"
              >
                <SimpleGridForm>
                  <GridItem colSpan={{ base: 12, md: 12, lg: 6 }}>
                    <NumberInput
                      id="outrasInformacoesLargura"
                      name="outrasInformacoesLargura"
                      label="Largura"
                      leftElementFontSize="12px"
                      editarFundoLeftElemento
                      bgLeftElement="gray.50"
                      leftElement="CM"
                      precision={7}
                      scale={2}
                      isDisabled={readonly || produtoTipoVariacao}
                    />
                  </GridItem>
                  <GridItem colSpan={{ base: 12, md: 12, lg: 6 }}>
                    <NumberInput
                      id="outrasInformacoesAltura"
                      name="outrasInformacoesAltura"
                      label="Altura"
                      precision={7}
                      scale={2}
                      defaultValue={0}
                      leftElementFontSize="12px"
                      editarFundoLeftElemento
                      bgLeftElement="gray.50"
                      leftElement="CM"
                      isDisabled={readonly || produtoTipoVariacao}
                    />
                  </GridItem>
                  <GridItem colSpan={{ base: 12, md: 12, lg: 6 }}>
                    <NumberInput
                      id="outrasInformacoesProfundidade"
                      name="outrasInformacoesProfundidade"
                      label="Profundidade"
                      precision={7}
                      scale={2}
                      leftElement="CM"
                      leftElementFontSize="12px"
                      editarFundoLeftElemento
                      bgLeftElement="gray.50"
                      isDisabled={readonly || produtoTipoVariacao}
                    />
                  </GridItem>
                  <GridItem colSpan={{ base: 12, md: 12, lg: 6 }}>
                    <NumberInput
                      id="outrasInformacoesPesoEmbalagem"
                      name="outrasInformacoesPesoEmbalagem"
                      label="Peso da embalagem"
                      precision={7}
                      scale={2}
                      leftElement="KG"
                      leftElementFontSize="12px"
                      editarFundoLeftElemento
                      bgLeftElement="gray.50"
                      isDisabled={readonly || produtoTipoVariacao}
                    />
                  </GridItem>
                </SimpleGridForm>
              </Container>
            </GridItem>
            <GridItem
              h="full"
              colSpan={[12, 12, 12, produtoTipoVariacao ? 12 : 4]}
            >
              <Container
                pb={9}
                bg="gray.50"
                title="Código da etiqueta"
                overflowX="scroll"
              >
                <GridItem mb="27px">
                  <Input
                    id="codigoBarrasInterno"
                    name="codigoBarrasInterno"
                    label="Código da etiqueta"
                    placeholder="Sem etiqueta"
                    maxLength={13}
                    isDisabled
                  />
                </GridItem>
                <GridItem>
                  <Input
                    id="codigoExterno"
                    name="codigoExterno"
                    label="Código externo"
                    placeholder="Digite o código externo"
                    borderColor={
                      hasProductsWithSameExternalCode ? 'yellow.600' : 'inherit'
                    }
                    onChange={(event: FocusEvent<HTMLInputElement>) =>
                      handleValidateProductsWithExternalCode(event)
                    }
                    maxLength={60}
                    isDisabled={readonly || produtoTipoVariacao}
                  />
                  {hasProductsWithSameExternalCode && (
                    <Tag
                      position="absolute"
                      h="32px"
                      px="8px"
                      bg="yellow.500"
                      borderRadius="4px"
                    >
                      <Flex gap={1}>
                        <Icon as={FiAlertCircle} />
                        <Text fontSize="14px" whiteSpace="nowrap">
                          Código já cadastrado.{' '}
                          <Box as="u" cursor="pointer" onClick={() => onOpen()}>
                            Ver produtos.
                          </Box>
                        </Text>
                      </Flex>
                    </Tag>
                  )}
                </GridItem>
              </Container>
            </GridItem>
          </>
        )}

        <GridItem colSpan={12}>
          <Container bg="gray.50" title="Configurações opcionais">
            <Box w={['600px', 'full']}>
              <SimpleGridForm
                fontSize="12px"
                color="black"
                borderTopRadius="4px"
                bg="gray.50"
              >
                <GridItem colSpan={[10, 8]}>
                  <Text>Descrição</Text>
                </GridItem>
                <GridItem colSpan={[2, 4]}>
                  <Text>Status</Text>
                </GridItem>
              </SimpleGridForm>
              <SimpleGridForm gridRowGap="1" gridGap="0" columnGap="6">
                <GridItem
                  bg="white"
                  h="56px"
                  py="19px"
                  boxShadow="0px 0px 4px #00000029"
                  px="24px"
                  borderRadius="5px"
                  colSpan={12}
                >
                  <SimpleGridForm>
                    <GridItem colSpan={[10, 8]}>
                      <Flex justifyContent="flex-start" alignItems="baseline">
                        <Text mr="4px">
                          Permitir movimentar o produto somente com saldo em
                          estoque
                        </Text>
                        {!permiteControlarEstoque && (
                          <Box position="relative">
                            <TooltipChakra
                              hasArrow
                              fontSize="12px"
                              placement="right"
                              p="10px"
                              bgColor="black"
                              label="O controle de estoque não está disponível no seu plano."
                            >
                              <Flex mt="-10px" position="absolute">
                                <InfoIcon />
                              </Flex>
                            </TooltipChakra>
                          </Box>
                        )}
                      </Flex>
                    </GridItem>

                    <GridItem colSpan={[2, 4]}>
                      <Switch
                        id="controlaEstoque"
                        name="controlaEstoque"
                        size="md"
                        isDisabled={readonly || !permiteControlarEstoque}
                      />
                    </GridItem>
                  </SimpleGridForm>
                </GridItem>

                <GridItem
                  bg={produtoTipoKit ? 'gray.50' : 'white'}
                  h="56px"
                  py="19px"
                  boxShadow="0px 0px 4px #00000029"
                  px="24px"
                  borderRadius="5px"
                  colSpan={12}
                >
                  <SimpleGridForm>
                    <GridItem colSpan={[10, 8]}>
                      <Flex alignItems="baseline">
                        {!possuiPermissaoAlterarPrecoVenda && (
                          <Icon
                            fontSize="14px"
                            mr="20px"
                            as={SemPermissaoIcon}
                          />
                        )}
                        <Text
                          mr="4px"
                          opacity={possuiPermissaoAlterarPrecoVenda ? 1 : 0.5}
                        >
                          Permitir alterar o valor do produto durante a venda
                        </Text>

                        {possuiPermissaoAlterarPrecoVenda && !produtoTipoKit && (
                          <Box position="relative">
                            <TooltipChakra
                              hasArrow
                              placement="right"
                              p="10px"
                              fontSize="12px"
                              bgColor="black"
                              label="Ative esta opção APENAS caso utilize diferentes valores para o mesmo produto. No momento da venda SERÁ PERMITIDO informar qualquer outro valor, independente do cadastro."
                            >
                              <Flex mt="-10px" position="absolute">
                                <InfoIcon />
                              </Flex>
                            </TooltipChakra>
                          </Box>
                        )}
                      </Flex>
                    </GridItem>

                    <GridItem colSpan={[2, 4]}>
                      {produtoTipoKit ? (
                        <Box position="relative">
                          <TooltipChakra
                            hasArrow
                            placement="left"
                            p="16px"
                            fontSize="12px"
                            bgColor="black"
                            borderRadius="5px"
                            label='Não é possível alterar o preço durante a venda para um produto do tipo "KIT". Você pode oferecer um desconto ou alterar os valores de cada item individualmente.'
                          >
                            <Flex ml="6px" position="absolute">
                              <InfoIconCircular
                                color="red"
                                width="22px"
                                height="22px"
                              />
                            </Flex>
                          </TooltipChakra>
                        </Box>
                      ) : (
                        <Switch
                          id="permiteAlteraValorNaVenda"
                          name="permiteAlteraValorNaVenda"
                          size="md"
                          isDisabled={
                            readonly ||
                            !possuiPermissaoAlterarPrecoVenda ||
                            produtoTipoKit
                          }
                        />
                      )}
                    </GridItem>
                  </SimpleGridForm>
                </GridItem>

                <GridItem
                  bg="white"
                  h="56px"
                  py="19px"
                  boxShadow="0px 0px 4px #00000029"
                  px="24px"
                  borderRadius="5px"
                  colSpan={12}
                >
                  <SimpleGridForm>
                    <GridItem colSpan={[10, 8]}>
                      <Flex alignItems="baseline">
                        <Text mr="4px">
                          Solicitar informações complementares no PDV
                        </Text>

                        <Box position="relative">
                          <TooltipChakra
                            hasArrow
                            placement="right"
                            p="10px"
                            fontSize="12px"
                            bgColor="black"
                            label="Esta informação sairá nos dados adicionais do produto na nota fiscal."
                          >
                            <Flex mt="-10px" position="absolute">
                              <InfoIcon />
                            </Flex>
                          </TooltipChakra>
                        </Box>
                      </Flex>
                    </GridItem>

                    <GridItem colSpan={[2, 4]}>
                      <Switch
                        id="solicitarInformacaoComplementarNoPdv"
                        name="solicitarInformacaoComplementarNoPdv"
                        size="md"
                        isDisabled={readonly}
                      />
                    </GridItem>
                  </SimpleGridForm>
                </GridItem>

                <GridItem
                  bg="white"
                  h="56px"
                  py="19px"
                  boxShadow="0px 0px 4px #00000029"
                  px="24px"
                  borderRadius="5px"
                  colSpan={12}
                >
                  <SimpleGridForm>
                    <GridItem colSpan={[10, 8]}>
                      <Flex alignItems="baseline">
                        <Text mr="4px">Recuperar peso da balança</Text>

                        <Box position="relative">
                          <TooltipChakra
                            hasArrow
                            placement="right"
                            p="10px"
                            fontSize="12px"
                            bgColor="black"
                            label="Este campo é utilizado exclusivamente para a integração com PDV Offline quando o pdv possui uma balança integrada que faz a leitura do peso do produto."
                          >
                            <Flex mt="-10px" position="absolute">
                              <InfoIcon />
                            </Flex>
                          </TooltipChakra>
                        </Box>
                      </Flex>
                    </GridItem>

                    <GridItem colSpan={[2, 4]}>
                      <Switch
                        id="utilizarBalanca"
                        name="utilizarBalanca"
                        size="md"
                        isDisabled={readonly}
                      />
                    </GridItem>
                  </SimpleGridForm>
                </GridItem>
                <GridItem
                  bg="white"
                  h="56px"
                  py="19px"
                  boxShadow="0px 0px 4px #00000029"
                  px="24px"
                  borderRadius="5px"
                  colSpan={12}
                >
                  <SimpleGridForm>
                    <GridItem colSpan={[10, 8]}>
                      <Flex alignItems="baseline">
                        <Text mr="4px">Exportar código para balança</Text>

                        <Box position="relative">
                          <TooltipChakra
                            hasArrow
                            placement="right"
                            p="10px"
                            fontSize="12px"
                            bgColor="black"
                            label="Este campo é utilizado exclusivamente para a integração com PDV Offline. Indica que o produto será incluído na lista de códigos que é enviada para uma balança etiquetadora a fim de que seja identificado pelo PDV"
                          >
                            <Flex mt="-10px" position="absolute">
                              <InfoIcon />
                            </Flex>
                          </TooltipChakra>
                        </Box>
                      </Flex>
                    </GridItem>

                    <GridItem colSpan={[2, 4]}>
                      <Switch
                        id="exportarBalanca"
                        name="exportarBalanca"
                        size="md"
                        isDisabled={readonly}
                      />
                    </GridItem>
                  </SimpleGridForm>
                </GridItem>

                <GridItem colSpan={12} />
              </SimpleGridForm>
            </Box>
          </Container>
        </GridItem>
        <GridItem colSpan={12}>
          <Container bg="gray.50" title="Campos personalizados">
            <CamposPersonalizados
              readonly={readonly}
              camposPersonalizados={camposPersonalizados}
            />
          </Container>
        </GridItem>
      </SimpleGridForm>
      <ModalProdutoCodigoDuplicado
        isOpen={isOpen}
        onClose={onClose}
        code={{
          type: 'Código Externo',
          value: codigoExterno || '',
        }}
        isLoading={isLoading}
        products={productsWithSameExternalCode}
        callback={async (products) => {
          setIsLoading(true);

          const listPromises = products.map((product) =>
            updateProductExternalCode(
              product.produtoCorTamanhoId,
              product?.codigo || ''
            )
          );

          const response = await Promise.all(listPromises).then((result) => {
            result.forEach(({ success, data }) => {
              if (success) {
                setProductsWithSameExternalCode((prev) =>
                  prev.filter(
                    (prevProduct) =>
                      prevProduct.produtoCorTamanhoId !==
                      data.produtoCorTamanhoId
                  )
                );

                toast.success('Código externo alterado com sucesso.');
              }
            });

            return { success: result.every(({ success }) => success) };
          });

          setIsLoading(false);

          if (response.success) {
            onClose();
          }
        }}
      />
    </FormProvider>
  );
};
