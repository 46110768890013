const EnumStatus = {
  ATIVOS: true,
  INATIVOS: false,
  TODOS: '',

  status: [
    { label: 'Ativos', value: true },
    { label: 'Inativos', value: false },
    { label: 'Todos', value: '' },
  ],

  options: [
    { label: 'Ativo', value: true },
    { label: 'Inativo', value: false },
  ],
};

export default EnumStatus;
