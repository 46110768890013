import React, { memo } from 'react';
import { Controller } from 'react-hook-form';
import Select, {
  OptionTypeBase,
  Props as SelectProps,
  GroupedOptionsType,
  OptionsType,
  components,
} from 'react-select';

import {
  selectStyles,
  OptionsIcon,
  blueSelectStyles,
  FilterIcon,
} from './styles';

const CustomInvisibleMenu = () => <></>;

interface SelectBuscaProps extends SelectProps<OptionTypeBase> {
  id: string;
  name: string;
  defaultValue?: any;
  control?: any;
  options?: GroupedOptionsType<OptionTypeBase> | OptionsType<OptionTypeBase>;
  onSelect?: () => void;
  filtrosAtivos?: boolean;
  controlWidth?: number | string;
  controlHeight?: number | string;
  singleValueFontSize?: number | string;
}

const SelectBusca = ({
  id,
  name,
  defaultValue,
  control,
  options,
  onSelect,
  isDisabled,
  filtrosAtivos,
  controlWidth,
  controlHeight,
  singleValueFontSize,
  ...rest
}: SelectBuscaProps) => {
  const CustomSingleValue = ({ ...props }: any) => (
    <>
      {filtrosAtivos ? <FilterIcon /> : <OptionsIcon />}
      <components.SingleValue {...props} />
    </>
  );

  if (control && options) {
    return (
      <Controller
        defaultValue={defaultValue}
        render={({ field }) => (
          <Select
            id={id}
            ref={field.ref}
            classNamePrefix="react-select"
            components={{ SingleValue: CustomSingleValue }}
            isSearchable={false}
            isDisabled={isDisabled}
            options={options}
            onChange={(newValue: any) => {
              field.onChange(newValue.value);
              if (onSelect) onSelect();
            }}
            value={options.find((option: any) => option.value === field.value)}
            noOptionsMessage={() => 'Não há valores disponíveis'}
            styles={selectStyles({
              controlWidth,
              controlHeight,
              singleValueFontSize,
            })}
            {...rest}
          />
        )}
        // onFocus={() => {
        //   selectRef.current.focus();
        // }}
        name={`${name}` as const}
        control={control}
      />
    );
  }

  return (
    <button
      type="button"
      id="buscaAvancada-button"
      disabled={isDisabled}
      onClick={() => {
        if (onSelect && !isDisabled) onSelect();
      }}
      style={{
        border: 'none',
        background: 'none',
        height: 'auto',
        padding: 0,
      }}
    >
      <Select
        id={id}
        classNamePrefix="react-select"
        components={{
          SingleValue: CustomSingleValue,
          Menu: CustomInvisibleMenu,
        }}
        isDisabled={isDisabled}
        value={{ label: defaultValue }}
        placeholder={defaultValue}
        isSearchable={false}
        styles={
          filtrosAtivos
            ? {
                ...blueSelectStyles,
                container: (base: any) => ({
                  ...base,
                  maxHeight: '40px',
                  maxWidth: '100%',
                }),
              }
            : {
                ...selectStyles({}),
                container: (base: any) => ({
                  ...base,
                  maxHeight: '40px',
                  maxWidth: '100%',
                }),
              }
        }
        {...rest}
      />
    </button>
  );
};

export default memo(SelectBusca);
