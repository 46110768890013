import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { SalvarInserirNovoIcon, SalvarConfirmarIcon } from 'icons';
import { Col, Form, Modal } from 'react-bootstrap';
import { OptionTypeBase } from 'react-select';
import { useFormContext } from 'react-hook-form';

import { getValorParcelas } from 'helpers/data/getValorParcelas';
import getOptionsByEnum from 'helpers/format/getOptionsByEnum';
import BandeirasCartaoEnum from 'constants/enum/fiscal/bandeiraCartao';
import MeioPagamentoFiscalEnum from 'constants/enum/fiscal/meioPagamentoFiscal';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import EstadosEnum from 'constants/enum/estados';
import api, { ResponseApi } from 'services/api';
import { v4 as uuidv4 } from 'uuid';

import SelectPadrao from 'components/Select/SelectPadrao';
import InputPadrao from 'components/Input/InputPadrao';
import { BootstrapNumberInput } from 'components/update/Input/BootstrapNumberInput';
import { Label } from 'components/Layout/CampoContainer/CampoContainerPadrao/styles';
import ButtonPadrao from 'components/Button/ButtonPadrao';
import ModalPadrao from 'components/Modal/ModalPadrao';
import InputCpfCnpj from 'components/Input/InputCpfCnpj';

import { useForm, yupResolver } from './validationForm';

interface UncontrolledFormInterface {
  show: boolean;
  onHide: () => void;
}

interface FormaPagamentoParcelaInterface {
  descricao: string;
  qtdeParcela: number;
  intervaloDias: number;
}

interface FormaPagamentoInterface {
  id: string;
  nome: string;
  meioDePagamento: number;
  formaPagamentoParcelas: Array<FormaPagamentoParcelaInterface>;
}

const PagamentoModalInserir: React.FC<UncontrolledFormInterface> = ({
  show,
  onHide,
}) => {
  const { t } = useTranslation();

  const {
    setValue: parentSetValue,
    getValues: parentGetValues,
  } = useFormContext();

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    setError,
    setFocus,
  } = useForm({
    resolver: yupResolver,
  });

  const [formasPagamento, setFormasPagamento] = useState<
    FormaPagamentoInterface[]
  >([]);

  const [formasPagamentoParcela, setFormasPagamentoParcela] = useState<
    FormaPagamentoParcelaInterface[]
  >([]);

  const [valorRestanteTotalNota, setValorRestanteTotalNota] = useState(0);

  const formaPagamentoWatch = watch('formaPagto') as FormaPagamentoInterface;
  const valorWatch = watch('valor') as number;

  const handleGetFormasPagamentoValues = useCallback(async () => {
    const response = await api.get<
      void,
      ResponseApi<FormaPagamentoInterface[]>
    >(ConstanteEnderecoWebservice.FORMA_PAGAMENTO_LISTAR_SELECT_NOTA_FISCAL);

    if (response?.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (response?.sucesso) {
      setFormasPagamento(response.dados);
    } else setFormasPagamento([]);
  }, []);

  const handleAddItem = (data: any) => {
    if (valorRestanteTotalNota < data.valor) {
      toast.warning(
        'O valor de pagamento não pode ser maior que o valor total da nota'
      );
      return false;
    }

    const itemsAdicionados = parentGetValues('documentoFiscalPagamentos');

    const { qtdeParcela, intervaloDias } = data.parcela;

    const valorParcelas = getValorParcelas(data.valor, qtdeParcela);

    const parcelasAdicionar = [];
    const dataAtual = new Date();

    const proximaParcela = itemsAdicionados.length + 1;
    const identificadorAgrupamento = uuidv4();

    for (
      let numeroParcela = proximaParcela;
      numeroParcela <= qtdeParcela + itemsAdicionados.length;
      numeroParcela += 1
    ) {
      parcelasAdicionar.push({
        formaPagamentoRecebimentoId: data.formaPagto.id,
        valor: valorParcelas[numeroParcela - itemsAdicionados.length - 1],
        parcela: numeroParcela,
        vencimento: new Date(
          dataAtual.setDate(dataAtual.getDate() + intervaloDias)
        ),
        bandeiraCartao: data.bandeiraCartao,
        numeroAutorizacao: data.numeroAutorizacao,
        meioDePagamento: data.formaPagto.meioDePagamento,
        identificadorAgrupamento,
        cnpjPagamento: data.cnpjPagamento,
        ufPagamento: data.ufPagamento,
      });
    }

    parentSetValue('documentoFiscalPagamentos', [
      ...itemsAdicionados,
      ...parcelasAdicionar,
    ]);

    setValorRestanteTotalNota(valorRestanteTotalNota - valorWatch);

    return true;
  };

  const inicializarValoresPadrao = useCallback(() => {
    let valorTotal = parentGetValues('valorTotal');

    const documentoFiscalPagamentos = parentGetValues(
      'documentoFiscalPagamentos'
    );

    if (documentoFiscalPagamentos.length > 0) {
      let somaTotalPagamento = 0;

      for (
        let index = 0;
        index < documentoFiscalPagamentos.length;
        index += 1
      ) {
        somaTotalPagamento += documentoFiscalPagamentos[index].valor;
      }

      valorTotal = Number.parseFloat(
        (valorTotal - somaTotalPagamento).toFixed(2)
      );
    }

    setValue('valor', valorTotal);
    setValorRestanteTotalNota(valorTotal);
  }, []);

  useEffect(() => {
    handleGetFormasPagamentoValues();
  }, [handleGetFormasPagamentoValues, show]);

  useEffect(() => {
    setFormasPagamentoParcela(formaPagamentoWatch?.formaPagamentoParcelas);
    if (formasPagamentoParcela?.length > 0) {
      setValue('parcela', formasPagamentoParcela[0]);
    }
  }, [formaPagamentoWatch, formasPagamentoParcela, setValue]);

  useEffect(() => {
    if (show) inicializarValoresPadrao();
  }, [inicializarValoresPadrao, show]);

  const onSubmitReset = handleSubmit(async (data) => {
    const success = await handleAddItem(data);
    if (success) {
      inicializarValoresPadrao();

      setValue('formaPagto', null);
      setValue('parcela', null);
      setValue('cnpjPagamento', '');
      setValue('ufPagamento', null);
    }
  });

  const onSubmit = handleSubmit(async (data) => {
    const success = await handleAddItem(data);
    if (success) {
      onHide();
    }
  });

  return (
    <>
      {show && (
        <ModalPadrao
          isOpen={show}
          handleOnHide={onHide}
          title={t('Adicionar dados de pagamento')}
          subtitle={t('Informe os dados de pagamento da nota.')}
          onEntered={() => setFocus('valor')}
          size="lg"
          minWidth="40%"
        >
          <Form>
            <Modal.Body>
              <Form.Row>
                <Col md={6} xl={6}>
                  <InputCpfCnpj
                    control={control}
                    error={errors.cnpjPagamento}
                    name="cnpjPagamento"
                    setError={setError}
                    id="cnpjPagamento"
                    label="CNPJ do estabelecimento"
                    placeholder="000.000.000-00"
                    required={false}
                  />
                </Col>
                <Col md={6} xl={6}>
                  <SelectPadrao
                    placeholder="Selecione o UF"
                    id="ufPagamento"
                    name="ufPagamento"
                    colSpan={12}
                    options={getOptionsByEnum(EstadosEnum, 'sigla', 'sigla')}
                    label="UF do CNPJ do estabelecimento"
                    helperText="Informe a Unidade Federativa (UF) do CNPJ do estabelecimento onde o pagamento será processado, transacionado ou recebido."
                    size="lg"
                    defaultValue={null}
                    control={control}
                    error={errors.ufPagamento}
                    readonly={false}
                    required={false}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Col md={4} xl={3}>
                  <BootstrapNumberInput
                    id="valor"
                    name="valor"
                    label={t('Valor')}
                    precision={12}
                    scale={2}
                    defaultValue={0}
                    leftElement="R$"
                    control={control}
                    error={errors.valor}
                    disabled={false}
                    required
                  />
                </Col>
                <Col md={5} xl={6}>
                  <SelectPadrao
                    id="formaPagto"
                    name="formaPagto"
                    label={t('Forma de pagamento')}
                    placeholder="Selecione"
                    defaultValue={null}
                    control={control}
                    error={errors.formaPagto}
                    options={formasPagamento.map((formaPagamento) => {
                      return {
                        label: formaPagamento.nome,
                        value: formaPagamento,
                      } as OptionTypeBase;
                    })}
                    readonly={false}
                    required
                  />
                </Col>
                {formasPagamentoParcela && (
                  <Col md={3} xl={3}>
                    <SelectPadrao
                      id="parcela"
                      name="parcela"
                      label={t('Parcelas')}
                      placeholder="Selecione"
                      defaultValue={null}
                      control={control}
                      error={errors.parcela}
                      options={formasPagamentoParcela?.map(
                        (formaPagamentoParcela) => {
                          return {
                            label: formaPagamentoParcela.descricao,
                            value: formaPagamentoParcela,
                          } as OptionTypeBase;
                        }
                      )}
                      readonly={false}
                      required
                    />
                  </Col>
                )}
              </Form.Row>

              {formaPagamentoWatch &&
                (formaPagamentoWatch.meioDePagamento ===
                  MeioPagamentoFiscalEnum.CartaoCredito ||
                  formaPagamentoWatch.meioDePagamento ===
                    MeioPagamentoFiscalEnum.CartaoDebito) && (
                  <>
                    <Form.Row>
                      <Col xl={12}>
                        <div className="d-flex mt-1">
                          <Label>Informações de Cartão</Label>
                        </div>
                        <hr className="my-auto flex-grow-1" />
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col xl={6}>
                        <SelectPadrao
                          id="bandeiraCartao"
                          name="bandeiraCartao"
                          label={t('Bandeira')}
                          noSelectedText={t('Clique aqui para selecionar.')}
                          defaultValue={null}
                          control={control}
                          error={errors.bandeiraCartao}
                          options={Object.entries(
                            BandeirasCartaoEnum.properties
                          ).map((value: any) => {
                            return (
                              {
                                label: t(value[1].name),
                                value: value[1].value,
                                color: value[1].iconColor,
                              } || {}
                            );
                          })}
                          readonly={false}
                        />
                      </Col>
                      <Col xl={6}>
                        <InputPadrao
                          type="text"
                          id="numeroAutorizacao"
                          label={t('Número de autorização')}
                          maxLength={7}
                          error={errors.numeroAutorizacao}
                          disabled={false}
                          control={control}
                        />
                      </Col>
                    </Form.Row>
                  </>
                )}
            </Modal.Body>

            <Modal.Footer>
              {valorWatch > 0 &&
                Number.parseFloat(
                  (Math.round(valorWatch * 100) / 100).toFixed(2)
                ) <
                  Number.parseFloat(
                    (Math.round(valorRestanteTotalNota * 100) / 100).toFixed(2)
                  ) && (
                  <ButtonPadrao
                    id="adicionarInserirNovo"
                    type="button"
                    variant="outline-secondary"
                    name="salvarInserirNovo"
                    text={t('Adicionar e inserir novo')}
                    icon={SalvarInserirNovoIcon}
                    disabled={false}
                    onClick={onSubmitReset}
                    style={{ height: '35px', width: '100%', maxWidth: '225px' }}
                  />
                )}
              <ButtonPadrao
                id="adicionarDocumentoReferenciado"
                name="adicionarDocumentoReferenciado"
                text={t('Adicionar')}
                icon={SalvarConfirmarIcon}
                type="button"
                variant="success"
                onClick={onSubmit}
                style={{ height: '35px', width: '100%', maxWidth: '120px' }}
              />
            </Modal.Footer>
          </Form>
        </ModalPadrao>
      )}
    </>
  );
};

export default PagamentoModalInserir;
