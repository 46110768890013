import React, { useEffect, useState, useCallback } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory, Prompt, RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import RegimeTributarioEnum from 'constants/enum/regimeTributario';
import {
  CertificadoDigitalRetorno,
  CodigoSegurancaContribuinteInterface,
  LojaInterface,
} from 'types/loja';
import api, { ResponseApi } from 'services/api';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import isPrenvent from 'helpers/layout/isPrenvent';
import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import LojaFormularioProvider from 'store/Lojas/LojasFormulario';
import RodapeFormulario from 'components/Geral/RodapeFormulario';
import ManterFoco from 'components/Geral/ManterFoco';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { Card } from 'styles';
import auth from 'modules/auth';
import TipoCertificadoEnum from 'constants/enum/fiscal/tipoCertificado';
import { useForm, yupResolver } from '../validationForm';
import { Container } from '../styles';

import { UncontrolledForm } from '..';

type TParams = { id: string };

const Alterar: React.FC<RouteComponentProps<TParams>> = ({ match }) => {
  const history = useHistory();
  const isMountedRef = useIsMountedRef();
  isPrenvent();

  const [isLoading, setIsLoading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [bloqueioInicial, setBloqueioInicial] = useState(false);
  const [bloqueioValorCpf, setBloqueioValorCpf] = useState(false);
  const [isSimplesNacional, setisSimplesNacional] = useState(false);
  const [dataHoraCadastro, setDataHoraCadastro] = useState('');
  const [dataHoraUltimaAlteracao, setDataHoraUltimaAlteracao] = useState('');
  const [certificadoDigital, setCertificadoDigital] = useState(
    {} as CertificadoDigitalRetorno
  );
  const [NFeReadOnly, setNFeReadOnly] = useState(false);
  const [NFCeReadOnly, setNFCeReadOnly] = useState(false);

  const {
    handleSubmit,
    register,
    control,
    reset,
    getValues,
    setError,
    setValue,
    formState,
    watch,
  } = useForm({
    resolver: yupResolver,
    shouldUnregister: false,
  });

  const handleGetLoja = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get<void, ResponseApi<LojaInterface>>(
      ConstanteEnderecoWebservice.LOJA_OBTER,
      {
        params: { id: match.params.id },
      }
    );
    if (response) {
      if (response.avisos) {
        setIsLoading(false);
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso && response.dados && isMountedRef.current) {
        const { dados } = response;
        setIsLoading(false);

        reset({
          ...dados,
          codigoProdutoFiscal: dados.codigoProdutoFiscal,
          ie: dados.ie || '',
          ieisento: dados.ieisento,
          cep: dados.cep,
          logradouro: dados.logradouro,
          numero: dados.numero,
          complemento: dados.complemento,
          bairro: dados.bairro,
          cidade: {
            label: dados.cidadeNome,
            value: dados.cidadeId,
            paisId: dados.paisId,
          },
          estado: dados.estadoNome,
          pais: { label: dados.paisNome, value: dados.paisId },
          telefoneWhats: dados.telefoneWhatsapp,
          celularWhats: dados.celularWhatsapp,
          contabilistaTelefoneWhats: dados.contabilistaTelefoneWhatsapp,
          contabilistaCelularWhats: dados.contabilistaCelularWhatsapp,
          obterXml: dados.autorizadoObterXml,
          documentosFiscaisAutomaticamente:
            dados.enviarDocumentosFiscaisAutomaticamente,
          contabilistaCidade: dados.contabilistaCidadeId && {
            label: dados.contabilistaCidadeNome,
            value: dados.contabilistaCidadeId,
            paisId: dados.contabilistaPaisId,
          },
          contabilistaEstado: dados.contabilistaEstadoNome,
          contabilistaPais: dados.contabilistaPaisId && {
            label: dados.contabilistaPaisNome,
            value: dados.contabilistaPaisId,
          },
          regraFiscalPadrao:
            dados.regraFiscalPadraoId !== null
              ? {
                  label: dados.regraFiscalPadraoLabel,
                  value: dados.regraFiscalPadraoId,
                }
              : {
                  label: 'Selecione',
                  value: null,
                },

          regimeTributarioSelect: RegimeTributarioEnum.properties.filter(
            (obj) => obj.value === dados.regimeTributario
          )[0],
          nfeNumeroSerie: dados.nfeNumeroSerie,
          nfeNumeracaoInicial: dados.nfeNumeracaoInicial,
          nfceNumeroSerie: dados.nfceNumeroSerie,
          nfceNumeracaoInicial: dados.nfceNumeracaoInicial,
          exigirCNPJCPF: {
            id: 'ExigirCNPJCPF',
            selecionado: dados.nfceExigirCNPJCPF,
          },
          beneficiarioPagamento: {
            id: 'BeneficiarioPagamento',
            selecionado: dados.beneficiarioPagamento,
          },
          nfceValorExigenciaCNPJCPF: dados.nfceValorExigenciaCNPJCPF,
          emitirNFe: { id: 'emitirNFe', selecionado: dados.emitirNFe ?? false },
          emitirNFCe: {
            id: 'emitirNfe',
            selecionado: dados.emitirNFCe ?? false,
          },
          tipoCertificado: `${dados.tipoCertificado}`,
        });

        setNFeReadOnly(!dados.emitirNFe ?? true);
        setNFCeReadOnly(!dados.emitirNFCe ?? true);
        setBloqueioValorCpf(dados.nfceExigirCNPJCPF);

        if (dados.regimeTributario > 2) {
          setisSimplesNacional(false);
        } else {
          setisSimplesNacional(true);
        }

        if (dados.codigosSegurancaContribuinte) {
          setValue(
            'codigosSegurancaContribuinte',
            dados.codigosSegurancaContribuinte.map(
              (obj: CodigoSegurancaContribuinteInterface) => {
                return {
                  CSC: obj.csc.trim(),
                  Token: obj.token.trim(),
                  CSCHomologacao: obj.cscHomologacao?.trim(),
                  TokenHomologacao: obj.tokenHomologacao?.trim(),
                  Principal: obj.principal,
                };
              }
            )
          );
        }

        if (dados.caminhoCertificadoDigital) {
          setCertificadoDigital({
            NomeArquivo: dados.caminhoCertificadoDigital,
            DataValidade: dados.dataValidadeCertificadoDigital,
            SenhaCertificado: dados.senhaCertificadoDigital,
            Uploaded: true,
          });
        }

        setDataHoraCadastro(dados.dataHoraCadastro);
        setDataHoraUltimaAlteracao(dados.dataHoraUltimaAlteracao);
      } else {
        history.push(ConstanteRotas.MINHAS_LOJAS);
      }
    }

    if (isMountedRef.current) setIsLoading(false);
  }, [history, isMountedRef, match.params.id, reset, setValue]);

  async function handlePostLoja() {
    const data = getValues();

    const ieIsento = data.cnpj.length === 14 ? false : data.ieisento;

    const dataViewModel = {
      ...data,
      arquivo: certificadoDigital.Arquivo64 || null,
      paisId: data.pais?.value,
      telefoneWhatsapp: data.telefoneWhats !== '' ? data.telefoneWhats : false,
      celularWhatsapp: data.celularWhats !== '' ? data.celularWhats : false,
      contabilistaTelefoneWhatsapp:
        data.contabilistaTelefoneWhats !== ''
          ? data.contabilistaTelefoneWhats
          : false,
      contabilistaCelularWhatsapp:
        data.contabilistaCelularWhats !== ''
          ? data.contabilistaCelularWhats
          : false,
      contabilistaCidadeId:
        data.contabilistaCidade && data.contabilistaCidade?.value,
      contabilistaPaisId: data.contabilistaPais && data.contabilistaPais?.value,
      regraFiscalPadraoId: data.regraFiscalPadrao?.value,
      nFCeExigirCNPJCPF: data.exigirCNPJCPF?.selecionado ?? false,
      beneficiarioPagamento: data.beneficiarioPagamento?.selecionado ?? false,
      autorizadoObterXml: data.obterXml === null ? false : data.obterXml,
      enviarDocumentosFiscaisAutomaticamente:
        data.documentosFiscaisAutomaticamente === null
          ? false
          : data.documentosFiscaisAutomaticamente,
      regimeTributario: data.regimeTributarioSelect?.value,
      usuarioLogado: auth.getDadosToken().userId,
      ieIsento,
      cidadeId: data.cidade?.value,
      cidadeNome: data.cidade.label,
      caminhoCertificadoDigital: certificadoDigital.NomeArquivo
        ? certificadoDigital.NomeArquivo.split('.')[0]
        : null,
      senhaCertificadoDigital: certificadoDigital.SenhaCertificado
        ? certificadoDigital.SenhaCertificado
        : null,
      dataHoraCadastro: null,
      dataHoraUltimaAlteracao: null,
      emitirNFe: data.emitirNFe?.selecionado ?? false,
      emitirNFCe: data.emitirNFCe?.selecionado ?? false,
      // eslint-disable-next-line radix
      tipoCertificado: parseInt(data.tipoCertificado),
    };

    if (
      data.emitirNFe?.selecionado === true ||
      data.emitirNFCe?.selecionado === true
    ) {
      if (
        data.tipoCertificado === TipoCertificadoEnum.A1 &&
        !certificadoDigital.NomeArquivo
      ) {
        toast.warning('Favor informar um certificado digital.');
        setIsLoading(false);
        return false;
      }
    }

    if (data.emitirNFCe?.selecionado === true) {
      if (data.CodigosSegurancaContribuinte?.length === 0) {
        toast.warning('Favor informar um código de segurança do contribuinte.');
        setIsLoading(false);
        return false;
      }
    }

    const response = await api.post<void, ResponseApi>(
      ConstanteEnderecoWebservice.LOJA_ALTERAR,
      dataViewModel
    );

    if (response.sucesso) {
      return true;
    }

    if (response.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    return false;
  }

  const onSubmit = handleSubmit(async () => {
    setIsLoading(true);

    const success = await handlePostLoja();

    if (success) {
      toast.success('O cadastro foi alterado com sucesso.');

      const { id: currentStorageLojaId = '' } = auth.getLoja();

      if (currentStorageLojaId === match.params.id) {
        const newFantasia = getValues('fantasia');

        const newLojaStorage = {
          id: currentStorageLojaId,
          fantasia: newFantasia,
        };

        auth.setLoja(newLojaStorage);
      }

      setFormIsDirty(false);

      history.push(ConstanteRotas.MINHAS_LOJAS);
    }

    setIsLoading(false);
  });

  useEffect(() => {
    setIsLoading(true);
    handleGetLoja();
  }, [handleGetLoja]);

  useEffect(() => {
    setFormIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  return (
    <Container>
      <Prompt when={formIsDirty} message="" />
      <Form>
        <ManterFoco blockTab={isLoading}>
          <Card>
            {isLoading && <LoadingPadrao />}
            <LojaFormularioProvider
              bloqueioInicial={bloqueioInicial}
              setBloqueioInicial={setBloqueioInicial}
              bloqueioValorCpf={bloqueioValorCpf}
              setBloqueioValorCpf={setBloqueioValorCpf}
              isSimplesNacional={isSimplesNacional}
              setIsSimplesNacional={setisSimplesNacional}
              certificadoDigital={certificadoDigital}
              setCertificadoDigital={setCertificadoDigital}
              NFeReadOnly={NFeReadOnly}
              NFCeReadOnly={NFCeReadOnly}
              setNFeReadOnly={setNFeReadOnly}
              setNFCeReadOnly={setNFCeReadOnly}
            >
              <UncontrolledForm
                errors={formState.errors}
                setError={setError}
                setValue={setValue}
                isAlterar
                getValues={getValues}
                control={control}
                register={register}
                watch={watch}
              />
            </LojaFormularioProvider>
          </Card>
          <RodapeFormulario
            dataHoraCriacao={dataHoraCadastro}
            dataHoraUltimaAlteracao={dataHoraUltimaAlteracao}
            onSubmit={onSubmit}
            disabled={isLoading}
          />
        </ManterFoco>
      </Form>
    </Container>
  );
};

export default Alterar;
