import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form } from 'react-bootstrap';
import { FieldErrors, UseFormRegister } from 'react-hook-form';

import InputPadrao from 'components/Input/InputPadrao';
import { BootstrapNumberInput } from 'components/update/Input/BootstrapNumberInput';
import TextAreaPadrao from 'components/TextArea/TextAreaPadrao';
import InputNumerico from 'components/Input/InputNumerico';

interface UncontrolledFormInterface {
  errors: FieldErrors;
  register: UseFormRegister<Record<string, any>>;
  control: any;
}

const InformacoesGerais: React.FC<UncontrolledFormInterface> = ({
  errors,
  register,
  control,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Form.Row>
        <Col md={3} xl={2}>
          <BootstrapNumberInput
            id="valorFrete"
            name="valorFrete"
            label={t('Valor do frete')}
            precision={12}
            scale={2}
            defaultValue={0}
            leftElement="R$"
            control={control}
            error={errors.valorFrete}
            disabled
          />
        </Col>
        <Col md={3} xl={2}>
          <BootstrapNumberInput
            id="valorSeguro"
            name="valorSeguro"
            label={t('Valor do seguro')}
            precision={12}
            scale={2}
            defaultValue={0}
            leftElement="R$"
            control={control}
            error={errors.valorSeguro}
            disabled={false}
          />
        </Col>
        <Col md={3} xl={2}>
          <BootstrapNumberInput
            id="valorDesconto"
            name="valorDesconto"
            label={t('Valor do desconto')}
            precision={12}
            scale={2}
            defaultValue={0}
            leftElement="R$"
            control={control}
            error={errors.valorDesconto}
            disabled={false}
          />
        </Col>
        <Col md={3} xl={2}>
          <BootstrapNumberInput
            id="valorOutrasDespesas"
            name="valorOutrasDespesas"
            label={t('Valor de outras despesas')}
            precision={12}
            scale={2}
            defaultValue={0}
            leftElement="R$"
            control={control}
            error={errors.valorOutrasDespesas}
            disabled={false}
          />
        </Col>
        <Col md={4} xl={2}>
          <InputPadrao
            type="text"
            id="numeroPedidoCompra"
            label={t('Número do pedido de compra')}
            maxLength={7}
            error={errors.numeroPedidoCompra}
            disabled={false}
            control={control}
          />
        </Col>
        <Col md={4} xl={2}>
          <InputNumerico
            type="text"
            id="itemPedidoCompra"
            name="itemPedidoCompra"
            label={t('Item do pedido de compra')}
            maxLength={10}
            control={control}
            error={errors.itemPedidoCompra}
            disabled={false}
          />
        </Col>
        <Col xl={12}>
          <TextAreaPadrao
            id="dadosAdicionais"
            label={t('Dados adicionais')}
            error={errors.dadosAdicionais}
            disabled={false}
            rows={5}
            {...register('dadosAdicionais')}
          />
        </Col>
      </Form.Row>
    </>
  );
};

export default InformacoesGerais;
