import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Heading,
  Grid,
  Button,
  Text,
  CloseButton,
  HStack,
  useMediaQuery,
  Flex,
  useBreakpointValue,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import useWindowSize from 'helpers/layout/useWindowSize';
import { useSelecaoFormaPagamentoContext } from 'store/PDV/SelecaoFormaPagamento';
import { useInformacoesGeraisContext } from 'store/PDV/InformacoesGerais';
import { usePagamentoContext } from 'store/PDV/Pagamento';
import MeioPagamentoEnum from 'constants/enum/fiscal/meioPagamento';

import ConditionalWrapper from 'components/Geral/ConditionalWrapper';
import ShadowScrollbar, {
  ShadowScrollbarForwardRefProps,
} from 'components/PDV/Geral/ShadowScrollbar';

export interface Parcela {
  descricao: string;
  qtdeParcela: number;
  intervaloDias: number;
  descontoPorcentagem: number;
  descontoValor: number;
  acrescimoPorcentagem: number;
  acrescimoValor: number;
  id: string;
}

const ListarParcelas = () => {
  const asMobileView = useBreakpointValue({ base: true, md: false });

  const {
    formaPagamento,
    handleSelecionarFormaPagamento,
    handleSelecionarParcela,
    handleChangeStep,
    setIsLoading,
  } = useSelecaoFormaPagamentoContext();
  const { contaFinanceira } = useInformacoesGeraisContext();
  const { valorTotalVenda } = usePagamentoContext();

  const { height: windowHeight } = useWindowSize();
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const isCaixaRegistradoStone = Boolean(contaFinanceira?.serialPOS);
  const isFormaPagamentoStone =
    formaPagamento?.regraMeioPagamento ===
      MeioPagamentoEnum.CartaoCreditoStone ||
    formaPagamento?.regraMeioPagamento === MeioPagamentoEnum.CartaoDebitoStone;

  const bloquearVendaParcelada =
    isCaixaRegistradoStone && isFormaPagamentoStone && valorTotalVenda <= 1;

  const [parcelas, setParcelas] = useState<Parcela[]>([]);

  const shadowScrollbarRef = useRef<ShadowScrollbarForwardRefProps>(null);

  const latestProps = useRef({
    formaPagamento,
    handleChangeStep,
    handleSelecionarParcela,
    setIsLoading,
  });
  useEffect(() => {
    latestProps.current = {
      formaPagamento,
      handleChangeStep,
      handleSelecionarParcela,
      setIsLoading,
    };
  });

  useEffect(() => {
    const handleGetParcelas = async () => {
      latestProps.current.setIsLoading(true);

      if (latestProps.current.formaPagamento) {
        const response = await api.get<void, ResponseApi<Parcela[]>>(
          ConstanteEnderecoWebservice.FORMA_PAGAMENTO_RECEBIMENTO_PARCELA_LISTAR_SELECT_PDV,
          {
            params: {
              formaPagamentoRecebimentoId:
                latestProps.current.formaPagamento.id,
            },
          }
        );

        if (response) {
          if (response.avisos) {
            response.avisos.map((item: string) => toast.warning(item));
          }

          if (response.sucesso && response.dados) {
            if (response.dados.length > 1) {
              setParcelas(response.dados);
              latestProps.current.setIsLoading(false);

              return;
            }

            if (response.dados.length === 1) {
              latestProps.current.handleSelecionarParcela(response.dados[0]);
            } else {
              latestProps.current.handleChangeStep('informandoValor');
            }

            return;
          }
        }
      }

      latestProps.current.setIsLoading(false);
      setParcelas([]);
    };

    handleGetParcelas();
  }, []);

  useEffect(() => {
    if (shadowScrollbarRef.current)
      shadowScrollbarRef.current.handleUpdateScrollbar();
  }, [parcelas]);

  return (
    <ConditionalWrapper
      condition={isLargerThan900}
      wrapper={(wrappedChildren) => (
        <ShadowScrollbar
          width="100%"
          maxHeight={windowHeight - 225}
          paddingTop="0"
          shadowTopStyle={{
            background:
              'transparent linear-gradient(180deg, var(--sti-ck-colors-gray-50)  0%,  #FFFFFF00 100%) 0% 0% no-repeat padding-box',
            height: 30,
          }}
          shadowBottomStyle={{
            background:
              'transparent linear-gradient(180deg, #FFFFFF00 0%, var(--sti-ck-colors-gray-50) 100%) 0% 0% no-repeat padding-box',
            height: 30,
          }}
        >
          {wrappedChildren}
        </ShadowScrollbar>
      )}
    >
      <Box py={{ base: 0, md: 5 }} px={{ base: 0, md: 8, xl: 12 }}>
        <HStack
          spacing={4}
          mb={2}
          justifyContent={
            parcelas && parcelas.length > 1 ? 'space-between' : 'flex-end'
          }
        >
          {parcelas && parcelas.length > 1 && (
            <Heading
              as="h4"
              fontSize={{ base: 'sm', md: 'xs', xl: 'md' }}
              mb={2}
              lineHeight="tall"
            >
              Selecione a quantidade de parcelas:
            </Heading>
          )}

          {!asMobileView && (
            <CloseButton
              size="sm"
              onClick={() => {
                handleSelecionarFormaPagamento(undefined);
              }}
            />
          )}
        </HStack>

        {parcelas && parcelas.length > 1 && (
          <Grid
            templateColumns={{
              base: '1fr 1fr 1fr',
              sm: '1fr 1fr 1fr 1fr',
            }}
            columnGap={{ base: 4, md: 6, lg: 8 }}
            rowGap={{ base: 4, md: 6, lg: 7 }}
          >
            {parcelas.map((parcela) => (
              <Flex w="full" justifyContent="center">
                <Button
                  key={parcela.qtdeParcela}
                  position="relative"
                  h={{ base: '90px', xl: '120px' }}
                  w={{ base: 'full', md: '100px', lg: '135px', xl: '200px' }}
                  justifyContent="center"
                  borderRadius="lg"
                  border="1px"
                  borderColor="gray.100"
                  boxShadow="md"
                  bg="white"
                  isDisabled={bloquearVendaParcelada && parcela.qtdeParcela > 1}
                  _hover={{ bg: 'white', color: 'gray.400' }}
                  _active={{ bg: 'white', color: 'gray.400' }}
                  color="gray.700"
                  py={2}
                  onClick={() => {
                    handleSelecionarParcela(parcela);
                  }}
                >
                  <Text
                    noOfLines={2}
                    whiteSpace="normal"
                    maxW="full"
                    fontSize={{ base: 'lg', md: 'xl', xl: '2xl' }}
                  >
                    {parcela.descricao}
                  </Text>
                </Button>
              </Flex>
            ))}
          </Grid>
        )}
      </Box>
    </ConditionalWrapper>
  );
};

export default ListarParcelas;
