import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import UncontrolledForm from '..';
import useCustomReportForm from '../hooks';
import { FormData, defaultValues, yupResolver } from '../validationForm';

const RelatorioPersonalizadoAlterar = () => {
  const {
    obterRelatorioPersonalizado,
    atualizarRelatorioPersonalizado,
    redirecionarRelatorioPersonalizadoListar,
  } = useCustomReportForm();

  const { id } = useParams<{ id: string }>();
  const formMethods = useForm<FormData>({
    defaultValues,
    resolver: yupResolver,
  });
  const { handleSubmit, reset } = formMethods;

  const [isLoading, setIsLoading] = useState(false);

  const handleSalvar = async (data: FormData) => {
    setIsLoading(true);

    const { success } = await atualizarRelatorioPersonalizado(id, data);

    if (success) {
      toast.success('Relatório personalizado alterado com sucesso');
      redirecionarRelatorioPersonalizadoListar();
    }

    setIsLoading(false);
  };

  const resetarForm = useCallback(async () => {
    setIsLoading(true);
    const customReport = await obterRelatorioPersonalizado(id);

    if (customReport) {
      reset(customReport);
    }
    setIsLoading(false);
  }, [obterRelatorioPersonalizado, id, reset]);

  useEffect(() => {
    resetarForm();
  }, [resetarForm]);

  return (
    <FormProvider {...formMethods}>
      <UncontrolledForm
        isLoading={isLoading}
        handleSalvar={handleSubmit(handleSalvar)}
      />
    </FormProvider>
  );
};

export default RelatorioPersonalizadoAlterar;
