import { toast } from 'react-toastify';
import { useCallback } from 'react';

import auth from 'modules/auth';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

const usePadronizacao = () => {
  const userIsAdmin = auth.getIsAdministrador();

  const handleRemoveAccountData = useCallback(async () => {
    const response = await api.post<void, ResponseApi>(
      ConstanteEnderecoWebservice.RESET_BANCO_DADOS
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }
    }
  }, []);

  return {
    userIsAdmin,
    handleRemoveAccountData,
  };
};

export default usePadronizacao;
