import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { GridItem, Button, Box, Flex, Text } from '@chakra-ui/react';

import { useConferenciaEstoqueContainerContext } from 'store/ConferenciaEstoque/ConferenciaEstoqueContainer';
import { useConferenciaEstoqueEtapasContext } from 'store/ConferenciaEstoque/ConferenciaEstoqueEtapas';

import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { DetailedRadio } from 'components/update/Radio/DetailedRadio';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { Container, Body, Footer } from 'components/update/Steps/StepContent';

import { yupResolver, FormData } from './validationForm';

type ListaConferenciaResponse = { id: string; nome: string };

export function EscolherTipoCorrecao() {
  const formMethods = useForm<FormData>({
    resolver: yupResolver,
  });

  const { descartarConferencia } = useConferenciaEstoqueContainerContext();
  const {
    previousStep,
    nextStep,
    conferenciaEstoque,
    setCorrigirApenasItensConferidos,
  } = useConferenciaEstoqueEtapasContext();

  const [isLoading, setIsLoading] = useState(false);

  function handleDescartarConferencia() {
    descartarConferencia();
  }

  function handleVoltar() {
    previousStep();
  }

  const handleAvancar = formMethods.handleSubmit(
    ({ tipoCorrecao }) => {
      setIsLoading(true);

      if (tipoCorrecao === 'apenasConferidos') {
        setCorrigirApenasItensConferidos(true);
      } else {
        setCorrigirApenasItensConferidos(false);
      }

      nextStep();

      setIsLoading(false);
    },
    (err) => {
      if (err && err.tipoCorrecao) {
        toast.warn(
          'Selecione o tipo da correção que deseja fazer para os produtos desta conferência.'
        );
      }
    }
  );

  const categoria = conferenciaEstoque?.listaCategorias || [];
  const marca = conferenciaEstoque?.listaMarcas || [];
  const existeMultiplasCategorias = categoria.length > 1;
  const existeMultiplasMarcas = marca.length > 1;

  const transformandoCategoriaEmString = JSON.stringify(
    (conferenciaEstoque?.listaCategorias || []).map((item) => item.label)
  )
    .replaceAll('"', '')
    .replaceAll(',', ', ')
    .replaceAll('[', ' ')
    .replaceAll(']', ' ');
  const transformandoMarcaEmString = JSON.stringify(
    (conferenciaEstoque?.listaMarcas || []).map((item) => item.label)
  )
    .replaceAll('"', '')
    .replaceAll(',', ', ')
    .replaceAll('[', ' ')
    .replaceAll(']', ' ');

  return (
    <Container>
      {isLoading && <LoadingPadrao />}
      <FormProvider {...formMethods}>
        <Body>
          <SimpleGridForm>
            <GridItem colSpan={12}>
              <DetailedRadio
                name="tipoCorrecao"
                onChange={(newValue) =>
                  formMethods.setValue('tipoCorrecao', newValue)
                }
                items={[
                  {
                    value: 'apenasConferidos',
                    title:
                      'Corrigir o estoque APENAS dos produtos que foram conferidos',
                    subtitle:
                      'A correção do estoque será aplicada SOMENTE PARA OS PRODUTOS QUE FORAM CONFERIDOS prevalecendo as quantidades informadas no passo anterior. Os demais produtos do seu estoque não serão alterados.',
                  },
                  {
                    value: 'todos',
                    title:
                      categoria.length > 0 || marca.length > 0
                        ? 'Corrigir todos os produtos do estoque conforme o filtro aplicado'
                        : 'Corrigir TODOS OS PRODUTOS DO ESTOQUE',
                    subtitle: (
                      <>
                        <Box>
                          {categoria.length > 0 || marca.length > 0
                            ? 'Todos os produtos das categorias ou marcas selecionadas serão corrigidos. '
                            : 'Todos os produtos ativos no estoque serão corrigidos. '}
                          Para os itens que foram lançados nesta conferência
                          será considerada as quantidades informadas no passo
                          anterior.{' '}
                          <strong>
                            Os demais itens que não foram registrados e possuem
                            quantidades diferentes de ZERO, o saldo será ZERADO.
                          </strong>
                        </Box>
                        <Box>
                          {categoria.length > 0 && (
                            <Flex>
                              <Text mr="4px">
                                {existeMultiplasCategorias
                                  ? 'Categorias:'
                                  : 'Categoria:'}
                              </Text>
                              <Flex>
                                <Text w="100%">
                                  {transformandoCategoriaEmString}
                                </Text>
                              </Flex>
                            </Flex>
                          )}
                          {marca.length > 0 && (
                            <Flex>
                              <Text mr="4px">
                                {existeMultiplasMarcas ? 'Marcas:' : 'Marca:'}
                              </Text>
                              <Flex>
                                <Text w="100%" mr="4px">
                                  {transformandoMarcaEmString}
                                </Text>
                              </Flex>
                            </Flex>
                          )}
                        </Box>
                      </>
                    ),
                  },
                ]}
              />
            </GridItem>
          </SimpleGridForm>
        </Body>
        <Footer>
          <Button
            variant="cancel"
            colorScheme="gray"
            borderRadius="md"
            w="full"
            maxW={{ base: 'full', md: '100px' }}
            onClick={handleDescartarConferencia}
          >
            Descartar
          </Button>
          <Button
            variant="outlineDefault"
            borderRadius="md"
            w="full"
            maxW={{ base: 'full', md: '100px' }}
            onClick={handleVoltar}
          >
            Voltar
          </Button>
          <Button
            colorScheme="primary"
            borderRadius="md"
            w="full"
            maxW={{ base: 'full', md: '160px' }}
            onClick={handleAvancar}
          >
            Avançar
          </Button>
        </Footer>
      </FormProvider>
    </Container>
  );
}
