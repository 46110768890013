import {
  useState,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import { Box, CSSObject, Flex } from '@chakra-ui/react';

import { useIntersectionObserver } from 'hooks/useIntersectionObserver';

export type VirtualizedListRefProps = {
  reload: () => void;
};

export type PaginationVirtualizedListProps = {
  paginaAtual: number;
  tamanhoPagina: number;
  campoOrdenacao: string;
  direcaoOrdenacao: 'asc' | 'desc';
};

type VirtualizedListProps<T> = {
  items: T[];
  render: (items: T[], heigh: string, marginBottom?: string) => React.ReactNode;
  itemHeight: number;
  containerHeight: number;
  quantidadeItems: number;
  handleOnClick?: (
    data: PaginationVirtualizedListProps,
    functionIsObserver: boolean
  ) => void;
  colorBgGradient?: string;
  tamanhoPagina?: number;
  direcaoOrdenacao?: string;
  campoOrdenacao?: string;
  marginBottom?: number;
  sx?: CSSObject | undefined;
  noItemsMessage?: string;
};

export const pagination = {
  tamanhoPagina: 10,
  direcaoOrdenacao: 'asc',
  campoOrdenacao: '',
};

function List<T>(
  {
    items,
    itemHeight: valueItemHeight,
    marginBottom = 0,
    containerHeight,
    quantidadeItems,
    sx,
    tamanhoPagina = 10,
    direcaoOrdenacao = 'asc',
    campoOrdenacao = '',
    render,
    handleOnClick,
    colorBgGradient = 'gray.100',
    noItemsMessage = 'Nenhum item foi encontrado',
  }: VirtualizedListProps<T>,
  ref: React.ForwardedRef<VirtualizedListRefProps>
) {
  const [scrollTop, setScrollTop] = useState(0);

  const paginaAtualRef = useRef(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const itemHeight = valueItemHeight + marginBottom;

  const visibleItemsLength = Math.ceil(containerHeight / itemHeight) + 1;

  const startIndex = Math.min(
    Math.floor(scrollTop / itemHeight),
    Math.max(items.length - visibleItemsLength, 0)
  );
  const endIndex = Math.min(startIndex + visibleItemsLength, items.length);

  const totalHeight = items.length * itemHeight;
  const visibleItems = items.slice(startIndex, endIndex);

  const isExibirScroll = totalHeight >= containerHeight;
  const isVisibleFirstElemento = scrollTop > 0 && true;
  const isVisibleLastElemento =
    scrollTop < totalHeight - (containerHeight / itemHeight) * itemHeight &&
    true;

  const chamarFuncaoPaginada = async (
    nextPage: boolean,
    isObserver: boolean
  ) => {
    if (nextPage) {
      paginaAtualRef.current += 1;
    }

    const dataPagination = {
      tamanhoPagina,
      direcaoOrdenacao,
      campoOrdenacao,
      paginaAtual: nextPage ? paginaAtualRef.current : 1,
    } as PaginationVirtualizedListProps;

    if (handleOnClick) {
      await handleOnClick(dataPagination, isObserver);
    }
  };

  const handleScroll = useCallback(() => {
    if (containerRef.current) {
      const valueScrollTop = containerRef.current.scrollTop || 0;
      setScrollTop(valueScrollTop);
    }
  }, []);

  const { elementRef: ultimoItemLista } = useIntersectionObserver({
    onIntersecting: () => {
      chamarFuncaoPaginada(!(handleOnClick && items.length > 0) || true, true);
    },
    isObserver: isExibirScroll,
  });

  useImperativeHandle(ref, () => ({
    reload: () => chamarFuncaoPaginada(false, false),
  }));

  useEffect(() => {
    chamarFuncaoPaginada(true, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box pos="relative" w="full">
      <Box
        position="absolute"
        h="20px"
        w="full"
        bgGradient={`linear(to-b, ${colorBgGradient} 0%, rgba(0, 0, 0, 0) 100%)`}
        pointerEvents="none"
        zIndex="22"
        borderRadius="5px"
        transition="opacity 0.4s"
        opacity={isVisibleFirstElemento ? 1 : 0}
      />
      <Box
        position="absolute"
        bottom={0}
        left={0}
        h="30px"
        w="full"
        bgGradient={`linear(to-t, ${colorBgGradient} 0%, rgba(0, 0, 0, 0) 100%)`}
        borderBottomRadius="md"
        pointerEvents="none"
        zIndex="22"
        transition="opacity 0.4s"
        opacity={isVisibleLastElemento ? 1 : 0}
      />
      <Box
        pos="relative"
        minH={`${containerHeight}px`}
        overflowY={isExibirScroll ? 'scroll' : 'hidden'}
        overflowX="hidden"
        id="containerVirtualized"
        w="full"
        color="white"
        onScroll={handleScroll}
        mr="-10px"
        sx={{
          ...sx,
          '& > div': {
            marginRight: sx ? 'unset' : '6px',
          },

          '&::-webkit-scrollbar': {
            width: '4px',
            scrollbarWidth: 'thin',
          },

          '&::-webkit-scrollbar-track': {
            background: 'none',
            borderRadius: '6px',
          },

          '&::-webkit-scrollbar-thumb': {
            background: 'gray.200',
            borderRadius: '6px',
          },

          '&::-webkit-scrollbar-thumb:hover': {
            background: 'gray.200',
          },
        }}
        ref={containerRef}
      >
        <Box
          h={`${itemHeight * quantidadeItems}px`}
          color="currentColor"
          w="100%"
          pos="relative"
        />
        {visibleItems?.length > 0 ? (
          <Box
            pos="absolute"
            top={`${startIndex * itemHeight}px`}
            left="0"
            right="0"
            p="2px"
          >
            {render(visibleItems, `${valueItemHeight}px`, `${marginBottom}px`)}
          </Box>
        ) : (
          <Flex
            color="black"
            flexDirection={['column', 'row', 'row']}
            justifyContent="space-between"
            alignItems={['left', 'center', 'center']}
            mb="5px"
            h="48px"
            bg="white"
            p="10px"
            pl={['2px', '20px', '20px']}
            pr={['10px', '20px', '20px']}
            borderRadius="6px"
          >
            {noItemsMessage}
          </Flex>
        )}

        {handleOnClick && <Box h={`${itemHeight}px`} ref={ultimoItemLista} />}
      </Box>
    </Box>
  );
}

const VirtualizedList = forwardRef(List) as <T>(
  props: VirtualizedListProps<T> & {
    ref?: React.ForwardedRef<VirtualizedListRefProps>;
  }
) => ReturnType<typeof List>;

export default VirtualizedList;
