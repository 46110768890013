import {
  Flex,
  Grid,
  GridItem,
  Text,
  Tooltip,
  Icon,
  useMediaQuery,
  Box,
} from '@chakra-ui/react';

import { cepMask, moneyMask } from 'helpers/format/fieldsMasks';

import { InfoIconCircular } from 'icons';

import { ClienteFornecedorInterface } from '../Types/validationForms';

type TabDadosCliente = {
  cliente: ClienteFornecedorInterface;
  corClassificacao: string;
  obterLogradouro: string;
  obterBairroCidadeEstado: string;
  numeroTelefone: string;
};

export const TabDadosCliente = ({
  cliente,
  corClassificacao,
  numeroTelefone,
  obterLogradouro,
  obterBairroCidadeEstado,
}: TabDadosCliente) => {
  const emptyField = '---';

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  return (
    <Grid
      templateAreas={
        isLargerThan900
          ? `"dataNascimento cpf rg classificacao"
                      "email email telefone telefone"
                      "endereco endereco endereco endereco"
                      "saldoAberto valorAtraso limiteCredito limiteDisponivel"`
          : `"dataNascimento cpf" "rg classificacao"
            "email email" "telefone telefone" "endereco endereco" "saldoAberto valorAtraso"
            "limiteCredito limiteDisponivel"`
      }
      gridTemplateRows={
        isLargerThan900
          ? '80px 64px 84px 64px'
          : '80px 80px 64px 64px 112px 64px 64px'
      }
      gridTemplateColumns={isLargerThan900 ? '24% 24% 24% 24%' : '49% 49%'}
      gap="2"
      pl="5px"
      color="blackAlpha.700"
      fontWeight="bold"
    >
      <GridItem
        borderRadius="5px"
        px="16px"
        py="12px"
        bg="white"
        area="dataNascimento"
      >
        <Text fontSize="12px" textAlign="left" color="primary.50">
          Data de nascimento
        </Text>
        <Text
          fontSize="16px"
          textAlign="left"
          fontWeight="bold"
          color="primary.500"
        >
          {cliente.dataNascimento
            ? new Date(cliente.dataNascimento).toLocaleDateString('pt-br', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              })
            : emptyField}
        </Text>
      </GridItem>
      <GridItem px="16px" py="12px" borderRadius="5px" bg="white" area="cpf">
        <Text fontSize="12px" textAlign="left" color="primary.50">
          CPF
        </Text>
        <Text
          fontSize="16px"
          textAlign="left"
          fontWeight="bold"
          color="primary.500"
          letterSpacing="0px"
        >
          {cliente.cpfCnpj || emptyField}
        </Text>
      </GridItem>
      <GridItem px="16px" borderRadius="5px" py="12px" bg="white" area="rg">
        <Text fontSize="12px" textAlign="left" color="primary.50">
          RG
        </Text>
        <Text
          fontSize="16px"
          textAlign="left"
          fontWeight="bold"
          color="primary.500"
          letterSpacing="0px"
        >
          {cliente.rgInscricaoEstadual || emptyField}
        </Text>
      </GridItem>
      <GridItem
        px="16px"
        borderRadius="5px"
        py="12px"
        bg={corClassificacao}
        area="classificacao"
      >
        <Text fontSize="12px" textAlign="left" color="white">
          Classificação
        </Text>
        <Text
          fontSize="16px"
          textAlign="left"
          fontWeight="bold"
          color="white"
          letterSpacing="0px"
        >
          {cliente.categoriaClienteDescricao || emptyField}
        </Text>
      </GridItem>
      <GridItem px="16px" borderRadius="5px" py="12px" bg="white" area="email">
        <Text fontSize="12px" textAlign="left" color="primary.50">
          E-mail
        </Text>
        <Text
          fontSize="16px"
          textAlign="left"
          fontWeight="bold"
          color="primary.500"
        >
          {cliente.email || emptyField}
        </Text>
      </GridItem>
      <GridItem
        px="16px"
        borderRadius="5px"
        py="12px"
        bg="white"
        area="telefone"
      >
        <Text fontSize="12px" textAlign="left" color="primary.50">
          Telefone
        </Text>
        <Text
          fontSize="16px"
          textAlign="left"
          fontWeight="bold"
          color="primary.500"
        >
          {numeroTelefone}
        </Text>
      </GridItem>
      <GridItem
        px="16px"
        borderRadius="5px"
        py="12px"
        bg="white"
        area="endereco"
      >
        <Text fontSize="12px" textAlign="left" color="primary.50">
          Endereço
        </Text>
        <Flex
          alignItems={['start', 'center']}
          justifyContent="left"
          w="full"
          flexDir={['column', 'row']}
        >
          <Text
            fontSize="16px"
            textAlign="left"
            fontWeight="bold"
            color="primary.500"
          >
            {obterLogradouro}
            {obterBairroCidadeEstado}
            {cliente.cep ? `, CEP: ${cepMask(cliente.cep)}` : ''}
          </Text>
        </Flex>
      </GridItem>

      <GridItem
        px="16px"
        borderRadius="5px"
        py="12px"
        bg="white"
        area="saldoAberto"
      >
        <Flex justifyContent="left" alignItems="center">
          <Text fontSize="12px" mr="5px" textAlign="left" color="primary.50">
            Saldo em aberto
          </Text>
          <Tooltip
            tabIndex={-1}
            placement="right"
            hasArrow
            bg="yellow.500"
            w="450px"
            maxW="450px"
            label={
              <Flex
                pt="18px"
                ml="25px"
                pb="18px"
                bg="yellow.500"
                w="450"
                h="93px"
              >
                <Text fontSize="12px" color="black">
                  O cadastro do cliente é compartilhado entre todas as lojas. É
                  possível que os valores relacionados ao saldo ou limite de
                  crédito estejam comprometidos por compras efetuadas em outra
                  loja.
                </Text>
              </Flex>
            }
          >
            <Box cursor="pointer" tabIndex={-1}>
              <Icon color="primary.500" as={InfoIconCircular} boxSize="13px" />
            </Box>
          </Tooltip>
        </Flex>
        <Text
          fontSize="16px"
          textAlign="left"
          fontWeight="bold"
          color="primary.500"
        >
          {moneyMask(cliente.saldoAberto, true)}
        </Text>
      </GridItem>

      <GridItem
        px="16px"
        borderRadius="5px"
        py="12px"
        bg={cliente.valoresAtraso ? 'red.500' : 'white'}
        area="valorAtraso"
      >
        <Text
          fontSize="12px"
          textAlign="left"
          color={cliente.valoresAtraso ? 'white' : 'primary.50'}
        >
          Valores em atraso
        </Text>
        <Text
          fontSize="16px"
          textAlign="left"
          fontWeight="bold"
          color={cliente.valoresAtraso ? 'white' : 'primary.500'}
        >
          {moneyMask(cliente.valoresAtraso, true)}
        </Text>
      </GridItem>
      <GridItem
        px="16px"
        borderRadius="5px"
        py="12px"
        bg="white"
        area="limiteCredito"
      >
        <Text fontSize="12px" textAlign="left" color="primary.50">
          Limite de crédito
        </Text>
        <Text
          fontSize="16px"
          textAlign="left"
          fontWeight="bold"
          color="primary.500"
        >
          {moneyMask(cliente.limiteCredito, true)}
        </Text>
      </GridItem>
      <GridItem
        px="16px"
        borderRadius="5px"
        py="12px"
        bg="white"
        area="limiteDisponivel"
      >
        <Text fontSize="12px" textAlign="left" color="primary.50">
          Limite disponível
        </Text>
        <Text
          fontSize="16px"
          textAlign="left"
          fontWeight="bold"
          color="primary.500"
        >
          {moneyMask(
            cliente.limiteCredito > 0
              ? cliente.limiteCredito - cliente.saldoAberto
              : 0,
            true
          )}
        </Text>
      </GridItem>
    </Grid>
  );
};
