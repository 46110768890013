import { useState, useCallback, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { Box, Flex, useMediaQuery } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import TipoRepasseComissaoEnum from 'constants/enum/tipoRegraPagComissao';
import api, { ResponseApi } from 'services/api';
import { useMetasComissoesRelatorioVendasContext } from 'store/MetasComissoes/MetasComissoesRelatorioVendas';

import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import { PaginationData } from 'components/update/Pagination';
import {
  PagedTable,
  PagedTableForwardRefData,
} from 'components/update/Table/PagedTable';

import { RegraPagamentoResponse } from '../../Etapas/EscolherRegraPagComissao';
import { HistoricoVenda } from '../../ComissaoVendedores/HistoricoVenda';
import { ListarHistoricoVendasResponseProps } from '../../Types/validationsForm';

export const HistoricoComissao = () => {
  const [
    valorCadastradoRegraPagamento,
    setValorCadastradoRegraPagamento,
  ] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [totalRegistros, setTotalRegistros] = useState(0);

  const pagedTableRef = useRef<PagedTableForwardRefData>(null);
  const pageIsLoaded = useRef(false);
  const isMountedRef = useIsMountedRef();

  const {
    setRegistroPaginacao,
    obterVendedorVinculado,
  } = useMetasComissoesRelatorioVendasContext();
  const { watch } = useFormContext();

  const [mesAnoWatch, vendedorId] = watch(['mesAno', 'todosVendedores']);

  const [listarHistoricoComissao, setListarHistoricoComissao] = useState<
    ListarHistoricoVendasResponseProps[]
  >([]);

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const atualizarValoresRegraPagamento = useCallback(async () => {
    const response = await api.get<void, ResponseApi<RegraPagamentoResponse>>(
      ConstanteEnderecoWebservice.OBTER_CADASTRO_METAS_COMISSAO_REGRA_REPASSE,
      {
        params: {
          periodo: mesAnoWatch,
        },
      }
    );
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        setValorCadastradoRegraPagamento(response.dados.regraPagamentoComissao);
      }
    }
  }, [mesAnoWatch]);

  const regraRepasseRecebimento =
    valorCadastradoRegraPagamento === TipoRepasseComissaoEnum.RECEBIMENTO;

  const paginationHandle = useCallback(
    async (gridPaginadaConsulta: PaginationData) => {
      setIsLoading(true);
      const vendedor = await obterVendedorVinculado();
      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<ListarHistoricoVendasResponseProps>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.LISTAR_PAGINADO_HISTORICO_VENDA_METAS,
          gridPaginadaConsulta
        ),
        {
          params: {
            mesAno: mesAnoWatch?.toISOString() ?? '',
            vendedorId: vendedor === null ? vendedorId?.value : vendedor,
          },
        }
      );

      if (response?.sucesso && isMountedRef.current) {
        setTotalRegistros(response?.dados?.total);
        setRegistroPaginacao(gridPaginadaConsulta);
        setListarHistoricoComissao(response?.dados?.registros);
        setIsLoading(false);
      }

      if (isMountedRef.current) {
        setIsLoading(false);

        if (!pageIsLoaded.current) {
          pageIsLoaded.current = true;
        }
      }
    },
    [
      isMountedRef,
      mesAnoWatch,
      obterVendedorVinculado,
      setRegistroPaginacao,
      vendedorId,
    ]
  );

  useEffect(() => {
    atualizarValoresRegraPagamento();
  }, [atualizarValoresRegraPagamento]);

  return (
    <Box
      mt="26px"
      boxShadow="0px 0px 6px #00000034"
      overflowX="scroll"
      sx={{
        '&::-webkit-scrollbar': {
          height: '0',
          width: '0',
        },
        '& .virtualized_List::-webkit-scrollbar': {
          height: '0',
          width: '0',
        },
      }}
    >
      <PagedTable
        ref={pagedTableRef}
        editarTable
        isLoading={isLoading}
        loadColumnsData={paginationHandle}
        itemsTotalCount={totalRegistros}
        ml="0"
        variant="simpleCardVariant"
        sx={{
          '& td:only-child': { bg: 'white', h: '2px', border: 'none' },
          '& tr': { boxShadow: 'none', borderBottom: 'none' },
          '& th': { borderBottom: '1px solid #CCCCCC' },

          position: 'relative',
        }}
        editarCorBorda="white"
        defaultKeyOrdered="dataHora"
        tableHeaders={[
          {
            key: 'dataHora',
            content: 'Data e hora',
            w: '12%',
            isOrderable: false,
          },
          { key: 'numero', isOrderable: false, content: 'Número', w: '10%' },
          { key: 'cliente', isOrderable: false, content: 'Cliente', w: '40%' },
          {
            key: 'valor',
            isOrderable: false,
            content: (
              <Flex justifyContent="right" alignItems="right">
                {isLargerThan900 ? 'Valor total da venda' : 'Valor'}
              </Flex>
            ),
            w: '20%',
          },
          {
            key: 'comissao',
            isOrderable: false,
            content: (
              <Flex justifyContent="right" alignItems="right">
                {isLargerThan900 ? 'Comissão sobre a venda' : 'Comissão'}
              </Flex>
            ),
            w: '20%',
          },
        ]}
        renderTableRows={(listarHistoricoComissao || []).map((historico) => (
          <HistoricoVenda
            historico={historico}
            regraRepasseRecebimento={regraRepasseRecebimento}
          />
        ))}
      />
    </Box>
  );
};
