import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  ModalFooter,
  Box,
  ModalHeader,
  useMediaQuery,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import Cropper, { Area } from 'react-easy-crop';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';

type ModalRedimensionarResponse = {
  imagemRedimensionada: string;
};

type ModalRedimensionarImagemProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalRedimensionarResponse> & {
    imageValue: string;
    ratio: number;
  };

export const ModalRedimensionarImagem = create<
  ModalRedimensionarImagemProps,
  ModalRedimensionarResponse
>(({ imageValue, onResolve, onReject, ratio, ...rest }) => {
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({} as Area);

  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const onCropComplete = useCallback((_, areaPixel: Area) => {
    setCroppedAreaPixels(areaPixel);
  }, []);

  const getCroppedImg = async (imageSrc: string, pixelCrop: Area) => {
    const image = new Image();
    image.src = imageSrc;

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      return null;
    }

    canvas.width = image.width;
    canvas.height = image.height;

    ctx.drawImage(image, 0, 0);

    const croppedCanvas = document.createElement('canvas');
    const croppedCtx = croppedCanvas.getContext('2d');

    if (!croppedCtx) {
      return null;
    }

    croppedCanvas.width = pixelCrop.width;
    croppedCanvas.height = pixelCrop.height;

    croppedCtx.drawImage(
      canvas,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );
    const cropImage = croppedCanvas.toDataURL('image/png');

    return cropImage;
  };

  const cropImage = async (newImage: string) => {
    const img = new Image();

    img.src = newImage;
    img.onload = () => {
      const newWidth = img.width;
      const newHeight = img.height;

      const canvas = document.createElement('canvas');

      const ctx = canvas.getContext('2d');
      if (!ctx) {
        return;
      }
      canvas.width = newWidth;
      canvas.height = newHeight;
      ctx.drawImage(img, 0, 0, newWidth, newHeight);

      const finalImage = canvas.toDataURL('image/jpeg');

      onResolve({
        imagemRedimensionada: finalImage,
      });
    };
  };

  const handleCroppedImage = async () => {
    const newImage = await getCroppedImg(imageValue, croppedAreaPixels);

    if (newImage !== null) {
      await cropImage(newImage);
    } else {
      toast.warning(
        'Não foi possível redimensionar a imagem, por favor tente novamente'
      );
    }
  };

  return (
    <ModalPadraoChakra
      isCentered={isLargerThan900}
      size={isLargerThan900 ? '5xl' : 'full'}
      motionPreset="none"
      {...rest}
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={false}
    >
      <ModalContent bg="gray.50">
        <ModalHeader pt="16px" pb="0" pl="24px">
          <Text color="primary.50" fontSize="20px" pb="10px">
            Redimensionar a imagem
          </Text>
        </ModalHeader>
        <ModalBody p={0}>
          <Box
            w="full"
            bg="black"
            position="relative"
            h={{ base: '70vh', md: '550px' }}
          >
            {imageValue && (
              <Cropper
                image={imageValue}
                crop={crop}
                zoom={1}
                onCropComplete={onCropComplete}
                aspect={ratio}
                onCropChange={setCrop}
              />
            )}
          </Box>
        </ModalBody>
        <ModalFooter
          as={Flex}
          flexDirection={{ base: 'column', md: 'row' }}
          justifyContent="center"
          alignItems="center"
          gap={{ base: 2, md: 6 }}
        >
          <Button
            variant="outlineDefault"
            minW="min-content"
            colorScheme="gray"
            width={{ base: 'full', sm: '120px' }}
            onClick={onClose}
          >
            Cancelar
          </Button>
          <Button
            color="white"
            variant="solid"
            colorScheme="aquamarine.500"
            isDisabled={!imageValue}
            minW="min-content"
            width={{ base: 'full', sm: '160px' }}
            onClick={() => {
              handleCroppedImage();
            }}
          >
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </ModalPadraoChakra>
  );
});
