import React, {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import auth from 'modules/auth';
import ConstanteRotas from 'constants/rotas';
import { obterMetaGeral } from 'helpers/data/getMetaGeral';

import { MetaGeralProps } from 'pages/Vendas/MetasComissoes/Types/validationsForm';

type RegraPagamentoResponse = {
  id: string;
  regraPagamentoComissao: number;
};

type ValorMeta = {
  id: string;
  valorMetaGeral: number;
};

interface MetasComissoesEtapasContextProps {
  activeStep: number;
  handleExcluirConfiguracaoMetasComissoes(): Promise<void>;
  lojaId: string;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  obterValorMetaGeral: () => Promise<void>;
  setActiveStep: Dispatch<SetStateAction<number>>;
  setObterValorMetaLoja: React.Dispatch<
    React.SetStateAction<ValorMeta | undefined>
  >;
  animacaoLoading: () => void;
  atualizarValoresRegraPagamento: () => Promise<string>;
  obterValorMetaLoja: ValorMeta | undefined;
  metaGeral: MetaGeralProps | undefined;
}

export const MetasComissoesEtapasContext = createContext<MetasComissoesEtapasContextProps>(
  {} as MetasComissoesEtapasContextProps
);

interface MetasComissoesEtapasProviderProps {
  children: React.ReactNode;
}

export function MetasComissoesEtapasProvider({
  children,
}: MetasComissoesEtapasProviderProps): JSX.Element {
  const [activeStep, setActiveStep] = useState(0);

  const [obterValorMetaLoja, setObterValorMetaLoja] = useState<ValorMeta>();
  const [metaGeral, setMetaGeral] = useState<MetaGeralProps>();

  const [isLoading, setIsLoading] = useState(false);

  const { id: lojaId } = auth.getLoja();
  const history = useHistory();

  const obterValorMetaGeral = useCallback(async () => {
    const response = await obterMetaGeral();

    if (response.id) {
      setObterValorMetaLoja(response);
      setMetaGeral(response);
    }
  }, []);

  const atualizarValoresRegraPagamento = useCallback(async () => {
    setIsLoading(true);
    if (metaGeral?.dataHoraUltimaAlteracao) {
      const response = await api.get<void, ResponseApi<RegraPagamentoResponse>>(
        ConstanteEnderecoWebservice.OBTER_CADASTRO_METAS_COMISSAO_REGRA_REPASSE,
        {
          params: {
            periodo: metaGeral?.dataHoraUltimaAlteracao,
          },
        }
      );
      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
          setIsLoading(false);
        }

        if (response.sucesso && response.dados) {
          setIsLoading(false);
          return String(response.dados.regraPagamentoComissao);
        }
      }
    }
    setIsLoading(false);
    return '0';
  }, [metaGeral]);

  async function handleExcluirConfiguracaoMetasComissoes() {
    setIsLoading(true);
    const response = await api.delete<
      void,
      ResponseApi<RegraPagamentoResponse>
    >(ConstanteEnderecoWebservice.EXCLUIR_CONFIGURACAO_METAS_COMISSOES, {
      params: {
        id: obterValorMetaLoja?.id,
      },
    });
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
        setIsLoading(false);
      }

      if (response.sucesso) {
        history.push(ConstanteRotas.METAS_E_COMISSOES);
      }
    }
    setIsLoading(false);
  }

  const animacaoLoading = useCallback(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [setIsLoading]);

  useEffect(() => {
    obterValorMetaGeral();
  }, [obterValorMetaGeral]);

  return (
    <MetasComissoesEtapasContext.Provider
      value={{
        activeStep,
        metaGeral,
        isLoading,
        animacaoLoading,
        setIsLoading,
        atualizarValoresRegraPagamento,
        handleExcluirConfiguracaoMetasComissoes,
        lojaId,
        obterValorMetaGeral,
        setObterValorMetaLoja,
        obterValorMetaLoja,
        setActiveStep,
      }}
    >
      {children}
    </MetasComissoesEtapasContext.Provider>
  );
}

export function useMetasComissoesEtapasContext(): MetasComissoesEtapasContextProps {
  const context = useContext(MetasComissoesEtapasContext);

  if (!context)
    throw new Error(
      'useMetasComissoesEtapasContext must be used within a MetasComissoesEtapasProvider.'
    );

  return context;
}
