import React, { memo } from 'react';
import { Controller } from 'react-hook-form';
import { HStack, Radio, RadioGroup, RadioGroupProps } from '@chakra-ui/react';

interface RadioButtonProps extends RadioGroupProps {
  name: string;
  defaultValue?: string;
  control?: any;
  children: any;
}

const RadioButtonPadrao = ({
  name,
  defaultValue,
  control,
  children,
  ...rest
}: RadioButtonProps) => (
  <Controller
    defaultValue={defaultValue || '0'}
    render={({ field }) => (
      <RadioGroup
        id={name}
        name={name}
        value={field.value}
        onChange={(value) => field.onChange(value)}
        {...rest}
      >
        <HStack>{children}</HStack>
      </RadioGroup>
    )}
    name={`${name}` as const}
    control={control}
  />
);

export default memo(RadioButtonPadrao);
