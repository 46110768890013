import React, { useEffect, useState, useCallback } from 'react';
import { VStack, Text, Box } from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import ButtonSubmit from 'components/Autenticacao/Button/Submit';
import Loading from 'components/Layout/Loading/LoadingPadrao';
import Layout from 'components/Autenticacao/Layout';

import DownloadDocumentosComponent from '../DownloadDocumentosComponent';
import { exportarDocumentoFiscal, ProcessandoDownloadProps } from '../hooks';

type TParams = { id: string; hashIdentificador: string };

interface DocumentoFiscalExportacaoProps {
  periodo: string;
  razaoSocial: string;
  nomeFantasia: string;
  modelosFiscais: string[];
  nomeArquivoCompactadoDownload: string;
}

const DownloadExterno = () => {
  const { id: idRota, hashIdentificador } = useParams<TParams>();

  const history = useHistory();

  const [isLoadingPage, setLoadingPage] = useState(false);
  const [processandoDownload, setProcessandoDownload] = useState(false);
  const [downloadData, setDownloadData] = useState<ProcessandoDownloadProps>();
  const [
    documentoFiscalExportacao,
    setDocumentoFiscalExportacao,
  ] = useState<DocumentoFiscalExportacaoProps>();

  const getDocumentoFiscalExportacao = useCallback(async () => {
    setLoadingPage(true);

    const response = await api.get<
      void,
      ResponseApi<DocumentoFiscalExportacaoProps>
    >(
      `${ConstanteEnderecoWebservice.DOCUMENTO_FISCAL_EXPORTACAO_OBTER}/${idRota}/${hashIdentificador}`
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
        history.push('/');
      }

      if (response?.sucesso && response?.dados) {
        setDocumentoFiscalExportacao(response.dados);
      }
    }

    setLoadingPage(false);
  }, [hashIdentificador, history, idRota]);

  const handleDownloadDocumento = useCallback(async () => {
    setProcessandoDownload(true);

    if (idRota && hashIdentificador && documentoFiscalExportacao) {
      const response = await exportarDocumentoFiscal({
        id: idRota,
        hashIdentificador,
        isDownloadExterno: true,
        periodo: documentoFiscalExportacao?.periodo,
      });

      if (response) {
        setDownloadData(response);
      }
    }

    setProcessandoDownload(false);
  }, [documentoFiscalExportacao, hashIdentificador, idRota]);

  useEffect(() => {
    getDocumentoFiscalExportacao();
  }, [getDocumentoFiscalExportacao]);

  return (
    <>
      {isLoadingPage && <Loading />}
      <Layout>
        <VStack w="full" spacing={2}>
          <Box
            w="full"
            py={5}
            textAlign="center"
            textColor="aquamarine.300"
            fontWeight="normal"
            fontSize={{ base: 'md', xl: 'lg' }}
          >
            <Text>Download dos arquivos fiscais</Text>
          </Box>
          <VStack
            w="full"
            py={4}
            spacing={3}
            fontSize={{ base: 'xs', md: 'sm' }}
            fontWeight="normal"
            textTransform="capitalize"
            alignItems="start"
          >
            <Box color="white">
              <Text color="aquamarine.50">Empresa:</Text>
              <Text>
                {documentoFiscalExportacao?.nomeFantasia}
                <br />
                {documentoFiscalExportacao?.razaoSocial}
              </Text>
            </Box>
            <Box color="aquamarine.50">
              Período:
              <Text color="white">{documentoFiscalExportacao?.periodo}</Text>
            </Box>
            <Box color="aquamarine.50">
              Arquivos:
              <Text color="white">
                {documentoFiscalExportacao?.modelosFiscais &&
                  ` ${documentoFiscalExportacao?.modelosFiscais?.join('/')}`}
              </Text>
            </Box>
          </VStack>
          {processandoDownload ? (
            <DownloadDocumentosComponent
              textColor="secondary.300"
              processingDownload={processandoDownload}
              waitingResponseDownload={downloadData?.waitingResponseDownload}
              downloadPercentage={downloadData?.downloadPercentage}
              valueTotalFiles={downloadData?.valueTotalFiles}
            />
          ) : (
            <ButtonSubmit
              isLoading={processandoDownload}
              isDisabled={processandoDownload}
              onClick={handleDownloadDocumento}
            >
              Baixar arquivos fiscais
            </ButtonSubmit>
          )}
        </VStack>
      </Layout>
    </>
  );
};

export default DownloadExterno;
