import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, FormLabel, GridItem, Flex } from '@chakra-ui/react';

import { optionsFormaEmissaoNfe } from 'constants/enum/enumFormaEmissaoNfe';
import { optionValidadorPlEvento } from 'constants/enum/enumValidadorXmlPlEvento';
import { optionValidadorPlEpec } from 'constants/enum/enumValidadorPlEpec';
import { optionsNfePlNotaFiscal } from 'constants/enum/enumValidadorXmlPlNfe';

import { TextArea } from 'components/update/TextArea';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import { Switch } from 'components/update/Switch';
import { NumberInput } from 'components/update/Input/NumberInput';
import Input from 'components/PDV/Input';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { InfoTooltip } from 'components/update/Tooltip/InfoTooltip';

import { Container } from '../../components/Container';
import { optionsVersaoSistema, FormData } from '../validationForms';
import { useConfiguracao } from '../hooks';

export const ConfiguracaoItem = () => {
  const formMethods = useFormContext<FormData>();

  const { getConfiguracao } = useConfiguracao(formMethods);

  useEffect(() => {
    getConfiguracao();
  }, [getConfiguracao]);

  return (
    <SimpleGridForm mt="0">
      <Container colSpan={[12, 6, 6]} title="Vendas">
        <SimpleGridForm w="full">
          <GridItem colSpan={12}>
            <Flex>
              <Box mr="10px">
                <Switch
                  size="md"
                  label=""
                  id="vendaObrigatorioCPF"
                  name="vendaObrigatorioCPF"
                />
              </Box>
              <Flex>
                <FormLabel>
                  Obrigatório informar o CPF do cliente nas vendas
                </FormLabel>
                <InfoTooltip
                  p="10px"
                  borderRadius="6px"
                  label="O sistema irá solicitar que seja informado o cpf do cliente na venda e irá considerar este campo como obrigatório, não permitindo que a venda seja finalizada sem que o cpf seja informado."
                />
              </Flex>
            </Flex>
          </GridItem>
          <GridItem colSpan={12}>
            <Flex>
              <Box mr="10px">
                <Switch
                  size="md"
                  label=""
                  id="utilizarVendPDV"
                  name="utilizarVendPDV"
                />
              </Box>
              <Flex>
                <FormLabel>Solicitar o vendedor ao iniciar a venda</FormLabel>
                <InfoTooltip
                  p="10px"
                  borderRadius="6px"
                  label="O PDV irá solicitar que seja informado o vendedor sempre que for iniciar uma nova venda. Se a opção estiver desativada, o pdv irá considerar o vendedor que está relacionado ao usuário que fez login."
                />
              </Flex>
            </Flex>
          </GridItem>
          <GridItem colSpan={12}>
            <Flex>
              <Box mr="10px">
                <Switch
                  size="md"
                  id="vendaSolicitarCpf"
                  name="vendaSolicitarCpf"
                />
              </Box>
              <Flex>
                <FormLabel>
                  Solicitar CPF automaticamente ao iniciar a Venda
                </FormLabel>
                <InfoTooltip
                  p="10px"
                  borderRadius="6px"
                  label="Sempre que for iniciada uma nova venda o PDV irá solicitar que seja informado o CPF do cliente, porém é possível concluir a venda sem que o cpf seja informado, basta deixar o campo em branco."
                />
              </Flex>
            </Flex>
          </GridItem>

          <GridItem colSpan={12}>
            <Flex>
              <Box mr="10px">
                <Switch
                  size="md"
                  id="considerarCodigoBarraBalanca"
                  name="considerarCodigoBarraBalanca"
                />
              </Box>

              <Flex>
                <FormLabel>
                  Identificar códigos de barras gerados por balança etiquetadora
                </FormLabel>
                <InfoTooltip
                  p="10px"
                  borderRadius="6px"
                  label="O sistema irá considerar que os códigos de barras de 13 dígitos iniciados pelo dígito 2 são códigos de etiquetas emitidas  por uma balança e irá aplicar o tratamento para extrair o valor e o produto vendido."
                />
              </Flex>
            </Flex>
          </GridItem>
          <GridItem colSpan={12}>
            <Flex>
              <Box mr="10px">
                <Switch
                  size="md"
                  id="aceitarCodigoInternoPDV"
                  name="aceitarCodigoInternoPDV"
                />
              </Box>
              <Flex>
                <FormLabel>Permitir digitar código interno</FormLabel>
                <InfoTooltip
                  p="10px"
                  borderRadius="6px"
                  label="Com esta opção ativa, é possível lançar um produto no pdv através de seu código identificador no sistema. Se a opção estiver desabilitada, o sistema irá considerar somente códigos de barras com 8 digitos ou mais para o lançamento de produtos."
                />
              </Flex>
            </Flex>
          </GridItem>
          <GridItem colSpan={12}>
            <NumberInput
              label="Quantidade de dígitos do código do produto na etiqueta de balança"
              id="qtdDigCodProdBalanca"
              scale={0}
              helperTextLabel="As balanças etiquetadoras possuem uma configuração que determina a quantidade de dígitos que são reservados na etiqueta para identificar o código do produto. Este campo informa a quantidade de dígitos que o sistema irá considerar para identificar corretamente o produto quando realiza a leitura de uma etiqueta gerada pela balança."
              name="qtdDigCodProdBalanca"
            />
          </GridItem>
          <GridItem
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            colSpan={12}
          >
            <NumberInput
              name="limiteQuantidadeVenda"
              scale={0}
              id="limiteQuantidadeVenda"
              label="Qtd. Máx. Itens lançados de uma vez"
            />
          </GridItem>

          <GridItem
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            colSpan={12}
          >
            <Box mb="24px">
              <NumberInput
                scale={0}
                name="diasBloqVenda"
                id="diasBloqVenda"
                label="Dias de atraso"
              />
            </Box>
            <Flex>
              <Box mr="10px">
                <Switch
                  size="md"
                  id="bloquearCliAtraso"
                  name="bloquearCliAtraso"
                />
              </Box>
              <FormLabel>Bloquear no cadastro de cliente</FormLabel>
            </Flex>
          </GridItem>
        </SimpleGridForm>
      </Container>

      <Container colSpan={[12, 6, 6]} title="Configurações gerais">
        <SimpleGridForm w="full">
          <GridItem colSpan={12}>
            <Flex>
              <Box mr="10px">
                <Switch
                  size="md"
                  id="pdvManterFocoNaTela"
                  name="pdvManterFocoNaTela"
                />
              </Box>
              <Flex>
                <FormLabel>Manter a tela do PDV sempre à frente</FormLabel>
                <InfoTooltip
                  p="10px"
                  borderRadius="6px"
                  label="Com esta opção ativa, a tela do pdv ficará sempre á frente das outras janelas e disponível para utilização. Se o usuário abri outro programa, a tela do PDV ficará à frente e com prioridade."
                />
              </Flex>
            </Flex>
          </GridItem>
          <GridItem colSpan={12}>
            <Flex>
              <Box mr="10px">
                <Switch
                  size="md"
                  id="exibirDescansoDeTelaNoPDV"
                  name="exibirDescansoDeTelaNoPDV"
                />
              </Box>
              <Flex>
                <FormLabel>Usar Descanso de Tela no PDV</FormLabel>
                <InfoTooltip
                  p="10px"
                  borderRadius="6px"
                  label="Nos casos de inatividade do pdv, o sistema entra em uma tela de espera informando que o caixa está livre ou fechado. É possível utilizar algumas imagens promocionais que são exibidas durante este período de inatividade. Para isso, basta adicionar estas imagens neste caminho. (C:\STi3\imagens\promocional). Este caminho pode ser um pouco diferente dependendo da instalação do PDV Offline."
                />
              </Flex>
            </Flex>
          </GridItem>
          <GridItem colSpan={12}>
            <Flex justifyContent="left" alignItems="center">
              <Box mr="10px">
                <Switch
                  size="md"
                  id="decimaisDireitaPDV"
                  name="decimaisDireitaPDV"
                />
              </Box>
              <Flex>
                <FormLabel>
                  Utilizar os campos de valor com a vírgula fixa
                </FormLabel>
                <InfoTooltip
                  p="10px"
                  borderRadius="6px"
                  label={
                    <Box>
                      <Box mb="3px">
                        Com esta opção ativa, o sistema irá manter a vírgula e a
                        quantidade de casas decimais fixas, e conforme os
                        caracteres vão sendo digitados, o campo será preenchido
                        da esquerda para direita. Ex: para informar um valor de
                        1,59:
                      </Box>
                      <Box>
                        {`digita "1" - o campo fica preenchido com 0,01 digita "5"
                        - o campo fica preenchido com 0,15 digita "9" - o campo
                        fica preenchido com 1,59`}
                      </Box>
                    </Box>
                  }
                />
              </Flex>
            </Flex>
          </GridItem>
          <GridItem colSpan={12}>
            <NumberInput
              label="Tempo de espera após finalizar venda (segundos)"
              id="timerAposVendaPDV"
              scale={0}
              name="timerAposVendaPDV"
            />
          </GridItem>

          <GridItem colSpan={12}>
            <SelectPadrao
              name="versaoDadosSAT"
              options={optionsVersaoSistema}
              id="versaoDadosSAT"
              placeholder="Selecione"
              label="Versão layout SAT"
            />
          </GridItem>

          <GridItem colSpan={12}>
            <NumberInput
              label="Código do produto genérico"
              id="idProdutoGenerico"
              scale={0}
              helperTextLabel="Em alguns casos de etiquetas emitidas por balança, o produto não é identificado, a etiqueta traz somente a informação do valor a ser cobrado. Quando este código é lançado no pdv, é necessário identificar um produto para assumir este valor. Para estes casos, o sistema assume o código do produto informado neste campo."
              name="idProdutoGenerico"
            />
          </GridItem>
        </SimpleGridForm>
      </Container>

      <Container colSpan={12} title="Caixa">
        <SimpleGridForm w="full">
          <GridItem colSpan={[12, 12, 12]}>
            <NumberInput
              editarFundoLeftElemento
              leftElementColor="gray.700"
              bgLeftElement="gray.50"
              leftElement="R$"
              label="Realizar sangria após o saldo em dinheiro atingir:"
              id="sangriaAposDinheiroPDV"
              name="sangriaAposDinheiroPDV"
              helperTextLabel="Quando o valor de dinheiro acumulado no caixa ultrapassar o valor informado, o PDV solicita que seja feito um lançamento de sangria para retirada deste valor do caixa."
            />
          </GridItem>
        </SimpleGridForm>
      </Container>

      <Container colSpan={[12, 6, 6]} title="Informações da impressão">
        <SimpleGridForm w="full">
          <GridItem colSpan={12}>
            <TextArea
              name="cabecalhoCupom"
              id="cabecalhoCupom"
              placeholder="Informe o texto que sera impresso no cabeçalho do cupom não fiscal"
              label="Cabeçalho cupom não fiscal"
              w="full"
            />
          </GridItem>
          <GridItem colSpan={12}>
            <TextArea
              w="full"
              name="rodapeCupom"
              id="rodapeCupom"
              placeholder="Informe o valor do rodapé"
              label="Rodapé cupom não fiscal"
            />
          </GridItem>
        </SimpleGridForm>
      </Container>

      <Container colSpan={[12, 6, 6]} title="Informações de revenda">
        <TextArea
          name="rodapeCupomParceiro"
          helperTextLabel="O texto informado neste campo deve ter no máximo 45 caracteres e será impresso no final da impressão sem validade fiscal em uma única linha."
          id="rodapeCupomParceiro"
          placeholder="Informe o texto da impressão"
          maxLength={45}
          label="Texto que será impresso no rodapé do cupom sem validade fiscal"
        />
      </Container>

      <Container colSpan={12} title="Impressões">
        <SimpleGridForm>
          <GridItem colSpan={[12, 12, 4]}>
            <Flex>
              <Box mr="10px">
                <Switch
                  size="md"
                  id="mostrarLimitesRelVendas"
                  name="mostrarLimitesRelVendas"
                />
              </Box>
              <Flex>
                <FormLabel>
                  Imprimir saldo devedor e limite de crédito do cliente
                </FormLabel>
                <InfoTooltip
                  p="10px"
                  borderRadius="6px"
                  label="Quando esta opção está ativa, o pdv irá consultar o saldo devedor e o limite de crédito do cliente no servidor e irá exibir estas informações na impressão sem validade fiscal."
                />
              </Flex>
            </Flex>
          </GridItem>
          <GridItem colSpan={[12, 12, 4]}>
            <Flex>
              <Box mr="10px">
                <Switch
                  size="md"
                  id="impressaoPorItensPDV"
                  name="impressaoPorItensPDV"
                />
              </Box>
              <Flex>
                <FormLabel>
                  imprimir fichas individuais dos itens da venda
                </FormLabel>
                <InfoTooltip
                  p="10px"
                  borderRadius="6px"
                  label="Quando esta opção está ativa, o sistema imprime uma ficha para de cada item vendido. As fichas são individuais e são emitidas conforme as quantidades lançadas na venda. Esta opção é utilizada em casos onde é realizada uma pré-venda e o cliente não necessita retirar todos os produtos de uma só vez, ou mesmo quando os produtos são retirados em locais diferentes."
                />
              </Flex>
            </Flex>
          </GridItem>
          <GridItem colSpan={[12, 12, 4]}>
            <Flex justifyContent="center" alignItems="center">
              <Box mr="10px">
                <Switch
                  size="md"
                  id="imprimirViaCupomNaoFiscal"
                  name="imprimirViaCupomNaoFiscal"
                />
              </Box>
              <Flex>
                <FormLabel>
                  Imprimir via de cupom não fiscal após a impressão do cupom
                  fiscal
                </FormLabel>
                <InfoTooltip
                  p="10px"
                  borderRadius="6px"
                  label="O sistema sempre emite apenas a via do cupom fiscal. Com esta configuração, após a impressão do cupom fiscal, o sistema irá imprimir também uma via de cupom sem validade fiscal."
                />
              </Flex>
            </Flex>
          </GridItem>
          <GridItem colSpan={[12, 12, 4]}>
            <Flex justifyContent="center" alignItems="center">
              <Box mr="10px">
                <Switch
                  size="md"
                  id="agruparItensImpressaoVenda"
                  name="agruparItensImpressaoVenda"
                />
              </Box>
              <Flex>
                <FormLabel>
                  Agrupar itens semelhantes na impressão da Venda
                </FormLabel>
                <InfoTooltip
                  p="10px"
                  borderRadius="6px"
                  label="O sistema identifica que existem produtos iguais lançados com o mesmo preço na venda, soma as quantidades e imprime uma única vez este produto com valores totalizados.
"
                />
              </Flex>
            </Flex>
          </GridItem>
          <GridItem colSpan={[12, 12, 4]}>
            <Flex>
              <Box mr="10px">
                <Switch
                  size="md"
                  id="impressaoDescontoVenda"
                  name="impressaoDescontoVenda"
                />
              </Box>
              <Flex>
                <FormLabel>
                  Imprimir comprovante de descontos concedidos na venda
                </FormLabel>
                <InfoTooltip
                  p="10px"
                  borderRadius="6px"
                  label="O sistema imprime um comprovante identificando o número da venda, o desconto que foi concedido e o usuário que aprovou o desconto."
                />
              </Flex>
            </Flex>
          </GridItem>
          <GridItem colSpan={[12, 12, 4]}>
            <Flex>
              <Box mr="10px">
                <Switch
                  size="md"
                  id="solicitarImpressaoEcologica"
                  name="solicitarImpressaoEcologica"
                />
              </Box>
              <Flex>
                <Flex>
                  <FormLabel>Solicitar Impressão Ecológica da NFCe</FormLabel>
                  <InfoTooltip
                    p="10px"
                    borderRadius="6px"
                    label="Se esta opção estiver ativa, o sistema irá solicitar se o usuário deseja que seja realizada a impressão ecológica da NFC-e.  Neste modelo de impressão, os itens não são exibidos, o que torna a impressão mais resumida e economiza papel."
                  />
                </Flex>
              </Flex>
            </Flex>
          </GridItem>
        </SimpleGridForm>
      </Container>

      <Container colSpan={12} title="Configurações NF-e">
        <SimpleGridForm w="full">
          <GridItem colSpan={[12, 12, 6]}>
            <SelectPadrao
              name="nFeFormaEmissao"
              options={optionsFormaEmissaoNfe}
              id="nFeFormaEmissao"
              placeholder="Selecione"
              label="Forma de emissão da NF-e"
            />
          </GridItem>

          <GridItem colSpan={[12, 12, 6]}>
            <SelectPadrao
              name="nfePLEvento"
              options={optionValidadorPlEvento}
              id="nfePLEvento"
              placeholder="Selecione"
              label="Validador do XML (PL Evento)"
            />
          </GridItem>
          <GridItem colSpan={[12, 12, 6]}>
            <SelectPadrao
              name="nfePLEPEC"
              options={optionValidadorPlEpec}
              id="nfePLEPEC"
              placeholder="Selecione"
              label="Validador do XML (PL EPEC)"
            />
          </GridItem>
          <GridItem colSpan={[12, 12, 6]}>
            <SelectPadrao
              name="nfePLNotaFiscal"
              options={optionsNfePlNotaFiscal}
              id="nfePLNotaFiscal"
              placeholder="Selecione"
              label="Validador do XML (PL NF-e)"
            />
          </GridItem>
          <GridItem colSpan={[12, 12, 4]}>
            <Input
              name="serieCertificado"
              id="serieCertificado"
              placeholder="Informe a serie"
              label="Serie do certificado"
            />
          </GridItem>
          <GridItem colSpan={[12, 12, 8]}>
            <Input
              name="assinaturaSatSTi3"
              id="assinaturaSatSTi3"
              placeholder="Informe a assinatura"
              label="Assinatura Sat"
            />
          </GridItem>
        </SimpleGridForm>
      </Container>
    </SimpleGridForm>
  );
};
