import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { IdentificacaoTipoOperacao } from 'constants/enum/identificacaoTipoOperacao';
import ModelosFiscaisEnum, {
  ModelosFiscais,
} from 'constants/enum/fiscal/modelosFiscais';
import { StatusFiscais } from 'constants/enum/fiscal/statusFiscal';
import { formatarDescricaoDocumentoFiscal } from './formatarDescricaoDocumentoFiscal';

interface DocumentoFiscaResponse {
  id: string;
  modeloFiscal: ModelosFiscais;
  status: StatusFiscais;
  numero: string;
  dataEmissao: Date;
}

interface ContatoAdicional {
  email: string;
  nome: string;
  telefone: string;
}

interface Cliente {
  celular?: string;
  email?: string;
  nome: string;
  telefone?: string;
  contatosAdicionais: ContatoAdicional[];
}

interface OperacaoObterParaCompartilharResponse {
  numeroOperacao: number;
  valorTotal: number;
  identificacaoTipoOperacao: IdentificacaoTipoOperacao;
  cliente: Cliente;
  documentosFiscais: DocumentoFiscaResponse[];
  expiracaoLink: Date;
  tokenAcessoTemporario: string;
}

interface DocumentoFiscal {
  id: string;
  status: StatusFiscais;
  descricao?: string;
  numero: string;
}

export interface OperacaoObterParaCompartilhar {
  numeroOperacao: number;
  valorTotal: number;
  identificacaoTipoOperacao: IdentificacaoTipoOperacao;
  cliente: Cliente;
  NFCe?: DocumentoFiscal;
  NFe?: DocumentoFiscal;
  expiracaoLink: Date;
  tokenAcessoTemporario: string;
}

const obterOperacaoParaCompartilhar = async (
  id: string
): Promise<OperacaoObterParaCompartilhar | undefined> => {
  const response = await api.get<
    void,
    ResponseApi<OperacaoObterParaCompartilharResponse>
  >(ConstanteEnderecoWebservice.PEDIDOORCAMENTOVENDA_OBTER_PARA_COMPARTILHAR, {
    params: { id },
  });

  if (response?.avisos) {
    response.avisos.map((item: string) => toast.warning(item));
  }

  if (response?.sucesso) {
    const { documentosFiscais, ...restOfOperacao } = response.dados;

    let NFCe;
    const documentoFiscalNFCe = documentosFiscais.find(
      (documentoFiscal) =>
        documentoFiscal.modeloFiscal === ModelosFiscaisEnum.NFCe
    );
    if (documentoFiscalNFCe) {
      NFCe = {
        id: documentoFiscalNFCe.id,
        numero: documentoFiscalNFCe.numero,
        status: documentoFiscalNFCe.status,
        descricao: formatarDescricaoDocumentoFiscal({
          status: documentoFiscalNFCe.status,
          numero: documentoFiscalNFCe.numero,
          dataEmissao: documentoFiscalNFCe.dataEmissao,
        }),
      } as DocumentoFiscal;
    }

    let NFe;
    const documentoFiscalNFe = documentosFiscais.find(
      (documentoFiscal) =>
        documentoFiscal.modeloFiscal === ModelosFiscaisEnum.NFe
    );
    if (documentoFiscalNFe) {
      NFe = {
        id: documentoFiscalNFe.id,
        numero: documentoFiscalNFe.numero,
        status: documentoFiscalNFe.status,
        descricao: formatarDescricaoDocumentoFiscal({
          status: documentoFiscalNFe.status,
          numero: documentoFiscalNFe.numero,
          dataEmissao: documentoFiscalNFe.dataEmissao,
        }),
      } as DocumentoFiscal;
    }

    return { ...restOfOperacao, NFCe, NFe };
  }

  return undefined;
};

export default obterOperacaoParaCompartilhar;
