export const StatusPesquisaClientesFornecedor = {
  TODOS: 0,
  CLIENTES: 1,
  FORNECEDORES: 2,

  properties: {
    0: { name: 'Todos', value: 0 },
    1: { name: 'Clientes', value: 1 },
    2: { name: 'Fornecedores', value: 2 },
  },
};
