import React, { Fragment, useEffect, useRef } from 'react';
import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  Text,
  HStack,
  useMediaQuery,
  Tag,
} from '@chakra-ui/react';
import ShadowScrollbar, {
  ShadowScrollbarForwardRefProps,
} from 'components/PDV/Geral/ShadowScrollbar';
import { useFormContext } from 'react-hook-form';

import { OperacaoItemObter } from 'helpers/api/Operacao/obterOperacaoComItens';
import { DecimalMask, moneyMask } from 'helpers/format/fieldsMasks';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';
import useWindowSize from 'helpers/layout/useWindowSize';
import { setTimeout } from 'timers';
import Totalizadores from './Totalizadores';
import MenuItemOpcoes from './MenuItemOpcoes';

const ListarItens = () => {
  const { watch } = useFormContext();
  const operacaoItens = watch('operacaoItens') as OperacaoItemObter[];
  const shadowScrollbarRef = useRef<ShadowScrollbarForwardRefProps>(null);
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const { casasDecimais } = usePadronizacaoContext();
  const { height: windowHeight } = useWindowSize();

  useEffect(() => {
    setTimeout(() => {
      if (shadowScrollbarRef.current)
        shadowScrollbarRef.current.handleUpdateScrollbar();
    }, 200);
  }, [operacaoItens]);

  const TableDados = () => (
    <Table>
      <Tbody>
        {operacaoItens.map((operacaoItem: OperacaoItemObter) => (
          <Fragment key={operacaoItem.id}>
            <Tr
              sx={
                operacaoItem.informacoesComplementares
                  ? {
                      '& > td': { border: 'none', pb: '0' },
                    }
                  : {}
              }
            >
              <Td w={isLargerThan900 ? '55%' : '80%'} pl={0} pr={2} py={1}>
                <VStack alignItems="flex-start" spacing={0}>
                  <Flex m={0}>
                    <Text
                      textColor="gray.700"
                      fontWeight="semibold"
                      fontSize="sm"
                      noOfLines={2}
                    >
                      {operacaoItem.produto}
                    </Text>
                  </Flex>
                  <HStack>
                    {operacaoItem.cor && (
                      <Tag
                        colorScheme="teal"
                        bg="teal.600"
                        color="white"
                        borderRadius="full"
                        size="sm"
                      >
                        {operacaoItem.cor}
                      </Tag>
                    )}

                    {operacaoItem.tamanho && (
                      <Tag
                        colorScheme="pink"
                        bg="pink.700"
                        color="white"
                        borderRadius="full"
                        size="sm"
                      >
                        {operacaoItem.tamanho}
                      </Tag>
                    )}
                  </HStack>
                </VStack>
              </Td>
              {isLargerThan900 && (
                <>
                  <Td
                    w="10%"
                    fontWeight="semibold"
                    color="gray.700"
                    pl={0}
                    pr={2}
                    py={1}
                    isNumeric
                  >
                    {DecimalMask(
                      operacaoItem.quantidade,
                      casasDecimais.casasDecimaisQuantidade
                    )}
                  </Td>
                  <Td w="10%" pl={0} pr={2} py={1} isNumeric>
                    <VStack spacing={0} alignItems="flex-end">
                      <Text fontWeight="semibold" color="gray.700">
                        {DecimalMask(
                          operacaoItem.valorUnitario,
                          casasDecimais.casasDecimaisValor
                        )}
                      </Text>
                    </VStack>
                  </Td>
                  <Td w="10%" pl={0} pr={2} py={1} isNumeric>
                    <VStack spacing={0} alignItems="flex-end">
                      <Text fontWeight="semibold" color="red.500">
                        {operacaoItem.valorDescontoItem > 0 ? '-' : null}
                        {DecimalMask(operacaoItem.valorDescontoItem, 2, 2)}
                      </Text>
                    </VStack>
                  </Td>
                </>
              )}
              <Td w="10%" pl={0} pr={isLargerThan900 ? 2 : 0} isNumeric>
                <Text fontWeight="semibold" color="blue.500">
                  {moneyMask(operacaoItem.valorItemComDesconto, false)}
                </Text>
              </Td>
              <Td w="5%" minW="40px" textAlign="right" color="gray.900" p={0}>
                <MenuItemOpcoes operacaoItem={operacaoItem} />
              </Td>
            </Tr>

            {operacaoItem.informacoesComplementares && (
              <Tr>
                <Td colSpan={6} p="0">
                  <Text noOfLines={1}>
                    {operacaoItem.informacoesComplementares}
                  </Text>
                </Td>
              </Tr>
            )}
          </Fragment>
        ))}
      </Tbody>
    </Table>
  );

  return operacaoItens && operacaoItens.length > 0 ? (
    <Box
      bg="gray.50"
      pr={{ base: 6, md: 12 }}
      pl={6}
      pt={isLargerThan900 ? 6 : 0}
      w="full"
      height="fit-content"
    >
      <Box
        bg="white"
        w="100%"
        height="fit-content"
        borderRadius="md"
        pl={5}
        py={1}
        boxShadow="base"
      >
        <Box pr={5}>
          <Table>
            <Thead>
              <Tr>
                <Th
                  w={isLargerThan900 ? '55%' : '80%'}
                  pl={0}
                  pr={2}
                  pb={1}
                  fontSize="2xs"
                  color="gray.400"
                >
                  Descrição
                </Th>
                {isLargerThan900 && (
                  <>
                    <Th w="10%" pl={0} pr={2} pb={1} isNumeric>
                      <Text fontSize="2xs" color="gray.400">
                        Qtde.
                      </Text>
                    </Th>
                    <Th w="10%" pl={0} pr={2} pb={1} isNumeric>
                      <Text fontSize="2xs" color="gray.400" whiteSpace="nowrap">
                        Valor un.
                      </Text>
                    </Th>
                    <Th w="10%" pl={0} pr={2} pb={1} isNumeric>
                      <Text fontSize="2xs" color="gray.400" whiteSpace="nowrap">
                        Desconto
                      </Text>
                    </Th>
                  </>
                )}
                <Th
                  w="10%"
                  pl={0}
                  pr={isLargerThan900 ? 2 : 0}
                  pb={1}
                  isNumeric
                >
                  <Text fontSize="2xs" color="gray.400" whiteSpace="nowrap">
                    Valor total
                  </Text>
                </Th>
                {isLargerThan900 && <Th w="5%" p={0} />}
              </Tr>
            </Thead>
          </Table>
        </Box>

        <ShadowScrollbar
          width="100%"
          maxHeight={windowHeight - 380}
          paddingTop="0"
          shadowTopStyle={{
            background:
              'transparent linear-gradient(180deg, var(--white)  0%,  #FFFFFF00 100%) 0% 0% no-repeat padding-box',
            height: 30,
          }}
          shadowBottomStyle={{
            background:
              'transparent linear-gradient(180deg, #FFFFFF00 0%, var(--white) 100%) 0% 0% no-repeat padding-box',
            height: 30,
          }}
          ref={shadowScrollbarRef}
        >
          {operacaoItens && (
            <Box pr={3}>
              <TableDados />
            </Box>
          )}
        </ShadowScrollbar>

        <Box pr={5}>
          <Totalizadores />
        </Box>
      </Box>
    </Box>
  ) : (
    <></>
  );
};

export default ListarItens;
