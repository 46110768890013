import { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import { shallowEqual } from 'helpers/validation/shallowEqual';

import { PagedTableForwardRefData } from 'components/update/Table/PagedTable';
import { PaginationData } from 'components/update/Pagination';
import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';

export type IntermediadorProps = {
  id: string;
  ativo: boolean;
  cnpj: string;
  dataHoraCadastro: string;
  dataHoraUltimaAlteracao: string;
  identificacaoVendedor: string | null;
  identificador: string;
};

export type FiltrosProps = { identificador: string; ativo: boolean };

const useIntermediadorListar = () => {
  const history = useHistory();
  const pagedTableRef = useRef<PagedTableForwardRefData>(null);

  const [filtros, setFiltros] = useState({
    identificador: '',
    ativo: true,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [intermediadorLista, setIntermediadorLista] = useState<
    IntermediadorProps[]
  >([]);

  const alterarIntermediador = (intermediadorId: string) => {
    history.push(
      SubstituirParametroRota(
        ConstanteRotas.FRENTE_CAIXA_INTERMEDIADOR_ALTERAR,
        'id',
        intermediadorId
      )
    );
  };

  const cadastrarIntermediador = () => {
    history.push(ConstanteRotas.FRENTE_CAIXA_INTERMEDIADOR_CADASTRAR);
  };

  const removerIntermediador = (intermediadorId: string) => {
    ModalConfirmacaoExcluir({
      callback: async (confirmado: boolean) => {
        if (!confirmado) {
          return;
        }

        setIsLoading(true);

        const response = await api.delete<void, ResponseApi>(
          ConstanteEnderecoWebservice.FRENTE_CAIXA_INTERMEDIADOR_EXCLUIR,
          {
            params: { id: intermediadorId },
          }
        );

        if (response) {
          if (response?.avisos) {
            response.avisos.forEach((aviso) => toast.warning(aviso));
          }
          if (response?.sucesso) {
            toast.success('Intermediador removido com sucesso');
            await pagedTableRef?.current?.reload();
          }
        }

        setIsLoading(false);
      },
    });
  };

  const obterIntermediadorListaPaginada = useCallback(
    async (paginationData: PaginationData) => {
      setIsLoading(true);

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<IntermediadorProps>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.FRENTE_CAIXA_INTERMEDIADOR_LISTAR_PAGINADO,
          paginationData
        ),
        {
          params: { ...filtros },
        }
      );

      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response?.sucesso && response?.dados) {
          setTotalRegistros(response.dados?.total);
          setIntermediadorLista(response.dados?.registros);
        }
      }

      setIsLoading(false);
    },
    [filtros]
  );

  const atualizarFiltros = (filtrosForm: FiltrosProps) => {
    if (!shallowEqual(filtrosForm, filtros)) {
      setFiltros(filtrosForm);
    }
  };

  return {
    atualizarFiltros,
    obterIntermediadorListaPaginada,
    alterarIntermediador,
    removerIntermediador,
    cadastrarIntermediador,
    isLoading,
    totalRegistros,
    intermediadorLista,
    pagedTableRef,
  };
};

export { useIntermediadorListar };
