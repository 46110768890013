import styled from 'styled-components';
import { Card as BootstrapCard } from 'react-bootstrap';

export const Card = styled(BootstrapCard)`
  border-radius: 5px !important;
  box-shadow: none;

  & .card-body {
    padding: 0 !important;

    @media (min-width: 900px) {
      padding: 0 25px;
    }
    @media (min-width: 1200px) {
      padding: 0 30px;
    }
    @media (min-width: 1800px) {
      padding: 0 35px;
    }
  }
`;

export const Table = styled.table`
  width: 100%;
  background: transparent;

  tbody tr + tr {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: calc(100% - 40px);
      top: 0;
      left: 0;
      margin-left: 20px;

      border-top: 1px solid var(--sti-ck-colors-gray-100);
    }
  }

  tbody tr td {
    padding: 0 10px;

    &:first-child {
      padding-left: 30px;
    }

    &:last-child {
      padding-right: 30px;
    }

    text-overflow: ellipsis;
    white-space: nowrap;

    /* @media (min-width: 700px) */
    height: 32px;

    @media (min-width: 900px) {
      height: 35px;
    }
    @media (min-width: 1200px) {
      height: 40px;
    }

    @media (min-width: 1800px) {
      height: 42px;
    }
  }

  tfoot tr td {
    padding: 0 10px;

    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const TableBody = styled.tbody``;

export const TableFoot = styled.tfoot``;

export const EmptyValuesText = styled.div`
  display: flex;
  align-items: center;

  padding: 0 30px;

  height: 32px;

  @media (min-width: 900px) {
    height: 35px;
  }
  @media (min-width: 1200px) {
    height: 40px;
  }

  @media (min-width: 1800px) {
    height: 42px;
  }
`;
