import { cpfMask, cnpjMask } from './fieldsMasks';

export const formattedValueCpfCnpj = (
  value: string,
  asCpf?: boolean,
  asCnpj?: boolean
) => {
  if (asCpf) return cpfMask(value);
  if (asCnpj) return cnpjMask(value);

  const unformattedValue = value.replace(/\D/g, '');

  if (unformattedValue.length <= 11) {
    return cpfMask(value);
  }

  return cnpjMask(value);
};
