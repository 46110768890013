import * as yup from 'yup';
import { useForm as useFormInstance } from 'react-hook-form';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import GenerosEnum from 'constants/enum/generos';

export const defaultValues = {
  foto: null,
  nome: '',
  telefone: '',
  email: '',
  genero: GenerosEnum.NAO_INFORMADO,
  dataNascimento: null,
  lojaPadraoId: '',
  abrirPdvAposLogin: null,
  ativo: false,
  login: '',
  senha: null,
  confirmesenha: null,
  pinUsuario: null,
};

const schema = yup.object().shape({
  nome: yup
    .string()
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  login: yup
    .string()
    .nullable()
    .matches(/^(?!.*\s).*$/, ConstanteMensagemValidacao.CAMPO_SEM_ESPACO)
    .matches(
      /^(?!.*(\W|\d|_)).\D*$/,
      ConstanteMensagemValidacao.CAMPO_COM_CARACTERE_ESPECIAL
    )
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  senha: yup
    .string()
    .nullable()
    .test(
      'senha',
      ConstanteMensagemValidacao.SENHA_UMA_LETRA_MINUSCULA,
      (value) => {
        if (value) {
          const testSchema = yup
            .string()
            .matches(
              /(?=.*[a-z])/,
              ConstanteMensagemValidacao.SENHA_UMA_LETRA_MINUSCULA
            );

          return testSchema.isValidSync(value);
        }
        return true;
      }
    )
    .test(
      'senha',
      ConstanteMensagemValidacao.SENHA_UMA_LETRA_MAIUSCULA,
      (value) => {
        if (value) {
          const testSchema = yup
            .string()
            .matches(
              /(?=.*[A-Z])/,
              ConstanteMensagemValidacao.SENHA_UMA_LETRA_MAIUSCULA
            );

          return testSchema.isValidSync(value);
        }
        return true;
      }
    )
    .test(
      'senha',
      ConstanteMensagemValidacao.SENHA_UM_CARACTER_NUMERICO,
      (value) => {
        if (value) {
          const testSchema = yup
            .string()
            .matches(
              /(?=.*[0-9])/,
              ConstanteMensagemValidacao.SENHA_UM_CARACTER_NUMERICO
            );

          return testSchema.isValidSync(value);
        }
        return true;
      }
    )
    .test(
      'senha',
      ConstanteMensagemValidacao.SENHA_UM_CARACTER_ESPECIAL,
      (value) => {
        if (value) {
          const testSchema = yup
            .string()
            .matches(
              /(?=.*[!@#$%^&*])/,
              ConstanteMensagemValidacao.SENHA_UM_CARACTER_ESPECIAL
            );

          return testSchema.isValidSync(value);
        }
        return true;
      }
    )
    .test(
      'senha',
      ConstanteMensagemValidacao.SENHA_MINIMO_SEIS_CARACTERES,
      (value) => {
        if (value) {
          const testSchema = yup
            .string()
            .matches(
              /(?=.{6,50}$)/,
              ConstanteMensagemValidacao.SENHA_MINIMO_SEIS_CARACTERES
            );

          return testSchema.isValidSync(value);
        }
        return true;
      }
    ),
  genero: yup
    .string()
    .nullable()
    .default('3')
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  email: yup
    .string()
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .email(ConstanteMensagemValidacao.EMAIL_INVALIDO),
  confirmesenha: yup
    .string()
    .nullable()
    .oneOf([yup.ref('senha')], ConstanteMensagemValidacao.SENHA_NAO_COINCIDEM),
  ativo: yup.boolean().default(false),
  dataNascimento: yup.string().nullable(),
  telefone: yup
    .string()
    .nullable()
    .notRequired()
    .test('telefone', ConstanteMensagemValidacao.TELEFONE_INVALIDO, (value) => {
      if (value) {
        const testSchema = yup.string().min(14).max(15);

        return testSchema.isValidSync(value);
      }

      return true;
    }),
  tipoUsuario: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  descontoMaximoPermitido: yup.number(),
});

export const yupResolver = yupResolverInstance(schema);

export const useForm = useFormInstance;
