import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export const formDefaultValues = {
  alturaEtiqueta: 5,
  comprimentoEtiqueta: 3,
  margemSuperior: 0,
  margemEsquerda: 0,
  escolherCor: '',
  alterarTamanhoFonte: 9,
  numeroLinhas: 1,
  numeroColunas: 1,
  espacoLinhas: 0,
  espacoColunas: 0,
  isChecked: false,
  name: '',
  quantidadeParcela: 1,
  tipoImpressora: 'Laser',
  quantidadeCaracteres: 0,
  etiquetaAtiva: true,
  tipoPreco: 'PRECO',
};

export type PropriedadesItensEtiqueta = {
  name: string;
  id: number;
  campo?: string;
  isChecked: boolean;
  tamanhoFonte: number;
  negrito?: boolean;
  tipoCodigoBarras?: string;
  sublinhado?: boolean;
  italico?: boolean;
  valorNegrito?: string;
  valorSublinhado?: string;
  valorItalico?: string;
  textoFixo?: string;
  textoFixo2?: string;
  tabelaPrecoId?: string | number;
  tipoTabelaPrecoId?: string | number;
  quantidadeParcela?: number;
  tamanhoCodigoBarras?: number;
  eixoY: number;
  eixoX: number;
  tipoLogo: string;
  eixoXemCm: number;
  eixoYemCm: number;
  quantidadeCaracteres: number;
  tipoPreco?: string;
};

export type PropertiesEtiquetas = {
  alturaEtiqueta: number;
  tipoImpressora: string;
  etiquetaAtiva: boolean;
  comprimentoEtiqueta: number;
  margemSuperior?: number;
  margemEsquerda?: number;
  numeroLinhas?: number;
  numeroColunas?: number;
  espacoColunas?: number;
  espacoLinhas?: number;
  propriedadesItensEtiqueta: PropriedadesItensEtiqueta[];
};

export type EtiquetaPersonalizadaProps = {
  alturaEtiqueta: number;
  numeroLinhas: number;
  numeroColunas: number;
  textoFixo: string;
  textoFixo2: string;
  espacoColunas: number;
  tamanhoCodigoBarras: number;
  comprimentoEtiqueta: number;
  espacoLinhas: number;
  quantidadeParcela: number;
  margemEsquerda: number;
  margemSuperior: number;
  escolherCor: string;
  isChecked?: boolean;
  alterarTamanhoFonte: number;
  tabelaPrecoId: string | number;
  tipoTabelaPrecoId: string | number;
  name?: string;
  id: number;
  fonte?: number;
  negrito?: string;
  sublinhado?: string;
  italico?: string;
  nomeEtiqueta: string;
  etiquetaAtiva: boolean;
  tipoImpressora: string;
  quantidadeCaracteres: number;
};

const schema = yup.object().shape({
  alturaEtiqueta: yup.number(),
  numeroLinhas: yup.number(),
  numeroColunas: yup.number(),
  espacoColunas: yup.number(),
  comprimentoEtiqueta: yup.number(),
  espacoLinhas: yup.number(),
  margemEsquerda: yup.number(),
  margemSuperior: yup.number(),
  isChecked: yup.boolean(),
  alterarTamanhoFonte: yup.number(),
  tabelaPrecoId: yup.string(),
  name: yup.string(),
  id: yup.number(),
  fonte: yup.number(),
  negrito: yup.string(),
  sublinhado: yup.string(),
  italico: yup.string(),
  textoFixo: yup.string(),
  textoFixo2: yup.string(),
  tamanhoCodigoBarras: yup.number(),
  etiquetaAtiva: yup
    .boolean()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  nomeEtiqueta: yup
    .string()
    .trim()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  tipoImpressora: yup
    .string()
    .trim()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);
