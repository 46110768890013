import { Flex, Text, Icon, Box, Button, useMediaQuery } from '@chakra-ui/react';
import { FiX, FiChevronLeft } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import ConstanteRotas from 'constants/rotas';
import { useSmartPosEtapasContext } from 'store/SmartPos/SmartPosEtapasContext';
import { IdentificacaoEtapasSmartPos } from 'constants/enum/IdentificacaoEtapasSmartPos';

type LayoutEtapasSmartPosProps = {
  children: React.ReactNode;
};

export const LayoutEtapasSmartPos = ({
  children,
}: LayoutEtapasSmartPosProps) => {
  const history = useHistory();

  const { activeStep, refHandleVoltar } = useSmartPosEtapasContext();

  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');

  const isImpressaoImgOuTexto =
    activeStep === IdentificacaoEtapasSmartPos.IMAGEM_PARA_IMPRESSAO ||
    activeStep === IdentificacaoEtapasSmartPos.TEXTO_PARA_IMPRESSAO;

  const handleSair = () => {
    history.push(ConstanteRotas.LOJA_APLICATIVOS);
  };

  return (
    <Flex
      pl={['10px', '10px', '40px']}
      bg="gray.50"
      pr={['10px', '10px', '40px']}
      w="full"
      flexDir="column"
      pt="24px"
      minH="100vh"
      h="full"
      alignItems="center"
      position="relative"
    >
      <Flex
        pb="24px"
        alignItems="center"
        w="full"
        h="51px"
        justifyContent="space-between"
      >
        {!isSmallerThan900 ? (
          <Text color="primary.50" fontSize="18px">
            Guia de integração {'>'} Smart POS{' '}
          </Text>
        ) : (
          <Flex alignItems="center">
            <Button
              onClick={() => {
                if (refHandleVoltar.current?.handle) {
                  refHandleVoltar.current?.handle();
                }
              }}
              variant="linkDefault"
              colorScheme="gray"
            >
              <Icon boxSize="20px" as={FiChevronLeft} />
            </Button>
            <Text color="gray.700" fontSize="14px">
              Voltar
            </Text>
          </Flex>
        )}

        <Button onClick={handleSair} variant="linkDefault" colorScheme="gray">
          <Icon boxSize="20px" as={FiX} />
        </Button>
      </Flex>

      <Box
        w={
          isImpressaoImgOuTexto ? ['92%', '92%', '65%'] : ['92%', '92%', '50%']
        }
      >
        {children}
      </Box>
    </Flex>
  );
};
