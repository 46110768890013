import {
  PinInputFieldProps as ChakraPinInputFieldProps,
  PinInput,
  PinInputField,
  HStack,
  Icon,
  Box,
} from '@chakra-ui/react';
import { useState } from 'react';

import CampoPrototipo, {
  CampoPrototipoProps,
} from 'components/PDV/Geral/CampoPrototipo';
import { EyeCloseSenha, EyeOpenSenha } from 'icons';

export type PinInputFieldProps = Omit<
  ChakraPinInputFieldProps,
  'ref' | 'variant' | 'colorScheme'
> &
  CampoPrototipoProps & {
    isRequired?: boolean;
    isDisabled?: boolean;
    mask?: boolean;
    labelColor?: string;
    numeroDeCamposDoPin?: number;
  };

export function InputPin({
  id,
  name,
  label,
  helperText,
  colSpan,
  colStart,
  colEnd,
  rowStart,
  rowEnd,
  rowSpan,
  w,
  maxW,
  minW,
  isRequired,
  isDisabled,
  size,
  onChange,
  labelColor = 'black',
  mask = false,
  numeroDeCamposDoPin = 6,
  autoFocus,
  ...rest
}: PinInputFieldProps) {
  const [senhaVisivel, setSenhaVisivel] = useState(false);

  return (
    <CampoPrototipo
      name={name}
      isRequired={isRequired}
      isDisabled={isDisabled}
      helperText={helperText}
      id={id}
      label={label}
      labelColor={labelColor}
      rowSpan={rowSpan}
      rowStart={rowStart}
      rowEnd={rowEnd}
      colSpan={colSpan}
      colStart={colStart}
      colEnd={colEnd}
    >
      {(_, { ref, ...fieldRest }) => {
        return (
          <HStack spacing="8px">
            <PinInput
              {...fieldRest}
              mask={mask || !senhaVisivel}
              focusBorderColor="1px primary.50"
              placeholder=" "
              otp
            >
              {Array.from({ length: numeroDeCamposDoPin }).map(
                (_rest, index) => {
                  return (
                    <PinInputField
                      {...rest}
                      autoFocus={index === 0 && autoFocus}
                    />
                  );
                }
              )}
            </PinInput>
            <Box marginInlineStart="14px">
              <Icon
                as={senhaVisivel ? EyeOpenSenha : EyeCloseSenha}
                boxSize={['20px', '24px']}
                onClick={() => setSenhaVisivel(!senhaVisivel)}
                strokeWidth="2px !important"
                cursor="pointer"
              />
            </Box>
          </HStack>
        );
      }}
    </CampoPrototipo>
  );
}
