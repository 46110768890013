import React, { useState } from 'react';
import {
  ModalContent,
  ModalBody,
  Flex,
  VStack,
  Button,
  Heading,
  Text,
  ScaleFade,
} from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import ManterFoco from 'components/Geral/ManterFoco';
import { DesbloquearIcon } from 'icons';
import Input from 'components/PDV/Input';

import { yupResolver } from './validationForm';

type FormData = {
  usuario: string;
  senha: string;
};

interface ModalAutorizacaoFuncionalidadeProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  autorizado: (chave: string) => void;
  titulo: string;
  descricao: string;
  tela: number;
  permissoes: Array<string>;
  color?: string;
  buttonText?: string;
}

const ModalAutorizacaoFuncionalidade = ({
  isOpen,
  setIsOpen,
  autorizado,
  titulo,
  descricao,
  tela,
  permissoes,
  buttonText,
  color,
}: ModalAutorizacaoFuncionalidadeProps) => {
  const { t } = useTranslation();

  const formMethods = useForm({ resolver: yupResolver });
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = formMethods.handleSubmit<FormData>(async (data) => {
    setIsLoading(true);
    const response = await api.post<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.AUTENTICACAO_VALIDAR_PERMISSAO_USUARIO,
      {
        ...data,
        tela,
        permissoes,
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((avisos) => toast.warning(avisos));
      }
      if (response.sucesso && response.dados) {
        autorizado(response.dados);
        setIsOpen(false);
        formMethods.reset();
      } else {
        toast.warning(t('O usuário não está autorizado.'));
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  });

  return (
    <ModalPadraoChakra
      onClose={() => {
        setIsOpen(false);
        formMethods.reset();
      }}
      isOpen={isOpen}
      isCentered
      size="xl"
    >
      <ModalContent
        marginBottom={{ base: 0, sm: '3.75rem' }}
        marginTop={{ base: 0, sm: '3.75rem' }}
        h={['full', 'full', '520px']}
        bg="gray.50"
        maxW={['full', 'full', '600px']}
      >
        <ManterFoco
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <ScaleFade initialScale={1.5} in={isOpen}>
            <ModalBody mb="30px" p={0} h="100%">
              <FormProvider {...formMethods}>
                <Flex flexDirection="column" align="stretch" h="100%">
                  <Flex
                    justifyContent="center"
                    h="135px"
                    bg="primary.50"
                    style={{
                      borderTopLeftRadius: '5px',
                      borderTopRightRadius: '5px',
                    }}
                  >
                    <DesbloquearIcon
                      style={{
                        fontSize: '75px',
                        fontWeight: 'bolder',
                        marginTop: '35px',
                        color: 'white',
                      }}
                    />
                  </Flex>

                  <Flex
                    h="366px"
                    justifyContent="flex-start"
                    flexDirection="column"
                    bg="gray.50"
                    px={8}
                    py={7}
                  >
                    <Heading
                      as="h2"
                      fontSize="2xl"
                      color="primary.50"
                      fontWeight="semibold"
                    >
                      {titulo}
                    </Heading>
                    <Text mt={15} fontSize="sm" color="gray.700">
                      {descricao}
                    </Text>
                    <VStack
                      spacing={4}
                      mt={6}
                      px={['10px', '10px', '24']}
                      w="full"
                    >
                      <Input
                        id="usuario"
                        name="usuario"
                        label="Usuário ou e-mail"
                        maxLength={256}
                        placeholder="Usuário"
                      />

                      <Input
                        name="senha"
                        maxLength={50}
                        label="Senha"
                        placeholder="Senha"
                        isPassword
                        inputRightElement
                      />

                      <Button
                        variant="solid"
                        isLoading={isLoading}
                        colorScheme="secondary"
                        w="full"
                        onClick={onSubmit}
                      >
                        Confirmar
                      </Button>
                      <Button
                        variant="cancelOutline"
                        isLoading={isLoading}
                        colorScheme="gray"
                        w="120px"
                        onClick={() => setIsOpen(false)}
                      >
                        Cancelar
                      </Button>
                    </VStack>
                  </Flex>
                </Flex>
              </FormProvider>
            </ModalBody>
          </ScaleFade>
        </ManterFoco>
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export default ModalAutorizacaoFuncionalidade;
