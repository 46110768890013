import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from 'react';
import {
  GridItem,
  Box,
  Flex,
  Text,
  Button,
  Icon,
  Tr,
  Td,
  Stack,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import StatusConsultaEnum from 'constants/enum/statusConsulta';
import auth from 'modules/auth';
import OptionType from 'types/optionType';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';
import { SalvarInserirNovoIcon } from 'icons';
import Input from 'components/PDV/Input';
import Select from 'components/PDV/Select/SelectPadrao';
import { SimpleCard } from 'components/update/Form/SimpleCard';
import {
  LoadMoreRowsParams,
  VirtualizedInfiniteTable,
} from 'components/update/Table/VirtualizedInfiniteTable';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import Textarea from 'components/PDV/Textarea';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { ModalAdicionarProduto } from 'components/update/Modal/ModalAdicionarProduto';

import {
  Produto,
  ProdutoPaginado,
  OptionResponseType,
  ForwardRefProps,
  FormularioProps,
} from './types';
import { produtosTableHeaders, tipoOperacaoOptions } from './validationForm';

const Formulario = forwardRef<ForwardRefProps, FormularioProps>(
  ({ isReadonly = false, movimentacaoEstoqueId, setIsPrenvented }, ref) => {
    const [
      isLocalEstoqueOptionsLoading,
      setIsLocalEstoqueOptionsLoading,
    ] = useState(true);
    const [localEstoqueOptions, setLocalEstoqueOptions] = useState<
      OptionType[]
    >([]);

    const [itemsTotalCount, setItemsTotalCount] = useState(0);
    const [quantidadeTotal, setQuantidadeTotal] = useState(0);
    const [operacoesItemExcluir, setOperacoesItemExcluir] = useState<string[]>(
      []
    );
    const [produtos, setProdutos] = useState<Produto[]>([]);
    const [produtosHasError, setProdutoHasError] = useState(false);
    const [isProdutosLoading, setIsProdutosLoading] = useState(false);
    const [hasTipoOperacao, setHasTipoOperacao] = useState(false);

    const { setValue } = useFormContext();
    const { casasDecimais } = usePadronizacaoContext();

    function handleRemoverProduto(index: number) {
      ModalConfirmacaoExcluir({
        callback: async (ok: boolean) => {
          if (ok) {
            if (setIsPrenvented) {
              setIsPrenvented(true);
            }

            const newProdutos = [...produtos];

            const { quantidade, operacaoItemId } = produtos[index];

            newProdutos.splice(index, 1);

            setProdutos(newProdutos);

            setItemsTotalCount((prev) => prev - 1);

            setQuantidadeTotal((prev) => prev - quantidade);

            if (operacaoItemId) {
              setOperacoesItemExcluir((prev) => [...prev, operacaoItemId]);
            }
          }
        },
      });
    }

    async function handleAdicionarProduto() {
      const {
        produtos: newProduto,
        deveReiniciar,
      } = await ModalAdicionarProduto({
        casasDecimaisQuantidade: casasDecimais.casasDecimaisQuantidade,
      });

      if (setIsPrenvented) {
        setIsPrenvented(!!newProduto && (newProduto || []).length > 0);
      }

      setProdutos((prev) => [...newProduto, ...prev]);

      setItemsTotalCount((prev) => prev + newProduto.length);

      setQuantidadeTotal(
        (prev) =>
          prev + newProduto.reduce((acc, curr) => acc + curr.quantidade, 0)
      );

      if (deveReiniciar) {
        handleAdicionarProduto();
      }
    }

    function handleSetProdutosError() {
      setProdutoHasError(true);

      handleAdicionarProduto();
    }

    const loadMoreRows = useCallback(
      async ({
        currentPage,
        pageSize,
        orderColumn,
        orderDirection,
      }: LoadMoreRowsParams) => {
        if (movimentacaoEstoqueId) {
          setIsProdutosLoading(true);

          const paginationData = {
            currentPage,
            pageSize,
            orderColumn,
            orderDirection,
          };

          const response = await api.post<void, ResponseApi<ProdutoPaginado>>(
            formatQueryPagegTable(
              ConstanteEnderecoWebservice.MOVIMENTACAO_ESTOQUE_LISTAR_ITENS_PAGINADO,
              paginationData
            ),

            { operacaoId: movimentacaoEstoqueId }
          );

          if (response) {
            if (response.sucesso) {
              setProdutos((prev) => [
                ...prev,
                ...((response.dados.registros || []).map((registro) => ({
                  operacaoItemId: registro.operacaoItemId,
                  produtoNome: registro.produtoNome,
                  corDescricao: registro.cor,
                  tamanhoDescricao: registro.tamanho,
                  quantidade: registro.quantidade,
                })) as Produto[]),
              ]);

              setItemsTotalCount(response.dados.totalProdutos || 0);
              setQuantidadeTotal(response.dados.totalItens || 0);
            }
          }
        }

        setIsProdutosLoading(false);
      },
      [movimentacaoEstoqueId]
    );

    const latestProps = useRef({ setValue, movimentacaoEstoqueId });
    useEffect(() => {
      latestProps.current = { setValue, movimentacaoEstoqueId };
      if (latestProps.current.movimentacaoEstoqueId) {
        setHasTipoOperacao(true);
      }
    });

    useEffect(() => {
      async function loadDefaultLocalEstoque(options: any[]) {
        setIsLocalEstoqueOptionsLoading(true);

        const response = await api.get<void, ResponseApi<string>>(
          ConstanteEnderecoWebservice.LOCAL_ESTOQUE_OBTER_PADRAO_SISTEMA
        );

        if (response) {
          if (response.avisos) {
            response.avisos.map((aviso: string) => toast.warning(aviso));
          }
          if (response.sucesso && response.dados) {
            const padraoSistemaOptions = options.find(
              (option) => option.value === response.dados
            );

            latestProps.current.setValue('localEstoque', padraoSistemaOptions);
          } else {
            latestProps.current.setValue('localEstoque', null);
          }
        }

        setIsLocalEstoqueOptionsLoading(false);
      }

      async function loadLocalEstoqueOptions() {
        setIsLocalEstoqueOptionsLoading(true);

        const lojaId = auth.getLoja().id;

        const params = {
          statusConsulta: StatusConsultaEnum.ATIVOS,
          lojaId,
        };

        const response = await api.get<void, ResponseApi<OptionResponseType[]>>(
          ConstanteEnderecoWebservice.LOCAL_ESTOQUE_LISTAR_SELECT,
          { params }
        );

        if (response) {
          if (response.avisos) {
            response.avisos.map((aviso: string) => toast.warning(aviso));
          }

          if (response.sucesso && response.dados) {
            const newEstoqueOptions = response.dados.map((option) => ({
              value: option.id,
              label: option.nome,
            }));

            setLocalEstoqueOptions(newEstoqueOptions);

            setIsLocalEstoqueOptionsLoading(false);

            return newEstoqueOptions;
          }
        }

        setIsLocalEstoqueOptionsLoading(false);

        return [];
      }

      async function loadLocalEstoque() {
        if (!latestProps.current.movimentacaoEstoqueId) {
          const options = await loadLocalEstoqueOptions();

          await loadDefaultLocalEstoque(options);
        }
      }

      loadLocalEstoque();
    }, []);

    useImperativeHandle(ref, () => ({
      produtos,
      handleSetProdutosError,
      operacoesItemExcluir,
    }));

    return (
      <SimpleCard>
        <SimpleGridForm>
          <GridItem colSpan={{ base: 12, md: 3 }}>
            <Select
              id="tipoOperacao"
              name="tipoOperacao"
              label="Operação"
              placeholder="Selecionar"
              required
              options={tipoOperacaoOptions(isReadonly)}
              autoFocus
              isDisabled={produtos.length > 0}
              helperText={
                produtos.length > 0 &&
                'Para alterar essa informação remova todos os itens'
              }
              onSelect={() => {
                setHasTipoOperacao(true);
              }}
            />
          </GridItem>
          <GridItem colSpan={{ base: 12, md: 3 }}>
            <Select
              id="localEstoque"
              name="localEstoque"
              asControlledByObject
              label="Estoque"
              placeholder="Selecionar"
              required
              options={localEstoqueOptions}
              isLoading={isLocalEstoqueOptionsLoading}
              isDisabled={produtos.length > 0}
              helperText={
                produtos.length > 0 &&
                'Para alterar essa informação remova todos os itens'
              }
            />
          </GridItem>
          <GridItem colSpan={{ base: 12, md: 6 }} rowSpan={2}>
            <Textarea
              id="motivo"
              name="motivo"
              label="Motivo"
              placeholder="Descreva o motivo da movimentação"
              rows={5}
              resize="none"
              isDisabled={isReadonly}
            />
          </GridItem>
          <GridItem colSpan={{ base: 12, md: 3 }}>
            <Input
              id="dataEmissao"
              name="dataEmissao"
              label="Data de movimentação"
              placeholder="00/00/0000"
              isDisabled
              {...(isReadonly ? {} : { _disabled: { bg: 'gray.50' } })}
            />
          </GridItem>
          <GridItem colSpan={{ base: 12, md: 3 }}>
            <Input
              id="usuario"
              name="usuario"
              label="Usuário"
              placeholder="Nome do usuário"
              isDisabled
              {...(isReadonly ? {} : { _disabled: { bg: 'gray.50' } })}
            />
          </GridItem>
          <GridItem colSpan={12}>
            <Box w="full">
              <Text
                as="label"
                lineHeight="none"
                mb="1"
                fontSize="sm"
                fontWeight="semibold"
              >
                Lista de produtos
              </Text>
              <Box
                borderRadius="md"
                bg="gray.50"
                border="1px"
                borderColor="gray.100"
                p={{ base: 4, sm: 6, md: 8 }}
              >
                <Stack
                  direction={{ base: 'column-reverse', sm: 'row' }}
                  justifyContent={isReadonly ? 'flex-end' : 'space-between'}
                  mb="4"
                >
                  {!isReadonly && (
                    <Button
                      borderRadius="md"
                      colorScheme="secondary"
                      leftIcon={
                        <Icon as={SalvarInserirNovoIcon} fontSize="lg" />
                      }
                      onClick={handleAdicionarProduto}
                      disabled={!hasTipoOperacao}
                    >
                      Adicionar itens
                    </Button>
                  )}

                  <VStack alignItems="flex-end" spacing="1">
                    <HStack>
                      <Text lineHeight="none">Total de produtos:</Text>
                      <Text lineHeight="none" fontWeight="bold">
                        {itemsTotalCount.toLocaleString('pt-BR', {
                          minimumFractionDigits:
                            casasDecimais.casasDecimaisQuantidade,
                          maximumFractionDigits:
                            casasDecimais.casasDecimaisQuantidade,
                        })}
                      </Text>
                    </HStack>
                    <HStack>
                      <Text lineHeight="none">Quantidade de itens:</Text>
                      <Text lineHeight="none" fontWeight="bold">
                        {quantidadeTotal.toLocaleString('pt-BR', {
                          minimumFractionDigits:
                            casasDecimais.casasDecimaisQuantidade,
                          maximumFractionDigits:
                            casasDecimais.casasDecimaisQuantidade,
                        })}
                      </Text>
                    </HStack>
                  </VStack>
                </Stack>
                <VirtualizedInfiniteTable
                  isLoading={isProdutosLoading}
                  alterarBordaListagem="white"
                  withoutRowsMessage={
                    produtosHasError
                      ? `Adicione ao menos um produto para ${
                          movimentacaoEstoqueId ? 'salvar' : 'cadastrar'
                        } a movimentação.`
                      : 'Nenhum produto informado.'
                  }
                  orderColumn="produto"
                  tableHeaders={produtosTableHeaders}
                  rowRenderer={({
                    index,
                    style: { height, ...restStyle },
                    key,
                  }) => {
                    const produto = produtos[index];

                    if (!produto) {
                      return null;
                    }

                    return (
                      <Tr key={key} style={restStyle}>
                        <Td width={produtosTableHeaders[0].width}>
                          <Text lineHeight="0" opacity={0}>
                            {produtosTableHeaders[0].content}
                          </Text>

                          {`${produto.produtoNome} ${produto.corDescricao}`}
                        </Td>
                        <Td width={produtosTableHeaders[1].width}>
                          <Text lineHeight="0" opacity={0}>
                            {produtosTableHeaders[1].content}
                          </Text>

                          {produto.tamanhoDescricao || '-'}
                        </Td>
                        <Td width={produtosTableHeaders[2].width} isNumeric>
                          <Text lineHeight="0" opacity={0}>
                            {produtosTableHeaders[2].content}
                          </Text>

                          {produto.quantidade.toLocaleString('pt-BR', {
                            minimumFractionDigits:
                              casasDecimais.casasDecimaisQuantidade,
                            maximumFractionDigits:
                              casasDecimais.casasDecimaisQuantidade,
                          })}
                        </Td>
                        <Td minW={produtosTableHeaders[3].minWidth}>
                          <Flex>
                            <Text lineHeight="0" opacity={0}>
                              {produtosTableHeaders[3].content}
                            </Text>

                            <ActionsMenu
                              isDisabled={isReadonly}
                              items={
                                isReadonly
                                  ? []
                                  : [
                                      {
                                        content: 'Remover',
                                        onClick: () =>
                                          handleRemoverProduto(index),
                                      },
                                    ]
                              }
                            />
                          </Flex>
                        </Td>
                      </Tr>
                    );
                  }}
                  rowCount={itemsTotalCount}
                  isRowLoaded={({ index }) => !!produtos[index]}
                  loadMoreRows={loadMoreRows}
                />
              </Box>
            </Box>
          </GridItem>
        </SimpleGridForm>
      </SimpleCard>
    );
  }
);

export default Formulario;
