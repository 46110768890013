import { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { enumIdentificacaoIntegracao } from 'constants/enum/enumIdentificacaoIntegracao';
import StatusFiscaisEnum from 'constants/enum/fiscal/statusFiscal';
import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';
import StatusOperacaoEnum from 'constants/enum/statusOperacao';
import ConstanteRotas from 'constants/rotas';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import api, { ResponseApi } from 'services/api';
import {
  DadosTrayProps,
  useTrayPainelControleContext,
} from 'store/Tray/PainelControle';

import { ModalEnviarCodigoRastreio } from 'components/Modal/ModalCodigoRastreio';
import { ModalCompartilhar } from 'components/Modal/ModalCompartilhar';
import { ModalPendencia } from 'components/Modal/ModalPendencia';
import { ModalPendenciaNotaFiscal } from 'components/Modal/ModalPendenciaNotaFiscal';
import {
  parseDate,
  formatDate as formatDateMonthInput,
} from 'components/update/Input/MonthInput';
import { PagedTableForwardRefData } from 'components/update/Table/PagedTable';

import {
  ListaIntegracaoPendenciaPedidoProps,
  ListVendasProps,
} from './validationForms';

type DadosCancelamento = {
  integracaoPedidoId?: string;
  operacaoId?: string;
};

export const useVenda = (
  onReload: () => void,
  numeroVenda?: number,
  handleCancelarVenda?: () => void
) => {
  const { dadosTray } = useTrayPainelControleContext();
  const [listVendas, setListVendas] = useState<ListVendasProps[]>([]);
  const [produtoEmitirNota, setProdutoEmitirNota] = useState<ListVendasProps[]>(
    []
  );
  const [abriModalNotaFiscal, setAbrirModalNotaFiscal] = useState(false);
  const [
    modalConfirmacaoCancelarIsOpen,
    setModalConfirmacaoCancelarIsOpen,
  ] = useState(false);
  const [dadosCancelamentoVenda, setDadosCancelamentoVenda] = useState<
    DadosCancelamento[]
  >([]);
  const [selecionarTodasVendas, setSelecionarTodasVendas] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAcaoEmMassa, setIsAcaoEmMassa] = useState(false);
  const [amountPendencia, setAmountPendencia] = useState(0);
  const [pendenciaNotaFiscal, setPendenciaNotaFiscal] = useState(0);

  const isMountedRef = useIsMountedRef();
  const pageIsLoaded = useRef(false);

  const history = useHistory();

  const hasVendaSeleciona = listVendas.some(
    (itemVenda) => itemVenda.isChecked === true
  );

  const vendasSelecionadas = listVendas.filter(
    (itemVenda) => itemVenda.isChecked === true
  );

  const amountVendaSelecionada = vendasSelecionadas.length;

  const isVendaSemOperacao = vendasSelecionadas.some(
    (item) =>
      item.operacaoId === null ||
      item.statusNotaFiscal === StatusFiscaisEnum.Autorizada ||
      item.identificacaoTipoOperacao !== IdentificacaoTipoOperacaoEnum.VENDA
  );

  const hasNotaFiscalAutorizada = vendasSelecionadas.every(
    (item) =>
      item.statusNotaFiscal === StatusFiscaisEnum.Autorizada &&
      item.codigoRastreioEnviado === false
  );

  const isCancelarMultiplas = vendasSelecionadas.every(
    (itemVenda) =>
      itemVenda.identificacaoTipoOperacao ===
      IdentificacaoTipoOperacaoEnum.PEDIDO
  );

  const handleGerarNotaFiscal = (data: ListVendasProps[]) => {
    setProdutoEmitirNota(data);
    setAbrirModalNotaFiscal(true);
  };

  const handleFecharModalNotaFiscal = () => {
    setAbrirModalNotaFiscal(false);
  };

  const getSituacaoNotaFiscal = useCallback(async () => {
    const response = await api.get<void, ResponseApi<number>>(
      ConstanteEnderecoWebservice.INTEGRACAO_NOTA_FISCAL_ALERTA,
      {
        params: {
          identificacaoIntegracao: enumIdentificacaoIntegracao.TRAY,
        },
      }
    );
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((avisos) => toast.warning(avisos));
      }

      if (response.sucesso) {
        setPendenciaNotaFiscal(response.dados);
      }
    }
  }, []);

  const obterQuantidadePendencias = useCallback(async () => {
    const response = await api.get<void, ResponseApi<number>>(
      ConstanteEnderecoWebservice.INTEGRACAO_TRAY_QUANTIDADE_PENDENCIA,
      {
        params: {
          identificacaoIntegracao: enumIdentificacaoIntegracao.TRAY,
        },
      }
    );

    if (response) {
      if (response.sucesso) {
        setAmountPendencia(response.dados);
      }
    }
  }, []);

  const reloadList = useCallback(() => {
    setIsLoading(true);
    onReload();
    obterQuantidadePendencias();
    getSituacaoNotaFiscal();
    setIsLoading(false);
  }, [obterQuantidadePendencias, onReload, getSituacaoNotaFiscal]);

  function handleVoltarPainelAdm() {
    history.push(ConstanteRotas.INTEGRACAO_TRAY_COMMERCE_PAINEL_ADM);
  }

  const handleHabilitarAcaoEmMassa = () => {
    setIsAcaoEmMassa((prev) => !prev);
    setListVendas((prev) =>
      prev.map((itemVenda) => ({
        ...itemVenda,
        isChecked: false,
      }))
    );
  };

  const handleAbrirModalCancelarVenda = (vendaItem: ListVendasProps) => {
    setModalConfirmacaoCancelarIsOpen(true);
    setDadosCancelamentoVenda((prev) => [
      {
        ...prev,
        integracaoPedidoId: vendaItem.integracaoPedidoId,
        operacaoId: vendaItem.operacaoId,
      },
    ]);
  };

  function handleOpenModalCodigoRastreio(vendaItem: ListVendasProps[]) {
    ModalEnviarCodigoRastreio({
      listInfoRastreio: vendaItem,
      reloadList,
    });
  }

  const handleCancelarMultiplasVendas = useCallback(() => {
    setModalConfirmacaoCancelarIsOpen(true);
    setDadosCancelamentoVenda((prev) => [
      ...prev,
      ...vendasSelecionadas.map((item) => ({
        integracaoPedidoId: item.integracaoPedidoId,
        operacaoId: item.operacaoId,
      })),
    ]);
  }, [vendasSelecionadas]);

  const handleToggleSelecionarTodasVendas = useCallback(() => {
    setListVendas((prev) =>
      prev.map((itemVenda) => ({
        ...itemVenda,
        isChecked:
          itemVenda.status === StatusOperacaoEnum.CANCELADA
            ? false
            : !selecionarTodasVendas,
      }))
    );
    setSelecionarTodasVendas(!selecionarTodasVendas);
  }, [selecionarTodasVendas]);

  const handleToggleSelecionarVendas = useCallback((index) => {
    setListVendas((prev) =>
      prev.map((itemVenda, indexVenda) => ({
        ...itemVenda,
        isChecked:
          indexVenda === index ? !itemVenda.isChecked : itemVenda.isChecked,
      }))
    );
  }, []);

  const handleLimparSelecao = useCallback(() => {
    setListVendas((prev) =>
      prev.map((itemPrev) => ({
        ...itemPrev,
        isChecked: false,
      }))
    );
  }, []);

  const openPendenceNotaFiscal = (
    motivo: string,
    handleNotaFiscal: () => void
  ) => {
    if (numeroVenda !== undefined && handleCancelarVenda !== undefined) {
      ModalPendenciaNotaFiscal({
        motivo,
        numeroVenda,
        handleGerarNotaFiscal: handleNotaFiscal,
        handleCancelarVenda,
      });
    }
  };

  const openPendences = (
    pendencias: ListaIntegracaoPendenciaPedidoProps[],
    integracaoPedidoId: string
  ) => {
    if (numeroVenda !== undefined && handleCancelarVenda !== undefined) {
      ModalPendencia({
        pendencias,
        trayId: dadosTray?.id,
        numeroVenda,
        handleCancelarVenda,
        reloadList,
        integracaoPedidoId,
      });
    }
  };

  const cancelarVenda = useCallback(
    async (motivo?: string, motivoNotaFiscal?: string) => {
      setIsLoading(true);
      const dataAtual = new Date();
      const data = dadosCancelamentoVenda.map((itemCancelado) => ({
        ...itemCancelado,
        motivoCancelamento: motivo,
        motivoCancelamentoNotaFiscal: motivoNotaFiscal,
        dataCancelamentoNotafiscal: parseDate(
          formatDateMonthInput(dataAtual),
          dataAtual.getDate()
        ),
        tela: enumIdentificacaoIntegracao.TRAY,
      }));

      setDadosCancelamentoVenda([]);
      const response = await api.put<void, ResponseApi<number>>(
        ConstanteEnderecoWebservice.INTEGRACAO_CANCELAR_PEDIDO,
        data
      );
      if (response) {
        if (response.sucesso) {
          reloadList();
        }
        setIsLoading(false);
      }
      setIsLoading(false);
    },
    [dadosCancelamentoVenda, reloadList, setDadosCancelamentoVenda]
  );

  const handleAbrirModalCompartilhar = useCallback(
    (
      handleEnviarEmailRelatorio: () => void,
      handleImprimirRelatorio: () => void
    ) => {
      ModalCompartilhar({
        items: [
          {
            titulo: 'Imprimir relatório',
            onClickEmail: (onClose) => {
              handleEnviarEmailRelatorio();
              onClose();
            },
            onClickImpressao: (onClose) => {
              handleImprimirRelatorio();
              onClose();
            },
          },
        ],
      });
    },
    []
  );

  return {
    produtoEmitirNota,
    abriModalNotaFiscal,
    handleGerarNotaFiscal,
    handleFecharModalNotaFiscal,
    hasVendaSeleciona,
    vendasSelecionadas,
    amountVendaSelecionada,
    handleVoltarPainelAdm,
    isMountedRef,
    pageIsLoaded,
    reloadList,
    listVendas,
    setListVendas,
    isVendaSemOperacao,
    hasNotaFiscalAutorizada,
    isCancelarMultiplas,
    modalConfirmacaoCancelarIsOpen,
    setModalConfirmacaoCancelarIsOpen,
    handleAbrirModalCancelarVenda,
    dadosCancelamentoVenda,
    setDadosCancelamentoVenda,
    getSituacaoNotaFiscal,
    handleOpenModalCodigoRastreio,
    handleCancelarMultiplasVendas,
    handleToggleSelecionarTodasVendas,
    selecionarTodasVendas,
    pendenciaNotaFiscal,
    handleToggleSelecionarVendas,
    handleLimparSelecao,
    openPendences,
    openPendenceNotaFiscal,
    setIsLoading,
    handleAbrirModalCompartilhar,
    obterQuantidadePendencias,
    cancelarVenda,
    amountPendencia,
    isLoading,
    handleHabilitarAcaoEmMassa,
    isAcaoEmMassa,
  };
};
