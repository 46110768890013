import { useCallback, useEffect, useImperativeHandle } from 'react';
import { VStack, Text, Link } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';

import { IdentificacaoEtapasTray } from 'constants/enum/IdentificacaoEtapasTray';
import { useTrayEtapasContext } from 'store/Tray';
import { buscarDadosAutenticacao, autenticarDados } from 'services/tray';

import Input from 'components/PDV/Input';
import { CadeadoAutenticacaoIcon } from 'icons';

import { yupResolver, FormData } from './validationForms';
import { Header } from '../Layout/Header';

export const Autenticacao = () => {
  const { ref, setActiveStep, setIsLoading } = useTrayEtapasContext();

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
  });

  const { handleSubmit: onSubmit, reset } = formMethods;

  const handleAvancar = useCallback(() => {
    setActiveStep(IdentificacaoEtapasTray.CANAL_VENDAS);
  }, [setActiveStep]);

  const handleVoltar = useCallback(() => {
    setActiveStep(IdentificacaoEtapasTray.PORTAL_CLIENTE);
  }, [setActiveStep]);

  const handleSubmit = onSubmit(async (data) => {
    setIsLoading(true);

    const response = await autenticarDados({
      ...data,
    });

    if (response !== null) {
      if (response === true) {
        handleAvancar();
      }
    }

    setIsLoading(false);
  });

  const getAutenticacao = useCallback(async () => {
    setIsLoading(true);

    const response = await buscarDadosAutenticacao();

    if (response !== null) {
      reset(response);
    }

    setIsLoading(false);
  }, [setIsLoading, reset]);

  useImperativeHandle(ref, () => ({
    handleAvancar: handleSubmit,
    handleVoltar,
  }));

  useEffect(() => {
    getAutenticacao();
  }, [getAutenticacao]);

  return (
    <>
      <Header
        title="Autenticação"
        handleVoltar={handleVoltar}
        icon={CadeadoAutenticacaoIcon}
      />
      <FormProvider {...formMethods}>
        <VStack
          pl={['10px', '10px', '20px']}
          pr={['10px', '10px', '20px']}
          color="primary.50"
          fontSize="14px"
          w="full"
          alignItems="left"
          spacing="24px"
        >
          <Text mb="4px" textAlign="left" letterSpacing="0px">
            Ainda no portal do{' '}
            <Link
              href="https://www.tray.com.br/"
              target="_blank"
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              cliente Tray,
            </Link>{' '}
            (dentro do aplicativo PowerStock Cloud) clique no botão “Acessar”.
            Na tela seguinte clique em “Autorizar”. Copie e cole os parâmetros
            da Tray nos campos abaixo. São dados personalizados e essenciais
            para o funcionamento da integração.
          </Text>

          <Input
            name="url"
            label="Domínio da loja (URL)"
            placeholder="https://nomedaloja.com.br"
            colSpan={12}
          />
          <Input
            id="code"
            name="code"
            label="Code"
            colSpan={12}
            placeholder="Informe o identificador da integração"
          />
        </VStack>
      </FormProvider>
    </>
  );
};
