import { Icon, Box, Grid, GridItem, Flex, SimpleGrid } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { FiChevronLeft } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import auth from 'modules/auth';
import { enumIntegracoes } from 'constants/enum/enumIntegracoes';

import { SearchInput } from 'components/update/Input/SearchInput';
import { IconProximoLancamento, IconType } from 'icons';

type FormData = {
  integracao: string;
};

type ListAplicativosProps = {
  proximoLancamento: boolean;
  id: string;
  ativo: boolean;
  bg: string;
  name: string;
  icon: IconType;
  iconInativo: IconType;
  rotaComercial: string;
  permissao?: number;
  rotaDetalhesIntegracao: string;
};

export const LojaAplicativos = () => {
  const listIntegracoes = enumIntegracoes.options;

  const history = useHistory();

  const formMethods = useForm<FormData>({
    defaultValues: {
      integracao: '',
    },
  });

  const { watch } = formMethods;

  const integracaoWatch = watch('integracao');

  const filterIntegracao = () =>
    listIntegracoes.filter((item) =>
      item.name?.toLowerCase().includes(integracaoWatch?.toLowerCase())
    );

  const integracoes = filterIntegracao();

  const possuiPermissao = (permissao: number) => {
    return auth.possuiServico(permissao).permitido;
  };

  const handleAbrirLoja = (listApp: ListAplicativosProps) => {
    const { permissao, rotaComercial, rotaDetalhesIntegracao } = listApp;
    if (permissao) {
      const isPermissao = possuiPermissao(permissao);

      if (isPermissao) {
        history.push(rotaDetalhesIntegracao);
      } else {
        history.push(rotaComercial || '');
      }
    } else {
      history.push(rotaComercial);
    }
  };

  return (
    <Box pt="15px">
      <FormProvider {...formMethods}>
        <SimpleGrid gap={{ base: 2, md: 4 }} columns={24}>
          <GridItem
            colSpan={{ base: 2, md: 1 }}
            as={Flex}
            h="36px"
            alignItems="center"
            justifyContent="end"
          >
            <Box
              w="min"
              cursor="pointer"
              onClick={() => {
                history.goBack();
              }}
            >
              <FiChevronLeft
                color="#482ABC"
                fontSize="30px"
                strokeWidth="1.25px"
              />
            </Box>
          </GridItem>
          <GridItem colSpan={{ base: 20, md: 22 }}>
            <Box w={{ base: 'full', md: '50%' }}>
              <SearchInput
                name="integracao"
                id="integracao"
                placeholder="Pesquisar integração"
                autoFocus
              />
            </Box>
            <Grid
              pt="40px"
              templateColumns={[
                'repeat(1, 1fr)',
                'repeat(2, 1fr)',
                'repeat(3, 1fr)',
                'repeat(4, 1fr)',
                'repeat(4, 1fr)',
                'repeat(5, 1fr)',
              ]}
              gap="16px"
            >
              {integracoes.map((integracaoItem) => (
                <GridItem
                  key={integracaoItem.id}
                  onClick={() => {
                    if (integracaoItem.ativo) {
                      handleAbrirLoja(integracaoItem);
                    }
                  }}
                  boxShadow="0px 0px 4px #00000029"
                  transition="all 0.2s"
                  _hover={{
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: integracaoItem.ativo
                      ? 'rgba(85, 2, 178, 0.5)'
                      : 'transparent',
                    transition: 'all 0.1s',
                  }}
                  cursor={integracaoItem.ativo ? 'pointer' : 'not-allowed'}
                  borderRadius="5px"
                  position="relative"
                  h="140px"
                  w="100%"
                  bg={integracaoItem.ativo ? integracaoItem.bg : 'white'}
                >
                  <Flex
                    justifyContent="center"
                    color="white"
                    w="full"
                    h="full"
                    textAlign="center"
                    opacity={integracaoItem.ativo ? 1 : 0.3}
                    alignItems="center"
                  >
                    <Icon
                      boxSize="150px"
                      as={
                        integracaoItem.ativo
                          ? integracaoItem.icon
                          : integracaoItem.iconInativo
                      }
                    />
                  </Flex>
                  {integracaoItem.proximoLancamento && (
                    <Flex position="absolute" top="-33px" right="-5px">
                      <Icon boxSize="150px" as={IconProximoLancamento} />
                    </Flex>
                  )}
                </GridItem>
              ))}
            </Grid>
          </GridItem>
          <GridItem colSpan={{ base: 2, md: 1 }} />
        </SimpleGrid>
      </FormProvider>
    </Box>
  );
};
