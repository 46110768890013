import React, {
  useEffect,
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useMediaQuery,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  useToken,
} from '@chakra-ui/react';
import { FiChevronDown } from 'react-icons/fi';

import { useRegrasFiscaisFormularioContext } from 'store/RegrasFiscais/RegrasFiscaisFormularios';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import RegraFiscalItemCampos from './RegraFiscalItemCampos';

export type RegraFiscalItemForwardRefData = {
  handleTabsChange: (newTabIndex: number) => void;
  handleSimplesNacionalAccordionsChange: (newAccordionIndex: any) => void;
  handleRegimeNormalAccordionsChange: (newAccordionIndex: any) => void;
  setDefaultTabAccordions: () => void;
};

interface RegraFiscalItemProps {
  isReadonly?: boolean;
}

const RegraFiscalItem = forwardRef<
  RegraFiscalItemForwardRefData,
  RegraFiscalItemProps
>(({ isReadonly = false }, ref) => {
  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');
  const [radiiMd] = useToken('radii', ['md']);
  const {
    activeOperacaoIndex,
    isLoading,
  } = useRegrasFiscaisFormularioContext();

  const [tabIndex, setTabIndex] = useState(0);
  const [
    simplesNacionalAccordionsIndex,
    setSimplesNacionalAccordionsIndex,
  ] = useState([]);
  const [
    regimeNormalAccordionsIndex,
    setRegimeNormalAccordionsIndex,
  ] = useState([]);

  function handleTabsChange(newTabIndex: number) {
    setTabIndex(newTabIndex);
  }

  function handleSimplesNacionalAccordionsChange(newAccordionIndex: any) {
    setSimplesNacionalAccordionsIndex(newAccordionIndex);
  }

  function handleRegimeNormalAccordionsChange(newAccordionIndex: any) {
    setRegimeNormalAccordionsIndex(newAccordionIndex);
  }

  const setDefaultTabAccordions = useCallback(() => {
    setTabIndex(0);
    setSimplesNacionalAccordionsIndex([]);
    setRegimeNormalAccordionsIndex([]);
  }, []);

  useImperativeHandle(ref, () => ({
    handleTabsChange,
    handleSimplesNacionalAccordionsChange,
    handleRegimeNormalAccordionsChange,
    setDefaultTabAccordions,
  }));

  useEffect(() => {
    setDefaultTabAccordions();
  }, [activeOperacaoIndex, setDefaultTabAccordions]);

  return (
    <Tabs
      variant="enclosed"
      position="relative"
      index={tabIndex}
      onChange={handleTabsChange}
      sx={{
        '& .chakra-collapse, & .chakra-accordion__item': {
          overflow: 'visible !important',
        },
      }}
    >
      {isLoading && <LoadingPadrao style={{ borderRadius: radiiMd }} />}
      {isSmallerThan900 ? (
        <Menu placement="bottom-start" gutter={0}>
          <MenuButton color="white" p="2">
            {tabIndex === 0 ? 'Simples nacional' : 'Regime normal'}
            <Icon as={FiChevronDown} ml="2" fontSize="xs" />
          </MenuButton>
          <MenuList>
            {tabIndex === 1 && (
              <MenuItem onClick={() => handleTabsChange(0)}>
                Simples nacional
              </MenuItem>
            )}
            {tabIndex === 0 && (
              <MenuItem onClick={() => handleTabsChange(1)}>
                Regime normal
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      ) : (
        <TabList>
          <Tab id="tab-simplesNacional" fontSize="sm">
            Simples nacional
          </Tab>
          <Tab id="tab-regimeNormal" fontSize="sm">
            Regime normal
          </Tab>
        </TabList>
      )}
      <TabPanels>
        <TabPanel p={{ base: 3, sm: 6, md: 8 }}>
          <Accordion
            allowMultiple
            index={simplesNacionalAccordionsIndex}
            onChange={handleSimplesNacionalAccordionsChange}
          >
            <VStack
              alignItems="flex-start"
              w="full"
              spacing={{ base: 3, sm: 6, md: 8 }}
            >
              <AccordionItem w="full">
                <AccordionButton id="accordion-contribuinte">
                  <Box flex="1" textAlign="left" fontSize="sm">
                    Contribuinte
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                  <RegraFiscalItemCampos
                    isReadonly={isReadonly}
                    index={activeOperacaoIndex}
                    regimeTributario="simplesNacional"
                    indicadorIe="contribuinte"
                  />
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem w="full">
                <AccordionButton id="accordion-naoContribuinte">
                  <Box flex="1" textAlign="left" fontSize="sm">
                    Consumidor / Não contribuinte
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                  <RegraFiscalItemCampos
                    isReadonly={isReadonly}
                    index={activeOperacaoIndex}
                    regimeTributario="simplesNacional"
                    indicadorIe="naoContribuinte"
                  />
                </AccordionPanel>
              </AccordionItem>
            </VStack>
          </Accordion>
        </TabPanel>
        <TabPanel p={{ base: 3, sm: 6, md: 8 }}>
          <Accordion
            allowMultiple
            index={regimeNormalAccordionsIndex}
            onChange={handleRegimeNormalAccordionsChange}
          >
            <VStack
              alignItems="flex-start"
              w="full"
              spacing={{ base: 3, sm: 6, md: 8 }}
            >
              <AccordionItem w="full">
                <AccordionButton id="accordion-contribuinte">
                  <Box flex="1" textAlign="left" fontSize="sm">
                    Contribuinte
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <RegraFiscalItemCampos
                    isReadonly={isReadonly}
                    index={activeOperacaoIndex}
                    regimeTributario="regimeNormal"
                    indicadorIe="contribuinte"
                  />
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem w="full">
                <AccordionButton id="accordion-naoContribuinte">
                  <Box flex="1" textAlign="left" fontSize="sm">
                    Consumidor / Não contribuinte
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <RegraFiscalItemCampos
                    isReadonly={isReadonly}
                    index={activeOperacaoIndex}
                    regimeTributario="regimeNormal"
                    indicadorIe="naoContribuinte"
                  />
                </AccordionPanel>
              </AccordionItem>
            </VStack>
          </Accordion>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
});

export default RegraFiscalItem;
