import { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Heading,
  Stack,
  VStack,
  Image,
  Button,
  Flex,
  useBreakpointValue,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import auth from 'modules/auth';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import {
  retomarImportarXMLImgs,
  inicioImportarXMLImgs,
} from 'constants/enum/enumsImgSistemas';
import { useFileUpload } from 'helpers/layout/useFileUpload';
import { base64ToBytes, getFileBase64 } from 'helpers/data/getFileBase64';
import { getImageBySystem } from 'helpers/layout/getImageBySystem';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { ResolucaoNaoCompativel } from 'pages/ResolucaoNaoCompativel';
import { ModalListaManifesto } from '../ModalListaNotaManifesto';

type VerificarImportacaoResponse = {
  entradaMercadoriaId?: string;
  usuarioId?: string;
  usuarioNome: string;
};

type ImportacaoAtiva = {
  entradaMercadoriaId: string;
  usuarioId: string;
};

export function IniciarEntradaImportacao() {
  const history = useHistory();

  const isAdministrador = auth.getIsAdministrador();
  const { userId } = auth.getDadosToken();

  const buttonSize = useBreakpointValue({
    base: 'md',
    md: 'lg',
  });

  const [isLargerThan800] = useMediaQuery('(min-width: 800px)');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(true);
  const [importacaoAtiva, setImportacaoAtiva] = useState<ImportacaoAtiva>();

  const { casasDecimais } = usePadronizacaoContext();

  const hasImportacaoAtiva =
    !!importacaoAtiva && !!importacaoAtiva.entradaMercadoriaId;

  function handleVoltar() {
    history.push(ConstanteRotas.DASHBOARD);
  }

  async function cadastrarEntradaImportacao(_: any, file?: File) {
    if (file && file.type === 'text/xml') {
      const xmlBase64 = await getFileBase64(file);
      const byteFromBase64 = base64ToBytes(xmlBase64);

      if (xmlBase64) {
        setIsLoading(true);
        const decodedXML = new TextDecoder().decode(byteFromBase64);

        const response = await api.post<void, ResponseApi<string>>(
          ConstanteEnderecoWebservice.ENTRADA_MERCADORIA_CADASTRAR_ENTRADA_IMPORTACAO,
          { xml: decodedXML }
        );

        if (response) {
          if (response.avisos) {
            response.avisos.map((aviso: string) => toast.warning(aviso));
          }

          if (response.sucesso && response.dados) {
            history.push(
              SubstituirParametroRota(
                ConstanteRotas.ENTRADA_MERCADORIA_ENTRADA_IMPORTACAO_CONTINUAR,
                'id',
                response.dados
              )
            );

            return;
          }
        }

        history.push(ConstanteRotas.ENTRADA_MERCADORIA);
      }
    } else {
      toast.error('O arquivo selecionado não é um XML válido.');
    }
  }

  const { handleSelectFile } = useFileUpload({
    onSelectFile: cadastrarEntradaImportacao,
    accept: 'text/xml',
  });

  async function handleIniciarNovaImportacao() {
    if (importacaoAtiva) {
      ModalConfirmacaoExcluir({
        text:
          'Deseja descartar a importação em andamento para iniciar uma nova? Não será possível desfazer esta ação.',
        confirmButtonText: 'Sim, descartar!',
        callback: async (ok: boolean) => {
          if (ok) {
            setIsLoading(true);

            const response = await api.delete<
              void,
              ResponseApi<VerificarImportacaoResponse>
            >(ConstanteEnderecoWebservice.ENTRADA_MERCADORIA_EXCLUIR, {
              params: { id: importacaoAtiva.entradaMercadoriaId },
            });

            if (response) {
              if (response.avisos) {
                response.avisos.map((aviso: string) => toast.warning(aviso));
              }

              if (response.sucesso) {
                history.replace('/');
                history.replace(
                  ConstanteRotas.ENTRADA_MERCADORIA_ENTRADA_IMPORTACAO_INICIAR
                );

                return;
              }
            }

            setIsLoading(false);
          }
        },
      });
    } else {
      // Aqui será feita a importação da NFe direto da Receita Federal
      // importarNfeReceitaFederal();
    }
  }

  function handleContinuarImportacao() {
    if (importacaoAtiva) {
      history.push(
        SubstituirParametroRota(
          ConstanteRotas.ENTRADA_MERCADORIA_ENTRADA_IMPORTACAO_CONTINUAR,
          'id',
          importacaoAtiva.entradaMercadoriaId
        )
      );
    }
  }

  useEffect(() => {
    async function verificarImportacaoExistente() {
      setIsLoading(true);

      const response = await api.get<
        void,
        ResponseApi<VerificarImportacaoResponse>
      >(
        ConstanteEnderecoWebservice.ENTRADA_MERCADORIA_OBTER_IMPORTACAO_XML_EM_ANDAMENTO
      );

      if (response) {
        if (response.avisos) {
          response.avisos.map((aviso: string) => toast.warning(aviso));
        }

        if (response.sucesso) {
          const { entradaMercadoriaId, usuarioId, usuarioNome } =
            response.dados || {};

          if (entradaMercadoriaId && usuarioId) {
            setImportacaoAtiva({ entradaMercadoriaId, usuarioId });

            if (!isAdministrador && usuarioId !== userId) {
              toast.warning(
                `Existe uma importação em andamento, para continuar ou recomeçar a importação entre com o usuário ${usuarioNome}, ou com o usuário administrador.`
              );

              history.push(ConstanteRotas.ENTRADA_MERCADORIA);
            }
          }
        } else {
          history.push(ConstanteRotas.ENTRADA_MERCADORIA);
        }
      } else {
        history.push(ConstanteRotas.ENTRADA_MERCADORIA);
      }

      setIsLoading(false);
    }

    verificarImportacaoExistente();
  }, [history, isAdministrador, userId]);

  return isLargerThan800 ? (
    <Box
      px={{ base: 4, md: 10, lg: 16, xl: 24 }}
      py={{ base: 6, md: 8, lg: 20, xl: 24 }}
      bg="white"
      boxShadow="md"
      borderRadius="md"
      position="relative"
    >
      {isLoading && <LoadingPadrao />}
      <Stack
        alignItems="center"
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
      >
        <VStack spacing={{ base: 4, lg: 6 }} alignItems="flex-start">
          <Heading
            fontWeight="bold"
            color="primary.50"
            fontSize={{ base: 'xl', md: '2xl', xl: '3xl' }}
          >
            {hasImportacaoAtiva
              ? 'Vamos continuar?'
              : 'Importar NFe de entrada'}
          </Heading>
          <Text
            fontWeight="regular"
            fontSize="sm"
            w={{
              base: 'full',
              md: '390px',
              lg: '520px',
              xl: '790px',
            }}
          >
            {hasImportacaoAtiva ? (
              <>
                Que bom que você voltou! Podemos continuar exatamente de onde
                paramos ou podemos começar tudo novamente.{' '}
                <strong>
                  Não é possível manter dois processos simultâneos
                </strong>
                , portanto se preferir começar novamente, a importação do XML
                existente será apagada.
              </>
            ) : (
              <>
                Elaboramos um formato guiado para facilitar a importação do seu
                XML de entrada. É possível importar um arquivo do seu computador
                ou baixá-lo diretamente do site da Receita Federal. Não se
                preocupe, vamos te ajudar explicando cada etapa. Para começar,
                selecione a origem do arquivo:
              </>
            )}
          </Text>

          {hasImportacaoAtiva ? (
            <VStack spacing={5}>
              <Button
                colorScheme="secondary"
                size={buttonSize}
                w="full"
                maxW={{ base: 'full', md: '380px' }}
                onClick={handleContinuarImportacao}
              >
                Continuar com a importação existente
              </Button>
              <Button
                variant="outline"
                size={buttonSize}
                w="full"
                maxW={{ base: 'full', md: '380px' }}
                onClick={handleIniciarNovaImportacao}
                id="cadastrarNovo"
              >
                Iniciar uma nova importação
              </Button>
            </VStack>
          ) : (
            <VStack spacing={5}>
              <Button
                colorScheme="primary"
                size={buttonSize}
                w="full"
                maxW={{ base: 'full', md: '380px' }}
                onClick={handleSelectFile}
              >
                Selecionar arquivo do meu computador
              </Button>
              <Button
                colorScheme="teal"
                size={buttonSize}
                w="full"
                maxW={{ base: 'full', md: '380px' }}
                isActive
                onClick={onOpen}
              >
                Importar NF-e da Receita Federal
              </Button>
            </VStack>
          )}
        </VStack>

        {isOpen && (
          <ModalListaManifesto
            casasDecimais={casasDecimais}
            onClose={onClose}
            isOpen={isOpen}
          />
        )}

        <Flex
          alignItems="center"
          justifyContent={{ base: 'center', md: 'flex-end' }}
          pt={{ base: 10, md: 0 }}
          px={{ base: 10, md: 0 }}
          h="full"
          w="full"
        >
          <Image
            src={
              hasImportacaoAtiva
                ? getImageBySystem(retomarImportarXMLImgs)
                : getImageBySystem(inicioImportarXMLImgs)
            }
            alt={
              hasImportacaoAtiva
                ? 'Continuar entrada por importação'
                : 'Iniciar entrada por importação'
            }
            maxH={{ base: '176px', lg: '243px', xl: 'full' }}
          />
        </Flex>
      </Stack>
    </Box>
  ) : (
    <ResolucaoNaoCompativel
      content="Para consultar o servidor da SEFAZ e verificar as notas fiscais é necessário utilizar um dispositivo com resolução mínima de 800x600 pixels."
      handleVoltar={handleVoltar}
    />
  );
}
