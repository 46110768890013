import React, { useState } from 'react';
import { Button, HStack, useMediaQuery } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import ConstanteRotasPDV from 'constants/rotasPDV';
import { useFinalizarContext } from 'store/PDV/Finalizar';
import { useOperacaoContext } from 'store/PDV/Operacao';

import CompartilharDocumentosFiscais from 'components/PDV/CompartilharDocumentosFiscais';

export const FinalizarCommonFooterButtons = () => {
  const history = useHistory();

  const handlePushNovoPedido = () => {
    history.push(ConstanteRotasPDV.PDV_HOME);
  };

  const handlePushConsultar = () => {
    history.push(ConstanteRotasPDV.PDV_CONSULTAR_OPERACOES);
  };

  return (
    <>
      <Button
        size="sm"
        minW="120px"
        variant="outline"
        onClick={handlePushConsultar}
      >
        Consultar
      </Button>
      <Button
        size="sm"
        minW="120px"
        colorScheme="primary"
        color="secondary.300"
        onClick={handlePushNovoPedido}
      >
        Novo pedido
      </Button>
    </>
  );
};

interface CompartilharProps {
  asModal?: boolean;
}

const Compartilhar = ({ asModal = false }: CompartilharProps) => {
  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');
  const { operacaoId } = useOperacaoContext();
  const { operacao } = useFinalizarContext();

  const [isLoading, setIsLoading] = useState(false);

  return (
    <CompartilharDocumentosFiscais
      asAccordion={isSmallerThan900}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      tipoOperacao={operacao?.identificacaoTipoOperacao}
      {...(asModal ? { px: '0', bg: 'primary.50', pt: 0 } : {})}
      operacao={
        operacao && operacaoId
          ? {
              id: operacaoId,
              numeroOperacao: operacao.numeroOperacao,
              cliente: {
                nome: operacao.cliente.nome,
                email: operacao.cliente.email,
                celular: operacao.cliente.celular,
                telefone: operacao.cliente.telefone,
                contatosAdicionais: operacao.cliente.contatosAdicionais,
              },
              NFCe: operacao.NFCe
                ? {
                    id: operacao.NFCe.id,
                    numero: operacao.NFCe.numero,
                    status: operacao.NFCe.status,
                  }
                : undefined,
              NFe: operacao.NFe
                ? {
                    id: operacao.NFe.id,
                    numero: operacao.NFe.numero,
                    status: operacao.NFe.status,
                  }
                : undefined,
            }
          : undefined
      }
    >
      {!asModal && (
        <HStack justifyContent="center" spacing={6} mt={10}>
          <FinalizarCommonFooterButtons />
        </HStack>
      )}
    </CompartilharDocumentosFiscais>
  );
};

export default Compartilhar;
