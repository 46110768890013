import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export function formatDate(value: Date | string) {
  return format(new Date(value), 'dd/MM/yyyy', {
    locale: ptBR,
  });
}

export function formatDateHourMinute(value: Date | string) {
  return format(new Date(value), 'dd/MM/yyyy HH:mm', {
    locale: ptBR,
  });
}

export function formatDateHourMinuteSecond(value: Date | string) {
  return format(new Date(value), 'dd/MM/yyyy HH:mm:ss', {
    locale: ptBR,
  });
}
