import { Flex, Text } from '@chakra-ui/react';

import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';

interface CabecalhoProps {
  nomeFantasia: string;
  tipoOperacao: number;
}

export const Cabecalho = ({ nomeFantasia, tipoOperacao }: CabecalhoProps) => {
  return (
    <>
      <Flex
        mt="2"
        pt={1}
        pb={1}
        alignItems="center"
        justifyContent="center"
        border="2px solid"
        borderColor="gray.700"
      >
        <Text fontSize="sm" fontWeight="black">
          {nomeFantasia}
        </Text>
      </Flex>
      <Flex
        mt="1"
        pt={2}
        pb={2}
        alignItems="center"
        justifyContent="center"
        borderBottom="1px solid"
        borderColor="gray.700"
      >
        <Text fontSize="sm" fontWeight="black">
          {tipoOperacao ===
          IdentificacaoTipoOperacaoEnum.TRANSFERENCIA_DINHEIRO_SAIDA
            ? 'SANGRIA'
            : 'SUPRIMENTO'}
        </Text>
      </Flex>
    </>
  );
};
