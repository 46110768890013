import {
  Box,
  Flex,
  useMediaQuery,
  Icon,
  Text,
  VStack,
  Image,
  AspectRatio,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { FiChevronLeft } from 'react-icons/fi';

import TaxaZeroSmartPos from 'assets/images/integracoes/smart_pos/03.png';
import SmartPosApp from 'assets/images/integracoes/smart_pos/01.png';
import MaquinaStone from 'assets/images/integracoes/smart_pos/02.png';
import ConstanteRotas from 'constants/rotas';

import {
  LogoStone,
  RestaurantesSmartPosIcon,
  ShowEventoSmartPos,
  FoodtrucksSmartPosIcon,
  RestauranteVarejoSmartPos,
} from 'icons';

export const SmartPos = () => {
  const history = useHistory();
  const handleVoltar = () => {
    history.push(ConstanteRotas.LOJA_APLICATIVOS);
  };

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const [isLargerThan500] = useMediaQuery('(min-width: 500px)');

  return (
    <Box
      bg="white"
      boxShadow="0px 0px 6px #00000034"
      pt="2.9%"
      pl="5.4%"
      pr="5.4%"
      pb="100px"
      borderRadius="5px"
    >
      <Flex mb="16px" justifyContent="flex-start" alignItems="center">
        <Box onClick={() => handleVoltar()} cursor="pointer" mr="16px">
          <Icon color="gray.500" boxSize="25px" as={FiChevronLeft} />
        </Box>
        <Box onClick={() => handleVoltar()} cursor="pointer">
          <Text fontSize="14px" color="gray.500">
            Voltar
          </Text>
        </Box>
      </Flex>
      <Flex
        mb="83px"
        direction={['column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box w={['full', 'full', '550px', '550px', '884px']}>
          <Text
            letterSpacing="0"
            fontSize={['25px', '25px', '32px']}
            color="primary.50"
            fontWeight="extrabold"
          >
            Smart POS:
          </Text>
          <Text
            lineHeight={['', '', '40px']}
            fontSize={['25px', '25px', '32px']}
            letterSpacing="0"
            whiteSpace={['normal', 'normal', 'normal', 'normal', 'nowrap']}
            color="primary.50"
          >
            O PDV móvel para vender seus produtos direto na maquininha.
          </Text>
          {!isLargerThan900 && (
            <Flex
              mt="20px"
              w="full"
              justifyContent="center"
              alignItems="center"
            >
              <Image w="426px" alt="app smart pos" src={SmartPosApp} />
            </Flex>
          )}
          <VStack w={['', '', '', '', '750px']} mb="43px" mt="24px">
            <Text
              lineHeight="20px"
              letterSpacing="0"
              fontSize="16px"
              color="gray.700"
            >
              <span
                style={{
                  fontWeight: 'bold',
                }}
              >
                Smart POS
              </span>{' '}
              é o nosso aplicativo próprio para quem precisa de um método
              operacional simples e com redução de custos. É um PDV móvel
              instalado direto na sua maquininha de cartão de crédito,
              possibilitando que você realize a venda e o pagamento em poucos
              cliques. Pode ser utilizado em qualquer lugar, em um evento
              externo ou na sua loja física, dispensando outros aparelhos do seu
              ponto de venda.
            </Text>
            <Text
              lineHeight="20px"
              letterSpacing="0"
              fontSize="16px"
              color="gray.700"
            >
              O aplicativo é totalmente integrado ao sistema, portanto você não
              terá nenhum problema com o gerenciamento dos produtos e a
              organização por categorias. Isso sem contar a facilidade com
              relatórios e o dashboard das vendas em tempo real.
            </Text>
          </VStack>
        </Box>
        {isLargerThan900 && (
          <Box>
            <Image w="426px" alt="app smart pos" src={SmartPosApp} />
          </Box>
        )}
      </Flex>
      <Flex
        borderRadius="13px"
        h={['auto', 'auto', '338px']}
        p="30px"
        bg="gray.100"
        direction={['column-reverse', 'column-reverse', 'row']}
        justifyContent="center"
        alignItems="center"
      >
        <AspectRatio w={['full', 'full', '400px']} h="225px" ratio={1}>
          <iframe
            title="Sti3"
            src="https://www.youtube.com/embed/SLqXdFwYE1A?"
            allowFullScreen
          />
        </AspectRatio>
        <Box
          ml={['0', '0', '90px']}
          mb={['20px', '20px', '0']}
          w={['full', 'full', '320px']}
        >
          <Text
            letterSpacing="0"
            lineHeight={['', '', '40px']}
            fontSize={['25px', '25px', '32px']}
            color="gray.800"
          >
            Conheça o{' '}
            <span
              style={{
                fontWeight: 'bold',
              }}
            >
              PDV móvel
            </span>{' '}
            que vai simplificar e agilizar o seu atendimento.
          </Text>
        </Box>
      </Flex>
      <Box mb="100px" mt="90px">
        <Text
          letterSpacing="0"
          fontSize={['25px', '25px', '32px']}
          color="primary.50"
          fontWeight="extrabold"
        >
          Plataforma adquirente:
        </Text>
        <Text
          letterSpacing="0"
          lineHeight={['', '', '40px']}
          fontSize={['25px', '25px', '32px']}
          color="primary.50"
        >
          Nosso aplicativo está homologado inicialmente com a{' '}
          <span
            style={{
              fontWeight: 'bold',
            }}
          >
            STONE.
          </span>
        </Text>
        <Flex
          flexDirection={['column', 'column', 'row']}
          pl={['0', '0', '40px']}
          mt={['0', '0', '40px']}
        >
          <Flex
            justifyContent="center"
            w="full"
            mt="30px"
            mb="11px"
            display={['flex', 'flex', 'none']}
          >
            <Text color="gray.700" fontWeight="extrabold" fontSize="24px">
              Sou cliente Stone:
            </Text>
          </Flex>
          <Flex
            w={['full', 'full', '220px']}
            justifyContent={['center', 'center', 'flex-start']}
            mb={['15px', '15px', '0']}
          >
            <Image boxSize="180px" borderRadius="24px" src={MaquinaStone} />
          </Flex>
          <Flex
            w={['full', 'full', 'calc(100% - 220px)']}
            justifyContent="center"
            alignItems="center"
          >
            <Box w="full">
              <Text
                display={['none', 'none', 'flex']}
                color="gray.700"
                fontWeight="extrabold"
                fontSize="24px"
              >
                Sou cliente Stone:
              </Text>
              <Text color="gray.700" fontSize="20px">
                Se você já tem uma conta Stone,{' '}
                <span
                  style={{
                    color: 'black',
                  }}
                >
                  suas tarifas permanecem as mesmas, sem nenhum acréscimo
                </span>
                . Se a sua maquininha for do modelo Smart, provavelmente você
                está apto para utilizar nosso app. Fale conosco para saber como
                instalar e liberar o aplicativo na sua maquininha.
              </Text>
            </Box>
          </Flex>
        </Flex>
        <Flex
          justifyContent="center"
          w="full"
          mt="10px"
          mb="11px"
          display={['flex', 'flex', 'none']}
        >
          <Text color="gray.700" fontWeight="extrabold" fontSize="24px">
            Não sou cliente Stone:
          </Text>
        </Flex>
        <Flex
          pl={['0', '0', '40px']}
          flexDirection={['column', 'column', 'row']}
          mt={['0', '0', '43px']}
        >
          <Flex
            w={['full', 'full', '220px']}
            alignItems="center"
            justifyContent={['center', 'center', 'flex-start']}
          >
            <Flex
              boxSize="180px"
              borderRadius="24px"
              bg="aquamarine.600"
              justifyContent="center"
              alignItems="center"
            >
              <Icon
                borderRadius="24px"
                boxSize="90px"
                color="white"
                as={LogoStone}
              />
            </Flex>
          </Flex>
          <Flex
            mt={['15px', '15px', '0']}
            w={['full', 'full', 'calc(100% - 220px)']}
            justifyContent="center"
            alignItems="center"
          >
            <Box w="full">
              <Text
                display={['none', 'none', 'flex']}
                color="gray.700"
                fontWeight="extrabold"
                fontSize="24px"
              >
                Não sou cliente Stone:
              </Text>
              <Text color="gray.700" fontSize="20px">
                Para utilizar o app será necessário ter uma conta Stone e uma
                maquininha Smart. Fale conosco para entender como abrir uma
                conta Stone já habilitada para utilizar o nosso app. A
                negociação de planos e tarifas será entre você e a operadora,
                totalmente independente do nosso aplicativo.
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Box>
      <Box mb="100px">
        <Text
          letterSpacing="0"
          fontSize={['25px', '25px', '32px']}
          color="primary.50"
          fontWeight="extrabold"
        >
          Ideal para diversos segmentos:
        </Text>
        <Text
          letterSpacing="0"
          lineHeight={['', '', '40px']}
          fontSize={['25px', '25px', '32px']}
          color="primary.50"
        >
          O Smart POS é um grande aliado para pequenos negócios e eventos. Já
          atendemos desde pequenas festas regionais até grandes shows.
        </Text>

        <Flex
          alignItems="center"
          flexDirection={['column', 'column', 'row']}
          justifyContent="space-between"
        >
          <Box>
            <Flex justifyContent="center" alignItems="center" w="full">
              <Icon boxSize="130px" as={RestaurantesSmartPosIcon} />
            </Flex>
            <Text w="130px" fontSize="20px" color="gray.700">
              Restaurantes, Lanchonetes
            </Text>
          </Box>
          <Box>
            <Flex justifyContent="center" alignItems="center" w="full">
              <Icon boxSize="130px" as={FoodtrucksSmartPosIcon} />
            </Flex>
            <Text fontSize="20px" textAlign="center" color="gray.700">
              Food Trucks
            </Text>
          </Box>
          <Box>
            <Flex justifyContent="center" alignItems="center" w="full">
              <Icon
                pl="20px"
                pt="10px"
                boxSize="130px"
                as={RestauranteVarejoSmartPos}
              />
            </Flex>
            <Text fontSize="20px" textAlign="center" color="gray.700">
              Comercio e<br /> varejo em geral
            </Text>
          </Box>
          <Box>
            <Flex justifyContent="center" alignItems="center" w="full">
              <Icon boxSize="130px" as={ShowEventoSmartPos} />
            </Flex>
            <Text fontSize="20px" color="gray.700">
              Shows e eventos
            </Text>
          </Box>
        </Flex>
      </Box>
      <Box>
        <Text
          letterSpacing="0"
          fontSize={['25px', '25px', '32px']}
          color="primary.50"
          fontWeight="extrabold"
        >
          Principais recursos:
        </Text>
        <Text
          letterSpacing="0"
          lineHeight={['', '', '40px']}
          fontSize={['25px', '25px', '32px']}
          color="primary.50"
        >
          Praticidade para suas vendas e mais agilidade no atendimento ao
          cliente.
        </Text>

        <Box mt={['23px', '23px', '33px']}>
          <UnorderedList
            fontSize={['16px', '16px', '20px']}
            lineHeight={['25px', '25px', '40px']}
            color="gray.700"
          >
            <ListItem>
              Sistema simples e rápido, com vendas em poucos segundos;
            </ListItem>
            <ListItem>Sem filas para pagamento</ListItem>
            <ListItem>
              Receba os pagamentos em cartão de crédito, débito, dinheiro, PIX
              ou outras formas específicas;
            </ListItem>
            <ListItem>
              Meios de pagamentos nativos (não precisa de outra maquininha para
              digitar valores);
            </ListItem>
            <ListItem>
              Funciona como um terminal de caixa, com sangria, suprimento e
              fechamento de caixa individual por POS;
            </ListItem>
            <ListItem>
              Mobilidade dentro e fora da loja, com wi-fi ou sinal 3G e 4G;
            </ListItem>
            <ListItem>
              Impressão de fichas e pedidos com senha direto da maquininha
              (personalizados com texto e imagem);
            </ListItem>
            <ListItem>
              Nenhum investimento em compra de equipamentos (CPU, teclado,
              monitor, mouse, nobreak, impressora);
            </ListItem>
            <ListItem>
              Totalmente integrado ao sistema, centralizando cadastro de
              produtos, categorias, estoque;
            </ListItem>
            <ListItem>
              Sem acréscimos nas tarifas (Você negocia diretamente com a Stone
              os melhores planos e tarifas operacionais);
            </ListItem>
          </UnorderedList>
        </Box>
      </Box>
      <Box mt="40px">
        {isLargerThan900 ? (
          <Flex
            bg="transparent linear-gradient(180deg, #B91B51 0%, #5D0E29 100%) 0% 0% no-repeat"
            borderRadius="12px"
            h="174px"
            w="full"
            justifyContent="center"
            alignItems="center"
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              pl="56px"
              h="full"
            >
              <Image src={TaxaZeroSmartPos} />
            </Flex>
            <Flex
              pl="10px"
              pr="10px"
              w="full"
              alignItems="center"
              justifyContent="center"
            >
              <Text w="333px" color="#FFFFFF" fontSize="16px">
                Você paga apenas a mensalidade por dispositivo ativado. Sem
                fidelidade contratual, cancele quando quiser.
              </Text>
            </Flex>
          </Flex>
        ) : (
          <Box
            bg="transparent linear-gradient(180deg, #B91B51 0%, #5D0E29 100%) 0% 0% no-repeat"
            w="full"
            borderRadius="12px"
            h={isLargerThan500 ? '408px' : '308px'}
          >
            <Box
              pt="20px"
              mb={isLargerThan500 ? '20px' : '0'}
              h={isLargerThan500 ? '30%' : '15%'}
            >
              <Image src={TaxaZeroSmartPos} />
            </Box>
            <Flex
              pl="10px"
              pr="10px"
              h={`calc(100% - ${isLargerThan500 ? '25%' : '10%'})`}
              w="full"
              alignItems="center"
              justifyContent="center"
            >
              <Text w="204px" color="#FFFFFF" fontSize="16px">
                Você paga apenas a mensalidade por Dispositivo ativado. Sem
                fidelidade Contratual, cancele quando quiser.
              </Text>
            </Flex>
          </Box>
        )}
      </Box>
      <Box mt="91px">
        <Text
          letterSpacing="0"
          fontSize={['25px', '25px', '32px']}
          color="primary.50"
          fontWeight="extrabold"
        >
          Como contratar:
        </Text>
        <Text
          letterSpacing="0"
          lineHeight={['', '', '40px']}
          fontSize={['25px', '25px', '32px']}
          color="primary.50"
        >
          O aplicativo Smart POS está disponível para todos os planos. Para
          saber sobre preço, contratação ou outros detalhes operacionais, entre
          em contato.
        </Text>
      </Box>
    </Box>
  );
};
