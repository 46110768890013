import { Text, Icon, Flex, Grid, GridItem } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import enumPainelAdministradorFrenteCaixa from 'constants/enum/enumPainelAdministradorFrenteCaixa';

import { IsotipoFrenteCaixa } from 'icons';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { ContainerHeader } from '../../Layout/ContainerHeader';
import { usePainelFrenteCaixa } from './hooks';

export const PainelAdministradorFrenteCaixa = () => {
  const history = useHistory();

  const { isLoading } = usePainelFrenteCaixa();

  return (
    <>
      {isLoading && <LoadingPadrao />}
      <ContainerHeader justifyContent="space-between" bg="#FF005A">
        <Flex alignItems="center " gap={2}>
          <IsotipoFrenteCaixa size={35} />
          <Text textColor="white" fontWeight="semibold" fontSize="16px">
            Frente de Caixa
          </Text>
        </Flex>
      </ContainerHeader>
      {!isLoading && (
        <Flex my="60px" justifyContent="center">
          <Grid
            templateColumns={[
              'repeat(1, 1fr)',
              'repeat(2, 1fr)',
              'repeat(3, 1fr)',
              'repeat(4, 1fr)',
            ]}
            columnGap="16px"
            gap={6}
          >
            {enumPainelAdministradorFrenteCaixa.options.map(
              ({ desabilitado, rota, icone, nome }) => {
                if (desabilitado) {
                  return null;
                }

                return (
                  <GridItem
                    key={rota}
                    w="240px"
                    minW="200px"
                    h="108px"
                    bg="white"
                    transition="all 0.2s"
                    boxShadow="0px 0px 4px #00000029"
                    borderRadius="md"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    cursor="pointer"
                    onClick={() => history.push(rota)}
                    _hover={{
                      boxShadow: '0px 0px 4px #FF005A',
                    }}
                  >
                    <Icon fontSize="45px" as={icone} />
                    <Text
                      textAlign="center"
                      fontSize="16px"
                      textColor="#0F0F0F"
                    >
                      {nome}
                    </Text>
                  </GridItem>
                );
              }
            )}
          </Grid>
        </Flex>
      )}
    </>
  );
};
