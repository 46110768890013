export const TipoUtilizacaoCadastro = {
  PRODUTO_ZENDAR: 0,
  PRODUTO_SITE: 1,
  PRODUTO_SITE_AUTOMATICO: 2,
};

export const dataDefaultCadastro = [
  {
    title: 'Enviar os produtos do sistema para a loja virtual',
    isDisable: false,
    isRecomendado: true,
    isChecked: true,
    value: TipoUtilizacaoCadastro.PRODUTO_ZENDAR,
    description:
      'Cadastre os produtos no sistema e envie para o site. É a melhor forma para padronizar as informações e manter de forma organizada a estrutura e os detalhes de cada item.',
  },

  {
    title: 'Importar os produtos do site com CADASTRO AUTOMÁTICO',
    isRecomendado: false,
    isDisable: false,
    value: TipoUtilizacaoCadastro.PRODUTO_SITE_AUTOMATICO,
    description:
      'Os produtos serão importados do site e cadastrados automaticamente no sistema (Apenas os dados disponibilizados pela plataforma). Posteriormente será necessário completar os cadastros com informações faltantes ou essenciais para emissão fiscal.',
  },
];
