import styled from 'styled-components';
import { Col as BootstrapCol } from 'react-bootstrap';

export const Container = styled.div``;

export const ColCheckBox = styled(BootstrapCol)`
  // Menor que 700px
  margin-top: 0;
  display: block;
  // 700px até 899px
  @media (min-width: 700px) {
    margin-top: 1.5rem;
    display: inline-flex !important;
  }
`;

export const ColSpan = styled(BootstrapCol)`
  // Menor que 700px
  margin-top: 15px;
  margin-bottom: 15px;

  // 700px até 899px
  @media (min-width: 700px) {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

export const HeaderInformacoesMultaJuros = styled.div`
  width: 100%;
  background-color: var(--sti-ck-colors-gray-100);
  padding: 11px;
`;

export const SpanInformacoesMultaJuros = styled.span`
  text-align: left;
  font: normal normal 600 13px/16px Nunito;
  letter-spacing: 0px;
  color: var(--black);
  opacity: 1;
`;
