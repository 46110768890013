import React, { useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, GridItem, Link, Td, Tr, Text } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import auth from 'modules/auth';
import api, { ResponseApi } from 'services/api';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import ConstanteFuncionalidades from 'constants/permissoes';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { shallowEqual } from 'helpers/validation/shallowEqual';
import MeioPagamentoEnum from 'constants/enum/fiscal/meioPagamento';
import TipoFormaPagamentoRecebimentoEnum from 'constants/enum/tipoFormaPagamentoRecebimento';

import { SearchInput } from 'components/update/Input/SearchInput';
import { ModalDesistencia } from 'components/Modal/ModalDesistencia';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { ModalConfirmacaoInativar } from 'components/Modal/ModalConfirmacaoInativar';
import {
  GridPaginadaConsulta,
  GridPaginadaRetorno,
} from 'components/Grid/Paginacao';
import {
  PagedTable,
  PagedTableForwardRefData,
} from 'components/update/Table/PagedTable';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { StatusCircle } from 'components/update/Table/StatusCircle';
import {
  ActionsMenu,
  ActionMenuItem,
} from 'components/update/Table/ActionsMenu';
import { FilterSelect } from 'components/update/Select/FilterSelect';
import { ButtonCadastrarNovo } from 'components/Layout/ButtonCadastrarNovo';

interface FormaPagamentoFiltros {
  nome: string;
  ativo: boolean;
}

interface FormaPagamento {
  id: string;
  nome: string;
  ativo: boolean;
  padraoSistema: boolean;
  meioPagamento: number;
}

const formDefaultValues = {
  nome: '',
  ativo: true,
};

const Listar = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [FormasPagamentos, setFormasPagamentos] = useState(
    [] as Array<FormaPagamento>
  );
  const [currentFilters, setCurrentFilters] = useState<FormaPagamentoFiltros>(
    formDefaultValues
  );

  const history = useHistory();
  const isMountedRef = useIsMountedRef();

  const formMethods = useForm({
    defaultValues: formDefaultValues,
  });
  const { setFocus, handleSubmit } = formMethods;

  const pageIsLoaded = useRef(false);
  const childRef = useRef<PagedTableForwardRefData>(null);

  const permissaoFormaPagamentoAlterar = auth.possuiPermissao(
    ConstanteFuncionalidades.FORMA_PAGTO_ALTERAR
  );

  const permissaoFormaPagamentoVisualizar = auth.possuiPermissao(
    ConstanteFuncionalidades.FORMA_PAGTO_VISUALIZAR
  );

  const handleHistoryPush = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history]
  );

  const getNomeLinkHref = useCallback(
    (id: string) => {
      let href = '';

      if (permissaoFormaPagamentoAlterar.permitido) {
        href = SubstituirParametroRota(
          ConstanteRotas.FORMA_PAGTO_ALTERAR,
          'id',
          id
        );
      } else if (permissaoFormaPagamentoVisualizar.permitido) {
        href = SubstituirParametroRota(
          ConstanteRotas.FORMA_PAGTO_VISUALIZAR,
          'id',
          id
        );
      }

      return href;
    },
    [permissaoFormaPagamentoAlterar, permissaoFormaPagamentoVisualizar]
  );

  const handleReset = handleSubmit((data) => {
    const filtersIsDirty = !shallowEqual(data, currentFilters || {});

    if (filtersIsDirty) {
      setCurrentFilters(data);
    }
  });

  const handleRefresh = useCallback(() => {
    return childRef.current?.reload();
  }, []);

  const paginationHandle = useCallback(
    async (gridPaginadaConsulta: GridPaginadaConsulta) => {
      setIsLoading(true);

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<FormaPagamento>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.FORMA_PAGTO_LISTAR_PAGINADO,
          gridPaginadaConsulta
        ),
        {
          params: {
            nome: currentFilters.nome,
            ativo: currentFilters.ativo,
            tipoFormaPagamentoRecebimento:
              TipoFormaPagamentoRecebimentoEnum.Pagamento,
          },
        }
      );

      if (response?.sucesso && isMountedRef.current) {
        setTotalRegistros(response.dados.total);

        setFormasPagamentos(response.dados.registros);
      }

      if (isMountedRef.current) {
        setIsLoading(false);

        if (!pageIsLoaded.current) {
          pageIsLoaded.current = true;

          setFocus('nome');
        }
      }
    },
    [currentFilters, isMountedRef, setFocus]
  );

  const updateStatus = useCallback(
    async (id: string, webService: string, status: boolean) => {
      const response = await api.put<void, ResponseApi>(
        `${webService}?id=${id}`
      );

      if (response) {
        if (response.avisos) {
          if (status === true) {
            const isSaldoEmAberto = response.avisos[0].includes(
              'forma-com-saldo'
            );
            ModalDesistencia({
              showCancelButton: false,
              title: isSaldoEmAberto
                ? 'Existe um saldo em aberto'
                : 'Não foi possível inativar',
              labelButtonConfirmar: 'Ok',
              textoMensagem: isSaldoEmAberto
                ? 'Para inativar uma forma de pagamento ou recebimento é necessário que o saldo dessa mesma forma esteja zerado em todos os caixas. Verifique se existe algum caixa com saldo positivo ou negativo utilizando essa forma, efetue o ajuste e retorne para confirmar a inativação'
                : response.avisos[0],
              callback: () => {},
            });
          } else {
            response.avisos.forEach((item: string) => toast.warning(item));
          }
        }
        if (response.sucesso) {
          toast.success(
            `O cadastro foi ${
              status === true ? 'inativado' : 'ativado'
            } com sucesso`
          );

          handleRefresh();
        }
      }
    },
    [handleRefresh]
  );

  const handleUpdatePaymentMethodStatus = async (
    id: string,
    status: boolean
  ) => {
    if (status === false) {
      updateStatus(id, ConstanteEnderecoWebservice.FORMA_PAGTO_ATIVAR, status);
    } else {
      ModalConfirmacaoExcluir({
        confirmButtonText: 'Sim, inativar',
        callback: async (ok: boolean) => {
          if (ok) {
            setIsLoading(true);
            updateStatus(
              id,
              ConstanteEnderecoWebservice.FORMA_PAGTO_INATIVAR,
              status
            );
            setIsLoading(false);
          }
        },
      });
    }
  };

  const excluirHandle = useCallback(
    async (forma_pagto_Id: string, ativo: boolean) => {
      ModalConfirmacaoExcluir({
        callback: async (ok: boolean) => {
          if (ok) {
            setIsLoading(true);

            const response = await api.delete<void, ResponseApi>(
              ConstanteEnderecoWebservice.FORMA_PAGTO_EXCLUIR,
              {
                params: { id: forma_pagto_Id },
              }
            );

            if (response?.sucesso) {
              toast.success('O cadastro foi removido com sucesso.');

              handleRefresh();
            }

            if (response) {
              ModalConfirmacaoInativar({
                response,
                callbackAvisos: true,
                rotaWebService:
                  ConstanteEnderecoWebservice.FORMA_PAGTO_INATIVAR,
                id: forma_pagto_Id,
                ativo,
                callback: (okInativar: boolean) => {
                  if (okInativar) handleRefresh();
                },
              });
            }

            setIsLoading(false);
          }
        },
      });
    },
    [handleRefresh]
  );

  return (
    <SimpleGridForm gap={{ base: '10px', sm: '10px', md: 8 }}>
      <FormProvider {...formMethods}>
        <GridItem colSpan={{ base: 12, md: 5, lg: 6 }}>
          <SearchInput
            type="search"
            onEnterKeyPress={() => handleReset()}
            placeholder="Buscar forma de pagamento por nome"
            isDisabled={isLoading}
            id="nome"
            name="nome"
            maxLength={40}
          />
        </GridItem>

        <GridItem colSpan={{ base: 12, md: 2, lg: 2 }}>
          <Box w={{ base: 'full', md: '190px', lg: '190px' }}>
            <FilterSelect
              id="ativo"
              name="ativo"
              isDisabled={isLoading}
              options={[
                {
                  value: true,
                  label: 'Ativos',
                },
                {
                  value: false,
                  label: 'Inativos',
                },
                {
                  value: null,
                  label: 'Todos',
                },
              ]}
              onSelect={() => {
                handleReset();
              }}
            />
          </Box>
        </GridItem>

        <GridItem
          colSpan={{ base: 12, md: 5, lg: 4 }}
          display={{ base: '', sm: '', md: 'flex' }}
          justifyContent={{ base: 'flex-start', sm: 'flex-end' }}
        >
          <ButtonCadastrarNovo
            onClick={() =>
              handleHistoryPush(ConstanteRotas.FORMA_PAGTO_CADASTRAR)
            }
            funcionalidade={ConstanteFuncionalidades.FORMA_PAGTO_CADASTRAR}
          />
        </GridItem>
        <GridItem mt={['10px', '10px', '-10px']} colSpan={12}>
          <PagedTable
            ref={childRef}
            itemsTotalCount={totalRegistros}
            loadColumnsData={paginationHandle}
            isLoading={isLoading}
            defaultKeyOrdered="nome"
            tableHeaders={[
              {
                content: <StatusCircle hasValue={false} />,
                key: 'Ativo',
                isOrderable: true,
                width: '37px',
              },
              {
                content: 'Nome',
                key: 'Nome',
                isOrderable: true,
                width: 'auto',
              },
              {
                content: 'Ações',
                key: 'Acoes',
                isOrderable: false,
                width: '38px',
              },
            ]}
            renderTableRows={FormasPagamentos.map((FormaPagamento) => {
              const isFormaPagamentoPadraoSistema =
                FormaPagamento.padraoSistema;

              const meioPagamentoIsCheque =
                MeioPagamentoEnum.Cheque === FormaPagamento.meioPagamento;

              const podeModificarFormaPagamento =
                !isFormaPagamentoPadraoSistema || meioPagamentoIsCheque;

              const actions = !isFormaPagamentoPadraoSistema
                ? [
                    {
                      content: 'Editar',
                      onClick: () => {
                        handleHistoryPush(
                          SubstituirParametroRota(
                            ConstanteRotas.FORMA_PAGTO_ALTERAR,
                            'id',
                            FormaPagamento.id
                          )
                        );
                      },
                      funcionalidade:
                        ConstanteFuncionalidades.FORMA_PAGTO_ALTERAR,
                    },
                    {
                      content: 'Remover',
                      onClick: () => {
                        excluirHandle(FormaPagamento.id, FormaPagamento.ativo);
                      },
                      funcionalidade:
                        ConstanteFuncionalidades.FORMA_PAGTO_EXCLUIR,
                    },
                  ]
                : [
                    {
                      content: FormaPagamento.ativo ? 'Inativar' : 'Ativar',
                      onClick: () => {
                        handleUpdatePaymentMethodStatus(
                          FormaPagamento.id,
                          FormaPagamento.ativo
                        );
                      },
                    },
                  ];

              return (
                <Tr key={FormaPagamento.id}>
                  <Td style={{ textAlign: 'center' }}>
                    <StatusCircle isActive={FormaPagamento.ativo} />
                  </Td>
                  <Td>
                    {FormaPagamento.padraoSistema ? (
                      <Text>{FormaPagamento.nome}</Text>
                    ) : (
                      <Link
                        id="link-visualizar"
                        href={getNomeLinkHref(FormaPagamento.id)}
                      >
                        {FormaPagamento.nome}
                      </Link>
                    )}
                  </Td>
                  <Td>
                    {podeModificarFormaPagamento && (
                      <ActionsMenu
                        id="mostrarMais"
                        items={actions as ActionMenuItem[]}
                      />
                    )}
                  </Td>
                </Tr>
              );
            })}
          />
        </GridItem>
      </FormProvider>
    </SimpleGridForm>
  );
};

export default Listar;
