import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import TipoValorEnum from 'constants/enum/tipoValor';

const schema = yup.object().shape({
  produtoCorTamanhoId: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(null),
  volumeUnitario: yup.boolean().default(false),
  quantidade: yup
    .number()
    .when('volumeUnitario', {
      is: true,
      then: (type: yup.NumberSchema) =>
        type
          .required(ConstanteMensagemValidacao.VALOR_MINIMO_1)
          .typeError(ConstanteMensagemValidacao.VALOR_MINIMO_1)
          .min(1, ConstanteMensagemValidacao.VALOR_MINIMO_1),
      otherwise: (type: yup.NumberSchema) =>
        type
          .required(ConstanteMensagemValidacao.VALOR_MINIMO_1)
          .positive(ConstanteMensagemValidacao.VALOR_MINIMO_1),
    })
    .default(1),
  tipoDescontoItem: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(TipoValorEnum.REAIS),
  valorUnitario: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(0),
  descontoItem: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(0)
    .test(
      'verificarMaximoDescontoItem',
      ConstanteMensagemValidacao.DESCONTO_ITEM_MAIOR_QUE_TOTAL,
      function verificarQuantidadeMinimaDiasAtrasoBloqueio() {
        const {
          quantidade,
          valorUnitario,
          tipoDescontoItem,
          descontoItem,
        } = this.parent;

        const valorItem = quantidade * valorUnitario;
        const valorDescontoItem =
          tipoDescontoItem === TipoValorEnum.REAIS
            ? descontoItem
            : (descontoItem / 100) * valorItem;

        if (valorDescontoItem > valorItem) {
          return false;
        }
        return true;
      }
    ),
});

export const yupResolver = yupResolverInstance(schema);

export const defaultValues = { ...schema.default(), valorTotal: 0 } as Record<
  string,
  any
>;

export const useForm = useFormInstance;
