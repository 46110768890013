import { useCallback, useRef, useState } from 'react';

import { enumTypeCarne } from 'constants/enum/enumTypeCarne';

import { ImpressaoCarnePromissoriaRefElement } from 'components/Impressao/Carne/types';

export const useCompartilharRecebimentos = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [typeCarne, setTypeCarne] = useState(enumTypeCarne.NORMAL);

  const impressaoCarneRef = useRef<ImpressaoCarnePromissoriaRefElement>(null);
  const impressaoCarneContainer = useRef<HTMLDivElement>(null);

  const openModalEscolherParcelasImpressaoCarne = useCallback(
    async (movimentacaoFinanceiraId: string) => {
      if (impressaoCarneRef.current) {
        setIsLoading(true);
        const dados = await impressaoCarneRef.current.fetchPrintInformation();
        setIsLoading(false);
        if (dados && dados.recebimentos) {
          if (
            dados?.recebimentos?.find(
              (item) => item.id === movimentacaoFinanceiraId
            )
          ) {
            impressaoCarneRef.current.printParcelas([movimentacaoFinanceiraId]);
          }
        }
      }
    },
    []
  );

  const handleImpressaoCarne = useCallback(
    async (type: string, movimentacaoFinanceiraId: string) => {
      setTypeCarne(type);

      await openModalEscolherParcelasImpressaoCarne(movimentacaoFinanceiraId);
    },
    [openModalEscolherParcelasImpressaoCarne]
  );

  return {
    isLoading,
    typeCarne,
    impressaoCarneRef,
    impressaoCarneContainer,
    handleImpressaoCarne,
  };
};
