import { useState, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import api, { ResponseApi } from 'services/api';
import TipoProdutoEnum from 'constants/enum/tipoProduto';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { useProdutosFormularioContext } from 'store/Produtos/ProdutosFormulario';

import { ModalDesistencia } from 'components/Modal/ModalDesistencia';

import { PrincipalProdutoProps } from '../../TabsContent/Principal';

export const useCadastroItem = (
  deleteProduct: (produtoId: string) => Promise<void>
) => {
  const productIsRemoved = useRef(true);

  const history = useHistory();

  const {
    tipoProduto,
    idProduto,
    hasVariation,
    setIsLoading,
    hasKit,
  } = useProdutosFormularioContext();

  const [pathnameRoute, setPathnameRoute] = useState<undefined | string>(
    undefined
  );

  const isKitProduct = tipoProduto === TipoProdutoEnum.PRODUTO_KIT;
  const isVariacaoProduct = tipoProduto === TipoProdutoEnum.PRODUTO_VARIACAO;

  const title = `Informe ${
    isKitProduct ? 'um produto no Kit!' : 'uma variação!'
  }`;

  const message = `É necessário cadastrar ao menos ${
    isKitProduct ? 'um produto para o kit' : 'uma variação'
  }. Se o produto não  ${
    isKitProduct ? 'é do tipo kit' : 'possui variações'
  }, retorne para o card principal e altere o tipo do produto para “Simples”. Se preferir, você pode desistir do cadastro e todas as informações serão removidas.`;

  const allowExitScreen = useCallback(
    (pathname: string) => {
      setPathnameRoute(pathname);
      productIsRemoved.current = false;

      history.push(pathname);
    },
    [history]
  );

  const desistProduct = async () => {
    await deleteProduct(idProduto);
    history.push(pathnameRoute as string);
  };

  const getHasProduct = useCallback(async () => {
    if (!idProduto) {
      return false;
    }

    const response = await api.get<void, ResponseApi<PrincipalProdutoProps>>(
      `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}`
    );

    if (response) {
      return !!(response.sucesso && response.dados);
    }

    return false;
  }, [idProduto]);

  const handleExitRegistration = async (pathname: string) => {
    const productHasKit = await hasKit();
    const productHasVariation = await hasVariation();
    const hasProduct = await getHasProduct();

    const withoutKit = isKitProduct && !productHasKit;
    const withoutVariation = isVariacaoProduct && !productHasVariation;
    const productNotExist = !(hasProduct && productIsRemoved.current);

    if (pathnameRoute || productNotExist) {
      allowExitScreen(pathname);
      return;
    }

    if (withoutKit || withoutVariation) {
      await ModalDesistencia({
        title,
        labelButtonCancelar: 'Desistir do cadastro',
        labelButtonConfirmar: 'Continuar editando',
        textoMensagem: message,
        callback: () => {
          setIsLoading(false);
        },
        callbackCancel: () => {
          desistProduct();
          allowExitScreen(pathname);
        },
      });

      return;
    }

    allowExitScreen(pathname);
  };

  const validatePrompt =
    productIsRemoved.current === true &&
    idProduto.length > 0 &&
    (isKitProduct || isVariacaoProduct) &&
    pathnameRoute === undefined;

  return {
    validatePrompt,
    handleExitRegistration,
  };
};
