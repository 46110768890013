const TiposEventosEnum = {
  Cancelamento: 110111,
  CartaCorrecao: 110110,
  CIENCIA_OPERACAO: 210210,
  CONFIRMACAO_OPERACAO: 210200,
  DESCONHECIMENTO_OPERACAO: 210220,
  OPERACAO_NAO_REALIZADA: 210240,

  properties: {
    1: { name: 'Cancelamento', value: 110111 },
    2: { name: 'Carta de correção', value: 110110 },
    3: { name: 'Ciência da operação', value: 210210 },
    4: { name: 'Confirmação da operação', value: 210200 },
    5: { name: 'Desconhecimento da operação', value: 210220 },
    6: { name: 'Operação não realizada', value: 210240 },
  },
};

export default TiposEventosEnum;
