import { Flex, Icon, IconButton, Text } from '@chakra-ui/react';
import { FiChevronLeft } from 'react-icons/fi';

interface MobileModalMenuHeaderProps {
  voltarIconColor: string;
  color: string;
  onClose: () => void;
  isTrocas?: boolean;
  title: string;
}

export function MobileModalMenuHeader({
  voltarIconColor,
  color,
  isTrocas,
  onClose,
  title,
}: MobileModalMenuHeaderProps) {
  return (
    <Flex
      h="56px"
      alignItems="center"
      justifyContent="space-between"
      pl={isTrocas ? '3' : '6'}
      pr="6"
    >
      <IconButton
        aria-label="Voltar"
        icon={<Icon as={FiChevronLeft} boxSize="6" />}
        variant="link"
        color={voltarIconColor}
        onClick={onClose}
        minW="8"
      />

      <Text color={color} fontSize="sm" mr="2">
        {title}
      </Text>
    </Flex>
  );
}
