import React, { memo, useCallback, useRef, useEffect } from 'react';
import {
  Controller,
  Control,
  FieldError,
  UseFormSetError,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { rgMask, ieMask } from 'helpers/format/fieldsMasks';
import { Tooltip } from 'react-bootstrap';
import { SemPermissaoIcon } from 'icons';
import OverlayTriggerPadrao from 'components/OverlayTriggerPadrao';
import { InputControl } from '../styles';
import { AppendContainerIsento, IsentoButton } from './styles';

import {
  Container,
  LabelContainer,
  Label,
  MensagemValidacao,
  InfoIcon,
} from '../../Layout/CampoContainer/CampoContainerPadrao/styles';

export interface InputRgIeProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  name: string;
  label: string;
  infoText?: string;
  error?: FieldError | null | undefined;
  control: Control<Record<string, any>>;
  defaultValue?: string;
  setValue: (name: string, value: string) => void;
  setError: UseFormSetError<Record<string, any>>;
  isRg: boolean;
  disabled?: boolean;
  alterarMascaraRg?: boolean;
  validarRg?: boolean;
  helperText?: string;
  clickHelperText?: () => void;
}

const InputRgIeIsento = ({
  id,
  name,
  label,
  error = null,
  validarRg,
  clickHelperText,
  required,
  alterarMascaraRg = false,
  infoText,
  control,
  defaultValue = '',
  setValue,
  setError,
  isRg: valueRg,
  disabled,
  helperText,
  ...rest
}: InputRgIeProps) => {
  const fieldName = `${name}isento`;

  const inputRef = useRef<HTMLInputElement>();
  const { t } = useTranslation('validation');

  const isRg = valueRg && alterarMascaraRg === false;

  const getFormattedValue = useCallback(
    (value: string) => {
      if (value && value.trim() !== '') {
        if (isRg) {
          return rgMask(value);
        }
        if (validarRg) {
          return rgMask(value);
        }
        return ieMask(value);
      }
      return '';
    },
    [isRg, validarRg]
  );

  useEffect(() => {
    if (inputRef.current)
      setValue(name, getFormattedValue(inputRef.current.value));
  }, [isRg]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Controller
      defaultValue={defaultValue}
      render={({ field }) => {
        const { onChange: setIsIsento, value: isIsento } = field;
        return (
          <Container>
            {label && (
              <LabelContainer>
                <Label id={`label-for-${id}`} style={{ width: '100%' }}>
                  {label}
                  {required && ' *'}
                  {!isRg && !helperText && (
                    <IsentoButton
                      tabIndex="-1"
                      id={`button-isento-for-${id}`}
                      disabled={disabled}
                      onClick={() => {
                        if (!isIsento) {
                          setError(name, {});
                        }

                        setIsIsento(!isIsento);
                      }}
                    >
                      {isIsento ? t('Não Isento') : t('Isento')}
                    </IsentoButton>
                  )}
                </Label>

                {infoText && (
                  <OverlayTriggerPadrao
                    placement="right"
                    overlay={(props: any) => {
                      return <Tooltip {...props}>{infoText}</Tooltip>;
                    }}
                  >
                    <InfoIcon />
                  </OverlayTriggerPadrao>
                )}
                {helperText && (
                  <IsentoButton
                    tabIndex="-1"
                    onClick={() => {
                      if (clickHelperText) {
                        clickHelperText();
                      }
                    }}
                  >
                    {helperText}
                  </IsentoButton>
                )}
              </LabelContainer>
            )}

            <Controller
              defaultValue={defaultValue}
              render={({ field: fieldValues }) => {
                return (
                  <>
                    {(!isIsento || isRg) && (
                      <InputControl
                        inputMode="numeric"
                        id={id}
                        ref={(e: any) => {
                          inputRef.current = e;
                          fieldValues.ref(e);
                        }}
                        value={getFormattedValue(fieldValues.value)}
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                          let formattedValue = '';

                          const isValueRg =
                            e.currentTarget.value
                              ?.replaceAll('.', '')
                              .replaceAll('-', '')?.length < 10;
                          if (isValueRg) {
                            formattedValue = rgMask(e.currentTarget.value);
                          } else {
                            formattedValue = ieMask(e.currentTarget.value);
                          }

                          fieldValues.onChange(formattedValue);
                        }}
                        maxLength={isRg ? 14 : 20}
                        disabled={disabled}
                        {...rest}
                      />
                    )}
                    {isIsento && !isRg && (
                      <>
                        <InputControl
                          inputMode="text"
                          id={id}
                          ref={(e: any) => {
                            inputRef.current = e;
                            fieldValues.ref(e);
                          }}
                          value="Isento"
                          onChange={(e: React.FormEvent<HTMLInputElement>) => {
                            const formattedValue = getFormattedValue(
                              e.currentTarget.value
                            );

                            field.onChange(formattedValue);
                          }}
                          maxLength={isRg ? 14 : 20}
                          disabled
                          {...rest}
                        />

                        <AppendContainerIsento>
                          <SemPermissaoIcon />
                        </AppendContainerIsento>
                      </>
                    )}
                  </>
                );
              }}
              // onFocus={() => {
              //   inputRef.current.focus();
              // }}
              name={`${name}` as const}
              control={control}
            />

            {error && (
              <MensagemValidacao id={`mensagem-validacao-${id}`}>
                {t(error.message as any)}
              </MensagemValidacao>
            )}
          </Container>
        );
      }}
      name={`${fieldName}` as const}
      control={control}
    />
  );
};

export default memo(InputRgIeIsento);
