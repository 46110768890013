import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

interface ItemSimplesProps extends BoxProps {
  children: React.ReactNode;
}

const ItemSimples: React.FC<ItemSimplesProps> = ({ children, ...rest }) => {
  return (
    <Box
      position="relative"
      fontSize="xs"
      color="gray.300"
      height={{ base: '56px', md: 'auto' }}
      width={{ base: '100%', md: 'auto' }}
      minW={{ base: undefined, md: 'min' }}
      py={{ base: 4, md: 0 }}
      px={0}
      borderRadius={{ base: 0, md: 'md' }}
      borderColor="pdv.divider"
      borderTopStyle="solid"
      borderTopWidth={{ base: '1px', md: '0px' }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default ItemSimples;
