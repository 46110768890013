import React from 'react';
import { useToken } from '@chakra-ui/react';

import { ModalTitleContainer, ModalTitle } from 'styles';
import { Form } from 'react-bootstrap';
import { PulseLoader } from 'react-spinners';
import { Modal } from './styles';

interface ModalTransmissaoNotaProps {
  show: boolean;
  message: string;
  title?: string;
}

const ModalTransmissaoNota: React.FC<ModalTransmissaoNotaProps> = ({
  show,
  message,
  title = 'Transmissão da Nota Fiscal',
}) => {
  const primary50 = useToken('colors', 'primary.50');

  return (
    <Modal show={show} centered size="lg">
      <Modal.Header closeButton={false}>
        <ModalTitleContainer>
          <ModalTitle>{title}</ModalTitle>
        </ModalTitleContainer>
      </Modal.Header>
      <Form>
        <Modal.Body style={{ marginTop: '10px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '0px',
              fontSize: '18px',
            }}
          >
            <p>{message}</p>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '25px',
            }}
          >
            <PulseLoader color={primary50} margin={8} size={10} />
          </div>
        </Modal.Body>
      </Form>
    </Modal>
  );
};

export default ModalTransmissaoNota;
