import { Box, Flex, Text, Divider } from '@chakra-ui/react';

import SelectPadrao from 'components/PDV/Select/SelectPadrao';

export type OptionsProps = {
  label: string;
  value: string;
  padraoSistema: boolean;
};

type ContainerProps = {
  name: string;
  options: OptionsProps[];
  title: string;
  isClearable?: boolean;
  isDisabled?: boolean;
};

export const Container = ({
  name,
  options,
  title,
  isClearable = false,
  isDisabled = false,
}: ContainerProps) => {
  return (
    <Box>
      <Flex alignItems="center" justifyContent="space-between">
        <Text whiteSpace="nowrap">{title}</Text>
        <Box w="300px">
          <SelectPadrao
            isDisabled={isDisabled}
            isClearable={isClearable}
            name={name}
            id={name}
            options={options}
          />
        </Box>
      </Flex>
      <Divider my="15px" />
    </Box>
  );
};
