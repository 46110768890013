export type TipoPlanoConta = 1 | 2 | 3;

const TipoPlanoContaEnum = {
  TOTALIZADOR: 1,
  CREDITO: 2,
  DEBITO: 3,

  properties: {
    1: { name: 'Totalizador', value: 1 },
    2: { name: 'Crédito', value: 2 },
    3: { name: 'Débito', value: 3 },
  },
};

export default TipoPlanoContaEnum;
