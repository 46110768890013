import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

interface CabecalhoProps {
  nomeFantasia: string;
  cnpjTelefone: string;
  enderecoLoja: string;
}

const Cabecalho = ({
  nomeFantasia,
  cnpjTelefone,
  enderecoLoja,
}: CabecalhoProps) => {
  return (
    <>
      <Flex
        py={1}
        px={2}
        alignItems="center"
        justifyContent="center"
        border="2px solid"
        borderColor="gray.700"
        direction="column"
      >
        <Text fontSize="md" color="black" fontWeight="900" textAlign="center">
          {nomeFantasia}
        </Text>
        <Text
          fontSize="x-small"
          whiteSpace="nowrap"
          color="black"
          fontWeight="900"
          textAlign="center"
        >
          {cnpjTelefone}
        </Text>
        <Text
          fontSize="x-small"
          textAlign="center"
          color="black"
          fontWeight="900"
        >
          {enderecoLoja}
        </Text>
      </Flex>
    </>
  );
};

export default Cabecalho;
