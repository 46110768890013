import { useCallback, useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

import { IdentificacaoEtapasSmartPos } from 'constants/enum/IdentificacaoEtapasSmartPos';
import { useSmartPosEtapasContext } from 'store/SmartPos/SmartPosEtapasContext';
import { CategoriaProdutoProps } from 'store/SmartPos/CategoriaProdutos';
import { cadastrarCategoriaSmartPos } from 'services/smartPos';

import { CategoriaProduto as CategoriaProdutoSmartPos } from 'components/SmartPos/CategoriaProdutoItem';
import { ConfiguracoesCategoriaProdutosIcon } from 'icons';

import { FooterSmartPos } from '../Layout/FooterSmartPos';
import { HeaderSmartPos } from '../Layout/Header';

export const CategoriaProduto = () => {
  const [categoriaSelecionada, setCategoriaSelecionada] = useState<string[]>(
    []
  );
  const { setActiveStep, setIsLoading } = useSmartPosEtapasContext();

  const obterCategoriaSelecionada = useCallback(
    (categorias: CategoriaProdutoProps[]) => {
      const filtrandoCategoriasSelecionadas = categorias.filter(
        (categoria) => categoria.isChecked
      );

      const categoriasSelecionadas = filtrandoCategoriasSelecionadas.map(
        (categoria) => categoria.id
      );
      setCategoriaSelecionada(categoriasSelecionadas);
    },
    []
  );

  const cadastrarCategoriasSelecionadas = useCallback(async () => {
    setIsLoading(true);
    const categoria = await cadastrarCategoriaSmartPos(categoriaSelecionada);
    setIsLoading(false);
    return categoria;
  }, [categoriaSelecionada, setIsLoading]);

  const isExisteCategoriaSelecionada = categoriaSelecionada.length > 0;

  const handleAvancar = async () => {
    const categoriaAdicionada = await cadastrarCategoriasSelecionadas();

    if (categoriaAdicionada) {
      setActiveStep(IdentificacaoEtapasSmartPos.TIPO_IMPRESSAO);
    }
  };

  const handleVoltar = () => {
    setActiveStep(IdentificacaoEtapasSmartPos.FORMA_RECEBIMENTO);
  };

  return (
    <>
      <Box>
        <HeaderSmartPos
          title="Categoria de produtos"
          handleVoltar={handleVoltar}
          icon={ConfiguracoesCategoriaProdutosIcon}
        />
        <Flex
          mb={['24px', '24px', '40px']}
          justifyContent="space-between"
          w="full"
          h="auto"
          pl={['10px', '10px', '20px']}
          pr={['10px', '10px', '20px']}
        >
          <Text
            textAlign={['center', 'left', 'left']}
            letterSpacing="0px"
            color="primary.50"
            fontSize="14px"
          >
            A organização dos produtos no PDV segue a estrutura criada por
            categorias, portanto todos os produtos cadastrados no sistema e
            vinculados a essas categorias estarão disponíveis para venda no
            aplicativo. Se houver necessidade, você pode desmarcar uma categoria
            (todos os produtos que pertencem a ela serão desconsiderados para
            venda). Para alterar a ordem de categorias acesse: Configurações /
            Categorias de produtos.
          </Text>
        </Flex>
      </Box>
      <Box overflowX="scroll" mb={['24px', '24px', '56px']}>
        <Box ml="-10px" w={['800px', '800px', 'full']}>
          <CategoriaProdutoSmartPos
            obterCategoriaSelecionada={obterCategoriaSelecionada}
          />
        </Box>
      </Box>
      <FooterSmartPos
        mb="30px"
        handleAvancar={handleAvancar}
        isDisabledAvancar={!isExisteCategoriaSelecionada}
      />
    </>
  );
};
