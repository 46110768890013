import { ConciliacaoContasProvider } from 'store/Financeiro/ConciliacaoContas';

import { TotalizadoresGerais } from './TotalizadoresGerais';
import { Listagem } from './Listagem';
import { Footer } from './Footer';
import { ActionsHeader } from './ActionsHeader';

export const ConciliacaoDeContas = () => {
  return (
    <ConciliacaoContasProvider>
      <ActionsHeader />
      <TotalizadoresGerais />
      <Listagem />
      <Footer />
    </ConciliacaoContasProvider>
  );
};
