import React, { useState } from 'react';
import { Box, Text, Flex, Divider } from '@chakra-ui/react';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

type TabType = {
  eventKey: string;
  title: string;
  content: string | React.ReactNode;
  disabled?: boolean;
};

interface TabsPadraoProps {
  id: string;
  tabs: TabType[];
  defaultActiveKey?: string;
  isLoading?: boolean;
}

const TabsLink = ({
  id,
  tabs,
  defaultActiveKey,
  isLoading,
}: TabsPadraoProps) => {
  const [tabActive, setTabActive] = useState(defaultActiveKey || tabs[0].title);

  return (
    <Box id={id}>
      {isLoading && <LoadingPadrao />}
      <Flex alignItems="flex-end" flexWrap="wrap" color="blue.500">
        {tabs.map(({ eventKey, disabled, title }, index) => (
          <Flex
            lineHeight="3px"
            mb="15px"
            alignItems="center"
            key={`tab-${eventKey}`}
          >
            <Text
              mb={tabs.length === index + 1 ? '5.5px' : undefined}
              cursor="pointer"
              fontWeight="normal"
              onClick={() => {
                if (!disabled) {
                  setTabActive(eventKey);
                }
              }}
            >
              {title}
            </Text>
            {index >= 0 && index < tabs.length - 1 && (
              <Box pl="10px" pr="10px">
                <Divider
                  bg="blue.500"
                  orientation="vertical"
                  w="1px"
                  h="14px"
                />
              </Box>
            )}
          </Flex>
        ))}
      </Flex>
      <Box bg="white">
        {tabs.map(({ eventKey, content }) => (
          <Box
            display={tabActive === eventKey ? 'unset' : 'none'}
            key={`content-tab-${eventKey}`}
          >
            {content}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default TabsLink;
