import React, { useState, useCallback, SyntheticEvent, memo } from 'react';
import { Control, Controller } from 'react-hook-form';

import Loading from 'components/Layout/Loading/LoadingPadrao';
import { Box, useMediaQuery } from '@chakra-ui/react';
import TableHeader, { ColumnHeaderType } from './TableHeader';

import { Card, Table, TableBody, TableFoot, EmptyValuesText } from './styles';

type TableOrderType = {
  orderColumn: string;
  orderDirection: 'asc' | 'desc';
  orderColumnCompareFn?: (a: any, b: any, order: 'asc' | 'desc') => number;
};

interface ChildWithFuncProps {
  tableValues: any[];
}

type ChildWithFuncType = (props: ChildWithFuncProps) => JSX.Element | string;

interface TableCampoFormularioPadraoProps {
  fieldName: string;
  control: Control<Record<string, any>>;
  isLoading: boolean;
  defaultColumnOrder: string;
  headerColumns: ColumnHeaderType[];
  emptyValuesText?: string;
  tableFootContent?: (props: ChildWithFuncProps) => JSX.Element | string;
  children: ChildWithFuncType;
}

const TableCampoFormularioPadrao = ({
  fieldName,
  control,
  isLoading,
  defaultColumnOrder,
  headerColumns,
  emptyValuesText,
  tableFootContent,
  children,
}: TableCampoFormularioPadraoProps) => {
  const [tableOrder, setTableOrder] = useState<TableOrderType>({
    orderColumn: defaultColumnOrder,
    orderDirection: 'asc',
  });
  const [isSmallerThan1200] = useMediaQuery('(max-width: 1200px)');
  const setOrder = useCallback(
    (
      event: SyntheticEvent,
      newOrderColumn: string,
      newOrderColumnCompareFn?: (
        a: any,
        b: any,
        order: 'asc' | 'desc'
      ) => number
    ) => {
      event.preventDefault();

      let newOrderDirection: 'asc' | 'desc' = 'asc';

      if (newOrderColumn === tableOrder.orderColumn) {
        if (tableOrder.orderDirection === 'asc') newOrderDirection = 'desc';
      }

      setTableOrder({
        orderColumn: newOrderColumn,
        orderDirection: newOrderDirection,
        orderColumnCompareFn: newOrderColumnCompareFn,
      });
    },
    [tableOrder.orderColumn, tableOrder.orderDirection]
  );

  return (
    <Card
      className="text-left"
      style={{ border: '1px solid var(--sti-ck-colors-gray-100)' }}
    >
      <Card.Body>
        {isLoading && <Loading />}
        <Controller
          defaultValue={[]}
          render={({ field: { value } }) => {
            const tableValues = !value
              ? []
              : value.sort((a: any, b: any) => {
                  if (tableOrder.orderColumnCompareFn)
                    return tableOrder.orderColumnCompareFn(
                      a,
                      b,
                      tableOrder.orderDirection
                    );

                  if (tableOrder.orderDirection === 'asc') {
                    if (a[tableOrder.orderColumn] < b[tableOrder.orderColumn])
                      return -1;

                    if (a[tableOrder.orderColumn] > b[tableOrder.orderColumn])
                      return 1;

                    return 0;
                  }

                  // desc
                  if (b[tableOrder.orderColumn] < a[tableOrder.orderColumn])
                    return -1;

                  if (b[tableOrder.orderColumn] > a[tableOrder.orderColumn])
                    return 1;

                  return 0;
                });

            return (
              <Box
                w="100%"
                overflowY={isSmallerThan1200 ? 'scroll' : 'visible'}
              >
                <Table>
                  <TableHeader
                    columns={headerColumns}
                    orderColumn={tableOrder.orderColumn}
                    orderDirection={tableOrder.orderDirection}
                    setOrder={setOrder}
                  />
                  {value && value.length > 0 && (
                    <>
                      <TableBody>
                        {(children as ChildWithFuncType)({ tableValues })}
                      </TableBody>
                      {tableFootContent && (
                        <TableFoot>
                          {tableFootContent({ tableValues })}
                        </TableFoot>
                      )}
                    </>
                  )}
                </Table>

                {!value ||
                  (value.length <= 0 && (
                    <EmptyValuesText>{emptyValuesText}</EmptyValuesText>
                  ))}
              </Box>
            );
          }}
          name={`${fieldName}` as const}
          control={control}
        />
      </Card.Body>
    </Card>
  );
};

export default memo(TableCampoFormularioPadrao);
