import React, { useCallback, useState, useRef } from 'react';
import { GridItem, Tr, Td, Icon } from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import StatusConsultaEnum from 'constants/enum/statusConsulta';
import { shallowEqual } from 'helpers/validation/shallowEqual';
import ConstanteFuncionalidades from 'constants/permissoes';
import auth from 'modules/auth';

import { SalvarInserirNovoIcon } from 'icons';
import {
  PagedTable,
  PagedTableForwardRefData,
} from 'components/update/Table/PagedTable';
import { PaginationData } from 'components/update/Pagination';
import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import { StatusCircle } from 'components/update/Table/StatusCircle';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { SearchInput } from 'components/update/Input/SearchInput';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { ButtonFuncionalidade } from 'components/update/Button/ButtonFuncionalidade';

import { ModalRelatoriosProdutosPersonalizados } from '../ModalRelatoriosPersonalizados';

type FormData = {
  nome?: string;
  statusConsulta: number;
};

const formDefaultValues: FormData = {
  nome: '',
  statusConsulta: StatusConsultaEnum.ATIVOS,
};

type ObterRelatorioPersonalizado = {
  id: string;
  nome: string;
  configuracoes: string;
};
type ListaDeRelatorios = {
  id: string;
  nome: string;
};

const Listar = () => {
  const formMethods = useForm<FormData>({
    defaultValues: formDefaultValues,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [itemsTotalCount, setItemsTotalCount] = useState(0);
  const [columnsData, setColumnsData] = useState<ListaDeRelatorios[]>([]);
  const [currentFilters, setCurrentFilters] = useState<FormData>(
    formDefaultValues
  );

  const pagedTableRef = useRef<PagedTableForwardRefData>(null);

  const permissaoAlterarRelatorioPersonalizado = auth.possuiPermissao(
    ConstanteFuncionalidades.RELATORIO_PERSONALIZADO_PRODUTOS_ALTERAR
  ).permitido;

  const filtersSubmit = formMethods.handleSubmit((data) => {
    const filtersIsDirty = !shallowEqual(data, currentFilters);

    if (filtersIsDirty) {
      setCurrentFilters(data);
    }
  });

  const loadColumnsData = useCallback(
    async (paginationData: PaginationData) => {
      setIsLoading(true);

      const params = {
        nome: currentFilters.nome,
        statusConsulta: currentFilters.statusConsulta,
      };

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<ListaDeRelatorios>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.RELATORIOS_PERSONALIZADOS_LISTAR_PAGINADO,
          paginationData
        ),
        { params }
      );

      if (response) {
        if (response.sucesso) {
          setItemsTotalCount(response.dados.total || 0);

          setColumnsData(response.dados.registros || []);
        }
      }

      setIsLoading(false);
    },
    [currentFilters]
  );

  const handleDeletarRelatorio = async (idRelatorio?: string) => {
    setIsLoading(true);
    const { sucesso } = await ModalConfirmacaoExcluir({
      title: 'Deseja excluir?',
      text:
        'Realmente deseja excluir este relatório? Você não poderá mais recupera-lo a não ser que o crie novamente',
    });
    if (sucesso) {
      const response = await api.delete<
        void,
        ResponseApi<ObterRelatorioPersonalizado>
      >(ConstanteEnderecoWebservice.RELATORIO_PERSONALIZADOS_DELETAR, {
        params: {
          id: idRelatorio,
        },
      });

      if (response.avisos) {
        response.avisos.forEach((avisos) => toast.warning(avisos));
      }

      if (response.sucesso) {
        toast.success('Relatorio excluído com sucesso!');
        pagedTableRef.current?.reload();
      }
    }
    setIsLoading(false);
  };

  const handleObterConfiguracaoRelatorioPersonalizado = useCallback(
    async (idRelatorio: string, nomeRelatorio: string) => {
      const response = await api.get<
        void,
        ResponseApi<ObterRelatorioPersonalizado>
      >(ConstanteEnderecoWebservice.RELATORIOS_PERSONALIZADOS_OBTER, {
        params: { id: idRelatorio },
      });

      if (response.avisos) {
        response.avisos.forEach((avisos) => toast.warning(avisos));
      }

      if (response.dados && response.sucesso) {
        const config = JSON.parse(response.dados.configuracoes);
        const { sucesso } = await ModalRelatoriosProdutosPersonalizados({
          settings: { Campos: config, nome: nomeRelatorio, id: idRelatorio },
        });

        if (sucesso) pagedTableRef.current?.reload();
      }
    },
    []
  );

  return (
    <SimpleGridForm>
      <FormProvider {...formMethods}>
        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <SearchInput
            name="nome"
            id="nome"
            placeholder="Buscar o relatório pelo nome"
            onEnterKeyPress={() => {
              filtersSubmit();
            }}
            isDisabled={isLoading}
            autoFocus
          />
        </GridItem>
        <GridItem
          colSpan={{ base: 12, sm: 6, lg: 6 }}
          display="grid"
          justifyContent={{ base: 'stretch', sm: 'flex-end' }}
        >
          <ButtonFuncionalidade
            funcionalidade={
              ConstanteFuncionalidades.RELATORIO_PERSONALIZADO_PRODUTOS_CADASTRAR
            }
            colorScheme="secondary"
            minWidth={{ base: 'full', sm: '225px' }}
            borderRadius="md"
            leftIcon={<Icon as={SalvarInserirNovoIcon} fontSize="lg" />}
            isDisabled={isLoading}
            onClick={async () => {
              const { sucesso } = await ModalRelatoriosProdutosPersonalizados({
                changeSettings: false,
              });
              if (sucesso) {
                pagedTableRef.current?.reload();
              }
            }}
            id="cadastrarNovo"
            name="cadastrarNovo"
          >
            Cadastrar Novo
          </ButtonFuncionalidade>
        </GridItem>
      </FormProvider>

      <GridItem colSpan={12}>
        <PagedTable
          ref={pagedTableRef}
          isLoading={isLoading}
          loadColumnsData={loadColumnsData}
          itemsTotalCount={itemsTotalCount}
          defaultKeyOrdered="nome"
          tableHeaders={[
            {
              key: 'ativo',
              content: <StatusCircle hasValue={false} />,
              w: '1px',
            },
            { key: 'nome', content: 'Nome' },
            { key: 'acoes', content: 'Ações', isOrderable: false, w: '1px' },
          ]}
          renderTableRows={columnsData.map((columnData) => (
            <Tr>
              <Td>
                <StatusCircle isActive={Boolean(columnData.id)} />
              </Td>
              <Td
                _hover={{ textDecoration: 'underline' }}
                cursor="pointer"
                onClick={() => {
                  if (permissaoAlterarRelatorioPersonalizado) {
                    handleObterConfiguracaoRelatorioPersonalizado(
                      columnData.id,
                      columnData.nome
                    );
                  }
                }}
              >
                {columnData.nome}
              </Td>
              <Td>
                <ActionsMenu
                  items={[
                    {
                      content: 'Editar',
                      onClick: () => {
                        handleObterConfiguracaoRelatorioPersonalizado(
                          columnData.id,
                          columnData.nome
                        );
                      },
                      funcionalidade:
                        ConstanteFuncionalidades.RELATORIO_PERSONALIZADO_PRODUTOS_ALTERAR,
                    },
                    {
                      content: 'Remover',
                      onClick: () => {
                        handleDeletarRelatorio(columnData.id);
                      },
                      funcionalidade:
                        ConstanteFuncionalidades.RELATORIO_PERSONALIZADO_PRODUTOS_EXCLUIR,
                    },
                  ]}
                />
              </Td>
            </Tr>
          ))}
        />
      </GridItem>
    </SimpleGridForm>
  );
};

export default Listar;
