import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// const LANGUAGES = ['pt', 'en'];
const LANGUAGES = ['pt'];
export const LOOKUPLOCALSTORAGE = 'language';

i18n
  // Habilita o backend do i18next
  .use(Backend)
  // Habilita a detecção automática de linguagem
  .use(LanguageDetector)
  // Habilita o módulo de inicialização do hook
  .use(initReactI18next)
  .init({
    // Linguagem padrão utilizada em caso de fallback
    fallbackLng: 'pt',

    // Para usar o conteúdo como key das traduções
    keySeparator: false,
    nsSeparator: false,

    // Desabilita o log de informações sobre o i18next no terminal
    debug: false,

    // Define as linguagens suportadas
    whitelist: LANGUAGES,

    // Habilita os namespaces possíveis para cada locale
    ns: ['common', 'validation'],
    defaultNS: 'common',

    // Detecta e salva em cache a linguagem escolhida
    detection: {
      order: ['localStorage'],
      // order: ['localStorage', 'navigator'],
      lookupLocalStorage: LOOKUPLOCALSTORAGE,
      caches: ['localStorage'],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
