import React, { useMemo, memo } from 'react';

import { useCategoriasProdutoContext } from 'store/CategoriasProduto';

import Item from '../Item';

interface ItemsContainerProps {
  categoriaProdutoPaiId?: string;
  nivel?: number;
  categoriaProdutoPaiIsAtivo?: boolean;
}

const ItemsContainer = ({
  categoriaProdutoPaiId,
  nivel = 1,
  categoriaProdutoPaiIsAtivo = true,
}: ItemsContainerProps) => {
  const { getCategoriasProdutoPeloPaiId } = useCategoriasProdutoContext();

  const items = useMemo(() => {
    return getCategoriasProdutoPeloPaiId(categoriaProdutoPaiId);
  }, [getCategoriasProdutoPeloPaiId, categoriaProdutoPaiId]);

  return (
    <>
      {items.map((item) => (
        <Item
          key={item.id}
          categoriaProdutoPaiIsAtivo={categoriaProdutoPaiIsAtivo}
          nivel={nivel}
          item={item}
        >
          <ItemsContainer
            categoriaProdutoPaiIsAtivo={item.ativo}
            categoriaProdutoPaiId={item.id}
            nivel={nivel + 1}
          />
        </Item>
      ))}
    </>
  );
};

export default memo(ItemsContainer);
