const ConsignacaoSituacaoEnum = {
  TODOS: 0,
  CONSIGNADO: 1,
  DEVOLVIDO: 2,

  properties: [
    { name: 'Todos', value: 0 },
    { name: 'Consignados', value: 1 },
    { name: 'Devolvidos (itens devolvidos/vendidos)', value: 2 },
  ],
};

export default ConsignacaoSituacaoEnum;
