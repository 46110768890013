import React, { useState, useEffect, useRef } from 'react';
import { GridItem, Button } from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import StatusConsultaEnum from 'constants/enum/statusConsulta';
import auth from 'modules/auth';
import OptionType from 'types/optionType';
import { useConferenciaEstoqueContainerContext } from 'store/ConferenciaEstoque/ConferenciaEstoqueContainer';
import { useConferenciaEstoqueEtapasContext } from 'store/ConferenciaEstoque/ConferenciaEstoqueEtapas';

import Select from 'components/PDV/Select/SelectPadrao';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { Container, Body, Footer } from 'components/update/Steps/StepContent';

import { yupResolver, FormData } from './validationForm';

type OptionResponseType = { id: string; nome: string };

export function EscolherLocalEstoque() {
  const { descartarConferencia } = useConferenciaEstoqueContainerContext();
  const {
    nextStep,
    conferenciaEstoque,
    setConferenciaEstoque,
  } = useConferenciaEstoqueEtapasContext();

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues: {
      localEstoque: conferenciaEstoque?.localEstoque,
    },
  });

  const [
    isLocalEstoqueOptionsLoading,
    setIsLocalEstoqueOptionsLoading,
  ] = useState(true);
  const [localEstoqueOptions, setLocalEstoqueOptions] = useState<OptionType[]>(
    []
  );

  function handleDescartarConferencia() {
    descartarConferencia();
  }

  const handleAvancar = formMethods.handleSubmit((data) => {
    setConferenciaEstoque((prev) => ({
      ...prev,
      localEstoque: data.localEstoque,
    }));

    nextStep();
  });

  const latestProps = useRef({
    setValue: formMethods.setValue,
    conferenciaEstoque,
  });
  useEffect(() => {
    latestProps.current = {
      setValue: formMethods.setValue,
      conferenciaEstoque,
    };
  });

  useEffect(() => {
    async function loadDefaultLocalEstoque(options: OptionType[]) {
      setIsLocalEstoqueOptionsLoading(true);

      const response = await api.get<void, ResponseApi<string>>(
        ConstanteEnderecoWebservice.LOCAL_ESTOQUE_OBTER_PADRAO_SISTEMA
      );

      if (response) {
        if (response.avisos) {
          response.avisos.map((aviso: string) => toast.warning(aviso));
        }

        if (response.sucesso && response.dados) {
          const localEstoqueDefaultValue = options.find(
            (option) => option.value === response.dados
          );

          if (localEstoqueDefaultValue) {
            latestProps.current.setValue(
              'localEstoque',
              localEstoqueDefaultValue
            );
          }
        }
      }

      setIsLocalEstoqueOptionsLoading(false);
    }

    async function loadLocalEstoqueOptions() {
      setIsLocalEstoqueOptionsLoading(true);

      const lojaId = auth.getLoja().id;

      const params = {
        statusConsulta: StatusConsultaEnum.ATIVOS,
        lojaId,
      };

      const response = await api.get<void, ResponseApi<OptionResponseType[]>>(
        ConstanteEnderecoWebservice.LOCAL_ESTOQUE_LISTAR_SELECT,
        { params }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.map((aviso: string) => toast.warning(aviso));
        }

        if (response.sucesso && response.dados) {
          const newEstoqueOptions = response.dados.map((option) => ({
            value: option.id,
            label: option.nome,
          }));

          setLocalEstoqueOptions(newEstoqueOptions);

          setIsLocalEstoqueOptionsLoading(false);

          return newEstoqueOptions;
        }
      }

      setIsLocalEstoqueOptionsLoading(false);

      return [];
    }

    async function loadLocalEstoque() {
      const options = await loadLocalEstoqueOptions();

      if (
        !latestProps.current.conferenciaEstoque ||
        (latestProps.current.conferenciaEstoque &&
          !latestProps.current.conferenciaEstoque.localEstoque)
      ) {
        await loadDefaultLocalEstoque(options);
      }
    }

    loadLocalEstoque();
  }, []);

  return (
    <Container>
      <FormProvider {...formMethods}>
        <Body>
          <SimpleGridForm>
            <GridItem
              colSpan={{ base: 12, md: 8, lg: 6, xl: 4 }}
              sx={{
                '& .chakra-form__label': {
                  color: 'primary.50',
                  fontSize: { base: 'md', md: 'lg' },
                },
              }}
            >
              <Select
                id="localEstoque"
                name="localEstoque"
                label="Selecione um local de estoque para iniciar a conferência"
                placeholder="Selecionar"
                options={localEstoqueOptions}
                isLoading={isLocalEstoqueOptionsLoading}
                isDisabled={isLocalEstoqueOptionsLoading}
                asControlledByObject
              />
            </GridItem>
          </SimpleGridForm>
        </Body>
        <Footer>
          <Button
            variant="cancel"
            colorScheme="gray"
            borderRadius="md"
            w="full"
            maxW={{ base: 'full', md: '100px' }}
            onClick={handleDescartarConferencia}
          >
            Descartar
          </Button>
          <Button
            colorScheme="primary"
            borderRadius="md"
            w="full"
            maxW={{ base: 'full', md: '160px' }}
            onClick={handleAvancar}
          >
            Avançar
          </Button>
        </Footer>
      </FormProvider>
    </Container>
  );
}
