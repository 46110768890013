import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import ModalidadesFreteEnum from 'constants/enum/fiscal/modalidadeFrete';

const schema = yup.object().shape({
  serie: yup.string().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  modalidadeFrete: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  dataEmissao: yup
    .date()
    .nullable()
    .default(null)
    // .max(new Date(), 'Data não permitida')
    .test('dataEmissao', 'Data não permitida', (value) => {
      if (value) {
        const testSchema = yup.date().max(new Date());

        return testSchema.isValidSync(value);
      }

      return true;
    })
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),

  quantidadeVolumeTransportadora: yup.number().when('modalidadeFrete', {
    is: (val) => val && val !== ModalidadesFreteEnum.SemFrete,
    then: yup
      .number()
      .min(1, ConstanteMensagemValidacao.VALOR_MINIMO_1)
      .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  }),
  transportadora: yup
    .object()
    .nullable()
    .when('modalidadeFrete', {
      is: (val) => val && val !== ModalidadesFreteEnum.SemFrete,
      then: yup
        .object({
          value: yup
            .string()
            .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
          label: yup.string(),
        })
        .nullable()
        .default(null)
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
    }),
  pesoBruto: yup.number().when('modalidadeFrete', {
    is: (val) => val !== ModalidadesFreteEnum.SemFrete,
    then: yup.number().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  }),
  pesoLiquido: yup.number().when('modalidadeFrete', {
    is: (val) => val !== ModalidadesFreteEnum.SemFrete,
    then: yup.number().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  }),
  cliente: yup
    .object()
    .nullable()
    .default(null)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  naturezaOperacao: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  icmsValor: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  cofinsValorTotal: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  fcpUfDestinoValorTotal: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  icmsBaseCalculo: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  icmsDesoneradoValorTotal: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  icmsFcpDestinoValorTotal: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  icmsFcpRemetenteValorTotal: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  icmsFcpValorTotal: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  icmsStBaseCalculo: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  icmsStFcpRetidoValorTotal: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  icmsStFcpValorTotal: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  icmsStValor: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  ipiDevolucaoValorTotal: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  pisValorTotal: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  valorDesconto: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  valorDespesas: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  valorFrete: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  valorImpostoImportacao: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  valorIpi: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  valorProdutos: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  valorSeguro: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  valorTotal: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  valorTotalTributos: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);

export const useForm = useFormInstance;
