import { Box, Flex, Text } from '@chakra-ui/react';
import Lottie from 'lottie-react';

import Rejeitada from 'assets/animations/acao_rejeitada.json';
import { useSignalRContext } from 'store/SignalR';

export const NotaRejeitada = () => {
  const { statusNotaFiscal } = useSignalRContext();
  return (
    <>
      <Flex
        bg="gray.100"
        w="full"
        h="full"
        justifyContent="center"
        alignItems="center"
      >
        <Flex alignItems="center" justifyContent="center">
          <Box>
            <Lottie
              animationData={Rejeitada}
              loop
              style={{
                width: '209px',
                height: '209px',
              }}
            />
          </Box>
          <Box color="gray.700" ml="40px">
            <Text mb="16px" color="gray.700" fontSize="20px">
              Nota fiscal{' '}
              <Text fontWeight="bold" display="inline" color="red.500">
                Rejeitada!
              </Text>
            </Text>
            {statusNotaFiscal?.motivo && (
              <Text maxW="350px">
                Motivo:{' '}
                <Text display="inline" color="red.500">
                  {statusNotaFiscal?.motivo}
                </Text>{' '}
              </Text>
            )}
            <Text w="340px">
              Instruções:{' '}
              <Text display="inline" color="black">
                Feche a tela de emissão para corrigir os dados rejeitados.
                Depois de corrigido, selecione a venda e clique em “Emitir
                novamente a Nota Fiscal”.
              </Text>
            </Text>
          </Box>
        </Flex>
      </Flex>
    </>
  );
};
