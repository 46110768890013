import React from 'react';
import { Box } from '@chakra-ui/react';

const Overlay = () => {
  return (
    <Box
      position="absolute"
      top={0}
      bg="blackAlpha.600"
      sx={{ '&': { 'backdrop-filter': 'blur(1px)' } }}
      h="100%"
      w="100%"
      overflow="hidden"
      zIndex="overlay"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    />
  );
};

export default Overlay;
