import {
  Box,
  Flex,
  Icon,
  Text,
  Image,
  VStack,
  Stack,
  UnorderedList,
  ListItem,
  Button,
  AspectRatio,
  GridItem,
} from '@chakra-ui/react';
import { FiChevronLeft } from 'react-icons/fi';

import Sistema from 'assets/images/integracoes/frente_caixa/sistema-frente-caixa-comercial.png';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';

import useFrenteCaixaComercial from './hooks';

const FrenteCaixaComercial = () => {
  const {
    isZendar,
    isLargerThan900,
    segments,
    redirectToAppStore,
    sendWhatsappMessage,
  } = useFrenteCaixaComercial();

  return (
    <Box
      overflowX="auto"
      boxShadow="0px 0px 6px #00000034"
      borderRadius="5px"
      bg="white"
      mt={2}
    >
      <Box px={{ base: 4, md: 16 }} py={6} minW="min">
        <Flex
          w="max"
          cursor="pointer"
          mb="16px"
          gap={4}
          justifyContent="flex-start"
          alignItems="center"
          onClick={() => redirectToAppStore()}
        >
          <Box>
            <Icon color="gray.500" boxSize="25px" as={FiChevronLeft} />
          </Box>
          <Box>
            <Text fontSize="14px" color="gray.500">
              Voltar
            </Text>
          </Box>
        </Flex>
        <VStack
          spacing={{ base: '48px', md: '122px' }}
          pb={{ base: '88px', md: '120px' }}
        >
          <VStack spacing={{ base: '52px', md: '80px' }}>
            <Flex
              w="full"
              gap="56px"
              align="center"
              flexDir={{ base: 'column', md: 'row' }}
            >
              <VStack spacing={6} letterSpacing="0">
                <Text
                  textColor="primary.50"
                  fontSize={{ base: '20px', md: '32px' }}
                  lineHeight={{ base: '24px', md: '36px' }}
                  fontWeight="light"
                >
                  <Box as="b" fontWeight="extrabold">
                    Frente de Caixa Food:
                  </Box>{' '}
                  Uma solução com funções específicas para gestão e controle de
                  atividades no segmento food service.
                </Text>
                {!isLargerThan900 && (
                  <Image src={Sistema} width={{ base: '220px', sm: '305px' }} />
                )}
                <Text textColor="gray.700" fontSize="16px" lineHeight="20px">
                  A Frente de Caixa Food possibilita o atendimento dentro dos
                  principais e mais comuns formatos de venda para um
                  restaurante: Atendimento no balcão, Controle de mesas e
                  comandas dentro do salão e gerenciamento de pedidos por
                  delivery com identificador de chamadas.
                </Text>
                <Text textColor="gray.700" fontSize="16px" lineHeight="20px">
                  Acompanhe com indicação na tela o tempo do cliente no local e
                  quando foi seu último pedido. Configure seus produtos
                  personalizados em etapas, com acompanhamento passo a passo.
                  Configure promoções e campanhas de Happy Hour. Personalize as
                  impressões por departamento (cozinha, fechamento e
                  recebimento), e muito mais.
                </Text>
              </VStack>
              {isLargerThan900 && <Image src={Sistema} width="375px" />}
            </Flex>
            {/* <Flex
              w="full"
              bg="gray.100"
              py="48px"
              px={5}
              gap={{ base: '26px', md: '100px' }}
              align="center"
              justify="center"
              borderRadius="xl"
              flexDir={{ base: 'column', md: 'row' }}
            >
              <AspectRatio
                w={{ base: 'full', sm: '400px' }}
                minW="60px"
                bg="black"
                ratio={16 / 9}
              >
                <Box bg="black" w="full" h="full" />
              </AspectRatio>
              <Text
                w="full"
                maxW="271px"
                textColor="#202020"
                letterSpacing="0"
                fontSize={{ base: '20px', md: '32px' }}
                textAlign={{ base: 'center', md: 'left' }}
                lineHeight={{ base: '24px', md: '36px' }}
              >
                É simples de usar e tem todas as funções que você{' '}
                <Box as="b">mais utiliza no seu dia a dia.</Box>
              </Text>
            </Flex> */}
          </VStack>
          <VStack spacing="82px">
            <Text
              textColor="primary.50"
              fontWeight="light"
              letterSpacing="0"
              fontSize={{ base: '20px', md: '32px' }}
              lineHeight={{ base: '24px', md: '36px' }}
            >
              <Text fontWeight="extrabold">
                Específico para o segmento de Alimentação:
              </Text>
              A Frente de Caixa Food é fácil de usar e completa, com recursos e
              Ferramentas essenciais para o controle do seu restaurante.
            </Text>
            <SimpleGridForm
              columns={{ base: 2, sm: 6, md: 8, lg: 10 }}
              w="full"
            >
              {segments.map(({ icon, text }) => (
                <GridItem colSpan={2}>
                  <VStack>
                    <Icon as={icon} boxSize="96px" />
                    <Text fontSize="20px">{text}</Text>
                  </VStack>
                </GridItem>
              ))}
            </SimpleGridForm>
          </VStack>
          <VStack w="full" align="start" spacing="24px">
            <Text
              textColor="primary.50"
              fontWeight="light"
              letterSpacing="0"
              fontSize={{ base: '20px', md: '32px' }}
              lineHeight={{ base: '24px', md: '36px' }}
            >
              <Text fontWeight="extrabold">Principais recursos:</Text>As
              funcionalidades essenciais para o controle do seu estabelecimento:
            </Text>
            <Box>
              <UnorderedList
                fontSize={{ base: '16px', md: '20px' }}
                textColor="gray.700"
              >
                <ListItem>Controle de mesas e comandas;</ListItem>
                <ListItem>Delivery: Gestão de pedidos recebidos;</ListItem>
                <ListItem>Vendas diretas no balcão;</ListItem>
                <ListItem>
                  Telas Touch-Screen: O sistema foi preparado para utilização em
                  monitores touch-screen;
                </ListItem>
                <ListItem>
                  Ficha Técnica: Cadastre a formação dos pratos e obtenha um
                  controle completo;
                </ListItem>
                <ListItem>
                  Bina e identificador de chamadas (disk entregas);
                </ListItem>
                <ListItem>Busca por endereço usando Google Maps;</ListItem>
                <ListItem>
                  Alertas interativos indicando tempo de permanência e ausência
                  de consumo do cliente;
                </ListItem>
                <ListItem>
                  Produtos personalizados em etapas, com acompanhamento passo a
                  passo
                </ListItem>
                <ListItem>Integração com balanças e catracas;</ListItem>
                <ListItem>Pedidos com Micro Terminais;</ListItem>
                <ListItem>Configuração de promoções e happy hour;</ListItem>
                <ListItem>Impressão de composição do ítem na cozinha;</ListItem>
                <ListItem>Impressão de fichas para consumo;</ListItem>
                <ListItem>
                  Personalização das impressões (cozinha, fechamento e
                  recebimento);
                </ListItem>
                <ListItem>Balança com pesagem automática;</ListItem>
                <ListItem>Abertura de caixa com controle de turnos;</ListItem>
              </UnorderedList>
            </Box>
          </VStack>
          <VStack spacing="38px">
            <Text
              textColor="primary.50"
              fontWeight="light"
              letterSpacing="0"
              fontSize={{ base: '20px', md: '32px' }}
              lineHeight={{ base: '24px', md: '36px' }}
            >
              <Text fontWeight="extrabold">Como contratar:</Text>
              Para agendar uma demonstração ou saber mais detalhes sobre
              contratação, preços e funcionalidades, entre em contato.
            </Text>
            {isZendar && (
              <Button
                h="full"
                minH="48px"
                w={{ base: 'full', sm: '400px' }}
                minW="162px"
                whiteSpace="normal"
                colorScheme="aquamarine"
                fontSize={{ base: '14px', sm: '18px' }}
                onClick={sendWhatsappMessage}
              >
                Quero conversar com um especialista
              </Button>
            )}
          </VStack>
        </VStack>
      </Box>
    </Box>
  );
};

export { FrenteCaixaComercial };
