import React from 'react';
import {
  Divider,
  Flex,
  useMediaQuery,
  FlexProps,
  Text,
  Icon,
} from '@chakra-ui/react';

import { useInformacoesGeraisContext } from 'store/PDV/InformacoesGerais';
import { moneyMask } from 'helpers/format/fieldsMasks';

import NumeroOperacao from 'components/PDV/Header/NumeroOperacao';
import Observacoes from 'components/PDV/Header/Observacoes';
import { PagamentosValePresenteIcon } from 'icons';

import CadastroCliente from '../CadastroCliente';

const OperacaoPedidoObservacaoCliente = ({ ...flexProps }: FlexProps) => {
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const { detalhesTroca } = useInformacoesGeraisContext();
  const { pathname } = window.location;
  const rotaPagamento = pathname.includes('pdv/pagamento');

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      h="full"
      {...flexProps}
    >
      <Flex>
        <NumeroOperacao />
        {isLargerThan900 && (
          <>
            <Divider
              orientation="vertical"
              marginLeft="4"
              marginRight="4"
              opacity="unset"
              borderLeftWidth="2px"
              h="14px"
              mt="0.5"
            />
            <Observacoes />
          </>
        )}
      </Flex>
      <Flex justifyContent="center" alignItems="center">
        {detalhesTroca.valorTotalTroca > 0 && !rotaPagamento && (
          <>
            <Flex color="red.200" justifyContent="center" alignItems="center">
              <Icon
                as={PagamentosValePresenteIcon}
                mr="5px"
                boxSize="30px"
                mb="15px"
              />
              <Text fontSize="15px">
                {moneyMask(detalhesTroca.valorTotalTroca, true)}
              </Text>
            </Flex>
            <Divider
              orientation="vertical"
              marginLeft="2"
              marginRight="4"
              opacity="unset"
              borderLeftWidth="2px"
              h="14px"
              mt="0.5"
            />
          </>
        )}
        <CadastroCliente />
      </Flex>
    </Flex>
  );
};

export default OperacaoPedidoObservacaoCliente;
