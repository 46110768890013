import { useState, useCallback, useEffect } from 'react';
import { useMediaQuery } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import { ModalNewPrintManager } from './ModalGerenciadorImpressao/Cadastrar';
import { ModalNewPrintDepartment } from './ModalDepImpressao/Cadastrar';
import { ModalUpdatePrintManager } from './ModalGerenciadorImpressao/Alterar';
import { ModalUpdatePrintDepartment } from './ModalDepImpressao/Alterar';
import { PrintersDepartmentProps, PrintManagerProps } from './types';

const useGerenciadorImpressao = () => {
  const [isLargerThan900, isLargerThan700] = useMediaQuery([
    '(min-width: 900px)',
    '(min-width: 700px)',
  ]);
  const formMethods = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [mobileSelectedTab, setMobileSelectedTab] = useState(-1);
  const [printManagerList, setPrintManagerList] = useState<PrintManagerProps[]>(
    []
  );
  const [listPrintersDepartments, setListPrintersDepartments] = useState<
    PrintersDepartmentProps[]
  >([]);

  const hasPrintManager = printManagerList.length > 0;
  const hasPrinterDepartament = listPrintersDepartments.length > 0;

  const handleMobileSelectedTab = (index: number) => {
    const newIndex = index !== mobileSelectedTab ? index : -1;
    setMobileSelectedTab(newIndex);
  };

  const handleSelectedTab = (index: number) => {
    setSelectedTab(index);
  };

  const getListPrintManager = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<void, ResponseApi<PrintManagerProps[]>>(
      ConstanteEnderecoWebservice.GERENCIADOR_IMPRESSAO
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response?.sucesso && response?.dados) {
        setPrintManagerList(response.dados);
      }
    }

    setIsLoading(false);
  }, []);

  const getListPrintersDepartments = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<
      void,
      ResponseApi<PrintersDepartmentProps[]>
    >(ConstanteEnderecoWebservice.DEPARTAMENTO_IMPRESSORA);

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response?.sucesso && response?.dados) {
        const newListPrintersDepartments = response.dados.filter(
          ({ impressoras }) => impressoras.length
        );

        setListPrintersDepartments(newListPrintersDepartments);
      }
    }

    setIsLoading(false);
  }, []);

  const createPrintManager = useCallback(async (data) => {
    setIsLoading(true);
    const response = await api.post<void, ResponseApi>(
      ConstanteEnderecoWebservice.GERENCIADOR_IMPRESSAO,
      {
        ...data,
      }
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response?.sucesso) {
        return true;
      }
    }
    setIsLoading(false);
    return false;
  }, []);

  const updatePrintManager = useCallback(async (data) => {
    setIsLoading(true);
    const response = await api.put<void, ResponseApi>(
      `${ConstanteEnderecoWebservice.GERENCIADOR_IMPRESSAO}/${data.id}`,
      {
        ...data,
      }
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response?.sucesso) {
        return true;
      }
    }
    setIsLoading(false);
    return false;
  }, []);

  const createPrintDepartment = useCallback(async (data) => {
    setIsLoading(true);
    const response = await api.post<void, ResponseApi>(
      ConstanteEnderecoWebservice.DEPARTAMENTO_IMPRESSORA,
      {
        ...data,
      }
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response?.sucesso) {
        return true;
      }
    }
    setIsLoading(false);
    return false;
  }, []);

  const updatePrintDepartment = useCallback(async (data) => {
    setIsLoading(true);
    const response = await api.put<void, ResponseApi>(
      `${ConstanteEnderecoWebservice.DEPARTAMENTO_IMPRESSORA}/${data.id}`,
      {
        ...data,
      }
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response?.sucesso) {
        return true;
      }
    }
    setIsLoading(false);
    return false;
  }, []);

  const removePrintManager = useCallback(async (id: string) => {
    setIsLoading(true);
    const response = await api.delete<void, ResponseApi>(
      `${ConstanteEnderecoWebservice.GERENCIADOR_IMPRESSAO}/${id}`
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response?.sucesso) {
        return true;
      }
    }
    setIsLoading(false);
    return false;
  }, []);

  const removePrintDepartment = useCallback(async (id: string) => {
    setIsLoading(true);
    const response = await api.delete<void, ResponseApi>(
      `${ConstanteEnderecoWebservice.DEPARTAMENTO_IMPRESSORA}/${id}`
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response?.sucesso) {
        return true;
      }
    }
    setIsLoading(false);
    return false;
  }, []);

  const openModalNewPrintManagers = useCallback(() => {
    ModalNewPrintManager({
      callback: async (data) => {
        const success = await createPrintManager(data);
        if (success) {
          toast.success('Gerenciador adicionado com sucesso');
          await getListPrintManager();
        }
        return { success };
      },
    });
  }, [createPrintManager, getListPrintManager]);

  const openModalUpdateManager = useCallback(
    (id: string) => {
      ModalUpdatePrintManager({
        callback: async (data) => {
          const success = await updatePrintManager(data);
          if (success) {
            toast.success('Gerenciador alterado com sucesso');
            await getListPrintManager();
          }
          return { success };
        },
        id,
      });
    },
    [getListPrintManager, updatePrintManager]
  );

  const openModalNewPrintDepartaments = useCallback(() => {
    ModalNewPrintDepartment({
      callback: async (data) => {
        const success = await createPrintDepartment(data);
        if (success) {
          toast.success('Impressora adicionada com sucesso');
          await getListPrintersDepartments();
        }
        return { success };
      },
    });
  }, [createPrintDepartment, getListPrintersDepartments]);

  const openModalUpdateDepartaments = useCallback(
    (id: string) => {
      ModalUpdatePrintDepartment({
        callback: async (data) => {
          const success = await updatePrintDepartment(data);
          if (success) {
            toast.success('Impressora alterada com sucesso');
            await getListPrintersDepartments();
          }
          return { success };
        },
        id,
      });
    },
    [updatePrintDepartment, getListPrintersDepartments]
  );

  useEffect(() => {
    getListPrintManager();
  }, [getListPrintManager]);

  useEffect(() => {
    getListPrintersDepartments();
  }, [getListPrintersDepartments]);

  return {
    isLargerThan900,
    isLargerThan700,
    isLoading,
    formMethods,
    openModalNewPrintManagers,
    openModalUpdateManager,
    openModalNewPrintDepartaments,
    openModalUpdateDepartaments,
    removePrintManager,
    removePrintDepartment,
    getListPrintManager,
    getListPrintersDepartments,
    printManagerList,
    listPrintersDepartments,
    hasPrintManager,
    hasPrinterDepartament,
    selectedTab,
    mobileSelectedTab,
    handleMobileSelectedTab,
    handleSelectedTab,
  };
};

export { useGerenciadorImpressao };
