export type IndicadorEscalaRelevante = 0 | 1 | 2;

const IndicadorEscalaRelevanteEnum = {
  NENHUM: 0,
  SIM: 1,
  NAO: 2,

  properties: {
    0: { name: 'Nenhum', value: 0 },
    1: { name: 'Sim', value: 1 },
    2: { name: 'Não', value: 2 },
  },
};

export default IndicadorEscalaRelevanteEnum;
