import { Th, Thead, Tr, Text, Flex, Icon } from '@chakra-ui/react';
import React, { SyntheticEvent } from 'react';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';

export interface ColumnHeaderType
  extends React.DetailedHTMLProps<
    React.ThHTMLAttributes<HTMLTableHeaderCellElement>,
    HTMLTableHeaderCellElement
  > {
  name: string;
  displayName: string | JSX.Element;
  isOrderable: boolean;
  width: string;
  minWidth?: string;
  alignText?: 'center' | 'right';
  orderColumnCompareFn?: (a: any, b: any, order: 'asc' | 'desc') => number;
  hidden?: boolean;
}

interface HeaderDataType {
  orderColumn: string;
  orderDirection: string;
  setOrder: (
    event: SyntheticEvent,
    newOrderColumn: string,
    newOrderColumnCompareFn?: (a: any, b: any, order: 'asc' | 'desc') => number
  ) => void;
  columns: Array<ColumnHeaderType>;
  background?: string;
}

const TableHeader = ({
  orderColumn,
  orderDirection,
  setOrder,
  columns,
  background = 'gray.100',
}: HeaderDataType) => {
  return (
    <Thead
      sx={{
        '& > tr > th:first-child': {
          paddingLeft: '30px',
        },
        '& > tr > th:last-child': {
          paddingRight: '30px',
        },
        '& > tr': {
          minHeight: '40px',
          height: '100%',
          maxHeight: '40px',
          background,

          '> th': {
            minHeight: '40px',
            height: '100%',
            maxHeight: '40px',
            padding: '10px 10px',
          },
        },
      }}
      position="relative"
      minH="40px"
      h="100%"
      maxH="40px"
    >
      <Tr>
        {columns
          .filter(({ hidden }) => !hidden)
          .map(
            ({
              alignText,
              name,
              width,
              minWidth,
              isOrderable,
              orderColumnCompareFn,
              displayName,
              style,
              ...rest
            }) => {
              let justifyContent;

              if (alignText === 'center') justifyContent = 'center';
              else if (alignText === 'right') justifyContent = 'flex-end';
              else justifyContent = 'flex-start';

              return (
                <Th
                  key={name}
                  style={{
                    ...style,
                    width,
                    minWidth,
                  }}
                  {...rest}
                >
                  <Text
                    role="presentation"
                    onClick={(event) =>
                      isOrderable && setOrder(event, name, orderColumnCompareFn)
                    }
                    cursor={isOrderable ? 'pointer' : 'not-allowed'}
                    userSelect="none"
                    display="flex"
                    alignItems="center"
                    justifyContent={justifyContent}
                    fontWeight={name === orderColumn ? 700 : 600}
                  >
                    <Flex
                      alignItems="center"
                      justifyContent={justifyContent}
                      textOverflow="ellipsis"
                      overflow="unset"
                      whiteSpace="nowrap"
                      w="100%"
                    >
                      {displayName}
                      {name === orderColumn &&
                        (orderDirection === 'asc' ? (
                          <Icon as={FiArrowDown} />
                        ) : (
                          <Icon as={FiArrowUp} />
                        ))}
                    </Flex>
                  </Text>
                </Th>
              );
            }
          )}
      </Tr>
    </Thead>
  );
};

export default TableHeader;
