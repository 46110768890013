import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import enumTipoItemProdutoEtapa from 'constants/enum/enumTipoItemProdutoEtapa';

export type FormData = {
  categoriaProdutoId: string;
  tipoEtapa: number;
  quantidade: number;
  itens: string[];
};

export type CategoryProps = {
  ativo: boolean;
  id: string;
  nome: string;
  nomeEcommerce: null | string;
};

export type ProductProps = CategoryProps & {
  isSelected: boolean;
};

const defaultValues = {
  categoriaProdutoId: undefined,
  tipoEtapa: enumTipoItemProdutoEtapa.PRODUTO,
  quantidade: 0,
};

const schema = yup.object().shape({
  categoriaProdutoId: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  tipoEtapa: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  quantidade: yup
    .number()
    .max(999, ConstanteMensagemValidacao.VALOR_MAXIMO_999)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

const yupResolver = yupResolverInstance(schema);

export { yupResolver, defaultValues };
