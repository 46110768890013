import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { VStack } from '@chakra-ui/react';

import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import { hideEmail } from 'helpers/format/email';
import api, { ResponseApi } from 'services/api';

import Title from 'components/Autenticacao/Text/Title';
import Paragraph from 'components/Autenticacao/Text/Paragraph';
import Input from 'components/Autenticacao/Input';
import ButtonSubmit from 'components/Autenticacao/Button/Submit';
import ButtonLink from 'components/Autenticacao/Button/Link';

import { useForm, yupResolver } from './validationForm';

type FormData = {
  email: string;
};

export const RecuperarSenha = () => {
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const isMountedRef = useIsMountedRef();

  const {
    handleSubmit,
    control,
    formState: { isValid, errors },
  } = useForm({
    resolver: yupResolver,
    mode: 'onChange',
  });

  const formIsInvalid = !isValid;

  const onSubmit = handleSubmit<FormData>(async (data) => {
    setIsLoading(true);

    const response = await api.post<void, ResponseApi<string>>(
      `${ConstanteEnderecoWebservice.AUTENTICACAO_RECUPERAR_SENHA}${data.email}`
    );
    if (response) {
      if (response.sucesso) {
        const hiddenEmail = hideEmail(data.email);

        history.push(ConstanteRotas.SENHA_ENVIADA_COM_SUCESSO, {
          email: hiddenEmail,
        });
      }

      if (response.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }
    }

    if (isMountedRef.current) setIsLoading(false);
  });

  const handlePushLogin = () => {
    setIsLoading(true);

    history.push(ConstanteRotas.LOGIN);

    if (isMountedRef.current) setIsLoading(false);
  };

  return (
    <VStack spacing={9}>
      <VStack gap="8px" alignItems="flex-start" w="full">
        <Title>Esqueceu a senha?</Title>
        <Paragraph>
          Não tem problema! Informe o endereço de <br /> e-mail que você
          utilizou no cadastro para <br /> receber um link com instruções sobre
          como <br /> redefinir sua senha.
        </Paragraph>
      </VStack>
      <VStack as="form" w="full" spacing={8} onSubmit={onSubmit}>
        <Input
          id="email"
          name="email"
          type="email"
          label="E-mail"
          defaultValue=""
          autoFocus
          control={control}
          errorText={errors?.email?.message ?? ''}
          isInvalid={!!errors?.email?.message}
          isDisabled={isLoading}
          maxLength={256}
        />

        <ButtonSubmit
          id="btnRedefinirSenha"
          isLoading={isLoading}
          isDisabled={isLoading || formIsInvalid}
          isInvalid={formIsInvalid}
        >
          Redefinir senha
        </ButtonSubmit>
      </VStack>

      <ButtonLink onClick={handlePushLogin} isDisabled={isLoading}>
        Lembrei a senha
      </ButtonLink>
    </VStack>
  );
};
