import MeioPagamentoEnum from 'constants/enum/fiscal/meioPagamento';

export const validacaoContaFinanceira = (meioPagamento: number) => {
  const formaPagamentoSemContaFinanceira =
    meioPagamento === MeioPagamentoEnum.Dinheiro ||
    meioPagamento === MeioPagamentoEnum.Cheque ||
    meioPagamento === MeioPagamentoEnum.CreditoLoja ||
    meioPagamento === MeioPagamentoEnum.ValePresente ||
    meioPagamento === MeioPagamentoEnum.ProgramaFidelidade ||
    meioPagamento === MeioPagamentoEnum.CreditoEmLoja;

  return formaPagamentoSemContaFinanceira;
};
