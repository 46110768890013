import React, {
  useState,
  SyntheticEvent,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@chakra-ui/react';

import Header, { HeaderData } from 'components/Grid/Cabecalho';
import Loading from 'components/Layout/Loading/LoadingPadrao';

import {
  Container,
  Card,
  ContainerPaging,
  SelectOption,
  TableStyled,
  Body,
  Li,
  LiActive,
  ButtonPageLink,
  DivQuantidadePagina,
  DivTotalPaginas,
  DivTotalRegistros,
  TableContainer,
} from './styles';

export interface GridPaginadaRetorno<T = unknown> {
  total: number;
  registros: Array<T>;
  totalVendas: number;
  faturamento: number;
  quantidadeVendas: number;
  totalComissao: number;
}

export interface GridPaginadaConsulta {
  currentPage: number;
  pageSize: number;
  orderColumn: string;
  orderDirection: string;
}

export interface ForwardRefMethod {
  refresh: (newTotal?: number) => void;
  reset: (orderColumn: string, orderDirection?: 'asc' | 'desc') => void;
}

interface PaginacaoProps {
  total: number;
  paginationHandle: (gridPaginadaConsulta: GridPaginadaConsulta) => void;
  isLoading: boolean;
  headerData: HeaderData;
  children: React.ReactNode;
}

export default forwardRef((props: PaginacaoProps, ref) => {
  const { total, paginationHandle, isLoading, headerData, children } = props;

  const { t } = useTranslation();
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const [gridPaginadaConsulta, setGridPaginadaConsulta] = useState({
    currentPage: 1,
    pageSize: 10,
    orderColumn: headerData.orderColumn,
    orderDirection: headerData.orderDirection,
  } as GridPaginadaConsulta);

  const lastPage = Math.ceil(total / gridPaginadaConsulta.pageSize);

  const changePageSize = (event: React.FormEvent<HTMLSelectElement>) => {
    const pageSizeCombo = Number(event.currentTarget.value);

    setGridPaginadaConsulta({
      ...gridPaginadaConsulta,
      pageSize: pageSizeCombo,
      currentPage: 1,
    });
  };

  const setPage = (event: SyntheticEvent, page: number) => {
    event.preventDefault();

    if (
      page < 1 ||
      page > lastPage ||
      gridPaginadaConsulta.currentPage === page
    )
      return;

    setGridPaginadaConsulta({
      ...gridPaginadaConsulta,
      currentPage: page,
    });
  };

  const setOrder = (event: SyntheticEvent, newOrderColumn: string) => {
    event.preventDefault();

    let newOrderDirection = 'asc';

    if (newOrderColumn === gridPaginadaConsulta.orderColumn) {
      if (gridPaginadaConsulta.orderDirection === 'asc')
        newOrderDirection = 'desc';
    }

    setGridPaginadaConsulta({
      ...gridPaginadaConsulta,
      orderDirection: newOrderDirection,
      orderColumn: newOrderColumn,
    });
  };

  useImperativeHandle(ref, () => ({
    refresh(newTotal?: number) {
      if (newTotal) {
        const newLasPage = Math.ceil(newTotal / gridPaginadaConsulta.pageSize);

        if (
          lastPage === gridPaginadaConsulta.currentPage &&
          lastPage > newLasPage &&
          newTotal > 0
        ) {
          setGridPaginadaConsulta({
            ...gridPaginadaConsulta,
            currentPage: newLasPage,
          });

          return;
        }
      }

      paginationHandle(gridPaginadaConsulta);
    },
    reset(orderColumn: string, orderDirection = 'asc') {
      setGridPaginadaConsulta({
        orderColumn,
        orderDirection,
        pageSize: 10,
        currentPage: 1,
      });
    },
  }));

  useEffect(() => {
    paginationHandle(gridPaginadaConsulta);
  }, [gridPaginadaConsulta, paginationHandle]);

  return (
    <Container>
      <Row>
        <Col className="col-12">
          <Card className="text-left">
            <div className="card-body">
              <div className="p-2">
                {isLoading && <Loading />}
                <TableContainer>
                  <TableStyled>
                    <Header
                      headerData={headerData}
                      orderColumn={gridPaginadaConsulta.orderColumn}
                      orderDirection={gridPaginadaConsulta.orderDirection}
                      setOrder={setOrder}
                    />
                    <Body>{children}</Body>
                  </TableStyled>
                </TableContainer>
                <ContainerPaging>
                  {total > 0 && (
                    <>
                      <DivQuantidadePagina style={{ zIndex: 500 }}>
                        <SelectOption>
                          {t('Mostrando')}
                          <select
                            name="selectPageSize"
                            className="form-control form-control-sm"
                            value={gridPaginadaConsulta.pageSize}
                            onChange={changePageSize}
                          >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                          {!isLargerThan900 && total > 0 && lastPage > 1
                            ? t(
                                `resultados por página, foram encontrados ${total} resultados.`
                              )
                            : t('resultados por página')}
                        </SelectOption>
                      </DivQuantidadePagina>
                      <DivTotalPaginas>
                        {gridPaginadaConsulta.currentPage && (
                          <ul className="pagination">
                            <Li>
                              <ButtonPageLink
                                onClick={(event) => setPage(event, 1)}
                                tabIndex={-1}
                              >
                                {t('Início')}
                              </ButtonPageLink>
                            </Li>
                            <Li>
                              <ButtonPageLink
                                aria-label={t('Anterior')}
                                onClick={(event) =>
                                  setPage(
                                    event,
                                    gridPaginadaConsulta.currentPage - 1
                                  )
                                }
                              >
                                <span aria-hidden="true">&laquo;</span>
                                <span className="sr-only">{t('Anterior')}</span>
                              </ButtonPageLink>
                            </Li>
                            {gridPaginadaConsulta.currentPage >= 3 && (
                              <Li>
                                <ButtonPageLink
                                  onClick={(event) =>
                                    setPage(
                                      event,
                                      gridPaginadaConsulta.currentPage - 2
                                    )
                                  }
                                >
                                  {gridPaginadaConsulta.currentPage - 2}
                                </ButtonPageLink>
                              </Li>
                            )}
                            {gridPaginadaConsulta.currentPage >= 2 && (
                              <Li>
                                <ButtonPageLink
                                  onClick={(event) =>
                                    setPage(
                                      event,
                                      gridPaginadaConsulta.currentPage - 1
                                    )
                                  }
                                >
                                  {gridPaginadaConsulta.currentPage - 1}
                                </ButtonPageLink>
                              </Li>
                            )}

                            <LiActive>
                              <ButtonPageLink
                                onClick={(e) => e.preventDefault()}
                              >
                                {gridPaginadaConsulta.currentPage}
                              </ButtonPageLink>
                            </LiActive>

                            {gridPaginadaConsulta.currentPage < lastPage && (
                              <Li>
                                <ButtonPageLink
                                  onClick={(event) =>
                                    setPage(
                                      event,
                                      gridPaginadaConsulta.currentPage + 1
                                    )
                                  }
                                >
                                  {gridPaginadaConsulta.currentPage + 1}
                                </ButtonPageLink>
                              </Li>
                            )}
                            {gridPaginadaConsulta.currentPage <
                              lastPage - 1 && (
                              <Li>
                                <ButtonPageLink
                                  onClick={(event) =>
                                    setPage(
                                      event,
                                      gridPaginadaConsulta.currentPage + 2
                                    )
                                  }
                                >
                                  {gridPaginadaConsulta.currentPage + 2}
                                </ButtonPageLink>
                              </Li>
                            )}

                            <Li>
                              <ButtonPageLink
                                aria-label={t('Próximo')}
                                onClick={(event) =>
                                  setPage(
                                    event,
                                    gridPaginadaConsulta.currentPage + 1
                                  )
                                }
                              >
                                <span aria-hidden="true">&raquo;</span>
                                <span className="sr-only">{t('Próximo')}</span>
                              </ButtonPageLink>
                            </Li>
                            <Li>
                              <ButtonPageLink
                                onClick={(event) => setPage(event, lastPage)}
                              >
                                {t('Última')}
                              </ButtonPageLink>
                            </Li>
                          </ul>
                        )}
                      </DivTotalPaginas>
                    </>
                  )}

                  {isLargerThan900 && (
                    <DivTotalRegistros>
                      {total < 1 && t('Nenhum resultado foi encontrado')}
                      {total === 1 && t('Foi encontrado 1 resultado')}
                      {total > 1 &&
                        t('Foram encontrados {{total}} resultados', { total })}
                    </DivTotalRegistros>
                  )}
                </ContainerPaging>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
});
