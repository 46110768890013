import React, { useEffect, useState, useCallback } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory, Prompt, RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import isPrenvent from 'helpers/layout/isPrenvent';
import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import ManterFoco from 'components/Geral/ManterFoco';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { Card } from 'styles';
import DataCriacaoAlteracao from 'components/Geral/DataCriacaoAlteracao';
import { useForm, yupResolver } from '../validationForm';
import { Container } from '../styles';

// eslint-disable-next-line import/no-named-as-default
import UncontrolledForm from '..';

type TParams = { id: string };

const Alterar = ({ match }: RouteComponentProps<TParams>) => {
  const history = useHistory();
  const isMountedRef = useIsMountedRef();
  isPrenvent();

  const [isLoading, setIsLoading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);

  const [dataHoraCadastro, setDataHoraCadastro] = useState('');
  const [dataHoraUltimaAlteracao, setDataHoraUltimaAlteracao] = useState('');
  const [meioPagamento, setMeioPagamento] = useState(-1);

  const {
    setError,
    clearErrors,
    register,
    control,
    watch,
    reset,
    getValues,
    setValue,
    formState,
  } = useForm({
    resolver: yupResolver,
    shouldUnregister: false,
  });

  const handleGetFormaPagamento = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<void, ResponseApi<any>>(
      ConstanteEnderecoWebservice.FORMA_PAGTO_OBTER,
      {
        params: { id: match.params.id },
      }
    );

    if (response?.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (response?.sucesso && isMountedRef.current) {
      const { contaFinanceiraOpcaoSelect } = response.dados;

      reset({
        ...response.dados,
        contaFinanceira: contaFinanceiraOpcaoSelect
          ? {
              value: contaFinanceiraOpcaoSelect.id,
              label: contaFinanceiraOpcaoSelect.nome,
            }
          : undefined,
        IntegracaoPagamento: response.dados.integracaoPagamento,
        CnpjCredenciadora: response.dados.cnpjCredenciadora,
      });

      setMeioPagamento(response.dados.regraMeioPagamento);
      if (response.dados.parcelas) {
        setValue('parcelas', response.dados.parcelas);
      }

      setDataHoraCadastro(response.dados.dataHoraCadastro);
      setDataHoraUltimaAlteracao(response.dados.dataHoraUltimaAlteracao);
    } else {
      history.push(ConstanteRotas.FORMA_RECEB);
    }

    if (isMountedRef.current) setIsLoading(false);
  }, [history, isMountedRef, match.params.id, reset, setValue]);

  useEffect(() => {
    setIsLoading(true);

    handleGetFormaPagamento();
  }, [handleGetFormaPagamento]);

  useEffect(() => {
    setFormIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  return (
    <Container>
      <Prompt when={formIsDirty} message="" />
      <Form>
        <ManterFoco blockTab={isLoading}>
          <Card>
            {isLoading && <LoadingPadrao />}
            <UncontrolledForm
              errors={formState.errors}
              setError={setError}
              clearErrors={clearErrors}
              watch={watch}
              control={control}
              register={register}
              readonly
              getValues={getValues}
              setValue={setValue}
              meioPagamento={meioPagamento}
              setMeioPagamento={setMeioPagamento}
            />
          </Card>
          <DataCriacaoAlteracao
            dataHoraCriacao={dataHoraCadastro}
            dataHoraUltimaAlteracao={dataHoraUltimaAlteracao}
          />
        </ManterFoco>
      </Form>
    </Container>
  );
};

export default Alterar;
