import { Route } from 'react-router-dom';

import { ConstanteRotasAlternativas } from 'constants/rotas';

import { TrayEtapas } from 'pages/Integracoes/LojaAplicativos/TrayCommerce/Etapas';

import LayoutGuard from './LayoutGuard';

export const TrayEtapasRoutes = [
  <Route path={ConstanteRotasAlternativas.TRAY_ETAPAS} key="tray-etapas">
    <LayoutGuard
      key="tray-etapas"
      component={TrayEtapas}
      path={ConstanteRotasAlternativas.TRAY_ETAPAS}
      exact
      meta={{ auth: true }}
    />
  </Route>,
];
