import { useCallback, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { ModalContent, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import { create } from 'react-modal-promise';

import { EnumStatusPagamentos } from 'constants/enum/enumPagamentos';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import MeioPagamentoEnum from 'constants/enum/fiscal/meioPagamento';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { ZoopModalProps, ResponseGerarPedidoZoop } from './types';
import { Pix } from './Pix';
import { PaymentFailedOrCanceled } from './components/PaymentFailedOrCanceled';

export const ModalPagamentosZoop = create<ZoopModalProps>(
  ({
    onResolve,
    onReject,
    connectionDetails,
    paymentDetails,
    gerarVendaAoPagar,
    ...rest
  }) => {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
    const [isLoading, setIsLoading] = useState(false);
    const [isGerandoVenda, setIsGerandoVenda] = useState(false);
    const [messageTransacao, setMessageTransacao] = useState(
      'Iniciando pagamento..'
    );
    const [transactionStatus, setTransactionStatus] = useState(
      EnumStatusPagamentos.PENDING
    );
    const [idTransacao, setIdTransacao] = useState('');
    const [qrCode, setQrCode] = useState('');

    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
    const [isLargerThan765] = useMediaQuery('(min-width: 765px)');

    const { hubConnection, joinGroup } = connectionDetails;
    const {
      valor,
      valorRestante,
      formaPagamento,
      idOperacao,
      cliente,
    } = paymentDetails;

    const isPagamentoNaTela =
      formaPagamento === MeioPagamentoEnum.PixPresencialZoop;

    const url = process.env.REACT_APP_MULTIPAY_URL;
    const linkTransacao = `${url}/pix/${idTransacao}`;

    const paymentFailedOrCanceled =
      transactionStatus === EnumStatusPagamentos.FAILED ||
      transactionStatus === EnumStatusPagamentos.CANCELED;

    const showErrorStatus = useCallback(() => {
      setTransactionStatus(EnumStatusPagamentos.FAILED);
      setMessageTransacao('OCORREU UM ERRO NO PAGAMENTO');
    }, []);

    const resetStatusTransacao = useCallback(() => {
      setTransactionStatus(EnumStatusPagamentos.PENDING);
      setMessageTransacao('Aguardando pagamento...');
    }, []);

    const changePaymentMethod = useCallback(async () => {
      onResolve({ success: false, cancel: false });
    }, [onResolve]);

    const fetchTransactionData = useCallback(async () => {
      setIsLoading(true);
      const response = await api.post<
        void,
        ResponseApi<ResponseGerarPedidoZoop>
      >(ConstanteEnderecoWebservice.GERAR_TRANSACAO_ZOOP, paymentDetails);
      if (!response) showErrorStatus();

      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((aviso: string) => toast.warning(aviso));
          showErrorStatus();
        }
        if (!response.sucesso) showErrorStatus();
        if (response.sucesso && response?.dados) {
          const {
            linkQrCodeTransacaoList: newQrCode,
            identificador: newIdTransacao,
          } = response.dados;

          if (paymentFailedOrCanceled) {
            resetStatusTransacao();
          }
          setIdTransacao(newIdTransacao || '');
          setQrCode(newQrCode ? newQrCode[0] : '');
        }
      }
      setIsLoading(false);
    }, [
      paymentDetails,
      showErrorStatus,
      paymentFailedOrCanceled,
      resetStatusTransacao,
    ]);

    const finalizarPagamento = useCallback(async () => {
      if (!isGerandoVenda) {
        if (valor === valorRestante) {
          setIsGerandoVenda(true);
          onResolve({
            success: true,
            cancel: false,
            gerarVenda: true,
          });
          onClose();
          return;
        }
        onResolve({ success: true, cancel: false });
        onClose();
      }
    }, [isGerandoVenda, onClose, onResolve, valor, valorRestante]);

    const handleCancelar = useCallback(async () => {
      onResolve({ success: false, cancel: true });
      onClose();
    }, [onClose, onResolve]);

    const transactionStatusSignalListener = useCallback(() => {
      joinGroup('zoop');
      joinGroup(`${idOperacao}_aguardando_transacao`);

      hubConnection.on(
        'alterar-status-transacao',
        (status: string, mensagem: string) => {
          if (status) {
            setTransactionStatus(status);
          }
          if (mensagem) {
            setMessageTransacao(mensagem);
          }
        }
      );

      if (idTransacao) {
        hubConnection.on(
          'finalizar-zoop',
          (status: string, zoopId: string, mensagem: string) => {
            if (zoopId === idTransacao) {
              if (status) {
                setTransactionStatus(status);
              }
              if (mensagem) {
                setMessageTransacao(mensagem);
              }
            }
          }
        );
      }
    }, [hubConnection, idOperacao, idTransacao, joinGroup]);

    useEffect(() => {
      transactionStatusSignalListener();
    }, [transactionStatusSignalListener]);

    useEffect(() => {
      if (paymentFailedOrCanceled) return;
      fetchTransactionData();
    }, [fetchTransactionData, paymentFailedOrCanceled]);

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? '5xl' : 'full'}
        {...rest}
        closeOnOverlayClick={false}
        isOpen={isOpen}
        closeOnEsc={false}
        onClose={onClose}
        autoFocus
      >
        <ModalContent
          bg="gray.100"
          borderRadius={isLargerThan900 ? '5px' : '0'}
          w="900px"
        >
          {isLoading && <LoadingPadrao />}
          {paymentFailedOrCanceled ? (
            <PaymentFailedOrCanceled
              messageTransacao={messageTransacao}
              retryTransaction={fetchTransactionData}
              changePaymentMethod={changePaymentMethod}
            />
          ) : (
            <Pix
              finalizarPagamento={finalizarPagamento}
              handleCancelar={handleCancelar}
              operacao={{
                messageTransacao,
                valor,
                qrCode,
                linkTransacao,
                isPagamentoNaTela,
                cliente,
              }}
              screenResolution={{
                isLargerThan765,
                isLargerThan900,
              }}
              transactionStatus={transactionStatus}
            />
          )}
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
