import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  ReactNode,
} from 'react';

type FiltrosType = {
  [x: string]: any;
};

interface ClienteFornecedorContextProps {
  contextFiltros?: FiltrosType;
  setContextFiltros: Dispatch<SetStateAction<FiltrosType>>;
}

const ClienteFornecedorFiltrosContext = createContext<ClienteFornecedorContextProps>(
  {} as ClienteFornecedorContextProps
);

interface ClienteFornecedorFiltroProviderProps {
  children: ReactNode;
}

export default function ClienteFornecedorFiltroProvider({
  children,
}: ClienteFornecedorFiltroProviderProps): JSX.Element {
  const [contextFiltros, setContextFiltros] = useState<FiltrosType>();

  return (
    <ClienteFornecedorFiltrosContext.Provider
      value={{
        contextFiltros,
        setContextFiltros,
      }}
    >
      {children}
    </ClienteFornecedorFiltrosContext.Provider>
  );
}

export function useClienteFornecedorFiltroContext(): ClienteFornecedorContextProps {
  const context = useContext(ClienteFornecedorFiltrosContext);

  if (!context)
    throw new Error(
      'useLojaFiltroContext must be used within a LojaFiltroProvider.'
    );

  return context;
}
