import {
  Box,
  Button,
  Grid,
  Text,
  GridItem,
  Flex,
  FormLabel,
  useMediaQuery,
  SimpleGrid,
} from '@chakra-ui/react';

import Input from 'components/PDV/Input';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { Switch } from 'components/update/Switch';
import CheckboxAtivoInativo from 'components/update/Checkbox/CheckboxAtivoInativo';
import Separator from 'components/Layout/Menu/Submenu/Nav/Separator';

import { Container } from './Components/Container';
import { Content } from './Components/Content';
import { useFormularioPromocao, listUsoPromocao } from './hooks';
import { Produtos } from './Components/Produtos';

type FormularioProps = {
  isAlterar?: boolean;
  isReadOnly?: boolean;
};

export const Formulario = ({ isAlterar, isReadOnly }: FormularioProps) => {
  const {
    diasSemanaSelecionados,
    handleAdicionarDiasSemana,
    handleAdicionarTodosDiasSemana,
    fields,
    handleSelecionarLojas,
    listLojas,
    possuiServicoFrenteCaixa,
  } = useFormularioPromocao();

  const [isSmallerThan1139] = useMediaQuery('(max-width: 1139px)');

  return (
    <>
      <SimpleGridForm>
        <GridItem colSpan={[12, 12, 6]}>
          <Input
            label="Nome"
            isRequired
            isDisabled={isReadOnly}
            maxLength={100}
            borderRadius="md"
            placeholder="Digite o nome da campanha"
            name="nome"
          />
        </GridItem>
        <GridItem colSpan={[12, 12, 6]}>
          <CheckboxAtivoInativo
            id="ativo"
            name="ativo"
            label="Status"
            isRequired
            isDisabled={isReadOnly}
          />
        </GridItem>
        <GridItem colSpan={[12, 12, 12, 6]}>
          <SimpleGridForm>
            <GridItem colSpan={12}>
              <Container
                px={['10px', '32px']}
                bg="gray.50"
                h={['200px', '200px', '105px']}
                title="Informe o período de duração da promoção"
              >
                <SimpleGridForm w="full">
                  <GridItem colSpan={[12, 12, 6]}>
                    <Input
                      label="Data de início"
                      type="date"
                      isDisabled={isReadOnly}
                      size="sm"
                      name="periodoVigenciaInicio"
                    />
                  </GridItem>
                  <GridItem colSpan={[12, 12, 6]}>
                    <Input
                      label="Data de término"
                      type="date"
                      size="sm"
                      isDisabled={isReadOnly}
                      name="periodoVigenciaFim"
                    />
                  </GridItem>
                </SimpleGridForm>
              </Container>
            </GridItem>
            <GridItem colSpan={12}>
              <Container
                px={['10px', '32px']}
                bg="gray.50"
                h={['200px', '200px', '105px']}
                title="Informe o horário de duração da promoção"
              >
                <SimpleGridForm w="full">
                  <GridItem colSpan={[12, 12, 6]}>
                    <Input
                      type="time"
                      label="Horário de início"
                      size="sm"
                      isDisabled={isReadOnly}
                      name="horarioVigenciaInicio"
                    />
                  </GridItem>
                  <GridItem colSpan={[12, 12, 6]}>
                    <Input
                      label="Horário de término"
                      type="time"
                      isDisabled={isReadOnly}
                      size="sm"
                      name="horarioVigenciaFim"
                    />
                  </GridItem>
                </SimpleGridForm>
              </Container>
            </GridItem>
          </SimpleGridForm>
        </GridItem>
        <GridItem colSpan={[12, 12, 12, 6]}>
          <Container
            px={['10px', '32px']}
            h={['auto', 'auto', '264px']}
            bg="gray.50"
            title="Informe as configurações da promoção"
          >
            <Grid
              templateColumns={[
                '1fr 1fr ',
                '1fr 1fr 1fr 1fr',
                '1fr 1fr 1fr 1fr',
                '1fr 1fr 1fr 1fr',
              ]}
              gap={4}
              w="fit-content"
              m={{ base: 'auto', sm: 0 }}
            >
              {fields.map((dayOfWeek) => (
                <Button
                  size="md"
                  isDisabled={isReadOnly}
                  w="100px"
                  borderRadius="full"
                  variant={dayOfWeek.ativo ? 'solid' : 'outlineDefault'}
                  colorScheme={dayOfWeek.ativo ? 'aquamarine' : 'gray'}
                  onClick={() => handleAdicionarDiasSemana(dayOfWeek.value)}
                >
                  {dayOfWeek.label}
                </Button>
              ))}
              <Button
                size="md"
                isDisabled={isReadOnly}
                w="100px"
                borderRadius="full"
                onClick={handleAdicionarTodosDiasSemana}
                variant={diasSemanaSelecionados ? 'solid' : 'outlineDefault'}
                colorScheme={diasSemanaSelecionados ? 'aquamarine' : 'gray'}
              >
                Todos
              </Button>
            </Grid>
            {possuiServicoFrenteCaixa && (
              <>
                <Box my="22px">
                  <Separator
                    fontWeight="normal"
                    title="Informe onde sera utilizada a promoção"
                  />
                </Box>
                <Grid
                  templateColumns={['1fr', '1fr 1fr 1fr', '1fr 1fr 1fr']}
                  gap={4}
                  w="fit-content"
                  m={{ base: 'auto', sm: 0 }}
                >
                  {listUsoPromocao.map((itemPromocao, index) => (
                    <Content>
                      <Flex justifyContent="space-between" w="full">
                        <FormLabel>{itemPromocao.label}</FormLabel>
                        <Flex>
                          <Switch
                            size="md"
                            label=""
                            isDisabled={isReadOnly}
                            id={`telasUsoPromocao.${index}`}
                            name={`telasUsoPromocao.${index}`}
                          />
                        </Flex>
                      </Flex>
                    </Content>
                  ))}
                </Grid>
              </>
            )}
          </Container>
        </GridItem>

        <GridItem colSpan={12}>
          <Container
            h={['auto', 'auto', 'full']}
            px={['10px', '32px']}
            bg="gray.50"
            title="Selecione as lojas que irão participar da promoção"
          >
            <SimpleGrid
              templateColumns={[
                'repeat(auto-fill, minmax(full, full))',
                'repeat(auto-fill, minmax(full, full))',
                'repeat(auto-fill, minmax(200px, 300px))',
              ]}
              spacing="6"
              display={!isSmallerThan1139 ? undefined : 'flex'}
              mb="34px"
              flexDirection={[
                'column-reverse',
                'column-reverse',
                isSmallerThan1139 ? 'column-reverse' : 'row',
              ]}
            >
              {listLojas.map((lojaItem, index) => (
                <Content mb="10px">
                  <Flex justifyContent="space-between" w="full">
                    <Box>
                      <FormLabel>{lojaItem.razaoSocial}</FormLabel>
                      <Text fontSize="12px">
                        {lojaItem?.endereco.logradouro},{' '}
                        {lojaItem?.endereco.numero} -{' '}
                        {lojaItem?.endereco.bairro} -{' '}
                        {lojaItem?.endereco.cidade} -{' '}
                        {lojaItem?.endereco.estado}
                      </Text>
                    </Box>
                    <Flex>
                      <Switch
                        size="md"
                        isDisabled={isReadOnly}
                        label=""
                        change={(event) =>
                          handleSelecionarLojas(event, index, lojaItem.id)
                        }
                        id={`lojas.${index}`}
                        name={`lojas.${index}`}
                      />
                    </Flex>
                  </Flex>
                </Content>
              ))}
            </SimpleGrid>
          </Container>
        </GridItem>
        <GridItem colSpan={12} />
      </SimpleGridForm>
      <Produtos isAlterar={isAlterar} isReadOnly={isReadOnly} />
    </>
  );
};
