import { unitNames } from './groups/unitNames';
import { digitGroups, tenToNineteen } from './groups/digitGroups';

function getGroup(number: number | string): string {
  if (number === '100' || number === 100) return 'cem';

  let output = '';
  for (let i = 0; i < number.toString().length; i += 1) {
    const c = number.toString()[i];
    if (output) {
      if (c === '0') {
        return output;
      }
      output += ' e ';
    }
    if (
      ((number.toString().length === 2 && i === 0) ||
        (number.toString().length === 3 && i === 1)) &&
      c === '1'
    ) {
      return output + tenToNineteen[+number.toString()[i + 1]];
    }
    output += digitGroups[number.toString().length - i - 1][+c];
  }
  return output;
}

function numberInFull(number: string | number): string {
  const output: string[] = [];
  const numbers: { number: number; name: string }[] = [];

  let newNumber = number.toString();

  while (newNumber.length > 0) {
    const piece =
      newNumber.length <= 3
        ? newNumber
        : newNumber.substr(newNumber.length - 3, 3);
    numbers.push({ number: +piece, name: getGroup(piece) });
    newNumber =
      newNumber.length - 3 > 0 ? newNumber.substr(0, newNumber.length - 3) : '';
  }

  if (numbers.length === 1 && numbers[0].number === 0) return numbers[0].name;

  for (let i = numbers.length - 1; i > 0; i -= 1) {
    if (numbers[i].number !== 0) {
      output.push(
        `${numbers[i].name} ${
          numbers[i].number === 1
            ? unitNames[i].replace('ões', 'ão')
            : unitNames[i]
        }`
      );
    }
  }

  if (numbers.length && numbers[0].number > 0) {
    output.push(
      `${
        numbers.length > 1 &&
        (numbers[0].number < 100 || numbers[0].number % 100 === 0)
          ? 'e '
          : ''
      }${numbers[0].name}`
    );
  } else if (output.length > 1) {
    output.splice(output.length - 1, 0, 'e');
  }

  return output.join(' ');
}

function numberToWords(newNumbers: string | number) {
  const numberStr = newNumbers.toString().replace('-', '').split('.');
  const numberBefore = numberStr[0] || 0;
  const numberAfter = `${numberStr[1]}00`.substr(0, 2);

  const numberBeforeExtended =
    (newNumbers < 0 ? 'menos ' : '') + numberInFull(numberBefore);
  const numberAfterExtended = numberInFull(Number(numberAfter));

  return (
    (numberBefore > 0
      ? numberBeforeExtended +
        (numberBefore.toString() === '1' ? ' real' : ' reais')
      : '') +
    (Number(numberAfter) > 0
      ? (numberBefore > 0 ? ' e ' : '') +
        numberAfterExtended +
        (Number(numberAfter) === 1 ? ' centavo' : ' centavos')
      : '')
  );
}

export const formatNumberToWords = (number: number) => {
  const result = numberToWords(number);
  return result;
};
