import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export type ContasFinanceirasProps = {
  nome: string;
  id: string;
  padraoSistema: boolean;
}[];

export type FormData = {
  juros: number;
  multa: number;
  desconto: number;
  totalBaixa: number;
  valor: number;
  dataPagamento: Date;
  formaPagamentoId: {
    value: string;
    label: string;
    lancarFatura: boolean;
    diaVencimento: number;
    contasFinanceiras: ContasFinanceirasProps;
  };
  contaFinanceiraId: string;
  parcelas?: number;
  dataVencimentoCartao?: Date;
};

type FormaPagamentoId = {
  label: string;
  lancarFatura: boolean;
};

const schema = yup.object().shape({
  totalBaixa: yup
    .number()
    .positive(ConstanteMensagemValidacao.VALOR_MAIOR_QUE_0)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  dataPagamento: yup
    .date()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  formaPagamentoId: yup.object().shape({
    lancarFatura: yup.boolean(),
    value: yup.string().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  }),
  contaFinanceiraId: yup.string().when('formaPagamentoId', {
    is: (formaPagamentoId: FormaPagamentoId) =>
      formaPagamentoId.lancarFatura === true,
    then: yup.string().notRequired(),
    otherwise: yup
      .string()
      .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  }),
});

export const yupResolver = yupResolverInstance(schema);
