import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { moneyMask } from 'helpers/format/fieldsMasks';

interface Totais {
  valorTotal: number;
  itens: Array<Itens>;
}

interface Itens {
  nome: string;
  quantidade: number;
  valorUnitario: number;
  desconto: number;
  valorTotal: number;
}

const Totais = ({ valorTotal, itens }: Totais) => {
  const itensQuantity = itens?.reduce((acc, item) => item.quantidade + acc, 0);
  return (
    <Flex
      w="100%"
      borderBottom="1px solid"
      borderColor="gray.700"
      justifyContent="flex-end"
      pt={1}
      pb={1}
    >
      <Flex
        w="60%"
        maxW="150px"
        direction="column"
        alignItems="flex-end"
        justifyContent="center"
      >
        <Flex pt={1} w="100%" justifyContent="space-between">
          <Text fontWeight="semibold" fontSize="x-small">
            Total de Itens:
          </Text>
          <Text as="span" fontWeight="semibold" fontSize="x-small">
            {itensQuantity}
          </Text>
        </Flex>
        <Flex pb={1} w="100%" justifyContent="space-between">
          <Text fontWeight="extrabold" fontSize="x-small">
            Valor Total:
          </Text>
          <Text fontWeight="extrabold" as="span" fontSize="x-small">
            {moneyMask(valorTotal, false)}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Totais;
