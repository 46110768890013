import { useEffect, useState, useCallback } from 'react';
import {
  ModalProps as ModalChakraProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  ModalFooter,
  ModalHeader,
  useMediaQuery,
  Divider,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { UncontrolledForm } from '../index';
import { FormData, defaultValues, yupResolver } from '../validationForm';

type ModalProps = {
  callback: (data: FormData) => Promise<{ success: boolean }>;
  id: string;
};

type ModalUpdatePrintDepartmentsProps = Omit<
  ModalChakraProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<void> &
  ModalProps;

export interface PrintDepartment {
  id: string;
  departamento: Departments;
  impressora: Printers;
  caminho: string;
  quantidadeLinhasFim: number;
  corteGuilhotina: number;
  tipoImpressao: number;
  tipoCupom: number;
}

export interface Departments {
  id: string;
  nome: string;
  ativo: boolean;
  nomeEcommerce: string;
}

type Printers = PrintDepartment;

export const ModalUpdatePrintDepartment = create<ModalUpdatePrintDepartmentsProps>(
  ({ onResolve, onReject, callback, id, ...rest }) => {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
    const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

    const [isLoading, setIsLoading] = useState(false);

    const formMethods = useForm<FormData>({
      defaultValues,
      resolver: yupResolver,
    });

    const { handleSubmit, reset } = formMethods;

    const handleSave = useCallback(
      async (data: FormData) => {
        setIsLoading(true);
        const { success } = await callback(data);

        if (success) {
          onClose();
        }
        setIsLoading(false);
      },
      [callback, onClose]
    );

    const getPrintDepartment = useCallback(async () => {
      setIsLoading(true);
      const response = await api.get<void, ResponseApi<PrintDepartment>>(
        `${ConstanteEnderecoWebservice.DEPARTAMENTO_IMPRESSORA}/${id}`
      );

      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response?.sucesso && response?.dados) {
          const newValues = {
            ...response.dados,
            departamentoId: response.dados?.departamento.id,
            impressoraId: response.dados?.impressora.id,
          };
          reset(newValues);
        }
      }
      setIsLoading(false);
    }, [id, reset]);

    useEffect(() => {
      getPrintDepartment();
    }, [getPrintDepartment]);

    return (
      <ModalPadraoChakra
        size={isLargerThan700 ? '4xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalContent bg="gray.50">
          <ModalHeader>
            <Text color="primary.50" fontSize="md">
              Departamento de impressão
            </Text>
            <Text color="gray.700" fontSize="xs">
              Edite as informações do departamento de impressão e clique em
              &quot;Salvar&quot; para confirmar as alterações.
            </Text>
          </ModalHeader>
          <Divider
            orientation="horizontal"
            width="calc(100% - 48px)"
            margin="0 auto"
          />
          <ModalBody mt="12px">
            {isLoading && <LoadingPadrao />}
            <FormProvider {...formMethods}>
              <UncontrolledForm />
            </FormProvider>
          </ModalBody>
          <ModalFooter
            justifyContent="center"
            flexWrap="wrap"
            gap={6}
            py="24px"
          >
            <Button
              w={{ base: 'full', sm: 'min-content' }}
              colorScheme="gray"
              variant="outlineDefault"
              onClick={onClose}
            >
              Cancelar
            </Button>
            <Button
              w={{ base: 'full', sm: 'min-content' }}
              minW="120px"
              colorScheme="secondary"
              onClick={handleSubmit(handleSave)}
              isDisabled={isLoading}
            >
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
