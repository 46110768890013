import React, { memo, useState } from 'react';
import {
  useMediaQuery,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  HStack,
  Button,
  ModalFooter,
} from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { useOperacaoContext } from 'store/PDV/Operacao';
import { usePagamentoContext } from 'store/PDV/Pagamento';
import TipoValorEnum, { TipoValor } from 'constants/enum/tipoValor';
import getHeaderChaveTemporaria from 'helpers/api/getHeaderChaveTemporaria';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';

import { MobileModalMenu } from 'components/PDV/Layout/MobileModalMenu';
import { CalcularValorTotalDescontoAdicional } from './Totalizadores';
import Conteudo from './Conteudo';

interface AcrescimosDescontosModalProps {
  isOpen: boolean;
  onClose: () => void;
  isReadOnly: boolean;
  chavePermissaoTemporaria?: string;
}

const AcrescimosDescontosModal: React.FC<AcrescimosDescontosModalProps> = ({
  isOpen,
  onClose,
  isReadOnly,
  chavePermissaoTemporaria,
}) => {
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const { operacaoId } = useOperacaoContext();
  const {
    totais,
    setTotais,
    setAcrescimoFoiLimpo,
    setAparecerValorAcrescimoHeaderPdv,
    setMovimentacoesFinanceiras,
  } = usePagamentoContext();
  const [resetAcrescimoValor, setIsResetAcrescimoValor] = useState<
    boolean | undefined
  >(true);
  const formMethods = useForm({
    defaultValues: {
      descontoItens: 0,
      descontoAdicional: 0,
      acrescimos: 0,
      outrasDespesas: 0,
      valorFrete: 0,
      tipoDescontoAdicional: TipoValorEnum.REAIS,
    },
  });

  const onSubmit = async () => {
    const {
      descontoAdicional: valorDescontoAdicional = 0,
      tipoDescontoAdicional = TipoValorEnum.REAIS,
      acrescimos: valorTotalAcrescimo = 0,
      outrasDespesas: valorTotalOutrasDespesas = 0,
      valorFrete: valorTotalFrete = 0,
    } = formMethods.getValues() ?? {};

    if (totais) {
      const descontoAdicional = CalcularValorTotalDescontoAdicional(
        tipoDescontoAdicional as TipoValor,
        valorDescontoAdicional,
        totais.valorTotalItensComDesconto ?? 0
      );
      const valorTotalDescontoAdicional =
        Math.round((descontoAdicional + Number.EPSILON) * 100) / 100;

      const newTotais = {
        ...totais,
        valorTotalDescontoAdicional,
        valorDescontoAdicional,
        tipoDescontoAdicional: tipoDescontoAdicional as TipoValor,
        valorTotalAcrescimo,
        valorTotalOutrasDespesas,
        valorTotalFrete,
      };

      const response = await api.put<void, ResponseApi>(
        ConstanteEnderecoWebservice.PEDIDOORCAMENTOVENDA_ATUALIZAR_TOTAIS,
        newTotais,
        {
          params: { id: operacaoId },
          ...getHeaderChaveTemporaria(chavePermissaoTemporaria),
        }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.map((item: string) => toast.warning(item));
        }

        if (response.sucesso) {
          const totalAcrescimos =
            valorTotalAcrescimo + valorTotalOutrasDespesas + valorTotalFrete;
          const totalDescontos =
            valorTotalDescontoAdicional + (totais.valorTotalDescontoItem || 0);

          setTotais({
            ...newTotais,
            valorTotal:
              Math.round(
                ((newTotais.valorTotalItensSemDesconto || 0) +
                  (totalAcrescimos - totalDescontos) +
                  Number.EPSILON) *
                  100
              ) / 100,
          });

          setAcrescimoFoiLimpo(false);
          setAparecerValorAcrescimoHeaderPdv(false);
          setMovimentacoesFinanceiras((valorAnterior) =>
            valorAnterior.map((item) => ({
              ...item,
              valor: item.valor - item.acrescimo,
              acrescimo: 0,
            }))
          );

          onClose();
          setIsResetAcrescimoValor(true);
        }
      }
    }
  };

  function resetAcrescimo() {
    if (
      resetAcrescimoValor &&
      (Number(totais?.valorTotalAcrescimo) > 0 ||
        Number(totais?.valorTotalAcrescimo) < 0)
    ) {
      setIsResetAcrescimoValor(false);
    }
    formMethods.setValue('acrescimos', 0);
  }

  if (!isLargerThan900) {
    return (
      <MobileModalMenu
        isOpen={isOpen}
        onClose={onClose}
        appendToParentPortal={false}
        title="Acréscimos e descontos"
      >
        <FormProvider {...formMethods}>
          <Conteudo isReadOnly={isReadOnly} resetAcrescimo={resetAcrescimo} />
        </FormProvider>
        <HStack w="full" justifyContent="center" py="6" pb="10" spacing={6}>
          <Button
            variant="solid"
            colorScheme="secondary"
            size="lg"
            minW="100px"
            onClick={onSubmit}
            isDisabled={isReadOnly}
          >
            Confirmar
          </Button>
        </HStack>
      </MobileModalMenu>
    );
  }

  return (
    <ModalPadraoChakra
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setIsResetAcrescimoValor(true);
      }}
      size={isLargerThan900 ? 'xl' : 'full'}
      scrollBehavior={isLargerThan900 ? 'inside' : 'outside'}
      isCentered={isLargerThan900}
    >
      <ModalContent
        margin={0}
        maxW={isLargerThan900 ? 600 : undefined}
        borderRadius={isLargerThan900 ? 'md' : 0}
        bg="gray.50"
      >
        <ModalHeader
          mt={isLargerThan900 ? undefined : 10}
          mb={isLargerThan900 ? undefined : 8}
        >
          Acréscimos e Descontos
        </ModalHeader>
        <ModalCloseButton
          mt={isLargerThan900 ? undefined : 12}
          mr={isLargerThan900 ? undefined : 6}
        />
        <ModalBody>
          <FormProvider {...formMethods}>
            <Conteudo isReadOnly={isReadOnly} resetAcrescimo={resetAcrescimo} />
          </FormProvider>
        </ModalBody>
        <ModalFooter py={8} justifyContent="center">
          <HStack spacing={6}>
            <Button
              variant="outline"
              size="sm"
              minW="100px"
              color="gray.400"
              onClick={onClose}
            >
              Cancelar
            </Button>

            <Button
              variant="solid"
              colorScheme="secondary"
              size="sm"
              minW="100px"
              onClick={onSubmit}
              isDisabled={isReadOnly && resetAcrescimoValor}
            >
              Confirmar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export default memo(AcrescimosDescontosModal);
