import React from 'react';
import { Flex, Button, FlexProps } from '@chakra-ui/react';

import { useSmartPosContext } from 'store/SmartPos/SmartPosContext';
import { EnumSmartPos } from 'constants/enum/enumSmartPos';

export const Footer = ({ ...rest }: FlexProps) => {
  const { ref, activeStep } = useSmartPosContext();

  const isImpressaoActive = activeStep.value === EnumSmartPos.IMPRESSAO.value;

  return (
    <Flex
      w={['full', 'full', isImpressaoActive ? '70%' : 'full']}
      justifyContent="center"
      alignItems="center"
      {...rest}
    >
      <Button
        variant="outlineDefault"
        mr="24px"
        borderRadius="16px"
        onClick={() => {
          if (ref?.current?.handleCancelar) {
            ref?.current?.handleCancelar();
          }
        }}
        colorScheme="gray"
        w="96px"
      >
        Cancelar
      </Button>
      <Button
        borderRadius="16px"
        variant="solid"
        onClick={() => {
          if (ref?.current?.handleSalvar) {
            ref?.current?.handleSalvar();
          }
        }}
        colorScheme="aquamarine"
        w="120px"
      >
        Salvar
      </Button>
    </Flex>
  );
};
