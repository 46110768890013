import { toast } from 'react-toastify';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

interface CidadeInterface {
  id: number;
  codigoIbge: string;
}

const getCidadeLojaLogada = async (): Promise<CidadeInterface | undefined> => {
  const response = await api.get<void, ResponseApi<CidadeInterface>>(
    ConstanteEnderecoWebservice.LOJA_OBTER_CIDADE
  );

  if (response?.avisos) {
    response.avisos.map((item: string) => toast.warning(item));
  }

  if (response?.sucesso) {
    return response.dados;
  }

  return undefined;
};

export default getCidadeLojaLogada;
