import { useEffect, useState, useCallback } from 'react';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Icon,
  Skeleton,
  Stack,
  Text,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import formatDate from 'date-fns/format';
import { ptBR } from 'date-fns/locale';
import { AutoSizer, List } from 'react-virtualized';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteFuncionalidades from 'constants/permissoes';
import TipoContaFinanceiraEnum from 'constants/enum/tipoContaFinanceira';
import ConstanteRotas from 'constants/rotas';
import { capitalize } from 'helpers/format/stringFormats';
import auth from 'modules/auth';
import api, { ResponseApi } from 'services/api';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';
import { useExtratoContext } from 'store/Financeiro/Extrato';
import { setDateMaxHours, setDateMinHours } from 'helpers/data/setHoursDate';

import {
  CompartilharMenuIcon,
  EstoqueTransferenciaEstoqueIcon,
  SalvarInserirNovoIcon,
} from 'icons';
import InputDateRange from 'components/PDV/InputDateRange';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { SimpleCard } from 'components/update/Form/SimpleCard';
import { TextValor } from 'components/PDV/Text/TextValor';
import { ButtonFuncionalidade } from 'components/update/Button/ButtonFuncionalidade';

import { ItemLancamentoExtrato } from './ItemLancamentoExtrato';
import { ModalTransferenciaEntreContas } from './ModalTransferenciaEntreContas';
import { FormData, formDefaultValues } from './validationForm';
import { Extrato } from './Types';

type RouteParams = {
  id: string;
};

export const ExtratoDetalhes = () => {
  const {
    setIdRota,
    valueDataExtrato,
    setValueDataExtrato,
  } = useExtratoContext();
  const { casasDecimais } = usePadronizacaoContext();

  const formMethods = useForm<FormData>({
    defaultValues: valueDataExtrato || formDefaultValues(),
  });

  const [filtrosAtuais, setFiltrosAtuais] = useState<FormData>(
    valueDataExtrato || formDefaultValues()
  );
  const [isLoading, setIsLoading] = useState(false);
  const [extrato, setExtrato] = useState<Extrato>();

  const { id: idContaFinanceira } = useParams<RouteParams>();
  const history = useHistory();

  const { permitido: clientCanThrowNewAccount } = auth.possuiPermissao(
    ConstanteFuncionalidades.LANCAMENTO_CADASTRAR
  );

  const contaFinanceiraDoTipoCaixaOuMovel =
    extrato?.tipoContaFinanceira === TipoContaFinanceiraEnum.CAIXA ||
    extrato?.tipoContaFinanceira === TipoContaFinanceiraEnum.CAIXA_MOVEL;

  const handleSubmitFiltros = formMethods.handleSubmit((data) => {
    setFiltrosAtuais(data);
  });

  function handleNovoLancamento(tipoConta: 'contas-receber' | 'contas-pagar') {
    history.push(ConstanteRotas.EXTRATOS_LANCAMENTO.replace(':id', tipoConta));
  }

  const toggleLancamentoConciliado = useCallback(
    (periodoIndex: number, lancamentoIndex: number) => {
      setExtrato((prev) => {
        if (prev) {
          const periodos = [...prev.periodos];

          const periodo = periodos[periodoIndex];
          const lancamento = periodo.lancamentos[lancamentoIndex];

          periodo.lancamentos.splice(lancamentoIndex, 1, {
            ...lancamento,
            conciliado: !lancamento.conciliado,
            visualmenteConciliado: false,
          });

          periodos.splice(periodoIndex, 1, periodo);

          return {
            ...prev,
            totalConciliado:
              prev.totalConciliado +
              (lancamento.conciliado
                ? lancamento.valor * -1
                : lancamento.valor),
            periodos,
          };
        }

        return undefined;
      });
    },
    []
  );

  const toggleLancamentoVisualmenteConciliado = useCallback(
    (periodoIndex: number, lancamentoIndex: number) => {
      setExtrato((prev) => {
        if (prev) {
          const periodos = [...prev.periodos];

          const periodo = periodos[periodoIndex];
          const lancamento = periodo.lancamentos[lancamentoIndex];

          periodo.lancamentos.splice(lancamentoIndex, 1, {
            ...lancamento,
            visualmenteConciliado: !lancamento.visualmenteConciliado,
          });

          periodos.splice(periodoIndex, 1, periodo);

          return {
            ...prev,
            periodos,
          };
        }

        return undefined;
      });
    },
    []
  );

  const obterExtrato = useCallback(async () => {
    setIsLoading(true);
    setExtrato(undefined);
    const { dataInicio, dataFim } = filtrosAtuais;

    const response = await api.get<void, ResponseApi<Extrato>>(
      ConstanteEnderecoWebservice.EXTRATO_LISTAR,
      {
        params: {
          idContaFinanceira,
          dataInicio: setDateMinHours(new Date(dataInicio)),
          dataFim: setDateMaxHours(new Date(dataFim)),
        },
      }
    );
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        setExtrato(response.dados);
      }
    }

    setIsLoading(false);
  }, [filtrosAtuais, idContaFinanceira]);

  async function handleNovaTransferencia() {
    const { sucesso } = await ModalTransferenciaEntreContas({
      contaFinanceiraOrigemId: idContaFinanceira,
    });

    if (sucesso) {
      obterExtrato();
    }
  }

  useEffect(() => {
    obterExtrato();
  }, [obterExtrato]);

  useEffect(() => {
    setIdRota(idContaFinanceira);
    setValueDataExtrato(filtrosAtuais);
  }, [setIdRota, idContaFinanceira, filtrosAtuais, setValueDataExtrato]);

  return (
    <FormProvider {...formMethods}>
      {isLoading && <LoadingPadrao />}

      <Stack
        direction={{ base: 'column', md: 'row' }}
        spacing={{ base: '4', md: '6' }}
        justifyContent="space-between"
      >
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: '4', md: '6' }}
        >
          <InputDateRange
            startDateName="dataInicio"
            endDateName="dataFim"
            onConfirm={handleSubmitFiltros}
            cleanFiltersButtonText="Voltar"
            minW="250px"
            borderRadius="full"
            validarMesAnteriorSucessor
            bg="transparent"
            borderColor="gray.400"
            color="gray.400"
            size="sm"
            sx={{
              '& > .chakra-input': {
                pl: 6,
              },
              '& > .chakra-input__right-element': {
                mr: 4,
              },
            }}
          />

          <ButtonFuncionalidade
            funcionalidade={ConstanteFuncionalidades.EXTRATO_TRANSFERENCIA}
            variant="outline"
            minWidth={{ base: 'full', md: '156px' }}
            size="sm"
            leftIcon={<Icon as={EstoqueTransferenciaEstoqueIcon} />}
            onClick={
              extrato?.emAberto || contaFinanceiraDoTipoCaixaOuMovel
                ? () =>
                    toast.warning(
                      'A transferência de caixas em aberto é feita pelas telas de suprimento e sangria.'
                    )
                : handleNovaTransferencia
            }
          >
            Transferência
          </ButtonFuncionalidade>
          <Button
            variant="outline"
            leftIcon={<Icon as={CompartilharMenuIcon} />}
            size="sm"
            minW="154px"
            isDisabled
            visibility="hidden"
          >
            Compartilhar
          </Button>
        </Stack>

        <Menu>
          {({ isOpen }) => (
            <>
              <MenuButton
                bg="secondary.500"
                color="primary.700"
                isActive={isOpen}
                as={Button}
                minWidth={{ base: 'full', md: '15rem' }}
                h="8"
                borderRadius="999px"
                px="8"
                disabled={!clientCanThrowNewAccount}
                leftIcon={<SalvarInserirNovoIcon />}
                _hover={{
                  backgroundColor: 'secondary.500',
                }}
                _active={{
                  backgroundColor: 'secondary.500',
                }}
              >
                Novo lançamento
              </MenuButton>
              <MenuList>
                <MenuItem
                  onClick={() => {
                    handleNovoLancamento('contas-receber');
                  }}
                >
                  Contas a receber
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleNovoLancamento('contas-pagar');
                  }}
                >
                  Contas a pagar
                </MenuItem>
              </MenuList>
            </>
          )}
        </Menu>
      </Stack>

      <SimpleCard
        p="6"
        pl="3"
        boxShadow="0px 0px 6px #00000034"
        mt="6"
        overflow="auto"
      >
        <Box w="full" minW="700px">
          <Flex ml="3" alignItems="center" justifyContent="space-between">
            <Flex alignItems="center">
              <Skeleton isLoaded={!!extrato}>
                <Avatar
                  src={extrato?.iconeContaFinanceira}
                  borderRadius="full"
                  boxSize="12"
                />
              </Skeleton>

              <Skeleton isLoaded={!!extrato}>
                <Text fontSize="xl" fontWeight="bold" ml="2.5">
                  {extrato?.nomeContaFinanceira}
                </Text>
              </Skeleton>
            </Flex>

            <HStack spacing="16">
              <Flex alignItems="center">
                <Text fontSize="sm" color="gray.500" mr="1.5">
                  Total conciliado:
                </Text>

                <TextValor
                  valor={extrato?.totalConciliado || 0}
                  casasDecimais={2}
                  fontSize="sm"
                  fontWeight="regular"
                  color="orange.500"
                />
              </Flex>
            </HStack>
          </Flex>

          <Divider orientation="horizontal" ml="3" my="6" />

          <Box h="634px">
            {!isLoading && (extrato?.periodos || []).length === 0 && (
              <Text ml="3" fontSize="md">
                Não foram encontrados lançamentos no período filtrado.
              </Text>
            )}
            {extrato && extrato.periodos.length > 0 && (
              <AutoSizer>
                {({ width, height: autoSizerHeight }) => (
                  <List
                    width={width}
                    height={autoSizerHeight}
                    rowCount={extrato.periodos.length}
                    rowHeight={({ index }) => {
                      const periodo = extrato.periodos[index];

                      let alturaPeriodo = 0; // em pixels

                      if (index === 0) {
                        alturaPeriodo += 37; // Título do primeiro período (dd MMMM/yyyy)
                        alturaPeriodo += 58; // Linha de saldo anterior contido no primeiro periodo
                      } else {
                        alturaPeriodo += 53; // Outros títulos de periodo (dd MMMM/yyyy)
                      }

                      const alturaLancamentos =
                        periodo.lancamentos.length * 108; // Linha de lancamento

                      alturaPeriodo += alturaLancamentos;
                      return alturaPeriodo;
                    }}
                    rowRenderer={({ index, key, style }) => {
                      const periodo = extrato.periodos[index];

                      const isFirst = index === 0;

                      const hasLancamentos = periodo.lancamentos;

                      return (
                        <Box key={key} style={style} ml="3">
                          <Text
                            fontSize="sm"
                            fontWeight="bold"
                            color="primary.50"
                            mb="4"
                            mt={isFirst ? '0' : '4'}
                          >
                            {capitalize(
                              formatDate(
                                new Date(periodo.dataCompensacao),
                                'dd MMMM/yyyy',
                                {
                                  locale: ptBR,
                                }
                              )
                            )}
                          </Text>

                          <Box borderLeft="1px" borderColor="gray.100">
                            {isFirst && (
                              <Flex
                                alignItems="center"
                                justifyContent="space-between"
                                p="6"
                                pt="3"
                                borderBottom={hasLancamentos ? '1px' : 'none'}
                                borderColor="gray.100"
                              >
                                <Text fontSize="sm">Saldo anterior</Text>

                                <TextValor
                                  valor={extrato?.saldoAnterior || 0}
                                  casasDecimais={2}
                                  fontSize="sm"
                                  fontWeight="regular"
                                  color="gray.700"
                                  mr="55px"
                                />
                              </Flex>
                            )}

                            {hasLancamentos &&
                              periodo.lancamentos.map(
                                (lancamento, lancamentoIndex) => {
                                  const isLast =
                                    periodo.lancamentos.length - 1 ===
                                    lancamentoIndex;

                                  return (
                                    <ItemLancamentoExtrato
                                      key={
                                        lancamento.movimentacaoFinanceiraBaixaId
                                      }
                                      lancamento={lancamento}
                                      isLast={isLast}
                                      casasDecimais={{
                                        casasDecimaisQuantidade:
                                          casasDecimais.casasDecimaisQuantidade,
                                        casasDecimaisValor: 2,
                                      }}
                                      toggleLancamentoConciliado={() => {
                                        toggleLancamentoConciliado(
                                          index,
                                          lancamentoIndex
                                        );
                                      }}
                                      toggleLancamentoVisualmenteConciliado={() => {
                                        toggleLancamentoVisualmenteConciliado(
                                          index,
                                          lancamentoIndex
                                        );
                                      }}
                                      recarregarListagem={obterExtrato}
                                    />
                                  );
                                }
                              )}
                          </Box>
                        </Box>
                      );
                    }}
                  />
                )}
              </AutoSizer>
            )}
          </Box>
        </Box>
      </SimpleCard>
    </FormProvider>
  );
};
