import {
  ModalBody,
  ModalContent,
  useDisclosure,
  useMediaQuery,
  ModalProps,
  Flex,
  Text,
  Button,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';

type PromiseModal = {
  success?: boolean;
  cancel?: boolean;
  gerarVenda?: boolean;
};

interface ModalCompartilharBoletoProps
  extends InstanceProps<PromiseModal>,
    Omit<ModalProps, 'children' | 'isOpen' | 'onClose'> {
  children: React.ReactNode;
  idOperacao: string;
}

export const ModalCompartilharBoletoZoop = create<ModalCompartilharBoletoProps>(
  ({ onResolve, onReject, children, idOperacao, ...rest }) => {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    const backgroundModal = `linear-gradient(180deg, rgba(85,2,178,1) ${
      isLargerThan900 ? '42.5%' : '66.0%'
    }, rgba(232,232,232,1) 40%)`;

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? '5xl' : 'full'}
        {...rest}
        closeOnOverlayClick={false}
        isOpen={isOpen}
        closeOnEsc={false}
        onClose={onClose}
        autoFocus
      >
        <ModalContent
          bg="gray.100"
          borderRadius={isLargerThan900 ? '5px' : '0'}
          w="900px"
        >
          <ModalBody p="0px">
            <Flex
              w="full"
              height={['full', '500px']}
              flexDir="column"
              alignItems="center"
              px="64px"
              pt="64px"
              borderRadius={['0px', '5px']}
              gap={['unset', '42px']}
              bg={backgroundModal}
            >
              <Text fontSize="40px" color="secondary.300">
                Boleto gerado com sucesso!
              </Text>
              {children}
            </Flex>
            <Flex w="full" justify="center" pb="42px">
              <Button
                variant="outlineDefault"
                size="sm"
                maxW={{ base: '120px', sm: '100px' }}
                minW="75px"
                onClick={onClose}
              >
                Fechar
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
