import React from 'react';

import ConstanteFuncionalidades from 'constants/permissoes';
import ConstanteRotas from '../constants/rotas';

import { CatalogoProdutos } from '../pages/Relatorio/CatalogoProdutos';

import LayoutGuard from './LayoutGuard';

export const RelatorioCatalogoProdutosRoutes = [
  <LayoutGuard
    key="relatorio-catalogo-produtos"
    component={CatalogoProdutos}
    permissaoFuncionalidade={
      ConstanteFuncionalidades.RELATORIO_CATALOGO_PRODUTOS
    }
    breadcrumb={[
      {
        title: 'Relatórios',
      },
      {
        title: 'Catálogo de produtos',
      },
    ]}
    path={ConstanteRotas.RELATORIO_CATALOGO_PRODUTOS}
    exact
    meta={{ auth: true }}
  />,
];
