import React from 'react';
import { useFormContext } from 'react-hook-form';
import { GridItem, Box, Flex, Text, Tooltip } from '@chakra-ui/react';

import TipoCampoPersonalizadoEnum from 'constants/enum/tipoCampoPersonalizado';
import CampoPersonalizadoInterface from 'types/campoPersonalizado';

import { Switch } from 'components/update/Switch';
import { Input } from 'components/update/Input/Input';
import { NumberInput } from 'components/update/Input/NumberInput';
import { InfoIcon } from 'icons';

import { TextArea } from '../TextArea';
import { SimpleGridForm } from '../Form/SimpleGridForm';

interface CamposPersonalizadosProps {
  camposPersonalizados: CampoPersonalizadoInterface[];
  readonly?: boolean;
  labelOfNull?: boolean;
  borderColor?: string;
}

export const CamposPersonalizados = ({
  camposPersonalizados,
  readonly,
  labelOfNull,
  borderColor = 'gray.100',
}: CamposPersonalizadosProps) => {
  const { setValue } = useFormContext();

  return camposPersonalizados.length > 0 ? (
    <SimpleGridForm w="full">
      {camposPersonalizados
        .filter(
          (campoPersonalizado) =>
            campoPersonalizado.tipo !== TipoCampoPersonalizadoEnum.CHECKBOX
        )
        .map((campoPersonalizado) => {
          return (
            <GridItem colSpan={12} key={campoPersonalizado.id}>
              {campoPersonalizado.tipo === TipoCampoPersonalizadoEnum.TEXTO && (
                <Input
                  type="text"
                  placeholder={campoPersonalizado.placeHolder}
                  label={campoPersonalizado.nome}
                  maxLength={campoPersonalizado.tamanho}
                  helperText={campoPersonalizado.descricaoInstrucao}
                  campoPersonalizado={campoPersonalizado}
                  id={`camposPersonalizados[${camposPersonalizados.indexOf(
                    campoPersonalizado
                  )}]`}
                  name={`camposPersonalizados[${camposPersonalizados.indexOf(
                    campoPersonalizado
                  )}]`}
                  isDisabled={readonly}
                />
              )}
              {campoPersonalizado.tipo ===
                TipoCampoPersonalizadoEnum.CAIXATEXTO && (
                <TextArea
                  placeholder={campoPersonalizado.placeHolder}
                  label={campoPersonalizado.nome}
                  maxLength={campoPersonalizado.tamanho}
                  helperText={campoPersonalizado.descricaoInstrucao}
                  campoPersonalizado={campoPersonalizado}
                  name={`camposPersonalizados[${camposPersonalizados.indexOf(
                    campoPersonalizado
                  )}]`}
                  isDisabled={readonly}
                />
              )}
              {campoPersonalizado.tipo ===
                TipoCampoPersonalizadoEnum.NUMERAL && (
                <NumberInput
                  id={`camposPersonalizados[${camposPersonalizados.indexOf(
                    campoPersonalizado
                  )}]`}
                  name={`camposPersonalizados[${camposPersonalizados.indexOf(
                    campoPersonalizado
                  )}]`}
                  scale={campoPersonalizado.casaDecimal}
                  maxLength={campoPersonalizado.tamanho}
                  helperText={campoPersonalizado.descricaoInstrucao}
                  label={campoPersonalizado.nome}
                  placeholder={campoPersonalizado.placeHolder}
                  onValueChange={(valueAsNumber) => {
                    setValue(
                      `camposPersonalizados[${camposPersonalizados.indexOf(
                        campoPersonalizado
                      )}]`,
                      {
                        campoPersonalizadoId: campoPersonalizado.id,
                        valor: valueAsNumber,
                      }
                    );
                  }}
                  isDisabled={readonly}
                  campoPersonalizado
                />
              )}
              {campoPersonalizado.tipo ===
                TipoCampoPersonalizadoEnum.PORCENTAGEM && (
                <NumberInput
                  id={`camposPersonalizados[${camposPersonalizados.indexOf(
                    campoPersonalizado
                  )}]`}
                  name={`camposPersonalizados[${camposPersonalizados.indexOf(
                    campoPersonalizado
                  )}]`}
                  onValueChange={(valueAsNumber) => {
                    setValue(
                      `camposPersonalizados[${camposPersonalizados.indexOf(
                        campoPersonalizado
                      )}]`,
                      {
                        campoPersonalizadoId: campoPersonalizado.id,
                        valor: valueAsNumber,
                      }
                    );
                  }}
                  leftElement="%"
                  editarFundoLeftElemento
                  bgLeftElement="gray.50"
                  scale={campoPersonalizado.casaDecimal}
                  maxLength={campoPersonalizado.tamanho}
                  helperText={campoPersonalizado.descricaoInstrucao}
                  label={campoPersonalizado.nome}
                  placeholder={campoPersonalizado.placeHolder}
                  isDisabled={readonly}
                  campoPersonalizado
                />
              )}
              {campoPersonalizado.tipo === TipoCampoPersonalizadoEnum.MOEDA && (
                <NumberInput
                  id={`camposPersonalizados[${camposPersonalizados.indexOf(
                    campoPersonalizado
                  )}]`}
                  name={`camposPersonalizados[${camposPersonalizados.indexOf(
                    campoPersonalizado
                  )}]`}
                  onValueChange={(valueAsNumber) => {
                    setValue(
                      `camposPersonalizados[${camposPersonalizados.indexOf(
                        campoPersonalizado
                      )}]`,
                      {
                        campoPersonalizadoId: campoPersonalizado.id,
                        valor: valueAsNumber,
                      }
                    );
                  }}
                  leftElement="R$"
                  editarFundoLeftElemento
                  bgLeftElement="gray.50"
                  scale={campoPersonalizado.casaDecimal}
                  maxLength={campoPersonalizado.tamanho}
                  helperText={campoPersonalizado.descricaoInstrucao}
                  label={campoPersonalizado.nome}
                  placeholder={campoPersonalizado.placeHolder}
                  campoPersonalizado
                  isDisabled={readonly}
                />
              )}
              {campoPersonalizado.tipo === TipoCampoPersonalizadoEnum.DATA && (
                <Input
                  type="date"
                  placeholder={campoPersonalizado.placeHolder}
                  label={campoPersonalizado.nome}
                  maxLength={campoPersonalizado.tamanho}
                  helperText={campoPersonalizado.descricaoInstrucao}
                  campoPersonalizado={campoPersonalizado}
                  id={`camposPersonalizados[${camposPersonalizados.indexOf(
                    campoPersonalizado
                  )}]`}
                  name={`camposPersonalizados[${camposPersonalizados.indexOf(
                    campoPersonalizado
                  )}]`}
                  isDisabled={readonly}
                />
              )}
            </GridItem>
          );
        })}
      {camposPersonalizados.filter(
        (campoPersonalizado) =>
          campoPersonalizado.tipo === TipoCampoPersonalizadoEnum.CHECKBOX
      ).length > 0 && (
        <GridItem colSpan={12}>
          <Box mb="3px">Opções</Box>
          <Box
            px="15px"
            py="10px"
            borderRadius="md"
            borderWidth="1px"
            borderStyle="solid"
            borderColor={borderColor}
          >
            {camposPersonalizados
              .filter(
                (campoPersonalizado) =>
                  campoPersonalizado.tipo ===
                  TipoCampoPersonalizadoEnum.CHECKBOX
              )
              .map((campoPersonalizado, index) => (
                <Flex
                  w="full"
                  pt="10px"
                  justifyContent="space-between"
                  key={campoPersonalizado.id}
                  borderBottomWidth={index > 0 ? '1px' : '0'}
                  borderBottomColor={
                    index > 0 ||
                    index + 1 !==
                      camposPersonalizados?.filter(
                        (campoItem) =>
                          campoItem.tipo === TipoCampoPersonalizadoEnum.CHECKBOX
                      )?.length
                      ? 'gray.100'
                      : 'none'
                  }
                  borderBottomStyle="solid"
                >
                  <Flex justifyContent="center" alignItems="baseline">
                    <Text whiteSpace="nowrap">{campoPersonalizado.nome}</Text>
                    {campoPersonalizado.descricaoInstrucao && (
                      <Tooltip
                        hasArrow
                        placement="right"
                        label={campoPersonalizado.descricaoInstrucao}
                        fontSize="sm"
                      >
                        <Flex
                          ml="8px"
                          justifyContent="flex-end"
                          alignItems="flex-end"
                        >
                          <InfoIcon />
                        </Flex>
                      </Tooltip>
                    )}
                  </Flex>
                  <Box>
                    <Switch
                      onChange={(valueAsNumber) => {
                        setValue(
                          `camposPersonalizados[${camposPersonalizados.indexOf(
                            campoPersonalizado
                          )}]`,
                          {
                            campoPersonalizadoId: campoPersonalizado.id,
                            valor: valueAsNumber.target.checked,
                          }
                        );
                      }}
                      campoPersonalizado
                      id={`camposPersonalizados[${camposPersonalizados.indexOf(
                        campoPersonalizado
                      )}]`}
                      name={`camposPersonalizados[${camposPersonalizados.indexOf(
                        campoPersonalizado
                      )}]`}
                      helperText={campoPersonalizado.descricaoInstrucao}
                      size="md"
                      isDisabled={readonly}
                    />
                  </Box>
                </Flex>
              ))}
          </Box>
        </GridItem>
      )}
    </SimpleGridForm>
  ) : labelOfNull ? null : (
    <GridItem colSpan={12}>
      <span>Você não tem campos personalizados cadastrados.</span>
    </GridItem>
  );
};
