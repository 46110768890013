import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Grid, useBreakpointValue } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FiXCircle } from 'react-icons/fi';

import { useOperacaoContext, Cliente, Vendedor } from 'store/PDV/Operacao';
import ConstanteRotasPDV from 'constants/rotasPDV';
import LancamentoEditarItemContext from 'store/PDV/LancamentoEditarItem';
import obterOperacaoComItens from 'helpers/api/Operacao/obterOperacaoComItens';
import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';
import MenuContextProvider from 'store/PDV/Menu';
import auth from 'modules/auth';
import ConstanteFuncionalidades from 'constants/permissoes';
import { useSignalRContext } from 'store/SignalR';
import LogAuditoriaTelaEnum from 'constants/enum/logAuditoriaTela';
import { useInformacoesGeraisContext } from 'store/PDV/InformacoesGerais';

import Layout from 'components/PDV/Layout';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import CaixaAberto from 'components/PDV/Layout/Footer/CaixaAberto';
import SelecionarVendedor from 'components/PDV/Layout/Footer/SelecionarVendedor';
import Observacoes from 'components/PDV/Header/Observacoes';
import NavHeaderButton from 'components/PDV/Layout/NavHeader/Button';
import ModalConfirmacao from 'components/PDV/Modal/ModalConfirmacao';
import ModalAutorizacaoFuncionalidade from 'components/Modal/ModalAutorizacaoFuncionalidade';
import CadastroCliente from 'components/PDV/Header/CadastroCliente';

import AdicionarEditarItem from './AdicionarEditarItem';
import LancamentoInternalHeader from './InternalHeader';
import LancamentoFooter from './Footer';
import ListarItens from './ListarItens';

type SParams = {
  cliente?: Cliente;
  vendedor?: Vendedor;
  chavePermissaoTemporaria?: string;
  identificacaoTipoOperacao?: number;
  tabelaPreco?: { id: string; nome: string };
};

const Lancamento = ({
  location: { state },
}: RouteComponentProps<any, any, SParams>) => {
  const history = useHistory();

  const { hubConnection, joinGroup } = useSignalRContext();

  const isNavHeaderVisible = useBreakpointValue({ base: false, md: true });

  const { setValue, getValues } = useFormContext();

  const {
    setDetalhesTroca,
    setVendedorPdv,
    tabelaPrecoId,
    setTabelaPrecoId,
    detalhesTroca,
  } = useInformacoesGeraisContext();

  const {
    operacaoIsLoading,
    setOperacaoIsLoading,
    operacaoId,
    handleChangeVendedor,
    handleCancelarOperacao,
    handleChangeCliente,
    handleChangeLocalEstoque,
  } = useOperacaoContext();

  const {
    obterLocalEstoquePedidoAberto,
    localDeEstoqueSelecionado,
  } = useInformacoesGeraisContext();

  const {
    cliente: clienteDefaultValue = undefined,
    vendedor: vendedorDefaultValue = undefined,
    chavePermissaoTemporaria: chavePermissaoTemporariaDefaultvalue = undefined,
    identificacaoTipoOperacao: identificacaoTipoOperacaoValue = undefined,
    tabelaPreco: tabelaPrecoValue = undefined,
  } = state || {};

  const [
    modalAutorizacaoCancelarIsOpen,
    setModalAutorizacaoCancelarIsOpen,
  ] = useState(false);
  const [
    modalConfirmacaoCancelarIsOpen,
    setModalConfirmacaoCancelarIsOpen,
  ] = useState(false);

  const permissaoCancelarOperacao = useMemo(
    () => auth.possuiPermissao(ConstanteFuncionalidades.PDV_CANCELAR.codigo),
    []
  );

  const handleConfirmarPermissaoCancelar = (
    chavePermissaoTemporaria: string
  ) => {
    handleCancelarOperacao(hubConnection, joinGroup, chavePermissaoTemporaria);
  };

  const cancelarOperacao = () => {
    if (permissaoCancelarOperacao.permitido) {
      handleCancelarOperacao(hubConnection, joinGroup);
    } else {
      setModalAutorizacaoCancelarIsOpen(true);
    }
  };

  const handleCancelar = () => {
    if (operacaoId || detalhesTroca.idOperacao) {
      setModalConfirmacaoCancelarIsOpen(true);
    } else {
      history.push(ConstanteRotasPDV.PDV_HOME);
    }
  };

  const latestProps = useRef({
    history,
    operacaoId,
    setOperacaoIsLoading,
    setValue,
    clienteDefaultValue,
    getValues,
    identificacaoTipoOperacaoValue,
    state,
    vendedorDefaultValue,
    handleChangeCliente,
    isNavHeaderVisible,
  });

  useEffect(() => {
    setValue('tabelaPreco', tabelaPrecoValue);
  }, [setValue, tabelaPrecoValue]);

  useEffect(() => {
    latestProps.current = {
      history,
      operacaoId,
      setOperacaoIsLoading,
      setValue,
      clienteDefaultValue,
      getValues,
      identificacaoTipoOperacaoValue,
      state,
      vendedorDefaultValue,
      handleChangeCliente,
      isNavHeaderVisible,
    };
  });

  useEffect(() => {
    const handleObterOperacao = async () => {
      if (latestProps.current.operacaoId) {
        latestProps.current.setOperacaoIsLoading(true);

        const operacao = await obterOperacaoComItens(
          latestProps.current.operacaoId
        );

        if (
          operacao &&
          (operacao.identificacaoTipoOperacao ===
            IdentificacaoTipoOperacaoEnum.PEDIDO ||
            operacao.identificacaoTipoOperacao ===
              IdentificacaoTipoOperacaoEnum.ORCAMENTO ||
            operacao.identificacaoTipoOperacao ===
              IdentificacaoTipoOperacaoEnum.CONSIGNACAO)
        ) {
          setDetalhesTroca((detalhesAdicionados) => ({
            ...detalhesAdicionados,
            valorTotalTroca: operacao.saldoVoucher,
          }));

          setTabelaPrecoId(
            operacao.tabelaPrecoOpcaoSelect
              ? operacao.tabelaPrecoOpcaoSelect.id
              : ''
          );

          setVendedorPdv(operacao.vendedorOpcaoSelect);
          latestProps.current.setValue(
            'vendedor',
            operacao.vendedorOpcaoSelect
          );
          latestProps.current.setValue(
            'cliente',
            operacao.clienteFornecedorOpcaoSelect
          );
          latestProps.current.setValue('observacao', operacao.observacao);

          latestProps.current.setValue('operacaoItens', operacao.operacaoItens);
          latestProps.current.setValue(
            'numeroOperacao',
            operacao.numeroOperacao
          );
          latestProps.current.setValue(
            'possuiMovimentacaoFinanceira',
            operacao.possuiMovimentacaoFinanceira
          );
          latestProps.current.setValue(
            'identificacaoTipoOperacao',
            operacao.identificacaoTipoOperacao
          );
          latestProps.current.setValue(
            'tabelaPreco',
            operacao.tabelaPrecoOpcaoSelect
              ? {
                  value: operacao.tabelaPrecoOpcaoSelect.id,
                  name: operacao.tabelaPrecoOpcaoSelect.nome,
                }
              : null
          );

          if (operacao.localEstoqueId !== localDeEstoqueSelecionado.id) {
            obterLocalEstoquePedidoAberto(operacao.localEstoqueId);
          }

          if (operacao.mensagemItensKitAtualizados) {
            toast.info(operacao.mensagemItensKitAtualizados);
          }
        } else {
          latestProps.current.history.push(ConstanteRotasPDV.PDV_HOME);
        }

        latestProps.current.setOperacaoIsLoading(false);
      }
    };

    handleObterOperacao();
  }, [
    setDetalhesTroca,
    tabelaPrecoId,
    setVendedorPdv,
    setTabelaPrecoId,
    localDeEstoqueSelecionado,
    obterLocalEstoquePedidoAberto,
    handleChangeLocalEstoque,
  ]);

  useEffect(() => {
    const handleSetVendedor = (vendedor: Vendedor) => {
      latestProps.current.setValue('vendedor', vendedor);

      latestProps.current.history.replace({
        ...latestProps.current.history.location,
        state: { ...latestProps.current.state, vendedor: undefined },
      });
    };

    if (
      !latestProps.current.operacaoId &&
      latestProps.current.clienteDefaultValue &&
      latestProps.current.vendedorDefaultValue
    ) {
      latestProps.current.setValue(
        'identificacaoTipoOperacao',
        latestProps.current.identificacaoTipoOperacaoValue
      );

      const cliente = latestProps.current.getValues('cliente');
      const vendedor = latestProps.current.getValues('vendedor');

      if (!cliente && latestProps.current.clienteDefaultValue) {
        latestProps.current.setValue(
          'cliente',
          latestProps.current.clienteDefaultValue
        );

        if (!latestProps.current.isNavHeaderVisible)
          latestProps.current.handleChangeCliente(
            latestProps.current.clienteDefaultValue
          );

        latestProps.current.history.replace({
          ...latestProps.current.history.location,
          state: { ...latestProps.current.state, cliente: undefined },
        });
      }

      if (!vendedor && latestProps.current.vendedorDefaultValue) {
        handleSetVendedor(latestProps.current.vendedorDefaultValue);
      }
    } else if (!latestProps.current.operacaoId) {
      toast.warning(
        'Você precisa selecionar um cliente e um vendedor para gerar uma nova operação.'
      );
      latestProps.current.history.push(ConstanteRotasPDV.PDV_HOME);
    }
  }, []);

  useEffect(() => {
    latestProps.current.setValue('cliente', clienteDefaultValue);
  }, [clienteDefaultValue]);

  return (
    <MenuContextProvider
      asAccordion={!isNavHeaderVisible}
      items={
        isNavHeaderVisible
          ? undefined
          : [
              <CadastroCliente />,
              <Observacoes asNavHeaderButton />,
              <CaixaAberto asNavHeaderButton />,
              <SelecionarVendedor
                name="vendedor"
                asNavHeaderButton
                onSubmit={(newVendedor) => {
                  handleChangeVendedor(newVendedor);
                }}
              />,
              <NavHeaderButton
                icon={FiXCircle}
                title="Cancelar"
                onClick={handleCancelar}
              />,
            ]
      }
    >
      <Layout
        NavHeaderContent={
          isNavHeaderVisible ? <LancamentoInternalHeader /> : null
        }
        FooterContent={<LancamentoFooter handleCancelar={handleCancelar} />}
      >
        {operacaoIsLoading && <LoadingPadrao />}
        <LancamentoEditarItemContext>
          <Grid
            h="full"
            templateColumns={{ base: '1fr', md: '1fr 1fr', lg: '9fr 12fr' }}
            display={{ base: 'unset', md: 'grid' }}
          >
            <AdicionarEditarItem
              chavePermissaoTemporaria={chavePermissaoTemporariaDefaultvalue}
            />
            <ListarItens />
          </Grid>
        </LancamentoEditarItemContext>

        {modalAutorizacaoCancelarIsOpen && (
          <ModalAutorizacaoFuncionalidade
            isOpen={modalAutorizacaoCancelarIsOpen}
            setIsOpen={setModalAutorizacaoCancelarIsOpen}
            autorizado={handleConfirmarPermissaoCancelar}
            titulo={ConstanteFuncionalidades.PDV_CANCELAR.titulo}
            descricao={ConstanteFuncionalidades.PDV_CANCELAR.descricao}
            tela={LogAuditoriaTelaEnum.PDV}
            permissoes={[ConstanteFuncionalidades.PDV_CANCELAR.codigo]}
          />
        )}
        {modalConfirmacaoCancelarIsOpen && (
          <ModalConfirmacao
            isOpen={modalConfirmacaoCancelarIsOpen}
            setIsOpen={setModalConfirmacaoCancelarIsOpen}
            onConfirm={cancelarOperacao}
            textoCabecalho="Deseja cancelar esta operação?"
            textoMensagem={
              detalhesTroca.idOperacao !== ''
                ? 'Somente a troca será cancelada, a devolução continuará no sistema. Deseja continuar?'
                : 'Se continuar cancelará toda a operação, e esta ação não poderá ser desfeita. Deseja continuar?'
            }
            textoCancelar="Não, voltar!"
          />
        )}
      </Layout>
    </MenuContextProvider>
  );
};

export default Lancamento;
