import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Box,
  Stack,
  ModalHeader,
  ModalFooter,
  GridItem,
  Grid,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';

import { NumberInput } from 'components/update/Input/NumberInput';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

export type ModalImpressaoEtiquetaProps = {
  logo: string;
  nomeFantasia: string;
  descricao: string;
  cor: string;
  tamanho: string;
  categoria: string;
  marca: string;
  unidadeMedida: string;
  gtinEan: string;
  codigoBarras: string;
  referencia: string;
  preco: number;
  valorEtiqueta: string;
  qrCode: string;
  textoFixo: string;
  quantidade: number;
  linhaInicial: number;
  colunaInicial: number;
};

type ListarSelect = {
  id: string;
  nome: string;
  ativo: boolean;
  configuracoes: string;
};

const formDefaultValues = {
  linhaInicial: 1,
  colunaInicial: 1,
};

type ModalImpressaoEtiquetaPersonalizadaProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalImpressaoEtiquetaProps> & {
    operacaoId: string;
  };

export const ModalImpressaoEtiquetaPersonalizada = create<
  ModalImpressaoEtiquetaPersonalizadaProps,
  ModalImpressaoEtiquetaProps
>(({ onResolve, onReject, operacaoId, ...rest }) => {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const [isLoading, setIsLoading] = useState(false);

  const [listarSelectEtiquetas, setListarSelectEtiquetas] = useState<
    ListarSelect[]
  >([]);

  const formMethods = useForm<ModalImpressaoEtiquetaProps>({
    defaultValues: formDefaultValues,
  });

  const [valorEtiqueta, linhaInicial, colunaInicial] = formMethods.watch([
    'valorEtiqueta',
    'linhaInicial',
    'colunaInicial',
  ]);

  const filtrandoEtiqueta = () => {
    if (listarSelectEtiquetas.length > 0) {
      const filtrandoValorEtiqueta = listarSelectEtiquetas.filter(
        (etiquetaFoiSelecionada) => etiquetaFoiSelecionada.id === valorEtiqueta
      );

      if (filtrandoValorEtiqueta[0]) {
        if (filtrandoValorEtiqueta[0].nome) {
          return (filtrandoValorEtiqueta[0].nome || '').includes('Laser');
        }
        return false;
      }

      return false;
    }
    return false;
  };

  const etiquetaSelecionadaTipoLaser = filtrandoEtiqueta();

  useEffect(() => {
    async function ObterListaEtiquetasSelect() {
      const response = await api.get<void, ResponseApi<ListarSelect[]>>(
        ConstanteEnderecoWebservice.ETIQUETA_PERSONALIZADA_LISTAR_SELECT
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response.sucesso && response.dados) {
          setListarSelectEtiquetas(response.dados);
        }
      }

      return [];
    }
    ObterListaEtiquetasSelect();
  }, [valorEtiqueta]);

  async function obterListagemImpressao() {
    setIsLoading(true);
    const response = await api.post<
      void,
      ResponseApi<ModalImpressaoEtiquetaProps[]>
    >(ConstanteEnderecoWebservice.ETIQUETA_PERSONALIZADA_IMPRIMIR, {
      OperacaoId: operacaoId,
      etiquetaId: valorEtiqueta,
      linhaInicio: etiquetaSelecionadaTipoLaser ? linhaInicial : 1,
      colunaInicio: etiquetaSelecionadaTipoLaser ? colunaInicial : 1,
    });

    if (response) {
      if (response.avisos) {
        setIsLoading(false);
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }
      if (response.sucesso) {
        setIsLoading(false);
        toast.success('Solicitação de etiqueta enviada com sucesso');
        onClose();
      }
    }

    setIsLoading(false);

    return [];
  }

  return (
    <ModalPadraoChakra
      isCentered
      size="3xl"
      {...rest}
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={false}
    >
      <ModalContent
        bg="gray.50"
        marginBottom={{ base: 0, sm: '3.75rem' }}
        marginTop={{ base: 0, sm: '3.75rem' }}
        maxWidth={{ base: '650px', lg: '560px' }}
        w={{ base: '700px', lg: '100%' }}
      >
        <ModalHeader>
          <Box>
            <Text fontSize="sm" fontWeight="bold" color="primary.50">
              Imprimir etiquetas
            </Text>
          </Box>
        </ModalHeader>

        <FormProvider {...formMethods}>
          <Stack h="90px" w="full">
            <form>
              <ModalBody pb="4" pt="2">
                {isLoading && <LoadingPadrao />}
                <Grid
                  w="full"
                  templateColumns={`repeat(2, ${
                    etiquetaSelecionadaTipoLaser ? 50 : 100
                  }% ${20}% ${22}%)`}
                  gap={6}
                >
                  <SelectPadrao
                    id="valorEtiqueta"
                    name="valorEtiqueta"
                    handleChange={() => {
                      formMethods.setValue('linhaInicial', 1);
                      formMethods.setValue('colunaInicial', 1);
                    }}
                    label="Selecione a etiqueta"
                    options={(listarSelectEtiquetas || []).map(
                      ({ nome, id }) => {
                        return (
                          {
                            label: nome,
                            value: id,
                          } || []
                        );
                      }
                    )}
                  />

                  {etiquetaSelecionadaTipoLaser && (
                    <>
                      <NumberInput
                        id="linhaInicial"
                        name="linhaInicial"
                        label="Linha inicial"
                        helperText="Informe qual linha vai começar a impressão"
                        scale={0}
                        disabled={!valorEtiqueta}
                        colorScheme="secondary.300"
                        color="black"
                      />
                      <NumberInput
                        id="colunaInicial"
                        name="colunaInicial"
                        label="Coluna inicial"
                        helperText="Informe qual Coluna vai começar a impressão"
                        scale={0}
                        disabled={!valorEtiqueta}
                        min={1}
                        colorScheme="secondary.300"
                        color="black"
                      />
                    </>
                  )}
                </Grid>
              </ModalBody>
            </form>
          </Stack>

          <ModalFooter>
            <GridItem
              w="full"
              display="flex"
              justifyContent={['flex-start', 'flex-end']}
              colSpan={{ base: 12, lg: 12 }}
            >
              <Button
                id="cancelar"
                name="cancelar"
                colorScheme="gray.100"
                variant="outline"
                borderRadius="20px"
                fontSize="sm"
                type="button"
                w="96px"
                h="32px"
                mr={6}
                _hover={{ bg: 'gray.50' }}
                onClick={onClose}
              >
                Cancelar
              </Button>

              <Button
                id="pesquisar"
                name="pesquisar"
                w="110px"
                h="32px"
                backgroundColor="aquamarine.600"
                borderRadius="20px"
                fontSize="sm"
                color="white"
                variant="success"
                onClick={() => obterListagemImpressao()}
                disabled={!valorEtiqueta && !isLoading}
                type="button"
                _hover={{ bg: 'aquamarine.700' }}
              >
                Imprimir
              </Button>
            </GridItem>
          </ModalFooter>
        </FormProvider>
      </ModalContent>
    </ModalPadraoChakra>
  );
});
