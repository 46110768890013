import React from 'react';
import { NamedProps } from 'react-select';
import CreatableReactSelect from 'react-select/creatable';
import { Flex, useTheme, Text } from '@chakra-ui/react';

import OptionType from 'types/optionType';

import { SalvarInserirNovoIcon } from 'icons';
import SelectHighlighter from 'components/PDV/SelectHighlighter';

import {
  ChakraReactSelectContainer,
  chakraComponents,
  chakraStyles,
} from '../../ReactSelectIntegracao';

export type CreatableSelectInputProps = NamedProps<OptionType> & {
  onCreateOption: (inputValue: string) => Promise<void>;
  isDisabled?: boolean;
  variant?: string;
  size?: string;
  creatableInputTextPreffix: string;
  components?: any;
  colorSelectedValue?: string;
  creatableButtonShow?: boolean;
  selectRef?: any;
  multiValueBg?: string;
  multiValueColor?: string;
};

const CreatableSelectInput = ({
  onCreateOption,
  id,
  isClearable = false,
  placeholder = '',
  noOptionsMessage = (obj) =>
    obj.inputValue !== '' ? 'Não há valores disponíveis' : null,
  loadingMessage = () => 'Carregando...',
  variant,
  size,
  creatableInputTextPreffix,
  components,
  autoFocus,
  colorSelectedValue,
  creatableButtonShow = true,
  selectRef,
  isDisabled,
  multiValueBg,
  multiValueColor,
  isMulti,
  styles,
  ...props
}: CreatableSelectInputProps) => {
  const { colors, radii } = useTheme();

  const blue600 = colors.blue[600];
  const placeholderColor = colors.gray[400];

  return (
    <ChakraReactSelectContainer
      variant={variant}
      size={size}
      isDisabled={isDisabled}
      isMulti={isMulti}
    >
      <CreatableReactSelect
        isDisabled={isDisabled}
        createOptionPosition="first"
        placeholder={placeholder}
        isClearable={isClearable}
        noOptionsMessage={noOptionsMessage}
        loadingMessage={loadingMessage}
        className="react-select-container"
        classNamePrefix="react-select"
        inputId={id}
        id={`select-container-${id}`}
        {...props}
        autoFocus={autoFocus}
        isMulti={isMulti}
        menuPlacement="auto"
        components={components || chakraComponents}
        onCreateOption={onCreateOption}
        styles={
          styles
            ? {
                ...chakraStyles(
                  colorSelectedValue,
                  multiValueBg,
                  multiValueColor
                ),
                ...styles,
              }
            : chakraStyles(colorSelectedValue, multiValueBg, multiValueColor)
        }
        theme={(baseTheme) => ({
          ...baseTheme,
          borderRadius: radii.md,
          colors: {
            ...baseTheme.colors,
            neutral50: placeholderColor, // placeholder text color
            neutral40: placeholderColor, // noOptionsMessage color
          },
          spacing: {
            ...baseTheme.spacing,
          },
        })}
        formatOptionLabel={SelectHighlighter}
        formatCreateLabel={(userInput: string) =>
          creatableButtonShow ? (
            <Flex align="center" gap="6px">
              <SalvarInserirNovoIcon
                style={{
                  color: `${blue600}`,
                  marginBottom: '2px',
                  strokeWidth: '2.5px !important',
                }}
              />
              <Text color={blue600} fontSize="14px" fontStyle="italic">
                {creatableInputTextPreffix} &quot;
                <strong
                  style={{
                    color: `${blue600}`,
                    fontStyle: 'normal',
                  }}
                >
                  {userInput}
                </strong>
                &quot;
              </Text>
            </Flex>
          ) : undefined
        }
        isValidNewOption={(option) => option !== '' && creatableButtonShow}
        sele
        ref={selectRef}
      />
    </ChakraReactSelectContainer>
  );
};

export default CreatableSelectInput;
