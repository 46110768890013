const BandeirasCartaoEnum = {
  Visa: 1,
  Mastercard: 2,
  AmericanExpress: 3,
  Sorocred: 4,
  DinersClub: 5,
  Elo: 6,
  Hipercard: 7,
  Aura: 8,
  Cabal: 9,
  Outros: 99,

  properties: {
    1: { name: 'American Express', value: 3 },
    2: { name: 'Aura', value: 8 },
    3: { name: 'Cabal', value: 9 },
    4: { name: 'Diners Club', value: 5 },
    5: { name: 'Elo', value: 6 },
    6: { name: 'Hipercard', value: 7 },
    7: { name: 'Mastercard', value: 2 },
    8: { name: 'Outros', value: 99 },
    9: { name: 'Sorocred', value: 4 },
    10: { name: 'Visa', value: 1 },
  },
};

export default BandeirasCartaoEnum;
