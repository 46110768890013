import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { moneyMask } from 'helpers/format/fieldsMasks';

interface Produtos {
  produto: Array<Itens>;
}

interface Itens {
  numeroItem: string;
  nomeProduto: string;
  codigoBarrasInterno: string;
  quantidade: number;
  unidadeMedida: string;
  valorDesconto: number;
  valorItemComDesconto: number;
  valorTotalItem: number;
  valorUnitario: number;
}

const Produtos = ({ produto }: Produtos) => {
  return (
    <Flex
      direction="column"
      pb={2}
      borderBottom="2px solid"
      borderColor="gray.700"
    >
      {produto.map((item) => (
        <Flex w="100%" mt={2} direction="column">
          <Flex alignItems="flex-start">
            <Text>{item.numeroItem}</Text>
            <Text ml={2}>{item.nomeProduto}</Text>
          </Flex>
          <Flex justifyContent="space-between">
            <Text ml={1.5}>
              {item.codigoBarrasInterno} {'  '} {item.quantidade}{' '}
              {item.unidadeMedida} x {moneyMask(item.valorUnitario, false)}
            </Text>
            <Text>{moneyMask(item.valorTotalItem, false)}</Text>
          </Flex>
          {item.valorDesconto > 0 && (
            <Flex justifyContent="space-between">
              <Flex w="160px" justifyContent="space-between" ml={1.5}>
                <Text>Desconto</Text>
                <Text>-{moneyMask(item.valorDesconto, false)}</Text>
              </Flex>
              <Text>{moneyMask(item.valorItemComDesconto, false)}</Text>
            </Flex>
          )}
        </Flex>
      ))}
    </Flex>
  );
};

export default Produtos;
