import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  ModalFooter,
  Box,
  ModalHeader,
  Textarea,
  useMediaQuery,
  Icon,
} from '@chakra-ui/react';
import { FiChevronLeft } from 'react-icons/fi';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { DecimalMask } from 'helpers/format/fieldsMasks';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import { ButtonDefault } from 'components/Button/ButtonChakra';
import {
  ListaTrocaProps,
  OperacaoProps,
  ProdutoSelecionadoProps,
} from 'pages/PDV/TrocarProdutos/Types/validationForm';

type ContasFinanceirasProps = {
  nome: string;
  id: string;
}[];

type FormaPagamentoIdProps = {
  value: string;
  label: string;
  contasFinanceiras: ContasFinanceirasProps;
};

type ModalDevolverDinheiroTrocaProps = {
  formaPagamento: FormaPagamentoIdProps;
  observacao?: string;
  contaFinanceiraId: string;
};

type CaixaMovimentacao = {
  id: string;
};

interface FormaPagamentoProps {
  id: string;
  nome: string;
  contasFinanceiras: ContasFinanceirasProps;
}

type ModalDevolverDinheiroProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalDevolverDinheiroTrocaProps> & {
    valorTotal: number;
    cliente: { nome: string; id: string };
    produtosTroca: ProdutoSelecionadoProps[];
    valorTotalQuantidade: number[];
    contaFinanceira?: { caixaMovimentacao: CaixaMovimentacao };
    infoComplementaresVenda?: ListaTrocaProps;
    setOperacao: React.Dispatch<React.SetStateAction<OperacaoProps>>;
  };

export const ModalDevolverDinheiro = create<
  ModalDevolverDinheiroProps,
  ModalDevolverDinheiroTrocaProps
>(
  ({
    onResolve,
    onReject,
    valorTotal,
    cliente,
    contaFinanceira,
    produtosTroca,
    setOperacao,
    infoComplementaresVenda,
    valorTotalQuantidade,
    ...rest
  }) => {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const [produtosDevolucao, setProdutosDevolucao] = useState<
      ProdutoSelecionadoProps[]
    >(produtosTroca);
    const [informacoesFormaPagamento, setInformacoesFormaPagamento] = useState<
      { value: string; label: string }[]
    >([]);
    const [isLoading, setIsLoading] = useState(false);

    const formMethods = useForm<ModalDevolverDinheiroTrocaProps>();
    const [
      formaPagamento,
      observacao,
      contaFinanceiraWatch,
    ] = formMethods.watch([
      'formaPagamento',
      'observacao',
      'contaFinanceiraId',
    ]);

    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
    const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

    const contaFinanceiraPagamento = useCallback(() => {
      return formaPagamento?.contasFinanceiras || [];
    }, [formaPagamento])();

    useEffect(() => {
      const getFormaPagamento = async () => {
        const response = await api.get<
          void,
          ResponseApi<FormaPagamentoProps[]>
        >(ConstanteEnderecoWebservice.FORMA_PAGAMENTO_LISTAR_SELECT_PAGAMENTO);

        if (response?.avisos) {
          response.avisos.map((aviso: string) => toast.warning(aviso));
        }

        if (response && response.sucesso && response.dados) {
          const data = response.dados.map((pagamento) => ({
            label: pagamento.nome,
            value: pagamento.id,
            contasFinanceiras: pagamento.contasFinanceiras,
          }));
          setInformacoesFormaPagamento(data);
          return data;
        }

        return [];
      };
      getFormaPagamento();
    }, []);

    useEffect(() => {
      setProdutosDevolucao((produtoAdicionado) =>
        produtoAdicionado.map((item, index) => {
          return {
            ...item,
            quantidade: valorTotalQuantidade ? valorTotalQuantidade[index] : 1,
          };
        })
      );
    }, [valorTotalQuantidade, produtosTroca]);

    const handleSubmit = useCallback(async () => {
      const filtros = formMethods.getValues();
      setIsLoading(true);
      const dadosEnviarApi = {
        numeroOperacaoOrigem: infoComplementaresVenda?.numeroVenda || 1,
        formaPagamentoRecebimentoId: formaPagamento?.value,
        valorDevolucao: valorTotal,
        observacao,
        clienteFornecedorId: cliente.id,
        contaFinanceiraId: filtros.contaFinanceiraId,
        devolucaoItens: produtosDevolucao.map((devolucao) => {
          const valorTotalItensDevolucao =
            devolucao.valor * devolucao.quantidade - devolucao.desconto;
          const produtosParaDevolucao = {
            produtoCorTamanhoId: devolucao.produtoCorTamanhoId,
            operacaoItemOrigemId: devolucao.operacaoItemId,
            quantidade: devolucao.quantidade,
            valorDesconto: devolucao.desconto,
            valorTotal: valorTotalItensDevolucao.toFixed(2),
            valorUnitario: devolucao.valor,
          };
          return produtosParaDevolucao;
        }),
      };
      const response = await api.post<void, ResponseApi<string>>(
        ConstanteEnderecoWebservice.TROCA_DEVOLUCAO_DEVOLVER_DINHEIRO,
        dadosEnviarApi
      );

      if (response?.avisos) {
        setIsLoading(false);
        response?.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response?.sucesso && response?.dados) {
        await setOperacao({
          id: response.dados,
          type: 'devolucaoDeDinheiro',
        });
        setIsLoading(false);
        onResolve();
      }
      setIsLoading(false);
    }, [
      formMethods,
      infoComplementaresVenda,
      formaPagamento,
      valorTotal,
      observacao,
      cliente,
      produtosDevolucao,
      setOperacao,
      onResolve,
    ]);

    const latestProps = useRef({ setValue: formMethods.setValue });

    useEffect(() => {
      latestProps.current = { setValue: formMethods.setValue };
    });

    useEffect(() => {
      if (
        contaFinanceiraPagamento.length === 1 &&
        contaFinanceiraPagamento &&
        contaFinanceiraPagamento[0].id
      ) {
        latestProps.current.setValue(
          'contaFinanceiraId',
          contaFinanceiraPagamento[0].id
        );
      } else {
        latestProps.current.setValue('contaFinanceiraId', '');
      }
    }, [contaFinanceiraPagamento]);

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? '3xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
      >
        <ModalContent
          bg="gray.50"
          borderRadius={isLargerThan900 ? 'md' : '0'}
          marginBottom={isLargerThan900 ? '3.75rem' : '0'}
          marginTop={isLargerThan900 ? '3.75rem' : '0'}
          w={isLargerThan900 ? '550px' : 'full'}
          h={isLargerThan900 ? '540px' : 'full'}
        >
          <ModalHeader p="0">
            <Flex
              justifyContent={isLargerThan900 ? 'start' : 'space-between'}
              mb="12px"
              mt="24px"
              w="90%"
              ml={isLargerThan900 ? '5%' : '4%'}
            >
              {!isLargerThan900 && (
                <Flex alignItems="center">
                  <Icon
                    fontSize="20px"
                    color="black"
                    mr="5px"
                    w="full"
                    cursor="pointer"
                    onClick={() => {
                      onClose();
                    }}
                    as={FiChevronLeft}
                  />
                </Flex>
              )}

              <Text
                fontSize={isLargerThan900 ? '20px' : '16px'}
                fontWeight="semibold"
                color="red.500"
              >
                Devolução de valores
              </Text>
            </Flex>
          </ModalHeader>

          <FormProvider {...formMethods}>
            {isLoading && <LoadingPadrao />}
            <ModalBody mb={isLargerThan900 ? '20px' : '20px'} p="0">
              <Flex
                color="white"
                justifyContent="center"
                alignItems="center"
                h="100px"
                bg="red.500"
                mb="24px"
              >
                <Text mt="4px" fontSize="sm" mr="2px">
                  Valor: R$
                </Text>

                <Text fontSize="24px" fontWeight="bold">
                  {DecimalMask(valorTotal, 2, 2)}
                </Text>
              </Flex>
              <Box ml="5%">
                <Flex mb="24px" w="94%">
                  <SelectPadrao
                    id="formaPagamento"
                    name="formaPagamento"
                    label="Forma"
                    autoFocus
                    asControlledByObject
                    placeholder="Selecione a forma"
                    isClearable
                    options={informacoesFormaPagamento}
                  />
                </Flex>
                <Flex mb="24px" w="94%">
                  <SelectPadrao
                    size="sm"
                    id="contaFinanceiraId"
                    name="contaFinanceiraId"
                    isDisabled={
                      formaPagamento === undefined ||
                      contaFinanceiraPagamento.length === 1
                    }
                    label="Conta financeira"
                    placeholder="Selecione uma conta financeira"
                    options={
                      formaPagamento?.contasFinanceiras
                        ? formaPagamento?.contasFinanceiras.map((conta) => ({
                            value: conta.id,
                            label: conta.nome,
                          }))
                        : []
                    }
                    colSpan={{ base: 12, lg: 6 }}
                  />
                </Flex>
                <Text
                  mb="2px"
                  color="black"
                  fontWeight="semibold"
                  fontSize="sm"
                >
                  Observação
                </Text>
                <Textarea
                  id="observacao"
                  w="94%"
                  h={isLargerThan900 ? '80px' : '120px'}
                  bgColor="white"
                  resize="none"
                  placeholder="Informe o motivo."
                  {...formMethods.register('observacao')}
                />
              </Box>
            </ModalBody>
            <ModalFooter
              h={
                isLargerThan900 ? '200px' : isLargerThan700 ? '800px' : '200px'
              }
            >
              <Flex
                w="full"
                h="full"
                justifyContent="center"
                alignItems="baseline"
              >
                <Button
                  id="gerarCredito"
                  name="gerarCredito"
                  color="gray.300"
                  variant="outline"
                  borderRadius="20px"
                  fontSize="sm"
                  type="button"
                  h={isLargerThan900 ? '32px' : '40px'}
                  mr="24px"
                  onClick={() => {
                    setProdutosDevolucao([]);
                    onClose();
                  }}
                  _hover={{ bg: 'gray.50' }}
                  w="96px"
                >
                  Cancelar
                </Button>
                <ButtonDefault
                  id="gerarCredito"
                  name="gerarCredito"
                  color="white"
                  variant="solid"
                  bg="red.500"
                  isDisabled={!formaPagamento || !contaFinanceiraWatch}
                  borderRadius="20px"
                  fontSize="sm"
                  type="button"
                  h={isLargerThan900 ? '32px' : '40px'}
                  onClick={() => handleSubmit()}
                  _hover={{ bg: 'red.500' }}
                  width="96px"
                  isLoading={isLoading}
                >
                  Confirmar
                </ButtonDefault>
              </Flex>
            </ModalFooter>
          </FormProvider>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
