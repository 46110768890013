import { create, InstanceProps } from 'react-modal-promise';
import {
  ModalProps,
  Button,
  useDisclosure,
  useMediaQuery,
  Divider,
  ModalFooter,
  ModalBody,
  Flex,
  Box,
  Icon,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Text,
} from '@chakra-ui/react';

import RegraLimiteCreditoEnum from 'constants/enum/regraLimiteCredito';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { UsuariosPerfilUsuarioIcon } from 'icons';

type ModalExplicacaoRegraBloqueioModalProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<null>;

export const ExplicacaoRegraBloqueioModal = create<ModalExplicacaoRegraBloqueioModalProps>(
  ({ onResolve, onReject, ...rest }) => {
    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? '3xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
      >
        <ModalContent
          bg="gray.50"
          borderRadius={isLargerThan900 ? 'md' : '0'}
          marginBottom={isLargerThan900 ? '3.75rem' : '0'}
          marginTop={isLargerThan900 ? '3.75rem' : '0'}
          w={isLargerThan900 ? '550px' : 'full'}
          h={isLargerThan900 ? '487px' : 'full'}
        >
          <ModalHeader>
            <Text fontSize="16px" color="primary.500">
              Regras para bloqueio de clientes
            </Text>
            <Text fontSize="14px">Entenda o significado de cada regra</Text>
          </ModalHeader>
          <ModalCloseButton />
          <Flex mb="15px" w="full" alignItems="center" justifyContent="center">
            <Divider w="97%" />
          </Flex>
          <ModalBody>
            {RegraLimiteCreditoEnum.properties.map((regraLimiteCredito) => {
              return (
                <Flex mb="15px" justifyContent="center" direction="column">
                  <Flex alignItems="center">
                    <Icon
                      fontSize="20px"
                      mr="15px"
                      as={UsuariosPerfilUsuarioIcon}
                      color={regraLimiteCredito.iconColor}
                    />
                    <Box fontSize="16px" fontWeight="bold">
                      {regraLimiteCredito.name}
                      {regraLimiteCredito.padrao && ' (padrão)'}
                    </Box>
                  </Flex>

                  <Box ml="35px">{regraLimiteCredito.descricao}</Box>
                </Flex>
              );
            })}
          </ModalBody>
          <ModalFooter>
            <Flex w="full" alignItems="center" justifyContent="center">
              <Button
                type="button"
                colorScheme="gray.100"
                borderRadius="19px"
                w={isLargerThan900 ? '100px' : 'full'}
                _hover={{ background: 'none' }}
                variant="outline"
                onClick={() => onClose()}
              >
                Fechar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
