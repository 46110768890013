import React, { useRef } from 'react';
import {
  Tr,
  TableRowProps,
  Td,
  Text,
  Flex,
  Button,
  HStack,
  Icon,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Portal,
  Th,
} from '@chakra-ui/react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

import { TextValor } from 'components/PDV/Text/TextValor';

import Table from '../Table';

type Parcela = {
  numeroParcela: number;
  dataVencimento: Date;
  valor: number;
};

interface TableRowFormaPagamentoProps extends TableRowProps {
  descricaoFormaPagto: string;
  descricaoParcelas: string;
  dataVencimento: Date;
  valor: number;
  parcelas: Parcela[];
  cupomTroca: boolean;
}

const formatDate = (date: Date) => {
  return new Date(date).toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};

const TableRowFormaPagamento = ({
  descricaoFormaPagto,
  descricaoParcelas,
  dataVencimento,
  cupomTroca,
  valor,
  parcelas,
  key,
  ...rest
}: TableRowFormaPagamentoProps) => {
  const accordionPanelParentRef = useRef<HTMLTableDataCellElement>(null);

  const hasVariasParcelas = parcelas?.length > 1;

  return (
    <React.Fragment key={key}>
      <AccordionItem as={Tr} {...rest}>
        {({ isExpanded }) => (
          <>
            <Td {...(isExpanded ? { border: 'none' } : {})}>
              <Flex gap="4px">
                <Text fontWeight="semibold">{descricaoFormaPagto}</Text>
                {cupomTroca && <Text color="red.500"> - Troca</Text>}
              </Flex>
            </Td>
            <Td {...(isExpanded ? { border: 'none' } : {})}>
              <HStack spacing="3">
                <Text fontWeight="semibold" whiteSpace="nowrap">
                  {descricaoParcelas}
                </Text>
                {hasVariasParcelas && (
                  <Button
                    as={AccordionButton}
                    size="sm"
                    fontSize={{ base: 'xs', md: 'sm' }}
                    variant="link"
                    colorScheme="blue"
                    p="1"
                    _focus={{
                      boxShadow: 'none',
                    }}
                  >
                    <HStack spacing="1">
                      <Text>Ver parcelas</Text>
                      <Icon as={isExpanded ? FiChevronUp : FiChevronDown} />
                    </HStack>
                  </Button>
                )}
              </HStack>
            </Td>
            <Td {...(isExpanded ? { border: 'none' } : {})}>
              <Text fontWeight="semibold">{formatDate(dataVencimento)}</Text>
            </Td>
            <Td {...(isExpanded ? { border: 'none' } : {})}>
              <TextValor
                valor={valor}
                casasDecimais={2}
                fontSize="sm"
                fontWeight="semibold"
                color="black"
                justifyContent="flex-end"
              />
            </Td>
            <Td {...(isExpanded ? { border: 'none' } : {})} />

            <Portal containerRef={accordionPanelParentRef}>
              <AccordionPanel
                bg="gray.50"
                sx={{ 'tr:last-of-type > td': { border: 'none' } }}
                borderBottom="1px"
                borderColor="gray.100"
                pl="12px"
              >
                <Table
                  theadContent={
                    <Tr>
                      <Th>Parcelas</Th>
                      <Th>Vencimento</Th>
                      <Th>Valor</Th>
                    </Tr>
                  }
                  tbodyContent={
                    <>
                      {parcelas.map((parcela) => (
                        <Tr>
                          <Td>
                            <Text fontWeight="semibold">{`Parcela ${String(
                              parcela.numeroParcela
                            ).padStart(2, '0')}`}</Text>
                          </Td>
                          <Td>
                            <Text fontWeight="semibold">
                              {formatDate(parcela.dataVencimento)}
                            </Text>
                          </Td>
                          <Td>
                            <TextValor
                              valor={parcela.valor}
                              casasDecimais={2}
                              fontSize="sm"
                              fontWeight="semibold"
                              color="black"
                            />
                          </Td>
                        </Tr>
                      ))}
                    </>
                  }
                />
              </AccordionPanel>
            </Portal>
          </>
        )}
      </AccordionItem>

      <Tr id="accordion-panel-parcelas">
        <Td
          p="0 !important"
          border="none"
          colSpan={5}
          ref={accordionPanelParentRef}
        />
      </Tr>
    </React.Fragment>
  );
};

export default TableRowFormaPagamento;
