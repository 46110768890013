import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export type EntradaMercadoriaItemRateio = {
  key: string;
  id: string;
  nomeProduto: string;
  participaRateioIcmsSt: boolean;
};

export type FormData = {
  valorIcmsSt: number;
  entradaMercadoriaItensRateio: EntradaMercadoriaItemRateio[];
  selecionarTodos: boolean;
};

const schema = yup.object().shape({
  selecionarTodos: yup.boolean(),
  valorIcmsSt: yup
    .number()
    .when(['selecionarTodos'], {
      is: true,
      then: (schem: any) =>
        schem.moreThan(0, ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
      otherwise: (schem: any) =>
        schem.min(0, ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
    })
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);
