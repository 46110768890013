import React from 'react';
import { Route } from 'react-router-dom';

import InformacoesGeraisProvider from 'store/PDV/InformacoesGerais';

import { ListarRecebimentoContasPdv } from 'pages/PDV/RecebimentoContasPdv/Listar';
import { TrocarProdutos } from 'pages/PDV/TrocarProdutos';
import { ProdutosConsignados } from 'pages/PDV/ProdutosConsignados';
import ProdutosConsignadosProvider from 'store/PDV/ProdutosConsignados';
import { AberturaCaixa } from 'pages/PDV/AberturaCaixa';
import LayoutGuard from './LayoutGuard';
import ConstanteRotasPDV from '../constants/rotasPDV';

import OperacaoProvider from '../store/PDV/Operacao';
import ConsultarOperacoesProvider from '../store/PDV/ConsultarOperacoes';

import Home from '../pages/PDV/Home';
import AbrirPdv from '../pages/PDV/AbrirPdv';
import AbrirCaixa from '../pages/PDV/AbrirCaixa';

import ConsultarOperacoes from '../pages/PDV/ConsultarOperacoes';
import Lancamento from '../pages/PDV/Lancamento';
import Pagamento from '../pages/PDV/Pagamento';
import Finalizar from '../pages/PDV/Finalizar';
import Compartilhar from '../pages/PDV/Compartilhar';

const PdvRoutes = () => (
  <Route path="/pdv" key="pdv">
    <InformacoesGeraisProvider>
      <LayoutGuard
        key="pdv-abrir-pdv"
        component={AbrirPdv}
        path={['', ConstanteRotasPDV.PDV_ABRIR_PDV]}
        exact
        meta={{ auth: true }}
      />

      <LayoutGuard
        key="pdv-abrir-caixa"
        component={AbrirCaixa}
        path={['', ConstanteRotasPDV.PDV_ABRIR_CAIXA]}
        exact
        meta={{ auth: true }}
      />
      <LayoutGuard
        key="pdv-abertura-caixa"
        component={AberturaCaixa}
        path={['', ConstanteRotasPDV.PDV_ABERTURA_CAIXA]}
        exact
        meta={{ auth: true }}
      />
      <LayoutGuard
        key="pdv-home"
        component={Home}
        path={['', ConstanteRotasPDV.PDV_HOME]}
        exact
        meta={{ auth: true }}
      />

      <Route
        exact
        path={[
          ConstanteRotasPDV.PDV_CONSULTAR_OPERACOES,
          ConstanteRotasPDV.PDV_COMPARTILHAR,
        ]}
      >
        <ConsultarOperacoesProvider>
          <LayoutGuard
            key="consulta-operacoes"
            component={ConsultarOperacoes}
            path={ConstanteRotasPDV.PDV_CONSULTAR_OPERACOES}
            exact
            meta={{ auth: true }}
          />
          <LayoutGuard
            key="compartilhar"
            component={Compartilhar}
            path={ConstanteRotasPDV.PDV_COMPARTILHAR}
            exact
            meta={{ auth: true }}
          />
        </ConsultarOperacoesProvider>
      </Route>

      <LayoutGuard
        key="recebimento-contas-listar-pdv"
        component={ListarRecebimentoContasPdv}
        path={ConstanteRotasPDV.PDV_RECEBIMENTO_CONTAS}
        exact
        meta={{ auth: true }}
      />

      <LayoutGuard
        key="trocar-produtos-listar-pdv"
        component={TrocarProdutos}
        path={ConstanteRotasPDV.PDV_TROCAR_PRODUTOS}
        exact
        meta={{ auth: true }}
      />
      <ProdutosConsignadosProvider>
        <LayoutGuard
          key="produtos-consignados-listar-pdv"
          component={ProdutosConsignados}
          path={ConstanteRotasPDV.PDV_PRODUTOS_CONSIGNADOS}
          exact
          meta={{ auth: true }}
        />
      </ProdutosConsignadosProvider>

      <Route
        exact
        path={[
          ConstanteRotasPDV.PDV_LANCAMENTO_ID,
          ConstanteRotasPDV.PDV_PAGAMENTO,
          ConstanteRotasPDV.PDV_FINALIZAR,
          ConstanteRotasPDV.PDV_FINALIZAR_GERAR_VOUCHER,
          ConstanteRotasPDV.PDV_FINALIZAR_DEVOLUCAO_DINHEIRO,
        ]}
        render={(props) => (
          <OperacaoProvider {...props}>
            <LayoutGuard
              key="lancamento"
              component={Lancamento}
              path={ConstanteRotasPDV.PDV_LANCAMENTO_ID}
              exact
              meta={{ auth: true }}
            />
            <LayoutGuard
              key="pagamento"
              component={Pagamento}
              path={ConstanteRotasPDV.PDV_PAGAMENTO}
              exact
              meta={{ auth: true }}
            />
            <LayoutGuard
              key="finalizar-pdv"
              component={Finalizar}
              path={ConstanteRotasPDV.PDV_FINALIZAR}
              exact
              meta={{ auth: true }}
            />
            <LayoutGuard
              key="finalizar-gerar-voucher"
              component={Finalizar}
              path={ConstanteRotasPDV.PDV_FINALIZAR_GERAR_VOUCHER}
              exact
              meta={{ auth: true }}
            />
            <LayoutGuard
              key="finalizar-devolucao-dinheiro"
              component={Finalizar}
              path={ConstanteRotasPDV.PDV_FINALIZAR_DEVOLUCAO_DINHEIRO}
              exact
              meta={{ auth: true }}
            />
          </OperacaoProvider>
        )}
      />
    </InformacoesGeraisProvider>
  </Route>
);

export default PdvRoutes;
