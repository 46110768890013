const OperacaoIntermediadorEnum = {
  SemIntermediador: 0,
  OperacaoEmSitePlataformaTerceiros: 1,

  properties: {
    1: { name: 'Sem intermediador', value: 0 },
    2: { name: 'Operação em site ou plataforma de terceiros', value: 1 },
  },
};

export default OperacaoIntermediadorEnum;
