import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';
import { toast } from 'react-toastify';
import RegraLimiteCreditoEnum from 'constants/enum/regraLimiteCredito';

export const validarDiasAtraso = async (
  clienteId: string
): Promise<boolean> => {
  const response = await api.get<void, ResponseApi<boolean>>(
    ConstanteEnderecoWebservice.MOVIMENTACAO_FINANCEIRA_VALIDAR_CONTAS_EM_ABERTO,
    {
      params: { clienteId },
    }
  );

  if (response?.avisos) {
    response.avisos.map((item: string) => toast.warning(item));
  }

  if (response?.sucesso) {
    return response.dados;
  }

  return false;
};

export const validarRegraLimiteCredito = async (
  cliente: any
): Promise<boolean> => {
  if (cliente) {
    if (cliente.regraLimiteCredito === RegraLimiteCreditoEnum.BLOQUEIO_TOTAL) {
      toast.warning(
        'O cliente está vinculado a categoria de bloqueio total e não pode ser selecionado.'
      );
      return false;
    }

    if (
      cliente.regraLimiteCredito === RegraLimiteCreditoEnum.BLOQUEIO_DIAS_ATRASO
    ) {
      if (!(await validarDiasAtraso(cliente.id))) {
        toast.warning(
          'Esse cliente não pode ser selecionado, pois ele está na categoria de bloqueio de dias em atraso e possui pendências.'
        );
        return false;
      }
    }
  }

  return true;
};
