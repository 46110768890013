import { useEffect } from 'react';
import {
  Text,
  VStack,
  Box,
  useMediaQuery,
  HStack,
  Flex,
  Button,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import {
  ContainerIntegracaoFixo,
  useSmartPosEtapasContext,
} from 'store/SmartPos/SmartPosEtapasContext';
import { IdentificacaoEtapasSmartPos } from 'constants/enum/IdentificacaoEtapasSmartPos';
import ImagemExemploCorreta from 'assets/images/integracoes/smart_pos/exemploImpressaoCorreta.png';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { getImagemSmartPos } from 'services/smartPos';

import { ConfiguracoesPadronizacaoIcon, ArrastarImagemIcon } from 'icons';
import ImagePickerPadrao from 'components/ImagePicker/ImagePickerPadrao';

import { HeaderSmartPos } from '../Layout/Header';
import { FooterSmartPos } from '../Layout/FooterSmartPos';
import { ModalImagemExemplo } from './ModalImagemExemplo';

type FormData = {
  foto: string;
};

export const ImagemImpressao = () => {
  const {
    setActiveStep,
    setImagem,
    setIsLoading,
    animacaoLoading,
  } = useSmartPosEtapasContext();

  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

  const formMethods = useForm<FormData>();

  const { handleSubmit: submit, setValue } = formMethods;

  const handleSubmit = submit(async (data) => {
    setIsLoading(true);
    if (!data.foto) {
      setActiveStep(IdentificacaoEtapasSmartPos.TEXTO_PARA_IMPRESSAO);
      return;
    }
    const response = await api.put<void, ResponseApi>(
      ConstanteEnderecoWebservice.INTEGRACAO_CAIXA_ALTERAR_IMAGEM_IMPRESSAO,
      {
        logo: data.foto,
      }
    );
    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
        setIsLoading(false);
      }
      if (response.sucesso) {
        setActiveStep(IdentificacaoEtapasSmartPos.TEXTO_PARA_IMPRESSAO);
        setImagem(data.foto);
      }
    }
    setIsLoading(false);
  });

  const handleDownloadImagemExemplo = () => {
    fetch(ImagemExemploCorreta)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Imagem_exemplo.png');
        link.click();
      })
      .catch(() => {
        toast('Erro ao baixar a imagem');
      });
  };

  const handleAbrirModalImagemExemplo = () => {
    ModalImagemExemplo({ handleDownloadImagemExemplo });
  };

  const handleVoltar = () => {
    setIsLoading(true);
    setActiveStep(IdentificacaoEtapasSmartPos.TIPO_IMPRESSAO);
  };

  useEffect(() => {
    const getImagem = async () => {
      const response = await getImagemSmartPos();

      if (response !== null) {
        setValue('foto', response);
        animacaoLoading();
      }
      animacaoLoading();
    };
    getImagem();
  }, [setImagem, setValue, animacaoLoading]);

  return (
    <FormProvider {...formMethods}>
      <ContainerIntegracaoFixo>
        <HeaderSmartPos
          title="Imagem para impressão"
          handleVoltar={handleVoltar}
          icon={ConfiguracoesPadronizacaoIcon}
        />
        <Box pl={['10px', '20px', '60px']} pr={['10px', '20px', '60px']}>
          <VStack
            mb={['0', '22px', '22px']}
            color="primary.50"
            fontSize="14px"
            justifyContent="center"
            spacing="24px"
          >
            <Text
              textAlign={isLargerThan700 ? undefined : 'center'}
              letterSpacing="0px"
              w={isLargerThan700 ? '560px' : 'full'}
            >
              Adicione um logotipo ou imagem para personalizar a impressão. A
              imagem ideal deve ser toda na cor preta, sem cores, efeitos ou
              degradês, com resolução máxima de 190 x 190 pixels. Confira as
              dicas para obter o melhor resultado. Você poderá alterar essa
              imagem nas configurações da impressão a qualquer momento.
            </Text>
          </VStack>
          <HStack
            justifyContent="center"
            alignItems="baseline"
            flexDirection={['column', 'row', 'row']}
            spacing={['0', '16px', '16px']}
          >
            <Flex
              mt={['20px', '20px', '0']}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              w="190px"
            >
              <Box filter="grayscale(1)">
                <ImagePickerPadrao
                  id="foto"
                  name="foto"
                  isFormatSizeImage={false}
                  objectFit="contain"
                  required={false}
                  icon={ArrastarImagemIcon}
                  background="white"
                  label="Inserir imagem:"
                  valueTamanhoImg={190}
                  scaleImg={100}
                  w="190px"
                  h="190px"
                />
              </Box>
              <Flex w="full" textAlign="start" mt="6px">
                <Button
                  variant="link"
                  fontSize="12px"
                  w="fit-content"
                  colorScheme="blue"
                  onClick={() => handleAbrirModalImagemExemplo()}
                >
                  Ver dicas e exemplo
                </Button>
              </Flex>
            </Flex>
          </HStack>
        </Box>
      </ContainerIntegracaoFixo>
      <FooterSmartPos handleAvancar={handleSubmit} />
    </FormProvider>
  );
};
