import { Box, Flex, Text } from '@chakra-ui/react';

import { DecimalMask } from 'helpers/format/fieldsMasks';
import { formatDate } from 'helpers/format/formatStringDate';
import { CasasDecimais } from 'store/Padronizacao/Padronizacao';

import { ItensConsignados } from '../types';

type DadosProdutosConsignadosPdvMobileProps = {
  produtosConsignados: ItensConsignados[];
  casasDecimais: CasasDecimais;
};

export const DadosProdutosConsignadosPdvMobile = ({
  produtosConsignados,
  casasDecimais,
}: DadosProdutosConsignadosPdvMobileProps) => {
  return (
    <Box w="full" mt="-10px">
      {produtosConsignados.length > 0 ? (
        <>
          {produtosConsignados.map((produto) => {
            return (
              <Box
                mt="10px"
                w="full"
                borderRadius="4px"
                padding="10px"
                border="1px solid gray"
                key={produto.cliente}
              >
                <Box>
                  <Text color="primary.50" fontSize="16px">
                    {produto.cliente}
                  </Text>
                  <Flex w="full" justifyContent="space-between">
                    <Text fontSize="12px">Variação: {produto.variacao}</Text>
                    <Text fontSize="12px">
                      Quantidade:{' '}
                      {DecimalMask(
                        produto.quantidade,
                        casasDecimais?.casasDecimaisQuantidade,
                        casasDecimais?.casasDecimaisQuantidade
                      )}
                    </Text>
                  </Flex>
                  <Flex w="full" justifyContent="space-between">
                    <Text fontSize="12px">
                      Telefone: {produto.telefone || '-'}
                    </Text>
                    <Text fontSize="12px">
                      Data: {formatDate(produto.data)}
                    </Text>
                  </Flex>
                </Box>
              </Box>
            );
          })}
        </>
      ) : (
        <Box>Não existem registros para serem mostrados</Box>
      )}
    </Box>
  );
};
