import React, {
  useState,
  Dispatch,
  SetStateAction,
  useRef,
  ChangeEvent,
} from 'react';
import { Box, Flex, VStack, Icon, Text, Button } from '@chakra-ui/react';
import Dropzone from 'react-dropzone';
import { FiCheckCircle, FiXCircle } from 'react-icons/fi';

import { UploadArrastarIcon } from 'icons';

interface DropFileInputProps {
  file?: File;
  setFile: Dispatch<SetStateAction<File | undefined>>;
}

export function DropFileInput({ file, setFile }: DropFileInputProps) {
  const [fileIsReject, setFileIsReject] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  function handleOpenInput() {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }

  function onChange(newFile?: File) {
    if (newFile) {
      const fileType = newFile.name.split('.')[
        newFile.name.split('.').length - 1
      ];

      if (fileType === 'csv' || fileType === 'xls' || fileType === 'xlsx') {
        setFile(newFile);
        setFileIsReject(false);
      } else {
        setFile(undefined);
        setFileIsReject(true);
      }
    } else {
      setFileIsReject(true);
    }
  }

  if (file) {
    return (
      <Flex
        h="full"
        w="full"
        alignItems="center"
        justifyContent="center"
        flexDir="column"
        bg="aquamarine.50"
        borderRadius="2xl"
        border="1px dashed"
        borderColor="aquamarine.600"
      >
        <VStack spacing="4" justifyContent="center">
          <Icon as={FiCheckCircle} boxSize="14" color="aquamarine.600" />
          <Text fontSize="md" fontWeight="semibold" userSelect="none">
            {`Arquivo selecionado: ${file.name}`}
          </Text>

          <input
            type="file"
            accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            style={{ display: 'none' }}
            ref={inputRef}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const { files } = e.target;
              if (!files || files.length === 0) {
                return;
              }

              const newFile = files[0];

              if (newFile) onChange(newFile);
            }}
          />
        </VStack>

        <Button
          mt="8"
          variant="link"
          size="sm"
          colorScheme="blue"
          onClick={handleOpenInput}
        >
          Escolher novo arquivo
        </Button>
      </Flex>
    );
  }

  return (
    <Dropzone
      accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      onDrop={(acceptedFiles: File[], fileRejections) => {
        if (!file) {
          if (fileRejections && fileRejections.length > 0) {
            setFile(undefined);
            setFileIsReject(true);

            return;
          }

          setFileIsReject(false);

          if (!acceptedFiles || acceptedFiles.length === 0) {
            return;
          }

          const droppedFile = acceptedFiles[0];

          if (droppedFile) {
            onChange(droppedFile);
          }
        }
      }}
      noClick
    >
      {({ getRootProps, getInputProps, isDragActive, open }) => {
        const inputProps = getInputProps();

        if (fileIsReject) {
          return (
            <Flex
              h="full"
              w="full"
              alignItems="center"
              justifyContent="center"
              flexDir="column"
              bg="red.50"
              borderRadius="2xl"
              border="1px dashed"
              borderColor="red.100"
              {...getRootProps()}
              filter={isDragActive ? 'brightness(0.9)' : 'none'}
              transition="filter 0.2s"
            >
              <VStack spacing="4" justifyContent="center">
                <Icon as={FiXCircle} boxSize="14" color="red.500" />
                <Text fontSize="md" fontWeight="semibold" userSelect="none">
                  O formato desse arquivo não é permitido
                </Text>

                <input
                  type="file"
                  accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  style={{ display: 'none' }}
                  ref={inputRef}
                  {...inputProps}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const { files } = e.target;
                    if (!files || files.length === 0) {
                      return;
                    }

                    const newFile = files[0];

                    if (newFile) onChange(newFile);
                  }}
                />
              </VStack>

              <Button
                mt="8"
                variant="link"
                size="sm"
                colorScheme="blue"
                onClick={open}
              >
                Escolher novo arquivo
              </Button>
            </Flex>
          );
        }

        return (
          <Box
            h="full"
            bg="gray.50"
            borderRadius="2xl"
            border="1px dashed"
            borderColor="gray.100"
            {...getRootProps()}
            filter={isDragActive ? 'brightness(0.9)' : 'none'}
            transition="filter 0.2s"
          >
            <VStack spacing="4" h="full" justifyContent="center">
              <Icon as={UploadArrastarIcon} boxSize="14" color="gray.700" />
              <Text
                lineHeight="none"
                fontWeight="semibold"
                fontSize="md"
                userSelect="none"
              >
                Arraste e solte seu arquivo Excel (.xls ou xlsx) ou .CSV
                separado por ; aqui
              </Text>
              <Text
                lineHeight="none"
                fontWeight="semibold"
                fontSize="md"
                userSelect="none"
              >
                ou
              </Text>
              <Button
                type="button"
                colorScheme="secondary"
                borderRadius="md"
                fontSize="sm"
                onClick={open}
              >
                Adicione um arquivo de seu computador
              </Button>
            </VStack>

            <input
              type="file"
              accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              ref={inputRef}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const { files } = e.target;
                if (!files || files.length === 0) {
                  return;
                }

                const newFile = files[0];

                if (newFile) onChange(newFile);
              }}
              {...inputProps}
            />
          </Box>
        );
      }}
    </Dropzone>
  );
}
