import { Box, BoxProps } from '@chakra-ui/react';

import { corCategoriaProdutoEnum } from 'constants/enum/nivelCategoriaProduto';

interface ContainerProps extends BoxProps {
  nivel: number;
  height?: number;
  children: React.ReactNode;
}

export const Container = ({
  nivel,
  height = 0,
  children,
  ...rest
}: ContainerProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      height={`${height}px`}
      marginLeft={nivel === 1 ? '12px' : `calc(45px * ${nivel - 1})`}
      marginTop={nivel > 1 ? '5px' : undefined}
      color={
        corCategoriaProdutoEnum.properties[nivel - 1].foregroundColor // Certifique-se de ajustar isso conforme necessário
      }
      {...rest}
    >
      {children}
    </Box>
  );
};
