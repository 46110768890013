import React, { useState } from 'react';
import {
  MenuButton,
  IconButton,
  Icon,
  MenuList,
  MenuItem,
  useDisclosure,
} from '@chakra-ui/react';
import { FiMoreVertical } from 'react-icons/fi';

import { useEditarParcelasContext } from 'store/PDV/EditarParcelas';
import MeioPagamentoEnum from 'constants/enum/fiscal/meioPagamento';
import { useInformacoesGeraisContext } from 'store/PDV/InformacoesGerais';

import MenuListas from 'components/PDV/Menu/MenuListas';
import ModalConfirmacao from 'components/PDV/Modal/ModalConfirmacao';
import { ModalConfirmarExcluirStone } from 'components/PDV/Modal/ModalConfirmarExcluirStone';

import { DrawerInformacoesFiscais } from '../../DrawerInformacoesFiscais';
import { MovimentacaoFinanceira } from '../../types';

interface ActionMenuProps {
  movimentacaoFinanceira: MovimentacaoFinanceira;
  handleDeleteMovimentacaoFinanceira: (
    identificacaoAgrupamento: string
  ) => Promise<void>;
  isDisabled: boolean;
}

export const ActionsMenu = ({
  movimentacaoFinanceira,
  handleDeleteMovimentacaoFinanceira,
  isDisabled,
}: ActionMenuProps) => {
  const {
    handleSetMovimentacaoFinanceiraEditando,
  } = useEditarParcelasContext();
  const { contaFinanceira } = useInformacoesGeraisContext();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [modalConfirmacaoIsOpen, setModalConfirmacaoIsOpen] = useState(false);
  const { regraMeioPagamento } = movimentacaoFinanceira;
  const isMeioPagamentoStone =
    regraMeioPagamento === MeioPagamentoEnum.CartaoCreditoStone ||
    regraMeioPagamento === MeioPagamentoEnum.CartaoDebitoStone;

  const handleOnConfirmModalConfirmacao = () => {
    handleDeleteMovimentacaoFinanceira(
      movimentacaoFinanceira.identificacaoAgrupamento
    );
  };

  const handleEditPaymentInformation = () => {
    if (isOpen) {
      onClose();
    } else {
      onOpen();
    }
  };

  const naoPodeAlterarDataVencimento =
    movimentacaoFinanceira &&
    (movimentacaoFinanceira.regraMeioPagamento ===
      MeioPagamentoEnum.CartaoDebitoStone ||
      movimentacaoFinanceira.regraMeioPagamento ===
        MeioPagamentoEnum.PixPresencialZoop ||
      movimentacaoFinanceira.regraMeioPagamento ===
        MeioPagamentoEnum.PixEnvioZoop ||
      movimentacaoFinanceira.regraMeioPagamento ===
        MeioPagamentoEnum.ValePresente);

  return (
    <>
      <MenuListas
        isDisabled={isDisabled}
        MenuButton={() => (
          <MenuButton
            as={IconButton}
            icon={<Icon as={FiMoreVertical} fontSize="xl" />}
            variant="ghost"
            borderRadius="md"
            size="sm"
            minW="5"
            isDisabled={isDisabled}
          />
        )}
        MenuList={() => (
          <>
            <MenuList>
              <MenuItem onClick={handleEditPaymentInformation}>
                Informações de pagamento
              </MenuItem>
              {!naoPodeAlterarDataVencimento && (
                <MenuItem
                  onClick={() => {
                    handleSetMovimentacaoFinanceiraEditando(
                      movimentacaoFinanceira
                    );
                  }}
                >
                  {movimentacaoFinanceira.temParcelas
                    ? 'Ver parcelas'
                    : 'Ver parcela'}
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  if (
                    isMeioPagamentoStone &&
                    contaFinanceira &&
                    contaFinanceira?.serialPOS
                  ) {
                    ModalConfirmarExcluirStone({
                      confirmaExclusao: handleOnConfirmModalConfirmacao,
                    });
                  } else {
                    setModalConfirmacaoIsOpen(true);
                  }
                }}
              >
                Remover
              </MenuItem>
            </MenuList>
          </>
        )}
      />

      {modalConfirmacaoIsOpen && (
        <ModalConfirmacao
          isOpen={modalConfirmacaoIsOpen}
          setIsOpen={setModalConfirmacaoIsOpen}
          textoCabecalho="Confirmar"
          textoMensagem="A movimentação financeira será excluída, deseja continuar?"
          onConfirm={handleOnConfirmModalConfirmacao}
          textoCancelar="Não, cancelar!"
          textoConfirmar="Sim, continuar!"
        />
      )}
      <DrawerInformacoesFiscais
        onClose={onClose}
        isOpen={isOpen}
        identificadorAgrupamento={
          movimentacaoFinanceira.identificacaoAgrupamento
        }
      />
    </>
  );
};
