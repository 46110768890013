import React, { useRef, useState } from 'react';

import {
  FlexProps,
  Flex,
  Box,
  Icon,
  Text,
  VStack,
  Image as ImageChakra,
} from '@chakra-ui/react';
import { Controller } from 'react-hook-form';

import { ImagemAdicionarIcon } from 'icons';
import { ListImagensProps } from 'components/ImagePicker/ImageUploader';

import { ModalRedimensionarImagem } from '../ModalRedimensionarImagem';

type ImageUploaderRatioProps = FlexProps & {
  name: string;
  ratio: number;
  handleSaveImage?: (value: ListImagensProps) => void;
  limitResolution?: number;
  limitSizeKilobytes?: number;
};

const ImageUploaderRatio = ({
  name,
  ratio,
  handleSaveImage,
  limitResolution = 2000,
  limitSizeKilobytes = 2000,
  ...rest
}: ImageUploaderRatioProps) => {
  const inputFileRef = useRef<HTMLInputElement>(null);

  const [onMouseOver, setOnMouseOver] = useState(false);

  return (
    <>
      <Controller
        name={name}
        render={({ field: { value: imageValue, onChange } }) => {
          const allowedTypes = ['image/png', 'image/jpeg'];

          const handleAdicionarImagem = (value: ListImagensProps) => {
            if (handleSaveImage) {
              handleSaveImage(value);
            }
          };

          const onloadImage = (
            file: File,
            isForaPadrao: boolean,
            tamanhoImagem: { widthImage: number; heightImage: number }
          ) => {
            const reader = new FileReader();

            const imageAspectRatio =
              tamanhoImagem.widthImage / tamanhoImagem.heightImage;
            const isDifferentRatio = imageAspectRatio !== ratio;

            reader.onload = async () => {
              if (typeof reader.result !== 'string' || isForaPadrao) {
                handleAdicionarImagem({
                  isForaPadrao,
                  linkImagem: '',
                  tamanhoImagem: {
                    widthImage: tamanhoImagem.widthImage,
                    heightImage: tamanhoImagem.heightImage,
                  },
                });

                return;
              }

              if (isDifferentRatio) {
                const { imagemRedimensionada } = await ModalRedimensionarImagem(
                  {
                    ratio,
                    imageValue: reader.result,
                  }
                );

                handleAdicionarImagem({
                  isForaPadrao,
                  linkImagem: imagemRedimensionada,
                  tamanhoImagem: {
                    widthImage: tamanhoImagem.widthImage,
                    heightImage: tamanhoImagem.heightImage,
                  },
                });

                onChange(imagemRedimensionada);
                return;
              }

              handleAdicionarImagem({
                isForaPadrao,
                linkImagem: reader.result,
                tamanhoImagem: {
                  widthImage: tamanhoImagem.widthImage,
                  heightImage: tamanhoImagem.heightImage,
                },
              });

              onChange(reader.result);
            };

            reader.readAsDataURL(file);
          };

          const convertFileInJPEG = async (
            image: HTMLImageElement,
            file: File
          ) => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            if (!ctx) {
              return;
            }

            const imgWidth = image.width;
            const imgHeight = image.height;

            canvas.width = imgWidth;
            canvas.height = imgHeight;

            ctx.drawImage(image, 0, 0, imgWidth, imgHeight);

            canvas.toBlob((blob) => {
              if (!blob) {
                return;
              }

              const jpegFile = new File(
                [blob],
                file.name.replace(/\.[^/.]+$/, '.jpeg'),
                {
                  type: 'image/jpeg',
                  lastModified: Date.now(),
                }
              );

              const imageSize = {
                heightImage: canvas.height,
                widthImage: canvas.width,
              };

              if (
                image.width > limitResolution ||
                image.height > limitResolution
              ) {
                onloadImage(jpegFile, true, imageSize);
                return;
              }

              if (file.size > limitSizeKilobytes * 1024) {
                onloadImage(jpegFile, true, imageSize);
                return;
              }

              onloadImage(jpegFile, false, imageSize);
            }, 'image/jpeg');
          };

          const dropImage = (file: File) => {
            if (file && file.type.startsWith('image/')) {
              const image = new Image();

              image.onload = () => {
                convertFileInJPEG(image, file);
              };

              image.src = URL.createObjectURL(file);
            }
          };

          const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
            e.preventDefault();

            const amountFiles = e.dataTransfer.files.length;
            const { files } = e.dataTransfer;

            const isAllowedType = allowedTypes.some((typeImagem) =>
              typeImagem.includes(files[0].type)
            );

            if (!isAllowedType) {
              return;
            }

            const newFiles = Array.from({ length: amountFiles });
            newFiles.forEach((_, index) => {
              dropImage(files[index]);
            });
          };

          const handleFile = (file: File) => {
            if (file) {
              const image = new Image();

              image.onload = () => {
                convertFileInJPEG(image, file);
              };

              image.src = URL.createObjectURL(file);
            }
          };

          const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
            e.preventDefault();
          };

          return (
            <>
              {imageValue ? (
                <Flex
                  onMouseOverCapture={() => setOnMouseOver(true)}
                  onMouseLeave={() => setOnMouseOver(false)}
                  {...rest}
                >
                  <ImageChakra
                    w="full"
                    h="full"
                    src={imageValue}
                    alt="imagem da variação"
                  />

                  {onMouseOver && (
                    <Flex
                      h="full"
                      w="full"
                      bg="gray.50"
                      alignItems="center"
                      justifyContent="center"
                      position="absolute"
                    >
                      <Box bg="white" py={2} w="max">
                        <Box
                          p={2}
                          w="full"
                          bg="white"
                          cursor="pointer"
                          _hover={{
                            bg: 'gray.100',
                          }}
                          onClick={() => {
                            onChange('');

                            if (inputFileRef.current) {
                              inputFileRef.current.value = '';
                            }
                          }}
                        >
                          Excluir imagem
                        </Box>
                        <Box
                          p={2}
                          w="full"
                          bg="white"
                          cursor="pointer"
                          _hover={{
                            bg: 'gray.100',
                          }}
                          onClick={() => {
                            if (inputFileRef?.current?.click) {
                              inputFileRef.current.click();
                            }
                          }}
                        >
                          Adicionar nova imagem
                        </Box>
                      </Box>
                    </Flex>
                  )}
                </Flex>
              ) : (
                <Flex
                  bg="white"
                  borderRadius="md"
                  borderWidth={imageValue ? '0' : '2px'}
                  borderStyle="dashed"
                  borderColor="gray.200"
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  cursor="pointer"
                  onClick={() => {
                    if (inputFileRef?.current?.click) {
                      inputFileRef.current.click();
                    }
                  }}
                  {...rest}
                >
                  <Flex justifyContent="center">
                    <VStack
                      spacing={2}
                      fontSize={{ base: '10px', md: 'sm' }}
                      lineHeight="15px"
                      textAlign="center"
                    >
                      <Icon
                        fontSize={{ base: '30px', md: '40px' }}
                        as={ImagemAdicionarIcon}
                      />
                      <Text w="60%">Arraste e solte seu arquivo aqui</Text>
                      <Text
                        w="max"
                        color="violet.500"
                        _hover={{ textDecor: 'underline' }}
                      >
                        Escolher arquivo
                      </Text>
                    </VStack>
                  </Flex>
                </Flex>
              )}
              <input
                tabIndex={-1}
                onChange={(event) => {
                  const { files } = event.target;

                  if (!files || files.length === 0) {
                    return;
                  }

                  const newFiles = Array.from({ length: files.length });

                  newFiles.forEach((_, index) => {
                    handleFile(files[index]);
                  });
                }}
                style={{
                  display: 'none',
                }}
                multiple={false}
                ref={inputFileRef}
                type="file"
                accept="image/png, image/jpeg"
              />
            </>
          );
        }}
      />
    </>
  );
};

export default ImageUploaderRatio;
