import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Flex, Checkbox, FlexProps } from '@chakra-ui/react';

interface ParcelaCheckboxProps extends FlexProps {
  children: React.ReactNode;
  name: string;
}

export const ParcelaCheckbox = ({
  name,
  onClick,
  onChange,
  children,
  ...rest
}: ParcelaCheckboxProps) => {
  const formMethods = useFormContext();
  const { setValue } = formMethods;

  const isChecked = formMethods.watch(name);

  return (
    <>
      <Flex
        {...rest}
        alignItems="center"
        padding="12px"
        gap="4px"
        cursor="pointer"
        borderRadius="md"
        border="1px solid"
        borderColor="gray.200"
        transition="all 0.2s"
        bg={isChecked ? 'purple.50' : 'white'}
        _hover={{
          borderColor: 'rgba(85, 2, 178, 0.5)',
          transition: 'all 0.2s',
        }}
        _active={{ bg: 'purple.50' }}
        onClick={(e) => {
          if (onClick) {
            setValue(name, !isChecked);
            onClick(e);
          } else {
            setValue(name, !isChecked);
          }
        }}
      >
        <Checkbox
          colorScheme="primary"
          mb="0px"
          mr="6px"
          name={name}
          boxShadow="inset 0px 3px 6px #00000029"
          onChange={onChange}
          isChecked={isChecked}
          pointerEvents="none"
        />
        {children}
      </Flex>
    </>
  );
};
