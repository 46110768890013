import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export type FormData = {
  url: string;
  code: string;
};

const schema = yup.object().shape({
  url: yup.string().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  code: yup.string().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);
