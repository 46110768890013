import { Icon, MenuItem } from '@chakra-ui/react';

import { NotificacoesIcon } from 'icons';

type ListMenuItemProps = {
  onClick: () => void;
  children: React.ReactNode;
};

export const ListMenuItem = ({ onClick, children }: ListMenuItemProps) => {
  return (
    <MenuItem
      _hover={{
        boxShadow: '0px 0px 4px rgba(85, 2, 178, 0.5)',
      }}
      px="12px"
      borderRadius="6px"
      _focus={{
        bg: 'none',
        boxShadow: '0px 0px 4px #0000003E',
      }}
      _active={{
        bg: 'purple.100',
      }}
      mb="5px"
      boxShadow="0px 0px 4px #0000003E"
      h="64px"
      fontSize="14px"
      onClick={onClick}
      icon={<Icon as={NotificacoesIcon} fontSize="lg" />}
    >
      {children}
    </MenuItem>
  );
};
