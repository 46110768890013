import { Text, VStack, Box, HStack } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  ContainerIntegracaoFixo,
  useSmartPosEtapasContext,
} from 'store/SmartPos/SmartPosEtapasContext';
import { IdentificacaoEtapasSmartPos } from 'constants/enum/IdentificacaoEtapasSmartPos';
import ConstanteRotas from 'constants/rotas';
import { getCodigoAtivacaoSmartPos } from 'services/smartPos';

import { CodigoAtivacaoIcon } from 'icons';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { HeaderSmartPos } from '../Layout/Header';
import { FooterSmartPos } from '../Layout/FooterSmartPos';

export const CodigoAtivacao = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [codigoAtivacao, setCodigoAtivacao] = useState<string[]>([]);

  const history = useHistory();

  const { setActiveStep } = useSmartPosEtapasContext();

  const obterCodigoAtivacao = useCallback(async () => {
    setIsLoading(true);
    const response = await getCodigoAtivacaoSmartPos();
    setCodigoAtivacao(response);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    obterCodigoAtivacao();
  }, [obterCodigoAtivacao]);

  const handleSair = () => {
    setIsLoading(true);
    toast.success('Integração finalizada com sucesso!');
    setIsLoading(false);
    history.push(ConstanteRotas.SMART_POS_LISTAGEM);
  };

  const handleVoltar = () => {
    setActiveStep(IdentificacaoEtapasSmartPos.SENHA_ADM);
  };

  return (
    <>
      {isLoading && <LoadingPadrao />}
      <ContainerIntegracaoFixo>
        <HeaderSmartPos
          title="Código de ativação"
          icon={CodigoAtivacaoIcon}
          iconSize={80}
          handleVoltar={handleVoltar}
        />
        <VStack alignItems="center" w="full" gap="24px" px={['8px', '24px']}>
          <Box w="full" h="72px" bg="primary.50" borderRadius="5px">
            <HStack
              alignItems="center"
              h="full"
              w="full"
              gap={['4px', '8px']}
              justifyContent="center"
            >
              {(codigoAtivacao || []).map((code) => (
                <VStack
                  alignItems="center"
                  justifyContent="center"
                  bg="white"
                  fontWeight="semibold"
                  color="gray.900"
                  textAlign="center"
                  boxSize="40px"
                  borderRadius="5px"
                  fontSize="lg"
                >
                  <Text>{code}</Text>
                </VStack>
              ))}
            </HStack>
          </Box>
          <Text
            textAlign={['center', 'left', 'left']}
            color="primary.50"
            fontSize="14px"
            letterSpacing="0px"
            w="full"
          >
            Informe o código na tela inicial do aplicativo. Esse mesmo código
            será necessário para ativar o app em todas as suas maquininhas.
          </Text>
          <Text
            textAlign={['center', 'left', 'left']}
            color="primary.50"
            fontSize="14px"
            letterSpacing="0px"
            w="full"
          >
            Se você já está com a maquininha em mãos, inicie o aplicativo Smart
            POS e preencha o código da sua conta. Caso você não esteja com a
            maquininha agora, será possível fazer isso a qualquer momento. O
            código de ativação e a quantidade de licenças estarão disponíveis no
            seu painel gerencial.
          </Text>
        </VStack>
      </ContainerIntegracaoFixo>
      <FooterSmartPos handleAvancar={handleSair} />
    </>
  );
};
