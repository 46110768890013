function assertIsGTIN(barcode: string) {
  if (typeof barcode !== 'string') return false;
  if (barcode === '') return false;

  return /^(\d{12,14}|\d{8})$/.test(barcode);
}

function to13Digits(barcode: string) {
  return barcode.padStart(13, '0');
}

export function generateCheckDigit(barcode: string) {
  return String(
    (10 -
      (to13Digits(barcode)
        .split('')
        .map((num, index) => +num * (index % 2 === 0 ? 3 : 1))
        .reduce((prev, cur) => prev + cur) %
        10)) %
      10
  );
}

export function isValidGTIN(barcode: string) {
  if (!assertIsGTIN(barcode)) return false;

  const checkDigit = barcode.slice(-1);
  const generatedCheckDigit = generateCheckDigit(barcode.slice(0, -1));

  return checkDigit === generatedCheckDigit;
}
