import React from 'react';
import { Route } from 'react-router-dom';

import ConstanteRotas from 'constants/rotas';
import ConstanteFuncionalidades from 'constants/permissoes';

import { Promocao } from 'pages/Promocao/Listar';

import LayoutGuard from './LayoutGuard';

export const PromocaoRoutes = [
  <Route path={ConstanteRotas.PROMOCAO}>
    <LayoutGuard
      key="promocao-listar"
      permissaoFuncionalidade={ConstanteFuncionalidades.PROMOCAO_CONFIGURAR}
      component={Promocao}
      breadcrumb={[
        {
          title: 'Configurações',
        },
        {
          title: 'Promoção',
        },
      ]}
      path={ConstanteRotas.PROMOCAO}
      exact
      meta={{ auth: true }}
    />
  </Route>,
];
