import { Box, Flex, Icon, Text } from '@chakra-ui/react';

import { IconType } from 'icons';

interface OpcaoModalProps {
  title: string;
  icon: IconType;
  onClick: () => void;
}

export const OpcaoModal = ({ onClick, icon, title }: OpcaoModalProps) => {
  return (
    <Box
      borderRadius="16px"
      w={['full', 'full', '128px']}
      _hover={{
        boxShadow: '0px 0px 4px rgba(85, 2, 178, 0.5)',
      }}
      h="144px"
      transition="all 0.2s"
      cursor="pointer"
      boxShadow="0px 0px 16px #00000029"
      onClick={onClick}
    >
      <Flex
        w="full"
        h="full"
        justifyContent="center"
        p="15px"
        alignItems="center"
      >
        <Box h="70px">
          <Flex w="full" justifyContent="center" alignItems="center">
            <Icon fontSize="40px" color="gray.700" as={icon} />
          </Flex>
          <Flex w="full" pt="5px" justifyContent="center" alignItems="center">
            <Text fontSize="16px" textAlign="center" color="gray.700">
              {title}
            </Text>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};
