import React, { useMemo } from 'react';
import {
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalProps,
} from '@chakra-ui/react';

import { useSelecaoFormaPagamentoContext } from 'store/PDV/SelecaoFormaPagamento';
import { usePagamentoContext } from 'store/PDV/Pagamento';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import Layout from 'components/PDV/Layout';

import InternalHeader from './InternalHeader';
import PagamentoFooter from '../Footer';

interface ModalAdicionarPagamentoProps extends ModalProps {
  asMobileView?: boolean;
  handleCancelar: () => void;
}

export function ModalAdicionarPagamento({
  isOpen,
  onClose,
  handleCancelar,
  asMobileView = false,
  children,
  ...rest
}: ModalAdicionarPagamentoProps) {
  const {
    formaPagamentoStep,
    handleSelecionarFormaPagamento,
  } = useSelecaoFormaPagamentoContext();

  const {
    totais: { valorTotal = 0 } = {},
    valorRestante,
  } = usePagamentoContext();

  function handleVoltar() {
    if (formaPagamentoStep === 'selecionandoFormaPagamento') {
      onClose();
    } else {
      handleSelecionarFormaPagamento(undefined);
    }
  }

  const informacoesHeader = useMemo(() => {
    if (formaPagamentoStep === 'informandoValor') {
      return {
        label: 'Falta pagar:',
        value: valorRestante,
      };
    }

    return {
      label: 'Total a pagar:',
      value: valorTotal,
    };
  }, [formaPagamentoStep, valorRestante, valorTotal]);

  if (!asMobileView) {
    return <>{children}</>;
  }

  if (!isOpen) {
    return null;
  }

  return (
    <ModalPadraoChakra
      motionPreset="none"
      size="full"
      {...rest}
      isOpen={isOpen}
      onClose={onClose}
      hasOverlay={false}
      closeOnOverlayClick={false}
      scrollBehavior="inside"
    >
      <ModalContent
        margin="0"
        borderRadius="0"
        overflowX="hidden"
        overflowY="auto"
      >
        <Layout
          NavHeaderContent={
            <ModalHeader p="0">
              <InternalHeader
                label={informacoesHeader.label}
                value={informacoesHeader.value}
                handleVoltar={handleVoltar}
              />
            </ModalHeader>
          }
          FooterContent={<PagamentoFooter handleCancelar={handleCancelar} />}
        >
          <ModalBody p="4" h="full" bg="gray.50">
            {children}
          </ModalBody>
        </Layout>
      </ModalContent>
    </ModalPadraoChakra>
  );
}
