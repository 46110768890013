import {
  Box,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';

import { formatDate } from 'helpers/format/formatStringDate';

import {
  CadastrosVendedoresIcon,
  CaixaAbertoIcon,
  TabelaPrecosIcon,
  ConsignacaoIcon,
  InfoIconCircular,
} from 'icons';
import { ItensConsignados } from 'pages/PDV/ProdutosConsignados/types/validationForms';

import PortalFullscreen from '../Geral/PortalFullscreen';

type MenuDetalhesConsignacaoProps = {
  informacoesDetalheProdutoConsignado: ItensConsignados;
};

export function MenuDetalhesConsignacao({
  informacoesDetalheProdutoConsignado,
}: MenuDetalhesConsignacaoProps) {
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        icon={<Icon as={InfoIconCircular} fontSize="xl" />}
        variant="ghost"
        borderRadius="md"
        size="sm"
        py={0}
        _hover={{
          background: 'none',
        }}
      />
      <PortalFullscreen>
        <MenuList>
          <MenuItem
            p="0"
            pl="18px"
            mt="10px"
            cursor="default"
            display="flex"
            justifyContent="left"
            alignItems="left"
            _hover={{
              background: 'none',
            }}
            _focus={{
              background: 'none',
            }}
          >
            <Box w="10px" mr="20px">
              <Icon as={ConsignacaoIcon} boxSize="5" color="black" />
            </Box>
            <Text fontSize="sm" mt="3px" textTransform="capitalize">
              {formatDate(
                informacoesDetalheProdutoConsignado?.dataHoraConsignacao
              )}
            </Text>
          </MenuItem>
          {informacoesDetalheProdutoConsignado?.contaFinanceira && (
            <MenuItem
              p="0"
              pl="18px"
              mt="10px"
              cursor="default"
              display="flex"
              justifyContent="left"
              alignItems="left"
              _hover={{
                background: 'none',
              }}
              _focus={{
                background: 'none',
              }}
            >
              <Box w="10px" mr="20px">
                <Icon as={CaixaAbertoIcon} boxSize="5" color="black" />
              </Box>
              <Text fontSize="sm" mt="3px" textTransform="capitalize">
                {informacoesDetalheProdutoConsignado?.contaFinanceira}
              </Text>
            </MenuItem>
          )}
          <MenuItem
            p="0"
            pl="18px"
            mt="10px"
            cursor="default"
            display="flex"
            justifyContent="left"
            alignItems="left"
            _hover={{
              background: 'none',
            }}
          >
            <Box w="10px" mr="20px">
              <Icon as={CadastrosVendedoresIcon} boxSize="5" color="black" />
            </Box>
            <Text fontSize="sm" mt="3px" textTransform="capitalize">
              {informacoesDetalheProdutoConsignado?.vendedor}
            </Text>
          </MenuItem>
          <MenuItem
            p="0"
            pl="18px"
            mt="10px"
            cursor="default"
            display="flex"
            justifyContent="left"
            alignItems="left"
            _hover={{
              background: 'none',
            }}
          >
            <Box w="10px" mr="20px">
              <Icon as={TabelaPrecosIcon} boxSize="5" color="black" />
            </Box>
            <Text fontSize="sm" mt="3px" textTransform="capitalize">
              {informacoesDetalheProdutoConsignado?.tabelaPreco}
            </Text>
          </MenuItem>
        </MenuList>
      </PortalFullscreen>
    </Menu>
  );
}
