import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import OperacaoItem from 'types/operacaoItem';
import getHeaderChaveTemporaria from 'helpers/api/getHeaderChaveTemporaria';

interface OperacaoItemAlterar extends OperacaoItem {
  tela: number;
  operacaoId: string;
}

const alterarOperacaoItem = async (
  operacaoItemViewModel: OperacaoItemAlterar,
  chavePermissaoTemporaria?: string
): Promise<boolean> => {
  const response = await api.put<void, ResponseApi<boolean>>(
    ConstanteEnderecoWebservice.PEDIDOORCAMENTOVENDA_ITEM_ALTERAR,
    {
      ...operacaoItemViewModel,
    },
    getHeaderChaveTemporaria(chavePermissaoTemporaria)
  );

  if (response) {
    if (response.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (response.sucesso) {
      return true;
    }
  }

  return false;
};

export default alterarOperacaoItem;
