import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export type BankAccountInfo = {
  nomeConta?: string;
  agencia: string;
  nomeBanco: string;
  numeroConta: string;
  codigoBanco: string;
};

export type ResponsePostBankAccount = {
  statusCredenciamento: 'pending' | 'succeeded' | 'rejected';
};

export type BankAccountList = {
  id: string;
  nome: string;
};

export type Option = {
  value: string;
  label: string;
};

export type Bank = {
  nome: string;
  codigo: string;
};

const schema = yup.object().shape({
  nomeConta: yup.string(),
  codigoBanco: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  agencia: yup.string().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  numeroConta: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);
