import styled from 'styled-components';

import { Button } from '@chakra-ui/react';

export const ButtonFechar = styled(Button)`
  width: 150px;

  margin-left: auto;
  margin-right: 15px;
  font-weight: normal !important;
`;
