import React from 'react';

import SemPermissao from 'pages/SemPermissao';

// import { Container, PermissaoIFrame } from './styles';

const SemPermissaoPlano = () => {
  // A página de callback para restrição de permissões por plano não foi feita ainda.
  // Retornando a página de bloqueio de permissão do usuário momentaneamente.
  return <SemPermissao />;

  // return (
  //   <Container>
  //     <PermissaoIFrame
  //       title="Vantagens da função"
  //       src="https://www.google.com"
  //     />
  //   </Container>
  // );
};

export default SemPermissaoPlano;
