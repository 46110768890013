import { useCallback, useEffect, useState } from 'react';
import { Box, useToken, Text } from '@chakra-ui/react';
import { BeatLoader } from 'react-spinners';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { useProdutosFormularioContext } from 'store/Produtos/ProdutosFormulario';

import { DragDrop } from 'components/DragDrop';
import { VariacoesCoresProps } from 'pages/Produtos/Formulario/FunctionsCadastroProduto/ObterCoresCadastradas';
import { ModalAdicionarImagem } from 'components/Modal/ModalAdicionarImagem';

import { ListImagensResponseProps, ListItemImagem } from '../ListItemImagem';

type ListItemVariacao = {
  variacao: VariacoesCoresProps;
  produtoIsVariacao: boolean;
};

const DefaultValueImage = [
  {
    linkImagem: 'imagem1.jpg',
    isPrincipal: false,
    order: 12,
    notDrag: true,
  },
];

export const ListItemVariacoes = ({
  variacao,
  produtoIsVariacao,
}: ListItemVariacao) => {
  const [isLoading, setIsloading] = useState(false);
  const {
    idProduto,
    listImagens,
    setListImagens,
    handleAtualizarImagemPrincipal,
    inserirImagemPrincipal,
  } = useProdutosFormularioContext();

  const primary50 = useToken('colors', 'primary.50');

  const getProdutoCoresAtualizados = useCallback(
    async (idVariacao: string) => {
      const response = await api.get<
        void,
        ResponseApi<ListImagensResponseProps[]>
      >(
        `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/produto-cores/${idVariacao}/imagens`
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
          return [];
        }

        if (response.sucesso) {
          return response.dados;
        }

        return [];
      }
      return [];
    },
    [idProduto]
  );

  const getProdutoCor = useCallback(
    async (idVariacao: string) => {
      const dados = await getProdutoCoresAtualizados(idVariacao);

      let newListImagens = [] as ListImagensResponseProps[];

      setListImagens((prev) => {
        const data = [
          ...prev,
          ...(dados
            .map((itemVariacao) => {
              const imagemJaExiste = prev.some(
                (item) => item.id === itemVariacao.id
              );

              if (!imagemJaExiste) {
                return {
                  ...itemVariacao,
                  idVariacao,
                } as ListImagensResponseProps;
              }

              return null;
            })
            .filter(Boolean) as ListImagensResponseProps[]),
        ];
        newListImagens = data;
        return data;
      });
      inserirImagemPrincipal(newListImagens);
    },
    [getProdutoCoresAtualizados, inserirImagemPrincipal, setListImagens]
  );

  const atualizarPosicao = useCallback(
    async (idVariacao: string) => {
      const dados = await getProdutoCoresAtualizados(idVariacao);
      setListImagens((prev) =>
        prev.map((itemVariacao) => {
          const imagemJaExiste = dados.some(
            (item) => item.id === itemVariacao.id
          );

          const dadosImagem = dados.find((item) => item.id === itemVariacao.id);

          if (imagemJaExiste) {
            return {
              ...itemVariacao,
              idVariacao,
              sequenciaOrdenacao: dadosImagem?.sequenciaOrdenacao,
            } as ListImagensResponseProps;
          }

          return {
            ...itemVariacao,
          };
        })
      );
    },
    [getProdutoCoresAtualizados, setListImagens]
  );

  const cadastrarImagem = useCallback(
    async (produtoCorId: string, data: ListImagensResponseProps[]) => {
      if (data.length === 0) {
        return;
      }
      setIsloading(true);
      const response = await api.post<
        void,
        ResponseApi<ListImagensResponseProps[]>
      >(
        `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/produto-cores/${produtoCorId}/imagens`,
        data.map((item) => ({
          produtoCorId: item.idVariacao,
          imagem: item.imagem.split('base64,')[1],
          principal: item.principal,
        }))
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }

        if (response.sucesso) {
          await getProdutoCor(data[0].idVariacao || '');
          toast.success('Imagens salvas com sucesso');
        }
        setIsloading(false);
      }
      setIsloading(false);
    },
    [getProdutoCor, idProduto]
  );

  const handleAdicionarImagem = async (newVariacao: {
    name: string;
    id: string;
  }) => {
    const response = await ModalAdicionarImagem({
      valueVariacao: newVariacao.id,
      idProduto,
      listIdVariacao: listImagens.map((item) => item.idVariacao || ''),
    });

    await cadastrarImagem(response.produtoCorId, response.listImagens);
  };

  const handleAlterarSequenciaImagem = useCallback(
    async (itemDraggin: ListImagensResponseProps) => {
      const response = await api.patch<void, ResponseApi<boolean>>(
        `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/produto-cores/${itemDraggin.produtoCorId}/imagens/${itemDraggin?.id}/sequencia/${itemDraggin.sequenciaDrag}`
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }

        if (response.sucesso) {
          toast.success('Sequência alterada com sucesso');
          await atualizarPosicao(itemDraggin.idVariacao || '');
        }
      }
    },
    [idProduto, atualizarPosicao]
  );

  useEffect(() => {
    async function getImagens() {
      setIsloading(true);
      setListImagens([]);
      const responseImagens = await api.get<
        void,
        ResponseApi<ListImagensResponseProps[]>
      >(
        `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/produto-cores/${variacao.id}/imagens`
      );

      if (responseImagens) {
        if (responseImagens.avisos) {
          responseImagens.avisos.forEach((item: string) => toast.warning(item));
        }

        if (responseImagens.sucesso) {
          setListImagens((prev) => [
            ...prev,
            ...responseImagens.dados.map((item) => ({
              ...item,
              idVariacao: variacao.id,
            })),
          ]);
          setIsloading(false);
        }
        setIsloading(false);
      }
      setIsloading(false);
    }

    getImagens();
  }, [idProduto, setListImagens, variacao]);

  return (
    <Box
      py="17px"
      px="60px"
      minH="224px"
      borderBottomWidth="1px"
      borderColor="gray.200"
    >
      <Text
        mt={
          produtoIsVariacao && variacao.cor.padraoSistema === false
            ? undefined
            : '10px'
        }
      >
        {produtoIsVariacao && variacao.cor.padraoSistema === false
          ? variacao.cor.descricao
          : ''}
      </Text>
      {isLoading ? (
        <BeatLoader color={primary50} size={25} />
      ) : (
        <DragDrop
          display="flex"
          dragEnd={(_, itemDraggin) =>
            handleAlterarSequenciaImagem(
              itemDraggin as ListImagensResponseProps
            )
          }
          heightItem="170px"
          flexWrap="wrap"
          items={[
            ...listImagens
              .filter((item) => item.idVariacao === variacao.id)
              .sort((a, b) => a.sequenciaOrdenacao - b.sequenciaOrdenacao),
            ...DefaultValueImage,
          ]}
        >
          {(imagem, index) => (
            <>
              <ListItemImagem
                handleAtualizarImagemPrincipal={handleAtualizarImagemPrincipal}
                handleAdicionarImagem={handleAdicionarImagem}
                imagem={imagem as ListImagensResponseProps}
                index={index}
                iconSize="20px"
                variacao={{
                  id: variacao.cor.id,
                  name: variacao.cor.descricao,
                }}
              />
            </>
          )}
        </DragDrop>
      )}
    </Box>
  );
};
