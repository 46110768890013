import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Flex,
  useMediaQuery,
  Box,
  Text,
  ModalHeader,
  ModalFooter,
  Divider,
  VStack,
  HStack,
  Icon,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';
import { useState } from 'react';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { InputPin } from 'components/update/Input/InputPin';
import { SenhaConferidaIcon, SenhaErroIcon } from 'icons';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

export type FormData = {
  senha: string;
  confirmarSenha: string;
};

type ModalAlterarSenhaProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalProps> & {
    alterarSenhaAdm: (senha: string) => void;
    numeroDeCamposDoPin?: number;
  };
export const ModalAlterarSenha = create<ModalAlterarSenhaProps, ModalProps>(
  ({
    onResolve,
    onReject,
    alterarSenhaAdm,
    numeroDeCamposDoPin = 6,
    ...rest
  }) => {
    const [isLoading, setIsLoading] = useState(false);

    const formMethods = useForm<FormData>();

    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    const { watch, handleSubmit: onSubmit } = formMethods;

    const [senha, confirmarSenha] = watch(['senha', 'confirmarSenha']);

    const senhasEstaoPreenchidas =
      senha?.length === numeroDeCamposDoPin &&
      confirmarSenha?.length === numeroDeCamposDoPin;
    const senhaEstaInvalida =
      senhasEstaoPreenchidas && senha !== confirmarSenha;
    const senhaEstaCorreta = !senhaEstaInvalida && senhasEstaoPreenchidas;
    const isSenhaAdministrativa = numeroDeCamposDoPin === 6;

    const handleSubmit = onSubmit(async (data) => {
      setIsLoading(true);
      alterarSenhaAdm(data.senha);
      onClose();
      setIsLoading(false);
    });

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? 'xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
      >
        <ModalContent
          bg="gray.50"
          w={['full', 'full', '560px']}
          h={['full', 'full', '400px']}
          pb="40px"
          pt="16px"
          px="40px"
        >
          {isLoading && <LoadingPadrao />}
          <FormProvider {...formMethods}>
            <ModalHeader padding="0px">
              <Text fontSize="16px" color="primary.50" fontWeight="700">
                Senha numérica {isSenhaAdministrativa ? '(Smart POS)' : ''}
              </Text>
              <Text fontSize="14px" color="black">
                Informe uma{' '}
                <Text as="span" fontWeight="bold">
                  {' '}
                  senha numérica
                </Text>{' '}
                de {numeroDeCamposDoPin} dígitos.
              </Text>
              <Divider mt="16px" />
            </ModalHeader>
            <ModalBody
              justifyContent="center"
              alignItems="center"
              h="full"
              w="full"
              padding="0px"
            >
              <Flex
                h="full"
                w="full"
                justifyContent="center"
                alignItems="center"
              >
                <Box>
                  <Flex w="full" justifyContent="center" alignItems="center">
                    <Box>
                      <VStack
                        justifyContent="center"
                        px="6px"
                        w="full"
                        gap={['12px', '24px']}
                        mb={
                          senhaEstaInvalida || senhaEstaCorreta ? '0px' : '40px'
                        }
                      >
                        <Box>
                          <HStack alignItems="center">
                            <InputPin
                              name="senha"
                              labelColor="primary.50"
                              borderColor="primary.50"
                              borderWidth="1px"
                              label="Senha:"
                              borderStyle="solid"
                              autoFocus
                              height={['32px', '40px', '40px']}
                              maxWidth={['32px', '40px', '40px']}
                              numeroDeCamposDoPin={numeroDeCamposDoPin}
                              sx={{
                                _placeholderShown: {
                                  bg: 'transparent',
                                  borderColor: 'gray.200',
                                },
                              }}
                              bg="white"
                              _hover={{
                                borderColor: 'primary.50',
                              }}
                              _focus={{
                                borderColor: 'primary.50',
                                bg: 'white',
                              }}
                              isRequired={false}
                            />
                          </HStack>
                        </Box>
                        <Box>
                          <HStack alignItems="center">
                            <InputPin
                              name="confirmarSenha"
                              label="Confirmar senha:"
                              labelColor="primary.50"
                              borderColor={
                                senhaEstaInvalida
                                  ? 'red.500'
                                  : senhaEstaCorreta
                                  ? 'aquamarine.600'
                                  : 'primary.50'
                              }
                              borderWidth="1px"
                              borderStyle="solid"
                              numeroDeCamposDoPin={numeroDeCamposDoPin}
                              sx={{
                                _placeholderShown: {
                                  bg: 'transparent',
                                  borderColor: 'gray.200',
                                },
                              }}
                              bg="white"
                              _hover={{
                                borderColor: senhaEstaInvalida
                                  ? 'red.500'
                                  : senhaEstaCorreta
                                  ? 'aquamarine.600'
                                  : 'primary.50',
                              }}
                              _focus={{
                                borderColor: 'primary.50',
                                bg: 'white',
                              }}
                              isRequired={false}
                              height={['32px', '40px', '40px']}
                              maxWidth={['32px', '40px', '40px']}
                            />
                          </HStack>
                        </Box>
                      </VStack>
                      {senhaEstaInvalida && (
                        <HStack
                          justifyContent="center"
                          mt="20px"
                          alignItems="center"
                        >
                          <Icon as={SenhaErroIcon} boxSize="20px" />
                          <Text
                            color="red.500"
                            fontSize="xs"
                            fontWeight="semibold"
                            mt="20px"
                            letterSpacing="0px"
                          >
                            Senha não confere
                          </Text>
                        </HStack>
                      )}
                      {senhaEstaCorreta && (
                        <HStack
                          alignItems="center"
                          mt="20px"
                          justifyContent="center"
                        >
                          <Icon as={SenhaConferidaIcon} boxSize="20px" />
                        </HStack>
                      )}
                    </Box>
                  </Flex>
                </Box>
              </Flex>
            </ModalBody>
            <ModalFooter justifyContent="center" display="flex" padding="0px">
              <Button
                w="96px"
                mr="24px"
                colorScheme="gray"
                variant="outlineDefault"
                fontSize="14px"
                fontWeight="500"
                borderRadius="full"
                height="32px"
                onClick={onClose}
              >
                Cancelar
              </Button>
              <Button
                w="120px"
                colorScheme="aquamarine"
                variant="solid"
                borderRadius="full"
                fontWeight="500"
                fontSize="14px"
                height="32px"
                isDisabled={!senhasEstaoPreenchidas || senhaEstaInvalida}
                onClick={handleSubmit}
              >
                Confirmar
              </Button>
            </ModalFooter>
          </FormProvider>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
