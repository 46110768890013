import React, { useState, useRef, memo, useCallback, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import { OptionTypeBase, Props as SelectProps } from 'react-select';
import { Controller, FieldError } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import ContainerInputPadrao from 'components/Layout/CampoContainer/CampoContainerPadrao';

import { selectStyles } from '../styles';

export interface TabelaPrecoInterface {
  id: number;
  nome: string;
}

interface SelectTabelaPrecoProps extends SelectProps<OptionTypeBase> {
  id: string;
  name: string;
  label: string;
  required?: boolean;
  infoText?: string;
  control: any;
  error?: FieldError | null;
  getSelectedTabelaPreco: (tabelaPrecoId?: number) => void;
}

const SelectTabelaPreco = ({
  id,
  name,
  label,
  required,
  infoText,
  control,
  error = null,
  placeholder = 'Digite o nome da tabela de preço',
  isLoading: isLoadingProp,
  getSelectedTabelaPreco,
  isDisabled,
  ...rest
}: SelectTabelaPrecoProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [defaultOptions, setDefaultOptions] = useState<OptionTypeBase[]>([]);
  const currentInputValue = useRef('');

  const loadOptions = useCallback(
    async (inputValue: string, callback: any, canSearchForce?: boolean) => {
      if (isDisabled) {
        callback([]);
        return;
      }

      setIsLoading(true);

      const promise = new Promise(() => {
        setTimeout(
          async () => {
            if (currentInputValue.current === inputValue || canSearchForce) {
              const response = await api.get<
                void,
                ResponseApi<TabelaPrecoInterface[]>
              >(ConstanteEnderecoWebservice.TABELA_PRECO_LISTAR_POR_NOME, {
                params: { nome: inputValue },
              });

              if (response?.avisos) {
                response.avisos.map((item: string) => toast.warning(item));
              }

              if (response?.sucesso) {
                callback(
                  response?.dados.map((tabelaPreco: TabelaPrecoInterface) => {
                    return {
                      label: `${tabelaPreco.nome}`,
                      value: tabelaPreco.id,
                    };
                  })
                );
                setIsLoading(false);

                return;
              }
            }

            callback([]);
            setIsLoading(false);
          },
          canSearchForce ? 0 : 500
        );
      });

      // eslint-disable-next-line consistent-return
      return promise;
    },
    [isDisabled]
  );

  const handleSetDefaultOptions = useCallback(() => {
    loadOptions('', (value: OptionTypeBase[]) => {
      setDefaultOptions(value);
    });
  }, [loadOptions]);

  useEffect(() => {
    handleSetDefaultOptions();
  }, [handleSetDefaultOptions]);

  return (
    <ContainerInputPadrao
      id={id}
      label={label}
      error={error}
      required={required}
      infoText={infoText}
    >
      <Controller
        defaultValue=""
        render={({ field }) => (
          <AsyncSelect
            id={id}
            loadOptions={loadOptions}
            ref={field.ref}
            classNamePrefix="react-select"
            isClearable
            isLoading={isLoadingProp || isLoading}
            onInputChange={(newValue: string) => {
              currentInputValue.current = newValue;
            }}
            onChange={(newValue: any) => {
              field.onChange(newValue);
              getSelectedTabelaPreco(
                newValue ? newValue.value || undefined : undefined
              );
            }}
            value={field.value}
            defaultOptions={defaultOptions}
            noOptionsMessage={() => 'Digite o nome da tabela de preço desejada'}
            loadingMessage={() => 'Carregando...'}
            placeholder={placeholder}
            styles={{
              ...selectStyles,
              indicatorSeparator: (base: any) => ({
                ...base,
              }),
            }}
            isDisabled={isDisabled}
            {...rest}
          />
        )}
        // onFocus={() => {
        //   selectRef.current.focus();
        // }}
        name={`${name}` as const}
        control={control}
      />
    </ContainerInputPadrao>
  );
};

export default memo(SelectTabelaPreco);
