import React from 'react';
import { Box, Flex, FormLabel } from '@chakra-ui/react';
import { ColorPicker } from './ColorPicker';

export const Cor = ({ name, label }: { name: string; label: string }) => {
  return (
    <Flex
      pb="16px"
      borderBottom="1px solid #ccc"
      height="fit-content"
      flexWrap="wrap"
      w="full"
      justifyContent="space-between"
      align="baseline"
    >
      <Flex>
        <FormLabel letterSpacing="0px" mr="2px">
          {label}
        </FormLabel>
      </Flex>
      <Box minW="122px" w="122px">
        <ColorPicker name={name} />
      </Box>
    </Flex>
  );
};
