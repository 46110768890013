import React, { useEffect, useCallback, useState } from 'react';
import { Text, VStack, Flex, Icon, Button } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { useFinalizarContext } from 'store/PDV/Finalizar';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';
import ConstanteRotasPDV from 'constants/rotasPDV';
import { EnumTipoTransacao } from 'constants/enum/enumPagamentos';

import { WhatsAppZoopIcon, CopiarIcon, PagamentosBoletoIcon } from 'icons';
import { WhatsAppModal } from 'components/PDV/CompartilharDocumentosFiscais/WhatsAppModal';
import { ModalCompartilharBoletoZoop } from 'components/PDV/Modal/ModalCompartilharBoletoZoop';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { CompartilharLink } from './components/CompartilharLink';

export const LinkPagamento = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    operacao,
    setBoletoZoopFoiGerado,
    boletoZoopFoiGerado,
    erroAoGerarBoletoZoop,
    setErroAoGerarBoletoZoop,
  } = useFinalizarContext();

  const history = useHistory();

  const isPixEnvioZoop = operacao?.pixLink;
  const isBoletoZoop = operacao?.boletoLink;

  const location = window.location.pathname;
  const idOperacao = location.split('/')[location.split('/').length - 1];

  const url = process.env.REACT_APP_MULTIPAY_URL;
  const linkTransacao = `${url}/${
    isPixEnvioZoop ? 'pix' : 'boleto'
  }/${idOperacao}`;
  const cliente = operacao?.cliente;

  const textoLinkGerado = `O ${
    isPixEnvioZoop ? 'link para o pagamento' : 'boleto'
  } foi gerado e pode ser acessado no link abaixo. Você pode acompanhar o status do pagamento ou consultar novamente o link na tela de transações da integração.`;

  const textoErro =
    'Não foi possível gerar o boleto. Você pode tentar novamente no botão abaixo ou gerar um novo link pela tela de transações da integração.';

  const gerarLinkBoleto = useCallback(async () => {
    setIsLoading(true);
    setBoletoZoopFoiGerado(true);
    const response = await api.post<void, ResponseApi>(
      ConstanteEnderecoWebservice.GERAR_TRANSACAO_ZOOP,
      {
        tipo: EnumTipoTransacao.BOLETO,
        idOperacao,
      }
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }
      if (!response.sucesso || response.avisos) {
        setErroAoGerarBoletoZoop(true);
      }
      if (response.sucesso) {
        setErroAoGerarBoletoZoop(false);
        ModalCompartilharBoletoZoop({
          children: (
            <CompartilharLink
              cliente={cliente}
              isBoletoZoop
              linkTransacao={linkTransacao}
            />
          ),
          idOperacao,
        });
      }
    }
    setIsLoading(false);
  }, [
    setBoletoZoopFoiGerado,
    setErroAoGerarBoletoZoop,
    idOperacao,
    cliente,
    linkTransacao,
  ]);

  const handleCopyLink = () => {
    toast.success('Link copiado para a área de transferência');
    navigator.clipboard.writeText(linkTransacao);
  };

  const handleEnviarWhatsApp = async () => {
    if (cliente) {
      WhatsAppModal({
        cliente,
        messageLinkZoop: `Olá ${cliente.nome}! Segue o link para pagamento: ${linkTransacao}`,
      });
    }
  };

  const handlePushNovoPedido = () => {
    history.push(ConstanteRotasPDV.PDV_HOME);
  };

  const handlePushConsultar = () => {
    history.push(ConstanteRotasPDV.PDV_CONSULTAR_OPERACOES);
  };

  const handleOpenLink = () => {
    window.open(linkTransacao, '_blank');
  };

  useEffect(() => {
    if (isBoletoZoop && !boletoZoopFoiGerado) {
      gerarLinkBoleto();
    }
  }, [isBoletoZoop, gerarLinkBoleto, boletoZoopFoiGerado]);

  return (
    <VStack spacing="0px" gap="28px">
      {isLoading && <LoadingPadrao />}
      <VStack
        justify="flex-start"
        gap="6px"
        align="flex-start"
        alignSelf="flex-start"
        px={['12px', '32px']}
        pt="40px"
      >
        <Text fontSize="xl" color="primary.50">
          Link de Pagamento
        </Text>
        <Text fontSize="md" color="gray.700">
          {erroAoGerarBoletoZoop ? textoErro : textoLinkGerado}
        </Text>
      </VStack>
      {!erroAoGerarBoletoZoop && (
        <Flex
          w="full"
          alignItems="flex-start"
          justify="flex-start"
          flexDir="column"
          padding="32px"
          height="258px"
          bg="white"
          gap="2px"
        >
          <Text fontSize="md" color="gray.700">
            Copie e cole o link onde quiser:
          </Text>
          <Flex w={['full', 'full', '700px']} gap="8px" align="center">
            <Text
              bg="gray.50"
              fontSize="md"
              fontWeight="semibold"
              borderWidth="1px"
              borderColor="gray.200"
              borderRadius="5px"
              borderStyle="solid"
              color="violet.500"
              wordBreak="break-all"
              noOfLines={1}
              h="38px"
              maxH="38px"
              w="full"
              px="12px"
              py="8px"
              cursor="pointer"
              onClick={handleOpenLink}
            >
              {linkTransacao}
            </Text>
            <Flex
              w="80px"
              borderRadius="5px"
              bg="primary.50"
              h="38px"
              maxH="38px"
              align="center"
              justify="center"
              _hover={{ bg: 'primary.100' }}
              marginRight="5px"
              cursor="pointer"
              onClick={handleCopyLink}
            >
              <Icon as={CopiarIcon} width="32px" height="24px" color="white" />
            </Flex>
          </Flex>
          <Flex
            fontSize="sm"
            color="gray.700"
            mt="12px"
            w="full"
            gap="48px"
            justify="flex-start"
          >
            <VStack
              cursor="pointer"
              padding="8px"
              borderRadius="5px"
              onClick={handleEnviarWhatsApp}
            >
              <Icon as={WhatsAppZoopIcon} boxSize="56px" />
              <Text>WhatsApp</Text>
            </VStack>
            {isBoletoZoop && (
              <VStack
                cursor="pointer"
                padding="8px"
                borderRadius="5px"
                onClick={handleOpenLink}
              >
                <Icon as={PagamentosBoletoIcon} boxSize="56px" />
                <Text>Visualizar</Text>
              </VStack>
            )}
          </Flex>
        </Flex>
      )}

      <Flex
        justifyContent="center"
        pb="12px"
        flexDir={['column', 'row']}
        gap="24px"
        w="full"
        px="12px"
      >
        <Button
          minW={['full', '120px']}
          variant="outline"
          onClick={handlePushConsultar}
        >
          Consultar
        </Button>
        <Button
          w={['full', '120px']}
          colorScheme="primary"
          color="secondary.300"
          onClick={handlePushNovoPedido}
        >
          Novo pedido
        </Button>
        {erroAoGerarBoletoZoop && (
          <Button
            minW={['full', 'auto']}
            colorScheme="secondary"
            onClick={async () => {
              await gerarLinkBoleto();
            }}
          >
            Tentar novamente
          </Button>
        )}
      </Flex>
    </VStack>
  );
};
