import { useCallback, useState, useEffect } from 'react';
import { BiLink } from 'react-icons/bi';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { IconType } from 'react-icons/lib';
import { toast } from 'react-toastify';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import {
  pendenciaIntegracao,
  tipoPendenciaCadastro,
  tipoPendenciaIntegracao,
} from 'constants/enum/tipoImportacaoIntegracao';
import api, { ResponseApi } from 'services/api';

import { ListaIntegracaoPendenciaPedidoProps } from 'pages/Integracoes/LojaAplicativos/TrayCommerce/PainelAdministradorTray/VendasTray/validationForms';
import { ModalAlterarProduto } from 'pages/EntradaMercadoria/EtapasCompartilhadas/ModalAlterarProduto';

import { ModalIntermediador } from '../ModalEmissaoNotaFiscal/ModalIntermediador';
import { ModalFormaRecebimento } from '../ModalFormaRecebimento';
import { ModalProdutoNaoEncontrado } from '../ModalProdutoNaoEncontrado';
import { ModalSelecionarProduto } from '../ModalSelecionarProduto';
import { ModalValidarSituacao } from '../ModalValidarSituacao';
import { ModalVincularCpf } from '../ModalVincularCpf';

export const useModalPendencia = (
  listPendencia: ListaIntegracaoPendenciaPedidoProps[],
  setListPendencia: React.Dispatch<
    React.SetStateAction<ListaIntegracaoPendenciaPedidoProps[]>
  >,
  setClienteSistemaId: (
    value: React.SetStateAction<string | undefined>
  ) => void,
  onClose: () => void,
  isOpen: boolean,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  finalizarPendenciaVenda: () => Promise<void>
) => {
  const [isReprocessandoPendencia, setIsReprocessandoPendencia] = useState(
    false
  );
  const [animate, setAnimate] = useState(false);
  const [isPendenciaResolvida, setIsPendenciaResolvida] = useState(false);

  const uniquePendency =
    listPendencia.filter((item) => item.clienteFornecedorId === null).length ===
    1;

  const handleNextTela = async () => {
    setAnimate(true);

    setTimeout(() => {
      setIsReprocessandoPendencia(true);
    }, 500);

    setTimeout(() => {
      setAnimate(false);
    }, 1000);
  };

  const handleSubmit = useCallback(async () => {
    if (uniquePendency) {
      setIsLoading(true);
      await finalizarPendenciaVenda();
      setIsLoading(false);
    } else {
      handleNextTela();
    }
  }, [uniquePendency, setIsLoading, finalizarPendenciaVenda]);

  const atualizarPendencia = useCallback(
    async (id: string) => {
      setIsLoading(true);

      setListPendencia((prev) =>
        prev.map((itemPendente) => ({
          ...itemPendente,
          possuiPendencia:
            itemPendente.id === id ? false : itemPendente.possuiPendencia,
        }))
      );
      setIsPendenciaResolvida(true);

      setIsLoading(false);
    },
    [setIsLoading, setListPendencia]
  );

  const handleCadastrarProduto = useCallback(
    async (plataformaId: string, id: string, descricaoProduto: string) => {
      setIsLoading(true);

      const response = await api.post<void, ResponseApi<string>>(
        `${ConstanteEnderecoWebservice.INTEGRACAO_CADASTRAR_PRODUTO}/${plataformaId}`
      );
      if (response) {
        if (response.avisos) {
          response.avisos.forEach((avisos) => toast.warning(avisos));
        }

        if (response.sucesso) {
          ModalProdutoNaoEncontrado({
            id: response.dados,
            atualizarPendencia: () => {
              atualizarPendencia(id);
            },
            descricaoProduto,
          });
        }
        setIsLoading(false);
      }
      setIsLoading(false);
    },

    [atualizarPendencia, setIsLoading]
  );

  const obterClienteFornecedorId = useCallback(
    (id: string) => {
      setClienteSistemaId(id);
    },
    [setClienteSistemaId]
  );

  const handleVincularCpfDuplicado = useCallback(
    async (pendenciaId: string, valueCpf: string, id: string) => {
      await ModalVincularCpf({
        id: pendenciaId,
        valueCpf,
        atualizarPendencia: async () => {
          await atualizarPendencia(id);
        },
        obterClienteFornecedorId,
      });
    },
    [atualizarPendencia, obterClienteFornecedorId]
  );

  const handleUpdateProduto = async (id: string, idProduto: string) => {
    const { sucesso } = await ModalAlterarProduto({
      valueIdProduto: idProduto,
    });
    if (sucesso) {
      atualizarPendencia(id);
    }
  };

  const pendencesIntegracao = {
    [tipoPendenciaIntegracao.NCM]: pendenciaIntegracao.NCM,
    [tipoPendenciaIntegracao.CADASTRO_INATIVO]:
      pendenciaIntegracao.CADASTRO_INATIVO,
  };

  const pendencesCadastro = {
    [tipoPendenciaCadastro.MARKETPLACE]: pendenciaIntegracao.INTERMEDIADOR,
    [tipoPendenciaCadastro.CLIENTE]: pendenciaIntegracao.CPF_DUPLICADO,
    [tipoPendenciaCadastro.PRODUTO]: pendenciaIntegracao.PRODUTO_NAO_CADASTRADO,
    [tipoPendenciaCadastro.SITUACAO]: pendenciaIntegracao.SITUACAO,
    [tipoPendenciaCadastro.FORMA_RECEBIMENTO]:
      pendenciaIntegracao.FORMA_RECEBIMENTO,
  };

  const getPendenceFactory = (tipoPendencia: number, tipoCadastro: number) => {
    if (
      tipoCadastro === tipoPendenciaCadastro.MARKETPLACE ||
      tipoPendencia === tipoPendenciaIntegracao.CADASTROS_VINCULO ||
      tipoPendencia === tipoPendenciaIntegracao.VINCULO ||
      tipoPendencia === tipoPendenciaIntegracao.CADASTRO_PLATAFORMA
    ) {
      return pendencesCadastro[tipoCadastro];
    }
    return pendencesIntegracao[tipoPendencia];
  };

  const optionsPendences = (
    pendences: ListaIntegracaoPendenciaPedidoProps,
    newIcon: (icon?: IconType) => JSX.Element,
    trayId: string
  ) => {
    const {
      tipoPendencia,
      tipoCadastro,
      descricao,
      integracaoPedidoId,
      cadastroPlataformaId,
      id,
      descricaoProduto,
      cadastroSistemaId,
      tipoProduto,
    } = pendences;

    const valuePendence = getPendenceFactory(tipoPendencia, tipoCadastro);

    const componentPendence = {
      [pendenciaIntegracao.CPF_DUPLICADO]: [
        {
          content: 'Escolher Cpf',
          onClick: () =>
            handleVincularCpfDuplicado(
              integracaoPedidoId,
              cadastroPlataformaId,
              id
            ),
        },
      ],
      [pendenciaIntegracao.FORMA_RECEBIMENTO]: [
        {
          icon: newIcon(BiLink),
          content: 'Vincular a forma recebimento',
          onClick: () => {
            ModalFormaRecebimento({
              id,
              formaRecebimento: descricao,
              atualizarPendencia,
              siteId: cadastroPlataformaId,
            });
          },
        },
      ],
      [pendenciaIntegracao.INTERMEDIADOR]: [
        {
          content: 'Identifique o intermediador',
          onClick: () => {
            ModalIntermediador({
              id,
              atualizarPendencia,
              siteId: cadastroPlataformaId,
              trayId,
            });
          },
        },
      ],
      [pendenciaIntegracao.CADASTRO_INATIVO]: [
        {
          content: 'Ativar o produto',
          onClick: () => handleUpdateProduto(id, cadastroSistemaId),
        },
      ],
      [pendenciaIntegracao.NCM]: [
        {
          content: 'Identifique o NCM',
          onClick: () => {
            ModalProdutoNaoEncontrado({
              id: cadastroSistemaId,
              descricaoProduto,
              atualizarPendencia: () => {
                atualizarPendencia(id);
              },
            });
          },
        },
      ],
      [pendenciaIntegracao.PRODUTO_NAO_CADASTRADO]: [
        {
          icon: newIcon(IoMdAddCircleOutline),
          content: 'Criar novo cadastro',
          onClick: () =>
            handleCadastrarProduto(cadastroPlataformaId, id, descricaoProduto),
        },
        {
          icon: newIcon(BiLink),
          content: 'Vincular a cadastro existente',
          onClick: () => {
            ModalSelecionarProduto({
              nameProduto: descricao,
              idVenda: cadastroPlataformaId,
              atualizarPendencia: () => {
                atualizarPendencia(id);
              },
              tipoProduto,
            });
          },
        },
      ],
      [pendenciaIntegracao.SITUACAO]: [
        {
          icon: newIcon(BiLink),
          content: 'Vincule a situação',
          onClick: () => {
            ModalValidarSituacao({
              id,
              formaRecebimento: descricao,
              atualizarPendencia,
              siteId: cadastroPlataformaId,
              trayId,
            });
          },
        },
      ],
    };

    if (componentPendence[valuePendence]) {
      return componentPendence[valuePendence];
    }
    return [
      {
        content: 'Ligar no número (14) 3411-3344',
      },
    ];
  };

  useEffect(() => {
    if (uniquePendency && isPendenciaResolvida) {
      handleSubmit();
    }
  }, [handleSubmit, isPendenciaResolvida, uniquePendency]);

  return {
    optionsPendences,
    handleCadastrarProduto,
    handleSubmit,
    isOpen,
    listPendencia,
    onClose,
    atualizarPendencia,
    isReprocessandoPendencia,
    animate,
    finalizarPendenciaVenda,
  };
};
