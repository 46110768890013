import React, { useState } from 'react';
import {
  ModalContent,
  ModalBody,
  Flex,
  Button,
  Heading,
  Text,
  AlertDialogFooter,
  ScaleFade,
} from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';
import { AtencaoAvisoIcon } from 'icons';
import { create } from 'react-modal-promise';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import ManterFoco from 'components/Geral/ManterFoco';

import { ButtonFechar } from './styles';

const ModalConfirmacaoAnonimoConst = ({ callback }: any) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);

  return (
    <ModalPadraoChakra
      onClose={() => {
        setIsOpen(false);
      }}
      isOpen={isOpen}
      isCentered
      size="xl"
      autoFocus={false}
    >
      <ModalContent
        marginBottom={{ base: 0, sm: '3.75rem' }}
        marginTop={{ base: 0, sm: '3.75rem' }}
        h="unset"
        maxW="600px"
      >
        <ManterFoco
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <ScaleFade initialScale={1.5} in={isOpen}>
            <ModalBody p={0} h="100%">
              <Flex flexDirection="column" align="stretch" h="100%">
                <Flex
                  justifyContent="center"
                  h="150px"
                  bg="orange.400"
                  style={{
                    borderTopLeftRadius: '5px',
                    borderTopRightRadius: '5px',
                  }}
                >
                  <AtencaoAvisoIcon
                    style={{
                      fontSize: '75px',
                      fontWeight: 'bolder',
                      marginTop: '35px',
                    }}
                  />
                </Flex>

                <Flex
                  justifyContent="flex-start"
                  flexDirection="column"
                  bg="gray.50"
                  px={8}
                  py={7}
                  h="250px"
                  style={{
                    borderBottomLeftRadius: '5px',
                    borderBottomRightRadius: '5px',
                  }}
                >
                  <Heading
                    as="h2"
                    fontSize="2xl"
                    color="orange.500"
                    fontWeight="semibold"
                    textAlign="initial"
                  >
                    {t('Tem certeza que deseja anonimizar este cliente?')}
                  </Heading>

                  <Text mt={15} fontSize="14px" color="gray.700">
                    {t(
                      'Esta é uma ação irreversível e todos os dados do cliente e o histórico serão apagados.'
                    )}
                  </Text>

                  <AlertDialogFooter mt="6">
                    <ButtonFechar
                      id="cancelModal"
                      variant="ghost"
                      color="gray.300"
                      border="1px"
                      borderColor="gray.200"
                      _hover={{
                        borderColor: 'gray.700',
                        color: 'gray.700',
                      }}
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      Cancelar
                    </ButtonFechar>
                    <Button
                      id="confirmModal"
                      bg="orange.400"
                      maxW="200px"
                      _hover={{
                        bg: 'orange.200',
                      }}
                      _active={{
                        bg: 'orange.300',
                      }}
                      onClick={() => {
                        setIsOpen(false);
                        callback(true);
                      }}
                      style={{
                        marginRight: 'auto',
                      }}
                    >
                      Sim, Anonimizar!
                    </Button>
                  </AlertDialogFooter>
                </Flex>
              </Flex>
            </ModalBody>
          </ScaleFade>
        </ManterFoco>
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export const ModalAnonimo = create(ModalConfirmacaoAnonimoConst);
