import { useCallback } from 'react';
import { Box, useMediaQuery } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import auth from 'modules/auth';
import { setDateMaxHours, setDateMinHours } from 'helpers/data/setHoursDate';
import { useMetasComissoesHistoricoVendasContext } from 'store/MetasComissoes/MetasComissoesHistoricoVendas';
import ConstanteFuncionalidades from 'constants/permissoes';
import ConstanteRotas from 'constants/rotas';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { formatEmailsToSend } from 'helpers/format/email';

import { ModalCompartilhar } from 'components/Modal/ModalCompartilhar';
import { EmailModal } from 'components/PDV/CompartilharDocumentosFiscais/EmailModal';

import { HistoricoVendedorComPermissao } from './HistoricoVendedorComPermissao';
import { HistoricoVendedorSemPermissao } from './HistoricoVendedorSemPermissao';
import { handleImprimirRelatorioHistoricoVendas } from '../ComissaoVendedores/HistoricoVenda/Impressao/imprimirRelatorio';

export const formDefaultValues = {
  todosVendedores: null,
  dataHistoricoInicio: setDateMinHours(new Date()),
  dataHistoricoFim: setDateMaxHours(new Date()),
  dataHistorico: new Date(),
  mesAno: new Date(),
};

type VendedoresProps = { value: string; label: string };

export type FormData = {
  todosVendedores: VendedoresProps | null;
  dataHistoricoInicio: Date;
  dataHistoricoFim: Date;
  dataHistorico: Date;
  mesAno: Date;
};

export const HistoricoVendasMetasComissoes = () => {
  const formMethods = useForm<FormData>({
    defaultValues: { mesAno: new Date() },
  });

  const history = useHistory();

  const possuiPermissaoMetaComissao = auth.possuiPermissao(
    ConstanteFuncionalidades.METAS_COMISSAO
  ).permitido;

  const [isLargerThan1200] = useMediaQuery('(min-width: 1200px)');
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const handleVoltarParaMetasComissoes = () => {
    history.push(ConstanteRotas.METAS_E_COMISSOES);
  };

  const {
    registroPaginacao,
    vendedorVinculado,
  } = useMetasComissoesHistoricoVendasContext();

  const filtrosPaginacao = useCallback(() => {
    const filtros = {
      paginaAtual: registroPaginacao.currentPage,
      tamanhoPagina: registroPaginacao.pageSize,
      campoOrdenacao: registroPaginacao.orderColumn,
      direcaoOrdenacao: registroPaginacao.orderDirection,
      mesAno: formMethods.watch('mesAno'),
      vendedorId: possuiPermissaoMetaComissao
        ? formMethods.watch('todosVendedores')?.value
        : vendedorVinculado.id,
    };
    return filtros;
  }, [
    formMethods,
    registroPaginacao,
    possuiPermissaoMetaComissao,
    vendedorVinculado.id,
  ])();

  const formatFiltersForSharing = useCallback(() => {
    const filters = {
      paginaAtual: filtrosPaginacao.paginaAtual,
      tamanhoPagina: filtrosPaginacao.tamanhoPagina,
      campoOrdenacao: filtrosPaginacao.campoOrdenacao,
      direcaoOrdenacao: filtrosPaginacao.direcaoOrdenacao,
      mesAno: filtrosPaginacao.mesAno.toISOString(),
      ...(filtrosPaginacao?.vendedorId && {
        vendedorId: filtrosPaginacao.vendedorId,
      }),
    };
    return filters;
  }, [
    filtrosPaginacao.campoOrdenacao,
    filtrosPaginacao.direcaoOrdenacao,
    filtrosPaginacao.mesAno,
    filtrosPaginacao.paginaAtual,
    filtrosPaginacao.tamanhoPagina,
    filtrosPaginacao.vendedorId,
  ]);

  const sendEmail = useCallback(
    async (emails: string[]) => {
      const response = await api.get<void, ResponseApi<boolean>>(
        `${
          ConstanteEnderecoWebservice.ENVIAR_EMAIL_METAS_HISTORICO_VENDAS
        }?${new URLSearchParams(
          Object(formatFiltersForSharing())
        ).toString()}${formatEmailsToSend(emails)}`
      );
      if (response) {
        if (response?.sucesso) {
          toast.success('O e-mail foi enviado com sucesso.');
        }
        if (response?.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }
      }
    },
    [formatFiltersForSharing]
  );

  const handleEnviarEmailHistoricoVendas = useCallback(async () => {
    await EmailModal({
      getEmail: async (emails: string[]) => {
        sendEmail(emails);
      },
    });
  }, [sendEmail]);

  const handleImprimirOperacao = useCallback(() => {
    ModalCompartilhar({
      items: [
        {
          titulo: 'Imprimir histórico venda',
          onClickImpressao: (onClose) => {
            handleImprimirRelatorioHistoricoVendas({
              filtrosPaginacao,
              nameFile: 'relatórioHistóricoVendas',
            });
            onClose();
          },
          onClickEmail: (onClose) => {
            handleEnviarEmailHistoricoVendas();
            onClose();
          },
        },
      ],
    });
  }, [filtrosPaginacao, handleEnviarEmailHistoricoVendas]);

  return (
    <FormProvider {...formMethods}>
      <Box
        bg="gray.50"
        overflow="scroll"
        sx={{
          '&::-webkit-scrollbar': {
            height: '0',
            width: '0',
          },
          '& .virtualized_List::-webkit-scrollbar': {
            height: '0',
            width: '0',
          },
        }}
        h="100vh"
        pb="30px"
        pt="24px"
        pl={isLargerThan1200 ? '40px' : isLargerThan900 ? '24px' : '16px'}
        pr={isLargerThan1200 ? '40px' : isLargerThan900 ? '24px' : '16px'}
      >
        {possuiPermissaoMetaComissao ? (
          <HistoricoVendedorComPermissao
            handleImprimirOperacao={handleImprimirOperacao}
            handleVoltarParaMetasComissoes={handleVoltarParaMetasComissoes}
          />
        ) : (
          <HistoricoVendedorSemPermissao
            handleImprimirOperacao={handleImprimirOperacao}
            handleVoltarParaMetasComissoes={handleVoltarParaMetasComissoes}
          />
        )}
      </Box>
    </FormProvider>
  );
};
