import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

import api, { ResponseApi } from 'services/api';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import isPrenvent from 'helpers/layout/isPrenvent';
import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import RegraLimiteCreditoEnum from 'constants/enum/regraLimiteCredito';
import useReloadRegistration from 'helpers/layout/useReloadRegistration';

import { ContainerListagem } from 'components/Layout/Listagem/containerListagem';

import { yupResolver, FormData } from '../validationForm';
import { UncontrolledForm } from '..';

const Cadastro = () => {
  const history = useHistory();
  const isMountedRef = useIsMountedRef();

  const [isLoading, setIsLoading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues: {
      ativo: true,
      diasAtrasoBloqueio: 1,
      descricao: '',
      regraLimiteCredito: {
        value: RegraLimiteCreditoEnum.properties[2].value,
        label: RegraLimiteCreditoEnum.properties[2].name,
        color: RegraLimiteCreditoEnum.properties[2].iconColor,
      },
    },
  });
  const { handleSubmit, reset, getValues, formState, setFocus } = formMethods;

  const historyReload = useReloadRegistration({ setFormIsDirty });
  isPrenvent(formIsDirty);

  const handlePostCategoriaCliente = useCallback(async () => {
    const data = getValues();
    const dadosEnviarApi = {
      ativo: data.ativo,
      descricao: data.descricao,
      diasAtrasoBloqueio:
        data.regraLimiteCredito.value ===
        RegraLimiteCreditoEnum.BLOQUEIO_DIAS_ATRASO
          ? data.diasAtrasoBloqueio
          : 0,
      regraLimiteCredito: data.regraLimiteCredito.value,
    };

    const response = await api.post<void, ResponseApi>(
      ConstanteEnderecoWebservice.CATEGORIA_CLIENTE_CADASTRAR,
      dadosEnviarApi
    );

    if (response) {
      if (response.sucesso) {
        return true;
      }

      if (response.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }
    }

    return false;
  }, [getValues]);

  const onSubmit = handleSubmit(async () => {
    setIsLoading(true);

    const success = await handlePostCategoriaCliente();

    if (success) {
      toast.success('O cadastro foi realizado com sucesso.');

      setFormIsDirty(false);

      history.push(ConstanteRotas.CATEGORIA_CLIENTE);
    }

    setIsLoading(false);
  });

  const onSubmitReset = handleSubmit(async () => {
    setIsLoading(true);

    const success = await handlePostCategoriaCliente();

    if (success) {
      toast.success('O cadastro foi realizado com sucesso.');
      if (isMountedRef.current) setFocus('descricao');

      historyReload(ConstanteRotas.CATEGORIA_CLIENTE_CADASTRAR);
    }

    setIsLoading(false);
  });

  useEffect(() => {
    reset({
      descricao: '',
      ativo: true,
      regraLimiteCredito: {
        value: RegraLimiteCreditoEnum.properties[2].value,
        label: RegraLimiteCreditoEnum.properties[2].name,
        color: RegraLimiteCreditoEnum.properties[2].iconColor,
      },
      diasAtrasoBloqueio: 1,
    });
  }, [reset]);

  useEffect(() => {
    setFormIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  return (
    <ContainerListagem
      formMethods={formMethods}
      isLoading={isLoading}
      onSubmit={onSubmit}
      onSubmitReset={onSubmitReset}
      formIsDirty={formIsDirty}
    >
      <UncontrolledForm />
    </ContainerListagem>
  );
};

export default Cadastro;
