import { GridItem } from '@chakra-ui/react';

import Input from 'components/PDV/Input';
import CheckboxAtivoInativo from 'components/update/Checkbox/CheckboxAtivoInativo';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';

export interface MarcaInterface {
  id: string;
  nome: string;
  ativo: boolean;
  dataHoraCadastro: string;
  dataHoraUltimaAlteracao: string;
}

interface UncontrolledFormInterface {
  readonly?: boolean;
}

const UncontrolledForm = ({ readonly }: UncontrolledFormInterface) => {
  return (
    <SimpleGridForm>
      <GridItem colSpan={{ base: 12, md: 10, lg: 10 }}>
        <Input
          id="nome"
          name="nome"
          label="Nome"
          autoFocus
          isRequired
          placeholder="Digite um nome para diferenciar a marca"
          maxLength={40}
          isDisabled={readonly}
        />
      </GridItem>
      <GridItem colSpan={{ base: 12, md: 2, lg: 2 }}>
        <CheckboxAtivoInativo
          id="ativo"
          name="ativo"
          label="Status"
          isDisabled={readonly}
        />
      </GridItem>
    </SimpleGridForm>
  );
};

export default UncontrolledForm;
