import { useCallback, useRef, useState } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  ModalFooter,
  ModalHeader,
  useMediaQuery,
  Divider,
  Image,
} from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import Webcam from 'react-webcam';
import { IoMdReverseCamera, IoMdCamera } from 'react-icons/io';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import {
  byteToSize,
  calcDataUriBytesSize,
  dataURItoBase64,
  isDataURI,
  testIfIsUrl,
} from 'helpers/validation/imagesAttachment';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { ButtonDefault } from 'components/Button/ButtonChakra';

type FormData = {
  linkImage: string;
  isForaPadrao: boolean;
};

type ModalTirarFotoProps = Omit<ModalProps, 'children' | 'isOpen' | 'onClose'> &
  InstanceProps<FormData>;

export const ModalTirarFoto = create<ModalTirarFotoProps, FormData>(
  ({ onResolve, onReject, children, ...rest }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [cameraFrontal, setCameraFrontal] = useState(!isMobile);

    const webcamRef = useRef(null);

    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

    const formMethods = useForm<FormData>();

    const { handleSubmit: onSubmit, watch, setValue } = formMethods;

    const linkImageWatch = watch('linkImage');

    const videoConstraints = {
      width: 500,
      height: 500,
      facingMode: cameraFrontal ? 'user' : { exact: 'environment' },
    };

    const handleAlterarCamera = useCallback(() => {
      setCameraFrontal(!cameraFrontal);
    }, [cameraFrontal]);

    const formattedValue = useCallback((value: string) => {
      if (!testIfIsUrl(value)) return `data:image/jpeg;base64,${value}`;

      return value;
    }, []);

    const handleCapture = useCallback(() => {
      const dataUrlImage = ((webcamRef.current as unknown) as Webcam).getScreenshot(
        { height: 500, width: 500 }
      );

      if (dataUrlImage && isDataURI(dataUrlImage)) {
        const bytesSize = calcDataUriBytesSize(dataUrlImage);

        if (byteToSize(bytesSize, 'KB') > 500) {
          setValue('linkImage', dataURItoBase64(dataUrlImage));
          setValue('isForaPadrao', true);

          return;
        }

        setValue('linkImage', formattedValue(dataURItoBase64(dataUrlImage)));
        setValue('isForaPadrao', false);
      } else {
        toast.warning(
          'Não foi possível tirar a foto, por favor tente novamente'
        );
      }
    }, [setValue, formattedValue]);

    const handleSubmit = onSubmit((data) => {
      setIsLoading(true);
      onResolve(data);
      setIsLoading(false);
      onClose();
    });

    return (
      <ModalPadraoChakra
        isCentered
        size={isLargerThan700 ? '5xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
      >
        <ModalContent
          bg="gray.50"
          borderRadius="md"
          marginBottom="3.75rem"
          marginTop="3.75rem"
          w={['undefined', '960px']}
          h={['undefined', '560px']}
        >
          <ModalHeader pt="16px" pb="0" pl="0" pr="0">
            <Flex px="24px" justifyContent="space-between">
              <Text color="primary.50" fontSize="16px">
                Adicionar imagem
              </Text>
              {isMobile && (
                <Flex cursor="pointer">
                  {cameraFrontal ? (
                    <IoMdCamera onClick={handleAlterarCamera} />
                  ) : (
                    <IoMdReverseCamera onClick={handleAlterarCamera} />
                  )}
                </Flex>
              )}
            </Flex>

            <Divider color="gray.200" mt="16px" />
          </ModalHeader>

          <FormProvider {...formMethods}>
            {isLoading && <LoadingPadrao />}
            <ModalBody
              h={['100%', '400px']}
              pl="0"
              pr="0"
              pt="0"
              justifyContent={['center', 'undefined']}
              display={['flex', 'undefined']}
              pb="0"
            >
              <Flex
                mt={['10px', '0']}
                w="full"
                justifyContent="center"
                bg="black"
                h="100%"
              >
                {linkImageWatch ? (
                  <Image
                    w={['full', '435px']}
                    h="full"
                    src={linkImageWatch || ''}
                  />
                ) : (
                  <Webcam
                    height="full"
                    width={500}
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                  />
                )}
              </Flex>
            </ModalBody>
            <ModalFooter
              pt="0"
              h={['125px', '78px']}
              px="0"
              flexDirection="column"
              mt={['15px', '5px']}
            >
              <Flex
                w="full"
                h="full"
                px="24px"
                justifyContent={['center', 'end']}
                alignItems="center"
              >
                <Flex flexDirection={['column', 'row']}>
                  <Button
                    variant="outlineDefault"
                    borderRadius="20px"
                    colorScheme="gray"
                    h="32px"
                    mr={['0', '24px']}
                    onClick={() => {
                      if (linkImageWatch) {
                        setValue('linkImage', '');
                      } else {
                        handleCapture();
                      }
                    }}
                    w={['100vw', '142px']}
                  >
                    {linkImageWatch ? 'Tirar outra foto' : 'Tirar foto'}
                  </Button>
                  <Button
                    variant="outlineDefault"
                    borderRadius="20px"
                    colorScheme="gray"
                    h="32px"
                    my={['5px', '0']}
                    mr={['0', '24px']}
                    onClick={() => onClose()}
                    w={['100vw', '96px']}
                  >
                    Cancelar
                  </Button>
                  <ButtonDefault
                    color="white"
                    variant="solid"
                    colorScheme="aquamarine.600"
                    lineHeight="0"
                    borderRadius="20px"
                    isDisabled={linkImageWatch === undefined}
                    fontSize="sm"
                    h="32px"
                    w={['100vw', '110px']}
                    onClick={() => handleSubmit()}
                    isLoading={isLoading}
                  >
                    Confirmar
                  </ButtonDefault>
                </Flex>
              </Flex>
            </ModalFooter>
          </FormProvider>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
