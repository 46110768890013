import { Button, Icon, useMediaQuery, Box, Flex } from '@chakra-ui/react';

import { SalvarConfirmarIcon, SalvarInserirNovoIcon } from 'icons';
import { DataExibindoCriacaoAlteracao } from 'components/Geral/DataExibindoCriacaoAlteracao';

interface RodapeFormularioProps {
  onSubmit?: () => void;
  textSubmit?: string;
  onSubmitReset?: () => void;
  textSubmitReset?: string;
  iconSubmitReset?: any;
  iconSubmit?: boolean;
  disabled?: boolean;
  isDisabledReset?: boolean;
  dataHoraCriacao?: string;
  dataHoraUltimaAlteracao?: string;
  onCancel?: () => void;
  labelOnCancel?: string;
}

const RodapeFormulario = ({
  onSubmit,
  onSubmitReset,
  disabled,
  isDisabledReset,
  dataHoraCriacao,
  dataHoraUltimaAlteracao,
  iconSubmit = true,
  textSubmit,
  textSubmitReset,
  iconSubmitReset,
  onCancel,
  labelOnCancel = 'Desistir do cadastro',
}: RodapeFormularioProps) => {
  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');
  return (
    <Box w="full" pb="10px">
      {dataHoraCriacao && dataHoraUltimaAlteracao && (
        <DataExibindoCriacaoAlteracao
          dataHoraCriacao={dataHoraCriacao}
          dataHoraUltimaAlteracao={dataHoraUltimaAlteracao}
        />
      )}
      {(onSubmitReset || onSubmit) && (
        <Flex
          direction={isSmallerThan900 ? 'column' : 'row'}
          mt="50px"
          justifyContent="center"
          alignItems="center"
        >
          {onSubmitReset && (
            <Button
              id="salvarInserirNovo"
              variant="outlineDefault"
              borderRadius="16px"
              colorScheme="gray"
              mb={isSmallerThan900 ? '15px' : undefined}
              mr={isSmallerThan900 ? undefined : '30px'}
              w={isSmallerThan900 ? 'full' : '225px'}
              name="salvarInserirNovo"
              leftIcon={
                <Icon
                  as={iconSubmitReset || SalvarInserirNovoIcon}
                  fontSize="lg"
                />
              }
              disabled={disabled || isDisabledReset}
              isLoading={disabled}
              onClick={(event) => {
                if (event.detail === 1 && onSubmitReset) {
                  onSubmitReset();
                }
              }}
            >
              {textSubmitReset || 'Salvar e inserir novo'}
            </Button>
          )}
          {onCancel && (
            <Button
              id="cancel"
              variant="outlineDefault"
              mb={isSmallerThan900 ? '15px' : undefined}
              mr={isSmallerThan900 ? undefined : '30px'}
              w={isSmallerThan900 ? 'full' : '170px'}
              disabled={disabled}
              isLoading={disabled}
              colorScheme="gray"
              onClick={(event) => {
                if (event.detail === 1 && onCancel) {
                  onCancel();
                }
              }}
            >
              {labelOnCancel}
            </Button>
          )}

          {onSubmit && (
            <Button
              w={isSmallerThan900 ? 'full' : '175px'}
              borderRadius="16px"
              color="white"
              variant="solid"
              colorScheme="aquamarine.600"
              leftIcon={
                iconSubmit ? (
                  <Icon as={SalvarConfirmarIcon} fontSize="lg" />
                ) : undefined
              }
              disabled={disabled}
              isLoading={disabled}
              onClick={(event) => {
                if (event.detail === 1 && onSubmit) {
                  onSubmit();
                }
              }}
            >
              {textSubmit || 'Salvar'}
            </Button>
          )}
        </Flex>
      )}
    </Box>
  );
};

export default RodapeFormulario;
