import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export const defaultValues = {
  foto: null,
  nome: '',
  telefone: null,
  telefoneWhatsapp: false,
  email: null,
  comissao: 0,
  metaMensal: 0,
  ativo: true,
  dataNascimento: null,
  genero: 3,
};

const schema = yup.object().shape({
  foto: yup.string().nullable().default(null),
  nome: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .max(50, ConstanteMensagemValidacao.MAX_LENGTH_50),
  telefone: yup
    .string()
    .nullable()
    .default(null)
    .notRequired()
    .test('telefone', ConstanteMensagemValidacao.TELEFONE_INVALIDO, (value) => {
      if (value) {
        const testSchema = yup.string().min(14).max(15);

        return testSchema.isValidSync(value);
      }

      return true;
    }),
  telefoneWhatsapp: yup.boolean().default(false),
  email: yup
    .string()
    .nullable()
    .default(null)
    .email(ConstanteMensagemValidacao.EMAIL_INVALIDO)
    .max(60, ConstanteMensagemValidacao.MAX_LENGTH_60),
  ativo: yup.boolean().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  dataNascimento: yup.string().nullable().default(null),
  genero: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(3),
});

export const yupResolver = yupResolverInstance(schema);

export const useForm = useFormInstance;
