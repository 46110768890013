import React, { useEffect, useState, useCallback } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory, Prompt } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import isPrenvent from 'helpers/layout/isPrenvent';
import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import CampoPersonalizadoInterface from 'types/campoPersonalizado';
import CampoPersonalizadoValorInterface from 'types/campoPersonalizadoValor';
import TipoCadastroCampoPersonalizadoEnum from 'constants/enum/tipoCadastroCampoPersonalizado';
import { cnpjMask, cpfMask } from 'helpers/format/fieldsMasks';
import useReloadRegistration from 'helpers/layout/useReloadRegistration';

import { ContainerListagem } from 'components/Layout/Listagem/containerListagem';

import {
  useForm,
  yupResolverCliente,
  yupResolverFornecedor,
} from '../validationForm';

import UncontrolledForm from '..';

interface CadastrarProps {
  rotaCliente?: boolean;
  cadastroPdv?: boolean;
  nomeOuCpfCnpjCliente?: string;
  cadastroSucessoCallback?: (cliente: any) => void;
}

const Cadastro = ({
  rotaCliente,
  nomeOuCpfCnpjCliente,
  cadastroPdv = false,
  cadastroSucessoCallback,
}: CadastrarProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [camposPersonalizados, setCamposPersonalizados] = useState<
    CampoPersonalizadoInterface[]
  >([]);

  const formMethods = useForm({
    resolver: rotaCliente ? yupResolverCliente : yupResolverFornecedor,
    shouldUnregister: false,
  });
  const {
    handleSubmit,
    register,
    control,
    reset,
    getValues,
    formState,
    setError,
    setValue,
    watch,
  } = formMethods;

  const historyReload = useReloadRegistration({ setFormIsDirty });
  isPrenvent(formIsDirty);

  async function handlePostClienteFornecedor() {
    const data = getValues();

    const listCampoPersonalizado = data?.camposPersonalizados?.filter(Boolean);

    const viewModel = {
      possuiInscricaoEstadual: data.possuiInscricao || false,
      Ativo: data.ativo,
      tipoCadastroPessoa: rotaCliente ? 1 : 2,
      CpfCnpj: data.cpfCnpj || null,
      ieisento: data.rgInscricaoEstadualisento || false,
      RgInscricaoEstadual: data.rgInscricaoEstadual || null,
      Nome: data.nome,
      Foto: data.foto || null,
      Apelido: data.apelido || null,
      Genero: data.genero,
      Identificador: data.identificador || null,
      DataNascimento: data.dataNascimento,
      SitePerfil: data.sitePerfil || null,
      Telefone: data.telefone || null,
      Celular: data.celular || null,
      Email: data.email || null,
      EmailNFe: data.emailNfe || null,
      Observacao: data.observacao || null,
      LimiteCredito: rotaCliente && (data.limiteCredito || 0),
      ConsumidorFinal: data.consumidorFinal || false,
      ContribuinteIcms: data.contribuinteIcms || false,
      setorEntregaId: data.setorEntrega?.value,
      setorEntregaNome: data.setorEntrega?.label,
      CamposPersonalizados:
        rotaCliente && listCampoPersonalizado
          ? listCampoPersonalizado
              .filter(
                ({
                  campoPersonalizadoId,
                  valor,
                }: CampoPersonalizadoValorInterface) =>
                  campoPersonalizadoId &&
                  valor !== undefined &&
                  valor !== 'undefined' &&
                  valor !== null &&
                  valor !== 'null' &&
                  String(valor)
              )
              .map(
                ({
                  campoPersonalizadoId,
                  valor,
                }: CampoPersonalizadoValorInterface) => ({
                  campoPersonalizadoId,
                  valor: String(valor),
                })
              )
          : null,
      CategoriaClienteId:
        rotaCliente && data.categoriaCliente
          ? data.categoriaCliente.value
          : null,
      TelefoneWhatsapp: data.telefoneWhats || false,
      CelularWhatsapp: data.celularWhats || false,
      tabelaPrecoId:
        rotaCliente && data.tabelaPreco !== null
          ? data.tabelaPreco?.value || null
          : null,
      Suframa:
        rotaCliente && (data.suframa || data.suframa === 0)
          ? data.suframa
          : null,
      DocumentoEstrangeiro: data.documentoEstrangeiro || null,
      CodigoExterno: data.codigoExterno || null,
      CadastroAnonimo: data.cadastroAnonimo || false,
      Enderecos:
        data.enderecosAdicionais?.map((endereco: any) => {
          return {
            Logradouro: endereco.logradouro || null,
            Numero: endereco.numero || null,
            Complemento: endereco.complemento || null,
            Bairro: endereco.bairro || null,
            Cep: endereco.cep || null,
            CidadeId: endereco.cidade,
            PaisId: endereco.pais,
            Principal: false,
            Cobranca: endereco.cobranca || false,
            Entrega: endereco.entrega || false,
          };
        }) || [],
      ContatosAdicionais:
        data.contatosAdicionais?.map((contato: any) => {
          return {
            Nome: contato.Nome || null,
            Telefone: contato.Telefone || null,
            Email: contato.Email || null,
            WhatsApp: contato.WhatsApp || false,
          };
        }) || [],
    };
    const enderecoprincipal = {
      Logradouro: data.logradouro || null,
      Numero: data.numero || null,
      Complemento: data.complemento || null,
      Bairro: data.bairro || null,
      Cep: data.cep || null,
      CidadeId: data.cidade.value,
      PaisId: data.pais.value,
      Principal: true,
      Cobranca: false,
      Entrega: false,
    };

    viewModel.Enderecos.push(enderecoprincipal);
    const location = window.location.pathname;
    const nameCurrentOperationPdv = location.includes('pdv');

    const response = await api.post<void, ResponseApi<any>>(
      rotaCliente
        ? nameCurrentOperationPdv
          ? ConstanteEnderecoWebservice.CLIENTE_CADASTRAR
          : ConstanteEnderecoWebservice.CLIENTE_CADASTRAR_FORA_PDV
        : nameCurrentOperationPdv
        ? ConstanteEnderecoWebservice.FORNECEDOR_CADASTRAR
        : ConstanteEnderecoWebservice.FORNECEDOR_CADASTRAR_MODAL,
      viewModel
    );

    if (response.sucesso) {
      setValue('cep', '');
      setValue('logradouro', '');
      setValue('numero', '');
      setValue('complemento', '');
      setValue('bairro', '');

      if (cadastroPdv && cadastroSucessoCallback)
        cadastroSucessoCallback(response.dados);

      return true;
    }

    if (response.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    return false;
  }

  const onSubmit = handleSubmit(async () => {
    setIsLoading(true);

    const success = await handlePostClienteFornecedor();

    if (success) {
      toast.success('O cadastro foi realizado com sucesso.');

      if (!cadastroPdv) {
        setFormIsDirty(false);

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        rotaCliente
          ? history.push(ConstanteRotas.CLIENTE)
          : history.push(ConstanteRotas.FORNECEDOR);
      }
    }

    setIsLoading(false);
  });

  const onSubmitReset = handleSubmit(async () => {
    setIsLoading(true);

    const success = await handlePostClienteFornecedor();

    if (success) {
      toast.success(t('O cadastro foi realizado com sucesso.'));
      historyReload(
        rotaCliente
          ? ConstanteRotas.CLIENTE_CADASTRAR
          : ConstanteRotas.FORNECEDOR_CADASTRAR
      );
    }

    setIsLoading(false);
  });

  const retornarCpfCnpj = useCallback(() => {
    const unformattedValue = nomeOuCpfCnpjCliente?.replace(/\D/g, '');

    if (unformattedValue) {
      if (unformattedValue.length <= 11) {
        return cpfMask(unformattedValue);
      }

      return cnpjMask(unformattedValue);
    }

    return undefined;
  }, [nomeOuCpfCnpjCliente]);

  useEffect(() => {
    if (cadastroPdv) {
      const cpfCnpj = retornarCpfCnpj();

      reset({
        ativo: true,
        nome: !cpfCnpj ? nomeOuCpfCnpjCliente : '',
        cpfCnpj,
        genero: 3,
        consumidorFinal: true,
      });
    } else {
      reset({
        ativo: true,
        genero: 3,
        consumidorFinal: true,
      });
    }
  }, [cadastroPdv, nomeOuCpfCnpjCliente, reset, retornarCpfCnpj]);

  useEffect(() => {
    setFormIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  useEffect(() => {
    const handleGetCamposPersonalizados = async () => {
      const response = await api.get<
        void,
        ResponseApi<CampoPersonalizadoInterface[]>
      >(ConstanteEnderecoWebservice.CAMPO_PERSONALIZADO_LISTARPORTIPOCADASTRO, {
        params: { tipoCadastro: TipoCadastroCampoPersonalizadoEnum.CLIENTE },
      });

      if (response?.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }

      if (response && response.sucesso) {
        setCamposPersonalizados(response.dados);
      }
    };

    handleGetCamposPersonalizados();
  }, []);

  return (
    <ContainerListagem
      formMethods={formMethods}
      isLoading={isLoading}
      onSubmit={onSubmit}
      onSubmitReset={onSubmitReset}
      formIsDirty={formIsDirty}
      maxWidth="full"
    >
      <UncontrolledForm
        errors={formState.errors}
        control={control}
        register={register}
        setValue={setValue}
        getValue={getValues}
        setError={setError}
        watch={watch}
        camposPersonalizados={camposPersonalizados}
        rotaCliente={rotaCliente}
        cadastroPdv={cadastroPdv}
        readonly={false}
        isCadastrar
      />
    </ContainerListagem>
  );
};

export default Cadastro;
