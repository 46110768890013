import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

type SelectValue = { value: string; label: string };

export type FormData = {
  lojaOrigem: string;
  localEstoqueOrigem?: SelectValue;
  lojaDestino?: SelectValue;
  localEstoqueDestino?: SelectValue;
  usuario: string;
  dataEmissao: string;
  motivo?: string;
  dataRecebimento?: string;
  usuarioRecebimento?: string;
};

const schema = yup.object().shape({
  lojaOrigem: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(''),
  localEstoqueOrigem: yup
    .object()
    .shape({
      value: yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .default(null),
      label: yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .default(null),
    })
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(null),
  lojaDestino: yup
    .object()
    .shape({
      value: yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .default(null),
      label: yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .default(null),
    })
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(null),
  localEstoqueDestino: yup
    .object()
    .shape({
      value: yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .default(null),
      label: yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .default(null),
    })
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(null),
  motivo: yup.string().nullable(),
});

export const yupResolver = yupResolverInstance(schema);
