import React from 'react';
import { Flex, Text, HStack } from '@chakra-ui/react';
import formatData from 'helpers/format/formatData';
import { moneyMask } from 'helpers/format/fieldsMasks';

interface Pagamentos {
  pagamento: Array<Recebimentos>;
}

interface Recebimentos {
  parcela: string;
  dataVencimento: string;
  descricao: string;
  valor: number;
  cupomTroca: boolean;
}

const FormaPagamento = ({ pagamento }: Pagamentos) => {
  return (
    <>
      {pagamento.length > 0 && (
        <Flex
          direction="column"
          pt={2}
          pb={2}
          borderBottom="2px solid"
          borderColor="gray.700"
        >
          <Text>FORMA DE PAGAMENTO</Text>
          {pagamento.map((item) => (
            <Flex mt={2} w="100%" justifyContent="space-between">
              <HStack>
                <Text as="span">{item.parcela}</Text>
                <Text as="span">{formatData(item.dataVencimento)}</Text>
              </HStack>
              <Text>
                {item.descricao} {item.cupomTroca ? '- Troca' : ''}
              </Text>
              <Text>{moneyMask(item.valor, false)}</Text>
            </Flex>
          ))}
        </Flex>
      )}
    </>
  );
};

export default FormaPagamento;
