const RegimeTributarioEnum = {
  SIMPLES_NACIONAL: 1,
  SIMPLES_NACIONAL_EXCESSO_SUBLIMITE_RECEITA_BRUTA: 2,
  REGIME_NORMAL: 3,

  properties: [
    {
      label: 'Simples nacional',
      value: 1,
    },
    {
      label: 'Simples nacional - excesso de sublimite de receita bruta',
      value: 2,
    },
    {
      label: 'Simples nacional - MEI',
      value: 4,
    },
    {
      label: 'Regime normal',
      value: 3,
    },
  ],
};

export default RegimeTributarioEnum;
