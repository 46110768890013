import { useState } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Flex,
  ModalFooter,
  Box,
  ModalHeader,
  useMediaQuery,
  Divider,
  Text,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { ButtonDefault } from 'components/Button/ButtonChakra';
import Input from 'components/PDV/Input';

import { yupResolver } from './validationForms';

type FormData = {
  descricao: string;
};

type ModalAdicionarDescricaoProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<FormData> & {
    identificador: string;
  };

export const ModalAdicionarDescricao = create<
  ModalAdicionarDescricaoProps,
  FormData
>(({ onResolve, onReject, identificador, ...rest }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
  });

  const { handleSubmit: onSubmit } = formMethods;

  const handleSubmit = onSubmit((data) => {
    setIsLoading(true);
    onResolve(data);
    setIsLoading(false);
    onClose();
  });

  return (
    <ModalPadraoChakra
      isCentered={isLargerThan900}
      size={isLargerThan900 ? 'xl' : 'full'}
      {...rest}
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={false}
    >
      <ModalContent
        bg="gray.50"
        borderRadius={isLargerThan900 ? 'md' : '0'}
        marginBottom={isLargerThan900 ? '3.75rem' : '0'}
        marginTop={isLargerThan900 ? '3.75rem' : '0'}
        w={isLargerThan900 ? '480px' : 'full'}
        h={isLargerThan900 ? '320px' : 'full'}
      >
        <ModalHeader pt="16px" pb="28px" pl="24px">
          <Text color="primary.50" fontSize="16px">
            Adicionar descrição
          </Text>
          <Text color="gray.700" fontSize="12px">
            Informe um nome para identificar mais facilmente a maquininha.
          </Text>

          <Divider mt="16px" />
        </ModalHeader>

        <FormProvider {...formMethods}>
          {isLoading && <LoadingPadrao />}
          <ModalBody
            pl="24px"
            pr="24px"
            mb={isLargerThan900 ? '20px' : '20px'}
            pt="0"
            pb="0"
          >
            <Flex justifyContent="center" alignItems="center" h="full" w="full">
              <Box w={['full', 'full', '320px']}>
                <Input
                  id="descricao"
                  placeholder="Digite o nome para o POS"
                  label="Descrição"
                  maxLength={40}
                  name="descricao"
                />
                <Text color="gray.500" mt="14px" textAlign="right">
                  {identificador}
                </Text>
              </Box>
            </Flex>
          </ModalBody>
          <ModalFooter flexDirection="column">
            <Flex
              w="full"
              h="full"
              justifyContent="center"
              alignItems="baseline"
            >
              <Button
                variant="outlineDefault"
                borderRadius="16px"
                fontSize="sm"
                h={isLargerThan900 ? '32px' : '40px'}
                mr="24px"
                onClick={() => onClose()}
                w="96px"
              >
                Cancelar
              </Button>
              <ButtonDefault
                variant="solid"
                color="white"
                borderRadius="16px"
                colorScheme="aquamarine.600"
                h={isLargerThan900 ? '32px' : '40px'}
                width="120px"
                onClick={() => handleSubmit()}
                isLoading={isLoading}
              >
                Confirmar
              </ButtonDefault>
            </Flex>
          </ModalFooter>
        </FormProvider>
      </ModalContent>
    </ModalPadraoChakra>
  );
});
