export type TipoCadastroPessoa = 1 | 2;
const TipoCadastroPessoaEnum = {
  CLIENTE: 1,
  FORNECEDOR: 2,

  properties: {
    1: { name: 'Cliente', value: 1 },
    2: { name: 'Fornecedor', value: 2 },
  },
};

export default TipoCadastroPessoaEnum;
