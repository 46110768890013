import React, { useState, useEffect } from 'react';
import {
  useMediaQuery,
  Box,
  VStack,
  HStack,
  Text,
  CloseButton,
  GridItem,
  Button,
  Flex,
  Icon,
  useDisclosure,
  IconButton,
  FormLabel,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { FiChevronLeft } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { formatOptionsSelectClient } from 'helpers/format/formatSelectClient';

import ConstanteRotasPDV from 'constants/rotasPDV';
import IdentificacaoTipoOperacaoEnum, {
  identificacaoTipoOperacaoOptions,
} from 'constants/enum/identificacaoTipoOperacao';
import { statusOperacaoOptions } from 'constants/enum/statusOperacao';
import { enumFiltrarCaixaAtual } from 'constants/enum/enumFiltrarCaixaAtual';
import { useConsultarOperacoesContext } from 'store/PDV/ConsultarOperacoes';
import { useInformacoesGeraisContext } from 'store/PDV/InformacoesGerais';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { StatusPesquisaClientesFornecedor } from 'constants/enum/statusPesquisaClientesFornecedor';
import TipoContaFinanceiraEnum from 'constants/enum/tipoContaFinanceira';

import { BuscaIcon } from 'icons';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import AsyncSelect from 'components/PDV/Select/AsyncSelectPadrao';
import InputInteger from 'components/PDV/InputInteger';
import InputDateRange from 'components/PDV/InputDateRange';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';

type ClientesOptionResponse = {
  id: string;
  nome: string;
  endereco: string;
  codigo: number;
  cpfCnpj: string;
};

const HomeInternalHeader: React.FC = () => {
  const [isSmallerThan1800] = useMediaQuery('(max-width: 1800px)');
  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const history = useHistory();
  const { isOpen, onToggle, onClose } = useDisclosure();
  const {
    contaFinanceira,
    carregarInformacoes,
    possuiCadastroOutrosCaixas,
  } = useInformacoesGeraisContext();

  const caixaFechadoEPossuiCadastroOutrosCaixas =
    contaFinanceira?.tipoContaFinanceira ===
      TipoContaFinanceiraEnum.CONTA_COFRE && possuiCadastroOutrosCaixas;

  const {
    handleGetOperacoes: contextHandleGetOperacoes,
    isLoading,
  } = useConsultarOperacoesContext();
  const { setValue } = useFormContext();
  const [existeCaixaAtual, setExisteCaixaAtual] = useState(false);

  useEffect(() => {
    carregarInformacoes();
  }, [carregarInformacoes]);

  useEffect(() => {
    const nome = contaFinanceira?.nome;
    if (nome !== 'Conta Cofre' && nome) {
      setValue('filtrarPeloCaixaAtual', true);
      setExisteCaixaAtual(true);
    } else {
      setValue('filtrarPeloCaixaAtual', false);
      setExisteCaixaAtual(false);
    }
  }, [contaFinanceira, setValue]);

  function handleGetOperacoes() {
    onClose();
    contextHandleGetOperacoes();
  }

  async function getClientesOptions(inputValue: string) {
    const response = await api.get<void, ResponseApi<ClientesOptionResponse[]>>(
      ConstanteEnderecoWebservice.CLIENTE_FORNECEDOR_LISTAR_SELECT,
      {
        params: {
          cpfCnpjNomeApelidoCodigoExterno: inputValue,
          filtroTipoCadastroPessoa: StatusPesquisaClientesFornecedor.CLIENTES,
        },
      }
    );
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        return response.dados.map((client) => {
          const option = formatOptionsSelectClient(client);
          return option;
        });
      }
    }
    return [];
  }

  function handleRedirectHome() {
    history.push(ConstanteRotasPDV.PDV_HOME);
  }

  const opcoesCasoCaixaEstejaFechado = [
    { label: 'Pedidos', value: IdentificacaoTipoOperacaoEnum.PEDIDO },
    { label: 'Orçamentos', value: IdentificacaoTipoOperacaoEnum.ORCAMENTO },
  ];

  return (
    <VStack
      spacing={4}
      alignItems="center"
      justifyContent="center"
      h={{ base: isOpen ? 'auto' : '56px', md: '225px' }}
      px={[6, 8, 8, 28, 56]}
      py={{ base: 6, md: 0 }}
      pt={{ base: isOpen ? 0 : 6, md: 0 }}
    >
      <HStack
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
        w="full"
        h={{ base: '56px', md: 'auto' }}
      >
        {isSmallerThan900 && (
          <Flex flex="1">
            <IconButton
              aria-label="Voltar"
              icon={<Icon as={FiChevronLeft} boxSize="6" />}
              variant="link"
              color="white"
              onClick={handleRedirectHome}
              minW="8"
            />
          </Flex>
        )}
        <HStack
          spacing={2}
          onClick={isSmallerThan900 ? onToggle : undefined}
          cursor={isSmallerThan900 ? 'pointer' : 'default'}
        >
          <Text
            as="h2"
            color="secondary.500"
            fontSize={{ base: 'sm', md: 'lg' }}
          >
            Consulta de operações
          </Text>
          {isSmallerThan900 && (
            <Icon
              as={BuscaIcon}
              color="secondary.500"
              fontSize={{ base: 'lg', md: 'xl' }}
            />
          )}
        </HStack>
        {isLargerThan900 && (
          <CloseButton
            color="gray.100"
            _hover={{ color: 'gray.50' }}
            size="md"
            p={1}
            onClick={handleRedirectHome}
          />
        )}
      </HStack>
      {(!isSmallerThan900 || (isSmallerThan900 && isOpen)) && (
        <SimpleGridForm w="full" gridGap={6}>
          <GridItem colSpan={[12, 12, 3, 3, 3]}>
            <InputInteger
              id="numeroOperacao"
              name="numeroOperacao"
              isDisabled={isLoading}
              label="Número"
              fontWeightLabel="light"
              labelColor="white"
            />
          </GridItem>
          <GridItem colSpan={[12, 12, 6, 6, 6]}>
            <AsyncSelect
              id="clienteId"
              name="clienteId"
              label="Cliente"
              labelColor="white"
              isClearable
              fontWeightLabel="light"
              isDisabled={isLoading}
              placeholder="Informe o nome, CPF ou CNPJ"
              handleGetOptions={getClientesOptions}
              colSpan={6}
              shouldAppearTheAddress
            />
          </GridItem>
          <GridItem colSpan={[12, 12, 3, 3, 3]}>
            <SelectPadrao
              id="filtrarPeloCaixaAtual"
              name="filtrarPeloCaixaAtual"
              label="Caixa"
              fontWeightLabel="light"
              labelColor="white"
              options={enumFiltrarCaixaAtual.properties}
              isSearchable={false}
              isDisabled={!existeCaixaAtual}
            />
          </GridItem>
          <GridItem colSpan={[12, 12, 3, 3, 3]}>
            <SelectPadrao
              id="identificacaoTipoOperacao"
              name="identificacaoTipoOperacao"
              label="Operação"
              fontWeightLabel="light"
              labelColor="white"
              options={
                caixaFechadoEPossuiCadastroOutrosCaixas
                  ? opcoesCasoCaixaEstejaFechado
                  : identificacaoTipoOperacaoOptions
              }
              isSearchable={false}
              isDisabled={isLoading}
            />
          </GridItem>
          <GridItem colSpan={[12, 12, 2, 2, 2]}>
            <SelectPadrao
              id="status"
              name="status"
              label="Status"
              fontWeightLabel="light"
              labelColor="white"
              options={statusOperacaoOptions}
              isSearchable={false}
              isDisabled={isLoading}
            />
          </GridItem>
          <GridItem colSpan={[12, 12, 4, 4, 4]}>
            <FormLabel
              fontSize="sm"
              mb="0"
              lineHeight="14px"
              color="white"
              fontWeight="light"
            >
              Período
            </FormLabel>
            <Box mt="4px">
              <InputDateRange
                startDateName="dataEmissaoInicio"
                endDateName="dataEmissaoFim"
                isDisabled={isLoading}
                maxDate={new Date()}
                inputValueIsSmaller={isSmallerThan1800 && isLargerThan900}
                onConfirm={contextHandleGetOperacoes}
              />
            </Box>
          </GridItem>
          <GridItem
            colSpan={[12, 12, 2, 2, 2]}
            as={Flex}
            alignItems="center"
            justifyContent="center"
            pt={['0px', '0px', '20px']}
            h="full"
            w="full"
            minW="160px"
          >
            <Button
              colorScheme="secondary"
              size="sm"
              w="full"
              onClick={handleGetOperacoes}
              isDisabled={isLoading}
            >
              Pesquisar
            </Button>
          </GridItem>
        </SimpleGridForm>
      )}
    </VStack>
  );
};

export default HomeInternalHeader;
