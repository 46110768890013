import { toast } from 'react-toastify';
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState,
  useCallback,
} from 'react';
import { Box, Flex, Grid, LayoutProps } from '@chakra-ui/react';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';

import { InformacoesImpressaoNFCeInterface } from 'types/ImpressaoNFCe';

import Cabecalho from './Divisoes/cabecalho';
import Produtos from './Divisoes/produtos';
import Totais from './Divisoes/totais';
import ChaveAcesso from './Divisoes/chaveAcesso';
import ConsultaViaQrCode from './Divisoes/consultaViaQrCode';
import Consumidor from './Divisoes/consumidor';
import IdentificacaoNFCe from './Divisoes/identificacaoNFCe';
import MensagemFiscal from './Divisoes/mensagemFiscal';
import MensagemInteresseContribuinte from './Divisoes/mensagemInteresseContribuinte';
import DezenasPremiadas from './Divisoes/dezenasPremiadas';

interface ImpressaoNFCeInterface {
  maxWidth?: LayoutProps['maxW'];
  documentoFiscalId: string;
  containerRef?: React.RefObject<HTMLDivElement>;
  operacao?: Operacao;
}

type Operacao = {
  id: string;
  numeroOperacao?: number;
};

export interface ImpressaoNFCeRefElement {
  obterInformacoesImpressaoHandle: () => Promise<boolean>;
}

const ImpressaoNFCe: ForwardRefRenderFunction<
  ImpressaoNFCeRefElement,
  ImpressaoNFCeInterface
> = ({ maxWidth, documentoFiscalId, operacao, containerRef }, ref) => {
  const [
    informacoesImpressaoNFCe,
    setInformacoesImpressaoNFCe,
  ] = useState<InformacoesImpressaoNFCeInterface>();

  const obterInformacoesImpressaoHandle = useCallback(async () => {
    const response = await api.get<
      void,
      ResponseApi<InformacoesImpressaoNFCeInterface>
    >(ConstanteEnderecoWebservice.NOTA_FISCAL_IMPRIMIR_NFCE, {
      params: { documentoFiscalId },
    });

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((avisos) => toast.warning(avisos));
        return false;
      }

      if (response.sucesso && response.dados) {
        setInformacoesImpressaoNFCe(response.dados);
        return true;
      }
    }
    return false;
  }, [documentoFiscalId]);

  useImperativeHandle(ref, () => ({
    obterInformacoesImpressaoHandle,
  }));

  return (
    <Box
      h="full"
      minH="100vh"
      minW="100%"
      pl="5px"
      pr="10px"
      sx={{
        '& div': {
          color: 'black',
          fontWeight: '900',
          fontSize: '10px',
          fontFamily: 'calibri',
        },
        '& div, & hr': {
          opacity: 'unset',
          borderColor: 'black',
        },
      }}
      ref={containerRef}
    >
      {informacoesImpressaoNFCe?.xml && (
        <Grid minW="58mm" maxW={maxWidth} className="impressao">
          <Cabecalho
            emitente={
              informacoesImpressaoNFCe?.xml.informacoesNFeXml.emitente || ''
            }
            logo={informacoesImpressaoNFCe.lojaLogo}
          />

          <Produtos
            nfceAutorizada={informacoesImpressaoNFCe.autorizada}
            produtos={informacoesImpressaoNFCe.xml.informacoesNFeXml.itens}
            valorProdutos={
              informacoesImpressaoNFCe.xml.informacoesNFeXml.grupoValoresTotais
                .valoresTotaisIcms.valorProduto
            }
          />

          <Totais
            valoresTotais={
              informacoesImpressaoNFCe.xml.informacoesNFeXml.grupoValoresTotais
                .valoresTotaisIcms
            }
            pagamento={informacoesImpressaoNFCe.xml.informacoesNFeXml.pag}
            qtdItens={
              informacoesImpressaoNFCe.xml.informacoesNFeXml.itens.length
            }
          />

          <ChaveAcesso
            chaveAcesso={informacoesImpressaoNFCe.xml.informacoesNFeXml.id}
            url={
              informacoesImpressaoNFCe.xml.informacoesSuplementaresXml.urlChave
            }
          />

          <Flex mt="8px" mb="8px">
            <ConsultaViaQrCode
              qrCode={
                informacoesImpressaoNFCe?.xml.informacoesSuplementaresXml.qrCode
              }
            />
            <Box textAlign="left" justifyContent="flex-start">
              <Consumidor
                destinatario={
                  informacoesImpressaoNFCe?.xml.informacoesNFeXml.destinatario
                }
              />

              <IdentificacaoNFCe
                numero={
                  informacoesImpressaoNFCe.xml.informacoesNFeXml.ide.numero
                }
                serie={informacoesImpressaoNFCe.xml.informacoesNFeXml.ide.serie}
                dataEmissao={
                  informacoesImpressaoNFCe.xml.informacoesNFeXml.ide.dataEmissao
                }
                contingencia={false}
                informacoesProtocolo={
                  informacoesImpressaoNFCe.protocolo.informacoesProtocoloXml
                }
              />
              <Box>Venda: {operacao?.numeroOperacao}</Box>
            </Box>
          </Flex>
          <DezenasPremiadas
            siglaUf={
              informacoesImpressaoNFCe.xml.informacoesNFeXml.emitente
                .enderecoEmitente.siglaUF
            }
            informacoesProtocolo={
              informacoesImpressaoNFCe.protocolo.informacoesProtocoloXml
            }
          />
          <MensagemFiscal
            contingencia={false}
            tipoAmbiente={
              informacoesImpressaoNFCe.xml.informacoesNFeXml.ide.tipoAmbiente
            }
            informacoesFisco={
              informacoesImpressaoNFCe.xml.informacoesNFeXml
                .informacoesAdicionais?.informacoesFisco
            }
          />

          <MensagemInteresseContribuinte
            informacoesComplementares={
              informacoesImpressaoNFCe.xml.informacoesNFeXml
                .informacoesAdicionais?.informacoesComplementares
            }
          />
        </Grid>
      )}
    </Box>
  );
};

export default forwardRef(ImpressaoNFCe);
