import { Flex, Text, Box, Divider, useMediaQuery } from '@chakra-ui/react';

import { moneyMask } from 'helpers/format/fieldsMasks';

import { CalculoComissao } from '../../Types/validationsForm';

type CalculoComissaoIndividualProps = {
  listaIndividualCalculoComissao: CalculoComissao;
};

export const CalculoComissaoIndividual = ({
  listaIndividualCalculoComissao,
}: CalculoComissaoIndividualProps) => {
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  return (
    <Flex
      borderRadius="5px"
      boxShadow="0px 0px 6px #00000034"
      direction={isLargerThan900 ? 'row' : 'column'}
      justifyContent={isLargerThan900 ? 'space-between' : 'flex-end'}
      alignItems={isLargerThan900 ? 'center' : 'left'}
      pl={isLargerThan900 ? '70px' : '20px'}
      pr={isLargerThan900 ? '80px' : '20px'}
      pt="29px"
      w="full"
      pb="24px"
      bg="white"
    >
      <Box>
        <Flex
          mb={isLargerThan900 ? '13px' : '3px'}
          direction={isLargerThan900 ? 'column' : 'row'}
          justifyContent="left"
          alignItems="center"
        >
          <Text mr="5px" fontSize="14px" color="gray.700">
            Percentual de comissão
          </Text>
          <Text fontSize="16px" color="black">
            {moneyMask(listaIndividualCalculoComissao.percentual, false)} %
          </Text>
        </Flex>
      </Box>
      {isLargerThan900 && <Divider orientation="vertical" h="50px" />}
      <Box>
        <Flex
          direction={isLargerThan900 ? 'column' : 'row'}
          mb={isLargerThan900 ? '13px' : '3px'}
          justifyContent="left"
          alignItems="center"
        >
          <Text mr="5px" fontSize="14px" color="gray.700">
            Valor da comissão
          </Text>
          <Text fontSize="16px" color="black">
            {moneyMask(listaIndividualCalculoComissao.valorComissao, true)}
          </Text>
        </Flex>
      </Box>
      {isLargerThan900 && <Divider orientation="vertical" h="60px" />}
      <Box>
        <Flex
          mb={isLargerThan900 ? '13px' : '3px'}
          justifyContent="left"
          direction={isLargerThan900 ? 'column' : 'row'}
          alignItems="center"
        >
          <Text mr="5px" fontSize="14px" color="gray.700">
            Bonificação por meta geral
          </Text>
          <Text fontSize="16px" color="black">
            {moneyMask(listaIndividualCalculoComissao.boninificacao, true)}
          </Text>
        </Flex>
      </Box>
      {isLargerThan900 && <Divider orientation="vertical" h="60px" />}
      <Box>
        <Flex
          mb={isLargerThan900 ? '13px' : '3px'}
          justifyContent="left"
          direction={isLargerThan900 ? 'column' : 'row'}
          alignItems="center"
        >
          <Text mr="5px" fontSize="14px" color="gray.700">
            Valor total a receber
          </Text>
          <Text fontSize="16px" color="black">
            {moneyMask(listaIndividualCalculoComissao.valorReceber, true)}
          </Text>
        </Flex>
      </Box>
    </Flex>
  );
};
