import RelatorioProdutoComPreco from 'pages/Produtos/Relatorios/ProdutosComPreco';
import RelatorioProdutosVendidos from 'pages/Produtos/Relatorios/ProdutosVendidos';
import RelatorioProdutosPersonalizados from 'pages/Produtos/Relatorios/Personalizados';
import PermissaoRelatorioProdutosVendidos from 'pages/Produtos/Relatorios/ProdutosVendidos/PermissaoRelatorioProdutosVendidos';
import CadastroRelatoriosPersonalizadosProdutos from 'pages/Produtos/Relatorios/CadastroRelatorios';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

export const RelatorioProdutosPersonalizadosRoutes = [
  <LayoutGuard
    key="gerar-relatorios-produtos-personalizados"
    permissaoFuncionalidade={
      ConstanteFuncionalidades.RELATORIO_PRODUTO_PERSONALIZADO
    }
    component={RelatorioProdutosPersonalizados}
    breadcrumb={[
      {
        title: 'Relatórios',
      },
      {
        title: 'Personalizados',
      },
    ]}
    path={ConstanteRotas.RELATORIO_PRODUTOS_PERSONALIZADOS}
    exact
    meta={{ auth: true }}
  />,

  <LayoutGuard
    key="gerar-relatorios-produtos-preco"
    permissaoFuncionalidade={PermissaoRelatorioProdutosVendidos()}
    component={RelatorioProdutosVendidos}
    breadcrumb={[
      {
        title: 'Relatórios',
      },
      {
        title: 'Produtos Vendidos',
      },
    ]}
    path={ConstanteRotas.RELATORIO_PRODUTOS_VENDIDOS}
    exact
    meta={{ auth: true }}
  />,

  <LayoutGuard
    key="gerar-relatorios-produtos-preco"
    permissaoFuncionalidade={
      ConstanteFuncionalidades.RELATORIO_PRODUTOS_COM_PRECO
    }
    component={RelatorioProdutoComPreco}
    breadcrumb={[
      {
        title: 'Relatórios',
      },
      {
        title: 'Produtos com Preço',
      },
    ]}
    path={ConstanteRotas.RELATORIO_PRODUTOS_COM_PRECO}
    exact
    meta={{ auth: true }}
  />,

  <LayoutGuard
    key="cadastro-relatorios-produtos-personalizados"
    permissaoFuncionalidade={
      ConstanteFuncionalidades.RELATORIO_PRODUTO_PERSONALIZADO
    }
    component={CadastroRelatoriosPersonalizadosProdutos}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Personalizados',
      },
    ]}
    path={ConstanteRotas.RELATORIO_PRODUTOS_CADASTRO}
    exact
    meta={{ auth: true }}
  />,
];
