import React from 'react';
import {
  Circle,
  Text,
  HStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';

interface StepDescriptionAccordionProps {
  stepNumber: number;
  title: string;
  description: string;
}

export function StepDescriptionAccordion({
  stepNumber,
  title,
  description,
}: StepDescriptionAccordionProps) {
  return (
    <Accordion allowToggle>
      <AccordionItem mt="-5" bg="primary.500">
        <AccordionButton
          as={HStack}
          alignItems="center"
          justifyContent="space-between"
          cursor="pointer"
          py={2}
          px={6}
          bg="primary.500"
          _hover={{ bg: 'primary.500' }}
          borderRadius="md"
          h="10"
        >
          <HStack>
            <Circle size="6" bg="secondary.300">
              <Text
                color="primary.500"
                fontSize="xs"
                fontWeight="semibold"
                userSelect="none"
              >
                {stepNumber}
              </Text>
            </Circle>

            <Text
              color="secondary.300"
              fontSize="md"
              fontWeight="semibold"
              lineHeight="none"
              userSelect="none"
            >
              {title}
            </Text>
          </HStack>

          <AccordionIcon color="secondary.300" fontSize="xl" />
        </AccordionButton>
        <AccordionPanel p="2" pt="0" pr="6" pl="14">
          <Text color="white" fontSize="sm">
            {description}
          </Text>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
