import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { AmbienteFiscal } from 'constants/enum/fiscal/ambienteFiscal';

const obterAmbienteFiscal = async (): Promise<AmbienteFiscal | undefined> => {
  const response = await api.get<void, ResponseApi<AmbienteFiscal>>(
    ConstanteEnderecoWebservice.LOJA_OBTER_TIPO_AMBIENTE_FISCAL
  );

  if (response?.avisos) {
    response.avisos.map((item: string) => toast.warning(item));
  }

  if (response?.sucesso) {
    return response.dados;
  }

  return undefined;
};

export default obterAmbienteFiscal;
