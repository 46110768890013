import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

interface CabecalhoProps {
  nomeFantasia: string;
}

const Cabecalho = ({ nomeFantasia }: CabecalhoProps) => {
  return (
    <>
      <Flex
        pt={1}
        pb={1}
        pl={1}
        pr={1}
        alignItems="center"
        justifyContent="center"
        border="2px solid"
        borderColor="gray.700"
      >
        <Text fontSize="md" color="black" fontWeight="900">
          {nomeFantasia}
        </Text>
      </Flex>
    </>
  );
};

export default Cabecalho;
