import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import isPrenvent from 'helpers/layout/isPrenvent';
import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import auth from 'modules/auth';
import { setDateMinHours } from 'helpers/data/setHoursDate';
import useReloadRegistration from 'helpers/layout/useReloadRegistration';
import { ContainerListagem } from 'components/Layout/Listagem/containerListagem';

import { useForm, yupResolver } from '../validationForm';
import { UncontrolledForm } from '..';

interface BancoInterface {
  codigoBanco: string;
  nomeBanco: string;
}

interface ContaFinanceira {
  id: string;
  tipo: string;
  nome: string;
  ativo: boolean;
  tipoContaFinanceira: number;
  codigoBanco: string;
  dataHoraCadastro: string;
  dataHoraUltimaAlteracao: string;
  icone: string;
  saldoInicial: number;
}

let bancoList: BancoInterface[] = [];

const Cadastro = () => {
  const handleGetBanco = async () => {
    const response = await api.get<void, ResponseApi<Array<BancoInterface>>>(
      ConstanteEnderecoWebservice.BANCO_LISTAR
    );

    if (response) {
      if (response.avisos) {
        response.avisos.map((aviso) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        bancoList = response.dados;
      }
    }
  };

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);

  const formMethods = useForm({
    resolver: yupResolver,
  });

  const { handleSubmit, reset, getValues, formState } = formMethods;

  const historyReload = useReloadRegistration({ setFormIsDirty });
  isPrenvent(formIsDirty);

  async function handlePostContaFinanceira() {
    const data = getValues();

    let newValuesData;

    if (
      data.exibirSaldoInicial === false ||
      data.exibirSaldoInicial === undefined
    ) {
      newValuesData = {
        ...data,
        numeroConta: String(data.numeroConta),
        LojaId: auth.getLoja().id,
      };
    } else {
      newValuesData = {
        ...data,
        numeroConta: String(data.numeroConta),
        LojaId: auth.getLoja().id,
        dataLancamentoSaldo: setDateMinHours(new Date()),
      };
    }

    const response = await api.post<void, ResponseApi<ContaFinanceira>>(
      ConstanteEnderecoWebservice.CONTA_FINANCEIRA_CADASTRAR,
      newValuesData
    );

    if (response.sucesso) {
      return true;
    }

    if (response.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    return false;
  }

  const onSubmit = handleSubmit(async () => {
    setIsLoading(true);

    const success = await handlePostContaFinanceira();

    if (success) {
      toast.success('O cadastro foi realizado com sucesso.');

      setFormIsDirty(false);

      history.push(ConstanteRotas.CONTA_FINANCEIRA);
    }

    setIsLoading(false);
  });

  const onSubmitReset = handleSubmit(async () => {
    setIsLoading(true);

    const success = await handlePostContaFinanceira();

    if (success) {
      toast.success('O cadastro foi realizado com sucesso.');
      historyReload(ConstanteRotas.CONTA_FINANCEIRA_CADASTRAR);
    }

    setIsLoading(false);
  });

  useEffect(() => {
    reset({
      descricao: '',
      ativo: true,
      tipoContaFinanceira: 1,
    });
  }, [reset]);

  useEffect(() => {
    setFormIsDirty(formState.isDirty);
    handleGetBanco();
  }, [formState.isDirty]);

  return (
    <ContainerListagem
      formMethods={formMethods}
      isLoading={isLoading}
      onSubmit={onSubmit}
      onSubmitReset={onSubmitReset}
      formIsDirty={formIsDirty}
    >
      <UncontrolledForm bancoList={bancoList} />
    </ContainerListagem>
  );
};

export default Cadastro;
