import { useState, useCallback, useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';

import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { ModalGradeTamanhos } from 'components/update/Modal/ModalGradeTamanhos';
import { ModalBuscarProdutoParaImpressao } from 'components/Modal/ModalBuscarProdutoParaImpressao';
import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import { PaginationData } from 'components/update/Pagination';

import {
  EtiquetaResponse,
  ProdutosImpressao,
  FormData,
  ProdutoCorResponse,
  TamanhosResponse,
  ProdutoCorTamanho,
} from './validationForm';
import { PropertiesEtiquetas } from '../FormularioEtiquetas/validationForm';

export const useImprimirEtiquetas = ({
  formMethods,
}: {
  formMethods: UseFormReturn<FormData>;
}) => {
  const [listProductsToPrint, setListProductsToPrint] = useState<
    ProdutosImpressao[]
  >([]);
  const [
    etiquetasProperties,
    setEtiquetasProperties,
  ] = useState<PropertiesEtiquetas>();
  const [optionsEtiquetas, setOptionsEtiquetas] = useState<EtiquetaResponse[]>(
    []
  );
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [listaTamanhos, setListaTamanhos] = useState<TamanhosResponse[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingProduto, setIsLoadingProduto] = useState(false);

  const { casasDecimais } = usePadronizacaoContext();

  const { watch, handleSubmit, setValue } = formMethods;
  const quantidadePorProduto = watch('quantidadePorProduto');
  const selectedProduct = watch('infoProdutos');
  const etiquetaSelected = watch('valorEtiqueta');

  const noProductSelected = !selectedProduct?.value;
  const isPrintingListEmpty = (listProductsToPrint || []).length === 0;
  const isTipoImpressoraNotSelected = !etiquetaSelected;

  const hasUniqueSize = listaTamanhos?.some(
    (verificandoTamanho) => verificandoTamanho.padraoSistema
  );
  const showFieldSize = listaTamanhos?.length > 0 && !hasUniqueSize;

  const checkIsEtiquetaTypeLaser = useCallback(() => {
    const selectedEtiqueta = watch('valorEtiqueta');

    const optionsFromSelectedEtiqueta = optionsEtiquetas.find(
      (etiqueta) => etiqueta.id === selectedEtiqueta
    );

    if (optionsFromSelectedEtiqueta && optionsFromSelectedEtiqueta.nome) {
      return (optionsFromSelectedEtiqueta.nome.toUpperCase() || '').includes(
        'LASER'
      );
    }

    return false;
  }, [optionsEtiquetas, watch]);

  const obterValorTamanho = useCallback(() => {
    const tamanhos = watch('tamanho');

    const filtrarTamanho = listaTamanhos?.find((item) => item.id === tamanhos);

    if (filtrarTamanho) {
      const tamanhoSelecionado = filtrarTamanho.nome;
      return tamanhoSelecionado;
    }

    return '-';
  }, [listaTamanhos, watch]);

  const obterPrecoVendaTamanhoSelecionado = useCallback(() => {
    const tamanhos = watch('tamanho');

    const filtrarTamanho = listaTamanhos?.find((item) => item.id === tamanhos);

    if (filtrarTamanho) {
      const { precoVenda } = filtrarTamanho;
      return precoVenda;
    }
    if (listaTamanhos[0]) {
      return listaTamanhos[0].precoVenda;
    }
    return 0;
  }, [listaTamanhos, watch]);

  const obterValorId = useCallback(() => {
    const tamanhos = watch('tamanho');

    if (listaTamanhos[0]) {
      const tamanhoSelecionado = listaTamanhos[0].id;

      return tamanhoSelecionado;
    }

    return tamanhos;
  }, [listaTamanhos, watch]);

  const selectedEtiquetaIsTypeLaser = checkIsEtiquetaTypeLaser();
  const obterValorTamanhoSelecionado = obterValorTamanho();
  const obterIdProdutosSelecionados = obterValorId();

  const sendToPrint = async () => {
    const [colunaInicial, linhaInicial, valorEtiqueta] = watch([
      'colunaInicial',
      'linhaInicial',
      'valorEtiqueta',
    ]);

    const mappedProductList = (listProductsToPrint || []).map((product) => {
      return {
        produtoCorTamanhoId: product.id,
        quantidade: quantidadePorProduto[product.id],
      };
    });

    const response = await api.post<void, ResponseApi>(
      ConstanteEnderecoWebservice.ETIQUETA_PERSONALIZADA_IMPRIMIR,
      {
        etiquetaId: valorEtiqueta,
        linhaInicio: selectedEtiquetaIsTypeLaser ? linhaInicial : 1,
        colunaInicio: selectedEtiquetaIsTypeLaser ? colunaInicial : 1,
        produtoCorTamanho: mappedProductList,
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }
      if (response.sucesso) {
        toast.success('Solicitação de etiqueta enviada com sucesso');
      }
    }
  };

  const handleDeleteProduct = useCallback(
    (productId: string) => {
      setIsLoading(true);
      setListProductsToPrint((prevState) =>
        prevState.filter((product) => product.id !== productId)
      );
      toast.success('Produto removido da lista de impressão');
      setIsLoading(false);
    },
    [setListProductsToPrint]
  );

  const handleDeleteAllProducts = useCallback(async () => {
    setIsLoading(true);
    await ModalConfirmacaoExcluir({
      title: 'Excluir produtos',
      text:
        'Todos os produtos serão excluídos da lista de impressão. Deseja continuar?',
      confirmButtonText: 'Sim, continuar!',
      cancelButtonText: 'Cancelar',
      callback: async (ok: boolean) => {
        if (ok) {
          setListProductsToPrint([]);
          toast.success(
            'Todos os produtos foram removidos da lista de impressão'
          );
        }
      },
    });
    setIsLoading(false);
  }, []);

  const handleAbrirModalBuscarProduto = useCallback(
    (buscarPorNome: boolean) => {
      setIsLoading(true);

      const productsAlreadyAdded = (listProductsToPrint || []).map(
        ({ id, produto, tamanho, precoVenda, padraoSistema }) => {
          return {
            id,
            produto,
            tamanho,
            quantidade: quantidadePorProduto[id],
            precoVenda,
            padraoSistema,
          };
        }
      );
      setListProductsToPrint(productsAlreadyAdded);
      ModalBuscarProdutoParaImpressao({
        produtosJaAdicionadosImpressao: productsAlreadyAdded,
        setListaProdutosImpressao: setListProductsToPrint,
        setValue,
        casasDecimaisValor: casasDecimais?.casasDecimaisValor || 2,
        buscarPorNome,
      });

      setIsLoading(false);
    },
    [
      casasDecimais?.casasDecimaisValor,
      listProductsToPrint,
      quantidadePorProduto,
      setValue,
    ]
  );

  const handleIncrementProductQuantity = (id: string) => {
    const newListaImpressao = [...listProductsToPrint];
    const index = newListaImpressao.findIndex((item) => item.id === id);
    if (index !== -1) {
      const produtoAtual = newListaImpressao[index];

      const quantidadeAtual = quantidadePorProduto[produtoAtual.id];

      const quantidadeIncrementada =
        quantidadeAtual === 999 ? 999 : quantidadeAtual + 1;

      newListaImpressao.splice(index, 1, {
        ...produtoAtual,
        quantidade: quantidadeIncrementada,
      });

      setValue(
        `quantidadePorProduto.${produtoAtual.id}`,
        quantidadeIncrementada
      );

      setListProductsToPrint(newListaImpressao);
    }
  };

  const handleDecreaseProductQuantity = (id: string) => {
    if (quantidadePorProduto[id] > 1) {
      const newListaImpressao = [...listProductsToPrint];
      const index = newListaImpressao.findIndex((item) => item.id === id);
      if (index !== -1) {
        const produtoAtual = newListaImpressao[index];
        const quantidadeAtual = quantidadePorProduto[produtoAtual.id];
        const quantidadeDecrementada = quantidadeAtual - 1;

        newListaImpressao.splice(index, 1, {
          ...produtoAtual,
          quantidade: quantidadeDecrementada,
        });

        setValue(
          `quantidadePorProduto.${produtoAtual.id}`,
          quantidadeDecrementada
        );

        setListProductsToPrint(newListaImpressao);
      }
    }
  };

  const handleResetPropertiesEtiquetas = useCallback(() => {
    setValue('linhaInicial', 1);
    setValue('colunaInicial', 1);
  }, [setValue]);

  const clearFields = useCallback(() => {
    const { setFocus } = formMethods;
    setValue('quantidade', 1);
    setValue('infoProdutos', null);
    setValue('tamanho', '');
    setListaTamanhos([]);

    setTimeout(() => {
      setFocus('infoProdutos');
    }, '1000');
  }, [formMethods, setValue]);

  const handleAddProduct = () => {
    const [quantidade, tamanhos] = watch(['quantidade', 'tamanho']);

    if (selectedProduct) {
      const productDescription = `${selectedProduct.produtoNome}${
        selectedProduct.corDescricao ? ` | ${selectedProduct.corDescricao}` : ''
      }`;

      setListProductsToPrint((previousProducts) => {
        if (showFieldSize && !tamanhos) {
          return previousProducts;
        }

        const productId = showFieldSize
          ? tamanhos
          : obterIdProdutosSelecionados;

        if (!productId) {
          toast.warning('Por favor, selecione novamente esse produto');
          return previousProducts;
        }

        const newProduct = {
          id: productId,
          produto: productDescription,
          tamanho: obterValorTamanhoSelecionado,
          quantidade,
          precoVenda: obterPrecoVendaTamanhoSelecionado(),
        };

        if (!previousProducts) {
          return [newProduct];
        }

        const existingProductIndex = previousProducts.findIndex(
          (item) => item.id === newProduct.id
        );

        if (existingProductIndex !== -1) {
          const copiedPreviousProducts = [...previousProducts];

          const currentProduct = copiedPreviousProducts[existingProductIndex];

          const newQuantityToAdd = Number(newProduct.quantidade);
          const sumOfCurrentQuantityAndNewQuantityToAdd =
            currentProduct.quantidade + newQuantityToAdd;

          const updatedQuantity =
            sumOfCurrentQuantityAndNewQuantityToAdd > 999
              ? 999
              : sumOfCurrentQuantityAndNewQuantityToAdd;

          copiedPreviousProducts.splice(existingProductIndex, 1, {
            ...currentProduct,
            quantidade: updatedQuantity,
          });

          setValue(
            `quantidadePorProduto.${currentProduct.id}`,
            updatedQuantity
          );

          return copiedPreviousProducts;
        }
        setValue(`quantidadePorProduto.${productId}`, quantidade);
        return [...previousProducts, newProduct];
      });

      clearFields();
    }
  };

  const handleModalGradeTamanhos = async () => {
    if (selectedProduct) {
      const transformedSizes = listaTamanhos?.map(
        ({ id, nome, precoVenda, padraoSistema }) => {
          return {
            produtoCorTamanhoId: id,
            tamanho: nome,
            precoVenda,
            padraoSistema,
          };
        }
      );

      const formattedModalSizes: ProdutoCorTamanho[] = await ModalGradeTamanhos(
        {
          produtoNome: selectedProduct.produtoNome,
          corDescricao: selectedProduct.corDescricao,
          casasDecimaisQuantidade: 0,
          tamanhos: transformedSizes,
        }
      );

      if (formattedModalSizes) {
        formattedModalSizes.forEach((item) => {
          const productDescription = `${item.produtoNome}${
            item.corDescricao ? ` | ${item.corDescricao}` : ''
          }`;

          const quantityOfProduct = Number(item.quantidade);
          const newProdutos = {
            id: item.produtoCorTamanhoId,
            produto: productDescription,
            quantidade: quantityOfProduct,
            tamanho: item.tamanhoDescricao || '-',
            precoVenda: item.precoVenda || 0,
            padraoSistema: item.padraoSistema || false,
          };

          setListProductsToPrint((previousProducts) => {
            if (!previousProducts) {
              return [newProdutos];
            }

            const jaExisteProdutoIndex = previousProducts.findIndex(
              (produto) => produto.id === item.produtoCorTamanhoId
            );

            if (jaExisteProdutoIndex !== -1) {
              const produtosAnterioresJaInseridos = [...previousProducts];

              const produtoAtual =
                produtosAnterioresJaInseridos[jaExisteProdutoIndex];

              const newQuantidadeAtual =
                produtoAtual.quantidade + quantityOfProduct;

              produtosAnterioresJaInseridos.splice(jaExisteProdutoIndex, 1, {
                ...produtoAtual,
                quantidade: newQuantidadeAtual,
              });
              setValue(
                `quantidadePorProduto.${produtoAtual.id}`,
                newQuantidadeAtual
              );

              return produtosAnterioresJaInseridos;
            }

            const newTotalProdutos = [...previousProducts, newProdutos];

            newTotalProdutos.forEach((produto) => {
              setValue(
                `quantidadePorProduto.${produto.id}`,
                produto.quantidade
              );
            });

            return newTotalProdutos;
          });
        });
      }

      clearFields();
    }
  };

  const handleSubmitPrint = handleSubmit(async () => {
    setIsLoading(true);
    await sendToPrint();
    setIsLoading(false);
  });

  const getProdutoCor = useCallback(
    async (inputValue: string, dataPagination: PaginationData) => {
      setIsLoadingProduto(true);
      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<ProdutoCorResponse>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.PRODUTO_COR_LISTAR_PAGINADO,
          dataPagination
        ),
        {
          params: {
            nome: inputValue,
          },
        }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso: string) => toast.warning(aviso));
        }

        setTotalRegistros(response.dados.total);

        if (response.sucesso && response.dados?.registros) {
          const data = response.dados?.registros?.map((option) => {
            const cortandoNomeProduto = option.descricao.split('|');
            const newProdutoNome = cortandoNomeProduto[0].trim();
            const newProdutoCor = cortandoNomeProduto
              ? cortandoNomeProduto[1]
                ? cortandoNomeProduto[1].trim()
                : ''
              : '';
            setIsLoadingProduto(false);

            return {
              label: option.descricao,
              value: option.id,
              produtoNome: newProdutoNome,
              corDescricao: newProdutoCor,
            };
          });
          setIsLoadingProduto(false);

          return data;
        }
      }
      setIsLoadingProduto(false);

      return [];
    },
    []
  );

  const getTamanhos = useCallback(async () => {
    if (selectedProduct) {
      setIsLoadingProduto(true);

      const response = await api.get<void, ResponseApi<TamanhosResponse[]>>(
        ConstanteEnderecoWebservice.PRODUTO_COR_TAMANHO_OBTER_TAMANHOS,
        {
          params: { produtoCorId: selectedProduct.value },
        }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response.sucesso && response.dados) {
          setListaTamanhos(response.dados);
        }
      }
    }
    setIsLoadingProduto(false);
  }, [selectedProduct]);

  const getOptionsEtiquetas = useCallback(async () => {
    const response = await api.get<void, ResponseApi<EtiquetaResponse[]>>(
      ConstanteEnderecoWebservice.ETIQUETA_PERSONALIZADA_LISTAR_SELECT
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        setOptionsEtiquetas(response.dados);

        const filtrarValores = response.dados.find(
          (item) => item.id === etiquetaSelected
        );

        if (filtrarValores) {
          setEtiquetasProperties(
            JSON.parse(filtrarValores.configuracoes.toString() || '')
          );
        }
      }
    }
  }, [etiquetaSelected]);

  useEffect(() => {
    if (selectedProduct) {
      setValue('tamanho', '');
    } else {
      setValue('quantidade', 1);
      setListaTamanhos([]);
    }
  }, [selectedProduct, setValue]);

  useEffect(() => {
    getOptionsEtiquetas();
  }, [getOptionsEtiquetas]);

  useEffect(() => {
    getTamanhos();
  }, [getTamanhos]);

  return {
    etiquetasProperties,
    optionsEtiquetas,
    totalRegistros,
    noProductSelected,
    listaTamanhos,
    listProductsToPrint,
    isLoading,
    isPrintingListEmpty,
    isTipoImpressoraNotSelected,
    selectedProduct,
    selectedEtiquetaIsTypeLaser,
    showFieldSize,
    isLoadingProduto,
    getProdutoCor,
    handleAbrirModalBuscarProduto,
    handleDeleteProduct,
    handleDeleteAllProducts,
    handleDecreaseProductQuantity,
    handleIncrementProductQuantity,
    handleResetPropertiesEtiquetas,
    handleAddProduct,
    handleModalGradeTamanhos,
    handleSubmitPrint,
  };
};
