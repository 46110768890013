import { BoxProps, Flex } from '@chakra-ui/react';

interface ContainerHeaderProps extends BoxProps {
  children: React.ReactNode;
}

export const ContainerHeader = ({
  children,
  h = '64px',
  pl = '24px',
  zIndex = '1020',
  bg = 'blue.900',
  boxShadow = '3px 0px 6px #00000080',
  ...rest
}: ContainerHeaderProps) => {
  return (
    <Flex
      zIndex={zIndex}
      w="full"
      h={h}
      minH="64px"
      bg={bg}
      boxShadow={boxShadow}
      pl={pl}
      {...rest}
    >
      {children}
    </Flex>
  );
};
