import React, {
  ReactNode,
  useState,
  forwardRef,
  useRef,
  useEffect,
  useImperativeHandle,
} from 'react';
import {
  List,
  AutoSizer,
  ListRowRenderer,
  InfiniteLoader,
  Index,
} from 'react-virtualized';
import {
  Table,
  Thead,
  Tr,
  Th,
  TableColumnHeaderProps,
  Tbody,
  Box,
  Td,
  ThemingProps,
  Flex,
  useMediaQuery,
} from '@chakra-ui/react';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

export type ForwardRefData = {
  reload: () => Promise<void>;
};

export interface TableHeader
  extends Omit<TableColumnHeaderProps, 'children' | 'minW' | 'maxW' | 'w'> {
  key: string;
  content: ReactNode;
}

export type LoadMoreRowsParams = {
  currentPage: number;
  pageSize: number;
  orderColumn: string;
  orderDirection: 'asc' | 'desc';
};

interface VirtualizedInfiniteTableProps {
  tableHeaders: TableHeader[];
  rowRenderer: ListRowRenderer;
  rowCount: number;
  isLoading?: boolean;
  isRowLoaded: (params: Index) => boolean;
  loadMoreRows: (params: LoadMoreRowsParams) => Promise<void>;
  visibleItemsCount?: number;
  itemHeight?: number;
  dynamicHeight?: (params: Index) => number;
  withoutRowsMessage?: string;
  pageSize?: number;
  orderColumn: string;
  orderDirection?: 'asc' | 'desc';
  canRenderEmptyRows?: boolean;
  variant?: ThemingProps<'Table'>['variant'];
  size?: ThemingProps<'Table'>['size'];
  bg?: string;
  colorFundo?: string;
  isLargerThan375?: boolean;
  color?: string;
  boxShadow?: string;
  isEdicaoManual?: boolean;
  isMobileProdutoTroca?: boolean;
  editarWidht?: boolean;
  editarLargura?: boolean;
  alterarBordaListagem?: string;
  isUpdateWidthTable?: boolean;
  paddingRight?: string;
  widthSizeScroll?: string;
  destacarItemInicial?: boolean;
  heightTable?: number;
}

export const VirtualizedInfiniteTable = forwardRef<
  ForwardRefData,
  VirtualizedInfiniteTableProps
>(
  (
    {
      tableHeaders,
      color,
      isEdicaoManual,
      rowRenderer,
      isUpdateWidthTable = false,
      rowCount,
      alterarBordaListagem = 'gray.100',
      isMobileProdutoTroca,
      isLoading = false,
      editarLargura = false,
      isRowLoaded,
      editarWidht = false,
      loadMoreRows,
      visibleItemsCount = 5,
      isLargerThan375 = false,
      itemHeight = 47,
      dynamicHeight,
      withoutRowsMessage = 'Nenhum resultado foi encontrado',
      pageSize = 10,
      orderColumn,
      orderDirection = 'asc',
      canRenderEmptyRows = false,
      variant = 'filled',
      size,
      bg,
      colorFundo = 'white',
      boxShadow = 'md',
      paddingRight,
      widthSizeScroll,
      destacarItemInicial = false,
      heightTable,
    },
    ref
  ) => {
    const isGettingItems = useRef(true);
    const currentPage = useRef(1);

    const [firstElementIsVisible, setFirstElementIsVisible] = useState(true);
    const [lastElementIsVisible, setLastElementIsVisible] = useState(true);

    const [isLargerThan520] = useMediaQuery('(min-width: 520px)');
    const isTranferenciaEstoque = window.location.pathname.includes(
      'transferencia-estoque'
    );

    const dataHasMoreThanMinimumVisibleItems = rowCount > visibleItemsCount;
    const height =
      dataHasMoreThanMinimumVisibleItems || (!!dynamicHeight && rowCount > 0)
        ? visibleItemsCount * itemHeight
        : rowCount * itemHeight;

    const latestProps = useRef({
      pageSize,
      orderColumn,
      orderDirection,
      loadMoreRows,
    });
    useEffect(() => {
      latestProps.current = {
        pageSize,
        orderColumn,
        orderDirection,
        loadMoreRows,
      };
    });

    async function reload() {
      currentPage.current = 1;
      isGettingItems.current = true;

      await latestProps.current.loadMoreRows({
        currentPage: currentPage.current,
        pageSize: latestProps.current.pageSize,
        orderColumn: latestProps.current.orderColumn,
        orderDirection: latestProps.current.orderDirection,
      });

      isGettingItems.current = false;
    }

    useEffect(() => {
      async function loadInitialRows() {
        await latestProps.current.loadMoreRows({
          currentPage: currentPage.current,
          pageSize: latestProps.current.pageSize,
          orderColumn: latestProps.current.orderColumn,
          orderDirection: latestProps.current.orderDirection,
        });

        isGettingItems.current = false;
      }

      loadInitialRows();
    }, []);

    useImperativeHandle(ref, () => ({
      reload,
    }));

    return (
      <Box
        position="relative"
        sx={{
          '&::-webkit-scrollbar': {
            height: '0',
            width: '0',
          },
          '& .virtualized_List::-webkit-scrollbar': {
            height: '0',
            width: '0',
          },
        }}
        h="full"
        bg={colorFundo}
        boxShadow={boxShadow}
        borderBottom={
          isLoading || rowCount === 0 || rowCount > visibleItemsCount
            ? ''
            : isEdicaoManual
            ? '1px solid #bbbbbb'
            : ''
        }
        borderRadius={isEdicaoManual ? '0' : 'md'}
        overflow={editarWidht && !isLargerThan520 ? 'scroll' : 'hidden'}
      >
        {isLoading && <LoadingPadrao />}

        <Table
          variant={variant}
          size={size}
          w={isUpdateWidthTable ? `calc(100% - 15px)` : 'full'}
        >
          <Thead>
            <Tr>
              {tableHeaders.map(({ content, key, ...rest }) => (
                <>
                  {isMobileProdutoTroca ? (
                    <>
                      {isLargerThan375 ? (
                        <Th
                          key={key}
                          color={color}
                          whiteSpace="normal"
                          userSelect="none"
                          overflow="hidden"
                        >
                          {}
                          <Flex {...rest} ml="-20px">
                            {content}
                          </Flex>
                        </Th>
                      ) : (
                        <Th
                          key={key}
                          color={color}
                          whiteSpace="normal"
                          userSelect="none"
                          overflow="hidden"
                          {...rest}
                        >
                          {}
                          <Flex ml="-20px">{content}</Flex>
                        </Th>
                      )}
                    </>
                  ) : (
                    <Th
                      key={key}
                      color={color}
                      whiteSpace="normal"
                      userSelect="none"
                      overflow="hidden"
                      {...rest}
                    >
                      {content}
                    </Th>
                  )}
                </>
              ))}
            </Tr>
          </Thead>
          {!isLoading && rowCount === 0 && (
            <Tbody>
              <Tr>
                <Td h={`${itemHeight}px`} colSpan={9999}>
                  {withoutRowsMessage}
                </Td>
              </Tr>
            </Tbody>
          )}
        </Table>

        <Box
          h={`${heightTable || height}px`}
          bg={bg}
          w={
            editarWidht && !isLargerThan520
              ? editarLargura
                ? 590
                : isTranferenciaEstoque
                ? 200
                : 440
              : widthSizeScroll || ''
          }
          minH="1px"
          position="relative"
          sx={{
            '& .ReactVirtualized__List': {
              paddingRight,

              '-ms-overflow-style': 'none',
              '&::-webkit-scrollbar-thumb': {
                background: 'gray.200',
                borderRadius: '5px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: 'gray.200',
              },
              '&::-webkit-scrollbar-track': {
                background: colorFundo,
              },
            },
            '& tr > td': { height: `${itemHeight}px`, whiteSpace: 'normal' },
            '& table:not(:first-of-type) tr > td': {
              _before: {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                width: 'full',
                borderTop: isEdicaoManual ? 'none' : '1px',
                borderColor: 'gray.100',
              },
            },
            '& table:not(:first-of-type) tr > td:first-of-type': {
              _before: {
                w: {
                  base: 'calc(100% - 1.25rem)', // "5" size
                  md: 'calc(100% -  1.5rem)', // "6" size
                  xl: 'calc(100% -  1.75rem)', // "7" size
                },
                ml: { base: 7, md: 6, xl: 7 },
              },
            },
            '& table:first-of-type tr:first-of-type': {
              borderColor: destacarItemInicial ? 'violet.500' : 'undefined',
              borderWidth: destacarItemInicial ? '2px' : 'undefined',
              backgroundColor: destacarItemInicial ? 'purple.50' : 'undefined',
            },
            '& table:not(:first-of-type) tr > td:last-of-type': {
              _before: {
                w: {
                  base: 'calc(100% - 1.25rem)', // "5" size
                  md: 'calc(100% -  1.5rem)', // "6" size
                  xl: 'calc(100% -  1.75rem)', // "7" size
                },
                mr: { base: 7, md: 6, xl: 7 },
              },
            },
          }}
        >
          {/*   <Box
            position="absolute"
            h="20px"
            w="full"
            bgGradient={`linear(to-b, ${alterarBordaListagem} 0%, rgba(0, 0, 0, 0) 100%)`}
            pointerEvents="none"
            zIndex="docked"
            transition="opacity 0.4s"
            opacity={firstElementIsVisible ? 0 : 1}
          /> */}

          <Box
            position="absolute"
            bottom={0}
            left={0}
            h="30px"
            w="full"
            bgGradient={`linear(to-t, ${alterarBordaListagem} 0%, rgba(0, 0, 0, 0) 100%)`}
            borderBottomRadius="md"
            pointerEvents="none"
            zIndex="docked"
            transition="opacity 0.4s"
            opacity={lastElementIsVisible ? 0 : 1}
          />

          <InfiniteLoader
            isRowLoaded={isRowLoaded}
            threshold={pageSize / 2}
            minimumBatchSize={pageSize}
            loadMoreRows={async () => {
              if (!isGettingItems.current) {
                isGettingItems.current = true;

                currentPage.current += 1;

                const params = {
                  currentPage: currentPage.current,
                  pageSize,
                  orderColumn,
                  orderDirection,
                };

                await loadMoreRows(params);

                isGettingItems.current = false;
              }
            }}
            rowCount={rowCount}
          >
            {({ onRowsRendered, registerChild }) => (
              <AutoSizer>
                {({ width, height: autoSizerHeight }) => {
                  const alreadyLoadRowCount = currentPage.current * pageSize;

                  const extraItemsCount = Math.min(
                    pageSize,
                    rowCount - alreadyLoadRowCount
                  );

                  const finalRowCount =
                    canRenderEmptyRows || alreadyLoadRowCount >= rowCount
                      ? rowCount
                      : alreadyLoadRowCount + extraItemsCount;

                  return (
                    <List
                      ref={registerChild}
                      width={
                        editarWidht && !isLargerThan520
                          ? editarLargura
                            ? 600
                            : isTranferenciaEstoque
                            ? 300
                            : 450
                          : width
                      }
                      height={autoSizerHeight}
                      rowCount={finalRowCount}
                      rowHeight={dynamicHeight || itemHeight}
                      rowRenderer={(props) => (
                        <Table
                          w="full"
                          variant={variant}
                          size={size}
                          key={props.key}
                        >
                          <Tbody>{rowRenderer(props)}</Tbody>
                        </Table>
                      )}
                      onRowsRendered={(props) => {
                        if (props.startIndex === 0) {
                          setFirstElementIsVisible(true);
                        } else {
                          setFirstElementIsVisible(false);
                        }

                        if (props.stopIndex === rowCount - 1) {
                          setLastElementIsVisible(true);
                        } else {
                          setLastElementIsVisible(false);
                        }

                        onRowsRendered(props);
                      }}
                    />
                  );
                }}
              </AutoSizer>
            )}
          </InfiniteLoader>
        </Box>
      </Box>
    );
  }
);
