import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { renderToString } from 'react-dom/server';
import {
  Box,
  Icon,
  Circle,
  Flex,
  useToken,
  Text,
  useMediaQuery,
  Divider,
} from '@chakra-ui/react';
import ReactApexChart from 'react-apexcharts';

import { obterMetaGeral } from 'helpers/data/getMetaGeral';
import api, { ResponseApi } from 'services/api';
import { moneyMask } from 'helpers/format/fieldsMasks';
import formatData from 'helpers/format/formatData';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { useMetasComissoesCadastroContext } from 'store/MetasComissoes/MetaComissoesCadastro';

import { SetaBottomIcon, SetaTopIcon } from 'icons';

type MetaDiariaResponse = {
  dia: number;
  periodo: Date;
  valorRecebidoAcumuladoPeriodo: number;
  valorMetaAcumuladoPeriodo: number;
  diferencaPeriodo: number;
};

type GraficoMetaGeralDaLojaProps = {
  mesAno: string;
  usuarioNaoPossuiPermissao?: boolean;
};

export const GraficoMetaGeralDaLoja = ({
  mesAno,
  usuarioNaoPossuiPermissao,
}: GraficoMetaGeralDaLojaProps) => {
  const [metaDiaria, setMetaDiaria] = useState<MetaDiariaResponse[]>([]);

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

  const {
    utilizaMetaGeral,
    setUtilizaMetaGeral,
    valorMetaGeral,
    setValorMetaGeral,
  } = useMetasComissoesCadastroContext();

  const [black, primary50, aquamarine500] = useToken('colors', [
    'black',
    'primary.50',
    'aquamarine.500',
  ]);

  const handleObterMetaDiariaComissao = useCallback(async () => {
    await setMetaDiaria([]);

    const response = await api.get<void, ResponseApi<MetaDiariaResponse[]>>(
      ConstanteEnderecoWebservice.META_COMISSAO_POR_DIA,
      {
        params: { periodo: mesAno },
      }
    );
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        setMetaDiaria(response.dados);
      }
    }
  }, [mesAno]);

  const dadosMetaGeralLojaPorDia = useCallback(() => {
    const newDadosMetaDiaria = [...metaDiaria];
    const diasPares = newDadosMetaDiaria.filter((meta) => meta.dia % 2 === 0);
    if (isLargerThan700) {
      return metaDiaria;
    }
    return diasPares;
  }, [isLargerThan700, metaDiaria])();

  const valorDadosGrafico = useCallback(() => {
    let valorAlcance = 0;
    if (dadosMetaGeralLojaPorDia.length > 0) {
      valorAlcance =
        dadosMetaGeralLojaPorDia[dadosMetaGeralLojaPorDia?.length - 1]
          .valorRecebidoAcumuladoPeriodo || 0;
    }

    if (valorMetaGeral > valorAlcance) {
      return valorMetaGeral;
    }
    return valorAlcance;
  }, [dadosMetaGeralLojaPorDia, valorMetaGeral])();

  const customLegend = useCallback(
    (dataPointIndex: any) => {
      if (dataPointIndex === undefined) return '';

      const meta = metaDiaria[dataPointIndex];
      const { diferencaPeriodo } = meta;
      const validandoMeta = Math.sign(diferencaPeriodo);

      const metaFoiUltrapassada = validandoMeta === 1;

      return renderToString(
        <Box
          pt="10px"
          pl="12px"
          pr="16px"
          borderRadius="base"
          w="250px"
          h="180px"
          bg="white"
        >
          <Text mb="8px" fontSize="14px" color="black">
            {formatData(meta.periodo)}
          </Text>
          <Flex mb="14px" justifyContent="center" alignItems="center">
            <Divider h="1px" bg={black} w="220px" />
          </Flex>
          <Flex justifyContent="left" alignItems="center">
            <Circle
              top="40%"
              transform={`translateY(-${diferencaPeriodo === 0 ? 10 : 120}%)`}
              bg={primary50}
              mr="10px"
              size="10px"
            />
            <Box>
              <Text fontSize="14px" color={primary50}>
                {moneyMask(meta.valorRecebidoAcumuladoPeriodo, true)}
              </Text>

              {diferencaPeriodo !== 0 && (
                <>
                  {utilizaMetaGeral && (
                    <Flex justifyContent="left" alignItems="center">
                      <Icon
                        mr="5px"
                        as={metaFoiUltrapassada ? SetaTopIcon : SetaBottomIcon}
                        color={metaFoiUltrapassada ? black : undefined}
                        boxSize="10px"
                      />

                      <Text mt="2px" fontSize="12px" color={black}>
                        {metaFoiUltrapassada
                          ? `${moneyMask(
                              diferencaPeriodo,
                              false
                            )}% acima da meta`
                          : `- ${moneyMask(
                              diferencaPeriodo * -1,
                              false
                            )}% abaixo da meta`}
                      </Text>
                    </Flex>
                  )}
                </>
              )}
            </Box>
          </Flex>
          <Flex justifyContent="left" alignItems="center">
            <Circle
              top="40%"
              transform="translateY(-70%)"
              bg={aquamarine500}
              mr="10px"
              size="10px"
            />
            <Box>
              <Text mt="12px" fontSize="14px" color={aquamarine500}>
                {moneyMask(meta.valorMetaAcumuladoPeriodo, true)}
              </Text>
              <Text mt="2px" fontSize="12px" color={black}>
                Meta
              </Text>
            </Box>
          </Flex>
        </Box>
      );
    },
    [aquamarine500, black, metaDiaria, primary50, utilizaMetaGeral]
  );

  useEffect(() => {
    async function obterValorMetaGeral() {
      const response = await obterMetaGeral(mesAno);
      if (response.id) {
        setUtilizaMetaGeral(response.utilizaMetaGeral);

        setValorMetaGeral(response.valorMetaGeral);
      }
    }
    obterValorMetaGeral();
  }, [mesAno, setUtilizaMetaGeral, setValorMetaGeral]);

  useEffect(() => {
    handleObterMetaDiariaComissao();
  }, [handleObterMetaDiariaComissao]);

  return (
    <Box
      pt={usuarioNaoPossuiPermissao ? undefined : '25px'}
      mt={usuarioNaoPossuiPermissao ? '25px' : undefined}
      pl="10px"
      boxShadow="0px 0px 6px #00000034"
      borderRadius="md"
      bg="white"
      w={usuarioNaoPossuiPermissao ? '100%' : isLargerThan900 ? '84%' : 'full'}
      h="304px"
    >
      <Flex>
        <Text
          pt={usuarioNaoPossuiPermissao ? '20px' : undefined}
          pl="18px"
          fontSize="16px"
          pb={isLargerThan700 ? undefined : '15px'}
          color="primary.50"
        >
          Meta geral da loja
        </Text>
      </Flex>
      {utilizaMetaGeral ? (
        <ReactApexChart
          options={{
            chart: {
              height: 100,
              width: 200,
              type: 'line',

              stacked: true,
              toolbar: {
                show: false,
              },
            },

            tooltip: {
              enabled: true,
              theme: 'dark',
              marker: {
                show: false,
              },
              custom({ dataPointIndex }: any) {
                return customLegend(dataPointIndex);
              },
            },

            grid: {
              padding: {
                left: 2,
                right: 20,
              },
              xaxis: {
                lines: {
                  show: true,
                },
              },
              yaxis: {
                lines: {
                  show: false,
                },
              },
            },

            fill: {
              type: 'solid',
              opacity: [0.2, 1],
            },
            labels: dadosMetaGeralLojaPorDia.map((meta) => String(meta.dia)),
            markers: {
              size: 0,
            },

            legend: {
              position: 'top',
              horizontalAlign: 'right',
            },

            yaxis: [
              {
                seriesName: 'meta',
                labels: {
                  show: false,
                },
                max: valorDadosGrafico,
                tickAmount: 5,
              },
              {
                max: valorDadosGrafico,
                tickAmount: 5,
                labels: {
                  show: true,
                  style: {
                    fontSize: '12px',
                  },

                  formatter: (value) => {
                    return `${moneyMask(value, true)} -`;
                  },
                },
              },
            ],
          }}
          series={[
            {
              name: 'Alcance',
              type: 'area',
              data: dadosMetaGeralLojaPorDia.map(
                (alcanceMeta) => alcanceMeta.valorRecebidoAcumuladoPeriodo
              ),
              color: primary50,
            },
            {
              name: 'meta',
              type: 'line',
              data: dadosMetaGeralLojaPorDia.map(
                (meta) => meta.valorMetaAcumuladoPeriodo
              ),
              color: aquamarine500,
            },
          ]}
          type="line"
          width="100%"
          style={{
            marginLeft: '-20px',
            marginRight: '18px',
            marginTop: '-20px',
          }}
          height="250px"
        />
      ) : (
        <Box pl="17px" pt="10px">
          Não existem dados para serem exibidos
        </Box>
      )}
    </Box>
  );
};
