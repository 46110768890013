import React, { ReactNode, memo } from 'react';
import {
  Flex,
  Menu,
  MenuProps,
  MenuButton,
  IconButton,
  Icon,
  MenuList,
  MenuItem,
  MenuItemProps,
  useDisclosure,
  PositionProps,
} from '@chakra-ui/react';
import { FiMoreVertical } from 'react-icons/fi';

import PortalFullscreen from 'components/PDV/Geral/PortalFullscreen';
import { ValidarPermissao } from 'components/update/Validacao/ValidarPermissao';
import { TooltipPermissao } from 'components/update/Validacao/TooltipPermissao';
import { IconType } from 'icons';

export interface ActionMenuItem extends Omit<MenuItemProps, 'children'> {
  content: ReactNode;
  funcionalidade?: string | string[];
  hidden?: boolean;
}

interface ActionsMenuProps extends Omit<MenuProps, 'children'> {
  items: ActionMenuItem[];
  isDisabled?: boolean;
  menuZIndex?: PositionProps['zIndex'];
  iconItem?: IconType;
  backgroundHoverColor?: string;
  buttonContent?: ReactNode;
  sizeIcon?: string;
}

export const ActionsMenu = memo(
  ({
    items,
    isDisabled: isMenuDisabled,
    menuZIndex,
    iconItem,
    sizeIcon,
    buttonContent,
    variant = 'linkDefault',
    backgroundHoverColor = 'gray.100',
    ...menuRest
  }: ActionsMenuProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
      <Flex maxH="5" alignItems="center" justifyContent="center">
        <Menu isLazy onOpen={onOpen} onClose={onClose} id="mostrarMais">
          <MenuButton
            as={IconButton}
            icon={
              buttonContent || (
                <Icon
                  as={iconItem || FiMoreVertical}
                  fontSize={iconItem ? sizeIcon || '13px' : sizeIcon || 'xl'}
                />
              )
            }
            variant={variant}
            p="5px"
            _hover={{
              backgroundColor: backgroundHoverColor,
            }}
            colorScheme="gray"
            borderRadius="md"
            size="sm"
            h="30px"
            w="2px"
            isDisabled={isMenuDisabled}
            {...menuRest}
          />
          {isOpen && (
            <PortalFullscreen>
              <MenuList zIndex={menuZIndex}>
                {items.map(
                  ({
                    content,
                    funcionalidade,
                    isDisabled,
                    icon,
                    hidden,
                    ...itemRest
                  }) =>
                    !hidden && (
                      <ValidarPermissao funcionalidade={funcionalidade}>
                        {({ permitido, bloqueio, icon: permissaoIcon }) => (
                          <TooltipPermissao
                            bloqueio={bloqueio}
                            isDisabled={permitido}
                          >
                            <MenuItem
                              {...itemRest}
                              isDisabled={isDisabled || !permitido}
                              icon={
                                permitido ? icon : <Icon as={permissaoIcon} />
                              }
                              id={`${content}`}
                            >
                              {content}
                            </MenuItem>
                          </TooltipPermissao>
                        )}
                      </ValidarPermissao>
                    )
                )}
              </MenuList>
            </PortalFullscreen>
          )}
        </Menu>
      </Flex>
    );
  }
);
