import { useCallback, useMemo, useRef, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  Text,
  Stack,
  Checkbox,
  GridItem,
  useMediaQuery,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { shallowEqual } from 'helpers/validation/shallowEqual';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';
import { ImprimirPDF } from 'helpers/impressoes/imprimirPDF';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';
import { useContextFiltrosContaspagar } from 'store/Financeiro/ContasPagar';
import ConstanteFuncionalidades from 'constants/permissoes';

import {
  LixeiraIcon,
  FinanceiroBaixarContas,
  SalvarInserirNovoIcon,
  CompartilharMenuIcon,
} from 'icons';
import InputDateRange from 'components/PDV/InputDateRange';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { TextValor } from 'components/PDV/Text/TextValor';
import {
  PagedTable,
  PagedTableForwardRefData,
} from 'components/update/Table/PagedTable';
import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import { PaginationData } from 'components/update/Pagination';
import { ButtonFuncionalidade } from 'components/update/Button/ButtonFuncionalidade';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { EmailModal } from 'components/PDV/CompartilharDocumentosFiscais/EmailModal';
import { BuscaAvancadaButton } from 'components/update/BuscaAvancadaButton';
import { ModalCompartilhar } from 'components/Modal/ModalCompartilhar';

import { ModalBaixarContas } from './ModalBaixarContas';
import { ModalBuscaAvancada } from './ModalBuscaAvancada';
import { ContasPagarItem } from './ContasPagarItem';
import { FormData, formDefaultValues, Conta } from './validationForm';

type Totais = {
  totalDescontos: number;
  totalMultasJuros: number;
  totalAPagar: number;
};

type InformacoesGeraisListagem = Totais & {
  quantidadeContas: number;
};

type GridPaginadaRetornoContasPagar = GridPaginadaRetorno<Conta> & Totais;

export function ListarContasPagar() {
  const history = useHistory();

  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');

  const {
    casasDecimais: { casasDecimaisValor },
  } = usePadronizacaoContext();

  const {
    lastFilters,
    setLastFilters,
    carregarDadosContexto,
  } = useContextFiltrosContaspagar();

  const [currentFilters, setCurrentFilters] = useState<FormData>(
    carregarDadosContexto.current
      ? lastFilters || formDefaultValues
      : formDefaultValues
  );
  const [hasFilters, setHasFilters] = useState(
    !!(lastFilters && carregarDadosContexto.current)
  );

  const formMethods = useForm<FormData>({
    defaultValues:
      carregarDadosContexto.current && lastFilters
        ? lastFilters
        : formDefaultValues,
  });

  const pagedTableRef = useRef<PagedTableForwardRefData>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [columnsData, setColumnsData] = useState<Conta[]>([]);
  const [
    informacoesGeraisListagem,
    setInformacoesGeraisListagem,
  ] = useState<InformacoesGeraisListagem>({
    quantidadeContas: 0,
    totalAPagar: 0,
    totalDescontos: 0,
    totalMultasJuros: 0,
  });

  const [deveSelecionarTodosItens, setDeveSelecionarTodosItens] = useState(
    false
  );

  const quantidadeItensSelecionados = useMemo(
    () => columnsData.filter((item) => item.selecionado).length,
    [columnsData]
  );
  const temItensSelecionados = quantidadeItensSelecionados > 0;

  function getTotaisItem(index: number) {
    const item = columnsData[index];

    const valorTotalMulta = (item.movimentacaoFinanceiraBaixa || []).reduce(
      (acc, curr) => acc + (curr?.multa || 0),
      0
    );
    const valorTotalJuros = (item.movimentacaoFinanceiraBaixa || []).reduce(
      (acc, curr) => acc + (curr?.juros || 0),
      0
    );
    const valorTotalDescontos = (item.movimentacaoFinanceiraBaixa || []).reduce(
      (acc, curr) => acc + (curr?.desconto || 0),
      0
    );

    return {
      valorTotalMulta,
      valorTotalJuros,
      valorTotalDescontos,
    };
  }

  function handleLimparItensSelecionados() {
    setColumnsData((valorAnterior) =>
      valorAnterior.map((item) => ({
        ...item,
        selecionado: false,
      }))
    );

    setDeveSelecionarTodosItens(false);
  }

  async function handleBaixarConta(indexes: number[]) {
    const {
      dataVencimento,
      fornecedorRazaoSocial,
      fornecedorNomeFantasia: fornecedorFantasia,
      dataEmissaoOperacao,
    } = indexes.length === 1 ? columnsData[indexes[0]] : ({} as Conta);

    const { sucesso } = await ModalBaixarContas({
      baixarContaItems: indexes.map((index) => {
        const item = columnsData[index];

        const totais = getTotaisItem(index);

        return {
          desconto: totais.valorTotalDescontos,
          juros: totais.valorTotalJuros,
          multa: totais.valorTotalMulta,
          valor: item.valor,
          movimentacaoFinanceiraId: item.movimentacaoFinanceiraId,
        };
      }),
      dataVencimento,
      fornecedorRazaoSocial,
      fornecedorFantasia,
      casasDecimaisValor,
      dataEmissao: dataEmissaoOperacao,
    });

    if (sucesso && pagedTableRef.current) {
      setDeveSelecionarTodosItens(false);
      pagedTableRef.current.reload();
    }
  }

  async function handleBaixarContasSelecionadas() {
    const indexes = columnsData
      .map(({ selecionado }, index) => ({ index, selecionado }))
      .filter((item) => item.selecionado)
      .map((item) => item.index);

    if (indexes.length > 0) {
      handleBaixarConta(indexes);
    }
  }

  async function handleExcluirContas(operacoes: string[]) {
    ModalConfirmacaoExcluir({
      title: 'Remover a conta?',
      text:
        'A conta será excluída da sua movimentação de contas a pagar. Essa ação não poderá ser desfeita. Você tem certeza que deseja remover?',
      confirmButtonText: 'Sim, remover!',
      cancelButtonText: 'Cancelar',
      callback: async (ok: boolean) => {
        if (ok) {
          const response = await api.delete<void, ResponseApi>(
            ConstanteEnderecoWebservice.CONTAS_PAGAR_EXCLUIR_CONTAS,
            { data: { operacoes } }
          );

          if (response) {
            if (response.avisos) {
              response.avisos.forEach((aviso) => toast.warning(aviso));
            }

            if (response.sucesso && pagedTableRef.current) {
              setDeveSelecionarTodosItens(false);
            }

            if (pagedTableRef.current) {
              pagedTableRef.current.reload();
            }
          }
        }
      },
    });
  }

  async function handleExcluirContasSelecionadas() {
    const operacoes = columnsData
      .filter((item) => item.selecionado)
      .map((item) => item.operacaoId);

    if (operacoes.length > 0) {
      handleExcluirContas(operacoes);
    }
  }

  async function handleCancelarBaixa(movimentacaoFinanceiraId: string) {
    const response = await api.delete<void, ResponseApi>(
      ConstanteEnderecoWebservice.CONTAS_PAGAR_EXCLUIR_BAIXA,
      { data: { movimentacoesFinanceiras: [movimentacaoFinanceiraId] } }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso && pagedTableRef.current) {
        setDeveSelecionarTodosItens(false);
        pagedTableRef.current.reload();
      }
    }
  }

  function handleNovaConta() {
    const hasLastFilters = !shallowEqual(currentFilters, formDefaultValues);
    if (hasLastFilters) carregarDadosContexto.current = true;
    history.push(ConstanteRotas.CONTAS_PAGAR_CADASTRAR_LANCAMENTO);
  }

  function handleEditarConta(id: string) {
    carregarDadosContexto.current = true;
    history.push(
      SubstituirParametroRota(
        ConstanteRotas.CONTAS_PAGAR_ALTERAR_LANCAMENTO,
        'id',
        id
      )
    );
  }

  function filtersSubmit(data: FormData) {
    const filtersIsDirty = !shallowEqual(data, currentFilters);
    const hasFiltersDirty = !shallowEqual(data, formDefaultValues);

    setHasFilters(hasFiltersDirty);

    if (filtersIsDirty) {
      formMethods.reset(data);

      setLastFilters(data);
      setCurrentFilters(data);
    }
  }

  const handleSubmit = formMethods.handleSubmit(filtersSubmit);

  const createFilters = () => {
    let filters;

    if (lastFilters && carregarDadosContexto.current) {
      filters = {
        ...lastFilters,
        clienteFornecedorId: lastFilters.clienteFornecedorId
          ? lastFilters.clienteFornecedorId.value
          : '',
        planoContasId: lastFilters.planoContasId || '',
        formaPagamentoId: lastFilters.formaPagamentoId || '',
        dataVencimentoInicio:
          lastFilters.dataVencimentoInicio?.toISOString() ?? '',
        dataVencimentoFim: lastFilters.dataVencimentoFim?.toISOString() ?? '',
        dataEmissaoInicio: lastFilters?.dataEmissaoInicio?.toISOString() ?? '',
        dataEmissaoFim: lastFilters?.dataEmissaoFim?.toISOString() ?? '',
        dataPagamentoInicio:
          lastFilters?.dataPagamentoInicio?.toISOString() ?? '',
        dataPagamentoFim: lastFilters?.dataPagamentoFim?.toISOString() ?? '',
        competencia: lastFilters?.competencia?.toISOString() ?? '',
        identificador: Number(lastFilters?.identificador) ?? 0,
      };

      carregarDadosContexto.current = false;
      setCurrentFilters(lastFilters);
    } else {
      filters = {
        dataVencimentoInicio: currentFilters?.dataVencimentoInicio
          ? currentFilters?.dataVencimentoInicio?.toISOString()
          : '',
        dataVencimentoFim: currentFilters?.dataVencimentoFim
          ? currentFilters?.dataVencimentoFim?.toISOString()
          : '',
        dataEmissaoInicio: currentFilters.dataEmissaoInicio
          ? currentFilters.dataEmissaoInicio.toISOString()
          : '',
        dataEmissaoFim: currentFilters.dataEmissaoFim
          ? currentFilters.dataEmissaoFim?.toISOString()
          : '',
        dataPagamentoInicio: currentFilters.dataPagamentoInicio
          ? currentFilters.dataPagamentoInicio.toISOString()
          : '',
        dataPagamentoFim: currentFilters.dataPagamentoFim
          ? currentFilters.dataPagamentoFim.toISOString()
          : '',
        clienteFornecedorId: currentFilters.clienteFornecedorId
          ? currentFilters.clienteFornecedorId?.value
          : '',
        competencia: currentFilters.competencia
          ? currentFilters.competencia.toISOString()
          : '',
        planoContasId: currentFilters.planoContasId || '',
        formaPagamentoId: currentFilters.formaPagamentoId || '',
        situacao: currentFilters.situacao ?? '',
        identificador: currentFilters.identificador
          ? Number(currentFilters.identificador)
          : 0,
        numeroDocumento: currentFilters.numeroDocumento || '',
        historico: currentFilters.historico || '',
        exibirFatura: currentFilters.exibirFatura,
        exibirItensFatura: currentFilters.exibirItensFatura,
      };
    }

    return filters;
  };

  const loadColumnsData = useCallback(
    async (paginationData: PaginationData) => {
      setIsLoading(true);

      const params = createFilters();

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetornoContasPagar>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.CONTAS_PAGAR_LISTAR_PAGINADO,
          paginationData,
          params
        )
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response.sucesso && response.dados) {
          setDeveSelecionarTodosItens(false);

          setColumnsData(
            (response.dados.registros || []).map((item) => ({
              ...item,
              selecionado: false,
            }))
          );

          setInformacoesGeraisListagem({
            quantidadeContas: response.dados.total || 0,
            totalAPagar: response.dados.totalAPagar || 0,
            totalDescontos: response.dados.totalDescontos || 0,
            totalMultasJuros: response.dados.totalMultasJuros || 0,
          });
        }
      }

      setIsLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentFilters]
  );

  async function handlePushModalBuscaAvancada() {
    const novosFiltros = await ModalBuscaAvancada({
      currentFilters,
    });
    filtersSubmit(novosFiltros);
  }

  function handleToggleItemSelecionado(index: number) {
    const itensSelecionados = [...columnsData];
    const itemSelecionado = itensSelecionados[index];

    itensSelecionados.splice(index, 1, {
      ...itemSelecionado,
      selecionado: !itemSelecionado.selecionado,
    });

    setColumnsData(itensSelecionados);
  }

  function handleToggleDeveSelecionarTodosItens() {
    setColumnsData((valorAnterior) =>
      valorAnterior.map((item) => {
        const contaEstaPaga =
          item.movimentacaoFinanceiraBaixa &&
          item.movimentacaoFinanceiraBaixa.length > 0 &&
          item.movimentacaoFinanceiraBaixa.some(
            (movimentacao) => movimentacao.dataPagamento
          );

        return {
          ...item,
          selecionado: contaEstaPaga ? false : !deveSelecionarTodosItens,
        };
      })
    );

    setDeveSelecionarTodosItens(!deveSelecionarTodosItens);
  }

  const handleImprimirRelatorio = useCallback(async () => {
    const dados = createFilters();

    const response = await api.get<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.CONTAS_PAGAR_RELATORIO_LISTAGEM,
      {
        params: dados,
      }
    );

    if (response.sucesso && response.dados) {
      ImprimirPDF(response.dados, 'relatorioContasPagar');
    }

    if (response?.avisos) {
      response.avisos.forEach((item) => toast.warning(item));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilters]);

  const handleEnvioEmailRelatorio = useCallback(async () => {
    const params = createFilters();

    EmailModal({
      filtroListaPaginada: {
        contasPagar: params,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilters]);

  const handleCompartilharOperacao = useCallback(() => {
    ModalCompartilhar({
      items: [
        {
          titulo: 'Relatório contas a pagar',
          onClickImpressao: (onClose) => {
            handleImprimirRelatorio();
            onClose();
          },
          onClickEmail: (onClose) => {
            handleEnvioEmailRelatorio();
            onClose();
          },
        },
      ],
    });
  }, [handleImprimirRelatorio, handleEnvioEmailRelatorio]);

  return (
    <>
      <FormProvider {...formMethods}>
        {temItensSelecionados ? (
          <Stack
            direction={{ base: 'column', sm: 'row' }}
            spacing={{ base: 3, sm: 6, md: 8 }}
            w="full"
          >
            <ButtonFuncionalidade
              funcionalidade={ConstanteFuncionalidades.CONTAS_PAGAR_BAIXA}
              borderRadius="full"
              variant="outlineDefault"
              colorScheme="gray"
              height="32px"
              padding="10px 24px"
              minW="160px"
              leftIcon={<Icon as={FinanceiroBaixarContas} fontSize="md" />}
              onClick={handleBaixarContasSelecionadas}
            >
              Baixar contas
            </ButtonFuncionalidade>

            <ButtonFuncionalidade
              funcionalidade={ConstanteFuncionalidades.CONTAS_PAGAR_EXCLUIR}
              borderRadius="full"
              variant="outlineDefault"
              colorScheme="gray"
              height="32px"
              padding="10px 24px"
              minW="160px"
              leftIcon={<Icon as={LixeiraIcon} fontSize="md" />}
              onClick={() => {
                handleExcluirContasSelecionadas();
              }}
            >
              Excluir contas
            </ButtonFuncionalidade>

            <Flex
              alignItems="center"
              justifyContent={{ base: 'space-between', sm: 'flex-start' }}
            >
              <Text fontSize="sm" lineHeight="none">
                {quantidadeItensSelecionados}
                {quantidadeItensSelecionados === 1
                  ? ' item selecionado'
                  : ' itens selecionados'}
              </Text>

              <Button
                size="xs"
                variant="link"
                colorScheme="red"
                textDecoration="none"
                _hover={{ textDecoration: 'none' }}
                ml="4"
                onClick={handleLimparItensSelecionados}
              >
                Limpar seleção
              </Button>
            </Flex>
          </Stack>
        ) : (
          <SimpleGridForm gridGap={['12px', '12px', '12px', '24px']}>
            <GridItem colSpan={[12, 12, 4, 4, 3, 3]}>
              <Box>
                <InputDateRange
                  name="dataVencimento"
                  placeholder="Selecione a data de vencimento"
                  startDateName="dataVencimentoInicio"
                  endDateName="dataVencimentoFim"
                  borderRadius="5px"
                  borderColor="gray.100"
                  size="sm"
                  onConfirm={handleSubmit}
                  isClearable
                  inputValueIsSmaller={false}
                />
              </Box>
            </GridItem>
            <GridItem colSpan={[12, 12, 5, 5, 5, 5]}>
              <Flex
                flexDir={['column', 'row']}
                justifyContent={['center', 'space-between', 'start']}
                gap={['12px', '12px', '12px', '24px']}
              >
                <BuscaAvancadaButton
                  borderRadius="full"
                  colorScheme={hasFilters ? 'blue' : 'gray'}
                  variant={hasFilters ? 'solid' : 'outlineDefault'}
                  bg="transparent"
                  height="32px"
                  setIsModalBuscaAvancadaOpen={handlePushModalBuscaAvancada}
                  hasFilters={hasFilters}
                  padding="10px 24px"
                  justifyContent="center"
                  minW={['155px', '155px', '155px', '188px']}
                  width={['full', '50%', '155px', '188px']}
                >
                  {hasFilters ? 'Filtro selecionado' : 'Busca avançada'}
                </BuscaAvancadaButton>

                <Button
                  borderRadius="full"
                  variant="outlineDefault"
                  colorScheme="gray"
                  height="32px"
                  borderColor="gray.100"
                  padding="10px 24px"
                  minW={['145px', '145px', '145px', '188px']}
                  width={['full', '50%', '145px', '188px']}
                  onClick={handleCompartilharOperacao}
                  leftIcon={
                    <Icon as={CompartilharMenuIcon} mr="0px" boxSize="14px" />
                  }
                >
                  Compartilhar
                </Button>
              </Flex>
            </GridItem>
            <GridItem colSpan={[12, 12, 3, 3, 4, 4]} textAlign="end">
              <ButtonFuncionalidade
                funcionalidade={ConstanteFuncionalidades.LANCAMENTO_CADASTRAR}
                colorScheme={isLoading ? 'gray' : 'secondary'}
                width={['full', 'full', '150px', '160px']}
                borderRadius="full"
                height="32px"
                padding="10px 24px"
                leftIcon={
                  <Icon
                    as={SalvarInserirNovoIcon}
                    fontSize="lg"
                    boxSize="14px"
                  />
                }
                isDisabled={isLoading}
                onClick={handleNovaConta}
              >
                Nova conta
              </ButtonFuncionalidade>
            </GridItem>
          </SimpleGridForm>
        )}
      </FormProvider>

      <Stack
        width="100%"
        spacing={{ base: '2', lg: '4' }}
        mt={{ base: 3, sm: 6, md: 8 }}
        direction={{ base: 'column', md: 'row' }}
        justifyContent={{ base: 'center', xl: 'flex-start' }}
        alignItems="center"
        minH={{ base: '64', md: '16' }}
        px="8"
        bg="primary.700"
        borderRadius="md"
      >
        <Flex
          w="full"
          maxW="400px"
          alignItems="center"
          justifyContent="center"
          flexDir="column"
          gap="4px"
        >
          <Text color="white" fontSize="sm">
            Quantidade de contas
          </Text>
          <Text color="white" fontSize="xl" lineHeight="none">
            {informacoesGeraisListagem.quantidadeContas}
          </Text>
        </Flex>

        <Divider
          orientation={!isSmallerThan900 ? 'vertical' : 'horizontal'}
          h={{ base: '1px', md: '12' }}
          w={{ base: 'full', md: '1px' }}
        />

        <Flex
          w="full"
          maxW="400px"
          alignItems="center"
          justifyContent="center"
          flexDir="column"
          gap="4px"
        >
          <Text color="white" fontSize="sm">
            Valor em desconto
          </Text>
          <TextValor
            casasDecimais={2}
            valor={informacoesGeraisListagem.totalDescontos}
            color="white"
            fontSize="xl"
            fontWeight="semibold"
            symbolFontSize="xs"
            symbolFontWeight="semibold"
          />
        </Flex>

        <Divider
          orientation={!isSmallerThan900 ? 'vertical' : 'horizontal'}
          h={{ base: '1px', md: '12' }}
          w={{ base: 'full', md: '1px' }}
        />

        <Flex
          w="full"
          maxW="400px"
          alignItems="center"
          justifyContent="center"
          flexDir="column"
          gap="4px"
        >
          <Text color="white" fontSize="sm">
            Valor em multas e juros
          </Text>
          <TextValor
            casasDecimais={2}
            valor={informacoesGeraisListagem.totalMultasJuros}
            color="white"
            fontSize="xl"
            fontWeight="semibold"
            symbolFontSize="xs"
            symbolFontWeight="semibold"
          />
        </Flex>

        <Divider
          orientation={!isSmallerThan900 ? 'vertical' : 'horizontal'}
          h={{ base: '1px', md: '12' }}
          w={{ base: 'full', md: '1px' }}
        />

        <Flex
          w="full"
          maxW="400px"
          alignItems="center"
          justifyContent="center"
          flexDir="column"
          gap="4px"
        >
          <Text color="white" fontSize="sm" align="center">
            Valor total no período
          </Text>
          <TextValor
            casasDecimais={2}
            valor={informacoesGeraisListagem.totalAPagar}
            color="yellow.600"
            fontSize="xl"
            fontWeight="semibold"
            symbolFontSize="xs"
            symbolFontWeight="semibold"
          />
        </Flex>
      </Stack>

      <PagedTable
        variant="simple-card"
        bg="transparent"
        boxShadow="none"
        size="sm"
        mt="3"
        paginationHasDivider={false}
        ref={pagedTableRef}
        sx={{
          '& tr > th': { borderBottom: 'none' },
          '& td:only-child': { bg: 'white', h: '60px', border: 'none' },
          '& tr': { boxShadow: 'none' },
        }}
        isLoading={isLoading}
        loadColumnsData={loadColumnsData}
        itemsTotalCount={informacoesGeraisListagem.quantidadeContas}
        defaultKeyOrdered="dataVencimento"
        defaultOrderDirection="asc"
        tableHeaders={[
          {
            key: 'checkboxSelecionarTodos',
            content: (
              <Checkbox
                onChange={() => handleToggleDeveSelecionarTodosItens()}
                isChecked={deveSelecionarTodosItens}
                boxShadow="inset 0px 3px 6px #00000029"
                colorScheme="primary"
                bg="white"
                mb="0"
              />
            ),
            isOrderable: false,
            w: '1px',
          },
          { key: 'dataVencimento', content: 'Dia de vencimento' },
          { key: 'fornecedor', content: 'Fornecedor', isOrderable: false },
          {
            key: 'planoContas',
            content: 'Plano de contas',
            isOrderable: false,
          },
          {
            key: 'valorTotal',
            content: 'Valor total',
            isOrderable: false,
            isNumeric: true,
          },
          {
            key: 'iconesStatusVencimento',
            content: '',
            isOrderable: false,
            w: '1px',
          },
          { key: 'acoes', content: 'Ações', isOrderable: false, w: '1px' },
        ]}
        renderTableRows={
          columnsData &&
          columnsData.map((item, index) => (
            <ContasPagarItem
              handleToggleLinhaProduto={handleToggleItemSelecionado}
              handleEditarConta={handleEditarConta}
              handleBaixarConta={() => handleBaixarConta([index])}
              handleExcluirConta={() => handleExcluirContas([item.operacaoId])}
              item={item}
              index={index}
              getTotaisItem={getTotaisItem}
              handleCancelarBaixa={handleCancelarBaixa}
            />
          ))
        }
      />
    </>
  );
}
