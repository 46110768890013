import ConstanteFuncionalidades from 'constants/permissoes';
import auth from 'modules/auth';

interface EnumRelatorioEstoqueProperty {
  label: string;
  value: number;
}

interface EnumRelatorioEstoque {
  POSICAO_ESTOQUE: number;
  POSICAO_ESTOQUE_PRECO_VENDA: number;
  POSICAO_ESTOQUE_PRECO_CUSTO: number;
  properties: EnumRelatorioEstoqueProperty[];
}

const possuiPermissaoVisualizarPrecoCusto = auth.possuiPermissao(
  ConstanteFuncionalidades.USUARIO_VISUALIZAR_PRECO_CUSTO
).permitido;

const enumCompletoRelatorioEstoque: EnumRelatorioEstoque = {
  POSICAO_ESTOQUE: 0,
  POSICAO_ESTOQUE_PRECO_VENDA: 1,
  POSICAO_ESTOQUE_PRECO_CUSTO: 2,

  properties: [
    {
      label: 'Posição do estoque',
      value: 0,
    },
    {
      label: 'Posição do estoque com preço de venda',
      value: 1,
    },
    {
      label: 'Posição do estoque com preço de custo',
      value: 2,
    },
  ],
};

function filtrarEnumRelatorioEstoquePorPermissao(
  enumRelatorio: EnumRelatorioEstoque
): EnumRelatorioEstoque {
  const enumFiltrado = {
    ...enumRelatorio,
    properties: [...enumRelatorio.properties],
  };

  if (!possuiPermissaoVisualizarPrecoCusto) {
    enumFiltrado.properties = enumFiltrado.properties.filter(
      (property) => property.value !== enumFiltrado.POSICAO_ESTOQUE_PRECO_CUSTO
    );
  }

  return enumFiltrado;
}

export const enumRelatorioEstoque = filtrarEnumRelatorioEstoquePorPermissao(
  enumCompletoRelatorioEstoque
);
