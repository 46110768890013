import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import { isValidCnpj, isValidCpf } from 'helpers/validation/IsValidCpfCnpj';

export type FormData = {
  codigoCliente: string;
  cpfCnpj: string;
};

const schema = yup.object().shape({
  codigoCliente: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  cpfCnpj: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .test('cpfCnpj', ConstanteMensagemValidacao.CNPJ_INVALIDO, (value) => {
      if (value) {
        if (value.length > 14) return isValidCnpj(value);
      }

      return true;
    })
    .test('cpfCnpj', ConstanteMensagemValidacao.CPF_INVALIDO, (value) => {
      if (value) {
        if (value.length <= 14) return isValidCpf(value);
      }

      return true;
    }),
});

export const yupResolver = yupResolverInstance(schema);
