import styled from 'styled-components';
import { CalendarioDataCadastroIcon } from 'icons';

export const Container = styled.div`
  margin: 20px 0px -20px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;

  height: 0px;

  color: var(--black) !important;

  &:last-child {
    margin-bottom: 20px;
  }
`;

export const DataText = styled.div`
  margin: 0;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.5;
`;

export const DataIcon = styled(CalendarioDataCadastroIcon)`
  cursor: pointer;

  font-size: 1rem;

  margin-left: 5px;
`;
