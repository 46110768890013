import React, { Fragment } from 'react';
import { Box, Text, VStack, HStack, Grid, Divider } from '@chakra-ui/react';

import { DecimalMask } from 'helpers/format/fieldsMasks';
import { useEditarParcelasContext } from 'store/PDV/EditarParcelas';
import { usePagamentoContext } from 'store/PDV/Pagamento';
import { formatDate } from 'helpers/format/formatStringDate';

import { ActionsMenu } from './ActionsMenu';

import { ContainerMovimentacoesFinanceirasProps } from '../types';

export const ContainerMovimentacoesFinanceiras = ({
  movimentacaoFinanceira,
  index,
  handleDeleteMovimentacaoFinanceira,
}: ContainerMovimentacoesFinanceirasProps) => {
  const {
    valor,
    dataVencimento,
    descricaoFormaPagto,
    descricaoParcelas,
    identificacaoAgrupamento,
    temParcelas,
    acrescimo,
  } = movimentacaoFinanceira;

  const { acrescimoFoiLimpo } = usePagamentoContext();
  const { movimentacaoFinanceiraEditando } = useEditarParcelasContext();

  return (
    <Fragment key={identificacaoAgrupamento}>
      {index !== 0 && (
        <Box px="4" w="full">
          <Divider borderColor="gray.100" />
        </Box>
      )}
      <Box
        bg={{ base: 'white', md: 'gray.50' }}
        px={6}
        py={3}
        boxShadow={{
          base: 'none',
          md: '0px 0px 4px 0px #00000029',
        }}
        borderRadius="md"
        w="full"
        h={{ base: '60px', xl: '70px' }}
      >
        <Grid
          h="full"
          maxW="full"
          alignItems="center"
          justifyContent="space-between"
          gap={2}
          templateColumns="1fr auto auto"
        >
          <VStack
            spacing={1}
            h="full"
            alignItems="flex-start"
            justifyContent="center"
          >
            <Text
              fontSize={{ base: 'xs', lg: 'sm', xl: 'md' }}
              fontWeight="bold"
              isTruncated
            >
              {descricaoFormaPagto}
            </Text>
            <HStack
              spacing={2}
              fontSize={{ base: '2xs', xl: 'xs' }}
              fontWeight="light"
              lineHeight="none"
            >
              {descricaoParcelas && temParcelas && (
                <>
                  <Text>{descricaoParcelas}</Text>
                  <Text>|</Text>
                </>
              )}
              <Text>{formatDate(dataVencimento)}</Text>
            </HStack>
          </VStack>

          <Text
            ml={2}
            mr={{ sm: 2, md: 4 }}
            fontSize={{ base: 'sm', xl: 'md' }}
            fontWeight="bold"
            lineHeight="none"
            whiteSpace="nowrap"
          >
            {acrescimoFoiLimpo
              ? `R$ ${DecimalMask(valor - acrescimo, 2)}`
              : `R$ ${DecimalMask(valor, 2)}`}
          </Text>

          <ActionsMenu
            movimentacaoFinanceira={movimentacaoFinanceira}
            handleDeleteMovimentacaoFinanceira={
              handleDeleteMovimentacaoFinanceira
            }
            isDisabled={!!movimentacaoFinanceiraEditando}
          />
        </Grid>
      </Box>
    </Fragment>
  );
};
