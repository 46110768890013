import { Box, Text, VStack, Flex, Td, Tr } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { enumFormaPagamentoZoop } from 'constants/enum/enumFormaPagamentoZoop';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import api, { ResponseApi } from 'services/api';
import { setDateMaxHours } from 'helpers/data/setHoursDate';
import { useZoopContext } from 'store/Zoop/ZoopContext';
import { shallowEqual } from 'helpers/validation/shallowEqual';
import { moneyMask } from 'helpers/format/fieldsMasks';
import { formatDateHourMinute } from 'helpers/format/formatStringDate';
import {
  enumStatusTransacoesZoopOptions,
  enumStatusTransacoesZoopLabels,
} from 'constants/enum/enumSituacaoTransacoesZoop';
import { EnumStatusPagamentos } from 'constants/enum/enumPagamentos';

import InputDateRange from 'components/PDV/InputDateRange';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import {
  PagedTable,
  PagedTableForwardRefData,
} from 'components/update/Table/PagedTable';
import {
  GridPaginadaConsulta,
  GridPaginadaRetorno,
} from 'components/Grid/Paginacao';
import { BuscaAvancadaButton } from 'components/update/BuscaAvancadaButton';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import {
  ActionMenuItem,
  ActionsMenu,
} from 'components/update/Table/ActionsMenu';
import { WhatsAppModal } from 'components/PDV/CompartilharDocumentosFiscais/WhatsAppModal';

import { ModalBuscaAvancada } from './components/ModalBuscaAvancada';
import {
  formDefaultValues,
  FormData,
  TransacoesResponse,
  Cliente,
} from './validationForm';

import { Container } from '../Layout/Container';

export const Transacoes = () => {
  const [transacoes, setTransacoes] = useState<TransacoesResponse[]>([]);
  const [hasFilters, setHasFilters] = useState(false);
  const [totalTransacoes, setTotalTransacoes] = useState(0);
  const [isModalBuscaAvancadaOpen, setIsModalBuscaAvancadaOpen] = useState(
    false
  );
  const [currentFilters, setCurrentFilters] = useState<FormData>({
    ...formDefaultValues,
  });

  const { isLoading, setIsLoading } = useZoopContext();

  const pagedTableRef = useRef<PagedTableForwardRefData>(null);

  const formMethods = useForm<FormData>({
    defaultValues: formDefaultValues,
  });

  const url = process.env.REACT_APP_MULTIPAY_URL;

  const { reset, handleSubmit } = formMethods;

  const filtersSubmit = handleSubmit((data) => {
    setIsLoading(true);
    setHasFilters(true);
    setCurrentFilters(data);
    setIsLoading(false);
  });

  const handleSubmitModalBuscaAvancada = (data: FormData) => {
    reset(data);
    const filtersIsDirty = !shallowEqual(data, formDefaultValues);
    setCurrentFilters(data);

    if (filtersIsDirty) {
      setHasFilters(true);
    }
  };

  const handleReset = useCallback(() => {
    reset({
      ...formDefaultValues,
    });
    setHasFilters(false);
  }, [reset]);

  const handleAtualizarStatusTransacao = async (link: string) => {
    setIsLoading(true);
    const response = await api.get<void, ResponseApi<boolean>>(
      ConstanteEnderecoWebservice.TRANSACAO_ZOOP_ATUALIZAR_STATUS,
      {
        params: {
          identificador: link,
        },
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((avisos) => toast.warning(avisos));
      }
      if (response.sucesso) {
        window.location.reload();
      }
    }
    setIsLoading(false);
  };

  const handleVisualizarBoleto = (link: string) => {
    window.open(link, '_blank')?.focus();
  };

  const handleCopiarChavePix = (chavePix: string) => {
    navigator.clipboard.writeText(chavePix);
    toast.success('Chave PIX copiada com sucesso!');
  };

  const handleOpenLink = (linkTransacao: string) => {
    window.open(linkTransacao, '_blank');
  };

  const handleEnviarWhatsApp = async (link: string, cliente: Cliente) => {
    if (cliente) {
      WhatsAppModal({
        cliente,
        messageLinkZoop: `Olá ${cliente.nome}! Segue o link para pagamento: ${link}`,
      });
    }
  };

  const paginationHandle = useCallback(
    async (paginationData: GridPaginadaConsulta) => {
      setIsLoading(true);

      const filtros = {
        dataCriacaoInicio: currentFilters.dataCriacaoInicio.toISOString(),
        dataCriacaoFim: setDateMaxHours(
          new Date(currentFilters.dataCriacaoFim)
        ).toISOString(),
        clienteFornecedorId: currentFilters?.clienteFornecedorId
          ? currentFilters.clienteFornecedorId.value
          : '',
      };

      const params = {
        ...filtros,
        ...(currentFilters.numeroOperacao !== null && {
          numeroOperacao: currentFilters.numeroOperacao,
        }),
        ...(currentFilters.status !== null && {
          status: currentFilters.status,
        }),
        ...(currentFilters.tipoPagamento !== null && {
          tipoPagamento: currentFilters.tipoPagamento,
        }),
      };

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<TransacoesResponse>>
      >(
        `${formatQueryPagegTable(
          ConstanteEnderecoWebservice.TRANSACOES_ZOOP_LISTAR_PAGINADO,
          paginationData,
          params
        )}`
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((avisos) => toast.warning(avisos));
        }
        if (response.sucesso && response.dados) {
          setTransacoes(response.dados.registros);
          setTotalTransacoes(response.dados.total);
        }
      }
      setIsLoading(false);
    },

    [currentFilters, setIsLoading]
  );

  return (
    <Container ml="2px" padding="1px">
      {isLoading && <LoadingPadrao />}
      <FormProvider {...formMethods}>
        <Flex
          width="100%"
          direction={{ base: 'column', md: 'row', lg: 'row' }}
          justify="space-between"
          alignItems="center"
          minH={{ base: '200px', md: '16', lg: '16' }}
          p="28px"
          bg="transparent"
          gap="0px"
          borderRadius="md"
          borderBottomRadius="none"
        >
          <Flex
            gap="24px"
            direction={{ base: 'column', md: 'row', lg: 'row' }}
            w="full"
          >
            <Box width={{ base: 'full', md: '260px', lg: '260px' }}>
              <VStack
                align="flex-start"
                gap="0px"
                spacing="0px"
                width={{ base: 'full', md: '260px', lg: '260px' }}
              >
                <Text
                  color="black"
                  fontSize="sm"
                  fontWeight="semibold"
                  lineHeight="1"
                  mb="4px"
                >
                  Período de emissão
                </Text>
                <InputDateRange
                  borderColor="gray.200"
                  borderRadius="md"
                  name="dataCriacao"
                  size="md"
                  onConfirm={() => filtersSubmit()}
                  startDateName="dataCriacaoInicio"
                  endDateName="dataCriacaoFim"
                  maxDate={new Date()}
                />
              </VStack>
            </Box>
            <Box minW="160px">
              <SelectPadrao
                name="tipoPagamento"
                labelColor="black"
                label="Tipo"
                isSearchable={false}
                size="md"
                onSelect={() => filtersSubmit()}
                options={enumFormaPagamentoZoop}
              />
            </Box>

            <BuscaAvancadaButton
              borderRadius="full"
              mt="18px"
              colorScheme={hasFilters ? 'blue' : 'gray'}
              variant={hasFilters ? 'solid' : 'outlineDefault'}
              bg="transparent"
              height="36px"
              setIsModalBuscaAvancadaOpen={setIsModalBuscaAvancadaOpen}
              hasFilters={hasFilters}
              padding="10px 24px"
              borderColor="gray.300"
              color="gray.500"
              justifyContent="center"
              minW={['full', '155px', '155px', '188px']}
              width={['full', 'full', '155px', '188px']}
            >
              {hasFilters ? 'Filtro selecionado' : 'Busca avançada'}
            </BuscaAvancadaButton>
          </Flex>
        </Flex>
      </FormProvider>
      <Box position="relative" padding="28px">
        <PagedTable
          ref={pagedTableRef}
          variant="simple-card"
          bg="transparent"
          boxShadow="none"
          size="sm"
          mt="3"
          paginationHasDivider={false}
          sx={{
            '& tr > th': { borderBottom: 'none' },
            '& td:only-child': {
              bg: 'white',
              h: '72px',
              border: 'none',
              boxShadow: 'none',
            },
            'table tbody tr': { boxShadow: 'none' },
          }}
          itemsTotalCount={totalTransacoes}
          loadColumnsData={paginationHandle}
          tableHeaders={[
            {
              content: 'Dia de emissão',
              key: 'dataCriacaoInicio',
              isOrderable: false,
              w: '6%',
            },
            {
              content: 'Dia de vencimento',
              key: 'dataVencimento',
              isOrderable: false,
              w: '6%',
            },
            {
              content: 'Operação',
              key: 'operacao',
              isOrderable: false,
              w: '24%',
            },
            {
              content: 'Nome do cliente',
              key: 'cliente',
              isOrderable: false,
              w: '28%',
            },
            {
              content: 'Tipo',
              key: 'tipoPagamento',
              isOrderable: false,
              w: '10%',
            },
            {
              content: 'Situação',
              key: 'status',
              isOrderable: false,
              w: '10%',
              textAlign: 'right',
            },
            {
              content: 'Valor total',
              key: 'valorTotal',
              isOrderable: false,
              w: '10%',
              textAlign: 'right',
            },
            {
              content: 'Ações',
              key: 'Acoes',
              isOrderable: false,
              pr: '8px',
              w: 'fit-content',
            },
          ]}
          defaultKeyOrdered="dataCriacao"
          defaultOrderDirection="desc"
          renderTableRows={transacoes.map((transacao, index) => {
            const dataCancelamento = transacao?.parcelas[0]?.dataCancelamento;
            const dataPagamento = transacao?.parcelas[0]?.dataPagamento;

            const dateVencimento = formatDateHourMinute(
              transacao?.parcelas[0]?.dataVencimento || new Date()
            ).slice(0, 11);

            const dateCriacao = formatDateHourMinute(
              transacao.dataCriacao || new Date()
            ).slice(0, 11);

            const tipoPix = 'PIX';

            const statusTransacao = dataCancelamento
              ? enumStatusTransacoesZoopOptions.CANCELADO
              : dataPagamento
              ? enumStatusTransacoesZoopOptions.PAGO
              : enumStatusTransacoesZoopOptions.PENDENTE;

            const pixPago =
              transacao.status === EnumStatusPagamentos.PAID ||
              transacao.status === EnumStatusPagamentos.SUCCEEDED;

            const pixCanceladoOuFalha =
              transacao.status === EnumStatusPagamentos.CANCELED ||
              transacao.status === EnumStatusPagamentos.FAILED;

            const statusTransacaoPix = pixPago
              ? enumStatusTransacoesZoopOptions.PAGO
              : pixCanceladoOuFalha
              ? enumStatusTransacoesZoopOptions.CANCELADO
              : enumStatusTransacoesZoopOptions.PENDENTE;

            const tipoStatus =
              transacao?.tipoTransacao === tipoPix
                ? statusTransacaoPix
                : statusTransacao;

            const statusTransacaoText =
              enumStatusTransacoesZoopLabels[tipoStatus] || '';

            const optionsAcoes: ActionMenuItem[] = (() => {
              const linkPix = `${url}/pix/${transacao.zoopTransacaoId}`;
              const linkBoleto = `${url}/boleto/${transacao.identificadorOperacao}`;
              const hasLinkQrCode = !!transacao.linkQrCode;
              const tipoBoleto = 'BOLETO';

              const optionVisualizarBoleto = {
                funcionalidade: '',
                content: 'Visualizar boleto',
                onClick: () => {
                  handleVisualizarBoleto(transacao.linkQrCode);
                },
              };

              const optionCopiarChavePix = {
                funcionalidade: '',
                content: 'Copiar chave pix',
                onClick: () => {
                  handleCopiarChavePix(transacao.linkQrCode);
                },
              };

              if (transacao.tipoTransacao === tipoBoleto) {
                let acoesBoleto = [
                  {
                    funcionalidade: '',
                    content: 'Abrir link',
                    onClick: () => {
                      handleOpenLink(linkBoleto);
                    },
                  },

                  {
                    funcionalidade: '',
                    content: 'Atualizar status',
                    onClick: () => {
                      handleAtualizarStatusTransacao(transacao.zoopTransacaoId);
                    },
                  },

                  {
                    funcionalidade: '',
                    content: 'Enviar WhatsApp',
                    onClick: () => {
                      handleEnviarWhatsApp(linkBoleto, {
                        nome: transacao.cliente,
                      });
                    },
                  },
                ];
                if (hasLinkQrCode) {
                  acoesBoleto = [...acoesBoleto, optionVisualizarBoleto];
                }
                return acoesBoleto;
              }

              let acoesPix = [
                {
                  funcionalidade: '',
                  content: 'Abrir link',
                  onClick: () => {
                    handleOpenLink(linkPix);
                  },
                },
                {
                  funcionalidade: '',
                  content: 'Atualizar status',
                  onClick: () => {
                    handleAtualizarStatusTransacao(transacao.zoopTransacaoId);
                  },
                },
                {
                  funcionalidade: '',
                  content: 'Enviar WhatsApp',
                  onClick: () => {
                    handleEnviarWhatsApp(linkPix, {
                      nome: transacao.cliente,
                    });
                  },
                },
              ];

              if (statusTransacao !== enumStatusTransacoesZoopOptions.PAGO) {
                acoesPix = [...acoesPix, optionCopiarChavePix];
              }

              return acoesPix;
            })();

            return (
              <>
                <Box h={index === 0 ? '0' : '8px'} />
                <Tr
                  fontSize="sm"
                  key={transacao.id}
                  sx={{
                    '& > td': {
                      bg: 'white',
                      color: 'gray.700',
                      fontWeight: 'normal',
                      fontSize: '14px',
                      height: '72px',
                    },
                  }}
                >
                  <Td>
                    <Box>
                      <Text>{dateCriacao}</Text>
                    </Box>
                  </Td>
                  <Td>
                    <Box>
                      <Text>{dateVencimento}</Text>
                    </Box>
                  </Td>
                  <Td>
                    <Box>
                      <Text>VENDA</Text>
                      <Text as="span" color="gray.700" fontSize="12px">
                        {transacao.observacao}
                      </Text>
                    </Box>
                  </Td>

                  <Td>
                    <Text>{transacao.cliente}</Text>
                  </Td>
                  <Td>
                    <Box>
                      <Text>{transacao.tipoTransacao}</Text>
                    </Box>
                  </Td>
                  <Td textAlign="end">
                    <Box>
                      <Text as="span" color="gray.700">
                        {statusTransacaoText}
                      </Text>
                    </Box>
                  </Td>

                  <Td textAlign="right">
                    <Flex fontWeight="semibold" justifyContent="flex-end">
                      <Text as="span" mr="1" fontSize="10px">
                        R$
                      </Text>
                      <Text>{moneyMask(transacao.valorTotal, false)}</Text>
                    </Flex>
                  </Td>
                  <Td>
                    <ActionsMenu items={optionsAcoes} />
                  </Td>
                </Tr>
              </>
            );
          })}
        />
      </Box>
      <ModalBuscaAvancada
        isOpen={isModalBuscaAvancadaOpen}
        onClose={() => {
          setIsModalBuscaAvancadaOpen(false);
        }}
        onClear={handleReset}
        handleSubmitModalBuscaAvancada={handleSubmitModalBuscaAvancada}
        filtros={formMethods.watch()}
      />
    </Container>
  );
};
