import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  Dispatch,
  SetStateAction,
  useRef,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import ConstanteRotasPDV from 'constants/rotasPDV';
import { useOperacaoContext } from 'store/PDV/Operacao';
import obterOperacaoComPagamentos, {
  OperacaoTotais,
  OperacaoMovimentacaoFinanceira,
} from 'helpers/api/Operacao/obterOperacaoComPagamentos';
import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';

import { useInformacoesGeraisContext } from './InformacoesGerais';

interface PagamentoContextProps {
  totais?: OperacaoTotais;
  setTotais: Dispatch<SetStateAction<OperacaoTotais | undefined>>;
  movimentacoesFinanceiras?: OperacaoMovimentacaoFinanceira[];
  setMovimentacoesFinanceiras: Dispatch<
    SetStateAction<OperacaoMovimentacaoFinanceira[]>
  >;
  valorRestante: number;
  valorFaltaPagar: number;
  valorPago: number;
  somarValorTotalPagoSemAcrescimo: number;
  troco: number;
  setValorTotalVenda: React.Dispatch<React.SetStateAction<number>>;
  valorTotalVenda: number;
  handleAtualizarValores: () => Promise<void>;
  setAcrescimoFoiLimpo: React.Dispatch<React.SetStateAction<boolean>>;
  setAparecerValorAcrescimoHeaderPdv: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  acrescimoFoiLimpo: boolean;
  aparecerValorAcrescimoHeaderPdv: boolean;
}

export const PagamentoContext = createContext<PagamentoContextProps>(
  {} as PagamentoContextProps
);

interface PagamentoProviderProps {
  children: React.ReactNode;
  onModalAdicionarPagamentoOpen: () => void;
}

export default function PagamentoProvider({
  children,
  onModalAdicionarPagamentoOpen,
}: PagamentoProviderProps): JSX.Element {
  const history = useHistory();
  const { setValue, reset } = useFormContext();
  const {
    setOperacaoIsLoading,
    operacaoId,
    setInformacoesCliente,
  } = useOperacaoContext();

  const [totais, setTotais] = useState<OperacaoTotais>();
  const [acrescimoFoiLimpo, setAcrescimoFoiLimpo] = useState(false);
  const [
    aparecerValorAcrescimoHeaderPdv,
    setAparecerValorAcrescimoHeaderPdv,
  ] = useState(false);
  const [movimentacoesFinanceiras, setMovimentacoesFinanceiras] = useState(
    [] as OperacaoMovimentacaoFinanceira[]
  );
  const [valorTotalVenda, setValorTotalVenda] = useState(0);
  const [valorRestante, setValorRestante] = useState(0);
  const { setVendedorPdv } = useInformacoesGeraisContext();

  const somarValorTotalPagoSemAcrescimo = (
    movimentacoesFinanceiras || []
  ).reduce((acc, curr) => acc + curr.valor - curr.acrescimo, 0);
  const valorPago = useMemo(
    () =>
      movimentacoesFinanceiras && movimentacoesFinanceiras.length > 0
        ? movimentacoesFinanceiras.reduce(
            (previousValue, currentValue) => previousValue + currentValue.valor,
            0
          )
        : 0,
    [movimentacoesFinanceiras]
  );
  const troco = useMemo(
    () =>
      movimentacoesFinanceiras && movimentacoesFinanceiras.length > 0
        ? movimentacoesFinanceiras.reduce(
            (previousValue, currentValue) => previousValue + currentValue.troco,
            0
          )
        : 0,
    [movimentacoesFinanceiras]
  );

  const valorTotalCompraComAcrescimosEDescontos = totais
    ? Number(
        totais.valorTotalItensComDesconto +
          (totais.valorTotalFrete || 0) +
          (totais.valorTotalOutrasDespesas || 0) -
          (totais.valorTotalDescontoAdicional || 0)
      )
    : 0;

  const validandoValorFaltaPagar = () => {
    if (
      somarValorTotalPagoSemAcrescimo > valorTotalCompraComAcrescimosEDescontos
    ) {
      return 0;
    }
    return (
      valorTotalCompraComAcrescimosEDescontos - somarValorTotalPagoSemAcrescimo
    );
  };
  const valorFaltaPagar = validandoValorFaltaPagar();

  const latestProps = useRef({
    history,
    operacaoId,
    reset,
    setOperacaoIsLoading,
    setValue,
    onModalAdicionarPagamentoOpen,
  });
  useEffect(() => {
    latestProps.current = {
      history,
      operacaoId,
      reset,
      setOperacaoIsLoading,
      setValue,
      onModalAdicionarPagamentoOpen,
    };
  });

  const handleAtualizarValores = async () => {
    if (latestProps.current.operacaoId) {
      latestProps.current.setOperacaoIsLoading(true);

      const operacao = await obterOperacaoComPagamentos(
        latestProps.current.operacaoId
      );

      if (
        operacao &&
        (operacao.identificacaoTipoOperacao ===
          IdentificacaoTipoOperacaoEnum.PEDIDO ||
          operacao.identificacaoTipoOperacao ===
            IdentificacaoTipoOperacaoEnum.ORCAMENTO)
      ) {
        setVendedorPdv(operacao.vendedorOpcaoSelect);
        latestProps.current.setValue('vendedor', operacao.vendedorOpcaoSelect);
        latestProps.current.setValue(
          'cliente',
          operacao.clienteFornecedorOpcaoSelect
        );
        setInformacoesCliente(operacao.clienteFornecedorOpcaoSelect);
        latestProps.current.setValue(
          'tabelaPreco',
          operacao.tabelaPrecoOpcaoSelect
            ? {
                value: operacao.tabelaPrecoOpcaoSelect.id,
                name: operacao.tabelaPrecoOpcaoSelect.nome,
              }
            : null
        );
        latestProps.current.setValue('observacao', operacao.observacao);
        latestProps.current.setValue('numeroOperacao', operacao.numeroOperacao);
        latestProps.current.setValue(
          'identificacaoTipoOperacao',
          operacao.identificacaoTipoOperacao
        );

        if ((operacao.movimentacoesFinanceiras || []).length <= 0) {
          latestProps.current.onModalAdicionarPagamentoOpen();
        }

        setMovimentacoesFinanceiras(operacao.movimentacoesFinanceiras);
        setTotais(operacao.totais);
      } else {
        latestProps.current.history.push(ConstanteRotasPDV.PDV_HOME);
      }

      latestProps.current.setOperacaoIsLoading(false);
    }
  };

  useEffect(() => {
    handleAtualizarValores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newValorRestante = totais
      ? totais.valorTotal - Number(valorPago.toFixed(2))
      : 0;

    setValorRestante(newValorRestante > 0 ? newValorRestante : 0);
  }, [totais, valorPago]);

  useEffect(() => {
    setValue(
      'possuiMovimentacaoFinanceira',
      !!movimentacoesFinanceiras && movimentacoesFinanceiras.length > 0
    );
  }, [movimentacoesFinanceiras, setValue]);

  return (
    <PagamentoContext.Provider
      value={{
        totais,
        setAcrescimoFoiLimpo,
        valorFaltaPagar,
        setAparecerValorAcrescimoHeaderPdv,
        aparecerValorAcrescimoHeaderPdv,
        somarValorTotalPagoSemAcrescimo,
        acrescimoFoiLimpo,
        setValorTotalVenda,
        valorTotalVenda,
        handleAtualizarValores,
        setTotais,
        movimentacoesFinanceiras,
        setMovimentacoesFinanceiras,
        valorRestante,
        valorPago,
        troco,
      }}
    >
      {children}
    </PagamentoContext.Provider>
  );
}

export function usePagamentoContext(): PagamentoContextProps {
  const context = useContext(PagamentoContext);

  if (!context)
    throw new Error(
      'PagamentoContext must be used within a PagamentoProvider.'
    );

  return context;
}
