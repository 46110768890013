import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

const schema = (hasNotaFiscal: boolean) =>
  yup.object().shape({
    motivoCancelamento: yup
      .string()
      .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
      .max(255, ConstanteMensagemValidacao.MAX_LENGTH_255),
    ...(hasNotaFiscal
      ? {
          motivoCancelamentoNotaFiscal: yup
            .string()
            .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
            .min(15, ConstanteMensagemValidacao.MIN_LENGTH_15)
            .max(255, ConstanteMensagemValidacao.MAX_LENGTH_255),
        }
      : {}),
  });

export const yupResolver = (hasNotaFiscal: boolean) =>
  yupResolverInstance(schema(hasNotaFiscal));

export const useForm = useFormInstance;
