import { Box, Flex, Text } from '@chakra-ui/react';
import Lottie from 'lottie-react';
import { FiAlertCircle } from 'react-icons/fi';

import { formatDateHourMinute } from 'helpers/format/formatStringDate';
import AcaoConfirmada from 'assets/animations/acao_confirmada.json';

type InfoHeaderProps = {
  subtitulo: string;
  isFormularioSalvo: boolean;
  isPlaying: boolean;
  handleAnimationComplete: (isCompleto: boolean) => void;
  alertDescription?: string;
};

export const InfoHeader = ({
  subtitulo,
  isFormularioSalvo,
  isPlaying,
  handleAnimationComplete,
  alertDescription,
}: InfoHeaderProps) => {
  return (
    <Box pb="30px">
      {alertDescription ? (
        <Flex
          mb="3px"
          justifyContent="center"
          alignItems="center"
          whiteSpace={['normal', 'nowrap']}
          borderRadius="4px"
          py="5px"
          color="black"
          px="15px"
          bg="yellow.400"
        >
          <FiAlertCircle size={16} />
          <Text ml="5px">{alertDescription}</Text>
        </Flex>
      ) : (
        <Flex h="25px">
          {isFormularioSalvo && isPlaying ? (
            <>
              {isPlaying && (
                <Flex justifyContent="flex-start" alignItems="center">
                  <Box
                    boxSize="30px"
                    sx={{
                      'svg path': { stroke: 'secondary.300' },
                    }}
                  >
                    <Lottie
                      animationData={AcaoConfirmada}
                      autoPlay={false}
                      loop={isPlaying}
                      onLoopComplete={() => {
                        handleAnimationComplete(false);
                      }}
                      style={{
                        width: '25px',
                        height: '25px',
                      }}
                      // O isStopped existe no componente Lottie, mas não esta adicionado no Ts da lib
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      isStopped={isPlaying}
                    />
                  </Box>

                  <Text whiteSpace="nowrap" color="secondary.300">
                    Cadastro salvo
                  </Text>
                </Flex>
              )}
            </>
          ) : (
            <>
              {subtitulo && (
                <Text fontSize="12px" whiteSpace="nowrap" color="white">
                  Última alteração: {formatDateHourMinute(subtitulo)}
                </Text>
              )}
            </>
          )}
        </Flex>
      )}
    </Box>
  );
};
