import React from 'react';
import {
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';

import CadastrarCliente from 'pages/ClientesFornecedores/Formulario/Cadastrar';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { BrowserRouter, Route } from 'react-router-dom';

interface ModalCadastroClienteProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  nomeOuCpfCnpjCliente: string;
  novoClienteCallback: (cliente: any) => void;
}

const ModalCadastroCliente = ({
  isOpen,
  setIsOpen,
  nomeOuCpfCnpjCliente,
  novoClienteCallback,
}: ModalCadastroClienteProps) => {
  const cadastroSucessoCallback = (cliente: any) => {
    novoClienteCallback(cliente);
    setIsOpen(false);
  };

  return (
    <ModalPadraoChakra
      onClose={() => setIsOpen(false)}
      size="full"
      isOpen={isOpen}
    >
      <ModalContent margin={{ base: 0, sm: '3.75rem' }}>
        <ModalHeader>Cadastrar Cliente</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <BrowserRouter>
            <Route>
              <CadastrarCliente
                nomeOuCpfCnpjCliente={nomeOuCpfCnpjCliente}
                cadastroPdv
                rotaCliente
                cadastroSucessoCallback={cadastroSucessoCallback}
              />
            </Route>
          </BrowserRouter>
        </ModalBody>
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export default ModalCadastroCliente;
