import { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import { shallowEqual } from 'helpers/validation/shallowEqual';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';

import { PagedTableForwardRefData } from 'components/update/Table/PagedTable';
import { PaginationData } from 'components/update/Pagination';
import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import { ModalConfirmacaoInativar } from 'components/Modal/ModalConfirmacaoInativar';

export type CashPeriodProps = {
  id: string;
  ativo: boolean;
  nome: string;
};

export type FiltrosProps = { nome: string; ativo: boolean | null };

const usePeriodoCaixaListar = () => {
  const history = useHistory();
  const pagedTableRef = useRef<PagedTableForwardRefData>(null);

  const [filtros, setFiltros] = useState<FiltrosProps>({
    nome: '',
    ativo: true,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [periodoCaixaLista, setPeriodoCaixaLista] = useState<CashPeriodProps[]>(
    []
  );

  const alterarPeriodoCaixa = (periodoCaixaId: string) => {
    history.push(
      SubstituirParametroRota(
        ConstanteRotas.FRENTE_CAIXA_PERIODO_CAIXA_ALTERAR,
        'id',
        periodoCaixaId
      )
    );
  };

  const cadastrarPeriodoCaixa = () => {
    history.push(ConstanteRotas.FRENTE_CAIXA_PERIODO_CAIXA_CADASTRAR);
  };

  const inativarPeriodoCaixa = (periodoCaixaId: string, status: boolean) => {
    ModalConfirmacaoInativar({
      id: periodoCaixaId,
      response: null,
      ativo: status,
      title: 'Inativar registro!',
      text: '',
      rotaWebService:
        ConstanteEnderecoWebservice.FRENTE_CAIXA_PERIODO_CAIXA_INATIVAR,
      callback: async (confirm: boolean) => {
        if (!confirm) {
          return;
        }

        setIsLoading(true);

        await pagedTableRef?.current?.reload();

        setIsLoading(false);
      },
    });
  };

  const obterPeriodoCaixaListaPaginada = useCallback(
    async (paginationData: PaginationData) => {
      setIsLoading(true);

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<CashPeriodProps>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.FRENTE_CAIXA_PERIODO_CAIXA_LISTAR_PAGINADO,
          paginationData
        ),
        {
          params: { ...filtros },
        }
      );

      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response?.sucesso && response?.dados) {
          setTotalRegistros(response.dados?.total);
          setPeriodoCaixaLista(response.dados?.registros);
        }
      }

      setIsLoading(false);
    },
    [filtros]
  );

  const atualizarFiltros = (filtrosForm: FiltrosProps) => {
    if (!shallowEqual(filtrosForm, filtros)) {
      setFiltros(filtrosForm);
    }
  };

  return {
    atualizarFiltros,
    obterPeriodoCaixaListaPaginada,
    alterarPeriodoCaixa,
    inativarPeriodoCaixa,
    cadastrarPeriodoCaixa,
    periodoCaixaLista,
    totalRegistros,
    pagedTableRef,
    isLoading,
  };
};

export default usePeriodoCaixaListar;
