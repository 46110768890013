import { Box, Flex, Icon, Text } from '@chakra-ui/react';

import { tipoPendenciaCadastro } from 'constants/enum/tipoImportacaoIntegracao';

import {
  ActionMenuItem,
  ActionsMenu,
} from 'components/update/Table/ActionsMenu';
import { FinanceiroRecebida, IconType } from 'icons';
import { ListaIntegracaoPendenciaPedidoProps } from 'pages/Integracoes/LojaAplicativos/TrayCommerce/PainelAdministradorTray/VendasTray/validationForms';

type PendenciaItemProps = {
  itemPendencia: ListaIntegracaoPendenciaPedidoProps;
  trayId: string;
  optionsPendences: (
    pendences: ListaIntegracaoPendenciaPedidoProps,
    newIcon: (icon?: IconType | undefined) => JSX.Element,
    trayId: string
  ) => ActionMenuItem[];
};

export const PendenciaItem = ({
  itemPendencia,
  trayId,
  optionsPendences,
}: PendenciaItemProps) => {
  const { possuiPendencia, descricao, tipoPendencia } = itemPendencia;

  const newIcon = (icon?: IconType | undefined) => {
    return <Icon fontSize="20px" color="gray.700" as={icon} />;
  };

  const pendenciaStatusPedidoNaoVinculado =
    tipoPendencia === tipoPendenciaCadastro.SITUACAO;

  return (
    <Flex
      justifyContent="space-between"
      px="24px"
      py="12px"
      alignItems="center"
      mb="4px"
      borderRadius="8px"
      color={possuiPendencia ? 'black' : 'white'}
      minH={possuiPendencia ? '64px' : '40px'}
      bg={possuiPendencia ? 'yellow.600' : 'violet.500'}
    >
      {pendenciaStatusPedidoNaoVinculado ? (
        <Flex
          justifyContent="flex-start"
          h="full"
          alignItems="flex-start"
          flexDir="column"
          fontSize="14px"
        >
          <Text fontWeight="bold">
            Status do pedido não identificado no sistema
          </Text>
          <Text fontWeight="300">
            A situação utilizada na plataforma não existe no sistema.
            Identifique a operação correspondente para o novo status.
          </Text>
        </Flex>
      ) : (
        <Flex justifyContent="center" h="full" alignItems="center">
          <Text fontWeight="bold">{descricao}</Text>
        </Flex>
      )}
      <Flex justifyContent="center" h="full" alignItems="center">
        {possuiPendencia ? (
          <ActionsMenu
            backgroundHoverColor="yellow.600"
            menuZIndex="modal"
            items={optionsPendences(itemPendencia, newIcon, trayId)}
          />
        ) : (
          <Box mr="5px">
            <Icon fontSize="20px" as={FinanceiroRecebida} />
          </Box>
        )}
      </Flex>
    </Flex>
  );
};
