import { useEffect, useState, useCallback } from 'react';
import {
  Box,
  SimpleGrid,
  Flex,
  Text,
  useMediaQuery,
  Icon,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { FormProvider, useForm } from 'react-hook-form';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';
import { useExtratoContext } from 'store/Financeiro/Extrato';
import TipoContaFinanceiraEnum from 'constants/enum/tipoContaFinanceira';
import { moneyMask } from 'helpers/format/fieldsMasks';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { Separator } from 'components/Layout/Menu/Submenu/Nav/Separator';

import { ContaFinanceiraItem } from './ContaFinanceiraItem';

type ContaFinanceira = {
  id: string;
  nome: string;
  icone: string;
  tipoConta: number;
  saldo: number;
};

export const ExtratoListar = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [valueIsVisible, setValueIsVisible] = useState(false);
  const [contasFinanceiras, setContasFinanceiras] = useState<ContaFinanceira[]>(
    []
  );
  const formMethods = useForm();
  const { setValueDataExtrato } = useExtratoContext();

  const [isSmallerThan1139] = useMediaQuery('(max-width: 1139px)');
  const [isSmallerThan1400] = useMediaQuery('(max-width: 1400px)');

  const exibirIcon = () => {
    return valueIsVisible ? AiOutlineEyeInvisible : AiOutlineEye;
  };

  const getContasFinanceiras = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<void, ResponseApi<ContaFinanceira[]>>(
      ConstanteEnderecoWebservice.CONTA_FINANCEIRA_LISTAR_POR_LOJA
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        setContasFinanceiras(response.dados);
      }
    }

    setIsLoading(false);
  }, []);

  const listContaCofre = contasFinanceiras?.filter(
    (contaFinanceiraItem) =>
      contaFinanceiraItem.tipoConta === TipoContaFinanceiraEnum.CONTA_COFRE
  );

  const listContaBancaria = contasFinanceiras
    ?.filter(
      (contaFinanceiraItem) =>
        contaFinanceiraItem.tipoConta === TipoContaFinanceiraEnum.BANCO
    )
    .sort((a, b) => a.nome.localeCompare(b.nome));

  const listCaixaPDV = contasFinanceiras
    ?.filter(
      (contaFinanceiraItem) =>
        contaFinanceiraItem.tipoConta === TipoContaFinanceiraEnum.CAIXA
    )
    .sort((a, b) => a.nome.localeCompare(b.nome));

  const listCaixaMovel = contasFinanceiras
    ?.filter(
      (contaFinanceiraItem) =>
        contaFinanceiraItem.tipoConta === TipoContaFinanceiraEnum.CAIXA_MOVEL
    )
    .sort((a, b) => a.nome.localeCompare(b.nome));

  const listCaixas = [...listCaixaPDV, ...listCaixaMovel];

  const saldoTotalContas = contasFinanceiras?.reduce((accumulator, conta) => {
    return accumulator + conta?.saldo;
  }, 0);

  useEffect(() => {
    getContasFinanceiras();
  }, [getContasFinanceiras]);

  useEffect(() => {
    setValueDataExtrato(undefined);
  }, [setValueDataExtrato]);

  return (
    <Box>
      {isLoading && <LoadingPadrao />}

      <FormProvider {...formMethods}>
        <SimpleGrid
          templateColumns={[
            'repeat(auto-fill, minmax(full, full))',
            'repeat(auto-fill, minmax(full, full))',
            'repeat(2, minmax(580px, 580px))',
          ]}
          spacing="6"
          display={!isSmallerThan1139 ? undefined : 'flex'}
          mb="34px"
          flexDirection={[
            'column-reverse',
            'column-reverse',
            isSmallerThan1139 ? 'column-reverse' : 'row',
          ]}
        >
          <Box w={['full', 'full', isSmallerThan1139 ? '580px' : 'full']}>
            {listContaCofre.map((contaFinanceira) => (
              <ContaFinanceiraItem
                key={contaFinanceira.id}
                {...contaFinanceira}
              />
            ))}
          </Box>
          <Flex
            justifyContent={[
              'left',
              'left',
              isSmallerThan1400 ? 'left' : 'center',
            ]}
            alignItems="center"
          >
            <Flex
              justifyContent="center"
              flexDirection={['column', 'column', 'row']}
              alignItems={['left', 'left', 'center']}
            >
              <Text whiteSpace="nowrap" mr="7px">
                Saldo total da loja:
              </Text>
              <Flex
                color="white"
                justifyContent="space-between"
                h="28px"
                w="200px"
                alignItems="center"
                px="12px"
                borderRadius="5px"
                lineHeight="0"
                bg={saldoTotalContas >= 0 ? 'aquamarine.600' : 'red.500'}
              >
                <Flex alignItems="center" gap="3px">
                  {valueIsVisible && (
                    <Text fontSize="sm" fontWeight="light">
                      R${' '}
                    </Text>
                  )}
                  <Text fontSize="md" fontWeight="bold">
                    {valueIsVisible
                      ? `${moneyMask(saldoTotalContas, false)}`
                      : '********'}
                  </Text>
                </Flex>
                <Icon
                  boxSize="22px"
                  cursor="pointer"
                  color="white"
                  onClick={() => setValueIsVisible(!valueIsVisible)}
                  as={exibirIcon()}
                />
              </Flex>
            </Flex>
          </Flex>
        </SimpleGrid>
        <Separator title="Contas Bancárias" fontWeight="semibold" />
        <SimpleGrid
          templateColumns={[
            'repeat(auto-fill, minmax(full, full))',
            'repeat(auto-fill, minmax(full, full))',
            'repeat(auto-fill, minmax(580px, 580px))',
          ]}
          spacing="8px"
          mb="34px"
          mt="10px"
        >
          {listContaBancaria.map((contaFinanceira) => (
            <ContaFinanceiraItem
              key={contaFinanceira.id}
              {...contaFinanceira}
            />
          ))}
        </SimpleGrid>
        <Separator title="Caixas" fontWeight="semibold" />
        <SimpleGrid
          templateColumns={[
            'repeat(auto-fill, minmax(full, full))',
            'repeat(auto-fill, minmax(full, full))',
            'repeat(auto-fill, minmax(580px, 580px))',
          ]}
          spacing="8px"
          mb="34px"
          mt="10px"
        >
          {listCaixas.map((contaFinanceira) => (
            <ContaFinanceiraItem
              key={contaFinanceira.id}
              {...contaFinanceira}
            />
          ))}
        </SimpleGrid>
      </FormProvider>
    </Box>
  );
};
