import React from 'react';

import ConstanteFuncionalidades from 'constants/permissoes';

import ConstanteRotas from '../constants/rotas';
import { InformacoesClientes } from '../pages/Relatorio/InformacoesClientes';
import LayoutGuard from './LayoutGuard';

export const RelatorioInformacoesClientes = [
  <LayoutGuard
    key={ConstanteRotas.RELATORIO_INFORMACOES_CLIENTES}
    permissaoFuncionalidade={
      ConstanteFuncionalidades.RELATORIO_INFORMACOES_CLIENTE
    }
    component={InformacoesClientes}
    breadcrumb={[
      {
        title: 'Relatórios',
      },
      {
        title: 'Informações dos clientes',
      },
    ]}
    path={ConstanteRotas.RELATORIO_INFORMACOES_CLIENTES}
    exact
    meta={{ auth: true }}
  />,
];
