import { useEffect, useRef, useCallback, useState, useMemo } from 'react';
import {
  Box,
  Heading,
  CloseButton,
  HStack,
  VStack,
  Tag,
  Text,
  Grid,
  Button,
  Flex,
  Skeleton,
  useBreakpointValue,
  useMediaQuery,
  Divider,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { addDays } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

import { useAcrescimosDescontosContext } from 'store/PDV/AcrescimosDescontos';
import { useSelecaoFormaPagamentoContext } from 'store/PDV/SelecaoFormaPagamento';
import { useOperacaoContext } from 'store/PDV/Operacao';
import { usePagamentoContext } from 'store/PDV/Pagamento';
import cadastrarMovimentacaoFinanceira, {
  InformacoesParcelaMovimentacaoFinanceira,
  ParcelasMovimentacaoFinanceira,
} from 'helpers/api/MovimentacaoFinanceira/cadastrarMovimentacaoFinanceira';
import { setDateMinHours } from 'helpers/data/setHoursDate';
import { getValorParcelas } from 'helpers/data/getValorParcelas';
import { getNonWeekendDate } from 'helpers/data/getNonWeekendDate';
import { DecimalMask } from 'helpers/format/fieldsMasks';
import LogAuditoriaTelaEnum from 'constants/enum/logAuditoriaTela';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import MeioPagamentoEnum from 'constants/enum/fiscal/meioPagamento';
import api, { ResponseApi } from 'services/api';
import auth from 'modules/auth';
import { useInformacoesGeraisContext } from 'store/PDV/InformacoesGerais';
import { useSignalRContext } from 'store/SignalR';
import TipoValorEnum from 'constants/enum/tipoValor';
import finalizarVenda from 'helpers/api/Operacao/finalizarVenda';
import { deleteMovimentacaoFinanceira } from 'helpers/api/MovimentacaoFinanceira/deleteMovimentacaoFinanceira';
import { SubstituirParametroRota } from 'constants/rotas';
import ConstanteFuncionalidades from 'constants/permissoes';
import ConstanteRotasPDV from 'constants/rotasPDV';
import { EnumTipoTransacao } from 'constants/enum/enumPagamentos';

import { ModalPagamentosZoop } from 'components/PDV/Modal/ModalPagamentosZoop';
import { NumberInput } from 'components/update/Input/NumberInput';
import { ModalDesistencia } from 'components/Modal/ModalDesistencia';
import { ModalPagamentoStone } from 'components/PDV/Modal/ModalPagamentoStone';
import ModalAutorizacaoFuncionalidade from 'components/Modal/ModalAutorizacaoFuncionalidade';
import { ButtonDefault } from 'components/Button/ButtonChakra';
import { ModalInserirVoucher } from 'components/PDV/Modal/ModalInserirVoucher';

import { useForm, yupResolver } from './validationForm';

const notas = [5, 10, 20, 50, 100, 200];

type ValeTroca = {
  saldoVale: number;
  valeId: string;
  sequenciaOrdenacao: number;
};

const InformarValorParcela = () => {
  const [aparecerSkeleton, setAparecerSkeleton] = useState(false);
  const [possuiLimiteCompra, setPossuiLimiteCompra] = useState(false);
  const [
    modalAutorizarFuncionalidadeIsOpen,
    setModalAutorizarFuncionalidadeIsOpen,
  ] = useState(false);
  const [
    obterValorTotalComAcrescimo,
    setObterValorAtualComAcrescimo,
  ] = useState(1);

  const valorValeTroca = useRef<ValeTroca[]>([]);
  const identificadorAgrupamentoFinanceiroRef = useRef('');
  const orderIdRef = useRef('');

  const asMdBreakpoint = useBreakpointValue({ base: false, md: true });
  const asMobileView = useBreakpointValue({ base: true, md: false });
  const [isLargerThanPosition] = useMediaQuery(
    '(mix-width: 892px)' && '(max-width: 458px)'
  );

  const { hubConnection, joinGroup } = useSignalRContext();
  const {
    operacaoId,
    informacoesCliente,
    clienteNaoPodeComprarNoCreditoLoja,
  } = useOperacaoContext();

  const {
    handleSelecionarFormaPagamento,
    setIsLoading,
    parcela,
    formaPagamento,
  } = useSelecaoFormaPagamentoContext();

  const {
    contaFinanceira,
    handleSetInformacoesLocalEstoque,
    localEstoquePadraoDoUsuario,
  } = useInformacoesGeraisContext();

  const {
    valorRestante,
    movimentacoesFinanceiras,
    setMovimentacoesFinanceiras,
    setTotais,
    setAparecerValorAcrescimoHeaderPdv,
    setAcrescimoFoiLimpo,
    acrescimoFoiLimpo,
    somarValorTotalPagoSemAcrescimo,
    totais,
    valorTotalVenda,
    valorFaltaPagar: valorFaltaPagarCompra,
  } = usePagamentoContext();

  const { acrescimosDescontosValor } = useAcrescimosDescontosContext();
  const formMethods = useForm({
    resolver: yupResolver,
  });

  const history = useHistory();

  const possuiPermissaoLiberarClienteBloqueado = auth.possuiPermissao(
    ConstanteFuncionalidades.PDV_LIBERAR_CLIENTE_BLOQUEADO.codigo
  ).permitido;

  const { watch } = formMethods;

  const [valor, valorAcrescimo] = watch(['valor', 'valorAcrescimo']);

  const openModalValidationWhyOperationCredito =
    formaPagamento?.regraMeioPagamento === MeioPagamentoEnum.CreditoLoja &&
    clienteNaoPodeComprarNoCreditoLoja;

  const paymentMethod = formaPagamento?.regraMeioPagamento;

  useEffect(() => {
    if (valorAcrescimo) {
      setObterValorAtualComAcrescimo(valor + valorAcrescimo);
    } else {
      setObterValorAtualComAcrescimo(valor);
    }
  }, [valor, valorAcrescimo]);

  const obterDataVencimento = useCallback(
    (numeroParcela?: number) => {
      const hoje = setDateMinHours(new Date());

      if (parcela && (numeroParcela || numeroParcela === 0)) {
        const days = addDays(hoje, numeroParcela * parcela.intervaloDias);
        const utilDate = getNonWeekendDate(days);

        return utilDate;
      }

      return hoje;
    },
    [parcela]
  );

  const obterValorAdicionalAcrescimo = useCallback(
    (valorPagamento: number) => {
      if (parcela?.acrescimoPorcentagem || (parcela?.acrescimoValor || 0) > 0) {
        const fatorMultiplicacaoAcrescimo = Array(parcela?.qtdeParcela || 1)
          .fill('')
          .reduce((acc, _, index) => {
            const numeroParcela = index + 1;

            if (numeroParcela === 1) {
              return 1;
            }

            if (numeroParcela === 2) {
              return 1 + 2;
            }

            return acc + numeroParcela;
          }, 0);
        const porcentagemAcrescimoDecimal =
          (parcela?.acrescimoPorcentagem || 0) / 100;

        const valorParcela = valorPagamento / (parcela?.qtdeParcela || 1);
        let adicionandoAcrescimoParcela = 0;

        if (porcentagemAcrescimoDecimal) {
          adicionandoAcrescimoParcela =
            valorParcela *
              porcentagemAcrescimoDecimal *
              fatorMultiplicacaoAcrescimo +
            (parcela?.acrescimoValor || 0);
        } else {
          adicionandoAcrescimoParcela = parcela?.acrescimoValor || 0;
        }

        return Number(adicionandoAcrescimoParcela.toFixed(2));
      }

      return 0;
    },
    [parcela]
  );
  const validandoValorAcrescimo =
    (totais?.valorTotalAcrescimo || 0) < 0
      ? 0
      : totais?.valorTotalAcrescimo || 0;

  const somandoValorTotalCompra = Number(
    (totais?.valorTotalItensComDesconto || 0) +
      (totais?.valorTotalOutrasDespesas || 0) +
      validandoValorAcrescimo +
      (totais?.valorTotalFrete || 0)
  );

  const valorTotalCompraComAcrescimosEDescontos = useMemo(() => {
    const valorTotalCompraComPorcentagemDesconto =
      somandoValorTotalCompra -
      ((totais?.valorDescontoAdicional || 0) / 100) *
        (totais?.valorTotalItensComDesconto || 0);

    if (totais?.tipoDescontoAdicional === TipoValorEnum.REAIS) {
      return somandoValorTotalCompra - (totais?.valorDescontoAdicional || 0);
    }

    return valorTotalCompraComPorcentagemDesconto;
  }, [somandoValorTotalCompra, totais]);

  const validandoValorFaltaPagar = () => {
    if (totais) {
      if (
        somarValorTotalPagoSemAcrescimo >
        valorTotalCompraComAcrescimosEDescontos
      ) {
        return 0;
      }
      return (
        valorTotalCompraComAcrescimosEDescontos -
        somarValorTotalPagoSemAcrescimo
      );
    }
    return valorRestante;
  };
  const valorFaltaPagar = validandoValorFaltaPagar();

  const obterParcelas = useCallback(() => {
    const valorRestanteComAcrescimo =
      valorFaltaPagarCompra +
      obterValorAdicionalAcrescimo(valorFaltaPagarCompra);
    const valorValeTrocaTotal = valorValeTroca.current.reduce((acc, curr) => {
      return acc + curr.saldoVale;
    }, 0);

    const valorComAcrescimo =
      valorValeTrocaTotal > 0 && valorValeTrocaTotal <= valor
        ? valorValeTrocaTotal + valorAcrescimo
        : valor + valorAcrescimo;

    const troco =
      valorComAcrescimo > valorRestanteComAcrescimo
        ? valorComAcrescimo - valorRestanteComAcrescimo
        : 0;

    /* const valorPagamento = valorComAcrescimo - troco; */
    const valorTotalVoucher = valorValeTroca.current.reduce((acc, item) => {
      return acc + item.saldoVale;
    }, 0);
    const validandoValorVoucher =
      valorTotalVoucher > valor ? valor : valorTotalVoucher;

    const valorSeraCadastrado =
      formaPagamento?.regraMeioPagamento === MeioPagamentoEnum.ValePresente
        ? validandoValorVoucher
        : valor;

    const valorTotalOperacao =
      Math.round(
        (valorSeraCadastrado + valorAcrescimo + Number.EPSILON) * 100
      ) / 100;

    const validandoValorTotalPagar =
      valor > valorFaltaPagarCompra
        ? valorFaltaPagarCompra
        : valorTotalOperacao;

    const pagamento = { troco } as ParcelasMovimentacaoFinanceira;

    if (!parcela) {
      pagamento.parcelas = [
        {
          numeroParcela: 1,
          valor: validandoValorTotalPagar,
          acrescimo: valorAcrescimo,
          parcelaExibicao: '1/1',
          dataVencimento: obterDataVencimento(),
        } as InformacoesParcelaMovimentacaoFinanceira,
      ];

      return pagamento;
    }

    if (formaPagamento?.regraMeioPagamento === MeioPagamentoEnum.Dinheiro) {
      pagamento.parcelas = [
        {
          numeroParcela: 1,
          valor: validandoValorTotalPagar,
          acrescimo: valorAcrescimo,
          parcelaExibicao: '1/1',
          dataVencimento: obterDataVencimento(),
        } as InformacoesParcelaMovimentacaoFinanceira,
      ];
      if (totais) {
        setTotais({
          ...totais,
          valorTotalAcrescimo:
            (totais?.valorTotalAcrescimo || 0) + valorAcrescimo,
          valorTotal: (totais?.valorTotal || 0) + valorAcrescimo,
        });
      }
      return pagamento;
    }

    const valoresParcelas = getValorParcelas(
      validandoValorTotalPagar,
      parcela.qtdeParcela
    );

    const valorAcrescimoParcela = getValorParcelas(
      valorAcrescimo,
      parcela?.qtdeParcela
    );

    const quantidadeTotalParcelas = Array(parcela.qtdeParcela).length;

    pagamento.parcelas = Array(parcela.qtdeParcela)
      .fill('')
      .map((_, index) => {
        const numeroParcela = index + 1;
        const obterParcelaExibicao = `${numeroParcela}/${quantidadeTotalParcelas}`;
        return {
          numeroParcela,
          valor: valoresParcelas[index],
          acrescimo: valorAcrescimoParcela[index],
          parcelaExibicao: obterParcelaExibicao,
          dataVencimento: obterDataVencimento(numeroParcela),
          transacaoId: orderIdRef.current ? orderIdRef.current : null,
        } as InformacoesParcelaMovimentacaoFinanceira;
      });

    if (totais) {
      setTotais({
        ...totais,
        valorTotalAcrescimo:
          (totais?.valorTotalAcrescimo || 0) + valorAcrescimo,
        valorTotal: (totais?.valorTotal || 0) + valorAcrescimo,
      });
    }
    return pagamento;
  }, [
    valorFaltaPagarCompra,
    obterValorAdicionalAcrescimo,
    valor,
    valorAcrescimo,
    formaPagamento,
    parcela,
    totais,
    obterDataVencimento,
    setTotais,
  ]);

  const handleSubmit = formMethods.handleSubmit(async () => {
    setIsLoading(true);
    setAparecerSkeleton(true);

    const identificadorAgrupamento = uuidv4();
    identificadorAgrupamentoFinanceiroRef.current = identificadorAgrupamento;
    const pagamento = obterParcelas();
    let valorFinal = 0;

    if (operacaoId && formaPagamento) {
      const valuePaid =
        Math.round((valor + valorAcrescimo + Number.EPSILON) * 100) / 100;

      const valorValeTrocaTotal = valorValeTroca.current.reduce((acc, item) => {
        return acc + item.saldoVale;
      }, 0);

      if (
        formaPagamento.regraMeioPagamento === MeioPagamentoEnum.ValePresente
      ) {
        valorFinal =
          valuePaid >= valorValeTrocaTotal ? valorValeTrocaTotal : valuePaid;
      } else {
        valorFinal = valuePaid;
      }
      const validandoValorFaltaPagarCompra =
        valor > valorFaltaPagarCompra ? valorFaltaPagarCompra : valorFinal;

      const valorTroco =
        Math.round((pagamento.troco + Number.EPSILON) * 100) / 100;

      const cadastrarMovimentacao = async () => {
        const responseCadastrarMovimentacao = await cadastrarMovimentacaoFinanceira(
          {
            operacaoId,
            formaPagamentoRecebimentoId: formaPagamento.id,
            formaPagamentoRecebimentoParcelaId: parcela?.id,
            identificadorAgrupamento,
            troco: valorTroco,
            parcelas: pagamento.parcelas,
            tela: LogAuditoriaTelaEnum.PDV,
            vales: valorValeTroca.current.map((vales) => ({
              valeId: vales.valeId,
              sequenciaOrdenacao: vales.sequenciaOrdenacao,
            })),
            contaFinanceiraId: contaFinanceira?.id,
          }
        );

        return responseCadastrarMovimentacao;
      };

      const setarValorMovimentacaoFinanceira = () => {
        setMovimentacoesFinanceiras([
          ...(movimentacoesFinanceiras || []),
          {
            valor:
              Math.round(
                (validandoValorFaltaPagarCompra + Number.EPSILON) * 100
              ) / 100,
            dataVencimento: pagamento.parcelas[0].dataVencimento,
            descricaoFormaPagto: formaPagamento.nome,
            descricaoParcelas: `Pagamento em ${
              parcela ? parcela.qtdeParcela : 1
            }x`,
            identificacaoAgrupamento: identificadorAgrupamento,
            troco: pagamento.troco,
            temParcelas: !!parcela && parcela.qtdeParcela > 1,
            regraMeioPagamento: formaPagamento.regraMeioPagamento,
            acrescimo: valorAcrescimo,
          },
        ]);
      };

      if (valorTroco) {
        if (formaPagamento.regraMeioPagamento === MeioPagamentoEnum.Dinheiro) {
          const response = await cadastrarMovimentacao();
          if (response) {
            setarValorMovimentacaoFinanceira();
          }
        } else {
          toast.warn('A forma de pagamento selecionada não permite troco');
        }
      } else {
        const response = await cadastrarMovimentacao();
        if (response) {
          setarValorMovimentacaoFinanceira();
        }
      }
    }

    handleSelecionarFormaPagamento(undefined);
    setAparecerValorAcrescimoHeaderPdv(false);
    setAcrescimoFoiLimpo(false);
    setAparecerSkeleton(false);
    setIsLoading(false);
  });

  const confirmPaymentMethod = useCallback(async () => {
    setIsLoading(true);
    await handleSubmit();
    setIsLoading(false);
  }, [handleSubmit, setIsLoading]);

  const resetFormaPagamento = useCallback(() => {
    handleSelecionarFormaPagamento(undefined);
  }, [handleSelecionarFormaPagamento]);

  const excluirFormaPagamentoAdicionada = useCallback(async () => {
    setIsLoading(true);
    const movimentacao = {
      identificadorAgrupamento: identificadorAgrupamentoFinanceiroRef.current,
    };

    const success = await deleteMovimentacaoFinanceira(movimentacao);

    if (success) {
      if (movimentacoesFinanceiras && movimentacoesFinanceiras?.length > 0) {
        const newMovimentacoesFinanceiras = [...movimentacoesFinanceiras];
        const movimentacaoFinanceiraExcluidaIndex = movimentacoesFinanceiras?.findIndex(
          (movimentacaoFinanceira) =>
            movimentacaoFinanceira.identificacaoAgrupamento ===
            identificadorAgrupamentoFinanceiroRef.current
        );
        if (movimentacaoFinanceiraExcluidaIndex === -1) {
          setMovimentacoesFinanceiras([...newMovimentacoesFinanceiras]);
          setIsLoading(false);
          return;
        }

        newMovimentacoesFinanceiras?.splice(
          movimentacaoFinanceiraExcluidaIndex,
          1
        );
        if (totais) {
          setTotais({
            ...totais,
            valorTotalAcrescimo: acrescimoFoiLimpo
              ? 0
              : (totais?.valorTotalAcrescimo || 0) -
                movimentacoesFinanceiras[movimentacaoFinanceiraExcluidaIndex]
                  ?.acrescimo,
            valorTotal:
              (totais?.valorTotal || 0) -
              movimentacoesFinanceiras[movimentacaoFinanceiraExcluidaIndex]
                ?.acrescimo,
          });
        }

        setMovimentacoesFinanceiras([...newMovimentacoesFinanceiras]);
      } else {
        setMovimentacoesFinanceiras([]);
      }
    }
    setIsLoading(false);
  }, [
    acrescimoFoiLimpo,
    movimentacoesFinanceiras,
    setIsLoading,
    setMovimentacoesFinanceiras,
    setTotais,
    totais,
  ]);

  const naoPodeInformarValorZerado = useCallback(() => {
    if (valorTotalVenda > 0 && valor === 0) {
      return true;
    }

    return false;
  }, [valor, valorTotalVenda])();

  const handleInformarLimite = useCallback(async () => {
    setIsLoading(true);

    if (
      formaPagamento?.regraMeioPagamento === MeioPagamentoEnum.CreditoLoja &&
      valor &&
      obterValorTotalComAcrescimo
    ) {
      const response = await api.get<void, ResponseApi<boolean>>(
        ConstanteEnderecoWebservice.MOVIMENTACAO_FINANCEIRA_VALIDAR_LIMITE_CREDITO,
        {
          params: {
            clienteId: informacoesCliente?.id,
            valor: obterValorTotalComAcrescimo,
          },
        }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response.sucesso) {
          setPossuiLimiteCompra(response.dados);
          setIsLoading(false);

          return response.dados;
        }
      }
    }
    setIsLoading(false);

    return false;
  }, [
    formaPagamento,
    valor,
    obterValorTotalComAcrescimo,
    informacoesCliente,
    setPossuiLimiteCompra,
    setIsLoading,
  ]);

  const finalizarVendaAtual = useCallback(async () => {
    setIsLoading(true);
    if (operacaoId && contaFinanceira) {
      const vendaGerada = await finalizarVenda(operacaoId);

      if (vendaGerada) {
        handleSetInformacoesLocalEstoque(localEstoquePadraoDoUsuario.current);
        history.push(
          SubstituirParametroRota(
            ConstanteRotasPDV.PDV_FINALIZAR,
            'id',
            operacaoId
          )
        );
        toast.success('Venda gerada com sucesso!');
      }
    }
    setIsLoading(false);
  }, [
    contaFinanceira,
    handleSetInformacoesLocalEstoque,
    history,
    localEstoquePadraoDoUsuario,
    operacaoId,
    setIsLoading,
  ]);

  const handleValePresentePayment = useCallback(async () => {
    const {
      sucesso,
      saldoVale,
      valeId,
      vouchersSelecionados,
    } = await ModalInserirVoucher({
      cliente: {
        id: informacoesCliente?.id,
        name: informacoesCliente?.nome,
      },

      value: valor,
    });
    if (sucesso && saldoVale && valeId) {
      valorValeTroca.current.push({
        saldoVale,
        valeId,
        sequenciaOrdenacao: 1,
      });
      await handleSubmit();
    } else if (
      sucesso &&
      vouchersSelecionados &&
      vouchersSelecionados.length > 0
    ) {
      valorValeTroca.current = vouchersSelecionados;
      await handleSubmit();
    }
  }, [handleSubmit, informacoesCliente, valor]);

  const handleZoopPayment = useCallback(async () => {
    const isBoletoZoop = paymentMethod === MeioPagamentoEnum.BoletoZoop;
    const transactionType = isBoletoZoop
      ? EnumTipoTransacao.BOLETO
      : EnumTipoTransacao.PIX;

    await confirmPaymentMethod();
    if (isBoletoZoop) {
      return;
    }

    const { qtdeParcela } = parcela || { qtdeParcela: 1 };

    const paymentDetails = {
      valor,
      valorRestante,
      parcelas: qtdeParcela,
      descricao: informacoesCliente?.nome || '',
      tipo: transactionType,
      idOperacao: operacaoId || '',
      formaPagamento: paymentMethod,
      cliente: informacoesCliente,
    };

    const connectionDetails = {
      joinGroup,
      hubConnection,
    };
    const { success, cancel, gerarVenda } = await ModalPagamentosZoop({
      paymentDetails,
      connectionDetails,
      gerarVendaAoPagar: confirmPaymentMethod,
    });

    if (!success) {
      if (cancel) toast.warn('Pagamento cancelado pelo usuário.');
      excluirFormaPagamentoAdicionada();
      return;
    }

    if (gerarVenda) {
      await finalizarVendaAtual();
    }
  }, [
    paymentMethod,
    parcela,
    valor,
    valorRestante,
    informacoesCliente,
    operacaoId,
    joinGroup,
    hubConnection,
    confirmPaymentMethod,
    excluirFormaPagamentoAdicionada,
    finalizarVendaAtual,
  ]);

  const updateOrderId = useCallback((orderIdValue: string) => {
    orderIdRef.current = orderIdValue;
  }, []);

  const handleEfetuarVendaClienteBloqueado = useCallback(async () => {
    await handleSubmit();
  }, [handleSubmit]);

  const gerarVendaStone = useCallback(async () => {
    setIsLoading(true);
    await handleSubmit();
    setIsLoading(false);
  }, [handleSubmit, setIsLoading]);

  const handleCreditoLojaPayment = useCallback(async () => {
    const hasCurrentPurchaseLimit = await handleInformarLimite();

    if (hasCurrentPurchaseLimit) {
      await handleSubmit();
    } else if (possuiPermissaoLiberarClienteBloqueado) {
      ModalDesistencia({
        title: 'Confirmar',
        labelButtonCancelar: 'Cancelar',
        labelButtonConfirmar: 'Sim, confirmar',
        textoMensagem: `O valor da conta irá exceder o limite de crédito do cliente "${informacoesCliente?.nome}", deseja continuar?`,
        callback: () => handleEfetuarVendaClienteBloqueado(),
      });
    } else {
      setModalAutorizarFuncionalidadeIsOpen(true);
    }
  }, [
    handleInformarLimite,
    possuiPermissaoLiberarClienteBloqueado,
    handleSubmit,
    informacoesCliente?.nome,
    handleEfetuarVendaClienteBloqueado,
  ]);

  const handleStonePayment = useCallback(async () => {
    if (!contaFinanceira?.serialPOS) {
      await handleSubmit();
      return;
    }

    const transactionType =
      paymentMethod === MeioPagamentoEnum.CartaoDebitoStone
        ? EnumTipoTransacao.DEBITO_STONE
        : EnumTipoTransacao.CREDITO_STONE;

    const { qtdeParcela } = parcela || { qtdeParcela: 1 };

    const dadosTransacao = {
      valor,
      valorRestante,
      qtdeParcela,
      idOperacao: operacaoId || '',
      display: informacoesCliente?.nome || '',
      tipo: transactionType,
      contaFinanceiraId: contaFinanceira?.id || '',
    };

    const { success, cancel, gerarVenda } = await ModalPagamentoStone({
      dadosTransacao,
      updateOrderId,
      hubConnection,
      joinGroup,
      gerarVendaAoPagar: gerarVendaStone,
    });

    if (!success) {
      if (cancel) toast.warn('Pagamento cancelado pelo usuário.');
      resetFormaPagamento();
      return;
    }

    if (gerarVenda) {
      await finalizarVendaAtual();
      return;
    }

    resetFormaPagamento();
    await handleSubmit();
  }, [
    contaFinanceira?.serialPOS,
    contaFinanceira?.id,
    paymentMethod,
    parcela,
    valor,
    valorRestante,
    operacaoId,
    informacoesCliente?.nome,
    updateOrderId,
    hubConnection,
    joinGroup,
    gerarVendaStone,
    resetFormaPagamento,
    handleSubmit,
    finalizarVendaAtual,
  ]);

  const handleValidandoLimiteCredito = useCallback(async () => {
    const isClientePadraoSistema = informacoesCliente?.padraoSistema;

    const isPaymentCreditoLoja =
      paymentMethod === MeioPagamentoEnum.CreditoLoja;
    const isPaymentStone = paymentMethod
      ? [
          MeioPagamentoEnum.CartaoCreditoStone,
          MeioPagamentoEnum.CartaoDebitoStone,
        ]?.includes(paymentMethod)
      : false;
    const isPaymentValePresente =
      paymentMethod === MeioPagamentoEnum.ValePresente;
    const isPaymentZoop = paymentMethod
      ? [
          MeioPagamentoEnum.PixEnvioZoop,
          MeioPagamentoEnum.PixPresencialZoop,
          MeioPagamentoEnum.BoletoZoop,
        ]?.includes(paymentMethod)
      : false;

    if (isClientePadraoSistema && isPaymentCreditoLoja) {
      toast.warn('A forma de recebimento não é válida para esse cliente.');
      return;
    }

    if (
      !(
        paymentMethod !== MeioPagamentoEnum.Dinheiro &&
        valor >
          Math.round((valorRestante + valorAcrescimo + Number.EPSILON) * 100) /
            100
      )
    ) {
      if (isPaymentValePresente) {
        handleValePresentePayment();
      } else if (isPaymentZoop) {
        await handleZoopPayment();
      } else if (isPaymentCreditoLoja) {
        await handleCreditoLojaPayment();
      } else if (isPaymentStone) {
        await handleStonePayment();
      } else {
        await confirmPaymentMethod();
      }
    } else {
      toast.warn('A forma de pagamento selecionada não permite troco');
    }
  }, [
    informacoesCliente?.padraoSistema,
    paymentMethod,
    valor,
    valorRestante,
    valorAcrescimo,
    handleValePresentePayment,
    handleZoopPayment,
    handleCreditoLojaPayment,
    handleStonePayment,
    confirmPaymentMethod,
  ]);

  const handleAdicionarValor = (valorAdicionado: number) => {
    if (!formMethods.formState.isDirty) {
      formMethods.setValue('valor', valorAdicionado, {
        shouldDirty: true,
      });
    } else {
      formMethods.setValue('valor', valorRestante + valorAdicionado, {
        shouldDirty: true,
      });
    }

    formMethods.setFocus('valor');
  };

  const latestProps = useRef({
    setIsLoading,
    setValue: formMethods.setValue,
    setFocus: formMethods.setFocus,
    handleSubmit: formMethods.handleSubmit,
  });
  useEffect(() => {
    latestProps.current = {
      setIsLoading,
      setValue: formMethods.setValue,
      setFocus: formMethods.setFocus,
      handleSubmit: formMethods.handleSubmit,
    };
  });

  useEffect(() => {
    latestProps.current.setIsLoading(false);
  }, []);

  const arredondarValorFaltaPagar = () => {
    const result =
      Math.round((valorFaltaPagarCompra + Number.EPSILON) * 100) / 100;
    return result;
  };
  const valorFaltaPagarArredondado = arredondarValorFaltaPagar();

  useEffect(() => {
    if (valorFaltaPagar) {
      latestProps.current.setValue(
        'valor',
        valorFaltaPagarArredondado > 0 ? valorFaltaPagarArredondado : 0
      );
    } else {
      latestProps.current.setValue('valor', Number(valorRestante.toFixed(2)));
    }
  }, [
    valorRestante,
    valorFaltaPagarArredondado,
    valorFaltaPagar,
    acrescimosDescontosValor,
  ]);

  useEffect(() => {
    if (asMobileView || asMobileView === undefined) {
      if (document.activeElement) {
        (document.activeElement as HTMLInputElement).blur();
      }
    } else {
      latestProps.current.setFocus('valor');
    }
  }, [asMobileView]);

  useEffect(() => {
    const valorAdicionalAcrescimo = obterValorAdicionalAcrescimo(valor);

    latestProps.current.setValue('valorAcrescimo', valorAdicionalAcrescimo);
  }, [obterValorAdicionalAcrescimo, valor]);

  return (
    <>
      <VStack
        spacing={4}
        h="full"
        py={{ base: 0, md: 5 }}
        pb={{ base: 2, md: 5 }}
        px={{ base: 0, md: 8, xl: 12 }}
        justifyContent={{ base: 'space-between', md: 'flex-start' }}
      >
        <VStack w="full" h="full" spacing={4} justifyContent="flex-start">
          <HStack w="full" spacing={4} justifyContent="space-between">
            <Heading
              as="h4"
              fontSize={{ base: 'sm', md: 'xs', xl: 'md' }}
              lineHeight="tall"
            >
              Informe o valor:
            </Heading>

            {!asMobileView && (
              <CloseButton size="sm" onClick={resetFormaPagamento} />
            )}
          </HStack>

          <Box
            w="full"
            borderRadius="md"
            bg="white"
            boxShadow="0px 0px 4px 0px #00000029"
            p={6}
          >
            <FormProvider {...formMethods}>
              <Grid templateColumns={{ base: '1fr', lg: 'auto 1fr' }} gap={6}>
                <form onSubmit={handleSubmit}>
                  <NumberInput
                    id="valor"
                    name="valor"
                    scale={2}
                    size="lg"
                    variant="flushed"
                    colorScheme="primary"
                    color="primary.500"
                    focusBorderColor="primary.500"
                    prefix="R$"
                    fontSize="3xl"
                    leftElementFontSize="sm"
                  />
                </form>

                <Grid
                  templateColumns={{
                    base: !isLargerThanPosition
                      ? '1fr 1fr 1fr 1fr 1fr 1fr'
                      : '1fr 1fr 1fr',
                    lg: '1fr 1fr 1fr',
                  }}
                  gap={3}
                  w="fit-content"
                  m={{ base: 'auto', sm: 0 }}
                >
                  {notas.map((nota) => (
                    <Button
                      size="xs"
                      color="teal.500"
                      variant="solid"
                      border="1px"
                      borderColor="gray.100"
                      background="gray.50"
                      _hover={{ bg: 'gray.50', color: 'teal.400' }}
                      _active={{ bg: 'gray.50', color: 'teal.400' }}
                      onClick={() => {
                        handleAdicionarValor(nota);
                      }}
                    >
                      {`+ R$ ${nota}`}
                    </Button>
                  ))}
                </Grid>
              </Grid>
            </FormProvider>
          </Box>
          <HStack justifyContent="space-between" w="full" spacing={6}>
            {formaPagamento && (
              <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                alignItems={{ base: 'normal', md: 'center' }}
                justifyContent="space-between"
                w="full"
              >
                <Flex
                  alignItems="baseline"
                  justifyContent={{ base: 'normal', md: 'center' }}
                >
                  <Tag px="3" mr="2" borderRadius="full">
                    {formaPagamento.nome}
                  </Tag>
                  {parcela && (
                    <Tag px="3" mr="2" borderRadius="full">
                      {`${parcela.qtdeParcela}x de `}
                      <Text as="strong" ml="1">
                        {` R$ ${DecimalMask(
                          (valor + valorAcrescimo) / parcela.qtdeParcela,
                          2,
                          2
                        )}`}
                      </Text>
                    </Tag>
                  )}
                </Flex>

                {!asMdBreakpoint && !!valorAcrescimo && <Divider my="4" />}

                {valorAcrescimo ? (
                  <Skeleton isLoaded={!aparecerSkeleton}>
                    <VStack
                      alignItems={{ base: 'normal', md: 'flex-end' }}
                      spacing={{ base: 1, md: 2 }}
                    >
                      {parcela && (
                        <Text>
                          {`(R$ ${DecimalMask(
                            valor / parcela.qtdeParcela,
                            2,
                            2
                          )} + R$ ${DecimalMask(
                            valorAcrescimo / parcela.qtdeParcela,
                            2,
                            2
                          )})`}
                        </Text>
                      )}
                      <Text>
                        Total com acréscimo:
                        <Text as="strong" ml="1">
                          {` R$ ${DecimalMask(valor + valorAcrescimo, 2, 2)}`}
                        </Text>
                      </Text>
                    </VStack>
                  </Skeleton>
                ) : (
                  <Box />
                )}
              </Flex>
            )}
          </HStack>
          <Box>
            <ButtonDefault
              size={asMobileView ? 'lg' : 'md'}
              colorScheme="primary"
              alignSelf={{ base: 'center', md: 'flex-end' }}
              onClick={() => {
                handleValidandoLimiteCredito();
              }}
              color="white"
              isDisabled={naoPodeInformarValorZerado}
              minHeight={asMobileView ? '10' : '8'}
              mt="8"
            >
              Confirmar
            </ButtonDefault>
          </Box>
        </VStack>
      </VStack>
      <ModalAutorizacaoFuncionalidade
        isOpen={
          modalAutorizarFuncionalidadeIsOpen &&
          (openModalValidationWhyOperationCredito || !possuiLimiteCompra) &&
          !possuiPermissaoLiberarClienteBloqueado
        }
        setIsOpen={setModalAutorizarFuncionalidadeIsOpen}
        autorizado={async (chave) => {
          if (chave !== '') {
            await handleSubmit();
          }
        }}
        titulo={ConstanteFuncionalidades.PDV_LIBERAR_CLIENTE_BLOQUEADO.titulo}
        descricao="Não é possível continuar a operação porque o limite de crédito foi atingido. Insira os dados de um usário com permissão para liberar a forma de recebimento."
        tela={LogAuditoriaTelaEnum.PDV}
        permissoes={[
          ConstanteFuncionalidades.PDV_LIBERAR_CLIENTE_BLOQUEADO.codigo,
        ]}
      />
    </>
  );
};

export default InformarValorParcela;
