import { Box, Divider, Flex, Icon, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';

import { TodosTamanhos } from '../../types';

type DetalhesEstoqueLojaAtualProps = {
  itemTamanho: TodosTamanhos;
  somandoValoresQuantidade: number;
};

export function DetalhesEstoqueLojaAtual({
  itemTamanho,
  somandoValoresQuantidade,
}: DetalhesEstoqueLojaAtualProps) {
  const [renderizarLocalEstoque, setRenderizarLocalEstoque] = useState(false);

  function renderizarComponente() {
    setRenderizarLocalEstoque(!renderizarLocalEstoque);
  }

  return (
    <Box>
      <Divider />
      <Flex padding="10px" ml="5px" justifyContent="space-between">
        <Text fontSize="16px">{itemTamanho.tamanho}</Text>

        <Text
          color={somandoValoresQuantidade === 0 ? 'red.500' : 'gray.500'}
          fontSize="14px"
        >
          Quantidade: {somandoValoresQuantidade}{' '}
          <Icon
            fontSize="12px"
            color="black"
            mt="-3px"
            onClick={() => renderizarComponente()}
            as={renderizarLocalEstoque ? FiArrowUp : FiArrowDown}
          />
        </Text>
      </Flex>
      {renderizarLocalEstoque && (
        <Flex ml="10px" padding="5px" mt="-10px" mb="5px">
          <Box>
            <Text>
              {itemTamanho.locaisEstoque.map((valorLocalEstoque) => (
                <Flex>
                  <Text>
                    {valorLocalEstoque.quantidade}
                    {valorLocalEstoque.quantidade < 0 ? '' : ' -'}
                  </Text>
                  <Text ml="5px">{valorLocalEstoque.localEstoque}</Text>
                </Flex>
              ))}
            </Text>
          </Box>
        </Flex>
      )}
      <Divider />
    </Box>
  );
}
