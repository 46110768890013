import React from 'react';
import {
  Button,
  ButtonProps,
  HStack,
  Icon,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { IconType as ReactIconsIconType } from 'react-icons/lib';
import { FiChevronRight } from 'react-icons/fi';

import { IconType } from 'icons';
import { MobileModalMenu } from '../../MobileModalMenu';

interface ChildWithFuncProps {
  isOpen: boolean;
  onClose: () => void;
}

type ChildWithFuncType = (props: ChildWithFuncProps) => JSX.Element | string;

interface MobileMenuItemProps extends ButtonProps {
  icon: IconType | ReactIconsIconType;
  title: string;
  subtitle?: string;
  hasArrow?: boolean;
  children?: ChildWithFuncType;
  modalTitle?: string;
}

export function MobileMenuItem({
  icon,
  title,
  subtitle,
  hasArrow = true,
  children,
  modalTitle,
  ...rest
}: MobileMenuItemProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Button
      variant="ghost"
      colorScheme="secondary"
      color="gray.100"
      fontSize="xs"
      width="100%"
      h="54px"
      p="0"
      py={4}
      borderRadius={0}
      borderTopStyle="solid"
      borderTopWidth="1px"
      borderColor="pdv.divider"
      _focus={{ boxShadow: 'none' }}
      onClick={onOpen}
      {...rest}
    >
      <HStack align="center" justifyContent="flex-start" w="full" spacing={3}>
        <Icon as={icon} boxSize="5" />

        <Text fontSize="sm" fontWeight="normal">
          {title}
        </Text>

        {subtitle && (
          <Text fontSize="xs" color="aquamarine.400">
            {`( ${subtitle} )`}
          </Text>
        )}
      </HStack>

      {hasArrow && <Icon as={FiChevronRight} boxSize="4" color="white" />}

      {children && (
        <MobileModalMenu
          isOpen={isOpen}
          onClose={onClose}
          title={modalTitle || title}
          bg="pdv.nav_header"
          color="aquamarine.400"
          voltarIconColor="white"
          pt="0px !important"
        >
          {children({ isOpen, onClose })}
        </MobileModalMenu>
      )}
    </Button>
  );
}
