import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import { isValidCnpj } from 'helpers/validation/IsValidCpfCnpj';
import OperacaoIntermediadorEnum from 'constants/enum/fiscal/operacaoIntermediador';
import ModalidadesFreteEnum from 'constants/enum/fiscal/modalidadeFrete';

const schema = yup.object().shape({
  dataEmissao: yup
    .date()
    .typeError('A data informada não é valida')
    .nullable()
    .default(null)
    .test('dataSaida', 'Data não permitida', (value) => {
      if (value) {
        const testSchema = yup.date().max(new Date());

        return testSchema.isValidSync(value);
      }

      return true;
    })
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  operacaoComIntermediador: yup.number().required(),
  cnpjIntermediador: yup.string().when('operacaoComIntermediador', {
    is: (val) =>
      val === OperacaoIntermediadorEnum.OperacaoEmSitePlataformaTerceiros,
    then: yup
      .string()
      .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
      .test('cpfCnpj', ConstanteMensagemValidacao.CNPJ_INVALIDO, (value) => {
        if (value) {
          if (value.length > 14) return isValidCnpj(value);
        }

        return true;
      }),
    otherwise: yup.string().nullable().default(null),
  }),
  identificacaoNoIntermediador: yup.string().when('operacaoComIntermediador', {
    is: (val) =>
      val === OperacaoIntermediadorEnum.OperacaoEmSitePlataformaTerceiros,
    then: yup.string().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
    otherwise: yup.string().nullable().default(null),
  }),
  modalidadeFrete: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  transportadora: yup
    .object()
    .shape({
      value: yup.string().nullable().default(null),
      label: yup.string().nullable().default(null),
    })
    .when('modalidadeFrete', {
      is: (val) => val !== ModalidadesFreteEnum.SemFrete,
      then: yup
        .object()
        .shape({
          value: yup
            .string()
            .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
            .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
            .default(null),
          label: yup
            .string()
            .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
            .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
            .default(null),
        })
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
    }),
  pesoBruto: yup.number().when('modalidadeFrete', {
    is: (val) => val !== ModalidadesFreteEnum.SemFrete,
    then: yup.number().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  }),
  pesoLiquido: yup.number().when('modalidadeFrete', {
    is: (val) => val !== ModalidadesFreteEnum.SemFrete,
    then: yup.number().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  }),
  quantidadeVolumeTransportadora: yup.number().when('modalidadeFrete', {
    is: (val) => val !== ModalidadesFreteEnum.SemFrete,
    then: yup
      .number()
      .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
      .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  }),
});

export const yupResolver = yupResolverInstance(schema);

export const useForm = useFormInstance;
