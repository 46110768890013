import { Box, FlexProps, useMediaQuery } from '@chakra-ui/react';
import React, {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useRef,
  useCallback,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { IdentificacaoEtapasSmartPos } from 'constants/enum/IdentificacaoEtapasSmartPos';
import ConstanteRotas from 'constants/rotas';
import { obterEtapaSmartPos } from 'services/smartPos';

type RefHandleVoltarProps = {
  handle?: () => void;
};

interface SmartPosEtapasContextProps {
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  animacaoLoading: () => void;
  refHandleVoltar: React.RefObject<RefHandleVoltarProps>;
  imagem: string;
  setImagem: React.Dispatch<React.SetStateAction<string>>;
}

export const SmartPosEtapasContext = createContext<SmartPosEtapasContextProps>(
  {} as SmartPosEtapasContextProps
);

interface SmartPosEtapasProviderProps {
  children: React.ReactNode;
}

interface ContainerSmartPosProps extends FlexProps {
  children: React.ReactNode;
  height?: string;
}

export const ContainerIntegracaoFixo = ({
  children,
  height = '100vh',
}: ContainerSmartPosProps) => {
  const [isLargerThan600] = useMediaQuery('(min-height: 600px)');
  return (
    <Box
      overflowY="auto"
      mb={!isLargerThan600 ? '20px' : undefined}
      h={`calc(${!isLargerThan600 ? '100%' : height} - 151px)`}
    >
      {children}
    </Box>
  );
};

export function SmartPosEtapasProvider({
  children,
}: SmartPosEtapasProviderProps): JSX.Element {
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [imagem, setImagem] = useState('');

  const history = useHistory();

  const refHandleVoltar = useRef<RefHandleVoltarProps>(null);

  const animacaoLoading = useCallback(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [setIsLoading]);

  const salvandoEtapas = useCallback(async () => {
    if (activeStep > IdentificacaoEtapasSmartPos.GUIA_INTEGRACAO) {
      await api.put<void, ResponseApi<boolean>>(
        ConstanteEnderecoWebservice.CADASTRAR_ETAPA_SMART_POS,
        { identificacaoEtapasSmartPos: activeStep }
      );
    }
  }, [activeStep]);

  const obterEtapa = useCallback(async () => {
    setIsLoading(true);
    const response = await obterEtapaSmartPos();

    if (response !== null) {
      const stoneSucesso =
        response === IdentificacaoEtapasSmartPos.FOMULARIO_STONE_SUCESSO;

      const cadastroFinalizado =
        response === IdentificacaoEtapasSmartPos.CODIGO_ATIVACAO;

      if (cadastroFinalizado) {
        history.push(ConstanteRotas.SMART_POS_LISTAGEM);
      }
      setActiveStep(
        stoneSucesso ? IdentificacaoEtapasSmartPos.AUTENTICACAO : response
      );
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [history]);

  useEffect(() => {
    salvandoEtapas();
  }, [salvandoEtapas]);

  useEffect(() => {
    obterEtapa();
  }, [obterEtapa]);

  return (
    <SmartPosEtapasContext.Provider
      value={{
        activeStep,
        setActiveStep,
        isLoading,
        setIsLoading,
        animacaoLoading,
        refHandleVoltar,
        imagem,
        setImagem,
      }}
    >
      {children}
    </SmartPosEtapasContext.Provider>
  );
}

export function useSmartPosEtapasContext(): SmartPosEtapasContextProps {
  const context = useContext(SmartPosEtapasContext);

  if (!context)
    throw new Error(
      'SmartPosEtapasContext must be used within a SmartPosEtapasProvider.'
    );

  return context;
}
