export const IdentificacaoIntegracao = {
  TODAS: 0,
  TRAY: 1,
  SMART_POS: 2,
  PDV: null,

  properties: {
    0: { label: 'Todas', value: 0 },
    1: { label: 'Tray', value: 1 },
    2: { label: 'Smart POS', value: 2 },
    null: { label: 'PDV', value: null },
  },
};

export const IdentificacaoIntegracaoOptions = [
  { label: 'Todas', value: IdentificacaoIntegracao.TODAS },
  { label: 'Tray', value: IdentificacaoIntegracao.TRAY },
  { label: 'Smart POS', value: IdentificacaoIntegracao.SMART_POS },
  { label: 'PDV', value: IdentificacaoIntegracao.PDV },
];
