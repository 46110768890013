import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { CategoriaPermissaoInterface } from 'components/Permissoes';
import auth from 'modules/auth';

const listarCategoriaPermissoes = async (
  perfilId?: string
): Promise<CategoriaPermissaoInterface[] | undefined> => {
  const response = await api.get<
    void,
    ResponseApi<CategoriaPermissaoInterface[]>
  >(ConstanteEnderecoWebservice.PERFIL_USUARIO_LISTAR_CATEGORIA_PERMISSOES, {
    params: { perfilId },
  });

  if (response) {
    if (response.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (response.sucesso && response.dados) {
      const categorias: CategoriaPermissaoInterface[] = [];

      response.dados.forEach((categoria) => {
        if (
          categoria.servico === null ||
          auth.possuiServico(categoria.servico).permitido
        ) {
          categorias.push(categoria);
        }
      });

      return categorias;
    }
  }

  return undefined;

  // if (perfilId === '0') {
  //   return;
  // }
  // const response = await api.get<
  //   void,
  //   ResponseApi<Array<CategoriaPermissaoInterface>>
  // >(
  //   ConstanteEnderecoWebservice.PERFIL_USUARIO_LISTAR_CATEGORIA_PERMISSOES,
  //   {
  //     params: { perfilId: perfilId || null },
  //   }
  // );

  // if (response?.avisos) {
  //   response.avisos.map((item: string) => toast.warning(item));
  // }
  // if (response?.sucesso) {
  //   setCategorias(response.dados);
  // }
};

export default listarCategoriaPermissoes;
