import { Box, Divider, Flex, Icon, Text } from '@chakra-ui/react';

import {
  CadastrosVendedoresIcon,
  CaixaAbertoIcon,
  TabelaPrecosIcon,
  VendasIcon,
} from 'icons';

import {
  EditarItens,
  InformacaoAdicionalDoItemProps,
} from '../../Types/validationForm';

type InfoComponente = {
  h: string;
  align: string;
  jc: string;
  valorEdicao: EditarItens;
  informacaoAdicionalDoItem?: InformacaoAdicionalDoItemProps;
};

export const InformacoesItensVenda = ({
  h,
  align,
  jc,
  valorEdicao,
  informacaoAdicionalDoItem,
}: InfoComponente) => {
  return (
    <Flex h={h} justifyContent={jc} alignItems={align} color="white">
      <Flex display={valorEdicao.display}>
        <Flex mb="10px">
          <Box w="10px" mr="20px">
            <Icon as={VendasIcon} boxSize="5" color="white" />
          </Box>
          <Text fontSize="sm" mt="3px" textTransform="capitalize">
            Venda: {informacaoAdicionalDoItem?.numeroVenda}
          </Text>
        </Flex>
        {valorEdicao.aparecerItem && (
          <Divider
            orientation="vertical"
            marginLeft="4"
            marginRight="4"
            opacity="unset"
            borderLeftWidth="2px"
            h="20px"
            mt="0.5"
          />
        )}
        <Flex>
          <Box w="10px" mr="20px">
            <Icon as={CaixaAbertoIcon} boxSize="5" color="white" />
          </Box>
          <Text fontSize="sm" mt="3px" textTransform="capitalize">
            {informacaoAdicionalDoItem?.contaFinanceira}
          </Text>
        </Flex>
        {valorEdicao.aparecerItem && (
          <Divider
            orientation="vertical"
            marginLeft="4"
            marginRight="4"
            opacity="unset"
            borderLeftWidth="2px"
            h="20px"
            mt="0.5"
          />
        )}
      </Flex>
      {!valorEdicao.aparecerItem && (
        <Divider
          orientation="vertical"
          marginLeft="4"
          marginRight="4"
          opacity="unset"
          borderLeftWidth="2px"
          h="50px"
          mt="0.5"
        />
      )}
      <Flex display={valorEdicao.display}>
        <Flex mb="10px">
          <Box w="10px" mr="20px">
            <Icon as={CadastrosVendedoresIcon} boxSize="5" color="white" />
          </Box>
          <Text fontSize="sm" mt="3px" textTransform="capitalize">
            {informacaoAdicionalDoItem?.vendedor}
          </Text>
        </Flex>
        {valorEdicao.aparecerItem && (
          <Divider
            orientation="vertical"
            marginLeft="4"
            marginRight="4"
            opacity="unset"
            borderLeftWidth="2px"
            h="20px"
            mt="0.5"
          />
        )}
        <Flex>
          <Box w="10px" mr="20px">
            <Icon as={TabelaPrecosIcon} boxSize="5" color="white" />
          </Box>
          <Text fontSize="sm" mt="3px" textTransform="capitalize">
            {informacaoAdicionalDoItem?.tabelaPreco}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
