import React from 'react';
import {
  useMediaQuery,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';

import { MobileModalMenu } from 'components/PDV/Layout/MobileModalMenu';
import ModalPadraoChakra from '../ModalPadraoChakra';

import { ConteudoModalAtalhos } from './ConteudoModalAtalhos';

interface ModalAtalhosProps {
  isOpen: boolean;
  onClose: () => void;
  asMobileView?: boolean;
}

const ModalAtalhos: React.FC<ModalAtalhosProps> = ({
  isOpen,
  onClose,
  asMobileView = false,
}) => {
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  if (asMobileView) {
    return (
      <MobileModalMenu isOpen={isOpen} onClose={onClose} title="Atalhos">
        <ConteudoModalAtalhos />
      </MobileModalMenu>
    );
  }

  return (
    <ModalPadraoChakra
      isOpen={isOpen}
      onClose={onClose}
      size={isLargerThan900 ? 'xl' : 'full'}
      scrollBehavior={isLargerThan900 ? 'inside' : 'outside'}
      isCentered={isLargerThan900}
    >
      <ModalContent
        margin={0}
        maxW={isLargerThan900 ? 600 : undefined}
        borderRadius={isLargerThan900 ? 'md' : 0}
        bg="gray.50"
      >
        <ModalHeader
          color="primary.500"
          mt={isLargerThan900 ? undefined : 12}
          mb={isLargerThan900 ? undefined : 8}
        >
          Atalhos
        </ModalHeader>
        <ModalCloseButton
          mt={isLargerThan900 ? undefined : 14}
          mr={isLargerThan900 ? undefined : 6}
        />
        <ModalBody pb={6}>
          <ConteudoModalAtalhos />
        </ModalBody>
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export default ModalAtalhos;
