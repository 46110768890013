import React from 'react';

import TipoImportacaoEnum from 'constants/enum/tipoImportacao';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

import ImportarProdutosListar from '../pages/Importacao/Listar';
import ImportarProdutosImportar from '../pages/Importacao/Importar';

const ImportarRoutes = [
  <LayoutGuard
    key="importarprodutos-listar"
    permissaoFuncionalidade={ConstanteFuncionalidades.IMPORTACAO_PRODUTO_LISTAR}
    component={ImportarProdutosListar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Importar Produtos',
      },
    ]}
    path={ConstanteRotas.IMPORTAR_PRODUTOS}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="importarprodutos-importar"
    permissaoFuncionalidade={
      ConstanteFuncionalidades.IMPORTACAO_PRODUTO_IMPORTAR
    }
    component={() => (
      <ImportarProdutosImportar tipoImportacao={TipoImportacaoEnum.Produtos} />
    )}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Importar Produtos',
        path: ConstanteRotas.IMPORTAR_PRODUTOS,
      },
      {
        title: 'Novo',
      },
    ]}
    path={ConstanteRotas.IMPORTAR_PRODUTOS_IMPORTAR}
    exact
    meta={{ auth: true }}
  />,
];

export default ImportarRoutes;
