import { useState } from 'react';
import {
  Text,
  Box,
  Flex,
  Button,
  Avatar,
  Icon,
  Divider,
} from '@chakra-ui/react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

import { moneyMask } from 'helpers/format/fieldsMasks';

import { LixeiraIcon, SalvarInserirNovoIcon } from 'icons';
import {
  ComissaoDosVendedoresProps,
  FaixaComissaoProps,
} from 'pages/Vendas/MetasComissoes/Types/validationsForm';
import { NumberInput } from 'components/update/Input/NumberInput';

import { ExibirMetasFixas } from '../Components/ExibirMetasFixas';

type ComissaoVendedoresMobileProps = {
  listaFaixasComissao: FaixaComissaoProps[];
  handleAdicionarOutraFaixa: () => Promise<void>;
  obterIdVendedor: string;
  comissaoDosVendedores: ComissaoDosVendedoresProps[];
  handleAlterarValorCampoPercentualComissao(
    valorId: string,
    valorPercentComissao: number | string,
    index: number
  ): void;
  handleExcluirFaixa: (id: string) => Promise<void>;
  existeMetaIgual: boolean;
  obterValorMetaTotalVendedor: number;
  handleAtualizarDadosVendedores: (
    idVendedor: string,
    metaMensal: number
  ) => Promise<void>;
  handleAlterarValorCampoAlcanceMeta(
    valorId: string,
    valorAlcance: number | string,
    index: number
  ): void;
};

export const ComissaoVendedoresMobile = ({
  listaFaixasComissao,
  existeMetaIgual,
  obterValorMetaTotalVendedor,
  handleAlterarValorCampoPercentualComissao,
  handleAdicionarOutraFaixa,
  obterIdVendedor,
  handleAlterarValorCampoAlcanceMeta,
  handleExcluirFaixa,
  comissaoDosVendedores,
  handleAtualizarDadosVendedores,
}: ComissaoVendedoresMobileProps) => {
  const [exibirMetaVendedores, setExibirMetaVendedores] = useState(-1);

  const listaAtualizadaFaixasComissao = listaFaixasComissao.filter(
    (faixaComissao) => faixaComissao.idVendedor === obterIdVendedor
  );

  function exibirMetas(index: number) {
    setExibirMetaVendedores(exibirMetaVendedores === index ? -1 : index);
  }

  return (
    <Box
      overflowY={comissaoDosVendedores.length > 2 ? 'scroll' : 'hidden'}
      h="400px"
      sx={{
        '&::-webkit-scrollbar': {
          height: '0',
          width: '0',
        },
        '& .virtualized_List::-webkit-scrollbar': {
          height: '0',
          width: '0',
        },
      }}
    >
      {comissaoDosVendedores.map((comissaoVendedor, index) => (
        <Box
          bg={exibirMetaVendedores === index ? '#F5F5F5' : 'white'}
          w="full"
          pt="10px"
          pb="10px"
        >
          <Flex
            pl="4%"
            pr="4%"
            onClick={() => {
              handleAtualizarDadosVendedores(
                comissaoVendedor.vendedorId,
                comissaoVendedor.valorMeta
              );
              exibirMetas(index);
            }}
            cursor="pointer"
            justifyContent="space-between"
            alignItems="center"
            w="100%"
          >
            <Flex justifyContent="center" alignItems="center">
              <Avatar
                boxSize="30px"
                mr="10px"
                ml="10px"
                name={comissaoVendedor.foto}
                src={comissaoVendedor.foto}
              />
              <Text>{comissaoVendedor.vendedor}</Text>
            </Flex>
            <Icon
              as={exibirMetaVendedores === index ? FiChevronUp : FiChevronDown}
            />
          </Flex>
          {exibirMetaVendedores === index && (
            <Box
              mt="15px"
              overflowY={
                listaAtualizadaFaixasComissao.length > 2 ? 'scroll' : 'hidden'
              }
              sx={{
                '&::-webkit-scrollbar': {
                  height: '0',
                  width: '0',
                },
                '& .virtualized_List::-webkit-scrollbar': {
                  height: '0',
                  width: '0',
                },
              }}
              pl="10px"
              pr="10px"
            >
              <ExibirMetasFixas comissaoVendedor={comissaoVendedor} />
              {listaAtualizadaFaixasComissao.map(
                (faixaComissao, indexFaixa) => {
                  return (
                    <>
                      <Flex
                        mt="15px"
                        mb="15px"
                        direction="column"
                        color="gray.700"
                        alignItems="left"
                        justifyContent="left"
                      >
                        <Flex alignItems="center" justifyContent="left">
                          <Text mr="10px" fontSize="16px">
                            Quando alcançar:
                          </Text>

                          <Box w="100px" mr="10px">
                            <NumberInput
                              id="quandoAlcancar"
                              name={`quandoAlcancar.${faixaComissao.id}`}
                              mr="2px"
                              rightElementPorcentagem="%"
                              scale={2}
                              color="gray.600"
                              onValueChange={async (valueAsString) => {
                                handleAlterarValorCampoAlcanceMeta(
                                  faixaComissao.id,
                                  valueAsString,
                                  indexFaixa
                                );
                              }}
                              fontSize={{
                                base: 'sm',
                                md: 'md',
                              }}
                            />
                          </Box>
                        </Flex>
                        <Flex alignItems="center" justifyContent="left">
                          <Text mr="10px" fontSize="16px">
                            <strong>
                              {moneyMask(
                                faixaComissao.valorDaMetaComAlcancePercent,
                                true
                              )}
                            </strong>
                            , o percentual da comissão será de
                          </Text>
                          <Box w="82px">
                            <NumberInput
                              widhtPorcentagem="20"
                              h="40px"
                              id={`percentualComissao.${faixaComissao.id}`}
                              rightElementPorcentagem="%"
                              name={`percentualComissao.${faixaComissao.id}`}
                              scale={2}
                              onValueChange={(valorPercentComissao) =>
                                handleAlterarValorCampoPercentualComissao(
                                  faixaComissao.id,
                                  valorPercentComissao,
                                  indexFaixa
                                )
                              }
                            />
                          </Box>
                          <Icon
                            boxSize="19px"
                            cursor="pointer"
                            ml="20px"
                            onClick={() => handleExcluirFaixa(faixaComissao.id)}
                            as={LixeiraIcon}
                          />
                        </Flex>
                      </Flex>

                      <Divider w="full" />
                    </>
                  );
                }
              )}
              <Button
                color="blue.700"
                mt="15px"
                mb="20px"
                isDisabled={
                  existeMetaIgual || obterValorMetaTotalVendedor === 0
                }
                onClick={() => handleAdicionarOutraFaixa()}
                leftIcon={<Icon as={SalvarInserirNovoIcon} fontSize="lg" />}
                variant="link"
              >
                Adicionar outra faixa
              </Button>
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};
