import React, { useEffect, useState, useCallback } from 'react';
import { FormProvider } from 'react-hook-form';
import { Prompt } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box } from '@chakra-ui/react';

import api, { ResponseApi } from 'services/api';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import isPrenvent from 'helpers/layout/isPrenvent';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';

import RodapeFormulario from 'components/Geral/RodapeFormulario';
import ManterFoco from 'components/Geral/ManterFoco';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { useForm, yupResolver } from '../validationForm';
import { UncontrolledForm } from '..';

interface PadronizacaoInterface {
  dataHoraUltimaAlteracao: string;
  casasDecimaisValor: number;
  casasDecimaisQuantidade: number;
}

const Alterar = () => {
  const { setCasasDecimais } = usePadronizacaoContext();
  const isMountedRef = useIsMountedRef();
  isPrenvent();

  const [isLoading, setIsLoading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);

  const [dataHoraUltimaAlteracao, setDataHoraUltimaAlteracao] = useState('');

  const formMethods = useForm<PadronizacaoInterface>({
    resolver: yupResolver,
    shouldUnregister: false,
    defaultValues: {
      casasDecimaisQuantidade: 2,
      casasDecimaisValor: 2,
    },
  });

  const { handleSubmit, reset, getValues, formState } = formMethods;

  const handleGetPadronizacao = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<void, ResponseApi<PadronizacaoInterface>>(
      ConstanteEnderecoWebservice.PADRONIZACAO_OBTER
    );
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso && isMountedRef.current) {
        reset({
          ...response.dados,
        });

        setDataHoraUltimaAlteracao(response.dados.dataHoraUltimaAlteracao);
      }
    }

    if (isMountedRef.current) setIsLoading(false);
  }, [isMountedRef, reset]);

  const onSubmit = handleSubmit(async () => {
    setIsLoading(true);

    const data = getValues();

    const response = await api.put<void, ResponseApi>(
      ConstanteEnderecoWebservice.PADRONIZACAO_ALTERAR,
      data
    );
    if (response) {
      if (response.sucesso) {
        toast.success('O cadastro foi alterado com sucesso.');

        setFormIsDirty(false);
        setIsLoading(false);

        handleGetPadronizacao();

        setCasasDecimais({
          casasDecimaisQuantidade: data.casasDecimaisQuantidade,
          casasDecimaisValor: data.casasDecimaisValor,
        });

        return;
      }

      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }
    }

    if (isMountedRef.current) setIsLoading(false);
  });

  useEffect(() => {
    setIsLoading(true);

    handleGetPadronizacao();
  }, [handleGetPadronizacao]);

  useEffect(() => {
    setFormIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  return (
    <Box w="full">
      <Prompt when={formIsDirty} message="" />
      <FormProvider {...formMethods}>
        <ManterFoco blockTab={isLoading}>
          <Box
            p="30px"
            bg="white"
            width="full"
            borderRadius="md"
            boxShadow="0px 0px 6px #00000034"
          >
            {isLoading && <LoadingPadrao />}
            <UncontrolledForm />
          </Box>
          <RodapeFormulario
            dataHoraUltimaAlteracao={dataHoraUltimaAlteracao}
            onSubmit={onSubmit}
            disabled={isLoading}
          />
        </ManterFoco>
      </FormProvider>
    </Box>
  );
};

export default Alterar;
