import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export type ProdutoCorResponse = {
  id: string;
  nome: string;
  descricao: string;
  precoVenda: number;
};

export type TamanhosResponse = ProdutoCorResponse & {
  padraoSistema: boolean;
};

export type EtiquetaResponse = ProdutoCorResponse & {
  ativo: boolean;
  configuracoes: string;
};

export type ProdutosImpressao = {
  id: string;
  produto: string;
  quantidade: number;
  tamanho: string;
  precoVenda: number;
  padraoSistema?: boolean;
};

export type ProdutoCorTamanho = {
  corDescricao?: string;
  produtoCorTamanhoId: string;
  produtoNome: string;
  quantidade: number;
  tamanhoDescricao?: string;
  precoVenda: number;
  padraoSistema?: boolean;
};

export type ProdutoResponse = {
  corDescricao: string;
  label: string;
  produtoNome: string;
  value: string;
  precoVenda: number;
  padraoSistema: boolean;
};

export type FormData = {
  colunaInicial: number;
  infoProdutos: ProdutoResponse | null;
  linhaInicial: number;
  quantidade: number;
  quantidadePorProduto: { [key: string]: number };
  tamanho: string;
  valorEtiqueta: string;
};

export const formDefaultValues = {
  colunaInicial: 1,
  linhaInicial: 1,
  quantidade: 1,
  tamanho: '',
};

const schema = yup.object().shape({
  valorEtiqueta: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);
