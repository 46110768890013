import React, { useCallback, useEffect, useRef } from 'react';
import {
  Flex,
  Grid,
  GridItem,
  Button,
  useDisclosure,
  useMediaQuery,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import MenuContextProvider from 'store/PDV/Menu';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteRotasPDV from 'constants/rotasPDV';
import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';
import { SubstituirParametroRota } from 'constants/rotas';
import { useInformacoesGeraisContext } from 'store/PDV/InformacoesGerais';

import { isIOS } from 'helpers/layout/isIOS';
import Layout from 'components/PDV/Layout';
import SelectCliente, {
  SelectClienteRefInterface,
} from 'components/PDV/Select/SelectCliente';
import SelectVendedor, {
  ForwardRefMethods,
} from 'components/PDV/Select/SelectVendedor';
import ManterFoco from 'components/Geral/ManterFoco';
import ModalSelecionarVendedor, {
  VendedorSelecionado,
} from 'components/PDV/Modal/ModalSelecionarVendedor';

import { isMobile } from 'react-device-detect';
import { yupResolver } from './validationForm';
import HomeInternalHeader from './InternalHeader';
import HomeFooter from './Footer';

type SParams = {
  orcamento?: boolean;
};

type VendedorVinculado = {
  id: string;
  nome: string;
};

type Formdata = {
  identificacaoTipoOperacao: number | undefined;
  cliente: {
    tabelaPrecoNome: string;
    tabelaPrecoId: string;
  };
  vendedorId: string;
  tabelaPreco: {
    name: string;
    value: string;
  };
};
const Home = ({
  location: { state },
}: RouteComponentProps<any, any, SParams>) => {
  const isNavHeaderVisible = useBreakpointValue({ base: false, md: true });

  const {
    localEstoquePadraoDoUsuario,
    handleSetInformacoesLocalEstoque,
    onChangeOperacaoItem,
  } = useInformacoesGeraisContext();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const deveUsarModalVendedor = !isLargerThan900 || isMobile;

  const { orcamento: orcamentoDefaultValue = undefined } = state || {};

  const history = useHistory();
  const formMethods = useForm<Formdata>({
    resolver: yupResolver,
    defaultValues: {
      identificacaoTipoOperacao: orcamentoDefaultValue
        ? IdentificacaoTipoOperacaoEnum.ORCAMENTO
        : IdentificacaoTipoOperacaoEnum.PEDIDO,
      vendedorId: '',
    },
  });

  const selectVendedorRef = useRef<ForwardRefMethods>(null);
  const buttonLancarProdutos = useRef<any>();
  const { setVendedorPdv, setDetalhesTroca } = useInformacoesGeraisContext();

  const selectClienteRef = useRef<SelectClienteRefInterface>(
    {} as SelectClienteRefInterface
  );

  const vendedorWatch = formMethods.watch('vendedorId');
  useEffect(() => {
    if (selectVendedorRef.current) {
      const vendedorSelecionado = selectVendedorRef.current.handleGetVendedorSelecionado();

      if (vendedorSelecionado) {
        const vendedor = {
          id: vendedorWatch,
          nome: vendedorSelecionado.nome,
        };

        setVendedorPdv(vendedor);
      }
    }
  }, [setVendedorPdv, vendedorWatch]);

  useEffect(() => {
    const valorReset = {
      idOperacao: '',
      valorTotalTroca: 0,
    };
    setDetalhesTroca(valorReset);
  }, [setDetalhesTroca]);

  const onSubmit = formMethods.handleSubmit(async (data) => {
    const { cliente, identificacaoTipoOperacao } = data;

    const vendedor = { id: data.vendedorId, nome: '' };
    if (selectVendedorRef.current) {
      const vendedorSelecionado = selectVendedorRef.current.handleGetVendedorSelecionado();

      if (vendedorSelecionado) {
        vendedor.nome = vendedorSelecionado.nome;
      }
    }

    localStorage.setItem('pdv-operacao-inicial-vendedorId', vendedor.id);

    const tabelaPreco = formMethods.getValues('tabelaPreco' as any);

    history.push(ConstanteRotasPDV.PDV_LANCAMENTO, {
      cliente,
      vendedor,
      identificacaoTipoOperacao,
      tabelaPreco,
    });
  });

  // método utilizado para  alterar a rota do cliente caso exista um pedido aberto e o plano seja start e pro
  const getLastRequest = async () => {
    const response = await api.get<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.ULTIMO_PEDIDO_CADASTRADO
    );

    if (response.avisos) {
      response.avisos.forEach((item) => toast.warn(item));
    }

    if (response.dados) {
      history.push(
        SubstituirParametroRota(
          ConstanteRotasPDV.PDV_LANCAMENTO_ID,
          'id',
          response.dados
        )
      );
    }
  };

  const obterClientePadraoSistema = useCallback(async () => {
    const response = await api.get<void, ResponseApi<any>>(
      ConstanteEnderecoWebservice.CLIENTE_FORNECEDOR_OBTER_PADRAO_SISTEMA
    );

    if (response?.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (response?.sucesso) {
      selectClienteRef?.current?.setCliente(response.dados);
    }
  }, []);

  useEffect(() => {
    handleSetInformacoesLocalEstoque(localEstoquePadraoDoUsuario.current);
    onChangeOperacaoItem(0);
  }, [
    onChangeOperacaoItem,
    handleSetInformacoesLocalEstoque,
    localEstoquePadraoDoUsuario,
  ]);

  useEffect(() => {
    obterClientePadraoSistema();
  }, [obterClientePadraoSistema]);

  const obterVendedorOperacaoAnterior = useCallback(async () => {
    if (!formMethods.getValues('vendedorId')) {
      const vendedores = selectVendedorRef.current?.vendedores;

      if (vendedores) {
        const response = await api.get<void, ResponseApi<VendedorVinculado>>(
          ConstanteEnderecoWebservice.OBTER_VENDEDOR_VINCULADO
        );
        if (response) {
          if (response.avisos) {
            response.avisos.forEach((aviso) => toast.warning(aviso));
          }
          if (response.sucesso && response.dados) {
            const existeVendedorVinculado = vendedores.some(
              (vendedorItem) => vendedorItem.value === response.dados.id
            );

            if (existeVendedorVinculado) {
              formMethods.setValue('vendedorId', response.dados.id);
            }
          }
        }
      }
    }
  }, [formMethods]);

  useEffect(() => {
    getLastRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isIOS()) {
      if (document.activeElement) {
        (document.activeElement as HTMLInputElement).blur();
      }
    } else if (selectClienteRef.current.focus) {
      setTimeout(() => {
        selectClienteRef.current?.focus();
      }, 1000);
    }
  }, []);

  const latestProps = useRef({ setValue: formMethods.setValue });

  useEffect(() => {
    latestProps.current = { setValue: formMethods.setValue };
  });

  const cliente = formMethods.watch('cliente');
  useEffect(() => {
    if (cliente?.tabelaPrecoId && cliente?.tabelaPrecoNome) {
      latestProps.current.setValue('tabelaPreco', {
        name: cliente?.tabelaPrecoNome,
        value: cliente?.tabelaPrecoId,
      });
    }
  }, [cliente]);

  return (
    <FormProvider {...formMethods}>
      <MenuContextProvider asAccordion={!isNavHeaderVisible}>
        <Layout
          NavHeaderContent={isNavHeaderVisible ? <HomeInternalHeader /> : null}
          FooterContent={<HomeFooter />}
          containerOverflow="auto"
          bodyOverflow="visible"
        >
          <form
            onSubmit={onSubmit}
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <ManterFoco
              style={{
                width: '100%',
                height: '100%',
              }}
            >
              <Flex
                h="100%"
                w="100%"
                alignItems={{ base: 'flex-start', md: 'center' }}
                justifyContent="center"
                px="6"
                py={{ base: '10', md: '6' }}
              >
                <Grid
                  w="100%"
                  maxW="900px"
                  templateColumns={{ base: 'auto', md: '6fr 4fr' }}
                  templateRows={{
                    base: 'repeat(3, auto)',
                    md: 'repeat(2, auto)',
                  }}
                  gap={6}
                >
                  <GridItem colSpan={2}>
                    <SelectCliente
                      id="cliente"
                      name="cliente"
                      label="Cliente"
                      placeholder="Digite o código, nome ou CPF/CNPJ do cliente"
                      required
                      ref={selectClienteRef}
                      size="lg"
                      habilitarMenu
                      shouldAppearTheAddress
                    />
                  </GridItem>

                  <SelectVendedor
                    ref={selectVendedorRef}
                    id="vendedorId"
                    name="vendedorId"
                    label="Vendedor"
                    placeholder="Selecione o vendedor"
                    required
                    size="lg"
                    onClick={!deveUsarModalVendedor ? undefined : onOpen}
                    executeOnLoad={obterVendedorOperacaoAnterior}
                  />

                  <GridItem
                    colSpan={{ base: 2, md: 1 }}
                    display="flex"
                    alignItems="center"
                    pt={{ base: '4', md: '0' }}
                  >
                    <Button
                      ref={buttonLancarProdutos}
                      variant="solid"
                      colorScheme="secondary"
                      size="lg"
                      w="full"
                      mt={{ base: 0, md: 5 }}
                      borderRadius="full"
                      type="submit"
                    >
                      Lançar produtos
                    </Button>
                  </GridItem>
                </Grid>
              </Flex>
            </ManterFoco>
          </form>
        </Layout>
      </MenuContextProvider>
      <ModalSelecionarVendedor
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={(newVendedor: VendedorSelecionado) => {
          formMethods.setValue('vendedorId', newVendedor.id);
        }}
        getCurrentVendedor={() => {
          return formMethods.getValues('vendedorId') || undefined;
        }}
        finalFocusRef={buttonLancarProdutos}
        asMobileView={!isLargerThan900}
      />
    </FormProvider>
  );
};

export default Home;
