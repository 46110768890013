import RegimeTributarioEnum from 'constants/enum/regimeTributario';
import { IndicadorIeEnum } from 'constants/enum/indicadorIe';
import { RegraFiscal, BaseLegal, RegraFiscalItem } from 'types/regrasFiscais';

type FormBaseLegalData = {
  id?: string;
  descricao: string;
};

type FormRegraFiscalItemData = {
  id?: string;
  naturezaOperacao?: string;
  modalidadeBaseCalculoIcms?: number;
  cfopDentroEstado?: string;
  cfopForaEstado?: string;
  cfopCupomVinculadoDentroEstado?: string;
  cfopCupomVinculadoForaEstado?: string;
  pisCofinsCst?: string;
  cstCsosnDentroEstado?: string;
  cstCsosnForaEstado?: string;
  basesLegais?: FormBaseLegalData[];
  calcularTributosMunEstFed?: boolean;
};

type FormRegimeTributarioData = {
  contribuinte: FormRegraFiscalItemData;
  naoContribuinte: FormRegraFiscalItemData;
};

export type FormOperacaoData = {
  identificacaoTipoOperacao: number;
  simplesNacional: FormRegimeTributarioData;
  regimeNormal: FormRegimeTributarioData;
};

export type FormRegraFiscalData = {
  nome: string;
  ativo: boolean;
  operacoes: FormOperacaoData[];
};

function getFormattedBasesLegais(
  basesLegais?: FormBaseLegalData[]
): BaseLegal[] | undefined {
  if (!basesLegais) return undefined;

  return basesLegais.map((baseLegal) => ({ ...baseLegal, ativo: true }));
}

export function mapRequestRegraFiscalData(
  formData: FormRegraFiscalData,
  regraFiscalId?: string
): RegraFiscal {
  const { operacoes, ...restFormData } = formData;

  const regraFiscalItens: RegraFiscalItem[] = [];

  operacoes.forEach((operacao) => {
    regraFiscalItens.push({
      identificacaoTipoOperacao: operacao.identificacaoTipoOperacao,
      regimeTributario: RegimeTributarioEnum.SIMPLES_NACIONAL,
      indicadorIe: IndicadorIeEnum.CONTRIBUINTE_ICMS,
      ...operacao.simplesNacional.contribuinte,
      basesLegais: getFormattedBasesLegais(
        operacao.simplesNacional.contribuinte.basesLegais
      ),
    });
    regraFiscalItens.push({
      identificacaoTipoOperacao: operacao.identificacaoTipoOperacao,
      regimeTributario: RegimeTributarioEnum.SIMPLES_NACIONAL,
      indicadorIe: IndicadorIeEnum.NAO_CONTRIBUINTE,
      ...operacao.simplesNacional.naoContribuinte,
      basesLegais: getFormattedBasesLegais(
        operacao.simplesNacional.naoContribuinte.basesLegais
      ),
    });

    regraFiscalItens.push({
      identificacaoTipoOperacao: operacao.identificacaoTipoOperacao,
      regimeTributario: RegimeTributarioEnum.REGIME_NORMAL,
      indicadorIe: IndicadorIeEnum.CONTRIBUINTE_ICMS,
      ...operacao.regimeNormal.contribuinte,
      basesLegais: getFormattedBasesLegais(
        operacao.regimeNormal.contribuinte.basesLegais
      ),
    });
    regraFiscalItens.push({
      identificacaoTipoOperacao: operacao.identificacaoTipoOperacao,
      regimeTributario: RegimeTributarioEnum.REGIME_NORMAL,
      indicadorIe: IndicadorIeEnum.NAO_CONTRIBUINTE,
      ...operacao.regimeNormal.naoContribuinte,
      basesLegais: getFormattedBasesLegais(
        operacao.regimeNormal.naoContribuinte.basesLegais
      ),
    });
  });

  return {
    ...restFormData,
    id: regraFiscalId,
    regraFiscalItens,
  };
}

export function mapResponseRegraFiscalData(
  data: RegraFiscal
): FormRegraFiscalData {
  const { nome, ativo, regraFiscalItens } = data;

  const operacoes = regraFiscalItens.reduce<FormOperacaoData[]>(
    (prev, regraFiscalItem) => {
      const identificacaoTipoOperacaoIndex = prev.findIndex(
        (prevValue) =>
          prevValue.identificacaoTipoOperacao ===
          regraFiscalItem.identificacaoTipoOperacao
      );

      if (identificacaoTipoOperacaoIndex > -1) {
        const currentValue = prev[identificacaoTipoOperacaoIndex];

        prev.splice(identificacaoTipoOperacaoIndex, 1);

        if (
          regraFiscalItem.regimeTributario ===
          RegimeTributarioEnum.SIMPLES_NACIONAL
        ) {
          if (
            regraFiscalItem.indicadorIe === IndicadorIeEnum.CONTRIBUINTE_ICMS
          ) {
            prev.splice(identificacaoTipoOperacaoIndex, 0, {
              ...currentValue,
              simplesNacional: {
                ...currentValue.simplesNacional,
                contribuinte: regraFiscalItem,
              },
            });
          } else if (
            regraFiscalItem.indicadorIe === IndicadorIeEnum.NAO_CONTRIBUINTE
          ) {
            prev.splice(identificacaoTipoOperacaoIndex, 0, {
              ...currentValue,
              simplesNacional: {
                ...currentValue.simplesNacional,
                naoContribuinte: regraFiscalItem,
              },
            });
          }
        } else if (
          regraFiscalItem.regimeTributario ===
          RegimeTributarioEnum.REGIME_NORMAL
        ) {
          if (
            regraFiscalItem.indicadorIe === IndicadorIeEnum.CONTRIBUINTE_ICMS
          ) {
            prev.splice(identificacaoTipoOperacaoIndex, 0, {
              ...currentValue,
              regimeNormal: {
                ...currentValue.regimeNormal,
                contribuinte: regraFiscalItem,
              },
            });
          } else if (
            regraFiscalItem.indicadorIe === IndicadorIeEnum.NAO_CONTRIBUINTE
          ) {
            prev.splice(identificacaoTipoOperacaoIndex, 0, {
              ...currentValue,
              regimeNormal: {
                ...currentValue.regimeNormal,
                naoContribuinte: regraFiscalItem,
              },
            });
          }
        }
      } else if (
        regraFiscalItem.regimeTributario ===
        RegimeTributarioEnum.SIMPLES_NACIONAL
      ) {
        if (regraFiscalItem.indicadorIe === IndicadorIeEnum.CONTRIBUINTE_ICMS) {
          prev.push({
            identificacaoTipoOperacao:
              regraFiscalItem.identificacaoTipoOperacao,
            simplesNacional: {
              contribuinte: regraFiscalItem,
              naoContribuinte: {},
            },
            regimeNormal: {
              contribuinte: {},
              naoContribuinte: {},
            },
          });
        } else if (
          regraFiscalItem.indicadorIe === IndicadorIeEnum.NAO_CONTRIBUINTE
        ) {
          prev.push({
            identificacaoTipoOperacao:
              regraFiscalItem.identificacaoTipoOperacao,
            simplesNacional: {
              contribuinte: {},
              naoContribuinte: regraFiscalItem,
            },
            regimeNormal: {
              contribuinte: {},
              naoContribuinte: {},
            },
          });
        }
      } else if (
        regraFiscalItem.regimeTributario === RegimeTributarioEnum.REGIME_NORMAL
      ) {
        if (regraFiscalItem.indicadorIe === IndicadorIeEnum.CONTRIBUINTE_ICMS) {
          prev.push({
            identificacaoTipoOperacao:
              regraFiscalItem.identificacaoTipoOperacao,
            simplesNacional: {
              contribuinte: {},
              naoContribuinte: {},
            },
            regimeNormal: {
              contribuinte: regraFiscalItem,
              naoContribuinte: {},
            },
          });
        } else if (
          regraFiscalItem.indicadorIe === IndicadorIeEnum.NAO_CONTRIBUINTE
        ) {
          prev.push({
            identificacaoTipoOperacao:
              regraFiscalItem.identificacaoTipoOperacao,
            simplesNacional: {
              contribuinte: {},
              naoContribuinte: {},
            },
            regimeNormal: {
              contribuinte: {},
              naoContribuinte: regraFiscalItem,
            },
          });
        }
      }

      return prev;
    },
    []
  );

  return { nome, ativo, operacoes };
}
