import React, { ComponentType, ReactElement, ReactNode } from 'react';
import { TypographyProps } from '@chakra-ui/react';
import {
  Controller,
  ControllerRenderProps,
  useFormContext,
  UseFormReturn,
} from 'react-hook-form';

import GridItemProps from 'types/gridItemProps';
import CampoContainer, { CampoContainerProps } from '../CampoContainer';

interface ReadModeProps {
  value: any;
}

export interface CampoPrototipoProps extends GridItemProps {
  id?: string;
  name: string;
  label?: ReactNode | string;
  labelColor?: string;
  required?: boolean;
  disabled?: boolean;
  helperText?: ReactNode | string;
  errorText?: string;
  errorPropName?: string;
  actionLinkText?: string;
  actionLinkOnClick?: () => void;
  fontWeightLabel?: TypographyProps['fontWeight'];
}

interface InnerPrototypeProps {
  isInvalid: boolean;
}

export interface IProps extends Omit<CampoContainerProps, 'children'> {
  children: (
    methods: UseFormReturn<Record<string, any>>,
    controllerProps: ControllerRenderProps,
    innerProps: InnerPrototypeProps,
    ref: any
  ) => ReactElement;
  readModeComponent?: ComponentType<ReadModeProps>;
  ref?: any;
  helperTextLabel?: string;
  errorPropName?: string;
  errorText?: string;
  color?: string;
  bgHelperText?: string;
  colorHelperText?: string;
}

const CampoPrototipo = ({
  children,
  name,
  color = 'black',
  labelColor = 'black',
  bgHelperText = 'black',
  colorHelperText = 'white',
  isRequired = true,
  defaultValue,
  ref,
  errorPropName,
  helperTextLabel,
  errorText,
  fontWeightLabel = 'semibold',
  actionLinkText,
  actionLinkOnClick,
  ...rest
}: IProps) => {
  const methods = useFormContext();
  let error = methods?.formState?.errors[name];

  const isInvalid = !!errorText || !!error;

  if (errorPropName) {
    error = error ? error[errorPropName] : null;
  }

  return (
    <CampoContainer
      errorText={errorText || error?.message}
      isInvalid={isInvalid}
      name={name}
      color={color}
      labelColor={labelColor}
      helperTextLabel={helperTextLabel}
      colorHelperText={colorHelperText}
      bgHelperText={bgHelperText}
      isRequired={isRequired}
      fontWeightLabel={fontWeightLabel}
      actionLinkText={actionLinkText}
      actionLinkOnClick={actionLinkOnClick}
      {...rest}
    >
      <Controller
        name={`${name}` as const}
        render={({ field }) => {
          return children(methods, field, { isInvalid }, ref);
        }}
        defaultValue={defaultValue}
      />
    </CampoContainer>
  );
};

export default CampoPrototipo;
