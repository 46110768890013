import { useCallback, useEffect, useState, useImperativeHandle } from 'react';
import { Text, VStack, HStack, GridItem } from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';

import {
  getLocalEstoque as getLocalEstoqueResponse,
  cadastrarLocalEstoque,
  getLocalEstoquePadrao,
  getLocalEstoqueSmartPos,
} from 'helpers/data/getLocalEstoque';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { useSmartPosContext } from 'store/SmartPos/SmartPosContext';
import {
  cadastrarSenhaAdm,
  getSenhaAdm,
  getCodigoAtivacaoSmartPos,
} from 'services/smartPos';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import Input from 'components/PDV/Input';

import { Container } from '../Layout/Container';
import { ModalAlterarSenha } from './ModalAlterarSenha';

type LocalEstoqueProps = {
  label: string;
  value: string;
};

type FormData = {
  senhaAdm: string;
  localEstoque: string;
};

type PlanoContratadoProps = {
  total: number;
  ativo: number;
};

export const Configuracao = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [codigoAtivacao, setCodigoAtivacao] = useState<string[]>([]);
  const [listaLocalEstoque, setListaLocalEstoque] = useState<
    LocalEstoqueProps[]
  >([]);
  const [planoContratado, setPlanoContratado] = useState<PlanoContratadoProps>(
    {} as PlanoContratadoProps
  );

  const { ref, setIsStepAlterada } = useSmartPosContext();

  const formMethods = useForm<FormData>();

  const { reset, setValue, handleSubmit, formState } = formMethods;

  const alterarSenhaAdm = useCallback(
    (senha: string) => {
      setValue('senhaAdm', senha);
    },
    [setValue]
  );

  const getPlanoContratado = useCallback(async () => {
    const response = await api.get<void, ResponseApi<number>>(
      ConstanteEnderecoWebservice.INTEGRACAO_SMART_POS_PLANO_CONTRATADO
    );

    return response;
  }, []);

  const getPlanoAtivo = useCallback(async () => {
    const response = await api.get<void, ResponseApi<number>>(
      ConstanteEnderecoWebservice.INTEGRACAO_SMART_POS_PLANO_ATIVA
    );

    return response;
  }, []);

  const getValoresPlanos = useCallback(async () => {
    const [responsePlanoContratado, responsePlanoAtivo] = await Promise.all([
      getPlanoContratado(),
      getPlanoAtivo(),
    ]);

    const hasPlanoContratado =
      responsePlanoContratado && responsePlanoContratado.sucesso;
    const hasPlanoAtivo = responsePlanoAtivo && responsePlanoAtivo.sucesso;

    if (hasPlanoContratado || hasPlanoAtivo) {
      setPlanoContratado((prev) => ({
        ...prev,
        ativo: responsePlanoAtivo?.dados || 0,
        total: responsePlanoContratado?.dados || 0,
      }));
    }
  }, [getPlanoAtivo, getPlanoContratado]);

  const getConfiguracaoSenha = useCallback(async () => {
    setIsLoading(true);

    await getValoresPlanos();
    const options = await getLocalEstoqueResponse();

    const localEstoqueCadastrado = await getLocalEstoqueSmartPos();
    let valueLocalEstoque;

    if (localEstoqueCadastrado) {
      valueLocalEstoque = localEstoqueCadastrado;
    } else {
      const localEstoqueDefault = await getLocalEstoquePadrao(options);
      valueLocalEstoque = localEstoqueDefault?.value;
    }
    const senhaAdm = await getSenhaAdm();
    const codigoAtivacaoSmartPos = await getCodigoAtivacaoSmartPos();

    setListaLocalEstoque(options);
    setCodigoAtivacao(codigoAtivacaoSmartPos);

    reset({
      localEstoque: valueLocalEstoque,
      senhaAdm: senhaAdm || '',
    });
    setIsStepAlterada(false);
    setIsLoading(false);
  }, [getValoresPlanos, reset, setIsStepAlterada]);

  const handleAbrirModalAlterarSenha = () => {
    ModalAlterarSenha({ alterarSenhaAdm });
  };

  const handleSalvar = handleSubmit(async (data) => {
    const responseSenhaAdm = await cadastrarSenhaAdm(data.senhaAdm);
    const responseLocalEstoque = await cadastrarLocalEstoque(data.localEstoque);
    if (responseSenhaAdm && responseLocalEstoque) {
      toast.success('Cadastrado com sucesso');
      setIsStepAlterada(false);
      await getConfiguracaoSenha();
    }
  });

  useImperativeHandle(ref, () => ({
    handleSalvar,
    handleCancelar: () => {
      getConfiguracaoSenha();
    },
  }));

  useEffect(() => {
    getConfiguracaoSenha();
  }, [getConfiguracaoSenha]);

  useEffect(() => {
    setIsStepAlterada(formState.isDirty);
  }, [formState, setIsStepAlterada]);

  return (
    <Container h="auto">
      {isLoading && <LoadingPadrao />}
      <FormProvider {...formMethods}>
        <SimpleGridForm>
          <GridItem mb={['20px', '20px', '']} colSpan={[12, 12, 6, 4]}>
            <Text fontSize="14px">Código de ativação</Text>
            <HStack
              alignItems="center"
              h="full"
              gap="8px"
              justifyContent="center"
              bg="gray.50"
              p="16px"
              pl={['5px', '16px', '16px']}
              pr={['5px', '16px', '16px']}
              borderColor="gray.200"
              borderWidth="1px"
              borderStyle="solid"
              borderRadius="5px"
              w="full"
            >
              {(codigoAtivacao || []).map((code) => (
                <VStack
                  alignItems="center"
                  justifyContent="center"
                  bg="white"
                  fontWeight="semibold"
                  borderColor="gray.200"
                  borderWidth="1px"
                  borderStyle="solid"
                  color="gray.900"
                  fontSize={['12px', '14px', '14px']}
                  textAlign="center"
                  boxSize="40px"
                  borderRadius="5px"
                >
                  <Text>{code}</Text>
                </VStack>
              ))}
            </HStack>
          </GridItem>
          <GridItem
            ml={['0', '0', '10px']}
            mt={['20px', '20px', '0']}
            mb={['20px', '20px', '']}
            colSpan={[12, 12, 6, 6]}
          >
            <Text fontSize="14px">Plano contratado</Text>
            <HStack
              alignItems="center"
              h="full"
              gap="8px"
              justifyContent="center"
              bg="gray.50"
              p="16px"
              borderColor="gray.200"
              borderWidth="1px"
              borderStyle="solid"
              borderRadius="5px"
              w="full"
            >
              <Text fontSize="14px">
                Seu plano possui{' '}
                <Text as="span" fontSize="24px" color="purple.500">
                  {planoContratado?.total}
                </Text>{' '}
                licenças para o SmartPOS. No momento estão ativas{' '}
                <Text as="span" fontSize="24px" color="purple.500">
                  {planoContratado?.ativo}/{planoContratado?.total}
                </Text>
                .
              </Text>
            </HStack>
          </GridItem>
          <GridItem
            mt={['20px', '20px', '30px']}
            mb={['20px', '20px', '']}
            colSpan={[12, 12, 4]}
          >
            <Input
              actionLinkText="Alterar senha"
              actionLinkOnClick={() => handleAbrirModalAlterarSenha()}
              name="senhaAdm"
              label="Senha administrativa"
              placeholder="*****"
              colorSchemeLinkText="purple"
              isPassword
              bg="gray.50"
              isDisabled
              inputRightElement
            />
          </GridItem>
          <GridItem
            ml={['0', '0', '10px']}
            mt={['0', '0', '30px']}
            colSpan={[12, 12, 4]}
          >
            <SelectPadrao
              name="localEstoque"
              label="Local de estoque"
              options={listaLocalEstoque}
            />
          </GridItem>
        </SimpleGridForm>
      </FormProvider>
    </Container>
  );
};
