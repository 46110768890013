import React from 'react';
import { Text } from '@chakra-ui/react';

import auth from 'modules/auth';

import HeaderMenuOptions from 'components/PDV/Header/MenuOptions';
import HeaderContainer from 'components/PDV/Header/Container';

const HomeInternalHeader: React.FC = () => {
  const { nome: nomeUsuario } = auth.getUsuario();

  return (
    <HeaderContainer>
      <Text color="secondary.500" fontSize="xl" isTruncated>
        {nomeUsuario ? `Olá, ${nomeUsuario}` : 'Olá'}
      </Text>
      <HeaderMenuOptions />
    </HeaderContainer>
  );
};

export default HomeInternalHeader;
