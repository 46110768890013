import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory, Prompt } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import isPrenvent from 'helpers/layout/isPrenvent';
import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import LojaFormularioProvider from 'store/Lojas/LojasFormulario';
import useReloadRegistration from 'helpers/layout/useReloadRegistration';
import auth from 'modules/auth';
import { getOptionByEnum } from 'helpers/format/getOptionsByEnum';
import TipoCertificadoEnum from 'constants/enum/fiscal/tipoCertificado';
import RegimeTributarioEnum from 'constants/enum/regimeTributario';

import RodapeFormulario from 'components/Geral/RodapeFormulario';
import ManterFoco from 'components/Geral/ManterFoco';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { Card } from 'styles';
import { CertificadoDigitalRetorno } from 'types/loja';
import { useForm, yupResolver } from '../validationForm';
import { Container } from '../styles';

import { UncontrolledForm } from '..';

const Cadastrar = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const isMountedRef = useIsMountedRef();

  const [isLoading, setIsLoading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [bloqueioInicial, setBloqueioInicial] = useState(true);
  const [bloqueioValorCpf, setBloqueioValorCpf] = useState(false);
  const [isSimplesNacional, setisSimplesNacional] = useState(false);
  const [certificadoDigital, setCertificadoDigital] = useState(
    {} as CertificadoDigitalRetorno
  );

  const [NFeReadOnly, setNFeReadOnly] = useState(true);
  const [NFCeReadOnly, setNFCeReadOnly] = useState(true);

  const {
    handleSubmit,
    register,
    control,
    reset,
    getValues,
    setError,
    setValue,
    formState,
    setFocus,
    watch,
  } = useForm({
    resolver: yupResolver,
    defaultValues: {
      regimeTributarioSelect: getOptionByEnum(
        RegimeTributarioEnum,
        RegimeTributarioEnum.SIMPLES_NACIONAL
      ),
      codigosSegurancaContribuinte: [],
      tipoCertificado: TipoCertificadoEnum.A1,
    } as Record<string, any>,
  });

  const historyReload = useReloadRegistration({ setFormIsDirty });
  isPrenvent(formIsDirty);

  async function handlePostLoja() {
    const data = getValues();

    const dataViewModel = {
      ...data,
      lojaBaseId: data?.lojaBase.value,
      arquivo: certificadoDigital.Arquivo64 || null,
      paisId: data.pais?.value,
      telefoneWhatsapp: data.telefoneWhats !== '' ? data.telefoneWhats : false,
      celularWhatsapp: data.celularWhats !== '' ? data.celularWhats : false,
      contabilistaTelefoneWhatsapp:
        data.contabilistaTelefoneWhats !== ''
          ? data.contabilistaTelefoneWhats
          : false,
      contabilistaCelularWhatsapp:
        data.contabilistaCelularWhats !== ''
          ? data.contabilistaCelularWhats
          : false,
      contabilistaCidadeId: data.contabilistaCidade?.value || null,
      contabilistaPaisId: data.contabilistaPais?.value || null,
      regraFiscalPadraoId: data.regraFiscalPadrao?.value || null,
      nFCeExigirCNPJCPF: data.exigirCNPJCPF?.selecionado || false,
      beneficiarioPagamento: data.beneficiarioPagamento?.selecionado || false,
      autorizadoObterXml: data.obterXml === null ? false : data.obterXml,
      enviarDocumentosFiscaisAutomaticamente:
        data.documentosFiscaisAutomaticamente === null
          ? false
          : data.documentosFiscaisAutomaticamente,
      regimeTributario: data.regimeTributarioSelect?.value || null,
      usuarioLogado: auth.getDadosToken().userId || null,
      ieisento: data.ieisento || false,
      cidadeId: data.cidade?.value || null,
      cidadeNome: data.cidade.label || null,
      caminhoCertificadoDigital: certificadoDigital.NomeArquivo
        ? certificadoDigital.NomeArquivo.split('.')[0]
        : null,
      senhaCertificadoDigital: certificadoDigital.SenhaCertificado
        ? certificadoDigital.SenhaCertificado
        : null,
      dataHoraCadastro: null,
      dataHoraUltimaAlteracao: null,
      emitirNFe: data.emitirNFe?.selecionado ?? false,
      emitirNFCe: data.emitirNFCe?.selecionado ?? false,
      // eslint-disable-next-line radix
      tipoCertificado: parseInt(data.tipoCertificado),
    };

    if (
      data.emitirNFe?.selecionado === true ||
      data.emitirNFCe?.selecionado === true
    ) {
      if (
        data.tipoCertificado === TipoCertificadoEnum.A1 &&
        !certificadoDigital.NomeArquivo
      ) {
        toast.warning('Favor informar um certificado digital.');
        setIsLoading(false);
        return false;
      }
    }

    if (data.emitirNFCe?.selecionado === true) {
      if (data.CodigosSegurancaContribuinte.length === 0) {
        toast.warning('Favor informar um código de segurança do contribuinte.');
        setIsLoading(false);
        return false;
      }
    }

    const response = await api.post<void, ResponseApi>(
      ConstanteEnderecoWebservice.LOJA_CADASTRAR,
      dataViewModel
    );

    if (response.sucesso) {
      return true;
    }

    if (response.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    return false;
  }

  const onSubmit = handleSubmit(async () => {
    setIsLoading(true);

    const success = await handlePostLoja();

    if (success) {
      toast.success('O cadastro foi realizado com sucesso.');

      setFormIsDirty(false);

      history.push(ConstanteRotas.MINHAS_LOJAS);
    }

    setIsLoading(false);
  });

  const onSubmitReset = handleSubmit(async () => {
    setIsLoading(true);

    const success = await handlePostLoja();

    if (success) {
      toast.success(t('O cadastro foi realizado com sucesso.'));
      if (isMountedRef.current) setFocus('razaoSocial');

      historyReload(ConstanteRotas.LOJA_CADASTRAR);
    }

    setIsLoading(false);
  });

  useEffect(() => {
    reset(
      {
        descricao: '',
        ativo: true,
        tipoCertificado: TipoCertificadoEnum.A1,
        tipoOperacao: 'cadastrar',
      },
      { keepDefaultValues: true }
    );
  }, [reset]);

  useEffect(() => {
    setFormIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  return (
    <Container>
      <Prompt when={formIsDirty} message="" />
      <Form>
        <ManterFoco blockTab={isLoading}>
          <Card>
            {isLoading && <LoadingPadrao />}
            <LojaFormularioProvider
              bloqueioInicial={bloqueioInicial}
              setBloqueioInicial={setBloqueioInicial}
              bloqueioValorCpf={bloqueioValorCpf}
              setBloqueioValorCpf={setBloqueioValorCpf}
              isSimplesNacional={isSimplesNacional}
              setIsSimplesNacional={setisSimplesNacional}
              certificadoDigital={certificadoDigital}
              setCertificadoDigital={setCertificadoDigital}
              NFeReadOnly={NFeReadOnly}
              NFCeReadOnly={NFCeReadOnly}
              setNFeReadOnly={setNFeReadOnly}
              setNFCeReadOnly={setNFCeReadOnly}
            >
              <UncontrolledForm
                errors={formState.errors}
                setError={setError}
                setValue={setValue}
                getValues={getValues}
                control={control}
                register={register}
                watch={watch}
              />
            </LojaFormularioProvider>
          </Card>
          <RodapeFormulario
            onSubmit={onSubmit}
            onSubmitReset={onSubmitReset}
            disabled={isLoading}
          />
        </ManterFoco>
      </Form>
    </Container>
  );
};

export default Cadastrar;
