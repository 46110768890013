import React, { useState } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  useMediaQuery,
  useDisclosure,
  ModalHeader,
  ModalFooter,
  HStack,
  Button,
  VStack,
  Text,
  GridItem,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import Select from 'components/PDV/Select/SelectPadrao';
import { SimpleCard } from 'components/update/Form/SimpleCard';

import { yupResolver, FormData } from './validationForm';

type FormaPagamentoOption = {
  value: string;
  label: string;
};

type AlterarFormaPagamentoModalResponse = {
  formaPagamentoId: string;
};

type AlterarFormaPagamentoModalProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<AlterarFormaPagamentoModalResponse> & {
    defaultFormaPagamentoId: string;
    formasPagamentoOptions: FormaPagamentoOption[];
    isImportacao?: boolean;
  };

export const AlterarFormaPagamentoModal = create<
  AlterarFormaPagamentoModalProps,
  AlterarFormaPagamentoModalResponse
>(
  ({
    onResolve,
    onReject,
    formasPagamentoOptions,
    defaultFormaPagamentoId,
    isImportacao,
    ...rest
  }) => {
    const formMethods = useForm<FormData>({
      resolver: yupResolver,
      defaultValues: {
        formaPagamentoId: defaultFormaPagamentoId,
      },
    });
    const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = formMethods.handleSubmit(async (data) => {
      setIsLoading(true);

      onResolve(data);
    });

    function handleCancelar() {
      onReject();
    }

    return (
      <ModalPadraoChakra
        isCentered
        size={!isSmallerThan900 ? 'xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalContent
          marginBottom={{ base: 0, md: '3.75rem' }}
          marginTop={{ base: 0, md: '3.75rem' }}
          h="unset"
          maxW={{ base: '100%', md: '800px' }}
        >
          {isLoading && <LoadingPadrao />}
          <ModalHeader
            mt={isSmallerThan900 ? 12 : undefined}
            mb={isSmallerThan900 ? 8 : undefined}
            borderBottom="none"
            px="0"
            mx={{ base: 6, md: 8 }}
          >
            <VStack alignItems="flex-start" spacing="1" lineHeight="1">
              <Text color="primary.500" fontSize={{ base: 'xl', md: 'md' }}>
                Alterar forma de pagamento
              </Text>
              <Text color="gray.400" fontSize={{ base: 'sm', md: 'xs' }}>
                {isImportacao
                  ? 'É necessário informar a forma de pagamento cadastrada no sistema que equivale a informada na nota fiscal.'
                  : 'A forma de pagamento será alterada somente nesta parcela.'}
              </Text>
            </VStack>
          </ModalHeader>
          <ModalBody
            p={{ base: 6, md: 8 }}
            mb={{ base: 6, md: 8 }}
            py="0px !important"
          >
            <FormProvider {...formMethods}>
              <SimpleCard
                bg="gray.50"
                border="1px"
                borderColor="gray.100"
                boxShadow="none"
              >
                <SimpleGridForm>
                  <Select
                    id="formaPagamentoId"
                    name="formaPagamentoId"
                    label="Selecione a forma de pagamento"
                    placeholder="Selecionar"
                    options={formasPagamentoOptions}
                    colSpan={8}
                  />
                </SimpleGridForm>
              </SimpleCard>
            </FormProvider>
          </ModalBody>
          <ModalFooter
            justifyContent="flex-end"
            borderTop="1px"
            borderColor="gray.100"
            mx={{ base: 6, md: 8 }}
            px="0"
          >
            <HStack spacing={6}>
              <Button
                borderRadius="md"
                color="gray.400"
                variant="outline"
                minW="96px"
                onClick={handleCancelar}
              >
                Cancelar
              </Button>
              <Button
                colorScheme="aquamarine"
                borderRadius="md"
                minW="120px"
                onClick={handleSubmit}
              >
                Alterar
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
