import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { OptionTypeBase, OptionsType } from 'react-select';

import api, { ResponseApi } from 'services/api';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import isPrenvent from 'helpers/layout/isPrenvent';
import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import TipoFormaPagamentoRecebimentoEnum from 'constants/enum/tipoFormaPagamentoRecebimento';
import FormaPagamentoFormularioProvider, {
  IconesStorage,
} from 'store/FormaPagamento/FormaPagamentoFormulario';
import MeioPagamentoEnum from 'constants/enum/fiscal/meioPagamento';

import { ContainerListagem } from 'components/Layout/Listagem/containerListagem';

import { useForm, yupResolver } from '../validationForm';

// eslint-disable-next-line import/no-named-as-default
import UncontrolledForm from '..';

type TParams = { id: string };

const Alterar = () => {
  const history = useHistory();
  const isMountedRef = useIsMountedRef();
  isPrenvent();

  const [isLoading, setIsLoading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [iconesOptions, setIconesOptions] = useState<
    OptionsType<OptionTypeBase>
  >([]);
  const [urlPreviewList, setUrlPreviewList] = useState<IconesStorage[]>();

  const [dataHoraCadastro, setDataHoraCadastro] = useState('');
  const [dataHoraUltimaAlteracao, setDataHoraUltimaAlteracao] = useState('');
  const [showVencimento, setShowVencimento] = useState(false);
  const [urlPreview, setUrlPreview] = useState('');

  const formMethods = useForm({
    resolver: yupResolver,
    shouldUnregister: false,
  });
  const { handleSubmit, reset, getValues, formState, setValue } = formMethods;

  const { id: idRota } = useParams<TParams>();

  const handleGetFormaPagamento = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<void, ResponseApi<any>>(
      ConstanteEnderecoWebservice.FORMA_PAGTO_OBTER,
      {
        params: { id: idRota },
      }
    );

    if (response?.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (response?.sucesso && isMountedRef.current) {
      reset({
        ...response.dados,
      });

      if (
        response.dados.regraMeioPagamento ===
          MeioPagamentoEnum.CartaoCreditoLink ||
        response.dados.regraMeioPagamento ===
          MeioPagamentoEnum.CartaoCreditoMaquina
      ) {
        setShowVencimento(true);
        setUrlPreview(response.dados.icone);
        setValue('lancarFatura', response.dados.lancarFatura);
      } else {
        setShowVencimento(false);
      }

      setDataHoraCadastro(response.dados.dataHoraCadastro);
      setDataHoraUltimaAlteracao(response.dados.dataHoraUltimaAlteracao);
    } else {
      history.push(ConstanteRotas.FORMA_PAGTO);
    }

    if (isMountedRef.current) setIsLoading(false);
  }, [history, isMountedRef, idRota, reset, setValue]);

  const filtrandoValorUrl = (urlPreviewList || []).filter(
    (valorFiltrado) => valorFiltrado.url === urlPreview
  );
  const obterValorNomeIcone = filtrandoValorUrl.map(
    (obterNomeIcone) => obterNomeIcone.nome
  );

  const indiceIconeSelecionado = obterValorNomeIcone[0];

  const onSubmit = handleSubmit(async () => {
    setIsLoading(true);

    const data = getValues();

    const response = await api.put<void, ResponseApi>(
      ConstanteEnderecoWebservice.FORMA_PAGTO_ALTERAR,
      {
        ...data,
        tipoFormaPagamentoRecebimento:
          TipoFormaPagamentoRecebimentoEnum.Pagamento,
        Icone: indiceIconeSelecionado || null,
      }
    );

    if (response.sucesso) {
      toast.success('O cadastro foi alterado com sucesso.');

      setFormIsDirty(false);

      history.push(ConstanteRotas.FORMA_PAGTO);

      return;
    }

    if (response.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (isMountedRef.current) setIsLoading(false);
  });

  const salvarIcones = handleSubmit(async () => {
    const data = getValues();

    const response = await api.put<void, ResponseApi>(
      ConstanteEnderecoWebservice.FORMA_PAGTO_ALTERAR,
      {
        ...data,
        Icone:
          urlPreviewList && data.codigoBanco
            ? urlPreviewList.filter((url) => url.nome === data.codigoBanco)[0]
                .url
            : null,
      }
    );

    if (response.sucesso) {
      toast.success('O ícone foi alterado com sucesso');
      setFormIsDirty(false);
      return;
    }

    if (response.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }
  });

  useEffect(() => {
    setIsLoading(true);
    handleGetFormaPagamento();
  }, [handleGetFormaPagamento]);

  useEffect(() => {
    setFormIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  return (
    <ContainerListagem
      maxWidth="full"
      formIsDirty={formIsDirty}
      formMethods={formMethods}
      isLoading={isLoading}
      onSubmit={onSubmit}
      dataHoraUltimaAlteracao={dataHoraUltimaAlteracao}
      dataHoraCadastro={dataHoraCadastro}
    >
      <FormaPagamentoFormularioProvider
        urlPreview={urlPreview}
        setUrlPreview={setUrlPreview}
        showVencimento={showVencimento}
        setShowVencimento={setShowVencimento}
        iconesOptions={iconesOptions}
        setIconesOptions={setIconesOptions}
        urlPreviewList={urlPreviewList}
        setUrlPreviewList={setUrlPreviewList}
      >
        <UncontrolledForm onSubmit={salvarIcones} />
      </FormaPagamentoFormularioProvider>
    </ContainerListagem>
  );
};

export default Alterar;
