import { useCallback, useState, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  Table,
  Text,
  Thead,
  Tr,
  Td,
  Th,
  Tbody,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { enumIdentificacaoIntegracao } from 'constants/enum/enumIdentificacaoIntegracao';
import api, { ResponseApi } from 'services/api';
import { useTrayPainelControleContext } from 'store/Tray/PainelControle';

import Input from 'components/PDV/Input';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import InputCpfCnpj from 'components/PDV/InputCpfCnpj';
import { formattedValueCpfCnpj } from 'helpers/format/formattedValueCpfCnpj';

type FormData = {
  identificacaoSistema: string[];
  cnpj: string[];
};

type IntermediadorProps = {
  id: string;
  descricao: string;
  cnpj: string;
};

export const Intermediador = () => {
  const [listIntermediador, setListIntermediador] = useState<
    IntermediadorProps[]
  >([]);

  const formMethods = useForm<FormData>();

  const { dadosTray, isLoading, setIsLoading } = useTrayPainelControleContext();

  const { watch, setValue } = formMethods;

  const [identificacaoSistemaWatch, cnpjWatch] = watch([
    'identificacaoSistema',
    'cnpj',
  ]);

  const getListIntermediador = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get<void, ResponseApi<IntermediadorProps[]>>(
      ConstanteEnderecoWebservice.INTEGRACAO_TRAY_ORIGEM,
      {
        params: {
          integracao: enumIdentificacaoIntegracao.TRAY,
        },
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
        setIsLoading(false);
      }

      if (response.sucesso && response.dados) {
        setListIntermediador(response.dados);

        response.dados.forEach((itemIntermediador, index) => {
          setValue(
            `identificacaoSistema.${index}`,
            itemIntermediador.descricao
          );
          setValue(
            `cnpj.${index}`,
            formattedValueCpfCnpj(itemIntermediador?.cnpj || '', false, true)
          );
        });

        setIsLoading(false);
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [setIsLoading, setValue]);

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    const data = listIntermediador.map((itemIntermediador, index) => ({
      id: itemIntermediador.id,
      descricao: identificacaoSistemaWatch[index],
      cnpj: cnpjWatch[index],
      integracaoId: dadosTray.id,
    }));

    const response = await api.put<void, ResponseApi>(
      ConstanteEnderecoWebservice.INTEGRACAO_ORIGEM_PEDIDO_ALTERAR,
      data
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso) {
        toast.success('Intermediador vinculado com sucesso');
        await getListIntermediador();
        setIsLoading(false);
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [
    cnpjWatch,
    dadosTray.id,
    getListIntermediador,
    identificacaoSistemaWatch,
    listIntermediador,
    setIsLoading,
  ]);

  const hasDadosPreenchidos = useCallback(() => {
    if (
      identificacaoSistemaWatch === undefined ||
      (identificacaoSistemaWatch || []).length === 0
    ) {
      return false;
    }
    const newListDados = [...identificacaoSistemaWatch, ...cnpjWatch];

    return newListDados?.some(
      (item) => item === null || item === undefined || item === ''
    );
  }, [cnpjWatch, identificacaoSistemaWatch])();

  useEffect(() => {
    getListIntermediador();
  }, [getListIntermediador]);

  return (
    <FormProvider {...formMethods}>
      {isLoading && <LoadingPadrao />}
      <Box
        pt={['24px', '24px', '40px']}
        px={['0', '0', '20px']}
        w="full"
        minW="400px"
      >
        <Text>Cadastro de Intermediadores para complemento fiscal</Text>
        <Box
          borderWidth="1px"
          borderRadius="5px"
          borderColor="gray.200"
          bg="gray.50"
        >
          <Box
            p="24px"
            mr="5px"
            borderRadius="5px"
            maxH="320px"
            bg="gray.50"
            overflowY="auto"
          >
            <Table variant="simple" size="sm">
              <Thead>
                <Tr
                  sx={{
                    '& th': {
                      fontSize: '12px',
                      color: 'gray.500',
                      paddingBottom: '2px',
                    },
                  }}
                >
                  <Th pl="0">Origem da venda</Th>
                  <Th>Identificação no sistema:</Th>
                  <Th>CNPJ:</Th>
                </Tr>
              </Thead>
              <Tbody>
                {listIntermediador.map((situacaoItem, index) => {
                  return (
                    <Tr
                      sx={{
                        '& td': {
                          fontSize: '14px',
                        },
                      }}
                    >
                      <Td color="black">
                        <li>
                          <Text ml="-6px" display="inline">
                            {situacaoItem.descricao}
                          </Text>
                        </li>
                      </Td>
                      <Td w="33%">
                        <Input
                          name={`identificacaoSistema.${index}`}
                          placeholder="Informe o intermediador"
                          id={`identificacaoSistema.${index}`}
                        />
                      </Td>
                      <Td w="33%" isNumeric>
                        <Flex justifyContent="flex-end">
                          <Box w="full">
                            <InputCpfCnpj
                              name={`cnpj.${index}`}
                              placeholder="00.000.000-0000/00"
                              id={`cnpj.${index}`}
                            />
                          </Box>
                        </Flex>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
        </Box>

        <Flex mt="30px" w="full" justifyContent="center" alignItems="baseline">
          <Button
            variant="outlineDefault"
            borderRadius="16px"
            colorScheme="gray"
            mr="24px"
            w="96px"
          >
            Cancelar
          </Button>
          <Button
            color="black"
            variant="solid"
            onClick={handleSubmit}
            isDisabled={hasDadosPreenchidos}
            colorScheme="secondary.400"
            borderRadius="16px"
            width="120px"
          >
            Confirmar
          </Button>
        </Flex>
      </Box>
    </FormProvider>
  );
};
