export const hideEmail = (email: string) => {
  return email.replace(/(.{2})(.*)(?=@)/, (gp1, gp2, gp3) => {
    for (let i = 0; i < gp3.length; i += 1) {
      gp2 += '*'; // eslint-disable-line no-param-reassign
    }

    return gp2;
  });
};

export const formatEmailsToSend = (emails: string[]) => {
  let urlEmails = '';
  emails.forEach((email) => {
    urlEmails += `&emails=${email}`;
  });
  return urlEmails;
};
