import React, { useEffect } from 'react';

import { useNavMenuContext } from 'store/NavMenu';

interface ClickForaMenuProps {
  children: React.ReactNode;
}

export const ClickForaMenu = ({ children }: ClickForaMenuProps) => {
  const { setOpenMenu } = useNavMenuContext();

  useEffect(() => {
    function clickListener(e: MouseEvent) {
      const navMenuContainer = document.getElementById(
        'sti3-navmenu-container'
      );
      const navSubmenuContainer = document.getElementById(
        'sti3-navsubmenu-container'
      );

      if (
        e.target &&
        navMenuContainer &&
        !navMenuContainer.contains(e.target as HTMLElement) &&
        navSubmenuContainer &&
        !navSubmenuContainer.contains(e.target as HTMLElement)
      ) {
        setOpenMenu('');
      }
    }

    document.addEventListener('click', clickListener, true);

    return () => {
      document.removeEventListener('click', clickListener, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <span
      style={{
        overflow: 'hidden',
      }}
    >
      {children}
    </span>
  );
};

export default ClickForaMenu;
