import React, { useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  GridItem,
  Td,
  Tr,
  Box,
  Flex,
  Link,
  useMediaQuery,
} from '@chakra-ui/react';

import auth from 'modules/auth';
import api, { ResponseApi } from 'services/api';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import ConstanteFuncionalidades from 'constants/permissoes';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { shallowEqual } from 'helpers/validation/shallowEqual';

import {
  PagedTable,
  PagedTableForwardRefData,
} from 'components/update/Table/PagedTable';
import { SearchInput } from 'components/update/Input/SearchInput';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { ModalConfirmacaoInativar } from 'components/Modal/ModalConfirmacaoInativar';
import { StatusCircle } from 'components/update/Table/StatusCircle';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import { FilterSelect } from 'components/update/Select/FilterSelect';
import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import { PaginationData } from 'components/update/Pagination';
import { ButtonCadastrarNovo } from 'components/Layout/ButtonCadastrarNovo';

interface Cor {
  id: string;
  descricao: string;
  ativo: boolean;
  padraoSistema: boolean;
}

const formDefaultValues = {
  ativo: true,
  descricao: '',
};

const Listar = () => {
  const history = useHistory();
  const isMountedRef = useIsMountedRef();

  const formMethods = useForm({
    defaultValues: formDefaultValues,
  });
  const { setFocus } = formMethods;

  const [isLoading, setIsLoading] = useState(false);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [cores, setCores] = useState<Cor[]>([]);
  const [currentFilters, setCurrentFilters] = useState(formDefaultValues);

  const pageIsLoaded = useRef(false);
  const pagedTableRef = useRef<PagedTableForwardRefData>(null);
  const [isLargerThan900] = useMediaQuery('(max-width: 900px)');

  const permissaoAlterarCores = auth.possuiPermissao(
    ConstanteFuncionalidades.COR_ALTERAR
  );
  const permissaoVisualizarCores = auth.possuiPermissao(
    ConstanteFuncionalidades.COR_VISUALIZAR
  );

  const handleHistoryPush = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history]
  );

  const handleRefresh = useCallback(() => {
    return pagedTableRef.current?.reload();
  }, []);

  const filtersSubmit = formMethods.handleSubmit((data) => {
    const filtersIsDirty = !shallowEqual(data, currentFilters || {});

    if (filtersIsDirty) {
      setCurrentFilters(data);
    }
  });

  const paginationHandle = useCallback(
    async (gridPaginadaConsulta: PaginationData) => {
      setIsLoading(true);

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<Cor>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.COR_LISTAR_PAGINADO,
          gridPaginadaConsulta
        ),
        {
          params: {
            descricao: currentFilters.descricao,
            ativo: currentFilters.ativo,
          },
        }
      );

      if (response?.sucesso && isMountedRef.current) {
        setTotalRegistros(response.dados.total);
        setCores(response.dados.registros);
      }

      if (isMountedRef.current) {
        setIsLoading(false);

        if (!pageIsLoaded.current) {
          pageIsLoaded.current = true;

          setFocus('descricao');
        }
      }
    },
    [isMountedRef, setFocus, currentFilters]
  );

  const getPossuiPermissaoVisualizarAlterar = useCallback(
    (id: string) => {
      let href = '';

      if (permissaoAlterarCores.permitido) {
        href = SubstituirParametroRota(ConstanteRotas.COR_ALTERAR, 'id', id);
      } else if (permissaoVisualizarCores.permitido) {
        href = SubstituirParametroRota(ConstanteRotas.COR_VISUALIZAR, 'id', id);
      }

      return href;
    },
    [permissaoAlterarCores.permitido, permissaoVisualizarCores.permitido]
  );

  const excluirHandle = useCallback(
    async (corId: string, ativo: boolean) => {
      ModalConfirmacaoExcluir({
        callback: async (ok: boolean) => {
          if (ok) {
            setIsLoading(true);

            const response = await api.delete<void, ResponseApi>(
              ConstanteEnderecoWebservice.COR_EXCLUIR,
              {
                params: { id: corId },
              }
            );

            if (response?.sucesso) {
              toast.success('O cadastro foi removido com sucesso.');

              handleRefresh();
            }

            if (response) {
              ModalConfirmacaoInativar({
                response,
                rotaWebService: ConstanteEnderecoWebservice.COR_INATIVAR,
                id: corId,
                ativo,
                callback: (okInativar: boolean) => {
                  if (okInativar) handleRefresh();
                },
              });
            }

            setIsLoading(false);
          }
        },
      });
    },
    [handleRefresh]
  );

  return (
    <Box>
      <FormProvider {...formMethods}>
        <Flex direction={isLargerThan900 ? 'column' : 'row'} w="full">
          <Box
            w={isLargerThan900 ? 'full' : '50%'}
            mr={isLargerThan900 ? undefined : '40px'}
          >
            <SearchInput
              type="search"
              placeholder="Buscar cor por descrição"
              onEnterKeyPress={() => filtersSubmit()}
              isDisabled={isLoading}
              id="descricao"
              name="descricao"
            />
          </Box>
          <Box w={isLargerThan900 ? 'full' : '200px'}>
            <FilterSelect
              id="ativo"
              name="ativo"
              options={[
                {
                  value: true,
                  label: 'Ativos',
                },
                {
                  value: false,
                  label: 'Inativos',
                },
                {
                  value: null,
                  label: 'Todos',
                },
              ]}
              onSelect={() => {
                filtersSubmit();
              }}
              isDisabled={isLoading}
            />
          </Box>
          <Flex
            justifyContent="flex-end"
            alignItems="center"
            w={isLargerThan900 ? 'full' : 'calc((100% - 50%) - 200px)'}
          >
            <ButtonCadastrarNovo
              onClick={() => handleHistoryPush(ConstanteRotas.COR_CADASTRAR)}
              funcionalidade={ConstanteFuncionalidades.COR_CADASTRAR}
            />
          </Flex>
        </Flex>

        <GridItem mt="25px" colSpan={12}>
          <PagedTable
            ref={pagedTableRef}
            defaultKeyOrdered="Descricao"
            itemsTotalCount={totalRegistros}
            loadColumnsData={paginationHandle}
            isLoading={isLoading}
            tableHeaders={[
              {
                content: <StatusCircle hasValue={false} />,
                key: 'Ativo',
                isOrderable: true,
                width: '1px',
              },
              {
                content: 'Descrição',
                key: 'Descricao',
                isOrderable: true,
              },
              {
                content: 'Ações',
                key: 'Acoes',
                isOrderable: false,
                width: '1px',
              },
            ]}
            renderTableRows={cores.map((cor) => (
              <Tr key={cor.id}>
                <Td>
                  <StatusCircle isActive={cor.ativo} />
                </Td>
                <Td>
                  <Link
                    id="link-visualizar"
                    href={getPossuiPermissaoVisualizarAlterar(cor.id)}
                  >
                    {cor.descricao}
                  </Link>
                </Td>
                <Td>
                  {!cor.padraoSistema && (
                    <ActionsMenu
                      id="mostrarMais"
                      items={[
                        {
                          content: 'Editar',
                          onClick: () => {
                            handleHistoryPush(
                              SubstituirParametroRota(
                                ConstanteRotas.COR_ALTERAR,
                                'id',
                                cor.id
                              )
                            );
                          },
                          funcionalidade: ConstanteFuncionalidades.COR_ALTERAR,
                        },
                        {
                          content: 'Remover',
                          onClick: () => {
                            excluirHandle(cor.id, cor.ativo);
                          },
                          funcionalidade: ConstanteFuncionalidades.COR_EXCLUIR,
                        },
                      ]}
                    />
                  )}
                </Td>
              </Tr>
            ))}
          />
        </GridItem>
      </FormProvider>
    </Box>
  );
};

export default Listar;
