import { toast } from 'react-toastify';
import { useFormContext } from 'react-hook-form';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import AsyncSelect from 'components/PDV/Select/AsyncSelectPadrao';
import OptionType, { OptionProps } from 'types/optionType';

import { SelectFieldProps } from '../SelectCidade/index';

export interface ListaCidadesInterface {
  id: number;
  nome: string;
  codigoIBGE: string;
  estadoNome: string;
  estadoSigla: string;
  paisId?: number;
}

export type PaisInterface = {
  nome: string;
  id: string;
};
export interface ListaValoresSelect {
  label: string;
  value: number;
}

type SelectPaisProps = SelectFieldProps & {
  getSelectedPais?: (paisId?: number) => void;
};

const SelectPais = ({
  name,
  label,
  getSelectedCidade,
  id,
  onSelect,
  isRequired,
  getSelectedPais,
  asControlledByObject = true,
  ...rest
}: SelectPaisProps) => {
  const {
    formState: { errors },
  } = useFormContext();

  const errorText = errors[name]?.message;

  const getOptionSelected = (event: OptionType) => {
    const newEvent = event as OptionProps;
    if (getSelectedPais) {
      getSelectedPais(newEvent?.value as number);
    }
  };

  const handleGetOptions = async (newInputValue: string) => {
    const response = await api.get<void, ResponseApi<ListaCidadesInterface[]>>(
      ConstanteEnderecoWebservice.PAIS_LISTAR_CACHE,
      {
        params: { nome: newInputValue },
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso) {
        return response?.dados.map((item: ListaCidadesInterface) => {
          return {
            label: item.nome,
            value: item.id,
          };
        });
      }
    }

    return [];
  };

  return (
    <AsyncSelect
      {...rest}
      id={id}
      name={name}
      errorText={errorText}
      asControlledByObject={asControlledByObject}
      label={label}
      onOptionSelect={getOptionSelected}
      handleGetOptions={handleGetOptions}
    />
  );
};

export default SelectPais;
