import React from 'react';
import {
  ModalProps,
  useMediaQuery,
  Text,
  ModalContent,
  useDisclosure,
  Box,
  Button,
  ModalFooter,
  ModalCloseButton,
  ModalBody,
  Flex,
} from '@chakra-ui/react';
import { InstanceProps, create } from 'react-modal-promise';

import formatDate from 'helpers/format/formatUTCToLocateDateTime';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';

import { LogAuditoria } from '../../Types/validationForms';

type VisualizarDetalhesModalProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<null> & {
    informacaoDetalhe?: LogAuditoria;
  };

export const VisualizarDetalhesModal = create<VisualizarDetalhesModalProps>(
  ({ onResolve, onReject, informacaoDetalhe, ...rest }) => {
    const { onClose, isOpen } = useDisclosure({ defaultIsOpen: true });
    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    return (
      <ModalPadraoChakra
        size="3xl"
        {...rest}
        isOpen={isOpen}
        isCentered={isLargerThan900}
        onClose={onClose}
      >
        <ModalContent
          bg="gray.50"
          marginBottom={{ base: 0, sm: '3.75rem' }}
          marginTop={{ base: 0, sm: '3.75rem' }}
          maxW="1000px"
          w={isLargerThan900 ? '1000px' : 'full'}
          h={isLargerThan900 ? '350px' : 'full'}
        >
          <ModalCloseButton
            fontSize="2xs"
            border="none"
            _hover={{ bg: 'none' }}
            _focus={{ border: 'none' }}
          />
          <ModalBody pl="30px" pt="30px">
            <Flex>
              <Text mr="5px" fontWeight="bold" color="primary.50">
                Tela:{' '}
              </Text>
              <Text>
                {informacaoDetalhe && informacaoDetalhe.descricaoTela}
              </Text>
            </Flex>

            <Flex mt="10px">
              <Text mr="5px" fontWeight="bold" color="primary.50">
                Usuário:{' '}
              </Text>
              <Text>{informacaoDetalhe && informacaoDetalhe.usuario}</Text>
            </Flex>

            <Flex mt="10px">
              <Text mr="5px" fontWeight="bold" color="primary.50">
                Data:{' '}
              </Text>
              <Text>
                {informacaoDetalhe &&
                  formatDate(informacaoDetalhe.dataHoraFormatada).substring(
                    0,
                    10
                  )}
              </Text>
            </Flex>

            <Flex mt="10px">
              <Text mr="5px" fontWeight="bold" color="primary.50">
                Ação:{' '}
              </Text>
              <Text>
                {informacaoDetalhe && informacaoDetalhe.descricaoOperacao}
              </Text>
            </Flex>

            {informacaoDetalhe && (
              <Box
                borderRadius="md"
                p="30px"
                mt="30px"
                boxShadow="0px 0px 6px #00000034"
              >
                <Text>{informacaoDetalhe.descricao}</Text>
              </Box>
            )}
          </ModalBody>
          <ModalFooter>
            <Flex w="full" justifyContent="center" alignItems="center">
              <Button
                borderRadius="md"
                name="fechar"
                variant="outline"
                _hover={{
                  background: 'none',
                }}
                w="150px"
                h="35px"
                onClick={() => onClose()}
              >
                Fechar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
