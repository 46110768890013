import React, {
  forwardRef,
  useState,
  useCallback,
  useImperativeHandle,
  useEffect,
  useRef,
} from 'react';
import { Form, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { OptionsType, OptionTypeBase } from 'react-select';
import { FieldErrors, UseFormRegister, UseFormSetError } from 'react-hook-form';
import { FiAlertCircle } from 'react-icons/fi';
import { Icon, Tag, Text, useToken } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import api, { ResponseApi } from 'services/api';
import { CnpjResponse } from 'services/receitaws';
import GenerosEnum from 'constants/enum/generos';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import CampoPersonalizadoInterface from 'types/campoPersonalizado';
import { cepMask } from 'helpers/format/fieldsMasks';
import consultarViaCep, { CepResponse } from 'services/viacep';
import RegraLimiteCreditoEnum from 'constants/enum/regraLimiteCredito';
import { handleGetCidade } from 'helpers/data/getCidadeObterCache';
import TipoCadastroPessoaEnum from 'constants/enum/tipoCadastroPessoa';
import handleGetCidadeLojaLogada from 'helpers/api/getCidadeLojaLogada';

import InputPadrao from 'components/Input/InputPadrao';
import InputTelefoneWhatsapp from 'components/Input/InputTelefoneWhatsapp';
import CheckBoxAtivoInativo from 'components/CheckBox/CheckBoxAtivoInativo';
import InputNull from 'components/Input/InputNull';
import SelectPadrao from 'components/Select/SelectPadrao';
import ImagePickerPadrao from 'components/ImagePicker/ImagePickerPadrao';
import InputCpfCnpj from 'components/Input/InputCpfCnpj';
import { SelectCidadeRefInterface } from 'components/Select/SelectCidade';
import { PaisInterface } from 'components/Select/SelectPais';
import TextAreaPadrao from 'components/TextArea/TextAreaPadrao';
import { OptionsInterface } from 'components/Select/SelectCreate';
import InputRgIeIsento from 'components/Input/InputRgIeIsento';

import OutrasInformacoes from './OutrasInformacoes';
import ContatosAdicionais from './ContatosAdicionais';
import Enderecos from './Enderecos';
import CamposPersonalizados from './CamposPersonalizados';

interface UncontrolledFormInterface {
  errors: FieldErrors;
  register: UseFormRegister<Record<string, any>>;
  control: any;
  readonly?: boolean;
  setError: UseFormSetError<Record<string, any>>;
  setValue: any;
  getValue: any;
  camposPersonalizados: CampoPersonalizadoInterface[];
  rotaCliente?: boolean;
  cadastroPdv?: boolean;
  isAlteracao?: boolean;
  watch: any;
  isCadastrar?: boolean;
}

export interface UncontrolledFormRefInterface {
  setCidade: (cidadeId?: number, codigoIBGE?: string) => Promise<void>;
  setPais: (paisId: number) => Promise<void>;
  setCpfOrCnpj: (isCpfOrCnpj: boolean) => Promise<void>;
  firstInputFocus: () => void;
}

interface CategoriaClienteInterface {
  id: string;
  nome: string;
}

const UncontrolledForm = forwardRef(
  (
    {
      errors,
      register,
      control,
      setError,
      readonly,
      setValue,
      getValue,
      camposPersonalizados,
      rotaCliente,
      cadastroPdv,
      isAlteracao,
      isCadastrar,
      watch,
    }: UncontrolledFormInterface,
    ref
  ) => {
    const { t } = useTranslation();
    const [isCpf, setIsCpf] = useState(true);
    const selectCidadeRef = useRef<SelectCidadeRefInterface>();
    const [categoriaClientes, setCategoriaClientes] = useState<
      OptionsType<OptionTypeBase>
    >([]);

    const [isInformarIE, setIsInformarIE] = useState(false);
    const [isCpfCnpjCadastrado, setIsCpfCnpjCadastrado] = useState(false);
    const [emailWatch, possuiInscricaoEstadualWatch, cpfCnpjWatch] = watch([
      'email',
      'possuiInscricaoEstadual',
      'cpfCnpj',
    ]);

    const isValueRg =
      (watch('rgInscricaoEstadual') || '')?.replace(/[.-]/g, '')?.length < 10;

    const yellow500 = useToken('colors', 'yellow.500');

    const idRouter = useParams<{ id: string }>();

    const handleGetCategoriaClientesValues = useCallback(async () => {
      const currentCategoriaCliente = getValue('categoriaCliente');

      const response = await api.get<
        void,
        ResponseApi<CategoriaClienteInterface[]>
      >(ConstanteEnderecoWebservice.CATEGORIA_CLIENTE_SELECT, {
        params: {
          cadastroPdv,
          cadastrando: !isAlteracao,
        },
      });

      if (response?.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }

      let categoriaClienteSelect: any;

      if (response?.sucesso) {
        setCategoriaClientes(
          response.dados.map((categoriaCliente: any) => {
            if (
              !categoriaClienteSelect &&
              categoriaCliente.regralimite ===
                RegraLimiteCreditoEnum.BLOQUEIO_DIAS_ATRASO
            ) {
              categoriaClienteSelect = {
                value: categoriaCliente.id,
                label: categoriaCliente.nome,
              };
            }

            return {
              label: categoriaCliente.nome,
              value: categoriaCliente.id,
            } as OptionsInterface;
          })
        );

        if (categoriaClienteSelect && !currentCategoriaCliente) {
          setValue('categoriaCliente', categoriaClienteSelect);
        }
      }
    }, [getValue, cadastroPdv, setValue, isAlteracao]);

    const handleGetPais = useCallback(async (paisId: number) => {
      const response = await api.get<void, ResponseApi<PaisInterface>>(
        ConstanteEnderecoWebservice.PAIS_OBTER_CACHE,
        { params: { id: paisId } }
      );

      if (response?.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }

      if (response?.sucesso) {
        return response?.dados;
      }

      return {} as PaisInterface;
    }, []);

    const obterCnpjCpfCadastrado = useCallback(
      async (value?: string) => {
        const response = await api.get<void, ResponseApi<boolean>>(
          ConstanteEnderecoWebservice.CLIENTE_FORNECEDOR_VALIDAR_CPF_CNPJ,
          {
            params: {
              cpfCnpj: value || cpfCnpjWatch,
              tipoCadastroPessoa: rotaCliente
                ? TipoCadastroPessoaEnum.CLIENTE
                : TipoCadastroPessoaEnum.FORNECEDOR,
              clienteFornecedorId: idRouter?.id || undefined,
            },
          }
        );

        if (response) {
          if (response.avisos) {
            response.avisos.forEach((item: string) => toast.warning(item));
          }

          if (response.sucesso) {
            setIsCpfCnpjCadastrado(!response.dados);
          }
        }
      },
      [cpfCnpjWatch, idRouter, rotaCliente]
    );

    const setPais = useCallback(
      async (paisId: number) => {
        const pais = paisId ? await handleGetPais(paisId) : undefined;

        if (pais)
          setValue('pais', {
            label: pais.nome,
            value: pais.id,
          });

        if (selectCidadeRef.current)
          selectCidadeRef.current.handleSetDefaultOptions();
      },
      [handleGetPais, setValue]
    );

    const setCpfOrCnpj = useCallback(
      async (isCpfOrCnpj: boolean) => {
        setIsCpf(isCpfOrCnpj);
        if (!isCpfOrCnpj) {
          setValue('genero', 3);
        }
      },
      [setIsCpf, setValue]
    );

    const setCidade = useCallback(
      async (cidadeId?: number, codigoIBGE?: string, nomeCidade?: string) => {
        const cidade =
          cidadeId || codigoIBGE
            ? await handleGetCidade(cidadeId, codigoIBGE, nomeCidade)
            : undefined;

        if (cidade) {
          setValue('cidade', {
            label: `${cidade.nome} - ${cidade.estadoSigla}`,
            value: cidade.id,
            paisId: cidade.paisId,
          });
          setValue('estado', cidade.estadoNome);

          if (cidade.paisId) setPais(cidade.paisId);
        } else {
          setValue('cidade', null);
          setValue('estado', null);
        }
      },
      [setValue, setPais]
    );

    const getCepData = useCallback(
      (data: CepResponse) => {
        if (data.bairro) setValue('bairro', data.bairro);

        if (data.complemento) setValue('complemento', data.complemento);

        if (data.ibge) {
          setCidade(undefined, data.ibge.toString(), data.localidade);
        }

        if (data.logradouro) setValue('logradouro', data.logradouro);
      },
      [setCidade, setValue]
    );

    const getData = useCallback(
      (sucesso: boolean, dados: any) => {
        if (!sucesso) setError('cep', { message: dados });
        else {
          setError('cep', { message: '' });
          getCepData(dados);
        }
      },
      [getCepData, setError]
    );

    const getCnpjData = useCallback(
      (data: CnpjResponse) => {
        if (data.nome) setValue('nome', data.nome);
        else setValue('nome', null);

        if (data.fantasia) setValue('apelido', data.fantasia);
        else setValue('apelido', null);

        if (data.bairro) setValue('bairro', data.bairro);

        if (data.cep) setValue('cep', cepMask(data.cep));

        if (data.complemento) setValue('complemento', data.complemento);
        else setValue('complemento', null);

        if (data.email) setValue('email', data.email);

        if (data.logradouro) setValue('logradouro', data.logradouro);

        if (data.municipio) {
          consultarViaCep(data.cep, getData);
        }

        if (data.numero) setValue('numero', data.numero);

        if (data.telefone) setValue('telefone', data.telefone);
        else setValue('telefone', null);
      },
      [getData, setValue]
    );

    const getCidadeLojaLogada = useCallback(async () => {
      const cidadeLoja = await handleGetCidadeLojaLogada();

      setCidade(cidadeLoja?.id, cidadeLoja?.codigoIbge);
    }, [setCidade]);

    useImperativeHandle(ref, () => ({
      setCpfOrCnpj,
      setCidade,
      setPais,
    }));

    useEffect(() => {
      setCpfOrCnpj(isCpf);
      handleGetCategoriaClientesValues();
    }, [handleGetCategoriaClientesValues, setCpfOrCnpj, isCpf]);

    useEffect(() => {
      if (isCadastrar) {
        getCidadeLojaLogada();
      }
    }, [getCidadeLojaLogada, isCadastrar]);

    useEffect(() => {
      setValue('possuiInscricao', isInformarIE);
    }, [isInformarIE, setValue]);

    useEffect(() => {
      if (isAlteracao) {
        setIsInformarIE(possuiInscricaoEstadualWatch);
      }
    }, [isAlteracao, possuiInscricaoEstadualWatch]);

    return (
      <>
        <Form.Row>
          <Col xl={2} lg={3} xs={12}>
            <ImagePickerPadrao
              id="foto"
              name="foto"
              label={t('Adicionar foto')}
              w={['100%', '100%', '100%', '100%', '100%']}
              h={['115px', '116px', '123px', '220px', '240px']}
              error={errors.foto}
              readonly={readonly}
            />
          </Col>
          <Col xs={0}>
            <Form.Row>
              <Col
                style={{
                  position: 'relative',
                }}
                xl={7}
                lg={5}
                md={6}
                sm={12}
                xs={12}
              >
                <InputCpfCnpj
                  id="cpfCnpj"
                  name="cpfCnpj"
                  label={t('CPF / CNPJ')}
                  placeholder={t('Digite o CPF ou o CNPJ')}
                  control={control}
                  error={errors.cpfCnpj}
                  setError={setError}
                  onChangeCnpj={(e) => obterCnpjCpfCadastrado(e)}
                  onBlur={() => obterCnpjCpfCadastrado()}
                  maskTypeChange={(isCpfResponse: boolean) => {
                    setIsCpf(isCpfResponse);
                  }}
                  getCnpjData={getCnpjData}
                  disabled={readonly}
                  style={{
                    borderColor: isCpfCnpjCadastrado ? yellow500 : '',
                  }}
                  deveOuvirAlteracoesFormulario={cadastroPdv && !isAlteracao}
                />
                {isCpfCnpjCadastrado && (
                  <Tag
                    position="absolute"
                    w="320px"
                    mt="3px"
                    pt="5px"
                    pb="5px"
                    pl="10px"
                    bg="yellow.500"
                  >
                    <Icon as={FiAlertCircle} />
                    <Text
                      pl="5px"
                      fontSize="12px"
                      whiteSpace="nowrap"
                    >{`Existe um cliente cadastrado com o mesmo ${
                      isCpf ? 'CPF' : 'CNPJ'
                    }`}</Text>
                  </Tag>
                )}
              </Col>
              <Col xl={5} lg={7} md={6} sm={12} xs={12}>
                <InputPadrao
                  type="text"
                  id="nome"
                  name="nome"
                  label={isCpf ? t('Nome') : t('Razão social')}
                  required
                  placeholder={
                    isCpf
                      ? t('Digite o nome completo')
                      : t('Digite a razão social do cliente')
                  }
                  maxLength={60}
                  error={errors.nome}
                  disabled={readonly}
                  control={control}
                />
              </Col>
            </Form.Row>
            <Form.Row>
              <Col xl={6} lg={8} md={8} sm={6} xs={12}>
                <InputNull
                  type="text"
                  id="apelido"
                  name="apelido"
                  label={isCpf ? t('Apelido') : t('Nome fantasia')}
                  placeholder={
                    isCpf ? t('Digite o apelido') : t('Digite o nome fantasia')
                  }
                  maxLength={60}
                  control={control}
                  error={errors.apelido}
                  disabled={readonly}
                />
              </Col>
              <Col xl={6} lg={4} md={4} sm={6} xs={12}>
                <SelectPadrao
                  id="genero"
                  name="genero"
                  label={t('Gênero')}
                  noSelectedText={
                    isCpf
                      ? t('Clique aqui para selecionar.')
                      : t('Não Informado')
                  }
                  placeholder={
                    isCpf
                      ? t('Clique aqui para selecionar.')
                      : t('Não Informado')
                  }
                  defaultValue={3}
                  isDisabled={!isCpf}
                  control={control}
                  readonly={readonly}
                  error={errors.genero}
                  required
                  options={Object.entries(GenerosEnum.properties).map(
                    (value: any) => {
                      if (isCpf) {
                        return {
                          label: t(value[1].name),
                          value: value[1].value,
                        };
                      }
                      return {
                        label: t('Não informado'),
                        value: 3,
                      };
                    }
                  )}
                />
              </Col>
            </Form.Row>
            <Form.Row>
              <Col xl={3} lg={4} md={4} sm={6} xs={12}>
                <InputPadrao
                  id="codigo"
                  label={t('Código')}
                  maxLength={60}
                  error={errors.codigo}
                  control={control}
                  disabled
                />
              </Col>
              <Col xl={3} lg={4} md={4} sm={6} xs={12}>
                <InputNull
                  type="date"
                  dateLimiter
                  id="dataNascimento"
                  name="dataNascimento"
                  label={
                    isCpf ? t('Data de nascimento') : t('Data de abertura')
                  }
                  control={control}
                  error={errors.dataNascimento}
                  disabled={readonly}
                />
              </Col>
              <Col xl={6} lg={4} md={4} sm={12} xs={12}>
                <InputRgIeIsento
                  type="text"
                  isRg={isInformarIE ? false : isCpf}
                  id="rgInscricaoEstadual"
                  name="rgInscricaoEstadual"
                  setValue={setValue}
                  label={isCpf && !isInformarIE ? 'RG' : 'Inscrição estadual'}
                  placeholder={
                    isCpf && !isInformarIE
                      ? 'Digite o RG'
                      : 'Digite a inscrição estadual'
                  }
                  validarRg={isInformarIE ? false : isCpf && isValueRg}
                  control={control}
                  helperText={
                    isCpf
                      ? !isInformarIE
                        ? 'Informar IE'
                        : 'Informar RG'
                      : undefined
                  }
                  clickHelperText={() => {
                    setIsInformarIE(!isInformarIE);
                  }}
                  error={errors.rgInscricaoEstadual}
                  setError={setError}
                  disabled={readonly}
                />
              </Col>
            </Form.Row>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col xl={7} lg={6} md={12} sm={12} xs={12}>
            <InputNull
              type="text"
              id="sitePerfil"
              name="sitePerfil"
              label={t('Site ou perfil (URL)')}
              placeholder={t('Digite o site ou perfil')}
              maxLength={60}
              control={control}
              error={errors.SitePerfil}
              disabled={readonly}
            />
          </Col>
          <Col xl lg md sm xs={12}>
            <InputTelefoneWhatsapp
              inputMode="numeric"
              type="tel"
              id="celular"
              name="celular"
              label={t('Celular')}
              placeholder="(00) 00000-0000"
              control={control}
              error={errors.celular}
              disabled={readonly}
              setValue={setValue}
            />
          </Col>
          <Col xl lg md sm xs={12}>
            <InputTelefoneWhatsapp
              inputMode="numeric"
              type="tel"
              id="telefone"
              name="telefone"
              label={t('Telefone')}
              placeholder="(00) 0000-0000"
              control={control}
              maxLength={15}
              error={errors.telefone}
              disabled={readonly}
              setValue={setValue}
            />
          </Col>
          {/* </Col> */}
        </Form.Row>
        {rotaCliente && (
          <Form.Row>
            <Col xl={4} lg={4} md={6} sm={6} xs={12}>
              <InputNull
                type="text"
                id="email"
                name="email"
                label={t('E-mail')}
                placeholder={t('Digite o endereço de e-mail')}
                maxLength={60}
                control={control}
                error={errors.email}
                disabled={readonly}
              />
            </Col>
            <Col xl={4} lg={4} md={6} sm={6} xs={12}>
              <InputNull
                type="text"
                id="emailNfe"
                name="emailNfe"
                label={t('E-mail NF-e')}
                placeholder={t('Digite o endereço de e-mail')}
                maxLength={60}
                control={control}
                error={errors.emailNfe}
                disabled={readonly}
                linkAction={
                  emailWatch && {
                    label: 'Repetir e-mail',
                    onClick: () => {
                      setValue('emailNfe', emailWatch);
                    },
                  }
                }
              />
            </Col>
            <Col xl={4} lg={4} md={12} sm={12} xs={12}>
              <SelectPadrao
                id="categoriaCliente"
                name="categoriaCliente"
                label={t('Categoria')}
                noSelectedText={t('Selecione a categoria.')}
                placeholder={t('Selecione a categoria.')}
                defaultValue=""
                required
                control={control}
                readonly={readonly}
                error={errors.categoriaCliente}
                options={categoriaClientes}
                controlledByObject
              />
            </Col>
          </Form.Row>
        )}
        {!rotaCliente && (
          <Form.Row>
            <Col xl={7} lg={6} md={6} sm={6} xs={12}>
              <InputNull
                type="text"
                id="email"
                name="email"
                label={t('E-mail')}
                placeholder={t('Digite o endereço de e-mail')}
                maxLength={60}
                control={control}
                error={errors.email}
                disabled={readonly}
              />
            </Col>
            <Col xl={5} lg={6} md={6} sm={6} xs={12}>
              <InputNull
                type="text"
                id="emailNfe"
                name="emailNfe"
                label={t('E-mail NF-e')}
                placeholder={t('Digite o endereço de e-mail')}
                maxLength={60}
                control={control}
                error={errors.emailNfe}
                disabled={readonly}
              />
            </Col>
          </Form.Row>
        )}
        <Form.Row>
          <Col xl={10} lg={8} md={8} sm={12} xs={12}>
            <TextAreaPadrao
              id="observacao"
              label={t('Observação')}
              placeholder={t('Digite sua observação')}
              error={errors.observacao}
              disabled={readonly}
              {...register('observacao')}
            />
          </Col>
          <Col xl={2} lg={4} md={4} sm={12} xs={12}>
            <CheckBoxAtivoInativo
              id="ativo"
              name="ativo"
              label="Status"
              control={control}
              readonly={readonly}
            />
          </Col>
        </Form.Row>
        <br />
        <br />
        <Form.Row>
          <Col lg={0}>
            <Form.Row>
              <Col lg={12}>
                <Enderecos
                  errors={errors}
                  setError={setError}
                  register={register}
                  readonly={readonly}
                  control={control}
                  setValue={setValue}
                  getValue={getValue}
                  cadastroPdv={cadastroPdv}
                />
              </Col>
            </Form.Row>
            <br />
            <br />
            <Form.Row>
              <Col lg={12}>
                <ContatosAdicionais
                  register={register}
                  readonly={readonly}
                  control={control}
                  setValue={setValue}
                  getValue={getValue}
                  cadastroPdv={cadastroPdv}
                />
              </Col>
            </Form.Row>
            <br />
            <br />
            <Form.Row>
              <Col lg={12}>
                <OutrasInformacoes
                  errors={errors}
                  readonly={readonly}
                  control={control}
                  isCpf={isCpf}
                  setValue={setValue}
                  rotaCliente={rotaCliente}
                  isAlteracao={isAlteracao}
                />
              </Col>
            </Form.Row>
            {rotaCliente && (
              <>
                <br />
                <br />
                <Form.Row>
                  <Col lg={0}>
                    <CamposPersonalizados
                      readonly={readonly}
                      camposPersonalizados={camposPersonalizados}
                    />
                  </Col>
                </Form.Row>
              </>
            )}
          </Col>
        </Form.Row>
      </>
    );
  }
);

export default UncontrolledForm;
