import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import { setDateMaxHours, setDateMinHours } from 'helpers/data/setHoursDate';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import ConsignacaoTipoRelatorioEnum from 'constants/enum/consignacaoTipoRelatorioEnum';
import ConsignacaoSituacaoEnum from 'constants/enum/consignacaoSituacaoEnum';

const schema = yup.object().shape({
  dataInicio: yup.date().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  tipoRelatorioConsignacao: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);

export type ClienteOptionResponse = {
  label: string;
  value: string;
  endereco: string;
};

export type SelectProdutoCorResponse = {
  id: string;
  nome: string;
};

export type FormData = {
  cliente: ClienteOptionResponse | null;
  dataInicio: Date;
  dataFim: Date;
  produtos: string;
  situacao: number;
  tipoRelatorioConsignacao: number;
};

export const formDefaultValues = {
  dataInicio: setDateMinHours(new Date()),
  dataFim: setDateMaxHours(new Date()),
  cliente: null,
  produtos: '',
  situacao: ConsignacaoSituacaoEnum.TODOS,
  tipoRelatorioConsignacao: ConsignacaoTipoRelatorioEnum.LISTAGEM_PRODUTOS,
};
