import React, { useEffect, useState, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useHistory, RouteComponentProps, Prompt } from 'react-router-dom';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';
import RegrasFiscaisFormularioProvider from 'store/RegrasFiscais/RegrasFiscaisFormularios';
import ConstanteRotas from 'constants/rotas';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import isPrenvent from 'helpers/layout/isPrenvent';
import { obterRegraFiscal as apiObterRegraFiscal } from 'helpers/api/RegrasFiscais/obterRegraFiscal';

import ManterFoco from 'components/Geral/ManterFoco';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import RodapeFormulario from 'components/Geral/RodapeFormulario';

import {
  FormRegraFiscalData,
  mapRequestRegraFiscalData,
  mapResponseRegraFiscalData,
} from '../types';
import Formulario, { FormularioForwardRefData } from '..';
import { yupResolver } from '../validationForm';

type TParams = { id: string };

const Alterar = ({ match }: RouteComponentProps<TParams>) => {
  const history = useHistory();
  const formMethods = useForm<FormRegraFiscalData>({
    resolver: yupResolver,
    defaultValues: {
      nome: '',
      ativo: true,
      operacoes: [
        {
          identificacaoTipoOperacao: IdentificacaoTipoOperacaoEnum.VENDA,
        },
      ],
    },
  });
  const isMountedRef = useIsMountedRef();

  const { dirtyFields } = formMethods.formState;
  const isDirty =
    dirtyFields &&
    dirtyFields.constructor === Object &&
    Object.keys(dirtyFields).length > 0;

  const [isPrenvented, setIsPrenvented] = useState(false);
  isPrenvent(isPrenvented);

  const regraFiscalId = match.params.id;

  const [isLoading, setIsLoading] = useState(false);
  const [dataHoraCadastro, setDataHoraCadastro] = useState('');
  const [dataHoraUltimaAlteracao, setDataHoraUltimaAlteracao] = useState('');

  const formularioRef = useRef<FormularioForwardRefData>(null);

  function historyPush(path: string) {
    setIsPrenvented(false);

    history.push(path);
  }

  const handleSubmit = formMethods.handleSubmit(
    async (data) => {
      setIsLoading(true);

      const mappedData = mapRequestRegraFiscalData(data, regraFiscalId);

      const response = await api.put<void, ResponseApi>(
        ConstanteEnderecoWebservice.REGRA_FISCAL_ALTERAR,
        {
          ...mappedData,
        }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.map((item: string) => toast.warning(item));
        }

        if (response.sucesso) {
          toast.success('O cadastro foi alterado com sucesso.');

          historyPush(ConstanteRotas.REGRA_FISCAL);
        }
      }

      if (isMountedRef.current) setIsLoading(false);
    },
    (errors) => {
      setIsLoading(true);

      if (formularioRef.current) {
        formularioRef.current.focusAccordionError(errors);
      }

      setIsLoading(false);
    }
  );

  const latestProps = useRef({
    reset: formMethods.reset,
    setValue: formMethods.setValue,
    regraFiscalId,
    historyPush,
    setFocus: formMethods.setFocus,
  });
  useEffect(() => {
    latestProps.current = {
      reset: formMethods.reset,
      setValue: formMethods.setValue,
      regraFiscalId,
      historyPush,
      setFocus: formMethods.setFocus,
    };
  });

  useEffect(() => {
    async function obterRegraFiscal() {
      setIsLoading(true);

      const responseData = await apiObterRegraFiscal(
        latestProps.current.regraFiscalId
      );

      if (responseData) {
        setDataHoraCadastro(responseData.dataHoraCadastro || '');
        setDataHoraUltimaAlteracao(responseData.dataHoraUltimaAlteracao || '');

        const mappedData = mapResponseRegraFiscalData(responseData);

        latestProps.current.reset({
          ...mappedData,
          operacoes: [mappedData.operacoes[0]],
        });

        latestProps.current.setValue('operacoes', mappedData.operacoes);

        setIsLoading(false);

        latestProps.current.setFocus('nome');
      } else {
        setIsLoading(false);

        latestProps.current.historyPush(ConstanteRotas.REGRA_FISCAL);
      }
    }

    obterRegraFiscal();
  }, []);

  useEffect(() => {
    setIsPrenvented(isDirty);
  }, [isDirty]);

  return (
    <Box>
      <Prompt when={isPrenvented} message="" />
      <ManterFoco>
        {isLoading && <LoadingPadrao />}
        <RegrasFiscaisFormularioProvider {...formMethods}>
          <Formulario ref={formularioRef} />
        </RegrasFiscaisFormularioProvider>

        <RodapeFormulario
          onSubmit={handleSubmit}
          disabled={isLoading}
          dataHoraCriacao={dataHoraCadastro}
          dataHoraUltimaAlteracao={dataHoraUltimaAlteracao}
        />
      </ManterFoco>
    </Box>
  );
};

export default Alterar;
