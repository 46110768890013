import { useEffect, useRef, useState } from 'react';
import {
  Flex,
  Box,
  Text,
  Button,
  Avatar,
  Icon,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { BsArrowRight } from 'react-icons/bs';
import { toast } from 'react-toastify';

import { useMetasComissoesEtapasContext } from 'store/MetasComissoes/MetasComissoesEtapas';
import { IdentificacaoEtapasMetasComissoes } from 'constants/enum/identificacaoEtapasMetasComissoes';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import { AplicarLote } from 'icons';
import { NumberInput } from 'components/update/Input/NumberInput';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { ModalValorMetasVendedores } from './ModalValorMetas';
import { FooterMetasComissoes } from '../FooterMetasComissoes';

type VendedorMetaProps = {
  vendedorId: string;
  vendedor: string;
  foto: string;
  lojaId: string;
  valorMeta: number;
  naoUtilizaMeta: boolean;
  comissaoPadrao: number;
};

export function MetaVendedores() {
  const [metaPorVendedor, setMetaPorVendedor] = useState<VendedorMetaProps[]>(
    []
  );

  const {
    setActiveStep,
    setIsLoading,
    isLoading,
    animacaoLoading,
  } = useMetasComissoesEtapasContext();
  const { handleSubmit, setValue } = useFormContext();

  const isMobile = useBreakpointValue({
    sm: true,
    base: true,
    md: false,
    lg: false,
  });

  const valorDaUltimaMeta = useRef<number>(0);

  async function handleSetMetasVendedores() {
    const { current: valorDaMetaAnterior } = valorDaUltimaMeta;
    const { success, valorMeta } = await ModalValorMetasVendedores({
      valorDaMetaAnterior,
      isMobile,
    });

    if (success) {
      metaPorVendedor.forEach((_, index) => {
        setValue(`metaVendedor.${index}`, valorMeta);
      });

      valorDaUltimaMeta.current = valorMeta;
    }
  }

  const handleAvancar = handleSubmit(async ({ metaVendedor }) => {
    await setIsLoading(true);
    const dadosEnviarApi = await metaPorVendedor.map((meta, index) => ({
      vendedorId: meta.vendedorId,
      valorMeta: metaVendedor[index],
    }));

    const response = await api.post<void, ResponseApi<VendedorMetaProps[]>>(
      ConstanteEnderecoWebservice.CADASTRAR_LISTA_COMISSAO_VENDEDOR,
      dadosEnviarApi
    );
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }
      if (response.sucesso) {
        setActiveStep(IdentificacaoEtapasMetasComissoes.COMISSAO_VENDEDORES);
      }
    }
    setIsLoading(false);
  });

  function handleVoltar() {
    setActiveStep(IdentificacaoEtapasMetasComissoes.ESCOLHER_VALOR_META);
  }

  useEffect(() => {
    async function getVendedores() {
      const response = await api.get<void, ResponseApi<VendedorMetaProps[]>>(
        ConstanteEnderecoWebservice.LISTAR_VENDEDORES_META_COMISSOES
      );
      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response.sucesso && response.dados) {
          await setMetaPorVendedor(() => {
            return response.dados.map((valorMetas, index) => {
              setValue(`metaVendedor.${index}`, valorMetas.valorMeta);
              return {
                ...valorMetas,
              };
            });
          });

          animacaoLoading();
        }
      }

      animacaoLoading();
    }
    getVendedores();
  }, [animacaoLoading, setValue]);

  return (
    <>
      {isLoading && <LoadingPadrao />}
      <Box borderRadius="md" boxShadow="md" w="full" overflow="hidden">
        <Flex
          justifyContent="space-between"
          align="center"
          flexDirection="row"
          px="6"
          py="4"
          h="48px"
          bg="primary.500"
        >
          <Text fontSize="md" color="secondary.300" fontWeight="semibold">
            Meta dos vendedores
          </Text>
          <Button
            _active={{
              color: 'yellow.500',
            }}
            p="1"
            color="yellow.500"
            letterSpacing="0.235px"
            fontSize="sm"
            variant="gost"
            fontWeight="normal"
            _hover={{
              textDecoration: 'underline',
              fontWeight: 'bold',
              letterSpacing: 0,
            }}
            onClick={handleSetMetasVendedores}
          >
            {isMobile ? (
              <Icon as={AplicarLote} w={6} h={6} />
            ) : (
              'Aplicar o mesmo valor para todos os vendedores'
            )}
          </Button>
        </Flex>
        <Box
          sx={{
            '& > div:not(:last-child)': {
              borderBottom: '1px solid var(--sti-ck-colors-gray-200)',
            },
            '&::-webkit-scrollbar': {
              width: '0',
            },
          }}
          bg="white"
          borderBottomColor="gray.200"
          paddingX={{ base: '2', md: '8' }}
          maxH="400px"
          overflow="auto"
        >
          {metaPorVendedor.map((vendedor, index) => (
            <Flex
              key={vendedor.vendedorId}
              pt="15px"
              pb="15px"
              flexDirection={isMobile ? 'column' : 'row'}
              gap={isMobile ? '3' : ''}
            >
              <Flex
                flexDirection="row"
                w={isMobile ? '' : '50%'}
                alignItems="center"
                pl="20px"
                gap="4"
              >
                <Avatar boxSize="30px" src={vendedor.foto} />
                <Text
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {vendedor.vendedor}
                </Text>
              </Flex>
              <Box
                display="flex"
                flexDirection="row"
                gap="10"
                alignItems="center"
                ml="43px"
              >
                {!isMobile && <Icon as={BsArrowRight} w={8} h={6} />}
                <NumberInput
                  name={`metaVendedor.${index}`}
                  id={`metaVendedor.${index}`}
                  w="240px"
                  editarFundoLeftElemento
                  bgLeftElement="gray.50"
                  leftElement="R$"
                  scale={2}
                  colSpan={3}
                  isDisabled={vendedor.naoUtilizaMeta}
                />
              </Box>
            </Flex>
          ))}
        </Box>
      </Box>
      <FooterMetasComissoes
        handleAvancar={handleAvancar}
        handleVoltar={handleVoltar}
        isDisabledAvancar={false}
      />
    </>
  );
}
