import { useState, useEffect, useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import {
  Box,
  Text,
  Stack,
  Button,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Divider,
  Heading,
  ModalProps,
  GridItem,
  useDisclosure,
  Flex,
  useBreakpointValue,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { create, InstanceProps } from 'react-modal-promise';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { formatDate } from 'helpers/format/formatStringDate';

import { NumberInput } from 'components/update/Input/NumberInput';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import Select from 'components/PDV/Select/SelectPadrao';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { DateInput } from 'components/update/Input/DateInput';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { MonthInput } from 'components/update/Input/MonthInput';
import { ButtonDefault } from 'components/Button/ButtonChakra';

import {
  yupResolver,
  FormData,
  ContasFinanceirasProps,
} from './validationForm';

type ModalBaixarContasResponse = {
  sucesso: boolean;
};

type BaixarContaItem = {
  movimentacaoFinanceiraId: string;
  valor: number;
  desconto: number;
  multa: number;
  juros: number;
};

type ModalBaixarContasProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalBaixarContasResponse> & {
    baixarContaItems: BaixarContaItem[];
    fornecedorRazaoSocial?: string;
    fornecedorFantasia?: string;
    dataVencimento?: Date;
    dataEmissao?: Date;
    casasDecimaisValor: number;
  };

interface FormaPagamentoProps {
  id: string;
  nome: string;
  lancarFatura: boolean;
  diaVencimento: number;
  contasFinanceiras: ContasFinanceirasProps;
}

type Option = {
  label: string;
  value: string;
  lancarFatura?: boolean;
  diaVencimento?: number;
};

export const ModalBaixarContas = create<
  ModalBaixarContasProps,
  ModalBaixarContasResponse
>(
  ({
    onReject,
    onResolve,
    baixarContaItems,
    fornecedorRazaoSocial,
    fornecedorFantasia,
    dataVencimento,
    casasDecimaisValor,
    dataEmissao,
    ...rest
  }) => {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const isResponsiveVersion = useBreakpointValue({
      base: false,
      lg: true,
    });

    const isMultipla = baixarContaItems.length > 1;

    const valor = baixarContaItems.reduce((acc, curr) => acc + curr.valor, 0);

    const formMethods = useForm<FormData>({
      resolver: yupResolver,
      defaultValues: {
        dataPagamento: new Date(),
        parcelas: 1,
        juros: baixarContaItems.reduce((acc, curr) => acc + curr.juros, 0),
        multa: baixarContaItems.reduce((acc, curr) => acc + curr.multa, 0),
        desconto: baixarContaItems.reduce(
          (acc, curr) => acc + curr.desconto,
          0
        ),
      },
    });

    const {
      formState: { errors },
    } = formMethods;

    const [
      isFormaPagamentoOptionsLoading,
      setIsFormaPagamentoOptionsLoading,
    ] = useState<boolean>(false);
    const [formasPagamentoOptions, setIsFormasPagamentoOptions] = useState<
      Option[]
    >([]);

    const [
      envioBaixarContasLoading,
      setIsEnvioBaixarContasLoading,
    ] = useState<boolean>(false);

    const [
      renderizaSelectFatura,
      setIsRenderizaSelectFatura,
    ] = useState<boolean>(false);

    const [valorParcelas, setValorParcelas] = useState(0);

    const [
      formaPagamentoSelecionada,
      dataVencimentoCartao,
      dataPagamento,
      parcelas,
      total,
      juros,
      multa,
      desconto,
    ] = formMethods.watch([
      'formaPagamentoId',
      'dataVencimentoCartao',
      'dataPagamento',
      'parcelas',
      'totalBaixa',
      'juros',
      'multa',
      'desconto',
    ]);
    const contaFinanceira = useCallback(() => {
      return formaPagamentoSelecionada?.contasFinanceiras || [];
    }, [formaPagamentoSelecionada])();

    const handleSubmit = formMethods.handleSubmit(
      async ({
        formaPagamentoId,
        contaFinanceiraId,
        dataPagamento: DataPagamento,
        desconto: Desconto,
        multa: Multa,
        juros: Juros,
        dataVencimentoCartao: DataVencimentoCartao,
        parcelas: Parcelas,
        totalBaixa,
      }) => {
        setIsEnvioBaixarContasLoading(true);

        const data = baixarContaItems.map((baixarContaItem) => {
          let valuesBaixarConta;
          const dadosConta = {
            formaPagamentoId: formaPagamentoId.value,
            dataFatura: formaPagamentoSelecionada.lancarFatura
              ? DataVencimentoCartao
              : null,
            dataPagamento: new Date(DataPagamento).toISOString(),
            movimentacaoFinanceiraId: baixarContaItem.movimentacaoFinanceiraId,
            desconto: isMultipla ? baixarContaItem.desconto : Desconto,
            multa: isMultipla ? baixarContaItem.multa : Multa,
            juros: isMultipla ? baixarContaItem.juros : Juros,
            valor: isMultipla
              ? baixarContaItem.valor -
                baixarContaItem.desconto +
                baixarContaItem.multa +
                baixarContaItem.juros
              : totalBaixa,
            QtdParcela: Parcelas || 1,
          };

          if (!renderizaSelectFatura) {
            valuesBaixarConta = {
              ...dadosConta,
              contaFinanceiraId,
            };
          } else {
            valuesBaixarConta = {
              ...dadosConta,
            };
          }
          return valuesBaixarConta;
        });

        const response = await api.post<void, ResponseApi<FormData>>(
          ConstanteEnderecoWebservice.CONTAS_PAGAR_BAIXAR_CONTAS,
          data
        );

        if (response) {
          if (response.avisos) {
            response.avisos.forEach((aviso) => toast.warning(aviso));
          }

          if (response.sucesso) {
            onResolve({ sucesso: response.sucesso });

            toast.success(
              isMultipla
                ? 'Contas baixadas com sucesso!'
                : 'Conta baixada com sucesso!'
            );

            return;
          }
        }

        setIsEnvioBaixarContasLoading(false);
      }
    );

    useEffect(() => {
      async function getFormaPagamentoOption() {
        setIsFormaPagamentoOptionsLoading(true);

        const response = await api.get<
          void,
          ResponseApi<FormaPagamentoProps[]>
        >(ConstanteEnderecoWebservice.FORMA_PAGAMENTO_LISTAR_SELECT_PAGAMENTO);

        if (response) {
          if (response.avisos) {
            response.avisos.forEach((aviso) => toast.warning(aviso));
          }

          if (response.sucesso && response.dados) {
            setIsFormasPagamentoOptions(
              response.dados.map((formaPagamento) => ({
                value: formaPagamento.id,
                label: formaPagamento.nome,
                lancarFatura: formaPagamento.lancarFatura,
                diaVencimento: formaPagamento.diaVencimento,
                contasFinanceiras: formaPagamento.contasFinanceiras,
              }))
            );
          }
        }

        setIsFormaPagamentoOptionsLoading(false);
      }
      getFormaPagamentoOption();
    }, []);

    const latestProps = useRef({ setValue: formMethods.setValue });

    useEffect(() => {
      latestProps.current = { setValue: formMethods.setValue };
    });

    useEffect(() => {
      const vlrtotal = valor + juros + multa - desconto;
      latestProps.current.setValue(
        'totalBaixa',
        Math.round((vlrtotal + Number.EPSILON) * 100) / 100
      );
    }, [juros, multa, desconto, valor]);

    useEffect(() => {
      let vlrParcelas;
      if (!parcelas) {
        vlrParcelas = Number(total) / 1;
        setValorParcelas(vlrParcelas);
      } else {
        vlrParcelas = Number(total) / Number(parcelas);
        setValorParcelas(vlrParcelas);
      }
    }, [parcelas, total]);

    useEffect(() => {
      let verificaDataHoje = new Date();

      const indiceFormaPagamentoSelecionada = formasPagamentoOptions.findIndex(
        (element) => element.value === formaPagamentoSelecionada?.value
      );
      if (
        indiceFormaPagamentoSelecionada !== -1 &&
        indiceFormaPagamentoSelecionada >= 0
      ) {
        if (
          formasPagamentoOptions[indiceFormaPagamentoSelecionada].lancarFatura
        ) {
          setIsRenderizaSelectFatura(true);
          // este teste verifica se o dia do vencimento que vem da api é maior ou menor que o dia atual, menor? lançar para o próximo mês, maior lançar para o mês em questão.
          if (
            Number(
              formasPagamentoOptions[indiceFormaPagamentoSelecionada]
                .diaVencimento
            ) > verificaDataHoje.getDate()
          ) {
            verificaDataHoje = new Date(
              verificaDataHoje.getFullYear(),
              new Date().getMonth()
            );
            latestProps.current.setValue(
              'dataVencimentoCartao',
              verificaDataHoje
            );
          } else {
            verificaDataHoje = new Date(
              verificaDataHoje.getFullYear(),
              new Date().getMonth() + 1
            );
            latestProps.current.setValue(
              'dataVencimentoCartao',
              verificaDataHoje
            );
          }
          // fim do teste.
        } else {
          setIsRenderizaSelectFatura(false);
        }
      }
    }, [formaPagamentoSelecionada, formasPagamentoOptions]);

    useEffect(() => {
      const dateNow = new Date();
      const DataVencimentoCartao = dataVencimentoCartao ?? dateNow;

      if (
        (formaPagamentoSelecionada?.diaVencimento <= dateNow?.getDate() ||
          formaPagamentoSelecionada?.diaVencimento <=
            dataPagamento?.getDate()) &&
        (dataPagamento?.getMonth() !== DataVencimentoCartao?.getMonth() - 1 ||
          dataPagamento?.getMonth() === DataVencimentoCartao?.getMonth())
      ) {
        latestProps.current.setValue(
          'dataVencimentoCartao',
          new Date(
            dateNow.getFullYear(),
            dataPagamento.getMonth() + 1,
            formaPagamentoSelecionada.diaVencimento,
            0,
            0,
            0
          )
        );
      } else if (
        formaPagamentoSelecionada?.diaVencimento > dataPagamento?.getDate() &&
        dataPagamento?.getMonth() !== DataVencimentoCartao?.getMonth()
      ) {
        latestProps.current.setValue(
          'dataVencimentoCartao',
          new Date(
            dateNow.getFullYear(),
            dataPagamento.getMonth(),
            formaPagamentoSelecionada.diaVencimento,
            0,
            0,
            0
          )
        );
      } else if (
        DataVencimentoCartao?.getDate() !==
          formaPagamentoSelecionada?.diaVencimento &&
        formaPagamentoSelecionada?.lancarFatura
      ) {
        latestProps.current.setValue(
          'dataVencimentoCartao',
          new Date(
            dateNow.getFullYear(),
            DataVencimentoCartao.getMonth(),
            formaPagamentoSelecionada?.diaVencimento,
            0,
            0,
            0
          )
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formaPagamentoSelecionada, dataPagamento]);

    useEffect(() => {
      if (
        contaFinanceira &&
        contaFinanceira[0]?.id &&
        contaFinanceira?.length > 0
      ) {
        contaFinanceira.forEach((itemContaFinanceira) => {
          if (itemContaFinanceira?.padraoSistema) {
            latestProps.current.setValue(
              'contaFinanceiraId',
              itemContaFinanceira.id
            );
          }
        });
      }
    }, [contaFinanceira, formaPagamentoSelecionada]);

    return (
      <Box px="4">
        <ModalPadraoChakra
          size="4xl"
          {...rest}
          isOpen={isOpen}
          onClose={onClose}
          isCentered
        >
          <ModalContent px="2" pt="6" mx="8" bg="gray.50">
            <ModalHeader fontSize="sm" mb="4" py="0">
              <Heading
                fontSize="lg"
                color="primary.50"
                mb={{ base: '1', lg: '4' }}
              >
                {isMultipla ? 'Baixar várias contas' : 'Baixar conta'}
              </Heading>
              <Box bg="primary.400" mt="2" borderRadius="md">
                <SimpleGridForm mx="4">
                  <GridItem
                    colSpan={{ base: 12, md: 12, lg: 8 }}
                    my={{ lg: 3 }}
                    mt={{ base: 2, md: 2 }}
                  >
                    <Text fontSize="xs" color="white">
                      Fornecedor:
                    </Text>
                    <Text fontSize="sm" color="white" fontWeight="normal">
                      {isMultipla
                        ? '---'
                        : `${fornecedorRazaoSocial || '---'}${
                            fornecedorFantasia
                              ? ` - (${fornecedorFantasia})`
                              : ''
                          }`}
                    </Text>
                  </GridItem>
                  <GridItem
                    colSpan={{ base: 12, md: 12, lg: 2 }}
                    my={{ base: 0, md: 0, lg: 3 }}
                  >
                    <Text fontSize="xs" color="white">
                      Valor original:
                    </Text>
                    <Text fontSize="sm" color="white">
                      {`R$ ${valor.toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`}
                    </Text>
                  </GridItem>
                  <GridItem
                    colSpan={{ base: 12, md: 12, lg: 2 }}
                    my={{ base: 0, md: 0, lg: 3 }}
                    mb={{ base: 2, md: 2, lg: 0 }}
                  >
                    <Text fontSize="xs" color="white">
                      Vencimento:
                    </Text>
                    <Text fontSize="sm" color="white" fontWeight="normal">
                      {!isMultipla && dataVencimento
                        ? formatDate(dataVencimento)
                        : '---'}
                    </Text>
                  </GridItem>
                </SimpleGridForm>
              </Box>
            </ModalHeader>

            <ModalBody position="relative">
              {envioBaixarContasLoading && <LoadingPadrao />}
              <FormProvider {...formMethods}>
                <SimpleGridForm>
                  <NumberInput
                    isRequired={false}
                    label="Multas"
                    name="multa"
                    leftElement="R$"
                    size="sm"
                    isDisabled={isMultipla}
                    colSpan={{ base: 12, lg: 3 }}
                  />
                  <NumberInput
                    isRequired={false}
                    label="Juros"
                    name="juros"
                    leftElement="R$"
                    size="sm"
                    isDisabled={isMultipla}
                    colSpan={{ base: 12, lg: 3 }}
                  />
                  <NumberInput
                    isRequired={false}
                    label="Descontos"
                    name="desconto"
                    leftElement="R$"
                    size="sm"
                    isDisabled={isMultipla}
                    colSpan={{ base: 12, lg: 3 }}
                  />
                  <NumberInput
                    label="Valor total"
                    bg="secondary.300"
                    name="totalBaixa"
                    isReadOnly
                    border="gray.100"
                    leftElement="R$"
                    size="sm"
                    isRequired={false}
                    colSpan={{ base: 12, lg: 3 }}
                  />
                  <Select
                    size="sm"
                    asControlledByObject
                    id="formaPagamentoId"
                    name="formaPagamentoId"
                    errorText={errors.formaPagamentoId?.value?.message ?? ''}
                    label="Formas de pagamento"
                    placeholder="Formas de pagamento"
                    options={formasPagamentoOptions}
                    isLoading={isFormaPagamentoOptionsLoading}
                    onSelect={() => {
                      latestProps.current.setValue('contaFinanceiraId', '');
                    }}
                    colSpan={{ base: 12, lg: 6 }}
                  />
                  {!renderizaSelectFatura ? (
                    ''
                  ) : (
                    <>
                      <GridItem
                        colSpan={{ base: 12, lg: 3 }}
                        mt={{ base: 2, lg: 0 }}
                      >
                        <NumberInput
                          isRequired={false}
                          label="Parcelas"
                          name="parcelas"
                          leftElement="x"
                          precision={2}
                          size="sm"
                          scale={0}
                        />
                      </GridItem>

                      <GridItem colSpan={{ base: 12, lg: 3 }}>
                        <Flex
                          height="full"
                          alignItems="center"
                          justify={!isResponsiveVersion ? 'flex-end' : ''}
                          paddingTop={{ base: 0, lg: '6' }}
                        >
                          <Text pt="sm" fontSize="sm">
                            ({parcelas === 0 ? 1 : parcelas}x de{' '}
                            <strong>
                              {`R$ ${valorParcelas.toLocaleString('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}`}
                            </strong>
                            )
                          </Text>
                        </Flex>
                      </GridItem>
                    </>
                  )}
                  {!renderizaSelectFatura && <GridItem colSpan={4} />}
                  <DateInput
                    isRequired={false}
                    label="Data de pagamento"
                    name="dataPagamento"
                    colSpan={{ base: 12, lg: 3 }}
                    size="sm"
                  />
                  {!renderizaSelectFatura ? (
                    <Select
                      size="sm"
                      id="contaFinanceiraId"
                      name="contaFinanceiraId"
                      isDisabled={formaPagamentoSelecionada === undefined}
                      label="Conta financeira"
                      placeholder="Selecione uma conta financeira"
                      options={
                        formaPagamentoSelecionada?.contasFinanceiras
                          ? formaPagamentoSelecionada?.contasFinanceiras.map(
                              (conta) => ({
                                value: conta.id,
                                label: conta.nome,
                              })
                            )
                          : []
                      }
                      colSpan={{ base: 12, lg: 6 }}
                    />
                  ) : (
                    <MonthInput
                      name="dataVencimentoCartao"
                      type="month"
                      label="Fatura de vencimento do cartão"
                      height="8"
                      colSpan={{ base: 12, lg: 6 }}
                      borderColor="gray.100"
                      borderRadius="md"
                      size="sm"
                    />
                  )}
                </SimpleGridForm>
              </FormProvider>
            </ModalBody>
            <Box px="6" mt="2">
              <Divider mt="4" color="gray.200" />
            </Box>

            <ModalFooter
              justifyContent="center"
              mx={{ base: 6, md: 2 }}
              py={{ base: 6, md: 8 }}
              px="0"
            >
              <Stack
                direction={{ base: 'column', md: 'row' }}
                justifyContent="center"
                spacing={6}
                w="full"
              >
                <Button
                  variant="outline"
                  borderRadius="full"
                  w="full"
                  maxW={{ base: 'full', md: '100px' }}
                  onClick={() => onClose()}
                  size="sm"
                >
                  Cancelar
                </Button>
                <ButtonDefault
                  colorScheme="aquamarine"
                  borderRadius="full"
                  color="white"
                  width="full"
                  isLoading={envioBaixarContasLoading}
                  maxW={{ base: 'full', md: '110px' }}
                  size="sm"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Baixar conta
                </ButtonDefault>
              </Stack>
            </ModalFooter>
          </ModalContent>
        </ModalPadraoChakra>
      </Box>
    );
  }
);
