import { useImperativeHandle } from 'react';
import { Flex, Text, Icon, Box } from '@chakra-ui/react';
import { FiChevronLeft } from 'react-icons/fi';

import { IdentificacaoEtapasSmartPos } from 'constants/enum/IdentificacaoEtapasSmartPos';
import { useSmartPosEtapasContext } from 'store/SmartPos/SmartPosEtapasContext';

import { IconType } from 'icons';

type HeaderSmartPosProps = {
  icon: IconType;
  iconSize?: number;
  title: string;
  handleVoltar?: () => void;
  colorIcon?: string;
};

export const HeaderSmartPos = ({
  icon,
  title,
  iconSize = 40,
  handleVoltar,
  colorIcon = 'primary.50',
}: HeaderSmartPosProps) => {
  const { activeStep, refHandleVoltar } = useSmartPosEtapasContext();
  const isGuiaIntegracaoOuSucessoStone =
    activeStep === IdentificacaoEtapasSmartPos.GUIA_INTEGRACAO ||
    activeStep === IdentificacaoEtapasSmartPos.FOMULARIO_STONE_SUCESSO;

  useImperativeHandle(refHandleVoltar, () => ({
    handle: handleVoltar,
  }));

  return (
    <Box>
      <Flex
        justifyContent="center"
        w="full"
        alignItems="center"
        cursor="pointer"
      >
        <Icon
          boxSize={`${iconSize}px`}
          size={iconSize}
          color={colorIcon}
          as={icon}
        />
      </Flex>

      <Flex
        borderBottomStyle="solid"
        mb={['13px', '13px', '24px']}
        borderBottomWidth="1px"
        borderBottomColor="gray.100"
        alignItems="baseline"
        justifyContent={
          isGuiaIntegracaoOuSucessoStone ? 'space-between' : 'center'
        }
      >
        {!isGuiaIntegracaoOuSucessoStone && (
          <Box
            ml="10px"
            h="full"
            boxSize={['20px', '20px', '']}
            onClick={handleVoltar}
            cursor="pointer"
          >
            <Icon
              color="primary.50"
              display={['none', 'none', 'flex']}
              boxSize="30px"
              onClick={handleVoltar}
              cursor="pointer"
              as={FiChevronLeft}
            />
          </Box>
        )}

        <Box w="full">
          <Text
            textAlign="center"
            fontSize={['20px', '20px', '28px']}
            fontWeight="bold"
            marginTop={['8px', '8px', '12px']}
          >
            {title}
          </Text>
        </Box>

        {!isGuiaIntegracaoOuSucessoStone && (
          <Box h="full" w="20px" cursor="pointer" />
        )}
      </Flex>
    </Box>
  );
};
