import StatusFiscaisEnum, {
  StatusFiscais,
} from 'constants/enum/fiscal/statusFiscal';
import { formatDateHourMinute } from 'helpers/format/formatStringDate';

const formatarDataEmissao = (dataEmissao: Date) =>
  formatDateHourMinute(dataEmissao);

interface FormatarDescricaoDocumentoFiscalProps {
  status: StatusFiscais;
  numero?: string;
  dataEmissao: Date;
}

export function formatarDescricaoDocumentoFiscal({
  status,
  numero,
  dataEmissao,
}: FormatarDescricaoDocumentoFiscalProps) {
  if (status === StatusFiscaisEnum.Autorizada) {
    return `${numero} | ${formatarDataEmissao(dataEmissao)}`;
  }
  if (numero) {
    return `${numero} | ${StatusFiscaisEnum.properties[status].name}`;
  }

  return undefined;
}
