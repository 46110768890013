import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export type RelatorioProperties = {
  id: number;
  value: number;
  name: string;
  label: string;
  isChecked: boolean;
};

export interface RelatorioProps {
  FOTO?: number;
  DESCRICAO?: number;
  DESCRICAO_ABREVIADA?: number;
  GTIN_EAN?: number;
  REFERENCIA?: number;
  TIPO?: number;
  CATEGORIA?: number;
  MARCA?: number;
  ESTOQUE_MINIMO?: number;
  PRECO_COMPRA?: number;
  PRECO_CUSTO?: number;
  MARKUP?: number;
  PRECO_VENDA?: number;
  NCM?: number;
  CEST?: number;
  UNIDADE_MEDIDA?: number;
  TAGS?: number;
  ESTOQUE_ATUAL?: number;
  SKU?: number;
  REGRA_FISCAL?: number;
  CODIGO_BARRAS_INTERNO?: number;
  properties: RelatorioProperties[];
}

export type FormData = {
  nomeRelatorio: string;
  FOTO: RelatorioProperties;
  DESCRICAO: RelatorioProperties;
  DESCRICAO_ABREVIADA: RelatorioProperties;
  GTIN_EAN: RelatorioProperties;
  REFERENCIA: RelatorioProperties;
  TIPO: RelatorioProperties;
  CATEGORIA: RelatorioProperties;
  MARCA: RelatorioProperties;
  ESTOQUE_MINIMO: RelatorioProperties;
  PRECO_COMPRA: RelatorioProperties;
  PRECO_CUSTO: RelatorioProperties;
  MARKUP: RelatorioProperties;
  PRECO_VENDA: RelatorioProperties;
  NCM: RelatorioProperties;
  CEST: RelatorioProperties;
  UNIDADE_MEDIDA: RelatorioProperties;
  TAGS: RelatorioProperties;
  ESTOQUE_ATUAL: RelatorioProperties;
  SKU?: number;
  REGRA_FISCAL: RelatorioProperties;
  CODIGO_BARRAS_INTERNO: RelatorioProperties;
  camposOrdenacao: number[];
};

const schema = yup.object().shape({
  nomeRelatorio: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);
