const TipoFormaPagamentoRecebimentoEnum = {
  Recebimento: 1,
  Pagamento: 2,
  PagamentoRecebimento: 3,

  properties: {
    1: { name: 'Recebimento', value: 1 },
    2: { name: 'Pagamento', value: 2 },
    3: { name: 'Pagamento e Recebimento', value: 3 },
  },
};

export default TipoFormaPagamentoRecebimentoEnum;
