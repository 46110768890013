import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { toast } from 'react-toastify';
import api, { ResponseApi } from 'services/api';
import { ProdutoOption } from 'store/PDV/SelectProduto';
import { TipoProduto } from 'constants/enum/tipoProduto';

type ProdutoInfo = {
  produtoId: string;
  volumeUnitario: boolean;
  solicitarInformacaoComplementar: boolean;
  permiteAlterarValorNaVenda: boolean;
  tamanhos?: TamanhosProduto[] | [];
  tipoProduto: TipoProduto;
};
type TamanhosProduto = {
  id: string;
  tamanho: string;
  padraoSistema: boolean;
  sku: string | null;
  codigoGTINEAN: string | null;
  codigoExterno: string | null;
  codigoBarrasInterno: string | null;
  sequenciaCodigoBarras: string | null;
};

export type TamanhoOption = {
  id: string;
  nome: string;
  padraoSistema: boolean;
};

async function getPhoto(id: string, produtoCorId: string) {
  const { URL_IMAGEM_PRODUTO } = ConstanteEnderecoWebservice;
  const url = URL_IMAGEM_PRODUTO?.replace('produtoId', id)?.replace(
    'produtoCorId',
    produtoCorId
  );

  const response = await api.get<void, ResponseApi<string>>(url);

  if (response.avisos) {
    response.avisos.forEach((avisos) => toast.warn(avisos));
  }

  if (response.sucesso && response.dados) {
    return response.dados;
  }
  return '';
}

export async function obterProdutosLancamento(produtoEscolhido: {
  label: string;
  value: string;
}) {
  const response = await api.get<void, ResponseApi<ProdutoInfo>>(
    ConstanteEnderecoWebservice.OBTER_INFORMACOES_PRODUTO,
    {
      params: {
        produtoCorId: produtoEscolhido.value,
      },
    }
  );

  if (response) {
    if (response.avisos) {
      response.avisos.forEach((avisos) => toast.warn(avisos));
      return undefined;
    }

    if (response.sucesso && response.dados) {
      const {
        tamanhos: tamanhosApi,
        permiteAlterarValorNaVenda,
        produtoId,
        solicitarInformacaoComplementar,
        volumeUnitario,
        tipoProduto,
      } = response.dados;
      const foto = await getPhoto(produtoId, produtoEscolhido.value);

      const tamanhos = tamanhosApi?.map((tamanho) => {
        return {
          id: tamanho.id,
          nome: tamanho.tamanho,
          padraoSistema: tamanho.padraoSistema,
        };
      });

      const produto: ProdutoOption = {
        nome: produtoEscolhido.label.split('|')[0],
        cor: produtoEscolhido.label.split('|')[1],
        foto,
        volumeUnitario,
        solicitarInformacaoComplementar,
        permiteAlterarValorNaVenda,
        tamanhos: tamanhos?.length ? tamanhos : [],
        itemConsignado: false,
        adicionarItemAutomaticamente: false,
        tipoProduto,
      };

      return produto;
    }
  }
  return undefined;
}
