const colors = {
  current: 'currentColor', // https://tableless.com.br/css-3-o-valor-currentcolor/
  transparent: 'transparent',
  black: '#000',
  white: '#fff',
  menu: {
    bg: '#2f005d',
    border: '#390073',
    bgButton: '#00000020',
    borderButton: '#29004f',
    hoverText: '#A8E303',
  },
  pdv: {
    header: '#96cd00',
    headerTextColor: 'black',
    headerIconColor: '#3d1174',

    nav_header: '#29004f',
    footer: '#29004f',

    bg: '#390073',
    textColor: '#390073',

    menuButton: '#29004f',
    menuBorder: '#23003F',
    hoverText: '#A8E303',

    buttonPagamento: '#6a4ddb',

    divider: '#00000085',
    focus: '#2f005d',

    modalUsuarioBg: '#96cd00',
    modalUsuarioDivider: '#77bc1f',
    modalHover: '#00000020',
  },
  login: {
    disabled: '#568D00',
    hover: '#C2FC1C',
    active: '#A8E303',
    normal: '#96CD00',
    loading: '#5502b2',
    text: '#96CD00',
    buttonText: 'black',
  },
  loading: '#5502b2',
  primary: {
    50: '#5502b2',
    100: '#5100a1',
    200: '#4b0095',
    300: '#470787',
    400: '#3d1174',
    500: '#390073',
    600: '#330066',
    700: '#2f005d',
    800: '#29004f',
    900: '#23003f',
  },
  secondary: {
    50: '#F6FFDC',
    100: '#EAFFAE',
    200: '#D5FD66',
    300: '#C2FC1C',
    400: '#A8E303',
    500: '#96CD00',
    600: '#77BC1F',
    700: '#568D00',
    800: '#3A6B00',
    900: '#2C4821',
  },
  orange: {
    50: '#FFE9D1',
    100: '#FFD8AA',
    200: '#FFC575',
    300: '#FFB34B',
    400: '#FF9E31',
    500: '#FF8700',
    600: '#FF711D',
    700: '#FF561C',
    800: '#FF3100',
    900: '#DE3100',
  },
  red: {
    50: '#FFE1E1',
    100: '#FFB1B1',
    200: '#FF7F7F',
    300: '#FF4C4C',
    400: '#FF1A1A',
    500: '#E60000',
    600: '#B40000',
    700: '#810000',
    800: '#5A0000',
    900: '#450000',
  },
  brown: {
    50: '#F7F0ED',
    100: '#DBC5B0',
    200: '#C4A88C',
    300: '#AD855E',
    400: '#835931',
    500: '#6C4928',
    600: '#5D3918',
    700: '#4D2D0C',
    800: '#321706',
    900: '#190B01',
  },
  yellow: {
    50: '#FFF9DA',
    100: '#FFF4AD',
    200: '#FFF27D',
    300: '#FFF34B',
    400: '#FFF01E',
    500: '#FFE61A',
    600: '#FFD000',
    700: '#CA8E00',
    800: '#805700',
    900: '#643C00',
  },
  aquamarine: {
    50: '#d8fff9',
    100: '#abffed',
    200: '#7bffe2',
    300: '#49ffd6',
    400: '#18F8C4',
    500: '#00e6b1',
    600: '#00b389',
    700: '#008061',
    800: '#004e3a',
    900: '#1c4131',
  },
  teal: {
    50: '#dcf8fd',
    100: '#b8edec',
    200: '#91e4e5',
    300: '#6adedb',
    400: '#43d6d4',
    500: '#38B7BF',
    600: '#1a8f92',
    700: '#0a616a',
    800: '#004750',
    900: '#00313C',
  },
  blue: {
    50: '#ebf8ff',
    100: '#bee3f8',
    200: '#90cdf4',
    300: '#63b3ed',
    400: '#4299e9',
    500: '#0c61ff',
    600: '#1a33ff',
    700: '#0000e6',
    800: '#0000b4',
    900: '#000082',
  },
  purple: {
    50: '#ECE9FF',
    100: '#DBD0FF',
    200: '#B3A2EF',
    300: '#8C78E2',
    400: '#6A4DDB',
    500: '#572ABC',
    600: '#4B0095',
    700: '#390073',
    800: '#2F005D',
    900: '#23003F',
  },
  indigo: {
    50: '#fce3ff',
    100: '#ebb2ff',
    200: '#d880ff',
    300: '#c24efe',
    400: '#a91dfd',
    500: '#8905e4',
    600: '#6502b2',
    700: '#4f0095',
    800: '#440080',
    900: '#2e004f',
  },
  pink: {
    50: '#fff5f7',
    100: '#fed7e2',
    200: '#fbb6ce',
    300: '#f687b3',
    400: '#ed64a6',
    500: '#d53f8c',
    600: '#b83280',
    700: '#97266d',
    800: '#702459',
    900: '#521b41',
  },
  violet: {
    50: '#DBD5FF',
    100: '#B1A4F8',
    200: '#8E5CFF',
    300: '#740DE6',
    400: '#5C01C6',
    500: '#4F01B1',
    600: '#580E98',
    700: '#50008B',
    800: '#40016E',
    900: '#2D014D',
  },
  gray: {
    50: '#F5F5F5',
    100: '#E8E8E8',
    200: '#CCCCCC',
    300: '#AAAAAA',
    400: '#909090',
    500: '#707070',
    600: '#606060',
    700: '#404040',
    800: '#303030',
    900: '#202020',
  },
};

export default colors;
