import React, { memo } from 'react';

import NavMenuProvider from 'store/NavMenu';

import ImportedMenu from './Menu';
import Submenu from './Submenu';

const Menu = () => {
  return (
    <NavMenuProvider>
      <ImportedMenu />
      <Submenu />
    </NavMenuProvider>
  );
};

export default memo(Menu);
