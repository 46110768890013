import React, { cloneElement } from 'react';
import {
  Box,
  Divider,
  Flex,
  Icon,
  IconButton,
  ModalBody,
  ModalContent,
  ModalProps,
} from '@chakra-ui/react';
import { FiChevronLeft } from 'react-icons/fi';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { MobileSelectWrapperChildren } from './types';

interface MobileSelectWrapperModalProps extends ModalProps {
  children: MobileSelectWrapperChildren;
  asMobileView?: boolean;
  renderChildrenWhenClosed?: boolean;
  isLoading?: boolean;
}

export function MobileSelectWrapperModal({
  isOpen,
  onClose,
  children,
  asMobileView = false,
  renderChildrenWhenClosed = false,
  isLoading = false,
  ...rest
}: MobileSelectWrapperModalProps) {
  if (!asMobileView) {
    return children({ onClose });
  }

  if (!isOpen) {
    return renderChildrenWhenClosed ? children({ onClose }) : null;
  }

  return (
    <ModalPadraoChakra
      motionPreset="none"
      size="full"
      {...rest}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalContent
        margin="0"
        borderRadius="0"
        overflowX="hidden"
        overflowY="auto"
      >
        {isLoading && <LoadingPadrao />}
        <ModalBody p="0">
          <Box
            position="relative"
            w="full"
            mx="4"
            my="1.5"
            _focusWithin={{
              '& + div > hr': { borderColor: 'blue.500' },
            }}
            sx={{
              '& .react-select__placeholder': {
                isTruncated: true,
              },
              '& .react-select__custom-container': {
                ml: '6',
                maxW: 'calc(100% - 56px);',
              },
              '& .react-select__control': {
                border: 'none !important',
                borderRadius: 'none',
                boxShadow: 'none !important',
                borderColor: 'none !important',
              },
              '& .react-select__control--is-focused, & .react-select__control--menu-is-open': {
                border: 'none !important',
                boxShadow: 'none',
                borderColor: 'none !important',
              },
              '& .react-select__menu': {
                top: 'calc(100% + 2px)',
                left: '-10',
                w: 'calc(100% + 56px)',
                marginTop: '1.5',
                marginBottom: '1.5',
                '& > div': {
                  border: 'none',
                  boxShadow: 'none',
                  padding: '0',
                  borderRadius: '0px',
                  py: 2,
                },
                '& .react-select__option': {
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  py: 2,
                  px: 14,
                  fontSize: 'sm',
                  bg: 'white !important',
                  '& > svg': {
                    position: 'absolute',
                    left: '8',
                  },
                },
              },
            }}
          >
            <Flex position="absolute" h="full" alignItems="center">
              <IconButton
                aria-label="Fechar"
                left=""
                variant="link"
                size="sm"
                colorScheme="blue"
                p="1"
                minW="6"
                icon={<Icon as={FiChevronLeft} boxSize="7" strokeWidth="1.5" />}
                zIndex="tooltip"
                onClick={onClose}
              />
            </Flex>

            {cloneElement(children({ onClose }), {
              menuIsOpen: true,
              defaultMenuIsOpen: true,
              closeMenuOnSelect: false,
              closeMenuOnScroll: false,
            })}
          </Box>

          <Box mx="4">
            <Divider
              borderBottomWidth="2px"
              borderColor="gray.100"
              transition="border-color .2s"
            />
          </Box>
        </ModalBody>
      </ModalContent>
    </ModalPadraoChakra>
  );
}
