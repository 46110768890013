import { useCallback, useEffect, useState } from 'react';
import { Box, Image, Text, Flex } from '@chakra-ui/react';
import axios from 'axios';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import { FiDownload, FiHelpCircle } from 'react-icons/fi';

import TipoProdutoEnum from 'constants/enum/tipoProduto';
import { useProdutosFormularioContext } from 'store/Produtos/ProdutosFormulario';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';

import Input from 'components/PDV/Input';

import { ModalAjuda } from './ModalAjuda';
import { ModalDownload } from './ModalDownload';
import {
  getVariacoesCores,
  VariacoesCoresProps,
} from '../../FunctionsCadastroProduto/ObterCoresCadastradas';
import { ListItemVariacoes } from './ListItemVariacoes';

export const Imagens = () => {
  const [listVariacoes, setListVariacoes] = useState<VariacoesCoresProps[]>([]);
  const [valueThumbnailUrl, setValueThumbnailUrl] = useState('');
  const [urlVideo, setUrlVideo] = useState('');

  const {
    idProduto,
    setIsLoading,
    listImagens,
    onChangeFormIsDirty,
  } = useProdutosFormularioContext();
  const { watch, setValue } = useFormContext();

  const [videoWatch, tipoProdutoWatch] = watch(['urlVideo', 'tipoProduto']);

  const existeVariacoes = listVariacoes.length > 0;

  const produtoIsVariacao =
    TipoProdutoEnum.PRODUTO_VARIACAO === tipoProdutoWatch;

  const getCores = useCallback(async () => {
    setIsLoading(true);
    const response = await getVariacoesCores(idProduto, true);
    setListVariacoes(response);
    setIsLoading(false);
  }, [idProduto, setIsLoading]);

  function extractVideoId(url: string): string | null {
    const match = url.match(/[?&]v=([^&]+)/);
    return match ? match[1] : null;
  }

  const handleSalvarUrlVideo = useCallback(
    async (videoUrl: string, videoThumbnailUrl: string) => {
      const response = await api.patch<void, ResponseApi<boolean>>(
        `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/video`,
        {
          videoUrl,
          videoThumbnailUrl,
        }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
          setIsLoading(false);
        }
      }
    },
    [idProduto, setIsLoading]
  );

  const getUrlVideo = useCallback(async () => {
    const response = await api.get<
      void,
      ResponseApi<{ videoUrl: string; videoThumbnailUrl: string }>
    >(
      `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/video`
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }
      if (response.sucesso) {
        setValue('urlVideo', response.dados.videoUrl);
        setUrlVideo(response.dados.videoUrl);
        setValueThumbnailUrl(response.dados.videoThumbnailUrl);
      }
    }
  }, [idProduto, setValue]);

  const handleBuscarThumbnails = useCallback(() => {
    if (!videoWatch) {
      return;
    }

    const videoId = extractVideoId(videoWatch);

    axios
      .get(
        `${ConstanteEnderecoWebservice.YOU_TUBE}${videoId}&key=${process.env.REACT_APP_APP_API_KEY_YOU_TUBE}&part=snippet`
      )
      .then((response) => {
        const thumbnailUrl = response.data.items[0].snippet.thumbnails.high.url;
        setValueThumbnailUrl(thumbnailUrl);
        handleSalvarUrlVideo(videoWatch, thumbnailUrl);
      })
      .catch((error) => {
        toast.warning('Erro ao buscar informações do vídeo:', error);
      });
  }, [handleSalvarUrlVideo, videoWatch]);

  useEffect(() => {
    getCores();
  }, [getCores]);

  useEffect(() => {
    getUrlVideo();
  }, [getUrlVideo]);

  useEffect(() => {
    onChangeFormIsDirty(false);
  }, [onChangeFormIsDirty]);

  return (
    <Box w="full">
      <Flex justifyContent="space-between" alignItems="center">
        <Text fontSize="14px" color="black" fontWeight="semibold">
          Gerenciamento de imagens
        </Text>

        <Flex mb="5px">
          <Box
            mr="15px"
            onClick={() => ModalAjuda()}
            cursor="pointer"
            color="gray.700"
          >
            <FiHelpCircle size={20} />
          </Box>
          {existeVariacoes && listImagens?.length > 0 && (
            <Box
              onClick={() => {
                ModalDownload({ idProduto });
              }}
              cursor="pointer"
              color="gray.700"
            >
              <FiDownload size={20} />
            </Box>
          )}
        </Flex>
      </Flex>
      <Box
        borderRadius="5px"
        overflowY="auto"
        borderColor="gray.200"
        bg="gray.100"
        borderWidth="1px"
      >
        {existeVariacoes ? (
          <>
            {(listVariacoes.length > 1
              ? listVariacoes.filter(
                  (itemCores) => itemCores.cor.padraoSistema === false
                )
              : listVariacoes
            ).map((variacao) => (
              <ListItemVariacoes
                produtoIsVariacao={produtoIsVariacao}
                variacao={variacao}
              />
            ))}
          </>
        ) : (
          <Box p="20px">
            Para inserir imagens é necessário ao menos ter uma variação
          </Box>
        )}
      </Box>

      <Box mt="28px" mb="15px" w="70%">
        <Input
          helperTextLabel="Insira a Url do YouTube para salvar o link do vídeo"
          name="urlVideo"
          onBlur={() => {
            if (urlVideo !== videoWatch && videoWatch?.length > 29) {
              handleBuscarThumbnails();
            } else if (videoWatch?.length < 29) {
              setValueThumbnailUrl('');
            }
          }}
          id="urlVideo"
          placeholder="http://www.youtube.com/seuvideo"
          label="Endereço do vídeo (url)"
        />
      </Box>
      {valueThumbnailUrl && (
        <Image w="400px" h="300px" objectFit="cover" src={valueThumbnailUrl} />
      )}
    </Box>
  );
};
