import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Col, Container, Button, Tooltip } from 'react-bootstrap';
import { OptionTypeBase, OptionsType } from 'react-select';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  Box,
  HStack,
  Radio,
  Text,
  useToken,
  VStack,
  Button as ButtonChakra,
  Icon,
  Link,
  Flex,
  useMediaQuery,
} from '@chakra-ui/react';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { useLojaFormularioContext } from 'store/Lojas/LojasFormulario';
import formatUTCToLocateDateTime from 'helpers/format/formatUTCToLocateDateTime';
import { CertificadoDigitalRetorno } from 'types/loja';
import TipoCertificadoEnum from 'constants/enum/fiscal/tipoCertificado';
import { useSistemaAtual } from 'helpers/data/useSistemaAtual';
import { CnpjResponse } from 'services/receitaws';
import { cepMask } from 'helpers/format/fieldsMasks';
import AmbienteFiscalEnum from 'constants/enum/fiscal/ambienteFiscal';
import consultarViaCep from 'services/viacep';
import getOptionsByEnum, {
  getOptionByEnum,
} from 'helpers/format/getOptionsByEnum';
import auth from 'modules/auth';
import PlanoContratacaoEnum from 'constants/enum/planoContratacao';

import CodigoProdutoFiscalEnum from 'constants/enum/fiscal/codigoProdutoFiscal';

import {
  CertificadoDigitalIcon,
  DownloadModuloDesktopIcon,
  InfoIcon,
  LixeiraIcon,
} from 'icons';
import InputPadrao from 'components/Input/InputPadrao';
import InputCpfCnpj from 'components/Input/InputCpfCnpj';
import InputRgIeIsento from 'components/Input/InputRgIeIsento';
import RegimeTributarioEnum from 'constants/enum/regimeTributario';
import AccordionPadrao from 'components/Accordion/AccordionPadrao';
import EnderecoCompleto, {
  EnderecoCompletoRef,
} from 'components/EnderecoCompleto';
import InputNull from 'components/Input/InputNull';
import InputTelefoneWhatsapp from 'components/Input/InputTelefoneWhatsapp';
import CheckBoxDefault from 'components/CheckBox/CheckBoxDefault';
import SelectPadrao from 'components/Select/SelectPadrao';
import { BootstrapNumberInput } from 'components/update/Input/BootstrapNumberInput';
import ButtonComOpcoes from 'components/Button/ButtonComOpcoes';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import CheckBoxDefaultMappedField from 'components/CheckBox/CheckBoxDefaultMappedField';
import ImagePickerQuadradoOuRetangular from 'components/ImagePicker/ImagePickerQuadradoOuRetangular';
import OverlayTriggerPadrao from 'components/OverlayTriggerPadrao';
import RadioButtonPadrao from 'components/RadioButton/RadioButtonPadrao';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import { Card, Label } from 'styles';

import ModalCertificadoDigital from './ModalCertificadoDigital';
import CSCModal from './ModalCSC';
import ModalPinA3 from './ModalPinA3';
import {
  ButtonAdicionarCertificado,
  SpanFileDetails,
  SpanFileName,
  TableCampoFormularioPadraoStyle,
  ColLogoQuadrada,
  IconLogoQuadrada,
  IconLogoRetangular,
  ColMargemQuadrada,
  DivDetalhesLogo,
  ColMargemRetangular,
  HeaderInformacoesFiscais,
  SpanInformacoesFiscais,
} from './styles';

interface UncontrolledFormInterface {
  errors: FieldErrors;
  register: UseFormRegister<Record<string, any>>;
  control: any;
  readonly?: boolean;
  setError?: any;
  getValues?: any;
  setValue?: any;
  watch: any;
  isAlterar?: boolean;
}

export const UncontrolledForm = ({
  errors,
  register,
  control,
  readonly,
  setValue,
  isAlterar,
  setError,
  getValues,
  watch,
}: UncontrolledFormInterface) => {
  const enderecoCompletoRef = useRef<EnderecoCompletoRef>(null);

  const { nomeExibicao } = useSistemaAtual();

  const { t } = useTranslation();
  const blue500 = useToken('colors', 'blue.500');
  const {
    bloqueioInicial,
    setBloqueioInicial,
    bloqueioValorCpf,
    setBloqueioValorCpf,
    isSimplesNacional,
    setIsSimplesNacional,
    certificadoDigital,
    setCertificadoDigital,
    NFeReadOnly,
    NFCeReadOnly,
    setNFeReadOnly,
    setNFCeReadOnly,
  } = useLojaFormularioContext();
  const [regraFiscalOptions, setRegraFiscalOptions] = useState<
    OptionsType<OptionTypeBase>
  >([]);
  const [
    certificadoDigitalModalIsOpen,
    setCertificadoDigitalModalIsOpen,
  ] = useState(false);

  const [
    regraFiscalOptionsIsLoading,
    setRegraFiscalOptionsIsLoading,
  ] = useState(false);

  const [isCpf, setIsCpf] = useState(true);
  const primeiraValidacaoDoCPFCNPJ = useRef<boolean>(false);

  const [senhaCertificadoValido, setSenhaCertificadoValido] = useState(false);
  const [CSCModalIsOpen, setCSCModalIsOpen] = useState(false);
  const [modalPinA3IsOpen, setModalPinA3IsOpen] = useState(false);

  const [indexItemAlteracao, setIndexItemAlteracao] = useState(-1);

  const [isLargerThan500] = useMediaQuery('(min-width: 500px)');

  const documentosFiscaisAutomaticamenteWatch = watch(
    'documentosFiscaisAutomaticamente'
  );

  const planoAtual = auth.getPlano();
  const planoTeste = PlanoContratacaoEnum.TESTE;

  const isValueRg = (watch('ie') || '')?.replace(/[.-]/g, '')?.length < 10;

  const cidade = watch('cidade');
  const estado = watch('estado');
  const pais = watch('pais');

  useEffect(() => {
    if (
      cidade &&
      estado &&
      pais &&
      !getValues('contabilistaCidade') &&
      !getValues('contabilistaPais')
    ) {
      setValue('contabilistaCidade', cidade);
      setValue('contabilistaEstado', estado);
      setValue('contabilistaPais', pais);
    }
  }, [cidade, estado, pais, getValues, setValue, watch]);

  const tipoCertificadoWatch = watch('tipoCertificado', '0');
  const handleCSCPrincipalChange = (index: number) => {
    const { codigosSegurancaContribuinte } = getValues();
    const { csc } = getValues();

    if (csc.length === 1) {
      codigosSegurancaContribuinte[index].Principal = true;
      csc[index].Principal = true;
      setValue(`csc[${index}]`, { id: `csc[${index}]`, selecionado: true });

      toast.warning(
        'É preciso ter um Código de Segurança do Contribuinte Principal.'
      );
      return;
    }
    if (csc.length > 1) {
      csc.forEach((item: any, indexNumber: number) => {
        setValue(`csc[${indexNumber}]`, {
          id: `csc[${indexNumber}]`,
          selecionado: index === indexNumber,
        });
        codigosSegurancaContribuinte[indexNumber].Principal =
          index === indexNumber;
      });
    }

    setValue('codigosSegurancaContribuinte', codigosSegurancaContribuinte);
  };

  const removerItemList = (index: number, propertyName: string) => {
    const items = getValues(propertyName) as Array<any>;

    ModalConfirmacaoExcluir({
      callback: async (ok: boolean) => {
        if (ok) {
          let indexPrincipal = 0;

          items.forEach((value: any, indexCodigo: number) => {
            if (value.Principal) {
              indexPrincipal = indexCodigo;
            }
          });

          if (indexPrincipal > index) {
            handleCSCPrincipalChange(indexPrincipal - 1);
          }

          items.splice(index, 1);
          setValue(propertyName, [...items]);
        }
      },
    });
  };

  const handleDownloadCertificado = async () => {
    const { cnpj } = getValues();
    const dataViewModel = {
      arquivo: null,
      nomeArquivo: certificadoDigital.NomeArquivo,
      senhaCertificado: certificadoDigital.SenhaCertificado,
      cnpj,
    };

    const response = await api.post<void, ResponseApi>(
      ConstanteEnderecoWebservice.CERTIFICADODIGITAL_OBTER,
      dataViewModel
    );

    const dados = response.dados as any;
    if (response.sucesso) {
      const blob = new Blob([dados], { type: 'application/x-pkcs12' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      const fileName = `${certificadoDigital.NomeArquivo}.pfx`;
      link.download = fileName;
      link.click();
    }
  };

  const handleModalCertificadoSubmit = () => {
    setCertificadoDigitalModalIsOpen(false);
    setSenhaCertificadoValido(!senhaCertificadoValido);
  };

  const handleRemoverCertificado = () => {
    setCertificadoDigitalModalIsOpen(false);
    setSenhaCertificadoValido(!senhaCertificadoValido);
    setCertificadoDigital({} as CertificadoDigitalRetorno);
  };

  const getCnpjData = useCallback(
    (data: CnpjResponse) => {
      if (data.nome) setValue('razaoSocial', data.nome);
      else setValue('razaoSocial', null);

      if (data.fantasia) setValue('fantasia', data.fantasia);
      else setValue('fantasia', null);

      if (data.bairro) setValue('bairro', data.bairro);

      if (data.cep) setValue('cep', cepMask(data.cep));

      if (data.complemento) setValue('complemento', data.complemento);
      else setValue('complemento', null);

      if (data.email) setValue('email', data.email);

      if (data.logradouro) setValue('logradouro', data.logradouro);

      if (data.municipio) {
        consultarViaCep(data.cep, (sucesso: boolean, dados: any) => {
          if (sucesso && enderecoCompletoRef.current) {
            enderecoCompletoRef.current.getCepData(dados);
          }
        });
      }

      if (data.numero) setValue('numero', data.numero);

      if (data.telefone) setValue('telefone', data.telefone);
      else setValue('telefone', null);
    },
    [setValue]
  );

  const latestProps = useRef({ setValue });
  useEffect(() => {
    latestProps.current = { setValue };
  });

  useEffect(() => {
    const handleGetRegraFiscalOptions = async () => {
      setRegraFiscalOptionsIsLoading(true);

      const response = await api.get<void, ResponseApi<OptionTypeBase[]>>(
        ConstanteEnderecoWebservice.REGRA_FISCAL_LISTAR_SELECT
      );

      if (response?.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }

      if (response?.sucesso) {
        setRegraFiscalOptions(
          response.dados.map((regraFiscal: any) => {
            return {
              label: regraFiscal.nome,
              value: regraFiscal.id,
            } as OptionTypeBase;
          })
        );
        if (!isAlterar) {
          latestProps.current.setValue('regraFiscalPadrao', {
            label: response.dados[0].nome,
            value: response.dados[0].id,
          });
        }
      } else setRegraFiscalOptions([]);

      setRegraFiscalOptionsIsLoading(false);
    };

    handleGetRegraFiscalOptions();
  }, [isAlterar]);

  useEffect(() => {
    if (certificadoDigital.NomeArquivo) {
      setSenhaCertificadoValido(true);
    }
  }, [certificadoDigital]);

  const Informacoes = ({ mensagem }: any) => {
    return (
      <OverlayTriggerPadrao
        placement="auto"
        delay={{ show: 200, hide: 300 }}
        overlay={({ className, ...props }: any) => {
          return <Tooltip {...props}>{mensagem}</Tooltip>;
        }}
      >
        <InfoIcon style={{ marginLeft: '5px' }} />
      </OverlayTriggerPadrao>
    );
  };

  if (!primeiraValidacaoDoCPFCNPJ.current) {
    const cpfCnpj = getValues('cnpj');
    if (cpfCnpj && cpfCnpj.length > 14) {
      primeiraValidacaoDoCPFCNPJ.current = true;
      setTimeout(() => {
        setIsCpf(false);
      }, 50);
    }

    if (cpfCnpj) {
      primeiraValidacaoDoCPFCNPJ.current = true;
    }
  }

  return (
    <>
      <ModalCertificadoDigital
        show={certificadoDigitalModalIsOpen}
        onHide={() => {
          setCertificadoDigitalModalIsOpen(false);
        }}
        onSubmit={handleModalCertificadoSubmit}
        setValuesLoja={setValue}
        readonly={readonly}
      />
      <Form.Row>
        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
          <InputCpfCnpj
            id="cnpj"
            name="cnpj"
            label={t('CPF / CNPJ')}
            placeholder={t('Digite o CPF ou o CNPJ')}
            control={control}
            showSearchIcon={false}
            error={errors.cnpj}
            required
            maskTypeChange={(isCpfValue: boolean) => {
              setIsCpf(isCpfValue);
            }}
            setError={setError}
            disabled={readonly}
            getCnpjData={getCnpjData}
          />
        </Col>
        <Col xl={8} lg={8} md={6} sm={12} xs={12}>
          <InputPadrao
            type="text"
            id="razaoSocial"
            label={isCpf ? t('Nome') : t('Razão social')}
            autoFocus
            required
            placeholder={t('Digite a razão social')}
            maxLength={60}
            error={errors.razaoSocial}
            disabled={readonly}
            control={control}
          />
        </Col>
        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
          <InputPadrao
            type="text"
            id="fantasia"
            // required={!isCpf}
            label={isCpf ? t('Apelido') : t('Nome fantasia')}
            placeholder={t('Digite o nome fantasia')}
            maxLength={60}
            error={errors.fantasia}
            disabled={readonly}
            control={control}
          />
        </Col>
        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
          <InputRgIeIsento
            type="text"
            id="ie"
            name="ie"
            setValue={setValue}
            isRg={false}
            alterarMascaraRg
            label={isCpf && isValueRg ? 'RG' : 'Inscrição estadual'}
            placeholder={
              isCpf && isValueRg ? 'Digite o RG' : 'Digite a inscrição estadual'
            }
            validarRg={isCpf && isValueRg}
            control={control}
            setError={setError}
            error={errors.ie}
            disabled={readonly}
          />
        </Col>
        <Col xl={4} lg={4} md={12} sm={12} xs={12}>
          <InputPadrao
            type="text"
            id="contato"
            label={t('Responsável')}
            placeholder={t('Digite o responsável')}
            maxLength={30}
            error={errors.contato}
            disabled={readonly}
            control={control}
          />
        </Col>
        <Col>
          <AccordionPadrao title="Dados da empresa" defaultOpen>
            <Container fluid style={{ paddingRight: '0', paddingLeft: '0' }}>
              <EnderecoCompleto
                ref={enderecoCompletoRef}
                errors={errors}
                setError={setError}
                register={register}
                control={control}
                readonly={readonly}
                setValue={setValue}
                getValue={getValues}
                cepCampoId="cep"
                logradouroCampoId="logradouro"
                numeroCampoId="numero"
                complementoCampoId="complemento"
                bairroCampoId="bairro"
                cidadeCampoId="cidade"
                estadoCampoId="estado"
                paisCampoId="pais"
                cepCampoRequired
                logradouroCampoRequired
                numeroCampoRequired
                bairroCampoRequired
                cidadeCampoRequired
                padding="0 0 45px 0"
              />
              <Form.Row>
                <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                  <InputTelefoneWhatsapp
                    inputMode="numeric"
                    type="tel"
                    id="celular"
                    name="celular"
                    label={t('Celular')}
                    placeholder="(00) 0000-0000"
                    control={control}
                    error={errors.celular}
                    disabled={readonly}
                    setValue={setValue}
                  />
                </Col>
                <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                  <InputTelefoneWhatsapp
                    inputMode="numeric"
                    type="tel"
                    id="telefone"
                    name="telefone"
                    label={t('Telefone')}
                    placeholder="(00) 0000-0000"
                    control={control}
                    maxLength={15}
                    error={errors.telefone}
                    disabled={readonly}
                    setValue={setValue}
                  />
                </Col>
                <Col xl={3} lg={6} md={6} sm={6} xs={12}>
                  <InputNull
                    type="text"
                    id="sitePerfil"
                    name="sitePerfil"
                    label={t('Site ou perfil (URL)')}
                    placeholder={t('Digite o site ou perfil')}
                    maxLength={80}
                    control={control}
                    error={errors.sitePerfil}
                    disabled={readonly}
                  />
                </Col>
                <Col xl={3} lg={12} md={6} sm={6} xs={12}>
                  <InputNull
                    type="text"
                    id="email"
                    name="email"
                    label={t('E-mail')}
                    placeholder={t('Digite o endereço de e-mail')}
                    maxLength={80}
                    control={control}
                    error={errors.email}
                    disabled={readonly}
                  />
                </Col>
              </Form.Row>
            </Container>
          </AccordionPadrao>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <AccordionPadrao
            title="Dados da contabilidade"
            id="accordion-dados-contabilidade"
          >
            <Container fluid style={{ paddingRight: '0', paddingLeft: '0' }}>
              <Form.Row>
                <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                  <InputPadrao
                    type="text"
                    id="contabilistaNome"
                    label={t('Contabilista responsável')}
                    placeholder={t('Digite o nome do contabilista')}
                    maxLength={60}
                    error={errors.contabilistaNome}
                    disabled={readonly}
                    control={control}
                  />
                </Col>
                <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                  <InputCpfCnpj
                    id="contabilistaCpf"
                    name="contabilistaCpf"
                    label={t('CPF do contabilista')}
                    placeholder={t('Digite o CPF do contabilista')}
                    control={control}
                    error={errors.contabilistaCpf}
                    setError={setError}
                    onlyCpf
                    disabled={readonly}
                  />
                </Col>
                <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                  <InputNull
                    type="text"
                    id="contabilistaCrc"
                    name="contabilistaCrc"
                    label={t('CRC')}
                    placeholder={t('Digite o CRC')}
                    maxLength={20}
                    control={control}
                    error={errors.contabilistaCrc}
                    disabled={readonly}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                  <InputCpfCnpj
                    id="contabilistaCnpj"
                    name="contabilistaCnpj"
                    label={t('CNPJ do escritório do contabilista')}
                    showSearchIcon={false}
                    placeholder={t(
                      'Digite o CNPJ do escritório do contabilista'
                    )}
                    control={control}
                    error={errors.contabilistaCnpj}
                    setError={setError}
                    onlyCnpj
                    disabled={readonly}
                  />
                </Col>
                <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                  <InputPadrao
                    id="contabilistaEmail"
                    name="contabilistaEmail"
                    label={t('E-mail')}
                    placeholder={t('Digite o endereço de e-mail')}
                    maxLength={80}
                    control={control}
                    error={errors.contabilistaEmail}
                    disabled={readonly}
                    required={documentosFiscaisAutomaticamenteWatch}
                  />
                </Col>
                <Col xl={2} lg={2} md={6} sm={6} xs={12}>
                  <InputTelefoneWhatsapp
                    inputMode="numeric"
                    type="tel"
                    id="contabilistaCelular"
                    name="contabilistaCelular"
                    label={t('Celular')}
                    placeholder="(00) 0000-0000"
                    control={control}
                    error={errors.contabilistaCelular}
                    disabled={readonly}
                    setValue={setValue}
                  />
                </Col>
                <Col xl={2} lg={2} md={6} sm={6} xs={12}>
                  <InputTelefoneWhatsapp
                    inputMode="numeric"
                    type="tel"
                    id="contabilistaTelefone"
                    name="contabilistaTelefone"
                    label={t('Telefone')}
                    placeholder="(00) 0000-0000"
                    control={control}
                    maxLength={14}
                    error={errors.contabilistaTelefone}
                    disabled={readonly}
                    setValue={setValue}
                  />
                </Col>
              </Form.Row>
              <EnderecoCompleto
                errors={errors}
                setError={setError}
                register={register}
                control={control}
                readonly={readonly}
                setValue={setValue}
                getValue={getValues}
                cepCampoId="contabilistaCep"
                logradouroCampoId="contabilistaLogradouro"
                numeroCampoId="contabilistaNumero"
                complementoCampoId="contabilistaComplemento"
                bairroCampoId="contabilistaBairro"
                cidadeCampoId="contabilistaCidade"
                estadoCampoId="contabilistaEstado"
                paisCampoId="contabilistaPais"
                padding="45px 0"
              />
              <Form.Row>
                <Col
                  xl={2}
                  lg={4}
                  md={6}
                  sm={6}
                  xs={12}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <CheckBoxDefault
                    id="obterXml"
                    name="obterXml"
                    label=""
                    readonly={readonly}
                    control={control}
                    defaultValue={false}
                  />
                  <Label
                    id="label-for-obterXml"
                    htmlFor="obterXml"
                    style={{ marginLeft: '5px' }}
                  >
                    {t('Autorização para obter XML')}
                  </Label>
                </Col>

                <Col
                  xl={3}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <CheckBoxDefault
                    id="documentosFiscaisAutomaticamente"
                    name="documentosFiscaisAutomaticamente"
                    label=""
                    readonly={readonly}
                    control={control}
                    defaultValue={false}
                  />
                  <Label
                    id="label-for-documentosFiscaisAutomaticamente"
                    htmlFor="documentosFiscaisAutomaticamente"
                    style={{ marginLeft: '5px' }}
                  >
                    {t('Enviar documentos fiscais automaticamente')}
                  </Label>
                  <Informacoes mensagem="No primeiro dia do mês o contador receberá no e-mail informado acima um link para baixar os arquivos fiscais, é possível acompanhar a exportação pela tela Fiscal > Exportação XML. Marcando esta opção, é obrigatório informar o e-mail do contabilista." />
                </Col>
              </Form.Row>
            </Container>
          </AccordionPadrao>
        </Col>
      </Form.Row>
      {planoTeste !== planoAtual && (
        <Form.Row>
          <Col>
            <AccordionPadrao
              title="Informações fiscais"
              id="accordion-informacoes-fiscais"
            >
              <Container fluid style={{ paddingRight: '0', paddingLeft: '0' }}>
                <Form.Row>
                  <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                    <SelectPadrao
                      id="regimeTributarioSelect"
                      name="regimeTributarioSelect"
                      label={t('Regime tributário')}
                      required
                      defaultValue={getOptionByEnum(
                        RegimeTributarioEnum,
                        RegimeTributarioEnum.SIMPLES_NACIONAL
                      )}
                      control={control}
                      readonly={readonly}
                      controlledByObject
                      onChange={(newValue: any) => {
                        if (newValue.value > 2) {
                          setIsSimplesNacional(false);
                          setValue('icmsAproveitamentoAliquota', 0.0);
                        } else {
                          setIsSimplesNacional(true);
                          setValue('aliquotaPadraoPIS', 0.0);
                          setValue('aliquotaPadraoCofins', 0.0);
                        }
                        setBloqueioInicial(false);
                      }}
                      error={errors.regimeTributarioSelect}
                      options={getOptionsByEnum(RegimeTributarioEnum)}
                      infoText="O Regime tributário é usado para determinar as informações que são necessárias para emissão do documento fiscal. Se esse campo for alterado, é necessário conferir as informações da Regra Fiscal."
                    />
                  </Col>

                  <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                    <BootstrapNumberInput
                      id="icmsAproveitamentoAliquota"
                      name="icmsAproveitamentoAliquota"
                      label={t('Alíquota do simples nacional (%)')}
                      precision={5}
                      scale={2}
                      required={isSimplesNacional}
                      defaultValue={0}
                      leftElement="%"
                      control={control}
                      error={errors.IcmsAproveitamentoAliquota}
                      disabled={
                        readonly || !isSimplesNacional || bloqueioInicial
                      }
                    />
                  </Col>
                  <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                    <SelectPadrao
                      id="tipoAmbienteFiscal"
                      name="tipoAmbienteFiscal"
                      label={t('Ambiente para emissão de nota fiscal')}
                      placeholder="Selecione"
                      defaultValue={1}
                      control={control}
                      error={errors.tipoAmbienteFiscal}
                      options={Object.entries(
                        AmbienteFiscalEnum.properties
                      ).map((value: any) => {
                        return (
                          {
                            label: t(value[1].name),
                            value: value[1].value,
                          } || {}
                        );
                      })}
                      readonly={readonly}
                      required
                    />
                  </Col>
                </Form.Row>

                <Form.Row>
                  <Col md={4} xl={4}>
                    <SelectPadrao
                      id="codigoProdutoFiscal"
                      name="codigoProdutoFiscal"
                      label={t('Código do produto fiscal')}
                      placeholder="Selecione"
                      defaultValue={1}
                      control={control}
                      error={errors.finalidade}
                      options={Object.entries(
                        CodigoProdutoFiscalEnum.properties
                      ).map((value: any) => {
                        return (
                          {
                            label: t(value[1].name),
                            value: value[1].value,
                          } || {}
                        );
                      })}
                      readonly={readonly}
                      required
                      infoText="Código usado para identificar o produto na nota fiscal"
                    />
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col xs={12}>
                    <HeaderInformacoesFiscais>
                      <SpanInformacoesFiscais
                        style={{
                          display: 'flex',
                        }}
                      >
                        Informações usadas como base para o cadastro de produtos
                        <Informacoes mensagem="Estas informações serão preenchidas automaticamente no cadastro do produto e podem ser alteradas se for necessário." />
                      </SpanInformacoesFiscais>
                    </HeaderInformacoesFiscais>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                    <SelectPadrao
                      id="regraFiscalPadrao"
                      name="regraFiscalPadrao"
                      label={t('Regra fiscal')}
                      noSelectedText={t('Selecione')}
                      defaultValue={undefined}
                      control={control}
                      required
                      error={errors.regraFiscalPadrao}
                      options={regraFiscalOptions}
                      readonly={readonly}
                      isDisabled={regraFiscalOptionsIsLoading}
                      isLoading={regraFiscalOptionsIsLoading}
                      controlledByObject
                    />
                  </Col>
                  <Col xl={4} lg={3} md={3} sm={6} xs={12}>
                    <BootstrapNumberInput
                      id="aliquotaPadraoPIS"
                      name="aliquotaPadraoPIS"
                      label={t('Alíquota PIS (%)')}
                      precision={5}
                      scale={2}
                      defaultValue={0}
                      leftElement="%"
                      control={control}
                      error={errors.AliquotaPadraoPIS}
                      disabled={
                        readonly || isSimplesNacional || bloqueioInicial
                      }
                    />
                  </Col>
                  <Col xl={4} lg={3} md={3} sm={6} xs={12}>
                    <BootstrapNumberInput
                      id="aliquotaPadraoCofins"
                      name="aliquotaPadraoCofins"
                      label={t('Alíquota COFINS (%)')}
                      precision={5}
                      scale={2}
                      defaultValue={0}
                      leftElement="%"
                      control={control}
                      error={errors.AliquotaPadraoCofins}
                      disabled={
                        readonly || isSimplesNacional || bloqueioInicial
                      }
                    />
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col xs={12} style={{ paddingBottom: '0px' }}>
                    <HStack w="100%" bg="var(--sti-ck-colors-gray-50)" p="11px">
                      <span
                        style={{
                          textAlign: 'left',
                          font: 'normal normal 600 13px/16px Nunito',
                          letterSpacing: '0px',
                          color: 'var(--black)',
                          opacity: '1',
                        }}
                      >
                        Escolha o tipo de certificado digital:
                      </span>

                      <RadioButtonPadrao
                        name="tipoCertificado"
                        control={control}
                        ml="5"
                        isDisabled={readonly}
                      >
                        <Radio value={TipoCertificadoEnum.A1} marginBottom="0">
                          {TipoCertificadoEnum.properties[0].name}
                        </Radio>
                        <Radio value={TipoCertificadoEnum.A3} marginBottom="0">
                          {TipoCertificadoEnum.properties[1].name}
                        </Radio>
                      </RadioButtonPadrao>
                    </HStack>
                  </Col>
                </Form.Row>
                {tipoCertificadoWatch === TipoCertificadoEnum.A1 && (
                  <Form.Row>
                    {!senhaCertificadoValido && (
                      <Col xl={12} style={{ paddingBottom: '2rem' }}>
                        <ButtonAdicionarCertificado
                          type="button"
                          id="adicionar-contato-adicional"
                          disabled={readonly}
                          onClick={() => {
                            setCertificadoDigitalModalIsOpen(true);
                          }}
                        >
                          Adicionar arquivo
                        </ButtonAdicionarCertificado>
                        <SpanFileName>
                          Nenhum certificado cadastrado
                        </SpanFileName>
                      </Col>
                    )}
                    {senhaCertificadoValido && certificadoDigital && (
                      <Col xl={12} style={{ paddingBottom: '2rem' }}>
                        <Card
                          style={{
                            borderRadius: '5px',
                            padding: '10px',
                          }}
                        >
                          <Form.Row>
                            <Col
                              xl={1}
                              lg={1}
                              md={2}
                              sm={2}
                              xs={4}
                              style={{
                                display: 'flex',
                                margin: 'auto',
                                justifyContent: 'center',
                              }}
                            >
                              <CertificadoDigitalIcon
                                style={{
                                  fontSize: '70px',
                                  color: `${blue500}`,
                                }}
                              />
                            </Col>
                            <Col
                              xl={4}
                              lg={4}
                              md={3}
                              sm={3}
                              xs={8}
                              style={{ margin: 'auto' }}
                            >
                              <SpanFileDetails style={{ fontWeight: 'bold' }}>
                                Arquivo:
                              </SpanFileDetails>

                              {certificadoDigital.NomeArquivo}
                            </Col>
                            <Col
                              xl={3}
                              lg={3}
                              md={3}
                              sm={3}
                              xs={6}
                              style={{ margin: 'auto', textAlign: 'center' }}
                            >
                              <SpanFileDetails style={{ fontWeight: 'bold' }}>
                                Data de validade:
                              </SpanFileDetails>

                              {(certificadoDigital.DataValidade &&
                                formatUTCToLocateDateTime(
                                  certificadoDigital.DataValidade
                                ).substring(0, 10)) ??
                                '-'}
                            </Col>
                            {certificadoDigital.Uploaded && (
                              <Col
                                xl={2}
                                lg={2}
                                md={2}
                                sm={2}
                                xs={4}
                                style={{ textAlign: 'center', margin: 'auto' }}
                              >
                                <Button
                                  variant="link"
                                  id="downloadCertificado"
                                  onClick={handleDownloadCertificado}
                                  style={{
                                    color: `${blue500}`,
                                    letterSpacing: '0',
                                    fontWeight: 600,
                                    fontSize: '12px',
                                    whiteSpace: 'normal',
                                  }}
                                >
                                  Fazer download do certificado
                                </Button>
                              </Col>
                            )}
                            {!certificadoDigital.Uploaded && (
                              <Col
                                xl={2}
                                lg={2}
                                md={2}
                                sm={2}
                                xs={4}
                                style={{ textAlign: 'center', margin: 'auto' }}
                              >
                                &nbsp;
                              </Col>
                            )}

                            <Col
                              xl={1}
                              lg={1}
                              md={1}
                              sm={1}
                              xs={2}
                              style={{
                                display: 'flex',
                                margin: 'auto',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                variant="link"
                                id="RemoverCertificado"
                                disabled={readonly}
                                onClick={() => {
                                  handleRemoverCertificado();
                                }}
                              >
                                <LixeiraIcon
                                  style={{
                                    fontSize: '20px',
                                    color: 'var(--sti-ck-colors-red-500)',
                                  }}
                                />
                              </Button>
                            </Col>
                          </Form.Row>
                        </Card>
                      </Col>
                    )}
                  </Form.Row>
                )}

                {tipoCertificadoWatch === TipoCertificadoEnum.A3 && (
                  <Form.Row>
                    <Col xl={12} style={{ paddingBottom: '1rem' }}>
                      <VStack align="stretch">
                        <Text
                          fontSize="18px"
                          fontWeight="bold"
                          color="primary.50"
                        >
                          Certificado A3:
                        </Text>
                        <HStack w="70%">
                          <Text color="gray.700" fontSize="14px">
                            {`Para configurar o certificado digital modelo A3 será
                          necessário baixar e instalar o nosso Módulo desktop.
                          Ele será o responsável por estabelecer a comunicação
                          entre o certificado instalado no seu computador e o
                          sistema ${nomeExibicao}.`}
                          </Text>
                        </HStack>
                        <VStack pt="24px" alignItems="left" pl="10px">
                          <Text color="primary.50" fontSize="14px">
                            01 - Insira o certificado (pendrive ou cartão) no
                            seu computador.
                          </Text>
                          <Box
                            pb="24px"
                            pl="2.5%"
                            w="80%"
                            fontSize="14px"
                            color="gray.700"
                          >
                            O modelo A3 funciona apenas no computador onde o
                            certificado está plugado. Após confirmar que o
                            dispositivo está devidamente conectado, clique em
                            iniciar a instalação.
                          </Box>
                          <Text color="primary.50" fontSize="14px">
                            02 - Download e instalação.
                          </Text>
                          <Box
                            pb="24px"
                            pl="2.5%"
                            w="80%"
                            fontSize="14px"
                            color="gray.700"
                          >
                            Clique no botão abaixo para baixar o aplicativo e
                            iniciar a instalação. O Módulo desktop é um
                            aplicativo que funciona em segundo plano no seu
                            computador. Ele inicia automaticamente ao ligar a
                            maquina e você não precisará realizar nenhuma ação.
                            (É possível encontrá-lo na barra de ferramentas do
                            windows, no canto inferior direito)
                          </Box>
                          <Flex
                            pb="40px"
                            pl="2.5%"
                            alignItems="center"
                            gap="38px"
                            flexDir={['column', 'column', 'row']}
                            color="gray.700"
                          >
                            <ButtonChakra
                              color="white"
                              leftIcon={
                                isLargerThan500 ? (
                                  <Icon
                                    ml="10px"
                                    boxSize="14px"
                                    as={DownloadModuloDesktopIcon}
                                  />
                                ) : undefined
                              }
                              textAlign="center"
                              bg="primary.50"
                              colorScheme="primary"
                              height="32px"
                              fontSize="14px"
                              fontWeight="light"
                              onClick={() =>
                                window.open(
                                  'https://zendarappprod.blob.core.windows.net/zendar-desktop/Desktop/M%C3%B3dulo%20Desktop%20Setup%202.2.0%20x64.exe'
                                )
                              }
                            >
                              Download
                              {isLargerThan500
                                ? ' do módulo desktop (64 bits)'
                                : ' versão para 64-bits'}
                            </ButtonChakra>
                            <Link
                              href="https://zendarappprod.blob.core.windows.net/zendar-desktop/Desktop/M%C3%B3dulo%20Desktop%20Setup%202.2.0%20x86.exe"
                              textDecor="underline"
                            >
                              Download{' '}
                              {isLargerThan500
                                ? ' da versão para 32-bits'
                                : ' versão para 32-bits'}
                            </Link>
                          </Flex>
                          <Text color="primary.50" fontSize="14px">
                            03 - Gerar código de configuração.
                          </Text>
                          <Box
                            pb="24px"
                            pl="2.5%"
                            w="80%"
                            fontSize="14px"
                            color="gray.700"
                          >
                            {`Após iniciar a instalação, será solicitado pelo app o
                          código de ativação. Clique no link abaixo para gerar o
                          código. Copie e informe na configuração inicial do
                          Módulo desktop.`}
                          </Box>
                          <Box
                            pl="2.5%"
                            w={['full', 'full', '187px']}
                            color="gray.700"
                          >
                            <ButtonChakra
                              variant="solid"
                              colorScheme="secondary"
                              color="black"
                              onClick={() => {
                                if (getValues('id')) {
                                  setModalPinA3IsOpen(true);
                                } else {
                                  toast.warning(
                                    'Só será possível gerar o código após inserir a loja.'
                                  );
                                }
                              }}
                            >
                              Gerar código de ativação
                            </ButtonChakra>
                          </Box>
                        </VStack>
                      </VStack>
                    </Col>
                  </Form.Row>
                )}

                <Form.Row>
                  <Col xs={12}>
                    <HeaderInformacoesFiscais>
                      <SpanInformacoesFiscais
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        Nota fiscal eletrônica (NF-e) &nbsp;&nbsp;&nbsp;
                        <CheckBoxDefaultMappedField
                          id="emitirNFe"
                          name="emitirNFe"
                          label=""
                          campoDinamicoId="emitirNFe"
                          control={control}
                          defaultValue={{
                            id: 'emitirNFe',
                            selecionado: false,
                          }}
                          readonly={readonly}
                          handleChange={() => {
                            const { emitirNFe } = getValues();

                            setNFeReadOnly(!emitirNFe.selecionado);
                          }}
                        />
                      </SpanInformacoesFiscais>
                    </HeaderInformacoesFiscais>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col xl={4} lg={6} md={6} sm={6} xs={12}>
                    <BootstrapNumberInput
                      id="nfeNumeroSerie"
                      name="nfeNumeroSerie"
                      label={t('Número de série')}
                      placeholder={t('Digite o número de série')}
                      precision={10}
                      scale={0}
                      defaultValue={1}
                      control={control}
                      error={!NFeReadOnly && errors.nfeNumeroSerie}
                      disabled={readonly || NFeReadOnly}
                    />
                  </Col>
                  <Col xl={4} lg={6} md={6} sm={6} xs={12}>
                    <BootstrapNumberInput
                      id="nfeNumeracaoInicial"
                      name="nfeNumeracaoInicial"
                      label={t('Numeração inicial')}
                      precision={10}
                      scale={0}
                      defaultValue={1}
                      control={control}
                      error={!NFeReadOnly && errors.nfeNumeracaoInicial}
                      disabled={readonly || NFeReadOnly}
                    />
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col xs={12}>
                    <HeaderInformacoesFiscais>
                      <SpanInformacoesFiscais
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        Nota fiscal de consumidor eletrônica (NFC-e)
                        &nbsp;&nbsp;&nbsp;
                        <CheckBoxDefaultMappedField
                          id="emitirNFCe"
                          name="emitirNFCe"
                          label=""
                          campoDinamicoId="emitirNFCe"
                          control={control}
                          defaultValue={{
                            id: 'emitirNFCe',
                            selecionado: false,
                          }}
                          readonly={readonly || isCpf}
                          handleChange={() => {
                            const { emitirNFCe } = getValues();

                            setNFCeReadOnly(!emitirNFCe.selecionado);
                          }}
                        />
                      </SpanInformacoesFiscais>
                    </HeaderInformacoesFiscais>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col xl={4} lg={6} md={6} sm={6} xs={12}>
                    <BootstrapNumberInput
                      id="nfceNumeroSerie"
                      name="nfceNumeroSerie"
                      label={t('Número de série')}
                      precision={10}
                      scale={0}
                      defaultValue={1}
                      control={control}
                      error={!NFCeReadOnly && errors.nfceNumeroSerie}
                      disabled={readonly || NFCeReadOnly}
                    />
                  </Col>
                  <Col xl={4} lg={6} md={6} sm={6} xs={12}>
                    <BootstrapNumberInput
                      id="nfceNumeracaoInicial"
                      name="nfceNumeracaoInicial"
                      label={t('Numeração inicial')}
                      precision={10}
                      scale={0}
                      defaultValue={1}
                      control={control}
                      error={!NFeReadOnly && errors.nfceNumeracaoInicial}
                      disabled={readonly || NFCeReadOnly}
                    />
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col lg={12} style={{ paddingBottom: 0 }}>
                    <span
                      style={{
                        color: 'var(--black)',
                        padding: '0',
                        fontWeight: 600,
                        fontSize: '13px',
                        float: 'left',
                      }}
                    >
                      Código de segurança do contribuinte (CSC)
                    </span>
                    <Button
                      id="adicionar-csc"
                      variant="link"
                      onClick={() => {
                        setIndexItemAlteracao(-1);
                        setCSCModalIsOpen(true);
                      }}
                      disabled={readonly || NFCeReadOnly}
                      style={{
                        color: `${blue500}`,
                        padding: '0',
                        fontWeight: 600,
                        fontSize: '13px',
                        float: 'right',
                      }}
                    >
                      Adicionar CSC
                    </Button>
                  </Col>

                  <Col lg={12} style={{ paddingTop: 0 }} id="codigos-seguranca">
                    <TableCampoFormularioPadraoStyle
                      fieldName="codigosSegurancaContribuinte"
                      defaultColumnOrder=""
                      headerColumns={[
                        {
                          name: 'principal',
                          displayName: 'Principal',
                          isOrderable: false,
                          width: 'auto',
                        },
                        {
                          name: 'CSC',
                          displayName: 'CSC',
                          isOrderable: false,
                          width: 'auto',
                        },
                        {
                          name: 'Token',
                          displayName: 'CSC token',
                          isOrderable: false,
                          width: 'auto',
                        },
                        {
                          name: 'CSCHomologacao',
                          displayName: 'CSC homologação',
                          isOrderable: false,
                          width: 'auto',
                        },
                        {
                          name: 'TokenHomologacao',
                          displayName: 'CSC token homologação',
                          isOrderable: false,
                          width: 'auto',
                        },
                        {
                          name: 'acoes',
                          displayName: 'Ações',
                          isOrderable: false,
                          width: '38px',
                        },
                      ]}
                      emptyValuesText={t('Nenhum CSC cadastrado')}
                      control={control}
                      isLoading={false}
                    >
                      {({ tableValues }) => (
                        <>
                          {tableValues &&
                            tableValues.map((tableValue, index) => {
                              return (
                                // eslint-disable-next-line react/no-array-index-key
                                <tr key={index}>
                                  <td>
                                    <CheckBoxDefaultMappedField
                                      id={`csc[${index}]`}
                                      name={`csc[${index}]`}
                                      label=""
                                      control={control}
                                      defaultValue={{
                                        id: `csc[${index}]`,
                                        selecionado:
                                          tableValue.Principal || false,
                                      }}
                                      campoDinamicoId={`csc[${index}]`}
                                      readonly={readonly || NFCeReadOnly}
                                      handleChange={() =>
                                        handleCSCPrincipalChange(index)
                                      }
                                    />
                                  </td>
                                  <td>{tableValue.CSC}</td>
                                  <td>{tableValue.Token}</td>
                                  <td>{tableValue.CSCHomologacao}</td>
                                  <td>{tableValue.TokenHomologacao}</td>
                                  <td>
                                    {!readonly && !NFCeReadOnly && (
                                      <ActionsMenu
                                        id="mostrarMais"
                                        items={[
                                          {
                                            content: t('Editar'),

                                            onClick: () => {
                                              setIndexItemAlteracao(index);
                                              setCSCModalIsOpen(true);
                                            },
                                          },
                                          {
                                            content: t('Remover'),
                                            isDisabled: readonly,
                                            onClick: () =>
                                              removerItemList(
                                                index,
                                                'codigosSegurancaContribuinte'
                                              ),
                                          },
                                        ]}
                                        menuZIndex="modal"
                                      />
                                    )}
                                    {(readonly || NFCeReadOnly) && (
                                      <ButtonComOpcoes
                                        id="mostrarMais"
                                        dropdownItems={[
                                          {
                                            title: t('Visualizar'),

                                            onClick: () => {
                                              setIndexItemAlteracao(index);
                                              setCSCModalIsOpen(true);
                                            },
                                          },
                                        ]}
                                      />
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                        </>
                      )}
                    </TableCampoFormularioPadraoStyle>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col
                    xl={5}
                    lg={7}
                    md={9}
                    sm={12}
                    xs={12}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <CheckBoxDefaultMappedField
                      id="exigirCNPJCPF"
                      name="exigirCNPJCPF"
                      label=""
                      control={control}
                      defaultValue={false}
                      campoDinamicoId="exigirCNPJCPF"
                      readonly={readonly || NFCeReadOnly}
                      handleChange={() => {
                        const { exigirCNPJCPF } = getValues();
                        setBloqueioValorCpf(exigirCNPJCPF.selecionado);
                        if (!exigirCNPJCPF.selecionado) {
                          setValue('nfceValorExigenciaCNPJCPF', 0.0);
                        }
                      }}
                    />
                    <Label
                      id="label-for-exigirCNPJCPF"
                      htmlFor="exigirCNPJCPF"
                      style={{ marginLeft: '5px', fontSize: '12px' }}
                    >
                      {t(
                        'Exigir a informação de CPF ou CNPJ do cliente para vendas com valor à partir de'
                      )}
                    </Label>
                    <Informacoes mensagem="Consulte o seu contabilista para saber se essa exigência se aplica a sua UF." />
                  </Col>
                  <Col xl={4} lg={5} md={3} sm={6} xs={12}>
                    <BootstrapNumberInput
                      id="nfceValorExigenciaCNPJCPF"
                      name="nfceValorExigenciaCNPJCPF"
                      label={t('')}
                      precision={10}
                      scale={2}
                      defaultValue={0}
                      leftElement="R$"
                      control={control}
                      error={errors.nfceValorExigenciaCNPJCPF}
                      disabled={readonly || !bloqueioValorCpf || NFCeReadOnly}
                    />
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col
                    xl={5}
                    lg={7}
                    md={9}
                    sm={12}
                    xs={12}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <CheckBoxDefaultMappedField
                      id="beneficiarioPagamento"
                      name="beneficiarioPagamento"
                      label=""
                      control={control}
                      defaultValue={false}
                      campoDinamicoId="beneficiarioPagamento"
                      readonly={readonly}
                    />
                    <Label
                      id="label-for-beneficiarioPagamento"
                      htmlFor="beneficiarioPagamento"
                      style={{ marginLeft: '5px', fontSize: '12px' }}
                    >
                      {t(
                        'Incluir no XML do documento fiscal o CNPJ do beneficiário do pagamento'
                      )}
                    </Label>
                  </Col>
                </Form.Row>
              </Container>
            </AccordionPadrao>
          </Col>
        </Form.Row>
      )}
      <Form.Row>
        <Col>
          <AccordionPadrao
            title="Logotipo para impressão de arquivos"
            id="accordion-logo-para-impressao"
          >
            <Container
              fluid
              style={{
                paddingRight: '0',
                paddingLeft: '0',
                paddingBottom: '15px',
              }}
            >
              <Form.Row>
                <Col xl={12}>
                  <span
                    style={{
                      textAlign: 'left',
                      font: 'normal normal normal 13px/20px Nunito',
                      letterSpacing: '0px',
                      color: 'var(--black)',
                      opacity: '1',
                      fontWeight: 'normal',
                    }}
                  >
                    Para personalizar notas, recibos e relatórios com sua
                    própria marca, insira as imagens seguindo as especificações
                    abaixo. É possível inserir até dois arquivos nos formatos
                    JPEG ou PNG. Arraste e solte a imagem na caixa de upload ou
                    clique no botão para adicionar um arquivo do seu computador.
                  </span>
                </Col>
              </Form.Row>
              <Form.Row>
                <ColLogoQuadrada
                  xl={5}
                  lg={5}
                  md={5}
                  sm={11}
                  xs={11}
                  style={{ margin: 'auto' }}
                >
                  <ColMargemQuadrada
                    style={{ paddingRight: '0', paddingLeft: '0' }}
                  >
                    <ImagePickerQuadradoOuRetangular
                      id="logoQuadrado"
                      name="logoQuadrado"
                      label={t('Formato quadrado')}
                      height={{
                        xs: '131px',
                        sm: '131px',
                        md: '131px',
                        lg: '150px',
                        xl: '150px',
                      }}
                      width={{
                        xs: '131px',
                        sm: '131px',
                        md: '131px',
                        lg: '150px',
                        xl: '150px',
                      }}
                      control={control}
                      widthCanvas={150}
                      heightCanvas={150}
                      error={errors.logoQuadrado}
                      readonly={readonly}
                    />
                    <DivDetalhesLogo>
                      <IconLogoQuadrada />

                      <div style={{ display: 'inline', marginLeft: '10px' }}>
                        <p style={{ marginTop: '10px' }}>
                          - Extensões permitidas: <b>JPEG ou PNG</b>
                        </p>
                        <br />
                        <p>
                          - Resolução recomendada: <b>150 x 150 px</b>
                        </p>
                      </div>
                    </DivDetalhesLogo>
                  </ColMargemQuadrada>
                </ColLogoQuadrada>
                <Col
                  xl={7}
                  lg={7}
                  md={7}
                  sm={11}
                  xs={11}
                  style={{ margin: 'auto' }}
                >
                  <ColMargemRetangular
                    style={{ paddingRight: '0', paddingLeft: '0' }}
                  >
                    <ImagePickerQuadradoOuRetangular
                      id="logoRetangular"
                      name="logoRetangular"
                      label={t('Formato retangular')}
                      height={{
                        xs: '130px',
                        sm: '130px',
                        md: '130px',
                        lg: '150px',
                        xl: '150px',
                      }}
                      width={{
                        xs: '300px',
                        sm: '300px',
                        md: '250px',
                        lg: '450px',
                        xl: '450px',
                      }}
                      widthCanvas={450}
                      heightCanvas={150}
                      control={control}
                      error={errors.logoRetangular}
                      readonly={readonly}
                    />

                    <DivDetalhesLogo>
                      <IconLogoRetangular />

                      <div style={{ display: 'inline', marginLeft: '10px' }}>
                        <p style={{ marginTop: '10px' }}>
                          - Extensões permitidas: <b>JPEG ou PNG</b>
                        </p>
                        <br />
                        <p>
                          - Resolução recomendada:<b> 450 x 150 px</b>
                        </p>
                      </div>
                    </DivDetalhesLogo>
                  </ColMargemRetangular>
                </Col>
              </Form.Row>
            </Container>
          </AccordionPadrao>
        </Col>
      </Form.Row>
      <Form.Row style={{ display: 'none' }}>
        <Col>
          <AccordionPadrao
            title="Tabelas de preços"
            id="accordion-tabela-preco"
          >
            <Container
              fluid
              style={{
                paddingRight: '0',
                paddingLeft: '0',
                paddingBottom: '15px',
              }}
            >
              <Form.Row>
                <Col xl={12}>
                  <span
                    style={{
                      textAlign: 'left',
                      font: 'normal normal normal 13px/20px Nunito',
                      letterSpacing: '0px',
                      color: 'var(--black)',
                      opacity: '1',
                      fontWeight: 'normal',
                    }}
                  >
                    Selecione qual tabela de preços usar nesta loja
                  </span>
                </Col>
              </Form.Row>
            </Container>
          </AccordionPadrao>
        </Col>
      </Form.Row>
      {CSCModalIsOpen && (
        <CSCModal
          show={CSCModalIsOpen}
          onHide={() => {
            setCSCModalIsOpen(false);
          }}
          readonly={readonly || NFCeReadOnly}
          getValuesLoja={getValues}
          setValuesLoja={setValue}
          indexItem={indexItemAlteracao}
        />
      )}

      {modalPinA3IsOpen && (
        <ModalPinA3
          show={modalPinA3IsOpen}
          onHide={() => {
            setModalPinA3IsOpen(false);
          }}
          lojaId={getValues('id')}
        />
      )}
    </>
  );
};
