const SituacaoTributariaPisCofinsEnum = {
  properties: {
    1: {
      name:
        '01 - Operação tributável (base de cálculo = valor da operação alíquota normal (cumulativo/não cumulativo))',
      value: 1,
    },
    2: {
      name:
        '02 - Operação tributável (base de cálculo = valor da operação (alíquota diferenciada))',
      value: 2,
    },
    3: {
      name:
        '03 - Operação tributável (base de cálculo = quantidade vendida x alíquota por unidade de produto)',
      value: 3,
    },
    4: {
      name: '04 - Operação tributável (tributação monofásica (alíquota zero))',
      value: 4,
    },
    5: {
      name: '05 - Operação tributável (substituição tributária)',
      value: 5,
    },
    6: { name: '06 - Operação tributável (alíquota zero)', value: 6 },
    7: { name: '07 - Operação isenta da contribuição', value: 7 },
    8: { name: '08 - Operação sem incidência da contribuição', value: 8 },
    9: { name: '09 - Operação com suspensão da contribuição', value: 9 },
    10: { name: '49 - Outras operações de saída', value: 49 },
    11: {
      name:
        '50 - Operação com direito a crédito - vinculada exclusivamente a receita tributada no mercado interno',
      value: 50,
    },
    12: {
      name:
        '51 - Operação com direito a crédito - vinculada exclusivamente a receita não tributada no mercado interno',
      value: 51,
    },
    13: {
      name:
        '52 - Operação com direito a crédito – vinculada exclusivamente a receita de exportação',
      value: 52,
    },
    14: {
      name:
        '53 - Operação com direito a crédito - vinculada a receitas tributadas e não-tributadas no mercado interno',
      value: 53,
    },
    15: {
      name:
        '54 - Operação com direito a crédito - vinculada a receitas tributadas no mercado interno e de exportação',
      value: 54,
    },
    16: {
      name:
        '55 - Operação com direito a crédito - vinculada a receitas não-tributadas no mercado interno e de exportação',
      value: 55,
    },
    17: {
      name:
        '56 - Operação com direito a crédito - vinculada a receitas tributadas e não-tributadas no mercado interno, e de exportação',
      value: 56,
    },
    18: {
      name:
        '60 - Crédito presumido - operação de aquisição vinculada exclusivamente a receita tributada no mercado interno',
      value: 60,
    },
    19: {
      name:
        '61 - Crédito presumido - operação de aquisição vinculada exclusivamente a receita não-tributada no mercado interno',
      value: 61,
    },
    20: {
      name:
        '62 - Crédito presumido - operação de aquisição vinculada exclusivamente a receita de exportação',
      value: 62,
    },
    21: {
      name:
        '63 - Crédito presumido - operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno',
      value: 63,
    },
    22: {
      name:
        '64 - Crédito presumido - operação de aquisição vinculada a receitas tributadas no mercado interno e de exportação',
      value: 64,
    },
    23: {
      name:
        '65 - Crédito presumido - operação de aquisição vinculada a receitas não-tributadas no mercado interno e de exportação',
      value: 65,
    },
    24: {
      name:
        '66 - Crédito presumido - operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno, e de exportação',
      value: 66,
    },
    25: { name: '67 - Crédito presumido - outras operações', value: 67 },
    26: {
      name: '70 - Operação de aquisição sem direito a crédito',
      value: 70,
    },
    27: { name: '71 - Operação de aquisição com isenção', value: 71 },
    28: { name: '72 - Operação de aquisição com suspensão', value: 72 },
    29: { name: '73 - Operação de aquisição a alíquota zero', value: 73 },
    30: {
      name: '74 - Operação de aquisição; sem incidência da contribuição',
      value: 74,
    },
    31: {
      name: '75 - Operação de aquisição por substituição tributária',
      value: 75,
    },
    32: { name: '98 - Outras operações de entrada', value: 98 },
    33: { name: '99 - Outras operações', value: 99 },
  },
};

export default SituacaoTributariaPisCofinsEnum;
