import React from 'react';
import {
  Stack,
  HStack,
  Text,
  Flex,
  Button,
  useMediaQuery,
} from '@chakra-ui/react';

import SelectPadrao from 'components/PDV/Select/SelectPadrao/Input';
import { ValueType } from 'react-select';
import OptionType from 'types/optionType';

const pageSizeOptions = [
  { label: '10', value: 10 },
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

interface PaginacaoProps {
  pageSize: number;
  handleChangePageSize: (newPageSize: number) => void;
  currentPage: number;
  lastPage: number;
  handleChangeCurrentPage: (newCurrentPage: number) => void;
  itemsTotalCount: number;
}

const Paginacao = ({
  pageSize,
  handleChangePageSize,
  currentPage,
  lastPage,
  handleChangeCurrentPage,
  itemsTotalCount,
}: PaginacaoProps) => {
  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

  return (
    <Stack
      direction={isLargerThan700 ? 'row' : 'column'}
      justifyContent={isLargerThan700 ? 'space-between' : 'center'}
      alignItems="center"
      spacing={isLargerThan700 ? 7 : 2}
      w="100%"
      h="75px"
    >
      {isLargerThan700 && (
        <HStack spacing={2}>
          <SelectPadrao
            size={isLargerThan700 ? 'sm' : 'xs'}
            options={pageSizeOptions}
            value={pageSizeOptions.find((option) => option.value === pageSize)}
            onChange={(option: ValueType<OptionType>) => {
              if (option) {
                handleChangePageSize((option as OptionType).value);
              }
            }}
            styles={{
              container: (base: any) => ({
                ...base,
                cursor: 'pointer',
              }),
              indicatorSeparator: (base: any) => ({ ...base, display: 'none' }),

              dropdownIndicator: (base: any) => ({ ...base, display: 'none' }),

              singleValue: (base: any) => ({
                ...base,
                position: 'relative',
                maxWidth: '100%',
                top: 'auto',
                transform: 'none',
              }),
            }}
            isClearable={false}
            isSearchable={false}
            menuPlacement="auto"
          />
          <Text color="gray.700">resultados por página</Text>
        </HStack>
      )}
      <Flex h={isLargerThan700 ? 'full' : 'auto'} alignItems="center">
        <Button
          borderRadius="sm"
          variant="ghost"
          colorScheme="primary"
          size={isLargerThan700 ? 'sm' : 'xs'}
          onClick={() => {
            handleChangeCurrentPage(1);
          }}
          isDisabled={currentPage === 1}
        >
          Início
        </Button>
        <Button
          borderRadius="sm"
          variant="ghost"
          colorScheme="primary"
          size={isLargerThan700 ? 'sm' : 'xs'}
          onClick={() => {
            handleChangeCurrentPage(currentPage - 1);
          }}
          isDisabled={currentPage === 1}
        >
          &laquo;
        </Button>
        {currentPage === lastPage && currentPage > 4 && (
          <Button
            borderRadius="sm"
            variant="ghost"
            colorScheme="primary"
            size={isLargerThan700 ? 'sm' : 'xs'}
            onClick={() => {
              handleChangeCurrentPage(currentPage - 4);
            }}
          >
            {currentPage - 4}
          </Button>
        )}
        {currentPage >= lastPage - 1 && currentPage > 3 && (
          <Button
            borderRadius="sm"
            variant="ghost"
            colorScheme="primary"
            size={isLargerThan700 ? 'sm' : 'xs'}
            onClick={() => {
              handleChangeCurrentPage(currentPage - 3);
            }}
          >
            {currentPage - 3}
          </Button>
        )}
        {currentPage >= 3 && (
          <Button
            borderRadius="sm"
            variant="ghost"
            colorScheme="primary"
            size={isLargerThan700 ? 'sm' : 'xs'}
            onClick={() => {
              handleChangeCurrentPage(currentPage - 2);
            }}
          >
            {currentPage - 2}
          </Button>
        )}
        {currentPage >= 2 && (
          <Button
            borderRadius="sm"
            variant="ghost"
            colorScheme="primary"
            size={isLargerThan700 ? 'sm' : 'xs'}
            onClick={() => {
              handleChangeCurrentPage(currentPage - 1);
            }}
          >
            {currentPage - 1}
          </Button>
        )}
        <Button
          borderRadius="sm"
          colorScheme="primary"
          size={isLargerThan700 ? 'sm' : 'xs'}
          isActive
        >
          {currentPage}
        </Button>
        {currentPage < lastPage && (
          <Button
            borderRadius="sm"
            variant="ghost"
            colorScheme="primary"
            size={isLargerThan700 ? 'sm' : 'xs'}
            onClick={() => {
              handleChangeCurrentPage(currentPage + 1);
            }}
          >
            {currentPage + 1}
          </Button>
        )}
        {currentPage < lastPage - 1 && (
          <Button
            borderRadius="sm"
            variant="ghost"
            colorScheme="primary"
            size={isLargerThan700 ? 'sm' : 'xs'}
            onClick={() => {
              handleChangeCurrentPage(currentPage + 2);
            }}
          >
            {currentPage + 2}
          </Button>
        )}
        {currentPage < lastPage - 2 && currentPage < 3 && (
          <Button
            borderRadius="sm"
            variant="ghost"
            colorScheme="primary"
            size={isLargerThan700 ? 'sm' : 'xs'}
            onClick={() => {
              handleChangeCurrentPage(currentPage + 3);
            }}
          >
            {currentPage + 3}
          </Button>
        )}
        {currentPage < lastPage - 3 && currentPage < 2 && (
          <Button
            borderRadius="sm"
            variant="ghost"
            colorScheme="primary"
            size={isLargerThan700 ? 'sm' : 'xs'}
            onClick={() => {
              handleChangeCurrentPage(currentPage + 4);
            }}
          >
            {currentPage + 4}
          </Button>
        )}
        <Button
          borderRadius="sm"
          variant="ghost"
          colorScheme="primary"
          size={isLargerThan700 ? 'sm' : 'xs'}
          onClick={() => {
            handleChangeCurrentPage(currentPage + 1);
          }}
          isDisabled={currentPage === lastPage}
        >
          &raquo;
        </Button>
        <Button
          borderRadius="sm"
          variant="ghost"
          colorScheme="primary"
          size={isLargerThan700 ? 'sm' : 'xs'}
          onClick={() => {
            handleChangeCurrentPage(lastPage);
          }}
          isDisabled={currentPage === lastPage}
        >
          Última
        </Button>
      </Flex>
      <Text color="gray.700" fontSize={isLargerThan700 ? 'xs' : '2xs'}>
        {itemsTotalCount
          ? `Foram encontrados ${itemsTotalCount} resultados`
          : 'Não foram encontrados resultados'}
      </Text>
    </Stack>
  );
};

export default Paginacao;
