import React, { forwardRef, ForwardRefRenderFunction, memo } from 'react';
import { FieldError } from 'react-hook-form';

import ContainerInputPadrao from 'components/Layout/CampoContainer/CampoContainerPadrao';
import { InputControl } from '../styles';

export interface TextAreaPadraoProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  id: string;
  name: string;
  label: string;
  error?: FieldError | null;
  infoText?: string;
  rows?: number;
}

const TextAreaBase: ForwardRefRenderFunction<
  HTMLTextAreaElement,
  TextAreaPadraoProps
> = (
  { id, label, error = null, required, infoText, rows = 3, ...rest },
  ref
) => {
  return (
    <ContainerInputPadrao
      id={id}
      label={label}
      error={error}
      required={required}
      infoText={infoText}
    >
      <InputControl
        as="textarea"
        rows={rows}
        className="form-control"
        id={id}
        ref={ref}
        onBlur={(e: any) => {
          e.target.value = e.target.value.trim();
        }}
        {...rest}
      />
    </ContainerInputPadrao>
  );
};

export default memo(forwardRef(TextAreaBase));
