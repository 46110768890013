import { Text, Flex, Tr, Td, Icon, useMediaQuery } from '@chakra-ui/react';
import { IconType } from 'react-icons/lib';

import { DecimalMask } from 'helpers/format/fieldsMasks';
import { formatDate } from 'helpers/format/formatStringDate';

import {
  ForwardRefData,
  LoadMoreRowsParams,
  TableHeader,
  VirtualizedInfiniteTable,
} from 'components/update/Table/VirtualizedInfiniteTable';

import { ModalTrocarProdutosPdv } from '../validationForms';

type EscolherVendaParaDevolucaoMobileProps = {
  isLoading: boolean;
  vendaRef: React.RefObject<ForwardRefData>;
  vendas: ModalTrocarProdutosPdv[];
  handlePesquisarProdutosTroca: (itens: ModalTrocarProdutosPdv) => void;
  itemsTotalCount: number;
  loadMoreRows: ({
    currentPage,
    pageSize,
    orderColumn,
    orderDirection,
  }: LoadMoreRowsParams) => Promise<void>;
  FiChevronRight: IconType;
};

export const EscolherVendaParaDevolucaoMobile = ({
  isLoading,
  vendaRef,
  vendas,
  handlePesquisarProdutosTroca,
  itemsTotalCount,
  FiChevronRight,
  loadMoreRows,
}: EscolherVendaParaDevolucaoMobileProps) => {
  const [isLargerThan620] = useMediaQuery('(max-width: 620px)');
  const [isLargerThan700] = useMediaQuery('(max-width: 700px)');
  const [isLargerThan375] = useMediaQuery('(max-width: 375px)');

  const vendasTableHeaders: TableHeader[] = [
    {
      key: 'venda',
      content: (
        <Flex justifyContent="center" alignItems="center">
          <Text mr="35px">Venda</Text>
          <Text>Data</Text>
        </Flex>
      ),
      whiteSpace: 'nowrap',
      width: `${
        isLargerThan700 ? (isLargerThan375 ? '0px' : '180px') : '120px'
      }`,
      justifyContent: 'left',
      alignItems: 'left',
    },

    {
      key: 'valor',
      content: 'Valor',
      isNumeric: false,
      position: 'absolute',
      right: '1',
      top: '3',
      width: '50px',
      whiteSpace: 'nowrap',
    },
  ];

  return (
    <VirtualizedInfiniteTable
      ref={vendaRef}
      colorFundo="gray.50"
      boxShadow="null"
      isMobileProdutoTroca
      alterarBordaListagem="gray.50"
      color="gray.300"
      orderDirection="desc"
      isLargerThan375
      isEdicaoManual={isLargerThan620}
      variant="outline"
      isLoading={isLoading}
      withoutRowsMessage="Não existem produtos para troca."
      orderColumn="dataEmissao"
      visibleItemsCount={10}
      pageSize={20}
      tableHeaders={vendasTableHeaders}
      rowRenderer={({ index, style: { height, ...restStyle }, key }) => {
        const todasVendas = vendas[index];

        if (!todasVendas) {
          return null;
        }

        return (
          <>
            <Tr
              position="relative"
              key={key}
              h="50px !important"
              style={restStyle}
              cursor="pointer"
            >
              <Flex
                _hover={{
                  opacity: '0.6',
                }}
                alignItems="center"
                justifyContent="space-between"
                onClick={() => handlePesquisarProdutosTroca(todasVendas)}
              >
                <Td w="100px">
                  <Text
                    w="full"
                    position="absolute"
                    top="2"
                    left="2"
                    color="primary.500"
                    fontSize="14px"
                  >
                    {todasVendas.numeroOperacao}
                  </Text>
                </Td>

                <Td>
                  <Text
                    position="absolute"
                    top="2"
                    left="78"
                    isTruncated
                    w="full"
                    fontSize="14px"
                  >
                    {formatDate(todasVendas?.dataEmissao || '')}
                  </Text>
                </Td>
                <Td isNumeric>
                  <Flex
                    w="140px"
                    position="absolute"
                    top="2"
                    right="2"
                    justifyContent="flex-end"
                    alignItems="right"
                  >
                    <Text isTruncated fontSize="14px">
                      {`R$ ${DecimalMask(todasVendas?.valorTotalOperacao, 2)}`}
                    </Text>
                    <Flex mt="2px" fontSize="14px">
                      <Icon
                        color="black"
                        top="16px"
                        right="30px"
                        cursor="pointer"
                        as={FiChevronRight}
                      />
                    </Flex>
                  </Flex>
                </Td>
                <Td>
                  <Text
                    position="absolute"
                    isTruncated
                    mt="5px"
                    top="22"
                    left="2"
                    w="full"
                    fontSize="14px"
                  >
                    {todasVendas.clienteNome}
                  </Text>
                </Td>
              </Flex>
            </Tr>
          </>
        );
      }}
      rowCount={itemsTotalCount}
      isRowLoaded={({ index }) => !!vendas[index]}
      loadMoreRows={loadMoreRows}
    />
  );
};
