import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import { useProdutosFormularioContext } from 'store/Produtos/ProdutosFormulario';

import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import { PaginationData } from 'components/update/Pagination';
import { PagedTableForwardRefData } from 'components/update/Table/PagedTable';

import { ProductSizes, ProdutosProps, TamanhosProps } from './types';
import { ModalProdutoAlterar } from './components/ModalProduto/Alterar';
import {
  ModalProdutoAdicionar,
  ProdutoResponse,
} from './components/ModalProduto/Cadastrar';

export const useFichaTecnica = () => {
  const {
    idProduto: idProdutoPrincipal,
    readonly,
  } = useProdutosFormularioContext();

  const [listProdutos, setListProdutos] = useState<ProdutosProps[]>([]);
  const [listTamanhos, setListTamanhos] = useState<TamanhosProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRegistros, setTotalRegistros] = useState(0);

  const pagedTableRef = useRef<PagedTableForwardRefData>(null);

  const isDefaultSize = listTamanhos.every(
    ({ padraoSistema }) => padraoSistema
  );

  const atualizarLista = () => {
    if (pagedTableRef?.current?.reload) {
      pagedTableRef.current.reload();
    }
  };

  const getSizes = useCallback(async () => {
    const response = await api.get<void, ResponseApi<TamanhosProps[]>>(
      `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProdutoPrincipal}/tamanhos`
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.success(aviso));
      }

      if (response?.sucesso && response?.dados) {
        if (response.dados.length === 1) {
          setListTamanhos(response.dados);
          return;
        }

        const newSizes = response.dados.filter((size) => !size.padraoSistema);
        setListTamanhos(newSizes);
      }
    }
  }, [idProdutoPrincipal]);

  const paginationHandle = useCallback(
    async (gridPaginadaConsulta: PaginationData) => {
      if (listTamanhos.length === 0) {
        return;
      }

      setIsLoading(true);
      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<ProdutosProps>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.PRODUTOS_FICHA_TECNICA.replace(
            'id',
            idProdutoPrincipal
          ),
          gridPaginadaConsulta
        )
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }
        if (response.sucesso) {
          const dados = response?.dados;
          const data = dados?.registros || [];

          setTotalRegistros(dados?.total || 0);
          setListProdutos(data);
        }
      }
      setIsLoading(false);
    },
    [idProdutoPrincipal, listTamanhos]
  );

  const registerProductItem = useCallback(
    async (data: ProdutoResponse) => {
      const response = await api.post<void, ResponseApi<boolean>>(
        ConstanteEnderecoWebservice.PRODUTOS_FICHA_TECNICA.replace(
          'id',
          idProdutoPrincipal
        ),
        { ...data }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso: string) => toast.warning(aviso));
        }

        if (response.sucesso) {
          atualizarLista();
          toast.success('Produto adicionado com sucesso');

          return { success: true };
        }
      }
      return { success: false };
    },
    [idProdutoPrincipal]
  );

  const handleAbrirModalCadastrarProduto = async () => {
    const newSizes = listTamanhos.map((size) => ({
      id: size.id,
      nome: size.descricao,
      quantidade: 0,
    }));

    const { deveReiniciar } = await ModalProdutoAdicionar({
      casasDecimais: 3,
      listSizes: newSizes,
      isDefaultSize,
      idProduto: idProdutoPrincipal,
      callback: registerProductItem,
    });

    if (deveReiniciar) {
      await handleAbrirModalCadastrarProduto();
    }
  };

  const updateProductItem = useCallback(
    async (data: ProdutoResponse) => {
      const {
        produtoItemId,
        produtoPrincipalId,
        materiaPrimaFinal,
        tamanhos,
      } = data;

      const response = await api.put<void, ResponseApi<string>>(
        `${ConstanteEnderecoWebservice.PRODUTOS_FICHA_TECNICA.replace(
          'id',
          idProdutoPrincipal
        )}/${produtoItemId}`,
        {
          produtoPrincipalId,
          produtoItemId,
          materiaPrimaFinal,
          tamanhos,
        }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }

        if (response.sucesso) {
          atualizarLista();
          toast.success('Produto atualizado com sucesso');

          return { success: true };
        }
      }

      return { success: false };
    },
    [idProdutoPrincipal]
  );

  const handleEditarTamanho = async (data: {
    productItemDescription: string;
    productItemId: string;
    finalRawMaterial: boolean;
    productSizes: ProductSizes[];
  }) => {
    const {
      productItemDescription,
      productItemId,
      finalRawMaterial,
      productSizes,
    } = data;

    const newSizes = listTamanhos.map((size) => ({
      id: size.id,
      nome: size.descricao,
      quantidade:
        productSizes.find((item) => item.tamanho.id === size.id)?.quantidade ||
        0,
    }));

    ModalProdutoAlterar({
      title: productItemDescription,
      productMainId: idProdutoPrincipal,
      productItemId,
      isDefaultSize,
      finalRawMaterial,
      listSizes: newSizes,
      decimalPlaces: 3,
      callback: updateProductItem,
    });
  };

  const handleExcluirTamanho = async (id: string) => {
    const response = await api.delete<void, ResponseApi<string>>(
      `${ConstanteEnderecoWebservice.PRODUTOS_FICHA_TECNICA.replace(
        'id',
        idProdutoPrincipal
      )}/${id}`
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso) {
        toast.success('Produto removido com sucesso');
        atualizarLista();
      }
    }
  };

  const formatDecimalPlaces = (value: number): string => {
    return value.toLocaleString('pt-BR', {
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    });
  };

  useEffect(() => {
    getSizes();
  }, [getSizes]);

  return {
    handleExcluirTamanho,
    totalRegistros,
    handleEditarTamanho,
    isLoading,
    paginationHandle,
    handleAbrirModalCadastrarProduto,
    pagedTableRef,
    listProdutos,
    isDefaultSize,
    formatDecimalPlaces,
    readonly,
  };
};
