import ConstanteRotas from 'constants/rotas';

import {
  IconDashboardGerencial,
  IconSmartPosApp,
  IconTrayApp,
  IconTrayAppWhite,
  IconMercadoLivreApp,
  IconNuvemIntegracaoApp,
  IconShopeeApp,
  IconSmartPosAppAtivo,
  LogoZoop,
  IconPdvOffline,
  LogoFrenteCaixa,
  IconFrenteCaixaDetalhesIntegracao,
} from 'icons';

import { enumReferenciaServicoStargate } from './referenciaServicoStargate';

export const enumIntegracoes = {
  options: [
    {
      proximoLancamento: false,
      id: 'dashboard',
      ativo: true,
      bg: '#c2fc1c',
      name: 'Dashboard',
      icon: IconDashboardGerencial,
      iconInativo: IconDashboardGerencial,
      rotaDetalhesIntegracao: ConstanteRotas.DASHBOARD_GERENCIAL_DETALHES,
      rotaComercial: ConstanteRotas.DASHBOARD_GERENCIAL,
    },
    {
      proximoLancamento: false,
      id: 'smartPos',
      ativo: true,
      bg: 'primary.500',
      name: 'Smart Pos',
      icon: IconSmartPosAppAtivo,
      iconInativo: IconSmartPosApp,
      rotaDetalhesIntegracao: ConstanteRotas.SMART_POS_DETALHES_INTEGRACAO,
      permissao: enumReferenciaServicoStargate.DISPOSITIVO_SMART_POS,
      rotaComercial: ConstanteRotas.SMART_POS,
    },
    {
      proximoLancamento: false,
      id: 'tray',
      ativo: true,
      bg: '#182250',
      name: 'tray',
      icon: IconTrayAppWhite,
      permissao: enumReferenciaServicoStargate.INTEGRACAO_TRAY,
      iconInativo: IconTrayApp,
      rotaDetalhesIntegracao: ConstanteRotas.INTEGRACAO_TRAY_DETALHES,
      rotaComercial: ConstanteRotas.INTEGRACAO_TRAY_TELA_COMERCIAL,
    },
    {
      proximoLancamento: false,
      id: 'zoop',
      ativo: true,
      bg: '#EE741C',
      name: 'Zoop Recebimentos',
      icon: LogoZoop,
      permissao: enumReferenciaServicoStargate.INTEGRACAO_ZOOP,
      iconInativo: LogoZoop,
      rotaComercial: ConstanteRotas.ZOOP_TELA_COMERCIAL,
      rotaDetalhesIntegracao: ConstanteRotas.ZOOP_DETALHES_INTEGRACAO,
    },
    {
      proximoLancamento: false,
      id: 'pdv-offline',
      ativo: true,
      bg: 'primary.900',
      name: 'Pdv Offline',
      icon: IconPdvOffline,
      permissao: enumReferenciaServicoStargate.DISPOSITIVO_PDV,
      iconInativo: IconPdvOffline,
      rotaComercial: ConstanteRotas.PDV_OFFLINE_TELA_COMERCIAL,
      rotaDetalhesIntegracao: ConstanteRotas.PDV_OFFLINE_DETALHES,
    },
    {
      proximoLancamento: false,
      id: 'frente-caixa',
      ativo: true,
      bg: '#FF005A',
      name: 'Frente de Caixa',
      icon: LogoFrenteCaixa,
      permissao: enumReferenciaServicoStargate.DISPOSITIVO_FRENTE_CAIXA,
      iconInativo: IconFrenteCaixaDetalhesIntegracao,
      rotaDetalhesIntegracao: ConstanteRotas.FRENTE_CAIXA_DETALHES,
      rotaComercial: ConstanteRotas.FRENTE_CAIXA_COMERCIAL,
    },
    {
      proximoLancamento: false,
      id: 'nuvemShop',
      ativo: false,
      bg: 'yellow.600',
      name: 'Nuvem shop',
      icon: IconNuvemIntegracaoApp,
      rotaDetalhesIntegracao: '',
      iconInativo: IconNuvemIntegracaoApp,
      rotaComercial: '',
      identificacaoAplicativo: 0,
    },
    {
      proximoLancamento: false,
      id: 'mercadoLivre',
      ativo: false,
      bg: 'Black',
      name: 'Mercado livre',
      icon: IconMercadoLivreApp,
      rotaDetalhesIntegracao: '',
      iconInativo: IconMercadoLivreApp,
      rotaComercial: '',
    },

    {
      proximoLancamento: false,
      id: 'shopee',
      ativo: false,
      bg: 'yellow.600',
      name: 'Shopee',
      icon: IconShopeeApp,
      rotaDetalhesIntegracao: '',
      iconInativo: IconShopeeApp,
      rotaComercial: '',
    },
  ],
};
