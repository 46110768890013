import React from 'react';
import { Icon, VStack, Text, Flex } from '@chakra-ui/react';

import { AjudaContato, AjudaEmail } from 'icons';

export const SistemaTipoClienteSTi3 = () => {
  return (
    <>
      <Flex gap="12px" justify="center" cursor="pointer" w="full">
        <Flex
          bg="violet.50"
          h="48px"
          minW="48px"
          borderRadius="full"
          align="center"
          justify="center"
        >
          <Icon as={AjudaEmail} boxSize="6" borderRadius="5px" />
        </Flex>
        <VStack align="flex-start" spacing="2px" w="full">
          <Text fontWeight="bold" fontSize="md">
            E-mail
          </Text>
          <Flex align="center" justify="space-between" w="full">
            <Text>atendimento@sti3.com.br</Text>
          </Flex>
        </VStack>
      </Flex>
      <Flex gap="12px" justify="center" cursor="pointer" w="full">
        <Flex
          bg="violet.50"
          h="48px"
          minW="48px"
          borderRadius="full"
          align="center"
          justify="center"
        >
          <Icon as={AjudaContato} boxSize="6" borderRadius="5px" />
        </Flex>
        <VStack align="flex-start" spacing="2px" w="full">
          <Text fontWeight="bold" fontSize="md">
            Telefone
          </Text>
          <Flex align="center" justify="space-between" w="full">
            <Text>(14) 3411-3333</Text>
          </Flex>
        </VStack>
      </Flex>
    </>
  );
};
