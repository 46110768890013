import { SelectComponents } from 'react-select/src/components';

import { SelectFieldProps as SelectPadraoProps } from 'components/PDV/Select/SelectPadrao';
import { PaginationData } from 'components/update/Pagination';
import OptionType from 'types/optionType';

type SelectFieldProps = Omit<SelectPadraoProps, 'disabled' | 'options'> & {
  handleGetOptions: (
    inputValue: string,
    dataPagination: PaginationData
  ) => Promise<OptionType[]>;
};

export type SelectVirtualizedProps = SelectFieldProps & {
  totalRegistros: number;
  handleGetOptions: (
    inputValue: string,
    dataPagination: PaginationData
  ) => Promise<OptionType[]>;
  isDisabled?: boolean;
  withoutDefaultOptions?: boolean;
  variant?: string;
  size?: string;
  asControlledByObject?: boolean;
  closeMenuOnSelect?: boolean;
  shouldAppearTheAddress?: boolean;
  renderOptionDetails?: (data: any) => JSX.Element | null;
  components?: Partial<SelectComponents<OptionType<any>>> | undefined;
  autoFocus?: boolean;
  isClearable?: boolean;
  placeholder?: string;
  fontWeightLabel?: string;
};

export const valuePaginationDefault = {
  currentPage: 1,
  orderColumn: '',
  orderDirection: 'asc',
  pageSize: 20,
} as PaginationData;
