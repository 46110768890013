import { Flex, Icon, IconButton, Text } from '@chakra-ui/react';
import { TextValor } from 'components/PDV/Text/TextValor';
import { FiChevronLeft } from 'react-icons/fi';
import { useEffect } from 'react';

import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';
import { usePagamentoContext } from 'store/PDV/Pagamento';

interface InternalHeaderProps {
  label: string;
  value: number;
  handleVoltar: () => void;
}

export default function InternalHeader({
  label,
  value,
  handleVoltar,
}: InternalHeaderProps) {
  const { setValorTotalVenda } = usePagamentoContext();
  const {
    casasDecimais: { casasDecimaisValor },
  } = usePadronizacaoContext();

  useEffect(() => {
    setValorTotalVenda(value);
  }, [setValorTotalVenda, value]);

  return (
    <Flex
      h="56px"
      bg="primary.800"
      alignItems="center"
      justifyContent="space-between"
      pl="2"
      pr="6"
    >
      <IconButton
        aria-label="Voltar"
        icon={<Icon as={FiChevronLeft} boxSize="6" />}
        variant="link"
        color="white"
        onClick={handleVoltar}
      />

      <Flex ml="2">
        <Text color="white" fontSize="sm" mr="2">
          {label}
        </Text>

        <TextValor
          valor={value}
          casasDecimais={casasDecimaisValor}
          fontSize="lg"
          symbolFontSize="sm"
        />
      </Flex>
    </Flex>
  );
}
