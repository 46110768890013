import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export type FormData = {
  descricao: string;
  ativo: boolean;
};

const schema = yup.object().shape({
  descricao: yup
    .string()
    .trim()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .max(50, 'O campo de descrição não pode conter mais que 50 caracteres.'),
  ativo: yup.boolean().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);

export const useForm = useFormInstance;
