import { useEffect } from 'react';
import { Flex, Icon, Text, Box, useMediaQuery } from '@chakra-ui/react';
import { FiChevronLeft } from 'react-icons/fi';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useProdutosConsignadosContext } from 'store/PDV/ProdutosConsignados';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';
import { DecimalMask, moneyMask } from 'helpers/format/fieldsMasks';

import Input from 'components/PDV/Input';

type InternalHeaderProdutosConsignadosProps = {
  handleVoltarParaPdv: () => void;
  quantidade: number[];
};

export const InternalHeaderProdutosConsignados = ({
  handleVoltarParaPdv,
  quantidade,
}: InternalHeaderProdutosConsignadosProps) => {
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const { casasDecimais } = usePadronizacaoContext();

  const {
    setExisteCodigoBarras,
    produtosConsignadosJaForamDevolvidos,
    produtosConsignados,
    setValorTotalConsignacao,
    existeCodigoBarras,
    produtosSelecionados,
  } = useProdutosConsignadosContext();

  const { getValues } = useFormContext();

  const textoHeader = () => {
    if (produtosConsignados.length === 0) {
      return 'Produtos consignados';
    }
    if (isLargerThan900) {
      return `Informe os produtos que serão ${
        produtosConsignadosJaForamDevolvidos ? 'vendidos' : 'devolvidos'
      }`;
    }
    return `Informe os produtos ${
      produtosConsignadosJaForamDevolvidos ? 'vendidos' : 'devolvidos'
    }`;
  };

  const valorItensConsignados = produtosConsignados
    .map((item, index) => ({
      ...item,
      quantidadeConsignada: quantidade[index],
    }))
    .filter((item) => item.isChecked)
    .map((item) => {
      const itensDevolucao = {
        id: item.operacaoItemId,
        valorUnitario: item.valorUnitario,
        quantidade: item.quantidadeConsignada,
      };

      return itensDevolucao;
    });

  const valorTotalItensConsignados = valorItensConsignados.reduce(
    (acc, curr) => acc + (curr.valorUnitario || 0) * curr.quantidade,
    0
  );

  const textoParaExibicaoHeader = textoHeader();

  useEffect(() => {
    setValorTotalConsignacao(valorTotalItensConsignados);
  }, [valorTotalItensConsignados, setValorTotalConsignacao]);

  return (
    <Box h="72px" bg="primary.800">
      <Flex h="full" w="full">
        <Flex
          justifyContent={isLargerThan900 ? '' : 'space-between'}
          h="full"
          w={isLargerThan900 ? '' : 'full'}
          pl="25px"
        >
          <Flex
            h="full"
            w={isLargerThan900 ? '10%' : '40px'}
            alignItems="center"
            justifyContent="center"
            position="relative"
          >
            <Icon
              color={
                produtosConsignadosJaForamDevolvidos
                  ? 'aquamarine.300'
                  : 'orange.300'
              }
              w={6}
              mr="24px"
              onClick={() => handleVoltarParaPdv()}
              cursor="pointer"
              h={6}
              as={FiChevronLeft}
            />
          </Flex>

          <Flex
            h="full"
            alignItems="center"
            mr={isLargerThan900 ? '0' : '23px'}
            justifyContent="left"
          >
            <Text
              w={isLargerThan900 ? '320px' : '240px'}
              color={
                produtosConsignadosJaForamDevolvidos
                  ? 'aquamarine.300'
                  : 'orange.300'
              }
              fontSize="16px"
            >
              {textoParaExibicaoHeader}
            </Text>
          </Flex>
        </Flex>

        {isLargerThan900 && (
          <>
            <Flex w="50%" alignItems="center" justifyContent="center">
              {!produtosConsignadosJaForamDevolvidos && (
                <Box>
                  <Text fontSize="14px" mb="2px" mt="6px" color="white">
                    Código de barras
                  </Text>
                  <Input
                    autoFocus
                    w="120px"
                    h="15px"
                    fontSize="14px"
                    color="white"
                    borderRadius="none"
                    bg="none"
                    onEnterKeyPress={async () => {
                      await setExisteCodigoBarras(!existeCodigoBarras);
                      const codigoBarrasDigitado = getValues().codigoBarras;
                      const validandoCodigoBarras = produtosConsignados.some(
                        ({
                          codigoBarrasInterno,
                          codigoExterno,
                          codigoBarrasHexaDecimal,
                          codigoGTINEAN,
                        }) =>
                          [
                            codigoExterno,
                            codigoBarrasInterno,
                            codigoBarrasHexaDecimal,
                            codigoGTINEAN,
                          ].includes(codigoBarrasDigitado)
                      );
                      if (!validandoCodigoBarras) {
                        toast.warning('O código de barras não foi encontrado.');
                      }
                    }}
                    _active={{
                      borderColor: 'white',
                    }}
                    _focus={{ borderColor: 'white' }}
                    variant="flushed"
                    isRequired
                    maxLength={100}
                    name="codigoBarras"
                  />
                </Box>
              )}
              <Box ml="10%" w="150px">
                <Flex
                  fontSize="16px"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text fontSize="14px" mt="8px" mb="8px" color="white">
                    Itens selecionados
                  </Text>
                </Flex>
                <Flex
                  mt="-6px"
                  fontSize="16px"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text
                    color={
                      produtosConsignadosJaForamDevolvidos
                        ? 'aquamarine.300'
                        : 'orange.300'
                    }
                  >
                    {DecimalMask(
                      produtosSelecionados,
                      casasDecimais.casasDecimaisQuantidade,
                      casasDecimais.casasDecimaisQuantidade
                    )}
                  </Text>
                </Flex>
              </Box>

              <Flex ml="10%" alignItems="center" justifyContent="center">
                {produtosConsignadosJaForamDevolvidos && (
                  <Box>
                    <Text fontSize="14px" mt="6px" mb="8px" color="white">
                      Valor total da venda
                    </Text>
                    <Flex
                      fontSize="16px"
                      mt="-6px"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Text color="aquamarine.300">
                        {moneyMask(valorTotalItensConsignados, true)}
                      </Text>
                    </Flex>
                  </Box>
                )}
              </Flex>
            </Flex>
          </>
        )}
      </Flex>
    </Box>
  );
};
