import React from 'react';
import Highlighter from 'react-highlight-words';
import latinize from 'latinize';

const SelectHighlighter = ({ label }: any, { inputValue }: any) => {
  const formattedValue = inputValue?.replace(/[^0-9a-zA-Z]/g, '');

  return typeof label !== 'string' ? (
    label
  ) : (
    <Highlighter
      searchWords={[formattedValue]}
      textToHighlight={label}
      highlightStyle={{
        backgroundColor: 'var(--sti-ck-colors-secondary-300)',
        padding: 0,
      }}
      sanitize={latinize}
    />
  );
};

export default SelectHighlighter;
