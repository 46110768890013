import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Text,
  Button,
  SimpleGrid,
  VStack,
  Flex,
  IconButton,
  Icon,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { LixeiraIcon } from 'icons';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { SelecionarLojasModal } from './SelecionarLojasModal';

import { useTabelaPreco } from '../../hooks';
import { Loja } from '../../types';

interface SelecionarLojasProps {
  label?: string;
  modalSubtitle?: string;
  isReadonly?: boolean;
}

export function SelecionarLojas({
  label = 'Lojas habilitadas',
  modalSubtitle = 'Marque apenas as lojas que ficarão habilitadas neste cadastro',
  isReadonly = false,
}: SelecionarLojasProps) {
  const { setValue, watch } = useFormContext();
  const { getLojas } = useTabelaPreco();

  const lojasSelecionadas = watch('lojasSelecionadas');

  const [lojas, setLojas] = useState<Loja[]>([]);
  const [isLojasLoading, setIsLojasLoading] = useState(false);

  async function handleAdicionarLoja() {
    const {
      lojasSelecionadas: newLojasSelecionadas,
    } = await SelecionarLojasModal({
      subtitle: modalSubtitle,
      lojas,
    });

    setValue('lojasSelecionadas', newLojasSelecionadas);
  }

  function handleDeselecionarLoja(id: string) {
    const newLojasSelecionadas = [...lojasSelecionadas].filter(
      (lojaId) => lojaId !== id
    );

    setValue('lojasSelecionadas', newLojasSelecionadas);
  }

  const latestProps = useRef({ getLojas, setValue });
  useEffect(() => {
    latestProps.current = { getLojas, setValue };
  });

  useEffect(() => {
    setIsLojasLoading(true);

    latestProps.current.getLojas().then((newLojas) => {
      const newLojasSelecionadas = newLojas
        .filter((loja) => loja.selecionado)
        .map((loja) => loja.id);

      latestProps.current.setValue('lojasSelecionadas', newLojasSelecionadas);

      setLojas(newLojas);

      setIsLojasLoading(false);
    });
  }, []);

  useEffect(() => {
    setLojas((prev) =>
      prev.map((loja) => ({
        ...loja,
        selecionado: (lojasSelecionadas || []).includes(loja.id),
      }))
    );
  }, [lojasSelecionadas]);

  return (
    <Box w="full">
      <Text
        as="label"
        lineHeight="none"
        fontSize="sm"
        fontWeight="semibold"
        mb="0"
      >
        {label}
      </Text>

      <Box
        borderRadius="md"
        bg="gray.50"
        border="1px"
        borderColor="gray.100"
        p={{ base: 4, sm: 6, md: 8 }}
        position="relative"
      >
        {isLojasLoading && <LoadingPadrao />}

        {isReadonly && (lojasSelecionadas || []).length === 0 && (
          <Text fontSize="sm" color="gray.500">
            Nenhuma loja adicionada.
          </Text>
        )}

        {!isReadonly && (
          <Button
            variant="link"
            colorScheme="blue"
            size="sm"
            fontWeight="semibold"
            lineHeight="none"
            textDecoration="none"
            _hover={{ textDecoration: 'none' }}
            p="1"
            mb={(lojasSelecionadas || []).length > 0 ? '1' : '0'}
            onClick={handleAdicionarLoja}
          >
            Adicionar loja
          </Button>
        )}
        {(lojasSelecionadas || []).length > 0 && (
          <SimpleGrid
            templateColumns={{
              base: '1fr',
              md: 'repeat(auto-fit, minmax(335px, 335px))',
            }}
            columnGap={{ base: 4, sm: 6, md: 8 }}
            rowGap={4}
          >
            {lojas
              .filter((loja) => loja.selecionado)
              .map((loja) => (
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  key={loja.id}
                  bg="white"
                  borderRadius="md"
                  p={{ base: 3, md: 5 }}
                  lineHeight="none"
                  boxShadow="md"
                >
                  <VStack spacing="2" lineHeight="none" alignItems="flex-start">
                    <Text fontSize="sm" fontWeight="semibold">
                      {`${loja.fantasia}${loja.cidade && ` | ${loja.cidade}`}`}
                    </Text>
                    <Text fontSize="xs" color="gray.300">
                      {loja.endereco}
                    </Text>
                  </VStack>

                  {!isReadonly && (
                    <IconButton
                      aria-label="Remover loja"
                      variant="link"
                      colorScheme="red"
                      p="0.5"
                      minW="1px"
                      borderRadius="md"
                      alignSelf="flex-start"
                      icon={<Icon as={LixeiraIcon} />}
                      onClick={() => handleDeselecionarLoja(loja.id)}
                    />
                  )}
                </Flex>
              ))}
          </SimpleGrid>
        )}
      </Box>
    </Box>
  );
}
