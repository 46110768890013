import { IdentificacaoEtapasMetasComissoes } from 'constants/enum/identificacaoEtapasMetasComissoes';

import { EscolherValorMeta } from '../EscolherValorMeta';
import { MetaVendedores } from '../MetaVendedores';
import { EscolherComissaoVendedores } from '../EscolherComissaoVendedores';
import { PremiacaoPorEquipe } from '../PremiacaoPorEquipe/index';
import { EscolherRegraPagComissao } from '../EscolherRegraPagComissao';

type StepsMetasComissoesProps = {
  activeStep: number;
};

export const StepsMetasComissoes = ({
  activeStep,
}: StepsMetasComissoesProps) => {
  switch (activeStep) {
    case IdentificacaoEtapasMetasComissoes.ESCOLHER_VALOR_META:
      return <EscolherValorMeta />;
    case IdentificacaoEtapasMetasComissoes.ESCOLHER_META_VENDEDORES:
      return <MetaVendedores />;
    case IdentificacaoEtapasMetasComissoes.REGRA_PAGAMENTO:
      return <EscolherRegraPagComissao />;
    case IdentificacaoEtapasMetasComissoes.COMISSAO_VENDEDORES:
      return <EscolherComissaoVendedores />;
    case IdentificacaoEtapasMetasComissoes.PREMIACAO_POR_EQUIPE:
      return <PremiacaoPorEquipe />;

    default:
      return null;
  }
};
