import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

const schema = yup.object().shape({
  cnpj: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .min(18, ConstanteMensagemValidacao.CNPJ_INVALIDO),
  identificacaoSistema: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);
