import React from 'react';
import { useTranslation } from 'react-i18next';
import { SalvarInserirNovoIcon, SalvarConfirmarIcon } from 'icons';
import ButtonPadrao from 'components/Button/ButtonPadrao';
import { Col, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import SelectBaseLegal from 'components/Select/SelectBaseLegal';
import { useFormContext } from 'react-hook-form';
import ModalPadrao from 'components/Modal/ModalPadrao';
import { useForm, yupResolver } from './validationForm';

interface UncontrolledFormInterface {
  show: boolean;
  onHide: () => void;
}

const BaseLegalModal: React.FC<UncontrolledFormInterface> = ({
  show,
  onHide,
}) => {
  const { t } = useTranslation();
  const { setValue, getValues: parentGetValues } = useFormContext();

  const {
    formState: { errors },
    control,
    handleSubmit,
    reset,
    setFocus,
  } = useForm({
    resolver: yupResolver,
    shouldUnregister: true,
  });

  const handleAddItem = (data: any) => {
    const itemsAdicionados = parentGetValues('basesLegais');

    if (
      itemsAdicionados.some((item: any) => item.id === data.baseLegal.value)
    ) {
      toast.warning(t('Essa base legal já foi adicionada a nota.'));
      return false;
    }

    setValue('basesLegais', [
      ...itemsAdicionados,
      { descricao: data.baseLegal.label, id: data.baseLegal.value },
    ]);

    return true;
  };

  const onSubmitReset = handleSubmit(async (data) => {
    const success = await handleAddItem(data);

    if (success) {
      reset({});
    }
  });

  const onSubmit = handleSubmit(async (data) => {
    const success = await handleAddItem(data);

    if (success) {
      onHide();
    }
  });

  return (
    <>
      {show && (
        <ModalPadrao
          isOpen={show}
          handleOnHide={onHide}
          title={t('Adicionar base legal')}
          subtitle={t('Selecione a base legal.')}
          onEntered={() => setFocus('baseLegal')}
          maxWidth={800}
          size="lg"
        >
          <Form>
            <Modal.Body>
              <Form.Row>
                <Col>
                  <SelectBaseLegal
                    type="text"
                    autoFocus
                    id="baseLegal"
                    name="baseLegal"
                    label={t('Base legal')}
                    required={false}
                    placeholder={t('Digite a base legal')}
                    control={control}
                    error={errors.baseLegal}
                    isDisabled={false}
                  />
                </Col>
              </Form.Row>
            </Modal.Body>
            <Modal.Footer>
              <ButtonPadrao
                id="adicionarInserirNovo"
                type="button"
                variant="outline-secondary"
                name="salvarInserirNovo"
                text={t('Adicionar e inserir novo')}
                icon={SalvarInserirNovoIcon}
                disabled={false}
                onClick={onSubmitReset}
                style={{ height: '35px', width: '100%', maxWidth: '225px' }}
              />
              <ButtonPadrao
                id="adicionarDocumentoReferenciado"
                name="adicionarDocumentoReferenciado"
                text={t('Adicionar')}
                icon={SalvarConfirmarIcon}
                type="button"
                variant="success"
                onClick={onSubmit}
                style={{ height: '35px', width: '100%', maxWidth: '120px' }}
              />
            </Modal.Footer>
          </Form>
        </ModalPadrao>
      )}
    </>
  );
};

export default BaseLegalModal;
