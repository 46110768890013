import {
  Box,
  Text,
  Flex,
  Divider,
  Icon,
  useMediaQuery,
} from '@chakra-ui/react';

import { moneyMask } from 'helpers/format/fieldsMasks';

import { NumberInput } from 'components/update/Input/NumberInput';
import { LixeiraIcon } from 'icons';

type ListarFaixaPercentualProps = {
  index: number;
  obterValorMetaLoja: number;
  valorAlcancePremiacaoVendedoresWatch: number[];
  handleExcluirFaixaBonificacaoPercent: (index: number) => void;
};

export const ListarFaixaPercentual = ({
  index,
  obterValorMetaLoja,
  valorAlcancePremiacaoVendedoresWatch,
  handleExcluirFaixaBonificacaoPercent,
}: ListarFaixaPercentualProps) => {
  const [isLargerThan1200] = useMediaQuery('(min-width: 1200px)');
  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

  return (
    <Box w="full">
      <Flex
        direction={isLargerThan1200 ? 'row' : 'column'}
        mt="25px"
        justifyContent="left"
        alignItems="center"
        w="full"
      >
        <Flex
          w={isLargerThan1200 ? '' : 'full'}
          mb={isLargerThan1200 ? '' : isLargerThan700 ? '10px' : '10px'}
          justifyContent="left"
          direction={isLargerThan700 ? 'row' : 'column'}
          alignItems="center"
        >
          <Flex
            mb={isLargerThan700 ? '' : '10px'}
            w={isLargerThan700 ? '' : 'full'}
            justifyContent="left"
            alignItems="center"
          >
            <Text fontSize="16px" mr="10px">
              Quando a loja alcançar
            </Text>
          </Flex>
          <Flex
            justifyContent="left"
            alignItems="center"
            mr="10px"
            ml={isLargerThan700 ? '' : '10px'}
            w={isLargerThan700 ? '100px' : 'full'}
          >
            <NumberInput
              w="full"
              id={`valorAlcancePremiacaoVendedores.${index}`}
              bgLeftElement="gray.50"
              name={`valorAlcancePremiacaoVendedores.${index}`}
              scale={2}
              rightElementPorcentagem="%"
            />
          </Flex>
          {!isLargerThan1200 && (
            <Flex
              justifyContent="left"
              alignItems="center"
              mt={isLargerThan700 ? '' : '10px'}
              w={isLargerThan700 ? '' : 'full'}
            >
              <Text fontSize="16px" fontWeight="bold">
                (
                {moneyMask(
                  (obterValorMetaLoja *
                    Number(valorAlcancePremiacaoVendedoresWatch[index] || 0)) /
                    100,
                  true
                )}
                ),
              </Text>
            </Flex>
          )}
        </Flex>
        <Flex
          w={isLargerThan1200 ? '' : 'full'}
          direction={isLargerThan700 ? 'row' : 'column'}
          justifyContent="left"
          alignItems="center"
        >
          <Flex
            mb={isLargerThan700 ? '' : '10px'}
            w={isLargerThan700 ? '' : 'full'}
            justifyContent="left"
            alignItems="center"
          >
            <Text mr="10px" fontSize="16px">
              {isLargerThan1200 && (
                <strong>
                  {moneyMask(
                    (obterValorMetaLoja *
                      Number(
                        valorAlcancePremiacaoVendedoresWatch[index] || 0
                      )) /
                      100,
                    true
                  )}
                  ,
                </strong>
              )}{' '}
              o percentual da bonificação extra será de
            </Text>
          </Flex>
          <Flex
            justifyContent="left"
            alignItems="center"
            mr="10px"
            ml={isLargerThan700 ? '' : '10px'}
            w={isLargerThan700 ? '100px' : 'full'}
          >
            <NumberInput
              w="full"
              rightElementPorcentagem="%"
              id={`percentualBonificacaoVendedores.${index}`}
              bgLeftElement="gray.50"
              name={`percentualBonificacaoVendedores.${index}`}
              scale={2}
            />
          </Flex>
          <Flex
            justifyContent="left"
            alignItems="center"
            mr="10px"
            mt={isLargerThan700 ? '' : '10px'}
            w={isLargerThan700 ? '' : 'full'}
          >
            <Text fontSize="16px">para cada vendedor.</Text>
            <Icon
              boxSize="19px"
              ml="10px"
              cursor="pointer"
              onClick={() => handleExcluirFaixaBonificacaoPercent(index)}
              as={LixeiraIcon}
            />
          </Flex>
        </Flex>
      </Flex>
      <Divider mt="15px" />
    </Box>
  );
};
