import React, { memo } from 'react';
import { Tooltip } from 'react-bootstrap';
import formatDateHourMinute from 'helpers/format/formatUTCToLocateDateTime';
import { useTranslation } from 'react-i18next';

import OverlayTriggerPadrao from 'components/OverlayTriggerPadrao';
import { Container, DataText, DataIcon } from './styles';

interface DataCriacaoAlteracaoProps {
  dataHoraCriacao?: string;
  dataHoraUltimaAlteracao?: string;
}

const DataCriacaoAlteracao = ({
  dataHoraCriacao,
  dataHoraUltimaAlteracao,
}: DataCriacaoAlteracaoProps) => {
  const { t } = useTranslation();

  const dataCriacao = dataHoraCriacao
    ? formatDateHourMinute(dataHoraCriacao)
    : undefined;
  const dataUltimaAlteracao = dataHoraUltimaAlteracao
    ? formatDateHourMinute(dataHoraUltimaAlteracao)
    : undefined;

  return (
    <Container>
      <DataText>{dataCriacao && dataCriacao.split(' ')[0]}</DataText>
      <DataText>
        {!dataCriacao &&
          dataUltimaAlteracao &&
          dataUltimaAlteracao.split(' ')[0]}
      </DataText>

      <OverlayTriggerPadrao
        overlay={({ className, ...rest }: any) => {
          return (
            <Tooltip className="dataAlteracaoCriacaoTooltip" {...rest}>
              {dataCriacao && (
                <>
                  {`${t('Data do Cadastro')}: ${dataCriacao}`}
                  <br />
                </>
              )}
              {dataUltimaAlteracao && (
                <>{`${t('Última Alteração')}: ${dataUltimaAlteracao}`}</>
              )}
            </Tooltip>
          );
        }}
      >
        <DataIcon />
      </OverlayTriggerPadrao>
    </Container>
  );
};

export default memo(DataCriacaoAlteracao);
