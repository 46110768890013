import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  ModalFooter,
  Box,
  ModalHeader,
  useMediaQuery,
  Divider,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';

type ModalPendenciaNotaFiscalProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalProps> & {
    motivo: string;
    numeroVenda: number;
    handleGerarNotaFiscal: () => void;
    handleCancelarVenda: () => void;
  };

export const ModalPendenciaNotaFiscal = create<ModalPendenciaNotaFiscalProps>(
  ({
    onResolve,
    onReject,
    motivo,
    handleCancelarVenda,
    handleGerarNotaFiscal,
    numeroVenda,
    ...rest
  }) => {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? '4xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
      >
        <ModalContent
          bg="gray.50"
          borderRadius={isLargerThan900 ? 'md' : '0'}
          marginBottom={isLargerThan900 ? '3.75rem' : '0'}
          marginTop={isLargerThan900 ? '3.75rem' : '0'}
          w={isLargerThan900 ? '800px' : 'full'}
          h={isLargerThan900 ? '460px' : 'full'}
        >
          <ModalHeader pt="16px" pb="20px" pl="24px">
            <Text color="primary.50" fontSize="16px">
              Venda #{numeroVenda}
            </Text>
            <Text color="gray.700" fontSize="14px">
              O motivo da rejeição está exibido abaixo. Após corrigir, retorne a
              esta tela para emitir novamente a NFe.
            </Text>

            <Divider mt="16px" />
          </ModalHeader>

          <ModalBody
            pl="24px"
            pr="24px"
            mb="20px"
            pt="0"
            overflowY="auto"
            pb="0"
          >
            <Box>
              <Flex
                justifyContent="space-between"
                px="24px"
                py="12px"
                mb="4px"
                borderRadius="8px"
                color="black"
                h="49px"
                bg="red.100"
              >
                <Flex justifyContent="center" h="full" alignItems="center">
                  <Text fontWeight="bold">{motivo}</Text>
                </Flex>
              </Flex>
            </Box>
          </ModalBody>
          <ModalFooter flexDirection="column">
            <Divider mb="24px" />
            <Flex
              w="full"
              h="full"
              justifyContent="center"
              alignItems="baseline"
            >
              <Button
                variant="outlineDefault"
                colorScheme="gray"
                width="96px"
                mx="24px"
                onClick={() => onClose()}
              >
                Fechar
              </Button>
              <Button
                variant="solid"
                colorScheme="aquamarine.600"
                width="187px"
                onClick={() => {
                  onClose();
                  handleGerarNotaFiscal();
                }}
              >
                Emitir NFe Novamente
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
