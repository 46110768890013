import {
  useRef,
  ReactNode,
  useImperativeHandle,
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useState,
} from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import jsFileDownload from 'js-file-download';
import { toast } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';

import StatusFiscaisEnum, {
  StatusFiscais,
} from 'constants/enum/fiscal/statusFiscal';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { downloadArquivosXML } from 'helpers/api/NotaFiscal/downloadArquivosXML';
import { ImprimirPDF } from 'helpers/impressoes/imprimirPDF';
import ModelosFiscaisEnum from 'constants/enum/fiscal/modelosFiscais';
import ConstanteRotas from 'constants/rotas';
import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';
import { enumTypeCarne } from 'constants/enum/enumTypeCarne';

import {
  CompartilharImprimirIcon,
  CompartilharWhatsappIcon,
  CompartilharEmailIcon,
  RelatoriosIcon,
  FiscalExportarXmlIcon,
} from 'icons';
import ImpressaoNFCe, {
  ImpressaoNFCeRefElement,
} from 'components/Impressao/ImpressaoNFCe/ImpressaoNFCe';
import { DadosCompartilhadosProps } from 'components/Compartilhar/types/validationsTypes';
import ImpressaoCupomNaoFiscal, {
  ImpressaoCupomNaoFiscalRefElement,
} from 'components/Impressao/ImpressaoCupomNaoFiscal/ImpressaoCupomNaoFiscal';
import TabsMenu from 'components/TabsMenu';
import ImpressaoDevolucaoDinheiro, {
  ImpressaoDevolucaoDinheiroProps,
} from 'components/Impressao/impressaoDevolucaoDinheiro';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import ImpressaoCupomFechamentoCaixa, {
  ImpressaoCupomFechamentoCaixaRefElement,
} from 'components/Impressao/impressaoFechamentoCaixa';
import {
  ImpressaoVoucherDeTroca,
  ImpressaoVouhcerRefElement,
} from 'components/Impressao/imprimirVouhcer';
import ImpressaoCupomTroca from 'components/Impressao/ImpressaoCupomTroca/ImpressaoCupomTroca';
import { ImpressaoCarnePromissoriaRefElement } from 'components/Impressao/Carne/types';
import ImpressaoCarnePromissoria from 'components/Impressao/Carne';
import { ImpressaoCupomTrocaRefElement } from 'components/Impressao/ImpressaoCupomTroca/types';
import { ModalEscolherParcelasImpressaoCarne } from 'components/Modal/ModalEscolherParcelasImpressaoCarne';

import { InformacoesImpressaoNFCeInterface } from 'types/ImpressaoNFCe';
import { EmailModal } from './EmailModal';
import { WhatsAppModal } from './WhatsAppModal';
import { ImprimirVendaA4 } from './utils/CompartilharVendaA4';

type DocumentoFiscal = {
  id: string;
  numero: string;
  status: StatusFiscais;
};

interface ContatoAdicional {
  email: string;
  nome: string;
  telefone: string;
}

interface Cliente {
  celular?: string;
  email?: string;
  nome: string;
  telefone?: string;
  contatosAdicionais: ContatoAdicional[];
}

type Operacao = {
  id: string;
  NFCe?: DocumentoFiscal;
  NFe?: DocumentoFiscal;
  cliente: Cliente;
  numeroOperacao?: number;
};

export type PropsImpressao = {
  getPrintFunctions56mm?(): Promise<void>;
  getPrintFunctions80mm?(): Promise<void>;
  getPrintFunctionsFechamentoCaixa?(): Promise<void>;
  getPrintDevolucaoDinheiro?(): Promise<void>;
  handleImprimirVoucherDevolucao?(): Promise<void>;
  onClose?(): void;
};

interface CompartilharDocumentosFiscaisProps extends BoxProps {
  operacao?: Operacao;
  tipoOperacao?: number;
  isLoading: boolean;
  setIsLoading: (newValue: boolean) => void;
  children?: ReactNode;
  asAccordion?: boolean;
  hasTitle?: boolean;
  onClose?: () => void;
  sharedPerDepartment?: DadosCompartilhadosProps;
  movimentacaoFinanceiraId?: string[];
  caixaMovimentacaoId?: string;
  sharedWhatsApp?: boolean;
  mostrarCompartilharPorXml?: boolean;
  mostrarCompartilharPorEmail?: boolean;
  mostrarCompartilharPorImpressao?: boolean;
  modeloNotaFiscal?: number;
  notaFiscalStatus?: number;
  usuarioPodeExportarArquivoCSV?: boolean;
}

const CompartilharDocumentosFiscais: ForwardRefRenderFunction<
  PropsImpressao,
  CompartilharDocumentosFiscaisProps
> = (
  {
    operacao,
    tipoOperacao,
    isLoading,
    setIsLoading,
    children,
    onClose,
    mostrarCompartilharPorXml,
    mostrarCompartilharPorEmail,
    mostrarCompartilharPorImpressao,
    modeloNotaFiscal,
    notaFiscalStatus,
    asAccordion,
    sharedPerDepartment,
    hasTitle = true,
    movimentacaoFinanceiraId,
    caixaMovimentacaoId,
    sharedWhatsApp = true,
    usuarioPodeExportarArquivoCSV = false,
    ...rest
  }: CompartilharDocumentosFiscaisProps,
  ref
) => {
  const { pathname } = window.location;
  const [typeCarne, setTypeCarne] = useState(enumTypeCarne.NORMAL);

  const isTipoVenda = tipoOperacao === IdentificacaoTipoOperacaoEnum.VENDA;
  const operacaoCompartilharEhVoucher =
    pathname.includes('impressao-voucher') ||
    sharedPerDepartment?.listagemVouchers ||
    false;
  const operacaoCompartilaharEhDevolucaoDinheiro = pathname.includes(
    'impressao-devolucao'
  );

  const isCompartilharListagemNotaFiscal = pathname.includes(
    ConstanteRotas.NOTA_FISCAL
  );

  const compartilharNfPorEmailIsHidden =
    !mostrarCompartilharPorEmail && isCompartilharListagemNotaFiscal;

  const compartilharImpressaoIsHidden =
    !mostrarCompartilharPorImpressao && isCompartilharListagemNotaFiscal;

  const OperacaoEhDeDevolucao =
    !!sharedPerDepartment?.devolucoes?.impressao ||
    operacaoCompartilaharEhDevolucaoDinheiro;

  const compartilharComWppIsHidden =
    !sharedWhatsApp || OperacaoEhDeDevolucao || operacaoCompartilharEhVoucher;

  const compartilharPorEmailIsHidden =
    OperacaoEhDeDevolucao ||
    operacaoCompartilharEhVoucher ||
    compartilharNfPorEmailIsHidden;

  const imprimirCupomDevolucaoIsHidden =
    !OperacaoEhDeDevolucao ||
    Boolean(sharedPerDepartment?.devolucoes?.impressao.length);

  const podeCompartilharNFCe =
    operacao &&
    operacao.NFCe &&
    (operacao.NFCe.status === StatusFiscaisEnum.Autorizada ||
      operacao.NFCe.status === StatusFiscaisEnum.Cancelada);

  const podeCompartilharNFe =
    operacao &&
    operacao.NFe &&
    (operacao.NFe.status === StatusFiscaisEnum.Autorizada ||
      operacao.NFe.status === StatusFiscaisEnum.Cancelada);

  const statusPossuiCompartilhamento =
    notaFiscalStatus === StatusFiscaisEnum.Autorizada ||
    notaFiscalStatus === StatusFiscaisEnum.Cancelada ||
    notaFiscalStatus === StatusFiscaisEnum.EmDigitacao ||
    notaFiscalStatus === StatusFiscaisEnum.Rejeitada ||
    notaFiscalStatus === StatusFiscaisEnum.Inutilizada;

  const showCompartilharNFeNaListagemNotas =
    operacao &&
    Boolean(operacao.id) &&
    modeloNotaFiscal === ModelosFiscaisEnum.NFe &&
    statusPossuiCompartilhamento;

  const showCompartilharNFCeNaListagemNotas =
    operacao &&
    Boolean(operacao.id) &&
    modeloNotaFiscal === ModelosFiscaisEnum.NFCe &&
    statusPossuiCompartilhamento;

  const naoPossuiDanfe = !(
    notaFiscalStatus === StatusFiscaisEnum.EmDigitacao ||
    notaFiscalStatus === StatusFiscaisEnum.Autorizada
  );

  const naoPossuiCupomFiscal = !(
    notaFiscalStatus === StatusFiscaisEnum.Autorizada
  );

  const podeCompartilharNFeOuNFCe = podeCompartilharNFe && podeCompartilharNFCe;
  // const mostrarComprovante = imprimirRelatorioVenda?.length === 0;
  // const mostrarComprovanteEmail = imprimirEmailRelatorioVenda?.length === 0;

  const impressaoDevolucaoDinheiro = useRef<ImpressaoDevolucaoDinheiroProps>(
    null
  );
  const impressaoDevolucaoDinheiroContainerRef = useRef<HTMLDivElement>(null);
  const imprimirDevolucaoDinheiro = useReactToPrint({
    content: () => impressaoDevolucaoDinheiroContainerRef.current,
  });

  async function getPrintDevolucaoDinheiro() {
    setIsLoading(true);

    if (imprimirDevolucaoDinheiro && impressaoDevolucaoDinheiro.current) {
      await impressaoDevolucaoDinheiro.current.obterInformacoesImpressao();
      imprimirDevolucaoDinheiro();
    }
    if (onClose) onClose();
    setIsLoading(false);
  }

  async function handleImprimirDevolucaoDinheiro() {
    await getPrintDevolucaoDinheiro();
  }

  const cupomNaoFiscalComponentToPrintRef = useRef<ImpressaoCupomNaoFiscalRefElement>(
    null
  );
  const cupomNaoFiscalComponentToPrintContainerRef = useRef<HTMLDivElement>(
    null
  );

  const cupomTrocaComponentToPrintRef = useRef<ImpressaoCupomTrocaRefElement>(
    null
  );

  const impressaoCarneRef = useRef<ImpressaoCarnePromissoriaRefElement>(null);
  const carneImpressaoComponentToPrintRef = useRef<HTMLDivElement>(null);

  const imprimirCupomNaoFiscal = useReactToPrint({
    content: () => cupomNaoFiscalComponentToPrintContainerRef.current,
  });

  const cupomFiscalComponentToPrintRef = useRef<ImpressaoNFCeRefElement>(null);
  const cupomFiscalComponentToPrintContainerRef = useRef<HTMLDivElement>(null);
  const imprimirCupomFiscal = useReactToPrint({
    content: () => cupomFiscalComponentToPrintContainerRef.current,
  });

  const handleImprimirCupomFiscal = async () => {
    setIsLoading(true);
    if (
      imprimirCupomFiscal &&
      cupomFiscalComponentToPrintRef.current &&
      cupomFiscalComponentToPrintContainerRef
    ) {
      const impressaoCarregada = await cupomFiscalComponentToPrintRef.current.obterInformacoesImpressaoHandle();

      if (impressaoCarregada) {
        imprimirCupomFiscal();
      }
    }

    if (onClose) onClose();
    setIsLoading(false);
  };

  const impressaoVoucherComponentToPrintRef = useRef<ImpressaoVouhcerRefElement>(
    null
  );
  const impressaoVoucherContainerRef = useRef<HTMLDivElement>(null);

  const imprimirVouhcer = useReactToPrint({
    content: () => impressaoVoucherContainerRef.current,
  });

  async function handleImprimirVoucherDevolucao() {
    setIsLoading(true);
    if (
      impressaoVoucherComponentToPrintRef.current &&
      impressaoVoucherContainerRef.current &&
      imprimirVouhcer
    ) {
      await impressaoVoucherComponentToPrintRef.current.obterInformacoesImpressao();
      setTimeout(() => {
        imprimirVouhcer();
        setIsLoading(false);
      }, 100);
    }
  }

  // impressao do fechamento de caixa
  const impressaoFechamentoCaixaComponentToPrintRef = useRef<ImpressaoCupomFechamentoCaixaRefElement>(
    null
  );
  const impressaoFechamentoCaixaComponentToPrintContainerRef = useRef<HTMLDivElement>(
    null
  );

  const imprimirCupomFechamentoCaixa = useReactToPrint({
    content: () => impressaoFechamentoCaixaComponentToPrintContainerRef.current,
  });

  const getPrintFunctionsFechamentoCaixa = useCallback(async () => {
    setIsLoading(true);
    if (
      imprimirCupomFechamentoCaixa &&
      impressaoFechamentoCaixaComponentToPrintRef.current
    ) {
      await impressaoFechamentoCaixaComponentToPrintRef.current.obterInformacoesImpressao();
      imprimirCupomFechamentoCaixa();
    }

    setIsLoading(false);
  }, [imprimirCupomFechamentoCaixa, setIsLoading]);

  useImperativeHandle(ref, () => ({
    getPrintFunctionsFechamentoCaixa,
    getPrintDevolucaoDinheiro,
    onClose,
  }));

  // Cupom Não Fiscal
  const handleImprimirCupomNaoFiscal = async () => {
    setIsLoading(true);

    if (imprimirCupomNaoFiscal && cupomNaoFiscalComponentToPrintRef.current) {
      await cupomNaoFiscalComponentToPrintRef.current.obterInformacoesImpressao();

      imprimirCupomNaoFiscal();
    }

    if (onClose) onClose();
    setIsLoading(false);
  };

  const handleImprimirCupomTroca = async () => {
    if (cupomTrocaComponentToPrintRef.current) {
      await cupomTrocaComponentToPrintRef.current.imprimirCupom();
    }
  };

  const openModalEscolherParcelasImpressaoCarne = useCallback(async () => {
    if (impressaoCarneRef.current) {
      setIsLoading(true);
      const dados = await impressaoCarneRef.current.fetchPrintInformation();
      setIsLoading(false);
      if (dados && dados.recebimentos) {
        if (dados.recebimentos.length === 1) {
          impressaoCarneRef.current.printParcelas(
            dados.recebimentos.map((item) => item.id)
          );
          return;
        }
        try {
          const { parcelas } = await ModalEscolherParcelasImpressaoCarne({
            parcelas: dados?.recebimentos || [],
            isPdv: true,
            isLoading,
          });
          if (parcelas) {
            impressaoCarneRef.current.printParcelas(parcelas);
          }
        } catch {
          setIsLoading(false);
        }
      }
    }
  }, [isLoading, setIsLoading]);

  const handleImpressaoCarne = useCallback(
    async (type: string) => {
      setTypeCarne(type);

      await openModalEscolherParcelasImpressaoCarne();
    },
    [openModalEscolherParcelasImpressaoCarne]
  );

  const handleImprimirDanfe = async () => {
    if (operacao && operacao.NFe) {
      setIsLoading(true);

      const response = await api.get<void, ResponseApi<string>>(
        `${ConstanteEnderecoWebservice.NOTA_FISCAL_DANFE}/${operacao.NFe.id}`
      );

      if (response.sucesso) {
        jsFileDownload(
          Uint8Array.from(atob(response.dados), (c) => c.charCodeAt(0)),
          `Danfe_${operacao.NFe.numero}.pdf`
        );
      }

      if (response?.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }

      if (onClose) onClose();
      setIsLoading(false);
    }
  };

  const handleEnviarWhatsApp = async () => {
    if (operacao) {
      WhatsAppModal({
        cliente: operacao.cliente,
        operacaoId: operacao.id,
      });
      if (onClose) onClose();
    }
  };

  const handleEnviarWhatsAppCupomFiscal = async () => {
    if (operacao && operacao.NFCe) {
      const response = await api.get<
        void,
        ResponseApi<InformacoesImpressaoNFCeInterface>
      >(ConstanteEnderecoWebservice.NOTA_FISCAL_IMPRIMIR_NFCE, {
        params: { documentoFiscalId: operacao.NFCe?.id },
      });
      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }
        if (response.sucesso && response.dados) {
          WhatsAppModal({
            cliente: operacao.cliente,
            operacaoId: operacao.id,
            linkQRcode: response.dados.xml.informacoesSuplementaresXml.qrCode,
          });
        }
      }
      if (onClose) onClose();
    }
  };

  const handleEnviarEmailCupomFiscal = async () => {
    if (operacao && operacao.NFCe) {
      EmailModal({
        cliente: operacao.cliente,
        documentoFiscalId: operacao.NFCe.id,
      });
      if (onClose) onClose();
    }
  };

  const handleEnviarEmailDanfe = async () => {
    if (operacao && operacao.NFe) {
      EmailModal({
        cliente: operacao.cliente,
        documentoFiscalId: operacao.NFe.id,
      });
      if (onClose) onClose();
    }
  };

  const handleEnviarEmailCupomNaoFiscal = async () => {
    if (operacao) {
      EmailModal({
        cliente: operacao.cliente,
        operacaoId: operacao.id,
      });
      if (onClose) onClose();
    }
  };

  const handleDownloadXMLCupomFiscal = async () => {
    if (operacao && operacao.NFCe) {
      await downloadArquivosXML(operacao.NFCe.id, operacao.NFCe.numero);

      if (onClose) onClose();
    }
  };

  const handleDownloadXMLDanfe = async () => {
    if (operacao && operacao.NFe) {
      await downloadArquivosXML(operacao.NFe.id, operacao.NFe.numero);

      if (onClose) onClose();
    }
  };
  const userCanSendSalesOrders = Boolean(
    sharedPerDepartment?.contasPagar ||
      sharedPerDepartment?.contasReceber ||
      sharedPerDepartment?.notaFiscal ||
      sharedPerDepartment?.relatorioListarNotaFiscal ||
      sharedPerDepartment?.operacoes ||
      sharedPerDepartment?.relatorioConferenciaEstoque ||
      sharedPerDepartment?.faturas ||
      sharedPerDepartment?.relatorioFechamentoCaixa ||
      sharedPerDepartment?.sharedListContasReceber ||
      sharedPerDepartment?.listagemVouchers ||
      OperacaoEhDeDevolucao ||
      operacaoCompartilharEhVoucher ||
      sharedPerDepartment?.relatorioCurvaABC ||
      sharedPerDepartment?.historicoVendasMetasComissao ||
      sharedPerDepartment?.relatorioVendasMetasComissao
  );

  const handleButtonSendEmail = () => {
    if (sharedPerDepartment?.sharedListContasReceber?.email.length === 1) {
      sharedPerDepartment.sharedListContasReceber.email[0].onClick();
      return;
    }

    if (sharedPerDepartment?.contasReceber?.email.length === 1) {
      sharedPerDepartment.contasReceber?.email[0].onClick();
      return;
    }

    if (sharedPerDepartment?.contasPagar?.email.length === 1) {
      sharedPerDepartment.contasPagar?.email[0].onClick();
      return;
    }

    if (sharedPerDepartment?.relatorioConferenciaEstoque?.email.length === 1) {
      sharedPerDepartment.relatorioConferenciaEstoque?.email[0].onClick();
    }

    if (sharedPerDepartment?.faturas?.email.length === 1) {
      sharedPerDepartment.faturas?.email[0].onClick();
      return;
    }

    if (sharedPerDepartment?.notaFiscal?.email.length === 1) {
      sharedPerDepartment.notaFiscal?.email[0].onClick();
      return;
    }
    if (sharedPerDepartment?.relatorioListarNotaFiscal?.email.length === 1) {
      sharedPerDepartment.relatorioListarNotaFiscal?.email[0].onClick();
      return;
    }

    if (sharedPerDepartment?.relatorioFechamentoCaixa?.email.length === 1) {
      sharedPerDepartment.relatorioFechamentoCaixa?.email[0].onClick();
      return;
    }

    if (sharedPerDepartment?.operacoes?.email.length === 1) {
      sharedPerDepartment.operacoes?.email[0].onClick();
      return;
    }

    if (sharedPerDepartment?.relatorioCurvaABC?.email.length === 1) {
      sharedPerDepartment?.relatorioCurvaABC.email[0].onClick();
      return;
    }

    if (sharedPerDepartment?.historicoVendasMetasComissao?.email.length === 1) {
      sharedPerDepartment?.historicoVendasMetasComissao.email[0].onClick();
      return;
    }
    if (sharedPerDepartment?.relatorioVendasMetasComissao?.email.length === 1) {
      sharedPerDepartment?.relatorioVendasMetasComissao.email[0].onClick();

      return;
    }

    if (!userCanSendSalesOrders) {
      handleEnviarEmailCupomNaoFiscal();
    }
  };

  async function handleImprimirComprovanteA4() {
    setIsLoading(true);
    await ImprimirVendaA4({ id: operacao?.id });
    setIsLoading(false);
  }

  async function handleImpressaoMeiaFolha() {
    setIsLoading(true);
    const response = await api.get<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.IMPRESSAO_VENDA_MEIA_FOLHA,
      {
        params: { id: operacao?.id },
      }
    );

    if (response.avisos) {
      response.avisos.forEach((avisos) => toast.warning(avisos));
    }

    if (response.dados && response.sucesso) {
      ImprimirPDF(response.dados, 'relatorioDocFiscais');
    }
    setIsLoading(false);
  }

  const handleEnviarEmail = () => {
    if (podeCompartilharNFe && sharedPerDepartment?.relatorioListarNotaFiscal) {
      handleEnviarEmailDanfe();
    }
    if (
      podeCompartilharNFCe &&
      sharedPerDepartment?.relatorioListarNotaFiscal
    ) {
      handleEnviarEmailCupomFiscal();
    }
    if (!podeCompartilharNFCe && !podeCompartilharNFe) {
      handleButtonSendEmail();
    }
  };

  const handleDownloadXML = () => {
    if (podeCompartilharNFe || podeCompartilharNFCe) {
      if (podeCompartilharNFe) {
        handleDownloadXMLDanfe();
      }
      if (podeCompartilharNFCe) {
        handleDownloadXMLCupomFiscal();
      }
    } else {
      sharedPerDepartment?.relatorioListarNotaFiscal?.xml[0].onClick();
    }
  };

  return (
    <>
      {isLoading && <LoadingPadrao />}
      <Box px={{ base: 6, md: 10 }} py={{ base: 6, md: 12 }} {...rest}>
        <TabsMenu
          asAccordion={asAccordion}
          title={hasTitle ? 'Compartilhar' : undefined}
          w="full"
          tabs={[
            ...(compartilharImpressaoIsHidden
              ? []
              : [
                  {
                    icon: CompartilharImprimirIcon,
                    title: 'Imprimir',
                    options: [
                      {
                        title: 'Cupom fiscal',
                        onClick: handleImprimirCupomFiscal,
                        isHidden: isCompartilharListagemNotaFiscal
                          ? !showCompartilharNFCeNaListagemNotas ||
                            naoPossuiCupomFiscal
                          : !podeCompartilharNFCe,
                      },
                      {
                        title: 'Danfe',
                        onClick: handleImprimirDanfe,
                        isHidden: isCompartilharListagemNotaFiscal
                          ? !showCompartilharNFeNaListagemNotas ||
                            naoPossuiDanfe
                          : !podeCompartilharNFe,
                      },
                      {
                        title: 'Comprovante sem validade fiscal',
                        onClick: handleImprimirCupomNaoFiscal,
                        isHidden: userCanSendSalesOrders,
                      },
                      {
                        title: 'Comprovante para troca',
                        onClick: handleImprimirCupomTroca,
                        isHidden: !isTipoVenda,
                      },

                      {
                        title: 'Imprimir o comprovante de devolução',
                        onClick: handleImprimirDevolucaoDinheiro,
                        isHidden: imprimirCupomDevolucaoIsHidden,
                      },
                      {
                        title: 'Imprimir voucher',
                        onClick: handleImprimirVoucherDevolucao,
                        isHidden: !operacaoCompartilharEhVoucher,
                      },
                      {
                        title: 'Comprovante sem validade fiscal A4',
                        onClick: handleImprimirComprovanteA4,
                        isHidden: userCanSendSalesOrders,
                      },
                      {
                        title: 'Impressão meia folha',
                        onClick: handleImpressaoMeiaFolha,
                        isHidden: userCanSendSalesOrders,
                      },
                      {
                        title: 'Impressão carnê normal',
                        onClick: () =>
                          handleImpressaoCarne(enumTypeCarne.NORMAL),
                        isHidden: !isTipoVenda,
                      },
                      {
                        title: 'Impressão carnê promissória',
                        onClick: () =>
                          handleImpressaoCarne(enumTypeCarne.PROMISSORIA),
                        isHidden: !isTipoVenda,
                      },
                      ...(sharedPerDepartment?.relatorioFechamentoCaixa
                        ?.impressao ?? []),
                      ...(sharedPerDepartment?.sharedListContasReceber
                        ?.impressao ?? []),
                      ...(sharedPerDepartment?.contasReceber?.impressao ?? []),
                      ...(sharedPerDepartment?.contasPagar?.impressao ?? []),
                      ...(sharedPerDepartment?.faturas?.impressao ?? []),
                      ...(sharedPerDepartment?.notaFiscal?.impressao ?? []),
                      ...(sharedPerDepartment?.relatorioListarNotaFiscal
                        ?.impressao ?? []),
                      ...(sharedPerDepartment?.relatorioConferenciaEstoque
                        ?.impressao ?? []),
                      ...(sharedPerDepartment?.operacoes?.impressao ?? []),
                      ...(sharedPerDepartment?.devolucoes?.impressao ?? []),
                      ...(sharedPerDepartment?.relatorioCurvaABC?.impressao ??
                        []),
                      ...(sharedPerDepartment?.historicoVendasMetasComissao
                        ?.impressao ?? []),
                      ...(sharedPerDepartment?.relatorioVendasMetasComissao
                        ?.impressao ?? []),
                    ],
                  },
                ]),
            ...(!compartilharPorEmailIsHidden
              ? [
                  {
                    icon: CompartilharEmailIcon,
                    title: 'Enviar por e-mail',
                    onClick: podeCompartilharNFeOuNFCe
                      ? undefined
                      : handleEnviarEmail,
                    isHidden: compartilharPorEmailIsHidden,
                    options: [
                      {
                        title: isCompartilharListagemNotaFiscal
                          ? ''
                          : 'Cupom fiscal',
                        onClick: handleEnviarEmailCupomFiscal,
                        isHidden: isCompartilharListagemNotaFiscal
                          ? !showCompartilharNFCeNaListagemNotas
                          : !podeCompartilharNFCe,
                      },
                      {
                        title: isCompartilharListagemNotaFiscal ? '' : 'Danfe',
                        onClick: handleEnviarEmailDanfe,
                        isHidden: isCompartilharListagemNotaFiscal
                          ? !showCompartilharNFeNaListagemNotas
                          : !podeCompartilharNFe,
                      },
                      {
                        title: 'Comprovante sem validade fiscal',
                        onClick: handleEnviarEmailCupomNaoFiscal,
                        isHidden: userCanSendSalesOrders,
                      },
                      ...(sharedPerDepartment?.relatorioVendasMetasComissao
                        ?.email ?? []),
                      ...(sharedPerDepartment?.relatorioConferenciaEstoque
                        ?.email ?? []),
                      ...(sharedPerDepartment?.relatorioListarNotaFiscal
                        ?.email ?? []),
                    ],
                  },
                ]
              : []),

            ...(!compartilharComWppIsHidden
              ? [
                  {
                    icon: CompartilharWhatsappIcon,
                    title: 'Enviar via WhatsApp',
                    isHidden: compartilharComWppIsHidden,
                    onClick:
                      userCanSendSalesOrders && !podeCompartilharNFCe
                        ? handleEnviarWhatsAppCupomFiscal
                        : undefined,
                    options: [
                      {
                        title: 'Cupom fiscal',
                        onClick: handleEnviarWhatsAppCupomFiscal,
                        isHidden: !podeCompartilharNFCe,
                      },
                      {
                        title: 'Comprovante sem validade fiscal',
                        onClick: handleEnviarWhatsApp,
                        isHidden: userCanSendSalesOrders,
                      },
                    ],
                  },
                ]
              : []),
            ...(podeCompartilharNFCe ||
            (!isCompartilharListagemNotaFiscal && podeCompartilharNFe) ||
            mostrarCompartilharPorXml
              ? [
                  {
                    icon: FiscalExportarXmlIcon,
                    title: 'Download do XML',
                    onClick: isCompartilharListagemNotaFiscal
                      ? handleDownloadXML
                      : undefined,
                    options: [
                      {
                        title: isCompartilharListagemNotaFiscal
                          ? ''
                          : 'Cupom fiscal',
                        onClick: handleDownloadXMLCupomFiscal,
                        isHidden: isCompartilharListagemNotaFiscal
                          ? !showCompartilharNFCeNaListagemNotas
                          : !podeCompartilharNFCe,
                      },
                      {
                        title: isCompartilharListagemNotaFiscal ? '' : 'Danfe',
                        onClick: handleDownloadXMLDanfe,
                        isHidden: isCompartilharListagemNotaFiscal
                          ? !showCompartilharNFeNaListagemNotas
                          : !podeCompartilharNFe,
                      },
                      ...(sharedPerDepartment?.relatorioListarNotaFiscal?.xml ??
                        []),
                    ],
                  },
                ]
              : []),
            ...(usuarioPodeExportarArquivoCSV
              ? [
                  {
                    title: 'Exportar CSV',
                    icon: RelatoriosIcon,
                    options: [
                      ...(sharedPerDepartment?.relatorioCurvaABC?.exportarCSV ??
                        []),
                      ...(sharedPerDepartment?.relatorioConferenciaEstoque
                        ?.exportarCSV ?? []),
                    ],
                  },
                ]
              : []),
          ]}
        />

        {children}

        {operacao && operacao.NFCe && (
          <Box display="none">
            <ImpressaoNFCe
              ref={cupomFiscalComponentToPrintRef}
              containerRef={cupomFiscalComponentToPrintContainerRef}
              documentoFiscalId={operacao.NFCe.id}
              operacao={operacao}
            />
          </Box>
        )}
        {operacao && !OperacaoEhDeDevolucao && (
          <Box display="none">
            <ImpressaoCupomNaoFiscal
              ref={cupomNaoFiscalComponentToPrintRef}
              containerRef={cupomNaoFiscalComponentToPrintContainerRef}
              operacaoId={operacao.id}
            />
          </Box>
        )}

        {operacao && OperacaoEhDeDevolucao && (
          <Box display="none">
            <ImpressaoDevolucaoDinheiro
              ref={impressaoDevolucaoDinheiro}
              containerRef={impressaoDevolucaoDinheiroContainerRef}
              operacaoId={operacao.id}
            />
          </Box>
        )}

        {caixaMovimentacaoId && (
          <Box display="none">
            <ImpressaoCupomFechamentoCaixa
              ref={impressaoFechamentoCaixaComponentToPrintRef}
              containerRef={
                impressaoFechamentoCaixaComponentToPrintContainerRef
              }
              caixaMovimentacaoId={caixaMovimentacaoId}
            />
          </Box>
        )}

        {operacao && operacaoCompartilharEhVoucher && (
          <Box display="none">
            <ImpressaoVoucherDeTroca
              containerRef={impressaoVoucherContainerRef}
              ref={impressaoVoucherComponentToPrintRef}
              voucherId={operacao.id}
            />
          </Box>
        )}

        {operacao && (
          <Box display="none">
            <ImpressaoCupomTroca
              operacaoId={operacao.id}
              maxWidth="80mm"
              ref={cupomTrocaComponentToPrintRef}
            />
          </Box>
        )}
        {operacao && (
          <Box display="none">
            <ImpressaoCarnePromissoria
              operacaoId={operacao.id}
              ref={impressaoCarneRef}
              type={typeCarne}
              componentRef={carneImpressaoComponentToPrintRef}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default forwardRef(CompartilharDocumentosFiscais);
