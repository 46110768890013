import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Flex,
  useMediaQuery,
  Icon,
  Text,
  ModalHeader,
  ModalFooter,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { LogoutIcon } from 'icons';

type ResponseModal = {
  success: boolean;
};

type ModalInterromperAcessoProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ResponseModal> & {
    isCurrentUser: boolean;
  };
export const ModalInterromperAcesso = create<ModalInterromperAcessoProps>(
  ({ onResolve, onReject, isCurrentUser, ...rest }) => {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    const handleConfirm = () => {
      onResolve({ success: true });
      onClose();
    };

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? 'xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={() => {
          onReject({ success: false });
          onClose();
        }}
        autoFocus={false}
      >
        <ModalContent
          bg="gray.50"
          w={['full', 'full', '550px']}
          h={['full', 'full', '384px']}
          pb="32px"
        >
          <ModalHeader padding="0px">
            <Flex height="128px" bg="red.500" align="center" justify="center">
              <Icon
                as={LogoutIcon}
                fontSize="60px"
                color="white"
                sx={{
                  strokeWidth: '4px !important',
                }}
              />
            </Flex>
          </ModalHeader>
          <ModalBody
            justifyContent="center"
            alignItems="center"
            h="full"
            w="full"
            px="34px"
            py="0px"
          >
            <Text
              fontSize="20px"
              fontWeight="semibold"
              color="red.500"
              mt="32px"
            >
              Faça o login novamente!
            </Text>
            <Text
              mt="16px"
              fontSize="14px"
              fontWeight="500"
              letterSpacing="0px"
            >
              {isCurrentUser
                ? `Para que as alterações no cadastro de usuário sejam aplicadas,
              será necessário fazer o login novamente. Se preferir, continue
              utilizando o sistema e as alterações serão aplicadas no seu
              próximo acesso. Deseja aplicar as alterações imediatamente?`
                : 'Para que as alterações no cadastro desse usuário sejam aplicadas, será necessário interromper o acesso do usuário. Se preferir, continue utilizando o sistema e as alterações serão aplicadas no próximo acesso do usuário. Deseja interromper o acesso do usuário imediatamente?'}
            </Text>
          </ModalBody>
          <ModalFooter justifyContent="center" display="flex" padding="0px">
            <Button
              w="120px"
              mr="24px"
              colorScheme="gray"
              variant="outlineDefault"
              borderRadius="full"
              onClick={() => {
                onReject({ success: false });
                onClose();
              }}
              height="32px"
              fontWeight="500"
              fontSize="14px"
            >
              Continuar
            </Button>
            <Button
              w="216px"
              colorScheme="red"
              variant="solid"
              borderRadius="full"
              fontWeight="500"
              fontSize="14px"
              height="32px"
              onClick={handleConfirm}
            >
              {isCurrentUser ? 'Fazer login novamente' : 'Interromper acesso'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
