import auth from 'modules/auth';
import PlanoContratacaoEnum from './planoContratacao';

const planoAtual = auth.getPlano();

const isPlanoStart = planoAtual === PlanoContratacaoEnum.START;
const isPlanoPro = planoAtual === PlanoContratacaoEnum.PRO;

// No plano start as tabs Tabela preços e Local de estoque não estão disponíveis e no plano pro a tab Local de estoque não está disponível
export const enumTabsConfiguracaoTray = {
  DADOS_GERAIS: 0,
  VENDEDOR: 1,
  TABELA_PRECO: 2,
  LOCAL_ESTOQUE: 3,
  SITUACAO: isPlanoStart ? 2 : isPlanoPro ? 3 : 4,
  INTERMEDIADOR: isPlanoStart ? 3 : isPlanoPro ? 4 : 5,
} as const;
