/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import {
  Flex,
  HStack,
  Icon,
  IconButton,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { FiArrowDownCircle, FiCheck } from 'react-icons/fi';
import { toast } from 'react-toastify';

import ModelosFiscaisEnum from 'constants/enum/fiscal/modelosFiscais';
import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { TipoAcaoEnum } from 'constants/enum/tipoAcao';
import { getName } from 'helpers/enum/getName';
import api, { ResponseApi } from 'services/api';
import { CasasDecimais } from 'store/Padronizacao/Padronizacao';

import { TextValor } from 'components/PDV/Text/TextValor';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { ModalVisualizarOperacao } from 'components/Modal/ModalVisualizarOperacao';

import { ModalDetalheContasAReceber } from 'pages/ContasReceber/Listar/ModalDetalheContasAReceber';
import { ModalDetalhesContaPagar } from 'pages/ContasPagar/Listar/ModalDetalhesContaPagar';

import { ModalObservacoes } from './ModalObservacoes';
import { ExtratoLancamento } from './Types';

interface ItemLancamentoExtratoProps {
  lancamento: ExtratoLancamento;
  isLast: boolean;
  casasDecimais: CasasDecimais;
  toggleLancamentoConciliado: () => void;
  toggleLancamentoVisualmenteConciliado: () => void;
  recarregarListagem: () => void;
}

const estiloPadrao = {
  corFonte: 'gray.700',
  corFundo: 'aquamarine.50',
  corTooltip: 'gray.900',
  descricaoConta: 'Conta recebida',
  tamanhoTooltip: '36',
};

export const ItemLancamentoExtrato = ({
  lancamento,
  isLast,
  casasDecimais,
  toggleLancamentoConciliado,
  toggleLancamentoVisualmenteConciliado,
  recarregarListagem,
}: ItemLancamentoExtratoProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isVisualmenteConciliado, setIsVisualmenteConciliado] = useState(false);

  const isEntrada = lancamento.acaoFinanceira === TipoAcaoEnum.ENTRADA;

  const documentoFiscalFormatado =
    (lancamento.documentosFiscais || []).length > 0
      ? `${getName(
          ModelosFiscaisEnum,
          lancamento.documentosFiscais[0].modeloFiscal
        )} | ${lancamento.documentosFiscais?.[0].numero ?? ''}`
      : '';

  const deveExibirAcaoExcluir =
    lancamento.tipoOperacao === IdentificacaoTipoOperacaoEnum.CONTAS_PAGAR ||
    lancamento.tipoOperacao === IdentificacaoTipoOperacaoEnum.CONTAS_RECEBER;

  const deveExibirAcaoConciliarDesconciliar = !lancamento.antecipado;

  const deveExibirAcaoDetalhe =
    IdentificacaoTipoOperacaoEnum.AJUSTE_SALDO_SAIDA !==
      lancamento.tipoOperacao ||
    IdentificacaoTipoOperacaoEnum.AJUSTE_SALDO_SAIDA !==
      lancamento.tipoOperacao;

  const deveExibirAcaoVerObservacao = !!lancamento.observacao;

  const lancamentoTipoTransferenciaDinheiroSaidaOuEntrada =
    lancamento.tipoOperacao ===
      IdentificacaoTipoOperacaoEnum.TRANSFERENCIA_DINHEIRO_SAIDA ||
    lancamento.tipoOperacao ===
      IdentificacaoTipoOperacaoEnum.TRANSFERENCIA_DINHEIRO_ENTRADA;

  async function requestConciliacao(conciliar: boolean) {
    setIsLoading(true);

    const response = await api.put<void, ResponseApi>(
      ConstanteEnderecoWebservice.EXTRATO_CONCILIACAO,
      {
        id: lancamento.movimentacaoFinanceiraBaixaId,
        conciliado: conciliar,
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso) {
        toggleLancamentoConciliado();
      }
    }

    setIsLoading(false);
  }

  async function handleConciliar() {
    await requestConciliacao(true);
  }

  async function handleDesconciliar() {
    if (lancamento.antecipado) {
      toast.warning(
        'Não foi possível desconciliar essa movimentação, para desconciliar é necessário cancelar a antecipação.'
      );
      return;
    }
    await requestConciliacao(false);
  }

  async function handleDetalhesContasReceber() {
    await ModalDetalheContasAReceber({
      id: lancamento.movimentacaoFinanceiraId,
      informacaoStatusVencimento: estiloPadrao,
      casasDecimaisValor: 2,
    });
  }

  async function handleDetalhesContasPagar() {
    await ModalDetalhesContaPagar({
      id: lancamento.movimentacaoFinanceiraId,
      informacaoStatusVencimento: estiloPadrao,
      casasDecimaisValor: 2,
    });
  }

  async function excluirExtrato() {
    setIsLoading(true);

    const response = await api.delete<void, ResponseApi>(
      ConstanteEnderecoWebservice.EXTRATO_EXCLUIR,
      {
        params: {
          idMovimentacaoBaixa: lancamento.movimentacaoFinanceiraBaixaId,
        },
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso) {
        recarregarListagem();
      }
    }

    setIsLoading(false);
  }

  async function handleExcluir() {
    if (lancamento.qtdParcelas > 1) {
      await ModalConfirmacaoExcluir({
        text:
          'Esta movimentação possui outras parcelas e todas serão excluídas. Deseja continuar?',
        confirmButtonText: 'Sim, continuar!',
        callback: async (ok: boolean) => {
          if (ok) {
            excluirExtrato();
          }
        },
      });
    } else {
      excluirExtrato();
    }
  }

  function handleDetalharConta() {
    ModalVisualizarOperacao({
      casasDecimais,
      operacaoId: lancamento.operacaoId,
      entradaMercadoriaId: lancamento.entradaMercadoriaId,
      identificacaoTipoOperacao: lancamento.tipoOperacao,
    });
  }

  function handleAbrirModalObservacoes() {
    ModalObservacoes({ observacao: lancamento.observacao });
  }

  function handleToogleVisualmenteConciliado() {
    toggleLancamentoVisualmenteConciliado();
  }

  function handleAlertaNaoExisteDetalhe() {
    toast.warning('Esse tipo de conta não possui detalhes.');
  }

  function handleExibirDetalhes(tipoOperacao: number) {
    switch (tipoOperacao) {
      case IdentificacaoTipoOperacaoEnum.CONTAS_PAGAR: {
        return handleDetalhesContasPagar;
      }
      case IdentificacaoTipoOperacaoEnum.CONTAS_RECEBER: {
        return handleDetalhesContasReceber;
      }
      default: {
        return handleDetalharConta;
      }
    }
  }

  useEffect(() => {
    setIsVisualmenteConciliado(lancamento.visualmenteConciliado);
  }, [lancamento.visualmenteConciliado]);

  return (
    <Flex
      h="108px"
      borderBottom={isLast ? 'none' : '1px'}
      borderColor="gray.100"
      alignItems="center"
      position="relative"
      bg={
        isVisualmenteConciliado || lancamento.conciliado ? 'orange.50' : 'white'
      }
    >
      <IconButton
        aria-label="Ação financeira"
        variant="unstyled"
        h="5"
        w="5"
        minW="0px"
        py="0px !important"
        px="0px !important"
        bg={lancamento.conciliado ? 'orange.500' : 'white'}
        borderRadius="full"
        position="absolute"
        top="50%"
        transform={`translateY(-50%) ${
          lancamento.conciliado
            ? ''
            : isEntrada
            ? 'rotate(-45deg)'
            : 'rotate(130deg)'
        }`}
        left="-2.5"
        display="flex"
        alignItems="center"
        justifyContent="center"
        icon={
          <Icon
            as={lancamento.conciliado ? FiCheck : FiArrowDownCircle}
            color={
              lancamento.conciliado
                ? 'white'
                : isEntrada
                ? 'aquamarine.600'
                : 'red.500'
            }
            boxSize={lancamento.conciliado ? '3' : '5'}
            display="flex"
          />
        }
        onClick={lancamento.conciliado ? handleDesconciliar : handleConciliar}
        isLoading={isLoading}
        _loading={{ color: 'orange.500', opacity: 1, bg: 'white' }}
      />

      <HStack
        w="full"
        h="full"
        spacing="20"
        justifyContent="space-between"
        pl="6"
        cursor={lancamento.conciliado ? 'default' : 'pointer'}
        onClick={
          lancamento.conciliado ? undefined : handleToogleVisualmenteConciliado
        }
      >
        <Flex flexDir="column" w={{ base: '100px', lg: '300px', xl: '400px' }}>
          <Text fontSize="sm">
            {getName(IdentificacaoTipoOperacaoEnum, lancamento.tipoOperacao)}
          </Text>

          <Text fontSize="xs" color="gray.400" mt="1">
            {`Cód: ${lancamento.numeroOperacao}`}
          </Text>
        </Flex>

        <Flex flexDir="column" w={{ base: '150px', lg: 'full' }} py="2">
          <Text fontSize="sm">{lancamento.clienteFornecedorNome || '---'}</Text>

          <Text fontSize="xs" color="gray.400" mt="1">
            {`${lancamento.formaPagamentoRecebimentoNome}
              ${
                lancamento.competencia
                  ? `   -  Competência ${lancamento.competencia}`
                  : ''
              }
              ${
                documentoFiscalFormatado
                  ? `   -  Doc: ${documentoFiscalFormatado}`
                  : ''
              } `}
          </Text>
          <Tooltip
            maxW={{ base: '150px', lg: '2xl' }}
            label="Para mais detalhes da observação você pode clicar em Ver observação no menu de ações"
            noOfLines={3}
            fontSize="xs"
          >
            <Text
              color="gray.400"
              mt="1"
              noOfLines={{ base: 1, lg: 3 }}
              maxW={{ base: '150px', lg: '2xl' }}
            >
              {`${
                lancamento.observacao ? `Obs: ${lancamento.observacao}` : ''
              }`}
            </Text>
          </Tooltip>
        </Flex>

        <Flex
          flexDir="column"
          alignItems="flex-end"
          minW={{ base: '50px', lg: '70px', xl: '150px' }}
        >
          <TextValor
            valor={isEntrada ? lancamento.valor : lancamento.valor * -1}
            casasDecimais={casasDecimais.casasDecimaisValor}
            fontSize="sm"
            fontWeight="regular"
            color={isEntrada ? 'gray.700' : 'red.500'}
          />
        </Flex>

        <Flex
          flexDir="column"
          alignItems="flex-end"
          minW={{ base: '50px', lg: '70px', xl: '150px' }}
        >
          <TextValor
            valor={lancamento.saldo}
            casasDecimais={casasDecimais.casasDecimaisValor}
            fontSize="sm"
            fontWeight="regular"
            color={lancamento.saldo > 0 ? 'gray.700' : 'red.500'}
          />
        </Flex>
      </HStack>

      <Flex flexDir="column" w="80px" px="6">
        <ActionsMenu
          items={[
            ...(deveExibirAcaoExcluir
              ? [
                  {
                    content: 'Excluir',
                    onClick: handleExcluir,
                  },
                ]
              : []),
            ...(deveExibirAcaoConciliarDesconciliar
              ? [
                  {
                    content: lancamento.conciliado
                      ? 'Desconciliar'
                      : 'Conciliar',
                    onClick: lancamento.conciliado
                      ? handleDesconciliar
                      : handleConciliar,
                  },
                ]
              : []),
            ...(deveExibirAcaoDetalhe
              ? [
                  {
                    content: 'Detalhar conta',
                    onClick: lancamentoTipoTransferenciaDinheiroSaidaOuEntrada
                      ? handleAlertaNaoExisteDetalhe
                      : handleExibirDetalhes(lancamento.tipoOperacao),
                  },
                ]
              : []),
            ...(deveExibirAcaoVerObservacao
              ? [
                  {
                    content: 'Ver observação',
                    onClick: handleAbrirModalObservacoes,
                  },
                ]
              : []),
          ]}
        />
      </Flex>
    </Flex>
  );
};
