import { Box, GridItem, Tr, Td, Link } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';

import getOptionsByEnum from 'helpers/format/getOptionsByEnum';
import StatusConsultaEnum from 'constants/enum/statusConsulta';

import InputDateRange from 'components/PDV/InputDateRange';
import Input from 'components/PDV/Input';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { ButtonCadastrarNovo } from 'components/Layout/ButtonCadastrarNovo';
import { PagedTable } from 'components/update/Table/PagedTable';
import { StatusCircle } from 'components/update/Table/StatusCircle';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import { FilterSelect } from 'components/update/Select/FilterSelect';

import { useListarPromocao } from './hooks';

export const Promocao = () => {
  const {
    isLoading,
    totalRegistros,
    listarPromocao,
    history,
    pagedTableRef,
    paginationHandle,
    handleCadastrarPromocao,
    filtersSubmit,
    formMethods,
    excluirHandle,
    getNomeLinkHref,
  } = useListarPromocao();

  return (
    <FormProvider {...formMethods}>
      <SimpleGridForm>
        <GridItem colSpan={{ base: 12, md: 4, lg: 5 }}>
          <Input
            label=""
            onEnterKeyPress={() => filtersSubmit()}
            borderRadius="md"
            placeholder="Digite o nome da campanha"
            name="nome"
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, md: 3, lg: 3 }}>
          <FilterSelect
            id="status"
            name="status"
            options={getOptionsByEnum(StatusConsultaEnum)}
            onSelect={() => {
              filtersSubmit();
            }}
            isDisabled={isLoading}
          />
        </GridItem>

        <GridItem
          colSpan={{ base: 12, md: 5, lg: 4 }}
          display={{ base: '', sm: '', md: 'flex' }}
          justifyContent={{ base: 'flex-start', sm: 'flex-end' }}
        >
          <ButtonCadastrarNovo
            variant="solid"
            color="primary.700"
            colorScheme="secondary.400"
            onClick={() => handleCadastrarPromocao()}
            funcionalidade=""
          />
        </GridItem>
        <GridItem
          colSpan={12}
          justifyContent={{
            base: 'space-evenly',
            xl: 'flex-start',
          }}
          alignItems="center"
          minH={{ base: '200px', md: '16', lg: '16' }}
          p="4"
          bg="primary.700"
          borderRadius="md"
        >
          <Box w={{ base: 'full', md: '300px', lg: '400px' }}>
            <InputDateRange
              borderColor="gray.100"
              borderRadius="md"
              name="dataVigencia"
              onConfirm={() => filtersSubmit()}
              startDateName="vigenciaInicio"
              endDateName="vigenciaFim"
            />
          </Box>
        </GridItem>
        <GridItem colSpan={12}>
          <PagedTable
            ref={pagedTableRef}
            isLoading={isLoading}
            variant="card"
            bg="transparent"
            boxShadow="none"
            paddingRight="5px"
            paddingLeft="5px"
            pb="10px"
            paginationHasDivider={false}
            loadColumnsData={paginationHandle}
            itemsTotalCount={totalRegistros}
            defaultKeyOrdered="nome"
            tableHeaders={[
              {
                key: 'ativo',
                isOrderable: false,
                content: <StatusCircle hasValue={false} />,
                w: '1px',
                paddingRight: '0',
              },
              {
                key: 'nome',
                content: 'Nome',
                isOrderable: false,
              },

              { key: 'acoes', content: 'Ações', isOrderable: false, w: '1px' },
            ]}
            renderTableRows={(listarPromocao || []).map((itemPromocao) => {
              return (
                <>
                  <Tr
                    sx={{
                      boxShadow: '0px 0px 4px #00000029',
                      borderRadius: '6px',
                      '& > td': {
                        height: '30px',
                        bg: 'white',
                      },
                    }}
                  >
                    <Td>
                      <StatusCircle isActive={itemPromocao.ativo} />
                    </Td>
                    <Td fontWeight="bold">
                      <Link
                        id="link-visualizar"
                        href={getNomeLinkHref(itemPromocao.id)}
                        whiteSpace="nowrap"
                      >
                        {itemPromocao.nome}
                      </Link>
                    </Td>

                    <Td>
                      <ActionsMenu
                        items={[
                          {
                            content: 'Alterar',
                            onClick: () =>
                              history.push(getNomeLinkHref(itemPromocao.id)),
                          },
                          {
                            content: 'Excluir',
                            onClick: () =>
                              excluirHandle(
                                itemPromocao.id,
                                itemPromocao.ativo
                              ),
                          },
                        ]}
                      />
                    </Td>
                  </Tr>
                  <Box h="5px" />
                </>
              );
            })}
          />
        </GridItem>
      </SimpleGridForm>
    </FormProvider>
  );
};
