interface GetNameEnumInterface {
  name: string;
  value: number;
}

export const getName = (enumObj: any, valor: any): string => {
  const valueInt =
    typeof valor !== 'number' ? parseFloat(valor) : (valor as number);
  return Object.entries(enumObj.properties as GetNameEnumInterface).filter(
    (obj: any) => obj[1].value === valueInt
  )[0][1].name as string;
};

export const getValue = (enumObj: any, name: any): number => {
  return Object.entries(enumObj.properties as GetNameEnumInterface).filter(
    (obj: any) => obj[1].name === name
  )[0][1].value as number;
};

export const getEnumByName = (
  enumObj: any,
  name: any
): GetNameEnumInterface => {
  return Object.entries(enumObj.properties as GetNameEnumInterface).filter(
    (obj: any) => obj[1].name === name
  )[0][1] as GetNameEnumInterface;
};
