import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import isPrenvent from 'helpers/layout/isPrenvent';
import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import useReloadRegistration from 'helpers/layout/useReloadRegistration';

import { CategoriaPermissaoInterface } from 'components/Permissoes';
import { ContainerListagem } from 'components/Layout/Listagem/containerListagem';

import { useForm, yupResolver, PermissoesProps } from '../validationForm';
import { UncontrolledForm } from '..';

const Cadastro = () => {
  const history = useHistory();
  const isMountedRef = useIsMountedRef();

  const [isLoading, setIsLoading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [categorias, setCategorias] = useState(
    [] as Array<CategoriaPermissaoInterface>
  );
  const [listaPermissoes, setListaPermissoes] = useState<PermissoesProps[]>([]);

  const formMethods = useForm({
    resolver: yupResolver,
    shouldUnregister: false,
  });

  const {
    handleSubmit,
    reset,
    watch,
    getValues,
    formState,
    setFocus,
  } = formMethods;

  const historyReload = useReloadRegistration({ setFormIsDirty });
  isPrenvent(formIsDirty);

  const permissoeswatch = watch('permissoes');

  const atualizarValoresPermissao = useCallback(() => {
    return listaPermissoes.map((permissao, index) => {
      return {
        id: permissao.id,
        selecionado: permissoeswatch[index],
      };
    });
  }, [listaPermissoes, permissoeswatch]);

  async function handlePostPerfilUsuario() {
    const permissao = await atualizarValoresPermissao();
    const data = {
      ativo: getValues().ativo,
      dataHoraCadastro: getValues().dataHoraCadastro,
      dataHoraUltimaAlteracao: getValues().dataHoraUltimaAlteracao,
      descricao: getValues().descricao,
      permissoes: permissao,
    };

    const response = await api.post<void, ResponseApi>(
      ConstanteEnderecoWebservice.PERFIL_USUARIO_CADASTRAR,
      data
    );

    if (response.sucesso) {
      return true;
    }

    if (response.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    return false;
  }

  const onSubmit = handleSubmit(async () => {
    setIsLoading(true);

    const success = await handlePostPerfilUsuario();

    if (success) {
      toast.success('O cadastro foi realizado com sucesso.');

      setFormIsDirty(false);

      history.push(ConstanteRotas.PERFIL_USUARIO);
    }

    setIsLoading(false);
  });

  const onSubmitReset = handleSubmit(async () => {
    setIsLoading(true);

    const success = await handlePostPerfilUsuario();

    if (success) {
      toast.success('O cadastro foi realizado com sucesso.');
      if (isMountedRef.current) setFocus('descricao');

      historyReload(ConstanteRotas.PERFIL_USUARIO_CADASTRAR);
    }

    setIsLoading(false);
  });

  useEffect(() => {
    categorias.forEach((categoria) => {
      categoria.grupos.forEach((grupo) => {
        grupo.permissoes.forEach((permissao) => {
          setListaPermissoes((valorAnterior) => [
            ...valorAnterior,
            { id: permissao.id },
          ]);
        });
      });
    });
  }, [categorias]);

  useEffect(() => {
    setFormIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  const handleGetCategoriaPermissao = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<
      void,
      ResponseApi<Array<CategoriaPermissaoInterface>>
    >(ConstanteEnderecoWebservice.PERFIL_USUARIO_LISTAR_CATEGORIA_PERMISSOES, {
      params: { perfilId: null },
    });

    if (response?.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (response?.sucesso && isMountedRef.current) {
      setCategorias(response.dados);
    }

    if (isMountedRef.current) setIsLoading(false);
  }, [isMountedRef]);

  useEffect(() => {
    reset({
      descricao: '',
      ativo: true,
      permissoes: [],
    });
    handleGetCategoriaPermissao();
  }, [handleGetCategoriaPermissao, reset]);

  return (
    <ContainerListagem
      formMethods={formMethods}
      isLoading={isLoading}
      onSubmit={onSubmit}
      onSubmitReset={onSubmitReset}
      formIsDirty={formIsDirty}
      maxWidth="full"
    >
      <UncontrolledForm categorias={categorias} />
    </ContainerListagem>
  );
};

export default Cadastro;
