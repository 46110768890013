export type StatusConsultaEnum = 1 | 2 | 3;

const StatusConsultaEnum = {
  ATIVOS: 1,
  INATIVOS: 2,
  TODOS: 3,

  properties: {
    1: { label: 'Ativos', value: 1 },
    2: { label: 'Inativos', value: 2 },
    3: { label: 'Todos', value: 3 },
  },
};

export default StatusConsultaEnum;

export const statusConsultaOptions = [
  { label: 'Ativos', value: StatusConsultaEnum.ATIVOS },
  { label: 'Inativos', value: StatusConsultaEnum.INATIVOS },
  { label: 'Todos', value: StatusConsultaEnum.TODOS },
];
