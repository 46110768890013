import { useCallback, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { toast } from 'react-toastify';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';
import { MeioPagamentosTEF } from 'constants/enum/fiscal/meioPagamento';
import { sistemaApiKey } from 'services/sistemaApiKey';

import {
  DefaultInfoTef,
  FormData,
  idConfigTefDefault,
  valueDefaultInfoTef,
  FormaRecebimentoProps,
} from './validationForms';

export const useFormaRecebimento = (formMethods: UseFormReturn<FormData>) => {
  const [listPagamentoCartao, setListPagamentoCartao] = useState<
    { label: string; value: string }[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  const { setValue, watch, handleSubmit, reset, getValues } = formMethods;

  const [
    tefWatch,
    tipoCartaoWatch,
    operacaoTEFWatch,
    parcelamentoTEFWatch,
    credenciadoraCartaoIdWatch,
  ] = watch([
    'tef',
    'tipoCartao',
    'operacaoTEF',
    'parcelamentoTEF',
    'credenciadoraCartaoId',
  ]);

  const listFormaRecebimento = [
    ...(tipoCartaoWatch || []),
    ...(operacaoTEFWatch || []),
    ...(parcelamentoTEFWatch || []),
    ...(credenciadoraCartaoIdWatch || []),
  ];

  const isFormaRecebimentoVinculado = listFormaRecebimento.some(
    (formaRecebimentoItem) =>
      formaRecebimentoItem === null || formaRecebimentoItem === undefined
  );

  const alterarConfigTef = async (data: FormData) => {
    const dataConfiguracao =
      data.tef === false
        ? []
        : {
            loja: data.loja,
            endereco: data.endereco,
            parametros: data.parametros,
            senhaSupervisor: data.senhaSupervisor,
            tef: data.idConfigTef,
            listaFormaPagamentoRecebimento: listPagamentoCartao.map(
              (pagamentoCartaoItem, index) => ({
                id: pagamentoCartaoItem.value,
                tef: data.tef,
                tipoCartaoTefId: data?.tipoCartao && data?.tipoCartao[index],
                operacaoTefId: data?.operacaoTEF && data?.operacaoTEF[index],
                parcelamentoTefId:
                  data?.parcelamentoTEF && data?.parcelamentoTEF[index],
                credenciadoraCartaoId:
                  data.credenciadoraCartaoId &&
                  data.credenciadoraCartaoId[index],
              })
            ),
          };

    const response = await sistemaApiKey().post<void, ResponseApi<FormData>>(
      ConstanteEnderecoWebservice.PDV_ALTERAR_CONFIGURACAO_TEF,
      dataConfiguracao
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }
      if (response.sucesso) {
        toast.success('Configuração alterada com sucesso');
        setIsLoading(false);
      }
    }
    setIsLoading(false);
  };

  const handleUpdateFormaRecebimento = handleSubmit(async (data) => {
    setIsLoading(true);

    await alterarConfigTef(data);
  });

  const handleConfigHasTef = async (hasTef: boolean) => {
    if (!hasTef) {
      const data = getValues();
      valueDefaultInfoTef.forEach((itemInfo) => {
        Object.keys(itemInfo).forEach((name) => {
          if (itemInfo) {
            const value = itemInfo[name as keyof DefaultInfoTef];
            setValue(name as keyof DefaultInfoTef, value);
          }
        });
      });
      await alterarConfigTef(data);
    } else {
      setValue('idConfigTef', idConfigTefDefault);
    }
  };

  const getFormaRecebimento = useCallback(async () => {
    setIsLoading(true);
    const response = await sistemaApiKey().get<
      void,
      ResponseApi<FormaRecebimentoProps>
    >(ConstanteEnderecoWebservice.PDV_AUTONOMO_OBTER_FORMA_RECEBIMENTO);

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }
      if (response.sucesso) {
        const { dados } = response;

        const listFormaRecebimentoVinculo = listPagamentoCartao
          .map((itemFormaPagamento) => {
            const valueItemAdicionado = dados?.listaFormaPagamentoRecebimento?.find(
              (item) => item.id === itemFormaPagamento.value
            );

            return valueItemAdicionado;
          })
          .filter(Boolean);

        const credenciadoraCartaoId = listFormaRecebimentoVinculo?.map(
          (item) => item?.credenciadoraCartaoId
        );
        const operacaoTEF = listFormaRecebimentoVinculo?.map(
          (item) => item?.operacaoTefId
        );
        const parcelamentoTEF = listFormaRecebimentoVinculo?.map(
          (item) => item?.parcelamentoTefId
        );
        const tipoCartao = listFormaRecebimentoVinculo?.map(
          (item) => item?.tipoCartaoTefId
        );

        reset({
          loja: dados?.loja,
          endereco: dados?.endereco,
          parametros: dados?.parametros,
          senhaSupervisor: dados?.senhaSupervisor,
          idConfigTef: dados?.tef,
          tef: !!dados?.tef,
          credenciadoraCartaoId,
          operacaoTEF,
          parcelamentoTEF,
          tipoCartao,
        });
        setIsLoading(false);
      }
    }
    setIsLoading(false);
  }, [listPagamentoCartao, reset]);

  const obterValoresFormaRecebimento = useCallback(async () => {
    const response = await api.get<
      void,
      ResponseApi<{ nome: string; id: string; regraMeioPagamento: number }[]>
    >(ConstanteEnderecoWebservice.FORMA_RECEBIMENTO_LISTAR_SELECT_RECEBIMENTO);

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        const data = response.dados
          .filter(
            (pagamentoItem) =>
              pagamentoItem.regraMeioPagamento ===
                MeioPagamentosTEF.CartaoCreditoLink ||
              pagamentoItem.regraMeioPagamento ===
                MeioPagamentosTEF.CartaoCreditoMaquina ||
              pagamentoItem.regraMeioPagamento ===
                MeioPagamentosTEF.CartaoDebito
          )
          .map((item) => ({
            value: item.id,
            label: item.nome,
          }));
        setListPagamentoCartao(data);
      }
    }
  }, []);

  useEffect(() => {
    const getRequests = async () => {
      setIsLoading(true);
      await Promise.all([
        obterValoresFormaRecebimento(),
        getFormaRecebimento(),
      ]);
      setIsLoading(false);
    };
    getRequests();
  }, []);

  return {
    handleConfigHasTef,
    handleUpdateFormaRecebimento,
    tefWatch,
    listPagamentoCartao,
    isLoading,
    isFormaRecebimentoVinculado,
  };
};
