import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useHistory } from 'react-router-dom';

import {
  Flex,
  Text,
  Button,
  Icon,
  GridItem,
  Tr,
  Td,
  Link,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { FormProvider } from 'react-hook-form';

import auth from 'modules/auth';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';
import { enumReferenciaServicoStargate } from 'constants/enum/referenciaServicoStargate';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteFuncionalidades from 'constants/permissoes';
import PlanoContratacaoEnum from 'constants/enum/planoContratacao';
import { useProdutosFiltrosContext } from 'store/Produtos/ProdutosFiltros';
import api, { ResponseApi } from 'services/api';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import { shallowEqual } from 'helpers/validation/shallowEqual';
import { moneyMask } from 'helpers/format/fieldsMasks';

import {
  GridPaginadaConsulta,
  GridPaginadaRetorno,
} from 'components/Grid/Paginacao';
import { PesquisarEstoqueIcon } from 'icons';
import { SearchInput } from 'components/update/Input/SearchInput';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { ModalConfirmacaoInativar } from 'components/Modal/ModalConfirmacaoInativar';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import ModalListarSaldoVariacoes from 'components/Modal/ModalListarSaldoVariacoes';
import { BuscaAvancadaButton } from 'components/update/BuscaAvancadaButton';
import {
  PagedTable,
  PagedTableForwardRefData,
} from 'components/update/Table/PagedTable';
import { StatusCircle } from 'components/update/Table/StatusCircle';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import { ButtonCadastrarNovo } from 'components/Layout/ButtonCadastrarNovo';

import {
  useForm,
  yupResolver,
  defaultValues,
  FiltrosProps,
  Produto,
} from './validationForm';
import { DrawerBuscaAvancada } from './DrawerBuscaAvancada';

export const ListarCadastroProduto = () => {
  const { casasDecimais } = usePadronizacaoContext();
  const history = useHistory();
  const isMountedRef = useIsMountedRef();
  const { contextFiltros, setContextFiltros } = useProdutosFiltrosContext();

  const newFiltrosDefault =
    contextFiltros === undefined ? defaultValues : contextFiltros;

  const [filtrosAtivos, setFiltrosAtivos] = useState(false);
  const [currentFilters, setCurrentFilters] = useState<FiltrosProps>(
    newFiltrosDefault
  );
  const [idProduto, setIdProduto] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [produtos, setProdutos] = useState([] as Array<Produto>);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [openDrawerBuscaAvancada, setOpenDrawerBuscaAvancada] = useState(false);

  const pageIsLoaded = useRef(false);
  const childRef = useRef<PagedTableForwardRefData>(null);

  const permissaoProdutoAlterar = useMemo(
    () => auth.possuiPermissao(ConstanteFuncionalidades.PRODUTO_ALTERAR),
    []
  );
  const planoContratado = useMemo(() => auth.getPlano(), []);
  const planoStartOuPro =
    planoContratado === PlanoContratacaoEnum.START ||
    planoContratado === PlanoContratacaoEnum.PRO;

  const permissaoProdutoVisualizar = auth.possuiPermissao(
    ConstanteFuncionalidades.PRODUTO_VISUALIZAR
  );

  const possuiPermissaoVisualizarPrecoCusto = auth.possuiPermissao(
    ConstanteFuncionalidades.USUARIO_VISUALIZAR_PRECO_CUSTO
  ).permitido;

  const possuiServicoFrenteCaixa = auth.possuiServico(
    enumReferenciaServicoStargate.DISPOSITIVO_FRENTE_CAIXA
  ).permitido;

  const possuiServicoTray = auth.possuiServico(
    enumReferenciaServicoStargate.INTEGRACAO_TRAY
  ).permitido;

  const possuiServicoPdvOffline = auth.possuiServico(
    enumReferenciaServicoStargate.DISPOSITIVO_PDV
  ).permitido;

  const produtoPodeSerRemovido = !(
    possuiServicoFrenteCaixa ||
    possuiServicoTray ||
    possuiServicoPdvOffline
  );

  const formMethods = useForm<FiltrosProps>({
    resolver: yupResolver,
    shouldUnregister: false,
    defaultValues: newFiltrosDefault,
  });

  const handleHistoryPush = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history]
  );

  const filtersSubmit = useCallback((filters: FiltrosProps) => {
    setCurrentFilters(filters);
  }, []);

  const getNomeLinkHref = useCallback(
    (id: string) => {
      let href = '';

      if (permissaoProdutoAlterar.permitido) {
        href = SubstituirParametroRota(
          ConstanteRotas.PRODUTO_ALTERAR,
          'id',
          id
        );
      } else if (permissaoProdutoVisualizar.permitido) {
        href = SubstituirParametroRota(
          ConstanteRotas.PRODUTO_VISUALIZAR,
          'id',
          id
        );
      }

      return href;
    },
    [permissaoProdutoAlterar, permissaoProdutoVisualizar]
  );

  const handleDuplicar = useCallback(
    async (id) => {
      setIsLoading(true);
      const response = await api.post<void, ResponseApi<string>>(
        `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${id}`
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }
        if (response.sucesso) {
          const rota = getNomeLinkHref(response.dados);
          history.push(rota, { isDuplicar: true });
        }
        setIsLoading(false);
      }
      setIsLoading(false);
    },
    [getNomeLinkHref, history]
  );

  const handleSetFiltrosAtivos = useCallback(() => {
    const filtros = currentFilters;
    const {
      camposPersonalizados: filtrosCamposPersonalizados,
      ...restOfFiltros
    } = filtros;

    if (
      (filtrosCamposPersonalizados &&
        (
          filtrosCamposPersonalizados?.filter(
            (campoPersonalizado) =>
              campoPersonalizado &&
              campoPersonalizado !== null &&
              campoPersonalizado.campoPersonalizadoId &&
              campoPersonalizado.campoPersonalizadoId !== null &&
              campoPersonalizado.valor !== undefined &&
              campoPersonalizado.valor !== 'undefined' &&
              campoPersonalizado.valor !== null &&
              campoPersonalizado.valor !== 'null' &&
              String(campoPersonalizado.valor)
          ) || []
        ).length > 0) ||
      JSON.stringify(restOfFiltros) !== JSON.stringify(defaultValues)
    ) {
      setFiltrosAtivos(true);
    } else {
      setFiltrosAtivos(false);
    }
  }, [currentFilters]);

  const paginationHandle = useCallback(
    async (gridPaginadaConsulta: GridPaginadaConsulta) => {
      setIsLoading(true);

      const {
        cores,
        categoriasProduto,
        marcas,
        tamanhos,
        camposPersonalizados,
        tags,
      } = currentFilters;

      const camposPersonalizadosComValor = camposPersonalizados
        ? (camposPersonalizados || [])
            .filter(
              ({ campoPersonalizadoId, valor }) =>
                campoPersonalizadoId &&
                valor !== undefined &&
                valor !== 'undefined' &&
                valor !== null &&
                valor !== 'null' &&
                String(valor)
            )
            .map(({ campoPersonalizadoId, valor }) => ({
              campoPersonalizadoId,
              valor: String(valor),
            }))
        : [];

      const filtros = {
        ...currentFilters,
        cores: (cores || []).length > 0 && cores ? cores : null,
        categoriasProduto:
          (categoriasProduto || []).length > 0 && categoriasProduto
            ? categoriasProduto
            : null,
        marcas: (marcas || []).length > 0 && marcas ? marcas : null,
        tags: (tags || []).length > 0 && tags ? tags : null,
        tamanhos: (tamanhos || []).length > 0 && tamanhos ? tamanhos : null,
        camposPersonalizados: camposPersonalizadosComValor,
      };

      const response = await api.post<
        void,
        ResponseApi<GridPaginadaRetorno<Produto>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.PRODUTO_LISTAR_PAGINADO,
          gridPaginadaConsulta
        ),
        {
          ...filtros,
        }
      );

      if (response) {
        if (response.sucesso && isMountedRef.current) {
          setTotalRegistros(response.dados.total);
          setProdutos(response.dados.registros);

          formMethods.reset(filtros, {
            keepIsSubmitted: true,
            keepDirty: false,
          });
          setContextFiltros(filtros);

          handleSetFiltrosAtivos();
        }
      }

      if (isMountedRef.current) {
        setIsLoading(false);

        if (!pageIsLoaded.current) {
          pageIsLoaded.current = true;

          formMethods.setFocus('nomeReferencia');
        }
      }
    },
    [
      currentFilters,
      formMethods,
      handleSetFiltrosAtivos,
      isMountedRef,
      setContextFiltros,
    ]
  );

  const handleRefresh = useCallback(() => {
    return childRef.current?.reload();
  }, []);

  const handleReset = formMethods.handleSubmit((data) => {
    const filtersIsDirty = !shallowEqual(data, currentFilters || {});

    if (filtersIsDirty) {
      setCurrentFilters(data);
    }
  });

  const inativarProduto = useCallback(
    (response: ResponseApi | null, produtoId: string, ativo: boolean) => {
      ModalConfirmacaoInativar({
        response,
        rotaWebService: ConstanteEnderecoWebservice.PRODUTO_INATIVAR,
        id: produtoId,
        ativo,
        callback: (okInativar: boolean) => {
          if (okInativar) handleRefresh();
        },
      });
    },
    [handleRefresh]
  );

  const excluirProduto = useCallback(
    async (produtoId: string, ativo: boolean) => {
      ModalConfirmacaoExcluir({
        callback: async (ok: boolean) => {
          if (ok) {
            setIsLoading(true);

            const response = await api.delete<void, ResponseApi>(
              ConstanteEnderecoWebservice.PRODUTO_EXCLUIR,
              {
                params: { id: produtoId },
              }
            );

            if (response?.sucesso) {
              toast.success('O cadastro foi removido com sucesso.');

              handleRefresh();
            }

            if (response) {
              inativarProduto(response, produtoId, ativo);
            }

            setIsLoading(false);
          }
        },
      });
    },
    [handleRefresh, inativarProduto]
  );

  useEffect(() => {
    handleSetFiltrosAtivos();
  }, [handleSetFiltrosAtivos]);

  return (
    <SimpleGridForm gap={{ base: '10px', sm: '10px', md: 8 }}>
      <FormProvider {...formMethods}>
        <GridItem colSpan={{ base: 12, md: 4, lg: 5 }}>
          <SearchInput
            type="search"
            placeholder="Buscar o produto por nome, cód. SKU, GTIN/EAN, cód. externo e cód. de barras interno"
            onEnterKeyPress={() => handleReset()}
            isDisabled={isLoading}
            id="nomeReferencia"
            name="nomeReferencia"
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, md: 3, lg: 3 }}>
          <BuscaAvancadaButton
            setIsModalBuscaAvancadaOpen={() => setOpenDrawerBuscaAvancada(true)}
            hasFilters={filtrosAtivos}
            borderRadius="full"
            colorScheme="gray.500"
            variant="outlineDefault"
            bg="none"
            isDisabled={isLoading}
            width={['full', 'full', '200px']}
          />
        </GridItem>
        <GridItem
          colSpan={{ base: 12, md: 5, lg: 4 }}
          display={{ base: '', sm: '', md: 'flex' }}
          justifyContent={{ base: 'flex-start', sm: 'flex-end' }}
        >
          <ButtonCadastrarNovo
            variant="solid"
            color="primary.700"
            colorScheme="secondary.400"
            onClick={() => handleHistoryPush(ConstanteRotas.PRODUTO_CADASTRAR)}
            funcionalidade={ConstanteFuncionalidades.PRODUTO_CADASTRAR}
          />
        </GridItem>

        <ModalListarSaldoVariacoes
          isOpen={modalIsOpen}
          setIsOpen={setModalIsOpen}
          idProduto={idProduto}
        />
        <GridItem mt={['10px', '10px', '-10px']} colSpan={12}>
          <PagedTable
            loadColumnsData={paginationHandle}
            itemsTotalCount={totalRegistros}
            defaultKeyOrdered="Nome"
            isLoading={isLoading}
            tableHeaders={[
              {
                content: <StatusCircle hasValue={false} />,
                key: 'Ativo',
                isOrderable: true,
                width: '37px',
              },
              {
                content: 'Nome',
                key: 'Nome',
                isOrderable: true,
                width: 'auto',
              },
              {
                content: 'Referência',
                key: 'Referencia',
                isOrderable: false,
                width: 'auto',
              },
              possuiPermissaoVisualizarPrecoCusto
                ? {
                    content: (
                      <Flex alignItems="center" justifyContent="flex-end">
                        Preço de custo
                      </Flex>
                    ),
                    key: 'PrecoCusto',
                    isOrderable: false,
                    width: '150px',
                  }
                : {
                    content: <></>,
                    key: '',
                  },
              {
                content: (
                  <Flex alignItems="center" justifyContent="flex-end">
                    Preço de venda
                  </Flex>
                ),
                key: 'PrecoVenda',
                isOrderable: false,
                width: '150px',
              },
              {
                content: (
                  <Flex alignItems="center" justifyContent="flex-end">
                    Estoque
                  </Flex>
                ),
                key: 'Estoque',
                isOrderable: false,
                width: '150px',
              },
              {
                content: '',
                key: '',
                isOrderable: false,
                width: '80px',
              },
              {
                content: 'Ações',
                key: 'Acoes',
                isOrderable: false,
                width: '38px',
              },
            ]}
            ref={childRef}
            renderTableRows={produtos.map((produto) => {
              const hasEstoque = produto.estoque > 0;

              return (
                <Tr
                  sx={{
                    '& td': {
                      color: hasEstoque ? 'gray.700' : 'red.500',
                    },
                  }}
                  key={produto.id}
                >
                  <Td style={{ textAlign: 'center' }}>
                    <StatusCircle isActive={produto.ativo} />
                  </Td>
                  <Td>
                    <Link
                      id="link-visualizar"
                      href={getNomeLinkHref(produto.id)}
                    >
                      {produto.nome}
                    </Link>
                  </Td>
                  <Td>{produto.referencia || '---'}</Td>
                  {possuiPermissaoVisualizarPrecoCusto ? (
                    <Td>
                      <Flex justifyContent="flex-end">
                        {moneyMask(produto.precoCusto || 0, true)}
                      </Flex>
                    </Td>
                  ) : (
                    <Td />
                  )}
                  <Td>
                    <Flex justifyContent="flex-end">
                      {moneyMask(produto.precoVenda, true)}
                    </Flex>
                  </Td>
                  <Td
                    textAlign="end"
                    color={produto.estoque <= 0 ? 'red.400' : undefined}
                  >
                    <Flex alignItems="center" justifyContent="flex-end">
                      <Text>
                        {produto.estoque.toLocaleString('locale', {
                          minimumFractionDigits:
                            casasDecimais.casasDecimaisQuantidade,
                          maximumFractionDigits:
                            casasDecimais.casasDecimaisQuantidade,
                        })}
                      </Text>
                    </Flex>
                  </Td>
                  {planoStartOuPro ? (
                    <Td />
                  ) : (
                    <Td pt="0" pb="0">
                      <Button
                        pr="0"
                        fontSize="lg"
                        cursor={planoStartOuPro ? 'not-allowed' : 'pointer'}
                        variant="none"
                        _focus={{ border: 'none' }}
                        style={{ position: 'relative', top: '-3px' }}
                        onClick={() => {
                          setModalIsOpen(true);
                          setIdProduto(produto.id);
                        }}
                        isDisabled={planoStartOuPro}
                      >
                        <Icon
                          color={
                            produto.estoque <= 0 ? 'red.400' : 'primary.50'
                          }
                          cursor={planoStartOuPro ? 'not-allowed' : 'pointer'}
                          as={PesquisarEstoqueIcon}
                          fontSize="lg"
                        />
                      </Button>
                    </Td>
                  )}

                  <Td isNumeric>
                    <Flex alignItems="center" justifyContent="flex-end">
                      <ActionsMenu
                        id="mostrarMais"
                        items={[
                          {
                            content: 'Editar',
                            onClick: () => {
                              handleHistoryPush(
                                SubstituirParametroRota(
                                  ConstanteRotas.PRODUTO_ALTERAR,
                                  'id',
                                  produto.id
                                )
                              );
                            },
                            funcionalidade:
                              ConstanteFuncionalidades.PRODUTO_ALTERAR,
                          },
                          produtoPodeSerRemovido
                            ? {
                                content: 'Remover',
                                onClick: () => {
                                  excluirProduto(produto.id, produto.ativo);
                                },
                                funcionalidade:
                                  ConstanteFuncionalidades.PRODUTO_EXCLUIR,
                              }
                            : {
                                content: 'Inativar',
                                onClick: () => {
                                  inativarProduto(
                                    null,
                                    produto.id,
                                    produto.ativo
                                  );
                                },
                                funcionalidade:
                                  ConstanteFuncionalidades.PRODUTO_EXCLUIR,
                              },
                          {
                            content: 'Duplicar',
                            onClick: () => {
                              handleDuplicar(produto.id);
                            },
                            funcionalidade:
                              ConstanteFuncionalidades.PRODUTO_CADASTRAR,
                          },
                        ]}
                      />
                    </Flex>
                  </Td>
                </Tr>
              );
            })}
          />
        </GridItem>
        <DrawerBuscaAvancada
          currentFilters={currentFilters}
          filtersSubmit={filtersSubmit}
          isOpen={openDrawerBuscaAvancada}
          onClose={() => setOpenDrawerBuscaAvancada(false)}
          filtrosReset={defaultValues}
        />
      </FormProvider>
    </SimpleGridForm>
  );
};
