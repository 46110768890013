import { useCallback, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, GridItem, Td, Tr } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import auth from 'modules/auth';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';
import ConstanteFuncionalidades from 'constants/permissoes';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import { shallowEqual } from 'helpers/validation/shallowEqual';
import { getName } from 'helpers/enum/getName';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';
import api, { ResponseApi } from 'services/api';
import { formatDateHourMinute } from 'helpers/format/formatStringDate';
import TipoOperacaoEnum from 'constants/enum/tipoOperacao';

import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import { BuscaAvancadaButton } from 'components/update/BuscaAvancadaButton';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { PaginationData } from 'components/update/Pagination';
import {
  PagedTable,
  PagedTableForwardRefData,
} from 'components/update/Table/PagedTable';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import { SelectVirtualized } from 'components/PDV/Select/SelectVirtualized';
import { ModalVisualizarOperacao } from 'components/Modal/ModalVisualizarOperacao';
import Loading from 'components/Layout/Loading/LoadingPadrao';

import ModalBuscaAvancadaHistoricoProduto from './ModalBuscaAvancada';
import {
  FiltersData,
  formDefaultValues,
  ListarHistoricoProdutoProps,
} from './types';

const HistoricoProdutoListar = () => {
  const emptyField = '---';

  const { casasDecimais } = usePadronizacaoContext();

  const [currentFilters, setCurrentFilters] = useState<FiltersData>(
    formDefaultValues()
  );
  const formMethods = useForm({ defaultValues: currentFilters });
  const [hasFilters, setHasFilters] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalBuscaAvancadaOpen, setIsModalBuscaAvancadaOpen] = useState(
    false
  );
  const [itemsTotalCount, setItemsTotalCount] = useState(0);
  const [columnsData, setColumnsData] = useState<ListarHistoricoProdutoProps[]>(
    []
  );
  const [totalRegistros, setTotalRegistros] = useState(0);

  const permissaoHistoricoProdutoVisualizar = auth.possuiPermissao(
    ConstanteFuncionalidades.HISTORICO_PRODUTO_VISUALIZAR
  );

  const pagedTableRef = useRef<PagedTableForwardRefData>(null);

  const handleReset = () => {
    setCurrentFilters(formDefaultValues());
    setHasFilters(false);
    formMethods.reset(formDefaultValues());
  };

  const filtersSubmit = formMethods.handleSubmit((data) => {
    const filtersIsDirty = !shallowEqual(data, currentFilters);

    if (filtersIsDirty) {
      setCurrentFilters(data);
      setHasFilters(true);
    }
  });

  function handleSubmitModalBuscaAvancada(data: FiltersData) {
    formMethods.reset(data);
    filtersSubmit();
  }

  const loadColumnsData = useCallback(
    async (paginationData: PaginationData) => {
      setIsLoading(true);

      const params = {
        produtoCorId: currentFilters.produtoCorId?.value || '',
        produtoCorTamanhoId: currentFilters.produtoCorTamanhoId || '',
        localEstoqueId: currentFilters.localEstoqueId || '',
        tipoOperacaoEstoque: currentFilters.tipoOperacaoEstoque || '',
        dataHoraInicio: currentFilters.dataHoraInicio
          ? currentFilters.dataHoraInicio.toISOString()
          : '',
        dataHoraFim: currentFilters.dataHoraFim
          ? currentFilters.dataHoraFim.toISOString()
          : '',
      };

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<ListarHistoricoProdutoProps>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.HISTORICO_PRODUTO_LISTAR_PAGINADO,
          paginationData,
          params
        )
      );

      if (response) {
        if (response.avisos) {
          response.avisos.map((item: string) => toast.warning(item));
        }

        if (response.sucesso) {
          setColumnsData(response.dados.registros || []);

          setItemsTotalCount(response.dados.total || 0);
        }
      }

      setIsLoading(false);
    },
    [currentFilters]
  );

  const buscarTamanhos = useCallback(async (produtoCorId) => {
    setIsLoading(true);
    const response = await api.get<
      void,
      ResponseApi<
        {
          id: string;
          nome: string;
          padraoSistema: boolean;
        }[]
      >
    >(ConstanteEnderecoWebservice.PRODUTO_COR_TAMANHO_OBTER_TAMANHOS, {
      params: { produtoCorId },
    });

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
        setIsLoading(false);
      }

      if (response.sucesso && response.dados) {
        const tamanhosSemTamanhoPadraoSistema = response.dados.filter(
          (tamanho) => !tamanho.padraoSistema
        );
        setIsLoading(false);

        return (tamanhosSemTamanhoPadraoSistema || [])?.map((tamanho) => ({
          id: tamanho.id,
          descricao: tamanho.nome,
          padraoSistema: tamanho.padraoSistema,
        }));
      }
    }
    setIsLoading(false);

    return [];
  }, []);

  const selecionarOpcao = useCallback(
    async (option) => {
      const produtoCorTamanhos = await buscarTamanhos(option.value);
      const produtoCompleto = {
        label: option.label,
        value: option.value,
        produtoCorId: option.produtoCorId,
        tamanhos: produtoCorTamanhos,
      };
      formMethods.setValue('produtoCorId', produtoCompleto);
      filtersSubmit();
    },
    [buscarTamanhos, filtersSubmit, formMethods]
  );

  const buscarOpcoes = useCallback(
    async (inputValue: string, dataPagination: PaginationData) => {
      setIsLoading(true);
      const valorDigitado = inputValue;
      const response = await api.get<
        void,
        ResponseApi<
          GridPaginadaRetorno<{
            id: number;
            descricao: string;
          }>
        >
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.PRODUTO_COR_LISTAR_PAGINADO,
          dataPagination
        ),
        {
          params: {
            nome: valorDigitado,
          },
        }
      );
      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso: string) => toast.warning(aviso));
          setIsLoading(false);
        }
        setTotalRegistros(response?.dados?.total || 0);

        if (response.sucesso && response.dados) {
          const opcoes = response?.dados?.registros.map((option) => {
            const nomeProdutoCor = option.descricao.split('|');
            const nomeProduto = nomeProdutoCor[0].trim();
            const cor = nomeProdutoCor
              ? nomeProdutoCor[1]
                ? nomeProdutoCor[1].trim()
                : ''
              : '';

            return {
              label: option.descricao,
              value: option.id,
              produtoCorId: option.id,
              produto: nomeProduto,
              cor,
            };
          });
          setIsLoading(false);

          return opcoes;
        }
        setIsLoading(false);
      }
      setIsLoading(false);

      return [];
    },
    []
  );

  return (
    <>
      <SimpleGridForm>
        <FormProvider {...formMethods}>
          <GridItem colSpan={{ base: 12, lg: 6 }}>
            <SelectVirtualized
              asControlledByObject
              autoFocus
              totalRegistros={totalRegistros}
              handleGetOptions={buscarOpcoes}
              helperText="A pesquisa de produto considera os campos Status, Descrição, Código SKU, GTIN/EAN, Código externo e Código de barras interno."
              id="produtoCorId"
              isClearable
              name="produtoCorId"
              placeholder="Buscar o produto por nome ou por código de barras"
              required
              onSelect={(option) => {
                selecionarOpcao(option);
              }}
              withoutDefaultOptions
            />
          </GridItem>
          <GridItem colSpan={{ base: 12, sm: 6, lg: 3 }}>
            <Box maxW={{ base: 'full', sm: '240px' }}>
              <BuscaAvancadaButton
                setIsModalBuscaAvancadaOpen={setIsModalBuscaAvancadaOpen}
                hasFilters={hasFilters}
              />
            </Box>
          </GridItem>
        </FormProvider>
        {isLoading && <Loading />}
        <GridItem colSpan={12}>
          <PagedTable
            ref={pagedTableRef}
            loadColumnsData={loadColumnsData}
            itemsTotalCount={itemsTotalCount}
            defaultKeyOrdered="dataHoraEmissao"
            defaultOrderDirection="desc"
            tableHeaders={[
              { key: 'dataHoraEmissao', content: 'Data/Hora', w: '200px' },
              {
                key: 'tamanho',
                content: 'Tamanho',
                isOrderable: false,
                w: '150px',
              },
              {
                key: 'operacao',
                content: 'Operação',
                isOrderable: false,
                w: '200px',
              },
              {
                key: 'identificador',
                content: 'Identificador',
                isOrderable: false,
                w: '150px',
              },
              {
                key: 'quantidade',
                content: 'Quantidade',
                isOrderable: false,
                w: '150px',
              },
              {
                key: 'clienteFornecedor',
                content: 'Cliente/Fornecedor',
                isOrderable: false,
              },
              { key: 'acoes', content: 'Ações', isOrderable: false, w: '1px' },
            ]}
            renderTableRows={
              columnsData &&
              columnsData.map((columnData) => {
                function getVisualizarOperacoes() {
                  if (permissaoHistoricoProdutoVisualizar.permitido) {
                    ModalVisualizarOperacao({
                      casasDecimais,
                      operacaoId: columnData.operacaoId,
                      entradaMercadoriaId: columnData.entradaMercadoriaId,
                      identificacaoTipoOperacao:
                        columnData.identificacaoTipoOperacao,
                    });
                  }
                }

                const dropDownItem = [
                  {
                    content: 'Visualizar',
                    onClick: () => {
                      getVisualizarOperacoes();
                    },
                    funcionalidade:
                      ConstanteFuncionalidades.HISTORICO_PRODUTO_VISUALIZAR,
                  },
                ];

                const dataEmissao = formatDateHourMinute(
                  columnData.dataHoraEmissao
                );

                return (
                  <Tr>
                    <Td
                      color={
                        columnData.tipoAcaoEstoque === TipoOperacaoEnum.ENTRADA
                          ? 'primary.100'
                          : ''
                      }
                    >
                      {dataEmissao}
                    </Td>
                    <Td
                      color={
                        columnData.tipoAcaoEstoque === TipoOperacaoEnum.ENTRADA
                          ? 'primary.100'
                          : ''
                      }
                    >
                      {columnData.tamanho ? columnData.tamanho : emptyField}
                    </Td>
                    <Td
                      color={
                        columnData.tipoAcaoEstoque === TipoOperacaoEnum.ENTRADA
                          ? 'primary.100'
                          : ''
                      }
                    >
                      {getName(
                        IdentificacaoTipoOperacaoEnum,
                        columnData.identificacaoTipoOperacao
                      )}
                    </Td>
                    <Td
                      color={
                        columnData.tipoAcaoEstoque === TipoOperacaoEnum.ENTRADA
                          ? 'primary.100'
                          : ''
                      }
                    >
                      {columnData.numeroOperacao}
                    </Td>
                    <Td
                      color={
                        columnData.tipoAcaoEstoque === TipoOperacaoEnum.ENTRADA
                          ? 'primary.100'
                          : ''
                      }
                    >
                      {columnData.tipoAcaoEstoque === TipoOperacaoEnum.SAIDA
                        ? `- ${columnData.quantidade},00`
                        : `${columnData.quantidade},00`}
                    </Td>
                    <Td
                      color={
                        columnData.tipoAcaoEstoque === TipoOperacaoEnum.ENTRADA
                          ? 'primary.100'
                          : ''
                      }
                    >
                      {columnData.nomeCliente
                        ? columnData.nomeCliente
                        : emptyField}
                    </Td>
                    <Td>
                      <ActionsMenu
                        isDisabled={
                          !permissaoHistoricoProdutoVisualizar.permitido
                        }
                        items={dropDownItem}
                      />
                    </Td>
                  </Tr>
                );
              })
            }
          />
        </GridItem>
      </SimpleGridForm>
      <ModalBuscaAvancadaHistoricoProduto
        isOpen={isModalBuscaAvancadaOpen}
        setIsOpen={setIsModalBuscaAvancadaOpen}
        handleSubmitModalBuscaAvancada={handleSubmitModalBuscaAvancada}
        currentFilters={currentFilters}
        handleReset={handleReset}
      />
    </>
  );
};

export default HistoricoProdutoListar;
