export function setFocusFieldError(
  fieldOrderNames: Array<string>,
  errorList: any
) {
  for (let index = 0; index < fieldOrderNames.length; index += 1) {
    const fieldName = fieldOrderNames[index];

    if (errorList[fieldName]) {
      errorList[fieldName].ref.focus();
      break;
    }
  }
}
