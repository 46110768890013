import styled, { css } from 'styled-components';
import { Card as BootstrapCard } from 'react-bootstrap';

export const ContainerPaging = styled.div`
  max-width: 100%;

  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: center;

  & > * + * {
    margin-bottom: 20px;
  }

  @media (min-width: 900px) {
    flex-direction: row;
    justify-content: space-between;

    & > * + * {
      margin-bottom: 0px;
    }
  }

  padding: 15px 20px;
`;

export const Card = styled(BootstrapCard)`
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  font-size: 0.8125rem;

  & .card-body,
  & .p-2 {
    padding: 0 !important;
    border-radius: 5px;
  }
`;

export const DivQuantidadePagina = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
`;

export const DivTotalPaginas = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .pagination {
    margin-bottom: 0px !important;
  }

  @media (max-width: 900px) {
    justify-content: flex-end;
  }
`;

export const DivTotalRegistros = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 900px) {
    justify-content: flex-start;
  }
`;

export const SelectOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;

  select.form-control-sm {
    min-width: 30px;

    padding: 0 5px;

    font-size: 0.8125rem;
    text-align-last: center;
  }

  select.form-control {
    width: auto;
    margin: 0 10px;
  }
`;

const BorderBottomLine = css`
  &:after {
    content: '';
    position: absolute;
    width: calc(100% - 40px);
    left: 0;
    bottom: 0;
    margin-left: 20px;

    border-bottom: 1px solid var(--sti-ck-colors-gray-100);
  }
`;

export const TableStyled = styled.table`
  width: 100%;
  background: transparent;

  tbody tr {
    position: relative;

    ${BorderBottomLine}
  }

  tbody tr:hover {
    background-color: var(--sti-ck-colors-gray-50);
  }

  tbody tr td {
    padding: 0px 10px;

    &:first-child {
      padding-left: 30px;
    }

    &:last-child {
      padding-right: 30px;
    }

    text-overflow: ellipsis;
    white-space: nowrap;

    /* @media (min-width: 700px) */
    height: 32px;

    @media (min-width: 900px) {
      height: 35px;
    }
    @media (min-width: 1200px) {
      height: 40px;
    }

    @media (min-width: 1800px) {
      height: 42px;
    }
  }
`;

export const Body = styled.tbody``;

export const Li = styled.li.attrs({
  className: 'page-item',
})``;

export const LiActive = styled.li.attrs({
  className: 'page-item active',
})`
  &:focus {
    box-shadow: var(--focus-shadow);
  }

  button {
    color: var(--white) !important;
    background-color: var(--sti-ck-colors-primary-50) !important;
    border: 0;
  }
`;

export const ButtonPageLink = styled.button.attrs({
  className: 'page-link',
  type: 'button',
})`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--white);
  color: var(--sti-ck-colors-primary-50);
  border: 0;

  margin-left: 0px !important;

  padding: 0 5px;

  font-size: 0.8125rem;

  height: 25px;
  min-width: 25px;

  &:focus,
  &:hover {
    box-shadow: var(--focus-shadow);
    background-color: var(--sti-ck-colors-gray-50);
    color: var(--sti-ck-colors-primary-50);
  }
`;

export const Container = styled.div``;

export const TableContainer = styled.div`
  max-width: 100%;

  overflow: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  padding-bottom: 250px;
  margin-bottom: -250px;
`;
