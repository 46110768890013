import { useHistory } from 'react-router-dom';

import ConstanteRotas from 'constants/rotas';

const useDeliveryPersonForm = () => {
  const history = useHistory();

  const redirectToDeliveryPersonList = () => {
    history.push(ConstanteRotas.FRENTE_CAIXA_ENTREGADORES);
  };

  return {
    redirectToDeliveryPersonList,
  };
};

export { useDeliveryPersonForm };
