import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export type Parcela = {
  id?: string;
  dataVencimento: Date;
  numeroParcela: number;
  formaPagamento: string;
  valor: number;
};

export type FormData = {
  valorTotal: number;
  valorTotalAdicionado: number;
  parcelas: Parcela[];
};

const schema = yup.object().shape({
  parcelas: yup
    .array()
    .of(
      yup.object().shape({
        dataVencimento: yup
          .string()
          .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
        numeroParcela: yup
          .string()
          .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
        formaPagamento: yup
          .string()
          .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
        valor: yup
          .number()
          .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
      })
    )
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);
