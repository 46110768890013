import React from 'react';
import { Button, ButtonProps, Icon } from '@chakra-ui/react';

import { BuscaIcon } from 'icons';
import { FiFilter } from 'react-icons/fi';

interface BuscaAvancadaButtonProps extends ButtonProps {
  setIsModalBuscaAvancadaOpen: (value: boolean) => void;
  hasFilters: boolean;
  hasText?: boolean;
  colorScheme?: string;
  borderColor?: string;
  variant?: string;
  bg?: string;
  borderRadius?: string;
  color?: string;
  hasIcon?: boolean;
}

export const BuscaAvancadaButton = ({
  setIsModalBuscaAvancadaOpen,
  hasFilters,
  hasText = true,
  colorScheme = 'none',
  variant = 'outline',
  borderColor = 'gray.200',
  bg = 'white',
  justifyContent = 'left',
  borderRadius = 'md',
  color = 'gray.700',
  width = ['100%', '230px'],
  hasIcon = true,
  ...rest
}: BuscaAvancadaButtonProps) => {
  return (
    <Button
      onClick={() => setIsModalBuscaAvancadaOpen(true)}
      colorScheme={colorScheme}
      variant={variant}
      borderColor={borderColor}
      bg={hasFilters ? 'blue.500' : bg}
      justifyContent={justifyContent}
      borderRadius={borderRadius}
      width={width}
      leftIcon={
        hasIcon ? <Icon as={!hasFilters ? BuscaIcon : FiFilter} /> : undefined
      }
      color={hasFilters ? 'white' : color}
      {...rest}
    >
      {hasText ? (!hasFilters ? 'Busca avançada' : 'Filtro selecionado') : ''}
    </Button>
  );
};
