import React from 'react';
import { Controller } from 'react-hook-form';

import { InputProps } from 'components/Input/InputPadrao';
import ContainerInputPadrao, {
  LinkActionInterface,
} from 'components/Layout/CampoContainer/CampoContainerPadrao';
import { InputGroup } from 'react-bootstrap';
import { InputControl } from 'components/TextArea/styles';

interface InputNullProps extends InputProps {
  control: any;
  prependText?: any;
  linkAction?: LinkActionInterface;
  dateLimiter?: boolean;
}

const InputNull = ({
  id,
  name,
  label,
  control,
  error,
  dateLimiter = false,
  infoText,
  defaultValue,
  prependText,
  linkAction,
  ...rest
}: InputNullProps) => {
  const customDateLimiter = (valueInput: string) => {
    if (valueInput.length > 10) {
      return valueInput.slice(valueInput.length - 10);
    }
    return valueInput;
  };

  return (
    <ContainerInputPadrao
      id={id}
      label={label}
      error={error}
      infoText={infoText}
      linkAction={linkAction}
    >
      <InputGroup>
        {prependText && (
          <InputGroup.Prepend>
            <InputGroup.Text>{prependText}</InputGroup.Text>
          </InputGroup.Prepend>
        )}
        <Controller
          defaultValue={defaultValue}
          render={({ field }) => (
            <>
              <InputControl
                ref={field.ref}
                id={id}
                name={name}
                label={label}
                value={field.value || ''}
                onChange={(selected: React.ChangeEvent<HTMLInputElement>) => {
                  if (selected.target.value) {
                    field.onChange(
                      dateLimiter
                        ? customDateLimiter(selected.target.value)
                        : selected.target.value
                    );
                  } else field.onChange(null);
                }}
                error={error || ''}
                {...rest}
              />
            </>
          )}
          name={`${name}` as const}
          // onFocus={() => {
          //   if (inputRef) inputRef.current.focus();
          //   else ref.current.focus();
          // }}
          control={control}
        />
      </InputGroup>
    </ContainerInputPadrao>
  );
};

export default InputNull;
