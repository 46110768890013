import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

const shapeRegraFiscalExcecao = {
  estadoOrigemId: yup
    .number()
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(null),
  estadoDestinoId: yup
    .number()
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(null),
  aliquotaIcms: yup
    .number()
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(0),
  icmsReducaoBaseCalculo: yup
    .number()
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(0),
  porcentagemFCP: yup
    .number()
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(0),
  codigoBeneficioFiscal: yup
    .string()
    .nullable()
    .notRequired()
    .test(
      'codigoBeneficioFiscal',
      ConstanteMensagemValidacao.TAMANHO_BENEFICIO_FISCAL,
      (value) => {
        if (value && value.length > 0) {
          if (value.length === 8 || value.length === 10) {
            return true;
          }
          return false;
        }
        return true;
      }
    )
    .default(null),
  reducaoBaseCalculo: yup.number().notRequired(),
};

export const schemaRegraFiscalExcecao = yup
  .object()
  .shape(shapeRegraFiscalExcecao);

export const yupResolver = yupResolverInstance(schemaRegraFiscalExcecao);

export const defaultValues = schemaRegraFiscalExcecao.default();

export const useForm = useFormInstance;
