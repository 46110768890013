import { Grid, Flex } from '@chakra-ui/react';
import formatUTCToLocateDateTime from 'helpers/format/formatUTCToLocateDateTime';
import React from 'react';

import { InformacoesProtocoloInterface } from 'types/ImpressaoNFCe';

interface ImpressaoInterface {
  numero: number;
  serie: number;
  dataEmissao: string;
  contingencia: boolean;
  informacoesProtocolo?: InformacoesProtocoloInterface;
}

const IdentificacaoNFCe = ({
  numero,
  serie,
  dataEmissao,
  contingencia,
  informacoesProtocolo,
}: ImpressaoInterface) => {
  return (
    <Grid marginTop="5px">
      <Flex>
        NFC-e Nº {numero} Série {serie} {formatUTCToLocateDateTime(dataEmissao)}
      </Flex>
      {contingencia && (
        <Grid>
          <Flex fontWeight="bold">EMITIDA EM CONTINGÊNCIA</Flex>
          <Flex>Pendente de Autorização</Flex>
        </Grid>
      )}
      {!contingencia && informacoesProtocolo && (
        <Grid>
          <Flex>
            Protocolo de Autorização {informacoesProtocolo.numeroProtocolo}
          </Flex>
          <Flex>
            Data de Autorização{' '}
            {formatUTCToLocateDateTime(
              informacoesProtocolo.dataHoraRecebimento
            )}
          </Flex>
        </Grid>
      )}
    </Grid>
  );
};

export default IdentificacaoNFCe;
