import { GuardedRoute as Guard, GuardedRouteProps } from 'react-router-guards';

import ConstanteRotas from 'constants/rotas';

import { Dominio } from 'pages/Autenticacao/Dominio';
import { RecuperarDominio } from 'pages/Autenticacao/RecuperarDominio';
import { DominioEnviadoSucesso } from 'pages/Autenticacao/DominioEnviadoSucesso';
import { Login } from 'pages/Autenticacao/Login';
import { RecuperarSenha } from 'pages/Autenticacao/RecuperarSenha';
import { SenhaEnviadaSucesso } from 'pages/Autenticacao/SenhaEnviadaSucesso';
import { RedefinirSenha } from 'pages/Autenticacao/RedefinirSenha';
import { SenhaRedefinidaSucesso } from 'pages/Autenticacao/SenhaRedefinidaSucesso';

import AutenticacaoLayout from 'components/Autenticacao/Layout';

interface GuardRoutesProps extends GuardedRouteProps {
  key: string;
  path: string;
}

export const authRoutes: GuardRoutesProps[] = [
  {
    key: 'login',
    component: Login,
    path: ConstanteRotas.LOGIN,
  },
  { key: 'dominio', component: Dominio, path: '/', exact: true },
  {
    key: 'dominio-enviado-com-sucesso',
    component: DominioEnviadoSucesso,
    path: ConstanteRotas.DOMINIO_ENVIADO_COM_SUCESSO,
  },
  {
    key: 'recuperar-senha',
    component: RecuperarSenha,
    path: ConstanteRotas.RECUPERAR_SENHA,
  },
  {
    key: 'recuperar-dominio',
    component: RecuperarDominio,
    path: ConstanteRotas.RECUPERAR_DOMINIO,
  },
  {
    key: 'redefinir-senha',
    component: RedefinirSenha,
    path: ConstanteRotas.REDEFINIR_SENHA,
  },
  {
    key: 'senha-enviada-com-sucesso',
    component: SenhaEnviadaSucesso,
    path: ConstanteRotas.SENHA_ENVIADA_COM_SUCESSO,
  },
  {
    key: 'senha-redefinida-com-sucesso',
    component: SenhaRedefinidaSucesso,
    path: ConstanteRotas.SENHA_REDEFINIDA_COM_SUCESSO,
  },
];

const AutenticacaoRoutes = () => {
  return (
    <AutenticacaoLayout>
      {authRoutes.map(({ key, ...rest }) => (
        <Guard key={key} {...rest} />
      ))}
    </AutenticacaoLayout>
  );
};

export default AutenticacaoRoutes;
