import { ContainerListagem } from 'components/Layout/Listagem/containerListagem';

import { Formulario } from '..';
import { usePromocaoCadastrar } from './hooks';

export const Cadastrar = () => {
  const {
    isLoading,
    onSubmit,
    onSubmitReset,
    formIsDirty,
  } = usePromocaoCadastrar();

  return (
    <ContainerListagem
      isLoading={isLoading}
      maxWidth="100%"
      isForm={false}
      onSubmit={onSubmit}
      formIsDirty={formIsDirty}
      onSubmitReset={onSubmitReset}
    >
      <Formulario />
    </ContainerListagem>
  );
};
