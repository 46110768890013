import { Box, Td, Tr } from '@chakra-ui/react';

import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';

import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import { PagedTable } from 'components/update/Table/PagedTable';

import { useProdutos } from '../hooks';

import { useProdutoPaginado } from './hooks';

export const ProdutoItemPaginado = () => {
  const {
    listarProdutos,
    totalRegistros,
    isLoading,
    paginationHandle,
    pagedTableRef,
  } = useProdutoPaginado();

  const { casasDecimais } = usePadronizacaoContext();

  const {
    handleEditarProdutoPaginado,
    handleExcluirProdutoPaginado,
  } = useProdutos(true);

  return (
    <Box mt="10px">
      <PagedTable
        ref={pagedTableRef}
        isLoading={isLoading}
        variant="card"
        bg="transparent"
        boxShadow="none"
        paddingRight="5px"
        paddingLeft="5px"
        pb="10px"
        paginationHasDivider={false}
        loadColumnsData={paginationHandle}
        itemsTotalCount={totalRegistros}
        defaultKeyOrdered="nome"
        tableHeaders={[
          {
            key: 'produto',
            content: 'Produto',
            isOrderable: false,
          },
          {
            key: 'tamanho',
            content: 'Tamanho',
            isOrderable: false,
            isNumeric: true,
          },

          {
            key: 'quantidade',
            content: 'Quantidade',
            isOrderable: false,
            isNumeric: true,
          },
          {
            key: 'precoOriginal',
            content: 'Preco original',
            isOrderable: false,
          },
          {
            key: 'precoPromocao',
            content: 'Preco promoção',
            isOrderable: false,
          },

          { key: 'acoes', content: 'Ações', isOrderable: false, w: '1px' },
        ]}
        renderTableRows={(listarProdutos || []).map((produtoItem) => {
          return (
            <>
              <Tr>
                <Td
                  w="45%"
                  whiteSpace="nowrap"
                >{`${produtoItem.produtoCorTamanho.produto} ${produtoItem.produtoCorTamanho.cor}`}</Td>
                <Td w="25%" isNumeric>
                  {produtoItem.produtoCorTamanho.tamanho || '-'}
                </Td>
                <Td w="25%" isNumeric>
                  {produtoItem.quantidade}
                </Td>
                <Td isNumeric w="10%">
                  {produtoItem?.precoOriginal?.toLocaleString('pt-BR', {
                    minimumFractionDigits: casasDecimais.casasDecimaisValor,
                    maximumFractionDigits: casasDecimais.casasDecimaisValor,
                  })}
                </Td>
                <Td isNumeric w="10%">
                  {produtoItem?.precoPromocao?.toLocaleString('pt-BR', {
                    minimumFractionDigits: casasDecimais.casasDecimaisValor,
                    maximumFractionDigits: casasDecimais.casasDecimaisValor,
                  })}
                </Td>

                <Td>
                  <ActionsMenu
                    items={[
                      {
                        content: 'Editar',
                        onClick: () => handleEditarProdutoPaginado(produtoItem),
                      },
                      {
                        content: 'Remover',
                        onClick: () =>
                          handleExcluirProdutoPaginado(
                            produtoItem.produtoCorTamanho.produtoCorTamanhoId
                          ),
                      },
                    ]}
                  />
                </Td>
              </Tr>
              <Box h="5px" />
            </>
          );
        })}
      />
    </Box>
  );
};
