import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

type Tamanho = {
  produtoCorTamanhoId: string;
  tamanho: string;
  padraoSistema?: boolean;
};
type Cores = {
  label: string;
  value: string;
};

export type ProdutoCor = {
  value: string;
  label: string;
};

export type ProdutoTamanhoCorProps = {
  value: string;
  label: string;
  precoVenda: number;
  precoNovo: number;
  isChecked: boolean;
  precoCusto: number;
  cor: string;
  tamanho: string;
};

export type ProdutoTamanhoCorResponseProps = {
  nome: string;
  id: string;
  precoVenda: number;
  precoNovo: number;
  isChecked: boolean;
  precoCusto: number;
  cor: string;
  tamanho: string;
};

export interface ProdutoVariacao {
  value: string;
  label: string;
  produtoNome: string;
  corDescricao?: string;
  precoCusto: number;
  tamanhos: Tamanho;
  cores: Cores;
  precoVenda: number;
  precoNovo: number;
  isChecked: boolean;
}

type ProdutoCorTamanho = {
  value: string;
  label: string;
  padraoSistema?: boolean;
};

export type FormData = {
  produtoCor: ProdutoCor;
  produtoCorTamanho: ProdutoCorTamanho | null;
  precoVenda: number;
  precoCusto: number;
  preco: number;
};

const schema = yup.object().shape({
  precoVenda: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);
