import {
  Avatar,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  Flex,
  Icon,
  IconButton,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { FiXCircle } from 'react-icons/fi';

import auth from 'modules/auth';

import DrawerPadraoChakra from 'components/PDV/Drawer/DrawerPadraoChakra';
import WrapperButton from 'components/PDV/Layout/Header/WrapperButton';
import {
  AlternarLojaIcon,
  ConfiguracoesUsuariosIcon,
  LogoutIcon,
  NotificacoesIcon,
} from 'icons';

import { Ajuda } from '../../Ajuda';

type HeaderItemMobileProps = {
  logOff: () => void;
  onModalAlternarLojaOpen: () => void;
};

export const HeaderItemMobile = ({
  logOff,
  onModalAlternarLojaOpen,
}: HeaderItemMobileProps) => {
  const { fantasia: lojaFantasia = '' } = auth.getLoja();

  const { nome: nomeUsuario, foto: fotoUsuario } = auth.getUsuario();

  const {
    isOpen: isMenuWrapperOpen,
    onOpen: onMenuWrapperOpen,
    onClose: onMenuWrapperClose,
  } = useDisclosure();

  const handleOpenModalAlternarLoja = () => {
    onMenuWrapperClose();
    onModalAlternarLojaOpen();
  };

  return (
    <>
      <Avatar
        src={fotoUsuario}
        size="sm"
        cursor="pointer"
        onClick={onMenuWrapperOpen}
        border="1px"
        borderColor="primary.400"
      />
      <DrawerPadraoChakra
        size="full"
        onClose={onMenuWrapperClose}
        isOpen={isMenuWrapperOpen}
      >
        <DrawerContent background="secondary.500">
          <DrawerHeader p={8}>
            <Flex alignItems="center" justifyContent="center">
              <IconButton
                aria-label="Fechar modal"
                icon={<FiXCircle />}
                variant="link"
                borderRadius="md"
                fontSize="3xl"
                color="primary.800"
                _active={{ color: 'primary.700' }}
                onClick={onMenuWrapperClose}
              />
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            {lojaFantasia && (
              <Flex
                alignItems="center"
                justifyContent="center"
                borderTopWidth="1px"
                borderBottomWidth="1px"
                borderColor="primary.800"
                height="70px"
              >
                <Stack alignItems="center" justifyContent="center">
                  <Text fontSize="sm" color="primary.500">
                    {lojaFantasia}
                  </Text>
                  <Text fontSize="xs" color="primary.500">
                    <Icon as={ConfiguracoesUsuariosIcon} fontSize="xl" />{' '}
                    {nomeUsuario}
                  </Text>
                </Stack>
              </Flex>
            )}
            <Ajuda asWrapperButton />
            <WrapperButton
              icon={NotificacoesIcon}
              title="Notificações"
              isDisabled
            />
            <WrapperButton
              icon={AlternarLojaIcon}
              title="Alternar loja"
              onClick={handleOpenModalAlternarLoja}
            />
            <WrapperButton
              icon={LogoutIcon}
              title="Sair"
              onClick={() => logOff()}
            />
          </DrawerBody>
        </DrawerContent>
      </DrawerPadraoChakra>
    </>
  );
};
