import { Flex, FlexProps } from '@chakra-ui/react';

type SideContainerProps = FlexProps & {
  children: React.ReactNode;
};

export const SideContainer = ({ children, ...props }: SideContainerProps) => {
  return (
    <Flex alignItems="center" mb="5px" justifyContent="row" {...props}>
      {children}
    </Flex>
  );
};
