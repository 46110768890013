import React from 'react';

import { ListarEtiquetas } from 'pages/EtiquetasPersonalizadas/ListarEtiquetas';
import { ImprimirEtiquetas } from 'pages/EtiquetasPersonalizadas/ImprimirEtiquetas';
import { CadastrarEtiqueta } from 'pages/EtiquetasPersonalizadas/FormularioEtiquetas/CadastrarEtiqueta';
import { AlterarEtiquetas } from 'pages/EtiquetasPersonalizadas/FormularioEtiquetas/AlterarEtiquetas';
import { VisualizarEtiqueta } from 'pages/EtiquetasPersonalizadas/FormularioEtiquetas/VisualizarEtiquetas';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

export const EtiquetaPersonalizadaRoutes = [
  <LayoutGuard
    key="etiquetas-personalizadas-listar"
    permissaoFuncionalidade={
      ConstanteFuncionalidades.ETIQUETAS_PERSONALIZADAS_LISTAR
    }
    component={ListarEtiquetas}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Etiquetas Personalizadas',
      },
    ]}
    path={ConstanteRotas.ETIQUETAS_PERSONALIZADAS}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="etiquetas-personalizadas-imprimir"
    permissaoFuncionalidade={
      ConstanteFuncionalidades.ETIQUETAS_PERSONALIZADAS_LISTAR
    }
    component={ImprimirEtiquetas}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Etiquetas Personalizadas',
        path: ConstanteRotas.ETIQUETAS_PERSONALIZADAS,
      },
      {
        title: 'Impressão',
      },
    ]}
    path={ConstanteRotas.ETIQUETAS_IMPRIMIR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="etiquetas-personalizadas-cadastrar"
    permissaoFuncionalidade={
      ConstanteFuncionalidades.ETIQUETAS_PERSONALIZADAS_CADASTRAR
    }
    component={CadastrarEtiqueta}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Etiquetas Personalizadas',
        path: ConstanteRotas.ETIQUETAS_PERSONALIZADAS,
      },
      { title: 'Nova' },
    ]}
    path={ConstanteRotas.ETIQUETAS_PERSONALIZADAS_CADASTRAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="etiquetas-personalizadas-alterar"
    permissaoFuncionalidade={
      ConstanteFuncionalidades.ETIQUETAS_PERSONALIZADAS_ALTERAR
    }
    component={AlterarEtiquetas}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Etiquetas Personalizadas',
        path: ConstanteRotas.ETIQUETAS_PERSONALIZADAS,
      },
      {
        title: 'Editar',
      },
    ]}
    path={ConstanteRotas.ETIQUETA_ALTERAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="etiquetas-personalizadas-visualizar"
    permissaoFuncionalidade={
      ConstanteFuncionalidades.ETIQUETAS_PERSONALIZADAS_VISUALIZAR
    }
    component={VisualizarEtiqueta}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Etiquetas Personalizadas',
        path: ConstanteRotas.ETIQUETAS_PERSONALIZADAS,
      },
      {
        title: 'Visualizar',
      },
    ]}
    path={ConstanteRotas.ETIQUETA_VISUALIZAR}
    exact
    meta={{ auth: true }}
  />,
];
