import { Divider, Flex, Grid } from '@chakra-ui/react';
import { chaveAcessoMask } from 'helpers/format/fieldsMasks';
import React from 'react';

interface ImpressaoInterface {
  chaveAcesso: string;
  url: string;
}

const ChaveAcesso = ({ chaveAcesso, url }: ImpressaoInterface) => {
  return (
    <Grid>
      <Divider margin="3px 0px" />
      <Flex className="textCenter" fontWeight="900">
        Consulte pela Chave de Acesso em
      </Flex>
      <Flex className="textCenter">{url}</Flex>
      <Flex className="textCenter">{chaveAcessoMask(chaveAcesso)}</Flex>
    </Grid>
  );
};

export default ChaveAcesso;
