import ModelosFiscaisEnum from 'constants/enum/fiscal/modelosFiscais';
import StatusFiscaisEnum from 'constants/enum/fiscal/statusFiscal';

import {
  DadosCompartilhadosProps,
  FiltroCompartilhamentoProps,
} from '../types/validationsTypes';

const FiltroDadosCompartilhados = ({
  sharedPerDepartment,
  handleFunctionsImpressoesRef,
  setIsLoading,
  isDevolucao,
  hasOperacao,
  possuiCartaCorrecao,
  notaFiscalStatus,
  modeloNotaFiscal,
  fecharModal,
  onClose,
}: FiltroCompartilhamentoProps) => {
  const naoPossuiDanfe = !(
    notaFiscalStatus === StatusFiscaisEnum.EmDigitacao ||
    notaFiscalStatus === StatusFiscaisEnum.Autorizada
  );

  const dataToBeShared: DadosCompartilhadosProps = {
    contasReceber: {
      impressao: [
        {
          title: 'Imprimir relatório A4',
          onClick: () => {
            setIsLoading(true);
            if (sharedPerDepartment?.relatorioContasReceber?.impressao) {
              sharedPerDepartment.relatorioContasReceber.impressao();
              if (handleFunctionsImpressoesRef.current?.onClose)
                handleFunctionsImpressoesRef.current.onClose();
            }
            setIsLoading(false);
          },
        },
        {
          title: 'Recibo 80mm',
          onClick: () => {
            setIsLoading(true);
            if (
              handleFunctionsImpressoesRef.current &&
              handleFunctionsImpressoesRef?.current.getPrintFunctions80mm
            )
              handleFunctionsImpressoesRef.current.getPrintFunctions80mm();
            setIsLoading(false);
          },
        },
        {
          title: 'Recibo 56mm',
          onClick: () => {
            setIsLoading(true);
            if (
              handleFunctionsImpressoesRef.current &&
              handleFunctionsImpressoesRef?.current.getPrintFunctions56mm
            )
              handleFunctionsImpressoesRef.current.getPrintFunctions56mm();
            setIsLoading(false);
          },
        },
      ],
      email: [
        {
          title: 'Recibo A4',
          onClick: () => {
            setIsLoading(true);
            if (sharedPerDepartment?.relatorioContasReceber?.email) {
              sharedPerDepartment.relatorioContasReceber.email();
              if (handleFunctionsImpressoesRef.current?.onClose)
                handleFunctionsImpressoesRef.current.onClose();
            }
            setIsLoading(false);
          },
        },
      ],
    },
    sharedListContasReceber: {
      impressao: [
        {
          title: 'Relatório contas a receber',
          onClick: async () => {
            setIsLoading(true);
            if (sharedPerDepartment?.relatorioContasReceber?.printList?.print) {
              await sharedPerDepartment.relatorioContasReceber.printList.print();
              if (handleFunctionsImpressoesRef.current?.onClose)
                handleFunctionsImpressoesRef.current.onClose();
            }
            setIsLoading(false);
          },
        },
      ],
      email: [
        {
          title: 'Relatório contas a receber',
          onClick: async () => {
            setIsLoading(true);
            if (
              sharedPerDepartment?.relatorioContasReceber?.printList
                ?.sendPrintEmail
            ) {
              await sharedPerDepartment.relatorioContasReceber.printList.sendPrintEmail();
              if (handleFunctionsImpressoesRef.current?.onClose)
                handleFunctionsImpressoesRef.current.onClose();
            }
            setIsLoading(false);
          },
        },
      ],
    },
    contasPagar: {
      impressao: [
        {
          title: 'Relatório contas a pagar',
          onClick: async () => {
            setIsLoading(true);
            if (sharedPerDepartment?.relatorioContasPagar?.impressao) {
              sharedPerDepartment.relatorioContasPagar.impressao();
              if (handleFunctionsImpressoesRef.current?.onClose)
                handleFunctionsImpressoesRef.current.onClose();
            }
            setIsLoading(false);
          },
        },
      ],
      email: [
        {
          title: 'Relatório contas a pagar',
          onClick: () => {
            setIsLoading(true);
            if (sharedPerDepartment?.relatorioContasPagar?.email) {
              sharedPerDepartment.relatorioContasPagar.email();
              if (handleFunctionsImpressoesRef.current?.onClose)
                handleFunctionsImpressoesRef.current.onClose();
            }
            setIsLoading(false);
          },
        },
      ],
    },
    relatorioFechamentoCaixa: {
      impressao: [
        {
          title: 'Imprimir A4',
          onClick: async () => {
            setIsLoading(true);
            if (sharedPerDepartment?.relatorioFechamentoCaixa?.impressao) {
              await sharedPerDepartment.relatorioFechamentoCaixa.impressao();
              if (fecharModal) {
                onClose();
              }
            }
            setIsLoading(false);
          },
        },
        {
          title: 'Imprimir bobina',
          onClick: async () => {
            setIsLoading(true);
            if (
              handleFunctionsImpressoesRef.current &&
              handleFunctionsImpressoesRef.current
                .getPrintFunctionsFechamentoCaixa
            ) {
              await handleFunctionsImpressoesRef.current.getPrintFunctionsFechamentoCaixa();
              if (fecharModal) {
                onClose();
              }
            }
            setIsLoading(false);
          },
        },
      ],
      email: [
        {
          title: 'Relatorio fechamento de Caixa',
          onClick: async () => {
            setIsLoading(true);

            if (sharedPerDepartment?.relatorioFechamentoCaixa?.email) {
              await sharedPerDepartment.relatorioFechamentoCaixa.email();
              if (fecharModal) {
                onClose();
              }
            }
            setIsLoading(false);
          },
        },
      ],
    },

    notaFiscal: {
      impressao: [
        {
          title: 'Imprimir relatório nota fiscal',
          onClick: () => {
            setIsLoading(true);
            if (sharedPerDepartment?.relatorioNotaFiscal?.impressao) {
              sharedPerDepartment.relatorioNotaFiscal.impressao();
            }
            setIsLoading(false);
          },
        },
      ],
      email: [
        {
          title: 'Enviar email nota fiscal',
          onClick: () => {
            setIsLoading(true);
            if (sharedPerDepartment?.relatorioNotaFiscal?.email) {
              sharedPerDepartment.relatorioNotaFiscal.email();
            }
            setIsLoading(false);
          },
        },
      ],
    },
    relatorioListarNotaFiscal: {
      impressao: [
        {
          title:
            modeloNotaFiscal === ModelosFiscaisEnum.NFe
              ? 'Danfe'
              : 'Cupom fiscal',
          isHidden: hasOperacao || naoPossuiDanfe,
          onClick: () => {
            setIsLoading(true);
            if (sharedPerDepartment?.relatorioListarNotaFiscal?.impressao) {
              sharedPerDepartment.relatorioListarNotaFiscal.impressao();
            }
            onClose();
            setIsLoading(false);
          },
        },
        {
          title: 'Carta de correção',
          isHidden: !possuiCartaCorrecao,
          onClick: () => {
            setIsLoading(true);
            if (sharedPerDepartment?.relatorioListarNotaFiscal?.cartaCorrecao) {
              sharedPerDepartment.relatorioListarNotaFiscal.cartaCorrecao();
            }
            onClose();
            setIsLoading(false);
          },
        },
      ],
      email: [
        {
          title: '',
          isHidden: hasOperacao,

          onClick: () => {
            setIsLoading(true);
            if (sharedPerDepartment?.relatorioListarNotaFiscal?.email) {
              sharedPerDepartment.relatorioListarNotaFiscal.email();
              if (handleFunctionsImpressoesRef.current?.onClose)
                handleFunctionsImpressoesRef.current.onClose();
            }
            setIsLoading(false);
          },
        },
      ],
      xml: [
        {
          title: '',
          isHidden: hasOperacao,
          onClick: () => {
            setIsLoading(true);
            if (sharedPerDepartment?.relatorioListarNotaFiscal?.xml) {
              sharedPerDepartment.relatorioListarNotaFiscal.xml();
              if (handleFunctionsImpressoesRef.current?.onClose)
                handleFunctionsImpressoesRef.current.onClose();
            }
            onClose();
            setIsLoading(false);
          },
        },
      ],
    },
    operacoes: {
      impressao: [
        {
          title: 'Imprimir relatório operações',
          onClick: () => {
            setIsLoading(true);
            if (sharedPerDepartment?.relatorioOperacoes?.impressao) {
              sharedPerDepartment.relatorioOperacoes.impressao();
              if (handleFunctionsImpressoesRef.current?.onClose)
                handleFunctionsImpressoesRef.current.onClose();
            }
            setIsLoading(false);
          },
        },
      ],
      email: [
        {
          title: 'Enviar email operações',
          onClick: () => {
            setIsLoading(true);
            if (sharedPerDepartment?.relatorioOperacoes?.email) {
              sharedPerDepartment.relatorioOperacoes.email();
              if (handleFunctionsImpressoesRef.current?.onClose)
                handleFunctionsImpressoesRef.current.onClose();
            }
            setIsLoading(false);
          },
        },
      ],
    },
    relatorioConferenciaEstoque: {
      impressao: [
        {
          title: 'Relatório de conferência com divergências',
          onClick: async () => {
            setIsLoading(true);
            if (
              sharedPerDepartment?.relatorioConferenciaEstoque?.printDivergent
            ) {
              await sharedPerDepartment.relatorioConferenciaEstoque.printDivergent();
            }
            setIsLoading(false);
          },
        },
        {
          title: 'Relatório de conferência completa',
          onClick: async () => {
            setIsLoading(true);
            if (
              sharedPerDepartment?.relatorioConferenciaEstoque?.printComplete
            ) {
              await sharedPerDepartment.relatorioConferenciaEstoque.printComplete();
            }
            setIsLoading(false);
          },
        },
      ],
      email: [
        {
          title: 'Relatório conferência com divergências',
          onClick: async () => {
            setIsLoading(true);
            if (
              sharedPerDepartment?.relatorioConferenciaEstoque?.emailDivergent
            ) {
              sharedPerDepartment.relatorioConferenciaEstoque.emailDivergent();
              onClose();
            }
            setIsLoading(false);
          },
        },
        {
          title: 'Relatório conferência completa',
          onClick: async () => {
            setIsLoading(true);
            if (
              sharedPerDepartment?.relatorioConferenciaEstoque?.emailComplete
            ) {
              sharedPerDepartment.relatorioConferenciaEstoque.emailComplete();
              onClose();
            }
            setIsLoading(false);
          },
        },
      ],
      exportarCSV: [
        {
          title: 'Relatório de conferência com divergências',
          onClick: async () => {
            setIsLoading(true);
            if (sharedPerDepartment?.relatorioConferenciaEstoque?.exportarCSV) {
              await sharedPerDepartment.relatorioConferenciaEstoque?.exportarCSV();
              if (handleFunctionsImpressoesRef.current?.onClose)
                handleFunctionsImpressoesRef.current?.onClose();
            }
            setIsLoading(false);
          },
        },
        {
          title: 'Relatório de conferência completa',
          onClick: async () => {
            setIsLoading(true);
            if (
              sharedPerDepartment?.relatorioConferenciaEstoque
                ?.exportarCSVCompleto
            ) {
              await sharedPerDepartment.relatorioConferenciaEstoque.exportarCSVCompleto();
              if (handleFunctionsImpressoesRef.current?.onClose)
                handleFunctionsImpressoesRef.current.onClose();
            }
            setIsLoading(false);
          },
        },
      ],
    },
    faturas: {
      impressao: [
        {
          title: 'Imprimir relatório das faturas',
          onClick: () => {
            setIsLoading(true);
            if (sharedPerDepartment?.relatorioFaturas?.impressao) {
              sharedPerDepartment.relatorioFaturas.impressao();
            }
            setIsLoading(false);
          },
        },
      ],
      email: [
        {
          title: 'Enviar email das faturas',
          onClick: () => {
            setIsLoading(true);
            if (sharedPerDepartment?.relatorioFaturas?.email) {
              sharedPerDepartment.relatorioFaturas.email();
            }
            setIsLoading(false);
          },
        },
      ],
    },
    devolucoes: {
      impressao: [
        {
          title: 'Imprimir o comprovante de devolução',
          onClick: () => {
            if (
              handleFunctionsImpressoesRef &&
              handleFunctionsImpressoesRef.current?.getPrintDevolucaoDinheiro
            ) {
              handleFunctionsImpressoesRef.current.getPrintDevolucaoDinheiro();
            }
          },
        },
      ],
    },
    historicoVendasMetasComissao: {
      impressao: [
        {
          title: 'Imprimir histórico vendas',
          onClick: async () => {
            if (sharedPerDepartment?.historicoVendasMetasComissao?.impressao) {
              await sharedPerDepartment.historicoVendasMetasComissao.impressao();
              if (handleFunctionsImpressoesRef.current?.onClose)
                handleFunctionsImpressoesRef.current.onClose();
            }
          },
        },
      ],
      email: [
        {
          title: 'Enviar por e-mail',
          onClick: async () => {
            if (sharedPerDepartment?.historicoVendasMetasComissao?.email) {
              await sharedPerDepartment.historicoVendasMetasComissao.email();
              if (handleFunctionsImpressoesRef.current?.onClose)
                handleFunctionsImpressoesRef.current.onClose();
            }
          },
        },
      ],
    },
    relatorioVendasMetasComissao: {
      impressao: [
        {
          title: 'Relatório resumo da loja',
          isHidden: !sharedPerDepartment?.relatorioVendasMetasComissaoExisteVendedor,
          onClick: async () => {
            setIsLoading(true);
            if (sharedPerDepartment?.relatorioVendasMetasComissao?.resumoLoja) {
              await sharedPerDepartment.relatorioVendasMetasComissao.resumoLoja();
              if (handleFunctionsImpressoesRef.current?.onClose)
                handleFunctionsImpressoesRef.current.onClose();
            }
            setIsLoading(false);
          },
        },
        {
          title: 'Relatório resumo do vendedor',
          isHidden:
            sharedPerDepartment?.relatorioVendasMetasComissaoExisteVendedor,
          onClick: async () => {
            setIsLoading(true);
            if (
              sharedPerDepartment?.relatorioVendasMetasComissao?.resumoVendedor
            ) {
              await sharedPerDepartment.relatorioVendasMetasComissao.resumoVendedor();
              if (handleFunctionsImpressoesRef.current?.onClose)
                handleFunctionsImpressoesRef.current.onClose();
            }
            setIsLoading(false);
          },
        },
        {
          title: 'Relatório histórico de comissão',
          isHidden: !sharedPerDepartment?.relatorioVendasMetasComissaoExisteVendedor,
          onClick: async () => {
            setIsLoading(true);
            if (
              sharedPerDepartment?.relatorioVendasMetasComissao
                ?.relatorioHistoricoComissao
            ) {
              await sharedPerDepartment.relatorioVendasMetasComissao.relatorioHistoricoComissao();
              if (handleFunctionsImpressoesRef.current?.onClose)
                handleFunctionsImpressoesRef.current.onClose();
            }
            setIsLoading(false);
          },
        },
      ],
      email: [
        {
          title: 'Relatório resumo da loja',
          isHidden: !sharedPerDepartment?.relatorioVendasMetasComissaoExisteVendedor,
          onClick: async () => {
            if (
              sharedPerDepartment?.relatorioVendasMetasComissao?.emailResumoLoja
            ) {
              sharedPerDepartment.relatorioVendasMetasComissao.emailResumoLoja();
              onClose();
            }
          },
        },
        {
          title: 'Relatório resumo do vendedor',
          isHidden:
            sharedPerDepartment?.relatorioVendasMetasComissaoExisteVendedor,
          onClick: async () => {
            setIsLoading(true);
            if (
              sharedPerDepartment?.relatorioVendasMetasComissao
                ?.emailResumoVendedor
            ) {
              sharedPerDepartment.relatorioVendasMetasComissao.emailResumoVendedor();
              onClose();
            }
            setIsLoading(false);
          },
        },
        {
          title: 'Relatório histórico de comissão',
          isHidden: !sharedPerDepartment?.relatorioVendasMetasComissaoExisteVendedor,
          onClick: async () => {
            setIsLoading(true);
            if (
              sharedPerDepartment?.relatorioVendasMetasComissao
                ?.emailRelatorioHistoricoComissao
            ) {
              sharedPerDepartment.relatorioVendasMetasComissao.emailRelatorioHistoricoComissao();
              onClose();
            }
            setIsLoading(false);
          },
        },
      ],
    },
    listagemVouchers: true,
    relatorioCurvaABC: {
      impressao: [
        {
          title: 'Imprimir relatório curva ABC',
          onClick: async () => {
            setIsLoading(true);
            if (sharedPerDepartment?.relatorioCurvaABC?.impressao) {
              await sharedPerDepartment.relatorioCurvaABC.impressao();
              if (handleFunctionsImpressoesRef.current?.onClose)
                handleFunctionsImpressoesRef.current.onClose();
            }
            setIsLoading(false);
          },
        },
      ],
      email: [
        {
          title: 'Relatório curva ABC',
          onClick: async () => {
            setIsLoading(true);
            if (sharedPerDepartment?.relatorioCurvaABC?.email) {
              await sharedPerDepartment.relatorioCurvaABC.email();
              if (handleFunctionsImpressoesRef.current?.onClose)
                handleFunctionsImpressoesRef.current.onClose();
            }
            setIsLoading(false);
          },
        },
      ],
      exportarCSV: [
        {
          title: 'Exportar relatório curva ABC',
          onClick: async () => {
            setIsLoading(true);
            if (sharedPerDepartment?.relatorioCurvaABC?.exportarCSV) {
              await sharedPerDepartment.relatorioCurvaABC.exportarCSV();
              if (handleFunctionsImpressoesRef.current?.onClose)
                handleFunctionsImpressoesRef.current.onClose();
            }
            setIsLoading(false);
          },
        },
      ],
    },
  };

  function compartilharDados() {
    let dadosCompartilhados: DadosCompartilhadosProps;
    if (sharedPerDepartment?.listagemVouchers) {
      dadosCompartilhados = {
        listagemVouchers: dataToBeShared.listagemVouchers,
      };
      return dadosCompartilhados;
    }
    if (
      sharedPerDepartment?.relatorioContasReceber?.printList?.print &&
      sharedPerDepartment?.relatorioContasReceber?.printList?.sendPrintEmail &&
      handleFunctionsImpressoesRef
    ) {
      dadosCompartilhados = {
        sharedListContasReceber: dataToBeShared.sharedListContasReceber,
      };
      return dadosCompartilhados;
    }

    if (
      handleFunctionsImpressoesRef &&
      sharedPerDepartment?.relatorioFechamentoCaixa?.impressao &&
      sharedPerDepartment.relatorioFechamentoCaixa?.email
    ) {
      dadosCompartilhados = {
        relatorioFechamentoCaixa: dataToBeShared.relatorioFechamentoCaixa,
      };
      return dadosCompartilhados;
    }

    if (
      handleFunctionsImpressoesRef &&
      sharedPerDepartment?.relatorioFaturas?.impressao &&
      sharedPerDepartment?.relatorioFaturas?.email
    ) {
      dadosCompartilhados = {
        faturas: dataToBeShared.faturas,
      };
      return dadosCompartilhados;
    }

    if (
      sharedPerDepartment?.relatorioContasReceber &&
      sharedPerDepartment?.relatorioContasReceber?.impressao
    ) {
      dadosCompartilhados = {
        contasReceber: dataToBeShared.contasReceber,
      };
      return dadosCompartilhados;
    }

    if (
      sharedPerDepartment?.relatorioContasPagar?.impressao &&
      sharedPerDepartment?.relatorioContasPagar.email
    ) {
      dadosCompartilhados = {
        contasPagar: dataToBeShared.contasPagar,
      };
      return dadosCompartilhados;
    }
    if (
      sharedPerDepartment?.relatorioFaturas?.impressao &&
      sharedPerDepartment?.relatorioFaturas.email
    ) {
      dadosCompartilhados = {
        faturas: dataToBeShared.faturas,
      };
      return dadosCompartilhados;
    }

    if (
      sharedPerDepartment?.relatorioNotaFiscal?.impressao &&
      sharedPerDepartment?.relatorioNotaFiscal.email
    ) {
      dadosCompartilhados = {
        notaFiscal: dataToBeShared.notaFiscal,
      };
      return dadosCompartilhados;
    }
    if (
      sharedPerDepartment?.relatorioListarNotaFiscal?.impressao &&
      sharedPerDepartment?.relatorioListarNotaFiscal?.email &&
      sharedPerDepartment?.relatorioListarNotaFiscal?.xml
    ) {
      dadosCompartilhados = {
        relatorioListarNotaFiscal: dataToBeShared.relatorioListarNotaFiscal,
      };
      return dadosCompartilhados;
    }

    if (
      sharedPerDepartment?.relatorioOperacoes?.impressao &&
      sharedPerDepartment?.relatorioOperacoes?.email
    ) {
      dadosCompartilhados = {
        operacoes: dataToBeShared.operacoes,
      };
      return dadosCompartilhados;
    }
    if (
      sharedPerDepartment?.historicoVendasMetasComissao?.impressao &&
      sharedPerDepartment?.historicoVendasMetasComissao?.email
    ) {
      dadosCompartilhados = {
        historicoVendasMetasComissao:
          dataToBeShared.historicoVendasMetasComissao,
      };
      return dadosCompartilhados;
    }
    if (
      sharedPerDepartment?.relatorioVendasMetasComissao?.emailResumoVendedor &&
      sharedPerDepartment?.relatorioVendasMetasComissao
        ?.emailRelatorioHistoricoComissao &&
      sharedPerDepartment?.relatorioVendasMetasComissao?.emailResumoLoja &&
      sharedPerDepartment?.relatorioVendasMetasComissao?.resumoVendedor &&
      sharedPerDepartment?.relatorioVendasMetasComissao
        ?.relatorioHistoricoComissao &&
      sharedPerDepartment?.relatorioVendasMetasComissao?.resumoLoja
    ) {
      dadosCompartilhados = {
        relatorioVendasMetasComissao:
          dataToBeShared.relatorioVendasMetasComissao,
      };
      return dadosCompartilhados;
    }

    if (
      sharedPerDepartment?.relatorioConferenciaEstoque?.emailComplete &&
      sharedPerDepartment?.relatorioConferenciaEstoque?.emailDivergent &&
      sharedPerDepartment?.relatorioConferenciaEstoque?.printComplete &&
      sharedPerDepartment?.relatorioConferenciaEstoque?.printDivergent &&
      sharedPerDepartment?.relatorioConferenciaEstoque?.exportarCSV &&
      sharedPerDepartment?.relatorioConferenciaEstoque?.exportarCSVCompleto
    ) {
      dadosCompartilhados = {
        relatorioConferenciaEstoque: dataToBeShared.relatorioConferenciaEstoque,
      };

      return dadosCompartilhados;
    }

    if (
      handleFunctionsImpressoesRef &&
      handleFunctionsImpressoesRef.current?.getPrintDevolucaoDinheiro &&
      isDevolucao
    ) {
      dadosCompartilhados = {
        devolucoes: dataToBeShared.devolucoes,
      };

      return dadosCompartilhados;
    }

    if (sharedPerDepartment?.relatorioCurvaABC) {
      dadosCompartilhados = {
        relatorioCurvaABC: dataToBeShared.relatorioCurvaABC,
      };
      return dadosCompartilhados;
    }

    return {};
  }

  return compartilharDados();
};

export default FiltroDadosCompartilhados;
