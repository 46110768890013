import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import RegraLimiteCreditoEnum from 'constants/enum/regraLimiteCredito';

const schema = yup.object().shape({
  descricao: yup
    .string()
    .trim()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .max(30, 'O campo de descrição não pode conter mais que 30 caracteres.'),
  ativo: yup.boolean().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  diasAtrasoBloqueio: yup
    .number()
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .test(
      'verificarQuantidadeMinimaDiasAtrasoBloqueio',
      ConstanteMensagemValidacao.DIAS_MAIOR_QUE_ZERO,
      function verificarQuantidadeMinimaDiasAtrasoBloqueio() {
        const { diasAtrasoBloqueio, regraLimiteCredito } = this.parent;
        if (
          regraLimiteCredito &&
          regraLimiteCredito === RegraLimiteCreditoEnum.BLOQUEIO_DIAS_ATRASO &&
          (diasAtrasoBloqueio <= 0 || !diasAtrasoBloqueio)
        ) {
          return false;
        }
        return true;
      }
    ),
});

export type RegraBloqueioSelect = {
  value: number;
  label: string;
  color: string;
};

export type FormData = {
  ativo: boolean;
  regraLimiteCredito: RegraBloqueioSelect;
  diasAtrasoBloqueio: number;
  descricao: string;
};

export const yupResolver = yupResolverInstance(schema);

export const useForm = useFormInstance;
