import styled from 'styled-components';
import { Modal as BootstrapModal } from 'react-bootstrap';

export const Modal = styled(BootstrapModal)`
  .modal-dialog {
    .modal-header {
      margin: 0 22px;
      padding: 16px 0;
    }

    .modal-footer {
      margin: 0 22px;
      padding: 16px 0;
    }
  }

  div[class^='col'] .form-group {
    padding-left: 13px;
    padding-right: 13px;
  }

  div.form-row .form-group,
  div.row .form-group {
    margin-bottom: 27px;
  }
`;
