import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import React, { useState } from 'react';

import { ContainerListagem } from 'components/Layout/Listagem/containerListagem';

import UncontrolledForm from '..';
import { FormData, defaultValues, yupResolver } from '../validationForm';
import { useCredenciadoraCartaoForm } from '../hooks';

const CredenciadoraCartaoCadastrar = () => {
  const {
    cadastrarCredenciadoraCartao,
    redirecionarCredenciadoraCartaoListar,
    codigoSatPadrao,
  } = useCredenciadoraCartaoForm();

  const [isLoading, setIsLoading] = useState(false);

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues,
  });

  const { handleSubmit, reset } = formMethods;

  const handleSalvar = async (data: FormData) => {
    setIsLoading(true);

    const { success } = await cadastrarCredenciadoraCartao({
      ...data,
      codigoSat: data?.codigoSat || codigoSatPadrao,
    });

    if (success) {
      toast.success('Credenciadora de cartão cadastrada com sucesso');
      redirecionarCredenciadoraCartaoListar();
    }

    setIsLoading(false);
  };

  const handleSalvarInserirNovo = async (data: FormData) => {
    setIsLoading(true);

    const { success } = await cadastrarCredenciadoraCartao({
      ...data,
      codigoSat: data?.codigoSat || codigoSatPadrao,
    });

    if (success) {
      toast.success('Credenciadora de cartão cadastrada com sucesso');
      reset();
    }

    setIsLoading(false);
  };

  return (
    <ContainerListagem
      maxWidth="full"
      formMethods={formMethods}
      isLoading={isLoading}
      onSubmit={handleSubmit(handleSalvar)}
      onSubmitReset={handleSubmit(handleSalvarInserirNovo)}
    >
      <UncontrolledForm />
    </ContainerListagem>
  );
};

export { CredenciadoraCartaoCadastrar };
