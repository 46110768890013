export type TipoRejeicaoNotaFiscal = 0 | 1;

const TipoRejeicaoNotaFiscalEnum = {
  SEFAZ: 0,
  XSD: 1,

  properties: {
    0: { name: 'SEFAZ', value: 0 },
    1: { name: 'XSD', value: 1 },
  },
};

export default TipoRejeicaoNotaFiscalEnum;
