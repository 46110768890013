import { useCallback, useEffect, useState } from 'react';
import {
  GridItem,
  Box,
  Text,
  Flex,
  Icon,
  Button,
  useDisclosure,
  useMediaQuery,
  HStack,
  IconButton,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { FormProvider, useForm } from 'react-hook-form';
import { FiChevronLeft } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import auth from 'modules/auth';
import ConstanteRotasPDV from 'constants/rotasPDV';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteFuncionalidades from 'constants/permissoes';
import { StatusPesquisaClientesFornecedor } from 'constants/enum/statusPesquisaClientesFornecedor';
import { DecimalMask } from 'helpers/format/fieldsMasks';
import { formatDate } from 'helpers/format/formatStringDate';
import api, { ResponseApi } from 'services/api';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';
import { useDebounce } from 'hooks/useDebounce';

import { ModalCodigoBarras } from 'pages/PDV/Lancamento/AdicionarEditarItem/ModalCodigoBarras';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { FilterAsyncSelect } from 'components/update/Select/FilterAsyncSelect';
import ModalInformacoesCliente from 'components/PDV/Modal/ModalInformacoesCliente';
import Layout from 'components/PDV/Layout';
import { ListagemRecebimentoContas } from 'components/RecebimentoContasComponents/listagemRecebimentoContas';
import { ObterContasEmAbertoResponse } from 'components/RecebimentoContasComponents/validationForm';
import { BotoesParaEfetuarBaixa } from 'components/RecebimentoContasComponents/botoesParaEfetuarBaixa';
import { MobileModalMenuHeader } from 'components/PDV/Layout/MobileModalMenu/MobileModalMenuHeader';
import Input from 'components/PDV/Input';
import ModalAlterarCliente from 'components/PDV/Modal/ModalAlterarCliente';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { FinanceiroHistoricoCliente, LeitorCodigoBarrasIcon } from 'icons';

import { FormData, formDefaultValues } from './validationForm';

type FornecedorOptionResponse = {
  id: string;
  nome: string;
};

type ClienteSelectProps = {
  label: string;
  value: string;
};

export function ListarRecebimentoContasPdv() {
  const [idCliente, setIdCliente] = useState('');
  const [informacoesCliente, setInformacoesCliente] = useState<
    FornecedorOptionResponse[]
  >();

  const [modalAlterarIsOpen, setModalAlterarIsOpen] = useState(false);
  const [
    informacoesRecebimentoContas,
    setInformacoesRecebimentoContas,
  ] = useState<ObterContasEmAbertoResponse>();
  const [searchNumberOperation, setSearchNumberOperation] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const formMethods = useForm<FormData>({
    defaultValues: formDefaultValues,
  });
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const [isSmallerThan1200] = useMediaQuery('(max-width: 1200px)');
  const [isSmallerThan1345] = useMediaQuery('(max-width: 1345px)');

  const possuiPermissaoVisualizarInformacoesCliente = auth.possuiPermissao(
    ConstanteFuncionalidades.PDV_VISUALIZAR_INFORMACOES_CLIENTE
  ).permitido;

  const { casasDecimais } = usePadronizacaoContext();
  const history = useHistory();
  const {
    isOpen: isOpenModalInformacoesCliente,
    onOpen: onOpenModalInformacoesCliente,
    onClose: onCloseModalInformacoesCliente,
  } = useDisclosure();
  const {
    isOpen: isModalCodigoBarrasOpen,
    onOpen: onModalCodigoBarrasOpen,
    onClose: onModalCodigoBarrasClose,
  } = useDisclosure();

  const debouncedValue = useDebounce(searchNumberOperation, 500);

  const { watch, setValue } = formMethods;
  const clienteSelecionado = watch('clienteFornecedor');

  const getFornecedoresOptions = useCallback(async (inputValue: string) => {
    setIsLoading(true);
    const response = await api.get<
      void,
      ResponseApi<FornecedorOptionResponse[]>
    >(ConstanteEnderecoWebservice.CLIENTE_FORNECEDOR_LISTAR_SELECT, {
      params: {
        cpfCnpjNomeApelidoCodigoExterno: inputValue,
        filtroTipoCadastroPessoa: StatusPesquisaClientesFornecedor.CLIENTES,
      },
    });

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        setIsLoading(false);
        return response.dados.map((fornecedor) => ({
          label: fornecedor.nome,
          value: fornecedor.id,
        }));
      }
    }
    setIsLoading(false);
    return [];
  }, []);

  const updateCustomer = useCallback(
    async (newCustomer: { nome: string; id: string }) => {
      const customer = {
        value: newCustomer.id,
        label: newCustomer.nome,
      };
      setValue('clienteFornecedor', customer);
    },
    [setValue]
  );

  const openUpdateCustomer = async (idClienteValue: string) => {
    setIdCliente(idClienteValue);

    if (
      auth.possuiPermissao(ConstanteFuncionalidades.CLIENTE_ALTERAR.codigo)
        .permitido
    ) {
      setModalAlterarIsOpen(true);
    }
  };

  const openEditar = () => {
    onCloseModalInformacoesCliente();
    openUpdateCustomer(clienteSelecionado?.value || '');
  };

  const handleInputNumberOperation = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, '');
    setSearchNumberOperation(e.currentTarget.value);
  };

  const getCustomers = useCallback(async () => {
    const response = await api.get<
      void,
      ResponseApi<FornecedorOptionResponse[]>
    >(ConstanteEnderecoWebservice.CLIENTE_FORNECEDOR_LISTAR_SELECT, {
      params: {
        filtroTipoCadastroPessoa: StatusPesquisaClientesFornecedor.CLIENTES,
      },
    });

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        setInformacoesCliente(response.dados);
      }
    }
  }, []);

  function handleGoToPDVHome() {
    history.push(ConstanteRotasPDV.PDV_HOME);
  }

  const getRecebimentosFromCustomer = useCallback(
    async (clienteId?: string) => {
      setIsLoading(true);

      const cliente = watch('clienteFornecedor');
      setValue('numeroOperacao', '');

      if (clienteId || cliente?.value) {
        const response = await api.get<
          void,
          ResponseApi<ObterContasEmAbertoResponse>
        >(
          ConstanteEnderecoWebservice.RECEBIMENTO_CONTAS_OBTER_CONTAS_EM_ABERTO,
          {
            params: {
              clienteFornecedorId: clienteId || cliente?.value,
            },
          }
        );

        if (response) {
          if (response.avisos) {
            response.avisos.map((item: string) => toast.warning(item));
          }

          if (response.sucesso) {
            if (response.dados) {
              setInformacoesRecebimentoContas(response.dados);
            } else {
              toast.warning('O cliente não possui contas em aberto.');
            }
          }
        }
      }
      setIsLoading(false);
    },
    [setValue, watch]
  );

  const getRecebimentosFromNumberOperation = useCallback(async () => {
    setIsLoading(true);
    if (debouncedValue) {
      const response = await api.get<
        void,
        ResponseApi<ObterContasEmAbertoResponse>
      >(ConstanteEnderecoWebservice.RECEBIMENTO_CONTAS_OBTER_CONTAS_EM_ABERTO, {
        params: { identificador: debouncedValue },
      });

      setInformacoesRecebimentoContas(undefined);

      if (response) {
        if (response.avisos) {
          response.avisos.map((item: string) => toast.warning(item));
        }

        if (response.sucesso) {
          if (response.dados) {
            setInformacoesRecebimentoContas(response.dados);

            const { movimentacaoFinanceira } = response.dados || {};
            if (movimentacaoFinanceira && movimentacaoFinanceira.length > 0) {
              const cliente = {
                label: movimentacaoFinanceira[0].clienteFornecedorNome,
                value: movimentacaoFinanceira[0].clienteFornecedorId,
              };
              setValue('clienteFornecedor', cliente);
            }
          }
        }
      }
    }
    setIsLoading(false);
  }, [debouncedValue, setValue]);

  useEffect(() => {
    getCustomers();
  }, [getCustomers]);

  useEffect(() => {
    getRecebimentosFromNumberOperation();
  }, [getRecebimentosFromNumberOperation]);

  return (
    <Layout isHeaderVisible={isLargerThan900}>
      {!isLargerThan900 && (
        <MobileModalMenuHeader
          voltarIconColor="black"
          color="primary.50"
          onClose={handleGoToPDVHome}
          title="Recebimento de contas"
        />
      )}
      <Flex
        alignItems="center"
        justifyContent="left"
        h={{ base: 'auto', md: '140px' }}
        bg="primary.800"
      >
        {!isSmallerThan1200 ? (
          <Box position="absolute" top="75px" left="50px">
            <Icon
              color="white"
              cursor="pointer"
              w={6}
              h={6}
              onClick={handleGoToPDVHome}
              as={FiChevronLeft}
            />
          </Box>
        ) : (
          <></>
        )}
        <Box px={{ base: 6, md: 8, lg: 24 }} py={{ base: 6, md: 0 }} w="full">
          {isLargerThan900 && (
            <HStack alignItems="center" justifyContent="left" w="full">
              <HStack>
                {isSmallerThan1200 && (
                  <Icon
                    color="white"
                    w={6}
                    h={6}
                    onClick={handleGoToPDVHome}
                    as={FiChevronLeft}
                  />
                )}
                <Text as="h2" color="secondary.500" fontSize="18px">
                  Recebimento de contas
                </Text>
              </HStack>
            </HStack>
          )}
          <SimpleGridForm>
            <FormProvider {...formMethods}>
              <GridItem
                w="full"
                mt={{ base: '0px', md: '18px' }}
                colSpan={{ base: 12, md: 12, lg: 8 }}
              >
                <Flex w="full" gap="24px" flexWrap="wrap">
                  <Box mt="18px" w={['100%', '60%', '70%', '60%']}>
                    <FilterAsyncSelect
                      id="clienteFornecedor"
                      name="clienteFornecedor"
                      asControlledByObject
                      required
                      placeholder="Informe o cliente"
                      onSelect={() => {
                        getRecebimentosFromCustomer();
                      }}
                      handleGetOptions={getFornecedoresOptions}
                      autoFocus
                      hasLupaIcon={isLargerThan900}
                      hideDropdown={!isLargerThan900}
                      asMobileView={!isLargerThan900}
                    />
                  </Box>
                  <Flex
                    w={['100%', '36%', '27%', '36%']}
                    borderBottom="1px solid #ccc"
                    align="flex-end"
                    _focusWithin={{ borderColor: '#96cd00' }}
                  >
                    <Input
                      border="none"
                      _focus={{
                        border: 'none',
                      }}
                      bg="transparent"
                      name="numeroOperacao"
                      _autofill={{
                        backgroundColor: 'transparent',
                        WebkitTextFillColor: 'white',
                        color: 'white',
                      }}
                      transition="background-color 0s 6000000s, color 0s 6000000s !important"
                      label="Número da operação"
                      labelColor="white"
                      placeholder="Informe o número da operação"
                      onInput={handleInputNumberOperation}
                      color="white"
                      px="0"
                    />
                    {!isLargerThan900 && (
                      <>
                        <IconButton
                          aria-label="Leitor de códigos de barras"
                          icon={
                            <Icon as={LeitorCodigoBarrasIcon} boxSize="6" />
                          }
                          variant="link"
                          borderRadius="sm"
                          mb="12px"
                          onClick={onModalCodigoBarrasOpen}
                        />
                        <ModalCodigoBarras
                          isOpen={isModalCodigoBarrasOpen}
                          onClose={onModalCodigoBarrasClose}
                          onCodigoBarrasScanned={async (
                            codigoBarras: string
                          ) => {
                            setSearchNumberOperation(codigoBarras);

                            onModalCodigoBarrasClose();
                          }}
                        />
                      </>
                    )}
                  </Flex>
                </Flex>
              </GridItem>
              {informacoesRecebimentoContas?.dataUltimoPagamento &&
                !isSmallerThan1200 && (
                  <GridItem colSpan={{ base: 12, lg: 4 }}>
                    <Flex flexWrap="wrap" align="flex-end" height="full">
                      <Button
                        variant="link"
                        color="white"
                        leftIcon={
                          <Icon
                            fontSize="xl"
                            mt="-1"
                            color="white"
                            as={FinanceiroHistoricoCliente}
                          />
                        }
                        mb="4px"
                        onClick={() => {
                          if (possuiPermissaoVisualizarInformacoesCliente) {
                            onOpenModalInformacoesCliente();
                          }
                        }}
                      >
                        {isSmallerThan1345
                          ? 'Últ. pagamento'
                          : 'Último pagamento dia'}{' '}
                        {formatDate(
                          informacoesRecebimentoContas?.dataUltimoPagamento
                        )}
                      </Button>

                      <Text
                        color="white"
                        fontSize="16px"
                        fontWeight="bold"
                        mb="4px"
                      >
                        -{' '}
                        {`R$ ${DecimalMask(
                          informacoesRecebimentoContas.valorUltimoPagamento,
                          casasDecimais.casasDecimaisValor,
                          casasDecimais.casasDecimaisValor
                        )}`}
                      </Text>
                    </Flex>
                  </GridItem>
                )}
            </FormProvider>
          </SimpleGridForm>
        </Box>
      </Flex>
      {isLoading && <LoadingPadrao />}
      {informacoesRecebimentoContas &&
      informacoesRecebimentoContas?.movimentacaoFinanceira?.length > 0 ? (
        <Box w="full" px={{ base: 6, md: 8, lg: 24 }} pb={{ base: 6, md: 0 }}>
          <ListagemRecebimentoContas
            isSmallerThan1200={isSmallerThan1200}
            setInformacoesRecebimentoContas={setInformacoesRecebimentoContas}
            informacoesRecebimentoContas={informacoesRecebimentoContas}
            clienteFornecedorId={clienteSelecionado?.value || ''}
          />
        </Box>
      ) : (
        <Box w="full" px={{ base: 6, md: 8, lg: 24 }} py={{ base: 6, md: 0 }}>
          <Box mt="20px" padding="15px" borderRadius="4px" bg="white">
            Nenhum resultado foi encontrado
          </Box>
        </Box>
      )}
      <Box w="full" pb="24px">
        <BotoesParaEfetuarBaixa
          isSmallerThan={isSmallerThan1200}
          handleObterDetalhesRecebimentoContas={getRecebimentosFromCustomer}
          informacoesRecebimentoContas={informacoesRecebimentoContas}
          clienteFornecedorId={clienteSelecionado?.value || ''}
          informacoesCliente={informacoesCliente}
          casasDecimaisValor={casasDecimais.casasDecimaisValor}
          setInformacoesRecebimentoContas={setInformacoesRecebimentoContas}
        />
      </Box>

      {isOpenModalInformacoesCliente && (
        <ModalInformacoesCliente
          isOpen={isOpenModalInformacoesCliente}
          onClose={onCloseModalInformacoesCliente}
          idCliente={clienteSelecionado?.value || ''}
          openEditar={() => openEditar()}
        />
      )}
      <ModalAlterarCliente
        isOpen={modalAlterarIsOpen}
        setIsOpen={setModalAlterarIsOpen}
        clienteIdPdv={idCliente}
        alterarClienteCallback={updateCustomer}
        chavePermissaoTemporaria=""
      />
    </Layout>
  );
}
