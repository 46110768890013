import React from 'react';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

import HistoricoProdutoListar from '../pages/HistoricoProduto';

export const HistoricoProdutoRoutes = [
  <LayoutGuard
    key="historico-produto-listar"
    permissaoFuncionalidade={ConstanteFuncionalidades.HISTORICO_PRODUTO_LISTAR}
    component={HistoricoProdutoListar}
    breadcrumb={[
      {
        title: 'Estoque',
      },
      {
        title: 'Histórico de Produtos',
      },
    ]}
    path={ConstanteRotas.HISTORICO_PRODUTO}
    exact
    meta={{ auth: true }}
  />,
];
