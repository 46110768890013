import {
  Flex,
  Text,
  Icon,
  Box,
  Button,
  useMediaQuery,
  HStack,
  MenuList,
  MenuItem,
  Menu,
  MenuButton,
  IconButton,
} from '@chakra-ui/react';
import { FiX } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BiHelpCircle } from 'react-icons/bi';

import ConstanteRotas from 'constants/rotas';
import { useTrayEtapasContext } from 'store/Tray';
import { IdentificacaoEtapasTray } from 'constants/enum/IdentificacaoEtapasTray';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import { ModalRemoverDadosIntegracao } from 'components/update/Modal/ModalRemoverDadosIntegracao';

import { Footer } from './Footer';
import { HeaderProdutoSistema } from './Header/HeaderProdutoSistema';
import { ModalAjuda } from '../ProdutoSistema/ModalAjuda';

type LayoutEtapasSmartPosProps = {
  children: React.ReactNode;
};

export const LayoutTray = ({ children }: LayoutEtapasSmartPosProps) => {
  const {
    ref,
    activeStep,
    totalProdutoListados,
    isProdutoSelecionado,
    setActiveStep,
    setIsLoading,
  } = useTrayEtapasContext();

  const history = useHistory();

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

  const isGuiaIntegracao =
    activeStep === IdentificacaoEtapasTray.GUIA_INTEGRACAO;

  const isProdutoSite =
    activeStep === IdentificacaoEtapasTray.LISTA_PRODUTOS_SITE;

  const isProdutoSistema =
    activeStep === IdentificacaoEtapasTray.LISTA_PRODUTO_SISTEMA;

  const isImportandoEnviandoProduto =
    activeStep === IdentificacaoEtapasTray.IMPORTANDO ||
    activeStep === IdentificacaoEtapasTray.ENVIANDO_ZENDAR;

  const isInitialIntegrationSteps =
    activeStep === IdentificacaoEtapasTray.GUIA_INTEGRACAO ||
    activeStep === IdentificacaoEtapasTray.PORTAL_CLIENTE;

  const handleSair = () => {
    history.push(ConstanteRotas.DASHBOARD);
  };

  const handleDesistirConfiguracao = async () => {
    ModalRemoverDadosIntegracao({
      textForConfirm: 'RECOMEÇAR',
      textoExplicacao:
        'Ao desistir da configuração, os dados preenchidos até aqui serão excluídos e será necessário reiniciar o guia de integração desde o início',
      callback: async () => {
        if (isInitialIntegrationSteps) {
          setActiveStep(IdentificacaoEtapasTray.GUIA_INTEGRACAO);
        } else {
          setIsLoading(true);
          const response = await api.post<void, ResponseApi>(
            ConstanteEnderecoWebservice.INTEGRACAO_TRAY_EXCLUIR
          );
          if (response) {
            if (response?.avisos) {
              response.avisos.forEach((aviso: string) => toast.warning(aviso));
            }
            if (response?.sucesso) {
              setActiveStep(IdentificacaoEtapasTray.GUIA_INTEGRACAO);
            }
            setIsLoading(false);
          }
          setIsLoading(false);
        }
      },
    });
  };

  return (
    <Flex
      px={isProdutoSistema ? undefined : ['10px', '10px', '20px', '40px']}
      bg="gray.50"
      w="full"
      flexDir="column"
      minH="100vh"
      pt={['0', '24px']}
      overflow="hidden"
      h="full"
      alignItems="center"
      position="relative"
    >
      <Flex
        pb="24px"
        pt={['24px', '0']}
        px={isProdutoSistema ? ['10px', '10px', '20px', '40px'] : undefined}
        alignItems="center"
        w="full"
        h="51px"
        justifyContent="space-between"
      >
        <Flex flex={1}>
          {isLargerThan900 ? (
            <Text color="primary.50" fontSize="18px">
              Guia de integração {'>'} Tray{' '}
            </Text>
          ) : (
            <>
              {!isGuiaIntegracao && (
                <Text
                  cursor="pointer"
                  onClick={() => {
                    if (ref.current?.handleVoltar) {
                      ref.current?.handleVoltar();
                    }
                  }}
                  pl="10px"
                  fontSize="14px"
                >
                  Voltar
                </Text>
              )}
            </>
          )}
        </Flex>

        {isProdutoSite && (
          <Flex justifyContent="space-between" position="relative" flex={1}>
            <Flex
              mr={['10px', '10px', '10px', '0']}
              flex={2}
              justifyContent="center"
              alignItems="center"
            >
              <Text whiteSpace="nowrap">
                Total de produtos listados: {totalProdutoListados}
              </Text>
            </Flex>
            <Flex
              flex={0}
              right={['0', '0', '0', '-100px']}
              whiteSpace="nowrap"
              position={['relative', 'relative', 'relative', 'absolute']}
            >
              <Text color="red.500">Produtos pendentes de ação: 5</Text>
            </Flex>
          </Flex>
        )}

        <HStack
          flex={1}
          spacing={['0', '0', '0', '24px']}
          justifyContent="flex-end"
        >
          {(isProdutoSite || isProdutoSistema) && isLargerThan700 && (
            <HStack spacing={['13px', '13px', '13px', '24px']}>
              <HeaderProdutoSistema />
            </HStack>
          )}

          {!isLargerThan700 && isProdutoSistema && (
            <Icon
              cursor="pointer"
              boxSize="18px"
              color="gray.500"
              onClick={() => {
                ModalAjuda();
              }}
              as={BiHelpCircle}
            />
          )}
          <Menu>
            <MenuButton
              border="none"
              boxShadow="none"
              _active={{
                border: 'none',
                boxShadow: 'none',
              }}
              _focus={{
                boxShadow: 'none',
              }}
              variant="linkOutline"
              as={IconButton}
              aria-label="Options"
              icon={<Icon boxSize="20px" as={FiX} />}
            />
            <MenuList>
              <MenuItem onClick={handleSair}>
                Sair e continuar mais tarde
              </MenuItem>
              {!isImportandoEnviandoProduto && (
                <MenuItem onClick={handleDesistirConfiguracao}>
                  Desistir da configuração
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        </HStack>
      </Flex>
      {!isLargerThan700 && isProdutoSistema && (
        <Box w="full" h="134px" px="10px">
          <HeaderProdutoSistema />
        </Box>
      )}

      {isProdutoSistema ? (
        <Box
          display="flex"
          w="full"
          flexDirection="column"
          overflow="hidden"
          h={[
            'calc(100vh - 180px)',
            'calc(100vh - 75px)',
            'calc(100vh - 75px)',
          ]}
          overflowX="hidden"
        >
          <Flex
            overflowX="auto"
            overflowY="hidden"
            justifyContent="center"
            flex="1"
            mb="10px"
          >
            <Box w={['92%', '92%', '70%', '60%']}>{children}</Box>
          </Flex>
          {isProdutoSelecionado && (
            <Flex
              h={['50px', '70px', '56px']}
              bg="violet.500"
              alignItems="center"
              justifyContent="center"
            >
              <Button
                w="268px"
                variant="solid"
                colorScheme="secondary"
                color="violet.900"
                onClick={() => {
                  if (ref?.current?.handleConfirmarProdutoSelecionado) {
                    ref?.current?.handleConfirmarProdutoSelecionado();
                  }
                }}
              >
                Confirmar produtos selecionados
              </Button>
            </Flex>
          )}
        </Box>
      ) : (
        <Box
          w={['92%', '92%', '70%', isProdutoSite ? '60%' : '50%']}
          display="flex"
          flexDirection="column"
          minHeight="calc(100vh - 75px)"
          overflow="hidden"
        >
          <Box overflowY="auto" flex="1" mb="20px">
            {children}
          </Box>
          {!isProdutoSite && (
            <Flex mt="auto" mb="20px" justifyContent="center">
              <Footer />
            </Flex>
          )}
        </Box>
      )}
    </Flex>
  );
};
