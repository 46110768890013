import { Box, Collapse, GridItem } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { Switch } from 'components/update/Switch';
import { TextArea } from 'components/update/TextArea';
import Input from 'components/PDV/Input';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';

import { Container } from '../../components/Container';
import { VincularFormaRecebimento } from '../VincularFormaRecebimento';
import { FormData } from '../validationForms';
import { useFormaRecebimento } from '../hooks';

export const FormaRecebimentoItem = () => {
  const formMethods = useFormContext<FormData>();

  const { tefWatch, handleConfigHasTef } = useFormaRecebimento(formMethods);

  return (
    <Box>
      <Box minH="80px">
        <Container colSpan={[12, 6, 6]} p="0" title="Tef">
          <Box>
            <Switch
              size="md"
              change={(event) => handleConfigHasTef(event)}
              id="tef"
              label="Utilizar TEF por Integradora"
              name="tef"
            />

            <Collapse in={tefWatch} animateOpacity>
              <Box mt="20px" pl="2px">
                <SimpleGridForm>
                  <GridItem colSpan={[12, 12, 6]}>
                    <Input
                      isDisabled
                      id="idConfigTef"
                      label="TEF"
                      name="idConfigTef"
                    />
                  </GridItem>
                  <GridItem colSpan={[12, 12, 6]}>
                    <Input
                      id="endereco"
                      placeholder="Informe o endereço IP"
                      label="Endereço IP"
                      name="endereco"
                    />
                  </GridItem>
                  <GridItem colSpan={[12, 12, 6]}>
                    <Input
                      id="loja"
                      placeholder="Informe a loja"
                      label="Loja"
                      name="loja"
                    />
                  </GridItem>
                  <GridItem colSpan={[12, 12, 6]}>
                    <Input
                      id="senhaSupervisor"
                      placeholder="*****"
                      isPassword={tefWatch}
                      inputRightElement
                      label="Senha supervisor"
                      name="senhaSupervisor"
                    />
                  </GridItem>
                  <GridItem colSpan={12}>
                    <TextArea
                      name="parametros"
                      id="parametros"
                      placeholder="Informe o valor do parâmetros"
                      label="Parâmetros"
                    />
                  </GridItem>
                </SimpleGridForm>
              </Box>
            </Collapse>
          </Box>
        </Container>
      </Box>
      <VincularFormaRecebimento />
    </Box>
  );
};
