import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

const schema = yup.object().shape({
  casasDecimaisValor: yup
    .number()
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .test(
      'casasDecimaisValor',
      ConstanteMensagemValidacao.VALOR_MINIMO_2,
      (value) => {
        if (value && value > 1) return true;

        return false;
      }
    )
    .test('casasDecimaisValor', 'Valor máximo permitido é 6', (value) => {
      if (value && value <= 6) return true;

      return false;
    }),
  casasDecimaisQuantidade: yup
    .number()
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .test('casasDecimaisQuantidade', 'Valor máximo permitido é 4', (value) => {
      if (value === 0 || (value && value <= 4)) return true;

      return false;
    }),
});

export const yupResolver = yupResolverInstance(schema);

export const useForm = useFormInstance;
