import React, { useEffect, useState, FocusEvent, useCallback } from 'react';
import { FormProvider } from 'react-hook-form';
import { FiAlertCircle } from 'react-icons/fi';
import { toast } from 'react-toastify';
import {
  DrawerContent,
  useMediaQuery,
  DrawerHeader,
  Text,
  DrawerBody,
  Flex,
  GridItem,
  Button,
  Box,
  Tag,
  Icon,
  useDisclosure,
} from '@chakra-ui/react';

import auth from 'modules/auth';
import PlanoContratacaoEnum from 'constants/enum/planoContratacao';
import api, { ResponseApi } from 'services/api';
import { useProdutosFormularioContext } from 'store/Produtos/ProdutosFormulario';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import CheckboxAtivoInativo from 'components/update/Checkbox/CheckboxAtivoInativo';
import { NumberInput } from 'components/update/Input/NumberInput';
import Input from 'components/PDV/InputPdv';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import DrawerPadraoChakra from 'components/PDV/Drawer/DrawerPadraoChakra';
import ImagePickerPadrao from 'components/ImagePicker/ImagePickerPadrao';

import { Container } from '../../Preco/Container';
import { useForm, defaultValues } from './validationForm';
import { ModalProdutoCodigoDuplicado } from '../../components/ModalProdutoCodigoDuplicado';
import useProdutoFormulario, { ProdutoCorTamanhoProps } from '../../../hook';

type ProdutoCorTamanho = {
  produtoCorTamanhoId: string;
  produto: string;
  cor: string;
  tamanho: string;
  imagem: string;
};

type Identificadores = {
  codigoGTINEAN: string;
  sku: string;
  sequenciaCodigoBarras: number;
  sequenciaCodigoBarrasHexaDecimal: string;
  codigoBarrasInterno: string;
  codigoExterno: string;
};

export type VariacoesResponseProps = {
  produtoCorTamanho: ProdutoCorTamanho;
  identificadores: Identificadores;
  estoque: number;
  ativo: boolean;
  padraoSistema: boolean;
  isChecked: boolean;
};

interface EditarVariacaoDrawer {
  show: boolean;
  onHide: () => void;
  variacao: VariacoesResponseProps;
  atualizarVariacoes: () => void;
}

let inputExternalCodeTimeout: NodeJS.Timeout;
let inputGtinEanTimeout: NodeJS.Timeout;

const EditarVariacaoDrawer = ({
  show,
  onHide,
  variacao,
  atualizarVariacoes,
}: EditarVariacaoDrawer) => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    setImagemFoiAlterada,
    idProduto,
    action,
    isDuplicado,
  } = useProdutosFormularioContext();

  const {
    getProductsWithSameGtinEan,
    getProductsWithSameExternalCode,
    updateProductGtinEan,
    updateProductExternalCode,
  } = useProdutoFormulario();

  const {
    isOpen: isOpenModalGtin,
    onClose: onCloseModalGtin,
    onOpen: onOpenModalGtin,
  } = useDisclosure();
  const {
    isOpen: isOpenModalExternalCode,
    onClose: onCloseModalExternalCode,
    onOpen: onOpenModalExternalCode,
  } = useDisclosure();

  const isActionAlterar = action === 'alterar' && !isDuplicado;

  const formMethods = useForm({
    defaultValues,
  });

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const { handleSubmit, reset, watch } = formMethods;

  const { codigoGTINEAN, codigoExterno, sku: skuWatch } = watch();

  const planoAtual = auth.getPlano();

  const planoStart = PlanoContratacaoEnum.START;

  const [productsWithSameGtinEan, setProductsWithSameGtinEan] = useState<
    ProdutoCorTamanhoProps[]
  >([]);
  const [
    productsWithSameExternalCode,
    setProductsWithSameExternalCode,
  ] = useState<ProdutoCorTamanhoProps[]>([]);

  const hasProductsWithSameEAN = productsWithSameGtinEan.length > 0;
  const hasProductsWithSameExternalCode =
    productsWithSameExternalCode.length > 0;

  const onClose = () => {
    onHide();
    setProductsWithSameGtinEan([]);
    setProductsWithSameExternalCode([]);
  };

  const handleValidateProductsWithExternalCode = useCallback(
    async (event: FocusEvent<HTMLInputElement>) => {
      clearTimeout(inputExternalCodeTimeout);

      inputExternalCodeTimeout = global.setTimeout(async () => {
        const { produtoCorTamanhoId } = variacao.produtoCorTamanho;
        const { value } = event.target;

        if (value) {
          const products = await getProductsWithSameExternalCode(value);
          const filteredProducts = products.filter(
            (product) => product.produtoId !== produtoCorTamanhoId
          );

          setProductsWithSameExternalCode(filteredProducts);
          return;
        }

        setProductsWithSameExternalCode([]);
      }, 500);
    },
    [getProductsWithSameExternalCode, variacao]
  );

  const handleValidateProductsWithSameGtinEan = useCallback(
    async (event: FocusEvent<HTMLInputElement>) => {
      clearTimeout(inputGtinEanTimeout);

      inputGtinEanTimeout = global.setTimeout(async () => {
        const { produtoCorTamanhoId } = variacao.produtoCorTamanho;
        const { value } = event.target;

        const validLengths = [8, 12, 13, 14];

        if (validLengths.includes(value.length)) {
          const products = await getProductsWithSameGtinEan(value);
          const filteredProducts = products.filter(
            (product) => product.produtoId !== produtoCorTamanhoId
          );

          setProductsWithSameGtinEan(filteredProducts);
          return;
        }

        setProductsWithSameGtinEan([]);
      }, 500);
    },
    [getProductsWithSameGtinEan, variacao]
  );

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);
    const response = await api.put<void, ResponseApi<boolean>>(
      `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/produtoCorTamanhos/${variacao.produtoCorTamanho.produtoCorTamanhoId}`,
      {
        produtoCorId: idProduto,
        id: variacao.produtoCorTamanho.produtoCorTamanhoId,
        caracteristicas: {
          altura: data.altura,
          largura: data.largura,
          profundidade: data.profundidade,
          pesoLiquido: data.pesoLiquido,
          pesoBruto: data.pesoBruto,
          pesoEmbalagem: data.pesoEmbalagem,
        },
        identificadores: {
          codigoGTINEAN: data.codigoGTINEAN,
          sku: data.sku,
          codigoBarrasInterno: data.codigoBarrasInterno,
          codigoExterno: data.codigoExterno,
        },
        estoqueMinimo: data.estoqueMinimo,
        estoqueAtual: data.estoqueAtual,
        ativo: data.ativo,
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
        setIsLoading(false);
      }

      if (response.sucesso) {
        toast.success('A variação foi alterada com sucesso');
        atualizarVariacoes();
        onClose();
        setIsLoading(false);
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  });

  useEffect(() => {
    if (show) {
      const getVariacoes = async () => {
        const response = await api.get<void, ResponseApi<any>>(
          `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/produtoCorTamanhos/${variacao.produtoCorTamanho.produtoCorTamanhoId}`
        );

        if (response) {
          if (response.avisos) {
            response.avisos.forEach((item: string) => toast.warning(item));
          }

          if (response.sucesso) {
            reset({
              ...response.dados.caracteristicas,
              ...response.dados.identificadores,
              ...response.dados,
              ativo: response.dados.ativo,
              imagem: response.dados.produtoCorTamanho.imagem,
            });
          }
        }
      };
      getVariacoes();
    }
  }, [idProduto, reset, show, variacao]);

  return (
    <DrawerPadraoChakra
      size={isLargerThan900 ? '2xl' : 'full'}
      onClose={onClose}
      isOpen={show}
    >
      <DrawerContent
        style={{
          width: isLargerThan900 ? '900px' : '',
        }}
        bg="gray.50"
        pr="5px"
        overflow="hidden"
        boxShadow="-6px 0px 20px #00000029"
      >
        <FormProvider {...formMethods}>
          <DrawerHeader display="flex" justifyContent="flex-start">
            <Box w="120px">
              <ImagePickerPadrao
                id="imagem"
                name="imagem"
                w={['130px']}
                isAlterarImagem={false}
                h={['130px']}
                cursor="default"
                setImagemFoiAlterada={setImagemFoiAlterada}
                error=""
              />
            </Box>
            <Flex ml="40px" alignItems="center" justifyContent="center">
              <Box>
                <Flex>
                  {variacao.produtoCorTamanho.cor && (
                    <Text display="inline" color="teal.600" fontSize="20px">
                      {variacao.produtoCorTamanho.cor}
                      {variacao.produtoCorTamanho.cor &&
                        variacao.produtoCorTamanho.tamanho && (
                          <Text
                            display="inline"
                            mx="5px"
                            color="gray.700"
                            fontSize="20px"
                          >
                            |
                          </Text>
                        )}
                    </Text>
                  )}
                  {variacao.produtoCorTamanho.tamanho && (
                    <Text color="pink.700" fontSize="20px">
                      {variacao.produtoCorTamanho.tamanho}
                    </Text>
                  )}
                </Flex>
                <Text color="gray.500" fontSize="14px">
                  Código SKU: {skuWatch}
                </Text>
              </Box>
            </Flex>
          </DrawerHeader>
          <DrawerBody
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            position="relative"
          >
            <SimpleGridForm gap={{ base: 6, md: 8 }}>
              <GridItem
                colSpan={[12, 12, 4]}
                mb={hasProductsWithSameEAN ? { base: 3, md: 1 } : 0}
              >
                <Input
                  type="number"
                  name="codigoGTINEAN"
                  label="GTIN/EAN"
                  onChange={(event: FocusEvent<HTMLInputElement>) =>
                    handleValidateProductsWithSameGtinEan(event)
                  }
                  borderColor={
                    hasProductsWithSameEAN ? 'yellow.600' : 'inherit'
                  }
                  placeholder="Código de barras"
                  maxLength={14}
                />
                {hasProductsWithSameEAN && (
                  <Tag
                    position="absolute"
                    h="32px"
                    px="8px"
                    bg="yellow.500"
                    borderRadius="4px"
                  >
                    <Flex gap={1}>
                      <Icon as={FiAlertCircle} />
                      <Text fontSize="14px" whiteSpace="nowrap">
                        Código já cadastrado.{' '}
                        <Box
                          as="u"
                          cursor="pointer"
                          onClick={() => onOpenModalGtin()}
                        >
                          Ver produtos.
                        </Box>
                      </Text>
                    </Flex>
                  </Tag>
                )}
              </GridItem>
              <GridItem
                colSpan={[12, 12, 4]}
                mb={hasProductsWithSameExternalCode ? { base: 3, md: 1 } : 0}
              >
                <Input
                  id="codigoExterno"
                  autoFocus
                  name="codigoExterno"
                  helperText="É um campo genérico do sistema. Pode ser utilizado para registrar códigos que não foram gerados no sistema. (Ex: código de fornecedores, códigos de outro sistema, referência exclusiva para uma variação, etc..)"
                  label="Código externo"
                  placeholder="Digite o código externo"
                  maxLength={60}
                  className="codigoExterno"
                  borderColor={
                    hasProductsWithSameExternalCode ? 'yellow.600' : 'inherit'
                  }
                  onChange={(event: FocusEvent<HTMLInputElement>) =>
                    handleValidateProductsWithExternalCode(event)
                  }
                />
                {hasProductsWithSameExternalCode && (
                  <Tag
                    position="absolute"
                    h="32px"
                    px="8px"
                    bg="yellow.500"
                    borderRadius="4px"
                  >
                    <Flex gap={1}>
                      <Icon as={FiAlertCircle} />
                      <Text fontSize="14px" whiteSpace="nowrap">
                        Código já cadastrado.{' '}
                        <Box
                          as="u"
                          cursor="pointer"
                          onClick={() => onOpenModalExternalCode()}
                        >
                          Ver produtos.
                        </Box>
                      </Text>
                    </Flex>
                  </Tag>
                )}
              </GridItem>
              <GridItem colSpan={[12, 12, 4]}>
                <Input
                  id="codigoBarrasInterno"
                  autoFocus
                  name="codigoBarrasInterno"
                  isDisabled
                  label="Código da etiqueta"
                  placeholder="Digite o código da etiqueta"
                  maxLength={60}
                  className="codigoEtiqueta"
                />
              </GridItem>
              <GridItem colSpan={[12, 12, 4]}>
                <NumberInput
                  id="estoqueAtual"
                  name="estoqueAtual"
                  label={
                    isActionAlterar ? 'Saldo de estoque' : 'Estoque inicial'
                  }
                  precision={isActionAlterar ? 14 : 0}
                  scale={isActionAlterar ? 3 : 0}
                  isDisabled={planoAtual === planoStart || isActionAlterar}
                  defaultValue={undefined}
                />
              </GridItem>

              <GridItem colSpan={[12, 12, 4]}>
                <NumberInput
                  id="estoqueMinimo"
                  name="estoqueMinimo"
                  label="Estoque mínimo"
                  precision={14}
                  scale={4}
                  defaultValue={0}
                />
              </GridItem>
              <GridItem colSpan={[12, 12, 4]}>
                <CheckboxAtivoInativo
                  id="ativo"
                  name="ativo"
                  label="Status"
                  isRequired={false}
                />
              </GridItem>
              <GridItem colSpan={[12, 12, 4]}>
                <Container bg="gray.100" title="Informações para nota fiscal:">
                  <Box mb="30px" w="full">
                    <NumberInput
                      id="pesoLiquido"
                      name="pesoLiquido"
                      label="Peso líquido"
                      leftElement="KG"
                      editarFundoLeftElemento
                      bgLeftElement="gray.50"
                      leftElementColor="black"
                      leftElementFontSize="xs"
                      precision={12}
                      scale={3}
                      defaultValue={0}
                    />
                  </Box>
                  <Box w="full">
                    <NumberInput
                      id="pesoBruto"
                      name="pesoBruto"
                      editarFundoLeftElemento
                      label="Peso bruto"
                      leftElement="KG"
                      bgLeftElement="gray.50"
                      leftElementColor="black"
                      leftElementFontSize="xs"
                      precision={12}
                      scale={3}
                      defaultValue={0}
                    />
                  </Box>
                </Container>
              </GridItem>

              <GridItem colSpan={[12, 12, 8]}>
                <Container
                  bg="gray.100"
                  title="Características da embalagem para cálculo de frete:"
                >
                  <SimpleGridForm>
                    <GridItem colSpan={[12, 12, 6]}>
                      <NumberInput
                        id="largura"
                        name="largura"
                        label="Largura"
                        editarFundoLeftElemento
                        bgLeftElement="gray.50"
                        leftElementColor="black"
                        leftElement="CM"
                        leftElementFontSize="xs"
                        precision={7}
                        scale={2}
                        defaultValue={0}
                      />
                    </GridItem>
                    <GridItem colSpan={[12, 12, 6]}>
                      <NumberInput
                        id="altura"
                        name="altura"
                        label="Altura"
                        editarFundoLeftElemento
                        bgLeftElement="gray.50"
                        leftElementColor="black"
                        leftElement="CM"
                        leftElementFontSize="xs"
                        precision={7}
                        scale={2}
                        defaultValue={0}
                      />
                    </GridItem>
                    <GridItem colSpan={[12, 12, 6]}>
                      <NumberInput
                        id="profundidade"
                        name="profundidade"
                        label="Profundidade"
                        editarFundoLeftElemento
                        bgLeftElement="gray.50"
                        leftElementColor="black"
                        leftElement="CM"
                        leftElementFontSize="xs"
                        precision={7}
                        scale={2}
                        defaultValue={0}
                      />
                    </GridItem>
                    <GridItem colSpan={{ base: 12, md: 12, lg: 6 }}>
                      <NumberInput
                        id="pesoEmbalagem"
                        name="pesoEmbalagem"
                        label="Peso da embalagem"
                        precision={12}
                        scale={3}
                        leftElement="KG"
                        leftElementFontSize="xs"
                        editarFundoLeftElemento
                        leftElementColor="black"
                        bgLeftElement="gray.50"
                      />
                    </GridItem>
                  </SimpleGridForm>
                </Container>
              </GridItem>
            </SimpleGridForm>
            <Flex
              mt="25px"
              mb="10px"
              w="full"
              justifyContent="center"
              direction={['column', 'column', 'row']}
            >
              <Button
                type="button"
                variant="outlineDefault"
                colorScheme="gray.300"
                borderRadius="16px"
                w={['full', 'full', '96px']}
                onClick={onClose}
                mr={['', '', '15px']}
                mb={['15px', '15px', '']}
                isLoading={isLoading}
              >
                Cancelar
              </Button>

              <Button
                variant="solid"
                borderRadius="16px"
                w={['full', 'full', '96px']}
                isLoading={isLoading}
                colorScheme="aquamarine.600"
                onClick={() => onSubmit()}
              >
                Salvar
              </Button>
            </Flex>
          </DrawerBody>
        </FormProvider>
        <ModalProdutoCodigoDuplicado
          isOpen={isOpenModalGtin}
          onClose={onCloseModalGtin}
          code={{
            type: 'GTIN/EAN',
            value: codigoGTINEAN || '',
          }}
          isLoading={isLoading}
          products={productsWithSameGtinEan}
          callback={async (products) => {
            setIsLoading(true);

            const listPromises = products.map((product) =>
              updateProductGtinEan(
                product.produtoCorTamanhoId,
                product?.codigo || ''
              )
            );

            const response = await Promise.all(listPromises).then((result) => {
              result.forEach(({ success, data }) => {
                if (success) {
                  setProductsWithSameGtinEan((prev) =>
                    prev.filter(
                      (prevProduct) =>
                        prevProduct.produtoCorTamanhoId !==
                        data.produtoCorTamanhoId
                    )
                  );

                  toast.success('GTIN/EAN alterado com sucesso.');
                }
              });

              return { success: result.every(({ success }) => success) };
            });

            setIsLoading(false);
            if (response.success) {
              onCloseModalExternalCode();
            }
          }}
        />
        <ModalProdutoCodigoDuplicado
          isOpen={isOpenModalExternalCode}
          onClose={onCloseModalExternalCode}
          code={{
            type: 'Código Externo',
            value: codigoExterno || '',
          }}
          isLoading={isLoading}
          products={productsWithSameExternalCode}
          callback={async (products) => {
            setIsLoading(true);

            const listPromises = products.map((product) =>
              updateProductExternalCode(
                product.produtoCorTamanhoId,
                product?.codigo || ''
              )
            );

            const response = await Promise.all(listPromises).then((result) => {
              result.forEach(({ success, data }) => {
                if (success) {
                  setProductsWithSameExternalCode((prev) =>
                    prev.filter(
                      (prevProduct) =>
                        prevProduct.produtoCorTamanhoId !==
                        data.produtoCorTamanhoId
                    )
                  );

                  toast.success('Código externo alterado com sucesso.');
                }
              });

              return { success: result.every(({ success }) => success) };
            });

            setIsLoading(false);

            if (response.success) {
              onCloseModalExternalCode();
            }
          }}
        />
      </DrawerContent>
    </DrawerPadraoChakra>
  );
};

export default EditarVariacaoDrawer;
