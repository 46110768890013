import React, { createContext, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { MeioPagamento } from 'constants/enum/fiscal/meioPagamento';

interface FormaPagamento {
  id: string;
  nome: string;
  regraMeioPagamento: MeioPagamento;
}

interface FormasPagamentoContextProps {
  formasPagamento: FormaPagamento[];
  isLoading: boolean;
}

export const FormasPagamentoContext = createContext<FormasPagamentoContextProps>(
  {} as FormasPagamentoContextProps
);

interface FormasPagamentoProviderProps {
  children: React.ReactNode;
}

export default function FormasPagamentoProvider({
  children,
}: FormasPagamentoProviderProps): JSX.Element {
  const [formasPagamento, setFormasPagamento] = useState<FormaPagamento[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const handleGetFormasPagamento = async () => {
    setIsLoading(true);

    const response = await api.get<void, ResponseApi<FormaPagamento[]>>(
      ConstanteEnderecoWebservice.FORMA_PAGAMENTO_LISTAR_SELECT_PDV
    );

    if (response) {
      if (response.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }

      if (response.sucesso && response.dados) {
        setFormasPagamento(response.dados);
        setIsLoading(false);

        return;
      }
    }

    setIsLoading(false);
    setFormasPagamento([]);
  };

  useEffect(() => {
    handleGetFormasPagamento();
  }, []);

  return (
    <FormasPagamentoContext.Provider
      value={{
        formasPagamento,
        isLoading,
      }}
    >
      {children}
    </FormasPagamentoContext.Provider>
  );
}

export function useFormasPagamentoContext(): FormasPagamentoContextProps {
  const context = useContext(FormasPagamentoContext);

  if (!context)
    throw new Error(
      'useFormasPagamentoContext must be used within a FormasPagamentoProvider.'
    );

  return context;
}
