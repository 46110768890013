import { useState, useCallback, SyntheticEvent } from 'react';
import { Control, Controller } from 'react-hook-form';
import {
  Box,
  useMediaQuery,
  Table,
  Tbody,
  Tfoot,
  Flex,
} from '@chakra-ui/react';

import Loading from 'components/Layout/Loading/LoadingPadrao';

import TableHeader, { ColumnHeaderType } from './TableHeader';

type TableOrderType = {
  orderColumn: string;
  orderDirection: 'asc' | 'desc';
  orderColumnCompareFn?: (a: any, b: any, order: 'asc' | 'desc') => number;
};

interface ChildWithFuncProps {
  tableValues: any[];
}

type ChildWithFuncType = (props: ChildWithFuncProps) => JSX.Element | string;

interface TableCampoFormularioPadraoProps {
  fieldName: string;
  control: Control<Record<string, any>>;
  isLoading: boolean;
  defaultColumnOrder: string;
  headerColumns: ColumnHeaderType[];
  emptyValuesText?: string;
  tableFootContent?: (props: ChildWithFuncProps) => JSX.Element | string;
  children: ChildWithFuncType;
  borderWidth?: string;
  bgHeader?: string;
}

export const TableCampoFormularioPadrao = ({
  fieldName,
  control,
  isLoading,
  defaultColumnOrder,
  headerColumns,
  emptyValuesText,
  tableFootContent,
  children,
  borderWidth = '1px',
  bgHeader,
}: TableCampoFormularioPadraoProps) => {
  const [tableOrder, setTableOrder] = useState<TableOrderType>({
    orderColumn: defaultColumnOrder,
    orderDirection: 'asc',
  });
  const [isSmallerThan1120] = useMediaQuery('(max-width: 1120px)');
  const setOrder = useCallback(
    (
      event: SyntheticEvent,
      newOrderColumn: string,
      newOrderColumnCompareFn?: (
        a: any,
        b: any,
        order: 'asc' | 'desc'
      ) => number
    ) => {
      event.preventDefault();

      let newOrderDirection: 'asc' | 'desc' = 'asc';

      if (newOrderColumn === tableOrder.orderColumn) {
        if (tableOrder.orderDirection === 'asc') newOrderDirection = 'desc';
      }

      setTableOrder({
        orderColumn: newOrderColumn,
        orderDirection: newOrderDirection,
        orderColumnCompareFn: newOrderColumnCompareFn,
      });
    },
    [tableOrder.orderColumn, tableOrder.orderDirection]
  );

  return (
    <Box
      borderRadius="5px"
      p="2px"
      borderWidth={borderWidth}
      borderStyle="solid"
      borderColor="gray.100"
    >
      <Box>
        {isLoading && <Loading />}
        <Controller
          defaultValue={[]}
          render={({ field: { value } }) => {
            const tableValues = !value
              ? []
              : value.sort((a: any, b: any) => {
                  if (tableOrder.orderColumnCompareFn)
                    return tableOrder.orderColumnCompareFn(
                      a,
                      b,
                      tableOrder.orderDirection
                    );

                  if (tableOrder.orderDirection === 'asc') {
                    if (a[tableOrder.orderColumn] < b[tableOrder.orderColumn])
                      return -1;

                    if (a[tableOrder.orderColumn] > b[tableOrder.orderColumn])
                      return 1;

                    return 0;
                  }

                  // desc
                  if (b[tableOrder.orderColumn] < a[tableOrder.orderColumn])
                    return -1;

                  if (b[tableOrder.orderColumn] > a[tableOrder.orderColumn])
                    return 1;

                  return 0;
                });

            return (
              <Box
                w="100%"
                overflowY={isSmallerThan1120 ? 'scroll' : 'visible'}
              >
                <Table variant="none" p="10px">
                  <TableHeader
                    background={bgHeader}
                    columns={headerColumns}
                    orderColumn={tableOrder.orderColumn}
                    orderDirection={tableOrder.orderDirection}
                    setOrder={setOrder}
                  />
                  {value && value.length > 0 && (
                    <>
                      <Tbody>
                        {(children as ChildWithFuncType)({ tableValues })}
                      </Tbody>
                      {tableFootContent && (
                        <Tfoot>{tableFootContent({ tableValues })}</Tfoot>
                      )}
                    </>
                  )}
                </Table>

                {!value ||
                  (value.length <= 0 && (
                    <Flex
                      h={['35px', '35px', '40px', '42px']}
                      alignItems="center"
                      pr="30px"
                      pl="30px"
                    >
                      {emptyValuesText}
                    </Flex>
                  ))}
              </Box>
            );
          }}
          name={`${fieldName}` as const}
          control={control}
        />
      </Box>
    </Box>
  );
};
