import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import StatusOperacaoEnum from 'constants/enum/statusOperacao';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import { setDateMinHours, setDateMaxHours } from 'helpers/data/setHoursDate';
import { IdentificacaoIntegracao } from 'constants/enum/IdentificacaoIntegracao';

export type SelectClienteProps = {
  id: string;
  nome: string;
  endereco: string;
  codigo: number;
  cpfCnpj: string;
};

export type OptionsVendedores = {
  label: string;
  value: string | number;
};

export type OptionResponseVendedores = {
  id: string;
  nome: string;
};

export type FormData = {
  dataEmissaoFim: Date;
  dataEmissaoInicio: Date;
  tipoRelatorio: number | null;
  clienteFornecedorId: OptionsVendedores | null;
  vendedorId: string | null;
  statusConsulta?: null | number;
};

export const formDefaultValues = {
  dataEmissaoFim: setDateMaxHours(new Date()),
  dataEmissaoInicio: setDateMinHours(new Date()),
  origem: IdentificacaoIntegracao.TODAS,
  clienteFornecedorId: null,
  vendedorId: null,
  tipoRelatorio: null,
  statusConsulta: StatusOperacaoEnum.EFETUADA,
};

const schema = yup.object().shape({
  tipoRelatorio: yup
    .number()
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);
