import React, { useMemo } from 'react';

import { useProdutoCategoriasProdutoContext } from 'store/Produtos/ProdutosCategoriasProduto';

import Item from '../Item';

interface ItemsContainerProps {
  categoriaProdutoPaiId?: string;
  nivel: number;
}

const ItemsContainer = ({
  categoriaProdutoPaiId,
  nivel,
}: ItemsContainerProps) => {
  const {
    handleGetCategoriasPeloPaiId,
    categoriasIdSelecionaveis,
  } = useProdutoCategoriasProdutoContext();

  const categorias = useMemo(() => {
    return handleGetCategoriasPeloPaiId(categoriaProdutoPaiId);
  }, [handleGetCategoriasPeloPaiId, categoriaProdutoPaiId]);

  return (
    <>
      {categorias.map((categoria) => (
        <Item
          key={categoria.id}
          nivel={nivel}
          item={categoria}
          selecionavel={categoriasIdSelecionaveis.some(
            (categoriaIdSelecionavel) =>
              categoriaIdSelecionavel === categoria.id
          )}
        >
          <ItemsContainer
            categoriaProdutoPaiId={categoria.id}
            nivel={nivel + 1}
          />
        </Item>
      ))}
    </>
  );
};

export default ItemsContainer;
