import { createContext, ReactNode, useContext, useState } from 'react';

type EmissaoNotaFiscalControleProps = {
  telaExibicao: number;
  setTelaExibicao: React.Dispatch<React.SetStateAction<number>>;
  animatePrev: boolean;
  setAnimatePrev: React.Dispatch<React.SetStateAction<boolean>>;
  animateNext: boolean;
  setAnimateNext: React.Dispatch<React.SetStateAction<boolean>>;
  handlePrevTela: (value: number) => void;
  handleNextTela: (value: number) => void;
  transacaoId: string;
  setTransacaoId: React.Dispatch<React.SetStateAction<string>>;
  reloadList: () => void;
};

type EmissaoNotaFiscalProviderProps = {
  children: ReactNode;
  reloadList: () => void;
};

export const EmissaoNotaFiscalControleContext = createContext(
  {} as EmissaoNotaFiscalControleProps
);

export const EmissaoNotaFiscalProvider = ({
  children,
  reloadList,
}: EmissaoNotaFiscalProviderProps) => {
  const [transacaoId, setTransacaoId] = useState('');
  const [telaExibicao, setTelaExibicao] = useState(0);
  const [animatePrev, setAnimatePrev] = useState(false);
  const [animateNext, setAnimateNext] = useState(false);

  const handleNextTela = (value: number) => {
    setAnimatePrev(true);

    setTimeout(() => {
      setTelaExibicao(value);
    }, 500);

    setTimeout(() => {
      setAnimatePrev(false);
    }, 1000);
  };

  const handlePrevTela = (value: number) => {
    setAnimateNext(true);

    setTimeout(() => {
      setTelaExibicao(value);
    }, 500);

    setTimeout(() => {
      setAnimateNext(false);
    }, 1000);
  };

  return (
    <EmissaoNotaFiscalControleContext.Provider
      value={{
        telaExibicao,
        setTelaExibicao,
        animatePrev,
        setAnimatePrev,
        handlePrevTela,
        animateNext,
        setAnimateNext,
        handleNextTela,
        transacaoId,
        setTransacaoId,
        reloadList,
      }}
    >
      {children}
    </EmissaoNotaFiscalControleContext.Provider>
  );
};

export function useEmissaoNotaFiscalContext(): EmissaoNotaFiscalControleProps {
  const context = useContext(EmissaoNotaFiscalControleContext);

  if (!context)
    throw new Error(
      'useEmissaoNotaFiscalContext must be used within a EmissaoNotaFiscalProvider.'
    );

  return context;
}
