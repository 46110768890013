export type OrigemMercadoria = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

const OrigemMercadoriaEnum = {
  NACIONAL_EXCETO_CODIGOS_3_4_5_8: 0,
  ESTRANGEIRA_IMPORTACAO_DIRETA: 1,
  ESTRANGEIRA_MERCADO_INTERNO: 2,
  NACIONAL_IMPORTACAO_SUPERIOR_40_PORCENTO: 3,
  NACIONAL_PRODUCAO_CONFORME_PROCESSOS: 4,
  NACIONAL_IMPORTACAO_INFERIOR_40_PORCENTO: 5,
  ESTRANGEIRA_IMPORTACAO_DIRETA_SEM_SIMILAR_NACIONAL: 6,
  ESTRANGEIRA_MERCADO_INTERNO_SEM_SIMILAR_NACIONAL: 7,
  NACIONAL_IMPORTACAO_SUPERIOR_70_PORCENTO: 8,

  properties: {
    0: {
      name: '0 - Nacional, exceto as indicadas nos códigos 3, 4 e 5',
      value: 0,
    },
    1: {
      name:
        '1 - Estrangeira - Importação direta, exceto a indicada no código 6',
      value: 1,
    },
    2: {
      name:
        '2 - Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7',
      value: 2,
    },
    3: {
      name:
        '3 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40% e inferior ou igual a 70%',
      value: 3,
    },
    4: {
      name:
        '4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam as legislações citadas nos Ajustes',
      value: 4,
    },
    5: {
      name:
        '5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%',
      value: 5,
    },
    6: {
      name:
        '6 - Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX e gás natural',
      value: 6,
    },
    7: {
      name:
        '7 - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante lista CAMEX e gás natural',
      value: 7,
    },
    8: {
      name:
        '8 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%',
      value: 8,
    },
  },
};

export default OrigemMercadoriaEnum;
