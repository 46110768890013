import { useEffect, useState } from 'react';
import { Button, Flex, Box, useMediaQuery, Icon } from '@chakra-ui/react';
import { AiOutlineMenu } from 'react-icons/ai';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, Prompt } from 'react-router-dom';
import { toast } from 'react-toastify';

import { EnumSmartPos } from 'constants/enum/enumSmartPos';
import {
  useSmartPosContext,
  EtapaSelecionadaProps,
} from 'store/SmartPos/SmartPosContext';
import auth from 'modules/auth';
import { IdentificacaoEtapasSmartPos } from 'constants/enum/IdentificacaoEtapasSmartPos';
import ConstanteRotas, { ConstanteRotasAlternativas } from 'constants/rotas';
import enumReferenciaServicoStargate from 'constants/enum/referenciaServicoStargate';

import { ButtonCadastrarNovo } from 'components/Layout/ButtonCadastrarNovo';
import { ModalDesistencia } from 'components/Modal/ModalDesistencia';

import { obterEtapaSmartPos } from 'services/smartPos';
import isPrenvent from 'helpers/layout/isPrenvent';
import { StepsSmartPos } from './StepsSmartPos';
import { Footer } from './Components/Layout/Footer';
import { MenuMobile } from './Components/MenuMobile';

type FormData = { nome: string; ativo: boolean | null };

export const ListarSmartPOS = () => {
  const [possuiConfigSmartPos, setPossuiConfigSmartPos] = useState(false);
  const {
    activeStep,
    setActiveStep,
    isStepAlterada,
    setIsStepAlterada,
  } = useSmartPosContext();

  const history = useHistory();

  const [isLargerThan1270] = useMediaQuery('(min-width: 1270px)');
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const formMethods = useForm<FormData>({
    defaultValues: { nome: '', ativo: true },
  });

  const { setValue } = formMethods;

  const possuiPermissaoSmartPos = auth.possuiServico(
    enumReferenciaServicoStargate.DISPOSITIVO_SMART_POS
  ).permitido;

  const stepSelecionada = (step: EtapaSelecionadaProps) => {
    setActiveStep(step);
    setIsStepAlterada(false);
  };

  const handleAlterarStep = (step: EtapaSelecionadaProps) => {
    if (isStepAlterada) {
      ModalDesistencia({
        callback: () => stepSelecionada(step),
      });
    } else {
      stepSelecionada(step);
    }
  };

  useEffect(() => {
    if (!possuiPermissaoSmartPos) {
      toast.warning('Você não tem permissão para acessar essa função');
      history.push(ConstanteRotas.DASHBOARD);
    } else {
      const possuiCadastroSmartPos = async () => {
        const response = await obterEtapaSmartPos();

        if (response !== IdentificacaoEtapasSmartPos.CODIGO_ATIVACAO) {
          history.push(ConstanteRotasAlternativas.SMART_POS_ETAPA);

          setPossuiConfigSmartPos(false);
        } else {
          setPossuiConfigSmartPos(true);
        }
      };
      possuiCadastroSmartPos();
    }
  }, [history, possuiPermissaoSmartPos]);

  const formIsDirty = possuiPermissaoSmartPos && possuiConfigSmartPos;

  isPrenvent(formIsDirty);

  return (
    <Box>
      <Prompt when={formIsDirty} message="" />
      <FormProvider {...formMethods}>
        {isLargerThan900 ? (
          <Flex mb="31px">
            {EnumSmartPos.itensSmartPos.map((itemSmartPos) => {
              return (
                <Box mr={['', '', '2%', '5%']}>
                  <Button
                    h="32px"
                    fontWeight={
                      activeStep.value === itemSmartPos.value
                        ? 'bold'
                        : 'normal'
                    }
                    letterSpacing={
                      activeStep.value !== itemSmartPos.value ? '0.290px' : '0'
                    }
                    borderRadius="16px"
                    variant="linkDefault"
                    onClick={() => handleAlterarStep(itemSmartPos)}
                    colorScheme="purple"
                  >
                    {itemSmartPos.name}
                  </Button>
                </Box>
              );
            })}

            <Flex
              w={{ base: '30%', sm: '40%' }}
              display={{ base: '', sm: '', md: 'flex' }}
              justifyContent={{ base: 'flex-start', sm: 'flex-end' }}
            >
              <ButtonCadastrarNovo
                borderRadius="16px"
                colorScheme="secondary.400"
                h="32px"
                w={isLargerThan1270 ? '211px' : '140px'}
                label={
                  isLargerThan1270 ? 'Adicionar nova licença' : 'Adicionar'
                }
                onClick={() => {}}
                isDisabled
                funcionalidade=""
              />
            </Flex>
          </Flex>
        ) : (
          <Box mb="31px">
            <Icon
              boxSize="30px"
              cursor="pointer"
              onClick={() => {
                MenuMobile({
                  activeStep,
                  setActiveStep,
                  setValue,
                });
              }}
              as={AiOutlineMenu}
            />
          </Box>
        )}
        {StepsSmartPos({ activeStep })}
        {activeStep.value !== EnumSmartPos.DISPOSITIVO.value && (
          <Footer mt="40px" />
        )}
      </FormProvider>
    </Box>
  );
};
