import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

const schema = yup.object().shape({
  emails: yup
    .array()
    .of(yup.string().email(ConstanteMensagemValidacao.EMAIL_INVALIDO))
    .nullable()
    .default([]),
});

export const yupResolver = yupResolverInstance(schema);

export const useForm = useFormInstance;

export function emailIsValid(email: string) {
  const emailSchema = yup.string().email();
  const result = emailSchema.isValidSync(email);

  return result;
}
