import { useEffect } from 'react';
import {
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  ModalFooter,
  ModalHeader,
  useMediaQuery,
  Divider,
  GridItem,
} from '@chakra-ui/react';
import { create } from 'react-modal-promise';
import { FormProvider } from 'react-hook-form';

import TipoValorEnum from 'constants/enum/tipoValor';
import { moneyMask } from 'helpers/format/fieldsMasks';

import Select from 'components/PDV/Select/SelectPadrao';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { ButtonDefault } from 'components/Button/ButtonChakra';
import { NumberInput } from 'components/update/Input/NumberInput';
import { DateInput } from 'components/update/Input/DateInput';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { Switch } from 'components/update/Switch';
import AsyncCreatableSelect from 'components/PDV/Select/AsyncCreatableSelectPadrao';

import { useAnteciparModal } from './hooks';
import { FormData, ModalAnteciparContasProps } from './types';

export const ModalAnteciparContas = create<ModalAnteciparContasProps, FormData>(
  ({
    onResolve,
    onReject,
    totalSelecionado,
    valorOriginal,
    totalEmTaxas,
    ...rest
  }) => {
    const {
      formMethods,
      isLoading,
      tipoTaxa,
      possuiPermissaoCadastrarFornecedor,
      listFormaPagamento,
      formaPagamentoSelecionada,
      optionsContaFinanceira,
      defaultDate,
      alterarTaxaWatch,
      valorEmTaxa,
      getFornecedores,
      createFornecedor,
      handleResetValueContaFinanceira,
      resetToDefaultValues,
      handleOnBlurValorLiquido,
      handleAlterarTipoTaxa,
      handleOnBlurTaxaAntecipacao,
      handleSubmit,
    } = useAnteciparModal({
      onResolve,
      valorOriginal,
    });

    const { isOpen } = useDisclosure({ defaultIsOpen: true });
    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    const {
      formState: { errors },
    } = formMethods;

    const textContaOuContas = totalSelecionado > 1 ? 'contas' : 'conta';
    const taxaMaxima = tipoTaxa === TipoValorEnum.REAIS ? valorOriginal : 100;

    useEffect(() => {
      if (!isOpen) return;
      resetToDefaultValues();
    }, [isOpen, resetToDefaultValues]);

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size="3xl"
        {...rest}
        isOpen={isOpen}
        onClose={() => {
          onReject();
        }}
        autoFocus={false}
      >
        <ModalContent
          bg="gray.50"
          borderRadius={isLargerThan900 ? 'md' : '0'}
          marginBottom={isLargerThan900 ? '3.75rem' : '0'}
          marginTop={isLargerThan900 ? '3.75rem' : '0'}
          h={isLargerThan900 ? 'fit-content' : 'full'}
        >
          <ModalHeader pt="16px" pb="28px" pl="24px">
            <Text color="primary.50" fontSize="16px">
              Antecipar {textContaOuContas}
            </Text>

            <Divider mt="16px" />
          </ModalHeader>

          <FormProvider {...formMethods}>
            {isLoading && <LoadingPadrao />}
            <ModalBody pl="24px" pr="24px" pt="0" pb="0" height="full">
              <SimpleGridForm>
                <AsyncCreatableSelect
                  id="clienteFornecedorId"
                  name="clienteFornecedorId"
                  label="Fornecedor"
                  placeholder="Informe o nome para selecionar"
                  required
                  errorText={errors.clienteFornecedorId?.message ?? ''}
                  errorPropName="clienteFornecedorId"
                  handleGetOptions={getFornecedores}
                  creatableInputTextPreffix="Cadastrar o fornecedor"
                  handleCreateOption={createFornecedor}
                  creatableButtonShow={possuiPermissaoCadastrarFornecedor}
                  shouldAppearTheAddress
                  colSpan={{ base: 12, lg: 12 }}
                />
                <Select
                  id="formaPagamentoId"
                  name="formaPagamentoId"
                  label="Forma de pagamento"
                  placeholder="Selecione a forma de pagamento"
                  required
                  errorPropName="formaPagamentoId"
                  errorText={errors.formaPagamentoId?.value?.message ?? ''}
                  onChangeEffect={handleResetValueContaFinanceira}
                  options={listFormaPagamento}
                  asControlledByObject
                  colSpan={{ base: 12, sm: 12, md: 6 }}
                />
                <Select
                  id="contaFinanceiraId"
                  name="contaFinanceiraId"
                  required
                  isDisabled={formaPagamentoSelecionada === undefined}
                  label="Conta financeira"
                  placeholder="Selecione uma conta financeira"
                  options={optionsContaFinanceira}
                  colSpan={{ base: 12, lg: 6 }}
                />
                <DateInput
                  name="dataAntecipacao"
                  id="dataAntecipacao"
                  size="md"
                  defaultValue={defaultDate}
                  height="36px"
                  isRequired
                  label="Data de antecipação"
                  helperText="A data informada será aplicada para cada conta selecionada"
                  colSpan={{ base: 12, lg: 12 }}
                />
                <GridItem colSpan={1}>
                  <Switch
                    size="md"
                    label="Taxa"
                    id="alterarTaxa"
                    mt="8px"
                    name="alterarTaxa"
                    fontWeight="bold"
                  />
                </GridItem>
                <GridItem colSpan={5}>
                  <Flex w="full" justifyContent="flex-end" mb="9px">
                    <Button
                      variant="link"
                      size="xs"
                      lineHeight="8px"
                      boxShadow="none !important"
                      colorScheme="blue"
                      textDecoration="none"
                      _hover={{ textDecoration: 'none' }}
                      justifySelf="flex-end"
                      onClick={handleAlterarTipoTaxa}
                    >
                      {`Alterar usando ${
                        tipoTaxa === TipoValorEnum.PORCENTAGEM
                          ? 'valor'
                          : 'porcentagem'
                      }`}{' '}
                    </Button>
                  </Flex>

                  <NumberInput
                    id="taxaAntecipacao"
                    leftElement={tipoTaxa === TipoValorEnum.REAIS ? 'R$' : '%'}
                    editarFundoLeftElemento
                    isDisabled={!alterarTaxaWatch}
                    onBlur={handleOnBlurTaxaAntecipacao}
                    bgLeftElement="gray.50"
                    leftElementFontSize="xs"
                    scale={2}
                    max={taxaMaxima}
                    name="taxaAntecipacao"
                  />
                  {tipoTaxa === TipoValorEnum.PORCENTAGEM && (
                    <Flex w="full" justifyContent="flex-end">
                      <Text fontSize="xs" color="gray.500" mt="4px">
                        Valor da taxa: {moneyMask(valorEmTaxa || 0, true)}
                      </Text>
                    </Flex>
                  )}
                </GridItem>
                <NumberInput
                  isRequired={false}
                  label="Valor original"
                  name="valorOriginal"
                  leftElement="R$"
                  leftElementFontSize="xs"
                  height="36px"
                  isDisabled
                  isReadOnly
                  colSpan={{ base: 12, lg: 3 }}
                />
                <NumberInput
                  label="Valor líquido"
                  bg="secondary.300"
                  name="totalLiquido"
                  border="gray.100"
                  leftElement="R$"
                  leftElementFontSize="xs"
                  height="36px"
                  onBlur={handleOnBlurValorLiquido}
                  max={Number(valorOriginal.toFixed(2))}
                  isRequired={false}
                  scale={2}
                  colSpan={{ base: 12, lg: 3 }}
                />
              </SimpleGridForm>
            </ModalBody>
            <ModalFooter
              flexDirection="column"
              pb="20px"
              mt={tipoTaxa === TipoValorEnum.REAIS ? '18px' : '0px'}
            >
              <Divider mt="16px" mb="16px" />
              <Flex
                w="full"
                h="full"
                justifyContent="center"
                gap="24px"
                alignItems="baseline"
              >
                <Button
                  color="gray.300"
                  variant="outlineDefault"
                  borderRadius="20px"
                  borderColor="gray.300"
                  fontSize="sm"
                  type="button"
                  height="32px"
                  onClick={() => onReject()}
                  w="96px"
                >
                  Cancelar
                </Button>
                <ButtonDefault
                  color="black"
                  variant="solid"
                  colorScheme="secondary"
                  borderRadius="20px"
                  fontSize="sm"
                  h="32px"
                  width="160px"
                  onClick={handleSubmit}
                  isLoading={isLoading}
                >
                  Antecipar {textContaOuContas}
                </ButtonDefault>
              </Flex>
            </ModalFooter>
          </FormProvider>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
