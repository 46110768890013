import * as yup from 'yup';
import { toast } from 'react-toastify';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

export interface VariationProps {
  produtoCorTamanhoId: string;
  produto: string;
  cor: string;
  tamanho: string;
  imagem: string;
  isChecked: boolean;
}

export type FormData = {
  quantidade: number[];
  materiaPrimaFinal: boolean;
};

const schema = yup.object().shape({
  materiaPrimaFinal: yup.boolean(),
  quantidade: yup.array().of(
    yup.number().min(0.0001, () => {
      toast.warning('O campo quantidade não pode estar zerado', {
        toastId: 'minQuantityWarning',
      });
    })
  ),
});

export const yupResolver = yupResolverInstance(schema);
