import TipoSituacaoBaixa from 'constants/enum/situacaoBaixa';
import { setDateMaxHours, setDateMinHours } from 'helpers/data/setHoursDate';

type MovimentacaoFinanceiraBaixa = {
  dataPagamento?: Date;
  desconto?: number;
  juros?: number;
  multa?: number;
};

export type Conta = {
  selecionado?: boolean;
  competencia: Date | null;
  dataEmissaoOperacao?: Date;
  dataVencimento: Date;
  entradaMercadoriaId?: string;
  fornecedorRazaoSocial: string;
  fornecedorNomeFantasia: string;
  formaPagamento: string;
  faturaId?: string;
  historico?: string;
  identificador?: number;
  movimentacaoFinanceiraId: string;
  numeroDocumento?: string;
  operacaoId: string;
  parcela: string;
  planoContas: string;
  possuiDesmembramento: boolean;
  tipoOperacao: number;
  valor: number;
  movimentacaoFinanceiraBaixa: MovimentacaoFinanceiraBaixa[];
};

export type FormData = {
  clienteFornecedorId: {
    label: string;
    value: string;
  } | null;
  dataVencimentoInicio: Date;
  dataVencimentoFim?: Date;
  dataEmissaoInicio?: Date;
  dataEmissaoFim?: Date;
  dataPagamentoInicio?: Date;
  dataPagamentoFim?: Date;
  competencia?: Date | null;
  planoContasId: string | null;
  formaPagamentoId: string | null;
  situacao: string | number;
  identificador: string | number;
  numeroDocumento?: string;
  historico?: string;
  exibirFatura: boolean;
  exibirItensFatura: boolean;
};

export const formDefaultValues = {
  clienteFornecedorId: null,
  dataVencimentoInicio: setDateMinHours(new Date()),
  dataVencimentoFim: setDateMaxHours(new Date()),
  dataEmissaoInicio: undefined,
  dataEmissaoFim: undefined,
  dataPagamentoInicio: undefined,
  dataPagamentoFim: undefined,
  competencia: null,
  planoContasId: null,
  formaPagamentoId: null,
  situacao: TipoSituacaoBaixa.CONTAS_EM_ABERTO,
  identificador: '',
  numeroDocumento: '',
  historico: '',
  exibirFatura: false,
  exibirItensFatura: false,
};
