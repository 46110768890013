import { Box, Flex, Button, Text, Icon } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { FiChevronLeft } from 'react-icons/fi';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { ContainerHeader } from 'pages/Integracoes/LojaAplicativos/Layout/ContainerHeader';

import { FormaRecebimentoItem } from './FormaRecebimentoItem';
import { usePainelFrenteCaixa } from '../hooks';
import { useFormaRecebimento } from './hooks';
import { FormData, defaultValues, yupResolver } from './validationForms';

export const PainelAdministradorFrenteCaixaFormaRecebimento = () => {
  const formMethods = useForm<FormData>({
    defaultValues,
    resolver: yupResolver,
  });

  const { handleUpdateFormaRecebimento, isLoading } = useFormaRecebimento(
    formMethods
  );
  const { handleVoltarPainelAdm } = usePainelFrenteCaixa();

  return (
    <FormProvider {...formMethods}>
      <ContainerHeader bg="#FF005A" gap={2} color="white" alignItems="center">
        <Box cursor="pointer" mr="10px" onClick={handleVoltarPainelAdm}>
          <Icon fontSize="25px" as={FiChevronLeft} />
        </Box>
        <Text fontSize="16px" fontWeight="semibold">
          TEF
        </Text>
      </ContainerHeader>
      <Box position="relative" px={6}>
        {isLoading && <LoadingPadrao />}
        <Box mb="20px">
          <FormaRecebimentoItem />
        </Box>
        <Flex mb="20px" justifyContent="center" alignItems="center">
          <Button
            variant="outlineDefault"
            borderRadius="16px"
            colorScheme="gray"
            mr="24px"
            w="96px"
          >
            Cancelar
          </Button>
          <Button
            color="black"
            variant="solid"
            colorScheme="secondary.400"
            borderRadius="16px"
            onClick={handleUpdateFormaRecebimento}
            isDisabled={isLoading}
          >
            Salvar configurações
          </Button>
        </Flex>
      </Box>
    </FormProvider>
  );
};
