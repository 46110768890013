import ConstanteFuncionalidades from 'constants/permissoes';
import ConstanteRotas from 'constants/rotas';

import { ConciliacaoDeContas } from 'pages/ConciliacaoContas/Listar';

import LayoutGuard from './LayoutGuard';

export const ConciliacaoDeContasRoutes = [
  <LayoutGuard
    key="conciliacao_listar"
    permissaoFuncionalidade={ConstanteFuncionalidades.CONTAS_RECEBER_LISTAR}
    component={ConciliacaoDeContas}
    breadcrumb={[
      {
        title: 'Financeiro',
      },
      {
        title: 'Conciliação de Contas',
      },
    ]}
    path={ConstanteRotas.CONCILIACAO_CONTAS}
    exact
    meta={{ auth: true }}
  />,
];
