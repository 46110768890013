import React from 'react';
import { Flex, FlexProps, Text } from '@chakra-ui/react';

import { getImageBySystem } from 'helpers/layout/getImageBySystem';
import { loginBackgroundImgs } from 'constants/enum/enumsImgSistemas';

import Logo from 'components/Autenticacao/Logo';

interface BackgroundProps extends FlexProps {
  children: React.ReactNode;
}

export const LoginBackgroundFomer = ({
  children,
  ...rest
}: BackgroundProps) => {
  return (
    <Flex
      position="relative"
      h="100%"
      w="full"
      minH="calc(var(--vh-autenticacao, 1vh) * 100)"
      justifyContent="center"
      alignItems="center"
      bgImage={`url(${getImageBySystem(loginBackgroundImgs)})`}
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
      px={14}
      {...rest}
    >
      <Flex
        flexDirection="column"
        justifyContent="center"
        h="full"
        minH="calc(var(--vh-autenticacao, 1vh) * 100)"
        w={{ base: '265px', sm: '300px', xl: '350px' }}
        zIndex="docked"
        pt={{ base: '0', sm: '8', xl: '8' }}
        pb={{ base: '4', sm: '8', xl: '8' }}
      >
        <Logo />
        {children}
      </Flex>
      <Flex position="absolute" w="full" justifyContent="center" bottom="0">
        <Text color="purple.100">v{process.env.REACT_APP_APP_VERSION}</Text>
      </Flex>
    </Flex>
  );
};
