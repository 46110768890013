export type ModelosFiscais = 65 | 55 | 99;

const ModelosFiscaisExportacaoEnum = {
  NFCe: 65,
  NFe: 55,
  TODOS: 99,

  properties: {
    99: { name: 'Todos', value: 99 },
    65: { name: 'NFC-e', value: 65 },
    55: { name: 'NF-e', value: 55 },
  },

  options: [
    { value: 55, label: 'NF-e' },
    { value: 65, label: 'NFC-e' },
    { value: 99, label: 'Todos' },
  ],
};

export default ModelosFiscaisExportacaoEnum;
