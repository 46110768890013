import { Box, GridItem, Icon, Text, Flex, Button } from '@chakra-ui/react';
import { FiChevronLeft } from 'react-icons/fi';

import Input from 'components/PDV/Input';
import { SalvarInserirNovoIcon } from 'icons';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import CheckBoxAtivoInativo from 'components/update/Checkbox/CheckboxAtivoInativo';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { ContainerHeader } from 'pages/Integracoes/LojaAplicativos/Layout/ContainerHeader';

import { useDeliveryPersonForm } from './hooks';

type UncontrolledFormProps = Partial<{
  handleSave: () => Promise<void>;
  handleSaveAndReset: () => Promise<void>;
  isLoading: boolean;
}>;

const UncontrolledForm = ({
  handleSave,
  handleSaveAndReset,
  isLoading,
}: UncontrolledFormProps) => {
  const { redirectToDeliveryPersonList } = useDeliveryPersonForm();
  return (
    <>
      <ContainerHeader bg="#FF005A" gap={2} color="white" alignItems="center">
        <Box cursor="pointer" mr="10px" onClick={redirectToDeliveryPersonList}>
          <Icon fontSize="25px" as={FiChevronLeft} />
        </Box>
        <Text fontSize="16px" fontWeight="semibold">
          Entregadores
        </Text>
      </ContainerHeader>
      <Box position="relative" p={6}>
        {isLoading && <LoadingPadrao />}
        <SimpleGridForm gap={{ base: 6, md: 8 }}>
          <GridItem colSpan={{ base: 12, sm: 9 }}>
            <Input
              id="nome"
              name="nome"
              label="Nome"
              placeholder="Informe o nome"
              maxLength={50}
            />
          </GridItem>
          <GridItem colSpan={{ base: 12, sm: 3 }}>
            <CheckBoxAtivoInativo
              label="Status"
              id="ativo"
              name="ativo"
              isRequired={false}
            />
          </GridItem>
        </SimpleGridForm>
        <Flex p="40px" gap={6} align="center" justify="center" flexWrap="wrap">
          {handleSaveAndReset && (
            <Button
              w={{ base: 'full', sm: 'min-content' }}
              colorScheme="gray"
              variant="outlineDefault"
              leftIcon={<Icon as={SalvarInserirNovoIcon} fontSize="lg" />}
              onClick={handleSaveAndReset}
            >
              Salvar e inserir novo
            </Button>
          )}
          {handleSave && (
            <Button
              w={{ base: 'full', sm: 'min-content' }}
              minW="225px"
              colorScheme="secondary"
              onClick={handleSave}
            >
              Salvar
            </Button>
          )}
        </Flex>
      </Box>
    </>
  );
};

export { UncontrolledForm };
