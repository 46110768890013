import { setDateMaxHours, setDateMinHours } from 'helpers/data/setHoursDate';

export type FormData = {
  dataInicio: Date;
  dataFim: Date;
};

export function formDefaultValues() {
  return {
    dataInicio: setDateMinHours(new Date()),
    dataFim: setDateMaxHours(new Date()),
  };
}
