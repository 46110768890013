import React, { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { HStack, Text } from '@chakra-ui/react';

export const Totalizador = memo(() => {
  const { watch } = useFormContext();

  const tamanhos = watch('tamanhos');

  const quantidadeTotal = (tamanhos || []).reduce(
    (acc: number, curr: any) => acc + (curr?.quantidade || 0),
    0
  );

  return (
    <HStack spacing={1} whiteSpace="nowrap">
      <Text lineHeight="none">Total de produto:</Text>
      <Text lineHeight="none" fontWeight="bold">
        {quantidadeTotal}
      </Text>
    </HStack>
  );
});
