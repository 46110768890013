import React from 'react';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

import CategoriasClienteListar from '../pages/CategoriasCliente/Listar';
import CategoriaClienteCadastrar from '../pages/CategoriasCliente/Formulario/Cadastrar';
import CategoriaClienteAlterar from '../pages/CategoriasCliente/Formulario/Alterar';
import CategoriaClienteVisualizar from '../pages/CategoriasCliente/Formulario/Visualizar';

const CategoriaClientesRoutes = [
  <LayoutGuard
    key="categoria-clientes-listar"
    permissaoFuncionalidade={ConstanteFuncionalidades.CATEGORIA_CLIENTE_LISTAR}
    component={CategoriasClienteListar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Categoria de Clientes',
      },
    ]}
    path={ConstanteRotas.CATEGORIA_CLIENTE}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="categoria-clientes-cadastrar"
    permissaoFuncionalidade={
      ConstanteFuncionalidades.CATEGORIA_CLIENTE_CADASTRAR
    }
    component={CategoriaClienteCadastrar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Categoria de Clientes',
        path: ConstanteRotas.CATEGORIA_CLIENTE,
      },
      {
        title: 'Novo',
      },
    ]}
    path={ConstanteRotas.CATEGORIA_CLIENTE_CADASTRAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="categoria-clientes-alterar"
    permissaoFuncionalidade={ConstanteFuncionalidades.CATEGORIA_CLIENTE_ALTERAR}
    component={CategoriaClienteAlterar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Categoria de Clientes',
        path: ConstanteRotas.CATEGORIA_CLIENTE,
      },
      {
        title: 'Editar',
      },
    ]}
    path={ConstanteRotas.CATEGORIA_CLIENTE_ALTERAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="categoria-clientes-visualizar"
    permissaoFuncionalidade={
      ConstanteFuncionalidades.CATEGORIA_CLIENTE_VISUALIZAR
    }
    component={CategoriaClienteVisualizar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Categoria de Clientes',
        path: ConstanteRotas.CATEGORIA_CLIENTE,
      },
      {
        title: 'Visualizar',
      },
    ]}
    path={ConstanteRotas.CATEGORIA_CLIENTE_VISUALIZAR}
    exact
    meta={{ auth: true }}
  />,
];

export default CategoriaClientesRoutes;
