import React from 'react';

import { Route } from 'react-router-dom';
import ClienteFornecedorFiltroProvider from 'store/ClienteFornecedor/ClienteFornecedorFiltros';
import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

import ClientesListar from '../pages/ClientesFornecedores/Listar';
import ClientesCadastrar from '../pages/ClientesFornecedores/Formulario/Cadastrar';
import ClientesAlterar from '../pages/ClientesFornecedores/Formulario/Alterar';
import ClientesVisualizar from '../pages/ClientesFornecedores/Formulario/Visualizar';

const CustomClienteListar = (props: any) => {
  return <ClientesListar rotaCliente {...props} />;
};

const CustomClienteCadastrar = (props: any) => {
  return <ClientesCadastrar rotaCliente {...props} />;
};

const CustomClienteAlterar = (props: any) => {
  return <ClientesAlterar rotaCliente {...props} />;
};

const CustomClienteVisualizar = (props: any) => {
  return <ClientesVisualizar rotaCliente {...props} />;
};

const ClientesRoutes = [
  <Route path={ConstanteRotas.CLIENTE}>
    <ClienteFornecedorFiltroProvider>
      <LayoutGuard
        key="clientes-listar"
        permissaoFuncionalidade={ConstanteFuncionalidades.CLIENTE_LISTAR}
        component={CustomClienteListar}
        breadcrumb={[
          {
            title: 'Cadastros',
          },
          {
            title: 'Clientes',
          },
        ]}
        path={ConstanteRotas.CLIENTE}
        exact
        meta={{ auth: true }}
      />
    </ClienteFornecedorFiltroProvider>
  </Route>,
  <LayoutGuard
    key="clientes-cadastrar"
    permissaoFuncionalidade={ConstanteFuncionalidades.CLIENTE_CADASTRAR.codigo}
    component={CustomClienteCadastrar}
    breadcrumb={[
      {
        title: 'Cadastros',
      },
      {
        title: 'Clientes',
        path: ConstanteRotas.CLIENTE,
      },
      {
        title: 'Novo',
      },
    ]}
    path={ConstanteRotas.CLIENTE_CADASTRAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="clientes-alterar"
    permissaoFuncionalidade={ConstanteFuncionalidades.CLIENTE_ALTERAR.codigo}
    component={CustomClienteAlterar}
    breadcrumb={[
      {
        title: 'Cadastros',
      },
      {
        title: 'Clientes',
        path: ConstanteRotas.CLIENTE,
      },
      {
        title: 'Editar',
      },
    ]}
    path={ConstanteRotas.CLIENTE_ALTERAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="clientes-visualizar"
    permissaoFuncionalidade={ConstanteFuncionalidades.CLIENTE_VISUALIZAR}
    component={CustomClienteVisualizar}
    breadcrumb={[
      {
        title: 'Cadastros',
      },
      {
        title: 'Clientes',
        path: ConstanteRotas.CLIENTE,
      },
      {
        title: 'Visualizar',
      },
    ]}
    path={ConstanteRotas.CLIENTE_VISUALIZAR}
    exact
    meta={{ auth: true }}
  />,
];

export default ClientesRoutes;
