import React, { createContext, useState, useContext, useCallback } from 'react';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import auth from 'modules/auth';
import ConstanteFuncionalidades from 'constants/permissoes';

import { PaginationData } from 'components/update/Pagination';
import { VendedorVinculado } from 'pages/Vendas/MetasComissoes/Types/validationsForm';

interface MetasComissoesHistoricoVendasContextProps {
  registroPaginacao: PaginationData;
  setRegistroPaginacao: React.Dispatch<React.SetStateAction<PaginationData>>;
  vendedorVinculado: VendedorVinculado;
  obterVendedorVinculado: () => Promise<string | null>;
}

export const MetasComissoesHistoricoVendasContext = createContext<MetasComissoesHistoricoVendasContextProps>(
  {} as MetasComissoesHistoricoVendasContextProps
);

interface MetasComissoesHistoricoVendasProviderProps {
  children: React.ReactNode;
}

export function MetasComissoesHistoricoVendasProvider({
  children,
}: MetasComissoesHistoricoVendasProviderProps): JSX.Element {
  const [registroPaginacao, setRegistroPaginacao] = useState<PaginationData>(
    {} as PaginationData
  );

  const [vendedorVinculado, setVendedorVinculado] = useState<VendedorVinculado>(
    {} as VendedorVinculado
  );

  const possuiPermissaoMetaComissao = auth.possuiPermissao(
    ConstanteFuncionalidades.METAS_COMISSAO
  ).permitido;

  const obterVendedorVinculado = useCallback(async () => {
    if (!possuiPermissaoMetaComissao) {
      const response = await api.get<void, ResponseApi<VendedorVinculado>>(
        ConstanteEnderecoWebservice.OBTER_VENDEDOR_VINCULADO
      );
      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
          return '';
        }
        if (response.sucesso && response.dados) {
          setVendedorVinculado(response.dados);
          return response.dados.id;
        }
      }
      return '';
    }
    return null;
  }, [possuiPermissaoMetaComissao]);

  return (
    <MetasComissoesHistoricoVendasContext.Provider
      value={{
        registroPaginacao,
        setRegistroPaginacao,
        vendedorVinculado,
        obterVendedorVinculado,
      }}
    >
      {children}
    </MetasComissoesHistoricoVendasContext.Provider>
  );
}

export function useMetasComissoesHistoricoVendasContext(): MetasComissoesHistoricoVendasContextProps {
  const context = useContext(MetasComissoesHistoricoVendasContext);

  if (!context)
    throw new Error(
      'useMetasComissoesHistoricoVendasContext must be used within a MetasComissoesEtapasProvider.'
    );

  return context;
}
