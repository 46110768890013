import { useEffect, useRef } from 'react';

interface UseIntersectionObserverProps {
  onIntersecting: () => void;
  isObserver?: boolean;
}

export function useIntersectionObserver({
  onIntersecting,
  isObserver = true,
}: UseIntersectionObserverProps) {
  const elementRef = useRef(null);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting) && isObserver) {
        onIntersecting();
      }
    });

    const { current: element } = elementRef;
    if (element) {
      intersectionObserver.observe(element);
    }
    return () => intersectionObserver.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isObserver]);

  return { elementRef };
}
