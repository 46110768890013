import { Flex, Text } from '@chakra-ui/react';

interface FooterProps {
  rodaPe: string;
}
export function FooterCupomVoucher({ rodaPe }: FooterProps) {
  return (
    <Flex
      justifyContent="flex-end"
      alignItems="center"
      py="2"
      borderTop="2px solid black"
      fontWeight="extrabold"
      letterSpacing="tight"
    >
      <Text>{rodaPe}</Text>
    </Flex>
  );
}
