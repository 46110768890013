import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import { formatFullDate } from 'helpers/format/formatData';
import { setDateMaxHours, setDateMinHours } from 'helpers/data/setHoursDate';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import OptionType from 'types/optionType';

export type ResponseCategoriaProps = {
  id: string;
  nome: string;
};

export type CategoriaProps = {
  label: string;
  value: string;
};

export type FormData = {
  tipoRelatorio: string;
  periodoInicio: Date;
  periodoFim: Date;
  categoriaClienteId?: OptionType<string> | null;
  cidadeId?: OptionType<string> | null;
  status?: number;
  ultimaCompra?: boolean;
  dataUltimaCompra?: string;
  ordenacao?: string;
  mesAniversariante?: number;
};

export const EnumOptionsRelatorio = {
  CLIENTE_NUNCA_COMPRARAM: 'clientes-nunca-compraram',
  CLIENTE_NAO_COMPRARAM_NO_PERIODO: 'clientes-sem-venda',
  CLIENTES_ANIVERSARIANTES: 'clientes-aniversariantes',
  CLIENTE_MAIS_COMPRAM: 'clientes-mais-compram',

  options: [
    {
      label: 'Clientes que não compraram no período',
      value: 'clientes-sem-venda',
    },
    {
      label: 'Clientes que nunca compraram',
      value: 'clientes-nunca-compraram',
    },
    {
      label: 'Clientes que mais compram',
      value: 'clientes-mais-compram',
    },
    {
      label: 'Clientes aniversariantes',
      value: 'clientes-aniversariantes',
    },
  ],
};

export const EnumOptiosOrdenacao = {
  VALOR_TOTAL: 'ValorTotal',
  VALOR_MEDIO: 'Media',
  QUANTIDADE: 'Qtd',

  options: [
    {
      label: 'Valor total',
      value: 'ValorTotal',
    },
    {
      label: 'Valor médio',
      value: 'Media',
    },
    {
      label: 'Quantidade',
      value: 'Qtd',
    },
  ],
};

// Criando instância e ajustando a data para seis meses atrás
const today = new Date();
today.setMonth(today.getMonth() - 6);

export const defaultFormValue = {
  tipoRelatorio: EnumOptionsRelatorio.CLIENTE_NAO_COMPRARAM_NO_PERIODO,
  status: 1,
  periodoInicio: setDateMinHours(new Date()),
  periodoFim: setDateMaxHours(new Date()),
  categoriaClienteId: null,
  cidadeId: null,
  ultimaCompra: true,
  dataUltimaCompra: formatFullDate(today),
  ordenacao: EnumOptiosOrdenacao.VALOR_TOTAL,
  mesAniversariante: 0,
};

const schema = yup.object().shape({
  tipoRelatorio: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  periodoInicio: yup.date().when('tipoRelatorio', {
    is: (tipoRelatorio) =>
      tipoRelatorio === EnumOptionsRelatorio.CLIENTE_MAIS_COMPRAM ||
      tipoRelatorio === EnumOptionsRelatorio.CLIENTE_NAO_COMPRARAM_NO_PERIODO,
    then: yup.date(),
    otherwise: yup.date().transform(() => undefined),
  }),
  periodoFim: yup.date().when('tipoRelatorio', {
    is: (tipoRelatorio) =>
      tipoRelatorio === EnumOptionsRelatorio.CLIENTE_MAIS_COMPRAM ||
      tipoRelatorio === EnumOptionsRelatorio.CLIENTE_NAO_COMPRARAM_NO_PERIODO,
    then: yup.date(),
    otherwise: yup.date().transform(() => undefined),
  }),
  dataUltimaCompra: yup.string().when(['ultimaCompra', 'tipoRelatorio'], {
    is: (ultimaCompra, tipoRelatorio) =>
      ultimaCompra === true &&
      tipoRelatorio === EnumOptionsRelatorio.CLIENTE_NAO_COMPRARAM_NO_PERIODO,
    then: yup.string().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
    otherwise: yup.string().transform(() => undefined),
  }),
  ordenacao: yup.string().when('tipoRelatorio', {
    is: EnumOptionsRelatorio.CLIENTE_MAIS_COMPRAM,
    then: yup.string().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
    otherwise: yup.string().transform(() => undefined),
  }),
  mesAniversariante: yup.number().when('tipoRelatorio', {
    is: EnumOptionsRelatorio.CLIENTES_ANIVERSARIANTES,
    then: yup.number().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
    otherwise: yup.number().transform(() => undefined),
  }),
});

export const yupResolver = yupResolverInstance(schema);
