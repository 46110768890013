import React from 'react';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

import HistoricoAcoesListar from '../pages/HistoricoAcoes/Listar';
// import HistoricoAcoesVisualizar from '../pages/HistoricoAcoes/Formulario/Visualizar';

const HistoricoAcoesRoutes = [
  <LayoutGuard
    key="historico-acoes-listar"
    permissaoFuncionalidade={ConstanteFuncionalidades.HISTORICO_ACOES_LISTAR}
    component={HistoricoAcoesListar}
    path={ConstanteRotas.HISTORICO_ACOES}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Histórico de Ações',
      },
    ]}
    exact
    meta={{ auth: true }}
  />,
];

export default HistoricoAcoesRoutes;
