export const enumStatusTransacoesZoopOptions = {
  TODOS: null,
  CANCELADO: 0,
  PAGO: 6,
  PENDENTE: 8,

  properties: [
    {
      label: 'Todas as situações',
      value: null,
    },
    {
      label: 'Canceladas',
      value: 0,
    },
    {
      label: 'Pagas',
      value: 6,
    },
    {
      label: 'Pendentes',
      value: 8,
    },
  ],
};

export const enumStatusTransacoesZoopLabels = {
  [enumStatusTransacoesZoopOptions.CANCELADO]: 'CANCELADA',
  [enumStatusTransacoesZoopOptions.PAGO]: 'PAGA',
  [enumStatusTransacoesZoopOptions.PENDENTE]: 'PENDENTE',
};
