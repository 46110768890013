import React from 'react';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

import ContaFinanceiraListar from '../pages/ContaFinanceira/Listar';
import ContaFinanceiraCadastrar from '../pages/ContaFinanceira/Formulario/Cadastrar';
import ContaFinanceiraAlterar from '../pages/ContaFinanceira/Formulario/Alterar';
import ContaFinanceiraVisualizar from '../pages/ContaFinanceira/Formulario/Visualizar';

const ContaFinanceiraRoutes = [
  <LayoutGuard
    key="contafinanceira-listar"
    permissaoFuncionalidade={ConstanteFuncionalidades.CONTA_FINANCEIRA_LISTAR}
    component={ContaFinanceiraListar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Conta Financeira',
      },
    ]}
    path={ConstanteRotas.CONTA_FINANCEIRA}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="contafinanceira-cadastrar"
    permissaoFuncionalidade={
      ConstanteFuncionalidades.CONTA_FINANCEIRA_CADASTRAR
    }
    component={ContaFinanceiraCadastrar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Conta Financeira',
        path: ConstanteRotas.CONTA_FINANCEIRA,
      },
      {
        title: 'Novo',
      },
    ]}
    path={ConstanteRotas.CONTA_FINANCEIRA_CADASTRAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="contafinanceira-alterar"
    permissaoFuncionalidade={ConstanteFuncionalidades.CONTA_FINANCEIRA_ALTERAR}
    component={ContaFinanceiraAlterar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Conta Financeira',
        path: ConstanteRotas.CONTA_FINANCEIRA,
      },
      {
        title: 'Editar',
      },
    ]}
    path={ConstanteRotas.CONTA_FINANCEIRA_ALTERAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="contafinanceira-visualizar"
    permissaoFuncionalidade={
      ConstanteFuncionalidades.CONTA_FINANCEIRA_VISUALIZAR
    }
    component={ContaFinanceiraVisualizar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Conta Financeira',
        path: ConstanteRotas.CONTA_FINANCEIRA,
      },
      {
        title: 'Visualizar',
      },
    ]}
    path={ConstanteRotas.CONTA_FINANCEIRA_VISUALIZAR}
    exact
    meta={{ auth: true }}
  />,
];

export default ContaFinanceiraRoutes;
