import { ContextFiltrosContasPagarProvider } from 'store/Financeiro/ContasPagar';
import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

import { ListarContasPagar } from '../pages/ContasPagar/Listar';
import { CadastrarLancamentoContas } from '../pages/LancamentoContas/Formulario/Cadastrar';
import { AlterarLancamentoContas } from '../pages/LancamentoContas/Formulario/Alterar';

const ContaPagarRoutes = [
  <ContextFiltrosContasPagarProvider>
    <LayoutGuard
      key="contaspagar_listar"
      permissaoFuncionalidade={ConstanteFuncionalidades.CONTAS_PAGAR_LISTAR}
      component={ListarContasPagar}
      breadcrumb={[
        {
          title: 'Financeiro',
        },
        {
          title: 'Contas a Pagar',
        },
      ]}
      path={ConstanteRotas.CONTAS_PAGAR}
      exact
      meta={{ auth: true }}
    />
  </ContextFiltrosContasPagarProvider>,
  <ContextFiltrosContasPagarProvider>
    <LayoutGuard
      key="contaspagar-cadastrar-lancamento"
      permissaoFuncionalidade={ConstanteFuncionalidades.CONTAS_PAGAR_CADASTRAR}
      component={() => (
        <CadastrarLancamentoContas
          rotaRetorno={ConstanteRotas.CONTAS_PAGAR}
          rotaAtual={ConstanteRotas.CONTAS_PAGAR_CADASTRAR_LANCAMENTO}
        />
      )}
      breadcrumb={[
        {
          title: 'Financeiro',
        },
        {
          title: 'Contas a Pagar',
          path: ConstanteRotas.CONTAS_PAGAR,
        },
        { title: 'Nova conta' },
      ]}
      path={ConstanteRotas.CONTAS_PAGAR_CADASTRAR_LANCAMENTO}
      exact
      meta={{ auth: true }}
    />
  </ContextFiltrosContasPagarProvider>,
  <ContextFiltrosContasPagarProvider>
    <LayoutGuard
      key="contaspagar-alterar-lancamento"
      permissaoFuncionalidade={ConstanteFuncionalidades.CONTAS_PAGAR_ALTERAR}
      component={(props: any) => (
        <AlterarLancamentoContas
          {...props}
          rotaRetorno={ConstanteRotas.CONTAS_PAGAR}
          rotaAtual={ConstanteRotas.CONTAS_PAGAR_ALTERAR_LANCAMENTO}
        />
      )}
      breadcrumb={[
        {
          title: 'Financeiro',
        },
        {
          title: 'Contas a Pagar',
          path: ConstanteRotas.CONTAS_PAGAR,
        },
        { title: 'Editar conta' },
      ]}
      path={ConstanteRotas.CONTAS_PAGAR_ALTERAR_LANCAMENTO}
      exact
      meta={{ auth: true }}
    />
  </ContextFiltrosContasPagarProvider>,
  <ContextFiltrosContasPagarProvider>
    <LayoutGuard
      key="contaspagar-visualizar"
      permissaoFuncionalidade={ConstanteFuncionalidades.CONTAS_PAGAR_ALTERAR}
      component={(props: any) => (
        <AlterarLancamentoContas
          {...props}
          rotaRetorno={ConstanteRotas.CONTAS_PAGAR}
          rotaAtual={ConstanteRotas.CONTAS_PAGAR_VISUALIZAR}
        />
      )}
      breadcrumb={[
        {
          title: 'Financeiro',
        },
        {
          title: 'Contas a Pagar',
          path: ConstanteRotas.CONTAS_PAGAR,
        },
        { title: 'Visualizar' },
      ]}
      path={ConstanteRotas.CONTAS_PAGAR_VISUALIZAR}
      exact
      meta={{ auth: true }}
    />
  </ContextFiltrosContasPagarProvider>,
];

export default ContaPagarRoutes;
