import { useCallback, useState } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  ModalFooter,
  Progress,
  VStack,
  useMediaQuery,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FiDownload } from 'react-icons/fi';
import { toast } from 'react-toastify';
import JSZip from 'jszip';
import axios from 'axios';
import jsFileDownload from 'js-file-download';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';

type ModalAjudaProps = Omit<ModalProps, 'children' | 'isOpen' | 'onClose'> &
  InstanceProps<ModalProps> & {
    idProduto: string;
  };

export const ModalDownload = create<ModalAjudaProps, ModalProps>(
  ({ onResolve, onReject, idProduto, ...rest }) => {
    const [isLoading, setIsLoaging] = useState(false);
    const [porcentagemDownload, setPorcentagemDownload] = useState(0);

    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    const handleDownloadImagens = useCallback(async () => {
      setIsLoaging(true);
      const response = await api.get<void, ResponseApi<string[]>>(
        `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/imagens/download`
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }

        if (response.sucesso) {
          const { dados } = response;
          setPorcentagemDownload(0);

          Promise.all(
            dados.map((url) => {
              return axios.get(url, {
                responseType: 'blob',
              });
            })
          ).then(async (responseImagem) => {
            const zip = new JSZip();

            responseImagem.forEach((blob, index) => {
              const progressoAtual = index + 1;

              const progress = (progressoAtual * 100) / responseImagem.length;
              setPorcentagemDownload(progress);
              zip.file(`imagem-${index + 1}.png`, blob.data);
            });

            await zip.generateAsync({ type: 'blob' }).then((content) => {
              jsFileDownload(content, 'imagem-variacao.zip');
            });
          });
          toast.success('As imagens foram baixadas com sucesso');
          onClose();
        }
      }
    }, [idProduto, onClose]);

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? '3xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
      >
        <ModalContent
          bg="gray.50"
          borderRadius={isLargerThan900 ? 'md' : '0'}
          marginBottom={isLargerThan900 ? '3.75rem' : '0'}
          marginTop={isLargerThan900 ? '3.75rem' : '0'}
          w={isLargerThan900 ? '550px' : 'full'}
          h={isLargerThan900 ? '360px' : 'full'}
        >
          <ModalBody mb="20px" pt="0" px="0" pb="0">
            <Flex
              color="white"
              borderTopRadius="md"
              justifyContent="center"
              alignItems="center"
              bg="primary.50"
              h="128px"
            >
              <FiDownload size={80} />
            </Flex>
            <VStack
              pl="24px"
              mt="32px"
              pr="24px"
              color="gray.700"
              fontSize="14px"
              w="full"
              alignItems="left"
              spacing="16px"
            >
              <Text
                color="violet.500"
                fontSize="20px"
                textAlign="left"
                letterSpacing="0px"
                fontWeight="semibold"
              >
                Download das imagens
              </Text>
              <Text textAlign="left" letterSpacing="0px">
                Todas as imagens cadastradas no sistema serão baixadas para o
                seu dispositivo. Você tem certeza que deseja continuar?
              </Text>
              {porcentagemDownload > 0 && (
                <Progress
                  hasStripe
                  value={porcentagemDownload}
                  width="full"
                  colorScheme="violet"
                  isAnimated
                  borderRadius="full"
                />
              )}
            </VStack>
          </ModalBody>
          <ModalFooter flexDirection="column" mb="10px">
            <Flex
              w="full"
              h="full"
              direction={['column', 'column', 'row']}
              justifyContent="center"
              alignItems="baseline"
            >
              <Button
                mr={[0, 0, '24px']}
                mb={['24px', '24px', '0']}
                variant="outlineDefault"
                colorScheme="gray.500"
                width="96px"
                onClick={() => onClose()}
              >
                Cancelar
              </Button>
              <Button
                color="white"
                variant="solid"
                colorScheme="purple.500"
                lineHeight="0"
                isLoading={isLoading}
                padding="12px"
                onClick={() => handleDownloadImagens()}
              >
                Download das imagens
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
