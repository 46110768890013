import React, { useState } from 'react';
import { FiEdit3 } from 'react-icons/fi';
import { Box, Flex, Icon, Text, Circle, VStack } from '@chakra-ui/react';

import { ImagemAdicionarIcon } from 'icons';

import { DrawerInformacoesImagem } from './DrawerInformacoesImagem';

export type ListImagensResponseProps = {
  id?: string;
  produtoCorId: string;
  imagem: string;
  sequenciaOrdenacao: number;
  notDrag: boolean;
  principal: boolean;
  idVariacao?: string;
  carregando?: boolean;
  sequenciaDrag?: number;
};

type ListItemImagemProps = {
  imagem: ListImagensResponseProps;
  handleAdicionarImagem: (variacao: {
    name: string;
    id: string;
  }) => Promise<void>;
  variacao: {
    name: string;
    id: string;
  };
  index: number;
  iconSize?: string;
  handleAtualizarImagemPrincipal: (item: ListImagensResponseProps) => void;
};

export const ListItemImagem = ({
  imagem,
  handleAdicionarImagem,
  variacao,
  index,
  handleAtualizarImagemPrincipal,
  iconSize = '25px',
}: ListItemImagemProps) => {
  const [onMouseOver, setOnMouseOver] = useState(false);
  const [isOpenDetalhesImagem, setIsOpenDetalhesImagem] = useState(false);

  return (
    <>
      {imagem.notDrag ? (
        <>
          {index < 15 && (
            <VStack
              w="160px"
              h="160px"
              mr="6px"
              color="blue.700"
              borderWidth="1px"
              borderStyle="dashed"
              borderColor="blue.700"
              borderRadius="2px"
              cursor="pointer"
              justify="center"
              onClick={() => handleAdicionarImagem(variacao)}
            >
              <Icon fontSize={iconSize} as={ImagemAdicionarIcon} />
              <Text w="70px" textAlign="center" lineHeight="15px">
                Adicionar imagem
              </Text>
            </VStack>
          )}
        </>
      ) : (
        <Box
          onMouseOverCapture={() => setOnMouseOver(true)}
          onMouseLeave={() => setOnMouseOver(false)}
          boxShadow="0px 3px 6px #00000029"
          mr="6px"
          w="160px"
          borderRadius="2px"
          backgroundRepeat="no-repeat"
          backgroundSize="contain"
          backgroundPosition="center"
          style={{
            backgroundImage: onMouseOver
              ? `linear-gradient(360deg, rgba(0,0,0,0.5) 0%, rgba( 0, 0, 0, 0.0) 50%), url(${imagem.imagem})`
              : `url(${imagem.imagem})`,
          }}
          h="160px"
        >
          {onMouseOver ? (
            <Flex h="full" flexDirection="column" justifyContent="flex-end">
              <Flex
                color="white"
                justifyContent="space-between"
                alignItems="center"
                p="10px"
              >
                <Flex
                  onClick={() => handleAtualizarImagemPrincipal(imagem)}
                  cursor="pointer"
                  alignItems="center"
                >
                  <Circle
                    boxShadow="inset 0px 3px 6px #00000029"
                    size="3.5"
                    bg={imagem.principal ? 'secondary.300' : 'white'}
                  />

                  <Text fontSize="12px" ml="3px" color="white">
                    Principal
                  </Text>
                </Flex>
                <Box
                  onClick={() => setIsOpenDetalhesImagem(true)}
                  cursor="pointer"
                >
                  <FiEdit3 size={15} />
                </Box>
              </Flex>
            </Flex>
          ) : (
            <>
              {imagem.principal && (
                <Box px="10px" pt="5px">
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    bg="secondary.300"
                    w="70px"
                    borderRadius="10px"
                    color="primary.600"
                  >
                    Principal
                  </Flex>
                </Box>
              )}
            </>
          )}
        </Box>
      )}

      <DrawerInformacoesImagem
        isOpen={isOpenDetalhesImagem}
        dataImagem={{
          imagemId: imagem.id || '',
          produtoCorId: imagem.produtoCorId,
          idVariacao: imagem.idVariacao || '',
        }}
        linkImagem={imagem.imagem}
        onClose={() => setIsOpenDetalhesImagem(false)}
      />
      <Box h="10px" />
    </>
  );
};
