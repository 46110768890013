import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Flex,
  useMediaQuery,
  Box,
  Image,
  Text,
  ModalHeader,
  Divider,
  Icon,
  VStack,
  ModalFooter,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FiChevronLeft } from 'react-icons/fi';

import ImagemExemploErrada from 'assets/images/integracoes/smart_pos/exemploImpressaoErrada.png';
import ImagemExemploCorreta from 'assets/images/integracoes/smart_pos/exemploImpressaoCorreta.png';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { SenhaConferidaIcon, SenhaErroIcon } from 'icons';

type ModalImagemExemploProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalProps> & {
    handleDownloadImagemExemplo: () => void;
  };
export const ModalImagemExemplo = create<ModalImagemExemploProps, ModalProps>(
  ({ onResolve, onReject, handleDownloadImagemExemplo, ...rest }) => {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? '3xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
      >
        <ModalContent
          bg="gray.50"
          h={['full', 'full', '480px']}
          w={['full', 'full', '640px']}
        >
          <ModalHeader pb="0" pt="24px" pl="40px">
            <Text color="primary.50" alignItems="center" fontSize="16px">
              <Icon
                onClick={onClose}
                as={FiChevronLeft}
                display={['inline', 'none', 'none']}
                mr="20px"
                cursor="pointer"
                boxSize="20px"
              />
              Personalizar impressão
            </Text>
            <Divider my="5px" display={['', 'none', 'none']} />
            <Text letterSpacing="0" color="gray.700" fontSize="14px">
              Para obter o melhor resultado na impressão térmica, utilize uma
              imagem conforme o exemplo abaixo considerado como “ideal para
              impressão”.
            </Text>
          </ModalHeader>
          <ModalBody
            h="full"
            w="full"
            pl="40px"
            pr="24px"
            pt={['8px', '22px', '22px']}
            pb="0"
          >
            <Flex
              flexDirection={['column', 'row', 'row']}
              h="full"
              justifyContent={['flex-start', 'center', 'center']}
              w="full"
            >
              <VStack w={['full', '50%', '50%']}>
                <Flex
                  justifyContent={['flex-start', 'center', 'center']}
                  w="full"
                >
                  <Image src={ImagemExemploCorreta} />
                </Flex>

                <Flex
                  w="full"
                  justifyContent={['flex-start', 'center', 'center']}
                >
                  <Box px={['5px', '', '']} alignItems="left">
                    <Text w="full" pt="10px">
                      <Icon as={SenhaConferidaIcon} boxSize="16px" />
                      <Text
                        ml="5px"
                        pt="15px"
                        whiteSpace="nowrap"
                        color="aquamarine.600"
                        display="inline"
                      >
                        Ideal para impressão
                      </Text>
                    </Text>
                    <Text
                      pt="5px"
                      letterSpacing="0"
                      color="black"
                      fontSize="14px"
                    >
                      - Imagem em preto e branco;
                    </Text>
                    <Text
                      pt="5px"
                      letterSpacing="0"
                      color="black"
                      fontSize="14px"
                    >
                      - Sem efeitos e degrades;
                    </Text>
                    <Text
                      pb="10px"
                      pt="5px"
                      letterSpacing="0"
                      color="black"
                      fontSize="14px"
                    >
                      - 190px de resolução máxima;
                    </Text>
                    <Button
                      colorScheme="blue"
                      variant="link"
                      fontSize="12px"
                      onClick={() => handleDownloadImagemExemplo()}
                    >
                      Baixar imagem de exemplo
                    </Button>
                  </Box>
                </Flex>
              </VStack>
              <VStack w={['full', '50%', '50%']}>
                <Flex
                  justifyContent={['flex-start', 'center', 'center']}
                  w="full"
                >
                  <Image src={ImagemExemploErrada} />
                </Flex>

                <Flex
                  w="full"
                  alignItems="center"
                  justifyContent={['flex-start', 'center', 'center']}
                >
                  <Box px={['5px', '', '']} alignItems="left">
                    <Text
                      pb="5px"
                      textAlign={['left', 'center', 'center']}
                      w="full"
                      pt="10px"
                      alignItems="baseline"
                    >
                      <Icon as={SenhaErroIcon} boxSize="16px" />
                      <Text
                        whiteSpace="nowrap"
                        pt="15px"
                        color="red.500"
                        ml="5px"
                        display="inline"
                      >
                        Ruim para impressão
                      </Text>
                    </Text>
                    <Text
                      w="191px"
                      letterSpacing="0"
                      pl={['5px', '22px', '22px']}
                      color="black"
                      fontSize="14px"
                    >
                      As cores e degradês não serão reproduzidas pela impressão
                      térmica, tornando a imagem fraca e sem definição.
                    </Text>
                  </Box>
                </Flex>
              </VStack>
            </Flex>
          </ModalBody>
          <ModalFooter display="flex" justifyContent="center">
            <Button
              variant="solid"
              colorScheme="secondary"
              w="96px"
              onClick={onClose}
            >
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
