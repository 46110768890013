const SituacaoTributariaPisCofinsEnum = {
  properties: {
    1: {
      name: '00 - Entrada com recuperação de crédito',
      value: 0,
    },
    2: {
      name: '01 - Entrada tributada com alíquota zero',
      value: 1,
    },
    3: {
      name: '02 - Entrada isenta',
      value: 2,
    },
    4: {
      name: '03 - Entrada não-tributada',
      value: 3,
    },
    5: {
      name: '04 - Entrada imune',
      value: 4,
    },
    6: {
      name: '05 - Entrada com suspensão',
      value: 5,
    },
    7: {
      name: '49 - Outras entradas',
      value: 49,
    },
    8: {
      name: '50 - Saída tributada',
      value: 50,
    },
    9: {
      name: '51 - Saída tributada com alíquota zero',
      value: 51,
    },
    10: {
      name: '52 - Saída isenta',
      value: 52,
    },
    11: {
      name: '53 - Saída não-tributada',
      value: 53,
    },
    12: {
      name: '54 - Saída imune',
      value: 54,
    },
    13: {
      name: '55 - Saída com suspensão',
      value: 55,
    },
    14: {
      name: '99 - Outras saídas',
      value: 99,
    },
  },
};

export default SituacaoTributariaPisCofinsEnum;
