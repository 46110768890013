import { useCallback, useEffect, useRef, useState } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  ModalFooter,
  Box,
  ModalHeader,
  Icon,
  useMediaQuery,
  Divider,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { NumberInput } from 'components/update/Input/NumberInput';
import { LixeiraIcon, SalvarInserirNovoIcon } from 'icons';
import { ModalAplicarComissaoProps } from 'pages/Vendas/MetasComissoes/Types/validationsForm';

type ModalAplicarRegraProps = {
  comissaoPadrao: number;
  quandoAlcancarComissao: number[];
  percentualComissao: number[];
  quandoAlcancarComissaoTotal: number;
  percentualComissaoTotal: number;
};

type ValorAlcancarComissaoProps = {
  id: number | string;
  alcanceComissao: number;
};

type ValorPercentComissaoProps = {
  id: number | string;
  percentualComissao: number;
};

type ModalAplicarRegraComissaoParaTodosProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalAplicarRegraProps> & {
    geradorIdFaixasComissao: number;
    validandoValorObterIdVendedor: string;
    validandoValorMetaTotalVendedor: number;
    vendedoresSemMeta: boolean;
    handleCriarComissaoParaTodosVendedores: (
      valorComissaoPadrao: number,
      novaFaixaComissao: ModalAplicarComissaoProps[]
    ) => Promise<void>;
  };

export const ModalAplicarRegraComissaoParaTodos = create<
  ModalAplicarRegraComissaoParaTodosProps,
  ModalAplicarRegraProps
>(
  ({
    onResolve,
    onReject,
    validandoValorObterIdVendedor,
    validandoValorMetaTotalVendedor,
    geradorIdFaixasComissao,
    vendedoresSemMeta,
    handleCriarComissaoParaTodosVendedores,
    ...rest
  }) => {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const [idFaixasComissao, setIdFaixasComissada] = useState(
      geradorIdFaixasComissao
    );

    const [listaFaixasComissao, setListaFaixasComissao] = useState<
      ModalAplicarComissaoProps[]
    >([]);

    const gerarId = Math.floor(Date.now() * Math.random()).toString(36);
    const formMethods = useForm<ModalAplicarRegraProps>({
      defaultValues: { quandoAlcancarComissaoTotal: 100 },
    });

    const [
      comissaoPadraoWatch,
      quandoAlcancarComissaoWatch,
      percentualComissaoWatch,
      percentualComissaoTotal,
    ] = formMethods.watch([
      'comissaoPadrao',
      'quandoAlcancarComissao',
      'percentualComissao',
      'percentualComissaoTotal',
    ]);
    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    const existeAlcanceMetas = (quandoAlcancarComissaoWatch || []).some(
      (alcanceMetas) => alcanceMetas === 0
    );

    const existeFaixasNulas = useCallback(() => {
      if (listaFaixasComissao.length > 0) {
        if (existeAlcanceMetas) {
          return true;
        }
      }
      return false;
    }, [existeAlcanceMetas, listaFaixasComissao])();

    const idFaixasComissaoRef = useRef(geradorIdFaixasComissao);
    const [
      valorQuandoAlcancarComissao,
      setValorQuandoAlcancarComissao,
    ] = useState<ValorAlcancarComissaoProps[]>([]);
    const [valorPercentualComissao, setValorPercentualComissao] = useState<
      ValorPercentComissaoProps[]
    >([]);

    const handleAdicionarOutraFaixa = useCallback(async () => {
      await setIdFaixasComissada((valorAnterior) => valorAnterior + 1);

      await setListaFaixasComissao((faixaJaAdicionada) => {
        const novaFaixa: ModalAplicarComissaoProps = {
          id: idFaixasComissao,
          idVendedor: validandoValorObterIdVendedor,
          quandoAlcancarPorcentagem: 0,
          valorDaMetaComAlcancePercent: 0,
          percentualComissao: 0,
        };
        return [...faixaJaAdicionada, novaFaixa];
      });

      await formMethods.setValue(
        `quandoAlcancarComissao.${idFaixasComissao}`,
        0
      );
    }, [formMethods, idFaixasComissao, validandoValorObterIdVendedor]);

    const handleAplicarComissao = useCallback(async () => {
      const comissoesAplicarTodos = await [...listaFaixasComissao];
      const novaFaixa = {
        id: gerarId,
        idVendedor: validandoValorObterIdVendedor,
        quandoAlcancarPorcentagem: 100,
        valorDaMetaComAlcancePercent: 0,
        percentualComissao: percentualComissaoTotal,
      };
      const valoresComissaoAlterados = await comissoesAplicarTodos.map(
        (comissao) => {
          return {
            ...comissao,
            quandoAlcancarPorcentagem:
              quandoAlcancarComissaoWatch[Number(comissao.id)],
            percentualComissao: percentualComissaoWatch[Number(comissao.id)],
          };
        }
      );

      await valoresComissaoAlterados.push(novaFaixa);

      await handleCriarComissaoParaTodosVendedores(
        comissaoPadraoWatch,
        valoresComissaoAlterados
      );

      await onClose();
    }, [
      listaFaixasComissao,
      gerarId,
      validandoValorObterIdVendedor,
      percentualComissaoTotal,
      handleCriarComissaoParaTodosVendedores,
      comissaoPadraoWatch,
      onClose,
      quandoAlcancarComissaoWatch,
      percentualComissaoWatch,
    ]);

    const handleObterValueCampoAlcanceMetas = useCallback(
      (valorId: number | string, valorAlcance: number | string) => {
        const novoValorAlcanceMetas = [...valorQuandoAlcancarComissao];
        novoValorAlcanceMetas.splice(Number(valorId), 1, {
          id: valorId,
          alcanceComissao: Number(valorAlcance),
        });
        setValorQuandoAlcancarComissao(novoValorAlcanceMetas);
      },
      [valorQuandoAlcancarComissao]
    );

    const handleObterValueCampoPercentualComissao = useCallback(
      (valorId: number | string, valorPercentComissao: number | string) => {
        const novoPercentualComissao = [...valorPercentualComissao];
        novoPercentualComissao.splice(Number(valorId), 1, {
          id: valorId,
          percentualComissao: Number(valorPercentComissao),
        });
        setValorPercentualComissao(novoPercentualComissao);
      },
      [valorPercentualComissao]
    );

    const handleExcluirFaixa = useCallback(
      async (index: number) => {
        const novoValorAlcanceMetas = [...valorQuandoAlcancarComissao];
        quandoAlcancarComissaoWatch.splice(index, 1);
        novoValorAlcanceMetas.splice(index, 1);
        await setValorQuandoAlcancarComissao(novoValorAlcanceMetas);

        const novoValorPercentualComissao = [...valorPercentualComissao];
        percentualComissaoWatch.splice(index, 1);
        novoValorPercentualComissao.splice(index, 1);
        await setValorPercentualComissao(novoValorPercentualComissao);

        const novaListaComissao = [...listaFaixasComissao];
        novaListaComissao.splice(index, 1);
        await setListaFaixasComissao(novaListaComissao);

        await novoValorAlcanceMetas.forEach((valorAlcanceMetas) => {
          formMethods.setValue(
            `quandoAlcancarComissao.${Number(valorAlcanceMetas.id)}`,
            valorAlcanceMetas.alcanceComissao
          );
        });
        await novoValorPercentualComissao.forEach((valorPercentComissao) => {
          formMethods.setValue(
            `percentualComissao.${Number(valorPercentComissao.id)}`,
            valorPercentComissao.percentualComissao
          );
        });
      },
      [
        formMethods,
        listaFaixasComissao,
        percentualComissaoWatch,
        quandoAlcancarComissaoWatch,
        valorPercentualComissao,
        valorQuandoAlcancarComissao,
      ]
    );

    useEffect(() => {
      idFaixasComissaoRef.current = geradorIdFaixasComissao;
    }, [geradorIdFaixasComissao]);

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? '3xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
      >
        <ModalContent
          bg="gray.50"
          borderRadius="md"
          marginBottom={isLargerThan900 ? '3.75rem' : ''}
          marginTop={isLargerThan900 ? '3.75rem' : ''}
          w={isLargerThan900 ? '800px' : 'full'}
          h={isLargerThan900 ? '480px' : 'full'}
        >
          <ModalHeader p="0">
            <Flex
              pl="24px"
              justifyContent="space-between"
              mb="16px"
              mt="16px"
              w="90%"
            >
              <Text fontSize="16px" color="primary.50">
                Aplicar a mesma regra de comissão para todos
              </Text>
            </Flex>
          </ModalHeader>
          <FormProvider {...formMethods}>
            <ModalBody
              maxH={isLargerThan900 ? '68%' : '400px'}
              h="full"
              position="relative"
              p="0"
            >
              <Box
                h="full"
                w="100%"
                left="0"
                opacity="0.3"
                top="0"
                bg="#CCCCCC"
                position="absolute"
              />
              <Box
                left="0"
                top="0"
                position="absolute"
                w="100%"
                h="100%"
                pl="20px"
                pr="20px"
                overflowY="scroll"
                sx={{
                  '&::-webkit-scrollbar': {
                    height: '0',
                    width: '0',
                  },
                  '& .virtualized_List::-webkit-scrollbar': {
                    height: '0',
                    width: '0',
                  },
                }}
              >
                <Flex
                  alignItems="center"
                  justifyContent="left"
                  ml={isLargerThan900 ? '20px' : '10px'}
                  mt="15px"
                  mb="15px"
                  w="100%"
                  color="gray.700"
                >
                  <Text mr="10px" fontSize="16px">
                    Comissão padrão:
                  </Text>
                  <Box w="80px">
                    <NumberInput
                      widhtPorcentagem="20"
                      w="full"
                      h="40px"
                      id="comissaoPadrao"
                      rightElementPorcentagem="%"
                      name="comissaoPadrao"
                      scale={2}
                    />
                  </Box>
                </Flex>
                <Divider w="full" />
                <Box mb="15px">
                  <Flex
                    alignItems="center"
                    justifyContent="left"
                    direction={isLargerThan900 ? 'row' : 'column'}
                    w="100%"
                    ml={isLargerThan900 ? '20px' : '10px'}
                    pr="20px"
                    mt="15px"
                    mb="10px"
                    color="gray.700"
                  >
                    <Flex
                      w={isLargerThan900 ? '' : 'full'}
                      alignItems="center"
                      justifyContent="left"
                    >
                      <Text fontSize="16px" mr="10px">
                        Quando alcançar:
                      </Text>

                      <Box w="80px" mr="10px">
                        <NumberInput
                          widhtPorcentagem="20"
                          h="40px"
                          bg="white"
                          id="quandoAlcancarComissaoTotal"
                          isDisabled
                          rightElementPorcentagem="%"
                          name="quandoAlcancarComissaoTotal"
                          scale={0}
                          defaultValue={100}
                        />
                      </Box>
                    </Flex>
                    <Flex
                      direction={isLargerThan900 ? 'row' : 'column'}
                      w={isLargerThan900 ? '' : 'full'}
                      alignItems="center"
                      justifyContent="left"
                    >
                      <Flex
                        w={isLargerThan900 ? '' : 'full'}
                        mb={isLargerThan900 ? '' : '10px'}
                        alignItems="center"
                        justifyContent="left"
                      >
                        <Text mr="10px" fontSize="16px">
                          da meta, o percentual da comissão será de
                        </Text>
                      </Flex>
                      <Flex
                        w={isLargerThan900 ? '' : 'full'}
                        alignItems="center"
                        justifyContent="left"
                      >
                        <Box w="80px">
                          <NumberInput
                            widhtPorcentagem="20"
                            h="40px"
                            isDisabled={vendedoresSemMeta}
                            id="percentualComissaoTotal"
                            rightElementPorcentagem="%"
                            name="percentualComissaoTotal"
                            scale={2}
                          />
                        </Box>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Divider w="full" />
                </Box>
                {listaFaixasComissao.map((faixa, index) => (
                  <Box mb="15px">
                    <Flex
                      alignItems="center"
                      justifyContent="left"
                      direction={isLargerThan900 ? 'row' : 'column'}
                      w="100%"
                      ml={isLargerThan900 ? '20px' : '10px'}
                      pr="20px"
                      mt="15px"
                      mb="10px"
                      color="gray.700"
                    >
                      <Flex
                        w={isLargerThan900 ? '' : 'full'}
                        alignItems="center"
                        justifyContent="left"
                      >
                        <Text fontSize="16px" mr="10px">
                          Quando alcançar:
                        </Text>

                        <Box w="100px" mr="10px">
                          <NumberInput
                            widhtPorcentagem="20"
                            h="40px"
                            onValueChange={(valorAlcance) =>
                              handleObterValueCampoAlcanceMetas(
                                faixa.id,
                                valorAlcance
                              )
                            }
                            bg="white"
                            id={`quandoAlcancarComissao.${faixa.id}`}
                            rightElementPorcentagem="%"
                            name={`quandoAlcancarComissao.${faixa.id}`}
                            scale={2}
                            defaultValue={100}
                          />
                        </Box>
                      </Flex>
                      <Flex
                        direction={isLargerThan900 ? 'row' : 'column'}
                        w={isLargerThan900 ? '' : 'full'}
                        alignItems="center"
                        justifyContent="left"
                      >
                        <Flex
                          w={isLargerThan900 ? '' : 'full'}
                          mb={isLargerThan900 ? '' : '10px'}
                          alignItems="center"
                          justifyContent="left"
                        >
                          <Text mr="10px" fontSize="16px">
                            da meta, o percentual da comissão será de
                          </Text>
                        </Flex>
                        <Flex
                          w={isLargerThan900 ? '' : 'full'}
                          alignItems="center"
                          justifyContent="left"
                        >
                          <Box w="80px">
                            <NumberInput
                              widhtPorcentagem="20"
                              h="40px"
                              onValueChange={(valorPercentComissao) =>
                                handleObterValueCampoPercentualComissao(
                                  faixa.id,
                                  valorPercentComissao
                                )
                              }
                              id={`percentualComissao.${faixa.id}`}
                              rightElementPorcentagem="%"
                              name={`percentualComissao.${faixa.id}`}
                              scale={2}
                            />
                          </Box>

                          <Icon
                            boxSize="19px"
                            cursor="pointer"
                            ml="20px"
                            onClick={() => handleExcluirFaixa(index)}
                            as={LixeiraIcon}
                          />
                        </Flex>
                      </Flex>
                    </Flex>
                    <Divider w="full" />
                  </Box>
                ))}
                <Button
                  color="blue.700"
                  ml="20px"
                  isDisabled={vendedoresSemMeta}
                  mt="15px"
                  mb="20px"
                  onClick={() => handleAdicionarOutraFaixa()}
                  leftIcon={<Icon as={SalvarInserirNovoIcon} fontSize="lg" />}
                  variant="link"
                >
                  Adicionar outra faixa
                </Button>
              </Box>
            </ModalBody>
            <ModalFooter>
              <Flex
                w="full"
                h="full"
                justifyContent="center"
                alignItems="baseline"
              >
                <Button
                  id="gerarCredito"
                  name="gerarCredito"
                  color="gray.300"
                  variant="outline"
                  borderRadius="20px"
                  fontSize="sm"
                  type="button"
                  h="32px"
                  mr="24px"
                  onClick={async () => {
                    await setValorPercentualComissao([]);
                    await setValorQuandoAlcancarComissao([]);
                    onClose();
                  }}
                  _hover={{ bg: 'gray.50' }}
                  w="96px"
                >
                  Cancelar
                </Button>
                <Button
                  id="gerarCredito"
                  name="gerarCredito"
                  color="white"
                  variant="sucess"
                  bg="aquamarine.600"
                  borderRadius="20px"
                  fontSize="sm"
                  isDisabled={existeFaixasNulas || !comissaoPadraoWatch}
                  type="button"
                  h="32px"
                  onClick={() => handleAplicarComissao()}
                  w="96px"
                >
                  Aplicar
                </Button>
              </Flex>
            </ModalFooter>
          </FormProvider>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
