import { useState, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import { UncontrolledForm } from '..';
import { useDepartamentoForm } from '../hooks';
import { FormData, defaultValues, yupResolver } from '../validationForm';

const CadastrarDepartamento = () => {
  const { handleToDepartments } = useDepartamentoForm();

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues,
  });

  const { handleSubmit: onSubmit, reset } = formMethods;

  const [isLoading, setIsLoading] = useState(false);

  const registerDepartment = useCallback(async (data: FormData) => {
    const incorrectMainAccountRange =
      data.numeracaoInicialContaPrincipal > data.numeracaoFinalContaPrincipal;

    const incorrectSubAccountRange =
      data.numeracaoInicialSubConta > data.numeracaoFinalSubConta;

    if (incorrectMainAccountRange || incorrectSubAccountRange) {
      toast.warning(
        ` O valor inicial da ${
          incorrectMainAccountRange ? 'conta principal' : 'subconta'
        } deve ser equivalente ou menor que o valor final`
      );

      return { success: false };
    }

    const contasBloqueadas = data?.contasBloqueadas.map(
      (contaBloqueada) => contaBloqueada.value
    );

    const response = await api.post<void, ResponseApi>(
      ConstanteEnderecoWebservice.DEPARTAMENTO,
      { ...data, contasBloqueadas }
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response?.sucesso) {
        toast.success('Departamento cadastrado com sucesso.');
        return { success: true };
      }
    }

    return { success: false };
  }, []);

  const handleSave = onSubmit(async (data) => {
    setIsLoading(true);

    const { success } = await registerDepartment(data);

    if (success) {
      handleToDepartments();
    }

    setIsLoading(false);
  });

  const handleSaveAndReset = onSubmit(async (data) => {
    setIsLoading(true);

    const { success } = await registerDepartment(data);

    if (success) {
      reset({ ...defaultValues });
    }

    setIsLoading(false);
  });

  return (
    <FormProvider {...formMethods}>
      <UncontrolledForm
        handleSave={handleSave}
        handleSaveAndReset={handleSaveAndReset}
        isLoading={isLoading}
      />
    </FormProvider>
  );
};

export { CadastrarDepartamento };
