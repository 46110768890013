import React, { useRef } from 'react';
import {
  FlexProps,
  Box,
  useMediaQuery,
  Flex,
  Text,
  Button,
  Icon,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { GrClose } from 'react-icons/gr';
import { BsChevronLeft } from 'react-icons/bs';

import ConstanteRotas from 'constants/rotas';
import { useZoopEtapasContext } from 'store/Zoop/ZoopEtapasContext';
import { enumEtapasZoop } from 'constants/enum/enumEtapasZoop';

interface ContainerZoopProps extends FlexProps {
  children: React.ReactNode;
  height?: string;
}

type RefHandleVoltarProps = {
  handle?: () => void;
};

export const ContainerIntegracaoFixo = ({
  children,
  height = '100vh',
}: ContainerZoopProps) => {
  const refHandleVoltar = useRef<RefHandleVoltarProps>(null);

  const [isLargerThan600] = useMediaQuery('(min-height: 520px)');
  const [isSmallerThan900] = useMediaQuery('(max-width: 600px)');

  const { activeStep } = useZoopEtapasContext();

  const history = useHistory();

  const handleVoltar = () => {
    if (refHandleVoltar.current?.handle) {
      refHandleVoltar.current?.handle();
    }
  };

  const handleSair = () => {
    history.push(ConstanteRotas.LOJA_APLICATIVOS);
  };

  return (
    <Flex
      pl={['10px', '10px', '40px']}
      bg="gray.50"
      pr={['10px', '10px', '40px']}
      w="full"
      flexDir="column"
      pt="24px"
      minH="100vh"
      h="full"
      alignItems="center"
      position="relative"
    >
      <Flex
        pb="24px"
        alignItems="center"
        w="full"
        h="51px"
        justifyContent="space-between"
      >
        {!isSmallerThan900 ? (
          <Text color="primary.50" fontSize="18px" fontWeight="semibold">
            Guia de integração {'>'} Zoop
          </Text>
        ) : (
          <Flex alignItems="center">
            <Button
              onClick={handleVoltar}
              variant="linkDefault"
              colorScheme="gray"
            >
              <Icon boxSize="20px" as={BsChevronLeft} />
            </Button>
            <Text color="gray.700" fontSize="14px">
              Voltar
            </Text>
          </Flex>
        )}

        <Button onClick={handleSair} variant="linkDefault" colorScheme="gray">
          <Icon
            boxSize="20px"
            as={GrClose}
            sx={{
              path: {
                stroke: '#707070',
              },
            }}
          />
        </Button>
      </Flex>

      <Box
        maxW={activeStep === enumEtapasZoop.GUIA_INTEGRAÇÃO ? '800px' : '600px'}
      >
        <Box
          overflowY="auto"
          mb={!isLargerThan600 ? '20px' : undefined}
          h={`calc(${!isLargerThan600 ? '100%' : height} - 151px)`}
        >
          {children}
        </Box>
      </Box>
    </Flex>
  );
};
