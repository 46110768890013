import React from 'react';
import { Portal, PortalProps } from '@chakra-ui/react';

import { useFullScreenContext } from 'store/FullScreen';

interface PortalFullscreenProps extends PortalProps {
  children: React.ReactNode;
}

const PortalFullscreen = ({
  children,
  appendToParentPortal,
}: PortalFullscreenProps) => {
  const { handleFullScreen } = useFullScreenContext();

  return (
    <Portal
      containerRef={handleFullScreen.node}
      appendToParentPortal={appendToParentPortal}
    >
      {children}
    </Portal>
  );
};

export default PortalFullscreen;
