import { Box, BoxProps } from '@chakra-ui/react';

type CardContainerProps = BoxProps & {
  children: React.ReactNode;
};

export const CardContainer = ({ children, ...props }: CardContainerProps) => {
  return (
    <Box
      borderRadius="5px"
      boxShadow="0px 0px 6px 0px rgba(0, 0, 0, 0.2)"
      {...props}
    >
      {children}
    </Box>
  );
};
