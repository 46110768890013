import { useState } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  ModalFooter,
  Box,
  ModalHeader,
  useMediaQuery,
  Divider,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { ButtonDefault } from 'components/Button/ButtonChakra';
import { NumberInput } from 'components/update/Input/NumberInput';

type FormData = {
  markup: number;
};

type ModalPadronizarMarkupProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<FormData> & {
    padronizarMarkup: (valueMarkup: number) => void;
  };

export const ModalPadronizarMarkup = create<
  ModalPadronizarMarkupProps,
  FormData
>(({ onResolve, onReject, padronizarMarkup, ...rest }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const formMethods = useForm<FormData>();

  const { handleSubmit: onSubmit } = formMethods;

  const handleSubmit = onSubmit((data) => {
    setIsLoading(true);
    padronizarMarkup(data.markup);
    setIsLoading(false);
    onClose();
  });

  return (
    <ModalPadraoChakra
      isCentered={isLargerThan900}
      size={isLargerThan900 ? 'xl' : 'full'}
      {...rest}
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={false}
    >
      <ModalContent
        bg="gray.50"
        borderRadius={isLargerThan900 ? 'md' : '0'}
        marginBottom={isLargerThan900 ? '3.75rem' : '0'}
        marginTop={isLargerThan900 ? '3.75rem' : '0'}
        w={isLargerThan900 ? '400px' : 'full'}
        h={isLargerThan900 ? '296px' : 'full'}
      >
        <ModalHeader pt="16px" pb="28px" pl="24px">
          <Text color="primary.50" fontSize="16px">
            Padronizar o mesmo markup
          </Text>
          <Text color="gray.700" fontSize="12px">
            O valor será aplicado para todos os produtos da lista.
          </Text>
          <Divider mt="16px" />
        </ModalHeader>

        <FormProvider {...formMethods}>
          {isLoading && <LoadingPadrao />}
          <ModalBody pl="24px" pr="24px" mb="20px" pt="0" pb="0">
            <Flex justifyContent="center" alignItems="center" h="full" w="full">
              <Box w={['full', 'full', '209px']}>
                <NumberInput
                  leftElementColor="gray.700"
                  id="markup"
                  label="Markup"
                  editarFundoLeftElemento
                  labelColor="black"
                  fontWeightLabel="bold"
                  bgLeftElement="gray.50"
                  leftElement="%"
                  scale={4}
                  name="markup"
                />
              </Box>
            </Flex>
          </ModalBody>
          <ModalFooter flexDirection="column" mb="40px">
            <Divider mb="24px" />
            <Flex
              w="full"
              h="full"
              justifyContent="center"
              alignItems="baseline"
            >
              <Button
                color="gray.300"
                variant="outlineDefault"
                borderRadius="20px"
                borderColor="gray.300"
                fontSize="sm"
                lineHeight="0"
                type="button"
                h={isLargerThan900 ? '32px' : '40px'}
                mr="24px"
                onClick={() => onClose()}
                w="96px"
              >
                Cancelar
              </Button>
              <ButtonDefault
                color="black"
                variant="solid"
                colorScheme="secondary"
                lineHeight="0"
                borderRadius="20px"
                fontSize="sm"
                h={isLargerThan900 ? '32px' : '40px'}
                width="120px"
                onClick={() => handleSubmit()}
                isLoading={isLoading}
              >
                Confirmar
              </ButtonDefault>
            </Flex>
          </ModalFooter>
        </FormProvider>
      </ModalContent>
    </ModalPadraoChakra>
  );
});
