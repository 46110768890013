import { useEffect, useCallback, MutableRefObject, RefObject } from 'react';
import {
  focusFirstTabbable,
  focusLastTabbable,
  focusNextTabbable,
  focusPreviousTabbable,
} from './dom-query';

function useAccessibleTabulation(
  container?: RefObject<HTMLElement> | MutableRefObject<HTMLElement> | null
) {
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (container && container.current) {
        const { key } = event;

        switch (key) {
          case 'Down': // IE/Edge specific value
          case 'ArrowDown':
            event.preventDefault();

            focusNextTabbable(container.current);
            break;
          case 'Up': // IE/Edge specific value
          case 'ArrowUp':
            event.preventDefault();

            focusPreviousTabbable(container.current);
            break;
          case 'Home':
            event.preventDefault();

            focusFirstTabbable(container.current);
            break;
          case 'End':
            event.preventDefault();

            focusLastTabbable(container.current);
            break;
          default:
        }
      }
    },
    [container]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false);
    return () => {
      document.removeEventListener('keydown', handleKeyDown, false);
    };
  }, [handleKeyDown]);
}

export { useAccessibleTabulation };
