const ModalidadesBaseCalculoIcmsStEnum = {
  PrecoTabelado: 0,
  ListaNegativa: 1,
  ListaPositivo: 2,
  ListaNeutra: 3,
  MargemValorAgregado: 4,
  Pauta: 5,
  ValorOperacao: 6,

  properties: {
    1: { name: 'Lista negativa (valor)', value: 1 },
    2: { name: 'Lista neutra (valor)', value: 3 },
    3: { name: 'Lista positiva (valor)', value: 2 },
    4: { name: 'Margem valor agregado (%)', value: 4 },
    5: { name: 'Pauta (valor)', value: 5 },
    6: { name: 'Preço tabelado ou máximo sugerido', value: 0 },
    7: { name: 'Valor da operação', value: 6 },
  },
};

export default ModalidadesBaseCalculoIcmsStEnum;
