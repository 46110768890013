export const enumTipoDataImpressaoEtiqueta = {
  CADASTRADOS: 1,
  ALTERADOS: 2,

  properties: [
    { label: 'Produtos cadastrados', value: 1 },
    { label: 'Produtos alterados', value: 2 },
  ],
};

export default enumTipoDataImpressaoEtiqueta;
