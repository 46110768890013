import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import { isValidCnpj } from 'helpers/validation/IsValidCpfCnpj';

const schema = yup.object().shape({
  cnpjAdquirente: yup
    .string()
    .test('cnpj', ConstanteMensagemValidacao.CNPJ_INVALIDO, (value) => {
      if (value) return isValidCnpj(value);

      return true;
    })
    .max(18, ConstanteMensagemValidacao.MAX_LENGTH_18),
  valorAfrmm: yup
    .number()
    .default(0)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  numeroDocumento: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  codigoExportador: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  dataRegistro: yup
    .date()
    .nullable()
    .default(null)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  dataDesembaraco: yup
    .date()
    .nullable()
    .default(null)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  viaTransporte: yup
    .number()
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  formaImportacao: yup
    .number()
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  localDesembaraco: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  ufDesembaraco: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);

export const useForm = useFormInstance;
