import React from 'react';
import {
  Box,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  useMediaQuery,
  VStack,
  Flex,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { useConsultarOperacoesContext } from 'store/PDV/ConsultarOperacoes';
import { SubstituirParametroRota } from 'constants/rotas';
import ConstanteRotasPDV from 'constants/rotasPDV';

import Layout from 'components/PDV/Layout';
import Paginacao from 'components/PDV/Geral/Paginacao';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import ConsultarOperacoesInternalHeader from './InternalHeader';
import OperacaoItem from './OperacaoItem';

const ConsultarOperacoes: React.FC = () => {
  const history = useHistory();
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const {
    operacoes,
    gridPaginadaConsulta: { pageSize, currentPage },
    handleChangePageSize,
    handleChangeCurrentPage,
    totalCount,
    lastPage,
    isLoading,
  } = useConsultarOperacoesContext();

  const handleOpenOperacao = (
    operacaoId: string,
    chavePermissaoTemporaria?: string
  ) => {
    history.push(
      SubstituirParametroRota(
        ConstanteRotasPDV.PDV_LANCAMENTO_ID,
        'id?',
        operacaoId
      ),
      {
        chavePermissaoTemporaria,
      }
    );
  };

  return (
    <Layout NavHeaderContent={<ConsultarOperacoesInternalHeader />}>
      <Box h="full" w="full" position="relative">
        {isLoading && <LoadingPadrao />}
        <Box px={[6, 8, 8, 28, 56]} pt={{ base: 6, sm: 8 }}>
          {isLargerThan900 ? (
            <Table size="sm" variant="simple-card">
              <Thead>
                <Tr>
                  <Th>Operação</Th>
                  <Th>Número</Th>
                  <Th>Data</Th>
                  <Th>Cliente</Th>
                  <Th>Situação</Th>
                  <Th>Valor</Th>
                  <Th textAlign="center">Ações</Th>
                </Tr>
              </Thead>
              <Tbody>
                {operacoes &&
                  operacoes.length > 0 &&
                  operacoes.map((pedido, index) => (
                    <OperacaoItem
                      operacao={pedido}
                      index={index}
                      handleOpenOperacao={handleOpenOperacao}
                      isTr
                    />
                  ))}
                {!isLoading && (!operacoes || operacoes.length === 0) && (
                  <Tr>
                    <Td colSpan={7}>
                      Não encontramos nenhuma operação para a sua busca.
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          ) : (
            <VStack spacing={2} w="full">
              {operacoes &&
                operacoes.length > 0 &&
                operacoes.map((pedido, index) => (
                  <OperacaoItem
                    operacao={pedido}
                    index={index}
                    isTr={false}
                    handleOpenOperacao={handleOpenOperacao}
                  />
                ))}
              {!isLoading && (!operacoes || operacoes.length === 0) && (
                <Flex
                  alignItems="center"
                  px={6}
                  py={3}
                  bg="gray.50"
                  borderRadius="md"
                  boxShadow="md"
                  w="full"
                >
                  Não encontramos nenhuma operação para a sua busca.
                </Flex>
              )}
            </VStack>
          )}
          <Paginacao
            pageSize={pageSize}
            handleChangePageSize={handleChangePageSize}
            currentPage={currentPage}
            lastPage={lastPage}
            handleChangeCurrentPage={handleChangeCurrentPage}
            itemsTotalCount={totalCount}
          />
        </Box>
      </Box>
    </Layout>
  );
};

export default ConsultarOperacoes;
