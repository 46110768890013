import React, {
  ForwardRefRenderFunction,
  useImperativeHandle,
  forwardRef,
  useState,
  useCallback,
} from 'react';
import { Flex, Container, LayoutProps } from '@chakra-ui/react';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ContatoEmpresa from 'constants/contato';

import Cabecalho from './Divisoes/cabecalho';
import Devolucao from './Divisoes/devolucoes';
import Produtos from './Divisoes/produtos';
import Totais from './Divisoes/totais';
import { FooterDevolucaoDinheiro } from './Divisoes/footer';

interface ImpressaoCupomNaoFiscal {
  maxWidth?: LayoutProps['maxW'];
  operacaoId?: string;
  containerRef?: React.RefObject<HTMLDivElement>;
}

export interface ImpressaoDevolucaoDinheiroProps {
  obterInformacoesImpressao: () => Promise<void>;
}

type DevolucaoItensProps = {
  nome: string;
  quantidade: number;
  valorUnitario: number;
  desconto: number;
  valorTotal: number;
};

interface ObterInformacoes {
  nomeFantasia: string;
  usuario: 'string';
  cliente: string;
  formaDevolucao: string;
  numeroOperacaoDevolucao: number;
  numeroOperacaoOrigem: number;
  devolucaoItens: DevolucaoItensProps[];
}

const ImpressaoDevolucaoDinheiro: ForwardRefRenderFunction<
  ImpressaoDevolucaoDinheiroProps,
  ImpressaoCupomNaoFiscal
> = ({ maxWidth, operacaoId, containerRef }, ref) => {
  const [
    devolucaoDinheiro,
    setIsDevolucaoDinheiro,
  ] = useState<ObterInformacoes>({} as ObterInformacoes);

  const obterInformacoesImpressao = useCallback(async () => {
    const response = await api.get<void, ResponseApi<ObterInformacoes>>(
      ConstanteEnderecoWebservice.DEVOLUCAO_DINHEIRO_OBTER_PARA_COMPARTILHAR,
      {
        params: { operacaoId },
      }
    );

    if (response?.sucesso) {
      setIsDevolucaoDinheiro(response.dados);
    }
  }, [operacaoId]);

  useImperativeHandle(ref, () => {
    return {
      obterInformacoesImpressao,
    };
  });

  const DataEmissao = String(new Date());
  const total = devolucaoDinheiro.devolucaoItens?.reduce((acc, produto) => {
    return acc + produto.valorTotal;
  }, 0);

  return (
    <Container
      sx={{
        '& div,': {
          color: 'black',
          fontWeight: '900',
          fontSize: '10px',
          fontFamily: 'arial',
        },
        '& div, & hr': {
          opacity: 'unset',
          borderColor: 'black',
        },
      }}
      minW="100%"
      ref={containerRef}
    >
      <Flex minW="58mm" maxW={maxWidth} direction="column" h="auto" pt="3">
        <Cabecalho nomeFantasia={devolucaoDinheiro.nomeFantasia} />
        <Devolucao
          numeroOperacao={devolucaoDinheiro.numeroOperacaoDevolucao}
          numeroOperacaoOrigem={devolucaoDinheiro.numeroOperacaoOrigem}
        />
        <Produtos produto={devolucaoDinheiro.devolucaoItens} />
        <Totais valorTotal={total} itens={devolucaoDinheiro.devolucaoItens} />
      </Flex>
      <FooterDevolucaoDinheiro
        contatoEmpresa={ContatoEmpresa.CONTATO}
        formaRecebimento={devolucaoDinheiro.formaDevolucao}
        nomeCliente={devolucaoDinheiro.cliente}
        usuario={devolucaoDinheiro.usuario}
        dataEmissao={DataEmissao}
      />
    </Container>
  );
};

export default forwardRef(ImpressaoDevolucaoDinheiro);
