import React from 'react';
import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import Dashboard from '../pages/Dashboard';

const DashboardRoutes = [
  <LayoutGuard
    key="dashboard"
    component={Dashboard}
    breadcrumb={[
      {
        title: 'Dashboard',
      },
    ]}
    path={['', ConstanteRotas.DASHBOARD]}
    exact
    meta={{ auth: true }}
  />,
];

export default DashboardRoutes;
