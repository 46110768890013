import { toast } from 'react-toastify';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { ImprimirPDF } from 'helpers/impressoes/imprimirPDF';
import api, { ResponseApi } from 'services/api';

import { ModalCompartilhar } from 'components/Modal/ModalCompartilhar';
import { EmailModal } from 'components/PDV/CompartilharDocumentosFiscais/EmailModal';

type FiltersProps = {
  [key: string]: string | number | boolean | Date | null;
};

export const envioEmailRelatorioPdf = async (
  dados: FiltersProps,
  emails: string[]
) => {
  const response = await api.post<void, ResponseApi<boolean>>(
    `${
      ConstanteEnderecoWebservice.CONCILIACAO_RELATORIO_EMAIL
    }?${new URLSearchParams(Object(dados)).toString()}`,
    emails
  );
  if (response) {
    if (response?.sucesso) {
      toast.success('O e-mail foi enviado com sucesso.');
    }

    if (response?.avisos) {
      response.avisos.forEach((item) => toast.warning(item));
    }
  }
};

export const imprimirRelatorioPdf = async (dados: FiltersProps) => {
  const response = await api.get<void, ResponseApi<string>>(
    ConstanteEnderecoWebservice.CONCILIACAO_RELATORIO_LISTAGEM,
    {
      params: dados,
    }
  );
  if (response) {
    if (response.sucesso && response.dados) {
      ImprimirPDF(response.dados, 'relatorioConciliacao');
    }

    if (response?.avisos) {
      response.avisos.forEach((item) => toast.warning(item));
    }
  }
};

export const compartilharRelatorio = async (dados: FiltersProps) => {
  ModalCompartilhar({
    items: [
      {
        titulo: 'Relatório conciliação de contas',
        onClickImpressao: async () => {
          await imprimirRelatorioPdf(dados);
        },
        onClickEmail: async () => {
          await EmailModal({
            getEmail: async (emails) => {
              envioEmailRelatorioPdf(dados, emails);
            },
          });
        },
      },
    ],
  });
};
