import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { pushExternalUrl } from 'helpers/layout/pushExternalUrl';
import auth from 'modules/auth';

const movideskHost = 'atendimento.zendar.com.br';

export async function generateTemporaryToken(username: string) {
  const response = await api.post<void, ResponseApi<string>>(
    ConstanteEnderecoWebservice.MOVIDESK_GERAR_TOKEN_TEMPORARIO,
    { usuario: username }
  );

  if (response) {
    if (response.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (response.sucesso) {
      return response.dados;
    }
  }

  return undefined;
}

interface PushPrivateAccessUrlProps {
  url: string;
  token: string;
  target?: string;
}

export function pushPrivateAccessUrl({
  url: propUrl,
  token,
  target = '_blank',
}: PushPrivateAccessUrlProps) {
  const url = `https://${movideskHost}/ExternalLoginToken/AccessUrl?loginToken=${token}&url=${propUrl}`;

  pushExternalUrl(url, target);
}

export function getMovideskChatUrl() {
  const { email, unique_name: uniqueName, userId } = auth.getDadosToken() || {};
  const { id: lojaId } = auth.getLoja() || {};

  const url = `https://chat.movidesk.com/ChatWidget/Login/8E60A35377C648D2944773DB5CB8CDA5?Name=${encodeURIComponent(
    uniqueName
  )}&Email=${encodeURIComponent(email)}&CodeReference=${encodeURIComponent(
    userId
  )}&OrganizationCodeReference=${encodeURIComponent(
    lojaId
  )}&StayConnected=false&EmptySubject=true&StartChat=false`;

  return url;
}
