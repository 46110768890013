import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import { TipoOcorrenciaEnum } from 'constants/enum/tipoOcorrencia';

type PlanoConta = {
  value: string;
  label: string;
  tipoPlanoConta: number;
};

export type Parcelas = {
  descricao: string;
  qtdParcelas: number;
  id: string;
};

export type FormaPagamento = {
  value: string;
  label: string;
  lancarFatura: boolean;
};

type ClienteFornecedor = {
  value: string;
  label: string;
};

type ContaFinanceira = {
  value: string;
  label: string;
};

type QtdParcela = {
  value: string;
  label: string;
  qtdParcelas: number;
};

type FormaRecebimento = {
  value: string;
  label: string;
};

type Parcela = {
  parcela: string;
  vencimento: Date;
  valor: number;
};

export type FormData = {
  tipoOcorrencia: number;
  planoConta: PlanoConta;
  valorTotal: number;
  formaPagamento: FormaPagamento;
  clienteFornecedor: ClienteFornecedor;

  dataVencimento?: Date;
  quantidadeParcelas?: number;
  dataCompetencia?: Date;
  tipoCompetencia: number;

  numeroDocumento: string;
  dataLancamento: Date;
  data: Date;
  historico: string;
  juros: number;
  multa: number;
  desconto: number;
  valorTotalBaixa: number;
  dataBaixa?: Date;
  formaRecebimento: FormaRecebimento;
  qtdParcela: QtdParcela;
  contaFinanceira: ContaFinanceira;
  repeticoes: number;

  parcelas: Parcela[];

  baixarConta: boolean;

  faturaCartaoCredito: Date;
};

const schema = yup.object().shape({
  tipoOcorrencia: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  planoConta: yup
    .object()
    .shape({
      value: yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
      label: yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
    })
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  valorTotal: yup
    .number()
    .moreThan(0, ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  formaPagamento: yup
    .object({
      value: yup.string(),
      label: yup.string(),
    })
    .when('label', {
      is: (label) => Boolean(label) === false,
      then: yup.object({
        value: yup
          .string()
          .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
        label: yup
          .string()
          .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
      }),
      otherwise: yup.object({
        value: yup
          .string()
          .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
        label: yup
          .string()
          .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
      }),
    }),
  clienteFornecedor: yup
    .object()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  dataVencimento: yup.date().when('tipoOcorrencia', {
    is: (tipoOcorrencia) => tipoOcorrencia === TipoOcorrenciaEnum.PARCELADA,
    then: yup.date().nullable().default(null),
    otherwise: yup
      .date()
      .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  }),
  dataCompetencia: yup.date().when('tipoOcorrencia', {
    is: (tipoOcorrencia) => tipoOcorrencia === TipoOcorrenciaEnum.REPETIR,
    then: yup.date().nullable().default(null),
    otherwise: yup
      .date()
      .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  }),
  tipoCompetencia: yup.number().when('tipoOcorrencia', {
    is: (tipoOcorrencia) => tipoOcorrencia === TipoOcorrenciaEnum.REPETIR,
    then: yup.number().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
    otherwise: yup.number().nullable().default(null),
  }),
  numeroDocumento: yup
    .string()
    .max(20, ConstanteMensagemValidacao.MAX_LENGTH_20)
    .nullable(),
  dataLancamento: yup
    .date()
    .when(['dataVencimento', 'data'], (dataVencimento, data) => {
      const newDataVencimento = new Date(
        `${new Date(dataVencimento).getMonth() + 1}-${new Date(
          dataVencimento
        ).getDate()}-${new Date(dataVencimento).getFullYear()}`
      );

      const newData = new Date(
        `${new Date(data).getMonth() + 1}-${new Date(
          data
        ).getDate()}-${new Date(data).getFullYear()}`
      );

      return newData > newDataVencimento
        ? yup
            .date()
            .max(
              dataVencimento,
              ConstanteMensagemValidacao.DATA_LANCAMENTO_VENCIMENTO
            )
        : yup.date();
    })
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  historico: yup
    .string()
    .max(500, ConstanteMensagemValidacao.MAX_LENGTH_500)
    .nullable(),
  juros: yup.number(),
  multa: yup.number(),
  desconto: yup.number(),
  valorTotalBaixa: yup.number(),
  dataBaixa: yup.date().when(['tipoOcorrencia', 'baixarConta'], {
    is: (tipoOcorrencia, baixarConta) =>
      tipoOcorrencia === TipoOcorrenciaEnum.UNICA && baixarConta,
    then: yup.date().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
    otherwise: yup.date().nullable().default(null),
  }),
  contaFinanceira: yup
    .object()
    .when(['tipoOcorrencia', 'baixarConta'], {
      is: (tipoOcorrencia, baixarConta) =>
        tipoOcorrencia === TipoOcorrenciaEnum.UNICA && baixarConta === true,
      then: yup
        .object()
        .shape({
          value: yup.string(),
          label: yup.string(),
        })
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
      otherwise: yup.object().nullable().default(null),
    })
    .nullable(),
  formaRecebimento: yup.object().when(['tipoOcorrencia', 'baixarConta'], {
    is: (tipoOcorrencia, baixarConta) =>
      tipoOcorrencia === TipoOcorrenciaEnum.UNICA && baixarConta === true,
    then: yup.object().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  }),
  repeticoes: yup.number().when('tipoOcorrencia', {
    is: (tipoOcorrencia) => tipoOcorrencia === TipoOcorrenciaEnum.REPETIR,
    then: yup
      .number()
      .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
      .min(1, ConstanteMensagemValidacao.VALOR_MINIMO_1),
    otherwise: yup.number().default(0),
  }),
  parcelas: yup.array().when(['tipoOcorrencia', 'formaPagamento'], {
    is: (tipoOcorrencia, formaPagamento) =>
      tipoOcorrencia === TipoOcorrenciaEnum.PARCELADA &&
      !formaPagamento?.lancarFatura,
    then: yup.array().of(
      yup.object().shape({
        parcela: yup
          .string()
          .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
        vencimento: yup
          .date()
          .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
        valor: yup
          .number()
          .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
      })
    ),
    otherwise: yup.array().nullable().default(null),
  }),
  baixarConta: yup.boolean(),
});

export const yupResolver = yupResolverInstance(schema);
