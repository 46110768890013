const ViasTransporteEnum = {
  Maritima: 1,
  Fluvial: 2,
  Lacustre: 3,
  Aerea: 4,
  Postal: 5,
  Ferroviaria: 6,
  Rodoviaria: 7,
  CondutoRedeTransmissao: 8,
  MeiosProprios: 9,
  EntradaSaidaFicta: 10,

  properties: {
    1: { name: 'Aérea', value: 4 },
    2: { name: 'Conduto', value: 8 },
    3: { name: 'Entrada/saída ficta.', value: 10 },
    4: { name: 'Ferroviária', value: 6 },
    5: { name: 'Fluvial', value: 2 },
    6: { name: 'Lacustre', value: 3 },
    7: { name: 'Marítima', value: 1 },
    8: { name: 'Meios próprios', value: 9 },
    9: { name: 'Postal', value: 5 },
    10: { name: 'Rodoviária', value: 7 },
  },
};

export default ViasTransporteEnum;
