import React from 'react';
import {
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';

import AlterarCliente from 'pages/ClientesFornecedores/Formulario/Alterar';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { RouteComponentProps } from 'react-router-dom';

interface ModalCadastroClienteProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  clienteIdPdv: string;
  alterarClienteCallback: (cliente: any) => void;
  chavePermissaoTemporaria?: string;
}

const ModalAlterarCliente = ({
  isOpen,
  setIsOpen,
  clienteIdPdv,
  alterarClienteCallback,
  chavePermissaoTemporaria,
}: ModalCadastroClienteProps) => {
  const cadastroSucessoCallback = (cliente: any) => {
    alterarClienteCallback(cliente);
    setIsOpen(false);
  };

  const propsRoute = {} as RouteComponentProps<any>;

  return (
    <ModalPadraoChakra
      onClose={() => setIsOpen(false)}
      size="full"
      isOpen={isOpen}
    >
      <ModalContent margin={{ base: 0, sm: '3.75rem' }}>
        <ModalHeader>Alterar Cliente</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <AlterarCliente
            clienteIdPdv={clienteIdPdv}
            cadastroPdv
            rotaCliente
            cadastroSucessoCallback={cadastroSucessoCallback}
            chavePermissaoTemporaria={chavePermissaoTemporaria}
            {...propsRoute}
          />
        </ModalBody>
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export default ModalAlterarCliente;
