import {
  Text,
  Box,
  Icon,
  Flex,
  Button,
  GridItem,
  FormLabel,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { FiChevronLeft, FiFile } from 'react-icons/fi';
import { toast } from 'react-toastify';

import { SalvarInserirNovoIcon } from 'icons';
import Input from 'components/PDV/Input';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import CheckBoxAtivoInativo from 'components/update/Checkbox/CheckboxAtivoInativo';
import SelectField from 'components/PDV/Select/SelectPadrao';
import { ContainerHeader } from 'pages/Integracoes/LojaAplicativos/Layout/ContainerHeader';

import useRelatorioPersonalizadoForm from './hooks';

type UncontrolledFormProps = {
  isLoading?: boolean;
  readonly?: boolean;
  handleSalvar?: () => Promise<void>;
  handleSalvarInserirNovo?: () => Promise<void>;
};

const UncontrolledForm = ({
  handleSalvar,
  handleSalvarInserirNovo,
  isLoading,
  readonly = false,
}: UncontrolledFormProps) => {
  const {
    redirecionarRelatorioPersonalizadoListar,
    inputFileRef,
  } = useRelatorioPersonalizadoForm();

  const { setValue, watch } = useFormContext();
  const { nomeArquivo, caminhoArquivo } = watch();

  const handleSalvarArquivo = (files: FileList | null) => {
    const file = files && files.length > 0 ? files[0] : null;

    if (!file) {
      return;
    }

    if (!file.name.includes('.rpt')) {
      toast.warning(
        'A extensão do arquivo selecionado é inválida, esse campo aceita apenas arquivos .rpt'
      );

      return;
    }

    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onloadend = () => {
      if (reader.result) {
        const value =
          reader.result.toString().replace(/^data:(.*,)?/, '') || '';

        setValue('nomeArquivo', file.name);
        setValue('caminhoArquivo', value);
      }
    };
  };

  return (
    <>
      <ContainerHeader bg="#FF005A" gap={2} color="white" alignItems="center">
        <Box
          cursor="pointer"
          mr="10px"
          onClick={() => redirecionarRelatorioPersonalizadoListar()}
        >
          <Icon fontSize="25px" as={FiChevronLeft} />
        </Box>
        <Text fontSize="16px" fontWeight="semibold">
          Relatório personalizado
        </Text>
      </ContainerHeader>
      <Box position="relative" overflowX="auto">
        {isLoading && <LoadingPadrao />}
        <Box p={6} minW="min">
          <SimpleGridForm>
            <Input
              id="descricao"
              name="descricao"
              label="Descrição"
              placeholder="Informe a descrição do relatório personalizado"
              maxLength={100}
              isDisabled={readonly}
              colSpan={{ base: 12, sm: 6 }}
            />
            <SelectField
              id="tipo"
              name="tipo"
              label="Tipo"
              placeholder="Selecione"
              options={[
                { label: 'Laser', value: 'LASER' },
                { label: 'Cupom', value: 'CUPOM' },
              ]}
              isDisabled={readonly}
              colSpan={{ base: 12, sm: 6, lg: 3 }}
            />
            <CheckBoxAtivoInativo
              id="ativo"
              name="ativo"
              label="Status"
              isRequired={false}
              isDisabled={readonly}
              colSpan={{ base: 12, sm: 6, lg: 3 }}
            />
            <Box display="none">
              <input
                type="file"
                accept=".rpt"
                onChange={({ target }) => {
                  handleSalvarArquivo(target.files);
                }}
                ref={inputFileRef}
                multiple={false}
              />
            </Box>
            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormLabel m={0}>Relatório</FormLabel>
              {caminhoArquivo && nomeArquivo ? (
                <Box mt={2} p={4} bg="gray.100" borderRadius="4px">
                  <Flex
                    p={3}
                    bg="white"
                    borderWidth="1px"
                    borderColor="gray.200"
                    borderRadius="8px"
                    gap={2}
                    align={{ base: 'flex-start', sm: 'center' }}
                    justify={{ base: 'center', sm: 'space-between' }}
                    flexDir={{ base: 'column', sm: 'row' }}
                  >
                    <Flex gap={2}>
                      <Icon as={FiFile} textColor="gray.600" fontSize="20px" />
                      <Text
                        maxW="200px"
                        fontSize="14px"
                        overflow="hidden"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                      >
                        {nomeArquivo}
                      </Text>
                    </Flex>
                    <Text
                      w="max"
                      cursor="pointer"
                      fontSize="14px"
                      textColor="purple.500"
                      _hover={{
                        textDecoration: 'underline',
                        textDecorationColor: 'purple.300',
                      }}
                      onClick={() => inputFileRef.current?.click()}
                    >
                      Alterar arquivo
                    </Text>
                  </Flex>
                </Box>
              ) : (
                <Button
                  colorScheme="blue"
                  fontWeight="normal"
                  onClick={() => inputFileRef.current?.click()}
                >
                  Adicionar arquivo do relatório (.rpt)
                </Button>
              )}
            </GridItem>
          </SimpleGridForm>
          <Flex
            p="40px"
            gap={6}
            align="center"
            justify="center"
            flexWrap="wrap"
          >
            {handleSalvarInserirNovo && (
              <Button
                w={{ base: 'full', sm: 'min-content' }}
                minW="max"
                colorScheme="gray"
                variant="outlineDefault"
                leftIcon={<Icon as={SalvarInserirNovoIcon} fontSize="lg" />}
                onClick={handleSalvarInserirNovo}
              >
                Salvar e inserir novo
              </Button>
            )}
            {handleSalvar && (
              <Button
                w={{ base: 'full', sm: 'min-content' }}
                minW="225px"
                colorScheme="secondary"
                onClick={handleSalvar}
              >
                Salvar
              </Button>
            )}
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default UncontrolledForm;
