import { Box, Flex, Text } from '@chakra-ui/react';

import { InfoHeader } from './infoHeader';

type HeaderProps = {
  titulo: string;
  subtitulo: string;
  isFormularioSalvo: boolean;
  isPlaying: boolean;
  h?: string;
  handleAnimationComplete: (isCompleto: boolean) => void;
  children?: React.ReactNode;
  alertDescription?: string;
};

export const Header = ({
  titulo,
  subtitulo,
  isFormularioSalvo,
  isPlaying,
  handleAnimationComplete,
  h = '160px',
  children,
  alertDescription,
}: HeaderProps) => {
  return (
    <Flex
      pl="5.8%"
      pr={alertDescription ? '2%' : '5.8%'}
      justifyContent="space-between"
      direction={['row-reverse', 'row']}
      alignItems={['flex-start', 'baseline', 'center']}
      bg="violet.500"
      w="full"
      h={h}
    >
      <Flex
        pt={['20px', '20px', '0']}
        direction={['column', 'column', 'row']}
        justifyContent={['start', 'space-between', 'space-between']}
        alignItems={['left', 'left', 'center']}
        h="full"
        w={['', '', 'full']}
      >
        <Text
          w={['', '', 'full']}
          pb={['2px', '45px']}
          fontSize={['14px', '16px', '22px']}
          fontWeight="semibold"
          color="white"
        >
          {titulo || ''}

          <Box display={['', '', 'none']}>
            <InfoHeader
              alertDescription={alertDescription}
              subtitulo={subtitulo}
              isFormularioSalvo={isFormularioSalvo}
              isPlaying={isPlaying}
              handleAnimationComplete={handleAnimationComplete}
            />
          </Box>
        </Text>
        <Box display={['none', 'none', 'flex']}>
          <InfoHeader
            alertDescription={alertDescription}
            subtitulo={subtitulo}
            isFormularioSalvo={isFormularioSalvo}
            isPlaying={isPlaying}
            handleAnimationComplete={handleAnimationComplete}
          />
        </Box>
      </Flex>
      {children && children}
    </Flex>
  );
};
