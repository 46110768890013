export type RevendaProps = {
  id: string;
  nomeResponsavel: string;
  email: string;
  telefone: string;
  celular: string;
  atendimentoOnline: string;
  horariosFuncionamento: HorarioFuncionamento[];
};

type HorarioFuncionamento = {
  aberto: boolean;
  dia: number;
  dataHoraAbertura: string;
  dataHoraFechamento: string;
  abertura: string;
  fechamento: string;
};

export const optionsMenu = {
  MENU_PRINCIPAL: 0,
  CHAT_MOVIEDESK: 1,
  CHAT_REVENDA: 2,
};
