import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  Box,
  Flex,
  GridItem,
  Link,
  Td,
  Tr,
  Tooltip,
  Text,
} from '@chakra-ui/react';

import auth from 'modules/auth';
import api, { ResponseApi } from 'services/api';
import { getName } from 'helpers/enum/getName';
import enumTipoUsuario from 'constants/enum/enumTipoUsuario';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import ConstanteFuncionalidades from 'constants/permissoes';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { formatDateHourMinute } from 'helpers/format/formatStringDate';
import { shallowEqual } from 'helpers/validation/shallowEqual';

import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { ModalConfirmacaoInativar } from 'components/Modal/ModalConfirmacaoInativar';
import {
  GridPaginadaConsulta,
  GridPaginadaRetorno,
} from 'components/Grid/Paginacao';
import { ModalAtencao } from 'components/Modal/ModalAtencao';
import { ModalConfirmacao } from 'components/Modal/ModalConfirmacao';
import {
  PagedTable,
  PagedTableForwardRefData,
} from 'components/update/Table/PagedTable';
import { SearchInput } from 'components/update/Input/SearchInput';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { ButtonCadastrarNovo } from 'components/Layout/ButtonCadastrarNovo';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import { FilterSelect } from 'components/update/Select/FilterSelect';
import { InfoIconCircular } from 'icons';

interface UsuarioFiltros {
  ativo: boolean | null;
  nome: string;
}

interface Usuario {
  id: string;
  nome: string;
  usuario: string;
  perfil: string;
  ativo: boolean;
  email: string;
  administrador: boolean;
  ultimoAcesso: string;
  tipoUsuario: number;
}

const formDefaultValues = {
  nome: '',
  ativo: null,
};

const Listar = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [totalLicencas, setTotalLicencas] = useState(1);
  const [qntLicencasAtivas, setQntLicencasAtivas] = useState(1);
  const [usuarios, setUsuarios] = useState<Usuario[]>([]);
  const [currentFilters, setCurrentFilters] = useState<UsuarioFiltros>(
    formDefaultValues
  );

  const history = useHistory();
  const isMountedRef = useIsMountedRef();

  const formMethods = useForm({
    defaultValues: formDefaultValues,
  });
  const { setFocus, handleSubmit } = formMethods;

  const isAdminLogado = auth.getIsAdministrador();
  const idUsuarioLogado = auth.getDadosToken().userId;
  const possuiSomenteUmaLicenca = totalLicencas === 1;
  const todasLicencasForamUtilizadas = totalLicencas === qntLicencasAtivas;

  const pageIsLoaded = useRef(false);
  const childRef = useRef<PagedTableForwardRefData>(null);

  const permissaoUsuarioAlterar = auth.possuiPermissao(
    ConstanteFuncionalidades.USUARIO_ALTERAR
  );
  const permissaoUsuarioVisualizar = auth.possuiPermissao(
    ConstanteFuncionalidades.USUARIO_VISUALIZAR
  );

  const handleHistoryPush = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history]
  );

  const handleReset = handleSubmit((data) => {
    const filtersIsDirty = !shallowEqual(data, currentFilters || {});

    if (filtersIsDirty) {
      setCurrentFilters(data);
    }
  });

  const textoInfoLicenca = `Sua conta atingiu o limite máximo de usuários permitidos. Você pode cadastrar um novo usuário mas ele permanecerá sem acesso ao sistema. ${
    possuiSomenteUmaLicenca
      ? ' Para adquirir uma nova licença, '
      : ' Dica: É possível alternar a licença entre usuários, ativando um e desativando outro conforme sua necessidade. Se preferir adquirir uma nova licença, '
  }entre em contato conosco.`;

  const handleCadastrarNovo = useCallback(() => {
    if (todasLicencasForamUtilizadas) {
      ModalConfirmacao({
        callback: async (ok: boolean) => {
          if (ok) {
            handleHistoryPush(ConstanteRotas.USUARIO_CADASTRAR);
          }
        },

        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Cadastrar usuário sem licença',
        title: 'Limite de licenças de usuário',
        text: (
          <>
            <Text>{textoInfoLicenca}</Text>
            <Text mt="18px">Deseja continuar com o cadastro?</Text>
          </>
        ),
        heightModal: possuiSomenteUmaLicenca ? '298px' : '312px',
      });
    } else {
      handleHistoryPush(ConstanteRotas.USUARIO_CADASTRAR);
    }
  }, [
    handleHistoryPush,
    possuiSomenteUmaLicenca,
    textoInfoLicenca,
    todasLicencasForamUtilizadas,
  ]);

  const handleRefresh = useCallback(() => {
    return childRef.current?.reload();
  }, []);

  const paginationHandle = useCallback(
    async (gridPaginadaConsulta: GridPaginadaConsulta) => {
      setIsLoading(true);

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<Usuario>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.USUARIO_LISTAR_PAGINADO,
          gridPaginadaConsulta
        ),
        { params: { nome: currentFilters.nome, ativo: currentFilters.ativo } }
      );

      if (response?.sucesso && isMountedRef.current) {
        setTotalRegistros(response.dados.total);
        setUsuarios(response.dados.registros);
      }

      if (isMountedRef.current) {
        setIsLoading(false);

        if (!pageIsLoaded.current) {
          pageIsLoaded.current = true;

          setFocus('nome');
        }
      }
    },
    [currentFilters, isMountedRef, setFocus]
  );

  const excluirHandle = useCallback(
    async (usuarioId: string, ativo: boolean) => {
      ModalConfirmacaoExcluir({
        callback: async (ok: boolean) => {
          if (ok) {
            setIsLoading(true);

            const response = await api.delete<void, ResponseApi>(
              ConstanteEnderecoWebservice.USUARIO_EXCLUIR,
              {
                params: { id: usuarioId },
              }
            );

            if (response?.sucesso) {
              toast.success('O cadastro foi removido com sucesso.');

              handleRefresh();
            }

            if (response) {
              ModalConfirmacaoInativar({
                response,
                rotaWebService: ConstanteEnderecoWebservice.USUARIO_INATIVAR,
                id: usuarioId,
                ativo,
                callback: (okInativar: boolean) => {
                  if (okInativar) handleRefresh();
                },
              });
            }

            setIsLoading(false);
          }
        },
      });
    },
    [handleRefresh]
  );

  const getNomeLinkHref = useCallback(
    (id: string) => {
      let href = '';

      if (permissaoUsuarioAlterar.permitido) {
        href = SubstituirParametroRota(
          ConstanteRotas.USUARIO_ALTERAR,
          'id',
          id
        );
      } else if (permissaoUsuarioVisualizar.permitido) {
        href = SubstituirParametroRota(
          ConstanteRotas.USUARIO_VISUALIZAR,
          'id',
          id
        );
      }

      return href;
    },
    [permissaoUsuarioAlterar, permissaoUsuarioVisualizar]
  );

  const trocarSenhaHandle = async (EmailUsuario: string) => {
    ModalAtencao({
      title: 'Alterar senha?',
      text:
        'Será enviado para o e-mail do usuario instruções para alterar a senha. Deseja prosseguir?',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      focusCancel: true,
      callback: async (ok?: boolean) => {
        if (ok) {
          setIsLoading(true);

          const response = await api.post<void, ResponseApi<string>>(
            `${ConstanteEnderecoWebservice.AUTENTICACAO_RECUPERAR_SENHA}${EmailUsuario}`
          );

          setIsLoading(false);

          if (response) {
            if (response.avisos) {
              response.avisos.forEach((item: string) => toast.warning(item));
            }

            if (response.sucesso) {
              toast.success(
                'Foi enviado um e-mail com instruções para alterações de senha.'
              );
            }
          }
        }
      },
    });
  };

  const tratarDadosApi = useCallback(async (response) => {
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }

      if (response?.sucesso && response.dados) {
        return response.dados;
      }
    }
    return null;
  }, []);

  const getQntLicencas = useCallback(async () => {
    const response = await api.get<void, ResponseApi<number>>(
      ConstanteEnderecoWebservice.USUARIO_OBTER_QUANTIDADE_LICENCAS
    );

    return tratarDadosApi(response);
  }, [tratarDadosApi]);

  const getQntLicencasAtivas = useCallback(async () => {
    const response = await api.get<void, ResponseApi<number>>(
      ConstanteEnderecoWebservice.USUARIO_OBTER_QUANTIDADE_USUARIOS_ATIVOS
    );

    return tratarDadosApi(response);
  }, [tratarDadosApi]);

  const getInfoLicenca = useCallback(async () => {
    setIsLoading(true);
    const [responseLicencas, responseLicencasAtivas] = await Promise.all([
      getQntLicencas(),
      getQntLicencasAtivas(),
    ]);
    if (responseLicencas) {
      setTotalLicencas(responseLicencas);
    }
    if (responseLicencasAtivas) {
      setQntLicencasAtivas(responseLicencasAtivas);
    }
    setIsLoading(false);
  }, [getQntLicencas, getQntLicencasAtivas]);

  useEffect(() => {
    getInfoLicenca();
  }, [getInfoLicenca]);

  return (
    <SimpleGridForm gap={{ base: '10px', sm: '10px', md: 4 }}>
      <FormProvider {...formMethods}>
        <GridItem colSpan={{ base: 12, md: 5, lg: 6 }}>
          <SearchInput
            type="search"
            placeholder="Buscar usuário por nome"
            onEnterKeyPress={() => handleReset()}
            isDisabled={isLoading}
            id="nome"
            name="nome"
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, md: 2, lg: 2 }}>
          <Box w={{ base: 'full', md: '190px', lg: '190px' }}>
            <FilterSelect
              id="ativo"
              name="ativo"
              isDisabled={isLoading}
              options={[
                {
                  value: true,
                  label: 'Ativos',
                },
                {
                  value: false,
                  label: 'Sem licença',
                },
                {
                  value: null,
                  label: 'Todos',
                },
              ]}
              onSelect={() => {
                handleReset();
              }}
            />
          </Box>
        </GridItem>
        <GridItem
          colSpan={{ base: 12, md: 5, lg: 4 }}
          display={{ base: '', sm: '', md: 'flex' }}
          justifyContent={{ base: 'flex-start', sm: 'flex-end' }}
        >
          <ButtonCadastrarNovo
            onClick={handleCadastrarNovo}
            funcionalidade={ConstanteFuncionalidades.USUARIO_CADASTRAR}
            borderRadius="full"
            height="36px"
            width={['full', '', '']}
            px="20px"
          />
        </GridItem>
        <GridItem colSpan={12}>
          <Flex
            h={['85px', '40px']}
            bg="primary.700"
            w="full"
            alignItems="center"
            borderRadius="5px"
            letterSpacing="0px"
            fontSize="sm"
            px="16px"
            gap="8px"
          >
            {!isLoading && isMountedRef.current && (
              <>
                {todasLicencasForamUtilizadas && (
                  <Tooltip
                    hasArrow
                    placement="bottom-end"
                    label={textoInfoLicenca}
                    isOpen={tooltipIsOpen}
                  >
                    <Box
                      sx={{
                        '& svg  g': {
                          color: 'secondary.300',
                        },
                      }}
                    >
                      <InfoIconCircular
                        width="24px"
                        height="24px"
                        onMouseEnter={() => setTooltipIsOpen(true)}
                        onMouseLeave={() => setTooltipIsOpen(false)}
                        onClick={() => setTooltipIsOpen(true)}
                      />
                    </Box>
                  </Tooltip>
                )}
                <Text color="white">
                  Seu plano possui
                  <Text
                    color="secondary.300"
                    as="span"
                    fontSize="xl"
                    fontWeight="bold"
                    mx="6px"
                  >
                    {totalLicencas}
                  </Text>
                  {possuiSomenteUmaLicenca ? 'licença' : 'licenças'} para{' '}
                  {possuiSomenteUmaLicenca ? 'usuário' : 'usuários'}. No momento
                  estão ativas
                  <Text
                    color="secondary.300"
                    as="span"
                    fontSize="xl"
                    fontWeight="bold"
                    mx="6px"
                  >
                    {qntLicencasAtivas}/{totalLicencas}
                  </Text>
                </Text>
              </>
            )}
          </Flex>
        </GridItem>

        <GridItem colSpan={12}>
          <Box>
            <PagedTable
              ref={childRef}
              itemsTotalCount={totalRegistros}
              loadColumnsData={paginationHandle}
              defaultKeyOrdered="Ativo"
              defaultOrderDirection="desc"
              bg="gray.50"
              variant="card"
              editarCorBorda="gray.100"
              boxShadow="none"
              isLoading={isLoading}
              paddingLeft="4px"
              paddingBottom="4px"
              paddingRight="4px"
              mt="3"
              tableHeaders={[
                {
                  content: 'Nome',
                  key: 'Nome',
                  isOrderable: true,
                  width: '35%',
                },
                {
                  content: 'Login de usuário',
                  key: 'UserName',
                  isOrderable: true,
                  width: '20%',
                },
                {
                  content: 'Tipo de usuário',
                  key: 'tipoUsuario',
                  isOrderable: true,
                  width: '15%',
                },
                {
                  content: 'Perfil',
                  key: 'Perfil',
                  isOrderable: false,
                  width: '15%',
                },
                {
                  content: 'Status',
                  key: 'Ativo',
                  isOrderable: true,
                  width: '8%',
                  minWidth: '140px',
                },
                {
                  content: 'Último Acesso',
                  key: 'UltimoLogin',
                  isOrderable: true,
                  width: '10%',
                },
                {
                  content: 'Ações',
                  key: 'Acoes',
                  isOrderable: false,
                },
              ]}
              renderTableRows={usuarios?.map((usuario, index) => {
                const userIsAdmin = usuario.administrador;

                return (
                  <>
                    <Box h={index === 0 ? '0' : '5px'} />
                    <Tr
                      key={usuario.id}
                      fontWeight={userIsAdmin ? 'bold' : 'normal'}
                      sx={{
                        boxShadow: '0px 0px 4px #00000029',
                        borderRadius: '6px',
                        '& > td': {
                          bg: usuario?.ativo ? 'white' : 'gray.50',
                          height: '52px',
                        },
                      }}
                    >
                      <Td
                        borderTopLeftRadius="5px"
                        borderBottomLeftRadius="5px"
                      >
                        <Flex
                          alignItems="center"
                          fontWeight={userIsAdmin ? 'bold' : 'normal'}
                        >
                          {!userIsAdmin ? (
                            <Link href={getNomeLinkHref(usuario.id)}>
                              {usuario.nome}
                            </Link>
                          ) : (
                            <>
                              {isAdminLogado ? (
                                <Tooltip
                                  label="Administrador da conta"
                                  aria-label="tooltip"
                                  hasArrow
                                  placement="right"
                                  bg="gray.700"
                                >
                                  <Link href={getNomeLinkHref(usuario.id)}>
                                    {usuario.nome}
                                  </Link>
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  label="Administrador da conta"
                                  aria-label="tooltip"
                                  hasArrow
                                  placement="right"
                                  bg="gray.700"
                                >
                                  {usuario.nome}
                                </Tooltip>
                              )}
                            </>
                          )}
                        </Flex>
                      </Td>
                      <Td>{usuario.usuario}</Td>
                      <Td>{getName(enumTipoUsuario, usuario.tipoUsuario)}</Td>
                      <Td>{usuario.perfil || 'Personalizado por usuário'}</Td>
                      <Td textAlign="center">
                        <Box
                          bg={usuario.ativo ? 'aquamarine.600' : 'red.500'}
                          height="20px"
                          width="fit-content"
                          color="white"
                          fontSize="xs"
                          px="18px"
                          borderRadius="full"
                          letterSpacing="0"
                          whiteSpace="nowrap"
                        >
                          {usuario.ativo ? 'Ativa' : 'Sem licença'}
                        </Box>
                      </Td>
                      <Td>
                        {usuario.ultimoAcesso ? (
                          <>{formatDateHourMinute(usuario.ultimoAcesso)}h</>
                        ) : (
                          <>(Nenhum acesso)</>
                        )}
                      </Td>
                      <Td
                        borderTopRightRadius="5px"
                        borderBottomRightRadius="5px"
                      >
                        {!userIsAdmin ? (
                          <ActionsMenu
                            id="mostrarMais"
                            items={
                              idUsuarioLogado !== usuario.id
                                ? [
                                    {
                                      content: 'Editar',
                                      onClick: () => {
                                        handleHistoryPush(
                                          SubstituirParametroRota(
                                            ConstanteRotas.USUARIO_ALTERAR,
                                            'id',
                                            usuario.id
                                          )
                                        );
                                      },
                                      funcionalidade:
                                        ConstanteFuncionalidades.USUARIO_ALTERAR,
                                    },
                                    {
                                      content: 'Remover',
                                      onClick: () => {
                                        excluirHandle(
                                          usuario.id,
                                          usuario.ativo
                                        );
                                      },
                                      funcionalidade:
                                        ConstanteFuncionalidades.USUARIO_EXCLUIR,
                                    },
                                    {
                                      content: 'Trocar senha',
                                      onClick: () => {
                                        trocarSenhaHandle(usuario.email);
                                      },
                                    },
                                  ]
                                : [
                                    {
                                      content: 'Editar',
                                      onClick: () => {
                                        handleHistoryPush(
                                          SubstituirParametroRota(
                                            ConstanteRotas.USUARIO_ALTERAR,
                                            'id',
                                            usuario.id
                                          )
                                        );
                                      },
                                      funcionalidade:
                                        ConstanteFuncionalidades.USUARIO_ALTERAR,
                                    },
                                  ]
                            }
                          />
                        ) : (
                          <>
                            {isAdminLogado && (
                              <ActionsMenu
                                id="mostrarMais"
                                items={[
                                  {
                                    content: 'Editar',
                                    onClick: () => {
                                      handleHistoryPush(
                                        SubstituirParametroRota(
                                          ConstanteRotas.USUARIO_ALTERAR,
                                          'id',
                                          usuario.id
                                        )
                                      );
                                    },
                                    funcionalidade:
                                      ConstanteFuncionalidades.USUARIO_ALTERAR,
                                  },
                                ]}
                              />
                            )}
                          </>
                        )}
                      </Td>
                    </Tr>
                  </>
                );
              })}
            />
          </Box>
        </GridItem>
      </FormProvider>
    </SimpleGridForm>
  );
};

export default Listar;
