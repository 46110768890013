import { extendTheme } from '@chakra-ui/react';

import auth from 'modules/auth';
import TipoSistemaEnum from 'constants/enum/tipoSistema';

import colors from './foundations/colors';
import colors_fomer from './foundations/colors_fomer';
import colors_powerstock from './foundations/colors_powerstock';
import borders from './foundations/borders';
import fonts from './foundations/fonts';
import breakpoints from './foundations/breakpoints';
import fontSizes from './foundations/fontSizes';
import shadows from './foundations/shadows';

import Button from './components/button';
import Input from './components/input';
import Textarea from './components/textarea';
import NumberInput from './components/numberInput';
import Menu from './components/menu';
import Form from './components/form';
import FormLabel from './components/formLabel';
import Table from './components/table';
import Avatar from './components/avatar';
import Tabs from './components/tabs';
import Accordion from './components/accordion';
import Drawer from './components/drawer';
import { Steps } from './components/steps';

const system = auth.getSistema() || '';
let newColors = {};

switch (system.value) {
  case TipoSistemaEnum.ZENDAR:
    newColors = colors;
    break;
  case TipoSistemaEnum.POWERSTOCK:
    newColors = colors_powerstock;
    break;
  case TipoSistemaEnum.FOMER:
    newColors = colors_fomer;
    break;
  case TipoSistemaEnum.FOMER_SISTEMA:
    newColors = colors_fomer;
    break;
  case TipoSistemaEnum.POWERCHEF:
    newColors = colors_powerstock;
    break;

  default:
    newColors = colors;
}

const theme = extendTheme({
  config: {
    cssVarPrefix: 'sti-ck',
    initialColorMode: 'light',
    useSystemColorMode: false,
  },

  styles: {
    global: {
      '& > div': {
        marginRight: '6px',
      },
      '&::-webkit-scrollbar': {
        width: '6px',
        height: '5px',
        scrollbarWidth: 'thin',
        position: 'absolute',
        right: '-10px',
      },
      '&::-webkit-scrollbar-track': {
        background: 'gray.50',
        borderRadius: '6px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: 'gray.200',
        borderRadius: '6px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: 'gray.200',
      },
    },
  },

  // foundations
  colors: newColors,
  borders,
  fonts,
  breakpoints,
  fontSizes,
  shadows,

  components: {
    Button,
    Input,
    Textarea,
    NumberInput,
    Menu,
    Form,
    FormLabel,
    Table,
    Avatar,
    Tabs,
    Accordion,
    Steps,
    Drawer,
  },
});

export default theme;
