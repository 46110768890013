import { CSSProperties } from 'react';
import styled from 'styled-components';
import { BuscaIcon } from 'icons';
import { FiFilter } from 'react-icons/fi';

import {
  control as importedControl,
  option as importedOption,
  indicatorsContainer as importedIndicatorsContainer,
  dropdownIndicator as importedDropdownIndicator,
  selectStyles as importedSelectStyles,
} from '../styles';

export const OptionsIcon = styled(BuscaIcon)`
  font-size: 1.125rem;

  margin-right: 12px;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 5px;
`;

export const FilterIcon = styled(FiFilter)`
  font-size: 1.125rem;

  margin-right: 12px;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 5px;
`;

interface SelectStylesProps {
  controlWidth?: number | string;
  controlHeight?: number | string;
  singleValueFontSize?: number | string;
}

export const selectStyles = ({
  controlWidth,
  controlHeight,
  singleValueFontSize,
}: SelectStylesProps) => ({
  ...importedSelectStyles,
  control: (base: any, state: any) =>
    ({
      ...base,
      ...importedControl(base, state),
      minHeight: controlHeight || '40px',
      maxHeight: controlHeight || '40px',
      borderRadius: '8px',
      padding: '0 8px',
      boxShadow: state.isFocused ? '0 0 0 1px primary.700' : 'none',
    } as CSSProperties),
  input: (base: any) => ({
    ...base,
    maxHeight: controlHeight || '40px',
  }),
  option: (base: any, state: any) => ({
    ...base,
    ...importedOption(base, state),
    paddingLeft: '42px',
    fontSize: '14px',
  }),
  indicatorsContainer: (base: any) => ({
    ...base,
    ...importedIndicatorsContainer(base),
    maxHeight: controlHeight || '40px',
  }),
  container: (base: any) => ({
    ...base,
    maxHeight: controlHeight || '40px',
    maxWidth: controlWidth || '180px',
    minWidth: '122px',
  }),
  valueContainer: (base: any) => ({
    ...base,
    maxHeight: controlHeight || '40px',
    position: 'initial',
  }),
  singleValue: (base: any) => ({
    ...base,
    marginLeft: '35px',
    fontSize: singleValueFontSize || '15px',
    paddingRight: '60px',
  }),
  menu: (base: any) =>
    ({
      ...base,
      marginTop: '8px',
      borderRadius: '8px',
    } as CSSProperties),
});

export const blueSelectStyles = {
  ...importedSelectStyles,
  control: (base: any, state: any) => ({
    ...base,
    ...importedControl(base, state),
    minHeight: '40px',
    maxHeight: '40px',
    position: 'relative',
    color: 'var(--white)',
    background: 'var(--sti-ck-colors-blue-500)',
    border: '1px solid var(--sti-ck-colors-blue-500)',
    boxShadow: state.isFocused
      ? '0 0 0 0.2rem rgba(24, 150, 227, 0.25)'
      : 'unset',
  }),
  input: (base: any) => ({
    ...base,
    maxHeight: '40px',
    color: 'var(--white)',
  }),
  option: (base: any, state: any) => ({
    ...base,
    ...importedOption(base, state),
    paddingLeft: '42px',
    fontSize: '14px',
  }),
  indicatorsContainer: (base: any) => ({
    ...base,
    ...importedIndicatorsContainer(base),
    maxHeight: '40px',
    color: 'var(--white)',
  }),
  container: (base: any) => ({
    ...base,
    maxHeight: '40px',
  }),
  valueContainer: (base: any) => ({
    ...base,
    maxHeight: '40px',
  }),
  singleValue: (base: any) => ({
    ...base,
    marginLeft: '35px',
    fontSize: '15px',
    color: 'var(--white)',
    paddingRight: '30px',
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    ...importedDropdownIndicator(base),
    color: 'var(--white) !important',
  }),
};
