import { useCallback, useImperativeHandle, useState, useEffect } from 'react';
import { VStack, Text, Flex, Box, Icon } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { BsArrowRight } from 'react-icons/bs';

import { IdentificacaoEtapasTray } from 'constants/enum/IdentificacaoEtapasTray';
import { useTrayEtapasContext } from 'store/Tray';
import auth from 'modules/auth';
import PlanoContratacaoEnum from 'constants/enum/planoContratacao';

import { TabelaPrecosIcon } from 'icons';

import { dataDefaultTabelaPreco, TabelaPrecoValue } from './validationForms';
import { Header } from '../Layout/Header';
import { ModalTabelaCriada } from './ModalTabelaCriada';

export type OptionResponseProps = {
  id: string;
  nome: string;
};

export const TabelaPreco = () => {
  const [optionsTabelaPreco, setOptionsTabelaPreco] = useState(
    dataDefaultTabelaPreco
  );

  const {
    ref,
    setActiveStep,
    setIsLoading,
    dadosTray,
  } = useTrayEtapasContext();

  const isCriarTabelaPreco = optionsTabelaPreco.find(
    (itemTabelaPreco) => itemTabelaPreco.isChecked
  );

  const handleAvancar = useCallback(
    (identificacao: number) => {
      setActiveStep(identificacao);
    },
    [setActiveStep]
  );

  const planoIsAtual = auth.getPlano();

  const isPlanoStart = planoIsAtual === PlanoContratacaoEnum.START;

  const atualizarTabelaCriada = useCallback(async () => {
    setIsLoading(true);
    const { dados } = await dadosTray();

    let isPossuiTabelaPreco = false;

    try {
      const valueTabelaPreco = JSON.parse(dados.configuracoes) as {
        PossuiTabelaPrecoCriada: boolean;
      };
      isPossuiTabelaPreco = valueTabelaPreco.PossuiTabelaPrecoCriada;
    } catch (e) {
      isPossuiTabelaPreco = false;
    }

    setOptionsTabelaPreco((prev) =>
      prev.map((item) => ({
        ...item,
        isDisable:
          item.value === TabelaPrecoValue.CRIAR_TABELA_PRECO
            ? isPossuiTabelaPreco
            : item.isDisable,
      }))
    );
    setIsLoading(false);
  }, [dadosTray, setIsLoading]);

  const handleVoltar = useCallback(() => {
    setActiveStep(IdentificacaoEtapasTray.LOCAL_ESTOQUE);
  }, [setActiveStep]);

  const handleSelecionarCadastro = useCallback((index: number) => {
    setOptionsTabelaPreco((prev) =>
      prev.map((itemCadastro, indexCadastro) => ({
        ...itemCadastro,
        isChecked: index === indexCadastro,
      }))
    );
  }, []);

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);

    if (isCriarTabelaPreco?.value === undefined) {
      toast.warning('Escolha uma opção para avançar a etapa');
      setIsLoading(false);
      return;
    }

    if (
      isCriarTabelaPreco.value === TabelaPrecoValue.SELECIONAR_TABELA_EXISTENTE
    ) {
      const avancar = await ModalTabelaCriada();
      if (avancar.sucesso) {
        handleAvancar(IdentificacaoEtapasTray.TIPO_CADASTRO);
      }
      setIsLoading(false);
    } else {
      handleAvancar(IdentificacaoEtapasTray.CADASTRAR_TABELA_PRECO);
    }

    setIsLoading(false);
  }, [handleAvancar, isCriarTabelaPreco, setIsLoading]);

  useImperativeHandle(ref, () => ({
    handleAvancar: handleSubmit,
    handleVoltar,
  }));

  useEffect(() => {
    if (isPlanoStart) {
      handleAvancar(IdentificacaoEtapasTray.TIPO_CADASTRO);
    }
  }, [handleAvancar, isPlanoStart, setActiveStep]);

  useEffect(() => {
    atualizarTabelaCriada();
  }, [atualizarTabelaCriada]);

  return (
    <>
      <Header
        title="Tabela de preços"
        handleVoltar={handleVoltar}
        icon={TabelaPrecosIcon}
      />

      <VStack fontSize="14px" w="full" alignItems="left" spacing="24px">
        {optionsTabelaPreco.map((cadastro, index) => (
          <Flex
            borderWidth="1px"
            borderStyle="solid"
            opacity={cadastro.isDisable ? '0.6' : '1'}
            borderColor={
              cadastro.isChecked ? 'rgba(85, 2, 178, 0.5)' : 'gray.50'
            }
            _hover={{
              borderColor: cadastro.isDisable
                ? 'none'
                : 'rgba(85, 2, 178, 0.5)',
              transition: 'all 0.2s',
            }}
            transition="all 0.2s"
            bg={cadastro.isChecked ? 'blue.50' : ''}
            cursor={cadastro.isDisable ? 'not-allowed' : 'pointer'}
            pt="16px"
            pl={['10px', '10px', '30px']}
            pr={['10px', '10px', '30px']}
            pb="16px"
            w="full"
            onClick={() => {
              if (!cadastro.isDisable) {
                handleSelecionarCadastro(index);
              }
            }}
            justifyContent="flex-start"
          >
            <Box mr="9.5px">
              <Icon boxSize="26px" color="primary.50" as={BsArrowRight} />
            </Box>
            <Box>
              <Flex alignItems="baseline">
                <Box mr="10px">
                  <Text
                    color="primary.50"
                    fontSize="18px"
                    fontWeight="semibold"
                  >
                    {cadastro.title}
                  </Text>
                </Box>
                {cadastro.isRecomendado && (
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    h="16px"
                    p="8px"
                    bg="primary.50"
                    color="white"
                    borderRadius="10px"
                  >
                    <Text fontSize="12px">Recomendado</Text>
                  </Flex>
                )}
              </Flex>
              <Text letterSpacing="0px" color="gray.700" fontSize="14px">
                {cadastro.description}
              </Text>
            </Box>
          </Flex>
        ))}
      </VStack>
    </>
  );
};
