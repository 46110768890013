import { Route } from 'react-router-dom';

import { ConstanteRotasAlternativas } from 'constants/rotas';
import ConstanteFuncionalidades from 'constants/permissoes';

import { SmartPosEtapas } from 'pages/Integracoes/LojaAplicativos/SmartPos/Etapas';

import LayoutGuard from './LayoutGuard';

export const SmartPosRoutes = [
  <Route
    path={ConstanteRotasAlternativas.SMART_POS_ETAPA}
    key="smart-pos-listar"
  >
    <LayoutGuard
      key="smart-pos-listar"
      permissaoFuncionalidade={ConstanteFuncionalidades.SMART_POS_HABILITAR}
      component={SmartPosEtapas}
      path={ConstanteRotasAlternativas.SMART_POS_ETAPA}
      exact
      meta={{ auth: true }}
    />
  </Route>,
];
