import { Box, GridItem } from '@chakra-ui/react';

import { TextArea } from 'components/update/TextArea';
import Input from 'components/PDV/Input';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';

import { VincularFormaRecebimento } from '../VincularFormaRecebimento';

export const FormaRecebimentoItem = () => {
  return (
    <Box mt="24px">
      <Box
        w="full"
        bg="white"
        borderColor="gray.200"
        borderWidth="1px"
        borderStyle="solid"
        borderRadius="md"
        overflowX="auto"
      >
        <SimpleGridForm w="full" minW="min" gap={6} p={6}>
          <GridItem colSpan={{ base: 12, sm: 6, xl: 4 }}>
            <SimpleGridForm w="full" gap={6}>
              <GridItem colSpan={[12, 12, 6]}>
                <Input
                  isDisabled
                  id="idConfigTef"
                  label="TEF"
                  name="idConfigTef"
                />
              </GridItem>
              <GridItem colSpan={[12, 12, 6]}>
                <Input
                  id="endereco"
                  placeholder="Informe o endereço IP"
                  label="Endereço IP"
                  name="endereco"
                />
              </GridItem>
              <GridItem colSpan={[12, 12, 6]}>
                <Input
                  id="loja"
                  placeholder="Informe a loja"
                  label="Loja"
                  name="loja"
                />
              </GridItem>
              <GridItem colSpan={[12, 12, 6]}>
                <Input
                  id="senhaSupervisor"
                  placeholder="*****"
                  isPassword
                  inputRightElement
                  label="Senha supervisor"
                  name="senhaSupervisor"
                />
              </GridItem>
              <GridItem colSpan={[12, 12, 12]}>
                <Input
                  id="estabelecimento"
                  placeholder="Informe o estabelecimento"
                  label="Estabelecimento"
                  name="estabelecimento"
                />
              </GridItem>
            </SimpleGridForm>
          </GridItem>
          <GridItem colSpan={{ base: 12, sm: 6, xl: 4 }}>
            <SimpleGridForm w="full" gap={6}>
              <GridItem colSpan={[12, 12, 6]}>
                <Input
                  id="porta"
                  placeholder="Informe a porta"
                  label="Porta"
                  name="porta"
                />
              </GridItem>
              <GridItem colSpan={[12, 12, 6]}>
                <Input
                  id="versaoAc"
                  placeholder="Informe a versão"
                  label="Versão AC"
                  name="versaoAc"
                />
              </GridItem>
              <GridItem colSpan={[12, 12, 6]}>
                <Input
                  id="usuarioLogin"
                  name="usuarioLogin"
                  label="Usuário"
                  placeholder="Informe o usuário"
                />
              </GridItem>
              <GridItem colSpan={[12, 12, 6]}>
                <Input
                  id="usuarioSenha"
                  name="usuarioSenha"
                  label="Senha"
                  placeholder="*****"
                  isPassword
                  inputRightElement
                />
              </GridItem>
            </SimpleGridForm>
          </GridItem>
          <GridItem colSpan={{ base: 12, xl: 4 }} h="full">
            <TextArea
              name="parametros"
              id="parametros"
              minH="114px"
              placeholder="Informe o valor do parâmetros"
              label="Parâmetros"
            />
          </GridItem>
          <GridItem colSpan={12} minW="80px">
            <VincularFormaRecebimento />
          </GridItem>
        </SimpleGridForm>
      </Box>
    </Box>
  );
};
