import React from 'react';
import { Input as InputChakra, InputProps } from '@chakra-ui/react';
import { useFormContext, Controller } from 'react-hook-form';

import CampoPersonalizadoInterface from 'types/campoPersonalizado';

import CampoContainer from 'components/PDV/Geral/CampoContainer';

interface SearchInputProps extends InputProps {
  name: string;
  onEnterKeyPress?: () => void;
  campoPersonalizado?: CampoPersonalizadoInterface;
  label?: string;
  helperText?: string;
}

export const Input = ({
  size,
  id,
  name,
  label,
  helperText,
  onEnterKeyPress,
  onKeyPress,
  onBlur,
  campoPersonalizado,
  ...rest
}: SearchInputProps) => {
  const { setValue } = useFormContext();

  return (
    <CampoContainer id={id} name={name} label={label} helperText={helperText}>
      <Controller
        name={`${name}` as const}
        render={({ field: { onChange, value } }) => {
          return (
            <InputChakra
              type="search"
              {...rest}
              value={value?.valor}
              onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter' && onEnterKeyPress) {
                  e.currentTarget.value = e.currentTarget.value.trim();
                  onEnterKeyPress();
                }

                if (onKeyPress) onKeyPress(e);
              }}
              onChange={onChange}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                e.currentTarget.value = e.currentTarget.value.trim();

                if (onBlur) onBlur(e);

                if (campoPersonalizado) {
                  setValue(name, {
                    campoPersonalizadoId: campoPersonalizado.id,
                    valor: e.target.value,
                  });
                } else {
                  setValue(name, e.target.value);
                }
              }}
            />
          );
        }}
      />
    </CampoContainer>
  );
};
