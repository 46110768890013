import React from 'react';

import { FaturasDetalhes } from 'pages/Faturas/Detalhes';
import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

import { ListarFaturas } from '../pages/Faturas/Listar';

export const FaturasRoutes = [
  <LayoutGuard
    key="faturas-listar"
    permissaoFuncionalidade={ConstanteFuncionalidades.FATURA_LISTAR}
    component={ListarFaturas}
    breadcrumb={[
      {
        title: 'Financeiro',
      },
      {
        title: 'Fatura',
      },
    ]}
    path={ConstanteRotas.FATURAS}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="faturas-detalhes"
    permissaoFuncionalidade={ConstanteFuncionalidades.FATURA_VISUALIZAR}
    component={FaturasDetalhes}
    breadcrumb={[
      {
        title: 'Financeiro',
      },
      {
        title: 'Fatura',
        path: ConstanteRotas.FATURAS,
      },
      {
        title: 'Detalhes',
      },
    ]}
    path={ConstanteRotas.FATURAS_DETALHES}
    exact
    meta={{ auth: true }}
  />,
];
