const EtiquetaTamanhoLetra = {
  7: 1,
  8: 2,
  9: 3,
  10: 4,
  11: 5,
  12: 6,
  13: 7,
  14: 8,
  15: 9,
  16: 10,
  17: 11,
  18: 12,
  19: 13,
  20: 14,
  21: 15,
  22: 16,
  24: 17,
  25: 18,
  26: 19,
  27: 20,
  28: 21,
  29: 22,
  30: 23,
  31: 24,
  32: 25,
  33: 26,
  34: 27,
  35: 28,
  36: 29,
  37: 30,
  38: 31,
  39: 32,
  40: 33,
  41: 34,
  42: 35,
  43: 36,
  44: 37,
  45: 38,

  properties: {
    1: { name: 7, value: 7 },
    2: { name: 8, value: 8 },
    3: { name: 9, value: 9 },
    4: { name: 10, value: 10 },
    5: { name: 11, value: 11 },
    6: { name: 12, value: 12 },
    7: { name: 13, value: 13 },
    8: { name: 14, value: 14 },
    9: { name: 15, value: 15 },
    10: { name: 16, value: 16 },
    11: { name: 17, value: 17 },
    12: { name: 18, value: 18 },
    13: { name: 19, value: 19 },
    14: { name: 20, value: 20 },
    15: { name: 21, value: 21 },
    16: { name: 22, value: 22 },
    17: { name: 23, value: 23 },
    18: { name: 24, value: 24 },
    19: { name: 25, value: 25 },
    20: { name: 26, value: 26 },
    21: { name: 27, value: 27 },
    22: { name: 28, value: 28 },
    23: { name: 29, value: 29 },
    24: { name: 30, value: 30 },
    25: { name: 31, value: 31 },
    26: { name: 32, value: 32 },
    27: { name: 33, value: 33 },
    28: { name: 34, value: 34 },
    29: { name: 35, value: 35 },
    30: { name: 37, value: 37 },
    31: { name: 38, value: 38 },
    32: { name: 39, value: 39 },
    33: { name: 40, value: 40 },
    34: { name: 41, value: 41 },
    35: { name: 42, value: 42 },
    36: { name: 43, value: 43 },
    37: { name: 44, value: 44 },
    38: { name: 45, value: 45 },
    39: { name: 46, value: 46 },
    40: { name: 47, value: 47 },
    41: { name: 48, value: 48 },
    42: { name: 49, value: 49 },
    43: { name: 50, value: 50 },
    44: { name: 51, value: 51 },
    45: { name: 52, value: 52 },
  },
};

export default EtiquetaTamanhoLetra;
