import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  useMediaQuery,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Grid,
  ModalFooter,
  Button,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import ShadowScrollbar from 'components/PDV/Geral/ShadowScrollbar';
import ConditionalWrapper from 'components/Geral/ConditionalWrapper';
import { MobileModalMenu } from 'components/PDV/Layout/MobileModalMenu';
import ModalPadraoChakra from '../ModalPadraoChakra';
import VendedorButton from './VendedorButton';

type Vendedor = {
  id: string;
  nome: string;
  foto: string;
};

export type VendedorSelecionado = {
  id: string;
  nome: string;
};

interface ModalSelecionarVendedorProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (newVendedor: VendedorSelecionado) => void;
  getCurrentVendedor: () => string | undefined;
  finalFocusRef?: any;
  asMobileView?: boolean;
}

const ModalSelecionarVendedor: React.FC<ModalSelecionarVendedorProps> = ({
  isOpen,
  onClose,
  onSubmit,
  getCurrentVendedor,
  finalFocusRef,
  asMobileView = false,
}) => {
  const isMountedRef = useIsMountedRef();
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const [vendedores, setVendedores] = useState<Vendedor[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [vendedorSelecionadoId, setVendedorSelecionadoId] = useState<
    string | undefined
  >();

  const handleSetVendedorSelecionado = useCallback(
    (newVendedorSelecionadoId: string) => {
      if (isLargerThan900) {
        setVendedorSelecionadoId(newVendedorSelecionadoId);
      } else {
        const vendedor = vendedores.find(
          (v) => v.id === newVendedorSelecionadoId
        );

        if (vendedor) {
          onSubmit(vendedor);
        }

        onClose();
      }
    },
    [isLargerThan900, onClose, onSubmit, vendedores]
  );

  const handleSubmit = useCallback(() => {
    if (vendedorSelecionadoId) {
      const vendedor = vendedores.find((v) => v.id === vendedorSelecionadoId);

      if (vendedor) {
        onSubmit(vendedor);
      }
    }

    onClose();
  }, [onClose, onSubmit, vendedorSelecionadoId, vendedores]);

  const latestProps = useRef({ getCurrentVendedor, isMountedRef, onClose });
  useEffect(() => {
    latestProps.current = { getCurrentVendedor, isMountedRef, onClose };
  });

  useEffect(() => {
    const handleGetVendedores = async () => {
      setIsLoading(true);

      const response = await api.get<void, ResponseApi<Vendedor[]>>(
        ConstanteEnderecoWebservice.VENDEDOR_LISTAR_SELECT_PDV
      );

      if (!response) latestProps.current.onClose();

      if (response && latestProps.current.isMountedRef.current) {
        if (response.avisos) {
          response.avisos.map((item: string) => toast.warning(item));
        }

        if (response.sucesso) {
          setVendedores(response.dados);
        } else {
          latestProps.current.onClose();
        }
      }

      setIsLoading(false);
    };

    if (isOpen) {
      const currentVendedor = latestProps.current.getCurrentVendedor();
      setVendedorSelecionadoId(currentVendedor);

      handleGetVendedores();
    } else {
      setIsLoading(true);
    }
  }, [isOpen]);

  if (asMobileView) {
    return (
      <MobileModalMenu
        isOpen={isOpen}
        onClose={onClose}
        title="Selecione um vendedor"
        returnFocusOnClose={false}
      >
        <VStack spacing={2} w="100%">
          {vendedores.map((vendedor) => (
            <VendedorButton
              vendedor={vendedor}
              isSelected={vendedorSelecionadoId === vendedor.id}
              autoFocus={vendedorSelecionadoId === vendedor.id}
              onClick={() => {
                handleSetVendedorSelecionado(vendedor.id);
              }}
            />
          ))}
        </VStack>
      </MobileModalMenu>
    );
  }

  return (
    <ModalPadraoChakra
      isOpen={isOpen}
      onClose={onClose}
      size={isLargerThan900 ? 'xl' : 'full'}
      scrollBehavior={isLargerThan900 ? 'inside' : 'outside'}
      isCentered={isLargerThan900}
      finalFocusRef={finalFocusRef}
      motionPreset={isLargerThan900 ? 'scale' : 'none'}
    >
      <ModalContent
        margin={0}
        maxW={isLargerThan900 ? '600px' : undefined}
        borderRadius={isLargerThan900 ? 'md' : 0}
        bg="gray.50"
      >
        <ModalHeader
          color="primary.500"
          mt={isLargerThan900 ? undefined : 8}
          mb={isLargerThan900 ? undefined : 2}
        >
          Selecione um vendedor
        </ModalHeader>
        <ModalCloseButton
          mt={isLargerThan900 ? undefined : 10}
          mr={isLargerThan900 ? undefined : 4}
        />
        <ConditionalWrapper
          condition={isLargerThan900}
          wrapper={(wrappedChildren: React.ReactNode) => (
            <ShadowScrollbar
              maxHeight={360}
              paddingHeight={300}
              shadowTopStyle={{
                background:
                  'linear-gradient(to bottom, rgba(245, 245, 245, 1) 0%, rgba(245, 245, 245, 0) 100%)',
                height: 30,
              }}
              shadowBottomStyle={{
                background:
                  'linear-gradient(to top, rgba(245, 245, 245, 1) 0%, rgba(245, 245, 245, 0) 100%)',
                height: 30,
              }}
            >
              {wrappedChildren}
            </ShadowScrollbar>
          )}
        >
          <ModalBody>
            {isLoading && <LoadingPadrao />}
            <Grid
              templateColumns={isLargerThan900 ? 'repeat(3, 1fr)' : '1fr'}
              gap={isLargerThan900 ? 8 : 2}
              w="100%"
            >
              {vendedores.map((vendedor) => (
                <VendedorButton
                  vendedor={vendedor}
                  isSelected={vendedorSelecionadoId === vendedor.id}
                  autoFocus={vendedorSelecionadoId === vendedor.id}
                  onClick={() => {
                    handleSetVendedorSelecionado(vendedor.id);
                  }}
                />
              ))}
            </Grid>
          </ModalBody>
        </ConditionalWrapper>
        {isLargerThan900 && (
          <ModalFooter py={8} justifyContent="center">
            <HStack spacing={6}>
              <Button
                variant="solid"
                colorScheme="secondary"
                size="sm"
                minW="100px"
                onClick={handleSubmit}
              >
                Confirmar
              </Button>
            </HStack>
          </ModalFooter>
        )}
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export default ModalSelecionarVendedor;
