import { CSSProperties } from 'react';
import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export type Options = {
  label: string;
  value: string | number;
};

export type City = {
  nome: string;
  id: number;
  codigoIBGE: string;
};

export type ResponseDadosLoja = {
  cep: string;
  celular: string;
  cidadeNome: string;
  cidadeId: number;
  bairro: string;
  email: string;
  fantasia: string;
  estadoNome: string;
  numero: number;
  paisNome: number;
  paisId: number;
  razaoSocial: string;
  cnpj: string;
  telefone: string;
  logradouro: string;
};

export interface CredenciamentoData {
  owner: Owner;
  business_name: string; // Razão social
  business_phone: string;
  business_email: string;
  ein: string; // CNPJ
  statement_descriptor: string; // Nome fantasia
  business_address: Address;
  owner_address: Address;
  mcc: string; // Codigo da atividade feita pela loja
  revenue: number; // Faturamento
}

export interface Categoria {
  categoria: string;
  atividades: Atividade[];
}

export interface CategoriaOptions {
  label: string;
  value: number;
  atividades: {
    label: string;
    value: string;
  }[];
}

export interface Atividade {
  code: string;
  descricao: string;
}

export interface Credenciamento {
  credenciamentoAprovado: boolean;
  credenciamentoSolicitado: boolean;
  credenciamento: CredenciamentoData;
}

export interface Address {
  line1: string;
  line2: string;
  line3?: string;
  neighborhood: string;
  city: string;
  state: string;
  postal_code: string;
  country_code: string;
}

export interface Owner {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  taxpayer_id: string; // CPF dono da loja
  birthdate: string;
}

export const styleSelects = {
  placeholder: (base: CSSProperties) => ({
    ...base,
    fontSize: '16px',
    color: 'black',
  }),
  input: (base: CSSProperties) => ({
    ...base,
    fontSize: '16px',
  }),
  container: (base: CSSProperties) => ({
    ...base,
    fontSize: '16px',
    borderColor: '#CCCCCC',
  }),
  option: (base: CSSProperties) => ({
    ...base,
    fontSize: '16px',
  }),
  valueContainer: (base: CSSProperties) => ({
    ...base,
    fontSize: '16px',
  }),
};

const schema = yup.object().shape({
  razaoSocial: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  telefone: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.TELEFONE_INVALIDO),
  celular: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  email: yup
    .string()
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .email(ConstanteMensagemValidacao.EMAIL_INVALIDO)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  emailProprietario: yup
    .string()
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .email(ConstanteMensagemValidacao.EMAIL_INVALIDO)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  cnpj: yup.string().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  logradouro: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  numero: yup.string().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  bairro: yup.string().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  cidade: yup
    .object()
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .shape({
      label: yup.string(),
      value: yup.string(),
    })
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(null),
  estado: yup.string().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  cep: yup.string().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  pais: yup
    .object()
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .shape({
      label: yup.string(),
      value: yup.string(),
    })
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(null),
  mcc: yup.string().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  faturamento: yup
    .number()
    .required(ConstanteMensagemValidacao.VALOR_MAIOR_QUE_0),
  categoria: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  fantasia: yup.string().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  nome: yup.string().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  sobrenome: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  cpf: yup.string().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  dataNascimento: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);
