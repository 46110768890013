import React, {
  useCallback,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
} from 'react';
import { FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { Col } from 'react-bootstrap';

import InputCep from 'components/Input/InputCep';
import SelectCidade, {
  SelectCidadeRefInterface,
} from 'components/Select/SelectCidade';
import SelectPais, { PaisInterface } from 'components/Select/SelectPais';
import { useTranslation } from 'react-i18next';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';
import { toast } from 'react-toastify';
import InputNull from 'components/Input/InputNull';
import InputPadrao from 'components/Input/InputPadrao';
import { handleGetCidade } from 'helpers/data/getCidadeObterCache';
import { CepResponse } from 'services/viacep';
import CheckBoxDefaultMappedField from 'components/CheckBox/CheckBoxDefaultMappedField';

interface EnderecoCompletoInterface {
  errors: FieldErrors;
  register: UseFormRegister<Record<string, any>>;
  readonly?: boolean;
  control: any;
  setValue: UseFormSetValue<Record<string, any>>;
  getValue: (name: string) => any;
  cepCampoId?: string;
  logradouroCampoId?: string;
  numeroCampoId?: string;
  complementoCampoId?: string;
  bairroCampoId?: string;
  cidadeCampoId?: string;
  estadoCampoId?: string;
  paisCampoId?: string;
  cobrancaCampoId?: string;
  entregaCampoId?: string;
  indexEndereco: number;
}

const EnderecoCobranca = forwardRef(
  (
    {
      errors,
      register,
      control,
      setValue,
      getValue,
      readonly,
      cepCampoId,
      logradouroCampoId,
      numeroCampoId,
      complementoCampoId,
      bairroCampoId,
      cidadeCampoId,
      estadoCampoId,
      paisCampoId = '',
      cobrancaCampoId,
      entregaCampoId,
      indexEndereco,
    }: EnderecoCompletoInterface,
    ref
  ) => {
    const { t } = useTranslation();

    const selectCidadeRef = useRef<SelectCidadeRefInterface>(null);
    const firstInputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

    const handleGetPais = useCallback(async (paisId: number) => {
      const response = await api.get<void, ResponseApi<PaisInterface>>(
        ConstanteEnderecoWebservice.PAIS_OBTER_CACHE,
        { params: { id: paisId } }
      );

      if (response?.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }

      if (response?.sucesso) {
        return response?.dados;
      }

      return {} as PaisInterface;
    }, []);

    const setPais = useCallback(
      async (paisId: number) => {
        const pais = paisId ? await handleGetPais(paisId) : undefined;

        if (pais)
          setValue(`${paisCampoId || 'pais'}` as const, {
            label: pais.nome,
            value: pais.id,
          });

        if (selectCidadeRef.current)
          selectCidadeRef.current.handleSetDefaultOptions();
      },
      [handleGetPais, paisCampoId, setValue]
    );

    const setCidade = useCallback(
      async (cidadeId?: number, codigoIBGE?: string, nomeCidade?: string) => {
        const cidade =
          cidadeId || codigoIBGE
            ? await handleGetCidade(cidadeId, codigoIBGE, nomeCidade)
            : undefined;

        if (cidade) {
          setValue(`${cidadeCampoId || 'cidade'}` as const, {
            label: `${cidade.nome} - ${cidade.estadoSigla}`,
            value: cidade.id,
            paisId: cidade.paisId,
          });
          setValue(`${estadoCampoId || 'estado'}` as const, cidade.estadoNome);

          if (cidade.paisId) setPais(cidade.paisId);
        } else {
          setValue(`${cidadeCampoId || 'cidade'}` as const, null);
          setValue(`${estadoCampoId || 'estado'}` as const, null);
        }
      },
      [cidadeCampoId, setPais, setValue, estadoCampoId]
    );

    const getSelectedCidade = useCallback(
      (cidadeId: number) => {
        setCidade(cidadeId);
      },
      [setCidade]
    );

    const getCepData = useCallback(
      (data: CepResponse) => {
        if (data.bairro)
          setValue(`${bairroCampoId || 'bairro'}` as const, data.bairro);

        if (data.complemento)
          setValue(
            `${complementoCampoId || 'complemento'}` as const,
            data.complemento
          );

        if (data.ibge) {
          setCidade(undefined, data.ibge.toString(), data.localidade);
        }

        if (data.logradouro)
          setValue(
            `${logradouroCampoId || 'logradouro'}` as const,
            data.logradouro
          );
      },
      [
        bairroCampoId,
        complementoCampoId,
        logradouroCampoId,
        setCidade,
        setValue,
      ]
    );

    const getSelectedPais = useCallback(
      (paisId?: number) => {
        if (paisId) {
          const cidade = getValue(cidadeCampoId || 'cidade');
          if (
            cidade &&
            (cidade.paisId || paisId === 1) &&
            cidade.paisId !== paisId
          ) {
            setValue(`${cidadeCampoId || 'cidade'}` as const, null);
            setValue(`${estadoCampoId || 'estado'}` as const, null);
          }
        }

        if (selectCidadeRef.current)
          selectCidadeRef.current.handleSetDefaultOptions();
      },
      [cidadeCampoId, getValue, setValue, estadoCampoId]
    );

    useImperativeHandle(ref, () => ({
      setCidade,
      setPais,
    }));

    useEffect(() => {
      if (indexEndereco !== -1) {
        const data = getValue('enderecosAdicionais');
        setValue('idEnderecoModal', data[indexEndereco].id);
        setValue('cepModal', data[indexEndereco].cep);
        setValue('logradouroModal', data[indexEndereco].logradouro);
        setValue('numeroModal', data[indexEndereco].numero);
        setValue('complementoModal', data[indexEndereco].complemento);
        setValue('bairroModal', data[indexEndereco].bairro);
        setValue('cidadeModal', data[indexEndereco].cidadeCampo);
        setValue('estadoModal', data[indexEndereco].estado);
        setValue('paisModal', data[indexEndereco].paisCampo);
        setValue('entregaModal', {
          id: 'entregaModal',
          selecionado: data[indexEndereco].entrega,
        });
        setValue('cobrancaModal', {
          id: 'cobrancaModal',
          selecionado: data[indexEndereco].cobranca,
        });
      } else {
        setValue('cepModal', '');
        setValue('logradouroModal', '');
        setValue('numeroModal', '');
        setValue('complementoModal', '');
        setValue('bairroModal', '');
        setValue('cidadeModal', '');
        setValue('estadoModal', '');
        setValue('paisModal', '');
        setValue('cobrancaModal', { id: 'cobrancaModal', selecionado: false });
        setValue('entregaModal', { id: 'entregaModal', selecionado: false });
      }

      const firstInput = document.getElementById('cepModal');
      if (firstInput) firstInput.focus();
    }, [firstInputRef, indexEndereco, getValue, setValue]);

    return (
      <>
        <Col xl={4} lg={4} md={4} sm={4} xs={12}>
          <InputCep
            type="text"
            id={cepCampoId || 'cepModal'}
            name={cepCampoId || 'cepModal'}
            label={t('CEP')}
            placeholder={t('Digite o CEP')}
            getCepData={getCepData}
            control={control}
            error={errors.cepModal}
            disabled={readonly}
            defaultValue=""
          />
        </Col>
        <Col xl={8} lg={8} md={8} sm={8} xs={12}>
          <InputNull
            type="text"
            id={logradouroCampoId || 'logradouroModal'}
            name={logradouroCampoId || 'logradouroModal'}
            label={t('Logradouro')}
            placeholder={t('Rua, avenida, etc')}
            maxLength={60}
            control={control}
            // error={errors.logradouroModal}
            error={undefined}
            disabled={readonly}
            defaultValue=""
          />
        </Col>

        <Col xl={3} lg={3} md={3} sm={3} xs={12}>
          <InputNull
            type="text"
            id={numeroCampoId || 'numeroModal'}
            name={numeroCampoId || 'numeroModal'}
            label={t('Número')}
            placeholder={t('Digite o número')}
            maxLength={60}
            control={control}
            // error={errors.numeroModal}
            error={undefined}
            disabled={readonly}
            defaultValue=""
          />
        </Col>
        <Col xl={4} lg={4} md={4} sm={4} xs={12}>
          <InputNull
            type="text"
            id={complementoCampoId || 'complementoModal'}
            name={complementoCampoId || 'complementoModal'}
            label={t('Complemento')}
            placeholder={t('Digite o complemento')}
            maxLength={60}
            control={control}
            // error={errors.complementoModal}
            error={null}
            disabled={readonly}
            defaultValue=""
          />
        </Col>
        <Col xl={5} lg={5} md={5} sm={5} xs={12}>
          <InputNull
            type="text"
            id={bairroCampoId || 'bairroModal'}
            name={bairroCampoId || 'bairroModal'}
            label={t('Bairro')}
            placeholder={t('Digite o bairro')}
            maxLength={60}
            control={control}
            // error={errors.bairroModal}
            error={undefined}
            disabled={readonly}
            defaultValue=""
          />
        </Col>

        <Col xl={9} lg={9} md={9} sm={9} xs={12}>
          <SelectCidade
            ref={selectCidadeRef}
            type="text"
            id={cidadeCampoId || 'cidadeModal'}
            name={cidadeCampoId || 'cidadeModal'}
            label={t('Cidade')}
            required
            placeholder={t('Digite a cidade')}
            control={control}
            error={errors.cidadeModal}
            // error={undefined}
            isDisabled={readonly}
            getSelectedCidade={getSelectedCidade}
            getValue={getValue}
            defaultValue=""
            paisKey={paisCampoId || 'pais'}
          />
        </Col>
        <Col xl={3} lg={3} md={3} sm={3} xs={12}>
          <InputPadrao
            type="text"
            id={estadoCampoId || 'estadoModal'}
            name={estadoCampoId || 'estadoModal'}
            label={t('Estado')}
            required
            maxLength={50}
            error={errors.estadoModal}
            // error={undefined}
            disabled
            control={control}
          />
        </Col>

        <Col
          xl={4}
          lg={4}
          md={4}
          sm={4}
          xs={12}
          style={{ marginTop: 'auto', marginBottom: 'auto' }}
        >
          <SelectPais
            type="text"
            id={paisCampoId || 'paisModal'}
            name={paisCampoId || 'paisModal'}
            label={t('País')}
            required
            placeholder={t('Digite o país')}
            control={control}
            error={errors.paisModal}
            // error={undefined}
            isDisabled={readonly}
            getSelectedPais={getSelectedPais}
          />
        </Col>
        <Col
          xl={3}
          lg={3}
          md={3}
          sm={3}
          xs={5}
          style={{ marginTop: '20px', marginBottom: 'auto' }}
        >
          <span style={{ fontWeight: 'bolder', fontSize: '13px' }}>
            Definir este endereço como principal para:
          </span>
        </Col>
        <Col
          xl={2}
          lg={2}
          md={2}
          sm={2}
          xs={3}
          style={{
            display: 'flex',
            paddingLeft: '5px',
            marginTop: '30px',
            marginBottom: 'auto',
          }}
        >
          <CheckBoxDefaultMappedField
            id={entregaCampoId || 'entregaModal'}
            name={entregaCampoId || 'entregaModal'}
            label=""
            control={control}
            defaultValue={false}
            campoDinamicoId={entregaCampoId || 'entregaModal'}
            readonly={readonly}
            // handleChange={handleChange}
          />
          <span style={{ marginLeft: '5px', fontWeight: 'bold' }}>Entrega</span>
        </Col>
        <Col
          xl={2}
          lg={2}
          md={2}
          sm={2}
          xs={3}
          style={{ display: 'flex', marginTop: '30px', marginBottom: 'auto' }}
        >
          <CheckBoxDefaultMappedField
            id={cobrancaCampoId || 'cobrancaModal'}
            name={cobrancaCampoId || 'cobrancaModal'}
            label=""
            control={control}
            defaultValue={false}
            campoDinamicoId={cobrancaCampoId || 'cobrancaModal'}
            readonly={readonly}
            // handleChange={handleChange}
          />
          <span style={{ marginLeft: '5px', fontWeight: 'bold' }}>
            Cobrança
          </span>
        </Col>
      </>
    );
  }
);

export default EnderecoCobranca;
