import { Avatar, Box, Flex, Icon, Text } from '@chakra-ui/react';

import { TextValor } from 'components/PDV/Text/TextValor';
import { FiSearch } from 'react-icons/fi';
import { Link } from 'components/update/Link';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';

interface FaturaItemProps {
  faturaId: string;
  icone: string;
  nome: string;
  totalFatura: number;
  vencimento: string;
}

export function FaturaItem({
  faturaId,
  icone,
  nome,
  totalFatura,
  vencimento,
}: FaturaItemProps) {
  return (
    <Box bg="white" boxShadow="0px 0px 6px #00000034" borderRadius="md" p="6">
      <Box>
        <Avatar src={icone} borderRadius="full" boxSize="12" />
        <Text fontSize="lg" fontWeight="bold" mt="1.5">
          {nome}
        </Text>
      </Box>

      <Flex mt="5" alignItems="center" justifyContent="space-between">
        <Box>
          <Text fontSize="xs" color="gray.700">
            Total da fatura
          </Text>

          <TextValor
            valor={totalFatura}
            casasDecimais={2}
            fontSize="sm"
            fontWeight="bold"
            color="aquamarine.600"
          />
        </Box>

        <Box ml="2">
          <Text fontSize="xs" color="gray.700">
            Vencimento
          </Text>

          <Text
            fontSize="sm"
            color="gray.700"
            fontWeight="bold"
            lineHeight="none"
          >
            {vencimento}
          </Text>
        </Box>
      </Flex>

      <Flex justifyContent="space-between" alignItems="center" mt="5">
        <Link
          href={SubstituirParametroRota(
            ConstanteRotas.FATURAS_DETALHES,
            'id',
            faturaId
          )}
          fontSize="sm"
          fontWeight="light"
        >
          Ver fatura completa
        </Link>

        <Icon as={FiSearch} boxSize="4" />
      </Flex>
    </Box>
  );
}
