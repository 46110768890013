export type TipoValor = 1 | 2;

const TipoValorEnum = {
  REAIS: 1,
  PORCENTAGEM: 2,

  properties: {
    1: { name: 'R$', value: 1 },
    2: { name: '%', value: 2 },
  },
};

export default TipoValorEnum;
