export const setDateMaxHours = (date: Date) => {
  const newDate = date;

  newDate.setHours(23, 59, 59, 999);

  return newDate;
};

export const setDateStringMaxHours = (date: string) => {
  return new Date(`${date} 23:59:59:999`);
};

export const setDateMinHours = (date: Date) => {
  const newDate = date;

  newDate.setHours(0, 0, 0, 0);

  return newDate;
};

export const setDateStringMinHours = (date: string) => {
  return new Date(`${date} 00:00`);
};
