import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export type FormData = {
  descricao: string;
  valor: number;
  lojas: string[] | undefined[];
  ativo: boolean;
};

export const defaultValues = {
  descricao: '',
  valor: 0,
  ativo: true,
};

const schema = yup.object({
  descricao: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  valor: yup.number(),
  ativo: yup.boolean(),
});

export const yupResolver = yupResolverInstance(schema);
