import {
  Box,
  Text,
  Flex,
  Icon,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Tfoot,
  Stack,
  Checkbox,
  Divider,
  Button,
} from '@chakra-ui/react';
import { Dispatch, MutableRefObject, SetStateAction, useState } from 'react';
import { RiShareLine } from 'react-icons/ri';

import { DecimalMask } from 'helpers/format/fieldsMasks';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';
import { formatDate } from 'helpers/format/formatStringDate';
import { enumTypeCarne } from 'constants/enum/enumTypeCarne';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { ModalCompartilhar } from 'components/Modal/ModalCompartilhar';
import { FinanceiroAPagar, FinanceiroHistoricoCliente } from 'icons';
import ImpressaoCarnePromissoria from 'components/Impressao/Carne';
import { ModalDetalhesContaPagar } from 'pages/ContasPagar/Listar/ModalDetalhesContaPagar';
import { InformacaoStatusVencimento } from 'pages/ContasPagar/Listar/types';

import {
  MovimentacaoFinanceiraBaixa,
  ObterContasEmAbertoResponse,
} from './validationForm';
import { ListagemRecebimentoContasMobile } from './mobile/listagemRecebimentoContasMobile';
import { useCompartilharRecebimentos } from './hooks';

type ListagemRecebimentoContasResponse = {
  informacoesRecebimentoContas: ObterContasEmAbertoResponse | undefined;
  setInformacoesRecebimentoContas: Dispatch<
    SetStateAction<ObterContasEmAbertoResponse | undefined>
  >;
  isSmallerThan1200: boolean;
  clienteFornecedorId: string;
  isInformacoesRecebimentoContasLoading?: boolean;
  zerarMultaJuros?: MutableRefObject<boolean>;
};

export function ListagemRecebimentoContas({
  isSmallerThan1200,
  informacoesRecebimentoContas,
  setInformacoesRecebimentoContas,
  clienteFornecedorId,
  isInformacoesRecebimentoContasLoading,
  zerarMultaJuros,
}: ListagemRecebimentoContasResponse) {
  const { casasDecimais } = usePadronizacaoContext();
  const [deveSelecionarTodosItens, setDeveSelecionarTodosItens] = useState(
    false
  );
  const [operacaoId, setOperacaoId] = useState<string>('');

  const {
    impressaoCarneRef,
    impressaoCarneContainer,
    typeCarne,
    handleImpressaoCarne,
  } = useCompartilharRecebimentos();

  const informacoesStatusVencimento: {
    [key: string]: InformacaoStatusVencimento;
  } = {
    contaPagar: {
      corFundo: 'white',
      corFonte: 'aquamarine.600',
      corTooltip: 'aquamarine.600',
      icone: FinanceiroAPagar,
      tamanhoTooltip: '32',
      descricaoConta: 'Conta a pagar',
    },
  };

  const informacaoStatusVencimento = (() => {
    return informacoesStatusVencimento.contaPagar;
  })();

  function handleToggleItemSelecionado(index: number) {
    if (informacoesRecebimentoContas) {
      const novasMovimentacoesFinanceiras = [
        ...informacoesRecebimentoContas.movimentacaoFinanceira,
      ];

      const itemSelecionado = novasMovimentacoesFinanceiras[index];

      novasMovimentacoesFinanceiras.splice(index, 1, {
        ...itemSelecionado,
        selecionado: !itemSelecionado.selecionado,
      });

      setInformacoesRecebimentoContas((valorAnterior) =>
        valorAnterior
          ? {
              ...valorAnterior,
              movimentacaoFinanceira: novasMovimentacoesFinanceiras,
            }
          : undefined
      );
    }
  }

  async function handleAbrirModalDetalhe(indice: number) {
    const indexes = informacoesRecebimentoContas?.movimentacaoFinanceira
      .map(({ movimentacaoFinanceiraId }, index) => ({
        index,
        movimentacaoFinanceiraId,
      }))
      .filter((item) => item.movimentacaoFinanceiraId)
      .map((item) => item.index);

    const obterMovimentacaoFinanceiraId = (indexes || []).map(
      (index) =>
        informacoesRecebimentoContas?.movimentacaoFinanceira[index]
          .movimentacaoFinanceiraId
    );

    await ModalDetalhesContaPagar({
      id: obterMovimentacaoFinanceiraId[indice] || '',
      informacaoStatusVencimento,
      naoExibirDetalhesConta: true,
      casasDecimaisValor: casasDecimais.casasDecimaisValor,
    });
  }

  function handleToggleDeveSelecionarTodosItens() {
    if (informacoesRecebimentoContas) {
      setInformacoesRecebimentoContas((valorAnterior) =>
        valorAnterior
          ? {
              ...valorAnterior,
              movimentacaoFinanceira: (
                valorAnterior.movimentacaoFinanceira || []
              ).map((movimentacao) => ({
                ...movimentacao,
                selecionado: !deveSelecionarTodosItens,
              })),
            }
          : undefined
      );
      setDeveSelecionarTodosItens(!deveSelecionarTodosItens);
    }
  }

  const printCarne = (movimentacaoFinanceira: MovimentacaoFinanceiraBaixa) => {
    setOperacaoId(movimentacaoFinanceira.operacaoId || '');
    ModalCompartilhar({
      items: [
        {
          titulo: 'Impressão carnê normal',
          onClickDirect: async () => {
            await handleImpressaoCarne(
              enumTypeCarne.NORMAL,
              movimentacaoFinanceira.movimentacaoFinanceiraId
            );
          },
        },
        {
          titulo: 'Impressão carnê promissória',
          onClickDirect: async () => {
            await handleImpressaoCarne(
              enumTypeCarne.PROMISSORIA,
              movimentacaoFinanceira.movimentacaoFinanceiraId
            );
          },
        },
      ],
    });
  };

  return (
    <>
      {(informacoesRecebimentoContas?.movimentacaoFinanceira || []).length >
      0 ? (
        <>
          {isSmallerThan1200 && (
            <Stack
              borderRadius="md"
              padding="5"
              mt="5"
              mb="5"
              bg="primary.500"
              w="full"
            >
              <Flex justifyContent="space-between">
                <Flex justifyContent="left" alignItems="baseline">
                  <Text mr="2" fontSize="xs" color="white" fontWeight="light">
                    Valor total:
                  </Text>
                  <Flex
                    alignItems="baseline"
                    gap="1"
                    fontWeight="bold"
                    color="secondary.300"
                  >
                    <Text fontSize="xs">R$</Text>
                    <Text fontSize="md">
                      {DecimalMask(
                        informacoesRecebimentoContas
                          ? informacoesRecebimentoContas?.movimentacaoFinanceira.reduce(
                              (acc, curr) =>
                                acc +
                                (curr?.valorOriginal || 0) +
                                (curr?.juros || 0) +
                                (curr?.multa || 0),
                              0
                            )
                          : 0,
                        2,
                        2
                      )}
                    </Text>
                  </Flex>
                </Flex>
                <Box>
                  <Icon
                    color="white"
                    fontSize="md"
                    as={FinanceiroHistoricoCliente}
                  />
                </Box>
              </Flex>
              <Divider color="white" w="full" />
              <Box>
                <Flex
                  gap="1"
                  mb="1"
                  justifyContent="left"
                  alignItems="baseline"
                  color="white"
                  fontSize="sm"
                >
                  <Text
                    mr="2"
                    w="80px"
                    fontSize="xs"
                    fontWeight="light"
                    textAlign="right"
                  >
                    Valor original:
                  </Text>
                  <Text fontSize="2xs" fontWeight="light">
                    R$
                  </Text>
                  <Text fontWeight="normal">
                    {DecimalMask(
                      informacoesRecebimentoContas
                        ? informacoesRecebimentoContas?.movimentacaoFinanceira.reduce(
                            (acc, curr) => acc + (curr?.valorOriginal || 0),
                            0
                          )
                        : 0,
                      2,
                      2
                    )}
                  </Text>
                </Flex>
                <Flex
                  gap="1"
                  mb="1"
                  justifyContent="left"
                  alignItems="baseline"
                  color="white"
                  fontSize="sm"
                >
                  <Text
                    mr="2"
                    w="80px"
                    fontSize="xs"
                    textAlign="right"
                    fontWeight="light"
                  >
                    Multa:
                  </Text>
                  <Text fontSize="2xs" fontWeight="light">
                    R$
                  </Text>
                  <Text>
                    {DecimalMask(
                      informacoesRecebimentoContas
                        ? informacoesRecebimentoContas?.movimentacaoFinanceira.reduce(
                            (acc, curr) => acc + (curr?.multa || 0),
                            0
                          )
                        : 0,
                      2,
                      2
                    )}
                  </Text>
                </Flex>
                <Flex
                  gap="1"
                  justifyContent="left"
                  alignItems="baseline"
                  color="white"
                  fontSize="sm"
                >
                  <Text
                    mr="2"
                    fontSize="xs"
                    w="80px"
                    textAlign="right"
                    fontWeight="light"
                  >
                    Juros:
                  </Text>
                  <Text fontSize="2xs" fontWeight="light">
                    R$
                  </Text>
                  <Text fontSize="sm">
                    {DecimalMask(
                      informacoesRecebimentoContas
                        ? informacoesRecebimentoContas?.movimentacaoFinanceira.reduce(
                            (acc, curr) => acc + (curr?.juros || 0),
                            0
                          )
                        : 0,
                      2,
                      2
                    )}
                  </Text>
                </Flex>
              </Box>
            </Stack>
          )}
          {isSmallerThan1200 ? (
            <>
              {clienteFornecedorId && (
                <>
                  {informacoesRecebimentoContas?.movimentacaoFinanceira.map(
                    (movimentacaoFinanceira, index) => {
                      return (
                        <ListagemRecebimentoContasMobile
                          index={index}
                          movimentacaoFinanceira={movimentacaoFinanceira}
                          handleToggleItemSelecionado={
                            handleToggleItemSelecionado
                          }
                          printCarne={printCarne}
                        />
                      );
                    }
                  )}
                </>
              )}
            </>
          ) : (
            <Box
              mt="20px"
              w="full"
              overflow="auto"
              border="1px"
              borderColor="gray.100"
              borderRadius="md"
            >
              <Table
                boxShadow="md"
                p="6"
                rounded="md"
                variant=""
                bg="white"
                borderRadius="md"
                sx={{
                  '& th, & td': {
                    position: 'relative',
                    _after: {
                      content: '" "',
                      left: 0,
                      top: 0,
                      h: 'full',
                      w: 'full',
                      borderBottom: '1px',
                      borderColor: 'gray.100',
                    },
                  },
                  '& th:first-child, & td:first-child': {
                    _after: {
                      left: 3,
                    },
                  },
                  '& th:last-child, & td:last-child': {
                    _after: {
                      right: 3,
                      left: 'auto',
                    },
                  },
                }}
              >
                {isInformacoesRecebimentoContasLoading && <LoadingPadrao />}
                <Thead color="gray.700">
                  <Th whiteSpace="nowrap">
                    <Checkbox
                      onChange={() => handleToggleDeveSelecionarTodosItens()}
                      isChecked={deveSelecionarTodosItens}
                      boxShadow="inset 0px 3px 6px #00000029"
                      colorScheme="primary"
                      bg="white"
                      mb="0"
                    />
                  </Th>
                  <Th fontSize="xs" whiteSpace="nowrap">
                    Vencimento
                  </Th>
                  <Th fontSize="xs" whiteSpace="nowrap">
                    Operação
                  </Th>
                  <Th fontSize="xs" whiteSpace="nowrap">
                    Valor original
                  </Th>
                  <Th fontSize="xs" whiteSpace="nowrap">
                    Multa
                  </Th>
                  <Th fontSize="xs" whiteSpace="nowrap">
                    Juros
                  </Th>
                  <Th fontSize="xs" whiteSpace="nowrap">
                    Valor total
                  </Th>
                  <Th
                    fontSize="xs"
                    whiteSpace="nowrap"
                    textAlign="end"
                    w="90px"
                  />
                  <Tr>
                    <Th padding="0" colSpan={8}>
                      <Divider w="full" />
                    </Th>
                  </Tr>
                </Thead>

                {informacoesRecebimentoContas?.movimentacaoFinanceira.map(
                  (movimentacaoFinanceira, index) => {
                    return (
                      <>
                        {movimentacaoFinanceira?.movimentacaoFinanceiraId ? (
                          <Tbody>
                            <Tr>
                              <Td>
                                <Checkbox
                                  isChecked={
                                    movimentacaoFinanceira?.selecionado
                                  }
                                  onChange={() =>
                                    handleToggleItemSelecionado(index)
                                  }
                                  boxShadow="inset 0px 3px 6px #00000029"
                                  colorScheme="primary"
                                  bg="white"
                                  mb="0"
                                />
                              </Td>
                              <Td fontSize="md">
                                <Button
                                  variant="link"
                                  onClick={() => handleAbrirModalDetalhe(index)}
                                >
                                  {formatDate(
                                    movimentacaoFinanceira?.dataVencimento
                                  )}
                                </Button>
                              </Td>

                              <Td fontSize="md">
                                <Text>
                                  {movimentacaoFinanceira.numeroOperacao}
                                </Text>
                                <Text fontSize="xs">
                                  Parcela: {movimentacaoFinanceira.parcela}
                                </Text>
                              </Td>

                              <Td fontSize="md">
                                <Flex w="83px" justifyContent="flex-end">
                                  <Text mr="5px">R$</Text>
                                  {`${DecimalMask(
                                    movimentacaoFinanceira.valorOriginal,
                                    2,
                                    2
                                  )}`}{' '}
                                </Flex>
                              </Td>

                              <Td fontSize="md">
                                <Flex w="35px" justifyContent="flex-end">
                                  <Text mr="5px">R$</Text>
                                  {`${DecimalMask(
                                    movimentacaoFinanceira.multa,
                                    2,
                                    2
                                  )}`}
                                </Flex>
                              </Td>
                              <Td fontSize="md">
                                <Flex w="35px" justifyContent="flex-end">
                                  <Text mr="5px">R$</Text>
                                  {`${DecimalMask(
                                    movimentacaoFinanceira.juros,
                                    2,
                                    2
                                  )}`}
                                </Flex>
                              </Td>

                              <Td fontSize="md" w="155px">
                                <Flex w="70px" justifyContent="flex-end">
                                  <Text mr="5px">R$</Text>
                                  {`${DecimalMask(
                                    (movimentacaoFinanceira?.valorOriginal ||
                                      0) +
                                      movimentacaoFinanceira.juros +
                                      movimentacaoFinanceira.multa,
                                    2,
                                    2
                                  )}`}
                                </Flex>
                              </Td>
                              <Td w="90px">
                                <Flex justify="flex-end" pr="12px">
                                  <Icon
                                    as={RiShareLine}
                                    padding="6px"
                                    borderRadius="md"
                                    onClick={() => {
                                      printCarne(movimentacaoFinanceira);
                                    }}
                                    _hover={{
                                      cursor: 'pointer',
                                      backgroundColor: 'gray.100',
                                    }}
                                    fontSize="28px"
                                  />
                                </Flex>
                              </Td>
                            </Tr>
                            <Tr
                              _hover={{}}
                              sx={{
                                '& tr': {
                                  height: '0px !important',
                                },
                              }}
                            >
                              <Td padding="0" colSpan={8}>
                                <Divider w="full" />
                              </Td>
                            </Tr>
                          </Tbody>
                        ) : (
                          <></>
                        )}
                      </>
                    );
                  }
                )}
                <Tfoot
                  sx={{
                    paddingTop: '0',
                    '& th, & td': {
                      position: 'relative',

                      _after: {
                        content: '" "',
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        h: 'full',
                        w: 'full',
                        borderBottom: '0',
                        borderColor: 'white',
                      },
                    },
                    '& th:first-child, & td:first-child': {
                      _after: {
                        left: 3,
                      },
                    },
                    '& th:last-child, & td:last-child': {
                      _after: {
                        right: 3,
                        left: 'auto',
                      },
                    },
                  }}
                >
                  <Tr _hover={{}}>
                    <Td />
                    <Td />
                    <Td />
                    <Td>
                      <Box>
                        <Text>Valor original</Text>
                        <Flex w="83px" justifyContent="flex-end">
                          <Text mr="5px">R$</Text>
                          <Text fontSize="md" fontWeight="bold">{`${DecimalMask(
                            informacoesRecebimentoContas
                              ? Number(
                                  informacoesRecebimentoContas?.movimentacaoFinanceira.reduce(
                                    (acc, curr) =>
                                      acc + (curr?.valorOriginal || 0),
                                    0
                                  )
                                )
                              : 0,
                            2,
                            2
                          )}`}</Text>
                        </Flex>
                      </Box>
                    </Td>
                    <Td>
                      <Text>Multa</Text>
                      <Flex w="35px" justifyContent="flex-end">
                        <Text mr="5px">R$</Text>
                        <Text
                          fontSize="md"
                          color="red.500"
                          fontWeight="bold"
                        >{`${DecimalMask(
                          informacoesRecebimentoContas &&
                            !zerarMultaJuros?.current
                            ? informacoesRecebimentoContas?.movimentacaoFinanceira.reduce(
                                (acc, curr) => acc + (curr?.multa || 0),
                                0
                              )
                            : 0,
                          2,
                          2
                        )}`}</Text>
                      </Flex>
                    </Td>
                    <Td>
                      <Text>Juros</Text>
                      <Flex w="35px" justifyContent="flex-end">
                        <Text mr="5px">R$</Text>
                        <Text
                          fontSize="md"
                          color="red.500"
                          fontWeight="bold"
                        >{`${DecimalMask(
                          informacoesRecebimentoContas &&
                            !zerarMultaJuros?.current
                            ? informacoesRecebimentoContas?.movimentacaoFinanceira.reduce(
                                (acc, curr) => acc + (curr?.juros || 0),
                                0
                              )
                            : 0,
                          2,
                          2
                        )}`}</Text>
                      </Flex>
                    </Td>
                    <Td>
                      <Text>Valor total</Text>
                      <Flex w="70px" justifyContent="flex-end">
                        <Text mr="5px">R$</Text>
                        <Text
                          fontSize="md"
                          color="aquamarine.600"
                          fontWeight="bold"
                        >{`${DecimalMask(
                          informacoesRecebimentoContas
                            ? Number(
                                informacoesRecebimentoContas?.movimentacaoFinanceira.reduce(
                                  (acc, curr) =>
                                    acc +
                                    (curr?.valorOriginal || 0) +
                                    (curr?.juros || 0) +
                                    (curr?.multa || 0),
                                  0
                                )
                              )
                            : 0,
                          2,
                          2
                        )}`}</Text>
                      </Flex>
                    </Td>
                  </Tr>
                </Tfoot>
              </Table>
            </Box>
          )}
          <Box display="none">
            <ImpressaoCarnePromissoria
              operacaoId={operacaoId}
              ref={impressaoCarneRef}
              componentRef={impressaoCarneContainer}
              type={typeCarne}
            />
          </Box>
        </>
      ) : (
        <Box mt="20px" padding="15px" borderRadius="4px" bg="white" w="full">
          Nenhum resultado foi encontrado
        </Box>
      )}
    </>
  );
}
