import { Grid, Box, Text, Flex, useMediaQuery, HStack } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import getOptionsByEnum from 'helpers/format/getOptionsByEnum';
import { TipoCalculoEnum } from 'constants/enum/tipoCalculo';

import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import Select from 'components/PDV/Select/SelectPadrao';
import { InfoTooltip } from 'components/update/Tooltip/InfoTooltip';
import { Switch } from 'components/update/Switch';
import { NumberInput } from 'components/update/Input/NumberInput';

export const Regras = ({ isReadOnly }: { isReadOnly: boolean }) => {
  const [isLargerThan1200] = useMediaQuery('(min-width: 1200px)');
  const { watch } = useFormContext();

  const padronizarPreco = watch('padronizarPreco');

  return (
    <Box w="full">
      <Text
        as="label"
        lineHeight="none"
        mb="1"
        fontSize="sm"
        fontWeight="semibold"
      >
        Regra
      </Text>
      <Box
        borderRadius="md"
        bg="gray.50"
        border="1px"
        borderColor="gray.100"
        p={{ base: 4, sm: 6, md: 8 }}
      >
        <SimpleGridForm columns={{ base: 1, lg: 2 }}>
          <SimpleGridForm>
            <Select
              id="tipoCalculo"
              name="tipoCalculo"
              label="Tipo de cálculo"
              required
              placeholder="Selecione"
              options={getOptionsByEnum(TipoCalculoEnum)}
              colSpan={{ base: 8 }}
              isDisabled={isReadOnly}
            />

            <NumberInput
              name="percentual"
              label="Percentual"
              leftElement="%"
              precision={5}
              colSpan={4}
              isDisabled={isReadOnly}
            />
          </SimpleGridForm>

          <Flex justifyContent={{ base: 'flex-start', lg: 'flex-end' }}>
            <Grid
              rowGap={{ base: 3, sm: 6, md: 8 }}
              columnGap={4}
              templateColumns={{
                base: '1fr',
                lg: '1fr auto',
              }}
              w={{ base: 'full', lg: 'fit-content' }}
            >
              {isLargerThan1200 && (
                <HStack spacing="1" alignItems="center" h="full">
                  <Text
                    as="label"
                    htmlFor="padronizarPreco"
                    mb="0"
                    lineHeight="none"
                    fontSize="sm"
                    fontWeight="semibold"
                    whiteSpace="nowrap"
                    opacity={!isReadOnly ? 1 : 0.5}
                  >
                    Padronizar casas decimais
                  </Text>
                  <InfoTooltip label="Após aplicar o cálculo de percentual, o sistema irá corrigir as casas decimais conforme a regra informada abaixo." />
                </HStack>
              )}

              <Switch
                id="padronizarPreco"
                name="padronizarPreco"
                label={isLargerThan1200 ? '' : 'Padronizar casas decimais'}
                width={isLargerThan1200 ? 'full' : 'fit-content'}
                helperText="Após aplicar o cálculo de percentual, o sistema irá corrigir as casas decimais conforme a regra informada abaixo."
                isDisabled={isReadOnly}
              />

              {isLargerThan1200 && (
                <Flex alignItems="center" h="full">
                  <Text
                    as="label"
                    htmlFor="arredondamentoAcima"
                    mb="0"
                    lineHeight="none"
                    fontSize="sm"
                    fontWeight="semibold"
                    whiteSpace="nowrap"
                    opacity={padronizarPreco && !isReadOnly ? 1 : 0.5}
                  >
                    Final maior ou igual a 0,50 arredondar para
                  </Text>
                </Flex>
              )}

              <NumberInput
                id="arredondamentoAcima"
                name="arredondamentoAcima"
                label={
                  isLargerThan1200
                    ? ''
                    : 'Final maior ou igual a 0,50 arredondar para'
                }
                leftElement="R$"
                scale={2}
                max={0.99}
                isDisabled={!padronizarPreco || isReadOnly}
              />

              {isLargerThan1200 && (
                <Flex alignItems="center" h="full">
                  <Text
                    as="label"
                    htmlFor="arredondamentoAbaixo"
                    mb="0"
                    lineHeight="none"
                    fontSize="sm"
                    fontWeight="semibold"
                    whiteSpace="nowrap"
                    opacity={padronizarPreco && !isReadOnly ? 1 : 0.5}
                  >
                    Final menor que 0,50 arredondar para
                  </Text>
                </Flex>
              )}

              <NumberInput
                id="arredondamentoAbaixo"
                name="arredondamentoAbaixo"
                label={
                  isLargerThan1200 ? '' : 'Final menor que 0,50 arredondar para'
                }
                leftElement="R$"
                scale={2}
                max={0.99}
                isDisabled={!padronizarPreco || isReadOnly}
              />
            </Grid>
          </Flex>
        </SimpleGridForm>
      </Box>
    </Box>
  );
};
