import React, { useState, useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { FormProvider } from 'react-hook-form';
import { Box } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import ProdutosFormularioProvider, {
  ProdutoRefProps,
  ProdutosFormularioContext,
} from 'store/Produtos/ProdutosFormulario';
import { ProdutoPrecoLoja, ProdutoProps } from 'types/produto';
import ConstanteRotas from 'constants/rotas';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import { ContainerListagem } from 'components/Layout/Listagem/containerListagem';
import { ModalDesistencia } from 'components/Modal/ModalDesistencia';

import { useForm, yupResolver } from '../validationForm';
import { InformacoesProdutos } from '../Types';
import { CadastroItem } from './CadastroItem';

type TParams = { id: string };

export type ProdutosProps = {
  referencia: string;
  tipoProduto: number;
  foto: string;
};

interface CadastrarProps extends RouteComponentProps<TParams> {
  cadastroExterno?: boolean;
  nomeProduto?: string;
  cadastroSucessoCallback?: (produto: ProdutoProps) => void;
  cadastroCancelar?: () => void;
  listProducts?: ProdutosProps;
  produtos?: InformacoesProdutos;
}

const Cadastrar = ({
  match,
  produtos,
  cadastroExterno = false,
  nomeProduto,
  listProducts,
  cadastroCancelar,
  cadastroSucessoCallback,
}: CadastrarProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isDuplicado, setIsDuplicado] = useState(false);

  const history = useHistory();

  const formMethods = useForm({
    resolver: yupResolver,
    defaultValues: {
      ...(nomeProduto ? { nome: nomeProduto } : {}),
      ...produtos,
      ...listProducts,
      valorUnitario: produtos?.valorUnitario ?? 0,
      cores: [],
      tamanhos: [],
    },
    shouldUnregister: true,
  });

  const { setValue } = formMethods;

  const processRemovedProductRoute = () => {
    if (cadastroCancelar) {
      cadastroCancelar();
    } else {
      history.push(ConstanteRotas.PRODUTO);
    }
  };

  const deleteProduct = async (produtoId: string) => {
    setIsLoading(true);
    const response = await api.delete<void, ResponseApi>(
      ConstanteEnderecoWebservice.PRODUTO_EXCLUIR,
      {
        params: { id: produtoId },
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item) => toast.success(item));
      }
      if (response.sucesso) {
        toast.success('O cadastro foi removido com sucesso.');
        processRemovedProductRoute();
        setIsLoading(false);
      }
    }
    setIsLoading(false);
  };

  const desistProduct = async (produtoId: string) => {
    await ModalDesistencia({
      title: 'Desistir do cadastro',
      labelButtonCancelar: 'Cancelar',
      labelButtonConfirmar: 'Excluir produto',
      textoMensagem:
        'Todos os dados informados serão removidos e o cadastro cancelado. \nDeseja excluir o produto?',
      callback: async () => {
        await deleteProduct(produtoId);
      },
    });
  };

  const handleCancelSubmit = async (idProduto: string) => {
    if (!idProduto) {
      processRemovedProductRoute();
    } else {
      desistProduct(idProduto);
    }
  };

  const handleSubmit = async (
    ref: React.RefObject<ProdutoRefProps>,
    nome: string,
    idProduto: string,
    precoPorLoja: ProdutoPrecoLoja[]
  ) => {
    if (ref.current?.onClick) {
      const sucess = await ref.current.onClick();

      if (sucess) {
        if (cadastroSucessoCallback) {
          cadastroSucessoCallback({
            nome,
            id: idProduto,
            produtoPrecoLojas: precoPorLoja,
          });
        } else {
          history.push(ConstanteRotas.PRODUTO);
        }
      }
    } else if (cadastroSucessoCallback) {
      cadastroSucessoCallback({
        nome,
        id: idProduto,
        produtoPrecoLojas: precoPorLoja,
      });
    } else {
      history.push(ConstanteRotas.PRODUTO);
    }
  };

  useEffect(() => {
    if (match.params.id) {
      setIsDuplicado(true);
    }
  }, [match]);

  useEffect(() => {
    setIsLoading(true);
    const getNcmImportacaoXml = async () => {
      if (produtos && produtos?.ncm) {
        let ncm = {
          codigo: '',
          descricao: '',
        };

        const response = await api.get<
          void,
          ResponseApi<
            {
              codigo: string;
              descricao: string;
            }[]
          >
        >(ConstanteEnderecoWebservice.NCM_LISTAR_SELECT, {
          params: { descricaoCodigo: produtos?.ncm },
        });

        if (response) {
          if (response.avisos) {
            response.avisos.forEach((item: string) => toast.warning(item));
          }

          if (response.sucesso) {
            ncm = {
              codigo: response.dados[0]?.codigo,
              descricao: response.dados[0]?.descricao,
            };
          }

          if (response.dados && response.sucesso) {
            setValue('codigoNcm', {
              label: `${ncm.codigo} - ${ncm.descricao}`,
              value: ncm.codigo,
            });
            setIsLoading(false);
          }
          setIsLoading(false);
        }
      }
      setIsLoading(false);
    };

    getNcmImportacaoXml();
  }, [produtos, setValue]);

  return (
    <Box h="100%" minH="99vh" bg="gray.100">
      <FormProvider {...formMethods}>
        <ProdutosFormularioProvider
          action="cadastrar"
          cadastroExterno={cadastroExterno}
          isLoading={isLoading}
          valueProdutoXlm={produtos}
          setIsLoading={setIsLoading}
          isDuplicado={isDuplicado}
        >
          <ProdutosFormularioContext.Consumer>
            {({ ref, nome, precoPorLoja, idProduto }) => {
              return (
                <ContainerListagem
                  formMethods={formMethods}
                  isForm={false}
                  isLoading={isLoading}
                  onSubmit={() =>
                    handleSubmit(ref, nome, idProduto, precoPorLoja)
                  }
                  textSubmit="Finalizar"
                  iconSubmit={false}
                  maxWidth="full"
                  containerPossuiFundo={false}
                  onSubmitReset={undefined}
                  onCancel={() => handleCancelSubmit(idProduto)}
                >
                  <CadastroItem deleteProduct={deleteProduct} />
                </ContainerListagem>
              );
            }}
          </ProdutosFormularioContext.Consumer>
        </ProdutosFormularioProvider>
      </FormProvider>
    </Box>
  );
};

export default Cadastrar;
