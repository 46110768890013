import { Text } from '@chakra-ui/react';

export const InfoMulta = () => {
  return (
    <Text
      border="1px solid black"
      borderTop="none"
      textAlign="center"
      fontSize="xs"
      w="100%"
      h="20px"
    />
  );
};
