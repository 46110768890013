import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

interface MovimentacaoFinacanceiraFiscalProps {
  cnpjPagamento?: string;
  ufPagamento?: string;
  identificadorAgrupamento: string;
}

export const updateInformacoesFiscalNaMovimentacaoFinanceira = async (
  movimentacaoFinacanceiraFiscalData: MovimentacaoFinacanceiraFiscalProps
): Promise<boolean> => {
  const url = ConstanteEnderecoWebservice.MOVIMENTACAO_FINANCEIRA_INFORMACOES_FISCAIS.replace(
    'identificadorAgrupamento',
    movimentacaoFinacanceiraFiscalData.identificadorAgrupamento
  );
  const response = await api.put<void, ResponseApi>(url, {
    cnpjPagamento: movimentacaoFinacanceiraFiscalData.cnpjPagamento,
    ufPagamento: movimentacaoFinacanceiraFiscalData.ufPagamento,
  });

  if (response) {
    if (response.avisos) {
      response.avisos.forEach((item: string) => toast.warning(item));
      return false;
    }
    if (response.sucesso) {
      toast.success('Informações de pagamento atualizadas com sucesso!');
      return true;
    }
  }
  return false;
};
