import { Flex, GridItem, Text } from '@chakra-ui/react';
import { LabelSelect } from '../components/LabelSelect';
import { Cor } from '../components/Cor';

export const TelasSelecao = () => {
  return (
    <GridItem
      colSpan={[12, 12, 6, 4, 4]}
      minW={['100%', '100%', '50%', '373px']}
    >
      <Text fontWeight="bold">Telas de seleção</Text>
      <Flex
        bg="#E8E8E8"
        minH={['inherit', 'inherit', '493px']}
        padding="24px"
        pt="16px"
        borderRadius="5px"
        border="1px solid #CCCCCC"
        flexDir="column"
        gap="16px"
        height={['fit-content', 'full', 'full', 'full', 'fit-content']}
      >
        <Cor name="mainColor" label="Cor de fundo predominante" />
        <LabelSelect
          options={[
            {
              label: 'Branco',
              value: '#FFFFFF',
            },
            {
              label: 'Preto',
              value: '#000000',
            },
          ]}
          label="Cor do texto e ícones"
          name="navigationButtonTextColor"
        />
        <LabelSelect
          options={[
            {
              label: 'Branco',
              value: '#FFFFFF',
            },
            {
              label: 'Preto',
              value: '#000000',
            },
          ]}
          name="navigationButtonBackgroundColor"
          label=" Cor do botão de navegação"
        />
        <Cor
          name="forwardButtonBackgroundColor"
          label="Botão “principal” - cor do fundo"
        />
        <LabelSelect
          options={[
            {
              label: 'Branco',
              value: '#FFFFFF',
            },
            {
              label: 'Preto',
              value: '#000000',
            },
          ]}
          label="Botão “principal” - cor do texto"
          name="forwardButtonTextColor"
        />
        <LabelSelect
          options={[
            {
              label: 'Claro',
              value: '#FFFFFF80',
            },
            {
              label: 'Escuro',
              value: '#00000080',
            },
          ]}
          name="forwardButtonActionColor"
          showBorder={false}
          label="Botão “principal” - ação clique"
        />
      </Flex>
    </GridItem>
  );
};
