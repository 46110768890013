import React, { useState, useCallback, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Flex, GridItem, Box, Icon, Td, Tr } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import { shallowEqual } from 'helpers/validation/shallowEqual';
import { formatDateHourMinute } from 'helpers/format/formatStringDate';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import ConstanteFuncionalidades from 'constants/permissoes';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import { FiscalExportarXmlIcon } from 'icons';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import { ButtonCadastrarNovo } from 'components/Layout/ButtonCadastrarNovo';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import {
  PagedTable,
  PagedTableForwardRefData,
} from 'components/update/Table/PagedTable';
import {
  GridPaginadaConsulta,
  GridPaginadaRetorno,
} from 'components/Grid/Paginacao';

import { ModalDownload } from './ModalDownload';
import { ModalCadastro } from './ModalCadastro';
import { ExportarDocumentoProps } from '../hooks';
import { defaultValues } from './ModalCadastro/validationForm';

interface DocumentoFiscalExportacao {
  id: string;
  dataHoraCriacao: string;
  mes: number;
  ano: number;
  email: string;
  hashIdentificador: string;
}

interface DocumentoFiscalFiltros {
  mes: number;
  ano: number;
}

const Listar = () => {
  const formMethods = useForm({
    defaultValues,
  });

  const { handleSubmit } = formMethods;
  const childRef = useRef<PagedTableForwardRefData>(null);

  const [currentFilters, setCurrentFilters] = useState<DocumentoFiscalFiltros>(
    defaultValues
  );
  const [isLoading, setIsLoading] = useState(false);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [
    documentosFiscaisExportacao,
    setDocumentosFiscaisExportacao,
  ] = useState([] as DocumentoFiscalExportacao[]);

  const listMeses = Array.from({ length: 12 }, (_, index) => {
    const mes = index + 1;
    const mesFormatado = mes.toString().padStart(2, '0');

    return { value: mes, label: mesFormatado };
  });
  const listAnos = Array.from({ length: 6 }, (_, index) => {
    const ano = new Date().getFullYear() - index;

    return { value: ano, label: ano.toString() };
  });

  const handleReset = handleSubmit((data) => {
    const filterIsDirty = !shallowEqual(currentFilters, data);

    if (filterIsDirty) {
      setCurrentFilters(data);
    }
  });

  const handleRefresh = useCallback(() => {
    return childRef.current?.reload();
  }, []);

  const paginationHandle = useCallback(
    async (gridPaginadaConsulta: GridPaginadaConsulta) => {
      setIsLoading(true);

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<DocumentoFiscalExportacao>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.DOCUMENTO_FISCAL_EXPORTACAO_LISTAR_PAGINADO,
          gridPaginadaConsulta
        ),
        {
          params: currentFilters,
        }
      );

      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }
        if (response?.sucesso && response?.dados) {
          setTotalRegistros(response.dados.total);
          setDocumentosFiscaisExportacao(response.dados.registros);
        }
      }

      setIsLoading(false);
    },
    [currentFilters]
  );

  const handleDelete = useCallback(
    async (documentoFiscalExportacaoId: string) => {
      ModalConfirmacaoExcluir({
        callback: async (ok: boolean) => {
          if (ok) {
            setIsLoading(true);

            const response = await api.delete<void, ResponseApi>(
              ConstanteEnderecoWebservice.DOCUMENTO_FISCAL_EXPORTACAO_EXCLUIR,
              {
                params: { id: documentoFiscalExportacaoId },
              }
            );

            if (response) {
              if (response?.avisos) {
                response.avisos.forEach((aviso) => toast.warning(aviso));
              }
              if (response?.sucesso) {
                toast.success('A exportação foi removida com sucesso.');

                handleRefresh();
              }
            }

            setIsLoading(false);
          }
        },
      });
    },
    [handleRefresh]
  );

  const handleReenviarEmail = useCallback(
    async (documentoFiscalExportacaoId: string) => {
      setIsLoading(true);

      const response = await api.post<void, ResponseApi>(
        `${ConstanteEnderecoWebservice.DOCUMENTO_FISCAL_EXPORTACAO_REENVIAR_EMAIL}/${documentoFiscalExportacaoId}`
      );

      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }
        if (response?.sucesso) {
          toast.success('O e-mail foi reenviado com sucesso.');
        }
      }

      setIsLoading(false);
    },
    []
  );

  const handleAdicionarExportacao = useCallback(async () => {
    const sucesso = await ModalCadastro({
      anos: listAnos,
      meses: listMeses,
    });

    if (sucesso) {
      handleRefresh();
    }
  }, [handleRefresh, listAnos, listMeses]);

  const handleDownload = useCallback(
    ({ id, hashIdentificador }: ExportarDocumentoProps) => {
      ModalDownload({
        id,
        hashIdentificador,
      });
    },
    []
  );

  return (
    <>
      <FormProvider {...formMethods}>
        <SimpleGridForm alignItems="end">
          <GridItem colSpan={{ base: 12, sm: 3, lg: 2 }}>
            <SelectPadrao
              name="mes"
              label="Mês"
              placeholder="Selecione o mês"
              isDisabled={isLoading}
              options={listMeses}
              onSelect={() => {
                handleReset();
              }}
            />
          </GridItem>
          <GridItem colSpan={{ base: 12, sm: 3, lg: 2 }}>
            <SelectPadrao
              name="ano"
              label="Ano"
              placeholder="Selecione o ano"
              isDisabled={isLoading}
              options={listAnos}
              onSelect={() => {
                handleReset();
              }}
            />
          </GridItem>
          <GridItem
            colSpan={{ base: 12, sm: 3, lg: 8 }}
            as={Flex}
            justifyContent="end"
          >
            <Box width={{ base: 'full', lg: '200px' }}>
              <ButtonCadastrarNovo
                id="cadastrarNovo"
                name="cadastrarNovo"
                label="Exportar"
                px="20px"
                width="full"
                borderRadius="full"
                leftIcon={<Icon as={FiscalExportarXmlIcon} />}
                onClick={() => handleAdicionarExportacao()}
                funcionalidade={
                  ConstanteFuncionalidades.DOCUMENTO_FISCAL_EXPORTACAO_LISTAR
                }
              />
            </Box>
          </GridItem>
          <GridItem colSpan={12}>
            <PagedTable
              ref={childRef}
              loadColumnsData={paginationHandle}
              itemsTotalCount={totalRegistros}
              defaultKeyOrdered="DataHoraCriacao"
              tableHeaders={[
                {
                  content: 'Data',
                  key: 'DataHoraCriacao',
                  isOrderable: true,
                  width: '150px',
                },

                {
                  content: 'Período',
                  key: 'Periodo',
                  isOrderable: false,
                  width: '150px',
                },
                {
                  content: 'E-mail',
                  key: 'Email',
                  isOrderable: false,
                  width: 'auto',
                },
                {
                  content: 'Ações',
                  key: 'Acoes',
                  isOrderable: false,
                  width: '38px',
                },
              ]}
              renderTableRows={documentosFiscaisExportacao.map(
                ({
                  id,
                  dataHoraCriacao,
                  mes,
                  ano,
                  email,
                  hashIdentificador,
                }) => {
                  return (
                    <Tr key={id}>
                      <Td>{formatDateHourMinute(dataHoraCriacao)}</Td>
                      <Td>{`${String(mes).padStart(2, '0')}/${ano}`}</Td>
                      <Td>{email}</Td>
                      <Td>
                        <ActionsMenu
                          id="mostrarMais"
                          items={[
                            {
                              content: 'Reenviar e-mail',
                              onClick: () => {
                                handleReenviarEmail(id);
                              },
                              funcionalidade:
                                ConstanteFuncionalidades.DOCUMENTO_FISCAL_EXPORTACAO_LISTAR,
                            },
                            {
                              content: 'Download',
                              onClick: () => {
                                handleDownload({
                                  id,
                                  hashIdentificador,
                                  isDownloadExterno: false,
                                });
                              },
                              funcionalidade:
                                ConstanteFuncionalidades.DOCUMENTO_FISCAL_EXPORTACAO_LISTAR,
                            },
                            {
                              content: 'Remover',
                              onClick: () => {
                                handleDelete(id);
                              },
                              funcionalidade:
                                ConstanteFuncionalidades.DOCUMENTO_FISCAL_EXPORTACAO_LISTAR,
                            },
                          ]}
                        />
                      </Td>
                    </Tr>
                  );
                }
              )}
            />
          </GridItem>
        </SimpleGridForm>
      </FormProvider>
    </>
  );
};

export default Listar;
