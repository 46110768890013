import React, { useCallback, useState, useRef, useEffect } from 'react';
import {
  Button,
  GridItem,
  HStack,
  IconButton,
  Icon,
  Box,
  Text,
  VStack,
  Tr,
  Td,
  Flex,
  useMediaQuery,
} from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { useConferenciaEstoqueContainerContext } from 'store/ConferenciaEstoque/ConferenciaEstoqueContainer';
import { useConferenciaEstoqueEtapasContext } from 'store/ConferenciaEstoque/ConferenciaEstoqueEtapas';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import TipoConferenciaEnum from 'constants/enum/tipoConferencia';

import { GradeTamanhosIcon } from 'icons';
import { NumberInput } from 'components/update/Input/NumberInput';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import AsyncSelect from 'components/PDV/Select/AsyncSelectPadrao';
import Select from 'components/PDV/Select/SelectPadrao';
import {
  LoadMoreRowsParams,
  VirtualizedInfiniteTable,
  ForwardRefData,
  TableHeader,
} from 'components/update/Table/VirtualizedInfiniteTable';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { ModalGradeTamanhos } from 'components/update/Modal/ModalGradeTamanhos';
import { ModalEditarQuantidadeProduto } from 'components/update/Modal/ModalEditarQuantidadeProduto';
import { Container, Body, Footer } from 'components/update/Steps/StepContent';
import { ModalAtencao } from 'components/Modal/ModalAtencao';

import { CarregarArquivoColetor } from './CarregarArquivoColetor';
import { yupResolver, FormData } from './validationForm';

type ProdutoCorTamanhoResponse = {
  produtoCorTamanhoId: string;
  tamanho: string;
  padraoSistema: boolean;
  codigoBarrasInterno: string;
};

type ProdutoCorResponse = {
  nome: string;
  cor?: string;
  adicionarItemAutomaticamente: boolean;
  volumeUnitario: boolean;
  tamanhos: ProdutoCorTamanhoResponse[];
  produtoKit: boolean;
};

type ProdutoPaginadoRegistro = {
  produtoCorTamanhoId: string;
  codigoBarrasInterno: string;
  nome: string;
  cor?: string;
  tamanho?: string;
  quantidade: number;
  volumeUnitario: boolean;
};

type ProdutoPaginado = {
  totalProdutos: number;
  totalItens: number;
  registros: ProdutoPaginadoRegistro[];
};

type CadastrarConferenciaEstoqueItensResponse = {
  produtoCorTamanhoId: string;
  quantidadeTotal: number;
};

type ProdutoCor = {
  label: string;
  value: string;
  tamanhos: any[];
  volumeUnitario: boolean;
  produtoKit: boolean;
};

const produtosTableHeaders: TableHeader[] = [
  {
    key: 'codigoBarrasInterno',
    content: 'Código de barras',
    width: '15%',
    whiteSpace: 'nowrap',
  },
  {
    key: 'produto',
    content: 'Produtos',
    width: ['55%', '55%', '50%', '50%'],
  },
  { key: 'tamanho', content: 'Tamanho', width: '20%', textAlign: 'center' },
  {
    key: 'quantidade',
    content: 'Quantidade',
    isNumeric: true,
    width: '5%',
  },
  {
    key: 'acoes',
    content: 'Ações',
    width: ['5%', '5%', '10%', '10%'],
    textAlign: 'end',
  },
];

export function LancamentoProdutos() {
  const {
    conferenciaEstoqueId,
    descartarConferencia,
  } = useConferenciaEstoqueContainerContext();
  const {
    previousStep,
    nextStep,
    setExisteProdutos,
    nextStepRevisao,
    conferenciaEstoque,
  } = useConferenciaEstoqueEtapasContext();

  const { casasDecimais } = usePadronizacaoContext();

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues: { produtoCor: null, produtoCorTamanho: null, quantidade: 1 },
  });

  const tableRef = useRef<ForwardRefData>(null);

  const submitProdutoButtonRef = useRef<HTMLButtonElement>(null);

  const [produtoCorIsLoading, setProdutoCorIsLoading] = useState(false);

  const [isProdutosLoading, setIsProdutosLoading] = useState(false);
  const [itemsTotalCount, setItemsTotalCount] = useState(0);
  const [quantidadeTotal, setQuantidadeTotal] = useState(0);
  const [
    produtoSelecionado,
    setProdutoSelecionado,
  ] = useState<ProdutoCor | null>();
  const [produtos, setProdutos] = useState<ProdutoPaginadoRegistro[]>([]);
  const [
    produtoCorTamanhosJaCarregados,
    setProdutoCorTamanhosJaCarregados,
  ] = useState<string[]>([]);

  const produtoCor = formMethods.watch('produtoCor');

  const [isLargerThan520] = useMediaQuery('(min-width: 520px)');
  const [isLargerThan1148] = useMediaQuery('(min-width: 1148px)');

  const produtoCorTamanhoOptions = (produtoSelecionado?.tamanhos || []).map(
    (tamanho: ProdutoCorTamanhoResponse) => ({
      value: tamanho.produtoCorTamanhoId,
      label: tamanho.tamanho,
      codigoBarrasInterno: tamanho.codigoBarrasInterno,
      padraoSistema: tamanho.padraoSistema,
    })
  );
  const produtoCorTamanhoPadraoSistema =
    produtoCorTamanhoOptions.length === 1 &&
    produtoCorTamanhoOptions[0].padraoSistema;

  async function resetarLista() {
    if (tableRef.current) {
      setProdutos([]);
      setQuantidadeTotal(0);
      setItemsTotalCount(0);
      setProdutoCorTamanhosJaCarregados([]);

      await tableRef.current.reload();
    }
  }

  function handleVoltar() {
    previousStep();
  }

  function handleDescartarConferencia() {
    descartarConferencia();
  }

  const limparForm = useCallback(() => {
    formMethods.reset({
      produtoCor: null,
      produtoCorTamanho: null,
      quantidade: 1,
    });
  }, [formMethods]);

  async function handleRemoverConferenciaEstoqueItem(index: number) {
    ModalConfirmacaoExcluir({
      title: 'Remover produto',
      text:
        'Você tem certeza que deseja remover este produto da sua conferência?',
      submitText: 'Sim, remover',
      cancelText: 'Voltar',
      callback: async (ok: boolean) => {
        if (ok) {
          const { quantidade, produtoCorTamanhoId } = produtos[index];

          const response = await api.delete<void, ResponseApi>(
            ConstanteEnderecoWebservice.CONFERENCIA_ESTOQUE_EXCLUIR_ITEM,
            { params: { produtoCorTamanhoId } }
          );

          if (response) {
            if (response.avisos) {
              response.avisos.map((aviso: string) => toast.warning(aviso));
            }

            if (response.sucesso) {
              const newProdutos = [...produtos];

              newProdutos.splice(index, 1);

              setProdutos(newProdutos);

              setItemsTotalCount((prev) => prev - 1);

              setQuantidadeTotal((prev) => prev - quantidade);
            }
          }
        }
      },
    });
  }

  async function handleEditarQuantidadeItem(index: number) {
    const {
      quantidade,
      volumeUnitario,
      nome,
      cor,
      tamanho,
      produtoCorTamanhoId,
    } = produtos[index];

    const { quantidade: newQuantidade } = await ModalEditarQuantidadeProduto({
      casasDecimaisQuantidade: casasDecimais.casasDecimaisQuantidade,
      nomeProduto: nome,
      corDescricao: cor,
      tamanhoDescricao: tamanho,
      quantidadeAtual: quantidade,
      volumeUnitario,
    });

    formMethods.setFocus('produtoCor');

    const response = await api.put<void, ResponseApi>(
      ConstanteEnderecoWebservice.CONFERENCIA_ESTOQUE_ALTERAR_ITEM,
      {
        conferenciaEstoqueId,
        produtoCorTamanhoId,
        quantidade: newQuantidade,
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.map((aviso: string) => toast.warning(aviso));
      }

      if (response.sucesso) {
        const newProdutos = [...produtos];

        newProdutos.splice(index, 1);

        newProdutos.unshift({ ...produtos[index], quantidade: newQuantidade });

        setProdutos(newProdutos);

        setQuantidadeTotal((prev) => prev - quantidade + newQuantidade);
      }
    }
  }

  const cadastrarConferenciaEstoqueItens = useCallback(
    async (itens: ProdutoPaginadoRegistro[]) => {
      const response = await api.post<
        void,
        ResponseApi<CadastrarConferenciaEstoqueItensResponse[]>
      >(ConstanteEnderecoWebservice.CONFERENCIA_ESTOQUE_CADASTRAR_ITENS, {
        conferenciaEstoqueId,
        produtosCorTamanhoIdQuantidade: itens.map((item) => ({
          produtoCorTamanhoId: item.produtoCorTamanhoId,
          quantidade: item.quantidade,
        })),
      });

      if (response) {
        if (response.avisos) {
          response.avisos.map((aviso: string) => toast.warning(aviso));
        }

        if (response.sucesso) {
          let newItemsTotalCount = 0;

          setProdutos((prev) => {
            const newProdutos = [...prev];

            itens.forEach((item) => {
              const alreadyAddedProdutoCorTamanhoIndex = newProdutos.findIndex(
                (produto) =>
                  produto.produtoCorTamanhoId === item.produtoCorTamanhoId
              );

              if (alreadyAddedProdutoCorTamanhoIndex > -1) {
                const alreadyAddedProdutoCorTamanho =
                  newProdutos[alreadyAddedProdutoCorTamanhoIndex];

                newProdutos.splice(alreadyAddedProdutoCorTamanhoIndex, 1);

                newProdutos.unshift({
                  ...alreadyAddedProdutoCorTamanho,
                  quantidade:
                    alreadyAddedProdutoCorTamanho.quantidade + item.quantidade,
                });
              } else {
                const itemCadastrado = response.dados.find(
                  (val) => val.produtoCorTamanhoId === item.produtoCorTamanhoId
                );

                if (itemCadastrado) {
                  if (itemCadastrado.quantidadeTotal !== item.quantidade) {
                    setProdutoCorTamanhosJaCarregados(
                      (prevProdutoCorTamanhosJaCarregados) => [
                        ...prevProdutoCorTamanhosJaCarregados,
                        item.produtoCorTamanhoId,
                      ]
                    );
                  }

                  newProdutos.unshift({
                    ...item,
                    quantidade: itemCadastrado.quantidadeTotal,
                  });

                  newItemsTotalCount += 1;
                }
              }
            });

            return newProdutos;
          });

          setItemsTotalCount((prev) => prev + newItemsTotalCount);

          setQuantidadeTotal(
            (prev) =>
              prev + itens.reduce((acc, curr) => acc + curr.quantidade, 0)
          );

          return true;
        }
      }

      return false;
    },
    [conferenciaEstoqueId]
  );

  function handleAvancar() {
    nextStep();
  }

  async function handlePushGradeModal() {
    if (produtoCor) {
      const addedTamanhos = await ModalGradeTamanhos({
        produtoNome: produtoCor.label.split(' | ')[0],
        corDescricao: produtoCor.label.split(' | ')[1],
        casasDecimaisQuantidade: casasDecimais.casasDecimaisQuantidade,
        volumeUnitario: produtoCor.volumeUnitario,
        tamanhos: produtoCor.tamanhos,
      });

      formMethods.setFocus('produtoCor');

      if (addedTamanhos) {
        limparForm();

        const newProdutos: ProdutoPaginadoRegistro[] = [];

        addedTamanhos.forEach((addedTamanho) => {
          const produtoCorTamanho = produtoCor.tamanhos.find(
            (tamanho) =>
              tamanho.produtoCorTamanhoId === addedTamanho.produtoCorTamanhoId
          );

          if (produtoCorTamanho) {
            newProdutos.push({
              codigoBarrasInterno: produtoCorTamanho.codigoBarrasInterno,
              produtoCorTamanhoId: produtoCorTamanho.produtoCorTamanhoId,
              quantidade: addedTamanho.quantidade,
              volumeUnitario: produtoCor.volumeUnitario,
              nome: addedTamanho.produtoNome,
              cor: addedTamanho.corDescricao,
              tamanho: produtoCorTamanho.tamanho,
            });
          }
        });

        await cadastrarConferenciaEstoqueItens(newProdutos);
      }
    }
  }

  const handleAdicionarProduto = formMethods.handleSubmit(async (data) => {
    const currentData = { ...data };

    if (
      currentData.produtoCor &&
      currentData.produtoCorTamanho &&
      currentData.quantidade
    ) {
      limparForm();

      const newProduto: ProdutoPaginadoRegistro = {
        produtoCorTamanhoId: currentData.produtoCorTamanho.value,
        codigoBarrasInterno: currentData.produtoCorTamanho.codigoBarrasInterno,
        nome: currentData.produtoCor.label.split(' | ')[0],
        cor: currentData.produtoCor.label.split(' | ')[1],
        tamanho: currentData.produtoCorTamanho.padraoSistema
          ? undefined
          : currentData.produtoCorTamanho.label,
        quantidade: currentData.quantidade,
        volumeUnitario: currentData.produtoCor.volumeUnitario,
      };

      await cadastrarConferenciaEstoqueItens([newProduto]);

      formMethods.setFocus('produtoCor');
    }
  });

  const latestProps = useRef({
    conferenciaEstoque,
    setValue: formMethods.setValue,
    conferenciaEstoqueId,
    produtoCorTamanhosJaCarregados,
    setFocus: formMethods.setFocus,
  });
  useEffect(() => {
    latestProps.current = {
      conferenciaEstoque,
      setValue: formMethods.setValue,
      conferenciaEstoqueId,
      produtoCorTamanhosJaCarregados,
      setFocus: formMethods.setFocus,
    };
  });

  useEffect(() => {
    setExisteProdutos(produtos);
  }, [produtos, setExisteProdutos]);

  useEffect(() => {
    if (
      Number(conferenciaEstoque?.tipoConferencia) ===
      TipoConferenciaEnum.ZERAR_ESTOQUE
    ) {
      nextStepRevisao();
    }
  }, [conferenciaEstoque, nextStepRevisao]);

  const getProdutoCorOptions = useCallback(
    async (inputValue: string) => {
      setProdutoCorIsLoading(true);

      if (latestProps.current.conferenciaEstoque) {
        const response = await api.post<
          void,
          ResponseApi<ProdutoCorResponse[]>
        >(
          ConstanteEnderecoWebservice.PRODUTO_COR_TAMANHO_LISTAR_SELECT_CONFERENCIA_ESTOQUE,
          {
            nomeSkuCodigoExternoBarrasGtinEan: inputValue,
            listaMarcas: (
              latestProps.current.conferenciaEstoque.listaMarcas || []
            ).map((marca) => marca.value),
            listaCategorias: (
              latestProps.current.conferenciaEstoque.listaCategorias || []
            ).map((categoria) => categoria.value),
          }
        );

        if (response?.avisos) {
          response.avisos.map((aviso: string) => toast.warning(aviso));
        }

        if (response && response.sucesso && response.dados) {
          if (
            response.dados.length === 1 &&
            response.dados[0].adicionarItemAutomaticamente
          ) {
            setTimeout(() => formMethods.setFocus('quantidade'), 10);

            const quantidade = formMethods.watch('quantidade') || 1;
            limparForm();

            const newProduto: ProdutoPaginadoRegistro = {
              produtoCorTamanhoId:
                response.dados[0].tamanhos[0].produtoCorTamanhoId,
              codigoBarrasInterno:
                response.dados[0].tamanhos[0].codigoBarrasInterno,
              nome: response.dados[0].nome,
              cor: response.dados[0].cor,
              tamanho: response.dados[0].tamanhos[0].tamanho,
              quantidade,
              volumeUnitario: response.dados[0].volumeUnitario,
            };

            await cadastrarConferenciaEstoqueItens([newProduto]);

            setProdutoCorIsLoading(false);

            if (submitProdutoButtonRef.current) {
              submitProdutoButtonRef.current.focus();
            }
            setTimeout(() => formMethods.setFocus('produtoCor'), 200);

            return [];
          }

          const data = response.dados.map((option: any) => {
            return {
              label: `${option.nome}${option.cor ? ` | ${option.cor}` : ''}`,
              value: `${option.nome}${option.cor ? ` | ${option.cor}` : ''}`,
              tamanhos: option.tamanhos,
              volumeUnitario: option.volumeUnitario,
              produtoKit: option.produtoKit,
            };
          });

          setProdutoCorIsLoading(false);

          return data;
        }
      }

      setProdutoCorIsLoading(false);

      return [];
    },
    [cadastrarConferenciaEstoqueItens, formMethods, limparForm]
  );

  useEffect(() => {
    latestProps.current.setValue('produtoCorTamanho', null);
    async function handleAddProdutoKit() {
      await ModalAtencao({
        text: 'Este tipo de produto não pode ser adicionado nesta operação.',
        closeOnOverlayClick: false,
        callback: async (ok: boolean) => {
          if (ok) {
            latestProps.current.setValue('produtoCor', null);
          }
        },
      });
    }

    if (produtoCor?.produtoKit) {
      handleAddProdutoKit();
    }
  }, [produtoCor]);

  const loadMoreRows = useCallback(
    async ({
      currentPage,
      pageSize,
      orderColumn,
      orderDirection,
    }: LoadMoreRowsParams) => {
      if (latestProps.current.conferenciaEstoqueId) {
        setIsProdutosLoading(true);

        const paginationData = {
          currentPage,
          pageSize,
          orderColumn,
          orderDirection,
        };

        const response = await api.post<void, ResponseApi<ProdutoPaginado>>(
          formatQueryPagegTable(
            ConstanteEnderecoWebservice.CONFERENCIA_ESTOQUE_OBTER_LISTAR_ITENS_PAGINADO,
            paginationData
          ),
          {
            conferenciaEstoqueId: latestProps.current.conferenciaEstoqueId,
            listaProdutoCorTamanhosJaCarregados:
              latestProps.current.produtoCorTamanhosJaCarregados,
          }
        );

        if (response) {
          if (response.sucesso) {
            setProdutos((prev) => [
              ...prev,
              ...(response.dados.registros || []),
            ]);

            setItemsTotalCount(response.dados.totalProdutos || 0);
            setQuantidadeTotal(response.dados.totalItens || 0);
          }
        }
      }

      setIsProdutosLoading(false);
    },
    []
  );

  useEffect(() => {
    if (produtoCorTamanhoOptions && produtoCorTamanhoOptions.length === 1) {
      formMethods.setValue('produtoCorTamanho', produtoCorTamanhoOptions[0]);
    }
  }, [formMethods, produtoCorTamanhoOptions]);

  useEffect(() => {
    latestProps.current.setFocus('produtoCor');
  }, []);

  const exibirTamanhos =
    produtoCorTamanhoOptions &&
    produtoCorTamanhoOptions.length > 0 &&
    !produtoCorTamanhoOptions[0].padraoSistema;

  const hasProdutosAdicionados = produtos && produtos?.length > 0;

  return (
    <Container>
      <Body>
        <FormProvider {...formMethods}>
          <SimpleGridForm>
            <GridItem colSpan={{ base: 12, md: 6, lg: 6, xl: 5 }}>
              <AsyncSelect
                id="produtoCor"
                name="produtoCor"
                setValor={setProdutoSelecionado}
                label="Descrição do produto"
                fontWeightLabel="bold"
                placeholder="Digite o nome do produto e selecione a cor"
                helperText="A pesquisa de produto considera os campos Status, Descrição, Código SKU, GTIN/EAN, Código externo e Código de barras interno."
                isLoading={produtoCorIsLoading}
                handleGetOptions={getProdutoCorOptions}
                asControlledByObject
                withoutDefaultOptions
                autoFocus
              />
              {produtos && produtos.length > 0 && (
                <Text mt="1" fontWeight="bold" color="purple.500">
                  {`Último produto adicionado: ${produtos[0].nome}${
                    produtos[0].cor ? ` ${produtos[0].cor}` : ''
                  }${
                    produtos[0].tamanho
                      ? ` | Tamanho: ${produtos[0].tamanho}`
                      : ''
                  }`}
                </Text>
              )}
            </GridItem>

            {exibirTamanhos && (
              <GridItem colSpan={{ base: 12, md: 6, lg: 6, xl: 3 }}>
                <Flex>
                  <Select
                    id="produtoCorTamanho"
                    name="produtoCorTamanho"
                    label="Tamanho"
                    placeholder="Selecionar"
                    fontWeightLabel="bold"
                    isLoading={produtoCorIsLoading}
                    options={produtoCorTamanhoOptions}
                    isDisabled={!produtoCor || produtoCorTamanhoPadraoSistema}
                    asControlledByObject
                  />
                  <Flex ml="3" flex="1" alignItems="flex-end">
                    <IconButton
                      aria-label="Selecionar grade de tamanhos"
                      icon={<Icon as={GradeTamanhosIcon} fontSize="xl" />}
                      colorScheme="whiteAlpha"
                      color="gray.800"
                      border="1px"
                      borderColor="gray.200"
                      borderRadius="md"
                      isDisabled={produtoCorTamanhoOptions.length <= 1}
                      onClick={handlePushGradeModal}
                    />
                  </Flex>
                </Flex>
              </GridItem>
            )}

            <GridItem colSpan={{ base: 12, md: 6, lg: 6, xl: 2 }}>
              <NumberInput
                id="quantidade"
                name="quantidade"
                label="Quantidade"
                fontWeightLabel="bold"
                min={0}
                defaultValue={1}
                placeholder={
                  produtoCor?.volumeUnitario
                    ? '0'
                    : `0,${'0'.repeat(casasDecimais.casasDecimaisQuantidade)}`
                }
                scale={
                  produtoCor?.volumeUnitario
                    ? 0
                    : casasDecimais.casasDecimaisQuantidade
                }
              />
            </GridItem>
            <GridItem colSpan={{ base: 12, md: 6, lg: 6, xl: 2 }}>
              <Flex justifyContent="flex-end">
                <Button
                  colorScheme={!produtoCor ? 'gray' : 'aquamarine'}
                  borderRadius="md"
                  variant="solid"
                  w="full"
                  mt={18}
                  isDisabled={!produtoCor}
                  onClick={handleAdicionarProduto}
                  ref={submitProdutoButtonRef}
                >
                  Adicionar
                </Button>
              </Flex>
            </GridItem>

            <GridItem colSpan={12}>
              <Box>
                <VirtualizedInfiniteTable
                  ref={tableRef}
                  isLoading={isProdutosLoading}
                  widthSizeScroll="calc(100% - 15px)"
                  editarWidht
                  isUpdateWidthTable
                  alterarBordaListagem="white"
                  withoutRowsMessage="Adicione ao menos um produto para avançar na conferência."
                  orderColumn="produto"
                  visibleItemsCount={10}
                  pageSize={20}
                  boxShadow="none"
                  destacarItemInicial
                  tableHeaders={produtosTableHeaders}
                  rowRenderer={({
                    index,
                    style: { height, ...restStyle },
                    key,
                  }) => {
                    const produto = produtos[index];

                    if (!produto) {
                      return null;
                    }

                    return (
                      <Tr key={key} style={restStyle} height={height}>
                        <Td width={produtosTableHeaders[0].width}>
                          <Text lineHeight="0" opacity={0}>
                            {produtosTableHeaders[0].content}
                          </Text>

                          {produto.codigoBarrasInterno}
                        </Td>

                        <Td width={['55%', '55%', '50%', '50%']}>
                          <Text lineHeight="0" opacity={0}>
                            {produtosTableHeaders[1].content}
                          </Text>

                          <Flex gap="4px">
                            <Text fontSize="1em">
                              {`${produto.nome} ${
                                produto.cor ? ` ${produto.cor}` : ''
                              } `}
                            </Text>
                            {index === 0 && isLargerThan1148 && (
                              <Text fontWeight="extrabold" color="purple.500">
                                - Último item adicionado
                              </Text>
                            )}
                          </Flex>
                        </Td>
                        <Td
                          width={produtosTableHeaders[2].width}
                          textAlign="center"
                        >
                          <Text lineHeight="0" opacity={0}>
                            {produtosTableHeaders[2].content}
                          </Text>

                          {produto.tamanho || '-'}
                        </Td>
                        <Td width={produtosTableHeaders[3].width} isNumeric>
                          <Text
                            ml={isLargerThan520 ? '' : '-30px'}
                            lineHeight="0"
                            opacity={0}
                          >
                            {produtosTableHeaders[3].content}
                          </Text>

                          {produto.quantidade.toLocaleString('pt-BR', {
                            minimumFractionDigits:
                              casasDecimais.casasDecimaisQuantidade,
                            maximumFractionDigits:
                              casasDecimais.casasDecimaisQuantidade,
                          })}
                        </Td>
                        <Td width={['5%', '5%', '10%', '10%']} textAlign="end">
                          <Text lineHeight="0" opacity={0}>
                            {produtosTableHeaders[4].content}
                          </Text>
                          <Flex justifyContent="flex-end">
                            <ActionsMenu
                              colorScheme="gray"
                              items={[
                                {
                                  content: 'Editar quantidade',
                                  onClick: () =>
                                    handleEditarQuantidadeItem(index),
                                },
                                {
                                  content: 'Remover',
                                  onClick: () =>
                                    handleRemoverConferenciaEstoqueItem(index),
                                },
                              ]}
                            />
                          </Flex>
                        </Td>
                      </Tr>
                    );
                  }}
                  rowCount={itemsTotalCount}
                  isRowLoaded={({ index }) => !!produtos[index]}
                  loadMoreRows={loadMoreRows}
                />

                <VStack alignItems="flex-end" spacing="1" mt="4" pr="48px">
                  <HStack>
                    <Text lineHeight="none">Total de produtos:</Text>
                    <Text lineHeight="none" fontWeight="bold">
                      {itemsTotalCount.toLocaleString('pt-BR', {
                        minimumFractionDigits:
                          casasDecimais.casasDecimaisQuantidade,
                        maximumFractionDigits:
                          casasDecimais.casasDecimaisQuantidade,
                      })}
                    </Text>
                  </HStack>
                  <HStack>
                    <Text lineHeight="none">Quantidade de itens:</Text>
                    <Text lineHeight="none" fontWeight="bold">
                      {quantidadeTotal.toLocaleString('pt-BR', {
                        minimumFractionDigits:
                          casasDecimais.casasDecimaisQuantidade,
                        maximumFractionDigits:
                          casasDecimais.casasDecimaisQuantidade,
                      })}
                    </Text>
                  </HStack>
                </VStack>
              </Box>
            </GridItem>
          </SimpleGridForm>
        </FormProvider>
      </Body>
      <Footer>
        <CarregarArquivoColetor
          conferenciaEstoqueId={conferenciaEstoqueId}
          recarregarListagem={() => {
            resetarLista();
          }}
        />

        <Button
          variant="cancel"
          colorScheme="gray"
          borderRadius="md"
          w="full"
          maxW={{ base: 'full', md: '100px' }}
          onClick={handleDescartarConferencia}
        >
          Descartar
        </Button>
        <Button
          variant="outlineDefault"
          borderRadius="md"
          w="full"
          maxW={{ base: 'full', md: '100px' }}
          onClick={handleVoltar}
        >
          Voltar
        </Button>
        <Button
          colorScheme={!hasProdutosAdicionados ? 'gray' : 'primary'}
          borderRadius="md"
          w="full"
          maxW={{ base: 'full', md: '160px' }}
          isDisabled={!hasProdutosAdicionados}
          onClick={handleAvancar}
          variant="solid"
        >
          Avançar
        </Button>
      </Footer>
    </Container>
  );
}
