import React, { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Col, Container, Button } from 'react-bootstrap';
import ButtonComOpcoes from 'components/Button/ButtonComOpcoes';
import TableCampoFormularioPadrao from 'components/Table/TableCampoFormularioPadrao';
import { useFormContext } from 'react-hook-form';
import { moneyMask } from 'helpers/format/fieldsMasks';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { getName } from 'helpers/enum/getName';
import MeioPagamentoFiscalEnum from 'constants/enum/fiscal/meioPagamentoFiscal';
import PagamentoModalInserir from './PagamentoModalInserir';
import PagamentoModalAlterar from './PagamentoModalAlterar';

interface UncontrolledFormInterface {
  readonly?: boolean;
  isVisualizar?: boolean;
}

export const Pagamento = forwardRef(
  ({ readonly, isVisualizar }: UncontrolledFormInterface) => {
    const { t } = useTranslation();
    const [showPagamentoModalInserir, setShowPagamentoModalInserir] = useState(
      false
    );

    const [showPagamentoModalAlterar, setShowPagamentoModalAlterar] = useState(
      false
    );

    const [somaTotalPagamento, setSomaTotalPagamento] = useState(0);

    const [indexItemAlteracao, setIndexItemAlteracao] = useState(-1);

    const {
      getValues: parentGetValues,
      setValue: parentSetValue,
      control: parentControl,
      watch,
    } = useFormContext();

    const documentoFiscalPagamentosWatch = watch('documentoFiscalPagamentos');
    const valorTotalWatch = watch('valorTotal', 0);

    const removerItemList = (identificadorAgrupamento: string) => {
      ModalConfirmacaoExcluir({
        callback: async (ok: boolean) => {
          if (ok) {
            const items = parentGetValues(
              'documentoFiscalPagamentos'
            ) as Array<any>;
            const itemsFiltrados = items.filter(
              (item) =>
                item.identificadorAgrupamento !== identificadorAgrupamento
            );

            for (let index = 0; index < itemsFiltrados.length; index += 1) {
              itemsFiltrados[index].parcela = index + 1;
            }

            parentSetValue('documentoFiscalPagamentos', [...itemsFiltrados]);
          }
        },
        text: '',
      });
    };

    useEffect(() => {
      if (documentoFiscalPagamentosWatch?.length > 0) {
        setSomaTotalPagamento(
          documentoFiscalPagamentosWatch
            .map((item: any) => item.valor)
            .reduce((prev: number, next: number) => prev + next)
        );
      } else {
        setSomaTotalPagamento(0);
      }
    }, [documentoFiscalPagamentosWatch]);

    return (
      <>
        <Container fluid>
          <Form.Row>
            <Col xl={12}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {somaTotalPagamento < valorTotalWatch && !isVisualizar && (
                  <Button
                    variant="link"
                    style={{ fontSize: '14px' }}
                    onClick={() => setShowPagamentoModalInserir(true)}
                    disabled={readonly}
                  >
                    Adicionar dados de pagamento
                  </Button>
                )}
              </div>

              <TableCampoFormularioPadrao
                fieldName="documentoFiscalPagamentos"
                defaultColumnOrder=""
                headerColumns={[
                  {
                    name: 'parcela',
                    displayName: 'Parcela',
                    isOrderable: false,
                    width: '',
                  },
                  {
                    name: 'vencimento',
                    displayName: 'Vencimento',
                    isOrderable: false,
                    width: '',
                  },
                  {
                    name: 'meioPagamento',
                    displayName: 'Meio de pagamento',
                    isOrderable: false,
                    width: '',
                  },
                  {
                    name: 'valor',
                    displayName: 'Valor',
                    isOrderable: false,
                    width: '',
                  },
                  {
                    name: 'acoes',
                    displayName: isVisualizar ? '' : 'Ações',
                    isOrderable: false,
                    width: isVisualizar ? '0px' : '38px',
                  },
                ]}
                emptyValuesText="Nenhuma dado de pagamento adicionado para esta nota"
                control={parentControl}
                isLoading={false}
              >
                {({ tableValues }) => (
                  <>
                    {tableValues &&
                      tableValues.map((tableValue, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <tr key={index}>
                          <td>{tableValue.parcela}</td>
                          <td>
                            {tableValue.vencimento.toLocaleDateString
                              ? tableValue.vencimento.toLocaleDateString()
                              : new Date(
                                  tableValue.vencimento
                                ).toLocaleDateString()}
                          </td>
                          <td>
                            {getName(
                              MeioPagamentoFiscalEnum,
                              tableValue.meioDePagamento
                            )}
                          </td>
                          <td>{moneyMask(tableValue.valor, false)}</td>
                          {!isVisualizar && (
                            <td>
                              <ButtonComOpcoes
                                id="mostrarMais"
                                dropdownItems={[
                                  {
                                    title: t('Editar'),
                                    onClick: () => {
                                      setIndexItemAlteracao(index);
                                      setShowPagamentoModalAlterar(true);
                                    },
                                    disabled: readonly,
                                  },
                                  {
                                    title: t('Remover'),
                                    onClick: () =>
                                      removerItemList(
                                        tableValue.identificadorAgrupamento
                                      ),
                                    disabled: readonly,
                                  },
                                ]}
                              />
                            </td>
                          )}
                        </tr>
                      ))}
                    <tr>
                      <td />
                      <td />
                      <td />
                      <td>
                        <b>{moneyMask(valorTotalWatch, false)}</b>
                      </td>
                      <td />
                    </tr>
                  </>
                )}
              </TableCampoFormularioPadrao>
            </Col>
          </Form.Row>
        </Container>
        <PagamentoModalInserir
          show={showPagamentoModalInserir}
          onHide={() => setShowPagamentoModalInserir(false)}
        />

        <PagamentoModalAlterar
          indexItem={indexItemAlteracao}
          show={showPagamentoModalAlterar}
          onHide={() => setShowPagamentoModalAlterar(false)}
        />
      </>
    );
  }
);
