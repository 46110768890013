import { Flex, BoxProps } from '@chakra-ui/react';

type ContentProps = BoxProps & {
  children: React.ReactNode;
};

export const Content = ({
  children,
  px = '24px',
  h = '56px',
  ...rest
}: ContentProps) => {
  return (
    <Flex
      bg="white"
      minH={h}
      h="auto"
      py="19px"
      boxShadow="0px 0px 4px #00000029"
      px={px}
      borderRadius="5px"
      {...rest}
    >
      {children}
    </Flex>
  );
};
