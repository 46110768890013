const getHeaderChaveTemporaria = (chave: string | undefined) => {
  return chave
    ? {
        headers: {
          'chave-permissao-temporaria': chave,
        },
      }
    : {};
};

export default getHeaderChaveTemporaria;
