import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export type FormData = {
  motivo: string;
};

const schema = yup.object().shape({
  motivo: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .max(255, ConstanteMensagemValidacao.MAX_LENGTH_255),
});

export const yupResolver = yupResolverInstance(schema);

export const useForm = useFormInstance;
