import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import TipoProdutoEnum from 'constants/enum/tipoProduto';
import { useProdutosFormularioContext } from 'store/Produtos/ProdutosFormulario';

import { ModalDesistencia } from 'components/Modal/ModalDesistencia';

export const useAlterarItem = () => {
  const [allowExitScreen, setAllowExitScreen] = useState(false);
  const [pathnameRoute, setPathnameRoute] = useState<undefined | string>(
    undefined
  );

  const {
    tipoProduto,
    idProduto,
    hasKit,
    hasVariation,
    setIsLoading,
  } = useProdutosFormularioContext();

  const history = useHistory();

  const isKitProduct = tipoProduto === TipoProdutoEnum.PRODUTO_KIT;
  const isVariacaoProduct = tipoProduto === TipoProdutoEnum.PRODUTO_VARIACAO;

  const title = `Informe ${
    isKitProduct ? 'um produto no Kit!' : 'uma variação!'
  }`;

  const message = `É necessário cadastrar ao menos ${
    isKitProduct ? 'um produto para o kit' : 'uma variação'
  }. Se o produto não  ${
    isKitProduct ? 'é do tipo kit' : 'possui variações'
  }, retorne para o card principal e altere o tipo do produto para “Simples”.`;

  const handleExitRegistration = async (pathname: string) => {
    const productHasKit = await hasKit();
    const productHasVariation = await hasVariation();

    const withoutKit = isKitProduct && !productHasKit;
    const withoutVariation = isVariacaoProduct && !productHasVariation;

    if (withoutKit || withoutVariation) {
      await ModalDesistencia({
        title,
        labelButtonConfirmar: 'Continuar editando',
        textoMensagem: message,
        showCancelButton: false,
        callback: () => {
          setIsLoading(false);
        },
      });

      return;
    }

    setAllowExitScreen(true);
    setPathnameRoute(pathname);
  };

  const validatePrompt =
    (isKitProduct || isVariacaoProduct) &&
    allowExitScreen === false &&
    idProduto.length > 0 &&
    pathnameRoute === undefined;

  useEffect(() => {
    if (pathnameRoute) {
      history.push(pathnameRoute as string);
    }
  }, [history, idProduto, pathnameRoute]);

  return {
    validatePrompt,
    handleExitRegistration,
  };
};
