import { Flex, Text } from '@chakra-ui/react';
import { Draggable } from 'react-smooth-dnd';

type CategoriaItemProps = {
  nome: string;
  isDragging: boolean;
  ultimoItem: boolean;
};

const CategoriaItem = ({
  nome,
  isDragging = false,
  ultimoItem = false,
}: CategoriaItemProps) => {
  return (
    <Draggable>
      <Flex
        h="50px"
        w="full"
        py={3.5}
        px={8}
        align="center"
        borderBottomWidth={!ultimoItem ? '1px' : 0}
        borderBottomColor="gray.200"
        bgColor="white"
        cursor="move"
        _hover={{
          bgColor: !isDragging && 'gray.50',
        }}
      >
        <Text fontSize="md">{nome}</Text>
      </Flex>
    </Draggable>
  );
};

export default CategoriaItem;
