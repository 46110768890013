import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  useDisclosure,
  ModalProps as ModalChakraProps,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
  GridItem,
  Button,
  useMediaQuery,
} from '@chakra-ui/react';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { NumberInput } from 'components/update/Input/NumberInput';
import InputInteger from 'components/PDV/InputInteger';
import { Switch } from 'components/update/Switch';

type ModalProps = {
  descontoAcimaQuantidade: boolean;
  casasDecimaisValor: number;
  precoVendaAtual: number;
  nomeProduto: string;
  quantidade: number;
};

type ModalReponseProps = {
  precoVenda: number;
  quantidade: number;
  descontoAcimaQuantidade: boolean;
};

type ModalAlterarProdutoProps = Omit<
  ModalChakraProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalReponseProps> &
  ModalProps;

export const ModalAlterarProduto = create<
  ModalAlterarProdutoProps,
  ModalReponseProps
>(
  ({
    onResolve,
    onReject,
    descontoAcimaQuantidade,
    casasDecimaisValor,
    precoVendaAtual,
    nomeProduto,
    quantidade,
    ...rest
  }) => {
    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const formMethods = useForm({
      defaultValues: {
        precoVenda: precoVendaAtual,
        descontoAcimaQuantidade,
        quantidade,
      },
    });

    const { handleSubmit } = formMethods;

    const handleAlterarProduto = handleSubmit((data) => {
      if (!data.precoVenda) {
        toast.warning('O campo preço de venda não pode estar zerado');
        return;
      }

      onResolve({
        precoVenda: data.precoVenda,
        descontoAcimaQuantidade: data.descontoAcimaQuantidade,
        quantidade: data.quantidade,
      });
    });

    return (
      <ModalPadraoChakra
        size={isLargerThan900 ? '3xl' : 'full'}
        isCentered={isLargerThan900}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalContent px={{ base: '8px', md: '16px' }}>
          <ModalHeader pt={{ base: '12px', md: '24px' }}>
            <Text textColor="primary.500">{nomeProduto}</Text>
          </ModalHeader>
          <ModalBody>
            <FormProvider {...formMethods}>
              <SimpleGridForm>
                <GridItem colSpan={{ base: 12, sm: 6, md: 4 }}>
                  <NumberInput
                    id="precoVenda"
                    name="precoVenda"
                    label="Preço de venda"
                    placeholder={`0,${'0'.repeat(casasDecimaisValor)}`}
                    scale={casasDecimaisValor}
                    keepFocus={false}
                  />
                </GridItem>
                <GridItem colSpan={{ base: 12, sm: 6, md: 4 }}>
                  <InputInteger
                    id="quantidade"
                    helperText="A partir dessa quantidade a promoção fica ativa"
                    name="quantidade"
                    label="Quantidade"
                    defaultValue={1}
                    min={1}
                    scale={0}
                  />
                </GridItem>
                <GridItem colSpan={{ base: 12, sm: 6, md: 4 }}>
                  <Switch
                    id="descontoAcimaQuantidade"
                    name="descontoAcimaQuantidade"
                    size="md"
                    helperText="O preço promocional vai ser utilizado acima da quantidade informada "
                    label="Utilizar acima da quantidade"
                  />
                </GridItem>
              </SimpleGridForm>
            </FormProvider>
          </ModalBody>
          <ModalFooter
            py="40px"
            justifyContent="center"
            gap={{ base: 4, md: 6 }}
          >
            <Button
              id="fechar"
              name="fechar"
              variant="outlineDefault"
              colorScheme="gray"
              minW="min-content"
              width={{ base: 'full', sm: '100px' }}
              fontWeight="normal"
              onClick={onClose}
            >
              Cancelar
            </Button>
            <Button
              id="salvar"
              name="salvar"
              minW="min-content"
              colorScheme="secondary"
              width={{ base: 'full', sm: '160px' }}
              fontWeight="normal"
              onClick={handleAlterarProduto}
            >
              Confirmar
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
