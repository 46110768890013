import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import isPrenvent from 'helpers/layout/isPrenvent';
import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import getHeaderChaveTemporaria from 'helpers/api/getHeaderChaveTemporaria';
import CampoPersonalizadoInterface from 'types/campoPersonalizado';
import CampoPersonalizadoValorInterface from 'types/campoPersonalizadoValor';

import { ContainerListagem } from 'components/Layout/Listagem/containerListagem';

import {
  useForm,
  yupResolverCliente,
  yupResolverFornecedor,
} from '../validationForm';
import UncontrolledForm, { UncontrolledFormRefInterface } from '..';

type TParams = { id: string };

interface AlterarProps extends RouteComponentProps<TParams> {
  rotaCliente?: boolean;
  cadastroPdv?: boolean;
  clienteIdPdv?: string;
  cadastroSucessoCallback?: (cliente: any) => void;
  chavePermissaoTemporaria?: string;
}

const Alterar = ({
  rotaCliente,
  match,
  cadastroPdv = false,
  clienteIdPdv,
  cadastroSucessoCallback,
  chavePermissaoTemporaria,
}: AlterarProps) => {
  const history = useHistory();
  const isMountedRef = useIsMountedRef();
  isPrenvent();

  const [isLoading, setIsLoading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [dataHoraCadastro, setDataHoraCadastro] = useState('');
  const [dataHoraUltimaAlteracao, setDataHoraUltimaAlteracao] = useState('');
  const [camposPersonalizados, setCamposPersonalizados] = useState<
    CampoPersonalizadoInterface[]
  >([]);

  const formMethods = useForm({
    resolver: rotaCliente ? yupResolverCliente : yupResolverFornecedor,
    shouldUnregister: false,
  });
  const {
    handleSubmit,
    register,
    control,
    reset,
    getValues,
    formState,
    setError,
    setValue,
    watch,
  } = formMethods;

  const [clienteFornecedorId, setclienteFornecedorId] = useState('');
  const [enderecoPrincipalId, setenderecoPrincipalId] = useState('');

  const uncontrolledFormRef = useRef<UncontrolledFormRefInterface>();

  const latestProps = useRef({ chavePermissaoTemporaria });
  useEffect(() => {
    latestProps.current = { chavePermissaoTemporaria };
  });

  const handleGetClienteFornecedor = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<void, ResponseApi<any>>(
      rotaCliente
        ? ConstanteEnderecoWebservice.CLIENTE_OBTER
        : ConstanteEnderecoWebservice.FORNECEDOR_OBTER,
      {
        params: { id: !cadastroPdv ? match.params.id : clienteIdPdv },
        ...getHeaderChaveTemporaria(
          latestProps.current.chavePermissaoTemporaria
        ),
      }
    );

    if (response?.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (response?.sucesso && isMountedRef.current) {
      const {
        camposPersonalizados: dataCamposPersonalizados,
        ...data
      } = response.dados;

      setCamposPersonalizados(
        dataCamposPersonalizados.map(
          ({ campoPersonalizado }: CampoPersonalizadoValorInterface) =>
            campoPersonalizado
        )
      );

      const camposPersonalizadosValor = dataCamposPersonalizados.map(
        ({
          campoPersonalizadoId,
          valor,
        }: CampoPersonalizadoValorInterface) => ({
          campoPersonalizadoId,
          valor: Number(valor) || valor,
        })
      );

      reset({
        ...data,
        rgInscricaoEstadualisento: data.ieIsento,
        dataNascimento: data.dataNascimento?.split('T')[0],
        telefoneWhats: data.telefoneWhatsapp,
        celularWhats: data.celularWhatsapp,
        categoriaCliente: rotaCliente
          ? {
              value: data.categoriaClienteOpcaoSelect.id,
              label: data.categoriaClienteOpcaoSelect.nome,
            }
          : {},
        camposPersonalizados: camposPersonalizadosValor,
        tabelaPreco:
          data.tabelaPrecoId !== null
            ? {
                label: data.tabelaPrecoNome,
                value: data.tabelaPrecoId,
              }
            : {
                label: 'Digite o nome da tabela de preço',
                value: null,
              },
        cep: data.enderecos.filter((obj: any) => obj.principal)[0].cep,
        logradouro: data.enderecos.filter((obj: any) => obj.principal)[0]
          .logradouro,
        numero: data.enderecos.filter((obj: any) => obj.principal)[0].numero,
        complemento: data.enderecos.filter((obj: any) => obj.principal)[0]
          .complemento,
        bairro: data.enderecos.filter((obj: any) => obj.principal)[0].bairro,
        cidade: {
          label: data.enderecos[0].cidadeNome,
          value: data.enderecos[0].cidadeId,
          paisId: data.enderecos[0].paisId,
        },
        pais: {
          label: data.enderecos[0].paisNome,
          value: data.enderecos[0].paisId,
        },
        estado: data.enderecos[0].estadoNome,
        setorEntrega: data.setorEntregaId
          ? {
              label: data.setorEntregaNome,
              value: data.setorEntregaId,
            }
          : undefined,
      });

      setclienteFornecedorId(response.dados.id);
      setenderecoPrincipalId(
        response.dados.enderecos.filter((obj: any) => obj.principal)[0].id
      );
      setValue('possuiInscricaoEstadual', data.possuiInscricaoEstadual);
      if (uncontrolledFormRef.current) {
        await uncontrolledFormRef.current.setCidade(
          response.dados.enderecos.filter((obj: any) => obj.principal)[0]
            .cidadeId
        );

        if (!getValues('pais')) {
          await uncontrolledFormRef.current.setPais(
            response.dados.enderecos.filter((obj: any) => obj.principal)[0]
              .paisId
          );
        }

        const unformattedCpfCnpj =
          response.dados.cpfCnpj !== null
            ? response.dados.cpfCnpj.replace(/\D/g, '')
            : '';
        if (unformattedCpfCnpj.length <= 11) {
          uncontrolledFormRef.current.setCpfOrCnpj(true);
        } else {
          uncontrolledFormRef.current.setCpfOrCnpj(false);
        }
      }

      if (response.dados.contatosAdicionais) {
        setValue(
          'contatosAdicionais',
          response.dados.contatosAdicionais.map((obj: any) => {
            return {
              id: obj.id,
              Email: obj.email,
              Nome: obj.nome,
              Telefone: obj.telefone,
              WhatsApp: obj.whatsApp,
              codigo: undefined,
            };
          })
        );
      }

      if (response.dados.enderecos) {
        setValue(
          'enderecosAdicionais',
          response.dados.enderecos
            .filter((obj: any) => !obj.principal)
            .map((obj: any) => {
              return {
                id: obj.id,
                bairro: obj.bairro,
                cep: obj.cep,
                cidade: obj.cidadeId,
                cidadeCampo: {
                  label: obj.cidadeNome,
                  value: obj.cidadeId,
                  paisId: obj.paisId,
                },
                cidadeNome: obj.cidadeNome,
                cobranca: obj.cobranca,
                codigo: 0,
                complemento: obj.complemento,
                entrega: obj.entrega,
                logradouro: obj.logradouro,
                numero: obj.numero,
                pais: obj.paisId,
                paisCampo: { label: obj.paisNome, value: obj.paisId },
                principal: false,
                estado: obj.estadoNome,
              };
            })
        );
      }

      setDataHoraCadastro(response.dados.dataHoraCadastro);
      setDataHoraUltimaAlteracao(response.dados.dataHoraUltimaAlteracao);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      rotaCliente
        ? history.push(ConstanteRotas.CLIENTE)
        : history.push(ConstanteRotas.FORNECEDOR);
    }

    if (isMountedRef.current) setIsLoading(false);
  }, [
    cadastroPdv,
    match?.params?.id,
    clienteIdPdv,
    isMountedRef,
    reset,
    rotaCliente,
    getValues,
    setValue,
    history,
  ]);

  async function handlePostClienteFornecedor() {
    const data = getValues();

    const clienteFornecedorViewModel = {
      id: clienteFornecedorId,
      possuiInscricaoEstadual: data.possuiInscricao || false,
      Ativo: data.ativo,
      ieisento: data.rgInscricaoEstadualisento || false,
      tipoCadastroPessoa: rotaCliente ? 1 : 2,
      CpfCnpj: data.cpfCnpj || null,
      RgInscricaoEstadual: data.rgInscricaoEstadual || null,
      Nome: data.nome,
      Foto: data.foto || null,
      Apelido: data.apelido || null,
      Genero: data.genero,
      Codigo: data.codigo || 0,
      DataNascimento: data.dataNascimento,
      SitePerfil: data.sitePerfil || null,
      Telefone: data.telefone || null,
      Celular: data.celular || null,
      Email: data.email || null,
      EmailNFe: data.emailNfe || null,
      Observacao: data.observacao || null,
      LimiteCredito: rotaCliente && (data.limiteCredito || 0),
      ConsumidorFinal: data.consumidorFinal || false,
      ContribuinteIcms: data.contribuinteIcms || false,
      CategoriaClienteId:
        rotaCliente && data.categoriaCliente
          ? data.categoriaCliente.value
          : null,
      TelefoneWhatsapp: data.telefoneWhats || false,
      CelularWhatsapp: data.celularWhats || false,
      tabelaPrecoId:
        rotaCliente && data.tabelaPreco !== null
          ? data.tabelaPreco.value || null
          : null,
      Suframa:
        rotaCliente && (data.suframa || data.suframa === 0)
          ? data.suframa
          : null,
      DocumentoEstrangeiro: data.documentoEstrangeiro || null,
      CodigoExterno: data.codigoExterno || null,
      CadastroAnonimo: data.cadastroAnonimo || false,
      setorEntregaId: data.setorEntrega?.value,
      setorEntregaNome: data.setorEntrega?.label,
      CamposPersonalizados:
        rotaCliente && data.camposPersonalizados
          ? data.camposPersonalizados
              .filter(
                ({
                  campoPersonalizadoId,
                  valor,
                }: CampoPersonalizadoValorInterface) =>
                  campoPersonalizadoId &&
                  valor !== undefined &&
                  valor !== 'undefined' &&
                  valor !== 'null' &&
                  String(valor)
              )
              .map(
                ({
                  campoPersonalizadoId,
                  valor,
                }: CampoPersonalizadoValorInterface) => ({
                  campoPersonalizadoId,
                  valor: String(valor),
                })
              )
          : null,
      Enderecos: data.enderecosAdicionais.map((endereco: any) => {
        return {
          Id: endereco.id || null,
          Logradouro: endereco.logradouro || null,
          Numero: endereco.numero || null,
          Complemento: endereco.complemento || null,
          Bairro: endereco.bairro || null,
          Cep: endereco.cep || null,
          CidadeId: endereco.cidade,
          PaisId: endereco.pais,
          Principal: false,
          Cobranca: endereco.cobranca || false,
          Entrega: endereco.entrega || false,
        };
      }),
      ContatosAdicionais: data.contatosAdicionais?.map((contato: any) => {
        return {
          id: contato.id || null,
          Nome: contato.Nome || null,
          Telefone: contato.Telefone || null,
          Email: contato.Email || null,
          WhatsApp: contato.WhatsApp || false,
        };
      }),
    };

    const enderecoprincipal = {
      Id: enderecoPrincipalId,
      Logradouro: data.logradouro || null,
      Numero: data.numero || null,
      Complemento: data.complemento || null,
      Bairro: data.bairro || null,
      Cep: data.cep || null,
      CidadeId: data.cidade.value,
      PaisId: data.pais.value,
      Principal: true,
      Cobranca: false,
      Entrega: false,
    };

    clienteFornecedorViewModel.Enderecos.push(enderecoprincipal);

    const response = await api.put<void, ResponseApi<any>>(
      rotaCliente
        ? ConstanteEnderecoWebservice.CLIENTE_ALTERAR
        : ConstanteEnderecoWebservice.FORNECEDOR_ALTERAR,
      clienteFornecedorViewModel,
      getHeaderChaveTemporaria(chavePermissaoTemporaria)
    );
    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response?.sucesso) {
        if (cadastroPdv && cadastroSucessoCallback)
          cadastroSucessoCallback(response.dados);

        return true;
      }
    }

    return false;
  }

  const onSubmit = handleSubmit(async () => {
    setIsLoading(true);

    const success = await handlePostClienteFornecedor();

    if (success) {
      toast.success('O cadastro foi alterado com sucesso.');

      setFormIsDirty(false);

      if (!cadastroPdv) {
        setFormIsDirty(false);

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        rotaCliente
          ? history.push(ConstanteRotas.CLIENTE)
          : history.push(ConstanteRotas.FORNECEDOR);
      }
    }

    setIsLoading(false);
  });

  useEffect(() => {
    setIsLoading(true);

    handleGetClienteFornecedor();
  }, [handleGetClienteFornecedor]);

  useEffect(() => {
    setFormIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  return (
    <ContainerListagem
      maxWidth="full"
      formIsDirty={formIsDirty}
      formMethods={formMethods}
      isLoading={isLoading}
      onSubmit={onSubmit}
      dataHoraUltimaAlteracao={dataHoraUltimaAlteracao}
      dataHoraCadastro={dataHoraCadastro}
    >
      <UncontrolledForm
        ref={uncontrolledFormRef}
        errors={formState.errors}
        control={control}
        register={register}
        setValue={setValue}
        getValue={getValues}
        setError={setError}
        watch={watch}
        camposPersonalizados={camposPersonalizados}
        rotaCliente={rotaCliente}
        cadastroPdv={cadastroPdv}
        readonly={false}
        isAlteracao
      />
    </ContainerListagem>
  );
};

export default Alterar;
