import React, { useCallback, ChangeEvent, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { rgMask, ieMask } from 'helpers/format/fieldsMasks';

import Input, { InputProps } from '../../PDV/Input';

interface InputRgIeProps extends InputProps {
  isRg: boolean;
}

export const InputRgIe = ({
  isRg,
  onChange,
  name,
  ...rest
}: InputRgIeProps) => {
  const { setValue, watch } = useFormContext();

  const valueInput = watch(name);

  const getFormattedValue = useCallback(
    (value: string) => {
      if (isRg) {
        return rgMask(value);
      }

      return ieMask(value);
    },
    [isRg]
  );

  useEffect(() => {
    if (valueInput) setValue(name, getFormattedValue(valueInput));
  }, [isRg]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Input
      {...rest}
      name={name}
      onInput={(e: React.FormEvent<HTMLInputElement>) => {
        if (e.currentTarget.value)
          e.currentTarget.value = getFormattedValue(e.currentTarget.value);
      }}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        e.currentTarget.value = getFormattedValue(e.currentTarget.value);

        if (onChange) onChange(e);
      }}
      maxLength={isRg ? 12 : 20}
    />
  );
};
