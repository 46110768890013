import React, {
  createContext,
  useState,
  useContext,
  useRef,
  useCallback,
  useEffect,
} from 'react';
import { toast } from 'react-toastify';

import ConstanteFuncionalidades from 'constants/permissoes';
import { enumTabsZoop } from 'constants/enum/enumTabsZoop';
import { getCredenciamentoZoop } from 'services/zoop';
import auth from 'modules/auth';

import {
  ButtonClickProps,
  ZoopContextProps,
  TabSelecionadaProps,
  InfoCredenciamento,
} from './types';

export const ZoopContext = createContext<ZoopContextProps>(
  {} as ZoopContextProps
);

interface ZoopProviderProps {
  children: React.ReactNode;
}

const possuiPermissaoCredenciar = auth.possuiPermissao(
  ConstanteFuncionalidades.CREDENCIAR_ZOOP
).permitido;

const possuiPermissaoVisualizarTransacoes = auth.possuiPermissao(
  ConstanteFuncionalidades.VISUALIZAR_TRANSACOES_ZOOP
).permitido;

const tabDefault =
  possuiPermissaoCredenciar && !possuiPermissaoVisualizarTransacoes
    ? enumTabsZoop.CONFIGURACOES
    : enumTabsZoop.TRANSACOES;

export function ZoopProvider({ children }: ZoopProviderProps): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<TabSelecionadaProps>(tabDefault);
  const [tabFoiAlterada, setTabFoiAlterada] = useState(false);
  const [
    infoCredenciamento,
    setInfoCredenciamento,
  ] = useState<InfoCredenciamento>();

  const ref = useRef<ButtonClickProps>(null);
  const possuiContaBancariaVinculada =
    infoCredenciamento?.contaBancariaVinculada;

  const obterCredenciamento = useCallback(async () => {
    setIsLoading(true);
    const response = await getCredenciamentoZoop();

    if (response) {
      if (response?.avisos) {
        response.avisos?.forEach((aviso) => toast.warning(aviso));
      }

      if (response?.sucesso && response?.dados) {
        const { ...newInfoCredenciamento } = response?.dados;

        setInfoCredenciamento({
          ...newInfoCredenciamento,
        });
      } else {
        setInfoCredenciamento({
          credenciamentoAprovado: false,
          credenciamentoSolicitado: false,
          mensagemCredenciamento: '',
        });
      }
    }
    setIsLoading(false);
  }, []);

  const filterTabs = () => {
    if (possuiPermissaoCredenciar && !possuiPermissaoVisualizarTransacoes) {
      if (possuiContaBancariaVinculada) {
        return [enumTabsZoop.CONFIGURACOES, enumTabsZoop.CONTA_BANCARIA];
      }
      return [enumTabsZoop.CONFIGURACOES];
    }

    if (possuiPermissaoVisualizarTransacoes && !possuiPermissaoCredenciar) {
      if (possuiContaBancariaVinculada) {
        return [enumTabsZoop.TRANSACOES, enumTabsZoop.CONTA_BANCARIA];
      }
      return [enumTabsZoop.TRANSACOES, enumTabsZoop.CONTA_BANCARIA];
    }

    if (possuiContaBancariaVinculada) {
      return [enumTabsZoop.TRANSACOES, enumTabsZoop.CONFIGURACOES];
    }

    return [
      enumTabsZoop.TRANSACOES,
      enumTabsZoop.CONFIGURACOES,
      enumTabsZoop.CONTA_BANCARIA,
    ];
  };

  const tabsDisponiveis = filterTabs();

  useEffect(() => {
    obterCredenciamento();
  }, [obterCredenciamento]);

  return (
    <ZoopContext.Provider
      value={{
        activeTab,
        setActiveTab,
        ref,
        tabFoiAlterada,
        setTabFoiAlterada,
        isLoading,
        setIsLoading,
        obterCredenciamento,
        infoCredenciamento,
        tabsDisponiveis,
      }}
    >
      {children}
    </ZoopContext.Provider>
  );
}

export function useZoopContext(): ZoopContextProps {
  const context = useContext(ZoopContext);

  if (!context)
    throw new Error('ZoopContext must be used within a ZoopProvider.');

  return context;
}
