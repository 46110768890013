import React from 'react';
import {
  Stack,
  Text,
  Icon,
  Flex,
  AccordionItem as ChakraAccordionItem,
  AccordionItemProps as ChakraAccordionItemProps,
  AccordionButton,
  AccordionPanel,
} from '@chakra-ui/react';
import { IconType as ReactIconsIconType } from 'react-icons/lib';

import { IconType } from 'icons';
import { FiChevronRight } from 'react-icons/fi';

interface ChildWithFuncProps {
  isExpanded: boolean;
}

type ChildWithFuncType = (props: ChildWithFuncProps) => JSX.Element | string;

interface AccordionWrapperButtonProps extends ChakraAccordionItemProps {
  children: ChildWithFuncType;
  icon: IconType | ReactIconsIconType;
  title: string;
  onToggle?: (isOpen?: boolean) => void;
  hasArrow?: boolean;
}

export const AccordionWrapperButton = ({
  children,
  icon,
  title,
  onToggle,
  hasArrow = false,
  ...rest
}: AccordionWrapperButtonProps) => {
  return (
    <ChakraAccordionItem border="none" boxShadow="none" {...rest}>
      {({ isExpanded }) => (
        <>
          <AccordionButton
            position="relative"
            bg="secondary.500"
            color="primary.800"
            fontSize="xs"
            height="70px"
            width="100%"
            p={4}
            px={8}
            borderRadius={0}
            borderTopStyle="solid"
            borderBottom="1px"
            borderColor="secondary.600"
            _first={{ borderTopWidth: 0 }}
            _active={{ boxShadow: 'none' }}
            _hover={{ boxShadow: 'none', bg: 'secondary.500' }}
            _focus={{ boxShadow: 'none' }}
            onClick={() => {
              if (onToggle) {
                onToggle(!isExpanded);
              }
            }}
          >
            <Stack
              direction="row"
              align="center"
              justifyContent="flex-start"
              h="100%"
              w="100%"
              spacing={4}
            >
              <Icon as={icon} fontSize="2xl" />

              <Flex
                position="relative"
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="center"
              >
                <Flex alignItems="center" justifyContent="center">
                  <Text fontWeight="normal">{title}</Text>
                </Flex>
              </Flex>
            </Stack>

            {hasArrow && <Icon as={FiChevronRight} boxSize="4" />}
          </AccordionButton>
          <AccordionPanel background="gray.50" paddingY={2} paddingX={0}>
            {children({ isExpanded })}
          </AccordionPanel>
        </>
      )}
    </ChakraAccordionItem>
  );
};
