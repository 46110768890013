import fundoZendar from 'assets/images/zendar/fundo-login.svg';
import cadastrarMetasComissoesZendar from 'assets/images/zendar/cadastrar-metas-conferencia.png';
import inicioConfererenciaEstoqueZendar from 'assets/images/zendar/inicio-conferir-estoque.png';
import retomarConferenciaEstoqueZendar from 'assets/images/zendar/inicio-retomar-estoque.png';
import sucessoEtapasZendar from 'assets/images/zendar/sucesso-etapas.png';
import inicioImportarXMLZendar from 'assets/images/zendar/inicio-importarXML.png';
import retomarImportarXMLZendar from 'assets/images/zendar/retomar-importarXML.png';

import fundoFomer from 'assets/images/fomer/fundo-login.svg';
import cadastrarMetasComissoesFomer from 'assets/images/fomer/cadastrar-metas-conferencia.png';
import inicioConfererenciaEstoqueFomer from 'assets/images/fomer/inicio-conferir-estoque.png';
import retomarConferenciaEstoqueFomer from 'assets/images/fomer/inicio-retomar-estoque.png';
import sucessoEtapasFomer from 'assets/images/fomer/sucesso-etapas.png';
import inicioImportarXMLFomer from 'assets/images/fomer/inicio-importarXML.png';
import retomarImportarXMLFomer from 'assets/images/fomer/retomar-importarXML.png';

import fundoPowerstock from 'assets/images/powerstock/fundo-login.png';
import cadastrarMetasComissoesPowerstock from 'assets/images/powerstock/cadastrar-metas-conferencia.png';
import inicioConfererenciaEstoquePowerstock from 'assets/images/powerstock/inicio-conferir-estoque.png';
import retomarConferenciaEstoquePowerstock from 'assets/images/powerstock/inicio-retomar-estoque.png';
import sucessoEtapasPowerstock from 'assets/images/powerstock/sucesso-etapas.png';
import inicioImportarXMLPowerstock from 'assets/images/powerstock/inicio-importarXML.png';
import retomarImportarXMLPowerstock from 'assets/images/powerstock/retomar-importarXML.png';

export const loginBackgroundImgs = {
  1: fundoZendar,
  2: fundoPowerstock,
  3: fundoFomer,
  4: fundoFomer,
  5: fundoPowerstock,
};

export const metasConferenciaImgs = {
  1: cadastrarMetasComissoesZendar,
  2: cadastrarMetasComissoesPowerstock,
  3: cadastrarMetasComissoesFomer,
  4: cadastrarMetasComissoesFomer,
  5: cadastrarMetasComissoesPowerstock,
};

export const iniciarConferenciaEstoqueImgs = {
  1: inicioConfererenciaEstoqueZendar,
  2: inicioConfererenciaEstoquePowerstock,
  3: inicioConfererenciaEstoqueFomer,
  4: inicioConfererenciaEstoqueFomer,
  5: inicioConfererenciaEstoquePowerstock,
};

export const retomarConferenciaEstoqueImgs = {
  1: retomarConferenciaEstoqueZendar,
  2: retomarConferenciaEstoquePowerstock,
  3: retomarConferenciaEstoqueFomer,
  4: retomarConferenciaEstoqueFomer,
  5: retomarConferenciaEstoquePowerstock,
};

export const sucessoEtapasImgs = {
  1: sucessoEtapasZendar,
  2: sucessoEtapasPowerstock,
  3: sucessoEtapasFomer,
  4: sucessoEtapasFomer,
  5: sucessoEtapasPowerstock,
};

export const inicioImportarXMLImgs = {
  1: inicioImportarXMLZendar,
  2: inicioImportarXMLPowerstock,
  3: inicioImportarXMLFomer,
  4: inicioImportarXMLFomer,
  5: inicioImportarXMLPowerstock,
};

export const retomarImportarXMLImgs = {
  1: retomarImportarXMLZendar,
  2: retomarImportarXMLPowerstock,
  3: retomarImportarXMLFomer,
  4: retomarImportarXMLFomer,
  5: retomarImportarXMLPowerstock,
};
