import { useState } from 'react';
import {
  Divider,
  Checkbox,
  Flex,
  Text,
  Box,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { DecimalMask, moneyMask } from 'helpers/format/fieldsMasks';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';
import { useProdutosConsignadosContext } from 'store/PDV/ProdutosConsignados';

import { InputQuantidadeAcrescimoDecrescimo } from 'components/PDV/Consignacao/inputAcrescimoDecrescimo';

type ListarProdutosConsignadosProps = {
  quantidade: number[];
  valorTotalItensConsignados: string;
  quantidadeItensTotal: string;
};

export const ListarProdutosConsignadosVersaoTabletMobile = ({
  quantidade,
  valorTotalItensConsignados,
  quantidadeItensTotal,
}: ListarProdutosConsignadosProps) => {
  const [mostrarDetalhesProduto, setDetalhesProduto] = useState(-1);

  function exibirDetalhesProduto(index: number) {
    setDetalhesProduto(mostrarDetalhesProduto === index ? -1 : index);
  }

  const { setValue } = useFormContext();
  const {
    produtosConsignados,
    produtosSelecionados,
    handleToggleSelecionarTodosItens,
    selecionarTodosProdutos,
    handleToggleSelecionarProdutoTroca,
    produtosConsignadosJaForamDevolvidos,
  } = useProdutosConsignadosContext();

  const { casasDecimais } = usePadronizacaoContext();

  function handleDecrementarQuantidade(index: number) {
    const novaQuantidade = quantidade[index] - 1;

    setValue(`quantidade.${index}`, novaQuantidade >= 1 ? novaQuantidade : 1);
  }

  function handleIncrementarQuantidade(index: number) {
    const novaQuantidade = quantidade[index];
    setValue(`quantidade.${index}`, novaQuantidade + 1);
  }

  return (
    <Box overflow="hidden" boxShadow="md" rounded="md" bg="white">
      <Flex
        paddingTop="10px"
        paddingBottom="10px"
        justifyContent="space-between"
        w="full"
        color="gray.500"
        fontSize="xs"
      >
        <Flex ml="20px" whiteSpace="nowrap">
          <Checkbox
            colorScheme="purple"
            isChecked={selecionarTodosProdutos}
            onChange={() => handleToggleSelecionarTodosItens(setValue)}
            mb="0"
            mr="10px"
          />
          <Text>Produtos</Text>
        </Flex>
        <Box mr="30px" whiteSpace="nowrap">
          <Flex justifyContent="right" alignItems="right">
            <Text>Valor total</Text>
          </Flex>
        </Box>
      </Flex>
      <Flex justifyContent="center" alignItems="center">
        <Divider w="95%" />
      </Flex>

      {produtosConsignados.map((produtoConsignado, index) => {
        const quantidadePossuiDecimal =
          produtoConsignado.quantidadeConsignada % 1 !== 0;
        const valorTotal = produtoConsignado.valorUnitario * quantidade[index];

        const exibirQuantidade =
          !quantidadePossuiDecimal &&
          produtoConsignado.quantidadeConsignada > 1;
        return (
          <Box>
            <Grid
              bg={
                produtoConsignado.isChecked
                  ? produtosConsignadosJaForamDevolvidos
                    ? 'aquamarine.100'
                    : 'orange.300'
                  : ''
              }
              templateAreas={`"descricao valortotal"
                    "qtd valortotal"
                    "infAdicional infAdicional"`}
              gridTemplateRows={exibirQuantidade ? '1fr 50px' : '1fr 10px'}
              gridTemplateColumns="1fr 150px "
              gap="0"
              pl="20px"
              pr="5px"
            >
              <GridItem
                cursor="pointer"
                onClick={() => exibirDetalhesProduto(index)}
                area="descricao"
              >
                <Flex justifyContent="left" alignItems="top">
                  <Flex h="35px">
                    <Checkbox
                      colorScheme="purple"
                      isChecked={produtoConsignado.isChecked}
                      onChange={() => handleToggleSelecionarProdutoTroca(index)}
                      mb="0"
                      mr="10px"
                    />
                  </Flex>
                  <Text mt="7px" fontSize="sm">
                    {produtoConsignado.descricao}
                  </Text>
                </Flex>
              </GridItem>
              <GridItem area="valortotal">
                <Flex justifyContent="right" alignItems="top">
                  <Text mt="7px" mr="22px" fontSize="sm">
                    {moneyMask(valorTotal, true)}
                  </Text>
                </Flex>
              </GridItem>
              {exibirQuantidade && (
                <GridItem area="qtd">
                  <Flex mt="5px" justifyContent="left" alignItems="left">
                    <InputQuantidadeAcrescimoDecrescimo
                      handleDecrementarQuantidade={handleDecrementarQuantidade}
                      handleIncrementarQuantidade={handleIncrementarQuantidade}
                      produto={produtoConsignado}
                      index={index}
                      hasVolumeUnitario={produtoConsignado.volumeUnitario}
                      quantidade={quantidade}
                    />
                  </Flex>
                </GridItem>
              )}
            </Grid>
            {mostrarDetalhesProduto === index && (
              <GridItem ml="21px" mb="10px" fontSize="xs" area="infAdicional">
                <Flex>
                  <Text mr="5px">Quantidade devolvida:</Text>
                  <Text>
                    {DecimalMask(
                      produtoConsignado.quantidadeConsignada,
                      casasDecimais.casasDecimaisQuantidade,
                      casasDecimais.casasDecimaisQuantidade
                    )}
                  </Text>
                </Flex>

                <Flex>
                  <Text mr="5px">Valor unitário:</Text>
                  <Text>
                    {DecimalMask(
                      produtoConsignado.valorUnitario,
                      casasDecimais.casasDecimaisQuantidade,
                      casasDecimais.casasDecimaisQuantidade
                    )}
                  </Text>
                </Flex>

                <Flex>
                  <Text mr="5px">Consignação:</Text>
                  <Text>{produtoConsignado.numeroConsignacao}</Text>
                </Flex>
                {produtoConsignado.contaFinanceira && (
                  <Flex>
                    <Text mr="5px">Caixa:</Text>
                    <Text>{produtoConsignado.contaFinanceira}</Text>
                  </Flex>
                )}
                <Flex>
                  <Text mr="5px">Vendedor:</Text>
                  <Text>{produtoConsignado.vendedor}</Text>
                </Flex>
                <Flex>
                  <Text mr="5px">Tabela de preços:</Text>
                  <Text>{produtoConsignado.tabelaPreco}</Text>
                </Flex>
              </GridItem>
            )}
            <Flex justifyContent="center" alignItems="center">
              <Divider w="95%" />
            </Flex>
          </Box>
        );
      })}
      <Box paddingBottom="10px" mr="28px" paddingTop="10px">
        <Flex justifyContent="right" alignItems="center">
          <Text fontSize="xs" mr="4px">
            Itens selecionados:
          </Text>
          <Text
            color={
              produtosConsignadosJaForamDevolvidos
                ? 'aquamarine.300'
                : 'orange.300'
            }
            fontSize="sm"
            fontWeight="bold"
          >
            {DecimalMask(
              produtosSelecionados,
              casasDecimais.casasDecimaisQuantidade,
              casasDecimais.casasDecimaisQuantidade
            )}
          </Text>
        </Flex>
        <Flex justifyContent="right" alignItems="center">
          <Text fontSize="xs" mr="4px">
            Quantidade de itens:
          </Text>
          <Text fontSize="sm" fontWeight="bold">
            {quantidadeItensTotal}
          </Text>
        </Flex>

        <Flex mb="5px" justifyContent="right" alignItems="center">
          <Text fontSize="xs" mr="4px">
            Valor total:
          </Text>

          <Text fontSize="sm" color="blue.700" fontWeight="bold">
            {valorTotalItensConsignados}
          </Text>
        </Flex>
      </Box>
    </Box>
  );
};
