import React, { useEffect } from 'react';
import { FormProvider, useFormContext } from 'react-hook-form';
import {
  Box,
  Button,
  useMediaQuery,
  ModalContent,
  Divider,
  Flex,
  Icon,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Text,
  GridItem,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';

import EstadosEnum from 'constants/enum/estados';

import { SalvarInserirNovoIcon } from 'icons';
import Select from 'components/PDV/Select/SelectPadrao';
import { NumberInput } from 'components/update/Input/NumberInput';
import Input from 'components/PDV/Input';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';

import { useForm, yupResolver, defaultValues } from './validationForm';

interface ExecucaoEstadoICMSModalProps {
  show: boolean;
  onHide: () => void;
  acao: 'editar' | 'duplicar' | 'cadastrar';
  itemIndex: number;
}
type FormData = {
  estadoOrigemId: number | null;
  estadoDestinoId: number | null;
  aliquotaIcms: number;
  reducaoBaseCalculo: number;
  porcentagemFCP: number;
  codigoBeneficioFiscal: string | null;
};

const ExecucaoEstadoICMSModal = ({
  show,
  onHide,
  acao,
  itemIndex,
}: ExecucaoEstadoICMSModalProps) => {
  const { setValue, getValues: parentGetValues } = useFormContext();

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues,
  });

  const { handleSubmit, getValues, reset } = formMethods;

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const onSubmit = handleSubmit(() => {
    const regraFiscalExcecao = getValues();
    const produtoRegraFiscalExcecoesValue = parentGetValues(
      'produtoRegraFiscalExcecoes'
    ) as FormData[];

    if (
      produtoRegraFiscalExcecoesValue.some(
        (pordutoRegraItem, index) =>
          pordutoRegraItem.estadoOrigemId ===
            regraFiscalExcecao.estadoOrigemId &&
          pordutoRegraItem.estadoDestinoId ===
            regraFiscalExcecao.estadoDestinoId &&
          (index !== itemIndex || acao !== 'editar')
      )
    ) {
      toast.warn(
        'Já existe uma exceção cadastrada para este produto com os Estados selecionados.'
      );
      return;
    }

    if (acao === 'cadastrar' || acao === 'duplicar') {
      setValue('produtoRegraFiscalExcecoes', [
        ...produtoRegraFiscalExcecoesValue,
        regraFiscalExcecao,
      ]);
    } else {
      produtoRegraFiscalExcecoesValue.splice(itemIndex, 1);

      produtoRegraFiscalExcecoesValue.splice(itemIndex, 0, regraFiscalExcecao);

      setValue('produtoRegraFiscalExcecoes', [
        ...produtoRegraFiscalExcecoesValue,
      ]);
    }

    onHide();
  });

  useEffect(() => {
    if (show && (acao === 'editar' || acao === 'duplicar')) {
      const produtoRegraFiscalExcecoesValue = parentGetValues(
        'produtoRegraFiscalExcecoes'
      ) as FormData[];

      reset(produtoRegraFiscalExcecoesValue[itemIndex]);
    } else {
      reset(defaultValues);
    }
  }, [acao, itemIndex, parentGetValues, reset, show]);

  return (
    <ModalPadraoChakra
      isOpen={show}
      closeOnOverlayClick={false}
      onClose={onHide}
      isCentered={isLargerThan900}
      size={isLargerThan900 ? '3xl' : 'full'}
    >
      <ModalContent
        bg="gray.50"
        borderRadius={isLargerThan900 ? 'md' : '0'}
        marginBottom={isLargerThan900 ? '3.75rem' : '0'}
        marginTop={isLargerThan900 ? '3.75rem' : '0'}
        w={isLargerThan900 ? '900px' : 'full'}
        h={isLargerThan900 ? '450px' : 'full'}
      >
        <ModalHeader>
          <Text color="primary.50" fontSize="md">
            Exceção do ICMS por Estado
          </Text>
          <Text color="gray.700" fontSize="xs">
            Atenção para o Estados de ORIGEM e DESTINO.
          </Text>
          <ModalCloseButton />
          <Divider mt="10px" />
        </ModalHeader>
        <FormProvider {...formMethods}>
          <ModalBody>
            <SimpleGridForm>
              <GridItem colSpan={[12, 6]}>
                <Select
                  id="excecaoIcms-estadoOrigem"
                  name="estadoOrigemId"
                  label="UF - origem"
                  required
                  valueDefault={0}
                  options={Object.entries(EstadosEnum.properties).map(
                    (value) => {
                      return (
                        {
                          label: value[1].sigla,
                          value: value[1].id,
                        } || {}
                      );
                    }
                  )}
                />
              </GridItem>
              <GridItem colSpan={[12, 6]}>
                <Select
                  id="excecaoIcms-estadoDestino"
                  name="estadoDestinoId"
                  label="UF - destino"
                  required
                  valueDefault={0}
                  options={Object.entries(EstadosEnum.properties).map(
                    (value) => {
                      return (
                        {
                          label: value[1].sigla,
                          value: value[1].id,
                        } || {}
                      );
                    }
                  )}
                />
              </GridItem>
              <GridItem colSpan={[12, 6]}>
                <NumberInput
                  id="excecaoIcms-icmsAliquota"
                  name="aliquotaIcms"
                  label="Alíquota"
                  editarFundoLeftElemento
                  bgLeftElement="gray.50"
                  leftElement="%"
                  precision={5}
                  scale={2}
                  defaultValue={0}
                />
              </GridItem>
              <GridItem colSpan={[12, 6]}>
                <NumberInput
                  id="excecaoIcms-reducaoBaseCalculo"
                  name="reducaoBaseCalculo"
                  label="Redução da base de cálculo"
                  editarFundoLeftElemento
                  bgLeftElement="gray.50"
                  leftElement="%"
                  precision={5}
                  scale={2}
                  defaultValue={0}
                />
              </GridItem>
              <GridItem colSpan={[12, 6]}>
                <NumberInput
                  id="excecaoIcms-fcpAliquota"
                  name="porcentagemFCP"
                  label="% Fundo de combate a pobreza"
                  leftElement="%"
                  precision={5}
                  editarFundoLeftElemento
                  bgLeftElement="gray.50"
                  scale={2}
                  defaultValue={0}
                />
              </GridItem>
              <GridItem colSpan={[12, 6]}>
                <Input
                  name="codigoBeneficioFiscal"
                  label="Código do benefício fiscal"
                  placeholder="Digite o código"
                  maxLength={10}
                />
              </GridItem>
            </SimpleGridForm>
          </ModalBody>
        </FormProvider>
        <ModalFooter>
          <Box w="full">
            <Divider mb="20px" mt="20px" />
            <Flex w="full" justifyContent="flex-end">
              <Button
                type="button"
                variant="outline"
                borderRadius="5px"
                mr={['0', '20px', '20px']}
                onClick={() => onHide()}
                style={{ height: '35px', width: '100%', maxWidth: '100px' }}
              >
                Fechar
              </Button>

              <Button
                variant="solid"
                borderRadius="5px"
                colorScheme="aquamarine"
                leftIcon={<Icon as={SalvarInserirNovoIcon} />}
                onClick={() => onSubmit()}
                style={{
                  height: '35px',
                  width: '100%',
                  maxWidth: '125px',
                }}
              >
                Confirmar
              </Button>
            </Flex>
          </Box>
        </ModalFooter>
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export default ExecucaoEstadoICMSModal;
