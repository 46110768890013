import {
  createContext,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { IdentificacaoEtapasTray } from 'constants/enum/IdentificacaoEtapasTray';
import api, { ResponseApi } from 'services/api';
import ConstanteRotas from 'constants/rotas';
import { enumIdentificacaoIntegracao } from 'constants/enum/enumIdentificacaoIntegracao';
import { getObterEtapaTray } from 'services/tray';

import { ProdutoSelecionado } from 'pages/Integracoes/LojaAplicativos/TrayCommerce/Etapas/ProdutoSistema';

type RefHandleVoltarProps = {
  handleVoltar?: () => void;
  handleAvancar?: () => void;
  handleConfirmarProdutoSelecionado?: () => void;
};

type TrayEtapasProps = {
  activeStep: number;
  setActiveStep: React.Dispatch<SetStateAction<number>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  ref: React.RefObject<RefHandleVoltarProps>;
  setTotalProdutosListados: React.Dispatch<SetStateAction<number>>;
  totalProdutoListados: number;
  setIsProdutoSelecionado: React.Dispatch<SetStateAction<boolean>>;
  isProdutoSelecionado: boolean;
  setIsOpenBuscaAvancada: React.Dispatch<SetStateAction<boolean>>;
  isOpenBuscaAvancada: boolean;
  setHasFiltros: React.Dispatch<SetStateAction<boolean>>;
  hasFiltros: boolean;
  setIsProdutoSistema: React.Dispatch<SetStateAction<boolean>>;
  isProdutoSistema: boolean;
  listStorage: string | null;
  listProdutoLocalStorage: () => ProdutoSelecionado[];
  dadosTray: () => Promise<ResponseApi<{ configuracoes: string }>>;
};

type TrayEtapasProviderProps = {
  children: ReactNode;
};

export const TrayEtapasContext = createContext({} as TrayEtapasProps);

export const TrayEtapasProvider = ({ children }: TrayEtapasProviderProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const [totalProdutoListados, setTotalProdutosListados] = useState(0);
  const [isProdutoSelecionado, setIsProdutoSelecionado] = useState(false);
  const [isOpenBuscaAvancada, setIsOpenBuscaAvancada] = useState(false);
  const [isProdutoSistema, setIsProdutoSistema] = useState(false);
  const [hasFiltros, setHasFiltros] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const ref = useRef<RefHandleVoltarProps>(null);

  const history = useHistory();

  const listStorage = localStorage.getItem('listarProdutoStorage');

  const listProdutoLocalStorage = useCallback(() => {
    let produtosSalvos = [] as ProdutoSelecionado[];
    const storage = localStorage.getItem('listarProdutoStorage');

    if (storage === null) {
      produtosSalvos = [];
    } else {
      produtosSalvos = JSON.parse(storage.toString() || '');
    }

    return produtosSalvos;
  }, []);

  const cadastrarTray = useCallback(async () => {
    setIsLoading(true);

    await api.post<void, ResponseApi>(
      ConstanteEnderecoWebservice.INTEGRACAO_TRAY_CADASTRAR,
      {
        identificacaoIntegracao: enumIdentificacaoIntegracao.TRAY,
      }
    );

    setIsLoading(false);
  }, []);

  const alterarEtapaTray = useCallback(async () => {
    if (
      activeStep === IdentificacaoEtapasTray.GUIA_INTEGRACAO ||
      activeStep === IdentificacaoEtapasTray.CADASTRAR_TABELA_PRECO
    ) {
      return;
    }

    setIsLoading(true);

    await api.put<void, ResponseApi>(
      ConstanteEnderecoWebservice.INTEGRACAO_TRAY_ALTERAR_IDENTIFICACAO_ETAPA,
      {
        identificacaoEtapas: activeStep,
      }
    );

    setIsLoading(false);
  }, [activeStep]);

  const dadosTray = useCallback(async () => {
    const responseDataTray = await api.get<
      void,
      ResponseApi<{ configuracoes: string }>
    >(ConstanteEnderecoWebservice.INTEGRACAO_TRAY_OBTER);
    return responseDataTray;
  }, []);

  const getTray = useCallback(async () => {
    setIsLoading(true);

    const responseDataTray = await dadosTray();

    if (responseDataTray) {
      if (responseDataTray.dados && responseDataTray.sucesso) {
        const response = await getObterEtapaTray();

        if (response !== null) {
          setActiveStep(response);
          if (response === IdentificacaoEtapasTray.ETAPA_FINALIZADA) {
            history.push(ConstanteRotas.INTEGRACAO_TRAY_COMMERCE_PAINEL_ADM);
          }
        }
      } else {
        cadastrarTray();
      }
    }

    setIsLoading(false);
  }, [cadastrarTray, dadosTray, history]);

  useEffect(() => {
    alterarEtapaTray();
  }, [alterarEtapaTray]);

  useEffect(() => {
    getTray();
  }, [getTray]);

  return (
    <TrayEtapasContext.Provider
      value={{
        activeStep,
        setActiveStep,
        isProdutoSelecionado,
        isLoading,
        setIsLoading,
        listStorage,
        listProdutoLocalStorage,
        ref,
        setTotalProdutosListados,
        totalProdutoListados,
        setIsProdutoSelecionado,
        dadosTray,
        isOpenBuscaAvancada,
        setIsOpenBuscaAvancada,
        hasFiltros,
        setHasFiltros,
        isProdutoSistema,
        setIsProdutoSistema,
      }}
    >
      {children}
    </TrayEtapasContext.Provider>
  );
};

export function useTrayEtapasContext(): TrayEtapasProps {
  const context = useContext(TrayEtapasContext);

  if (!context)
    throw new Error(
      'useTrayEtapasContext must be used within a TrayEtapasProvider.'
    );

  return context;
}
