import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

type CurrentProductProps = {
  value: string;
  label: string;
  ativo: boolean;
};

export interface VariationProps {
  produtoCorTamanhoId: string;
  produto: string;
  cor: string;
  tamanho: string;
  imagem: string;
  isChecked: boolean;
}

interface Precos {
  venda: number;
  custo: number;
}

export interface ListProdutosProps {
  tipoProduto: number;
  variacoes: VariationProps[];
  precos: Precos;
}

export type FormData = {
  produtos: CurrentProductProps;
  precoVenda: number;
  precoCusto: number;
  preco: number;
  quantidade: number;
  descontoAcimaQuantidade: boolean;
};

const schema = yup.object().shape({
  precoVenda: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);
