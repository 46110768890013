import { useCallback } from 'react';
import { Text, Box, Flex, Image as ImageChakra } from '@chakra-ui/react';

import { useSistemaAtual } from 'helpers/data/useSistemaAtual';
import { formatDateHourMinute } from 'helpers/format/formatStringDate';
import { testIfIsUrl } from 'helpers/validation/imagesAttachment';

type ImpressaoFichaProps = {
  tituloWatch: string;
  mensagemWatch: string;
  imagem?: string;
  scaleImg?: string;
};

export const ImpressaoFicha = ({
  tituloWatch,
  mensagemWatch,
  imagem = '',
  scaleImg,
}: ImpressaoFichaProps) => {
  const { nomeExibicao } = useSistemaAtual();

  const imagemFoiSelecionada = !!imagem;

  const dataAtualFormatada = formatDateHourMinute(new Date());

  const formattedValue = useCallback((value: string) => {
    if (!testIfIsUrl(value)) return `data:image/jpeg;base64,${value}`;

    return value;
  }, []);

  return (
    <Flex
      bg="white"
      flexDir="column"
      w="210px"
      fontFamily="'Monospac821 BT', sans-serif"
      pt={imagemFoiSelecionada ? '24px' : '32px'}
      pb="18px"
      px="10px"
      boxShadow="0px 0px 6px #00000029"
    >
      {imagemFoiSelecionada && (
        <Flex w="190px" justify="center" mb="12px" filter="grayscale(1)">
          <ImageChakra
            src={formattedValue(imagem)}
            alt="logo impressão"
            id="imagemPreview"
            objectFit="cover"
            w={scaleImg}
          />
        </Flex>
      )}
      {tituloWatch && (
        <Text mb="6px" fontSize="12px" textAlign="center">
          {tituloWatch}
        </Text>
      )}
      <Flex
        flexDir="column"
        borderRadius="4px"
        borderWidth="1px"
        borderStyle="solid"
        borderColor="gray.700"
        padding="8px"
      >
        <Text color="gray.700" w="full" textAlign="center" fontSize="18px">
          Nome do produto
        </Text>
        <Text
          textAlign="center"
          fontWeight="bold"
          color="gray.700"
          fontSize="18px"
        >
          R$ 9,00
        </Text>
      </Flex>

      {!!mensagemWatch && (
        <Text
          mx="auto"
          my="8px"
          w="190px"
          as="p"
          letterSpacing="0px"
          px="4px"
          maxW="190px"
          borderBottomColor="gray.700"
          borderBottomWidth="1px"
          borderBottomStyle="solid"
          pb="8px"
          fontSize="12px"
          textAlign="center"
          whiteSpace="pre-wrap"
        >
          {mensagemWatch}
        </Text>
      )}
      <Flex
        borderBottomColor="gray.700"
        borderBottomWidth="1px"
        borderBottomStyle="solid"
        flexDir="column"
        fontSize="12px"
        align="center"
        justify="center"
        mb="8px"
        letterSpacing="0px"
        pb="8px"
      >
        <Box>
          <Text>{dataAtualFormatada} - 1/1</Text>
          <Text textAlign="center">Dinheiro</Text>
        </Box>
        <Text>POS: 000000000000000</Text>
      </Flex>
      <Text mb="18px" color="gray.700" fontSize="12px" textAlign="center">
        Sistema {nomeExibicao}
      </Text>
    </Flex>
  );
};
