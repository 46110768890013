// coppied from '@chakra-ui/utils

import { isFocusable, isTabbable, isHTMLElement } from './tabbable';

const focusableElList = [
  'input:not([disabled])',
  'select:not([disabled])',
  'textarea:not([disabled])',
  'embed',
  'iframe',
  'object',
  'a[href]',
  'area[href]',
  'button:not([disabled])',
  '[tabindex]',
  'audio[controls]',
  'video[controls]',
  '*[tabindex]:not([aria-disabled])',
  '*[contenteditable]',
];

const focusableElSelector = focusableElList.join();

function getAllFocusable<T extends HTMLElement>(container: T) {
  const focusableEls = Array.from(
    container.querySelectorAll<T>(focusableElSelector)
  );
  focusableEls.unshift(container);
  return focusableEls
    .filter(isFocusable)
    .filter((el) => window.getComputedStyle(el).display !== 'none');
}

export function getAllTabbable<T extends HTMLElement>(
  container: T,
  fallbackToFocusable?: boolean
) {
  const allFocusable = Array.from(
    container.querySelectorAll<T>(focusableElSelector)
  );
  const allTabbable = allFocusable.filter(isTabbable);

  if (isTabbable(container)) {
    allTabbable.unshift(container);
  }

  if (!allTabbable.length && fallbackToFocusable) {
    return allFocusable;
  }
  return allTabbable;
}

function getFirstTabbableIn<T extends HTMLElement>(
  container: T,
  fallbackToFocusable?: boolean
): T | null {
  const [first] = getAllTabbable(container, fallbackToFocusable);
  return first || null;
}

function getLastTabbableIn<T extends HTMLElement>(
  container: T,
  fallbackToFocusable?: boolean
): T | null {
  const allTabbable = getAllTabbable(container, fallbackToFocusable);
  return allTabbable[allTabbable.length - 1] || null;
}

function getNextTabbable<T extends HTMLElement>(
  container: T,
  fallbackToFocusable?: boolean
): T | null {
  const allFocusable = getAllFocusable(container);
  const index = allFocusable.indexOf(document.activeElement as T);
  const slice = allFocusable.slice(index + 1);
  return (
    slice.find(isTabbable) ||
    allFocusable.find(isTabbable) ||
    (fallbackToFocusable ? slice[0] : null)
  );
}

function getPreviousTabbable<T extends HTMLElement>(
  container: T,
  fallbackToFocusable?: boolean
): T | null {
  const allFocusable = getAllFocusable(container).reverse();
  const index = allFocusable.indexOf(document.activeElement as T);
  const slice = allFocusable.slice(index + 1);
  return (
    slice.find(isTabbable) ||
    allFocusable.find(isTabbable) ||
    (fallbackToFocusable ? slice[0] : null)
  );
}

export function focusFirstTabbable<T extends HTMLElement>(
  container: T,
  fallbackToFocusable?: boolean
) {
  const firstTabbable = getFirstTabbableIn(container, fallbackToFocusable);
  if (firstTabbable && isHTMLElement(firstTabbable)) {
    firstTabbable.focus();
  }
}

export function focusLastTabbable<T extends HTMLElement>(
  container: T,
  fallbackToFocusable?: boolean
) {
  const lastTabbable = getLastTabbableIn(container, fallbackToFocusable);
  if (lastTabbable && isHTMLElement(lastTabbable)) {
    lastTabbable.focus();
  }
}

export function focusNextTabbable<T extends HTMLElement>(
  container: T,
  fallbackToFocusable?: boolean
) {
  const nextTabbable = getNextTabbable(container, fallbackToFocusable);
  if (nextTabbable && isHTMLElement(nextTabbable)) {
    nextTabbable.focus();
  }
}

export function focusPreviousTabbable<T extends HTMLElement>(
  container: T,
  fallbackToFocusable?: boolean
) {
  const previousTabbable = getPreviousTabbable(container, fallbackToFocusable);
  if (previousTabbable && isHTMLElement(previousTabbable)) {
    previousTabbable.focus();
  }
}
