import { Box, Flex, Icon, Td, Tr, Text, Image } from '@chakra-ui/react';
import { RiImageAddFill } from 'react-icons/ri';

import { DecimalMask, moneyMask } from 'helpers/format/fieldsMasks';
import ConstanteFuncionalidades from 'constants/permissoes';
import auth from 'modules/auth';
import { KitProps } from 'store/Produtos/ProdutosFormulario';

import { ActionsMenu } from 'components/update/Table/ActionsMenu';

type KitItemProps = {
  tableValue: KitProps;
  readonly: boolean;
  handleAbrirModalKit: (value: number | undefined) => void;
  handleRemoverProdutoKit: (idKit: string, index: number) => Promise<void>;
  index: number;
};

export const KitItem = ({
  tableValue,
  index,
  readonly,
  handleAbrirModalKit,
  handleRemoverProdutoKit,
}: KitItemProps) => {
  const possuiPermissaoVisualizarPrecoCusto = auth.possuiPermissao(
    ConstanteFuncionalidades.USUARIO_VISUALIZAR_PRECO_CUSTO
  ).permitido;

  const valueMarkup = (tableValue.valor / tableValue.precoCusto - 1) * 100 || 0;
  return (
    <>
      <Box h="2px" />
      <Tr
        sx={{
          boxShadow: '0px 0px 4px #00000029',
          borderRadius: '5px',
          cursor: 'pointer',
          _hover: {
            boxShadow: '0px 0px 4px rgba(85, 2, 178, 0.5)',
          },
          '& td': {
            height: '64px !important',
            background: 'white',
          },
        }}
        key={tableValue.id}
      >
        <Td borderStartRadius="5px">
          <Flex>
            <Box
              boxSize="40px"
              borderWidth="1px"
              borderRadius="4px"
              borderColor="gray.200"
            >
              {tableValue.produtoCorTamanho.imagem ? (
                <Image
                  boxSize="40px"
                  src={tableValue.produtoCorTamanho.imagem}
                />
              ) : (
                <Flex
                  mb="3px"
                  h="full"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Icon fontSize="15px" color="gray.500" as={RiImageAddFill} />
                </Flex>
              )}
            </Box>
            <Box pl="5px">
              {`${tableValue.produtoCorTamanho.produto}`}
              <Text>
                <Text display="inline" color="teal.600">
                  {tableValue.produtoCorTamanho.cor}
                </Text>
                {tableValue.produtoCorTamanho.cor &&
                  tableValue.produtoCorTamanho.tamanho && (
                    <Text display="inline" margin="0px 0.25rem">
                      |
                    </Text>
                  )}
                <Text display="inline" color="pink.700">
                  {tableValue.produtoCorTamanho.tamanho}
                </Text>
              </Text>
            </Box>
          </Flex>
        </Td>
        <Td pr="12px" isNumeric>
          <Flex justifyContent="flex-end" display="grid" h="full" w="full">
            {DecimalMask(tableValue.quantidade || 0, 2, 4)}
          </Flex>
        </Td>
        {possuiPermissaoVisualizarPrecoCusto ? (
          <Td pr="12px" isNumeric>
            <Flex
              alignItems="flex-end"
              justifyContent="center"
              flexDirection="column"
            >
              <Box color="black">
                <span style={{ fontSize: '10px' }}>R$</span>{' '}
                {moneyMask(tableValue.precoCusto || 0, false)}
              </Box>
              <Box color="gray.700" fontSize="12px">
                <span style={{ fontSize: '10px' }}>R$</span>{' '}
                {moneyMask(
                  (tableValue.precoCusto || 0) * tableValue.quantidade,
                  false
                )}
              </Box>
            </Flex>
          </Td>
        ) : (
          <Td />
        )}
        <Td pr="12px" isNumeric>
          <Flex
            alignItems="flex-end"
            justifyContent="center"
            flexDirection="column"
          >
            <Box color="black">
              <span style={{ fontSize: '10px' }}>R$</span>{' '}
              {moneyMask(tableValue.valor || 0, false)}
            </Box>
            <Box color="gray.700" fontSize="12px">
              <span style={{ fontSize: '10px' }}>R$</span>{' '}
              {moneyMask(
                (tableValue.valor || 0) * tableValue.quantidade,
                false
              )}
            </Box>
          </Flex>
        </Td>
        <Td pr="12px" isNumeric>
          <Flex justifyContent="flex-end" display="grid" h="full" w="full">
            {valueMarkup === Infinity
              ? `${DecimalMask(0, 2, 2)}%`
              : `${DecimalMask(valueMarkup, 2, 4)}%`}{' '}
          </Flex>
        </Td>
        <Td borderEndRadius="5px">
          <ActionsMenu
            isDisabled={readonly}
            items={[
              {
                content: 'Editar',
                onClick: () => {
                  handleAbrirModalKit(index);
                },
              },
              {
                content: 'Remover',
                onClick: () => {
                  handleRemoverProdutoKit(tableValue.id, index);
                },
              },
            ]}
          />
        </Td>
      </Tr>
      <Box h="2px" />
    </>
  );
};
