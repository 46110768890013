import { Text, Flex, Tr, Td, Icon } from '@chakra-ui/react';
import { IconType } from 'react-icons/lib';

import { DecimalMask } from 'helpers/format/fieldsMasks';
import { formatDate } from 'helpers/format/formatStringDate';

import {
  ForwardRefData,
  LoadMoreRowsParams,
  TableHeader,
  VirtualizedInfiniteTable,
} from 'components/update/Table/VirtualizedInfiniteTable';

import { ModalTrocarProdutosPdv } from '../validationForms';

type EscolherVendaParaDevolucaoProps = {
  isLoading: boolean;
  vendaRef: React.RefObject<ForwardRefData>;
  vendas: ModalTrocarProdutosPdv[];
  handleExibirProdutoTroca: (
    descProduto: ModalTrocarProdutosPdv
  ) => Promise<void>;
  itemsTotalCount: number;
  loadMoreRows: ({
    currentPage,
    pageSize,
    orderColumn,
    orderDirection,
  }: LoadMoreRowsParams) => Promise<void>;
  FiChevronRight: IconType;
};

export const EscolherVendaParaDevolucao = ({
  isLoading,
  vendaRef,
  vendas,
  handleExibirProdutoTroca,
  itemsTotalCount,
  FiChevronRight,
  loadMoreRows,
}: EscolherVendaParaDevolucaoProps) => {
  const vendasTableHeaders: TableHeader[] = [
    {
      key: 'venda',
      content: 'Venda',
      whiteSpace: 'nowrap',
      width: '1%',
      justifyContent: 'left',
      alignItems: 'left',
    },
    { key: 'cliente', content: 'Cliente', width: '50%' },
    {
      key: 'data',
      content: 'Data',
      isNumeric: false,
      justifyContent: 'center',
      alignItems: 'center',
      width: '35%',
      whiteSpace: 'nowrap',
    },
    {
      key: 'valor',
      content: 'Valor',
      isNumeric: true,
      justifyContent: 'right',
      width: '10%',
      alignItems: 'right',
      whiteSpace: 'nowrap',
    },
  ];
  return (
    <VirtualizedInfiniteTable
      ref={vendaRef}
      boxShadow="null"
      isUpdateWidthTable
      paddingRight="10px"
      color="gray.300"
      variant="outline"
      isMobileProdutoTroca
      isLoading={isLoading}
      withoutRowsMessage="Não existem produtos para troca."
      orderColumn="dataEmissao"
      orderDirection="desc"
      alterarBordaListagem="white"
      visibleItemsCount={9}
      pageSize={20}
      tableHeaders={vendasTableHeaders}
      rowRenderer={({ index, style: { height, ...restStyle }, key }) => {
        const todasVendas = vendas[index];

        if (!todasVendas) {
          return null;
        }

        return (
          <>
            <Tr
              position="relative"
              key={key}
              style={restStyle}
              cursor="pointer"
            >
              <Flex
                _hover={{
                  opacity: '0.6',
                }}
                alignItems="center"
                onClick={() => handleExibirProdutoTroca(todasVendas)}
              >
                <Td width="1%">
                  <Text
                    position="absolute"
                    top="3"
                    left="2"
                    color="primary.500"
                    fontSize="14px"
                  >
                    {todasVendas.numeroOperacao}
                  </Text>
                </Td>

                <Td w="45%">
                  <Flex w="full">
                    <Text isTruncated w="full" fontSize="14px">
                      {todasVendas.clienteNome}
                    </Text>
                  </Flex>
                </Td>
                <Td width="14%" ml="-2px">
                  <Text w="80px" fontSize="14px">
                    {formatDate(todasVendas?.dataEmissao || '')}
                  </Text>
                </Td>
                <Flex
                  w="140px"
                  position="absolute"
                  top="3"
                  right="2"
                  justifyContent="flex-end"
                  alignItems="right"
                >
                  <Text isTruncated fontSize="14px">
                    {`R$ ${DecimalMask(todasVendas?.valorTotalOperacao, 2, 2)}`}
                  </Text>
                  <Flex mt="2px" fontSize="14px">
                    <Icon
                      color="black"
                      top="16px"
                      right="30px"
                      cursor="pointer"
                      as={FiChevronRight}
                    />
                  </Flex>
                </Flex>
              </Flex>
            </Tr>
          </>
        );
      }}
      rowCount={itemsTotalCount}
      isRowLoaded={({ index }) => !!vendas[index]}
      loadMoreRows={loadMoreRows}
    />
  );
};
