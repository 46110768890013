import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import { isValidCnpj } from 'helpers/validation/IsValidCpfCnpj';

const schema = yup.object().shape({
  chaveAcesso: yup
    .string()
    .min(44, ConstanteMensagemValidacao.MIN_LENGTH_44)
    .when('tipoDocumentoReferenciado', {
      is: 0,
      then: yup.string().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
    }),
  modeloFiscal: yup.number().when('tipoDocumentoReferenciado', {
    is: 1,
    then: yup
      .number()
      .nullable()
      .default(null)
      .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  }),
  codEstado: yup.string().when('tipoDocumentoReferenciado', {
    is: 1,
    then: yup.string().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  }),
  cnpj: yup.string().when('tipoDocumentoReferenciado', {
    is: 1,
    then: yup
      .string()
      .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
      .test('cnpj', ConstanteMensagemValidacao.CNPJ_INVALIDO, (value) => {
        if (value) return isValidCnpj(value);

        return true;
      })
      .max(18, ConstanteMensagemValidacao.MAX_LENGTH_18),
  }),
  emissaoMes: yup.string().when('tipoDocumentoReferenciado', {
    is: 1,
    then: yup.string().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  }),
  emissaoAno: yup.string().when('tipoDocumentoReferenciado', {
    is: 1,
    then: yup.string().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  }),
  numero: yup.string().when('tipoDocumentoReferenciado', {
    is: 1,
    then: yup.string().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  }),
  serie: yup.string().when('tipoDocumentoReferenciado', {
    is: 1,
    then: yup.string().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  }),
});

export const yupResolver = yupResolverInstance(schema);

export const useForm = useFormInstance;
