import React, { useCallback, useEffect } from 'react';
import { Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FiXCircle as LimparIcon } from 'react-icons/fi';
import { FormProvider, useForm } from 'react-hook-form';
import { FormLabel } from '@chakra-ui/react';

import { LupaIcon } from 'icons';
import ButtonPadrao from 'components/Button/ButtonPadrao';
import SelectPadrao from 'components/Select/SelectPadrao';
import StatusFiscaisEnum from 'constants/enum/fiscal/statusFiscal';
import ModalPadrao from 'components/Modal/ModalPadrao';
import ModelosFiscaisEnum from 'constants/enum/fiscal/modelosFiscais';
import InputPadrao from 'components/Input/InputPadrao';
import InputNumerico from 'components/Input/InputNumerico';
import InputDateRange from 'components/PDV/InputDateRange';

import Modal from './styles';

interface BuscaAvancadaModalProps {
  show: boolean;
  onHide: (willReset?: boolean) => void;
  onSubmit: (novosFiltros: any) => void;
  filtros: any;
}

const BuscaAvancadaModal: React.FC<BuscaAvancadaModalProps> = ({
  show,
  onHide,
  onSubmit,
  filtros,
}) => {
  const { t } = useTranslation();

  const formMethods = useForm({
    defaultValues: filtros,
  });

  const handlePesquisar = useCallback(() => {
    const data = formMethods.getValues();

    onSubmit({ ...data });
  }, [formMethods, onSubmit]);

  useEffect(() => {
    if (show) {
      formMethods.reset({ ...filtros });
    }
  }, [filtros, formMethods, show]);

  return (
    <ModalPadrao
      isOpen={show}
      handleOnHide={onHide}
      title={t('Busca avançada')}
      subtitle={t(
        'Utilize os filtros de busca para uma pesquisa específica. Todos os campos são acumulativos.'
      )}
      maxWidth="800px"
    >
      <FormProvider {...formMethods}>
        <Modal.Body>
          <Form>
            <Form.Row>
              <Col
                xs={12}
                sm={12}
                md={12}
                style={{ paddingBottom: '10px', paddingTop: '10px' }}
              >
                <FormLabel mb="0" fontSize="sm" color="black">
                  Período
                </FormLabel>
                <InputDateRange
                  borderColor="gray.100"
                  borderRadius="md"
                  id="dataHora"
                  name="dataHora"
                  placeholder="dd/mm/aaaa"
                  startDateName="dataInicio"
                  endDateName="dataFim"
                  isClearable
                  maxDate={new Date()}
                />
              </Col>

              <Col xs={12} sm={6} style={{ paddingBottom: '10px' }}>
                <SelectPadrao
                  id="status"
                  name="status"
                  label={t('Status')}
                  required
                  defaultValue={filtros.status ?? null}
                  control={formMethods.control}
                  placeholder="Selecione"
                  options={[
                    { label: 'Todos', value: null },
                    ...Object.entries(StatusFiscaisEnum.properties).map(
                      (value: any) => {
                        return (
                          {
                            label: t(value[1].name),
                            value: value[1].value,
                          } || {}
                        );
                      }
                    ),
                  ]}
                />
              </Col>
              <Col xs={12} sm={6} style={{ paddingBottom: '10px' }}>
                <SelectPadrao
                  id="modelo"
                  name="modelo"
                  label={t('Modelo ')}
                  required
                  defaultValue={filtros.modelo ?? null}
                  control={formMethods.control}
                  placeholder="Selecione"
                  options={[
                    { label: 'Todos', value: null },
                    ...Object.entries(ModelosFiscaisEnum.properties).map(
                      (value: any) => {
                        return (
                          {
                            label: t(value[1].name),
                            value: value[1].value,
                          } || {}
                        );
                      }
                    ),
                  ]}
                />
              </Col>
              <Col xl={6} sm={6}>
                <InputNumerico
                  id="numero"
                  name="numero"
                  label={t('Número')}
                  placeholder={t('Informe o número')}
                  maxLength={13}
                  error={undefined}
                  disabled={false}
                  control={formMethods.control}
                />
              </Col>
              <Col xl={6} sm={6}>
                <InputPadrao
                  type="text"
                  id="descricao"
                  label={t('Nome do cliente')}
                  placeholder={t('Buscar nota fiscal por cliente')}
                  control={formMethods.control}
                />
              </Col>
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <ButtonPadrao
            id="limparFiltros"
            name="limparFiltros"
            text={t('Limpar filtros')}
            variant="outline-secondary"
            icon={LimparIcon}
            style={{ maxWidth: '150px', height: '35px', marginRight: '35px' }}
            type="button"
            onClick={() => onHide(true)}
          />

          <ButtonPadrao
            id="pesquisar"
            name="pesquisar"
            text={t('Pesquisar')}
            variant="success"
            icon={LupaIcon}
            style={{ maxWidth: '150px', height: '35px' }}
            type="button"
            onClick={handlePesquisar}
          />
        </Modal.Footer>
      </FormProvider>
    </ModalPadrao>
  );
};

export default BuscaAvancadaModal;
