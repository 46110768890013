import React, { useCallback, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { GridItem } from '@chakra-ui/react';

import api, { ResponseApi } from 'services/api';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import StatusConsultaEnum from 'constants/enum/statusConsulta';
import PerfilUsuarioPersonalizadoOpcaoConstante from 'constants/perfilUsuarioPersonalizadoOpcao';
import { useFormularioUsuariosContext } from 'store/FormularioUsuarios';
import listarCategoriaPermissoes from 'helpers/api/PerfilUsuario/listarCategoriaPermissoes';

import Permissoes from 'components/Permissoes';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import { OptionsInterface } from 'components/Select/SelectCreate';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

interface PermissoesProps {
  readonly?: boolean;
}

export interface PermissaoCadastroInterface {
  index: string;
  id: string;
  nome: string;
  descricao: string;
  selecionado: boolean;
}

export interface PermissaoGrupoInterface {
  id: string;
  descricao: string;
  permissoes: Array<PermissaoCadastroInterface>;
}

export interface CategoriaPermissaoInterface {
  id: string;
  descricao: string;
  grupos: Array<PermissaoGrupoInterface>;
  servico: number | null;
}

interface PerfilUsuarioInterface {
  id: string;
  nome: string;
}

type ListaPerfil = {
  label: string;
  value: number;
};

const PermissoesTab = ({ readonly }: PermissoesProps) => {
  const [perfilUsuario, setPerfilUsuario] = useState<ListaPerfil[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const isMountedRef = useIsMountedRef();
  const { categorias, setCategorias } = useFormularioUsuariosContext();

  const handleChangePerfil = useCallback(
    async (perfilId: string) => {
      setIsLoading(true);
      if (perfilId === '0') {
        setIsLoading(false);
        // Perfil personalizado
        return;
      }

      const newCategorias = await listarCategoriaPermissoes(perfilId);

      if (newCategorias) {
        if (setCategorias) {
          setCategorias(newCategorias);
          setIsLoading(false);
        }
      }
      setIsLoading(false);
    },
    [setCategorias]
  );

  const handleGetPerfilUsuario = useCallback(async () => {
    const response = await api.get<void, ResponseApi<PerfilUsuarioInterface[]>>(
      ConstanteEnderecoWebservice.PERFIL_USUARIO_SELECT,
      {
        params: { statusConsulta: StatusConsultaEnum.ATIVOS },
      }
    );

    if (response?.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (response?.sucesso && isMountedRef.current) {
      const ListaPerfil = [];

      ListaPerfil.push(PerfilUsuarioPersonalizadoOpcaoConstante);

      response.dados.forEach((perfil) => {
        ListaPerfil.push({
          label: perfil.nome,
          value: perfil.id,
        } as OptionsInterface);
      });
      setPerfilUsuario(ListaPerfil);
    }

    return undefined;
  }, [isMountedRef]);

  useEffect(() => {
    handleGetPerfilUsuario();
  }, [handleGetPerfilUsuario]);

  return (
    <SimpleGridForm>
      {isLoading && <LoadingPadrao />}
      <GridItem colSpan={6}>
        <SelectPadrao
          id="perfilusuario"
          name="perfilusuario"
          placeholder="Selecione um perfil já definido"
          label="Perfil de usuário"
          options={perfilUsuario}
          isDisabled={readonly}
          onSelect={(newValue: any) => {
            if (newValue.value) {
              handleChangePerfil(newValue.value);
            }
          }}
        />
      </GridItem>

      <GridItem colSpan={12}>
        <Permissoes
          readonly={readonly}
          categorias={categorias}
          handleAlterado
        />
      </GridItem>
    </SimpleGridForm>
  );
};

export default PermissoesTab;
