import React, {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useRef,
} from 'react';

import { EnumSmartPos } from 'constants/enum/enumSmartPos';

interface SmartPosContextProps {
  activeStep: EtapaSelecionadaProps;
  setActiveStep: Dispatch<SetStateAction<EtapaSelecionadaProps>>;
  ref: React.RefObject<ButtonClickProps>;
  isStepAlterada: boolean;
  setIsStepAlterada: React.Dispatch<React.SetStateAction<boolean>>;
}

type ButtonClickProps = {
  handleSalvar?: () => void;
  handleCancelar?: () => void;
};

export type EtapaSelecionadaProps = {
  name: string;
  value: number;
};

export const SmartPosContext = createContext<SmartPosContextProps>(
  {} as SmartPosContextProps
);

interface SmartPosProviderProps {
  children: React.ReactNode;
}

export function SmartPosProvider({
  children,
}: SmartPosProviderProps): JSX.Element {
  const [activeStep, setActiveStep] = useState<EtapaSelecionadaProps>(
    EnumSmartPos.DISPOSITIVO
  );
  const [isStepAlterada, setIsStepAlterada] = useState(false);

  const ref = useRef<ButtonClickProps>(null);

  return (
    <SmartPosContext.Provider
      value={{
        activeStep,
        setActiveStep,
        ref,
        isStepAlterada,
        setIsStepAlterada,
      }}
    >
      {children}
    </SmartPosContext.Provider>
  );
}

export function useSmartPosContext(): SmartPosContextProps {
  const context = useContext(SmartPosContext);

  if (!context)
    throw new Error('SmartPosContext must be used within a SmartPosProvider.');

  return context;
}
