import { useState, useEffect, memo } from 'react';

import { usePlanoContasGeralContext } from 'store/PlanoContas/PlanoContasGeral';

import {
  Container,
  Card,
  SideContainer,
  ExpandirRecolherButton,
  ExpandirIcon,
  RecolherIcon,
  NivelIcon,
  ItemText,
} from '../styles';

interface ItemProps {
  title: string;
  haveChildrens: boolean;
  children: React.ReactNode;
}

const nivel = 1;

export const ItemInicialVisaoCaixa = memo(
  ({ title, haveChildrens, children }: ItemProps) => {
    const { itemsExpandidos } = usePlanoContasGeralContext();

    const [isOpen, setIsOpen] = useState(false);
    const [firstRender, setFirstRender] = useState(false);

    useEffect(() => {
      if (firstRender) setFirstRender(true);
    }, [firstRender]);

    useEffect(() => {
      if (!firstRender) setIsOpen(itemsExpandidos);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsExpandidos]);

    return (
      <>
        <Container nivel={nivel}>
          <NivelIcon />
          <Card
            nivel={1}
            inativo={false}
            id="planoConta-draggable"
            style={{ cursor: 'default' }}
          >
            <SideContainer>
              {haveChildrens && (
                <ExpandirRecolherButton
                  id={`expandir-planoConta-nivel${nivel}`}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  {isOpen ? <ExpandirIcon /> : <RecolherIcon />}
                </ExpandirRecolherButton>
              )}

              <ItemText id="planoConta-nome">{title}</ItemText>
            </SideContainer>
          </Card>
        </Container>

        {isOpen && haveChildrens && children}
      </>
    );
  }
);
