import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

const schema = yup.object().shape({
  valor: yup.number().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  contaFinanceiraId: yup
    .object()
    .shape({
      label: yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
      value: yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
    })
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  formaPagamentoRecebimentoId: yup
    .object()
    .shape({
      label: yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
      value: yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
    })
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);

export const defaultValues = { ...schema.default(), valorTotal: 0 } as Record<
  string,
  any
>;

export const useForm = useFormInstance;
