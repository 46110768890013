import React, { useState, useEffect, memo, useMemo } from 'react';
import { IoMdArrowDown, IoMdArrowUp } from 'react-icons/io';
import { DndProvider } from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';
import { Box, Flex, Divider, Button, Text, Icon } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';

import ConstanteFuncionalidades from 'constants/permissoes';
import { useCategoriasProdutoContext } from 'store/CategoriasProduto';
import auth from 'modules/auth';

import Loading from 'components/Layout/Loading/LoadingPadrao';
import { IoMdArrowDownRight, SalvarInserirNovoIcon } from 'icons';
import { FilterSelect } from 'components/update/Select/FilterSelect';

import { useForm, yupResolver } from '../validationForm';
import { ItemAdicionandoContent } from '../ItemContent';
import ItemsContainer from '../ItemsContainer';
import ItemDragLayer from '../ItemDragLayer';
import { ActionContainer } from '../components/ActionContainer';
import { Container } from '../components/Container';
import { Card } from '../components/Card';

const CardContent = () => {
  const {
    handleGetCategoriasProduto,
    isLoading,
    itemsExpandidos,
    setItemsExpandidos,
  } = useCategoriasProdutoContext();

  const formMethods = useForm({
    resolver: yupResolver,
    defaultValues: {
      status: true,
    },
  });

  const [adicionando, setAdicionando] = useState(false);

  const permissaoCategoriaProdutoAlterar = useMemo(
    () =>
      auth.possuiPermissao(ConstanteFuncionalidades.CATEGORIA_PRODUTO_ALTERAR),
    []
  );
  const permissaoCategoriaProdutoCadastrar = useMemo(
    () =>
      auth.possuiPermissao(
        ConstanteFuncionalidades.CATEGORIA_PRODUTO_CADASTRAR
      ),
    []
  );

  useEffect(
    () =>
      handleGetCategoriasProduto({
        status: true,
      }),
    [handleGetCategoriasProduto]
  );

  return (
    <>
      {isLoading && <Loading />}

      <Flex direction={['column', 'column', 'row']}>
        <Box flex={1}>
          <Box>
            <Flex
              justifyContent="center"
              direction="column"
              fontSize="13px"
              fontWeight="bold"
              minW="180px"
            >
              <Text>
                O modo de edição permite adicionar, editar, excluir ou criar
                sub-categorias utilizando até 4 níveis de diretório.
              </Text>
              <Text>
                Clique, segure e arraste as TAGS para movê-las entre os níveis.
              </Text>
            </Flex>
          </Box>
        </Box>

        <Box my={['10px', '10px', '0']} minWidth="190px">
          <FormProvider {...formMethods}>
            <FilterSelect
              id="status"
              name="status"
              isDisabled={isLoading}
              options={[
                {
                  value: true,
                  label: 'Ativos',
                },
                {
                  value: null,
                  label: 'Ativos e Inativos',
                },
              ]}
              onGetValue={(event) => {
                handleGetCategoriasProduto({
                  status: event.value,
                });
              }}
            />
          </FormProvider>
        </Box>
      </Flex>
      <Divider my="30px" />
      <ActionContainer direction={['column', 'column', 'row']} pl="36px">
        <Button
          leftIcon={<SalvarInserirNovoIcon />}
          variant="outlineDefault"
          colorScheme="blue.500"
          fontSize="sm"
          onClick={() => setAdicionando(true)}
          isDisabled={!permissaoCategoriaProdutoCadastrar}
        >
          Criar nova categoria principal
        </Button>

        <Button
          variant="linkDefault"
          fontSize="12px"
          onClick={() => setItemsExpandidos(!itemsExpandidos)}
        >
          {itemsExpandidos ? <IoMdArrowUp /> : <IoMdArrowDown />}
          <Text ml="5px">
            {itemsExpandidos ? 'Recolher níveis' : 'Expandir níveis'}
          </Text>
        </Button>
      </ActionContainer>
      <DndProvider options={HTML5toTouch}>
        {adicionando && (
          <Container height={36} nivel={1}>
            <Icon as={IoMdArrowDownRight} fontSize="16px" />
            <Card nivel={1} isTyping>
              <ItemAdicionandoContent ativo setAdicionando={setAdicionando} />
            </Card>
          </Container>
        )}
        <ItemsContainer />

        {permissaoCategoriaProdutoAlterar.permitido ? <ItemDragLayer /> : null}
      </DndProvider>
    </>
  );
};

export default memo(CardContent);
