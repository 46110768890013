import { Route } from 'react-router-dom';

import { LojaAplicativos } from 'pages/Integracoes/LojaAplicativos';
import { SmartPos } from 'pages/Integracoes/LojaAplicativos/SmartPos';
import { Zoop } from 'pages/Integracoes/LojaAplicativos/Zoop';

import ConstanteRotas from 'constants/rotas';

import LayoutGuard from './LayoutGuard';

export const LojaAplicativoRoutes = [
  <Route path={ConstanteRotas.LOJA_APLICATIVOS} key="loja-aplicativo-listar">
    <LayoutGuard
      key="loja-aplicativo-listar"
      component={LojaAplicativos}
      breadcrumb={[
        {
          title: 'Integrações',
        },
        {
          title: 'Loja de aplicativos',
        },
      ]}
      path={ConstanteRotas.LOJA_APLICATIVOS}
      exact
      meta={{ auth: true }}
    />
    <LayoutGuard
      key="smart-pos-listar"
      component={SmartPos}
      breadcrumb={[
        {
          title: 'Integrações',
        },
        {
          title: 'Loja de aplicativos',
          path: ConstanteRotas.LOJA_APLICATIVOS,
        },
        {
          title: 'Smart Pos',
        },
      ]}
      path={ConstanteRotas.SMART_POS}
      exact
      meta={{ auth: true }}
    />
    <LayoutGuard
      key="zoop"
      component={Zoop}
      breadcrumb={[
        {
          title: 'Integrações',
        },
        {
          title: 'Loja de aplicativos',
          path: ConstanteRotas.LOJA_APLICATIVOS,
        },
        {
          title: `Zoop Recebimentos`,
        },
      ]}
      path={ConstanteRotas.ZOOP}
      exact
      meta={{ auth: true }}
    />
  </Route>,
];
