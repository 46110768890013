import React, { ReactNode } from 'react';
import { Box, Heading } from '@chakra-ui/react';

interface CardProps {
  title: string;
  children: ReactNode;
  padding?: string;
}

const Card = ({ title, children, padding = '24px' }: CardProps) => {
  return (
    <Box w="full">
      <Heading fontSize="md" color="gray.700" mb="2" fontWeight="semibold">
        {title}
      </Heading>
      <Box
        boxShadow="sm"
        borderRadius="md"
        bg="white"
        padding={padding}
        overflow="auto"
      >
        {children}
      </Box>
    </Box>
  );
};

export default Card;
