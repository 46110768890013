const ConstanteRotasPDV = {
  PDV_HOME: '/pdv',
  PDV_CONSULTAR_OPERACOES: '/pdv/consultar-operacoes',
  PDV_LANCAMENTO: '/pdv/lancamento',
  PDV_LANCAMENTO_ID: '/pdv/lancamento/:id?',
  PDV_PAGAMENTO: '/pdv/pagamento/:id',
  PDV_FINALIZAR: '/pdv/finalizar/:id',
  PDV_FINALIZAR_GERAR_VOUCHER: '/pdv/finalizar/impressao-voucher/:id',
  PDV_FINALIZAR_DEVOLUCAO_DINHEIRO: '/pdv/finalizar/impressao-devolucao/:id',
  PDV_COMPARTILHAR: '/pdv/compartilhar/:id',
  PDV_ABRIR_PDV: '/pdv/abrir-pdv',
  PDV_ABRIR_CAIXA: '/pdv/abrir-caixa',
  PDV_RECEBIMENTO_CONTAS: '/pdv/recebimento-contas',
  PDV_TROCAR_PRODUTOS: '/pdv/trocar-produtos',
  PDV_PRODUTOS_CONSIGNADOS: '/pdv/produtos-consignados',
  PDV_ABERTURA_CAIXA: '/pdv/abertura-caixa',
};

export default ConstanteRotasPDV;
