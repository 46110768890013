import { GridItem } from '@chakra-ui/react';

import ConstanteFuncionalidades from 'constants/permissoes';
import auth from 'modules/auth';

import { NumberInput } from 'components/update/Input/NumberInput';
import InputInteger from 'components/PDV/InputInteger';

import { ListProdutosProps } from '../validationForm';

type InformPricesProps = {
  casasDecimaisValor: number;
  isExistProduct?: ListProdutosProps;
};

export const InformPrices = ({
  casasDecimaisValor,
  isExistProduct,
}: InformPricesProps) => {
  const possuiPermissaoVisualizarPrecoCusto = auth.possuiPermissao(
    ConstanteFuncionalidades.USUARIO_VISUALIZAR_PRECO_CUSTO
  ).permitido;

  return (
    <>
      <GridItem colSpan={{ base: 12, md: 3 }}>
        <NumberInput
          id="precoCusto"
          name="precoCusto"
          label="Custo"
          pattern="(^[\d-\)\(]+$)"
          bgLeftElement="gray.50"
          editarFundoLeftElemento
          leftElement="R$"
          esconderValor={!possuiPermissaoVisualizarPrecoCusto}
          placeholder={`0,${'0'.repeat(casasDecimaisValor)}`}
          scale={casasDecimaisValor}
          isDisabled
        />
      </GridItem>
      <GridItem colSpan={{ base: 12, md: 3 }}>
        <NumberInput
          id="preco"
          name="preco"
          label="Preço atual"
          pattern="(^[\d-\)\(]+$)"
          bgLeftElement="gray.50"
          editarFundoLeftElemento
          leftElement="R$"
          placeholder={`0,${'0'.repeat(casasDecimaisValor)}`}
          scale={casasDecimaisValor}
          isDisabled
        />
      </GridItem>
      <GridItem colSpan={{ base: 12, md: 3 }}>
        <NumberInput
          id="precoVenda"
          name="precoVenda"
          label="Preço novo"
          pattern="(^[\d-\)\(]+$)"
          bgLeftElement="gray.50"
          editarFundoLeftElemento
          leftElement="R$"
          placeholder={`0,${'0'.repeat(casasDecimaisValor)}`}
          scale={casasDecimaisValor}
          isDisabled={!isExistProduct}
        />
      </GridItem>
      <GridItem colSpan={{ base: 12, md: 3 }}>
        <InputInteger
          id="quantidade"
          helperText="A partir dessa quantidade a promoção fica ativa"
          name="quantidade"
          label="Quantidade"
          defaultValue={1}
          min={1}
          scale={0}
          isDisabled={!isExistProduct}
        />
      </GridItem>
    </>
  );
};
