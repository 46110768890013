import {
  Table,
  Tbody,
  Tr,
  Thead,
  Th,
  Divider,
  Td,
  Box,
  Flex,
} from '@chakra-ui/react';

import { moneyMask } from 'helpers/format/fieldsMasks';

import { CalculoComissao } from 'pages/Vendas/MetasComissoes/Types/validationsForm';

type CalculoComissaoVendedoresProps = {
  listarValoresCalculoComissao: CalculoComissao[];
};

export const CalculoComissaoVendedores = ({
  listarValoresCalculoComissao,
}: CalculoComissaoVendedoresProps) => {
  return (
    <Box
      boxShadow="0px 0px 6px #00000034"
      p="6"
      rounded="md"
      bg="white"
      overflowX="scroll"
      sx={{
        '&::-webkit-scrollbar': {
          height: '0',
          width: '0',
        },
        '& .virtualized_List::-webkit-scrollbar': {
          height: '0',
          width: '0',
        },
      }}
    >
      <Table overflowX="scroll" ml="0" variant="simpleCardVariant">
        <Thead>
          <Tr
            sx={{
              '& > th': {
                paddingBottom: '0',
                paddingTop: '0',
              },
            }}
          >
            <Th w="35%">Vendedor</Th>
            <Th w="10%">
              <Flex justifyContent="right" alignItems="right">
                Percentual
              </Flex>
            </Th>
            <Th w="15%">
              <Flex justifyContent="right" alignItems="right">
                Valor da comissão
              </Flex>
            </Th>
            <Th w="20%">
              <Flex justifyContent="right" alignItems="right">
                Bonificação meta geral
              </Flex>
            </Th>
            <Th w="20%">
              <Flex justifyContent="right" alignItems="right">
                Valor total a receber
              </Flex>
            </Th>
          </Tr>
          <Tr
            sx={{
              '& > th': {
                paddingBottom: '0',
                paddingTop: '5px',
              },
            }}
          >
            <Th pl="0" pr="0" pb="0" colSpan={5}>
              <Divider bg="gray.50" />
            </Th>
          </Tr>
        </Thead>
        <Tbody overflowX="scroll">
          {listarValoresCalculoComissao.map((comissao) => (
            <>
              <Tr
                sx={{
                  '& > td': {
                    paddingBottom: '0',
                    height: '30px',
                  },
                }}
              >
                <Td w="35%">{comissao.nome}</Td>
                <Td w="10%">
                  <Flex justifyContent="right" alignItems="right">
                    {`${comissao.percentual} %`}
                  </Flex>
                </Td>
                <Td w="15%">
                  <Flex justifyContent="right" alignItems="right">
                    {moneyMask(comissao.valorComissao, true)}
                  </Flex>
                </Td>
                <Td w="20%">
                  <Flex justifyContent="right" alignItems="right">
                    {moneyMask(comissao.boninificacao, true)}
                  </Flex>
                </Td>
                <Td w="20%">
                  <Flex justifyContent="right" alignItems="right">
                    {moneyMask(comissao.valorReceber, true)}
                  </Flex>
                </Td>
              </Tr>
              <Tr
                sx={{
                  '& > th': {
                    paddingBottom: '0',
                    paddingTop: '5px',
                  },
                }}
              >
                <Td pb="0" pl="0" pr="0" colSpan={5}>
                  <Divider bg="gray.50" />
                </Td>
              </Tr>
            </>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};
