import React, { memo, CSSProperties } from 'react';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { ModalTitleContainer, ModalTitle, ModalSubtitle } from 'styles';
import ManterFoco from 'components/Geral/ManterFoco';
import { useFullScreenContext } from 'store/FullScreen';
import { Modal } from './styles';

export interface ModalPadraoProps {
  isOpen: boolean;
  handleOnHide: () => void;
  title?: string;
  subtitle?: string;
  isLoading?: boolean;
  maxWidth?: string | number;
  minWidth?: string | number;
  onEntered?: () => void;
  size?: string;
  style?: any;
  styleOfContent?: CSSProperties;
  backdropClassName?: string;
}

interface ModalPadraoComponentProps extends ModalPadraoProps {
  children: React.ReactNode;
}

const ModalPadrao = ({
  children,
  isOpen,
  handleOnHide,
  title,
  subtitle,
  isLoading,
  maxWidth,
  minWidth,
  onEntered,
  style,
  size,
  backdropClassName,
  styleOfContent,
}: ModalPadraoComponentProps) => {
  const { handleFullScreen } = useFullScreenContext();

  return (
    <Modal
      show={isOpen}
      onHide={handleOnHide}
      centered
      maxWidth={maxWidth}
      minWidth={minWidth}
      onEntered={onEntered}
      size={size || 'md'}
      style={style}
      container={handleFullScreen.node}
      backdropClassName={backdropClassName}
    >
      {isLoading && <LoadingPadrao />}
      <Modal.Header closeButton>
        <ModalTitleContainer>
          {title && <ModalTitle>{title}</ModalTitle>}
          {subtitle && <ModalSubtitle>{subtitle}</ModalSubtitle>}
        </ModalTitleContainer>
      </Modal.Header>
      <ManterFoco blockTab={isLoading} style={styleOfContent}>
        {children}
      </ManterFoco>
    </Modal>
  );
};

export default memo(ModalPadrao);
