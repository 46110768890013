import { useCallback, useImperativeHandle, useState, useEffect } from 'react';
import { VStack, Text, Box, Flex } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { IdentificacaoEtapasTray } from 'constants/enum/IdentificacaoEtapasTray';
import { useTrayEtapasContext } from 'store/Tray';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import auth from 'modules/auth';
import { getVendedorTray, getCadastrarVendedorTray } from 'services/tray';

import { CadastrosVendedoresIcon } from 'icons';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import { ModalCadastrarVendedor } from 'components/Modal/ModalCadastrarVendedores';

import { yupResolver, FormData } from './validationForms';
import { Header } from '../Layout/Header';

type OptionsVendedores = {
  label: string;
  value: string;
};

export type OptionResponseVendedores = {
  id: string;
  nome: string;
};

export const Vendedores = () => {
  const [listaVendedores, setListaVendedores] = useState<OptionsVendedores[]>(
    []
  );

  const { ref, setActiveStep, setIsLoading } = useTrayEtapasContext();

  const { id: lojaId } = auth.getLoja();

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues: {
      vendedor: null,
    },
  });

  const { handleSubmit: onSubmit, setValue, reset } = formMethods;

  const handleAvancar = useCallback(() => {
    setActiveStep(IdentificacaoEtapasTray.LOCAL_ESTOQUE);
  }, [setActiveStep]);

  const handleVoltar = useCallback(() => {
    setActiveStep(IdentificacaoEtapasTray.CANAL_VENDAS);
  }, [setActiveStep]);

  const getVendedores = useCallback(async () => {
    const response = await api.get<
      void,
      ResponseApi<OptionResponseVendedores[]>
    >(ConstanteEnderecoWebservice.VENDEDOR_LISTAR_SELECT_POR_LOJA, {
      params: { lojaId },
    });

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item) => toast.warn(item));
      }

      if (response.sucesso && response.dados) {
        const dados = response.dados.map((dataVendedores) => {
          return {
            label: dataVendedores.nome,
            value: dataVendedores.id,
          };
        });
        setListaVendedores(dados);
        return dados;
      }
    }
    return [];
  }, [lojaId]);

  const handleAbrirModalCadastrarVendedor = async () => {
    const dadosVendedor = await ModalCadastrarVendedor();

    if (dadosVendedor) {
      const obterVendedor = await getVendedores();

      const newVendedor = obterVendedor.find(
        (vendedor) => vendedor.label === dadosVendedor.vendedor.nome
      );

      if (newVendedor) {
        setValue('vendedor', newVendedor.value);
      }
    }
  };

  const getObterVendedor = useCallback(async () => {
    setIsLoading(true);
    await getVendedores();

    const response = await getVendedorTray();

    if (response !== null) {
      reset({
        vendedor: response,
      });
    }

    setIsLoading(false);
  }, [setIsLoading, reset, getVendedores]);

  const handleSubmit = onSubmit(async (data) => {
    setIsLoading(true);

    const response = await getCadastrarVendedorTray({
      vendedor: data.vendedor || '',
    });

    if (response !== null) {
      if (response === true) {
        handleAvancar();
      }
    }

    setIsLoading(false);
  });

  useImperativeHandle(ref, () => ({
    handleAvancar: handleSubmit,
    handleVoltar,
  }));

  useEffect(() => {
    getObterVendedor();
  }, [getObterVendedor]);

  return (
    <>
      <Header
        title="Vendedor"
        handleVoltar={handleVoltar}
        icon={CadastrosVendedoresIcon}
      />
      <FormProvider {...formMethods}>
        <VStack
          pl={['10px', '10px', '20px']}
          pr={['10px', '10px', '20px']}
          fontSize="14px"
          w="full"
          alignItems="left"
          spacing="24px"
        >
          <Text
            mb="4px"
            color="primary.50"
            textAlign="left"
            letterSpacing="0px"
          >
            Informe um vendedor para vincular com as vendas online. Todas as
            vendas integradas ao sistema serão contabilizadas para o nome
            selecionado. Se preferir, cadastre um novo vendedor exclusivo para
            as vendas da integração.
          </Text>

          <Box w="full">
            <SelectPadrao
              id="vendedor"
              name="vendedor"
              label="Vendedor"
              asControlledByObject={false}
              colSpan={12}
              options={listaVendedores}
              placeholder="Selecione o vendedor"
            />
            <Flex mt="8px" w="full" justifyContent="flex-end">
              <Text
                onClick={handleAbrirModalCadastrarVendedor}
                textDecoration="underline"
                cursor="pointer"
                color="blue.700"
              >
                Cadastrar novo vendedor
              </Text>
            </Flex>
          </Box>
        </VStack>
      </FormProvider>
    </>
  );
};
