import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { TipoValor } from 'constants/enum/tipoValor';
import { MeioPagamento } from 'constants/enum/fiscal/meioPagamento';

export type Cliente = {
  id: string;
  nome: string;
  tabelaPrecoId: string;
  tabelaPrecoNome: string;
  regraLimiteCredito: number;
  padraoSistema: boolean;
  cpfCnpj?: string;
  endereco?: string;
};

export interface OperacaoTotais {
  valorTotal: number;
  valorTotalItensComDesconto: number;
  valorTotalItensSemDesconto?: number;
  valorTotalDescontoItem?: number;
  valorTotalDescontoAdicional?: number;
  valorDescontoAdicional?: number;
  tipoDescontoAdicional?: TipoValor;
  valorTotalAcrescimo?: number;
  valorTotalOutrasDespesas?: number;
  valorTotalFrete?: number;
}

export interface OperacaoMovimentacaoFinanceira {
  valor: number;
  troco: number;
  dataVencimento: Date;
  descricaoFormaPagto: string;
  descricaoParcelas: string;
  identificacaoAgrupamento: string;
  temParcelas: boolean;
  regraMeioPagamento: MeioPagamento;
  acrescimo: number;
}

interface OperacaoObter {
  vendedorOpcaoSelect: { id: string; nome: string };
  clienteFornecedorOpcaoSelect: Cliente;
  tabelaPrecoOpcaoSelect: { id: string; nome: string };
  observacao: string;
  numeroOperacao: number;
  totais: OperacaoTotais;
  identificacaoTipoOperacao: number;
  saldoVoucher: number;
  movimentacoesFinanceiras: OperacaoMovimentacaoFinanceira[];
}

const obterOperacaoComPagamentos = async (
  id: string
): Promise<OperacaoObter | undefined> => {
  const response = await api.get<void, ResponseApi<OperacaoObter>>(
    ConstanteEnderecoWebservice.PEDIDOORCAMENTOVENDA_OBTER_COM_PAGAMENTOS,
    { params: { id } }
  );

  if (response?.avisos) {
    response.avisos.map((item: string) => toast.warning(item));
  }

  if (response?.sucesso) {
    return {
      ...response.dados,
      movimentacoesFinanceiras: response.dados.movimentacoesFinanceiras.map(
        (movimentacao) => ({
          ...movimentacao,
          dataVencimento: new Date(movimentacao.dataVencimento),
        })
      ),
    };
  }

  return undefined;
};

export default obterOperacaoComPagamentos;
