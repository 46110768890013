import React from 'react';
import { Flex, Button, FlexProps } from '@chakra-ui/react';

import { useZoopContext } from 'store/Zoop/ZoopContext';

export const Footer = ({ ...rest }: FlexProps) => {
  const { ref } = useZoopContext();

  const handleCancelar = () => {
    if (ref?.current?.handleCancelar) {
      ref?.current?.handleCancelar();
    }
  };

  const handleSalvar = () => {
    if (ref?.current?.handleSalvar) {
      ref?.current?.handleSalvar();
    }
  };

  return (
    <Flex
      w={['full', 'full', 'full']}
      justifyContent="center"
      alignItems="center"
      {...rest}
    >
      <Button
        variant="outlineDefault"
        mr="24px"
        borderRadius="16px"
        onClick={handleCancelar}
        colorScheme="gray"
        w="96px"
      >
        Cancelar
      </Button>
      <Button
        borderRadius="16px"
        variant="solid"
        onClick={handleSalvar}
        colorScheme="aquamarine"
        w="120px"
      >
        Salvar
      </Button>
    </Flex>
  );
};
