import { useState, Fragment, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import {
  Box,
  Text,
  Stack,
  Button,
  ModalContent,
  ModalHeader,
  ModalBody,
  Tr,
  Td,
  ModalFooter,
  Divider,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';

import { PagedTable } from 'components/update/Table/PagedTable';
import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import { PaginationData } from 'components/update/Pagination';
import { formatDate } from 'helpers/format/formatStringDate';

type ListaManifesto = {
  id: string;
  emissor: string;
  numero: string;
  dataEmissao: Date;
  valor: number;
};

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  casasDecimais: { casasDecimaisValor: number };
};

export function ModalListaManifesto({
  isOpen,
  onClose,
  casasDecimais,
}: ModalProps) {
  const history = useHistory();

  const [activeIndex, setActiveIndex] = useState<number | undefined>();

  const [
    isListaDeManifestoLoading,
    setIsListaDeManifestoLoading,
  ] = useState<boolean>(true);
  const [listaDeManifesto, setObterListaManifesto] = useState<ListaManifesto[]>(
    []
  );
  const [itemsTotalCount, setItemsTotalCount] = useState(0);

  async function handleEnvioDocumentoFiscalId() {
    if (activeIndex || activeIndex === 0) {
      const { id } = listaDeManifesto[activeIndex];

      setIsListaDeManifestoLoading(true);

      const response = await api.get<void, ResponseApi<string>>(
        ConstanteEnderecoWebservice.CADASTRAR_ENTRADA_MANIFESTO,
        { params: { documentoFiscalManifestoId: id } }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response.sucesso && response.dados) {
          history.push(
            SubstituirParametroRota(
              ConstanteRotas.ENTRADA_MERCADORIA_ENTRADA_IMPORTACAO_CONTINUAR,
              'id',
              response.dados
            )
          );

          onClose();
        }
      }

      setIsListaDeManifestoLoading(false);
    }
  }

  const loadColumnsData = useCallback(
    async (paginationData: PaginationData) => {
      setIsListaDeManifestoLoading(true);

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<ListaManifesto>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.ENTRADA_DE_PRODUTO_LISTAR_MANIFESTO,
          paginationData
        )
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response.sucesso && response.dados) {
          setActiveIndex(undefined);

          setObterListaManifesto(response.dados.registros || []);

          setItemsTotalCount(response.dados.total || 0);
        }
      }

      setIsListaDeManifestoLoading(false);
    },
    []
  );

  return (
    <ModalPadraoChakra size="6xl" isOpen={isOpen} onClose={onClose}>
      <ModalContent px="2" py="6" mx="6">
        <ModalHeader fontSize="sm" py="0">
          <Text color="black" mb="2">
            Selecione uma nota fiscal e clique em avançar para iniciar o
            processo.
          </Text>
        </ModalHeader>
        <ModalBody>
          <Box
            pt="4"
            pb="4"
            px="6"
            bg="gray.50"
            borderRadius="lg"
            mt="1"
            border="1px"
            borderColor="gray.100"
            position="relative"
          >
            <PagedTable
              variant="simple-card"
              bg="transparent"
              boxShadow="none"
              size="sm"
              paginationHasDivider={false}
              isLoading={isListaDeManifestoLoading}
              sx={{
                '& tr > th': { borderBottom: 'none' },
                '& td:only-child': { bg: 'white' },
              }}
              loadColumnsData={loadColumnsData}
              itemsTotalCount={itemsTotalCount}
              defaultKeyOrdered="dataEmissao"
              defaultOrderDirection="desc"
              tableHeaders={[
                {
                  key: 'emissor',
                  content: 'Emissor',
                  isOrderable: false,
                },
                {
                  key: 'numeroNota',
                  content: 'Número da Nota',
                  isOrderable: false,
                },
                {
                  key: 'dataEmissao',
                  content: 'Data de emissão',
                },
                {
                  key: 'valor',
                  content: 'Valor',
                  isOrderable: false,
                  isNumeric: true,
                },
              ]}
              renderTableRows={
                listaDeManifesto &&
                listaDeManifesto.length > 0 &&
                listaDeManifesto.map((listaManifesto, index) => (
                  <Fragment key={listaManifesto.id}>
                    <Tr
                      sx={{
                        '& > td': {
                          bg: activeIndex === index ? 'secondary.300' : 'white',
                          lineHeight: '8',
                          fontWeight: 'bold',
                        },
                      }}
                      _hover={{
                        '& > td': { position: 'relative' },
                        '& > td:before':
                          activeIndex !== index
                            ? {
                                position: 'absolute',
                                content: '""',
                                width: 'full',
                                height: 'full',
                                top: 0,
                                left: 0,

                                borderTop: '1px',
                                borderBottom: '1px',
                                borderColor: 'primary.50',
                              }
                            : '',
                        '& > td:first-child:before': {
                          borderLeft: '1px',
                          borderLeftRadius: 'md',
                        },
                        '& > td:last-child:before': {
                          borderRightRadius: 'md',
                          borderRight: '1px',
                        },
                      }}
                      onClick={() =>
                        setActiveIndex(
                          activeIndex === index ? undefined : index
                        )
                      }
                      cursor="pointer"
                    >
                      <Td>{listaManifesto.emissor}</Td>
                      <Td>{listaManifesto.numero}</Td>
                      <Td>{formatDate(listaManifesto.dataEmissao)}</Td>
                      <Td isNumeric>
                        {`R$ ${listaManifesto.valor.toLocaleString('pt-BR', {
                          minimumFractionDigits:
                            casasDecimais.casasDecimaisValor,
                          maximumFractionDigits:
                            casasDecimais.casasDecimaisValor,
                        })}`}
                      </Td>
                    </Tr>
                    <Box
                      h={index + 1 === listaDeManifesto.length ? '' : '2'}
                      mb={index + 1 === listaDeManifesto.length ? '4' : ''}
                      zIndex="hide"
                    />
                  </Fragment>
                ))
              }
            />
          </Box>
        </ModalBody>
        <Box px="6" mt="2">
          <Divider mt="4" color="gray.200" />
        </Box>
        <ModalFooter pb="0" pt="1">
          <Stack direction="row" spacing="6" mt="5">
            <Button
              variant="outline"
              borderRadius="md"
              w="full"
              maxW={{ base: 'full', md: '100px' }}
              onClick={onClose}
            >
              Voltar
            </Button>

            <Button
              colorScheme="primary"
              borderRadius="md"
              w="full"
              maxW={{ base: 'full', md: '196px' }}
              onClick={handleEnvioDocumentoFiscalId}
              isDisabled={!activeIndex && activeIndex !== 0}
            >
              <Text mx="12">Avançar</Text>
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </ModalPadraoChakra>
  );
}
