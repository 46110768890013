import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { sistemaApiKey } from 'services/sistemaApiKey';
import ConstanteRotas from 'constants/rotas';
import ConstanteFuncionalidades from 'constants/permissoes';
import auth from 'modules/auth';
import { ResponseApi } from 'services/api';

import { ModalRemoverDadosIntegracao } from 'components/update/Modal/ModalRemoverDadosIntegracao';

export type DadosFrenteCaixaProps = {
  id: string;
  dataAtivacao: Date;
  ativo: boolean;
  sincronizacaoHabilitada: boolean;
};

export const useFrenteCaixaData = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [dataAtivacaoFrenteCaixa, setDataAtivacaoFrenteCaixa] = useState<Date>(
    new Date()
  );

  const possuiPermissaoExcluirIntegracao = auth.possuiPermissao(
    ConstanteFuncionalidades.FRENTE_CAIXA_EXCLUIR
  ).permitido;

  const listaItensInfo = [
    'Para acessar o painel de configurações da integração, clique em: Integrações > Frente de Caixa',
    'Para outras dúvidas ou problemas, acesse nossa central de ajuda ou entre em contato com nossa equipe técnica.',
  ];

  const obterFrenteCaixa = useCallback(async () => {
    setIsLoading(true);
    const response = await sistemaApiKey().get<
      void,
      ResponseApi<DadosFrenteCaixaProps>
    >(ConstanteEnderecoWebservice.FRENTE_CAIXA_OBTER_INTEGRACAO);

    if (response) {
      if (response.sucesso && response.dados) {
        if (response.dados.ativo) {
          setDataAtivacaoFrenteCaixa(response.dados.dataAtivacao);
        } else {
          setIsLoading(false);

          history.push(ConstanteRotas.FRENTE_CAIXA_COMERCIAL);
        }
      }
    }
    setIsLoading(false);
  }, [history]);

  const excluirIntegracao = async () => {
    if (!possuiPermissaoExcluirIntegracao) {
      toast.warn(
        'Você não tem permissão para realizar essa ação. Consulte o administrador da conta.'
      );
      return;
    }

    ModalRemoverDadosIntegracao({
      callback: async () => {
        setIsLoading(true);

        const response = await sistemaApiKey().post<void, ResponseApi>(
          ConstanteEnderecoWebservice.FRENTE_CAIXA_DESISTIR_INTEGRACAO
        );

        if (response) {
          if (response.avisos) {
            response.avisos.forEach((aviso: string) => toast.warning(aviso));
          }
          if (response.sucesso) {
            history.push(ConstanteRotas.LOJA_APLICATIVOS);
          }
        }

        setIsLoading(false);
      },
    });
  };

  useEffect(() => {
    obterFrenteCaixa();
  }, [obterFrenteCaixa]);

  return {
    listaItensInfo,
    isLoading,
    dataAtivacaoFrenteCaixa,
    excluirIntegracao,
  };
};
