import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { useSteps } from 'chakra-ui-steps';
import { Prompt, useHistory } from 'react-router-dom';

import TipoImportacaoEnum from 'constants/enum/tipoImportacao';
import { getOptionByEnum } from 'helpers/format/getOptionsByEnum';
import ConstanteRotas from 'constants/rotas';
import isPrenvent from 'helpers/layout/isPrenvent';

interface ImportarContextProps {
  activeStep: number;
  nextStep: () => void;
  tipoImportacao: number;
  nomeTipoImportacao: string;
  handleCancelar: () => void;
  handleFinalizar: () => void;
  arquivo?: File;
  setArquivo: Dispatch<SetStateAction<File | undefined>>;
}

export const ImportarContext = createContext<ImportarContextProps>(
  {} as ImportarContextProps
);

interface ImportarProviderProps {
  children: React.ReactNode;
  tipoImportacao: number;
}

export function ImportarProvider({
  children,
  tipoImportacao,
}: ImportarProviderProps): JSX.Element {
  const history = useHistory();
  const { nextStep, activeStep } = useSteps({
    initialStep: 0,
  });

  const [arquivo, setArquivo] = useState<File>();

  const isPrevented = activeStep !== 2 && !!arquivo;

  isPrenvent(isPrevented);

  const nomeTipoImportacao =
    getOptionByEnum(TipoImportacaoEnum, tipoImportacao)?.label || '';

  function pushRotaListagem() {
    if (tipoImportacao === TipoImportacaoEnum.Clientes) {
      history.push(ConstanteRotas.IMPORTAR_CLIENTES);
    } else if (tipoImportacao === TipoImportacaoEnum.Fornecedores) {
      history.push(ConstanteRotas.IMPORTAR_FORNECEDORES);
    } else if (tipoImportacao === TipoImportacaoEnum.Produtos) {
      history.push(ConstanteRotas.IMPORTAR_PRODUTOS);
    }
  }

  function handleCancelar() {
    pushRotaListagem();
  }

  function handleFinalizar() {
    pushRotaListagem();
  }

  return (
    <ImportarContext.Provider
      value={{
        activeStep,
        nextStep,
        tipoImportacao,
        nomeTipoImportacao,
        handleCancelar,
        arquivo,
        setArquivo,
        handleFinalizar,
      }}
    >
      <Prompt when={isPrevented} message="" />
      {children}
    </ImportarContext.Provider>
  );
}

export function useImportarContext(): ImportarContextProps {
  const context = useContext(ImportarContext);

  if (!context)
    throw new Error(
      'useImportarContext must be used within a ImportarProvider.'
    );

  return context;
}
