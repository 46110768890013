export const TipoCompetenciaEnum = {
  MES_VENCIMENTO: 1,
  MES_ANTERIOR_VENCIMENTO: 2,
  MES_LANCAMENTO: 3,

  properties: {
    1: {
      name: 'No mês do vencimento',
      value: 1,
    },
    2: {
      name: 'No mês anterior ao vencimento',
      value: 2,
    },
    3: {
      name: 'No mesmo mês do lançamento',
      value: 3,
    },
  },
};
