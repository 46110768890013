import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';

import { getCanalVendasTray, getObterEtapaTray } from 'services/tray';
import { IdentificacaoEtapasTray } from 'constants/enum/IdentificacaoEtapasTray';
import ConstanteRotas, { ConstanteRotasAlternativas } from 'constants/rotas';
import enumMesesDoAno from 'constants/enum/enumMesesDoAno';
import auth from 'modules/auth';
import enumReferenciaServicoStargate from 'constants/enum/referenciaServicoStargate';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { enumIdentificacaoIntegracao } from 'constants/enum/enumIdentificacaoIntegracao';
import { enumTabsConfiguracaoTray } from 'constants/enum/enumTabsConfiguracaoTray';

import { Configuracoes } from './types';

type TrayPainelControleProps = {
  nomeCanalVenda: string;
  dadosTray: DadosTrayProps;
  getTray: () => Promise<void>;
  setIsLoading: (value: boolean) => void;
  isLoading: boolean;
  getTotalizador: () => Promise<Totalizadores | null>;
  mesAtual?: {
    label: string;
    value: number;
  };
  isLoadingTotalizadores: boolean;
  avisoProcessoEmAndamento: {
    textoAviso: string;
    exibirAviso: boolean;
    exibirNaTab: number | null;
  };
};

export type DadosTrayProps = {
  id: string;
  dataAtivacao: Date;
  ativo: boolean;
  sincronizacaoHabilitada: boolean;
  configuracoes: string;
};

type TrayPainelControleProviderProps = {
  children: ReactNode;
};

export const TrayPainelControleContext = createContext(
  {} as TrayPainelControleProps
);

type Totalizadores = {
  descricaoProdutoMaisVendido: string;
  faturamento: number;
  ticketMedio: number;
  quantidadeVendas: number;
};

export const TrayPainelControleProvider = ({
  children,
}: TrayPainelControleProviderProps) => {
  const [nomeCanalVenda, setNomeCanalVenda] = useState('');
  const [dadosTray, setDadosTray] = useState<DadosTrayProps>({
    sincronizacaoHabilitada: true,
  } as DadosTrayProps);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTotalizadores, setIsLoadingTotalizadores] = useState(false);
  const [avisoProcessoEmAndamento, setAvisoProcessoEmAndamento] = useState<{
    textoAviso: string;
    exibirAviso: boolean;
    exibirNaTab: number | null;
  }>({
    textoAviso: '',
    exibirAviso: false,
    exibirNaTab: null,
  });

  const valueMonthAtual = new Date().getMonth();

  const checarAvisosProcessosEmAndamento = useCallback(
    (dados: DadosTrayProps) => {
      if (dados && dados.configuracoes) {
        try {
          const configuracoesValidas: Configuracoes = JSON.parse(
            dados.configuracoes.replace(/\\/g, '')
          );
          setAvisoProcessoEmAndamento({
            textoAviso: configuracoesValidas.AtualizacaoTabelaPreco
              ? ' Atualizando a tabela de preços. Enviaremos uma notificação assim que o processo estiver concluído.'
              : '',
            exibirAviso: configuracoesValidas.AtualizacaoTabelaPreco,
            exibirNaTab: configuracoesValidas.AtualizacaoTabelaPreco
              ? enumTabsConfiguracaoTray.TABELA_PRECO
              : null,
          });
          return;
        } catch (error) {
          setAvisoProcessoEmAndamento({
            textoAviso: '',
            exibirAviso: false,
            exibirNaTab: null,
          });
        }
      } else {
        setAvisoProcessoEmAndamento({
          textoAviso: '',
          exibirAviso: false,
          exibirNaTab: null,
        });
      }
    },
    []
  );

  const mesAtual = enumMesesDoAno.properties.find(
    (mes) => mes?.value === Number(valueMonthAtual + 1 || 0)
  );

  const history = useHistory();

  const possuiPermissaoTray = auth.possuiServico(
    enumReferenciaServicoStargate.INTEGRACAO_TRAY
  ).permitido;

  const getCanalVendas = useCallback(async () => {
    const response = await getCanalVendasTray<{ nomeCanalVenda: string }>();

    if (response !== null) {
      setNomeCanalVenda(response.nomeCanalVenda);
    }
  }, []);

  const getEtapaTray = useCallback(async () => {
    if (!possuiPermissaoTray) {
      history.push(ConstanteRotas.INTEGRACAO_TRAY_TELA_COMERCIAL);
      return;
    }
    const response = await getObterEtapaTray();

    if (response !== null) {
      if (response !== IdentificacaoEtapasTray.ETAPA_FINALIZADA) {
        history.push(ConstanteRotasAlternativas.TRAY_ETAPAS);
      }
    }
  }, [history, possuiPermissaoTray]);

  const getTray = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get<void, ResponseApi<DadosTrayProps>>(
      ConstanteEnderecoWebservice.INTEGRACAO_TRAY_OBTER
    );

    if (response) {
      if (response.sucesso) {
        checarAvisosProcessosEmAndamento(response.dados);
        setDadosTray(response.dados);
      }
    }
    setIsLoading(false);
  }, [checarAvisosProcessosEmAndamento]);

  const getTotalizador = useCallback(async () => {
    setIsLoadingTotalizadores(true);
    const response = await api.get<void, ResponseApi<Totalizadores>>(
      ConstanteEnderecoWebservice.INTEGRACAO_TRAY_TOTAL_VENDAS,
      {
        params: {
          identificacaoIntegracao: enumIdentificacaoIntegracao.TRAY,
          mes: mesAtual?.value,
        },
      }
    );

    if (response) {
      if (response.sucesso) {
        setIsLoadingTotalizadores(false);
        return response.dados;
      }
    }
    setIsLoadingTotalizadores(false);
    return null;
  }, [mesAtual?.value, setIsLoadingTotalizadores]);

  useEffect(() => {
    getCanalVendas();
  }, [getCanalVendas]);

  useEffect(() => {
    getEtapaTray();
  }, [getEtapaTray]);

  useEffect(() => {
    getTray();
  }, [getTray]);

  return (
    <TrayPainelControleContext.Provider
      value={{
        nomeCanalVenda,
        dadosTray,
        getTray,
        setIsLoading,
        isLoading,
        getTotalizador,
        isLoadingTotalizadores,
        mesAtual,
        avisoProcessoEmAndamento,
      }}
    >
      {children}
    </TrayPainelControleContext.Provider>
  );
};

export function useTrayPainelControleContext(): TrayPainelControleProps {
  const context = useContext(TrayPainelControleContext);

  if (!context)
    throw new Error(
      'useTrayPainelControleContext must be used within a TrayPainelControleProvider.'
    );

  return context;
}
