import styled, { css } from 'styled-components';

interface SwitchContainerInterface {
  value: boolean;
  readonly?: boolean;
}

export const SwitchContainer = styled.div.attrs(
  ({ readonly }: SwitchContainerInterface) => ({
    className: readonly ? 'disabled' : '',
  })
)`
  position: relative;

  height: 20px;
  width: 40px;

  border-radius: 100px;

  background: var(--sti-ck-colors-blue-500);

  z-index: 1;

  cursor: ${({ readonly }: SwitchContainerInterface) =>
    readonly ? 'not-allowed' : 'pointer'};

  opacity: ${({ readonly }: SwitchContainerInterface) => (readonly ? 0.5 : 1)};

  &:before {
    position: absolute;
    content: '';
    border-radius: 100px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    transition: opacity 0.1s linear;
    opacity: 0;
  }

  ${({ value }: SwitchContainerInterface) =>
    !value &&
    css`
      background: var(--sti-ck-colors-gray-100);

      &:before {
        opacity: 1;
      }
    `}

  > span {
    transition: transform 0.1s linear;

    transform: ${({ value }) => `translateX(${value ? '21px' : '1px'})`};
  }
`;

export const SwitchIcon = styled.span`
  position: absolute;

  top: 1px;

  height: 18px;
  width: 18px;
  background-color: var(--white);
  border-radius: 100px;
  border: 1px solid var(--sti-ck-colors-gray-50);
`;

export const HiddenCheckbox = styled.input.attrs(() => ({
  type: 'checkbox',
}))`
  display: none;
  opacity: 0;
`;
