import React, { useState } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useFieldArray, UseFormRegister } from 'react-hook-form';

import AccordionPadrao from 'components/Accordion/AccordionPadrao';
import ModalContatosAdicionais from '../ModalContatosAdicionais';
import {
  ButtonComOpcoesContato,
  ColContato,
  Container,
  SpanEmail,
  SpanNome,
} from './styles';

interface IContatoAdicional {
  id: string;
  Nome: string;
  Telefone: string;
  WhatsApp: boolean;
  Email: string;
}

interface IFieldArrayType {
  contatosAdicionais: IContatoAdicional[];
}

interface ContatosAdicionaisFormInterface {
  register: UseFormRegister<Record<string, any>>;
  readonly?: boolean;
  control: any;
  setValue: any;
  getValue: any;
  cadastroPdv?: boolean;
}

const ContatosAdicionais = ({
  register,
  readonly,
  control,
  setValue,
  getValue,
  cadastroPdv,
}: ContatosAdicionaisFormInterface) => {
  const { fields, append, remove } = useFieldArray<
    IFieldArrayType,
    'contatosAdicionais',
    'id'
  >({
    control,
    name: 'contatosAdicionais',
  });

  const { t } = useTranslation();

  const [
    ContatosAdicionaisModalIsOpen,
    setContatosAdicionaisModalIsOpen,
  ] = useState(false);

  const [indexEditContato, setIndexEditContato] = useState(-1);
  const [isWhats, setIsWhats] = useState(false);
  const adicionarNovo = () => {
    setIndexEditContato(-1);
    setContatosAdicionaisModalIsOpen(true);
  };

  const handleSubmit = () => {
    const data = getValue();

    if (
      (data.ContatoNome === '' ||
        data.ContatoNome === null ||
        data.ContatoNome === undefined) &&
      (data.ContatoTelefone === '' ||
        data.ContatoTelefone === null ||
        data.ContatoTelefone === undefined) &&
      (data.ContatoEmail === '' ||
        data.ContatoEmail === null ||
        data.ContatoEmail === undefined)
    ) {
      setContatosAdicionaisModalIsOpen(false);
      return;
    }

    const ContatoAdicionado = {
      id: data.ContatoIndex,
      Nome: data.ContatoNome,
      Telefone: data.ContatoTelefone,
      Email: data.ContatoEmail,
      WhatsApp: data.ContatoTelefoneWhats || false,
    };

    append(ContatoAdicionado);
    setContatosAdicionaisModalIsOpen(false);
  };

  return (
    <>
      <ModalContatosAdicionais
        show={ContatosAdicionaisModalIsOpen}
        onHide={() => {
          setContatosAdicionaisModalIsOpen(false);
        }}
        onSubmit={handleSubmit}
        register={register}
        control={control}
        getValues={getValue}
        setValues={setValue}
        readonly={readonly}
        indexContato={indexEditContato}
        isWhats={isWhats}
        cadastroPdv={cadastroPdv}
      />
      <AccordionPadrao title="Contatos adicionais" defaultOpen>
        <Container>
          <Form.Row
            style={{
              paddingTop: '0px',
              paddingBottom: '0px',
            }}
          >
            {!readonly && (
              <Col xl={12} style={{ paddingLeft: '0' }}>
                <Button
                  variant="link"
                  id="adicionar-contato-adicional"
                  onClick={adicionarNovo}
                  style={{
                    padding: '0px',
                    color: 'var(--sti-ck-colors-blue-500)',
                    letterSpacing: '0',
                    fontWeight: 600,
                    fontSize: '12px',
                  }}
                >
                  Adicionar novo contato
                </Button>
              </Col>
            )}
          </Form.Row>

          <div id="contatos-adicionais">
            {fields.map((field, index) => (
              <Form.Row>
                {index >= 0 && (
                  <>
                    {/* eslint-disable-next-line react/no-array-index-key */}
                    <Col xl={12} key={index} style={{ padding: '3px 0' }}>
                      <div
                        style={{
                          float: 'right',
                          padding: '25px 20px 20px 20px',
                        }}
                      >
                        {!readonly && (
                          <ButtonComOpcoesContato
                            id="mostrarMaisContatoAdicional"
                            dropdownItems={[
                              {
                                title: t('Editar'),
                                onClick: () => {
                                  setIsWhats(field.WhatsApp);
                                  setIndexEditContato(index);
                                  setContatosAdicionaisModalIsOpen(true);
                                },
                              },
                              {
                                title: t('Remover'),
                                onClick: () => {
                                  remove(index);
                                },
                              },
                            ]}
                          />
                        )}
                      </div>
                      <ColContato>
                        <SpanNome>
                          {(field.Nome && <span>{field.Nome}</span>) || (
                            <span>&nbsp;</span>
                          )}
                        </SpanNome>
                        <SpanEmail>
                          &nbsp;
                          {field.Email && field.Email}
                          {field.Telefone && (
                            <>
                              {field.Email && <span> | </span>}
                              {field.Telefone}
                              {field.WhatsApp && ' (WhatsApp)'}
                            </>
                          )}
                        </SpanEmail>
                      </ColContato>
                    </Col>
                  </>
                )}
              </Form.Row>
            ))}
          </div>
        </Container>
      </AccordionPadrao>
    </>
  );
};

export default ContatosAdicionais;
