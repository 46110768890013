import { useCallback, useEffect, useState } from 'react';
import { Text, VStack, Box } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  ContainerIntegracaoFixo,
  useSmartPosEtapasContext,
} from 'store/SmartPos/SmartPosEtapasContext';
import { IdentificacaoEtapasSmartPos } from 'constants/enum/IdentificacaoEtapasSmartPos';
import OptionType from 'types/optionType';
import {
  getLocalEstoque as getLocalEstoqueResponse,
  cadastrarLocalEstoque,
  getLocalEstoquePadrao,
  getLocalEstoqueSmartPos,
} from 'helpers/data/getLocalEstoque';

import { ConfiguracoesMinhasLojasIcon } from 'icons';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import { ModalCadastrarLocalEstoque } from 'components/Modal/ModalCadastrarLocalEstoque';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { FormData, yupResolver } from './validationForm';
import { FooterSmartPos } from '../Layout/FooterSmartPos';
import { HeaderSmartPos } from '../Layout/Header';

export const LocalEstoque = () => {
  const [localEstoqueOptions, setLocalEstoqueOptions] = useState<OptionType[]>(
    []
  );

  const {
    setActiveStep,
    animacaoLoading,
    setIsLoading,
    isLoading,
  } = useSmartPosEtapasContext();

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
  });

  const { handleSubmit, setValue } = formMethods;

  const handleAvancar = handleSubmit(async ({ localEstoque }) => {
    setIsLoading(true);

    const response = await cadastrarLocalEstoque(localEstoque);

    if (response) {
      setActiveStep(IdentificacaoEtapasSmartPos.FORMA_RECEBIMENTO);
    }

    setIsLoading(false);
  });

  const handleVoltar = () => {
    setActiveStep(IdentificacaoEtapasSmartPos.AUTENTICACAO);
  };

  const getLocalEstoquePadraoSistema = useCallback(
    async (options: OptionType[]) => {
      setIsLoading(true);
      const localEstoqueCadastrado = await getLocalEstoqueSmartPos();
      let valueLocalEstoque;

      if (localEstoqueCadastrado) {
        valueLocalEstoque = localEstoqueCadastrado;
      } else {
        const localEstoqueDefault = await getLocalEstoquePadrao(options);
        valueLocalEstoque = localEstoqueDefault?.value;
      }

      if (valueLocalEstoque) {
        setValue('localEstoque', valueLocalEstoque);
      }

      setIsLoading(false);
    },
    [setIsLoading, setValue]
  );

  const getLocalEstoque = useCallback(async () => {
    setIsLoading(true);
    const newLocalEstoqueOptions = await getLocalEstoqueResponse();

    setLocalEstoqueOptions(newLocalEstoqueOptions);

    setIsLoading(false);

    return newLocalEstoqueOptions;
  }, [setIsLoading]);

  const loadLocalEstoque = useCallback(async () => {
    setIsLoading(true);
    const options = await getLocalEstoque();
    getLocalEstoquePadraoSistema(options);
    setIsLoading(false);
  }, [getLocalEstoque, setIsLoading, getLocalEstoquePadraoSistema]);

  const handleAbrirModalCriarLocalEstoque = async () => {
    const {
      sucesso: newLocalEstoqueCriado,
    } = await ModalCadastrarLocalEstoque();
    if (newLocalEstoqueCriado) {
      setIsLoading(true);
      const optionsLocalEstoque = await getLocalEstoque();
      const newLocalEstoqueSelecionado = optionsLocalEstoque.find(
        (option) => option.label === newLocalEstoqueCriado.nome
      );
      if (newLocalEstoqueSelecionado) {
        setValue('localEstoque', newLocalEstoqueSelecionado.value);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadLocalEstoque();
    animacaoLoading();
  }, [loadLocalEstoque, animacaoLoading]);

  return (
    <FormProvider {...formMethods}>
      {isLoading && <LoadingPadrao />}
      <ContainerIntegracaoFixo>
        <HeaderSmartPos
          title="Local de estoque"
          icon={ConfiguracoesMinhasLojasIcon}
          handleVoltar={handleVoltar}
        />
        <VStack alignItems="center" w="full" gap="24px" px="20px">
          <Box color="primary.50" fontSize="14px" letterSpacing="0px" w="full">
            <Text>
              Se você utiliza apenas um mesmo local de estoque padrão, clique em
              avançar.
            </Text>

            <Text>
              Para quem trabalha com vários locais de estoque, é possível
              realizar o controle separando as vendas realizadas no Smart POS do
              estoque físico da sua loja. Você pode selecionar o local
              alternativo ou criar um novo local específico.
            </Text>
          </Box>

          <Box w="full" h="40px" textAlign="end">
            <SelectPadrao
              placeholder="Selecione um local de estoque"
              label="Local de estoque"
              id="localEstoque"
              name="localEstoque"
              options={localEstoqueOptions}
              colSpan={12}
            />
            <Text
              textDecoration="underline"
              color="blue.700"
              margin="8px 0 0 auto"
              w="fit-content"
              fontSize="sm"
              letterSpacing="0px"
              cursor="pointer"
              onClick={() => handleAbrirModalCriarLocalEstoque()}
            >
              Criar novo local de estoque
            </Text>
          </Box>
        </VStack>
      </ContainerIntegracaoFixo>
      <FooterSmartPos handleAvancar={handleAvancar} />
    </FormProvider>
  );
};
