export const EnumStatusPagamentos = {
  PENDING: 'pending',
  PAID: 'paid',
  CANCELED: 'canceled',
  FAILED: 'failed',
  SUCCEEDED: 'succeeded',
};

export const EnumTipoTransacao = {
  CREDITO_STONE: 'credit',
  DEBITO_STONE: 'debit',
  CARTAO_ZOOP: 1,
  BOLETO: 2,
  PIX: 3,
};
