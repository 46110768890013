import React, { useCallback, useEffect, useState } from 'react';
import {
  Flex,
  Button,
  useMediaQuery,
  VStack,
  Text,
  HStack,
  Icon,
  Box,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { FiAlertCircle } from 'react-icons/fi';
import { FormProvider, useForm } from 'react-hook-form';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteRotasPDV from 'constants/rotasPDV';
import auth from 'modules/auth';
import { useInformacoesGeraisContext } from 'store/PDV/InformacoesGerais';
import { moneyMask } from 'helpers/format/fieldsMasks';
import { formatDateHourMinute } from 'helpers/format/formatStringDate';
import ConstanteFuncionalidades from 'constants/permissoes';

import Layout from 'components/PDV/Layout';
import { NumberInput } from 'components/update/Input/NumberInput';
import Loading from 'components/Layout/Loading/LoadingPadrao';
import { AberturaCaixaIcon } from 'icons';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';

type FormaRecebimento = {
  formaRecebimentoNome: string;
  saldo: number;
  formaRecebimentoId: string;
  novoSaldo: number;
};

type AberturaCaixaProps = {
  saldoCaixa: number;
  usuarioFechamento: string;
  dataFechamento: Date;
  formasRecebimento: FormaRecebimento[];
};

type FormData = {
  saldoAbertura: number;
  formasRecebimento: number[];
};

export const AberturaCaixa = () => {
  const [
    listaAberturaCaixa,
    setListaAberturaCaixa,
  ] = useState<AberturaCaixaProps>();

  const [isLoading, setIsLoading] = useState(false);

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const { mensagemSignalR, infoCaixa } = useInformacoesGeraisContext();
  const history = useHistory<FormData>();

  const formMethods = useForm();

  const { watch, setValue } = formMethods;

  const recebimentoCaixaWatch: number[] = watch('recebimentoCaixa');

  const totalrecebimentoCaixa = (recebimentoCaixaWatch || []).reduce(
    (acc, curr) => acc + curr,
    0
  );

  const existeDiferencaSaldo = useCallback(() => {
    if ((listaAberturaCaixa?.formasRecebimento || []).length > 0) {
      if (totalrecebimentoCaixa !== listaAberturaCaixa?.saldoCaixa) {
        return true;
      }
      return false;
    }
    return false;
  }, [listaAberturaCaixa, totalrecebimentoCaixa])();

  const possuiPermissaoAlterarSaldoAbertura = auth.possuiPermissao(
    ConstanteFuncionalidades.PDV_PERMISSAO_ABERTURA_CAIXA_ALTERAR_SALDO
  ).permitido;

  const abrirCaixa = async () => {
    setIsLoading(true);
    const response = await api.post<void, ResponseApi>(
      ConstanteEnderecoWebservice.CAIXA_MOVIMENTACAO_ABRIR_CAIXA,
      {
        contaFinanceiraId: infoCaixa?.id,
        alteracaoSaldo: existeDiferencaSaldo,
        formasRecebimento: (listaAberturaCaixa?.formasRecebimento || []).map(
          (recebimento, index) => {
            return {
              ...recebimento,
              novoSaldo: recebimentoCaixaWatch[index],
            };
          }
        ),
      }
    );

    if (response?.avisos) {
      response.avisos.forEach((item: string) => toast.warning(item));
      history.push(ConstanteRotasPDV.PDV_HOME);
      window.location.reload();
    }

    if (response?.sucesso) {
      history.push(ConstanteRotasPDV.PDV_HOME);
      if (mensagemSignalR) {
        window.location.reload();
      }
    }
    setIsLoading(false);
  };

  const getAberturaCaixa = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get<void, ResponseApi<AberturaCaixaProps>>(
      `${ConstanteEnderecoWebservice.OBTER_ABERTURA_CAIXA}/${infoCaixa?.id}`
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso) {
        setListaAberturaCaixa(response.dados);
        setValue('saldoAbertura', response.dados.saldoCaixa);
        response.dados.formasRecebimento.forEach((recebimento, index) => {
          setValue(`recebimentoCaixa.${index}`, recebimento.saldo);
        });
      }
    }
    setIsLoading(false);
  }, [infoCaixa, setValue]);

  const handleSubimit = () => {
    if (existeDiferencaSaldo) {
      const valorMaior =
        totalrecebimentoCaixa > (listaAberturaCaixa?.saldoCaixa || 0);
      ModalConfirmacaoExcluir({
        title: `Valor ${valorMaior ? 'MAIOR' : 'MENOR'} que o saldo`,
        text: valorMaior
          ? 'O valor informado para abertura do caixa é maior que o saldo anterior. A diferença entre os valores será debitada da conta cofre.'
          : 'O valor informado para abertura do caixa é menor que o saldo anterior. A diferença entre os valores será transferida para a conta cofre.',
        confirmButtonText: 'Abrir o caixa',
        cancelButtonText: 'Cancelar',
        callback: async (ok: boolean) => {
          if (ok) {
            abrirCaixa();
          }
        },
      });
    } else {
      abrirCaixa();
    }
  };

  useEffect(() => {
    if (!infoCaixa) {
      history.push(ConstanteRotasPDV.PDV_ABRIR_CAIXA);
    }
  }, [history, infoCaixa]);

  useEffect(() => {
    getAberturaCaixa();
  }, [getAberturaCaixa]);

  return (
    <Layout
      containerOverflow="auto"
      bodyOverflow="visible"
      isHeaderVisible={isLargerThan900}
    >
      {isLoading && <Loading />}

      <Flex
        h="100%"
        w="100%"
        alignItems="center"
        justifyContent="center"
        p={{ base: 0, md: 6 }}
        bg="primary.800"
      >
        <VStack
          bg="gray.50"
          borderRadius={{ base: 'none', md: 'md' }}
          w={{ base: 'full', md: '800px' }}
          maxH={{
            base: 'full',
            md: '520px',
          }}
          h={!isLargerThan900 ? 'full' : undefined}
        >
          <FormProvider {...formMethods}>
            <Flex
              py="40px"
              px={{ base: '24px', md: '80px' }}
              alignItems="center"
              pt={{ base: '0px', md: '32px' }}
              w="full"
              mb={isLargerThan900 ? undefined : '10px'}
              bg="primary.100"
              borderTopLeftRadius={{ base: 'none', md: 'md' }}
              borderTopRightRadius={{ base: 'none', md: 'md' }}
              direction={isLargerThan900 ? 'row' : 'column'}
              justifyContent={isLargerThan900 ? 'space-between' : 'center'}
              h={!isLargerThan900 ? '300px' : '250px'}
            >
              <Box color="white">
                <Flex justifyContent="center">
                  <Icon
                    boxSize={isLargerThan900 ? '60px' : '50px'}
                    as={AberturaCaixaIcon}
                  />
                </Flex>
                <Text
                  mt="8px"
                  mb={isLargerThan900 ? undefined : '10px'}
                  fontSize="24px"
                  color="white"
                >
                  {infoCaixa?.nome}
                </Text>
              </Box>
              <VStack>
                <HStack w="full" justifyContent="flex-end">
                  <Text color="white" whiteSpace="nowrap" fontSize="14px">
                    Saldo do caixa
                  </Text>
                  <Box>
                    <NumberInput
                      w="160px"
                      _disabled={{
                        background: 'primary.100',
                        borderColor: 'gray.100',
                        color: 'gray.100',
                        opacity: 1,
                      }}
                      isDisabled
                      leftElementColor="gray.100"
                      id="saldoAbertura"
                      editarFundoLeftElemento
                      bgLeftElement="gray.50"
                      leftElement="R$"
                      scale={2}
                      colSpan={3}
                      name="saldoAbertura"
                    />
                  </Box>
                </HStack>
                {listaAberturaCaixa?.formasRecebimento?.map(
                  (recebimento, index) => (
                    <HStack
                      key={recebimento.formaRecebimentoId}
                      w="full"
                      justifyContent="flex-end"
                    >
                      <Text color="white" whiteSpace="nowrap" fontSize="14px">
                        {recebimento.formaRecebimentoNome}
                      </Text>
                      <Box>
                        <NumberInput
                          w="160px"
                          isDisabled={!possuiPermissaoAlterarSaldoAbertura}
                          defaultValue={recebimento.saldo}
                          _disabled={{
                            background: 'primary.100',
                            borderColor: 'gray.100',
                            color: 'gray.100',
                            opacity: 1,
                          }}
                          name={`recebimentoCaixa.${index}`}
                          leftElementColor={
                            possuiPermissaoAlterarSaldoAbertura
                              ? 'gray.700'
                              : 'gray.100'
                          }
                          editarFundoLeftElemento
                          bgLeftElement="gray.50"
                          leftElement="R$"
                          scale={2}
                          colSpan={3}
                          id={`recebimentoCaixa.${index}`}
                        />
                      </Box>
                    </HStack>
                  )
                )}
              </VStack>
            </Flex>
            <Flex
              h="30px"
              justifyContent="center"
              alignItems="center"
              position="relative"
              w="full"
            >
              <Flex
                h="80px"
                bg={existeDiferencaSaldo ? 'yellow.500' : 'secondary.300'}
                justifyContent="center"
                alignItems="center"
                boxShadow="0px 0px 6px #00000034"
                top={isLargerThan900 ? '-50px' : '-60px'}
                w="90%"
                position="absolute"
              >
                <Flex
                  direction={!isLargerThan900 ? 'column' : 'row'}
                  alignItems="baseline"
                >
                  <Text fontSize="14px" mr="5px">
                    Valor para abertura do caixa:
                  </Text>
                  <Flex
                    w={isLargerThan900 ? undefined : 'full'}
                    justifyContent="center"
                    alignItems="baseline"
                  >
                    <Text fontSize="16px" mr="5px" fontWeight="bold">
                      R$
                    </Text>
                    <Text textAlign="center" fontSize="28px" fontWeight="bold">
                      {moneyMask(totalrecebimentoCaixa || 0, false)}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>

            {existeDiferencaSaldo && (
              <Flex
                h="40px"
                mt="10px"
                justifyContent="center"
                alignItems="center"
                position="relative"
                w="full"
              >
                <Flex
                  bg="yellow.500"
                  h="full"
                  px="3%"
                  justifyContent="left"
                  alignItems="center"
                  boxShadow="0px 0px 6px #00000034"
                  w="90%"
                >
                  <Icon as={FiAlertCircle} boxSize="4" />
                  <Text lineHeight="none" ml="10px">
                    O caixa será aberto com valor diferente do saldo.
                  </Text>
                </Flex>
              </Flex>
            )}

            {listaAberturaCaixa?.usuarioFechamento && (
              <VStack px="5%" width="full">
                <Text w="full" fontSize="14px" textAlign="left" color="black">
                  Última movimentação do caixa:
                </Text>

                <Flex
                  w="full"
                  color="black"
                  h="50px"
                  borderWidth="1px"
                  pt={isLargerThan900 ? undefined : '5px'}
                  direction={isLargerThan900 ? 'row' : 'column'}
                  pl="5%"
                  bg="white"
                  borderRadius="5px"
                  borderColor="gray.200"
                >
                  <HStack pr="10%" alignItems="center">
                    <Text>Caixa fechado em:</Text>
                    <Text>
                      {formatDateHourMinute(
                        listaAberturaCaixa?.dataFechamento || new Date()
                      )}
                    </Text>
                  </HStack>
                  <HStack alignItems="center">
                    <Text>Usuário:</Text>
                    <Text>{listaAberturaCaixa?.usuarioFechamento}</Text>
                  </HStack>
                </Flex>
              </VStack>
            )}

            <Flex w="full" justifyContent="center" pb="20px" pt="10px">
              <HStack spacing={6}>
                <Button
                  variant="outline"
                  size="sm"
                  color="gray.400"
                  onClick={() => {
                    history.push(ConstanteRotasPDV.PDV_ABRIR_CAIXA);
                  }}
                  minW="96px"
                >
                  Voltar
                </Button>

                <Button
                  variant="solid"
                  isLoading={isLoading}
                  isDisabled={isLoading}
                  colorScheme="secondary"
                  size="sm"
                  onClick={handleSubimit}
                  minW="160px"
                >
                  Abrir caixa
                </Button>
              </HStack>
            </Flex>
          </FormProvider>
        </VStack>
      </Flex>
    </Layout>
  );
};
