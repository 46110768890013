import React from 'react';
import { Flex, Box, Fade, ScaleFade } from '@chakra-ui/react';

interface StepButtonProps {
  index: number;
  isActive: boolean;
  colorScheme?: string;
}

export function StepButton({
  index,
  isActive,
  colorScheme = 'primary',
}: StepButtonProps) {
  return (
    <>
      {isActive ? (
        <ScaleFade initialScale={0.5} in={isActive}>
          <Flex
            as="span"
            alignItems="center"
            justifyContent="center"
            h="8"
            w="8"
            borderRadius="50%"
            fontSize="sm"
            color="white"
            bg={`${colorScheme}.500`}
            userSelect="none"
          >
            {index + 1}
          </Flex>
        </ScaleFade>
      ) : (
        <Flex
          as="span"
          alignItems="center"
          justifyContent="center"
          h="8"
          w="8"
          borderRadius="50%"
          fontSize="sm"
          color="white"
          bg="gray.200"
          userSelect="none"
        >
          {index + 1}
        </Flex>
      )}

      <Fade in={isActive}>
        <Box
          position="absolute"
          left="8"
          top="calc(1rem - 3px)"
          w="calc((100% - 2rem) / 2)"
          h="6px"
          borderTopWidth="6px"
          borderTopColor={`${colorScheme}.500`}
        />
      </Fade>
    </>
  );
}
