import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  ModalFooter,
  VStack,
  ModalHeader,
  useMediaQuery,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';

import { ModalProdutosSelecionados } from '../ModalProdutosSelecionados';

type ProdutoSelecionado = {
  produtoId: string;
  listaProdutoCorTamanhoId: string[];
  descricao: string;
};

type ModalEnviarProdutosProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalProps> & {
    amountProdutos: number;
    handleEnviarProdutos: () => void;
    listProdutos: ProdutoSelecionado[];
    reload: () => void;
  };

export const ModalEnviarProdutos = create<ModalEnviarProdutosProps, ModalProps>(
  ({
    onResolve,
    onReject,
    handleEnviarProdutos,
    amountProdutos,
    listProdutos,
    reload,
    ...rest
  }) => {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    const abrirListaProdutoSelecionado = () => {
      onClose();
      ModalProdutosSelecionados({
        handleEnviarProdutos: () => handleEnviarProdutos(),
        listProdutos,
        reload,
      });
    };

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? '3xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
      >
        <ModalContent
          bg="gray.50"
          borderRadius={isLargerThan900 ? 'md' : '0'}
          marginBottom={isLargerThan900 ? '3.75rem' : '0'}
          marginTop={isLargerThan900 ? '3.75rem' : '0'}
          w={isLargerThan900 ? '640px' : 'full'}
          h={isLargerThan900 ? '340px' : 'full'}
        >
          <ModalHeader pt="16px" pb="28px" pl="24px">
            <Text color="primary.50" fontSize="20px">
              {`${amountProdutos} produtos selecionados!`}
            </Text>
          </ModalHeader>

          <ModalBody pl="24px" pr="24px" mb="20px" pt="0" pb="0">
            <VStack
              color="black"
              fontSize="14px"
              w="full"
              alignItems="left"
              spacing="24px"
            >
              <Text mb="4px" textAlign="left" letterSpacing="0px">
                Ao confirmar esta etapa, todos os produtos serão enviados
                automaticamente e publicados na plataforma. Antes de confirmar,
                verifique se o plano contratado na Tray suporta a quantidade de
                produtos selecionados.
              </Text>
              <Text
                color="blue.700"
                textDecoration="underline"
                mb="4px"
                textAlign="left"
                letterSpacing="0px"
                cursor="pointer"
                onClick={abrirListaProdutoSelecionado}
              >
                Clique aqui para ver a lista de produtos selecionados
              </Text>
            </VStack>
          </ModalBody>
          <ModalFooter flexDirection="column" mb="10px">
            <Flex
              w="full"
              h="full"
              justifyContent="center"
              alignItems="baseline"
            >
              <Button
                variant="outlineDefault"
                colorScheme="gray"
                lineHeight="0"
                borderRadius="20px"
                h="40px"
                mr="24px"
                width="120px"
                onClick={() => onClose()}
              >
                Cancelar
              </Button>
              <Button
                color="white"
                variant="solid"
                colorScheme="purple"
                lineHeight="0"
                borderRadius="20px"
                h="40px"
                width="200px"
                onClick={() => {
                  onClose();
                  handleEnviarProdutos();
                }}
              >
                Enviar produtos
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
