import { Box, Flex, Text, Divider } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { ListagemSituacao } from '../components/ListagemSituacao';
import { useSituacaoConfig } from '../hooks';

export const Situacao = () => {
  const {
    formMethods,
    isLoading,
    listSituacoesPedido,
    listSituacoesVenda,
    listSituacoesCancelamento,
    abrirModalAlterarSituacao,
  } = useSituacaoConfig();

  return (
    <FormProvider {...formMethods}>
      {isLoading && <LoadingPadrao />}
      <Box pl="5px" pr="40px" w="full" minW="400px">
        <Flex gap="21px" flexDir="column">
          <Text
            id="textoInicial"
            fontSize="14px"
            color="gray.700"
            whiteSpace="pre-wrap"
          >
            {
              'O sistema apenas recebe a informação da Tray e aplica o fluxo de operações.\nNão é possível retroceder o status de uma “venda” para “pedido”. Se for necessário, será preciso cancelar a venda.'
            }
          </Text>
          <Divider borderColor="gray.200" mb="13px" />
        </Flex>
        <ListagemSituacao
          situacoes={listSituacoesPedido}
          titulo="O sistema entende como um"
          tipo="PEDIDO"
          abrirModalAlterarSituacao={abrirModalAlterarSituacao}
        />
        <ListagemSituacao
          situacoes={listSituacoesVenda}
          titulo="O sistema entende como uma"
          tipo="VENDA"
          abrirModalAlterarSituacao={abrirModalAlterarSituacao}
        />
        <ListagemSituacao
          situacoes={listSituacoesCancelamento}
          titulo="O sistema entende como um"
          tipo="CANCELAMENTO"
          abrirModalAlterarSituacao={abrirModalAlterarSituacao}
        />
      </Box>
    </FormProvider>
  );
};
