import { useState, useEffect } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  ModalFooter,
  Box,
  ModalHeader,
  useMediaQuery,
  ModalCloseButton,
  Divider,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { create, InstanceProps } from 'react-modal-promise';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import formatData from 'helpers/format/formatData';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

type ModalDetalheEventoProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalProps> & {
    idConta: string;
  };

type HistoricoEventoProps = {
  usuarioNome: string;
  descricao: string;
  dataHora: Date;
};

export const ModalDetalheEvento = create<ModalDetalheEventoProps>(
  ({ onResolve, onReject, idConta, ...rest }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [listHistoricoEvento, setListHistoricoEvento] = useState<
      HistoricoEventoProps[]
    >([]);

    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    useEffect(() => {
      async function getHistoricoEvento() {
        setIsLoading(true);

        const response = await api.get<
          void,
          ResponseApi<HistoricoEventoProps[]>
        >(`${ConstanteEnderecoWebservice.HISTORICO_EVENTO}${idConta}`);

        if (response) {
          if (response.avisos) {
            response.avisos.forEach((aviso) => toast.warning(aviso));
          }

          if (response.sucesso && response.dados) {
            setListHistoricoEvento(response.dados);
          }
        }

        setIsLoading(false);
      }
      getHistoricoEvento();
    }, [idConta]);

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? '4xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
      >
        <ModalContent
          bg="white"
          borderRadius={isLargerThan900 ? 'md' : '0'}
          marginBottom={isLargerThan900 ? '3.75rem' : '0'}
          marginTop={isLargerThan900 ? '3.75rem' : '0'}
          w={isLargerThan900 ? '780px' : 'full'}
          maxH={isLargerThan900 ? '600px' : 'full'}
        >
          <ModalHeader pt="16px" pb="15px" pl={['10px', '10px', '24px']}>
            <Text color="primary.50" fontSize="16px">
              Histórico de eventos
            </Text>
            <ModalCloseButton />
            <Divider mt="16px" />
          </ModalHeader>

          {isLoading && <LoadingPadrao />}
          <ModalBody
            pl={['10px', '10px', '24px']}
            pr={['10px', '10px', '15px']}
            pt="0"
            pb="0"
          >
            <Box
              w="full"
              h={isLargerThan900 ? '300px' : 'full'}
              sx={{
                '& > div': {
                  marginRight: '5px',
                },

                '&::-webkit-scrollbar': {
                  width: '5px',
                  height: '5px',
                  scrollbarWidth: 'thin',
                },

                '&::-webkit-scrollbar-track': {
                  background: 'none',
                  borderRadius: '6px',
                },

                '&::-webkit-scrollbar-thumb': {
                  background: 'gray.100',
                  borderRadius: '6px',
                },

                '&::-webkit-scrollbar-thumb:hover': {
                  background: 'gray.100',
                },
              }}
              overflow="auto"
            >
              {listHistoricoEvento.map((historicoEventoItem) => {
                return (
                  <Flex
                    mb="5px"
                    h="60px"
                    borderRadius="6px"
                    p="5px"
                    pl="15px"
                    w={['700px', '99%', '99%']}
                    pr="15px"
                    bg="gray.50"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box w="360px" alignItems="center">
                      <Text alignItems="center">
                        {historicoEventoItem.descricao}
                      </Text>
                    </Box>

                    <Flex alignItems="center">
                      <Text>{formatData(historicoEventoItem.dataHora)}</Text>
                    </Flex>
                    <Flex alignItems="center">
                      <Text textTransform="capitalize">
                        {historicoEventoItem.usuarioNome}
                      </Text>
                    </Flex>
                  </Flex>
                );
              })}
            </Box>
          </ModalBody>
          <ModalFooter flexDirection="column">
            <Divider mb="24px" />
            <Flex
              w="full"
              h="full"
              justifyContent="center"
              alignItems="baseline"
            >
              <Button
                color="gray.300"
                variant="outline"
                borderRadius="20px"
                fontSize="sm"
                type="button"
                h={isLargerThan900 ? '32px' : '40px'}
                mr="24px"
                onClick={() => onClose()}
                _hover={{ bg: 'gray.50' }}
                w="96px"
              >
                Cancelar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
