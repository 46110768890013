export type TipoImportacao = 1 | 2 | 3;

const TipoImportacaoEnum = {
  Clientes: 1,
  Fornecedores: 2,
  Produtos: 3,

  properties: {
    1: { name: 'Clientes', value: 1 },
    2: { name: 'Fornecedores', value: 2 },
    3: { name: 'Produtos', value: 3 },
  },
};

export default TipoImportacaoEnum;
