import { Flex } from '@chakra-ui/react';

import NivelCategoriaProdutoEnum from 'constants/enum/nivelCategoriaProduto';

interface ContainerProps {
  height?: number;
  nivel: number;
  children: React.ReactNode;
}

export const ContainerCategoriaProduto = ({
  children,
  nivel,
  height,
}: ContainerProps) => {
  const marginLeft = nivel === 1 ? '12px' : `calc(45px * ${nivel - 1})`;

  return (
    <Flex
      alignItems="center"
      height={`${height}px`}
      marginLeft={marginLeft}
      marginTop={nivel > 1 ? '5px' : undefined}
      color={NivelCategoriaProdutoEnum.properties[nivel - 1].foregroundColor}
    >
      {children}
    </Flex>
  );
};
