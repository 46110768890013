import { useCallback } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  useDisclosure,
  Flex,
  useMediaQuery,
  Box,
  Text,
  ModalHeader,
  Divider,
  Icon,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { AiOutlineRight, AiOutlineLeft } from 'react-icons/ai';
import { UseFormSetValue } from 'react-hook-form';

import { EnumSmartPos } from 'constants/enum/enumSmartPos';
import { EtapaSelecionadaProps } from 'store/SmartPos/SmartPosContext';
import EnumStatus from 'constants/enum/enumStatus';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';

type FormData = { nome: string; ativo: boolean | null | string };

type MenuMobileProps = Omit<ModalProps, 'children' | 'isOpen' | 'onClose'> &
  InstanceProps<ModalProps> & {
    setActiveStep: React.Dispatch<React.SetStateAction<EtapaSelecionadaProps>>;
    activeStep: EtapaSelecionadaProps;
    setValue: UseFormSetValue<FormData>;
  };
export const MenuMobile = create<MenuMobileProps, ModalProps>(
  ({ onResolve, onReject, activeStep, setActiveStep, setValue, ...rest }) => {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    const handleAtualizarStatus = useCallback(
      (value: boolean | null | string) => {
        setValue('ativo', value);
        onClose();
      },
      [setValue, onClose]
    );

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size="full"
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
      >
        <ModalContent zIndex="9999" bg="gray.50" w="full" h="full">
          <ModalHeader>
            <Icon
              onClick={onClose}
              cursor="pointer"
              color="blue.600"
              as={AiOutlineLeft}
            />
          </ModalHeader>
          <ModalBody
            justifyContent="center"
            alignItems="center"
            h="full"
            w="full"
            pl="24px"
            pr="24px"
            pt="0"
          >
            <Flex h="full" w="full">
              <Box h="full" w="full">
                {EnumSmartPos.itensSmartPos.map((itemSmartPos, index) => {
                  return (
                    <>
                      <Divider mb="10px" mt="10px" />
                      <Flex
                        pl="20px"
                        pr="20px"
                        cursor="pointer"
                        w="full"
                        justifyContent="space-between"
                        alignItems="center"
                        onClick={() => {
                          setActiveStep(itemSmartPos);
                          onClose();
                        }}
                      >
                        <Text fontSize="16px">{itemSmartPos.name}</Text>
                        {activeStep.value === index && (
                          <Icon as={AiOutlineRight} />
                        )}
                      </Flex>
                      {index + 1 === EnumSmartPos.itensSmartPos.length && (
                        <Divider mb="10px" mt="10px" />
                      )}
                    </>
                  );
                })}
                {activeStep.value === EnumSmartPos.DISPOSITIVO.value && (
                  <>
                    <Flex
                      pl="20px"
                      pr="20px"
                      cursor="pointer"
                      w="full"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Text fontSize="16px">Filtrar por status da licença</Text>

                      <ActionsMenu
                        iconItem={AiOutlineRight}
                        menuZIndex="modal"
                        items={[
                          {
                            content: 'Ativas',
                            onClick: () => {
                              handleAtualizarStatus(EnumStatus.ATIVOS);
                            },
                          },
                          {
                            content: 'Inativas',
                            onClick: () => {
                              handleAtualizarStatus(EnumStatus.INATIVOS);
                            },
                          },
                          {
                            content: 'Todas',
                            onClick: () => {
                              handleAtualizarStatus(EnumStatus.TODOS);
                            },
                          },
                        ]}
                      />
                    </Flex>
                    <Divider mb="10px" mt="10px" />
                  </>
                )}
              </Box>
            </Flex>
          </ModalBody>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
