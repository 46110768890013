import React, { useRef } from 'react';
import { ValueType } from 'react-select';

import OptionType from 'types/optionType';
import CampoPrototipo, {
  CampoPrototipoProps,
} from 'components/PDV/Geral/CampoPrototipo';

import { MobileSelectWrapper } from 'components/update/Select/MobileSelect';
import AsyncCreatableSelectInput, {
  AsyncCreatableSelectInputProps,
} from './Input';

export type AsyncCreatableSelectFieldProps = Omit<
  AsyncCreatableSelectInputProps,
  'disabled' | 'onCreateOption' | 'createdOptions'
> &
  CampoPrototipoProps & {
    handleGetOptions: (inputValue: string) => Promise<OptionType[]>;
    handleCreateOption: (inputValue: string) => Promise<OptionType | undefined>;
    onChangeEffect?: (
      option: OptionType | Array<OptionType> | null
    ) => Promise<boolean>;
    withoutDefaultOptions?: boolean;
    selectOptionsRef?: React.MutableRefObject<OptionType[]>;
    colorSelectedValue?: string;
    asControlledByObject?: boolean;
    asMobileView?: boolean;
    isOptionSelected?: (option: OptionType, currentValue: any) => boolean;
    showMobileButton?: boolean;
    shouldAppearTheAddress?: boolean;
    onChangeSelect?: (option: OptionType | null | undefined) => void;
  };

const AsyncCreatableSelectField = ({
  handleGetOptions,
  handleCreateOption,
  name,
  label,
  required = false,
  isDisabled,
  helperText,
  onChangeSelect,
  id,
  isMulti = false,
  options,
  onChangeEffect,
  colSpan,
  colStart,
  colEnd,
  rowSpan,
  rowStart,
  rowEnd,
  withoutDefaultOptions,
  selectOptionsRef,
  components,
  colorSelectedValue,
  asControlledByObject = false,
  asMobileView = false,
  isOptionSelected,
  showMobileButton = true,
  errorText,
  shouldAppearTheAddress,
  ...selectProps
}: AsyncCreatableSelectFieldProps) => {
  // Ref utilizada para armazenar as opções selecionadas, necessária por conta das opções que são cadastradas mas não carregadas na lista assíncrona (na inserção o select não tem de onde tirar o label desta)
  const selectedOptions =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    selectOptionsRef || (useRef([]) as React.MutableRefObject<OptionType[]>);

  return (
    <CampoPrototipo
      name={name}
      isRequired={required}
      isDisabled={isDisabled}
      helperText={helperText}
      id={id}
      label={label}
      rowSpan={rowSpan}
      rowStart={rowStart}
      rowEnd={rowEnd}
      colSpan={colSpan}
      colStart={colStart}
      colEnd={colEnd}
      errorText={errorText}
    >
      {(
        { formState: { isSubmitting }, setValue, clearErrors },
        { value, ...fieldProps }
      ) => (
        <MobileSelectWrapper
          asMobileView={asMobileView}
          showButton={showMobileButton}
        >
          {({ onClose }) => (
            <AsyncCreatableSelectInput
              selectRef={fieldProps.ref}
              components={components}
              handleGetOptions={
                isDisabled ? async () => [] as OptionType[] : handleGetOptions
              }
              withoutDefaultOptions={withoutDefaultOptions}
              isDisabled={isSubmitting || isDisabled}
              {...fieldProps}
              {...selectProps}
              isOptionSelected={
                isOptionSelected
                  ? (option) => isOptionSelected(option, value)
                  : undefined
              }
              id={id}
              isMulti={isMulti}
              onCreateOption={async (inputValue: string) => {
                const createdOption = await handleCreateOption(inputValue);

                if (createdOption) {
                  selectedOptions.current = isMulti
                    ? [...selectedOptions.current, createdOption]
                    : [createdOption];

                  if (Array.isArray(value)) {
                    setValue(`${name}` as const, [
                      ...value,
                      asControlledByObject
                        ? createdOption
                        : createdOption.value,
                    ]);
                  } else {
                    setValue(
                      `${name}` as const,
                      asControlledByObject ? createdOption : createdOption.value
                    );
                  }
                }
              }}
              value={
                asControlledByObject
                  ? value
                  : selectedOptions.current.filter((selectedOption) => {
                      return Array.isArray(value)
                        ? value.some((v) => v === selectedOption.value)
                        : selectedOption.value === value;
                    })
              }
              onChange={async (
                option: ValueType<OptionType> | null | undefined
              ) => {
                const onClear = () => {
                  setValue(`${name}` as const, null, { shouldDirty: true });
                };

                if (onChangeSelect) {
                  onChangeSelect(option as OptionType);
                }

                if (onChangeEffect) {
                  if (
                    !(await onChangeEffect(
                      option as OptionType | Array<OptionType> | null
                    ))
                  ) {
                    // onClear();
                    return;
                  }
                }

                onClose();

                if (option) {
                  clearErrors(`${name}` as const);
                }

                if (!option) {
                  onClear();
                  return;
                }

                if (Array.isArray(option) && option.length === 0) {
                  onClear();
                  return;
                }

                selectedOptions.current = (Array.isArray(option)
                  ? option
                  : [option]) as OptionType[];

                if (Array.isArray(option)) {
                  setValue(
                    `${name}` as const,
                    asControlledByObject
                      ? option
                      : option.map((opt) => opt.value),
                    { shouldDirty: true }
                  );

                  return;
                }

                setValue(
                  `${name}` as const,
                  asControlledByObject ? option : (option as OptionType).value,
                  {
                    shouldDirty: true,
                  }
                );
              }}
              colorSelectedValue={colorSelectedValue}
              shouldAppearTheAddress={shouldAppearTheAddress}
            />
          )}
        </MobileSelectWrapper>
      )}
    </CampoPrototipo>
  );
};

export default AsyncCreatableSelectField;
