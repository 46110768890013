import React, { useEffect } from 'react';
import { useMediaQuery, Box, Stack, useDisclosure } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import auth from 'modules/auth';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteRotas from 'constants/rotas';
import ConstanteRotasPdv from 'constants/rotasPDV';
import { useSignalRContext } from 'store/SignalR';

import ModalAlternarLoja from 'components/PDV/Modal/ModalAlternarLoja';

import Breadcrumb from './Breadcrumb';
import { HeaderItem } from './HeaderItem';
import { HeaderItemMobile } from './HeaderItem/Mobile';

type HeaderProps = {
  hasBorder?: boolean;
};

const Header = ({ hasBorder = true }: HeaderProps) => {
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const { hubConnection } = useSignalRContext();

  const history = useHistory();

  const {
    isOpen: isModalAlternarLojaOpen,
    onOpen: onModalAlternarLojaOpen,
    onClose: onModalAlternarLojaClose,
  } = useDisclosure();

  async function logOff() {
    await api.post<void, ResponseApi>(
      ConstanteEnderecoWebservice.AUTENTICACAO_LOGOFF
    );

    auth.clearTokenAndRedirect();
  }

  useEffect(() => {
    hubConnection.on('trocar-loja', () => {
      const { pathname } = window.location;

      if (pathname.indexOf('pdv') !== -1) {
        history.push(ConstanteRotasPdv.PDV_HOME, null);
      } else {
        history.push(ConstanteRotas.DASHBOARD, null);
      }
      history.go(0);
    });
  }, [hubConnection]);

  return (
    <Box
      bg="gray.50"
      pl="3px"
      maxW="100%"
      overflow="hidden"
      gridArea="HE"
      zIndex="1018"
    >
      <Box
        borderBottom={hasBorder ? '1px' : '0'}
        borderColor="primary.400"
        mx={6}
        h="100%"
      >
        <Stack
          direction="row"
          align="center"
          justify="space-between"
          spacing={2}
          h="100%"
        >
          <Breadcrumb />

          <Stack direction="row" align="center" spacing={2} h="100%" w="auto">
            {isLargerThan900 ? (
              <HeaderItem
                logOff={logOff}
                onModalAlternarLojaOpen={onModalAlternarLojaOpen}
              />
            ) : (
              <HeaderItemMobile
                logOff={logOff}
                onModalAlternarLojaOpen={onModalAlternarLojaOpen}
              />
            )}

            <ModalAlternarLoja
              isOpen={isModalAlternarLojaOpen}
              onClose={onModalAlternarLojaClose}
              onSubmit={() => {}}
            />
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default Header;
