import { setDateMinHours, setDateMaxHours } from 'helpers/data/setHoursDate';
import { ModalProps } from '@chakra-ui/react';
import { InstanceProps } from 'react-modal-promise';

export type FormasRecebimentoResponse = {
  id: string;
  nome: string;
  regraMeioPagamento: number;
};

export type Option = {
  label: string;
  value: string | boolean | null;
};

export type ModalBuscaAvancadaProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<FormData> & {
    currentFilters: FormData;
    clearFilters: () => void;
  };

export type FormData = {
  conciliado?: boolean | null;
  dataVencimentoInicio?: Date | null;
  dataVencimentoFim?: Date | null;
  dataEmissaoInicio?: Date | null;
  dataEmissaoFim?: Date | null;
  dataPagamentoInicio?: Date | null;
  dataPagamentoFim?: Date | null;
  dataCompensacaoInicio?: Date | null;
  dataCompensacaoFim?: Date | null;
  formaPagamentoBaixaId?: string | null;
  codigoAutorizacao?: string | null;
  taxa?: {
    [key: string]: number | string;
  };
  dataCompensacao?: {
    [key: string]: Date | string;
  };
  checkboxSelecionar?: {
    [key: string]: boolean;
  };
};

export type HookBuscaAvancadaModalProps = {
  onResolve: (data: FormData) => void;
  clearFilters: () => void;
  currentFilters: FormData;
};

export const formDefaultValues: FormData = {
  conciliado: null,
  dataCompensacaoInicio: null,
  dataCompensacaoFim: null,
  formaPagamentoBaixaId: null,
  codigoAutorizacao: null,
  dataEmissaoInicio: setDateMinHours(new Date()),
  dataEmissaoFim: setDateMaxHours(new Date()),
  dataPagamentoInicio: null,
  dataPagamentoFim: null,
  dataVencimentoInicio: null,
  dataVencimentoFim: null,
};
