import { useEffect, useState } from 'react';
import {
  ModalContent,
  ModalBody,
  Flex,
  ModalHeader,
  ModalFooter,
  Text,
  Divider,
  Button,
  FormLabel,
  Icon,
  GridItem,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { FormProvider, useForm } from 'react-hook-form';

import { enumStatusTransacoesZoopOptions } from 'constants/enum/enumSituacaoTransacoesZoop';
import { enumFormaPagamentoZoop } from 'constants/enum/enumFormaPagamentoZoop';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { StatusPesquisaClientesFornecedor } from 'constants/enum/statusPesquisaClientesFornecedor';
import { formatOptionsSelectClient } from 'helpers/format/formatSelectClient';
import getOptionsByEnum from 'helpers/format/getOptionsByEnum';
import api, { ResponseApi } from 'services/api';

import { LupaIcon } from 'icons';
import InputDateRange from 'components/PDV/InputDateRange';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import AsyncSelect from 'components/PDV/Select/AsyncSelectPadrao';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { FormData } from '../../validationForm';

type ClientesResponse = {
  id: string;
  nome: string;
  endereco: string;
  codigo: number;
  cpfCnpj: string;
};

interface ModalBuscaAvancadaProps {
  isOpen: boolean;
  onClose: () => void;
  onClear: () => void;
  handleSubmitModalBuscaAvancada: (data: FormData) => void;
  filtros: FormData;
}

export const ModalBuscaAvancada = ({
  isOpen,
  onClose,
  onClear,
  handleSubmitModalBuscaAvancada,
  filtros,
}: ModalBuscaAvancadaProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const formMethods = useForm<FormData>();

  const handleSubmit = formMethods.handleSubmit(async (data: FormData) => {
    setIsLoading(true);
    handleSubmitModalBuscaAvancada(data);
    setIsLoading(false);
  });

  async function getClientesOptions(inputValue: string) {
    const response = await api.get<void, ResponseApi<ClientesResponse[]>>(
      ConstanteEnderecoWebservice.CLIENTE_FORNECEDOR_LISTAR_SELECT,
      {
        params: {
          cpfCnpjNomeApelidoCodigoExterno: inputValue,
          filtroTipoCadastroPessoa: StatusPesquisaClientesFornecedor.CLIENTES,
        },
      }
    );
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        return response.dados.map((client) => {
          const option = formatOptionsSelectClient(client);
          return option;
        });
      }
    }

    return [];
  }

  useEffect(() => {
    if (isOpen) {
      formMethods.reset(filtros);
    }
  }, [filtros, formMethods, isOpen]);

  return (
    <ModalPadraoChakra
      onClose={() => {
        onClose();
      }}
      isOpen={isOpen}
      isCentered
      size="4xl"
    >
      <ModalContent
        marginBottom={{ base: 0, sm: '3.75rem' }}
        marginTop={{ base: 0, sm: '3.75rem' }}
        h="auto"
        bg="gray.50"
        w={['full', 'full', '680px']}
      >
        {isLoading && <LoadingPadrao />}
        <ModalHeader>
          <Text color="primary.50" fontSize="md">
            Busca avançada
          </Text>
          <Text color="gray.700" fontSize="xs">
            Utilize os filtros de busca para uma pesquisa específica. Todos os
            campos são acumulativos.
          </Text>
          <Divider orientation="horizontal" mt="16px" />
        </ModalHeader>
        <ModalBody pt="0" pb="0">
          <FormProvider {...formMethods}>
            <SimpleGridForm>
              <GridItem colSpan={[12, 12, 8]}>
                <AsyncSelect
                  id="clienteFornecedorId"
                  name="clienteFornecedorId"
                  label="Nome do cliente"
                  isClearable
                  placeholder="Selecione a razão social ou nome fantasia"
                  handleGetOptions={getClientesOptions}
                  colSpan={6}
                  asControlledByObject={false}
                  shouldAppearTheAddress
                />
              </GridItem>
              <GridItem colSpan={[12, 12, 4]}>
                <SelectPadrao
                  label="Tipo"
                  id="tipoPagamento"
                  isSearchable={false}
                  name="tipoPagamento"
                  options={enumFormaPagamentoZoop}
                />
              </GridItem>

              <GridItem colSpan={[12, 12, 6]}>
                <FormLabel
                  mb="4px"
                  fontSize="sm"
                  color="black"
                  height="14px"
                  lineHeight="1.2"
                >
                  Período
                </FormLabel>
                <InputDateRange
                  borderRadius="md"
                  name="dataCriacao"
                  size="md"
                  startDateName="dataCriacaoInicio"
                  endDateName="dataCriacaoFim"
                  maxDate={new Date()}
                />
              </GridItem>

              <GridItem colSpan={[12, 12, 6]}>
                <SelectPadrao
                  name="status"
                  id="status"
                  isSearchable={false}
                  label="Situação"
                  options={getOptionsByEnum(enumStatusTransacoesZoopOptions)}
                />
              </GridItem>
            </SimpleGridForm>
          </FormProvider>
        </ModalBody>
        <ModalFooter flexDirection="column" mt={6}>
          <Divider orientation="horizontal" mb="16px" />
          <Flex
            width="100%"
            justifyContent={['center', 'center', 'center']}
            gap={['6px', '24px', '24px']}
          >
            <Button
              variant="cancelOutline"
              colorScheme="gray"
              borderRadius="full"
              px="12px"
              onClick={() => onClose()}
            >
              Cancelar
            </Button>
            <Button
              id="limparFiltros"
              name="limparFiltros"
              variant="outlineDefault"
              colorScheme="gray"
              h="35px"
              px="12px"
              borderRadius="16px"
              onClick={() => onClear()}
            >
              Limpar filtros
            </Button>
            <Button
              id="pesquisar"
              name="pesquisar"
              variant="solid"
              colorScheme="aquamarine"
              leftIcon={<Icon as={LupaIcon} />}
              h="35px"
              px="12px"
              borderRadius="16px"
              onClick={() => {
                handleSubmit();
                onClose();
              }}
            >
              Pesquisar
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </ModalPadraoChakra>
  );
};
