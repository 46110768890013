import { isMobile } from 'react-device-detect';
import { IoIosLink } from 'react-icons/io';
import { useEffect } from 'react';

import { useOperacaoContext } from 'store/PDV/Operacao';
import { useFinalizarContext } from 'store/PDV/Finalizar';
import auth from 'modules/auth';
import ConstanteFuncionalidades from 'constants/permissoes';

import { CompartilharMenuIcon, NFCeMenuIcon, NFeMenuIcon } from 'icons';
import MenuLateral from 'components/PDV/Layout/MenuLateral';
import TabButton from 'components/PDV/Layout/MenuLateral/TabButton';

interface MenuProps {
  tabIndex: number;
  handleTabsChange: (newIndex: number) => void;
}

const Menu = ({ tabIndex, handleTabsChange }: MenuProps) => {
  const { operacaoIsLoading } = useOperacaoContext();
  const {
    operacao,
    deveMostrarTelaEmitirNFCe,
    deveMostrarTelaEmitirNFe,
  } = useFinalizarContext();

  const hasPermissaoNotaFiscal = auth.possuiPermissao(
    ConstanteFuncionalidades.NOTA_FISCAL_EMITIR
  ).permitido;

  const isPixEnvioZoop = operacao?.pixLink;
  const isBoletoZoop = operacao?.boletoLink;

  useEffect(() => {
    if (!hasPermissaoNotaFiscal) {
      handleTabsChange(0);
    }
  }, [handleTabsChange, hasPermissaoNotaFiscal]);

  return (
    <MenuLateral
      isLoading={operacaoIsLoading}
      operacao={
        operacao
          ? {
              identificacaoTipoOperacao: operacao.identificacaoTipoOperacao,
              nomeCliente: operacao.cliente.nome,
              numeroOperacao: operacao.numeroOperacao,
              valorTotal: operacao.valorTotal,
              NFCe: operacao.NFCe
                ? {
                    descricao: operacao.NFCeFormatada || '',
                    status: operacao.NFCe.status,
                  }
                : undefined,
              NFe: operacao.NFe
                ? {
                    descricao: operacao.NFeFormatada || '',
                    status: operacao.NFe.status,
                  }
                : undefined,
            }
          : undefined
      }
    >
      {deveMostrarTelaEmitirNFCe && (
        <TabButton
          icon={NFCeMenuIcon}
          title="NFC-e"
          subtitle="Emitir nota fiscal do consumidor"
          isFirst
          disabled={!hasPermissaoNotaFiscal}
          onClick={() => {
            handleTabsChange(1);
          }}
          isActive={tabIndex === 1}
          maxW={isMobile ? '700px' : undefined}
        />
      )}
      {deveMostrarTelaEmitirNFe && (
        <TabButton
          icon={NFeMenuIcon}
          title="NF-e"
          subtitle="Emitir nota fiscal eletrônica"
          disabled={!hasPermissaoNotaFiscal}
          isFirst={!deveMostrarTelaEmitirNFCe}
          onClick={() => {
            handleTabsChange(2);
          }}
          isActive={tabIndex === 2}
          maxW={isMobile ? '700px' : undefined}
        />
      )}
      <TabButton
        icon={CompartilharMenuIcon}
        title="Compartilhar"
        subtitle="Salvar, imprimir ou enviar"
        isFirst={!deveMostrarTelaEmitirNFCe && !deveMostrarTelaEmitirNFe}
        onClick={() => {
          handleTabsChange(0);
        }}
        isActive={tabIndex === 0}
        maxW={isMobile ? '700px' : undefined}
      />
      {(isPixEnvioZoop || isBoletoZoop) && (
        <TabButton
          icon={IoIosLink}
          title="Link de Pagamento"
          subtitle="Copie e cole o link onde quiser"
          onClick={() => {
            handleTabsChange(3);
          }}
          maxW={isMobile ? '700px' : undefined}
          isActive={tabIndex === 3}
        />
      )}
    </MenuLateral>
  );
};

export default Menu;
