import { VStack, Text } from '@chakra-ui/react';
import { useImperativeHandle, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useTrayEtapasContext } from 'store/Tray';
import ConstanteRotas from 'constants/rotas';
import { getTipoProdutoTray } from 'services/tray';

import { ImportandoIcon } from 'icons';

import { Header } from '../Layout/Header';
import { TipoUtilizacaoCadastro } from '../TipoCadastro/validationForms';

export const Importando = () => {
  const { ref, setIsProdutoSistema, isProdutoSistema } = useTrayEtapasContext();

  const history = useHistory();

  const handleFechar = useCallback(() => {
    history.push(ConstanteRotas.DASHBOARD);
  }, [history]);

  useImperativeHandle(ref, () => ({
    handleAvancar: handleFechar,
  }));

  useEffect(() => {
    async function getData() {
      const response = await getTipoProdutoTray();

      if (response !== null) {
        setIsProdutoSistema(
          response.tipoCadastro === TipoUtilizacaoCadastro.PRODUTO_ZENDAR
        );
      }
    }
    getData();
  }, [setIsProdutoSistema]);

  return (
    <>
      <Header
        title={isProdutoSistema ? 'Enviando..' : 'Importando..'}
        icon={ImportandoIcon}
      />
      {!isProdutoSistema ? (
        <VStack
          w="full"
          pl={['10px', '10px', '20px']}
          pr={['10px', '10px', '20px']}
          color="primary.50"
          fontSize="14px"
          alignItems="left"
          spacing={['10px', '10px', '24px']}
        >
          <Text textAlign={['center', 'left', 'left']} letterSpacing="0px">
            Pronto, já estamos importando e cadastrando os produtos encontrados.
            Dependendo do número de itens o processo pode ser demorado, portanto
            a importação será executada em segundo plano.
          </Text>
          <Text textAlign={['center', 'left', 'left']} letterSpacing="0px">
            Você receberá uma notificação no sistema e também por e-mail assim
            que a importação estiver totalmente concluída. Não é possível seguir
            com a integração até que todos os itens sejam cadastrados.
          </Text>
          <Text textAlign={['center', 'left', 'left']} letterSpacing="0px">
            Enquanto isso, utilize normalmente todos os outros recursos do
            sistema. Clique no botão abaixo para sair do guia de integração e
            voltar a tela inicial.
          </Text>
        </VStack>
      ) : (
        <VStack
          w="full"
          pl={['10px', '10px', '20px']}
          pr={['10px', '10px', '20px']}
          color="primary.50"
          fontSize="14px"
          alignItems="left"
          spacing={['10px', '10px', '24px']}
        >
          <Text textAlign={['center', 'left', 'left']} letterSpacing="0px">
            Pronto, já estamos enviando e publicando os produtos na plataforma.
            Dependendo do número de itens o processo pode ser demorado, portanto
            o envio será executada em segundo plano.
          </Text>
          <Text textAlign={['center', 'left', 'left']} letterSpacing="0px">
            Você receberá uma notificação no sistema e também por e-mail assim
            que o envio estiver totalmente concluído. Não é possível seguir com
            a integração até que todos os itens sejam publicados.
          </Text>
          <Text textAlign={['center', 'left', 'left']} letterSpacing="0px">
            Enquanto isso, utilize normalmente todos os outros recursos do
            sistema. Clique no botão abaixo para sair do guia de integração e
            voltar a tela inicial.
          </Text>
        </VStack>
      )}
    </>
  );
};
