import React from 'react';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

import { TabelaPrecosListar } from '../pages/TabelaPrecos/Listar';
import TabelaPrecosCadastrar from '../pages/TabelaPrecos/Formulario/Cadastrar';
import TabelaPrecosAlterar from '../pages/TabelaPrecos/Formulario/Alterar';
import TabelaPrecosVisualizar from '../pages/TabelaPrecos/Formulario/Visualizar';

export const TabelaPrecosRoutes = [
  <LayoutGuard
    key="tabela-preco-listar"
    permissaoFuncionalidade={ConstanteFuncionalidades.TABELA_PRECO_LISTAR}
    component={TabelaPrecosListar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Tabela de Preços',
      },
    ]}
    path={ConstanteRotas.TABELA_PRECOS}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="tabela-preco-cadastrar"
    permissaoFuncionalidade={ConstanteFuncionalidades.TABELA_PRECO_CADASTRAR}
    component={TabelaPrecosCadastrar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Tabela de Preços',
        path: ConstanteRotas.TABELA_PRECOS,
      },
      {
        title: 'Novo',
      },
    ]}
    path={ConstanteRotas.TABELA_PRECO_CADASTRAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="tabela-preco-alterar"
    permissaoFuncionalidade={ConstanteFuncionalidades.TABELA_PRECO_ALTERAR}
    component={TabelaPrecosAlterar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Tabela de Preços',
        path: ConstanteRotas.TABELA_PRECOS,
      },
      {
        title: 'Editar',
      },
    ]}
    path={ConstanteRotas.TABELA_PRECO_ALTERAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="tabela-preco-visualizar"
    permissaoFuncionalidade={ConstanteFuncionalidades.TABELA_PRECO_VISUALIZAR}
    component={TabelaPrecosVisualizar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Tabela de Preços',
        path: ConstanteRotas.TABELA_PRECOS,
      },
      {
        title: 'Visualizar',
      },
    ]}
    path={ConstanteRotas.TABELA_PRECO_VISUALIZAR}
    exact
    meta={{ auth: true }}
  />,
];
