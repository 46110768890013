import React, { createContext, useCallback, useContext, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import putOperacao from 'helpers/api/Operacao/putOperacao';
import cancelarOperacao from 'helpers/api/Operacao/cancelarOperacao';
import OperacaoCamposAlterarEnum from 'constants/enum/operacaoCamposAlterar';
import LogAuditoriaTelaEnum from 'constants/enum/logAuditoriaTela';
import ConstanteRotasPDV from 'constants/rotasPDV';
import RegraLimiteCreditoEnum from 'constants/enum/regraLimiteCredito';
import excluirOperacaoItem from 'helpers/api/OperacaoItem/excluirOperacaoItem';
import { HubConnection } from '@microsoft/signalr';
import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';
import { toast } from 'react-toastify';

interface OperacaoContextProps {
  handleChangeVendedor: (
    newVendedor: Vendedor,
    operacaoId?: string
  ) => Promise<void>;
  handleChangeCliente: (
    newCliente: Cliente,
    operacaoId?: string
  ) => Promise<void>;
  handleChangeObservacao: (
    newObservacao: string,
    operacaoId?: string
  ) => Promise<void>;
  handleCancelarOperacao: (
    hubConnection: HubConnection,
    joinGroup: (group: string) => Promise<unknown>,
    chavePermissaoTemporaria?: string
  ) => Promise<void>;
  handleExcluirItemOperacao: (
    operacaoItemId: string,
    chave: string
  ) => Promise<boolean>;
  handleChangeTipoOperacao: (tipoOperacao: number) => Promise<void>;
  handleChangeLocalEstoque: (idLocalEstoque: string) => Promise<void>;
  operacaoIsLoading: boolean;
  setOperacaoIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  operacaoId?: string;
  informacoesCliente?: Cliente;
  setInformacoesCliente: React.Dispatch<
    React.SetStateAction<Cliente | undefined>
  >;
  clienteNaoPodeComprarNoCreditoLoja: boolean;
  setClienteNaoPodeComprarNoCreditoLoja: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  tipoOperacaoAtual: number;
}

const OperacaoContext = createContext<OperacaoContextProps>(
  {} as OperacaoContextProps
);

export type Cliente = {
  id: string;
  nome: string;
  tabelaPrecoId: string;
  tabelaPrecoNome: string;
  regraLimiteCredito: number;
  endereco?: string;
  cpfCnpj?: string;
  padraoSistema: boolean;
};

export type Vendedor = { id: string; nome: string };

type MParams = { id?: string };

interface OperacaoProviderProps extends RouteComponentProps<MParams> {
  children: React.ReactNode;
}

export default function OperacaoProvider({
  children,
  match: { params },
}: OperacaoProviderProps): JSX.Element {
  const history = useHistory();
  const formMethods = useForm({
    shouldUnregister: false,
  });

  const { id: operacaoId = undefined } = params || {};
  const [informacoesCliente, setInformacoesCliente] = useState<Cliente>();

  const [operacaoIsLoading, setOperacaoIsLoading] = useState(false);
  const [
    clienteNaoPodeComprarNoCreditoLoja,
    setClienteNaoPodeComprarNoCreditoLoja,
  ] = useState<boolean>(false);

  const tipoOperacaoAtual: number = formMethods?.getValues(
    'identificacaoTipoOperacao'
  );

  const handleChangeVendedor = async (newVendedor: Vendedor) => {
    let putSuccess = true;

    const { id: vendedorId = undefined } =
      formMethods.getValues('vendedor') || {};

    if (operacaoId && vendedorId !== newVendedor.id) {
      putSuccess = await putOperacao({
        id: operacaoId,
        conteudoCampo: newVendedor.id,
        campo: OperacaoCamposAlterarEnum.VENDEDOR,
        tela: LogAuditoriaTelaEnum.PDV,
      });
    }

    if (putSuccess) {
      formMethods.setValue('vendedor', newVendedor);
    }
  };

  const handleChangeCliente = async (newCliente: Cliente) => {
    let putSuccess = true;
    let cliente;
    const { id: clienteId = undefined } =
      formMethods.getValues('cliente') || {};
    const identificacaoTipoOperacao = formMethods.getValues(
      'identificacaoTipoOperacao'
    );

    if (operacaoId && clienteId !== newCliente.id) {
      let valueCliente;

      if (
        newCliente.padraoSistema &&
        identificacaoTipoOperacao === IdentificacaoTipoOperacaoEnum.CONSIGNACAO
      ) {
        toast.warning(
          'Não é permitido lançar uma consignação para o cliente padrão.'
        );
        valueCliente = clienteId;
        cliente = formMethods.getValues('cliente') || {};
      } else {
        valueCliente = newCliente.id;
        cliente = newCliente;
      }

      putSuccess = await putOperacao({
        id: operacaoId,
        conteudoCampo: valueCliente,
        campo: OperacaoCamposAlterarEnum.CLIENTE,
        tela: LogAuditoriaTelaEnum.PDV,
      });
    } else {
      cliente = newCliente;
    }

    if (putSuccess) {
      formMethods.setValue('cliente', cliente);
      if (cliente?.tabelaPrecoId && cliente?.tabelaPrecoNome) {
        formMethods.setValue('tabelaPreco', {
          name: cliente.tabelaPrecoNome,
          value: cliente.tabelaPrecoId,
        });
      }

      if (
        cliente?.regraLimiteCredito ===
        RegraLimiteCreditoEnum.BLOQUEIO_CREDIARIO
      ) {
        setClienteNaoPodeComprarNoCreditoLoja(true);
      } else {
        setClienteNaoPodeComprarNoCreditoLoja(false);
      }
    }
    setInformacoesCliente(newCliente);
  };

  const handleChangeTipoOperacao = async (tipoOperacao: number) => {
    if (operacaoId) {
      const permitidoAlterarOperacao = await putOperacao({
        id: operacaoId,
        conteudoCampo: tipoOperacao.toString(),
        campo: OperacaoCamposAlterarEnum.TIPO_OPERACAO,
        tela: LogAuditoriaTelaEnum.PDV,
      });

      if (permitidoAlterarOperacao) {
        formMethods.setValue('identificacaoTipoOperacao', tipoOperacao);
      }
    }
  };

  const handleChangeObservacao = async (newObservacao: string) => {
    let putSuccess = true;

    if (operacaoId) {
      putSuccess = await putOperacao({
        id: operacaoId,
        conteudoCampo: newObservacao,
        campo: OperacaoCamposAlterarEnum.OBSERVACAO,
        tela: LogAuditoriaTelaEnum.PDV,
      });
    }

    if (putSuccess) {
      formMethods.setValue('observacao', newObservacao);
    }
  };

  const cancelar = async (
    hubConnection: HubConnection,
    joinGroup: (group: string) => Promise<unknown>,
    idCancelar: string,
    chavePermissaoTemporaria?: string
  ) => {
    setOperacaoIsLoading(true);

    await cancelarOperacao(
      {
        id: idCancelar,
        tela: LogAuditoriaTelaEnum.PDV,
      },
      hubConnection,
      joinGroup,
      () => {
        setOperacaoIsLoading(false);
        history.push(ConstanteRotasPDV.PDV_HOME);
      },
      () => {
        setOperacaoIsLoading(false);
      },
      chavePermissaoTemporaria
    );
  };

  const handleCancelarOperacao = async (
    hubConnection: HubConnection,
    joinGroup: (group: string) => Promise<unknown>,
    chavePermissaoTemporaria?: string
  ) => {
    if (operacaoId) {
      await cancelar(
        hubConnection,
        joinGroup,
        operacaoId,
        chavePermissaoTemporaria
      );
    } else {
      history.push(ConstanteRotasPDV.PDV_HOME);
    }
  };

  const handleExcluirItemOperacao = async (
    operacaoItemId: string,
    chave: string
  ) => {
    if (operacaoId) {
      const excluirSucess = await excluirOperacaoItem(
        operacaoItemId,
        LogAuditoriaTelaEnum.PDV,
        chave
      );

      return excluirSucess;
    }
    return false;
  };

  const handleChangeLocalEstoque = useCallback(
    async (idLocalEstoque: string) => {
      if (operacaoId) {
        await putOperacao({
          id: operacaoId,
          conteudoCampo: idLocalEstoque,
          campo: OperacaoCamposAlterarEnum.LOCAL_ESTOQUE,
          tela: LogAuditoriaTelaEnum.PDV,
        });
      }
    },
    [operacaoId]
  );
  return (
    <FormProvider {...formMethods}>
      <OperacaoContext.Provider
        value={{
          handleChangeVendedor,
          handleChangeCliente,
          handleChangeObservacao,
          setInformacoesCliente,
          handleChangeTipoOperacao,
          handleCancelarOperacao,
          handleChangeLocalEstoque,
          informacoesCliente,
          handleExcluirItemOperacao,
          operacaoIsLoading,
          setOperacaoIsLoading,
          operacaoId,
          clienteNaoPodeComprarNoCreditoLoja,
          setClienteNaoPodeComprarNoCreditoLoja,
          tipoOperacaoAtual,
        }}
      >
        {children}
      </OperacaoContext.Provider>
    </FormProvider>
  );
}

export function useOperacaoContext(): OperacaoContextProps {
  const context = useContext(OperacaoContext);

  if (!context)
    throw new Error(
      'useOperacaoContext must be used within a OperacaoProvider.'
    );

  return context;
}
