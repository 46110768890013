import { useCallback } from 'react';
import {
  Tr,
  Text,
  Td,
  Box,
  Flex,
  Link,
  Tooltip,
  VStack,
  Icon,
} from '@chakra-ui/react';

import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import { moneyMask } from 'helpers/format/fieldsMasks';

import { ContaVenceHoje } from 'icons';

import { ListarHistoricoVendasResponseProps } from '../../Types/validationsForm';

type HistoricoVendaProps = {
  historico: ListarHistoricoVendasResponseProps;
  regraRepasseRecebimento: boolean;
};

export const HistoricoVenda = ({
  historico,
  regraRepasseRecebimento,
}: HistoricoVendaProps) => {
  const detalhesVenda = useCallback((id: string) => {
    return SubstituirParametroRota(ConstanteRotas.OPERACOES_DETALHE, 'id', id);
  }, []);

  const aparecerDetalheCanceladoOuDevolucao =
    historico.cancelado || historico.devolucao;

  return (
    <>
      <Tr
        sx={{
          '& > td': {
            paddingBottom: '12px',
            fontSize: '14px',
            color: historico.cancelado
              ? 'purple.500'
              : historico.devolucao
              ? 'red.500'
              : 'black',
            paddingTop: '15px',
            borderBottom: '1px solid #CCCCCC',
          },
        }}
      >
        <Td w="12%">{historico.dataHora}</Td>
        <Td w="10%" textDecoration="underline">
          <Link href={detalhesVenda(historico.operacaoId)} target="blank">
            {historico.numeroOperacao}
          </Link>
        </Td>
        <Td w="38%">
          <Flex justifyContent="left" alignItems="left">
            {historico.cliente}{' '}
            {aparecerDetalheCanceladoOuDevolucao && (
              <Flex
                justifyContent="center"
                ml="5px"
                pl="8px"
                pr="8px"
                borderRadius="12px"
                alignItems="center"
                bg={historico.cancelado ? 'purple.500' : 'red.500'}
              >
                <Text color="white">
                  {historico.cancelado ? 'cancelada' : 'devolução'}
                </Text>
              </Flex>
            )}
          </Flex>
        </Td>

        <Td w="20%">
          <Flex
            textDecoration={
              aparecerDetalheCanceladoOuDevolucao ? 'line-through' : 'none'
            }
            justifyContent="right"
            alignItems="right"
          >
            <Text mr="7px">{`R$ ${historico.valorTotal}`}</Text>
            <Tooltip
              borderRadius="md"
              w="48"
              alignItems="center"
              h="28"
              display="flex"
              justifyContent="left"
              placement="left-start"
              hasArrow
              fontSize="2xs"
              bg="gray.900"
              color="white"
              label={
                <VStack>
                  <Flex w="full" justifyContent="left" alignItems="center">
                    <Text color="white" mr="5px">
                      Valor total:
                    </Text>
                    <Text fontSize="xs" color="white">
                      {`R$ ${historico.valorTotal}`}
                    </Text>
                  </Flex>
                  <Flex w="full" justifyContent="left" alignItems="center">
                    <Text color="white" mr="5px">
                      Acréscimos:
                    </Text>
                    <Text color="red.300" fontSize="xs">
                      {moneyMask(historico?.detalhePagamento?.acrescimos, true)}
                    </Text>
                  </Flex>
                  <Flex w="full" justifyContent="left" alignItems="center">
                    <Text color="white" mr="5px">
                      {regraRepasseRecebimento
                        ? 'Valor recebido:'
                        : 'Valor para comissão:'}
                    </Text>
                    <Text fontSize="xs" color="aquamarine.600">
                      {moneyMask(
                        historico?.detalhePagamento?.valorParaComissao,
                        true
                      )}
                    </Text>
                  </Flex>
                  <Flex w="full" justifyContent="left" alignItems="center">
                    <Text color="white" mr="5px">
                      Percentual aplicado:
                    </Text>
                    <Text fontSize="xs" color="pink.100">
                      {`${moneyMask(
                        historico?.detalhePagamento?.porcentagemComissao,
                        false
                      )}%`}
                    </Text>
                  </Flex>
                </VStack>
              }
            >
              <Box mt="2px">
                <Icon as={ContaVenceHoje} />
              </Box>
            </Tooltip>
          </Flex>
        </Td>

        <Td w="20%">
          <Flex
            textDecoration={
              aparecerDetalheCanceladoOuDevolucao ? 'line-through' : 'none'
            }
            justifyContent="right"
            alignItems="right"
          >
            {`R$ ${historico.comissao}`}
          </Flex>
        </Td>
      </Tr>
    </>
  );
};
