import React, { forwardRef, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Col, Form } from 'react-bootstrap';

import { useNotaFiscalFormularioContext } from 'store/NotaFiscal/NotaFiscalFormulario';
import AmbienteFiscalEnum from 'constants/enum/fiscal/ambienteFiscal';

import TabsPadrao from 'components/Tabs/TabsPadrao';

import { toast } from 'react-toastify';
import FinalidadesNotaFiscalEnum from 'constants/enum/fiscal/finalidadeNotaFiscal';
import { Principal } from './AbasCadastro/Principal';
import { Produtos } from './AbasCadastro/Produtos';
import { Totais } from './AbasCadastro/Totais';
import { DadosAdicionais } from './AbasCadastro/DadosAdicionais';
import { Pagamento } from './AbasCadastro/Pagamento';

interface UncontrolledFormInterface {
  refTabInicial: React.MutableRefObject<HTMLAnchorElement>;
  isVisualizar?: boolean;
}

export const validarPagamentoIgualValorTotal = (
  data: any,
  valorTotalParcela: number
) => {
  if (data.documentoFiscalPagamentos?.length > 0) {
    if (valorTotalParcela !== data.valorTotal) {
      toast.warning(
        'O valor do pagamento não pode ser diferente do valor total da nota.'
      );
      return false;
    }
  }

  return true;
};

export const validarDocumentosReferenciadosNotaDevolucao = (data: any) => {
  if (
    data.finalidade === FinalidadesNotaFiscalEnum.DevolucaoMercadoria &&
    data.documentoFiscalReferenciados?.length === 0
  ) {
    toast.warning(
      'Nota fiscal de devolução precisa ter um documento fiscal referenciado.'
    );
    return false;
  }

  return true;
};

export const UncontrolledForm = forwardRef(
  ({ refTabInicial, isVisualizar = false }: UncontrolledFormInterface) => {
    const { setValue } = useFormContext();
    const {
      readonly,
      lojaInformacoesFiscais,
      tipoAmbienteFiscal,
    } = useNotaFiscalFormularioContext();

    const [isLoading] = useState(false);

    useEffect(() => {
      setValue('serie', lojaInformacoesFiscais?.nFeNumeroSerie);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lojaInformacoesFiscais]);

    return (
      <>
        {tipoAmbienteFiscal &&
          tipoAmbienteFiscal === AmbienteFiscalEnum.Homologacao && (
            <Form.Row>
              <Col>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    background: 'var(--sti-ck-colors-red-300)',
                    height: '35px',
                    alignItems: 'center',
                    marginBottom: '10px',
                    fontWeight: 'bold',
                    color: 'var(--white)',
                  }}
                >
                  HOMOLOGAÇÃO - SEM VALOR FISCAL
                </div>
              </Col>
            </Form.Row>
          )}
        <TabsPadrao
          id="produtos-tabs"
          isLoading={isLoading}
          tabs={[
            {
              eventKey: 'inicial',
              title: 'Inicial',
              content: (
                <Principal readonly={readonly} isVisualizar={isVisualizar} />
              ),
              refNavLink: refTabInicial,
            },
            {
              eventKey: 'produtos',
              title: 'Produtos',
              content: (
                <Produtos readonly={readonly} isVisualizar={isVisualizar} />
              ),
            },
            {
              eventKey: 'totais',
              title: 'Totais',
              content: (
                <Totais readonly={readonly} isVisualizar={isVisualizar} />
              ),
            },
            {
              eventKey: 'dados-adicionais',
              title: 'Dados adicionais',
              content: (
                <DadosAdicionais
                  readonly={readonly}
                  isVisualizar={isVisualizar}
                />
              ),
            },
            {
              eventKey: 'pagamento',
              title: 'Dados de pagamento',
              content: (
                <Pagamento readonly={readonly} isVisualizar={isVisualizar} />
              ),
              tabWidth: '200px',
            },
          ]}
        />
      </>
    );
  }
);
