import React from 'react';
import { Stack, StackProps } from '@chakra-ui/react';

export type HeaderContainerProps = StackProps & {
  children: any;
};

const HeaderContainer = ({ children, ...props }: HeaderContainerProps) => (
  <Stack
    direction="row"
    align="center"
    justifyContent="space-between"
    h="100%"
    height={{
      base: '75px',
      md: '100px',
    }}
    px={{ base: 6, md: 8, lg: 12 }}
    spacing={10}
    {...props}
  >
    {children}
  </Stack>
);

export default HeaderContainer;
