import { useCallback, useState, useEffect } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  ModalFooter,
  Box,
  ModalHeader,
  useMediaQuery,
  Icon,
  Grid,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import {
  CompartilharEmailIcon,
  CompartilharImprimirIcon,
  CompartilharWhatsappIcon,
  FiscalExportarXmlIcon,
  IconType,
} from 'icons';

import { ItemCompartilhar } from './ItemCompartilhar';

export type ItemsProps = {
  titulo: string;
  onClickWhatsApp?: (onClose: () => void) => void;
  onClickEmail?: (onClose: () => void) => void;
  onClickDownload?: (onClose: () => void) => void;
  onClickImpressao?: (onClose: () => void) => void;
  onClickDirect?: (onClose: () => void) => void;
  iconDownload?: IconType;
};

type ModalCompartilhar = Omit<ModalProps, 'children' | 'isOpen' | 'onClose'> &
  InstanceProps<ModalProps> & {
    items: ItemsProps[];
    onCloseCallback?: () => void;
    componenteParaImpressao?: () => React.ReactNode; // Usado quando o modal de compartilhamento impede a criação direta de um componente para ser utilizado pelo react-to-print.
  };

export const ModalCompartilhar = create<ModalCompartilhar, ModalProps>(
  ({
    onResolve,
    onReject,
    items,
    onCloseCallback,
    componenteParaImpressao,
    ...rest
  }) => {
    const [
      isVisibleOptionsCompartilhar,
      setIsVisibleOptionsCompartilhar,
    ] = useState(-1);
    const [tituloOption, setTituloOption] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isVisibleOptions, setIsVisibleOptions] = useState(true);

    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    const isUnicoItem = items.length === 1;

    const onCloseModal = useCallback(() => {
      if (onCloseCallback) {
        onCloseCallback();
      }
      onClose();
    }, [onClose, onCloseCallback]);

    const handleExibirOpcoesCompartilhar = useCallback(
      (index: number, titulo: string) => {
        setIsLoading(true);
        const option = items[index];

        if (option?.onClickDirect) {
          option?.onClickDirect(onClose);
          setIsLoading(false);
          return;
        }

        setTituloOption(titulo);
        setIsVisibleOptionsCompartilhar(
          isVisibleOptionsCompartilhar === index ? -1 : index
        );
        setIsVisibleOptions(false);

        setIsLoading(false);
      },
      [isVisibleOptionsCompartilhar, items, onClose]
    );

    const handleFecharModal = () => {
      if (onCloseCallback) {
        onCloseCallback();
      }
      onClose();
    };

    const handleExibirOpcoes = () => {
      setIsLoading(true);

      setIsVisibleOptions(true);
      setIsVisibleOptionsCompartilhar(-1);
      setTituloOption('');

      setIsLoading(false);
    };

    useEffect(() => {
      if (isUnicoItem && items) {
        setIsLoading(true);
        const valueItem = items[0];

        setTituloOption(valueItem.titulo);
        setIsVisibleOptionsCompartilhar(0);
        setIsVisibleOptions(false);

        setIsLoading(false);
      }
    }, [isUnicoItem, items]);

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? '3xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onCloseModal}
        autoFocus={false}
      >
        <ModalContent
          bg="gray.50"
          borderRadius={isLargerThan900 ? 'md' : '0'}
          marginBottom={isLargerThan900 ? '3.75rem' : '0'}
          marginTop={isLargerThan900 ? '3.75rem' : '0'}
          w={['full', 'full', '640px']}
          h={['full', 'full', '480px']}
        >
          <ModalHeader
            pt="16px"
            alignItems="center"
            display="flex"
            pb="28px"
            px="40px"
          >
            {tituloOption && !isUnicoItem && (
              <Icon
                onClick={handleExibirOpcoes}
                cursor="pointer"
                fontSize="25px"
                color="primary.50"
                mr="20px"
                as={FiChevronLeft}
              />
            )}
            <Text color="primary.50" fontSize="20px">
              {tituloOption || 'Compartilhar'}
            </Text>
          </ModalHeader>

          {isLoading && <LoadingPadrao />}
          <ModalBody px="40px" mb="20px" pt="0" pb="0">
            <Box>
              {items.map(
                (
                  {
                    titulo,
                    onClickDownload,
                    onClickEmail,
                    onClickImpressao,
                    onClickWhatsApp,
                    iconDownload,
                  },
                  index
                ) => (
                  <>
                    {isVisibleOptions ? (
                      <Box
                        onClick={() =>
                          handleExibirOpcoesCompartilhar(index, titulo)
                        }
                        cursor="pointer"
                        borderRadius="5px"
                        h="40px"
                        mb="5px"
                        px="16px"
                        boxShadow="0px 0px 8px #00000029"
                        _hover={{
                          boxShadow: '0px 0px 4px rgba(85, 2, 178, 0.5)',
                        }}
                      >
                        <Flex
                          justifyContent="space-between"
                          w="full"
                          h="full"
                          alignItems="center"
                        >
                          <Box>
                            <Text fontSize="16px">{titulo}</Text>
                          </Box>
                          <Box>
                            <Icon fontSize="16px" as={FiChevronRight} />
                          </Box>
                        </Flex>
                      </Box>
                    ) : (
                      <Grid
                        templateColumns={[
                          'repeat(1, 1fr)',
                          'repeat(4, 1fr)',
                          'repeat(4, 1fr)',
                        ]}
                        gap={3}
                      >
                        {isVisibleOptionsCompartilhar === index && (
                          <>
                            {onClickImpressao && (
                              <ItemCompartilhar
                                icon={CompartilharImprimirIcon}
                                onClick={() => onClickImpressao(onClose)}
                                text="Imprimir"
                              />
                            )}
                            {onClickEmail && (
                              <ItemCompartilhar
                                icon={CompartilharEmailIcon}
                                onClick={() => onClickEmail(onClose)}
                                text="Enviar por Email"
                              />
                            )}
                            {onClickWhatsApp !== undefined && (
                              <ItemCompartilhar
                                icon={CompartilharWhatsappIcon}
                                onClick={() => onClickWhatsApp(onClose)}
                                text="Enviar via WhatsApp"
                              />
                            )}

                            {onClickDownload && (
                              <ItemCompartilhar
                                icon={iconDownload || FiscalExportarXmlIcon}
                                onClick={() => onClickDownload(onClose)}
                                text="Download"
                              />
                            )}
                          </>
                        )}
                      </Grid>
                    )}
                  </>
                )
              )}
            </Box>
            {componenteParaImpressao && componenteParaImpressao()}
          </ModalBody>
          <ModalFooter flexDirection="column" mb="20px">
            <Flex
              w="full"
              h="full"
              justifyContent="center"
              alignItems="baseline"
            >
              <Button
                variant="cancelOutline"
                h={isLargerThan900 ? '32px' : '40px'}
                onClick={handleFecharModal}
                w="96px"
              >
                Cancelar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
