import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useState,
  useContext,
  useCallback,
  useRef,
} from 'react';
import produce from 'immer';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';

export type CategoriaProdutoType = {
  id: string;
  nome: string;
  categoriaProdutoPaiId?: string;
  ativo: boolean;
  foto?: string;
};

export type CategoryProps = {
  sincronizarNoAplicativo?: boolean;
  ocultarNoAplicativo?: boolean;
  permiteFracionamento?: boolean;
  controleMesas?: boolean;
  delivery?: boolean;
  vendasBalcao?: boolean;
} & CategoriaProdutoType;

interface CategoriasProdutoContextProps {
  categoriasProduto: CategoryProps[];
  setCategoriasProduto: (newCategoriasProduto: CategoryProps[]) => void;
  getCategoriasProdutoPeloPaiId: (
    categoriaProdutoPaiId?: string
  ) => CategoriaProdutoType[];
  moverCategoriaProduto: (
    categoriaProdutoId: string,
    categoriaProdutoPaiId?: string,
    categoriaAbaixoId?: string
  ) => void;
  getSubNiveisCategoriaProduto: (itemId: string) => number;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  handleGetCategoriasProduto: (data?: any) => void;
  itemsExpandidos: boolean;
  setItemsExpandidos: Dispatch<SetStateAction<boolean>>;
}

const CategoriasProdutoContext = createContext<CategoriasProdutoContextProps>(
  {} as CategoriasProdutoContextProps
);

interface CategoriasProdutoProviderProps {
  children: React.ReactNode;
}

export default function CategoriasProdutoProvider({
  children,
}: CategoriasProdutoProviderProps): JSX.Element {
  const [categoriasProduto, setCategoriasProdutoState] = useState<
    CategoryProps[]
  >([] as CategoryProps[]);
  const [isLoading, setIsLoading] = useState(false);
  const [itemsExpandidos, setItemsExpandidos] = useState(false);

  const dataRef = useRef();

  const setCategoriasProduto = useCallback(
    (newCategoriasProduto: CategoryProps[]) => {
      setCategoriasProdutoState(newCategoriasProduto);
    },
    []
  );

  const getCategoriasProdutoPeloPaiId = useCallback(
    (categoriaProdutoPaiId?: string) => {
      return categoriasProduto
        .filter((categoriaProduto) =>
          !categoriaProdutoPaiId
            ? !categoriaProduto.categoriaProdutoPaiId ||
              categoriaProduto.categoriaProdutoPaiId ===
                '00000000-0000-0000-0000-000000000000'
            : categoriaProduto.categoriaProdutoPaiId === categoriaProdutoPaiId
        )
        .sort((a, b) => {
          if (a.nome < b.nome) {
            return -1;
          }
          if (a.nome > b.nome) {
            return 1;
          }
          return 0;
        });
    },
    [categoriasProduto]
  );

  const moverCategoriaProduto = useCallback(
    (
      categoriaProdutoId: string,
      categoriaProdutoPaiId?: string,
      categoriaAbaixoId?: string
    ) => {
      setCategoriasProdutoState(
        produce(categoriasProduto, (draft) => {
          const targetItem = draft.find(
            (categoriaProduto) => categoriaProduto.id === categoriaProdutoId
          );

          if (targetItem) {
            const rInd = draft.indexOf(targetItem);
            draft.splice(rInd, 1);

            const parentItem = !categoriaProdutoPaiId
              ? undefined
              : draft.find(
                  (categoriaProduto) =>
                    categoriaProduto.id === categoriaProdutoPaiId
                );

            targetItem.categoriaProdutoPaiId = parentItem
              ? parentItem.id
              : undefined;

            if (parentItem && !parentItem.ativo) {
              targetItem.ativo = false;
            }

            let index = 0;

            if (categoriaAbaixoId) {
              index = draft.findIndex(
                (categoria) => categoria.id === categoriaAbaixoId
              );
            }

            draft.splice(index, 0, targetItem);
          }
        })
      );
    },
    [categoriasProduto]
  );

  const countSubNiveisCategoriaProduto = useCallback(
    (categoriaProdutoId: string, subNiveis: number) => {
      let newSubNiveis = subNiveis;

      const proximoNivelItems = categoriasProduto.filter(
        (categoriaProduto) =>
          categoriaProduto.categoriaProdutoPaiId === categoriaProdutoId
      );

      if (proximoNivelItems.length > 0) {
        newSubNiveis += 1;
      }

      proximoNivelItems.forEach((categoriaProduto) => {
        newSubNiveis = countSubNiveisCategoriaProduto(
          categoriaProduto.id,
          newSubNiveis
        );
      });

      return newSubNiveis;
    },
    [categoriasProduto]
  );

  const getSubNiveisCategoriaProduto = useCallback(
    (itemId: string) => {
      let subNiveis = 1;

      subNiveis = countSubNiveisCategoriaProduto(itemId, subNiveis);

      return subNiveis;
    },
    [countSubNiveisCategoriaProduto]
  );

  const handleGetCategoriasProduto = useCallback(
    async (data?: any) => {
      let filtros;
      if (data) {
        dataRef.current = data;
        filtros = data;
      } else if (dataRef.current) {
        filtros = dataRef.current;
      }

      if (filtros) {
        setIsLoading(true);

        const response = await api.get<
          void,
          ResponseApi<Array<CategoriaProdutoType>>
        >(ConstanteEnderecoWebservice.CATEGORIA_PRODUTO_LISTAR, {
          params: { ativo: filtros.status },
        });

        if (response && response.sucesso) {
          const value = response.dados.map(
            (categoriaProduto: CategoriaProdutoType) => ({
              ...categoriaProduto,
              expandido: false,
            })
          );
          setCategoriasProduto(
            value.sort((a, b) => {
              if (a.nome < b.nome) {
                return -1;
              }
              if (a.nome > b.nome) {
                return 1;
              }
              return 0;
            })
          );
        }

        setIsLoading(false);
      }
    },
    [setCategoriasProduto]
  );

  return (
    <CategoriasProdutoContext.Provider
      value={{
        categoriasProduto,
        setCategoriasProduto,
        getCategoriasProdutoPeloPaiId,
        moverCategoriaProduto,
        getSubNiveisCategoriaProduto,
        isLoading,
        setIsLoading,
        handleGetCategoriasProduto,
        itemsExpandidos,
        setItemsExpandidos,
      }}
    >
      {children}
    </CategoriasProdutoContext.Provider>
  );
}

export function useCategoriasProdutoContext(): CategoriasProdutoContextProps {
  const context = useContext(CategoriasProdutoContext);

  if (!context)
    throw new Error(
      'useCategoriasProdutoContext must be used within a CategoriasProdutoProvider.'
    );

  return context;
}
