import {
  ModalProps,
  ModalContent,
  ModalBody,
  useDisclosure,
  ModalHeader,
  VStack,
  Text,
  ModalCloseButton,
  useMediaQuery,
} from '@chakra-ui/react';
import { useState } from 'react';
import { create, InstanceProps } from 'react-modal-promise';
import { BrowserRouter, Route } from 'react-router-dom';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import CadastrarFormaRecebimento from 'pages/FormaRecebimento/Formulario/Cadastrar';

type FormaRecebimento = {
  [x: string]: any;
};
type PromiseModal = {
  formaRecebimentoCriada: FormaRecebimento;
};
type ModalCadastrarFormaRecebimentoProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<PromiseModal>;
export const ModalCadastrarFormaRecebimento = create<ModalCadastrarFormaRecebimentoProps>(
  ({ onResolve }) => {
    const [isLoading, setIsLoading] = useState(false);

    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
    const [isMobile] = useMediaQuery('(max-width: 900px)');

    const callbackCadastrarSucesso = (
      formaRecebimentoCriada: FormaRecebimento
    ) => {
      setIsLoading(true);
      onResolve({ formaRecebimentoCriada });
      setIsLoading(false);
    };
    return (
      <ModalPadraoChakra
        isOpen={isOpen}
        onClose={onClose}
        size={isMobile ? 'full' : '6xl'}
        isCentered={!isMobile}
      >
        <ModalContent>
          {isLoading && <LoadingPadrao />}
          <ModalHeader px="0" mx={{ base: 6, md: 8 }}>
            <VStack alignItems="flex-start" spacing="1" lineHeight="1">
              <Text color="primary.500" fontSize={{ base: 'lg', md: 'md' }}>
                Cadastro de Forma de Recebimento
              </Text>
            </VStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <BrowserRouter>
              <Route>
                <CadastrarFormaRecebimento
                  isRouteSmartPos
                  callbackCadastrarSucesso={callbackCadastrarSucesso}
                />
              </Route>
            </BrowserRouter>
          </ModalBody>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
