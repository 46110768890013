import React from 'react';
import { InputGroup, InputLeftElement, Icon, useToken } from '@chakra-ui/react';

import { BuscaIcon } from 'icons';
import SelectPadrao, {
  SelectFieldProps,
} from 'components/PDV/Select/SelectPadrao';

export const FilterSelect = ({ styles, ...rest }: SelectFieldProps) => {
  const [size12] = useToken('sizes', ['12']);

  return (
    <InputGroup cursor="pointer">
      <InputLeftElement pointerEvents="none" mx="2.5">
        <Icon as={BuscaIcon} />
      </InputLeftElement>

      <SelectPadrao
        {...rest}
        styles={{
          ...styles,
          valueContainer: (base: any) => ({
            ...base,
            paddingLeft: `${size12} !important`,
          }),
        }}
      />
    </InputGroup>
  );
};
