import { useState, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import { UncontrolledForm } from '..';
import { useDeliverySectorForm } from '../hooks';
import { defaultValues, FormData, yupResolver } from '../validationForm';

type Loja = {
  id: string;
  nome: string;
  ativo: boolean;
};

type SetorEntregaProps = {
  id: string;
  descricao: string;
  valor: number;
  lojas: Loja[];
  informacaoCadastro: {
    ativo: boolean;
  };
};

type Lojas = {
  id: string;
  razaoSocial: string;
  fantasia: string;
  endereco: {
    logradouro: string;
    bairro: string;
    numero: string;
    cep: string;
    cidade: string;
    estado: string;
    uf: string;
  };
};

const AlterarSetorEntrega = () => {
  const { handleToDeliverySector, listLojas } = useDeliverySectorForm();
  const { id: idRota } = useParams<{ id: string }>();

  const [isLoading, setIsLoading] = useState(false);

  const formMethods = useForm<FormData>({
    defaultValues,
    resolver: yupResolver,
  });

  const { handleSubmit: onSubmit, setValue, reset } = formMethods;

  const updateSetorCaixa = async (data: FormData) => {
    const newValueLojas = data.lojas as string[];

    const lojas = newValueLojas.filter(Boolean);

    if (lojas?.length === 0) {
      toast.warning('Selecione ao menos uma loja para o setor de entrega');

      return { success: false };
    }

    const newData = {
      ...data,
      lojas,
    };

    const response = await api.put<void, ResponseApi<string>>(
      `${ConstanteEnderecoWebservice.SETOR_ENTREGA}/${idRota}`,
      {
        ...newData,
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }
      if (response.sucesso) {
        toast.success('Setor de entrega alterado com sucesso');
        return { success: true };
      }
    }
    return { success: false };
  };

  const handleSave = onSubmit(async (data) => {
    setIsLoading(true);

    const { success } = await updateSetorCaixa(data);

    if (success) {
      handleToDeliverySector();
    }

    setIsLoading(false);
  });

  const getDeliverySector = useCallback(
    async (dataLojas: Lojas[]) => {
      if (!idRota) {
        return;
      }

      const response = await api.get<void, ResponseApi<SetorEntregaProps>>(
        `${ConstanteEnderecoWebservice.SETOR_ENTREGA}/${idRota}`
      );
      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }

        if (response.sucesso) {
          const {
            descricao,
            lojas,
            valor,
            informacaoCadastro,
          } = response.dados;

          const newLojas = lojas.map((itemLoja) => itemLoja.id);

          reset({
            descricao,
            valor,
            ativo: informacaoCadastro.ativo,
          });

          dataLojas.forEach((itemLoja, index) => {
            const lojaIsAdded = newLojas.find(
              (lojaAdded) => lojaAdded === itemLoja.id
            );

            if (lojaIsAdded) {
              setValue(`lojas.${index}`, itemLoja.id);
            }
          });
        }
      }
    },
    [idRota, setValue, reset]
  );

  useEffect(() => {
    getDeliverySector(listLojas);
  }, [getDeliverySector, listLojas]);

  return (
    <FormProvider {...formMethods}>
      <UncontrolledForm handleSave={handleSave} isLoading={isLoading} />
    </FormProvider>
  );
};

export { AlterarSetorEntrega };
