import React, { memo } from 'react';
import { XYCoord, useDragLayer } from 'react-dnd';

import { Card } from '../components/Card';

function getItemStyles(
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null
) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }

  const { x, y } = currentOffset;

  const transform = `translate(${x}px, ${y}px) rotate(0.5deg)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

const ItemDragLayer = () => {
  const { isDragging, item, initialOffset, currentOffset } = useDragLayer(
    (monitor) => ({
      item: monitor.getItem(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    })
  );

  if (!isDragging) return null;

  return (
    <Card
      nivel={item.nivel}
      style={{
        position: 'fixed',
        pointerEvents: 'none',
        left: 0,
        top: 0,
        width: `${item.width}px` || '100%',
        transition: 'opacity 0.2s',
        ...getItemStyles(initialOffset, currentOffset),
      }}
    >
      {item?.categoria?.nome}
    </Card>
  );
};

export default memo(ItemDragLayer);
