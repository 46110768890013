import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

interface PaginationItemProps extends Omit<ButtonProps, 'isActive'> {
  isCurrent?: boolean;
}

export const PaginationItem = ({
  isCurrent = false,
  children,
  onClick,
  ...rest
}: PaginationItemProps) => {
  if (isCurrent) {
    return (
      <Button
        borderRadius="sm"
        colorScheme="primary"
        size="xs"
        {...rest}
        isActive
        isDisabled={false}
      >
        {children}
      </Button>
    );
  }

  return (
    <Button
      borderRadius="sm"
      variant="ghost"
      colorScheme="primary"
      size="xs"
      onClick={onClick}
      {...rest}
    >
      {children}
    </Button>
  );
};
