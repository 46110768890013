import ReactApexChart from 'react-apexcharts';
import { useToken } from '@chakra-ui/react';

type GraficoMetaPessoalProps = {
  valorMetaPessoal: number;
};

export const GraficoMetaPessoal = ({
  valorMetaPessoal,
}: GraficoMetaPessoalProps) => {
  const [
    secondary300,
    aquamarine200,
    aquamarine50,
    white,
    black,
  ] = useToken('colors', [
    'secondary.300',
    'aquamarine.200',
    'aquamarine.50',
    'white',
    'black',
  ]);

  return (
    <ReactApexChart
      options={{
        colors: [secondary300],
        fill: {
          type: ['gradient'],
          colors: [secondary300],
          gradient: {
            shade: 'light',
            gradientToColors: [aquamarine200],
            type: 'horizontal',
            shadeIntensity: 0.25,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: 'round',
        },

        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: '70%',
              position: 'front',
            },
            track: {
              background: aquamarine50,
              opacity: 0.9,
              strokeWidth: '100%',
              margin: 0,
            },

            dataLabels: {
              show: true,
              name: {
                offsetY: 0,
                show: false,
                color: white,
                fontSize: '16px',
              },
              value: {
                color: black,
                offsetY: 6,
                fontSize: '17px',
                show: true,
              },
            },
          },
        },
      }}
      series={[valorMetaPessoal]}
      type="radialBar"
      style={{
        width: '100%',
      }}
      height="100%"
    />
  );
};
