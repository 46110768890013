import {
  Box,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useMediaQuery,
} from '@chakra-ui/react';
import { IconSeta, IconType } from 'icons';
import { IoIosMenu } from 'react-icons/io';

interface TabTypeProps {
  eventKey: number;
  title: string;
  content: string | React.ReactNode;
  disabled?: boolean;
  hidden?: boolean;
  funcionalidade?: string;
  onClick?: () => Promise<boolean | (() => void)>;
  dica?: string;
  subTitulo?: string;
  icon: IconType;
}

type ListTabMobileProps = {
  tabSelecionada: TabTypeProps;
  tabsAtivas: TabTypeProps[];
  isDisabled: boolean;
  handleSelecionarTab: (tab: TabTypeProps) => Promise<void>;
};

export const ListTabMobile = ({
  tabSelecionada,
  tabsAtivas,
  isDisabled,
  handleSelecionarTab,
}: ListTabMobileProps) => {
  return (
    <Menu>
      <MenuButton
        h="60px"
        borderTopEndRadius="0"
        px={['10px', '24px']}
        color="white"
        bg="violet.500"
      >
        <Flex h="full" justifyContent="left" alignItems="center">
          <Icon fontSize="25px" as={IoIosMenu} />
        </Flex>
      </MenuButton>
      <MenuList>
        {tabsAtivas.map((tabItem) => (
          <MenuItem
            isDisabled={isDisabled}
            onClick={() => {
              if (!isDisabled) {
                handleSelecionarTab(tabItem);
              }
            }}
          >
            {tabItem.title}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
