import React from 'react';

import TipoImportacaoEnum from 'constants/enum/tipoImportacao';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

import ImportarFornecedoresListar from '../pages/Importacao/Listar';
import ImportarFornecedoresImportar from '../pages/Importacao/Importar';

const ImportarFornecedoresRoutes = [
  <LayoutGuard
    key="importarfornecedores-listar"
    permissaoFuncionalidade={
      ConstanteFuncionalidades.IMPORTACAO_FORNECEDOR_LISTAR
    }
    component={ImportarFornecedoresListar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Importar Fornecedores',
      },
    ]}
    path={ConstanteRotas.IMPORTAR_FORNECEDORES}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="importarfornecedores-importar"
    permissaoFuncionalidade={
      ConstanteFuncionalidades.IMPORTACAO_FORNECEDOR_IMPORTAR
    }
    component={() => (
      <ImportarFornecedoresImportar
        tipoImportacao={TipoImportacaoEnum.Fornecedores}
      />
    )}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Importar Fornecedores',
        path: ConstanteRotas.IMPORTAR_FORNECEDORES,
      },
      {
        title: 'Novo',
      },
    ]}
    path={ConstanteRotas.IMPORTAR_FORNECEDORES_IMPORTAR}
    exact
    meta={{ auth: true }}
  />,
];

export default ImportarFornecedoresRoutes;
