import React, { memo } from 'react';
import { Controller } from 'react-hook-form';
import Select, {
  OptionTypeBase,
  Props as SelectProps,
  GroupedOptionsType,
  OptionsType,
} from 'react-select';

import ContainerInputPadrao, {
  CampoContainerPadraoProps,
} from 'components/Layout/CampoContainer/CampoContainerPadrao';

import { selectStyles } from '../styles';

interface SelectPadraoProps
  extends SelectProps<OptionTypeBase>,
    CampoContainerPadraoProps {
  id: string;
  name: string;
  noSelectedText?: string;
  defaultValue: any;
  control: any;
  onSelect?: (value: any) => void;
  readonly?: boolean;
  placeholder?: string;
  options: GroupedOptionsType<OptionTypeBase> | OptionsType<OptionTypeBase>;
  controlledByObject?: boolean;
  exibirDefaultValue?: boolean;
}

const SelectPadrao = ({
  id,
  name,
  label,
  required,
  noSelectedText,
  exibirDefaultValue = false,
  defaultValue,
  control,
  error,
  infoText,
  infoOnClick,
  onSelect,
  readonly,
  placeholder,
  isDisabled,
  options,
  styles,
  onChange: SelectOnChange,
  controlledByObject,
  ...rest
}: SelectPadraoProps) => {
  return (
    <ContainerInputPadrao
      id={id}
      label={label}
      error={error}
      required={required}
      infoText={infoText}
      infoOnClick={infoOnClick}
    >
      <Controller
        defaultValue={defaultValue}
        render={({ field }) => (
          <Select
            id={id}
            ref={field.ref}
            classNamePrefix="react-select"
            isSearchable
            isClearable={!required}
            options={options}
            onChange={(newValue: any, actionMeta: any) => {
              if (newValue) {
                field.onChange(controlledByObject ? newValue : newValue.value);
                if (SelectOnChange) SelectOnChange(newValue, actionMeta);
                if (onSelect) onSelect(newValue);
              } else {
                field.onChange(null);
              }
            }}
            value={
              controlledByObject
                ? field.value
                : options.find((option: any) => option.value === field.value) ||
                  options.find(
                    (option: any) =>
                      (required || exibirDefaultValue) &&
                      option.value === defaultValue
                  ) ||
                  null
            }
            noOptionsMessage={() => 'Não há valores disponíveis'}
            placeholder={noSelectedText || placeholder}
            styles={styles ? { ...selectStyles, ...styles } : selectStyles}
            isDisabled={readonly || isDisabled}
            {...rest}
          />
        )}
        // onFocus={() => {
        //   if (selectRef) selectRef.current.focus();
        //   else ref.current.focus();
        // }}
        name={`${name}` as const}
        control={control}
      />
    </ContainerInputPadrao>
  );
};

export default memo(SelectPadrao);
