import {
  VStack,
  FormLabel,
  GridItem,
  Box,
  Text,
  Flex,
  SimpleGrid,
  AspectRatio,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { FormProvider } from 'react-hook-form';

import { Switch } from 'components/update/Switch';
import TextareaField from 'components/PDV/Textarea';
import AsyncSelect from 'components/PDV/Select/AsyncSelectPadrao';
import { InputNumber } from 'components/update/Input/InputNumber';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';

import { useFood } from './hooks';
import ImageUploaderRatio from './ImageUploaderRatio';

const Food = () => {
  const { formMethods, casasDecimaisValor, getPrintManager } = useFood();

  return (
    <FormProvider {...formMethods}>
      <Box>
        <VStack
          justify="left"
          spacing={{ base: 6, md: 8 }}
          overflowX="auto"
          pb="10px"
        >
          <SimpleGridForm w="full">
            <GridItem colSpan={{ base: 12, md: 6 }}>
              <Flex gap={2}>
                <Switch
                  id="usarComoComplemento"
                  name="usarComoComplemento"
                  size="md"
                  width="min"
                />
                <FormLabel whiteSpace="nowrap">
                  Produto utilizado como complemento
                </FormLabel>
              </Flex>
            </GridItem>
            <GridItem colSpan={{ base: 12, md: 6 }}>
              <Flex gap={2}>
                <Switch
                  id="cobrarTaxaServico"
                  name="cobrarTaxaServico"
                  width="min"
                  size="md"
                />
                <FormLabel whiteSpace="nowrap">
                  Cobrar taxa de serviço
                </FormLabel>
              </Flex>
            </GridItem>
            <GridItem colSpan={{ base: 12, md: 6 }}>
              <Flex gap={2}>
                <Switch
                  id="utilizarBalanca"
                  name="utilizarBalanca"
                  width="min"
                  size="md"
                />
                <FormLabel whiteSpace="nowrap">Produto por peso</FormLabel>
              </Flex>
            </GridItem>
            <GridItem colSpan={{ base: 12, md: 6 }}>
              <Flex gap={2}>
                <Switch
                  id="baixarSaldoMateriaPrima"
                  name="baixarSaldoMateriaPrima"
                  width="min"
                  size="md"
                />
                <FormLabel whiteSpace="nowrap">
                  Baixar saldo das matérias primas
                </FormLabel>
              </Flex>
            </GridItem>
            <GridItem colSpan={{ base: 12, md: 6 }}>
              <Flex gap={2}>
                <Switch
                  id="produtoCombo"
                  name="produtoCombo"
                  width="min"
                  size="md"
                />
                <FormLabel whiteSpace="nowrap">Utilizar como combo</FormLabel>
              </Flex>
            </GridItem>
            <GridItem colSpan={{ base: 12, md: 6 }}>
              <Flex gap={2}>
                <Switch
                  id="exportarBalanca"
                  name="exportarBalanca"
                  width="min"
                  size="md"
                />
                <FormLabel whiteSpace="nowrap">Exportar para balança</FormLabel>
              </Flex>
            </GridItem>
            <GridItem colSpan={{ base: 12, md: 6 }}>
              <Flex gap={2}>
                <Switch
                  id="utilizarPrecoDosItensEtapa"
                  name="utilizarPrecoDosItensEtapa"
                  width="min-content"
                  size="md"
                />
                <FormLabel whiteSpace={{ base: 'normal', md: 'nowrap' }}>
                  Preço formado pelos itens selecionados nas etapas
                </FormLabel>
              </Flex>
            </GridItem>
          </SimpleGridForm>
          <SimpleGridForm w="full">
            <GridItem colSpan={{ base: 12, md: 4 }}>
              <InputNumber
                name="diasParaValidade"
                label="Dias para validade"
                scale={0}
              />
            </GridItem>
            <GridItem colSpan={{ base: 12, md: 4 }}>
              <InputNumber
                name="precoCombo"
                label="Preço do produto no combo"
                scale={casasDecimaisValor}
              />
            </GridItem>
            <GridItem colSpan={{ base: 12, md: 4 }}>
              <AsyncSelect
                id="gerenciadorImpressao"
                name="gerenciadorImpressao"
                label="Gerenciador de impressão"
                isClearable
                isSearchable
                asControlledByObject
                handleGetOptions={getPrintManager}
                placeholder="Selecione um gerenciador de impressão"
              />
            </GridItem>
            <GridItem colSpan={12}>
              <TextareaField
                name="composicaoProduto"
                label="Composição do produto"
                maxH="200px"
              />
            </GridItem>
            <GridItem colSpan={12} w="full" minW="220px">
              <Box
                position="relative"
                width="full"
                minH="200px"
                borderWidth="1px"
                borderColor="gray.300"
                borderRadius="md"
                p={6}
              >
                <FormLabel
                  position="absolute"
                  top="-10px"
                  left={6}
                  bg="gray.100"
                  px={1}
                >
                  Imagens para aplicativos
                </FormLabel>
                <Text fontWeight="semibold" color="black" fontSize="14px">
                  As imagens serão utilizadas para exibição em aplicativos de
                  autoatendimento e delivery. Arraste e solte a imagem na caixa
                  de upload ou clique no botão para adicionar um arquivo do seu
                  computador.
                </Text>
                <SimpleGrid mt={4} gap={3} columns={12}>
                  <GridItem
                    colSpan={{ base: 12, md: 6 }}
                    as={Flex}
                    gap={2}
                    flexDir="column"
                  >
                    <FormLabel>Imagem para cardápio</FormLabel>
                    <AspectRatio
                      maxW={['full', 'full', '243px', '324px']}
                      ratio={324 / 240}
                    >
                      <ImageUploaderRatio
                        id="imagemCardapio"
                        name="imagemCardapio"
                        ratio={324 / 240}
                        handleSaveImage={({
                          isForaPadrao,
                          tamanhoImagem: { heightImage, widthImage },
                        }) => {
                          if (isForaPadrao) {
                            const resolutionExceeds =
                              heightImage > 2000 || widthImage > 2000;

                            toast.warning(
                              resolutionExceeds
                                ? 'O arquivo selecionado tem resolução maior que 2000px'
                                : 'O arquivo selecionado é muito grande, este campo aceita somente imagens de até 2MB'
                            );
                          }
                        }}
                      />
                    </AspectRatio>
                    <Box>
                      <Text fontWeight="semibold">
                        Extensões permitidas: JPG e PNG
                      </Text>
                      <Text fontWeight="semibold">
                        Resolução recomendada: 324 x 240
                      </Text>
                    </Box>
                  </GridItem>
                  <GridItem
                    colSpan={{ base: 12, md: 6 }}
                    as={Flex}
                    gap={2}
                    flexDir="column"
                  >
                    <FormLabel>Imagem de destaque</FormLabel>
                    <AspectRatio
                      maxW={['full', 'full', '396px', '528px']}
                      ratio={880 / 400}
                    >
                      <ImageUploaderRatio
                        id="imagemDestaque"
                        name="imagemDestaque"
                        ratio={880 / 400}
                        handleSaveImage={({
                          isForaPadrao,
                          tamanhoImagem: { heightImage, widthImage },
                        }) => {
                          if (isForaPadrao) {
                            const resolutionExceeds =
                              heightImage > 2000 || widthImage > 2000;

                            toast.warning(
                              resolutionExceeds
                                ? 'O arquivo selecionado tem resolução maior que 2000px'
                                : 'O arquivo selecionado é muito grande, este campo aceita somente imagens de até 2MB'
                            );
                          }
                        }}
                      />
                    </AspectRatio>
                    <Box>
                      <Text fontWeight="semibold">
                        Extensões permitidas: JPG e PNG
                      </Text>
                      <Text fontWeight="semibold">
                        Resolução recomendada: 880 x 400
                      </Text>
                    </Box>
                  </GridItem>
                </SimpleGrid>
              </Box>
            </GridItem>
          </SimpleGridForm>
        </VStack>
      </Box>
    </FormProvider>
  );
};

export { Food };
