import { Flex, Text, Box } from '@chakra-ui/react';
import { useBarcode } from 'next-barcode';

import { moneyMask } from 'helpers/format/fieldsMasks';
import { formatDate } from 'helpers/format/formatStringDate';

interface InformacoesVoucherProps {
  identificador: string;
  valorVoucher: number;
  dataHoraCadastro: Date;
}
export function InformacoesVoucher({
  identificador,
  valorVoucher,
  dataHoraCadastro,
}: InformacoesVoucherProps) {
  const { inputRef } = useBarcode({
    value: identificador,
    options: {
      width: 1.3,
      height: 45,
      fontSize: 15,
    },
  });

  return (
    <>
      <Flex
        justifyContent="center"
        alignItems="center"
        padding="5"
        flexDirection="column"
        gap="0.1rem"
      >
        <Text
          fontSize="md"
          fontWeight="extrabold"
          letterSpacing="tight"
          color="black"
        >
          VOUCHER
        </Text>
        <Text
          fontSize="md"
          fontWeight="extrabold"
          letterSpacing="tight"
          color="black"
          ml="4"
        >
          SALDO:
          <Text as="span" ml="2">
            {moneyMask(valorVoucher, true)}
          </Text>
        </Text>
        <Box>
          <svg ref={inputRef} />
        </Box>
        <Text
          fontSize="xs"
          fontWeight="extrabold"
          letterSpacing="tight"
          color="black"
        >
          Data de emissão: {formatDate(dataHoraCadastro || new Date())}
        </Text>
      </Flex>
    </>
  );
}
