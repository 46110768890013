import { Box, Flex, Button } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { ConfiguracaoItem } from './ConfiguracaoItem';
import { useConfiguracao } from './hooks';
import { FormData } from './validationForms';

export const Configuracao = () => {
  const formMethods = useForm<FormData>();

  const {
    getConfiguracao,
    handleConfiguracaoUpdate,
    isLoading,
  } = useConfiguracao(formMethods);

  return (
    <FormProvider {...formMethods}>
      {isLoading && <LoadingPadrao />}
      <Box mb="50px">
        <ConfiguracaoItem />
      </Box>
      <Flex my="20px" justifyContent="center" alignItems="center">
        <Button
          variant="outlineDefault"
          borderRadius="16px"
          colorScheme="gray"
          mr="24px"
          w="96px"
          onClick={() => getConfiguracao()}
        >
          Cancelar
        </Button>
        <Button
          color="black"
          variant="solid"
          colorScheme="secondary.400"
          borderRadius="16px"
          width="120px"
          onClick={() => handleConfiguracaoUpdate()}
        >
          Confirmar
        </Button>
      </Flex>
    </FormProvider>
  );
};
