import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import { isValidGTIN } from 'helpers/validation/isValidGtinEan';

export const shapeEditarGTINEANVariacaoModal = {
  codigoGTINEAN: yup
    .string()
    .nullable()
    .test(
      'codigoGTINEAN',
      ConstanteMensagemValidacao.GTIN_INVALIDO,
      (value) => {
        if (value) return isValidGTIN(value);

        return true;
      }
    )
    .max(14, ConstanteMensagemValidacao.MAX_LENGTH_14)
    .default(null),
};

const schemaEditarGTINEANVariacaoModal = yup
  .object()
  .shape(shapeEditarGTINEANVariacaoModal);

export const yupResolver = yupResolverInstance(
  schemaEditarGTINEANVariacaoModal
);

export const defaultValues = schemaEditarGTINEANVariacaoModal.default();

export const useForm = useFormInstance;
