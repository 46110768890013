import { toast } from 'react-toastify';
import api, { ResponseApi } from 'services/api';
import {
  useCallback,
  useEffect,
  useRef,
  useState,
  TouchEvent,
  DragEvent,
} from 'react';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { useProdutosFormularioContext } from 'store/Produtos/ProdutosFormulario';

import ModalEtapaCadastrar from './components/ModalEtapa/Cadastrar';
import ModalEtapaAlterar from './components/ModalEtapa/Alterar';

export type StepProps = {
  id: string;
  categoria: string;
  tipoEtapa: number;
  quantidade: number;
};

type SequenceStepProps = {
  produtoEtapaId: string;
  sequenciaOrdenacao: number;
};

const useProdutoEtapa = () => {
  const { idProduto, readonly } = useProdutosFormularioContext();

  const [isLoading, setIsLoading] = useState(false);
  const [listSteps, setListSteps] = useState<StepProps[]>([]);
  const [isDragging, setIsDragging] = useState(false);
  const [dragOverItemIndex, setDragOverItemIndex] = useState<
    number | undefined
  >(undefined);

  const itemDraggableRef = useRef<HTMLElement | null>(null);
  const initialTouchPosition = useRef({ x: 0, y: 0 });

  const getListSteps = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<void, ResponseApi<StepProps[]>>(
      ConstanteEnderecoWebservice.PRODUTO_ETAPA.replace('id', idProduto)
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response?.sucesso && response?.dados) {
        setListSteps(response.dados);
      }
    }

    setIsLoading(false);
  }, [idProduto]);

  const handleDeleteStep = useCallback(
    async (stepId: string) => {
      const response = await api.delete<void, ResponseApi>(
        `${ConstanteEnderecoWebservice.PRODUTO_ETAPA.replace(
          'id',
          idProduto
        )}/${stepId}`
      );

      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response?.sucesso) {
          toast.success('Etapa removida com sucesso');
          await getListSteps();
        }
      }
    },
    [getListSteps, idProduto]
  );

  const handleRegisterStep = useCallback(async () => {
    ModalEtapaCadastrar({
      callback: async (data) => {
        const response = await api.post<void, ResponseApi>(
          ConstanteEnderecoWebservice.PRODUTO_ETAPA.replace('id', idProduto),
          {
            ...data,
          }
        );

        if (response) {
          if (response?.avisos) {
            response.avisos.forEach((aviso) => toast.warning(aviso));
          }

          if (response?.sucesso) {
            toast.success('Etapa adicionada com sucesso');
            await getListSteps();

            return { success: true };
          }
        }

        return { success: false };
      },
    });
  }, [getListSteps, idProduto]);

  const handleUpdateStep = useCallback(
    (stepId: string) => {
      ModalEtapaAlterar({
        stepId,
        productId: idProduto,
        callback: async (data) => {
          const response = await api.put<void, ResponseApi>(
            `${ConstanteEnderecoWebservice.PRODUTO_ETAPA.replace(
              'id',
              idProduto
            )}/${stepId}`,
            {
              ...data,
            }
          );

          if (response) {
            if (response?.avisos) {
              response.avisos.forEach((aviso) => toast.warning(aviso));
            }

            if (response?.sucesso) {
              toast.success('Etapa alterada com sucesso');
              await getListSteps();

              return { success: true };
            }
          }

          return { success: false };
        },
      });
    },
    [getListSteps, idProduto]
  );

  const handleUpdateSequenceStep = useCallback(
    async (data: SequenceStepProps[]) => {
      const response = await api.put<void, ResponseApi>(
        ConstanteEnderecoWebservice.PRODUTO_ETAPA_SEQUENCIA_ORDENACAO.replace(
          'id',
          idProduto
        ),
        [...data]
      );

      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response?.sucesso) {
          return { success: true };
        }
      }

      return { success: false };
    },
    [idProduto]
  );

  const updateStepPosition = useCallback(
    (oldPosition: number) => {
      const newPosition = dragOverItemIndex;
      const isSamePosition = oldPosition === newPosition;

      if (newPosition === undefined || isSamePosition) {
        return undefined;
      }

      const newSteps = [...listSteps];
      const stepItem = listSteps[oldPosition];

      newSteps.splice(oldPosition, 1);
      newSteps.splice(newPosition, 0, stepItem);

      setListSteps(newSteps);
      return newSteps;
    },
    [dragOverItemIndex, listSteps]
  );

  const onStartDrag = useCallback(
    (event: DragEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>) => {
      const cloneElement = event.currentTarget.cloneNode(true) as HTMLElement;

      cloneElement.style.width = `${event.currentTarget.clientWidth}px`;
      cloneElement.style.height = '50px';
      cloneElement.style.position = 'absolute';
      cloneElement.style.pointerEvents = 'none';
      cloneElement.style.border = '1px solid #E8E8E8';
      cloneElement.style.backgroundColor = 'white';
      cloneElement.style.display = 'flex';
      cloneElement.style.top = `${
        event.currentTarget.offsetTop + event.currentTarget.clientHeight / 2
      }px`;

      event.currentTarget.parentElement?.appendChild(cloneElement);

      itemDraggableRef.current = cloneElement;
    },
    []
  );

  const handleDragStart = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      setIsDragging(true);

      const image = new Image();
      event.dataTransfer.setDragImage(image, 0, 0);

      onStartDrag(event);
    },
    [onStartDrag]
  );

  const handleTouchStart = useCallback((event: TouchEvent<HTMLDivElement>) => {
    setIsDragging(true);

    const touch = event.touches[0];

    initialTouchPosition.current = {
      x: touch.clientX,
      y: touch.clientY,
    };
  }, []);

  const onDrag = useCallback(
    (event: DragEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>) => {
      const cloneElement = itemDraggableRef.current;

      if (!cloneElement) {
        return;
      }

      let clientY = 0;
      const parentClientY =
        event.currentTarget.parentElement?.getBoundingClientRect().y || 0;

      if ('touches' in event) {
        const touch = event.touches[0];
        clientY = touch.clientY - parentClientY;
      } else {
        clientY = event.clientY - parentClientY;
      }

      cloneElement.style.top = `${
        clientY + event.currentTarget.clientHeight / 2
      }px`;
    },
    []
  );

  const handleDragOver = useCallback(
    (event: DragEvent<HTMLDivElement>, stepIndex: number) => {
      setDragOverItemIndex(stepIndex);
    },
    []
  );

  const handleTouchMove = useCallback(
    (event: TouchEvent<HTMLDivElement>, index: number) => {
      const touch = event.touches[0];

      if (itemDraggableRef.current === null) {
        onStartDrag(event);
      }

      const newPosition =
        index + (touch.clientY - initialTouchPosition.current.y) / 50 || 0;

      setDragOverItemIndex(
        Math.min(
          Math.ceil(newPosition > 0 ? newPosition : 0),
          listSteps.length - 1
        )
      );

      onDrag(event);
    },
    [initialTouchPosition, listSteps, onDrag, onStartDrag]
  );

  const handleDrag = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      onDrag(event);
    },
    [onDrag]
  );

  const handleDragEnd = useCallback(
    async (
      event: DragEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>,
      stepIndex: number
    ) => {
      setIsDragging(false);
      setDragOverItemIndex(undefined);

      initialTouchPosition.current = { x: 0, y: 0 };

      const cloneElement = itemDraggableRef.current;

      if (cloneElement) {
        cloneElement.remove();
        itemDraggableRef.current = null;
      }

      const newListSteps = updateStepPosition(stepIndex);

      if (!newListSteps) {
        return;
      }

      const { success } = await handleUpdateSequenceStep(
        newListSteps.map((step, index) => ({
          produtoEtapaId: step.id,
          sequenciaOrdenacao: index + 1,
        }))
      );

      if (success) {
        toast.success('Sequência da etapa alterada com sucesso');
        return;
      }

      await getListSteps();
    },
    [updateStepPosition, handleUpdateSequenceStep, getListSteps]
  );

  useEffect(() => {
    getListSteps();
  }, [getListSteps]);

  return {
    handleRegisterStep,
    handleUpdateStep,
    handleDeleteStep,
    handleDragStart,
    handleDragOver,
    handleDrag,
    handleDragEnd,
    dragOverItemIndex,
    handleTouchStart,
    handleTouchMove,
    isDragging,
    isLoading,
    listSteps,
    readonly,
  };
};

export { useProdutoEtapa };
