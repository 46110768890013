import {
  PagamentosLinkIcon,
  PagamentosCartaoCreditoIcon,
  PagamentosCartaoDebitoIcon,
  PagamentosBoletoIcon,
  PagamentosDepositoIcon,
  PagamentosPixIcon,
  PagamentosTransferenciaBancariaIcon,
} from 'icons';

export const FormaPagamentoEnum = [
  { name: 'Cartão de crédito link', value: 3, icon: PagamentosLinkIcon },
  {
    name: 'Cartão de crédito máquina',
    value: 4,
    icon: PagamentosCartaoCreditoIcon,
  },
  { name: 'Cartão de débito', value: 5, icon: PagamentosCartaoDebitoIcon },

  { name: 'Boleto bancário', value: 9, icon: PagamentosBoletoIcon },
  { name: 'Depósito bancário', value: 11, icon: PagamentosDepositoIcon },
  { name: 'Pagamento instantâneo (PIX)', value: 12, icon: PagamentosPixIcon },
  {
    name: 'Transferência bancária',
    value: 17,
    icon: PagamentosTransferenciaBancariaIcon,
  },
];
