const enumTipoImpressaoGerenciador = {
  VENDAS: 1,
  DELIVERY: 3,
  MESA: 4,
  VALE: 7,
  CAIXA: 9,
  VENDA_ROMANEIO: 11,
  ENTREGA: 18,
  SAT: 19,
  CREDIÁRIO: 24,
  EXCLUIR_VENDA: 27,
  TEF: 28,
  COZINHA_ENTREGA: 29,
  SANGRIA_SUPRIMENTO: 31,
  FICHA: 33,
  COMPROVANTE_VALE: 36,
  NOTA_FISCAL: 37,

  properties: [
    { name: 'Vendas', value: 1 },
    { name: 'Delivery', value: 3 },
    { name: 'Mesa', value: 4 },
    { name: 'Vale', value: 7 },
    { name: 'Caixa', value: 9 },
    { name: 'Venda Romaneio', value: 11 },
    { name: 'Entrega', value: 18 },
    { name: 'SAT', value: 19 },
    { name: 'Crediário', value: 24 },
    { name: 'Excluir Venda', value: 27 },
    { name: 'TEF', value: 28 },
    { name: 'Cozinha Entrega', value: 29 },
    { name: 'Sangria/Suprimento', value: 31 },
    { name: 'Ficha', value: 33 },
    { name: 'Comprovante Vale', value: 36 },
    { name: 'Nota Fiscal', value: 37 },
  ],
};

export default enumTipoImpressaoGerenciador;
