import { Box, Icon, IconButton } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Controller, useFormContext } from 'react-hook-form';
import { FiCopy } from 'react-icons/fi';

import CampoContainer from 'components/PDV/Geral/CampoContainer';

import EditorToolbar, { formats } from './editorToolbar';

type TextEditorProps = {
  name: string;
  label?: string;
  helperText?: string;
  handleClick?: () => void;
};

const TextEditor = ({
  name,
  label,
  helperText,
  handleClick,
}: TextEditorProps) => {
  const [valueText, setValueText] = useState('');

  const quillRef = useRef<ReactQuill | null>(null);

  const modules = {
    toolbar: {
      container: '#toolbar',
    },
  };

  const getText = async () => {
    navigator.clipboard.writeText(valueText);
  };

  useEffect(() => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      quill.on('text-change', () => {
        const plainText = quill.getText();
        setValueText(plainText);
      });
    }
  }, []);

  return (
    <CampoContainer
      id={name}
      position="relative"
      name={name}
      label={label}
      helperText={helperText}
    >
      {handleClick && (
        <Box
          display="flex"
          right="0"
          pt="3px"
          pr="10px"
          justifyContent="flex-end"
          position="absolute"
        >
          <IconButton
            aria-label="Selecionar grade de tamanhos"
            icon={<Icon as={FiCopy} fontSize="xl" />}
            colorScheme="whiteAlpha"
            color="gray.800"
            bg="none"
            onClick={getText}
            w="32px"
            _hover={{ bg: 'gray.100' }}
            _active={{ bg: 'gray.100' }}
            border="1px"
            borderColor="gray.100"
            borderRadius="md"
          />
        </Box>
      )}
      <Controller
        name={`${name}` as const}
        render={({ field: { value, onChange } }) => {
          return (
            <Box>
              <EditorToolbar />
              <ReactQuill
                value={value || ''}
                ref={quillRef}
                onChange={onChange}
                modules={modules}
                formats={formats}
              />

              <style>
                {`
          .ql-toolbar.ql-snow {
            border-radius: 5px 5px 0 0;
            background: #E8E8E8;
            border-bottom: 0px;
          }
          .ql-font-arial {
            font-family: Arial, sans-serif;
          }
          .ql-font-comic-sans {
            font-family: 'Comic Sans MS', cursive;
          }
          .ql-font-courier-new {
            font-family: "Courier New", monospace;
          }
          .ql-font-georgia {
            font-family: "Georgia", serif;
          }
          .ql-font-helvetica {
            font-family: "Helvetica", Arial, sans-serif;
          }
          .ql-font-lucida {
            font-family: "Lucida Console", Monaco, monospace;
          }
          .ql-container.ql-snow {
            border-radius: 0 0 5px 5px;
            height: 192px
          }
          .ql-editor {
            line-height: 1.6rem
          }
          .ql-editor > p, .ql-editor > ol, .ql-editor > ul {
            margin: 13px 0
          }
          `}
              </style>
            </Box>
          );
        }}
      />
    </CampoContainer>
  );
};

export default TextEditor;
