import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export const baseLegalSchema = yup.object().shape({
  id: yup.string(),
  descricao: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .max(255, ConstanteMensagemValidacao.MAX_LENGTH_255),
});

const regraFiscalItemShape = {
  naturezaOperacao: yup
    .string()
    .max(60, ConstanteMensagemValidacao.MAX_LENGTH_60)
    .when(['cstCsosnDentroEstado', 'cstCsosnForaEstado'], {
      is: (cstCsosnDentroEstado, cstCsosnForaEstado) =>
        cstCsosnDentroEstado ||
        cstCsosnDentroEstado === 0 ||
        cstCsosnForaEstado ||
        cstCsosnForaEstado === 0,
      then: (n: any) =>
        n
          .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
          .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
      otherwise: (n: any) => n.nullable(),
    }),
  modalidadeBaseCalculoIcms: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),

  cstCsosnDentroEstado: yup.number().nullable(),
  cfopDentroEstado: yup
    .string()
    .max(5, ConstanteMensagemValidacao.MAX_LENGTH_5)
    .when('cstCsosnDentroEstado', {
      is: (val) => val || val === 0,
      then: (n: any) =>
        n
          .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
          .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
      otherwise: (n: any) => n.nullable(),
    }),
  cfopCupomVinculadoDentroEstado: yup
    .string()
    .max(5, ConstanteMensagemValidacao.MAX_LENGTH_5)
    .nullable(),

  cstCsosnForaEstado: yup.number().nullable(),
  cfopForaEstado: yup
    .string()
    .max(5, ConstanteMensagemValidacao.MAX_LENGTH_5)
    .when('cstCsosnForaEstado', {
      is: (val) => val || val === 0,
      then: (n: any) =>
        n
          .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
          .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
      otherwise: (n: any) => n.nullable(),
    }),
  cfopCupomVinculadoForaEstado: yup
    .string()
    .max(5, ConstanteMensagemValidacao.MAX_LENGTH_5)
    .nullable(),

  pisCofinsCst: yup.number().nullable(),
  basesLegais: yup.array().of(baseLegalSchema).nullable(),
};

const regimeTributarioShape = {
  contribuinte: yup
    .object()
    .shape(regraFiscalItemShape)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  naoContribuinte: yup
    .object()
    .shape(regraFiscalItemShape)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
};

const operacaoSchema = yup.object().shape({
  identificacaoTipoOperacao: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  simplesNacional: yup
    .object()
    .shape(regimeTributarioShape)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  regimeNormal: yup
    .object()
    .shape(regimeTributarioShape)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

const schema = yup.object().shape({
  nome: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .max(60, ConstanteMensagemValidacao.MAX_LENGTH_60),
  ativo: yup.boolean().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  operacoes: yup
    .array()
    .of(operacaoSchema)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);
