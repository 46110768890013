const ConstanteRotas = {
  HOME: '/',

  LOGIN: '/login',
  RECUPERAR_SENHA: '/recuperar-senha',
  REDEFINIR_SENHA: '/redefinir-senha',
  SENHA_ENVIADA_COM_SUCESSO: '/senha-enviada-sucesso',
  SENHA_REDEFINIDA_COM_SUCESSO: '/senha-redefinida-sucesso',
  RECUPERAR_DOMINIO: '/recuperar-dominio',
  DOMINIO_ENVIADO_COM_SUCESSO: '/dominio-enviado-sucesso',

  DASHBOARD: '/dashboard',

  CLIENTE: '/clientes',
  CLIENTE_ALTERAR: '/clientes/alterar/:id',
  CLIENTE_VISUALIZAR: '/clientes/visualizar/:id',
  CLIENTE_CADASTRAR: '/clientes/cadastrar',

  FORNECEDOR: '/fornecedores',
  FORNECEDOR_ALTERAR: '/fornecedores/alterar/:id',
  FORNECEDOR_VISUALIZAR: '/fornecedores/visualizar/:id',
  FORNECEDOR_CADASTRAR: '/fornecedores/cadastrar',

  VENDEDOR: '/vendedores',
  VENDEDOR_CADASTRAR: '/vendedores/cadastrar',
  VENDEDOR_ALTERAR: '/vendedores/alterar/:id',
  VENDEDOR_VISUALIZAR: '/vendedores/visualizar/:id',

  TRANSPORTADORA: '/transportadoras',
  TRANSPORTADORA_CADASTRAR: '/transportadoras/cadastrar',
  TRANSPORTADORA_ALTERAR: '/transportadoras/alterar/:id',
  TRANSPORTADORA_VISUALIZAR: '/transportadoras/visualizar/:id',

  PRODUTO: '/produtos',
  PRODUTO_CADASTRAR: '/produtos/cadastrar',
  PRODUTO_CADASTRAR_DUPLICAR: '/produtos/cadastrar/:id',
  PRODUTO_ALTERAR: '/produtos/alterar/:id',
  PRODUTO_VISUALIZAR: '/produtos/visualizar/:id',

  RELATORIO_PRODUTOS_COM_PRECO: '/relatorios/produto-preco',
  RELATORIO_CATALOGO_PRODUTOS: '/relatorios/catalogo-produtos',
  RELATORIO_PRODUTOS_VENDIDOS: '/relatorios/produtos-vendidos',
  RELATORIO_PRODUTOS_PERSONALIZADOS: '/relatorios/produtos-personalizados',
  RELATORIO_PRODUTOS_CADASTRO: '/cadastro/relatorio-produtos-personalizado',
  RELATORIO_CURVA_ABC: '/relatorios/curva-abc',
  RELATORIO_CONSIGNACAO: '/relatorios/consignacao',
  RELATORIO_DAS_VENDAS: '/relatorios/vendas',
  RELATORIO_INFORMACOES_CLIENTES: '/informacoes-clientes',

  OPERACOES: '/operacoes',
  OPERACOES_DETALHE: '/operacoes/detalhe/:id',
  OPERACOES_EMITIR_NOTA_FISCAL_NFE: '/operacoes/emitir-nota-fiscal/nfe/:id',
  OPERACOES_EMITIR_NOTA_FISCAL_NFCE: '/operacoes/emitir-nota-fiscal/nfce/:id',
  LISTAR_VOUCHERS: '/vouchers',

  METAS_E_COMISSOES: '/metas-comissoes',
  CADASTRAR_METAS_E_COMISSOES: '/metas-comissoes/cadastrar',

  HISTORICO_MOVIMENTACAO: '/historico-movimentacao',

  CONTAS_RECEBER: '/contas-receber',
  CONTAS_RECEBER_CADASTRAR_LANCAMENTO: '/contas-receber/lancamento',
  CONTAS_RECEBER_ALTERAR_LANCAMENTO: '/contas-receber/lancamento/:id',
  CONTAS_RECEBER_VISUALIZAR: '/contas-receber/visualizar/:id',

  CONCILIACAO_CONTAS: '/conciliacao-contas',
  RECEBIMENTO_CONTAS: '/recebimento-contas',

  CONTAS_PAGAR: '/contas-pagar',
  CONTAS_PAGAR_CADASTRAR_LANCAMENTO: '/contas-pagar/lancamento',
  CONTAS_PAGAR_ALTERAR_LANCAMENTO: '/contas-pagar/lancamento/:id',
  CONTAS_PAGAR_VISUALIZAR: '/contas-pagar/visualizar/:id',

  CONTROLE_CAIXA: '/controle-caixa',
  CONTROLE_CAIXA_DETALHES: '/controle-caixa/detalhes/:id',

  EXPORTAR_XML: '/documentos-fiscais-exportacao',

  RELATORIO_VENDAS: '/relatorios-vendas',
  RELATORIO_INVENTARIO: '/relatorios-inventario',
  RELATORIO_CLIENTES: '/relatorios-personalizados-clientes',
  RELATORIO_CADASTRO_CLIENTES: '/cadastro-relatorio-clientes',
  RELATORIO_CADASTRO_COMPLETO: '/relatorio-cadastro-completo-clientes',
  RELATORIO_ESTOQUE: '/relatorio-estoque',

  STI3PAY_SIMULACAO_VENDAS: '/sti3pay-simulacao-vendas',
  STI3PAY_HISTORICO: '/sti3pay-historico',

  MULTILOJA_DASHBOARD: '/multiloja-dashboard',

  LOJA_APLICATIVOS: '/loja-aplicativos',
  TRAY: '/tray',

  ZOOP_DETALHES_INTEGRACAO: '/integracoes/detalhes-zoop',
  ZOOP: '/loja-aplicativos/zoop',
  ZOOP_TELA_COMERCIAL: '/integracoes-zoop/zoop',

  SMART_POS: '/loja-aplicativos/smart-pos',
  SMART_POS_LISTAGEM: '/vendas/smart-pos',
  SMART_POS_DETALHES_INTEGRACAO: '/integracoes/detalhes-smart-pos',
  DASHBOARD_GERENCIAL: '/loja-aplicativos/dashboard-gerencial',
  DASHBOARD_GERENCIAL_DETALHES: '/integracoes/dashboard-gerencial-detalhes',

  MINHAS_LOJAS: '/minhas-lojas',
  MULTAS_JUROS: '/multas-juros',
  LOJA_CADASTRAR: '/loja/cadastrar',
  LOJA_ALTERAR: '/loja/alterar/:id',
  LOJA_VISUALIZAR: '/loja/visualizar/:id',

  CATEGORIA_CLIENTE: '/categoria-clientes',
  CATEGORIA_CLIENTE_CADASTRAR: '/categoria-clientes/cadastrar',
  CATEGORIA_CLIENTE_ALTERAR: '/categoria-clientes/alterar/:id',
  CATEGORIA_CLIENTE_VISUALIZAR: '/categoria-clientes/visualizar/:id',

  IMPORTAR_CLIENTES: '/importar-clientes',
  IMPORTAR_CLIENTES_IMPORTAR: '/importar-clientes/importar',

  IMPORTAR_FORNECEDORES: '/importar-fornecedores',
  IMPORTAR_FORNECEDORES_IMPORTAR: '/importar-fornecedores/importar',

  IMPORTAR_PRODUTOS: '/importar-produtos',
  IMPORTAR_PRODUTOS_IMPORTAR: '/importar-produtos/importar',

  ABRIR_IMPRESSAO_PDF: '/impressao-pdf',

  TABELA_PRECOS: '/tabela-precos',
  TABELA_PRECO_CADASTRAR: '/tabela-precos/cadastrar',
  TABELA_PRECO_ALTERAR: '/tabela-precos/alterar/:id',
  TABELA_PRECO_VISUALIZAR: '/tabela-precos/visualizar/:id',

  UNIDADE_MEDIDA: '/unidades-medida',
  UNIDADE_MEDIDA_CADASTRAR: '/unidades-medida/cadastrar',
  UNIDADE_MEDIDA_VISUALIZAR: '/unidades-medida/visualizar/:id',
  UNIDADE_MEDIDA_ALTERAR: '/unidades-medida/alterar/:id',

  MARCA: '/marcas',
  MARCA_CADASTRAR: '/marcas/cadastrar',
  MARCA_VISUALIZAR: '/marcas/visualizar/:id',
  MARCA_ALTERAR: '/marcas/alterar/:id',

  COR: '/cores',
  COR_CADASTRAR: '/cores/cadastrar',
  COR_VISUALIZAR: '/cores/visualizar/:id',
  COR_ALTERAR: '/cores/alterar/:id',

  TAMANHO: '/tamanhos',
  TAMANHO_CADASTRAR: '/tamanhos/cadastrar',
  TAMANHO_ALTERAR: '/tamanhos/alterar/:id',
  TAMANHO_VISUALIZAR: '/tamanhos/visualizar/:id',

  LOCAL_ESTOQUE: '/local-estoque',
  LOCAL_ESTOQUE_CADASTRAR: '/local-estoque/cadastrar',
  LOCAL_ESTOQUE_ALTERAR: '/local-estoque/alterar/:id',
  LOCAL_ESTOQUE_VISUALIZAR: '/local-estoque/visualizar/:id',

  ETIQUETAS_PERSONALIZADAS: '/etiqueta-personalizada',
  ETIQUETAS_IMPRIMIR: '/etiqueta-personalizada/imprimir',
  ETIQUETAS_PERSONALIZADAS_CADASTRAR: '/etiqueta-personalizada/cadastrar',
  ETIQUETA_ALTERAR: '/etiqueta-personalizada/alterar/:id',
  ETIQUETA_VISUALIZAR: '/etiqueta-personalizada/visualizar/:id',

  MOVIMENTACAO_ESTOQUE: '/movimentacao-estoque',
  MOVIMENTACAO_ESTOQUE_CADASTRAR: '/movimentacao-estoque/cadastrar',
  MOVIMENTACAO_ESTOQUE_ALTERAR: '/movimentacao-estoque/alterar/:id',
  MOVIMENTACAO_ESTOQUE_VISUALIZAR: '/movimentacao-estoque/visualizar/:id',

  TRANSFERENCIA_ESTOQUE: '/transferencia-estoque',
  TRANSFERENCIA_ESTOQUE_CADASTRAR: '/transferencia-estoque/cadastrar/:id?',
  TRANSFERENCIA_ESTOQUE_ALTERAR: '/transferencia-estoque/alterar/:id',
  TRANSFERENCIA_ESTOQUE_VISUALIZAR: '/transferencia-estoque/visualizar/:id',

  HISTORICO_PRODUTO: '/historico-produto',

  CONFERENCIA_ESTOQUE: '/conferencia-estoque',
  CONFERENCIA_ESTOQUE_CONTINUAR: '/conferencia-estoque/:id',
  CONFERENCIA_ESTOQUE_FINALIZAR: '/conferencia-estoque/finalizar',

  CONTA_FINANCEIRA: '/conta-financeira',
  CONTA_FINANCEIRA_CADASTRAR: '/conta-financeira/cadastrar',
  CONTA_FINANCEIRA_VISUALIZAR: '/conta-financeira/visualizar/:id',
  CONTA_FINANCEIRA_ALTERAR: '/conta-financeira/alterar/:id',

  PLANO_CONTAS: '/plano-contas',

  FORMA_PAGTO: '/formas-pagamento',
  FORMA_PAGTO_CADASTRAR: '/formas-pagamento/cadastrar',
  FORMA_PAGTO_ALTERAR: '/formas-pagamento/alterar/:id',
  FORMA_PAGTO_EXCLUIR: '/formas-pagamento/excluir',
  FORMA_PAGTO_LISTAR: '/formas-pagamento/listar',
  FORMA_PAGTO_VISUALIZAR: '/formas-pagamento/visualizar/:id',

  FORMA_RECEB: '/formas-recebimento',
  FORMA_RECEB_CADASTRAR: '/formas-recebimento/cadastrar',
  FORMA_RECEB_ALTERAR: '/formas-recebimento/alterar/:id',
  FORMA_RECEB_EXCLUIR: '/formas-recebimento/excluir',
  FORMA_RECEB_LISTAR: '/formas-recebimento/listar',
  FORMA_RECEB_VISUALIZAR: '/formas-recebimento/visualizar/:id',

  REGRA_FISCAL: '/regra-fiscal',
  REGRA_FISCAL_ALTERAR: '/regra-fiscal/alterar/:id',
  REGRA_FISCAL_VISUALIZAR: '/regra-fiscal/visualizar/:id',
  REGRA_FISCAL_CADASTRAR: '/regra-fiscal/cadastrar',

  OPERACAO: '/operacoes',
  COMPARTILHAR_EXTERNO: '/compartilhar/externo/:id',

  PADRONIZACAO: '/padronizacao',

  HISTORICO_ACOES: '/historico-acoes',
  HISTORICO_ACOES_VISUALIZAR: '/historico-acoes/visualizar/:id',

  CATEGORIA_PRODUTO: '/categoria-produtos',

  NOTA_FISCAL: '/notas-fiscais',
  NOTA_FISCAL_CADASTRAR: '/notas-fiscais/cadastrar',
  NOTA_FISCAL_ALTERAR: '/notas-fiscais/alterar/:id',
  NOTA_FISCAL_VISUALIZAR: '/notas-fiscais/visualizar/:id',

  PDV_OFFLINE: '/integracao-pdv/pdv-offline-configuracoes',
  PDV_OFFLINE_TELA_COMERCIAL: '/integracao-pdv/pdv-offline',
  PDV_OFFLINE_DETALHES: '/integracao-pdv/pdv-offline-detalhes',

  FRENTE_CAIXA: '/frente-caixa',
  FRENTE_CAIXA_ATIVACAO: '/frente-caixa/ativacao',
  FRENTE_CAIXA_CONFIGURACAO: '/frente-caixa/configuracao',
  FRENTE_CAIXA_DEPARTAMENTOS: '/frente-caixa/departamento',
  FRENTE_CAIXA_PAINEL: '/frente-caixa/painel-controle',
  FRENTE_CAIXA_DADOS: '/frente-caixa/dados',
  FRENTE_CAIXA_COMERCIAL: '/frente-caixa/comercial',
  FRENTE_CAIXA_DETALHES: '/frente-caixa/detalhes',
  FRENTE_CAIXA_DEPARTAMENTO_CADASTRAR: '/frente-caixa/departamento/cadastrar',
  FRENTE_CAIXA_DEPARTAMENTO_ALTERAR: '/frente-caixa/departamento/alterar/:id',
  FRENTE_CAIXA_DISPOSITIVOS: '/frente-caixa/dispositivos',
  FRENTE_CAIXA_ENTREGADORES: '/frente-caixa/entregador',
  FRENTE_CAIXA_ENTREGADOR_CADASTRAR: '/frente-caixa/entregador/cadastrar',
  FRENTE_CAIXA_ENTREGADOR_ALTERAR: '/frente-caixa/entregador/alterar/:id',
  FRENTE_CAIXA_FORMAS_RECEBIMENTO: '/frente-caixa/formas-recebimento',
  FRENTE_CAIXA_GERENCIADORES_IMPRESSAO: '/frente-caixa/gerenciadores-impressao',
  FRENTE_CAIXA_SETORES_ENTREGA: '/frente-caixa/setor-entrega',
  FRENTE_CAIXA_SETOR_ENTREGA_CADASTRAR: '/frente-caixa/setor-entrega/cadastrar',
  FRENTE_CAIXA_SETOR_ENTREGA_ALTERAR: '/frente-caixa/setor-entrega/alterar/:id',
  FRENTE_CAIXA_INTERMEDIADOR: '/frente-caixa/intermediador',
  FRENTE_CAIXA_INTERMEDIADOR_ALTERAR: '/frente-caixa/intermediador/alterar/:id',
  FRENTE_CAIXA_INTERMEDIADOR_CADASTRAR: '/frente-caixa/intermediador/cadastrar',
  FRENTE_CAIXA_PERIODO_CAIXA: '/frente-caixa/periodo-caixa',
  FRENTE_CAIXA_PERIODO_CAIXA_CADASTRAR: '/frente-caixa/periodo-caixa/cadastrar',
  FRENTE_CAIXA_PERIODO_CAIXA_ALTERAR: '/frente-caixa/periodo-caixa/alterar/:id',
  FRENTE_CAIXA_RELATORIO_PERSONALIZADO: '/frente-caixa/relatorio-personalizado',
  FRENTE_CAIXA_RELATORIO_PERSONALIZADO_CADASTRAR:
    '/frente-caixa/relatorio-personalizado/cadastrar',
  FRENTE_CAIXA_RELATORIO_PERSONALIZADO_ALTERAR:
    '/frente-caixa/relatorio-personalizado/alterar/:id',

  CREDENCIADORA_CARTAO: '/credenciadora-cartao',
  CREDENCIADORA_CARTAO_ALTERAR: '/credenciadora-cartao/alterar/:id',
  CREDENCIADORA_CARTAO_VISUALIZAR: '/credenciadora-cartao/visualizar/:id',
  CREDENCIADORA_CARTAO_CADASTRAR: '/credenciadora-cartao/cadastrar',

  PERFIL_USUARIO: '/perfil-usuarios',
  PERFIL_USUARIO_CADASTRAR: '/perfil-usuarios/cadastrar',
  PERFIL_USUARIO_VISUALIZAR: '/perfil-usuarios/visualizar/:id',
  PERFIL_USUARIO_ALTERAR: '/perfil-usuarios/alterar/:id',

  USUARIO: '/usuarios',
  USUARIO_CADASTRAR: '/usuarios/cadastrar',
  USUARIO_VISUALIZAR: '/usuarios/visualizar/:id',
  USUARIO_ALTERAR: '/usuarios/alterar/:id',

  CAMPO_PERSONALIZADO: '/campos-personalizados',
  CAMPO_PERSONALIZADO_CADASTRAR: '/campos-personalizados/cadastrar',
  CAMPO_PERSONALIZADO_VISUALIZAR: '/campos-personalizados/visualizar/:id',
  CAMPO_PERSONALIZADO_ALTERAR: '/campos-personalizados/alterar/:id',

  DOCUMENTO_FISCAL_EXPORTACAO_DOWNLOAD_EXTERNO:
    '/documentos-fiscais-exportacao/externo/:id/:hashIdentificador',
  DOCUMENTO_FISCAL_EXPORTACAO: '/documentos-fiscais-exportacao',

  ENTRADA_MERCADORIA: '/entrada-mercadoria',
  ENTRADA_MERCADORIA_ENTRADA_MANUAL: '/entrada-mercadoria/entrada-manual/:id?',
  ENTRADA_MERCADORIA_LANCAR_ESTOQUE: '/entrada-mercadoria/lancar-estoque/:id',
  ENTRADA_MERCADORIA_ENTRADA_MANUAL_VISUALIZAR:
    '/entrada-mercadoria/entrada-manual/visualizar/:id',
  ENTRADA_MERCADORIA_ENTRADA_IMPORTACAO_INICIAR:
    '/entrada-mercadoria/entrada-importacao/iniciar',
  ENTRADA_MERCADORIA_ENTRADA_IMPORTACAO_CONTINUAR:
    '/entrada-mercadoria/entrada-importacao/continuar/:id',
  ENTRADA_MERCADORIA_ENTRADA_IMPORTACAO_FINALIZAR:
    '/entrada-mercadoria/entrada-importacao/finalizar',

  EXTRATOS: '/extratos',
  EXTRATOS_DETALHES: '/extratos/detalhes/:id',
  EXTRATOS_LANCAMENTO: '/extratos/lancamento/:id',

  PROMOCAO: '/promocao-listar',
  PROMOCAO_CADASTRAR: '/promocao/cadastrar',
  PROMOCAO_ALTERAR: '/promocao/alterar/:id',
  PROMOCAO_VISUALIZAR: '/promocao/visualizar/:id',

  FATURAS: '/faturas',
  FATURAS_DETALHES: '/faturas/detalhes/:id',

  MANIFESTACAO_DESTINATARIO: '/manifestacao-destinatario',

  INTEGRACAO_TRAY_COMMERCE_VENDAS: '/integracoes-tray/tray-commerce/vendas',
  INTEGRACAO_TRAY_COMMERCE_CATEGORIAS:
    '/integracoes-tray/tray-commerce/categorias',
  INTEGRACAO_TRAY_COMMERCE_PRODUTO: '/integracoes-tray/tray-commerce/produto',
  INTEGRACAO_TRAY_COMMERCE_FORMA_RECEBIMENTO:
    '/integracoes-tray/tray-commerce/forma-recebimento',
  INTEGRACAO_TRAY_COMMERCE_CONFIGURACAO:
    '/integracoes-tray/tray-commerce/configuracao',
  INTEGRACAO_TRAY_COMMERCE_VINCULAR_COR:
    '/integracoes-tray/tray-commerce/cores',
  INTEGRACAO_TRAY_COMMERCE_VINCULAR_TAMANHO:
    '/integracoes-tray/tray-commerce/tamanhos',
  INTEGRACAO_TRAY_COMMERCE_VINCULAR_MARCAS:
    '/integracoes-tray/tray-commerce/marcas',
  INTEGRACAO_TRAY_COMMERCE_PAINEL_ADM:
    '/integracoes-tray/tray-commerce-painel-controle',
  INTEGRACAO_TRAY_TELA_COMERCIAL: '/integracoes-tray/tray-commerce',
  INTEGRACAO_TRAY_DETALHES: '/integracoes-tray/detalhes-tray-commerce',

  INTEGRACAO_ZOOP_GERAL: '/integracoes/zoop/geral',

  AUTO_ATENDIMENTO_TEMA: '/auto-atendimento/tema',
};

export default ConstanteRotas;

export const ConstanteRotasAlternativas = {
  HISTORICO_VENDAS_METAS_COMISSOES: '/metas-comissoes/historico-vendas',
  RELATORIO_VENDAS_METAS_COMISSOES: '/metas-comissoes/relatorio-vendas',

  SMART_POS_ETAPA: '/loja-aplicativos/smart-pos/configuracao',

  TRAY_ETAPAS: '/integracoes/tray-commerce/configuracao-tray',

  ZOOP_CONFIGURACAO: '/integracoes/zoop/configuracao',
  ZOOP_CREDENCIAMENTO_ENVIADO: '/integracoes/zoop/credenciamento-enviado',
};

export const SubstituirParametroRota = (
  route: string,
  paramName: string,
  value: string
) => {
  return route.replace(`:${paramName}`, value);
};
