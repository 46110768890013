import {
  Flex,
  Box,
  Icon,
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  useMediaQuery,
  Collapse,
} from '@chakra-ui/react';
import { useState } from 'react';
import {
  FiChevronRight,
  FiChevronDown,
  FiChevronUp,
  FiChevronLeft,
} from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import { styleTabSelected } from 'theme/components/tabs';
import ConstanteRotas from 'constants/rotas';
import { useTrayPainelControleContext } from 'store/Tray/PainelControle';

import { ContainerHeader } from 'pages/Integracoes/LojaAplicativos/Layout/ContainerHeader';

import { valueTabs } from './validationForms';

export const ConfiguracaoTray = () => {
  const [exibirConfiguracao, setExibirConfiguracao] = useState(-1);
  const [tabSelecionada, setTabSelecionada] = useState(0);

  const { avisoProcessoEmAndamento } = useTrayPainelControleContext();

  const possuiAvisoProcessoEmAndamento = avisoProcessoEmAndamento.exibirAviso;

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const history = useHistory();

  function handleExibirConfiguracao(index: number) {
    setExibirConfiguracao(exibirConfiguracao === index ? -1 : index);
  }

  function handleVoltarPainelAdm() {
    history.push(ConstanteRotas.INTEGRACAO_TRAY_COMMERCE_PAINEL_ADM);
  }

  return (
    <>
      <ContainerHeader justifyContent="space-between" h="56px">
        <Flex color="white" alignItems="center">
          <Box cursor="pointer" mr="10px" onClick={handleVoltarPainelAdm}>
            <Icon fontSize="25px" as={FiChevronLeft} />
          </Box>
          <Text fontSize="16px" ml={['0', '0', '10px']}>
            Configurações
          </Text>
        </Flex>
      </ContainerHeader>
      <Box mt="24px" px="24px">
        {isLargerThan900 ? (
          <Tabs
            onChange={(index) => setTabSelecionada(index)}
            boxShadow="0px 0px 6px #00000034"
            bg="white"
            borderRadius="5px"
            minH="484px"
            variant="clean"
          >
            <Flex h="full">
              <TabList mt="16px" w="32%">
                <Box w="100%">
                  {valueTabs.map((tabs, index) => (
                    <Tab
                      _selected={{
                        ...styleTabSelected,
                        background: 'gray.100',
                      }}
                      h="64px"
                      key={tabs.label}
                      w="100%"
                    >
                      <Flex
                        justifyContent="space-between"
                        w="100%"
                        alignItems="center"
                        pl="16px"
                      >
                        <Text>{tabs.label}</Text>
                        {tabSelecionada === index && (
                          <Icon as={FiChevronRight} />
                        )}
                      </Flex>
                    </Tab>
                  ))}
                </Box>
              </TabList>

              <TabPanels
                bg="gray.100"
                ml="-1px"
                borderRadius="0 5px 0 0"
                w="full"
              >
                {valueTabs.map((tab, index) => (
                  <TabPanel
                    key={tab.label}
                    w="full"
                    bg="gray.100"
                    padding="0px"
                  >
                    {possuiAvisoProcessoEmAndamento &&
                      index === avisoProcessoEmAndamento.exibirNaTab && (
                        <Flex
                          bg="yellow.300"
                          color="black"
                          justify="center"
                          align="center"
                          minH="50px"
                          fontSize="14px"
                        >
                          {avisoProcessoEmAndamento.textoAviso}
                        </Flex>
                      )}
                    <Flex pl="44px" mt="6px" h="98%">
                      {tab.content}
                    </Flex>
                  </TabPanel>
                ))}
              </TabPanels>
            </Flex>
          </Tabs>
        ) : (
          <Box
            pb="40px"
            borderRadius="5px"
            boxShadow="0px 0px 6px #00000034"
            bg="white"
            pt="30px"
          >
            {valueTabs.map((tabs, index) => (
              <Box
                px="24px"
                pb="20px"
                bg={exibirConfiguracao === index ? 'gray.50' : 'white'}
              >
                <Flex
                  cursor="pointer"
                  pt="24px"
                  justifyContent="space-between"
                  onClick={() => handleExibirConfiguracao(index)}
                  w="100%"
                  alignItems="center"
                >
                  <Text fontSize="16px">{tabs.label}</Text>
                  <Icon
                    fontSize="20px"
                    as={
                      exibirConfiguracao === index ? FiChevronUp : FiChevronDown
                    }
                  />
                </Flex>
                <Collapse in={exibirConfiguracao === index} animateOpacity>
                  <Box overflowX="auto">{tabs.content}</Box>
                </Collapse>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </>
  );
};
