import { useCallback, useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import ConstanteRotas from 'constants/rotas';
import { useTabObserver } from 'hooks/useTabObserver';
import { enumIdentificacaoSituacao } from 'constants/enum/enumIdentificacaoSituacao';
import { enumTabsConfiguracaoTray } from 'constants/enum/enumTabsConfiguracaoTray';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { enumIdentificacaoIntegracao } from 'constants/enum/enumIdentificacaoIntegracao';
import api, { ResponseApi } from 'services/api';
import { useTrayPainelControleContext } from 'store/Tray/PainelControle';

import { ModalAlterarStatusTray } from './Modal/ModalAlterarStatusTray';

type FormData = {
  operacaoSistema: number[];
};

type SituacaoResponseProps = {
  id: string;
  nome: string;
  situacaoSistema: null | number;
  padraoSistema?: boolean;
  integracaoSituacaoId: string;
};

type SituacaoVinculadaProps = {
  integracaoSituacaoId: string;
  descricaoSituacaoIntegracao: string;
  situacaoSistema: number;
};

export const useSituacaoConfig = () => {
  const [listTodasSituacoes, setListTodasSituacoes] = useState<
    SituacaoResponseProps[]
  >([]);
  const { dadosTray, isLoading, setIsLoading } = useTrayPainelControleContext();

  const formMethods = useForm<FormData>();

  const atualizarSituacoes = useCallback(
    (situacaoAlterada: SituacaoResponseProps) => {
      setListTodasSituacoes((prevState) => {
        return prevState.map((item) => {
          if (item.id === situacaoAlterada.id) {
            return situacaoAlterada;
          }
          return item;
        });
      });
    },
    []
  );

  const buscarSituacoesTray = useCallback(async () => {
    const response = await api.get<void, ResponseApi<SituacaoResponseProps[]>>(
      ConstanteEnderecoWebservice.INTEGRACAO_TRAY_OPERACOES_VENDA,
      {
        params: {
          integracao: enumIdentificacaoIntegracao.TRAY,
        },
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso && response.dados) {
        return response.dados;
      }
    }
    return [];
  }, []);

  const buscarSituacoes = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get<void, ResponseApi<SituacaoVinculadaProps[]>>(
      `${ConstanteEnderecoWebservice.INTEGRACAO_TRAY_SITUACAO}/${dadosTray?.id}`
    );
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }
      if (response.sucesso && response.dados.length > 0) {
        const situacoes = await buscarSituacoesTray();
        const novaListaSituacao = response.dados.map((itemSituacao, index) => {
          return {
            id: itemSituacao.integracaoSituacaoId,
            nome: itemSituacao.descricaoSituacaoIntegracao,
            situacaoSistema: itemSituacao.situacaoSistema,
            padraoSistema: situacoes[index].padraoSistema,
            integracaoSituacaoId: situacoes[index].id,
          };
        });
        setListTodasSituacoes(novaListaSituacao);

        setIsLoading(false);
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [buscarSituacoesTray, dadosTray?.id, setIsLoading]);

  useEffect(() => {
    buscarSituacoes();
  }, [buscarSituacoes]);

  const situacoesMapeadas: Record<string | number | symbol, string> = {
    [enumIdentificacaoSituacao.PEDIDO]: 'listSituacoesPedido',
    [enumIdentificacaoSituacao.EM_ABERTO]: 'listSituacoesPedido',
    [enumIdentificacaoSituacao.VENDA]: 'listSituacoesVenda',
    [enumIdentificacaoSituacao.CANCELAMENTO]: 'listSituacoesCancelamento',
    null: 'listSituacoesNaoVinculada',
  };

  const array = listTodasSituacoes.reduce(
    (acc: Record<string, any[]>, item) => {
      const key = situacoesMapeadas[item.situacaoSistema ?? 'null'];
      if (key) {
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(item);
      }
      return acc;
    },
    {}
  );

  const listSituacoesPedido = array.listSituacoesPedido || [];
  const listSituacoesVenda = array.listSituacoesVenda || [];
  const listSituacoesCancelamento = array.listSituacoesCancelamento || [];
  const listSituacoesNaoVinculada = useMemo(
    () => array.listSituacoesNaoVinculada || [],
    [array.listSituacoesNaoVinculada]
  );

  const history = useHistory();

  const voltarAoPainel = useCallback(() => {
    history.push(ConstanteRotas.INTEGRACAO_TRAY_COMMERCE_PAINEL_ADM);
  }, [history]);

  const abrirModalNovaSituacao = useCallback(() => {
    ModalAlterarStatusTray({
      trayId: dadosTray.id,
      situacao: listSituacoesNaoVinculada,
      podeCancelar: false,
      voltarAoPainel,
      atualizarSituacoes,
    });
  }, [
    atualizarSituacoes,
    dadosTray.id,
    listSituacoesNaoVinculada,
    voltarAoPainel,
  ]);

  const abrirModalAlterarSituacao = useCallback(
    (situacao: SituacaoResponseProps) => {
      ModalAlterarStatusTray({
        trayId: dadosTray.id,
        situacao: [situacao],
        podeCancelar: true,
        atualizarSituacoes,
      });
    },
    [atualizarSituacoes, dadosTray.id]
  );

  useTabObserver({
    tabIndex: enumTabsConfiguracaoTray.SITUACAO?.toString(),
    onTabSelected: abrirModalNovaSituacao,
    conditionToTrigger: listSituacoesNaoVinculada?.length > 0,
  });

  return {
    formMethods,
    isLoading,
    listTodasSituacoes,
    listSituacoesPedido,
    listSituacoesVenda,
    listSituacoesCancelamento,
    listSituacoesNaoVinculada,
    abrirModalNovaSituacao,
    abrirModalAlterarSituacao,
  };
};
