import { Text, VStack, Box } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import {
  ContainerIntegracaoFixo,
  useSmartPosEtapasContext,
} from 'store/SmartPos/SmartPosEtapasContext';
import ConstanteRotas from 'constants/rotas';
import { IdentificacaoEtapasSmartPos } from 'constants/enum/IdentificacaoEtapasSmartPos';

import { ConfirmarIcon } from 'icons';

import { FooterSmartPos } from '../../Layout/FooterSmartPos';
import { HeaderSmartPos } from '../../Layout/Header';

export const SucessoFormStone = () => {
  const history = useHistory();

  const { setActiveStep } = useSmartPosEtapasContext();

  const handleIniciarConfiguracao = () => {
    setActiveStep(IdentificacaoEtapasSmartPos.AUTENTICACAO);
  };

  const handleSairAguardar = () => {
    history.push(ConstanteRotas.DASHBOARD);
  };

  return (
    <>
      <ContainerIntegracaoFixo>
        <HeaderSmartPos title="Enviado com sucesso!" icon={ConfirmarIcon} />
        <VStack
          pl={['0', '0', '20px']}
          pr={['0', '0', '20px']}
          w="full"
          color="primary.50"
          fontSize="14px"
          spacing={['10px', '10px', '24px']}
        >
          <Text w="full" textAlign="left">
            Feito! Agora é só aguardar o contato do time de especialistas da
            Stone.
          </Text>

          <Text>
            Se preferir, você pode iniciar a configuração do sistema agora
            mesmo. Independente dos detalhes da operadora, é possível adiantar a
            definição dos produtos, categorias e formas de recebimento, deixando
            pendente apenas a configuração das maquininhas.
          </Text>
        </VStack>
      </ContainerIntegracaoFixo>
      <FooterSmartPos
        mt={['20px', '0', '0']}
        mb={['20px', '0', '0']}
        handleSair={handleSairAguardar}
        handleAvancar={handleIniciarConfiguracao}
      />
    </>
  );
};
