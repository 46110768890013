import React, { useState, useEffect, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Prompt, RouteComponentProps, useHistory } from 'react-router-dom';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteRotas from 'constants/rotas';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import isPrenvent from 'helpers/layout/isPrenvent';
import auth from 'modules/auth';
import StatusOperacaoTransferenciaEnum from 'constants/enum/statusOperacaoTransferencia';
import { obterTransferenciaEstoque as apiObterTransferenciaEstoque } from 'helpers/api/TransferenciaEstoque/obterTransferenciaEstoque';

import { ModalConfirmacao } from 'components/Modal/ModalConfirmacao';
import { ModalSucesso } from 'components/Modal/ModalSucesso';
import ManterFoco from 'components/Geral/ManterFoco';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { FormFooter } from 'components/update/Form/FormFooter';

import Formulario, { ForwardRefProps } from '..';
import { yupResolver, FormData } from '../validationForm';

type TParams = { id: string };

const Cadastrar = ({ match }: RouteComponentProps<TParams>) => {
  const history = useHistory();
  const { nome: nomeUsuario } = auth.getUsuario();
  const { id: lojaId, fantasia: lojaFantasia } = auth.getLoja();
  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues: {
      usuario: nomeUsuario,
      dataEmissao: new Date().toLocaleDateString('pt-BR'),
      lojaOrigem: lojaFantasia,
    },
  });

  const transferenciaEstoqueId = match.params.id;

  const isMountedRef = useIsMountedRef();

  const lojaDestino = formMethods.watch('lojaDestino');
  const isTranferenciaEntreEstoque =
    lojaDestino && lojaDestino.value === lojaId;

  const formularioRef = useRef<ForwardRefProps>(null);

  const { dirtyFields } = formMethods.formState;
  const isDirty =
    dirtyFields &&
    dirtyFields.constructor === Object &&
    Object.keys(dirtyFields).length > 0;

  const [isPrenvented, setIsPrenvented] = useState(false);
  isPrenvent(isPrenvented);

  const [isLoading, setIsLoading] = useState(false);

  function historyPush(path: string) {
    setIsPrenvented(false);

    history.push(path);
  }

  function historyReload() {
    setIsPrenvented(false);

    history.replace('/');
    history.replace(ConstanteRotas.TRANSFERENCIA_ESTOQUE_CADASTRAR);
  }

  async function cadastrarTransferenciaEstoque(
    data: FormData,
    isRascunho?: boolean
  ) {
    if (formularioRef.current) {
      if (formularioRef.current.produtos.length) {
        const { motivo, localEstoqueOrigem, localEstoqueDestino } = data;

        const listaItemAdicionar = formularioRef.current.produtos.map(
          ({ produtoCorTamanhoId, quantidade }) => ({
            produtoCorTamanhoId,
            quantidade,
          })
        );

        const response = await api.post<void, ResponseApi>(
          ConstanteEnderecoWebservice.TRANSFERENCIA_ESTOQUE_CADASTRAR,
          {
            motivo,
            localEstoqueOrigemId: localEstoqueOrigem?.value,
            localEstoqueDestinoId: localEstoqueDestino?.value,
            listaItemAdicionar,
            status: isRascunho
              ? StatusOperacaoTransferenciaEnum.RASCUNHO
              : StatusOperacaoTransferenciaEnum.PENDENTE,
          }
        );

        if (response) {
          if (response.avisos) {
            response.avisos.map((item: string) => toast.warning(item));
          }

          if (response.sucesso) {
            return true;
          }
        }
      } else {
        toast.warning(
          'Adicione ao menos um produto para cadastrar a transferência.'
        );

        formularioRef.current.handleSetProdutosError();
      }
    }

    return false;
  }

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    async function cadastrarTransferencia() {
      setIsLoading(true);

      const success = await cadastrarTransferenciaEstoque(data);

      if (success) {
        toast.success('O cadastro foi realizado com sucesso.');

        historyPush(ConstanteRotas.TRANSFERENCIA_ESTOQUE);
      }

      if (isMountedRef.current) setIsLoading(false);
    }

    if (!isTranferenciaEntreEstoque) {
      await ModalConfirmacao({
        callback: async (ok: boolean) => {
          if (ok) {
            await cadastrarTransferencia();
          }
        },
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Enviar',
        title: 'Enviar transferência',
        text: (
          <>
            Esta ação depende de uma confirmação de recebimento do local de
            destino para ser finalizada. Para sua segurança, os itens serão{' '}
            <strong>baixados no estoque de origem</strong> e ficarão com entrada
            pendente no estoque de destino até a confirmação de recebimento da
            operação.
          </>
        ),
      });
    } else {
      await cadastrarTransferencia();
    }
  });

  const handleSubmitReset = formMethods.handleSubmit(async (data) => {
    setIsLoading(true);

    const success = await cadastrarTransferenciaEstoque(
      data,
      !isTranferenciaEntreEstoque
    );

    if (success) {
      if (isTranferenciaEntreEstoque) {
        toast.success('O cadastro foi realizado com sucesso.');

        historyReload();
      } else {
        await ModalSucesso({
          titulo: 'Salvo como rascunho!',
          textoMensagem:
            'A transferência foi salva e você pode retomá-la quando quiser. Os estoques não serão modificados até que seja finalizado o processo.',
          autoClose: false,
          onConfirm: historyPush(ConstanteRotas.TRANSFERENCIA_ESTOQUE),
        });
      }

      setIsLoading(false);
    }

    if (isMountedRef.current) setIsLoading(false);
  });

  useEffect(() => {
    setIsPrenvented(isDirty);
  }, [isDirty]);

  const latestProps = useRef({
    reset: formMethods.reset,
    transferenciaEstoqueId,
    historyPush,
    setFocus: formMethods.setFocus,
  });

  useEffect(() => {
    async function obterTransferenciaEstoque() {
      setIsLoading(true);

      const responseData = await apiObterTransferenciaEstoque(
        latestProps.current.transferenciaEstoqueId,
        true
      );

      if (responseData) {
        const {
          lojaOrigemFantasia,
          localEstoqueOrigemOpcaoSelect,
          lojaDestinoOpcaoSelect,
          localEstoqueDestinoOpcaoSelect,
          motivo,
          usuarioNome,
          dataHoraEmissao,
          dataHoraEnvio,
        } = responseData;

        latestProps.current.reset({
          usuario: usuarioNome,
          motivo,
          dataEmissao: new Date(dataHoraEmissao).toLocaleString('pt-BR'),
          lojaOrigem: lojaOrigemFantasia,
          localEstoqueOrigem: {
            value: localEstoqueOrigemOpcaoSelect.id,
            label: localEstoqueOrigemOpcaoSelect.nome,
          },
          lojaDestino: {
            value: lojaDestinoOpcaoSelect.id,
            label: lojaDestinoOpcaoSelect.nome,
          },
          localEstoqueDestino: {
            value: localEstoqueDestinoOpcaoSelect.id,
            label: localEstoqueDestinoOpcaoSelect.nome,
          },
        });

        setIsLoading(false);
      } else {
        setIsLoading(false);

        latestProps.current.historyPush(ConstanteRotas.TRANSFERENCIA_ESTOQUE);
      }
    }
    if (transferenciaEstoqueId) {
      obterTransferenciaEstoque();
    }
  }, []);

  return (
    <Box>
      <Prompt when={isPrenvented} message="" />
      <ManterFoco>
        {isLoading && <LoadingPadrao />}
        <FormProvider {...formMethods}>
          <Formulario
            ref={formularioRef}
            transferenciaEstoqueId={transferenciaEstoqueId}
            setIsPrenvented={setIsPrenvented}
            isCadastrar
          />
        </FormProvider>

        <FormFooter
          onSubmit={handleSubmit}
          onSubmitReset={handleSubmitReset}
          isDisabled={isLoading}
          {...(isTranferenciaEntreEstoque
            ? {}
            : {
                textSubmit: 'Salvar e enviar',
                textSubmitReset: 'Salvar rascunho',
                submitResetButtonMaxWidth: { base: 'full', sm: '175px' },
                withoutIcons: true,
              })}
        />
      </ManterFoco>
    </Box>
  );
};

export default Cadastrar;
