import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  ReactNode,
} from 'react';

import auth from 'modules/auth';

import {
  FormData,
  formDefaultValues,
} from 'pages/MovimentacaoEstoque/Listar/validationForm';

interface MovimentacaoEstoqueFiltrosContextProps {
  currentFilters: FormData;
  setCurrentFilters: Dispatch<SetStateAction<FormData>>;
}

const MovimentacaoEstoqueFiltrosContext = createContext<MovimentacaoEstoqueFiltrosContextProps>(
  {} as MovimentacaoEstoqueFiltrosContextProps
);

interface MovimentacaoEstoqueFiltrosProviderProps {
  children: ReactNode;
}

export default function MovimentacaoEstoqueFiltrosProvider({
  children,
}: MovimentacaoEstoqueFiltrosProviderProps): JSX.Element {
  const { id: lojaId } = auth.getLoja();

  const [currentFilters, setCurrentFilters] = useState<FormData>(
    formDefaultValues(lojaId)
  );

  return (
    <MovimentacaoEstoqueFiltrosContext.Provider
      value={{ currentFilters, setCurrentFilters }}
    >
      {children}
    </MovimentacaoEstoqueFiltrosContext.Provider>
  );
}

export function useMovimentacaoEstoqueFiltrosContext(): MovimentacaoEstoqueFiltrosContextProps {
  const context = useContext(MovimentacaoEstoqueFiltrosContext);

  if (!context)
    throw new Error(
      'useMovimentacaoEstoqueFiltrosContext must be used within a MovimentacaoEstoqueFiltrosProvider.'
    );

  return context;
}
