import { useCallback, useEffect, useRef } from 'react';
import { FiX } from 'react-icons/fi';
import { IoIosAlert } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';

import ConstanteRotas, { ConstanteRotasAlternativas } from 'constants/rotas';
import auth from 'modules/auth';
import { useFullScreenContext } from 'store/FullScreen';
import { NotificacaoVenda, useSignalRContext } from 'store/SignalR';
import enumReferenciaServicoStargate from 'constants/enum/referenciaServicoStargate';

import {
  AlternarLojaIcon,
  CestaEcommerceIcon,
  ConfiguracoesUsuariosIcon,
  ExpandirFullscreenIcon,
  IconTrayApp,
  LogoutIcon,
  NotificacoesIcon,
  RecolherFullscreenIcon,
} from 'icons';

import PortalFullscreen from 'components/PDV/Geral/PortalFullscreen';

import {
  formatDate,
  formatDateHourMinute,
} from 'helpers/format/formatStringDate';
import { Ajuda } from '../Ajuda';
import { ListMenuItem } from '../MenuItem';

type HeaderItemProps = {
  logOff: () => void;
  onModalAlternarLojaOpen: () => void;
};

export const HeaderItem = ({
  logOff,
  onModalAlternarLojaOpen,
}: HeaderItemProps) => {
  const notificacaoRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);
  const vendasRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const { handleFullScreen } = useFullScreenContext();

  const {
    setPossuiNotificacao,
    possuiNotificacao,
    notificacaoTray,
    setNotificacaoTray,
    notificarVendas,
    setNotificarVendas,
    statusNotaFiscal,
    notificationsPdvAutonomo,
    handleSetNotificationsPdvAutonomo,
    notificationsFrenteCaixa,
    handleSetNotificationsFrenteCaixa,
  } = useSignalRContext();

  const history = useHistory();

  const possuiServicoTray = auth.possuiServico(
    enumReferenciaServicoStargate.INTEGRACAO_TRAY
  ).permitido;

  const { fantasia: lojaFantasia = '' } = auth.getLoja();
  const { id: lojaId } = auth.getLoja();
  const qtdLojas = auth.getQtdLojas();
  const { nome: nomeUsuario, foto: fotoUsuario } = auth.getUsuario();

  const vendas = notificarVendas
    .filter((vendaItem) => vendaItem.lojaId === lojaId)
    .reduce((acc, curr) => {
      const itemAlreadyAddedIndex = acc.findIndex(
        (item) => item.id === curr.id
      );

      if (itemAlreadyAddedIndex > -1) {
        acc.splice(itemAlreadyAddedIndex, 1, {
          ...acc[itemAlreadyAddedIndex],
        });
      } else {
        acc.push({
          ...curr,
        });
      }

      return acc;
    }, [] as NotificacaoVenda[]);

  const hasNotificacaoAtiva = vendas.some((itemVenda) => itemVenda.isChecked);

  const handleNotificacaoLida = useCallback(() => {
    setNotificarVendas((prev) =>
      prev.map((prevItem) => ({
        ...prevItem,
        isChecked: false,
      }))
    );
    history.push(ConstanteRotas.INTEGRACAO_TRAY_COMMERCE_VENDAS);
    vendasRef.current.click();
  }, [history, setNotificarVendas]);

  const handleOpenPdvAutonomo = () => {
    history.push(ConstanteRotas.PDV_OFFLINE);
    setPossuiNotificacao(false);
  };

  const handleOpenFrenteCaixa = () => {
    history.push(ConstanteRotas.FRENTE_CAIXA_PAINEL);
    setPossuiNotificacao(false);
  };

  function toggleFullScreen() {
    if (!handleFullScreen.active) {
      handleFullScreen.enter();
    } else {
      handleFullScreen.exit();
    }
  }

  useEffect(() => {
    if (possuiNotificacao) {
      if (notificacaoRef.current?.click) {
        notificacaoRef.current.click();
      }
    }
  }, [notificacaoTray, possuiNotificacao]);

  return (
    <>
      {lojaFantasia && (
        <Text fontSize="xs" color="primary.400">
          {lojaFantasia}
        </Text>
      )}
      {!navigator.userAgent.match(/iPhone/i) && (
        <IconButton
          aria-label="Toggle fullscreen"
          icon={
            handleFullScreen.active ? (
              <RecolherFullscreenIcon />
            ) : (
              <ExpandirFullscreenIcon />
            )
          }
          variant="link"
          colorScheme="primary"
          fontSize="xl"
          borderRadius="md"
          px={1}
          py={1}
          minW={7}
          onClick={toggleFullScreen}
        />
      )}

      <Ajuda />

      <Box position="relative">
        <Menu>
          <MenuButton
            as={IconButton}
            icon={<NotificacoesIcon />}
            variant="link"
            borderRadius="md"
            colorScheme="primary"
            fontSize="xl"
            ref={notificacaoRef}
            my={2}
            minW={7}
            isDisabled={!possuiNotificacao}
          />
          {possuiNotificacao && (
            <Box position="absolute" right="7px" top="10px" color="red.500">
              <IoIosAlert size={13} />
            </Box>
          )}
          <PortalFullscreen>
            <MenuList
              bg="gray.50"
              maxH="466px"
              w="328px"
              px="24px"
              zIndex="1020"
            >
              <MenuGroup
                zIndex="1020"
                title="Notificações"
                as={() => (
                  <Flex
                    alignItems="center"
                    zIndex="1020"
                    px="0"
                    pb="10px"
                    paddingTop={1}
                  >
                    <Text maxW="150px" fontSize="16px" isTruncated>
                      Notificações
                    </Text>
                  </Flex>
                )}
              >
                {notificacaoTray && (
                  <ListMenuItem
                    onClick={() => {
                      history.push(ConstanteRotasAlternativas.TRAY_ETAPAS);
                      setPossuiNotificacao(false);
                      setNotificacaoTray('');
                    }}
                  >
                    {notificacaoTray}
                  </ListMenuItem>
                )}
                {statusNotaFiscal && (
                  <ListMenuItem
                    onClick={() => {
                      history.push(
                        ConstanteRotas.INTEGRACAO_TRAY_COMMERCE_VENDAS
                      );
                      setPossuiNotificacao(false);
                    }}
                  >
                    As notas foram emitidas, clique aqui para saber mais
                  </ListMenuItem>
                )}
                {notificationsPdvAutonomo.map((notification, index) => {
                  const removeNotification = () => {
                    const newListNotifications = [...notificationsPdvAutonomo];
                    newListNotifications.splice(index, 1);

                    handleSetNotificationsPdvAutonomo(newListNotifications);
                  };

                  return (
                    <ListMenuItem
                      onClick={() => {
                        handleOpenPdvAutonomo();
                        removeNotification();
                      }}
                    >
                      {notification}
                    </ListMenuItem>
                  );
                })}
                {notificationsFrenteCaixa.map((notification, index) => {
                  const removeNotification = () => {
                    const newListNotifications = [...notificationsFrenteCaixa];
                    newListNotifications.splice(index, 1);

                    handleSetNotificationsFrenteCaixa(newListNotifications);
                  };

                  return (
                    <ListMenuItem
                      onClick={() => {
                        handleOpenFrenteCaixa();
                        removeNotification();
                      }}
                    >
                      {notification}
                    </ListMenuItem>
                  );
                })}
              </MenuGroup>
            </MenuList>
          </PortalFullscreen>
        </Menu>
      </Box>

      <Box position="relative">
        <Menu>
          {possuiServicoTray && (
            <MenuButton
              as={IconButton}
              icon={<CestaEcommerceIcon />}
              variant="link"
              borderRadius="md"
              colorScheme="primary"
              fontSize="2xl"
              ref={vendasRef}
              my={2}
              minW={7}
              isDisabled={vendas.length === 0}
            />
          )}
          {hasNotificacaoAtiva && (
            <Flex
              position="absolute"
              right="-3px"
              top="6px"
              color="white"
              borderRadius="100px"
              boxSize="15px"
              cursor="pointer"
              bg="red.500"
              onClick={() => {
                vendasRef.current.click();
              }}
            >
              <Flex
                p="7px"
                justifyContent="center"
                alignItems="center"
                h="full"
                w="full"
                mt="1.5px"
                fontSize="9px"
              >
                {
                  vendas.reverse().filter((vendaItem) => vendaItem.isChecked)
                    .length
                }
              </Flex>
            </Flex>
          )}
          <PortalFullscreen>
            <MenuList
              bg="gray.50"
              maxH="466px"
              w="360px"
              overflowY="auto"
              px="24px"
              zIndex="1020"
            >
              <MenuGroup
                zIndex="1020"
                title="Notificações"
                color="violet.500"
                as={() => (
                  <Flex
                    alignItems="center"
                    zIndex="1020"
                    borderBottomWidth="1px"
                    borderColor="gray.200"
                    justifyContent="space-between"
                    px="0"
                    pb="10px"
                    mb="17px"
                    paddingTop={1}
                  >
                    <Text
                      maxW="150px"
                      color="violet.500"
                      fontSize="16px"
                      isTruncated
                    >
                      Vendas online
                    </Text>
                    <Box cursor="pointer">
                      <FiX onClick={handleNotificacaoLida} />
                    </Box>
                  </Flex>
                )}
              >
                {vendas.map((vendaItem, index) => {
                  if (index + 1 > 10) {
                    return null;
                  }
                  return (
                    <MenuItem
                      key={vendaItem.id}
                      _hover={{
                        boxShadow: '0px 0px 4px rgba(85, 2, 178, 0.5)',
                        bg: vendaItem.isChecked ? 'blue.700' : 'white',
                      }}
                      _focus={{
                        bg: vendaItem.isChecked ? 'blue.700' : 'white',
                        boxShadow: '0px 0px 4px #0000003E',
                        color: vendaItem.isChecked ? 'white' : 'black',
                      }}
                      px="12px"
                      py="30px"
                      borderRadius="6px"
                      _active={{
                        bg: vendaItem.isChecked ? 'blue.700' : 'white',
                      }}
                      bg={vendaItem.isChecked ? 'blue.700' : 'white'}
                      mb="5px"
                      color={vendaItem.isChecked ? 'white' : 'black'}
                      boxShadow="0px 0px 4px #0000003E"
                      h="74px"
                      fontSize="14px"
                      onClick={() => {
                        handleNotificacaoLida();
                      }}
                      icon={
                        <Flex
                          bgColor="white"
                          borderRadius="6px"
                          boxSize="40px"
                          justify="center"
                          align="center"
                        >
                          <Icon as={IconTrayApp} fontSize="36px" />
                        </Flex>
                      }
                    >
                      <Text>{vendaItem.mensagem}</Text>
                      <Text>
                        {formatDate(vendaItem.dataNotificacao)} às{' '}
                        {formatDateHourMinute(vendaItem.dataNotificacao).slice(
                          11
                        )}
                        h
                      </Text>
                    </MenuItem>
                  );
                })}

                <Flex mt="24px" mb="10px" justifyContent="center">
                  <Box display="grid">
                    <Button
                      variant="solid"
                      mb="10px"
                      colorScheme="blue.600"
                      onClick={() => {
                        handleNotificacaoLida();
                      }}
                    >
                      Abrir pedidos
                    </Button>
                    <Button
                      variant="link"
                      fontSize="12px"
                      colorScheme="gray"
                      onClick={() => {
                        setNotificarVendas([]);
                        localStorage.removeItem('notificarVendas');
                        vendasRef.current.click();
                      }}
                    >
                      Excluir notificações
                    </Button>
                  </Box>
                </Flex>
              </MenuGroup>
            </MenuList>
          </PortalFullscreen>
        </Menu>
      </Box>
      <Menu>
        <MenuButton
          as={Avatar}
          src={fotoUsuario}
          size="xs"
          cursor="pointer"
          border="1px"
          borderColor="primary.400"
        />
        <PortalFullscreen>
          <MenuList zIndex="1020">
            <MenuGroup
              zIndex="1020"
              title={nomeUsuario}
              as={() => (
                <Flex
                  alignItems="center"
                  zIndex="1020"
                  paddingLeft={9}
                  paddingBottom={1}
                  paddingTop={1}
                  paddingRight={3}
                >
                  <Icon
                    as={ConfiguracoesUsuariosIcon}
                    fontSize="xl"
                    marginEnd="0.75rem"
                  />
                  <Text maxW="150px" isTruncated>
                    {nomeUsuario}
                  </Text>
                </Flex>
              )}
            >
              <MenuDivider borderColor="gray.100" />
              {qtdLojas > 1 && (
                <MenuItem
                  icon={<Icon as={AlternarLojaIcon} fontSize="xl" />}
                  onClick={onModalAlternarLojaOpen}
                >
                  Alternar loja
                </MenuItem>
              )}
              <MenuItem
                icon={<Icon as={LogoutIcon} fontSize="lg" />}
                onClick={() => logOff()}
              >
                Sair
              </MenuItem>
            </MenuGroup>
          </MenuList>
        </PortalFullscreen>
      </Menu>
    </>
  );
};
