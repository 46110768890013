/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import {
  NumberInput,
  NumberInputField,
  NumberInputProps,
} from '@chakra-ui/react';

import CampoPrototipo, { CampoPrototipoProps } from '../Geral/CampoPrototipo';

export type InputIntegerProps = NumberInputProps &
  CampoPrototipoProps & {
    autoFocus?: boolean;
    maxLength?: number;
    isNumber?: boolean;
  };

const InputInteger = ({
  name,
  label,
  required = false,
  helperText,
  id,
  colSpan,
  colStart,
  colEnd,
  rowSpan,
  rowStart,
  rowEnd,
  prefix,
  defaultValue,
  placeholder,
  textAlign,
  pr,
  autoFocus,
  isDisabled,
  maxLength,
  isNumber,
  labelColor,
  fontWeightLabel,
  ...inputProps
}: InputIntegerProps) => {
  return (
    <CampoPrototipo
      name={name}
      isRequired={required}
      helperText={helperText}
      id={id}
      label={label}
      rowSpan={rowSpan}
      rowStart={rowStart}
      rowEnd={rowEnd}
      colSpan={colSpan}
      colStart={colStart}
      colEnd={colEnd}
      defaultValue={defaultValue}
      isDisabled={isDisabled}
      labelColor={labelColor}
      fontWeightLabel={fontWeightLabel}
    >
      {({ setValue }, { value }) => {
        return (
          <NumberInput
            inputMode="numeric"
            min={0}
            precision={0}
            value={isNumber ? Number(value) : value || undefined}
            onChange={(valueString) => {
              const newValue = valueString.replace(/[^\d]+/g, '');
              if (isNumber) {
                const newValueAsNumber = Number(
                  newValue.substring(0, maxLength)
                );
                setValue(`${name}` as const, newValueAsNumber);
              } else {
                setValue(`${name}` as const, newValue.substring(0, maxLength));
              }
            }}
            isDisabled={isDisabled}
            {...inputProps}
          >
            <NumberInputField
              inputMode="decimal"
              placeholder={placeholder}
              textAlign={textAlign}
              pr={pr}
              autoFocus={autoFocus}
            />
          </NumberInput>
        );
      }}
    </CampoPrototipo>
  );
};

export default InputInteger;
