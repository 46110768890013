import { useState, useCallback, useRef, ChangeEvent } from 'react';
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri';
import {
  Box,
  Text,
  Stack,
  Button,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Icon,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { useFieldArray, useFormContext } from 'react-hook-form';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import api, { ResponseApi } from 'services/api';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';
import ConstanteFuncionalidades from 'constants/permissoes';
import auth from 'modules/auth';

import {
  CarregarArquivoColetorIcon,
  OpcoesIcon,
  SalvarInserirNovoIcon,
} from 'icons';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import { InfoTooltip } from 'components/update/Tooltip/InfoTooltip';
import {
  PagedTable,
  PagedTableForwardRefData,
} from 'components/update/Table/PagedTable';
import { PaginationData } from 'components/update/Pagination';
import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';

import { ModalEditarProduto } from '../ModalEditarProduto';
import { ModalAdicionarProduto } from '../ModalAdicionarProduto';

import {
  FieldArrayType,
  ProdutoTabelaPrecoProps,
  TabelaPrecoCadastrarProps,
} from '../../types';
import { useTabelaPreco } from '../../hooks';
import {
  TabelaPrecoItensProps,
  TabelaPrecoResponseProps,
} from '../../validationForm';

export const ListPrecosFixos = ({
  isReadOnly,
  isAlterar,
  padraoSistema,
}: {
  isReadOnly: boolean;
  isAlterar: boolean;
  padraoSistema?: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const {
    getProductsToExport,
    readCSV,
    validateCSV,
    idFromRouteParams,
  } = useTabelaPreco();

  const { casasDecimais } = usePadronizacaoContext();
  const {
    fields,
    remove,
    append,
    update,
    replace,
  } = useFieldArray<FieldArrayType>({
    name: 'tabelaPrecoProdutos',
  });
  const { handleSubmit } = useFormContext();
  const possuiPermissaoVisualizarPrecoCusto = auth.possuiPermissao(
    ConstanteFuncionalidades.USUARIO_VISUALIZAR_PRECO_CUSTO
  ).permitido;

  const buttonRef = useRef<HTMLButtonElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const pagedTableRef = useRef<PagedTableForwardRefData>(null);
  const pageIsLoaded = useRef(false);
  const isMountedRef = useIsMountedRef();

  const addProduct = useCallback(
    async (
      produtos: TabelaPrecoCadastrarProps[] | TabelaPrecoCadastrarProps
    ) => {
      setIsLoading(true);
      const produtoAdicionado = produtos as TabelaPrecoCadastrarProps;
      const newProduto =
        produtoAdicionado?.id === undefined ? produtos : [produtos];

      const response = await api.post<void, ResponseApi<string>>(
        `${ConstanteEnderecoWebservice.TABELA_PRECO_CADASTRAR_PRODUTO_LISTAGEM_PRODUTOS_SIMPLES}/${idFromRouteParams}`,
        newProduto
      );
      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso: string) => toast.warning(aviso));
          setIsLoading(false);
          pagedTableRef.current?.reload();
          return true;
        }

        if (response.sucesso) {
          pagedTableRef.current?.reload();
          return true;
        }
        setIsLoading(false);
      }
      setIsLoading(false);
      return false;
    },
    [idFromRouteParams]
  );

  const handleAddProduct = useCallback(async () => {
    const { produto: newProduto, deveReiniciar } = await ModalAdicionarProduto({
      casasDecimaisValor: casasDecimais.casasDecimaisValor,
      fields,
      isAlterar,
    });
    if (isAlterar) {
      await addProduct(
        newProduto.map((produtoItem) => ({
          id: produtoItem.produtoCorTamanhoId || '',
          valor: produtoItem.precoVenda || 0,
        }))
      );
    } else {
      newProduto.forEach((produtoItem) => {
        const produtoJaAdicionado = fields.some(
          (fieldsItem) =>
            fieldsItem.produtoCorTamanhoId === produtoItem.produtoCorTamanhoId
        );
        const indexFields = fields.findIndex(
          (fieldsItem) =>
            fieldsItem.produtoCorTamanhoId === produtoItem.produtoCorTamanhoId
        );
        if (produtoJaAdicionado) {
          update(indexFields, produtoItem);
        } else {
          append(produtoItem);
        }
      });
    }
    if (deveReiniciar) {
      if (buttonRef?.current) {
        buttonRef?.current?.click();
      }
    }
  }, [addProduct, append, casasDecimais, fields, isAlterar, update]);

  const editProduct = useCallback(
    async (data: ProdutoTabelaPrecoProps) => {
      const response = await api.put<void, ResponseApi<string>>(
        ConstanteEnderecoWebservice.TABELA_PRECO_ALTERAR_PRODUTO_LISTAGEM_PRECOS_SIMPLES,
        {
          tabelaPrecoId: idFromRouteParams,
          produtoId: data.produtoId,
          precoVenda: data.precoVenda,
        }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso: string) => toast.warning(aviso));
        }

        if (response.sucesso) {
          toast.success('Produto foi alterado com sucesso');
        }
      }
    },
    [idFromRouteParams]
  );

  const handleEditProduct = async (index: number, produtoId: string) => {
    const field = fields[index];

    const { precoVenda, markup } = await ModalEditarProduto({
      casasDecimaisValor: casasDecimais.casasDecimaisValor,
      nomeProduto: field.produtoNome,
      tamanhoDescricao: field.tamanhoDescricao,
      precoVendaAtual: field.precoVenda,
      precoCusto: field.precoCusto,
    });

    if (isAlterar) {
      await editProduct({
        tabelaPrecoId: idFromRouteParams,
        precoVenda,
        produtoId,
      });
      pagedTableRef.current?.reload();
      return;
    }

    update(index, { ...field, precoVenda, markup });
  };

  const deleteProduct = useCallback(async (data: ProdutoTabelaPrecoProps) => {
    const response = await api.delete<void, ResponseApi<string>>(
      `${ConstanteEnderecoWebservice.TABELA_PRECO_EXCLUIR_PRODUTO_LISTAGEM_PRECOS_SIMPLES}?tabelaPrecoId=${data.tabelaPrecoId}&produtoId=${data.produtoId}`
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }

      if (response.sucesso) {
        toast.success('Produto deletado com sucesso');
      }
    }
  }, []);

  const handleRemoveProduct = (index: number, produtoId: string) => {
    ModalConfirmacaoExcluir({
      callback: async (ok: boolean) => {
        if (ok) {
          if (isAlterar) {
            await deleteProduct({
              produtoId,
              tabelaPrecoId: idFromRouteParams,
            });
            pagedTableRef.current?.reload();
            return;
          }
          remove(index);
        }
      },
    });
  };

  const handleUploadFile = handleSubmit(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  });

  const exportProducts = (exportAll: boolean) => {
    const tabelaPrecoId = idFromRouteParams || null;
    getProductsToExport(exportAll, tabelaPrecoId, true);
  };

  const handleUploadCSV = async (file: File) => {
    const success = validateCSV(file);
    if (success) {
      await readCSV(file, true);
    }
  };

  const getListItens = useCallback(
    async (gridPaginadaConsulta: PaginationData) => {
      const isTelaCadastro = !idFromRouteParams;
      if (isTelaCadastro) {
        return;
      }

      setIsLoading(true);

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<TabelaPrecoResponseProps>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.TABELA_PRECO_LISTAR_PAGINADO_LISTAGEM_PRODUTOS_SIMPLES,
          {
            ...gridPaginadaConsulta,
            orderColumn: '',
            orderDirection: '',
          }
        ),
        { params: { id: idFromRouteParams } }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso: string) => toast.warning(aviso));
        }
        if (response.sucesso) {
          const newData = response.dados.registros.map((itemTabelaPreco) => {
            const { precoCusto, precoVenda } = itemTabelaPreco;
            const markup =
              precoCusto && precoVenda
                ? (precoVenda / precoCusto - 1) * 100
                : 0;

            return {
              ...itemTabelaPreco,
              produtoNome: itemTabelaPreco.nome,
              tamanhoDescricao: itemTabelaPreco?.tamanho?.descricao,
              markup,
            } as TabelaPrecoItensProps;
          });
          replace(newData);

          setTotalRegistros(response.dados.total);
          setIsLoading(false);
        }
      }

      if (isMountedRef.current) {
        setIsLoading(false);

        if (!pageIsLoaded.current) {
          pageIsLoaded.current = true;
        }
      }
    },
    [idFromRouteParams, isMountedRef, replace]
  );

  return (
    <Box w="full">
      <HStack spacing="1" mb="1">
        <Text
          as="label"
          lineHeight="none"
          fontSize="sm"
          fontWeight="semibold"
          mb="0"
        >
          Preços fixos por produto
        </Text>
        <InfoTooltip
          bg="black"
          padding="8px"
          pl="12px"
          borderRadius="8px"
          label="O sistema irá verificar se existe um preço diferente adicionado para determinado produto e utilizará este valor no momento da venda, desconsiderando as regras acima"
        />
      </HStack>

      <Box
        borderRadius="md"
        bg="gray.50"
        border="1px"
        borderColor="gray.100"
        p={{ base: 4, sm: 6, md: 8 }}
      >
        <Stack
          direction={[
            'column-reverse',
            'column-reverse',
            'column-reverse',
            'row',
          ]}
          justifyContent={isReadOnly ? 'flex-end' : 'space-between'}
          mb="4"
        >
          <Flex direction={['column', 'column', 'column', 'row']}>
            {!isReadOnly && (
              <Button
                borderRadius="md"
                ref={buttonRef}
                colorScheme="secondary"
                isDisabled={padraoSistema}
                leftIcon={<Icon as={SalvarInserirNovoIcon} fontSize="lg" />}
                onClick={() => handleAddProduct()}
              >
                Adicionar produto
              </Button>
            )}

            {isAlterar && (
              <>
                <Box>
                  <Button
                    variant="outlineDefault"
                    colorScheme="gray"
                    mt={['10px', '10px', '10px', '0']}
                    mb={['10px', '10px', '10px', '0']}
                    mr={['0', '0', '0', '20px']}
                    ml={['0', '0', '0', '20px']}
                    borderRadius="md"
                    isDisabled={padraoSistema}
                    w={['full', 'full', 'full', '200px']}
                    leftIcon={
                      <Icon as={CarregarArquivoColetorIcon} fontSize="lg" />
                    }
                    onClick={handleUploadFile}
                  >
                    Importar produtos
                  </Button>
                </Box>

                <Menu>
                  {({ isOpen }) => (
                    <>
                      <MenuButton
                        variant="outlineDefault"
                        colorScheme="gray"
                        isActive={isOpen}
                        as={Button}
                        w={['full', 'full', 'full', '230px']}
                        rightIcon={
                          isOpen ? (
                            <Icon as={RiArrowDropDownLine} h={7} w={7} />
                          ) : (
                            <Icon as={RiArrowDropUpLine} h={7} w={7} />
                          )
                        }
                        leftIcon={<OpcoesIcon />}
                        borderRadius="md"
                        bg="white"
                        border="1px"
                        isDisabled={padraoSistema}
                        borderColor="gray.100"
                      >
                        <Text paddingLeft="2" textAlign="center">
                          Opções gerais
                        </Text>
                      </MenuButton>
                      <MenuList>
                        <MenuItem onClick={() => exportProducts(true)}>
                          <Text ml="4" fontSize="sm">
                            Exportar todos os produtos
                          </Text>
                        </MenuItem>
                        <MenuItem onClick={() => exportProducts(false)}>
                          <Text ml="4" fontSize="sm">
                            Exportar produtos da tabela de preço
                          </Text>
                        </MenuItem>
                      </MenuList>
                    </>
                  )}
                </Menu>
              </>
            )}
          </Flex>
          <Flex alignItems="center">
            <Text fontSize="sm" fontWeight="semibold">
              Informe os produtos que possuem preço fixo para esta tabela de
              preço
            </Text>
          </Flex>
        </Stack>

        <input
          ref={inputRef}
          accept=".csv"
          type="file"
          value=""
          style={{ display: 'none' }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const { files } = e.target;
            if (!files || files.length === 0) {
              return;
            }

            const newFile = files[0];

            if (newFile) {
              handleUploadCSV(newFile);
            }
          }}
        />

        <Box
          bg="white"
          borderRadius="md"
          boxShadow="md"
          position="relative"
          overflow="auto"
          sx={{
            '&::-webkit-scrollbar': {
              w: '0',
              h: '0',
            },
          }}
        >
          {isAlterar ? (
            <PagedTable
              ref={pagedTableRef}
              isLoading={isLoading}
              loadColumnsData={getListItens}
              itemsTotalCount={totalRegistros}
              defaultKeyOrdered="nome"
              tableHeaders={[
                {
                  key: 'Produto',
                  content: 'Produto',
                  w: possuiPermissaoVisualizarPrecoCusto ? '65%' : '75%',
                  isOrderable: false,
                },
                {
                  key: 'precoVendas',
                  content: 'Preço de vendas',
                  isOrderable: false,
                  w: '10%',
                  isNumeric: true,
                },
                possuiPermissaoVisualizarPrecoCusto
                  ? {
                      key: 'custo',
                      content: 'Custo',
                      w: '10%',
                      isOrderable: false,
                      isNumeric: true,
                    }
                  : {
                      key: 'custo',
                      content: '',
                      w: '0%',
                      isOrderable: false,
                      isNumeric: true,
                    },
                {
                  key: 'markup',
                  content: 'Markup',
                  w: '10%',
                  isOrderable: false,
                  isNumeric: true,
                },
                {
                  key: 'acoes',
                  content: 'Ações',
                  isOrderable: false,
                  w: '1px',
                },
              ]}
              renderTableRows={
                fields?.length > 0 ? (
                  fields?.map((field, index) => (
                    <Tr>
                      <Td w="65%">{field.produtoNome}</Td>
                      <Td isNumeric w="10%">
                        {field.precoVenda?.toLocaleString('pt-BR', {
                          minimumFractionDigits:
                            casasDecimais.casasDecimaisValor,
                          maximumFractionDigits:
                            casasDecimais.casasDecimaisValor,
                        })}
                      </Td>
                      {possuiPermissaoVisualizarPrecoCusto ? (
                        <Td isNumeric w="10%">
                          {field.precoCusto?.toLocaleString('pt-BR', {
                            minimumFractionDigits:
                              casasDecimais.casasDecimaisValor,
                            maximumFractionDigits:
                              casasDecimais.casasDecimaisValor,
                          })}
                        </Td>
                      ) : (
                        <Td w="0%" />
                      )}
                      <Td isNumeric w="10%">
                        {`${field.markup?.toLocaleString('pt-BR', {
                          minimumFractionDigits:
                            casasDecimais.casasDecimaisValor,
                          maximumFractionDigits:
                            casasDecimais.casasDecimaisValor,
                        })}%`}
                      </Td>
                      <Td w="1px">
                        <ActionsMenu
                          isDisabled={isReadOnly}
                          items={
                            isReadOnly
                              ? []
                              : padraoSistema
                              ? [
                                  {
                                    content: 'Remover',
                                    onClick: () =>
                                      handleRemoveProduct(
                                        index,
                                        field.produtoId || ''
                                      ),
                                  },
                                ]
                              : [
                                  {
                                    content: 'Editar',
                                    onClick: () =>
                                      handleEditProduct(
                                        index,
                                        field.produtoId || ''
                                      ),
                                  },
                                  {
                                    content: 'Remover',
                                    onClick: () =>
                                      handleRemoveProduct(
                                        index,
                                        field.produtoId || ''
                                      ),
                                  },
                                ]
                          }
                        />
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td whiteSpace="nowrap" colSpan={9999}>
                      Nenhum produto adicionado.
                    </Td>
                  </Tr>
                )
              }
            />
          ) : (
            <Table variant="filled">
              <Thead>
                <Tr>
                  <Th
                    w={possuiPermissaoVisualizarPrecoCusto ? '65%' : '75%'}
                    whiteSpace="nowrap"
                    userSelect="none"
                  >
                    Produto
                  </Th>
                  <Th isNumeric w="10%" whiteSpace="nowrap" userSelect="none">
                    Preço de venda
                  </Th>
                  {possuiPermissaoVisualizarPrecoCusto ? (
                    <Th isNumeric w="10%" whiteSpace="nowrap" userSelect="none">
                      Custo
                    </Th>
                  ) : (
                    <Th w="0%" />
                  )}
                  <Th isNumeric w="10%" whiteSpace="nowrap" userSelect="none">
                    Markup
                  </Th>
                  <Th w="1px" whiteSpace="nowrap" userSelect="none">
                    Ações
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {(fields || [])?.length > 0 ? (
                  (fields || [])?.map((field, index) => (
                    <Tr>
                      <Td w="45%">{field.produtoNome} </Td>
                      <Td isNumeric w="10%">
                        {field.precoVenda.toLocaleString('pt-BR', {
                          minimumFractionDigits:
                            casasDecimais.casasDecimaisValor,
                          maximumFractionDigits:
                            casasDecimais.casasDecimaisValor,
                        })}
                      </Td>
                      {possuiPermissaoVisualizarPrecoCusto ? (
                        <Td isNumeric w="10%">
                          {field.precoCusto?.toLocaleString('pt-BR', {
                            minimumFractionDigits:
                              casasDecimais.casasDecimaisValor,
                            maximumFractionDigits:
                              casasDecimais.casasDecimaisValor,
                          })}
                        </Td>
                      ) : (
                        <Td w="0%" />
                      )}
                      <Td isNumeric w="10%">
                        {`${field.markup.toLocaleString('pt-BR', {
                          minimumFractionDigits:
                            casasDecimais.casasDecimaisValor,
                          maximumFractionDigits:
                            casasDecimais.casasDecimaisValor,
                        })}%`}
                      </Td>
                      <Td w="1px">
                        <ActionsMenu
                          isDisabled={isReadOnly}
                          items={
                            isReadOnly
                              ? []
                              : padraoSistema
                              ? [
                                  {
                                    content: 'Remover',
                                    onClick: () =>
                                      handleRemoveProduct(
                                        index,
                                        field.produtoCorTamanhoId
                                      ),
                                  },
                                ]
                              : [
                                  {
                                    content: 'Editar',
                                    onClick: () =>
                                      handleEditProduct(
                                        index,
                                        field.produtoCorTamanhoId
                                      ),
                                  },
                                  {
                                    content: 'Remover',
                                    onClick: () =>
                                      handleRemoveProduct(
                                        index,
                                        field.produtoCorTamanhoId
                                      ),
                                  },
                                ]
                          }
                        />
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td whiteSpace="nowrap" colSpan={9999}>
                      Nenhum produto adicionado.
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          )}
        </Box>
      </Box>
    </Box>
  );
};
