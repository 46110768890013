import React from 'react';
import { Button, ButtonProps, Icon } from '@chakra-ui/react';

import { ValidarPermissao } from 'components/update/Validacao/ValidarPermissao';
import { TooltipPermissao } from 'components/update/Validacao/TooltipPermissao';

interface ButtonFuncionalidadeProps extends ButtonProps {
  funcionalidade: string;
  iconSide?: 'left' | 'right';
  showTooltipIcon?: boolean;
}

export const ButtonFuncionalidade = ({
  children,
  funcionalidade,
  iconSide = 'right',
  variant = 'solid',
  colorScheme,
  isDisabled,
  rightIcon,
  leftIcon,
  width,
  showTooltipIcon = true,
  ...rest
}: ButtonFuncionalidadeProps) => {
  return (
    <ValidarPermissao funcionalidade={funcionalidade}>
      {({ permitido, bloqueio, icon }) => (
        <TooltipPermissao
          bloqueio={bloqueio}
          isDisabled={permitido}
          placement="auto"
        >
          <Button
            {...rest}
            w={width}
            isDisabled={isDisabled || !permitido}
            variant={permitido ? variant : 'outline'}
            colorScheme={permitido ? colorScheme : 'gray'}
            rightIcon={
              !permitido && iconSide === 'right' && showTooltipIcon ? (
                <Icon as={icon} />
              ) : (
                rightIcon
              )
            }
            leftIcon={
              !permitido && iconSide === 'left' && showTooltipIcon ? (
                <Icon as={icon} />
              ) : (
                leftIcon
              )
            }
            _disabled={{
              borderColor: 'gray.200',
            }}
          >
            {children}
          </Button>
        </TooltipPermissao>
      )}
    </ValidarPermissao>
  );
};
