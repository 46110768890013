import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import isPrenvent from 'helpers/layout/isPrenvent';
import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import { ContainerListagem } from 'components/Layout/Listagem/containerListagem';

import { useForm, yupResolver } from '../validationForm';
import { UncontrolledForm } from '..';

interface TamanhoInterface {
  id: string;
  nome: string;
  ativo: boolean;
  dataHoraCadastro: string;
  dataHoraUltimaAlteracao: string;
}

type TParams = { id: string };

const Alterar = () => {
  const history = useHistory();
  const isMountedRef = useIsMountedRef();
  isPrenvent();

  const [isLoading, setIsLoading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);

  const [dataHoraCadastro, setDataHoraCadastro] = useState('');
  const [dataHoraUltimaAlteracao, setDataHoraUltimaAlteracao] = useState('');

  const tamanhoId = useRef<string | null>(null);
  const { id: idRota } = useParams<TParams>();

  const formMethods = useForm({
    resolver: yupResolver,
    shouldUnregister: false,
  });
  const { handleSubmit, reset, getValues, formState } = formMethods;

  const handleGetTamanho = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<void, ResponseApi<TamanhoInterface>>(
      ConstanteEnderecoWebservice.TAMANHO_OBTER,
      {
        params: { id: idRota },
      }
    );

    if (response?.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (response?.sucesso && isMountedRef.current) {
      reset({
        ...response.dados,
      });

      setDataHoraCadastro(response.dados.dataHoraCadastro);
      setDataHoraUltimaAlteracao(response.dados.dataHoraUltimaAlteracao);

      if (isMountedRef.current) setIsLoading(false);
    } else {
      history.push(ConstanteRotas.TAMANHO);
    }
  }, [history, isMountedRef, idRota, reset]);

  const onSubmit = handleSubmit(async () => {
    setIsLoading(true);

    let data = getValues();

    data = {
      id: tamanhoId.current,
      ...data,
    };

    const response = await api.put<void, ResponseApi>(
      ConstanteEnderecoWebservice.TAMANHO_ALTERAR,
      data
    );

    if (response.sucesso) {
      toast.success('O cadastro foi alterado com sucesso.');

      setFormIsDirty(false);

      history.push(ConstanteRotas.TAMANHO);

      return;
    }

    if (response.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (isMountedRef.current) setIsLoading(false);
  });

  useEffect(() => {
    setIsLoading(true);

    handleGetTamanho();
  }, [handleGetTamanho]);

  useEffect(() => {
    setFormIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  return (
    <ContainerListagem
      formIsDirty={formIsDirty}
      formMethods={formMethods}
      isLoading={isLoading}
      onSubmit={onSubmit}
      dataHoraUltimaAlteracao={dataHoraUltimaAlteracao}
      dataHoraCadastro={dataHoraCadastro}
    >
      <UncontrolledForm />
    </ContainerListagem>
  );
};

export default Alterar;
