import {
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Box,
  Text,
} from '@chakra-ui/react';

import { DecimalMask } from 'helpers/format/fieldsMasks';
import { formatDate } from 'helpers/format/formatStringDate';
import {
  CasasDecimais,
  usePadronizacaoContext,
} from 'store/Padronizacao/Padronizacao';

type ItensConsignados = {
  data: Date;
  variacao: string;
  cliente: string;
  telefone: string;
  quantidade: number;
};

type DadosTabPanelProdutosConsignadosProps = {
  produtosConsignados: ItensConsignados[];
  casasDecimais?: CasasDecimais;
};

export const DadosTabPanelProdutosConsignados = ({
  produtosConsignados,
  casasDecimais,
}: DadosTabPanelProdutosConsignadosProps) => {
  const valorDecimal = usePadronizacaoContext();

  return (
    <>
      {produtosConsignados.length > 0 ? (
        <Table>
          <Thead>
            <Tr>
              <Th fontSize="16px" color="primary.500">
                <Flex w="40px" justifyContent="flex-end">
                  Data
                </Flex>
              </Th>

              <Th fontSize="16px" color="primary.500">
                Variação
              </Th>

              <Th fontSize="16px" color="primary.500">
                Nome do cliente
              </Th>
              <Th fontSize="16px" color="primary.500">
                Telefone
              </Th>
              <Th isNumeric fontSize="16px" color="primary.500">
                Quantidade
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {produtosConsignados.map((produto) => (
              <Tr>
                <Td color="black">{formatDate(produto.data)}</Td>
                <Td>{produto.variacao || '-'}</Td>
                <Td>{produto.cliente}</Td>
                <Td>{produto.telefone || '-'}</Td>
                <Td isNumeric>
                  {DecimalMask(
                    produto.quantidade,
                    casasDecimais
                      ? casasDecimais?.casasDecimaisQuantidade
                      : valorDecimal.casasDecimais?.casasDecimaisQuantidade,
                    valorDecimal.casasDecimais?.casasDecimaisQuantidade
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <Box>
          <Text>Nenhum resultado foi encontrado</Text>
        </Box>
      )}
    </>
  );
};
