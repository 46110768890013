import { Box, GridItem, Icon, Button, FormLabel, Flex } from '@chakra-ui/react';
import { RiShareLine } from 'react-icons/ri';
import { FormProvider } from 'react-hook-form';

import { useContextConciliacaoContas } from 'store/Financeiro/ConciliacaoContas';

import InputDateRange from 'components/PDV/InputDateRange';
import { BuscaAvancadaButton } from 'components/update/BuscaAvancadaButton';

export const DefaultActions = () => {
  const {
    confirmarDataEmissao,
    hasFilters,
    openModalBuscaAvancada,
    openModalCompartilharRelatorios,
    isListContaEmpty,
    formMethods,
  } = useContextConciliacaoContas();

  return (
    <Flex
      mb="24px"
      gap={['12px', '8px', '24px']}
      justifyContent={['flex-start', 'space-between', 'flex-start']}
      flexDirection={['column', 'row']}
    >
      <FormProvider {...formMethods}>
        <GridItem colSpan={[2]}>
          <Box>
            <FormLabel htmlFor="dataEmissao" mb="0px">
              Data de emissão
            </FormLabel>
            <InputDateRange
              name="dataEmissao"
              placeholder="Selecione a data de emissão"
              startDateName="dataEmissaoInicio"
              endDateName="dataEmissaoFim"
              borderRadius="5px"
              borderColor="gray.200"
              size="md"
              minW={['full', '230px', '270px']}
              onConfirm={confirmarDataEmissao}
            />
          </Box>
        </GridItem>
        <GridItem>
          <BuscaAvancadaButton
            bg="transparent"
            mt={['0px', '21px']}
            borderColor="gray.200"
            borderRadius="full"
            colorScheme={hasFilters ? 'blue' : 'gray'}
            hasFilters={hasFilters}
            height="36px"
            justifyContent="center"
            padding="10px 16px"
            setIsModalBuscaAvancadaOpen={openModalBuscaAvancada}
            width={['full', '188px']}
            variant={hasFilters ? 'solid' : 'outlineDefault'}
          >
            {hasFilters ? 'Filtro selecionado' : 'Busca avançada'}
          </BuscaAvancadaButton>
        </GridItem>
      </FormProvider>

      <GridItem>
        <Button
          mt={['0px', '21px']}
          onClick={openModalCompartilharRelatorios}
          variant="outlineDefault"
          colorScheme="gray"
          height="36px"
          isDisabled={isListContaEmpty}
          color="gray.700"
          leftIcon={<Icon as={RiShareLine} />}
          width={['full', '175px']}
          borderColor="gray.200"
          _disabled={{
            _hover: {
              color: 'gray.700',
            },
          }}
        >
          Compartilhar
        </Button>
      </GridItem>
    </Flex>
  );
};
