import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

type Tamanho = {
  produtoCorTamanhoId: string;
  tamanho: string;
  padraoSistema?: boolean;
};

export type ProdutoCor = {
  value: string;
  label: string;
  produtoNome: string;
  corDescricao?: string;
  volumeUnitario?: boolean;
  tamanhos?: Tamanho[];
};

type ProdutoCorTamanho = {
  value: string;
  label: string;
  padraoSistema?: boolean;
};

export type FormData = {
  produtoCor: ProdutoCor;
  produtoCorTamanho?: ProdutoCorTamanho | null;
  quantidade: number;
};

const schema = yup.object().shape({
  produtoCor: yup
    .object()
    .shape({
      value: yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .default(null),
      label: yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .default(null),
      produtoNome: yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .default(null),
      corDescricao: yup.string().nullable().default(null),
      volumeUnitario: yup.boolean().nullable().default(null),
      tamanhos: yup
        .array()
        .of(
          yup.object().shape({
            produtoCorTamanhoId: yup
              .string()
              .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
              .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
              .default(null),
            tamanho: yup
              .string()
              .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
              .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
              .default(null),
            padraoSistema: yup.boolean().nullable().default(null),
          })
        )
        .nullable()
        .default(null),
    })
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(null),
  produtoCorTamanho: yup
    .object()
    .shape({
      value: yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .default(null),
      label: yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .default(null),
    })
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(null),
  quantidade: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(1),
});

export const yupResolver = yupResolverInstance(schema);
