import { useEffect, useState } from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { FaturaItem } from './FaturaItem';

type Fatura = {
  faturaId: string;
  icone: string;
  nome: string;
  totalFatura: number;
  vencimento: string;
};

export function ListarFaturas() {
  const [isLoading, setIsLoading] = useState(false);
  const [faturas, setFaturas] = useState<Fatura[]>([]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const response = await api.get<void, ResponseApi<Fatura[]>>(
        ConstanteEnderecoWebservice.FATURA_LISTAR_MES_ATUAL
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response.sucesso && response.dados) {
          setFaturas(response.dados);
        }
      }

      setIsLoading(false);
    })();
  }, []);

  return (
    <Box>
      {isLoading && <LoadingPadrao />}

      <SimpleGrid
        templateColumns="repeat(auto-fill, minmax(264px, 264px))"
        spacing="6"
      >
        {faturas.length === 0 && (
          <p>Não existem faturas para serem exibidas.</p>
        )}
        {faturas.map((fatura) => (
          <FaturaItem key={fatura.faturaId} {...fatura} />
        ))}
      </SimpleGrid>
    </Box>
  );
}
