import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export interface ModalItemKitProps {
  show: boolean;
  onHide: () => void;
  onSaveAndInsertNew: () => void;
  valueKitIndex?: number;
  modalSubtitle?: string;
  getKits: () => Promise<void>;
}

export interface KitProps {
  id: string;
  produtoCorTamanhoPrincipalId: string;
  produtoCorTamanhoItemId: string;
  quantidade: number;
  valor: number;
  precoCusto: number;
  precoOriginal: number;
  produtoCorTamanho: {
    produtoCorTamanhoId: string;
    produto: string;
    cor: string;
    tamanho: string;
    imagem: string;
  };
  produtoCorTamanhoKitPrecoLojas: {
    produtoCorTamanhoKitId: string;
    lojaId: string;
    precoVenda: {
      precoVenda: number;
      markup: number;
    };
  }[];
}

export type ParentProdutoTamanhoKit = {
  id: string;
  produtoCorTamanhoId: string;
  produtoCorTamanhoItemId: string;
};

export type ProdutoTamanhoKitProps = {
  markup: number;
  precoCusto: number;
  quantidade: number;
  valor: number;
  tamanhoDescricao: string;
  value: string;
};

export type ProdutoCorListarSelect = {
  id?: string;
  corDescricao?: string;
  padraoSistema?: boolean;
  precoCusto?: number;
  produtoNome?: string;
  label?: string;
  value?: string;
  imagem?: string;
  precoVenda?: number;
};

export type ProdutoTamanhoProps = {
  label: string;
  value: string;
  id?: string;
  markup: number;
  precoVenda: number;
  precoCusto: number;
  padraoSistema: boolean;
};

export type ProdutoTamanhoResponseProps = {
  descricao: string;
  id: string;
  markup: number;
  precoVenda: number;
  precoCusto: number;
  padraoSistema: boolean;
};

export type FormData = {
  valorTotal: number;
  valor: number;
  quantidade: number;
  produtoCorTamanhoId: ProdutoTamanhoProps | null;
  produtoCorId: ProdutoCorListarSelect | null;
  produtoNovo: ProdutoCorListarSelect | null;
};

export const formDefaultValue = {
  valorTotal: 0,
  valor: 0,
  quantidade: 1,
  produtoCorTamanhoId: null,
  produtoCorId: null,
};

const shapeKits = {
  produtoCorId: yup
    .object()
    .nullable()
    .test(
      'produtoCorId',
      ConstanteMensagemValidacao.CAMPO_OBRIGATORIO,
      function verificarProdutoCorId() {
        const { produtoCorTamanhoId, produtoCorId } = this.parent;

        if (!produtoCorTamanhoId && !produtoCorId) {
          return false;
        }

        return true;
      }
    )
    .default(null),
  produtoCorTamanhoId: yup
    .object()
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(null),
  quantidade: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .test(
      'quantidade',
      ConstanteMensagemValidacao.VALOR_MINIMO_CADASTRO_KIT,
      (value) => {
        if (value && value > 0.009) return true;

        return false;
      }
    )
    .default(1),
  valor: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(0),
  valorTotal: yup.number(),
};

export const schemaKits = yup.object().shape(shapeKits);

export const yupResolver = yupResolverInstance(schemaKits);

export const useForm = useFormInstance;
