import auth from '../modules/auth';

export const useListPermissoesValidar = (listaPermissoes: string[]) => {
  let permissaoAtual: string | undefined;

  listaPermissoes.some((valorFuncionalidade) => {
    const { permitido } = auth.possuiPermissao(valorFuncionalidade);

    if (permitido) {
      permissaoAtual = valorFuncionalidade;
      return true;
    }

    return false;
  });

  return permissaoAtual || listaPermissoes[0];
};
