/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Col, Form, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { toast } from 'react-toastify';

import auth from 'modules/auth';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import enumReferenciaServicoStargate from 'constants/enum/referenciaServicoStargate';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';

import SelectTabelaPreco from 'components/Select/SelectTabelaPreco';
import { BootstrapNumberInput } from 'components/update/Input/BootstrapNumberInput';
import CheckBoxDefault from 'components/CheckBox/CheckBoxDefault';
import AccordionPadrao from 'components/Accordion/AccordionPadrao';
import InputNull from 'components/Input/InputNull';
import InputNumerico from 'components/Input/InputNumerico';
import { SelectVirtualized } from 'components/PDV/Select/SelectVirtualized';
import { PaginationData } from 'components/update/Pagination';
import { GridPaginadaRetorno } from 'components/Grid/Paginacao';

interface OutrasInformacoesFormInterface {
  errors: FieldErrors;
  readonly?: boolean;
  control: any;
  isCpf: boolean;
  setValue?: any;
  rotaCliente: any;
  isAlteracao?: boolean;
}

type DeliverySectorProps = {
  ativo: boolean;
  id: string;
  nome: string;
  nomeEcommerce: string | null;
};

const OutrasInformacoes = ({
  errors,
  readonly,
  control,
  isCpf,
  setValue,
  rotaCliente,
  isAlteracao,
}: OutrasInformacoesFormInterface) => {
  const { t } = useTranslation();

  const { id: lojaId } = auth.getLoja();

  const [totalRegistros, setTotalRegistros] = useState(0);

  const possuiServicoFrenteCaixa = auth.possuiServico(
    enumReferenciaServicoStargate.DISPOSITIVO_FRENTE_CAIXA
  ).permitido;

  const getListDeliverySectors = useCallback(
    async (inputValue: string, dataPagination: PaginationData) => {
      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<DeliverySectorProps>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.SETOR_ENTREGA_SELECT_PAGINADO.replace(
            'id',
            lojaId
          ),
          {
            ...dataPagination,
          }
        ),
        {
          params: {
            pesquisa: inputValue,
          },
        }
      );

      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        setTotalRegistros(response.dados.total);
        if (response?.sucesso && response?.dados) {
          return response.dados.registros.map(({ id, nome }) => ({
            label: nome,
            value: id,
          }));
        }
      }

      return [];
    },
    [lojaId]
  );

  useEffect(() => {
    if (!isAlteracao) {
      setValue('consumidorFinal', isCpf);
      setValue('contribuinteIcms', !isCpf);
    }
  }, [isAlteracao, isCpf, setValue]);

  return (
    <>
      <AccordionPadrao title="Outras informações" defaultOpen>
        <Container fluid>
          {rotaCliente && (
            <Form.Row
              style={{
                paddingTop: '0px',
                paddingBottom: '0px',
              }}
            >
              <Col xl={4} lg={6} md={6} sm={6}>
                <SelectTabelaPreco
                  type="text"
                  id="tabelaPreco"
                  name="tabelaPreco"
                  label={t('Tabela de preços')}
                  placeholder={t('Digite o nome da tabela de preço')}
                  control={control}
                  error={errors.tabelaPreco}
                  isDisabled={readonly}
                  getSelectedTabelaPreco={() => {
                    return false;
                  }}
                />
              </Col>
              <Col xl={4} lg={6} md={6} sm={6}>
                <BootstrapNumberInput
                  id="limiteCredito"
                  name="limiteCredito"
                  label={t('Limite de crédito')}
                  leftElement="R$"
                  precision={11}
                  scale={2}
                  control={control}
                  error={errors.limiteCredito}
                  disabled={readonly}
                />
              </Col>
            </Form.Row>
          )}

          <Form.Row
            style={{
              paddingTop: '0px',
              paddingBottom: '0px',
            }}
          >
            {rotaCliente && (
              <Col xl={4} lg={6} md={6} sm={6}>
                <InputNumerico
                  type="text"
                  id="suframa"
                  name="suframa"
                  label={t('Suframa')}
                  placeholder={t('Informe o suframa')}
                  maxLength={9}
                  error={errors.suframa}
                  disabled={readonly}
                  control={control}
                />
              </Col>
            )}
            <Col xl={4} lg={6} md={6} sm={6}>
              <InputNull
                id="documentoEstrangeiro"
                name="documentoEstrangeiro"
                label={t('Documento estrangeiro')}
                placeholder={t('Digite o documento estrangeiro')}
                control={control}
                maxLength={20}
                error={errors.documentoEstrangeiro}
                disabled={readonly}
              />
            </Col>
            <Col xl={4} lg={6} md={6} sm={6}>
              <InputNull
                id="codigoExterno"
                name="codigoExterno"
                label={t('Código externo')}
                placeholder={t('Digite o código externo')}
                control={control}
                error={errors.codigoExterno}
                disabled={readonly}
              />
            </Col>
          </Form.Row>

          <Form.Row>
            <Col xl={4} lg={6} md={6} sm={12} xs={12}>
              <span
                style={{
                  marginBottom: '-2px important',
                  fontWeight: 600,
                  lineHeight: '1.5',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  maxWidth: '100%',
                }}
              >
                Fiscais e tributárias
              </span>
              <div
                style={{
                  minWidth: '100%',
                  borderTop: '1px solid var(--sti-ck-colors-gray-100)',
                  borderLeft: '1px solid var(--sti-ck-colors-gray-100)',
                  borderRight: '1px solid var(--sti-ck-colors-gray-100)',
                  borderBottom: '1px solid var(--sti-ck-colors-gray-100)',
                  borderRadius: '5px',
                }}
              >
                <Col xl={12}>
                  <span style={{ display: 'block' }}>
                    {t('Consumidor final')}
                    <div style={{ float: 'right' }}>
                      <CheckBoxDefault
                        id="consumidorFinal"
                        name="consumidorFinal"
                        label=""
                        control={control}
                        readonly={readonly}
                      />
                    </div>
                  </span>
                </Col>
                <Col
                  xl={11}
                  lg={11}
                  md={11}
                  sm={11}
                  xs={11}
                  style={{
                    borderTop: '1px solid var(--sti-ck-colors-gray-100)',
                    margin: 'auto',
                    padding: '0',
                  }}
                />
                <Col xl={12}>
                  <span style={{ display: 'block' }}>
                    {t('Contribuinte de ICMS')}
                    <div style={{ float: 'right' }}>
                      <CheckBoxDefault
                        id="contribuinteIcms"
                        name="contribuinteIcms"
                        label=""
                        readonly={!!(isCpf || readonly)}
                        control={control}
                      />
                    </div>
                  </span>
                </Col>
              </div>
            </Col>
            {!!(possuiServicoFrenteCaixa && rotaCliente) && (
              <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                <SelectVirtualized
                  id="setorEntrega"
                  name="setorEntrega"
                  label="Setor de entrega"
                  placeholder="Digite o nome do setor de entrega e selecione"
                  handleGetOptions={getListDeliverySectors}
                  totalRegistros={totalRegistros}
                  styles={{
                    control: () => ({
                      borderRadius: '.25rem !important',
                      borderColor: '#E8E8E8 !important',
                    }),
                    placeholder: (base) => ({
                      ...base,
                      fontSize: '13px !important',
                    }),
                    singleValue: () => ({
                      fontSize: '13px !important',
                    }),
                  }}
                  asControlledByObject
                  isClearable
                />
              </Col>
            )}
          </Form.Row>
        </Container>
      </AccordionPadrao>
    </>
  );
};

export default memo(OutrasInformacoes);
