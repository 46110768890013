import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Divider,
  Flex,
  GridItem,
  HStack,
  Stack,
  Tag,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import { TipoOcorrenciaEnum } from 'constants/enum/tipoOcorrencia';
import getOptionsByEnum from 'helpers/format/getOptionsByEnum';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import TipoPlanoContaEnum from 'constants/enum/tipoPlanoConta';
import { TipoCompetenciaEnum } from 'constants/enum/tipoCompetencia';
import { StatusPesquisaClientesFornecedor } from 'constants/enum/statusPesquisaClientesFornecedor';
import auth from 'modules/auth';
import TipoContaEnum from 'constants/enum/tipoContaEnum';
import ConstanteFuncionalidades from 'constants/permissoes';

import { SimpleCard } from 'components/update/Form/SimpleCard';
import { NumberInput } from 'components/update/Input/NumberInput';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import Select from 'components/PDV/Select/SelectPadrao';
import { DateInput } from 'components/update/Input/DateInput';
import Input from 'components/PDV/Input';
import { Switch } from 'components/update/Switch';
import { MonthInput } from 'components/update/Input/MonthInput';
import AsyncCreatableSelect from 'components/PDV/Select/AsyncCreatableSelectPadrao';

import { DecimalMask } from 'helpers/format/fieldsMasks';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';
import ModalCadastrarCliente from 'components/PDV/Modal/ModalCadastrarCliente';
import { ModalCadastrarFornecedor } from 'pages/EntradaMercadoria/EtapasCompartilhadas/EscolherFornecedor/ModalCadastrarFornecedor';

import { formatOptionsSelectClient } from 'helpers/format/formatSelectClient';
import { BaixarContaForm } from './BaixarContaForm';
import { GerarParcelasForm, ParcelaGerada } from './GerarParcelasForm';
import { ListagemParcelas } from './ListagemParcelas';
import { Parcelas } from './validationForm';

export type ContasFinanceirasProps = {
  nome: string;
  id: string;
}[];

type FormaPagamentoOptionResponse = {
  id: string;
  nome: string;
  lancarFatura: boolean;
  diaVencimento: number;
  contasFinanceiras: ContasFinanceirasProps;
};

type PlanoContasOptionResponse = {
  id: string;
  nome: string;
  tipoPlanoConta: number;
};

type ClienteFornecedorOptionResponse = {
  id: string;
  nome: string;
  endereco: string;
  cpfCnpj: string;
  codigo: number;
};

type ContaFinanceiraOptionResponse = {
  id: string;
  nome: string;
  padraoSistema: boolean;
};

type FormaRecebimentoProps = {
  id: string;
  nome: string;
  contaFinanceiraId: string;
  parcelas: Parcelas[];
};

type Option = {
  label: string;
  value: string;
  lancarFatura?: boolean;
};
type OptionContaFinanceira = {
  label: string;
  value: string;
  lancarFatura?: boolean;
  padraoSistema: boolean;
};

interface FormularioLancamentoContasProps {
  estaAlterando?: boolean;
  podeAlterarParcelas?: boolean;
  errors?: any;
  isReadOnly?: boolean;
  nameCurrentOperation?: string;
}

export function FormularioLancamentoContas({
  podeAlterarParcelas = true,
  errors,
  nameCurrentOperation,
  isReadOnly,
}: FormularioLancamentoContasProps) {
  const { casasDecimais } = usePadronizacaoContext();
  const { watch, setValue } = useFormContext();

  const [isSmallerThan1024] = useMediaQuery('(max-width: 1024px)');

  const history = useHistory();
  const location = history.location.pathname;

  const typeOfPlanoConta = location.includes('contas-pagar')
    ? TipoPlanoContaEnum.DEBITO
    : TipoPlanoContaEnum.CREDITO;

  const [
    baixarConta,
    tipoOcorrencia,
    repeticoes,
    totalBaixa,
    valueOfInstallments,
    planoConta,
    formaPagamento,
    dataLancamento,
    faturaCartaoCredito,
    dataVencimento,
  ] = watch([
    'baixarConta',
    'tipoOcorrencia',
    'repeticoes',
    'valorTotal',
    'valueOfInstallments',
    'planoConta',
    'formaPagamento',
    'dataLancamento',
    'faturaCartaoCredito',
    'dataVencimento',
  ]);
  const latestProps = useRef({ setValue });
  useEffect(() => {
    latestProps.current = { setValue };
  });

  const onBlurRepeticoes = document.getElementById('repeticoes');

  const [
    isPlanosContasOptionsLoading,
    setIsPlanosContasOptionsLoading,
  ] = useState(false);
  const [planosContasOptions, setPlanosContasOptions] = useState<Option[]>([]);
  const [
    modalCadastrarClienteIsOpen,
    setModalCadastrarClienteIsOpen,
  ] = useState(false);

  const [nomeClieteOuFornecedor, setNomeClieteOuFornecedor] = useState('');
  const [
    formasPagamentoERecebimento,
    setFormasPagamentoERecebimento,
  ] = useState<Option[]>([]);

  const [
    isContasFinanceirasOptionsLoading,
    setIsContasFinanceirasOptionsLoading,
  ] = useState(false);

  const [
    isFormaRecebimentoOptionsLoading,
    setisFormaRecebimentoOptionsLoading,
  ] = useState(false);

  const [contasFinanceirasOptions, setContasFinanceirasOptions] = useState<
    OptionContaFinanceira[]
  >([]);

  const alreadyFetchedContasFinanceirasOptions = useRef(false);

  const getPlanosContaOptions = useCallback(async () => {
    setIsPlanosContasOptionsLoading(true);

    const response = await api.get<
      void,
      ResponseApi<PlanoContasOptionResponse[]>
    >(ConstanteEnderecoWebservice.PLANO_CONTA_LISTAR_SELECT, {
      params: { tipoPlanoConta: typeOfPlanoConta },
    });

    if (response) {
      if (response.avisos) {
        response.avisos.map((aviso: string) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        const newPlanosContasOptions = response.dados.map((item) => ({
          label: item.nome,
          value: item.id,
          tipoPlanoConta: item.tipoPlanoConta,
        }));

        setPlanosContasOptions(newPlanosContasOptions);
      }
    }

    setIsPlanosContasOptionsLoading(false);
  }, [typeOfPlanoConta]);

  const getFormasPagamentoOptions = useCallback(async () => {
    setisFormaRecebimentoOptionsLoading(true);
    const response = await api.get<
      void,
      ResponseApi<FormaPagamentoOptionResponse[]>
    >(ConstanteEnderecoWebservice.FORMA_PAGAMENTO_LISTAR_SELECT_PAGAMENTO);

    if (response) {
      if (response.avisos) {
        response.avisos.map((aviso: string) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        const data = response.dados.map((item) => ({
          label: item.nome,
          value: item.id,
          lancarFatura: item.lancarFatura,
          diaVencimento: item.diaVencimento,
          contasFinanceiras: item.contasFinanceiras,
        }));

        setFormasPagamentoERecebimento(data);
        setisFormaRecebimentoOptionsLoading(false);
        return data;
      }
    }
    setisFormaRecebimentoOptionsLoading(false);
    return [];
  }, []);

  const getFormaPagamentoRecebimentoOption = useCallback(async () => {
    setisFormaRecebimentoOptionsLoading(true);

    const response = await api.get<void, ResponseApi<FormaRecebimentoProps[]>>(
      ConstanteEnderecoWebservice.FORMA_RECEBIMENTO_LISTAR_SELECT_BAIXA
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        setisFormaRecebimentoOptionsLoading(false);
        return response.dados.map((formaPagamentoItem) => ({
          value: formaPagamentoItem.id,
          label: formaPagamentoItem.nome,
          contaFinanceiraId: formaPagamentoItem.contaFinanceiraId,
          parcelas: formaPagamentoItem.parcelas,
        }));
      }
    }
    setisFormaRecebimentoOptionsLoading(false);
    return [];
  }, []);

  const getFormasRecebimentosOptions = useCallback(async () => {
    const response = await api.get<
      void,
      ResponseApi<FormaPagamentoOptionResponse[]>
    >(
      ConstanteEnderecoWebservice.FORMA_PAGAMENTO_RECEBIMENTO_LISTAR_RECEBIMENTOS
    );

    if (response) {
      if (response.avisos) {
        response.avisos.map((aviso: string) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        const data = response.dados.map((item) => ({
          label: item.nome,
          value: item.id,
          contaFinanceiraId: null,
        }));

        setFormasPagamentoERecebimento(data);

        return data;
      }
    }

    return [];
  }, []);

  const getClientesFornecedoresOptions = useCallback(
    async (inputValue: string) => {
      const response = await api.get<
        void,
        ResponseApi<ClienteFornecedorOptionResponse[]>
      >(ConstanteEnderecoWebservice.CLIENTE_FORNECEDOR_LISTAR_SELECT, {
        params: {
          cpfCnpjNomeApelidoCodigoExterno: inputValue,
          filtroTipoCadastroPessoa:
            typeOfPlanoConta === TipoPlanoContaEnum.DEBITO
              ? StatusPesquisaClientesFornecedor.FORNECEDORES
              : StatusPesquisaClientesFornecedor.CLIENTES,
        },
      });

      if (response) {
        if (response.avisos) {
          response.avisos.map((aviso: string) => toast.warning(aviso));
        }

        if (response.sucesso && response.dados) {
          return response.dados.map((clienteFornecedor) => ({
            ...formatOptionsSelectClient(clienteFornecedor),
          }));
        }
      }
      return [];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const possuiPermissaoCadastrarFornecedor = auth.possuiPermissao(
    ConstanteFuncionalidades.FORNECEDOR_CADASTRAR
  ).permitido;
  const possuiPermissaoCadastrarCliente = auth.possuiPermissao(
    ConstanteFuncionalidades.CLIENTE_CADASTRAR.codigo
  ).permitido;

  async function handleAdicionarCliente(inputValue: string) {
    if (nameCurrentOperation === TipoContaEnum.CONTA_RECEBER) {
      setModalCadastrarClienteIsOpen(true);
      setNomeClieteOuFornecedor(inputValue);
    }

    if (nameCurrentOperation === TipoContaEnum.CONTA_PAGAR) {
      const { fornecedor } = await ModalCadastrarFornecedor({ inputValue });

      return {
        value: fornecedor.id,
        label: fornecedor.nome,
      };
    }

    return undefined;
  }

  const atualizarClienteCallback = useCallback(
    async (novoCliente: { nome: string; id: string }) => {
      const clienteNovoAtt = {
        value: novoCliente.id,
        label: novoCliente.nome,
      };
      latestProps.current.setValue('clienteFornecedor', clienteNovoAtt);
    },
    []
  );

  const getContasFinanceirasOptions = useCallback(async () => {
    setIsContasFinanceirasOptionsLoading(true);

    const response = await api.get<
      void,
      ResponseApi<ContaFinanceiraOptionResponse[]>
    >(
      ConstanteEnderecoWebservice.CONTA_FINANCEIRA_LISTAR_CONTAS_FINANCEIRAS_MENOS_CAIXAS
    );

    if (response) {
      if (response.avisos) {
        response.avisos.map((aviso: string) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        const newContasFinanceirasOptions = response.dados.map((item) => ({
          label: item.nome,
          value: item.id,
          padraoSistema: item.padraoSistema,
        }));

        setContasFinanceirasOptions(newContasFinanceirasOptions);
      }
    }

    setIsContasFinanceirasOptionsLoading(false);
  }, []);

  const onSubmitGerarParcelas = useCallback(
    (parcelasGeradas: ParcelaGerada[], quantidadeParcelas: number) => {
      const novasParcelas = parcelasGeradas.map((parcela) => ({
        ...parcela,
      }));

      latestProps.current.setValue('parcelas', novasParcelas);
      latestProps.current.setValue('quantidadeParcelas', quantidadeParcelas);
    },
    []
  );

  useEffect(() => {
    const valueTotal = totalBaixa / (repeticoes === 0 ? 1 : repeticoes);
    latestProps.current.setValue('valueOfInstallments', valueTotal);

    if (onBlurRepeticoes) {
      onBlurRepeticoes.onblur = () => {
        if (repeticoes === 0 && onBlurRepeticoes) {
          latestProps.current.setValue('repeticoes', 1);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repeticoes, totalBaixa]);

  useEffect(() => {
    getPlanosContaOptions();
  }, [getPlanosContaOptions]);

  useEffect(() => {
    if (typeOfPlanoConta === TipoPlanoContaEnum.DEBITO) {
      getFormasPagamentoOptions();
    } else {
      getFormasRecebimentosOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      baixarConta &&
      !alreadyFetchedContasFinanceirasOptions.current &&
      typeOfPlanoConta === TipoPlanoContaEnum.CREDITO
    ) {
      alreadyFetchedContasFinanceirasOptions.current = true;
      getContasFinanceirasOptions();
    }
  }, [baixarConta, getContasFinanceirasOptions, typeOfPlanoConta]);

  useEffect(() => {
    if (formaPagamento && formaPagamento.lancarFatura) {
      latestProps.current.setValue(
        'tipoOcorrencia',
        TipoOcorrenciaEnum.PARCELADA
      );
      latestProps.current.setValue('repeticoes', 1);
    } else {
      latestProps.current.setValue('tipoOcorrencia', TipoOcorrenciaEnum.UNICA);
    }
  }, [formaPagamento]);

  useEffect(() => {
    if (tipoOcorrencia === TipoOcorrenciaEnum.PARCELADA) {
      latestProps.current.setValue(
        'tipoCompetencia',
        TipoCompetenciaEnum.MES_VENCIMENTO
      );

      latestProps.current.setValue('baixarConta', false);
    } else if (tipoOcorrencia === TipoOcorrenciaEnum.UNICA) {
      latestProps.current.setValue('tipoCompetencia', null);

      latestProps.current.setValue('parcelas', 0);
    } else {
      latestProps.current.setValue('baixarConta', false);
      latestProps.current.setValue('dataCompetencia', undefined);
      latestProps.current.setValue(
        'tipoCompetencia',
        TipoCompetenciaEnum.MES_VENCIMENTO
      );
      latestProps.current.setValue('parcelas', 0);
      latestProps.current.setValue('repeticoes', 1);
    }
  }, [tipoOcorrencia]);

  useEffect(() => {
    if (!baixarConta) {
      latestProps.current.setValue('juros', 0);
      latestProps.current.setValue('multa', 0);
      latestProps.current.setValue('desconto', 0);
      latestProps.current.setValue('valorTotalBaixa', 0);
      latestProps.current.setValue('dataBaixa', new Date());
      latestProps.current.setValue('contaFinanceira', null);
    }
  }, [baixarConta]);

  useEffect(() => {
    const dateNow = new Date();

    if (
      (formaPagamento?.diaVencimento <= dateNow?.getDate() ||
        formaPagamento?.diaVencimento <= dataLancamento?.getDate()) &&
      (dataLancamento?.getMonth() !== faturaCartaoCredito?.getMonth() - 1 ||
        dataLancamento?.getMonth() === faturaCartaoCredito?.getMonth())
    ) {
      setValue(
        'faturaCartaoCredito',
        new Date(
          dateNow.getFullYear(),
          dataLancamento.getMonth() + 1,
          formaPagamento.diaVencimento,
          0,
          0,
          0
        )
      );
    } else if (
      formaPagamento?.diaVencimento > dataLancamento?.getDate() &&
      dataLancamento?.getMonth() !== faturaCartaoCredito?.getMonth()
    ) {
      setValue(
        'faturaCartaoCredito',
        new Date(
          dateNow.getFullYear(),
          dataLancamento.getMonth(),
          formaPagamento.diaVencimento,
          0,
          0,
          0
        )
      );
    } else if (
      faturaCartaoCredito?.getDate() !== formaPagamento?.diaVencimento &&
      formaPagamento?.lancarFatura
    ) {
      setValue(
        'faturaCartaoCredito',
        new Date(
          faturaCartaoCredito.getFullYear(),
          faturaCartaoCredito.getMonth(),
          formaPagamento?.diaVencimento,
          0,
          0,
          0
        )
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formaPagamento, dataLancamento]);

  useEffect(() => {
    latestProps.current.setValue('data', dataLancamento);
  }, [dataLancamento]);

  return (
    <SimpleCard>
      <SimpleCard boxShadow="none" bg="gray.50">
        <SimpleGridForm rowGap="24px">
          <Select
            id="planoConta"
            isDisabled={isReadOnly}
            name="planoConta"
            errorPropName="value"
            label="Plano de contas"
            placeholder="Selecione o plano de conta"
            options={planosContasOptions}
            isLoading={isPlanosContasOptionsLoading}
            asControlledByObject
            colSpan={12}
            required
            topRightElement={
              planoConta ? (
                <Tag
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="full"
                  size="sm"
                  minH="16px"
                  fontWeight="semibold"
                  px="4"
                  {...(planoConta.tipoPlanoConta === TipoPlanoContaEnum.CREDITO
                    ? { bg: 'secondary.400', color: 'black' }
                    : { bg: 'red.500', color: 'white' })}
                >
                  {planoConta.tipoPlanoConta === TipoPlanoContaEnum.CREDITO
                    ? 'Crédito'
                    : 'Débito'}
                </Tag>
              ) : undefined
            }
          />

          <NumberInput
            name="valorTotal"
            label="Valor total"
            isRequired
            isDisabled={isReadOnly}
            leftElement="R$"
            editarFundoLeftElemento
            bgLeftElement="gray.50"
            leftElementFontSize="xs"
            colSpan={{ base: 12, sm: 12, md: 3 }}
          />
          <GridItem colSpan={[12, 12, 6]} position="relative">
            <Select
              id="formaPagamento"
              name="formaPagamento"
              errorText={errors.formaPagamento?.message}
              errorPropName="value"
              label={
                typeOfPlanoConta === TipoPlanoContaEnum.CREDITO
                  ? 'Forma de recebimento'
                  : 'Forma de pagamento'
              }
              placeholder={
                typeOfPlanoConta === TipoPlanoContaEnum.CREDITO
                  ? 'Selecione a forma de recebimento'
                  : 'Selecione a forma de pagamento'
              }
              required
              isDisabled={isReadOnly}
              options={formasPagamentoERecebimento}
              asControlledByObject
              colSpan={{ base: 12, sm: 12, md: 6 }}
            />
            {formaPagamento && formaPagamento.lancarFatura && (
              <Text
                position="absolute"
                right="0px"
                bottom={isSmallerThan1024 ? '-18px' : '38px'}
                mt="2px"
                fontWeight="semibold"
                color="red.500"
                fontSize="xs"
              >
                Fatura cartão de crédito
              </Text>
            )}
          </GridItem>

          <Select
            id="tipoOcorrencia"
            name="tipoOcorrencia"
            label="Ocorrência"
            required
            options={getOptionsByEnum(TipoOcorrenciaEnum)}
            colSpan={{ base: 12, sm: 12, md: 3 }}
            isDisabled={
              (formaPagamento && formaPagamento.lancarFatura) || isReadOnly
            }
          />
        </SimpleGridForm>
      </SimpleCard>

      <SimpleGridForm mt={{ base: 3, sm: 6, md: 8 }}>
        <AsyncCreatableSelect
          id="clienteFornecedor"
          name="clienteFornecedor"
          label={
            typeOfPlanoConta === TipoPlanoContaEnum.CREDITO
              ? 'Cliente'
              : 'Fornecedor'
          }
          placeholder="Informe o nome para selecionar"
          required
          isDisabled={isReadOnly}
          errorPropName="value"
          handleGetOptions={getClientesFornecedoresOptions}
          creatableInputTextPreffix={
            typeOfPlanoConta === TipoPlanoContaEnum.CREDITO
              ? 'Cadastrar o cliente'
              : 'Cadastrar o fornecedor'
          }
          handleCreateOption={handleAdicionarCliente}
          asControlledByObject
          creatableButtonShow={
            typeOfPlanoConta === TipoPlanoContaEnum.CREDITO
              ? possuiPermissaoCadastrarCliente
              : possuiPermissaoCadastrarFornecedor
          }
          colSpan={
            formaPagamento && formaPagamento.lancarFatura
              ? { base: 12, sm: 8, md: 12, lg: 5, xl: 3 }
              : tipoOcorrencia === TipoOcorrenciaEnum.PARCELADA
              ? { base: 12, sm: 8, md: 12, lg: 5, xl: 6 }
              : { base: 12, sm: 8, md: 8, lg: 5, xl: 6 }
          }
          shouldAppearTheAddress
        />

        {tipoOcorrencia !== TipoOcorrenciaEnum.PARCELADA && (
          <DateInput
            name="dataVencimento"
            isDisabled={isReadOnly}
            label="Data de vencimento"
            colSpan={{ base: 12, sm: 4, lg: 4 }}
          />
        )}

        {formaPagamento && formaPagamento?.lancarFatura ? (
          <MonthInput
            name="faturaCartaoCredito"
            label="Fatura de vencimento do cartão"
            isDisabled={isReadOnly}
            colSpan={{ base: 12, md: 4, lg: 4 }}
            isRequired
            dia={formaPagamento?.diaVencimento}
          />
        ) : null}

        {tipoOcorrencia === TipoOcorrenciaEnum.REPETIR ||
        (tipoOcorrencia === TipoOcorrenciaEnum.PARCELADA &&
          formaPagamento?.lancarFatura) ? (
          <Select
            name="tipoCompetencia"
            label="Competência"
            isDisabled={isReadOnly}
            required
            options={getOptionsByEnum(TipoCompetenciaEnum)}
            colSpan={{ base: 12, sm: 4, lg: 3 }}
          />
        ) : (
          <MonthInput
            name="dataCompetencia"
            isDisabled={isReadOnly}
            isRequired
            label="Competência"
            colSpan={{ base: 12, sm: 4, md: 4, lg: 3, xl: 2 }}
          />
        )}

        <Input
          id="numeroDocumento"
          isDisabled={isReadOnly}
          name="numeroDocumento"
          label="Número do documento"
          placeholder="Digite o número"
          maxLength={20}
          colSpan={
            tipoOcorrencia === TipoOcorrenciaEnum.PARCELADA
              ? { base: 12, sm: 4, lg: 4 }
              : { base: 12, sm: 4, lg: 5 }
          }
        />

        <DateInput
          name="dataLancamento"
          isDisabled={isReadOnly}
          label="Data de emissão"
          maxDate={dataVencimento}
          colSpan={
            tipoOcorrencia === TipoOcorrenciaEnum.PARCELADA
              ? { base: 12, sm: 4, lg: 5 }
              : { base: 12, sm: 4, lg: 4 }
          }
        />

        <Input
          id="historico"
          name="historico"
          isDisabled={isReadOnly}
          label="Histórico"
          placeholder="Informe o motivo ou detalhes importantes do lançamento"
          maxLength={500}
          colSpan={
            tipoOcorrencia === TipoOcorrenciaEnum.PARCELADA &&
            formaPagamento?.lancarFatura
              ? { base: 12, sm: 12, md: 12, lg: 10 }
              : { base: 12, sm: 12, md: 12, lg: 12 }
          }
        />

        {tipoOcorrencia === TipoOcorrenciaEnum.PARCELADA &&
          podeAlterarParcelas &&
          !formaPagamento?.lancarFatura && (
            <GridItem colSpan={12}>
              <GerarParcelasForm
                onSubmit={onSubmitGerarParcelas}
                isReadOnly={isReadOnly}
              />
            </GridItem>
          )}
      </SimpleGridForm>

      {tipoOcorrencia === TipoOcorrenciaEnum.UNICA && (
        <>
          <Divider orientation="horizontal" mt={{ base: 3, sm: 6, md: 8 }} />

          <Flex
            mt="6"
            alignItems="center"
            justifyContent="flex-start"
            sx={{
              '& .chakra-form-control': { w: 'auto' },
              '& .chakra-switch': {
                mb: '0px !important',
                maxH: '16px',
              },
            }}
          >
            <Switch
              name="baixarConta"
              size="sm"
              colorScheme="blue"
              isDisabled={isReadOnly}
            />

            <Text
              as="label"
              htmlFor="baixarConta"
              mb="0"
              ml="4"
              lineHeight="none"
              fontSize="sm"
              fontWeight="semibold"
              whiteSpace="nowrap"
            >
              Baixar a conta
            </Text>
          </Flex>

          {baixarConta && (
            <BaixarContaForm
              isContasFinanceirasOptionsLoading={
                isContasFinanceirasOptionsLoading
              }
              isContasReceber={typeOfPlanoConta === TipoPlanoContaEnum.CREDITO}
              getFormaRecebimentoOptions={
                typeOfPlanoConta === TipoPlanoContaEnum.CREDITO
                  ? getFormaPagamentoRecebimentoOption
                  : getFormasPagamentoOptions
              }
              isFormaRecebimentoOptionsLoading={
                isFormaRecebimentoOptionsLoading
              }
              contasFinanceirasOptions={contasFinanceirasOptions}
              isReadOnly={isReadOnly}
            />
          )}
        </>
      )}

      {tipoOcorrencia === TipoOcorrenciaEnum.PARCELADA &&
        !formaPagamento?.lancarFatura && (
          <ListagemParcelas
            podeAlterarParcelas={podeAlterarParcelas}
            isReadOnly={isReadOnly}
          />
        )}

      {tipoOcorrencia === TipoOcorrenciaEnum.REPETIR ||
      (tipoOcorrencia === TipoOcorrenciaEnum.PARCELADA &&
        formaPagamento?.lancarFatura) ? (
        <Stack
          direction={{ base: 'column', sm: 'row' }}
          mt={{ base: 3, sm: 6, md: 8 }}
          px="7"
          py="5"
          bg="primary.500"
          borderRadius="md"
          spacing="3"
          alignItems={{ base: 'flex-start', sm: 'center' }}
        >
          <Text color="white">
            {formaPagamento?.lancarFatura
              ? 'Parcelado em'
              : 'Repetir este pagamento por'}
          </Text>

          <HStack spacing="3">
            <NumberInput
              isDisabled={isReadOnly}
              id="repeticoes"
              name="repeticoes"
              defaultValue={1}
              inputMode="numeric"
              scale={0}
              min={1}
              max={24}
              step={1}
              size="sm"
              maxW="50px"
              pl="4"
            />

            <Text
              color={formaPagamento?.lancarFatura ? 'secondary.300' : 'white'}
            >
              {formaPagamento?.lancarFatura
                ? `- ${repeticoes}x de R$ ${DecimalMask(
                    valueOfInstallments,
                    casasDecimais.casasDecimaisQuantidade,
                    casasDecimais.casasDecimaisQuantidade
                  )}`
                : 'meses.'}
            </Text>
          </HStack>
        </Stack>
      ) : null}
      {modalCadastrarClienteIsOpen && (
        <ModalCadastrarCliente
          isOpen={modalCadastrarClienteIsOpen}
          setIsOpen={setModalCadastrarClienteIsOpen}
          nomeOuCpfCnpjCliente={nomeClieteOuFornecedor}
          novoClienteCallback={atualizarClienteCallback}
        />
      )}
    </SimpleCard>
  );
}
