import { useContextConciliacaoContas } from 'store/Financeiro/ConciliacaoContas';
import { Conta } from 'store/Financeiro/ConciliacaoContas/validationsForm';

export const useListagemContasConcilicao = () => {
  const {
    setListContas,
    listContas,
    isAllContasSelected,
    formMethods: { getValues },
  } = useContextConciliacaoContas();

  const toggleContasSelecionadas = () => {
    const updatedList = listContas.map((conta) => ({
      ...conta,
      isChecked: false,
    }));
    setListContas(updatedList);
  };

  const toggleSelectAll = () => {
    const updatedList = listContas.map((conta) => ({
      ...conta,
      isChecked: conta.conciliado ? conta.isChecked : !isAllContasSelected,
    }));
    setListContas(updatedList);
  };

  const toggleSelect = (id: string) => {
    const updatedList = listContas.map((conta) =>
      conta.idMovimentacaoFinanceiraBaixa === id
        ? { ...conta, isChecked: !conta.isChecked }
        : conta
    );
    setListContas(updatedList);
  };

  const calculateLiquido = (contaAlterada: Conta) => {
    const { idMovimentacaoFinanceiraBaixa } = contaAlterada;
    const newTaxa = parseFloat(
      getValues(`taxa.${idMovimentacaoFinanceiraBaixa}`) as string
    );

    const updatedConta = {
      ...contaAlterada,
      taxa: newTaxa,
      valorLiquido: contaAlterada.valor - newTaxa,
      isChecked: true,
    };

    setListContas((prev) =>
      prev.map((conta) =>
        conta.idMovimentacaoFinanceiraBaixa === idMovimentacaoFinanceiraBaixa
          ? updatedConta
          : conta
      )
    );
  };

  const changeDateCompensacao = (contaAlterada: Conta) => {
    const { idMovimentacaoFinanceiraBaixa } = contaAlterada;
    const newDate = getValues(
      `dataCompensacao.${idMovimentacaoFinanceiraBaixa}`
    );

    if (!newDate) {
      setListContas((prev) =>
        prev.map((conta) =>
          conta.idMovimentacaoFinanceiraBaixa === idMovimentacaoFinanceiraBaixa
            ? { ...conta, dataCompensacao: '', isChecked: false }
            : conta
        )
      );
    } else {
      const dataCompensacao = new Date(newDate).toISOString();
      setListContas((prev) =>
        prev.map((conta) =>
          conta.idMovimentacaoFinanceiraBaixa === idMovimentacaoFinanceiraBaixa
            ? { ...conta, dataCompensacao, isChecked: true }
            : conta
        )
      );
    }
  };

  return {
    changeDateCompensacao,
    calculateLiquido,
    toggleSelectAll,
    toggleSelect,
    toggleContasSelecionadas,
  };
};
