import { Flex, Text, Stack, Divider, useMediaQuery } from '@chakra-ui/react';

import { TextValor } from 'components/PDV/Text/TextValor';

interface TotaisFechamentoCaixaProps {
  casasDecimaisValor: number;
  saldoInicial: number;
  totalEntradas: number;
  totalSaidas: number;
}

export function TotaisFechamentoCaixa({
  casasDecimaisValor,
  saldoInicial,
  totalEntradas,
  totalSaidas,
}: TotaisFechamentoCaixaProps) {
  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');

  return (
    <Stack
      mt="4"
      spacing="4"
      direction={{ base: 'column', md: 'row' }}
      justifyContent={{ base: 'center', xl: 'flex-start' }}
      alignItems="center"
      minH="80px"
      py="4"
      px="8"
      bg="white"
    >
      <Stack spacing="4" direction="row" w="full">
        <Flex
          w="full"
          maxW={{ base: 'none', md: '280px' }}
          alignItems="center"
          justifyContent="center"
          flexDir="column"
        >
          <Text fontSize="sm">Saldo abertura</Text>
          <TextValor
            casasDecimais={casasDecimaisValor}
            valor={saldoInicial || 0}
            color="gray.700"
            fontSize="xl"
            fontWeight="semibold"
            symbolFontSize="xs"
            symbolFontWeight="semibold"
          />
        </Flex>

        <Divider orientation="vertical" h="14" w="1px" />

        <Flex
          w="full"
          maxW={{ base: 'none', md: '280px' }}
          alignItems="center"
          justifyContent="center"
          flexDir="column"
        >
          <Text fontSize="sm">Entradas</Text>
          <TextValor
            casasDecimais={casasDecimaisValor}
            valor={totalEntradas || 0}
            color="aquamarine.600"
            fontSize="xl"
            fontWeight="semibold"
            symbolFontSize="xs"
            symbolFontWeight="semibold"
          />
        </Flex>
      </Stack>

      <Divider
        orientation={!isSmallerThan900 ? 'vertical' : 'horizontal'}
        h={{ base: '1px', md: '14' }}
        w={{ base: 'full', md: '1px' }}
      />

      <Stack spacing="4" direction="row" w="full">
        <Flex
          w="full"
          maxW={{ base: 'none', md: '280px' }}
          alignItems="center"
          justifyContent="center"
          flexDir="column"
        >
          <Text fontSize="sm">Saídas</Text>
          <TextValor
            casasDecimais={casasDecimaisValor}
            valor={totalSaidas || 0}
            color="red.400"
            fontSize="xl"
            fontWeight="semibold"
            symbolFontSize="xs"
            symbolFontWeight="semibold"
          />
        </Flex>

        <Divider orientation="vertical" h="14" w="1px" />

        <Flex
          w="full"
          maxW={{ base: 'none', md: '280px' }}
          alignItems="center"
          justifyContent="center"
          flexDir="column"
        >
          <Text fontSize="sm">Saldo no caixa</Text>
          <TextValor
            casasDecimais={casasDecimaisValor}
            valor={
              (saldoInicial || 0) + (totalEntradas || 0) - (totalSaidas || 0)
            }
            color="blue.700"
            fontSize="xl"
            fontWeight="semibold"
            symbolFontSize="xs"
            symbolFontWeight="semibold"
          />
        </Flex>
      </Stack>
    </Stack>
  );
}
