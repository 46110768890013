import { ListVendasProps } from 'pages/Integracoes/LojaAplicativos/TrayCommerce/PainelAdministradorTray/VendasTray/validationForms';

import { DadosNota } from '../DadosNota';
import { DadosTransportadora } from '../DadosTransporte';
import { EmitindoNotaFiscal } from '../EmitindoNotaFiscal';
import { NotaAprovada } from '../NotaAprovada';
import { NotaPossuiRejeicao } from '../NotaPossuiRejeicao';
import { NotaRejeitada } from '../NotaRejeitada';
import { TelaInicial } from '../TelaInicial';
import { NotaFiscalExibirTela } from '../validation';

type ItemTelaNotaFiscalProps = {
  telaExibicao: number;
  amountVendas: number;
  isMultipleVendas: boolean;
  listNotaFiscal: ListVendasProps[];
  onClose: (shouldReloadList?: boolean) => void;
};

export const ItemTelaNotaFiscal = ({
  telaExibicao,
  amountVendas,
  isMultipleVendas,
  listNotaFiscal,
  onClose,
}: ItemTelaNotaFiscalProps) => {
  switch (telaExibicao) {
    case NotaFiscalExibirTela.TELA_INICIAL:
      return (
        <TelaInicial
          amountVendas={amountVendas}
          listNotaFiscal={listNotaFiscal}
          isMultipleVendas={isMultipleVendas}
        />
      );
    case NotaFiscalExibirTela.DADOS_TRANSPORTE:
      return <DadosTransportadora isMultipleVendas={isMultipleVendas} />;

    case NotaFiscalExibirTela.DADOS_NOTA:
      return <DadosNota isMultipleVendas={isMultipleVendas} />;

    case NotaFiscalExibirTela.EMITINDO_NOTA_FISCAL:
      return (
        <EmitindoNotaFiscal
          isMultipleVendas={isMultipleVendas}
          amountVendas={amountVendas}
          onClose={onClose}
        />
      );

    case NotaFiscalExibirTela.NOTA_APROVADA:
      return <NotaAprovada isMultipleVendas={isMultipleVendas} />;

    case NotaFiscalExibirTela.NOTA_REJEITADA:
      return <NotaRejeitada />;
    case NotaFiscalExibirTela.NOTA_POSSUI_REJEICAO:
      return <NotaPossuiRejeicao />;

    default:
      return null;
  }
};
