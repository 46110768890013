import { Flex } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  MetasComissoesEtapasContext,
  MetasComissoesEtapasProvider,
} from 'store/MetasComissoes/MetasComissoesEtapas';
import { EnumFormaPagamentoPremiacaoMetas } from 'constants/enum/formaPagamentoPremiacaoMetas';

import { StepsMetasComissoes } from './StepsMetasComissoes';
import { FormDataEtapas } from '../Types/validationsForm';

export function EtapasMetasComissoes() {
  const formMethods = useForm<FormDataEtapas>({
    defaultValues: {
      quandoAlcancarTotal: 100,
      valorAlcanceMetaPremiacao: 100,
      formaPagamentoBonus:
        EnumFormaPagamentoPremiacaoMetas.NAO_POSSUI_BONIFICACAO,
    },
  });

  return (
    <>
      <FormProvider {...formMethods}>
        <MetasComissoesEtapasProvider>
          <MetasComissoesEtapasContext.Consumer>
            {({ activeStep }) => (
              <Flex w="full" flexDir="column" alignItems="center">
                <StepsMetasComissoes activeStep={activeStep} />
              </Flex>
            )}
          </MetasComissoesEtapasContext.Consumer>
        </MetasComissoesEtapasProvider>
      </FormProvider>
    </>
  );
}
