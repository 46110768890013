import { Box, Flex, Button } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { FormaRecebimentoItem } from './FormaRecebimentoItem';
import { useFormaRecebimento } from './hooks';
import { FormData, defaultValues, yupResolver } from './validationForms';

export const FormaRecebimento = () => {
  const formMethods = useForm<FormData>({
    defaultValues,
    resolver: yupResolver,
  });

  const {
    handleUpdateFormaRecebimento,
    tefWatch,
    isLoading,
    isFormaRecebimentoVinculado,
  } = useFormaRecebimento(formMethods);

  return (
    <FormProvider {...formMethods}>
      {isLoading && <LoadingPadrao />}
      <Box mb="20px">
        <FormaRecebimentoItem />
      </Box>
      <Flex mb="20px" justifyContent="center" alignItems="center">
        <Button
          variant="outlineDefault"
          borderRadius="16px"
          colorScheme="gray"
          mr="24px"
          w="96px"
        >
          Cancelar
        </Button>
        <Button
          color="black"
          isDisabled={!tefWatch || isFormaRecebimentoVinculado}
          variant="solid"
          colorScheme="secondary.400"
          borderRadius="16px"
          width="120px"
          onClick={handleUpdateFormaRecebimento}
        >
          Confirmar
        </Button>
      </Flex>
    </FormProvider>
  );
};
