export const EnumEmissaoSenhas = {
  IMPRIMIR_SENHA: true,
  NAO_IMPRIMIR_SENHA: false,

  properties: [
    { value: true, label: 'Imprimir senhas' },
    { value: false, label: 'Não imprimir senhas' },
  ],
};

export const EnumImprimirViaProducao = {
  IMPRIMIR_VIA_PRODUCAO: true,
  NAO_IMPRIMIR_VIA_PRODUCAO: false,

  properties: [
    { value: true, label: 'Sim' },
    { value: false, label: 'Não' },
  ],
};
