import React, {
  useState,
  useCallback,
  useRef,
  createRef,
  ChangeEvent,
} from 'react';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Heading,
  Button,
  Flex,
  Icon,
  GridItem,
  Tr,
  Td,
  Input,
  Box,
} from '@chakra-ui/react';
import { FiRefreshCw } from 'react-icons/fi';

import api, { ResponseApi } from 'services/api';
import { shallowEqual } from 'helpers/validation/shallowEqual';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import { formatDateHourMinute } from 'helpers/format/formatStringDate';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import TipoImportacao from 'constants/enum/tipoImportacao';
import ConstanteFuncionalidades from 'constants/permissoes';
import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import StatusImportacao from 'constants/enum/statusImportacao';

import { SalvarInserirNovoIcon } from 'icons';
import { ButtonFuncionalidade } from 'components/update/Button/ButtonFuncionalidade';
import ManterFoco from 'components/Geral/ManterFoco';
import {
  PagedTable,
  PagedTableForwardRefData,
} from 'components/update/Table/PagedTable';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import {
  GridPaginadaConsulta,
  GridPaginadaRetorno,
} from 'components/Grid/Paginacao';
import { SearchInput } from 'components/update/Input/SearchInput';

interface ImportarFiltros {
  [x: string]: any;
}

interface Importar {
  id: string;
  nomeArquivo: string;
  tipo: number;
  status: number;
  statusDescricao: string;
  dataHoraFormatada: any;
}

type Match = {
  url: string;
};

type ImportarFornecedores = {
  name: string;
  value: string;
  match: Match;
};

export const getTipoImportacao = (props: ImportarFornecedores) => {
  if ((props?.match.url as string).includes(ConstanteRotas.IMPORTAR_CLIENTES))
    return TipoImportacao.properties[1];
  if (
    (props?.match.url as string).includes(ConstanteRotas.IMPORTAR_FORNECEDORES)
  )
    return TipoImportacao.properties[2];

  return TipoImportacao.properties[3];
};

export const getPermissaoImportar = (tipoImportacao: number) => {
  if (tipoImportacao === 1)
    return ConstanteFuncionalidades.IMPORTACAO_CLIENTE_IMPORTAR;
  if (tipoImportacao === 2)
    return ConstanteFuncionalidades.IMPORTACAO_FORNECEDOR_IMPORTAR;

  return ConstanteFuncionalidades.IMPORTACAO_PRODUTO_IMPORTAR;
};

const Listar = (props: ImportarFornecedores) => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [importar, setImportar] = useState([] as Array<Importar>);
  const [currentFilters, setCurrentFilters] = useState<ImportarFiltros>(
    {} as ImportarFiltros
  );

  const history = useHistory();
  const isMountedRef = useIsMountedRef();

  const formMethods = useForm();
  const { getValues, setFocus } = formMethods;
  const fileInput = createRef<HTMLInputElement>();

  const pageIsLoaded = useRef(false);
  const childRef = useRef<PagedTableForwardRefData>(null);

  const tipoImportacao = getTipoImportacao(props);

  const handleHistoryPush = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history]
  );

  const reloadTable = () => {
    childRef.current?.reload();
  };

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, idGuid: string) => {
      setIsLoading(true);
      const file = (e.target.files as FileList)[0];

      async function importarAlterar() {
        const response = (await toBase64(file)) as string;
        const data = getValues();
        data.Id = idGuid;
        data.FileBase64 = response.split(',').pop();
        data.NomeArquivo = file.name;
        await api.put<void, ResponseApi<string>>(
          ConstanteEnderecoWebservice.IMPORTAR_ALTERAR,
          data
        );
        setIsLoading(false);
        reloadTable();
      }
      importarAlterar();
    },
    [getValues]
  );

  const handleDownloadFile = async (idGuid: string) => {
    const response = await api.get<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.IMPORTAR_DOWNLOAD,
      {
        params: { id: idGuid },
      }
    );

    const link = document.createElement('a');
    link.href = response.dados;
    link.click();
  };

  const handleReset = formMethods.handleSubmit((data) => {
    const filtersIsDirty = !shallowEqual(data, currentFilters || {});

    if (filtersIsDirty) {
      setCurrentFilters(data);
    }
  });

  const paginationHandle = useCallback(
    async (gridPaginadaConsulta: GridPaginadaConsulta) => {
      setIsLoading(true);

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<Importar>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.IMPORTAR_LISTAR_PAGINADO,
          gridPaginadaConsulta
        ),
        {
          params: {
            nomearquivo: currentFilters.nome,
            tipoImportacao: tipoImportacao.value,
          },
        }
      );

      if (response?.sucesso && isMountedRef.current) {
        setTotalRegistros(response.dados.total);
        setImportar(response.dados.registros);
      }

      if (isMountedRef.current) {
        setIsLoading(false);

        if (!pageIsLoaded.current) {
          pageIsLoaded.current = true;

          setFocus('nome');
        }
      }
    },
    [currentFilters, isMountedRef, setFocus, tipoImportacao.value]
  );

  return (
    <Box>
      <FormProvider {...formMethods}>
        <ManterFoco>
          <Flex flexDirection={['column', 'column', 'row']} w="full">
            <Box
              mb={['10px', '10px']}
              w={['full', 'full', '70%']}
              mr={['', '20px']}
            >
              <SearchInput
                type="search"
                placeholder="Buscar pelo nome de sua planilha"
                onEnterKeyPress={() => handleReset()}
                isDisabled={isLoading}
                id="nome"
                name="nome"
              />
            </Box>
            <Flex
              width="100%"
              flexDirection={['column', 'column', 'row']}
              justifyContent="flex-end"
            >
              <Flex
                width="100%"
                flexDirection={['column', 'row', 'row']}
                justifyContent="flex-end"
              >
                <Button
                  width={['100%', '100%', '260px']}
                  borderRadius="md"
                  variant="outline"
                  mr="3"
                  mb={['4', '0']}
                  leftIcon={<Icon as={FiRefreshCw} fontSize="md" />}
                  onClick={() => reloadTable()}
                >
                  {isLoading
                    ? 'Recarregando a lista'
                    : 'Clique para atualizar a lista'}
                </Button>

                <ButtonFuncionalidade
                  id="importar"
                  name="importar"
                  color="gray.700"
                  colorScheme="secondary"
                  borderRadius="md"
                  h="39px"
                  width={{ base: 'full', md: '220px' }}
                  leftIcon={<Icon as={SalvarInserirNovoIcon} />}
                  type="button"
                  onClick={() =>
                    handleHistoryPush(
                      `/importar-${tipoImportacao.name.toLowerCase()}/importar`
                    )
                  }
                  funcionalidade={getPermissaoImportar(tipoImportacao.value)}
                >
                  {`Importar ${tipoImportacao.name}`}
                </ButtonFuncionalidade>
              </Flex>
            </Flex>
          </Flex>
        </ManterFoco>
      </FormProvider>
      <GridItem mt="16px" colSpan={12}>
        <PagedTable
          ref={childRef}
          defaultKeyOrdered="DataHora"
          itemsTotalCount={totalRegistros}
          loadColumnsData={paginationHandle}
          isLoading={isLoading}
          tableHeaders={[
            {
              content: 'Data',
              key: 'DataHora',
              isOrderable: true,
              width: '200px',
            },
            {
              content: 'Nome da sua planilha',
              key: 'NomeArquivo',
              isOrderable: true,
              width: 'auto',
            },
            {
              content: 'Situação',
              key: 'Situacao',
              isOrderable: false,
              width: '200px',
            },
            {
              content: 'Ações',
              key: 'Acoes',
              isOrderable: false,
              width: '38px',
            },
          ]}
          renderTableRows={importar.map((importacao) => (
            <Tr key={importacao.id}>
              <Td>{formatDateHourMinute(importacao.dataHoraFormatada)}</Td>
              <Td>{importacao.nomeArquivo}</Td>
              <Td>
                {importacao.statusDescricao}
                {importacao.status === StatusImportacao.FALHA && (
                  <>
                    <Heading
                      as="span"
                      fontSize="sm"
                      fontWeight="normal"
                      color="purple.500"
                      margin="2"
                      onClick={() => handleDownloadFile(importacao.id)}
                      cursor="pointer"
                    >
                      [Baixar .csv com falhas]
                    </Heading>
                  </>
                )}
              </Td>
              <Td>
                {importacao.status === StatusImportacao.FALHA && (
                  <>
                    <Input
                      type="file"
                      display="none"
                      ref={fileInput}
                      typeof=".csv"
                      onChange={(e) => handleOnChange(e, importacao.id)}
                    />

                    <ActionsMenu
                      id="mostrarMais"
                      items={[
                        {
                          content: 'Reenviar Arquivo',
                          onClick: () => {
                            fileInput.current?.click();
                          },
                          funcionalidade: getPermissaoImportar(
                            tipoImportacao.value
                          ),
                        },
                      ]}
                    />
                  </>
                )}
              </Td>
            </Tr>
          ))}
        />
      </GridItem>
    </Box>
  );
};

export default Listar;
