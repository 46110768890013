import styled from 'styled-components';
import { Card as BootstrapCard } from 'react-bootstrap';

export const Card = styled(BootstrapCard)`
  padding: 0px;

  .card-body {
    padding: 20px;

    @media (min-width: 900px) {
      padding: 25px;
    }
    @media (min-width: 1200px) {
      padding: 30px;
    }
    @media (min-width: 1800px) {
      padding: 35px;
    }
  }

  .card-body .tab-content {
    padding: 0px;
  }

  border-radius: 5px !important;
  border: 1px solid var(--sti-ck-colors-gray-100) !important;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
`;
