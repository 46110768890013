import { Text, Progress, VStack, Stack, StackProps } from '@chakra-ui/react';

interface DownloadDocumentosComponentProps extends StackProps {
  processingDownload: boolean;
  waitingResponseDownload?: boolean;
  downloadPercentage?: number;
  valueTotalFiles?: number;
}

const DownloadDocumentosComponent = ({
  processingDownload = false,
  waitingResponseDownload = false,
  downloadPercentage = 0,
  valueTotalFiles = 0,
  width = 'full',
  textAlign = 'center',
  textColor = 'primary.500',
  fontWeight = 'semibold',
  fontSize = 'sm',
  ...rest
}: DownloadDocumentosComponentProps) => {
  const percentage = Math.floor((downloadPercentage * 100) / valueTotalFiles);
  const valuePercentage = percentage > 0 ? percentage : 0;

  return (
    <>
      {processingDownload && (
        <>
          <Stack
            direction="column"
            width={width}
            fontSize={fontSize}
            fontWeight={fontWeight}
            textColor={textColor}
            textAlign={textAlign}
            {...rest}
          >
            <Text>
              {waitingResponseDownload
                ? 'Iniciando o processamento do seu download...'
                : 'Estamos processando o seu download...'}
            </Text>
            <VStack>
              <Progress
                value={valuePercentage}
                width="full"
                bg="primary.500"
                colorScheme="secondary"
                borderRadius="full"
                isAnimated
                hasStripe
              />
              <Text>{valuePercentage}%</Text>
            </VStack>
          </Stack>
        </>
      )}
    </>
  );
};

export default DownloadDocumentosComponent;
