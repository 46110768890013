import React, { useEffect, useRef, useState, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  ModalContent,
  ModalBody,
  Flex,
  ScaleFade,
  ModalCloseButton,
  ModalHeader,
  FormLabel,
  ModalFooter,
  Button,
  Text,
  Divider,
  Box,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import { tipoOperacaoOptions } from 'constants/enum/tipoOperacao';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import StatusConsultaEnum from 'constants/enum/tipoAcaoEstoque';
import api, { ResponseApi } from 'services/api';
import auth from 'modules/auth';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import ManterFoco from 'components/Geral/ManterFoco';
import InputDateRange from 'components/PDV/InputDateRange';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import { SelectVirtualized } from 'components/PDV/Select/SelectVirtualized';
import { PaginationData } from 'components/update/Pagination';
import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import Loading from 'components/Layout/Loading/LoadingPadrao';

import { FiltersData, ListSelectProps } from '../types';

interface ModalProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  handleSubmitModalBuscaAvancada: (data: FiltersData) => void;
  currentFilters: FiltersData;
  handleReset: () => void;
}

const ModalBuscaAvancadaHistoricoProduto = ({
  isOpen,
  setIsOpen,
  currentFilters,
  handleSubmitModalBuscaAvancada,
  handleReset,
}: ModalProps) => {
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const { id: lojaId } = auth.getLoja();

  const formMethods = useForm<FiltersData>();
  const produtoCor = formMethods.watch('produtoCorId');
  const produtoCorOptions = produtoCor?.tamanhos || [];

  const [listarSelectLocalEstoque, setListarSelectLocalEstoque] = useState<
    ListSelectProps[]
  >([]);

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    handleSubmitModalBuscaAvancada(data);
  });

  const latestProps = useRef({ reset: formMethods.reset, currentFilters });
  useEffect(() => {
    latestProps.current = { reset: formMethods.reset, currentFilters };
  });

  useEffect(() => {
    if (isOpen) {
      latestProps.current.reset(latestProps.current.currentFilters);
    }
  }, [isOpen]);

  useEffect(() => {
    const params = {
      lojaId,
      statusConsulta: StatusConsultaEnum.ATIVOS,
    };

    async function listarLocalEstoque() {
      const response = await api.get<void, ResponseApi<ListSelectProps[]>>(
        ConstanteEnderecoWebservice.LOCAL_ESTOQUE_LISTAR_SELECT,
        {
          params,
        }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.map((item: string) => toast.warning(item));
        }

        if (response.sucesso) {
          setListarSelectLocalEstoque(response.dados);
        }
      }
    }
    listarLocalEstoque();
  }, [lojaId]);

  const buscarTamanhos = useCallback(async (produtoCorId) => {
    setIsLoading(true);
    const response = await api.get<
      void,
      ResponseApi<
        {
          id: string;
          nome: string;
          padraoSistema: boolean;
        }[]
      >
    >(ConstanteEnderecoWebservice.PRODUTO_COR_TAMANHO_OBTER_TAMANHOS, {
      params: { produtoCorId },
    });

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
        setIsLoading(false);
      }

      if (response.sucesso && response.dados) {
        const tamanhosSemTamanhoPadraoSistema = response.dados.filter(
          (tamanho) => !tamanho.padraoSistema
        );
        setIsLoading(false);

        return (tamanhosSemTamanhoPadraoSistema || [])?.map((tamanho) => ({
          id: tamanho.id,
          descricao: tamanho.nome,
          padraoSistema: tamanho.padraoSistema,
        }));
      }
    }
    setIsLoading(false);

    return [];
  }, []);

  const selecionarOpcao = useCallback(
    async (option) => {
      const produtoCorTamanhos = await buscarTamanhos(option.value);
      const produtoCompleto = {
        label: option.label,
        value: option.value,
        produtoCorId: option.produtoCorId,
        tamanhos: produtoCorTamanhos,
      };
      formMethods.setValue('produtoCorId', produtoCompleto);
    },
    [buscarTamanhos, formMethods]
  );

  const buscarOpcoes = useCallback(
    async (inputValue: string, dataPagination: PaginationData) => {
      setIsLoading(true);
      const valorDigitado = inputValue;
      const response = await api.get<
        void,
        ResponseApi<
          GridPaginadaRetorno<{
            id: number;
            descricao: string;
          }>
        >
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.PRODUTO_COR_LISTAR_PAGINADO,
          dataPagination
        ),
        {
          params: {
            nome: valorDigitado,
          },
        }
      );
      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso: string) => toast.warning(aviso));
          setIsLoading(false);
        }
        setTotalRegistros(response?.dados?.total || 0);

        if (response.sucesso && response.dados) {
          const opcoes = response?.dados?.registros.map((option) => {
            const nomeProdutoCor = option.descricao.split('|');
            const nomeProduto = nomeProdutoCor[0].trim();
            const cor = nomeProdutoCor
              ? nomeProdutoCor[1]
                ? nomeProdutoCor[1].trim()
                : ''
              : '';

            return {
              label: option.descricao,
              value: option.id,
              produtoCorId: option.id,
              produto: nomeProduto,
              cor,
            };
          });
          setIsLoading(false);

          return opcoes;
        }
        setIsLoading(false);
      }
      setIsLoading(false);

      return [];
    },
    []
  );

  return (
    <ModalPadraoChakra
      onClose={() => {
        setIsOpen(false);
      }}
      isOpen={isOpen}
      isCentered
      size="2xl"
    >
      <ModalContent
        marginBottom={{ base: 0, sm: '3.75rem' }}
        marginTop={{ base: 0, sm: '3.75rem' }}
        h="unset"
        maxW="600px"
      >
        <ManterFoco
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <ScaleFade initialScale={1.5} in={isOpen}>
            <ModalBody p={0} h="100%">
              <Flex flexDirection="column" align="stretch" h="100%">
                <ModalContent p="3" m="2">
                  <ModalHeader>
                    <Text color="primary.50" fontSize="md">
                      Busca avançada
                    </Text>
                    <Text color="gray.700" fontSize="xs">
                      Utilize os filtros de busca para uma pesquisa específica.
                      Todos os campos são acumulativos.
                    </Text>
                  </ModalHeader>
                  <Divider
                    orientation="horizontal"
                    width="92%"
                    margin="0 auto"
                  />
                  <ModalCloseButton
                    fontSize="2xs"
                    border="none"
                    _hover={{ bg: 'none' }}
                    _focus={{ border: 'none' }}
                  />
                  <ModalBody pb="6" pt="6">
                    {isLoading && <Loading />}
                    <FormProvider {...formMethods}>
                      <Box width="100%">
                        <FormLabel>Descrição do produto</FormLabel>
                        <SelectVirtualized
                          asControlledByObject
                          autoFocus
                          totalRegistros={totalRegistros}
                          onSelect={selecionarOpcao}
                          handleGetOptions={buscarOpcoes}
                          isClearable
                          id="produtoCorId"
                          name="produtoCorId"
                          placeholder="Digite o nome do produto e selecione a cor"
                          required
                          withoutDefaultOptions
                        />
                      </Box>

                      <Flex
                        flexDirection={['column', 'column', 'column', 'row']}
                        justifyContent="space-between"
                        pt="4"
                      >
                        {produtoCorOptions?.length > 0 &&
                          produtoCorOptions[0].descricao !== '' && (
                            <Box
                              minWidth={['100%', '100%', '100%', '48%']}
                              pt={['2', '2', '2', '3']}
                              mr="24px"
                            >
                              <FormLabel>Tamanho</FormLabel>
                              <SelectPadrao
                                id="produtoCorTamanhoId"
                                name="produtoCorTamanhoId"
                                placeholder="Selecionar"
                                options={produtoCorOptions?.map(
                                  ({ id, descricao }) => {
                                    return (
                                      {
                                        label: descricao,
                                        value: id,
                                      } || []
                                    );
                                  }
                                )}
                                isClearable
                              />
                            </Box>
                          )}

                        <Box
                          width={['100%', '100%', '100%', '100%']}
                          pt={['6', '6', '6', '3']}
                        >
                          <FormLabel>Período</FormLabel>
                          <InputDateRange
                            borderColor="gray.100"
                            borderRadius="md"
                            placeholder="Selecione uma data"
                            name="data"
                            startDateName="dataHoraInicio"
                            endDateName="dataHoraFim"
                            maxDate={new Date()}
                            cleanFiltersButtonText="Cancelar"
                          />
                        </Box>
                      </Flex>

                      <Flex
                        flexDirection={['column', 'column', 'column', 'row']}
                        justifyContent="space-between"
                        pt={6}
                      >
                        <Box width={['100%', '100%', '100%', '48%']}>
                          <FormLabel>Local de estoque</FormLabel>
                          <SelectPadrao
                            id="localEstoqueId"
                            name="localEstoqueId"
                            placeholder="Selecione"
                            options={listarSelectLocalEstoque.map(
                              ({ id, nome }) => {
                                return (
                                  {
                                    label: nome,
                                    value: id,
                                  } || {}
                                );
                              }
                            )}
                            isClearable
                          />
                        </Box>
                        <Box
                          width={['100%', '100%', '100%', '48%']}
                          pt={['6', '6', '6', '0']}
                        >
                          <FormLabel>Tipo de operação</FormLabel>
                          <SelectPadrao
                            id="tipoOperacaoEstoque"
                            name="tipoOperacaoEstoque"
                            placeholder="Selecione"
                            options={tipoOperacaoOptions}
                          />
                        </Box>
                      </Flex>
                    </FormProvider>
                  </ModalBody>
                  <Divider
                    orientation="horizontal"
                    width="92%"
                    margin="0 auto"
                    pt={6}
                  />

                  <ModalFooter mt={6}>
                    <Flex
                      width="100%"
                      justifyContent={[
                        'center',
                        'center',
                        'flex-end',
                        'flex-end',
                      ]}
                    >
                      <Button
                        width={['120px', '120px', '150px', '150px']}
                        id="cancelar"
                        name="cancelar"
                        colorScheme="gray.100"
                        variant="outline"
                        borderRadius="md"
                        fontSize="sm"
                        type="button"
                        onClick={() => {
                          setIsOpen(false);
                          handleReset();
                        }}
                        mr={6}
                        _hover={{ bg: 'gray.50' }}
                      >
                        Limpar filtros
                      </Button>
                      <Button
                        width={['120px', '120px', '150px', '150px']}
                        id="pesquisar"
                        name="pesquisar"
                        backgroundColor="aquamarine.600"
                        borderRadius="md"
                        fontSize="sm"
                        color="white"
                        variant="success"
                        type="button"
                        _hover={{ bg: 'aquamarine.700' }}
                        onClick={() => {
                          handleSubmit();
                          setIsOpen(false);
                        }}
                      >
                        Pesquisar
                      </Button>
                    </Flex>
                  </ModalFooter>
                </ModalContent>
              </Flex>
            </ModalBody>
          </ScaleFade>
        </ManterFoco>
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export default ModalBuscaAvancadaHistoricoProduto;
