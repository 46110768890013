import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';

import { PagedTableForwardRefData } from 'components/update/Table/PagedTable';
import {
  yupResolver,
  listDiasSemana,
  FormData,
} from 'pages/Promocao/Formulario/validationForms';

type PromocaoProviderProps = {
  children: React.ReactNode;
};

type PromocaoContextProps = {
  listLojas: Lojas[];
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  pagedTableRef: React.RefObject<PagedTableForwardRefData>;
  formIsDirty: boolean;
  setFormIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
};

export const PromocaoContext = createContext<PromocaoContextProps>(
  {} as PromocaoContextProps
);

type Lojas = {
  id: string;
  razaoSocial: string;
  fantasia: string;
  endereco: {
    logradouro: string;
    bairro: string;
    numero: string;
    cep: string;
    cidade: string;
    estado: string;
    uf: string;
  };
};

type ProdutoCorTamanho = {
  produtoCorTamanhoId: string;
  produto: string;
  cor: string;
  tamanho: string;
  imagem: string;
};

export type ProdutosResponseProps = {
  promocaoId: string;
  produtoCorTamanho: ProdutoCorTamanho;
  precoOriginal: number;
  precoPromocao: number;
  quantidade: number;
  descontoAcimaQuantidade: boolean;
};

export const PromocaoProvider = ({ children }: PromocaoProviderProps) => {
  const [listLojas, setListLojas] = useState<Lojas[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);

  const pagedTableRef = useRef<PagedTableForwardRefData>(null);

  const formMethods = useForm<FormData>({
    defaultValues: {
      nome: '',
      lojas: [],
      ativo: true,
      diasDaSemana: listDiasSemana,
      telasUsoPromocao: [],
    },
    resolver: yupResolver,
  });

  const { id: idRota } = useParams<{ id: string }>();

  const getLojas = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get<void, ResponseApi<Lojas[]>>(
      ConstanteEnderecoWebservice.LISTAR_LOJA_ENDERECO
    );
    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso) {
        setListLojas(response.dados);
        if (!idRota) {
          setIsLoading(false);
        }
      }
    }
    setIsLoading(false);
  }, [idRota]);

  useEffect(() => {
    getLojas();
  }, [getLojas]);

  return (
    <PromocaoContext.Provider
      value={{
        listLojas,
        isLoading,
        setIsLoading,
        pagedTableRef,
        formIsDirty,
        setFormIsDirty,
      }}
    >
      <FormProvider {...formMethods}>{children}</FormProvider>
    </PromocaoContext.Provider>
  );
};

export function usePromocaoContext(): PromocaoContextProps {
  const context = useContext(PromocaoContext);

  if (!context)
    throw new Error(
      'usePromocaoContext must be used within a PromocaoProvider.'
    );

  return context;
}
