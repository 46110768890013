import React, { useState, memo, useEffect } from 'react';
import {
  Flex,
  FlexProps,
  Image,
  ImageProps,
  Box,
  BoxProps,
  usePrefersReducedMotion,
  Text,
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';

import todosPlanetasImage from 'assets/images/zendar/todos-planetas.svg';
import planetaMaiorImage from 'assets/images/zendar/planeta-maior.svg';
import todasEstrelasImage from 'assets/images/zendar/todas-estrelas.svg';
import estrelasEfeito1Image from 'assets/images/zendar/estrelas-efeito-1.svg';
import estrelasEfeito2Image from 'assets/images/zendar/estrelas-efeito-2.svg';
import estrelasEfeito3Image from 'assets/images/zendar/estrelas-efeito-3.svg';

import useWindowSize from 'helpers/layout/useWindowSize';
import { getImageBySystem } from 'helpers/layout/getImageBySystem';
import { loginBackgroundImgs } from 'constants/enum/enumsImgSistemas';

import Logo from 'components/Autenticacao/Logo';

const MotionImage = motion<Omit<ImageProps, 'transition'>>(Image);
const MotionBox = motion<Omit<BoxProps, 'transition'>>(Box);

interface EstrelasProps {
  prefersReducedMotion: boolean;
}

const Estrelas = memo(({ prefersReducedMotion }: EstrelasProps) => {
  const images = [
    estrelasEfeito1Image,
    estrelasEfeito2Image,
    estrelasEfeito3Image,
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleNextImage = () => {
    const lastIndex = images.length - 1;

    if (currentImageIndex === lastIndex) {
      setCurrentImageIndex(0);
    } else {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  return (
    <>
      <Image
        userSelect="none"
        src={todasEstrelasImage}
        objectFit="cover"
        position="fixed"
        top={0}
        zIndex="base"
        w={{ base: '400vw', md: 'full' }}
        maxW={{ base: '400vw', md: 'full' }}
      />

      {!prefersReducedMotion && (
        <AnimatePresence custom={currentImageIndex}>
          <MotionImage
            userSelect="none"
            src={images[currentImageIndex]}
            key={currentImageIndex}
            custom={currentImageIndex}
            objectFit="cover"
            w={{ base: '400vw', md: 'full' }}
            maxW={{ base: '400vw', md: 'full' }}
            position="fixed"
            top={0}
            zIndex="base"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 5 } }}
            exit={{ opacity: 0, transition: { duration: 5 } }}
            onAnimationComplete={handleNextImage}
          />
        </AnimatePresence>
      )}
    </>
  );
});

const EstrelasCadentes = memo(() => {
  const [
    currentShootingStarGroupIndex,
    setCurrentShootingStarGroupIndex,
  ] = useState(0);

  const handleNextShootingStar = () => {
    if (currentShootingStarGroupIndex === 2) {
      setCurrentShootingStarGroupIndex(0);
    } else {
      setCurrentShootingStarGroupIndex(currentShootingStarGroupIndex + 1);
    }
  };

  return (
    <>
      {currentShootingStarGroupIndex === 0 && (
        <MotionBox
          userSelect="none"
          as="span"
          position="fixed"
          borderBottomRightRadius="full"
          borderBottomLeftRadius="full"
          bgGradient="linear(to-t, #fff 5%, var(--sti-ck-colors-purple-300) 25%, transparent)"
          opacity={0}
          w="5px"
          h="0px"
          top={0}
          left="10vw"
          transform="rotate(-55deg)"
          animate={{
            opacity: 0.3,
            height: '90px',
            top: '75vh',
            left: 'calc(100vw + 100px)',
            transition: { duration: 3, delay: 2 },
          }}
          zIndex="base"
          onAnimationComplete={handleNextShootingStar}
        />
      )}
      {currentShootingStarGroupIndex === 1 && (
        <>
          <MotionBox
            userSelect="none"
            as="span"
            position="fixed"
            borderBottomRightRadius="full"
            borderBottomLeftRadius="full"
            bgGradient="linear(to-t, #fff 5%, var(--sti-ck-colors-purple-300) 25%, transparent)"
            opacity={0}
            w="5px"
            h="0px"
            top="30vh"
            left={0}
            transform="rotate(-30deg)"
            animate={{
              opacity: 0.3,
              height: '70px',
              top: 'calc(100vh + 100px)',
              left: '50vh',
              transition: { duration: 3, delay: 2 },
            }}
            zIndex="base"
          />
          <MotionBox
            userSelect="none"
            as="span"
            position="fixed"
            borderBottomRightRadius="full"
            borderBottomLeftRadius="full"
            bgGradient="linear(to-t, #fff 5%, var(--sti-ck-colors-purple-300) 25%, transparent)"
            opacity={0}
            w="5px"
            h="0px"
            top="25vh"
            left={0}
            transform="rotate(-28deg)"
            animate={{
              opacity: 0.3,
              height: '40px',
              top: 'calc(100vh + 100px)',
              left: '55vh',
              transition: { duration: 3, delay: 2.25 },
            }}
            zIndex="base"
            onAnimationComplete={handleNextShootingStar}
          />
        </>
      )}
      {currentShootingStarGroupIndex === 2 && (
        <>
          <MotionBox
            userSelect="none"
            as="span"
            position="fixed"
            borderBottomRightRadius="full"
            borderBottomLeftRadius="full"
            bgGradient="linear(to-b, #fff 5%, var(--sti-ck-colors-purple-300) 25%, transparent)"
            opacity={0}
            w="5px"
            h="0px"
            top="75vh"
            left={0}
            transform="rotate(55deg)"
            animate={{
              opacity: 0.3,
              height: '70px',
              top: '-100px',
              left: '80vw',
              transition: { duration: 3, delay: 1 },
            }}
            zIndex="base"
          />
          <MotionBox
            userSelect="none"
            as="span"
            position="fixed"
            borderBottomRightRadius="full"
            borderBottomLeftRadius="full"
            bgGradient="linear(to-b, #fff 5%, var(--sti-ck-colors-purple-300) 25%, transparent)"
            opacity={0}
            w="5px"
            h="0px"
            top="calc(100vh + 100px)"
            left="75vh"
            transform="rotate(-28deg)"
            animate={{
              opacity: 0.3,
              height: '70px',
              top: '-100px',
              left: '10vw',
              transition: { duration: 3, delay: 2 },
            }}
            zIndex="base"
            onAnimationComplete={handleNextShootingStar}
          />
        </>
      )}
    </>
  );
});

interface BackgroundProps extends FlexProps {
  children: React.ReactNode;
}

export const LoginBackgroundZendar = ({
  children,
  ...rest
}: BackgroundProps) => {
  const { height: windowHeight } = useWindowSize();
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--vh-autenticacao',
      `${windowHeight * 0.01}px`
    );
  }, [windowHeight]);
  return (
    <Flex
      position="relative"
      h="100%"
      w="full"
      minH="calc(var(--vh-autenticacao, 1vh) * 100)"
      justifyContent="center"
      alignItems="center"
      bgColor="primary.50"
      bgImage={`url(${getImageBySystem(loginBackgroundImgs)})`}
      bgPosition="bottom"
      bgRepeat="no-repeat"
      bgSize="cover"
      px={14}
      {...rest}
    >
      <Estrelas prefersReducedMotion={prefersReducedMotion} />
      <Image
        userSelect="none"
        src={todosPlanetasImage}
        objectFit="cover"
        position="fixed"
        top={0}
        zIndex="base"
        w={{ base: '400vw', md: 'full' }}
        maxW={{ base: '400vw', md: 'full' }}
      />
      {!prefersReducedMotion && <EstrelasCadentes />}
      <Image
        userSelect="none"
        src={planetaMaiorImage}
        objectFit="cover"
        position="absolute"
        bottom={0}
        zIndex="base"
        h="full"
      />

      <Flex
        flexDirection="column"
        justifyContent="center"
        h="full"
        minH="calc(var(--vh-autenticacao, 1vh) * 100)"
        w={{ base: '265px', sm: '300px', xl: '350px' }}
        zIndex="docked"
        pt="8"
        pb="12"
      >
        <Logo />
        {children}
      </Flex>
      <Flex position="absolute" w="full" justifyContent="center" bottom="0">
        <Text color="purple.100">v{process.env.REACT_APP_APP_VERSION}</Text>
      </Flex>
    </Flex>
  );
};
