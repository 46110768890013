import {
  Box,
  Flex,
  HStack,
  Text,
  Tooltip,
  Icon,
  useMediaQuery,
} from '@chakra-ui/react';
import { useCallback, useState, useEffect } from 'react';

import { IconType, SetaIcon } from 'icons';

import { ListTabMobile } from './components/ListTabMobile';
import { Header } from './components/Header';

interface TabTypeProps {
  eventKey: number;
  title: string;
  content: string | React.ReactNode;
  disabled?: boolean;
  hidden?: boolean;
  funcionalidade?: string;
  onClick?: () => Promise<boolean | (() => void)>;
  dica?: string;
  subTitulo?: string;
  icon: IconType;
}

type TabPadraoProps = {
  tabs: TabTypeProps[];
  isExibirPorEtapa?: boolean;
  isDisabled?: boolean;
  titulo?: string;
  subtitulo?: string;
  cadastroSalvo?: boolean;
  alertDescription?: string;
};

export const Tabs = ({
  tabs,
  isExibirPorEtapa = true,
  isDisabled = false,
  cadastroSalvo = false,
  titulo,
  subtitulo,
  alertDescription,
}: TabPadraoProps) => {
  const [tabSelecionada, setTabSelecionada] = useState<TabTypeProps>(tabs[0]);
  const [isFormularioSalvo, setIsFormularioSalvo] = useState(cadastroSalvo);
  const [isPlaying, setIsPlaying] = useState(true);

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

  const tabsAtivas = tabs
    .filter((tabsItem) => !tabsItem.hidden)
    .sort((a, b) => {
      if (a.eventKey > b.eventKey) return 1;
      if (a.eventKey < b.eventKey) return -1;
      return 0;
    });

  const [tabListEtapa, setTabListEtapa] = useState<TabTypeProps[]>([
    tabsAtivas[0],
  ]);

  const tabListEtapasAtivas = tabListEtapa
    .filter((item) => !item.hidden)
    .sort((a, b) => {
      if (a.eventKey > b.eventKey) return 1;
      if (a.eventKey < b.eventKey) return -1;
      return 0;
    });

  const selectedTab = (tab: TabTypeProps) => {
    setTabSelecionada(tab);
  };

  const lastTabIsSelected =
    tabSelecionada?.eventKey ===
    tabListEtapasAtivas[tabListEtapasAtivas.length - 1]?.eventKey;

  const firstTabIsSelected =
    tabSelecionada?.eventKey === tabListEtapasAtivas[0]?.eventKey;

  const exibirAvancar = isLargerThan900 || lastTabIsSelected;

  const addTabActiveRecently = useCallback(
    (valueTabAtivaRecentemente: TabTypeProps) => {
      setTabListEtapa((prev) => [...prev, valueTabAtivaRecentemente]);
      setTabSelecionada(valueTabAtivaRecentemente);
    },
    []
  );

  const addNewTabs = useCallback(
    (indexLastTab: number) => {
      const valueTab = tabsAtivas[indexLastTab];

      let newItemTab: TabTypeProps | undefined;

      setTabListEtapa((prev) => {
        const isTabHasAlreadyAdded = prev.some(
          (itemPrev) => itemPrev.eventKey === valueTab?.eventKey
        );

        if (isTabHasAlreadyAdded || valueTab === undefined) {
          tabsAtivas.forEach((itemTabActive) => {
            const isTabActiveAlreadyAdded = prev.some(
              (itemPrev) => itemPrev.eventKey === itemTabActive.eventKey
            );

            if (!isTabActiveAlreadyAdded) {
              newItemTab = itemTabActive;
            }
          });

          return newItemTab !== undefined ? [...prev, newItemTab] : prev;
        }

        return [...prev, valueTab];
      });

      setTabSelecionada(newItemTab === undefined ? valueTab : newItemTab);
    },
    [tabsAtivas]
  );

  const handleAdicionarTab = useCallback(() => {
    const lastIsAddedTab = tabListEtapasAtivas[tabListEtapasAtivas.length - 1];

    const indexLastTab =
      tabsAtivas.findIndex(
        (tabItemActive) => tabItemActive.eventKey === lastIsAddedTab.eventKey
      ) + 1;

    const newListTabActive = [...tabsAtivas];

    newListTabActive.splice(indexLastTab, tabsAtivas.length);

    const isNewTabActiveAdded = newListTabActive.find((itemNewListActive) => {
      const tabHaveAlreadyBeenAdded = tabListEtapasAtivas.some(
        (itemPrev) => itemPrev.eventKey === itemNewListActive.eventKey
      );

      return !tabHaveAlreadyBeenAdded;
    });

    if (isNewTabActiveAdded) {
      addTabActiveRecently(isNewTabActiveAdded);
      return;
    }
    addNewTabs(indexLastTab);
  }, [addNewTabs, addTabActiveRecently, tabListEtapasAtivas, tabsAtivas]);

  const listNewTabs = useCallback(() => {
    if (isLargerThan900) {
      return tabListEtapasAtivas;
    }

    const sliceItens = isLargerThan700 ? 3 : 1;

    const indexAtual = tabListEtapasAtivas.findIndex(
      (tabItem) => tabItem.eventKey === tabSelecionada.eventKey
    );

    const listTabs = [...tabListEtapasAtivas];

    const amountItems = listTabs.length;

    const lastIsItem =
      tabSelecionada.eventKey === listTabs[listTabs.length - 1]?.eventKey;

    const firstIsItem = tabSelecionada.eventKey === listTabs[0]?.eventKey;

    if (amountItems >= (isLargerThan700 ? 4 : 2)) {
      if (lastIsItem) {
        const items = listTabs.splice(
          listTabs.length - sliceItens,
          listTabs.length
        );
        return items;
      }

      if (firstIsItem) {
        const items = listTabs.splice(0, sliceItens);
        return items;
      }

      const items = listTabs.splice(indexAtual - 1, indexAtual + sliceItens);
      let newValues = items;

      if (items.length > (isLargerThan700 ? 3 : 1)) {
        newValues = items.splice(0, sliceItens);
      }

      return newValues;
    }

    return listTabs;
  }, [isLargerThan700, isLargerThan900, tabListEtapasAtivas, tabSelecionada])();

  const handleNextTab = useCallback(() => {
    const tabsAdicionadas = [...tabListEtapasAtivas];

    const valueIndexTab = tabsAdicionadas.findIndex(
      (itemTab) => itemTab.eventKey === tabSelecionada.eventKey
    );

    setTabSelecionada(tabsAdicionadas[valueIndexTab + 1]);
  }, [tabListEtapasAtivas, tabSelecionada.eventKey]);

  const handlePrevTab = useCallback(() => {
    const tabsAdicionadas = [...tabListEtapasAtivas];

    const valueIndexTab = tabsAdicionadas.findIndex(
      (itemTab) => itemTab.eventKey === tabSelecionada.eventKey
    );

    setTabSelecionada(tabsAdicionadas[valueIndexTab - 1]);
  }, [tabListEtapasAtivas, tabSelecionada.eventKey]);

  const handleAnimationComplete = (isCompleto: boolean) => {
    setIsPlaying(isCompleto);
  };

  const handleModificarTabs = useCallback(
    async (funciont: () => void) => {
      if (tabSelecionada?.onClick !== undefined) {
        const response = await tabSelecionada?.onClick();

        if (response) {
          funciont();
          setIsFormularioSalvo(true);
          handleAnimationComplete(true);
        }
      } else {
        funciont();
        setIsFormularioSalvo(true);
        handleAnimationComplete(true);
      }
    },
    [tabSelecionada]
  );

  const handleSelecionarTab = useCallback(
    async (tab: TabTypeProps) => {
      if (tabSelecionada?.onClick !== undefined) {
        const response = await tabSelecionada?.onClick();

        if (response) {
          selectedTab(tab);
          setIsFormularioSalvo(true);
          handleAnimationComplete(true);
        }
      } else {
        selectedTab(tab);
        setIsFormularioSalvo(true);
        handleAnimationComplete(true);
      }
    },
    [tabSelecionada]
  );

  useEffect(() => {
    if (isLargerThan900 || isLargerThan700) {
      setTabSelecionada(tabs[0]);
      setTabListEtapa([tabsAtivas[0]]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLargerThan900, isLargerThan700]);

  useEffect(() => {
    setTabListEtapa((prev) => {
      const newListTabsActive = tabs;
      const newListTabsAdded = prev
        .map((prevItem) => {
          const indexTab = newListTabsActive.findIndex(
            (itemTab) => itemTab.eventKey === prevItem.eventKey
          );

          return {
            ...prevItem,
            hidden: newListTabsActive[indexTab]?.hidden,
          };
        })
        .sort((a, b) => {
          if (a.eventKey > b.eventKey) return 1;
          if (a.eventKey < b.eventKey) return -1;
          return 0;
        });
      return newListTabsAdded;
    });
  }, [tabs]);

  return (
    <Box bg="gray.100" position="relative">
      {!isExibirPorEtapa && !isLargerThan900 ? (
        <>
          <Header
            key="header"
            isPlaying={isPlaying}
            alertDescription={alertDescription}
            subtitulo={subtitulo || ''}
            titulo={titulo || ''}
            handleAnimationComplete={handleAnimationComplete}
            isFormularioSalvo={isFormularioSalvo}
            h={isLargerThan900 ? '160px' : '110px'}
          >
            <ListTabMobile
              tabSelecionada={tabSelecionada}
              tabsAtivas={tabsAtivas}
              isDisabled={isDisabled}
              handleSelecionarTab={handleSelecionarTab}
            />
          </Header>
        </>
      ) : (
        <>
          <Header
            key="header"
            isPlaying={isPlaying}
            alertDescription={alertDescription}
            subtitulo={subtitulo || ''}
            titulo={titulo || ''}
            h="116px"
            handleAnimationComplete={handleAnimationComplete}
            isFormularioSalvo={isFormularioSalvo}
          />

          <Box h="70px">
            <HStack
              px={['5.8%', '5.8%', '2.8%', '5.8%']}
              w="full"
              top="70px"
              h="90px"
              spacing="16px"
              position="absolute"
            >
              {!isLargerThan900 && !firstTabIsSelected && (
                <Box
                  color="white"
                  height="full"
                  onClick={() => {
                    if (!isDisabled) {
                      handleModificarTabs(handlePrevTab);
                    }
                  }}
                >
                  <Flex
                    gap="4px"
                    mt="30px"
                    alignItems="start"
                    justifyContent="start"
                    cursor="pointer"
                  >
                    <Icon as={SetaIcon} fontSize={16} transform="scaleX(-1)" />
                    <Text fontSize="14px">Voltar</Text>
                  </Flex>
                </Box>
              )}
              {(isExibirPorEtapa ? listNewTabs : tabsAtivas).map((tabItem) => {
                const tabInativa =
                  isDisabled && tabSelecionada.eventKey !== tabItem.eventKey;
                return (
                  <Tooltip
                    hasArrow
                    w="200px"
                    borderRadius="6px"
                    p="10px"
                    label={
                      tabInativa
                        ? 'É preciso preencher todos os campos obrigatórios para mudar de etapa'
                        : undefined
                    }
                  >
                    <Flex
                      _hover={{
                        borderColor: tabInativa ? 'transparent' : 'purple.500',
                      }}
                      onClick={() => {
                        if (!isDisabled) {
                          handleSelecionarTab(tabItem);
                        }
                      }}
                      boxShadow="0px 0px 4px #00000029"
                      borderRadius="xl"
                      justifyContent="center"
                      cursor={tabInativa ? 'not-allowed' : 'pointer'}
                      alignItems="center"
                      w="160px !important"
                      borderWidth="1px"
                      borderStyle="solid"
                      bg={
                        tabSelecionada.eventKey === tabItem.eventKey
                          ? 'white'
                          : 'gray.100'
                      }
                      h="full"
                    >
                      <Box>
                        <Flex justifyContent="center" alignItems="center">
                          <tabItem.icon size={40} />
                        </Flex>
                        <Text
                          textAlign="center"
                          fontSize="14px"
                          fontWeight="semibold"
                          mt="3px"
                        >
                          {isLargerThan700
                            ? tabItem.title
                            : tabSelecionada.title}
                        </Text>
                      </Box>
                    </Flex>
                  </Tooltip>
                );
              })}
              {tabsAtivas.length !== tabListEtapasAtivas.length &&
                isExibirPorEtapa &&
                exibirAvancar && (
                  <Tooltip
                    hasArrow
                    label={
                      isDisabled
                        ? 'É preciso preencher todos os campos obrigatórios para avançar de etapa'
                        : undefined
                    }
                  >
                    <Flex
                      _hover={{
                        boxShadow: isDisabled
                          ? 'none'
                          : '0px 0px 4px rgba(85, 2, 178, 0.5)',
                      }}
                      boxShadow="0px 0px 4px #00000029"
                      borderRadius="5px"
                      justifyContent="center"
                      cursor={isDisabled ? 'not-allowed' : 'pointer'}
                      onClick={() => {
                        if (!isDisabled) {
                          handleModificarTabs(handleAdicionarTab);
                        }
                      }}
                      alignItems="center"
                      w="140px !important"
                      bg={isDisabled ? 'gray.200' : 'secondary.300'}
                      h="full"
                      color="violet.900"
                    >
                      <Box>
                        <Flex justifyContent="center" alignItems="center">
                          <Icon
                            as={SetaIcon}
                            fontSize={35}
                            color={isDisabled ? 'gray.500' : 'violet.900'}
                          />
                        </Flex>
                        <Text
                          fontWeight="bold"
                          fontSize="14px"
                          mt="9px"
                          color={isDisabled ? 'gray.500' : 'violet.900'}
                        >
                          AVANÇAR
                        </Text>
                      </Box>
                    </Flex>
                  </Tooltip>
                )}

              {!isLargerThan900 && !lastTabIsSelected && (
                <Box
                  onClick={() => {
                    if (!isDisabled) {
                      handleModificarTabs(handleNextTab);
                    }
                  }}
                  height="full"
                >
                  <Flex
                    gap="4px"
                    mt="30px"
                    alignItems="start"
                    justifyContent="start"
                    cursor="pointer"
                    color="white"
                  >
                    <Text fontSize="14px">Avançar</Text>
                    <Icon as={SetaIcon} fontSize={16} />
                  </Flex>
                </Box>
              )}
            </HStack>
          </Box>
        </>
      )}

      <Box
        pt={!isExibirPorEtapa && !isLargerThan900 ? '20px' : undefined}
        px="24px"
      >
        {tabSelecionada.content}
      </Box>
    </Box>
  );
};
