import { useState, useCallback, useEffect } from 'react';
import {
  Text,
  Flex,
  Icon,
  Box,
  Checkbox,
  useToken,
  Image,
} from '@chakra-ui/react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

import {
  useCategoriaProdutoContext,
  CategoriaProdutoProps,
} from 'store/SmartPos/CategoriaProdutos';

import { CardCategoriaProduto } from 'components/CategoriaProdutos/Card';
import { ContainerCategoriaProduto } from 'components/CategoriaProdutos/Container';
import { IoMdArrowDownRight, ArrastarImagemIcon } from 'icons';

interface ListItemProdutoProps {
  nivel: number;
  itemCategoriaProduto: CategoriaProdutoProps;
  selecionavel: boolean;
  children: React.ReactNode;
}

export const ListItemProduto = ({
  nivel,
  itemCategoriaProduto,
  selecionavel,
  children,
}: ListItemProdutoProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const {
    setCategorias,
    categorias,
    isItemFoiExpandido,
    setCategoriaAlterada,
  } = useCategoriaProdutoContext();

  const gray200 = useToken('colors', 'gran.200');

  const encontrarItensRelacionados = useCallback(
    (categoriaProduto: CategoriaProdutoProps[], id: string) => {
      const newListCategoriaProduto = [] as CategoriaProdutoProps[];

      function percorrerItens(itemRelacionado: CategoriaProdutoProps) {
        newListCategoriaProduto.push(itemRelacionado);
        const filhos = categoriaProduto.filter(
          (itemCategoria) =>
            itemCategoria.categoriaProdutoPaiId === itemRelacionado.id
        );
        filhos.forEach((filho) => percorrerItens(filho));
      }

      const itemInicial = categoriaProduto.find(
        (itemCategoria) => itemCategoria.id === id
      );
      percorrerItens(itemInicial as CategoriaProdutoProps);

      return newListCategoriaProduto;
    },
    []
  );

  const handleToggleSelecionarCategoria = useCallback(
    (itemProduto: CategoriaProdutoProps, itemSelecionavel: boolean) => {
      setCategoriaAlterada(true);
      const newListCategoria = categorias.map((itemCategoria) => {
        const itemSelecionado =
          itemCategoria?.categoriaProdutoPaiId === itemProduto?.id ||
          itemCategoria.id === itemProduto?.id;

        return {
          ...itemCategoria,
          isChecked: itemSelecionado
            ? !itemProduto.isChecked
            : itemCategoria.isChecked,
        };
      });

      let newListCategoriaProdutos = [] as CategoriaProdutoProps[];

      if (!itemSelecionavel) {
        const listItensRelacionados = encontrarItensRelacionados(
          newListCategoria,
          itemProduto.id
        );

        newListCategoriaProdutos = newListCategoria.map((itemCategoria) => {
          const isItensRelacionados = listItensRelacionados.some(
            (itemRelacionado) => itemRelacionado.id === itemCategoria.id
          );

          const valueItemRelacionado = newListCategoria.find(
            (newItemCategoria) => newItemCategoria.id === itemProduto.id
          );

          return {
            ...itemCategoria,
            isChecked: isItensRelacionados
              ? valueItemRelacionado?.isChecked
              : itemCategoria.isChecked,
          };
        });
      } else {
        newListCategoriaProdutos = newListCategoria;
      }

      setCategorias(newListCategoriaProdutos);
    },
    [
      categorias,
      encontrarItensRelacionados,
      setCategoriaAlterada,
      setCategorias,
    ]
  );

  const handleExibirCategorias = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  useEffect(() => {
    setIsOpen(isItemFoiExpandido);
  }, [isItemFoiExpandido]);

  return (
    <>
      <ContainerCategoriaProduto nivel={nivel}>
        {nivel > 1 && (
          <Icon
            as={IoMdArrowDownRight}
            mr="8px"
            boxSize="20px"
            color="gray.700"
          />
        )}
        <CardCategoriaProduto
          isSmartPos
          id="categoria-produto-container"
          bg={itemCategoriaProduto.isChecked ? 'purple.50' : 'white'}
          nivel={nivel}
          borderWidth="1px"
          borderStyle="solid"
          borderColor={itemCategoriaProduto.isChecked ? 'purple.50' : 'gray.50'}
          _hover={{
            borderColor: 'rgba(85, 2, 178, 0.5)',
            transition: 'all 0.2s',
          }}
          transition="all 0.2s"
          _active={{ bg: 'white' }}
        >
          <Flex
            h="full"
            w="full"
            alignItems="center"
            justifyContent="space-between"
          >
            <Flex pt="2px" h="full" alignItems="center" justifyContent="center">
              <Box mr={['16px', '16px', '40px']}>
                <Checkbox
                  mb="0"
                  isChecked={itemCategoriaProduto.isChecked}
                  onChange={() =>
                    handleToggleSelecionarCategoria(
                      itemCategoriaProduto,
                      selecionavel
                    )
                  }
                  colorScheme="primary"
                />
              </Box>
              <Flex
                alignItems="center"
                justifyContent="center"
                boxSize="40px"
                borderRadius="4px"
                borderWidth="1px"
                borderColor="gray.200"
                borderStyle="solid"
                bg="white"
                mr="12px"
              >
                {itemCategoriaProduto?.foto ? (
                  <Image
                    src={itemCategoriaProduto.foto}
                    alt="Imagem categoria de produto"
                  />
                ) : (
                  <ArrastarImagemIcon bg={gray200} color={gray200} size={10} />
                )}
              </Flex>
              <Box>
                <Text
                  color="gray.700"
                  fontSize="18px"
                  textAlign="center"
                  id="categoria-produto-nome"
                >
                  {itemCategoriaProduto.categoria}
                </Text>
              </Box>
            </Flex>
            {!selecionavel && (
              <Icon
                as={isOpen ? FiChevronUp : FiChevronDown}
                cursor="pointer"
                color="black"
                boxSize="18px"
                onClick={() => handleExibirCategorias()}
              />
            )}
          </Flex>
        </CardCategoriaProduto>
      </ContainerCategoriaProduto>

      {isOpen && !selecionavel && children}
    </>
  );
};
