import React, { useCallback, useState } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Icon,
  Flex,
  ModalCloseButton,
  useMediaQuery,
  useDisclosure,
  ModalHeader,
  VStack,
  Text,
  ModalFooter,
  Button,
  Stack,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { useFieldArray, useForm, Control, FormProvider } from 'react-hook-form';

import { SalvarInserirNovoIcon } from 'icons';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { NumberInput } from 'components/update/Input/NumberInput';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';

import { Totalizador } from './Totalizador';

interface ITamanho {
  produtoCorTamanhoId: string;
  tamanho: string;
  padraoSistema?: boolean;
  quantidade?: number;
  precoVenda?: number;
}

interface IFieldArrayType {
  tamanhos: ITamanho[];
}

type Tamanhos = {
  tamanhos: {
    produtoCorTamanhoId: string;
    tamanho: string;
    padraoSistema?: boolean | undefined;
    quantidade?: number | undefined;
  }[];
};

type ProdutoCorTamanho = {
  produtoNome: string;
  corDescricao?: string;
  tamanhoDescricao?: string;
  produtoCorTamanhoId: string;
  quantidade: number;
  precoVenda: number;
  adicionarNovamenteProduto?: boolean;
};

type ModalGradeTamanhosResponse = Array<ProdutoCorTamanho>;

type ModalGradeTamanhosProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalGradeTamanhosResponse> & {
    produtoNome: string;
    corDescricao?: string;
    casasDecimaisQuantidade: number;
    volumeUnitario?: boolean;
    tamanhos: ITamanho[];
    inserirNovoTamanho?: boolean;
    padraoSistema?: boolean;
  };

const ModalGradeTamanhosComponent = ({
  produtoNome,
  corDescricao,
  onResolve,
  onReject,
  inserirNovoTamanho,
  casasDecimaisQuantidade,
  volumeUnitario,
  tamanhos,
  ...rest
}: ModalGradeTamanhosProps) => {
  const formMethods = useForm({ defaultValues: { tamanhos } });
  const { fields } = useFieldArray<IFieldArrayType, 'tamanhos'>({
    control: formMethods.control as Control<any>,
    name: 'tamanhos',
  });

  const contemDescricaoTamanhoGrande = tamanhos.some(
    (tamanho) => tamanho.tamanho.length >= 5
  );

  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const [isLoading, setIsLoading] = useState(false);

  const salvarDadosTamanhos = useCallback(
    (data: Tamanhos, isInserirNovoTamanho: boolean) => {
      setIsLoading(true);

      const responseData = data.tamanhos
        .filter((tamanho: ITamanho) => !!tamanho.quantidade)
        .map(
          (tamanho: ITamanho) =>
            ({
              produtoNome,
              corDescricao,
              precoVenda: tamanho.precoVenda,
              tamanhoDescricao: tamanho.padraoSistema ? '' : tamanho.tamanho,
              produtoCorTamanhoId: tamanho.produtoCorTamanhoId,
              quantidade: tamanho.quantidade,
              adicionarNovamenteProduto: isInserirNovoTamanho,
              padraoSistema: tamanho.padraoSistema,
            } as ProdutoCorTamanho)
        );

      onResolve(responseData);
      setIsLoading(false);
    },
    [corDescricao, onResolve, produtoNome]
  );

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    salvarDadosTamanhos(data, false);
  });

  const handleInserirNovoTamanho = formMethods.handleSubmit(async (data) => {
    salvarDadosTamanhos(data, true);
  });

  const handleLimparDados = () => {
    fields.forEach((_, index) => {
      formMethods.setValue(`tamanhos.${index}.quantidade` as const, 0);
    });
  };

  return (
    <ModalPadraoChakra
      isCentered
      size={!isSmallerThan900 ? 'xl' : 'full'}
      {...rest}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalContent
        marginBottom={{ base: 0, md: '3.75rem' }}
        marginTop={{ base: 0, md: '3.75rem' }}
        h="unset"
        maxW={{ base: '100%', md: inserirNovoTamanho ? '890px' : '690px' }}
      >
        {isLoading && <LoadingPadrao />}
        <ModalHeader
          mt={isSmallerThan900 ? 12 : undefined}
          mb={isSmallerThan900 ? 8 : undefined}
          px="0"
          mx={{ base: 6, md: 8 }}
        >
          <VStack alignItems="flex-start" spacing="1" lineHeight="1">
            <Text color="primary.500" fontSize={{ base: 'xl', md: 'md' }}>
              {produtoNome}
            </Text>
            {corDescricao && (
              <Text color="gray.400" fontSize={{ base: 'sm', md: 'xs' }}>
                {`Cor: ${corDescricao}`}
              </Text>
            )}
          </VStack>
        </ModalHeader>
        <ModalCloseButton />
        <FormProvider {...formMethods}>
          <ModalBody
            bg="gray.50"
            py={{ base: 4, md: 6 }}
            pl={{ base: 4, md: 6 }}
            pr="15px"
            sx={{
              '& .chakra-form-control': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',

                justifyContent: 'center',
              },
              '& .chakra-input__group': {
                width: volumeUnitario ? '55px' : 'full',
                '& > input': {
                  px: '1',
                },
              },
              '& .chakra-stack': {
                maxWidth: '55px',
                justifyContent: 'center',
                overflow: 'visible',
                '& .chakra-form__label': {
                  marginRight: '0',
                  whiteSpace: 'nowrap',
                },
              },
            }}
          >
            <Flex
              maxH="300px"
              px="10px"
              pb="10px"
              overflowX="hidden"
              overflowY="auto"
              flexDirection="column"
              h="full"
            >
              <SimpleGridForm
                columns={{
                  base: contemDescricaoTamanhoGrande || !volumeUnitario ? 2 : 4,
                  sm: contemDescricaoTamanhoGrande || !volumeUnitario ? 5 : 8,
                }}
              >
                {fields.map((field, index) => (
                  <NumberInput
                    key={field.id}
                    id={`tamanho-${field.id}`}
                    name={`tamanhos.${index}.quantidade`}
                    label={field.tamanho}
                    min={0}
                    placeholder={
                      volumeUnitario
                        ? '0'
                        : `0,${'0'.repeat(casasDecimaisQuantidade)}`
                    }
                    scale={volumeUnitario ? 0 : casasDecimaisQuantidade}
                    autoFocus={index === 0}
                  />
                ))}
              </SimpleGridForm>
            </Flex>
          </ModalBody>
          <ModalFooter
            justifyContent="flex-start"
            mx={{ base: 6, md: 8 }}
            px="0"
          >
            <Stack
              direction={{ base: 'column', md: 'row' }}
              w="full"
              justifyContent="space-between"
              spacing={6}
            >
              <Button
                borderRadius="md"
                variant="outline"
                minW="100px"
                color="gray.400"
                onClick={() => onReject(undefined)}
              >
                Cancelar
              </Button>

              <Totalizador />

              <Stack direction={{ base: 'column', md: 'row' }} spacing={6}>
                <Button
                  borderRadius="md"
                  variant="outline"
                  minW="100px"
                  color="gray.400"
                  onClick={handleLimparDados}
                  isDisabled={isLoading}
                >
                  Limpar dados
                </Button>
                {inserirNovoTamanho && (
                  <Button
                    borderRadius="md"
                    variant="outline"
                    minW="150px"
                    color="gray.400"
                    leftIcon={<Icon as={SalvarInserirNovoIcon} fontSize="lg" />}
                    onClick={handleInserirNovoTamanho}
                    isDisabled={isLoading}
                    isLoading={isLoading}
                  >
                    Confirmar e inserir novo
                  </Button>
                )}
                <Button
                  borderRadius="md"
                  colorScheme="aquamarine"
                  minW="100px"
                  leftIcon={<Icon as={SalvarInserirNovoIcon} fontSize="lg" />}
                  onClick={handleSubmit}
                  isDisabled={isLoading}
                  isLoading={isLoading}
                >
                  Confirmar
                </Button>
              </Stack>
            </Stack>
          </ModalFooter>
        </FormProvider>
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export const ModalGradeTamanhos = create<
  ModalGradeTamanhosProps,
  ModalGradeTamanhosResponse
>(ModalGradeTamanhosComponent);
