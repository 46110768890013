import Input from 'components/PDV/Input';
import CheckboxAtivoInativo from 'components/update/Checkbox/CheckboxAtivoInativo';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';

import UnidadeFiscalEnum from 'constants/enum/unidadeFiscal';
import { Box, Flex } from '@chakra-ui/react';

interface UncontrolledFormInterface {
  readonly?: boolean;
}

export const UncontrolledForm = ({ readonly }: UncontrolledFormInterface) => {
  return (
    <Box>
      <Box>
        <Input
          type="text"
          name="descricao"
          label="Descrição"
          isRequired
          autoFocus
          placeholder="Digite um nome para diferenciar a unidade de medida"
          maxLength={30}
          isDisabled={readonly}
        />
      </Box>
      <Flex
        direction={['column', 'column', 'row']}
        alignItems="center"
        mt={['10px', '10px', '20px']}
      >
        <Box w={['full', 'full', '30%']} mr={['0', '0', '3%']}>
          <Input
            type="text"
            name="sigla"
            label="Sigla"
            isRequired
            placeholder="Digite a sigla"
            maxLength={6}
            isDisabled={readonly}
          />
        </Box>
        <Box w={['full', 'full', '30%']} mr={['0', '0', '3%']}>
          <Input
            type="text"
            name="unidadeTributavel"
            label="Unidade tributável"
            placeholder="Digite a unidade tributável"
            maxLength={6}
            isRequired
            isDisabled={readonly}
          />
        </Box>
        <Box
          mt={['10px', '10px', '0']}
          w={['full', 'full', '30%']}
          mr={['0', '0', '3%']}
        >
          <SelectPadrao
            id="unidadeFiscal"
            name="unidadeFiscal"
            label="Unidade fiscal"
            options={UnidadeFiscalEnum.properties.map((unidadeFiscal) => {
              return (
                {
                  label: unidadeFiscal.name,
                  value: unidadeFiscal.value,
                } || {}
              );
            })}
            isDisabled={readonly}
          />
        </Box>
        <Box mt={['10px', '10px', '0']} w={['full', 'full', '30%']}>
          <CheckboxAtivoInativo
            id="ativo"
            name="ativo"
            label="Status"
            defaultChecked={false}
            isDisabled={readonly}
          />
        </Box>
      </Flex>
    </Box>
  );
};
