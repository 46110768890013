import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  HStack,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  PinInput,
  PinInputField,
  Divider,
  Box,
  Flex,
  ModalCloseButton,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import Loading from 'components/Layout/Loading/LoadingPadrao';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';

interface PinA3ModalProps {
  show: boolean;
  onHide: (willReset?: boolean) => void;
  lojaId: string;
}

const ModalPinA3: React.FC<PinA3ModalProps> = ({ show, onHide, lojaId }) => {
  const [copiado, setCopiado] = useState(false);
  const [pin, setPin] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const obterPinA3 = useCallback(async () => {
    setIsLoading(true);

    const retorno = await api.post<void, ResponseApi<string>>(
      `${ConstanteEnderecoWebservice.LOJA_GERAR_PIN_A3}/${lojaId}`
    );

    if (retorno.sucesso) {
      setPin(retorno.dados);
    } else {
      toast.warning('Não foi possível gerar o código de configuração.');
    }

    setIsLoading(false);
  }, [lojaId]);

  useEffect(() => {
    obterPinA3();
  }, [obterPinA3]);

  return (
    <>
      {isLoading && <Loading />}
      <ModalPadraoChakra
        isOpen={show}
        onClose={onHide}
        isCentered
        autoFocus={false}
        size="2xl"
      >
        <ModalContent
          bg="gray.50"
          borderRadius="md"
          maxH="279px"
          maxW="480px"
          marginBottom="3.75rem"
          marginTop="3.75rem"
        >
          <ModalHeader>
            <Text fontSize="16px" color="primary.50">
              Código de configuração do Módulo desktop
            </Text>
            <ModalCloseButton />
            <Text pb="15px" fontSize="14px">
              Copie o código e informe na configuração inicial do aplicativo
            </Text>
            <Divider />
          </ModalHeader>
          <ModalBody h="90%">
            <HStack
              alignItems="center"
              h="full"
              w="full"
              justifyContent="center"
            >
              <PinInput value={pin}>
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
              </PinInput>
              <Button
                h="2.5rem"
                size="sm"
                onClick={() => {
                  navigator.clipboard.writeText(pin);
                  setCopiado(true);

                  setTimeout(() => {
                    setCopiado(false);
                  }, 1500);
                }}
                colorScheme={copiado ? 'secondary' : 'primary'}
                borderRadius="md"
                w="70px"
                autoFocus
              >
                {copiado ? 'Copiado!' : 'Copiar'}
              </Button>
            </HStack>
          </ModalBody>
          <ModalFooter h="30%">
            <Box w="full">
              <Divider />
              <Flex justifyContent="center" pt="15px">
                <Button
                  w="96px"
                  onClick={() => {
                    onHide(true);
                  }}
                  variant="outline"
                >
                  Fechar
                </Button>
              </Flex>
            </Box>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    </>
  );
};

export default ModalPinA3;
