import React, {
  createContext,
  useState,
  useContext,
  Dispatch,
  useEffect,
  SetStateAction,
  useMemo,
  useCallback,
} from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import * as signalR from '@microsoft/signalr';

import auth from 'modules/auth';
import ConstanteSignalRGroups from 'constants/signalRGroups';
import ConstanteRotas from 'constants/rotas';
import EnumStatusNotificacao from 'constants/enum/enumStatusNotificacao';
import usePersistedState from 'helpers/layout/usePersistedState';
import {
  formatDate,
  formatDateHourMinute,
} from 'helpers/format/formatStringDate';

interface SignalRContextProps {
  securityStamp: string;
  setSecurityStamp: Dispatch<SetStateAction<string>>;
  hubConnection: signalR.HubConnection;
  joinGroup: (group: string) => Promise<unknown>;
  exitGroup: (group: string) => Promise<void>;
  sendMessageToGroup: (
    groupName: string,
    method: string,
    message?: string
  ) => Promise<unknown>;
  setHubConnectionLogoff: () => void;
  setHubConnectionLogoffSair: () => void;
  possuiNotificacao: boolean;
  notificacaoTray: string | undefined;
  setPossuiNotificacao: React.Dispatch<React.SetStateAction<boolean>>;
  setNotificacaoTray: React.Dispatch<React.SetStateAction<string>>;
  handleSetNotificationsPdvAutonomo: (notifications: string[]) => void;
  notificationsPdvAutonomo: string[];
  handleSetNotificationsFrenteCaixa: (notifications: string[]) => void;
  notificationsFrenteCaixa: string[];
  notificarVendas: NotificacaoVenda[];
  setNotificarVendas: (value: React.SetStateAction<NotificacaoVenda[]>) => void;
  notificarNotaFiscal: NotaFiscalEmitindo;
  statusNotaFiscal: StatusNotaFiscal | undefined;
  setStatusNotaFiscal: React.Dispatch<
    React.SetStateAction<StatusNotaFiscal | undefined>
  >;
  setNotificarNotaFiscal: React.Dispatch<
    React.SetStateAction<NotaFiscalEmitindo>
  >;
  avisoNotaFiscal: NotaFiscalEmitindo | undefined;
}

export type Mensagem = {
  lojaId: string;
  usuarioId: string;
  mensagem: string;
  id: string;
  integracaoPedidoId: string;
  isChecked: boolean;
};

export type NotificacaoVenda = Mensagem & {
  dataNotificacao: Date;
};

export type NotaFiscalEmitindo = {
  lojaId: string;
  mensagem: string;
  id: string;
};

export type StatusNotaFiscal = {
  transacaoId: string;
  status: string | null;
  usuarioId: string;
  operacaoId: string;
  motivo: string | null;
  quantidadeRetornoIndisponivel: number;
  quantidadeRejeitada: number;
  quantidadeAutorizada: number;
  quantidadeCancelada: number;
  quantidadeDenegado: number;
};

const SignalRContext = createContext<SignalRContextProps>(
  {} as SignalRContextProps
);

interface SignalRProviderProps {
  children: React.ReactNode | undefined;
}

export default function SignalRProvider({
  children,
}: SignalRProviderProps): JSX.Element {
  const [securityStamp, setSecurityStamp] = useState<string>('');
  const [possuiNotificacao, setPossuiNotificacao] = useState<boolean>(false);
  const [notificacaoTray, setNotificacaoTray] = usePersistedState<string>(
    'notificacaoTray',
    ''
  );
  const [notificarVendas, setNotificarVendas] = usePersistedState<
    NotificacaoVenda[]
  >('notificarVendas', [] as NotificacaoVenda[]);
  const [
    notificarNotaFiscal,
    setNotificarNotaFiscal,
  ] = useState<NotaFiscalEmitindo>({} as NotaFiscalEmitindo);
  const [statusNotaFiscal, setStatusNotaFiscal] = useState<StatusNotaFiscal>();
  const [avisoNotaFiscal, setAvisoNotaFiscal] = useState<NotaFiscalEmitindo>();
  const [notificationsFrenteCaixa, setNotificationsFrenteCaixa] = useState<
    string[]
  >([]);
  const [notificationsPdvAutonomo, setNotificationsPdvAutonomo] = useState<
    string[]
  >([]);

  const history = useHistory();
  const { userId: usuarioId = undefined } = auth.getDadosToken() || {};
  const { id: lojaId } = auth.getLoja();

  const hubConnection = useMemo(
    () =>
      new signalR.HubConnectionBuilder()
        .withUrl(
          process.env.REACT_APP_API_URL?.replace(
            'api/',
            'notificationHub'
          ) as string
        )
        .configureLogging(signalR.LogLevel.None)
        .withAutomaticReconnect()
        .build(),
    []
  );

  const GetOpenConnection = useCallback(async () => {
    return new Promise((resolve, reject) => {
      const msMax = 5000;
      const msInc = 10;

      let ms = 0;

      const idInterval = setInterval(() => {
        if (hubConnection.state === signalR.HubConnectionState.Disconnected) {
          hubConnection.start();
        }

        if (hubConnection.state === signalR.HubConnectionState.Connected) {
          clearInterval(idInterval);
          resolve(hubConnection);
        }

        ms += msInc;

        if (ms >= msMax) {
          clearInterval(idInterval);
          reject(hubConnection);
        }
      }, msInc);
    });
  }, [hubConnection]);

  const joinGroup = useCallback(
    async (group: string) => {
      GetOpenConnection().then(() => {
        hubConnection.invoke('JoinGroup', group);
      });
    },
    [GetOpenConnection, hubConnection]
  );

  const exitGroup = useCallback(
    async (group: string) => {
      if (hubConnection.state === signalR.HubConnectionState.Connected) {
        await hubConnection.invoke('ExitGroup', group);
      }
    },
    [hubConnection]
  );

  const sendMessageToGroup = useCallback(
    async (groupName: string, method: string, message?: string) => {
      GetOpenConnection().then(() => {
        hubConnection.invoke(
          'SendMessageToGroup',
          groupName,
          method,
          message || ''
        );
      });
    },
    [GetOpenConnection, hubConnection]
  );

  const setHubConnectionLogoff = useCallback(() => {
    if (auth.getToken()) {
      exitGroup(auth.getSecurityStamp());
      hubConnection.off('logoff_obrigatorio');

      hubConnection.on('logoff_obrigatorio', (message, connectionId) => {
        if (connectionId !== hubConnection.connectionId) {
          auth.clearToken();
          history.push(ConstanteRotas.LOGIN);
          toast.warning(message, { autoClose: 10000 });
        }
      });

      joinGroup(auth.getSecurityStamp());
    }
  }, [exitGroup, history, hubConnection, joinGroup]);

  const setHubConnectionLogoffSair = useCallback(() => {
    if (auth.getToken()) {
      exitGroup(auth.getSecurityStamp());
      hubConnection.off('logoff');

      hubConnection.on('logoff', (_, connectionId) => {
        if (connectionId !== hubConnection.connectionId) {
          auth.clearToken();
          history.push(ConstanteRotas.LOGIN);
        }
      });

      joinGroup(auth.getSecurityStamp());
    }
  }, [exitGroup, history, hubConnection, joinGroup]);

  const messageSignalImportacao = useCallback((status: string) => {
    if (status === EnumStatusNotificacao.CONCLUIDA) {
      toast.success(
        'Sua importação está pronta. Acesse a Conferência de Estoque para conferir os resultados!'
      );
    } else {
      toast.warning(
        'Houve um erro na importação! Acesse a Conferência de Estoque para mais informações'
      );
    }
  }, []);

  const connectionImportacao = useCallback(async () => {
    const groupName = ConstanteSignalRGroups.NOTIFICACAO_IMPORTACAO_CONFERENCIA.replace(
      '{0}',
      usuarioId
    );

    await joinGroup(groupName);

    hubConnection.on('Status', (status: string) => {
      messageSignalImportacao(status);
    });
  }, [hubConnection, joinGroup, messageSignalImportacao, usuarioId]);

  useEffect(() => {
    connectionImportacao();
    return () => {
      hubConnection.off('Status');
    };
  }, [connectionImportacao, hubConnection]);

  useEffect(() => {
    setHubConnectionLogoff();
    setHubConnectionLogoffSair();
  }, [setHubConnectionLogoff, setHubConnectionLogoffSair]);

  const mensagemSignalRNotificacaoImportacaoTray = useCallback(() => {
    joinGroup(`tray-${lojaId}`);
    hubConnection.on('importacao-produto', (notificacao: Mensagem) => {
      if (usuarioId === notificacao.usuarioId) {
        setPossuiNotificacao(true);
        setNotificacaoTray('A importação da tray foi concluída com sucesso');
      }
    });
  }, [
    hubConnection,
    joinGroup,
    lojaId,
    setNotificacaoTray,
    setPossuiNotificacao,
    usuarioId,
  ]);

  const mensagemSignalRNotificacaoVenda = useCallback(() => {
    joinGroup(`tray-${lojaId}`);
    hubConnection.on('pedido', (notificacao: NotificacaoVenda) => {
      setNotificarVendas((prev) => {
        const dataNotificacao = new Date(notificacao.dataNotificacao);

        return [...prev, { ...notificacao, dataNotificacao, isChecked: true }];
      });
    });
  }, [hubConnection, joinGroup, lojaId, setNotificarVendas]);

  const mensagemSignalRNotificacaoEmissaoNotaFiscal = useCallback(() => {
    joinGroup(`zendar-${lojaId}`);
    hubConnection.on(
      'notafiscal-emitindo',
      (notificacao: NotaFiscalEmitindo) => {
        setNotificarNotaFiscal(notificacao);
      }
    );
  }, [hubConnection, joinGroup, lojaId, setNotificarNotaFiscal]);

  const mensagemSignalRNotificacaoAvisoNotaFiscal = useCallback(() => {
    joinGroup(`zendar-${lojaId}`);
    hubConnection.on('notafiscal-aviso', (notificacao: NotaFiscalEmitindo) => {
      setAvisoNotaFiscal(notificacao);
    });
  }, [hubConnection, joinGroup, lojaId]);

  const mensagemSignalRNotificacaoEmissaoNotaFiscalStatus = useCallback(() => {
    joinGroup(`zendar-${lojaId}`);

    hubConnection.on(
      'notafiscal-status',
      (notificacao: { mensagem: string }) => {
        let newMensagem;
        try {
          newMensagem = JSON.parse(
            String(notificacao.mensagem || '')
          ) as StatusNotaFiscal;
        } catch {
          newMensagem = undefined;
        }

        if (newMensagem?.usuarioId === usuarioId) {
          setPossuiNotificacao(true);

          setStatusNotaFiscal(newMensagem);
        }
      }
    );
  }, [hubConnection, joinGroup, lojaId, usuarioId, setPossuiNotificacao]);

  const mensagemSignalRNotificacaoPdvAutonomoExportado = useCallback(() => {
    joinGroup(`pdv-autonomo-${lojaId}`);

    hubConnection.on('exportacao-base-pdv', (notificacao: Mensagem) => {
      if (notificacao?.usuarioId === usuarioId) {
        setPossuiNotificacao(true);
        setNotificationsPdvAutonomo((prev) => [
          ...prev,
          'A exportação do PDV Offline foi concluída, clique aqui para saber mais',
        ]);
      }
    });
  }, [joinGroup, lojaId, hubConnection, usuarioId, setPossuiNotificacao]);

  const mensagemSignalRNotificacaoPdvAutonomoScriptGerado = useCallback(() => {
    joinGroup(`pdv-autonomo-${lojaId}`);

    hubConnection.on('gerar-script-pdv', (notificacao: Mensagem) => {
      if (notificacao?.usuarioId === usuarioId) {
        setPossuiNotificacao(true);
        setNotificationsPdvAutonomo((prev) => [
          ...prev,
          'A geração de script do PDV Offline foi concluída, clique aqui para saber mais',
        ]);
      }
    });
  }, [joinGroup, lojaId, hubConnection, usuarioId, setPossuiNotificacao]);

  const mensagemSignalRNotificacaoTabelaPrecosAtualizada = useCallback(() => {
    joinGroup(`tray-${lojaId}`);
    hubConnection.on('atualizacao-tabela-preco', (notificacao: Mensagem) => {
      if (notificacao?.usuarioId === usuarioId) {
        setPossuiNotificacao(true);
        setNotificacaoTray(
          'A atualização da tabela de preços foi concluída com sucesso'
        );
      }
    });
  }, [joinGroup, lojaId, hubConnection, usuarioId, setNotificacaoTray]);

  const mensagemSignalRNotificacaoExportacaoTray = useCallback(() => {
    let funcaoChamada = false;

    joinGroup(`tray-${lojaId}`);
    hubConnection.on('exportacao-produto', (notificacao: Mensagem) => {
      if (usuarioId === notificacao.usuarioId) {
        setPossuiNotificacao(true);

        if (funcaoChamada === false) {
          toast.success('A exportação da tray foi concluída com sucesso');
        }
        funcaoChamada = true;
        setNotificacaoTray('A exportação da tray foi concluída com sucesso');
      }
    });
  }, [
    hubConnection,
    joinGroup,
    lojaId,
    setNotificacaoTray,
    setPossuiNotificacao,
    usuarioId,
  ]);

  const mensagemSignalRNotificacaoLimiteProdutoTray = useCallback(() => {
    joinGroup(`tray-${lojaId}`);
    hubConnection.on('exportacao-produto-limite', (notificacao: Mensagem) => {
      if (usuarioId === notificacao.usuarioId) {
        setPossuiNotificacao(true);

        setNotificacaoTray(
          'Exportação de produtos da Tray foi concluída com limite excedido do plano.'
        );
      }
    });
  }, [
    hubConnection,
    joinGroup,
    lojaId,
    setNotificacaoTray,
    setPossuiNotificacao,
    usuarioId,
  ]);

  const mensagemSignalRNotificacaoFrenteCaixaExportado = useCallback(() => {
    joinGroup(`frente-caixa-${lojaId}`);

    hubConnection.on(
      'exportacao-base-frente-caixa',
      (notificacao: Mensagem) => {
        if (notificacao?.usuarioId === usuarioId) {
          setPossuiNotificacao(true);
          setNotificationsFrenteCaixa((prev) => [
            ...prev,
            'A exportação do Frente de Caixa foi concluída, clique aqui para saber mais',
          ]);
        }
      }
    );
  }, [joinGroup, lojaId, hubConnection, usuarioId, setPossuiNotificacao]);

  const mensagemSignalRNotificacaoFrenteCaixaScriptGerado = useCallback(() => {
    joinGroup(`frente-caixa-${lojaId}`);

    hubConnection.on('gerar-script-frente-caixa', (notificacao: Mensagem) => {
      if (notificacao?.usuarioId === usuarioId) {
        setPossuiNotificacao(true);
        setNotificationsFrenteCaixa((prev) => [
          ...prev,
          'A geração de script do Frente de Caixa foi concluída, clique aqui para saber mais',
        ]);
      }
    });
  }, [joinGroup, lojaId, hubConnection, usuarioId, setPossuiNotificacao]);

  const handleSetNotificationsPdvAutonomo = useCallback(
    (notifications: string[]) => {
      setNotificationsPdvAutonomo(notifications);
    },
    []
  );

  const handleSetNotificationsFrenteCaixa = useCallback(
    (notifications: string[]) => {
      setNotificationsFrenteCaixa(notifications);
    },
    []
  );

  useEffect(() => {
    mensagemSignalRNotificacaoImportacaoTray();
    mensagemSignalRNotificacaoExportacaoTray();
  }, [
    mensagemSignalRNotificacaoImportacaoTray,
    mensagemSignalRNotificacaoExportacaoTray,
  ]);

  useEffect(() => {
    mensagemSignalRNotificacaoVenda();
  }, [mensagemSignalRNotificacaoVenda]);

  useEffect(() => {
    mensagemSignalRNotificacaoEmissaoNotaFiscal();
  }, [mensagemSignalRNotificacaoEmissaoNotaFiscal]);

  useEffect(() => {
    mensagemSignalRNotificacaoEmissaoNotaFiscalStatus();
  }, [mensagemSignalRNotificacaoEmissaoNotaFiscalStatus]);

  useEffect(() => {
    mensagemSignalRNotificacaoLimiteProdutoTray();
  }, [mensagemSignalRNotificacaoLimiteProdutoTray]);

  useEffect(() => {
    mensagemSignalRNotificacaoTabelaPrecosAtualizada();
  }, [mensagemSignalRNotificacaoTabelaPrecosAtualizada]);

  useEffect(() => {
    mensagemSignalRNotificacaoAvisoNotaFiscal();
  }, [mensagemSignalRNotificacaoAvisoNotaFiscal]);

  useEffect(() => {
    mensagemSignalRNotificacaoPdvAutonomoScriptGerado();
    mensagemSignalRNotificacaoPdvAutonomoExportado();
  }, [
    mensagemSignalRNotificacaoPdvAutonomoExportado,
    mensagemSignalRNotificacaoPdvAutonomoScriptGerado,
  ]);

  useEffect(() => {
    mensagemSignalRNotificacaoFrenteCaixaScriptGerado();
    mensagemSignalRNotificacaoFrenteCaixaExportado();
  }, [
    mensagemSignalRNotificacaoFrenteCaixaExportado,
    mensagemSignalRNotificacaoFrenteCaixaScriptGerado,
  ]);

  return (
    <SignalRContext.Provider
      value={{
        securityStamp,
        setSecurityStamp,
        hubConnection,
        joinGroup,
        setStatusNotaFiscal,
        setNotificarNotaFiscal,
        exitGroup,
        sendMessageToGroup,
        possuiNotificacao,
        setPossuiNotificacao,
        notificacaoTray,
        setNotificacaoTray,
        setHubConnectionLogoff,
        setHubConnectionLogoffSair,
        notificarVendas,
        setNotificarVendas,
        notificarNotaFiscal,
        statusNotaFiscal,
        avisoNotaFiscal,
        handleSetNotificationsFrenteCaixa,
        notificationsFrenteCaixa,
        handleSetNotificationsPdvAutonomo,
        notificationsPdvAutonomo,
      }}
    >
      {children}
    </SignalRContext.Provider>
  );
}

export function useSignalRContext(): SignalRContextProps {
  const context = useContext(SignalRContext);

  if (!context)
    throw new Error('useSignalRContext must be used within a SignalRProvider.');

  return context;
}
