import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export const idConfigTefDefault = 'ELGIN';

export type DefaultInfoTef = {
  idConfigTef: string;
  endereco: string;
  loja: string;
  senhaSupervisor: string;
  parametros: string;
};

interface FormaPagamentoRecebimento {
  id: string;
  tef: boolean;
  credenciadoraCartaoId: string;
  operacaoTefId: string;
  parcelamentoTefId: string;
  tipoCartaoTefId: string;
}

export interface FormaRecebimentoProps {
  estabelecimento: string;
  loja: string;
  versaoAc: string;
  nomeAc: string;
  endereco: string;
  porta: string;
  parametros: string;
  tef: string;
  senhaSupervisor: string;
  usuarioLogin: string;
  usuarioSenha: string;
  listaFormaPagamentoRecebimento: FormaPagamentoRecebimento[];
}

export const valueDefaultInfoTef: DefaultInfoTef[] = [
  {
    idConfigTef: idConfigTefDefault,
    endereco: '',
    loja: '',
    senhaSupervisor: '',
    parametros: '',
  },
];

export const defaultValues = {
  ...valueDefaultInfoTef,
  parcelamentoTEF: null,
  operacaoTEF: null,
  tipoCartao: null,
  tef: false,
};

export type FormData = DefaultInfoTef & {
  parcelamentoTEF: string[] | null;
  operacaoTEF: string[] | null;
  tipoCartao: string[] | null;
  tef: boolean;
  credenciadoraCartaoId: string[] | null;
};

const schema = yup.object().shape({
  idConfigTef: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  endereco: yup.string().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  loja: yup.string().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  senhaSupervisor: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  parametros: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);
