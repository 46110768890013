import { Tr, Th, Td, VStack, Text, HStack, Tag, Flex } from '@chakra-ui/react';

import { DecimalMask } from 'helpers/format/fieldsMasks';

import { TextValor } from 'components/PDV/Text/TextValor';

import { CasasDecimais } from 'store/Padronizacao/Padronizacao';
import Card from '../Card';
import Table from '../Table';

type Item = {
  produto: string;
  cor: string;
  tamanho: string;

  quantidade: number;

  valorDescontoItem: number;
  valorUnitario: number;
  valorItemComDesconto: number;

  informacoesComplementares: string;
};

interface DescricaoItensProps {
  itens: Item[];

  subTotal: number;
  valorTotal: number;
  quantidadeProdutos: number;
  descontos: number;
  quantidade: number;
  isLoading: boolean;

  casasDecimais: CasasDecimais;
}

const DescricaoItens = ({
  itens,
  subTotal,
  valorTotal,
  quantidadeProdutos,
  quantidade,
  descontos,
  isLoading,
  casasDecimais,
}: DescricaoItensProps) => {
  return (
    <Card title="Descrição dos itens" padding="12px 24px 12px 12px">
      <Table
        sx={{
          '& > thead > tr > th': {
            color: 'black',
            fontSize: 'xs',
            pl: '12px',
          },
          '& > tbody > tr > td': {
            pl: '12px',
          },
          '& > tfoot > tr > th': {
            pl: '12px',
          },
        }}
        theadContent={
          <Tr>
            <Th>Descrição dos produtos </Th>
            <Th>Quantidade</Th>
            <Th isNumeric>Valor unitário</Th>
            <Th isNumeric>Desconto</Th>
            <Th isNumeric>Valor total</Th>
            <Th />
          </Tr>
        }
        tbodyContent={
          <>
            {itens &&
              itens.map((item) => (
                <Tr>
                  <Td w="55%" whiteSpace="nowrap">
                    <VStack
                      spacing="1"
                      lineHeight="none"
                      alignItems="flex-start"
                    >
                      <Text fontSize="sm" fontWeight="semibold" color="black">
                        {item.produto}
                      </Text>
                      <HStack
                        fontSize={{ base: '2xs', md: 'xs', xl: 'sm' }}
                        fontWeight="normal"
                        gap="4px"
                        color="white"
                      >
                        {item.cor && (
                          <Text
                            bg="teal.600"
                            padding="4px 12px"
                            borderRadius="full"
                          >
                            {item.cor}
                          </Text>
                        )}
                        {item.tamanho && (
                          <Text
                            bg="pink.700"
                            padding="4px 12px"
                            borderRadius="full"
                          >
                            {item.tamanho}
                          </Text>
                        )}
                      </HStack>
                      {item.informacoesComplementares && (
                        <Text
                          w="100%"
                          whiteSpace="pre-line"
                          fontSize={{ base: '2xs', md: 'xs', xl: 'sm' }}
                          display="block"
                        >
                          {item.informacoesComplementares}
                        </Text>
                      )}
                    </VStack>
                  </Td>
                  <Td w="10%" minW="120px">
                    <Text fontSize="sm" fontWeight="semibold" color="black">
                      {DecimalMask(
                        item.quantidade,
                        casasDecimais.casasDecimaisQuantidade,
                        casasDecimais.casasDecimaisQuantidade
                      )}
                    </Text>
                  </Td>
                  <Td w="10%" minW="120px" isNumeric>
                    <VStack spacing="1" lineHeight="none" alignItems="flex-end">
                      <TextValor
                        valor={item.valorUnitario}
                        casasDecimais={casasDecimais.casasDecimaisValor}
                        fontSize="sm"
                        fontWeight="semibold"
                        color="black"
                        justifyContent="flex-end"
                      />
                    </VStack>
                  </Td>
                  <Td w="10%" minW="120px" color="black" isNumeric>
                    <VStack spacing="1" lineHeight="none" alignItems="flex-end">
                      <TextValor
                        valor={item.valorDescontoItem}
                        casasDecimais={casasDecimais.casasDecimaisValor}
                        fontSize="sm"
                        fontWeight="semibold"
                        color="black"
                        justifyContent="flex-end"
                      />
                      {item.quantidade > 1 && item.valorDescontoItem && (
                        <Flex gap="4px">
                          <TextValor
                            valor={item.valorDescontoItem / item.quantidade}
                            casasDecimais={2}
                            textDecoration="line-through"
                            fontSize="xs"
                            fontWeight="semibold"
                            color="gray.300"
                            justifyContent="flex-end"
                          />
                          <Text
                            fontWeight="semibold"
                            fontSize="xs"
                            color="gray.300"
                          >
                            unid.
                          </Text>
                        </Flex>
                      )}
                    </VStack>
                  </Td>
                  <Td w="10%" minW="120px" color="blue.500" isNumeric>
                    <TextValor
                      valor={item.valorItemComDesconto}
                      casasDecimais={2}
                      fontSize="sm"
                      fontWeight="semibold"
                      color="black"
                      justifyContent="flex-end"
                    />
                  </Td>
                  {item.valorDescontoItem > 0 && (
                    <Td minW="80px" px="12px">
                      <Tag
                        size="sm"
                        bg="red.400"
                        color="white"
                        fontSize="2xs"
                        whiteSpace="nowrap"
                        borderRadius="full"
                      >
                        % DESC.
                      </Tag>
                    </Td>
                  )}
                </Tr>
              ))}

            {!isLoading && itens.length === 0 && (
              <Tr>
                <Td colSpan={5}>Não foi encontrado nenhum item.</Td>
              </Tr>
            )}
          </>
        }
        tfootContent={
          <Tr>
            <Th w="55%" whiteSpace="nowrap">
              <VStack alignItems="flex-start">
                <Text
                  lineHeight="none"
                  fontSize="xs"
                  color="black"
                  fontWeight="normal"
                >
                  Quantidade de produtos
                </Text>
                <Text fontSize="sm" fontWeight="extrabold" color="black">
                  {quantidadeProdutos < 10
                    ? `0${quantidadeProdutos}`
                    : quantidadeProdutos}
                </Text>
              </VStack>
            </Th>
            <Th w="10%" minW="120px">
              <VStack alignItems="flex-start">
                <Text
                  lineHeight="none"
                  fontSize="xs"
                  color="black"
                  fontWeight="normal"
                >
                  Quantidade
                </Text>
                <Text fontSize="sm" fontWeight="extrabold" color="black">
                  {quantidade < 10
                    ? `0${DecimalMask(
                        quantidade,
                        casasDecimais.casasDecimaisQuantidade,
                        casasDecimais.casasDecimaisQuantidade
                      )}`
                    : DecimalMask(
                        quantidade,
                        casasDecimais.casasDecimaisQuantidade,
                        casasDecimais.casasDecimaisQuantidade
                      )}
                </Text>
              </VStack>
            </Th>
            <Th w="10%" minW="120px" isNumeric>
              <VStack alignItems="flex-end">
                <Text
                  lineHeight="none"
                  fontSize="xs"
                  color="black"
                  fontWeight="normal"
                >
                  Valor unitário
                </Text>
                <TextValor
                  valor={subTotal}
                  casasDecimais={2}
                  fontSize="sm"
                  fontWeight="extrabold"
                  color="black"
                  symbolFontWeight="extrabold"
                  symbolFontSize={{ base: '2xs', xl: 'xs' }}
                />
              </VStack>
            </Th>
            <Th w="10%" minW="120px" isNumeric>
              <VStack alignItems="flex-end">
                <Text
                  lineHeight="none"
                  fontSize="xs"
                  color="black"
                  fontWeight="normal"
                >
                  Desconto
                </Text>
                <TextValor
                  valor={descontos}
                  casasDecimais={2}
                  fontSize="sm"
                  fontWeight="extrabold"
                  color="red.500"
                  symbolFontWeight="extrabold"
                  symbolFontSize={{ base: '2xs', xl: 'xs' }}
                />
              </VStack>
            </Th>
            <Th w="10%" minW="120px" isNumeric>
              <VStack alignItems="flex-end">
                <Text
                  lineHeight="none"
                  fontSize="xs"
                  color="black"
                  fontWeight="normal"
                >
                  Valor total
                </Text>
                <TextValor
                  valor={valorTotal}
                  casasDecimais={2}
                  fontSize="sm"
                  fontWeight="extrabold"
                  symbolFontWeight="extrabold"
                  symbolFontSize={{ base: '2xs', xl: 'xs' }}
                  color="blue.500"
                />
              </VStack>
            </Th>
          </Tr>
        }
      />
    </Card>
  );
};

export default DescricaoItens;
