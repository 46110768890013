import {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import StateManager, { MenuListComponentProps, ValueType } from 'react-select';
import { InputGroup, useMediaQuery } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';

import {
  useSelectProdutoContext,
  ProdutoOption,
  GetPaginatedProductsOptionsParams,
} from 'store/PDV/SelectProduto';

import OptionType from 'types/optionType';
import CampoContainer from 'components/PDV/Geral/CampoContainer';
import { MobileSelectWrapper } from 'components/update/Select/MobileSelect';

import SelectInput from '../SelectPadrao/Input';
import { defaultPaginationData } from './validationForm';
import { MenuListVirtualized } from './MenuListVirtualized';
import { chakraComponents } from '../ReactSelectIntegracao';

interface SelectProdutoProps {
  value?: ProdutoOption;
  label?: string;
  readonly?: boolean;
  autoFocus?: boolean;
  onClick?: () => void;
  mobileViewAlreadyWrapped?: boolean;
}

export type SelectProdutoRef = {
  focus: () => void;
  blur: () => void;
  openMenu: () => void;
};

type OptionProps = OptionType<string>;

let getPaginatedProductsTimeout: NodeJS.Timeout;

export default forwardRef<SelectProdutoRef, SelectProdutoProps>(
  (props, ref) => {
    const {
      label,
      readonly,
      onClick,
      value,
      autoFocus,
      mobileViewAlreadyWrapped = true,
    } = props;

    const {
      inputValue,
      setInputValue,
      onChange,
      getPaginatedProductsOptions,
      options,
      totalRegistros,
      currentPage,
      clearOptions,
    } = useSelectProdutoContext();

    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    const [, setCalledByEnter] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [disableMultipleEnter, setDisableMultipleEnter] = useState(false);

    const selectRef = useRef<StateManager<OptionType>>(null);
    const isLoadingRef = useRef(false);
    const listOptionsRef = useRef<OptionProps[]>([]);

    const missingOptionsLoad = options.length < totalRegistros;

    const loadOptions = useCallback(
      async ({
        dataPagination,
        ...params
      }: GetPaginatedProductsOptionsParams) => {
        setIsLoading(true);
        isLoadingRef.current = true;

        const promise: void = await new Promise(() => {
          clearTimeout(getPaginatedProductsTimeout);

          if (!params.forceEnter) {
            currentPage.current += 1;
          }

          getPaginatedProductsTimeout = global.setTimeout(
            async () => {
              await getPaginatedProductsOptions({
                ...params,
                dataPagination: {
                  ...dataPagination,
                  currentPage: currentPage.current,
                },
              });

              isLoadingRef.current = false;
              setIsLoading(false);
            },
            params.forceEnter ? 0 : 500
          );
        });

        return promise;
      },
      [currentPage, getPaginatedProductsOptions]
    );

    const handleLoadOptions = useCallback(
      async (search: string) => {
        await loadOptions({
          dataPagination: defaultPaginationData,
          forceEnter: false,
          allowSearchOnlyWithNumbers: true,
          allowSearchWithLengthSmallerThanThree: true,
          search,
        });
      },
      [loadOptions]
    );

    const onInputChange = (newInputValue: string) => {
      setInputValue(newInputValue);

      handleLoadOptions(newInputValue);
      clearOptions();
    };

    const baseComponents = useMemo(() => {
      return isLargerThan900
        ? chakraComponents
        : { ...chakraComponents, DropdownIndicator: () => null };
    }, [isLargerThan900]);

    const customComponents: ReactNode = useMemo(() => {
      return {
        ...baseComponents,
        MenuList: (menuProps: MenuListComponentProps<OptionType>) =>
          MenuListVirtualized({
            search: inputValue,
            isLoading: isLoadingRef.current,
            listOptions: listOptionsRef.current,
            loadOptions: handleLoadOptions,
            hasMore: listOptionsRef.current.length < totalRegistros,
            ...menuProps,
          }),
      };
    }, [baseComponents, inputValue, totalRegistros, handleLoadOptions]);

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (selectRef.current?.focus()) {
          selectRef.current.focus();
        }
      },
      blur: () => {
        if (selectRef.current?.blur()) {
          selectRef.current.blur();
        }
      },
      openMenu: () => {
        if (selectRef.current?.onMenuOpen()) {
          selectRef.current?.onMenuOpen();
        }
      },
    }));

    useEffect(() => {
      listOptionsRef.current = options;
    }, [options]);

    return (
      <>
        <InputGroup>
          <CampoContainer
            name="produtoCor"
            id="produtoCor"
            label={label}
            sx={
              isLargerThan900
                ? {
                    '& .react-select__placeholder': {
                      isTruncated: true,
                      display: 'block',
                      height: 'auto',
                      w: 'full',
                      maxW: 'calc(100% - 20px)',
                    },
                  }
                : {}
            }
          >
            <MobileSelectWrapper
              asMobileView={isMobile && !mobileViewAlreadyWrapped}
              autoFocus={autoFocus}
            >
              {({ onClose }) => (
                <SelectInput
                  options={options}
                  placeholder={
                    isLargerThan900
                      ? 'Digite o nome do produto ou use um leitor de código de barras'
                      : 'Digite o nome do produto'
                  }
                  size="lg"
                  selectRef={selectRef}
                  inputValue={inputValue}
                  onInputChange={onInputChange}
                  openMenuOnClick={false}
                  autoFocus={autoFocus}
                  isDisabled={readonly}
                  value={value ? undefined : null}
                  filterOption={() => true}
                  isLoading={isLoading && missingOptionsLoad}
                  onChange={(option: ValueType<OptionType<string>>) => {
                    setCalledByEnter((prev) => {
                      if (!prev) {
                        onChange(option as OptionType);
                      } else if (prev) {
                        onChange(option as OptionType);
                      }
                      return prev;
                    });

                    if (onClick) {
                      onClick();
                    }

                    setCalledByEnter(true);
                    onClose();
                  }}
                  isOptionSelected={(option: OptionType) =>
                    option.value === value
                  }
                  components={
                    options.length > 0 ? customComponents : baseComponents
                  }
                  onKeyDown={async (e) => {
                    if (disableMultipleEnter) {
                      e.preventDefault();
                      return;
                    }

                    setCalledByEnter(true);

                    if (e.key === 'Enter') {
                      setDisableMultipleEnter(true);
                      setCalledByEnter(false);

                      loadOptions({
                        search: inputValue,
                        dataPagination: defaultPaginationData,
                        forceEnter: true,
                      });

                      setDisableMultipleEnter(false);
                    }
                  }}
                  styles={{
                    valueContainer: (base: any) => ({
                      ...base,
                      fontSize: '0.95rem',
                    }),
                  }}
                />
              )}
            </MobileSelectWrapper>
          </CampoContainer>
        </InputGroup>
      </>
    );
  }
);
