import { useCallback } from 'react';

import { useCategoriaProdutoContext } from 'store/SmartPos/CategoriaProdutos';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { handleGetCategoriasPeloPaiId } from 'components/CategoriaProdutos/Functions/getCategoriasPeloPaiId';

import { ListItemProduto } from '../ListItemProduto';

type CategoriaProdutoItemProps = {
  categoriaProdutoId?: string;
  nivel: number;
};

export const CategoriaComponenteItem = ({
  categoriaProdutoId,
  nivel,
}: CategoriaProdutoItemProps) => {
  const {
    categorias,
    categoriasIdSelecionaveis,
    isLoading,
  } = useCategoriaProdutoContext();

  const listCategorias = useCallback(() => {
    return handleGetCategoriasPeloPaiId(categorias, categoriaProdutoId);
  }, [categoriaProdutoId, categorias])();

  return (
    <>
      {isLoading && <LoadingPadrao />}
      {listCategorias.map((categoria) => (
        <ListItemProduto
          key={categoria.id}
          nivel={nivel}
          itemCategoriaProduto={categoria}
          selecionavel={categoriasIdSelecionaveis.some(
            (categoriaIdSelecionavel) =>
              categoriaIdSelecionavel === categoria.id
          )}
        >
          <CategoriaComponenteItem
            categoriaProdutoId={categoria.id}
            nivel={nivel + 1}
          />
        </ListItemProduto>
      ))}
    </>
  );
};
