import { Flex, GridItem, Box, FormLabel } from '@chakra-ui/react';

import { optionsFormaEmissaoNfe } from 'constants/enum/enumFormaEmissaoNfe';
import { optionValidadorPlEvento } from 'constants/enum/enumValidadorXmlPlEvento';
import { optionValidadorPlEpec } from 'constants/enum/enumValidadorPlEpec';
import { optionsNfePlNotaFiscal } from 'constants/enum/enumValidadorXmlPlNfe';

import Input from 'components/PDV/Input';
import { Switch } from 'components/update/Switch';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';

export const OpcoesFiscais = () => {
  return (
    <SimpleGridForm w="full">
      <GridItem colSpan={[12, 12, 6]}>
        <Flex>
          <Box mr="10px">
            <Switch size="md" id="satImpressaoLocal" name="satImpressaoLocal" />
          </Box>
          <Flex>
            <FormLabel>Impressão local do SAT</FormLabel>
          </Flex>
        </Flex>
      </GridItem>
      <GridItem colSpan={[12, 12, 6]}>
        <Flex>
          <Box mr="10px">
            <Switch
              size="md"
              id="emitirEmContingencia"
              name="emitirEmContingencia"
            />
          </Box>
          <Flex>
            <FormLabel>Emitir NFC-e em contingência offline</FormLabel>
          </Flex>
        </Flex>
      </GridItem>
      <GridItem colSpan={[12, 12, 6]}>
        <SelectPadrao
          id="versaoDadosSAT"
          name="versaoDadosSAT"
          label="Versão do layout do arquivo SAT"
          placeholder="Selecione"
          options={[
            {
              label: '0.07',
              value: '0.07',
            },
            {
              label: '0.08',
              value: '0.08',
            },
          ]}
        />
      </GridItem>
      <GridItem colSpan={[12, 12, 6]}>
        <Input
          label="Número de série do certificado digital"
          id="serieCertificado"
          name="serieCertificado"
          placeholder="Informe o número de série "
        />
      </GridItem>
      <GridItem colSpan={[12, 12, 6]}>
        <SelectPadrao
          name="nFeFormaEmissao"
          options={optionsFormaEmissaoNfe}
          id="nFeFormaEmissao"
          placeholder="Selecione"
          label="Forma de emissão da NF-e"
        />
      </GridItem>
      <GridItem colSpan={[12, 12, 6]}>
        <SelectPadrao
          id="nfePLEvento"
          name="nfePLEvento"
          label="Validador do XML (PL evento)"
          placeholder="Selecione"
          options={optionValidadorPlEvento}
        />
      </GridItem>
      <GridItem colSpan={[12, 12, 6]}>
        <SelectPadrao
          id="nfePLEPEC"
          name="nfePLEPEC"
          label="Validador do XML (PL EPEC)"
          placeholder="Selecione"
          options={optionValidadorPlEpec}
        />
      </GridItem>
      <GridItem colSpan={[12, 12, 6]}>
        <SelectPadrao
          options={optionsNfePlNotaFiscal}
          name="nfePLNotaFiscal"
          id="nfePLNotaFiscal"
          placeholder="Selecione"
          label="Validador do XML (PL NF-e)"
        />
      </GridItem>
      <GridItem colSpan={12}>
        <Input
          name="assinaturaSatSTi3"
          id="assinaturaSatSTi3"
          placeholder="Informe a assinatura"
          label="Assinatura SAT"
        />
      </GridItem>
    </SimpleGridForm>
  );
};
