import React from 'react';
import { Td, Tr, Th, Text, VStack } from '@chakra-ui/react';

import { TextValor } from 'components/PDV/Text/TextValor';

import Card from '../Card';
import Table from '../Table';

interface DescontosProps {
  descontoItens: number;
  descontoAdicional: number;
  totalDescontos: number;
}

const Descontos = ({
  descontoItens,
  descontoAdicional,
  totalDescontos,
}: DescontosProps) => {
  return (
    <Card title="Descontos" padding="24px">
      <Table
        tbodyContent={
          <>
            <Tr>
              <Td>
                <Text
                  fontWeight="semibold"
                  color="black"
                  fontSize="sm"
                  py="0px"
                  pl="8px"
                >
                  Desconto nos itens
                </Text>
              </Td>
              <Td isNumeric>
                <TextValor
                  valor={descontoItens}
                  casasDecimais={2}
                  symbolFontSize="2xs"
                  fontSize="sm"
                  fontWeight="semibold"
                  color="black"
                  justifyContent="flex-end"
                />
              </Td>
            </Tr>
            <Tr>
              <Td>
                <Text
                  fontWeight="semibold"
                  color="black"
                  fontSize="sm"
                  py="0px"
                  pl="8px"
                >
                  Desconto adicional
                </Text>
              </Td>
              <Td isNumeric>
                <TextValor
                  valor={descontoAdicional}
                  casasDecimais={2}
                  symbolFontSize="2xs"
                  fontSize="sm"
                  fontWeight="semibold"
                  color="black"
                  justifyContent="flex-end"
                />
              </Td>
            </Tr>
          </>
        }
        tfootContent={
          <Tr>
            <Th py="5" isNumeric colSpan={2}>
              <VStack alignItems="flex-end">
                <Text
                  lineHeight="none"
                  fontSize="xs"
                  color="black"
                  fontWeight="semibold"
                  letterSpacing="0px"
                >
                  Descontos
                </Text>
                <TextValor
                  valor={totalDescontos}
                  casasDecimais={2}
                  symbolFontWeight="bold"
                  symbolFontSize="2xs"
                  fontSize="sm"
                  color="red.500"
                />
              </VStack>
            </Th>
          </Tr>
        }
      />
    </Card>
  );
};

export default Descontos;
