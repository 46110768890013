import * as yup from 'yup';
import { yupResolver as instanceResolver } from '@hookform/resolvers/yup';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export type FormData = {
  vale: string;
};

const schema = yup.object().shape({
  vale: yup
    .string()
    .length(10, ConstanteMensagemValidacao.IDENTIFICADOR_VALE)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = instanceResolver(schema);
