import React from 'react';

import TipoImportacaoEnum from 'constants/enum/tipoImportacao';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

import ImportarClientesListar from '../pages/Importacao/Listar';
import ImportarClientesImportar from '../pages/Importacao/Importar';

const ImportarClientesRoutes = [
  <LayoutGuard
    key="importarclientes-listar"
    permissaoFuncionalidade={ConstanteFuncionalidades.IMPORTACAO_CLIENTE_LISTAR}
    component={ImportarClientesListar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Importar Clientes',
      },
    ]}
    path={ConstanteRotas.IMPORTAR_CLIENTES}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="importarclientes-importar"
    permissaoFuncionalidade={
      ConstanteFuncionalidades.IMPORTACAO_CLIENTE_IMPORTAR
    }
    component={() => (
      <ImportarClientesImportar tipoImportacao={TipoImportacaoEnum.Clientes} />
    )}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Importar Clientes',
        path: ConstanteRotas.IMPORTAR_CLIENTES,
      },
      {
        title: 'Novo',
      },
    ]}
    path={ConstanteRotas.IMPORTAR_CLIENTES_IMPORTAR}
    exact
    meta={{ auth: true }}
  />,
];

export default ImportarClientesRoutes;
