import { Flex, Text } from '@chakra-ui/react';
import React from 'react';

import BarcodeComponent from 'components/CodigoBarras';

type PromissoryDetailsProps = {
  valorPorExtenso: string;
  cidadeLoja: string;
  numeroOperacao: number;
};

export const PromissoryDetails = ({
  valorPorExtenso,
  cidadeLoja,
  numeroOperacao,
}: PromissoryDetailsProps) => (
  <Flex flexDir="column">
    <Flex border="1px solid #bbb" fontWeight="normal" px="4px">
      <Text borderRight="1px solid #bbb" w="70%" fontSize="10px">
        {valorPorExtenso}
      </Text>
      <Flex align="center" justify="center" w="30%">
        <BarcodeComponent
          value={numeroOperacao?.toString()}
          options={{
            background: 'transparent',
            height: 36,
            font: 'Nunito, sans-serif',
            width: 1.8,
            lineColor: '#151515',
            margin: 4,
            fontSize: 12,
            flat: true,
          }}
        />
      </Flex>
    </Flex>
    <Flex
      border="1px solid #bbb"
      fontWeight="extrabold"
      px="4px"
      bg="gray.100"
      mt="4px"
      align="center"
    >
      <Text w="80%" borderRight="1px solid #bbb" fontSize="10px">
        EM MOEDA CORRENTE DO PAÍS. NESTA PRAÇA DE {cidadeLoja?.toUpperCase()}
      </Text>
      <Text textAlign="end" w="20%" fontSize="10px">
        {numeroOperacao}
      </Text>
    </Flex>
  </Flex>
);
