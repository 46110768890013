import styled from 'styled-components';
import { Modal as BootstrapModal } from 'react-bootstrap';
import ButtonPadrao from 'components/Button/ButtonPadrao';

export const ButtonPadraoFechar = styled(ButtonPadrao)`
  width: 80px;
  margin-right: 10px;
  @media (max-width: 700px) {
    width: 100% !important;
    max-width: 100% !important;
    margin-right: 0px;
  }
`;

export const Modal = styled(BootstrapModal)`
  .modal-dialog {
    max-width: 660px !important;

    .modal-header {
      margin: 0 22px;
      padding: 16px 0;
    }

    .modal-body {
      max-height: calc(100vh - (1.75rem * 2) - 160px) !important;
      max-width: 100vh;

      padding: 5px 8px 8px 5px !important;

      overflow-y: none;

      ::-webkit-scrollbar-track {
        background: var(--sti-ck-colors-gray-100);
        border-radius: 5px;
      }

      ::-webkit-scrollbar-thumb {
        background: var(--white);
        border: 1px solid var(--sti-ck-colors-gray-100);
        border-radius: 5px;
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
      }

      ::-webkit-scrollbar-thumb:hover {
        background: var(--sti-ck-colors-gray-100);
      }
    }

    .modal-footer {
      margin: 0 22px;
      padding: 16px 0;
    }
  }
`;
