import React, { memo, useCallback } from 'react';
import { Controller, FieldError } from 'react-hook-form';

import ContainerInputPadrao from 'components/Layout/CampoContainer/CampoContainerPadrao';
import { InputControl } from '../styles';

export interface InputNumericoProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  name: string;
  label: string;
  error?: FieldError | null;
  infoText?: string;
  control: any;
  defaultValue?: number;
  convertValueToString?: boolean;
}

const InputNumerico = ({
  id,
  name,
  label,
  error,
  required,
  infoText,
  control,
  defaultValue,
  convertValueToString,
  ...rest
}: InputNumericoProps) => {
  const setValue = useCallback(
    (e: React.FormEvent<HTMLInputElement>, onChange: (event: any) => void) => {
      const valueReplace = e.currentTarget.value.replace(/\D/g, '');

      if (valueReplace.length > 0) {
        onChange(convertValueToString ? valueReplace : Number(valueReplace));
      } else {
        onChange(valueReplace);
      }
    },
    [convertValueToString]
  );

  return (
    <ContainerInputPadrao
      id={id}
      label={label}
      error={error}
      required={required}
      infoText={infoText}
    >
      <Controller
        defaultValue={defaultValue}
        render={({ field }) => (
          <InputControl
            inputMode="text"
            id={id}
            ref={field.ref}
            value={field.value}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              setValue(e, field.onChange);
            }}
            {...rest}
          />
        )}
        name={`${name}` as const}
        control={control}
      />
    </ContainerInputPadrao>
  );
};

export default memo(InputNumerico);
