import React, { CSSProperties } from 'react';

import { Box, Flex, FormLabel } from '@chakra-ui/react';

import SelectPadrao from 'components/PDV/Select/SelectPadrao';

export const styleSelects = {
  placeholder: (base: CSSProperties) => ({
    ...base,
    fontSize: '14px',
    color: 'black',
  }),
  input: (base: CSSProperties) => ({
    ...base,
    fontSize: '14px',
  }),
  container: (base: CSSProperties) => ({
    ...base,
    fontSize: '14px',
    borderColor: '#CCCCCC',
  }),
  option: (base: CSSProperties) => ({
    ...base,
    fontSize: '14px',
  }),
  valueContainer: (base: CSSProperties) => ({
    ...base,
    fontSize: '14px',
  }),
};

export const LabelSelect = ({
  name,
  label,
  options,
  selectWidth = '122px',
  showBorder = true,
}: {
  name: string;
  label: string;
  options: {
    label: string;
    value: any;
  }[];
  selectWidth?: string;
  showBorder?: boolean;
}) => {
  return (
    <Flex
      pb="16px"
      height="fit-content"
      borderBottom={showBorder ? '1px solid #CCCCCC' : 'none'}
      flexWrap="wrap"
      w="full"
      justifyContent="space-between"
      align="baseline"
    >
      <Flex>
        <FormLabel letterSpacing="0px" mr="2px" margin="0px 2px 0px 0px">
          {label}
        </FormLabel>
      </Flex>
      <Box minW={selectWidth}>
        <SelectPadrao
          styles={styleSelects}
          menuPosition="fixed"
          options={options}
          name={name}
          id={name}
          isSearchable={false}
          placeholder="Selecione"
        />
      </Box>
    </Flex>
  );
};
