import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { FormData, yupResolver } from '../validationForm';
import { useIntermediadorForm } from '../hooks';
import UncontrolledForm from '..';

const IntermediadorAlterar = () => {
  const {
    obterIntermediador,
    atualizarIntermediador,
    redirecionarIntermediadorListar,
  } = useIntermediadorForm();

  const { id } = useParams<{ id: string }>();

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
  });

  const { handleSubmit, reset } = formMethods;

  const [isLoading, setIsLoading] = useState(false);

  const handleSalvar = async (data: FormData) => {
    setIsLoading(true);

    const { success } = await atualizarIntermediador(id, data);

    if (success) {
      toast.success('Intermediador alterado com sucesso');
      redirecionarIntermediadorListar();
    }

    setIsLoading(false);
  };

  const resetarForm = useCallback(async () => {
    setIsLoading(true);

    const intermediador = await obterIntermediador(id);

    if (intermediador) {
      reset(intermediador);
    }

    setIsLoading(false);
  }, [obterIntermediador, id, reset]);

  useEffect(() => {
    resetarForm();
  }, [resetarForm]);

  return (
    <FormProvider {...formMethods}>
      <UncontrolledForm
        isLoading={isLoading}
        handleSalvar={handleSubmit(handleSalvar)}
      />
    </FormProvider>
  );
};

export { IntermediadorAlterar };
