const ModalidadesFreteEnum = {
  FretePorContaRemetente: 0,
  FretePorContaDestinatario: 1,
  FretePorContaTerceiros: 2,
  TransporteProprioContaDoRemetente: 3,
  TransporteProprioContaDoDestinatario: 4,
  SemFrete: 9,

  properties: {
    1: {
      name: 'Contratação do frete por conta do destinatário (FOB)',
      value: 1,
    },
    2: { name: 'Contratação do frete por conta do remetente (CIF)', value: 0 },
    3: { name: 'Contratação do frete por conta de terceiros', value: 2 },
    4: { name: 'Sem ocorrência de transporte', value: 9 },
    5: { name: 'Transporte próprio por conta do destinatário', value: 4 },
    6: { name: 'Transporte próprio por conta do remetente', value: 3 },
  },

  options: [
    {
      label: 'Contratação do frete por conta do destinatário (FOB)',
      value: 1,
    },
    { label: 'Contratação do frete por conta do remetente (CIF)', value: 0 },
    { label: 'Contratação do frete por conta de terceiros', value: 2 },
    { label: 'Sem ocorrência de transporte', value: 9 },
    { label: 'Transporte próprio por conta do destinatário', value: 4 },
    { label: 'Transporte próprio por conta do remetente', value: 3 },
  ],
};

export default ModalidadesFreteEnum;
