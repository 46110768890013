import styled, { css } from 'styled-components';
import { Button as BootstrapButton } from 'react-bootstrap';

interface ButtonProps {
  bloqueado?: 0 | 1;
}

export const Button = styled(BootstrapButton)<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 0.9375rem;

  width: 100%;
  max-width: 175px;

  & + button {
    margin-left: 20px;
  }

  ${({ bloqueado }) =>
    bloqueado &&
    css`
      box-shadow: none !important;
      cursor: default !important;
      border-color: var(--sti-ck-colors-gray-700) !important;
    `}
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 0.9375rem;
  line-height: 1;

  height: 100%;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1.125rem;

  + div {
    margin-left: 7px;
  }
`;
