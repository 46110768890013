import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

const schema = yup.object().shape(
  {
    formaPagto: yup
      .object()
      .nullable()
      .default(null)
      .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
    cnpjPagamento: yup.string().when('ufPagamento', {
      is: (ufPagamento: string) =>
        ufPagamento === '' || ufPagamento === null || ufPagamento === undefined,
      then: yup.string().nullable().notRequired(),
      otherwise: yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
    }),
    ufPagamento: yup.string().when('cnpjPagamento', {
      is: (cnpjPagamento: string) =>
        cnpjPagamento === '' ||
        cnpjPagamento === null ||
        cnpjPagamento === undefined,
      then: yup.string().nullable().notRequired(),
      otherwise: yup
        .string()
        .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
    }),
  },
  [['cnpjPagamento', 'ufPagamento']]
);

export const yupResolver = yupResolverInstance(schema);

export const useForm = useFormInstance;
