import { Flex, Text } from '@chakra-ui/react';

import { moneyMask } from 'helpers/format/fieldsMasks';
import formatData, {
  formatCurrentDateToWords,
  formatDateToWords,
} from 'helpers/format/formatData';
import { formatNumberToWords } from 'helpers/format/numberToWords';

import { StoreHeader } from './StoreHeader';
import { Client } from './Client';
import { OperationNumber } from './OperationNumber';
import { OperationDetails } from './OperationDetails';
import { StoreDetails } from './StoreDetails';
import { PaymentDetails } from './PaymentDetails';
import { PromissoryDetails } from './PromissoryDetails';
import { SignatureInfo } from './Signature';

type PromissoriaProps = {
  clienteEndereco: string;
  clienteNome: string;
  clienteCodigo: number;
  cnpjLoja: string;
  dataEmissao: Date;
  enderecoLoja: string;
  nomeFantasia: string;
  numeroOperacao: number;
  dataVencimento: Date;
  parcela: string;
  valorParcela: number;
  cidadeLoja: string;
  inscricaoEstadualLoja?: string;
  telefoneLoja?: string;
  clienteCpf?: string;
};

export const Promissoria = ({
  clienteEndereco,
  clienteNome,
  cnpjLoja,
  dataEmissao,
  enderecoLoja,
  nomeFantasia,
  numeroOperacao,
  dataVencimento,
  parcela,
  valorParcela,
  inscricaoEstadualLoja,
  clienteCodigo,
  cidadeLoja,
  clienteCpf,
  telefoneLoja,
}: PromissoriaProps) => {
  const valorPorExtenso = formatNumberToWords(valorParcela);
  const dataPagamentoPorExtenso = formatDateToWords(dataVencimento.toString());
  const currentDateInWords = formatCurrentDateToWords();

  return (
    <Flex padding="16px" pt="32px" minW="full" justify="flex-start" gap="4px">
      <Flex pb="4px" flexDir="column" border="1px solid black" w="38%">
        <StoreHeader
          nomeFantasia={nomeFantasia}
          enderecoLoja={enderecoLoja}
          telefone={telefoneLoja}
        />
        <Text
          width="calc(100% - 8px)"
          fontWeight="extrabold"
          bg="gray.100"
          textAlign="center"
          mx="4px"
          mt="2px"
          fontSize="10px"
        >
          CLIENTE
        </Text>
        <Flex flexDir="column" fontWeight="extrabold" px="4px">
          <Client clienteNome={clienteNome} codigoCliente={clienteCodigo} />
          <OperationNumber numeroOperacao={numeroOperacao} />
          <Flex w="full">
            <OperationDetails
              label="DATA DA COMPRA"
              value={formatData(dataEmissao)}
            />
            <OperationDetails
              label="VENCIMENTO"
              value={formatData(dataVencimento)}
            />
          </Flex>
          <Flex w="full">
            <OperationDetails
              label="VALOR DA PRESTAÇÃO"
              value={moneyMask(valorParcela, true)}
            />
            <OperationDetails label="PARCELA" value={parcela} />
          </Flex>
        </Flex>
      </Flex>
      <Flex
        border="1px solid black"
        bg="white"
        flexDir="column"
        padding="6px"
        gap="4px"
        w="62%"
        minW="62%"
      >
        <StoreDetails
          nomeFantasia={nomeFantasia}
          enderecoLoja={enderecoLoja}
          telefone={telefoneLoja}
          cnpjLoja={cnpjLoja}
          inscricaoEstadual={inscricaoEstadualLoja}
        />
        <PaymentDetails
          codigoCliente={clienteCodigo}
          dataVencimento={formatData(dataVencimento)}
          valorParcela={moneyMask(valorParcela, true)}
          dataPagamentoPorExtenso={dataPagamentoPorExtenso}
        />
        <PromissoryDetails
          valorPorExtenso={valorPorExtenso}
          cidadeLoja={cidadeLoja}
          numeroOperacao={numeroOperacao}
        />
        <SignatureInfo
          clienteNome={clienteNome}
          clienteCpf={clienteCpf}
          clienteEndereco={clienteEndereco}
          cidadeLoja={cidadeLoja}
          currentDateInWords={currentDateInWords}
        />
      </Flex>
    </Flex>
  );
};
