import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Form } from 'react-bootstrap';
import { Prompt, useHistory, RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import isPrenvent from 'helpers/layout/isPrenvent';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import obterConfigEmitirNFe from 'helpers/api/Loja/obterConfigEmitirNFe';

import RodapeFormulario from 'components/Geral/RodapeFormulario';
import ManterFoco from 'components/Geral/ManterFoco';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { SalvarConfirmarIcon } from 'icons';

import StatusFiscaisEnum from 'constants/enum/fiscal/statusFiscal';
import getDateTimeDefaultInput from 'helpers/data/getDateTimeDefaultInput';
import { FormProvider } from 'react-hook-form';
import { removeMaskCNPJ } from 'helpers/format/removeMask';
import NotaFiscalFormularioProvider from 'store/NotaFiscal/NotaFiscalFormulario';
import auth from 'modules/auth';
import PlanoContratacaoEnum from 'constants/enum/planoContratacao';
import { useSignalRContext } from 'store/SignalR';
import { ModalAtencao } from 'components/Modal/ModalAtencao';
import ConstanteRotas from 'constants/rotas';
import ModalTransmissaoNota from 'pages/NotasFiscais/ModalTransmissaoNota';
import OperacaoIntermediadorEnum from 'constants/enum/fiscal/operacaoIntermediador';
import { ModalRejeicaoNotaFiscal } from 'components/Modal/ModalRejeicaoNotaFiscal';
import { RejeicaoInterface } from 'components/EmitirNotaFiscal/BoxRejeicao';
import ModalidadesFreteEnum from 'constants/enum/fiscal/modalidadeFrete';
import { InformacoesOperacaoProps } from 'pages/MovimentacaoEstoque/Listar';
import TipoCertificadoEnum from 'constants/enum/fiscal/tipoCertificado';
import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';
import FinalidadesNotaFiscalEnum from 'constants/enum/fiscal/finalidadeNotaFiscal';
import ModelosFiscaisEnum from 'constants/enum/fiscal/modelosFiscais';
import { useForm, yupResolver } from '../validationForm';
import { Container } from '../styles';
import {
  UncontrolledForm,
  validarPagamentoIgualValorTotal,
  validarDocumentosReferenciadosNotaDevolucao,
} from '..';

interface SParams {
  dadosOperacaoEmitirNotaFiscal: InformacoesOperacaoProps;
}

interface ObterInformacoesFiscais {
  nfCeNumeroSerie: number;
  nFeNumeroSerie: number;
}

export default function Cadastro({
  location: { state },
}: RouteComponentProps<any, any, SParams>) {
  const history = useHistory();
  const [showTransmitindoNota, setShowTransmitindoNota] = useState(false);
  const [labelStatusTransmisssao, setLabelStatusTransmisssao] = useState('');
  const { hubConnection, joinGroup } = useSignalRContext();
  const refTabInicial = useRef() as React.MutableRefObject<HTMLAnchorElement>;
  const idDocumentoFiscalCadastrado = useRef<string | null>(null);
  const isSalvarEAlterar = useRef<boolean>(false);

  const [isLoading, setIsLoading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);

  isPrenvent(formIsDirty);

  const planoAtual = auth.getPlano();
  const isPlanoTeste = PlanoContratacaoEnum.TESTE;

  const formMethods = useForm({
    resolver: yupResolver,
    defaultValues: {
      status: 'Em Digitação',
      dataEmissao: getDateTimeDefaultInput(new Date()),
      dataSaida: getDateTimeDefaultInput(new Date()),
      transportadoraId: null,
      documentoFiscalReferenciados: [],
      operacaoComIntermediador: OperacaoIntermediadorEnum.SemIntermediador,
      icmsValor: 0,
      cofinsValorTotal: 0,
      fcpUfDestinoValorTotal: 0,
      icmsBaseCalculo: 0,
      icmsDesoneradoValorTotal: 0,
      icmsFcpDestinoValorTotal: 0,
      icmsFcpRemetenteValorTotal: 0,
      icmsFcpValorTotal: 0,
      icmsStBaseCalculo: 0,
      icmsStFcpRetidoValorTotal: 0,
      icmsStFcpValorTotal: 0,
      icmsStValor: 0,
      ipiDevolucaoValorTotal: 0,
      pisValorTotal: 0,
      valorDesconto: 0,
      valorDespesas: 0,
      valorFrete: 0,
      valorImpostoImportacao: 0,
      valorIpi: 0,
      valorProdutos: 0,
      valorSeguro: 0,
      valorTotal: 0,
      tipoOperacao: 1,
      tipoOperacaoPresencaComprador: 1,
      valorTotalTributos: 0,
    } as Record<string, any>,
    shouldUnregister: false,
  });

  const [tipoOperacaoWatch, presencaCompradorWatch] = formMethods.watch([
    'tipoOperacao',
    'tipoOperacaoPresencaComprador',
  ]);

  useEffect(() => {
    async function preencherCamposNotaFiscal() {
      let nFeNumeroSerie: number | null = null;

      const response = await api.get<
        void,
        ResponseApi<ObterInformacoesFiscais>
      >(ConstanteEnderecoWebservice.LOJA_OBTER_INFORMACOES_FISCAIS);

      if (response) {
        if (response.sucesso) {
          nFeNumeroSerie = response.dados.nFeNumeroSerie;
        }
      }

      formMethods.reset({
        ...state.dadosOperacaoEmitirNotaFiscal,
        serie: nFeNumeroSerie,
        dataEmissao: getDateTimeDefaultInput(new Date()),
        documentoFiscalReferenciados: [],
        documentoFiscalImportacoes: [],
        operacaoComIntermediador: OperacaoIntermediadorEnum.SemIntermediador,
        modalidadeFrete: ModalidadesFreteEnum.SemFrete,
        transportadoraId: null,
        valorFrete: 0,
        finalidade:
          state.dadosOperacaoEmitirNotaFiscal.identificacaoTipoOperacao ===
          IdentificacaoTipoOperacaoEnum.DEVOLUCAO_COMPRA
            ? FinalidadesNotaFiscalEnum.DevolucaoMercadoria
            : FinalidadesNotaFiscalEnum.NFeNormal,
      });
    }

    if (state && state.dadosOperacaoEmitirNotaFiscal) {
      preencherCamposNotaFiscal();
    }
  }, [state, formMethods]);

  useEffect(() => {
    history.replace({ ...history.location, state: {} });
  }, [history]);

  const latestProps = useRef({ historyPush: history.push });
  useEffect(() => {
    latestProps.current = { historyPush: history.push };
  });

  useEffect(() => {
    async function checarConfigEmitirNFe() {
      const temConfigEmitirNFe = await obterConfigEmitirNFe();

      if (!temConfigEmitirNFe) {
        ModalAtencao({
          text:
            'Para gerar nota fiscal é necessário habilitar a opção Emitir NFe no cadastro da loja.',
          width: 800,
        });

        setFormIsDirty(false);
        latestProps.current.historyPush(ConstanteRotas.NOTA_FISCAL);
      }
    }

    checarConfigEmitirNFe();
  }, []);

  const tratarAviso = (aviso: string, statusNota?: number) => {
    try {
      const rejeicao = JSON.parse(aviso) as RejeicaoInterface;

      ModalRejeicaoNotaFiscal({
        ...rejeicao,
        callback: () => {
          if (statusNota === StatusFiscaisEnum.RetornoIndisponivel) {
            setFormIsDirty(false);
            history.push(ConstanteRotas.NOTA_FISCAL);
          }
        },
      });
    } catch {
      ModalAtencao({
        text: aviso,
        width: 800,
        callback: () => {
          if (statusNota === StatusFiscaisEnum.RetornoIndisponivel) {
            setFormIsDirty(false);
            history.push(ConstanteRotas.NOTA_FISCAL);
          }
        },
      });
    }
  };

  const obterStatusNotaFiscal = useCallback(async (id: string) => {
    const response = await api.get<void, ResponseApi<number>>(
      ConstanteEnderecoWebservice.NOTA_FISCAL_OBTER_STATUS,
      {
        params: { id },
      }
    );
    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }
      if (response?.sucesso) {
        return response?.dados;
      }
    }
    return undefined;
  }, []);

  const transmitirHandle = async (documentoFiscalId: string) => {
    setFormIsDirty(false);
    setLabelStatusTransmisssao('Enviando a nota fiscal para a SEFAZ...');
    setShowTransmitindoNota(true);

    joinGroup(`${documentoFiscalId}_transmitindo-nota`);

    hubConnection.on('alterar-status-transmissao', (message: string) => {
      setLabelStatusTransmisssao(message);
    });

    const retorno = await api.post<void, ResponseApi<number | undefined>>(
      `${ConstanteEnderecoWebservice.NOTA_FISCAL_TRANSMITIR}/${documentoFiscalId}`
    );

    if (retorno.sucesso) {
      if (retorno.dados && retorno.dados === Number(TipoCertificadoEnum.A3)) {
        const timeout = window.setTimeout(
          () => {
            setShowTransmitindoNota(false);
            ModalAtencao({
              title: 'A comunicação com o certificado digital A3 falhou',
              text:
                'Verifique se o certificado digital está conectado no computador e se o aplicativo Módulo desktop está sendo executado.',
            });

            setFormIsDirty(false);
            history.push(ConstanteRotas.NOTA_FISCAL);
          },
          1000 * 60 // 1 minuto
        );

        hubConnection.off('sucesso-transmissao-a3');
        hubConnection.on('sucesso-transmissao-a3', () => {
          window.clearTimeout(timeout);

          setShowTransmitindoNota(false);
          toast.success('A nota fiscal foi transmitida com sucesso.');
          history.push(ConstanteRotas.NOTA_FISCAL);
        });

        hubConnection.off('rejeicao-transmissao-a3');
        hubConnection.on('rejeicao-transmissao-a3', async (message: string) => {
          window.clearTimeout(timeout);

          setShowTransmitindoNota(false);
          setFormIsDirty(false);
          const statusNota = await obterStatusNotaFiscal(documentoFiscalId);
          tratarAviso(message, statusNota);

          history.push(ConstanteRotas.NOTA_FISCAL);
        });

        return;
      }

      toast.success('A nota fiscal foi transmitida com sucesso.');
      history.push(ConstanteRotas.NOTA_FISCAL);
      return;
    }

    setShowTransmitindoNota(false);

    if (!retorno.sucesso && !retorno.avisos) {
      history.push(ConstanteRotas.NOTA_FISCAL);
      return;
    }

    if (retorno.avisos) {
      setFormIsDirty(false);
      const statusNota = await obterStatusNotaFiscal(documentoFiscalId);
      tratarAviso(retorno.avisos[0], statusNota);
    }
  };

  async function handlePostNotaFiscal(salvarTransmitir: boolean) {
    const {
      identificacaoNoIntermediador,
      cnpjIntermediador,
      ...data
    } = formMethods.getValues();
    const valorTotalParcela = formMethods.watch('valorTotal', 0);

    if (!validarPagamentoIgualValorTotal(data, valorTotalParcela)) return;

    if (!validarDocumentosReferenciadosNotaDevolucao(data)) return;

    setIsLoading(true);
    const dataPost = {
      ...data,
      ...(idDocumentoFiscalCadastrado.current && {
        id: idDocumentoFiscalCadastrado.current,
      }),
      modeloFiscal: ModelosFiscaisEnum.NFe,
      tipoOperacao: tipoOperacaoWatch === undefined ? 1 : data.tipoOperacao,
      tipoOperacaoPresencaComprador:
        presencaCompradorWatch === undefined
          ? 1
          : data.tipoOperacaoPresencaComprador,
      transportadora: data.transaportadora === '' ? null : data.transaportadora,
      status: StatusFiscaisEnum.EmDigitacao,
      clienteId: data.cliente.obj.id ? data.cliente.obj.id : null,
      transportadoraId: data.transportadora?.value,
      documentoFiscalReferenciados: data.documentoFiscalReferenciados.map(
        (item: any) => {
          return { ...item, cnpj: removeMaskCNPJ(item.cnpj) };
        }
      ),
      documentoFiscalImportacoes: data.documentoFiscalImportacoes.map(
        (item: any) => {
          return {
            ...item,
            cnpjAdquirente: removeMaskCNPJ(item.cnpjAdquirente),
          };
        }
      ),
      documentoFiscalItens: data.documentoFiscalItens.map((item: any) => {
        return {
          ...item,
          regraFiscalItemId: item?.regraFiscal
            ? item.regraFiscal.obj?.id
            : null,
          regraFiscal: item?.regraFiscal ? item.regraFiscal : null,
        };
      }),
      ...(data.operacaoComIntermediador && {
        identificacaoNoIntermediador,
      }),
      ...(data.operacaoComIntermediador && {
        cnpjIntermediador,
      }),
    };

    let fetch;
    let enderecoApi;
    if (!idDocumentoFiscalCadastrado.current) {
      fetch = api.post;

      enderecoApi = ConstanteEnderecoWebservice.NOTA_FISCAL_CADASTRAR;
    } else {
      fetch = api.put;

      enderecoApi = ConstanteEnderecoWebservice.NOTA_FISCAL_ALTERAR;
    }

    const response = await fetch<void, ResponseApi<string>>(
      enderecoApi,
      dataPost
    );

    setIsLoading(false);

    if (
      response?.sucesso &&
      !salvarTransmitir &&
      !idDocumentoFiscalCadastrado.current
    ) {
      toast.success('A nota fiscal foi cadastrada com sucesso.');
      setFormIsDirty(false);

      history.push(ConstanteRotas.NOTA_FISCAL);
      return;
    }

    if (response?.sucesso && salvarTransmitir) {
      idDocumentoFiscalCadastrado.current = response.dados;
      await transmitirHandle(response.dados);
      return;
    }

    if (response?.sucesso && idDocumentoFiscalCadastrado.current) {
      toast.success('O cadastro foi alterado com sucesso.');
      setFormIsDirty(false);

      history.push(ConstanteRotas.NOTA_FISCAL);
      return;
    }

    if (response?.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }
  }

  const onSubmit = formMethods.handleSubmit(
    async () => {
      await handlePostNotaFiscal(isSalvarEAlterar.current);
    },
    () => {
      document.getElementById('produtos-tabs-tab-inicial')?.click();
    }
  );

  async function handleSalvarEAlterar() {
    isSalvarEAlterar.current = true;
    await onSubmit();
    isSalvarEAlterar.current = false;
  }

  useEffect(() => {
    setFormIsDirty(formMethods.formState.isDirty);
  }, [formMethods.formState.isDirty]);

  return (
    <>
      <Container>
        <Prompt when={formIsDirty} message="" />
        <FormProvider {...formMethods}>
          <Form>
            <ManterFoco blockTab={isLoading}>
              {isLoading && <LoadingPadrao />}
              <NotaFiscalFormularioProvider
                readonly={false}
                alteracaoCarregada={false}
              >
                <UncontrolledForm refTabInicial={refTabInicial} />
              </NotaFiscalFormularioProvider>
              <RodapeFormulario
                onSubmit={onSubmit}
                disabled={isLoading}
                textSubmitReset="Salvar e transmitir"
                isDisabledReset={isPlanoTeste === planoAtual}
                onSubmitReset={handleSalvarEAlterar}
                iconSubmitReset={SalvarConfirmarIcon}
              />
            </ManterFoco>
          </Form>
        </FormProvider>
      </Container>
      <ModalTransmissaoNota
        show={showTransmitindoNota}
        message={labelStatusTransmisssao}
      />
    </>
  );
}
