import { Tab } from '@chakra-ui/react';

type HeaderTabProps = {
  title: string;
};

export const HeaderTab = ({ title }: HeaderTabProps) => {
  return (
    <Tab
      border="none"
      _selected={{
        bg: 'gray.50',
        color: 'primary.50',
      }}
      _hover={{
        background: 'purple.50',
      }}
      color="black"
      w="200px"
      _before={{
        boxShadow: 'none !important',
      }}
      _after={{
        boxShadow: 'none !important',
      }}
      _focus={{
        boxShadow: 'none !important',
      }}
      fontSize="sm"
    >
      {title}
    </Tab>
  );
};
