import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import isPrenvent from 'helpers/layout/isPrenvent';
import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { ModalAtencao } from 'components/Modal/ModalAtencao';
import { ContainerListagem } from 'components/Layout/Listagem/containerListagem';

import { defaultValues, useForm, yupResolver } from '../validationForm';
import { UncontrolledFormRefProps, UncontrolledForm } from '..';

type VendedorProps = {
  [x: string]: any;
};

type CadastrarProps = {
  cadastroSucessoCallback?: (produto: VendedorProps) => void;
  cadastroExterno?: boolean;
};

const Cadastrar = ({
  cadastroSucessoCallback,
  cadastroExterno,
}: CadastrarProps) => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [lojasIsDirty, setLojasIsDirty] = useState(false);

  const UncontrolledFormRef = useRef<UncontrolledFormRefProps>();

  isPrenvent(formIsDirty);

  const formMethods = useForm({
    resolver: yupResolver,
    defaultValues,
  });

  const {
    handleSubmit: onSubmit,
    reset,
    getValues,
    formState: { isDirty },
    setFocus,
  } = formMethods;

  const getLojas = useCallback(async () => {
    setIsLoading(true);

    await UncontrolledFormRef.current?.getLojas();

    setIsLoading(false);
  }, []);

  const handleCadastroVendedor = useCallback(async (data) => {
    const listLojas = UncontrolledFormRef.current?.lojas;
    const lojasSelecionadasId =
      listLojas?.filter(({ selecionado }) => selecionado).map(({ id }) => id) ||
      [];
    const dadosVendedorCadastro = {
      ...data,
      dataNascimento: data?.dataNascimento || null,
    };

    const hasLojaSelecionada = lojasSelecionadasId.length > 0;

    if (!hasLojaSelecionada) {
      ModalAtencao({
        text: 'É obrigatório vincular o vendedor em pelo menos uma loja.',
        callback: () => {
          if (UncontrolledFormRef.current)
            UncontrolledFormRef.current?.abrirModalLojas();
        },
      });

      return false;
    }

    const response = await api.post<void, ResponseApi>(
      ConstanteEnderecoWebservice.VENDEDOR_CADASTRAR,
      {
        vendedorViewModel: dadosVendedorCadastro,
        lojasSelecionadas: lojasSelecionadasId,
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }
      if (response.sucesso) {
        toast.success('O cadastro foi realizado com sucesso.');

        return true;
      }
    }

    return false;
  }, []);

  const handleSubmit = onSubmit(async (data) => {
    setIsLoading(true);

    const sucesso = await handleCadastroVendedor(data);

    if (sucesso) {
      setFormIsDirty(false);
      setLojasIsDirty(false);

      if (cadastroExterno && cadastroSucessoCallback) {
        cadastroSucessoCallback(getValues());
      } else {
        history.push(ConstanteRotas.VENDEDOR);
      }
    }

    setIsLoading(false);
  });

  const handleSubmitReset = onSubmit(async (data) => {
    setIsLoading(true);

    const sucesso = await handleCadastroVendedor(data);

    if (sucesso) {
      setFocus('nome');
      setFormIsDirty(false);
      setLojasIsDirty(false);

      reset(defaultValues);

      await getLojas();
    }

    setIsLoading(false);
  });

  useEffect(() => {
    getLojas();
  }, [getLojas]);

  useEffect(() => {
    setFormIsDirty(isDirty);
  }, [isDirty]);

  return (
    <FormProvider {...formMethods}>
      <ContainerListagem
        formMethods={formMethods}
        isLoading={isLoading}
        maxWidth="full"
        onSubmit={handleSubmit}
        onSubmitReset={handleSubmitReset}
        formIsDirty={formIsDirty || lojasIsDirty}
      >
        {isLoading && <LoadingPadrao />}
        <UncontrolledForm
          setIsDirty={setFormIsDirty}
          ref={UncontrolledFormRef}
        />
      </ContainerListagem>
    </FormProvider>
  );
};

export default Cadastrar;
