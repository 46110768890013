import { useCallback, useEffect, useRef, useState } from 'react';
import { GridItem } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import TipoCadastroCampoPersonalizadoEnum from 'constants/enum/tipoCadastroCampoPersonalizado';
import enumMesesDoAno from 'constants/enum/enumMesesDoAno';
import api, { ResponseApi } from 'services/api';

import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import AsyncSelect from 'components/PDV/Select/AsyncSelectPadrao';
import Input from 'components/PDV/Input';
import { CamposPersonalizados } from 'components/update/CamposPersonalizados';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import CampoPersonalizadoInterface from 'types/campoPersonalizado';

type FormCustomStandard = {
  control: any;
  Loading?: boolean;
  type?: 'Personalizados' | 'CadastroGeral';
};

type Cidade = {
  nome: string;
  id: number;
};

type Options = {
  label: string;
  value: string | number;
};

type OptionsValues = {
  id: string;
  nome: string;
};

type ListaDeRelatorios = {
  id: string;
  nome: string;
};

export type CamposPersonalizados = {
  campoPersonalizadoId: string;
  valor: string;
};

export const FormCustomStandardClientsComponent = ({
  control,
  type,
  Loading,
}: FormCustomStandard) => {
  const { setValue, getValues } = useForm();

  const [optionsReports, setOptionsReport] = useState<Options[]>([]);
  const [optionsReportsLoading, setIsOptionsReportLoading] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [categorias, setCategorias] = useState<Options[]>([]);
  const [categoriasIsLoading, setCategoriasIsLoading] = useState(false);
  const [cidadeIsLoading, setCidadeIsloading] = useState<boolean>(false);
  const [camposPersonalizados, setCamposPersonalizados] = useState<
    CampoPersonalizadoInterface[]
  >([]);

  const latestProps = useRef({
    setValue,
    getValues,
  });

  useEffect(() => {
    latestProps.current = {
      setValue,
      getValues,
    };
  });

  const getCategoriasValues = useCallback(async () => {
    setCategoriasIsLoading(true);
    const response = await api.get<void, ResponseApi<OptionsValues[]>>(
      ConstanteEnderecoWebservice.CATEGORIA_CLIENTE_SELECT
    );

    if (response?.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (response?.sucesso) {
      setCategorias(
        response.dados.map((categoria) => {
          return {
            label: categoria.nome,
            value: categoria.id,
          } as Options;
        })
      );
    }
    setCategoriasIsLoading(false);
  }, []);

  const getCidadesValues = useCallback(async (valueInput: string) => {
    setCidadeIsloading(true);
    const response = await api.get<void, ResponseApi<Cidade[]>>(
      ConstanteEnderecoWebservice.CIDADE_LISTAR_CACHE,
      {
        params: { nome: valueInput },
      }
    );

    if (response?.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (response?.sucesso) {
      setCidadeIsloading(false);
      return response.dados.map((cidade) => {
        return {
          label: cidade.nome,
          value: cidade.id,
        } as Options;
      });
    }
    setCidadeIsloading(false);
    return [];
  }, []);

  const getCamposPersonalizados = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get<
      void,
      ResponseApi<CampoPersonalizadoInterface[]>
    >(ConstanteEnderecoWebservice.CAMPO_PERSONALIZADO_LISTARPORTIPOCADASTRO, {
      params: { tipoCadastro: TipoCadastroCampoPersonalizadoEnum.CLIENTE },
    });

    if (response?.avisos) {
      response.avisos.forEach((item: string) => toast.warning(item));
    }

    if (response && response.sucesso) {
      setCamposPersonalizados(response.dados);

      const currentCamposPersonalizados = latestProps.current.getValues(
        'camposPersonalizados'
      );

      latestProps.current.setValue(
        'camposPersonalizados',
        response.dados.map((campoPersonalizado: any) => {
          const currentCampoPersonalizado = currentCamposPersonalizados
            ? currentCamposPersonalizados.find(
                (x: CamposPersonalizados) =>
                  x.campoPersonalizadoId === campoPersonalizado.id
              )
            : undefined;

          return {
            campoPersonalizadoId: campoPersonalizado.id,
            valor: currentCampoPersonalizado
              ? currentCampoPersonalizado.valor
              : null,
          } as CamposPersonalizados;
        })
      );
    }
    setIsLoading(false);
  }, []);

  const getOptionsReports = async () => {
    setIsOptionsReportLoading(true);
    const response = await api.get<void, ResponseApi<ListaDeRelatorios[]>>(
      ConstanteEnderecoWebservice.RELATORIO_PERSONALIZADO_CLIENTES_LISTAR
    );

    if (response.avisos) {
      response.avisos.forEach((avisos) => toast.warning(avisos));
    }

    if (response.sucesso && response.dados) {
      setOptionsReport(
        response.dados.map((item) => {
          return {
            label: item.nome,
            value: item.id,
          } as Options;
        })
      );
    }
    setIsOptionsReportLoading(false);
  };

  useEffect(() => {
    getCategoriasValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (type !== 'CadastroGeral') getOptionsReports();
  }, [type]);

  useEffect(() => {
    getCamposPersonalizados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {(isLoading || Loading) && <LoadingPadrao />}

      <SimpleGridForm bg="white" borderRadius="md" boxShadow="lg" padding="8">
        {type === 'Personalizados' && (
          <>
            <GridItem colSpan={{ base: 12, sm: 12, lg: 6 }}>
              <SelectPadrao
                placeholder="Selecione um relatório"
                id="relatorio"
                name="relatorio"
                label="Relatório"
                required
                options={optionsReports}
                isLoading={optionsReportsLoading}
              />
            </GridItem>
            <GridItem colSpan={{ base: 12, sm: 12, lg: 6 }}>
              <SelectPadrao
                placeholder="Selecione um mês de nascimento"
                id="mesNascimento"
                name="mesNascimento"
                label="Mês do nascimento"
                options={enumMesesDoAno.properties}
              />
            </GridItem>
          </>
        )}
        <GridItem colSpan={{ base: 12, sm: 6, lg: 6 }}>
          <Input
            id="nome"
            name="nome"
            helperText="A pesquisa por nome considera os caracteres que compõem o nome dos clientes"
            label="Nome"
            placeholder="Digite o nome"
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, sm: 6, lg: 6 }}>
          <Input
            placeholder="Digite o apelido"
            id="apelido"
            helperText="A pesquisa por apelido considera os caracteres que compõem o apelido dos clientes"
            name="apelido"
            label="Apelido"
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, sm: 6, lg: 6 }}>
          <Input
            helperText="A pesquisa por e-mail considera os caracteres que compõem o e-mail dos clientes"
            placeholder="Digite o e-mail"
            id="email"
            name="email"
            label="E-mail"
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, sm: 6, lg: 6 }}>
          <SelectPadrao
            placeholder="Selecione a opção desejada"
            id="categoria"
            name="categoria"
            label="Categoria"
            options={categorias}
            isLoading={categoriasIsLoading}
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, sm: 6, lg: 6 }}>
          <AsyncSelect
            placeholder="Selecione a cidade"
            id="cidade"
            isClearable
            name="cidade"
            isLoading={cidadeIsLoading}
            label="Cidade"
            handleGetOptions={getCidadesValues}
            asControlledByObject={false}
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, sm: 6, lg: 6 }}>
          <Input
            placeholder="Digite as observações"
            id="observacao"
            helperText="A pesquisa por observação considera as observações do cadastro de clientes"
            name="observacao"
            label="Observação"
          />
        </GridItem>

        <GridItem
          colSpan={{ base: 12, sm: 12, lg: 12 }}
          sx={{ '& > div': { padding: '1rem 0 0 !Important' } }}
        >
          <CamposPersonalizados
            labelOfNull
            camposPersonalizados={camposPersonalizados}
          />
        </GridItem>
      </SimpleGridForm>
    </>
  );
};
