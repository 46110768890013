import auth from 'modules/auth';
import ConstanteFuncionalidades from 'constants/permissoes';
import { enumRelatorioProdutosPersonalizadoPadrao } from 'constants/enum/RelatoriosPadrao';

export const validarTipoRelatorioProdutosVendidos = (item: {
  value: number;
}): boolean => {
  const isRelatorioProdutoPorVenda =
    item.value ===
    enumRelatorioProdutosPersonalizadoPadrao.RELATORIO_PRODUTO_POR_VENDA;

  const tipoRelatorio = isRelatorioProdutoPorVenda
    ? ConstanteFuncionalidades.RELATORIO_PRODUTO_POR_VENDA
    : ConstanteFuncionalidades.RELATORIO_LUCRO_POR_PRODUTO;

  const possuiFuncionalidade = auth.possuiPermissao(tipoRelatorio).permitido;

  return possuiFuncionalidade;
};
