import React, { useState, useRef, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Prompt, useHistory } from 'react-router-dom';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';
import RegrasFiscaisFormularioProvider from 'store/RegrasFiscais/RegrasFiscaisFormularios';
import ConstanteRotas from 'constants/rotas';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import isPrenvent from 'helpers/layout/isPrenvent';

import ManterFoco from 'components/Geral/ManterFoco';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import RodapeFormulario from 'components/Geral/RodapeFormulario';

import useReloadRegistration from 'helpers/layout/useReloadRegistration';
import { FormRegraFiscalData, mapRequestRegraFiscalData } from '../types';
import Formulario, { FormularioForwardRefData } from '..';
import { yupResolver } from '../validationForm';

async function cadastrarRegraFiscal(data: FormRegraFiscalData) {
  const mappedData = mapRequestRegraFiscalData(data);

  const response = await api.post<void, ResponseApi>(
    ConstanteEnderecoWebservice.REGRA_FISCAL_CADASTRAR,
    {
      ...mappedData,
    }
  );

  if (response) {
    if (response.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (response.sucesso) {
      return true;
    }
  }

  return false;
}

const Cadastrar = () => {
  const history = useHistory();
  const formMethods = useForm<FormRegraFiscalData>({
    resolver: yupResolver,
    defaultValues: {
      nome: '',
      ativo: true,
      operacoes: [
        {
          identificacaoTipoOperacao: IdentificacaoTipoOperacaoEnum.VENDA,
        },
      ],
    },
  });
  const isMountedRef = useIsMountedRef();

  const { dirtyFields } = formMethods.formState;
  const isDirty =
    dirtyFields &&
    dirtyFields.constructor === Object &&
    Object.keys(dirtyFields).length > 0;

  const [formIsDirty, setFormIsDirty] = useState(false);

  const historyReload = useReloadRegistration({ setFormIsDirty });
  isPrenvent(formIsDirty);

  const [isLoading, setIsLoading] = useState(false);

  const formularioRef = useRef<FormularioForwardRefData>(null);

  function historyPush(path: string) {
    setFormIsDirty(false);

    history.push(path);
  }

  const handleSubmit = formMethods.handleSubmit(
    async (data) => {
      setIsLoading(true);

      const success = await cadastrarRegraFiscal(data);

      if (success) {
        toast.success('O cadastro foi realizado com sucesso.');

        historyPush(ConstanteRotas.REGRA_FISCAL);
      }

      if (isMountedRef.current) setIsLoading(false);
    },
    (errors) => {
      setIsLoading(true);

      if (formularioRef.current) {
        formularioRef.current.focusAccordionError(errors);
      }

      setIsLoading(false);
    }
  );

  const handleSubmitReset = formMethods.handleSubmit(
    async (data) => {
      setIsLoading(true);

      const success = await cadastrarRegraFiscal(data);

      if (success) {
        toast.success('O cadastro foi realizado com sucesso.');

        if (formularioRef.current) {
          formularioRef.current.setDefaultTabAccordions();
        }

        historyReload(ConstanteRotas.REGRA_FISCAL_CADASTRAR);

        setIsLoading(false);

        formMethods.setFocus('nome');
      }

      if (isMountedRef.current) setIsLoading(false);
    },
    (errors) => {
      setIsLoading(true);

      if (formularioRef.current) {
        formularioRef.current.focusAccordionError(errors);
      }

      setIsLoading(false);
    }
  );

  useEffect(() => {
    setFormIsDirty(isDirty);
  }, [isDirty]);

  return (
    <Box>
      <Prompt when={formIsDirty} message="" />
      <ManterFoco>
        {isLoading && <LoadingPadrao />}
        <RegrasFiscaisFormularioProvider {...formMethods}>
          <Formulario ref={formularioRef} />
        </RegrasFiscaisFormularioProvider>
        <RodapeFormulario
          onSubmit={handleSubmit}
          onSubmitReset={handleSubmitReset}
          disabled={isLoading}
        />
      </ManterFoco>
    </Box>
  );
};

export default Cadastrar;
