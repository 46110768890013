import { Route } from 'react-router-dom';

import ConstanteRotas from 'constants/rotas';
import ConstanteFuncionalidades from 'constants/permissoes';

import { CredenciadoraCartaoListar } from 'pages/CredenciadoraCartao/Listar';
import { CredenciadoraCartaoCadastrar } from 'pages/CredenciadoraCartao/Formulario/Cadastrar';
import { CredenciadoraCartaoAlterar } from 'pages/CredenciadoraCartao/Formulario/Alterar';
import { CredenciadoraCartaoVisualizar } from 'pages/CredenciadoraCartao/Formulario/Visualizar';

import LayoutGuard from './LayoutGuard';

export const CredenciadoraCartaoRoutes = [
  <Route path={ConstanteRotas.CREDENCIADORA_CARTAO} key="credenciadora-cartao">
    <LayoutGuard
      key={ConstanteRotas.CREDENCIADORA_CARTAO}
      path={ConstanteRotas.CREDENCIADORA_CARTAO}
      component={CredenciadoraCartaoListar}
      permissaoFuncionalidade={
        ConstanteFuncionalidades.CREDENCIADORA_CARTAO_LISTAR
      }
      breadcrumb={[
        {
          title: 'Configurações',
        },
        {
          title: 'Credenciadora de cartão',
        },
      ]}
      meta={{ auth: true }}
      exact
    />
    <LayoutGuard
      key={ConstanteRotas.CREDENCIADORA_CARTAO_CADASTRAR}
      path={ConstanteRotas.CREDENCIADORA_CARTAO_CADASTRAR}
      component={CredenciadoraCartaoCadastrar}
      permissaoFuncionalidade={
        ConstanteFuncionalidades.CREDENCIADORA_CARTAO_CADASTRAR
      }
      breadcrumb={[
        {
          title: 'Configurações',
        },
        {
          title: 'Credenciadora de cartão',
          path: ConstanteRotas.CREDENCIADORA_CARTAO,
        },
        {
          title: 'Novo',
        },
      ]}
      meta={{ auth: true }}
      exact
    />

    <LayoutGuard
      key={ConstanteRotas.CREDENCIADORA_CARTAO_VISUALIZAR}
      path={ConstanteRotas.CREDENCIADORA_CARTAO_VISUALIZAR}
      component={CredenciadoraCartaoVisualizar}
      permissaoFuncionalidade={
        ConstanteFuncionalidades.CREDENCIADORA_CARTAO_VISUALIZAR
      }
      breadcrumb={[
        {
          title: 'Configurações',
        },
        {
          title: 'Credenciadora de cartão',
          path: ConstanteRotas.CREDENCIADORA_CARTAO,
        },
        {
          title: 'Visualizar',
        },
      ]}
      meta={{ auth: true }}
      exact
    />
    <LayoutGuard
      key={ConstanteRotas.CREDENCIADORA_CARTAO_ALTERAR}
      path={ConstanteRotas.CREDENCIADORA_CARTAO_ALTERAR}
      permissaoFuncionalidade={
        ConstanteFuncionalidades.CREDENCIADORA_CARTAO_ALTERAR
      }
      component={CredenciadoraCartaoAlterar}
      breadcrumb={[
        {
          title: 'Configurações',
        },
        {
          title: 'Credenciadora de cartão',
          path: ConstanteRotas.CREDENCIADORA_CARTAO,
        },
        {
          title: 'Alterar',
        },
      ]}
      meta={{ auth: true }}
      exact
    />
  </Route>,
];
