const StatusConsultaEnum = {
  ATIVOS: 1,
  INATIVOS: 2,
  TODOS: 3,

  properties: {
    1: { name: 'Ativos', value: 1 },
    2: { name: 'Inativos', value: 2 },
    3: { name: 'Todos', value: 3 },
  },

  status: [
    { label: 'Ativos', value: 1 },
    { label: 'Inativos', value: 2 },
    { label: 'Todos', value: 3 },
  ],
};

export default StatusConsultaEnum;
