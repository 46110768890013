import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import api, { ResponseApi } from 'services/api';
import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import { CategoriaPermissaoInterface } from 'components/Permissoes';
import { ContainerListagem } from 'components/Layout/Listagem/containerListagem';

import { useForm, yupResolver } from '../validationForm';
import { UncontrolledForm } from '..';

interface PerfilUsuarioInterface {
  id: string;
  descricao: string;
  ativo: boolean;
  dataHoraCadastro: string;
  dataHoraUltimaAlteracao: string;
}

type TParams = { id: string };

const Visualizar = () => {
  const history = useHistory();
  const isMountedRef = useIsMountedRef();

  const [isLoading, setIsLoading] = useState(true);

  const formMethods = useForm({
    resolver: yupResolver,
  });
  const { reset, getValues } = formMethods;

  const { id: idRota } = useParams<TParams>();

  const [dataHoraCadastro, setDataHoraCadastro] = useState('');
  const [dataHoraUltimaAlteracao, setDataHoraUltimaAlteracao] = useState('');

  const [categorias, setCategorias] = useState(
    [] as Array<CategoriaPermissaoInterface>
  );

  const latestProps = useRef({
    getValues,
    history,
    isMountedRef,
    matchId: idRota,
    reset,
  });
  useEffect(() => {
    latestProps.current = {
      getValues,
      history,
      isMountedRef,
      matchId: idRota,
      reset,
    };
  });

  useEffect(() => {
    const handleGetCategoriaPermissao = async () => {
      setIsLoading(true);

      const response = await api.get<
        void,
        ResponseApi<Array<CategoriaPermissaoInterface>>
      >(
        ConstanteEnderecoWebservice.PERFIL_USUARIO_LISTAR_CATEGORIA_PERMISSOES,
        {
          params: { perfilId: latestProps.current.matchId },
        }
      );

      if (response?.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }

      if (response?.sucesso && latestProps.current.isMountedRef.current) {
        const defaultValues: { [key: string]: any } = {
          permissoes: [],
        };

        response.dados.forEach((categoria) => {
          categoria.grupos.forEach((grupo) => {
            let todoSelecionados = true;

            grupo.permissoes.forEach((permissao) => {
              defaultValues.permissoes.push(permissao);

              if (!permissao.selecionado) {
                todoSelecionados = false;
              }
            });

            defaultValues[`grupo${grupo.id}`] = {
              id: grupo.id,
              selecionado: todoSelecionados,
            };
          });
        });

        latestProps.current.reset({
          ...latestProps.current.getValues(),
          ...defaultValues,
        });

        setCategorias(response.dados);
      }

      if (latestProps.current.isMountedRef.current) setIsLoading(false);
    };

    const handleGetPerfilUsuario = async () => {
      setIsLoading(true);

      const response = await api.get<void, ResponseApi<PerfilUsuarioInterface>>(
        ConstanteEnderecoWebservice.PERFIL_USUARIO_OBTER,
        {
          params: { id: latestProps.current.matchId },
        }
      );

      if (response?.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }

      if (response?.sucesso && latestProps.current.isMountedRef.current) {
        latestProps.current.reset({
          ...response.dados,
        });

        setDataHoraCadastro(response.dados.dataHoraCadastro);
        setDataHoraUltimaAlteracao(response.dados.dataHoraUltimaAlteracao);
      } else {
        latestProps.current.history.push(ConstanteRotas.PERFIL_USUARIO);
      }

      if (latestProps.current.isMountedRef.current) setIsLoading(false);
    };

    setIsLoading(true);

    handleGetPerfilUsuario();
    handleGetCategoriaPermissao();
  }, []);

  return (
    <ContainerListagem
      formMethods={formMethods}
      isLoading={isLoading}
      dataHoraUltimaAlteracao={dataHoraUltimaAlteracao}
      dataHoraCadastro={dataHoraCadastro}
      isVisualizar
      maxWidth="full"
    >
      <UncontrolledForm categorias={categorias} readonly />
    </ContainerListagem>
  );
};

export default Visualizar;
