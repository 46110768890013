import { useCallback } from 'react';
import {
  Input,
  InputProps,
  Icon,
  InputGroup,
  InputRightElement,
  useDisclosure,
  IconButton,
  Flex,
} from '@chakra-ui/react';
import format from 'date-fns/format';
import ptBR from 'date-fns/locale/pt-BR';
import { useFormContext } from 'react-hook-form';
import { FiX } from 'react-icons/fi';
import { toast } from 'react-toastify';

import { setDateMinHours, setDateMaxHours } from 'helpers/data/setHoursDate';

import { CalendarioIcon } from 'icons';
import ModalDateRange, { Selection } from 'components/PDV/Modal/ModalDateRange';

interface InputDateRangeProps extends InputProps {
  startDateName: string;
  endDateName: string;
  minDate?: Date;
  maxDate?: Date;
  inputValueIsSmaller?: boolean;
  onConfirm?: () => void;
  cleanFiltersButtonText?: string;
  isClearable?: boolean;
  amountMonths?: number;
  validarMesAnteriorSucessor?: boolean;
}

const InputDateRange = ({
  startDateName,
  endDateName,
  isDisabled,
  amountMonths,
  minDate,
  maxDate,
  inputValueIsSmaller = false,
  onConfirm,
  cleanFiltersButtonText,
  minW,
  variant,
  size = 'md',
  sx,
  validarMesAnteriorSucessor = false,
  isClearable = false,
  ...inputProps
}: InputDateRangeProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { watch, setValue } = useFormContext();
  const dateFormat = inputValueIsSmaller ? 'dd/MM/yy' : 'dd/MM/yyyy';

  const startDate: Date = watch(`${startDateName}` as const);
  const endDate: Date = watch(`${endDateName}` as const);

  const startDateFormatted = startDate
    ? format(startDate, dateFormat, {
        locale: ptBR,
      })
    : undefined;

  const endDateFormatted = endDate
    ? format(endDate, dateFormat, {
        locale: ptBR,
      })
    : undefined;

  const inputValue =
    startDateFormatted === endDateFormatted
      ? startDateFormatted
      : `${startDateFormatted} a ${endDateFormatted}`;

  const onSubmit = useCallback(
    (newSelection?: Selection) => {
      setValue(
        `${startDateName}` as const,
        newSelection ? setDateMinHours(newSelection.startDate) : undefined
      );
      setValue(
        `${endDateName}` as const,
        newSelection ? setDateMaxHours(newSelection.endDate) : undefined
      );

      if (validarMesAnteriorSucessor) {
        const valorData = Math.abs(
          (newSelection?.startDate || new Date()).getTime() -
            (newSelection?.endDate || new Date()).getTime()
        );
        const diferencaDiasDataInicialFinal = Math.ceil(
          valorData / (1000 * 3600 * 24)
        );

        if (onConfirm && diferencaDiasDataInicialFinal < 32) {
          onConfirm();
          return true;
        }
        toast.warning(
          'A data selecionada ultrapassa 1 mês, por favor informar um período dentro de 31 dias'
        );
        return false;
      }

      if (onConfirm) {
        onConfirm();
        return true;
      }

      return true;
    },
    [
      endDateName,
      onConfirm,
      setValue,
      startDateName,
      validarMesAnteriorSucessor,
    ]
  );

  const getCurrentSelection = useCallback(
    () => ({
      startDate: setDateMinHours(startDate || new Date()),
      endDate: setDateMaxHours(endDate || new Date()),
    }),
    [endDate, startDate]
  );

  return (
    <>
      <InputGroup
        position="relative"
        minW={minW}
        variant={variant}
        size={size}
        sx={sx}
        onClick={isDisabled || isClearable ? undefined : onOpen}
        cursor={isDisabled || isClearable ? undefined : 'pointer'}
      >
        <Input
          pr={isClearable ? '4.75rem' : undefined}
          {...inputProps}
          isDisabled={isDisabled}
          pointerEvents="none"
          borderWidth="1px"
          borderColor="inherit"
          value={inputValue || ''}
        />

        <InputRightElement w={isClearable ? '5.5rem' : '14'}>
          {isClearable && (
            <Flex alignItems="center" justifyContent="center" w="9">
              <IconButton
                variant="ghost"
                minW="9"
                borderRadius={size}
                size={size}
                aria-label="Limpar"
                icon={<Icon as={FiX} />}
                onClick={() => {
                  onSubmit();
                }}
              />
            </Flex>
          )}

          <Flex alignItems="center" justifyContent="center" w="9">
            {isClearable ? (
              <IconButton
                variant="ghost"
                minW="9"
                borderRadius={size}
                size={size}
                aria-label="Abrir calendário"
                icon={<Icon as={CalendarioIcon} color="gray.700" />}
                onClick={isDisabled ? undefined : onOpen}
              />
            ) : (
              <Icon as={CalendarioIcon} color="gray.700" />
            )}
          </Flex>
        </InputRightElement>
      </InputGroup>

      <ModalDateRange
        isOpen={isOpen}
        amountMonths={amountMonths}
        onClose={onClose}
        onSubmit={onSubmit}
        getCurrentSelection={getCurrentSelection}
        minDate={minDate}
        maxDate={maxDate}
        cleanFiltersButtonText={cleanFiltersButtonText}
      />
    </>
  );
};

export default InputDateRange;
