import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';

type SairDevolucaoProps = {
  handleOperacao: () => void;
  detalhesTroca: { idOperacao: string; valorTotalTroca: number };
};

const handleSairDevolucao = ({
  handleOperacao,
  detalhesTroca,
}: SairDevolucaoProps) => {
  if (detalhesTroca.idOperacao) {
    ModalConfirmacaoExcluir({
      title: 'Desistir da troca?',
      text: `Uma devolução e um voucher foram gerados porém a troca não foi finalizada, deseja desistir da troca?`,
      confirmButtonText: 'Sim, desistir!',
      cancelButtonText: 'Cancelar',
      callback: async (ok: boolean) => {
        if (ok) {
          handleOperacao();
        }
      },
    });
  } else {
    handleOperacao();
  }
};

export default handleSairDevolucao;
