const formatUTCToLocateDateTimeInput = (date: string): string => {
  const localeDateString = new Date(date).toLocaleDateString();
  const ano = localeDateString.substr(6, 4);
  const mes = localeDateString.substr(3, 2);
  const dia = localeDateString.substr(0, 2);

  return `${ano}-${mes}-${dia}T${new Date(date).toLocaleTimeString()}`;
};

export default formatUTCToLocateDateTimeInput;
