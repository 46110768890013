import { Box, Divider, Flex, Text } from '@chakra-ui/react';

import formatUTCToLocateDateTime from 'helpers/format/formatUTCToLocateDateTime';

interface FooterProps {
  usuario: string;
  contatoEmpresa: string;
  formaRecebimento: string;
  nomeCliente: string;
  dataEmissao: string;
}

export function FooterDevolucaoDinheiro({
  contatoEmpresa,
  usuario,
  formaRecebimento,
  nomeCliente,
  dataEmissao,
}: FooterProps) {
  return (
    <>
      <Flex
        direction="column"
        alignItems="flex-start"
        pt={2}
        pb={2}
        gap="1"
        borderBottom="2px solid"
        borderColor="gray.700"
      >
        <Box display="flex" w="100%">
          <Text flex="1" fontSize="2xs" fontWeight="extrabold" color="black">
            Data:
            <Text as="span" fontWeight="semibold" ml="1">
              {formatUTCToLocateDateTime(dataEmissao)}
            </Text>
          </Text>
        </Box>
        <Box>
          <Text fontWeight="extrabold" fontSize="x-small">
            Operador(a):
            <Text as="span" fontWeight="semibold" ml="1">
              {usuario}
            </Text>
          </Text>
        </Box>
        <Box>
          <Text fontWeight="extrabold" fontSize="x-small">
            Forma de devolução:
            <Text as="span" ml="1" fontWeight="semibold">
              {formaRecebimento}
            </Text>
          </Text>
        </Box>
        <Box>
          <Text fontWeight="extrabold" fontSize="x-small">
            Cliente:
            <Text as="span" ml="1" fontWeight="semibold">
              {nomeCliente}
            </Text>
          </Text>
        </Box>
        <Flex alignItems="center" justifyContent="flex-start" w="full">
          <Box mr="10px" fontWeight="extrabold">
            <Text as="span" fontWeight="extrabold" fontSize="10px">
              Ass:
            </Text>
          </Box>
          <Divider mt="4px" w="70%" />
        </Flex>
      </Flex>
      <Text
        textAlign="right"
        fontStyle="italic"
        fontSize="2xs"
        fontWeight="bold"
        color="gray.900"
      >
        {contatoEmpresa}
      </Text>
    </>
  );
}
