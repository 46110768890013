import { Box, HStack, FlexProps, Circle, Icon, Flex } from '@chakra-ui/react';
import { useCallback, useState, useEffect } from 'react';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';

type SlideProps = {
  content: React.ReactNode;
  key: string;
  isHidden: boolean;
};

interface SlidersProps extends FlexProps {
  slide: SlideProps[];
}

export const Sliders = ({ slide, bg = 'gray.200', ...rest }: SlidersProps) => {
  const slidesVisiveis = slide.filter(
    (slideItem) => slideItem.isHidden === false
  );

  const [listSlide, setListSlide] = useState<SlideProps>();
  const [slideSelecionado, setSlideSelecionado] = useState(0);

  const existeProximoSlide = slidesVisiveis.length > 1;

  const exibirSlideSelecionado = useCallback(
    (indexItem: number) => {
      if (slidesVisiveis) {
        setListSlide(slidesVisiveis[indexItem]);
      }
    },
    [slidesVisiveis]
  );

  const handleNextSlide = () => {
    const newPosition = slidesVisiveis.findIndex(
      (slideItem) => slideItem.key === listSlide?.key
    );
    if (newPosition + 1 === slidesVisiveis.length) {
      setSlideSelecionado(0);
    } else {
      setSlideSelecionado(newPosition + 1);
    }
  };

  const handlePreviousSlide = () => {
    const newPosition = slidesVisiveis.findIndex(
      (slideItem) => slideItem.key === listSlide?.key
    );

    setSlideSelecionado(newPosition - 1);
  };

  useEffect(() => {
    exibirSlideSelecionado(slideSelecionado);
  }, [exibirSlideSelecionado, slideSelecionado]);

  useEffect(() => {
    const slideVisivelItem = slide.filter(
      (slideItem) => slideItem.isHidden === false
    );
    setSlideSelecionado(
      slideVisivelItem.findIndex((slideItem) => slideItem.isHidden === false)
    );
  }, [slide]);

  return (
    <Flex
      pt="14px"
      borderRadius="5px"
      align="center"
      justifyContent="center"
      w="full"
      {...rest}
    >
      <Box h="full" position="relative">
        {existeProximoSlide && (
          <Flex
            w="full"
            h="full"
            justifyContent="end"
            right="-45px"
            top="-24px"
            alignItems="center"
            position="absolute"
          >
            <Icon
              cursor="pointer"
              color="gray.700"
              zIndex="1"
              onClick={() => handleNextSlide()}
              as={FiChevronRight}
              boxSize="40px"
            />
          </Flex>
        )}
        {existeProximoSlide && slideSelecionado > 0 && (
          <Flex
            w="full"
            h="full"
            justifyContent="start"
            left="-45px"
            top="-24px"
            alignItems="center"
            position="absolute"
          >
            <Icon
              cursor="pointer"
              color="gray.700"
              zIndex="1"
              onClick={() => handlePreviousSlide()}
              as={FiChevronLeft}
              boxSize="40px"
            />
          </Flex>
        )}
        <Flex h="full" align="center" justifyContent="center" w="full">
          {listSlide?.content}
        </Flex>
        {existeProximoSlide && (
          <HStack justifyContent="center" spacing="16px" mt="24px">
            {slide.map((slideItem, index) => {
              if (slideItem.isHidden) {
                return null;
              }

              return (
                <Circle
                  alignItems="center"
                  cursor="pointer"
                  borderWidth="1px"
                  borderStyle="solid"
                  transition={
                    listSlide?.key === slideItem.key
                      ? 'transform 1s ease-in-out'
                      : 'undefined'
                  }
                  onClick={() => setSlideSelecionado(index)}
                  background={
                    listSlide?.key === slideItem.key ? 'primary.50' : 'white'
                  }
                  size="10px"
                  zIndex={2}
                />
              );
            })}
          </HStack>
        )}
      </Box>
    </Flex>
  );
};
