export const TipoOcorrenciaEnum = {
  UNICA: 1,
  PARCELADA: 2,
  REPETIR: 3,

  properties: {
    1: { label: 'Única', value: 1 },
    2: { label: 'Parcelada', value: 2 },
    3: { label: 'Repetir', value: 3 },
  },
};
