import { IconDashboardGerencial } from 'icons';

import { Container } from '../../Components/Layout/Container';
import { useDashboardData } from './hooks';

export const DetalhesIntegracaoDashboard = () => {
  const { listItemsInfo } = useDashboardData();

  return (
    <Container
      listItemsInfo={listItemsInfo}
      widthIcon={150}
      heightIcon={150}
      isIntegrationCancel={false}
      icon={IconDashboardGerencial}
      date={undefined}
    />
  );
};
