import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react';
import { useHistory } from 'react-router-dom';

import { useOperacaoContext } from 'store/PDV/Operacao';
import ConstanteRotasPDV from 'constants/rotasPDV';
import obterOperacaoParaFinalizar, {
  OperacaoObterParaFinalizar,
  DocumentoFiscal,
} from 'helpers/api/Operacao/obterOperacaoParaFinalizar';
import obterInformacoesFinalizarVenda, {
  ObterInformacoesFinalizarVendaResponse,
} from 'helpers/api/Loja/obterInformacoesFinalizarVenda';
import { AmbienteFiscal } from 'constants/enum/fiscal/ambienteFiscal';
import obterAmbienteFiscal from 'helpers/api/Loja/obterAmbienteFiscal';
import ModelosFiscaisEnum from 'constants/enum/fiscal/modelosFiscais';
import StatusFiscaisEnum from 'constants/enum/fiscal/statusFiscal';
import usePersistedState from 'helpers/layout/usePersistedState';
import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';
import { formatarDescricaoDocumentoFiscal } from 'helpers/api/Operacao/formatarDescricaoDocumentoFiscal';
import auth from 'modules/auth';
import PlanoContratacaoEnum from 'constants/enum/planoContratacao';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

interface Operacao extends OperacaoObterParaFinalizar {
  NFCe?: DocumentoFiscal;
  NFCeFormatada?: string;
  NFe?: DocumentoFiscal;
  NFeFormatada?: string;
}

interface FinalizarContextProps {
  operacao?: Operacao;
  informacoesLoja?: ObterInformacoesFinalizarVendaResponse;
  handleObterOperacao: () => Promise<void>;
  tabIndex: number;
  handleTabsChange: (newTabIndex: number) => void;
  deveMostrarTelaEmitirNFCe: boolean;
  deveMostrarTelaEmitirNFe: boolean;
  setDefaultModeloFiscal: Dispatch<SetStateAction<'' | 'NFe' | 'NFCe'>>;
  temNFeAutorizada: boolean;
  ambienteFiscal?: AmbienteFiscal;
  boletoZoopFoiGerado: boolean;
  setBoletoZoopFoiGerado: Dispatch<SetStateAction<boolean>>;
  erroAoGerarBoletoZoop: boolean;
  setErroAoGerarBoletoZoop: Dispatch<SetStateAction<boolean>>;
}

export const FinalizarContext = createContext<FinalizarContextProps>(
  {} as FinalizarContextProps
);

interface FinalizarProviderProps {
  children: React.ReactNode;
}

export default function FinalizarProvider({
  children,
}: FinalizarProviderProps): JSX.Element {
  const history = useHistory();
  const {
    setOperacaoIsLoading,
    operacaoIsLoading,
    operacaoId,
  } = useOperacaoContext();

  const [defaultModeloFiscal, setDefaultModeloFiscal] = usePersistedState<
    'NFe' | 'NFCe' | ''
  >('pdv-ultimo-modelo-fiscal-emitido', '');
  const [operacao, setOperacao] = useState<Operacao>();
  const [boletoZoopFoiGerado, setBoletoZoopFoiGerado] = useState(false);
  const [erroAoGerarBoletoZoop, setErroAoGerarBoletoZoop] = useState(false);

  const [ambienteFiscal, setAmbienteFiscal] = useState<AmbienteFiscal>();
  const [
    informacoesLoja,
    setInformacoesLoja,
  ] = useState<ObterInformacoesFinalizarVendaResponse>();

  // 0 - Compartilhar
  // 1 - NFCe
  // 2 - NFe
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  const planoAtual = auth.getPlano();
  const planoTeste = PlanoContratacaoEnum.TESTE;

  const temNFCeAutorizada =
    !!operacao &&
    !!operacao.NFCe &&
    (operacao.NFCe.status === StatusFiscaisEnum.Autorizada ||
      operacao.NFCe.status === StatusFiscaisEnum.UsoDenegado);
  const temNFeAutorizada =
    !!operacao &&
    !!operacao.NFe &&
    (operacao.NFe.status === StatusFiscaisEnum.Autorizada ||
      operacao.NFe.status === StatusFiscaisEnum.UsoDenegado);

  const deveMostrarTelaEmitirNFCe =
    !!informacoesLoja &&
    informacoesLoja.emitirNFCe &&
    !!operacao &&
    operacao.identificacaoTipoOperacao ===
      IdentificacaoTipoOperacaoEnum.VENDA &&
    !temNFCeAutorizada &&
    planoAtual !== planoTeste;

  const deveMostrarTelaEmitirNFe =
    !!informacoesLoja &&
    informacoesLoja.emitirNFe &&
    !!operacao &&
    operacao.identificacaoTipoOperacao ===
      IdentificacaoTipoOperacaoEnum.VENDA &&
    !temNFeAutorizada &&
    planoAtual !== planoTeste;

  const obterOperacao = useCallback(async () => {
    if (operacaoId) {
      const newOperacao = await obterOperacaoParaFinalizar(operacaoId);

      if (newOperacao) {
        let NFCe;
        let NFCeFormatada;
        const documentoFiscalNFCe = newOperacao.documentosFiscais.find(
          (documentoFiscal) =>
            documentoFiscal.modeloFiscal === ModelosFiscaisEnum.NFCe
        );
        if (documentoFiscalNFCe) {
          NFCe = documentoFiscalNFCe;

          NFCeFormatada = formatarDescricaoDocumentoFiscal({
            status: documentoFiscalNFCe.status,
            numero: documentoFiscalNFCe.numero,
            dataEmissao: documentoFiscalNFCe.dataEmissao,
          });
        }

        let NFe;
        let NFeFormatada;
        const documentoFiscalNFe = newOperacao.documentosFiscais.find(
          (documentoFiscal) =>
            documentoFiscal.modeloFiscal === ModelosFiscaisEnum.NFe
        );
        if (documentoFiscalNFe) {
          NFe = documentoFiscalNFe;

          NFeFormatada = formatarDescricaoDocumentoFiscal({
            status: documentoFiscalNFe.status,
            numero: documentoFiscalNFe.numero,
            dataEmissao: documentoFiscalNFe.dataEmissao,
          });
        }

        setOperacao({ ...newOperacao, NFCe, NFCeFormatada, NFe, NFeFormatada });
      } else {
        history.push(ConstanteRotasPDV.PDV_HOME);
      }
    }
  }, [history, operacaoId]);

  const handleObterOperacao = useCallback(async () => {
    setOperacaoIsLoading(true);

    await obterOperacao();

    setOperacaoIsLoading(false);
  }, [obterOperacao, setOperacaoIsLoading]);

  const latestProps = useRef({ history, obterOperacao, setOperacaoIsLoading });
  useEffect(() => {
    latestProps.current = { history, obterOperacao, setOperacaoIsLoading };
  });
  const operacaoExistente = operacao === undefined;
  const isDevolver =
    operacao?.identificacaoTipoOperacao ===
    IdentificacaoTipoOperacaoEnum.DEVOLUCAO;

  useEffect(() => {
    const obterInformacoesLoja = async () => {
      const newInformacoesLoja = await obterInformacoesFinalizarVenda();

      if (newInformacoesLoja) {
        setInformacoesLoja(newInformacoesLoja);
      } else {
        latestProps.current.history.push(ConstanteRotasPDV.PDV_HOME);
      }
    };

    const obterTodasInformações = async () => {
      latestProps.current.setOperacaoIsLoading(true);

      if (!isDevolver && !operacaoExistente) {
        await obterInformacoesLoja();
        const newAmbienteFiscal = await obterAmbienteFiscal();
        setAmbienteFiscal(newAmbienteFiscal);
      }

      if (operacaoExistente) {
        await latestProps.current.obterOperacao();
      }

      latestProps.current.setOperacaoIsLoading(false);
    };

    obterTodasInformações();
  }, [isDevolver, operacaoExistente]);

  const defaultTabAlreadyPushedRef = useRef(false);

  useEffect(() => {
    if (operacao && informacoesLoja && !defaultTabAlreadyPushedRef.current) {
      defaultTabAlreadyPushedRef.current = true;
      if (operacao?.pixLink || operacao?.boletoLink) {
        handleTabsChange(3);
        return;
      }

      if (defaultModeloFiscal) {
        if (
          defaultModeloFiscal === 'NFCe' &&
          deveMostrarTelaEmitirNFCe &&
          !temNFeAutorizada
        ) {
          handleTabsChange(1);
          return;
        }
        if (defaultModeloFiscal === 'NFe' && deveMostrarTelaEmitirNFe) {
          handleTabsChange(2);
          return;
        }
      }

      if (deveMostrarTelaEmitirNFCe && !temNFeAutorizada) {
        handleTabsChange(1);

        if (!defaultModeloFiscal) {
          setDefaultModeloFiscal('NFCe');
        }
      } else if (deveMostrarTelaEmitirNFe) {
        handleTabsChange(2);

        if (!defaultModeloFiscal) {
          setDefaultModeloFiscal('NFe');
        }
      }
    }
  }, [
    defaultModeloFiscal,
    deveMostrarTelaEmitirNFCe,
    deveMostrarTelaEmitirNFe,
    informacoesLoja,
    operacao,
    setDefaultModeloFiscal,
    temNFeAutorizada,
  ]);

  return (
    <FinalizarContext.Provider
      value={{
        operacao,
        informacoesLoja,
        handleObterOperacao,
        tabIndex,
        handleTabsChange,
        deveMostrarTelaEmitirNFCe,
        deveMostrarTelaEmitirNFe,
        setDefaultModeloFiscal,
        temNFeAutorizada,
        ambienteFiscal,
        boletoZoopFoiGerado,
        setBoletoZoopFoiGerado,
        erroAoGerarBoletoZoop,
        setErroAoGerarBoletoZoop,
      }}
    >
      {operacaoIsLoading && <LoadingPadrao />}
      {children}
    </FinalizarContext.Provider>
  );
}

export function useFinalizarContext(): FinalizarContextProps {
  const context = useContext(FinalizarContext);

  if (!context)
    throw new Error(
      'useFinalizarContext must be used within a FinalizarProvider.'
    );

  return context;
}
