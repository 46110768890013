import React, { useEffect, useRef, useState } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Flex,
  useMediaQuery,
  useDisclosure,
  ModalHeader,
  Text,
  ModalFooter,
  HStack,
  Button,
  Box,
  Checkbox as ChakraCheckbox,
  FormLabel,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { Control, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { AutoSizer, List } from 'react-virtualized';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { NumberInput } from 'components/update/Input/NumberInput';
import { SimpleCard } from 'components/update/Form/SimpleCard';
import { Checkbox } from 'components/update/Checkbox';

import { yupResolver, FormData } from './validationForm';

type EntradaMercadoriaItem = {
  id: string;
  nomeProduto: string;
  participaRateioIcmsSt: boolean;
};

type InformarValorICMSSTModalResponse = {
  valorIcmsSt: number;
};

type InformarValorICMSSTModalProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<InformarValorICMSSTModalResponse> & {
    valorIcmsSt: number;
    casasDecimaisValor: number;
    entradaMercadoriaId: string;
  };

export const InformarValorICMSSTModal = create<
  InformarValorICMSSTModalProps,
  InformarValorICMSSTModalResponse
>(
  ({
    onResolve,
    onReject,
    casasDecimaisValor,
    entradaMercadoriaId,
    valorIcmsSt,
    ...rest
  }) => {
    const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');
    const { isOpen, onClose } = useDisclosure({
      defaultIsOpen: true,
    });
    const formMethods = useForm<FormData>({
      resolver: yupResolver,
      defaultValues: {
        valorIcmsSt,
      },
    });

    const { fields, append, update, remove } = useFieldArray<
      FormData,
      'entradaMercadoriaItensRateio'
    >({
      control: formMethods.control as Control<any>,
      name: 'entradaMercadoriaItensRateio',
      keyName: 'key' as any,
    });
    const [
      isEntradaMercadoriaItensLoading,
      setIsEntradaMercadoriaItensLoading,
    ] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = formMethods.handleSubmit(async (data) => {
      // condição para o valor de ICMSST
      const valorIcmsStAtual = data.valorIcmsSt;
      const participaRateioIcmsSt = data.entradaMercadoriaItensRateio.some(
        (index) => index.participaRateioIcmsSt === true
      );
      // fim condição
      if (participaRateioIcmsSt && valorIcmsStAtual === 0) {
        formMethods.setFocus('valorIcmsSt');
        toast.warning(
          ' Por favor, informe o valor do ICMS ST para os produtos selecionados.'
        );
      } else {
        setIsLoading(true);
        const listaEntradaMercadoriaItemId = data.entradaMercadoriaItensRateio
          .filter((item) => item.participaRateioIcmsSt)
          .map((item) => item.id);

        const response = await api.put<void, ResponseApi>(
          ConstanteEnderecoWebservice.ENTRADA_MERCADORIA_ALTERAR_ITEM_PARTICIPA_RATEIO_ICMSST,
          {
            id: entradaMercadoriaId,
            listaEntradaMercadoriaItemId,
            valorIcmsSt:
              listaEntradaMercadoriaItemId.length > 0 ? data.valorIcmsSt : 0,
            ratear: listaEntradaMercadoriaItemId.length > 0,
          }
        );

        if (response) {
          if (response.avisos) {
            response.avisos.map((aviso: string) => toast.warning(aviso));
          }

          if (response.sucesso) {
            onResolve({
              valorIcmsSt:
                listaEntradaMercadoriaItemId.length > 0 ? data.valorIcmsSt : 0,
            });

            return;
          }
        }

        onResolve({ valorIcmsSt });
      }
    });

    function handleCancelar() {
      onClose();
    }

    function selecionarTodos() {
      fields.forEach((field, index) => {
        update(index, {
          ...field,
          participaRateioIcmsSt: true,
        });
      });
    }

    function handleLimparSelecionados() {
      formMethods.setValue('selecionarTodos', false);

      fields.forEach((field, index) => {
        update(index, {
          ...field,
          participaRateioIcmsSt: false,
        });
      });
    }

    function handleToggleSelecionarTodos(deveSelecionarTodos: boolean) {
      if (deveSelecionarTodos) {
        selecionarTodos();
      }
    }

    function handleAlterarSelecaoItem(index: number, newIsChecked: boolean) {
      if (!newIsChecked) {
        formMethods.setValue('selecionarTodos', false);
      } else {
        const todosSelecionados = fields.every(
          (field, i) => field.participaRateioIcmsSt || i === index
        );

        formMethods.setValue('selecionarTodos', todosSelecionados);
      }

      update(index, {
        ...fields[index],
        participaRateioIcmsSt: newIsChecked,
      });
    }

    const latestProps = useRef({
      remove,
      append,
      setValue: formMethods.setValue,
    });
    useEffect(() => {
      latestProps.current = { remove, append, setValue: formMethods.setValue };
    });

    useEffect(() => {
      async function obterEntradaMercadoriaItens() {
        setIsEntradaMercadoriaItensLoading(true);

        const response = await api.get<
          void,
          ResponseApi<EntradaMercadoriaItem[]>
        >(
          ConstanteEnderecoWebservice.ENTRADA_MERCADORIA_LISTAR_ITENS_PARA_RATEIO_ICMSST,
          {
            params: {
              id: entradaMercadoriaId,
            },
          }
        );

        if (response) {
          if (response.avisos) {
            response.avisos.map((aviso: string) => toast.warning(aviso));
          }

          if (response.sucesso && response.dados) {
            const estaoTodosSelecionados = response.dados.every(
              (item) => item.participaRateioIcmsSt
            );

            latestProps.current.setValue(
              'selecionarTodos',
              estaoTodosSelecionados
            );

            latestProps.current.remove();

            latestProps.current.append(
              response.dados.map((item) => ({ ...item, key: uuidv4() }))
            );
          }
        }

        setIsEntradaMercadoriaItensLoading(false);
      }

      obterEntradaMercadoriaItens();
    }, [entradaMercadoriaId]);

    return (
      <ModalPadraoChakra
        isCentered
        size={!isSmallerThan900 ? 'xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalContent
          marginBottom={{ base: 0, md: '3.75rem' }}
          marginTop={{ base: 0, md: '3.75rem' }}
          h="unset"
          maxW={{ base: '100%', md: '800px' }}
        >
          {isLoading && <LoadingPadrao />}
          <ModalHeader
            mt={isSmallerThan900 ? 12 : undefined}
            mb={isSmallerThan900 ? 8 : undefined}
            px="0"
            mx={{ base: 6, md: 8 }}
            fontSize="sm"
          >
            O ICMS ST será distribuído entre os produtos selecionados de forma
            proporcional ao valor de cada item.
          </ModalHeader>
          <ModalBody p={{ base: 6, md: 8 }} pt="0px !important">
            <FormProvider {...formMethods}>
              <SimpleCard
                bg="gray.50"
                border="1px"
                borderColor="gray.100"
                boxShadow="none"
              >
                <HStack spacing={4}>
                  <FormLabel
                    fontWeight="bold"
                    color="black"
                    fontSize="sm"
                    htmlFor="valorIcmsSt"
                    margin="0"
                  >
                    Informe o valor total do ICMS ST
                  </FormLabel>
                  <Box w="full" maxW="240px">
                    <NumberInput
                      id="valorIcmsSt"
                      name="valorIcmsSt"
                      leftElement="R$"
                      scale={2}
                      placeholder={`0,${'0'.repeat(2)}`}
                    />
                  </Box>
                </HStack>
              </SimpleCard>
              <SimpleCard
                bg="gray.50"
                border="1px"
                borderColor="gray.100"
                boxShadow="none"
                mt={{ base: 6, md: 8 }}
                overflow="hidden"
                position="relative"
              >
                <Flex alignItems="center" justifyContent="space-between" mb="3">
                  <Checkbox
                    id="selecionarTodos"
                    name="selecionarTodos"
                    size="sm"
                    fontWeight="bold"
                    ml="4"
                    onChange={(e) => {
                      const isChecked = e.target.checked;

                      handleToggleSelecionarTodos(isChecked);
                    }}
                  >
                    Selecione os itens que recebem a incidência de ICMS ST
                  </Checkbox>

                  <Button
                    variant="link"
                    size="xs"
                    colorScheme="red"
                    boxShadow="none !important"
                    textDecoration="none"
                    lineHeight="0"
                    _hover={{ textDecoration: 'none' }}
                    onClick={handleLimparSelecionados}
                  >
                    Limpar seleção
                  </Button>
                </Flex>

                {isEntradaMercadoriaItensLoading && <LoadingPadrao />}

                <Box h="40">
                  <AutoSizer>
                    {({ width }) => (
                      <List
                        width={width}
                        height={160} // size "40" (160px)
                        rowCount={fields.length}
                        rowHeight={({ index }) => (index === 0 ? 32 : 40)} // size "8" (32px) (tamanho da linha) + size "2" (8px) (tamanho da margin)
                        rowRenderer={({ index, key, style }) => {
                          const field = fields[index];

                          return (
                            <HStack
                              key={key}
                              as="label"
                              mb="0"
                              mt={index === 0 ? 0 : 2}
                              cursor="pointer"
                              py="2.5"
                              px="4"
                              maxH="8"
                              w="full"
                              bg="white"
                              borderRadius="md"
                              alignItems="center"
                              border="1px"
                              borderColor="gray.100"
                              style={style}
                            >
                              <Box maxH="3">
                                <ChakraCheckbox
                                  isChecked={field.participaRateioIcmsSt}
                                  onChange={(e) => {
                                    const newIsChecked = e.target.checked;

                                    handleAlterarSelecaoItem(
                                      index,
                                      newIsChecked
                                    );
                                  }}
                                  size="sm"
                                  fontWeight="bold"
                                  lineHeight="0"
                                />
                              </Box>

                              <Text
                                lineHeight="none"
                                userSelect="none"
                                fontSize="sm"
                              >
                                {field.nomeProduto}
                              </Text>
                            </HStack>
                          );
                        }}
                      />
                    )}
                  </AutoSizer>
                </Box>
              </SimpleCard>
            </FormProvider>
          </ModalBody>
          <ModalFooter
            justifyContent="flex-end"
            borderTop="1px"
            borderColor="gray.100"
            mx={{ base: 6, md: 8 }}
            px="0"
          >
            <HStack spacing={6}>
              <Button
                borderRadius="md"
                color="gray.400"
                variant="outline"
                minW="96px"
                onClick={handleCancelar}
              >
                Cancelar
              </Button>
              <Button
                colorScheme="aquamarine"
                borderRadius="md"
                minW="100px"
                onClick={handleSubmit}
              >
                Confirmar
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
