import { useRef } from 'react';
import {
  NumberInput,
  NumberInputField,
  NumberInputProps,
  InputRightElement,
} from '@chakra-ui/react';

import CampoPrototipo, {
  IProps as CampoPrototipoProps,
} from 'components/PDV/Geral/CampoPrototipo';

type InputMinutosProps = NumberInputProps &
  Omit<CampoPrototipoProps, 'children'>;

const InputMinutos = ({
  name,
  max,
  defaultValue = 0,
  isRequired = false,
  clampValueOnBlur = true,
  onKeyDownCapture,
  ...rest
}: InputMinutosProps) => {
  const homeKeyWasPressed = useRef(false);

  const parse = (val: string) => val.replace(/^\$/, '');

  return (
    <CampoPrototipo
      {...rest}
      name={name}
      isRequired={isRequired}
      defaultValue={defaultValue}
    >
      {(_, { value = defaultValue, onChange, ref }) => {
        return (
          <>
            <NumberInput
              ref={ref}
              {...rest}
              value={value}
              onChange={(valueString) => {
                if (homeKeyWasPressed.current) {
                  return;
                }

                onChange(parse(valueString));
              }}
              max={max}
              isRequired={isRequired}
              clampValueOnBlur={clampValueOnBlur}
              onKeyDownCapture={(event) => {
                homeKeyWasPressed.current = event.key === 'Home';

                if (onKeyDownCapture) {
                  onKeyDownCapture(event);
                }
              }}
              isValidCharacter={(character: string) =>
                !!character.match(/^[0-9]$/)
              }
            >
              <NumberInputField p={0} pl={4} pr="calc(16px + 7ch)" />
              <InputRightElement w="max" pr={4}>
                Minutos
              </InputRightElement>
            </NumberInput>
          </>
        );
      }}
    </CampoPrototipo>
  );
};

export default InputMinutos;
