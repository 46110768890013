import { setDateMaxHours, setDateMinHours } from 'helpers/data/setHoursDate';

export type FormData = {
  contafinanceiraId: string;
  usuarioId: string;
  dataInicioAbertura: Date;
  dataFimAbertura: Date;
};

export const formDefaultValues = (): FormData => ({
  contafinanceiraId: '',
  usuarioId: '',
  dataInicioAbertura: setDateMinHours(new Date()),
  dataFimAbertura: setDateMaxHours(new Date()),
});
