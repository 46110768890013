import { useCallback, useEffect, useState } from 'react';
import {
  DrawerCloseButton,
  useMediaQuery,
  Text,
  Box,
  Button,
  Icon,
  Flex,
  Skeleton,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { FiCrop, FiDownload } from 'react-icons/fi';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { useProdutosFormularioContext } from 'store/Produtos/ProdutosFormulario';

import Select from 'components/PDV/Select/SelectPadrao';
import { LixeiraIcon } from 'icons';
import DrawerPadraoChakra from 'components/PDV/Drawer/DrawerPadraoChakra';
import { getVariacoesCores } from 'pages/Produtos/Formulario/FunctionsCadastroProduto/ObterCoresCadastradas';

type DataVariacaoProps = {
  produtoCorId: string;
  imagemId: string;
  idVariacao: string;
};

type VariacoesProps = {
  label: string;
  value: string;
};

type BuscaAvancadaProps = {
  isOpen: boolean;
  onClose: () => void;
  linkImagem: string;
  dataImagem: DataVariacaoProps;
};

export const DrawerInformacoesImagem = ({
  isOpen,
  onClose,
  linkImagem,
  dataImagem,
}: BuscaAvancadaProps) => {
  const [listVariacoes, setListVariacoes] = useState<VariacoesProps[]>([]);
  const [onMouseOver, setOnMouseOver] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const {
    idProduto,
    setListImagens,
    listImagens,
    inserirImagemPrincipal,
  } = useProdutosFormularioContext();

  const handleModificarVariacao = useCallback(
    async (value: string) => {
      if (dataImagem.idVariacao === value) {
        return;
      }

      if (listImagens.filter((item) => item.idVariacao === value).length > 14) {
        toast.warning(
          'Essa variação já atingiu o limite máximo de imagens, tente outra por favor'
        );
        return;
      }
      setIsLoading(true);
      const response = await api.patch<void, ResponseApi<boolean>>(
        `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/produto-cores/${dataImagem.produtoCorId}/imagens/${dataImagem.imagemId}/produto-cor/${value}`
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
          setIsLoading(false);
        }

        if (response.sucesso) {
          toast.success(
            'A alteração da variação dessa imagem foi realizada com sucesso'
          );

          setListImagens((prev) =>
            prev.map((prevItem) => ({
              ...prevItem,
              produtoCorId:
                prevItem.id === dataImagem.imagemId
                  ? value
                  : prevItem.produtoCorId,
              idVariacao:
                prevItem.id === dataImagem.imagemId
                  ? value
                  : prevItem.idVariacao,
            }))
          );
          onClose();
          setIsLoading(false);
        }
        setIsLoading(false);
      }
    },
    [dataImagem, idProduto, listImagens, setListImagens, onClose]
  );

  const handleExcluirImagem = useCallback(async () => {
    setIsLoading(true);
    const response = await api.delete<void, ResponseApi<boolean>>(
      `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/produto-cores/${dataImagem.produtoCorId}/imagens/${dataImagem.imagemId}`
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
        setIsLoading(false);
      }

      if (response.sucesso) {
        toast.success('A imagem foi removida com sucesso');

        const newListImagens = [...listImagens];

        const index = newListImagens.findIndex(
          (itemImagem) => itemImagem.id === dataImagem.imagemId
        );

        newListImagens.splice(index, 1);

        setListImagens(newListImagens);
        inserirImagemPrincipal(newListImagens);
        onClose();

        setIsLoading(false);
      }
      setIsLoading(false);
    }
  }, [
    idProduto,
    dataImagem,
    listImagens,
    setListImagens,
    inserirImagemPrincipal,
    onClose,
  ]);

  const handleDownloadImagem = () => {
    fetch(linkImagem)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'imagem.png');
        link.click();
      })
      .catch(() => {
        toast.warning('Erro ao baixar a imagem');
      });
  };

  useEffect(() => {
    async function getCoresResponse() {
      setIsLoading(true);
      const response = await getVariacoesCores(idProduto);

      setListVariacoes(
        response.map(
          (itemVariacao) =>
            ({
              label: itemVariacao.cor.descricao,
              value: itemVariacao.id,
            } as VariacoesProps)
        )
      );
      setIsLoading(false);
    }
    if (isOpen) {
      getCoresResponse();
    }
  }, [idProduto, isOpen]);

  return (
    <DrawerPadraoChakra
      size={isLargerThan900 ? ' lg' : 'full'}
      onClose={onClose}
      isOpen={isOpen}
    >
      <DrawerContent
        style={{
          width: isLargerThan900 ? '400px' : '',
        }}
        bg="gray.50"
        pr="5px"
        overflow="hidden"
        boxShadow="-6px 0px 20px #00000029"
      >
        <DrawerHeader>
          <Text color="violet.500" fontSize="md">
            Informações
          </Text>

          <DrawerCloseButton size="sm" />
        </DrawerHeader>
        <DrawerBody>
          <Box
            onMouseOverCapture={() => setOnMouseOver(true)}
            onMouseLeave={() => setOnMouseOver(false)}
            boxShadow="0px 3px 6px #00000029"
            mb="28px"
            boxSize="360px"
            borderRadius="2px"
            backgroundRepeat="no-repeat"
            backgroundSize="contain"
            backgroundPosition="center"
            style={{
              backgroundImage: onMouseOver
                ? `linear-gradient(360deg, rgba(0,0,0,0.6) 0%, rgba( 0, 0, 0, 0.0) 50%), url(${linkImagem})`
                : `url(${linkImagem})`,
            }}
          >
            {onMouseOver && (
              <Flex
                color="white"
                p="10px"
                h="full"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Flex
                  bg="purple.50"
                  cursor="pointer"
                  mr="16px"
                  w="40px"
                  h="32px"
                  borderRadius="16px"
                  p="4px"
                  alignItems="center"
                  justifyContent="center"
                  onClick={handleDownloadImagem}
                  color="purple.500"
                >
                  <FiDownload size={20} />
                </Flex>
              </Flex>
            )}
          </Box>
          {isLoading ? (
            <Skeleton height="32px" />
          ) : (
            <>
              {listVariacoes.length > 0 && (
                <Select
                  name="variacaoImagem"
                  id="variacaoImagem"
                  onGetValue={(e) => {
                    handleModificarVariacao(e.value as string);
                  }}
                  label="Variação de cor"
                  isLoading={isLoading}
                  valueDefault={dataImagem.idVariacao}
                  options={listVariacoes}
                />
              )}
            </>
          )}
          <Flex mt="130px" mb="10px" w="full" justifyContent="center">
            <Button
              w={['full', 'full', '170px']}
              variant={isLoading ? 'solid' : 'outlineDefault'}
              isDisabled={isLoading}
              isLoading={isLoading}
              onClick={handleExcluirImagem}
              colorScheme="gray"
              leftIcon={<Icon as={LixeiraIcon} />}
            >
              Excluir imagem
            </Button>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </DrawerPadraoChakra>
  );
};
