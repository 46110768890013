import React from 'react';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

import FormaPagamentoListar from '../pages/FormaPagamento/Listar';
import FormaPagamentoCadastrar from '../pages/FormaPagamento/Formulario/Cadastrar';
import FormaPagamentoAlterar from '../pages/FormaPagamento/Formulario/Alterar';
import FormaPagamentoVisualizar from '../pages/FormaPagamento/Formulario/Visualizar';

const FormaPagamentoRoutes = [
  <LayoutGuard
    key="forma-pagto-listar"
    permissaoFuncionalidade={ConstanteFuncionalidades.FORMA_PAGTO_LISTAR}
    component={FormaPagamentoListar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Formas de Pagamento',
      },
    ]}
    path={ConstanteRotas.FORMA_PAGTO}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="forma-pagto-cadastrar"
    permissaoFuncionalidade={ConstanteFuncionalidades.FORMA_PAGTO_CADASTRAR}
    component={FormaPagamentoCadastrar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Formas de Pagamento',
        path: ConstanteRotas.FORMA_PAGTO,
      },
      {
        title: 'Novo',
      },
    ]}
    path={ConstanteRotas.FORMA_PAGTO_CADASTRAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="forma-pagto-alterar"
    permissaoFuncionalidade={ConstanteFuncionalidades.FORMA_PAGTO_ALTERAR}
    component={FormaPagamentoAlterar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Formas de Pagamento',
        path: ConstanteRotas.FORMA_PAGTO,
      },
      {
        title: 'Editar',
      },
    ]}
    path={ConstanteRotas.FORMA_PAGTO_ALTERAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="forma-pagto-visualizar"
    permissaoFuncionalidade={ConstanteFuncionalidades.FORMA_PAGTO_VISUALIZAR}
    component={FormaPagamentoVisualizar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Formas de Pagamento',
        path: ConstanteRotas.FORMA_PAGTO,
      },
      {
        title: 'Visualizar',
      },
    ]}
    path={ConstanteRotas.FORMA_PAGTO_VISUALIZAR}
    exact
    meta={{ auth: true }}
  />,
];

export default FormaPagamentoRoutes;
