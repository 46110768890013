import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export type FormData = {
  nome: string;
  ativo: boolean;
  localEstoqueDestinoId?: string;
};

export type InativarLocalEstoqueProps = {
  id: string;
  localEstoqueDestinoId?: string;
};

export type AlterarLocalEstoqueResponse = {
  dadosSucesso: null | string;
  erro: null | number;
};

export type CadastrarLocalEstoqueProps = {
  isRouteSmartPos?: boolean;
  callbackCadastrarSucesso?: (local: { nome: string; ativo: boolean }) => void;
};

const schema = yup.object().shape({
  nome: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .max(20, ConstanteMensagemValidacao.MAX_LENGTH_20),
  ativo: yup.boolean().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);
