import { setDateMaxHours, setDateMinHours } from 'helpers/data/setHoursDate';

export type FormData = {
  numeroPedido?: string | null;
  numeroVenda?: string | null;
  cliente?: string;
  origem?: string | null;
  integracaoSituacaoPedidoId?: string[];
  situacaoVinculacao?: number | null;
  dataEmissaoInicio: Date;
  dataEmissaoFim: Date;
  integracaoSituacaoPedidoIdFilter?: string[];
  dataEmissaoFilterInicio?: Date;
  dataEmissaoFilterFim?: Date;
};

export type ListaIntegracaoPendenciaPedidoProps = {
  id: string;
  cadastroPlataformaId: string;
  descricao: string;
  tipoCadastro: number;
  descricaoProduto: string;
  tipoPendencia: number;
  integracaoPedidoId: string;
  integracaoPedido: null | string;
  possuiPendencia: boolean;
  cadastroSistemaId: string;
  tipoProduto: number;
  clienteFornecedorId: string | null;
};

export type ListVendasProps = {
  dataEmissao: Date;
  numeroVenda: string;
  cliente: string;
  apelido: string;
  origem: string;
  valorTotal: number;
  numeroPedido: string;
  status: number;
  statusNotaFiscal: number;
  situacaoPedidoZendar: string;
  codigoRastreioEnviado: boolean;
  endereco: string;
  numeroNotaFiscal: string;
  situacao: number;
  integracaoPedidoId: string;
  operacaoId: string;
  identificacaoTipoOperacao: number;
  isChecked: boolean;
  motivoNotaFiscal: string;
  listaIntegracaoPendenciaPedido?: ListaIntegracaoPendenciaPedidoProps[];
  listaMovimentacaoFinanceira?: {
    descricaoParcelas: string;
    descricaoFormaPagto: string;
  }[];
  listaNotaFiscalStatus?: {
    status: number;
  }[];
  possuiAlertaPlataforma: boolean;
};

const dataAtual = new Date();
const backdate = new Date(dataAtual.setDate(dataAtual.getDate() - 7));

export const formDefaultValues = {
  dataEmissaoInicio: setDateMinHours(backdate),
  dataEmissaoFim: setDateMaxHours(new Date()),
  cliente: '',
  numeroPedido: '0',
  numeroVenda: '0',
};
