export const IdentificacaoEtapasTray = {
  GUIA_INTEGRACAO: 0,
  PORTAL_CLIENTE: 1,
  AUTENTICACAO: 2,
  CANAL_VENDAS: 3,
  VENDEDOR: 4,
  COMISSAO_VENDAS: 5,
  LOCAL_ESTOQUE: 6,
  TABELA_PRECO: 7,
  TIPO_CADASTRO: 8,
  BUSCAR_PRODUTO: 9,
  IMPORTANDO: 10,
  LISTA_PRODUTOS_SITE: 11,
  LISTA_PRODUTO_SISTEMA: 12,
  ENVIANDO_ZENDAR: 13,
  ETAPA_FINALIZADA: 14,
  CADASTRAR_TABELA_PRECO: 15,
};
