export type StatusFiscais = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

const StatusFiscaisEnum = {
  RetornoIndisponivel: 0,
  EmDigitacao: 1,
  EmProcessamento: 2,
  Rejeitada: 3,
  Autorizada: 4,
  Cancelada: 5,
  CartaCorrecao: 6,
  UsoDenegado: 7,
  Inutilizada: 8,

  properties: {
    0: { name: 'Retorno indisponível', value: 0 },
    1: { name: 'Em digitação', value: 1 },
    2: { name: 'Em processamento', value: 2 },
    3: { name: 'Rejeitada', value: 3 },
    4: { name: 'Autorizada', value: 4 },
    5: { name: 'Cancelada', value: 5 },
    6: { name: 'Carta de correção', value: 6 },
    7: { name: 'Uso denegado', value: 7 },
    8: { name: 'Inutilizada', value: 8 },
  },
};

export const CoresStatusFiscalEnum = {
  RETORNO_INDISPONIVEL: 'red.400',
  AUTORIZADA: 'aquamarine.200',
  CANCELADA: 'red.400',
  CARTA_DE_CORRECAO: 'yellow.400',
  EM_DIGITACAO: 'yellow.400',
  EM_PROCESSAMENTO: 'yellow.400',
  INUTILIZADA: 'red.400',
  REJEITADA: 'red.400',
  USO_DENEGADO: 'aquamarine.200',
};

export default StatusFiscaisEnum;
