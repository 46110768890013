import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

const schema = yup.object().shape({
  descricao: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .max(45, 'O campo descrição não pode conter mais que 45 caracteres.'),
  sigla: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .max(6, 'O campo sigla não pode conter mais que 6 caracteres.'),
  unidadeTributavel: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .max(
      6,
      'O campo unidade tributável não pode conter mais que 6 caracteres.'
    ),
  ativo: yup.boolean().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);

export const useForm = useFormInstance;
