import { useCallback, useImperativeHandle, useState, useEffect } from 'react';
import { VStack, Text, Box } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { IdentificacaoEtapasTray } from 'constants/enum/IdentificacaoEtapasTray';
import { useTrayEtapasContext } from 'store/Tray';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import TipoTabelaPrecoEnum from 'constants/enum/tipoTabelaPrecoEnum';

import { TabelaPrecosIcon } from 'icons';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';

import { yupResolver, FormData } from './validationForms';
import { Header } from '../Layout/Header';

type Options = {
  label: string;
  value: string;
};

export type OptionResponseProps = {
  id: string;
  nome: string;
};

export const CadastrarTabelaPreco = () => {
  const [tabelaPreco, setTabelaPreco] = useState<Options[]>([]);

  const { ref, setActiveStep, setIsLoading } = useTrayEtapasContext();

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues: {
      tabelaPreco: null,
    },
  });

  const { handleSubmit: onSubmit } = formMethods;

  const handleAvancar = useCallback(() => {
    setActiveStep(IdentificacaoEtapasTray.TIPO_CADASTRO);
  }, [setActiveStep]);

  const handleVoltar = useCallback(() => {
    setActiveStep(IdentificacaoEtapasTray.TABELA_PRECO);
  }, [setActiveStep]);

  const getTabelaPreco = useCallback(async () => {
    const response = await api.get<void, ResponseApi<OptionResponseProps[]>>(
      `${ConstanteEnderecoWebservice.TABELA_PRECO_LISTAR_TABELAS_PRECO}?tipo=${TipoTabelaPrecoEnum.INTEGRACAO}`
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso && response.dados) {
        setTabelaPreco(
          response.dados.map((tabelasPreco) => {
            return {
              label: tabelasPreco.nome,
              value: tabelasPreco.id,
            } as Options;
          })
        );
      }
    }
  }, []);

  const handleSubmit = onSubmit(async (data) => {
    setIsLoading(true);
    const response = await api.put<void, ResponseApi<OptionResponseProps[]>>(
      `${ConstanteEnderecoWebservice.INTEGRACAO_TRAY_COPIAR_TABELA_PRECO}/${
        data.tabelaPreco || ''
      }`
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso) {
        handleAvancar();
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  });

  useImperativeHandle(ref, () => ({
    handleAvancar: handleSubmit,
    handleVoltar,
  }));

  useEffect(() => {
    getTabelaPreco();
  }, [getTabelaPreco]);

  return (
    <>
      <Header
        title="Criar uma nova tabela de preços"
        handleVoltar={handleVoltar}
        icon={TabelaPrecosIcon}
      />
      <FormProvider {...formMethods}>
        <VStack
          pl={['10px', '10px', '20px']}
          pr={['10px', '10px', '20px']}
          fontSize="14px"
          w="full"
          alignItems="left"
          spacing="24px"
        >
          <Text
            color="primary.50"
            mb="4px"
            textAlign="left"
            letterSpacing="0px"
          >
            Selecione abaixo uma tabela para servir como base de preços. A
            tabela selecionada servirá apenas como referência para a criação da
            nova tabela Tray.
          </Text>
          <Box color="primary.50">
            <Text mb="4px" textAlign="left" letterSpacing="0px">
              Todos os produtos publicados na Tray através da integração com o
              sistema serão adicionados automaticamente nessa tabela. Você pode
              alterar o preço de um produto especificamente para a venda na Tray
              utilizando dois caminhos:
            </Text>
            <Text color="primary.50" ml="20px">
              - Através do cadastro do produto na aba “preços” ;
            </Text>
            <Text color="primary.50" ml="20px">
              - Acesse diretamente a nova tabela, no menu configurações {'> '}
              Tabela de preços.
            </Text>
          </Box>

          <SelectPadrao
            id="tabelaPreco"
            name="tabelaPreco"
            label="Informe a tabela que será utilizada como referência:"
            asControlledByObject={false}
            colSpan={12}
            options={tabelaPreco}
            placeholder="Selecione a tabela de preço"
          />
        </VStack>
      </FormProvider>
    </>
  );
};
