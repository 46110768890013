import React, { useState } from 'react';
import { Flex, Button, HStack, Divider, Box } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteRotasPDV from 'constants/rotasPDV';
import { useFinalizarContext } from 'store/PDV/Finalizar';
import ModelosFiscaisEnum from 'constants/enum/fiscal/modelosFiscais';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { NFCeHeader } from 'components/EmitirNotaFiscal/NFCe';
import { NFeHeader } from 'components/EmitirNotaFiscal/NFe';
import {
  BoxRejeicao,
  RejeicaoInterface,
} from 'components/EmitirNotaFiscal/BoxRejeicao';
import { useSignalRContext } from 'store/SignalR';
import TipoCertificadoEnum from 'constants/enum/fiscal/tipoCertificado';

interface EmProcessamentoProps {
  modeloFiscal: number;
  documentoFiscalId: string;
}

const EmProcessamento = ({
  modeloFiscal,
  documentoFiscalId,
}: EmProcessamentoProps) => {
  const history = useHistory();
  const {
    handleObterOperacao,
    handleTabsChange,
    ambienteFiscal,
  } = useFinalizarContext();
  const { hubConnection, joinGroup } = useSignalRContext();

  const [isLoading, setIsLoading] = useState(false);
  const [requestError, setRequestError] = useState<RejeicaoInterface>();

  const handlePushNovoPedido = async () => {
    history.push(ConstanteRotasPDV.PDV_HOME);
  };

  const handlePushConsultar = () => {
    history.push(ConstanteRotasPDV.PDV_CONSULTAR_OPERACOES);
  };

  const handleVerificarSituacao = async () => {
    setIsLoading(true);

    joinGroup(`${documentoFiscalId}_transmitindo-consultar-protocolo`);

    const response = await api.post<void, ResponseApi<number | undefined>>(
      `${ConstanteEnderecoWebservice.NOTA_FISCAL_CONSULTAR_PROTOCOLO}/${documentoFiscalId}`
    );

    if (response.sucesso) {
      if (response.dados && response.dados === Number(TipoCertificadoEnum.A3)) {
        const timeout = window.setTimeout(
          async () => {
            setIsLoading(false);

            setRequestError({
              Mensagem:
                'A comunicação com o certificado digital A3 falhou, verifique se o certificado digital está conectado no computador e se o aplicativo Módulo desktop está sendo executado.',
            });

            await handleObterOperacao();
          },
          1000 * 60 // 1 minuto
        );

        hubConnection.off('sucesso-transmissao-a3');
        hubConnection.on('sucesso-transmissao-a3', async () => {
          window.clearTimeout(timeout);

          setIsLoading(false);
          toast.success('A nota fiscal foi autorizada com sucesso.');
          await handleObterOperacao();
        });

        hubConnection.off('rejeicao-transmissao-a3');
        hubConnection.on('rejeicao-transmissao-a3', async (message: string) => {
          window.clearInterval(timeout);

          setIsLoading(false);

          try {
            const rejeicao = JSON.parse(message) as RejeicaoInterface;

            setRequestError(rejeicao);
          } catch {
            setRequestError({ Mensagem: message });
          }

          await handleObterOperacao();
        });

        return;
      }

      toast.success('A nota fiscal foi autorizada com sucesso.');
    }

    if (response.avisos) {
      try {
        const rejeicao = JSON.parse(response.avisos[0]) as RejeicaoInterface;

        setRequestError(rejeicao);
      } catch {
        setRequestError({ Mensagem: response.avisos[0] });
      }
    } else {
      handleTabsChange(0);
    }

    await handleObterOperacao();
  };

  return (
    <>
      {isLoading && <LoadingPadrao />}

      <Flex flexDirection="column">
        {modeloFiscal === ModelosFiscaisEnum.NFCe && (
          <NFCeHeader ambienteFiscal={ambienteFiscal} />
        )}
        {modeloFiscal === ModelosFiscaisEnum.NFe && (
          <NFeHeader ambienteFiscal={ambienteFiscal} />
        )}
        {requestError ? (
          <BoxRejeicao rejeicao={requestError} modeloFiscal={modeloFiscal} />
        ) : (
          <Box mx={10}>
            <Divider orientation="horizontal" />
          </Box>
        )}
        <HStack justifyContent="center" spacing={6} my={8} mx={10}>
          <Button
            size="sm"
            minW="120px"
            variant="outline"
            onClick={handlePushConsultar}
          >
            Consultar
          </Button>
          <Button
            size="sm"
            minW="120px"
            variant="outline"
            onClick={handlePushNovoPedido}
          >
            Novo pedido
          </Button>
          <Button
            size="sm"
            minW="120px"
            colorScheme="secondary"
            onClick={handleVerificarSituacao}
          >
            Verificar situação
          </Button>
        </HStack>
      </Flex>
    </>
  );
};

export default EmProcessamento;
