const CodigoProdutoFiscalEnum = {
  Sku: 0,
  Codigo_GTIN_EAN: 1,
  CodigoBarrasInterno: 2,
  CodigoExterno: 3,

  properties: {
    1: { name: 'Código SKU', value: 0 },
    2: { name: 'GTIN/EAN', value: 1 },
    3: { name: 'Código de barras interno', value: 2 },
    4: { name: 'Código externo', value: 3 },
  },
};

export default CodigoProdutoFiscalEnum;
