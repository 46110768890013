const Avatar = {
  sizes: {
    xs: {
      container: {
        width: 7,
        height: 7,
        fontSize: `calc(7 / 2.5)`,
      },
      excessLabel: {
        width: 7,
        height: 7,
      },
      label: {
        fontSize: `calc(7 / 2.5)`,
      },
    },
  },
};

export default Avatar;
