import { useCallback, useState } from 'react';
import { Button, Flex, FormLabel, GridItem } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { StatusPesquisaClientesFornecedor } from 'constants/enum/statusPesquisaClientesFornecedor';
import { formatOptionsSelectClient } from 'helpers/format/formatSelectClient';
import ConsignacaoSituacaoEnum from 'constants/enum/consignacaoSituacaoEnum';
import ConsignacaoTipoRelatorioEnum from 'constants/enum/consignacaoTipoRelatorioEnum';
import { ImprimirPDF } from 'helpers/impressoes/imprimirPDF';

import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import InputDateRange from 'components/PDV/InputDateRange';
import AsyncSelect from 'components/PDV/Select/AsyncSelectPadrao';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import {
  formDefaultValues,
  ClienteOptionResponse,
  FormData,
  SelectProdutoCorResponse,
  yupResolver,
} from './Types/validationForm';

export const RelatorioConsignacao = () => {
  const [isLoading, setIsLoading] = useState(false);

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues: formDefaultValues,
  });

  const {
    formState: { errors },
  } = formMethods;

  const handleGetClientes = useCallback(async (inputValue: string) => {
    const response = await api.get<void, ResponseApi<ClienteOptionResponse[]>>(
      ConstanteEnderecoWebservice.CLIENTE_FORNECEDOR_LISTAR_SELECT,
      {
        params: {
          filtroTipoCadastroPessoa: StatusPesquisaClientesFornecedor.CLIENTES,
          cpfCnpjNomeApelidoCodigoExterno: inputValue,
        },
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        const dados = response.dados.map((client) => {
          const option = formatOptionsSelectClient(client);
          return option;
        });
        return dados;
      }
    }
    return [];
  }, []);

  const getProdutoOptions = useCallback(async (inputValue: string) => {
    const response = await api.get<
      void,
      ResponseApi<SelectProdutoCorResponse[]>
    >(
      ConstanteEnderecoWebservice.PRODUTO_COR_TAMANHO_LISTAR_SELECT_NOTA_FISCAL,
      {
        params: { nomeSkuCodigoExternoBarrasGtinEan: inputValue },
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        const data = response.dados.map((option) => {
          return {
            label: option.nome,
            value: option.id,
          };
        });

        return data;
      }
    }

    return [];
  }, []);

  const handleImprimirRelatoriovenda = formMethods.handleSubmit(
    async (filtros) => {
      setIsLoading(true);
      const response = await api.get<void, ResponseApi<string>>(
        ConstanteEnderecoWebservice.RELATORIO_CONSIGNACAO,
        {
          params: {
            periodoInicio: filtros.dataInicio,
            periodoFim: filtros.dataFim,
            situacao: filtros.situacao,
            clienteFornecedorId: filtros.cliente?.value,
            produtoCorTamanhoId: filtros.produtos,
            tipoRelatorioConsignacao: filtros.tipoRelatorioConsignacao,
          },
        }
      );

      if (response) {
        setIsLoading(false);
        if (response.sucesso && response.dados) {
          ImprimirPDF(response.dados, 'relatorioConsignacao');
        }

        if (response?.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }
      }

      setIsLoading(false);
    }
  );

  return (
    <FormProvider {...formMethods}>
      {isLoading && <LoadingPadrao />}
      <SimpleGridForm
        bg="white"
        pt={['20px', '20px', '40px']}
        pl={['20px', '20px', '40px']}
        pb={['20px', '20px', '40px']}
        pr={['20px', '20px', '40px']}
        borderRadius="5px"
        boxShadow="0px 0px 6px #00000034"
      >
        <GridItem mt="3px" colSpan={{ base: 12, md: 6, lg: 6, xl: 6 }}>
          <SelectPadrao
            id="tipoRelatorioConsignacao"
            name="tipoRelatorioConsignacao"
            label="Tipo relatório"
            placeholder="Informe a situação do relatório"
            options={ConsignacaoTipoRelatorioEnum.properties.map(
              (situacao) => ({
                value: situacao.value,
                label: situacao.name,
              })
            )}
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, md: 6, lg: 6, xl: 6 }}>
          <FormLabel mb="0" fontSize="sm" color="black">
            Período
          </FormLabel>
          <InputDateRange
            isRequired
            borderColor="gray.100"
            borderRadius="md"
            placeholder="dd/mm/aaaa"
            startDateName="dataInicio"
            endDateName="dataFim"
            inputValueIsSmaller={false}
          />
          {errors && (
            <FormLabel mb="0" fontSize="sm" color="red.500">
              {errors?.dataInicio?.message}
            </FormLabel>
          )}
        </GridItem>
        <GridItem mt="3px" colSpan={{ base: 12, md: 4, lg: 4, xl: 4 }}>
          <SelectPadrao
            id="situacao"
            name="situacao"
            label="Situação"
            placeholder="Informe a situação do relatório"
            options={ConsignacaoSituacaoEnum.properties.map((situacao) => ({
              value: situacao.value,
              label: situacao.name,
            }))}
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, md: 4, lg: 4, xl: 4 }}>
          <AsyncSelect
            id="cliente"
            isClearable
            label="Cliente"
            name="cliente"
            placeholder="Informe o cliente"
            handleGetOptions={handleGetClientes}
            asControlledByObject
            shouldAppearTheAddress
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, md: 4, lg: 4, xl: 4 }}>
          <AsyncSelect
            id="produtos"
            isClearable
            label="Produto"
            name="produtos"
            placeholder="Informe o produto"
            handleGetOptions={getProdutoOptions}
          />
        </GridItem>
      </SimpleGridForm>
      <Flex
        mt={['20px', '20px', '40px']}
        justifyContent="flex-end"
        alignItems="center"
      >
        <Button
          h="39px"
          color="gray.700"
          colorScheme="secondary"
          borderRadius="md"
          onClick={() => handleImprimirRelatoriovenda()}
          width={{ base: 'full', md: '220px' }}
        >
          Gerar relatório
        </Button>
      </Flex>
    </FormProvider>
  );
};
