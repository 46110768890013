import { toast } from 'react-toastify';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';

import { ListaCidadesInterface } from 'components/PDV/Select/SelectCidade';

export async function handleGetCidade(
  cidadeId?: number,
  codigoIBGE?: string,
  nomeCidade?: string
) {
  const response = await api.get<void, ResponseApi<ListaCidadesInterface>>(
    ConstanteEnderecoWebservice.CIDADE_OBTER_CACHE,
    {
      params: { cidadeId, codigoIBGE, nomeCidade },
    }
  );

  if (response) {
    if (response.avisos) {
      response.avisos.forEach((item: string) => toast.warning(item));
    }

    if (response.sucesso) {
      return response?.dados;
    }
  }

  return {} as ListaCidadesInterface;
}
