import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import { EnumNumeracaoContas } from 'constants/enum/enumNumeracaoContas';
import { EnumExibicaoContasDepartamento } from 'constants/enum/enumExibicaoContasDepartamento';

import OptionType from 'types/optionType';

export const useTabDadosCadastro = () => {
  const { watch } = useFormContext();
  const { utilizarSubConta, contasBloqueadas } = watch();

  const handleAdicionarContaBloqueada = (label: string) => {
    const newValue = Number(label);

    const accountAlreadyAdded = contasBloqueadas.some(
      (conta: OptionType<number>) => conta.value === newValue
    );

    if (
      !newValue ||
      newValue < 1 ||
      newValue > 9999 ||
      !Number.isInteger(newValue) ||
      accountAlreadyAdded
    ) {
      toast.warning(
        accountAlreadyAdded
          ? 'Essa conta já foi adicionada'
          : 'Informe um valor inteiro entre 1 e 9999'
      );

      return undefined;
    }

    const newOption = {
      label: newValue.toString(),
      value: newValue,
    };

    return newOption;
  };

  return {
    handleAdicionarContaBloqueada,
    utilizarSubConta,
    EnumNumeracaoContas,
    EnumExibicaoContasDepartamento,
  };
};
