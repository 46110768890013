import { Flex, Text } from '@chakra-ui/react';

interface ClienteCupomVoucherProps {
  nomeCliente: string;
}

export function ClienteCupomVoucher({ nomeCliente }: ClienteCupomVoucherProps) {
  return (
    <Flex
      padding="2"
      paddingBottom="1"
      borderBottom="2px solid black"
      justifyContent="flex-start"
      mt="3"
    >
      <Text
        fontSize="xs"
        fontWeight="extrabold"
        color="black"
        letterSpacing="tight"
      >
        Cliente:
        <Text
          as="span"
          fontSize="xs"
          fontWeight="extrabold"
          color="black"
          letterSpacing="tight"
          ml="1"
        >
          {nomeCliente}
        </Text>
      </Text>
    </Flex>
  );
}
