import React, { useCallback, useState, useRef } from 'react';
import { GridItem, Tr, Td, Box } from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import auth from 'modules/auth';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import getOptionsByEnum from 'helpers/format/getOptionsByEnum';
import StatusConsultaEnum from 'constants/enum/statusConsulta';
import { shallowEqual } from 'helpers/validation/shallowEqual';
import ConstanteFuncionalidades from 'constants/permissoes';
import EnumStatusErroLocalEstoque from 'constants/enum/enumStatusErroOperacao';

import { ModalErroInativarEstoque } from 'components/update/Modal/ModalErroInativarEstoque';
import { ModalTransferirEstoque } from 'components/update/Modal/ModalTransferirEstoque';
import {
  PagedTable,
  PagedTableForwardRefData,
} from 'components/update/Table/PagedTable';
import { PaginationData } from 'components/update/Pagination';
import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import { StatusCircle } from 'components/update/Table/StatusCircle';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { SearchInput } from 'components/update/Input/SearchInput';
import { FilterSelect } from 'components/update/Select/FilterSelect';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { ModalConfirmacaoInativar } from 'components/Modal/ModalConfirmacaoInativar';
import { Link } from 'components/update/Link';
import { ButtonCadastrarNovo } from 'components/Layout/ButtonCadastrarNovo';

import {
  InativarLocalEstoqueProps,
  AlterarLocalEstoqueResponse,
} from '../Formulario/validationForm';

type FormData = {
  nome?: string;
  statusConsulta: number;
};

const formDefaultValues: FormData = {
  nome: '',
  statusConsulta: StatusConsultaEnum.ATIVOS,
};

type LocalEstoque = {
  id: string;
  nome: string;
  ativo: boolean;
  padraoSistema?: boolean;
};

const Listar = () => {
  const history = useHistory();

  const formMethods = useForm<FormData>({
    defaultValues: formDefaultValues,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [itemsTotalCount, setItemsTotalCount] = useState(0);
  const [columnsData, setColumnsData] = useState<LocalEstoque[]>([]);
  const [currentFilters, setCurrentFilters] = useState<FormData>(
    formDefaultValues
  );

  const permissaoLocalEstoqueAlterar = auth.possuiPermissao(
    ConstanteFuncionalidades.LOCAL_ESTOQUE_ALTERAR
  );
  const permissaoLocalEstoqueVisualizar = auth.possuiPermissao(
    ConstanteFuncionalidades.LOCAL_ESTOQUE_VISUALIZAR
  );

  const hasProdutoComEstoque =
    EnumStatusErroLocalEstoque.POSSUI_PRODUTOS_ESTOQUE;

  const pagedTableRef = useRef<PagedTableForwardRefData>(null);

  const filtersSubmit = formMethods.handleSubmit((data) => {
    const filtersIsDirty = !shallowEqual(data, currentFilters);

    if (filtersIsDirty) {
      setCurrentFilters(data);
    }
  });

  const loadColumnsData = useCallback(
    async (paginationData: PaginationData) => {
      setIsLoading(true);

      const params = {
        nome: currentFilters.nome,
        statusConsulta: currentFilters.statusConsulta,
      };

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<LocalEstoque>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.LOCAL_ESTOQUE_LISTAR_PAGINADO,
          paginationData
        ),
        { params }
      );

      if (response) {
        if (response.sucesso) {
          setItemsTotalCount(response.dados.total || 0);

          setColumnsData(response.dados.registros || []);
        }
      }

      setIsLoading(false);
    },
    [currentFilters]
  );

  function handlePushCadastrarNovo() {
    history.push(ConstanteRotas.LOCAL_ESTOQUE_CADASTRAR);
  }

  function handlePushAlterarCadastro(id: string) {
    history.push(
      SubstituirParametroRota(ConstanteRotas.LOCAL_ESTOQUE_ALTERAR, 'id', id)
    );
  }

  function getNomeLinkHref(id: string) {
    let href = ConstanteRotas.LOCAL_ESTOQUE;

    if (permissaoLocalEstoqueAlterar.permitido) {
      href = SubstituirParametroRota(
        ConstanteRotas.LOCAL_ESTOQUE_ALTERAR,
        'id',
        id
      );
    } else if (permissaoLocalEstoqueVisualizar.permitido) {
      href = SubstituirParametroRota(
        ConstanteRotas.LOCAL_ESTOQUE_VISUALIZAR,
        'id',
        id
      );
    }

    return href;
  }

  const inativarLocalEstoque = async (data: InativarLocalEstoqueProps) => {
    setIsLoading(true);
    const response = await api.put<
      void,
      ResponseApi<AlterarLocalEstoqueResponse>
    >(ConstanteEnderecoWebservice.LOCAL_ESTOQUE_INATIVAR, data);

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        const { dadosSucesso, erro } = response.dados;
        if (dadosSucesso && !erro) {
          toast.success('O cadastro foi inativado com sucesso.');
          if (pagedTableRef.current) {
            pagedTableRef.current.reload();
          }
        } else if (erro === hasProdutoComEstoque) {
          const { id } = data;
          ModalErroInativarEstoque({
            callback: async (okInativar?: boolean) => {
              if (okInativar) {
                const {
                  transferir,
                  localEstoqueIdDestino,
                } = await ModalTransferirEstoque({
                  localEstoqueIdOrigem: id,
                });

                if (transferir) {
                  if (localEstoqueIdDestino) {
                    const newData = {
                      id,
                      localEstoqueDestinoId: localEstoqueIdDestino,
                    };
                    inativarLocalEstoque(newData);
                  }
                }
              }
            },
          });
        }
      }
    }

    setIsLoading(false);
  };

  async function handleExcluirLocalEstoque(
    localEstoqueId: string,
    ativo: boolean
  ) {
    ModalConfirmacaoExcluir({
      callback: async (ok: boolean) => {
        if (ok) {
          setIsLoading(true);

          const response = await api.delete<void, ResponseApi>(
            ConstanteEnderecoWebservice.LOCAL_ESTOQUE_EXCLUIR,
            {
              params: { id: localEstoqueId },
            }
          );
          if (response) {
            if (response?.avisos) {
              response.avisos.forEach((aviso: string) => toast.warning(aviso));
            }
            if (response?.sucesso) {
              toast.success('O cadastro foi removido com sucesso.');

              setItemsTotalCount((prev) => prev - 1);

              if (pagedTableRef.current) {
                pagedTableRef.current.reload();
              }
            } else {
              ModalConfirmacaoInativar({
                response,
                rotaWebService:
                  ConstanteEnderecoWebservice.LOCAL_ESTOQUE_INATIVAR,
                id: localEstoqueId,
                ativo,
                isLocalEstoque: true,
                callback: (okInativar: boolean) => {
                  if (okInativar) {
                    inativarLocalEstoque({ id: localEstoqueId });
                  }
                },
              });
            }
          }

          setIsLoading(false);
        }
      },
    });
  }

  return (
    <SimpleGridForm gap={{ base: '10px', sm: '10px', md: 8 }}>
      <FormProvider {...formMethods}>
        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <SearchInput
            name="nome"
            id="nome"
            placeholder="Buscar o estoque pelo nome"
            onEnterKeyPress={() => {
              filtersSubmit();
            }}
            isDisabled={isLoading}
            autoFocus
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, sm: 6, lg: 3 }}>
          <Box maxW={{ base: 'full', sm: '225px' }}>
            <FilterSelect
              id="statusConsulta"
              name="statusConsulta"
              options={getOptionsByEnum(StatusConsultaEnum)}
              onSelect={() => {
                filtersSubmit();
              }}
              isDisabled={isLoading}
            />
          </Box>
        </GridItem>
        <GridItem
          colSpan={{ base: 12, sm: 6, lg: 3 }}
          display={{ base: '', sm: '', md: 'flex' }}
          justifyContent={{ base: 'flex-start', sm: 'flex-end' }}
        >
          <ButtonCadastrarNovo
            onClick={() => handlePushCadastrarNovo()}
            funcionalidade={ConstanteFuncionalidades.LOCAL_ESTOQUE_CADASTRAR}
          />
        </GridItem>
        <GridItem mt={['10px', '10px', '-10px']} colSpan={12}>
          <PagedTable
            ref={pagedTableRef}
            isLoading={isLoading}
            loadColumnsData={loadColumnsData}
            itemsTotalCount={itemsTotalCount}
            defaultKeyOrdered="nome"
            tableHeaders={[
              {
                key: 'ativo',
                content: <StatusCircle hasValue={false} />,
                w: '1px',
              },
              { key: 'nome', content: 'Nome' },
              { key: 'acoes', content: 'Ações', isOrderable: false, w: '1px' },
            ]}
            renderTableRows={columnsData.map((columnData) => (
              <Tr>
                <Td>
                  <StatusCircle isActive={columnData.ativo} />
                </Td>
                <Td>
                  {columnData.padraoSistema ? (
                    columnData.nome
                  ) : (
                    <Link
                      id="link-visualizar"
                      href={getNomeLinkHref(columnData.id)}
                    >
                      {columnData.nome}
                    </Link>
                  )}
                </Td>
                <Td>
                  {!columnData.padraoSistema && (
                    <ActionsMenu
                      items={[
                        {
                          content: 'Editar',
                          onClick: () => {
                            handlePushAlterarCadastro(columnData.id);
                          },
                          funcionalidade:
                            ConstanteFuncionalidades.LOCAL_ESTOQUE_ALTERAR,
                        },
                        {
                          content: 'Remover',
                          onClick: () => {
                            handleExcluirLocalEstoque(
                              columnData.id,
                              columnData.ativo
                            );
                          },
                          funcionalidade:
                            ConstanteFuncionalidades.LOCAL_ESTOQUE_EXCLUIR,
                        },
                      ]}
                    />
                  )}
                </Td>
              </Tr>
            ))}
          />
        </GridItem>
      </FormProvider>
    </SimpleGridForm>
  );
};

export default Listar;
