import { Flex, Text, Box, Button } from '@chakra-ui/react';

import AsyncSelect from 'components/PDV/Select/AsyncSelectPadrao';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';

import { Content } from '../Content';
import ControlledList from '../ControlledList';
import { useTabProdutoFavorito } from './hooks';

export const TabProdutosFavoritos = () => {
  const {
    getProdutoCorOptions,
    optionsLoading,
    handleCadastrarProduto,
    listProducts,
    handleRemoverProduto,
    productItem,
  } = useTabProdutoFavorito();

  return (
    <Box pl="4px">
      <Flex
        justifyContent="space-between"
        alignItems="end"
        direction={['column', 'column', 'row']}
      >
        <Flex
          mb={['20px', '20px', '0']}
          w={['full', 'full', '']}
          flex={['full', 'full', 1.5]}
        >
          <AsyncSelect
            id="productItem"
            name="productItem"
            label="Descrição do produto"
            placeholder="Digite o nome do produto e selecione"
            isLoading={optionsLoading}
            handleGetOptions={getProdutoCorOptions}
            asControlledByObject
            withoutDefaultOptions
            autoFocus
          />
        </Flex>
        <Flex
          justifyContent="flex-end"
          w={['full', 'full', '']}
          flex={['full', 'full', 2]}
        >
          <Button
            w={['full', 'full', '200px']}
            onClick={handleCadastrarProduto}
            colorScheme="secondary"
            isDisabled={!productItem}
          >
            Cadastrar produto
          </Button>
        </Flex>
      </Flex>
      <Flex
        mt="24px"
        px="24px"
        mb="5px"
        justifyContent="space-between"
        w="full"
      >
        <Text>Produto</Text>
        <Text textAlign="right">Ações</Text>
      </Flex>
      <ControlledList
        name="listProducts"
        renderList={({ value }) => {
          return value?.length > 0 ? (
            <>
              {listProducts.map((itemProduto, index) => (
                <Content justifyContent="space-between" mb="5px">
                  <Text fontSize="14px">{itemProduto.nome}</Text>
                  <Box>
                    <ActionsMenu
                      menuZIndex="modal"
                      items={[
                        {
                          content: 'Remover',
                          onClick: () => handleRemoverProduto(index),
                        },
                      ]}
                    />
                  </Box>
                </Content>
              ))}
            </>
          ) : (
            <Content>Nenhum produto foi adicionado</Content>
          );
        }}
      />
    </Box>
  );
};
