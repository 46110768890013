import React from 'react';
import {
  Box,
  Text,
  Heading,
  Stack,
  VStack,
  Image,
  Button,
  Flex,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import ConstanteRotas from 'constants/rotas';
import { sucessoEtapasImgs } from 'constants/enum/enumsImgSistemas';
import { getImageBySystem } from 'helpers/layout/getImageBySystem';

export function FinalizarConferencia() {
  const history = useHistory();

  const buttonSize = useBreakpointValue({
    base: 'md',
    md: 'lg',
  });

  function handleIniciarNovaConferencia() {
    history.push(ConstanteRotas.CONFERENCIA_ESTOQUE, { isWritting: true });
  }

  function handlePushMovimentacaoEstoque() {
    history.push(ConstanteRotas.MOVIMENTACAO_ESTOQUE);
  }

  return (
    <Box
      px={{ base: 4, md: 10, lg: 16, xl: 24 }}
      py={{ base: 6, md: 8, lg: 20, xl: 24 }}
      bg="white"
      boxShadow="md"
      borderRadius="md"
      position="relative"
    >
      <Stack
        alignItems="center"
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
      >
        <VStack spacing={{ base: 4, lg: 6 }} alignItems="flex-start">
          <Heading
            fontWeight="bold"
            color="aquamarine.600"
            fontSize={{ base: 'xl', md: '2xl', xl: '3xl' }}
          >
            Estoque atualizado!
          </Heading>
          <Text
            fontWeight="regular"
            fontSize="sm"
            w={{
              base: 'full',
              md: '390px',
              lg: '520px',
              xl: '790px',
            }}
          >
            Todas as divergências encontradas foram corrigidas com sucesso.
            Agora seu estoque no sistema está exatamente igual a quantidade
            informada durante a conferência. Parabéns e conte sempre com a
            gente!
          </Text>

          <VStack spacing={5}>
            <Button
              colorScheme="aquamarine"
              size={buttonSize}
              w="full"
              maxW={{ base: 'full', md: '380px' }}
              onClick={handlePushMovimentacaoEstoque}
            >
              Ir para a movimentação de estoque
            </Button>
            <Button
              variant="outline"
              size={buttonSize}
              w="full"
              maxW={{ base: 'full', md: '380px' }}
              onClick={handleIniciarNovaConferencia}
            >
              Iniciar uma nova conferência
            </Button>
          </VStack>
        </VStack>

        <Flex
          alignItems="center"
          justifyContent={{ base: 'center', md: 'flex-end' }}
          pt={{ base: 10, md: 0 }}
          px={{ base: 10, md: 0 }}
          h="full"
          w="full"
        >
          <Image
            src={getImageBySystem(sucessoEtapasImgs)}
            alt="Estoque atualizado"
            maxH={{ base: '160px', md: '255px', lg: 'full' }}
          />
        </Flex>
      </Stack>
    </Box>
  );
}
