import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  ModalFooter,
  ModalHeader,
  useMediaQuery,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';

type ModalTempoMedioPreparoProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<void>;

export const ModalTempoMedioPreparo = create<ModalTempoMedioPreparoProps>(
  ({ onResolve, onReject, ...rest }) => {
    const [isLargerThan900] = useMediaQuery('(min-width: 900px');
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    return (
      <ModalPadraoChakra
        size={isLargerThan900 ? '2xl' : 'full'}
        isCentered={isLargerThan900}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalContent pt={8} px={{ base: '8px', md: '40px' }} bg="gray.50">
          <ModalHeader>
            <Text textColor="primary.50">Tempo médio de preparo</Text>
          </ModalHeader>
          <ModalBody
            overflow="auto"
            gap={5}
            display="flex"
            flexDir="column"
            fontSize="14px"
            textColor="black"
          >
            <span>
              O tempo médio de preparo é utilizado exclusivamente para pedidos
              de Delivery que possuem um agendamento para horário de entrega.
            </span>
            <span>
              Esta informação é utilizada para identificar com quanto tempo de
              antecedência o sistema irá exibir a notificação do pedido em
              relação ao horário agendado.
            </span>
            <span>
              <Text as="b">Exemplo: </Text>
              Um pedido que foi realizado às 17h porém com agendamento de
              entrega para 20h. Considerando que o tempo médio de preparo está
              configurado para 30 minutos, a notificação do pedido será exibida
              à partir das 19:30h.
            </span>
            <span>
              Nos casos de pedidos recebidos pelas plataformas de delivery
              integradas ao sistema, o sistema irá considerar a informação
              enviada pela plataforma. Caso contrário, será considerado o tempo
              informado nesta configuração.
            </span>
            <span>
              Mesmo que não seja exibida a notificação do pedido, é possível
              visualizar e confirmar os pedidos agendados. O horário de
              agendamento é exibido na lista de pedidos do delivery.
            </span>
          </ModalBody>
          <ModalFooter justifyContent="center" py="40px">
            <Button
              w={{ base: 'full', sm: 'min-content' }}
              minW="120px"
              colorScheme="purple"
              variant="solid"
              onClick={onClose}
            >
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
