import { useState, useCallback, useEffect } from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  GridItem,
  HStack,
  SimpleGrid,
  Icon,
  ModalBody,
  Image,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { RiImageAddFill } from 'react-icons/ri';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { useProdutosFormularioContext } from 'store/Produtos/ProdutosFormulario';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';
import auth from 'modules/auth';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import AsyncSelect from 'components/PDV/Select/AsyncSelectPadrao';
import { NumberInput } from 'components/update/Input/NumberInput';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { SalvarInserirNovoIcon } from 'icons';

import {
  yupResolver,
  formDefaultValue,
  ModalItemKitProps,
  FormData,
  KitProps as KitResponseData,
  ProdutoTamanhoProps,
  ParentProdutoTamanhoKit,
  ProdutoCorListarSelect,
  ProdutoTamanhoResponseProps,
} from './validationForms';

type KitProps = {
  quantidade: number;
  valor: number;
  precoCusto: number;
  markup: number;
  id: string;
  produtoNome: string;
  corDescricao: string;
  padraoSistema: boolean;
  label: string;
  value: string;
  produtoDescricao: string;
  produtoCorId: string;
  tamanhoDescricao: string;
  produtoCorTamanhoId: string;
  imagem: string;
};

export const ModalItemKit = ({
  show,
  onHide,
  onSaveAndInsertNew,
  valueKitIndex,
  modalSubtitle = '',
  getKits,
}: ModalItemKitProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isPossuiTamanho, setIsPossuiTamanho] = useState(false);

  const formMethods = useForm<FormData>({
    defaultValues: formDefaultValue,
    resolver: yupResolver,
  });

  const { getValues: parentGetValues } = useFormContext();

  const { idProduto } = useProdutosFormularioContext();

  const {
    casasDecimais: { casasDecimaisValor },
  } = usePadronizacaoContext();

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const lojaId = auth.getLoja().id;

  const { handleSubmit, reset, watch, setValue } = formMethods;

  const [
    produtoWatch,
    produtoCorIdWatch,
    valorWatch,
    quantidadeWatch,
    produtoCorTamanhoIdWatch,
  ] = watch([
    'produtoNovo',
    'produtoCorId',
    'valor',
    'quantidade',
    'produtoCorTamanhoId',
  ]);

  const produtoCorTamanhoKit = parentGetValues(
    'produtoCorTamanhoKitItens'
  ) as KitResponseData[];

  const isEditable = valueKitIndex === undefined;

  const produtoSelecionado = isEditable
    ? null
    : produtoCorTamanhoKit[valueKitIndex];

  const updateValueTamanho = useCallback(
    (value: ProdutoTamanhoProps) => {
      setValue('valor', value.precoVenda);
    },
    [setValue]
  );

  const getTamanhos = useCallback(
    async (inputValue: string, _?: boolean, produtoCor?: string) => {
      setIsLoading(true);
      const response = await api.get<
        void,
        ResponseApi<ProdutoTamanhoResponseProps[]>
      >(ConstanteEnderecoWebservice.PRODUTO_COR_TAMANHO_LISTAR_SELECT_TAMANHO, {
        params: {
          nomeSkuCodigoExternoBarrasGtinEan: inputValue,
          produtoCorId: produtoCor || produtoCorIdWatch?.id,
        },
      });

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }

        if (response.sucesso && response.dados) {
          const data = response.dados
            .filter((item) => item.padraoSistema === false)
            .map((tamanho) => {
              return {
                ...tamanho,
                label: tamanho.descricao,
                value: tamanho.id,
              };
            });

          const newValue = response.dados.map((tamanho) => {
            return {
              ...tamanho,
              label: tamanho.descricao,
              value: tamanho.id,
            };
          });

          if (data.length > 0) {
            updateValueTamanho(data[0]);
          }
          if (newValue.length === 1) {
            setValue('produtoCorTamanhoId', newValue[0]);
          }
          setIsPossuiTamanho(data.length > 1);
          setIsLoading(false);
          return data;
        }
        setIsLoading(false);
      }
      setIsLoading(false);

      return [];
    },
    [produtoCorIdWatch, setValue, updateValueTamanho]
  );

  const getProdutoCorId = useCallback(
    async (inputValue: string) => {
      setIsLoading(true);
      const response = await api.get<
        void,
        ResponseApi<ProdutoCorListarSelect[]>
      >(ConstanteEnderecoWebservice.PRODUTO_COR_LISTAR_SELECT_ITENS_KIT, {
        params: { nome: inputValue, produtoAtualId: produtoCorIdWatch?.id },
      });

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }

        if (response.sucesso && response.dados) {
          const data = response.dados.map((produtoCor) => {
            return {
              ...produtoCor,
              label: `${produtoCor.produtoNome} ${
                produtoCor.padraoSistema ? '' : `| ${produtoCor.corDescricao}`
              }`,
              value: produtoCor.id,
              produtoId: produtoCor.id,
            };
          });
          setIsLoading(false);
          return data;
        }
        setIsLoading(false);
      }
      setIsLoading(false);

      return [];
    },
    [produtoCorIdWatch]
  );

  const dataApi = useCallback(
    (data: KitProps) => {
      return {
        produtoCorTamanhoPrincipalId: data.produtoCorTamanhoId,
        produtoCorTamanhoItemId: data.produtoCorTamanhoId,
        quantidade: data.quantidade,
        valor: data.valor,
        precoCusto: data.precoCusto,
        precoOriginal: 0,
        produtoCorTamanho: {
          produtoCorTamanhoId: data.produtoCorTamanhoId,
          produto: data.label,
          cor: data.corDescricao,
          tamanho: data.tamanhoDescricao,
          imagem: '',
        },
        produtoCorTamanhoKitPrecoLojas: [
          {
            produtoCorTamanhoKitId: data.id,
            lojaId,
            precoVenda: {
              precoVenda: data.valor,
              markup: data.markup,
            },
          },
        ],
      };
    },
    [lojaId]
  );

  const cadastrarKit = useCallback(
    async (data: KitProps) => {
      setIsLoading(true);

      const dados = dataApi(data);

      const response = await api.post<
        void,
        ResponseApi<ProdutoTamanhoResponseProps[]>
      >(
        `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/kits`,
        {
          ...dados,
        }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));

          return false;
        }

        if (response.sucesso && response.dados) {
          getKits();
          return true;
        }
        setIsLoading(false);
        return false;
      }
      setIsLoading(false);

      return false;
    },
    [dataApi, getKits, idProduto]
  );

  const getDetalhesProduto = useCallback(async () => {
    if (produtoCorIdWatch === null) {
      return;
    }
    setIsLoading(true);

    const response = await api.get<void, ResponseApi<KitResponseData[]>>(
      `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/produto-cores/${produtoCorIdWatch?.id}/kits`
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso) {
        const dados = response.dados[0];
        setValue('produtoNovo', {
          id: produtoCorIdWatch?.id,
          produtoNome: dados.produtoCorTamanho.produto,
          corDescricao: dados.produtoCorTamanho.cor,
          padraoSistema: produtoCorIdWatch?.padraoSistema,
          label: produtoCorIdWatch?.label,
          value: produtoCorIdWatch?.value,
          imagem: dados.produtoCorTamanho.imagem,
          precoCusto: dados.precoCusto,
          precoVenda: dados.precoOriginal,
        });
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [idProduto, produtoCorIdWatch, setValue]);

  const alterarKit = useCallback(
    async (data: KitProps) => {
      setIsLoading(true);

      const dados = dataApi(data);

      const response = await api.put<
        void,
        ResponseApi<ProdutoTamanhoResponseProps[]>
      >(
        `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/kits/${data.id}`,
        {
          ...dados,
          id: data.id,
        }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
          return false;
        }

        if (response.sucesso) {
          toast.success('O kit foi alterado com sucesso');
          getKits();
          return true;
        }
        setIsLoading(false);
        return false;
      }
      setIsLoading(false);

      return false;
    },
    [dataApi, getKits, idProduto]
  );

  const valueMarkup = useCallback(() => {
    let valueProdutoTamanho = 0;

    if (!isEditable && produtoCorTamanhoKit?.length > 0) {
      valueProdutoTamanho = produtoCorTamanhoKit[valueKitIndex].precoCusto;
    } else if (
      produtoWatch &&
      (produtoWatch?.precoCusto || 0) > 0 &&
      isEditable
    ) {
      valueProdutoTamanho = produtoWatch?.precoCusto || 0;
    }

    let newMarkup = (valorWatch / valueProdutoTamanho - 1) * 100;
    if (newMarkup === Infinity) {
      newMarkup = 0;
    }
    return parseFloat((newMarkup || 0).toFixed(4));
  }, [
    isEditable,
    produtoCorTamanhoKit,
    produtoWatch,
    valorWatch,
    valueKitIndex,
  ])();

  const setCurrentProdutoKitValues = useCallback(async () => {
    const produtoCorTamanhoKitItensValue = parentGetValues(
      'produtoCorTamanhoKitItens'
    ) as ParentProdutoTamanhoKit[];

    const valoresCalculados = {
      quantidade: quantidadeWatch,
      valor: valorWatch,
      precoCusto: produtoCorTamanhoIdWatch?.precoCusto,
      markup: valueMarkup,
    };

    if (isEditable) {
      if (
        produtoCorTamanhoKitItensValue.some(
          (valueProduto) =>
            valueProduto.produtoCorTamanhoItemId ===
            (produtoCorTamanhoIdWatch?.id || '')
        )
      ) {
        toast.warn(
          'Já existe um item com a variação de produto selecionada adicionado para este kit.'
        );
        return false;
      }

      const produtoItemKit = {
        ...valoresCalculados,
        ...produtoWatch,
        produtoDescricao: produtoWatch?.produtoNome,
        produtoCorId: produtoWatch?.id,
        tamanhoDescricao: produtoCorTamanhoIdWatch?.label,
        produtoCorTamanhoId: produtoCorTamanhoIdWatch?.value,
      };

      const isKitCadastrado = await cadastrarKit(produtoItemKit as KitProps);

      if (!isKitCadastrado) {
        return false;
      }
    } else {
      const produtoItemKit = {
        ...valoresCalculados,
        ...produtoWatch,
        id: produtoCorTamanhoKitItensValue[valueKitIndex].id,
        produtoDescricao: produtoWatch?.produtoNome,
        produtoCorId: produtoWatch?.id,
        tamanhoDescricao: produtoCorTamanhoIdWatch?.label,
        produtoCorTamanhoId: produtoCorTamanhoIdWatch?.value,
      } as any;

      const isKitAlterado = await alterarKit(produtoItemKit as KitProps);
      if (!isKitAlterado) {
        return false;
      }
      return true;
    }

    return true;
  }, [
    alterarKit,
    cadastrarKit,
    isEditable,
    parentGetValues,
    produtoCorTamanhoIdWatch,
    produtoWatch,
    quantidadeWatch,
    valorWatch,
    valueKitIndex,
    valueMarkup,
  ]);

  const handleConfirmar = handleSubmit(async () => {
    const success = await setCurrentProdutoKitValues();

    if (success) {
      reset(formDefaultValue);
      onHide();
    }
  });

  const handleConfirmarInserirNovo = handleSubmit(async () => {
    const success = await setCurrentProdutoKitValues();
    if (success) {
      onSaveAndInsertNew();
      reset(formDefaultValue);
    }
  });
  const isTamanhos =
    produtoCorTamanhoIdWatch === undefined ? false : isPossuiTamanho;

  const updateValueForm = useCallback(() => {
    if (!isEditable && produtoCorTamanhoKit?.length > 0) {
      const valueProduto = produtoCorTamanhoKit[valueKitIndex];

      const { valor, quantidade, precoCusto } = valueProduto;

      reset({
        ...formDefaultValue,
        produtoCorTamanhoId: {
          precoVenda: valor,
          precoCusto,
        },
        valorTotal: valor * quantidade,
        quantidade,
        valor,
      });
    }
  }, [isEditable, produtoCorTamanhoKit, reset, valueKitIndex]);

  useEffect(() => {
    const valorTotal = quantidadeWatch * valorWatch;

    setValue('valorTotal', parseFloat(valorTotal.toFixed(2)));
  }, [quantidadeWatch, setValue, valorWatch]);

  useEffect(() => {
    updateValueForm();
  }, [updateValueForm]);

  useEffect(() => {
    getDetalhesProduto();
  }, [getDetalhesProduto]);

  return (
    <ModalPadraoChakra
      isOpen={show}
      closeOnOverlayClick={false}
      onClose={onHide}
      isCentered={isLargerThan900}
      size={isLargerThan900 ? '3xl' : 'full'}
    >
      <ModalContent
        bg="gray.50"
        borderRadius={isLargerThan900 ? 'md' : '0'}
        marginBottom={isLargerThan900 ? '3.75rem' : '0'}
        marginTop={isLargerThan900 ? '3.75rem' : '0'}
        w={isLargerThan900 ? '900px' : 'full'}
        h={isLargerThan900 ? (!isEditable ? '350px' : '430px') : 'full'}
      >
        <ModalHeader>
          <Text color="primary.500" fontSize="md">
            {!isEditable ? 'Editar produto no Kit' : 'Adicionar produto no Kit'}
          </Text>
          <Text color="gray.700" fontSize="xs">
            {!isEditable && modalSubtitle}
          </Text>

          <Divider mt="10px" />
        </ModalHeader>
        <ModalBody>
          {isLoading && <LoadingPadrao />}
          <FormProvider {...formMethods}>
            {isEditable && (
              <GridItem mb="24px" colSpan={12}>
                <AsyncSelect
                  id="produtoCorId"
                  name="produtoCorId"
                  label="Descrição do produto"
                  required
                  onOptionSelect={async (value: any) => {
                    reset({
                      ...formDefaultValue,
                      produtoCorId: value,
                    });
                    await getDetalhesProduto();
                    getTamanhos('', true, value?.id || '');
                  }}
                  autoFocus={isEditable}
                  asControlledByObject
                  placeholder="Buscar produto por nome"
                  handleGetOptions={getProdutoCorId}
                />
              </GridItem>
            )}
            {(produtoCorIdWatch !== null || !isEditable) && (
              <SimpleGridForm>
                <GridItem colSpan={[12, 12, 2]}>
                  <Box
                    mt="17px"
                    boxSize="112px"
                    borderWidth="1px"
                    borderRadius="6px"
                    borderColor="gray.200"
                  >
                    {(produtoSelecionado !== null &&
                      produtoSelecionado?.produtoCorTamanho?.imagem) ||
                    produtoWatch?.imagem ? (
                      <Image
                        boxSize="112px"
                        p="2px"
                        src={
                          produtoSelecionado?.produtoCorTamanho?.imagem ||
                          produtoWatch?.imagem
                        }
                        objectFit="cover"
                      />
                    ) : (
                      <Flex
                        mb="3px"
                        h="full"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Icon fontSize="20px" as={RiImageAddFill} />
                      </Flex>
                    )}
                  </Box>
                </GridItem>
                <GridItem pl={['0', '0', '14px']} colSpan={[12, 12, 10]}>
                  <SimpleGrid
                    spacingX="20px"
                    spacingY="20px"
                    templateAreas={
                      isTamanhos
                        ? " 'tamanho quantidade' 'preco valorTotal'"
                        : " 'quantidade quantidade' 'preco valorTotal'"
                    }
                  >
                    {isTamanhos && isEditable && (
                      <GridItem
                        colSpan={[12, 6]}
                        area={isLargerThan900 ? 'tamanho' : 'unset'}
                      >
                        <AsyncSelect
                          id="produtoCorTamanhoId"
                          name="produtoCorTamanhoId"
                          label="Tamanho"
                          required
                          isLoading={isLoading}
                          asControlledByObject
                          placeholder="Buscar o tamanho"
                          handleGetOptions={getTamanhos}
                          isDisabled={
                            produtoCorIdWatch === undefined ||
                            produtoCorTamanhoIdWatch?.padraoSistema
                          }
                        />
                      </GridItem>
                    )}

                    <GridItem
                      colSpan={[12, 6]}
                      area={isLargerThan900 ? 'quantidade' : 'unset'}
                    >
                      <NumberInput
                        id="quantidade"
                        name="quantidade"
                        label="Quantidade"
                        autoFocus={!isEditable}
                        required
                        precision={14}
                        scale={2}
                        max={99999}
                      />
                    </GridItem>
                    <GridItem
                      colSpan={[12, 6]}
                      area={isLargerThan900 ? 'preco' : 'unset'}
                    >
                      <NumberInput
                        id="valor"
                        name="valor"
                        label="Preço unitário no Kit"
                        editarFundoLeftElemento
                        bgLeftElement="gray.50"
                        leftElement="R$"
                        precision={14}
                        scale={casasDecimaisValor}
                        max={99999}
                        defaultValue={0}
                      />
                    </GridItem>
                    <GridItem
                      colSpan={[12, 6]}
                      area={isLargerThan900 ? 'valorTotal' : 'unset'}
                    >
                      <NumberInput
                        id="valorTotal"
                        name="valorTotal"
                        label="Valor total"
                        editarFundoLeftElemento
                        bgLeftElement="gray.50"
                        leftElement="R$"
                        precision={14}
                        scale={2}
                        defaultValue={0}
                        isDisabled
                      />
                    </GridItem>
                    {produtoWatch?.precoVenda !== valorWatch ? (
                      <GridItem
                        color="black"
                        bg="yellow.500"
                        borderRadius="4px"
                        px="20px"
                        h="24px"
                        fontSize="12px"
                        mt="-10px"
                        colSpan={12}
                      >
                        <Flex
                          w="full"
                          alignItems="center"
                          h="full"
                          justifyContent="flex-start"
                        >
                          <Text>{`Preço original: ${(
                            produtoWatch?.precoVenda || 0
                          ).toLocaleString('locale', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 4,
                          })}`}</Text>

                          <Text mx="10px">-</Text>
                          <Text>{`Markup novo: ${(
                            valueMarkup || 0
                          ).toLocaleString('locale', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 4,
                          })}%`}</Text>
                        </Flex>
                      </GridItem>
                    ) : (
                      <GridItem colSpan={12} />
                    )}
                  </SimpleGrid>
                </GridItem>
              </SimpleGridForm>
            )}
          </FormProvider>
        </ModalBody>
        <ModalFooter>
          <Box w="full">
            <Divider mb="10px" mt="10px" />
            <HStack
              spacing={['0', '0', '24px']}
              w="full"
              display={['block', 'block', 'flex']}
              justifyContent="center"
            >
              <Button
                id="cancel"
                variant="cancelOutline"
                w={['full', 'full', '96px']}
                name="cancelar"
                height="34px"
                fontSize="14px"
                onClick={() => {
                  reset(formDefaultValue);
                  onHide();
                }}
                colorScheme="gray"
              >
                Cancelar
              </Button>
              {isEditable && !isLargerThan900 && <Box h="10px" />}
              <Button
                variant="outlineDefault"
                borderRadius="16px"
                fontSize="14px"
                height="34px"
                colorScheme="gray.500"
                w={['full', 'full', '96px']}
                onClick={() => handleConfirmar()}
              >
                Confirmar
              </Button>
              {isEditable && (
                <Button
                  leftIcon={<Icon as={SalvarInserirNovoIcon} />}
                  type="button"
                  color="violet.900"
                  fontSize="14px"
                  variant="solid"
                  height="34px"
                  w={['full', 'full', '204px']}
                  borderRadius="16px"
                  onClick={() => handleConfirmarInserirNovo()}
                  colorScheme="secondary.400"
                >
                  Confirmar e inserir novo
                </Button>
              )}
            </HStack>
          </Box>
        </ModalFooter>
      </ModalContent>
    </ModalPadraoChakra>
  );
};
