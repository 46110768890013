import { useCallback, useState } from 'react';

import { deleteMovimentacaoFinanceira } from 'helpers/api/MovimentacaoFinanceira/deleteMovimentacaoFinanceira';

export const useListagemMovimentacaoFinanceira = () => {
  const [isLoading, setIsLoading] = useState(false);

  const removeAll = useCallback(
    async ({ operacaoId }: { operacaoId: string }) => {
      setIsLoading(true);
      const response = await deleteMovimentacaoFinanceira({ operacaoId });
      setIsLoading(false);
      return response;
    },
    []
  );

  const removeByIdentificadorAgrupamento = useCallback(
    async ({
      identificadorAgrupamento,
    }: {
      identificadorAgrupamento: string;
    }) => {
      setIsLoading(true);
      const response = await deleteMovimentacaoFinanceira({
        identificadorAgrupamento,
      });
      setIsLoading(false);
      return response;
    },
    []
  );

  return {
    isLoading,
    removeAll,
    removeByIdentificadorAgrupamento,
  };
};
