import { theme } from '@chakra-ui/react';
import { StyleFunctionProps } from '@chakra-ui/theme-tools';

type AccessibleColor = {
  bg?: string;
  hoverBg?: string;
  color?: string;
};

/** Accessible color overrides for less accessible colors. */
const accessibleColorMap: { [key: string]: AccessibleColor } = {
  white: {
    bg: 'white',
    hoverBg: 'white',
    color: 'black',
  },
  blue: {
    bg: 'blue.200',
    hoverBg: 'blue.200',
    color: 'blue.800',
  },
  red: { hoverBg: 'red.100' },
};

const Input = {
  parts: ['field', 'addon'],

  baseStyle: {
    field: {
      borderRadius: 'base',
    },
  },

  variants: {
    outline: {
      field: {
        bg: 'white',
        _disabled: {
          background: 'gray.200',
          borderColor: 'gray.300',
          color: 'gray.700',
          opacity: 1,
        },
        _invalid: { borderColor: 'inherit', boxShadow: 'none' },
      },
    },
    'pill-outline': (props: StyleFunctionProps) => {
      const { addon, field } = theme.components.Input.variants.outline(props);

      return {
        addon,
        field: {
          ...field,
          bg: 'white',
          borderRadius: 'full',
        },
      };
    },
    filled: (props: StyleFunctionProps) => {
      const { addon, field } = theme.components.Input.variants.filled(props);

      const {
        color = `${props.colorScheme}.500`,
        bg = `${props.colorScheme}.100`,
        hoverBg = `${props.colorScheme}.200`,
      } = accessibleColorMap[props.colorScheme ?? 'white'] || {};

      return {
        addon,
        field: {
          ...field,
          color,
          bg,
          _hover: { bg: hoverBg },
          _focus: { bg },
          _disabled: {
            opacity: 0.9,
            cursor: 'not-allowed',
          },
        },
      };
    },
  },

  sizes: {
    sm: {
      field: {
        borderRadius: 'md',
      },
    },
    md: {
      field: {
        h: 9,
      },
    },
    lg: {
      field: {
        h: 10,
        fontSize: 'md',
      },
      addon: {
        h: 10,
      },
    },
  },

  defaultProps: {
    size: 'md',
    variant: 'outline',
    focusBorderColor: 'primary.500',
  },
};

export default Input;
