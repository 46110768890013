import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export type FormData = {
  nome: string;
  impressoras: {
    impressoraId: string;
    imprimirAutomatico: boolean;
    quantidadeCopias: number;
    confirmarImpressao: boolean;
  }[];
  impressora: {
    value: string;
    label: string;
  } | null;
  confirmarImpressao: boolean;
  imprimirAutomatico: boolean;
  quantidadeCopias: number;
};

const defaultValues = {
  nome: '',
  impressora: null,
  impressoras: [],
  confirmarImpressao: false,
  imprimirAutomatico: false,
  quantidadeCopias: 1,
};

export const numeroCopiasOptions = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
];

const schema = yup.object({
  nome: yup.string().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

const yupResolver = yupResolverInstance(schema);

export { yupResolver, defaultValues };
