import React from 'react';
import {
  useMediaQuery,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Kbd,
  Grid,
  Heading,
} from '@chakra-ui/react';

export function ConteudoModalAtalhos() {
  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

  return (
    <>
      <Heading as="h2" fontSize="md" mb={3}>
        Teclas de atalho gerais
      </Heading>
      <Grid templateColumns={{ base: '1fr', sm: '1fr 1fr' }} gap={2}>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th w="full">Descrição</Th>
              <Th>Tecla</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Ajuda</Td>
              <Td>
                <Kbd color="black">F1</Kbd>
              </Td>
            </Tr>
            <Tr>
              <Td>Escolher Cliente</Td>
              <Td>
                <Kbd color="black">F2</Kbd>
              </Td>
            </Tr>
            <Tr>
              <Td>Informações do Cliente</Td>
              <Td>
                <Kbd color="black">F3</Kbd>
              </Td>
            </Tr>
            {!isLargerThan700 && (
              <>
                <Tr>
                  <Td>Consultar Operações</Td>
                  <Td>
                    <Kbd color="black">F4</Kbd>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Pedidos Abertos do Dia</Td>
                  <Td>
                    <Kbd color="black">F6</Kbd>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Habilitar Tela Cheia</Td>
                  <Td>
                    <Kbd color="black">F11</Kbd>
                  </Td>
                </Tr>
              </>
            )}
          </Tbody>
        </Table>
        {isLargerThan700 && (
          <Table size="sm">
            <Thead>
              <Tr>
                <Th w="full">Descrição</Th>
                <Th>Tecla</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Consultar Operações</Td>
                <Td>
                  <Kbd color="black">F4</Kbd>
                </Td>
              </Tr>
              <Tr>
                <Td>Pedidos Abertos do Dia</Td>
                <Td>
                  <Kbd color="black">F6</Kbd>
                </Td>
              </Tr>
              <Tr>
                <Td>Habilitar Tela Cheia</Td>
                <Td>
                  <Kbd color="black">F11</Kbd>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        )}
      </Grid>

      <Heading as="h2" fontSize="md" mb={3} mt={6}>
        Teclas de atalho do lançamento
      </Heading>
      <Grid templateColumns={{ base: '1fr', sm: '1fr 1fr' }} gap={2}>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th w="full">Descrição</Th>
              <Th>Tecla</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Observação</Td>
              <Td>
                <Kbd color="black">F7</Kbd>
              </Td>
            </Tr>
            {!isLargerThan700 && (
              <Tr>
                <Td>Vai para tela de pagamento</Td>
                <Td>
                  <Kbd color="black">F9</Kbd>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
        {isLargerThan700 && (
          <Table size="sm">
            <Thead>
              <Tr>
                <Th w="full">Descrição</Th>
                <Th>Tecla</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Vai para tela de pagamento</Td>
                <Td>
                  <Kbd color="black">F9</Kbd>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        )}
      </Grid>

      <Heading as="h2" fontSize="md" mb={3} mt={6}>
        Teclas de atalho do pagamento
      </Heading>
      <Grid templateColumns={{ base: '1fr', sm: '1fr 1fr' }} gap={2}>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th w="full">Descrição</Th>
              <Th>Tecla</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Observação</Td>
              <Td>
                <Kbd color="black">F7</Kbd>
              </Td>
            </Tr>
            <Tr>
              <Td>Acréscimos e descontos</Td>
              <Td>
                <Kbd color="black">F8</Kbd>
              </Td>
            </Tr>
            {!isLargerThan700 && (
              <>
                <Tr>
                  <Td>Finalizar venda</Td>
                  <Td>
                    <Kbd color="black">F9</Kbd>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Voltar para a tela anterior</Td>
                  <Td>
                    <Kbd color="black">F10</Kbd>
                  </Td>
                </Tr>
              </>
            )}
          </Tbody>
        </Table>
        {isLargerThan700 && (
          <Table size="sm">
            <Thead>
              <Tr>
                <Th w="full">Descrição</Th>
                <Th>Tecla</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Finalizar venda</Td>
                <Td>
                  <Kbd color="black">F9</Kbd>
                </Td>
              </Tr>
              <Tr>
                <Td>Voltar para a tela anterior</Td>
                <Td>
                  <Kbd color="black">F10</Kbd>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        )}
      </Grid>
    </>
  );
}
