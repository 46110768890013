import React, {
  ForwardRefRenderFunction,
  useImperativeHandle,
  forwardRef,
  useState,
  useCallback,
} from 'react';
import { toast } from 'react-toastify';
import { VStack, Text, Box, Flex, LayoutProps } from '@chakra-ui/react';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ContatoEmpresa from 'constants/contato';

import Cabecalho from './Divisoes/cabecalho';
import Vendas from './Divisoes/vendas';
import FormaPagamento from './Divisoes/formaPagamento';
import Produtos from './Divisoes/produtos';
import Totais from './Divisoes/totais';

interface ImpressaoCupomNaoFiscal {
  maxWidth?: LayoutProps['maxW'];
  operacaoId?: string;
  containerRef?: React.RefObject<HTMLDivElement>;
}

export interface ImpressaoCupomNaoFiscalRefElement {
  obterInformacoesImpressao: () => Promise<boolean>;
}

interface ObterInformacoes {
  nomeFantasia: string;
  cnpjTelefone: string;
  enderecoLoja: string;
  identificacaoTipoOperacao: number;
  numeroOperacao: number;
  status: number;
  dataEmissao: string;
  clienteNome: string;
  clienteEndereco: string;
  valorTotalItens: number;
  valorTotalAcrescimos: number;
  valorTotalDescontos: number;
  valorTotalItensSemDesconto: number;
  valorTotal: number;
  troco: number;
  frete: number;
  usuarioNome: string;
  vendedorNome: string;
  observacao: string;
  itens: Array<Itens>;
  recebimentos: Array<Recebimentos>;
}

interface Recebimentos {
  parcela: string;
  dataVencimento: string;
  descricao: string;
  valor: number;
  cupomTroca: boolean;
}

interface Itens {
  numeroItem: string;
  nomeProduto: string;
  codigoBarrasInterno: string;
  quantidade: number;
  unidadeMedida: string;
  valorDesconto: number;
  valorItemComDesconto: number;
  valorTotalItem: number;
  valorUnitario: number;
}

const ImpressaoCupomNaoFiscal: ForwardRefRenderFunction<
  ImpressaoCupomNaoFiscalRefElement,
  ImpressaoCupomNaoFiscal
> = ({ maxWidth, operacaoId, containerRef }, ref) => {
  const [
    impressaoCupom80mm,
    setIsImpressaoCupom80mm,
  ] = useState<ObterInformacoes>();

  const queryToken = new URLSearchParams(window.location.search);
  const token = queryToken.get('token');

  const obterInformacoesImpressao = useCallback(async () => {
    const response = await api.get<
      void,
      ResponseApi<ObterInformacoes | undefined>
    >(ConstanteEnderecoWebservice.PEDIDOORCAMENTOVENDA_OBTER_PARA_IMPRIMIR, {
      headers: { 'temporary-access-token': token },
      params: { id: operacaoId },
    });

    let hasLinkExpirado = false;

    if (response) {
      const statusCode403 = 403;

      if (response.status === statusCode403) {
        hasLinkExpirado = true;
        return hasLinkExpirado;
      }
      if (response?.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }
      if (response?.sucesso) {
        setIsImpressaoCupom80mm(response.dados);
      }
    }
    return hasLinkExpirado;
  }, [operacaoId, token]);

  useImperativeHandle(ref, () => {
    return {
      obterInformacoesImpressao,
    };
  });

  if (impressaoCupom80mm === undefined) return null;

  return (
    <Box
      h="full"
      minH="100vh"
      minW="100%"
      pl="10px"
      pr="10px"
      sx={{
        '& div': {
          color: 'black',
          fontWeight: '700',
          fontSize: '14px',
          fontFamily: 'calibri',
        },
        '& div, & hr': {
          opacity: 'unset',
          borderColor: 'black',
        },
      }}
      ref={containerRef}
    >
      <Flex minW="58mm" maxW={maxWidth} direction="column" h="auto" pt="3">
        <Cabecalho
          nomeFantasia={impressaoCupom80mm.nomeFantasia}
          cnpjTelefone={impressaoCupom80mm.cnpjTelefone}
          enderecoLoja={impressaoCupom80mm.enderecoLoja}
        />
        <Vendas
          numeroOperacao={impressaoCupom80mm.numeroOperacao}
          dataEmissao={impressaoCupom80mm.dataEmissao}
          clienteNome={impressaoCupom80mm.clienteNome}
          clienteEndereco={impressaoCupom80mm.clienteEndereco}
          status={impressaoCupom80mm.status}
          identificaoOperacao={impressaoCupom80mm.identificacaoTipoOperacao}
        />
        <Produtos produto={impressaoCupom80mm.itens} />
        <Totais
          valorTotalAcrescimo={impressaoCupom80mm.valorTotalAcrescimos}
          valorTotalItensSemDesconto={
            impressaoCupom80mm.valorTotalItensSemDesconto
          }
          valorTotalDescontos={impressaoCupom80mm.valorTotalDescontos}
          valorTotal={impressaoCupom80mm.valorTotal}
          troco={impressaoCupom80mm.troco}
          itens={impressaoCupom80mm.itens}
          frete={impressaoCupom80mm.frete}
        />
        <FormaPagamento pagamento={impressaoCupom80mm.recebimentos} />
        <Flex
          direction="column"
          alignItems="flex-start"
          pt={2}
          pb={2}
          borderBottom="2px solid"
          borderColor="gray.700"
        >
          <VStack mb={1}>
            <Text>Operador(a): {impressaoCupom80mm.usuarioNome}</Text>
          </VStack>
          <VStack mt={1}>
            <Text>Vendedor(a): {impressaoCupom80mm.vendedorNome}</Text>
          </VStack>
          {impressaoCupom80mm.observacao && (
            <VStack mt={1}>
              <Text>Observação: {impressaoCupom80mm.observacao}</Text>
            </VStack>
          )}
        </Flex>
        <Text
          textAlign="right"
          fontStyle="italic"
          fontSize="2xs"
          fontWeight="bold"
          color="gray.900"
          mt={2}
        >
          {ContatoEmpresa.CONTATO}
        </Text>
      </Flex>
    </Box>
  );
};

export default forwardRef(ImpressaoCupomNaoFiscal);
