import { Flex, Text } from '@chakra-ui/react';
import React from 'react';

type StoreHeaderProps = {
  nomeFantasia: string;
  enderecoLoja: string;
  telefone?: string;
};

export const StoreHeader = ({
  nomeFantasia,
  enderecoLoja,
  telefone,
}: StoreHeaderProps) => (
  <Flex
    flexDir="column"
    fontWeight="extrabold"
    bg="gray.100"
    borderBottom="1px solid black"
    alignItems="center"
    justifyContent="flex-start"
    py="4px"
    gap="6px"
    px="4px"
    minH="55px"
    w="full"
  >
    <Text fontSize="12px" lineHeight="1.2">
      {nomeFantasia?.toUpperCase()}
    </Text>
    <Text fontSize="8px" textAlign="center">
      {enderecoLoja?.toUpperCase()}
    </Text>
    <Text fontSize="8px" textAlign="center">
      {telefone ? `FONE: ${telefone?.toUpperCase()}` : ''}
    </Text>
  </Flex>
);
