import React from 'react';
import {
  Icon,
  useClipboard,
  Flex,
  Text,
  Link,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  Tooltip,
  Button,
} from '@chakra-ui/react';
import { FiXCircle } from 'react-icons/fi';
import { MdContentCopy } from 'react-icons/md';

import TipoRejeicaoNotaFiscalEnum from 'constants/enum/fiscal/tipoRejeicaoNotaFiscal';
import ModelosFiscaisEnum from 'constants/enum/fiscal/modelosFiscais';
import { useAtendimentoChatContext } from 'store/AtendimentoChat';

export interface RejeicaoInterface {
  Mensagem: string;
  LinkBaseConhecimento?: string;
  TipoRejeicao?: number;
}

interface BoxRejeicaoProps {
  rejeicao: RejeicaoInterface;
  modeloFiscal: number;
}

export const BoxRejeicao = ({
  rejeicao: {
    Mensagem: mensagem,
    LinkBaseConhecimento: linkBaseConhecimento,
    TipoRejeicao: tipoRejeicao = TipoRejeicaoNotaFiscalEnum.SEFAZ,
  },
  modeloFiscal,
}: BoxRejeicaoProps) => {
  const { handleOpenMovideskChat } = useAtendimentoChatContext();

  const { onCopy, hasCopied } = useClipboard(mensagem);

  return (
    <Flex h={24} px={{ base: 6, md: 10 }} bg="red.500" alignItems="center">
      <Icon as={FiXCircle} boxSize={7} mr={4} color="white" />

      <Flex flexDirection="column">
        {tipoRejeicao === TipoRejeicaoNotaFiscalEnum.SEFAZ ? (
          <>
            <Text
              fontWeight="semibold"
              fontSize="xl"
              lineHeight="tall"
              color="white"
            >
              {`Ocorreu um erro na emissão da ${
                modeloFiscal === ModelosFiscaisEnum.NFe ? 'NF-e' : 'NFC-e'
              }`}
            </Text>
            <Text fontSize="sm" color="white">
              {mensagem}
              {linkBaseConhecimento && (
                <>
                  {' - '}
                  <Text as="span" color="secondary.300">
                    {'Veja como resolver na '}
                    <Link
                      textDecoration="underline"
                      _hover={{
                        color: 'secondary.100',
                        textDecoration: 'underline',
                      }}
                      _active={{
                        color: 'secondary.100',
                        textDecoration: 'underline',
                      }}
                      href={linkBaseConhecimento}
                      isExternal
                    >
                      cental de ajuda
                    </Link>
                  </Text>
                </>
              )}
            </Text>
          </>
        ) : (
          <>
            <Text fontSize="sm" color="white">
              A nota fiscal foi rejeitada. Entre em contato com o nosso suporte{' '}
              <Button
                variant="link"
                size="sm"
                colorScheme="secondary"
                color="secondary.300"
                _hover={{
                  color: 'secondary.100',
                  textDecoration: 'underline',
                }}
                _active={{
                  color: 'secondary.100',
                  textDecoration: 'underline',
                }}
                onClick={() => {
                  handleOpenMovideskChat();
                }}
              >
                clicando aqui
              </Button>{' '}
              e passe as informações abaixo:
            </Text>
            <InputGroup size="md">
              <Input
                type="text"
                isReadOnly
                value={mensagem}
                isTruncated
                border="dashed 2px"
                borderColor={hasCopied ? 'black' : 'gray.300'}
              />
              <InputRightElement>
                <Tooltip
                  hasArrow
                  label={hasCopied ? 'Copiado' : 'Copiar'}
                  closeOnClick={false}
                  placement="top"
                >
                  <IconButton
                    aria-label="Copiar"
                    icon={<Icon as={MdContentCopy} fontSize="lg" />}
                    variant="link"
                    color={hasCopied ? 'black' : 'gray.300'}
                    p={1}
                    px={1}
                    borderRadius="md"
                    minW="1px"
                    onClick={onCopy}
                  />
                </Tooltip>
              </InputRightElement>
            </InputGroup>
          </>
        )}
      </Flex>
    </Flex>
  );
};
