import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { OptionTypeBase, OptionsType } from 'react-select';

import api, { ResponseApi } from 'services/api';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import isPrenvent from 'helpers/layout/isPrenvent';
import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import FormaPagamentoFormularioProvider, {
  IconesStorage,
} from 'store/FormaPagamento/FormaPagamentoFormulario';
import MeioPagamentoEnum from 'constants/enum/fiscal/meioPagamento';

import { ContainerListagem } from 'components/Layout/Listagem/containerListagem';

import { useForm, yupResolver } from '../validationForm';
// eslint-disable-next-line import/no-named-as-default
import UncontrolledForm from '..';

type TParams = { id: string };

const Alterar = () => {
  const history = useHistory();
  const isMountedRef = useIsMountedRef();
  isPrenvent();

  const [isLoading, setIsLoading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [iconesOptions, setIconesOptions] = useState<
    OptionsType<OptionTypeBase>
  >([]);
  const [urlPreviewList, setUrlPreviewList] = useState<IconesStorage[]>();
  const [dataHoraCadastro, setDataHoraCadastro] = useState('');
  const [dataHoraUltimaAlteracao, setDataHoraUltimaAlteracao] = useState('');
  const [showVencimento, setShowVencimento] = useState(false);
  const [urlPreview, setUrlPreview] = useState('');

  const formMethods = useForm({
    resolver: yupResolver,
    shouldUnregister: false,
  });
  const { reset, formState, setValue } = formMethods;

  const { id: idRota } = useParams<TParams>();

  const handleGetFormaPagamento = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<void, ResponseApi<any>>(
      ConstanteEnderecoWebservice.FORMA_PAGTO_OBTER,
      {
        params: { id: idRota },
      }
    );

    if (response?.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (response?.sucesso && isMountedRef.current) {
      reset({
        ...response.dados,
      });
      setDataHoraCadastro(response.dados.dataHoraCadastro);
      setDataHoraUltimaAlteracao(response.dados.dataHoraUltimaAlteracao);
      if (
        response.dados.regraMeioPagamento ===
          MeioPagamentoEnum.CartaoCreditoLink ||
        response.dados.regraMeioPagamento ===
          MeioPagamentoEnum.CartaoCreditoMaquina
      ) {
        setShowVencimento(true);
        setUrlPreview(response.dados.icone);
        setValue('lancarFatura', response.dados.lancarFatura);
      } else {
        setShowVencimento(false);
      }
    } else {
      history.push(ConstanteRotas.FORMA_PAGTO);
    }

    if (isMountedRef.current) setIsLoading(false);
  }, [idRota, isMountedRef, reset, setValue, history]);

  const ObterBancoIcone = useCallback(() => {
    if (urlPreviewList && urlPreview) {
      setValue(
        'codigoBanco',
        urlPreviewList.filter((listUrl) => listUrl.url === urlPreview)[0].nome
      );
    }
  }, [urlPreviewList, urlPreview, setValue]);

  useEffect(() => {
    setIsLoading(true);

    handleGetFormaPagamento();
  }, [handleGetFormaPagamento]);

  useEffect(() => {
    ObterBancoIcone();
  }, [urlPreviewList, urlPreview, ObterBancoIcone]);

  useEffect(() => {
    setFormIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  return (
    <ContainerListagem
      formMethods={formMethods}
      isLoading={isLoading}
      dataHoraUltimaAlteracao={dataHoraUltimaAlteracao}
      dataHoraCadastro={dataHoraCadastro}
      isVisualizar
      maxWidth="full"
      formIsDirty={formIsDirty}
    >
      <FormaPagamentoFormularioProvider
        urlPreview={urlPreview}
        setUrlPreview={setUrlPreview}
        showVencimento={showVencimento}
        setShowVencimento={setShowVencimento}
        iconesOptions={iconesOptions}
        setIconesOptions={setIconesOptions}
        urlPreviewList={urlPreviewList}
        setUrlPreviewList={setUrlPreviewList}
      >
        <UncontrolledForm readonly />
      </FormaPagamentoFormularioProvider>
    </ContainerListagem>
  );
};

export default Alterar;
