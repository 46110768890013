import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ContainerListagem } from 'components/Layout/Listagem/containerListagem';

import UncontrolledForm from '..';
import { FormData } from '../validationForm';
import { useCredenciadoraCartaoForm } from '../hooks';

const CredenciadoraCartaoAlterar = () => {
  const { id: idRota } = useParams<{ id: string }>();
  const {
    obterCredenciadoraCartao,
    atualizarCredenciadoraCartao,
    redirecionarCredenciadoraCartaoListar,
    codigoSatPadrao,
  } = useCredenciadoraCartaoForm();
  const formMethods = useForm();

  const { handleSubmit, reset } = formMethods;

  const [isLoading, setIsLoading] = useState(false);

  const handleSalvar = async (data: FormData) => {
    setIsLoading(true);

    const { success } = await atualizarCredenciadoraCartao(idRota, {
      ...data,
      codigoSat: data?.codigoSat || codigoSatPadrao,
    });

    if (success) {
      toast.success('Credenciadora de cartão alterada com sucesso');
      redirecionarCredenciadoraCartaoListar();
    }

    setIsLoading(false);
  };

  const resetarForm = useCallback(
    async (id: string) => {
      setIsLoading(true);

      const credenciadoraCartao = await obterCredenciadoraCartao(id);

      if (credenciadoraCartao) {
        reset(credenciadoraCartao);
      }

      setIsLoading(false);
    },
    [obterCredenciadoraCartao, reset]
  );

  useEffect(() => {
    resetarForm(idRota);
  }, [resetarForm, idRota]);

  return (
    <ContainerListagem
      maxWidth="full"
      formMethods={formMethods}
      isLoading={isLoading}
      onSubmit={handleSubmit(handleSalvar)}
    >
      <UncontrolledForm />
    </ContainerListagem>
  );
};

export { CredenciadoraCartaoAlterar };
