export const enumTipoImpressaoFichaSmartPos = {
  NENHUM: {
    value: 1,
    label: 'Nenhum',
  },

  FICHA: {
    value: 2,
    label: 'Ficha',
  },
  CUPOM: {
    value: 3,
    label: 'Cupom',
  },
  FICHA_CUPOM: {
    value: 4,
    label: 'Ficha + cupom',
  },
};
