import React from 'react';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

import ControleCaixaListar from '../pages/ControleCaixa/Listar';
import { ControleCaixaDetalhes } from '../pages/ControleCaixa/Detalhes';

const ControleCaixaRoutes = [
  <LayoutGuard
    key="controlecaixa-listar"
    permissaoFuncionalidade={ConstanteFuncionalidades.CONTROLE_CAIXA_LISTAR}
    component={ControleCaixaListar}
    breadcrumb={[
      {
        title: 'Financeiro',
      },
      {
        title: 'Controle de caixa',
      },
    ]}
    path={ConstanteRotas.CONTROLE_CAIXA}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="controlecaixa-detalhes"
    permissaoFuncionalidade={ConstanteFuncionalidades.CONTROLE_CAIXA_VISUALIZAR}
    component={ControleCaixaDetalhes}
    breadcrumb={[
      {
        title: 'Financeiro',
      },
      {
        title: 'Controle de caixa',
        path: ConstanteRotas.CONTROLE_CAIXA,
      },
      {
        title: 'Detalhes',
      },
    ]}
    path={ConstanteRotas.CONTROLE_CAIXA_DETALHES}
    exact
    meta={{ auth: true }}
  />,
];

export default ControleCaixaRoutes;
