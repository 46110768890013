import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useRef,
} from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { shallowEqual } from 'helpers/validation/shallowEqual';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import TipoValorEnum from 'constants/enum/tipoValor';

import {
  ForwardRefData,
  LoadMoreRowsParams,
} from 'components/update/Table/VirtualizedInfiniteList';
import { ModalConfirmacao } from 'components/Modal/ModalConfirmacao';
import { compartilharRelatorio } from 'pages/ConciliacaoContas/Listar/Compartilhar';
import { ModalBuscaAvancada } from 'pages/ConciliacaoContas/Listar/ModalBuscaAvancada';
import { ModalAlterarEmMassa } from 'pages/ConciliacaoContas/Listar/ModalAlterarEmMassa';
import { ModalAnteciparContas } from 'pages/ConciliacaoContas/Listar/ModalAnteciparContas';

import {
  formDefaultValues,
  totalizadoresDefaultValues,
  Conta,
  FormData,
  GridPaginadaRetornoConciliacao,
  ConciliacaoContasContextProps,
  AntecipacaoRequest,
  ConciliacaoContasProviderProps,
} from './validationsForm';

const ConciliacaoContasContext = createContext<
  ConciliacaoContasContextProps | undefined
>(undefined);

export const ConciliacaoContasProvider = ({
  children,
}: ConciliacaoContasProviderProps) => {
  const [listContas, setListContas] = useState<Conta[]>([]);
  const [totalizadoresListagem, setTotalizadoresListagem] = useState(
    totalizadoresDefaultValues
  );
  const [isLoading, setIsLoading] = useState(false);
  const [hasFilters, setHasFilters] = useState(false);

  const refVirtualizedTable = useRef<ForwardRefData>(null);

  const formMethods = useForm<FormData>({
    defaultValues: formDefaultValues,
  });

  const { watch, reset, getValues, setValue } = formMethods;

  const [
    dataCompensacaoInicio,
    dataCompensacaoFim,
    dataEmissaoInicio,
    dataEmissaoFim,
    dataPagamentoInicio,
    dataPagamentoFim,
    dataVencimentoInicio,
    dataVencimentoFim,
    formaPagamentoBaixaId,
    codigoAutorizacao,
    conciliado,
  ] = watch([
    'dataCompensacaoInicio',
    'dataCompensacaoFim',
    'dataEmissaoInicio',
    'dataEmissaoFim',
    'dataPagamentoInicio',
    'dataPagamentoFim',
    'dataVencimentoInicio',
    'dataVencimentoFim',
    'formaPagamentoBaixaId',
    'codigoAutorizacao',
    'conciliado',
  ]);

  const isListContaEmpty = listContas?.length === 0;
  const hasSomenteContasConciliadas = listContas?.every(
    (conta) => conta.conciliado
  );
  const selectedContas = listContas?.filter((conta) => conta.isChecked);
  const isSelectedContasEmpty = selectedContas?.length === 0;
  const isAllContasSelected =
    !isListContaEmpty &&
    listContas?.every((conta) => {
      if (conta.conciliado) {
        return true;
      }
      return conta.isChecked;
    });

  const totalSelectedContas = selectedContas?.length || 0;

  const todasContasPodemSerAntecipadas = selectedContas?.every(
    (conta) => conta.antecipavel
  );

  const totalTaxasFromSelectedContas =
    selectedContas?.reduce((acc, conta) => acc + conta.taxa, 0) || 0;

  const totalValorLiquidoFromSelectedContas =
    selectedContas?.reduce((acc, conta) => acc + conta.valorLiquido, 0) || 0;

  const totalValorBrutoFromSelectedContas =
    selectedContas?.reduce((acc, conta) => acc + conta.valor, 0) || 0;

  const fetchMoreRows = useCallback(
    async (
      endpoint: string,
      paginationData: LoadMoreRowsParams,
      params: { [key: string]: unknown }
    ) => {
      setIsLoading(true);
      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetornoConciliacao>
      >(formatQueryPagegTable(endpoint, paginationData, params));
      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
          setIsLoading(false);
          return {
            registros: [] as Conta[],
            total: 0,
            valorTotalBruto: 0,
            totalTaxa: 0,
            valorTotalLiquido: 0,
          };
        }

        if (response.sucesso && response.dados) {
          setIsLoading(false);
          return response.dados;
        }
      }
      setIsLoading(false);
      return {
        registros: [] as Conta[],
        total: 0,
        valorTotalBruto: 0,
        totalTaxa: 0,
        valorTotalLiquido: 0,
      };
    },
    []
  );

  const resetPageAndReloadTable = () => {
    if (refVirtualizedTable?.current) {
      refVirtualizedTable.current?.resetPage?.();
      refVirtualizedTable.current?.refresh?.();
    }
  };

  const createFiltersForRequest = useCallback(() => {
    const filters = getValues();

    const newFilters = {
      ...(filters?.dataCompensacaoInicio && {
        dataCompensacaoInicio: new Date(
          filters.dataCompensacaoInicio
        ).toISOString(),
      }),
      ...(filters?.dataCompensacaoFim && {
        dataCompensacaoFim: new Date(filters.dataCompensacaoFim).toISOString(),
      }),
      ...(filters?.dataEmissaoInicio && {
        dataEmissaoInicio: new Date(filters.dataEmissaoInicio).toISOString(),
      }),
      ...(filters?.dataEmissaoFim && {
        dataEmissaoFim: new Date(filters.dataEmissaoFim).toISOString(),
      }),
      ...(filters?.dataPagamentoInicio && {
        dataPagamentoInicio: new Date(
          filters.dataPagamentoInicio
        ).toISOString(),
      }),
      ...(filters?.dataPagamentoFim && {
        dataPagamentoFim: new Date(filters.dataPagamentoFim).toISOString(),
      }),
      ...(filters?.dataVencimentoInicio && {
        dataVencimentoInicio: new Date(
          filters.dataVencimentoInicio
        ).toISOString(),
      }),
      ...(filters?.dataVencimentoFim && {
        dataVencimentoFim: new Date(filters.dataVencimentoFim).toISOString(),
      }),
      ...(filters?.formaPagamentoBaixaId && {
        formaPagamentoBaixaId: filters.formaPagamentoBaixaId,
      }),
      ...(filters?.codigoAutorizacao && {
        codigoAutorizacao: filters.codigoAutorizacao,
      }),
      ...(filters?.conciliado !== null
        ? { conciliado: filters.conciliado }
        : {}),
    };
    return newFilters;
  }, [getValues]);

  const openModalCompartilharRelatorios = async () => {
    setIsLoading(true);
    const dadosRelatorio = createFiltersForRequest();
    await compartilharRelatorio(dadosRelatorio);
    setIsLoading(false);
  };

  const clearFilters = () => {
    reset(formDefaultValues);
    setHasFilters(false);
  };

  const loadMoreRows = useCallback(
    async ({
      currentPage,
      pageSize,
      orderColumn,
      orderDirection,
    }: LoadMoreRowsParams) => {
      setIsLoading(true);
      const paginationData = {
        currentPage,
        pageSize,
        orderColumn,
        orderDirection,
      };
      const params = createFiltersForRequest();

      const response = await fetchMoreRows(
        ConstanteEnderecoWebservice.LISTAR_CONCILIAR,
        paginationData,
        params
      );

      if (response && response.registros) {
        const newListContas = response?.registros?.map((conta) => ({
          ...conta,
          isChecked: false,
        }));

        newListContas?.forEach(
          ({ dataCompensacao, idMovimentacaoFinanceiraBaixa, taxa }) => {
            setValue(
              `dataCompensacao.${idMovimentacaoFinanceiraBaixa}` as const,
              dataCompensacao
            );
            setValue(
              `taxa.${idMovimentacaoFinanceiraBaixa}` as const,
              taxa.toFixed(2)
            );
          }
        );
        setListContas((prev) =>
          currentPage === 1 ? newListContas : [...prev, ...newListContas]
        );

        setTotalizadoresListagem({
          quantidadeContas: response.total || 0,
          valorTotalBruto: response.valorTotalBruto || 0,
          totalTaxa: response.totalTaxa || 0,
          valorTotalLiquido: response.valorTotalLiquido || 0,
        });
      }

      setIsLoading(false);
    },
    [createFiltersForRequest, fetchMoreRows, setValue]
  );

  const filtersSubmit = async (data: FormData) => {
    setIsLoading(true);
    const hasNewFilters = !shallowEqual(data, formDefaultValues);
    if (hasNewFilters) setHasFilters(true);
    await loadMoreRows({
      currentPage: 1,
      pageSize: 25,
      orderColumn: 'DataEmissaoOperacao',
      orderDirection: 'desc',
    });
    setIsLoading(false);
  };

  const anteciparContasSelecionadas = async (data: AntecipacaoRequest) => {
    setIsLoading(true);
    const textContaOuContas =
      totalSelectedContas > 1 ? 'Contas antecipadas' : 'Conta antecipada';
    const listaMovimentacaoFinanceiraBaixaId = selectedContas.map(
      (conta) => conta.idMovimentacaoFinanceiraBaixa
    );

    const requestBody = {
      ...data,
      listaMovimentacaoFinanceiraBaixaId,
    };

    const response = await api.put<void, ResponseApi>(
      ConstanteEnderecoWebservice.CONCILIACAO_ANTECIPACAO,
      requestBody
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
        setIsLoading(false);
      }
      if (response.sucesso) {
        toast.success(`${textContaOuContas} com sucesso`);
        if (refVirtualizedTable?.current) {
          resetPageAndReloadTable();
        }
      }
    }
    setIsLoading(false);
  };

  const openModalAnteciparContas = async () => {
    setIsLoading(true);
    try {
      const {
        taxaAntecipacao,
        clienteFornecedorId,
        dataAntecipacao,
        formaPagamentoId,
        tipoTaxa,
        contaFinanceiraId,
      } = await ModalAnteciparContas({
        totalSelecionado: totalSelectedContas,
        valorOriginal: totalValorLiquidoFromSelectedContas,
        totalEmTaxas: totalTaxasFromSelectedContas,
      });

      const hasTaxaComValorOuTaxaZerada =
        taxaAntecipacao || taxaAntecipacao === 0;

      const isTaxaEmPorcentagem = tipoTaxa === TipoValorEnum.PORCENTAGEM;

      let custoAntecipacao = 0;
      if (hasTaxaComValorOuTaxaZerada) {
        custoAntecipacao = isTaxaEmPorcentagem
          ? Number(
              (
                (totalValorLiquidoFromSelectedContas * taxaAntecipacao) /
                100
              ).toFixed(2)
            )
          : taxaAntecipacao;
      }

      const dataAntecipacaoFormatada = new Date(dataAntecipacao).toISOString();
      await anteciparContasSelecionadas({
        dataAntecipacao: dataAntecipacaoFormatada,
        clienteFornecedorId,
        custoAntecipacao,
        formaPagamentoId,
        contaFinanceiraId,
      });
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  };

  const openModalBuscaAvancada = async () => {
    setIsLoading(true);
    const currentFilters = {
      dataCompensacaoInicio,
      dataCompensacaoFim,
      dataEmissaoInicio,
      dataEmissaoFim,
      dataPagamentoInicio,
      dataPagamentoFim,
      dataVencimentoInicio,
      dataVencimentoFim,
      formaPagamentoBaixaId,
      codigoAutorizacao,
      conciliado,
    };
    try {
      const newFilters = await ModalBuscaAvancada({
        currentFilters,
        clearFilters,
      });
      if (newFilters) {
        if (refVirtualizedTable?.current) {
          refVirtualizedTable.current?.resetPage?.();
        }
        reset(newFilters);
        filtersSubmit(newFilters);
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const openModalAlterarEmMassa = async () => {
    setIsLoading(true);
    try {
      const {
        taxaEmMassa,
        dataCompensacaoEmMassa,
        tipoTaxa,
      } = await ModalAlterarEmMassa({
        totalSelecionado: totalSelectedContas,
        totalBruto: totalValorBrutoFromSelectedContas,
        totalEmTaxas: totalTaxasFromSelectedContas,
      });
      const hasTaxaComValorOuTaxaZerada = taxaEmMassa || taxaEmMassa === 0;

      if (!hasTaxaComValorOuTaxaZerada && !dataCompensacaoEmMassa) return;

      setListContas((prev) => {
        return prev.map((conta) => {
          if (conta.isChecked) {
            const isTaxaEmPorcentagem = tipoTaxa === TipoValorEnum.PORCENTAGEM;
            const valueTaxaEmMassa = isTaxaEmPorcentagem
              ? (conta.valor * taxaEmMassa) / 100
              : taxaEmMassa;

            const newValorLiquido =
              valueTaxaEmMassa > conta.valor
                ? conta.valor
                : conta.valor - valueTaxaEmMassa;
            const newTaxa =
              valueTaxaEmMassa > conta.valor ? conta.valor : valueTaxaEmMassa;

            if (hasTaxaComValorOuTaxaZerada) {
              setValue(`taxa.${conta.idMovimentacaoFinanceiraBaixa}`, newTaxa);
            }
            if (dataCompensacaoEmMassa) {
              setValue(
                `dataCompensacao.${conta.idMovimentacaoFinanceiraBaixa}`,
                dataCompensacaoEmMassa
              );
            }
            return {
              ...conta,
              taxa: hasTaxaComValorOuTaxaZerada ? newTaxa : conta.taxa,
              valorLiquido: hasTaxaComValorOuTaxaZerada
                ? newValorLiquido
                : conta.valor,
              isChecked: true,
              dataCompensacao: dataCompensacaoEmMassa
                ? new Date(dataCompensacaoEmMassa).toISOString()
                : conta.dataCompensacao,
            };
          }

          return conta;
        });
      });
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  };

  const confirmarDataEmissao = formMethods.handleSubmit((data) => {
    filtersSubmit(data);
  });

  const handleConciliarEmMassa = async () => {
    setIsLoading(true);

    const requestBody = selectedContas.map((conta) => ({
      id: conta.idMovimentacaoFinanceiraBaixa,
      conciliado: true,
      taxaTransacaoAtualizada: conta.taxa,
      dataCompensacaoAtualizada: new Date(conta.dataCompensacao)?.toISOString(),
    }));

    const response = await api.put<void, ResponseApi>(
      ConstanteEnderecoWebservice.CONCILIACAO_ALTERAR,
      requestBody
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }
      if (response.sucesso) {
        toast.success('Contas conciliadas com sucesso');
        if (refVirtualizedTable?.current) {
          resetPageAndReloadTable();
        }
      }
    }
    setIsLoading(false);
  };

  const handleConciliarUmaConta = async (conta: Conta) => {
    setIsLoading(true);
    const requestBody = [
      {
        id: conta.idMovimentacaoFinanceiraBaixa,
        conciliado: true,
        taxaTransacaoAtualizada: conta.taxa,
        dataCompensacaoAtualizada: new Date(
          conta.dataCompensacao
        )?.toISOString(),
      },
    ];

    const response = await api.put<void, ResponseApi>(
      ConstanteEnderecoWebservice.CONCILIACAO_ALTERAR,
      requestBody
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }
      if (response.sucesso) {
        toast.success('Conta conciliada com sucesso');
        if (refVirtualizedTable?.current) {
          resetPageAndReloadTable();
        }
        setIsLoading(false);
      }
    }
    setIsLoading(false);
  };

  const handleCancelarAntecipacao = async (id: string) => {
    setIsLoading(true);
    const response = await api.put<void, ResponseApi>(
      ConstanteEnderecoWebservice.CONCILIACAO_CANCELAR_ANTECIPACAO,
      null,
      { params: { id } }
    );
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }
      if (response.sucesso) {
        toast.success('Antecipação cancelada com sucesso');
        if (refVirtualizedTable?.current) {
          resetPageAndReloadTable();
        }
      }
    }
  };

  const handleDesconciliar = async (id: string) => {
    setIsLoading(true);
    const response = await api.put<void, ResponseApi>(
      ConstanteEnderecoWebservice.CONCILIACAO,
      {
        id,
        conciliado: false,
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }
      if (response.sucesso) {
        toast.success('Conta desconciliada com sucesso');
        if (refVirtualizedTable?.current) {
          resetPageAndReloadTable();
        }
      }
    }
    setIsLoading(false);
  };

  const openModalAvisoCancelamentoAntecipacao = async (id: string) => {
    await ModalConfirmacao({
      callback: async (ok: boolean) => {
        if (ok) {
          await handleCancelarAntecipacao(id);
        }
      },

      cancelButtonText: 'Fechar',
      confirmButtonText: 'Confirmar',
      title: 'Cancelar antecipação',
      text: (
        <>
          Esta ação irá cancelar a antecipação de todas as contas e cancelar o
          lançamento de contas a pagar da taxa de antecipação. Deseja confirmar
          o cancelamento?
        </>
      ),
    });
  };

  return (
    <FormProvider {...formMethods}>
      <ConciliacaoContasContext.Provider
        value={{
          formMethods,
          listContas,
          totalizadoresListagem,
          isLoading,
          hasFilters,
          refVirtualizedTable,
          isListContaEmpty,
          hasSomenteContasConciliadas,
          isSelectedContasEmpty,
          isAllContasSelected,
          totalSelectedContas,
          todasContasPodemSerAntecipadas,
          totalTaxasFromSelectedContas,
          totalValorLiquidoFromSelectedContas,
          totalValorBrutoFromSelectedContas,
          fetchMoreRows,
          resetPageAndReloadTable,
          openModalCompartilharRelatorios,
          clearFilters,
          loadMoreRows,
          filtersSubmit,
          openModalBuscaAvancada,
          openModalAlterarEmMassa,
          openModalAnteciparContas,
          confirmarDataEmissao,
          handleConciliarEmMassa,
          handleConciliarUmaConta,
          handleDesconciliar,
          openModalAvisoCancelamentoAntecipacao,
          setListContas,
        }}
      >
        {children}
      </ConciliacaoContasContext.Provider>
    </FormProvider>
  );
};

export const useContextConciliacaoContas = (): ConciliacaoContasContextProps => {
  const context = useContext(ConciliacaoContasContext);
  if (!context) {
    throw new Error(
      'useContextConciliacaoContas must be used within a ConciliacaoContasProvider'
    );
  }
  return context;
};
