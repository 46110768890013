import React, { useState } from 'react';
import {
  ModalContent,
  ModalBody,
  Flex,
  Button,
  Heading,
  Text,
  useMediaQuery,
  Box,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import Input from 'components/PDV/Input';

type ModalConfirmarProps = {
  textoMensagem?: string | React.ReactNode;
  isConfirmar?: boolean;
  title?: string;
  callback: () => void;
  colorButtonConfirm?: string;
  widthButtonConfirm?: string;
  heightModal?: string;
  labelButtonCancelar?: string;
  labelButtonConfirmar?: string;
  textToConfirm?: string;
  colorTitle?: string;
  colorScheme?: string;
  onCancel?: () => void;
};

type ModalProps = Omit<ModalConfirmarProps, 'children' | 'isOpen' | 'onClose'> &
  InstanceProps<ModalConfirmarProps>;

export const ModalConfirmar = create<ModalProps>(
  ({
    textoMensagem,
    labelButtonCancelar,
    onCancel,
    labelButtonConfirmar,
    colorButtonConfirm = 'white',
    title,
    isConfirmar = false,
    colorTitle = 'primary.50',
    colorScheme = 'purple',
    textToConfirm = 'CADASTRAR',
    heightModal = '380px',
    widthButtonConfirm = '200px',
    callback,
  }) => {
    const [isOpen, setIsOpen] = useState(true);

    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    const formMethods = useForm();

    const campoConfirmarWatch = formMethods.watch('campoConfirmar');

    const confirmationFieldIsCorrect = campoConfirmarWatch === textToConfirm;

    const handleConfirm = () => {
      setIsOpen(false);
      callback();
    };

    const handleCancel = () => {
      if (onCancel) {
        onCancel();
      }
      setIsOpen(false);
    };

    return (
      <ModalPadraoChakra
        onClose={() => {
          setIsOpen(false);
        }}
        isOpen={isOpen}
        isCentered
        size="2xl"
        autoFocus={false}
      >
        <ModalContent
          background="gray.50"
          borderRadius={isLargerThan900 ? 'md' : '0'}
          marginBottom={isLargerThan900 ? '3.75rem' : '0'}
          marginTop={isLargerThan900 ? '3.75rem' : '0'}
          w={isLargerThan900 ? '640px' : 'full'}
          h={isLargerThan900 ? heightModal : 'full'}
        >
          <ModalBody p={0}>
            <Flex flexDirection="column">
              <Flex
                justifyContent="flex-start"
                flexDirection="column"
                px="40px"
                py="40px"
              >
                <Heading
                  id="tituloModal"
                  as="h2"
                  fontSize="20px"
                  color={colorTitle}
                  fontWeight="semibold"
                  textAlign="initial"
                >
                  {title || 'Desistiu do cadastro?'}
                </Heading>
                <Text
                  mt="22px"
                  fontSize="14px"
                  color="black"
                  whiteSpace="pre-wrap"
                >
                  {textoMensagem ||
                    'Todos os dados que você preencheu serão cancelados.'}
                </Text>
                {isConfirmar && (
                  <Box mt="30px">
                    <FormProvider {...formMethods}>
                      <Text textColor="gray.700" fontSize="14px">
                        Digite
                        <Text
                          px="7px"
                          py="4px"
                          mx="5px"
                          display="inline"
                          bgColor="gray.200"
                        >
                          {textToConfirm}
                        </Text>
                        <Text display="inline">
                          no campo abaixo para confirmar a ação
                        </Text>
                      </Text>
                      <Input
                        mt="6px"
                        maxLength={textToConfirm?.length}
                        id="campoConfirmar"
                        name="campoConfirmar"
                        colSpan={12}
                        placeholder="Digite com letras maiúsculas"
                      />
                    </FormProvider>
                  </Box>
                )}
              </Flex>
            </Flex>
            <Flex display="flex" justifyContent="center" gap="24px" mt="8px">
              <Button
                onClick={handleCancel}
                variant="outlineDefault"
                colorScheme="gray"
                fontWeight="500"
                borderRadius="20px"
                height="32px"
              >
                {labelButtonCancelar || 'Voltar ao cadastro'}
              </Button>
              <Button
                onClick={handleConfirm}
                variant="solid"
                fontWeight="500"
                borderRadius="20px"
                height="32px"
                w={widthButtonConfirm}
                isDisabled={!!(isConfirmar && !confirmationFieldIsCorrect)}
                color={colorButtonConfirm}
                colorScheme={colorScheme}
                fontSize="14px"
              >
                {labelButtonConfirmar || 'Sim, desisti'}
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
