import { Flex, Text, Box, Button } from '@chakra-ui/react';

import AsyncSelect from 'components/PDV/Select/AsyncSelectPadrao';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';

import { Content } from '../Content';
import ControlledList from '../ControlledList';
import { useTabProdutoFavorito } from './hooks';

export const TabProdutosFavoritos = () => {
  const {
    getProdutoCorOptions,
    optionsLoading,
    handleCadastrarProduto,
    listProducts,
    handleRemoverProduto,
    productItem,
  } = useTabProdutoFavorito();

  return (
    <Box>
      <Flex
        justifyContent="space-between"
        alignItems="end"
        direction={{ base: 'column', md: 'row' }}
        gap={2}
      >
        <Box width={{ base: 'full', md: '50%' }}>
          <AsyncSelect
            id="productItem"
            name="productItem"
            label="Descrição do produto"
            placeholder="Selecione o produto "
            isLoading={optionsLoading}
            handleGetOptions={getProdutoCorOptions}
            asControlledByObject
            withoutDefaultOptions
            autoFocus
          />
        </Box>
        <Button
          w={['full', 'full', '200px']}
          onClick={handleCadastrarProduto}
          colorScheme="secondary"
          isDisabled={!productItem}
        >
          Cadastrar produto
        </Button>
      </Flex>
      <Flex
        mt="24px"
        px="24px"
        mb="5px"
        justifyContent="space-between"
        w="full"
      >
        <Text>Produto</Text>
        <Text textAlign="right">Ações</Text>
      </Flex>
      <ControlledList
        name="listProducts"
        renderList={({ value }) => {
          return value?.length > 0 ? (
            <>
              {listProducts.map((itemProduto, index) => (
                <Content justifyContent="space-between" mb="5px">
                  <Text fontSize="14px">{itemProduto.nome}</Text>
                  <Box>
                    <ActionsMenu
                      menuZIndex="modal"
                      items={[
                        {
                          content: 'Remover',
                          onClick: () => handleRemoverProduto(index),
                        },
                      ]}
                    />
                  </Box>
                </Content>
              ))}
            </>
          ) : (
            <Content>Nenhum produto foi adicionado</Content>
          );
        }}
      />
    </Box>
  );
};
