import { Box, Flex, Text } from '@chakra-ui/react';
import Lottie from 'lottie-react';

import Sucesso from 'assets/animations/acao_sucesso.json';

type NotaAprovadaProps = {
  isMultipleVendas: boolean;
};

export const NotaAprovada = ({ isMultipleVendas }: NotaAprovadaProps) => {
  return (
    <>
      <Flex
        bg="gray.100"
        w="full"
        h="full"
        justifyContent="center"
        alignItems="center"
      >
        <Flex alignItems="center" justifyContent="center">
          <Box>
            <Lottie
              animationData={Sucesso}
              loop
              style={{
                width: '209px',
                height: '209px',
              }}
            />
          </Box>
          <Box ml="40px" w={isMultipleVendas ? '214px' : '280px'}>
            <Text display="inline" color="gray.700" fontSize="20px">
              {isMultipleVendas
                ? 'Todas as notas foram emitidas'
                : 'Nota fiscal emitida'}{' '}
              com{' '}
              <Text fontWeight="bold" display="inline" color="violet.700">
                Sucesso!
              </Text>
            </Text>
          </Box>
        </Flex>
      </Flex>
    </>
  );
};
