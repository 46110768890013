import auth from 'modules/auth';
import ConstanteFuncionalidades from 'constants/permissoes';
import { enumRelatorioEstoque } from 'constants/enum/enumRelatorioEstoque';

export const validarTipoRelatorioEstoque = (item: {
  value: number;
}): boolean => {
  let tipoRelatorio = '';
  const enumRelatorio = enumRelatorioEstoque;

  switch (item.value) {
    case enumRelatorio.POSICAO_ESTOQUE:
      tipoRelatorio = ConstanteFuncionalidades.RELATORIO_ESTOQUE_SEM_PRECO;
      break;
    case enumRelatorio.POSICAO_ESTOQUE_PRECO_VENDA:
      tipoRelatorio = ConstanteFuncionalidades.RELATORIO_ESTOQUE_PRECO_VENDA;
      break;
    case enumRelatorio.POSICAO_ESTOQUE_PRECO_CUSTO:
      tipoRelatorio = ConstanteFuncionalidades.RELATORIO_ESTOQUE_PRECO_CUSTO;
      break;
    default:
      break;
  }

  const possuiFuncionalidade = auth.possuiPermissao(tipoRelatorio).permitido;

  return possuiFuncionalidade;
};
