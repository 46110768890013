import React, { useRef, useState, useEffect } from 'react';
import {
  FlexProps,
  Flex,
  Box,
  Icon,
  Text,
  VStack,
  Image as ImageChakra,
} from '@chakra-ui/react';

import { ImagemAdicionarIcon } from 'icons';

export type ListImagensProps = {
  linkImagem: string;
  isForaPadrao: boolean;
  tamanhoImagem: { widthImage: number; heightImage: number };
};

interface ImageUploaderProps extends FlexProps {
  valueTamanhoImg?: number;
  handleSalvarImagem: (value: ListImagensProps) => void;
  totalBytes?: number;
  sizeIcon?: string;
  isAlterarImagem?: boolean;
  multiple?: boolean;
  imageDefault?: string;
  deleteCallback?: () => void;
  fontSizeInstructionText?: string;
  fontSizeActionText?: string;
}

function ImageUploader({
  valueTamanhoImg = 1200,
  totalBytes = 350,
  sizeIcon = '40px',
  multiple = true,
  handleSalvarImagem,
  imageDefault = '',
  isAlterarImagem = false,
  deleteCallback,
  fontSizeInstructionText = 'sm',
  fontSizeActionText = 'sm',
  ...rest
}: ImageUploaderProps) {
  const [valueImage, setValueImage] = useState('');
  const [onMouseOver, setOnMouseOver] = useState(false);

  const inputFileRef = useRef<HTMLInputElement>(null);

  const typeImagensPermitidas = [
    'image/webp',
    'image/avif',
    'image/png',
    'image/jpeg',
  ];

  const handleAdicionarImagem = (value: ListImagensProps) => {
    if (handleSalvarImagem) {
      handleSalvarImagem(value);
    }
  };

  const onloadImage = (
    file: File,
    isForaPadrao: boolean,
    tamanhoImagem: { widthImage: number; heightImage: number }
  ) => {
    const reader = new FileReader();
    reader.onload = () => {
      handleAdicionarImagem({
        isForaPadrao,
        linkImagem: reader.result as string,
        tamanhoImagem: {
          widthImage: tamanhoImagem.widthImage,
          heightImage: tamanhoImagem.heightImage,
        },
      });
      if (isForaPadrao === false) {
        setValueImage(reader.result as string);
      }
    };
    reader.readAsDataURL(file);
  };

  const convertFileInJPEG = (image: HTMLImageElement, file: File) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = image.width;
    canvas.height = image.height;

    ctx?.drawImage(image, 0, 0, image.width, image.height);

    canvas.toBlob(
      (blob) => {
        if (blob === null) {
          return;
        }

        const jpegFile = new File(
          [blob],
          file.name.replace(/\.[^/.]+$/, '.jpeg'),
          {
            type: 'image/jpeg',
            lastModified: Date.now(),
          }
        );

        const tamanhoImagem = {
          heightImage: canvas.height,
          widthImage: canvas.width,
        };
        if (image.width <= valueTamanhoImg && image.height <= valueTamanhoImg) {
          const maxTotalBytes = totalBytes * 1024;
          if (file.size <= maxTotalBytes) {
            onloadImage(jpegFile, false, tamanhoImagem);
          } else {
            onloadImage(jpegFile, true, tamanhoImagem);
          }
        } else {
          onloadImage(jpegFile, true, tamanhoImagem);
        }
      },
      'image/jpeg',
      0.9
    );
  };

  const dropImage = (file: File) => {
    if (file && file.type.startsWith('image/')) {
      const image = new Image();

      image.onload = () => {
        convertFileInJPEG(image, file);
      };

      image.src = URL.createObjectURL(file);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    const amountFiles = e.dataTransfer.files.length;
    const { files } = e.dataTransfer;

    const typeImagemPermitido = typeImagensPermitidas.some((typeImagem) =>
      typeImagem.includes(files[0].type)
    );

    if (!typeImagemPermitido) {
      return;
    }

    const newFiles = Array.from({ length: amountFiles });
    newFiles.forEach((_, index) => {
      dropImage(files[index]);
    });
  };

  const handleFile = (file: File) => {
    if (file) {
      const image = new Image();
      image.onload = () => {
        convertFileInJPEG(image, file);
      };

      image.src = URL.createObjectURL(file);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (imageDefault) {
      setValueImage(imageDefault);
    }
  }, [imageDefault]);

  return (
    <>
      {valueImage && isAlterarImagem ? (
        <Flex
          position="relative"
          onMouseOverCapture={() => setOnMouseOver(true)}
          onMouseLeave={() => setOnMouseOver(false)}
          {...rest}
        >
          <ImageChakra
            w="full"
            h="full"
            src={valueImage}
            alt="imagem da variação"
          />

          {onMouseOver && (
            <Flex
              justifyContent="center"
              h="full"
              w="full"
              alignItems="center"
              top="0"
              bg="gray.50"
              // px="20px"
              position="absolute"
            >
              <Box bg="white" py="5px">
                <Box
                  p="7px"
                  _hover={{
                    bg: 'gray.100',
                  }}
                  w="full"
                  bg="white"
                  onClick={() => {
                    if (deleteCallback) {
                      deleteCallback();
                    }
                    setValueImage('');
                  }}
                >
                  Excluir imagem
                </Box>
                <Box
                  _hover={{
                    bg: 'gray.100',
                  }}
                  p="7px"
                  w="full"
                  bg="white"
                  onClick={() => {
                    if (inputFileRef?.current?.click) {
                      inputFileRef.current.click();
                    }
                  }}
                >
                  Adicionar nova imagem
                </Box>
              </Box>
            </Flex>
          )}
        </Flex>
      ) : (
        <Flex
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          {...rest}
          onClick={() => {
            if (inputFileRef?.current?.click) {
              inputFileRef.current.click();
            }
          }}
        >
          <Flex justifyContent="center" cursor="pointer">
            <VStack>
              <Icon fontSize={sizeIcon} as={ImagemAdicionarIcon} />
              <Text
                w="60%"
                mb="3px"
                fontSize={fontSizeInstructionText}
                textAlign="center"
                lineHeight="15px"
              >
                Arraste e solte seu arquivo aqui
              </Text>
              <Text
                mt="10%"
                w="140px"
                textAlign="center"
                fontSize={fontSizeActionText}
                lineHeight="15px"
                color="violet.500"
              >
                Escolher arquivo
              </Text>
            </VStack>
          </Flex>
        </Flex>
      )}
      <input
        tabIndex={-1}
        onChange={(event) => {
          const { files } = event.target;

          if (!files || files.length === 0) {
            return;
          }

          const newFiles = Array.from({ length: files.length });

          newFiles.forEach((_, index) => {
            handleFile(files[index]);
          });
        }}
        style={{
          display: 'none',
        }}
        multiple={multiple}
        ref={inputFileRef}
        type="file"
        accept="image/webp, image/avif, image/png, image/jpeg"
      />
    </>
  );
}

export default ImageUploader;
