import React, { useEffect, useRef, useState } from 'react';
import {
  ModalContent,
  ModalBody,
  Flex,
  ScaleFade,
  ModalCloseButton,
  ModalHeader,
  FormLabel,
  ModalFooter,
  Button,
  Text,
  Divider,
  Box,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import ManterFoco from 'components/Geral/ManterFoco';
import InputDateRange from 'components/PDV/InputDateRange';
import { FormProvider, useForm } from 'react-hook-form';
import InputInteger from 'components/PDV/InputInteger';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import { statusOperacaoOptions } from 'constants/enum/statusOperacao';
import { identificacaoOperacaoOptions } from 'constants/enum/identificacaoTipoOperacao';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { FormData } from '../validationForm';

interface ListSelectProps {
  id: string;
  nome: string;
  value: string;
  label: string;
}

interface ModalProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  handleSubmitModalBuscaAvancada: (data: FormData) => void;
  currentFilters: FormData;
  handleReset: () => void;
  lojaId: string;
}

const ModalBuscaAvancadaEstoque = ({
  isOpen,
  setIsOpen,
  currentFilters,
  handleSubmitModalBuscaAvancada,
  lojaId,
  handleReset,
}: ModalProps) => {
  const formMethods = useForm<FormData>();

  const [localEstoqueSelect, setLocalEstoqueSelect] = useState<
    ListSelectProps[]
  >([]);

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    handleSubmitModalBuscaAvancada(data);
  });

  const { t } = useTranslation();

  const latestProps = useRef({ reset: formMethods.reset, currentFilters });
  useEffect(() => {
    latestProps.current = { reset: formMethods.reset, currentFilters };
  });

  useEffect(() => {
    if (isOpen) {
      latestProps.current.reset(latestProps.current.currentFilters);
    }
  }, [isOpen]);

  useEffect(() => {
    const params = {
      lojaId,
      statusConsulta: currentFilters.status,
    };
    async function listarLocalEstoque() {
      const response = await api.get<void, ResponseApi<ListSelectProps[]>>(
        ConstanteEnderecoWebservice.LOCAL_ESTOQUE_LISTAR_SELECT,
        {
          params,
        }
      );

      if (response) {
        if (response.sucesso) {
          setLocalEstoqueSelect(response.dados);
        }
      }
    }
    listarLocalEstoque();
  }, [lojaId, currentFilters.status]);

  return (
    <ModalPadraoChakra
      onClose={() => {
        setIsOpen(false);
      }}
      isOpen={isOpen}
      isCentered
      size="2xl"
    >
      <ModalContent
        marginBottom={{ base: 0, sm: '3.75rem' }}
        marginTop={{ base: 0, sm: '3.75rem' }}
        h="unset"
        maxW="600px"
      >
        <ManterFoco
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <ScaleFade initialScale={1.5} in={isOpen}>
            <ModalBody p={0} h="100%">
              <Flex flexDirection="column" align="stretch" h="100%">
                <ModalContent p="3" m="2">
                  <ModalHeader>
                    <Text color="primary.50" fontSize="md">
                      Busca avançada
                    </Text>
                    <Text color="gray.700" fontSize="xs">
                      Utilize os filtros de busca para uma pesquisa específica.
                      Todos os campos são acumulativos.
                    </Text>
                  </ModalHeader>
                  <Divider
                    orientation="horizontal"
                    width="92%"
                    margin="0 auto"
                  />
                  <ModalCloseButton
                    fontSize="2xs"
                    border="none"
                    _hover={{ bg: 'none' }}
                    _focus={{ border: 'none' }}
                  />
                  <ModalBody pb="6" pt="6">
                    <FormProvider {...formMethods}>
                      <Box width={['100%', '100%', '100%', '100%']}>
                        <FormLabel>Período</FormLabel>
                        <InputDateRange
                          borderColor="gray.100"
                          borderRadius="md"
                          name="data"
                          startDateName="dataEmissaoInicio"
                          endDateName="dataEmissaoFim"
                          maxDate={new Date()}
                        />
                      </Box>

                      <Flex
                        flexDirection={['column', 'column', 'column', 'row']}
                        justifyContent="space-between"
                        pt="6"
                      >
                        <Box width={['100%', '100%', '100%', '48%']}>
                          <InputInteger
                            id="numeroOperacao"
                            name="numeroOperacao"
                            label={t('Identificador')}
                            placeholder="Número da movimentação"
                            borderColor="gray.100"
                            autoFocus
                          />
                        </Box>
                        <Box
                          width={['100%', '100%', '100%', '48%']}
                          pt={['6', '6', '6', '0']}
                        >
                          <SelectPadrao
                            id="status"
                            name="status"
                            label={t('Situação')}
                            options={statusOperacaoOptions}
                          />
                        </Box>
                      </Flex>

                      <Flex
                        flexDirection={['column', 'column', 'column', 'row']}
                        justifyContent="space-between"
                        pt={6}
                      >
                        <Box width={['100%', '100%', '100%', '48%']}>
                          <SelectPadrao
                            id="identificacaoTipoOperacao"
                            name="identificacaoTipoOperacao"
                            label={t('Operação')}
                            placeholder="Selecione"
                            options={identificacaoOperacaoOptions}
                            isClearable
                          />
                        </Box>
                        <Box
                          width={['100%', '100%', '100%', '48%']}
                          pt={['6', '6', '6', '0']}
                        >
                          <SelectPadrao
                            id="localEstoqueId"
                            name="localEstoqueId"
                            label={t('Local de estoque')}
                            placeholder="Selecione"
                            options={localEstoqueSelect.map(({ id, nome }) => {
                              return (
                                {
                                  label: nome,
                                  value: id,
                                } || {}
                              );
                            })}
                            isClearable
                          />
                        </Box>
                      </Flex>
                    </FormProvider>
                  </ModalBody>
                  <Divider
                    orientation="horizontal"
                    width="92%"
                    margin="0 auto"
                    pt={6}
                  />

                  <ModalFooter mt={6}>
                    <Flex
                      width="100%"
                      justifyContent={[
                        'center',
                        'center',
                        'flex-end',
                        'flex-end',
                      ]}
                    >
                      <Button
                        width={['120px', '120px', '150px', '150px']}
                        id="cancelar"
                        name="cancelar"
                        colorScheme="gray.100"
                        variant="outline"
                        borderRadius="md"
                        fontSize="sm"
                        type="button"
                        onClick={() => {
                          setIsOpen(false);
                          handleReset();
                        }}
                        mr={6}
                        _hover={{ bg: 'gray.50' }}
                      >
                        Limpar filtros
                      </Button>
                      <Button
                        width={['120px', '120px', '150px', '150px']}
                        id="pesquisar"
                        name="pesquisar"
                        backgroundColor="aquamarine.600"
                        borderRadius="md"
                        fontSize="sm"
                        color="white"
                        variant="success"
                        type="button"
                        _hover={{ bg: 'aquamarine.700' }}
                        onClick={() => {
                          handleSubmit();
                          setIsOpen(false);
                        }}
                      >
                        Pesquisar
                      </Button>
                    </Flex>
                  </ModalFooter>
                </ModalContent>
              </Flex>
            </ModalBody>
          </ScaleFade>
        </ManterFoco>
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export default ModalBuscaAvancadaEstoque;
