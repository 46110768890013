import { useState } from 'react';
import { Flex, Box, Text, Icon, Collapse } from '@chakra-ui/react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

import { valueTabs } from '../../validationForms';

export const ItemEcommerceMobile = () => {
  const [exibirEcommerce, setExibirEcommerce] = useState(-1);

  function handleExibirEcommerce(index: number) {
    setExibirEcommerce(exibirEcommerce === index ? -1 : index);
  }

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      borderRadius="5px"
      boxShadow="0px 0px 6px #00000034"
      bg="white"
      w="full"
    >
      {valueTabs.map((tabs, index) => (
        <Box
          py="20px"
          borderRadius="5px"
          w="full"
          px="15px"
          bg={exibirEcommerce === index ? 'gray.50' : 'white'}
        >
          <Flex
            cursor="pointer"
            justifyContent="space-between"
            onClick={() => handleExibirEcommerce(index)}
            w="100%"
            alignItems="center"
          >
            <Text fontSize="16px">{tabs.label}</Text>
            <Icon
              fontSize="20px"
              as={exibirEcommerce === index ? FiChevronUp : FiChevronDown}
            />
          </Flex>
          <Collapse in={exibirEcommerce === index} animateOpacity>
            <Box mt="10px" overflowX="auto">
              {tabs.component}
            </Box>
          </Collapse>
        </Box>
      ))}
    </Flex>
  );
};
