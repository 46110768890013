import { useEffect, useState } from 'react';
import { Text, VStack, Box, Flex, useMediaQuery } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useSmartPosEtapasContext } from 'store/SmartPos/SmartPosEtapasContext';
import { IdentificacaoEtapasSmartPos } from 'constants/enum/IdentificacaoEtapasSmartPos';
import {
  EnumEmissaoSenhas,
  EnumImprimirViaProducao,
} from 'constants/enum/enumsConfigSmartPos';
import { enumTipoImpressaoFichaSmartPos } from 'constants/enum/enumTipoImpressaoFichaSmartPos';
import {
  cadastrarTextoImpressaoSmartPos,
  getImpressaoSmartPos,
} from 'services/smartPos';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import { MenuFiscal } from 'icons';
import Input from 'components/PDV/Input';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import TextareaField from 'components/PDV/Textarea';

import { FooterSmartPos } from '../Layout/FooterSmartPos';
import { HeaderSmartPos } from '../Layout/Header';
import { FormData } from './validationForms';
import { ExibirImpressao } from './Components/ExibirImpressao';
import { ModalImpressao } from './ModalImpressao';

export const TextoImpressao = () => {
  const [tipoImpressao, setTipoImpressao] = useState(1);
  const [
    abrirModalVisualizarImpressao,
    setAbrirModalVisualizarImpressao,
  ] = useState(false);

  const {
    setActiveStep,
    imagem,
    animacaoLoading,
    setIsLoading,
  } = useSmartPosEtapasContext();

  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

  const formMethods = useForm<FormData>({
    defaultValues: {
      emissaoSenhas: EnumEmissaoSenhas.NAO_IMPRIMIR_SENHA,
    },
  });

  const { handleSubmit: submit, reset, watch } = formMethods;

  const emissaoSenhasWatch = watch('emissaoSenhas');

  const isFichaImpressao =
    tipoImpressao === enumTipoImpressaoFichaSmartPos.FICHA.value;
  const isTodasImpressoes =
    tipoImpressao === enumTipoImpressaoFichaSmartPos.FICHA_CUPOM.value;

  const handleSubmit = submit(async (data) => {
    setIsLoading(true);
    const response = await cadastrarTextoImpressaoSmartPos({
      mensagemFichaImpressao: data.mensagem,
      mensagemCupomImpressao: data.mensagemCupom,
      tituloImpressao: data.titulo,
      imprimirSenhaIdentificacao: data.emissaoSenhas,
      imprimirViaProducao: data.imprimirViaProducao,
    });

    if (!response) {
      setIsLoading(false);
    }
    if (response) {
      setActiveStep(IdentificacaoEtapasSmartPos.SENHA_ADM);
    }

    setIsLoading(false);
  });

  const handleVoltar = () => {
    setIsLoading(true);
    setActiveStep(IdentificacaoEtapasSmartPos.IMAGEM_PARA_IMPRESSAO);
  };

  useEffect(() => {
    const getTipoImpressao = async () => {
      const response = await api.get<void, ResponseApi<number>>(
        ConstanteEnderecoWebservice.INTEGRACAO_OBTER_TIPO_CUPOM_FICHA
      );
      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((aviso: string) => toast.warning(aviso));
        }
        if (response.sucesso) {
          if (response.dados === enumTipoImpressaoFichaSmartPos.NENHUM.value) {
            setActiveStep(IdentificacaoEtapasSmartPos.TIPO_IMPRESSAO);
          } else {
            setTipoImpressao(response.dados || 1);
          }
        }
      }
    };
    getTipoImpressao();
  }, [setActiveStep]);

  useEffect(() => {
    const getTipoImpressao = async () => {
      const response = await getImpressaoSmartPos();

      if (response !== null) {
        reset({
          emissaoSenhas: !!response?.imprimirSenhaIdentificacao,
          mensagem: response?.mensagemFichaImpressao,
          mensagemCupom: response?.mensagemCupomImpressao,
          titulo: response?.tituloImpressao,
          imprimirViaProducao: !!response?.imprimirViaProducao,
        });
      }
    };
    getTipoImpressao();
    animacaoLoading();
  }, [reset, animacaoLoading]);

  return (
    <FormProvider {...formMethods}>
      <Box overflowY="hidden" mb="56px">
        <HeaderSmartPos
          title="Textos para impressão"
          handleVoltar={handleVoltar}
          icon={MenuFiscal}
        />

        <Flex
          flexDirection={['column', 'row', 'row']}
          justifyContent={['flex-start', 'space-between', 'space-between']}
          w="full"
          h="auto"
          pl="20px"
        >
          <Box w={['full', '55%', '55%']}>
            <VStack mb="24px" color="primary.50" fontSize="14px" spacing="24px">
              <Text letterSpacing="0px">
                Personalize a impressão com dados da sua empresa. É possível
                inserir um título e um texto com observações. Todas as
                informações podem ser alteradas posteriormente. Veja o resultado
                final ao lado:
              </Text>
            </VStack>
            <VStack spacing="24px">
              <Input
                name="titulo"
                label="Título"
                placeholder={`Digite o cabeçalho ${
                  isTodasImpressoes
                    ? ''
                    : isFichaImpressao
                    ? 'da ficha'
                    : 'do Cupom'
                }`}
                colSpan={12}
              />
              {(isFichaImpressao || isTodasImpressoes) && (
                <TextareaField
                  id="mensagem"
                  name="mensagem"
                  label={
                    isTodasImpressoes ? 'Mensagem' : 'Mensagem para a ficha'
                  }
                  h="122px"
                  colSpan={12}
                  maxH="200px"
                  placeholder="Adicione as informações necessárias"
                />
              )}
              {!isFichaImpressao && (
                <TextareaField
                  id="mensagemCupom"
                  name="mensagemCupom"
                  label="Mensagem para o cupom"
                  h="122px"
                  colSpan={12}
                  maxH="200px"
                  placeholder="Adicione as informações necessárias"
                />
              )}
              {!isFichaImpressao && (
                <Flex w="100%" gap="12px">
                  <SelectPadrao
                    name="emissaoSenhas"
                    label="Emissão de senhas"
                    options={EnumEmissaoSenhas.properties}
                    colSpan={12}
                  />
                  <SelectPadrao
                    name="imprimirViaProducao"
                    label="Imprimir via de produção"
                    options={EnumImprimirViaProducao.properties}
                    colSpan={12}
                  />
                </Flex>
              )}
            </VStack>
          </Box>
          {isLargerThan700 ? (
            <Box
              borderRadius="5px"
              pl="45px"
              bg="gray.200"
              pr="45px"
              ml="40px"
              height="fit-content"
              py="30px"
            >
              <ExibirImpressao
                isTodasImpressoes={isTodasImpressoes}
                isFichaImpressao={isFichaImpressao}
                imagem={imagem}
                isImprimirSenha={emissaoSenhasWatch}
              />
            </Box>
          ) : (
            <Flex
              mt="24px"
              mb="10px"
              alignItems="center"
              justifyContent="center"
              w="full"
            >
              <Text
                color="primary.50"
                onClick={() => setAbrirModalVisualizarImpressao(true)}
                fontSize="14px"
                cursor="pointer"
                textDecoration="underline"
              >
                Pré-visualização da impressão
              </Text>
            </Flex>
          )}
        </Flex>
        <ModalImpressao
          isTodasImpressoes={isTodasImpressoes}
          isFichaImpressao={isFichaImpressao}
          imagem={imagem}
          isOpen={abrirModalVisualizarImpressao}
          onClose={() => setAbrirModalVisualizarImpressao(false)}
        />
      </Box>
      <FooterSmartPos
        mb={isTodasImpressoes ? '30px' : undefined}
        handleAvancar={handleSubmit}
      />
    </FormProvider>
  );
};
