type DirtyFieldsProps = {
  [x: string]: any;
};

export const useValidationFormDirtyFields = (dirtyFields: DirtyFieldsProps) => {
  const listDirtyFields = Object.keys(dirtyFields);

  const isFieldsHasValue = listDirtyFields.length > 0;

  return isFieldsHasValue;
};
