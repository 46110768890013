import StatusFiscaisEnum from 'constants/enum/fiscal/statusFiscal';
import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';
import StatusOperacaoEnum from 'constants/enum/statusOperacao';
import { CasasDecimais } from 'store/Padronizacao/Padronizacao';

import { CompartilharOperacao } from 'components/Modal/ModalCompartilhar/CompartilharOperacao';
import { ModalVisualizarOperacao } from 'components/Modal/ModalVisualizarOperacao';

import { ListVendasProps } from '../../validationForms';

export const useVendaItem = (
  vendaItem: ListVendasProps,
  casasDecimais: CasasDecimais,
  handleGerarNotaFiscal: (item: ListVendasProps[]) => void
) => {
  const itemNotaFiscal = {
    content: 'Emitir nota fiscal',
    onClick: () => handleGerarNotaFiscal([vendaItem]),
  };

  const listVendaItem =
    vendaItem.listaIntegracaoPendenciaPedido?.map((item) => ({
      ...item,
      possuiPendencia: true,
    })) || [];

  const itemVerDetalhe = {
    content: 'Ver detalhes',
    onClick: () => {
      ModalVisualizarOperacao({
        casasDecimais,
        operacaoId: vendaItem.operacaoId,
        identificacaoTipoOperacao: vendaItem.identificacaoTipoOperacao,
      });
    },
  };

  const isPendenceCpf = (vendaItem?.listaIntegracaoPendenciaPedido || []).some(
    (itemPendencia) => itemPendencia.clienteFornecedorId === null
  );

  const hasPendencia =
    (vendaItem?.listaIntegracaoPendenciaPedido || [])?.length > 0 &&
    isPendenceCpf;

  const listaMovimentacaoFinanceira =
    vendaItem?.listaMovimentacaoFinanceira || [];

  const isNotaFiscalRejeitada =
    vendaItem.statusNotaFiscal === StatusFiscaisEnum.Rejeitada;

  const isNotaFiscalAltorizada =
    vendaItem.statusNotaFiscal === StatusFiscaisEnum.Autorizada;

  const isOperacaoCancelada =
    vendaItem.status === StatusOperacaoEnum.CANCELADA ||
    vendaItem.situacaoPedidoZendar
      ?.toLowerCase()
      .includes('cancelado'.toLowerCase());

  const isVenda =
    vendaItem.identificacaoTipoOperacao === IdentificacaoTipoOperacaoEnum.VENDA;

  const isCancelPedido =
    vendaItem?.status === null &&
    vendaItem?.situacaoPedidoZendar?.includes('Cancelado');

  const backgroundVenda = () => {
    if (hasPendencia && !isOperacaoCancelada) {
      return 'yellow.400';
    }
    if (isNotaFiscalRejeitada) {
      return 'red.100';
    }
    if (isOperacaoCancelada) {
      return 'gray.50';
    }

    return 'white';
  };

  const handleCompartilharOperacao = (operacaoId: string) => {
    CompartilharOperacao({
      isOpen: true,
      operacaoId,
    });
  };

  return {
    backgroundVenda,
    handleCompartilharOperacao,
    isVenda,
    isOperacaoCancelada,
    listaMovimentacaoFinanceira,
    hasPendencia,
    isNotaFiscalRejeitada,
    isNotaFiscalAltorizada,
    itemVerDetalhe,
    itemNotaFiscal,
    isCancelPedido,
    listVendaItem,
  };
};
