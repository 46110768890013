import styled, { css } from 'styled-components';
import { Form } from 'react-bootstrap';
import { LupaIcon, WhatsappIcon } from 'icons';

export const InputControl = styled(Form.Control)``;

export const InputContainer = styled.div`
  position: relative;
`;

export const AppendContainer = styled.span`
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: default;
  user-select: none;
  pointer-events: none;

  height: 100%;
  width: 40px;

  background: none;
  color: var(--black);
  font-size: 1.375rem;

  right: 0;
  top: 0;
`;

export const SearchIcon = styled(LupaIcon)``;

export const WhatsIcon = styled(WhatsappIcon)``;

export const LoaderCss = css`
  margin-right: 5px !important;
  margin-bottom: 5px !important;
`;
