import { useToken } from '@chakra-ui/react';
import ReactApexChart from 'react-apexcharts';

type GraficoStatusMetaProps = {
  valorMeta: number;
};

export const GraficoStatusMeta = ({ valorMeta }: GraficoStatusMetaProps) => {
  const validandoValorMeta = valorMeta >= 30;

  const [pink300, white, gray200, primary300, primary500] = useToken('colors', [
    'pink.300',
    'white',
    'gray.200',
    'primary.300',
    'primary.500',
  ]);

  return (
    <ReactApexChart
      options={{
        chart: {
          type: 'radialBar',
          offsetY: -20,
          sparkline: {
            enabled: true,
          },
        },
        colors: [pink300],
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            hollow: {
              margin: 0,
              size: '50%',
              background: white,
              position: 'front',
            },
            track: {
              background: gray200,
              strokeWidth: '97%',
              margin: 5,
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                color: primary300,
                opacity: 1,
                blur: 2,
              },
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                offsetY: -2,
                fontSize: '17px',
              },
            },
          },
        },

        fill: {
          type: ['gradient'],
          colors: [pink300],
          gradient: {
            shade: 'light',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            colorStops: [
              {
                offset: validandoValorMeta ? 0 : 10,
                color: validandoValorMeta ? pink300 : primary500,
                opacity: 1,
              },
              {
                offset: validandoValorMeta ? 60 : 30,
                color: primary300,
                opacity: 1,
              },
              {
                offset: validandoValorMeta ? 100 : 70,
                color: validandoValorMeta ? primary500 : pink300,
                opacity: validandoValorMeta ? 1 : 0.8,
              },
            ],
            type: validandoValorMeta ? 'horizontal' : 'vertical',
            shadeIntensity: 0.1,
            stops: [40, 60, 100],
            opacityTo: 1,
          },
        },
        labels: [''],
      }}
      series={[valorMeta]}
      type="radialBar"
      height="100%"
    />
  );
};
