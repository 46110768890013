import React, { ElementType } from 'react';
import {
  HStack,
  VStack,
  Text,
  Icon,
  Button,
  ButtonProps,
  Flex,
} from '@chakra-ui/react';
import { FiChevronRight } from 'react-icons/fi';

interface TabButtonProps extends ButtonProps {
  icon: ElementType;
  title: string;
  subtitle: string;
  isFirst?: boolean;
  isActive?: boolean;
}

const TabButton = ({
  icon,
  title,
  subtitle,
  isFirst,
  isActive,
  maxW,
  ...rest
}: TabButtonProps) => (
  <Button
    variant="unstyled"
    display="flex"
    borderRadius="none"
    justifyContent="space-between"
    w="full"
    h="94px"
    bg={isActive ? 'primary.500' : 'transparent'}
    borderTop={isFirst ? undefined : '1px'}
    borderColor="primary.900"
    p={8}
    py={0}
    {...rest}
  >
    <Flex
      w="full"
      h="full"
      alignItems="center"
      justifyContent="space-between"
      maxW={maxW}
    >
      <HStack spacing={4}>
        <Icon as={icon} color="purple.100" boxSize={8} />

        <VStack spacing={1} alignItems="flex-start" fontWeight="semibold">
          <Text color="white" fontSize="lg">
            {title}
          </Text>
          <Text
            color="purple.200"
            fontSize="sm"
            w="full"
            textAlign="start"
            whiteSpace="normal"
          >
            {subtitle}
          </Text>
        </VStack>
      </HStack>

      <Icon as={FiChevronRight} color="purple.200" boxSize={5} />
    </Flex>
  </Button>
);

export default TabButton;
