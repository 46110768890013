import React, { useEffect, useState, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useHistory, RouteComponentProps, Prompt } from 'react-router-dom';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteRotas from 'constants/rotas';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import isPrenvent from 'helpers/layout/isPrenvent';
import { obterTabelaPreco as apiObterTabelaPreco } from 'helpers/api/TabelaPreco/obterTabelaPreco';

import ManterFoco from 'components/Geral/ManterFoco';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { FormFooter } from 'components/update/Form/FormFooter';

import Formulario from '..';
import { yupResolver, FormData } from '../validationForm';

type TParams = { id: string };

const Alterar = ({ match }: RouteComponentProps<TParams>) => {
  const history = useHistory();
  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues: {
      nome: '',
      ativo: true,
    },
  });
  const isMountedRef = useIsMountedRef();

  const { dirtyFields } = formMethods.formState;
  const isDirty =
    dirtyFields &&
    dirtyFields.constructor === Object &&
    Object.keys(dirtyFields).length > 0;

  const [isPrenvented, setIsPrenvented] = useState(false);
  isPrenvent(isPrenvented);

  const tabelaPrecoId = match.params.id;

  const [isLoading, setIsLoading] = useState(false);
  const [dataHoraCriacao, setDataHoraCriacao] = useState('');
  const [dataHoraUltimaAlteracao, setDataHoraUltimaAlteracao] = useState('');

  function historyPush(path: string) {
    setIsPrenvented(false);

    history.push(path);
  }

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    setIsLoading(true);
    const newData = {
      ...data,
      tabelaPrecoProdutos: data.tabelaPrecoProdutos.map((item) => ({
        ...item,
        produtoId: item.produtoCorTamanhoId,
      })),
    };
    const response = await api.put<void, ResponseApi>(
      ConstanteEnderecoWebservice.TABELA_PRECO_ALTERAR,
      {
        id: tabelaPrecoId,
        ...newData,
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso) {
        toast.success('O cadastro foi alterado com sucesso.');

        historyPush(ConstanteRotas.TABELA_PRECOS);
      }
    }

    if (isMountedRef.current) setIsLoading(false);
  });

  const latestProps = useRef({
    reset: formMethods.reset,
    getValues: formMethods.getValues,
    setValue: formMethods.setValue,
    tabelaPrecoId,
    historyPush,
    setFocus: formMethods.setFocus,
  });

  useEffect(() => {
    latestProps.current = {
      reset: formMethods.reset,
      setValue: formMethods.setValue,
      getValues: formMethods.getValues,
      tabelaPrecoId,
      historyPush,
      setFocus: formMethods.setFocus,
    };
  });

  useEffect(() => {
    async function obterTabelaPreco() {
      setIsLoading(true);

      const responseData = await apiObterTabelaPreco(
        latestProps.current.tabelaPrecoId
      );

      if (responseData) {
        setDataHoraCriacao(responseData.dataHoraCadastro || '');
        setDataHoraUltimaAlteracao(responseData.dataHoraUltimaAlteracao || '');

        const lojasSelecionadas = latestProps.current.getValues(
          'lojasSelecionadas'
        );
        latestProps.current.setValue(
          'padraoSistema',
          responseData.padraoSistema
        );
        latestProps.current.setValue('nome', responseData.nome);
        latestProps.current.setValue('ativo', responseData.ativo);
        latestProps.current.setValue('tipoCalculo', responseData.tipoCalculo);
        latestProps.current.setValue('percentual', responseData.percentual);
        latestProps.current.setValue(
          'padronizarPreco',
          responseData.padronizarPreco
        );
        latestProps.current.setValue(
          'arredondamentoAcima',
          responseData.arredondamentoAcima
        );
        latestProps.current.setValue(
          'arredondamentoAbaixo',
          responseData.arredondamentoAbaixo
        );
        latestProps.current.setValue(
          'lojasSelecionadas',
          lojasSelecionadas || []
        );

        setIsLoading(false);

        latestProps.current.setFocus('nome');
      } else {
        setIsLoading(false);

        latestProps.current.historyPush(ConstanteRotas.TABELA_PRECOS);
      }
    }

    obterTabelaPreco();
  }, []);

  useEffect(() => {
    setIsPrenvented(isDirty);
  }, [isDirty]);

  return (
    <Box>
      <Prompt when={isPrenvented} message="" />
      <ManterFoco>
        {isLoading && <LoadingPadrao />}
        <FormProvider {...formMethods}>
          <Formulario isAlterar tabelaPrecoId={tabelaPrecoId} />
        </FormProvider>

        <FormFooter
          onSubmit={handleSubmit}
          isDisabled={isLoading}
          dates={{ dataHoraCriacao, dataHoraUltimaAlteracao }}
        />
      </ManterFoco>
    </Box>
  );
};

export default Alterar;
