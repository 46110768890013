import { Box } from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';

const NumeroOperacao = () => {
  const { watch } = useFormContext();
  const [numeroOperacao, identificacaoTipoOperacao] = watch([
    'numeroOperacao',
    'identificacaoTipoOperacao',
  ]);

  return (
    <>
      {identificacaoTipoOperacao ? (
        <>
          {identificacaoTipoOperacao ===
            IdentificacaoTipoOperacaoEnum.PEDIDO && (
            <Box color="white" fontSize="sm">
              {numeroOperacao ? `Pedido nº ${numeroOperacao}` : 'Novo Pedido'}
            </Box>
          )}
          {identificacaoTipoOperacao ===
            IdentificacaoTipoOperacaoEnum.ORCAMENTO && (
            <Box color="pink.500" fontSize="sm">
              {numeroOperacao
                ? `Orçamento nº ${numeroOperacao}`
                : 'Novo Orçamento'}
            </Box>
          )}
          {identificacaoTipoOperacao ===
            IdentificacaoTipoOperacaoEnum.CONSIGNACAO && (
            <Box color="white" fontSize="sm">
              {numeroOperacao
                ? `Consignação nº ${numeroOperacao}`
                : 'Nova consignação'}
            </Box>
          )}
        </>
      ) : (
        <Box color="white" fontSize="sm">
          {numeroOperacao ? `Pedido nº ${numeroOperacao}` : 'Novo Pedido'}
        </Box>
      )}
    </>
  );
};

export default NumeroOperacao;
