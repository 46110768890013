import { FormProvider } from 'react-hook-form';
import { GridItem, FormLabel, Text, Box, Flex, Button } from '@chakra-ui/react';

import StatusConsultaEnum from 'constants/enum/statusConsulta';
import getOptionsByEnum from 'helpers/format/getOptionsByEnum';
import enumMesesDoAno from 'constants/enum/enumMesesDoAno';

import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import InputDateRange from 'components/PDV/InputDateRange';
import { Switch } from 'components/update/Switch';
import Input from 'components/PDV/Input';
import SelectCidade from 'components/PDV/Select/SelectCidade';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { FilterSelect } from 'components/update/Select/FilterSelect';

import { EnumOptionsRelatorio, EnumOptiosOrdenacao } from './validationForms';
import { useInformacoesCliente } from './hooks';

export const InformacoesClientes = () => {
  const {
    formMethods,
    handleGerarRelatorio,
    listCategorias,
    isLoading,
    watchTipoRelatorio,
  } = useInformacoesCliente();

  const tipoRelatorioClientesNuncamCompraramOuAniversariantes =
    watchTipoRelatorio === EnumOptionsRelatorio.CLIENTE_NUNCA_COMPRARAM ||
    watchTipoRelatorio === EnumOptionsRelatorio.CLIENTES_ANIVERSARIANTES;

  return (
    <FormProvider {...formMethods}>
      <SimpleGridForm
        bg="white"
        padding={['20px', '20px', '40px']}
        borderRadius="5px"
        gap="24px"
        boxShadow="0px 0px 6px #00000034"
      >
        <GridItem
          colSpan={
            tipoRelatorioClientesNuncamCompraramOuAniversariantes
              ? [12, 12, 6]
              : [12, 12, 6, 6, 3]
          }
        >
          <SelectPadrao
            name="tipoRelatorio"
            label="Relatório"
            id="tipoRelatorio"
            placeholder="Digite os campos"
            options={EnumOptionsRelatorio.options}
          />
        </GridItem>
        {(watchTipoRelatorio ===
          EnumOptionsRelatorio.CLIENTE_NAO_COMPRARAM_NO_PERIODO ||
          watchTipoRelatorio === EnumOptionsRelatorio.CLIENTE_MAIS_COMPRAM) && (
          <GridItem colSpan={[12, 6, 6, 6, 3]}>
            <FormLabel mb="0" fontSize="sm" color="black" lineHeight="1.2">
              Período
            </FormLabel>
            <InputDateRange
              name="periodo"
              placeholder="Selecione um periodo"
              startDateName="periodoInicio"
              endDateName="periodoFim"
              amountMonths={12}
              borderRadius="5px"
              inputValueIsSmaller={false}
            />
          </GridItem>
        )}
        {watchTipoRelatorio ===
          EnumOptionsRelatorio.CLIENTES_ANIVERSARIANTES && (
          <GridItem colSpan={[12, 12, 6, 6, 3]}>
            <SelectPadrao
              placeholder="Selecione um mês de nascimento"
              id="mesAniversariante"
              name="mesAniversariante"
              label="Mês do nascimento"
              options={enumMesesDoAno.properties}
            />
          </GridItem>
        )}
        <GridItem
          colSpan={[
            12,
            tipoRelatorioClientesNuncamCompraramOuAniversariantes ? 12 : 6,
            6,
            6,
            3,
          ]}
        >
          <FormLabel mb="0" fontSize="sm" color="black" lineHeight="1.2">
            Status
          </FormLabel>
          <FilterSelect
            id="status"
            name="status"
            options={getOptionsByEnum(StatusConsultaEnum)}
          />
        </GridItem>
        <GridItem colSpan={[12, 12, 6, 6, 3]}>
          <SelectPadrao
            id="categoriaClienteId"
            name="categoriaClienteId"
            label="Categoria dos clientes"
            placeholder="Selecione"
            asControlledByObject
            options={listCategorias}
            isClearable
          />
        </GridItem>
        {!(
          watchTipoRelatorio === EnumOptionsRelatorio.CLIENTES_ANIVERSARIANTES
        ) && (
          <GridItem colSpan={[12, 12, 6, 6, 3]}>
            <SelectCidade
              id="cidadeId"
              name="cidadeId"
              label="Cidade"
              placeholder="Digite a cidade"
              isClearable
            />
          </GridItem>
        )}
        {watchTipoRelatorio === EnumOptionsRelatorio.CLIENTE_MAIS_COMPRAM && (
          <>
            <GridItem colSpan={[12, 12, 6, 6, 4]}>
              <SelectPadrao
                name="ordenacao"
                label="Ordernar por:"
                id="ordenacao"
                options={EnumOptiosOrdenacao.options}
              />
            </GridItem>
          </>
        )}
        {watchTipoRelatorio ===
          EnumOptionsRelatorio.CLIENTE_NAO_COMPRARAM_NO_PERIODO && (
          <GridItem colSpan={12}>
            <Text fontSize="14px" fontWeight="bold">
              Filtro adicional
            </Text>
            <Box
              borderRadius="6px"
              p="24px"
              bg="gray.100"
              borderWidth="1px"
              borderColor="gray.200"
            >
              <Flex align={['left', 'center']} direction={['column', 'row']}>
                <Box mr="10px">
                  <Switch
                    size="md"
                    label=""
                    id="ultimaCompra"
                    name="ultimaCompra"
                  />
                </Box>
                <FormLabel
                  justifyContent="center"
                  alignItems={['flex-start', 'center']}
                  display="flex"
                  flexDirection={['column', 'row']}
                >
                  <Text whiteSpace="nowrap" mr="5px">
                    Última compra realizada a partir de
                  </Text>
                  <Input
                    isRequired
                    maxLength={100}
                    type="date"
                    borderRadius="md"
                    id="dataUltimaCompra"
                    name="dataUltimaCompra"
                  />
                </FormLabel>
              </Flex>
            </Box>
          </GridItem>
        )}

        <GridItem
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          colSpan={12}
        >
          <Button
            variant="solid"
            w={['full', '250px', '200px']}
            borderRadius="6px"
            onClick={handleGerarRelatorio}
            colorScheme="secondary"
            isLoading={isLoading}
          >
            Gerar relatório
          </Button>
        </GridItem>
      </SimpleGridForm>
    </FormProvider>
  );
};
