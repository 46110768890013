import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useState, useCallback, useEffect } from 'react';

import auth from 'modules/auth';
import ConstanteFuncionalidades from 'constants/permissoes';
import TipoValorEnum from 'constants/enum/tipoValor';
import { StatusPesquisaClientesFornecedor } from 'constants/enum/statusPesquisaClientesFornecedor';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { formatFullDate } from 'helpers/format/formatData';
import { formatOptionsSelectClient } from 'helpers/format/formatSelectClient';
import api, { ResponseApi } from 'services/api';

import { ModalCadastrarFornecedor } from 'pages/EntradaMercadoria/EtapasCompartilhadas/EscolherFornecedor/ModalCadastrarFornecedor';

import {
  FormaPagamentoProps,
  ClienteFornecedorOptionResponse,
  FormaPagamentoResponse,
  HookAnteciparModalProps,
  yupResolver,
} from './types';

export const useAnteciparModal = ({
  onResolve,
  valorOriginal,
}: HookAnteciparModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [tipoTaxa, setTipoTaxa] = useState<number>(TipoValorEnum.REAIS);
  const [listFormaPagamento, setListFormaPagamento] = useState<
    FormaPagamentoProps[]
  >([]);

  const formMethods = useForm({
    resolver: yupResolver,
  });

  const { handleSubmit: onSubmit, reset, watch, setValue } = formMethods;

  const [
    taxaAntecipacaoWatch,
    alterarTaxaWatch,
    totalLiquidoWatch,
    formaPagamentoSelecionada,
  ] = watch([
    'taxaAntecipacao',
    'alterarTaxa',
    'totalLiquido',
    'formaPagamentoId',
  ]);

  const possuiPermissaoCadastrarFornecedor = auth.possuiPermissao(
    ConstanteFuncionalidades.FORNECEDOR_CADASTRAR
  ).permitido;

  const defaultDate = formatFullDate(new Date());

  const optionsContaFinanceira = formaPagamentoSelecionada?.contasFinanceiras
    ? (formaPagamentoSelecionada as FormaPagamentoProps)?.contasFinanceiras.map(
        (conta) => ({
          value: conta.id,
          label: conta.nome,
        })
      )
    : [];

  const valorOriginalFormatado = valorOriginal.toFixed(2);
  const valorEmTaxa = ((valorOriginal * taxaAntecipacaoWatch) / 100).toFixed(2);

  const handleAlterarTipoTaxa = () => {
    setValue('taxaAntecipacao', 0);
    setValue('totalLiquido', valorOriginalFormatado);
    setTipoTaxa(
      tipoTaxa === TipoValorEnum.REAIS
        ? TipoValorEnum.PORCENTAGEM
        : TipoValorEnum.REAIS
    );
  };

  const handleOnBlurTaxaAntecipacao = () => {
    if (tipoTaxa === TipoValorEnum.REAIS) {
      setValue(
        'totalLiquido',
        (valorOriginal - taxaAntecipacaoWatch).toFixed(2)
      );
    } else {
      setValue(
        'totalLiquido',
        (valorOriginal - (valorOriginal * taxaAntecipacaoWatch) / 100).toFixed(
          2
        )
      );
    }
  };

  const handleOnBlurValorLiquido = () => {
    if (tipoTaxa === TipoValorEnum.REAIS) {
      setValue(
        'taxaAntecipacao',
        (valorOriginal - totalLiquidoWatch).toFixed(2)
      );
    } else {
      const taxaPercentual =
        ((valorOriginal - totalLiquidoWatch) / valorOriginal) * 100;

      setValue(
        'taxaAntecipacao',
        (taxaPercentual < 0 ? 0 : taxaPercentual).toFixed(2)
      );
    }
  };

  const handleSubmit = onSubmit((data) => {
    const taxaIsTipoPorcentagem = tipoTaxa === TipoValorEnum.PORCENTAGEM;
    if (
      taxaAntecipacaoWatch === valorOriginal ||
      (taxaIsTipoPorcentagem && taxaAntecipacaoWatch === 100)
    ) {
      toast.warn(
        'A conta não pode ser antecipada com valor líquido igual a zero ou negativo.'
      );
      return;
    }
    onResolve({
      taxaAntecipacao: data.alterarTaxa ? data.taxaAntecipacao : null,
      dataAntecipacao: data.dataAntecipacao,
      tipoTaxa,
      formaPagamentoId: data.formaPagamentoId.value,
      contaFinanceiraId: data.contaFinanceiraId,
      clienteFornecedorId: data.clienteFornecedorId,
    });
  });

  const resetToDefaultValues = useCallback(() => {
    reset({
      taxaAntecipacao: 0,
      dataAntecipacao: new Date(),
      valorOriginal: valorOriginalFormatado,
      totalLiquido: valorOriginalFormatado,
      alterarTaxa: true,
    });
  }, [reset, valorOriginalFormatado]);

  const handleResetValueContaFinanceira = () => {
    setValue('contaFinanceiraId', '');
  };

  const getFormaPagamento = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get<void, ResponseApi<FormaPagamentoResponse[]>>(
      ConstanteEnderecoWebservice.FORMA_PAGAMENTO_LISTAR_SELECT_PAGAMENTO
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        const contasFinanceiras = response.dados.map((formaPagamento) => ({
          value: formaPagamento.id,
          label: formaPagamento.nome,
          lancarFatura: formaPagamento.lancarFatura,
          diaVencimento: formaPagamento.diaVencimento,
          contasFinanceiras: formaPagamento.contasFinanceiras,
        }));

        setListFormaPagamento(contasFinanceiras);
      }
    }
    setIsLoading(false);
  }, []);

  const getFornecedores = useCallback(async (inputValue: string) => {
    setIsLoading(true);
    const response = await api.get<
      void,
      ResponseApi<ClienteFornecedorOptionResponse[]>
    >(ConstanteEnderecoWebservice.CLIENTE_FORNECEDOR_LISTAR_SELECT, {
      params: {
        cpfCnpjNomeApelidoCodigoExterno: inputValue,
        filtroTipoCadastroPessoa: StatusPesquisaClientesFornecedor.FORNECEDORES,
      },
    });

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        setIsLoading(false);
        return response.dados.map((clienteFornecedor) => ({
          ...formatOptionsSelectClient(clienteFornecedor),
        }));
      }
    }
    setIsLoading(false);
    return [];
  }, []);

  const createFornecedor = useCallback(async (inputValue: string) => {
    try {
      setIsLoading(true);
      const { fornecedor } = await ModalCadastrarFornecedor({ inputValue });

      if (!fornecedor) {
        setIsLoading(false);
        return undefined;
      }
      setIsLoading(false);
      return {
        value: fornecedor.id,
        label: fornecedor.nome,
      };
    } catch {
      setIsLoading(false);
      toast.error('Erro ao cadastrar fornecedor');
      return undefined;
    }
  }, []);

  useEffect(() => {
    getFormaPagamento();
  }, [getFormaPagamento]);

  return {
    isLoading,
    possuiPermissaoCadastrarFornecedor,
    formMethods,
    tipoTaxa,
    listFormaPagamento,
    defaultDate,
    alterarTaxaWatch,
    valorEmTaxa,
    formaPagamentoSelecionada,
    optionsContaFinanceira,
    getFornecedores,
    createFornecedor,
    handleResetValueContaFinanceira,
    resetToDefaultValues,
    handleAlterarTipoTaxa,
    handleOnBlurTaxaAntecipacao,
    handleOnBlurValorLiquido,
    handleSubmit,
  };
};
