import { TrayEtapasContext, TrayEtapasProvider } from 'store/Tray';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { StepsTray } from './StepsTray';
import { LayoutTray } from './Layout';

export const TrayEtapas = () => {
  return (
    <>
      <TrayEtapasProvider>
        <TrayEtapasContext.Consumer>
          {({ activeStep, isLoading }) => (
            <LayoutTray>
              {isLoading && <LoadingPadrao />}
              <StepsTray activeStep={activeStep} />
            </LayoutTray>
          )}
        </TrayEtapasContext.Consumer>
      </TrayEtapasProvider>
    </>
  );
};
