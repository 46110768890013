import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

const schema = yup.object().shape({
  motivo: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .min(15, 'O campo deve ter pelo menos 15 caracteres')
    .max(255, 'Este campo não aceita mais que 255 caracteres'),
});

export const yupResolver = yupResolverInstance(schema);

export const useForm = useFormInstance;
