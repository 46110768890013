import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import isPrenvent from 'helpers/layout/isPrenvent';
import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';
import useReloadRegistration from 'helpers/layout/useReloadRegistration';
import { ContainerListagem } from 'components/Layout/Listagem/containerListagem';

import {
  useForm,
  yupResolver,
  formDefaultValue,
  FormData,
} from '../validationForm';
import UncontrolledForm, { UncontrolledFormRefInterface } from '..';

interface CadastrarProps {
  cadastroExterno?: boolean;
  nomeTransportadora?: string;
  onSubmitExterno?: (transportadora: FormData) => Promise<boolean>;
}

const Cadastrar = ({
  cadastroExterno = false,
  nomeTransportadora,
  onSubmitExterno,
}: CadastrarProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);

  const uncontrolledFormRef = useRef<UncontrolledFormRefInterface>();

  const history = useHistory();
  const isMountedRef = useIsMountedRef();

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues: formDefaultValue,
  });

  const historyReload = useReloadRegistration({ setFormIsDirty });
  isPrenvent(formIsDirty);

  const { handleSubmit, reset, getValues, formState } = formMethods;

  async function handlePostTransportadora(data: FormData) {
    const response = await api.post<void, ResponseApi>(
      ConstanteEnderecoWebservice.TRANSPORTADORA_CADASTRAR,
      { cidadeId: data.cidade.value, paisId: data.pais.value, ...data }
    );

    if (response) {
      if (response.sucesso) {
        return true;
      }

      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }
    }

    return false;
  }

  const onSubmit = handleSubmit(async () => {
    setIsLoading(true);

    const data = getValues();

    const sucesso = onSubmitExterno
      ? await onSubmitExterno(data)
      : await handlePostTransportadora(data);

    if (sucesso) {
      toast.success('O cadastro foi realizado com sucesso.');

      setFormIsDirty(false);

      if (!cadastroExterno) {
        history.push(ConstanteRotas.TRANSPORTADORA);
      }
    }

    if (isMountedRef.current) setIsLoading(false);
  });

  const onSubmitReset = handleSubmit(async () => {
    setIsLoading(true);

    const data = getValues();

    const sucesso = await handlePostTransportadora(data);

    if (sucesso) {
      toast.success('O cadastro foi realizado com sucesso.');
      if (isMountedRef.current && uncontrolledFormRef.current)
        uncontrolledFormRef.current.firstInputFocus();

      historyReload(ConstanteRotas.TRANSPORTADORA_CADASTRAR);
    }

    if (isMountedRef.current) setIsLoading(false);
  });

  useEffect(() => {
    reset({
      ...formDefaultValue,
      nome: nomeTransportadora,
    });
  }, [nomeTransportadora, reset]);

  useEffect(() => {
    setFormIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  return (
    <ContainerListagem
      maxWidth="100%"
      formMethods={formMethods}
      isLoading={isLoading}
      onSubmit={onSubmit}
      onSubmitReset={onSubmitReset}
      formIsDirty={formIsDirty}
    >
      <UncontrolledForm ref={uncontrolledFormRef} />
    </ContainerListagem>
  );
};

export default Cadastrar;
