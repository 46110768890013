import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { VStack } from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';

import auth from 'modules/auth';
import ConstanteRotas from 'constants/rotas';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';

import Title from 'components/Autenticacao/Text/Title';
import Paragraph from 'components/Autenticacao/Text/Paragraph';
import ButtonSubmit from 'components/Autenticacao/Button/Submit';

type RouteParams = {
  email: string;
};

export const DominioEnviadoSucesso = () => {
  const [isLoading, setIsLoading] = useState(false);

  const isMountedRef = useIsMountedRef();

  const history = useHistory();
  const {
    state: { email },
  } = useLocation<RouteParams>();

  const latestProps = useRef({ email, history });

  const handlePushAcesso = () => {
    setIsLoading(true);

    const urlApp = auth.getUrlApp();
    if (urlApp) window.location.replace(urlApp);

    if (isMountedRef.current) setIsLoading(false);
  };

  useEffect(() => {
    latestProps.current = { email, history };
  });

  useLayoutEffect(() => {
    if (!latestProps.current.email) {
      const urlApp = auth.getUrlApp();
      if (urlApp) {
        window.location.replace(urlApp);
      } else {
        latestProps.current.history.push(ConstanteRotas.HOME);
      }
    }
  }, []);

  return (
    <VStack spacing={9}>
      <VStack spacing={2} alignItems="flex-start" w="full">
        <Title>Encontramos!</Title>
        <Paragraph>
          Para sua segurança, o <strong>nome da conta</strong> foi <br />
          enviado para o e-mail do administrador:
          <br />
          <strong>{email}</strong>
          <br />
          <br />
          Siga as instruções contidas no e-mail para
          <br /> acessar novamente sua conta.
        </Paragraph>
      </VStack>

      <ButtonSubmit
        type="button"
        isLoading={isLoading}
        isDisabled={isLoading}
        onClick={handlePushAcesso}
      >
        Voltar para tela de acesso
      </ButtonSubmit>
    </VStack>
  );
};
