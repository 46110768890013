import { HStack, Button } from '@chakra-ui/react';

import { useContextConciliacaoContas } from 'store/Financeiro/ConciliacaoContas';

export const Footer = () => {
  const {
    isLoading,
    isSelectedContasEmpty,
    handleConciliarEmMassa,
  } = useContextConciliacaoContas();
  return (
    <>
      <HStack justifyContent="center" spacing={8} mt="24px">
        <Button
          colorScheme="aquamarine"
          variant="solid"
          height="36px"
          fontSize="sm"
          w="174px"
          onClick={handleConciliarEmMassa}
          isDisabled={isLoading || isSelectedContasEmpty}
        >
          Conciliar selecionados
        </Button>
      </HStack>
    </>
  );
};
