import React, { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import { Box, Grid, GridItem, useMediaQuery } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FieldErrors, useFormContext } from 'react-hook-form';

import { useRegrasFiscaisFormularioContext } from 'store/RegrasFiscais/RegrasFiscaisFormularios';

import InputPadrao from 'components/Input/InputPadrao';
import CheckBoxAtivoInativo from 'components/CheckBox/CheckBoxAtivoInativo';

import Operacoes from './Operacoes';
import RegraFiscalItem, {
  RegraFiscalItemForwardRefData,
} from './RegraFiscalItem';

export type FormularioForwardRefData = {
  focusAccordionError: (focusErrors: FieldErrors) => void;
  setDefaultTabAccordions: () => void;
};

interface FormularioProps {
  isReadonly?: boolean;
}

const Formulario = forwardRef<FormularioForwardRefData, FormularioProps>(
  ({ isReadonly = false }, ref) => {
    const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');
    const { t } = useTranslation();
    const {
      formState: { errors },
      control,
    } = useFormContext();
    const {
      handleSetActiveOperacaoIndex,
    } = useRegrasFiscaisFormularioContext();

    const regraFiscalItemRef = useRef<RegraFiscalItemForwardRefData>(null);

    function focusAccordionError(focusErrors: FieldErrors) {
      const { operacoes } = focusErrors;

      if (operacoes && operacoes.length > 0) {
        (operacoes as any[]).every((operacao, index) => {
          if (operacao) {
            handleSetActiveOperacaoIndex(index);

            if (regraFiscalItemRef.current) {
              const { simplesNacional, regimeNormal } = operacao;

              if (simplesNacional) {
                regraFiscalItemRef.current.handleTabsChange(0);

                const { contribuinte } = simplesNacional;

                if (contribuinte) {
                  regraFiscalItemRef.current.handleSimplesNacionalAccordionsChange(
                    [0]
                  );
                } else {
                  regraFiscalItemRef.current.handleSimplesNacionalAccordionsChange(
                    [1]
                  );
                }

                return false;
              }

              if (regimeNormal) {
                regraFiscalItemRef.current.handleTabsChange(1);

                const { contribuinte } = regimeNormal;

                if (contribuinte) {
                  regraFiscalItemRef.current.handleRegimeNormalAccordionsChange(
                    0
                  );
                } else {
                  regraFiscalItemRef.current.handleRegimeNormalAccordionsChange(
                    1
                  );
                }
              }
            }

            return false;
          }

          return true;
        });
      }
    }

    useImperativeHandle(ref, () => ({
      focusAccordionError,
      setDefaultTabAccordions: regraFiscalItemRef.current
        ? regraFiscalItemRef.current.setDefaultTabAccordions
        : () => {},
    }));

    return (
      <Box
        borderRadius="md"
        boxShadow="md"
        p={{ base: 3, sm: 6, md: 8 }}
        bgGradient={{
          base: 'linear(primary.800 530px, gray.100 530px)',
          md: 'linear(primary.800 220px, gray.100 220px)',
        }}
      >
        <Grid
          templateColumns={{
            base: '1fr',
            sm: '1fr auto',
            lg: '7fr 5fr',
            xl: '1fr 1fr',
          }}
          mb={{ base: 3, sm: 6, md: 8 }}
          gap={{ base: 3, sm: 6, md: 8 }}
          w="full"
          sx={{ '& label': { color: 'white !important' } }}
        >
          <InputPadrao
            type="text"
            id="nome"
            label={t('Descrição da regra fiscal')}
            required
            autoFocus
            placeholder={t('Informe um nome para a regra')}
            maxLength={60}
            error={errors.nome}
            disabled={isReadonly}
            control={control}
          />
          <CheckBoxAtivoInativo
            id="ativo"
            name="ativo"
            label="Status"
            control={control}
            readonly={isReadonly}
          />
          {isSmallerThan900 && (
            <GridItem colSpan={{ base: 1, sm: 2 }}>
              <Grid
                templateColumns={{
                  base: '1fr',
                  sm: '1fr 1fr',
                }}
              >
                <Operacoes isReadonly={isReadonly} asAccordion />
              </Grid>
            </GridItem>
          )}
        </Grid>

        <Grid
          gap={{ base: 3, sm: 6, md: 8 }}
          templateColumns={{ base: '1fr', md: 'auto 1fr' }}
        >
          {!isSmallerThan900 && <Operacoes isReadonly={isReadonly} />}

          <RegraFiscalItem ref={regraFiscalItemRef} isReadonly={isReadonly} />
        </Grid>
      </Box>
    );
  }
);

export default Formulario;
