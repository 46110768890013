import { GridPaginadaConsulta } from 'components/Grid/Paginacao';

export function formatQueryGridPaginadaConsulta(
  gridPaginadaConsulta: GridPaginadaConsulta
): string {
  return (
    `paginaAtual=${gridPaginadaConsulta.currentPage}` +
    `&tamanhoPagina=${gridPaginadaConsulta.pageSize}` +
    `&campoOrdenacao=${gridPaginadaConsulta.orderColumn}` +
    `&direcaoOrdenacao=${gridPaginadaConsulta.orderDirection}`
  );
}

export function formatQueryObject(obj: any): string {
  return new URLSearchParams(obj).toString();
}

/**
 * Função utilizada para formatar a url de paginação
 * @param  {[string]} url
 * @param  {[GridPaginadaConsulta]} gridPaginadaConsulta
 * @param  {[any]} obj Recebe um objeto preenchido para conversão ou uma string com os parâmetros já formatados. Ex: {nome: 'cor'} ou 'nome=cor'
 * @return {[string]} Url formatada com os parâmetros
 */
export function formatQueryPagegTable(
  url: string,
  gridPaginadaConsulta: GridPaginadaConsulta,
  obj?: any
): string {
  return `${url}?${formatQueryGridPaginadaConsulta(gridPaginadaConsulta)}${
    obj ? `&${formatQueryObject(obj)}` : ''
  }`;
}

export function formatQueryListarSelect(
  url: string,
  statusConsulta?: number,
  idsSelecionados?: string[] | string
): string {
  const idsSelecionadosQuery =
    typeof idsSelecionados === 'string'
      ? `idsSelecionados[0]=${idsSelecionados}`
      : (idsSelecionados || [])
          .filter((id) => id)
          .map((id, index) => `idsSelecionados[${index}]=${id}`)
          .join('&');

  return `${url}?${formatQueryObject({ statusConsulta })}${
    idsSelecionadosQuery ? '&' : ''
  }${idsSelecionadosQuery}`;
}
