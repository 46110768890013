import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  Box,
  Stack,
  ModalHeader,
  FormLabel,
  ModalFooter,
  GridItem,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { ImprimirPDF } from 'helpers/impressoes/imprimirPDF';

import Input from 'components/PDV/Input';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import { NumberInput } from 'components/update/Input/NumberInput';
import { ObterContasEmAbertoResponse } from 'components/RecebimentoContasComponents/validationForm';
import ImpressaoCupom80mm from 'components/Impressao/impressaoCupom80mm/ImpressaoCupom80mm';
import { ImpressaoCupom80mmRefElement } from 'components/Impressao/impressaoCupom80mm/types';
import { ModalCompartilhar } from 'components/Modal/ModalCompartilhar';
import ImpressaoCupom56mm from 'components/Impressao/impressaoCupom56mm/ImpressaoCupom56mm';
import { ImpressaoCupom56mmRefElement } from 'components/Impressao/impressaoCupom56mm/types';
import { EmailModal } from 'components/PDV/CompartilharDocumentosFiscais/EmailModal';
import { ButtonDefault } from 'components/Button/ButtonChakra';

type OptionParcela = {
  label: string;
  value: string;
  qtdParcelas: number;
};

type ModalBaixarValorEspecificoProps = {
  valor?: number;
  contaRecebimentoId?: string;
  contaFinanceiraId?: string;
  qtdParcelas?: OptionParcela;
  zerarMultaJuros?: boolean;
  sucesso?: boolean;
};

type Parcelas = {
  qtdParcelas: number;
  descricao: string;
  id: string;
};

type FormaRecebimentoResponse = {
  id: string;
  nome: string;
  contaFinanceiraId: string;
  parcelas: Parcelas[];
  qtdParcelas: number[];
};

type CaixaAbertoResponse = {
  id: string;
  nome: string;
};

type ModalBaixarValorProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalBaixarValorEspecificoProps> & {
    clienteFornecedorId: string;
    casasDecimaisValor: number;
    valorTotalBaixa: number;
    clienteIndex: string | never[];
    setInformacoesRecebimentoContas: Dispatch<
      SetStateAction<ObterContasEmAbertoResponse | undefined>
    >;
    informacoesRecebimentoContas: ObterContasEmAbertoResponse;
    zerarMultaJuros?: boolean;
  };

export const ModalBaixarValorEspecifico = create<
  ModalBaixarValorProps,
  ModalBaixarValorEspecificoProps
>(
  ({
    onResolve,
    onReject,
    casasDecimaisValor,
    valorTotalBaixa,
    clienteFornecedorId,
    clienteIndex,
    setInformacoesRecebimentoContas,
    informacoesRecebimentoContas,
    zerarMultaJuros = false,
    ...rest
  }) => {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
    const [formaPagamentoRecebimento, setFormaPagamentoRecebimento] = useState<
      FormaRecebimentoResponse[]
    >();
    const [isLoading, setIsLoading] = useState(false);
    const [contaRecebimentoId, setContaRecebimentoId] = useState<string>();
    const formMethods = useForm<ModalBaixarValorEspecificoProps>({
      defaultValues: {
        valor: 0,
        contaRecebimentoId: undefined,
      },
    });

    const valor = formMethods.watch('valor');
    const latestProps = useRef({
      setValue: formMethods.setValue,
      setFocus: formMethods.setFocus,
    });

    const [caixaAberto, setIsCaixaAberto] = useState<CaixaAbertoResponse>(
      {} as CaixaAbertoResponse
    );
    const [movimentacaoIdImpressao, setMovimentacaoIdImpressao] = useState<
      string[]
    >([]);

    const cupom56mmComponentToPrintRef = useRef<ImpressaoCupom56mmRefElement>(
      null
    );
    const cupom80mmComponentToPrintRef = useRef<ImpressaoCupom80mmRefElement>(
      null
    );

    const caixaAbertoRef = useRef('');

    const arrayFormaPagamentoId = formaPagamentoRecebimento?.filter(
      (item) => item?.id === contaRecebimentoId
    );

    const contaFinanceiraIdFormaPagamento = formaPagamentoRecebimento?.filter(
      (item) => item.id === contaRecebimentoId
    );
    const obterContaFinanceiraSelecionada = () => {
      if (contaFinanceiraIdFormaPagamento) {
        const obterId = contaFinanceiraIdFormaPagamento[0];

        if (obterId) {
          const obterContaFinanceira = obterId.contaFinanceiraId;
          return obterContaFinanceira;
        }
        return '';
      }
      return '';
    };
    const valorContaFinanceira = obterContaFinanceiraSelecionada();

    const handleImprimirComprovanteSemValidadeA4 = useCallback(
      async (dados: string[]) => {
        const response = await api.post<void, ResponseApi<string>>(
          ConstanteEnderecoWebservice.CONTAS_RECEBER_RECIBO_A4,
          dados
        );

        if (response.sucesso && response.dados) {
          ImprimirPDF(response.dados, 'relatorioBaixaContaValorEspecifico');
        }

        if (response?.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }
      },
      []
    );

    const handleEnviarEmailRelatorioContasReceber = useCallback(
      async (dados: string[]) => {
        EmailModal({
          idContas: dados,
        });
      },
      []
    );

    const getPrintFunctions56mm = async () => {
      if (cupom56mmComponentToPrintRef.current) {
        await cupom56mmComponentToPrintRef.current.imprimirCupom56mm();
      }
    };

    const getPrintFunctions80mm = async () => {
      if (cupom80mmComponentToPrintRef.current) {
        await cupom80mmComponentToPrintRef.current.imprimirCupom80mm();
      }
    };

    const handleCompartilharOperacao = (dados: string[]) => {
      setMovimentacaoIdImpressao(dados);

      ModalCompartilhar({
        items: [
          {
            titulo: 'Recibo A4',
            onClickImpressao: async () => {
              await handleImprimirComprovanteSemValidadeA4(dados);
            },
            onClickEmail: async () => {
              await handleEnviarEmailRelatorioContasReceber(dados);
            },
          },
          {
            titulo: 'Cupom 80mm',
            onClickImpressao: async () => {
              await getPrintFunctions80mm();
            },
          },
          {
            titulo: 'Cupom 56mm',
            onClickImpressao: async () => {
              await getPrintFunctions56mm();
            },
          },
        ],
        onCloseCallback: () => {
          onResolve({ sucesso: true });
          onClose();
        },
      });
    };

    useEffect(() => {
      async function obterCaixaAberto() {
        const response = await api.get<void, ResponseApi<CaixaAbertoResponse>>(
          ConstanteEnderecoWebservice.CONTA_FINANCEIRA_OBTER_CAIXA_ABERTO_LOGADO
        );

        if (response) {
          if (response.avisos) {
            response.avisos.forEach((avisos) => toast.warning(avisos));
          }
          if (response.sucesso && response.dados) {
            setIsCaixaAberto({
              id: response.dados.id,
              nome: response.dados.nome,
            });
          }
        }
      }
      obterCaixaAberto();
    }, []);

    useEffect(() => {
      async function getContaFinanceira() {
        if (contaRecebimentoId) {
          const response = await api.get<void, ResponseApi<string>>(
            ConstanteEnderecoWebservice.CONTA_FINANCEIRA_OBTER_NOME,
            {
              params: {
                id: valorContaFinanceira,
              },
            }
          );

          if (response) {
            if (response.avisos) {
              response.avisos.forEach((aviso) => toast.warning(aviso));
              if (caixaAberto.nome) {
                latestProps.current.setValue(
                  'contaFinanceiraId',
                  caixaAberto.nome
                );
              } else {
                latestProps.current.setValue(
                  'contaFinanceiraId',
                  'Conta cofre'
                );
              }
            }

            if (response.sucesso) {
              const { dados } = response;
              if (dados === null) {
                latestProps.current.setValue(
                  'contaFinanceiraId',
                  caixaAbertoRef.current
                );
              } else {
                latestProps.current.setValue('contaFinanceiraId', dados);
              }
            }
          }
        }
      }
      getContaFinanceira();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [caixaAberto, valorContaFinanceira]);

    useEffect(() => {
      async function obterValoresFormaRecebimento() {
        const response = await api.get<
          void,
          ResponseApi<FormaRecebimentoResponse[]>
        >(ConstanteEnderecoWebservice.FORMA_RECEBIMENTO_LISTAR_SELECT_BAIXA);

        if (response) {
          if (response.avisos) {
            response.avisos.forEach((aviso) => toast.warning(aviso));
          }

          if (response.sucesso && response.dados) {
            const newValue = response.dados.map((formaRecebimento) => ({
              nome: formaRecebimento.nome,
              id: formaRecebimento.id,
              contaFinanceiraId: formaRecebimento.contaFinanceiraId,
              parcelas: formaRecebimento.parcelas,
              qtdParcelas: formaRecebimento.parcelas.map(
                (item) => item.qtdParcelas
              ),
              descricao: formaRecebimento.parcelas.map(
                (item) => item.descricao
              ),
            }));
            setFormaPagamentoRecebimento(newValue);
          }
        }
      }

      obterValoresFormaRecebimento();
    }, []);

    const handleSubmit = formMethods.handleSubmit(async ({ qtdParcelas }) => {
      setIsLoading(true);
      if ((valor || 0) > valorTotalBaixa) {
        toast.warning(
          'Você não pode informar um valor maior que o da sua conta'
        );
      } else {
        const response = await api.post<void, ResponseApi<string[]>>(
          ConstanteEnderecoWebservice.CONTAS_RECEBER_BAIXAR_CONTAS,
          {
            clienteFornecedorId,
            valorInformado: valor,
            formaRecebimentoId: contaRecebimentoId,
            contaFinanceiraId: caixaAberto.id || null,
            zerarMultaJuros,
            qtdParcelas: qtdParcelas?.qtdParcelas,
            formaRecebimentoParcelaId: qtdParcelas?.value,
          }
        );

        if (response) {
          if (response?.avisos) {
            response.avisos?.forEach((aviso) => toast.warning(aviso));
            onClose();
          }

          if (response?.sucesso && response?.dados) {
            toast.success('Baixa realizada com sucesso');
            handleCompartilharOperacao(response.dados);
          }
        } else {
          onClose();
        }
      }
      setIsLoading(false);
    });

    useEffect(() => {
      latestProps.current.setValue('valor', valorTotalBaixa);
    }, [valorTotalBaixa]);

    const obtendoValorParcelas = arrayFormaPagamentoId
      ? arrayFormaPagamentoId.filter((item) => item.qtdParcelas)
      : [];

    const pegandoAQtdParcelas = (obtendoValorParcelas[0] || []).parcelas;
    const obtendoValorPrimeiraParcela = (pegandoAQtdParcelas || [])[0];

    useEffect(() => {
      if (obtendoValorPrimeiraParcela !== undefined) {
        latestProps.current.setValue('qtdParcelas', {
          label: obtendoValorPrimeiraParcela?.descricao,
          qtdParcelas: obtendoValorPrimeiraParcela?.qtdParcelas,
          value: obtendoValorPrimeiraParcela?.id,
        });
      }
    }, [obtendoValorPrimeiraParcela]);

    useEffect(() => {
      async function obterCaixaAberto() {
        const response = await api.get<void, ResponseApi<CaixaAbertoResponse>>(
          ConstanteEnderecoWebservice.OBTER_CAIXA_ABERTO_USUARIO_LOGADO
        );

        if (response) {
          if (response.avisos) {
            response.avisos.forEach((avisos) => toast.warning(avisos));
          }
          if (response.sucesso) {
            caixaAbertoRef.current = response.dados.nome;

            latestProps.current.setValue(
              'contaFinanceiraId',
              response.dados.nome
            );
          } else {
            latestProps.current.setValue('contaFinanceiraId', 'Conta cofre');
          }
        }
      }
      obterCaixaAberto();
    }, [caixaAberto]);

    return (
      <ModalPadraoChakra
        isCentered
        size="3xl"
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
      >
        <ModalContent
          bg="gray.50"
          marginBottom={{ base: 0, sm: '3.75rem' }}
          marginTop={{ base: 0, sm: '3.75rem' }}
          maxWidth={{ base: '650px', lg: '560px' }}
          w={{ base: '700px', lg: '100%' }}
        >
          <ModalHeader>
            <Box>
              <Text fontSize="sm" fontWeight="bold" color="primary.50">
                Baixar um valor específico
              </Text>
              <Text fontSize="sm">Cliente: {clienteIndex}</Text>
            </Box>
          </ModalHeader>

          <FormProvider {...formMethods}>
            <Stack bg="primary.500" h="88px" w="full">
              <Flex alignItems="center" marginLeft="150px" h="full">
                <form onSubmit={handleSubmit}>
                  <NumberInput
                    id="valor"
                    name="valor"
                    scale={2}
                    size="2xl"
                    w="250px"
                    variant="flushed"
                    colorScheme="secondary.300"
                    color="secondary.300"
                    focusBorderColor="none"
                    leftElement="R$"
                    fontSize="2xl"
                    colSpan={6}
                  />
                </form>
              </Flex>
            </Stack>
            <Flex flexDirection="column" align="stretch">
              <ModalBody pb="4" pt="5">
                <SelectPadrao
                  id="contaRecebimentoId"
                  onChangeEffect={async (option: any) => {
                    if (option) {
                      await setContaRecebimentoId(option.value);
                    }
                  }}
                  name="contaRecebimentoId"
                  colSpan={12}
                  placeholder="Selecione a forma de recebimento"
                  options={(formaPagamentoRecebimento || []).map(
                    ({ nome, id }) => {
                      return (
                        {
                          label: nome,
                          value: id,
                        } || []
                      );
                    }
                  )}
                />

                <Flex mt="20px">
                  {contaRecebimentoId ? (
                    <Flex>
                      {(pegandoAQtdParcelas || []).length > 0 ? (
                        <GridItem mr="25px" colSpan={4}>
                          <Flex>
                            <FormLabel mt="-4px" fontSize="sm" color="black">
                              Parcelas
                            </FormLabel>
                          </Flex>
                          {arrayFormaPagamentoId?.map((formaPagamento) => {
                            return (
                              <>
                                {formaPagamento?.parcelas ? (
                                  <Box mt="-7px" w="120px">
                                    <SelectPadrao
                                      id="qtdParcelas"
                                      name="qtdParcelas"
                                      placeholder="Parcelas"
                                      asControlledByObject
                                      options={formaPagamento?.parcelas.map(
                                        ({ qtdParcelas, descricao, id }) => {
                                          return (
                                            {
                                              label: descricao,
                                              value: id,
                                              qtdParcelas,
                                            } || []
                                          );
                                        }
                                      )}
                                    />
                                  </Box>
                                ) : (
                                  <></>
                                )}
                              </>
                            );
                          })}
                        </GridItem>
                      ) : (
                        <></>
                      )}
                      <GridItem colSpan={8}>
                        <Input
                          type="text"
                          isDisabled
                          size="sm"
                          id="contaFinanceiraId"
                          name="contaFinanceiraId"
                          label="Conta financeira"
                          placeholder="Selecione uma conta financeira"
                          autoFocus
                          w="370px"
                          alignItems="left"
                          h="35px"
                        />
                      </GridItem>
                    </Flex>
                  ) : (
                    <GridItem colSpan={8}>
                      <Input
                        type="text"
                        isDisabled
                        size="sm"
                        id="contaFinanceiraId"
                        name="contaFinanceiraId"
                        label="Conta financeira"
                        placeholder="Selecione uma conta financeira"
                        autoFocus
                        w="360px"
                        alignItems="left"
                        h="35px"
                      />
                    </GridItem>
                  )}
                </Flex>
              </ModalBody>
            </Flex>
            <ModalFooter>
              <GridItem
                w="full"
                mb="4"
                display="flex"
                justifyContent={['flex-start', 'center']}
                colSpan={{ base: 12, lg: 12 }}
              >
                <Button
                  id="cancelar"
                  name="cancelar"
                  colorScheme="gray.100"
                  variant="outline"
                  borderRadius="20px"
                  fontSize="sm"
                  type="button"
                  w="96px"
                  h="32px"
                  mr={6}
                  _hover={{ bg: 'gray.50' }}
                  onClick={onClose}
                >
                  Cancelar
                </Button>
                <ButtonDefault
                  id="baixarValor"
                  name="baixarValor"
                  w="110px"
                  color="white"
                  h="32px"
                  borderRadius="20px"
                  isLoading={isLoading}
                  variant="solid"
                  colorScheme="aquamarine"
                  isDisabled={contaRecebimentoId === undefined || isLoading}
                  onClick={handleSubmit}
                >
                  Baixar valor
                </ButtonDefault>
              </GridItem>
            </ModalFooter>
            {movimentacaoIdImpressao && (
              <>
                <Box display="none">
                  <ImpressaoCupom80mm
                    ref={cupom80mmComponentToPrintRef}
                    movimentacaoFinanceiaId={movimentacaoIdImpressao}
                  />
                </Box>
                <Box display="none">
                  <ImpressaoCupom56mm
                    ref={cupom56mmComponentToPrintRef}
                    movimentacaoFinanceiaId={movimentacaoIdImpressao}
                    maxWidth="56mm"
                  />
                </Box>
              </>
            )}
          </FormProvider>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
