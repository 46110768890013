import { FormProvider, useForm } from 'react-hook-form';
import React, { useCallback, useState } from 'react';
import {
  ModalContent,
  ModalHeader,
  ModalProps as ModalChakraProps,
  useDisclosure,
  Text,
  VStack,
  ModalBody,
  ModalFooter,
  Button,
  GridItem,
  useMediaQuery,
  Icon,
  FormLabel,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { create, InstanceProps } from 'react-modal-promise';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import enumTipoItemProdutoEtapa from 'constants/enum/enumTipoItemProdutoEtapa';

import { SalvarInserirNovoIcon } from 'icons';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { InputNumber } from 'components/update/Input/InputNumber';
import SelectField from 'components/PDV/Select/SelectPadrao';
import AsyncSelectField from 'components/PDV/Select/AsyncSelectPadrao';

import { OptionProps } from 'types/optionType';

import {
  FormData,
  yupResolver,
  defaultValues,
  CategoryProps,
  ProductProps,
} from './validationForm';
import ListProductsVirtualized from '../../listProductsVirtualized';

type ModalProps = {
  title?: string;
  callback: (data: FormData) => Promise<{ success: boolean }>;
};

type ModalEtapaCadastrarProps = Omit<
  ModalChakraProps,
  'isOpen' | 'onClose' | 'children'
> &
  InstanceProps<void> &
  ModalProps;

const ModalEtapaCadastrar = create<ModalEtapaCadastrarProps>(
  ({ title = 'Adicionar etapa', callback, ...rest }) => {
    const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const formMethods = useForm({
      resolver: yupResolver,
      defaultValues,
    });

    const { handleSubmit, reset, watch } = formMethods;
    const { tipoEtapa } = watch();

    const isProductType = tipoEtapa === enumTipoItemProdutoEtapa.PRODUTO;

    const [isLoading, setIsLoading] = useState(false);
    const [productsIsLoading, setProductsIsLoading] = useState(false);
    const [listProducts, setListProducts] = useState<ProductProps[]>([]);

    const registerStep = useCallback(
      async (data: FormData) => {
        const itens = isProductType
          ? listProducts
              .filter(({ isSelected }) => isSelected)
              .map(({ id }) => id)
          : [];

        const { success } = await callback({
          ...data,
          itens,
        });

        return { success };
      },
      [callback, isProductType, listProducts]
    );

    const handleConfirm = async (data: FormData) => {
      setIsLoading(true);

      const { success } = await registerStep(data);

      if (success) {
        onClose();
      }

      setIsLoading(false);
    };

    const handleConfirmAndReset = async (data: FormData) => {
      setIsLoading(true);

      const { success } = await registerStep(data);

      if (success) {
        setListProducts([]);
        reset();
      }

      setIsLoading(false);
    };

    const onChangeCategory = useCallback(async (option: OptionProps) => {
      if (!option) {
        return;
      }

      setProductsIsLoading(true);

      const response = await api.get<void, ResponseApi<ProductProps[]>>(
        ConstanteEnderecoWebservice.PRODUTO_ETAPA_PRODUTOS_CATEGORIA_OBTER.replace(
          'id',
          option.value as string
        )
      );

      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response?.sucesso && response?.dados) {
          const newProducts = response.dados.map((product) => ({
            ...product,
            isSelected: false,
          }));

          setListProducts(newProducts);
        }
      }

      setProductsIsLoading(false);
    }, []);

    const getListCategories = useCallback(async (inputValue: string) => {
      const response = await api.get<void, ResponseApi<CategoryProps[]>>(
        ConstanteEnderecoWebservice.PRODUTO_ETAPA_CATEGORIAS_OBTER,
        {
          params: { pesquisa: inputValue },
        }
      );

      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response?.sucesso && response?.dados) {
          return response.dados.map((category) => ({
            label: category.nome,
            value: category.id,
          }));
        }
      }

      return [];
    }, []);

    const handleSetListProducts = (products: ProductProps[]) => {
      setListProducts(products);
    };

    return (
      <ModalPadraoChakra
        isCentered={!isSmallerThan900}
        size={!isSmallerThan900 ? '5xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalContent px={{ base: '8px', md: '16px' }} bg="gray.50">
          {isLoading && <LoadingPadrao />}
          <ModalHeader pt={{ base: '12px', md: '24px' }}>
            <VStack alignItems="flex-start" spacing="1" lineHeight="1">
              <Text color="primary.500" mt={{ base: 4, md: 0 }}>
                {title}
              </Text>
            </VStack>
          </ModalHeader>
          <ModalBody p={{ base: 6, md: 8 }}>
            <FormProvider {...formMethods}>
              <SimpleGridForm w="full" gap={{ base: 6, md: 8 }}>
                <GridItem colSpan={{ base: 12, lg: 6 }}>
                  <AsyncSelectField
                    id="categoriaProdutoId"
                    name="categoriaProdutoId"
                    label="Categoria"
                    placeholder="Selecione uma categoria"
                    handleGetOptions={getListCategories}
                    onOptionSelect={onChangeCategory}
                    isDisabled={isLoading}
                  />
                </GridItem>
                <GridItem colSpan={{ base: 12, md: 6, lg: 3 }}>
                  <SelectField
                    id="tipoEtapa"
                    name="tipoEtapa"
                    label="Itens que serão exibidos"
                    placeholder="Selecione"
                    options={enumTipoItemProdutoEtapa.options}
                    isDisabled={isLoading}
                  />
                </GridItem>
                <GridItem colSpan={{ base: 12, md: 6, lg: 3 }}>
                  <InputNumber
                    id="quantidade"
                    name="quantidade"
                    label="Quantidade de itens"
                    scale={0}
                    max={999}
                    isDisabled={isLoading}
                  />
                </GridItem>
                {isProductType && listProducts?.length > 0 && (
                  <GridItem colSpan={12}>
                    <FormLabel>Produtos</FormLabel>
                    <ListProductsVirtualized
                      px={4}
                      listProducts={listProducts}
                      setListProducts={handleSetListProducts}
                      isLoading={productsIsLoading}
                    />
                  </GridItem>
                )}
              </SimpleGridForm>
            </FormProvider>
          </ModalBody>
          <ModalFooter
            justifyContent="center"
            flexWrap="wrap"
            gap={6}
            py="40px"
          >
            <Button
              w={{ base: 'full', sm: 'min-content' }}
              colorScheme="gray"
              variant="outlineDefault"
              onClick={onClose}
            >
              Cancelar
            </Button>
            <Button
              w={{ base: 'full', sm: 'min-content' }}
              colorScheme="gray"
              variant="outlineDefault"
              onClick={handleSubmit(handleConfirm)}
              isDisabled={isLoading || productsIsLoading}
            >
              Confirmar
            </Button>
            <Button
              w={{ base: 'full', sm: 'min-content' }}
              minW="225px"
              colorScheme="secondary"
              leftIcon={<Icon as={SalvarInserirNovoIcon} fontSize="lg" />}
              onClick={handleSubmit(handleConfirmAndReset)}
              isDisabled={isLoading || productsIsLoading}
            >
              Confirmar e inserir novo
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);

export default ModalEtapaCadastrar;
