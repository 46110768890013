import React from 'react';
import { Text, TextProps } from '@chakra-ui/react';

const Title = ({ children, ...rest }: TextProps) => {
  return (
    <Text
      as="h1"
      color="secondary.300"
      fontSize="16px"
      fontWeight="300"
      {...rest}
    >
      {children}
    </Text>
  );
};

export default Title;
