import { useState } from 'react';
import {
  Box,
  Flex,
  Button,
  Divider,
  HStack,
  Icon,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalProps,
  Text,
  useDisclosure,
  useMediaQuery,
  VStack,
  Link,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { create, InstanceProps } from 'react-modal-promise';

import { useSistemaAtual } from 'helpers/data/useSistemaAtual';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { DownloadModuloDesktopIcon } from 'icons';
import ModalPinA3 from 'pages/Lojas/Formulario/ModalPinA3';

type ModalDetalhesEtiquetaDesktopProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalProps> & {
    lojaId: string;
  };

export const ModalDetalhesEtiquetaDesktop = create<
  ModalDetalhesEtiquetaDesktopProps,
  ModalProps
>(({ onResolve, onReject, lojaId, ...rest }) => {
  const [modalPinA3IsOpen, setModalPinA3IsOpen] = useState(false);
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const { nomeExibicao } = useSistemaAtual();

  return (
    <ModalPadraoChakra
      isCentered={isLargerThan900}
      size={isLargerThan900 ? '3xl' : 'full'}
      {...rest}
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={false}
    >
      <ModalContent
        bg="gray.50"
        borderRadius={isLargerThan900 ? 'md' : '0'}
        marginBottom={isLargerThan900 ? '3.75rem' : '0'}
        marginTop={isLargerThan900 ? '3.75rem' : '0'}
      >
        <ModalBody mb="20px" p="0" width={isLargerThan900 ? '800px' : 'full'}>
          <VStack pl="16px" pr="64px" pt="16px" align="stretch">
            <Text
              fontSize="16px"
              fontWeight="bold"
              color="primary.50"
              pb="8px"
              pl="16px"
              letterSpacing="0"
            >
              Configurar impressão de etiqueta
            </Text>
            <Divider color="gray.200" />
            <HStack pt="24px" pl="24px">
              <Text color="gray.700" fontSize="14px">
                {`Para configurar a impressão de etiquetas será necessário baixar e instalar o Módulo desktop. Ele será o responsável por estabelecer a comunicação entre a impressora e o sistema ${nomeExibicao}.`}
              </Text>
            </HStack>
            <VStack pt="15px" alignItems="left" pl="16px" letterSpacing="0">
              <Text color="primary.50" fontSize="14px" fontWeight="bold">
                01 - Download e instalação.
              </Text>
              <Box pb="15px" pl="4%" fontSize="14px" color="gray.700">
                Clique no botão abaixo para baixar o aplicativo e iniciar a
                instalação. O módulo desktop é um aplicativo que funciona em
                segundo plano no seu computador. Ele inicia automaticamente ao
                ligar a maquina e você não precisará realizar nenhuma ação. (É
                possível encontrá-lo na barra de ferramentas do windows, no
                canto inferior direito)
              </Box>
              <Flex
                pb="40px"
                pl="4%"
                alignItems="center"
                gap="38px"
                flexDir={['column', 'column', 'row']}
                color="gray.700"
              >
                <Button
                  color="white"
                  leftIcon={
                    <Icon
                      ml="10px"
                      boxSize="14px"
                      as={DownloadModuloDesktopIcon}
                    />
                  }
                  textAlign="center"
                  bg="primary.50"
                  colorScheme="primary"
                  height="32px"
                  fontSize="14px"
                  fontWeight="light"
                  onClick={() =>
                    window.open(
                      'https://zendarappprod.blob.core.windows.net/zendar-desktop/Desktop/M%C3%B3dulo%20Desktop%20Setup%202.2.0%20x64.exe'
                    )
                  }
                >
                  Download do módulo desktop (64 bits)
                </Button>
                <Link
                  href="https://zendarappprod.blob.core.windows.net/zendar-desktop/Desktop/M%C3%B3dulo%20Desktop%20Setup%202.2.0%20x86.exe"
                  textDecor="underline"
                >
                  Download da versão para 32-bits
                </Link>
              </Flex>
              <Text color="primary.50" fontSize="14px" fontWeight="bold">
                02 - Código de ativação da loja.
              </Text>
              <Box pb="15px" pl="4%" fontSize="14px" color="gray.700">
                Após iniciar a instalação, será solicitado pelo app o código de
                ativação. Clique no link abaixo para gerar o código. Copie e
                informe na configuração inicial do módulo desktop.
              </Box>
              <Box
                pb="40px"
                pl="4%"
                w={['full', 'full', '187px']}
                color="gray.700"
              >
                <Button
                  variant="solid"
                  colorScheme="secondary"
                  color="black"
                  onClick={() => {
                    if (lojaId) {
                      setModalPinA3IsOpen(true);
                    } else {
                      toast.warning(
                        'Só será possível gerar o código após inserir a loja.'
                      );
                    }
                  }}
                >
                  Gerar código de ativação
                </Button>
              </Box>
              <Text color="primary.50" fontSize="14px" fontWeight="bold">
                03 - Configuração do módulo.
              </Text>
              <Box pl="4%" fontSize="14px" color="gray.700">
                Na tela inicial do módulo, selecione a opção “impressão de
                etiquetas” para configurar a impressora.
              </Box>
            </VStack>
          </VStack>
          {modalPinA3IsOpen && (
            <ModalPinA3
              show={modalPinA3IsOpen}
              onHide={() => {
                setModalPinA3IsOpen(false);
              }}
              lojaId={lojaId}
            />
          )}
        </ModalBody>
        <ModalFooter h="90px">
          <Box w="full">
            <Divider color="gray.200" />
            <Flex pt="20px" justifyContent="center">
              <Button
                w="110px"
                colorScheme="aquamarine"
                onClick={onClose}
                variant="solid"
                height="32px"
                fontWeight="normal"
                fontSize="14px"
              >
                Fechar
              </Button>
            </Flex>
          </Box>
        </ModalFooter>
      </ModalContent>
    </ModalPadraoChakra>
  );
});
