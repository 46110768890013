import React, {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react';

import { OperacaoItemObter as ImportedOperacaoItemObter } from 'helpers/api/Operacao/obterOperacaoComItens';

interface OperacaoItemObter extends ImportedOperacaoItemObter {
  chavePermissaoTemporariaAlterar?: string;
}

interface LancamentoEditarItemContextProps {
  operacaoItemEditando: OperacaoItemObter | undefined;
  setOperacaoItemEditando: Dispatch<
    SetStateAction<OperacaoItemObter | undefined>
  >;
}

const LancamentoEditarItemContext = createContext<LancamentoEditarItemContextProps>(
  {} as LancamentoEditarItemContextProps
);

interface LancamentoEditarItemProviderProps {
  children: React.ReactNode;
}

export default function LancamentoEditarItemProvider({
  children,
}: LancamentoEditarItemProviderProps): JSX.Element {
  const [operacaoItemEditando, setOperacaoItemEditando] = useState<
    OperacaoItemObter | undefined
  >();

  return (
    <LancamentoEditarItemContext.Provider
      value={{
        operacaoItemEditando,
        setOperacaoItemEditando,
      }}
    >
      {children}
    </LancamentoEditarItemContext.Provider>
  );
}

export function useLancamentoEditarItemContext(): LancamentoEditarItemContextProps {
  const context = useContext(LancamentoEditarItemContext);

  if (!context)
    throw new Error(
      'useLancamentoEditarItemContext must be used within a LancamentoEditarItemProvider.'
    );

  return context;
}
