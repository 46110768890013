import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormRegister } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';

import { SalvarConfirmarIcon } from 'icons';
import InputPadrao from 'components/Input/InputPadrao';
import InputNumerico from 'components/Input/InputNumerico';
import { BootstrapNumberInput } from 'components/update/Input/BootstrapNumberInput';
import {
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Text,
  ModalHeader,
  ModalFooter,
  Button,
  GridItem,
} from '@chakra-ui/react';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

interface ParcelamentoModalProps {
  show: boolean;
  onHide: (willReset?: boolean) => void;
  register: UseFormRegister<Record<string, any>>;
  control: any;
  getValues: any;
  setValues: any;
  readonly: any;
  indexItem: number;
  formaPagamentoRecebimentoId: string;
  isCadastrar?: boolean;
}

type Parcela = {
  ativo: boolean;
  descricao: string;
  qtdeParcela: number;
  intervaloDias: number;
  taxaTransacaoPorcentagem: number;
  taxaTransacaoValor: number;
  acrescimoPorcentagem: number;
  acrescimoValor: number;
  formaPagamentoRecebimentoId?: string;
  id?: string;
  sucesso?: boolean;
};

const ParcelamentoModal: React.FC<ParcelamentoModalProps> = ({
  show,
  onHide,
  register,
  control,
  readonly,
  getValues,
  setValues,
  indexItem,
  isCadastrar,
  formaPagamentoRecebimentoId,
}) => {
  const { t } = useTranslation();

  const [errors, setErrors] = useState<any>({
    descricao: { message: '' },
  });

  const [isLoading, setIsLoading] = useState(false);

  const schema = yup.object().shape({
    descricao: yup
      .string()
      .nullable()
      .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const ParcelaNova = {
    ativo: true,
    descricao: '',
    qtdeParcela: 0,
    intervaloDias: 0,
    taxaTransacaoPorcentagem: 0,
    taxaTransacaoValor: 0,
    acrescimoPorcentagem: 0,
    acrescimoValor: 0,
  };

  const createOrEditParcelamento = useCallback(
    async (parcela: Parcela, hasParcela: boolean) => {
      setIsLoading(true);
      const rota = hasParcela
        ? ConstanteEnderecoWebservice.FORMA_RECEBIMENTO_PARCELA_ALTERAR
        : ConstanteEnderecoWebservice.FORMA_RECEBIMENTO_PARCELA_CADASTRAR;

      const response = await api.post<void, ResponseApi<string>>(rota, {
        ...parcela,
      });
      if (response) {
        if (response.avisos) {
          response.avisos.forEach((avisos) => toast.warning(avisos));
        }
        if (response.sucesso) {
          toast.success(
            `O parcelamento foi ${
              hasParcela ? 'alterado' : 'cadastrado'
            } com sucesso`
          );

          setIsLoading(false);
          return {
            ...parcela,
            id: response.dados || parcela.id,
            sucesso: response.sucesso,
          };
        }
      }
      setIsLoading(false);
      return undefined;
    },
    []
  );

  const updateParcela = useCallback(
    (parcelas: Parcela[], data: Parcela, isNewParcelamento: boolean) => {
      const cloneListParcelas = parcelas.map((parcela: Parcela) => ({
        ...parcela,
      }));

      const parcela = isNewParcelamento
        ? { ...ParcelaNova }
        : cloneListParcelas[indexItem];

      parcela.descricao = data.descricao;
      parcela.qtdeParcela = data.qtdeParcela;
      parcela.intervaloDias = data.intervaloDias;
      parcela.taxaTransacaoPorcentagem = data.taxaTransacaoPorcentagem;
      parcela.taxaTransacaoValor = data.taxaTransacaoValor;
      parcela.acrescimoPorcentagem = data.acrescimoPorcentagem;
      parcela.acrescimoValor = data.acrescimoValor;
      parcela.formaPagamentoRecebimentoId = formaPagamentoRecebimentoId;
      return parcela;
    },
    [ParcelaNova, formaPagamentoRecebimentoId, indexItem]
  );

  const handleSalvar = useCallback(async () => {
    const { parcelas = [] } = getValues();
    const data = getValues();

    const form = { descricao: data.descricao.trim() };

    if (!schema.isValidSync(form)) {
      setErrors({
        descricao: {
          message: ConstanteMensagemValidacao.CAMPO_OBRIGATORIO,
        },
      });
      return;
    }
    setErrors({
      descricao: { message: '' },
    });
    let retornoParcelaApi;
    const isNewParcela = indexItem === -1;
    if (isNewParcela) {
      const parcelamentoNovo = updateParcela(parcelas, data, true);
      if (isCadastrar) {
        parcelas.push(parcelamentoNovo);
      } else if (formaPagamentoRecebimentoId) {
        retornoParcelaApi = await createOrEditParcelamento(
          parcelamentoNovo,
          false
        );
        if (retornoParcelaApi?.sucesso) {
          parcelas.push(retornoParcelaApi);
        }
      }
    } else {
      const parcelamentoAlterado = updateParcela(parcelas, data, false);
      if (isCadastrar) {
        parcelas[indexItem] = parcelamentoAlterado;
      } else if (formaPagamentoRecebimentoId) {
        retornoParcelaApi = await createOrEditParcelamento(
          parcelamentoAlterado,
          true
        );
        if (retornoParcelaApi?.sucesso) {
          parcelas[indexItem] = retornoParcelaApi;
        }
      }
    }
    setValues('parcelas', [...parcelas]);
    onHide();
  }, [
    getValues,
    schema,
    isCadastrar,
    indexItem,
    onHide,
    updateParcela,
    formaPagamentoRecebimentoId,
    createOrEditParcelamento,
    setValues,
  ]);

  useEffect(() => {
    if (show && indexItem !== -1) {
      const { parcelas } = getValues();
      const parcela = parcelas[indexItem];
      setValues('descricao', parcela.descricao);
      setValues('qtdeParcela', parcela.qtdeParcela);
      setValues('intervaloDias', parcela.intervaloDias);
      setValues('taxaTransacaoPorcentagem', parcela.taxaTransacaoPorcentagem);
      setValues('taxaTransacaoValor', parcela.taxaTransacaoValor);
      setValues('acrescimoPorcentagem', parcela.acrescimoPorcentagem);
      setValues('acrescimoValor', parcela.acrescimoValor);
    } else {
      setValues('descricao', ParcelaNova.descricao);
      setValues('qtdeParcela', ParcelaNova.qtdeParcela);
      setValues('intervaloDias', ParcelaNova.intervaloDias);
      setValues(
        'taxaTransacaoPorcentagem',
        ParcelaNova.taxaTransacaoPorcentagem
      );
      setValues('taxaTransacaoValor', ParcelaNova.taxaTransacaoValor);
      setValues('acrescimoPorcentagem', ParcelaNova.acrescimoPorcentagem);
      setValues('acrescimoValor', ParcelaNova.acrescimoValor);
    }

    setTimeout(() => {
      const firstInput = document.getElementById('descricao');
      if (firstInput) firstInput.focus();
    }, 200);

    setErrors({
      descricao: { message: '' },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <ModalPadraoChakra isOpen={show} onClose={onHide} isCentered>
      <ModalContent>
        <ModalHeader>
          <Text color="primary.400" fontWeight="semibold">
            Parcelamentos
          </Text>
          <Text fontSize="sm" fontWeight="medium">
            Adicione ou edite o parcelamento
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isLoading && <LoadingPadrao />}
          <SimpleGridForm>
            <GridItem colSpan={12}>
              <InputPadrao
                type="text"
                id="descricao"
                name="descricao"
                defaultValue={undefined}
                required
                maxLength={30}
                label={t('Descrição')}
                placeholder={t('Digite a descrição')}
                error={errors.descricao}
                disabled={readonly}
                control={control}
              />
            </GridItem>
            <GridItem colSpan={[12, 6]}>
              <InputNumerico
                id="qtdeParcela"
                name="qtdeParcela"
                maxLength={11}
                defaultValue={0}
                disabled={readonly}
                required
                control={control}
                label={t('Quantidade de parcelas')}
                error={null}
              />
            </GridItem>

            <GridItem colSpan={[12, 6]}>
              <InputNumerico
                id="intervaloDias"
                name="intervaloDias"
                maxLength={11}
                defaultValue={0}
                disabled={readonly}
                required
                control={control}
                label={t('Intervalo de dias')}
                error={errors.intervaloDias}
              />
            </GridItem>

            <GridItem colSpan={[12, 6]}>
              <BootstrapNumberInput
                id="taxaTransacaoPorcentagem"
                name="taxaTransacaoPorcentagem"
                maxValue={100}
                scale={2}
                precision={5}
                disabled={readonly}
                control={control}
                label={t('Taxa de transação (%)')}
                leftElement="%"
                error={errors.taxaTransacaoPorcentagem}
              />
            </GridItem>
            <GridItem colSpan={[12, 6]}>
              <BootstrapNumberInput
                id="taxaTransacaoValor"
                name="taxaTransacaoValor"
                precision={7}
                scale={2}
                label={t('Taxa de transação (R$)')}
                leftElement="R$"
                control={control}
                error={errors.taxaTransacaoValor}
                disabled={readonly}
              />
            </GridItem>
            <GridItem colSpan={[12, 6]}>
              <BootstrapNumberInput
                type="text"
                id="acrescimoPorcentagem"
                name="acrescimoPorcentagem"
                maxValue={100}
                scale={2}
                precision={5}
                disabled={readonly}
                label={t('Acréscimo (%)')}
                leftElement="%"
                control={control}
                error={errors.acrescimoPorcentagem}
              />
            </GridItem>
            <GridItem colSpan={[12, 6]}>
              <BootstrapNumberInput
                id="acrescimoValor"
                name="acrescimoValor"
                precision={7}
                scale={2}
                control={control}
                label={t('Acréscimo (R$)')}
                leftElement={t('R$')}
                error={errors.acrescimoValor}
                disabled={readonly}
              />
            </GridItem>
          </SimpleGridForm>
        </ModalBody>
        <ModalFooter gap="12px">
          <Button
            id="fechar"
            name="fechar"
            colorScheme="gray"
            variant="outlineDefault"
            fontWeight="normal"
            onClick={() => {
              onHide();
            }}
            maxW="100px"
            height="35px"
            borderRadius="6px"
          >
            Fechar
          </Button>

          {!readonly && (
            <Button
              onClick={handleSalvar}
              id="salvar-parcelamento"
              name="salvar"
              colorScheme="aquamarine"
              variant="solid"
              fontWeight="normal"
              w="full"
              maxW="150px"
              height="35px"
              leftIcon={<SalvarConfirmarIcon />}
              borderRadius="6px"
            >
              Salvar
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export default ParcelamentoModal;
