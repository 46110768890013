import { useForm as useFormInstance } from 'react-hook-form';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

const schema = yup.object().shape({
  senha: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .min(6, ConstanteMensagemValidacao.SENHA_MINIMO_SEIS_CARACTERES)
    .matches(/[0-9]/, ConstanteMensagemValidacao.SENHA_UM_CARACTER_NUMERICO)
    .matches(/[a-z]/, ConstanteMensagemValidacao.SENHA_UMA_LETRA_MINUSCULA)
    .matches(/[A-Z]/, ConstanteMensagemValidacao.SENHA_UMA_LETRA_MAIUSCULA)
    .matches(
      /([^a-zA-Z\d])+([a-zA-Z\d])+|([a-zA-Z\d])+([^a-zA-Z\d])+/,
      ConstanteMensagemValidacao.SENHA_UM_CARACTER_ESPECIAL
    ),
  confirmarSenha: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .oneOf(
      [yup.ref('senha'), ''],
      ConstanteMensagemValidacao.SENHA_NAO_COINCIDEM
    ),
});

export const yupResolver = yupResolverInstance(schema);

export const useForm = useFormInstance;
