import {
  ModalContent,
  ModalBody,
  Flex,
  Button,
  Text,
  ModalFooter,
  Box,
  useDisclosure,
  ModalProps as ModalChakraProps,
  ModalHeader,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import Input from 'components/PDV/Input';

type ModalProps = {
  title?: string;
  message?: string;
  confirmationText: string;
  confirmationValidation?: (input: string) => boolean;
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
  confirmButtonColor?: string;
  confirmButtonBackground?: string;
  confirmButtonColorScheme?: string;
  widthButtonConfirmar?: string;
  callback: (onClose: () => void) => void;
  callbackCancel?: () => void;
  placeholder?: string;
  corTitulo?: string;
};

type ModalConfirmarProps = Omit<
  ModalChakraProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<void> &
  ModalProps;

export const ModalDigitarConfirmacao = create<ModalConfirmarProps>(
  ({
    title = 'Você tem certeza?',
    message = 'Ao fazer isso, você está ciente de que os dados de todas as lojas serão excluídos indefinidamente, sem possibilidade de recuperação.',
    cancelButtonLabel = 'Cancelar',
    confirmButtonLabel = 'Excluir todos os meus dados',
    confirmButtonColor = 'white',
    confirmButtonBackground = 'red.500',
    confirmButtonColorScheme = 'red',
    corTitulo = 'red.500',
    widthButtonConfirmar = '240px',
    confirmationText,
    confirmationValidation,
    callback,
    callbackCancel,
    ...rest
  }) => {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const formMethods = useForm({
      defaultValues: {
        confirmationField: '',
      },
    });

    const { watch } = formMethods;
    const { confirmationField } = watch();

    const hasConfirmation = () => {
      if (confirmationValidation) {
        return confirmationValidation(confirmationField);
      }

      return confirmationField === confirmationText;
    };

    const handleCancel = () => {
      if (callbackCancel) {
        callbackCancel();
      }

      onClose();
    };

    const handleConfirm = () => {
      callback(onClose);
    };

    return (
      <ModalPadraoChakra
        isCentered
        autoFocus={false}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalContent
          borderRadius="md"
          px="40px"
          maxWidth="560px"
          height={['full', '360px']}
        >
          <ModalHeader px={0} pt="40px">
            <Text color={corTitulo} fontSize="20px">
              {title}
            </Text>
          </ModalHeader>
          <ModalBody p={0}>
            <Flex flexDirection="column">
              <Flex justifyContent="flex-start" flexDirection="column">
                <Text fontSize="14px" color="black" fontWeight="medium">
                  {message}
                </Text>
                <Box mt="30px">
                  <FormProvider {...formMethods}>
                    <Text textColor="gray.700" fontSize="14px" lineHeight="2">
                      Digite
                      <Text
                        p="5px"
                        mx="5px"
                        display="inline"
                        bgColor="gray.200"
                      >
                        {confirmationText}
                      </Text>
                      <Text display="inline">
                        no campo abaixo para confirmar a ação
                      </Text>
                    </Text>
                    <Input
                      id="confirmationField"
                      name="confirmationField"
                      colSpan={12}
                      maxLength={confirmationText.length}
                      placeholder="Digite com letras maiúsculas"
                      mt="6px"
                    />
                  </FormProvider>
                </Box>
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter
            py="40px"
            flexDir={{ base: 'column', sm: 'row' }}
            justifyContent="center"
            gap="24px"
          >
            <Button
              onClick={handleCancel}
              variant="outlineDefault"
              colorScheme="gray"
              borderRadius="16px"
              fontSize="14px"
              height="32px"
              width={{ base: 'full', sm: 'max-content' }}
            >
              {cancelButtonLabel}
            </Button>
            <Button
              onClick={handleConfirm}
              variant="solid"
              bg={confirmButtonBackground}
              color={confirmButtonColor}
              colorScheme={confirmButtonColorScheme}
              borderRadius="16px"
              fontSize="14px"
              height="32px"
              fontWeight="300"
              width={['full', widthButtonConfirmar]}
              minWidth="160px"
              isDisabled={!hasConfirmation()}
            >
              {confirmButtonLabel}
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
