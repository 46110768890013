/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-autofocus */
import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo,
  memo,
} from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { toast } from 'react-toastify';
import {
  Box,
  Flex,
  Image as ImageChakra,
  Button,
  Text,
  Icon,
} from '@chakra-ui/react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { FiPlusCircle } from 'react-icons/fi';

import ConstanteFuncionalidades from 'constants/permissoes';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';
import auth from 'modules/auth';
import { useCategoriasProdutoContext } from 'store/CategoriasProduto';
import { corCategoriaProdutoEnum } from 'constants/enum/nivelCategoriaProduto';

import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { ModalConfirmacaoInativar } from 'components/Modal/ModalConfirmacaoInativar';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import { IoMdArrowDownRight } from 'icons';

import { ItemAdicionandoContent, ItemEditandoContent } from '../ItemContent';
import { Card } from '../components/Card';
import { Container } from '../components/Container';
import { SideContainer } from '../components/SideContainer';
import { ModalEditarCategoria } from '../ModalEditarCategoria';
import { CategoryProps } from '../ModalEditarCategoria/types';

interface ItemProps {
  item: CategoryProps;
  categoriaProdutoPaiIsAtivo: boolean;
  nivel: number;
  children: React.ReactNode;
}

const Item = ({
  item,
  categoriaProdutoPaiIsAtivo,
  nivel,
  children,
}: ItemProps) => {
  const {
    moverCategoriaProduto,
    getSubNiveisCategoriaProduto,
    setIsLoading,
    handleGetCategoriasProduto,
    itemsExpandidos,
  } = useCategoriasProdutoContext();

  const [isOpen, setIsOpen] = useState(false);
  const [adicionando, setAdicionando] = useState(false);
  const [editando, setEditando] = useState(false);
  const [firstRender, setFirstRender] = useState(false);

  const permissaoCategoriaProdutoCadastrar = useMemo(
    () =>
      auth.possuiPermissao(
        ConstanteFuncionalidades.CATEGORIA_PRODUTO_CADASTRAR
      ),
    []
  );

  const permissaoCategoriaProdutoAlterar = useMemo(
    () =>
      auth.possuiPermissao(ConstanteFuncionalidades.CATEGORIA_PRODUTO_ALTERAR),
    []
  );

  const haveChildrens = useMemo(
    () =>
      nivel < 4 && (getSubNiveisCategoriaProduto(item.id) > 1 || adicionando),
    [adicionando, getSubNiveisCategoriaProduto, item.id, nivel]
  );

  const editCategory = useCallback(
    async (categoria: CategoryProps) => {
      try {
        await ModalEditarCategoria({
          categoriaProduto: categoria,
        });
        await handleGetCategoriasProduto();
      } catch {
        toast.error('Erro ao editar categoria');
      }
    },
    [handleGetCategoriasProduto]
  );

  const handleEnableAdicionar = useCallback(() => {
    setAdicionando(true);
  }, []);

  const handleInativarCategoriaProduto = useCallback(
    async (categoriaProdutoId: string, ativo: boolean) => {
      setIsLoading(true);

      ModalConfirmacaoInativar({
        rotaWebService: ConstanteEnderecoWebservice.CATEGORIA_PRODUTO_INATIVAR,
        id: categoriaProdutoId,
        ativo,
        text:
          'Todas as sub-categorias que estão dentro deste nível também serão bloqueadas. Se preferir, você pode movê-las para outra pasta antes de inativar.',
        title: 'Inativar todo o conteúdo?',
        callback: (ok: boolean) => {
          if (ok) handleGetCategoriasProduto();
        },
      });

      setIsLoading(false);
    },
    [handleGetCategoriasProduto, setIsLoading]
  );

  const handleDeleteCategoriaProduto = useCallback(
    async (categoriaProdutoId: string, ativo: boolean) => {
      setIsLoading(true);

      ModalConfirmacaoExcluir({
        callback: async (ok: boolean) => {
          if (ok) {
            setIsLoading(true);

            const response = await api.delete<void, ResponseApi>(
              ConstanteEnderecoWebservice.CATEGORIA_PRODUTO_EXCLUIR,
              {
                params: { id: categoriaProdutoId },
              }
            );

            if (response?.sucesso) {
              toast.success('O cadastro foi removido com sucesso.');

              handleGetCategoriasProduto();
            }

            if (response) {
              ModalConfirmacaoInativar({
                response,
                rotaWebService:
                  ConstanteEnderecoWebservice.CATEGORIA_PRODUTO_INATIVAR,
                id: categoriaProdutoId,
                ativo,
                text:
                  'Não é possível remover esta categoria pois ela já foi utilizada em outras ações do sistema. Se preferir você pode inativá-la.',
                callback: (okInativar: boolean) => {
                  if (okInativar) handleGetCategoriasProduto();
                },
              });
            }

            setIsLoading(false);
          }
        },
      });

      setIsLoading(false);
    },
    [handleGetCategoriasProduto, setIsLoading]
  );

  const handlePutCategoriaProduto = useCallback(
    async (categoriaProduto: CategoryProps, getCategorias?: boolean) => {
      setIsLoading(true);

      const response = await api.put<void, ResponseApi>(
        ConstanteEnderecoWebservice.CATEGORIA_PRODUTO_ALTERAR,
        { ...categoriaProduto, nivel: 1, ultimoNivel: false }
      );

      if (response.sucesso && getCategorias) {
        handleGetCategoriasProduto();
      }

      if (response.avisos)
        response.avisos.map((aviso: string) => toast.warning(aviso));

      setIsLoading(false);
    },
    [handleGetCategoriasProduto, setIsLoading]
  );

  const ativarInativarDropdownObjeto = useMemo(() => {
    if (!item.ativo && categoriaProdutoPaiIsAtivo) {
      return [
        {
          content: 'Ativar',
          onClick: () =>
            handlePutCategoriaProduto({ ...item, ativo: true }, true),
          funcionalidade: ConstanteFuncionalidades.CATEGORIA_PRODUTO_ALTERAR,
        },
      ];
    }
    if (item.ativo) {
      return [
        {
          content: 'Inativar',
          onClick: () => handleInativarCategoriaProduto(item.id, item.ativo),
          funcionalidade: ConstanteFuncionalidades.CATEGORIA_PRODUTO_EXCLUIR,
        },
      ];
    }

    return [];
  }, [
    categoriaProdutoPaiIsAtivo,
    handleInativarCategoriaProduto,
    handlePutCategoriaProduto,
    item,
  ]);

  const handleClose = useCallback(() => {
    if (haveChildrens) setIsOpen(false);
  }, [haveChildrens]);

  const ref = useRef() as React.RefObject<HTMLDivElement>;

  const [{ isDragging }, dragRef, preview] = useDrag({
    item: {
      type: 'CATEGORIAPRODUTO',
      acept: 'CATEGORIAPRODUTO',
      categoria: item,
      nivel,
      handleClose,
      width: ref.current ? ref.current.getBoundingClientRect().width || 0 : 0,
      canDrop: false,
      canDropCount: false,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    isDragging: (monitor) => item.id === monitor.getItem().categoria.id,
  });

  const [, dropRef] = useDrop({
    accept: 'CATEGORIAPRODUTO',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    hover(
      draggedItem: {
        type: string;
        categoria: CategoryProps;
        handleClose: () => void;
        width: number;
        canDrop: boolean;
        canDropCount: boolean;
      },
      monitor
    ) {
      draggedItem.handleClose();

      if (!draggedItem.canDropCount) {
        draggedItem.canDropCount = true;
        setTimeout(() => {
          draggedItem.canDrop = true;
        }, 100);
      }

      if (draggedItem.canDrop) {
        draggedItem.canDropCount = false;
        draggedItem.canDrop = false;

        if (draggedItem.categoria.id === item.id) return;
        if (haveChildrens) setIsOpen(true);

        const targetSize = ref?.current
          ? ref?.current?.getBoundingClientRect()
          : undefined;

        if (targetSize) {
          draggedItem.width = targetSize?.width;
          const targetCenter = (targetSize?.bottom - targetSize.top) / 2;

          const draggedOffset = monitor.getClientOffset();
          if (draggedOffset) {
            const draggedTop = draggedOffset.y - targetSize?.top;

            const draggedPaiId = draggedItem.categoria.categoriaProdutoPaiId;
            const targetPaiId =
              draggedTop < targetCenter || nivel === 4
                ? item.categoriaProdutoPaiId
                : item.id;

            if (draggedPaiId === targetPaiId) {
              return;
            }

            const draggedItemSubNiveis = getSubNiveisCategoriaProduto(
              draggedItem.categoria.id
            );

            if (draggedItemSubNiveis > 1 && nivel + draggedItemSubNiveis > 4)
              return;

            moverCategoriaProduto(
              draggedItem.categoria.id,
              targetPaiId,
              draggedTop < targetCenter ? item.id : undefined
            );
            draggedItem.categoria = {
              ...draggedItem.categoria,
              categoriaProdutoPaiId: targetPaiId,
            };
          }
        }
      }
    },
    drop(draggedItem: { type: string; categoria: CategoryProps }) {
      handlePutCategoriaProduto(draggedItem.categoria);
    },
  });

  dragRef(dropRef(ref));

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  useEffect(() => {
    if (firstRender) setFirstRender(true);
  }, [firstRender]);

  useEffect(() => {
    if (!firstRender) setIsOpen(itemsExpandidos);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsExpandidos]);

  return (
    <>
      <Container height={item.foto ? 80 : 36} nivel={nivel}>
        <Icon as={IoMdArrowDownRight} fontSize="16px" />

        <Card
          height={item.foto ? 75 : undefined}
          ref={permissaoCategoriaProdutoAlterar.permitido ? ref : undefined}
          isDragging={isDragging}
          style={
            !permissaoCategoriaProdutoAlterar.permitido
              ? { cursor: 'default' }
              : undefined
          }
          nivel={nivel}
          w="full"
          inativo={!item.ativo}
          isTyping={editando}
          id="CATEGORIAPRODUTO"
        >
          <Flex
            justifyContent="space-between"
            mt="2px"
            alignItems="center"
            w="full"
          >
            {editando ? (
              <ItemEditandoContent
                categoriaProduto={item}
                setEditando={setEditando}
                handlePutCategoriaProduto={handlePutCategoriaProduto}
              />
            ) : (
              <>
                <SideContainer>
                  {item.foto && (
                    <Box
                      mr="10px"
                      color="gray.100"
                      border="1px"
                      w="50px"
                      h="50px"
                    >
                      <ImageChakra objectFit="cover" src={item.foto} />
                    </Box>
                  )}
                  {haveChildrens && !isDragging && (
                    <Button
                      variant="linkDefault"
                      padding="0"
                      mt="2px"
                      colorScheme="none"
                      onClick={() => setIsOpen(!isOpen)}
                    >
                      <Flex
                        bg={
                          corCategoriaProdutoEnum.properties[nivel - 1]
                            .foregroundColor
                        }
                        color="white"
                        borderRadius="5px"
                        justifyContent="center"
                        boxSize="18px"
                        alignItems="center"
                      >
                        {isOpen ? (
                          <Icon as={IoIosArrowUp} fontSize="12px" />
                        ) : (
                          <Icon as={IoIosArrowDown} fontSize="12px" />
                        )}
                      </Flex>
                    </Button>
                  )}

                  {!isDragging && (
                    <Text
                      mt="4px"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      maxW="100%"
                      id="categoriaProduto-nome"
                      data-item-name={item.nome}
                    >
                      {item.nome}
                    </Text>
                  )}
                </SideContainer>
                <SideContainer>
                  {!item.ativo && <Text>Inativo</Text>}
                  {nivel < 4 &&
                    !isDragging &&
                    item.ativo &&
                    permissaoCategoriaProdutoCadastrar.permitido && (
                      <Button
                        id={`adicionar-categoriaProduto-nivel${nivel + 1}`}
                        variant="linkDefault"
                        colorScheme="none"
                        padding="0"
                        color="blue.500"
                        onClick={() => {
                          setIsOpen(true);
                          handleEnableAdicionar();
                        }}
                      >
                        <FiPlusCircle />
                      </Button>
                    )}

                  {!isDragging && (
                    <ActionsMenu
                      id="mostrarMais"
                      data-mostrarmais-name={item.nome}
                      items={[
                        {
                          content: 'Editar',
                          onClick: () => editCategory(item),
                          funcionalidade:
                            ConstanteFuncionalidades.CATEGORIA_PRODUTO_ALTERAR,
                        },
                        {
                          content: 'Remover',
                          onClick: () =>
                            handleDeleteCategoriaProduto(item.id, item.ativo),
                          funcionalidade:
                            ConstanteFuncionalidades.CATEGORIA_PRODUTO_EXCLUIR,
                        },
                        ...ativarInativarDropdownObjeto,
                      ]}
                    />
                  )}
                </SideContainer>
              </>
            )}
          </Flex>
        </Card>
      </Container>

      {adicionando && nivel < 4 && (
        <Container height={36} nivel={nivel + 1}>
          <Icon as={IoMdArrowDownRight} fontSize="16px" />
          <Card nivel={nivel + 1} isTyping>
            <ItemAdicionandoContent
              categoriaProdutoPaiId={item.id}
              ativo={item.ativo}
              setAdicionando={setAdicionando}
            />
          </Card>
        </Container>
      )}

      {isOpen && haveChildrens && children}
    </>
  );
};

export default memo(Item);
