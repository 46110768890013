import { useBarcode } from 'next-barcode';

interface Options {
  format?: string;
  width?: number;
  height?: number;
  displayValue?: boolean;
  text?: string;
  fontOptions?: string;
  font?: string;
  textAlign?: string;
  textPosition?: string;
  textMargin?: number;
  fontSize?: number;
  background?: string;
  lineColor?: string;
  margin?: number;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  flat?: boolean;
  valid?: (valid: boolean) => void;
}

interface BarcodeComponentProps {
  value: string;
  options?: Options;
}

export default function BarcodeComponent({
  value,
  options,
}: BarcodeComponentProps) {
  const { inputRef } = useBarcode({
    value,
    options,
  });

  return <svg ref={inputRef} />;
}
