import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory, Prompt, RouteComponentProps } from 'react-router-dom';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import isPrenvent from 'helpers/layout/isPrenvent';
import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import CampoPersonalizadoInterface from 'types/campoPersonalizado';
import CampoPersonalizadoValorInterface from 'types/campoPersonalizadoValor';

import RodapeFormulario from 'components/Geral/RodapeFormulario';
import ManterFoco from 'components/Geral/ManterFoco';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { Card } from 'styles';
import { ContainerListagem } from 'components/Layout/Listagem/containerListagem';
import {
  useForm,
  yupResolverCliente,
  yupResolverFornecedor,
} from '../validationForm';
import { Container } from '../styles';

import UncontrolledForm, { UncontrolledFormRefInterface } from '..';

type TParams = { id: string };

interface AlterarProps extends RouteComponentProps<TParams> {
  rotaCliente?: boolean;
}

interface UncontrolledFormInterface {
  setNameInputRef: (e: HTMLInputElement) => void;
  errors: FieldErrors;
  register: UseFormRegister<Record<string, any>>;
  control: any;
  readonly?: boolean;
}

const Visualizar = ({ rotaCliente, match }: AlterarProps) => {
  const history = useHistory();
  const isMountedRef = useIsMountedRef();
  isPrenvent();

  const [isLoading, setIsLoading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [dataHoraCadastro, setDataHoraCadastro] = useState('');
  const [dataHoraUltimaAlteracao, setDataHoraUltimaAlteracao] = useState('');
  const [camposPersonalizados, setCamposPersonalizados] = useState<
    CampoPersonalizadoInterface[]
  >([]);

  const formMethods = useForm({
    resolver: rotaCliente ? yupResolverCliente : yupResolverFornecedor,
    shouldUnregister: false,
  });
  const {
    register,
    control,
    reset,
    getValues,
    formState,
    setError,
    setValue,
    watch,
  } = formMethods;

  const uncontrolledFormRef = useRef<UncontrolledFormRefInterface>();

  const handleGetClienteFornecedor = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<void, ResponseApi<any>>(
      rotaCliente
        ? ConstanteEnderecoWebservice.CLIENTE_OBTER
        : ConstanteEnderecoWebservice.FORNECEDOR_OBTER,
      {
        params: { id: match.params.id },
      }
    );

    if (response?.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (response?.sucesso && isMountedRef.current) {
      const {
        camposPersonalizados: dataCamposPersonalizados,
        ...data
      } = response.dados;

      setCamposPersonalizados(
        dataCamposPersonalizados.map(
          ({ campoPersonalizado }: CampoPersonalizadoValorInterface) =>
            campoPersonalizado
        )
      );

      const camposPersonalizadosValor = dataCamposPersonalizados.map(
        ({
          campoPersonalizadoId,
          valor,
        }: CampoPersonalizadoValorInterface) => ({
          campoPersonalizadoId,
          valor: Number(valor) || valor,
        })
      );

      reset({
        ...data,
        dataNascimento: data.dataNascimento?.split('T')[0],
        telefoneWhats: data.telefoneWhatsapp,
        celularWhats: data.celularWhatsapp,
        categoriaCliente: {
          value: data.categoriaClienteOpcaoSelect.id,
          label: data.categoriaClienteOpcaoSelect.nome,
        },
        cidade: {
          label: data.enderecos[0].cidadeNome,
          value: data.enderecos[0].cidadeId,
          paisId: data.enderecos[0].paisId,
        },
        pais: {
          label: data.enderecos[0].paisNome,
          value: data.enderecos[0].paisId,
        },
        estado: data.enderecos[0].estadoNome,
        camposPersonalizados: camposPersonalizadosValor,
        tabelaPreco:
          data.tabelaPrecoId !== null
            ? {
                label: data.tabelaPrecoNome,
                value: data.tabelaPrecoId,
              }
            : {
                label: 'Digite o nome da tabela de preço',
                value: null,
              },
        cep: data.enderecos.filter((obj: any) => obj.principal)[0].cep,
        logradouro: data.enderecos.filter((obj: any) => obj.principal)[0]
          .logradouro,
        numero: data.enderecos.filter((obj: any) => obj.principal)[0].numero,
        complemento: data.enderecos.filter((obj: any) => obj.principal)[0]
          .complemento,
        bairro: data.enderecos.filter((obj: any) => obj.principal)[0].bairro,
      });

      if (uncontrolledFormRef.current) {
        await uncontrolledFormRef.current.setCidade(
          response.dados.enderecos.filter((obj: any) => obj.principal)[0]
            .cidadeId
        );

        if (!getValues('pais')) {
          await uncontrolledFormRef.current.setPais(
            response.dados.enderecos.filter((obj: any) => obj.principal)[0]
              .paisId
          );
        }

        const unformattedCpfCnpj =
          response.dados.cpfCnpj !== null
            ? response.dados.cpfCnpj.replace(/\D/g, '')
            : '';
        if (unformattedCpfCnpj.length <= 11) {
          uncontrolledFormRef.current.setCpfOrCnpj(true);
        } else {
          uncontrolledFormRef.current.setCpfOrCnpj(false);
        }
      }
      if (response.dados.contatosAdicionais) {
        setValue(
          'contatosAdicionais',
          response.dados.contatosAdicionais.map((obj: any) => {
            return {
              id: obj.id,
              Email: obj.email,
              Nome: obj.nome,
              Telefone: obj.telefone,
              WhatsApp: obj.whatsApp,
              codigo: undefined,
            };
          })
        );
      }

      if (response.dados.enderecos) {
        setValue(
          'enderecosAdicionais',
          response.dados.enderecos
            .filter((obj: any) => !obj.principal)
            .map((obj: any) => {
              return {
                id: obj.id,
                bairro: obj.bairro,
                cep: obj.cep,
                cidade: obj.cidadeId,
                cidadeCampo: {
                  label: obj.cidadeNome,
                  value: obj.cidadeId,
                  paisId: obj.paisId,
                },
                cidadeNome: obj.cidadeNome,
                cobranca: obj.cobranca,
                codigo: 0,
                complemento: obj.complemento,
                entrega: obj.entrega,
                logradouro: obj.logradouro,
                numero: obj.numero,
                pais: obj.paisId,
                paisCampo: { label: obj.paisNome, value: obj.paisId },
                principal: false,
                estado: obj.estadoNome,
              };
            })
        );
      }

      setDataHoraCadastro(response.dados.dataHoraCadastro);
      setDataHoraUltimaAlteracao(response.dados.dataHoraUltimaAlteracao);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      rotaCliente
        ? history.push(ConstanteRotas.CLIENTE)
        : history.push(ConstanteRotas.FORNECEDOR);
    }

    if (isMountedRef.current) setIsLoading(false);
  }, [
    history,
    isMountedRef,
    match.params.id,
    reset,
    setValue,
    getValues,
    rotaCliente,
  ]);

  useEffect(() => {
    setIsLoading(true);

    handleGetClienteFornecedor();
  }, [handleGetClienteFornecedor]);

  useEffect(() => {
    setFormIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  return (
    <ContainerListagem
      formMethods={formMethods}
      isLoading={isLoading}
      formIsDirty={formIsDirty}
      dataHoraUltimaAlteracao={dataHoraUltimaAlteracao}
      dataHoraCadastro={dataHoraCadastro}
      isVisualizar
      maxWidth="full"
    >
      <UncontrolledForm
        ref={uncontrolledFormRef}
        errors={formState.errors}
        control={control}
        register={register}
        setValue={setValue}
        getValue={getValues}
        setError={setError}
        watch={watch}
        camposPersonalizados={camposPersonalizados}
        rotaCliente={rotaCliente}
        readonly
      />
    </ContainerListagem>
  );
};

export default Visualizar;
