const TipoContaFinanceiraEnum = {
  CAIXA: 1,
  BANCO: 2,
  CONTA_COFRE: 3,
  CAIXA_MOVEL: 4,

  properties: {
    1: { name: 'Caixa', value: 1 },
    2: { name: 'Conta bancária', value: 2 },
    4: { name: 'Smart POS', value: 4 },
  },
};

export default TipoContaFinanceiraEnum;
