import { Prompt } from 'react-router-dom';

import UncontrolledForm from '../..';
import { useAlterarItem } from './hooks';

export const AlterarItem = () => {
  const { validatePrompt, handleExitRegistration } = useAlterarItem();

  return (
    <>
      <Prompt
        when={validatePrompt}
        message={(location) => {
          handleExitRegistration(location.pathname);

          return false;
        }}
      />

      <UncontrolledForm />
    </>
  );
};
