import { Text, Box } from '@chakra-ui/react';
import EstadosEnum from 'constants/enum/estados';
import { InformacoesProtocoloInterface } from 'types/ImpressaoNFCe';

interface ImpressaoInterface {
  siglaUf: string;
  informacoesProtocolo?: InformacoesProtocoloInterface;
}

const DezenasPremiadas = ({
  siglaUf,
  informacoesProtocolo,
}: ImpressaoInterface) => {
  const siglaMatoGrossoDoSul = Object.values(EstadosEnum.properties).find(
    (estado) => estado.codigo === EstadosEnum.MatoGrossoSul.codigo
  )?.sigla;

  if (
    informacoesProtocolo?.codigoMensagem !== '200' ||
    siglaUf !== siglaMatoGrossoDoSul
  ) {
    return null;
  }

  const splitMessage = informacoesProtocolo?.mensagem?.split(' | ');

  return (
    <Box>
      {splitMessage?.map((message) => (
        <Text>{message}</Text>
      ))}
    </Box>
  );
};

export default DezenasPremiadas;
