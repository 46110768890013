import { GridItem } from '@chakra-ui/react';

import Input from 'components/PDV/Input';
import CheckboxAtivoInativo from 'components/update/Checkbox/CheckboxAtivoInativo';
import Permissoes, { CategoriaPermissaoInterface } from 'components/Permissoes';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';

interface UncontrolledFormInterface {
  readonly?: boolean;
  categorias: Array<CategoriaPermissaoInterface>;
}

export const UncontrolledForm = ({
  readonly,
  categorias,
}: UncontrolledFormInterface) => {
  return (
    <SimpleGridForm>
      <GridItem colSpan={[12, 12, 6]}>
        <Input
          type="text"
          name="descricao"
          label="Descrição"
          autoFocus
          isRequired
          placeholder="Digite um nome para diferenciar o perfil de usuário"
          maxLength={100}
          isDisabled={readonly}
        />
      </GridItem>
      <GridItem colSpan={[12, 12, 3]}>
        <CheckboxAtivoInativo
          id="ativo"
          name="ativo"
          label="Status"
          isDisabled={readonly}
        />
      </GridItem>

      <GridItem colSpan={12}>
        <Permissoes readonly={readonly} categorias={categorias} />
      </GridItem>
    </SimpleGridForm>
  );
};
