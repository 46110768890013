import React from 'react';

import { useSelecaoFormaPagamentoContext } from 'store/PDV/SelecaoFormaPagamento';

import ListarParcelas from './ListarParcelas';
import InformarValorParcela from './InformarValorParcela';
import ListarOpcoesFormasPagamento from './ListarOpcoesFormasPagamento';

const SelecaoFormaPagamento = () => {
  const { formaPagamentoStep } = useSelecaoFormaPagamentoContext();

  if (formaPagamentoStep === 'selecionandoParcela') {
    return <ListarParcelas />;
  }
  if (formaPagamentoStep === 'informandoValor') {
    return <InformarValorParcela />;
  }

  return <ListarOpcoesFormasPagamento />;
};

export default SelecaoFormaPagamento;
