import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Col, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import PlanoContaInterface from 'types/planoConta';
import { SalvarConfirmarIcon } from 'icons';
import TipoPlanoContaEnum from 'constants/enum/tipoPlanoConta';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { usePlanoContasGeralContext } from 'store/PlanoContas/PlanoContasGeral';

import ButtonPadrao from 'components/Button/ButtonPadrao';
import ModalPadrao from 'components/Modal/ModalPadrao';
import InputPadrao from 'components/Input/InputPadrao';
import SelectPadrao from 'components/Select/SelectPadrao';
import CheckBoxDefault from 'components/CheckBox/CheckBoxDefault';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { Label } from 'styles';

import { useForm, yupResolver, defaultValues } from './validationForm';

interface AdicionarPlanoContaModalProps {
  show: boolean;
  onHide: () => void;
  planoConta: {
    permiteTipoTotalizador: boolean;
    tipoPlanoContaObrigatorio?: number;
    obj?: PlanoContaInterface;
    planoContaPaiId?: string;
  };
}

const AdicionarPlanoContaModal = ({
  show,
  onHide,
  planoConta,
}: AdicionarPlanoContaModalProps) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { errors },
    getValues,
    control,
    reset,
    watch,
    setFocus,
  } = useForm({
    resolver: yupResolver,
    defaultValues: {
      ...defaultValues,
      ...(planoConta.tipoPlanoContaObrigatorio
        ? { tipoPlanoConta: planoConta.tipoPlanoContaObrigatorio }
        : {}),
    },
  });
  const {
    handleAddPlanoConta,
    handleUpdatePlanoConta,
    handleGetSubNiveisPlanoConta,
  } = usePlanoContasGeralContext();

  const [isLoading, setIsLoading] = useState(false);

  const haveChildrens = useMemo(
    () => planoConta.obj && handleGetSubNiveisPlanoConta(planoConta.obj.id) > 1,
    [handleGetSubNiveisPlanoConta, planoConta.obj]
  );

  const tipoPlanoContaWatch = watch('tipoPlanoConta');

  const handlePutPlanoConta = async () => {
    setIsLoading(true);

    const data = { ...planoConta.obj, ...getValues() } as PlanoContaInterface;

    const response = await api.put<void, ResponseApi>(
      ConstanteEnderecoWebservice.PLANO_CONTA_ALTERAR,
      data
    );

    if (response) {
      if (response.sucesso) {
        handleUpdatePlanoConta(data);
      }

      if (response.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }
    }

    setIsLoading(false);

    return response && response.sucesso;
  };

  const handlePostPlanoConta = async () => {
    setIsLoading(true);

    const { tipoPlanoConta, nome, exibirDre, exibirFluxoDeCaixa } = getValues();

    const response = await api.post<void, ResponseApi<PlanoContaInterface>>(
      ConstanteEnderecoWebservice.PLANO_CONTA_CADASTRAR,
      {
        nome,
        tipoPlanoConta,
        exibirDre,
        exibirFluxoDeCaixa,
        planoContaPaiId: planoConta.planoContaPaiId,
        ativo: true,
      }
    );

    if (response) {
      if (response.sucesso && response.dados) {
        handleAddPlanoConta(response.dados);
      }

      if (response.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }
    }

    setIsLoading(false);

    return response && response.sucesso;
  };

  const onSubmit = handleSubmit(async () => {
    let success = false;

    if (planoConta.obj) {
      success = await handlePutPlanoConta();
    } else {
      success = await handlePostPlanoConta();
    }

    if (success) {
      onHide();
    }
  });

  useEffect(() => {
    if (show && planoConta.obj) {
      reset(planoConta.obj);
    } else {
      reset({
        ...defaultValues,
        ...(planoConta.tipoPlanoContaObrigatorio
          ? { tipoPlanoConta: planoConta.tipoPlanoContaObrigatorio }
          : {}),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <ModalPadrao
      isOpen={show}
      handleOnHide={onHide}
      title={t('Plano de contas')}
      subtitle={
        planoConta.obj
          ? t('Editar um plano de conta')
          : t('Adicionar novo plano de conta')
      }
      maxWidth="470px"
      onEntered={() => setFocus('tipoPlanoConta')}
    >
      <Modal.Body>
        {isLoading && <LoadingPadrao />}
        <Form.Row>
          <Col xs={12}>
            <SelectPadrao
              id="tipoPlanoConta"
              name="tipoPlanoConta"
              label={t('Tipo de conta')}
              required
              noSelectedText={t('Selecione')}
              defaultValue={planoConta.tipoPlanoContaObrigatorio || 0}
              control={control}
              error={errors.tipoPlanoConta}
              options={Object.entries(TipoPlanoContaEnum.properties)
                .filter(
                  (value: any) =>
                    planoConta.permiteTipoTotalizador ||
                    value[1].value !== TipoPlanoContaEnum.TOTALIZADOR
                )
                .map((value: any) => {
                  return (
                    {
                      label: t(value[1].name),
                      value: value[1].value,
                    } || {}
                  );
                })}
              isDisabled={
                haveChildrens || !!planoConta.tipoPlanoContaObrigatorio
              }
            />
          </Col>
          <Col xs={12}>
            <InputPadrao
              type="text"
              id="nome"
              label={t('Nome')}
              placeholder={t('Digite o nome')}
              maxLength={60}
              required
              error={errors.nome}
              control={control}
            />
          </Col>
          {tipoPlanoContaWatch && tipoPlanoContaWatch !== 1 && (
            <>
              <Col xs={0} style={{ display: 'flex', alignItems: 'center' }}>
                <CheckBoxDefault
                  id="exibirDre"
                  name="exibirDre"
                  control={control}
                />
                <Label
                  id="label-for-exibirDre"
                  htmlFor="exibirDre"
                  style={{
                    marginLeft: '10px',
                  }}
                >
                  Exibir no DRE
                </Label>
              </Col>
              <Col
                xs="auto"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  maxWidth: '100%',
                }}
              >
                <CheckBoxDefault
                  id="exibirFluxoDeCaixa"
                  name="exibirFluxoDeCaixa"
                  control={control}
                />
                <Label
                  id="label-for-exibirFluxoDeCaixa"
                  htmlFor="exibirFluxoDeCaixa"
                  style={{ marginLeft: '10px' }}
                >
                  Exibir no fluxo de caixa
                </Label>
              </Col>
            </>
          )}
        </Form.Row>
      </Modal.Body>
      <Modal.Footer>
        <ButtonPadrao
          id="fechar"
          name="fechar"
          text={t('Fechar')}
          type="button"
          variant="outline-secondary"
          onClick={onHide}
          style={{ height: '35px', width: '100%', maxWidth: '100px' }}
        />

        <ButtonPadrao
          id="salvar"
          name="salvar"
          text={t('Salvar')}
          icon={SalvarConfirmarIcon}
          type="button"
          variant="success"
          onClick={() => onSubmit()}
          style={{ height: '35px', width: '100%', maxWidth: '120px' }}
        />
      </Modal.Footer>
    </ModalPadrao>
  );
};

export default AdicionarPlanoContaModal;
