const ModalidadesBaseCalculoIcmsEnum = {
  MargemValorAgregado: 0,
  PautaValor: 1,
  PrecoTabeladoOuMaximoSugeridoValor: 2,
  ValorOperacao: 3,

  properties: {
    1: { name: 'Margem valor agregado (%)', value: 0 },
    2: { name: 'Pauta (valor)', value: 1 },
    3: { name: 'Preço tabelado máx. (valor)', value: 2 },
    4: { name: 'Valor da operação', value: 3 },
  },
};

export default ModalidadesBaseCalculoIcmsEnum;
