import React from 'react';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

import LojasListar from '../pages/Lojas/Listar';
import LojasCadastrar from '../pages/Lojas/Formulario/Cadastrar';
import LojasAlterar from '../pages/Lojas/Formulario/Alterar';
import LojasVisualizar from '../pages/Lojas/Formulario/Visualizar';

const LojasRoutes = [
  <LayoutGuard
    key="lojas-listar"
    permissaoFuncionalidade={ConstanteFuncionalidades.LOJA_LISTAR}
    component={LojasListar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Minhas Lojas',
      },
    ]}
    path={ConstanteRotas.MINHAS_LOJAS}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="lojas-cadastrar"
    permissaoFuncionalidade={ConstanteFuncionalidades.LOJA_CADASTRAR}
    component={LojasCadastrar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Minhas Lojas',
        path: ConstanteRotas.MINHAS_LOJAS,
      },
      {
        title: 'Novo',
      },
    ]}
    path={ConstanteRotas.LOJA_CADASTRAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="lojas-alterar"
    permissaoFuncionalidade={ConstanteFuncionalidades.LOJA_ALTERAR}
    component={LojasAlterar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Minhas Lojas',
        path: ConstanteRotas.MINHAS_LOJAS,
      },
      {
        title: 'Editar',
      },
    ]}
    path={ConstanteRotas.LOJA_ALTERAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="lojas-visualizar"
    permissaoFuncionalidade={ConstanteFuncionalidades.LOJA_VISUALIZAR}
    component={LojasVisualizar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Minhas Lojas',
        path: ConstanteRotas.MINHAS_LOJAS,
      },
      {
        title: 'Visualizar',
      },
    ]}
    path={ConstanteRotas.LOJA_VISUALIZAR}
    exact
    meta={{ auth: true }}
  />,
];

export default LojasRoutes;
