import { useCallback, useEffect, useState } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  ModalFooter,
  Box,
  ModalHeader,
  useMediaQuery,
  Divider,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { enumIdentificacaoIntegracao } from 'constants/enum/enumIdentificacaoIntegracao';
import { enumIdentificacaoSituacao } from 'constants/enum/enumIdentificacaoSituacao';

import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import Input from 'components/PDV/Input';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { ButtonDefault } from 'components/Button/ButtonChakra';

import { yupResolver } from './validationForms';

type FormData = {
  operacaoSistema: number;
};

type SituacaoResponseProps = {
  id: string;
  nome: string;
};

type ModalValidarSituacaoProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<FormData> & {
    id: string;
    formaRecebimento: string;
    atualizarPendencia: (id: string) => void;
    siteId: string;
    trayId: string;
  };

export const ModalValidarSituacao = create<ModalValidarSituacaoProps, FormData>(
  ({
    onResolve,
    onReject,
    id,
    formaRecebimento,
    siteId,
    trayId,
    atualizarPendencia,
    ...rest
  }) => {
    const [situacaoSite, setSituacaoSite] = useState<SituacaoResponseProps>();
    const [isLoading, setIsLoading] = useState(false);

    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    const formMethods = useForm<FormData>({
      resolver: yupResolver,
    });

    const { handleSubmit: onSubmit, watch } = formMethods;
    const fluxoWatch = watch('operacaoSistema');

    const handleSubmit = onSubmit(async (formData) => {
      setIsLoading(true);
      const data = {
        integracaoSituacaoId: situacaoSite?.id,
        descricaoSituacaoIntegracao: situacaoSite?.nome,
        situacaoSistema: formData.operacaoSistema,
      };

      const response = await api.post<void, ResponseApi>(
        `${ConstanteEnderecoWebservice.INTEGRACAO_TRAY_SITUACAO}/${trayId}`,
        [data]
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }

        if (response.sucesso) {
          toast.success('Situação vinculada com sucesso');
          atualizarPendencia(id);
          onClose();
          setIsLoading(false);
        }
        setIsLoading(false);
      }
      setIsLoading(false);
    });

    const getSituacao = useCallback(async () => {
      setIsLoading(true);
      const response = await api.get<
        void,
        ResponseApi<SituacaoResponseProps[]>
      >(ConstanteEnderecoWebservice.INTEGRACAO_TRAY_OPERACOES_VENDA, {
        params: {
          integracao: enumIdentificacaoIntegracao.TRAY,
        },
      });

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }

        if (response.sucesso && response.dados) {
          const newSituacao = response.dados.find((itemSituacao) =>
            itemSituacao.nome.toLowerCase().includes(siteId.toLowerCase())
          );

          setSituacaoSite(newSituacao);

          setIsLoading(false);
        }
        setIsLoading(false);
      }
      setIsLoading(false);
    }, [siteId]);

    useEffect(() => {
      getSituacao();
    }, [getSituacao]);

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? '3xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
      >
        <ModalContent
          bg="gray.50"
          borderRadius={isLargerThan900 ? 'md' : '0'}
          marginBottom={isLargerThan900 ? '3.75rem' : '0'}
          marginTop={isLargerThan900 ? '3.75rem' : '0'}
          w={isLargerThan900 ? '640px' : 'full'}
          h={isLargerThan900 ? '422px' : 'full'}
        >
          <ModalHeader py="24px" pl="40px">
            <Text color="primary.50" fontSize="16px" fontWeight="bold">
              Novo STATUS cadastrado na Tray
            </Text>
            <Text color="gray.700" fontSize="14px" mt="4px" mb="12px">
              Identifique para qual fluxo operacional a nova situação
              corresponde.
            </Text>
            <Divider borderColor="gray.200" />
          </ModalHeader>
          <FormProvider {...formMethods}>
            {isLoading && <LoadingPadrao />}
            <ModalBody px="40px" mb="20px" pt="0" overflowY="auto" pb="0">
              <Flex direction="column" gap="20px" w="full">
                <Box
                  mr={['0', '0', '24px']}
                  mb={['30px', '24px', '0']}
                  width="full"
                >
                  <Input
                    label="Situação utilizada na Tray"
                    id="situacaoSite"
                    isDisabled
                    paddingLeft="24px"
                    _disabled={{ bgColor: 'gray.100', borderColor: 'gray.200' }}
                    height="56px"
                    defaultValue={siteId}
                    name="situacaoSite"
                  />
                </Box>
                <Box width="full">
                  <SelectPadrao
                    name="operacaoSistema"
                    placeholder="Selecione uma opção: Pedido, Venda ou Cancelamento"
                    label="Identifique o fluxo operacional:"
                    id="operacaoSistema"
                    options={enumIdentificacaoSituacao.options}
                  />
                </Box>
              </Flex>
            </ModalBody>
            <ModalFooter flexDirection="column" pb="40px">
              <Flex
                w="full"
                h="full"
                justifyContent="center"
                alignItems="baseline"
                gap="24px"
              >
                <Button
                  variant="outlineDefault"
                  colorScheme="gray"
                  onClick={() => onClose()}
                  w="96px"
                  height="32px"
                  fontSize="14px"
                >
                  Cancelar
                </Button>
                <ButtonDefault
                  variant="solid"
                  colorScheme={fluxoWatch ? 'secondary.400' : 'gray'}
                  width="160px"
                  fontSize="14px"
                  color={fluxoWatch ? 'black' : 'white'}
                  fontWeight="500"
                  height="32px"
                  isDisabled={!fluxoWatch}
                  onClick={() => handleSubmit()}
                  isLoading={isLoading}
                >
                  Confirmar
                </ButtonDefault>
              </Flex>
            </ModalFooter>
          </FormProvider>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
