import { Text, Box, VStack, useMediaQuery } from '@chakra-ui/react';
import {
  ContainerIntegracaoFixo,
  useSmartPosEtapasContext,
} from 'store/SmartPos/SmartPosEtapasContext';

import { MenuIntegracoes } from 'icons';

import { IdentificacaoEtapasSmartPos } from 'constants/enum/IdentificacaoEtapasSmartPos';
import { FooterSmartPos } from '../Layout/FooterSmartPos';
import { HeaderSmartPos } from '../Layout/Header';

export const GuiaIntegracao = () => {
  const { setActiveStep } = useSmartPosEtapasContext();

  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

  const handleEntrarFormularioStone = () => {
    setActiveStep(IdentificacaoEtapasSmartPos.FORMULARIO_STONE);
  };

  const handleComecarConfiguracao = () => {
    setActiveStep(IdentificacaoEtapasSmartPos.AUTENTICACAO);
  };

  return (
    <>
      <ContainerIntegracaoFixo height={isLargerThan700 ? '100vh' : '100%'}>
        <HeaderSmartPos title="Guia de integração" icon={MenuIntegracoes} />
        <VStack
          pl={['10px', '10px', '20px']}
          pr={['10px', '10px', '20px']}
          color="primary.50"
          fontSize="14px"
          spacing={['10px', '10px', '24px']}
        >
          <Text textAlign={['center', 'left', 'left']} letterSpacing="0px">
            Inicialmente o aplicativo Smart POS está homologado apenas com a
            operadora STONE. Será necessário ter um plano contratado diretamente
            com a Stone e utilizar uma maquininha do modelo SMART. Se você já é
            cliente Stone e possui um dispositivo no modelo Smart, clique em
            “Iniciar configuração”.
          </Text>

          <Box>
            <Text textAlign={['center', 'left', 'left']} fontWeight="bold">
              Sou cliente Stone mas minha maquininha NÃO É SMART:
            </Text>
            <Text textAlign={['center', 'left', 'left']} letterSpacing="0px">
              Entre em contato com a Stone e informe o atendente que você irá
              utilizar o aplicativo “Smart POS” e necessita de uma maquininha do
              modelo Smart. Você já pode configurar o sistema, mas precisa da
              nova maquininha para instalar o app de vendas.
            </Text>
          </Box>

          <Box>
            <Text textAlign={['center', 'left', 'left']} fontWeight="bold">
              Se você ainda não tem um plano contratado na Stone:
            </Text>
            <Text textAlign={['center', 'left', 'left']} letterSpacing="0px">
              Clique em “Quero ser cliente Stone” para solicitar o contato de um
              especialista direto da Stone. A negociação de planos e tarifas
              será entre você e a operadora, totalmente independente do nosso
              aplicativo.
            </Text>
          </Box>
        </VStack>
      </ContainerIntegracaoFixo>
      <FooterSmartPos
        mt={['20px', '-30px', '0']}
        mb={['20px', '0', '0']}
        handleAvancar={handleComecarConfiguracao}
        handleClienteStone={handleEntrarFormularioStone}
      />
    </>
  );
};
