import React from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Icon,
  Flex,
  Text,
  HStack,
  Box,
  useToken,
} from '@chakra-ui/react';
import { DotLoader } from 'react-spinners';

import { AtualizandoEstoqueIcon } from 'icons';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';

export const ModalCorrigindoEstoque = (props: Omit<ModalProps, 'children'>) => {
  const primary50 = useToken('colors', 'primary.50');

  return (
    <ModalPadraoChakra isCentered size="xl" {...props}>
      <ModalContent
        marginBottom={{ base: 0, sm: '3.75rem' }}
        marginTop={{ base: 0, sm: '3.75rem' }}
        h="unset"
        maxW="550px"
        mx={4}
      >
        <ModalBody px={9} py={16}>
          <Flex alignItems="center" justifyContent="space-between" h="full">
            <HStack spacing={6} mr={6}>
              <Icon as={AtualizandoEstoqueIcon} boxSize={16} color="gray.700" />

              <Flex flexDirection="column">
                <Text fontSize="xl" fontWeight="bold" color="gray.700">
                  Atualização de estoque
                </Text>
                <Text fontSize="lg" color={primary50}>
                  Corrigindo quantidade no sistema...
                </Text>
              </Flex>
            </HStack>

            <Box position="relative">
              <DotLoader size={24} color={primary50} />
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </ModalPadraoChakra>
  );
};
