import { Flex, Text, Button, useMediaQuery } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { useProdutosConsignadosContext } from 'store/PDV/ProdutosConsignados';

import { ClienteProps } from '../types/validationForms';

type ConsignacaoFooterProps = {
  clienteConsignacao?: ClienteProps;
  quantidade: number[];
  handleVoltarParaPdv: () => void;
};

export const ConsignacaoFooter = ({
  clienteConsignacao,
  quantidade,
  handleVoltarParaPdv,
}: ConsignacaoFooterProps) => {
  const {
    produtosSelecionados,
    handleAtualizarDadosParaVenda,
    handleAtualizarDadosParaDevolucao,
    produtosConsignados,
    produtosConsignadosJaForamDevolvidos,
    setProdutosConsignadosJaForamDevolvidos,
    setExisteCodigoBarras,
  } = useProdutosConsignadosContext();

  const { reset } = useFormContext();
  const [isLargerThan900] = useMediaQuery('(max-width: 900px)');

  const limparForm = useCallback(() => {
    reset({
      quantidade: [1],
    });
  }, [reset]);

  const handleDevolverProdutos = useCallback(async () => {
    await setExisteCodigoBarras(false);
    await handleAtualizarDadosParaDevolucao(quantidade);

    setTimeout(async () => {
      await limparForm();
    }, 1000);
    await setProdutosConsignadosJaForamDevolvidos(true);
  }, [
    setExisteCodigoBarras,
    handleAtualizarDadosParaDevolucao,
    quantidade,
    setProdutosConsignadosJaForamDevolvidos,
    limparForm,
  ]);

  const handleVenderProdutos = useCallback(async () => {
    await handleAtualizarDadosParaVenda(quantidade);
  }, [handleAtualizarDadosParaVenda, quantidade]);

  return (
    <Flex
      justifyContent="space-between"
      flexDirection={isLargerThan900 ? 'column' : 'row'}
      alignItems="center"
      bg={isLargerThan900 ? '' : 'primary.800'}
      h="52px"
    >
      {!isLargerThan900 && (
        <Flex pl="12px">
          <Text fontSize="14px" mr="10px" color="white">
            Cliente:
          </Text>
          <Text
            textTransform="capitalize"
            fontSize="14px"
            color="secondary.300"
          >
            {clienteConsignacao?.label}
          </Text>
        </Flex>
      )}
      {produtosConsignados.length > 0 && (
        <Flex
          mt={isLargerThan900 ? '21px' : ''}
          mb={isLargerThan900 ? '21px' : ''}
          flexDirection={isLargerThan900 ? 'column-reverse' : 'row'}
          w={isLargerThan900 ? '90%' : ''}
        >
          {produtosConsignadosJaForamDevolvidos && (
            <Button
              id="Finalizar"
              name="Finalizar"
              color="#CCCCCC"
              variant="outline"
              h={isLargerThan900 ? '40px ' : '32px'}
              fontSize="14px"
              mr={isLargerThan900 ? '' : '24px'}
              w={isLargerThan900 ? 'full' : '96px'}
              borderRadius="16px"
              type="button"
              _hover={{ bg: 'none' }}
              onClick={() => handleVoltarParaPdv()}
            >
              {produtosConsignadosJaForamDevolvidos ? 'Finalizar' : 'Fechar'}
            </Button>
          )}

          {!produtosConsignadosJaForamDevolvidos && (
            <Button
              id="ficarTudo"
              name="ficarTudo"
              color={
                produtosConsignados.length < 1 ||
                (produtosSelecionados !== 0 && isLargerThan900)
                  ? 'black'
                  : 'gray.100'
              }
              variant="outline"
              bg={
                produtosConsignados.length < 1 ||
                (produtosSelecionados !== 0 && isLargerThan900)
                  ? 'gray.100'
                  : 'none'
              }
              h={isLargerThan900 ? '40px ' : '32px'}
              mb={isLargerThan900 ? '16px' : ''}
              fontSize="14px"
              mr={isLargerThan900 ? '' : '24px'}
              w={isLargerThan900 ? 'full' : '120px'}
              isDisabled={
                produtosConsignados.length < 1 || produtosSelecionados !== 0
              }
              borderRadius="16px"
              type="button"
              _hover={{
                bg:
                  produtosConsignados.length < 1 ||
                  (produtosSelecionados !== 0 && isLargerThan900)
                    ? 'gray.100'
                    : 'none',
              }}
              onClick={() => setProdutosConsignadosJaForamDevolvidos(true)}
            >
              Ficar com tudo
            </Button>
          )}
          {produtosConsignadosJaForamDevolvidos ? (
            <Button
              id="gerarVenda"
              name="gerarVenda"
              borderColor="aquamarine.300"
              fontSize="14px"
              color="black"
              variant="outline"
              bg="aquamarine.300"
              h={isLargerThan900 ? '40px ' : '32px'}
              w={isLargerThan900 ? 'full' : '140px'}
              mb={isLargerThan900 ? '16px' : ''}
              borderRadius="16px"
              isDisabled={
                produtosSelecionados < 1 || produtosConsignados.length < 1
              }
              type="button"
              _hover={{
                bg: 'aquamarine.300',
              }}
              onClick={() => handleVenderProdutos()}
            >
              Gerar venda
            </Button>
          ) : (
            <Button
              id="devolverProduto"
              name="devolverProduto"
              borderColor={
                produtosSelecionados < 1 || produtosConsignados.length < 1
                  ? 'gray.100'
                  : 'orange.300'
              }
              fontSize="14px"
              color="black"
              variant="outline"
              mb={isLargerThan900 ? '16px' : ''}
              bg={
                produtosSelecionados < 1 || produtosConsignados.length < 1
                  ? 'gray.100'
                  : 'orange.300'
              }
              h={isLargerThan900 ? '40px ' : '32px'}
              w={isLargerThan900 ? 'full' : '160px'}
              borderRadius="16px"
              isDisabled={
                produtosSelecionados < 1 || produtosConsignados.length < 1
              }
              type="button"
              _hover={{
                bg:
                  produtosSelecionados < 1 || produtosConsignados.length < 1
                    ? 'gray.100'
                    : 'orange.300',
              }}
              onClick={() => handleDevolverProdutos()}
            >
              Devolver produtos
            </Button>
          )}
        </Flex>
      )}
    </Flex>
  );
};
