import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';

import TipoFiltroProdutoEstoqueEnum from 'constants/enum/tipoFiltroProdutoEstoque';
import StatusConsultaEnum from 'constants/enum/statusConsulta';

export type CamposPersonalizadosProps = {
  campoPersonalizadoId: string;
  valor: number | string | null;
};

export type FiltrosProps = {
  nomeReferencia: string;
  sku: string;
  codigoBarras: string;
  tipoEstoque: number;
  statusConsulta: number;
  cores: null | string[];
  tamanhos: null | string[];
  categoriasProduto: null | string[];
  marcas: null | string[];
  tags: null | string[];
  camposPersonalizados?: CamposPersonalizadosProps[];
};

export type Produto = {
  id: string;
  nome: string;
  referencia: string;
  sku: string;
  precoVenda: number;
  precoCusto: number;
  estoque: number;
  ativo: boolean;
  tipoProduto: number;
};

export type OptionsResponseProps = {
  nome: string;
  id: string;
};

export type OptionsProps = {
  value: string | null;
  label: string;
};

const schema = yup.object().shape({
  nomeReferencia: yup.string().nullable().default(''),
  sku: yup.string().nullable().default(''),
  codigoBarras: yup.string().nullable().default(''),
  tipoEstoque: yup.number().default(TipoFiltroProdutoEstoqueEnum.TODOS),
  statusConsulta: yup.number().default(StatusConsultaEnum.ATIVOS),
  cores: yup.array().of(yup.string()).nullable().default(null),
  tamanhos: yup.array().of(yup.string()).nullable().default(null),
  categoriasProduto: yup.array().of(yup.string()).nullable().default(null),
  marcas: yup.array().of(yup.string()).nullable().default(null),
  tags: yup.array().of(yup.string()).nullable().default(null),
});

export const yupResolver = yupResolverInstance(schema);

export const useForm = useFormInstance;

export const defaultValues = {
  nomeReferencia: '',
  sku: '',
  codigoBarras: '',
  tipoEstoque: TipoFiltroProdutoEstoqueEnum.TODOS,
  statusConsulta: StatusConsultaEnum.ATIVOS,
  cores: null,
  tamanhos: null,
  categoriasProduto: null,
  marcas: null,
  tags: null,
  camposPersonalizados: undefined,
};
