import { useCallback } from 'react';
import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  Flex,
  Box,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import {
  useProdutosFormularioContext,
  LojaProps,
  TabelaPrecoProps,
} from 'store/Produtos/ProdutosFormulario';
import TipoProdutoEnum from 'constants/enum/tipoProduto';
import auth from 'modules/auth';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';

import { NumberInput } from 'components/update/Input/NumberInput';

import { ModalPadronizarValores, FormData } from './ModalPadronizarValores';

type PrecoLojaProps = {
  lojasCadastradas: LojaProps[];
  tabelaPreco: TabelaPrecoProps[];
  setValoresIniciaisAlterados: React.Dispatch<React.SetStateAction<boolean>>;
};

export function PrecoPorLoja({
  lojasCadastradas,
  tabelaPreco,
  setValoresIniciaisAlterados,
}: PrecoLojaProps) {
  const { action, tipoProduto } = useProdutosFormularioContext();
  const { casasDecimais } = usePadronizacaoContext();

  const { id } = auth.getLoja();

  const { setValue, getValues } = useFormContext();

  const produtoEhDoTipoKit = Boolean(
    tipoProduto === TipoProdutoEnum.PRODUTO_KIT && action === 'alterar'
  );

  const handleModificarMarkup = useCallback(
    (lojaId: string) => {
      const precoVenda = getValues(`valuePrecoVenda.${lojaId}`);

      if (precoVenda === 0) {
        return;
      }

      const precoCusto = getValues(`valuePrecoCusto.${lojaId}`);

      const newMarkup = (precoVenda / precoCusto - 1) * 100;

      if (newMarkup === Infinity) {
        return;
      }

      if (lojaId === id) {
        tabelaPreco.forEach((tabelaItem) => {
          if (tabelaItem.isTabelaPreco) {
            const precoVendaTabelaPreco = getValues(
              `valuePrecoVenda.${tabelaItem.lojaId}`
            );
            const markupTabelaPreco =
              (precoVendaTabelaPreco / precoCusto - 1) * 100;

            setValue(
              `valueMarkup.${tabelaItem.lojaId}`,
              (markupTabelaPreco || 0)?.toFixed(4)
            );
          }
        });
      }

      setValue(`valueMarkup.${lojaId}`, (newMarkup || 0)?.toFixed(4));
    },
    [getValues, id, setValue, tabelaPreco]
  );

  const atualizarValoresPadronizados = useCallback(
    (data: FormData) => {
      const newValueData = data;
      setValoresIniciaisAlterados(true);
      lojasCadastradas.forEach((item, index) => {
        if (data.lojas[index]?.isChecked !== true) {
          return;
        }

        if (newValueData.precoCompra !== 0) {
          setValue(
            `valuePrecoCompra.${item.lojaId}`,
            newValueData.precoCompra.toFixed(2)
          );
          handleModificarMarkup(item.lojaId);
        }
        if (newValueData.precoCusto !== 0) {
          setValue(
            `valuePrecoCusto.${item.lojaId}`,
            newValueData.precoCusto.toFixed(2)
          );
          handleModificarMarkup(item.lojaId);
        }
      });
    },
    [
      lojasCadastradas,
      setValue,
      setValoresIniciaisAlterados,
      handleModificarMarkup,
    ]
  );

  const handlePadronizarValores = useCallback(() => {
    ModalPadronizarValores({
      padronizarValores: (event) => atualizarValoresPadronizados(event),
      casasDecimaisValor: casasDecimais.casasDecimaisValor,
      lojasCadastradas,
    });
  }, [atualizarValoresPadronizados, casasDecimais, lojasCadastradas]);

  return (
    <>
      <Table variant="none" minW="800px">
        <Thead>
          <Tr
            sx={{
              '& > th': {
                color: 'gray.500',
                fontWeight: 'bold',
                paddingTop: '0 !important',
                paddingBottom: '0',
                fontSize: '12px',
                paddingLeft: '0 !important',
              },
            }}
          >
            <Th w="40%">
              <Flex justifyContent="flex-start">Loja</Flex>
            </Th>
            <Th w="20%">
              <Flex justifyContent="flex-start">Preço de compra</Flex>
            </Th>
            <Th w="20%">
              <Flex justifyContent="flex-start">Preço de custo</Flex>
            </Th>
            {lojasCadastradas.length > 0 && !produtoEhDoTipoKit && (
              <Th w="20%">
                <Flex
                  cursor="pointer"
                  textDecoration="underline"
                  justifyContent="flex-end"
                  color="blue.500"
                  onClick={handlePadronizarValores}
                  whiteSpace="nowrap"
                >
                  Padronizar custo
                </Flex>
              </Th>
            )}
          </Tr>
          <Tr>
            <Th
              paddingBottom="0 !important"
              paddingTop="5px"
              borderBottomWidth="1px"
              borderColor="none"
              colSpan={4}
            />
          </Tr>
        </Thead>
        <Tbody>
          {lojasCadastradas.map((loja) => (
            <>
              <Tr
                key={loja.id}
                sx={{
                  '& > td': {
                    paddingRight: '4',
                    paddingLeft: 0,
                  },
                }}
              >
                <Td w="50%" paddingLeft="0 !important">
                  <Box>
                    <Text fontWeight="semibold">{loja.fantasia}</Text>
                    <Text fontSize="12px">
                      {loja.endereco} - {loja.cidade}
                    </Text>
                  </Box>
                </Td>
                <Td w="25%">
                  <NumberInput
                    w="96%"
                    defaultValue={loja.precoCompra}
                    bgLeftElement="gray.50"
                    leftElement="R$"
                    editarFundoLeftElemento
                    leftElementColor="gray.700"
                    leftElementFontSize="xs"
                    precision={12}
                    onBlur={() => setValoresIniciaisAlterados(true)}
                    isDisabled={produtoEhDoTipoKit}
                    name={`valuePrecoCompra.${loja.lojaId}`}
                    id={`valuePrecoCompra.${loja.lojaId}`}
                  />
                </Td>
                <Td w="25%">
                  <NumberInput
                    w="96%"
                    precision={12}
                    isDisabled={produtoEhDoTipoKit}
                    bgLeftElement="gray.50"
                    defaultValue={loja.precoCusto}
                    leftElementColor="gray.700"
                    leftElementFontSize="xs"
                    scale={casasDecimais.casasDecimaisValor}
                    leftElement="R$"
                    onBlur={() => {
                      handleModificarMarkup(loja.lojaId);
                      setValoresIniciaisAlterados(true);
                    }}
                    editarFundoLeftElemento
                    name={`valuePrecoCusto.${loja.lojaId}`}
                    id={`valuePrecoCusto.${loja.lojaId}`}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td
                  paddingBottom="0 !important"
                  paddingTop="5px"
                  borderBottomWidth="1px"
                  borderColor="gray.200"
                  colSpan={4}
                />
              </Tr>
            </>
          ))}
        </Tbody>
      </Table>
    </>
  );
}
