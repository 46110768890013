import { useCallback, useEffect, useState } from 'react';
import {
  ModalContent,
  ModalBody,
  ModalFooter,
  Flex,
  Heading,
  Divider,
  ModalProps,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import auth from 'modules/auth';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import StatusConsultaEnum from 'constants/enum/statusConsulta';

import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';

import {
  ListaLocalEstoqueProps,
  LojasProps,
  TransferirEstoqueProps,
  LojasResponseProps,
  yupResolver,
} from './validationForm';

type PromiseModalProps = {
  transferir: boolean;
  localEstoqueIdDestino?: string;
};

interface ModalTransferirEstoqueProps
  extends InstanceProps<PromiseModalProps>,
    Omit<ModalProps, 'children' | 'isOpen' | 'onClose'> {
  localEstoqueIdOrigem: string;
}

export const ModalTransferirEstoque = create<
  ModalTransferirEstoqueProps,
  PromiseModalProps
>(({ onResolve, localEstoqueIdOrigem, ...rest }) => {
  const [lojas, setLojas] = useState<LojasProps[]>([]);

  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const formMethods = useForm<TransferirEstoqueProps>({
    defaultValues: {
      loja: null,
      localEstoque: null,
    },
    resolver: yupResolver,
  });

  const { watch, setValue, handleSubmit } = formMethods;

  const [lojaWatch, localEstoqueWatch] = watch(['loja', 'localEstoque']);

  const isDisabledSubmit = !lojaWatch || !localEstoqueWatch;

  const { id: lojaId } = auth.getLoja();

  const lojaDestino = lojas.find((loja) => loja.value === lojaWatch);

  const obterLojas = useCallback(async () => {
    const params = {
      statusConsulta: StatusConsultaEnum.ATIVOS,
      lojaId,
    };

    const response = await api.get<void, ResponseApi<LojasResponseProps[]>>(
      ConstanteEnderecoWebservice.LOJA_LISTAR_TRANSFERENCIA_ESTOQUE,
      {
        params,
      }
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }
      if (response?.sucesso && response?.dados) {
        const newEstoqueOptions = response.dados
          ?.filter((option) => option.id === lojaId)
          ?.map((option) => ({
            value: option.id,
            label: option.fantasia,
            listaLocalEstoque: option.listaLocalEstoque,
          }));
        setLojas(newEstoqueOptions);
        setValue('loja', newEstoqueOptions[0]?.value);
      }
    }
  }, [lojaId, setValue]);

  const localEstoqueOptions = useCallback(() => {
    if (lojaDestino && lojaDestino.listaLocalEstoque) {
      const listLocalEstoqueLojaDestino = lojaDestino.listaLocalEstoque
        ?.filter(
          (localEstoque: ListaLocalEstoqueProps) =>
            localEstoque.id !== localEstoqueIdOrigem
        )
        ?.map((localEstoque: ListaLocalEstoqueProps) => ({
          value: localEstoque.id,
          label: localEstoque.nome,
        }));

      return listLocalEstoqueLojaDestino;
    }

    return [];
  }, [lojaDestino, localEstoqueIdOrigem]);

  const onSubmit = handleSubmit(async (data) => {
    const { localEstoque } = data;

    onResolve({
      transferir: true,
      localEstoqueIdDestino: localEstoque || '',
    });

    onClose();
  });

  useEffect(() => {
    obterLojas();
  }, [obterLojas]);

  return (
    <ModalPadraoChakra
      onClose={() => {
        onClose();
        onResolve({
          transferir: false,
        });
      }}
      {...rest}
      isOpen={isOpen}
      isCentered
      size="xl"
      autoFocus={false}
    >
      <ModalContent
        marginBottom={{ base: 0, sm: '3.75rem' }}
        marginTop={{ base: 0, sm: '3.75rem' }}
        h="unset"
        bg="gray.50"
        maxW="500px"
      >
        <FormProvider {...formMethods}>
          <ModalBody p={0} h="100%">
            <Flex flexDirection="column" align="stretch" h="100%" px={10}>
              <Flex
                justifyContent="flex-start"
                flexDirection="column"
                bg="gray.50"
                py={7}
                borderRadius="5px"
              >
                <Heading
                  as="h2"
                  fontSize="lg"
                  color="primary.50"
                  fontWeight="semibold"
                  textAlign="initial"
                >
                  Transferência de estoque
                </Heading>
                <Divider mt={5} mb={6} borderWidth="1px" />

                <Flex flexDir="column" alignItems="center" gap="32px" mb={5}>
                  <SelectPadrao
                    id="loja"
                    name="loja"
                    label="Loja"
                    fontWeightLabel="semibold"
                    placeholder="Selecione a loja"
                    options={lojas}
                    isDisabled
                    onSelect={() => {
                      setValue('localEstoque', null);
                    }}
                  />
                  <SelectPadrao
                    id="localEstoque"
                    name="localEstoque"
                    label="Local do Estoque"
                    fontWeightLabel="semibold"
                    placeholder="Selecione um local"
                    options={localEstoqueOptions()}
                  />
                </Flex>
              </Flex>
              <Divider borderWidth="1px" />
            </Flex>
          </ModalBody>
        </FormProvider>

        <ModalFooter py="24px">
          <Flex w="100%" alignItems="center" justifyContent="center" gap="32px">
            <Button
              id="cancelModal"
              variant="outlineDefault"
              color="gray.400"
              borderColor="gray.400"
              px="20px"
              onClick={() => {
                onResolve({
                  transferir: false,
                });
                onClose();
              }}
            >
              Cancelar
            </Button>
            <Button
              id="okButton"
              colorScheme={isDisabledSubmit ? 'gray' : 'secondary'}
              px="32px"
              variant={isDisabledSubmit ? 'outline' : 'solid'}
              isDisabled={isDisabledSubmit}
              onClick={onSubmit}
            >
              Confirmar e Transferir
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </ModalPadraoChakra>
  );
});
