import { Box } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';

import auth from 'modules/auth';
import { ConstanteRotasAlternativas } from 'constants/rotas';
import ConstanteFuncionalidades from 'constants/permissoes';

import { ComissaoVendedoresComPermissao } from './ComissaoVendedores/ComissaoVendedoresComPermissao';
import { ComissaoVendedorSemPermissao } from './ComissaoVendedores/ComissaoVendedoresSemPermissao';
import { Vendedor } from './Types/validationsForm';

export type FormData = {
  mesAno: Date;
  mesAnoUsuarioSemPermissao: Date;
  vendedor: Vendedor[];
};

export const formDefaultValues = {
  mesAno: new Date(),
  mesAnoUsuarioSemPermissao: new Date(),
};

export const MetasComissoesPossuemCadastro = () => {
  const history = useHistory();
  const formMethods = useForm<FormData>({ defaultValues: formDefaultValues });

  const handleAbrirHistoricoVendas = () => {
    history.push(ConstanteRotasAlternativas.HISTORICO_VENDAS_METAS_COMISSOES);
  };

  const handleAbrirRelatorioVendas = () => {
    history.push(ConstanteRotasAlternativas.RELATORIO_VENDAS_METAS_COMISSOES);
  };

  const possuiPermissaoMetaComissao = auth.possuiPermissao(
    ConstanteFuncionalidades.METAS_COMISSAO
  ).permitido;

  return (
    <FormProvider {...formMethods}>
      <Box>
        {possuiPermissaoMetaComissao ? (
          <ComissaoVendedoresComPermissao
            handleAbrirRelatorioVendas={handleAbrirRelatorioVendas}
            handleAbrirHistoricoVendas={handleAbrirHistoricoVendas}
          />
        ) : (
          <ComissaoVendedorSemPermissao
            handleAbrirRelatorioVendas={handleAbrirRelatorioVendas}
            handleAbrirHistoricoVendas={handleAbrirHistoricoVendas}
          />
        )}
      </Box>
    </FormProvider>
  );
};
