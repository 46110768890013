import { useCallback } from 'react';
import { UseFormGetValues } from 'react-hook-form';
import { useMediaQuery } from '@chakra-ui/react';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { enumIdentificacaoIntegracao } from 'constants/enum/enumIdentificacaoIntegracao';

export type ValoresEcommerceProps = {
  id: string;
  produtoId: string;
  identificacaoIntegracao: number;
  titulo: string;
  descricao: string;
  anunciado: boolean;
  itensInclusos: string;
  tempoGarantia: string;
  disponibilidadeEntrega: string;
  campo1: string;
  campo2: string;
  [key: string]: string | number | boolean;
};

const identificadoresEcommerces = [enumIdentificacaoIntegracao.TRAY];

export const useProdutoEcommerce = () => {
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const salvarDadosEcommerces = useCallback(
    async (idProduto: string, data: ValoresEcommerceProps[]) => {
      return api.put<void, ResponseApi<ValoresEcommerceProps[]>>(
        `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/ecommerces`,
        data
      );
    },
    []
  );

  const obterDadosEcommerces = useCallback(async (idProduto: string) => {
    return api.get<void, ResponseApi<ValoresEcommerceProps[]>>(
      `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/ecommerces`
    );
  }, []);

  const obterDadosFormularioEcommerces = useCallback(
    (idProduto: string, getValues: UseFormGetValues<any>) =>
      identificadoresEcommerces.map((identificacao) => {
        const valores = {
          produtoId: idProduto,
          identificacaoIntegracao: identificacao,
          titulo: getValues(`titulo-${identificacao}`),
          descricao: getValues(`descricao-${identificacao}`),
          anunciado: getValues(`anunciado-${identificacao}`),
          itensInclusos: getValues(`itensInclusos-${identificacao}`),
          tempoGarantia: getValues(`tempoGarantia-${identificacao}`),
          disponibilidadeEntrega: getValues(
            `disponibilidadeEntrega-${identificacao}`
          ),
          campo1: getValues(`campo1-${identificacao}`),
          campo2: getValues(`campo2-${identificacao}`),
        } as ValoresEcommerceProps;

        return valores;
      }),
    []
  );

  return {
    isLargerThan900,
    salvarDadosEcommerces,
    obterDadosEcommerces,
    obterDadosFormularioEcommerces,
  };
};
