import { enumIdentificacaoIntegracao } from 'constants/enum/enumIdentificacaoIntegracao';

import { TrayEcommerce } from './Tray';

export const valueTabs = [
  {
    label: 'Tray Commerce',
    value: enumIdentificacaoIntegracao.TRAY,
    component: <TrayEcommerce />,
  },
];
