import React from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useMediaQuery,
  useDisclosure,
  ModalHeader,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { BrowserRouter, Route } from 'react-router-dom';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import CadastrarTransportadora from 'pages/Transportadoras/Formulario/Cadastrar';

type ModalCadastrarTransportadoraResponse = {
  transportadora: { id: string; nome: string };
};

type ModalCadastrarTransportadoraProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalCadastrarTransportadoraResponse> & {
    inputValue: string;
  };

export const ModalCadastrarTransportadora = create<
  ModalCadastrarTransportadoraProps,
  ModalCadastrarTransportadoraResponse
>(({ onResolve, onReject, inputValue, ...rest }) => {
  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  async function onSubmit(data: any) {
    const response = await api.post<
      void,
      ResponseApi<{ id: string; nome: string }>
    >(
      ConstanteEnderecoWebservice.TRANSPORTADORA_CADASTRAR_TRANSPORTADORA_MODAL,
      { cidadeId: data.cidade.value, paisId: data.pais.value, ...data }
    );

    if (response) {
      if (response.sucesso && response.dados) {
        onResolve({ transportadora: { ...response.dados } });

        return true;
      }

      if (response.avisos) {
        response.avisos.map((aviso: string) => toast.warning(aviso));
      }
    }

    return false;
  }

  return (
    <ModalPadraoChakra size="full" {...rest} isOpen={isOpen} onClose={onClose}>
      <ModalContent mx="6" my="6">
        <ModalHeader
          mt={isSmallerThan900 ? 12 : undefined}
          mb={isSmallerThan900 ? 8 : undefined}
          borderBottom="1px"
          borderColor="gray.100"
          px="0"
          mx={{ base: 6, md: 8 }}
        >
          Cadastrar Transportadora
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p={{ base: 6, md: 8 }}>
          <BrowserRouter>
            <Route>
              <CadastrarTransportadora
                cadastroExterno
                nomeTransportadora={inputValue}
                onSubmitExterno={onSubmit}
              />
            </Route>
          </BrowserRouter>
        </ModalBody>
      </ModalContent>
    </ModalPadraoChakra>
  );
});
