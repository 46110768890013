import React from 'react';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

import MarcasListar from '../pages/Marcas/Listar';
import MarcasCadastrar from '../pages/Marcas/Formulario/Cadastrar';
import MarcasAlterar from '../pages/Marcas/Formulario/Alterar';
import MarcasVisualizar from '../pages/Marcas/Formulario/Visualizar';

const CoresRoutes = [
  <LayoutGuard
    key="marcas-listar"
    permissaoFuncionalidade={ConstanteFuncionalidades.MARCA_LISTAR}
    component={MarcasListar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Marcas',
      },
    ]}
    path={ConstanteRotas.MARCA}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="marcas-cadastrar"
    permissaoFuncionalidade={ConstanteFuncionalidades.MARCA_CADASTRAR}
    component={MarcasCadastrar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Marcas',
        path: ConstanteRotas.MARCA,
      },
      {
        title: 'Novo',
      },
    ]}
    path={ConstanteRotas.MARCA_CADASTRAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="marcas-alterar"
    permissaoFuncionalidade={ConstanteFuncionalidades.MARCA_ALTERAR}
    component={MarcasAlterar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Marcas',
        path: ConstanteRotas.MARCA,
      },
      {
        title: 'Editar',
      },
    ]}
    path={ConstanteRotas.MARCA_ALTERAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="marcas-visualizar"
    permissaoFuncionalidade={ConstanteFuncionalidades.MARCA_VISUALIZAR}
    component={MarcasVisualizar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Marcas',
        path: ConstanteRotas.MARCA,
      },
      {
        title: 'Visualizar',
      },
    ]}
    path={ConstanteRotas.MARCA_VISUALIZAR}
    exact
    meta={{ auth: true }}
  />,
];

export default CoresRoutes;
