import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FormProvider } from 'react-hook-form';

import api, { ResponseApi } from 'services/api';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import isPrenvent from 'helpers/layout/isPrenvent';
import { getName } from 'helpers/enum/getName';
import { cnpjMask } from 'helpers/format/fieldsMasks';
import formatUTCToLocateDateTimeInput from 'helpers/format/formatUTCToLocateDateTimeInput';
import ConstanteRotas from 'constants/rotas';
import StatusFiscaisEnum from 'constants/enum/fiscal/statusFiscal';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import NotaFiscalFormularioProvider from 'store/NotaFiscal/NotaFiscalFormulario';

import RodapeFormulario from 'components/Geral/RodapeFormulario';
import ManterFoco from 'components/Geral/ManterFoco';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { Card } from 'styles';

import { useForm } from '../validationForm';
import { Container } from '../styles';
import { UncontrolledForm } from '..';

type TParams = { id: string };

export const NotasFiscaisVisualizar = () => {
  const history = useHistory();
  const isMountedRef = useIsMountedRef();
  const { id: idRota } = useParams<TParams>();

  isPrenvent();

  const [isLoading, setIsLoading] = useState(false);

  const [dataHoraCadastro, setDataHoraCadastro] = useState('');
  const [dataHoraUltimaAlteracao, setDataHoraUltimaAlteracao] = useState('');
  const [alteracaoCarregada, setAlteracaoCarregada] = useState(false);

  const refTabInicial = useRef() as React.MutableRefObject<HTMLAnchorElement>;

  const formMethods = useForm();

  const handleGetNotaFiscal = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<void, ResponseApi<any>>(
      ConstanteEnderecoWebservice.NOTA_FISCAL_OBTER,
      {
        params: { id: idRota },
      }
    );

    if (response?.avisos) {
      response.avisos.forEach((aviso: string) => toast.warning(aviso));
    }

    if (response?.sucesso && isMountedRef.current) {
      const { dados } = response;

      formMethods.reset({
        ...dados,
        dataEmissao: formatUTCToLocateDateTimeInput(dados.dataEmissao),
        dataSaida: dados.dataSaida
          ? formatUTCToLocateDateTimeInput(dados.dataSaida)
          : null,
        documentoFiscalPagamentos: dados.documentoFiscalPagamentos.map(
          (item: any) => {
            return {
              ...item,
              vencimento: formatUTCToLocateDateTimeInput(item.vencimento),
            };
          }
        ),
        cnpjIntermediador: dados.cnpjIntermediador
          ? cnpjMask(dados.cnpjIntermediador)
          : null,
      });
      formMethods.setValue('modeloFiscal', dados.modeloFiscal);

      setDataHoraCadastro(response.dados.dataHoraCadastro);
      setDataHoraUltimaAlteracao(response.dados.dataHoraUltimaAlteracao);

      const cliente = response.dados.cliente.obj;

      formMethods.setValue(
        'destinatarioEnderecoEntrega',
        cliente.descricaoEnderecoEntrega
      );

      formMethods.setValue(
        'destinatarioEnderecoCobranca',
        cliente.descricaoEnderecoCobranca
      );

      const statusDescricao = getName(StatusFiscaisEnum, response.dados.status);

      formMethods.setValue('status', statusDescricao);
    } else {
      history.push(ConstanteRotas.NOTA_FISCAL);
    }

    setAlteracaoCarregada(true);
    if (isMountedRef.current) setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idRota, isMountedRef]);

  useEffect(() => {
    setIsLoading(true);

    handleGetNotaFiscal();
  }, [handleGetNotaFiscal]);

  return (
    <>
      <Container>
        <FormProvider {...formMethods}>
          <Form>
            <ManterFoco blockTab={isLoading}>
              <Card>
                {isLoading && <LoadingPadrao />}
                <NotaFiscalFormularioProvider
                  readonly
                  alteracaoCarregada={alteracaoCarregada}
                >
                  <UncontrolledForm
                    refTabInicial={refTabInicial}
                    isVisualizar
                  />
                </NotaFiscalFormularioProvider>
              </Card>
              <RodapeFormulario
                dataHoraCriacao={dataHoraCadastro}
                dataHoraUltimaAlteracao={dataHoraUltimaAlteracao}
                disabled
                isDisabledReset
              />
            </ManterFoco>
          </Form>
        </FormProvider>
      </Container>
    </>
  );
};
