import React, { createContext, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import usePersistedState from 'helpers/layout/usePersistedState';

interface NavMenuContextProps {
  openMenuKey: string;
  setOpenMenu: (dataKey?: string) => void;
  handleHistoryPush: (path?: string) => void;
  navMenuIsOpen: boolean;
  setNavMenuIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const NavMenuContext = createContext<NavMenuContextProps>(
  {} as NavMenuContextProps
);

interface NavMenuProviderProps {
  children: React.ReactNode | undefined;
}

export default function NavMenuProvider({
  children,
}: NavMenuProviderProps): JSX.Element {
  const history = useHistory();

  const [navMenuIsOpen, setNavMenuIsOpen] = usePersistedState<boolean>(
    'navMenuIsOpen',
    true
  );
  const [openMenuKey, setOpenMenuKey] = useState('');

  function setOpenMenu(menuKey?: string) {
    setOpenMenuKey(menuKey || '');
  }

  function handleHistoryPush(path?: string) {
    if (path) {
      history.push(path);

      setOpenMenuKey('');
    }
  }

  return (
    <NavMenuContext.Provider
      value={{
        openMenuKey,
        setOpenMenu,
        handleHistoryPush,
        navMenuIsOpen,
        setNavMenuIsOpen,
      }}
    >
      {children}
    </NavMenuContext.Provider>
  );
}

export function useNavMenuContext(): NavMenuContextProps {
  const context = useContext(NavMenuContext);

  if (!context)
    throw new Error('useNavMenuContext must be used within a NavMenuProvider.');

  return context;
}
