import auth from 'modules/auth';
import PlanoContratacaoEnum from 'constants/enum/planoContratacao';

import { DadosGerais } from './Tabs/dadosGerais';
import { Vendedor } from './Tabs/vendedor';
import { TabelaPreco } from './Tabs/tabelaPreco';
import { Situacao } from './Tabs/situacao';
import { LocalEstoque } from './Tabs/localEstoque';
import { Intermediador } from './Tabs/intermediador';

type ValueTabsProps = {
  label: string;
  content: React.ReactNode;
};

const planoAtual = auth.getPlano();

const isPlanoStart = planoAtual === PlanoContratacaoEnum.START;
const isPlanoPro = planoAtual === PlanoContratacaoEnum.PRO;

export const valueTabs = [
  { label: 'Dados gerais', content: <DadosGerais /> },
  { label: 'Vendedor', content: <Vendedor /> },
  isPlanoStart
    ? undefined
    : { label: 'Tabela de preços', content: <TabelaPreco /> },
  isPlanoStart || isPlanoPro
    ? undefined
    : { label: 'Local de estoque', content: <LocalEstoque /> },
  { label: 'Situação', content: <Situacao /> },
  { label: 'Intermediador', content: <Intermediador /> },
].filter(Boolean) as ValueTabsProps[];
