import { ReactNode, CSSProperties } from 'react';
import {
  Tr,
  Td,
  Table,
  Tbody,
  TableColumnHeaderProps,
  Tooltip,
} from '@chakra-ui/react';

import { CurvaABCEnum, TipoRelatorioCurvaABC } from 'constants/enum/curvaABC';
import {
  DecimalMask,
  moneyMask,
  porcentagemMask,
} from 'helpers/format/fieldsMasks';
import ConstanteFuncionalidades from 'constants/permissoes';
import auth from 'modules/auth';

export interface TableHeader
  extends Omit<TableColumnHeaderProps, 'children' | 'minW' | 'maxW' | 'w'> {
  key: string;
  content: ReactNode;
}

type ListarProdutosCurvaAbcProps = {
  nome: string;
  valorUnitario: number;
  custo: number;
  quantidadeVendida: number;
  quantidadeVendas: number;
  valorTotal: number;
  lucroValor: number;
  lucroPorcentagem: number;
  valorBase: number;
  porcentagem: number;
  porcentagemAcumulada: number;
  classe: 'A' | 'B' | 'C';
  key: string;
  style: CSSProperties;
  type: number;
  ordenacao: number;
  isSmallerThan1500: boolean;
};

type ColumnsValues = {
  typeRelatorioProduto?: {
    quantidade: number;
    valorUnitario: number;
    custoMedio: number;
    lucro: number;
    lucroPorcentagem: number;
    totalFaturamento: number;
    porcentagem: number;
    porcentagemAcumulada: number;
  };

  typeRelatorioClienteItensVendidosOuQuantidadeVendas?: {
    valorTotal: number;
    quantidadeVendas: number;
    itensVendidos: number;
    porcentagem: number;
    porcentagemAcumulada: number;
  };

  typeRelatorioClienteLucroOuTotalVendas?: {
    totalCusto: number;
    totalVendas: number;
    lucroPorcentagem: number;
    valorTotalLucro: number;
    porcentagem: number;
    porcentagemAcumulada: number;
  };
};

const ColorsClasse = {
  A: 'green.100',
  B: 'blue.100',
  C: 'red.100',
};

export function linhaProdutosCurvaAbc({
  classe,
  lucroPorcentagem,
  lucroValor,
  quantidadeVendida,
  nome,
  custo,
  porcentagem,
  porcentagemAcumulada,
  quantidadeVendas,
  valorTotal,
  valorUnitario,
  ordenacao,
  type,
  key,
  style,
  isSmallerThan1500,
}: ListarProdutosCurvaAbcProps) {
  const possuiPermissaoVisualizarPrecoCusto = auth.possuiPermissao(
    ConstanteFuncionalidades.USUARIO_VISUALIZAR_PRECO_CUSTO
  ).permitido;

  let width = '170px';

  const typeIsClienteItensVendidosOuQuantidadeVendas =
    type === TipoRelatorioCurvaABC.CLIENTE &&
    (ordenacao === CurvaABCEnum.ITENS_VENDIDOS ||
      ordenacao === CurvaABCEnum.QUANTIDADE_VENDAS);

  const typeIsClienteLucroOuTotalVendas =
    type === TipoRelatorioCurvaABC.CLIENTE &&
    (ordenacao === CurvaABCEnum.PORCENTAGEM_LUCRO_CLIENTE ||
      ordenacao === CurvaABCEnum.VALOR_LUCRO_CLIENTE ||
      ordenacao === CurvaABCEnum.VALOR_TOTAL_VENDAS);

  const columnValues = ((): ColumnsValues => {
    if (type === TipoRelatorioCurvaABC.PRODUTO) {
      width = '120px';

      return {
        typeRelatorioProduto: {
          quantidade: quantidadeVendida,
          valorUnitario,
          custoMedio: custo,
          lucro: lucroValor,
          lucroPorcentagem,
          totalFaturamento: valorTotal,
          porcentagem,
          porcentagemAcumulada,
        },
      };
    }
    if (typeIsClienteItensVendidosOuQuantidadeVendas) {
      width = '170px';
      return {
        typeRelatorioClienteItensVendidosOuQuantidadeVendas: {
          valorTotal,
          quantidadeVendas,
          itensVendidos: quantidadeVendida,
          porcentagem,
          porcentagemAcumulada,
        },
      };
    }
    if (typeIsClienteLucroOuTotalVendas) {
      width = '160px';
      return {
        typeRelatorioClienteLucroOuTotalVendas: {
          totalCusto: custo,
          totalVendas: valorTotal,
          lucroPorcentagem,
          valorTotalLucro: lucroValor,
          porcentagem,
          porcentagemAcumulada,
        },
      };
    }
    return {} as ColumnsValues;
  })();

  const {
    typeRelatorioProduto,
    typeRelatorioClienteItensVendidosOuQuantidadeVendas,
    typeRelatorioClienteLucroOuTotalVendas,
  } = columnValues;

  return (
    <Table
      style={style}
      variant="simple-card"
      boxShadow="none"
      sx={{
        borderCollapse: 'separate',
        borderSpacing: '0 4px',
      }}
      key={key}
    >
      <Tbody>
        <Tr
          sx={{
            '&': {
              boxShadow: 'none !important',
            },
            '& > td': {
              bg: ColorsClasse[classe],
              color: 'gray.700',
              lineHeigth: '1.6',
              maxW: isSmallerThan1500 ? '100px' : width,
              w: isSmallerThan1500 ? '100px' : width,
              minWidth: isSmallerThan1500 ? '100px' : width,
              whiteSpace: 'nowrap',
              px: '1',
            },
            'td:last-child': {
              paddingRight: '6',
            },
            'td:first-child': {
              width: 'auto',
            },
          }}
        >
          {type === TipoRelatorioCurvaABC.PRODUTO ? (
            <>
              <Td textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
                <Tooltip
                  isDisabled={nome?.length < 40}
                  bg="gray.900"
                  color="white"
                  hasArrow
                  placement="bottom"
                  label={nome}
                >
                  {nome}
                </Tooltip>
              </Td>
              <Td isNumeric>
                {DecimalMask(typeRelatorioProduto?.quantidade || 0, 2, 2)}
              </Td>
              <Td isNumeric>
                {moneyMask(typeRelatorioProduto?.valorUnitario, true)}
              </Td>
              {possuiPermissaoVisualizarPrecoCusto && (
                <Td isNumeric>
                  {moneyMask(typeRelatorioProduto?.custoMedio, true)}
                </Td>
              )}
              <Td isNumeric>
                {moneyMask(typeRelatorioProduto?.lucro || 0, true)}
              </Td>
              <Td isNumeric>
                {porcentagemMask(
                  typeRelatorioProduto?.lucroPorcentagem || 0,
                  0,
                  2
                )}
              </Td>
              <Td isNumeric>
                {moneyMask(typeRelatorioProduto?.totalFaturamento || 0, true)}
              </Td>
              <Td isNumeric>
                {porcentagemMask(typeRelatorioProduto?.porcentagem || 0, 0, 2)}
              </Td>
              <Td isNumeric>
                {porcentagemMask(
                  typeRelatorioProduto?.porcentagemAcumulada || 0,
                  0,
                  2
                )}
              </Td>
            </>
          ) : typeIsClienteLucroOuTotalVendas ? (
            <>
              <Td textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
                <Tooltip
                  isDisabled={nome?.length < 40}
                  bg="gray.900"
                  color="white"
                  hasArrow
                  placement="bottom"
                  label={nome}
                >
                  {nome}
                </Tooltip>
              </Td>
              <Td isNumeric>
                {moneyMask(
                  typeRelatorioClienteLucroOuTotalVendas?.totalCusto || 0,
                  true
                )}
              </Td>
              <Td isNumeric>
                {moneyMask(
                  typeRelatorioClienteLucroOuTotalVendas?.totalVendas || 0,
                  true
                )}
              </Td>
              <Td isNumeric>
                {porcentagemMask(
                  typeRelatorioClienteLucroOuTotalVendas?.lucroPorcentagem || 0,
                  0,
                  2
                )}
              </Td>
              <Td isNumeric>
                {moneyMask(
                  typeRelatorioClienteLucroOuTotalVendas?.valorTotalLucro || 0,
                  true
                )}
              </Td>
              <Td isNumeric>
                {porcentagemMask(
                  typeRelatorioClienteLucroOuTotalVendas?.porcentagem || 0,
                  0,
                  2
                )}
              </Td>
              <Td isNumeric>
                {porcentagemMask(
                  typeRelatorioClienteLucroOuTotalVendas?.porcentagemAcumulada ||
                    0,
                  0,
                  2
                )}
              </Td>
            </>
          ) : (
            <>
              <Td textOverflow="ellipsis" overflow="hidden" whiteSpace="normal">
                <Tooltip
                  isDisabled={nome?.length < 40}
                  bg="gray.900"
                  color="white"
                  hasArrow
                  placement="bottom"
                  label={nome}
                >
                  {nome}
                </Tooltip>
              </Td>
              <Td isNumeric>
                {moneyMask(
                  typeRelatorioClienteItensVendidosOuQuantidadeVendas?.valorTotal ||
                    0,
                  true
                )}
              </Td>
              <Td isNumeric>
                {DecimalMask(
                  typeRelatorioClienteItensVendidosOuQuantidadeVendas?.quantidadeVendas ||
                    0,
                  2,
                  2
                )}
              </Td>
              <Td isNumeric>
                {DecimalMask(
                  typeRelatorioClienteItensVendidosOuQuantidadeVendas?.itensVendidos ||
                    0,
                  2,
                  2
                )}
              </Td>
              <Td isNumeric>
                {porcentagemMask(
                  typeRelatorioClienteItensVendidosOuQuantidadeVendas?.porcentagem ||
                    0,
                  0,
                  2
                )}
              </Td>
              <Td isNumeric>
                {porcentagemMask(
                  typeRelatorioClienteItensVendidosOuQuantidadeVendas?.porcentagemAcumulada ||
                    0,
                  0,
                  2
                )}
              </Td>
            </>
          )}
        </Tr>
      </Tbody>
    </Table>
  );
}
