import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Text, GridItem, Button, Flex } from '@chakra-ui/react';
import { FormatOptionLabelMeta, OptionTypeBase } from 'react-select';
import { useHistory } from 'react-router-dom';

import OptionType from 'types/optionType';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import StatusConsultaEnum from 'constants/enum/statusConsulta';
import TipoConferenciaEnum from 'constants/enum/tipoConferencia';
import { useConferenciaEstoqueContainerContext } from 'store/ConferenciaEstoque/ConferenciaEstoqueContainer';
import { useConferenciaEstoqueEtapasContext } from 'store/ConferenciaEstoque/ConferenciaEstoqueEtapas';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';

import Select from 'components/PDV/Select/SelectPadrao';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { DetailedRadio } from 'components/update/Radio/DetailedRadio';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { Container, Body, Footer } from 'components/update/Steps/StepContent';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { SelectCategoria } from 'components/Select/SelectCategoria';

import { yupResolver, FormData } from './validationForm';

function formIsValid(data: FormData) {
  const { tipoConferencia, categorias, marcas } = data;

  if (!tipoConferencia) {
    return false;
  }

  if (
    tipoConferencia === String(TipoConferenciaEnum.COM_FILTROS) &&
    (categorias || []).length === 0 &&
    (marcas || []).length === 0
  ) {
    return false;
  }

  return true;
}

export function EscolherFiltros() {
  const history = useHistory();

  const {
    descartarConferencia,
    conferenciaEstoqueId,
  } = useConferenciaEstoqueContainerContext();
  const {
    previousStep,
    nextStep,
    nextStepRevisao,
    conferenciaEstoque,
    setConferenciaEstoque,
    existeProdutos,
  } = useConferenciaEstoqueEtapasContext();

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues: {
      tipoConferencia: String(conferenciaEstoque?.tipoConferencia || ''),
      marcas:
        conferenciaEstoque && conferenciaEstoque.listaMarcas
          ? conferenciaEstoque.listaMarcas.map((marca) => ({
              ...marca,
              isFixed: true,
            }))
          : null,
      categorias:
        conferenciaEstoque && conferenciaEstoque.listaCategorias
          ? conferenciaEstoque.listaCategorias.map((categoria) => ({
              ...categoria,
              isFixed: true,
            }))
          : null,
    },
  });

  const [
    tipoConferenciaWatch,
    marcasWatch,
    categoriasWatch,
  ] = formMethods.watch(['tipoConferencia', 'marcas', 'categorias']);

  const isValid = useMemo(
    () =>
      formIsValid({
        tipoConferencia: tipoConferenciaWatch,
        categorias: categoriasWatch,
        marcas: marcasWatch,
      }),
    [tipoConferenciaWatch, categoriasWatch, marcasWatch]
  );

  const optionsAlreadyLoaded = useRef(false);

  const [isLoading, setIsLoading] = useState(false);

  const [marcasOptions, setMarcasOptions] = useState<OptionType[]>([]);
  const [isMarcasLoading, setIsMarcasLoading] = useState(false);

  function handleDescartarConferencia() {
    descartarConferencia();
  }

  function handleVoltar() {
    previousStep();
  }

  const handleExportar = formMethods.handleSubmit(async (data) => {
    ModalConfirmacaoExcluir({
      title: 'Exportar produtos',
      text:
        'Deseja exportar todos os produtos de acordo com o filtro selecionado? Lembrando que ao importar os produtos que não pertencem ao filtro serão desconsiderados.',
      confirmButtonText: 'Exportar',
      cancelButtonText: 'Cancelar',
      callback: async (ok: boolean) => {
        if (ok && conferenciaEstoque && conferenciaEstoque.localEstoque) {
          const { tipoConferencia, categorias, marcas } = data;

          const conferenciaEstoqueCategoriasFiltros = (
            categorias || []
          ).map((categoria) => ({ categoriaProdutoId: categoria.value }));

          const conferenciaEstoqueMarcasFiltros = (marcas || []).map(
            (marca) => ({
              marcaId: marca.value,
            })
          );

          const response = await api.post<void, ResponseApi<string>>(
            ConstanteEnderecoWebservice.CONFERENCIA_ESTOQUE_EXPORTAR_PRODUTOS,
            {
              tipoConferencia: Number(tipoConferencia),
              localEstoqueId: conferenciaEstoque.localEstoque.value,
              conferenciaEstoqueFiltros: [
                ...conferenciaEstoqueCategoriasFiltros,
                ...conferenciaEstoqueMarcasFiltros,
              ],
            }
          );

          const dataHora = new Date();
          const dataFormatada = `${dataHora.getDate()}-${
            dataHora.getMonth() + 1
          }-${dataHora.getFullYear()}-0${dataHora.getHours()}-${dataHora.getMinutes()}`;

          if (response) {
            if (response.avisos) {
              response.avisos.forEach((aviso) => toast.warning(aviso));
            }

            if (response.sucesso && response.dados) {
              const url = window.URL.createObjectURL(
                new Blob([response.dados])
              );
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute(
                'download',
                `${dataFormatada}-conferencia-produto.csv`
              );
              link.click();
            }
          }
        }
      },
    });
  });

  const handleAvancar = formMethods.handleSubmit(
    async (data) => {
      setIsLoading(true);

      if (conferenciaEstoque && conferenciaEstoque.localEstoque) {
        const { tipoConferencia, categorias, marcas } = data;

        if (conferenciaEstoqueId) {
          const conferenciaEstoqueCategoriasFiltros = (
            categoriasWatch || []
          ).map((categoria) => ({ categoriaProdutoId: categoria.value }));

          const conferenciaEstoqueMarcasFiltros = (marcasWatch || []).map(
            (marca) => ({
              marcaId: marca.value,
            })
          );

          const response = await api.put<void, ResponseApi<string>>(
            ConstanteEnderecoWebservice.CONFERENCIA_ESTOQUE_CADASTRAR_NOVOS_FILTROS,
            {
              id: conferenciaEstoqueId,
              localEstoqueId: conferenciaEstoque.localEstoque.value,
              conferenciaEstoqueFiltros: [
                ...conferenciaEstoqueCategoriasFiltros,
                ...conferenciaEstoqueMarcasFiltros,
              ],
            }
          );

          if (response) {
            if (response.avisos) {
              response.avisos.map((aviso: string) => toast.warning(aviso));
            }

            if (response.sucesso) {
              setConferenciaEstoque({
                localEstoque: conferenciaEstoque?.localEstoque,
                tipoConferencia: Number(tipoConferencia),
                listaMarcas: marcas || undefined,
                listaCategorias: categorias || undefined,
              });

              if (
                Number(tipoConferencia) === TipoConferenciaEnum.ZERAR_ESTOQUE
              ) {
                nextStepRevisao();
              } else {
                nextStep();
              }
            }
          }
        } else {
          const conferenciaEstoqueCategoriasFiltros = (
            categorias || []
          ).map((categoria) => ({ categoriaProdutoId: categoria.value }));

          const conferenciaEstoqueMarcasFiltros = (marcas || []).map(
            (marca) => ({
              marcaId: marca.value,
            })
          );

          const response = await api.post<void, ResponseApi<string>>(
            ConstanteEnderecoWebservice.CONFERENCIA_ESTOQUE_CADASTRAR,
            {
              tipoConferencia: Number(tipoConferencia),
              localEstoqueId: conferenciaEstoque.localEstoque.value,
              conferenciaEstoqueFiltros: [
                ...conferenciaEstoqueCategoriasFiltros,
                ...conferenciaEstoqueMarcasFiltros,
              ],
            }
          );

          if (response) {
            if (response.avisos) {
              response.avisos.map((aviso: string) => toast.warning(aviso));
            }

            if (response.sucesso && response.dados) {
              setConferenciaEstoque({
                localEstoque: conferenciaEstoque?.localEstoque,
                tipoConferencia: Number(tipoConferencia),
                listaMarcas: marcas || undefined,
                listaCategorias: categorias || undefined,
              });

              history.push(
                SubstituirParametroRota(
                  ConstanteRotas.CONFERENCIA_ESTOQUE_CONTINUAR,
                  'id',
                  response.dados
                )
              );
            }
          }
        }
      }

      setIsLoading(false);
    },
    (err) => {
      if (err && err.tipoConferencia) {
        toast.warn(
          'Selecione o tipo do filtro de estoque que você vai usar na conferência.'
        );
      }
    }
  );

  const existeProdutoAdicionado = (existeProdutos || []).length > 0;
  useEffect(() => {
    async function loadMarcasOptions() {
      setIsMarcasLoading(true);

      const response = await api.get<void, ResponseApi<OptionTypeBase[]>>(
        ConstanteEnderecoWebservice.MARCA_LISTAR_SELECT,
        { params: { statusConsulta: StatusConsultaEnum.ATIVOS } }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.map((aviso: string) => toast.warning(aviso));
        }

        if (response.sucesso && response.dados) {
          const newMarcasOptions = response.dados.map((option) => ({
            value: option.id,
            label: option.nome,
          }));

          setMarcasOptions(newMarcasOptions);
        }
      }

      setIsMarcasLoading(false);
    }

    function loadOptions() {
      if (!optionsAlreadyLoaded.current) {
        optionsAlreadyLoaded.current = true;

        loadMarcasOptions();
      }
    }

    loadOptions();
  }, []);

  return (
    <Container>
      {isLoading && <LoadingPadrao />}
      <FormProvider {...formMethods}>
        <Body>
          <SimpleGridForm>
            <GridItem colSpan={12}>
              <DetailedRadio
                name="tipoConferencia"
                defaultValue={String(formMethods.getValues('tipoConferencia'))}
                onChange={(newValue) =>
                  formMethods.setValue('tipoConferencia', newValue)
                }
                items={[
                  {
                    value: String(TipoConferenciaEnum.COMPLETA),
                    title: 'Conferir o estoque completo',
                    subtitle:
                      'Todos os itens estarão disponíveis para conferência e correção.',
                    isDisabled: !!conferenciaEstoqueId,
                  },
                  {
                    value: String(TipoConferenciaEnum.COM_FILTROS),
                    title: 'Conferir apenas uma parte do estoque',
                    subtitle:
                      'Utilize os filtros apenas quando queira corrigir o estoque de determinadas categorias ou marcas.',
                    isDisabled: !!conferenciaEstoqueId,
                    activeContent: (
                      <SimpleGridForm
                        sx={{
                          '& .react-select__multi-value__remove': {
                            color: 'white',
                            fontSize: '2xs',
                            _hover: {
                              color: 'white',
                              bg: 'gray.400',
                            },
                          },
                        }}
                      >
                        <GridItem colSpan={6}>
                          <SelectCategoria
                            name="categorias"
                            label="Filtrar por Categorias"
                            asControlledByObject
                            isDisabled={existeProdutoAdicionado}
                          />
                        </GridItem>
                        <GridItem colSpan={6}>
                          <Select
                            id="marcas"
                            name="marcas"
                            label="Filtrar por Marcas"
                            isDisabled={existeProdutoAdicionado}
                            placeholder="Informe as marcas"
                            isMulti
                            asControlledByObject
                            options={marcasOptions}
                            isLoading={isMarcasLoading}
                            multiValueBg="var(--sti-ck-colors-gray-500)"
                            formatOptionLabel={(
                              option: OptionType,
                              labelMeta: FormatOptionLabelMeta<OptionType>
                            ) => {
                              const isValueContext =
                                labelMeta.context === 'value';

                              return (
                                <Text
                                  as="span"
                                  fontSize="1em"
                                  color={isValueContext ? 'white' : undefined}
                                >
                                  {option.label}
                                </Text>
                              );
                            }}
                          />
                        </GridItem>
                      </SimpleGridForm>
                    ),
                  },
                  {
                    value: String(TipoConferenciaEnum.ZERAR_ESTOQUE),
                    title: 'Zerar todos os produtos do estoque',
                    subtitle: 'Todos os itens ficarão com o estoque zerado.',
                    isDisabled: !!conferenciaEstoqueId,
                  },
                ]}
              />
            </GridItem>
          </SimpleGridForm>
        </Body>
        <Footer>
          <Flex w="full">
            <Button
              variant={isValid ? 'outlineDefault' : 'outline'}
              borderRadius="md"
              w="full"
              maxW={{ base: 'full', md: '160px' }}
              onClick={handleExportar}
              isDisabled={!isValid}
            >
              Exportar produtos
            </Button>
          </Flex>

          <Button
            variant="cancel"
            colorScheme="gray"
            borderRadius="md"
            w="full"
            maxW={{ base: 'full', md: '100px' }}
            onClick={handleDescartarConferencia}
          >
            Descartar
          </Button>
          {!conferenciaEstoqueId && (
            <Button
              variant="outlineDefault"
              borderRadius="md"
              w="full"
              maxW={{ base: 'full', md: '100px' }}
              onClick={handleVoltar}
            >
              Voltar
            </Button>
          )}
          <Button
            colorScheme="primary"
            borderRadius="md"
            w="full"
            maxW={{ base: 'full', md: '160px' }}
            onClick={handleAvancar}
            isDisabled={!isValid}
          >
            Avançar
          </Button>
        </Footer>
      </FormProvider>
    </Container>
  );
}
