import { useCallback, useEffect, useState } from 'react';
import {
  DrawerHeader,
  DrawerBody,
  DrawerContent,
  Icon,
  DrawerCloseButton,
  GridItem,
  FormLabel,
  Flex,
  Button,
  useMediaQuery,
} from '@chakra-ui/react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { useFormContext } from 'react-hook-form';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { enumIdentificacaoIntegracao } from 'constants/enum/enumIdentificacaoIntegracao';
import { situacaoOperacaoSistema } from 'constants/enum/enumIdentificacaoSituacao';

import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import Input from 'components/PDV/Input';
import { InputNumber } from 'components/PDV/InputNumber';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import InputDateRange from 'components/PDV/InputDateRange';
import DrawerPadraoChakra from 'components/PDV/Drawer/DrawerPadraoChakra';
import { LupaIcon } from 'icons';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { SelectMulti } from 'components/Select/SelectMultiCheckbox';

import { FormData, formDefaultValues } from '../validationForms';

type DrawerBuscaAvancadaProps = {
  onClose: () => void;
  isOpen: boolean;
  handleSubmitBuscaAVancada: (data: FormData) => void;
};

type ListarSelectSituacaoPedido = {
  id: string;
  nome: string;
};

type IntermediadorProps = {
  id: string;
  descricao: string;
  cnpj: string;
};

type SituacaoPedido = {
  label: string;
  value: string;
};

type OrigemProps = {
  label: string;
  value: string;
};

export const DrawerBuscaAvancada = ({
  onClose,
  isOpen,
  handleSubmitBuscaAVancada,
}: DrawerBuscaAvancadaProps) => {
  const [isLargerThan1000] = useMediaQuery('(min-width: 1000px)');
  const [listSituacaoPedido, setListSituacaoPedido] = useState<
    SituacaoPedido[]
  >([]);
  const [listOrigem, setListOrigem] = useState<OrigemProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit: onSubmit, reset } = useFormContext<FormData>();

  const getSituacaoPedido = useCallback(async () => {
    const response = await api.get<
      void,
      ResponseApi<ListarSelectSituacaoPedido[]>
    >(ConstanteEnderecoWebservice.INTEGRACAO_TRAY_OPERACOES_VENDA, {
      params: {
        integracao: enumIdentificacaoIntegracao.TRAY,
      },
    });
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((avisos) => toast.warning(avisos));
      }

      if (response.sucesso) {
        setListSituacaoPedido(
          response.dados.map((item) => ({
            label: item.nome,
            value: item.id,
          }))
        );
      }
    }
  }, []);

  const getOrigem = useCallback(async () => {
    const response = await api.get<void, ResponseApi<IntermediadorProps[]>>(
      ConstanteEnderecoWebservice.INTEGRACAO_TRAY_ORIGEM,
      {
        params: {
          integracao: enumIdentificacaoIntegracao.TRAY,
        },
      }
    );
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((avisos) => toast.warning(avisos));
      }

      if (response.sucesso) {
        setListOrigem(
          response.dados.map((item) => ({
            label: item.descricao,
            value: item.id,
          }))
        );
      }
    }
  }, []);

  const handleSubmit = onSubmit((data: FormData) => {
    setIsLoading(true);
    const filteredData = Object.fromEntries(
      Object.entries(data).filter(([, value]) => value !== undefined)
    ) as FormData;

    const valueDataEmissao = {
      dataEmissaoFim: filteredData?.dataEmissaoFilterFim || new Date(),
      dataEmissaoInicio: filteredData?.dataEmissaoFilterInicio || new Date(),
    };

    handleSubmitBuscaAVancada({
      ...filteredData,
      ...valueDataEmissao,
      numeroPedido: !data.numeroPedido ? '0' : data.numeroPedido,
      numeroVenda: !data.numeroVenda ? '0' : data.numeroVenda,
    });
    onClose();
    setIsLoading(false);
  });

  const handleLimparFiltros = () => {
    reset(formDefaultValues);
    handleSubmitBuscaAVancada(formDefaultValues);
    onClose();
  };

  useEffect(() => {
    getSituacaoPedido();
  }, [getSituacaoPedido]);

  useEffect(() => {
    getOrigem();
  }, [getOrigem]);

  return (
    <DrawerPadraoChakra
      isOverlay={false}
      size={isLargerThan1000 ? ' lg' : 'full'}
      onClose={onClose}
      isOpen={isOpen}
    >
      {isLoading && <LoadingPadrao />}
      <DrawerContent
        style={{
          width: isLargerThan1000 ? '620px' : '',
        }}
        bg="gray.50"
        overflow="hidden"
        px="24px"
        boxShadow="-6px 0px 20px #00000029"
      >
        <DrawerCloseButton fontSize="8px" color="violet.500" />
        <DrawerHeader
          px="0"
          borderBottomWidth="1px"
          fontSize="16px"
          color="violet.500"
        >
          Busca avançada
        </DrawerHeader>

        <DrawerBody overflow="hidden" mt="28px" px="2px">
          <SimpleGridForm>
            <GridItem colSpan={12}>
              <Input
                id="cliente"
                name="cliente"
                placeholder="Digite o nome do cliente"
                label="Nome do cliente"
                border="1px solid"
                borderColor="gray.100"
                _placeholder={{ color: 'gray.200' }}
              />
            </GridItem>
            <GridItem colSpan={[12, 12, 6]}>
              <InputNumber
                id="numeroPedido"
                _placeholder={{ color: 'gray.200' }}
                name="numeroPedido"
                label="Número do pedido no site"
                placeholder="Digite o código da venda no site"
                scale={0}
                type="number"
              />
            </GridItem>
            <GridItem colSpan={[12, 12, 6]}>
              <SelectMulti
                name="integracaoSituacaoPedidoId"
                textLabelSelectAll="Todas as vendas"
                id="integracaoSituacaoPedidoId"
                placeholder="Escolha "
                variant="outline"
                options={listSituacaoPedido}
                label="Situação do pedido no site"
                isMulti
                isSearchable={false}
                closeMenuOnSelect={false}
              />
            </GridItem>
            <GridItem colSpan={[12, 12, 6]}>
              <InputNumber
                id="numeroVenda"
                _placeholder={{ color: 'gray.200' }}
                name="numeroVenda"
                label="Número da operação no sistema"
                placeholder="Digite o código da operação"
                scale={0}
                type="number"
              />
            </GridItem>
            <GridItem colSpan={[12, 12, 6]}>
              <SelectPadrao
                label="Situação da operação no sistema"
                id="situacaoVinculacao"
                placeholder="Selecione a situação"
                name="situacaoVinculacao"
                options={situacaoOperacaoSistema.options}
              />
            </GridItem>
            <GridItem colSpan={[12, 12, 6]}>
              <FormLabel mb="0" fontSize="sm" color="black" lineHeight="1.2">
                Data ou período
              </FormLabel>
              <InputDateRange
                borderColor="gray.100"
                borderRadius="md"
                name="data"
                onConfirm={() => {}}
                startDateName="dataEmissaoFilterInicio"
                endDateName="dataEmissaoFilterFim"
              />
            </GridItem>
            <GridItem colSpan={[12, 12, 6]}>
              <SelectPadrao
                label="Origem"
                id="origemId"
                placeholder="Selecione a origem"
                name="origemId"
                options={listOrigem}
              />
            </GridItem>
          </SimpleGridForm>
          <Flex
            mt="40px"
            w="full"
            h="full"
            justifyContent="center"
            alignItems="baseline"
          >
            <Button
              variant="outlineDefault"
              leftIcon={<Icon as={AiOutlineCloseCircle} />}
              borderRadius="16px"
              colorScheme="gray"
              mr="24px"
              onClick={() => handleLimparFiltros()}
              w="150px"
            >
              Limpar filtros
            </Button>
            <Button
              color="black"
              leftIcon={<Icon as={LupaIcon} />}
              variant="solid"
              colorScheme="secondary"
              borderRadius="16px"
              width="132px"
              onClick={handleSubmit}
              isLoading={isLoading}
            >
              Pesquisar
            </Button>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </DrawerPadraoChakra>
  );
};
