const TelaInicialAposLogin = {
  PAGINA_INICIAL: false,
  PDV: true,

  properties: {
    1: { name: 'Página inicial', value: false },
    2: { name: 'PDV', value: true },
  },
};

export default TelaInicialAposLogin;
