import React, { useState, useEffect, useRef } from 'react';
import {
  ModalContent,
  ModalBody,
  Icon,
  Flex,
  ModalCloseButton,
  useMediaQuery,
  useDisclosure,
} from '@chakra-ui/react';
import { create } from 'react-modal-promise';

import obterOperacaoParaCompartilhar, {
  OperacaoObterParaCompartilhar,
} from 'helpers/api/Operacao/obterOperacaoParaCompartilhar';

import { CompartilharMenuIcon } from 'icons';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import CompartilharDocumentosFiscais, {
  PropsImpressao,
} from 'components/PDV/CompartilharDocumentosFiscais';
import { MobileModalMenuHeader } from 'components/PDV/Layout/MobileModalMenu/MobileModalMenuHeader';

import FiltroDadosCompartilhados from './compartilharDados';
import { ModalCompartilharProps } from './types/validationsTypes';

const ModalCompartilharComponent = ({
  operacaoId,
  sharedPerDepartment,
  movimentacaoFinanceiraId,
  movimentacaoCaixaId,
  sharedWhatsApp,
  mostrarCompartilharPorXml = false,
  mostrarCompartilharPorEmail = false,
  mostrarCompartilharPorImpressao = false,
  modeloNotaFiscal,
  notaFiscalStatus,
  isDevolucao,
  tipoOperacao,
  hasOperacao = false,
  possuiCartaCorrecao = false,
  fecharModal = true,
  closeOnOverlayClick = true,
  usuarioPodeExportarArquivoCSV,
  ...rest
}: ModalCompartilharProps) => {
  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const [isLoading, setIsLoading] = useState(false);
  const [operacao, setOperacao] = useState<OperacaoObterParaCompartilhar>();

  const latestProps = useRef({ operacaoId });
  useEffect(() => {
    latestProps.current = { operacaoId };
  });

  useEffect(() => {
    async function handleObterOperacaoParaCompartilhar() {
      setIsLoading(true);
      if (operacaoId) {
        const newOperacao = await obterOperacaoParaCompartilhar(
          latestProps.current.operacaoId
        );
        setOperacao(newOperacao);
      }

      setIsLoading(false);
    }

    handleObterOperacaoParaCompartilhar();
  }, [operacaoId]);

  const handleFunctionsImpressoesRef = useRef<PropsImpressao>(null);

  const handleDadosCompartilhadosContext = FiltroDadosCompartilhados({
    sharedPerDepartment,
    handleFunctionsImpressoesRef,
    setIsLoading,
    isDevolucao,
    hasOperacao,
    notaFiscalStatus,
    possuiCartaCorrecao,
    modeloNotaFiscal,
    onClose,
    fecharModal,
  });

  return (
    <ModalPadraoChakra
      isCentered
      closeOnOverlayClick={closeOnOverlayClick}
      size={!isSmallerThan900 ? 'xl' : 'full'}
      {...rest}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalContent
        marginBottom={{ base: 0, md: '3.75rem' }}
        marginTop={{ base: 0, md: '3.75rem' }}
        h="unset"
        maxW={{ base: '100%', md: '700px' }}
        borderRadius={{ base: 'none', md: 'md' }}
      >
        {isSmallerThan900 ? (
          <MobileModalMenuHeader
            voltarIconColor="black"
            color="primary.50"
            onClose={onClose}
            title="Compartilhar"
          />
        ) : (
          <ModalCloseButton color="white" />
        )}
        <ModalBody
          p={0}
          bg={isSmallerThan900 ? 'primary.50' : 'white'}
          borderRadius={{ base: 'none', md: 'md' }}
        >
          {!isSmallerThan900 && (
            <Flex
              alignItems="center"
              justifyContent="center"
              bg="primary.50"
              h="135px"
              borderTopRadius="md"
            >
              <Icon as={CompartilharMenuIcon} boxSize="50px" color="white" />
            </Flex>
          )}
          <Flex flexDirection="column" h="full">
            <CompartilharDocumentosFiscais
              ref={handleFunctionsImpressoesRef}
              asAccordion={isSmallerThan900}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              onClose={onClose}
              {...(isSmallerThan900
                ? { px: '0', bg: 'primary.50', pt: 0 }
                : {})}
              movimentacaoFinanceiraId={movimentacaoFinanceiraId}
              caixaMovimentacaoId={movimentacaoCaixaId}
              sharedPerDepartment={handleDadosCompartilhadosContext}
              sharedWhatsApp={sharedWhatsApp}
              mostrarCompartilharPorXml={mostrarCompartilharPorXml}
              mostrarCompartilharPorEmail={mostrarCompartilharPorEmail}
              mostrarCompartilharPorImpressao={mostrarCompartilharPorImpressao}
              usuarioPodeExportarArquivoCSV={usuarioPodeExportarArquivoCSV}
              modeloNotaFiscal={modeloNotaFiscal}
              notaFiscalStatus={notaFiscalStatus}
              tipoOperacao={tipoOperacao}
              operacao={
                operacao && operacaoId
                  ? {
                      id: operacaoId,
                      numeroOperacao: operacao.numeroOperacao,
                      cliente: {
                        nome: operacao.cliente.nome,
                        email: operacao.cliente.email,
                        celular: operacao.cliente.celular,
                        telefone: operacao.cliente.telefone,
                        contatosAdicionais: operacao.cliente.contatosAdicionais,
                      },
                      NFCe: operacao.NFCe
                        ? {
                            id: operacao.NFCe.id,
                            numero: operacao.NFCe.numero,
                            status: operacao.NFCe.status,
                          }
                        : undefined,
                      NFe: operacao.NFe
                        ? {
                            id: operacao.NFe.id,
                            numero: operacao.NFe.numero,
                            status: operacao.NFe.status,
                          }
                        : undefined,
                    }
                  : undefined
              }
            />
          </Flex>
        </ModalBody>
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export const ModalCompartilhar = create<ModalCompartilharProps>(
  ModalCompartilharComponent
);
