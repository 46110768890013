import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Stack,
  Icon,
  HStack,
  IconButton,
  Text,
  Flex,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  useMediaQuery,
} from '@chakra-ui/react';
import {
  useForm,
  FormProvider,
  useFieldArray,
  Control,
  SubmitErrorHandler,
} from 'react-hook-form';
import { toast } from 'react-toastify';
import { AutoSizer, List } from 'react-virtualized';
import { FiAlertCircle, FiEdit2 } from 'react-icons/fi';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { useEntradaMercadoriaDadosCadastroContext } from 'store/EntradaMercadoria/EntradaMercadoriaDadosCadastro';
import { useEntradaMercadoriaEtapasContext } from 'store/EntradaMercadoria/EntradaMercadoriaEtapas';
import { checkIsGuidEmpty } from 'helpers/validation/checkIsGuidEmpty';
import { getValorParcelas } from 'helpers/data/getValorParcelas';

import { LimparIcon, SalvarInserirNovoIcon } from 'icons';
import { NumberInput } from 'components/update/Input/NumberInput';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { DateInput } from 'components/update/Input/DateInput';
import {
  Container,
  Body,
  Footer,
  StepDescriptionAccordion,
} from 'components/update/Steps/StepContent';
import { SimpleCard } from 'components/update/Form/SimpleCard';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { TextValor } from 'components/PDV/Text/TextValor';

import { AdicionarPagamentoModal } from './AdicionarPagamentoModal';
import { AlterarFormaPagamentoModal } from './AlterarFormaPagamentoModal';
import { FormData, Parcela, yupResolver } from './validationForm';

type ObterPagamentoResponse = {
  entradaXml: boolean;
  valorTotal: number;
  valorTotalAdicionado: number;
  parcelas: Parcela[];
};

type FormaPagamentoResponse = {
  id: string;
  nome: string;
};

type FormaPagamentoOption = {
  value: string;
  label: string;
};

interface LancamentoPagamentosProps {
  isImportacao?: boolean;
}

export function LancamentoPagamentos({
  isImportacao = false,
}: LancamentoPagamentosProps) {
  const { nextStep, previousStep } = useEntradaMercadoriaEtapasContext();
  const {
    descartarEntradaMercadoria,
    entradaMercadoriaId,
    voltarParaListagem,
    temPermissaoExcluir,
    isReadOnly,
    IsCadastroExterno,
    menuIsOpen,
    statusLancamentos: { foiLancadoFinanceiro },
  } = useEntradaMercadoriaDadosCadastroContext();

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const formMethods = useForm<FormData>({ resolver: yupResolver });

  const { fields, append, update } = useFieldArray<FormData, 'parcelas'>({
    control: formMethods.control as Control<any>,
    name: 'parcelas',
  });

  const parcelas = formMethods.watch('parcelas');

  const [isLoading, setIsLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const [isFormasPagamentoLoading, setIsFormasPagamentoLoading] = useState(
    false
  );
  const [formasPagamentoOptions, setFormasPagamentoOptions] = useState<
    FormaPagamentoOption[]
  >([]);

  const [firstElementIsVisible, setFirstElementIsVisible] = useState(true);
  const [lastElementIsVisible, setLastElementIsVisible] = useState(true);

  function handleVoltar() {
    previousStep();
  }

  function handleDescartarEntradaMercadoria() {
    descartarEntradaMercadoria();
  }

  async function adicionarPagamentos(data: FormData) {
    const temPagamentosNaoSalvos = parcelas.some((parcela) => !parcela.id);

    if (!isDirty || (!temPagamentosNaoSalvos && parcelas.length > 0)) {
      return true;
    }

    if (entradaMercadoriaId) {
      setIsLoading(true);

      const response = await api.post<void, ResponseApi>(
        ConstanteEnderecoWebservice.ENTRADA_MERCADORIA_ADICIONAR_PAGAMENTOS,
        {
          id: entradaMercadoriaId,
          pagamentos: data.parcelas.map((parcela) => ({
            dataVencimento: new Date(parcela.dataVencimento).toISOString(),
            numeroParcela: parcela.numeroParcela,
            formaPagamentoRecebimentoId: parcela.formaPagamento,
            valor: parcela.valor,
          })),
        }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.map((aviso: string) => toast.warning(aviso));
        }

        if (response.sucesso) {
          setIsLoading(false);

          return true;
        }
      }

      setIsLoading(false);
    }

    return false;
  }

  const tratarErros: SubmitErrorHandler<FormData> = (error) => {
    if (error.parcelas) {
      const parcela = error.parcelas.find((p) => p.formaPagamento);

      if (parcela) {
        toast.warning(
          'Selecione uma forma de pagamento válida para todas as parcelas'
        );
      }
    }
  };

  const handleSalvarRascunho =
    foiLancadoFinanceiro || isReadOnly
      ? () => nextStep()
      : formMethods.handleSubmit(async (data) => {
          await adicionarPagamentos(data);

          voltarParaListagem();
        }, tratarErros);

  const handleAvancar =
    foiLancadoFinanceiro || isReadOnly
      ? () => nextStep()
      : formMethods.handleSubmit(async (data) => {
          const sucesso = await adicionarPagamentos(data);

          if (sucesso) {
            nextStep();
          }
        }, tratarErros);

  function obterLabelFormaPagamento(value: string) {
    const formaPagamentoOption = formasPagamentoOptions.find(
      (option) => option.value === value
    );

    return formaPagamentoOption?.label || '';
  }

  async function handleAlterarFormaPagamento(index: number) {
    const parcela = parcelas[index];

    const {
      formaPagamentoId: novaFormaPagamento,
    } = await AlterarFormaPagamentoModal({
      formasPagamentoOptions,
      defaultFormaPagamentoId: parcela.formaPagamento,
      isImportacao,
    });

    const todasParcelasEstaoSemFormaPagamento = parcelas.every(
      (p) => !p.formaPagamento
    );

    if (todasParcelasEstaoSemFormaPagamento) {
      parcelas.forEach((p, pIndex) => {
        update(pIndex, { ...p, formaPagamento: novaFormaPagamento });
      });
    } else {
      update(index, { ...parcela, formaPagamento: novaFormaPagamento });
    }
  }

  const latestProps = useRef({
    reset: formMethods.reset,
    getValues: formMethods.getValues,
    voltarParaListagem,
  });
  useEffect(() => {
    latestProps.current = {
      reset: formMethods.reset,
      getValues: formMethods.getValues,
      voltarParaListagem,
    };
  });

  function getValorDiferenca() {
    const valorTotal = latestProps.current.getValues('valorTotal');

    const valorTotalAdicionado = (parcelas || []).reduce(
      (acc, curr) => acc + curr.valor,
      0
    );

    const fixedValorTotal = (valorTotal || 0).toFixed(2);
    const fixedValorTotalAdicionar = valorTotalAdicionado.toFixed(2);

    return parseFloat(fixedValorTotal) - parseFloat(fixedValorTotalAdicionar);
  }

  const valorDiferenca = getValorDiferenca();

  async function handleAdicionarPagamento() {
    const { formaPagamentoId, numeroParcelas } = await AdicionarPagamentoModal({
      formasPagamentoOptions,
    });
    const valorParcelas = getValorParcelas(valorDiferenca, numeroParcelas);

    Array.from(Array(numeroParcelas).keys()).forEach((_, index) => {
      const dateObj = new Date();
      let month = dateObj.getMonth() + index;
      month = month <= 11 ? month : month - 12;

      append({
        numeroParcela: index + 1,
        formaPagamento: formaPagamentoId,
        dataVencimento: new Date(
          dateObj.getFullYear(),
          month,
          dateObj.getDate(),
          0,
          0,
          0
        ),
        valor: valorParcelas[index],
      });
    });
  }

  async function handleLimparParcelas() {
    if ((parcelas || []).length === 0) {
      return;
    }

    await ModalConfirmacaoExcluir({
      title: 'Limpar pagamentos',
      text: 'Todos os pagamentos serão removidos. Deseja continuar?',
      confirmButtonText: 'Sim, continuar!',
      callback: async (ok: boolean) => {
        if (ok) {
          const valorTotal = formMethods.getValues('valorTotal');

          if ((parcelas || []).length > 0) {
            setIsDirty(true);
          }

          formMethods.reset({ valorTotal, parcelas: [] }, { keepDirty: true });
        }
      },
    });
  }

  useEffect(() => {
    async function obterPagamentos() {
      if (entradaMercadoriaId) {
        setIsLoading(true);

        const response = await api.get<
          void,
          ResponseApi<ObterPagamentoResponse>
        >(ConstanteEnderecoWebservice.ENTRADA_MERCADORIA_OBTER_PAGAMENTO, {
          params: {
            id: entradaMercadoriaId,
          },
        });

        if (response) {
          if (response.avisos) {
            response.avisos.map((aviso: string) => toast.warning(aviso));
          }

          if (response.sucesso && response.dados) {
            latestProps.current.reset({
              valorTotal: +response.dados.valorTotal.toFixed(2),
              parcelas: response.dados.parcelas || [],
            });
          } else {
            latestProps.current.voltarParaListagem();
          }
        }

        setIsLoading(false);
      }
    }

    obterPagamentos();
  }, [entradaMercadoriaId]);

  useEffect(() => {
    async function loadFormasPagamentoOptions() {
      setIsFormasPagamentoLoading(true);

      const response = await api.get<
        void,
        ResponseApi<FormaPagamentoResponse[]>
      >(ConstanteEnderecoWebservice.FORMA_PAGAMENTO_LISTAR_SELECT_PAGAMENTO);

      if (response) {
        if (response.avisos) {
          response.avisos.map((aviso: string) => toast.warning(aviso));
        }

        if (response.sucesso && response.dados) {
          setFormasPagamentoOptions(
            response.dados.map((dado) => ({
              value: dado.id,
              label: dado.nome,
            }))
          );
        }
      }

      setIsFormasPagamentoLoading(false);
    }

    loadFormasPagamentoOptions();
  }, []);

  useEffect(() => {
    if (formMethods.formState.isDirty) {
      setIsDirty(true);
    }
  }, [formMethods.formState.isDirty]);

  return (
    <>
      {isLoading && <LoadingPadrao />}

      <Container mt="5">
        <StepDescriptionAccordion
          stepNumber={4}
          title="Forma de pagamento"
          description={
            isImportacao
              ? 'Informe como será feito o pagamento: Data de vencimento, forma e valor de cada parcela. Para adicionar uma nova forma de pagamento ou para alterar a quantidade de parcelas informada na nota fiscal clique em “adicionar pagamento”.'
              : 'Informe como será feito o pagamento: Data de vencimento, forma e valor de cada parcela. Após gerar as parcelas é possível alterar a data de vencimento e o valor de cada uma separadamente.'
          }
        />

        <FormProvider {...formMethods}>
          <Body>
            <SimpleCard
              boxShadow="none"
              borderRadius="md"
              bg="gray.50"
              border="1px"
              borderColor="gray.100"
            >
              <HStack
                spacing="6"
                justifyContent={
                  foiLancadoFinanceiro || isReadOnly
                    ? 'flex-end'
                    : 'space-between'
                }
                mb={{ base: 4, sm: 6, md: 8 }}
              >
                {!foiLancadoFinanceiro && !isReadOnly && (
                  <Button
                    size="sm"
                    borderRadius="md"
                    colorScheme="secondary"
                    leftIcon={<Icon as={SalvarInserirNovoIcon} fontSize="sm" />}
                    minW="194px"
                    onClick={handleAdicionarPagamento}
                    isDisabled={isFormasPagamentoLoading || fields.length > 0}
                  >
                    Adicionar pagamento
                  </Button>
                )}

                <HStack spacing="6">
                  <Flex alignItems="center">
                    <Text
                      whiteSpace="nowrap"
                      fontSize="sm"
                      fontWeight="semibold"
                      lineHeight="none"
                      mr="2"
                    >
                      Valor total:
                    </Text>
                    <NumberInput
                      id="valorTotal"
                      name="valorTotal"
                      leftElement="R$"
                      placeholder={`0,${'0'.repeat(2)}`}
                      scale={2}
                      isDisabled
                    />
                  </Flex>

                  {!foiLancadoFinanceiro && !isReadOnly && (
                    <IconButton
                      aria-label="Limpar pagamentos"
                      icon={<Icon as={LimparIcon} fontSize="xl" />}
                      onClick={handleLimparParcelas}
                      border="1px"
                      borderColor="gray.200"
                      borderRadius="md"
                      bg="white"
                      _hover={{ bg: 'gray.100' }}
                      _active={{ bg: 'gray.100' }}
                    />
                  )}
                </HStack>
              </HStack>

              <Box
                border="1px"
                borderColor="gray.100"
                borderRadius="md"
                overflow="hidden"
                sx={{
                  '& table > thead > tr > th': {
                    bg: 'gray.100',
                  },
                  '& .ReactVirtualized__List': {
                    scrollbarWidth: 'none',
                    '-ms-overflow-style': 'none',
                    '&::-webkit-scrollbar': {
                      display: 'none',
                    },
                  },
                }}
              >
                <Table variant="simple" size="sm">
                  <Thead>
                    <Tr>
                      <Th w="20%">Vencimento</Th>
                      <Th w="20%">Parcela</Th>
                      <Th w="30%">Forma de pagamento</Th>
                      <Th w="30%" isNumeric>
                        Valor
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody
                    h={
                      // eslint-disable-next-line no-nested-ternary
                      fields.length > 0
                        ? fields.length < 5
                          ? `${47 * fields.length}px`
                          : '235px'
                        : undefined
                    }
                  >
                    <Box
                      position="absolute"
                      h="20px"
                      w="full"
                      bgGradient="linear(to-b, gray.100 0%, rgba(0, 0, 0, 0) 100%)"
                      pointerEvents="none"
                      zIndex="overlay"
                      transition="opacity 0.4s"
                      opacity={firstElementIsVisible ? 0 : 1}
                    />

                    <Box
                      position="absolute"
                      bottom={0}
                      left={0}
                      h="30px"
                      w="full"
                      bgGradient="linear(to-t, gray.100 0%, rgba(0, 0, 0, 0) 100%)"
                      pointerEvents="none"
                      zIndex="overlay"
                      transition="opacity 0.4s"
                      opacity={lastElementIsVisible ? 0 : 1}
                    />

                    {fields.length === 0 ? (
                      <Tr>
                        <Td h="47px" colSpan={9999} border="none">
                          Nenhuma forma de pagamento informada
                        </Td>
                      </Tr>
                    ) : (
                      <AutoSizer>
                        {({ width }) => (
                          <List
                            width={width}
                            height={235}
                            rowCount={parcelas.length}
                            rowHeight={47}
                            rowRenderer={({ index, key, style }) => {
                              const parcela = (parcelas || [])[index];

                              if (!parcela) {
                                return null;
                              }

                              const isParcelaSalva = !checkIsGuidEmpty(
                                parcela.id
                              );

                              return (
                                <Table
                                  key={key}
                                  style={style}
                                  variant="simple"
                                  size="sm"
                                >
                                  <Tbody>
                                    <Tr>
                                      <Td w="20%">
                                        <DateInput
                                          id={`parcelas.${index}.dataVencimento`}
                                          name={`parcelas.${index}.dataVencimento`}
                                          size="sm"
                                          isDisabled={
                                            isParcelaSalva ||
                                            foiLancadoFinanceiro ||
                                            isReadOnly
                                          }
                                        />
                                      </Td>
                                      <Td w="20%">{`Parcela ${String(
                                        parcela.numeroParcela
                                      ).padStart(2, '0')}`}</Td>
                                      <Td
                                        w="30%"
                                        sx={{
                                          '& .chakra-form-control': {
                                            maxW: '240px',
                                          },
                                        }}
                                      >
                                        {(isParcelaSalva ||
                                          foiLancadoFinanceiro ||
                                          isReadOnly) &&
                                        parcela.formaPagamento ? (
                                          <>{parcela.formaPagamento}</>
                                        ) : (
                                          <Button
                                            rightIcon={
                                              <Icon
                                                as={FiEdit2}
                                                fontSize="md"
                                                ml="1"
                                              />
                                            }
                                            onClick={() =>
                                              handleAlterarFormaPagamento(index)
                                            }
                                            border="1px"
                                            borderColor="gray.200"
                                            borderRadius="md"
                                            bg="white"
                                            _hover={{ bg: 'gray.100' }}
                                            _active={{ bg: 'gray.100' }}
                                            size="sm"
                                            fontSize="xs"
                                          >
                                            {parcela.formaPagamento
                                              ? obterLabelFormaPagamento(
                                                  parcela.formaPagamento
                                                )
                                              : 'Clique para selecionar'}
                                          </Button>
                                        )}
                                      </Td>
                                      <Td
                                        w="30%"
                                        isNumeric
                                        sx={{
                                          '& .chakra-form-control': {
                                            maxW: '160px',
                                            ml: 'auto',
                                          },
                                        }}
                                      >
                                        <NumberInput
                                          id={`parcelas.${index}.valor`}
                                          name={`parcelas.${index}.valor`}
                                          defaultValue={parcela.valor}
                                          leftElement="R$"
                                          placeholder={`0,${'0'.repeat(2)}`}
                                          scale={2}
                                          size="sm"
                                          isDisabled={
                                            fields.length === 1 ||
                                            isParcelaSalva ||
                                            foiLancadoFinanceiro ||
                                            isReadOnly
                                          }
                                        />
                                      </Td>
                                    </Tr>
                                  </Tbody>
                                </Table>
                              );
                            }}
                            onRowsRendered={(props) => {
                              if (props.startIndex === 0) {
                                setFirstElementIsVisible(true);
                              } else {
                                setFirstElementIsVisible(false);
                              }

                              if (props.stopIndex === fields.length - 1) {
                                setLastElementIsVisible(true);
                              } else {
                                setLastElementIsVisible(false);
                              }
                            }}
                          />
                        )}
                      </AutoSizer>
                    )}
                  </Tbody>
                </Table>
              </Box>
              {valorDiferenca !== 0 && parcelas.length > 0 && (
                <Flex
                  alignItems="center"
                  justifyContent="flex-end"
                  color="red.500"
                  mt="2.5"
                >
                  <Icon as={FiAlertCircle} fontSize="xl" />

                  <Text ml="1.5" fontSize="xs" fontWeight="semibold">
                    A soma das parcelas está diferente do valor total
                  </Text>

                  <Flex
                    alignItems="center"
                    justifyContent="flex-end"
                    ml="2.5"
                    h="7"
                    border="1px"
                    borderColor="red.500"
                    borderRadius="md"
                    minW="80px"
                    px="1.5"
                  >
                    <TextValor
                      valor={valorDiferenca}
                      color="red.500"
                      fontSize="sm"
                      fontWeight="semibold"
                    />
                  </Flex>
                </Flex>
              )}
            </SimpleCard>
          </Body>
        </FormProvider>
      </Container>
      <Footer
        justifyContent={isReadOnly ? 'space-between' : 'flex-end'}
        position={isLargerThan900 ? 'fixed' : 'relative'}
        bottom="0px"
        bg="gray.50"
        borderTop={isLargerThan900 ? '1px solid' : 'none'}
        borderColor="#5502B2"
        w={`calc(100% - ${menuIsOpen ? '210px' : '108px'})`}
        py="16px"
        px="48px"
      >
        <Button
          variant="outlineDefault"
          borderRadius="full"
          w="full"
          maxW={{ base: 'full', md: '160px' }}
          onClick={handleVoltar}
        >
          Voltar
        </Button>

        <Stack
          w="full"
          justifyContent="flex-end"
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: 2, sm: 4, md: 6 }}
        >
          {!IsCadastroExterno && (
            <>
              {isReadOnly ? (
                <Button
                  variant="outlineDefault"
                  borderRadius="full"
                  w="full"
                  maxW={{ base: 'full', md: '196px' }}
                  onClick={voltarParaListagem}
                >
                  Voltar para a listagem
                </Button>
              ) : (
                <Button
                  variant="outlineDefault"
                  borderRadius="full"
                  w="full"
                  maxW={{ base: 'full', md: '160px' }}
                  onClick={handleDescartarEntradaMercadoria}
                  isDisabled={!temPermissaoExcluir}
                >
                  Descartar
                </Button>
              )}
            </>
          )}

          {!isReadOnly && (
            <Button
              variant="outlineDefault"
              borderRadius="full"
              w="full"
              maxW={{ base: 'full', md: '160px' }}
              onClick={handleSalvarRascunho}
            >
              Salvar e sair
            </Button>
          )}

          <Button
            colorScheme="purple"
            borderRadius="full"
            w="full"
            maxW={{ base: 'full', md: '160px' }}
            onClick={handleAvancar}
            isDisabled={valorDiferenca !== 0 && fields.length > 0}
          >
            Avançar
          </Button>
        </Stack>
      </Footer>
    </>
  );
}
