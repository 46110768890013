import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

interface OperacaoAlterar {
  id: string;
  conteudoCampo: string;
  campo: number;
  tela: number;
}

const putOperacao = async (operacaoAlterar: OperacaoAlterar) => {
  const response = await api.put<void, ResponseApi>(
    ConstanteEnderecoWebservice.PEDIDOORCAMENTOVENDA_ALTERAR,
    operacaoAlterar
  );

  if (response?.avisos) {
    response.avisos.forEach((item: string) => toast.warning(item));
  }

  if (response?.sucesso) {
    return true;
  }

  return false;
};

export default putOperacao;
