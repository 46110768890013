import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Flex,
  useMediaQuery,
  Icon,
  Text,
  ModalHeader,
  ModalFooter,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { ErroAvisoIcon } from 'icons';

type ResponseModal = {
  success: boolean;
};
type ModalInativarVariacaoProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ResponseModal>;
export const ModalInativarVariacao = create<ModalInativarVariacaoProps>(
  ({ onResolve, onReject, ...rest }) => {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
    const handleConfirm = () => {
      onResolve({ success: true });
      onClose();
    };
    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? 'xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={() => {
          onReject({ success: false });
          onClose();
        }}
        autoFocus={false}
      >
        <ModalContent
          bg="gray.50"
          w={['full', 'full', '550px']}
          h={['full', 'full', '384px']}
          pb="32px"
        >
          <ModalHeader padding="0px">
            <Flex
              height="128px"
              bg="red.500"
              align="center"
              justify="center"
              borderTopRadius="5px"
            >
              <Icon
                as={ErroAvisoIcon}
                fontSize="60px"
                color="white"
                sx={{
                  strokeWidth: '4px !important',
                }}
              />
            </Flex>
          </ModalHeader>
          <ModalBody
            justifyContent="center"
            alignItems="center"
            h="full"
            w="full"
            px="34px"
            py="0px"
          >
            <Text
              fontSize="20px"
              fontWeight="semibold"
              color="red.500"
              mt="32px"
            >
              Não é possível excluir a variação
            </Text>
            <Text
              mt="16px"
              fontSize="14px"
              fontWeight="500"
              letterSpacing="0px"
              color="gray.700"
            >
              Não é possível excluir uma variação de produto que já foi
              utilizada em alguma operação de venda no sistema. Se preferir, é
              possível inativar para que ela não seja mais exibida. Deseja
              inativar?
            </Text>
          </ModalBody>
          <ModalFooter justifyContent="center" display="flex" padding="0px">
            <Button
              w="96px"
              mr="24px"
              colorScheme="gray"
              variant="outlineDefault"
              borderRadius="full"
              onClick={() => {
                onReject({ success: false });
                onClose();
              }}
              height="32px"
              fontWeight="500"
              fontSize="14px"
            >
              Cancelar
            </Button>
            <Button
              w="160px"
              colorScheme="red"
              variant="solid"
              borderRadius="full"
              fontWeight="500"
              fontSize="14px"
              height="32px"
              onClick={handleConfirm}
            >
              Inativar variação
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
