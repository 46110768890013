import React from 'react';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

import VendedoresListar from '../pages/Vendedores/Listar';
import VendedoresCadastrar from '../pages/Vendedores/Formulario/Cadastrar';
import VendedoresAlterar from '../pages/Vendedores/Formulario/Alterar';
import VendedoresVisualizar from '../pages/Vendedores/Formulario/Visualizar';

const VendedoresRoutes = [
  <LayoutGuard
    key="vendedores-listar"
    permissaoFuncionalidade={ConstanteFuncionalidades.VENDEDOR_LISTAR}
    component={VendedoresListar}
    breadcrumb={[
      {
        title: 'Cadastros',
      },
      {
        title: 'Vendedores',
      },
    ]}
    path={ConstanteRotas.VENDEDOR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="vendedores-cadastrar"
    permissaoFuncionalidade={ConstanteFuncionalidades.VENDEDOR_CADASTRAR}
    component={VendedoresCadastrar}
    breadcrumb={[
      {
        title: 'Cadastros',
      },
      {
        title: 'Vendedores',
        path: ConstanteRotas.VENDEDOR,
      },
      {
        title: 'Novo',
      },
    ]}
    path={ConstanteRotas.VENDEDOR_CADASTRAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="vendedores-alterar"
    permissaoFuncionalidade={ConstanteFuncionalidades.VENDEDOR_ALTERAR}
    component={VendedoresAlterar}
    breadcrumb={[
      {
        title: 'Cadastros',
      },
      {
        title: 'Vendedores',
        path: ConstanteRotas.VENDEDOR,
      },
      {
        title: 'Editar',
      },
    ]}
    path={ConstanteRotas.VENDEDOR_ALTERAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="vendedores-visualizar"
    permissaoFuncionalidade={ConstanteFuncionalidades.VENDEDOR_VISUALIZAR}
    component={VendedoresVisualizar}
    breadcrumb={[
      {
        title: 'Cadastros',
      },
      {
        title: 'Vendedores',
        path: ConstanteRotas.VENDEDOR,
      },
      {
        title: 'Visualizar',
      },
    ]}
    path={ConstanteRotas.VENDEDOR_VISUALIZAR}
    exact
    meta={{ auth: true }}
  />,
];

export default VendedoresRoutes;
