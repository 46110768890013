import { useCallback, useState } from 'react';
import {
  ModalContent,
  ModalBody,
  Button,
  Flex,
  ModalFooter,
  Box,
  useMediaQuery,
  keyframes,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { useEmissaoNotaFiscalContext } from 'store/NotaFiscal/EmissaoNotaFiscal';
import auth from 'modules/auth';
import { useSignalRContext, NotaFiscalEmitindo } from 'store/SignalR';
import ModalidadesFreteEnum from 'constants/enum/fiscal/modalidadeFrete';
import { getClientTimezoneISOString } from 'helpers/data/getClientTimezoneISOString';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { ListVendasProps } from 'pages/Integracoes/LojaAplicativos/TrayCommerce/PainelAdministradorTray/VendasTray/validationForms';
import { CompartilharMenuIcon } from 'icons';

import { NotaFiscalExibirTela, FormData, yupResolver } from './validation';
import { ItemTelaNotaFiscal } from './ItemNotaFiscal';
import { CompartilharOperacao } from '../ModalCompartilhar/CompartilharOperacao';

type ModalEmissaoNotaFiscalProps = {
  listNotaFiscal: ListVendasProps[];
  isOpen: boolean;
  onClose: () => void;
};

const FormDefaultValue = {
  numeracaoVolumeTransportadora: 1,
  quantidadeVolumeTransportadora: 1,
  dataSaida: new Date(),
  dataEmissao: new Date(),
  modalidadeFrete: ModalidadesFreteEnum.FretePorContaRemetente,
  transportadoraId: null,
};

export const ModalEmissaoNotaFiscal = ({
  listNotaFiscal,
  isOpen,
  onClose: close,
}: ModalEmissaoNotaFiscalProps) => {
  const {
    telaExibicao,
    setTelaExibicao,
    animatePrev,
    animateNext,
    reloadList,
    handleNextTela,
  } = useEmissaoNotaFiscalContext();
  const {
    setNotificarNotaFiscal,
    setStatusNotaFiscal,
    statusNotaFiscal,
    setPossuiNotificacao,
  } = useSignalRContext();
  const [isLoading, setIsLoading] = useState(false);

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const formMethods = useForm<FormData>({
    defaultValues: {
      ...FormDefaultValue,
    },
    resolver: yupResolver,
  });

  const { id: lojaId } = auth.getLoja();
  const { id: usuarioId } = auth.getUsuario();

  const amountVendas = listNotaFiscal.length;

  const isMultipleVendas = amountVendas > 1;

  const { handleSubmit: onSubmit, reset, watch } = formMethods;

  const transportadoraIdWatch = watch('transportadoraId');

  const isEmitindoNotaFiscal =
    telaExibicao === NotaFiscalExibirTela.EMITINDO_NOTA_FISCAL;

  const emitindoNotaSimples = !isMultipleVendas && isEmitindoNotaFiscal;

  const animationKeyframes = keyframes`
    0% { transform: translateX(0); }
    50% { transform: translateX(1000px); opacity: 0}
    51% {transform: translateY(1400px);}
    55% { transform: translateX(1px); }
    100% { transform: translateX(0);}
    `;

  const animationKeyframesPrev = keyframes`
    0% { transform: translateX(0); }
    50% { transform: translateX(-1000px); opacity: 0}
    51% {transform: translateY(1400px);}
    55% { transform: translateX(1px); }
    100% { transform: translateX(0);}
    `;

  const animationNext = `${animationKeyframes} 1s ease-in-out`;
  const animationPrev = `${animationKeyframesPrev} 1s ease-in-out`;

  const modificarFooter =
    telaExibicao !== NotaFiscalExibirTela.TELA_INICIAL &&
    telaExibicao !== NotaFiscalExibirTela.DADOS_TRANSPORTE &&
    telaExibicao !== NotaFiscalExibirTela.DADOS_NOTA;

  const onClose = () => {
    reset(FormDefaultValue);
    setTelaExibicao(NotaFiscalExibirTela.TELA_INICIAL);
    setStatusNotaFiscal(undefined);
    setNotificarNotaFiscal({} as NotaFiscalEmitindo);
    setPossuiNotificacao(false);
    close();
  };

  const handleError = (data: any) => {
    const newValueData = data as FormData;
    if (!newValueData?.transportadoraId && newValueData?.modalidadeFrete) {
      handleNextTela(NotaFiscalExibirTela.DADOS_TRANSPORTE);
    }
  };

  const handleSubmit = onSubmit(async (data) => {
    setIsLoading(true);

    const { dataEmissao, dataSaida } = data;

    if (data.dataSaida.getTime() < dataEmissao.getTime()) {
      toast.warning(
        'A data de saída deve ser maior ou igual a data de emissão'
      );

      setIsLoading(false);
      return;
    }

    const response = await api.post<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.INTEGRACAO_GERAR_NOTA_FISCAL,
      {
        ...data,
        dataEmissao: getClientTimezoneISOString(dataEmissao),
        dataSaida: getClientTimezoneISOString(dataSaida),
        lojaId,
        usuarioId,
        numeracaoVolumeTransportadora: String(
          data.numeracaoVolumeTransportadora
        ),
        modalidadeFrete: data.modalidadeFrete,
        transportadoraId: data?.transportadoraId?.value,
        listaOperacaoId: listNotaFiscal.map((item) => item.operacaoId),
      }
    );
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
        setIsLoading(false);
      }

      if (response.sucesso) {
        handleNextTela(NotaFiscalExibirTela.EMITINDO_NOTA_FISCAL);
      }
    }
    setIsLoading(false);
  }, handleError);

  const handleCompartilharOperacao = useCallback(() => {
    CompartilharOperacao({
      isOpen: true,
      operacaoId: statusNotaFiscal?.operacaoId,
    });
  }, [statusNotaFiscal]);

  return (
    <ModalPadraoChakra
      isCentered={isLargerThan900}
      size={isLargerThan900 ? '5xl' : 'full'}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={false}
    >
      <ModalContent
        bg="white"
        borderRadius={isLargerThan900 ? 'md' : '0'}
        marginBottom={isLargerThan900 ? '3.75rem' : '0'}
        marginTop={isLargerThan900 ? '3.75rem' : '0'}
        w={isLargerThan900 ? '1024px' : 'full'}
        h={isLargerThan900 ? '560px' : 'full'}
        overflow={isLargerThan900 ? 'hidden' : 'auto'}
      >
        <FormProvider {...formMethods}>
          {isLoading && <LoadingPadrao />}
          <ModalBody bg="gray.100" borderTopRadius="10px" padding="0">
            <Box
              h="full"
              w="full"
              animation={
                animateNext
                  ? animationNext
                  : animatePrev
                  ? animationPrev
                  : 'none'
              }
            >
              <ItemTelaNotaFiscal
                telaExibicao={telaExibicao}
                listNotaFiscal={listNotaFiscal}
                amountVendas={amountVendas}
                isMultipleVendas={isMultipleVendas}
                onClose={(shouldReloadList) => {
                  if (shouldReloadList) {
                    reloadList();
                  }

                  onClose();
                }}
              />
            </Box>
          </ModalBody>
          {!emitindoNotaSimples && (
            <ModalFooter flexDirection="column">
              <Flex
                w="full"
                h="full"
                justifyContent="center"
                alignItems="center"
              >
                {isEmitindoNotaFiscal ? (
                  <Button
                    color="gray.500"
                    variant="outlineDefault"
                    borderRadius="20px"
                    colorScheme="gray"
                    onClick={() => onClose()}
                    w="320px"
                  >
                    Não quero acompanhar a emissão
                  </Button>
                ) : (
                  <>
                    <Button
                      color="gray.500"
                      variant="outlineDefault"
                      borderRadius="20px"
                      colorScheme="gray"
                      onClick={() => {
                        if (modificarFooter) {
                          reloadList();
                        }
                        onClose();
                      }}
                      mr="24px"
                      w={modificarFooter ? '160px' : '96px'}
                    >
                      {modificarFooter ? 'Fechar' : 'Cancelar'}
                    </Button>
                    {telaExibicao !== NotaFiscalExibirTela.NOTA_REJEITADA && (
                      <Button
                        variant="solid"
                        colorScheme="secondary.400"
                        color="primary.700"
                        isDisabled={!transportadoraIdWatch}
                        leftIcon={
                          modificarFooter ? <CompartilharMenuIcon /> : undefined
                        }
                        width={modificarFooter ? '240px' : '200px'}
                        onClick={() => {
                          if (!modificarFooter) {
                            handleSubmit();
                          } else {
                            handleCompartilharOperacao();
                          }
                        }}
                        isLoading={isLoading}
                      >
                        {modificarFooter
                          ? 'Compartilhar'
                          : 'Emitir Nota Fiscal'}
                      </Button>
                    )}
                  </>
                )}
              </Flex>
            </ModalFooter>
          )}
        </FormProvider>
      </ModalContent>
    </ModalPadraoChakra>
  );
};
