import React from 'react';
import { Box } from '@chakra-ui/react';
import { isIOS } from 'react-device-detect';

const Footer: React.FC = ({ children }) => {
  return (
    <Box
      bg="pdv.footer"
      w="100%"
      minH="50px"
      p="4"
      pb={{ base: isIOS ? '10' : 4, md: '4' }}
    >
      {children}
    </Box>
  );
};

export default Footer;
