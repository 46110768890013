import { addDays } from 'date-fns';

/**
 * Retorna uma data considerando a regra de evitar sábado e domingo.
 *
 * @param {Date} date - A data de referência para calcular a data útil.
 * @returns {Date} Uma nova data que não é sábado nem domingo.
 *
 * @example
 * const originalDate = new Date('2023-07-22'); // Uma sexta-feira (dia da semana 5)
 * const utilDate = getNonWeekendDate(originalDate); // Retorna a própria data, pois não é sábado nem domingo
 *
 * @example
 * const originalDate = new Date('2023-07-23'); // Um sábado (dia da semana 6)
 * const utilDate = getNonWeekendDate(originalDate); // Retorna uma nova data com dois dias adicionados (segunda-feira)
 *
 * @example
 * const originalDate = new Date('2023-07-24'); // Um domingo (dia da semana 0)
 * const utilDate = getNonWeekendDate(originalDate); // Retorna uma nova data com um dia adicionado (segunda-feira)
 */

export function getNonWeekendDate(date: Date): Date {
  const dateIsSaturday = date.getDay() === 6;
  const dateIsSunday = date.getDay() === 0;

  if (dateIsSaturday) {
    return addDays(date, 2);
  }

  if (dateIsSunday) {
    return addDays(date, 1);
  }

  return date;
}
