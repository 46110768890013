export const TipoAcaoEnum = {
  SEM_ACAO: 1,
  ENTRADA: 2,
  SAIDA: 3,

  properties: {
    1: { label: 'Sem ação', value: 1 },
    2: { label: 'Entrada', value: 2 },
    3: { label: 'Saída', value: 3 },
  },
};
