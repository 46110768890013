import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './assets/css/lite-purple.css';
import './i18n';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import Loading from './components/Layout/Loading/LoadingPadrao';

ReactDOM.render(
  <React.StrictMode>
    <Suspense
      fallback={
        <div style={{ minHeight: '100vh', position: 'relative' }}>
          <Loading />
        </div>
      }
    >
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      // const evt = new CustomEvent('pwaUpdated');
      // window.dispatchEvent(evt);

      window.location.reload();
    }
  },
});
