import styled, { css } from 'styled-components';
import { FiPlusCircle } from 'react-icons/fi';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';

import { IoMdArrowDownRight } from 'icons';

import NivelCategoriaProdutoEnum from 'constants/enum/nivelCategoriaProduto';

export const SideContainer = styled.div`
  display: flex;
  align-items: center;

  flex-direction: row;
`;

export const ExpandirRecolherButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  height: 18px;
  width: 18px;
  margin-right: 6px;
  padding: 0px;
  border-radius: 4px;
  border: none;
`;

const ExpandirRecolherIconStyle = css`
  font-size: 0.75rem;

  user-select: none;
`;

export const ExpandirIcon = styled(IoIosArrowUp)`
  ${ExpandirRecolherIconStyle}
`;

export const RecolherIcon = styled(IoIosArrowDown)`
  ${ExpandirRecolherIconStyle}
`;

export const ItemText = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  max-width: 100%;
`;

export const AddButton = styled.button.attrs(() => ({ type: 'button' }))`
  display: flex;
  align-items: center;
  justify-content: center;

  background: transparent;
  border: none;
  padding: 0px;
  margin-right: 5px;

  color: var(--sti-ck-colors-blue-500);
`;

export const AddIcon = styled(FiPlusCircle)`
  font-size: 1.125rem;
`;

export const NivelIcon = styled(IoMdArrowDownRight)`
  font-size: 1rem;
`;

interface CardProps {
  isDragging?: boolean;
  nivel: number;
  nivelCores?: number;
  inativo?: boolean;
  isTyping?: boolean;
}

export const Card = styled.div<CardProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: grab;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);

  height: 30px;
  width: 100%;
  border-radius: 5px;
  padding: 5px 15px;
  font-size: 0.8125rem;

  margin-left: 10px;

  ${({ nivel, nivelCores, isDragging }) =>
    css`
      background-color: ${isDragging
        ? 'transparent'
        : NivelCategoriaProdutoEnum.properties[(nivelCores || nivel) - 1]
            .backgroundColor};
      color: ${NivelCategoriaProdutoEnum.properties[(nivelCores || nivel) - 1]
        .foregroundColor};

      /* margin-left: ${`calc(20px * ${nivel - 1})`}; */

      border: ${isDragging
        ? `2px dashed ${
            NivelCategoriaProdutoEnum.properties[(nivelCores || nivel) - 1]
              .foregroundColor
          }`
        : 'none'};

      ${nivel < 4 &&
      css`
        ${ExpandirRecolherButton} {
          color: ${NivelCategoriaProdutoEnum.properties[
            (nivelCores || nivel) - 1
          ].backgroundColor};
          background-color: ${NivelCategoriaProdutoEnum.properties[
            (nivelCores || nivel) - 1
          ].foregroundColor};
        }
      `}
    `}

  ${({ nivel, inativo, isDragging }) =>
    inativo &&
    css`
      background-color: ${isDragging
        ? 'transparent'
        : 'var(--sti-ck-colors-red-400)'};
      color: var(--white);

      ${nivel < 4 &&
      css`
        ${ExpandirRecolherButton} {
          color: var(--sti-ck-colors-red-400);
          background-color: var(--white);
        }

        ${AddButton} {
          color: var(--white);
        }
      `}
    `}

    ${({ isTyping }) =>
    isTyping &&
    css`
      border: 2px solid var(--sti-ck-colors-blue-500);
    `}
`;

interface ContainerProps {
  nivel: number;
  nivelCores?: number;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  height: 30px;
  margin-left: ${({ nivel }) =>
    nivel === 1 ? '12px' : `calc(45px * ${nivel - 1})`};
  & + & {
    margin-top: 5px;
  }

  color: ${({ nivel, nivelCores }) =>
    NivelCategoriaProdutoEnum.properties[(nivelCores || nivel) - 1]
      .foregroundColor};
`;

export const TagInativo = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 20px;
  margin: auto 10px;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.6875rem;
  font-style: italic;

  color: var(--sti-ck-colors-red-400);
  background-color: var(--white);
`;
