import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';
import TipoTabelaPrecoEnum from 'constants/enum/tipoTabelaPrecoEnum';
import { sistemaApiKey } from 'services/sistemaApiKey';

import { OptionsProps } from './Container';

type OptionResponseProps = {
  id: string;
  nome: string;
  padraoSistema: boolean;
};

type FormData = {
  tabelaPrecoIdAVista: string;
  tabelaPrecoIdAPrazo: string;
  tabelaPrecoIdAlternativo: string;
};

export const useTabelaPreco = () => {
  const [listTabelaPreco, setListTabelaPreco] = useState<OptionsProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const formMethods = useForm<FormData>();

  const { setValue, handleSubmit: onSubmit, reset } = formMethods;

  const listTabelaPadraoSistema = listTabelaPreco.filter(
    (itemTabelaPreco) => itemTabelaPreco.padraoSistema
  );

  const getTabelaPreco = useCallback(async () => {
    const response = await api.get<void, ResponseApi<OptionResponseProps[]>>(
      `${ConstanteEnderecoWebservice.TABELA_PRECO_LISTAR_TABELAS_PRECO}?tipo=${TipoTabelaPrecoEnum.INTEGRACAO}`
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso && response.dados) {
        const newListTabelaPreco = response.dados.map((itemTabelaPreco) => ({
          label: itemTabelaPreco.nome,
          value: itemTabelaPreco.id,
          padraoSistema: itemTabelaPreco.padraoSistema,
        }));

        response.dados.forEach((itemTabelaPreco) => {
          if (itemTabelaPreco.padraoSistema) {
            setValue('tabelaPrecoIdAVista', itemTabelaPreco.id);
            setValue('tabelaPrecoIdAPrazo', itemTabelaPreco.id);
          }
        });

        setListTabelaPreco(newListTabelaPreco);
      }
    }
  }, [setValue]);

  const handleSubmit = onSubmit(async (data) => {
    setIsLoading(true);
    const response = await sistemaApiKey().post<void, ResponseApi<FormData>>(
      ConstanteEnderecoWebservice.PDV_AUTONOMO_CADASTRAR_TABELA_PRECO,
      {
        ...data,
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }
      if (response.sucesso) {
        toast.success('As tabelas de preço foram salvas com sucesso');
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  });
  const getConfiguracaoTabelaPreco = useCallback(async () => {
    setIsLoading(true);
    await getTabelaPreco();

    const response = await sistemaApiKey().get<void, ResponseApi<FormData>>(
      ConstanteEnderecoWebservice.PDV_AUTONOMO_OBTER_TABELA_PRECO
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }
      if (response.sucesso) {
        reset(response.dados);
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [reset, getTabelaPreco]);

  useEffect(() => {
    const getRequests = async () => {
      getConfiguracaoTabelaPreco();
    };
    getRequests();
  }, [getConfiguracaoTabelaPreco]);

  return {
    setValue,
    listTabelaPreco,
    setListTabelaPreco,
    isLoading,
    formMethods,
    listTabelaPadraoSistema,
    handleSubmit,
  };
};
