import { Box } from '@chakra-ui/react';
import { Prompt } from 'react-router-dom';
import { FormProvider, UseFormReturn } from 'react-hook-form';

import ManterFoco from 'components/Geral/ManterFoco';
import RodapeFormulario from 'components/Geral/RodapeFormulario';
import DataCriacaoAlteracao from 'components/Geral/DataCriacaoAlteracao';

import LoadingPadrao from '../Loading/LoadingPadrao';

type ContainerListagem = {
  formIsDirty?: boolean;
  formMethods?: UseFormReturn<any, any>;
  isLoading?: boolean;
  onSubmit?: () => void;
  iconSubmit?: boolean;
  onSubmitReset?: () => void;
  children: React.ReactNode;
  dataHoraCadastro?: string;
  dataHoraUltimaAlteracao?: string;
  isVisualizar?: boolean;
  maxWidth?: string;
  onCancel?: () => void;
  containerPossuiFundo?: boolean;
  isForm?: boolean;
  hasPadding?: boolean;
  textSubmit?: string;
  labelOnCancel?: string;
};

export const ContainerListagem = ({
  formIsDirty,
  formMethods,
  isLoading = false,
  iconSubmit,
  labelOnCancel,
  isForm = true,
  textSubmit,
  hasPadding = true,
  onSubmit,
  onSubmitReset,
  dataHoraCadastro,
  dataHoraUltimaAlteracao,
  isVisualizar = false,
  containerPossuiFundo = true,
  maxWidth = '1166px',
  onCancel,
  children,
}: ContainerListagem) => {
  return (
    <Box maxWidth={maxWidth}>
      {formIsDirty && <Prompt when={formIsDirty} message="" />}
      {isForm && formMethods ? (
        <FormProvider {...formMethods}>
          <ManterFoco blockTab={isLoading}>
            {containerPossuiFundo ? (
              <Box
                borderRadius="5px"
                bg="white"
                boxShadow="0px 0px 6px 0px rgba(0, 0, 0, 0.2)"
                padding={['25px', '20px', '30px', '35px']}
              >
                {isLoading && <LoadingPadrao />}
                {children}
              </Box>
            ) : (
              <>{children}</>
            )}
            {isVisualizar ? (
              <DataCriacaoAlteracao
                dataHoraCriacao={dataHoraCadastro}
                dataHoraUltimaAlteracao={dataHoraUltimaAlteracao}
              />
            ) : (
              <RodapeFormulario
                labelOnCancel={labelOnCancel}
                onCancel={onCancel}
                onSubmit={onSubmit}
                onSubmitReset={onSubmitReset}
                disabled={isLoading}
                dataHoraCriacao={dataHoraCadastro}
                dataHoraUltimaAlteracao={dataHoraUltimaAlteracao}
              />
            )}
          </ManterFoco>
        </FormProvider>
      ) : (
        <ManterFoco blockTab={isLoading}>
          {containerPossuiFundo ? (
            <Box
              borderRadius="5px"
              bg="white"
              boxShadow="0px 0px 6px 0px rgba(0, 0, 0, 0.2)"
              padding={
                hasPadding ? ['25px', '20px', '30px', '35px'] : undefined
              }
            >
              {isLoading && <LoadingPadrao />}
              {children}
            </Box>
          ) : (
            <>{children}</>
          )}
          {isVisualizar ? (
            <DataCriacaoAlteracao
              dataHoraCriacao={dataHoraCadastro}
              dataHoraUltimaAlteracao={dataHoraUltimaAlteracao}
            />
          ) : (
            <RodapeFormulario
              onCancel={onCancel}
              onSubmit={onSubmit}
              textSubmit={textSubmit}
              onSubmitReset={onSubmitReset}
              disabled={isLoading}
              labelOnCancel={labelOnCancel}
              iconSubmit={iconSubmit}
              dataHoraCriacao={dataHoraCadastro}
              dataHoraUltimaAlteracao={dataHoraUltimaAlteracao}
            />
          )}
        </ManterFoco>
      )}
    </Box>
  );
};
