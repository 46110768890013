import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 998;
`;

export const ContainerSpinner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 999;
`;
