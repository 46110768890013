import React, { createContext, useContext, useCallback } from 'react';
import produce from 'immer';

import PlanoContaInterface from 'types/planoConta';

import { usePlanoContasGeralContext } from './PlanoContasGeral';

interface PlanoContasVisaoDREContextProps {
  handleGetPlanosContasPeloPaiId: (paiId?: string) => PlanoContaInterface[];
  handleMoverPlanoConta: (
    planoContaId: string,
    planoContaPaiId?: string,
    planoContaAbaixoId?: string
  ) => void;
}

const PlanoContasVisaoDREContext = createContext<PlanoContasVisaoDREContextProps>(
  {} as PlanoContasVisaoDREContextProps
);

interface PlanoContasProviderProps {
  children: React.ReactNode;
}

export default function PlanoContasProvider({
  children,
}: PlanoContasProviderProps): JSX.Element {
  const { planosConta, setPlanosConta } = usePlanoContasGeralContext();

  const handleGetPlanosContasPeloPaiId = useCallback(
    (paiId?: string) => {
      return planosConta
        .filter((planoConta) =>
          !paiId
            ? !planoConta.planoContaPaiId ||
              planoConta.planoContaPaiId ===
                '00000000-0000-0000-0000-000000000000'
            : planoConta.planoContaPaiId === paiId
        )
        .sort((a, b) => {
          if (
            Number(a.indice.replaceAll('.', '')) <
            Number(b.indice.replaceAll('.', ''))
          ) {
            return -1;
          }
          if (
            Number(a.indice.replaceAll('.', '')) >
            Number(b.indice.replaceAll('.', ''))
          ) {
            return 1;
          }
          return 0;
        });
    },
    [planosConta]
  );

  const handleMoverPlanoConta = useCallback(
    (
      planoContaId: string,
      planoContaPaiId?: string,
      planoContaAbaixoId?: string
    ) => {
      setPlanosConta(
        produce(planosConta, (draft) => {
          const targetItem = draft.find(
            (planoConta) => planoConta.id === planoContaId
          );

          if (targetItem) {
            const rInd = draft.indexOf(targetItem);
            draft.splice(rInd, 1);

            const parentItem = !planoContaPaiId
              ? undefined
              : draft.find((planoConta) => planoConta.id === planoContaPaiId);

            targetItem.planoContaPaiId = parentItem ? parentItem.id : undefined;

            if (parentItem && !parentItem.ativo) {
              targetItem.ativo = false;
            }

            let index = 0;

            if (planoContaAbaixoId) {
              index = draft.findIndex(
                (planoConta) => planoConta.id === planoContaAbaixoId
              );
            }

            targetItem.indice = '0';

            draft.splice(index, 0, targetItem);
          }
        })
      );
    },
    [planosConta, setPlanosConta]
  );

  return (
    <PlanoContasVisaoDREContext.Provider
      value={{
        handleGetPlanosContasPeloPaiId,
        handleMoverPlanoConta,
      }}
    >
      {children}
    </PlanoContasVisaoDREContext.Provider>
  );
}

export function usePlanoContasVisaoDREContext(): PlanoContasVisaoDREContextProps {
  const context = useContext(PlanoContasVisaoDREContext);

  if (!context)
    throw new Error(
      'usePlanoContasVisaoDREContext must be used within a PlanoContasProvider.'
    );

  return context;
}
