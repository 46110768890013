/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, GridItem, Icon, Td, Tr } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import auth from 'modules/auth';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteFuncionalidades from 'constants/permissoes';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';
import statusOperacaoTransferenciaEnum from 'constants/enum/statusOperacaoTransferencia';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import { SalvarInserirNovoIcon } from 'icons';
import { shallowEqual } from 'helpers/validation/shallowEqual';
import { getName } from 'helpers/enum/getName';

import InputDateRange from 'components/PDV/InputDateRange';
import { BuscaAvancadaButton } from 'components/update/BuscaAvancadaButton';
import { ButtonFuncionalidade } from 'components/update/Button/ButtonFuncionalidade';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { PaginationData } from 'components/update/Pagination';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import {
  PagedTable,
  PagedTableForwardRefData,
} from 'components/update/Table/PagedTable';
import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import { ModalConfirmacao } from 'components/Modal/ModalConfirmacao';
import { ModalConferirItens } from 'components/update/Modal/ModalConferirItem';
import { ModalErro } from 'components/Modal/ModalErro';
import { Link } from 'components/update/Link';
import { useSignalRContext } from 'store/SignalR';
import { ModalImpressaoEtiquetaPersonalizada } from 'components/Modal/ModalImpressaoEtiquetaPersonalizada';
import { formatDateHourMinute } from 'helpers/format/formatStringDate';
import ModalBuscaAvancadaTransferenciaEstoque from './ModalBuscaAvancada';
import { FormData, formDefaultValues } from './validationForm';
import {
  ListarTransferenciaEstoqueProps,
  ObterLojasProps,
  DropDownItensProps,
} from './types';

const TransferenciaEstoqueListar = () => {
  const { hubConnection, joinGroup, exitGroup } = useSignalRContext();

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [hasFilters, setHasFilters] = useState(false);
  const [columnsData, setColumnsData] = useState<
    ListarTransferenciaEstoqueProps[]
  >([]);
  const [itemsCount, setItemsCount] = useState(0);
  const [getLoja, setGetLoja] = useState<ObterLojasProps[]>([]);
  const loja = auth.getLoja();

  const formMethods = useForm({
    defaultValues: formDefaultValues(),
  });

  const [currentFilters, setCurrentFilters] = useState<FormData>(
    formDefaultValues
  );

  const [isModalBuscaAvancadaOpen, setIsModalBuscaAvancadaOpen] = useState(
    false
  );

  const pagedTableRef = useRef<PagedTableForwardRefData>(null);

  const { casasDecimais } = usePadronizacaoContext();

  const permissaoTransferenciaEstoqueVisualizar = auth.possuiPermissao(
    ConstanteFuncionalidades.TRANSFERENCIA_ESTOQUE_VISUALIZAR
  );

  const permissaoTransferenciaEstoqueEditar = auth.possuiPermissao(
    ConstanteFuncionalidades.TRANSFERENCIA_ESTOQUE_ALTERAR
  );

  const permissaoTransferenciaEstoqueDuplicar = auth.possuiPermissao(
    ConstanteFuncionalidades.TRANSFERENCIA_ESTOQUE_DUPLICAR
  );

  const handleReset = () => {
    setCurrentFilters(formDefaultValues());
    setHasFilters(false);
    formMethods.reset(formDefaultValues());
  };

  const filtersSubmit = formMethods.handleSubmit((data) => {
    const filtersIsDirty = !shallowEqual(data, currentFilters);

    if (filtersIsDirty) {
      setCurrentFilters(data);
      setHasFilters(true);
    }
  });

  function handleSubmitModalBuscaAvancada(data: FormData) {
    formMethods.reset(data);
    filtersSubmit();
  }

  const loadColumnsData = useCallback(
    async (paginationData: PaginationData) => {
      setIsLoading(true);

      const params = {
        situacao: currentFilters.situacao
          ? Number(currentFilters.situacao)
          : '',
        identificador: currentFilters.identificador
          ? Number(currentFilters.identificador)
          : '',
        lojaOrigem: currentFilters.lojaOrigem || '',
        lojaDestino: currentFilters.lojaDestino || '',
        dataEmissaoInicio: currentFilters.dataEmissaoInicio.toISOString(),
        dataEmissaoFim: currentFilters.dataEmissaoFim.toISOString(),
      };

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<ListarTransferenciaEstoqueProps>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.TRANSFERENCIA_ESTOQUE_LISTAR_PAGINADO,
          paginationData,
          params
        )
      );

      if (response) {
        if (response.sucesso) {
          setColumnsData(response.dados.registros || []);
          setItemsCount(response.dados.total || 0);

          const groupNotification = `transferencia-estoque-${
            auth.getLoja().id
          }`;

          hubConnection.off('alteracao-status');
          await exitGroup(groupNotification);

          joinGroup(groupNotification);

          hubConnection.on('alteracao-status', async (userId: string) => {
            const usuarioLogadoId = auth.getUsuario().id;

            if (usuarioLogadoId !== userId) {
              toast.info('A situação de uma transferência foi alterada.');
              pagedTableRef?.current?.reload();
            }
          });
        }
      }

      setIsLoading(false);
    },
    [currentFilters]
  );

  useEffect(() => {
    async function obterLojas() {
      const response = await api.get<void, ResponseApi<ObterLojasProps[]>>(
        ConstanteEnderecoWebservice.LOJA_LISTAR_TRANSFERENCIA_ESTOQUE
      );

      if (response) {
        if (response.sucesso) {
          setGetLoja(response.dados);
        }
      }
    }
    obterLojas();
  }, []);

  function handlePushVisualizar(operacaoTransferenciaId: string) {
    let href = ConstanteRotas.TRANSFERENCIA_ESTOQUE;

    if (permissaoTransferenciaEstoqueVisualizar.permitido) {
      href = SubstituirParametroRota(
        ConstanteRotas.TRANSFERENCIA_ESTOQUE_VISUALIZAR,
        'id',
        operacaoTransferenciaId
      );
    }

    history.push(href);
  }

  function handlePushEditar(operacaoTransferenciaId: string) {
    let href = ConstanteRotas.TRANSFERENCIA_ESTOQUE;

    if (permissaoTransferenciaEstoqueEditar.permitido) {
      href = SubstituirParametroRota(
        ConstanteRotas.TRANSFERENCIA_ESTOQUE_ALTERAR,
        'id',
        operacaoTransferenciaId
      );
    }

    history.push(href);
  }

  const handleEmitirNotaFiscal = async (operacaoOrigemId: string) => {
    const response = await api.get<
      void,
      ResponseApi<ListarTransferenciaEstoqueProps>
    >(ConstanteEnderecoWebservice.NOTA_FISCAL_OBTER_INFORMACOES_OPERACAO, {
      params: { id: operacaoOrigemId },
    });

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item) => toast.warning(item));
      }

      if (response.sucesso && response.dados) {
        history.push(ConstanteRotas.NOTA_FISCAL_CADASTRAR, {
          dadosOperacaoEmitirNotaFiscal: response.dados,
        });
      }
    }
  };

  async function handleConfirmarTransferencia(operacaoTransferenciaId: string) {
    ModalConfirmacao({
      callback: async (ok: boolean) => {
        if (ok) {
          setIsLoading(true);

          const response = await api.put<void, ResponseApi>(
            ConstanteEnderecoWebservice.TRANSFERENCIA_ESTOQUE_ALTERAR_STATUS,
            {
              operacaoTransferenciaId,
              status: statusOperacaoTransferenciaEnum.CONFIRMADA,
            }
          );

          if (response) {
            if (response.avisos) {
              response.avisos.map((item: string) => toast.warning(item));
            }

            if (response?.sucesso) {
              if (pagedTableRef.current) {
                pagedTableRef.current.reload();
              }
            }
          }

          setIsLoading(false);
        }
      },

      cancelButtonText: 'Voltar',
      confirmButtonText: 'Sim, confirmar',
      title: 'Confirmar transferência',
      text: (
        <>
          Os itens serão{' '}
          <strong>adicionados ao seu estoque imediatamente.</strong>
          <br /> **Os valores de custo de um ou mais produtos são diferentes
          entre as lojas,
          <br /> portanto o{' '}
          <strong>custo médio e o markup serão atualizados.</strong>
          <br /> Deseja confirmar esta transferência?
        </>
      ),
      hasSecondaryColorScheme: true,
    });
  }

  async function handleRejeitarTransferencia(operacaoTransferenciaId: string) {
    ModalConfirmacao({
      callback: async (ok: boolean) => {
        if (ok) {
          setIsLoading(true);

          const response = await api.put<void, ResponseApi>(
            ConstanteEnderecoWebservice.TRANSFERENCIA_ESTOQUE_ALTERAR_STATUS,
            {
              operacaoTransferenciaId,
              status: statusOperacaoTransferenciaEnum.REJEITADA,
            }
          );

          if (response) {
            if (response.avisos) {
              response.avisos.map((item: string) => toast.warning(item));
            }

            if (response?.sucesso) {
              if (pagedTableRef.current) {
                pagedTableRef.current.reload();
              }
            }
          }

          setIsLoading(false);
        }
      },

      cancelButtonText: 'Voltar',
      confirmButtonText: 'Sim, rejeitar',
      title: 'Rejeitar transferência',
      text: (
        <>
          Os itens <strong>não serão adicionados ao seu estoque</strong> e o
          local de origem será
          <br />
          notificado. Você tem certeza que deseja rejeitar esta transferência?
        </>
      ),
    });
  }

  async function handleEnviarTransferencia(operacaoTransferenciaId: string) {
    ModalConfirmacao({
      callback: async (ok: boolean) => {
        if (ok) {
          setIsLoading(true);

          const response = await api.put<void, ResponseApi>(
            ConstanteEnderecoWebservice.TRANSFERENCIA_ESTOQUE_ALTERAR_STATUS,
            {
              operacaoTransferenciaId,
              status: statusOperacaoTransferenciaEnum.PENDENTE,
            }
          );

          if (response) {
            if (response.avisos) {
              response.avisos.map((item: string) => toast.warning(item));
            }

            if (response?.sucesso) {
              if (pagedTableRef.current) {
                pagedTableRef.current.reload();
              }
            }
          }

          setIsLoading(false);
        }
      },

      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Enviar',
      title: 'Enviar transferência',
      text: (
        <>
          Esta ação depende de uma confirmação de recebimento do local de
          destino
          <br />
          para ser finalizada. Para sua segurança, os itens serão{' '}
          <strong>baixados no estoque de origem</strong> e ficarão com entrada
          pendente no estoque de destino até a<br />
          confirmação de recebimento da operação.
        </>
      ),
    });
  }

  async function handleCancelarTransferencia(operacaoTransferenciaId: string) {
    ModalErro({
      callback: async (ok: boolean) => {
        if (ok) {
          setIsLoading(true);

          const response = await api.put<void, ResponseApi>(
            ConstanteEnderecoWebservice.TRANSFERENCIA_ESTOQUE_ALTERAR_STATUS,
            {
              operacaoTransferenciaId,
              status: statusOperacaoTransferenciaEnum.CANCELADA,
            }
          );

          if (response) {
            if (response.avisos) {
              response.avisos.map((item: string) => toast.warning(item));
            }

            if (response?.sucesso) {
              if (pagedTableRef.current) {
                pagedTableRef.current.reload();
              }
            }
          }

          setIsLoading(false);
        }
      },

      textoTitulo: 'Cancelar transferência',
      textoMensagem: (
        <>
          Os produtos selecionados nesta ação serão devolvidos ao estoque.
          <br />
          Você tem certeza que deseja cancelar?
        </>
      ),
      textoButton: 'Sim, cancelar',
    });
  }

  const handleDuplicarTransferenciaEstoque = async (
    operacaoTransferenciaId: string
  ) => {
    setIsLoading(true);

    if (permissaoTransferenciaEstoqueDuplicar.permitido) {
      history.push(
        SubstituirParametroRota(
          ConstanteRotas.TRANSFERENCIA_ESTOQUE_CADASTRAR,
          'id',
          operacaoTransferenciaId
        )
      );
    }

    setIsLoading(false);
  };

  function handlePushCadastrarNovaTransferenciaEstoque() {
    history.push(
      SubstituirParametroRota(
        ConstanteRotas.TRANSFERENCIA_ESTOQUE_CADASTRAR,
        'id',
        ''
      )
    );
  }

  return (
    <>
      <SimpleGridForm>
        <FormProvider {...formMethods}>
          <GridItem colSpan={{ base: 12, lg: 2 }}>
            <Box maxW={{ base: 'full' }}>
              <InputDateRange
                borderColor="gray.100"
                borderRadius="md"
                name="data"
                startDateName="dataEmissaoInicio"
                endDateName="dataEmissaoFim"
                maxDate={new Date()}
                onConfirm={() => filtersSubmit()}
              />
            </Box>
          </GridItem>
          <GridItem colSpan={{ base: 12, sm: 6, lg: 6 }}>
            <Box maxW={{ base: 'full', sm: '240px' }}>
              <BuscaAvancadaButton
                setIsModalBuscaAvancadaOpen={setIsModalBuscaAvancadaOpen}
                hasFilters={hasFilters}
              />
            </Box>
          </GridItem>
          <GridItem
            colSpan={{ base: 12, sm: 6, lg: 4 }}
            display="flex"
            justifyContent={{ base: 'flex-start', sm: 'flex-end' }}
          >
            <ButtonFuncionalidade
              funcionalidade={
                ConstanteFuncionalidades.TRANSFERENCIA_ESTOQUE_CONFIRMAR
              }
              colorScheme="secondary"
              minWidth={{ base: 'full', sm: '220px' }}
              borderRadius="5px"
              style={{
                height: '42px',
              }}
              leftIcon={<Icon as={SalvarInserirNovoIcon} fontSize="lg" />}
              isDisabled={isLoading}
              onClick={handlePushCadastrarNovaTransferenciaEstoque}
            >
              Nova transferência
            </ButtonFuncionalidade>
          </GridItem>
        </FormProvider>
        <GridItem colSpan={12}>
          <PagedTable
            ref={pagedTableRef}
            isLoading={isLoading}
            loadColumnsData={loadColumnsData}
            itemsTotalCount={itemsCount}
            defaultKeyOrdered="dataHoraEmissao"
            defaultOrderDirection="desc"
            tableHeaders={[
              { key: 'dataHoraEmissao', content: 'Data/Hora', w: '200px' },
              {
                key: 'identificador',
                content: 'Identificador',
                isOrderable: false,
                w: '150px',
              },
              {
                key: 'localOrigem',
                isOrderable: false,
                content: 'Local de origem',
              },
              {
                key: 'localDestino',
                isOrderable: false,
                content: 'Local de destino',
              },
              {
                key: 'situacao',
                content: 'Situação',
                isOrderable: false,
                w: '150px',
              },
              {
                key: 'totalItens',
                content: 'Total de itens',
                isOrderable: false,
                w: '150px',
              },
              { key: 'acoes', content: 'Ações', isOrderable: false, w: '1px' },
            ]}
            renderTableRows={
              columnsData &&
              columnsData.map(
                ({
                  operacaoTransferenciaId,
                  dataHoraEmissao,
                  identificador,
                  lojaDestinoId,
                  lojaDestino,
                  lojaOrigemId,
                  lojaOrigem,
                  quantidadeTotal,
                  status,
                  operacaoOrigemId,
                }) => {
                  let dropDownItens: DropDownItensProps[] = [];

                  const duplicar = {
                    content: 'Duplicar',
                    onClick: () => {
                      handleDuplicarTransferenciaEstoque(
                        operacaoTransferenciaId
                      );
                    },
                    funcionalidade:
                      ConstanteFuncionalidades.TRANSFERENCIA_ESTOQUE_DUPLICAR,
                  };

                  if (loja.id === lojaOrigemId && loja.id === lojaDestinoId) {
                    if (status === statusOperacaoTransferenciaEnum.CONFIRMADA) {
                      dropDownItens = [
                        ...dropDownItens,
                        {
                          content: 'Visualizar',
                          onClick: () => {
                            handlePushVisualizar(operacaoTransferenciaId);
                          },
                          funcionalidade:
                            ConstanteFuncionalidades.TRANSFERENCIA_ESTOQUE_VISUALIZAR,
                        },
                        duplicar,
                        {
                          content: 'Emitir Nota Fiscal',
                          onClick: () => {
                            handleEmitirNotaFiscal(operacaoOrigemId);
                          },
                          funcionalidade: '',
                        },
                        {
                          content: 'Imprimir etiquetas',
                          onClick: () => {
                            ModalImpressaoEtiquetaPersonalizada({
                              operacaoId: operacaoOrigemId,
                            });
                          },
                          funcionalidade: '',
                        },
                      ];
                    }

                    if (status === statusOperacaoTransferenciaEnum.CANCELADA) {
                      dropDownItens = [
                        ...dropDownItens,
                        {
                          content: 'Visualizar',
                          onClick: () => {
                            handlePushVisualizar(operacaoTransferenciaId);
                          },
                          funcionalidade:
                            ConstanteFuncionalidades.TRANSFERENCIA_ESTOQUE_VISUALIZAR,
                        },
                        duplicar,
                      ];
                    }
                  } else if (loja.id === lojaOrigemId) {
                    if (status === statusOperacaoTransferenciaEnum.RASCUNHO) {
                      dropDownItens = [
                        ...dropDownItens,
                        {
                          content: 'Editar',
                          onClick: () => {
                            handlePushEditar(operacaoTransferenciaId);
                          },
                          funcionalidade:
                            ConstanteFuncionalidades.TRANSFERENCIA_ESTOQUE_ALTERAR,
                        },
                        {
                          content: 'Enviar',
                          onClick: () => {
                            handleEnviarTransferencia(operacaoTransferenciaId);
                          },
                          funcionalidade:
                            ConstanteFuncionalidades.TRANSFERENCIA_ESTOQUE_CONFIRMAR,
                        },
                        {
                          content: 'Cancelar',
                          onClick: () => {
                            handleCancelarTransferencia(
                              operacaoTransferenciaId
                            );
                          },
                          funcionalidade:
                            ConstanteFuncionalidades.TRANSFERENCIA_ESTOQUE_CANCELAR,
                        },
                        duplicar,
                      ];
                    }

                    if (status === statusOperacaoTransferenciaEnum.PENDENTE) {
                      dropDownItens = [
                        ...dropDownItens,
                        {
                          content: 'Visualizar',
                          onClick: () => {
                            handlePushVisualizar(operacaoTransferenciaId);
                          },
                          funcionalidade:
                            ConstanteFuncionalidades.TRANSFERENCIA_ESTOQUE_VISUALIZAR,
                        },
                        {
                          content: 'Cancelar',
                          onClick: () => {
                            handleCancelarTransferencia(
                              operacaoTransferenciaId
                            );
                          },
                          funcionalidade:
                            ConstanteFuncionalidades.TRANSFERENCIA_ESTOQUE_CANCELAR,
                        },
                        duplicar,
                        {
                          content: 'Emitir Nota Fiscal',
                          onClick: () => {
                            handleEmitirNotaFiscal(operacaoOrigemId);
                          },
                          funcionalidade: '',
                        },
                      ];
                    }

                    if (status === statusOperacaoTransferenciaEnum.REJEITADA) {
                      dropDownItens = [
                        ...dropDownItens,
                        {
                          content: 'Editar',
                          onClick: () => {
                            handlePushEditar(operacaoTransferenciaId);
                          },
                          funcionalidade:
                            ConstanteFuncionalidades.TRANSFERENCIA_ESTOQUE_ALTERAR,
                        },
                        {
                          content: 'Cancelar',
                          onClick: () => {
                            handleCancelarTransferencia(
                              operacaoTransferenciaId
                            );
                          },
                          funcionalidade:
                            ConstanteFuncionalidades.TRANSFERENCIA_ESTOQUE_CANCELAR,
                        },
                        duplicar,
                      ];
                    }

                    if (status === statusOperacaoTransferenciaEnum.CONFIRMADA) {
                      dropDownItens = [
                        ...dropDownItens,
                        {
                          content: 'Visualizar',
                          onClick: () => {
                            handlePushVisualizar(operacaoTransferenciaId);
                          },
                          funcionalidade:
                            ConstanteFuncionalidades.TRANSFERENCIA_ESTOQUE_VISUALIZAR,
                        },

                        duplicar,
                        {
                          content: 'Emitir Nota Fiscal',
                          onClick: () => {
                            handleEmitirNotaFiscal(operacaoOrigemId);
                          },
                          funcionalidade: '',
                        },
                      ];
                    }

                    if (status === statusOperacaoTransferenciaEnum.CANCELADA) {
                      dropDownItens = [
                        ...dropDownItens,
                        {
                          content: 'Visualizar',
                          onClick: () => {
                            handlePushVisualizar(operacaoTransferenciaId);
                          },
                          funcionalidade:
                            ConstanteFuncionalidades.TRANSFERENCIA_ESTOQUE_VISUALIZAR,
                        },
                        duplicar,
                      ];
                    }
                  } else if (loja.id === lojaDestinoId) {
                    if (status === statusOperacaoTransferenciaEnum.PENDENTE) {
                      dropDownItens = [
                        ...dropDownItens,
                        {
                          content: 'Visualizar',
                          onClick: () => {
                            handlePushVisualizar(operacaoTransferenciaId);
                          },
                          funcionalidade:
                            ConstanteFuncionalidades.TRANSFERENCIA_ESTOQUE_VISUALIZAR,
                        },
                        {
                          content: 'Confirmar',
                          onClick: () => {
                            handleConfirmarTransferencia(
                              operacaoTransferenciaId
                            );
                          },
                          funcionalidade:
                            ConstanteFuncionalidades.TRANSFERENCIA_ESTOQUE_CONFIRMAR,
                        },
                        {
                          content: 'Rejeitar',
                          onClick: () => {
                            handleRejeitarTransferencia(
                              operacaoTransferenciaId
                            );
                          },
                          funcionalidade:
                            ConstanteFuncionalidades.TRANSFERENCIA_ESTOQUE_REJEITAR,
                        },
                        {
                          content: 'Conferir itens',
                          onClick: () => {
                            ModalConferirItens({
                              id: operacaoOrigemId,
                              casasDecimais,
                            });
                          },
                          funcionalidade: '',
                        },
                      ];
                    }

                    if (status === statusOperacaoTransferenciaEnum.REJEITADA) {
                      dropDownItens = [
                        ...dropDownItens,
                        {
                          content: 'Visualizar',
                          onClick: () => {
                            handlePushVisualizar(operacaoTransferenciaId);
                          },
                          funcionalidade:
                            ConstanteFuncionalidades.TRANSFERENCIA_ESTOQUE_VISUALIZAR,
                        },
                        {
                          content: 'Confirmar',
                          onClick: () => {
                            handleConfirmarTransferencia(
                              operacaoTransferenciaId
                            );
                          },
                          funcionalidade:
                            ConstanteFuncionalidades.TRANSFERENCIA_ESTOQUE_CONFIRMAR,
                        },
                        duplicar,
                      ];
                    }

                    if (status === statusOperacaoTransferenciaEnum.CONFIRMADA) {
                      dropDownItens = [
                        ...dropDownItens,
                        {
                          content: 'Visualizar',
                          onClick: () => {
                            handlePushVisualizar(operacaoTransferenciaId);
                          },
                          funcionalidade:
                            ConstanteFuncionalidades.TRANSFERENCIA_ESTOQUE_VISUALIZAR,
                        },
                        duplicar,
                        {
                          content: 'Imprimir etiquetas',
                          onClick: () => {
                            ModalImpressaoEtiquetaPersonalizada({
                              operacaoId: operacaoOrigemId,
                            });
                          },
                          funcionalidade: '',
                        },
                      ];
                    }

                    if (status === statusOperacaoTransferenciaEnum.CANCELADA) {
                      dropDownItens = [
                        ...dropDownItens,
                        {
                          content: 'Visualizar',
                          onClick: () => {
                            handlePushVisualizar(operacaoTransferenciaId);
                          },
                          funcionalidade:
                            ConstanteFuncionalidades.TRANSFERENCIA_ESTOQUE_VISUALIZAR,
                        },
                      ];
                    }
                  }

                  const dataHora = formatDateHourMinute(dataHoraEmissao);

                  function getNomeLinkHref(id: string) {
                    let href = ConstanteRotas.TRANSFERENCIA_ESTOQUE;
                    const podeAlterar =
                      permissaoTransferenciaEstoqueEditar.permitido &&
                      loja.id === lojaOrigemId &&
                      loja.id !== lojaDestinoId &&
                      (status === statusOperacaoTransferenciaEnum.REJEITADA ||
                        status === statusOperacaoTransferenciaEnum.RASCUNHO);

                    if (podeAlterar) {
                      href = SubstituirParametroRota(
                        ConstanteRotas.TRANSFERENCIA_ESTOQUE_ALTERAR,
                        'id',
                        id
                      );
                    } else if (
                      permissaoTransferenciaEstoqueVisualizar.permitido
                    ) {
                      href = SubstituirParametroRota(
                        ConstanteRotas.TRANSFERENCIA_ESTOQUE_VISUALIZAR,
                        'id',
                        id
                      );
                    }

                    return href;
                  }

                  return (
                    <Tr
                      bg={
                        status === statusOperacaoTransferenciaEnum.PENDENTE
                          ? 'secondary.200'
                          : status === statusOperacaoTransferenciaEnum.REJEITADA
                          ? 'red.100'
                          : ''
                      }
                    >
                      <Td>{dataHora}</Td>
                      <Td>
                        <Link
                          id="link-visualizar-alterar"
                          href={getNomeLinkHref(operacaoTransferenciaId)}
                        >
                          {String(identificador).padStart(3, '0')}
                        </Link>
                      </Td>
                      <Td>
                        <Link
                          id="link-visualizar-alterar"
                          href={getNomeLinkHref(operacaoTransferenciaId)}
                        >
                          {lojaOrigem}
                        </Link>
                      </Td>
                      <Td>{lojaDestino}</Td>
                      <Td>{`${getName(
                        statusOperacaoTransferenciaEnum,
                        status
                      )}`}</Td>
                      <Td>{quantidadeTotal}</Td>
                      <Td>
                        <ActionsMenu
                          items={dropDownItens}
                          isDisabled={dropDownItens.length === 0}
                        />
                      </Td>
                    </Tr>
                  );
                }
              )
            }
          />
        </GridItem>
      </SimpleGridForm>
      <ModalBuscaAvancadaTransferenciaEstoque
        isOpen={isModalBuscaAvancadaOpen}
        setIsOpen={setIsModalBuscaAvancadaOpen}
        currentFilters={currentFilters}
        handleSubmitModalBuscaAvancada={handleSubmitModalBuscaAvancada}
        handleReset={handleReset}
        getLoja={getLoja}
      />
    </>
  );
};

export default TransferenciaEstoqueListar;
