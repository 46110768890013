import React from 'react';
import { Text, Icon, Flex, Button, Stack } from '@chakra-ui/react';
import { IconType } from 'icons';

type ButtonPdvProps = {
  icon: IconType;
  title: string;
  onClick: () => void;
  isDisabled?: boolean;
};

const ButtonAbrirPdv: React.FC<ButtonPdvProps> = ({
  icon,
  title,
  onClick,
  isDisabled,
  ...rest
}) => {
  return (
    <Flex alignItems="center" w="100%" minW="1px">
      <Button
        position="relative"
        w="120px"
        h="120px"
        justifyContent={{ base: 'flex-start', md: 'center' }}
        borderRadius="lg"
        border="1px"
        borderColor="gray.100"
        bg="white"
        boxShadow={isDisabled ? 'none' : 'md'}
        opacity={isDisabled ? 0.4 : 1}
        cursor={isDisabled ? 'not-allowed' : 'pointer'}
        _hover={{ bg: 'white' }}
        _active={{ bg: 'white' }}
        onClick={onClick}
        {...rest}
      >
        <Stack
          direction="column"
          spacing={4}
          alignItems="center"
          justifyContent="center"
          h="100%"
          w="full"
        >
          <Icon as={icon} fontSize="xx-large" color="gray.500" />

          <Text
            whiteSpace="normal"
            maxW={{
              base: 'full',
              md: 'full',
            }}
            fontSize="sm"
            color="gray.700"
          >
            {title}
          </Text>
        </Stack>
      </Button>
    </Flex>
  );
};

export default ButtonAbrirPdv;
