import { useCallback, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { toast } from 'react-toastify';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { ResponseApi } from 'services/api';
import { sistemaApiKey } from 'services/sistemaApiKey';

import { FormData } from './validationForms';

export const useConfiguracao = (formMethods: UseFormReturn<FormData>) => {
  const [isLoading, setIsLoading] = useState(false);

  const { reset, handleSubmit } = formMethods;

  const getConfiguracao = useCallback(async () => {
    setIsLoading(true);
    const response = await sistemaApiKey().get<void, ResponseApi<FormData>>(
      ConstanteEnderecoWebservice.PDV_AUTONOMO_OBTER_CONFIGURACAO
    );

    if (response) {
      if (response.sucesso) {
        reset(response.dados);
      }
      setIsLoading(true);
    }
    setIsLoading(true);
  }, [reset]);

  const handleConfiguracaoUpdate = handleSubmit(async (data) => {
    const response = await sistemaApiKey().post<void, ResponseApi<FormData>>(
      ConstanteEnderecoWebservice.PDV_AUTONOMO_ALTERAR_CONFIGURACAO,
      data
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }
      if (response.sucesso) {
        toast.success('Configuração alterada com sucesso');
      }
    }
  });

  return {
    getConfiguracao,
    isLoading,
    handleConfiguracaoUpdate,
  };
};
