import React, { ReactNode } from 'react';
import {
  Box,
  HStack,
  Text,
  Flex,
  Skeleton,
  SkeletonText,
  Stack,
} from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';

import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';
import StatusFiscaisEnum, {
  CoresStatusFiscalEnum,
  StatusFiscais,
} from 'constants/enum/fiscal/statusFiscal';
import { getName } from 'helpers/enum/getName';

import { TextValor } from 'components/PDV/Text/TextValor';

type DocumentoFiscal = {
  descricao: string;
  status: StatusFiscais;
};

type Operacao = {
  identificacaoTipoOperacao: number;
  numeroOperacao: number;
  valorTotal: number;
  nomeCliente: string;
  NFCe?: DocumentoFiscal;
  NFe?: DocumentoFiscal;
};

interface MenuLateralProps {
  operacao?: Operacao;
  isLoading: boolean;
  children: ReactNode;
}

const coresStatusOperacao = {
  0: CoresStatusFiscalEnum.RETORNO_INDISPONIVEL,
  4: CoresStatusFiscalEnum.AUTORIZADA,
  5: CoresStatusFiscalEnum.CANCELADA,
  6: CoresStatusFiscalEnum.CARTA_DE_CORRECAO,
  1: CoresStatusFiscalEnum.EM_DIGITACAO,
  2: CoresStatusFiscalEnum.EM_PROCESSAMENTO,
  8: CoresStatusFiscalEnum.INUTILIZADA,
  3: CoresStatusFiscalEnum.REJEITADA,
  7: CoresStatusFiscalEnum.USO_DENEGADO,
};

const MenuLateral = ({ operacao, isLoading, children }: MenuLateralProps) => {
  const getOperacaoTitulo = () => {
    if (operacao) {
      switch (operacao.identificacaoTipoOperacao) {
        case IdentificacaoTipoOperacaoEnum.VENDA:
          return `Venda ${operacao.numeroOperacao}`;
        case IdentificacaoTipoOperacaoEnum.PEDIDO:
          return `Pedido ${operacao.numeroOperacao}`;
        case IdentificacaoTipoOperacaoEnum.ORCAMENTO:
          return `Orçamento ${operacao.numeroOperacao}`;
        case IdentificacaoTipoOperacaoEnum.DEVOLUCAO:
          return `Devolução ${operacao.numeroOperacao}`;
        case IdentificacaoTipoOperacaoEnum.CONSIGNACAO:
          return `Consignação ${operacao.numeroOperacao}`;

        default:
          break;
      }
    }

    return undefined;
  };

  return (
    <Box
      bg="primary.700"
      px={{ base: 4, lg: 6, xl: 8 }}
      pt={{ base: '12', md: isMobile ? '5' : '12' }}
    >
      <Stack
        direction={{
          base: 'column',
          sm: 'row',
          md: isMobile ? 'row' : 'column',
        }}
        alignItems={{
          base: 'flex-start',
          sm: 'flex-end',
          md: isMobile ? 'flex-end' : 'flex-start',
        }}
        px={8}
        spacing={{ base: '2', md: isMobile ? '16' : '2' }}
      >
        <Skeleton
          isLoaded={!isLoading && !!operacao}
          height="20px"
          whiteSpace="nowrap"
          w="auto"
        >
          <Text color="secondary.300" fontSize="lg" mb={0.5}>
            {getOperacaoTitulo()}
          </Text>
        </Skeleton>

        {(isLoading || !operacao) && (
          <SkeletonText
            isLoaded={!isLoading && !!operacao}
            spacing={2}
            noOfLines={3}
          />
        )}
        {!isLoading && operacao && (
          <>
            <HStack>
              <Text lineHeight="none" color="purple.200" fontSize="xs">
                Valor:
              </Text>
              <TextValor
                valor={operacao.valorTotal ?? 0}
                casasDecimais={2}
                fontSize="sm"
                color="purple.50"
                lineHeight="none"
              />
            </HStack>
            <HStack>
              <Text lineHeight="none" color="purple.200" fontSize="xs">
                Cliente:
              </Text>
              <Text fontSize="sm" lineHeight="none" color="purple.50">
                {operacao.nomeCliente}
              </Text>
            </HStack>
            {operacao.NFCe && (
              <HStack>
                <Text lineHeight="none" color="purple.200" fontSize="xs">
                  NFC-e:
                </Text>
                <Text
                  fontSize="sm"
                  lineHeight="none"
                  color={coresStatusOperacao[operacao.NFCe.status]}
                >
                  {operacao.NFCe
                    ? getName(StatusFiscaisEnum, operacao?.NFCe?.status)
                    : ''}
                </Text>
              </HStack>
            )}
            {operacao.NFe && (
              <HStack>
                <Text lineHeight="none" color="purple.200" fontSize="xs">
                  NF-e:
                </Text>
                <Text
                  fontSize="sm"
                  lineHeight="none"
                  color={coresStatusOperacao[operacao.NFe.status]}
                >
                  {operacao.NFe
                    ? getName(StatusFiscaisEnum, operacao?.NFe?.status)
                    : ''}
                </Text>
              </HStack>
            )}
          </>
        )}
      </Stack>

      <Flex
        flexDirection="column"
        mt={6}
        borderTop="1px"
        borderBottom="1px"
        borderColor="primary.900"
      >
        {children}
      </Flex>
    </Box>
  );
};

export default MenuLateral;
