import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DropResult } from 'react-smooth-dnd';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { shallowEqual } from 'helpers/validation/shallowEqual';

import { ModalDesistenciaOrdenacaoMenu } from '../../components/ModalDesistenciaOrdenacaoMenu';

type Categoria = {
  id: string;
  nome: string;
  categoriaProdutoPaiId: string;
  categoriaFomerVM: {
    sincronizarNoAplicativo: boolean;
    ocultarNoAplicativo: boolean;
    permiteFracionamento: boolean;
    criterioExibicao: number;
  };
};

const useOrdenacaoMenu = (
  permiteAlterarTabRota: boolean,
  handleSetPermiteAlterarTabRota: (value: boolean) => void
) => {
  const history = useHistory();
  const handleAlterarRota = useRef<(() => void) | null>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [categorias, setCategorias] = useState<Categoria[]>([]);

  const deveAtivarPrompt = !permiteAlterarTabRota;

  const obterCategorias = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get<void, ResponseApi<Categoria[]>>(
      ConstanteEnderecoWebservice.CATEGORIA_PRODUTO_LISTAR_PRIMEIRO_NIVEL
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response?.sucesso && response?.dados) {
        setCategorias(response.dados);
      }
    }
    setIsLoading(false);
  }, []);

  const handleAtualizarOrdenacaoMenu = useCallback(async () => {
    setIsLoading(true);
    const params = categorias.map((categoria, index) => ({
      categoriaProdutoId: categoria.id,
      ordemExibicao: index + 1,
    }));

    const response = await api.put<void, ResponseApi>(
      ConstanteEnderecoWebservice.CATEGORIA_PRODUTO_ORDEM_EXIBICAO,
      [...params]
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response?.sucesso) {
        handleSetPermiteAlterarTabRota(true);
        toast.success('Ordenação do menu alterada com sucesso');
      } else {
        await obterCategorias();
      }
      setIsLoading(false);
    }
  }, [categorias, handleSetPermiteAlterarTabRota, obterCategorias]);

  const ordernarLista = (dragResult: DropResult) => {
    const { removedIndex, addedIndex } = dragResult;

    if (removedIndex === null && addedIndex === null) {
      return categorias;
    }

    const novasCategorias = [...categorias];

    const [itemAdicionado] =
      removedIndex !== null ? novasCategorias.splice(removedIndex, 1) : [];

    if (addedIndex !== null && itemAdicionado) {
      novasCategorias.splice(addedIndex, 0, itemAdicionado);
    }

    if (!shallowEqual(categorias, novasCategorias)) {
      handleSetPermiteAlterarTabRota(false);
      setCategorias(novasCategorias);
    }

    return null;
  };

  useEffect(() => {
    if (!deveAtivarPrompt) {
      return () => {};
    }

    handleAlterarRota.current = history.block(({ pathname }) => {
      if (deveAtivarPrompt) {
        ModalDesistenciaOrdenacaoMenu({
          callback: () => {
            if (handleAlterarRota.current) {
              handleAlterarRota.current();
            }

            history.push(pathname);
          },
        });

        return false;
      }

      return '';
    });

    return () => {
      if (handleAlterarRota.current) {
        handleAlterarRota.current();
      }
    };
  }, [history, deveAtivarPrompt]);

  useEffect(() => {
    obterCategorias();
  }, [obterCategorias]);

  return {
    isLoading,
    categorias,
    handleAtualizarOrdenacaoMenu,
    ordernarLista,
  };
};

export { useOrdenacaoMenu };
