import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import StatusConsultaEnum from 'constants/enum/statusConsulta';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import TipoFiltroProdutoEstoqueEnum from 'constants/enum/tipoFiltroProdutoEstoque';

export type CamposPersonalizados = {
  campoPersonalizadoId: string;
  valor: string;
};

export type FormData = {
  tipoRelatorio: number;
  nomeSkuCodigoExternoBarrasGtinEan: string;
  tipoEstoque: number;
  statusConsulta: number;
  cores: string;
  tamanhos: string;
  categoriasProduto: string;
  marcas: string;
  tags: string;
  camposPersonalizados: CamposPersonalizados[];
};

export const formDefaultValues = {
  statusConsulta: StatusConsultaEnum.TODOS,
  tipoEstoque: TipoFiltroProdutoEstoqueEnum.TODOS,
};

const schema = yup.object().shape({
  tipoRelatorio: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);
