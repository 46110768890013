import React, { useState } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useMediaQuery,
  useDisclosure,
  Image,
  Box,
  Text,
  Flex,
  Grid,
  ModalFooter,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';

import { IconesStorage } from 'store/FormaPagamento/FormaPagamentoFormulario';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';

type ModalIconeProps = Omit<ModalProps, 'children' | 'isOpen' | 'onClose'> &
  InstanceProps<any> & {
    urlPreview: string;
    onSubmit?: () => void;
    urlPreviewList: IconesStorage[] | undefined;
    setUrlPreview?: React.Dispatch<React.SetStateAction<string>>;
  };

export const ModalEditarIcones = create<ModalIconeProps>(
  ({
    onResolve,
    onReject,
    urlPreview,
    urlPreviewList,
    setUrlPreview,
    onSubmit,
    ...rest
  }) => {
    const [isSmallerThan700] = useMediaQuery('(max-width: 700px)');
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const [urlSelecionada, setUrlSelecionada] = useState(urlPreview);

    function handleNovaUrlSelecionada(novaUrl: string) {
      setUrlSelecionada(novaUrl);
    }

    function handleSelecionarItem() {
      if (urlPreviewList && setUrlPreview) {
        setUrlPreview(urlSelecionada);
        onClose();
      }
    }

    return (
      <ModalPadraoChakra
        isCentered
        size="2xl"
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalContent w={['450px', '620px']}>
          <ModalBody padding="3" pl="10" pr="10">
            <Flex justifyContent="space-between">
              <Box>
                <Text mb="15px" fontSize="16px" color="primary.50">
                  Selecione um ícone
                </Text>
              </Box>
            </Flex>
            <Flex
              borderTop="1px solid #CCCCCC"
              borderBottom="1px solid #CCCCCC"
            >
              <Flex
                mt="20px"
                mb="20px"
                justifyContent="center"
                alignItems="center"
                flex="1"
              >
                <Grid
                  templateColumns={['repeat(4, 2fr)', 'repeat(5, 2fr)']}
                  gap={6}
                >
                  {(urlPreviewList || []).map((icone) => {
                    return (
                      <Button
                        _active={{
                          bg: 'null',
                          transform: 'null',
                          borderColor: 'none',
                        }}
                        _hover={{ bg: 'null' }}
                        h={['60px', '80px']}
                        w={['60px', '80px']}
                        borderRadius="50px"
                        bg="null"
                        onClick={() => handleNovaUrlSelecionada(icone.url)}
                      >
                        <Image
                          position="absolute"
                          h={['60px', '80px']}
                          w={['60px', '80px']}
                          objectFit="cover"
                          src={icone.url}
                        />
                      </Button>
                    );
                  })}
                </Grid>
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter
            mt="-5px"
            mr="20px"
            justifyContent={isSmallerThan700 ? 'center' : 'flex-end'}
          >
            <Flex>
              <Button
                id="cancelar"
                name="cancelar"
                colorScheme="gray.100"
                variant="outline"
                borderRadius="19px"
                fontSize="sm"
                type="button"
                w="96px"
                h="8"
                mr={6}
                _hover={{ bg: 'gray.50' }}
                onClick={onClose}
              >
                Cancelar
              </Button>
              <Button
                id="pesquisar"
                name="pesquisar"
                w="110px"
                h="8"
                backgroundColor="aquamarine.600"
                borderRadius="19px"
                fontSize="sm"
                color="white"
                variant="success"
                type="button"
                onClick={() => handleSelecionarItem()}
                _hover={{ bg: 'aquamarine.700' }}
              >
                Salvar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
