import {
  Box,
  Flex,
  Td,
  Text,
  HStack,
  Checkbox,
  Tr,
  Tooltip,
} from '@chakra-ui/react';

import auth from 'modules/auth';
import { moneyMask } from 'helpers/format/fieldsMasks';
import { formatDateHourMinute } from 'helpers/format/formatStringDate';
import ConstanteFuncionalidades from 'constants/permissoes';
import { useContextConciliacaoContas } from 'store/Financeiro/ConciliacaoContas';

import { TableHeader } from 'components/update/Table/VirtualizedInfiniteTable';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import { DateInput } from 'components/update/Input/DateInput';
import { NumberInput } from 'components/update/Input/NumberInput';
import VirtualizedInfiniteList from 'components/update/Table/VirtualizedInfiniteList';

import { AntecipacaoTooltip } from './components/AntecipacaoTooltip';
import { ConciliadoIconButton } from './components/ConciliadoIconButton';
import { ContaCheckbox } from './components/ContaCheckbox';

import { useListagemContasConcilicao } from '../hooks';

export const Listagem = () => {
  const {
    isLoading,
    listContas,
    isListContaEmpty,
    hasSomenteContasConciliadas,
    isAllContasSelected,
    refVirtualizedTable,
    totalizadoresListagem: { quantidadeContas },
    loadMoreRows,
    handleConciliarUmaConta,
    handleDesconciliar,
    openModalAvisoCancelamentoAntecipacao,
  } = useContextConciliacaoContas();

  const {
    toggleSelectAll,
    toggleSelect,
    calculateLiquido,
    changeDateCompensacao,
  } = useListagemContasConcilicao();

  const possuiPermissaoAlterarListagem = auth.possuiPermissao(
    ConstanteFuncionalidades.CONTAS_RECEBER_ALTERAR
  ).permitido;

  const tableHeaders: TableHeader[] = [
    {
      key: 'checkboxSelecionarTodos',
      content: (
        <Checkbox
          onChange={toggleSelectAll}
          isChecked={
            isListContaEmpty || hasSomenteContasConciliadas
              ? false
              : isAllContasSelected
          }
          colorScheme="primary"
          isDisabled={
            isListContaEmpty ||
            hasSomenteContasConciliadas ||
            !possuiPermissaoAlterarListagem
          }
          mb="0"
          boxShadow="inset 0px 3px 6px #00000029"
          bg="white"
        />
      ),
      minWidth: '90px',
      width: '5%',
    },
    {
      key: 'dataEmissaoOperacao',
      content: 'Emissão',
      minWidth: '100px',
      width: '10%',
    },
    {
      key: 'dataVencimento',
      content: 'Vencimento',
      minWidth: '100px',
      width: '10%',
    },
    {
      key: 'formaDeRecebimento',
      content: 'Forma de recebimento',
      minWidth: '180px',
      width: '10%',
    },
    {
      key: 'dataCompensacao',
      content: 'Compensação',
      minWidth: '180px',
      width: '10%',
    },
    {
      key: 'valorBruto',
      content: 'Valor bruto',
      isNumeric: true,
      minWidth: '100px',
      width: '10%',
    },
    {
      key: 'taxa',
      content: 'Taxa',
      isNumeric: true,
      minWidth: '100px',
      width: '10%',
    },
    {
      key: 'valorLiquido',
      content: 'Valor líquido',
      paddingInlineEnd: '24px !important',
      isNumeric: true,
      minWidth: '100px',
      width: '10%',
    },
    {
      key: 'acoes',
      content: 'Ações',
      isNumeric: true,
      justifyContent: 'flex-end',
      textAlign: 'end',
      minWidth: '90px',
      width: '10%',
    },
  ];

  return (
    <Box
      sx={{
        '& th': {
          border: 'none',
          whiteSpace: 'nowrap',
          borderRadius: '0px',
        },
        '& > div': {
          borderRadius: '0px',
          background: 'transparent',
          border: 'none',
          boxShadow: 'none',
          padding: '0px',
          margin: '0px',
          overflow: 'scroll',
        },
        '& tr th:first-of-type': {
          borderRadius: '0px',
        },
        '& tr th:last-of-type': {
          borderRadius: '0px',
          paddingRight: '48px',
          textAlign: 'end',
        },
        '& thead tr th': {
          backgroundColor: 'transparent',
          fontSize: '2xs',
          mt: '8px',
          pt: '12px',
          pb: '4px',
        },
        '& td': {
          backgroundColor: isListContaEmpty ? 'white' : 'transparent',
          borderBottomRadius: isListContaEmpty ? '6px' : '0px',
          height: isListContaEmpty ? '60px' : '96px',
        },
      }}
    >
      <VirtualizedInfiniteList
        isLoading={isLoading}
        orderColumn="DataEmissaoOperacao"
        orderDirection="desc"
        ref={refVirtualizedTable}
        visibleRowCount={10}
        rowHeight={58}
        columns={tableHeaders}
        pageSize={25}
        borderGradientColor={
          hasSomenteContasConciliadas
            ? 'orange.50'
            : isAllContasSelected
            ? 'purple.50'
            : 'white'
        }
        totalRowCount={quantidadeContas || 0}
        isRowLoaded={({ index }) => !!listContas[index]}
        loadMoreRows={loadMoreRows}
        key="listagemContasConciliacao"
        rowRenderer={({ index, style: { height, ...restStyle }, key }) => {
          const contaItem = listContas[index];
          const contaIsChecked = contaItem?.isChecked;
          if (!contaItem) {
            return null;
          }

          const acoes = (() => {
            const acaoCancelarAntecipacao = [
              {
                content: 'Cancelar antecipação',
                funcionalidade: ConstanteFuncionalidades.CONTAS_RECEBER_ALTERAR,
                onClick: () =>
                  openModalAvisoCancelamentoAntecipacao(
                    contaItem.idMovimentacaoFinanceiraBaixa
                  ),
              },
            ];
            if (contaItem.dataAntecipacao) return acaoCancelarAntecipacao;

            const actions = [
              {
                content: contaItem.conciliado ? 'Desconciliar' : 'Conciliar',
                funcionalidade: ConstanteFuncionalidades.CONTAS_RECEBER_ALTERAR,
                onClick: contaItem.conciliado
                  ? () =>
                      handleDesconciliar(
                        contaItem.idMovimentacaoFinanceiraBaixa
                      )
                  : () => handleConciliarUmaConta(contaItem),
              },
            ];

            return actions;
          })();

          return (
            <Tr
              key={key}
              style={restStyle}
              color="gray.700"
              borderBottom="1px"
              borderColor="gray.100"
              bg={
                contaItem.conciliado
                  ? 'orange.50'
                  : contaIsChecked
                  ? 'purple.50'
                  : 'white'
              }
              w="full"
            >
              <Td minW={tableHeaders[0].minWidth} width={tableHeaders[0].width}>
                {contaItem.dataAntecipacao ? (
                  <AntecipacaoTooltip
                    dataAntecipacao={contaItem.dataAntecipacao}
                  />
                ) : contaItem.conciliado ? (
                  <ConciliadoIconButton
                    onClick={() =>
                      handleDesconciliar(
                        contaItem.idMovimentacaoFinanceiraBaixa
                      )
                    }
                    isLoading={isLoading}
                  />
                ) : (
                  <ContaCheckbox
                    idMovimentacaoFinanceiraBaixa={
                      contaItem.idMovimentacaoFinanceiraBaixa
                    }
                    contaIsChecked={contaIsChecked}
                    possuiPermissaoAlterarListagem={
                      possuiPermissaoAlterarListagem
                    }
                    toggleSelect={toggleSelect}
                  />
                )}
              </Td>

              <Td minW={tableHeaders[1].minWidth} width={tableHeaders[1].width}>
                <Text>
                  {formatDateHourMinute(contaItem.dataEmissaoOperacao).slice(
                    0,
                    11
                  )}
                </Text>
              </Td>
              <Td minW={tableHeaders[2].minWidth} width={tableHeaders[2].width}>
                <Flex flexDir="column" gap="4px" w="full">
                  {formatDateHourMinute(contaItem.dataVencimento)?.slice(0, 11)}
                  <Text fontSize="xs" color="gray.400">
                    {contaItem.parcelaExibicao
                      ? `Parcela: ${contaItem.parcelaExibicao}`
                      : ''}{' '}
                  </Text>
                </Flex>
              </Td>
              <Td minW={tableHeaders[3].minWidth} width={tableHeaders[3].width}>
                <Flex flexDir="column" gap="4px">
                  <Text fontSize="sm">{contaItem.formaRecebimentoNome}</Text>

                  <Text fontSize="xs" color="gray.400">
                    {contaItem.lancamentoIdentificador}
                  </Text>
                </Flex>
              </Td>
              <Td minW={tableHeaders[4].minWidth} width={tableHeaders[4].width}>
                {contaItem.conciliado || !possuiPermissaoAlterarListagem ? (
                  <Text fontSize="sm">
                    {formatDateHourMinute(contaItem?.dataCompensacao)?.slice(
                      0,
                      11
                    )}
                  </Text>
                ) : (
                  <Flex justify="center">
                    <DateInput
                      placeholder=""
                      isDisabled={contaItem.conciliado}
                      name={`dataCompensacao.${contaItem?.idMovimentacaoFinanceiraBaixa}`}
                      id={`dataCompensacao.${contaItem?.idMovimentacaoFinanceiraBaixa}`}
                      size="sm"
                      justifyContent="center"
                      onValueChange={() => changeDateCompensacao(contaItem)}
                    />
                  </Flex>
                )}
              </Td>
              <Td
                minW={tableHeaders[5].minWidth}
                width={tableHeaders[5].width}
                isNumeric
              >
                <Text fontWeight="bold">
                  <Tooltip
                    borderRadius="md"
                    w="44"
                    alignItems="center"
                    h="28"
                    display="flex"
                    justifyContent="left"
                    placement="left"
                    gutter={15}
                    hasArrow
                    fontSize="2xs"
                    bg="gray.900"
                    color="white"
                    label={
                      <Box ml="2">
                        <Flex alignItems="center">
                          <Text mr="2">Valor bruto:</Text>
                          <Text fontSize="xs">
                            {moneyMask(contaItem.valor, true)}
                          </Text>
                        </Flex>

                        <Flex mt="1" alignItems="center">
                          <Text mr="2">Multa:</Text>
                          <Text fontSize="xs" color="red.100">
                            {moneyMask(contaItem.multa, true)}
                          </Text>
                        </Flex>
                        <Flex mt="1" alignItems="center">
                          <Text mr="2">Juros:</Text>
                          <Text fontSize="xs" color="red.100">
                            {moneyMask(contaItem.juros, true)}
                          </Text>
                        </Flex>
                      </Box>
                    }
                  >
                    {moneyMask(contaItem.valor, true)}
                  </Tooltip>
                </Text>
              </Td>
              <Td
                minW={tableHeaders[6].minWidth}
                width={tableHeaders[6].width}
                userSelect="none"
              >
                <HStack spacing={2} w="full" justify="flex-end">
                  {contaItem.conciliado || !possuiPermissaoAlterarListagem ? (
                    <Text>{moneyMask(contaItem.taxa, true)}</Text>
                  ) : (
                    <NumberInput
                      id={`taxa.${contaItem.idMovimentacaoFinanceiraBaixa}`}
                      name={`taxa.${contaItem.idMovimentacaoFinanceiraBaixa}`}
                      scale={2}
                      maxW="100px"
                      size="sm"
                      max={contaItem.valor}
                      isDisabled={contaItem.conciliado}
                      onBlur={() => calculateLiquido(contaItem)}
                    />
                  )}
                </HStack>
              </Td>
              <Td
                minW={tableHeaders[7].minWidth}
                width={tableHeaders[7].width}
                isNumeric
                pr="24px"
              >
                {moneyMask(contaItem.valor - contaItem.taxa, true)}{' '}
              </Td>

              <Td
                minW={tableHeaders[8].minWidth}
                width={tableHeaders[8].width}
                isNumeric
              >
                <Flex justifyContent="flex-end" w="full">
                  <ActionsMenu items={acoes} />
                </Flex>
              </Td>
            </Tr>
          );
        }}
      />
    </Box>
  );
};
