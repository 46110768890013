import { useState, useCallback, useEffect } from 'react';
import { Flex, Text, Icon, Box, Checkbox } from '@chakra-ui/react';
import { IoIosArrowRoundDown, IoIosArrowRoundUp } from 'react-icons/io';
import { toast } from 'react-toastify';

import {
  CategoriaProdutoProvider,
  CategoriaProdutoProps,
  CategoriaProdutoType,
} from 'store/SmartPos/CategoriaProdutos';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import { CategoriaComponenteItem } from './CategoriaComponenteItem';

type CategoriaProdutoComponentProps = {
  obterCategoriaSelecionada: (categorias: CategoriaProdutoProps[]) => void;
  voltarAlteracoes?: boolean;
  isSelecionarTodos?: boolean;
  categoriaIsAlterada?: (categoria: boolean) => void;
};

export const CategoriaProduto = ({
  obterCategoriaSelecionada,
  voltarAlteracoes = false,
  isSelecionarTodos = false,
  categoriaIsAlterada,
}: CategoriaProdutoComponentProps) => {
  const [categorias, setCategorias] = useState<CategoriaProdutoProps[]>([]);
  const [categoriasIdSelecionaveis, setCategoriasIdSelecionaveis] = useState<
    string[]
  >([]);
  const [isExpandirTodosNiveis, setIsExpandirTodosNiveis] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [selecionarTodasCategorias, setSelecionarTodasCategorias] = useState(
    false
  );
  const [categoriaAlterada, setCategoriaAlterada] = useState(false);

  const handleExpandirItens = () => {
    setIsExpandirTodosNiveis(!isExpandirTodosNiveis);
  };

  const handleToggleSelecionarTodosItens = () => {
    setSelecionarTodasCategorias(!selecionarTodasCategorias);
    setCategoriaAlterada(true);
    setCategorias((prev) =>
      prev.map((item) => {
        return {
          ...item,
          isChecked: !selecionarTodasCategorias,
        };
      })
    );
  };

  const handleGetCategoriaProdutoOptions = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<void, ResponseApi<CategoriaProdutoProps[]>>(
      ConstanteEnderecoWebservice.CATEGORIA_PRODUTO_INTEGRACAO_SELECT,
      { params: { ativo: true } }
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response?.sucesso) {
        const categoriasSelecionaveis: CategoriaProdutoType[] = response.dados.filter(
          (categoriaProduto) =>
            !response.dados.find(
              (categoriaProdutoInterna) =>
                categoriaProdutoInterna.categoriaProdutoPaiId ===
                categoriaProduto.id
            )
        );

        setCategoriasIdSelecionaveis(
          categoriasSelecionaveis.map(
            (categoriaSelecionavel) => categoriaSelecionavel.id
          )
        );
        const allCategoriaProdutosNaoSelecionadas = response.dados.every(
          (categoriaSelecionavel) => categoriaSelecionavel.selecionado === false
        );

        if (allCategoriaProdutosNaoSelecionadas) {
          setCategorias(
            response.dados.map((item) => {
              return {
                ...item,
                isChecked: true,
              };
            })
          );
        } else {
          setCategorias(
            response.dados.map((item) => {
              return {
                ...item,
                isChecked: item.selecionado,
              };
            })
          );
        }

        setIsLoading(false);
      }
    }

    setIsLoading(false);
  }, [setCategorias]);

  useEffect(() => {
    handleGetCategoriaProdutoOptions();
  }, [handleGetCategoriaProdutoOptions, voltarAlteracoes]);

  useEffect(() => {
    obterCategoriaSelecionada(categorias);
  }, [categorias, obterCategoriaSelecionada]);

  useEffect(() => {
    if (categoriaIsAlterada) {
      categoriaIsAlterada(categoriaAlterada);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriaAlterada]);

  useEffect(() => {
    const todosItensSelecionados = categorias.every(
      (categoriaItem) => categoriaItem.isChecked
    );
    setSelecionarTodasCategorias(todosItensSelecionados);
  }, [categorias]);

  return (
    <CategoriaProdutoProvider
      setCategorias={setCategorias}
      categorias={categorias}
      isLoading={isLoading}
      categoriasIdSelecionaveis={categoriasIdSelecionaveis}
      isItemFoiExpandido={!isExpandirTodosNiveis}
      setCategoriaAlterada={setCategoriaAlterada}
    >
      <Flex
        cursor="pointer"
        alignItems="center"
        pb="5px"
        w="full"
        justifyContent={isSelecionarTodos ? 'space-between' : 'flex-end'}
      >
        {isSelecionarTodos && (
          <Flex
            pl={['30px', '30px', '45px']}
            gap="12px"
            w="full"
            alignItems="center"
            cursor="pointer"
          >
            <Checkbox
              colorScheme="primary"
              mb="0px"
              isChecked={selecionarTodasCategorias}
              onChange={() => handleToggleSelecionarTodosItens()}
            />
            <Text
              onClick={() => handleToggleSelecionarTodosItens()}
              fontSize="md"
            >
              Selecionar todas as categorias
            </Text>
          </Flex>
        )}
        <Box onClick={() => handleExpandirItens()}>
          <Icon
            as={isExpandirTodosNiveis ? IoIosArrowRoundDown : IoIosArrowRoundUp}
            boxSize="20px"
          />
        </Box>
        <Box onClick={() => handleExpandirItens()}>
          <Text whiteSpace="nowrap" fontSize="14px">{`${
            isExpandirTodosNiveis ? 'Expandir' : 'Recolher'
          } todos os níveis`}</Text>
        </Box>
      </Flex>

      <CategoriaComponenteItem nivel={1} />
    </CategoriaProdutoProvider>
  );
};
