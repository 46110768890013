import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Text,
  Tr,
  Td,
  Icon,
  Box,
  HStack,
  Flex,
  Button,
  useMediaQuery,
  useDisclosure,
} from '@chakra-ui/react';
import { CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { FormProvider, useForm } from 'react-hook-form';
import { Prompt, RouteComponentProps, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import auth from 'modules/auth';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteRotas from 'constants/rotas';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';
import isPrenvent from 'helpers/layout/isPrenvent';
import { DecimalMask } from 'helpers/format/fieldsMasks';

import {
  VirtualizedInfiniteTable,
  LoadMoreRowsParams,
  ForwardRefData,
} from 'components/update/Table/VirtualizedInfiniteTable';
import { NumberInput } from 'components/update/Input/NumberInput';
import { ModalPadronizarMarkup } from 'components/Modal/ModalPadronizarMarkup';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { ResolucaoNaoCompativel } from 'pages/ResolucaoNaoCompativel';

import {
  FormData,
  Produto,
  ProdutoResponse,
  LojaProps,
  yupResolver,
} from './validationForm';

import { produtosTableHeaders } from './tableHeaders';
import { ProdutoInfo } from './ProdutoInfo';
import { ModalAplicarConfigLojas } from './ModalAplicarConfigLojas';
import { DrawerEditarConfig } from './DrawerEditarConfig';

type TParams = { id: string };

export function LancarEstoqueEntradaMercadoria({
  match,
}: RouteComponentProps<TParams>) {
  const [isLoading, setIsLoading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [lojas, setLojas] = useState<LojaProps[]>([]);
  const [isProdutosLoading, setIsProdutosLoading] = useState(false);

  const { casasDecimais } = usePadronizacaoContext();

  const produtoRef = useRef<ForwardRefData>(null);
  const casaDecimalRef = useRef(casasDecimais);

  const [isLargerThan1200] = useMediaQuery('(min-width: 1200px)');
  const [isLargerThan800] = useMediaQuery('(min-width: 800px)');

  const { isOpen, onOpen, onClose } = useDisclosure();

  const history = useHistory();
  const entradaMercadoriaId = match.params.id;

  const lojaAtualId = auth?.getLoja()?.id;
  const { userId = undefined } = auth.getDadosToken() || {};

  const cache = new CellMeasurerCache({
    defaultHeight: 136,
    minHeight: 56,
    fixedWidth: true,
  });

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues: {
      desconsiderarDescontos: false,
      manterCustoAtual: false,
      manterPrecoVenda: false,
      produtos: [],
    },
  });

  const { watch, getValues, setValue, handleSubmit } = formMethods;

  const produtos = watch('produtos');

  isPrenvent(formIsDirty);

  const hasVariasLojasDisponiveis =
    (lojas || []).filter((loja) => !loja.bloqueada)?.length > 1;

  const lojasSelecionadas = lojas?.filter((loja) => loja.selecionada);

  const [
    manterPrecoVendaWatch,
    manterCustoAtualWatch,
    desconsiderarDescontosWatch,
  ] = watch(['manterPrecoVenda', 'manterCustoAtual', 'desconsiderarDescontos']);

  // função que verifica dinamicamente a altura da linha
  function getDynamicHeight(index: number, marginSize: number) {
    const isLastItem = index === produtos.length - 1;
    const produtoItem = produtos[index];
    const produtoHeight = produtoItem.height + (isLastItem ? 0 : marginSize);

    const closedProdutoHeight = produtoHeight;
    const openedProdutoHeight = produtoHeight + (isLargerThan1200 ? 135 : 200);

    return produtoItem?.isOpen ? openedProdutoHeight : closedProdutoHeight;
  }

  function handleToggleLinhaProduto(index: number) {
    const produto = produtos[index];

    const novosProdutos = [...produtos];

    novosProdutos.splice(index, 1, {
      ...produto,
      isOpen: !produto.isOpen,
    });

    setValue('produtos', novosProdutos);
  }

  const getNewCustoMedio = useCallback(
    (produto: Produto) => {
      const [manterCustoAtual, desconsiderarDescontos] = getValues([
        'manterCustoAtual',
        'desconsiderarDescontos',
      ]);
      const { custoMedioNovo, custoMedioSemDesconto, precoCusto } = produto;

      const custoPossuiDesconto = desconsiderarDescontos
        ? custoMedioSemDesconto
        : custoMedioNovo;

      const valueCustoMedio = manterCustoAtual
        ? precoCusto
        : custoPossuiDesconto;

      return valueCustoMedio;
    },
    [getValues]
  );

  function recalcularValoresIndividual(
    index: number,
    value: number,
    calcularMarkup: boolean,
    calcularPrecoVenda: boolean
  ) {
    const produto = produtos[index];

    const newCustoMedio = getNewCustoMedio(produto);

    const precoVendaNovo = calcularPrecoVenda
      ? newCustoMedio + newCustoMedio * (value / 100)
      : produto.precoVendaNovo;

    const markupNovo = calcularMarkup
      ? (value / newCustoMedio - 1) * 100
      : produto.markupNovo;
    const novoValorMarkupValidado = markupNovo === Infinity ? 0 : markupNovo;
    const novosProdutos = [...produtos];

    novosProdutos.splice(index, 1, {
      ...produto,
      markupNovo: parseFloat(novoValorMarkupValidado.toFixed(4)),
      precoVendaNovo: parseFloat(
        precoVendaNovo.toFixed(casaDecimalRef.current.casasDecimaisValor)
      ),
    });

    setValue('produtos', novosProdutos);
    setFormIsDirty(true);
  }

  const handleRecalcularTodosValores = useCallback(() => {
    const manterPrecoVenda = getValues('manterPrecoVenda');

    const novosProdutos = produtos.map((produto) => {
      const newCustoMedio = getNewCustoMedio(produto);

      const newPrecoVenda = manterPrecoVenda
        ? produto.precoVenda
        : newCustoMedio + newCustoMedio * (produto.markup / 100);

      const newMarkupNovo = manterPrecoVenda
        ? (produto.precoVenda / newCustoMedio - 1) * 100
        : produto.markup;

      const novoValorMarkupValidado =
        newMarkupNovo === Infinity ? 0 : newMarkupNovo;

      return {
        ...produto,
        precoVendaNovo: parseFloat(
          newPrecoVenda.toFixed(casaDecimalRef.current.casasDecimaisValor)
        ),
        markupNovo: parseFloat(novoValorMarkupValidado.toFixed(4)),
      };
    });

    setValue('produtos', novosProdutos);
    setFormIsDirty(true);
  }, [getValues, produtos, setValue, getNewCustoMedio]);

  const padronizarMarkup = useCallback(
    (valueMarkup: number) => {
      produtos.forEach((produto, index) => {
        const newValueCustoMedio = getNewCustoMedio(produto);
        const valorPrecoVendaNovo =
          newValueCustoMedio + newValueCustoMedio * (valueMarkup / 100);

        setValue(
          `produtos.${index}.markupNovo`,
          parseFloat(valueMarkup.toFixed(4))
        );

        setValue(
          `produtos.${index}.precoVendaNovo`,
          parseFloat(
            valorPrecoVendaNovo.toFixed(
              casaDecimalRef.current.casasDecimaisValor
            )
          )
        );
      });
    },
    [getNewCustoMedio, produtos, setValue]
  );

  const aplicarConfigEmVariasLojas = useCallback((listLojas: LojaProps[]) => {
    setLojas(listLojas);
  }, []);

  const handleAbrirModalPadronizarMarkup = useCallback(() => {
    ModalPadronizarMarkup({ padronizarMarkup });
    onClose();
  }, [onClose, padronizarMarkup]);

  const handleAbrirModalAplicarConfigLojas = useCallback(() => {
    ModalAplicarConfigLojas({ aplicarConfigEmVariasLojas, lojas });
    onClose();
  }, [aplicarConfigEmVariasLojas, lojas, onClose]);

  const handleGetLojas = useCallback(async () => {
    if (userId) {
      setIsLoading(true);

      const response = await api.get<void, ResponseApi<LojaProps[]>>(
        ConstanteEnderecoWebservice.LOJA_LISTAR_LOJAUSUARIO,
        {
          params: {
            usuarioId: userId,
          },
        }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso: string) => toast.warning(aviso));
        }
        if (response.sucesso && response.dados) {
          setLojas(() => {
            return response.dados?.map((loja) => ({
              ...loja,
              selecionada: lojaAtualId === loja.id,
              isLojaAtual: lojaAtualId === loja.id,
            }));
          });
        }
      }
      setIsLoading(false);
    }
  }, [lojaAtualId, userId]);

  const handleConfirmarEntradaEstoque = handleSubmit(async (data) => {
    setIsLoading(true);

    const response = await api.put<void, ResponseApi>(
      ConstanteEnderecoWebservice.ENTRADA_MERCADORIA_LANCAR_ESTOQUE,
      {
        id: entradaMercadoriaId,
        lojas: lojasSelecionadas.map((loja) => loja.id),
        lancamentos: data.produtos.map((produto) => ({
          produtoId: produto.id,
          markup: produto.markupNovo || 0,
          precoCusto: getNewCustoMedio(produto) || 0,
          precoVenda: produto.precoVendaNovo || 0,
        })),
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }

      if (response.sucesso) {
        toast.success('O lançamento em estoque foi feito com sucesso.');

        setFormIsDirty(false);
        history.push(ConstanteRotas.ENTRADA_MERCADORIA);

        return;
      }
    }

    setIsLoading(false);
  });

  const latestProps = useRef({ setValue });
  useEffect(() => {
    latestProps.current = { setValue };
  });

  useEffect(() => {
    casaDecimalRef.current = casasDecimais;
  }, [casasDecimais]);

  const loadMoreRows = useCallback(
    async ({
      currentPage,
      pageSize,
      orderColumn,
      orderDirection,
    }: LoadMoreRowsParams) => {
      setIsProdutosLoading(true);

      const paginationData = {
        currentPage,
        pageSize,
        orderColumn,
        orderDirection,
      };

      const response = await api.get<void, ResponseApi<ProdutoResponse[]>>(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.ENTRADA_MERCADORIA_OBTER_PRODUTOS_LANCAR_ESTOQUE,
          paginationData
        ),
        { params: { id: entradaMercadoriaId || '' } }
      );

      if (response) {
        if (response.sucesso && response.dados) {
          const novosProdutos = response.dados.map((registro) => {
            const valorVendaPrecoNovo =
              registro.custoMedioNovo +
              registro.custoMedioNovo * (registro.markup / 100);

            return {
              ...registro,
              height: 56,
              isOpen: false,
              markupNovo: parseFloat(registro.markup.toFixed(4)),
              precoVendaNovo: parseFloat(
                valorVendaPrecoNovo.toFixed(
                  casaDecimalRef.current.casasDecimaisValor
                )
              ),
            } as Produto;
          });

          latestProps.current.setValue('produtos', novosProdutos);
        }
      }

      setIsProdutosLoading(false);
    },
    [entradaMercadoriaId]
  );

  useEffect(() => {
    handleGetLojas();
  }, [handleGetLojas]);

  if (!isLargerThan800) {
    return (
      <ResolucaoNaoCompativel
        handleVoltar={() => {
          history.push(ConstanteRotas.ENTRADA_MERCADORIA);
        }}
      />
    );
  }

  return (
    <>
      {(isLoading || isProdutosLoading) && <LoadingPadrao />}
      <FormProvider {...formMethods}>
        <Box width={isOpen && isLargerThan1200 ? `calc(100% - 320px)` : 'full'}>
          <Prompt when={formIsDirty} message="" />
          <Flex
            flexDirection={['column', 'column', 'row']}
            justifyContent="space-between"
          >
            <Button
              onClick={isOpen ? onClose : onOpen}
              color="white"
              variant="solid"
              colorScheme="violet"
              background="violet.600"
              fontWeight="normal"
              height="28px"
              w="174px"
              mb="-14px"
              zIndex="1"
              fontSize="sm"
            >
              Editar configurações
            </Button>
          </Flex>

          <Box
            sx={{
              '& th': { border: 'none', whiteSpace: 'nowrap' },
              '& > div': {
                background: 'transparent',
              },
              '& .chakra-table th:last-of-type': {
                paddingRight: '4px',
              },
              '& .chakra-table td:last-of-type': {
                paddingRight: '4px',
              },
            }}
          >
            <VirtualizedInfiniteTable
              visibleItemsCount={7}
              ref={produtoRef}
              variant="simple-card"
              size="sm"
              boxShadow="none"
              bg="transparent"
              withoutRowsMessage="Nenhum produto adicionado."
              orderColumn="nomeProduto"
              isUpdateWidthTable
              colorFundo="gray.50"
              paddingRight="10px"
              alterarBordaListagem="white"
              tableHeaders={produtosTableHeaders}
              dynamicHeight={({ index }) => getDynamicHeight(index, 5)}
              itemHeight={56}
              rowRenderer={({
                index,
                style: { height, ...restStyle },
                key,
                parent,
              }) => {
                const produto = produtos[index];

                if (!produto) {
                  return null;
                }

                return (
                  <CellMeasurer
                    cache={cache}
                    columnIndex={1}
                    key={key}
                    parent={parent}
                    rowIndex={index}
                  >
                    {({ registerChild, measure }) => (
                      <Tr
                        ref={(e) => {
                          if (e && registerChild) {
                            registerChild(e);
                          }
                        }}
                        style={restStyle}
                        h="min-content"
                        sx={{
                          '& > td': {
                            boxShadow: 'none',
                            _before: { display: 'none' },
                            paddingX: '4px',
                          },
                        }}
                        borderRadius="md"
                        border="1px"
                        borderColor="gray.100"
                        background="white !important"
                        boxShadow="0px 0px 4px #00000029 !important"
                        color="gray.700"
                      >
                        <Td
                          ref={(e) => {
                            produtos[index].height = e?.clientHeight || 56;
                          }}
                          width={produtosTableHeaders[0].width}
                          cursor="pointer"
                          px="4px"
                          userSelect="none"
                          onClick={() => {
                            measure();
                            handleToggleLinhaProduto(index);
                          }}
                        >
                          <Flex alignItems="center">
                            <Icon
                              as={produto.isOpen ? FiChevronUp : FiChevronDown}
                              mr="1"
                            />
                            <Text
                              noOfLines={3}
                              fontSize={{
                                base: '10px',
                                lg: '12px',
                                xl: '14px',
                              }}
                            >
                              {produto.nome}
                            </Text>
                          </Flex>
                        </Td>
                        <Td
                          width={produtosTableHeaders[1].width}
                          isNumeric
                          px="4px"
                        >
                          <Text
                            lineHeight="0"
                            whiteSpace="nowrap"
                            fontSize="14px"
                            color={
                              manterCustoAtualWatch ? 'violet.500' : 'gray.700'
                            }
                          >
                            {DecimalMask(produto.precoCusto, 2, 2)}
                          </Text>
                        </Td>
                        <Td
                          width={produtosTableHeaders[2].width}
                          color={produto.markup <= 0 ? 'red' : ''}
                          isNumeric
                          pr="4px"
                        >
                          <Text
                            lineHeight="0"
                            whiteSpace="nowrap"
                            fontSize="14px"
                          >
                            {`${DecimalMask(produto.markup, 2, 2)}%`}
                          </Text>
                        </Td>
                        <Td
                          pr="4px"
                          width={produtosTableHeaders[3].width}
                          isNumeric
                          minW="70px"
                        >
                          <Text
                            lineHeight="0"
                            whiteSpace="nowrap"
                            fontSize="14px"
                          >
                            {DecimalMask(
                              produto.precoVenda,
                              casasDecimais.casasDecimaisValor,
                              casasDecimais.casasDecimaisValor
                            )}
                          </Text>
                        </Td>
                        <Td
                          pr="4px"
                          width={produtosTableHeaders[4].width}
                          isNumeric
                          minW="90px"
                        >
                          <Text
                            lineHeight="0"
                            whiteSpace="nowrap"
                            fontSize="14px"
                            color={
                              !desconsiderarDescontosWatch ||
                              (desconsiderarDescontosWatch &&
                                manterCustoAtualWatch)
                                ? 'gray.700'
                                : 'violet.500'
                            }
                            textDecor={
                              manterCustoAtualWatch ? 'line-through' : ''
                            }
                          >
                            {DecimalMask(
                              desconsiderarDescontosWatch
                                ? produto.custoMedioSemDesconto
                                : produto.custoMedioNovo,
                              2,
                              2
                            )}
                          </Text>
                        </Td>
                        <Td width={produtosTableHeaders[5].width}>
                          <Box
                            lineHeight="0"
                            whiteSpace="nowrap"
                            fontSize="14px"
                          >
                            <NumberInput
                              id={`markupNovo.${index}`}
                              name={`produtos.${index}.markupNovo`}
                              leftElement="%"
                              scale={4}
                              size="sm"
                              fontWeight="bold"
                              color={produto.markupNovo <= 0 ? 'red' : ''}
                              minW={{ base: '95px', lg: '104px' }}
                              onValueChange={(markupNovo) => {
                                recalcularValoresIndividual(
                                  index,
                                  Number(markupNovo),
                                  false,
                                  true
                                );
                              }}
                            />
                          </Box>
                        </Td>
                        <Td width={produtosTableHeaders[6].width}>
                          <Box
                            lineHeight="0"
                            whiteSpace="nowrap"
                            fontSize="14px"
                          >
                            <NumberInput
                              id={`precoVendaNovo.${index}`}
                              name={`produtos.${index}.precoVendaNovo`}
                              leftElement="R$"
                              scale={casaDecimalRef.current.casasDecimaisValor}
                              size="sm"
                              bg={manterPrecoVendaWatch ? 'purple.50' : 'white'}
                              fontWeight="bold"
                              minW={{ base: '95px', lg: '104px' }}
                              onValueChange={(precoVendaNovo) => {
                                recalcularValoresIndividual(
                                  index,
                                  Number(precoVendaNovo),
                                  true,
                                  false
                                );
                              }}
                            />
                          </Box>
                        </Td>

                        {produto.isOpen && (
                          <ProdutoInfo
                            produto={produto}
                            casasDecimais={casasDecimais}
                          />
                        )}
                      </Tr>
                    )}
                  </CellMeasurer>
                );
              }}
              rowCount={produtos.length}
              isRowLoaded={({ index }) => !!produtos[index]}
              loadMoreRows={loadMoreRows}
              canRenderEmptyRows
            />
          </Box>

          <HStack
            justifyContent="center"
            spacing={8}
            mt={{ base: 4, sm: 6, md: 8 }}
          >
            <Button
              variant="outline"
              borderRadius="md"
              w="full"
              fontSize="sm"
              maxW={{ base: 'full', md: '96px' }}
              onClick={() => {
                history.push(ConstanteRotas.ENTRADA_MERCADORIA);
              }}
            >
              Voltar
            </Button>
            <Button
              colorScheme="aquamarine"
              borderRadius="md"
              w="full"
              fontSize="sm"
              maxW={{ base: 'full', md: '240px' }}
              onClick={handleConfirmarEntradaEstoque}
              isDisabled={isLoading || isProdutosLoading}
            >
              Confirmar entrada de estoque
            </Button>
          </HStack>
        </Box>
        <DrawerEditarConfig
          onClose={onClose}
          isOpen={isOpen}
          hasVariasLojasDisponiveis={hasVariasLojasDisponiveis}
          lojasSelecionadas={lojasSelecionadas}
          handleAbrirModalPadronizarMarkup={handleAbrirModalPadronizarMarkup}
          handleAbrirModalAplicarConfigLojas={
            handleAbrirModalAplicarConfigLojas
          }
          handleRecalcularTodosValores={handleRecalcularTodosValores}
        />
      </FormProvider>
    </>
  );
}
