import { useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import {
  Table,
  Thead,
  Tr,
  Th,
  Divider,
  Checkbox,
  Flex,
  Text,
  Tbody,
  Td,
  Box,
  Tfoot,
  useMediaQuery,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import { DecimalMask, moneyMask } from 'helpers/format/fieldsMasks';
import { CasasDecimais } from 'store/Padronizacao/Padronizacao';
import { useProdutosConsignadosContext } from 'store/PDV/ProdutosConsignados';

import { MenuDetalhesConsignacao } from 'components/PDV/Consignacao/menuDetalhesConsignacao';
import { InputQuantidadeAcrescimoDecrescimo } from 'components/PDV/Consignacao/inputAcrescimoDecrescimo';

import { ListarProdutosConsignadosVersaoTabletMobile } from '../../Mobile';

type TableDadosConsignacaoProps = {
  casasDecimais: CasasDecimais;
  quantidade: number[];
};

export const TableDadosConsignacao = ({
  casasDecimais,
  quantidade,
}: TableDadosConsignacaoProps) => {
  const [isLargerThan900] = useMediaQuery('(max-width: 900px)');

  const { setValue, getValues, setFocus } = useFormContext();

  const valorCodigoBarrasWatch = useCallback(() => {
    return getValues().codigoBarras || 0;
  }, [getValues]);

  const codigoBarrasWatch = valorCodigoBarrasWatch();

  const {
    produtosConsignados,
    codigoBarrasVersaoMobile,
    produtosConsignadosJaForamDevolvidos,
    setProdutosConsignados,
    handleToggleSelecionarTodosItens,
    selecionarTodosProdutos,
    handleToggleSelecionarProdutoTroca,
    existeCodigoBarras,
    codigoBarrasRef,
  } = useProdutosConsignadosContext();
  const produtosConsignadosRef = useRef(produtosConsignados);

  useEffect(() => {
    produtosConsignadosRef.current = produtosConsignados;
  }, [produtosConsignados]);

  const valorTotalItensConsignados = moneyMask(
    produtosConsignados.reduce((acc, curr) => {
      const valorCadaProduto = curr.valorUnitario * curr.quantidadeConsignada;
      return valorCadaProduto + acc;
    }, 0),
    true
  );

  const quantidadeItensTotal = DecimalMask(
    produtosConsignados.reduce(
      (acc, curr) => acc + curr.quantidadeConsignada,
      0
    ),
    casasDecimais.casasDecimaisQuantidade,
    casasDecimais.casasDecimaisQuantidade
  );

  const valorCodigoBarras = isLargerThan900
    ? codigoBarrasVersaoMobile
    : codigoBarrasWatch;

  const validandoValorCodigoBarras = useRef(valorCodigoBarras);

  useImperativeHandle(codigoBarrasRef, () => ({
    focus() {
      setFocus('codigoBarras' as const);
    },
  }));

  const handleVoltarFocusCodigoBarras = () => {
    if (codigoBarrasRef.current.focus) {
      if (!produtosConsignadosJaForamDevolvidos) {
        codigoBarrasRef.current?.focus();
      }
    }
  };

  useEffect(() => {
    validandoValorCodigoBarras.current = valorCodigoBarras;
  }, [valorCodigoBarras]);

  function handleIncrementarQuantidade(index: number) {
    const novaQuantidade = quantidade[index];
    setValue(`quantidade.${index}`, novaQuantidade + 1);
  }

  function handleDecrementarQuantidade(index: number) {
    const novaQuantidade = quantidade[index] - 1;

    setValue(`quantidade.${index}`, novaQuantidade >= 1 ? novaQuantidade : 1);
    setProdutosConsignados((valorJaConsignado) =>
      valorJaConsignado.map((produto, indexProduto) => ({
        ...produto,
        produtoSelecionado:
          index === indexProduto ? false : produto.produtoSelecionado,
      }))
    );
  }

  const handleAtualizarQuantidade = useCallback(() => {
    produtosConsignados.forEach((produto, index) => {
      const quantidadePossuiDecimal = produto.quantidadeConsignada % 1 !== 0;
      const qtd = quantidade[index];

      setValue(
        `quantidade.${index}`,
        qtd > 1
          ? qtd
          : quantidadePossuiDecimal
          ? produto.quantidadeConsignada
          : 1
      );
    });
  }, [produtosConsignados, quantidade, setValue]);

  const handleAtualizarQuantidadeComCodigoBarras = useCallback(() => {
    produtosConsignadosRef.current.forEach(
      (
        {
          codigoBarrasInterno,
          codigoBarrasHexaDecimal,
          codigoGTINEAN,
          isChecked,
          quantidadeConsignada,
          codigoExterno,
        },
        index
      ) => {
        const novaQuantidade = quantidade[index];

        const isCodigoBarrasSelecionado = [
          codigoBarrasInterno,
          codigoBarrasHexaDecimal,
          codigoGTINEAN,
          codigoExterno,
        ].includes(valorCodigoBarras);

        setValue(
          `quantidade.${index}`,
          isCodigoBarrasSelecionado
            ? !isChecked
              ? 1
              : quantidadeConsignada === quantidade[index]
              ? quantidadeConsignada
              : novaQuantidade + 1
            : novaQuantidade
        );
      }
    );
  }, [quantidade, setValue, valorCodigoBarras]);

  const handleValidarProdutoCodigoBarras = useCallback(async () => {
    await handleAtualizarQuantidadeComCodigoBarras();

    setProdutosConsignados((produtoJaConsignado) => {
      const indexCodigoBarras = produtoJaConsignado.findIndex((produto) => {
        const {
          codigoBarrasInterno,
          codigoExterno,
          codigoBarrasHexaDecimal,
          codigoGTINEAN,
        } = produto;
        const isCodigoBarrasSelecionado = [
          codigoBarrasInterno,
          codigoExterno,
          codigoBarrasHexaDecimal,
          codigoGTINEAN,
        ].includes(valorCodigoBarras);
        return (
          isCodigoBarrasSelecionado && produto.produtoSelecionado === false
        );
      });

      if (indexCodigoBarras > -1) {
        const produtoSelecionado = produtoJaConsignado[indexCodigoBarras];
        const {
          codigoBarrasInterno,
          codigoExterno,
          codigoBarrasHexaDecimal,
          codigoGTINEAN,
        } = produtoSelecionado;
        const isCodigoBarrasSelecionado = [
          codigoBarrasInterno,
          codigoExterno,
          codigoBarrasHexaDecimal,
          codigoGTINEAN,
        ].includes(valorCodigoBarras);
        if (isCodigoBarrasSelecionado) {
          if (
            produtoJaConsignado[indexCodigoBarras].quantidadeConsignada ===
            quantidade[indexCodigoBarras]
          ) {
            produtoJaConsignado.splice(indexCodigoBarras, 1, {
              ...produtoJaConsignado[indexCodigoBarras],
              produtoSelecionado: true,
            });
          }
          produtoJaConsignado.splice(indexCodigoBarras, 1, {
            ...produtoJaConsignado[indexCodigoBarras],
            isChecked: true,
          });
        }
      }
      return [...produtoJaConsignado];
    });
  }, [
    setProdutosConsignados,
    valorCodigoBarras,
    quantidade,
    handleAtualizarQuantidadeComCodigoBarras,
  ]);

  useEffect(() => {
    handleAtualizarQuantidade();
  }, [handleAtualizarQuantidade]);

  useEffect(() => {
    if (isLargerThan900) {
      handleValidarProdutoCodigoBarras();
    } else {
      handleValidarProdutoCodigoBarras();
    }
    setValue('codigoBarras', null);
  }, [
    handleValidarProdutoCodigoBarras,
    isLargerThan900,
    setValue,
    existeCodigoBarras,
  ]);

  return (
    <>
      {!isLargerThan900 ? (
        <Table
          variant="simpleCardVariant"
          overflow="hidden"
          background="gray.50"
        >
          <Thead whiteSpace="nowrap">
            <Tr position="relative">
              <Th pb="0" pr="0" w="1px">
                <Checkbox
                  colorScheme="purple"
                  isChecked={selecionarTodosProdutos}
                  onChange={() => handleToggleSelecionarTodosItens(setValue)}
                  mb="0"
                />
              </Th>
              <Th pb="0" pl="10px" w="40%">
                <Flex justifyContent="left" alignItems="left">
                  <Text color="gray.300" fontSize="14px">
                    Produto
                  </Text>
                </Flex>
              </Th>
              <Th pb="0" w="18%">
                <Flex justifyContent="right" alignItems="right">
                  <Text color="gray.300" fontSize="14px">
                    Qtd. devolvida
                  </Text>
                </Flex>
              </Th>
              <Th pb="0" w="14%">
                <Flex justifyContent="right" alignItems="right">
                  <Text color="gray.300" fontSize="14px">
                    Valor unitário
                  </Text>
                </Flex>
              </Th>
              <Th pb="0" w="14%">
                <Flex justifyContent="right" alignItems="right">
                  <Text color="gray.300" fontSize="14px">
                    Valor total
                  </Text>
                </Flex>
              </Th>
              <Th pb="0" w="14%">
                <Flex justifyContent="right" alignItems="right">
                  <Text color="gray.300" fontSize="14px">
                    Consignação
                  </Text>
                </Flex>
              </Th>
              <Th pb="0" pl="0" w="1%" />
            </Tr>
            <Tr>
              <Th pb="0" pr={0} colSpan={8}>
                <Divider />
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {produtosConsignados.map((produtoConsignado, index) => {
              const quantidadePossuiDecimal =
                produtoConsignado.quantidadeConsignada % 1 !== 0;

              const valorTotalDaConsignacao =
                produtoConsignado.valorUnitario * quantidade[index];

              return (
                <>
                  <Tr
                    key={produtoConsignado.operacaoItemId}
                    bg={
                      produtoConsignado.isChecked
                        ? produtosConsignadosJaForamDevolvidos
                          ? 'aquamarine.100'
                          : 'orange.300'
                        : ''
                    }
                  >
                    <Td
                      onClick={() => handleVoltarFocusCodigoBarras()}
                      pr="0"
                      w="1px"
                    >
                      <Checkbox
                        colorScheme="purple"
                        isChecked={produtoConsignado.isChecked}
                        onChange={() =>
                          handleToggleSelecionarProdutoTroca(index)
                        }
                        mb="0"
                      />
                    </Td>
                    <Td
                      onClick={() => handleVoltarFocusCodigoBarras()}
                      pl="10px"
                    >
                      <Flex justifyContent="left" alignItems="left">
                        <Text fontSize="14px">
                          {produtoConsignado.descricao}
                        </Text>
                      </Flex>
                    </Td>
                    <Td w="20%">
                      <Flex justifyContent="right" alignItems="right">
                        {quantidadePossuiDecimal ? (
                          <Flex justifyContent="right" alignItems="right">
                            <Text fontSize="14px">
                              {DecimalMask(
                                produtoConsignado.quantidadeConsignada,
                                casasDecimais.casasDecimaisQuantidade,
                                casasDecimais.casasDecimaisQuantidade
                              )}
                            </Text>
                          </Flex>
                        ) : (
                          <>
                            {produtoConsignado.quantidadeConsignada > 1 ? (
                              <Flex justifyContent="right" alignItems="right">
                                <InputQuantidadeAcrescimoDecrescimo
                                  handleDecrementarQuantidade={
                                    handleDecrementarQuantidade
                                  }
                                  hasVolumeUnitario={
                                    produtoConsignado.volumeUnitario
                                  }
                                  handleIncrementarQuantidade={
                                    handleIncrementarQuantidade
                                  }
                                  produto={produtoConsignado}
                                  index={index}
                                  quantidade={quantidade}
                                />
                              </Flex>
                            ) : (
                              <Flex justifyContent="right" alignItems="right">
                                <Text>
                                  {DecimalMask(
                                    produtoConsignado.quantidadeConsignada,
                                    casasDecimais.casasDecimaisQuantidade,
                                    casasDecimais.casasDecimaisQuantidade
                                  )}
                                </Text>
                              </Flex>
                            )}
                          </>
                        )}
                        <Flex
                          alignItems="flex-end"
                          justifyContent="flex-end"
                          verticalAlign="bottom"
                        >
                          <Text
                            ml="5px"
                            fontWeight="bold"
                            color={
                              produtosConsignadosJaForamDevolvidos
                                ? 'aquamarine.500'
                                : produtoConsignado.isChecked
                                ? 'orange.500'
                                : 'orange.300'
                            }
                          >
                            /{' '}
                            {DecimalMask(
                              produtoConsignado.quantidadeConsignada,
                              casasDecimais.casasDecimaisQuantidade,
                              casasDecimais.casasDecimaisQuantidade
                            )}
                          </Text>
                        </Flex>
                      </Flex>
                    </Td>
                    <Td onClick={() => handleVoltarFocusCodigoBarras()}>
                      <Flex justifyContent="right" alignItems="right">
                        <Text fontSize="14px">
                          {`R$
                            ${DecimalMask(
                              produtoConsignado.valorUnitario,
                              casasDecimais.casasDecimaisValor,
                              casasDecimais.casasDecimaisValor
                            )}
                            `}
                        </Text>
                      </Flex>
                    </Td>
                    <Td onClick={() => handleVoltarFocusCodigoBarras()}>
                      <Flex justifyContent="right" alignItems="right">
                        <Text fontSize="14px">
                          {moneyMask(valorTotalDaConsignacao, true)}
                        </Text>
                      </Flex>
                    </Td>
                    <Td onClick={() => handleVoltarFocusCodigoBarras()}>
                      <Flex justifyContent="right" alignItems="right">
                        <Text fontSize="14px">
                          {produtoConsignado.numeroConsignacao}
                        </Text>
                      </Flex>
                    </Td>
                    <Td
                      onClick={() => handleVoltarFocusCodigoBarras()}
                      pl="0"
                      w="1px"
                    >
                      <Flex justifyContent="right" alignItems="right">
                        <MenuDetalhesConsignacao
                          informacoesDetalheProdutoConsignado={
                            produtoConsignado
                          }
                        />
                      </Flex>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td pb="0" pt="0" pr={0} colSpan={8}>
                      <Divider />
                    </Td>
                  </Tr>
                </>
              );
            })}
          </Tbody>
          <Tfoot onClick={() => handleVoltarFocusCodigoBarras()}>
            <Td />
            <Td w="40%" />

            <Td>
              <Flex justifyContent="right" alignItems="right">
                <Box>
                  <Text fontSize="14px" color="gray.300" fontWeight="bold">
                    Qtd. de itens
                  </Text>
                  <Flex justifyContent="right" alignItems="right">
                    <Text fontSize="14px" fontWeight="bold" color="black">
                      {quantidadeItensTotal}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </Td>
            <Td />

            <Td>
              <Flex justifyContent="right" alignItems="right">
                <Text fontSize="14px" color="gray.300" fontWeight="bold">
                  Valor total
                </Text>
              </Flex>
              <Flex justifyContent="right" alignItems="right">
                <Text fontSize="14px" fontWeight="bold" color="blue.700">
                  {valorTotalItensConsignados}
                </Text>
              </Flex>
            </Td>
            <Td />

            <Td />
          </Tfoot>
        </Table>
      ) : (
        <ListarProdutosConsignadosVersaoTabletMobile
          quantidadeItensTotal={quantidadeItensTotal}
          valorTotalItensConsignados={valorTotalItensConsignados}
          quantidade={quantidade}
        />
      )}
    </>
  );
};
