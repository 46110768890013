import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import { isValidCnpj, isValidCpf } from 'helpers/validation/IsValidCpfCnpj';
import { getOptionByEnum } from 'helpers/format/getOptionsByEnum';
import RegimeTributarioEnum from 'constants/enum/regimeTributario';

const schema = yup.object().shape({
  razaoSocial: yup
    .string()
    .trim()
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .max(60, 'O campo razão social não pode conter mais que 60 caracteres.'),
  fantasia: yup
    .string()
    .trim()
    .max(60, 'O campo nome fantasia não pode conter mais que 80 caracteres.')
    .when('cnpj', {
      is: (value: string) => value.length <= 14,
      then: (value: yup.ObjectSchema) => value.nullable(),
      otherwise: (value: yup.ObjectSchema) => value.nullable(),
    }),
  cnpj: yup
    .string()
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .test('cnpj', ConstanteMensagemValidacao.CNPJ_INVALIDO, (value) => {
      if (value) {
        if (value.length > 14) return isValidCnpj(value);
      }

      return true;
    })
    .test('cnpj', ConstanteMensagemValidacao.CPF_INVALIDO, (value) => {
      if (value && value.length < 14) {
        return false;
      }
      return true;
    }),
  ieisento: yup.boolean(),
  ie: yup
    .string()
    .trim()
    .max(
      15,
      'O campo inscrição estadual não pode conter mais que 15 caracteres.'
    )
    .when(['ieisento', 'cnpj'], {
      is: (val, cnpj: string) => !val && cnpj.length > 14,
      then: (val: any) =>
        val
          .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
          .test(
            'ieIsento',
            ConstanteMensagemValidacao.CAMPO_OBRIGATORIO,
            (value: string) => {
              if (
                !value ||
                (value && String(value).trim().toLowerCase() === 'isento')
              ) {
                return false;
              }

              return true;
            }
          ),
      otherwise: (val: any) => val.nullable().default(null),
    }),
  contato: yup
    .string()
    .trim()
    .nullable()
    .notRequired()
    .max(30, 'O campo responsável não pode conter mais que 30 caracteres.'),
  logradouro: yup
    .string()
    .trim()
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .max(80, 'O campo logradouro não pode conter mais que 60 caracteres.'),
  numero: yup
    .string()
    .trim()
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .max(10, 'O campo número não pode conter mais que 10 caracteres.'),
  complemento: yup
    .string()
    .trim()
    .nullable()
    .max(50, 'O campo complemento não pode conter mais que 50 caracteres.'),
  bairro: yup
    .string()
    .trim()
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .max(50, 'O campo bairro não pode conter mais que 50 caracteres.'),
  cep: yup
    .string()
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .test('cep', ConstanteMensagemValidacao.CEP_INVALIDO, (value) => {
      if (value) {
        const testSchema = yup.string().min(9).max(9);

        return testSchema.isValidSync(value);
      }

      return true;
    }),
  cidade: yup
    .object({
      value: yup
        .number()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
      label: yup.string(),
      paisId: yup.number().nullable(),
    })
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  pais: yup
    .object({
      value: yup
        .number()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
      label: yup.string(),
    })
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  celular: yup
    .string()
    .nullable()
    .notRequired()
    .test('celular', ConstanteMensagemValidacao.TELEFONE_INVALIDO, (value) => {
      if (value) {
        const testSchema = yup.string().min(14).max(15);

        return testSchema.isValidSync(value);
      }

      return true;
    }),
  telefone: yup
    .string()
    .nullable()
    .notRequired()
    .test('telefone', ConstanteMensagemValidacao.TELEFONE_INVALIDO, (value) => {
      if (value) {
        const testSchema = yup.string().min(14).max(15);

        return testSchema.isValidSync(value);
      }

      return true;
    }),
  email: yup
    .string()
    .nullable()
    .email(ConstanteMensagemValidacao.EMAIL_INVALIDO)
    .max(80, 'O campo e-mail não pode conter mais que 80 caracteres.'),
  sitePerfil: yup
    .string()
    .nullable()
    .max(80, 'O campo site/perfil não pode conter mais que 80 caracteres.'),
  contabilistaNome: yup
    .string()
    .trim()
    .nullable()
    .notRequired()
    .max(
      60,
      'O campo contabilista responsável não pode conter mais que 60 caracteres.'
    ),
  contabilistaCpf: yup
    .string()
    .nullable()
    .test(
      'contabilistaCpf',
      ConstanteMensagemValidacao.CPF_INVALIDO,
      (value) => {
        if (value) {
          if (value.length <= 14) return isValidCpf(value);
        }

        return true;
      }
    ),
  contabilistaCrc: yup
    .string()
    .trim()
    .nullable()
    .notRequired()
    .max(20, 'O campo CRC não pode conter mais que 20 caracteres.'),
  contabilistaCnpj: yup
    .string()
    .nullable()
    .test(
      'contabilistaCnpj',
      ConstanteMensagemValidacao.CNPJ_INVALIDO,
      (value) => {
        if (value) {
          if (value.length > 14) return isValidCnpj(value);
        }

        return true;
      }
    ),
  contabilistaEmail: yup
    .string()
    .nullable()
    .test(
      'contabilistaEmail',
      ConstanteMensagemValidacao.CAMPO_OBRIGATORIO,
      function ValidarEnviarAutomatico(value: any) {
        const { documentosFiscaisAutomaticamente } = this.parent;

        if (documentosFiscaisAutomaticamente && !value) {
          return false;
        }

        return true;
      }
    )
    .email(ConstanteMensagemValidacao.EMAIL_INVALIDO)
    .max(
      80,
      'O campo e-mail do contabilista não pode conter mais que 80 caracteres.'
    ),
  contabilistaCelular: yup
    .string()
    .nullable()
    .notRequired()
    .test(
      'contabilistaCelular',
      ConstanteMensagemValidacao.TELEFONE_INVALIDO,
      (value) => {
        if (value) {
          const testSchema = yup.string().min(14).max(15);

          return testSchema.isValidSync(value);
        }

        return true;
      }
    ),
  contabilistaTelefone: yup
    .string()
    .nullable()
    .notRequired()
    .test(
      'contabilistaTelefone',
      ConstanteMensagemValidacao.TELEFONE_INVALIDO,
      (value) => {
        if (value) {
          const testSchema = yup.string().min(14).max(15);

          return testSchema.isValidSync(value);
        }

        return true;
      }
    ),
  contabilistaCep: yup
    .string()
    .nullable()
    .notRequired()
    .test(
      'contabilistaCep',
      ConstanteMensagemValidacao.CEP_INVALIDO,
      (value) => {
        if (value) {
          const testSchema = yup.string().min(9).max(9);

          return testSchema.isValidSync(value);
        }

        return true;
      }
    ),
  regraFiscalPadrao: yup
    .object()
    .shape({
      value: yup.string().nullable().default(null),
      label: yup.string().nullable().default(null),
    })
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  nfeNumeroSerie: yup
    .number()
    .nullable()
    .test(
      'emitirNFe',
      ConstanteMensagemValidacao.CAMPO_OBRIGATORIO,
      function ValidarEmitirNFe(value: any) {
        const { emitirNFe } = this.parent;

        if (emitirNFe && emitirNFe.selecionado === true && value === '') {
          return false;
        }

        return true;
      }
    ),
  nfeNumeracaoInicial: yup
    .number()
    .nullable()
    .test(
      'emitirNFe',
      ConstanteMensagemValidacao.CAMPO_OBRIGATORIO,
      function ValidarEmitirNFe(value: any) {
        const { emitirNFe } = this.parent;

        if (emitirNFe && emitirNFe.selecionado === true && value === '') {
          return false;
        }

        return true;
      }
    )
    .test(
      'emitirNFe',
      ConstanteMensagemValidacao.MIN_VALUE_1,
      function ValidarEmitirNFe(value: any) {
        const { emitirNFe } = this.parent;

        if (emitirNFe && emitirNFe.selecionado === true && value < 1) {
          return false;
        }

        return true;
      }
    ),

  nfceNumeroSerie: yup
    .number()
    .nullable()
    .test(
      'emitirNFCe',
      ConstanteMensagemValidacao.CAMPO_OBRIGATORIO,
      function ValidarEmitirNFCe(value: any) {
        const { emitirNFCe } = this.parent;

        if (emitirNFCe && emitirNFCe.selecionado === true && value === '') {
          return false;
        }

        return true;
      }
    ),

  nfceNumeracaoInicial: yup
    .number()
    .nullable()
    .test(
      'emitirNFCe',
      ConstanteMensagemValidacao.CAMPO_OBRIGATORIO,
      function ValidarEmitirNFCe(value: any) {
        const { emitirNFCe } = this.parent;

        if (emitirNFCe && emitirNFCe.selecionado === true && value === '') {
          return false;
        }

        return true;
      }
    )
    .test(
      'emitirNFCe',
      ConstanteMensagemValidacao.MIN_VALUE_1,
      function ValidarEmitirNFCe(value: any) {
        const { emitirNFCe } = this.parent;

        if (emitirNFCe && emitirNFCe.selecionado === true && value < 1) {
          return false;
        }

        return true;
      }
    ),

  nfceValorExigenciaCNPJCPF: yup.number().when('exigirCNPJCPF.selecionado', {
    is: true,
    then: (n: any) =>
      n
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
    otherwise: (n: any) => n.nullable(),
  }),
  regimeTributarioSelect: yup
    .object()
    .shape({
      value: yup.number().nullable(),
      label: yup.string().nullable(),
    })
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(
      getOptionByEnum(
        RegimeTributarioEnum,
        RegimeTributarioEnum.SIMPLES_NACIONAL
      )
    ),
  tipoOperacao: yup.string().oneOf(['cadastrar', 'alterar']),
  lojaBase: yup.string().when('tipoOperacao', {
    is: (tipoOperacao: string) => tipoOperacao === 'cadastrar',
    then: (operacao: yup.StringSchema) =>
      operacao.required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
    otherwise: (operacao: yup.StringSchema) =>
      operacao.nullable().notRequired(),
  }),
});

export const yupResolver = yupResolverInstance(schema);

export const useForm = useFormInstance;
