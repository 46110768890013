import { Container, Flex, LayoutProps, Text } from '@chakra-ui/react';
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import { toast } from 'react-toastify';

import ContatoEmpresa from 'constants/contato';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import Cabecalho from './Divisoes/cabecalho';
import ResumoCaixa from './Divisoes/resumoCaixa';
import ResumoSaidas from './Divisoes/tableSaidas';
import TableResumoConferencia from './Divisoes/tableConferencia';

interface Saidas {
  formaRecebimentoNome: string;
  valor: number;
}

interface ConferenciaCaixa {
  formaRecebimentoId: string;
  formaRecebimentoNome: string;
  valor: number;
  valorConferido: number;
  diferenca: number;
  consideraSaldoInicial: true;
}

interface ObterInformacoes {
  contaFinanceiraNome: string;
  dataHoraUsuarioAbertura: string;
  dataHoraUsuarioFechamento: string;
  saldoInicial: number;
  totalEntradas: number;
  totalSaidas: number;
  saldoFinal: number;
  qtdVendasConcluidas: number;
  totalValorVendasConcluidas: number;
  qtdVendasCanceladas: number;
  totalValorDevolucoes: number;
  totalValorSangria: number;
  totalValorSuprimento: number;
  saldo: number;
  caixaConferencias: ConferenciaCaixa[];
  saidas: Saidas[];
}

interface ImpressaoFechamentoCaixa {
  maxWidth?: LayoutProps['maxW'];
  containerRef: React.RefObject<HTMLDivElement>;
  caixaMovimentacaoId?: string;
}

export interface ImpressaoCupomFechamentoCaixaRefElement {
  obterInformacoesImpressao: () => Promise<void>;
}

const ImpressaoCupomFechamentoCaixa: ForwardRefRenderFunction<
  ImpressaoCupomFechamentoCaixaRefElement,
  ImpressaoFechamentoCaixa
> = ({ maxWidth, containerRef, caixaMovimentacaoId }, ref) => {
  const [
    impressaoFechamentoCaixa,
    setImpressaoFechamentoCaixa,
  ] = useState<ObterInformacoes>();

  const obterInformacoesImpressao = useCallback(async () => {
    const response = await api.get<void, ResponseApi<ObterInformacoes>>(
      ConstanteEnderecoWebservice.FECHAMENTO_CAIXA_OBTER_PARA_IMPRIMIR,
      {
        params: {
          caixaMovimentacaoId,
        },
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }
      if (response.sucesso && response.dados) {
        setImpressaoFechamentoCaixa(response.dados);
      }
    }
  }, [caixaMovimentacaoId]);

  useImperativeHandle(ref, () => ({
    obterInformacoesImpressao,
  }));

  if (!caixaMovimentacaoId) return null;

  return (
    <Container
      pl="10px"
      h="full"
      minH="100vh"
      pr="10px"
      sx={{
        '& div,': {
          color: 'black',
          fontWeight: '900',
          fontSize: '10px',
          fontFamily: 'arial',
        },
        '& div, & hr': {
          opacity: 'unset',
          borderColor: 'black',
        },
      }}
      minW="100%"
      ref={containerRef}
    >
      <Flex minW="58mm" maxW={maxWidth} direction="column" h="auto" pt="2">
        <Cabecalho informacoesCabecalhoProps={impressaoFechamentoCaixa} />
        <ResumoCaixa resumoCaixa={impressaoFechamentoCaixa} />
        <TableResumoConferencia
          conferenciaCaixaProps={impressaoFechamentoCaixa?.caixaConferencias}
        />
        {(impressaoFechamentoCaixa?.saidas || []).length > 0 && (
          <ResumoSaidas saidas={impressaoFechamentoCaixa?.saidas} />
        )}

        <Text
          textAlign="right"
          fontStyle="italic"
          fontSize="2xs"
          fontWeight="bold"
          color="gray.900"
          mt={2}
        >
          {ContatoEmpresa.CONTATO}
        </Text>
      </Flex>
    </Container>
  );
};

export default forwardRef(ImpressaoCupomFechamentoCaixa);
