import React, { useMemo, useState, useRef } from 'react';
import {
  Box,
  Tr,
  Td,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  IconButton,
  VStack,
  HStack,
  Text,
  Button,
  Flex,
  Tooltip,
} from '@chakra-ui/react';
import format from 'date-fns/format';
import ptBR from 'date-fns/locale/pt-BR';
import { FiMoreVertical } from 'react-icons/fi';

import IdentificacaoTipoOperacaoEnum, {
  IdentificacaoTipoOperacao,
} from 'constants/enum/identificacaoTipoOperacao';
import StatusOperacaoEnum, {
  StatusOperacao,
} from 'constants/enum/statusOperacao';
import ModelosFiscaisEnum from 'constants/enum/fiscal/modelosFiscais';
import ConstanteFuncionalidades from 'constants/permissoes';
import LogAuditoriaTelaEnum from 'constants/enum/logAuditoriaTela';
import auth from 'modules/auth';
import cancelarOperacao from 'helpers/api/Operacao/cancelarOperacao';
import duplicarOperacao from 'helpers/api/Operacao/duplicarOperacao';
import { CadastroDevolucaoVendasIcon } from 'icons';

import { useConsultarOperacoesContext } from 'store/PDV/ConsultarOperacoes';

import ModalAutorizacaoFuncionalidade from 'components/Modal/ModalAutorizacaoFuncionalidade';
import ModalConfirmacao from 'components/PDV/Modal/ModalConfirmacao';
import { ModalRelatorioDespacho } from 'components/Modal/ModalRelatorioDespacho';
import ModalMotivoCancelamento from 'components/PDV/Modal/ModalMotivoCancelamento';

import { useHistory } from 'react-router-dom';
import ConstanteRotasPDV from 'constants/rotasPDV';
import { SubstituirParametroRota } from 'constants/rotas';
import { getName } from 'helpers/enum/getName';
import { useSignalRContext } from 'store/SignalR';

export interface Operacao {
  id: string;
  identificacaoTipoOperacao: IdentificacaoTipoOperacao;
  numeroOperacao: number;
  dataEmissao: string;
  cliente: string;
  status: StatusOperacao;
  modeloNumeroFiscais?: Array<any>;
  valorTotal: number;
  possuiDevolucao: boolean;
  consignacaoFinalizada: boolean;
}

interface OperacaoItemProps {
  operacao: Operacao;
  index: number;
  isTr: boolean;
  handleOpenOperacao: (
    operacaoId: string,
    chavePermissaoTemporaria?: string
  ) => void;
}

const OperacaoItem: React.FC<OperacaoItemProps> = ({
  operacao,
  index,
  isTr,
  handleOpenOperacao,
}) => {
  const { hubConnection, joinGroup } = useSignalRContext();

  const { handleGetOperacoes, setIsLoading } = useConsultarOperacoesContext();

  const [
    modalAutorizacaoAlterarOperacaoIsOpen,
    setModalAutorizacaoAlterarOperacaoIsOpen,
  ] = useState(false);
  const [
    modalAutorizacaoCancelarOperacaoIsOpen,
    setModalAutorizacaoCancelarOperacaoIsOpen,
  ] = useState(false);
  const [
    modalConfirmacaoCancelarIsOpen,
    setModalConfirmacaoCancelarIsOpen,
  ] = useState(false);
  const [
    modalMotivoCancelamentoIsOpen,
    setModalMotivoCancelamentoIsOpen,
  ] = useState(false);

  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

  const history = useHistory();

  const chavePermissaoTemporaria = useRef<string>();
  const motivoCancelamento = useRef<string>();
  const motivoCancelamentoNotaFiscal = useRef<string>();

  const permissaoCancelarOperacao = useMemo(
    () => auth.possuiPermissao(ConstanteFuncionalidades.PDV_CANCELAR.codigo),
    []
  );

  const identificacaoTipoOperacao =
    IdentificacaoTipoOperacaoEnum.properties[operacao.identificacaoTipoOperacao]
      .name;

  const data = format(new Date(operacao.dataEmissao), "dd/MM/yyyy  HH:mm'h'", {
    locale: ptBR,
  });

  const valorTotal = operacao.valorTotal.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 4,
  });

  const isVenda =
    operacao.identificacaoTipoOperacao === IdentificacaoTipoOperacaoEnum.VENDA;

  const isDevolucao =
    operacao.identificacaoTipoOperacao ===
    IdentificacaoTipoOperacaoEnum.DEVOLUCAO;

  const color = isVenda ? 'gray.900' : 'blue.500';
  const bg = isVenda ? 'gray.50' : 'blue.50';

  const isCancelado = operacao.status === StatusOperacaoEnum.CANCELADA;
  const isCanceladoText = isVenda ? 'Venda Cancelada' : 'Cancelado';

  const menuListContent = useMemo(() => {
    const handleAbrir = () => {
      if (
        auth.possuiPermissao(ConstanteFuncionalidades.PDV_ALTERAR.codigo)
          .permitido
      ) {
        handleOpenOperacao(operacao.id);
      } else {
        setModalAutorizacaoAlterarOperacaoIsOpen(true);
      }
    };

    const handleCancelarOperacao = async () => {
      setIsLoading(true);

      await cancelarOperacao(
        {
          id: operacao.id,
          tela: LogAuditoriaTelaEnum.PDV,
          motivoCancelamento: motivoCancelamento.current,
          motivoCancelamentoNotaFiscal: motivoCancelamentoNotaFiscal.current,
        },
        hubConnection,
        joinGroup,
        () => {
          handleGetOperacoes();
          setIsLoading(false);
        },
        () => {
          setIsLoading(false);
        },
        chavePermissaoTemporaria.current
      );

      chavePermissaoTemporaria.current = undefined;
      motivoCancelamento.current = undefined;
      motivoCancelamentoNotaFiscal.current = undefined;
    };

    const handleConfirmarPermissaoCancelar = (chave: string) => {
      chavePermissaoTemporaria.current = chave;

      if (isVenda) {
        setModalMotivoCancelamentoIsOpen(true);
      } else {
        handleCancelarOperacao();
      }
    };

    const handleConfirmarMotivoCancelamento = (
      motivo: string,
      motivoNotaFiscal?: string
    ) => {
      motivoCancelamento.current = motivo;
      motivoCancelamentoNotaFiscal.current = motivoNotaFiscal;

      handleCancelarOperacao();
    };

    const handleCancelar = () => {
      if (permissaoCancelarOperacao.permitido) {
        if (isVenda) {
          setModalMotivoCancelamentoIsOpen(true);
        } else {
          handleCancelarOperacao();
        }
      } else {
        setModalAutorizacaoCancelarOperacaoIsOpen(true);
      }
    };

    const handleDuplicar = async () => {
      setIsLoading(true);

      const retorno = await duplicarOperacao(operacao.id);

      if (retorno) {
        history.push(
          SubstituirParametroRota(
            ConstanteRotasPDV.PDV_LANCAMENTO_ID,
            'id?',
            retorno
          )
        );

        return;
      }

      setIsLoading(false);
    };

    const handleEmitirNotaFiscal = () => {
      history.push(
        SubstituirParametroRota(
          ConstanteRotasPDV.PDV_FINALIZAR,
          'id',
          operacao.id
        )
      );
    };

    const handleEmitirCompartilhar = () => {
      history.push(
        SubstituirParametroRota(
          ConstanteRotasPDV.PDV_COMPARTILHAR,
          'id',
          operacao.id
        )
      );
    };

    const handleAbrirRelatorioDespacho = () => {
      ModalRelatorioDespacho({ operacaoId: operacao.id });
    };

    return isCancelado ? (
      <>
        <MenuItem onClick={handleEmitirCompartilhar}>Compartilhar</MenuItem>
        <MenuItem onClick={handleDuplicar}>Duplicar</MenuItem>
      </>
    ) : (
      <>
        {!isVenda && !operacao.consignacaoFinalizada && !isDevolucao && (
          <MenuItem onClick={handleAbrir}>Abrir</MenuItem>
        )}
        <MenuItem onClick={handleEmitirCompartilhar}>Compartilhar</MenuItem>
        {isVenda && (
          <MenuItem onClick={handleAbrirRelatorioDespacho}>
            Etiqueta de despacho
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            setModalConfirmacaoCancelarIsOpen(true);
          }}
        >
          Cancelar
        </MenuItem>
        {!isDevolucao && <MenuItem onClick={handleDuplicar}>Duplicar</MenuItem>}
        {isVenda &&
          operacao.modeloNumeroFiscais &&
          operacao.modeloNumeroFiscais?.filter(
            (docFiscal) => docFiscal.modeloFiscal === ModelosFiscaisEnum.NFe
          ).length === 0 && (
            <MenuItem onClick={handleEmitirNotaFiscal}>
              Emitir Nota Fiscal
            </MenuItem>
          )}

        {modalAutorizacaoAlterarOperacaoIsOpen && (
          <ModalAutorizacaoFuncionalidade
            isOpen={modalAutorizacaoAlterarOperacaoIsOpen}
            setIsOpen={setModalAutorizacaoAlterarOperacaoIsOpen}
            autorizado={(chave) => {
              handleOpenOperacao(operacao.id, chave);
            }}
            titulo={ConstanteFuncionalidades.PDV_ALTERAR.titulo}
            descricao={ConstanteFuncionalidades.PDV_ALTERAR.descricao}
            tela={LogAuditoriaTelaEnum.PDV}
            permissoes={[ConstanteFuncionalidades.PDV_ALTERAR.codigo]}
          />
        )}
        {modalAutorizacaoCancelarOperacaoIsOpen && (
          <ModalAutorizacaoFuncionalidade
            isOpen={modalAutorizacaoCancelarOperacaoIsOpen}
            setIsOpen={setModalAutorizacaoCancelarOperacaoIsOpen}
            autorizado={handleConfirmarPermissaoCancelar}
            titulo={ConstanteFuncionalidades.PDV_CANCELAR.titulo}
            descricao={ConstanteFuncionalidades.PDV_CANCELAR.descricao}
            tela={LogAuditoriaTelaEnum.PDV}
            permissoes={[ConstanteFuncionalidades.PDV_CANCELAR.codigo]}
          />
        )}
        {modalConfirmacaoCancelarIsOpen && (
          <ModalConfirmacao
            isOpen={modalConfirmacaoCancelarIsOpen}
            setIsOpen={setModalConfirmacaoCancelarIsOpen}
            onConfirm={handleCancelar}
            textoCabecalho="Deseja cancelar esta operação?"
            textoMensagem="Se continuar cancelará toda a operação, e esta ação não poderá ser desfeita. Deseja continuar?"
            textoCancelar="Não, voltar!"
          />
        )}
        {modalMotivoCancelamentoIsOpen && (
          <ModalMotivoCancelamento
            isOpen={modalMotivoCancelamentoIsOpen}
            onClose={() => {
              setModalMotivoCancelamentoIsOpen(false);
            }}
            onSubmit={handleConfirmarMotivoCancelamento}
            hasNotaFiscal={
              !!(
                operacao.modeloNumeroFiscais &&
                operacao.modeloNumeroFiscais?.length > 0
              )
            }
          />
        )}
      </>
    );
  }, [
    isCancelado,
    isDevolucao,
    isVenda,
    modalAutorizacaoAlterarOperacaoIsOpen,
    modalAutorizacaoCancelarOperacaoIsOpen,
    modalConfirmacaoCancelarIsOpen,
    modalMotivoCancelamentoIsOpen,
    operacao.modeloNumeroFiscais,
    operacao.id,
    handleOpenOperacao,
    setIsLoading,
    handleGetOperacoes,
    permissaoCancelarOperacao.permitido,
    history,
  ]);

  return isTr ? (
    <>
      {index > 0 && <Box h={1} />}
      <Tr
        color={isCancelado ? 'red.500' : color}
        sx={{ td: { bg: isCancelado ? 'red.50' : bg } }}
      >
        <Td w="auto" whiteSpace="nowrap">
          {identificacaoTipoOperacao}
        </Td>
        <Td w="auto" whiteSpace="nowrap">
          <Flex flexDirection="row" gap="3" alignItems="center">
            {operacao.numeroOperacao}
            {operacao.possuiDevolucao ? (
              <Tooltip
                shouldWrapChildren
                hasArrow
                label="Possui devolução"
                placement="auto"
                gutter={15}
              >
                <Icon as={CadastroDevolucaoVendasIcon} w={5} h={5} />
              </Tooltip>
            ) : null}
          </Flex>
        </Td>
        <Td w="auto" whiteSpace="nowrap">
          {data}
        </Td>
        <Td w="40%" whiteSpace="nowrap">
          {operacao.cliente}
        </Td>
        <Td w="auto" whiteSpace="nowrap" lineHeight="shorter">
          {operacao.modeloNumeroFiscais &&
          operacao.modeloNumeroFiscais.length > 0 ? (
            <>
              {isCancelado ? (
                <Flex h="15px" alignItems="center">
                  <VStack
                    spacing={0}
                    alignItems="flex-start"
                    justifyContent="center"
                    maxH="1px"
                  >
                    {operacao.modeloNumeroFiscais.map(
                      (documentofiscal: any) => {
                        return (
                          <Text
                            fontSize="2xs"
                            fontWeight="bold"
                            lineHeight="shorter"
                          >
                            {getName(
                              ModelosFiscaisEnum,
                              documentofiscal.modeloFiscal
                            )}{' '}
                            | {documentofiscal.numeroFiscal}
                          </Text>
                        );
                      }
                    )}
                    <Text fontSize="2xs" lineHeight="shorter">
                      {isCanceladoText}
                    </Text>
                  </VStack>
                </Flex>
              ) : (
                operacao.modeloNumeroFiscais.map((documentofiscal: any) => {
                  return (
                    <Text>
                      {getName(
                        ModelosFiscaisEnum,
                        documentofiscal.modeloFiscal
                      )}{' '}
                      | {documentofiscal.numeroFiscal}
                    </Text>
                  );
                })
              )}
            </>
          ) : (
            <>{isCancelado ? isCanceladoText : '----'}</>
          )}
        </Td>
        <Td w="1px" whiteSpace="nowrap" isNumeric>
          <Flex justifyContent="space-between">
            <Text mr="1.5">R$</Text>
            {`${valorTotal}`}
          </Flex>
        </Td>
        <Td w="auto" whiteSpace="nowrap" textAlign="center" color="gray.900">
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<Icon as={FiMoreVertical} fontSize="xl" />}
              variant="ghost"
              borderRadius="md"
              size="sm"
              py={0}
              h="1em"
            />
            <MenuList>{menuListContent}</MenuList>
          </Menu>
        </Td>
      </Tr>
    </>
  ) : (
    <VStack
      position="relative"
      borderRadius="md"
      boxShadow="md"
      bg={isCancelado ? 'red.50' : bg}
      color={isCancelado ? 'red.500' : color}
      py={4}
      px={6}
      spacing={2}
      w="full"
      h="165px"
      alignItems="flex-start"
      justifyContent="space-between"
    >
      <HStack spacing={2} maxW="100%">
        <Text fontSize="2xs" fontWeight="light" color="gray.400">
          Operação:
        </Text>
        <Flex flexDirection="row" gap="3" alignItems="center">
          <Text
            fontSize="sm"
            isTruncated
          >{`${identificacaoTipoOperacao} - ${operacao.numeroOperacao}`}</Text>
          {operacao.possuiDevolucao ? (
            <Tooltip
              shouldWrapChildren
              hasArrow
              label="Possui devolução"
              placement="auto"
              gutter={15}
              isOpen={tooltipIsOpen}
            >
              <Icon
                as={CadastroDevolucaoVendasIcon}
                w={5}
                h={5}
                onClick={() => setTooltipIsOpen(!tooltipIsOpen)}
              />
            </Tooltip>
          ) : null}
        </Flex>
      </HStack>
      <HStack spacing={2} maxW="100%">
        <Text fontSize="2xs" fontWeight="light" color="gray.400">
          Data:
        </Text>
        <Text fontSize="sm" isTruncated>
          {data}
        </Text>
      </HStack>
      <HStack spacing={2} maxW="100%">
        <Text fontSize="2xs" fontWeight="light" color="gray.400">
          Cliente:
        </Text>
        <Text fontSize="sm" isTruncated>
          {operacao.cliente}
        </Text>
      </HStack>
      <HStack spacing={2} maxW="100%">
        <Text fontSize="2xs" fontWeight="light" color="gray.400">
          Situação:
        </Text>
        <Text fontSize="sm" isTruncated>
          {operacao.modeloNumeroFiscais &&
          operacao.modeloNumeroFiscais.length > 0 ? (
            <>
              {isCancelado
                ? isCanceladoText
                : operacao.modeloNumeroFiscais.map((documentofiscal: any) => {
                    return (
                      <p>
                        {getName(
                          ModelosFiscaisEnum,
                          documentofiscal.modeloFiscal
                        )}{' '}
                        | {documentofiscal.numeroFiscal}
                      </p>
                    );
                  })}
            </>
          ) : (
            <>{isCancelado ? isCanceladoText : '----'}</>
          )}
        </Text>
      </HStack>
      <HStack spacing={2} maxW="100%">
        <Text fontSize="2xs" fontWeight="light" color="gray.400">
          Valor:
        </Text>
        <Text fontSize="sm" isTruncated>{`R$ ${valorTotal}`}</Text>
      </HStack>

      <Menu>
        <MenuButton
          as={Button}
          position="absolute"
          variant="outline"
          color="gray.400"
          right={6}
          bottom={4}
          size="xs"
        >
          Ações
        </MenuButton>
        <MenuList>{menuListContent}</MenuList>
      </Menu>
    </VStack>
  );
};

export default OperacaoItem;
