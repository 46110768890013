import { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Box, Checkbox, Flex, Text, VStack } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import {
  cadastrarFormaRecebimentoSmartPos,
  FormaRecebimentoProps,
  getFormaRecebimentoSmartPos,
} from 'services/smartPos';
import { useSmartPosContext } from 'store/SmartPos/SmartPosContext';

import Loading from 'components/Layout/Loading/LoadingPadrao';

import { Container } from '../Layout/Container';

export const FormaRecebimento = () => {
  const [listFormaRecebimento, setListFormaRecebimento] = useState<
    FormaRecebimentoProps[]
  >([]);
  const [
    selecionarTodasFormasRecebimentos,
    setSelecionarTodasFormasRecebimentos,
  ] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { ref, setIsStepAlterada } = useSmartPosContext();

  const formasRecebimentoSelecionadas = listFormaRecebimento
    .filter((formaRecebimento) => formaRecebimento.selecionado)
    .map((formaRecebimento) => formaRecebimento.id);

  const handleToggleSelecionarFormaRecebimento = useCallback(
    (index: number) => {
      setListFormaRecebimento((prev) =>
        prev.map((valueItem, indexItem) => ({
          ...valueItem,
          selecionado:
            index === indexItem
              ? !valueItem.selecionado
              : valueItem.selecionado,
        }))
      );
      setIsStepAlterada(true);
    },
    [setIsStepAlterada]
  );

  const handleToggleSelecionarTodasFormaRecebimento = useCallback(() => {
    setSelecionarTodasFormasRecebimentos(!selecionarTodasFormasRecebimentos);
    setListFormaRecebimento((prev) =>
      prev.map((valueItem) => ({
        ...valueItem,
        selecionado: !selecionarTodasFormasRecebimentos,
      }))
    );
    setIsStepAlterada(true);
  }, [selecionarTodasFormasRecebimentos, setIsStepAlterada]);

  const getFormasRecebimento = useCallback(async () => {
    setIsLoading(true);

    const response = await getFormaRecebimentoSmartPos();
    setListFormaRecebimento(response);

    setIsLoading(false);
  }, []);

  const handleSalvarFormaRecebimento = useCallback(async () => {
    setIsLoading(true);

    const response = await cadastrarFormaRecebimentoSmartPos(
      formasRecebimentoSelecionadas
    );
    if (response) {
      toast.success('Cadastrado com sucesso');
      setIsStepAlterada(false);
    }
    setIsLoading(false);
  }, [formasRecebimentoSelecionadas, setIsStepAlterada]);

  useImperativeHandle(ref, () => ({
    handleSalvar: () => handleSalvarFormaRecebimento(),
    handleCancelar: () => getFormasRecebimento(),
  }));

  useEffect(() => {
    getFormasRecebimento();
  }, [getFormasRecebimento]);

  useEffect(() => {
    const todosItensSelecionados = listFormaRecebimento.every(
      (formaRecebimento) => formaRecebimento.selecionado
    );
    setSelecionarTodasFormasRecebimentos(todosItensSelecionados);
  }, [listFormaRecebimento]);

  return (
    <Container>
      {isLoading && <Loading />}
      <Box w={['full', 'full', 'full', '70%']} overflowX="scroll">
        <Box w={['800px', '800px', 'full']}>
          <VStack overflow="auto" bg="gray.50">
            <Flex
              p="16px 20px"
              pb="0"
              gap="12px"
              w="full"
              alignItems="center"
              cursor="pointer"
            >
              <Checkbox
                colorScheme="primary"
                mb="0px"
                isChecked={selecionarTodasFormasRecebimentos}
                onChange={() => handleToggleSelecionarTodasFormaRecebimento()}
              />
              <Text
                onClick={() => handleToggleSelecionarTodasFormaRecebimento()}
                fontSize="md"
              >
                Selecionar todas as formas de recebimento
              </Text>
            </Flex>
            {listFormaRecebimento.map((formaRecebimento, index) => (
              <Flex
                p="16px 20px"
                gap="12px"
                h="48px"
                w="full"
                transition="all 0.2s"
                bg={formaRecebimento.selecionado ? 'purple.50' : 'white'}
                borderWidth="1px"
                borderStyle="solid"
                borderColor={
                  formaRecebimento.selecionado ? 'purple.50' : 'white'
                }
                _hover={{
                  borderColor: 'rgba(85, 2, 178, 0.5)',
                  transition: 'all 0.2s',
                }}
                _active={{ bg: 'white' }}
                borderRadius="5px"
                alignItems="center"
                onClick={() => handleToggleSelecionarFormaRecebimento(index)}
                cursor="pointer"
              >
                <Checkbox
                  colorScheme="primary"
                  mb="0px"
                  onChange={() => handleToggleSelecionarFormaRecebimento(index)}
                  isChecked={formaRecebimento.selecionado}
                />
                <Text fontSize="md">{formaRecebimento.nome}</Text>
              </Flex>
            ))}
          </VStack>
        </Box>
      </Box>
    </Container>
  );
};
