import React, { useState, useEffect, useCallback } from 'react';
import {
  Flex,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  VStack,
  Switch,
  useDisclosure,
  ModalProps,
  Button,
  Icon,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';

import { SalvarConfirmarIcon } from 'icons';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';

export interface LojasInterface {
  id: string;
  fantasia: string;
  endereco: string;
  cidade: string;
  selecionado: boolean;
}

interface ModalLojasProps
  extends Omit<ModalProps, 'children' | 'isOpen' | 'onClose'>,
    InstanceProps<void> {
  lojasState: LojasInterface[];
  title?: string;
  handleLojas?: (newLojas: LojasInterface[], onClose?: () => void) => void;
}

const ModalLojasComponent = ({
  title = 'Selecionar lojas',
  lojasState,
  handleLojas,
}: ModalLojasProps) => {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const [lojas, setLojas] = useState<LojasInterface[]>([] as LojasInterface[]);

  const handleToogleLojaSelecionada = useCallback((idLoja: string) => {
    setLojas((prev) =>
      prev.map((lojaItem) => {
        const { id, selecionado } = lojaItem;

        return {
          ...lojaItem,
          selecionado: id === idLoja ? !selecionado : selecionado,
        };
      })
    );
  }, []);

  useEffect(() => {
    setLojas(lojasState);
  }, [lojasState]);

  return (
    <ModalPadraoChakra isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalContent px="16px">
        <ModalHeader pt="24px">
          <Text color="primary.50">{title}</Text>
        </ModalHeader>
        <ModalBody>
          {lojas.map((lojaItem) => (
            <Flex align="center" gap={6} mb={3}>
              <Switch
                name={`loja.${lojaItem.id}`}
                size="md"
                isChecked={lojaItem.selecionado}
                width="min-content"
                colorScheme="primary"
                onChange={() => handleToogleLojaSelecionada(lojaItem.id)}
              />
              <VStack w="full" align="start" gap={0}>
                <Text fontWeight="bold">
                  {`${lojaItem.fantasia} | ${lojaItem.cidade}`}
                </Text>
                <Text color="gray.300" fontSize=".65rem" mt="0 !important">
                  {lojaItem.endereco}
                </Text>
              </VStack>
            </Flex>
          ))}
        </ModalBody>
        <ModalFooter py="40px" justifyContent="center" gap={{ base: 4, md: 6 }}>
          <Button
            id="fechar"
            name="fechar"
            variant="cancel"
            colorScheme="red"
            minW="min-content"
            width={{ base: 'full', sm: '100px' }}
            fontWeight="normal"
            color="gray.500"
            borderColor="gray.500"
            onClick={onClose}
          >
            Fechar
          </Button>
          <Button
            id="salvarDocumentoExportacao"
            name="salvarDocumentoExportacao"
            colorScheme="secondary"
            minW="min-content"
            width={{ base: 'full', sm: '120px' }}
            fontWeight="normal"
            onClick={() => {
              if (handleLojas) {
                handleLojas(lojas, onClose);
              }
            }}
          >
            Confirmar
          </Button>
        </ModalFooter>
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export const ModalLojas = create<ModalLojasProps>(ModalLojasComponent);
