import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import getOptionsByEnum from 'helpers/format/getOptionsByEnum';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';

import { TableHeader } from 'components/update/Table/VirtualizedInfiniteTable';

export const tipoOperacaoOptions = (isReadonly?: boolean) =>
  getOptionsByEnum(IdentificacaoTipoOperacaoEnum, undefined, undefined, [
    IdentificacaoTipoOperacaoEnum.DEVOLUCAO_COMPRA,
    IdentificacaoTipoOperacaoEnum.BRINDE,
    IdentificacaoTipoOperacaoEnum.PERDA,
    IdentificacaoTipoOperacaoEnum.AJUSTE_ESTOQUE_SAIDA,
    IdentificacaoTipoOperacaoEnum.AJUSTE_ESTOQUE_ENTRADA,
    ...(isReadonly
      ? [
          IdentificacaoTipoOperacaoEnum.CONFERENCIA_ESTOQUE_ENTRADA,
          IdentificacaoTipoOperacaoEnum.CONFERENCIA_ESTOQUE_SAIDA,
        ]
      : []),
  ]);

export const produtosTableHeaders: TableHeader[] = [
  { key: 'produto', content: 'Produtos', width: '50%' },
  { key: 'tamanho', content: 'Tamanho', width: '25%' },
  {
    key: 'quantidade',
    content: 'Quantidade',
    isNumeric: true,
    width: '25%',
  },
  {
    key: 'acoes',
    content: 'Ações',
    textAlign: 'right',
    minWidth: '120px',
  },
];

export type FormData = {
  tipoOperacao: number;
  localEstoque: { value: string; label: string };
  usuario: string;
  dataEmissao: string;
  motivo?: string;
};

const schema = yup.object().shape({
  tipoOperacao: yup
    .number()
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  localEstoque: yup
    .object()
    .shape({
      value: yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .default(null),
      label: yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .default(null),
    })
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(null),
  motivo: yup.string().nullable(),
});

export const yupResolver = yupResolverInstance(schema);
