import { Box, Flex, Td, Tr, Text, Icon } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { FiChevronLeft } from 'react-icons/fi';

import getOptionsByEnum from 'helpers/format/getOptionsByEnum';
import StatusConsultaEnum from 'constants/enum/statusConsulta';

import { ContainerHeader } from 'pages/Integracoes/LojaAplicativos/Layout/ContainerHeader';
import { ButtonCadastrarNovo } from 'components/Layout/ButtonCadastrarNovo';
import { SearchInput } from 'components/update/Input/SearchInput';
import { PagedTable } from 'components/update/Table/PagedTable';
import { StatusCircle } from 'components/update/Table/StatusCircle';
import { FilterSelect } from 'components/update/Select/FilterSelect';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { useDepartamento } from './hooks';
import { usePainelFrenteCaixa } from '../../hooks';

const ListarDepartamentos = () => {
  const {
    formMethods,
    pagedTableRef,
    listDepartamentos,
    paginationHandle,
    filtersSubmit,
    handleCadastrarDepartamento,
    handleAlterarDepartamento,
    handleRemoverDepartamento,
    isLoading,
    totalRegistros,
  } = useDepartamento();
  const { handleVoltarPainelAdm } = usePainelFrenteCaixa();

  return (
    <FormProvider {...formMethods}>
      <ContainerHeader bg="#FF005A" gap={2} color="white" alignItems="center">
        <Box cursor="pointer" mr="10px" onClick={handleVoltarPainelAdm}>
          <Icon fontSize="25px" as={FiChevronLeft} />
        </Box>
        <Text fontSize="16px" fontWeight="semibold">
          Departamentos
        </Text>
      </ContainerHeader>
      <Box position="relative" p={6}>
        {isLoading && <LoadingPadrao />}
        <Flex
          justifyContent="space-between"
          direction={['column', 'column', 'row']}
        >
          <Flex mb={['20px', '20px', '0']} flex={1.5}>
            <SearchInput
              type="search"
              id="descricao"
              name="descricao"
              placeholder="Buscar departamento por nome"
              onEnterKeyPress={() => {
                filtersSubmit();
              }}
              isDisabled={isLoading}
            />
          </Flex>
          <Flex mb={['20px', '20px', '0']} ml={['0', '0', '24px']} flex={1}>
            <FilterSelect
              id="status"
              name="status"
              options={getOptionsByEnum(StatusConsultaEnum)}
              onSelect={() => {
                filtersSubmit();
              }}
              isDisabled={isLoading}
            />
          </Flex>
          <Flex justifyContent="flex-end" flex={2}>
            <ButtonCadastrarNovo
              onClick={handleCadastrarDepartamento}
              funcionalidade=""
            />
          </Flex>
        </Flex>
        <PagedTable
          ref={pagedTableRef}
          isLoading={isLoading}
          variant="card"
          bg="transparent"
          boxShadow="none"
          paddingRight="2px"
          paddingLeft="2px"
          pb="10px"
          pt={['10px', '10px', '']}
          paginationHasDivider={false}
          loadColumnsData={paginationHandle}
          itemsTotalCount={totalRegistros}
          defaultKeyOrdered="nome"
          tableHeaders={[
            {
              key: 'ativo',
              isOrderable: false,
              content: <StatusCircle hasValue={false} />,
              width: '1px',
            },
            {
              key: 'nome',
              content: 'Nome',
              isOrderable: false,
            },
            {
              key: 'acoes',
              content: 'Ações',
              isOrderable: false,
              width: '1px',
            },
          ]}
          renderTableRows={(listDepartamentos || []).map((departamento) => {
            const actionMenu = [
              {
                content: 'Editar',
                onClick: () => handleAlterarDepartamento(departamento.id),
              },
              {
                content: 'Remover',
                onClick: () => handleRemoverDepartamento(departamento.id),
              },
            ];

            return (
              <>
                <Tr>
                  <Td>
                    <StatusCircle isActive />
                  </Td>
                  <Td fontWeight="bold" whiteSpace="nowrap">
                    <Text
                      w="min"
                      cursor="pointer"
                      onClick={() => {
                        handleAlterarDepartamento(departamento.id);
                      }}
                      _hover={{ textDecor: 'underline' }}
                    >
                      {departamento.nome}
                    </Text>
                  </Td>
                  <Td>
                    <ActionsMenu items={actionMenu} />
                  </Td>
                </Tr>
                <Box h="5px" />
              </>
            );
          })}
        />
      </Box>
    </FormProvider>
  );
};

export { ListarDepartamentos };
