import {
  Td,
  Tr,
  Text,
  useMediaQuery,
  HStack,
  VStack,
  Box,
  Icon,
} from '@chakra-ui/react';
import { FiChevronLeft } from 'react-icons/fi';

import EnumStatus from 'constants/enum/enumStatus';

import { PagedTable } from 'components/update/Table/PagedTable';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import { StatusCircle } from 'components/update/Table/StatusCircle';
import { ContainerHeader } from 'pages/Integracoes/LojaAplicativos/Layout/ContainerHeader';

import { useDispositivo } from './hooks';
import { usePainelFrenteCaixa } from '../hooks';

export const PainelAdministradorFrenteCaixaDispositivo = () => {
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const {
    alterarStatusDispositivo,
    isLoading,
    totalRegistros,
    listarDispositivos,
    pagedTableRef,
    paginationHandle,
  } = useDispositivo();
  const { handleVoltarPainelAdm } = usePainelFrenteCaixa();

  return (
    <>
      <ContainerHeader bg="#FF005A" gap={2} color="white" alignItems="center">
        <Box cursor="pointer" mr="10px" onClick={handleVoltarPainelAdm}>
          <Icon fontSize="25px" as={FiChevronLeft} />
        </Box>
        <Text fontSize="16px" fontWeight="semibold">
          Dispositivos
        </Text>
      </ContainerHeader>
      <Box position="relative" p={6} w="full">
        <PagedTable
          ref={pagedTableRef}
          variant="card"
          pb="10px"
          pt={['10px', '10px', '']}
          paddingRight="2px"
          paddingLeft="2px"
          bg="transparent"
          boxShadow="none"
          defaultKeyOrdered="nome"
          paginationHasDivider={false}
          isLoading={isLoading}
          loadColumnsData={paginationHandle}
          itemsTotalCount={totalRegistros}
          isTable={isLargerThan900}
          tableHeaders={[
            {
              key: 'ativo',
              isOrderable: false,
              content: <StatusCircle hasValue={false} />,
              w: '1px',
              paddingRight: '0',
            },
            {
              key: 'apelido',
              content: 'Apelido',
              isOrderable: false,
            },
            {
              key: 'status',
              content: 'Status',
              isOrderable: false,
            },
            {
              key: 'acoes',
              content: 'Ações',
              isOrderable: false,
              w: '1px',
            },
          ]}
          renderTableRows={(listarDispositivos || []).map(
            (registroDispositivo) => {
              const valueStatus = EnumStatus.options.find(
                (itemStatus) => itemStatus.value === registroDispositivo.ativo
              );

              const actionMenu = [
                {
                  content: registroDispositivo.ativo ? 'Desativar' : 'Ativar',
                  onClick: () =>
                    alterarStatusDispositivo(
                      registroDispositivo.id,
                      registroDispositivo.ativo
                    ),
                },
              ];

              return (
                <>
                  {isLargerThan900 ? (
                    <>
                      <Tr
                        sx={{
                          boxShadow: '0px 0px 4px #00000029',
                          borderRadius: '6px',
                          '& > td': {
                            height: '30px',
                            bg:
                              valueStatus?.value === EnumStatus.ATIVOS
                                ? 'white'
                                : 'gray.50',
                          },
                        }}
                      >
                        <Td>
                          <StatusCircle isActive />
                        </Td>
                        <Td fontWeight="bold">{registroDispositivo.apelido}</Td>
                        <Td>
                          <Text
                            fontSize="14px"
                            color="white"
                            p="2px"
                            w="70px"
                            borderRadius="12px"
                            textAlign="center"
                            bg={
                              valueStatus?.value === EnumStatus.ATIVOS
                                ? 'aquamarine.600'
                                : 'red.500'
                            }
                          >
                            {valueStatus?.label}
                          </Text>
                        </Td>

                        <Td>
                          <ActionsMenu items={actionMenu} />
                        </Td>
                      </Tr>
                      <Box h="5px" />
                    </>
                  ) : (
                    <>
                      <VStack
                        alignItems="left"
                        textAlign="left"
                        borderRadius="5px"
                        w="full"
                        bg={
                          valueStatus?.value === EnumStatus.ATIVOS
                            ? 'white'
                            : 'gray.50'
                        }
                        pl="12px"
                        pr="12px"
                        pt="15px"
                        pb="15px"
                        spacing="10px"
                        boxShadow="0px 0px 4px #00000029"
                      >
                        <HStack alignItems="baseline">
                          <Text fontSize="10px" color="gray.500">
                            Apelido:
                          </Text>
                          <Text fontSize="14px" color="black" fontWeight="bold">
                            {registroDispositivo.apelido}
                          </Text>
                        </HStack>

                        <HStack
                          justifyContent="space-between"
                          alignItems="baseline"
                        >
                          <HStack>
                            <Text fontSize="10px" color="gray.500">
                              Status:
                            </Text>
                            <Text
                              fontSize="14px"
                              color="white"
                              p="2px"
                              pl="10px"
                              pr="10px"
                              borderRadius="12px"
                              bg={
                                valueStatus?.value === EnumStatus.ATIVOS
                                  ? 'aquamarine.600'
                                  : 'red.500'
                              }
                            >
                              {valueStatus?.label}
                            </Text>
                          </HStack>
                          <ActionsMenu items={actionMenu} />
                        </HStack>
                      </VStack>
                      <Box h="5px" />
                    </>
                  )}
                </>
              );
            }
          )}
        />
      </Box>
    </>
  );
};
