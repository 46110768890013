import React, { useCallback, useState } from 'react';

import { Container, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { UseFormRegister } from 'react-hook-form';
import ButtonPadrao from 'components/Button/ButtonPadrao';
import { SalvarConfirmarIcon } from 'icons';
import * as yup from 'yup';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import ModalPadrao from 'components/Modal/ModalPadrao';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react';
import ContatoAdicional from '../ContatosAdicionaisForm';
import { Modal } from './styles';

interface ContatosAdicionaisModalProps {
  show: boolean;
  onHide: (willReset?: boolean) => void;
  onSubmit: () => void;
  register: UseFormRegister<Record<string, any>>;
  control: any;
  getValues: any;
  setValues: any;
  readonly: any;
  indexContato: number;
  isWhats?: boolean;
  cadastroPdv?: boolean;
}

const ContatosAdicionaisModal: React.FC<ContatosAdicionaisModalProps> = ({
  show,
  onHide,
  onSubmit,
  register,
  control,
  getValues,
  setValues,
  readonly,
  indexContato,
  isWhats,
  cadastroPdv,
}) => {
  const { t } = useTranslation();

  const [errors, setErrors] = useState<any>({
    ContatoEmail: { message: '' },
  });

  const schema = yup.object().shape({
    ContatoEmail: yup
      .string()
      .nullable()
      .email(ConstanteMensagemValidacao.EMAIL_INVALIDO),
    ContatoTelefone: yup
      .string()
      .nullable()
      .notRequired()
      .test(
        'ContatoTelefone',
        ConstanteMensagemValidacao.TELEFONE_INVALIDO,
        (value) => {
          if (value) {
            const testSchema = yup.string().min(14).max(15);

            return testSchema.isValidSync(value);
          }

          return true;
        }
      ),
  });

  const handleSalvar = useCallback(() => {
    const data = getValues();

    const formEmail = {
      ContatoEmail: data.ContatoEmail,
    };

    const formTelefone = {
      ContatoTelefone: data.ContatoTelefone,
    };

    setErrors({
      ContatoEmail: {
        message: !schema.isValidSync(formEmail)
          ? ConstanteMensagemValidacao.EMAIL_INVALIDO
          : '',
      },
      ContatoTelefone: {
        message: !schema.isValidSync(formTelefone)
          ? ConstanteMensagemValidacao.TELEFONE_INVALIDO
          : '',
      },
    });

    if (!schema.isValidSync(formEmail) || !schema.isValidSync(formTelefone)) {
      return;
    }

    if (data.contatosAdicionais?.length > 0) {
      const contatoRemover = data.contatosAdicionais[indexContato];

      setValues(
        'contatosAdicionais',
        data.contatosAdicionais.filter((obj: any) => obj !== contatoRemover)
      );
    }
    onSubmit();

    setValues('ContatoNome', '');
    setValues('ContatoTelefone', '');
    setValues('ContatoEmail', '');
    setValues('ContatoTelefoneWhats', false);
  }, [onSubmit, setValues, getValues, indexContato, schema]);

  const FooterButtons = () => (
    <>
      <ButtonPadrao
        id="fechar"
        name="fechar"
        text={t('Fechar')}
        type="button"
        variant="outline-secondary"
        onClick={() => {
          setValues('ContatoNome', '');
          setValues('ContatoTelefone', '');
          setValues('ContatoEmail', '');
          setValues('ContatoTelefoneWhats', false);
          onHide(true);
        }}
        style={{ height: '35px', width: '100%', maxWidth: '100px' }}
      />
      <ButtonPadrao
        id="salvar-contato-adicional"
        name="salvar"
        text={t('Salvar')}
        icon={SalvarConfirmarIcon}
        type="button"
        variant="success"
        onClick={handleSalvar}
        style={{ height: '35px', width: '100%', maxWidth: '150px' }}
      />
    </>
  );

  return cadastroPdv ? (
    <ModalPadraoChakra isOpen={show} size="xl" onClose={onHide}>
      <ModalContent>
        <ModalHeader>{t('Contatos adicionais')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Form>
            <ContatoAdicional
              register={register}
              errors={errors}
              control={control}
              readonly={readonly}
              setValue={setValues}
              getValue={getValues}
              isWhats={isWhats}
              indexContato={indexContato}
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <FooterButtons />
        </ModalFooter>
      </ModalContent>
    </ModalPadraoChakra>
  ) : (
    <ModalPadrao
      style={{ zIndex: 1430 }}
      isOpen={show}
      handleOnHide={onHide}
      title={t('Contatos adicionais')}
      subtitle={t('Informe os dados do contato')}
    >
      <Modal.Body>
        <Container fluid>
          <Form>
            <ContatoAdicional
              register={register}
              errors={errors}
              control={control}
              readonly={readonly}
              setValue={setValues}
              getValue={getValues}
              isWhats={isWhats}
              indexContato={indexContato}
            />
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <FooterButtons />
      </Modal.Footer>
    </ModalPadrao>
  );
};

export default ContatosAdicionaisModal;
