import {
  GridItem,
  Text,
  Box,
  Checkbox,
  Grid,
  Flex,
  useMediaQuery,
} from '@chakra-ui/react';

import { VariationProps } from '../validationForm';

type VariationsProps = {
  allVariationSelected: boolean;
  handleToggleSelectAllVariations: (value: boolean) => void;
  handleToggleSelectVariation: (value: number) => void;
  listVariations: VariationProps[];
};

export const Variations = ({
  allVariationSelected,
  handleToggleSelectAllVariations,
  handleToggleSelectVariation,
  listVariations,
}: VariationsProps) => {
  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');

  return (
    <GridItem w="full" colSpan={12}>
      <Text color="gray.700" fontSize="14px">
        Selecione para qual variação será aplicado o novo preço:
      </Text>
      <Box
        borderWidth="1px"
        borderRadius="5px"
        borderColor="gray.200"
        borderStyle="solid"
        p="16px"
      >
        <Grid
          templateColumns={[
            'repeat(1, 1fr)',
            'repeat(1, 1fr)',
            'repeat(2, 1fr)',
          ]}
          mb="2px"
          rowGap="2px"
          gridGap="16px"
        >
          <GridItem
            h="28px"
            display="flex"
            pl="16px"
            pt="3px"
            alignItems="baseline"
            bg={allVariationSelected ? 'secondary.300' : 'white'}
            w="100%"
            borderRadius="5px"
            borderWidth="1px"
            borderColor="gray.200"
            borderStyle="solid"
          >
            <Box>
              <Checkbox
                colorScheme="primary"
                isChecked={allVariationSelected}
                onChange={(event) =>
                  handleToggleSelectAllVariations(event.target.checked)
                }
                mr="12px"
                mb="0"
              />
            </Box>
            <Box>
              <Text color="gray.700" fontSize="14px">
                Selecionar todas variações
              </Text>
            </Box>
          </GridItem>
        </Grid>
        <Grid
          templateColumns={[
            'repeat(1, 1fr)',
            'repeat(1, 1fr)',
            'repeat(2, 1fr)',
          ]}
          rowGap="2px"
          mr="-10px"
          sx={{
            '& > div': {
              marginRight: '6px',
            },
            '&::-webkit-scrollbar': {
              width: '4px',
              height: '5px',
              scrollbarWidth: 'thin',
              position: 'absolute',
              right: '-10px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'none',
              borderRadius: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'gray.100',
              borderRadius: '6px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: 'gray.100',
            },
          }}
          gridGap="16px"
          maxH="120px"
          overflowY="auto"
        >
          {listVariations.map((produtoVariacao, index) => {
            const nameProduto = `${
              produtoVariacao?.cor || produtoVariacao?.tamanho ? '' : ''
            }  ${
              produtoVariacao?.cor
                ? `${produtoVariacao?.cor} ${
                    produtoVariacao?.cor && produtoVariacao?.tamanho ? ' |' : ''
                  }`
                : ''
            } ${produtoVariacao?.tamanho}`;

            return (
              <Box>
                <Grid
                  pl="16px"
                  as="div"
                  h="28px"
                  pt="4px"
                  pr="16px"
                  alignItems="baseline"
                  bg={produtoVariacao.isChecked ? 'purple.50' : 'white'}
                  w="100%"
                  display="flex"
                  justifyContent="flex-start"
                  borderRadius="5px"
                  borderWidth="1px"
                  borderColor="gray.200"
                  borderStyle="solid"
                >
                  <Flex>
                    <Box>
                      <Checkbox
                        colorScheme="primary"
                        isChecked={produtoVariacao.isChecked}
                        onChange={() => handleToggleSelectVariation(index)}
                        mr="12px"
                        mb="0"
                      />
                    </Box>
                    <Box>
                      <Text color="gray.700" fontSize="14px">
                        {nameProduto.slice(0, !isSmallerThan900 ? 35 : 60)}
                        {nameProduto.length > (!isSmallerThan900 ? 35 : 60)
                          ? '...'
                          : ''}
                      </Text>
                    </Box>
                  </Flex>
                </Grid>
              </Box>
            );
          })}
        </Grid>
      </Box>
    </GridItem>
  );
};
