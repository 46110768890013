import React from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useMediaQuery,
  useDisclosure,
  ModalHeader,
  Text,
  ModalFooter,
  HStack,
  Button,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import getOptionsByEnum from 'helpers/format/getOptionsByEnum';
import { statusOperacaoOptions } from 'constants/enum/statusOperacao';
import TipoProdutoFiscalEnum from 'constants/enum/tipoProdutoFiscal';
import { TipoLancamentoEnum } from 'constants/enum/tipoLancamento';
import { formatOptionsSelectClient } from 'helpers/format/formatSelectClient';
import { StatusPesquisaClientesFornecedor } from 'constants/enum/statusPesquisaClientesFornecedor';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import AsyncSelect from 'components/PDV/Select/AsyncSelectPadrao';
import Select from 'components/PDV/Select/SelectPadrao';
import InputInteger from 'components/PDV/InputInteger';
import InputDateRange from 'components/PDV/InputDateRange';
import CampoContainer from 'components/PDV/Geral/CampoContainer';

import { FormData, formDefaultValues } from './validationForm';

type FornecedorOptionResponse = {
  id: string;
  nome: string;
  endereco: string;
  codigo: number;
  cpfCnpj: string;
};

type ModalBuscaAvancadaProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<FormData> & {
    currentFilters: FormData;
  };

export const ModalBuscaAvancada = create<ModalBuscaAvancadaProps, FormData>(
  ({ onResolve, onReject, currentFilters, ...rest }) => {
    const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const formMethods = useForm<FormData>({
      defaultValues: { ...formDefaultValues, ...currentFilters },
    });

    async function getFornecedoresOptions(inputValue: string) {
      if (inputValue) {
        const response = await api.get<
          void,
          ResponseApi<FornecedorOptionResponse[]>
        >(ConstanteEnderecoWebservice.CLIENTE_FORNECEDOR_LISTAR_SELECT, {
          params: {
            cpfCnpjNomeApelidoCodigoExterno: inputValue,
            filtroTipoCadastroPessoa:
              StatusPesquisaClientesFornecedor.FORNECEDORES,
          },
        });

        if (response) {
          if (response.avisos) {
            response.avisos.map((aviso: string) => toast.warning(aviso));
          }

          if (response.sucesso && response.dados) {
            return response.dados.map((fornecedor) => {
              const option = formatOptionsSelectClient(fornecedor);
              return option;
            });
          }
        }
      }

      return [];
    }

    function handleCancelar() {
      onReject();
    }

    const handleSubmit = formMethods.handleSubmit((data) => {
      onResolve(data);
    });

    return (
      <ModalPadraoChakra
        size={isSmallerThan900 ? 'full' : 'xl'}
        {...rest}
        isCentered
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalContent
          mx={isSmallerThan900 ? '0' : '6'}
          my={isSmallerThan900 ? '0' : '6'}
          maxW={{ base: undefined, md: '640px' }}
        >
          <ModalHeader
            mt={isSmallerThan900 ? 12 : undefined}
            mb={isSmallerThan900 ? 8 : undefined}
            borderBottom="1px"
            borderColor="gray.100"
            px="0"
            mx={{ base: 6, md: 8 }}
          >
            <Text color="primary.50" fontSize="md">
              Busca avançada
            </Text>
            <Text color="gray.700" fontSize="xs">
              Utilize os filtros de busca para uma pesquisa específica. Todos os
              campos são acumulativos.
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p={{ base: 6, md: 8 }}>
            <FormProvider {...formMethods}>
              <SimpleGridForm>
                <AsyncSelect
                  id="fornecedorId"
                  isClearable
                  name="fornecedorId"
                  label="Nome do fornecedor"
                  asControlledByObject
                  placeholder="Digite a razão social ou nome fantasia"
                  handleGetOptions={getFornecedoresOptions}
                  colSpan={12}
                  shouldAppearTheAddress
                />
                <InputInteger
                  id="numeroNFe"
                  name="numeroNFe"
                  label="Número da NFe"
                  placeholder="Número da nota fiscal"
                  colSpan={{ base: 12, sm: 6 }}
                />
                <InputInteger
                  id="identificador"
                  name="identificador"
                  label="Identificador"
                  placeholder="Número da operação"
                  colSpan={{ base: 12, sm: 6 }}
                />
                <CampoContainer
                  id="dataEmissao"
                  name="dataEmissao"
                  label="Data de emissão"
                  colSpan={{ base: 12, sm: 6 }}
                >
                  <InputDateRange
                    borderColor="gray.100"
                    borderRadius="md"
                    isClearable
                    placeholder="Selecione um período"
                    startDateName="dataEmissaoInicio"
                    endDateName="dataEmissaoFim"
                    maxDate={new Date()}
                  />
                </CampoContainer>
                <CampoContainer
                  id="dataEntrada"
                  name="dataEntrada"
                  label="Data de entrada"
                  colSpan={{ base: 12, sm: 6 }}
                >
                  <InputDateRange
                    borderColor="gray.100"
                    placeholder="Selecione um período"
                    borderRadius="md"
                    isClearable
                    startDateName="dataEntradaInicio"
                    endDateName="dataEntradaFim"
                    maxDate={new Date()}
                  />
                </CampoContainer>
                <Select
                  id="situacao"
                  name="situacao"
                  label="Situação"
                  options={statusOperacaoOptions}
                  colSpan={{ base: 12, sm: 6 }}
                />
                <Select
                  id="finalidade"
                  name="finalidade"
                  label="Finalidade da entrada"
                  options={[
                    { value: null, label: 'Todos' },
                    ...getOptionsByEnum(TipoProdutoFiscalEnum),
                  ]}
                  colSpan={{ base: 12, sm: 6 }}
                />
                <Select
                  id="statusLancamentoEstoque"
                  name="statusLancamentoEstoque"
                  label="Status do lançamento de estoque"
                  options={getOptionsByEnum(TipoLancamentoEnum)}
                  colSpan={{ base: 12, sm: 6 }}
                />
                <Select
                  id="statusLancamentoFinanceiro"
                  name="statusLancamentoFinanceiro"
                  label="Status do lançamento financeiro"
                  options={getOptionsByEnum(TipoLancamentoEnum)}
                  colSpan={{ base: 12, sm: 6 }}
                />
              </SimpleGridForm>
            </FormProvider>
          </ModalBody>
          <ModalFooter
            justifyContent="flex-end"
            borderTop="1px"
            borderColor="gray.100"
            mx={{ base: 6, md: 8 }}
            px="0"
          >
            <HStack spacing={6}>
              <Button
                borderRadius="md"
                color="gray.400"
                variant="outline"
                minW="96px"
                onClick={handleCancelar}
              >
                Cancelar
              </Button>
              <Button
                colorScheme="aquamarine"
                borderRadius="md"
                minW="120px"
                onClick={handleSubmit}
              >
                Pesquisar
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
