const IcmsCstCsosnEnum = {
  // Cst
  CstTributadaIntegralmente: 0,
  CstTributadaComCobrancaPorSubstituicaoTributaria: 10,
  CstReducaoBaseCalculo: 20,
  CstIsentaNaoTributadaComCobrancaPorSubstituicaoTributaria: 30,
  CstIsenta: 40,
  CstNaoTributada: 41,
  CstSuspensao: 50,
  CstDiferimento: 51,
  CstIcmsCobradoAnteriormentePorSubstituicaoTributaria: 60,
  CstReducaoBaseCalculoCobrancaPorSubstituicaoTributaria: 70,
  CstOutros: 90,

  // Csosn
  CsosnTributadaSimplesNacionalComPermissaoCredito: 101,
  CsosnTributadaSimplesNacionalSemPermissaoCredito: 102,
  CsosnIsencaoIcmsSimplesNacionalFaixaReceitaBruta: 103,
  CsosnTributadaSimplesNacionalComPermissaoCreditoComCobrancaPorSubstituicaoTributaria: 201,
  CsosnTributadaSimplesNacionalSemPermissaoCreditoComCobrancaPorSubstituicaoTributaria: 202,
  CsosnIsencaoIcmsSimplesNacionalFaixaReceitaBrutaComCobrancaPorSubstituicaoTributaria: 203,
  CsosnImune: 300,
  CsosnNaoTributadaSimplesNacional: 400,
  CsosnIcmsCobradoAnteriormente: 500,
  CsosnOutros: 900,

  properties: {
    1: { name: '00 - Tributada integralmente', value: 0 },
    2: {
      name: '10 - Tributada e com cobrança do ICMS por substituição tributária',
      value: 10,
    },
    3: { name: '20 - Com redução de base de cálculo', value: 20 },
    4: {
      name:
        '30 - Isenta ou não tributada e com cobrança do ICMS por substituição tributária',
      value: 30,
    },
    5: {
      name: '40 - Isenta',
      value: 40,
    },
    6: {
      name: '41 - Não tributada',
      value: 41,
    },
    7: {
      name: '50 - Suspensão',
      value: 50,
    },
    8: {
      name: '51 - Diferimento',
      value: 51,
    },
    9: {
      name: '60 - ICMS cobrado anteriormente por substituição tributária',
      value: 60,
    },
    10: {
      name:
        '70 - Com redução de base de cálculo e cobrança do ICMS por substituição tributária',
      value: 70,
    },
    11: {
      name: '90 - Outros',
      value: 90,
    },
    12: {
      name: '101 - Tributada pelo simples nacional com permissão de crédito',
      value: 101,
    },
    13: {
      name: '102 - Tributada pelo simples nacional sem permissão de crédito',
      value: 102,
    },
    14: {
      name:
        '103 - Isenção do ICMS no simples nacional para faixa de receita bruta',
      value: 103,
    },
    15: {
      name:
        '201 - Tributada pelo simples nacional com permissão de crédito e com cobrança do ICMS por substituição tributária',
      value: 201,
    },
    16: {
      name:
        '202 - Tributada pelo simples nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária',
      value: 202,
    },
    17: {
      name:
        '203 - Isenção do ICMS nos simples nacional para faixa de receita bruta e com cobrança do ICMS por substituição tributária',
      value: 203,
    },
    18: {
      name: '300 - Imune',
      value: 300,
    },
    19: {
      name: '400 - Não tributada pelo simples nacional',
      value: 400,
    },
    20: {
      name:
        '500 - ICMS cobrado anteriormente por substituição tributária(substituído) ou por antecipação',
      value: 500,
    },
    21: {
      name: '900 - Outros',
      value: 900,
    },
  },
};

export default IcmsCstCsosnEnum;
