import React, { useState, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { Flex, Icon, useTheme } from '@chakra-ui/react';
import ImportedCreatableSelect from 'react-select/creatable';
import {
  Props,
  OptionTypeBase,
  GroupedOptionsType,
  OptionsType,
} from 'react-select';

import auth from 'modules/auth';
import StatusConsultaEnum from 'constants/enum/statusConsulta';

import { SalvarInserirNovoIcon } from 'icons';
import ContainerInputPadrao, {
  CampoContainerPadraoProps,
} from 'components/Layout/CampoContainer/CampoContainerPadrao';
import {
  chakraStyles,
  chakraComponents,
  ChakraReactSelectContainer,
} from 'components/PDV/Select/ReactSelectIntegracao';

interface CreatableMultiSelectProps
  extends Props<OptionTypeBase>,
    CampoContainerPadraoProps {
  id: string;
  name: string;
  defaultValue?: any;
  colorSelectedValue?: string;
  onSelect?: () => void;
  readonly?: boolean;
  placeholder?: string;
  options: GroupedOptionsType<OptionTypeBase> | OptionsType<OptionTypeBase>;
  createValue: (
    inputValue: string
  ) => Promise<OptionTypeBase | undefined> | OptionTypeBase | undefined;
  maxLength?: number;
  creatableInputTextPreffix?: string;
  optionBackgroundColor?: string;
  optionForegroundColor?: string;
  funcionalidadeCadastrar?: string;
  controlledByObject?: boolean;
  required?: boolean;
  variant?: string;
  size?: string;
  isMulti?: boolean;
  isDisabledOption?: boolean;
  tipoFiltro?: number;
}

type OptionFilter = {
  value: string;
  label: string;
  data: {
    value: string;
    label: string;
    __isNew__: boolean;
  };
};

const CreatableMultiSelect = ({
  id,
  name,
  label,
  required,
  defaultValue,
  error,
  colorSelectedValue,
  infoText,
  infoOnClick,
  onSelect,
  readonly,
  variant,
  placeholder,
  isDisabled,
  options,
  isMulti,
  isDisabledOption = false,
  size,
  onChange: SelectOnChange,
  createValue,
  isLoading: propsIsLoading,
  maxLength,
  creatableInputTextPreffix,
  optionBackgroundColor = 'gray.500',
  optionForegroundColor = 'white',
  funcionalidadeCadastrar = '',
  controlledByObject,
  tipoFiltro = StatusConsultaEnum.TODOS,
  components,
  ...rest
}: CreatableMultiSelectProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const { colors, radii } = useTheme();

  const placeholderColor = colors.gray[400];

  const possuiPermissao = funcionalidadeCadastrar
    ? auth.possuiPermissao(funcionalidadeCadastrar).permitido
    : true;

  const handleCreate = useCallback(
    async (inputValue: string, onChange: (value: any) => void) => {
      setIsLoading(true);

      const newOption = await createValue(inputValue);

      if (newOption) {
        onChange(controlledByObject ? newOption : newOption.value);
      }

      setIsLoading(false);
    },
    [controlledByObject, createValue]
  );

  const customFilterOption = (option: OptionFilter, rawInput: string) => {
    const itemOption = rawInput.toLowerCase().split(' ');
    return (
      // eslint-disable-next-line no-underscore-dangle
      option.data.__isNew__ ||
      itemOption.reduce(
        (acc: boolean, cur: string) =>
          acc && option.label.toLowerCase().includes(cur),
        true
      )
    );
  };

  return (
    <ChakraReactSelectContainer
      variant={variant}
      size={size}
      isDisabled={isDisabled}
      isMulti={isMulti}
    >
      <ContainerInputPadrao
        id={id}
        label={label}
        error={error}
        required={required}
        infoText={infoText}
        infoOnClick={infoOnClick}
      >
        <Controller
          defaultValue={defaultValue}
          render={({ field }) => (
            <ImportedCreatableSelect
              id={id}
              ref={field.ref}
              classNamePrefix="react-select"
              isMulti
              filterOption={customFilterOption}
              tipoFiltro={tipoFiltro}
              options={options}
              onChange={(newValues: any, actionMeta: any) => {
                const newValuesArray = newValues
                  ? newValues.map((newValue: any) =>
                      controlledByObject ? newValue : newValue.value
                    )
                  : [];
                field.onChange(newValuesArray);

                if (SelectOnChange) SelectOnChange(newValuesArray, actionMeta);
                if (onSelect) onSelect();
              }}
              onCreateOption={(inputValue: any) =>
                handleCreate(inputValue, (newValue: any) => {
                  field.onChange([...field.value, newValue]);
                })
              }
              value={
                controlledByObject
                  ? field.value
                  : options?.filter((option: OptionTypeBase) =>
                      field.value.includes(option.value)
                    ) || null
              }
              noOptionsMessage={() => 'Não há valores disponíveis'}
              placeholder={placeholder}
              creatableInputTextPreffix={
                possuiPermissao ? creatableInputTextPreffix : ''
              }
              isDisabled={readonly || isDisabled || propsIsLoading || isLoading}
              createOptionPosition="first"
              formatCreateLabel={(userInput: string) =>
                possuiPermissao ? (
                  <Flex
                    color="blue.500"
                    justifyContent="flex-start"
                    alignItems="center"
                    width="100%"
                    height="100%"
                  >
                    <Icon as={SalvarInserirNovoIcon} mr="5px" />
                    <Flex
                      height="100%"
                      maxWidth="100%"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      lineHeight="0.95"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {possuiPermissao ? creatableInputTextPreffix : ''} &quot;
                      <strong>{userInput}</strong>
                      &quot;
                    </Flex>
                  </Flex>
                ) : undefined
              }
              theme={(baseTheme) => ({
                ...baseTheme,
                borderRadius: radii.md,
                colors: {
                  ...baseTheme.colors,
                  neutral50: placeholderColor, // placeholder text color
                  neutral40: placeholderColor, // noOptionsMessage color
                },
                spacing: {
                  ...baseTheme.spacing,
                },
              })}
              isLoading={isLoading || propsIsLoading}
              components={components || chakraComponents}
              styles={chakraStyles(
                colorSelectedValue,
                optionBackgroundColor,
                optionForegroundColor,
                isDisabledOption || false
              )}
              {...rest}
            />
          )}
          name={`${name}` as const}
        />
      </ContainerInputPadrao>
    </ChakraReactSelectContainer>
  );
};

export default CreatableMultiSelect;
