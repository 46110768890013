export const EnumSmartPos = {
  DISPOSITIVO: {
    name: 'Dispositivo',
    value: 0,
  },
  CATEGORIAS: {
    name: 'Categorias',
    value: 1,
  },
  FORMA_RECEBIMENTO: {
    name: 'Forma de recebimento',
    value: 2,
  },
  IMPRESSAO: {
    name: 'Impressão',
    value: 3,
  },
  CONFIGURACOES: {
    name: 'Configurações',
    value: 4,
  },

  itensSmartPos: [
    {
      name: 'Dispositivos',
      value: 0,
    },
    {
      name: 'Categorias',
      value: 1,
    },
    {
      name: 'Forma de recebimento',
      value: 2,
    },
    {
      name: 'Impressão',
      value: 3,
    },
    {
      name: 'Configurações',
      value: 4,
    },
  ],
};
