import React, { memo } from 'react';
import { IconType as ReactIconsIconType } from 'react-icons/lib';
import { IconType } from 'icons';

import ValidarPermissao from 'components/Validacao/ValidarPermissao';
import { Button, Text, IconContainer } from './styles';

interface ButtonPadraoProps
  extends React.InputHTMLAttributes<HTMLButtonElement> {
  id: string;
  name: string;
  text?: string;
  type: 'button' | 'submit' | 'reset';
  variant?: string;
  icon?: IconType | ReactIconsIconType;
  funcionalidade?: string;
}

const ButtonPadrao = ({
  text,
  icon: Icon,
  funcionalidade,
  variant,
  onClick,
  ...rest
}: ButtonPadraoProps) => {
  return (
    <ValidarPermissao funcionalidade={funcionalidade} tooltipWrap="container">
      {({ permitido, BloqueadoIcone }) => (
        <Button
          variant={permitido ? variant : 'outline-secondary'}
          bloqueado={permitido ? 0 : 1}
          onClick={permitido ? onClick : undefined}
          {...rest}
        >
          {Icon && (
            <IconContainer>
              <Icon />
            </IconContainer>
          )}
          {text && <Text>{text}</Text>}

          {BloqueadoIcone && (
            <BloqueadoIcone
              bloqueioPermissaoIconeStyle={{
                marginLeft: '15px',
                color: 'var(--black)',
                fontSize: '0.9375rem',
              }}
              bloqueioPlanoIconeStyle={{
                marginLeft: '15px',
                color: 'var(--black)',
                fontSize: '0.9375rem',
              }}
            />
          )}
        </Button>
      )}
    </ValidarPermissao>
  );
};

export default memo(ButtonPadrao);
