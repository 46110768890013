import { useState } from 'react';
import { Box, Divider, Flex, Text, Grid, GridItem } from '@chakra-ui/react';

import { DecimalMask, moneyMask } from 'helpers/format/fieldsMasks';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';
import { ProdutoSelecionadoProps } from 'pages/PDV/TrocarProdutos/Types/validationForm';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';

import { InputQuantidadeAcrescimoDecrescimo } from './InputQuantidadeAcresimoDecrescimo';

type ResolucaoMobileTableteProps = {
  produtosTroca: ProdutoSelecionadoProps[];
  valorTotalQuantidade: number[];
  handleExcluirProduto(index: number): void;
  quantidade: number[];
  handleDecrementarQuantidade(index: number): void;
  handleIncrementarQuantidade(index: number): void;
};

export function ResolucaoMobileTablet({
  produtosTroca,
  handleExcluirProduto,
  handleIncrementarQuantidade,
  quantidade,
  handleDecrementarQuantidade,
  valorTotalQuantidade,
}: ResolucaoMobileTableteProps) {
  const [mostrarDetalhesProduto, setDetalhesProduto] = useState(-1);

  function exibirDetalhesProduto(index: number) {
    setDetalhesProduto(mostrarDetalhesProduto === index ? -1 : index);
  }

  const { casasDecimais } = usePadronizacaoContext();
  return (
    <Box overflow="hidden" boxShadow="md" rounded="md" bg="white">
      <Flex
        paddingTop="10px"
        paddingBottom="10px"
        justifyContent="space-between"
        w="full"
        color="gray.500"
        fontSize="xs"
      >
        <Flex ml="20px" whiteSpace="nowrap">
          Descrição
        </Flex>
        <Box mr="30px" whiteSpace="nowrap">
          <Flex justifyContent="right" alignItems="right">
            <Text>Valor total</Text>
          </Flex>
        </Box>
      </Flex>
      <Flex justifyContent="center" alignItems="center">
        <Divider w="95%" />
      </Flex>
      <Box overflow="hidden !important">
        {produtosTroca.map((produto, index) => {
          const quantidadePossuiDecimal = produto.quantidade % 1 !== 0;
          const valorTotal =
            (produto.valor - produto.desconto) * quantidade[index];

          const exibirQuantidade =
            !quantidadePossuiDecimal && produto.quantidade > 1;

          const valorTotalDesconto = produto.desconto * quantidade[index];

          const valorTotalAcrescimo = produto.acrescimo * quantidade[index];

          return (
            <Box>
              <Grid
                templateAreas={`"descricao valortotal"
                  "qtd valortotal"
                  "infAdicional infAdicional"`}
                gridTemplateRows={exibirQuantidade ? '1fr 50px' : '1fr 10px'}
                gridTemplateColumns="1fr 150px "
                gap="0"
                ml="20px"
                mr="5px"
              >
                <GridItem
                  onClick={() => exibirDetalhesProduto(index)}
                  area="descricao"
                >
                  <Flex justifyContent="left" alignItems="top">
                    <Text mt="7px" fontSize="sm">
                      {produto.nome}
                    </Text>
                  </Flex>
                </GridItem>
                <GridItem area="valortotal">
                  <Flex justifyContent="right" alignItems="top">
                    <Text mt="7px" fontSize="sm">
                      {moneyMask(valorTotal, true)}
                    </Text>
                    <Flex mt="6px" mr="7px">
                      <ActionsMenu
                        items={[
                          {
                            content: 'Remover',
                            onClick: () => handleExcluirProduto(index),
                          },
                        ]}
                        menuZIndex="modal"
                      />
                    </Flex>
                  </Flex>
                </GridItem>
                {exibirQuantidade && (
                  <GridItem area="qtd">
                    <Flex justifyContent="left" alignItems="left">
                      <InputQuantidadeAcrescimoDecrescimo
                        handleDecrementarQuantidade={
                          handleDecrementarQuantidade
                        }
                        handleIncrementarQuantidade={
                          handleIncrementarQuantidade
                        }
                        produto={produto}
                        quantidade={quantidade}
                        index={index}
                      />
                    </Flex>
                  </GridItem>
                )}
                {mostrarDetalhesProduto === index && (
                  <GridItem mb="10px" fontSize="xs" area="infAdicional">
                    {!exibirQuantidade && (
                      <Flex>
                        <Text mr="5px">Quantidade:</Text>
                        <Text>
                          {DecimalMask(valorTotalQuantidade[index], 2, 2)}
                        </Text>
                      </Flex>
                    )}
                    <Flex>
                      <Text mr="5px">Valor unitário:</Text>
                      <Text>{`R$ ${DecimalMask(
                        produto.valor,
                        casasDecimais.casasDecimaisValor,
                        casasDecimais.casasDecimaisValor
                      )}`}</Text>
                    </Flex>

                    <Flex>
                      <Text mr="5px">Desconto:</Text>
                      <Text>{`R$ ${DecimalMask(
                        valorTotalDesconto,
                        2,
                        2
                      )}`}</Text>
                    </Flex>
                    <Flex>
                      <Text mr="5px">Acréscimo:</Text>
                      <Text>{`R$ ${DecimalMask(
                        valorTotalAcrescimo,
                        2,
                        2
                      )}`}</Text>
                    </Flex>
                  </GridItem>
                )}
              </Grid>
              <Flex justifyContent="center" alignItems="center">
                <Divider w="95%" />
              </Flex>
            </Box>
          );
        })}
        <Box paddingBottom="10px" mr="40px" paddingTop="10px">
          <Flex mb="5px" justifyContent="right" alignItems="center">
            <Text fontSize="xs" mr="4px">
              Valor devolvido:
            </Text>

            <Text fontSize="sm" fontWeight="bold">
              {moneyMask(
                produtosTroca.reduce((acc, curr, index) => {
                  const valorCadaProduto =
                    (curr.valor - curr.desconto) * quantidade[index];
                  return valorCadaProduto + acc;
                }, 0),
                true
              )}
            </Text>
          </Flex>
          <Flex justifyContent="right" alignItems="center">
            <Text fontSize="xs" mr="4px">
              Quantidade de itens:
            </Text>
            <Text fontSize="sm" fontWeight="bold">
              {DecimalMask(
                valorTotalQuantidade.reduce((acc, curr) => acc + curr, 0),
                casasDecimais.casasDecimaisQuantidade,
                casasDecimais.casasDecimaisQuantidade
              )}
            </Text>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
}
