import React, { ReactNode, useState } from 'react';
import {
  NumberInput as ChakraNumberInput,
  NumberInputProps as ChakraNumberInputProps,
  NumberInputField,
  InputGroup,
  InputLeftElement,
  ColorProps,
  TypographyProps,
} from '@chakra-ui/react';

import CampoPrototipo, {
  CampoPrototipoProps,
} from 'components/PDV/Geral/CampoPrototipo';

type NumberInputProps = CampoPrototipoProps &
  ChakraNumberInputProps & {
    name: string;
    defaultValue?: number;
    leftElement?: ReactNode;
    rightElement?: ReactNode;
    precision?: number;
    scale?: number;
    saveAs?: (valueAsNumber?: number, valueAsString?: string) => any;
    getValueAsString?: (value: any) => string;
    canBeUndefined?: boolean;
    leftElementColor?: ColorProps['color'];
    leftElementFontSize?: TypographyProps['fontSize'];
    autoFocus?: boolean;
    actionLinkText?: string;
    onValueChange?: (parsedValue: string | number) => void;
  };

export function InputNumber({
  name,
  defaultValue = 0,
  leftElement,
  rightElement,
  scale = 2,
  precision,
  max,
  min,
  saveAs,
  getValueAsString,
  canBeUndefined = false,
  placeholder,
  id,
  label,
  colSpan,
  colStart,
  colEnd,
  rowSpan,
  rowStart,
  rowEnd,
  isRequired = false,
  helperText,
  leftElementColor = 'gray.500',
  leftElementFontSize,
  autoFocus = false,
  onValueChange,
  bg,
  _disabled,
  maxW,
  pl,
  size,
  actionLinkText,
  actionLinkOnClick,
  ...rest
}: NumberInputProps) {
  const inputMinForAccessibility = min || 0;
  const inputMaxForAccessibility =
    max ||
    Number(
      `${'9'.repeat(precision ? precision - scale : 1)}.${'9'.repeat(scale)}`
    );

  const [endHomePressed, setEndHomePressed] = useState(true);

  const getPlaceholder = () => {
    if (!canBeUndefined) {
      return '';
    }

    if (placeholder) {
      return placeholder;
    }

    const placeholderValue = '0'.repeat(precision || scale + 1);

    return scale > 0
      ? `${placeholderValue.substring(
          0,
          placeholderValue.length - scale
        )},${placeholderValue.slice(scale * -1)}`
      : placeholderValue;
  };

  const format = (value: any) => {
    let valueAsString = '';

    try {
      if (
        canBeUndefined &&
        ((!value && value !== 0) ||
          (getValueAsString &&
            !getValueAsString(value) &&
            getValueAsString(value) !== '0'))
      ) {
        return undefined;
      }

      valueAsString = getValueAsString
        ? getValueAsString(value)
        : Number(value || 0).toString();
    } catch {
      if (canBeUndefined) {
        return undefined;
      }

      valueAsString = '0';
    }

    if (scale > 0) {
      const [integerPart, decimalPart = ''] = valueAsString.split('.');

      const integerPartMaxLength = precision
        ? precision - scale
        : integerPart.length;

      if (decimalPart) {
        valueAsString = `${integerPart
          .slice(0, integerPartMaxLength)
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.')},${decimalPart.padEnd(
          scale,
          '0'
        )}`;
      } else {
        valueAsString = `${integerPart
          .slice(0, integerPartMaxLength)
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.')},${'0'.repeat(scale)}`;
      }
    } else {
      valueAsString = valueAsString.replace(/\D/g, '');

      if (precision) {
        valueAsString = valueAsString.slice(0, precision);
      }
    }

    return valueAsString;
  };

  const parse = (valueAsString: string) => {
    if (!valueAsString) {
      if (canBeUndefined) {
        return saveAs ? saveAs() : undefined;
      }

      return saveAs ? saveAs(0, '0') : 0;
    }

    let valueAsNumber = 0;

    let parsedValueAsString = valueAsString.replace(/\D/g, '');

    if (precision) {
      parsedValueAsString = parsedValueAsString
        .replace(/^0+/, '')
        .slice(0, precision);
    }

    if (scale > 0) {
      parsedValueAsString = parsedValueAsString.padStart(scale + 1, '0');

      parsedValueAsString = `${parsedValueAsString.substring(
        0,
        parsedValueAsString.length - scale
      )}.${parsedValueAsString.slice(scale * -1)}`;
    }

    try {
      valueAsNumber = Number(parsedValueAsString);
    } catch {
      valueAsNumber = 0;
    }

    if (min && valueAsNumber < min) {
      valueAsNumber = min;
    } else if (max && valueAsNumber > max) {
      valueAsNumber = max;
    }

    return saveAs
      ? saveAs(valueAsNumber, valueAsNumber.toString())
      : valueAsNumber;
  };

  return (
    <CampoPrototipo
      id={id}
      name={name}
      defaultValue={defaultValue || canBeUndefined ? undefined : 0}
      isRequired={isRequired}
      helperText={helperText}
      label={label}
      rowSpan={rowSpan}
      rowStart={rowStart}
      rowEnd={rowEnd}
      colSpan={colSpan}
      colStart={colStart}
      colEnd={colEnd}
      maxW={maxW}
      actionLinkText={actionLinkText}
      actionLinkOnClick={actionLinkOnClick}
    >
      {(_, { value, onChange, ref, onBlur }) => (
        <InputGroup size={size}>
          {leftElement && (
            <InputLeftElement
              pointerEvents="none"
              fontSize={leftElementFontSize}
              color={leftElementColor}
              whiteSpace="nowrap"
              h="full"
            >
              {leftElement}
            </InputLeftElement>
          )}

          <ChakraNumberInput
            w="100%"
            {...rest}
            size={size}
            value={format(value)}
            onChange={(valueAsString) => {
              if (endHomePressed) {
                return;
              }
              const parsedValue = parse(valueAsString);

              onChange(parsedValue);

              if (onValueChange) {
                onValueChange(parsedValue);
              }
            }}
            onKeyDownCapture={(e) => {
              if (e.key === 'Home' || e.key === 'End') {
                setEndHomePressed(true);
              } else {
                setEndHomePressed(false);
              }
            }}
            isValidCharacter={(character: string) =>
              !!character.match(/^[0-9]$/)
            }
            onBlur={onBlur}
            step={0}
            id={id}
            isRequired={isRequired}
            clampValueOnBlur={false}
            min={inputMinForAccessibility}
            max={inputMaxForAccessibility}
          >
            <NumberInputField
              textAlign="right"
              ref={ref}
              placeholder={getPlaceholder()}
              onFocus={(event) => {
                event.stopPropagation();
                event.preventDefault();

                event.currentTarget.select();
              }}
              pl={pl || 'var(--number-input-field-padding)'}
              pr="4"
              autoFocus={autoFocus}
              bg={bg}
              _disabled={_disabled}
            />
          </ChakraNumberInput>
        </InputGroup>
      )}
    </CampoPrototipo>
  );
}
