import { Box, Flex, Icon, IconButton, Text } from '@chakra-ui/react';
import { FiMinus, FiPlus } from 'react-icons/fi';

import { NumberInput } from 'components/update/Input/NumberInput';
import { ProdutoSelecionadoProps } from 'pages/PDV/TrocarProdutos/Types/validationForm';

type InputAcrescimoDecrescimoTrocasProps = {
  produto: ProdutoSelecionadoProps;
  quantidade: number[];
  index: number;
  handleDecrementarQuantidade(index: number): void;
  handleIncrementarQuantidade(index: number): void;
};

export function InputQuantidadeAcrescimoDecrescimo({
  produto,
  quantidade,
  index,
  handleDecrementarQuantidade,
  handleIncrementarQuantidade,
}: InputAcrescimoDecrescimoTrocasProps) {
  return (
    <Box>
      <Flex justifyContent="right" alignItems="right" w="100%">
        <IconButton
          aria-label="Decrementar"
          icon={<Icon as={FiMinus} />}
          variant="outline"
          _focus={{
            bg: 'transparent',
          }}
          _hover={{
            bg: 'transparent',
          }}
          _active={{
            bg: 'gray.100',
          }}
          borderRadius="md"
          w="32px"
          onClick={() => handleDecrementarQuantidade(index)}
        />

        <Box>
          <NumberInput
            id="quantidade"
            name={`quantidade.${index}`}
            ml="2px"
            mr="2px"
            scale={produto.volumeUnitario === false ? 1 : 0}
            color="gray.600"
            max={produto.quantidade}
            fontSize={{
              base: 'sm',
              md: 'md',
            }}
            w="80px"
          />
        </Box>

        <IconButton
          aria-label="Incrementar"
          icon={<Icon as={FiPlus} />}
          variant="outline"
          className="teste"
          _focus={{
            bg: 'transparent',
          }}
          _hover={{
            bg: 'transparent',
          }}
          _active={{
            bg: 'gray.100',
          }}
          borderRadius="md"
          w="32px"
          isDisabled={produto.quantidade === quantidade[index]}
          minW={{
            base: '1px',
            sm: 9,
          }}
          onClick={() => handleIncrementarQuantidade(index)}
        />
      </Flex>

      {quantidade[index] === 0 && (
        <Flex justifyContent="center" alignItems="center">
          <Text fontSize="10px" color="red">
            A quantidade não pode ser 0
          </Text>
        </Flex>
      )}
    </Box>
  );
}
