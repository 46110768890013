import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import auth from 'modules/auth';
import { ResponseApi } from 'services/api';
import { sistemaApiKey } from 'services/sistemaApiKey';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import enumReferenciaServicoStargate from 'constants/enum/referenciaServicoStargate';
import ConstanteFuncionalidades from 'constants/permissoes';
import ConstanteRotas from 'constants/rotas';

import { ModalRemoverDadosIntegracao } from 'components/update/Modal/ModalRemoverDadosIntegracao';

import { InfoIntegracaoProps } from '../PainelAdministrador/validationForms';

export const usePdvOfflineData = () => {
  const history = useHistory();

  const [dataAtivacaoPdvOffline, setDataAtivacaoPdvOffline] = useState<Date>(
    new Date()
  );
  const [isLoading, setIsLoading] = useState(false);

  const possuiPermissao = auth.possuiServico(
    enumReferenciaServicoStargate.DISPOSITIVO_PDV
  ).permitido;

  const possuiPermissaoExcluirIntegracao = auth.possuiPermissao(
    ConstanteFuncionalidades.PDV_OFFLINE_EXCLUIR
  ).permitido;

  const listaItensInfo = [
    'Para acessar o painel de configurações da integração, clique em: Integrações > Pdv Offline',
    'Para outras dúvidas ou problemas, acesse nossa central de ajuda ou entre em contato com nossa equipe técnica.',
  ];

  const obterPdvOffline = useCallback(async () => {
    setIsLoading(true);
    const response = await sistemaApiKey().get<
      void,
      ResponseApi<InfoIntegracaoProps>
    >(ConstanteEnderecoWebservice.PDV_AUTONOMO_OBTER_INTEGRACAO);

    if (response) {
      if (response.avisos) {
        if (possuiPermissao) {
          history.push(ConstanteRotas.PDV_OFFLINE);
        } else {
          history.push(ConstanteRotas.LOJA_APLICATIVOS);
        }
        setIsLoading(false);
      }
      if (response.sucesso) {
        setDataAtivacaoPdvOffline(response.dados.dataAtivacao);
        setIsLoading(false);
      }
    }
  }, [history, possuiPermissao]);

  const excluirIntegracao = async () => {
    if (!possuiPermissaoExcluirIntegracao) {
      toast.warn(
        'Você não tem permissão para realizar essa ação. Consulte o administrador da conta.'
      );
      return;
    }

    ModalRemoverDadosIntegracao({
      callback: async () => {
        setIsLoading(true);
        const response = await sistemaApiKey().post<void, ResponseApi>(
          ConstanteEnderecoWebservice.PDV_AUTONOMO_DESISTIR_INTEGRACAO
        );
        if (response) {
          if (response.avisos) {
            response.avisos.forEach((aviso: string) => toast.warning(aviso));
          }
          if (response.sucesso) {
            history.push(ConstanteRotas.LOJA_APLICATIVOS);
          }
        }
        setIsLoading(false);
      },
    });
  };

  useEffect(() => {
    obterPdvOffline();
  }, [obterPdvOffline]);

  return {
    dataAtivacaoPdvOffline,
    listaItensInfo,
    isLoading,
    excluirIntegracao,
  };
};
