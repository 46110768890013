import {
  HStack,
  Icon,
  Text,
  Divider,
  Box,
  Menu,
  MenuItem,
  Button,
  MenuList,
  MenuButton,
  Flex,
} from '@chakra-ui/react';
import { FiChevronDown, FiChevronRight, FiChevronUp } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { useFormContext } from 'react-hook-form';

import { moneyMask } from 'helpers/format/fieldsMasks';
import { useEmissaoNotaFiscalContext } from 'store/NotaFiscal/EmissaoNotaFiscal';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ModalidadesFreteEnum from 'constants/enum/fiscal/modalidadeFrete';

import { NFeIcon } from 'icons';
import Input from 'components/PDV/Input';
import { DateInput } from 'components/update/Input/DateInput';
import AsyncSelect from 'components/PDV/Select/AsyncSelectPadrao';
import { TransportadorInterface } from 'components/Select/SelectTransportador';
import { ListVendasProps } from 'pages/Integracoes/LojaAplicativos/TrayCommerce/PainelAdministradorTray/VendasTray/validationForms';

import { CardHeader } from '../Components/CardHeader';
import { NotaFiscalExibirTela } from '../validation';

type TelaInicialProps = {
  isMultipleVendas: boolean;
  amountVendas: number;
  listNotaFiscal: ListVendasProps[];
};

export const TelaInicial = ({
  isMultipleVendas,
  amountVendas,
  listNotaFiscal,
}: TelaInicialProps) => {
  const { handleNextTela } = useEmissaoNotaFiscalContext();

  const { watch } = useFormContext();

  const modalidadeFreteWatch = watch('modalidadeFrete');
  const dataEmissao = watch('dataEmissao');

  const valueModalidadeFrete =
    ModalidadesFreteEnum.options.find(
      (modalidadeFreteItem) =>
        modalidadeFreteItem.value === (modalidadeFreteWatch || 0)
    )?.label || '';

  const valorOrigem = () => {
    let origem = '';

    if (listNotaFiscal.length === 1) {
      origem = listNotaFiscal[0].origem;
    }

    const hasMesmaOrigem = listNotaFiscal.every(
      (item) => item.origem === listNotaFiscal[0].origem
    );

    if (hasMesmaOrigem) {
      origem = listNotaFiscal[0].origem;
    } else {
      origem = '-';
    }

    return origem;
  };

  const handleGetTransportadorasOptions = async (newInputValue: string) => {
    const response = await api.get<void, ResponseApi<TransportadorInterface[]>>(
      ConstanteEnderecoWebservice.TRANSPORTADORA_LISTAR_SELECT_NOTA_FISCAL,
      {
        params: { nome: newInputValue },
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso) {
        return response.dados.map((transportador: TransportadorInterface) => {
          return {
            label: transportador.nome,
            value: transportador.id,
          };
        });
      }
    }

    return [];
  };

  return (
    <>
      <CardHeader>
        <HStack spacing="20px">
          <Icon as={NFeIcon} w="110px" h="auto" maxH="50px" />
          <Divider h="25px" bg="black" w="1px" orientation="vertical" />
          <Text color="black" fontWeight="semibold" fontSize="20px">
            Emissão de Nota Fiscal Eletrônica
          </Text>
        </HStack>
        <Box>
          {isMultipleVendas ? (
            <Menu variant="ghost">
              {({ isOpen: isOpenMenu }) => (
                <>
                  <MenuButton
                    _hover={{
                      background: 'none',
                    }}
                    _active={{
                      color: 'none',
                    }}
                    _focus={{
                      boxShadow: 'none',
                    }}
                    variant="ghost"
                    isActive={isOpenMenu}
                    as={Button}
                    rightIcon={
                      !isOpenMenu ? <FiChevronDown /> : <FiChevronUp />
                    }
                  >
                    {amountVendas} vendas selecionadas
                  </MenuButton>
                  <MenuList>
                    {listNotaFiscal.map((itemNotaFiscal, index) => (
                      <MenuItem
                        px="12px"
                        borderRadius="6px"
                        _focus={{
                          bg: 'none',
                          boxShadow: 'none',
                        }}
                        _active={{
                          bg: 'purple.100',
                        }}
                        fontSize="14px"
                      >
                        <Box
                          w="full"
                          pb="8px"
                          borderBottomWidth={
                            index + 1 === amountVendas ? '0' : '1px'
                          }
                          borderColor="gray.100"
                        >
                          <Text color="gray.700" fontSize="14px">
                            Venda: {itemNotaFiscal.numeroPedido}
                          </Text>
                          <Text
                            textAlign="start"
                            color="gray.400"
                            fontSize="12px"
                          >
                            {moneyMask(itemNotaFiscal.valorTotal, true)}
                          </Text>
                        </Box>
                      </MenuItem>
                    ))}
                  </MenuList>
                </>
              )}
            </Menu>
          ) : (
            <>
              <Text color="gray.700" fontSize="14px">
                Venda: {listNotaFiscal[0].numeroPedido}
              </Text>
              <Text textAlign="end" color="gray.400" fontSize="12px">
                {moneyMask(listNotaFiscal[0].valorTotal, true)}
              </Text>
            </>
          )}
        </Box>
      </CardHeader>
      <HStack px="24px" mb="38px" spacing="8%">
        <Box w={['full', 'full', '72%']}>
          <AsyncSelect
            id="transportadoraId"
            name="transportadoraId"
            label="Transportadora"
            required
            autoFocus
            placeholder="Selecione a transportadora"
            asControlledByObject
            handleGetOptions={handleGetTransportadorasOptions}
          />
          <Text mt="2px" textAlign="right" color="gray.700" fontSize="12px">
            Modalidade do frete: {valueModalidadeFrete}
          </Text>
        </Box>
        <Box>
          <Text
            display="flex"
            onClick={() =>
              handleNextTela(NotaFiscalExibirTela.DADOS_TRANSPORTE)
            }
            cursor="pointer"
            alignItems="center"
            fontSize="14px"
            color="violet.500"
          >
            Editar dados de transporte <FiChevronRight />
          </Text>
        </Box>
      </HStack>
      <Divider />
      <HStack px="24px" mt="28px" spacing="8%">
        <Flex w={['full', 'full', '72%']}>
          <Box w={['full', 'full', '60%']}>
            <Input
              isDisabled
              defaultValue={valorOrigem()}
              label="Identificação do Intermediador"
              id="identificacaoIntermediador"
              placeholder="informe a identificação do intermediador"
              name="identificacaoIntermediador"
            />
          </Box>
          <Box ml="24px" w={['full', 'full', '40%']}>
            <DateInput
              id="dataSaida"
              name="dataSaida"
              label="Data de saída"
              minDate={dataEmissao}
            />
          </Box>
        </Flex>
        <Flex h="40px" alignItems="flex-end">
          <Text
            display="flex"
            cursor="pointer"
            alignItems="center"
            fontSize="14px"
            color="violet.500"
            onClick={() => handleNextTela(NotaFiscalExibirTela.DADOS_NOTA)}
          >
            Editar dados gerais da nota <FiChevronRight />
          </Text>
        </Flex>
      </HStack>
    </>
  );
};
