import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  Divider,
  Box,
  Stack,
  HStack,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Link,
  useMediaQuery,
  Skeleton,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';

import api, { ResponseApi } from 'services/api';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { DecimalMask } from 'helpers/format/fieldsMasks';
import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';
import { formatDate } from 'helpers/format/formatStringDate';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { FormData } from '../validationForm';
import { InformacaoStatusVencimento } from '../types';

type MovimentacaoFinanceiraBaixa = {
  dataPagamento: Date;
  formaPagamento: string;
  contaFinanceira: string;
  desconto: number;
  multaJuros: number;
  valor: number;
  nomeUsuario: string;
};

type ObterDetalhesContaPagarResponse = {
  dataVencimento: Date;
  fornecedorRazaoSocial: string;
  fornecedorNomeFantasia: string;
  valor: number;
  planoContas: string;
  parcela: string;
  dataEmissao: Date;
  competencia: Date;
  historico: string;
  numeroDocumento: string;
  numeroNFe: string;
  identificador: number;
  tipoOperacao: number;
  operacaoId: string;
  movimentacaoFinanceiraBaixa: MovimentacaoFinanceiraBaixa[];
};

type ModalDetalhesPedidoProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<FormData> & {
    id: string;
    informacaoStatusVencimento: InformacaoStatusVencimento;
    casasDecimaisValor: number;
    naoExibirDetalhesConta?: boolean;
    faturaId?: string;
  };

export const ModalDetalhesContaPagar = create<
  ModalDetalhesPedidoProps,
  FormData
>(
  ({
    onResolve,
    id,
    naoExibirDetalhesConta = false,
    informacaoStatusVencimento,
    casasDecimaisValor,
    onReject,
    faturaId,
    ...rest
  }) => {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const [
      isInformacoesContaPagarLoading,
      setIsInformacoesContaPagarLoading,
    ] = useState(false);
    const [
      informacoesContaPagar,
      setInformacoesContaPagar,
    ] = useState<ObterDetalhesContaPagarResponse>();

    const [isLargerThan1200] = useMediaQuery('(min-width: 1200px)');
    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    useEffect(() => {
      async function obterDetalhesContaPagar() {
        setIsInformacoesContaPagarLoading(true);

        const response = await api.get<
          void,
          ResponseApi<ObterDetalhesContaPagarResponse>
        >(ConstanteEnderecoWebservice.CONTAS_PAGAR_OBTER_DETALHE, {
          params: { movimentacaoFinanceiraId: id },
        });

        if (response) {
          if (response.avisos) {
            response.avisos.map((item: string) => toast.warning(item));
          }

          if (response.sucesso && response.dados) {
            setInformacoesContaPagar(response.dados);
          }
        }

        setIsInformacoesContaPagarLoading(false);
      }

      obterDetalhesContaPagar();
    }, [id]);

    let tipoIdentificador = '';
    function handlePushVerDetalheConta() {
      let href = '';
      const Operacoes = Object.entries(
        IdentificacaoTipoOperacaoEnum.properties
      ).map((item) => ({
        name: item[1].name,
      }));

      if (
        informacoesContaPagar?.tipoOperacao ===
          IdentificacaoTipoOperacaoEnum.DEVOLUCAO_COMPRA ||
        informacoesContaPagar?.tipoOperacao ===
          IdentificacaoTipoOperacaoEnum.BRINDE ||
        informacoesContaPagar?.tipoOperacao ===
          IdentificacaoTipoOperacaoEnum.PERDA ||
        informacoesContaPagar?.tipoOperacao ===
          IdentificacaoTipoOperacaoEnum.BRINDE ||
        informacoesContaPagar?.tipoOperacao ===
          IdentificacaoTipoOperacaoEnum.AJUSTE_ESTOQUE_SAIDA ||
        informacoesContaPagar?.tipoOperacao ===
          IdentificacaoTipoOperacaoEnum.AJUSTE_ESTOQUE_ENTRADA ||
        informacoesContaPagar?.tipoOperacao ===
          IdentificacaoTipoOperacaoEnum.CONFERENCIA_ESTOQUE_SAIDA ||
        informacoesContaPagar?.tipoOperacao ===
          IdentificacaoTipoOperacaoEnum.CONFERENCIA_ESTOQUE_ENTRADA
      ) {
        href = SubstituirParametroRota(
          ConstanteRotas.MOVIMENTACAO_ESTOQUE_VISUALIZAR,
          'id',
          informacoesContaPagar?.operacaoId
        );
        tipoIdentificador =
          Operacoes[informacoesContaPagar.tipoOperacao - 1].name;
      } else if (
        informacoesContaPagar?.tipoOperacao ===
          IdentificacaoTipoOperacaoEnum.CONTAS_PAGAR &&
        !faturaId
      ) {
        href = SubstituirParametroRota(
          ConstanteRotas.CONTAS_PAGAR_VISUALIZAR,
          'id',
          informacoesContaPagar?.operacaoId
        );
        tipoIdentificador =
          Operacoes[informacoesContaPagar.tipoOperacao - 1].name;
      } else if (
        informacoesContaPagar?.tipoOperacao ===
          IdentificacaoTipoOperacaoEnum.VENDA ||
        informacoesContaPagar?.tipoOperacao ===
          IdentificacaoTipoOperacaoEnum.PEDIDO ||
        informacoesContaPagar?.tipoOperacao ===
          IdentificacaoTipoOperacaoEnum.ORCAMENTO
      ) {
        href = SubstituirParametroRota(
          ConstanteRotas.OPERACOES_DETALHE,
          'id',
          informacoesContaPagar?.operacaoId
        );
        tipoIdentificador =
          Operacoes[informacoesContaPagar.tipoOperacao - 1].name;
      } else if (
        informacoesContaPagar?.tipoOperacao ===
          IdentificacaoTipoOperacaoEnum.PEDIDO_COMPRA ||
        informacoesContaPagar?.tipoOperacao ===
          IdentificacaoTipoOperacaoEnum.ENTRADA_MERCADORIA ||
        informacoesContaPagar?.tipoOperacao ===
          IdentificacaoTipoOperacaoEnum.PAGAMENTO_COMPRA ||
        informacoesContaPagar?.tipoOperacao ===
          IdentificacaoTipoOperacaoEnum.COMPRA_MERCADORIA
      ) {
        href = SubstituirParametroRota(
          ConstanteRotas.ENTRADA_MERCADORIA_ENTRADA_MANUAL_VISUALIZAR,
          'id',
          informacoesContaPagar?.operacaoId
        );
        tipoIdentificador =
          Operacoes[informacoesContaPagar.tipoOperacao - 1].name;
      }
      return href;
    }

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? '6xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
      >
        <ModalContent
          bg="gray.50"
          width={['full', 'full', 'full', '1000px']}
          h={['full', 'full', '450px']}
        >
          <Flex flexDirection="column" align="stretch">
            {isInformacoesContaPagarLoading && <LoadingPadrao />}
            <ModalBody pb="4" pt="5">
              <Stack
                width="100%"
                spacing={{ base: '2', lg: '4' }}
                direction={{ base: 'column', md: 'row' }}
                justifyContent={{ base: 'center', xl: 'flex-start' }}
                alignItems="center"
                minH={{ base: '20', md: '16' }}
                px="8"
                bg={informacaoStatusVencimento.corFundo}
                borderRadius="md"
              >
                <Flex w="100%" justifyContent="space-between">
                  <Box>
                    <Text color="black">
                      {informacoesContaPagar?.fornecedorRazaoSocial}
                    </Text>
                    <Text color={informacaoStatusVencimento.corFonte}>
                      {informacoesContaPagar?.fornecedorNomeFantasia}
                    </Text>
                  </Box>
                  <Box>
                    <Text color="black">Valor original</Text>
                    <Flex justifyContent="right" alignItems="right">
                      <Skeleton isLoaded={!isInformacoesContaPagarLoading}>
                        <Text
                          color={informacaoStatusVencimento.corFonte}
                          fontWeight="bold"
                        >
                          {`R$ ${DecimalMask(
                            informacoesContaPagar?.valor,
                            casasDecimaisValor,
                            casasDecimaisValor
                          )}`}
                        </Text>
                      </Skeleton>
                    </Flex>
                  </Box>
                </Flex>
              </Stack>
              <Flex mt="-2">
                <HStack
                  display={{ base: 'column', lg: 'flex' }}
                  w="100%"
                  h="36"
                  pl="2px"
                  fontSize="xs"
                >
                  <Box
                    mt={{ base: '6', lg: '0' }}
                    h={{ base: '33%', lg: '60%' }}
                    w={{ base: '98%', lg: '63%' }}
                  >
                    <Flex mb={{ base: '0', lg: '3' }}>
                      <Text fontSize="xs" mr="2">
                        Data de vencimento:
                      </Text>
                      <Text fontWeight="bold">
                        {informacoesContaPagar && (
                          <>
                            {formatDate(
                              informacoesContaPagar?.dataVencimento || ''
                            )}
                          </>
                        )}
                      </Text>
                    </Flex>
                    <Flex mb={{ base: '0', lg: '3' }}>
                      <Text fontSize="xs" mr="2">
                        Plano de contas:
                      </Text>
                      <Text fontWeight="bold">
                        {informacoesContaPagar?.planoContas}
                      </Text>
                    </Flex>
                    <Flex>
                      <Text fontSize="xs" mr="2">
                        Parcela:
                      </Text>
                      <Text fontWeight="bold">
                        {informacoesContaPagar?.parcela}
                      </Text>
                    </Flex>
                  </Box>
                  <Flex
                    mt={{ base: '2', lg: '0' }}
                    width={{ base: '97%', lg: '2rem' }}
                    height={{ base: '3', lg: '62%' }}
                  >
                    <Divider
                      color="gray.200"
                      mt={{ base: '2', lg: '0' }}
                      ml={{ base: '-7px', lg: '0' }}
                      orientation={isLargerThan1200 ? 'vertical' : 'horizontal'}
                      w={isLargerThan1200 ? '58px' : 'full'}
                      h={{ base: '0', lg: '96%' }}
                    />
                  </Flex>

                  <Box
                    h={{ base: '33%', lg: '60%' }}
                    w={{ base: '98%', lg: '63%' }}
                  >
                    <Flex mb={{ base: '0', lg: '3' }}>
                      <Text ml={{ base: '-7px', lg: '0' }} fontSize="xs" mr="2">
                        Data da emissão:
                      </Text>

                      <Text fontWeight="bold">
                        {informacoesContaPagar && (
                          <>
                            {formatDate(
                              informacoesContaPagar?.dataEmissao || ''
                            )}
                          </>
                        )}
                      </Text>
                    </Flex>
                    <Flex mb={{ base: '0', lg: '3' }}>
                      <Text ml={{ base: '-7px', lg: '0' }} fontSize="xs" mr="2">
                        Competência:
                      </Text>
                      <Text fontWeight="bold">
                        {informacoesContaPagar && (
                          <>
                            {formatDate(
                              informacoesContaPagar?.competencia || ''
                            )}
                          </>
                        )}
                      </Text>
                    </Flex>

                    <Flex position="absolute">
                      <Text ml={{ base: '-7px', lg: '0' }} fontSize="xs" mr="2">
                        Histórico:
                      </Text>
                      <Text fontWeight="bold">
                        {informacoesContaPagar?.historico
                          ? informacoesContaPagar?.historico
                          : '---'}
                      </Text>
                    </Flex>
                  </Box>
                  <Flex
                    justifyContent="flex-start"
                    mt={{ base: '2', lg: '0' }}
                    width={{ base: '97%', lg: '2rem' }}
                    height={{ base: '3', lg: '62%' }}
                  >
                    <Divider
                      color="gray.200"
                      mt={{ base: '2', lg: '0' }}
                      ml={{ base: '-7px', lg: '0' }}
                      orientation={isLargerThan1200 ? 'vertical' : 'horizontal'}
                      w={isLargerThan1200 ? '58px' : 'full'}
                      h={{ base: '0', lg: '58%' }}
                    />
                  </Flex>
                  <Box
                    h={{ base: '33%', lg: '60%' }}
                    w={{ base: '98%', lg: '58%' }}
                  >
                    <Flex mb={{ base: '0', lg: '3' }}>
                      <Text ml={{ base: '-7px', lg: '0' }} fontSize="xs" mr="2">
                        Número do documento:
                      </Text>
                      <Text fontWeight="bold">
                        {informacoesContaPagar?.numeroDocumento ??
                          informacoesContaPagar?.numeroNFe ??
                          '---'}
                      </Text>
                    </Flex>
                    <Flex>
                      <Text ml={{ base: '-7px', lg: '0' }} fontSize="xs" mr="2">
                        Identificador:
                      </Text>
                      {faturaId ? (
                        <Text fontWeight="bold"> --- </Text>
                      ) : (
                        <Link
                          id="link-visualizar"
                          target="_blank"
                          href={handlePushVerDetalheConta()}
                        >
                          {tipoIdentificador}{' '}
                          {informacoesContaPagar?.identificador}
                        </Link>
                      )}
                    </Flex>
                  </Box>
                </HStack>
              </Flex>
              {naoExibirDetalhesConta === false && (
                <Box
                  mt={{ base: '40px', lg: '-2px' }}
                  maxW="full"
                  overflow="auto"
                  border="1px"
                  borderColor="gray.100"
                  borderRadius="md"
                >
                  <Table
                    boxShadow="md"
                    p="6"
                    rounded="md"
                    variant=""
                    bg="white"
                    borderRadius="md"
                    sx={{
                      '& th, & td': {
                        position: 'relative',
                        _after: {
                          content: '" "',
                          position: 'absolute',
                          left: 0,
                          top: 0,
                          h: 'full',
                          w: 'full',
                          borderBottom: '1px',
                          borderColor: 'gray.100',
                        },
                      },
                      '& th:first-child, & td:first-child': {
                        _after: {
                          left: 3,
                        },
                      },
                      '& th:last-child, & td:last-child': {
                        _after: {
                          right: 3,
                          left: 'auto',
                        },
                      },
                      '& tr:last-child > td': {
                        _after: {
                          display: 'none',
                        },
                      },
                    }}
                  >
                    <Thead color="gray.700">
                      <Tr>
                        <Th whiteSpace="nowrap">Data do pagamento</Th>
                        <Th whiteSpace="nowrap">Forma do pagamento</Th>
                        <Th whiteSpace="nowrap">Conta financeira</Th>
                        <Th whiteSpace="nowrap" textAlign="right">
                          Valor original
                        </Th>
                        <Th whiteSpace="nowrap" textAlign="right">
                          Multa e juros
                        </Th>
                        <Th whiteSpace="nowrap" textAlign="right">
                          Desconto
                        </Th>
                        <Th whiteSpace="nowrap" textAlign="right">
                          Valor total
                        </Th>
                        <Th whiteSpace="nowrap" textAlign="right">
                          Operador
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {informacoesContaPagar &&
                      informacoesContaPagar.movimentacaoFinanceiraBaixa
                        .length === 0 ? (
                        <Tr>
                          <Td colSpan={9}>
                            <Text>Nenhum resultado foi encontrado</Text>
                          </Td>
                        </Tr>
                      ) : (
                        informacoesContaPagar?.movimentacaoFinanceiraBaixa.map(
                          (movimentacaoFinanceiraBaixa) => {
                            return (
                              <Tr>
                                <Td>
                                  {formatDate(
                                    movimentacaoFinanceiraBaixa.dataPagamento
                                  )}
                                </Td>
                                <Td>
                                  {movimentacaoFinanceiraBaixa.formaPagamento}
                                </Td>
                                <Td>
                                  {movimentacaoFinanceiraBaixa.contaFinanceira}
                                </Td>
                                <Td textAlign="right">
                                  {`R$ ${DecimalMask(
                                    movimentacaoFinanceiraBaixa.valor,
                                    casasDecimaisValor,
                                    casasDecimaisValor
                                  )}`}
                                </Td>
                                <Td textAlign="right">
                                  {`R$ ${DecimalMask(
                                    movimentacaoFinanceiraBaixa.multaJuros,
                                    casasDecimaisValor,
                                    casasDecimaisValor
                                  )}`}
                                </Td>
                                <Td textAlign="right">
                                  {`R$ ${DecimalMask(
                                    movimentacaoFinanceiraBaixa.desconto,
                                    casasDecimaisValor,
                                    casasDecimaisValor
                                  )}`}
                                </Td>
                                <Td textAlign="right">
                                  {`R$ ${DecimalMask(
                                    movimentacaoFinanceiraBaixa.multaJuros -
                                      movimentacaoFinanceiraBaixa.desconto +
                                      movimentacaoFinanceiraBaixa.valor,
                                    casasDecimaisValor,
                                    casasDecimaisValor
                                  )}`}
                                </Td>
                                <Td>
                                  {movimentacaoFinanceiraBaixa?.nomeUsuario}
                                </Td>
                              </Tr>
                            );
                          }
                        )
                      )}
                    </Tbody>
                  </Table>
                </Box>
              )}

              <Flex
                alignItems="center"
                justifyContent="center"
                mt={naoExibirDetalhesConta ? '-10px' : '8'}
                mb="4"
              >
                <Button
                  w="96px"
                  onClick={onClose}
                  colorScheme="gray"
                  variant="outlineDefault"
                >
                  Fechar
                </Button>
              </Flex>
            </ModalBody>
          </Flex>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
