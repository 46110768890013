import React, { useState } from 'react';
import { Button, Flex, Icon } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { ObservacaoIcon, ObservacaoPreenchidaIcon } from 'icons';
import NavHeaderButton from 'components/PDV/Layout/NavHeader/Button';
import { GlobalHotKeys } from 'react-hotkeys';
import ModalCadastro from './ModalCadastro';

interface ObservacoesProps {
  asNavHeaderButton?: boolean;
}

const Observacoes = ({ asNavHeaderButton }: ObservacoesProps) => {
  const [showModalCadastro, setShowModalCadastro] = useState(false);

  const { watch } = useFormContext();

  const observacoesWatch = watch('observacao');

  const keyMap = {
    F7: ['f7'],
  };

  const handlersHotKeys = {
    F7: (event: any) => {
      event.preventDefault();
      setShowModalCadastro(true);
    },
  };

  return (
    <>
      {asNavHeaderButton ? (
        <NavHeaderButton
          icon={ObservacaoIcon}
          title={observacoesWatch ? 'Ver observações' : 'Adicionar observações'}
          titleColor={observacoesWatch ? 'red.200' : 'gray.100'}
          colorScheme={observacoesWatch ? 'red' : 'gray'}
          onClick={() => setShowModalCadastro(true)}
          hasIndicator
        />
      ) : (
        <>
          <GlobalHotKeys keyMap={keyMap} handlers={handlersHotKeys} />

          <Flex>
            <Button
              fontWeight="normal"
              color={observacoesWatch ? 'red.200' : 'white'}
              variant="link"
              onClick={() => setShowModalCadastro(true)}
              mt="-1"
              fontSize="sm"
            >
              {observacoesWatch ? 'Ver observações' : 'Adicionar observações'}
              {observacoesWatch && (
                <Icon
                  as={ObservacaoPreenchidaIcon}
                  ml="2"
                  fontSize="md"
                  color="red.200"
                />
              )}
            </Button>
          </Flex>
        </>
      )}
      {showModalCadastro && (
        <ModalCadastro
          show={showModalCadastro}
          setIsOpen={setShowModalCadastro}
        />
      )}
    </>
  );
};

export default Observacoes;
