import { GridItem, Text, GridItemProps, Box, Flex } from '@chakra-ui/react';

import { InfoTooltip } from 'components/update/Tooltip/InfoTooltip';

type ContainerProps = GridItemProps & {
  children: React.ReactNode;
  title: string;
  helperText?: string;
};

export const Container = ({
  children,
  title,
  colSpan = 12,
  pt = '20px',
  h = 'full',
  helperText,
  ...rest
}: ContainerProps) => {
  return (
    <GridItem minH="10px" h={h} pt={pt} colSpan={colSpan} {...rest}>
      <Text fontSize="14px">{title}</Text>
      <Box
        h="full"
        bg="gray.50"
        p="20px"
        borderWidth="1px"
        borderColor="gray.200"
        borderRadius="6px"
      >
        <Flex>
          {children}
          {helperText && (
            <InfoTooltip p="10px" borderRadius="6px" label={helperText || ''} />
          )}
        </Flex>
      </Box>
    </GridItem>
  );
};
