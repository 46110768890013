import { useListPermissoesValidar } from 'hooks/useListPermissoesValidar';
import ConstanteFuncionalidades from 'constants/permissoes';

const PermissaoRelatorioVendas = (): string => {
  const listFuncionalidades = [
    ConstanteFuncionalidades.RELATORIO_VENDAS_POR_DIA,
    ConstanteFuncionalidades.RELATORIO_VENDAS_POR_FORMA_RECEBIMENTO,
    ConstanteFuncionalidades.RELATORIO_VENDAS_TOTALIZADAS_VENDEDORES,
    ConstanteFuncionalidades.RELATORIO_VENDA_SIMPLIFICADAS,
  ];

  const validacaoFuncionalidade = useListPermissoesValidar(listFuncionalidades);

  return validacaoFuncionalidade;
};

export default PermissaoRelatorioVendas;
