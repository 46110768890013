import { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { shallowEqual } from 'helpers/validation/shallowEqual';

import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import { PaginationData } from 'components/update/Pagination';
import { PagedTableForwardRefData } from 'components/update/Table/PagedTable';

type ListarSetoresEntregaProps = {
  id: string;
  nome: string;
  ativo: boolean;
};

type FormData = {
  pesquisa: string;
  status: number;
};

const defaultValues = {
  pesquisa: '',
  status: 1,
};

export const useSetoresEntrega = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [currentFilters, setCurrentFilters] = useState(defaultValues);
  const [listSetoresEntrega, setListSetoresEntrega] = useState<
    ListarSetoresEntregaProps[]
  >([]);

  const pagedTableRef = useRef<PagedTableForwardRefData>(null);
  const isMountedRef = useIsMountedRef();
  const pageIsLoaded = useRef(false);

  const formMethods = useForm<FormData>({
    defaultValues,
  });

  const filtersSubmit = formMethods.handleSubmit((data) => {
    const filtersIsDirty = !shallowEqual(data, currentFilters || {});

    if (filtersIsDirty) {
      setCurrentFilters(data);
    }
  });

  const paginationHandle = useCallback(
    async (gridPaginadaConsulta: PaginationData) => {
      setIsLoading(true);
      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<ListarSetoresEntregaProps>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.SETOR_ENTREGA,
          gridPaginadaConsulta,
          {
            ...currentFilters,
          }
        )
      );

      if (response?.sucesso && isMountedRef.current) {
        setTotalRegistros(response.dados.total);
        setListSetoresEntrega(response.dados.registros);
        setIsLoading(false);
      }

      if (isMountedRef.current) {
        setIsLoading(false);

        if (!pageIsLoaded.current) {
          pageIsLoaded.current = true;
        }
      }
    },
    [isMountedRef, currentFilters]
  );

  const handleRemoverSetor = async (id: string) => {
    setIsLoading(true);
    const response = await api.delete<void, ResponseApi<boolean>>(
      `${ConstanteEnderecoWebservice.SETOR_ENTREGA}/${id}`
    );
    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso) {
        toast.success('Setor de entrega removido com sucesso');
        pagedTableRef.current?.reload();
      }
    }
    setIsLoading(false);
  };

  const handleUpdateDeliverySector = async (id: string) => {
    history.push(
      SubstituirParametroRota(
        ConstanteRotas.FRENTE_CAIXA_SETOR_ENTREGA_ALTERAR,
        'id',
        id
      )
    );
  };

  const handleRegisterDeliverySector = () => {
    history.push(ConstanteRotas.FRENTE_CAIXA_SETOR_ENTREGA_CADASTRAR);
  };

  return {
    isLoading,
    totalRegistros,
    formMethods,
    filtersSubmit,
    pagedTableRef,
    paginationHandle,
    listSetoresEntrega,
    handleRegisterDeliverySector,
    handleUpdateDeliverySector,
    handleRemoverSetor,
  };
};
