import React from 'react';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

import RegrasFiscaisListar from '../pages/RegrasFiscais/Listar';
import RegrasFiscaisCadastrar from '../pages/RegrasFiscais/Formulario/Cadastrar';
import RegrasFiscaisAlterar from '../pages/RegrasFiscais/Formulario/Alterar';
import RegrasFiscaisVisualizar from '../pages/RegrasFiscais/Formulario/Visualizar';

const RegrasFiscaisRoutes = [
  <LayoutGuard
    key="regras-fiscais-listar"
    permissaoFuncionalidade={ConstanteFuncionalidades.REGRA_FISCAL_LISTAR}
    component={RegrasFiscaisListar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Regra Fiscal',
      },
    ]}
    path={ConstanteRotas.REGRA_FISCAL}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="regras-fiscais-cadastrar"
    permissaoFuncionalidade={ConstanteFuncionalidades.REGRA_FISCAL_CADASTRAR}
    component={RegrasFiscaisCadastrar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Regra Fiscal',
        path: ConstanteRotas.REGRA_FISCAL,
      },
      {
        title: 'Novo',
      },
    ]}
    path={ConstanteRotas.REGRA_FISCAL_CADASTRAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="regras-fiscais-alterar"
    permissaoFuncionalidade={ConstanteFuncionalidades.REGRA_FISCAL_ALTERAR}
    component={RegrasFiscaisAlterar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Regra Fiscal',
        path: ConstanteRotas.REGRA_FISCAL,
      },
      {
        title: 'Editar',
      },
    ]}
    path={ConstanteRotas.REGRA_FISCAL_ALTERAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="regras-fiscais-visualizar"
    permissaoFuncionalidade={ConstanteFuncionalidades.REGRA_FISCAL_VISUALIZAR}
    component={RegrasFiscaisVisualizar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Regra Fiscal',
        path: ConstanteRotas.REGRA_FISCAL,
      },
      {
        title: 'Visualizar',
      },
    ]}
    path={ConstanteRotas.REGRA_FISCAL_VISUALIZAR}
    exact
    meta={{ auth: true }}
  />,
];

export default RegrasFiscaisRoutes;
