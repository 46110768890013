export const styleTabSelected = {
  color: 'black',
  bgColor: 'white',
  boxShadow: 'none',
  borderColor: 'none',
  borderRadius: 'none',

  // Rounded base style
  _first: { _before: { display: 'none' } },
  '&:before, &:after': { opacity: 1 },
  _before: {
    left: '-0.375rem',
    borderBottomRightRadius: '0.375rem',
    boxShadow: 'none',
  },
  _after: {
    right: '-0.375rem',
    borderBottomLeftRadius: '0.375rem',
    boxShadow: 'none',
  },
};

function variantEnclosed() {
  return {
    tab: {
      position: 'relative',
      borderTopRadius: 'md',
      border: 'none',
      borderBottomColor: 'transparent',
      color: 'white',

      // Rounded base style
      '&:before, &:after': {
        opacity: 0,
        position: 'absolute',
        bottom: '0.1rem',
        width: '0.375rem',
        height: '0.375rem',
        content: '" "',
        transition: 'opacity 0.25s ease-in',
      },
      _selected: {
        color: 'black',
        bgColor: 'white',
        boxShadow: '0px 5px 0px 0px #fff',
        borderColor: '#5502b260',

        // Rounded base style
        _first: { _before: { display: 'none' } },
        '&:before, &:after': { opacity: 1 },
        _before: {
          left: '-0.375rem',
          borderBottomRightRadius: '0.375rem',
          boxShadow: '0.375rem 0.375rem 0px 0.375rem var(--white)',
        },
        _after: {
          right: '-0.375rem',
          borderBottomLeftRadius: '0.375rem',
          boxShadow: '-0.375rem 0.375rem 0px 0.375rem var(--white)',
        },
      },
      _focus: {
        boxShadow: 'none',
      },
    },
    tablist: {
      border: 'none',
    },
    tabpanel: { bgColor: 'white', borderRadius: 'md' },
  };
}

function variantOutline() {
  return {
    tab: {
      position: 'relative',
      borderTopRadius: 'md',
      border: 'none',
      borderBottomColor: 'transparent',
      color: 'white',

      // Rounded base style
      '&:before, &:after': {
        opacity: 0,
        position: 'absolute',
        bottom: '0.1rem',
        width: '0.375rem',
        height: '0.375rem',
        content: '" "',
        transition: 'opacity 0.25s ease-in',
      },
      _selected: {
        color: 'gray.700',
        bgColor: 'gray.50',

        _first: { _before: { display: 'none' } },
        '&:before, &:after': { opacity: 1 },
        _before: {
          left: '-0.375rem',
          borderBottomRightRadius: '0.375rem',
        },
        _after: {
          right: '-0.375rem',
          borderBottomLeftRadius: '0.375rem',
        },
      },
      _focus: {
        boxShadow: 'none',
      },
    },
    tablist: {
      border: 'none !important',
      bg: 'primary.500 !important',
      color: 'white !important',
      marginRight: '10px !important',
    },
    tabpanels: {
      border: 'none !important',
      padding: '0 !important',
    },
    tabpanel: {
      bgColor: 'gray.50',
      borderRadius: 'md',
    },
  };
}

function variantClean() {
  return {
    tab: {
      position: 'relative',
      borderTopRadius: 'md',
      border: 'none',
      borderBottomColor: 'transparent',
      color: 'black',

      // Rounded base style
      '&:before, &:after': {
        opacity: 0,
        position: 'absolute',
        bottom: '0.1rem',
        width: '0.375rem',
        height: '0.375rem',
        content: '" "',
        transition: 'opacity 0.25s ease-in',
      },
      _selected: {
        ...styleTabSelected,
        background: '#F5F5F5',
      },
      _focus: {
        boxShadow: 'none',
      },
    },
    tablist: {
      border: 'none',
    },
    tabpanel: { bgColor: 'white', borderRadius: 'md' },
  };
}

const Tabs = {
  variants: {
    enclosed: variantEnclosed,
    clean: variantClean,
    outline: variantOutline,
  },
};

export default Tabs;
