import React, {
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react';

export type CategoriaProdutoType = {
  id: string;
  categoria: string;
  categoriaProdutoPaiId?: string;
  foto?: string;
  selecionado: boolean;
};

export interface CategoriaProdutoProps extends CategoriaProdutoType {
  isChecked?: boolean;
}
type CategoriaProdutoItensProps = {
  isItemFoiExpandido: boolean;
  isLoading: boolean;
  categoriasIdSelecionaveis: string[];
  categorias: CategoriaProdutoProps[];
  setCategorias: Dispatch<SetStateAction<CategoriaProdutoProps[]>>;
  setCategoriaAlterada: Dispatch<SetStateAction<boolean>>;
};
type CategoriaProdutoContextProps = CategoriaProdutoItensProps;

export const CategoriaProdutoContext = createContext<CategoriaProdutoContextProps>(
  {} as CategoriaProdutoContextProps
);

interface CategoriaProdutoProviderProps extends CategoriaProdutoItensProps {
  children: React.ReactNode;
}

export function CategoriaProdutoProvider({
  children,
  categorias,
  setCategorias,
  categoriasIdSelecionaveis,
  isLoading,
  isItemFoiExpandido,
  setCategoriaAlterada,
}: CategoriaProdutoProviderProps): JSX.Element {
  return (
    <CategoriaProdutoContext.Provider
      value={{
        isLoading,
        categoriasIdSelecionaveis,
        categorias,
        setCategorias,
        isItemFoiExpandido,
        setCategoriaAlterada,
      }}
    >
      {children}
    </CategoriaProdutoContext.Provider>
  );
}

export function useCategoriaProdutoContext(): CategoriaProdutoContextProps {
  const context = useContext(CategoriaProdutoContext);

  if (!context)
    throw new Error(
      'CategoriaProdutoContext must be used within a CategoriaProdutoProvider.'
    );

  return context;
}
