import { toast } from 'react-toastify';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';

import { ModalDesistencia } from 'components/Modal/ModalDesistencia';
import { ModalDigitarConfirmacao } from 'components/Modal/ModalDigitarConfirmacao';

type DataTrayAutenticacao = {
  url: string;
  code: string;
  sincronizacaoHabilitada?: boolean;
};

type MetaComissaoTray = {
  pagarComissao: boolean;
  considerarFaturamento: boolean;
};

export const getCanalVendasTray = async <T>() => {
  const response = await api.get<void, ResponseApi<T>>(
    ConstanteEnderecoWebservice.INTEGRACAO_TRAY_CANAL_VENDAS_OBTER
  );

  if (response) {
    if (response.sucesso) {
      return response.dados;
    }
  }
  return null;
};

export const getCadastrarCanalVendasTray = async (data: {
  nomeCanalVenda: string;
}) => {
  const response = await api.put<void, ResponseApi<boolean>>(
    ConstanteEnderecoWebservice.INTEGRACAO_TRAY_CANAL_VENDAS_ALTERAR,
    { ...data }
  );

  if (response) {
    if (response.avisos) {
      response.avisos.forEach((aviso) => toast.warning(aviso));
      return null;
    }

    if (response.sucesso) {
      return true;
    }
  }
  return null;
};

export const getMetaComissao = async () => {
  const response = await api.get<void, ResponseApi<MetaComissaoTray>>(
    ConstanteEnderecoWebservice.INTEGRACAO_TRAY_OBTER_COMISSAO_VENDA
  );

  if (response) {
    if (response.avisos) {
      response.avisos.forEach((aviso) => toast.warning(aviso));
      return null;
    }

    if (response.sucesso) {
      return response.dados;
    }
  }
  return null;
};

export const buscarDadosAutenticacao = async () => {
  const response = await api.get<void, ResponseApi<DataTrayAutenticacao>>(
    ConstanteEnderecoWebservice.INTEGRACAO_TRAY_OBTER_AUTENTICACAO_DADOS
  );

  if (response) {
    if (response.avisos) {
      response.avisos.forEach((aviso) => toast.warning(aviso));
      return null;
    }

    if (response.sucesso) {
      return response.dados;
    }
  }
  return null;
};

export const reprocessarIntegracao = async (
  id: number,
  integracaoPedidoId: string,
  clienteSistemaId?: string
) => {
  const response = await api.post<void, ResponseApi<boolean>>(
    `${
      ConstanteEnderecoWebservice.REPROCESSAR_INTEGRACAO
    }/${id}?integracaoPedidoId=${integracaoPedidoId}${
      clienteSistemaId ? `&clienteSistemaId=${clienteSistemaId}` : ''
    }`
  );

  if (response) {
    if (response.avisos) {
      response.avisos.forEach((avisos) => toast.warning(avisos));
      return false;
    }
    if (response.sucesso) {
      return true;
    }
  }
  return false;
};

export const autenticarDados = async (data: DataTrayAutenticacao) => {
  const response = await api.post<void, ResponseApi<boolean>>(
    ConstanteEnderecoWebservice.INTEGRACAO_TRAY_AUTENTICAR_DADOS,
    { ...data }
  );

  if (response) {
    if (response.avisos) {
      response.avisos.forEach((aviso) => toast.warning(aviso));
      return null;
    }

    if (response.sucesso) {
      return true;
    }
  }
  return null;
};

export const getVendedorTray = async () => {
  const response = await api.get<void, ResponseApi<string>>(
    ConstanteEnderecoWebservice.INTEGRACAO_TRAY_VENDEDOR_OBTER
  );

  if (response) {
    if (response.avisos) {
      response.avisos.forEach((aviso) => toast.warning(aviso));
      return null;
    }

    if (response.sucesso) {
      return response.dados;
    }
  }
  return null;
};

export const getCadastrarVendedorTray = async (data: { vendedor: string }) => {
  const response = await api.put<void, ResponseApi<boolean>>(
    `${ConstanteEnderecoWebservice.INTEGRACAO_TRAY_VENDEDOR_ALTERAR}/${data.vendedor}`
  );

  if (response) {
    if (response.avisos) {
      response.avisos.forEach((aviso) => toast.warning(aviso));
      return null;
    }

    if (response.sucesso) {
      return true;
    }
  }
  return null;
};

export const buscarTabelaPrecoTrayAtiva = async () => {
  const response = await api.get<void, ResponseApi<string>>(
    ConstanteEnderecoWebservice.INTEGRACAO_TRAY_TABELA_PRECO_OBTER
  );

  if (response) {
    if (response.avisos) {
      response.avisos.forEach((aviso) => toast.warning(aviso));
      return null;
    }

    if (response.sucesso) {
      return response.dados;
    }
  }
  return null;
};

const atualizarTabelaPrecoTray = async (tabelaPrecoId: string) => {
  const response = await api.put<void, ResponseApi<boolean>>(
    ConstanteEnderecoWebservice.INTEGRACAO_TRAY_ATUALIZACAO_TABELA_PRECO,
    {
      tabelaPrecoId,
    }
  );

  if (response) {
    if (response.avisos) {
      response.avisos.forEach((aviso) => toast.warning(aviso));
      return null;
    }

    if (response.sucesso) {
      window.location.reload();
      return true;
    }
  }
  return null;
};

const abrirModalDigitarConfirmacao = async (tabelaPrecoId: string) => {
  ModalDigitarConfirmacao({
    confirmationText: 'ALTERAR TABELA',
    corTitulo: 'black',
    message:
      'Certifique-se de selecionar a tabela correta e que a mesma esteja com preços atualizados. Todos os anúncios receberão o novo valor de venda.',
    confirmButtonColor: 'black',
    confirmButtonBackground: 'orange.400',
    confirmButtonColorScheme: 'orange',
    confirmButtonLabel: 'Confirmar alteração da tabela',

    callback: async (onClose) => {
      await atualizarTabelaPrecoTray(tabelaPrecoId);
      onClose();
    },
  });
};

export const abrirModalConfirmacaoAtualizacaoTabelaPrecos = async (data: {
  tabelaPreco: string;
}) => {
  ModalDesistencia({
    callback: async () => {
      abrirModalDigitarConfirmacao(data.tabelaPreco);
    },
    labelButtonConfirmar: 'Continuar',
    title: 'Alterar tabela de preços',
    labelButtonCancelar: 'Fechar',
    widthButtonConfirmar: '160px',
    colorButtonConfirm: 'black',
    textoMensagem:
      'Todos os produtos publicados serão atualizados e dependendo do número de ítens o processo pode ser demorado. A alteração será executada em segundo plano e você poderá utilizar normalmente o sistema. Enviaremos uma notificação assim que a atualização de dados estiver concluída. \nDeseja continuar?',
  });
};

export const getTipoProdutoTray = async () => {
  const response = await api.get<void, ResponseApi<{ tipoCadastro: number }>>(
    ConstanteEnderecoWebservice.INTEGRACAO_TRAY_TIPO_CADASTRO_OBTER
  );

  if (response) {
    if (response.avisos) {
      response.avisos.forEach((aviso) => toast.warning(aviso));
      return null;
    }

    if (response.sucesso) {
      return response.dados;
    }
  }
  return null;
};

export const getObterEtapaTray = async () => {
  const response = await api.get<void, ResponseApi<number>>(
    ConstanteEnderecoWebservice.INTEGRACAO_TRAY_OBTER_IDENTIFICACAO_ETAPA
  );

  if (response) {
    if (response.avisos) {
      response.avisos.forEach((aviso) => toast.warning(aviso));
      return null;
    }

    if (response.sucesso) {
      return response.dados;
    }
  }
  return null;
};
