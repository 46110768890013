import auth from 'modules/auth';

import TipoProdutoEnum from 'constants/enum/tipoProduto';
import { ProdutosEnum } from 'constants/enum/produtosEnum';
import enumReferenciaServicoStargate from 'constants/enum/referenciaServicoStargate';
import { useProdutosFormularioContext } from 'store/Produtos/ProdutosFormulario';

import { Tabs } from 'components/update/Tabs';
import {
  KitsIcon,
  GeralIcon,
  EcommerceIcon,
  NotaFiscalIcon,
  PrincipalIcon,
  ImagensAgrupadasIcon,
  PrecosIcon,
  VariacoesIcon,
  CofiguracoesFoodIcon,
  FichaTecnicaIcon,
  ProdutoEtapaIcon,
} from 'icons';

import { Imagens } from './TabsContent/Imagens';
import { Variacoes } from './TabsContent/Variacoes';
import { Fiscais } from './TabsContent/Fiscais';
import { Geral } from './TabsContent/Geral';
import { Kits } from './TabsContent/Kits';
import { Preco } from './TabsContent/Preco';
import { Ecommerce } from './TabsContent/Ecommerce';
import { Principal } from './TabsContent/Principal';
import { FichaTecnica } from './TabsContent/FichaTecnica';
import { Food } from './TabsContent/ConfiguracoesFood';
import { ProdutoEtapa } from './TabsContent/ProdutoEtapa';

const UncontrolledForm = () => {
  const {
    idProduto,
    ref: refProduto,
    tipoProduto,
    action,
    nome,
    dataHoraUltimaAlteracao,
    campoObrigatorioVazio,
    isEcommerceAtivo,
    hasNcm,
  } = useProdutosFormularioContext();

  const possuiServicoFrenteCaixa = auth.possuiServico(
    enumReferenciaServicoStargate.DISPOSITIVO_FRENTE_CAIXA
  ).permitido;

  const isProdutoKit = tipoProduto === TipoProdutoEnum.PRODUTO_KIT;

  const handleAlterarTab = async (
    onClick: (() => Promise<boolean>) | undefined
  ) => {
    if (onClick) {
      const salvar = await onClick();

      return salvar;
    }
    return () => {};
  };

  return (
    <>
      <Tabs
        titulo={nome}
        alertDescription={
          !idProduto || hasNcm
            ? undefined
            : 'Atenção! Preencha as informações fiscais.'
        }
        subtitulo={dataHoraUltimaAlteracao}
        cadastroSalvo={action !== 'cadastrar'}
        isExibirPorEtapa={false}
        isDisabled={campoObrigatorioVazio}
        tabs={[
          {
            eventKey: ProdutosEnum.PRINCIPAL,
            title: 'Principal',
            content: <Principal />,
            icon: PrincipalIcon,
            onClick: () => handleAlterarTab(refProduto.current?.onClick),
          },
          {
            eventKey: ProdutosEnum.CONFIGURACOES_FOOD,
            title: 'Configurações',
            content: <Food />,
            icon: CofiguracoesFoodIcon,
            onClick: () => handleAlterarTab(refProduto.current?.onClick),
            hidden: !possuiServicoFrenteCaixa,
          },
          {
            eventKey: ProdutosEnum.VARIACOES,
            title: 'Variações',
            content: <Variacoes />,
            hidden: tipoProduto !== TipoProdutoEnum.PRODUTO_VARIACAO,
            icon: VariacoesIcon,
            onClick: () => handleAlterarTab(refProduto.current?.onClick),
          },
          {
            eventKey: ProdutosEnum.PRODUTO_ETAPA,
            icon: ProdutoEtapaIcon,
            title: 'Etapas do produto',
            content: <ProdutoEtapa />,
            hidden: isProdutoKit || !possuiServicoFrenteCaixa,
            onClick: () => handleAlterarTab(refProduto.current?.onClick),
          },
          {
            eventKey: ProdutosEnum.FICHA_TECNICA,
            title: 'Ficha técnica',
            hidden: isProdutoKit || !possuiServicoFrenteCaixa,
            content: <FichaTecnica />,
            icon: FichaTecnicaIcon,
            onClick: () => handleAlterarTab(refProduto.current?.onClick),
          },
          {
            eventKey: ProdutosEnum.KITS,
            icon: KitsIcon,
            title: 'Kits',
            content: <Kits />,
            onClick: () => handleAlterarTab(refProduto.current?.onClick),
            hidden: tipoProduto !== TipoProdutoEnum.PRODUTO_KIT,
          },
          {
            eventKey: ProdutosEnum.PRECO,
            title: 'Preço',
            icon: PrecosIcon,
            content: <Preco />,
            onClick: () => handleAlterarTab(refProduto.current?.onClick),
          },
          {
            eventKey: ProdutosEnum.FISCAIS,
            title: 'Fiscal',
            content: <Fiscais />,
            hidden: isProdutoKit,
            icon: NotaFiscalIcon,
            onClick: () => handleAlterarTab(refProduto.current?.onClick),
          },
          {
            eventKey: ProdutosEnum.IMAGEM,
            title: 'Imagens',
            content: <Imagens />,
            icon: ImagensAgrupadasIcon,
            onClick: () => handleAlterarTab(refProduto.current?.onClick),
          },
          {
            eventKey: ProdutosEnum.GERAL,
            title: 'Geral',
            icon: GeralIcon,
            onClick: () => handleAlterarTab(refProduto.current?.onClick),
            content: <Geral />,
          },
          {
            eventKey: ProdutosEnum.ECOMMERCE,
            title: 'E-commerce',
            content: <Ecommerce />,
            icon: EcommerceIcon,
            hidden: isEcommerceAtivo !== true,
            onClick: () => handleAlterarTab(refProduto.current?.onClick),
          },
        ]}
      />
    </>
  );
};

export default UncontrolledForm;
