import { useCallback, useEffect, useState } from 'react';
import { Text, useToken } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { FormatOptionLabelMeta } from 'react-select';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';
import { CategoriaProdutoType } from 'store/CategoriasProduto';
import OptionType from 'types/optionType';
import NivelCategoriaProdutoEnum from 'constants/enum/nivelCategoriaProduto';

import SelectPadrao from 'components/PDV/Select/SelectPadrao';

type Options = {
  label: string;
  value: string | number;
};

type SelectCategoriaProps = {
  name: string;
  label?: string;
  isDisabled?: boolean;
  asControlledByObject?: boolean;
  placeholder?: string;
};

export const SelectCategoria = ({
  name,
  label,
  isDisabled,
  asControlledByObject = false,
  placeholder = 'Selecione as opções desejadas',
}: SelectCategoriaProps) => {
  const [opcoesCategorias, setOpcoesCategorias] = useState<Options[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const gray500 = useToken('colors', 'gray.500');

  const obterLabelCategoriaPai = useCallback(
    (
      categorias: CategoriaProdutoType[],
      categoriaPaiId?: string,
      categoriasMapeadas: Set<string> = new Set()
    ): string => {
      if (!categoriaPaiId || categoriasMapeadas.has(categoriaPaiId)) {
        return '';
      }

      categoriasMapeadas.add(categoriaPaiId);

      const categoriaPai = categorias.find(
        (categoria: CategoriaProdutoType) => categoria.id === categoriaPaiId
      );

      if (categoriaPai) {
        const labelCategoriaPai = `${categoriaPai.nome} > `;

        if (categoriaPai.categoriaProdutoPaiId) {
          return `${obterLabelCategoriaPai(
            categorias,
            categoriaPai.categoriaProdutoPaiId,
            categoriasMapeadas
          )}${labelCategoriaPai}`;
        }

        return labelCategoriaPai;
      }

      return '';
    },
    []
  );

  const obterValoresCategorias = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get<void, ResponseApi<CategoriaProdutoType[]>>(
      ConstanteEnderecoWebservice.CATEGORIA_PRODUTO_LISTAR_SELECT
    );

    if (response?.avisos) {
      response.avisos.forEach((aviso: string) => toast.warning(aviso));
    }

    if (response?.sucesso) {
      const novasOpcoesCategorias = response.dados.map((opcao) => ({
        value: opcao.id,
        label: `${obterLabelCategoriaPai(
          response.dados,
          opcao.categoriaProdutoPaiId
        )}${opcao.nome}`,
      }));

      const categoriasOrdenadas = novasOpcoesCategorias.sort((a, b) =>
        a.label?.localeCompare(b.label)
      );

      setOpcoesCategorias(categoriasOrdenadas);
    }
    setIsLoading(false);
  }, [obterLabelCategoriaPai]);

  useEffect(() => {
    obterValoresCategorias();
  }, [obterValoresCategorias]);

  return (
    <SelectPadrao
      id={name}
      name={name}
      label={label}
      isMulti
      isDisabled={isDisabled}
      placeholder={placeholder}
      options={opcoesCategorias}
      asControlledByObject={asControlledByObject}
      multiValueBg={gray500}
      isLoading={isLoading}
      formatOptionLabel={(
        option: OptionType,
        labelMeta: FormatOptionLabelMeta<OptionType>
      ) => {
        const isValueContext = labelMeta.context === 'value';

        const [primeiroNivel, segundoNivel, terceiroNivel, quartoNivel] = (
          option.label || ''
        ).split('>');

        return (
          <Text as="span" fontSize="1em">
            <Text
              as="span"
              fontSize={isValueContext && segundoNivel ? '0.85em' : '1em'}
              {...(isValueContext
                ? {
                    color: segundoNivel ? 'gray.100' : 'white',
                  }
                : {
                    color: NivelCategoriaProdutoEnum.properties[0].color,
                  })}
            >
              {`${primeiroNivel}${segundoNivel ? ' > ' : ''}`}
            </Text>
            {segundoNivel && (
              <Text
                as="span"
                fontSize={isValueContext && terceiroNivel ? '0.85em' : '1em'}
                {...(isValueContext
                  ? {
                      color: terceiroNivel ? 'gray.100' : 'white',
                    }
                  : {
                      color: NivelCategoriaProdutoEnum.properties[1].color,
                    })}
              >
                {`${segundoNivel}${terceiroNivel ? ' > ' : ''}`}
              </Text>
            )}
            {terceiroNivel && (
              <Text
                as="span"
                fontSize={isValueContext && quartoNivel ? '0.85em' : '1em'}
                {...(isValueContext
                  ? {
                      color: quartoNivel ? 'gray.100' : 'white',
                    }
                  : {
                      color: NivelCategoriaProdutoEnum.properties[2].color,
                    })}
              >
                {`${terceiroNivel}${quartoNivel ? ' > ' : ''}`}
              </Text>
            )}
            {quartoNivel && (
              <Text
                as="span"
                fontSize="1em"
                color={
                  isValueContext
                    ? 'white'
                    : NivelCategoriaProdutoEnum.properties[3].color
                }
              >
                {`${quartoNivel}`}
              </Text>
            )}
          </Text>
        );
      }}
    />
  );
};
