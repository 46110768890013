import { useCallback, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import api, { ResponseApi } from 'services/api';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import { shallowEqual } from 'helpers/validation/shallowEqual';

import { PaginationData } from 'components/update/Pagination';
import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import { PagedTableForwardRefData } from 'components/update/Table/PagedTable';

type DepartamentoProps = {
  id: string;
  ativo: boolean;
  nome: string;
};

type FormData = {
  descricao: string;
  status: number;
};

const defaultValues = {
  descricao: '',
  status: 1,
};

export const useDepartamento = () => {
  const formMethods = useForm<FormData>({
    defaultValues,
  });

  const { handleSubmit } = formMethods;

  const history = useHistory();

  const pagedTableRef = useRef<PagedTableForwardRefData>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [currentFilters, setCurrentFilters] = useState<FormData>(defaultValues);
  const [listDepartamentos, setListDepartamentos] = useState<
    DepartamentoProps[]
  >([]);

  const filtersSubmit = handleSubmit((data) => {
    const filtersIsDirty = !shallowEqual(data, currentFilters);

    if (filtersIsDirty) {
      setCurrentFilters(data);
    }
  });

  const reloadList = () => {
    if (pagedTableRef?.current?.reload) {
      pagedTableRef.current.reload();
    }
  };

  const paginationHandle = useCallback(
    async (gridPaginadaConsulta: PaginationData) => {
      setIsLoading(true);

      const response = await api.get<
        void,
        ResponseApi<
          GridPaginadaRetorno<{ id: string; ativo: boolean; nome: string }>
        >
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.DEPARTAMENTO,
          gridPaginadaConsulta,
          {
            ...currentFilters,
          }
        )
      );

      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        setTotalRegistros(response.dados.total);
        if (response.sucesso) {
          setListDepartamentos(response.dados.registros);
        }
      }

      setIsLoading(false);
    },
    [currentFilters]
  );

  const handleCadastrarDepartamento = useCallback(async () => {
    history.push(ConstanteRotas.FRENTE_CAIXA_DEPARTAMENTO_CADASTRAR);
  }, [history]);

  const handleAlterarDepartamento = useCallback(
    async (id: string) => {
      history.push(
        SubstituirParametroRota(
          ConstanteRotas.FRENTE_CAIXA_DEPARTAMENTO_ALTERAR,
          'id',
          id
        )
      );
    },
    [history]
  );

  const handleRemoverDepartamento = useCallback(async (id: string) => {
    const response = await api.delete<void, ResponseApi>(
      `${ConstanteEnderecoWebservice.DEPARTAMENTO}/${id}`
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso) {
        reloadList();
        toast.success('Departamento removido com sucesso.');
      }
    }
  }, []);

  return {
    formMethods,
    handleCadastrarDepartamento,
    handleAlterarDepartamento,
    handleRemoverDepartamento,
    paginationHandle,
    pagedTableRef,
    listDepartamentos,
    totalRegistros,
    isLoading,
    filtersSubmit,
  };
};
