const EnumStatusNotificacao = {
  CONCLUIDA: 'CONCLUIDA',
  FALHA: 'FALHA',

  properties: [
    { label: 'Concluída', value: 'CONCLUIDA' },
    { label: 'Falha', value: 'FALHA' },
  ],
};

export default EnumStatusNotificacao;
