import { Button, Flex, Box, useMediaQuery, Icon } from '@chakra-ui/react';
import { AiOutlineMenu } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';

import { enumTabsZoop } from 'constants/enum/enumTabsZoop';
import { useZoopContext } from 'store/Zoop/ZoopContext';
import { TabSelecionadaProps } from 'store/Zoop/types';
import { ConstanteRotasAlternativas } from 'constants/rotas';

import { ModalDesistencia } from 'components/Modal/ModalDesistencia';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { TabsZoop } from './TabsZoop';
import { Footer } from './components/Layout/Footer';
import { MenuMobile } from './components/MenuMobile';

export const GeralZoop = () => {
  const {
    activeTab,
    setActiveTab,
    tabFoiAlterada,
    setTabFoiAlterada,
    isLoading,
    tabsDisponiveis,
  } = useZoopContext();

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const history = useHistory();

  const updateTabSelecionada = (tab: TabSelecionadaProps) => {
    setActiveTab(tab);
    setTabFoiAlterada(false);
  };

  const goToContaBancaria = () => {
    history.push(ConstanteRotasAlternativas.ZOOP_CONFIGURACAO);
  };

  const handleChangeTab = (tab: TabSelecionadaProps) => {
    const alterandoParaTabContaBancaria =
      tab?.value === enumTabsZoop.CONTA_BANCARIA.value;
    const callbackChange = alterandoParaTabContaBancaria
      ? () => goToContaBancaria()
      : () => updateTabSelecionada(tab);

    if (tabFoiAlterada) {
      ModalDesistencia({
        callback: () => callbackChange(),
      });
    } else {
      callbackChange();
    }
  };

  return (
    <Box>
      {isLoading && <LoadingPadrao />}
      {isLargerThan900 ? (
        <Flex mb="24px">
          {tabsDisponiveis.map((itemZoop) => {
            return (
              <Box mr={['', '', '2%', '5%']}>
                <Button
                  h="32px"
                  fontWeight={
                    activeTab?.value === itemZoop?.value ? 'bold' : 'normal'
                  }
                  letterSpacing={
                    activeTab?.value !== itemZoop?.value ? '0.290px' : '0'
                  }
                  borderRadius="16px"
                  variant="linkDefault"
                  onClick={() => handleChangeTab(itemZoop)}
                  colorScheme="purple"
                >
                  {itemZoop.name}
                </Button>
              </Box>
            );
          })}
        </Flex>
      ) : (
        <Box mb="24px">
          <Icon
            boxSize="24px"
            cursor="pointer"
            onClick={() => {
              MenuMobile({
                activeTab,
                setActiveTab,
                tabsDisponiveis,
                goToContaBancaria,
              });
            }}
            as={AiOutlineMenu}
          />
        </Box>
      )}
      {TabsZoop({ activeTab })}
      {activeTab.value === enumTabsZoop.CONFIGURACOES.value && (
        <Footer mt="40px" />
      )}
    </Box>
  );
};
