import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@chakra-ui/react';
import { useState } from 'react';

import ConstanteRotas from 'constants/rotas';

const useDepartamentoForm = () => {
  const history = useHistory();

  const handleToDepartments = () => {
    history.push(ConstanteRotas.FRENTE_CAIXA_DEPARTAMENTOS);
  };

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const [selectedTab, setSelectedTab] = useState(0);
  const [mobileSelectedTab, setMobileSelectedTab] = useState(-1);

  const handleMobileSelectedTab = (index: number) => {
    const newIndex = index !== mobileSelectedTab ? index : -1;
    setMobileSelectedTab(newIndex);
  };

  const handleSelectedTab = (index: number) => {
    setSelectedTab(index);
  };

  return {
    isLargerThan900,
    selectedTab,
    mobileSelectedTab,
    handleSelectedTab,
    handleMobileSelectedTab,
    handleToDepartments,
  };
};

export { useDepartamentoForm };
