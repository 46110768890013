import React, { useMemo, useEffect, memo, ReactNode } from 'react';
import {
  Stack,
  HStack,
  VStack,
  StackProps,
  Icon,
  Text,
  Box,
  Button,
  Tooltip,
  Flex,
} from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { usePagamentoContext } from 'store/PDV/Pagamento';
import TipoValorEnum from 'constants/enum/tipoValor';
import { DecimalMask } from 'helpers/format/fieldsMasks';

import {
  AcrescimosIcon,
  DescontosIcon,
  DescontosAddIcon,
  OutrasDespesasIcon,
  ValorFreteIcon,
  LixeiraIcon,
} from 'icons';
import { NumberInput } from 'components/update/Input/NumberInput';

import ButtonItemDesconto from 'components/PDV/ButtonItemDesconto';
import ConstanteFuncionalidades from 'constants/permissoes';
import auth from 'modules/auth';
import Totalizadores from '../Totalizadores';

interface ReadonlyTooltip {
  children: ReactNode;
  isReadonly: boolean;
}

const ReadonlyToolttip = ({ children, isReadonly }: ReadonlyTooltip) => {
  return (
    <Tooltip
      hasArrow
      label="Você pode excluir o valor de acréscimo clicando no ícone da lixeira. Não é possível alterar outros valores enquanto houver uma forma de recebimento selecionada."
      shouldWrapChildren
      placement="auto-start"
      bg="gray.900"
      px="20px"
      py="17px"
      borderRadius="md"
      isDisabled={!isReadonly}
    >
      {children}
    </Tooltip>
  );
};

interface AcrescimoDescontoItemProps extends StackProps {
  icon: any;
  title: string;
  rightSection: () => JSX.Element;
  isReadonly: boolean;
}

const AcrescimoDescontoItem = ({
  icon,
  title,
  rightSection: RightSection,
  isReadonly,
  ...rest
}: AcrescimoDescontoItemProps) => (
  <Stack
    direction={{ base: 'column', md: 'row' }}
    justifyContent="space-between"
    color="gray.700"
    {...rest}
    py={2.5}
    px={{ base: 3.5, md: 10 }}
    borderRadius="md"
    w="full"
    spacing={{ base: 1, md: 2 }}
  >
    <HStack spacing={{ base: 2, md: 4 }} ml={{ base: 0, md: 6 }}>
      <Icon as={icon} fontSize={{ base: 'xl', md: '3xl' }} />
      <Text fontSize={{ base: 'xs', md: 'sm' }} fontWeight="semibold">
        {title}
      </Text>
    </HStack>
    <HStack
      spacing={4}
      minW={{ base: 'auto', md: '50%' }}
      sx={{ '& input, & .chakra-input__group': { maxW: { base: 36, md: 40 } } }}
      alignSelf={{ base: 'normal', md: 'flex-end' }}
      pl={{ base: '7', md: '0' }}
    >
      <ReadonlyToolttip isReadonly={isReadonly}>
        <RightSection />
      </ReadonlyToolttip>
    </HStack>
  </Stack>
);

interface DescontoAdicionalRightSectionProps {
  isReadOnly: boolean;
}

const DescontoAdicionalRightSection = ({
  isReadOnly,
}: DescontoAdicionalRightSectionProps) => {
  const {
    totais: {
      valorTotalItensSemDesconto = 0,
      valorDescontoAdicional = 0,
      valorTotalItensComDesconto = 0,
      tipoDescontoAdicional: totaisTipoDescontoAdicional = TipoValorEnum.REAIS,
    } = {},
  } = usePagamentoContext();
  const { control } = useFormContext();

  const descontoAdicional = useWatch({
    control,
    name: 'descontoAdicional',
    defaultValue: valorDescontoAdicional,
  });
  const tipoDescontoAdicional = useWatch({
    control,
    name: 'tipoDescontoAdicional',
    defaultValue: totaisTipoDescontoAdicional,
  });

  const conversaoValor = useMemo(() => {
    if (!descontoAdicional) {
      return '';
    }

    if (tipoDescontoAdicional === TipoValorEnum.PORCENTAGEM) {
      return `= R$ ${DecimalMask(
        (descontoAdicional / 100) * valorTotalItensComDesconto,
        2,
        2
      )}`;
    }

    return `= ${DecimalMask(
      (descontoAdicional / valorTotalItensSemDesconto) * 100,
      2
    )}%`;
  }, [
    descontoAdicional,
    tipoDescontoAdicional,
    valorTotalItensComDesconto,
    valorTotalItensSemDesconto,
  ]);

  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent={{ base: 'normal', md: 'space-between' }}
      alignItems="center"
      w="full"
    >
      <HStack
        maxW={{ base: 36, md: 40 }}
        sx={{
          '& input.descontoAdicional__input': {
            maxW: '100%',
          },
        }}
      >
        <Box
          sx={{
            '& div.react-select__control div.react-select__value-container div.react-select__single-value': {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              h: 'full',
              ml: 0,
              mr: 0,
              maxW: 'none',
              position: 'sticky',
              top: 0,
              transform: 'none',
              fontSize: 'xs',
            },
          }}
        >
          <ButtonItemDesconto
            id="tipoDescontoAdicional"
            name="tipoDescontoAdicional"
            variant="filled"
            bg="white"
            fontSize={{ base: 'xs', xl: 'sm' }}
            isDisabled={isReadOnly}
          />
        </Box>

        <NumberInput
          id="descontoAdicional"
          name="descontoAdicional"
          className="descontoAdicional__input"
          scale={2}
          leftElementFontSize="2xs"
          color="red.500"
          variant="filled"
          isDisabled={isReadOnly}
        />
      </HStack>

      {conversaoValor && (
        <Text fontSize={{ base: 'md', md: 'xs' }} whiteSpace="nowrap">
          {conversaoValor}
        </Text>
      )}
    </Stack>
  );
};

interface ConteudoProps {
  isReadOnly: boolean;
  resetAcrescimo(): void;
}

const Conteudo = ({ isReadOnly, resetAcrescimo }: ConteudoProps) => {
  const { totais } = usePagamentoContext();
  const { reset } = useFormContext();

  const permissaoZerarAcrescimos = auth.possuiPermissao(
    ConstanteFuncionalidades.PDV_REMOVER_ACRESCIMO
  );

  useEffect(() => {
    reset({
      descontoItens: totais?.valorTotalDescontoItem ?? 0,
      descontoAdicional: totais?.valorDescontoAdicional ?? 0,
      acrescimos:
        Math.round(
          ((totais?.valorTotalAcrescimo ?? 0) + Number.EPSILON) * 100
        ) / 100,
      outrasDespesas: totais?.valorTotalOutrasDespesas ?? 0,
      valorFrete: totais?.valorTotalFrete ?? 0,
      tipoDescontoAdicional:
        totais?.tipoDescontoAdicional || TipoValorEnum.REAIS,
    });
  }, [reset, totais]);

  return (
    <>
      <VStack spacing={2} w="full">
        <AcrescimoDescontoItem
          bg="red.50"
          icon={DescontosIcon}
          title="Desconto nos itens"
          isReadonly={isReadOnly}
          rightSection={() => (
            <NumberInput
              id="descontoItens"
              name="descontoItens"
              leftElement="R$"
              leftElementFontSize="2xs"
              scale={2}
              variant="filled"
              colorScheme="red"
              isDisabled
              _disabled={{ opacity: isReadOnly ? 0.9 : 1 }}
            />
          )}
        />
        <AcrescimoDescontoItem
          bg="red.50"
          icon={DescontosAddIcon}
          title="Desconto adicional"
          isReadonly={isReadOnly}
          rightSection={() => (
            <DescontoAdicionalRightSection isReadOnly={isReadOnly} />
          )}
        />
        <AcrescimoDescontoItem
          bg="blue.100"
          icon={AcrescimosIcon}
          title="Acréscimos"
          isReadonly={isReadOnly}
          rightSection={() => (
            <Flex justifyContent="space-between">
              <NumberInput
                id="acrescimos"
                name="acrescimos"
                leftElement="R$"
                leftElementFontSize="2xs"
                scale={2}
                variant="filled"
                colorScheme="blue"
                isDisabled
                _disabled={{ opacity: isReadOnly ? 0.9 : 1 }}
              />
              <Tooltip
                hasArrow
                label="Usuário sem permissão para remover o valor de acrescimo"
                shouldWrapChildren
                placement="right"
                isDisabled={permissaoZerarAcrescimos.permitido}
              >
                <Button
                  disabled={!permissaoZerarAcrescimos.permitido}
                  onClick={() => {
                    resetAcrescimo();
                  }}
                  bg="blue.100"
                  _hover={{
                    bg: 'blue.100',
                  }}
                  _focus={{
                    outline: 'unset',
                  }}
                  _active={{ bg: 'blue.100' }}
                >
                  <Icon as={LixeiraIcon} color="black" h="30px" w="22px" />
                </Button>
              </Tooltip>
            </Flex>
          )}
        />
        <AcrescimoDescontoItem
          bg="blue.100"
          icon={OutrasDespesasIcon}
          title="Outras despesas"
          isReadonly={isReadOnly}
          rightSection={() => (
            <NumberInput
              id="outrasDespesas"
              name="outrasDespesas"
              leftElement="R$"
              leftElementFontSize="2xs"
              scale={2}
              color="blue.800"
              variant="filled"
              isDisabled={isReadOnly}
              opacity="1 !important"
            />
          )}
        />
        <AcrescimoDescontoItem
          bg="blue.100"
          icon={ValorFreteIcon}
          title="Valor do frete"
          isReadonly={isReadOnly}
          rightSection={() => (
            <NumberInput
              id="valorFrete"
              name="valorFrete"
              leftElement="R$"
              leftElementFontSize="2xs"
              scale={2}
              color="blue.800"
              variant="filled"
              isDisabled={isReadOnly}
              opacity="1 !important"
            />
          )}
        />
      </VStack>
      <Totalizadores />
    </>
  );
};

export default memo(Conteudo);
