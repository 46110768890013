import React, { useState, useCallback, useRef } from 'react';
import { Box, Flex, GridItem, Link, Td, Tr } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';

import auth from 'modules/auth';
import api, { ResponseApi } from 'services/api';
import ConstanteFuncionalidades from 'constants/permissoes';
import { shallowEqual } from 'helpers/validation/shallowEqual';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import {
  GridPaginadaConsulta,
  GridPaginadaRetorno,
} from 'components/Grid/Paginacao';
import { SearchInput } from 'components/update/Input/SearchInput';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { ModalConfirmacaoInativar } from 'components/Modal/ModalConfirmacaoInativar';
import { StatusCircle } from 'components/update/Table/StatusCircle';
import { FilterSelect } from 'components/update/Select/FilterSelect';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { ButtonCadastrarNovo } from 'components/Layout/ButtonCadastrarNovo';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import {
  PagedTable,
  PagedTableForwardRefData,
} from 'components/update/Table/PagedTable';

interface Vendedor {
  id: string;
  nome: string;
  telefone: string;
  email: string;
  ativo: boolean;
}

const formDefaultValue = { nome: '', ativo: true };

interface VendedorFiltros {
  ativo: boolean | null;
  nome: string;
}

const Listar = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [vendedores, setVendedores] = useState([] as Array<Vendedor>);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [currentFilters, setCurrentFilters] = useState<VendedorFiltros>(
    formDefaultValue
  );

  const childRef = useRef<PagedTableForwardRefData>(null);

  const formMethods = useForm({
    defaultValues: formDefaultValue,
  });
  const { setFocus, handleSubmit } = formMethods;

  const permissaoVendedorAlterar = auth.possuiPermissao(
    ConstanteFuncionalidades.VENDEDOR_ALTERAR
  );

  const permissaoVendedorVisualizar = auth.possuiPermissao(
    ConstanteFuncionalidades.VENDEDOR_VISUALIZAR
  );

  const handleReset = handleSubmit((data: VendedorFiltros) => {
    const filtersIsDirty = !shallowEqual(data, currentFilters || {});

    if (filtersIsDirty) {
      setCurrentFilters(data);
    }
  });

  const handleRefresh = useCallback(() => {
    return childRef.current?.reload();
  }, []);

  const handleHistoryPush = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history]
  );

  const getNomeLinkHref = useCallback(
    (id: string) => {
      let href = '';

      if (permissaoVendedorAlterar.permitido) {
        href = SubstituirParametroRota(
          ConstanteRotas.VENDEDOR_ALTERAR,
          'id',
          id
        );
      } else if (permissaoVendedorVisualizar.permitido) {
        href = SubstituirParametroRota(
          ConstanteRotas.VENDEDOR_VISUALIZAR,
          'id',
          id
        );
      }

      return href;
    },
    [permissaoVendedorAlterar, permissaoVendedorVisualizar]
  );

  const paginationHandle = useCallback(
    async (gridPaginadaConsulta: GridPaginadaConsulta) => {
      setIsLoading(true);

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<Vendedor>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.VENDEDOR_LISTAR_PAGINADO,
          gridPaginadaConsulta
        ),
        { params: { nome: currentFilters.nome, ativo: currentFilters.ativo } }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }
        if (response?.sucesso && response?.dados) {
          setTotalRegistros(response.dados.total);
          setVendedores(response.dados.registros);
        }
      }

      setIsLoading(false);
      setFocus('nome');
    },
    [currentFilters, setFocus]
  );

  const excluirHandle = useCallback(
    async (vendedorId: string, ativo: boolean) => {
      ModalConfirmacaoExcluir({
        callback: async (ok: boolean) => {
          if (ok) {
            setIsLoading(true);
            const response = await api.delete<void, ResponseApi>(
              ConstanteEnderecoWebservice.VENDEDOR_EXCLUIR,
              {
                params: { id: vendedorId },
              }
            );

            if (response?.sucesso) {
              toast.success('O cadastro foi removido com sucesso.');
              handleRefresh();
            }

            if (response) {
              ModalConfirmacaoInativar({
                response,
                rotaWebService: ConstanteEnderecoWebservice.VENDEDOR_INATIVAR,
                id: vendedorId,
                ativo,
                callback: (okInativar: boolean) => {
                  if (okInativar) handleRefresh();
                },
              });
            }

            setIsLoading(false);
          }
        },
      });
    },
    [handleRefresh]
  );

  return (
    <SimpleGridForm>
      <FormProvider {...formMethods}>
        <GridItem colSpan={{ base: 12, md: 5, lg: 6 }}>
          <SearchInput
            type="search"
            placeholder="Buscar vendedor por nome"
            onEnterKeyPress={() => handleReset()}
            isDisabled={isLoading}
            name="nome"
            id="nome"
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, md: 2, lg: 2 }}>
          <Box w={{ base: 'full', md: '190px', lg: '190px' }}>
            <FilterSelect
              id="ativo"
              name="ativo"
              isDisabled={isLoading}
              options={[
                {
                  value: true,
                  label: 'Ativos',
                },
                {
                  value: false,
                  label: 'Inativos',
                },
                {
                  value: null,
                  label: 'Todos',
                },
              ]}
              onSelect={() => {
                handleReset();
              }}
            />
          </Box>
        </GridItem>
        <GridItem
          colSpan={{ base: 12, md: 5, lg: 4 }}
          as={Flex}
          justifyContent="end"
        >
          <Box width={{ base: 'full', md: '200px' }}>
            <ButtonCadastrarNovo
              id="cadastrarNovo"
              name="cadastrarNovo"
              width="full"
              minW="min-content"
              px="20px"
              borderRadius="full"
              funcionalidade={ConstanteFuncionalidades.VENDEDOR_CADASTRAR}
              onClick={() =>
                handleHistoryPush(ConstanteRotas.VENDEDOR_CADASTRAR)
              }
            />
          </Box>
        </GridItem>
        <GridItem colSpan={12}>
          <Box>
            <PagedTable
              ref={childRef}
              defaultKeyOrdered="Nome"
              defaultOrderDirection="asc"
              itemsTotalCount={totalRegistros}
              loadColumnsData={paginationHandle}
              isLoading={isLoading}
              tableHeaders={[
                {
                  content: <StatusCircle hasValue={false} />,
                  key: 'Ativo',
                  isOrderable: true,
                  width: '37px',
                },
                {
                  content: 'Nome',
                  key: 'Nome',
                  isOrderable: true,
                  width: 'auto',
                },
                {
                  content: 'Telefone',
                  key: 'Telefone',
                  isOrderable: true,
                  width: '150px',
                },
                {
                  content: 'E-mail',
                  key: 'Email',
                  isOrderable: true,
                  width: '150px',
                },
                {
                  content: 'Ações',
                  key: 'Acoes',
                  isOrderable: false,
                  width: '38px',
                },
              ]}
              renderTableRows={vendedores.map((vendedorItem) => {
                return (
                  <Tr>
                    <Td>
                      <StatusCircle isActive={vendedorItem.ativo} />
                    </Td>
                    <Td>
                      <Link href={getNomeLinkHref(vendedorItem.id)}>
                        {vendedorItem.nome}
                      </Link>
                    </Td>
                    <Td>{vendedorItem.telefone}</Td>
                    <Td>{vendedorItem.email}</Td>
                    <Td>
                      <ActionsMenu
                        id="mostrarMais"
                        items={[
                          {
                            content: 'Editar',
                            onClick: () =>
                              handleHistoryPush(
                                SubstituirParametroRota(
                                  ConstanteRotas.VENDEDOR_ALTERAR,
                                  'id',
                                  vendedorItem.id
                                )
                              ),
                            funcionalidade:
                              ConstanteFuncionalidades.VENDEDOR_ALTERAR,
                          },
                          {
                            content: 'Remover',
                            onClick: () =>
                              excluirHandle(
                                vendedorItem.id,
                                vendedorItem.ativo
                              ),
                            funcionalidade:
                              ConstanteFuncionalidades.VENDEDOR_EXCLUIR,
                          },
                        ]}
                      />
                    </Td>
                  </Tr>
                );
              })}
            />
          </Box>
        </GridItem>
      </FormProvider>
    </SimpleGridForm>
  );
};

export default Listar;
