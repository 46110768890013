import React, { ReactElement } from 'react';
import {
  Table as ChakraTable,
  TableProps as ChakraTabsProps,
  Thead,
  Tbody,
  Tfoot,
} from '@chakra-ui/react';

interface TableProps extends ChakraTabsProps {
  theadContent?: ReactElement;

  tbodyContent: ReactElement;

  tfootContent?: ReactElement;
}

const Table = ({
  theadContent,
  tbodyContent,
  tfootContent,
  ...rest
}: TableProps) => {
  return (
    <ChakraTable
      variant="simple"
      size="md"
      sx={{
        '& > thead > tr > th:first-of-type, & > tbody > tr > td:first-of-type, & > tfoot > tr > th:first-of-type': {
          pl: 1,
        },
        '& > thead > tr > th:last-of-type, & > tbody > tr > td:last-of-type, & > tfoot > tr > th:last-of-type': {
          pr: 1,
        },
      }}
      {...rest}
    >
      {theadContent && (
        <Thead
          sx={{
            '& > tr > th': {
              color: 'black',
              fontWeight: 'semibold',
              fontSize: 'xs',
              whiteSpace: 'nowrap',
              pb: 1,
            },
          }}
        >
          {theadContent}
        </Thead>
      )}
      <Tbody
        sx={{
          '& > tr > td': {
            fontSize: 'sm',
            color: 'gray.700',
          },
        }}
      >
        {tbodyContent}
      </Tbody>
      {tfootContent && <Tfoot>{tfootContent}</Tfoot>}
    </ChakraTable>
  );
};

export default Table;
