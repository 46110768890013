import React from 'react';
import {
  Input,
  InputProps,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Icon,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { LupaIcon, LeitorCodigoBarrasIcon } from 'icons';

interface SearchInputProps extends InputProps {
  name: string;
  onEnterKeyPress?: () => void;
  isCodigoBarras?: boolean;
  isRightElement?: boolean;
  isPostionCodigoBarrasRight?: boolean;
}

export const SearchInput = ({
  size,
  name,
  onEnterKeyPress,
  isCodigoBarras = false,
  onKeyPress,
  onBlur,
  isRightElement = false,
  isPostionCodigoBarrasRight = false,
  ...rest
}: SearchInputProps) => {
  const { register } = useFormContext();

  const fieldProps = register(name);

  return (
    <InputGroup size={size}>
      <InputLeftElement pointerEvents="none" mx="2.5">
        <Icon
          as={isCodigoBarras ? LeitorCodigoBarrasIcon : LupaIcon}
          boxSize="5"
        />
      </InputLeftElement>

      <Input
        type="search"
        pl="12"
        {...rest}
        onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Enter' && onEnterKeyPress) {
            e.currentTarget.value = e.currentTarget.value.trim();

            onEnterKeyPress();
          }

          if (onKeyPress) onKeyPress(e);
        }}
        {...fieldProps}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
          e.currentTarget.value = e.currentTarget.value.trim();

          if (onBlur) onBlur(e);

          fieldProps.onBlur(e);
        }}
      />
      {isRightElement && (
        <InputRightElement pointerEvents="none" mx="2.5">
          <Icon
            as={isPostionCodigoBarrasRight ? LeitorCodigoBarrasIcon : LupaIcon}
            boxSize="5"
          />
        </InputRightElement>
      )}
    </InputGroup>
  );
};
