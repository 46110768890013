import { Flex, Text, Divider, useMediaQuery } from '@chakra-ui/react';

import { TextValor } from 'components/PDV/Text/TextValor';

type TotalizadorProps = {
  title: string;
  valorTotal: number;
  displayFormattedValue?: boolean;
  hasDivider?: boolean;
  color?: string;
};
export const Totalizador = ({
  valorTotal = 0,
  displayFormattedValue = true,
  title,
  color = 'white',
  hasDivider = true,
}: TotalizadorProps) => {
  const [isSmallerThan1200] = useMediaQuery('(max-width: 1200px)');

  return (
    <>
      <Flex
        w="full"
        alignItems="center"
        justifyContent="center"
        flexDir="column"
        gap="8px"
      >
        <Text color={color} fontSize="xs">
          {title}
        </Text>
        {displayFormattedValue ? (
          <TextValor
            casasDecimais={2}
            valor={valorTotal}
            color={color}
            fontSize="xl"
            fontWeight="semibold"
            symbolFontSize="xs"
            symbolFontWeight="semibold"
          />
        ) : (
          <Text
            color={color}
            lineHeight="none"
            fontSize="xl"
            fontWeight="semibold"
            maxH="fit-content"
          >
            {valorTotal}
          </Text>
        )}
      </Flex>
      {hasDivider && (
        <Divider
          color="white"
          orientation={isSmallerThan1200 ? 'horizontal' : 'vertical'}
          h={{ base: '1px', md: '1px', lg: '12' }}
          w={{ base: 'full', md: 'full', lg: '1px' }}
        />
      )}
    </>
  );
};
