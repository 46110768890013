import React, { ReactNode, useState } from 'react';
import {
  ModalContent,
  ModalBody,
  Flex,
  Button,
  Heading,
  Text,
  AlertDialogFooter,
  ScaleFade,
  ModalProps,
} from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';
import { AtencaoAvisoIcon, SalvarConfirmarIcon } from 'icons';
import { create, InstanceProps } from 'react-modal-promise';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import ManterFoco from 'components/Geral/ManterFoco';

type PromiseModal = {
  success?: boolean;
};
interface ModalConfirmacaoProps
  extends InstanceProps<PromiseModal>,
    Omit<ModalProps, 'children' | 'isOpen' | 'onClose'> {
  cancelButtonText: string;
  confirmButtonText: string;
  text: string | ReactNode;
  title: string;
  hasSecondaryColorScheme?: boolean;
  callback?: (value: boolean) => Promise<void>;
  heightModal?: string;
}

const ModalConfirmacaoConst = ({
  cancelButtonText,
  confirmButtonText,
  text,
  hasSecondaryColorScheme,
  title,
  callback,
  onResolve,
  onReject,
  heightModal = '300px',
}: ModalConfirmacaoProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);

  return (
    <ModalPadraoChakra
      onClose={() => {
        setIsOpen(false);
        if (callback) callback(false);
      }}
      isOpen={isOpen}
      isCentered
      size="xl"
      autoFocus={false}
    >
      <ModalContent
        marginBottom={{ base: 0, sm: '3.75rem' }}
        marginTop={{ base: 0, sm: '3.75rem' }}
        h="unset"
        maxW="600px"
      >
        <ManterFoco
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <ScaleFade initialScale={1.5} in={isOpen}>
            <ModalBody p={0} h="100%">
              <Flex flexDirection="column" align="stretch" h="100%">
                <Flex
                  justifyContent="center"
                  h="150px"
                  bg={hasSecondaryColorScheme ? 'secondary.500' : 'orange.400'}
                  style={{
                    borderTopLeftRadius: '5px',
                    borderTopRightRadius: '5px',
                  }}
                >
                  {hasSecondaryColorScheme ? (
                    <SalvarConfirmarIcon
                      style={{
                        fontSize: '75px',
                        fontWeight: 'bolder',
                        marginTop: '35px',
                      }}
                    />
                  ) : (
                    <AtencaoAvisoIcon
                      style={{
                        fontSize: '75px',
                        fontWeight: 'bolder',
                        marginTop: '35px',
                      }}
                    />
                  )}
                </Flex>

                <Flex
                  justifyContent="flex-start"
                  flexDirection="column"
                  bg="gray.50"
                  px={8}
                  py={8}
                  h={heightModal}
                  style={{
                    borderBottomLeftRadius: '5px',
                    borderBottomRightRadius: '5px',
                  }}
                >
                  <Heading
                    as="h2"
                    fontSize="2xl"
                    color={
                      hasSecondaryColorScheme ? 'secondary.500' : 'orange.500'
                    }
                    fontWeight="semibold"
                    textAlign="initial"
                  >
                    {t(title)}
                  </Heading>

                  <Text minH="50px" mt={15} fontSize="14px" color="gray.700">
                    {text}
                  </Text>

                  <AlertDialogFooter mt="12">
                    <Button
                      marginLeft="auto"
                      marginRight="15px"
                      fontWeight="normal"
                      id="cancelModal"
                      variant="ghost"
                      color="gray.300"
                      border="1px"
                      borderColor="gray.200"
                      _hover={{
                        borderColor: 'gray.700',
                        color: 'gray.700',
                      }}
                      onClick={() => {
                        setIsOpen(false);
                        if (callback) callback(false);
                        onReject();
                      }}
                    >
                      {cancelButtonText ? t(cancelButtonText) : t('Cancelar')}
                    </Button>

                    <Button
                      id="confirmModal"
                      bg={
                        hasSecondaryColorScheme ? 'secondary.500' : 'orange.400'
                      }
                      maxW="auto"
                      ml={!cancelButtonText ? 'auto' : 'initial'}
                      _hover={{
                        bg: hasSecondaryColorScheme
                          ? 'secondary.400'
                          : 'orange.200',
                      }}
                      _active={{
                        bg: hasSecondaryColorScheme
                          ? 'secondary.400'
                          : 'orange.300',
                      }}
                      onClick={() => {
                        setIsOpen(false);
                        if (callback) callback(true);
                        onResolve({
                          success: true,
                        });
                      }}
                      style={{
                        marginRight: 'auto',
                      }}
                    >
                      {confirmButtonText ? t(confirmButtonText) : t('Ok')}
                    </Button>
                  </AlertDialogFooter>
                </Flex>
              </Flex>
            </ModalBody>
          </ScaleFade>
        </ManterFoco>
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export const ModalConfirmacao = create<ModalConfirmacaoProps>(
  ModalConfirmacaoConst
);
