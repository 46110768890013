import { setDateMaxHours, setDateMinHours } from 'helpers/data/setHoursDate';

export type FormData = {
  precoRelatorioInventario: number | null;
  localEstoque: number | null;
  dataHoraInicio: Date;
  dataHoraFim: Date;
  exibirMovimentacaoEstoque: boolean;
  totalizarGrade: boolean;
  localEstoqueId: string;
  posicaoData: string;
  lojaId: string;
};

export const formDefaultValues = {
  precoRelatorioInventario: 2,
  localEstoque: null,
  dataHoraInicio: setDateMinHours(new Date()),
  dataHoraFim: setDateMaxHours(new Date()),
  exibirMovimentacaoEstoque: false,
  totalizarGrade: false,
  localEstoqueId: '',
  posicaoData: '',
  lojaId: '',
};
