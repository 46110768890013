import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Container as ContainerDraggable, Draggable } from 'react-smooth-dnd';
import { toast } from 'react-toastify';
import { Box, Flex, GridItem, Link, Text } from '@chakra-ui/react';

import auth from 'modules/auth';
import ConstanteFuncionalidades from 'constants/permissoes';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import TipoAvisoConstante from 'constants/tipoAvisos';

import { SearchInput } from 'components/update/Input/SearchInput';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { ModalConfirmacaoInativar } from 'components/Modal/ModalConfirmacaoInativar';
import Loading from 'components/Layout/Loading/LoadingPadrao';
import { StatusCircle } from 'components/update/Table/StatusCircle';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { FilterSelect } from 'components/update/Select/FilterSelect';
import { ButtonCadastrarNovo } from 'components/Layout/ButtonCadastrarNovo';

interface TamanhoFiltros {
  descricao: string;
  ativo: boolean;
}

interface TamanhoVisualizacao {
  id: string;
  descricao: string;
  sequenciaOrdenacao: string;
  ativo: boolean;
  padraoSistema: boolean;
}

const formDefaultValues = {
  ativo: null,
  descricao: '',
};

const Listar = () => {
  const history = useHistory();

  const formMethods = useForm({
    defaultValues: formDefaultValues,
  });

  const { getValues, setFocus } = formMethods;

  const isMountedRef = useIsMountedRef();
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [tamanhos, setTamanho] = useState([] as Array<TamanhoVisualizacao>);

  const pageIsLoaded = useRef(false);

  const permissaoTamanhoAlterar = auth.possuiPermissao(
    ConstanteFuncionalidades.TAMANHO_ALTERAR
  );
  const permissaoTamanhoVisualizar = auth.possuiPermissao(
    ConstanteFuncionalidades.TAMANHO_VISUALIZAR
  );

  const [permiteAlterarOrdem, setPermiteAlterarOrdem] = useState(
    permissaoTamanhoAlterar.permitido
  );

  const searchHandle = useCallback(async () => {
    setIsLoading(true);

    const filtros = getValues();

    setPermiteAlterarOrdem(
      permissaoTamanhoAlterar.permitido &&
        filtros.descricao === '' &&
        filtros.ativo === null
    );

    const response = await api.get<
      void,
      ResponseApi<Array<TamanhoVisualizacao>>
    >(ConstanteEnderecoWebservice.TAMANHO_LISTAR, {
      params: { descricao: filtros.descricao, ativo: filtros.ativo },
    });

    if (response?.sucesso && isMountedRef.current) {
      setTotal(response.dados.length);
      setTamanho(response.dados);
    }

    if (isMountedRef.current) {
      setIsLoading(false);

      if (!pageIsLoaded.current) {
        pageIsLoaded.current = true;

        setFocus('descricao');
      }
    }
  }, [getValues, isMountedRef, permissaoTamanhoAlterar.permitido, setFocus]);

  const handleHistoryPush = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history]
  );

  const getPossuiPermissaoVisualizarAlterar = useCallback(
    (id: string) => {
      let href = '';

      if (permissaoTamanhoAlterar.permitido) {
        href = SubstituirParametroRota(
          ConstanteRotas.TAMANHO_ALTERAR,
          'id',
          id
        );
      } else if (permissaoTamanhoVisualizar.permitido) {
        href = SubstituirParametroRota(
          ConstanteRotas.TAMANHO_VISUALIZAR,
          'id',
          id
        );
      }

      return href;
    },
    [permissaoTamanhoAlterar, permissaoTamanhoVisualizar]
  );

  const alterarSequenciaOrdenacao = useCallback(
    async (listAlteracao: Array<TamanhoVisualizacao>) => {
      const data = listAlteracao.map((item, index) => {
        return { id: item.id, sequenciaOrdenacao: index + 1 };
      });

      const response = await api.put<void, ResponseApi>(
        ConstanteEnderecoWebservice.TAMANHO_ALTERAR_SEQUENCIA_ORDENACAO,
        data
      );

      if (
        response?.tiposAviso &&
        response.tiposAviso.find(
          (tipoAviso: string) =>
            tipoAviso === TipoAvisoConstante.ORDENACAO_LISTA_DESATUALIZADA
        )
      ) {
        response.avisos.map((item: string) => toast.warning(item));
        searchHandle();
      }
    },
    [searchHandle]
  );

  const excluirHandle = useCallback(
    async (tamanhoId: string, ativo: boolean) => {
      ModalConfirmacaoExcluir({
        callback: async (ok: boolean) => {
          if (ok) {
            setIsLoading(true);

            const response = await api.delete<void, ResponseApi>(
              ConstanteEnderecoWebservice.TAMANHO_EXCLUIR,
              {
                params: { id: tamanhoId },
              }
            );

            if (response?.sucesso) {
              toast.success('O cadastro foi removido com sucesso.');

              searchHandle();
            }

            if (response) {
              ModalConfirmacaoInativar({
                response,
                rotaWebService: ConstanteEnderecoWebservice.TAMANHO_INATIVAR,
                id: tamanhoId,
                ativo,
                callback: (okInativar: boolean) => {
                  if (okInativar) {
                    searchHandle();
                  }
                },
              });
            }
          }
        },
      });
    },
    [searchHandle]
  );

  const applyDrag = useCallback(
    (arr: any, dragResult: any) => {
      const { removedIndex, addedIndex, payload } = dragResult;
      if (removedIndex === null && addedIndex === null) return arr;

      const result = [...arr];
      let itemToAdd = payload;

      if (removedIndex !== null) {
        const [itemOne] = result.splice(removedIndex, 1);
        itemToAdd = itemOne;
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }

      setTamanho(result);
      alterarSequenciaOrdenacao(result);

      return null;
    },

    [alterarSequenciaOrdenacao]
  );

  useEffect(() => {
    searchHandle();
  }, [searchHandle]);

  return (
    <SimpleGridForm gap={{ base: '10px', sm: '10px', md: 8 }}>
      <FormProvider {...formMethods}>
        <GridItem colSpan={{ base: 12, md: 5, lg: 6 }}>
          <SearchInput
            type="search"
            onEnterKeyPress={() => {
              searchHandle();
            }}
            placeholder="Buscar tamanho por descrição"
            isDisabled={isLoading}
            id="descricao"
            name="descricao"
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, md: 2, lg: 2 }}>
          <Box w={{ base: 'full', md: '190px', lg: '190px' }}>
            <FilterSelect
              id="ativo"
              name="ativo"
              isDisabled={isLoading}
              options={[
                {
                  value: true,
                  label: 'Ativos',
                },
                {
                  value: false,
                  label: 'Inativos',
                },
                {
                  value: null,
                  label: 'Todos',
                },
              ]}
              onSelect={() => {
                searchHandle();
              }}
            />
          </Box>
        </GridItem>
        <GridItem
          colSpan={{ base: 12, md: 5, lg: 4 }}
          display={{ base: '', sm: '', md: 'flex' }}
          justifyContent={{ base: 'flex-start', sm: 'flex-end' }}
        >
          <ButtonCadastrarNovo
            onClick={() => handleHistoryPush(ConstanteRotas.TAMANHO_CADASTRAR)}
            funcionalidade={ConstanteFuncionalidades.TAMANHO_CADASTRAR}
          />
        </GridItem>
      </FormProvider>

      <GridItem mt={['10px', '10px', '-10px']} colSpan={12}>
        <Box
          bg="white"
          borderRadius="5px"
          boxShadow="0px 0px 6px 0px rgba(0, 0, 0, 0.2)"
        >
          <Flex
            pr="35px"
            pl="35px"
            bg="gray.100"
            h="45px"
            color="black"
            borderTopRadius="5px"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text>
              Clique, segure e arraste os itens para mover e ordenar os
              tamanhos.
            </Text>
            <Text>Ações</Text>
          </Flex>
          <Box pt="30px" pl="20px" pr="20px">
            {isLoading && <Loading />}

            <Box pr="10px" pl="10px">
              <ContainerDraggable
                dragBeginDelay={50}
                animationDuration={500}
                dragClass="opacity-ghost"
                dropPlaceholder={{ className: 'dropPlaceholder' }}
                onDrop={(e) => applyDrag(tamanhos, e)}
                nonDragAreaSelector={`.dropdown, .nonDragAreaSeelector${
                  permiteAlterarOrdem ? '' : ', .smooth-dnd-draggable-wrapper'
                }`}
              >
                {tamanhos.map((tamanho) => {
                  return (
                    <Draggable key={tamanho.id}>
                      <Flex
                        height="37px"
                        bg="gray.50"
                        borderRadius="5px"
                        justifyContent="space-between"
                        mb="6px"
                        color="gray.700"
                        fontSize="0.8125rem"
                        w="full"
                        boxShadow="0px 1px 2px rgba(0, 0, 0, 0.24)"
                        cursor={permiteAlterarOrdem ? 'move' : 'not-allowed'}
                        id={tamanho.descricao}
                      >
                        <Flex
                          alignItems="center"
                          height="100%"
                          ml="20px"
                          className={
                            permiteAlterarOrdem ? '' : 'nonDragAreaSeelector'
                          }
                        >
                          <StatusCircle isActive={tamanho.ativo} />
                          <Link
                            ml="20px"
                            className="dropdown"
                            id="link-visualizar"
                            href={getPossuiPermissaoVisualizarAlterar(
                              tamanho.id
                            )}
                          >
                            {tamanho.descricao}
                          </Link>
                        </Flex>
                        {!tamanho.padraoSistema && (
                          <Box className="dropdown" mt="7px">
                            <ActionsMenu
                              menuZIndex="base"
                              id="mostrarMais"
                              items={[
                                {
                                  content: 'Editar',
                                  onClick: () => {
                                    handleHistoryPush(
                                      SubstituirParametroRota(
                                        ConstanteRotas.TAMANHO_ALTERAR,
                                        'id',
                                        tamanho.id
                                      )
                                    );
                                  },
                                  funcionalidade:
                                    ConstanteFuncionalidades.TAMANHO_ALTERAR,
                                },
                                {
                                  content: 'Remover',
                                  onClick: () => {
                                    excluirHandle(tamanho.id, tamanho.ativo);
                                  },
                                  funcionalidade:
                                    ConstanteFuncionalidades.TAMANHO_EXCLUIR,
                                },
                              ]}
                            />
                          </Box>
                        )}
                      </Flex>
                    </Draggable>
                  );
                })}
              </ContainerDraggable>
            </Box>
          </Box>
          <Flex justifyContent="flex-end">
            <Text mr="35px" mb="13px" mt="15px" as="span">
              {total < 1 && 'Nenhum resultado foi encontrado'}
              {total === 1 && 'Foi encontrado 1 resultado'}
              {total > 1 && `Foram encontrados ${total} resultados`}
            </Text>
          </Flex>
        </Box>
      </GridItem>
    </SimpleGridForm>
  );
};

export default Listar;
