import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { OptionsType, OptionTypeBase } from 'react-select';
import { toast } from 'react-toastify';
import { useFormContext } from 'react-hook-form';

import { CategoriaProdutoType } from 'store/CategoriasProduto';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

export declare type TipoProdutoType = 1 | 2 | 3;

type ProdutoCategoriasProdutoContextProps = {
  categorias: CategoriaProdutoType[];
  categoriasOptions: OptionsType<OptionTypeBase>;
  categoriasIdSelecionaveis: string[];
  isLoading: boolean;
  handleGetCategoriasPeloPaiId: (
    categoriaProdutoPaiId?: string
  ) => CategoriaProdutoType[];
  categoriaSelecionada: string;
  setCategoriaSelecionada: React.Dispatch<React.SetStateAction<string>>;
  resetCategoriaSelecionada: () => void;
  handleGetCategoriaProdutoOptions: () => Promise<void>;
};

export const ProdutoCategoriasProdutoContext = createContext<ProdutoCategoriasProdutoContextProps>(
  {} as ProdutoCategoriasProdutoContextProps
);

interface ProdutoCategoriasProdutoProviderProps {
  children: ReactNode;
}

export default function ProdutoCategoriasProdutoProvider({
  children,
}: ProdutoCategoriasProdutoProviderProps): JSX.Element {
  const { getValues } = useFormContext();

  const [categorias, setCategorias] = useState<CategoriaProdutoType[]>([]);
  const [categoriasOptions, setCategoriasOptions] = useState<
    OptionsType<OptionTypeBase>
  >([]);
  const [categoriasIdSelecionaveis, setCategoriasIdSelecionaveis] = useState<
    string[]
  >([]);
  const [categoriaSelecionada, setCategoriaSelecionada] = useState<string>(
    getValues('categoriaProduto')?.value || undefined
  );
  const [isLoading, setIsLoading] = useState(true);

  const resetCategoriaSelecionada = () => {
    setCategoriaSelecionada(getValues('categoriaProduto')?.value || undefined);
  };

  const handleGetCategoriasSelecionaveis = (
    categoriasProdutoValue: CategoriaProdutoType[]
  ) => {
    const categoriasSelecionaveis: CategoriaProdutoType[] = categoriasProdutoValue.filter(
      (categoriaProduto) =>
        !categoriasProdutoValue.find(
          (categoriaProdutoInterna) =>
            categoriaProdutoInterna.categoriaProdutoPaiId ===
            categoriaProduto.id
        )
    );

    setCategoriasIdSelecionaveis(
      categoriasSelecionaveis.map(
        (categoriaSelecionavel) => categoriaSelecionavel.id
      )
    );

    const options: OptionTypeBase[] = [];

    categoriasSelecionaveis.forEach((categoriaProduto) => {
      const stringifyOption = {
        label: categoriaProduto.nome,
        value: categoriaProduto.id,
      };

      const categoriasJaMapeadas = new Set<string>();

      function handleGetCategoriaOptionLabel(categoriaProdutoPaiId: string) {
        if (categoriasJaMapeadas.has(categoriaProdutoPaiId)) return;
        categoriasJaMapeadas.add(categoriaProdutoPaiId);

        const parent = categoriasProdutoValue.find(
          (v) => v.id === categoriaProdutoPaiId
        );

        if (parent) {
          stringifyOption.label = `${parent.nome}>${stringifyOption.label}`;

          if (parent.categoriaProdutoPaiId)
            handleGetCategoriaOptionLabel(parent.categoriaProdutoPaiId);
        }
      }

      if (categoriaProduto.categoriaProdutoPaiId)
        handleGetCategoriaOptionLabel(categoriaProduto.categoriaProdutoPaiId);

      const [
        primeiroNivel,
        segundoNivel,
        terceiroNivel,
        quartoNivel,
      ] = stringifyOption.label.split('>');

      const newOption = {
        value: categoriaProduto.id,
        primeiroNivel,
        segundoNivel,
        terceiroNivel,
        quartoNivel,
      };

      options.push(newOption);
    });
    const ordernarAlfabeticamentePorPrimeiroNivel = options.sort((a, b) =>
      a.primeiroNivel?.localeCompare(b.primeiroNivel)
    );

    setCategoriasOptions(ordernarAlfabeticamentePorPrimeiroNivel);
  };

  const handleGetCategoriaProdutoOptions = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<void, ResponseApi<CategoriaProdutoType[]>>(
      ConstanteEnderecoWebservice.CATEGORIA_PRODUTO_LISTAR_SELECT,
      { params: { ativo: true } }
    );

    if (response?.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (response?.sucesso) {
      setCategorias(response.dados);
      handleGetCategoriasSelecionaveis(response.dados);
    } else setCategorias([]);

    setIsLoading(false);
  }, []);

  const handleGetCategoriasPeloPaiId = useCallback(
    (categoriaProdutoPaiId?: string) => {
      return categorias
        .filter((categoriaProduto) =>
          !categoriaProdutoPaiId
            ? !categoriaProduto.categoriaProdutoPaiId ||
              categoriaProduto.categoriaProdutoPaiId ===
                '00000000-0000-0000-0000-000000000000'
            : categoriaProduto.categoriaProdutoPaiId === categoriaProdutoPaiId
        )
        .sort((a, b) => {
          if (a.nome < b.nome) {
            return -1;
          }
          if (a.nome > b.nome) {
            return 1;
          }
          return 0;
        });
    },
    [categorias]
  );

  useEffect(() => {
    handleGetCategoriaProdutoOptions();
  }, [handleGetCategoriaProdutoOptions]);

  return (
    <ProdutoCategoriasProdutoContext.Provider
      value={{
        categorias,
        categoriasOptions,
        categoriasIdSelecionaveis,
        handleGetCategoriaProdutoOptions,
        isLoading,
        handleGetCategoriasPeloPaiId,
        categoriaSelecionada,
        setCategoriaSelecionada,
        resetCategoriaSelecionada,
      }}
    >
      {children}
    </ProdutoCategoriasProdutoContext.Provider>
  );
}

export function useProdutoCategoriasProdutoContext(): ProdutoCategoriasProdutoContextProps {
  const context = useContext(ProdutoCategoriasProdutoContext);

  if (!context)
    throw new Error(
      'useProdutoCategoriasProdutoContext must be used within a ProdutoCategoriasProdutoProvider.'
    );

  return context;
}
