import React from 'react';
import {
  ModalContent,
  ModalBody,
  Flex,
  Button,
  Heading,
  Text,
  Center,
  useDisclosure,
  ModalProps,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';

import { AtencaoAvisoIcon, ErroAvisoIcon } from 'icons';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';

type ModalConfirmarAcaoProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalProps> & {
    callbackCancel?: () => void;
    callbackSubmit: () => void;
    mainText: string;
    secondaryText: string;
    variant: 'danger' | 'warning';
    submitButtonText?: string;
    cancelButtonText?: string;
  };

export const ModalConfirmarAcao = create<ModalConfirmarAcaoProps>(
  ({
    onResolve,
    onReject,
    mainText,
    secondaryText,
    variant,
    callbackCancel,
    callbackSubmit,
    submitButtonText = 'Continuar',
    cancelButtonText = 'Cancelar',
    ...rest
  }) => {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    return (
      <ModalPadraoChakra
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        motionPreset="slideInBottom"
        size="xl"
      >
        <ModalContent
          marginBottom={{ base: 0, sm: '3.75rem' }}
          marginTop={{ base: 0, sm: '3.75rem' }}
          borderRadius="5px"
          h="unset"
          w="550px"
        >
          <ModalBody p={0} h="100%" borderRadius="5px">
            <Flex flexDirection="column" align="stretch" h="100%">
              <Flex
                justifyContent="center"
                alignItems="center"
                h="128px"
                bg={variant === 'danger' ? 'red.500' : 'yellow.500'}
                style={{
                  borderTopLeftRadius: '5px',
                  borderTopRightRadius: '5px',
                }}
              >
                {variant === 'danger' ? (
                  <ErroAvisoIcon
                    style={{
                      fontSize: '60px',
                      fontWeight: 'bolder',
                      color: 'white',
                    }}
                  />
                ) : (
                  <AtencaoAvisoIcon
                    style={{
                      fontSize: '60px',
                      fontWeight: 'bolder',
                      color: 'white',
                    }}
                  />
                )}
              </Flex>

              <Flex
                justifyContent="flex-start"
                flexDirection="column"
                bg="gray.50"
                px="35px"
                pt="32px"
                pb="40px"
                gap="16px"
                borderRadius="5px"
                minH="232px"
              >
                <Heading
                  as="h2"
                  fontSize="20px"
                  color={variant === 'danger' ? 'red.500' : 'yellow.500'}
                  fontWeight="semibold"
                  textAlign="initial"
                >
                  {mainText}
                </Heading>

                <Text minH="68px" fontSize="sm" color="gray.700">
                  {secondaryText}
                </Text>

                <Center>
                  <Flex
                    justifyContent="center"
                    width="full"
                    gap="24px"
                    mt="16px"
                  >
                    <Button
                      variant="outline"
                      width="96px"
                      height="32px"
                      maxW="120px"
                      fontSize="sm"
                      onClick={callbackCancel || onClose}
                    >
                      {cancelButtonText}
                    </Button>
                    <Button
                      variant="solid"
                      colorScheme={variant === 'danger' ? 'red' : 'yellow'}
                      maxW="200px"
                      width="150px"
                      height="32px"
                      autoFocus
                      fontWeight="medium"
                      fontSize="sm"
                      onClick={() => {
                        onClose();
                        callbackSubmit();
                      }}
                    >
                      {submitButtonText}
                    </Button>
                  </Flex>
                </Center>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
