export type StatusOperacaoTransferencia = 1 | 2 | 3 | 4 | 5;

const statusOperacaoTransferenciaEnum = {
  RASCUNHO: 1,
  PENDENTE: 2,
  REJEITADA: 3,
  CONFIRMADA: 4,
  CANCELADA: 5,

  properties: {
    1: { name: 'Rascunho', value: 1 },
    2: { name: 'Pendente', value: 2 },
    3: { name: 'Rejeitada', value: 3 },
    4: { name: 'Confirmada', value: 4 },
    5: { name: 'Cancelada', value: 5 },
  },
};

export const statusOperacaoTransferenciaOptions = [
  {
    label: 'Rascunho',
    value: statusOperacaoTransferenciaEnum.RASCUNHO,
  },
  { label: 'Pendente', value: statusOperacaoTransferenciaEnum.PENDENTE },
  { label: 'Rejeitada', value: statusOperacaoTransferenciaEnum.REJEITADA },
  {
    label: 'Confirmada',
    value: statusOperacaoTransferenciaEnum.CONFIRMADA,
  },
  {
    label: 'Cancelada',
    value: statusOperacaoTransferenciaEnum.CANCELADA,
  },
];

export default statusOperacaoTransferenciaEnum;
