import React, { useEffect, useState } from 'react';
import {
  SimpleGrid,
  GridItem,
  Text,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Icon,
  HStack,
  Button,
  Flex,
  useToken,
  Tooltip,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { FiMoreVertical } from 'react-icons/fi';
import Highlighter from 'react-highlight-words';

import { useRegrasFiscaisFormularioContext } from 'store/RegrasFiscais/RegrasFiscaisFormularios';
import getOptionsByEnum from 'helpers/format/getOptionsByEnum';
import ModalidadesBaseCalculoIcmsEnum from 'constants/enum/fiscal/modalidadeBaseCalculoIcms';
import IcmsCstCsosnEnum from 'constants/enum/fiscal/icmsCstCsosn';
import SituacaoTributariaPisCofinsEnum from 'constants/enum/fiscal/situacaoTributariaPisCofins';

import Input from 'components/Input/InputPadrao';
import InputCfop from 'components/Input/InputCfop';
import Select from 'components/Select/SelectPadrao';
import TableCampoFormularioPadrao from 'components/Table/TableCampoFormularioPadrao';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { ColumnHeaderType } from 'components/Table/TableCampoFormularioPadrao/TableHeader';
import CheckBoxDefault from 'components/CheckBox/CheckBoxDefault';
import { InfoIcon } from 'icons';

import ModalAdicionarBaseLegal, {
  baseLegalTags,
} from './ModalAdicionarBaseLegal';

interface RegraFiscalItemProps {
  index: number;
  regimeTributario: 'simplesNacional' | 'regimeNormal';
  indicadorIe: 'contribuinte' | 'naoContribuinte';
  isReadonly?: boolean;
}

const RegraFiscalItem = ({
  index,
  regimeTributario,
  indicadorIe,
  isReadonly = false,
}: RegraFiscalItemProps) => {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useFormContext();

  const { isLoading, setIsLoading } = useRegrasFiscaisFormularioContext();
  const blue500 = useToken('colors', 'blue.500');

  function getFieldName(fieldName: string) {
    return `operacoes.${index}.${regimeTributario}.${indicadorIe}.${fieldName}`;
  }

  function getFieldError(fieldName: string) {
    if (errors) {
      const { operacoes } = errors;

      const operacao = operacoes ? operacoes[index] : undefined;

      if (operacao) {
        const regimeTributarioErrors = operacao[regimeTributario];

        if (regimeTributarioErrors) {
          const regraFiscalItem = regimeTributarioErrors[indicadorIe];

          if (regraFiscalItem) {
            return regraFiscalItem[fieldName];
          }
        }
      }
    }

    return null;
  }

  async function handleAddBaseLegal() {
    const newBaseLegal = await ModalAdicionarBaseLegal();

    const basesLegais = getValues(`${getFieldName('basesLegais')}` as const);

    setValue(`${getFieldName('basesLegais')}` as const, [
      ...(basesLegais || []),
      newBaseLegal,
    ]);
  }

  async function handlePatchBaseLegal(baseLegalIndex: number) {
    const newBasesLegais = [
      ...(getValues(`${getFieldName('basesLegais')}` as const) || []),
    ];

    const currentBaseLegal = newBasesLegais[baseLegalIndex];

    newBasesLegais.splice(baseLegalIndex, 1);

    if (currentBaseLegal) {
      const { descricao } = await ModalAdicionarBaseLegal({
        defaultValues: currentBaseLegal,
      });

      const newBaseLegal = { ...currentBaseLegal, descricao };

      newBasesLegais.splice(baseLegalIndex, 0, newBaseLegal);

      setValue(`${getFieldName('basesLegais')}` as const, newBasesLegais);
    }
  }

  function handleRemoveBaseLegal(baseLegalIndex: number) {
    ModalConfirmacaoExcluir({
      callback: async (ok: boolean) => {
        if (ok) {
          const newBasesLegais = [
            ...(getValues(`${getFieldName('basesLegais')}` as const) || []),
          ];

          newBasesLegais.splice(baseLegalIndex, 1);

          setValue(`${getFieldName('basesLegais')}` as const, newBasesLegais);
        }
      },
    });
  }

  const cstCsosnDentroEstado = watch(
    `${getFieldName('cstCsosnDentroEstado')}` as const
  );
  const cstCsosnForaEstado = watch(
    `${getFieldName('cstCsosnForaEstado')}` as const
  );
  const cstCsosnForaPais = watch(
    `${getFieldName('cstCsosnForaPais')}` as const
  );

  const [icmsCstCsosnFilter, setIcmsCstCsosnFilter] = useState([] as any);

  useEffect(() => {
    const icmsCstCsosnEnumList = Object.entries(
      IcmsCstCsosnEnum.properties
    ).map((value: any) => {
      return (
        {
          label: value[1].name,
          value: value[1].value,
        } || {}
      );
    });

    if (regimeTributario === 'simplesNacional') {
      setIcmsCstCsosnFilter(
        icmsCstCsosnEnumList.filter((item) => item.value > 100)
      );
    } else {
      setIcmsCstCsosnFilter(
        icmsCstCsosnEnumList.filter((item) => item.value < 100)
      );
    }
  }, [regimeTributario]);

  useEffect(() => {
    if (isLoading) {
      setIsLoading(false);
    }
  }, [isLoading, setIsLoading]);

  if (isLoading) {
    return null;
  }

  return (
    <SimpleGrid columns={12} gap={{ base: 3, sm: 6, md: 8 }}>
      <GridItem colSpan={{ base: 12, lg: 6 }}>
        <Input
          type="text"
          id={getFieldName('naturezaOperacao')}
          label="Natureza da operação"
          placeholder="Informe um nome para a regra"
          maxLength={60}
          disabled={isReadonly}
          required={
            !!cstCsosnDentroEstado ||
            cstCsosnDentroEstado === 0 ||
            !!cstCsosnForaEstado ||
            cstCsosnForaEstado === 0
          }
          error={getFieldError('naturezaOperacao')}
          control={control}
        />
      </GridItem>
      <GridItem colSpan={{ base: 12, lg: 6 }}>
        <Select
          id={getFieldName('modalidadeBaseCalculoIcms')}
          name={getFieldName('modalidadeBaseCalculoIcms')}
          label="Modalidade para base de cálculo"
          placeholder="Selecione"
          options={getOptionsByEnum(ModalidadesBaseCalculoIcmsEnum)}
          defaultValue={ModalidadesBaseCalculoIcmsEnum.ValorOperacao}
          readonly={isReadonly}
          control={control}
          error={getFieldError('modalidadeBaseCalculoIcms')}
          required
        />
      </GridItem>
      <GridItem colSpan={12}>
        <Text lineHeight="1" fontSize="13px" fontWeight="semibold" mb="3px">
          Operações DENTRO do Estado
        </Text>
        <Box
          p={{ base: 3, sm: 6, md: 8 }}
          bg="purple.50"
          borderRadius="md"
          border="1px"
          borderColor="gray.100"
        >
          <SimpleGrid columns={12} gap={{ base: 3, sm: 6, md: 8 }}>
            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <Select
                id={getFieldName('cstCsosnDentroEstado')}
                name={getFieldName('cstCsosnDentroEstado')}
                defaultValue={null}
                label="Situação tributária"
                placeholder="Selecione"
                options={icmsCstCsosnFilter}
                readonly={isReadonly}
                control={control}
                error={getFieldError('cstCsosnDentroEstado')}
              />
            </GridItem>
            <GridItem colSpan={{ base: 12, md: 6, lg: 3 }}>
              <InputCfop
                inputMode="numeric"
                type="cf"
                id={getFieldName('cfopDentroEstado')}
                name={getFieldName('cfopDentroEstado')}
                label="CFOP"
                placeholder="Digite o número"
                disabled={isReadonly}
                error={getFieldError('cfopDentroEstado')}
                control={control}
                required={!!cstCsosnDentroEstado || cstCsosnDentroEstado === 0}
              />
            </GridItem>
            <GridItem colSpan={{ base: 12, md: 6, lg: 3 }}>
              <InputCfop
                inputMode="numeric"
                type="cf"
                id={getFieldName('cfopCupomVinculadoDentroEstado')}
                name={getFieldName('cfopCupomVinculadoDentroEstado')}
                label="CFOP com cupom vinculado"
                placeholder="Digite o número"
                disabled={isReadonly}
                error={getFieldError('cfopCupomVinculadoDentroEstado')}
                control={control}
                infoText="CFOP que será utilizado na NF-e quando houver um cupom fiscal vinculado."
              />
            </GridItem>
          </SimpleGrid>
        </Box>
      </GridItem>
      <GridItem colSpan={12}>
        <Text lineHeight="1" fontSize="13px" fontWeight="semibold" mb="3px">
          Operações FORA do Estado
        </Text>
        <Box
          p={{ base: 3, sm: 6, md: 8 }}
          bg="aquamarine.50"
          borderRadius="md"
          border="1px"
          borderColor="gray.100"
        >
          <SimpleGrid columns={12} gap={{ base: 3, sm: 6, md: 8 }}>
            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <Select
                id={getFieldName('cstCsosnForaEstado')}
                name={getFieldName('cstCsosnForaEstado')}
                defaultValue={null}
                label="Situação tributária"
                placeholder="Selecione"
                options={icmsCstCsosnFilter}
                readonly={isReadonly}
                control={control}
                error={getFieldError('cstCsosnForaEstado')}
              />
            </GridItem>
            <GridItem colSpan={{ base: 12, md: 6, lg: 3 }}>
              <InputCfop
                inputMode="numeric"
                type="cf"
                id={getFieldName('cfopForaEstado')}
                name={getFieldName('cfopForaEstado')}
                label="CFOP"
                placeholder="Digite o número"
                disabled={isReadonly}
                error={getFieldError('cfopForaEstado')}
                control={control}
                required={!!cstCsosnForaEstado || cstCsosnForaEstado === 0}
              />
            </GridItem>
            <GridItem colSpan={{ base: 12, md: 6, lg: 3 }}>
              <InputCfop
                inputMode="numeric"
                type="cf"
                id={getFieldName('cfopCupomVinculadoForaEstado')}
                name={getFieldName('cfopCupomVinculadoForaEstado')}
                label="CFOP com cupom vinculado"
                placeholder="Digite o número"
                disabled={isReadonly}
                error={getFieldError('cfopCupomVinculadoForaEstado')}
                control={control}
                infoText="CFOP que será utilizado na NF-e quando houver um cupom fiscal vinculado."
              />
            </GridItem>
          </SimpleGrid>
        </Box>
      </GridItem>
      <GridItem colSpan={12}>
        <Text lineHeight="1" fontSize="13px" fontWeight="semibold" mb="3px">
          Operações FORA do País
        </Text>
        <Box
          p={{ base: 3, sm: 6, md: 8 }}
          bg="blue.50"
          borderRadius="md"
          border="1px"
          borderColor="gray.100"
        >
          <SimpleGrid columns={12} gap={{ base: 3, sm: 6, md: 8 }}>
            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <Select
                id={getFieldName('cstCsosnForaPais')}
                name={getFieldName('cstCsosnForaPais')}
                defaultValue={null}
                label="Situação tributária"
                placeholder="Selecione"
                options={icmsCstCsosnFilter}
                readonly={isReadonly}
                control={control}
                error={getFieldError('cstCsosnForaPais')}
              />
            </GridItem>
            <GridItem colSpan={{ base: 12, md: 6, lg: 3 }}>
              <InputCfop
                inputMode="numeric"
                type="cf"
                id={getFieldName('cfopForaPais')}
                name={getFieldName('cfopForaPais')}
                label="CFOP"
                placeholder="Digite o número"
                disabled={isReadonly}
                error={getFieldError('cfopForaPais')}
                control={control}
                required={!!cstCsosnForaPais || cstCsosnForaPais === 0}
              />
            </GridItem>
            <GridItem colSpan={{ base: 12, md: 6, lg: 3 }}>
              <InputCfop
                inputMode="numeric"
                type="cf"
                id={getFieldName('cfopCupomVinculadoForaPais')}
                name={getFieldName('cfopCupomVinculadoForaPais')}
                label="CFOP com cupom vinculado"
                placeholder="Digite o número"
                disabled={isReadonly}
                error={getFieldError('cfopCupomVinculadoForaPais')}
                control={control}
                infoText="CFOP que será utilizado na NF-e quando houver um cupom fiscal vinculado."
              />
            </GridItem>
          </SimpleGrid>
        </Box>
      </GridItem>
      <GridItem colSpan={{ base: 12, lg: 9 }}>
        <Select
          id={getFieldName('pisCofinsCst')}
          name={getFieldName('pisCofinsCst')}
          defaultValue={null}
          label="Situação tributária PIS/COFINS"
          placeholder="Selecione"
          options={getOptionsByEnum(SituacaoTributariaPisCofinsEnum)}
          readonly={isReadonly}
          control={control}
          error={getFieldError('pisCofinsCst')}
        />
      </GridItem>
      <GridItem colSpan={{ base: 12, lg: 9 }}>
        <Flex>
          <Text fontWeight="600">
            Calcular tributo municipal, estadual e federal
          </Text>
          <Tooltip
            hasArrow
            placement="right"
            label="Calcular o valor do tributo federal, estadual e municipal na regra fiscal"
            fontSize="sm"
          >
            <Flex mt="3px" ml="10px" justifyContent="flex-start">
              <InfoIcon />
            </Flex>
          </Tooltip>
        </Flex>
        <CheckBoxDefault
          id={getFieldName('calcularTributosMunEstFed')}
          name={getFieldName('calcularTributosMunEstFed')}
          control={control}
          defaultValue
        />
      </GridItem>
      <GridItem colSpan={12}>
        <HStack justify="space-between" mb="3px">
          <Text lineHeight="1" fontSize="13px" fontWeight="semibold">
            Base legal
          </Text>

          {!isReadonly && (
            <Button
              variant="link"
              colorScheme="blue"
              size="xs"
              textDecoration="none"
              _hover={{ textDecoration: 'none' }}
              _focus={{ boxShadow: 'none' }}
              onClick={() => handleAddBaseLegal()}
            >
              Adicionar nova
            </Button>
          )}
        </HStack>
        <TableCampoFormularioPadrao
          fieldName={getFieldName('basesLegais')}
          defaultColumnOrder=""
          headerColumns={[
            {
              name: 'descricao',
              displayName: 'Descrição',
              isOrderable: false,
              width: 'auto',
            },
            ...(isReadonly
              ? []
              : ([
                  {
                    name: 'acoes',
                    displayName: 'Ações',
                    isOrderable: false,
                    width: '1px',
                    alignText: 'center',
                  },
                ] as ColumnHeaderType[])),
          ]}
          emptyValuesText="Nenhuma base legal adicionada para esta regra fiscal."
          control={control}
          isLoading={isLoading}
        >
          {({ tableValues }) => (
            <>
              {tableValues &&
                tableValues.map((tableValue, baseLegalIndex) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <tr key={baseLegalIndex}>
                    <td>
                      <Flex
                        position="relative"
                        w="full"
                        h="full"
                        align="center"
                      >
                        <Text position="absolute" isTruncated maxW="full">
                          <Highlighter
                            searchWords={baseLegalTags}
                            textToHighlight={tableValue.descricao}
                            autoEscape
                            highlightStyle={{
                              color: `${blue500}`,
                              background: 'none',
                              padding: 0,
                            }}
                          />
                        </Text>
                      </Flex>
                    </td>
                    {!isReadonly && (
                      <td>
                        <Menu placement="auto">
                          <MenuButton
                            as={IconButton}
                            borderRadius="md"
                            aria-label="Options"
                            icon={<Icon as={FiMoreVertical} fontSize="lg" />}
                            variant="unstyled"
                            size="xs"
                            p="2"
                          />
                          <MenuList>
                            <MenuItem
                              onClick={() =>
                                handlePatchBaseLegal(baseLegalIndex)
                              }
                            >
                              Editar
                            </MenuItem>
                            <MenuItem
                              onClick={() =>
                                handleRemoveBaseLegal(baseLegalIndex)
                              }
                            >
                              Remover
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </td>
                    )}
                  </tr>
                ))}
            </>
          )}
        </TableCampoFormularioPadrao>
      </GridItem>
    </SimpleGrid>
  );
};

export default RegraFiscalItem;
