import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import CreatableSelectField from 'components/PDV/Select/CreatableSelect';
import OptionType from 'types/optionType';

import { chakraComponents } from '../ReactSelectIntegracao';
import { MenuList } from './components/MenuList';
import {
  CreatableSelectVirtualizedProps,
  valuePaginationDefault,
} from './validationForm';

let inputTypingTimeout: NodeJS.Timeout;

const CreatableSelectVirtualized = ({
  name,
  totalRegistros = 0,
  handleGetOptions,
  components,
  ...rest
}: CreatableSelectVirtualizedProps) => {
  const [valueInput, setValueInput] = useState('');
  const [listOptions, setOptions] = useState<OptionType[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const currentPage = useRef(0);
  const listOptionsRef = useRef<OptionType[]>([]);
  const isLoadingRef = useRef(false);

  const missingOptionsLoad = listOptions.length < totalRegistros;

  const getValueInput = (inputValue: string) => {
    setValueInput(inputValue);
  };

  const clearList = useCallback(() => {
    currentPage.current = 1;
    setOptions([]);
  }, []);

  const loadOptions = useCallback(
    async (inputValue: string) => {
      setIsLoading(true);
      isLoadingRef.current = true;

      const promise = await new Promise(() => {
        clearTimeout(inputTypingTimeout);

        currentPage.current += 1;

        inputTypingTimeout = global.setTimeout(async () => {
          const options = await handleGetOptions(inputValue, {
            ...valuePaginationDefault,
            currentPage: currentPage.current,
            pageSize: valuePaginationDefault.pageSize,
          });

          setOptions((prev) => [...prev, ...options]);
          isLoadingRef.current = false;
          setIsLoading(false);
        }, 500);
      });

      return promise;
    },
    [handleGetOptions]
  );

  const updateOptionsListSelect = useCallback(
    async (inputValue: string) => {
      loadOptions(inputValue);
    },
    [loadOptions]
  );

  const componentsSelect = useMemo(() => {
    const newOptionCreatable = { label: valueInput, value: valueInput };

    return {
      ...components,
      ...chakraComponents,
      MenuList: ({ children }: any) =>
        MenuList({
          isLoading: isLoadingRef.current,
          children,
          listOptions:
            valueInput?.length > 0
              ? [newOptionCreatable, ...listOptionsRef.current]
              : listOptionsRef.current,
          totalRegistros,
          inputValue: valueInput,
          updateOptionsListSelect,
        }),
    };
  }, [components, totalRegistros, updateOptionsListSelect, valueInput]);

  useEffect(() => {
    const getOptions = () => {
      if (valueInput?.length > 0) {
        updateOptionsListSelect(valueInput);

        clearList();
      }
    };
    getOptions();
  }, [clearList, updateOptionsListSelect, valueInput]);

  useEffect(() => {
    listOptionsRef.current = listOptions;
  }, [listOptions]);

  return (
    <>
      <CreatableSelectField
        name={name}
        filterOption={() => true}
        isLoading={isLoading && missingOptionsLoad}
        onInputChange={getValueInput}
        components={componentsSelect}
        options={listOptions}
        onBlur={clearList}
        noOptionsMessage={() => 'Não há valores disponíveis'}
        {...rest}
      />
    </>
  );
};

export default CreatableSelectVirtualized;
