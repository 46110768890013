export const CurvaABCEnum = {
  FATURAMENTO: 0,
  QUANTIDADE: 1,
  VALOR_LUCRO: 2,
  PORCENTAGEM_LUCRO: 3,
  ITENS_VENDIDOS: 4,
  QUANTIDADE_VENDAS: 5,
  VALOR_TOTAL_VENDAS: 6,
  VALOR_LUCRO_CLIENTE: 7,
  PORCENTAGEM_LUCRO_CLIENTE: 8,

  properties: [
    {
      name: 'Faturamento',
      value: 0,
    },
    {
      name: 'Quantidade',
      value: 1,
    },
    {
      name: 'Lucro (Valor) ',
      value: 2,
    },
    {
      name: 'Lucro (%) ',
      value: 3,
    },
    {
      name: 'Itens vendidos',
      value: 4,
    },
    {
      name: 'Quantidade de vendas',
      value: 5,
    },
    {
      name: 'Valor total',
      value: 6,
    },
    {
      name: 'Lucro (valor)',
      value: 7,
    },
    {
      name: 'Lucro (%)',
      value: 8,
    },
  ],
};
export const TipoRelatorioCurvaABC = {
  PRODUTO: 0,
  CLIENTE: 1,

  properties: [
    {
      name: 'Produto',
      value: 0,
    },
    {
      name: 'Cliente',
      value: 1,
    },
  ],
};
