import React, { SyntheticEvent } from 'react';

import { Container, Title, AscIcon, DescIcon } from './styles';

export interface ColumnHeader {
  name: string;
  displayName: string | JSX.Element;
  isOrderable: boolean;
  width: string;
  minWidth?: string;
  alignText?: 'center' | 'right';
}

export interface HeaderData {
  orderColumn: string;
  orderDirection: string;
  columns: Array<ColumnHeader>;
}

interface CabecalhoProps {
  headerData: HeaderData;
  setOrder: (event: SyntheticEvent, newOrderColumn: string) => void;
  orderColumn: string;
  orderDirection: string;
}

const Cabecalho = ({
  headerData,
  setOrder,
  orderColumn,
  orderDirection,
}: CabecalhoProps) => {
  return (
    <Container>
      <tr>
        {headerData.columns.map((column) => {
          let justifyContent;

          if (column.alignText === 'center') justifyContent = 'center';
          else if (column.alignText === 'right') justifyContent = 'flex-end';
          else justifyContent = 'flex-start';

          return (
            <th
              key={column.name}
              style={{
                width: column.width,
                minWidth: column.minWidth,
              }}
            >
              <span
                role="presentation"
                onClick={(event) =>
                  column.isOrderable && setOrder(event, column.name)
                }
                style={{
                  cursor: column.isOrderable ? 'pointer' : 'not-allowed',
                  userSelect: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent,
                  fontWeight: column.name === orderColumn ? 700 : 600,
                }}
              >
                <Title style={{ justifyContent }}>
                  {column.displayName}
                  {column.name === orderColumn &&
                    (orderDirection === 'asc' ? <AscIcon /> : <DescIcon />)}
                </Title>
              </span>
            </th>
          );
        })}
      </tr>
    </Container>
  );
};

export default Cabecalho;
