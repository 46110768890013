import { Text, Flex, Tr, Td, Checkbox, useMediaQuery } from '@chakra-ui/react';

import currency from 'currency.js';

import {
  ForwardRefData,
  LoadMoreRowsParams,
  TableHeader,
  VirtualizedInfiniteTable,
} from 'components/update/Table/VirtualizedInfiniteTable';
import { ProdutoSelecionadoProps } from 'pages/PDV/TrocarProdutos/Types/validationForm';

type EscolherProdutosParaDevolucaoMobileProps = {
  isLoading: boolean;
  produtoRef: React.RefObject<ForwardRefData>;
  listaProduto: ProdutoSelecionadoProps[];
  itemsTotalCount: number;
  loadMoreRowsProduct: ({
    currentPage,
    pageSize,
    orderColumn,
    orderDirection,
  }: LoadMoreRowsParams) => Promise<void>;
  handleToggleSelecionarTodosItens(): void;
  selecionarTodosProdutos: boolean;
  handleToggleSelecionarProdutoTroca(index: number): void;
};

export const EscolherProdutosParaDevolucaoMobile = ({
  isLoading,
  produtoRef,
  listaProduto,
  itemsTotalCount,
  loadMoreRowsProduct,
  handleToggleSelecionarTodosItens,
  selecionarTodosProdutos,
  handleToggleSelecionarProdutoTroca,
}: EscolherProdutosParaDevolucaoMobileProps) => {
  const [isLargerThan500] = useMediaQuery('(max-width: 500px)');

  const produtosTableHeader: TableHeader[] = [
    {
      key: 'select',
      content: (
        <Checkbox
          onChange={() => handleToggleSelecionarTodosItens()}
          colorScheme="primary"
          isChecked={selecionarTodosProdutos}
          mb="0"
        />
      ),
      width: '10px',
      whiteSpace: 'nowrap',
    },
    {
      key: 'produto',
      content: 'Produto',
      width: '82%',
    },
    {
      key: 'valor ',
      content: 'Valor unitário',
      isNumeric: true,
      whiteSpace: 'nowrap',
    },
  ];

  return (
    <VirtualizedInfiniteTable
      ref={produtoRef}
      boxShadow="null"
      color="gray.300"
      alterarBordaListagem="gray.50"
      isMobileProdutoTroca
      isEdicaoManual
      variant="outline"
      isLoading={isLoading}
      withoutRowsMessage="Adicione ao menos um produto para avançar na conferência."
      orderColumn="Nome"
      visibleItemsCount={9}
      pageSize={20}
      tableHeaders={produtosTableHeader}
      rowRenderer={({ index, style: { height, ...restStyle }, key }) => {
        const produto = listaProduto[index];

        if (!produto) {
          return null;
        }

        return (
          <>
            <Tr
              h="50px !important"
              position="relative"
              key={key}
              style={restStyle}
            >
              <Td>
                <Checkbox
                  colorScheme="primary"
                  top="3"
                  left="1"
                  position="absolute"
                  onChange={() => handleToggleSelecionarProdutoTroca(index)}
                  isChecked={produto.isChecked}
                  mb="0"
                />
              </Td>

              <Td w="80%">
                <Flex left="9" ml="15px" top="3" position="absolute">
                  <Text
                    isTruncated
                    w={isLargerThan500 ? '140px' : 'full'}
                    fontSize="14px"
                  >
                    {produto.nome}
                  </Text>
                </Flex>
              </Td>

              <Td isNumeric>
                <Flex w="140px" position="absolute" top="3" right="6">
                  <Text isTruncated w="140px" fontSize="14px">
                    {currency(produto.valor).format({
                      precision: 2,
                      separator: '.',
                      decimal: ',',
                      symbol: 'R$ ',
                    })}
                  </Text>
                </Flex>
              </Td>
            </Tr>
          </>
        );
      }}
      rowCount={itemsTotalCount}
      isRowLoaded={({ index }) => !!listaProduto[index]}
      loadMoreRows={loadMoreRowsProduct}
    />
  );
};
