import jsonp from 'jsonp';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export interface CepResponse {
  bairro: string;
  complemento: string;
  logradouro: string;
  ibge: string | number;
  localidade: string;
}

const consultarViaCep = async (
  cep: string,
  getData: (sucesso: boolean, dados: CepResponse | string) => void
) => {
  jsonp(
    `https://viacep.com.br/ws/${cep.replace(/\D/g, '')}/json/`,
    { timeout: 5000 },
    (err: any, data: any) => {
      if (err || !data)
        getData(false, ConstanteMensagemValidacao.CEP_ERRO_CONSULTA);
      else if (data && data.erro)
        getData(false, ConstanteMensagemValidacao.CEP_INVALIDO);
      else if (data) getData(true, { ...data, complemento: '' });
    }
  );
};

export default consultarViaCep;
