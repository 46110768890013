import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Icon,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalProps,
  Text,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import { RiShareLine } from 'react-icons/ri';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { ImprimirPDF } from 'helpers/impressoes/imprimirPDF';

import { NumberInput } from 'components/update/Input/NumberInput';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { ButtonDefault } from 'components/Button/ButtonChakra';

type ModalRelatorioProps = {
  volume: number;
};

type ModalRelatorioDespachoProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalRelatorioProps> & {
    operacaoId?: string;
    documentoFiscalId?: string;
    volume?: number;
  };

export const ModalRelatorioDespacho = create<
  ModalRelatorioDespachoProps,
  ModalRelatorioProps
>(({ onResolve, onReject, documentoFiscalId, volume, operacaoId, ...rest }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const formMethods = useForm<ModalRelatorioProps>({
    defaultValues: {
      volume: 1,
    },
  });

  const { watch, setValue } = formMethods;

  const volumeWatch = watch('volume');

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    let dadosApi = {};
    if (operacaoId) {
      dadosApi = {
        volume: volumeWatch,
        operacaoId,
      };
    }
    if (documentoFiscalId) {
      dadosApi = {
        volume: volumeWatch,
        documentoFiscalId,
      };
    }

    const response = await api.get<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.ETIQUETA_DESPACHO,
      {
        params: dadosApi,
      }
    );

    if (response) {
      if (response.avisos) {
        setIsLoading(false);
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso && response?.dados) {
        setIsLoading(false);
        ImprimirPDF(response.dados, 'etiqueta-despacho');
        onClose();
      }
    }
    setIsLoading(false);
  }, [documentoFiscalId, onClose, operacaoId, volumeWatch]);

  useEffect(() => {
    if (volume) {
      setValue('volume', volume);
    }
  }, [setValue, volume]);

  return (
    <ModalPadraoChakra
      isCentered={isLargerThan900}
      size={isLargerThan900 ? '3xl' : 'full'}
      {...rest}
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={false}
    >
      <ModalContent
        bg="gray.50"
        borderRadius={isLargerThan900 ? 'md' : '0'}
        marginBottom={isLargerThan900 ? '3.75rem' : '0'}
        marginTop={isLargerThan900 ? '3.75rem' : '0'}
        w={isLargerThan900 ? '550px' : 'full'}
        h={isLargerThan900 ? '420px' : 'full'}
      >
        <ModalHeader
          borderTopLeftRadius="6px"
          borderTopRightRadius="6px"
          p="0"
          bg="primary.50"
        >
          <Flex
            h="137px"
            borderTopLeftRadius="6px"
            borderTopRightRadius="6px"
            justifyContent="center"
            alignItems="center"
            bg="primary.50"
          >
            <Icon boxSize="50px" as={RiShareLine} color="white" />
          </Flex>
        </ModalHeader>
        <ModalBody>
          <FormProvider {...formMethods}>
            <Text
              mt="20px"
              fontWeight="bold"
              color="primary.50"
              fontSize="20px"
            >
              Etiqueta de despacho
            </Text>
            <Text mt="10px">
              Informe a quantidade de volumes que serão enviados.
            </Text>
            <Flex
              mt="20px"
              justifyContent="center"
              alignItems="center"
              w="100%"
            >
              <Box w={['full', 'full', '90%']}>
                <NumberInput
                  id="volume"
                  label="Volume"
                  name="volume"
                  scale={0}
                  colorScheme="secondary.300"
                  color="black"
                />
              </Box>
            </Flex>
          </FormProvider>
        </ModalBody>
        <ModalFooter>
          <Flex w="full" h="full" justifyContent="center" alignItems="baseline">
            <Button
              id="gerarCredito"
              name="gerarCredito"
              color="gray.300"
              variant="outline"
              borderRadius="20px"
              fontSize="sm"
              type="button"
              h={isLargerThan900 ? '32px' : '40px'}
              mr="24px"
              onClick={() => {
                onClose();
              }}
              _hover={{ bg: 'gray.50' }}
              w="96px"
            >
              Cancelar
            </Button>
            <ButtonDefault
              color="black"
              variant="solid"
              colorScheme="secondary"
              isDisabled={!volumeWatch}
              borderRadius="20px"
              fontSize="sm"
              type="button"
              h={isLargerThan900 ? '32px' : '40px'}
              onClick={() => handleSubmit()}
              width="96px"
              isLoading={isLoading}
            >
              Confirmar
            </ButtonDefault>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </ModalPadraoChakra>
  );
});
