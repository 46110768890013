import { useCallback, useEffect, useState } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  ModalFooter,
  Box,
  ModalHeader,
  useMediaQuery,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import { SelectNcm } from 'components/update/Select/SelectNCM';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { ButtonDefault } from 'components/Button/ButtonChakra';
import { FiscalProps } from 'pages/Produtos/Formulario/validationForm';
import { ModalAlterarProduto } from 'pages/EntradaMercadoria/EtapasCompartilhadas/ModalAlterarProduto';

import { yupResolver } from './validationForms';

type SelectProps = {
  label: string;
  value: string;
};

type FormData = {
  ncm: SelectProps;
  regraFiscal: SelectProps;
};

type RegraFiscalProps = {
  label: string;
  value: string;
};

type RegraFiscalResponseProps = {
  nome: string;
  id: string;
};

type ModalProdutoNaoEncontradoProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<FormData> & {
    id: string;
    atualizarPendencia: () => void;
    descricaoProduto: string;
  };

export const ModalProdutoNaoEncontrado = create<
  ModalProdutoNaoEncontradoProps,
  FormData
>(
  ({
    onResolve,
    onReject,
    id,
    descricaoProduto,
    atualizarPendencia,
    ...rest
  }) => {
    const [regraFiscalOptions, setRegraFiscalOptions] = useState<
      RegraFiscalProps[]
    >([]);
    const [
      regraFiscalOptionsIsLoading,
      setRegraFiscalOptionsIsLoading,
    ] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    const formMethods = useForm<FormData>({
      resolver: yupResolver,
    });

    const { handleSubmit: onSubmit, watch } = formMethods;

    const [ncmWatch, regraFiscalWatch] = watch(['ncm', 'regraFiscal']);

    const valoresPreenchidos =
      ncmWatch?.value !== undefined && regraFiscalWatch?.value !== undefined;

    const getFiscais = useCallback(async () => {
      const response = await api.get<void, ResponseApi<FiscalProps>>(
        `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${id}/fiscais`
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
          return null;
        }

        if (response.sucesso) {
          return response.dados;
        }
      }
      return null;
    }, [id]);

    const handleSubmit = onSubmit(async (data) => {
      setIsLoading(true);

      const valueFiscais = await getFiscais();

      const codigoCestApenasNumeros =
        valueFiscais?.codigoCest?.replace(/\D/g, '') ?? '';

      const response = await api.patch<void, ResponseApi<boolean>>(
        `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${id}/fiscais`,
        {
          ...valueFiscais,
          codigoCest: codigoCestApenasNumeros,
          ncmLabel: data.ncm.label,
          codigoNcm: data?.ncm?.value,
          regraFiscalId: data?.regraFiscal?.value,
        }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }

        if (response.sucesso) {
          toast.success('Produto cadastrado com sucesso');
          atualizarPendencia();
          onClose();
        }
      }
      setIsLoading(false);
    });

    const handleAlterarProduto = useCallback(async () => {
      await ModalAlterarProduto({
        valueIdProduto: id,
      });
    }, [id]);

    const handleGetRegraFiscalOptions = useCallback(async () => {
      setRegraFiscalOptionsIsLoading(true);

      const response = await api.get<
        void,
        ResponseApi<RegraFiscalResponseProps[]>
      >(ConstanteEnderecoWebservice.REGRA_FISCAL_LISTAR_SELECT);

      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }

        if (response?.sucesso) {
          setRegraFiscalOptions(
            response.dados.map((regraFiscal) => {
              return {
                label: regraFiscal.nome,
                value: regraFiscal.id,
              };
            })
          );
        }
      }

      setRegraFiscalOptionsIsLoading(false);
    }, []);

    useEffect(() => {
      handleGetRegraFiscalOptions();
    }, [handleGetRegraFiscalOptions]);

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? '3xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
      >
        <ModalContent
          bg="gray.50"
          borderRadius={isLargerThan900 ? 'md' : '0'}
          marginBottom={isLargerThan900 ? '3.75rem' : '0'}
          marginTop={isLargerThan900 ? '3.75rem' : '0'}
          w={isLargerThan900 ? '640px' : 'full'}
          h={isLargerThan900 ? '442px' : 'full'}
        >
          <ModalHeader pt="16px" pb="24px" pl="24px">
            <Text color="primary.50" fontSize="16px">
              {descricaoProduto}
            </Text>
          </ModalHeader>

          <FormProvider {...formMethods}>
            {isLoading && <LoadingPadrao />}
            <ModalBody px="24px" mb="20px" pt="0" overflowY="auto" pb="0">
              <FormProvider {...formMethods}>
                <Box
                  pt="14px"
                  pb="14px"
                  px="24px"
                  borderWidth="1px"
                  borderColor="gray.200"
                  h="auto"
                  minH="64px"
                  borderRadius="5px"
                  bg="gray.100"
                >
                  <Text fontSize="12px" color="gray.500">
                    Forma de recebimento utilizada no site:
                  </Text>
                  <Text fontWeight="semibold" fontSize="14px" color="black">
                    O produto será cadastrado automaticamente no sistema.
                    Preencha alguns dados obrigatórios ou, se preferir, edite o
                    cadastro completo.
                  </Text>
                </Box>
                <Box mt="40px">
                  <Box mb="28px" width="full">
                    <SelectNcm
                      label="NCM - nomenclatura comum do Mercosul"
                      id="ncm"
                      placeholder="Digite o código ou texto da classificação NCM"
                      name="ncm"
                      colSpan={8}
                      asControlledByObject
                    />
                  </Box>
                  <Box width="full">
                    <SelectPadrao
                      label="Regra fiscal"
                      id="formaRecebimento"
                      isLoading={regraFiscalOptionsIsLoading}
                      placeholder="Selecione a regra fiscal"
                      name="regraFiscal"
                      options={regraFiscalOptions}
                      colSpan={8}
                      asControlledByObject
                    />
                  </Box>
                </Box>
              </FormProvider>
            </ModalBody>
            <ModalFooter flexDirection="column">
              <Flex
                w="full"
                h="full"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  variant="cancelOutline"
                  mr="24px"
                  onClick={() => onClose()}
                  w="140px"
                >
                  Cancelar
                </Button>
                <Button
                  variant="outlineDefault"
                  colorScheme="gray.500"
                  mr="24px"
                  onClick={() => handleAlterarProduto()}
                  w="200px"
                >
                  Abrir cadastro completo
                </Button>
                <ButtonDefault
                  variant="solid"
                  colorScheme={valoresPreenchidos ? 'secondary' : 'gray.200'}
                  width="191px"
                  onClick={() => handleSubmit()}
                  isLoading={isLoading}
                >
                  Confirmar produto
                </ButtonDefault>
              </Flex>
            </ModalFooter>
          </FormProvider>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
