import React from 'react';
import { Flex, useMediaQuery } from '@chakra-ui/react';

import { useEntradaMercadoriaEtapasContext } from 'store/EntradaMercadoria/EntradaMercadoriaEtapas';
import { useEntradaMercadoriaDadosCadastroContext } from 'store/EntradaMercadoria/EntradaMercadoriaDadosCadastro';

import { Steps } from 'components/update/Steps';
import { SimpleCard } from 'components/update/Form/SimpleCard';

import { EscolherFornecedor } from '../EtapasCompartilhadas/EscolherFornecedor';
import { LancamentoProdutos } from './LancamentoProdutos';
import { DefinirValores } from '../EtapasCompartilhadas/DefinirValores';
import { LancamentoPagamentos } from '../EtapasCompartilhadas/LancamentoPagamentos';
import { InformacoesGerais } from '../EtapasCompartilhadas/InformacoesGerais';
import { Custos } from './Custos';

export function EntradaManual() {
  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

  const { activeStep } = useEntradaMercadoriaEtapasContext();
  const {
    statusLancamentos: { foiLancadoEstoque },
  } = useEntradaMercadoriaDadosCadastroContext();

  return (
    <SimpleCard
      p={0}
      bg="gray.50"
      boxShadow="0px 0px 6px #00000034"
      position="relative"
    >
      <Flex
        w="full"
        flexDir="column"
        alignItems="center"
        pt={{ base: 4, sm: 6, md: 8 }}
      >
        <Steps
          w="100%"
          showLabel={isLargerThan700}
          maxW={{ base: '300px', sm: '500px', lg: '900px' }}
          colorScheme="primary"
          activeStep={activeStep}
          steps={[
            {
              label: 'Fornecedor',
              content: <EscolherFornecedor />,
            },
            {
              label: 'Produtos',
              content: <LancamentoProdutos />,
            },
            {
              label: 'Valores',
              content: <DefinirValores />,
            },
            {
              label: 'Pagamento',
              content: <LancamentoPagamentos />,
            },
            {
              label: 'Informações',
              content: <InformacoesGerais />,
            },
            ...(foiLancadoEstoque
              ? [
                  {
                    label: 'Custos',
                    content: <Custos />,
                  },
                ]
              : []),
          ]}
        />
      </Flex>
    </SimpleCard>
  );
}
