export const unitNames = [
  '',
  'mil',
  'milhões',
  'bilhões',
  'trilhões',
  'quatrilhões',
  'quintilhões',
  'sextilhões',
  'septilhões',
  'octilhões',
  'nonilhões',
  'decilhões',
  'undecilhões',
  'duodecilhões',
  'tredecilhões',
];
