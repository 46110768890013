import React, { useEffect, useState, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory, RouteComponentProps } from 'react-router-dom';

import ConstanteRotas from 'constants/rotas';
import { obterLocalEstoque as apiObterLocalEstoque } from 'helpers/api/LocalEstoque/obterLocalEstoque';

import ManterFoco from 'components/Geral/ManterFoco';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { FormFooter } from 'components/update/Form/FormFooter';

import Formulario from '..';
import { yupResolver, FormData } from '../validationForm';

type TParams = { id: string };

const Visualizar = ({ match }: RouteComponentProps<TParams>) => {
  const history = useHistory();
  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues: {
      nome: '',
      ativo: true,
    },
  });

  const localEstoqueId = match.params.id;

  const [isLoading, setIsLoading] = useState(false);
  const [dataHoraCriacao, setDataHoraCriacao] = useState('');
  const [dataHoraUltimaAlteracao, setDataHoraUltimaAlteracao] = useState('');

  const latestProps = useRef({
    reset: formMethods.reset,
    localEstoqueId,
    history,
  });
  useEffect(() => {
    latestProps.current = { reset: formMethods.reset, localEstoqueId, history };
  });

  useEffect(() => {
    async function obterLocalEstoque() {
      setIsLoading(true);

      const responseData = await apiObterLocalEstoque(
        latestProps.current.localEstoqueId
      );

      if (responseData) {
        setDataHoraCriacao(responseData.dataHoraCadastro || '');
        setDataHoraUltimaAlteracao(responseData.dataHoraUltimaAlteracao || '');

        setIsLoading(false);

        latestProps.current.reset(responseData);
      } else {
        setIsLoading(false);

        latestProps.current.history.push(ConstanteRotas.LOCAL_ESTOQUE);
      }
    }

    obterLocalEstoque();
  }, []);

  return (
    <Box maxW="1116px">
      <ManterFoco>
        {isLoading && <LoadingPadrao />}
        <FormProvider {...formMethods}>
          <Formulario isReadonly />
        </FormProvider>

        <FormFooter
          isDisabled={isLoading}
          dates={{ dataHoraCriacao, dataHoraUltimaAlteracao }}
        />
      </ManterFoco>
    </Box>
  );
};

export default Visualizar;
