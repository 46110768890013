import { Flex, Text } from '@chakra-ui/react';
import React from 'react';

type StoreDetailsProps = {
  nomeFantasia: string;
  enderecoLoja: string;
  telefone?: string;
  inscricaoEstadual?: string;
  cnpjLoja: string;
};

export const StoreDetails = ({
  nomeFantasia,
  enderecoLoja,
  telefone,
  inscricaoEstadual,
  cnpjLoja,
}: StoreDetailsProps) => (
  <Flex bg="gray.100" border="1px solid #bbb" px="4px" minH="55px" w="full">
    <Flex
      fontWeight="extrabold"
      gap="4px"
      flexDir="column"
      minW="75%"
      w="75%"
      py="2px"
      justify="center"
      align="center"
    >
      <Text fontSize="12px">{nomeFantasia?.toUpperCase()}</Text>
      <Text fontSize="7px" textAlign="center">
        {enderecoLoja?.toUpperCase()}
      </Text>
      <Text fontSize="7px">
        {telefone ? `FONE: ${telefone?.toUpperCase()}` : ''}
      </Text>
    </Flex>
    <Flex
      fontWeight="extrabold"
      flexDir="column"
      borderLeft="1px solid #bbb"
      w="25%"
    >
      <Flex
        borderBottom="1px solid #bbb"
        flexDir="column"
        w="full"
        height="50%"
      >
        <Text pl="4px" pt="4px" fontSize="6px">
          CNPJ:
        </Text>
        <Text fontSize="7px" pl="4px">
          {cnpjLoja?.toUpperCase()}
        </Text>
      </Flex>
      <Flex padding="4px" flexDir="column" height="50%">
        <Text fontSize="6px">INSCRIÇÃO ESTADUAL:</Text>
        <Text fontSize="7px">
          {inscricaoEstadual ? inscricaoEstadual?.toUpperCase() : '-----'}
        </Text>
      </Flex>
    </Flex>
  </Flex>
);
