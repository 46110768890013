import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
  MutableRefObject,
} from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteSignalRGroups from 'constants/signalRGroups';
import { useSignalRContext } from 'store/SignalR';
import auth from 'modules/auth';
import ConstanteRotasPDV from 'constants/rotasPDV';
import TipoContaFinanceiraEnum from 'constants/enum/tipoContaFinanceira';
import {
  ObterLocalDeEstoquePadrao,
  Estoque,
} from 'helpers/api/Operacao/obterLocalDeEstoque';

type ContaFinanceiraCofreCaixaViewModel = {
  id: string;
  nome: string;
  tipoContaFinanceira: number;
  caixaMovimentacao: ContaFinanceiraCofreCaixaMovimentacaoViewModel;
  serialPOS: string;
};

type ContaFinanceiraCofreCaixaMovimentacaoViewModel = {
  id: string;
  usuarioAberturaId: string;
  usuarioAberturaNome: string;
};

type OperacaoAlteradaProps = {
  usuario: string;
  operacaoId: string;
};

type TabelaPreco = {
  id: string;
  name: string;
};

type Trocas = {
  valorTotalTroca: number;
  idOperacao: string;
};

type Vendedor = {
  id: string;
  nome: string;
};

interface InformacoesGeraisContextProps {
  contaFinanceira?: ContaFinanceiraCofreCaixaViewModel;
  contaFinanceiraIsLoading: boolean;
  pedidosPendentesCount: number;
  carregarInformacoes: () => void;
  abrirContaCofre: () => Promise<boolean>;
  possuiCadastroOutrosCaixas: boolean;
  handleSetInformacoesLocalEstoque(stock: Estoque): void;
  localDeEstoqueSelecionado: Estoque;
  localEstoquePadraoDoUsuario: MutableRefObject<Estoque>;
  locaisDeEstoque: Estoque[];
  operacaoItemlength: number;
  onChangeOperacaoItem(operacaoItemlength: number): void;
  ultimoEstoqueSelecionado: MutableRefObject<Estoque>;
  obterLocalEstoquePedidoAberto(localEstoqueId: string): void;
  setInformacoesTabelaPreco: React.Dispatch<
    React.SetStateAction<TabelaPreco | undefined>
  >;
  informacoesTabelaPreco: TabelaPreco | undefined;
  setDetalhesTroca: React.Dispatch<React.SetStateAction<Trocas>>;
  detalhesTroca: Trocas;
  setVendedorPdv: React.Dispatch<React.SetStateAction<Vendedor>>;
  vendedorPdv: Vendedor;
  mensagemSignalR: boolean;
  setInfoCaixa: React.Dispatch<
    React.SetStateAction<ContaFinanceiraCofreCaixaViewModel | undefined>
  >;
  infoCaixa: ContaFinanceiraCofreCaixaViewModel | undefined;
  tabelaPrecoId: string;
  setTabelaPrecoId: React.Dispatch<React.SetStateAction<string>>;
}

const InformacoesGeraisContext = createContext<InformacoesGeraisContextProps>(
  {} as InformacoesGeraisContextProps
);

interface InformacoesGeraisProviderProps {
  children: React.ReactNode;
}

export default function InformacoesGeraisProvider({
  children,
}: InformacoesGeraisProviderProps): JSX.Element {
  const { hubConnection, joinGroup } = useSignalRContext();
  const history = useHistory();

  const [
    contaFinanceira,
    setContaFinanceira,
  ] = useState<ContaFinanceiraCofreCaixaViewModel>();
  const [
    infoCaixa,
    setInfoCaixa,
  ] = useState<ContaFinanceiraCofreCaixaViewModel>();
  const [
    possuiCadastroOutrosCaixas,
    setPossuiCadastroOutrosCaixas,
  ] = useState<boolean>(false);
  const [mensagemSignalR, setMensagemSignalR] = useState<boolean>(false);
  const [
    mensagemSignalRAlterouOperacao,
    setMensagemSignalRAlterouOperacao,
  ] = useState<boolean>(false);
  // controle de estado da opção local de estoque do menu no pdv.
  const [locaisDeEstoque, setLocaisDeEstoque] = useState<Estoque[]>([]);
  // controle de estado da opção local de estoque do menu no pdv.
  const [localDeEstoqueSelecionado, setLocalDeEstoqueSelecionado] = useState(
    {} as Estoque
  );
  // utilizo esta variável para verificar o local de estoque padrao de cada usuário.
  const localEstoquePadraoDoUsuario = useRef<Estoque>({} as Estoque);
  // utilizo esta variável para verificar se foi alterado o valor do ultimo estoque e assim alterar a operacao para gerar um log.
  const ultimoEstoqueSelecionado = useRef<Estoque>({} as Estoque);

  const [operacaoItemlength, setOperacaoItemLength] = useState(0);
  const [idOperacaoAlterada, setIdOperacaoAlterada] = useState('');

  const [contaFinanceiraIsLoading, setContaFinanceiraIsLoading] = useState(
    true
  );
  const [
    informacoesTabelaPreco,
    setInformacoesTabelaPreco,
  ] = useState<TabelaPreco>();
  const [pedidosPendentesCount, setPedidosPendentesCount] = useState(0);
  const [detalhesTroca, setDetalhesTroca] = useState<Trocas>({} as Trocas);
  const [vendedorPdv, setVendedorPdv] = useState<Vendedor>({} as Vendedor);
  const [tabelaPrecoId, setTabelaPrecoId] = useState('');

  useEffect(() => {
    const lojaId = auth.getLoja()?.id || '';

    if (lojaId) {
      const groupName = ConstanteSignalRGroups.PDV_PEDIDOSABERTOS.replace(
        '{0}',
        lojaId
      );

      joinGroup(groupName);

      hubConnection.on('notificacao-pedidos-dia', (count) => {
        setPedidosPendentesCount(count);
      });
    }
  }, [hubConnection, joinGroup]);

  const location = window.location.pathname;

  const nameCurrentOperationPdv = location.includes('pdv');

  useEffect(() => {
    const mensagemSignalRFecharCaixa = () => {
      const { id: usuarioId } = auth.getUsuario();
      joinGroup(`${usuarioId}_caixa-fechado`);
      hubConnection.on('caixa-fechado', () => {
        setMensagemSignalR(true);
      });
    };

    mensagemSignalRFecharCaixa();
  }, [history, hubConnection, joinGroup]);

  useEffect(() => {
    if (mensagemSignalR === true) {
      if (location === ConstanteRotasPDV.PDV_TROCAR_PRODUTOS) {
        history.push(ConstanteRotasPDV.PDV_ABRIR_PDV);
        window.location.reload();
      }
      if (location === ConstanteRotasPDV.PDV_PRODUTOS_CONSIGNADOS) {
        history.push(ConstanteRotasPDV.PDV_ABRIR_PDV);
        window.location.reload();
      } else if (nameCurrentOperationPdv) {
        history.push(ConstanteRotasPDV.PDV_ABRIR_PDV);
      }
    }
  }, [history, location, nameCurrentOperationPdv, mensagemSignalR]);

  const { nome: nomeUsuario } = auth.getUsuario();

  useEffect(() => {
    const idOperacao = location.split('/')[location.split('/').length - 1];

    const mensagemSignalRCancelarOperacao = () => {
      joinGroup(`pdv-operacao`);
      hubConnection.on('acao-operacao', (operacao: OperacaoAlteradaProps) => {
        if (
          nomeUsuario !== operacao.usuario &&
          idOperacao === operacao.operacaoId
        ) {
          setIdOperacaoAlterada(operacao.operacaoId);
          setMensagemSignalRAlterouOperacao(true);
        }
      });
    };

    mensagemSignalRCancelarOperacao();
  }, [history, hubConnection, joinGroup, location, nomeUsuario]);

  useEffect(() => {
    const idOperacao = location.split('/')[location.split('/').length - 1];
    if (
      mensagemSignalRAlterouOperacao === true &&
      idOperacaoAlterada === idOperacao
    ) {
      history.push(ConstanteRotasPDV.PDV_HOME);
      window.location.reload();
    }
  }, [
    history,
    location,
    nameCurrentOperationPdv,
    mensagemSignalRAlterouOperacao,
    idOperacaoAlterada,
  ]);

  const consultarContasApi = async () => {
    setContaFinanceiraIsLoading(true);
    const response = await api.get<
      void,
      ResponseApi<ContaFinanceiraCofreCaixaViewModel[]>
    >(
      ConstanteEnderecoWebservice.CONTA_FINANCEIRA_CONSULTAR_CONTAS_CAIXA_COFRE_POR_LOJA
    );
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
        setContaFinanceiraIsLoading(false);
        return false;
      }
      if (response.sucesso && response.dados) {
        setContaFinanceiraIsLoading(false);

        return response;
      }
    }
    setContaFinanceiraIsLoading(false);

    return false;
  };

  const abrirContaCofre = async (): Promise<boolean> => {
    const contas = await consultarContasApi();

    const contaCofre = ((contas && contas?.dados) || []).filter(
      (contaFinanceiraObj: ContaFinanceiraCofreCaixaViewModel) =>
        contaFinanceiraObj.tipoContaFinanceira ===
        TipoContaFinanceiraEnum.CONTA_COFRE
    );

    const { id: usuarioId } = auth.getUsuario();

    const caixaAbertoUsuario = ((contas && contas?.dados) || []).filter(
      (contaFinanceiraObj: ContaFinanceiraCofreCaixaViewModel) =>
        contaFinanceiraObj.caixaMovimentacao?.usuarioAberturaId === usuarioId
    );

    if (caixaAbertoUsuario.length > 0) {
      toast.warning('Já existe um outro caixa aberto com o seu usuário.');
      return false;
    }

    setContaFinanceira(contaCofre[0]);
    return true;
  };

  const carregarInformacoes = useCallback(() => {
    const handleShowWaningToast = (description: string) => {
      toast.warning(description);
    };

    const handleGetCaixaAberto = async () => {
      if (!contaFinanceira) {
        setContaFinanceiraIsLoading(true);

        const response = await consultarContasApi();

        if (response) {
          if (response.avisos) {
            response.avisos.map((item: string) => handleShowWaningToast(item));
          }

          setContaFinanceiraIsLoading(false);
          if (response.sucesso && response.dados) {
            if (response.dados.length === 1) {
              setPossuiCadastroOutrosCaixas(false);
              setContaFinanceira(response.dados[0]);

              return;
            }

            setPossuiCadastroOutrosCaixas(true);

            const { id: usuarioId } = auth.getUsuario();

            const caixaAbertoUsuario = response.dados.filter(
              (contaFinanceiraObj: ContaFinanceiraCofreCaixaViewModel) =>
                contaFinanceiraObj.caixaMovimentacao?.usuarioAberturaId ===
                usuarioId
            );

            if (caixaAbertoUsuario.length > 0) {
              setContaFinanceira(caixaAbertoUsuario[0]);
              return;
            }

            history.push(ConstanteRotasPDV.PDV_ABRIR_PDV);
            return;
          }
        }
        history.push(ConstanteRotasPDV.PDV_ABRIR_PDV);

        setContaFinanceiraIsLoading(false);
      }
    };

    const handleGetPedidosPendentesDia = async () => {
      const response = await api.get<void, ResponseApi<number>>(
        ConstanteEnderecoWebservice.PEDIDOORCAMENTOVENDA_OBTER_QUANTIDADE_PEDIDOS_DIA
      );

      if (response) {
        if (response.avisos) {
          response.avisos.map((item: string) => handleShowWaningToast(item));
        }

        if (response.sucesso && response.dados) {
          return setPedidosPendentesCount(response.dados);
        }
      }

      return [];
    };

    handleGetCaixaAberto();
    handleGetPedidosPendentesDia();
  }, [contaFinanceira, history]);

  useEffect(() => {
    async function ObterEstoque() {
      const {
        estoques,
        estoquePadraoDoUsuario,
      } = await ObterLocalDeEstoquePadrao();
      setLocaisDeEstoque(estoques);
      setLocalDeEstoqueSelecionado(estoquePadraoDoUsuario || ({} as Estoque));
      localEstoquePadraoDoUsuario.current =
        estoquePadraoDoUsuario || ({} as Estoque);
      ultimoEstoqueSelecionado.current =
        estoquePadraoDoUsuario || ({} as Estoque);
    }
    ObterEstoque();
  }, []);

  const handleSetInformacoesLocalEstoque = useCallback((stock: Estoque) => {
    setLocalDeEstoqueSelecionado((lastStock) => {
      ultimoEstoqueSelecionado.current = lastStock;

      return stock;
    });
  }, []);

  const onChangeOperacaoItem = useCallback((operacaoItemLength: number) => {
    setOperacaoItemLength(operacaoItemLength);
  }, []);

  const obterLocalEstoquePedidoAberto = useCallback(
    (localEstoqueId) => {
      const findLocalEstoque = locaisDeEstoque.find(
        (LocalEstoque) => localEstoqueId === LocalEstoque.id
      );

      if (findLocalEstoque) {
        setLocalDeEstoqueSelecionado(findLocalEstoque);
      }
    },
    [locaisDeEstoque]
  );
  return (
    <InformacoesGeraisContext.Provider
      value={{
        contaFinanceira,
        setDetalhesTroca,
        setVendedorPdv,
        vendedorPdv,
        detalhesTroca,
        mensagemSignalR,
        informacoesTabelaPreco,
        setInformacoesTabelaPreco,
        contaFinanceiraIsLoading,
        pedidosPendentesCount,
        carregarInformacoes,
        abrirContaCofre,
        possuiCadastroOutrosCaixas,
        infoCaixa,
        handleSetInformacoesLocalEstoque,
        localDeEstoqueSelecionado,
        locaisDeEstoque,
        localEstoquePadraoDoUsuario,
        onChangeOperacaoItem,
        setInfoCaixa,
        operacaoItemlength,
        ultimoEstoqueSelecionado,
        obterLocalEstoquePedidoAberto,
        tabelaPrecoId,
        setTabelaPrecoId,
      }}
    >
      {children}
    </InformacoesGeraisContext.Provider>
  );
}

export function useInformacoesGeraisContext(): InformacoesGeraisContextProps {
  const context = useContext(InformacoesGeraisContext);

  if (!context)
    throw new Error(
      'useInformacoesGeraisContext must be used within a InformacoesGeraisProvider.'
    );

  return context;
}
