import styled from 'styled-components';
import { Modal as BootstrapModal } from 'react-bootstrap';

interface ModalProps {
  maxWidth?: string | number;
}

export const Modal = styled(BootstrapModal)<ModalProps>`
  .modal-dialog {
    ${({ maxWidth }) => `max-width: ${maxWidth || '600px'} !important;`}
    ${({ minWidth }) => `min-width: ${minWidth || 'unset'} !important;`}

    .modal-content {
      background: var(--sti-ck-colors-gray-50);
      /* max-height: calc(100vh - 50px); */
    }

    .modal-header,
    .modal-footer {
      margin: 0 20px;

      @media (min-width: 900px) {
        margin: 0 25px;
      }
      @media (min-width: 1200px) {
        margin: 0 30px;
      }
      @media (min-width: 1800px) {
        margin: 0 35px;
      }

      padding: 16px 0;

      min-height: 80px;
    }

    .modal-body {
      padding: 20px;

      @media (min-width: 900px) {
        padding: 25px;
      }
      @media (min-width: 1200px) {
        padding: 30px;
      }
      @media (min-width: 1800px) {
        padding: 35px;
      }

      /* overflow: auto;
      ::-webkit-scrollbar {
        display: none;
      }
      scrollbar-width: none; */
    }
  }
`;
