import { useFormContext } from 'react-hook-form';

import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';

import { Produto } from 'pages/Promocao/Formulario/validationForms';

import { useProdutos } from '../hooks';

export const useProdutoItem = () => {
  const { casasDecimais } = usePadronizacaoContext();
  const { handleEditarProduto, handleExcluirProduto } = useProdutos(false);

  const { watch } = useFormContext();

  const listProdutos = watch('produtos') as Produto[];

  return {
    listProdutos,
    casasDecimais,
    handleEditarProduto,
    handleExcluirProduto,
  };
};
