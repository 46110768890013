import React from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  ModalBody,
  ModalContent,
  ModalProps,
  Text,
} from '@chakra-ui/react';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';

interface ModalCodigoBarrasProps extends Omit<ModalProps, 'children'> {
  onCodigoBarrasScanned: (codigoBarras: string) => Promise<void>;
}

export function ModalCodigoBarras({
  isOpen,
  onClose,
  onCodigoBarrasScanned,
  ...rest
}: ModalCodigoBarrasProps) {
  if (!isOpen) {
    return null;
  }

  return (
    <ModalPadraoChakra
      motionPreset="none"
      size="full"
      {...rest}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalContent
        margin="0"
        borderRadius="0"
        overflowX="hidden"
        overflowY="auto"
      >
        <ModalBody p="6" py="8">
          <Text fontSize="md" fontWeight="semibold" lineHeight="shorter" mb="4">
            Posicione a linha vermelha sobre o código de barras até a
            confirmação:
          </Text>

          <Box
            borderRadius="md"
            overflow="hidden"
            position="relative"
            minH="100px"
          >
            <Box filter="brightness(0.6)">
              <BarcodeScannerComponent
                width={500}
                height={500}
                delay={1500}
                onUpdate={async (_, result) => {
                  if (result) {
                    await onCodigoBarrasScanned(result.getText());
                  }
                }}
              />
            </Box>

            <Divider
              borderColor="red.500"
              position="absolute"
              w="full"
              top="50%"
              mt="-0.5px"
            />
          </Box>

          <Flex justifyContent="center" mt="6">
            <Button colorScheme="purple" size="lg" onClick={onClose}>
              Fechar câmera
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </ModalPadraoChakra>
  );
}
