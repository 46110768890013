import React from 'react';
import { Grid } from '@chakra-ui/react';

import Input from 'components/PDV/Input';
import CheckboxAtivoInativo from 'components/update/Checkbox/CheckboxAtivoInativo';

export const NameAndStatus = ({ isReadOnly }: { isReadOnly: boolean }) => {
  return (
    <Grid
      gap={{ base: 3, sm: 6, md: 8 }}
      templateColumns={{ base: '1fr', sm: '1fr auto' }}
    >
      <Input
        id="nome"
        name="nome"
        placeholder="Digite o nome da tabela de preços"
        label="Descrição"
        maxLength={20}
        isRequired
        autoFocus
        isDisabled={isReadOnly}
      />

      <CheckboxAtivoInativo
        id="ativo"
        name="ativo"
        label="Status"
        isRequired
        isDisabled={isReadOnly}
      />
    </Grid>
  );
};
