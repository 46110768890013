import { ListProps, List, ListItem } from '@chakra-ui/react';
import React, { ReactNode, useCallback, useState, useEffect } from 'react';

type ItemProps<T> = T & {
  notDrag?: boolean;
  sequenciaDrag?: number;
};

interface DragDropProps<T> extends ListProps {
  items: ItemProps<T>[];
  children: (item: ItemProps<T>, index: number) => ReactNode;
  heightItem?: string;
  dragEnd?: (item: ItemProps<T>[], itemDraggin: ItemProps<T>) => void;
}

const listItemStyle = {
  transition: 'transform 0.2s ease-in-out',
};

export function DragDrop<T>({
  items,
  children,
  heightItem = '160px',
  dragEnd,
  ...rest
}: DragDropProps<T>) {
  const [listItems, setListItems] = useState<ItemProps<T>[]>([]);
  const [draggedItemIndex, setDraggedItemIndex] = useState<number | null>(null);

  const handleDragStart = (index: number) => {
    setDraggedItemIndex(index);
  };

  const handleDragOver = useCallback(
    (index: number) => {
      if (draggedItemIndex === null) {
        return;
      }

      const newItems = [...listItems];
      const [draggedItem] = newItems.splice(draggedItemIndex, 1);
      newItems.splice(index, 0, draggedItem);
      setListItems(
        newItems.map((itemDrag, indexItems) => ({
          ...itemDrag,
          sequenciaDrag: indexItems + 1,
        }))
      );
      setDraggedItemIndex(index);
    },
    [draggedItemIndex, listItems]
  );

  const handleDragEnd = () => {
    if (dragEnd) {
      const newListDrag = [...listItems];
      newListDrag.pop();
      dragEnd(newListDrag, newListDrag[draggedItemIndex || 0]);
    }
    setDraggedItemIndex(null);
  };

  useEffect(() => {
    if (items) {
      setListItems(items);
    }
  }, [items]);

  return (
    <List {...rest}>
      {listItems.map((item, index) => {
        return (
          <ListItem
            key={String(index)}
            borderRadius={4}
            h={heightItem}
            cursor={item.notDrag ? 'default' : 'move'}
            draggable={!item.notDrag}
            style={
              draggedItemIndex === index
                ? { ...listItemStyle, transform: 'scale(1.05)' }
                : listItemStyle
            }
            onDragStart={
              !item.notDrag ? () => handleDragStart(index) : undefined
            }
            onDragOver={!item.notDrag ? () => handleDragOver(index) : undefined}
            onDragEnd={!item.notDrag ? handleDragEnd : undefined}
            opacity={draggedItemIndex === index ? 0.5 : 1}
          >
            {children(item, index)}
          </ListItem>
        );
      })}
    </List>
  );
}
