import { IconSmartPosApp } from 'icons';

import { Container } from '../../Components/Layout/Container';
import { useSmartPosData } from './hooks';

export const DetalhesIntegracaoSmartPos = () => {
  const {
    smartPosDateActivated,
    listItemsInfoActive,
    listItemsInfoNotActive,
    handleCancel,
    handleActive,
    isActive,
    isLoading,
  } = useSmartPosData();

  return (
    <Container
      listItemsInfo={isActive ? listItemsInfoActive : listItemsInfoNotActive}
      isLoading={isLoading}
      widthIcon={150}
      heightIcon={150}
      handleCancel={handleCancel}
      icon={IconSmartPosApp}
      isIntegrationCancel={isActive}
      handleActive={handleActive}
      date={smartPosDateActivated}
    />
  );
};
