const RegraLimiteCreditoEnum = {
  NAO_VALIDAR: 1,
  BLOQUEIO_CREDIARIO: 2,
  BLOQUEIO_DIAS_ATRASO: 3,
  BLOQUEIO_TOTAL: 4,

  properties: [
    {
      name: 'Não bloquear',
      value: 1,
      iconColor: 'var(--sti-ck-colors-blue-400)',
      descricao:
        'Não será verificado se estes clientes têm contas à pagar pendentes.',
      padrao: false,
    },
    {
      name: 'Bloqueio de crediário',
      value: 2,
      iconColor: 'var(--sti-ck-colors-orange-400)',
      descricao:
        'Não permite vendas no crediário para clientes dessa categoria.',
      padrao: false,
    },
    {
      name: 'Bloqueio com atraso de dias',
      value: 3,
      iconColor: 'var(--sti-ck-colors-secondary-700)',
      descricao:
        'Cliente somente será impedido de realizar uma nova compra se as contas em atraso forem superiores à quantidade de dias definida na tela anterior',
      padrao: true,
    },
    {
      name: 'Bloqueio total',
      value: 4,
      iconColor: 'var(--sti-ck-colors-red-400)',
      descricao: 'Não será possível efetuar uma venda para estes clientes.',
      padrao: false,
    },
  ],
};

export default RegraLimiteCreditoEnum;
