import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export type RelatorioProperties = {
  label: string;
  value: number;
  isChecked: boolean;
};

export interface RelatorioProps {
  CODIGO?: number;
  NOME?: number;
  CPFCNPJ?: number;
  APELIDO?: number;
  CELULAR?: number;
  TELEFONE?: number;
  GENERO?: number;
  DATANASCIMENTO?: number;
  RGINSCRICAOESTADUAL?: number;
  ATIVO?: number;
  EMAIL?: number;
  EMAILNFE?: number;
  CATEGORIA?: number;
  SITEPERFIL?: number;
  CEP?: number;
  BAIRRO?: number;
  CIDADEUF?: number;
  ENDERECO?: number;
  OBSERVACAO?: number;
  properties: RelatorioProperties[];
}

export type FormData = {
  nomeRelatorio: string;
  FOTO: RelatorioProperties;
  DESCRICAO: RelatorioProperties;
  DESCRICAO_ABREVIADA: RelatorioProperties;
  GTIN_EAN: RelatorioProperties;
  REFERENCIA: RelatorioProperties;
  TIPO: RelatorioProperties;
  CATEGORIA: RelatorioProperties;
  MARCA: RelatorioProperties;
  ESTOQUE_MINIMO: RelatorioProperties;
  PRECO_COMPRA: RelatorioProperties;
  PRECO_CUSTO: RelatorioProperties;
  MARKUP: RelatorioProperties;
  PRECO_VENDA: RelatorioProperties;
  NCM: RelatorioProperties;
  CEST: RelatorioProperties;
  UNIDADE_MEDIDA: RelatorioProperties;
  TAGS: RelatorioProperties;
  camposOrdenacao: number[];
};

const schema = yup.object().shape({
  nomeRelatorio: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);
