import { setDateMinHours, setDateMaxHours } from 'helpers/data/setHoursDate';

export type ClienteId = {
  label: string | null;
  value: string | null;
};

export type ListTransacoes = {
  id: string;
  tipoTransacao: number;
  operacao: number;
  codigo: number;
  nomeCliente: string;
  tipoPagamento: number;
  status: number | number[];
  valor: number;
  parcelas: number;
};

export interface LojasResponse {
  id: string;
  razaoSocial: string;
  fantasia: string;
  cnpj: string;
}

export type FormData = {
  status: string | null;
  tipoPagamento: number | null;
  dataCriacao: Date;
  dataCriacaoInicio: Date;
  dataCriacaoFim: Date;
  clienteFornecedorId: ClienteId | null;
  numeroOperacao?: number | null;
};

interface ContatoAdicional {
  nome: string;
  telefone: string;
}
export interface Cliente {
  celular?: string;
  nome: string;
  telefone?: string;
  contatosAdicionais?: ContatoAdicional[];
}
export interface TransacoesResponse {
  id: string;
  identificadorOperacao: string;
  tipoTransacao: string;
  dataCriacao: Date;
  cliente: string;
  status: string;
  valorTotal: number;
  observacao: string;
  linkQrCode: string;
  parcelas: Parcela[];
  zoopTransacaoId: string;
}

export interface Parcela {
  id: string;
  dataVencimento: Date;
  dataPagamento?: Date;
  dataCompensacao: Date;
  valor: number;
  numeroParcela: number;
  dataCancelamento?: Date;
  numeroOperacao: number;
}

export const formDefaultValues = {
  status: null,
  tipoPagamento: null,
  dataCriacao: new Date(),
  dataCriacaoInicio: setDateMinHours(new Date()),
  dataCriacaoFim: setDateMaxHours(new Date()),
  clienteFornecedorId: null,
  numeroOperacao: null,
};
