import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

export type FormData = {
  valorTotalItensSemDesconto: number;
  valorTotalIcmsSt: number;
  valorTotalFcpSt: number;
  valorTotalIpi: number;
  ratearIcmsSt: boolean;
  valorTotalFrete: number;
  valorTotalOutrasDespesas: number;
  valorTotalDescontoAdicional: number;
  valorTotalCustoAdicional: number;
  valorTotal: number;
};

const schema = yup.object().shape({
  valorTotalFrete: yup.number().nullable(),
  valorTotalOutrasDespesas: yup.number().nullable(),
  valorTotalDescontoAdicional: yup.number().nullable(),
});

export const yupResolver = yupResolverInstance(schema);
