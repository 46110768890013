import React from 'react';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

import FormaRecebimentoListar from '../pages/FormaRecebimento/Listar';
import FormaRecebimentoCadastrar from '../pages/FormaRecebimento/Formulario/Cadastrar';
import FormaRecebimentoAlterar from '../pages/FormaRecebimento/Formulario/Alterar';
import FormaRecebimentoVisualizar from '../pages/FormaRecebimento/Formulario/Visualizar';

const FormaRecebimentoRoutes = [
  <LayoutGuard
    key="forma-receb-listar"
    permissaoFuncionalidade={ConstanteFuncionalidades.FORMA_RECEB_LISTAR}
    component={FormaRecebimentoListar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Formas de Recebimento',
      },
    ]}
    path={ConstanteRotas.FORMA_RECEB}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="forma-receb-cadastrar"
    permissaoFuncionalidade={ConstanteFuncionalidades.FORMA_RECEB_CADASTRAR}
    component={FormaRecebimentoCadastrar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Formas de Recebimento',
        path: ConstanteRotas.FORMA_RECEB,
      },
      {
        title: 'Novo',
      },
    ]}
    path={ConstanteRotas.FORMA_RECEB_CADASTRAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="forma-receb-alterar"
    permissaoFuncionalidade={ConstanteFuncionalidades.FORMA_RECEB_ALTERAR}
    component={FormaRecebimentoAlterar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Formas de Recebimento',
        path: ConstanteRotas.FORMA_RECEB,
      },
      {
        title: 'Editar',
      },
    ]}
    path={ConstanteRotas.FORMA_RECEB_ALTERAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="forma-receb-visualizar"
    permissaoFuncionalidade={ConstanteFuncionalidades.FORMA_RECEB_VISUALIZAR}
    component={FormaRecebimentoVisualizar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Formas de Recebimento',
        path: ConstanteRotas.FORMA_RECEB,
      },
      {
        title: 'Visualizar',
      },
    ]}
    path={ConstanteRotas.FORMA_RECEB_VISUALIZAR}
    exact
    meta={{ auth: true }}
  />,
];

export default FormaRecebimentoRoutes;
