import { useCallback, useMemo, useRef, useState } from 'react';
import {
  ModalBody,
  ModalProps,
  useDisclosure,
  ModalContent,
  Flex,
  Button,
  Text,
  Box,
  Tr,
  Td,
  Checkbox,
  useBreakpointValue,
  ModalCloseButton,
  Icon,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { RiArrowLeftSLine } from 'react-icons/ri';

import api, { ResponseApi } from 'services/api';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import { formatOptionsSelectClient } from 'helpers/format/formatSelectClient';
import { DecimalMask } from 'helpers/format/fieldsMasks';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { StatusPesquisaClientesFornecedor } from 'constants/enum/statusPesquisaClientesFornecedor';

import {
  VirtualizedInfiniteTable,
  TableHeader,
  LoadMoreRowsParams,
  ForwardRefData,
} from 'components/update/Table/VirtualizedInfiniteTable';
import InputDateRange from 'components/PDV/InputDateRange';
import AsyncSelect from 'components/PDV/Select/AsyncSelectPadrao';
import Loading from 'components/Layout/Loading/LoadingPadrao';

import { setDateMaxHours } from 'helpers/data/setHoursDate';
import ModalPadraoChakra from '../ModalPadraoChakra';

type ValesProps = {
  valeId: string;
  saldoVale: number;
  sequenciaOrdenacao: number;
};

type PromiseModal = {
  sucesso: boolean;
  voucherSelecionados: ValesProps[];
};

interface ModalInserirVoucherProps
  extends Omit<ModalProps, 'children' | 'isOpen' | 'onClose'>,
    InstanceProps<PromiseModal> {
  currentClient: {
    name?: string;
    id?: string;
  };
  voucherUnico?: boolean;
}

type VoucherProps = {
  valeId: string;
  saldo: number;
  cliente: string;
  isChecked?: boolean;
};

type ClienteProps = {
  id: string;
  nome: string;
};

type Options = {
  label: string;
  value: string;
};

type FormData = {
  cliente: Options;
  dataPesquisa: Date;
  dataPesquisaInicio: Date;
  dataPesquisaFim: Date;
};

type VoucherPropsPaginado = {
  totalProdutos: number;
  total: number;
  registros: VoucherProps[];
};

function Modal({
  currentClient,
  voucherUnico = false,
  onReject,
  onResolve,
  ...props
}: ModalInserirVoucherProps) {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const isResponsiveVersion = useBreakpointValue({
    base: true,
    md: false,
    lg: false,
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [voucherItens, setVoucher] = useState<VoucherProps[]>([]);
  const [itemsTotalCount, setItemsTotalCount] = useState<number>(0);
  const [voucherSelecionados, setVoucherSelecionados] = useState<
    VoucherProps[]
  >([]);

  const [stepsForFindVoucher, setStepsForFindVoucher] = useState(0);

  const maxDate = new Date();

  const formMethods = useForm<FormData>({
    defaultValues: {
      dataPesquisaInicio: new Date(
        maxDate.getFullYear(),
        maxDate.getMonth(),
        maxDate.getDate() - 30
      ),
      dataPesquisaFim: maxDate,
      cliente: {
        label: currentClient.name,
        value: currentClient.id,
      },
    },
  });
  const tableRef = useRef<ForwardRefData>(null);
  const {
    cliente,
    dataPesquisaFim,
    dataPesquisaInicio,
  } = formMethods.getValues();

  const produtosTableHeaders: TableHeader[] = [
    {
      key: 'cliente',
      content: 'Cliente',
      width: 'full',
      minWidth: 'full',
      pl: '10',
      whiteSpace: 'nowrap',
    },
    {
      key: 'valor',
      content: 'Valor',
      width: '130px',
      whiteSpace: 'nowrap',
      display: isResponsiveVersion ? 'none' : 'table-cell',
      maxWidth: isResponsiveVersion ? 0 : 'none',
    },
  ];

  const loadMoreRows = useCallback(
    async ({
      currentPage,
      pageSize,
      orderColumn,
      orderDirection,
    }: LoadMoreRowsParams) => {
      setIsLoading(true);

      const paginationData = {
        currentPage,
        pageSize,
        orderColumn,
        orderDirection,
      };

      const response = await api.get<void, ResponseApi<VoucherPropsPaginado>>(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.LISTAR_PAGINADO_CLIENTE_VOUCHER,
          paginationData
        ),
        {
          params: {
            clienteId: cliente?.value,
            dataEmissaoInicio: dataPesquisaInicio.toISOString(),
            dataEmissaoFim: setDateMaxHours(
              new Date(dataPesquisaFim)
            ).toISOString(),
          },
        }
      );

      if (response) {
        if (response.sucesso) {
          setVoucher((prev) => [
            ...prev,
            ...(response.dados.registros.map((registers) => ({
              ...registers,
              isChecked: false,
            })) || []),
          ]);

          setItemsTotalCount(response.dados.total);
        }
      }

      setIsLoading(false);
    },
    [cliente, dataPesquisaFim, dataPesquisaInicio]
  );

  const handleGetCliente = async (newInputValue: string) => {
    const response = await api.get<void, ResponseApi<ClienteProps[]>>(
      ConstanteEnderecoWebservice.CLIENTE_FORNECEDOR_LISTAR_SELECT,
      {
        params: {
          cpfCnpjNomeApelidoCodigoExterno: newInputValue,
          filtroTipoCadastroPessoa: StatusPesquisaClientesFornecedor.CLIENTES,
        },
      }
    );

    if (response?.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (response?.sucesso) {
      return response?.dados.map((clienteProps: ClienteProps) => ({
        ...formatOptionsSelectClient(clienteProps),
      }));
    }

    return [];
  };

  function handleToggleSelecionarVales(index: number) {
    const voucher = voucherItens[index];
    const listVouchers = voucherItens;

    listVouchers.splice(index, 1, {
      ...voucher,
      isChecked: !voucher.isChecked,
    });

    const voucherSelecionado = listVouchers[index];

    if (voucherUnico) {
      setVoucherSelecionados([voucherSelecionado]);
      setVoucher((prev) => {
        const newListVoucher = prev.map((item) => ({
          ...item,
          isChecked: item.valeId === voucherSelecionado.valeId,
        }));

        return newListVoucher;
      });
      return;
    }

    if (voucherSelecionado.isChecked) {
      setVoucherSelecionados((items) => [...items, voucherSelecionado]);
    } else {
      const listVouchersSelecionados = voucherSelecionados.filter(
        (items) => items.valeId !== voucherSelecionado.valeId
      );
      setVoucherSelecionados(listVouchersSelecionados);
    }
  }

  async function handleOnSubimitVouchersSelecionados() {
    const valesSelecionadosEOrdenados = voucherSelecionados.map(
      (itemVoucher, index) => ({
        valeId: itemVoucher.valeId,
        saldoVale: itemVoucher.saldo,
        sequenciaOrdenacao: index + 1,
      })
    );

    onResolve({
      sucesso: true,
      voucherSelecionados: valesSelecionadosEOrdenados,
    });
  }

  const handlePesquisarVales = useCallback(async () => {
    await setVoucher([]);
    setIsLoading(true);
    setStepsForFindVoucher(1);
    tableRef.current?.reload();
  }, []);

  function onReturnInitialFormVoucher() {
    setStepsForFindVoucher(0);
  }

  const AsideComponent = useMemo(
    () => (
      <Box
        as="aside"
        px="6"
        bg="gray.50"
        py="10"
        borderTopLeftRadius="md"
        borderBottomLeftRadius="md"
        h={{ base: '100%', md: 'auto' }}
      >
        <Flex flexFlow="column nowrap" gap="4">
          <Text
            mb={{ base: '3.37rem', md: '4' }}
            color="black"
            fontWeight="semibold"
            fontSize="md"
            letterSpacing="tight"
          >
            Encontre e selecione um voucher disponível:
          </Text>
          <AsyncSelect
            id="cliente"
            name="cliente"
            placeholder="Selecione um cliente"
            label=""
            required
            handleGetOptions={handleGetCliente}
            asControlledByObject
            withoutDefaultOptions
            shouldAppearTheAddress
          />
          <InputDateRange
            id="dataPesquisa"
            name="dataPesquisa"
            startDateName="dataPesquisaInicio"
            endDateName="dataPesquisaFim"
            mb="4"
            amountMonths={3}
            maxDate={maxDate}
          />
          <Button
            bg="primary.500"
            color="white"
            sx={{
              '&': {
                transition: 'filter 0.2s',
              },
              '&: hover': {
                backgroundColor: 'primary.500',
                filter: 'brightness(0.9)',
              },
            }}
            onClick={handlePesquisarVales}
          >
            Pesquisar
          </Button>
        </Flex>
      </Box>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const messageListOnEmpty = {
    clientEmpty: 'Adicione ao menos um cliente para avançar na conferência.',
    notFoundVoucher: ' Nenhum vale foi encontrado. Por favor tente novamente.',
  };
  const ListOfVoucherPerCustomer = useMemo(
    () => (
      <Box
        pt="3"
        h={isResponsiveVersion ? 'full' : '530px'}
        background={isResponsiveVersion ? 'none' : 'white'}
      >
        <Box p="4">
          <VirtualizedInfiniteTable
            ref={tableRef}
            boxShadow="null"
            bg={isResponsiveVersion ? 'gray.50' : 'white'}
            alterarBordaListagem={isResponsiveVersion ? 'gray.50' : 'white'}
            color="gray.300"
            isEdicaoManual
            variant="outline"
            colorFundo={isResponsiveVersion ? 'gray.50' : 'none'}
            withoutRowsMessage={
              cliente.label && cliente.value
                ? messageListOnEmpty.notFoundVoucher
                : messageListOnEmpty.clientEmpty
            }
            orderColumn="saldo"
            visibleItemsCount={8}
            pageSize={20}
            tableHeaders={produtosTableHeaders}
            rowRenderer={({ index, style: { height, ...restStyle }, key }) => {
              const voucher = voucherItens[index];

              if (!voucher) {
                return null;
              }

              return (
                <Tr key={key} style={restStyle}>
                  <Td
                    width={produtosTableHeaders[0].width}
                    display={isResponsiveVersion ? 'flex' : 'table-cell'}
                    pl="8px !important"
                    pt="1"
                    pb="1"
                    valign="middle"
                  >
                    <Flex
                      flexFlow="row nowrap"
                      gap="4"
                      alignItems="center"
                      position="relative"
                    >
                      <Box alignSelf="flex-start" pt="2px">
                        <Checkbox
                          my="auto"
                          colorScheme="primary"
                          onChange={() => {
                            handleToggleSelecionarVales(index);
                          }}
                          isChecked={voucher.isChecked}
                        />
                      </Box>
                      <Box
                        display="flex"
                        flexFlow={
                          isResponsiveVersion ? 'column wrap' : 'row wrap'
                        }
                        rowGap="0"
                        columnGap="2"
                      >
                        <Text fontSize="14px" lineHeight="base">
                          {voucher.cliente}
                        </Text>
                        <Text fontSize="14px" lineHeight="base">
                          {isResponsiveVersion && (
                            <>
                              R$
                              <Text as="span" ml="1" fontSize="14px">
                                {DecimalMask(voucher.saldo, 2)}
                              </Text>
                            </>
                          )}
                        </Text>
                      </Box>
                    </Flex>
                  </Td>
                  {isResponsiveVersion ? null : (
                    <Td width={produtosTableHeaders[1].width}>
                      <Flex>
                        <Text
                          width={produtosTableHeaders[1].width}
                          fontSize="14px"
                          lineHeight="base"
                          textAlign="right"
                        >
                          R$
                          <Text as="span" fontSize="14px" ml="1">
                            {DecimalMask(voucher.saldo, 2)}
                          </Text>
                        </Text>
                      </Flex>
                    </Td>
                  )}
                </Tr>
              );
            }}
            rowCount={itemsTotalCount}
            isRowLoaded={({ index }) => !!voucherItens[index]}
            loadMoreRows={loadMoreRows}
          />
        </Box>
        <Flex mt="10px" justifyContent="center">
          <Button
            mr="24px"
            onClick={onClose}
            h="32px"
            w="96px"
            variant="outline"
          >
            Cancelar
          </Button>
          <Button
            w="140px"
            h="32px"
            variant="solid"
            bg="secondary.400"
            _hover={{ background: 'secondary.400' }}
            isDisabled={voucherSelecionados.length === 0}
            onClick={handleOnSubimitVouchersSelecionados}
          >
            Confirmar
          </Button>
        </Flex>
        {voucherUnico && (
          <Flex mt="32px" justifyContent="center">
            <Text color="orange.500" fontWeight="bold" textAlign="center">
              Não será possível utilizar mais de um voucher para a mesma baixa.
            </Text>
          </Flex>
        )}
      </Box>
    ),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      itemsTotalCount,
      voucherItens,
      loadMoreRows,
      onClose,
      isResponsiveVersion,
      voucherSelecionados,
    ]
  );

  const stepsFindVoucher = [AsideComponent, ListOfVoucherPerCustomer];

  return (
    <FormProvider {...formMethods}>
      <ModalPadraoChakra
        {...props}
        isCentered={!isResponsiveVersion}
        size="4xl"
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        hasOverlay={!isResponsiveVersion}
      >
        <ModalContent
          margin="0"
          borderRadius={{ base: '0', md: 'md' }}
          overflowX={{ base: 'hidden', md: 'auto' }}
          overflowY={{ base: 'hidden', md: 'auto' }}
          maxWidth={isResponsiveVersion ? 'full' : '960px'}
          maxHeight={isResponsiveVersion ? 'full' : '530px'}
          w="100%"
          h="100%"
          position="relative"
        >
          <ModalBody p="0" position="relative" bg="gray.50">
            {isLoading && <Loading />}
            {isResponsiveVersion && stepsForFindVoucher ? (
              <Flex position="absolute" zIndex={2} top="12px" left="4">
                <Button
                  variant="unstyled"
                  lineHeight="0"
                  p="0"
                  h="auto"
                  onClick={onReturnInitialFormVoucher}
                >
                  <Icon as={RiArrowLeftSLine} h={6} w={6} color="gray.400" />
                </Button>
              </Flex>
            ) : null}
            <ModalCloseButton zIndex={10} color="gray.400" />
            <Box
              display="grid"
              gridTemplateColumns={{ base: '1fr', md: '384px 1fr' }}
            >
              {isResponsiveVersion
                ? stepsFindVoucher[stepsForFindVoucher]
                : [...stepsFindVoucher]}
            </Box>
          </ModalBody>
        </ModalContent>
      </ModalPadraoChakra>
    </FormProvider>
  );
}

export const ModalEncontreUmVoucher = create<
  ModalInserirVoucherProps,
  PromiseModal
>(Modal);
