export type FormData = {
  nome: string;
  ativo: boolean;
};

const defaultValues = {
  nome: '',
  ativo: true,
} as FormData;

export { defaultValues };
