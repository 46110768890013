import { ReactNode } from 'react';
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  Box,
  Flex,
  Text,
} from '@chakra-ui/react';

import {
  ActionMenuItem,
  ActionsMenu,
} from 'components/update/Table/ActionsMenu';

type ItemProps = {
  title: string;
  actionsMenuItems?: ActionMenuItem[];
  children: ReactNode;
};

const Item = ({ title, actionsMenuItems, children }: ItemProps) => {
  return (
    <AccordionItem
      w="full"
      border="none"
      boxShadow="none"
      borderRadius="none"
      mt={2}
    >
      <Flex bg="gray.500" color="white" align="center">
        <AccordionButton
          p={4}
          bg="gray.500"
          fontSize="16px"
          gap={1}
          _hover={{
            bg: 'gray.500',
          }}
        >
          <Box mx={2}>
            <AccordionIcon />
          </Box>
          <Text whiteSpace="nowrap">{title}</Text>
        </AccordionButton>
        {actionsMenuItems && (
          <Box mr={4}>
            <ActionsMenu
              items={actionsMenuItems}
              backgroundHoverColor="gray.600"
              colorScheme="white"
            />
          </Box>
        )}
      </Flex>
      {children}
    </AccordionItem>
  );
};

export default Item;
