import { EnumSmartPos } from 'constants/enum/enumSmartPos';
import { EtapaSelecionadaProps } from 'store/SmartPos/SmartPosContext';

import { CategoriaProduto } from '../Components/CategoriaProduto';
import { Impressao } from '../Components/Impressao';
import { Configuracao } from '../Components/Configuracao';
import { Dispositivos } from '../Components/Dispositivos';
import { FormaRecebimento } from '../Components/FormaRecebimento';

type StepsSmartPosProps = {
  activeStep: EtapaSelecionadaProps;
};

export const StepsSmartPos = ({ activeStep }: StepsSmartPosProps) => {
  switch (activeStep.value) {
    case EnumSmartPos.DISPOSITIVO.value:
      return <Dispositivos />;
    case EnumSmartPos.CATEGORIAS.value:
      return <CategoriaProduto />;
    case EnumSmartPos.FORMA_RECEBIMENTO.value:
      return <FormaRecebimento />;
    case EnumSmartPos.IMPRESSAO.value:
      return <Impressao />;
    case EnumSmartPos.CONFIGURACOES.value:
      return <Configuracao />;

    default:
      return null;
  }
};
