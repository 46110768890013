import React, { useEffect, useState, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useHistory, RouteComponentProps } from 'react-router-dom';

import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';
import RegrasFiscaisFormularioProvider from 'store/RegrasFiscais/RegrasFiscaisFormularios';
import ConstanteRotas from 'constants/rotas';
import { obterRegraFiscal as apiObterRegraFiscal } from 'helpers/api/RegrasFiscais/obterRegraFiscal';

import ManterFoco from 'components/Geral/ManterFoco';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import RodapeFormulario from 'components/Geral/RodapeFormulario';

import { FormRegraFiscalData, mapResponseRegraFiscalData } from '../types';
import Formulario from '..';
import { yupResolver } from '../validationForm';

type TParams = { id: string };

const Visualizar = ({ match }: RouteComponentProps<TParams>) => {
  const history = useHistory();
  const formMethods = useForm<FormRegraFiscalData>({
    resolver: yupResolver,
    defaultValues: {
      nome: '',
      ativo: true,
      operacoes: [
        {
          identificacaoTipoOperacao: IdentificacaoTipoOperacaoEnum.VENDA,
        },
      ],
    },
  });

  const regraFiscalId = match.params.id;

  const [isLoading, setIsLoading] = useState(false);
  const [dataHoraCadastro, setDataHoraCadastro] = useState('');
  const [dataHoraUltimaAlteracao, setDataHoraUltimaAlteracao] = useState('');

  const latestProps = useRef({
    reset: formMethods.reset,
    regraFiscalId,
    history,
  });
  useEffect(() => {
    latestProps.current = { reset: formMethods.reset, regraFiscalId, history };
  });

  useEffect(() => {
    async function obterRegraFiscal() {
      setIsLoading(true);

      const responseData = await apiObterRegraFiscal(
        latestProps.current.regraFiscalId
      );

      if (responseData) {
        setDataHoraCadastro(responseData.dataHoraCadastro || '');
        setDataHoraUltimaAlteracao(responseData.dataHoraUltimaAlteracao || '');

        const mappedData = mapResponseRegraFiscalData(responseData);

        setIsLoading(false);

        latestProps.current.reset(mappedData);
      } else {
        setIsLoading(false);

        latestProps.current.history.push(ConstanteRotas.REGRA_FISCAL);
      }
    }

    obterRegraFiscal();
  }, []);

  return (
    <Box>
      <ManterFoco>
        {isLoading && <LoadingPadrao />}
        <RegrasFiscaisFormularioProvider {...formMethods}>
          <Formulario isReadonly />
        </RegrasFiscaisFormularioProvider>
        <RodapeFormulario
          disabled={isLoading}
          dataHoraCriacao={dataHoraCadastro}
          dataHoraUltimaAlteracao={dataHoraUltimaAlteracao}
        />
      </ManterFoco>
    </Box>
  );
};

export default Visualizar;
