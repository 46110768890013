import React, { useState, useRef, useCallback, useEffect } from 'react';
import { AiOutlineRight } from 'react-icons/ai';
import { toast } from 'react-toastify';
import {
  IconButton,
  Icon,
  VStack,
  InputGroup,
  Input,
  InputRightAddon,
  Skeleton,
  Text,
  Flex,
} from '@chakra-ui/react';

import auth from 'modules/auth';
import { useAtendimentoChatContext } from 'store/AtendimentoChat';
import { pushExternalUrl } from 'helpers/layout/pushExternalUrl';
import TipoSistemaEnum from 'constants/enum/tipoSistema';
import {
  generateAccessToken,
  pushExternalUrlTreinamentos,
} from 'services/treinamentos';

import { AjudaCentral, AjudaChat, AjudaTreinamento, LupaIcon } from 'icons';

import { optionsMenu } from './validationForm';
import { SistemaTipoClienteSTi3 } from './components/SistemaTipoClienteSTi3';
import { ChatMoviedesk } from './components/ChatMoviedesk';
import { ChatRevenda } from './components/ChatRevenda';

const sistema = auth.getSistema();

export const OpcoesAjuda = () => {
  const [selectedOption, setSelectedOption] = useState(
    optionsMenu.MENU_PRINCIPAL
  );

  const isClienteSTi3 =
    sistema.value === TipoSistemaEnum.ZENDAR ||
    sistema.value === TipoSistemaEnum.FOMER ||
    sistema.value === TipoSistemaEnum.FOMER_SISTEMA;

  const inputPesquisaRef = useRef<HTMLInputElement>(null);

  const {
    isMenuOpen,
    onMenuClose,
    setChatAccordionIndex,
  } = useAtendimentoChatContext();

  function handlePushCentralAjudaPesquisa() {
    if (inputPesquisaRef.current) {
      const inputValue = inputPesquisaRef.current.value;

      inputPesquisaRef.current.value = '';

      if (inputValue) {
        const url = `https://treinamentosdesistema.freshdesk.com/support/search?term=${inputValue}`;

        pushExternalUrl(url);
        onMenuClose();
      }
    }
  }

  function handlePushCentralAjuda() {
    pushExternalUrl('https://treinamentosdesistema.freshdesk.com/support/home');
    onMenuClose();
  }

  async function handlePushTreinamentos() {
    const response = await generateAccessToken();

    if (response) {
      if (response?.sucesso && response?.dados) {
        const accessToken = response.dados;

        pushExternalUrlTreinamentos(accessToken);
        onMenuClose();
      }
    } else {
      toast.error('Ocorreu um erro inesperado.', {
        toastId: 'erroInesperadoToast',
      });
    }
  }

  const goToOpcaoMenuPrincipal = useCallback(() => {
    setSelectedOption(optionsMenu.MENU_PRINCIPAL);
  }, []);

  const closeMenuAndReset = useCallback(() => {
    goToOpcaoMenuPrincipal();
    onMenuClose();
  }, [goToOpcaoMenuPrincipal, onMenuClose]);

  useEffect(() => {
    if (isMenuOpen) return;

    closeMenuAndReset();
  }, [isMenuOpen, closeMenuAndReset]);

  return (
    <>
      {selectedOption === optionsMenu.MENU_PRINCIPAL && (
        <VStack alignItems="flex-start" py="12px" gap="24px">
          <Skeleton w="full" isLoaded>
            <Text fontWeight="bold" mb="2px">
              O que podemos ajudar você a encontrar?
            </Text>
            <InputGroup size="sm">
              <Input
                ref={inputPesquisaRef}
                placeholder="Descreva sua dúvida ou palavra chave"
                isDisabled={false}
                _disabled={{
                  bg: 'none',
                  opacity: 0.4,
                  cursor: 'blocked',
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handlePushCentralAjudaPesquisa();
                  }
                }}
              />

              <InputRightAddon
                as={IconButton}
                aria-label="Ajuda"
                icon={
                  <Icon
                    as={LupaIcon}
                    boxSize="4"
                    color="black"
                    borderRadius="5px"
                  />
                }
                px="4"
                colorScheme="secondary"
                bg="secondary.400"
                borderRightRadius="md"
                isDisabled={false}
                onClick={handlePushCentralAjudaPesquisa}
              />
            </InputGroup>
          </Skeleton>
          <VStack alignItems="flex-start" w="full" mt="24px" gap="16px">
            <Skeleton isLoaded w="full">
              <Flex
                gap="12px"
                align="center"
                justify="flex-start"
                onClick={handlePushCentralAjuda}
                cursor="pointer"
                w="full"
              >
                <Flex
                  bg="violet.50"
                  h="48px"
                  minW="48px"
                  borderRadius="full"
                  align="center"
                  justify="center"
                >
                  <Icon as={AjudaCentral} boxSize="6" borderRadius="5px" />
                </Flex>
                <VStack align="flex-start" spacing="2px" w="full">
                  <Text fontWeight="bold" fontSize="md">
                    Central de ajuda
                  </Text>
                  <Flex align="center" justify="space-between" w="full">
                    <Text>Dicas, artigos e tutoriais para casos de uso</Text>
                    <AiOutlineRight />
                  </Flex>
                </VStack>
              </Flex>
            </Skeleton>

            <Flex
              gap="12px"
              justify="center"
              cursor="pointer"
              w="full"
              onClick={handlePushTreinamentos}
            >
              <Flex
                bg="violet.50"
                h="48px"
                minW="48px"
                borderRadius="full"
                align="center"
                justify="center"
              >
                <Icon as={AjudaTreinamento} boxSize="6" borderRadius="5px" />
              </Flex>
              <VStack align="flex-start" spacing="2px" w="full">
                <Text fontWeight="bold" fontSize="md">
                  Treinamentos
                </Text>
                <Flex align="center" justify="space-between" w="full">
                  <Text>Videos explicativos sobre o sistema</Text>
                  <AiOutlineRight />
                </Flex>
              </VStack>
            </Flex>

            <Flex
              gap="12px"
              justify="center"
              onClick={async () => {
                if (isClienteSTi3) {
                  setSelectedOption(optionsMenu.CHAT_MOVIEDESK);
                  setChatAccordionIndex(0);
                } else {
                  setSelectedOption(optionsMenu.CHAT_REVENDA);
                }
              }}
              w="full"
              cursor="pointer"
            >
              <Flex
                bg="violet.50"
                h="48px"
                minW="48px"
                borderRadius="full"
                align="center"
                justify="center"
              >
                <Icon as={AjudaChat} boxSize="6" borderRadius="5px" />
              </Flex>
              <VStack align="flex-start" spacing="2px" w="full">
                <Text fontWeight="bold" fontSize="md">
                  {isClienteSTi3 ? 'Chat online' : 'Atendimento ao cliente'}
                </Text>
                <Flex align="center" justify="space-between" w="full">
                  <Text>Fale com nossa equipe de suporte técnico</Text>
                  <AiOutlineRight />
                </Flex>
              </VStack>
            </Flex>
            {isClienteSTi3 && <SistemaTipoClienteSTi3 />}
          </VStack>
        </VStack>
      )}

      {selectedOption === optionsMenu.CHAT_MOVIEDESK && (
        <ChatMoviedesk voltarMenu={goToOpcaoMenuPrincipal} />
      )}
      {selectedOption === optionsMenu.CHAT_REVENDA && (
        <ChatRevenda voltarMenu={goToOpcaoMenuPrincipal} />
      )}
    </>
  );
};
