import React from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useMediaQuery,
  useDisclosure,
  ModalHeader,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { BrowserRouter, Route } from 'react-router-dom';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import CadastrarTamanho from 'pages/Tamanhos/Formulario/Cadastrar';
import { FormData } from 'pages/Tamanhos/Formulario/validationForm';

type CadastrarTamanhoResponse = {
  id: string;
  descricao: string;
};

type ModalCadastrarTamanhoResponse = {
  tamanho: CadastrarTamanhoResponse;
};

type ModalCadastrarTamanhoProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalCadastrarTamanhoResponse> & {
    inputValue: string;
    produtoId: string;
  };

export const ModalCadastrarTamanho = create<
  ModalCadastrarTamanhoProps,
  ModalCadastrarTamanhoResponse
>(({ onResolve, onReject, inputValue, produtoId, ...rest }) => {
  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  async function onSubmit(data: FormData) {
    const response = await api.post<
      void,
      ResponseApi<CadastrarTamanhoResponse>
    >(ConstanteEnderecoWebservice.TAMANHO_CADASTRAR_TAMANHO_MODAL, {
      ...data,
      produtoId,
    });

    if (response) {
      if (response.sucesso && response.dados) {
        onResolve({ tamanho: response.dados });

        return true;
      }

      if (response.avisos) {
        response.avisos.map((aviso: string) => toast.warning(aviso));
      }
    }

    return false;
  }

  return (
    <ModalPadraoChakra
      size="xl"
      {...rest}
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalContent mx="6" my="6" maxW="1000px">
        <ModalHeader
          mt={isSmallerThan900 ? 12 : undefined}
          mb={isSmallerThan900 ? 8 : undefined}
          borderBottom="1px"
          borderColor="gray.100"
          px="0"
          mx={{ base: 6, md: 8 }}
        >
          Cadastrar Tamanho
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p={{ base: 6, md: 8 }}>
          <BrowserRouter>
            <Route
              render={(props) => (
                <CadastrarTamanho
                  {...props}
                  cadastroExterno
                  descricaoTamanho={inputValue}
                  onSubmitExterno={onSubmit}
                />
              )}
            />
          </BrowserRouter>
        </ModalBody>
      </ModalContent>
    </ModalPadraoChakra>
  );
});
