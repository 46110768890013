import styled from 'styled-components';
import { UsuariosPerfilUsuarioIcon } from 'icons';

export const CategoriaClienteIcon = styled(UsuariosPerfilUsuarioIcon)`
  font-size: 1rem;
  min-width: 13px;

  margin-right: 6px;
  margin-left: -22px;
`;
