import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export type FormData = {
  ativo: boolean;
  descricao: string;
  caminhoArquivo: string;
  nomeArquivo: string;
  tipo: string;
};

const defaultValues = {
  ativo: true,
  descricao: '',
  caminhoArquivo: '',
  nomeArquivo: '',
  tipo: 'CUPOM',
} as FormData;

const schema = yup.object().shape({
  ativo: yup.boolean().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  tipo: yup.string().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  descricao: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  caminhoArquivo: yup.string().when('descricao', {
    is: (value) => !!value,
    then: yup.string().required(() => {
      toast.warning('Adicione o relatório (.rpt)', {
        toastId: 'caminhoArquivo',
      });
    }),
  }),
  nomeArquivo: yup.string().when('descricao', {
    is: (value) => !!value,
    then: yup.string().required(() => {
      toast.warning('Adicione o relatório (.rpt)', {
        toastId: 'caminhoArquivo',
      });
    }),
  }),
});

const yupResolver = yupResolverInstance(schema);

export { yupResolver, defaultValues };
