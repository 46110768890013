import { useCallback, useEffect, useState } from 'react';
import {
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  ModalFooter,
  VStack,
  ModalHeader,
  useMediaQuery,
  Box,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { buscarTabelaPrecoTrayAtiva } from 'services/tray';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import TipoTabelaPrecoEnum from 'constants/enum/tipoTabelaPrecoEnum';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { yupResolver, FormData } from '../validationForms';

type ModalTabelaCriadaResponseProps = {
  sucesso: boolean;
};

type Options = {
  label: string;
  value: string;
};

export type OptionResponseProps = {
  id: string;
  nome: string;
};

type ModalTabelaCriadaProps = Omit<
  ModalTabelaCriadaResponseProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalTabelaCriadaResponseProps>;

export const ModalTabelaCriada = create<
  ModalTabelaCriadaProps,
  ModalTabelaCriadaResponseProps
>(({ onResolve, onReject, ...rest }) => {
  const [tabelaPreco, setTabelaPreco] = useState<Options[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues: {
      tabelaPreco: null,
    },
  });

  const { reset } = formMethods;

  const preencherDadosTabelaPreco = useCallback(async () => {
    setIsLoading(true);

    const response = await buscarTabelaPrecoTrayAtiva();
    if (response !== null) {
      reset({ tabelaPreco: response });
    }

    setIsLoading(false);
  }, [reset]);

  const buscarTabelasPrecoIntegracao = useCallback(async () => {
    const response = await api.get<void, ResponseApi<OptionResponseProps[]>>(
      `${ConstanteEnderecoWebservice.TABELA_PRECO_LISTAR_TABELAS_PRECO}?tipo=${TipoTabelaPrecoEnum.INTEGRACAO}`
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso && response.dados) {
        setTabelaPreco(
          response.dados.map((tabelasPreco) => {
            return {
              label: tabelasPreco.nome,
              value: tabelasPreco.id,
            } as Options;
          })
        );
      }
    }
  }, []);

  const handleAvancar = () => {
    onResolve({
      sucesso: true,
    });
  };

  useEffect(() => {
    buscarTabelasPrecoIntegracao();
  }, [buscarTabelasPrecoIntegracao]);

  useEffect(() => {
    preencherDadosTabelaPreco();
  }, [preencherDadosTabelaPreco]);

  return (
    <ModalPadraoChakra
      isCentered={isLargerThan900}
      size={isLargerThan900 ? '3xl' : 'full'}
      {...rest}
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={false}
    >
      <ModalContent
        bg="gray.50"
        borderRadius={isLargerThan900 ? 'md' : '0'}
        marginBottom={isLargerThan900 ? '3.75rem' : '0'}
        marginTop={isLargerThan900 ? '3.75rem' : '0'}
        w={isLargerThan900 ? '640px' : 'full'}
        h={isLargerThan900 ? '380px' : 'full'}
      >
        <ModalHeader pt="16px" pb="18px" pl="24px">
          <Text color="primary.50" fontSize="20px" pb="10px">
            Selecionar uma tabela de preços já existente no sistema
          </Text>
        </ModalHeader>
        {isLoading && <LoadingPadrao />}
        <ModalBody px="24px" mb="20px" py="0" w="95%">
          <VStack
            color="black"
            fontSize="14px"
            w="full"
            alignItems="left"
            spacing="24px"
          >
            <Text mb="4px" textAlign="left" letterSpacing="0px">
              Se você já trabalha com uma tabela de preços específica para venda
              online, poderá utilizar a mesma, no entanto não será possível
              diferenciar o preço de venda entre as plataformas.
            </Text>
            <Box>
              <FormProvider {...formMethods}>
                <SelectPadrao
                  id="tabelaPreco"
                  name="tabelaPreco"
                  label="Selecionar a tabela"
                  asControlledByObject={false}
                  colSpan={12}
                  options={tabelaPreco}
                  placeholder="Selecione a tabela de preço"
                />
              </FormProvider>
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter flexDirection="column" mb="10px">
          <Flex w="full" h="full" justifyContent="center" alignItems="baseline">
            <Button
              color="white"
              variant="solid"
              colorScheme="purple.500"
              lineHeight="0"
              borderRadius="20px"
              fontSize="sm"
              h="40px"
              width="120px"
              onClick={() => handleAvancar()}
            >
              Confirmar
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </ModalPadraoChakra>
  );
});
