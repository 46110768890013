import React, { useCallback, useState } from 'react';
import {
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  InputGroup,
  InputRightElement,
  Icon,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';

import CampoContainer, {
  CampoContainerProps,
} from 'components/PDV/Geral/CampoContainer';

export type InputProps = Omit<ChakraInputProps, 'ref'> & CampoContainerProps;

const InputSenhaUsuario = ({
  id,
  name,
  label,
  helperText,
  colSpan,
  colStart,
  colEnd,
  rowStart,
  rowEnd,
  rowSpan,
  borderRadius,
  w,
  maxW,
  minW,
  isRequired,
  actionLinkText,
  actionLinkOnClick,
  colorSchemeLinkText,
  icon,
  color = 'black',
  bg = 'white',
  fontWeightLabel = 'bold',
  type,
  ...rest
}: InputProps) => {
  const [valueIsVisible, setValueIsVisible] = useState(true);

  const {
    register,
    trigger,
    formState: { errors, isValid },
    getValues,
  } = useFormContext();

  const regexSenha = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{6,50}$/;
  // regex que verifica se a string tem pelo menos 1 letra maiuscula, 1 minuscula, 1 numero e 1 caracter especial

  const getColor = useCallback(() => {
    const valueInput = getValues(name);
    if (errors[name]) {
      return 'red.500';
    }
    if (isValid && valueInput) {
      return 'green.500';
    }
    return 'gray.200';
  }, [errors, getValues, isValid, name]);

  return (
    <CampoContainer
      id={id}
      name={name}
      label={label}
      helperText={helperText}
      colSpan={colSpan}
      colStart={colStart}
      colEnd={colEnd}
      rowStart={rowStart}
      rowEnd={rowEnd}
      rowSpan={rowSpan}
      errorText={errors[name]?.message}
      isInvalid={errors[name]}
      w={w}
      borderRadius={borderRadius}
      maxW={maxW}
      minW={minW}
      isRequired={isRequired}
      actionLinkText={actionLinkText}
      actionLinkOnClick={actionLinkOnClick}
      colorSchemeLinkText={colorSchemeLinkText}
      fontWeightLabel={fontWeightLabel}
    >
      <InputGroup>
        <ChakraInput
          {...rest}
          id={id}
          bg={bg}
          color={color}
          autofill={{
            WebkitTextFillColor: 'black',
            color: 'black',
            transition: 'background-color 5000s ease-in-out 0s',
          }}
          type={valueIsVisible ? 'password' : type}
          {...register(`${name}` as const, {
            pattern: regexSenha,
          })}
          onBlur={() => {
            trigger(`${name}` as const);
          }}
          _invalid={{
            borderColor: 'red.500',
          }}
          borderColor={getColor()}
          _focus={{
            borderWidth: '2px',
            borderColor: getColor(),
          }}
          _hover={{}}
        />
        <InputRightElement>
          <Icon
            boxSize="22px"
            cursor="pointer"
            color="gray.600"
            onClick={() => setValueIsVisible(!valueIsVisible)}
            as={valueIsVisible ? AiOutlineEyeInvisible : AiOutlineEye}
          />
        </InputRightElement>
      </InputGroup>
    </CampoContainer>
  );
};

export default InputSenhaUsuario;
