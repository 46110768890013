import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  SetStateAction,
  Dispatch,
} from 'react';
import { FormProvider, FormProviderProps } from 'react-hook-form';

import { FormRegraFiscalData } from 'pages/RegrasFiscais/Formulario/types';

interface RegrasFiscaisFormularioContextProps {
  activeOperacaoIndex: number;
  handleSetActiveOperacaoIndex: (newActiveOperacaoIndex: number) => void;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

const RegrasFiscaisFormularioContext = createContext<RegrasFiscaisFormularioContextProps>(
  {} as RegrasFiscaisFormularioContextProps
);

interface RegrasFiscaisFormularioProviderProps
  extends Omit<FormProviderProps<FormRegraFiscalData>, 'children'> {
  children: ReactNode;
}

export default function RegrasFiscaisFormularioProvider({
  children,
  ...formMethods
}: RegrasFiscaisFormularioProviderProps): JSX.Element {
  const [activeOperacaoIndex, setActiveOperacaoIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  function handleSetActiveOperacaoIndex(newActiveOperacaoIndex: number) {
    setIsLoading(true);

    setActiveOperacaoIndex(newActiveOperacaoIndex);
  }

  return (
    <RegrasFiscaisFormularioContext.Provider
      value={{
        activeOperacaoIndex,
        handleSetActiveOperacaoIndex,
        isLoading,
        setIsLoading,
      }}
    >
      <FormProvider {...formMethods}>{children}</FormProvider>
    </RegrasFiscaisFormularioContext.Provider>
  );
}

export function useRegrasFiscaisFormularioContext(): RegrasFiscaisFormularioContextProps {
  const context = useContext(RegrasFiscaisFormularioContext);

  if (!context)
    throw new Error(
      'useRegrasFiscaisFormularioContext must be used within a RegrasFiscaisFormularioProvider.'
    );

  return context;
}
