import { useCallback, useState, useEffect } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  ModalFooter,
  Box,
  ModalHeader,
  useMediaQuery,
  Divider,
  SimpleGrid,
  Grid,
  Checkbox,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { ButtonDefault } from 'components/Button/ButtonChakra';

type VariacoesProps = {
  id: string;
  produtoId: string;
  nome: string;
  cor: string;
  tamanho: string;
  precoCusto: number;
  precoVenda: number;
  isChecked: boolean;
};

type ModalSelecionarVariacaoProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<string[]> & {
    id: string;
    nomeProduto: string;
    dataVariacoes?: string[];
  };

export const ModalSelecionarVariacao = create<
  ModalSelecionarVariacaoProps,
  string[]
>(({ onResolve, onReject, id, nomeProduto, dataVariacoes, ...rest }) => {
  const [listVariacoes, setListVariacoes] = useState<VariacoesProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selecionarTodosProduto, setSelecionarTodosProduto] = useState(false);

  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  function handleToggleSelecionarVariacao(index: number) {
    const novoArray = [...listVariacoes];

    const item = novoArray[index];

    novoArray.splice(index, 1, {
      ...item,
      isChecked: !item.isChecked,
    });

    setListVariacoes(novoArray);

    if (novoArray.every((produtoItem) => produtoItem.isChecked === true)) {
      setSelecionarTodosProduto(true);
    } else {
      setSelecionarTodosProduto(false);
    }
  }

  function handleToggleSelecionarTodasVariacao() {
    setSelecionarTodosProduto(!selecionarTodosProduto);

    setListVariacoes((prev) =>
      prev.map((itemJaFoiAdicionado) => ({
        ...itemJaFoiAdicionado,
        isChecked: !selecionarTodosProduto,
      }))
    );
  }

  const getVariacoes = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get<void, ResponseApi<VariacoesProps[]>>(
      ConstanteEnderecoWebservice.TABELA_PRECO_OBTER_PRODUTO_COR_TAMANHO,
      { params: { produtoId: id } }
    );
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso) {
        const newValueData = response.dados.map((itemVariacao) => {
          const variacaoAdicionada = (dataVariacoes || []).some(
            (item) => item === itemVariacao.id
          );

          return {
            ...itemVariacao,
            isChecked: variacaoAdicionada,
          };
        });
        setListVariacoes(newValueData);
        setIsLoading(false);
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [id, dataVariacoes]);

  const handleSubmit = () => {
    setIsLoading(true);
    const variacoesSelecionadas = listVariacoes.filter(
      (item) => item.isChecked
    );
    if (variacoesSelecionadas.length === 0) {
      toast.warning('Informe um variação');
    } else {
      onResolve(variacoesSelecionadas.map((item) => item.id));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getVariacoes();
  }, [getVariacoes]);

  return (
    <ModalPadraoChakra
      isCentered={isLargerThan900}
      size={isLargerThan900 ? '5xl' : 'full'}
      {...rest}
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={false}
    >
      <ModalContent
        bg="gray.50"
        borderRadius={isLargerThan900 ? 'md' : '0'}
        marginBottom={isLargerThan900 ? '3.75rem' : '0'}
        marginTop={isLargerThan900 ? '3.75rem' : '0'}
        w={isLargerThan900 ? '1000px' : 'full'}
        h={isLargerThan900 ? '520px' : 'full'}
      >
        <ModalHeader pt="16px" pb="28px" pl="24px">
          <Text color="primary.50" fontSize="16px">
            {nomeProduto}
          </Text>
        </ModalHeader>

        {isLoading && <LoadingPadrao />}
        <ModalBody h="300px" pl="24px" pr="24px" pt="0" pb="0">
          <Grid
            pl="16px"
            as="div"
            h="40px"
            pr="16px"
            bg={selecionarTodosProduto ? 'secondary.300' : 'white'}
            w={['full', '304px', '304px']}
            justifyContent="space-between"
            borderRadius="5px"
            borderWidth="1px"
            borderColor="gray.200"
            borderStyle="solid"
            mb="8px"
          >
            <Flex h="full" alignItems="center" justifyContent="center">
              <Box>
                <Checkbox
                  colorScheme="primary"
                  isChecked={selecionarTodosProduto}
                  onChange={() => handleToggleSelecionarTodasVariacao()}
                  mr="12px"
                  mb="0"
                />
              </Box>
              <Box>
                <Text color="gray.700" fontSize="14px">
                  Selecionar todas variações
                </Text>
              </Box>
            </Flex>
          </Grid>
          <SimpleGrid
            templateColumns={[
              'repeat(auto-fill, minmax(304px, full))',
              'repeat(auto-fill, minmax(304px, 304px))',
              'repeat(auto-fill, minmax(304px, 304px))',
            ]}
            spacing="4"
            maxH="305px"
            overflow="auto"
            columnGap="16px"
            rowGap="4px"
            mb="34px"
          >
            {listVariacoes.map((variacoes, index) => {
              const nameProduto = `${
                variacoes?.cor || variacoes?.tamanho ? '' : ''
              }  ${
                variacoes?.cor
                  ? `${variacoes?.cor} ${
                      variacoes?.cor && variacoes?.tamanho ? ' |' : ''
                    }`
                  : ''
              } ${variacoes?.tamanho}`;
              return (
                <Box>
                  <Grid
                    pl="16px"
                    as="div"
                    h="40px"
                    pt="4px"
                    pr="16px"
                    alignItems="baseline"
                    bg={variacoes.isChecked ? 'purple.50' : 'white'}
                    w="100%"
                    display="flex"
                    justifyContent="space-between"
                    borderRadius="5px"
                    borderWidth="1px"
                    borderColor="gray.200"
                    borderStyle="solid"
                  >
                    <Flex h="full" alignItems="center" justifyContent="center">
                      <Box>
                        <Checkbox
                          colorScheme="primary"
                          isChecked={variacoes.isChecked}
                          onChange={() => handleToggleSelecionarVariacao(index)}
                          mr="12px"
                          mb="0"
                        />
                      </Box>
                      <Box>
                        <Text color="gray.700" fontSize="14px">
                          {nameProduto.slice(0, !isLargerThan900 ? 35 : 60)}
                          {nameProduto.length > (!isLargerThan900 ? 35 : 60)
                            ? '...'
                            : ''}
                        </Text>
                      </Box>
                    </Flex>
                  </Grid>
                </Box>
              );
            })}
          </SimpleGrid>
        </ModalBody>
        <ModalFooter flexDirection="column" mb="10px">
          <Divider mb="24px" />
          <Flex w="full" h="full" justifyContent="center" alignItems="baseline">
            <Button
              color="gray.300"
              variant="outlineDefault"
              borderRadius="20px"
              borderColor="gray.300"
              fontSize="sm"
              lineHeight="0"
              type="button"
              h={isLargerThan900 ? '32px' : '40px'}
              mr="24px"
              onClick={() => onClose()}
              w="96px"
            >
              Cancelar
            </Button>
            <ButtonDefault
              color="black"
              variant="solid"
              colorScheme="secondary"
              lineHeight="0"
              borderRadius="20px"
              fontSize="sm"
              h={isLargerThan900 ? '32px' : '40px'}
              width="120px"
              onClick={handleSubmit}
              isLoading={isLoading}
            >
              Confirmar
            </ButtonDefault>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </ModalPadraoChakra>
  );
});
