const GenerosEnum = {
  MASCULINO: 1,
  FEMININO: 2,
  NAO_INFORMADO: 3,

  properties: {
    1: { name: 'Masculino', value: 1 },
    2: { name: 'Feminino', value: 2 },
    3: { name: 'Não informado', value: 3 },
  },
};

export default GenerosEnum;
