import { useEffect, useState } from 'react';

import {
  VStack,
  Text,
  HStack,
  useMediaQuery,
  TextProps,
  SimpleGrid,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { OperacaoItemObter } from 'helpers/api/Operacao/obterOperacaoComItens';
import { moneyMask } from 'helpers/format/fieldsMasks';

const Totalizadores = () => {
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const [quantidade, setQuantidade] = useState(0);
  const [subTotal, setSubtotal] = useState('');
  const [desconto, setDesconto] = useState('');
  const [valorTotal, setValorTotal] = useState('');

  const [hasTotalizadoresDesconto, setHasTotalizadoresDesconto] = useState(
    true
  );

  const { watch } = useFormContext();

  const operacaoItens = watch('operacaoItens') as OperacaoItemObter[];

  useEffect(() => {
    if (operacaoItens) {
      let somaSubtotal = 0;
      let somaDesconto = 0;
      let somaQuantidade = 0;

      operacaoItens.forEach((item) => {
        somaQuantidade += item.volumeUnitario ? item.quantidade : 1;
        somaSubtotal += item.quantidade * item.valorUnitario;
        somaDesconto += item.valorDescontoItem;
      });

      setHasTotalizadoresDesconto(somaDesconto > 0);

      setSubtotal(moneyMask(somaSubtotal, false));
      setDesconto(moneyMask(somaDesconto, false));
      setValorTotal(moneyMask(somaSubtotal - somaDesconto, false));
      setQuantidade(somaQuantidade);
    }
  }, [operacaoItens]);

  const Titulo = ({ nome, ...props }: { nome: string; props?: TextProps }) => (
    <Text color="gray.400" fontSize="2xs" fontWeight="semibold" {...props}>
      {nome}
    </Text>
  );

  const ValorSubTotal = () => (
    <HStack alignItems="baseline" spacing={1}>
      <Text fontSize="2xs">R$</Text>
      <Text
        color="gray.700"
        fontSize={{ base: 'sm', md: 'md' }}
        fontWeight="extrabold"
      >
        {subTotal}
      </Text>
    </HStack>
  );

  const ValorDesconto = () => (
    <HStack alignItems="baseline" color="red.500" spacing={1}>
      <Text fontSize="2xs">R$</Text>
      <Text fontSize={{ base: 'sm', md: 'md' }} fontWeight="extrabold">
        {desconto}
      </Text>
    </HStack>
  );

  const ValorTotal = () => (
    <HStack alignItems="baseline" color="blue.800" spacing={1}>
      <Text fontSize="2xs">R$</Text>
      <Text fontSize={{ base: 'sm', md: 'md' }} fontWeight="extrabold">
        {valorTotal}
      </Text>
    </HStack>
  );

  return isLargerThan900 ? (
    <SimpleGrid
      templateColumns="repeat(auto-fit, minmax(100px, 1fr))"
      p="5"
      rowGap="4"
    >
      <VStack spacing={0} alignItems="normal">
        <HStack>
          <Text fontSize="xs" color="gray.300">
            Produtos:
          </Text>
          <Text fontSize="md" fontWeight="extrabold">
            {operacaoItens.length}
          </Text>
        </HStack>
        <HStack>
          <Text fontSize="xs" color="gray.300">
            Volume:
          </Text>
          <Text fontSize="md" fontWeight="extrabold">
            {quantidade}
          </Text>
        </HStack>
      </VStack>

      <VStack spacing={0} borderLeft="1px" borderColor="gray.100">
        <Titulo nome="SUBTOTAL:" />
        <ValorSubTotal />
      </VStack>

      <VStack spacing={0} borderLeft="1px" borderColor="gray.100">
        <Titulo nome="DESCONTOS:" />
        <ValorDesconto />
      </VStack>

      <VStack spacing={0} borderLeft="1px" borderColor="gray.100">
        <Titulo nome="VALOR TOTAL:" />
        <ValorTotal />
      </VStack>
    </SimpleGrid>
  ) : (
    <VStack
      spacing="3"
      alignItems="flex-end"
      w="full"
      py="4"
      sx={{ '& p': { lineHeight: 'none' } }}
    >
      <HStack>
        <Text fontSize="xs" color="gray.700">
          Produtos:
        </Text>
        <Text fontSize="sm" color="black">
          {operacaoItens.length}
        </Text>
      </HStack>

      <HStack>
        <Text fontSize="xs" color="gray.700">
          Quantidade:
        </Text>
        <Text fontSize="sm" color="black">
          {quantidade}
        </Text>
      </HStack>

      {hasTotalizadoresDesconto && (
        <HStack alignItems="center" justifyContent="flex-end">
          <Titulo nome="SUBTOTAL" />
          <ValorSubTotal />
        </HStack>
      )}

      {hasTotalizadoresDesconto && (
        <HStack alignItems="center" justifyContent="flex-end">
          <Titulo nome="DESCONTOS" />
          <ValorDesconto />
        </HStack>
      )}

      <HStack alignItems="center" justifyContent="flex-end">
        <Titulo nome="VALOR TOTAL" />
        <ValorTotal />
      </HStack>
    </VStack>
  );
};

export default Totalizadores;
