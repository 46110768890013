import ConstanteRotas from 'constants/rotas';

import {
  PainelConfiguracoesIcon,
  PainelFormaRecebimentoIcon,
  PainelProdutosIcon,
  PainelVendasIcon,
} from 'icons';

export const painelAdministradorTrayEnum = {
  option: [
    {
      icone: PainelProdutosIcon,
      name: 'Produtos',
      rota: ConstanteRotas.INTEGRACAO_TRAY_COMMERCE_PRODUTO,
      isDisabled: false,
    },
    {
      icone: PainelVendasIcon,
      name: 'Vendas',
      rota: ConstanteRotas.INTEGRACAO_TRAY_COMMERCE_VENDAS,
      isDisabled: false,
    },

    {
      icone: PainelFormaRecebimentoIcon,
      name: 'Formas de recebimento',
      rota: ConstanteRotas.INTEGRACAO_TRAY_COMMERCE_FORMA_RECEBIMENTO,
      isDisabled: false,
    },
    {
      icone: PainelConfiguracoesIcon,
      name: 'Configurações',
      rota: ConstanteRotas.INTEGRACAO_TRAY_COMMERCE_CONFIGURACAO,
      isDisabled: false,
    },
  ],
};
