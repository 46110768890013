import { useCallback, useEffect } from 'react';
import {
  ModalContent,
  ModalBody,
  useDisclosure,
  Text,
  Flex,
  useMediaQuery,
  Box,
  Icon,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { toast } from 'react-toastify';
import Lottie from 'lottie-react';

import Loading from 'assets/animations/loading.json';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import { ImportarProdutosIcon } from 'icons';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';

type ModalBuscarProdutoResponseProps = {
  sucesso?: boolean;
  infoProduto?: InformacaoProdutoProps | null;
};

export type InformacaoProdutoProps = {
  ignorarReferenciaEan: boolean;
  quantidadeProduto: number;
};

export type DadosProps = {
  siteStatus: number;
};

type ModalBuscarProdutoProps = Omit<
  ModalBuscarProdutoResponseProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalBuscarProdutoResponseProps> & {
    dados: DadosProps;
  };

export const ModalBuscarProduto = create<
  ModalBuscarProdutoProps,
  ModalBuscarProdutoResponseProps
>(({ onResolve, onReject, dados, ...rest }) => {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const getQuantidadeProduto = useCallback(async () => {
    const response = await api.get<void, ResponseApi<InformacaoProdutoProps>>(
      ConstanteEnderecoWebservice.INTEGRACAO_TRAY_QUANTIDADE_PRODUTO
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
        return null;
      }
      if (response.sucesso) {
        return response.dados;
      }
    }
    return null;
  }, []);

  const alterarBuscaProduto = useCallback(async () => {
    const response = await api.put<void, ResponseApi<boolean>>(
      ConstanteEnderecoWebservice.INTEGRACAO_TRAY_BUSCAR_PRODUTO_ALTERAR,
      dados
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso) {
        const infoProduto = await getQuantidadeProduto();
        if (
          (infoProduto?.quantidadeProduto || 0) > 0 &&
          infoProduto?.quantidadeProduto !== null
        ) {
          onResolve({
            sucesso: true,
            infoProduto,
          });
        } else {
          toast.warning('Não foi possível encontrar produtos nessa data');
          onResolve({
            sucesso: false,
            infoProduto: undefined,
          });
        }
      }
    }
  }, [dados, getQuantidadeProduto, onResolve]);

  useEffect(() => {
    if (isOpen) {
      alterarBuscaProduto();
    }
  }, [alterarBuscaProduto, isOpen]);

  return (
    <ModalPadraoChakra
      isCentered={isLargerThan900}
      size={isLargerThan900 ? '3xl' : 'full'}
      {...rest}
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={false}
    >
      <ModalContent
        bg="gray.50"
        borderRadius={isLargerThan900 ? 'md' : '0'}
        marginBottom={isLargerThan900 ? '3.75rem' : '0'}
        marginTop={isLargerThan900 ? '3.75rem' : '0'}
        w={isLargerThan900 ? '640px' : 'full'}
        h={isLargerThan900 ? '200px' : 'full'}
      >
        <ModalBody px="56px" pt="0" w="100%" pb="0">
          <Flex
            color="black"
            fontSize="14px"
            w="full"
            h="full"
            alignItems="center"
            justifyContent="space-between"
          >
            <Flex align="center">
              <Icon as={ImportarProdutosIcon} boxSize="64px" />
              <Box pl="24px">
                <Text color="gray.700" fontSize="20px" fontWeight="bold">
                  Buscando produtos na plataforma
                </Text>
                <Text color="primary.50" fontSize="17px">
                  Carregando informações…
                </Text>
              </Box>
            </Flex>
            <Box>
              <Lottie
                animationData={Loading}
                loop
                style={{
                  width: '50px',
                  height: '50px',
                }}
              />
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </ModalPadraoChakra>
  );
});
