import { Box, Flex, Text } from '@chakra-ui/react';
import Lottie from 'lottie-react';

import Atencao from 'assets/animations/acao_atencao.json';
import { useSignalRContext } from 'store/SignalR';

export const NotaPossuiRejeicao = () => {
  const { statusNotaFiscal } = useSignalRContext();

  const amountRejeitada = statusNotaFiscal?.quantidadeRejeitada || 0;
  const amountSucesso = statusNotaFiscal?.quantidadeAutorizada || 0;

  return (
    <>
      <Flex
        bg="gray.100"
        w="full"
        h="full"
        justifyContent="center"
        alignItems="center"
      >
        <Flex alignItems="center" justifyContent="center">
          <Box>
            <Lottie
              animationData={Atencao}
              loop
              style={{
                width: '209px',
                height: '209px',
              }}
            />
          </Box>
          <Box color="gray.700" ml="40px">
            {amountSucesso !== 0 && (
              <Text color="gray.700" fontSize="20px">
                {amountSucesso}{' '}
                {(amountSucesso || 0) > 1
                  ? 'notas foram emitidas'
                  : 'nota foi emitida'}{' '}
                com{' '}
                <Text fontWeight="bold" display="inline" color="violet.500">
                  Sucesso!
                </Text>
              </Text>
            )}
            <Text mb="16px" color="gray.700" fontSize="20px">
              {amountRejeitada}{' '}
              {amountRejeitada > 1 ? 'notas foram' : 'nota foi'}{' '}
              <Text fontWeight="bold" display="inline" color="red.500">
                {amountRejeitada > 1 ? 'Rejeitadas' : 'Rejeitada'}
              </Text>
            </Text>

            <Text w="340px">
              Instruções:{' '}
              <Text display="inline" color="black">
                Feche a tela de emissão para corrigir os dados rejeitados.
                Depois de corrigido, selecione a venda e clique em “Emitir
                novamente a Nota Fiscal”
              </Text>
            </Text>
          </Box>
        </Flex>
      </Flex>
    </>
  );
};
