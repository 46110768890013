import React, {
  createContext,
  useState,
  useContext,
  SetStateAction,
  Dispatch,
  useCallback,
  useRef,
  useEffect,
  MutableRefObject,
} from 'react';
import produce from 'immer';
import { toast } from 'react-toastify';

import PlanoContaInterface from 'types/planoConta';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';
import StatusConsultaSimplificadoEnum, {
  StatusConsultaSimplificado,
} from 'constants/enum/statusConsultaSimplificado';

import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { ModalConfirmacaoInativar } from 'components/Modal/ModalConfirmacaoInativar';

interface PlanoContasGeralContextProps {
  planosConta: PlanoContaInterface[];
  setPlanosConta: Dispatch<SetStateAction<PlanoContaInterface[]>>;

  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  itemsExpandidos: boolean;
  setItemsExpandidos: Dispatch<SetStateAction<boolean>>;
  handleGetPlanosConta: (statusConsulta?: StatusConsultaSimplificado) => void;
  modalIsOpen: boolean;
  handleOpenModal: (
    permiteTipoTotalizador: boolean,
    planoContaObj?: PlanoContaInterface,
    planoContaPaiId?: string,
    tipoPlanoContaObrigatorio?: number
  ) => void;
  currentPlanoConta: {
    permiteTipoTotalizador: boolean;
    tipoPlanoContaObrigatorio?: number;
    id?: string;
    planoContaPaiId?: string;
  };
  handleCloseModal: () => void;
  handleAddPlanoConta: (planoConta: PlanoContaInterface) => void;
  handleUpdatePlanoConta: (planoConta: PlanoContaInterface) => void;
  visaoPlanoContas: 'caixa' | 'dre';
  handleToggleVisaoPlanoContas: () => void;
  handleGetSubNiveisPlanoConta: (itemId: string) => number;
  handleInativarPlanoConta: (
    planoContaId: string,
    ativo: boolean
  ) => Promise<void>;
  handleDeletePlanoConta: (
    planoContaId: string,
    ativo: boolean
  ) => Promise<void>;
  handlePutPlanoConta: (
    planoConta: PlanoContaInterface,
    getPlanosConta?: boolean
  ) => Promise<void>;
}

const PlanoContasGeralContext = createContext<PlanoContasGeralContextProps>(
  {} as PlanoContasGeralContextProps
);

interface PlanoContasProviderProps {
  children: React.ReactNode;
}

export default function PlanoContasProvider({
  children,
}: PlanoContasProviderProps): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const [itemsExpandidos, setItemsExpandidos] = useState(false);
  const [planosConta, setPlanosConta] = useState<PlanoContaInterface[]>([]);
  const [currentPlanoConta, setCurrentPlanoConta] = useState<{
    permiteTipoTotalizador: boolean;
    obj?: PlanoContaInterface;
    planoContaPaiId?: string;
    tipoPlanoContaObrigatorio?: number;
  }>({
    permiteTipoTotalizador: true,
    obj: undefined,
    planoContaPaiId: undefined,
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [visaoPlanoContas, setVisaoPlanoContas] = useState<'caixa' | 'dre'>(
    'caixa'
  );

  const handleToggleVisaoPlanoContas = useCallback(() => {
    setVisaoPlanoContas((prev) => (prev === 'caixa' ? 'dre' : 'caixa'));
  }, []);

  const statusConsultaAtualRef = useRef(
    StatusConsultaSimplificadoEnum.ATIVOS
  ) as MutableRefObject<StatusConsultaSimplificado>;

  const handleGetPlanosConta = useCallback(
    async (statusConsulta?: StatusConsultaSimplificado) => {
      let filtroStatusConsulta = StatusConsultaSimplificadoEnum.ATIVOS;
      if (statusConsulta) {
        statusConsultaAtualRef.current = statusConsulta;
        filtroStatusConsulta = statusConsulta;
      } else if (statusConsultaAtualRef.current) {
        filtroStatusConsulta = statusConsultaAtualRef.current;
      }

      setIsLoading(true);

      const response = await api.get<
        void,
        ResponseApi<Array<PlanoContaInterface>>
      >(ConstanteEnderecoWebservice.PLANO_CONTA_LISTAR, {
        params: { statusConsulta: filtroStatusConsulta },
      });

      if (response && response.sucesso) {
        const value = response.dados.map((planoConta: PlanoContaInterface) => ({
          ...planoConta,
          expandido: false,
        }));
        setPlanosConta(
          value.sort((a, b) => {
            if (
              Number(a.indice.replaceAll('.', '')) <
              Number(b.indice.replaceAll('.', ''))
            ) {
              return -1;
            }
            if (
              Number(a.indice.replaceAll('.', '')) >
              Number(b.indice.replaceAll('.', ''))
            ) {
              return 1;
            }
            return 0;
          })
        );
      }

      setIsLoading(false);
    },
    [setPlanosConta]
  );

  const handleOpenModal = useCallback(
    (
      permiteTipoTotalizador: boolean,
      planoContaObj?: PlanoContaInterface,
      planoContaPaiId?: string,
      tipoPlanoContaObrigatorio?: number
    ) => {
      setCurrentPlanoConta({
        permiteTipoTotalizador,
        obj: planoContaObj,
        planoContaPaiId,
        tipoPlanoContaObrigatorio,
      });

      setModalIsOpen(true);
    },
    []
  );

  const handleCloseModal = useCallback(() => {
    setCurrentPlanoConta({
      permiteTipoTotalizador: true,
      obj: undefined,
      planoContaPaiId: undefined,
    });

    setModalIsOpen(false);
  }, []);

  const handleAddPlanoConta = useCallback(
    (planoConta: PlanoContaInterface) => {
      setPlanosConta([...planosConta, planoConta]);
    },
    [planosConta]
  );

  const handleUpdatePlanoConta = useCallback(
    (planoConta: PlanoContaInterface) => {
      setPlanosConta(
        produce(planosConta, (draft) => {
          const oldPlanoContaIndex = planosConta.findIndex(
            (value) => value.id === planoConta.id
          );

          draft.splice(oldPlanoContaIndex, 1);

          draft.splice(oldPlanoContaIndex, 0, planoConta);
        })
      );
    },
    [planosConta]
  );

  const countSubNiveisPlanoConta = useCallback(
    (planoContaId: string, subNiveis: number) => {
      let newSubNiveis = subNiveis;

      const proximoNivelItems = planosConta.filter(
        (planoConta) => planoConta.planoContaPaiId === planoContaId
      );

      if (proximoNivelItems.length > 0) {
        newSubNiveis += 1;
      }

      proximoNivelItems.forEach((planoConta) => {
        newSubNiveis = countSubNiveisPlanoConta(planoConta.id, newSubNiveis);
      });

      return newSubNiveis;
    },
    [planosConta]
  );

  const handleGetSubNiveisPlanoConta = useCallback(
    (itemId: string) => {
      let subNiveis = 1;

      subNiveis = countSubNiveisPlanoConta(itemId, subNiveis);

      return subNiveis;
    },
    [countSubNiveisPlanoConta]
  );

  const handleInativarPlanoConta = useCallback(
    async (planoContaId: string, ativo: boolean) => {
      setIsLoading(true);

      ModalConfirmacaoInativar({
        rotaWebService: ConstanteEnderecoWebservice.PLANO_CONTA_INATIVAR,
        id: planoContaId,
        ativo,
        text:
          'Todas os subníveis que estão dentro deste nível também serão bloqueados. Se preferir, você pode movê-los para outra pasta antes de inativar.',
        title: 'Inativar todo o conteúdo?',
        callback: (ok: boolean) => {
          if (ok) handleGetPlanosConta();
        },
      });

      setIsLoading(false);
    },
    [handleGetPlanosConta, setIsLoading]
  );

  const handleDeletePlanoConta = useCallback(
    async (planoContaId: string, ativo: boolean) => {
      setIsLoading(true);

      ModalConfirmacaoExcluir({
        callback: async (ok: boolean) => {
          if (ok) {
            setIsLoading(true);

            const response = await api.delete<void, ResponseApi>(
              ConstanteEnderecoWebservice.PLANO_CONTA_EXCLUIR,
              {
                params: { id: planoContaId },
              }
            );

            if (response?.sucesso) {
              toast.success('O cadastro foi removido com sucesso.');

              handleGetPlanosConta();
            }

            if (response) {
              ModalConfirmacaoInativar({
                response,
                rotaWebService:
                  ConstanteEnderecoWebservice.PLANO_CONTA_INATIVAR,
                id: planoContaId,
                ativo,
                text:
                  'Não é possível remover este plano de contas pois ele já foi utilizado em outras ações do sistema. Se preferir você pode inativá-lo.',
                title: `${'Não pode!'} :(`,
                callback: (okInativar: boolean) => {
                  if (okInativar) handleGetPlanosConta();
                },
              });
            }

            setIsLoading(false);
          }
        },
      });

      setIsLoading(false);
    },
    [handleGetPlanosConta, setIsLoading]
  );

  const handlePutPlanoConta = useCallback(
    async (planoConta: PlanoContaInterface, getPlanosConta?: boolean) => {
      setIsLoading(true);

      const response = await api.put<void, ResponseApi>(
        ConstanteEnderecoWebservice.PLANO_CONTA_ALTERAR,
        planoConta
      );

      if (response.sucesso && getPlanosConta) {
        handleGetPlanosConta();
      } else if (!response.sucesso) {
        handleGetPlanosConta();
      }

      if (response.avisos)
        response.avisos.map((aviso: string) => toast.warning(aviso));

      setIsLoading(false);
    },
    [handleGetPlanosConta, setIsLoading]
  );

  useEffect(() => {
    handleGetPlanosConta();
  }, [handleGetPlanosConta]);

  return (
    <PlanoContasGeralContext.Provider
      value={{
        planosConta,
        setPlanosConta,
        isLoading,
        setIsLoading,
        itemsExpandidos,
        setItemsExpandidos,
        handleGetPlanosConta,
        modalIsOpen,
        handleOpenModal,
        currentPlanoConta,
        handleCloseModal,
        handleAddPlanoConta,
        handleUpdatePlanoConta,
        visaoPlanoContas,
        handleToggleVisaoPlanoContas,
        handleGetSubNiveisPlanoConta,
        handleInativarPlanoConta,
        handleDeletePlanoConta,
        handlePutPlanoConta,
      }}
    >
      {children}
    </PlanoContasGeralContext.Provider>
  );
}

export function usePlanoContasGeralContext(): PlanoContasGeralContextProps {
  const context = useContext(PlanoContasGeralContext);

  if (!context)
    throw new Error(
      'usePlanoContasGeralContext must be used within a PlanoContasProvider.'
    );

  return context;
}
