import React, { useRef, memo } from 'react';

import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  ToggleText,
  ToggleIcon,
} from './styles';

interface DropdownItemInterface {
  title: string;
  onClick: () => void;
  selected: boolean;
  disabled?: boolean;
}

interface TabsDropdownProps {
  id: string;
  dropdownItems: DropdownItemInterface[];
}

const TabsDropdown = ({ id, dropdownItems, ...rest }: TabsDropdownProps) => {
  const dropdownToggleRef = useRef() as React.MutableRefObject<HTMLElement>;

  return (
    <Dropdown>
      <DropdownToggle id={id} ref={dropdownToggleRef} {...rest}>
        <ToggleText>
          {dropdownItems.find((item) => item.selected)?.title || ''}
        </ToggleText>
        <ToggleIcon>▼</ToggleIcon>
      </DropdownToggle>
      {dropdownItems && (
        <Dropdown.Menu>
          {dropdownItems.map(({ title, disabled, selected, onClick }) => {
            return (
              <DropdownItem
                key={title}
                onClick={disabled ? undefined : onClick}
                disabled={disabled}
                selected={selected}
              >
                {title}
              </DropdownItem>
            );
          })}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

export default memo(TabsDropdown);
