import React, { forwardRef, memo } from 'react';
import { Controller, FieldError } from 'react-hook-form';

import ContainerInputPadrao, {
  CampoContainerPadraoProps,
} from 'components/Layout/CampoContainer/CampoContainerPadrao';
import { InputControl } from '../styles';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    CampoContainerPadraoProps {
  id: string;
  label?: string;
  error?: FieldError | null;
  infoText?: string;
  control: any;
  saveAsUpperCase?: boolean;
}

export default memo(
  forwardRef<HTMLInputElement, InputProps>(
    ({
      id,
      label,
      error = null,
      required,
      infoText,
      linkAction,
      control,
      saveAsUpperCase = false,
      ...rest
    }) => {
      return (
        <ContainerInputPadrao
          id={id}
          label={label}
          error={error}
          required={required}
          infoText={infoText}
          linkAction={linkAction}
        >
          <Controller
            name={id}
            control={control}
            render={({ field: { onChange, value, ref } }) => (
              <InputControl
                id={id}
                ref={ref}
                value={value}
                required={required}
                onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                  e.currentTarget.select();
                  if (onChange) onChange(e);
                }}
                onBlur={(e: any) => {
                  e.target.value = e.target.value.trim();
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const newValue = e.target.value;
                  if (saveAsUpperCase) {
                    onChange(newValue.toUpperCase());
                    return;
                  }
                  onChange(newValue);
                }}
                {...rest}
              />
            )}
          />
        </ContainerInputPadrao>
      );
    }
  )
);
