import { useCallback, useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { getCredenciamentoZoop } from 'services/zoop';
import { enumStatusCredenciamento } from 'constants/enum/enumStatusCredenciamento';

type DadosCredenciamento = {
  dateCredenciamento?: Date;
  credenciamentoSolicitado: boolean;
  credenciamentoAprovado: boolean;
  statusCredenciamento: string;
};

export const useZoopData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dadosCredenciamento, setDadosCredenciamento] = useState(
    {} as DadosCredenciamento
  );
  const [listItemsInfo, setListItemsInfo] = useState<string[]>([
    `Para acessar o painel de configurações da integração, clique em: Integrações > Vendas > Zoop Recebimentos`,
    'Para configurar taxas e juros de boletos, acesse a aba Configurações dentro do painel de configurações da integração.',
    'Para outras dúvidas ou problemas, acesse nossa central de ajuda ou entre em contato com nossa equipe técnica.',
  ]);

  const obterCredenciamento = useCallback(async () => {
    setIsLoading(true);
    const response = await getCredenciamentoZoop();

    if (response) {
      if (response?.sucesso && response?.dados) {
        if (response?.avisos) {
          response.avisos?.forEach((aviso) => toast.warning(aviso));
        }
        const {
          credenciamentoSolicitado,
          credenciamentoAprovado,
          dataCredenciamento,
          contaBancariaVinculada,
          statusCredenciamento,
        } = response?.dados;

        setDadosCredenciamento({
          dateCredenciamento: dataCredenciamento,
          credenciamentoSolicitado,
          credenciamentoAprovado,
          statusCredenciamento:
            statusCredenciamento || enumStatusCredenciamento.PENDING,
        });

        if (!contaBancariaVinculada) {
          setListItemsInfo([
            `Para acessar o painel de configurações da integração, clique em: Integrações > Vendas > Zoop Recebimentos`,
            'Para configurar taxas e juros de boletos, acesse a aba Configurações dentro do painel de configurações da integração.',
            'Você ainda não vinculou uma conta bancária à sua conta Zoop. Para vincular uma conta bancária,  acesse a aba Conta bancária dentro do painel de configurações da integração.',
            'Para outras dúvidas ou problemas, acesse nossa central de ajuda ou entre em contato com nossa equipe técnica.',
          ]);
        }

        setIsLoading(false);
        return;
      }
    }
    setDadosCredenciamento({
      credenciamentoAprovado: false,
      credenciamentoSolicitado: false,
      statusCredenciamento: enumStatusCredenciamento.PENDING,
    });

    setIsLoading(false);
  }, []);

  useEffect(() => {
    obterCredenciamento();
  }, [obterCredenciamento]);

  return {
    listItemsInfo,
    isLoading,
    dadosCredenciamento,
  };
};
