import { toast } from 'react-toastify';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

const obterPrecoProduto = async (
  produtoCorTamanhoId: string,
  tabelaPrecoId: string
): Promise<number | undefined> => {
  const routeWebService = ConstanteEnderecoWebservice.TABELA_PRECO_OBTER_PRECO_PRODUTO.replace(
    'id',
    tabelaPrecoId
  ).replace('variacaoId', produtoCorTamanhoId);

  const response = await api.get<void, ResponseApi<number>>(routeWebService);

  if (response?.avisos) {
    response.avisos.map((item: string) => toast.warning(item));
  }

  if (response?.sucesso) {
    return response.dados;
  }

  return undefined;
};

export default obterPrecoProduto;
