import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useBreakpointValue,
  Tabs,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Flex,
  Icon,
} from '@chakra-ui/react';

import { IconSeta } from 'icons';
import { ValidarPermissao } from 'components/update/Validacao/ValidarPermissao';

type TabTypeProps = {
  eventKey: number;
  title: string;
  content: string | React.ReactNode;
  disabled?: boolean;
  hidden?: boolean;
  tabWidth?: string | number;
  funcionalidade?: string;
  widthTab?: string;
  onClick?: () => void;
  padding?: string;
};

type TabPadraoProps = {
  tabs: TabTypeProps[];
  defaultIndex?: number;
  isAlterarTab?: boolean;
};

export type TabProps = {
  label: string;
  value: number;
};

export const defaultTab = {
  label: '',
  value: 0,
};

export type TabForwardRefData = {
  handleAtualizarTab: (valueTab: number) => void;
};

export const TabPadrao = forwardRef<TabForwardRefData, TabPadraoProps>(
  ({ tabs, defaultIndex = 0, isAlterarTab = false }, ref) => {
    const [activeKey, setActiveKey] = useState(0);

    const isMobileBreakpoint = useBreakpointValue({
      base: true,
      md: false,
      lg: false,
    });

    const listTabs = tabs.filter((tab) => !tab.hidden);

    const nomeTabSelecionado = useCallback(() => {
      return listTabs.find((tab) => tab.eventKey === activeKey);
    }, [activeKey, listTabs])();

    const atualizarTab = (value: number) => {
      let tabActive = value;
      if (!isMobileBreakpoint) {
        tabActive = listTabs.findIndex((item) => item.eventKey === value);
      }
      setActiveKey(tabActive);
    };

    useImperativeHandle(ref, () => ({
      handleAtualizarTab: atualizarTab,
    }));

    useEffect(() => {
      setActiveKey(0);
    }, [isMobileBreakpoint]);

    return (
      <Tabs
        index={activeKey}
        defaultIndex={defaultIndex}
        onChange={(value: number) => {
          setActiveKey(value);
        }}
        variant={isAlterarTab ? 'outline' : 'enclosed'}
        position="relative"
        w="full"
      >
        {isMobileBreakpoint ? (
          <>
            <TabList
              paddingTop="13px"
              pl="23px"
              h="60px"
              pb={isMobileBreakpoint ? '10px' : undefined}
              borderRadius={isAlterarTab ? '0' : '6px 6px 0 0'}
              borderBlockEnd={
                isMobileBreakpoint ? 'none' : `1px solid #5502b260`
              }
              mb="0"
              w={isAlterarTab ? 'full' : ''}
              bg={isAlterarTab ? 'primary.500' : '#00000034'}
            >
              <Menu>
                <MenuButton>
                  <Flex
                    color={isAlterarTab ? 'white' : 'black'}
                    justifyContent="left"
                    alignItems="center"
                  >
                    {nomeTabSelecionado?.title}
                    <Icon
                      transform="rotate(-90deg)"
                      fontSize="12px"
                      ml="10px"
                      as={IconSeta}
                    />
                  </Flex>
                </MenuButton>
                <MenuList>
                  {tabs
                    .filter(
                      (valorTab) =>
                        valorTab.eventKey !== nomeTabSelecionado?.eventKey &&
                        !valorTab.hidden
                    )
                    .map((tab) => (
                      <ValidarPermissao funcionalidade={tab.funcionalidade}>
                        {({ permitido }) => (
                          <MenuItem
                            isDisabled={!permitido}
                            onClick={() => {
                              if (tab.onClick) {
                                tab.onClick();
                              }
                              setActiveKey(tab.eventKey);
                            }}
                          >
                            {tab.title}
                          </MenuItem>
                        )}
                      </ValidarPermissao>
                    ))}
                </MenuList>
              </Menu>
            </TabList>
            <TabPanels
              borderRight="1px solid #00000034"
              borderLeft="1px solid #00000034"
              borderBottom="1px solid #00000034"
              borderRadius="0 0 6px 6px"
              w="full"
              p={['0', '10px', '20px']}
              bg={isAlterarTab ? 'gray.50' : 'white'}
            >
              {tabs.map((tab) => {
                return (
                  <TabPanel w="full" key={tab.eventKey}>
                    {tab.content}
                  </TabPanel>
                );
              })}
            </TabPanels>
          </>
        ) : (
          <>
            <TabList
              paddingTop="13px"
              pl={isAlterarTab ? '30px' : '23px'}
              h="60px"
              pb={isMobileBreakpoint ? '10px' : undefined}
              borderRadius={isAlterarTab ? '0' : '6px 6px 0 0'}
              borderBlockEnd={
                isMobileBreakpoint ? 'none' : `1px solid #5502b260`
              }
              mb="0"
              w={isAlterarTab ? 'full' : ''}
              bg={isAlterarTab ? 'primary.500' : '#00000034'}
            >
              {tabs.map((tab) => {
                if (tab.hidden) return null;
                return (
                  <ValidarPermissao funcionalidade={tab.funcionalidade}>
                    {({ permitido }) => (
                      <Tab
                        key={tab.eventKey}
                        onClick={() => {
                          if (tab.onClick) {
                            tab.onClick();
                          }
                        }}
                        isDisabled={!permitido || tab.disabled}
                        border={isAlterarTab ? 'none' : '1px solid #5502b260'}
                        color={isAlterarTab ? 'white' : 'black'}
                        w={tab.tabWidth ? tab.tabWidth : '150px'}
                        _before={{
                          boxShadow: 'none !important',
                        }}
                        _after={{
                          boxShadow: 'none !important',
                        }}
                        fontSize="14px"
                        fontWeight={
                          tab.eventKey === activeKey ? 'bold' : 'normal'
                        }
                      >
                        {tab.title}
                      </Tab>
                    )}
                  </ValidarPermissao>
                );
              })}
            </TabList>

            <TabPanels
              borderRight="1px solid #00000034"
              borderLeft="1px solid #00000034"
              borderBottom="1px solid #00000034"
              borderRadius="0 0 6px 6px"
              w="full"
              bg={isAlterarTab ? 'gray.50' : 'white'}
              p={isAlterarTab ? '15' : '0'}
            >
              {tabs.map((tab) => {
                if (tab.hidden) return null;
                return (
                  <TabPanel
                    w="full"
                    borderRadius="0 0 6px 6px"
                    padding={tab.padding || '24px'}
                    key={tab.eventKey}
                  >
                    {tab.content}
                  </TabPanel>
                );
              })}
            </TabPanels>
          </>
        )}
      </Tabs>
    );
  }
);
