import {
  Box,
  Flex,
  Icon,
  Text,
  Tr,
  Td,
  Tooltip,
  Checkbox,
} from '@chakra-ui/react';

import { DecimalMask } from 'helpers/format/fieldsMasks';
import ConstanteFuncionalidades from 'constants/permissoes';
import { setDateMinHours } from 'helpers/data/setHoursDate';
import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';

import {
  FinanceiroParcial,
  ContaReceber,
  ContaReceberContaVencida,
  ContaPaga,
  ContaReceberVencimentoHoje,
  FinanceiroParcialEContaVencida,
} from 'icons';
import {
  ActionMenuItem,
  ActionsMenu,
} from 'components/update/Table/ActionsMenu';

import { formatDate } from 'helpers/format/formatStringDate';
import { Conta } from '../validationForm';
import { ModalDetalheContasAReceber } from '../ModalDetalheContasAReceber';
import { InformacaoStatusVencimento } from '../types';
import { BaixarContaItem, BaixarContaList } from '../Compartilhar';

const informacoesStatusVencimento: {
  [key: string]: InformacaoStatusVencimento;
} = {
  contaReceber: {
    corFundo: 'white',
    corFonte: 'gray.700',
    corTooltip: 'gray.700',
    icone: ContaReceber,
    tamanhoTooltip: '32',
    descricaoConta: 'Conta a receber',
  },
  contaPaga: {
    corFonte: 'gray.700',
    corFundo: 'aquamarine.50',
    corTooltip: 'teal.600',
    icone: ContaPaga,
    descricaoConta: 'Conta recebida',
    tamanhoTooltip: '36',
  },
  pagamentoParcial: {
    corFundo: 'white',
    corFonte: 'gray.700',
    corTooltip: 'gray.700',
    icone: FinanceiroParcial,
    descricaoConta: 'Recebimento parcial',
    tamanhoTooltip: '40',
  },
  contaVencida: {
    corFundo: 'red.50',
    corFonte: 'gray.700',
    corTooltip: 'red.600',
    tamanhoTooltip: '28',
    icone: ContaReceberContaVencida,
    descricaoConta: 'Conta vencida',
  },
  vencimentoHoje: {
    corFundo: 'white',
    corFonte: 'gray.700',
    corTooltip: 'orange.500',
    icone: ContaReceberVencimentoHoje,
    tamanhoTooltip: '32',
    descricaoConta: 'Conta vence hoje',
  },
  contaVencidaEPagaParcialmente: {
    corFundo: 'red.50',
    corFonte: 'gray.700',
    corTooltip: 'red.600',
    tamanhoTooltip: '40',
    icone: FinanceiroParcialEContaVencida,
    descricaoConta: 'Recebimento parcial',
  },
};

type ContasReceberItemProps = {
  item: Conta;
  index: number;
  handleBaixarConta: () => void;
  handleToggleLinhaProduto: (index: number) => void;
  handleEditarConta: (id: string) => void;
  handleExcluirConta: () => void;
  handleCancelarBaixa: (movimentacaoFinanceiraId: string) => Promise<void>;
  handleCompartilharContaBaixada: (
    dados: BaixarContaItem | BaixarContaList
  ) => void;
  showOriginalValue: boolean;
};

export function ContasReceberItem({
  item,
  index,
  handleToggleLinhaProduto,
  handleEditarConta,
  handleBaixarConta,
  handleExcluirConta,
  handleCancelarBaixa,
  handleCompartilharContaBaixada,
  showOriginalValue,
}: ContasReceberItemProps) {
  // caso seja necessário a criação de mais alguma variável que dita um valor de movimentações, apenas ampliar o retorno e este então será atribuído ao "obterTotais"
  const obterTotais = (() => {
    const valorRecebido = item.movimentacaoFinanceiraBaixa.reduce(
      (acc, totais) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return acc + totais.valor!;
      },
      0
    );
    return { valorRecebido };
  })();

  const totalAReceber = item.juros + item.multa;
  const valorAReceber = item.valor + totalAReceber;
  const valorDaConta =
    totalAReceber + (showOriginalValue ? item.valorOriginal : item.valor);

  const contaTemMultaEJuros = item.multa || item.juros || item.desconto;

  const contaEstaPaga =
    item.movimentacaoFinanceiraBaixa.length > 0 && !item.pagamentoParcial;

  const dataHoje = new Date();
  const dataHojeSemHoras = setDateMinHours(new Date());

  const dataVencimento = new Date(item.dataVencimento);
  const dataVencimentoSemHoras = setDateMinHours(new Date(item.dataVencimento));
  const possuiPagamentoParcial = item.pagamentoParcial;

  const vencidaEComPagamentoParcial =
    item.pagamentoParcial && dataVencimento < dataHoje;

  const informacaoStatusVencimento = (() => {
    if (vencidaEComPagamentoParcial) {
      return informacoesStatusVencimento.contaVencidaEPagaParcialmente;
    }

    if (contaEstaPaga) {
      return informacoesStatusVencimento.contaPaga;
    }

    if (possuiPagamentoParcial) {
      return informacoesStatusVencimento.pagamentoParcial;
    }

    if (dataVencimentoSemHoras.getTime() === dataHojeSemHoras.getTime()) {
      return informacoesStatusVencimento.vencimentoHoje;
    }

    if (dataVencimento < dataHoje) {
      return informacoesStatusVencimento.contaVencida;
    }

    return informacoesStatusVencimento.contaReceber;
  })();

  async function handleAbrirModalDetalhe() {
    await ModalDetalheContasAReceber({
      id: item.movimentacaoFinanceiraId,
      informacaoStatusVencimento,
      casasDecimaisValor: 2,
      valorAReceber,
    });
  }

  const validarTipoOperacao = () => {
    if (item.tipoOperacao === IdentificacaoTipoOperacaoEnum.CONTAS_RECEBER) {
      return true;
    }
    if (item.tipoOperacao === IdentificacaoTipoOperacaoEnum.CONTAS_PAGAR) {
      return true;
    }

    return false;
  };

  const tipoOperacaoContasPagarReceber = validarTipoOperacao();

  const dropDownItens = ((): ActionMenuItem[] => {
    const baixarContaItem = {
      content: 'Baixar conta',
      onClick: () => {
        handleBaixarConta();
      },
      funcionalidade: ConstanteFuncionalidades.CONTAS_RECEBER_BAIXA,
    };

    const compartilharContaBaixada = {
      content: 'Compartilhar conta',
      onClick: async () => {
        await handleCompartilharContaBaixada(item);
      },
    };

    const cancelarBaixaItem = {
      content: 'Cancelar baixa',
      onClick: () => {
        handleCancelarBaixa(item.movimentacaoFinanceiraId);
      },
      funcionalidade: ConstanteFuncionalidades.CONTAS_RECEBER_CANCELARBAIXA,
    };
    const verDetalhesContaItem = {
      content: 'Ver detalhes',
      onClick: handleAbrirModalDetalhe,
    };

    const baixarContaCancelarBaixaItem =
      item.tipoOperacao === IdentificacaoTipoOperacaoEnum.CONTAS_RECEBER ||
      IdentificacaoTipoOperacaoEnum.VENDA
        ? [...(contaEstaPaga ? [cancelarBaixaItem] : [baixarContaItem])]
        : contaEstaPaga
        ? []
        : [baixarContaItem];

    const removerItem = [
      {
        content: 'Remover',
        onClick: () => {
          handleExcluirConta();
        },
        funcionalidade: ConstanteFuncionalidades.CONTAS_RECEBER_EXCLUIR,
      },
    ];

    const editarContas = [
      {
        content: 'Editar',
        onClick: () => {
          handleEditarConta(item.operacaoId);
        },
        funcionalidade: ConstanteFuncionalidades.CONTAS_RECEBER_ALTERAR,
      },
    ];

    const contaReceberOperacao =
      item.tipoOperacao === IdentificacaoTipoOperacaoEnum.CONTAS_RECEBER ||
      IdentificacaoTipoOperacaoEnum.VENDA
        ? [
            ...(contaEstaPaga
              ? [compartilharContaBaixada]
              : item.pagamentoParcial
              ? [cancelarBaixaItem, compartilharContaBaixada]
              : tipoOperacaoContasPagarReceber
              ? [...editarContas, ...removerItem]
              : []),
          ]
        : [];

    return [
      ...baixarContaCancelarBaixaItem,
      verDetalhesContaItem,
      ...contaReceberOperacao,
    ];
  })();

  return (
    <>
      <Box h={index === 0 ? '0' : '2'} />

      <Tr
        boxShadow="none !important"
        sx={{
          '& > td': {
            bg: informacaoStatusVencimento.corFundo,
            height: '72px',
          },
        }}
      >
        <Td>
          <Checkbox
            isChecked={item.selecionado}
            onChange={() => handleToggleLinhaProduto(index)}
            boxShadow="inset 0px 3px 6px #00000029"
            colorScheme="primary"
            bg="white"
            mb="0"
          />
        </Td>

        <Td>
          <Text fontSize="sm" color={informacaoStatusVencimento.corFonte}>
            {formatDate(item.dataVencimento)}
          </Text>

          <Text mt="1" fontSize="xs">
            {`Parcela: ${item.parcela}`}
          </Text>
        </Td>

        <Td whiteSpace="nowrap">
          <Text fontSize="sm" color={informacaoStatusVencimento.corFonte}>
            {item.clienteFornecedorRazaoSocial}
          </Text>

          <Text mt="1" fontSize="xs">
            {item.clienteFornecedorNome}
          </Text>
        </Td>

        <Td whiteSpace="nowrap">
          <Text fontSize="sm" color={informacaoStatusVencimento.corFonte}>
            {item.formaRecebimento}
          </Text>

          <Text mt="1" fontSize="xs">
            {item.planoContas}
          </Text>
        </Td>

        <Td
          whiteSpace="nowrap"
          color={informacaoStatusVencimento.corFonte}
          fontSize="sm"
          background="red"
          textAlign="right"
        >
          <Text fontWeight={contaTemMultaEJuros ? 'bold' : 'regular'}>
            <Tooltip
              borderRadius="md"
              w="44"
              alignItems="center"
              h="28"
              display="flex"
              justifyContent="left"
              placement="left"
              gutter={15}
              hasArrow
              fontSize="2xs"
              bg="gray.900"
              color="white"
              label={
                <Box ml="2">
                  <Flex alignItems="center">
                    <Text mr="2">Valor original:</Text>
                    <Text fontSize="xs">
                      {DecimalMask(item.valorOriginal, 2, 2)}
                    </Text>
                  </Flex>
                  {item.movimentacaoFinanceiraBaixa.length > 0 &&
                  !item.pagamentoParcial ? (
                    <Flex mt="1" alignItems="center">
                      <Text mr="2">Desconto:</Text>
                      <Text fontSize="xs" color="yellow.100">
                        {DecimalMask(item.desconto, 2, 2)}
                      </Text>
                    </Flex>
                  ) : null}
                  <Flex mt="1" alignItems="center">
                    <Text mr="2">Multa:</Text>
                    <Text fontSize="xs" color="red.100">
                      {DecimalMask(item.multa, 2, 2)}
                    </Text>
                  </Flex>
                  <Flex mt="1" alignItems="center">
                    <Text mr="2">Juros:</Text>
                    <Text fontSize="xs" color="red.100">
                      {DecimalMask(item.juros, 2, 2)}
                    </Text>
                  </Flex>
                  <Flex mt="1" alignItems="center">
                    <Text mr="2">Valor recebido:</Text>
                    <Text fontSize="xs" color="red.100">
                      {DecimalMask(obterTotais.valorRecebido, 2, 2)}
                    </Text>
                  </Flex>
                </Box>
              }
            >
              {`R$ ${DecimalMask(valorDaConta, 2, 2)}`}
            </Tooltip>
          </Text>
        </Td>

        <Tooltip
          borderRadius="md"
          w={informacaoStatusVencimento.tamanhoTooltip}
          alignItems="center"
          h="40px"
          display="flex"
          justifyContent="center"
          placement="left"
          hasArrow
          fontSize="sm"
          bg={informacaoStatusVencimento.corTooltip}
          label={informacaoStatusVencimento.descricaoConta}
        >
          <Td fontSize={20}>
            <Icon as={informacaoStatusVencimento.icone} />
          </Td>
        </Tooltip>

        <Td>
          <ActionsMenu items={dropDownItens} />
        </Td>
      </Tr>
    </>
  );
}
