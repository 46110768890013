import React, { useState } from 'react';
import {
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Flex,
  Button,
  useMediaQuery,
  Heading,
  Text,
  Center,
  Checkbox,
} from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AtencaoAvisoIcon } from 'icons';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import ManterFoco from 'components/Geral/ManterFoco';
import Textarea from 'components/PDV/Textarea';

import { yupResolver } from './validationForm';

type FormData = {
  motivoCancelamento: string;
  motivoCancelamentoNotaFiscal?: string;
};

interface ModalMotivoCancelamentoProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (
    motivoCancelamento: string,
    motivoCancelamentoNotaFiscal?: string
  ) => void;
  hasNotaFiscal: boolean;
}

const ModalMotivoCancelamento = ({
  isOpen,
  onClose,
  onSubmit,
  hasNotaFiscal,
}: ModalMotivoCancelamentoProps) => {
  const [motivoIsRepetido, setMotivoIsRepetido] = useState(false);
  const { t } = useTranslation();

  const [menorQue700] = useMediaQuery('(max-width: 700px)');

  const formMethods = useForm({ resolver: yupResolver(hasNotaFiscal) });

  const { getValues, setValue } = formMethods;

  const handleOnSubmit = formMethods.handleSubmit<FormData>(async (data) => {
    onSubmit(data.motivoCancelamento, data.motivoCancelamentoNotaFiscal);

    onClose();
  });

  const handleGetMotivoCancelamento = () => {
    if (!motivoIsRepetido) {
      const { motivoCancelamento } = getValues();

      setValue('motivoCancelamentoNotaFiscal', motivoCancelamento);
    } else {
      setValue('motivoCancelamentoNotaFiscal', '');
    }
    setMotivoIsRepetido(!motivoIsRepetido);
  };

  return (
    <ModalPadraoChakra onClose={onClose} isOpen={isOpen} isCentered size="xl">
      <ModalContent
        marginBottom={{ base: 0, sm: '3.75rem' }}
        marginTop={{ base: 0, sm: '3.75rem' }}
        h="unset"
        maxW={menorQue700 ? undefined : '600px'}
      >
        <ManterFoco
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <ModalCloseButton color="white" />
          <ModalBody p={0} h="100%">
            <FormProvider {...formMethods}>
              <Flex flexDirection="column" align="stretch" h="100%">
                <Flex
                  justifyContent="center"
                  h="150px"
                  bg="red.400"
                  style={{
                    borderTopLeftRadius: '5px',
                    borderTopRightRadius: '5px',
                  }}
                >
                  <AtencaoAvisoIcon
                    style={{
                      fontSize: '75px',
                      fontWeight: 'bolder',
                      marginTop: '35px',
                      color: 'white',
                    }}
                  />
                </Flex>

                <Flex
                  justifyContent="flex-start"
                  flexDirection="column"
                  bg="gray.50"
                  px={8}
                  py={7}
                >
                  <Heading
                    as="h2"
                    fontSize="2xl"
                    color="red.500"
                    fontWeight="semibold"
                    textAlign="initial"
                  >
                    {!hasNotaFiscal
                      ? t('Cancelar operação?')
                      : t('Cancelar venda?')}
                  </Heading>

                  <Text mt={15} fontSize="sm" color="gray.700">
                    {t(
                      'A operação será cancelada e não poderá ser desfeita. Deseja continuar? Informe no campo abaixo o motivo do cancelamento para registro do sistema.'
                    )}
                  </Text>

                  <Textarea
                    id="motivoCancelamento"
                    name="motivoCancelamento"
                    placeholder="Descreva o motivo"
                    maxLength={255}
                    mt={3}
                    mb={4}
                    bg="white"
                    autoFocus
                  />

                  {hasNotaFiscal && (
                    <>
                      <Flex alignItems="center" justifyContent="space-between">
                        <Text>
                          Informe o motivo para cancelar a Nota Fiscal*
                        </Text>
                        <Flex alignItems="center">
                          <Text
                            mt={2}
                            mb={1}
                            mr="5px"
                            colorScheme="primary"
                            color="gray.700"
                          >
                            Repetir motivo
                          </Text>
                          <Checkbox
                            isChecked={motivoIsRepetido}
                            onChange={handleGetMotivoCancelamento}
                            colorScheme="primary"
                            mb="0"
                            size="sm"
                          />
                        </Flex>
                      </Flex>
                      <Textarea
                        mt="-2px"
                        id="motivoCancelamentoNotaFiscal"
                        name="motivoCancelamentoNotaFiscal"
                        placeholder="Informe o motivo que será registrado na NF"
                        bg="white"
                        minLength={15}
                        maxLength={255}
                      />
                    </>
                  )}
                  <Center>
                    <Flex justifyContent="flex" mt="4">
                      <Button variant="outline" maxW="120px" onClick={onClose}>
                        Voltar
                      </Button>
                      <Button
                        variant="solid"
                        colorScheme="red"
                        maxW="200px"
                        onClick={handleOnSubmit}
                        ml="4"
                      >
                        Sim, cancelar
                      </Button>
                    </Flex>
                  </Center>
                </Flex>
              </Flex>
            </FormProvider>
          </ModalBody>
        </ManterFoco>
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export default ModalMotivoCancelamento;
