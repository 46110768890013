export const optionsFormaEmissaoNfe = [
  {
    label: 'Normal',
    value: 1,
  },
  {
    label: 'Contingência FS',
    value: 2,
  },
  {
    label: 'Contingência SCAN',
    value: 3,
  },
  {
    label: 'Contingência DPEC',
    value: 4,
  },
  {
    label: 'Contingência FS-DA',
    value: 5,
  },
  {
    label: 'Contingência SVC-AN',
    value: 6,
  },
  {
    label: 'Contingência SVC-RS',
    value: 7,
  },
];
