import { IconPdvOfflineRoxo } from 'icons';

import { Container } from '../../Components/Layout/Container';
import { usePdvOfflineData } from './hooks';

export const DetalhesIntegracaoPdvOffline = () => {
  const {
    dataAtivacaoPdvOffline,
    listaItensInfo,
    isLoading,
    excluirIntegracao,
  } = usePdvOfflineData();

  return (
    <Container
      listItemsInfo={listaItensInfo}
      isLoading={isLoading}
      widthIcon={150}
      heightIcon={150}
      icon={IconPdvOfflineRoxo}
      date={dataAtivacaoPdvOffline}
      handleCancel={excluirIntegracao}
    />
  );
};
