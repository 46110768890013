import { Box, Button, Flex } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { Container } from './Container';
import { useTabelaPreco } from './hooks';

export const TabelaPreco = () => {
  const {
    formMethods,
    listTabelaPadraoSistema,
    listTabelaPreco,
    handleSubmit,
    isLoading,
  } = useTabelaPreco();

  return (
    <FormProvider {...formMethods}>
      {isLoading && <LoadingPadrao />}
      <Box
        mt="24px"
        borderWidth="1px"
        borderRadius="5px"
        borderColor="gray.200"
        bg="gray.50"
      >
        <Box p="24px" overflow="auto" borderRadius="5px" bg="gray.50">
          <Container
            name="tabelaPrecoIdAVista"
            isDisabled
            title="Preco a vista"
            options={listTabelaPadraoSistema}
          />
          <Container
            name="tabelaPrecoIdAPrazo"
            title="Preco a prazo"
            options={listTabelaPreco}
          />
          <Container
            isClearable
            name="tabelaPrecoIdAlternativo"
            title="Preco alternativo"
            options={listTabelaPreco}
          />
        </Box>
      </Box>
      <Flex mt="50px" justifyContent="center" alignItems="center">
        <Box mr="20px">
          <Button
            color="black"
            variant="solid"
            onClick={handleSubmit}
            colorScheme="secondary.400"
            borderRadius="16px"
            width="120px"
          >
            Confirmar
          </Button>
        </Box>
      </Flex>
    </FormProvider>
  );
};
