import { GridItem, Icon, Button, Stack, Text, Flex } from '@chakra-ui/react';

import { useContextConciliacaoContas } from 'store/Financeiro/ConciliacaoContas';

import { EditarMassaIcon, FinanceiroContasPagarIcon } from 'icons';
import { TextValor } from 'components/PDV/Text/TextValor';

import { useListagemContasConcilicao } from '../../hooks';

export const SelectedItemsActions = () => {
  const {
    openModalAnteciparContas,
    openModalAlterarEmMassa,
    isSelectedContasEmpty,
    totalSelectedContas,
    todasContasPodemSerAntecipadas,
    totalValorLiquidoFromSelectedContas,
  } = useContextConciliacaoContas();

  const { toggleContasSelecionadas } = useListagemContasConcilicao();

  return (
    <Stack
      direction={{ base: 'column', sm: 'column', md: 'row' }}
      spacing={{ base: 3, sm: 6, md: 6 }}
      w="full"
      mb="24px"
      mt="17px"
    >
      <GridItem>
        <Button
          onClick={openModalAnteciparContas}
          variant="outlineDefault"
          colorScheme="gray"
          height="36px"
          isDisabled={isSelectedContasEmpty || !todasContasPodemSerAntecipadas}
          leftIcon={<Icon as={FinanceiroContasPagarIcon} mb="2px" />}
          width={['full', 'full', '175px']}
          borderColor="gray.200"
          _disabled={{
            _hover: {
              color: 'gray.500',
            },
          }}
        >
          {totalSelectedContas > 1 ? 'Antecipar contas' : 'Antecipar conta'}
        </Button>
      </GridItem>
      <GridItem>
        <Button
          onClick={openModalAlterarEmMassa}
          variant="solid"
          colorScheme="purple"
          height="36px"
          isDisabled={isSelectedContasEmpty}
          leftIcon={<Icon as={EditarMassaIcon} />}
          width={['full', 'full', '188px']}
        >
          {totalSelectedContas > 1 ? 'Alterar em massa' : 'Alterar conta'}
        </Button>
      </GridItem>
      <GridItem>
        <Flex
          alignItems="center"
          direction={['column', 'row']}
          justifyContent="center"
          mt="8px"
          fontSize="xs"
          gap="24px"
        >
          <Flex
            alignItems="center"
            direction={['column', 'row']}
            fontSize="xs"
            gap="8px"
            justifyContent="center"
          >
            <Text>Total líquido selecionado:</Text>
            <TextValor
              casasDecimais={2}
              valor={totalValorLiquidoFromSelectedContas}
              color="primary.500"
              fontSize="xl"
              fontWeight="semibold"
              symbolFontSize="xs"
              mb="2px"
              symbolFontWeight="semibold"
            />
          </Flex>
          <Text>
            {totalSelectedContas}{' '}
            {totalSelectedContas > 1
              ? 'Itens selecionados'
              : 'Item selecionado'}{' '}
          </Text>
          <Button
            size="xs"
            variant="link"
            colorScheme="red"
            textDecoration="none"
            _hover={{ textDecoration: 'none' }}
            ml="2"
            padding="8px"
            onClick={toggleContasSelecionadas}
          >
            Limpar seleção
          </Button>
        </Flex>
      </GridItem>
    </Stack>
  );
};
