import React, { ReactElement } from 'react';
import {
  Flex,
  Text,
  ColorProps,
  GridItem,
  GridItemProps,
} from '@chakra-ui/react';

interface DetailsRowProps extends GridItemProps {
  title: string;
  titleColor?: ColorProps['color'];
  value?: string | ReactElement;
  valueColor?: ColorProps['color'];
}

const DetailsRow = ({
  title,
  titleColor = 'gray.500',
  value,
  valueColor = 'black',
  noOfLines = undefined,
  ...rest
}: DetailsRowProps) => {
  return (
    <GridItem {...rest}>
      <Flex alignItems="flex-start" flexDir="column" gap="2px">
        <Text
          color={titleColor}
          fontSize="sm"
          lineHeight="shorter"
          fontWeight="normal"
          marginTop="2px"
        >
          {`${title}:`}
        </Text>
        <Text
          color={valueColor}
          fontSize="md"
          lineHeight="shorter"
          fontWeight="semibold"
          noOfLines={noOfLines}
        >
          {value || '----'}
        </Text>
      </Flex>
    </GridItem>
  );
};

export default DetailsRow;
