import { useCallback, useState, useEffect } from 'react';
import { Flex, Link, Text, Box } from '@chakra-ui/react';
import { AiOutlineLeft } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { FaExternalLinkAlt } from 'react-icons/fa';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { enumDiasFuncionamento } from 'constants/enum/enumDiasFuncionamento';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';
import auth from 'modules/auth';

import { RevendaProps } from '../validationForm';

type ChatRevendaProps = {
  voltarMenu: () => void;
};

export const ChatRevenda = ({ voltarMenu }: ChatRevendaProps) => {
  const [revenda, setRevenda] = useState<RevendaProps>({} as RevendaProps);
  const [isLoading, setIsLoading] = useState(false);

  const getDadosRevenda = useCallback(async () => {
    setIsLoading(true);
    const dadosRevenda = auth.getRevendaId();
    if (!dadosRevenda) {
      setIsLoading(false);
      return;
    }

    const response = await api.get<void, ResponseApi<RevendaProps>>(
      ConstanteEnderecoWebservice.REVENDA,
      {
        params: { revendaId: dadosRevenda },
      }
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }
      if (response?.sucesso && response.dados) {
        setRevenda(response.dados);
      }
    }
    setIsLoading(false);
  }, []);

  const setURL = (link: string): string => {
    return link?.includes('://') ? link : `//${link}`;
  };

  useEffect(() => {
    getDadosRevenda();
  }, [getDadosRevenda]);

  return (
    <>
      {isLoading && <LoadingPadrao />}
      <Flex
        gap="14px"
        color="violet.500"
        fontSize="md"
        align="center"
        mb="12px"
        onClick={() => {
          voltarMenu();
        }}
        cursor="pointer"
      >
        <AiOutlineLeft />
        <Text fontSize="md" fontWeight="semibold" color="violet.500">
          Atendimento ao cliente
        </Text>
      </Flex>
      <Flex
        borderTopWidth="1px"
        borderStyle="solid"
        borderColor="gray.200"
        pt="36px"
        pb="14px"
        flexDirection="column"
      >
        <Text>Responsável</Text>
        <Text fontSize="md" fontWeight="bold">
          {revenda?.nomeResponsavel}
        </Text>
      </Flex>
      <Flex
        borderTopWidth="1px"
        borderStyle="solid"
        borderColor="gray.200"
        py="14px"
        flexDirection="column"
      >
        <Text>E-mail</Text>
        <Text fontSize="md" fontWeight="bold">
          {revenda?.email}
        </Text>
      </Flex>
      <Flex
        borderTopWidth="1px"
        borderStyle="solid"
        borderColor="gray.200"
        py="14px"
        flexDirection="column"
      >
        <Text>Telefone</Text>
        <Text fontSize="md" fontWeight="bold">
          {revenda?.telefone}
        </Text>
      </Flex>
      {revenda?.atendimentoOnline && (
        <Flex
          borderTopWidth="1px"
          borderStyle="solid"
          borderColor="gray.200"
          py="14px"
          flexDirection="column"
        >
          <Text>Atendimento online</Text>

          <Link
            fontSize="md"
            color="blue.500"
            display="inline-flex"
            gap="6px"
            justifyContent="flex-start"
            href={setURL(revenda?.atendimentoOnline)}
            isExternal
          >
            {revenda?.atendimentoOnline}
            <Box mt="6px">
              <FaExternalLinkAlt size="12px" />
            </Box>
          </Link>
        </Flex>
      )}
      {revenda?.horariosFuncionamento?.length > 0 && (
        <Flex
          borderTopWidth="1px"
          borderStyle="solid"
          borderColor="gray.200"
          py="14px"
          flexDirection="column"
        >
          <Text>Horário</Text>
          <Text fontSize="md" fontWeight="bold">
            {revenda?.horariosFuncionamento?.map((horario) => {
              if (!horario.aberto) return null;

              const dia =
                enumDiasFuncionamento[
                  horario.dia as keyof typeof enumDiasFuncionamento
                ];

              const abertura = new Date(
                horario.dataHoraAbertura
              )?.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
              });
              const fechamento = new Date(
                horario.dataHoraFechamento
              )?.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
              });
              return (
                <Text key={horario.dia} fontSize="md" fontWeight="bold">
                  {dia}, das {abertura}h às {fechamento}h
                </Text>
              );
            })}
          </Text>
        </Flex>
      )}
    </>
  );
};
