import {
  ModalContent,
  ModalBody,
  useDisclosure,
  ModalHeader,
  Text,
  Flex,
  Divider,
  ModalFooter,
  FormLabel,
  Button,
  GridItem,
  Icon,
} from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { create } from 'react-modal-promise';

import { enumSituacaoBaixa } from 'constants/enum/enumSituacaoBaixa';
import getOptionsByEnum from 'helpers/format/getOptionsByEnum';

import InputDateRange from 'components/PDV/InputDateRange';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import Input from 'components/PDV/Input';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { LupaIcon } from 'icons';

import { FormData, ModalBuscaAvancadaProps } from './validationForms';

import { useBuscaAvancadaModal } from './hooks';

export const ModalBuscaAvancada = create<ModalBuscaAvancadaProps, FormData>(
  ({ currentFilters, clearFilters, onResolve, onReject, ...rest }) => {
    const { isOpen } = useDisclosure({ defaultIsOpen: true });
    const {
      formMethods,
      handlePesquisar,
      onClear,
      listFormaRecebimento,
      isLoading,
    } = useBuscaAvancadaModal({ currentFilters, clearFilters, onResolve });

    return (
      <ModalPadraoChakra
        {...rest}
        isOpen={isOpen}
        onClose={() => {
          onReject();
        }}
        isCentered
        size="4xl"
      >
        <ModalContent
          marginBottom={{ base: 0, sm: '3.75rem' }}
          marginTop={{ base: 0, sm: '3.75rem' }}
          h="auto"
          bg="gray.50"
        >
          {isLoading && <LoadingPadrao />}
          <ModalHeader>
            <Text color="primary.50" fontSize="md">
              Busca avançada
            </Text>
            <Text color="gray.700" fontSize="xs">
              Utilize os filtros de busca para uma pesquisa específica. Todos os
              campos são acumulativos.
            </Text>
          </ModalHeader>
          <Divider
            orientation="horizontal"
            width="calc(100% - 48px)"
            margin="0 auto"
          />

          <ModalBody pb="0" pt="0" mt="12px">
            <SimpleGridForm>
              <FormProvider {...formMethods}>
                <GridItem colSpan={{ base: 12, lg: 6 }}>
                  <FormLabel mb="0" fontSize="sm" color="black">
                    Data de emissão
                  </FormLabel>
                  <InputDateRange
                    isClearable
                    id="dataEmissao"
                    name="dataEmissao"
                    borderColor="gray.100"
                    borderRadius="md"
                    placeholder="dd/mm/aaaa"
                    startDateName="dataEmissaoInicio"
                    endDateName="dataEmissaoFim"
                  />
                </GridItem>
                <GridItem colSpan={{ base: 12, lg: 6 }}>
                  <FormLabel mb="0" fontSize="sm" color="black">
                    Data de vencimento
                  </FormLabel>
                  <InputDateRange
                    isClearable
                    id="dataVencimento"
                    name="dataVencimento"
                    borderColor="gray.100"
                    borderRadius="md"
                    placeholder="dd/mm/aaaa"
                    startDateName="dataVencimentoInicio"
                    endDateName="dataVencimentoFim"
                  />
                </GridItem>
                <GridItem colSpan={{ base: 12, lg: 6 }}>
                  <FormLabel mb="0" fontSize="sm" color="black">
                    Data do pagamento
                  </FormLabel>
                  <InputDateRange
                    isClearable
                    id="dataPagamento"
                    name="dataPagamento"
                    borderColor="gray.100"
                    borderRadius="md"
                    placeholder="dd/mm/aaaa"
                    startDateName="dataPagamentoInicio"
                    endDateName="dataPagamentoFim"
                  />
                </GridItem>
                <GridItem colSpan={{ base: 12, lg: 6 }}>
                  <FormLabel mb="0" fontSize="sm" color="black">
                    Data da compensação
                  </FormLabel>
                  <InputDateRange
                    isClearable
                    name="dataCompensacao"
                    id="dataCompensacao"
                    borderRadius="md"
                    borderColor="gray.100"
                    placeholder="dd/mm/aaaa"
                    startDateName="dataCompensacaoInicio"
                    endDateName="dataCompensacaoFim"
                  />
                </GridItem>
                <GridItem colSpan={{ base: 12, lg: 4 }}>
                  <SelectPadrao
                    label="Forma de recebimento na baixa"
                    id="formaPagamentoBaixaId"
                    placeholder="Selecione o recebimento"
                    isClearable
                    name="formaPagamentoBaixaId"
                    options={listFormaRecebimento}
                  />
                </GridItem>
                <GridItem colSpan={{ base: 12, lg: 4 }}>
                  <SelectPadrao
                    label="Situação"
                    id="conciliado"
                    placeholder="Selecione a situação"
                    isSearchable={false}
                    name="conciliado"
                    options={getOptionsByEnum(enumSituacaoBaixa)}
                  />
                </GridItem>
                <GridItem colSpan={{ base: 12, lg: 4 }}>
                  <Input
                    label="Código de autorização"
                    id="codigoAutorizacao"
                    name="codigoAutorizacao"
                    placeholder="Digite o código de autorização"
                  />
                </GridItem>
              </FormProvider>
            </SimpleGridForm>
          </ModalBody>

          <ModalFooter flexDirection="column" mt={6}>
            <Divider orientation="horizontal" mb="16px" />
            <Flex
              width="100%"
              justifyContent={['center', 'center', 'center']}
              gap={['6px', '24px', '24px']}
            >
              <Button
                variant="cancelOutline"
                colorScheme="gray"
                borderRadius="full"
                px="12px"
                onClick={() => onReject()}
              >
                Cancelar
              </Button>
              <Button
                variant="outlineDefault"
                colorScheme="gray"
                px="12px"
                onClick={onClear}
              >
                Limpar filtros
              </Button>
              <Button
                variant="solid"
                colorScheme="aquamarine"
                leftIcon={<Icon as={LupaIcon} />}
                px="12px"
                onClick={handlePesquisar}
              >
                Pesquisar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
