export type TipoConferencia = 1 | 2;

const TipoConferenciaEnum = {
  COMPLETA: 1,
  COM_FILTROS: 2,
  ZERAR_ESTOQUE: 3,

  // properties: {
  //   1: { name: 'Conferência completa', value: 1 },
  //   2: { name: 'Conferência com filtros', value: 2 },
  // },
};

export default TipoConferenciaEnum;
