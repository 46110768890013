import React, { useCallback, useEffect, useState } from 'react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';
import { OptionsType } from 'react-select';
import { toast } from 'react-toastify';
import {
  Text,
  ModalContent,
  ModalHeader,
  ModalBody,
  GridItem,
  ModalFooter,
  Button,
  ModalProps,
  useDisclosure,
} from '@chakra-ui/react';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import CreatableSelectField from 'components/PDV/Select/CreatableSelect';
import { emailIsValid } from 'components/PDV/CompartilharDocumentosFiscais/EmailModal/validationForm';

import OptionType from 'types/optionType';

import { yupResolver, FormData, defaultValues } from './validationForm';

interface ModalCadastroResponse {
  sucesso: boolean;
}

interface ModalCadastroProps
  extends Omit<ModalProps, 'children' | 'onClose' | 'isOpen'>,
    InstanceProps<ModalCadastroResponse> {
  anos: OptionType[];
  meses: OptionType[];
  title?: string;
}

const ModalCadastroComponent = ({
  anos,
  meses,
  onResolve,
  title = 'Adicionar exportação',
}: ModalCadastroProps) => {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues,
  });
  const { handleSubmit: onSubmit, setValue } = formMethods;

  const [listEmail, setListEmail] = useState<OptionsType<OptionType>>([]);

  const handleSubmit = onSubmit(async (data) => {
    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    const currentYear = today.getFullYear();

    const dateIsEqualsCurrent =
      data.mes === currentMonth && data.ano === currentYear;

    const dateIsUpperCurrent =
      data.mes > currentMonth
        ? data.ano >= currentYear
        : data.ano > currentYear;

    if (dateIsEqualsCurrent || dateIsUpperCurrent) {
      toast.warning(
        'Não é possível cadastrar uma exportação para uma data igual ou posterior a atual.'
      );
      return;
    }
    const newListEmail = data.email.split(',');

    const response = await api.post<void, ResponseApi>(
      ConstanteEnderecoWebservice.DOCUMENTO_FISCAL_EXPORTACAO_CADASTRAR,
      { ...data, email: newListEmail }
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response?.sucesso) {
        toast.success('O cadastro foi realizado com sucesso.');

        onResolve({ sucesso: true });
        onClose();
      }
    }
  });

  const getEmailContabilista = useCallback(async () => {
    const response = await api.get<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.LOJA_OBTER_EMAIL_CONTABILISTA
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response?.sucesso && response?.dados) {
        const email = {
          label: response.dados,
          value: response.dados,
        } as OptionType;

        setListEmail([email]);
        setValue('email', response.dados);
      }
    }
  }, [setValue]);

  useEffect(() => {
    if (isOpen) {
      getEmailContabilista();
    }
  }, [getEmailContabilista, isOpen]);

  return (
    <>
      <ModalPadraoChakra isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalContent px="16px">
          <ModalHeader textColor="gray.700" pt="24px">
            {title && <Text color="primary.50">{title}</Text>}
          </ModalHeader>
          <ModalBody>
            <FormProvider {...formMethods}>
              <SimpleGridForm>
                <GridItem colSpan={{ base: 12, sm: 6, md: 6 }}>
                  <SelectPadrao
                    id="mes"
                    name="mes"
                    label="Mês"
                    placeholder="Selecione"
                    options={meses}
                  />
                </GridItem>
                <GridItem colSpan={{ base: 12, sm: 6, md: 6 }}>
                  <SelectPadrao
                    id="ano"
                    name="ano"
                    label="Ano"
                    placeholder="Selecione"
                    options={anos}
                  />
                </GridItem>
                <GridItem colSpan={12}>
                  <CreatableSelectField
                    id="email"
                    name="email"
                    label="E-mail"
                    options={listEmail}
                    placeholder="Adicione os e-mails para enviar o link da exportação"
                    creatableInputTextPreffix="Adicionar e-mail"
                    handleCreateOption={async (value) => {
                      const success = emailIsValid(value);

                      if (success) {
                        const newEmail = {
                          label: value,
                          value,
                        };

                        setListEmail((prev) => [...prev, newEmail]);
                        return newEmail;
                      }

                      toast.warning(
                        'E-mail informado está em um formato inválido.'
                      );

                      return undefined;
                    }}
                    isMulti
                    required
                  />
                </GridItem>
              </SimpleGridForm>
            </FormProvider>
          </ModalBody>
          <ModalFooter
            gap={{ base: 4, md: 6 }}
            justifyContent="center"
            py="40px"
          >
            <Button
              id="cancelarDocumentoExportacao"
              name="cancelarDocumentoExportacao"
              variant="cancel"
              colorScheme="red"
              minW="min-content"
              width={{ base: 'full', sm: '100px' }}
              fontWeight="normal"
              color="gray.500"
              borderColor="gray.500"
              onClick={onClose}
            >
              Cancelar
            </Button>
            <Button
              id="salvarDocumentoExportacao"
              name="salvarDocumentoExportacao"
              colorScheme="secondary"
              minW="min-content"
              width={{ base: 'full', sm: '120px' }}
              fontWeight="normal"
              onClick={handleSubmit}
            >
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    </>
  );
};

export const ModalCadastro = create<ModalCadastroProps, ModalCadastroResponse>(
  ModalCadastroComponent
);
