import React, { useRef, useEffect } from 'react';

import { useLayoutContext } from 'store/Layout';
import {
  ExtratoContext,
  ExtratoProvider,
} from 'store/Financeiro/Extrato/index';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

import { ExtratoListar } from '../pages/Extrato/Listar';
import { ExtratoDetalhes } from '../pages/Extrato/Detalhes';
import {
  CadastrarLancamentoContas,
  TipoOperacaoProps,
} from '../pages/LancamentoContas/Formulario/Cadastrar';

export const ExtratoRoutes = [
  <ExtratoProvider>
    <ExtratoContext.Consumer>
      {({ idRota }) => (
        <>
          <LayoutGuard
            key="extrato-listar"
            permissaoFuncionalidade={ConstanteFuncionalidades.EXTRATO_LISTAR}
            component={ExtratoListar}
            breadcrumb={[
              {
                title: 'Financeiro',
              },
              {
                title: 'Extratos',
              },
            ]}
            path={ConstanteRotas.EXTRATOS}
            exact
            meta={{ auth: true }}
          />
          <LayoutGuard
            key="extrato-detalhes"
            permissaoFuncionalidade={ConstanteFuncionalidades.EXTRATO_LISTAR}
            component={ExtratoDetalhes}
            breadcrumb={[
              {
                title: 'Financeiro',
              },
              {
                title: 'Extratos',
                path: ConstanteRotas.EXTRATOS,
              },
              {
                title: 'Detalhes',
              },
            ]}
            path={ConstanteRotas.EXTRATOS_DETALHES}
            exact
            meta={{ auth: true }}
          />
          <LayoutGuard
            key="extrato-lancamento"
            permissaoFuncionalidade={
              ConstanteFuncionalidades.LANCAMENTO_CADASTRAR
            }
            component={() => {
              const nameCurrentOperation = useRef<TipoOperacaoProps>({
                name: '',
              });

              const { setBreadcrumb } = useLayoutContext();

              useEffect(() => {
                setBreadcrumb([
                  {
                    title: 'Extratos',
                    path: ConstanteRotas.EXTRATOS,
                  },
                  {
                    title: 'Detalhes',
                    path: ConstanteRotas.EXTRATOS,
                  },
                  { title: nameCurrentOperation.current.name },
                ]);
              }, [nameCurrentOperation, setBreadcrumb]);

              return (
                <CadastrarLancamentoContas
                  idRota={idRota}
                  rotaRetorno={ConstanteRotas.EXTRATOS_DETALHES}
                  rotaAtual={ConstanteRotas.EXTRATOS_LANCAMENTO}
                  ref={nameCurrentOperation}
                />
              );
            }}
            path={ConstanteRotas.EXTRATOS_LANCAMENTO}
            exact
            meta={{ auth: true }}
          />
        </>
      )}
    </ExtratoContext.Consumer>
  </ExtratoProvider>,
];
