import React from 'react';
import { Drawer, DrawerProps, DrawerOverlay } from '@chakra-ui/react';

import PortalFullscreen from 'components/PDV/Geral/PortalFullscreen';

interface PropsDrawerPadraoChakra extends DrawerProps {
  children: any;
  isOverlay?: boolean;
}

const DrawerPadraoChakra = ({
  children,
  isOverlay = true,
  ...props
}: PropsDrawerPadraoChakra) => {
  return (
    <PortalFullscreen appendToParentPortal>
      <Drawer autoFocus {...props}>
        {isOverlay ? (
          <DrawerOverlay zIndex="modal">{children}</DrawerOverlay>
        ) : (
          children
        )}
      </Drawer>
    </PortalFullscreen>
  );
};

export default DrawerPadraoChakra;
