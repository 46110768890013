import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SalvarConfirmarIcon } from 'icons';
import ButtonPadrao from 'components/Button/ButtonPadrao';
import { Col, Form, Modal } from 'react-bootstrap';
import TextAreaPadrao from 'components/TextArea/TextAreaPadrao';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { toast } from 'react-toastify';

import TipoEventoEnum from 'constants/enum/fiscal/tipoEvento';
import ModalPadrao from 'components/Modal/ModalPadrao';
import formatUTCToLocateDateTime from 'helpers/format/formatUTCToLocateDateTime';
import { useSignalRContext } from 'store/SignalR';
import TipoCertificadoEnum from 'constants/enum/fiscal/tipoCertificado';
import { ModalAtencao } from 'components/Modal/ModalAtencao';
import { useForm, yupResolver } from './validationForm';

interface CartaCorrecaoNotaModalPrps {
  show: boolean;
  onHide: () => void;
  documentoFiscalId: string;
}

interface DocumentoFiscalInfoCartaCorrecaoInterface {
  sequenciaEvento: number;
  motivo: string;
}

const CartaCorrecaoModal: React.FC<CartaCorrecaoNotaModalPrps> = ({
  show,
  onHide,
  documentoFiscalId,
}) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    formState: { errors },
    getValues,
    register,
    setValue,
    setFocus,
  } = useForm({
    resolver: yupResolver,
    shouldUnregister: true,
    defaultValues: {
      motivo: '',
      condicaoUso:
        'A Carta de Correção é disciplinada pelo § 1º-A do art. 7º do Convênio S/N, de 15 de dezembro de 1970 e pode ser utilizada para regularização de erro ocorrido na emissão de documento fiscal, desde que o erro não esteja relacionado com: I - as variáveis que determinam o valor do imposto tais como: base de cálculo, alíquota, diferença de preço, quantidade, valor da operação ou da prestação; II - a correção de dados cadastrais que implique mudança do remetente ou do destinatário; III - a data de emissão ou de saída.',
    },
  });

  const { hubConnection, joinGroup } = useSignalRContext();
  const [isLoading, setIsLoading] = useState(false);
  const [sequenciaEvento, setSequenciaEvento] = useState(0);

  const handlePostCartaCorrecao = async () => {
    const data = getValues();

    setIsLoading(true);

    const response = await api.post<void, ResponseApi<number | undefined>>(
      ConstanteEnderecoWebservice.NOTA_FISCAL_EVENTO,
      {
        documentoFiscalId,
        tipoEvento: TipoEventoEnum.CartaCorrecao,
        ...data,
        dataEmissao: formatUTCToLocateDateTime(new Date().toString()),
      }
    );

    if (response.sucesso) {
      if (response.dados && response.dados === Number(TipoCertificadoEnum.A3)) {
        const timeout = window.setTimeout(
          () => {
            setIsLoading(false);
            ModalAtencao({
              title: 'A comunicação com o certificado digital A3 falhou',
              text:
                'Verifique se o certificado digital está conectado no computador e se o aplicativo Módulo desktop está sendo executado.',
            });
          },
          1000 * 60 // 1 minuto
        );

        await joinGroup(`${documentoFiscalId}_transmitindo-evento`);

        hubConnection.off('sucesso-transmissao-a3');
        hubConnection.on('sucesso-transmissao-a3', () => {
          window.clearTimeout(timeout);

          setIsLoading(false);
          toast.success(t('A carta de correção foi realizada com sucesso.'));
          onHide();
        });

        hubConnection.off('rejeicao-transmissao-a3');
        hubConnection.on('rejeicao-transmissao-a3', (message: string) => {
          window.clearTimeout(timeout);

          setIsLoading(false);
          toast.warning(message);
        });

        return;
      }

      toast.success(t('A carta de correção foi realizada com sucesso.'));
      onHide();
    }

    setIsLoading(false);

    if (response.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }
  };

  const onSubmit = handleSubmit(async () => {
    await handlePostCartaCorrecao();
  });

  const handleGetInfoCartaCorrecao = useCallback(async () => {
    if (show) {
      setIsLoading(true);

      const response = await api.get<
        void,
        ResponseApi<DocumentoFiscalInfoCartaCorrecaoInterface>
      >(ConstanteEnderecoWebservice.NOTA_FISCAL_OBTER_INFO_CARTA_CORRECAO, {
        params: { documentoFiscalId },
      });

      if (response.sucesso) {
        setSequenciaEvento(response.dados.sequenciaEvento);
        setValue('motivo', response.dados.motivo);
      }

      if (response.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }

      setIsLoading(false);
    }
  }, [documentoFiscalId, setValue, show]);

  useEffect(() => {
    handleGetInfoCartaCorrecao();
  }, [handleGetInfoCartaCorrecao]);

  return (
    <ModalPadrao
      isOpen={show}
      handleOnHide={onHide}
      title={t('Carta de correção')}
      subtitle={t(
        'Apenas a última carta de correção será considerada pela SEFAZ.'
      )}
      onEntered={() => setFocus('motivo')}
      size="lg"
      minWidth="40%"
    >
      <Form>
        {isLoading && <LoadingPadrao />}
        <Modal.Body>
          <Form.Row>
            <Col xs={0}>
              <Form.Label style={{ fontWeight: 600 }}>
                {t('Número de correções já realizadas')}: {sequenciaEvento}
              </Form.Label>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs={0}>
              <TextAreaPadrao
                id="motivo"
                label={t('Correção')}
                placeholder={t('Digite a correção')}
                error={errors.motivo}
                disabled={false}
                maxLength={255}
                required
                rows={5}
                {...register('motivo')}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs={0}>
              <TextAreaPadrao
                id="condicaoUso"
                label={t('Condição de uso')}
                error={errors.condicaoUso}
                disabled
                required
                rows={5}
                {...register('condicaoUso')}
              />
            </Col>
          </Form.Row>
        </Modal.Body>

        <Modal.Footer>
          <ButtonPadrao
            id="fechar"
            name="fechar"
            text={t('Fechar')}
            type="button"
            variant="outline-secondary"
            onClick={onHide}
            style={{ height: '35px', width: '100%', maxWidth: '100px' }}
          />
          <ButtonPadrao
            id="salvarInutilizar"
            name="salvar"
            text={t('Salvar')}
            icon={SalvarConfirmarIcon}
            type="button"
            variant="success"
            onClick={() => {
              onSubmit();
            }}
            style={{ height: '35px', width: '100%', maxWidth: '120px' }}
          />
        </Modal.Footer>
      </Form>
    </ModalPadrao>
  );
};

export default CartaCorrecaoModal;
