import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { AmbienteFiscal } from 'constants/enum/fiscal/ambienteFiscal';
import obterAmbienteFiscal from 'helpers/api/Loja/obterAmbienteFiscal';
import ModelosFiscaisEnum from 'constants/enum/fiscal/modelosFiscais';
import StatusFiscaisEnum from 'constants/enum/fiscal/statusFiscal';
import obterOperacaoParaFinalizar, {
  OperacaoObterParaFinalizar,
  DocumentoFiscal,
} from 'helpers/api/Operacao/obterOperacaoParaFinalizar';
import { formatarDescricaoDocumentoFiscal } from 'helpers/api/Operacao/formatarDescricaoDocumentoFiscal';
import obterInformacoesFinalizarVenda, {
  ObterInformacoesFinalizarVendaResponse,
} from 'helpers/api/Loja/obterInformacoesFinalizarVenda';
import ConstanteRotas from 'constants/rotas';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

interface Operacao extends OperacaoObterParaFinalizar {
  NFCe?: DocumentoFiscal;
  NFCeFormatada?: string;
  NFe?: DocumentoFiscal;
  NFeFormatada?: string;
}

interface OperacoesEmitirNotaContextProps {
  operacao?: Operacao;
  operacaoId?: string;
  informacoesLoja?: ObterInformacoesFinalizarVendaResponse;
  handleObterOperacao: () => Promise<void>;
  handlePushVoltar: () => void;
  temNFeAutorizada: boolean;
  ambienteFiscal?: AmbienteFiscal;
}

interface OperacoesEmitirNotaProviderProps {
  children: React.ReactNode;
}

type TParams = { id: string };

export const OperacoesEmitirNotaContext = createContext<OperacoesEmitirNotaContextProps>(
  {} as OperacoesEmitirNotaContextProps
);

export default function OperacoesEmitirNotaProvider({
  children,
}: OperacoesEmitirNotaProviderProps): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const [operacao, setOperacao] = useState<Operacao>();
  const [ambienteFiscal, setAmbienteFiscal] = useState<AmbienteFiscal>();
  const [
    informacoesLoja,
    setInformacoesLoja,
  ] = useState<ObterInformacoesFinalizarVendaResponse>();

  const { id: operacaoId } = useParams<TParams>();
  const history = useHistory();

  const operacaoNaoExiste = !operacao;

  const temNFeAutorizada =
    !!operacao &&
    !!operacao.NFe &&
    (operacao.NFe.status === StatusFiscaisEnum.Autorizada ||
      operacao.NFe.status === StatusFiscaisEnum.UsoDenegado);

  const handlePushVoltar = useCallback(() => {
    history.push(ConstanteRotas.OPERACOES);
  }, [history]);

  const handleObterOperacao = useCallback(async () => {
    setIsLoading(true);

    if (operacaoId) {
      const newOperacao = await obterOperacaoParaFinalizar(operacaoId);

      if (newOperacao) {
        let NFCe;
        let NFCeFormatada;
        const documentoFiscalNFCe = newOperacao.documentosFiscais.find(
          (documentoFiscal) =>
            documentoFiscal.modeloFiscal === ModelosFiscaisEnum.NFCe
        );
        if (documentoFiscalNFCe) {
          NFCe = documentoFiscalNFCe;

          NFCeFormatada = formatarDescricaoDocumentoFiscal({
            status: documentoFiscalNFCe.status,
            numero: documentoFiscalNFCe.numero,
            dataEmissao: documentoFiscalNFCe.dataEmissao,
          });
        }

        let NFe;
        let NFeFormatada;
        const documentoFiscalNFe = newOperacao.documentosFiscais.find(
          (documentoFiscal) =>
            documentoFiscal.modeloFiscal === ModelosFiscaisEnum.NFe
        );
        if (documentoFiscalNFe) {
          NFe = documentoFiscalNFe;

          NFeFormatada = formatarDescricaoDocumentoFiscal({
            status: documentoFiscalNFe.status,
            numero: documentoFiscalNFe.numero,
            dataEmissao: documentoFiscalNFe.dataEmissao,
          });
        }

        setOperacao({ ...newOperacao, NFCe, NFCeFormatada, NFe, NFeFormatada });
      } else {
        handlePushVoltar();
      }
    }
    setIsLoading(false);
  }, [handlePushVoltar, operacaoId]);

  const latestProps = useRef({ history, handleObterOperacao });

  const obterInformacoesLoja = useCallback(async () => {
    const newInformacoesLoja = await obterInformacoesFinalizarVenda();

    if (newInformacoesLoja) {
      setInformacoesLoja(newInformacoesLoja);
    } else {
      handlePushVoltar();
    }
  }, [handlePushVoltar]);

  const obterTodasInformações = useCallback(async () => {
    setIsLoading(true);

    if (!operacaoNaoExiste) {
      await obterInformacoesLoja();
      const newAmbienteFiscal = await obterAmbienteFiscal();
      setAmbienteFiscal(newAmbienteFiscal);
    }

    if (operacaoNaoExiste) {
      await latestProps.current.handleObterOperacao();
    }

    setIsLoading(false);
  }, [obterInformacoesLoja, operacaoNaoExiste]);

  useEffect(() => {
    latestProps.current = { history, handleObterOperacao };
  });

  useEffect(() => {
    obterTodasInformações();
  }, [obterTodasInformações]);

  return (
    <OperacoesEmitirNotaContext.Provider
      value={{
        operacao,
        operacaoId,
        informacoesLoja,
        handleObterOperacao,
        handlePushVoltar,
        temNFeAutorizada,
        ambienteFiscal,
      }}
    >
      {isLoading && <LoadingPadrao />}
      {children}
    </OperacoesEmitirNotaContext.Provider>
  );
}

export function useOperacoesEmitirNotaContext(): OperacoesEmitirNotaContextProps {
  const context = useContext(OperacoesEmitirNotaContext);

  if (!context)
    throw new Error(
      'useOperacoesEmitirNotaContext must be used within a OperacoesEmitirNotaProvider.'
    );

  return context;
}
