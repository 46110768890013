import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import isPrenvent from 'helpers/layout/isPrenvent';
import { setDateMinHours } from 'helpers/data/setHoursDate';
import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import { ContainerListagem } from 'components/Layout/Listagem/containerListagem';

import { useForm, yupResolver } from '../validationForm';
import { UncontrolledForm } from '..';

interface ContaFinanceira {
  id: string;
  tipo: string;
  nome: string;
  ativo: boolean;
  tipoContaFinanceira: number;
  codigoBanco: string;
  dataHoraCadastro: string;
  dataHoraUltimaAlteracao: string;
  icone: string;
  saldoInicial: number;
}

type TParams = { id: string };

interface BancoInterface {
  codigoBanco: string;
  nomeBanco: string;
}

let bancoList: BancoInterface[] = [];

const Alterar = () => {
  const handleGetBanco = async () => {
    const response = await api.get<void, ResponseApi<Array<BancoInterface>>>(
      ConstanteEnderecoWebservice.BANCO_LISTAR
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        bancoList = response.dados;
      }
    }
  };

  const { id: idRota } = useParams<TParams>();
  const history = useHistory();
  const isMountedRef = useIsMountedRef();
  isPrenvent();

  const [isLoading, setIsLoading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);

  const [dataHoraCadastro, setDataHoraCadastro] = useState('');
  const [descricaoIcone, setDescricaoIcone] = useState('');
  const [dataHoraUltimaAlteracao, setDataHoraUltimaAlteracao] = useState('');

  const formMethods = useForm({
    resolver: yupResolver,
    shouldUnregister: false,
  });

  const { handleSubmit, reset, getValues, formState } = formMethods;

  const handleGetContaFinanceira = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<void, ResponseApi<ContaFinanceira>>(
      ConstanteEnderecoWebservice.CONTA_FINANCEIRA_OBTER,
      {
        params: { id: idRota },
      }
    );

    if (response?.avisos) {
      response.avisos.forEach((item: string) => toast.warning(item));
    }

    if (response?.sucesso && isMountedRef.current) {
      reset({
        ...response.dados,
        dataLancamentoSaldo: setDateMinHours(new Date()),
      });

      setDataHoraCadastro(response.dados.dataHoraCadastro);
      setDataHoraUltimaAlteracao(response.dados.dataHoraUltimaAlteracao);
      setDescricaoIcone(response.dados.icone);
    } else {
      history.push(ConstanteRotas.CONTA_FINANCEIRA);
    }

    if (isMountedRef.current) setIsLoading(false);
  }, [history, isMountedRef, idRota, reset]);

  const onSubmit = handleSubmit(async () => {
    setIsLoading(true);

    const data = await getValues();
    const exibirSaldoInicial = !!data.exibirSaldoInicial;

    if (!exibirSaldoInicial) {
      delete data.dataLancamentoSaldo;
      delete data.saldoInicial;
    }

    const newValuesData = await {
      ...data,
      id: idRota,
      numeroConta: String(data.numeroConta),
      dataLancamentoSaldo: data.dataLancamentoSaldo,
    };

    const response = await api.put<void, ResponseApi>(
      ConstanteEnderecoWebservice.CONTA_FINANCEIRA_ALTERAR,
      newValuesData
    );

    if (response.sucesso) {
      setFormIsDirty(false);
      toast.success('O cadastro foi alterado com sucesso.');
      history.push(ConstanteRotas.CONTA_FINANCEIRA);
    }

    if (response.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (isMountedRef.current) setIsLoading(false);
  });

  useEffect(() => {
    setIsLoading(true);
    handleGetBanco();
    handleGetContaFinanceira();
  }, [handleGetContaFinanceira]);

  useEffect(() => {
    setFormIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  return (
    <ContainerListagem
      formIsDirty={formIsDirty}
      formMethods={formMethods}
      isLoading={isLoading}
      onSubmit={onSubmit}
      dataHoraUltimaAlteracao={dataHoraUltimaAlteracao}
      dataHoraCadastro={dataHoraCadastro}
    >
      <UncontrolledForm
        descricaoIcone={descricaoIcone}
        setDescricaoIcone={setDescricaoIcone}
        bancoList={bancoList}
        readonlyTipo
      />
    </ContainerListagem>
  );
};

export default Alterar;
