import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export type ItemVinculacao = {
  produtoId: string;
  nomeProduto: string;
  referencia: string;
  corId?: string;
  corDescricao?: string;
  listaTamanhoIdQuantidade: {
    id: string;
    quantidade: number;
    descricaoTamanho: string;
  }[];
  custoAdicional: number;
};

export type FormData = {
  quantidade: number;
  valorUnitario: number;
  cfop: string;
  itensVinculacao: ItemVinculacao[];
};

const schema = yup.object().shape({
  itensVinculacao: yup
    .array()
    .of(
      yup.object().shape({
        produtoId: yup
          .string()
          .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
          .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
        corId: yup.string().nullable(),
        listaTamanhoIdQuantidade: yup
          .array()
          .of(
            yup.object().shape({
              id: yup.string(),
              quantidade: yup
                .number()
                .moreThan(0, ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
                .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
                .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
            })
          )
          .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
      })
    )
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  quantidade: yup
    .number()
    .moreThan(0, ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  valorUnitario: yup.number().nullable(),
  cfop: yup.string().max(5, ConstanteMensagemValidacao.MAX_LENGTH_5).nullable(),
});

export const yupResolver = yupResolverInstance(schema);
