import {
  Icon,
  IconButton,
  Input as ChakraInput,
  InputGroup,
  InputProps as ChakraInputProps,
  InputRightElement,
} from '@chakra-ui/react';

import CampoPrototipo, {
  CampoPrototipoProps,
} from 'components/PDV/Geral/CampoPrototipo';
import { FiX } from 'react-icons/fi';

interface MonthInputProps extends ChakraInputProps, CampoPrototipoProps {
  name: string;
  autoFocus?: boolean;
  dia?: number;
  isClearable?: boolean;
  valuePadding?: string;
}

export function formatDate(value: any) {
  if (value) {
    const date = new Date(value);

    const ano = date.getFullYear();
    const mes = date.getMonth() + 1;

    return `${String(ano).padStart(4, '0')}-${String(mes).padStart(2, '0')}`;
  }

  return '';
}

export function parseDate(value?: string, dia?: number): Date | null {
  const [anoCompetencia, mesCompetencia] = (value || '').split('-');

  return anoCompetencia && mesCompetencia
    ? new Date(
        Number(anoCompetencia),
        Number(mesCompetencia) - 1,
        dia || 1,
        0,
        0,
        0
      )
    : null;
}

export function MonthInput({
  name,
  defaultValue,
  placeholder,
  id,
  label,
  colSpan,
  colStart,
  colEnd,
  rowSpan,
  rowStart,
  rowEnd,
  isRequired = false,
  helperText,
  maxW,
  dia = 1,
  variant,
  size = 'md',
  sx,
  min,
  valuePadding = '2',
  isClearable = false,
  ...rest
}: MonthInputProps) {
  return (
    <CampoPrototipo
      id={id}
      name={name}
      defaultValue={defaultValue}
      isRequired={isRequired}
      helperText={helperText}
      label={label}
      rowSpan={rowSpan}
      rowStart={rowStart}
      rowEnd={rowEnd}
      colSpan={colSpan}
      colStart={colStart}
      colEnd={colEnd}
      maxW={maxW}
    >
      {(_, { value, onChange, onBlur, ...restField }) => {
        return (
          <InputGroup position="relative" variant={variant} size={size} sx={sx}>
            <ChakraInput
              w="100%"
              {...rest}
              {...restField}
              variant={variant}
              size={size}
              type="month"
              defaultValue={formatDate(value)}
              onChange={(e) => {
                const inputValue = e.currentTarget.value;

                const novoValor = parseDate(inputValue);

                onChange(novoValor, dia);
              }}
              onBlur={onBlur}
              id={id}
              min={min}
              isRequired={isRequired}
              pr={valuePadding}
            />

            {isClearable && (
              <InputRightElement position="absolute" right="12">
                <IconButton
                  variant="ghost"
                  minW="9"
                  borderRadius={size}
                  size={size}
                  aria-label="Limpar"
                  icon={<Icon as={FiX} />}
                  onClick={() => {
                    onChange(undefined);
                  }}
                />
              </InputRightElement>
            )}
          </InputGroup>
        );
      }}
    </CampoPrototipo>
  );
}
