import React from 'react';

import HeaderMenuOptions from 'components/PDV/Header/MenuOptions';
import HeaderContainer from 'components/PDV/Header/Container';
import OperacaoPedidoObservacaoCliente from 'components/PDV/Header/OperacaoPedidoObservacaoCliente';

const InternalHeader = () => {
  return (
    <HeaderContainer>
      <OperacaoPedidoObservacaoCliente />
      <HeaderMenuOptions />
    </HeaderContainer>
  );
};

export default InternalHeader;
