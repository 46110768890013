import { Text, Box, Icon, Flex, Button } from '@chakra-ui/react';
import { FiChevronLeft } from 'react-icons/fi';

import { SalvarInserirNovoIcon } from 'icons';
import Input from 'components/PDV/Input';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import CheckBoxAtivoInativo from 'components/update/Checkbox/CheckboxAtivoInativo';
import { ContainerHeader } from 'pages/Integracoes/LojaAplicativos/Layout/ContainerHeader';

import useCashPeriodForm from './hooks';

type UncontrolledFormProps = {
  isLoading?: boolean;
  readonly?: boolean;
  handleSalvar?: () => Promise<void>;
  handleSalvarInserirNovo?: () => Promise<void>;
};

const UncontrolledForm = ({
  handleSalvar,
  handleSalvarInserirNovo,
  isLoading,
  readonly = false,
}: UncontrolledFormProps) => {
  const { redirecionarPeriodoCaixaListar } = useCashPeriodForm();

  return (
    <>
      <ContainerHeader bg="#FF005A" gap={2} color="white" alignItems="center">
        <Box
          cursor="pointer"
          mr="10px"
          onClick={() => redirecionarPeriodoCaixaListar()}
        >
          <Icon fontSize="25px" as={FiChevronLeft} />
        </Box>
        <Text fontSize="16px" fontWeight="semibold">
          Período do caixa
        </Text>
      </ContainerHeader>
      <Box position="relative" p={6}>
        {isLoading && <LoadingPadrao />}
        <SimpleGridForm>
          <Input
            id="nome"
            name="nome"
            label="Nome"
            placeholder="Informe o nome do período"
            maxLength={30}
            isDisabled={readonly}
            colSpan={{ base: 12, sm: 9 }}
          />
          <CheckBoxAtivoInativo
            id="ativo"
            name="ativo"
            label="Status"
            isRequired={false}
            isDisabled={readonly}
            colSpan={{ base: 12, sm: 3 }}
          />
        </SimpleGridForm>
        <Flex p="40px" gap={6} align="center" justify="center" flexWrap="wrap">
          {handleSalvarInserirNovo && (
            <Button
              w={{ base: 'full', sm: 'min-content' }}
              minW="max"
              colorScheme="gray"
              variant="outlineDefault"
              leftIcon={<Icon as={SalvarInserirNovoIcon} fontSize="lg" />}
              onClick={handleSalvarInserirNovo}
            >
              Salvar e inserir novo
            </Button>
          )}
          {handleSalvar && (
            <Button
              w={{ base: 'full', sm: 'min-content' }}
              minW="225px"
              colorScheme="secondary"
              onClick={handleSalvar}
            >
              Salvar
            </Button>
          )}
        </Flex>
      </Box>
    </>
  );
};

export default UncontrolledForm;
