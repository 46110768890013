import { Box, GridItem, Text, useMediaQuery } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import React from 'react';
import { toast } from 'react-toastify';

import auth from 'modules/auth';

import CheckBoxLoja from 'components/CheckBox/CheckBoxLoja';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';

type LocalEstoque = {
  localEstoqueId: string;
  nome: string;
  padraoSistema: boolean;
};

interface LojasProps {
  readonly?: boolean;
  lojas: LojasInterface[];
  usuarioLogado?: string;
}

export type Vendedores = {
  vendedorId: string;
  nome: string;
  padraoSistema: boolean;
};

export interface LojasInterface {
  id: string;
  fantasia: string;
  endereco: string;
  cidade: string;
  selecionado: boolean;
  localEstoqueId: string;
  vendedorId: string;
  vendedor: string;
  locaisEstoque: LocalEstoque[];
  vendedores: Vendedores[];
}

const Lojas = ({ readonly, lojas, usuarioLogado }: LojasProps) => {
  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');

  const { userId } = auth.getDadosToken();
  const { id: idLojaAtual } = auth.getLoja();

  const { setValue } = useFormContext();

  const listLojasSelecionadas = useWatch({
    name: `lojaSelecionada.`,
  });

  const toogleLojaSelecionada = (
    lojaId: string,
    lojaSelecionadaValue: boolean
  ) => {
    if (lojaId === idLojaAtual && usuarioLogado === userId) {
      toast.warning('Não é possivel alterar a loja atual do usuário logado.');
    } else {
      setValue(`lojaSelecionada.${lojaId}`, !lojaSelecionadaValue);
    }
  };

  return (
    <>
      <Box mb={['8px', '6px']}>
        <Text fontSize="14px" color="gray.700" fontWeight="semibold" w="full">
          Selecione as lojas que o usuário terá acesso. É necessário atribuí-lo
          à um vendedor e informar um local de estoque padrão para cada loja.
        </Text>
      </Box>

      <Box
        w="full"
        bgColor="gray.50"
        borderWidth="1px"
        borderColor="gray.200"
        borderRadius="5px"
        px={['16px', '24px']}
        pt={['36px', '24px']}
        pb={['40px', '42px']}
      >
        <SimpleGridForm
          flexDirection="row"
          alignItems="center"
          color="gray.500"
          px=" 24px"
          display={['none', 'none', 'grid']}
        >
          <GridItem colSpan={6}>
            <Text ml="24px">Loja</Text>
          </GridItem>
          <GridItem colSpan={3}>
            <Text>Vendedor</Text>
          </GridItem>
          <GridItem colSpan={3}>
            <Text>Local de estoque</Text>
          </GridItem>
        </SimpleGridForm>

        {lojas?.map((lojaItem: LojasInterface) => {
          const storeIsSelected =
            (listLojasSelecionadas || [])[lojaItem?.id] || false;

          const listLocaisEstoque = lojaItem.locaisEstoque?.map(
            (localItem) => ({
              label: localItem.nome,
              value: localItem.localEstoqueId,
            })
          );

          const listVendedores = lojaItem.vendedores?.map((vendedorItem) => ({
            label: vendedorItem.nome,
            value: vendedorItem.vendedorId,
          }));

          return (
            <GridItem
              colSpan={12}
              w="full"
              mt="4px"
              px={['8px', '24px']}
              py={['16px', '20px']}
              flexDirection="row"
              bgColor={storeIsSelected ? 'purple.50' : 'gray.50'}
              border="1px solid"
              borderColor="gray.200"
              borderRadius="5px"
              alignItems="start"
              key={lojaItem.id}
            >
              <SimpleGridForm
                columns={12}
                flexDirection="row"
                justifyContent="space-around"
                alignItems="center"
                color="gray.500"
              >
                <GridItem colSpan={[12, 12, 6]}>
                  <CheckBoxLoja
                    id={`lojaSelecionada.${lojaItem.id}`}
                    name={`lojaSelecionada.${lojaItem.id}`}
                    labelNome={lojaItem.fantasia}
                    labelEndereco={`${lojaItem.endereco} - ${lojaItem.cidade}`}
                    readOnly={readonly}
                    onClick={() =>
                      toogleLojaSelecionada(lojaItem.id, storeIsSelected)
                    }
                    onChange={() =>
                      toogleLojaSelecionada(lojaItem.id, storeIsSelected)
                    }
                    isChecked={storeIsSelected}
                    isCheckedBox
                  />
                </GridItem>
                <GridItem colSpan={[12, 12, 3]}>
                  <SelectPadrao
                    id={`vendedorId.${lojaItem.id}`}
                    name={`vendedorId.${lojaItem.id}`}
                    label={isSmallerThan900 && 'Vendedor'}
                    labelColor="gray.500"
                    placeholder="Selecione um vendedor"
                    options={listVendedores}
                    required
                    isSearchable={false}
                    isDisabled={!storeIsSelected}
                  />
                </GridItem>
                <GridItem colSpan={[12, 12, 3]}>
                  <SelectPadrao
                    id={`localEstoqueId.${lojaItem.id}`}
                    name={`localEstoqueId.${lojaItem.id}`}
                    label={isSmallerThan900 && 'Local de estoque'}
                    labelColor="gray.500"
                    placeholder="Selecione um local de estoque"
                    options={listLocaisEstoque}
                    required
                    isSearchable={false}
                    isDisabled={!storeIsSelected}
                  />
                </GridItem>
              </SimpleGridForm>
            </GridItem>
          );
        })}
      </Box>
    </>
  );
};

export default Lojas;
