import { useState } from 'react';
import { Button, Flex } from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import { ImprimirPDF } from 'helpers/impressoes/imprimirPDF';
import { setDateMaxHours, setDateMinHours } from 'helpers/data/setHoursDate';
import StatusConsultaEnum from 'constants/enum/statusConsulta';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import TipoFiltroProdutoEstoqueEnum from 'constants/enum/tipoFiltroProdutoEstoque';
import { enumRelatorioProdutosPersonalizadoPadrao } from 'constants/enum/RelatoriosPadrao';

import { CustomFormStandardComponent } from 'components/update/CustomFormStandard';

import { FormData, yupResolver } from './validationForm';

export type CamposPersonalizados = {
  campoPersonalizadoId: string;
  valor: string;
};

export const RelatorioProdutosVendidos = () => {
  const [isLoading, setIsLoading] = useState(false);

  const formMethods = useForm<FormData>({
    defaultValues: {
      dataEmissaoInicio: setDateMinHours(new Date()),
      dataEmissaoFim: setDateMaxHours(new Date()),
      clienteFornecedorId: null,
      statusConsulta: StatusConsultaEnum.TODOS,
      tipoEstoque: TipoFiltroProdutoEstoqueEnum.TODOS,
    },
    resolver: yupResolver,
  });

  const { handleSubmit, watch } = formMethods;

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);
    const clienteSelecionadoWatch = watch('clienteFornecedorId');

    const isRelatorioProdutoPorVenda =
      data.tipoRelatorio ===
      enumRelatorioProdutosPersonalizadoPadrao.RELATORIO_PRODUTO_POR_VENDA;

    const listCamposPersonalizados = data.camposPersonalizados
      .filter((campoItem) => campoItem.valor)
      .map((campoPersonalizadoItem) => ({
        ...campoPersonalizadoItem,
        valor: String(campoPersonalizadoItem.valor),
      }));

    const camposPersonalizados =
      listCamposPersonalizados.length > 0 ? listCamposPersonalizados : null;

    const dados = {
      ...data,
      camposPersonalizados,
      clienteFornecedorId: clienteSelecionadoWatch?.value || null,
    };

    const enderecoWebservice = isRelatorioProdutoPorVenda
      ? ConstanteEnderecoWebservice.RELATORIO_PRODUTO_POR_VENDA
      : ConstanteEnderecoWebservice.RELATORIO_LUCRO_POR_PRODUTO;

    const response = await api.post<void, ResponseApi<string>>(
      enderecoWebservice,
      dados
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        ImprimirPDF(response.dados, 'relatorioProdutosVendidos');
      }
    }
    setIsLoading(false);
  });

  return (
    <FormProvider {...formMethods}>
      <CustomFormStandardComponent
        type="ProdutosVendidos"
        isLoading={isLoading}
      />
      <Flex justifyContent="end">
        <Button
          variant="unstyled"
          w={{ base: 'full', sm: '250px' }}
          h="10"
          borderRadius="md"
          color="gray.900"
          bg="secondary.400"
          mt={{ base: '6', md: '6', lg: '10' }}
          onClick={onSubmit}
        >
          Gerar Relatório
        </Button>
      </Flex>
    </FormProvider>
  );
};

export default RelatorioProdutosVendidos;
