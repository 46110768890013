import React from 'react';

import { Col, Container, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ButtonPadrao from 'components/Button/ButtonPadrao';
import { SalvarConfirmarIcon } from 'icons';
import ModalPadrao from 'components/Modal/ModalPadrao';

import { ModalAtencao } from 'components/Modal/ModalAtencao';
import { toast } from 'react-toastify';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import { useLojaFormularioContext } from 'store/Lojas/LojasFormulario';
import InputPadrao from 'components/Input/InputPadrao';
import { Modal } from './styles';
import InputFile from '../../../../components/Input/InputFile';

import { yupResolver, useForm } from './validationForm';

interface CertificadoDigitalModalProps {
  show: boolean;
  onHide: (willReset?: boolean) => void;
  onSubmit: () => void;
  readonly: any;
  setValuesLoja: any;
}

const CertificadoDigitalModal: React.FC<CertificadoDigitalModalProps> = ({
  show,
  onHide,
  onSubmit,
  setValuesLoja,
  readonly,
}) => {
  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({
    resolver: yupResolver,
    shouldUnregister: true,
  });

  const { setCertificadoDigital } = useLojaFormularioContext();
  const { t } = useTranslation();

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  // eslint-disable-next-line consistent-return
  const handleSalvar = handleSubmit(async (data: any) => {
    if (data.certificadoDigital.type.split('/')[1] !== 'x-pkcs12') {
      toast.warning('Favor selecionar um certificado com o formato .pfx');
      return false;
    }

    const certificado64 = await toBase64(data.certificadoDigital);

    if (!certificado64) {
      toast.warning('Erro ao converter certificado.');
      return false;
    }

    const dataViewModel = {
      arquivo: String(certificado64).split(',')[1],
      nomeArquivo: data.certificadoDigital.name.split('.')[0],
      senhaCertificado: data.senhaCertificado,
    };

    const response = await api.post<void, ResponseApi>(
      ConstanteEnderecoWebservice.CERTIFICADODIGITAL_VALIDARSALVAR,
      dataViewModel
    );

    if (response.sucesso && response.dados) {
      toast.success('Certificado validado e pronto para uso.');

      const dados = response.dados as any;

      setCertificadoDigital({
        NomeArquivo: dados.nomeArquivo,
        DataValidade: dados.dataValidade,
        SenhaCertificado: dados.senhaArquivo,
        Arquivo64: String(certificado64).split(',')[1],
        Uploaded: false,
      });
    } else {
      ModalAtencao({
        text: 'Senha ou certificado inválido.',
      });
      return false;
    }

    setValuesLoja('senhaCertificadoValido', true);

    onSubmit();
  });

  return (
    <ModalPadrao
      isOpen={show}
      handleOnHide={onHide}
      title={t('Certificado digital A1')}
      maxWidth="450px"
      subtitle={t('Escolha o arquivo (.pfx) e faça a validação da senha.')}
    >
      <Modal.Body>
        <Container>
          <Form.Row>
            <Col xs={12}>
              <InputFile
                id="certificadoDigital"
                name="certificadoDigital"
                label="Escolher arquivo"
                control={control}
                required
                setValues={setValue}
                error={errors.certificadoDigital}
                disabled={readonly}
                accept="application/,.pfx"
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs={12}>
              <InputPadrao
                type="password"
                id="senhaCertificado"
                name="senhaCertificado"
                label={t('Senha')}
                placeholder={t('Informe a senha do certificado')}
                maxLength={60}
                control={control}
                error={errors.senhaCertificado}
                disabled={readonly}
                required
              />
            </Col>
          </Form.Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Form.Row>
          <Col xs={12}>
            <ButtonPadrao
              id="salvar-contato-adicional"
              name="salvar"
              text={t('Validar senha')}
              icon={SalvarConfirmarIcon}
              type="button"
              variant="success"
              onClick={handleSalvar}
              style={{
                height: '35px',
                width: '100%',
                maxWidth: '150px',
              }}
            />
          </Col>
        </Form.Row>
      </Modal.Footer>
    </ModalPadrao>
  );
};

export default CertificadoDigitalModal;
