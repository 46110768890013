import React, { ChangeEvent } from 'react';

import { telefoneMask } from 'helpers/format/fieldsMasks';

import Input, { InputProps } from '../Input';

export const getFormattedValue = (value: string) => {
  return telefoneMask(value);
};

type InputTelefoneProps = InputProps;

const InputTelefone = ({ onChange, ...rest }: InputTelefoneProps) => {
  return (
    <Input
      {...rest}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        e.currentTarget.value = getFormattedValue(e.currentTarget.value);

        if (onChange) onChange(e);
      }}
      onInput={(e: ChangeEvent<HTMLInputElement>) => {
        e.currentTarget.value = getFormattedValue(e.currentTarget.value);
      }}
      maxLength={15}
    />
  );
};

export default InputTelefone;
