import React from 'react';
import { Box } from '@chakra-ui/react';

const NavHeader: React.FC = ({ children }) => {
  return (
    <Box bg="pdv.nav_header" w="100%" maxW="100vw">
      {children}
    </Box>
  );
};

export default NavHeader;
