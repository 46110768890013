import { ContainerListagem } from 'components/Layout/Listagem/containerListagem';

import { Formulario } from '..';
import { usePromocaoAlterar } from './hooks';

export const Alterar = () => {
  const {
    isLoading,
    onSubmit,
    formIsDirty,
    dataHoraCadastro,
    dataHoraUltimaAlteracao,
  } = usePromocaoAlterar();

  return (
    <ContainerListagem
      isLoading={isLoading}
      maxWidth="100%"
      isForm={false}
      onSubmit={onSubmit}
      formIsDirty={formIsDirty}
      dataHoraCadastro={dataHoraCadastro}
      dataHoraUltimaAlteracao={dataHoraUltimaAlteracao}
    >
      <Formulario isAlterar />
    </ContainerListagem>
  );
};
