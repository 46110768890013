import { Route } from 'react-router-dom';

import ConstanteRotas from 'constants/rotas';
import ConstanteFuncionalidades from 'constants/permissoes';
import { FrenteCaixaProvider } from 'store/FrenteCaixa';

import { PainelAdministradorFrenteCaixa } from 'pages/Integracoes/LojaAplicativos/FrenteCaixa/PainelAdministradorFrenteCaixa';
import { PainelAdministradorFrenteCaixaAtivacao } from 'pages/Integracoes/LojaAplicativos/FrenteCaixa/PainelAdministradorFrenteCaixa/Ativacao';
import { PainelAdministradorFrenteCaixaConfiguracao } from 'pages/Integracoes/LojaAplicativos/FrenteCaixa/PainelAdministradorFrenteCaixa/Configuracao';
import { PainelAdministradorFrenteCaixaDispositivo } from 'pages/Integracoes/LojaAplicativos/FrenteCaixa/PainelAdministradorFrenteCaixa/Dispositivo';
import { PainelAdministradorFrenteCaixaFormaRecebimento } from 'pages/Integracoes/LojaAplicativos/FrenteCaixa/PainelAdministradorFrenteCaixa/FormaRecebimento';
import { PainelAdministradorFrenteCaixaGerenciadorImpressao } from 'pages/Integracoes/LojaAplicativos/FrenteCaixa/PainelAdministradorFrenteCaixa/GerenciadorImpressao';
import { ListarDepartamentos } from 'pages/Integracoes/LojaAplicativos/FrenteCaixa/PainelAdministradorFrenteCaixa/Departamento/Listar';
import { CadastrarDepartamento } from 'pages/Integracoes/LojaAplicativos/FrenteCaixa/PainelAdministradorFrenteCaixa/Departamento/Formulario/Cadastrar';
import { AlterarDepartamento } from 'pages/Integracoes/LojaAplicativos/FrenteCaixa/PainelAdministradorFrenteCaixa/Departamento/Formulario/Alterar';
import { ListarSetoresEntrega } from 'pages/Integracoes/LojaAplicativos/FrenteCaixa/PainelAdministradorFrenteCaixa/SetorEntrega/Listar';
import { CadastrarSetorEntrega } from 'pages/Integracoes/LojaAplicativos/FrenteCaixa/PainelAdministradorFrenteCaixa/SetorEntrega/Formulario/Cadastrar';
import { AlterarSetorEntrega } from 'pages/Integracoes/LojaAplicativos/FrenteCaixa/PainelAdministradorFrenteCaixa/SetorEntrega/Formulario/Alterar';
import { ListarEntregadores } from 'pages/Integracoes/LojaAplicativos/FrenteCaixa/PainelAdministradorFrenteCaixa/Entregador/Listar';
import { CadastrarEntregador } from 'pages/Integracoes/LojaAplicativos/FrenteCaixa/PainelAdministradorFrenteCaixa/Entregador/Formulario/Cadastrar';
import { AlterarEntregador } from 'pages/Integracoes/LojaAplicativos/FrenteCaixa/PainelAdministradorFrenteCaixa/Entregador/Formulario/Alterar';
import { DetalhesIntegracaoFrenteCaixa } from 'pages/Integracoes/LojaAplicativos/FrenteCaixa/DetalhesIntegracaoFrenteCaixa';
import { FrenteCaixaComercial } from 'pages/Integracoes/LojaAplicativos/FrenteCaixa/TelaComercial';
import { IntermediadorListar } from 'pages/Integracoes/LojaAplicativos/FrenteCaixa/PainelAdministradorFrenteCaixa/Intermediador/Listar';
import { IntermediadorCadastrar } from 'pages/Integracoes/LojaAplicativos/FrenteCaixa/PainelAdministradorFrenteCaixa/Intermediador/Formulario/Cadastrar';
import { IntermediadorAlterar } from 'pages/Integracoes/LojaAplicativos/FrenteCaixa/PainelAdministradorFrenteCaixa/Intermediador/Formulario/Alterar';
import PeriodoCaixaListar from 'pages/Integracoes/LojaAplicativos/FrenteCaixa/PainelAdministradorFrenteCaixa/PeriodoCaixa/Listar';
import PeriodoCaixaCadastrar from 'pages/Integracoes/LojaAplicativos/FrenteCaixa/PainelAdministradorFrenteCaixa/PeriodoCaixa/Formulario/Cadastrar';
import PeriodoCaixaAlterar from 'pages/Integracoes/LojaAplicativos/FrenteCaixa/PainelAdministradorFrenteCaixa/PeriodoCaixa/Formulario/Alterar';
import RelatorioPersonalizadoListar from 'pages/Integracoes/LojaAplicativos/FrenteCaixa/PainelAdministradorFrenteCaixa/RelatorioPersonalizado/Listar';
import RelatorioPersonalizadoAlterar from 'pages/Integracoes/LojaAplicativos/FrenteCaixa/PainelAdministradorFrenteCaixa/RelatorioPersonalizado/Formulario/Alterar';
import RelatorioPersonalizadoCadastrar from 'pages/Integracoes/LojaAplicativos/FrenteCaixa/PainelAdministradorFrenteCaixa/RelatorioPersonalizado/Formulario/Cadastrar';

import LayoutGuard from './LayoutGuard';

export const FrenteCaixaRoutes = [
  <Route path={ConstanteRotas.FRENTE_CAIXA} key="frente-caixa">
    <FrenteCaixaProvider>
      <LayoutGuard
        key={ConstanteRotas.FRENTE_CAIXA_PAINEL}
        path={ConstanteRotas.FRENTE_CAIXA_PAINEL}
        component={PainelAdministradorFrenteCaixa}
        meta={{ auth: true }}
        permissaoFuncionalidade={
          ConstanteFuncionalidades.FRENTE_CAIXA_CONFIGURAR
        }
        breadcrumb={[
          {
            title: 'Integrações',
            path: ConstanteRotas.LOJA_APLICATIVOS,
          },
          {
            title: 'Frente de Caixa',
          },
          {
            title: 'Painel',
            path: ConstanteRotas.FRENTE_CAIXA_PAINEL,
          },
        ]}
        exact
      />
      <LayoutGuard
        key={ConstanteRotas.FRENTE_CAIXA_ATIVACAO}
        path={ConstanteRotas.FRENTE_CAIXA_ATIVACAO}
        component={PainelAdministradorFrenteCaixaAtivacao}
        meta={{ auth: true }}
        permissaoFuncionalidade={
          ConstanteFuncionalidades.FRENTE_CAIXA_CONFIGURAR
        }
        breadcrumb={[
          {
            title: 'Integrações',
            path: ConstanteRotas.LOJA_APLICATIVOS,
          },
          {
            title: 'Frente de Caixa',
            path: ConstanteRotas.FRENTE_CAIXA_PAINEL,
          },
          {
            title: 'Ativação',
          },
        ]}
        exact
      />
      <LayoutGuard
        key={ConstanteRotas.FRENTE_CAIXA_CONFIGURACAO}
        path={ConstanteRotas.FRENTE_CAIXA_CONFIGURACAO}
        component={PainelAdministradorFrenteCaixaConfiguracao}
        meta={{ auth: true }}
        permissaoFuncionalidade={
          ConstanteFuncionalidades.FRENTE_CAIXA_CONFIGURAR
        }
        breadcrumb={[
          {
            title: 'Integrações',
            path: ConstanteRotas.LOJA_APLICATIVOS,
          },
          {
            title: 'Frente de Caixa',
            path: ConstanteRotas.FRENTE_CAIXA_PAINEL,
          },
          {
            title: 'Configurações',
          },
        ]}
        exact
      />
      <LayoutGuard
        key={ConstanteRotas.FRENTE_CAIXA_DEPARTAMENTOS}
        path={ConstanteRotas.FRENTE_CAIXA_DEPARTAMENTOS}
        component={ListarDepartamentos}
        meta={{ auth: true }}
        permissaoFuncionalidade={
          ConstanteFuncionalidades.FRENTE_CAIXA_CONFIGURAR
        }
        breadcrumb={[
          {
            title: 'Integrações',
            path: ConstanteRotas.LOJA_APLICATIVOS,
          },
          {
            title: 'Frente de Caixa',
            path: ConstanteRotas.FRENTE_CAIXA_PAINEL,
          },
          {
            title: 'Departamentos',
          },
        ]}
        exact
      />
      <LayoutGuard
        key={ConstanteRotas.FRENTE_CAIXA_DEPARTAMENTO_CADASTRAR}
        path={ConstanteRotas.FRENTE_CAIXA_DEPARTAMENTO_CADASTRAR}
        component={CadastrarDepartamento}
        meta={{ auth: true }}
        permissaoFuncionalidade={
          ConstanteFuncionalidades.FRENTE_CAIXA_CONFIGURAR
        }
        breadcrumb={[
          {
            title: 'Integrações',
            path: ConstanteRotas.LOJA_APLICATIVOS,
          },
          {
            title: 'Frente de Caixa',
            path: ConstanteRotas.FRENTE_CAIXA_PAINEL,
          },
          {
            title: 'Departamentos',
            path: ConstanteRotas.FRENTE_CAIXA_DEPARTAMENTOS,
          },
          {
            title: 'Novo',
          },
        ]}
        exact
      />
      <LayoutGuard
        key={ConstanteRotas.FRENTE_CAIXA_DEPARTAMENTO_ALTERAR}
        path={ConstanteRotas.FRENTE_CAIXA_DEPARTAMENTO_ALTERAR}
        component={AlterarDepartamento}
        meta={{ auth: true }}
        permissaoFuncionalidade={
          ConstanteFuncionalidades.FRENTE_CAIXA_CONFIGURAR
        }
        breadcrumb={[
          {
            title: 'Integrações',
            path: ConstanteRotas.LOJA_APLICATIVOS,
          },
          {
            title: 'Frente de Caixa',
            path: ConstanteRotas.FRENTE_CAIXA_PAINEL,
          },
          {
            title: 'Departamentos',
            path: ConstanteRotas.FRENTE_CAIXA_DEPARTAMENTOS,
          },
          {
            title: 'Alterar',
          },
        ]}
        exact
      />
      <LayoutGuard
        key={ConstanteRotas.FRENTE_CAIXA_DISPOSITIVOS}
        path={ConstanteRotas.FRENTE_CAIXA_DISPOSITIVOS}
        component={PainelAdministradorFrenteCaixaDispositivo}
        meta={{ auth: true }}
        permissaoFuncionalidade={
          ConstanteFuncionalidades.FRENTE_CAIXA_CONFIGURAR
        }
        breadcrumb={[
          {
            title: 'Integrações',
            path: ConstanteRotas.LOJA_APLICATIVOS,
          },
          {
            title: 'Frente de Caixa',
            path: ConstanteRotas.FRENTE_CAIXA_PAINEL,
          },
          {
            title: 'Dispositivos',
          },
        ]}
        exact
      />
      <LayoutGuard
        key={ConstanteRotas.FRENTE_CAIXA_ENTREGADORES}
        path={ConstanteRotas.FRENTE_CAIXA_ENTREGADORES}
        component={ListarEntregadores}
        meta={{ auth: true }}
        permissaoFuncionalidade={
          ConstanteFuncionalidades.FRENTE_CAIXA_CONFIGURAR
        }
        breadcrumb={[
          {
            title: 'Integrações',
            path: ConstanteRotas.LOJA_APLICATIVOS,
          },
          {
            title: 'Frente de Caixa',
            path: ConstanteRotas.FRENTE_CAIXA_PAINEL,
          },
          {
            title: 'Entregadores',
          },
        ]}
        exact
      />
      <LayoutGuard
        key={ConstanteRotas.FRENTE_CAIXA_ENTREGADOR_CADASTRAR}
        path={ConstanteRotas.FRENTE_CAIXA_ENTREGADOR_CADASTRAR}
        component={CadastrarEntregador}
        meta={{ auth: true }}
        permissaoFuncionalidade={
          ConstanteFuncionalidades.FRENTE_CAIXA_CONFIGURAR
        }
        breadcrumb={[
          {
            title: 'Integrações',
            path: ConstanteRotas.LOJA_APLICATIVOS,
          },
          {
            title: 'Frente de Caixa',
            path: ConstanteRotas.FRENTE_CAIXA_PAINEL,
          },
          {
            title: 'Entregadores',
            path: ConstanteRotas.FRENTE_CAIXA_ENTREGADORES,
          },
          {
            title: 'Novo',
          },
        ]}
        exact
      />
      <LayoutGuard
        key={ConstanteRotas.FRENTE_CAIXA_ENTREGADOR_ALTERAR}
        path={ConstanteRotas.FRENTE_CAIXA_ENTREGADOR_ALTERAR}
        component={AlterarEntregador}
        meta={{ auth: true }}
        permissaoFuncionalidade={
          ConstanteFuncionalidades.FRENTE_CAIXA_CONFIGURAR
        }
        breadcrumb={[
          {
            title: 'Integrações',
            path: ConstanteRotas.LOJA_APLICATIVOS,
          },
          {
            title: 'Frente de Caixa',
            path: ConstanteRotas.FRENTE_CAIXA_PAINEL,
          },
          {
            title: 'Entregadores',
            path: ConstanteRotas.FRENTE_CAIXA_ENTREGADORES,
          },
          {
            title: 'Alterar',
          },
        ]}
        exact
      />
      <LayoutGuard
        key={ConstanteRotas.FRENTE_CAIXA_FORMAS_RECEBIMENTO}
        path={ConstanteRotas.FRENTE_CAIXA_FORMAS_RECEBIMENTO}
        component={PainelAdministradorFrenteCaixaFormaRecebimento}
        meta={{ auth: true }}
        permissaoFuncionalidade={
          ConstanteFuncionalidades.FRENTE_CAIXA_CONFIGURAR
        }
        breadcrumb={[
          {
            title: 'Integrações',
            path: ConstanteRotas.LOJA_APLICATIVOS,
          },
          {
            title: 'Frente de Caixa',
            path: ConstanteRotas.FRENTE_CAIXA_PAINEL,
          },
          {
            title: 'TEF',
          },
        ]}
        exact
      />
      <LayoutGuard
        key={ConstanteRotas.FRENTE_CAIXA_GERENCIADORES_IMPRESSAO}
        path={ConstanteRotas.FRENTE_CAIXA_GERENCIADORES_IMPRESSAO}
        component={PainelAdministradorFrenteCaixaGerenciadorImpressao}
        meta={{ auth: true }}
        permissaoFuncionalidade={
          ConstanteFuncionalidades.FRENTE_CAIXA_CONFIGURAR
        }
        breadcrumb={[
          {
            title: 'Integrações',
            path: ConstanteRotas.LOJA_APLICATIVOS,
          },
          {
            title: 'Frente de Caixa',
            path: ConstanteRotas.FRENTE_CAIXA_PAINEL,
          },
          {
            title: 'Gerenciador de impressão',
          },
        ]}
        exact
      />
      <LayoutGuard
        key={ConstanteRotas.FRENTE_CAIXA_SETORES_ENTREGA}
        path={ConstanteRotas.FRENTE_CAIXA_SETORES_ENTREGA}
        component={ListarSetoresEntrega}
        meta={{ auth: true }}
        permissaoFuncionalidade={
          ConstanteFuncionalidades.FRENTE_CAIXA_CONFIGURAR
        }
        breadcrumb={[
          {
            title: 'Integrações',
            path: ConstanteRotas.LOJA_APLICATIVOS,
          },
          {
            title: 'Frente de Caixa',
            path: ConstanteRotas.FRENTE_CAIXA_PAINEL,
          },
          {
            title: 'Setor de entrega',
          },
        ]}
        exact
      />
      <LayoutGuard
        key={ConstanteRotas.FRENTE_CAIXA_SETOR_ENTREGA_CADASTRAR}
        path={ConstanteRotas.FRENTE_CAIXA_SETOR_ENTREGA_CADASTRAR}
        component={CadastrarSetorEntrega}
        meta={{ auth: true }}
        permissaoFuncionalidade={
          ConstanteFuncionalidades.FRENTE_CAIXA_CONFIGURAR
        }
        breadcrumb={[
          {
            title: 'Integrações',
            path: ConstanteRotas.LOJA_APLICATIVOS,
          },
          {
            title: 'Frente de Caixa',
            path: ConstanteRotas.FRENTE_CAIXA_PAINEL,
          },
          {
            title: 'Setor de entrega',
            path: ConstanteRotas.FRENTE_CAIXA_SETORES_ENTREGA,
          },
          {
            title: 'Novo',
          },
        ]}
        exact
      />
      <LayoutGuard
        key={ConstanteRotas.FRENTE_CAIXA_SETOR_ENTREGA_ALTERAR}
        path={ConstanteRotas.FRENTE_CAIXA_SETOR_ENTREGA_ALTERAR}
        component={AlterarSetorEntrega}
        meta={{ auth: true }}
        permissaoFuncionalidade={
          ConstanteFuncionalidades.FRENTE_CAIXA_CONFIGURAR
        }
        breadcrumb={[
          {
            title: 'Integrações',
            path: ConstanteRotas.LOJA_APLICATIVOS,
          },
          {
            title: 'Frente de Caixa',
            path: ConstanteRotas.FRENTE_CAIXA_PAINEL,
          },
          {
            title: 'Setor de entrega',
            path: ConstanteRotas.FRENTE_CAIXA_SETORES_ENTREGA,
          },
          {
            title: 'Alterar',
          },
        ]}
        exact
      />
      <LayoutGuard
        key="frente-caixa-detalhes"
        component={DetalhesIntegracaoFrenteCaixa}
        breadcrumb={[
          {
            title: 'Integrações',
          },
          {
            title: 'Loja de aplicativos',
            path: ConstanteRotas.LOJA_APLICATIVOS,
          },
          {
            title: 'Frente de Caixa',
          },
        ]}
        path={ConstanteRotas.FRENTE_CAIXA_DETALHES}
        exact
        meta={{ auth: true }}
      />
      <LayoutGuard
        key="frente-caixa-comercial"
        component={FrenteCaixaComercial}
        breadcrumb={[
          {
            title: 'Integrações',
          },
          {
            title: 'Loja de aplicativos',
            path: ConstanteRotas.LOJA_APLICATIVOS,
          },
          {
            title: 'Frente de Caixa',
          },
        ]}
        path={ConstanteRotas.FRENTE_CAIXA_COMERCIAL}
        exact
        meta={{ auth: true }}
      />
      <LayoutGuard
        key={ConstanteRotas.FRENTE_CAIXA_INTERMEDIADOR}
        path={ConstanteRotas.FRENTE_CAIXA_INTERMEDIADOR}
        component={IntermediadorListar}
        breadcrumb={[
          {
            title: 'Integrações',
            path: ConstanteRotas.LOJA_APLICATIVOS,
          },
          {
            title: 'Frente de Caixa',
            path: ConstanteRotas.FRENTE_CAIXA_PAINEL,
          },
          {
            title: 'Intermediador',
          },
        ]}
        meta={{ auth: true }}
        exact
      />
      <LayoutGuard
        key={ConstanteRotas.FRENTE_CAIXA_INTERMEDIADOR_CADASTRAR}
        path={ConstanteRotas.FRENTE_CAIXA_INTERMEDIADOR_CADASTRAR}
        component={IntermediadorCadastrar}
        breadcrumb={[
          {
            title: 'Integrações',
            path: ConstanteRotas.LOJA_APLICATIVOS,
          },
          {
            title: 'Frente de Caixa',
            path: ConstanteRotas.FRENTE_CAIXA_PAINEL,
          },
          {
            title: 'Intermediador',
            path: ConstanteRotas.FRENTE_CAIXA_INTERMEDIADOR,
          },
          {
            title: 'Novo',
          },
        ]}
        meta={{ auth: true }}
        exact
      />

      <LayoutGuard
        key={ConstanteRotas.FRENTE_CAIXA_INTERMEDIADOR_ALTERAR}
        path={ConstanteRotas.FRENTE_CAIXA_INTERMEDIADOR_ALTERAR}
        component={IntermediadorAlterar}
        breadcrumb={[
          {
            title: 'Integrações',
            path: ConstanteRotas.LOJA_APLICATIVOS,
          },
          {
            title: 'Frente de Caixa',
            path: ConstanteRotas.FRENTE_CAIXA_PAINEL,
          },
          {
            title: 'Intermediador',
            path: ConstanteRotas.FRENTE_CAIXA_INTERMEDIADOR,
          },
          {
            title: 'Alterar',
          },
        ]}
        meta={{ auth: true }}
        exact
      />
      <LayoutGuard
        key={ConstanteRotas.FRENTE_CAIXA_PERIODO_CAIXA}
        path={ConstanteRotas.FRENTE_CAIXA_PERIODO_CAIXA}
        component={PeriodoCaixaListar}
        breadcrumb={[
          {
            title: 'Integrações',
            path: ConstanteRotas.LOJA_APLICATIVOS,
          },
          {
            title: 'Frente de Caixa',
            path: ConstanteRotas.FRENTE_CAIXA_PAINEL,
          },
          {
            title: 'Período do caixa',
          },
        ]}
        meta={{ auth: true }}
        exact
      />
      <LayoutGuard
        key={ConstanteRotas.FRENTE_CAIXA_PERIODO_CAIXA_CADASTRAR}
        path={ConstanteRotas.FRENTE_CAIXA_PERIODO_CAIXA_CADASTRAR}
        component={PeriodoCaixaCadastrar}
        breadcrumb={[
          {
            title: 'Integrações',
            path: ConstanteRotas.LOJA_APLICATIVOS,
          },
          {
            title: 'Frente de Caixa',
            path: ConstanteRotas.FRENTE_CAIXA_PAINEL,
          },
          {
            title: 'Período do caixa',
            path: ConstanteRotas.FRENTE_CAIXA_PERIODO_CAIXA,
          },
          {
            title: 'Novo',
          },
        ]}
        meta={{ auth: true }}
        exact
      />
      <LayoutGuard
        key={ConstanteRotas.FRENTE_CAIXA_PERIODO_CAIXA_ALTERAR}
        path={ConstanteRotas.FRENTE_CAIXA_PERIODO_CAIXA_ALTERAR}
        component={PeriodoCaixaAlterar}
        breadcrumb={[
          {
            title: 'Integrações',
            path: ConstanteRotas.LOJA_APLICATIVOS,
          },
          {
            title: 'Frente de Caixa',
            path: ConstanteRotas.FRENTE_CAIXA_PAINEL,
          },
          {
            title: 'Período do caixa',
            path: ConstanteRotas.FRENTE_CAIXA_PERIODO_CAIXA,
          },
          {
            title: 'Alterar',
          },
        ]}
        meta={{ auth: true }}
        exact
      />
      <LayoutGuard
        key={ConstanteRotas.FRENTE_CAIXA_RELATORIO_PERSONALIZADO}
        path={ConstanteRotas.FRENTE_CAIXA_RELATORIO_PERSONALIZADO}
        component={RelatorioPersonalizadoListar}
        breadcrumb={[
          {
            title: 'Integrações',
            path: ConstanteRotas.LOJA_APLICATIVOS,
          },
          {
            title: 'Frente de Caixa',
            path: ConstanteRotas.FRENTE_CAIXA_PAINEL,
          },
          {
            title: 'Relatório personalizado',
          },
        ]}
        exact
        meta={{ auth: true }}
      />
      <LayoutGuard
        key={ConstanteRotas.FRENTE_CAIXA_RELATORIO_PERSONALIZADO_CADASTRAR}
        path={ConstanteRotas.FRENTE_CAIXA_RELATORIO_PERSONALIZADO_CADASTRAR}
        component={RelatorioPersonalizadoCadastrar}
        breadcrumb={[
          {
            title: 'Integrações',
            path: ConstanteRotas.LOJA_APLICATIVOS,
          },
          {
            title: 'Frente de Caixa',
            path: ConstanteRotas.FRENTE_CAIXA_PAINEL,
          },
          {
            title: 'Relatório personalizado',
            path: ConstanteRotas.FRENTE_CAIXA_RELATORIO_PERSONALIZADO,
          },
          {
            title: 'Novo',
          },
        ]}
        exact
        meta={{ auth: true }}
      />
      <LayoutGuard
        key={ConstanteRotas.FRENTE_CAIXA_RELATORIO_PERSONALIZADO_ALTERAR}
        path={ConstanteRotas.FRENTE_CAIXA_RELATORIO_PERSONALIZADO_ALTERAR}
        component={RelatorioPersonalizadoAlterar}
        breadcrumb={[
          {
            title: 'Integrações',
            path: ConstanteRotas.LOJA_APLICATIVOS,
          },
          {
            title: 'Frente de Caixa',
            path: ConstanteRotas.FRENTE_CAIXA_PAINEL,
          },
          {
            title: 'Relatório personalizado',
            path: ConstanteRotas.FRENTE_CAIXA_RELATORIO_PERSONALIZADO,
          },
          {
            title: 'Alterar',
          },
        ]}
        exact
        meta={{ auth: true }}
      />
    </FrenteCaixaProvider>
  </Route>,
];
