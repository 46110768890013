import { Flex, Text } from '@chakra-ui/react';
import React from 'react';

type OperationDetailsProps = {
  label: string;
  value: string | number;
};

export const OperationDetails = ({ label, value }: OperationDetailsProps) => (
  <Flex flexDir="column" w="50%" mt="4px">
    <Text
      textAlign="center"
      padding="2px"
      pt="4px"
      fontSize="2xs"
      bg="gray.100"
    >
      {label}
    </Text>
    <Text border="1px solid #bbb" bg="white" textAlign="center" mt="4px">
      {value}
    </Text>
  </Flex>
);
