import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';

export const shapeEditarEstoqueVariacaoModal = {
  estoqueAtual: yup.number().default(0),
  estoqueMinimo: yup.number().default(0),
};

const schemaEditarEstoqueVariacaoModal = yup
  .object()
  .shape(shapeEditarEstoqueVariacaoModal);

export const yupResolver = yupResolverInstance(
  schemaEditarEstoqueVariacaoModal
);

export const defaultValues = schemaEditarEstoqueVariacaoModal.default();

export const useForm = useFormInstance;
