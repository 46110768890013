import React from 'react';
import { Route, Switch } from 'react-router-dom';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
// import ConstanteFuncionalidades from '../constants/permissoes';

import ConferenciaEstoque from '../pages/ConferenciaEstoque';
import { FinalizarConferencia } from '../pages/ConferenciaEstoque/FinalizarConferencia';

export const ConferenciaEstoqueRoutes = () => (
  <Route path={ConstanteRotas.CONFERENCIA_ESTOQUE}>
    <Switch>
      <LayoutGuard
        key="conferencia-estoque-finalizar"
        component={FinalizarConferencia}
        breadcrumb={[
          {
            title: 'Estoque',
          },
          {
            title: 'Conferência de Estoque',
          },
        ]}
        path={ConstanteRotas.CONFERENCIA_ESTOQUE_FINALIZAR}
        exact
        meta={{ auth: true }}
      />

      <LayoutGuard
        key="conferencia-estoque"
        component={ConferenciaEstoque}
        breadcrumb={[
          {
            title: 'Estoque',
          },
          {
            title: 'Conferência de Estoque',
          },
        ]}
        path={[
          ConstanteRotas.CONFERENCIA_ESTOQUE,
          ConstanteRotas.CONFERENCIA_ESTOQUE_CONTINUAR,
        ]}
        exact
        meta={{ auth: true }}
      />
    </Switch>
  </Route>
);
