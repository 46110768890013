export type TipoProdutoFiscal =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 99
  | 16;

const TipoProdutoFiscalEnum = {
  MERCADORIA_PARA_REVENDA: 0,
  MATERIA_PRIMA: 1,
  EMBALAGEM: 2,
  PRODUTO_EM_PROCESSO: 3,
  PRODUTO_ACABADO: 4,
  SUBPRODUTO: 5,
  PRODUTO_INTERMEDIARIO: 6,
  MATERIAL_DE_USO_E_CONSUMO: 7,
  ATIVO_IMOBILIZADO: 8,
  SERVICOS: 9,
  OUTROS_INSUMOS: 10,
  OUTRAS: 99,

  properties: {
    0: { name: 'Mercadoria para revenda', value: 0 },
    1: { name: 'Matéria prima', value: 1 },
    2: { name: 'Embalagem', value: 2 },
    3: { name: 'Produto em processo', value: 3 },
    4: { name: 'Produto acabado', value: 4 },
    5: { name: 'Subproduto', value: 5 },
    6: { name: 'Produto intermediário', value: 6 },
    7: { name: 'Material de uso e consumo', value: 7 },
    8: { name: 'Ativo imobilizado', value: 8 },
    9: { name: 'Serviços', value: 9 },
    10: { name: 'Outros insumos', value: 10 },
    99: { name: 'Outras', value: 99 },
  },
};

export default TipoProdutoFiscalEnum;
