import React from 'react';
import ConstanteRotas from 'constants/rotas';

import { RelatorioVendas } from 'pages/Relatorio/Vendas';
import PermissaoRelatorioVendas from 'pages/Relatorio/Vendas/PermissaoRelatorioVendas';

import LayoutGuard from './LayoutGuard';

export const RelatorioVendasRoutes = [
  <LayoutGuard
    key="relatorio-vendas"
    component={RelatorioVendas}
    permissaoFuncionalidade={PermissaoRelatorioVendas()}
    breadcrumb={[
      {
        title: 'Relatórios',
      },
      {
        title: 'Vendas',
      },
    ]}
    path={ConstanteRotas.RELATORIO_DAS_VENDAS}
    exact
    meta={{ auth: true }}
  />,
];
