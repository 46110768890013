import React, { useState, useCallback, ChangeEvent } from 'react';

import consultarViaCep, { CepResponse } from 'services/viacep';
import { cepMask } from 'helpers/format/fieldsMasks';

import { LupaIcon } from 'icons';

import Input, { InputProps } from '../../PDV/Input';

interface InputCepProps extends InputProps {
  getCepData: (data: CepResponse) => void;
}

export const InputCep = ({
  getCepData,
  isDisabled,
  onChange,
  ...rest
}: InputCepProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const getData = useCallback(
    (sucesso: boolean, dados: CepResponse | string) => {
      if (sucesso) getCepData(dados as CepResponse);
    },
    [getCepData]
  );

  const handleGetCep = useCallback(
    async (unformattedValue: string) => {
      if (unformattedValue.length === 8) {
        setIsLoading(true);

        consultarViaCep(unformattedValue, getData);

        setIsLoading(false);
      }
    },
    [getData]
  );

  const getFormattedValue = useCallback(
    (value: string) => {
      const unformattedValue = value.replace(/\D/g, '');

      if (unformattedValue.length === 8) handleGetCep(unformattedValue);

      return cepMask(value);
    },
    [handleGetCep]
  );

  return (
    <Input
      {...rest}
      onInput={(e: React.FormEvent<HTMLInputElement>) => {
        if (e.currentTarget.value)
          e.currentTarget.value = getFormattedValue(e.currentTarget.value);
      }}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        e.currentTarget.value = getFormattedValue(e.currentTarget.value);

        if (onChange) onChange(e);
      }}
      inputRightElement
      icon={LupaIcon}
      isDisabled={isLoading || isDisabled}
      maxLength={9}
    />
  );
};
