import { Text, Flex, GridItem, Td, Tr, Box, Icon } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { FiChevronLeft } from 'react-icons/fi';

import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { SearchInput } from 'components/update/Input/SearchInput';
import { FilterSelect } from 'components/update/Select/FilterSelect';
import {
  ActionMenuItem,
  ActionsMenu,
} from 'components/update/Table/ActionsMenu';
import { PagedTable } from 'components/update/Table/PagedTable';
import { ButtonCadastrarNovo } from 'components/Layout/ButtonCadastrarNovo';
import { StatusCircle } from 'components/update/Table/StatusCircle';
import { ContainerHeader } from 'pages/Integracoes/LojaAplicativos/Layout/ContainerHeader';

import usePeriodoCaixaListar, { FiltrosProps } from './hooks';
import { usePainelFrenteCaixa } from '../../hooks';

const PeriodoCaixaListar = () => {
  const { handleVoltarPainelAdm } = usePainelFrenteCaixa();

  const {
    atualizarFiltros,
    obterPeriodoCaixaListaPaginada,
    alterarPeriodoCaixa,
    inativarPeriodoCaixa,
    cadastrarPeriodoCaixa,
    isLoading,
    totalRegistros,
    periodoCaixaLista,
    pagedTableRef,
  } = usePeriodoCaixaListar();

  const formMethods = useForm<FiltrosProps>({
    defaultValues: {
      nome: '',
      ativo: true,
    },
  });

  const handleAtualizarFiltros = () => {
    const filtrosForm = formMethods.getValues();

    atualizarFiltros(filtrosForm);
  };

  return (
    <FormProvider {...formMethods}>
      <ContainerHeader bg="#FF005A" gap={2} color="white" alignItems="center">
        <Box cursor="pointer" mr="10px" onClick={() => handleVoltarPainelAdm()}>
          <Icon fontSize="25px" as={FiChevronLeft} />
        </Box>
        <Text fontSize="16px" fontWeight="semibold">
          Período do caixa
        </Text>
      </ContainerHeader>
      <Box position="relative" p={6}>
        <SimpleGridForm>
          <GridItem colSpan={12}>
            <Flex
              justifyContent="space-between"
              direction={['column', 'column', 'row']}
              gap={6}
            >
              <Flex flex={2}>
                <SearchInput
                  id="nome"
                  name="nome"
                  placeholder="Buscar período do caixa por nome"
                  onEnterKeyPress={() => handleAtualizarFiltros()}
                />
              </Flex>
              <Flex flex={1}>
                <FilterSelect
                  id="ativo"
                  name="ativo"
                  options={[
                    { label: 'Ativos', value: true },
                    { label: 'Inativos', value: false },
                    { label: 'Todos', value: null },
                  ]}
                  onSelect={() => handleAtualizarFiltros()}
                  isDisabled={isLoading}
                />
              </Flex>
              <Flex justifyContent="flex-end" flex={2}>
                <ButtonCadastrarNovo
                  funcionalidade=""
                  onClick={() => cadastrarPeriodoCaixa()}
                />
              </Flex>
            </Flex>
          </GridItem>
          <GridItem colSpan={12}>
            <PagedTable
              ref={pagedTableRef}
              loadColumnsData={obterPeriodoCaixaListaPaginada}
              itemsTotalCount={totalRegistros}
              defaultKeyOrdered="nome"
              isLoading={isLoading}
              variant="card"
              pb="10px"
              pt={['10px', '10px', '']}
              bg="transparent"
              boxShadow="none"
              paddingRight="2px"
              paddingLeft="2px"
              paginationHasDivider={false}
              tableHeaders={[
                {
                  content: <StatusCircle hasValue={false} />,
                  key: 'Ativo',
                  isOrderable: true,
                  width: '1px',
                },
                {
                  key: 'nome',
                  content: 'Nome',
                  w: 'full',
                  isOrderable: true,
                },
                { key: 'acoes', content: 'Ações', w: '10px' },
              ]}
              renderTableRows={periodoCaixaLista?.map((periodoCaixa) => (
                <>
                  <Tr
                    sx={{
                      boxShadow: '0px 0px 4px #00000029',
                      borderRadius: '6px',
                      '& > td': {
                        height: '30px',
                        bg: 'white',
                      },
                    }}
                  >
                    <Td>
                      <StatusCircle isActive={periodoCaixa?.ativo} />
                    </Td>
                    <Td>
                      <Text
                        w="max"
                        cursor="pointer"
                        onClick={() => alterarPeriodoCaixa(periodoCaixa?.id)}
                        _hover={{
                          textDecor: 'underline',
                        }}
                      >
                        {periodoCaixa?.nome}
                      </Text>
                    </Td>
                    <Td>
                      <ActionsMenu
                        items={
                          [
                            {
                              content: 'Alterar',
                              onClick: () =>
                                alterarPeriodoCaixa(periodoCaixa?.id),
                            },
                            periodoCaixa.ativo && {
                              content: 'Inativar',
                              onClick: () =>
                                inativarPeriodoCaixa(
                                  periodoCaixa?.id,
                                  periodoCaixa?.ativo
                                ),
                            },
                          ].filter(Boolean) as ActionMenuItem[]
                        }
                      />
                    </Td>
                  </Tr>
                  <Box h="5px" />
                </>
              ))}
            />
            <Flex />
          </GridItem>
        </SimpleGridForm>
      </Box>
    </FormProvider>
  );
};

export default PeriodoCaixaListar;
