import { useCallback, useEffect, useState } from 'react';
import {
  ModalContent,
  ModalBody,
  Flex,
  ModalHeader,
  ModalFooter,
  Text,
  Divider,
  Button,
  FormLabel,
  Icon,
  GridItem,
  useMediaQuery,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { FormProvider, useForm } from 'react-hook-form';

import { statusOperacaoOptions } from 'constants/enum/statusOperacao';
import { IdentificacaoIntegracaoOptions } from 'constants/enum/IdentificacaoIntegracao';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { StatusPesquisaClientesFornecedor } from 'constants/enum/statusPesquisaClientesFornecedor';
import { formatOptionsSelectClient } from 'helpers/format/formatSelectClient';
import api, { ResponseApi } from 'services/api';

import { LupaIcon } from 'icons';
import InputDateRange from 'components/PDV/InputDateRange';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import AsyncSelect from 'components/PDV/Select/AsyncSelectPadrao';
import { NumberInput } from 'components/update/Input/NumberInput';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { SelectMulti } from 'components/Select/SelectMultiCheckbox';

import { FormData } from '../validationForm';

type ClientesOptionResponse = {
  id: string;
  nome: string;
  endereco: string;
  codigo: number;
  cpfCnpj: string;
};

type ListCaixaAtivoProps = {
  label: string;
  value: string;
};

type CaixaAtivoProps = {
  id: string;
  nome: string;
};
interface ModalBuscaAvancadaProps {
  isOpen: boolean;
  onClose: () => void;
  onClear: () => void;
  handleSubmitModalBuscaAvancada: (data: FormData) => void;
  existeCaixaAberto: boolean;
  filtros: FormData;
  opcoesTipoOperacao: OpcoesTipoOperacaoProps[];
}

type OpcoesTipoOperacaoProps = {
  label: string;
  value: number;
};

const ModalBuscaAvancada = ({
  isOpen,
  onClose,
  onClear,
  handleSubmitModalBuscaAvancada,
  existeCaixaAberto,
  filtros,
  opcoesTipoOperacao,
}: ModalBuscaAvancadaProps) => {
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const [listCaixaAtivo, setListCaixaAtivo] = useState<ListCaixaAtivoProps[]>(
    []
  );

  const formMethods = useForm<FormData>();
  const { handleSubmit: submit, reset } = formMethods;

  const handleSubmit = submit(async (data: FormData) => {
    const isTodosCaixasSelecionados =
      (data?.contasFinanceirasId || []).length === listCaixaAtivo.length;

    handleSubmitModalBuscaAvancada({
      ...data,
      isTodosCaixasSelecionados,
    });

    onClose();
  });

  const getClientesOptions = useCallback(async (inputValue: string) => {
    const response = await api.get<void, ResponseApi<ClientesOptionResponse[]>>(
      ConstanteEnderecoWebservice.CLIENTE_FORNECEDOR_LISTAR_SELECT,
      {
        params: {
          cpfCnpjNomeApelidoCodigoExterno: inputValue,
          filtroTipoCadastroPessoa: StatusPesquisaClientesFornecedor.CLIENTES,
        },
      }
    );
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        return response.dados.map((client) => {
          const option = formatOptionsSelectClient(client);
          return option;
        });
      }
    }
    return [];
  }, []);

  const getCaixaOptions = useCallback(async () => {
    const response = await api.get<void, ResponseApi<CaixaAtivoProps[]>>(
      ConstanteEnderecoWebservice.CONTA_FINANCEIRA_LISTAR_CAIXA_ATIVO
    );
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso) {
        setListCaixaAtivo(
          response.dados.map((data) => ({
            label: data.nome,
            value: data.id,
          }))
        );
      }
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      reset(filtros);
    }
  }, [filtros, isOpen, reset]);

  useEffect(() => {
    getCaixaOptions();
  }, [getCaixaOptions]);

  return (
    <ModalPadraoChakra
      onClose={onClose}
      isOpen={isOpen}
      isCentered={isLargerThan900}
      size="5xl"
    >
      <ModalContent
        marginBottom={{ base: 0, sm: '3.75rem' }}
        marginTop={{ base: 0, sm: '3.75rem' }}
        h="auto"
        bg="gray.50"
        w={['full', 'full', '900px']}
      >
        <ModalHeader>
          <Text color="primary.50" fontSize="md">
            Busca avançada
          </Text>
          <Text color="gray.700" fontSize="xs">
            Utilize os filtros de busca para uma pesquisa específica. Todos os
            campos são acumulativos.
          </Text>
          <Divider orientation="horizontal" mt="16px" />
        </ModalHeader>
        <ModalBody pt="0" pb="0">
          <FormProvider {...formMethods}>
            <SimpleGridForm>
              <GridItem colSpan={[12, 12, 8]}>
                <AsyncSelect
                  id="clienteId"
                  name="clienteId"
                  label="Nome do cliente"
                  isClearable
                  placeholder="Selecione a razão social ou nome fantasia"
                  asControlledByObject
                  handleGetOptions={getClientesOptions}
                  colSpan={6}
                  shouldAppearTheAddress
                />
              </GridItem>
              <GridItem colSpan={[12, 12, 4]}>
                <NumberInput
                  id="numeroOperacao"
                  name="numeroOperacao"
                  label="Número da operação"
                  scale={0}
                  placeholder="Informe o número"
                  defaultValue={undefined}
                  isDisabled={false}
                  colorScheme="secondary.300"
                  color="black"
                />
              </GridItem>
              <GridItem colSpan={[12, 12, 4]}>
                <NumberInput
                  id="numeroPedidoPlataforma"
                  name="numeroPedidoPlataforma"
                  label="Número da venda na integração "
                  scale={0}
                  placeholder="Informe o número"
                  defaultValue={undefined}
                  isDisabled={false}
                  colorScheme="secondary.300"
                  color="black"
                  helperText="Utilize este campo para localizar uma operação que tenha origem em alguma das integrações. É possível pesquisar, por exemplo, o número do pedido da plataforma Tray ou também o código da venda gerada pelos sistemas Frente de Caixa e PDV Offline."
                />
              </GridItem>
              <GridItem colSpan={[12, 12, 4]}>
                <FormLabel
                  mb="4px"
                  fontSize="sm"
                  color="black"
                  height="14px"
                  lineHeight="1.2"
                >
                  Tipo de operação
                </FormLabel>
                <SelectMulti
                  name="identificacaoTipoOperacao"
                  id="identificacaoTipoOperacao"
                  placeholder="Escolha quais operações deseja visualizar"
                  options={opcoesTipoOperacao}
                  variant="outline"
                  isMulti
                  isSearchable={false}
                  closeMenuOnSelect={false}
                />
              </GridItem>
              <GridItem colSpan={[12, 12, 4]}>
                <FormLabel
                  mb="4px"
                  fontSize="sm"
                  color="black"
                  height="14px"
                  lineHeight="1.2"
                >
                  Período
                </FormLabel>
                <InputDateRange
                  borderRadius="md"
                  name="dataEmissao"
                  startDateName="dataEmissaoInicio"
                  endDateName="dataEmissaoFim"
                  maxDate={new Date()}
                />
              </GridItem>

              <GridItem colSpan={[12, 12, 4]}>
                <SelectPadrao
                  label="Status"
                  id="status"
                  isSearchable={false}
                  name="status"
                  required
                  defaultValue={null}
                  options={statusOperacaoOptions}
                />
              </GridItem>
              <GridItem colSpan={[12, 12, 4]}>
                <SelectPadrao
                  label="Origem"
                  id="origem"
                  isSearchable={false}
                  name="origem"
                  options={IdentificacaoIntegracaoOptions}
                />
              </GridItem>
              <GridItem colSpan={[12, 12, 4]}>
                <SelectMulti
                  id="contasFinanceirasId"
                  closeMenuOnSelect={false}
                  name="contasFinanceirasId"
                  label="Caixa"
                  isMulti
                  textLabelSelectAll="Todos os caixas"
                  variant="outline"
                  options={listCaixaAtivo}
                  isSearchable={false}
                  isClearable={false}
                  isDisabled={!existeCaixaAberto || listCaixaAtivo.length < 1}
                />
              </GridItem>
            </SimpleGridForm>
          </FormProvider>
        </ModalBody>
        <ModalFooter flexDirection="column" mt={6}>
          <Divider orientation="horizontal" mb="16px" />
          <Flex width="100%" justifyContent="center">
            <Button
              variant="cancelOutline"
              mr="30px"
              colorScheme="gray"
              borderRadius="full"
              w="120"
              onClick={() => onClose()}
            >
              Cancelar
            </Button>
            <Button
              id="limparFiltros"
              name="limparFiltros"
              variant="outlineDefault"
              colorScheme="gray"
              h="35px"
              w="150px"
              mr="30px"
              borderRadius="16px"
              onClick={() => onClear()}
            >
              Limpar filtros
            </Button>
            <Button
              id="pesquisar"
              name="pesquisar"
              variant="solid"
              colorScheme="aquamarine"
              leftIcon={<Icon as={LupaIcon} />}
              h="35px"
              w="150px"
              borderRadius="16px"
              onClick={() => handleSubmit()}
            >
              Pesquisar
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export default ModalBuscaAvancada;
