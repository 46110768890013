import { useCallback } from 'react';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

export type ProdutoCorTamanhoProps = {
  produtoId: string;
  produtoCorTamanhoId: string;
  nome: string;
};

const useProdutoFormulario = () => {
  const getProductsWithSameGtinEan = useCallback(async (value?: string) => {
    if (value) {
      const response = await api.get<
        void,
        ResponseApi<ProdutoCorTamanhoProps[]>
      >(ConstanteEnderecoWebservice.PRODUTO_COR_TAMANHO_OBTER_POR_EAN, {
        params: {
          s: value,
        },
      });

      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response?.sucesso && response?.dados) {
          return response.dados;
        }
      }
    }

    return [];
  }, []);

  const getProductsWithSameExternalCode = useCallback(
    async (value?: string) => {
      if (value) {
        const response = await api.get<
          void,
          ResponseApi<ProdutoCorTamanhoProps[]>
        >(
          ConstanteEnderecoWebservice.PRODUTO_COR_TAMANHO_OBTER_POR_CODIGO_EXTERNO,
          {
            params: {
              s: value,
            },
          }
        );

        if (response) {
          if (response?.avisos) {
            response.avisos.forEach((aviso) => toast.warning(aviso));
          }

          if (response?.sucesso && response?.dados) {
            return response.dados;
          }
        }
      }

      return [];
    },
    []
  );

  const updateProductExternalCode = useCallback(
    async (produtoCorTamanhoId: string, produtoCorTamanhoCodigo: string) => {
      const response = await api.patch<void, ResponseApi<null>>(
        ConstanteEnderecoWebservice.PRODUTO_COR_TAMANHO_ALTERAR_CODIGO_EXTERNO.replace(
          '{variacaoId}',
          produtoCorTamanhoId
        ).replace('{codigo}', produtoCorTamanhoCodigo)
      );

      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response?.sucesso) {
          return {
            success: true,
            data: { produtoCorTamanhoId, produtoCorTamanhoCodigo },
          };
        }
      }

      return {
        success: false,
        data: { produtoCorTamanhoId, produtoCorTamanhoCodigo },
      };
    },
    []
  );

  const updateProductGtinEan = useCallback(
    async (produtoCorTamanhoId: string, produtoCorTamanhoCodigo: string) => {
      const response = await api.patch<void, ResponseApi<null>>(
        ConstanteEnderecoWebservice.PRODUTO_COR_TAMANHO_ALTERAR_EAN.replace(
          '{variacaoId}',
          produtoCorTamanhoId
        ).replace('{codigo}', produtoCorTamanhoCodigo)
      );

      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response?.sucesso) {
          return {
            success: true,
            data: { produtoCorTamanhoId, produtoCorTamanhoCodigo },
          };
        }
      }

      return {
        success: false,
        data: { produtoCorTamanhoId, produtoCorTamanhoCodigo },
      };
    },
    []
  );

  return {
    getProductsWithSameGtinEan,
    getProductsWithSameExternalCode,
    updateProductExternalCode,
    updateProductGtinEan,
  };
};

export default useProdutoFormulario;
