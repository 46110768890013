import { createBreakpoints } from '@chakra-ui/theme-tools';

const breakpoints = createBreakpoints({
  sm: '700px',
  md: '900px',
  lg: '1200px',
  xl: '1800px',
  '2xl': '2500px',
});

export default breakpoints;
