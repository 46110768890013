import React, { useMemo, useCallback, useState } from 'react';
import { HStack, Button, useMediaQuery, Grid } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { GlobalHotKeys } from 'react-hotkeys';
import { toast } from 'react-toastify';

import TipoContaFinanceiraEnum from 'constants/enum/tipoContaFinanceira';
import ConstanteRotasPDV from 'constants/rotasPDV';
import { SubstituirParametroRota } from 'constants/rotas';
import { useOperacaoContext } from 'store/PDV/Operacao';
import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';
import { usePagamentoContext } from 'store/PDV/Pagamento';
import finalizarVenda from 'helpers/api/Operacao/finalizarVenda';
import MeioPagamentoEnum from 'constants/enum/fiscal/meioPagamento';
import { useInformacoesGeraisContext } from 'store/PDV/InformacoesGerais';

import CaixaAberto from 'components/PDV/Layout/Footer/CaixaAberto';
import SelecionarVendedor from 'components/PDV/Layout/Footer/SelecionarVendedor';
import { SelecionarLocalEstoque } from 'components/PDV/Layout/Footer/SelecionarLocalDeEstoque';
import { ButtonDefault } from 'components/Button/ButtonChakra';

interface FooterProps {
  handleCancelar: () => void;
}

const Footer = ({ handleCancelar }: FooterProps) => {
  const { valorPago, valorRestante } = usePagamentoContext();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFinalizar, setIsLoadingFinalizar] = useState(false);
  const history = useHistory();
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const {
    handleChangeVendedor,
    operacaoId,
    handleChangeLocalEstoque,
  } = useOperacaoContext();
  const { watch } = useFormContext();
  const { movimentacoesFinanceiras } = usePagamentoContext();
  const {
    contaFinanceira,
    possuiCadastroOutrosCaixas,
    localDeEstoqueSelecionado,
    locaisDeEstoque,
    localEstoquePadraoDoUsuario,
    handleSetInformacoesLocalEstoque,
    operacaoItemlength,
  } = useInformacoesGeraisContext();

  const identificacaoTipoOperacao = watch('identificacaoTipoOperacao');

  const isOrcamento = useMemo(
    () => identificacaoTipoOperacao === IdentificacaoTipoOperacaoEnum.ORCAMENTO,
    [identificacaoTipoOperacao]
  );
  const hasMovimentacoes = useMemo(
    () => (movimentacoesFinanceiras || []).length > 0,
    [movimentacoesFinanceiras]
  );

  const hasMovimentacoesFinanceirasComFormaPagamentoZoop = movimentacoesFinanceiras?.some(
    (movimentacao) => {
      if (
        movimentacao.regraMeioPagamento === MeioPagamentoEnum.BoletoZoop ||
        movimentacao.regraMeioPagamento === MeioPagamentoEnum.PixEnvioZoop ||
        movimentacao.regraMeioPagamento === MeioPagamentoEnum.PixPresencialZoop
      ) {
        return true;
      }
      return false;
    }
  );

  const existeValorRestante = valorRestante.toFixed(2) !== '0.00';

  const validatePagamento =
    contaFinanceira?.tipoContaFinanceira ===
      TipoContaFinanceiraEnum.CONTA_COFRE && possuiCadastroOutrosCaixas;

  const handleVoltar = useCallback(async () => {
    if (operacaoId) {
      history.push(
        SubstituirParametroRota(
          ConstanteRotasPDV.PDV_LANCAMENTO_ID,
          'id?',
          operacaoId
        )
      );
    }
  }, [history, operacaoId]);

  const handleFinalizar = useCallback(async () => {
    if (operacaoId) {
      setIsLoadingFinalizar(true);
      history.push(
        SubstituirParametroRota(
          ConstanteRotasPDV.PDV_FINALIZAR,
          'id',
          operacaoId
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, operacaoId]);

  const handleFinalizarVenda = useCallback(async () => {
    setIsLoading(true);

    if (movimentacoesFinanceiras?.length === 0) {
      toast.warning('Para finalizar a venda, adicione uma forma de pagamento.');
      return;
    }
    if (valorRestante > valorPago) {
      toast.warning('Não é possível gerar uma venda com recebimento parcial.');
      return;
    }

    if (operacaoId && contaFinanceira && hasMovimentacoes) {
      const success = await finalizarVenda(operacaoId);

      if (success) {
        handleSetInformacoesLocalEstoque(localEstoquePadraoDoUsuario.current);
        handleFinalizar();
      } else {
        setIsLoading(false);
      }
    }
    setIsLoading(false);
  }, [
    contaFinanceira,
    handleFinalizar,
    hasMovimentacoes,
    movimentacoesFinanceiras,
    operacaoId,
    valorRestante,
    valorPago,
    handleSetInformacoesLocalEstoque,
    localEstoquePadraoDoUsuario,
  ]);

  const keyMap = {
    F9: ['f9'],
    F10: ['f10'],
  };

  const handlersHotKeys = {
    F9: (event: any) => {
      const validateGerarVenda = !hasMovimentacoes || existeValorRestante;

      if (!validateGerarVenda && !isLoading && !validatePagamento) {
        event.preventDefault();
        handleFinalizarVenda();
      }
    },
    F10: (event: any) => {
      event.preventDefault();

      handleVoltar();
    },
  };
  return (
    <>
      <GlobalHotKeys keyMap={keyMap} handlers={handlersHotKeys} allowChanges />
      <HStack
        spacing={6}
        justifyContent="space-between"
        maxW="100vw"
        px={{ base: 0, md: 2, lg: 10 }}
      >
        <Grid
          templateColumns={isLargerThan900 ? 'auto 1fr' : '1fr'}
          w="full"
          gap={{ base: '1', sm: '2' }}
        >
          {isLargerThan900 && (
            <HStack
              spacing={{ base: 8, md: 2, lg: 16 }}
              align="center"
              w="full"
            >
              <CaixaAberto />
              <SelecionarVendedor
                name="vendedor"
                onSubmit={(newVendedor) => {
                  handleChangeVendedor(newVendedor);
                }}
              />
              {localEstoquePadraoDoUsuario.current.id !==
              localDeEstoqueSelecionado.id ? (
                <SelecionarLocalEstoque
                  localDeEstoqueSelecionado={localDeEstoqueSelecionado}
                  isDisabled={!!operacaoItemlength}
                  listarEstoques={locaisDeEstoque}
                  onSubmit={async (localEstoque) => {
                    if (operacaoId) {
                      await handleChangeLocalEstoque(localEstoque.id);
                    }
                    handleSetInformacoesLocalEstoque(localEstoque);
                  }}
                />
              ) : null}
            </HStack>
          )}

          <HStack
            spacing={{ base: 2, sm: 4, lg: 6 }}
            justifyContent={{ base: 'center', md: 'flex-end' }}
            w="full"
            overflowX="auto"
          >
            {isLargerThan900 && (
              <Button
                variant="outline"
                size="sm"
                maxW={{ base: '65px', sm: '100px' }}
                minW={{ base: '65px', sm: '70px' }}
                w="full"
                _hover={{
                  bg: 'white',
                  color: 'black',
                  border: '1px solid',
                  borderColor: 'white',
                }}
                onClick={() => {
                  handleVoltar();
                }}
              >
                Voltar
              </Button>
            )}
            {isLargerThan900 && (
              <Button
                variant="outline"
                size="sm"
                maxW={{ base: '75px', sm: '100px' }}
                minW="70px"
                w="full"
                border="1px solid"
                _hover={{
                  bg: 'red.500',
                  color: 'white',
                  border: '1px solid',
                  borderColor: 'red.500',
                }}
                _active={{
                  bg: 'red.600',
                  borderColor: 'red.600',
                }}
                onClick={handleCancelar}
              >
                Cancelar
              </Button>
            )}
            <ButtonDefault
              variant={isOrcamento ? 'solid' : 'outlineDefault'}
              colorScheme={isOrcamento ? 'secondary' : 'gray'}
              size={isLargerThan900 ? 'sm' : 'lg'}
              minW="130px"
              maxW={{ base: isOrcamento ? '160px' : '140px', sm: '180px' }}
              width="full"
              isLoading={isLoadingFinalizar}
              color={isOrcamento ? 'black' : 'gray.300'}
              onClick={handleFinalizar}
              isDisabled={hasMovimentacoesFinanceirasComFormaPagamentoZoop}
              sx={{
                '&:hover': {
                  bg: isOrcamento ? 'secondary.400' : 'primary.800',
                  color: isOrcamento ? 'black' : 'gray.300',
                },
              }}
            >
              {isOrcamento ? 'Finalizar orçamento' : 'Finalizar pedido'}
            </ButtonDefault>
            <ButtonDefault
              variant={isOrcamento ? 'outline' : 'solid'}
              colorScheme={isOrcamento ? 'gray' : 'secondary'}
              color={isOrcamento ? 'gray.300' : 'black'}
              bg={isOrcamento ? 'primary.800' : 'secondary.400'}
              borderColor={isOrcamento ? 'gray.300' : 'secondary.400'}
              size={isLargerThan900 ? 'sm' : 'lg'}
              maxW={{ base: '110px', sm: '180px' }}
              minW={{ base: isOrcamento ? '110px' : '140px', sm: '140px' }}
              width="full"
              isLoading={isLoading}
              onClick={handleFinalizarVenda}
              isDisabled={
                !hasMovimentacoes || existeValorRestante || validatePagamento
              }
              sx={{
                '&:disabled': {
                  opacity: 1,
                  background: 'pdv.textColor',
                  border: 'none',
                  color: 'pdv.buttonPagamento',
                },
                '&:hover': {
                  bg: isOrcamento ? 'pdv.menuButton' : 'secondary.300',
                  _disabled: { bg: 'pdv.textColor' },
                },
              }}
            >
              Gerar venda
            </ButtonDefault>
          </HStack>
        </Grid>
      </HStack>
    </>
  );
};

export default Footer;
