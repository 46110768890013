import { useState, useEffect, useRef, useCallback } from 'react';
import {
  Box,
  Text,
  Stack,
  Button,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Divider,
  Heading,
  ModalProps,
  GridItem,
  useDisclosure,
  Flex,
  useMediaQuery,
  Icon,
  ScaleFade,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { create, InstanceProps } from 'react-modal-promise';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { DecimalMask, moneyMask } from 'helpers/format/fieldsMasks';
import { formatDate } from 'helpers/format/formatStringDate';
import MeioPagamentoEnum from 'constants/enum/fiscal/meioPagamento';

import {
  PagamentosCartaoDebitoIcon,
  FinanceiroTroco,
  FinanceiroParcial,
} from 'icons';
import Input from 'components/PDV/Input';
import { NumberInput } from 'components/update/Input/NumberInput';
import Select from 'components/PDV/Select/SelectPadrao';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { DateInput } from 'components/update/Input/DateInput';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { ButtonDefault } from 'components/Button/ButtonChakra';
import { ModalInserirVoucher } from 'components/PDV/Modal/ModalInserirVoucher';

import {
  yupResolver,
  FormData,
  ClientePadraoSistemaResponse,
} from './validationForm';

type ModalBaixarContasResponse = {
  sucesso: boolean;
  reloadHeards: boolean;
};

type BaixarContaItem = {
  movimentacaoFinanceiraId: string;
  valor: number;
  desconto?: number;
  multa: number;
  juros: number;
  operacaoId?: string;
};

type ModalBaixarContasProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalBaixarContasResponse> & {
    baixarContaItems: BaixarContaItem[];
    compartilharContaRecebida?: (contas: BaixarContaItem[]) => void;

    clienteFornecedorRazaoSocial?: string;
    clienteFornecedorNome?: string;
    clienteFornecedorId?: string;
    dataVencimento?: Date;
    parcela: string;
    aparecerClienteFornecedorRazaoSocial?: boolean;
    valorMultas?: number;
    valorJuros?: number;
    valorDesconto?: number;
    valorOriginal?: number;

    casasDecimaisValor: number;
    dataEmissao?: Date;
  };

type FormaRecebimentoProps = {
  id: string;
  nome: string;
  contaFinanceiraId: string;
  lancarFatura: boolean;
  diaVencimento: number;
  parcelas: {
    descricao?: string;
    qtdParcelas?: number;
    id?: string;
  };
  regraMeioPagamento: number;
};

type ContaFinanceiraProps = {
  id: string;
  nome: string;
};

type Option = {
  label: string;
  value: string;
  lancarFatura?: boolean;
  diaVencimento?: number;
};

type CaixaAberto = {
  id: string;
  nome: string;
};

type BaixaContasProps = {
  valorTroco: number | string;
  valorBaixaParcial: number | string;
};

export const ModalBaixarContas = create<
  ModalBaixarContasProps,
  ModalBaixarContasResponse
>(
  ({
    onReject,
    onResolve,
    baixarContaItems,
    clienteFornecedorRazaoSocial,
    aparecerClienteFornecedorRazaoSocial,
    clienteFornecedorNome,
    clienteFornecedorId,
    dataVencimento,
    casasDecimaisValor,
    parcela,
    dataEmissao,
    valorMultas,
    valorJuros,
    valorDesconto,
    valorOriginal,
    compartilharContaRecebida,
    ...rest
  }) => {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const [isResponsiveVersion] = useMediaQuery('(min-width: 900px)');

    const isMultipla: boolean = baixarContaItems.length > 1;

    const zerarMultaJuros = useRef<boolean>(false);

    const valorOriginalAReceber = baixarContaItems.reduce(
      (acc, curr) => acc + curr.valor,
      0
    );
    const caixaAberto = useRef({} as CaixaAberto);

    const formMethods = useForm<FormData>({
      resolver: yupResolver,
      defaultValues: {
        dataPagamento: new Date(),
        juros: Number(valorJuros?.toFixed(casasDecimaisValor)) || 0,
        multa: Number(valorMultas?.toFixed(casasDecimaisValor)) || 0,
        desconto: valorDesconto || 0,
        valorRecebido: valorOriginalAReceber,
      },
    });

    const contasFinanceiras = useRef<ContaFinanceiraProps[]>([]);

    const [
      baixaContasParicalProps,
      setIsBaixaContasParcialProps,
    ] = useState<BaixaContasProps>({} as BaixaContasProps);

    const [
      isFormaPagamentoOptionsLoading,
      setIsFormaPagamentoOptionsLoading,
    ] = useState<boolean>(false);
    const [formasPagamentoOptions, setIsFormasPagamentoOptions] = useState<
      Option[]
    >([]);

    const [
      envioBaixarContasLoading,
      setIsEnvioBaixarContasLoading,
    ] = useState<boolean>(false);

    const [valorParcelas, setValorParcelas] = useState(0);

    const latestProps = useRef({
      setValue: formMethods.setValue,
    });
    useEffect(() => {
      latestProps.current = {
        setValue: formMethods.setValue,
      };
    });

    const [juros, multa, desconto] = formMethods.watch([
      'juros',
      'multa',
      'desconto',
    ]);

    useEffect(() => {
      const vlrtotal = valorOriginalAReceber + juros + multa - desconto;
      latestProps.current.setValue('totalBaixa', Number(vlrtotal.toFixed(2)));
      latestProps.current.setValue(
        'valorRecebido',
        Number(vlrtotal.toFixed(2))
      );
    }, [juros, multa, desconto, valorOriginalAReceber]);

    const [parcelas, total, valorRecebido] = formMethods.watch([
      'qtdParcelas',
      'totalBaixa',
      'valorRecebido',
    ]);

    const valorRecebidoEMenor = valorRecebido < total;
    const valorRecebidoEMaior = valorRecebido > total;
    const valorRecebidoEigual = valorRecebido === total;
    const scaleFadeOpen = !!(
      valorRecebidoEMenor ||
      valorRecebidoEMaior ||
      valorRecebidoEigual
    );

    async function handleZerarMultasEJuros() {
      formMethods.setValue('juros', 0);
      formMethods.setValue('multa', 0);
      zerarMultaJuros.current = true;
    }

    useEffect(() => {
      async function obterCaixaAberto() {
        const response = await api.get<void, ResponseApi<CaixaAberto>>(
          ConstanteEnderecoWebservice.OBTER_CAIXA_ABERTO_USUARIO_LOGADO
        );

        if (response) {
          if (response.avisos) {
            response.avisos.forEach((avisos) => toast.warning(avisos));
          }
          if (response.sucesso && response.dados) {
            caixaAberto.current = {
              id: response.dados.id,
              nome: response.dados.nome,
            };
            latestProps.current.setValue(
              'contaFinanceiraId',
              response.dados.nome
            );
          } else {
            latestProps.current.setValue('contaFinanceiraId', 'Conta cofre');
          }
        }
      }
      obterCaixaAberto();
    }, []);

    useEffect(() => {
      async function getFormaPagamentoOption() {
        setIsFormaPagamentoOptionsLoading(true);

        const response = await api.get<
          void,
          ResponseApi<FormaRecebimentoProps[]>
        >(ConstanteEnderecoWebservice.FORMA_RECEBIMENTO_LISTAR_SELECT_BAIXA);

        if (response) {
          if (response.avisos) {
            response.avisos.forEach((aviso) => toast.warning(aviso));
          }

          if (response.sucesso && response.dados) {
            setIsFormasPagamentoOptions(
              response.dados.map((formaPagamento) => ({
                value: formaPagamento.id,
                label: formaPagamento.nome,
                parcelas: formaPagamento.parcelas,
                contaFinanceiraId: formaPagamento.contaFinanceiraId,
                regraMeioPagamento: formaPagamento.regraMeioPagamento,
              }))
            );
          }
        }
        setIsFormaPagamentoOptionsLoading(false);
      }
      getFormaPagamentoOption();
    }, []);

    const formaRecebimentoSelecionada = formMethods.watch('formaRecebimento');
    const formaRecebimentoValePresente =
      formaRecebimentoSelecionada &&
      formaRecebimentoSelecionada?.regraMeioPagamento ===
        MeioPagamentoEnum.ValePresente;

    const optionsFormasPagamento = formaRecebimentoSelecionada?.parcelas.map(
      (item) => ({
        label: item.descricao,
        value: item.id,
        qtdParcelas: item.qtdParcelas,
      })
    );
    const nomeFormaPagamento = formaRecebimentoSelecionada?.label;
    const formaPagamentoTemParcelas =
      Number(formaRecebimentoSelecionada?.parcelas.length) > 0;

    useEffect(() => {
      if (formaRecebimentoSelecionada?.parcelas?.length > 0) {
        latestProps.current.setValue('qtdParcelas', {
          label: formaRecebimentoSelecionada.parcelas[0].descricao,
          value: formaRecebimentoSelecionada.parcelas[0].id,
          qtdParcelas: formaRecebimentoSelecionada.parcelas[0].qtdParcelas,
        });
      }
    }, [formaRecebimentoSelecionada]);

    useEffect(() => {
      async function getContasFinanceiras() {
        const response = await api.get<
          void,
          ResponseApi<ContaFinanceiraProps[]>
        >(ConstanteEnderecoWebservice.CONTA_FINANCEIRA_LISTAR_SELECT);

        if (response.avisos) {
          response.avisos.forEach((item) => toast.warning(item));
        }

        if (response.sucesso && response.dados) {
          contasFinanceiras.current = response.dados;
        }
      }

      getContasFinanceiras();
    }, []);

    useEffect(() => {
      async function getContaFinanceiraOption() {
        if (formaRecebimentoSelecionada?.contaFinanceiraId) {
          const contaFinanceira = contasFinanceiras.current.find(
            (conta) =>
              conta.id === formaRecebimentoSelecionada?.contaFinanceiraId
          );
          if (contaFinanceira) {
            latestProps.current.setValue(
              'contaFinanceiraId',
              contaFinanceira.nome
            );
          }
        } else {
          latestProps.current.setValue(
            'contaFinanceiraId',
            caixaAberto.current.nome ?? 'Conta cofre'
          );
        }
      }
      getContaFinanceiraOption();
    }, [formaRecebimentoSelecionada]);

    useEffect(() => {
      let vlrParcelas;
      if (!parcelas) {
        vlrParcelas = Number(total) / 1;
        setValorParcelas(vlrParcelas);
      } else {
        vlrParcelas = Number(total) / Number(parcelas.qtdParcelas);
        setValorParcelas(vlrParcelas);
      }
    }, [parcelas, total]);

    useEffect(() => {
      setIsBaixaContasParcialProps({
        valorTroco: DecimalMask(valorRecebido - total, 2),
        valorBaixaParcial: DecimalMask(total - valorRecebido, 2),
      });
    }, [valorRecebido, total]);

    // variáveis que controlam o texto de alerta de forma de pagamento.
    const formaPagamentoNaoAceitaTroco =
      valorRecebidoEMaior &&
      formaRecebimentoSelecionada?.label !==
        MeioPagamentoEnum.properties[1].name;

    const formaPagamentoAceitaTroco =
      valorRecebidoEMaior &&
      formaRecebimentoSelecionada?.label ===
        MeioPagamentoEnum.properties[1].name;

    const deveAparecerOAlerta =
      (valorRecebidoEMenor ||
        valorRecebidoEMaior ||
        !valorRecebidoEigual ||
        formaPagamentoTemParcelas) &&
      formaRecebimentoSelecionada?.label;

    const handleSubmit = formMethods.handleSubmit(
      async ({
        formaRecebimento,
        dataPagamento,
        desconto: descontoConta,
        multa: multaConta,
        juros: jurosConta,
        valorRecebido: valorRecebidoDaConta,
        totalBaixa,
        qtdParcelas,
        valeId,
        valorVoucher,
      }) => {
        if (!formaPagamentoNaoAceitaTroco) {
          setIsEnvioBaixarContasLoading(true);
          const amountRecivedIsDiffent =
            (valorRecebidoDaConta || totalBaixa) !==
            valorOriginalAReceber + (valorJuros ?? 0) + (valorMultas ?? 0);

          const valorTotalRecebido = formaRecebimentoValePresente
            ? valorRecebido < valorVoucher
              ? valorRecebido
              : valorVoucher
            : valorRecebidoDaConta;

          const contasAReceber = baixarContaItems.map((baixarContaItem) => ({
            movimentacaoFinanceiraId: baixarContaItem.movimentacaoFinanceiraId,
            desconto: isMultipla ? baixarContaItem.desconto : descontoConta,
            multa: isMultipla ? baixarContaItem.multa : multaConta,
            juros: isMultipla ? baixarContaItem.juros : jurosConta,
            valor: !isMultipla
              ? valorTotalRecebido
              : baixarContaItem.valor +
                baixarContaItem.juros +
                baixarContaItem.multa -
                (baixarContaItem.desconto ?? 0),
            formaRecebimentoId: formaRecebimento?.value,
            formaRecebimentoParcelaId: qtdParcelas?.value,
            contaFinanceiraId:
              formaRecebimento?.contaFinanceiraId ||
              caixaAberto?.current.id ||
              null,
            qtdParcela: qtdParcelas?.qtdParcelas || 1,
            dataPagamento: new Date(dataPagamento).toISOString(),
            baixaParcial:
              !isMultipla &&
              valorTotalRecebido <
                baixarContaItem.valor - desconto + multa + juros,
            zerarMultaJuros: zerarMultaJuros.current,
            valeId: formaRecebimentoValePresente ? valeId : null,
          }));

          const response = await api.post<void, ResponseApi<FormData>>(
            ConstanteEnderecoWebservice.BAIXAR_CONTAS_A_RECEBER,
            contasAReceber
          );

          if (response) {
            if (response.avisos) {
              response.avisos.forEach((aviso) => toast.warning(aviso));
            }

            if (!response.avisos)
              onResolve({
                sucesso: response.sucesso,
                reloadHeards: amountRecivedIsDiffent,
              });

            if (response.sucesso) {
              toast.success(
                isMultipla
                  ? 'Contas baixadas com sucesso!'
                  : 'Conta baixada com sucesso!'
              );
              if (compartilharContaRecebida) {
                compartilharContaRecebida(baixarContaItems);
              }
            }
          }
          setIsEnvioBaixarContasLoading(false);
        } else {
          toast.warn(
            'A forma de pagamento selecionada não permite troco. Por favor, altere o valor ou a forma de recebimento'
          );
        }
      }
    );

    const getClientePadraoSistema = async () => {
      const response = await api.get<
        void,
        ResponseApi<ClientePadraoSistemaResponse>
      >(ConstanteEnderecoWebservice.CLIENTE_FORNECEDOR_OBTER_PADRAO_SISTEMA);
      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }
        if (response.sucesso && response.dados) {
          const { id, nome } = response.dados;
          return {
            id,
            name: nome,
          };
        }
      }
      return {};
    };

    const avisoValorVoucher = (valor: number) => {
      const valorDaBaixa = moneyMask(valor, true);
      toast.warn(
        <Text p="8px" fontSize="sm">
          <Text as="span" display="inline" color="black">
            <>
              <span>Baixa parcial: O valor restante de </span>
              <Text display="inline" fontWeight="bold">
                {valorDaBaixa}
              </Text>
              <span> ficará em aberto com a mesma data de vencimento.</span>
            </>
          </Text>
        </Text>,
        {
          autoClose: 20000,
        }
      );
    };

    const handleEscolherFormaPagamentoVale = useCallback(async () => {
      const cliente = isMultipla
        ? await getClientePadraoSistema()
        : {
            id: clienteFornecedorId,
            name: clienteFornecedorRazaoSocial,
          };
      const {
        sucesso,
        saldoVale,
        valeId,
        vouchersSelecionados,
      } = await ModalInserirVoucher({
        cliente,
        value: valorRecebido,
        voucherUnico: true,
      });
      if (sucesso && saldoVale && valeId) {
        if (saldoVale < valorRecebido) {
          if (isMultipla) {
            toast.warn(
              'O valor do voucher é menor que o valor total das contas, por favor, selecione outro voucher ou outra forma de recebimento'
            );
            return;
          }
          avisoValorVoucher(valorRecebido - saldoVale);
        }
        formMethods.setValue('valeId', valeId);
        formMethods.setValue('valorVoucher', saldoVale);

        handleSubmit();
      } else if (
        sucesso &&
        vouchersSelecionados &&
        vouchersSelecionados.length > 0
      ) {
        const saldoTotalVales = vouchersSelecionados.reduce(
          (acc, curr) => acc + curr.saldoVale,
          0
        );
        if (saldoTotalVales < valorRecebido) {
          if (isMultipla) {
            toast.warn(
              'O valor do voucher é menor que o valor total das contas, por favor, selecione outro voucher ou outra forma de recebimento'
            );
            return;
          }
          avisoValorVoucher(valorRecebido - saldoTotalVales);
        }
        formMethods.setValue('valeId', vouchersSelecionados[0].valeId);
        formMethods.setValue('valorVoucher', saldoTotalVales);
        handleSubmit();
      }
    }, [
      clienteFornecedorId,
      clienteFornecedorRazaoSocial,
      formMethods,
      handleSubmit,
      isMultipla,
      valorRecebido,
    ]);

    useEffect(() => {
      if (!formaPagamentoTemParcelas) {
        formMethods.setValue('qtdParcelas', undefined);
      }
    }, [formMethods, formaPagamentoTemParcelas]);

    return (
      <ModalPadraoChakra
        size={!isResponsiveVersion ? 'full' : '4xl'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        isCentered={isResponsiveVersion}
        scrollBehavior={!isResponsiveVersion ? 'inside' : 'outside'}
      >
        <ModalContent
          marginBottom={isResponsiveVersion ? '3.75rem' : '0'}
          marginTop={isResponsiveVersion ? '3.75rem' : '0'}
          bg="gray.50"
        >
          <ModalHeader fontSize="sm" mb="4" py="0">
            <Heading
              fontSize="lg"
              pt="15px"
              color="primary.50"
              mb={{ base: '1', lg: '4' }}
            >
              {isMultipla
                ? 'Baixar recebimento de várias contas'
                : 'Baixar recebimento'}
            </Heading>
            <Box bg="primary.400" mt="2" borderRadius="md">
              <SimpleGridForm mx="4">
                <GridItem
                  colSpan={{ base: 12, md: 12, lg: 8 }}
                  my={{ lg: 3 }}
                  mt={{ base: 2, md: 2 }}
                >
                  <Text fontSize="xs" color="white">
                    Cliente:
                  </Text>
                  <Text fontSize="sm" color="white" fontWeight="normal">
                    {aparecerClienteFornecedorRazaoSocial ? (
                      clienteFornecedorRazaoSocial
                    ) : (
                      <>
                        {isMultipla
                          ? '---'
                          : `${clienteFornecedorRazaoSocial}${
                              clienteFornecedorNome
                                ? ` - (${clienteFornecedorNome})`
                                : ''
                            }`}
                      </>
                    )}
                  </Text>
                </GridItem>
                <GridItem
                  colSpan={{ base: 12, md: 12, lg: 2 }}
                  my={{ base: 0, md: 0, lg: 3 }}
                >
                  <Text fontSize="xs" color="white">
                    Valor original:
                  </Text>
                  <Text fontSize="sm" color="white">
                    {`R$ ${(
                      valorOriginal ?? valorOriginalAReceber
                    ).toLocaleString('pt-BR', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`}
                  </Text>
                </GridItem>
                <GridItem
                  colSpan={{ base: 12, md: 12, lg: 2 }}
                  my={{ base: 0, md: 0, lg: 3 }}
                  mb={{ base: 2, md: 2, lg: 0 }}
                >
                  <Text fontSize="xs" color="white">
                    Vencimento:
                  </Text>
                  <Text fontSize="sm" color="white" fontWeight="normal">
                    {!isMultipla && dataVencimento
                      ? formatDate(dataVencimento)
                      : '---'}
                  </Text>
                </GridItem>
              </SimpleGridForm>
            </Box>
          </ModalHeader>

          <ModalBody position="relative">
            {envioBaixarContasLoading && <LoadingPadrao />}
            <FormProvider {...formMethods}>
              <SimpleGridForm>
                <NumberInput
                  isRequired={false}
                  label="Multas"
                  name="multa"
                  leftElement="R$"
                  size="sm"
                  isDisabled
                  colSpan={{ base: 12, lg: 3 }}
                />
                <NumberInput
                  isRequired={false}
                  label="Juros"
                  name="juros"
                  leftElement="R$"
                  size="sm"
                  isDisabled
                  colSpan={{ base: 12, lg: 3 }}
                />
                <NumberInput
                  isRequired={false}
                  label="Descontos"
                  name="desconto"
                  leftElement="R$"
                  size="sm"
                  isDisabled={isMultipla}
                  colSpan={{ base: 12, lg: 3 }}
                />
                <NumberInput
                  label="Valor a receber"
                  bg="secondary.300"
                  name="totalBaixa"
                  isReadOnly
                  border="gray.100"
                  leftElement="R$"
                  size="sm"
                  isRequired={false}
                  colSpan={{ base: 12, lg: 3 }}
                />
                <NumberInput
                  label="Valor recebido"
                  id="valorRecebido"
                  name="valorRecebido"
                  borderColor={
                    valorRecebidoEMenor
                      ? 'red.500'
                      : valorRecebidoEMaior
                      ? 'yellow.500'
                      : 'gray.100'
                  }
                  leftElement="R$"
                  size="sm"
                  isDisabled={isMultipla}
                  isRequired={false}
                  colSpan={{ base: 12, lg: 3 }}
                />
                <Select
                  size="sm"
                  id="formaRecebimento"
                  name="formaRecebimento"
                  label="Formas de recebimento"
                  placeholder="Selecione a forma de recebimento"
                  autoFocus
                  options={formasPagamentoOptions}
                  isLoading={isFormaPagamentoOptionsLoading}
                  colSpan={{ base: 12, lg: 6 }}
                  asControlledByObject
                />
                {!formaPagamentoTemParcelas ? (
                  <GridItem colSpan={3} />
                ) : (
                  <>
                    <GridItem
                      colSpan={{ base: 12, lg: 3 }}
                      mt={{ base: 2, lg: 0 }}
                    >
                      <Select
                        label="Parcelas"
                        asControlledByObject
                        name="qtdParcelas"
                        options={optionsFormasPagamento}
                        size="sm"
                      />
                    </GridItem>
                  </>
                )}
                {deveAparecerOAlerta ? (
                  <GridItem colSpan={{ base: 12, lg: 12 }}>
                    <ScaleFade in={scaleFadeOpen} initialScale={0.5}>
                      <Flex
                        paddingLeft="4"
                        height="10"
                        borderRadius="md"
                        justifyContent="start"
                        bg={
                          valorRecebidoEMenor
                            ? 'red.500'
                            : valorRecebidoEMaior
                            ? 'yellow.500'
                            : 'gray.100'
                        }
                        alignItems="center"
                        py={{ base: '2' }}
                        px={{ base: '4', lg: '6' }}
                        justify={!isResponsiveVersion ? 'flex-end' : ''}
                      >
                        <Icon
                          as={
                            valorRecebidoEMenor
                              ? FinanceiroParcial
                              : valorRecebidoEMaior
                              ? FinanceiroTroco
                              : PagamentosCartaoDebitoIcon
                          }
                          display={
                            !isResponsiveVersion && valorRecebidoEMenor
                              ? 'none'
                              : 'block'
                          }
                          h={
                            valorRecebidoEMenor
                              ? '6'
                              : valorRecebidoEMaior
                              ? '9'
                              : '7'
                          }
                          w={
                            valorRecebidoEMenor
                              ? '6'
                              : valorRecebidoEMaior
                              ? '9'
                              : '7'
                          }
                          marginRight="4"
                          color={
                            valorRecebidoEMenor
                              ? 'white'
                              : valorRecebidoEMaior
                              ? 'black'
                              : 'black'
                          }
                        />
                        <Text pt="sm" fontSize="sm">
                          <Text
                            as="span"
                            display={
                              valorRecebidoEMaior || valorRecebidoEMenor
                                ? 'inline'
                                : 'hidden'
                            }
                            color={valorRecebidoEMenor ? 'white' : 'black'}
                          >
                            {valorRecebidoEMenor ? (
                              <>
                                <span>Baixa parcial: O valor restante de </span>
                                <Text display="inline" fontWeight="bold">
                                  R$ {baixaContasParicalProps.valorBaixaParcial}{' '}
                                </Text>
                                <span>
                                  ficará em aberto com a mesma data de
                                  vencimento.
                                </span>
                              </>
                            ) : formaPagamentoAceitaTroco ? (
                              <>
                                <span> Devolver troco de: </span>
                                <Text display="inline" fontWeight="bold">
                                  R$ {baixaContasParicalProps.valorTroco}
                                </Text>
                              </>
                            ) : formaPagamentoNaoAceitaTroco ? (
                              <>
                                <span>
                                  A forma de pagamento selecionada não permite
                                  troco
                                </span>
                              </>
                            ) : null}
                          </Text>
                          <Text
                            display="inline"
                            fontWeight="bold"
                            color={
                              valorRecebidoEMenor
                                ? 'white'
                                : valorRecebidoEMaior
                                ? 'white'
                                : 'black'
                            }
                          >
                            {formaPagamentoTemParcelas &&
                            !valorRecebidoEMenor &&
                            !valorRecebidoEMaior
                              ? ` ${nomeFormaPagamento} ${
                                  parcelas?.qtdParcelas
                                }x de ${valorParcelas.toLocaleString('pt-BR', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })} `
                              : ''}
                          </Text>
                        </Text>
                      </Flex>
                    </ScaleFade>
                  </GridItem>
                ) : null}
                <DateInput
                  isRequired={false}
                  label="Data de recebimento"
                  name="dataPagamento"
                  colSpan={{ base: 12, lg: 3 }}
                  minDate={new Date(String(dataEmissao))}
                  size="sm"
                />

                <Input
                  type="text"
                  isDisabled
                  size="sm"
                  id="contaFinanceiraId"
                  name="contaFinanceiraId"
                  label="Conta financeira"
                  placeholder="Selecione uma conta financeira"
                  autoFocus
                  colSpan={{ base: 12, lg: 6 }}
                />
              </SimpleGridForm>
            </FormProvider>
          </ModalBody>
          <Box px="6" mt="2">
            <Divider mt="4" color="gray.200" />
          </Box>

          <ModalFooter
            justifyContent="center"
            mx={{ base: 6, md: 2 }}
            py={{ base: 6, md: 8 }}
            px="0"
          >
            <Stack
              direction={{ base: 'column', md: 'row' }}
              justifyContent="center"
              spacing={6}
              w="full"
            >
              <Button
                variant="outline"
                borderRadius="full"
                w="full"
                maxW={{ base: 'full', md: '155px' }}
                onClick={handleZerarMultasEJuros}
                size="sm"
              >
                Zerar multas e juros
              </Button>
              <Button
                variant="outline"
                borderRadius="full"
                w="full"
                maxW={{ base: 'full', md: '100px' }}
                onClick={onClose}
                size="sm"
              >
                Cancelar
              </Button>
              <ButtonDefault
                colorScheme="aquamarine"
                borderRadius="full"
                width="full"
                isLoading={envioBaixarContasLoading}
                color="white"
                maxW={{ base: 'full', md: '110px' }}
                size="sm"
                type="submit"
                onClick={
                  formaRecebimentoValePresente && !formaPagamentoNaoAceitaTroco
                    ? handleEscolherFormaPagamentoVale
                    : handleSubmit
                }
              >
                Baixar conta
              </ButtonDefault>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
