import { Flex, Text } from '@chakra-ui/react';

interface CabecalhoProps {
  nomeFantasia: string;
  cliente?: string;
  telefone?: string;
  emissao: Date;
}

const Cabecalho = ({
  nomeFantasia,
  cliente,
  emissao,
  telefone,
}: CabecalhoProps) => {
  return (
    <>
      <Flex
        mt="2"
        pt={1}
        pb={1}
        alignItems="center"
        justifyContent="center"
        border="2px solid"
        borderColor="gray.700"
      >
        <Text fontSize="sm" fontWeight="black">
          {nomeFantasia}
        </Text>
      </Flex>
      {telefone && (
        <Flex
          alignItems="center"
          justifyContent="center"
          borderBottom="2px solid"
          borderColor="gray.700"
          width="100%"
          py={2}
        >
          <Text fontSize="2xs">Fone: {telefone}</Text>
        </Flex>
      )}
      <Flex mt="1" pt={2} pb={2} alignItems="center" justifyContent="center">
        <Text fontSize="sm" fontWeight="black">
          RECIBO
        </Text>
      </Flex>

      <Flex
        pt={2}
        pb={2}
        alignItems="center"
        wrap="wrap"
        sx={{
          borderBottom: '2px solid',
          borderTop: '2px solid',
        }}
        justifyContent="start"
        borderColor="gray.700"
      >
        <Text w="100%" fontSize="2xs">
          Cliente: {cliente || ''}
        </Text>
        <Text w="100%" fontSize="2xs">
          Emissão: {new Date(emissao).toLocaleDateString('pt-BR')}
        </Text>
      </Flex>
    </>
  );
};

export default Cabecalho;
