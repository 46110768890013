import React, { useCallback, useState, useMemo } from 'react';
import {
  Button,
  Box,
  Tr,
  Td,
  Text,
  Icon,
  Stack,
  Divider,
  HStack,
  Flex,
  useToken,
  useMediaQuery,
} from '@chakra-ui/react';
import { CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { FiCheckCircle, FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { toast } from 'react-toastify';

import { useEntradaMercadoriaEtapasContext } from 'store/EntradaMercadoria/EntradaMercadoriaEtapas';
import { useEntradaMercadoriaDadosCadastroContext } from 'store/EntradaMercadoria/EntradaMercadoriaDadosCadastro';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';
import { DecimalMask } from 'helpers/format/fieldsMasks';

import {
  Container,
  Body,
  Footer,
  StepDescriptionAccordion,
} from 'components/update/Steps/StepContent';
import {
  LoadMoreRowsParams,
  VirtualizedInfiniteTable,
  TableHeader,
} from 'components/update/Table/VirtualizedInfiniteTable';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';

import { ModalVincularProduto } from './ModalVincularProduto';

type InformacoesRodape = {
  totalProdutos: number;
  quantidadeItens: number;
  valorTotalProdutos: number;
  todosProdutosVinculados: boolean;
};

type Produto = {
  isOpen: boolean;

  documentoFiscalItemId: string;
  descricaoProduto: string;
  quantidade: number;
  valorUnitario: number;
  valorTotal: number;
  vinculado: boolean;
  valorIcmsSt: number;
  valorIpi: number;
  valorFcpSt: number;
  cfop: string;
  codigoGTINEAN: string;
  ncm: string;
  codigoCest: string;
};

type ProdutoPaginadoRetorno = {
  totalProdutos: number;
  totalItens: number;
  valorTotal: number;
  todosProdutosVinculados: boolean;

  registros: Produto[];
};

const cache = new CellMeasurerCache({
  defaultHeight: 65,
  minHeight: 52,
  fixedWidth: true,
});

export function VincularProdutos() {
  const [aquamarine600] = useToken('colors', ['aquamarine.600']);

  const { casasDecimais } = usePadronizacaoContext();
  const { nextStep, previousStep } = useEntradaMercadoriaEtapasContext();
  const {
    entradaMercadoriaId,
    descartarEntradaMercadoria,
    voltarParaListagem,
    temPermissaoExcluir,
    isReadOnly,
    menuIsOpen,
  } = useEntradaMercadoriaDadosCadastroContext();

  const [isProdutosLoading, setIsProdutosLoading] = useState(false);
  const [produtos, setProdutos] = useState<Produto[]>([]);
  const [informacoesRodape, setInformacoesRodape] = useState<InformacoesRodape>(
    {
      quantidadeItens: 0,
      totalProdutos: 0,
      valorTotalProdutos: 0,
      todosProdutosVinculados: false,
    }
  );

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const heightTable = () => {
    const itemOpenInTable = produtos?.filter((produto) => produto.isOpen);
    const numberOfItensOpenInList = itemOpenInTable.length;
    const heightOfAllItensOpen = numberOfItensOpenInList * 112;
    const heightOfAllItensClosed =
      (produtos?.length - numberOfItensOpenInList) * 64;
    return heightOfAllItensOpen + heightOfAllItensClosed;
  };

  const todosProdutosVinculados =
    informacoesRodape.todosProdutosVinculados ||
    (produtos.length === informacoesRodape.totalProdutos &&
      produtos.every((produto) => produto.vinculado));

  function handleDescartarEntradaMercadoria() {
    descartarEntradaMercadoria();
  }

  function handleVoltar() {
    previousStep();
  }

  function handleSalvarRascunho() {
    voltarParaListagem();
  }

  function handleAvancar() {
    if (todosProdutosVinculados) {
      nextStep();
    } else {
      toast.warning('É necessário vincular todos os produtos para continuar.');
    }
  }

  function handleToggleLinhaProduto(index: number) {
    const produtosAtualizados = [...produtos];
    const produtoAtualizado = produtosAtualizados[index];

    produtosAtualizados.splice(index, 1, {
      ...produtoAtualizado,
      isOpen: !produtoAtualizado.isOpen,
    });

    setProdutos(produtosAtualizados);
  }

  async function vincularProduto(index: number, isEdicao = false) {
    const {
      documentoFiscalItemId,
      descricaoProduto,
      quantidade,
      valorUnitario,
      valorTotal,
      cfop,
      codigoCest,
      ncm,
      codigoGTINEAN,
    } = produtos[index];

    if (entradaMercadoriaId) {
      try {
        const {
          success,
          quantidade: novaQuantidade,
          valorUnitario: novoValorUnitario,
          cfop: novoCfop,
        } = await ModalVincularProduto({
          casasDecimaisQuantidade: casasDecimais.casasDecimaisQuantidade,
          casasDecimaisValor: casasDecimais.casasDecimaisValor,
          produto: {
            documentoFiscalItemId,
            descricaoProduto,
            quantidade,
            valorUnitario,
            valorTotal,
            cfop,
            codigoGTINEAN,
            ncm,
            codigoCest,
          },
          totalProdutos: informacoesRodape.totalProdutos,
          entradaMercadoriaId,
          isEdicao,
        });

        if (success) {
          const produtosAtualizados = [...produtos];
          produtosAtualizados.splice(index, 1, {
            ...produtosAtualizados[index],
            vinculado: true,
            quantidade: novaQuantidade,
            valorUnitario: novoValorUnitario,
            cfop: novoCfop,
          });

          setProdutos(produtosAtualizados);
        }
        return success;
      } catch (error) {
        return error;
      }
    }
    return false;
  }

  async function handleEditar(index: number) {
    await vincularProduto(index, true);
  }

  async function handleVincularProduto(index: number) {
    await vincularProduto(index);
  }

  const produtosTableHeaders: TableHeader[] = useMemo(
    () => [
      {
        key: 'descricaoProduto',
        content: 'Produto',
        width: '40%',
      },
      {
        key: 'quantidade',
        content: 'Quantidade',
        isNumeric: true,
        width: '20%',
        lineHeight: 'none',
        verticalAlign: 'bottom',
      },
      {
        key: 'valorUnitario',
        content: 'Valor unitário',
        width: '20%',
        lineHeight: 'none',
        verticalAlign: 'bottom',
        isNumeric: true,
      },
      {
        key: 'valorTotal',
        content: 'Valor total',
        width: '20%',
        lineHeight: 'none',
        verticalAlign: 'bottom',
        isNumeric: true,
      },
      {
        key: 'acoes',
        content: 'Ações',
        textAlign: 'center',
        lineHeight: 'none',
        verticalAlign: 'bottom',
        minWidth: '165px',
      },
    ],
    []
  );

  function getDynamicHeight(index: number, marginSize: number) {
    const produto = produtos[index];

    const isLastItem = index === produtos.length - 1;

    const closedProdutoHeight = 56 + (isLastItem ? 0 : marginSize);
    const openedProdutoHeight = 104 + (isLastItem ? 0 : marginSize); // 56px da linha e 48px da Box na linha aberta

    const produtoHeight = produto?.isOpen
      ? openedProdutoHeight
      : closedProdutoHeight;

    return produtoHeight;
  }

  const loadMoreRows = useCallback(
    async ({
      currentPage,
      pageSize,
      orderColumn,
      orderDirection,
    }: LoadMoreRowsParams) => {
      if (entradaMercadoriaId) {
        setIsProdutosLoading(true);

        const paginationData = {
          currentPage,
          pageSize,
          orderColumn,
          orderDirection,
        };

        const response = await api.get<
          void,
          ResponseApi<ProdutoPaginadoRetorno>
        >(
          formatQueryPagegTable(
            ConstanteEnderecoWebservice.ENTRADA_MERCADORIA_LISTAR_ITENS_PAGINADOS_IMPORTACAO_XML,
            paginationData
          ),
          { params: { id: entradaMercadoriaId } }
        );

        if (response) {
          if (response.sucesso) {
            setProdutos((prev) => [
              ...prev,
              ...(response.dados.registros || []).map(
                (registro) => ({ ...registro, isOpen: false } as Produto)
              ),
            ]);

            setInformacoesRodape({
              quantidadeItens: response.dados.totalItens,
              totalProdutos: response.dados.totalProdutos,
              valorTotalProdutos: response.dados.valorTotal,
              todosProdutosVinculados: response.dados.todosProdutosVinculados,
            });
          }
        }
      }

      setIsProdutosLoading(false);
    },
    [entradaMercadoriaId]
  );

  return (
    <>
      <Container mt="5">
        <StepDescriptionAccordion
          stepNumber={2}
          title="Lista de produtos"
          description="Todos os produtos contidos na nota fiscal precisam ser vinculados a um produto existente no sistema. Clique em “vincular ao sistema” em cada um dos itens listados abaixo para realizar esta ação. Caso exista um novo produto você poderá cadastrá-lo na própria tela de vinculação."
        />

        <Body>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            borderRadius="md"
            bg="gray.50"
            border="1px"
            borderColor="gray.100"
            minH="390px"
            py={{ base: 4, sm: 6, md: 8 }}
            pl={{ base: 4, sm: 6, md: 8 }}
            pr={{ base: '6px', sm: '14px', md: '22px' }}
            sx={{
              '& table': { bg: 'gray.50' },
              '& thead > tr > th': {
                bg: 'gray.50',
                border: 'none',
              },
              '& tbody > tr': {
                boxShadow: 'none',
                borderRadius: 'md',
                ...(informacoesRodape.totalProdutos > 0
                  ? { border: '1px', borderColor: 'gray.100' }
                  : {
                      '& > td': {
                        position: 'relative',
                        _before: {
                          content: '""',
                          position: 'absolute',
                          h: 'full',
                          w: 'full',
                          top: 0,
                          left: 0,
                          borderLeft: 'none',
                          borderRight: 'none',
                          borderRadius: 'md',
                        },
                      },
                    }),
              },
              '& tbody > tr > td': {
                bg: 'white',
                lineHeight: 'none',
                _before: {
                  border:
                    informacoesRodape.totalProdutos > 0
                      ? 'none !important'
                      : '1px',
                  borderColor: 'gray.100',
                },
              },
            }}
          >
            <VirtualizedInfiniteTable
              variant="simple-card"
              paddingRight="10px"
              size="sm"
              bg="gray.50"
              colorFundo="gray.50"
              alterarBordaListagem="gray.50"
              boxShadow="none"
              isUpdateWidthTable
              withoutRowsMessage="Nenhum produto adicionado."
              orderColumn="descricaoProduto"
              tableHeaders={produtosTableHeaders}
              itemHeight={56}
              isLoading={isProdutosLoading}
              visibleItemsCount={informacoesRodape?.quantidadeItens}
              dynamicHeight={({ index }) => getDynamicHeight(index, 5)}
              rowRenderer={({
                index,
                style: { height, ...restStyle },
                key,
                parent,
              }) => {
                const produto = produtos[index];

                if (!produto) {
                  return null;
                }

                return (
                  <CellMeasurer
                    cache={cache}
                    columnIndex={1}
                    key={key}
                    parent={parent}
                    rowIndex={index}
                  >
                    {({ registerChild, measure }) => (
                      <Tr
                        ref={(e) => {
                          if (e && registerChild) {
                            registerChild(e);
                          }
                        }}
                        style={restStyle}
                        h={`${getDynamicHeight(index, 0)}px !important`}
                        bg={
                          produto.vinculado
                            ? `${aquamarine600} !important`
                            : 'white'
                        }
                        sx={{
                          '& > td': {
                            bg: produto.vinculado
                              ? `${aquamarine600} !important`
                              : 'white',
                            color: produto.vinculado ? 'white' : 'inherit',
                            ...(produto.isOpen
                              ? {
                                  marginBottom: '5px',
                                  borderBottomRadius: '0px !important',
                                }
                              : {}),
                          },
                        }}
                      >
                        <Td
                          width={produtosTableHeaders[0].width}
                          cursor="pointer"
                          userSelect="none"
                          onClick={() => {
                            measure();
                            handleToggleLinhaProduto(index);
                          }}
                        >
                          <Icon
                            as={produto.isOpen ? FiChevronUp : FiChevronDown}
                            mr="1"
                          />

                          {produto.descricaoProduto}
                        </Td>
                        <Td width={produtosTableHeaders[1].width} isNumeric>
                          <Text lineHeight="0" opacity={0}>
                            {produtosTableHeaders[1].content}
                          </Text>

                          {DecimalMask(produto.quantidade, 4, 4)}
                        </Td>
                        <Td width={produtosTableHeaders[2].width} isNumeric>
                          <Text lineHeight="0" opacity={0}>
                            {produtosTableHeaders[2].content}
                          </Text>

                          {produto.valorUnitario.toString()}
                        </Td>
                        <Td width={produtosTableHeaders[3].width} isNumeric>
                          <Text lineHeight="0" opacity={0}>
                            {produtosTableHeaders[3].content}
                          </Text>

                          {DecimalMask(
                            produto.valorTotal,
                            casasDecimais.casasDecimaisValor,
                            casasDecimais.casasDecimaisValor
                          )}
                        </Td>
                        <Td
                          minWidth={produtosTableHeaders[4].minWidth}
                          maxWidth={produtosTableHeaders[4].minWidth}
                        >
                          <Text lineHeight="0" opacity={0}>
                            {produtosTableHeaders[4].content}
                          </Text>

                          {produto.vinculado ? (
                            <Flex justifyContent="space-between">
                              <HStack spacing="1" color="secondary.300">
                                <Icon as={FiCheckCircle} boxSize="4" />
                                <Text fontSize="xs">Vinculado</Text>
                              </HStack>

                              <ActionsMenu
                                items={[
                                  {
                                    content: 'Editar',
                                    onClick: () => handleEditar(index),
                                  },
                                ]}
                              />
                            </Flex>
                          ) : (
                            <Flex alignItems="center" justifyContent="center">
                              <Button
                                size="xs"
                                colorScheme="orange"
                                onClick={() => {
                                  handleVincularProduto(index);
                                }}
                              >
                                Vincular ao sistema
                              </Button>
                            </Flex>
                          )}
                        </Td>

                        {produto.isOpen && (
                          <Box
                            h="48px"
                            borderBottomRadius="md"
                            bg={produto.vinculado ? aquamarine600 : 'white'}
                            color={produto.vinculado ? 'white' : 'inherit'}
                            px="5"
                          >
                            <Divider />

                            <HStack
                              spacing="6"
                              px="5"
                              h="full"
                              lineHeight="none"
                              fontSize="xs"
                            >
                              <Flex>
                                <Text fontWeight="light">ICMS ST:</Text>
                                <Text ml="2" fontWeight="bold">
                                  <Text as="span" fontSize="2xs" mr="0.5">
                                    R$
                                  </Text>
                                  {DecimalMask(
                                    produto.valorIcmsSt,
                                    casasDecimais.casasDecimaisValor,
                                    casasDecimais.casasDecimaisValor
                                  )}
                                </Text>
                              </Flex>
                              <Divider orientation="vertical" h="6" />
                              <Flex>
                                <Text fontWeight="light">IPI:</Text>
                                <Text ml="2" fontWeight="bold">
                                  <Text as="span" fontSize="2xs" mr="0.5">
                                    R$
                                  </Text>
                                  {DecimalMask(
                                    produto.valorIpi,
                                    casasDecimais.casasDecimaisValor,
                                    casasDecimais.casasDecimaisValor
                                  )}
                                </Text>
                              </Flex>
                              <Divider orientation="vertical" h="6" />
                              <Flex>
                                <Text fontWeight="light">FCP ST:</Text>
                                <Text ml="2" fontWeight="bold">
                                  <Text as="span" fontSize="2xs" mr="0.5">
                                    R$
                                  </Text>
                                  {DecimalMask(
                                    produto.valorFcpSt,
                                    casasDecimais.casasDecimaisValor,
                                    casasDecimais.casasDecimaisValor
                                  )}
                                </Text>
                              </Flex>
                            </HStack>
                          </Box>
                        )}
                      </Tr>
                    )}
                  </CellMeasurer>
                );
              }}
              rowCount={informacoesRodape.totalProdutos}
              isRowLoaded={({ index }) => !!produtos[index]}
              loadMoreRows={loadMoreRows}
              heightTable={heightTable()}
            />

            <Flex
              alignItems="center"
              justifyContent="space-between"
              mt={{ base: 4, sm: 6, md: 8 }}
              fontSize="sm"
            >
              <HStack spacing="6" h="full" lineHeight="none">
                <Flex>
                  <Text fontWeight="light">Total de produtos:</Text>
                  <Text ml="2" fontWeight="bold">
                    {DecimalMask(
                      informacoesRodape.totalProdutos,
                      casasDecimais.casasDecimaisQuantidade,
                      casasDecimais.casasDecimaisQuantidade
                    )}
                  </Text>
                </Flex>
                <Divider orientation="vertical" h="6" />
                <Flex>
                  <Text fontWeight="light">Quantidade de itens:</Text>
                  <Text ml="2" fontWeight="bold">
                    {DecimalMask(
                      informacoesRodape.quantidadeItens,
                      casasDecimais.casasDecimaisQuantidade,
                      casasDecimais.casasDecimaisQuantidade
                    )}
                  </Text>
                </Flex>
              </HStack>

              <Flex>
                <Text fontWeight="light">Total da entrada:</Text>
                <Text ml="2" fontWeight="bold">
                  <Text as="span" fontSize="xs" mr="0.5">
                    R$
                  </Text>
                  {DecimalMask(
                    informacoesRodape.valorTotalProdutos,
                    casasDecimais.casasDecimaisValor,
                    casasDecimais.casasDecimaisValor
                  )}
                </Text>
              </Flex>
            </Flex>
          </Box>
        </Body>
      </Container>
      <Footer
        justifyContent="space-between"
        position={isLargerThan900 ? 'fixed' : 'relative'}
        bottom="0px"
        bg="gray.50"
        borderTop={isLargerThan900 ? '1px solid' : 'none'}
        borderColor="#5502B2"
        w={`calc(100% - ${menuIsOpen ? '210px' : '108px'})`}
        py="16px"
        px="48px"
      >
        <Button
          variant="outlineDefault"
          borderRadius="full"
          w="full"
          maxW={{ base: 'full', md: '160px' }}
          onClick={handleVoltar}
        >
          Voltar
        </Button>
        <Stack
          w="full"
          justifyContent="flex-end"
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: 2, sm: 4, md: 6 }}
        >
          {isReadOnly ? (
            <Button
              variant="outlineDefault"
              borderRadius="full"
              w="full"
              maxW={{ base: 'full', md: '196px' }}
              onClick={voltarParaListagem}
            >
              Voltar para a listagem
            </Button>
          ) : (
            <Button
              variant="outlineDefault"
              borderRadius="full"
              w="full"
              maxW={{ base: 'full', md: '160px' }}
              onClick={handleDescartarEntradaMercadoria}
              isDisabled={!temPermissaoExcluir}
            >
              Descartar
            </Button>
          )}

          {!isReadOnly && (
            <Button
              variant="outlineDefault"
              borderRadius="full"
              w="full"
              maxW={{ base: 'full', md: '160px' }}
              onClick={handleSalvarRascunho}
            >
              Salvar e sair
            </Button>
          )}

          <Button
            colorScheme="purple"
            borderRadius="full"
            w="full"
            maxW={{ base: 'full', md: '160px' }}
            onClick={handleAvancar}
            isDisabled={!todosProdutosVinculados}
          >
            Avançar
          </Button>
        </Stack>
      </Footer>
    </>
  );
}
