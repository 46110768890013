import {
  ModalProps,
  ModalContent,
  ModalBody,
  useDisclosure,
  ModalHeader,
  VStack,
  Text,
  ModalCloseButton,
} from '@chakra-ui/react';
import { useState } from 'react';
import { create, InstanceProps } from 'react-modal-promise';
import { BrowserRouter, Route } from 'react-router-dom';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import CadastrarLocalEstoque from 'pages/LocalEstoque/Formulario/Cadastrar';

type LocalEstoque = {
  nome: string;
  ativo: boolean;
};

type PromiseModal = {
  sucesso: LocalEstoque;
};

type ModalCadastrarLocalEstoqueProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<PromiseModal>;

export const ModalCadastrarLocalEstoque = create<ModalCadastrarLocalEstoqueProps>(
  ({ onResolve }) => {
    const [isLoading, setIsLoading] = useState(false);

    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const callbackCadastrarSucesso = (localEstoqueCriado: LocalEstoque) => {
      setIsLoading(true);
      onResolve({ sucesso: localEstoqueCriado });
      setIsLoading(false);
    };

    return (
      <ModalPadraoChakra isCentered isOpen={isOpen} onClose={onClose}>
        <ModalContent
          marginBottom={{ base: 0, md: '3.75rem' }}
          marginTop={{ base: 0, md: '3.75rem' }}
          maxW={{ base: '100%', md: '600px' }}
        >
          {isLoading && <LoadingPadrao />}

          <ModalHeader
            borderBottom="1px"
            borderColor="gray.100"
            px="0"
            mx={{ base: 6, md: 8 }}
          >
            <VStack alignItems="flex-start" spacing="1" lineHeight="1">
              <Text color="primary.500" fontSize={{ base: 'xl', md: 'md' }}>
                Cadastro de Local do Estoque
              </Text>
            </VStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <BrowserRouter>
              <Route>
                <CadastrarLocalEstoque
                  isRouteSmartPos
                  callbackCadastrarSucesso={callbackCadastrarSucesso}
                />
              </Route>
            </BrowserRouter>
          </ModalBody>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
