import CategoriasProdutoProvider from 'store/CategoriasProduto';

import CardContent from './CardContent';
import { CardContainer } from './components/CardContainer';

const CategoriasProduto = () => {
  return (
    <CardContainer
      bg="white"
      padding={['10px', '20px', '35px']}
      maxWidth={['full', 'full', '1000px']}
    >
      <CategoriasProdutoProvider>
        <CardContent />
      </CategoriasProdutoProvider>
    </CardContainer>
  );
};

export default CategoriasProduto;
