import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

const schema = yup.object().shape({
  idProduto: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);
