import { Route } from 'react-router-dom';

import ConstanteRotas from 'constants/rotas';

import { Comercial } from 'pages/Integracoes/LojaAplicativos/Zoop/Etapas/Comercial';

import LayoutGuard from './LayoutGuard';

export const ZoopConfigRoutes = [
  <Route path="/integracoes-zoop" key="zoop-configuracoes">
    <LayoutGuard
      key="zoop-configuracoes"
      component={Comercial}
      path={ConstanteRotas.ZOOP_TELA_COMERCIAL}
      exact
      meta={{ auth: true }}
    />
  </Route>,
];
