import React, { useEffect } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Flex,
  ModalCloseButton,
  useMediaQuery,
  useDisclosure,
  ModalHeader,
  VStack,
  Text,
  ModalFooter,
  HStack,
  Button,
  Box,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tooltip,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { Control, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { NumberInput } from 'components/update/Input/NumberInput';
import ConditionalWrapper from 'components/Geral/ConditionalWrapper';
import ShadowScrollbar from 'components/PDV/Geral/ShadowScrollbar';
import { ModalConfirmacao } from 'components/Modal/ModalConfirmacao';
import { yupResolver } from './validationForm';

interface ITransferenciaEstoque {
  operacaoItemId: string;
  nome: string;
  quantidade: number;
  saldo: number;
  itemKit: boolean;
}

type ModalDivergenciaEstoqueResponse = {
  transferencias: Array<ITransferenciaEstoque>;
};

type ModalDivergenciaEstoqueProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalDivergenciaEstoqueResponse> & {
    casasDecimaisQuantidade: number;
    transferencias: ITransferenciaEstoque[];
  };

interface IFieldArrayType {
  transferencias: ITransferenciaEstoque[];
}

export const ModalDivergenciaEstoque = create<
  ModalDivergenciaEstoqueProps,
  ModalDivergenciaEstoqueResponse
>(
  ({
    onResolve,
    onReject,
    casasDecimaisQuantidade,
    transferencias,
    ...rest
  }) => {
    const formMethods = useForm<{ transferencias: ITransferenciaEstoque[] }>({
      resolver: yupResolver,
    });

    const { fields, append } = useFieldArray<IFieldArrayType, 'transferencias'>(
      {
        control: formMethods.control as Control<any>,
        name: 'transferencias',
      }
    );

    const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    function handleCancel() {
      onReject();
    }

    function handleValidarEstoque(data: any) {
      let valido = true;

      data.forEach((element: any) => {
        if (element.saldo < element.quantidade) {
          valido = false;
        }
      });
      return valido;
    }

    const handleSubmit = formMethods.handleSubmit(
      async (data: any) => {
        if (!handleValidarEstoque(data.transferencias)) {
          toast.warning('Favor verificar as quantidades informadas.');
          return;
        }

        await ModalConfirmacao({
          callback: async (ok: boolean) => {
            if (ok) {
              onResolve({ transferencias: data.transferencias });
            }
          },
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Confirmar',
          title: 'Alterar quantidade dos produtos',
          text: (
            <>
              Esta ação vai alterar as quantidades informadas anteriormente na
              transferência.
            </>
          ),
        });
      },
      () => toast.warning('O campo é obrigatório.')
    );

    useEffect(() => {
      append(transferencias);
    }, [append, transferencias]);

    return (
      <ModalPadraoChakra
        isCentered
        size={!isSmallerThan900 ? 'xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalContent
          marginBottom={{ base: 0, md: '3.75rem' }}
          marginTop={{ base: 0, md: '3.75rem' }}
          h="unset"
          maxW={{ base: '100%', md: '900px' }}
        >
          <ModalHeader
            mt={isSmallerThan900 ? 12 : undefined}
            mb={isSmallerThan900 ? 8 : undefined}
            borderBottom="1px"
            borderColor="gray.100"
            px="0"
            mx={{ base: 6, md: 8 }}
          >
            <VStack alignItems="flex-start" spacing="1" lineHeight="1">
              <Text color="primary.500" fontSize={{ base: 'xl', md: 'md' }}>
                Alterar quantidade
              </Text>
              <Text color="gray.400" fontSize={{ base: 'sm', md: 'xs' }}>
                Informe a quantidade dos produtos conforme disponibilidade do
                saldo.
              </Text>
            </VStack>
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody p={{ base: 6, md: 8 }}>
            <FormProvider {...formMethods}>
              <Flex flexDirection="column" h="full">
                <Box
                  bg="white"
                  borderRadius="md"
                  boxShadow="md"
                  position="relative"
                >
                  <Table variant="filled" bottom="0">
                    <Thead>
                      <Tr>
                        <Th w="55%" whiteSpace="nowrap" userSelect="none">
                          Produto
                        </Th>
                        <Th
                          isNumeric
                          w="20%"
                          whiteSpace="nowrap"
                          userSelect="none"
                        >
                          Saldo
                        </Th>
                        <Th
                          isNumeric
                          w="35%"
                          whiteSpace="nowrap"
                          userSelect="none"
                        >
                          Quantidade
                        </Th>
                      </Tr>
                    </Thead>
                  </Table>
                  <ConditionalWrapper
                    condition={!isSmallerThan900}
                    wrapper={(wrappedChildren: React.ReactNode) => (
                      <ShadowScrollbar
                        maxHeight={360}
                        paddingHeight={300}
                        paddingTop="0"
                        shadowTopStyle={{
                          background:
                            'linear-gradient(to bottom, rgba(245, 245, 245, 1) 0%, rgba(245, 245, 245, 0) 100%)',
                          height: 30,
                        }}
                        shadowBottomStyle={{
                          background:
                            'linear-gradient(to top, rgba(245, 245, 245, 1) 0%, rgba(245, 245, 245, 0) 100%)',
                          height: 30,
                        }}
                      >
                        {wrappedChildren}
                      </ShadowScrollbar>
                    )}
                  >
                    <Table variant="filled">
                      <Tbody>
                        {fields.map((field, index) => (
                          <Tr>
                            <Td w="55%">{field.nome}</Td>
                            <Td w="20%" isNumeric>
                              {field.saldo}
                            </Td>
                            <Td w="35%">
                              <Tooltip
                                hasArrow
                                label={
                                  field.itemKit
                                    ? 'O item faz parte de um kit, nesse caso é necessário remover e adicionar o item com a quantidade correta.'
                                    : undefined
                                }
                                closeOnClick={false}
                                placement="top"
                              >
                                <Box tabIndex={0}>
                                  <NumberInput
                                    key={field.id}
                                    id={`transferencia-${field.id}`}
                                    name={`transferencias.${index}.quantidade`}
                                    min={0}
                                    placeholder={`0,${'0'.repeat(
                                      casasDecimaisQuantidade
                                    )}`}
                                    scale={casasDecimaisQuantidade}
                                    autoFocus={index === 0}
                                    disabled={field.itemKit}
                                  />
                                </Box>
                              </Tooltip>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </ConditionalWrapper>
                </Box>
              </Flex>
            </FormProvider>
          </ModalBody>

          <ModalFooter
            justifyContent="flex-end"
            borderTop="1px"
            borderColor="gray.100"
            mx={{ base: 6, md: 8 }}
            px="0"
          >
            <HStack spacing={6}>
              <Button
                borderRadius="md"
                variant="outline"
                minW="100px"
                color="gray.400"
                onClick={handleCancel}
              >
                Cancelar
              </Button>
              <Button
                colorScheme="aquamarine"
                borderRadius="md"
                minW="100px"
                onClick={handleSubmit}
              >
                Confirmar
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
