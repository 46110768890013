import React, {
  forwardRef,
  useState,
  useCallback,
  useImperativeHandle,
  useEffect,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react';
import { toast } from 'react-toastify';
import { useFormContext } from 'react-hook-form';
import {
  Box,
  Flex,
  GridItem,
  Text,
  VStack,
  HStack,
  Icon,
} from '@chakra-ui/react';

import api, { ResponseApi } from 'services/api';
import GenerosEnum from 'constants/enum/generos';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import getMaxDataNascimento from 'helpers/data/getMaxDataNascimento';
import getMinDataNascimento from 'helpers/data/getMinDataNascimento';

import { LixeiraIcon } from 'icons';
import Input from 'components/PDV/Input';
import CheckBoxAtivoInativo from 'components/update/Checkbox/CheckboxAtivoInativo';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import InputTelefone from 'components/PDV/InputTelefonePdv';
import ImagePickerPadrao from 'components/ImagePicker/ImagePickerPadrao';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { ModalDesistencia } from 'components/Modal/ModalDesistencia';
import InputTelefoneWhatsapp from 'components/update/Input/InputTelefoneWhatsapp';

import { ModalLojas, LojasInterface } from './LojasModal';

export interface UncontrolledFormRefProps {
  getLojas: (vendedorId?: string) => Promise<void>;
  lojas: LojasInterface[];
  abrirModalLojas: () => void;
}

interface UncontrolledFormProps {
  readonly?: boolean;
  setIsDirty?: Dispatch<SetStateAction<boolean>>;
}

export const UncontrolledForm = forwardRef(
  ({ readonly, setIsDirty }: UncontrolledFormProps, ref) => {
    const [lojas, setLojas] = useState<LojasInterface[]>([]);

    const defaultLojasSelecionadasId = useRef<string[]>();

    const {
      formState: { errors },
      setFocus,
    } = useFormContext();

    const listLojasSelecionadas = lojas.filter(
      ({ selecionado }) => selecionado
    );
    const listGeneros = [
      { label: 'Masculino', value: GenerosEnum.MASCULINO },
      { label: 'Feminino', value: GenerosEnum.FEMININO },
      { label: 'Não informado', value: GenerosEnum.NAO_INFORMADO },
    ];

    const handleDesabilitarLoja = useCallback((id: string) => {
      ModalDesistencia({
        title: 'Você tem certeza?',
        textoMensagem:
          'Ao confirmar esta ação, o vendedor será desvinculado da loja.',
        labelButtonCancelar: 'Não, cancelar!',
        labelButtonConfirmar: 'Sim, desvincular',
        widthButtonConfirmar: '160px',
        callback: async () => {
          setLojas((prev) =>
            prev.map((lojaItem) => {
              const selecionado =
                lojaItem.id === id ? false : lojaItem.selecionado;

              return {
                ...lojaItem,
                selecionado,
              };
            })
          );
        },
      });
    }, []);

    const handleHabilitarLoja = useCallback(() => {
      ModalLojas({
        lojasState: lojas,
        handleLojas: (lojasItens, onClose) => {
          setLojas(lojasItens);

          if (onClose) {
            onClose();
          }
        },
      });
    }, [lojas]);

    const getLojas = useCallback(
      async (vendedorId?: string) => {
        const response = await api.get<void, ResponseApi<LojasInterface[]>>(
          ConstanteEnderecoWebservice.LOJA_LISTAR_LOJAVENDEDOR,
          {
            params: { vendedorId: vendedorId || null },
          }
        );

        if (response) {
          if (response.avisos) {
            response.avisos.forEach((aviso) => toast.warning(aviso));
          }
          if (response.sucesso && response.dados) {
            const { dados } = response;

            if (dados.length === 1) {
              setLojas([{ ...dados[0], selecionado: true }]);
            } else {
              setLojas(dados);
            }

            setFocus('nome');
          }
        }
      },
      [setFocus]
    );

    useImperativeHandle(ref, () => ({
      getLojas,
      lojas,
      abrirModalLojas: handleHabilitarLoja,
    }));

    useEffect(() => {
      const currentLojasSelecionadasId =
        lojas
          .filter(({ selecionado }) => selecionado)
          .map((lojaItem) => lojaItem.id) || [];

      if (!defaultLojasSelecionadasId.current && lojas.length > 0) {
        defaultLojasSelecionadasId.current = currentLojasSelecionadasId;
      }

      if (setIsDirty) {
        const isDirty =
          JSON.stringify(defaultLojasSelecionadasId.current) !==
          JSON.stringify(currentLojasSelecionadasId);

        setIsDirty(isDirty);
      }
    }, [lojas, setIsDirty]);

    return (
      <>
        <Flex
          gap={{ base: 3, sm: 6, md: 8 }}
          wrap={{ base: 'wrap', lg: 'nowrap' }}
        >
          <Box w={{ base: 'full', lg: 'min-content' }}>
            <ImagePickerPadrao
              id="foto"
              name="foto"
              w={{ base: 'full', lg: '218px', '2xl': '300px' }}
              h={{ base: '115px', md: '123px', lg: '220px', '2xl': '240px' }}
              label="Adicionar foto"
              error={errors.foto}
              readonly={readonly}
              required={false}
            />
          </Box>
          <SimpleGridForm w="full">
            <GridItem colSpan={{ base: 12, lg: 9 }}>
              <Input
                type="text"
                id="nome"
                name="nome"
                label="Nome"
                placeholder="Digite o nome do vendedor"
                isDisabled={readonly}
                isRequired
              />
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <CheckBoxAtivoInativo
                id="ativo"
                name="ativo"
                label="Status"
                isRequired={false}
                isDisabled={readonly}
              />
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 9 }}>
              <Input
                type="text"
                id="email"
                name="email"
                label="E-mail"
                placeholder="Digite o endereço de e-mail"
                maxLength={100}
                isDisabled={readonly}
              />
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <Input
                type="date"
                id="dataNascimento"
                name="dataNascimento"
                label="Data de nascimento"
                max={getMaxDataNascimento()}
                min={getMinDataNascimento()}
                isDisabled={readonly}
              />
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <InputTelefoneWhatsapp
                name="telefone"
                id="telefone"
                label="Telefone"
                placeholder="(00) 0000-0000"
                whatsappFieldName="telefoneWhatsapp"
                isRequired={false}
                isDisabled={readonly}
              />
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <SelectPadrao
                id="genero"
                name="genero"
                label="Gênero"
                options={listGeneros}
                isDisabled={readonly}
              />
            </GridItem>
          </SimpleGridForm>
        </Flex>
        <SimpleGridForm mt={{ base: 3, sm: 6, md: 8 }}>
          <GridItem colSpan={12}>
            <Text fontWeight="600" color="black" mb="3px">
              Lojas habilitadas para este vendedor
            </Text>
            <Box
              p={{ base: '20px', md: '30px' }}
              bgColor="gray.50"
              borderWidth="1px"
              borderColor="gray.100"
            >
              {!readonly && (
                <Box
                  id="adicionarLoja"
                  mb="5px"
                  onClick={() => handleHabilitarLoja()}
                  cursor="pointer"
                  color="blue.500"
                  fontWeight="bold"
                >
                  Adicionar loja
                </Box>
              )}
              {listLojasSelecionadas && listLojasSelecionadas.length > 0 && (
                <Flex wrap="wrap" gap={{ base: '15px', lg: '30px' }}>
                  {listLojasSelecionadas.map((lojaSelecionadaItem) => (
                    <HStack
                      key={lojaSelecionadaItem.id}
                      align="start"
                      w={{ base: 'full', md: 'max-content' }}
                      p="15px"
                      gap="0 !important"
                      borderRadius="5px"
                      boxShadow="0px 0px 6px 0px rgba(0, 0, 0, 0.2)"
                      bgColor="white"
                    >
                      <VStack
                        w={{ base: 'full', md: '300px' }}
                        align="left"
                        justifyContent="space-between"
                      >
                        <Text fontWeight="bold" m={0}>
                          {lojaSelecionadaItem.fantasia}
                          {lojaSelecionadaItem.cidade &&
                            ` | ${lojaSelecionadaItem.cidade}`}
                        </Text>
                        <Text
                          fontSize="0.6875rem"
                          color="gray.300"
                          m="0 !important"
                        >
                          {lojaSelecionadaItem.endereco}
                        </Text>
                      </VStack>
                      {!readonly && (
                        <Icon
                          as={LixeiraIcon}
                          color="red.400"
                          fontSize="1rem"
                          cursor="pointer"
                          onClick={() =>
                            handleDesabilitarLoja(lojaSelecionadaItem.id)
                          }
                        />
                      )}
                    </HStack>
                  ))}
                </Flex>
              )}
            </Box>
          </GridItem>
        </SimpleGridForm>
      </>
    );
  }
);
