import getOptionsByEnum from 'helpers/format/getOptionsByEnum';

export const StatusManifestoEnum = {
  PENDENTE: 1,
  CIENCIA_EMISSAO: 2,
  NFE_CONFIRMADA: 3,
  DESCONHECIMENTO: 4,
  NFE_NAO_REALIZADA: 5,

  properties: {
    1: { name: 'Pendente', value: 1 },
    2: { name: 'Ciência da emissão', value: 2 },
    3: { name: 'NFe confirmada', value: 3 },
    4: { name: 'Desconhecimento', value: 4 },
    5: { name: 'Não realizada', value: 5 },
  },
};

export const statusManifestoOptions = [
  { label: 'Todas as notas', value: null },
  ...getOptionsByEnum(StatusManifestoEnum),
];
