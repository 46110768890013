import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  ReactNode,
} from 'react';

import { FiltrosProps } from 'pages/Produtos/Listar/validationForm';

interface ProdutosFiltrosContextProps {
  contextFiltros?: FiltrosProps;
  setContextFiltros: React.Dispatch<
    React.SetStateAction<FiltrosProps | undefined>
  >;
}

const ProdutosFiltrosContext = createContext<ProdutosFiltrosContextProps>(
  {} as ProdutosFiltrosContextProps
);

interface ProdutosFiltrosProviderProps {
  children: ReactNode;
}

export default function ProdutosFiltrosProvider({
  children,
}: ProdutosFiltrosProviderProps): JSX.Element {
  const [contextFiltros, setContextFiltros] = useState<FiltrosProps>();

  return (
    <ProdutosFiltrosContext.Provider
      value={{ contextFiltros, setContextFiltros }}
    >
      {children}
    </ProdutosFiltrosContext.Provider>
  );
}

export function useProdutosFiltrosContext(): ProdutosFiltrosContextProps {
  const context = useContext(ProdutosFiltrosContext);

  if (!context)
    throw new Error(
      'useProdutosFiltrosContext must be used within a ProdutosFiltrosProvider.'
    );

  return context;
}
