import React, { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import {
  Button,
  Divider,
  GridItem,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';

import auth from 'modules/auth';
import PlanoContratacaoEnum from 'constants/enum/planoContratacao';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { useProdutosFormularioContext } from 'store/Produtos/ProdutosFormulario';

import { NumberInput } from 'components/update/Input/NumberInput';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';

import { useForm, yupResolver, defaultValues } from './validationForm';

interface EditarEstoqueVariacaoModalProps {
  show: boolean;
  onHide: () => void;
  action: string;
  produtoCorTamanhosId: string[];
  atualizarVariacoes: () => void;
  isDuplicado: boolean;
}

const EditarEstoqueVariacaoModal = ({
  show,
  onHide,
  action,
  isDuplicado,
  produtoCorTamanhosId,
  atualizarVariacoes,
}: EditarEstoqueVariacaoModalProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const { idProduto } = useProdutosFormularioContext();

  const formMethods = useForm({
    resolver: yupResolver,
    defaultValues,
  });

  const { handleSubmit, reset } = formMethods;

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const isAlterar = action === 'alterar' && isDuplicado === false;

  const isPlanoPro = auth.getPlano() === PlanoContratacaoEnum.PRO;

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);
    const response = await api.patch<void, ResponseApi>(
      `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/produtoCorTamanhos/estoque`,
      {
        produtoCorTamanhosId,
        estoqueMinimo: data.estoqueMinimo,
        estoqueAtual: data.estoqueAtual,
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso) {
        toast.success('O estoque foi salvo com sucesso ');
        reset(defaultValues);
        onHide();
        atualizarVariacoes();
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  });

  return (
    <ModalPadraoChakra
      isOpen={show}
      closeOnOverlayClick={false}
      onClose={onHide}
      isCentered={isLargerThan900}
      size={isLargerThan900 ? '3xl' : 'full'}
    >
      <ModalContent
        bg="gray.50"
        borderRadius={isLargerThan900 ? 'md' : '0'}
        marginBottom={isLargerThan900 ? '3.75rem' : '0'}
        marginTop={isLargerThan900 ? '3.75rem' : '0'}
        w={isLargerThan900 ? '900px' : 'full'}
        h={isLargerThan900 ? undefined : 'full'}
      >
        <ModalHeader>
          <Text color="primary.50" fontSize="md">
            Estoque
          </Text>
          <Text color="gray.700" fontSize="xs">
            As informações serão aplicadas para todas as variações selecionadas.
          </Text>
          <ModalCloseButton />
          <Divider mt="10px" />
        </ModalHeader>
        <ModalBody>
          <FormProvider {...formMethods}>
            <SimpleGridForm>
              {!isAlterar && !isPlanoPro && (
                <GridItem colSpan={[12, 12, 4]}>
                  <NumberInput
                    id="modalEditarVariacaoMassa-estoqueAtual"
                    name="estoqueAtual"
                    label="Estoque inicial"
                    precision={14}
                    scale={4}
                    defaultValue={0}
                    autoFocus={!isAlterar}
                    helperText="Ao cadastrar o produto, será gerado automaticamente uma entrada de estoque com a quantidade informada no estoque inicial."
                  />
                </GridItem>
              )}
              <GridItem colSpan={[12, 12, isAlterar ? 6 : 4]}>
                <NumberInput
                  id="modalEditarVariacaoMassa-estoqueMinimo"
                  name="estoqueMinimo"
                  label="Estoque mínimo"
                  precision={14}
                  scale={4}
                  defaultValue={0}
                  autoFocus={isAlterar}
                />
              </GridItem>
            </SimpleGridForm>
          </FormProvider>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outlineDefault"
            colorScheme="gray.300"
            borderRadius="16px"
            onClick={onHide}
            mr="15px"
            w="96px"
            isLoading={isLoading}
          >
            Fechar
          </Button>

          <Button
            variant="solid"
            borderRadius="16px"
            isLoading={isLoading}
            colorScheme="aquamarine.600"
            onClick={() => onSubmit()}
          >
            Aplicar alterações
          </Button>
        </ModalFooter>
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export default EditarEstoqueVariacaoModal;
