import React from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Icon,
  ModalCloseButton,
  useMediaQuery,
  useDisclosure,
  ModalHeader,
  VStack,
  Text,
  ModalFooter,
  HStack,
  Button,
  GridItem,
  Flex,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import ConstanteFuncionalidades from 'constants/permissoes';
import auth from 'modules/auth';

import { SalvarInserirNovoIcon } from 'icons';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { NumberInput } from 'components/update/Input/NumberInput';

import { yupResolver, FormData } from './validationForm';

type ModalEditarProdutoResponse = {
  precoVenda: number;
  markup: number;
};

type ModalEditarProdutoProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalEditarProdutoResponse> & {
    casasDecimaisValor: number;
    nomeProduto: string;
    corDescricao?: string;
    tamanhoDescricao?: string;
    precoVendaAtual: number;
    precoCusto: number;
  };

export const ModalEditarProduto = create<
  ModalEditarProdutoProps,
  ModalEditarProdutoResponse
>(
  ({
    onResolve,
    onReject,
    nomeProduto,
    corDescricao,
    tamanhoDescricao,
    precoVendaAtual,
    precoCusto,
    casasDecimaisValor,
    ...rest
  }) => {
    const possuiPermissaoVisualizarPrecoCusto = auth.possuiPermissao(
      ConstanteFuncionalidades.USUARIO_VISUALIZAR_PRECO_CUSTO
    ).permitido;

    const formMethods = useForm<FormData>({
      resolver: yupResolver,
      defaultValues: { precoVenda: precoVendaAtual },
    });
    const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const precoVenda: number = formMethods.watch('precoVenda');
    const markup =
      precoCusto && precoVenda ? (precoVenda / precoCusto - 1) * 100 : 0;

    function handleCancel() {
      onReject();
    }

    const handleSubmit = formMethods.handleSubmit((data) => {
      if (precoVenda !== 0) {
        onResolve({ precoVenda: data.precoVenda, markup });
      } else {
        toast.warning('O campo preço de venda não pode estar zerado');
      }
    });

    return (
      <ModalPadraoChakra
        isCentered
        size={!isSmallerThan900 ? 'xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalContent
          marginBottom={{ base: 0, md: '3.75rem' }}
          marginTop={{ base: 0, md: '3.75rem' }}
          h="unset"
          maxW={{ base: '100%', md: '600px' }}
        >
          <ModalHeader
            mt={isSmallerThan900 ? 12 : undefined}
            mb={isSmallerThan900 ? 8 : undefined}
            borderBottom="1px"
            borderColor="gray.100"
            px="0"
            mx={{ base: 6, md: 8 }}
          >
            <VStack alignItems="flex-start" spacing="1" lineHeight="1">
              <Text color="primary.500" fontSize={{ base: 'xl', md: 'md' }}>
                {`${nomeProduto}${corDescricao ? ` ${corDescricao}` : ''}`}
              </Text>
              {tamanhoDescricao && (
                <Text color="gray.400" fontSize={{ base: 'sm', md: 'xs' }}>
                  {`Tamanho: ${tamanhoDescricao}`}
                </Text>
              )}{' '}
              2
            </VStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p={{ base: 6, md: 8 }}>
            <FormProvider {...formMethods}>
              <SimpleGridForm>
                <GridItem colSpan={{ base: 12, md: 5 }}>
                  <NumberInput
                    id="precoVenda"
                    name="precoVenda"
                    label="Preço de venda"
                    placeholder={`0,${'0'.repeat(casasDecimaisValor)}`}
                    scale={casasDecimaisValor}
                  />
                  {possuiPermissaoVisualizarPrecoCusto && (
                    <Flex justifyContent="flex-end">
                      <Text fontSize="xs">
                        {`Custo: ${precoCusto.toLocaleString('pt-BR', {
                          minimumFractionDigits: casasDecimaisValor,
                          maximumFractionDigits: casasDecimaisValor,
                        })}`}
                      </Text>
                    </Flex>
                  )}
                </GridItem>

                <GridItem colSpan={12}>
                  <Text color="primary.100">
                    {`Markup: ${markup.toLocaleString('pt-BR', {
                      minimumFractionDigits: casasDecimaisValor,
                      maximumFractionDigits: casasDecimaisValor,
                    })}%`}
                  </Text>
                </GridItem>
              </SimpleGridForm>
            </FormProvider>
          </ModalBody>
          <ModalFooter
            justifyContent="flex-end"
            borderTop="1px"
            borderColor="gray.100"
            mx={{ base: 6, md: 8 }}
            px="0"
          >
            <HStack spacing={6}>
              <Button
                borderRadius="md"
                variant="outline"
                minW="100px"
                color="gray.400"
                onClick={handleCancel}
              >
                Cancelar
              </Button>
              <Button
                borderRadius="md"
                colorScheme="aquamarine"
                minW="120px"
                leftIcon={<Icon as={SalvarInserirNovoIcon} fontSize="lg" />}
                onClick={handleSubmit}
              >
                Confirmar
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
