import styled, { css } from 'styled-components';
import { Dropdown as BootstrapDropdown } from 'react-bootstrap';

export const Dropdown = styled(BootstrapDropdown)`
  > button,
  & > button.btn-primary,
  &.show > button,
  &.dropdown > button,
  &.show > .dropdown-toggle,
  &.show > button.btn-primary {
    max-width: calc(100vw - 115px);

    border-color: transparent;
    background-color: transparent;

    color: var(--black);

    :hover,
    :active {
      border-color: transparent;
      background: transparent;

      box-shadow: none;
    }

    :hover {
      color: var(--black);
    }
  }

  > button:focus,
  & > button.btn-primary:focus,
  &.show > button:focus,
  &.dropdown > button:focus,
  &.show > .dropdown-toggle:focus,
  &.show > .btn-primary:focus {
    border-color: transparent;
    background: transparent;

    box-shadow: none !important;
  }

  &.show > button,
  &.show > .dropdown-toggle,
  &.show > .btn-primary {
    color: var(--black) !important;
  }

  > button.btn-primary:not(:disabled):not(.disabled):active {
    border-color: transparent;
    background-color: transparent;

    color: var(--black);
  }
`;

interface DropdownItemProps {
  disabled?: boolean;
  selected?: boolean;
}

export const DropdownItem = styled(BootstrapDropdown.Item)<DropdownItemProps>`
  position: relative;

  padding-left: 40px;

  color: var(--black) !important;

  &.active,
  &:active {
    background-color: var(--sti-ck-colors-purple-50);
  }

  &:hover {
    background-color: var(--sti-ck-colors-purple-50);
  }

  ${({ disabled }) =>
    disabled &&
    css`
      user-select: none;

      background-color: rgba(0, 0, 0, 0.025) !important;

      &.active,
      &:active {
        background-color: rgba(0, 0, 0, 0.025);
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.025);
      }

      cursor: unset;
    `}

  ${({ selected }) =>
    selected &&
    css`
      &:after {
        content: '';
        position: absolute;
        left: 18px;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        width: 5px;
        height: 10px;
        border: solid var(--black);
        border-width: 0 1px 1px 0;
      }
    `}
`;

export const ToggleIcon = styled.div`
  font-size: 0.5rem;

  user-select: none;

  margin-left: 5px;
`;

export const ToggleText = styled.div`
  font-weight: bold;
  text-decoration: underline;

  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  user-select: none;
`;

export const DropdownToggle = styled(BootstrapDropdown.Toggle)`
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding: 0px !important;

  &:hover {
    color: var(--black);
  }

  &:after {
    display: none;
  }
`;
