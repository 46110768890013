import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

import { EntradaMercadoriaDadosCadastroProvider } from '../store/EntradaMercadoria/EntradaMercadoriaDadosCadastro';
import { EntradaMercadoriaEtapasProvider } from '../store/EntradaMercadoria/EntradaMercadoriaEtapas';

import { EntradaManual } from '../pages/EntradaMercadoria/EntradaManual';
import EntradaMercadoriaListar from '../pages/EntradaMercadoria/Listar';
import { LancarEstoqueEntradaMercadoria } from '../pages/EntradaMercadoria/LancarEstoque';
import { IniciarEntradaImportacao } from '../pages/EntradaMercadoria/Importacao/Iniciar';
import { ContinuarEntradaImportacao } from '../pages/EntradaMercadoria/Importacao/Continuar';
import { FinalizarEntradaImportacao } from '../pages/EntradaMercadoria/Importacao/Finalizar';

type TParams = { id?: string };

export const EntradaMercadoriaRoutes = [
  <Route
    key="entrada-manual"
    path={ConstanteRotas.ENTRADA_MERCADORIA_ENTRADA_MANUAL}
  >
    <EntradaMercadoriaEtapasProvider>
      <LayoutGuard
        key="entrada-manual"
        render={({ match }: RouteComponentProps<TParams>) => (
          <EntradaMercadoriaDadosCadastroProvider
            defaultEntradaMercadoriaId={match.params.id}
          >
            <EntradaManual />
          </EntradaMercadoriaDadosCadastroProvider>
        )}
        breadcrumb={[
          {
            title: 'Entrada de mercadorias',
            path: ConstanteRotas.ENTRADA_MERCADORIA,
          },
          {
            title: 'Nova entrada',
          },
        ]}
        path={ConstanteRotas.ENTRADA_MERCADORIA_ENTRADA_MANUAL}
        exact
        meta={{ auth: true }}
      />
    </EntradaMercadoriaEtapasProvider>
  </Route>,
  <Route
    key="entrada-manual-visualizar"
    path={ConstanteRotas.ENTRADA_MERCADORIA_ENTRADA_MANUAL_VISUALIZAR}
  >
    <EntradaMercadoriaEtapasProvider>
      <LayoutGuard
        key="entrada-manual-visualizar"
        render={({ match }: RouteComponentProps<TParams>) => (
          <EntradaMercadoriaDadosCadastroProvider
            defaultEntradaMercadoriaId={match.params.id}
            isReadOnly
          >
            <EntradaManual />
          </EntradaMercadoriaDadosCadastroProvider>
        )}
        breadcrumb={[
          {
            title: 'Entrada de mercadorias',
            path: ConstanteRotas.ENTRADA_MERCADORIA,
          },
          {
            title: 'Nova entrada',
          },
        ]}
        path={ConstanteRotas.ENTRADA_MERCADORIA_ENTRADA_MANUAL_VISUALIZAR}
        exact
        meta={{ auth: true }}
      />
    </EntradaMercadoriaEtapasProvider>
  </Route>,
  <LayoutGuard
    key="entrada-mercadoria-listar"
    permissaoFuncionalidade={ConstanteFuncionalidades.ENTRADA_MERCADORIA_LISTAR}
    component={EntradaMercadoriaListar}
    breadcrumb={[
      {
        title: 'Entrada de mercadorias',
      },
    ]}
    path={ConstanteRotas.ENTRADA_MERCADORIA}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="entrada-mercadoria-lancar-estoque"
    permissaoFuncionalidade={
      ConstanteFuncionalidades.ENTRADA_MERCADORIA_LANCAR_ESTOQUE
    }
    component={LancarEstoqueEntradaMercadoria}
    breadcrumb={[
      {
        title: 'Entrada de mercadorias',
        path: ConstanteRotas.ENTRADA_MERCADORIA,
      },
      {
        title: 'Lançar estoque',
      },
    ]}
    path={ConstanteRotas.ENTRADA_MERCADORIA_LANCAR_ESTOQUE}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="entrada-mercadoria-iniciar-importacao"
    permissaoFuncionalidade={
      ConstanteFuncionalidades.ENTRADA_MERCADORIA_CADASTRAR
    }
    component={IniciarEntradaImportacao}
    breadcrumb={[
      {
        title: 'Entrada de mercadorias',
        path: ConstanteRotas.ENTRADA_MERCADORIA,
      },
      {
        title: 'Importar NFe',
      },
    ]}
    path={ConstanteRotas.ENTRADA_MERCADORIA_ENTRADA_IMPORTACAO_INICIAR}
    exact
    meta={{ auth: true }}
  />,
  <Route
    key="entrada-mercadoria-continuar-importacao"
    path={ConstanteRotas.ENTRADA_MERCADORIA_ENTRADA_IMPORTACAO_CONTINUAR}
  >
    <EntradaMercadoriaEtapasProvider>
      <LayoutGuard
        key="entrada-mercadoria-continuar-importacao"
        render={({ match }: RouteComponentProps<TParams>) => (
          <EntradaMercadoriaDadosCadastroProvider
            defaultEntradaMercadoriaId={match.params.id}
          >
            <ContinuarEntradaImportacao />
          </EntradaMercadoriaDadosCadastroProvider>
        )}
        breadcrumb={[
          {
            title: 'Entrada de mercadorias',
            path: ConstanteRotas.ENTRADA_MERCADORIA,
          },
          {
            title: 'Importar NFe',
          },
        ]}
        path={ConstanteRotas.ENTRADA_MERCADORIA_ENTRADA_IMPORTACAO_CONTINUAR}
        exact
        meta={{ auth: true }}
      />
    </EntradaMercadoriaEtapasProvider>
  </Route>,
  <LayoutGuard
    key="entrada-mercadoria-finalizar-importacao"
    component={FinalizarEntradaImportacao}
    breadcrumb={[
      {
        title: 'Entrada de mercadorias',
        path: ConstanteRotas.ENTRADA_MERCADORIA,
      },
      {
        title: 'Importar NFe',
      },
    ]}
    path={ConstanteRotas.ENTRADA_MERCADORIA_ENTRADA_IMPORTACAO_FINALIZAR}
    exact
    meta={{ auth: true }}
  />,
];
