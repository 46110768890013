import { HexColorPicker as HexColor } from 'react-colorful';
import { Flex, Box } from '@chakra-ui/react';
import { useCallback, useRef, useEffect, useState } from 'react';

type HexColorPicker = {
  colorDefault?: string;
  getColor: (color: string) => void;
  height?: string;
};

export const HexColorPicker = ({
  colorDefault = '#FFF',
  getColor,
  height = '303px',
}: HexColorPicker) => {
  const [selectedColor, setSelectedColor] = useState(colorDefault);
  const colorRef = useRef('');

  const handleColor = useCallback(() => {
    setSelectedColor(colorRef.current);
    getColor(colorRef.current);
  }, [getColor]);

  useEffect(() => {
    setSelectedColor(colorDefault);
  }, [colorDefault]);

  return (
    <Flex justifyContent={['center', 'flex-start']} w="400px">
      <Box
        borderRadius="6px"
        mr="10px"
        h={`calc(${height} + 18px)`}
        bg={selectedColor || colorDefault}
        w={['30px', '40px']}
      />
      <Flex
        bg="#121212"
        w={['290px', '360px']}
        borderRadius="6px"
        mb="40px"
        pb="5px"
      >
        <HexColor
          onMouseUp={() => handleColor()}
          color={colorDefault}
          onChange={(color) => {
            colorRef.current = color;
          }}
        />
        <style>
          {`
          .react-colorful {
            width: 360px;
            height: ${height};
          }
          .react-colorful__hue {
            height: 5px;
            border-radius: 5px;
            margin: 0 10px 0 10px
          }
          .react-colorful__saturation-pointer {
            width: 20px;
            height: 20px;
          }
          .react-colorful__saturation {
            margin: 0 0 12px 0;
            border-radius: 5px;
            border-bottom: none;
          }
          .react-colorful__hue-pointer {
            width: 15px;
            height: 15px;
          }
          `}
        </style>
      </Flex>
    </Flex>
  );
};
