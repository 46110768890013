export type TipoCadastroCampoPersonalizado = 1 | 2;

const TipoCadastroCampoPersonalizadoEnum = {
  PRODUTO: 1 as TipoCadastroCampoPersonalizado,
  CLIENTE: 2 as TipoCadastroCampoPersonalizado,

  properties: {
    1: { name: 'Cadastro de produtos', value: 1 },
    2: { name: 'Cadastro de cliente', value: 2 },
  },

  options: [
    { name: 'Cadastro de produtos', value: 1 },
    { name: 'Cadastro de cliente', value: 2 },
  ],
};

export default TipoCadastroCampoPersonalizadoEnum;
