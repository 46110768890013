import React, { useEffect, useState, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory, RouteComponentProps } from 'react-router-dom';

import ConstanteRotas from 'constants/rotas';
import { obterMovimentacaoEstoque as apiObterMovimentacaoEstoque } from 'helpers/api/MovimentacaoEstoque/obterMovimentacaoEstoque';

import ManterFoco from 'components/Geral/ManterFoco';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { FormFooter } from 'components/update/Form/FormFooter';

import Formulario from '..';
import { yupResolver, FormData } from '../validationForm';

export type VisualizarTParams = { id: string };

type VisualizarProps = RouteComponentProps<VisualizarTParams> & {
  movimentacaoEstoqueId?: string;
};

const Visualizar = ({
  movimentacaoEstoqueId: propsMovimentacaoEstoqueId,
  match,
}: VisualizarProps) => {
  const history = useHistory();
  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues: {
      usuario: '',
      dataEmissao: new Date().toLocaleString('pt-BR'),
    },
  });

  const [dataHoraCriacao, setDataHoraCriacao] = useState('');
  const [dataHoraUltimaAlteracao, setDataHoraUltimaAlteracao] = useState('');

  const movimentacaoEstoqueId = propsMovimentacaoEstoqueId || match?.params.id;

  const [isLoading, setIsLoading] = useState(false);

  const latestProps = useRef({
    reset: formMethods.reset,
    movimentacaoEstoqueId,
    history,
  });
  useEffect(() => {
    latestProps.current = {
      reset: formMethods.reset,
      movimentacaoEstoqueId,
      history,
    };
  });

  useEffect(() => {
    async function obterMovimentacaoEstoque() {
      setIsLoading(true);
      const responseData = await apiObterMovimentacaoEstoque(
        latestProps.current.movimentacaoEstoqueId
      );
      if (responseData) {
        const {
          dataEmissao,
          dataHoraUltimaAlteracao: newDataHoraUltimaAlteracao,
          usuarioNome,
          tipoOperacao,
          motivo,
          localEstoqueOpcaoSelect,
        } = responseData;

        setDataHoraCriacao(dataEmissao);
        setDataHoraUltimaAlteracao(newDataHoraUltimaAlteracao);

        latestProps.current.reset({
          usuario: usuarioNome,
          tipoOperacao,
          motivo,
          dataEmissao: new Date(dataEmissao).toLocaleString('pt-BR'),
          localEstoque: {
            value: localEstoqueOpcaoSelect.id,
            label: localEstoqueOpcaoSelect.nome,
          },
        });
        setIsLoading(false);
      } else {
        setIsLoading(false);
        latestProps.current.history.push(ConstanteRotas.MOVIMENTACAO_ESTOQUE);
      }
    }

    obterMovimentacaoEstoque();
  }, []);

  return (
    <Box>
      <ManterFoco>
        {isLoading && <LoadingPadrao />}
        <FormProvider {...formMethods}>
          <Formulario
            isReadonly
            movimentacaoEstoqueId={movimentacaoEstoqueId}
          />
        </FormProvider>

        <FormFooter
          isDisabled={isLoading}
          dates={{ dataHoraCriacao, dataHoraUltimaAlteracao }}
        />
      </ManterFoco>
    </Box>
  );
};

export default Visualizar;
