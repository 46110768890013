import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { ChakraProvider } from '@chakra-ui/react';
import 'react-toastify/dist/ReactToastify.css';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import 'react-virtualized/styles.css';

import { useSistemaAtual } from 'helpers/data/useSistemaAtual';

import { HeaderSistema } from 'components/Header';

import GlobalStyle from './styles/global';
import Routes from './routes/index.routes';
import FullScreen from './store/FullScreen';
import theme from './theme';

// theme css file

const App = () => {
  const { nomeExibicao } = useSistemaAtual();

  useEffect(() => {
    document.title = nomeExibicao;
  }, [nomeExibicao]);

  return (
    <ChakraProvider theme={theme}>
      <FullScreen>
        <Routes />
        <GlobalStyle />
        <ToastContainer />
      </FullScreen>
      <HeaderSistema />
    </ChakraProvider>
  );
};

export default App;
