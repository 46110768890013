import React, { useState } from 'react';
import {
  ModalContent,
  ModalBody,
  Flex,
  Button,
  Heading,
  Text,
  ModalFooter,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import Input from 'components/PDV/Input';

type ModalRemoverDadosIntegracaoProps = {
  showCancelButton?: boolean;
  title?: string;
  callback: () => void;
  callbackCancel?: () => void;
  colorButtonConfirm?: string;
  labelButtonCancelar?: string;
  labelButtonConfirmar?: string;
  textForConfirm?: string;
  textoExplicacao?: string;
};

type ModalProps = Omit<
  ModalRemoverDadosIntegracaoProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalRemoverDadosIntegracaoProps>;

export const ModalRemoverDadosIntegracao = create<ModalProps>(
  ({
    labelButtonCancelar,
    labelButtonConfirmar,
    colorButtonConfirm = 'white',
    title,
    callbackCancel,
    showCancelButton = true,
    textoExplicacao,
    callback,
    textForConfirm = 'EXCLUIR DADOS',
  }) => {
    const [isOpen, setIsOpen] = useState(true);

    const formMethods = useForm();

    const campoConfirmarWatch = formMethods.watch('campoConfirmar');

    const confirmationFieldIsCorrect = campoConfirmarWatch === textForConfirm;

    const handleConfirm = () => {
      setIsOpen(false);
      callback();
    };

    const handleCancel = () => {
      setIsOpen(false);
      if (callbackCancel) {
        callbackCancel();
      }
    };

    return (
      <ModalPadraoChakra
        onClose={() => {
          setIsOpen(false);
        }}
        isOpen={isOpen}
        isCentered
        size="2xl"
        motionPreset="slideInRight"
        closeOnOverlayClick={false}
        autoFocus={false}
      >
        <ModalContent
          marginBottom={{ base: 0, sm: '3.75rem' }}
          marginTop={{ base: 0, sm: '3.75rem' }}
          h="unset"
          bg="gray.50"
          w="640px"
        >
          <ModalBody p={0} h="100%">
            <Flex flexDirection="column" align="stretch" h="100%" p="40px">
              <Flex
                justifyContent="flex-start"
                flexDirection="column"
                style={{
                  borderBottomLeftRadius: '5px',
                  borderBottomRightRadius: '5px',
                }}
              >
                <Heading
                  id="tituloModal"
                  as="h2"
                  fontSize="20px"
                  color="red.500"
                  fontWeight="semibold"
                  textAlign="initial"
                >
                  {title || 'Você tem certeza?'}
                </Heading>
                <Flex
                  minH="63px"
                  flexWrap="wrap"
                  mt="24px"
                  fontSize="14px"
                  color="black"
                >
                  {textoExplicacao || (
                    <Text as="span">
                      Este processo apenas remove os dados contidos no sistema
                      <Text
                        as="span"
                        fontWeight="bold"
                        color="red.500"
                        mx="4px"
                      >
                        Para encerrar as cobranças e cancelar o módulo do seu
                        contrato,
                      </Text>
                      entre em contato conosco.
                    </Text>
                  )}
                </Flex>
                <Flex mt="12px" flexDir="column">
                  <FormProvider {...formMethods}>
                    <Text
                      textColor="gray.700"
                      fontSize="14px"
                      height="28px"
                      pt="6px"
                    >
                      Digite
                      <Text
                        height="28px"
                        mx="5px"
                        pt="6px"
                        pb="3px"
                        px="6px"
                        display="inline"
                        bgColor="gray.200"
                      >
                        {textForConfirm}
                      </Text>
                      no campo abaixo para confirmar a ação
                    </Text>
                    <Input
                      mt="6px"
                      id="campoConfirmar"
                      name="campoConfirmar"
                      colSpan={12}
                      maxW="480px"
                      borderColor="gray.200"
                      maxH="40px"
                      placeholder="Digite com letras maiúsculas"
                    />
                  </FormProvider>
                </Flex>
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter
            display="flex"
            justifyContent="center"
            pt="14px"
            pb="40px"
            gap="24px"
          >
            {showCancelButton && (
              <Button
                onClick={() => handleCancel()}
                variant="outlineDefault"
                colorScheme="gray"
                borderColor="gray.300"
                color="gray.300"
                minW="96px"
                fontSize="sm"
                height="32px"
              >
                {labelButtonCancelar || 'Cancelar'}
              </Button>
            )}

            <Button
              onClick={handleConfirm}
              variant="solid"
              color={colorButtonConfirm}
              minW="240px"
              fontSize="sm"
              isDisabled={!confirmationFieldIsCorrect}
              height="32px"
              fontWeight="normal"
              colorScheme="red"
            >
              {labelButtonConfirmar || 'Remover dados da integração'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
