import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  useDisclosure,
  Text,
  useMediaQuery,
  Box,
  Flex,
  ModalCloseButton,
  Icon,
  ModalFooter,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { shallowEqual } from 'helpers/validation/shallowEqual';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import { StatusPesquisaClientesFornecedor } from 'constants/enum/statusPesquisaClientesFornecedor';
import { formatOptionsSelectClient } from 'helpers/format/formatSelectClient';
import OptionType from 'types/optionType';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import {
  ForwardRefData,
  LoadMoreRowsParams,
} from 'components/update/Table/VirtualizedInfiniteTable';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { PaginationData } from 'components/update/Pagination';
import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import {
  ProdutoSelecionadoProps,
  ListaTrocaProps,
  InformacaoAdicionalDoItemProps,
} from 'pages/PDV/TrocarProdutos/Types/validationForm';

import {
  ModalTrocarProdutosPdv,
  SelectClienteProps,
  formDefaultValues,
  ProdutoSelect,
  ListaVendasPaginada,
  ListaProdutoPaginadoTrocar,
  FormData,
} from './validationForms';
import { MobileModalTrocarProdutos } from './Mobile';
import { EscolherVendaParaDevolucao } from './EscolherVenda';
import { EscolherProdutosParaDevolucao } from './EscolherProduto';
import { BotoesFooterDevolucao } from './BotoesFooter';
import { RealizarPesquisaDasVendas } from './RealizarPesquisa';

type ModalTrocarProdutoProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalTrocarProdutosPdv> & {
    setProdutosTroca: React.Dispatch<
      React.SetStateAction<ProdutoSelecionadoProps[]>
    >;
    setInfoComplementaresVenda?: React.Dispatch<
      React.SetStateAction<ListaTrocaProps | undefined>
    >;
    infoComplementaresVenda?: ListaTrocaProps;
    produtosTroca?: ProdutoSelecionadoProps[];
    exibirVendasModal: boolean;
    limparForm?: () => void;
    setAbrirModalAutomaticamente: React.Dispatch<React.SetStateAction<boolean>>;
    setAtualizarQuantidade?: React.Dispatch<React.SetStateAction<boolean>>;
    setExisteQuantidadeFields?: React.Dispatch<React.SetStateAction<boolean>>;
    listarFieldsFiltrado?: () => void;
    setInformacaoAdicionalDoItem?: React.Dispatch<
      React.SetStateAction<InformacaoAdicionalDoItemProps | undefined>
    >;
  };

export const ModalTrocarProdutos = create<
  ModalTrocarProdutoProps,
  ModalTrocarProdutosPdv
>(
  ({
    onResolve,
    limparForm,
    onReject,
    setAtualizarQuantidade,
    exibirVendasModal,
    listarFieldsFiltrado,
    setInformacaoAdicionalDoItem,
    setProdutosTroca,
    setExisteQuantidadeFields,
    setInfoComplementaresVenda,
    infoComplementaresVenda,
    produtosTroca,
    setAbrirModalAutomaticamente,
    ...rest
  }) => {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const [isLoading, setIsLoading] = useState(false);
    const [aparecerVendas, setAparecerVendas] = useState(false);
    const [vendaSelecionada, setVendaSelecionada] = useState(false);
    const [selecionarTodosProdutos, setSelecionarTodosProdutos] = useState(
      false
    );
    const [vendasLimpadas, setVendasLimpadas] = useState(false);

    const [itemsTotalCount, setItemsTotalCount] = useState(0);

    const [idOperacaoTroca, setIdOperacaoTroca] = useState('');
    const [nomeCliente, setNomeCliente] = useState('');
    const [idCliente, setIdCliente] = useState('');
    const [numeroVenda, setNumeroVenda] = useState(0);
    const [totalRegistros, setTotalRegistros] = useState(0);

    const [vendas, setVendas] = useState<ModalTrocarProdutosPdv[]>([]);
    const [listaProduto, setListaProduto] = useState<ProdutoSelecionadoProps[]>(
      []
    );

    const [isMobile] = useMediaQuery('(max-width: 900px)');

    const formMethods = useForm<FormData>({
      defaultValues: formDefaultValues,
    });
    const [currentFilters, setCurrentFilters] = useState<FormData>(
      formDefaultValues
    );

    useEffect(() => {
      if (produtosTroca) {
        const atualizarValoresProduto = () => {
          setVendaSelecionada(exibirVendasModal);
          if (infoComplementaresVenda) {
            setIdOperacaoTroca(infoComplementaresVenda?.idOperacao);
            setNomeCliente(infoComplementaresVenda?.nomeCliente);
            setNumeroVenda(infoComplementaresVenda?.numeroVenda);
            setIdCliente(infoComplementaresVenda?.idCliente);
          }
        };
        atualizarValoresProduto();
      }
    }, [produtosTroca, exibirVendasModal, infoComplementaresVenda]);

    const latestProps = useRef({
      setValue: formMethods.setValue,
      setFocus: formMethods.setFocus,
    });

    useEffect(() => {
      latestProps.current = {
        setValue: formMethods.setValue,
        setFocus: formMethods.setFocus,
      };
    }, [formMethods]);

    const filtersSubmit = formMethods.handleSubmit((data) => {
      const filtersIsDirty = !shallowEqual(data, currentFilters);
      if (filtersIsDirty) {
        setCurrentFilters(data);
      }
    });

    const valorProduto = formMethods.watch('produto');

    function handleToggleSelecionarTodosItens() {
      setListaProduto((valorAnterior) =>
        valorAnterior.map((item) => {
          return {
            ...item,
            isChecked: !selecionarTodosProdutos,
          };
        })
      );

      setSelecionarTodosProdutos(!selecionarTodosProdutos);
    }

    const vendaRef = useRef<ForwardRefData>(null);
    const produtoRef = useRef<ForwardRefData>(null);

    const getProdutoOptions = useCallback(
      async (inputValue: string, dataPagination: PaginationData) => {
        const response = await api.get<
          void,
          ResponseApi<GridPaginadaRetorno<ProdutoSelect>>
        >(
          formatQueryPagegTable(
            ConstanteEnderecoWebservice.PRODUTO_LISTAR_SELECT_PAGINADO,
            dataPagination
          ),
          {
            params: {
              pesquisa: inputValue,
            },
          }
        );

        if (response) {
          if (response.avisos) {
            response.avisos.map((item: string) => toast.warning(item));
          }
          setTotalRegistros(response.dados.total || 0);
          if (response.sucesso && response.dados.registros) {
            return response.dados.registros.map((produto) => ({
              label: produto.nome,
              value: produto.id,
            }));
          }
        }
        return [];
      },
      []
    );

    const loadMoreRows = useCallback(
      async ({
        currentPage,
        pageSize,
        orderColumn,
        orderDirection,
      }: LoadMoreRowsParams) => {
        setIsLoading(true);

        const paginationData = {
          currentPage,
          pageSize,
          orderColumn,
          orderDirection,
        };

        const response = await api.get<void, ResponseApi<ListaVendasPaginada>>(
          formatQueryPagegTable(
            ConstanteEnderecoWebservice.LISTAGEM_PAGINADA_TROCA,
            paginationData
          ),
          {
            params: {
              ClienteId:
                (formMethods.getValues().clienteFornecedorId as OptionType)
                  ?.value || '',
              ProdutoCorTamanhoId: valorProduto?.value,
              DataEmissaoInicio: formMethods.getValues().dataVendaInicio,
              DataEmissaoFim: formMethods.getValues().dataVendaFim,
              NumeroOperacao: formMethods.getValues().idVenda || 0,
            },
          }
        );

        if (response) {
          if (response.sucesso) {
            setVendas((prev) => [...prev, ...(response.dados.registros || [])]);
            setItemsTotalCount(response.dados.total || 0);
          }
        }

        setIsLoading(false);
      },
      [formMethods, valorProduto]
    );

    const loadMoreRowsProduct = useCallback(
      async ({
        currentPage,
        pageSize,
        orderColumn,
        orderDirection,
      }: LoadMoreRowsParams) => {
        setIsLoading(true);

        const paginationData = {
          currentPage,
          pageSize,
          orderColumn,
          orderDirection,
        };

        const response = await api.get<
          void,
          ResponseApi<ListaProdutoPaginadoTrocar>
        >(
          formatQueryPagegTable(
            ConstanteEnderecoWebservice.LISTAR_PRODUTOS_PARA_TROCA_DEVOLUCAO,
            paginationData
          ),
          {
            params: {
              operacaoId: idOperacaoTroca,
            },
          }
        );

        if (response) {
          if (response.sucesso) {
            if (setInformacaoAdicionalDoItem) {
              const informacoesSobreTroca = {
                tabelaPreco: response.dados.tabelaPreco,
                contaFinanceira: response.dados.contaFinanceira,
                vendedor: response.dados.vendedor,
                numeroVenda: response.dados.numeroVenda,
              };
              setInformacaoAdicionalDoItem(informacoesSobreTroca);
            }

            const novoProduto = response.dados.registros.map((item) => {
              return {
                ...item,
                isChecked: false,
              };
            });

            const setarNovoProduto = () => {
              setListaProduto((prev) => [...prev, ...(novoProduto || [])]);
            };

            if (!vendasLimpadas) {
              if (produtosTroca !== undefined) {
                const produtos = response.dados.registros.map((item) => {
                  const existeProduto = (produtosTroca || []).some(
                    (produtoExistente) =>
                      produtoExistente.operacaoItemId === item.operacaoItemId
                  );

                  return {
                    ...item,
                    isChecked: !!existeProduto,
                  };
                });
                setListaProduto((prev) => [...prev, ...(produtos || [])]);
              } else {
                setarNovoProduto();
              }
            } else {
              setarNovoProduto();
            }

            setItemsTotalCount(response.dados.total || 0);
          }
        }

        setIsLoading(false);
      },
      [
        idOperacaoTroca,
        setInformacaoAdicionalDoItem,
        produtosTroca,
        vendasLimpadas,
      ]
    );

    const handlePesquisarProduto = async () => {
      setAparecerVendas(true);

      setVendasLimpadas(true);

      if (vendaRef.current) {
        setVendas([]);
        await vendaRef.current.reload();
      }
    };

    const handleExibirProdutoTroca = useCallback(
      async (descProduto: ModalTrocarProdutosPdv) => {
        setIdOperacaoTroca(descProduto.operacaoId);
        setNomeCliente(descProduto.clienteNome);
        setIdCliente(descProduto?.clienteId);
        setNumeroVenda(descProduto.numeroOperacao);
        setVendaSelecionada(true);

        if (produtoRef.current) {
          setListaProduto([]);

          await produtoRef.current.reload();
        }
      },
      []
    );

    const listaProdutosSelecionados = listaProduto.filter(
      (item) => item.isChecked
    );

    const limparVendas = () => {
      setIsLoading(true);
      setVendaSelecionada(false);
      setListaProduto([]);
      setProdutosTroca([]);
      setSelecionarTodosProdutos(false);
      if (limparForm) {
        limparForm();
      }
    };

    function handleExibirVendas() {
      const existemProdutosSelecionados = listaProduto.filter(
        (item) => item.isChecked
      );
      if (existemProdutosSelecionados.length > 0) {
        ModalConfirmacaoExcluir({
          title: 'Você quer escolher outra venda?',
          text: `Se você prosseguir com essa ação, os produtos selecionados serão cancelados`,
          confirmButtonText: 'Sim, continuar!',
          cancelButtonText: 'Cancelar',
          callback: async (ok: boolean) => {
            if (ok) {
              limparVendas();
            }
          },
        });
      } else {
        limparVendas();
      }
    }

    const handleSubmit = () => {
      setProdutosTroca((valorAnterior) => {
        const novoValor = [...(valorAnterior || [])].filter((item) =>
          listaProdutosSelecionados.some(
            (itemNovaLista) =>
              itemNovaLista.operacaoItemId === item.operacaoItemId
          )
        );

        listaProdutosSelecionados.forEach((item) => {
          const produtoJaExiste = novoValor.some(
            (produto) => produto.operacaoItemId === item.operacaoItemId
          );

          if (!produtoJaExiste) {
            const produtoNovo = {
              nome: item.nome,
              produtoCorTamanhoId: item.produtoCorTamanhoId,
              quantidade: item.quantidade,
              valor: item.valor,
              isChecked: item.isChecked,
              desconto: item.desconto,
              operacaoItemId: item.operacaoItemId,
              volumeUnitario: item.volumeUnitario,
              acrescimo: item.acrescimo,
            };

            novoValor.push(produtoNovo);
          }
        });

        return novoValor;
      });

      setAbrirModalAutomaticamente(false);
      if (setExisteQuantidadeFields) {
        setExisteQuantidadeFields(true);
      }

      if (listarFieldsFiltrado) {
        listarFieldsFiltrado();
      }

      if (setInfoComplementaresVenda) {
        const itensParaTroca = {
          numeroVenda,
          idOperacao: idOperacaoTroca,
          nomeCliente,
          idCliente,
        };
        setInfoComplementaresVenda(itensParaTroca);
      }
      if (setAtualizarQuantidade) {
        setAtualizarQuantidade(true);
      }
      setVendasLimpadas(false);

      onClose();
    };

    function handleToggleSelecionarProdutoTroca(index: number) {
      const novoArray = [...listaProduto];

      const item = novoArray[index];

      novoArray.splice(index, 1, {
        ...item,
        isChecked: !item.isChecked,
      });

      setListaProduto(novoArray);
    }

    async function obterClientes(inputValue: string) {
      const response = await api.get<void, ResponseApi<SelectClienteProps[]>>(
        ConstanteEnderecoWebservice.CLIENTE_FORNECEDOR_LISTAR_SELECT,
        {
          params: {
            cpfCnpjNomeApelidoCodigoExterno: inputValue,
            filtroTipoCadastroPessoa: StatusPesquisaClientesFornecedor.CLIENTES,
          },
        }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response.sucesso && response.dados) {
          return response.dados.map((cliente) => ({
            ...formatOptionsSelectClient(cliente),
          }));
        }
      }

      return [];
    }

    return (
      <ModalPadraoChakra
        isCentered={!isMobile}
        size={!isMobile ? '4xl' : 'full'}
        {...rest}
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
      >
        <ModalContent
          bg="white"
          borderRadius={isMobile ? '0' : '5px'}
          maxWidth="960px"
          maxHeight="530px"
          w="100%"
          h="100%"
        >
          <FormProvider {...formMethods}>
            {isMobile ? (
              <MobileModalTrocarProdutos
                onClose={onClose}
                setProdutosTroca={setProdutosTroca}
                exibirVendasModal={exibirVendasModal}
                produtosTroca={produtosTroca}
                handleSubmit={handleSubmit}
                limparForm={limparForm}
                getProdutoOptions={getProdutoOptions}
                totalRegistros={totalRegistros}
                aparecerVendas={aparecerVendas}
                setListaProduto={setListaProduto}
                handleToggleSelecionarProdutoTroca={
                  handleToggleSelecionarProdutoTroca
                }
                listaProduto={listaProduto}
                handleToggleSelecionarTodosItens={
                  handleToggleSelecionarTodosItens
                }
                selecionarTodosProdutos={selecionarTodosProdutos}
                loadMoreRowsProduct={loadMoreRowsProduct}
                produtoRef={produtoRef}
                handleExibirProdutoTroca={handleExibirProdutoTroca}
                isLoading={isLoading}
                setVendas={setVendas}
                itemsTotalCount={itemsTotalCount}
                loadMoreRows={loadMoreRows}
                vendaRef={vendaRef}
                vendas={vendas}
                numeroVenda={numeroVenda}
                getClientes={obterClientes}
                filtersSubmit={filtersSubmit}
                setVendaSelecionada={setVendaSelecionada}
                handlePesquisarProduto={handlePesquisarProduto}
              />
            ) : (
              <>
                <ModalBody padding="0" w="full">
                  {vendaSelecionada ? (
                    <Box h={isLoading ? 400 : 'full'} w="full" padding="22px">
                      <Flex>
                        <Flex>
                          <Icon
                            fontSize="20px"
                            mr="50px"
                            color="black"
                            cursor="pointer"
                            onClick={() => handleExibirVendas()}
                            as={FiChevronLeft}
                          />
                        </Flex>
                        <Flex>
                          <Text fontSize="16px" color="primary.50">
                            Venda nº {numeroVenda}
                          </Text>
                        </Flex>
                      </Flex>

                      <Box w="100%" mt="15px">
                        <EscolherProdutosParaDevolucao
                          isLoading={isLoading}
                          produtoRef={produtoRef}
                          listaProduto={listaProduto}
                          itemsTotalCount={itemsTotalCount}
                          loadMoreRowsProduct={loadMoreRowsProduct}
                          handleToggleSelecionarTodosItens={
                            handleToggleSelecionarTodosItens
                          }
                          selecionarTodosProdutos={selecionarTodosProdutos}
                          handleToggleSelecionarProdutoTroca={
                            handleToggleSelecionarProdutoTroca
                          }
                        />
                      </Box>
                    </Box>
                  ) : (
                    <Flex h="full" w="full">
                      <RealizarPesquisaDasVendas
                        filtersSubmit={filtersSubmit}
                        getProdutoOptions={getProdutoOptions}
                        totalRegistros={totalRegistros}
                        getClientes={obterClientes}
                        handlePesquisarProduto={handlePesquisarProduto}
                        onClose={onClose}
                        w="336px"
                        tamanhoComponente="40%"
                      />
                      <Box paddingY="20px" pl="20px" pr="5px" w="80%">
                        <Flex mb="20px" justifyContent="flex-end" w="full">
                          <ModalCloseButton
                            onClick={() => setAbrirModalAutomaticamente(false)}
                          />
                        </Flex>
                        {aparecerVendas && (
                          <EscolherVendaParaDevolucao
                            isLoading={isLoading}
                            vendaRef={vendaRef}
                            vendas={vendas}
                            handleExibirProdutoTroca={handleExibirProdutoTroca}
                            itemsTotalCount={itemsTotalCount}
                            loadMoreRows={loadMoreRows}
                            FiChevronRight={FiChevronRight}
                          />
                        )}
                      </Box>
                    </Flex>
                  )}
                </ModalBody>
                {vendaSelecionada && !isMobile && (
                  <ModalFooter>
                    <BotoesFooterDevolucao
                      handleSubmit={handleSubmit}
                      onClose={onClose}
                    />
                  </ModalFooter>
                )}
              </>
            )}
          </FormProvider>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
