export const enumRelatorioPersonalizadoClientes = {
  CODIGO: 1,
  NOME: 2,
  CPFCNPJ: 3,
  APELIDO: 4,
  CELULAR: 5,
  TELEFONE: 6,
  GENERO: 7,
  DATANASCIMENTO: 8,
  RGINSCRICAOESTADUAL: 9,
  ATIVO: 10,
  EMAIL: 11,
  EMAILNFE: 12,
  CATEGORIA: 13,
  SITEPERFIL: 14,
  CEP: 15,
  BAIRRO: 16,
  CIDADEUF: 17,
  ENDERECO: 18,
  OBSERVACAO: 19,

  properties: [
    {
      label: 'Código',
      value: 1,
      isChecked: false,
    },
    {
      label: 'Nome',
      value: 2,
      isChecked: false,
    },
    {
      label: 'CPF/CNPJ',
      value: 3,
      isChecked: false,
    },
    {
      label: 'Apelido',
      value: 4,
      isChecked: false,
    },
    {
      label: 'Celular',
      value: 5,
      isChecked: false,
    },
    {
      label: 'Telefone',
      value: 6,
      isChecked: false,
    },
    {
      label: 'Gênero',
      value: 7,
      isChecked: false,
    },
    {
      label: 'Data de nascimento',
      value: 8,
      isChecked: false,
    },
    {
      label: 'RG/Inscrição estadual',
      value: 9,
      isChecked: false,
    },
    {
      label: 'Ativo',
      value: 10,
      isChecked: false,
    },
    {
      label: 'Email',
      value: 11,
      isChecked: false,
    },
    {
      label: 'Email NFE',
      value: 12,
      isChecked: false,
    },
    {
      label: 'Categoria',
      value: 13,
      isChecked: false,
    },
    {
      label: 'Site perfil',
      value: 14,
      isChecked: false,
    },
    {
      label: 'CEP',
      value: 15,
      isChecked: false,
    },
    {
      label: 'Bairro',
      value: 16,
      isChecked: false,
    },
    {
      label: 'Cidade UF',
      value: 17,
      isChecked: false,
    },
    {
      label: 'Endereço',
      value: 18,
      isChecked: false,
    },
    {
      label: 'Observação',
      value: 19,
      isChecked: false,
    },
  ],
};
