import React from 'react';
import { Quill } from 'react-quill';

const Size = Quill.import('formats/size');
Size.whitelist = ['extra-small', 'small', 'medium', 'large'];
Quill.register(Size, true);

const Font = Quill.import('formats/font');
Font.whitelist = [
  'arial',
  'comic-sans',
  'courier-new',
  'georgia',
  'helvetica',
  'lucida',
];
Quill.register(Font, true);

export const modules = {
  toolbar: {
    container: '#toolbar',
  },
};

export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'script',
  'blockquote',
  'background',
  'list',
  'bullet',
  'indent',
  'image',
  'color',
  'code-block',
];

export const QuillToolbar = () => (
  <div id="toolbar">
    <span className="ql-formats">
      <select className="ql-font" defaultValue="arial">
        <option value="arial">Arial</option>
        <option value="comic-sans">Comic Sans</option>
        <option value="courier-new">Courier New</option>
        <option value="georgia">Georgia</option>
        <option value="helvetica">Helvetica</option>
        <option value="lucida">Lucida</option>
      </select>
      <select className="ql-size" defaultValue="medium">
        <option value="small">Extra pequeno</option>
        <option value="extra-small">Pequeno</option>
        <option value="medium">Médio</option>
        <option value="large">Grande</option>
      </select>
      <select className="ql-header" defaultValue="3">
        <option value="1">Cabeçalho</option>
        <option value="2">Subtítulo</option>
        <option value="3">Normal</option>
      </select>
    </span>
    <span className="ql-formats">
      <button type="button" className="ql-bold" aria-label="Negrito" />
      <button type="button" className="ql-italic" aria-label="Itálico" />
      <button type="button" className="ql-underline" aria-label="Sublinhado" />
      <button type="button" className="ql-strike" aria-label="Tachado" />
    </span>
    <span className="ql-formats">
      <button
        type="button"
        className="ql-list"
        value="ordered"
        aria-label="Lista ordenada"
      />
      <button
        type="button"
        className="ql-list"
        value="bullet"
        aria-label="Lista com marcadores"
      />
      <button
        type="button"
        className="ql-indent"
        value="-1"
        aria-label="Diminuir recuo"
      />
      <button
        type="button"
        className="ql-indent"
        value="+1"
        aria-label="Aumentar recuo"
      />
    </span>

    <span className="ql-formats">
      <select className="ql-align" aria-label="Alinhamento" />
      <select className="ql-color" aria-label="Cor do texto" />
      <select className="ql-background" aria-label="Cor de fundo" />
    </span>
  </div>
);

export default QuillToolbar;
