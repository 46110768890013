import { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useMediaQuery } from '@chakra-ui/react';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import PlanoContratacaoEnum from 'constants/enum/planoContratacao';
import auth from 'modules/auth';
import { useZoopEtapasContext } from 'store/Zoop/ZoopEtapasContext';
import { enumEtapasZoop } from 'constants/enum/enumEtapasZoop';

import {
  Option,
  BankAccountInfo,
  Bank,
  BankAccountList,
  ResponsePostBankAccount,
  yupResolver,
} from './validationForms';

const useBankAccount = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [listBankAccounts, setListBankAccounts] = useState<Option[]>([]);
  const [listBank, setListBank] = useState<Option[]>([]);

  const { setActiveStep } = useZoopEtapasContext();

  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

  const formMethods = useForm<BankAccountInfo>({
    resolver: yupResolver,
  });
  const { watch, setValue, handleSubmit } = formMethods;
  const selectedBankAccountId = watch('nomeConta');

  const isPlanoStart = auth.getPlano() === PlanoContratacaoEnum.START;

  const handleWarnings = useCallback((avisos: string[]) => {
    if (avisos) {
      avisos.forEach((aviso) => toast.warning(aviso));
    }
  }, []);

  const fetchBancoList = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get<void, ResponseApi<Bank[]>>(
      ConstanteEnderecoWebservice.BANCO_LISTAR
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        const bankList = response.dados.map((bank) => ({
          label: bank.nome,
          value: bank.codigo,
        }));
        setIsLoading(false);
        return bankList;
      }
    }
    setIsLoading(false);
    return [];
  }, []);

  const fetchBankAccountInfo = useCallback(
    async (id: string) => {
      setIsLoading(true);

      const response = await api.get<void, ResponseApi<BankAccountInfo>>(
        ConstanteEnderecoWebservice.CONTA_FINANCEIRA_OBTER,
        { params: { id } }
      );

      if (response) {
        if (response.avisos) handleWarnings(response.avisos);

        if (response.sucesso && response.dados) {
          const {
            agencia,
            nomeBanco,
            numeroConta,
            codigoBanco,
          } = response.dados;

          setIsLoading(false);

          return { agencia, nomeBanco, numeroConta, codigoBanco };
        }
      }
      setIsLoading(false);
      return { agencia: '', nomeBanco: '', numeroConta: '', codigoBanco: '' };
    },
    [handleWarnings]
  );

  const fetchListBankAccounts = useCallback(async () => {
    const { id: idLojaAtual } = auth.getLoja();
    setIsLoading(true);

    const response = await api.get<void, ResponseApi<BankAccountList[]>>(
      ConstanteEnderecoWebservice.CONTA_FINANCEIRA_LISTAR_SELECT_CONTA_BANCOS,
      {
        params: { lojaId: idLojaAtual },
      }
    );

    if (response) {
      if (response.avisos) handleWarnings(response.avisos);

      if (response.sucesso && response.dados) {
        const bankAccountsList = response.dados?.map(
          (account: BankAccountList) => ({
            value: account.id,
            label: account.nome,
          })
        );
        setIsLoading(false);
        return bankAccountsList;
      }
    }
    setIsLoading(false);
    return [];
  }, [handleWarnings]);

  const postBankAccount = useCallback(
    async (data: BankAccountInfo) => {
      setIsLoading(true);

      const requestData = {
        bank_code: data.codigoBanco,
        routing_number: data.agencia,
        account_number: data.numeroConta,
      };

      const response = await api.post<
        BankAccountInfo,
        ResponseApi<ResponsePostBankAccount>
      >(ConstanteEnderecoWebservice.CREDENCIAMENTO_CONTA_BANCARIA, {
        ...requestData,
      });

      if (response) {
        if (response.avisos) handleWarnings(response.avisos);

        if (response.sucesso) {
          setIsLoading(false);
          return response.sucesso;
        }
      }
      setIsLoading(false);

      return false;
    },
    [handleWarnings]
  );

  const getApisData = useCallback(async () => {
    const bankList = await fetchBancoList();
    setListBank(bankList);
    if (!isPlanoStart) {
      const bankAccountsList = await fetchListBankAccounts();
      setListBankAccounts(bankAccountsList);
    }
  }, [fetchBancoList, fetchListBankAccounts, isPlanoStart]);

  const updateBankAccountInfo = useCallback(
    async (bankAccountId) => {
      if (bankAccountId) {
        const data = await fetchBankAccountInfo(bankAccountId);
        if (data) {
          setValue('nomeBanco', data.nomeBanco);
          setValue('agencia', data.agencia);
          setValue('numeroConta', data.numeroConta);
          setValue('codigoBanco', data.codigoBanco);
        }
      }
    },
    [fetchBankAccountInfo, setValue]
  );

  const handleEnviar = handleSubmit(async (data) => {
    setIsLoading(true);
    const credentialInfo = await postBankAccount(data);
    if (!credentialInfo) {
      setIsLoading(false);
      return;
    }

    setActiveStep(enumEtapasZoop.CREDENCIAMENTO_PENDENTE);

    setIsLoading(false);
  });

  const handlePreencherDepois = useCallback(() => {
    setActiveStep(enumEtapasZoop.CREDENCIAMENTO_PENDENTE);
  }, [setActiveStep]);

  useEffect(() => {
    getApisData();
  }, [getApisData]);

  return {
    isLoading,
    listBankAccounts,
    listBank,
    selectedBankAccountId,
    updateBankAccountInfo,
    formMethods,
    handleEnviar,
    handlePreencherDepois,
    isLargerThan700,
    isPlanoStart,
  };
};

export default useBankAccount;
