import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export interface TabelaPrecoItensProps {
  produtoCorTamanhoId: string;
  produtoId?: string;
  produtoNome: string;
  corDescricao?: string;
  tamanhoDescricao?: string;
  precoVenda: number;
  precoCusto: number;
  markup: number;
}

type CorProps = {
  ativo: boolean;
  descricao: string;
  id: string;
};

type TamanhoProps = {
  ativo: boolean;
  descricao: string;
  id: string;
  padraoSistema: boolean;
  sequenciaOrdenacao: number;
};
export interface TabelaPrecoResponseProps {
  produtoCorTamanhoId: string;
  nome: string;
  cor?: CorProps;
  tamanho: TamanhoProps;
  tamanhoDescricao?: string;
  precoVenda: number;
  precoCusto: number;
}

export type FormData = {
  nome: string;
  ativo: boolean;
  tipoCalculo: number;
  percentual: number;
  padronizarPreco: boolean;
  arredondamentoAcima: number;
  arredondamentoAbaixo: number;
  tabelaPrecoProdutos: TabelaPrecoItensProps[];
  tabelaPrecoProdutoCorTamanhos: TabelaPrecoItensProps[];
  lojasSelecionadas: string[];
  padraoSistema: boolean;
};

const schema = yup.object().shape({
  nome: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .max(20, ConstanteMensagemValidacao.MAX_LENGTH_20),
  ativo: yup.boolean().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  tipoCalculo: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  percentual: yup.number().nullable(),
  padronizarPreco: yup
    .boolean()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  arredondamentoAcima: yup.number().nullable(),
  arredondamentoAbaixo: yup.number().nullable(),
  tabelaPrecoProdutoCorTamanhos: yup.array().of(
    yup.object().shape({
      produtoCorTamanhoId: yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
      precoVenda: yup
        .number()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
    })
  ),
  lojasSelecionadas: yup.array().of(yup.string()),
});

export const yupResolver = yupResolverInstance(schema);
