import {
  Text,
  TabPanel,
  TabPanels,
  TabList,
  Tabs,
  Icon,
  Flex,
  Box,
  Button,
  Collapse,
} from '@chakra-ui/react';
import { FiChevronDown, FiChevronLeft, FiChevronUp } from 'react-icons/fi';

import { SalvarInserirNovoIcon } from 'icons';
import { ContainerHeader } from 'pages/Integracoes/LojaAplicativos/Layout/ContainerHeader';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { HeaderTab } from './components/HeaderTab';
import { TabDadosCadastro } from './components/TabDadosCadastro';
import { TabProdutosFavoritos } from './components/TabProdutosFavoritos';
import { useDepartamentoForm } from './hooks';

type UncontrolledFormProps = Partial<{
  handleSave: () => Promise<void>;
  handleSaveAndReset: () => Promise<void>;
  isLoading: boolean;
}>;

const UncontrolledForm = ({
  handleSave,
  handleSaveAndReset,
  isLoading,
}: UncontrolledFormProps) => {
  const {
    isLargerThan900,
    selectedTab,
    mobileSelectedTab,
    handleSelectedTab,
    handleMobileSelectedTab,
    handleToDepartments,
  } = useDepartamentoForm();

  const tabs = [
    { label: 'Dados do cadastro', content: <TabDadosCadastro /> },
    { label: 'Produtos favoritos', content: <TabProdutosFavoritos /> },
  ];

  return (
    <>
      <ContainerHeader bg="#FF005A" gap={2} color="white" alignItems="center">
        <Box cursor="pointer" mr="10px" onClick={handleToDepartments}>
          <Icon fontSize="25px" as={FiChevronLeft} />
        </Box>
        <Text fontSize="16px" fontWeight="semibold">
          Departamentos
        </Text>
      </ContainerHeader>
      <Box position="relative" p={6}>
        {isLoading && <LoadingPadrao />}
        {isLargerThan900 ? (
          <Tabs
            variant="clean"
            h="max"
            bg="white"
            borderRadius="base"
            boxShadow="0px 0px 6px #00000034"
            defaultIndex={selectedTab}
            onChange={(index) => handleSelectedTab(index)}
          >
            <Flex h="full">
              <TabList mt="16px" w="32%">
                <Box w="100%">
                  {tabs.map((tab, index) => {
                    const isSelectedTab = selectedTab === index;
                    return (
                      <HeaderTab
                        title={tab.label}
                        isSelectedTab={isSelectedTab}
                      />
                    );
                  })}
                </Box>
              </TabList>
              <TabPanels
                w="full"
                px={10}
                py={6}
                bg="gray.100"
                borderRadius="0 5px 0 0"
                minH="400px"
              >
                {tabs.map((tab) => (
                  <TabPanel w="full" bg="gray.100" p={0}>
                    {tab.content}
                  </TabPanel>
                ))}
              </TabPanels>
            </Flex>
          </Tabs>
        ) : (
          <Box
            py={4}
            bg="white"
            borderRadius="base"
            boxShadow="0px 0px 6px #00000034"
          >
            {tabs.map((tab, index) => {
              const isSelectedTab = mobileSelectedTab === index;
              return (
                <Box pb={isSelectedTab ? 5 : 0} bg="gray.50">
                  <Flex
                    w="full"
                    py={6}
                    px={4}
                    bg={isSelectedTab ? 'gray.50' : 'white'}
                    cursor="pointer"
                    alignItems="center"
                    justifyContent="space-between"
                    onClick={() => handleMobileSelectedTab(index)}
                  >
                    <Text fontSize="16px">{tab.label}</Text>
                    <Icon
                      fontSize="20px"
                      as={isSelectedTab ? FiChevronUp : FiChevronDown}
                    />
                  </Flex>
                  <Collapse in={isSelectedTab} animateOpacity>
                    <Box overflowX="auto" p={4}>
                      {tab.content}
                    </Box>
                  </Collapse>
                </Box>
              );
            })}
          </Box>
        )}
        <Flex p="40px" gap={6} align="center" justify="center" flexWrap="wrap">
          {handleSaveAndReset && (
            <Button
              w={{ base: 'full', sm: 'min-content' }}
              colorScheme="gray"
              variant="outlineDefault"
              leftIcon={<Icon as={SalvarInserirNovoIcon} fontSize="lg" />}
              onClick={handleSaveAndReset}
            >
              Salvar e inserir novo
            </Button>
          )}
          {handleSave && (
            <Button
              w={{ base: 'full', sm: 'min-content' }}
              minW="225px"
              colorScheme="secondary"
              onClick={handleSave}
            >
              Salvar
            </Button>
          )}
        </Flex>
      </Box>
    </>
  );
};

export { UncontrolledForm };
