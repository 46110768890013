export const enumFormaPagamentoZoop = [
  {
    label: 'Todos',
    value: null,
  },
  {
    label: 'Boleto',
    value: 2,
  },
  {
    label: 'Pix',
    value: 3,
  },
];
