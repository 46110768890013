import { useCallback, useState, useRef } from 'react';
import {
  Flex,
  Box,
  Button,
  Table,
  Thead,
  Tr,
  Th,
  Text,
  useMediaQuery,
  Icon,
  FormLabel,
  Tooltip,
} from '@chakra-ui/react';
import { RiShareLine } from 'react-icons/ri';
import jsFileDownload from 'js-file-download';
import { useForm, FormProvider } from 'react-hook-form';
import { AutoSizer, List, ListRowProps } from 'react-virtualized';
import { toast } from 'react-toastify';
import { FcInfo } from 'react-icons/fc';

import { ImprimirPDF } from 'helpers/impressoes/imprimirPDF';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { TipoRelatorioCurvaABC, CurvaABCEnum } from 'constants/enum/curvaABC';

import { RelatoriosIcon } from 'icons';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import { ModalCompartilhar } from 'components/Modal/ModalCompartilhar';
import InputDateRange from 'components/PDV/InputDateRange';
import { EmailModal } from 'components/PDV/CompartilharDocumentosFiscais/EmailModal';
import Loading from 'components/Layout/Loading/LoadingPadrao';
import { linhaProdutosCurvaAbc } from './components/ListarProdutosCurvaAbc';
import { TableHeaderFunction } from './components/tableHeader';

import {
  FilterProps,
  FormData,
  ListaCurvaABCProps,
  RegistrosCurvaABCProps,
  ThreeMonthsAgo,
  firstDayOfMonth,
  today,
} from './types';

const tipoProductOptions = CurvaABCEnum.properties
  .filter((option) => option.value < 4)
  .map((option) => ({
    label: option.name,
    value: option.value,
  }));

const tipoClientOptions = CurvaABCEnum.properties
  .filter((option) => option.value >= 4)
  .map((option) => ({
    label: option.name,
    value: option.value,
  }));

const orderOptions = TipoRelatorioCurvaABC.properties.map((option) => ({
  label: option.name,
  value: option.value,
}));

export function RelatorioCurvaABC() {
  const [isSmallerThan1500] = useMediaQuery('(max-width: 1500px)');
  const [isSmallerThan1300] = useMediaQuery('(max-width: 1300px)');
  const [isSmallerThan1000] = useMediaQuery('(max-width: 1000px)');

  const [listaCurvaABC, setListaCurvaABC] = useState<RegistrosCurvaABCProps[]>(
    []
  );

  const currentFilters = useRef<FilterProps>({} as FilterProps);
  const ultimoValorDoTipoDeRelatorio = useRef<number>(0);
  const estruturaTabela = useRef({
    width: 1100,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    ordenacao: campoOrdenacao,
    tipoDeRelatorio: tipoRelatorio,
  } = currentFilters.current;

  const formMethods = useForm<FormData>({
    defaultValues: {
      endDate: today,
      startDate: firstDayOfMonth,
      tipoDeRelatorio: 0,
      ordenacao: 0,
    },
  });
  const { watch } = formMethods;

  const tipoDeRelatorioSelecionado = watch('tipoDeRelatorio');

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    if (isSmallerThan1300) {
      const storageValue = localStorage.getItem('navMenuIsOpen');
      const toggleMenuLateral = document.getElementById('menuPrincipalBtn');

      if (storageValue) {
        const menuIsOpen = JSON.parse(storageValue);
        if (menuIsOpen && toggleMenuLateral) toggleMenuLateral.click();
      }
    }
    setIsLoading(true);
    currentFilters.current = data;

    const { startDate, endDate, ordenacao, tipoDeRelatorio } = data;
    // estrutura da tabela
    if (
      tipoDeRelatorio === TipoRelatorioCurvaABC.PRODUTO &&
      isSmallerThan1000
    ) {
      estruturaTabela.current.width = 1100;
    }

    if (
      tipoDeRelatorio === TipoRelatorioCurvaABC.CLIENTE &&
      estruturaTabela.current.width > 930
    ) {
      estruturaTabela.current.width = 930;
    }

    const response = await api.get<void, ResponseApi<ListaCurvaABCProps>>(
      ConstanteEnderecoWebservice.OBTER_RELATORIO_VENDAS_CURVA_ABC,
      {
        params: {
          periodoVendasInicio: startDate.toISOString(),
          PeriodoVendasFim: endDate.toISOString(),
          Agregador: tipoDeRelatorio,
          ordenacao,
        },
      }
    );

    if (response.avisos) {
      response.avisos.map((aviso) => toast.warning(aviso));
    }

    if (response.dados && response.sucesso) {
      const { registros } = response.dados;
      setListaCurvaABC(registros);
    }

    if (!response.dados && response.sucesso) {
      setListaCurvaABC([]);
    }
    setIsLoading(false);
  });

  if (ultimoValorDoTipoDeRelatorio.current !== tipoDeRelatorioSelecionado) {
    const tipoOrdenacao = tipoDeRelatorioSelecionado === 0 ? 0 : 4;
    formMethods.setValue('ordenacao', tipoOrdenacao);
    ultimoValorDoTipoDeRelatorio.current = tipoDeRelatorioSelecionado;
  }

  const compartilhar = useCallback(async () => {
    const {
      endDate,
      startDate,
      ordenacao,
      tipoDeRelatorio,
    } = formMethods.getValues();

    const response = await api.get<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.IMPRESSAO_RELATORIO_CURVA_ABC,
      {
        params: {
          periodoVendasInicio: startDate,
          PeriodoVendasFim: endDate,
          Agregador: tipoDeRelatorio,
          ordenacao,
        },
      }
    );

    if (response.avisos) {
      response.avisos.forEach((avisos) => toast.warn(avisos));
    }

    if (response.dados && response.dados) {
      ImprimirPDF(response.dados, 'relatorioCurvaABC');
    }
  }, [formMethods]);

  const compartilharEmail = useCallback(async () => {
    const {
      endDate,
      startDate,
      ordenacao,
      tipoDeRelatorio: agregador,
    } = formMethods.getValues();

    EmailModal({
      curvaABC: {
        agregador,
        ordenacao: ordenacao || 0,
        periodoVendasFim: endDate?.toISOString(),
        periodoVendasInicio: startDate?.toDateString(),
      },
    });
  }, [formMethods]);

  const handleExportarAquivoCSV = useCallback(async () => {
    const {
      endDate,
      startDate,
      ordenacao,
      tipoDeRelatorio: agregador,
    } = formMethods.getValues();

    const response = await api.get<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.RELATORIO_CURVA_ABC_EXPORTAR_CSV,
      {
        params: {
          periodoVendasInicio: startDate,
          PeriodoVendasFim: endDate,
          ordenacao,
          agregador,
        },
      }
    );

    if (response.dados === null) {
      toast.warn('Nenhum resultado foi encontrado');
      return;
    }

    if (response.dados) {
      jsFileDownload(
        Uint8Array.from(atob(response.dados), (c) => c.charCodeAt(0)),
        'Relatorio curvaABC.csv'
      );
    }
  }, [formMethods]);

  const handleCompartilhar = useCallback(async () => {
    ModalCompartilhar({
      items: [
        {
          titulo: 'Relatório Curva ABC',
          iconDownload: RelatoriosIcon,
          onClickImpressao: (onClose) => {
            compartilhar();
            onClose();
          },
          onClickEmail: (onClose) => {
            compartilharEmail();
            onClose();
          },
          onClickDownload: (onClose) => {
            handleExportarAquivoCSV();
            onClose();
          },
        },
      ],
    });
  }, [compartilhar, compartilharEmail, handleExportarAquivoCSV]);

  const TableHeader = TableHeaderFunction({
    ordenacao: campoOrdenacao || 0,
    tipoDeRelatorio: tipoRelatorio || 0,
    isResponsiveVersion: isSmallerThan1500,
  });

  function ListarProdutosCurvaAbc({ index, style, key }: ListRowProps) {
    const data = listaCurvaABC[index];
    return linhaProdutosCurvaAbc({
      ...data,
      type: tipoRelatorio,
      ordenacao: campoOrdenacao || 0,
      key,
      style,
      isSmallerThan1500,
    });
  }

  return (
    <Flex flexDirection="column" gap={{ base: '2', md: '3' }}>
      {isLoading && <Loading />}
      <FormProvider {...formMethods}>
        <Flex
          flexDirection={{ base: 'column', sm: 'column', md: 'row' }}
          flexWrap="wrap"
          columnGap={{ base: '4', sm: '4', md: '8' }}
          rowGap={{ base: '6', md: '2' }}
        >
          <Box flexBasis={{ md: '315px' }}>
            <FormLabel
              htmlFor="dataDePesquisa"
              m="0"
              fontSize="14px"
              lineHeight="1.3"
            >
              Período
            </FormLabel>
            <InputDateRange
              endDateName="endDate"
              startDateName="startDate"
              id="dataDePesquisa"
              maxDate={today}
              minDate={ThreeMonthsAgo}
              onConfirm={handleSubmit}
            />
          </Box>
          <Box w={{ base: 'full', md: '275px' }}>
            <SelectPadrao
              placeholder="Selecione um tipo"
              label="Tipo de relatório"
              id="tipoDeRelatorio"
              name="tipoDeRelatorio"
              options={orderOptions}
            />
          </Box>
          <Box w={{ base: 'full', md: '315px' }}>
            <SelectPadrao
              placeholder="Selecione o tipo de ordenação"
              label="Tipo de ordenação"
              id="ordenacao"
              name="ordenacao"
              options={
                tipoDeRelatorioSelecionado === 0
                  ? tipoProductOptions
                  : tipoClientOptions
              }
            />
          </Box>
          <Box pt={{ base: '0', md: '18px' }}>
            <Button
              w={{ base: 'full', md: '210px' }}
              colorScheme="secondary"
              borderRadius="md"
              variant="solid"
              onClick={handleSubmit}
            >
              Pesquisar
            </Button>
          </Box>
          <Box pt={{ base: '0', md: '18px' }}>
            <Tooltip
              shouldWrapChildren
              hasArrow
              label="Não existem arquivos a serem compartilhados"
              gutter={20}
              placement="bottom"
              w="44"
              isDisabled={listaCurvaABC.length > 0}
              borderRadius="md"
              padding="2"
              textAlign="center"
            >
              <Button
                variant="outline"
                bg="white"
                width={{ base: 'full', md: '210px' }}
                borderRadius="md"
                border="1px solid"
                borderColor="gray.100"
                color="gray.700"
                _active={{
                  backgroundColor: 'inherit',
                }}
                onClick={handleCompartilhar}
                isDisabled={listaCurvaABC.length === 0}
              >
                <Icon mr="20px" as={RiShareLine} fontSize="md" />
                Compartilhar
              </Button>
            </Tooltip>
          </Box>
          <Box
            flex="1"
            flexBasis="100%"
            display="flex"
            alignItems="center"
            mt="2"
            ml="2"
          >
            <Icon
              as={FcInfo}
              size="lg"
              h={6}
              w={6}
              alignSelf="flex-start"
              transform={{ base: 'translateY(3px)', md: 'none' }}
            />
            <Text
              ml="2"
              as="span"
              fontWeight="normal"
              transform="translateY(2px)"
            >
              Os valores de frete e outras despesas não são considerados no
              total da venda
            </Text>
          </Box>
        </Flex>
        <Box
          maxH="90dvh"
          h={`calc(${listaCurvaABC.length} * 60px + 42px)`} // 42px é o tamanho do header da tabela e 60px é o tamanho de cada linha
          overflowY={listaCurvaABC.length > 10 ? 'scroll' : 'hidden'}
          overflowX={!isSmallerThan1300 ? 'hidden' : 'auto'}
          w="full"
          maxW="100%"
        >
          <Box
            h="full"
            overflow="hidden"
            w={!isSmallerThan1300 ? 'full' : '1300px'}
            pr={listaCurvaABC.length > 10 ? '6px' : '0'}
          >
            <Table
              variant="simple-card"
              size="sm"
              boxShadow="none"
              sx={{
                borderCollapse: 'separate',
                borderSpacing: '0 0.5rem',
                paddingRight: listaCurvaABC.length > 10 ? '10px' : '0',
              }}
            >
              <Thead>
                <Tr
                  sx={{
                    '& > th': {
                      borderBottom: 'none',
                      whiteSpace: 'nowrap',
                    },
                  }}
                >
                  {TableHeader.map(({ content, key, ...props }) => {
                    if (!content) return null;
                    return (
                      <Th key={key} {...props}>
                        {content}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
            </Table>
            <AutoSizer>
              {({ height, width }) => {
                return (
                  <List
                    className="virtualized_List"
                    height={height}
                    style={{
                      maxHeight: 'calc(88vh - 32.8px)',
                      paddingRight: listaCurvaABC.length > 10 ? '4px' : '0',
                      overflowY: 'auto',
                    }}
                    noRowsRenderer={() => (
                      <Box padding="6" bg="white" borderRadius="md">
                        <Text pl="2" color="gray.700" fontWeight="normal">
                          Nenhum resultado foi encontrado
                        </Text>
                      </Box>
                    )}
                    width={width}
                    rowCount={listaCurvaABC.length}
                    overscanRowCount={10}
                    rowHeight={60}
                    rowRenderer={ListarProdutosCurvaAbc}
                  />
                );
              }}
            </AutoSizer>
          </Box>
        </Box>
      </FormProvider>
    </Flex>
  );
}
