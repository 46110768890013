/* eslint-disable jsx-a11y/alt-text */
import { Divider, Flex, Grid } from '@chakra-ui/react';
import { cnpjMask } from 'helpers/format/fieldsMasks';
import React from 'react';
import { EmitenteInterface } from 'types/ImpressaoNFCe';

interface ImpressaoInterface {
  emitente: EmitenteInterface;
  logo: string;
}

const Cabecalho = ({ emitente, logo }: ImpressaoInterface) => {
  const possuiLogo = !!logo;
  const alinhamentoTexto = possuiLogo ? 'left' : 'center';

  return (
    <Grid templateRows="auto 10px auto">
      <Grid templateColumns={possuiLogo ? '51px auto' : 'auto'} width="100%">
        {possuiLogo && (
          <Flex pr="4px" alignItems="center">
            <img width="51px" height="51px" src={logo} />
          </Flex>
        )}
        <Grid>
          <Flex justifyContent={alinhamentoTexto}>{emitente.fantasia}</Flex>
          <Flex justifyContent={alinhamentoTexto}>
            CNPJ - {emitente.cnpj && cnpjMask(emitente.cnpj)}
          </Flex>
          <Flex justifyContent={alinhamentoTexto} textAlign={alinhamentoTexto}>
            {emitente.enderecoEmitente.logradouro},{' '}
            {emitente.enderecoEmitente.numero},{' '}
            {emitente.enderecoEmitente.bairro},{' '}
            {emitente.enderecoEmitente.nomeMunicipio}
            {' - '}
            {emitente.enderecoEmitente.siglaUF}
          </Flex>
        </Grid>
      </Grid>

      <Divider margin="3px 0px" />

      <Flex className="textCenter" fontWeight="bold">
        DOCUMENTO AUXILIAR DA NOTA FISCAL DE CONSUMIDOR ELETRÔNICA
      </Flex>
    </Grid>
  );
};

export default Cabecalho;
