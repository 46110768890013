import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  Slider,
  ModalFooter,
  Box,
  ModalHeader,
  SliderThumb,
  SliderFilledTrack,
  useMediaQuery,
  SliderTrack,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import Cropper, { Area } from 'react-easy-crop';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';

type ModalRedimensionarResponse = {
  imagemRedimensionada: string;
};

type ModalRedimensionarImagemProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalRedimensionarResponse> & {
    valueImage: string;
    maxWidthImage?: number;
    maxHeightImage?: number;
    widthImage?: number;
    heightImage?: number;
    isImagemRedimensionada?: boolean;
  };

export const ModalRedimensionarImagem = create<
  ModalRedimensionarImagemProps,
  ModalRedimensionarResponse
>(
  ({
    valueImage,
    onResolve,
    widthImage = 0,
    isImagemRedimensionada = false,
    heightImage = 0,
    maxWidthImage = 1200,
    maxHeightImage = 1200,
    onReject,
    ...rest
  }) => {
    const [urlImage, setUrlImage] = useState('');
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>(
      {} as Area
    );

    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    const drawImageWithBorder = useCallback(() => {
      return new Promise((resolve) => {
        const borderWidth = 200; // Largura da borda nas laterais

        const canvas = document.createElement('canvas');
        canvas.width = widthImage + 2 * borderWidth; // Adiciona a largura das bordas nas laterais
        canvas.height = heightImage + 2 * borderWidth; // Adiciona a largura das bordas nas laterais

        const ctx = canvas.getContext('2d');
        if (ctx === null) {
          return;
        }

        const img = new Image();
        img.crossOrigin = 'anonymous'; // Habilitar o uso de imagens de outras origens
        img.src = valueImage;

        img.onload = () => {
          const imgWidth = img.width;
          const imgHeight = img.height;
          const imgAspectRatio = imgWidth / imgHeight;

          let renderWidth = widthImage;
          let renderHeight = heightImage;

          if (imgAspectRatio > 1) {
            renderWidth = widthImage;
            renderHeight = widthImage / imgAspectRatio;
          } else {
            renderWidth = heightImage * imgAspectRatio;
            renderHeight = heightImage;
          }

          // Calcula as coordenadas para desenhar a imagem com as bordas
          const imageX = borderWidth + (widthImage - renderWidth) / 2;
          const imageY = borderWidth + (heightImage - renderHeight) / 2;

          ctx.fillStyle = 'white';
          ctx.fillRect(0, 0, canvas.width, canvas.height); // Fundo branco
          ctx.drawImage(img, imageX, imageY, renderWidth, renderHeight); // Imagem

          // Bordas
          ctx.fillStyle = 'transparent'; // Define a cor transparente para a borda
          ctx.fillRect(0, 0, canvas.width, borderWidth); // Topo
          ctx.fillRect(
            0,
            canvas.height - borderWidth,
            canvas.width,
            borderWidth
          ); // Inferior
          ctx.fillRect(0, 0, borderWidth, canvas.height); // Esquerda
          ctx.fillRect(
            canvas.width - borderWidth,
            0,
            borderWidth,
            canvas.height
          ); // Direita

          resolve(canvas.toDataURL());
        };
      });
    }, [heightImage, valueImage, widthImage]);

    const onCropComplete = useCallback((_, areaPixel: Area) => {
      setCroppedAreaPixels(areaPixel);
    }, []);

    function getRadianAngle() {
      return (0 * Math.PI) / 180;
    }

    function rotateSize(width: number, height: number) {
      const rotRad = getRadianAngle();

      return {
        width:
          Math.abs(Math.cos(rotRad) * width) +
          Math.abs(Math.sin(rotRad) * height),
        height:
          Math.abs(Math.sin(rotRad) * width) +
          Math.abs(Math.cos(rotRad) * height),
      };
    }

    // Essa função cria uma nova imagem a partir do redirecionamento que o usuário escolheu
    async function getCroppedImg(
      imageSrc: string,
      pixelCrop: Area,
      flip = { horizontal: false, vertical: false }
    ) {
      const image = await new Image();
      image.src = imageSrc;

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        return null;
      }

      const rotRad = getRadianAngle();

      const { width: imageWidth, height: imageHeight } = rotateSize(
        image.width,
        image.height
      );

      canvas.width = imageWidth;
      canvas.height = imageHeight;

      ctx.translate(imageWidth / 2, imageHeight / 2);
      ctx.rotate(rotRad);
      ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
      ctx.translate(-image.width / 2, -image.height / 2);

      ctx.drawImage(image, 0, 0);

      const croppedCanvas = document.createElement('canvas');

      const croppedCtx = croppedCanvas.getContext('2d');

      if (!croppedCtx) {
        return null;
      }

      croppedCanvas.width = pixelCrop.width;
      croppedCanvas.height = pixelCrop.height;

      croppedCtx.drawImage(
        canvas,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
      );
      const cropImage = croppedCanvas.toDataURL('image/png');

      return cropImage;
    }

    // Essa função pega a imagem redirecionada e converte o seu tamanho, para o padrão em que ela for usada
    const cropImage = async (newImage: string) => {
      const img = new Image();
      img.src = newImage;
      img.onload = () => {
        const maxWidth = maxWidthImage;
        const maxHeight = maxHeightImage;

        let newWidth = img.width;
        let newHeight = img.height;

        if (img.width > maxWidth) {
          newWidth = maxWidth;
          newHeight = (maxWidth / img.width) * img.height;
        }

        if (newHeight > maxHeight) {
          newHeight = maxHeight;
          newWidth = (maxHeight / img.height) * img.width;
        }

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          return;
        }
        canvas.width = newWidth;
        canvas.height = newHeight;
        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        const finalImage = canvas.toDataURL('image/jpeg');

        onResolve({
          imagemRedimensionada: finalImage,
        });
        onClose();
      };
    };

    const handleCroppedImage = async () => {
      const newImage = await getCroppedImg(urlImage, croppedAreaPixels);

      if (newImage !== null) {
        await cropImage(newImage);
      } else {
        toast.warning(
          'Não foi possível redimensionar a imagem, por favor tente novamente'
        );
      }
    };

    useEffect(() => {
      const getUrlImagem = async () => {
        const image = (await drawImageWithBorder()) as string;

        setUrlImage(isImagemRedimensionada ? valueImage : image);
      };

      getUrlImagem();
    }, [drawImageWithBorder, isImagemRedimensionada, valueImage]);

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? '3xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
      >
        <ModalContent
          bg="gray.50"
          borderRadius={isLargerThan900 ? 'md' : '0'}
          marginBottom={isLargerThan900 ? '3.75rem' : '0'}
          marginTop={isLargerThan900 ? '3.75rem' : '0'}
          w={isLargerThan900 ? '640px' : 'full'}
          h={isLargerThan900 ? '388px' : 'full'}
        >
          <ModalHeader pt="16px" pb="0" pl="24px">
            <Text color="primary.50" fontSize="20px" pb="10px">
              Redimensionar a imagem
            </Text>
          </ModalHeader>

          <ModalBody pl="0" pr="0" pt="0" pb="0">
            <Box display="flex" bg="gray.50">
              <Box bg="black" h="250px" w="full">
                <Box bg="black" w="full" h="full" position="relative">
                  {urlImage && (
                    <Cropper
                      image={urlImage}
                      crop={crop}
                      zoom={zoom}
                      onCropComplete={onCropComplete}
                      aspect={4 / 4}
                      onCropChange={setCrop}
                      onZoomChange={setZoom}
                    />
                  )}
                  <Flex
                    right="0"
                    bg="black"
                    h="250px"
                    position="absolute"
                    w="10%"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Slider
                      mt="10px"
                      min={1}
                      max={3}
                      h="200px"
                      orientation="vertical"
                      step={0.1}
                      defaultValue={0}
                      value={zoom}
                      onChange={(e) => setZoom(Number(e))}
                    >
                      <SliderTrack bg="gray.300">
                        <SliderFilledTrack bg="gray.700" />
                      </SliderTrack>
                      <SliderThumb borderColor="gray.700" />
                    </Slider>
                  </Flex>
                </Box>
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter flexDirection="column" mb="10px">
            <Flex
              w="full"
              h="full"
              justifyContent="center"
              alignItems="baseline"
            >
              <Button
                color="white"
                variant="solid"
                colorScheme="aquamarine.500"
                lineHeight="0"
                borderRadius="20px"
                isDisabled={!urlImage}
                width="120px"
                onClick={() => {
                  handleCroppedImage();
                }}
                mr="10px"
              >
                Salvar
              </Button>
              <Button
                variant="outlineDefault"
                colorScheme="gray"
                lineHeight="0"
                borderRadius="20px"
                width="96px"
                onClick={onClose}
              >
                Cancelar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
