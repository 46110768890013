import React from 'react';
import { Route } from 'react-router-dom';

import MovimentacaoEstoqueFiltrosProvider from '../store/MovimentacaoEstoque/MovimentacaoEstoqueFiltros';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

import MovimentacaoEstoqueListar from '../pages/MovimentacaoEstoque/Listar';
import MovimentacaoEstoqueCadastrar from '../pages/MovimentacaoEstoque/Formulario/Cadastrar';
import MovimentacaoEstoqueAlterar from '../pages/MovimentacaoEstoque/Formulario/Alterar';
import MovimentacaoEstoqueVisualizar from '../pages/MovimentacaoEstoque/Formulario/Visualizar';

export const MovimentacaoEstoqueRoutes = () => (
  <Route path={ConstanteRotas.MOVIMENTACAO_ESTOQUE}>
    <MovimentacaoEstoqueFiltrosProvider>
      <LayoutGuard
        key="movimentacao-estoque-listar"
        permissaoFuncionalidade={
          ConstanteFuncionalidades.MOVIMENTACAO_ESTOQUE_LISTAR
        }
        component={MovimentacaoEstoqueListar}
        breadcrumb={[
          {
            title: 'Estoque',
          },
          {
            title: 'Movimentação de Estoque',
          },
        ]}
        path={ConstanteRotas.MOVIMENTACAO_ESTOQUE}
        exact
        meta={{ auth: true }}
      />
      <LayoutGuard
        key="movimentacao-estoque-cadastrar"
        component={MovimentacaoEstoqueCadastrar}
        breadcrumb={[
          {
            title: 'Estoque',
          },
          {
            title: 'Movimentação de Estoque',
            path: ConstanteRotas.MOVIMENTACAO_ESTOQUE,
          },
          {
            title: 'Novo',
          },
        ]}
        path="/movimentacao-estoque/cadastrar"
        exact
        meta={{ auth: true }}
      />
      <LayoutGuard
        key="movimentacao-estoque-alterar"
        component={MovimentacaoEstoqueAlterar}
        breadcrumb={[
          {
            title: 'Estoque',
          },
          {
            title: 'Movimentação de Estoque',
            path: ConstanteRotas.MOVIMENTACAO_ESTOQUE,
          },
          {
            title: 'Editar',
          },
        ]}
        path="/movimentacao-estoque/alterar/:id"
        exact
        meta={{ auth: true }}
      />
      <LayoutGuard
        key="movimentacao-estoque-visualizar"
        component={MovimentacaoEstoqueVisualizar}
        breadcrumb={[
          {
            title: 'Estoque',
          },
          {
            title: 'Movimentação de Estoque',
            path: ConstanteRotas.MOVIMENTACAO_ESTOQUE,
          },
          {
            title: 'Visualizar',
          },
        ]}
        path="/movimentacao-estoque/visualizar/:id"
        exact
        meta={{ auth: true }}
      />
    </MovimentacaoEstoqueFiltrosProvider>
  </Route>
);
