import { useState, useCallback, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import {
  Box,
  Button,
  Icon,
  Checkbox,
  Divider,
  Flex,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalProps,
  Text,
  useDisclosure,
  useMediaQuery,
  FormLabel,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm, UseFormSetValue } from 'react-hook-form';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { enumTipoDataImpressaoEtiqueta } from 'constants/enum/enumTipoDataImpressaoEtiqueta';
import { formatFullDate } from 'helpers/format/formatData';
import { setDateMaxHours, setDateMinHours } from 'helpers/data/setHoursDate';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';

import { PaginationData } from 'components/update/Pagination';
import { LupaIcon } from 'icons';
import { SearchInput } from 'components/update/Input/SearchInput';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import VirtualizedList from 'components/update/VirtualizedList';
import Input from 'components/PDV/Input';
import { TextValor } from 'components/PDV/Text/TextValor';
import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import {
  defaultValues,
  FormData,
  ListaProdutosModalProps,
  ProdutoProps,
  ListaProdutosImpressaoProps,
  ProdutosResponseProps,
  yupResolver,
} from './validationForm';

type ModalBuscarProdutoParaImpressaoProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalBuscarProdutoParaImpressaoProps> & {
    produtosJaAdicionadosImpressao: ListaProdutosImpressaoProps[];
    setListaProdutosImpressao: React.Dispatch<
      React.SetStateAction<ListaProdutosImpressaoProps[]>
    >;
    setValue: UseFormSetValue<ProdutoProps>;
    casasDecimaisValor: number;
    buscarPorNome?: boolean;
  };
export const ModalBuscarProdutoParaImpressao = create<ModalBuscarProdutoParaImpressaoProps>(
  ({
    setListaProdutosImpressao,
    produtosJaAdicionadosImpressao,
    setValue: parentSetValue,
    casasDecimaisValor,
    buscarPorNome,
    onResolve,
    onReject,
    ...rest
  }) => {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
    const [listaProdutosModal, setListaProdutosModal] = useState<
      ListaProdutosModalProps[]
    >([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selecionarTodosProdutos, setSelecionarTodosProdutos] = useState(
      true
    );
    const [totalRegistros, setTotalRegistros] = useState(0);

    const paginaAtual = useRef(1);
    const quantidadeProdutosBuscados = useRef(0);

    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
    const [isSmallerThan500] = useMediaQuery('(max-width: 500px)');

    const formMethods = useForm<FormData>({
      defaultValues,
      resolver: yupResolver,
    });

    const { reset, watch } = formMethods;

    const [dataBusca, tipoData] = watch(['dataBusca', 'tipoData']);
    const possuiProdutosNaListagem = (listaProdutosModal || []).length > 0;
    const produtosSelecionados = listaProdutosModal.filter(
      (produto) => produto.produtoSelecionado
    );

    const naoPossuiProdutosSelecionados =
      (produtosSelecionados || []).length < 1;

    const handleAdicionarProdutos = useCallback(() => {
      setIsLoading(true);

      const produtosPorId: { [id: string]: ListaProdutosModalProps } = {};

      [...produtosSelecionados, ...produtosJaAdicionadosImpressao].forEach(
        (produto) => {
          const { id, quantidade } = produto;
          const quantidadeProduto = Math.round(
            Math.max(1, Math.min(999, Number(quantidade)))
          );

          if (produtosPorId[id]) {
            const quantidadeAposSoma = Math.round(
              Math.max(
                1,
                Math.min(
                  999,
                  Number(produtosPorId[id].quantidade + quantidadeProduto)
                )
              )
            );
            produtosPorId[id].quantidade = quantidadeAposSoma;
          } else {
            produtosPorId[id] = {
              ...produto,
              padraoSistema: produto.padraoSistema,
              quantidade: quantidadeProduto,
            };
          }
        }
      );

      const newListaProdutos = Object.values(produtosPorId);

      parentSetValue(`quantidadePorProduto`, {});

      newListaProdutos.forEach(({ id, quantidade }) => {
        parentSetValue(`quantidadePorProduto.${id}`, Number(quantidade));
      });

      setListaProdutosImpressao(newListaProdutos);
      setListaProdutosModal([]);
      setIsLoading(false);
      onClose();
    }, [
      produtosSelecionados,
      produtosJaAdicionadosImpressao,
      parentSetValue,
      setListaProdutosImpressao,
      onClose,
    ]);

    const handleBuscarPorNome = useCallback(async () => {
      setIsLoading(true);
      const valuePaginationDefault = {
        currentPage: paginaAtual.current,
        orderColumn: '',
        orderDirection: 'asc',
        pageSize: 20,
      } as PaginationData;

      const inputValue = formMethods.watch('nomeSkuCodigoExternoBarrasGtinEan');
      if (!inputValue || inputValue.length < 3) {
        toast.warning(' Digite ao menos 3 caracteres para pesquisar');
        setIsLoading(false);
        return;
      }

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<ProdutosResponseProps>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.ETIQUETAS_PERSONALIZADAS_PESQUISAR_POR_NOME,
          valuePaginationDefault
        ),
        {
          params: {
            nomeSkuCodigoExternoBarrasGtinEan: inputValue,
          },
        }
      );
      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((avisos: string) => toast.warning(avisos));
        }
        setTotalRegistros(response?.dados?.total || 0);
        if (response?.sucesso && response?.dados) {
          const listaProdutos = response.dados.registros.map(
            ({
              id,
              nome,
              descricaoTamanho,
              saldo,
              precoVenda,
              padraoSistema,
            }) => {
              return {
                id,
                produto: nome,
                tamanho: descricaoTamanho,
                quantidade: saldo,
                produtoSelecionado: false,
                precoVenda,
                padraoSistema,
              };
            }
          );
          if (listaProdutos?.length === 0) {
            toast.warning('Nenhum produto encontrado');
          }
          if (paginaAtual.current === 1) {
            quantidadeProdutosBuscados.current = listaProdutos?.length;
            setListaProdutosModal(listaProdutos);
          } else {
            setListaProdutosModal((valorAnterior) => {
              const todosProdutosBuscados = [
                ...valorAnterior,
                ...listaProdutos,
              ];
              quantidadeProdutosBuscados.current =
                todosProdutosBuscados?.length;
              return todosProdutosBuscados;
            });
          }
          paginaAtual.current += 1;
        }
      }
      setIsLoading(false);
    }, [formMethods]);

    const handleBuscarPorData = useCallback(async () => {
      if (!dataBusca) {
        toast.warning('Informe a data para buscar os produtos');
        return;
      }

      const nextDay = new Date(dataBusca).setDate(
        new Date(dataBusca).getDate() + 1
      );

      setIsLoading(true);
      const valuePaginationDefault = {
        currentPage: paginaAtual.current,
        orderColumn: '',
        orderDirection: 'asc',
        pageSize: 20,
      } as PaginationData;

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<ProdutosResponseProps>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.ETIQUETA_PERSONALIZADA_PESQUISAR_POR_DATA,
          valuePaginationDefault
        ),
        {
          params: {
            dataInicio: setDateMinHours(new Date(nextDay)).toISOString(),
            dataFim: setDateMaxHours(new Date(nextDay)).toISOString(),
            tipoData,
          },
        }
      );

      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((avisos: string) => toast.warning(avisos));
        }
        setTotalRegistros(response?.dados?.total || 0);
        if (response?.sucesso && response?.dados) {
          const listProducts = response.dados.registros.map(
            ({
              id,
              nome,
              descricaoTamanho,
              saldo,
              precoVenda,
              padraoSistema,
            }) => {
              return {
                id,
                produto: nome,
                tamanho: descricaoTamanho,
                quantidade: saldo,
                produtoSelecionado: saldo > 0,
                precoVenda,
                padraoSistema,
              };
            }
          );
          if (listProducts.length === 0) {
            toast.warning('Nenhum produto encontrado');
          }
          if (paginaAtual.current === 1) {
            quantidadeProdutosBuscados.current = listProducts.length;
            setListaProdutosModal(listProducts);
          } else {
            setListaProdutosModal((valorAnterior) => {
              const todosProdutosBuscados = [...valorAnterior, ...listProducts];
              quantidadeProdutosBuscados.current =
                todosProdutosBuscados?.length;
              return todosProdutosBuscados;
            });
          }
          paginaAtual.current += 1;
        }
      }
      setIsLoading(false);
    }, [dataBusca, tipoData]);

    const handleLimparBusca = () => {
      paginaAtual.current = 1;
      quantidadeProdutosBuscados.current = 0;
      reset(defaultValues);
      setListaProdutosModal([]);
    };

    const handleToggleSelecionarTodosItens = useCallback(() => {
      setListaProdutosModal((valorAnterior) =>
        valorAnterior.map((item) => {
          return {
            ...item,
            produtoSelecionado: !selecionarTodosProdutos,
          };
        })
      );
    }, [selecionarTodosProdutos]);

    const handleToggleSelecionarItem = useCallback((idProduto: string) => {
      setListaProdutosModal((valorAnterior) => {
        return valorAnterior.map((produto) => {
          if (produto.id === idProduto) {
            return {
              ...produto,
              produtoSelecionado: !produto.produtoSelecionado,
            };
          }

          return produto;
        });
      });
    }, []);

    useEffect(() => {
      const todosProdutosSelecionados = listaProdutosModal.every(
        (produtoItem) => produtoItem.produtoSelecionado
      );
      setSelecionarTodosProdutos(todosProdutosSelecionados);
    }, [listaProdutosModal]);

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? '4xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
      >
        <ModalContent
          bg="white"
          borderRadius={isLargerThan900 ? 'md' : '0'}
          w={isLargerThan900 ? '900px' : 'full'}
          h={isLargerThan900 ? '800px' : 'full'}
          px="40px"
          pt="24px"
        >
          <ModalHeader px="0px" pt="0px">
            <Box>
              <Text fontSize="16px" fontWeight="bold" color="primary.50">
                Busca de produtos
              </Text>
            </Box>
            <Divider w="full" mt="18px" />
          </ModalHeader>
          <ModalBody pb="4" pt="2" px="0px">
            <FormProvider {...formMethods}>
              <Flex
                mb="24px"
                gap="24px"
                alignItems="center"
                flexDirection={['column', 'row']}
              >
                {buscarPorNome ? (
                  <Flex flexDir="column" w={['full', 'calc(100% - 160px)']}>
                    <FormLabel mb="0" fontSize="sm" color="black">
                      Buscar por nome
                    </FormLabel>
                    <SearchInput
                      type="search"
                      placeholder="Informe um nome para buscar"
                      onEnterKeyPress={() => {
                        handleBuscarPorNome();
                      }}
                      isDisabled={isLoading}
                      id="nomeSkuCodigoExternoBarrasGtinEan"
                      name="nomeSkuCodigoExternoBarrasGtinEan"
                    />
                  </Flex>
                ) : (
                  <>
                    <Box minWidth="230px" w="full">
                      <Input
                        name="dataBusca"
                        id="dataBusca"
                        label="Selecione o dia*"
                        type="date"
                        max={formatFullDate(new Date())}
                      />
                    </Box>
                    <Box minWidth="230px" w="full" minH="54px">
                      <SelectPadrao
                        label="Tipo de operação"
                        placeholder="Tipo de operação"
                        id="tipoData"
                        name="tipoData"
                        isSearchable={false}
                        options={enumTipoDataImpressaoEtiqueta.properties}
                        isDisabled={isLoading}
                        required
                      />
                    </Box>
                  </>
                )}

                <Button
                  borderRadius="full"
                  color="white"
                  variant="solid"
                  minW="100px"
                  maxW={['full', '160px']}
                  mt={['0px', '16px']}
                  w="full"
                  isLoading={isLoading}
                  colorScheme="purple"
                  leftIcon={<Icon as={LupaIcon} boxSize="18px" />}
                  onClick={async () => {
                    paginaAtual.current = 1;
                    quantidadeProdutosBuscados.current = 0;
                    const funcParaPesquisar = buscarPorNome
                      ? handleBuscarPorNome
                      : handleBuscarPorData;
                    await funcParaPesquisar();
                  }}
                >
                  Pesquisar
                </Button>
              </Flex>

              <Flex
                color="black"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                height="40px"
                w="full"
                bg="gray.200"
                borderTopRadius="lg"
                p="10px"
                pl="32px"
                pr="42px"
              >
                {possuiProdutosNaListagem && (
                  <Flex>
                    <Checkbox
                      onChange={() => handleToggleSelecionarTodosItens()}
                      colorScheme="primary"
                      isChecked={selecionarTodosProdutos}
                      mb="0"
                      bg="white"
                      boxShadow="inset 0px 3px 6px #00000029"
                    />
                  </Flex>
                )}
                <Text w={possuiProdutosNaListagem ? '45%' : '25%'}>
                  Produto
                </Text>
                <Text w={possuiProdutosNaListagem ? '10%' : '25%'}>
                  Tamanho
                </Text>
                <Text w={possuiProdutosNaListagem ? '15%' : '25%'}>
                  Preco venda
                </Text>
                <Text w={possuiProdutosNaListagem ? '20%' : '25%'}>
                  Quantidade atual
                </Text>
              </Flex>
              {isLoading && <LoadingPadrao />}
              {possuiProdutosNaListagem ? (
                <VirtualizedList
                  containerHeight={isLargerThan900 ? 480 : 400}
                  itemHeight={45}
                  colorBgGradient="white"
                  marginBottom={5}
                  items={listaProdutosModal}
                  sx={{
                    maxHeight: '480px',
                  }}
                  quantidadeItems={listaProdutosModal.length}
                  key="virtualizedList"
                  handleOnClick={async (_, observer) => {
                    const funcParaChamarProximaPagina = buscarPorNome
                      ? handleBuscarPorNome
                      : handleBuscarPorData;

                    const possuiMaisItensParaBuscar =
                      totalRegistros > quantidadeProdutosBuscados?.current;
                    if (observer && possuiMaisItensParaBuscar) {
                      await funcParaChamarProximaPagina();
                    }
                  }}
                  render={(produtos, height, marginBottom) => (
                    <>
                      {(produtos || []).map((infoProduto, index) => (
                        <Flex
                          color="black"
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="space-between"
                          borderTopRadius={index > 0 ? 'lg' : 'none'}
                          borderBottomRadius="lg"
                          mb={marginBottom}
                          height={height}
                          w="full"
                          bg="gray.50"
                          p="10px"
                          px="32px"
                          key={infoProduto.id}
                          cursor="pointer"
                          onClick={() => {
                            handleToggleSelecionarItem(infoProduto.id);
                          }}
                        >
                          <Flex>
                            <Checkbox
                              onChange={() => {
                                handleToggleSelecionarItem(infoProduto.id);
                              }}
                              colorScheme="primary"
                              isChecked={infoProduto.produtoSelecionado}
                              mb="0"
                              boxShadow="inset 0px 3px 6px #00000029"
                            />
                          </Flex>
                          <Box textAlign="start" w="45%">
                            {infoProduto.produto}
                          </Box>
                          <Box textAlign="start" w="10%">
                            {infoProduto.padraoSistema
                              ? '-'
                              : infoProduto.tamanho}
                          </Box>
                          <Box textAlign="start" w="15%">
                            <TextValor
                              casasDecimais={casasDecimaisValor}
                              valor={infoProduto.precoVenda || 0}
                              color="black"
                              fontSize="sm"
                              fontWeight="normal"
                              symbolFontSize="x-small"
                            />
                          </Box>
                          <Box textAlign="start" w="20%">
                            {infoProduto.quantidade}
                          </Box>
                        </Flex>
                      ))}
                    </>
                  )}
                />
              ) : (
                <Flex
                  boxShadow="md"
                  borderBottomRadius="lg"
                  align="center"
                  pl="32px"
                  minH="50px"
                >
                  Nenhum produto encontrado
                </Flex>
              )}
            </FormProvider>
          </ModalBody>
          <ModalFooter mt="-5px" justifyContent="center">
            <Flex
              justifyContent="center"
              flexDir={isSmallerThan500 ? 'column-reverse' : 'row'}
              gap={['12px', '24px']}
              w="full"
            >
              <Button
                variant="unstyled"
                colorScheme="red"
                borderRadius="full"
                color="gray.500"
                minW={isSmallerThan500 ? 'full' : '100px'}
                _hover={{ color: 'red.500', bg: 'red.50' }}
                _active={{ color: 'red.500', bg: 'red.100' }}
                _focus={{ boxShadow: 'none' }}
                onClick={() => onClose()}
              >
                Cancelar
              </Button>
              <Button
                variant="outlineDefault"
                colorScheme="gray"
                borderRadius="full"
                color="gray.700"
                minW={isSmallerThan500 ? 'full' : '100px'}
                onClick={handleLimparBusca}
              >
                Limpar pesquisa
              </Button>
              <Button
                borderRadius="full"
                color={naoPossuiProdutosSelecionados ? 'gray.700' : 'white'}
                colorScheme={
                  naoPossuiProdutosSelecionados ? 'gray' : 'aquamarine'
                }
                variant="solid"
                minW={isSmallerThan500 ? 'full' : '100px'}
                isDisabled={naoPossuiProdutosSelecionados}
                onClick={() => {
                  handleAdicionarProdutos();
                }}
              >
                Adicionar produtos
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
