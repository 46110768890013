import React from 'react';
import { Stack, StackProps, useRadioGroup } from '@chakra-ui/react';

import { DetailedRadioItem, DetailedRadioItemType } from './DetailedRadioItem';

export interface DetailedRadioProps extends Omit<StackProps, 'onChange'> {
  name: string;
  items: DetailedRadioItemType[];
  colorScheme?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
}

export function DetailedRadio({
  name,
  items,
  colorScheme = 'purple',
  defaultValue,
  onChange,
  ...rest
}: DetailedRadioProps) {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    onChange,
  });

  const rootProps = getRootProps();

  return (
    <Stack spacing="4" {...rest} {...rootProps}>
      {items.map((item) => {
        const radioProps = getRadioProps({ value: item.value });

        return (
          <DetailedRadioItem
            key={item.value}
            item={item}
            radioProps={radioProps}
            colorScheme={colorScheme}
          />
        );
      })}
    </Stack>
  );
}
