import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form } from 'react-bootstrap';
import { FieldErrors, UseFormRegister } from 'react-hook-form';

import { moneyMask } from 'helpers/format/fieldsMasks';

import ButtonComOpcoes from 'components/Button/ButtonComOpcoes';
import TableCampoFormularioPadrao from 'components/Table/TableCampoFormularioPadrao';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { BootstrapNumberInput } from 'components/update/Input/BootstrapNumberInput';

import DocumentoFiscalModal from './DocumentoFiscalModal';

interface UncontrolledFormInterface {
  errors: FieldErrors;
  register: UseFormRegister<Record<string, any>>;
  control: any;
  getValues: any;
  setValue: any;
}

const DadosImportacao: React.FC<UncontrolledFormInterface> = ({
  errors,
  register,
  control,
  getValues,
  setValue,
}) => {
  const { t } = useTranslation();
  const [showDocumentoFiscalModal, setShowDocumentoFiscalModal] = useState(
    false
  );

  const removerItemList = (index: number, propertyName: string) => {
    ModalConfirmacaoExcluir({
      callback: async (ok: boolean) => {
        if (ok) {
          const items = getValues(propertyName) as Array<any>;
          items.splice(index, 1);

          setValue(propertyName, [...items]);
        }
      },
      text: '',
    });
  };

  return (
    <>
      <Form.Row>
        <Col xl={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '-20px',
            }}
          >
            <Button
              variant="link"
              style={{ fontSize: '14px' }}
              onClick={() => setShowDocumentoFiscalModal(true)}
            >
              Adicionar documento de adição
            </Button>
          </div>
          <div style={{ marginBottom: '22px' }}>
            <TableCampoFormularioPadrao
              fieldName="adicoes"
              defaultColumnOrder=""
              headerColumns={[
                {
                  name: 'di',
                  displayName: 'DI',
                  isOrderable: false,
                  width: '',
                },
                {
                  name: 'numeroAdicao',
                  displayName: 'Número da adição',
                  isOrderable: false,
                  width: '',
                },
                {
                  name: 'itemAdicao',
                  displayName: 'Item da adição',
                  isOrderable: false,
                  width: '',
                },
                {
                  name: 'codigoFabricante',
                  displayName: 'Código do fabricante',
                  isOrderable: false,
                  width: '',
                },
                {
                  name: 'valorDesconto',
                  displayName: 'Valor do desconto',
                  isOrderable: false,
                  width: '',
                },
                {
                  name: 'acoes',
                  displayName: 'Ações',
                  isOrderable: false,
                  width: '38px',
                },
              ]}
              emptyValuesText="Nenhuma adição adicionada para este produto"
              control={control}
              isLoading={false}
            >
              {({ tableValues }) => (
                <>
                  {tableValues &&
                    tableValues.map((tableValue, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <tr key={index}>
                        <td>{tableValue.documentoImportacao}</td>
                        <td>{tableValue.numeroAdicao}</td>
                        <td>{tableValue.itemAdicao}</td>
                        <td>{tableValue.codigoFabricante}</td>
                        <td>{moneyMask(tableValue.descontoAdicao, false)}</td>
                        <td>
                          <ButtonComOpcoes
                            id="mostrarMais"
                            dropdownItems={[
                              {
                                title: t('Remover'),
                                onClick: () =>
                                  removerItemList(index, 'adicoes'),
                              },
                            ]}
                          />
                        </td>
                      </tr>
                    ))}
                </>
              )}
            </TableCampoFormularioPadrao>
          </div>
        </Col>
        <Col md={3} xl={2}>
          <BootstrapNumberInput
            id="impostoImportacao"
            name="impostoImportacao"
            label={t('Valor do imposto de importação')}
            precision={12}
            scale={2}
            defaultValue={0}
            leftElement="R$"
            control={control}
            error={errors.impostoImportacao}
          />
        </Col>
      </Form.Row>
      <DocumentoFiscalModal
        errors={errors}
        control={control}
        register={register}
        getValues={getValues}
        setValue={setValue}
        show={showDocumentoFiscalModal}
        onHide={() => setShowDocumentoFiscalModal(false)}
      />
    </>
  );
};

export default DadosImportacao;
