import { useState, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import { UncontrolledForm } from '..';
import { useDeliveryPersonForm } from '../hooks';
import { FormData, defaultValues, yupResolver } from '../validationForm';

type DeliveryPersonResponseProps = {
  id: string;
  nome: string;
  loja: {
    id: string;
    nome: string;
    ativo: boolean;
    nomeEcommerce: string;
  };
  informacoes: {
    ativo: boolean;
    dataHoraCadastro: string;
    dataHoraUltimaAlteracao: string;
  };
};

const AlterarEntregador = () => {
  const { id: idRota } = useParams<{ id: string }>();
  const { redirectToDeliveryPersonList } = useDeliveryPersonForm();

  const formMethods = useForm<FormData>({
    defaultValues,
    resolver: yupResolver,
  });

  const { handleSubmit, reset } = formMethods;

  const [isLoading, setIsLoading] = useState(false);

  const updateDeliveryPerson = useCallback(async (data: FormData) => {
    const response = await api.put<void, ResponseApi>(
      `${ConstanteEnderecoWebservice.ENTREGADOR}/${idRota}`,
      {
        ...data,
      }
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response?.sucesso) {
        toast.success('Entregador alterado com sucesso');
        return { success: true };
      }
    }

    return { success: false };
  }, []);

  const getDeliveryPerson = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<
      void,
      ResponseApi<DeliveryPersonResponseProps>
    >(`${ConstanteEnderecoWebservice.ENTREGADOR}/${idRota}`);

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }
      if (response?.sucesso && response?.dados) {
        const { informacoes, nome } = response.dados;

        reset({
          nome,
          ativo: informacoes.ativo,
        });
      }
    }

    setIsLoading(false);
  }, [idRota, reset]);

  const handleSave = handleSubmit(async (data) => {
    setIsLoading(true);
    const { success } = await updateDeliveryPerson(data);

    if (success) {
      redirectToDeliveryPersonList();
    }
    setIsLoading(false);
  });

  useEffect(() => {
    getDeliveryPerson();
  }, [getDeliveryPerson]);

  return (
    <FormProvider {...formMethods}>
      <UncontrolledForm handleSave={handleSave} isLoading={isLoading} />
    </FormProvider>
  );
};

export { AlterarEntregador };
