export type MovimentacaoFinanceiraBaixa = {
  movimentacaoFinanceiraId: string;
  dataVencimento: Date;
  parcela: string;
  valorOriginal?: number;
  multa: number;
  juros: number;
  valorTotal: number;
  selecionado: boolean;
  clienteFornecedorNome: string;
  clienteFornecedorId: string;
  operacaoId: string;
  numeroOperacao: number;
};

export type ObterContasEmAbertoResponse = {
  dataUltimoPagamento?: Date;
  valorUltimoPagamento: number;
  movimentacaoFinanceira: MovimentacaoFinanceiraBaixa[];
};

export type FormData = {
  clienteFornecedorId: {
    value: string;
    label: string;
  };
  numeroOperacao?: string | number | null;
};

export const formDefaultValues = {
  clienteFornecedorId: undefined,
};
