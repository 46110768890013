import {
  Button,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  Td,
  Flex,
  Box,
  Icon,
  Text,
  MenuButton,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { FiAlertCircle } from 'react-icons/fi';
import { isExists, lastDayOfMonth } from 'date-fns';

import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';

import { SimpleCard } from 'components/update/Form/SimpleCard';
import { DateInput } from 'components/update/Input/DateInput';
import { NumberInput } from 'components/update/Input/NumberInput';
import { TextValor } from 'components/PDV/Text/TextValor';
import MenuDia from 'components/PDV/Menu/MenuDia';

type FormaPagamento = {
  value: string;
  label: string;
  lancarFatura: boolean;
};

type Parcela = {
  parcela: string;
  vencimento: Date;
  formaPagamento: FormaPagamento;
  valor: number;
};

interface ListagemParcelasProps {
  podeAlterarParcelas?: boolean;
  isReadOnly?: boolean;
}

export function ListagemParcelas({
  podeAlterarParcelas = true,
  isReadOnly,
}: ListagemParcelasProps) {
  const { casasDecimais } = usePadronizacaoContext();
  const { watch, setValue } = useFormContext();

  const parcelas: Parcela[] = watch('parcelas') || [];
  const formaPagamento = watch('formaPagamento') || {};
  const valorTotal = watch('valorTotal') || 0;

  function getValorDiferenca() {
    const valorTotalAdicionado = (parcelas || []).reduce(
      (acc, curr) => acc + curr.valor,
      0
    );

    const fixedValorTotal = (valorTotal || 0).toFixed(
      casasDecimais.casasDecimaisValor
    );
    const fixedValorTotalAdicionar = valorTotalAdicionado.toFixed(
      casasDecimais.casasDecimaisValor
    );

    return parseFloat(fixedValorTotal) - parseFloat(fixedValorTotalAdicionar);
  }

  const valorDiferenca = getValorDiferenca();

  function handlePadronizarDia(diaSelecionado: number) {
    let previousMonth = 0;
    let previousYear: number;

    const novasParcelas = parcelas.map((parcela, index) => {
      let isValid = false;

      const dateObj = new Date(parcela.vencimento);
      let year = previousYear ?? dateObj.getUTCFullYear();
      let month = dateObj.getUTCMonth();

      if (index === 0) {
        previousMonth = month;
        previousYear = year;
      } else if (previousMonth < 11) {
        month = previousMonth + 1;
        previousMonth = month;
      } else {
        month = 0;
        previousMonth = month;

        year += 1;
        previousYear = year;
      }

      try {
        isValid = isExists(year, month, diaSelecionado);
      } catch (error) {
        isValid = false;
      }

      const lastDay = lastDayOfMonth(new Date(parcela.vencimento));
      lastDay.setHours(0, 0, 0, 0);

      return {
        ...parcela,
        vencimento: isValid
          ? new Date(year, month, diaSelecionado, 0, 0, 0)
          : lastDay,
      };
    });

    setValue('parcelas', novasParcelas);
  }

  return (
    <SimpleCard
      boxShadow="none"
      bg="gray.50"
      mt={{ base: 3, sm: 6, md: 8 }}
      overflow="auto"
    >
      {(podeAlterarParcelas ||
        (valorDiferenca !== 0 && parcelas.length > 0)) && (
        <Flex h="6" alignItems=" center" justifyContent="space-between">
          {podeAlterarParcelas && (
            <MenuDia
              MenuButton={() => (
                <MenuButton
                  as={Button}
                  variant="link"
                  colorScheme="pink"
                  size="xs"
                  disabled={isReadOnly}
                >
                  Padronizar o mesmo dia de vencimento
                </MenuButton>
              )}
              OnSelectDay={(selectedDay) => {
                handlePadronizarDia(selectedDay);
              }}
            />
          )}

          {valorDiferenca !== 0 && parcelas.length > 0 && (
            <Flex
              alignItems="center"
              justifyContent="flex-end"
              color="red.500"
              mt="2.5"
            >
              <Icon as={FiAlertCircle} fontSize="md" />

              <Text ml="1.5" fontSize="xs" fontWeight="semibold">
                A soma das parcelas está diferente do valor total
              </Text>

              <Flex
                alignItems="center"
                justifyContent="flex-end"
                ml="2.5"
                h="6"
                border="1px"
                borderColor="red.500"
                borderRadius="md"
                minW="80px"
                px="1.5"
              >
                <TextValor
                  valor={valorDiferenca}
                  color="red.500"
                  fontSize="xs"
                  fontWeight="semibold"
                />
              </Flex>
            </Flex>
          )}
        </Flex>
      )}

      <Table
        size="sm"
        variant="simple-card"
        mt={
          podeAlterarParcelas || (valorDiferenca !== 0 && parcelas.length > 0)
            ? { base: 2, sm: 4 }
            : 0
        }
        sx={{
          'th:first-child': { pl: 'calc(1.5rem + 6px)' },
          'th:last-child': { pr: 'calc(1.5rem + 18px)' },
        }}
      >
        <Thead>
          <Tr sx={{ '& > th': { borderBottom: 'none', whiteSpace: 'nowrap' } }}>
            <Th>Parcelas</Th>
            <Th minW="150px">Vencimento</Th>
            <Th>Forma de pagamento</Th>
            <Th isNumeric minW="150px">
              Valor
            </Th>
          </Tr>
        </Thead>
        <Tbody sx={{ '& td': { bg: 'white', whiteSpace: 'nowrap', py: 2 } }}>
          {parcelas.length > 0 ? (
            parcelas.map((parcela, index) => (
              <>
                <Tr key={parcela.parcela}>
                  <Td color="primary.50">{parcela.parcela}</Td>
                  <Td>
                    <DateInput
                      name={`parcelas.${index}.vencimento`}
                      size="sm"
                      maxW="200px"
                      isDisabled={!podeAlterarParcelas || isReadOnly}
                    />
                  </Td>
                  <Td>{formaPagamento.label}</Td>
                  <Td isNumeric>
                    <Flex justifyContent="flex-end">
                      <NumberInput
                        name={`parcelas.${index}.valor`}
                        leftElement="R$"
                        size="sm"
                        maxW="200px"
                        isDisabled={!podeAlterarParcelas || isReadOnly}
                      />
                    </Flex>
                  </Td>
                </Tr>
                <Box
                  key={`${parcela.parcela}-box-2`}
                  h={index + 1 === parcelas.length ? 0 : 1}
                  zIndex="hide"
                />
              </>
            ))
          ) : (
            <Tr>
              <Td colSpan={4}>
                Não existem parcelas informadas. Preencha o valor total e clique
                em “Gerar parcelas”.
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </SimpleCard>
  );
}
