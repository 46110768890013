import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

interface GerarNotaFiscalReqData {
  operacaoId: string;
  cpfCnpj?: string;
  modeloFiscal: number;
  dataEmissao?: string;
  dataSaida?: string | null;
  dadosAdicionais?: string;
  dadosAdicionaisTributos?: string;
  numeroPedidoExterno?: string;

  // Informações de transporte
  modalidadeFrete?: number;
  transportadoraId?: string;
  placaTransportadora?: string;
  ufVeiculoTransportadora?: string;
  pesoLiquido?: number;
  pesoBruto?: number;
  quantidadeVolumeTransportadora?: number;
  especieVolumeTransportadora?: string;
  marcaVolumeTransportadora?: string;
  numeracaoVolumeTransportadora?: string;
  ufEmbarque?: string;
  localEmbarque?: string;

  // Intermediador
  operacaoComIntermediador?: number;
  cnpjIntermediador?: string | null;
  identificacaoNoIntermediador?: string;
}

const gerarNotaFiscal = async (
  data: GerarNotaFiscalReqData,
  setErrorText?: (errorText: string) => void
): Promise<string | undefined> => {
  const response = await api.post<void, ResponseApi<string>>(
    ConstanteEnderecoWebservice.PEDIDOORCAMENTOVENDA_GERAR_NOTA_FISCAL,
    data
  );

  if (response) {
    if (response.avisos) {
      if (setErrorText) {
        setErrorText(response.avisos[0]);
      } else {
        response.avisos.map((item: string) => toast.warning(item));
      }
    }

    if (response.sucesso) {
      return response.dados;
    }
  }

  return undefined;
};

export default gerarNotaFiscal;
