import { FiCheck } from 'react-icons/fi';
import { MouseEventHandler } from 'react';
import { IconButton, Icon } from '@chakra-ui/react';

export const ConciliadoIconButton = ({
  isLoading,
  onClick,
}: {
  isLoading: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
}) => (
  <IconButton
    aria-label="Contas conciliadas"
    variant="unstyled"
    h="4"
    w="4"
    minW="0px"
    bg="orange.500"
    borderRadius="full"
    display="flex"
    alignItems="center"
    justifyContent="center"
    onClick={onClick}
    icon={<Icon as={FiCheck} color="white" boxSize="3" display="flex" />}
    isLoading={isLoading}
    _loading={{
      color: 'orange.500',
      opacity: 1,
      bg: 'white',
    }}
  />
);
