import {
  Box,
  HStack,
  useMediaQuery,
  useToken,
  VStack,
  Text,
  Circle,
  Divider,
  Stack,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import ApexCharts, { ApexOptions } from 'apexcharts';
import { moneyMask } from 'helpers/format/fieldsMasks';
import { renderToString } from 'react-dom/server';

export interface DashboardInicialDetalhesPorMesInterface {
  descricaoMes: string;
  valorTotal: number;
  qtdVendas: number;
  qtdProdutos: number;
}

export interface DashboardInicialTotalizadoresPorMesInterface {
  totalVendas: number;
  ticketMedio: number;
  produtosVendidos: number;
  produtosPorAtendimento: number;
  faturamento: number;
  meses: DashboardInicialDetalhesPorMesInterface[];
}

interface UltimosDozeMesesInterface {
  meses: DashboardInicialDetalhesPorMesInterface[] | undefined;
  loading: boolean;
}

const UltimosDozeMeses = ({ meses, loading }: UltimosDozeMesesInterface) => {
  const [infoGraficoState, setInfoGraficoState] = useState<any>(undefined);

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const [
    primary50,
    gray200,
    gray700,
    gray900,
    secondary600,
    orange500,
    purple500,
    teal600,
    pink500,
  ] = useToken('colors', [
    'primary.400',
    'gray.200',
    'gray.700',
    'gray.900',
    'secondary.600',
    'orange.500',
    'purple.500',
    'teal.600',
    'pink.500',
  ]);

  const carregarGrafico = useCallback(() => {
    const infoGrafico = {
      series: [
        {
          name: 'Total',
          data: [],
        },
      ],
      options: {
        chart: {
          zoom: {
            enabled: false,
          },
          type: 'area',
          id: 'venda-ultimos-doze-meses',
          toolbar: {
            show: false,
          },
          foreColor: gray700,
          fontFamily: 'Nunito, sans-serif',
          fontSize: '12px',
        },
        title: {
          text: 'Faturamento',
          align: 'left',
          margin: 24,
          offsetX: 8,
          offsetY: 0,
          style: {
            fontSize: '14px',
            color: primary50,
            fontWeight: 'normal',
          },
        },
        colors: [primary50],
        fill: {
          gradient: {
            gradientToColors: ['#fff'],
            opacityFrom: 1,
            opacityTo: 0.5,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        markers: {
          colors: [primary50],
          strokeColor: primary50,
          strokeWidth: 3,
          hover: {
            size: undefined,
            sizeOffset: 10,
          },
        },
        xaxis: {
          categories: meses?.map(
            (item: DashboardInicialDetalhesPorMesInterface) => [
              item.descricaoMes.toUpperCase(),
            ]
          ),
          tooltip: {
            enabled: false,
          },
          labels: {
            show: true,
            style: {
              colors: [gray700],
            },
          },
        },
        grid: {
          borderColor: gray700,
          yaxis: {
            lines: {
              show: true,
            },
          },
          padding: {
            bottom: isLargerThan900 ? 20 : 0,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          title: {
            style: {
              color: gray700,
            },
          },
          labels: {
            show: true,
            style: {
              colors: [gray700],
            },
            formatter: (value: number) => {
              return moneyMask(value, true);
            },
          },
        },
        labels: [],
        tooltip: {
          enabled: true,
          theme: 'dark',
          marker: {
            show: false,
          },
          custom({ dataPointIndex }) {
            const mes = (meses
              ? meses[dataPointIndex]
              : []) as DashboardInicialDetalhesPorMesInterface;

            const valorTicketMedio = () => {
              if (mes.valorTotal && mes.qtdVendas && mes.qtdVendas !== 0) {
                if (mes.qtdVendas < 0) {
                  moneyMask(mes.valorTotal / (mes.qtdVendas * -1), true);
                }

                return moneyMask(mes.valorTotal / mes.qtdVendas, true);
              }
              return moneyMask(0, true);
            };

            const valorProdutoPorAtendimento = () => {
              if (mes.qtdProdutos && mes.qtdVendas && mes.qtdVendas !== 0) {
                if (mes.qtdVendas < 0) {
                  return Math.floor(mes.qtdProdutos / (mes.qtdVendas * -1));
                }

                return Math.floor(mes.qtdProdutos / mes.qtdVendas);
              }

              return 0;
            };

            return renderToString(
              <VStack
                borderRadius="md"
                boxShadow="md"
                bg={gray900}
                opacity="0.95"
                color="white"
                py="10"
                alignItems="flex-start"
              >
                <Text pl="20">{mes.descricaoMes}</Text>
                <HStack pl="10" width="100%">
                  <Divider
                    orientation="horizontal"
                    color="red"
                    bgColor={gray200}
                    height="2px"
                    width="95%"
                    opacity="0.6"
                  />
                </HStack>
                <Stack px="20">
                  <HStack>
                    <Circle size="11px" bg={secondary600} />
                    <Text fontSize="x-small">FATURAMENTO: </Text>
                    <Text fontSize="small">
                      {moneyMask(mes.valorTotal, true)}
                    </Text>
                  </HStack>
                  <HStack>
                    <Circle size="11px" bg={orange500} />
                    <Text fontSize="x-small">TOTAL DE VENDAS: </Text>
                    <Text fontSize="small">{mes.qtdVendas}</Text>
                  </HStack>
                  <HStack>
                    <Circle size="11px" bg={purple500} />
                    <Text fontSize="x-small">TICKET MÉDIO: </Text>
                    <Text fontSize="small">{valorTicketMedio()}</Text>
                  </HStack>
                  <HStack>
                    <Circle size="11px" bg={teal600} />
                    <Text fontSize="x-small">PROD. VENDIDOS: </Text>
                    <Text fontSize="small">{mes.qtdProdutos} </Text>
                  </HStack>
                  <HStack>
                    <Circle size="11px" bg={pink500} />
                    <Text fontSize="x-small">POR ATENDIMENTO: </Text>
                    <Text fontSize="small">{valorProdutoPorAtendimento()}</Text>
                  </HStack>
                </Stack>
              </VStack>
            );
          },
        },
        noData: {
          text: 'Não existem dados para serem exibidos',
          style: {
            color: primary50,
          },
        },
      } as ApexOptions,
    };

    const newInfoGrafico = {
      ...infoGrafico,
      series: [
        {
          name: '<p>teste</p>',
          data: meses?.map((item) => item.valorTotal),
        },
      ],
    };

    setInfoGraficoState(newInfoGrafico);
  }, [isLargerThan900, meses]);

  useEffect(() => {
    carregarGrafico();
  }, [carregarGrafico]);

  return (
    <Box
      boxShadow="lg"
      borderRadius="md"
      height="full"
      maxH={{ base: '450px', md: '327px' }}
      bg="white"
      px="2"
      mt={{ base: '24px', md: '32px', lg: '40px' }}
    >
      {!loading && infoGraficoState && (
        <ReactApexChart
          options={infoGraficoState.options}
          series={infoGraficoState.series}
          type={infoGraficoState.options.chart.type}
          height={isLargerThan900 ? '350px' : '450px'}
        />
      )}
    </Box>
  );
};

export default UltimosDozeMeses;
