import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  Box,
  Button,
  Divider,
  HStack,
  VStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Icon,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Text,
} from '@chakra-ui/react';
import { FiMoreVertical } from 'react-icons/fi';

import { getOptionByEnum } from 'helpers/format/getOptionsByEnum';
import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';
import { useRegrasFiscaisFormularioContext } from 'store/RegrasFiscais/RegrasFiscaisFormularios';

import { LixeiraIcon } from 'icons';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';

import ModalAdicionarOperacao, {
  possibleOptions,
} from './ModalAdicionarOperacao';
import { FormOperacaoData } from '../types';

interface OperacaoFieldArray extends FormOperacaoData {
  id: string;
}

interface FieldArrayType {
  operacoes: OperacaoFieldArray[];
}

interface OperacoesProps {
  isReadonly?: boolean;
  asAccordion?: boolean;
}

const Operacoes = ({
  isReadonly = false,
  asAccordion = false,
}: OperacoesProps) => {
  const {
    activeOperacaoIndex,
    handleSetActiveOperacaoIndex,
  } = useRegrasFiscaisFormularioContext();
  const { getValues } = useFormContext();
  const { fields, append, remove } = useFieldArray<FieldArrayType>({
    name: 'operacoes',
  });

  async function handleAddOperacao() {
    const operacoes: FormOperacaoData[] = getValues('operacoes');

    const identificacoesTipoOperacaoAlreadyAdded = operacoes
      ? operacoes.map((operacao) => operacao.identificacaoTipoOperacao)
      : [];

    const { identificacaoTipoOperacao } = await ModalAdicionarOperacao({
      identificacoesTipoOperacaoAlreadyAdded,
    });

    append({
      identificacaoTipoOperacao,
    } as any);

    handleSetActiveOperacaoIndex(fields.length);
  }

  function handleRemoveOperacao(index: number) {
    ModalConfirmacaoExcluir({
      callback: async (ok: boolean) => {
        if (ok) {
          if (activeOperacaoIndex === index) {
            handleSetActiveOperacaoIndex(0);
          }

          remove(index);
        }
      },
      closeOnOverlayClick: false,
    });
  }

  const allOptionsAlreadyAdded = fields.length === possibleOptions.length + 1; // +1 por conta da operação de venda que não está nas opções do select, mas é padrão de todos os cadastros.

  if (asAccordion) {
    const hasOthersFields = fields.some(
      (_, index) => index !== activeOperacaoIndex
    );

    return (
      <Box w="full">
        <Text
          color="white"
          fontSize="13px"
          lineHeight="1.5"
          fontWeight="semibold"
          mb="3px"
        >
          Selecione a operação
        </Text>

        <Accordion
          allowToggle
          index={isReadonly && !hasOthersFields ? -1 : undefined}
        >
          <AccordionItem w="full">
            <AccordionButton bg="white" color="primary.50">
              <Box flex="1" textAlign="left" fontSize="sm">
                {fields &&
                  fields[activeOperacaoIndex] &&
                  getOptionByEnum(
                    IdentificacaoTipoOperacaoEnum,
                    (fields[activeOperacaoIndex] as FormOperacaoData)
                      .identificacaoTipoOperacao
                  )?.label}
              </Box>
              <AccordionIcon color="primary.50" />
            </AccordionButton>
            <AccordionPanel bg="white">
              <VStack
                alignItems="flex-start"
                spacing={hasOthersFields ? '5' : '0'}
              >
                <VStack
                  alignItems="flex-start"
                  spacing={hasOthersFields ? '3' : '0'}
                  w="full"
                >
                  {fields.map((field: OperacaoFieldArray, index) => {
                    const { label: fieldTitle = '' } =
                      getOptionByEnum(
                        IdentificacaoTipoOperacaoEnum,
                        field.identificacaoTipoOperacao
                      ) || {};

                    const isActive = activeOperacaoIndex === index;

                    if (isActive) {
                      return null;
                    }

                    return (
                      <HStack key={field.id} justify="space-between" w="full">
                        <Button
                          variant="link"
                          size="sm"
                          justifyContent="flex-start"
                          w="full"
                          fontSize="xs"
                          fontWeight={isActive ? 'semibold' : 'normal'}
                          colorScheme={isActive ? 'primary' : 'gray'}
                          textDecoration="none"
                          _hover={{ textDecoration: 'none' }}
                          _focus={{ boxShadow: 'none' }}
                          _disabled={{ opacity: '1 !important' }}
                          onClick={
                            isActive
                              ? undefined
                              : () => handleSetActiveOperacaoIndex(index)
                          }
                          isDisabled={isActive}
                        >
                          {fieldTitle}
                        </Button>

                        {field.identificacaoTipoOperacao !==
                          IdentificacaoTipoOperacaoEnum.VENDA &&
                          !isReadonly && (
                            <Menu placement="auto">
                              <MenuButton
                                as={IconButton}
                                borderRadius="md"
                                aria-label="Options"
                                icon={
                                  <Icon as={FiMoreVertical} fontSize="lg" />
                                }
                                variant="unstyled"
                                size="xs"
                                p="2"
                              />
                              <MenuList>
                                <MenuItem
                                  icon={
                                    <Icon
                                      as={LixeiraIcon}
                                      color="red.500"
                                      fontSize="lg"
                                    />
                                  }
                                  onClick={() => handleRemoveOperacao(index)}
                                >
                                  Remover
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          )}
                      </HStack>
                    );
                  })}
                </VStack>

                {!isReadonly && !allOptionsAlreadyAdded && (
                  <>
                    {hasOthersFields && <Divider />}

                    <Button
                      variant="link"
                      colorScheme="blue"
                      size="sm"
                      fontSize="xs"
                      _focus={{ boxShadow: 'none' }}
                      onClick={handleAddOperacao}
                    >
                      Adicionar operação
                    </Button>
                  </>
                )}
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
    );
  }

  return (
    <Box
      minW="40"
      h="390px"
      borderRadius="md"
      boxShadow="md"
      py="6"
      px="5"
      bg="white"
    >
      <VStack alignItems="flex-start" spacing="5">
        <VStack alignItems="flex-start" spacing="3" w="full">
          {fields.map((field: OperacaoFieldArray, index) => {
            const { label: fieldTitle = '' } =
              getOptionByEnum(
                IdentificacaoTipoOperacaoEnum,
                field.identificacaoTipoOperacao
              ) || {};

            const isActive = activeOperacaoIndex === index;

            return (
              <HStack key={field.id} justify="space-between" w="full">
                <Button
                  variant="link"
                  size="sm"
                  fontSize="xs"
                  fontWeight={isActive ? 'semibold' : 'normal'}
                  colorScheme={isActive ? 'primary' : 'gray'}
                  textDecoration="none"
                  _hover={{ textDecoration: 'none' }}
                  _focus={{ boxShadow: 'none' }}
                  _disabled={{ opacity: '1 !important' }}
                  onClick={
                    isActive
                      ? undefined
                      : () => handleSetActiveOperacaoIndex(index)
                  }
                  isDisabled={isActive}
                >
                  {fieldTitle}
                </Button>

                {field.identificacaoTipoOperacao !==
                  IdentificacaoTipoOperacaoEnum.VENDA &&
                  !isReadonly && (
                    <Menu placement="auto">
                      <MenuButton
                        as={IconButton}
                        borderRadius="md"
                        aria-label="Options"
                        icon={<Icon as={FiMoreVertical} fontSize="lg" />}
                        variant="unstyled"
                        size="xs"
                        p="2"
                      />
                      <MenuList>
                        <MenuItem
                          icon={
                            <Icon
                              as={LixeiraIcon}
                              color="red.500"
                              fontSize="lg"
                            />
                          }
                          onClick={() => handleRemoveOperacao(index)}
                        >
                          Remover
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  )}
              </HStack>
            );
          })}
        </VStack>

        {!isReadonly && !allOptionsAlreadyAdded && (
          <>
            <Divider />

            <Button
              id="adicionarOperacao"
              variant="link"
              colorScheme="blue"
              size="sm"
              fontSize="xs"
              _focus={{ boxShadow: 'none' }}
              onClick={handleAddOperacao}
            >
              Adicionar operação
            </Button>
          </>
        )}
      </VStack>
    </Box>
  );
};

export default Operacoes;
