import {
  Box,
  Flex,
  Icon,
  Text,
  VStack,
  AspectRatio,
  UnorderedList,
  ListItem,
  Image,
  Grid,
  GridItem,
  useMediaQuery,
} from '@chakra-ui/react';
import { FiChevronLeft } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import ConstanteRotas from 'constants/rotas';
import imagemDashboardGerencial from 'assets/images/integracoes/dashboard/01.png';
import imagemDashboardVendas from 'assets/images/integracoes/dashboard/02.png';

import {
  AppCelularDashboardGerencial,
  AppWebDashboardGerencial,
  AppUsuarioDashboardGerencial,
  AppInfoLojaDashboardGerencial,
} from 'icons';

export const DashboardGerencial = () => {
  const history = useHistory();
  const handleVoltar = () => {
    history.push(ConstanteRotas.LOJA_APLICATIVOS);
  };

  const [isLargerThan1100] = useMediaQuery('(min-width: 1100px)');
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  return (
    <Box
      bg="white"
      boxShadow="0px 0px 6px #00000034"
      pt="2.9%"
      pl="5.4%"
      pr="5.4%"
      pb="100px"
      borderRadius="5px"
    >
      <Flex mb="16px" justifyContent="flex-start" alignItems="center">
        <Box onClick={() => handleVoltar()} cursor="pointer" mr="16px">
          <Icon color="gray.500" boxSize="25px" as={FiChevronLeft} />
        </Box>
        <Box onClick={() => handleVoltar()} cursor="pointer">
          <Text fontSize="14px" color="gray.500">
            Voltar
          </Text>
        </Box>
      </Flex>
      <Flex
        mb="83px"
        direction={['column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box w={['full', 'full', '550px']}>
          <Text
            letterSpacing="0"
            fontSize={['25px', '25px', '32px']}
            color="primary.50"
            fontWeight="extrabold"
          >
            Dashboard gerencial:
          </Text>
          <Text
            lineHeight={['', '', '40px']}
            fontSize={['25px', '25px', '32px']}
            letterSpacing="0"
            color="primary.50"
          >
            Os principais indicadores da empresa em tempo real e na palma da
            mão.
          </Text>
          {!isLargerThan900 && (
            <Flex
              mt="20px"
              w="full"
              justifyContent="space-between"
              alignItems="center"
            >
              <Image w="465px" src={imagemDashboardGerencial} />
            </Flex>
          )}
          <VStack mb="43px" mt="24px">
            <Text
              lineHeight="20px"
              letterSpacing="0"
              fontSize="16px"
              color="gray.700"
            >
              <span
                style={{
                  fontWeight: 'bold',
                }}
              >
                Dashboard
              </span>{' '}
              é o nosso aplicativo gerencial próprio para que você tenha ainda
              mais mobilidade e acompanhe a empresa sempre de perto. É
              compatível com celulares{' '}
              <span
                style={{
                  fontWeight: 'bold',
                }}
              >
                Android
              </span>{' '}
              e{' '}
              <span
                style={{
                  fontWeight: 'bold',
                }}
              >
                IOS
              </span>{' '}
              e também pode ser acessado diretamente pelo navegador do seu
              computador.
            </Text>
            <Text
              lineHeight="20px"
              letterSpacing="0"
              fontSize="16px"
              color="gray.700"
            >
              O aplicativo é atualizado em tempo real com as informações do
              sistema. É simples, repleto de gráficos intuitivos e de fácil
              compreensão, permitindo que você acompanhe o desempenho de tudo
              que está acontecendo na empresa de onde estiver, em qualquer
              lugar.
            </Text>
          </VStack>
        </Box>
        {isLargerThan900 && (
          <Box>
            <Image w="465px" src={imagemDashboardGerencial} />
          </Box>
        )}
      </Flex>
      <Flex
        borderRadius="13px"
        h={['auto', 'auto', '338px']}
        p="30px"
        bg="gray.100"
        direction={['column-reverse', 'column-reverse', 'row']}
        justifyContent="center"
        alignItems="center"
      >
        <AspectRatio w={['full', 'full', '400px']} h="225px" ratio={1}>
          <iframe
            title="Sti3"
            src="https://www.youtube.com/embed/Rx2HPMppnxo?"
            allowFullScreen
          />
        </AspectRatio>
        <Box
          ml={['0', '0', '90px']}
          mb={['20px', '20px', '0']}
          w={['full', 'full', '320px']}
        >
          <Text
            letterSpacing="0"
            lineHeight={['', '', '40px']}
            fontSize={['25px', '25px', '32px']}
            color="gray.800"
          >
            O{' '}
            <span
              style={{
                fontWeight: 'bold',
              }}
            >
              aplicativo ideal
            </span>{' '}
            para que você tome as{' '}
            <span
              style={{
                fontWeight: 'bold',
              }}
            >
              melhores decisões
            </span>{' '}
            de forma rápida.
          </Text>
        </Box>
      </Flex>
      <Box mb="36px" mt="90px">
        <Text
          letterSpacing="0"
          fontSize={['25px', '25px', '32px']}
          color="primary.50"
          fontWeight="extrabold"
        >
          Principais recursos:
        </Text>
        <Text
          letterSpacing="0"
          lineHeight={['', '', '40px']}
          fontSize={['25px', '25px', '32px']}
          color="primary.50"
        >
          Para acompanhar processos, analisar situações
        </Text>
        <Text
          letterSpacing="0"
          lineHeight={['', '', '40px']}
          fontSize={['25px', '25px', '32px']}
          color="primary.50"
        >
          e identificar problemas de forma visual e eficiente.
        </Text>
      </Box>
      <Flex mb="100px" justifyContent="space-between" alignItems="center">
        <UnorderedList fontSize="20px" lineHeight="40px" color="gray.700">
          <ListItem>Melhores horários das suas vendas;</ListItem>
          <ListItem>Evolução do ticket médio da empresa;</ListItem>
          <ListItem>Totalizadores de vendas, acréscimos e descontos;</ListItem>
          <ListItem>Produtos mais vendidos;</ListItem>
          <ListItem>Movimentação por Dia da semana;</ListItem>
          <ListItem>Representatividade de cada forma de pagamento;</ListItem>
          <ListItem>Principais informações do Contas à Pagar;</ListItem>
          <ListItem>Resumo de informações do Contas à Receber;</ListItem>
          <ListItem>Desempenho dos vendedores;</ListItem>
          <ListItem>Situação do estoque (acima ou abaixo do mínimo);</ListItem>
          <ListItem>
            Valor atual do seu estoque (por preço de custo e de venda);
          </ListItem>
          <ListItem>Todas as lojas numa só tela;</ListItem>
        </UnorderedList>
        {isLargerThan1100 && (
          <Box>
            <Image w="353px" src={imagemDashboardVendas} />
          </Box>
        )}
      </Flex>
      <Box mb="54px">
        <Text
          letterSpacing="0"
          fontSize={['25px', '25px', '32px']}
          color="primary.50"
          fontWeight="extrabold"
        >
          Acesse de qualquer lugar:
        </Text>
        <Text
          lineHeight={['', '', '40px']}
          fontSize={['25px', '25px', '32px']}
          letterSpacing="0"
          color="primary.50"
        >
          É só ter um dispositivo com navegador e sinal de
        </Text>
        <Text
          letterSpacing="0"
          lineHeight={['', '', '40px']}
          fontSize={['25px', '25px', '32px']}
          color="primary.50"
        >
          internet para acessar todas as métricas em tempo real.
        </Text>
      </Box>
      <Grid
        mb="100px"
        templateColumns={
          isLargerThan1100
            ? 'repeat(4, 1fr)'
            : ['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)']
        }
      >
        <GridItem alignItems="center" w="100%">
          <Flex w="full" justifyContent="center">
            <Icon boxSize="200px" as={AppCelularDashboardGerencial} />
          </Flex>
          <Text textAlign="center" fontSize="20px" color="gray.700">
            Compatível com Android e iOS.
          </Text>
        </GridItem>
        <GridItem alignItems="center" w="100%">
          <Flex w="full" justifyContent="center">
            <Icon boxSize="200px" as={AppWebDashboardGerencial} />
          </Flex>
          <Text textAlign="center" fontSize="20px" color="gray.700">
            Acesso pelo navegador em qualquer dispositivo.
          </Text>
        </GridItem>
        <GridItem alignItems="center" w="100%">
          <Flex w="full" justifyContent="center">
            <Icon boxSize="200px" as={AppUsuarioDashboardGerencial} />
          </Flex>
          <Text textAlign="center" fontSize="20px" color="gray.700">
            Liberação por usuário, sem limite de dispositivos.
          </Text>
        </GridItem>
        <GridItem alignItems="center" w="100%">
          <Flex w="full" justifyContent="center">
            <Icon boxSize="200px" as={AppInfoLojaDashboardGerencial} />
          </Flex>
          <Text textAlign="center" fontSize="20px" color="gray.700">
            Analisa informações de uma loja ou de todas.
          </Text>
        </GridItem>
      </Grid>
      <Flex
        h={['auto', 'auto', '94px']}
        p="30px"
        borderRadius="10px"
        bg="secondary.200"
        fontSize="16px"
        justifyContent="center"
        alignItems="center"
      >
        <Box>
          <Text fontWeight="bold">
            O aplicativo Dashboard está disponível para todos os planos.{' '}
          </Text>
          <Text>
            Para saber sobre preço, contratação ou outros detalhes de
            performance, entre em contato.{' '}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};
