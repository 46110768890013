import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  ModalFooter,
  Box,
  useMediaQuery,
  Icon,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { TrocasIcon } from 'icons';
import { moneyMask } from 'helpers/format/fieldsMasks';

type ModalAvisoTrocaProps = {
  nome: string;
};

type ModalAvisoGerarTrocaProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalAvisoTrocaProps> & {
    nomeCliente: string;
    valorTotalTroca: number;
    handleGerarTroca: () => void;
  };

export const ModalAvisoGerarTroca = create<
  ModalAvisoGerarTrocaProps,
  ModalAvisoTrocaProps
>(
  ({
    onResolve,
    onReject,
    nomeCliente,
    valorTotalTroca,
    handleGerarTroca,
    ...rest
  }) => {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    return (
      <>
        <ModalPadraoChakra
          isCentered={isLargerThan900}
          size={isLargerThan900 ? '3xl' : 'full'}
          {...rest}
          closeOnOverlayClick={false}
          isOpen={isOpen}
          onClose={onClose}
          autoFocus={false}
        >
          <ModalContent
            bg="gray.50"
            borderRadius={isLargerThan900 ? '16px' : '0'}
            marginBottom={isLargerThan900 ? '3.75rem' : '0'}
            marginTop={isLargerThan900 ? '3.75rem' : '0'}
            w={isLargerThan900 ? '600px' : 'full'}
            h={isLargerThan900 ? '540px' : 'full'}
          >
            <ModalBody mb={isLargerThan900 ? '20px' : '20px'} p="0">
              <Flex
                borderRadius={isLargerThan900 ? '16px 16px 0 0' : '0'}
                color="white"
                justifyContent="center"
                alignItems="center"
                h="150px"
                bg="primary.50"
                mb="32px"
              >
                <Icon boxSize="70px" as={TrocasIcon} />
              </Flex>

              <Box borderRadius="5px" w="full" pl="23px" pr="23px">
                <Box mt="32px">
                  <Flex>
                    <Text fontWeight="bold" fontSize="20px" color="primary.50">
                      Devolução confirmada
                    </Text>
                  </Flex>
                  <Flex mt={isLargerThan900 ? '32px' : '20px'}>
                    <Text fontSize="16px">
                      Os produtos foram devolvidos e já estão liberados no seu
                      estoque. Para o valor da devolução foi criado um voucher
                      de crédito que será adicionado automaticamente como forma
                      de pagamento na troca.
                    </Text>
                  </Flex>
                  <Box mt="32px">
                    <Flex>
                      <Text mr="10px">Nome do cliente:</Text>
                      <Text fontWeight="bold">{nomeCliente}</Text>
                    </Flex>
                    <Flex mt="10px">
                      <Text mr="10px">Valor do voucher:</Text>
                      <Text fontWeight="bold">
                        {moneyMask(valorTotalTroca, true)}
                      </Text>
                    </Flex>
                  </Box>
                </Box>
              </Box>
            </ModalBody>
            <ModalFooter>
              <Flex
                w="full"
                h={isLargerThan900 ? 'full' : '200px'}
                justifyContent="center"
                alignItems="baseline"
                mb="27px"
              >
                <Button
                  id="gerarCredito"
                  name="gerarCredito"
                  color="black"
                  variant="solid"
                  bg="secondary.400"
                  borderRadius="20px"
                  fontSize="sm"
                  type="button"
                  h={isLargerThan900 ? '32px' : '40px'}
                  onClick={async () => {
                    onClose();
                    await handleGerarTroca();
                  }}
                  _hover={{ bg: 'secondary.400' }}
                  w={isLargerThan900 ? '140px' : 'full'}
                >
                  Continuar
                </Button>
              </Flex>
            </ModalFooter>
          </ModalContent>
        </ModalPadraoChakra>
      </>
    );
  }
);
