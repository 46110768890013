import { Flex, Grid } from '@chakra-ui/react';
import React from 'react';
import QRCode from 'qrcode.react';

interface ImpressaoInterface {
  qrCode?: string;
}

const ConsultaViaQrCode = ({ qrCode }: ImpressaoInterface) => {
  return (
    <Grid>
      {qrCode && (
        <Flex ml="3mm" mr="3mm" justifyContent="center">
          <QRCode size={100} value={qrCode} />
        </Flex>
      )}
    </Grid>
  );
};

export default ConsultaViaQrCode;
