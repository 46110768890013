import React, { useState } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Icon,
  ModalCloseButton,
  useMediaQuery,
  useDisclosure,
  ModalHeader,
  VStack,
  Text,
  ModalFooter,
  HStack,
  Button,
  Switch,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';

import { SalvarConfirmarIcon } from 'icons';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';

type Loja = {
  id: string;
  fantasia: string;
  endereco: string;
  cidade: string;
  selecionado: boolean;
};

type SelecionarLojasModalResponse = {
  lojasSelecionadas: string[];
};

type SelecionarLojasModalProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<SelecionarLojasModalResponse> & {
    subtitle: string;
    lojas: Loja[];
  };

export const SelecionarLojasModal = create<
  SelecionarLojasModalProps,
  SelecionarLojasModalResponse
>(({ onResolve, onReject, subtitle, lojas: defaultLojas, ...rest }) => {
  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const [lojas, setLojas] = useState<Loja[]>(defaultLojas);

  function handleCancel() {
    onReject();
  }

  function handleSubmit() {
    const lojasSelecionadas = lojas
      .filter((loja) => loja.selecionado)
      .map((loja) => loja.id);

    onResolve({
      lojasSelecionadas,
    });
  }

  function handleToggleLojaSelecionada(index: number) {
    const newLojas = [...lojas];

    newLojas.splice(index, 1, {
      ...newLojas[index],
      selecionado: !newLojas[index].selecionado,
    });

    setLojas(newLojas);
  }

  return (
    <ModalPadraoChakra
      isCentered
      size={!isSmallerThan900 ? 'xl' : 'full'}
      {...rest}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalContent
        marginBottom={{ base: 0, md: '3.75rem' }}
        marginTop={{ base: 0, md: '3.75rem' }}
        h="unset"
        maxW={{ base: '100%', md: '600px' }}
      >
        <ModalHeader
          mt={isSmallerThan900 ? 12 : undefined}
          mb={isSmallerThan900 ? 8 : undefined}
          borderBottom="1px"
          borderColor="gray.100"
          px="0"
          mx={{ base: 6, md: 8 }}
        >
          <VStack alignItems="flex-start" spacing="1" lineHeight="1">
            <Text color="primary.500" fontSize={{ base: 'xl', md: 'md' }}>
              Selecionar lojas
            </Text>
            <Text color="gray.400" fontSize={{ base: 'sm', md: 'xs' }}>
              {subtitle}
            </Text>
          </VStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p={{ base: 6, md: 8 }}>
          <VStack spacing="6" alignItems="flex-start">
            {lojas.map((loja, index) => (
              <HStack
                key={loja.id}
                as="label"
                mb="0 !important"
                cursor="pointer"
                userSelect="none"
                spacing="6"
                alignItems="center"
              >
                <Switch
                  isChecked={loja.selecionado}
                  onChange={() => {
                    handleToggleLojaSelecionada(index);
                  }}
                  mb="0 !important"
                />
                <VStack spacing="1" lineHeight="none" alignItems="flex-start">
                  <Text fontSize="sm" fontWeight="bold">
                    {`${loja.fantasia}${loja.cidade && ` | ${loja.cidade}`}`}
                  </Text>
                  <Text fontSize="xs" color="gray.300">
                    {loja.endereco}
                  </Text>
                </VStack>
              </HStack>
            ))}
          </VStack>
        </ModalBody>
        <ModalFooter
          justifyContent="flex-end"
          borderTop="1px"
          borderColor="gray.100"
          mx={{ base: 6, md: 8 }}
          px="0"
        >
          <HStack spacing={6}>
            <Button
              borderRadius="md"
              variant="outline"
              minW="100px"
              color="gray.400"
              onClick={handleCancel}
            >
              Cancelar
            </Button>
            <Button
              borderRadius="md"
              colorScheme="aquamarine"
              minW="120px"
              leftIcon={<Icon as={SalvarConfirmarIcon} fontSize="lg" />}
              onClick={handleSubmit}
            >
              Confirmar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </ModalPadraoChakra>
  );
});
