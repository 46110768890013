/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import ReactSelect, { NamedProps } from 'react-select';
import { useTheme } from '@chakra-ui/react';
import OptionType from 'types/optionType';

import { useFullScreenContext } from 'store/FullScreen';

import SelectHighlighter from 'components/PDV/SelectHighlighter';
import {
  chakraComponents,
  chakraStyles,
  ChakraReactSelectContainer,
} from '../../ReactSelectIntegracao';

type Options = OptionType[];

export const getValue = (value: unknown, isMulti: boolean): any => {
  if (value === null || value === undefined) {
    if (isMulti) {
      return [];
    }

    return null;
  }

  return value;
};

export const findOptions = (
  selectedValues: Array<unknown>,
  options: Options
): Options => {
  if (options.length === 0) {
    return [];
  }

  if (selectedValues.length === 0) {
    return [];
  }

  return selectedValues.map((selectedValue) => {
    const result =
      options.find((option) => option.value === selectedValue) ?? undefined;

    if (result === undefined) {
      return { label: selectedValue as string, value: selectedValue };
    }

    return result;
  });
};

export const findOption = (
  selectedValue: unknown,
  options: Options,
  shouldAppearTheAddress = false
): OptionType | null => {
  if (selectedValue === '' || selectedValue === -1) {
    return null;
  }

  if (typeof selectedValue === 'object' && selectedValue !== null) {
    throw new Error(`FormSelect: incorrect value type`);
  }

  const option = options
    ? options.find((o) => {
        if (typeof o.value === 'object' && o.value !== null) {
          throw new Error(`FormSelect: incorrect value type`);
        }
        return o.value === selectedValue;
      })
    : undefined;

  if (!option && !selectedValue) {
    return null;
  }

  if (shouldAppearTheAddress) {
    const splittedString = option?.label.indexOf('(');
    const valueReturned = option?.label.slice(4, splittedString);

    return { label: valueReturned || '', value: selectedValue || '' };
  }

  return option || { label: selectedValue as string, value: selectedValue };
};

export const getReadValue = (
  value: unknown,
  options: Options,
  isMulti: boolean
) => {
  if (isMulti) {
    return findOptions(getValue(value, isMulti), options)
      .map((opt) => opt.label)
      .join(', ');
  }
  const option = findOption(getValue(value, isMulti), options) || { label: '' };
  return option.label;
};

export type SelectInputProps = NamedProps<OptionType> & {
  options: OptionType[];
  variant?: string;
  size?: string;
  colorScheme?: string;
  selectRef?: React.LegacyRef<ReactSelect<OptionType>>;
  onClick?: () => void;
  multiValueBg?: string;
  shouldAppearTheAddress?: boolean;
  renderOptionDetails?: (data: any) => JSX.Element | null;
};

const SelectInput = ({
  id,
  isClearable = false,
  placeholder = '',
  noOptionsMessage = () => 'Não há valores disponíveis',
  loadingMessage = () => 'Carregando...',
  variant,
  size,
  colorScheme,
  components,
  styles,
  isDisabled,
  selectRef,
  onClick,
  multiValueBg,
  isMulti,
  shouldAppearTheAddress,
  renderOptionDetails,
  ...props
}: SelectInputProps) => {
  const { colors, radii } = useTheme();

  const { handleFullScreen } = useFullScreenContext();

  const placeholderColor = colors.gray[400];

  return (
    <ChakraReactSelectContainer
      variant={variant}
      size={size}
      colorScheme={colorScheme}
      isDisabled={isDisabled}
      isMulti={isMulti}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
          if (onClick) onClick();
        }}
        onTouchEnd={(e) => {
          e.stopPropagation();
          if (onClick) onClick();
        }}
      >
        <ReactSelect
          isMulti={isMulti}
          isClearable={isClearable}
          isDisabled={isDisabled}
          placeholder={placeholder}
          noOptionsMessage={noOptionsMessage}
          loadingMessage={loadingMessage}
          className="react-select-container"
          classNamePrefix="react-select"
          inputId={id}
          id={`select-container-${id}`}
          menuPortalTarget={handleFullScreen.active ? null : document.body}
          components={{ ...chakraComponents, ...components }}
          styles={{ ...chakraStyles(undefined, multiValueBg), ...styles }}
          theme={(baseTheme) => ({
            ...baseTheme,
            borderRadius: radii.md,
            colors: {
              ...baseTheme.colors,
              neutral50: placeholderColor, // placeholder text color
              neutral40: placeholderColor, // noOptionsMessage color
            },
            spacing: {
              ...baseTheme.spacing,
            },
          })}
          menuPlacement="auto"
          formatOptionLabel={SelectHighlighter}
          {...props}
          ref={selectRef}
          shouldAppearTheAddress={shouldAppearTheAddress}
          renderOptionDetails={renderOptionDetails}
        />
      </div>
    </ChakraReactSelectContainer>
  );
};

export default SelectInput;
