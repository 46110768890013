import { IconTrayApp } from 'icons';

import { Container } from '../../Components/Layout/Container';
import { useTrayData } from './hooks';

export const DetalhesIntegracaoTray = () => {
  const {
    trayDateActivated,
    listItemsInfo,
    handleCancel,
    isLoading,
  } = useTrayData();

  return (
    <Container
      listItemsInfo={listItemsInfo}
      isLoading={isLoading}
      widthIcon={150}
      heightIcon={150}
      handleCancel={handleCancel}
      icon={IconTrayApp}
      date={trayDateActivated}
    />
  );
};
