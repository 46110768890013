import { Route } from 'react-router-dom';

import VendasFiltroProvider from 'store/Vendas/VendasFiltros';

import { ListarVouchers } from 'pages/Vendas/Vouchers';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

const VouchersRoutes = [
  <VendasFiltroProvider>
    <Route path={ConstanteRotas.LISTAR_VOUCHERS}>
      <LayoutGuard
        key="vendas-vouchers"
        permissaoFuncionalidade={ConstanteFuncionalidades.LISTAR_VOUCHERS}
        component={ListarVouchers}
        breadcrumb={[
          {
            title: 'Vendas',
          },
          {
            title: 'Vouchers',
          },
        ]}
        path={ConstanteRotas.LISTAR_VOUCHERS}
        exact
        meta={{ auth: true }}
      />
    </Route>
  </VendasFiltroProvider>,
];

export default VouchersRoutes;
