import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  ModalContent,
  ModalBody,
  Icon,
  Flex,
  ModalCloseButton,
  useMediaQuery,
  useDisclosure,
  ModalHeader,
  VStack,
  Text,
  ModalFooter,
  HStack,
  Button,
  GridItem,
  IconButton,
} from '@chakra-ui/react';
import { create } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';

import { SalvarInserirNovoIcon } from 'icons';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import Select from 'components/PDV/Select/SelectPadrao';
import { PaginationData } from 'components/update/Pagination';
import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import { NumberInput } from 'components/update/Input/NumberInput';
import { SelectVirtualized } from 'components/PDV/Select/SelectVirtualized';

import { ModalGradeTamanhos } from '../ModalGradeTamanhos';
import { yupResolver, FormData, ProdutoCor } from './validationForm';
import {
  ModalAdicionarProdutoProps,
  ModalAdicionarProdutoResponse,
  ProdutoCorResponse,
  TamanhosResponse,
} from './types';

export const ModalAdicionarProduto = create<
  ModalAdicionarProdutoProps,
  ModalAdicionarProdutoResponse
>(({ onResolve, onReject, casasDecimaisQuantidade, ...rest }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [autoFocus, setAutoFocus] = useState(true);

  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues: { quantidade: 1 },
  });

  const {
    clearErrors,
    watch,
    setValue,
    setFocus,
    handleSubmit: handleSubmitForm,
  } = formMethods;

  const productSelected: ProdutoCor = watch('produtoCor');
  const productSizeOptions = (productSelected?.tamanhos || []).map(
    (tamanho) => {
      return {
        value: tamanho.produtoCorTamanhoId,
        label: tamanho.tamanho,
        padraoSistema: tamanho.padraoSistema,
      };
    }
  );
  const hasMultipleSizes = productSizeOptions.length > 1;

  const handleSubmit = handleSubmitForm(async (data) => {
    setIsLoading(true);
    if (data.quantidade === 0) {
      toast.warning('A quantidade não pode estar zerada');
    } else {
      const responseData = {
        produtoNome: data.produtoCor.produtoNome,
        corDescricao: data.produtoCor.corDescricao,
        tamanhoDescricao: data.produtoCorTamanho?.padraoSistema
          ? ''
          : data.produtoCorTamanho?.label,
        produtoCorTamanhoId: data.produtoCorTamanho?.value || '',
        quantidade: data.quantidade,
      };

      onResolve({ produtos: [responseData] });
    }
    setIsLoading(false);
  });

  const handleSubmitReset = useCallback(
    formMethods.handleSubmit(async (data) => {
      setIsLoading(true);

      if (data.quantidade === 0) {
        toast.warning('A quantidade não pode estar zerada');
      } else {
        const responseData = {
          produtoNome: data.produtoCor.produtoNome,
          corDescricao: data.produtoCor.corDescricao,
          tamanhoDescricao: data.produtoCorTamanho?.padraoSistema
            ? ''
            : data.produtoCorTamanho?.label,
          produtoCorTamanhoId: data.produtoCorTamanho?.value || '',
          quantidade: data.quantidade,
        };

        onResolve({ deveReiniciar: true, produtos: [responseData] });
      }
      setIsLoading(false);
    }),
    [formMethods, onResolve]
  );

  async function handlePushGradeModal() {
    setAutoFocus(false);
    const addedTamanhos = await ModalGradeTamanhos({
      produtoNome: productSelected.produtoNome,
      corDescricao: productSelected.corDescricao,
      casasDecimaisQuantidade,
      volumeUnitario: productSelected.volumeUnitario,
      tamanhos: productSelected?.tamanhos || [],
      inserirNovoTamanho: true,
    });

    if (addedTamanhos) {
      onResolve({
        produtos: addedTamanhos,
        deveReiniciar: addedTamanhos[0].adicionarNovamenteProduto,
      });
    }
  }

  const getProductSizes = useCallback(async (produto: { value: string }) => {
    if (produto) {
      const response = await api.get<void, ResponseApi<TamanhosResponse[]>>(
        ConstanteEnderecoWebservice.PRODUTO_COR_TAMANHO_OBTER_TAMANHOS,
        {
          params: { produtoCorId: produto.value },
        }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response.sucesso && response.dados) {
          return response.dados?.map((tamanho) => ({
            produtoCorTamanhoId: tamanho.id,
            tamanho: tamanho.nome,
            padraoSistema: tamanho.padraoSistema,
            sku: tamanho.sku,
            codigoGTINEAN: tamanho.codigoGTINEAN,
            codigoExterno: tamanho.codigoExterno,
            codigoBarrasInterno: tamanho.codigoBarrasInterno,
            sequenciaCodigoBarras: tamanho.sequenciaCodigoBarras,
          }));
        }
      }
      return [];
    }
    return [];
  }, []);

  const autoAddProduct = useCallback(async () => {
    await handleSubmitReset();
  }, [handleSubmitReset]);

  const loadProductColorOptions = useCallback(
    async (inputValue: string, dataPagination: PaginationData) => {
      setIsLoading(true);

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<ProdutoCorResponse>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.PRODUTO_COR_LISTAR_PAGINADO,
          dataPagination
        ),
        {
          params: {
            nome: inputValue,
          },
        }
      );

      if (response?.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }

      if (
        response &&
        response.sucesso &&
        response?.dados &&
        response.dados?.registros
      ) {
        const data = response?.dados?.registros.map((option) => {
          const nameAndColor = option.descricao.split('|');
          const productName = nameAndColor[0].trim();
          const productColor = nameAndColor
            ? nameAndColor[1]
              ? nameAndColor[1].trim()
              : ''
            : '';

          return {
            label: option.descricao,
            value: option.id,
            produtoNome: productName,
            corDescricao: productColor,
            volumeUnitario: option.volumeUnitario,
          };
        });

        setTotalRegistros(response.dados.total || 0);

        if (data.length === 1) {
          const productToAddWithoutSizes = data[0];
          const tamanhos = await getProductSizes(productToAddWithoutSizes);
          const filteredSize = tamanhos?.filter((tamanho) => {
            if (
              tamanho.codigoBarrasInterno === inputValue ||
              tamanho.codigoGTINEAN === inputValue ||
              tamanho.codigoExterno === inputValue ||
              tamanho.sku === inputValue ||
              tamanho.sequenciaCodigoBarras === inputValue
            ) {
              return tamanho;
            }
            return null;
          });
          const updatedProduct = {
            label: productToAddWithoutSizes.label,
            value: productToAddWithoutSizes.value,
            produtoNome: productToAddWithoutSizes.produtoNome,
            corDescricao: productToAddWithoutSizes.corDescricao,
            volumeUnitario: productToAddWithoutSizes.volumeUnitario,
            tamanhos,
          };

          setValue('produtoCor', updatedProduct);
          setValue('quantidade', 1);

          if (filteredSize?.length > 0) {
            setValue('produtoCorTamanho', {
              label: filteredSize[0].tamanho,
              value: filteredSize[0].produtoCorTamanhoId,
              padraoSistema: filteredSize[0].padraoSistema,
            });
            await autoAddProduct();
          } else if (tamanhos?.length > 0) {
            setValue('produtoCorTamanho', {
              label: tamanhos[0].tamanho,
              value: tamanhos[0].produtoCorTamanhoId,
              padraoSistema: tamanhos[0].padraoSistema,
            });
          }
          setIsLoading(false);
          return data;
        }

        setIsLoading(false);

        return data;
      }

      setTotalRegistros(0);
      setIsLoading(false);
      return [];
    },
    [autoAddProduct, getProductSizes, setValue]
  );

  const handleAddProductInList = useCallback(
    async (produto: ProdutoCor) => {
      if (produto) {
        const tamanhos = await getProductSizes(produto);
        const produtoParaAdicionar = {
          label: produto.label,
          value: produto.value,
          produtoNome: produto.produtoNome,
          corDescricao: produto.corDescricao,
          volumeUnitario: produto.volumeUnitario,
          tamanhos,
        };
        setValue('produtoCor', produtoParaAdicionar);
        setValue(
          'produtoCorTamanho',
          produtoParaAdicionar?.tamanhos?.length === 1
            ? {
                label: produtoParaAdicionar.tamanhos[0].tamanho,
                value: produtoParaAdicionar.tamanhos[0].produtoCorTamanhoId,
                padraoSistema: produtoParaAdicionar.tamanhos[0].padraoSistema,
              }
            : null
        );
      }
    },
    [getProductSizes, setValue]
  );

  const latestProps = useRef({
    setValue,
    setFocus,
    clearErrors,
    productSizeOptions,
  });

  useEffect(() => {
    latestProps.current = {
      setValue,
      clearErrors,
      productSizeOptions,
      setFocus,
    };
  });

  useEffect(() => {
    if (
      latestProps.current.productSizeOptions &&
      latestProps.current.productSizeOptions.length > 0 &&
      !latestProps.current.productSizeOptions[0].padraoSistema
    ) {
      latestProps?.current?.setFocus('produtoCorTamanho');
    } else if (
      productSelected &&
      latestProps.current.productSizeOptions.length > 0 &&
      latestProps.current.productSizeOptions[0].padraoSistema
    ) {
      latestProps.current.setFocus('quantidade');
    }
  }, [productSelected]);

  return (
    <ModalPadraoChakra
      isCentered
      size={!isSmallerThan900 ? 'xl' : 'full'}
      {...rest}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalContent
        marginBottom={{ base: 0, md: '3.75rem' }}
        marginTop={{ base: 0, md: '3.75rem' }}
        h="unset"
        maxW={{ base: '100%', md: '600px' }}
      >
        {isLoading && <LoadingPadrao />}
        <ModalHeader
          mt={isSmallerThan900 ? 12 : undefined}
          mb={isSmallerThan900 ? 8 : undefined}
          borderBottom="1px"
          borderColor="gray.100"
          px="0"
          mx={{ base: 6, md: 8 }}
        >
          <VStack alignItems="flex-start" spacing="1" lineHeight="1">
            <Text color="primary.500" fontSize={{ base: 'xl', md: 'md' }}>
              Adicionar itens
            </Text>
            <Text color="gray.400" fontSize={{ base: 'sm', md: 'xs' }}>
              Clique em “confirmar” para adicionar um único item ou “inserir
              novo” para vários.
            </Text>
          </VStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p={{ base: 6, md: 8 }}>
          <FormProvider {...formMethods}>
            <Flex flexDirection="column" h="full">
              <SimpleGridForm>
                <GridItem colSpan={12}>
                  <SelectVirtualized
                    id="produtoCor"
                    name="produtoCor"
                    label="Descrição do produto"
                    placeholder="Digite o nome do produto e selecione a cor"
                    required
                    autoFocus={autoFocus}
                    helperText="A pesquisa de produto considera os campos Status, Descrição, Código SKU, GTIN/EAN, Código externo e Código de barras interno."
                    isLoading={isLoading}
                    onSelect={handleAddProductInList}
                    handleGetOptions={loadProductColorOptions}
                    asControlledByObject
                    totalRegistros={totalRegistros}
                    withoutDefaultOptions
                  />
                </GridItem>
                {productSizeOptions &&
                  productSizeOptions.length > 0 &&
                  !productSizeOptions[0].padraoSistema && (
                    <GridItem colSpan={{ base: 12, md: 7 }}>
                      <HStack alignItems="end">
                        <Select
                          id="produtoCorTamanho"
                          name="produtoCorTamanho"
                          label="Tamanho"
                          placeholder="Selecionar"
                          required
                          isDisabled={!productSelected || !hasMultipleSizes}
                          options={productSizeOptions}
                          asControlledByObject
                        />

                        <IconButton
                          aria-label="Selecionar grade de tamanhos"
                          icon={<Icon as={SalvarInserirNovoIcon} />}
                          isDisabled={!productSelected || !hasMultipleSizes}
                          colorScheme="whiteAlpha"
                          color="gray.800"
                          border="1px"
                          borderRadius="md"
                          borderColor="gray.200"
                          onClick={handlePushGradeModal}
                        />
                      </HStack>
                    </GridItem>
                  )}
                <GridItem colSpan={{ base: 12, md: 5 }}>
                  <NumberInput
                    id="quantidade"
                    name="quantidade"
                    label="Quantidade"
                    placeholder={
                      productSelected?.volumeUnitario
                        ? '0'
                        : `0,${'0'.repeat(casasDecimaisQuantidade)}`
                    }
                    scale={
                      productSelected && productSelected.volumeUnitario
                        ? 0
                        : casasDecimaisQuantidade
                    }
                    isDisabled={!productSelected}
                  />
                </GridItem>
              </SimpleGridForm>
            </Flex>
          </FormProvider>
        </ModalBody>
        <ModalFooter
          justifyContent="flex-end"
          borderTop="1px"
          borderColor="gray.100"
          mx={{ base: 6, md: 8 }}
          px="0"
        >
          <HStack spacing={6}>
            <Button
              borderRadius="md"
              color="gray.400"
              variant="outline"
              minW="225px"
              leftIcon={<Icon as={SalvarInserirNovoIcon} fontSize="lg" />}
              onClick={handleSubmitReset}
            >
              Confirmar e inserir novo
            </Button>
            <Button
              colorScheme="aquamarine"
              borderRadius="md"
              minW="100px"
              _focus={{
                boxShadow: '0 0 0 2px rgba(57, 0, 115, 1)',
              }}
              onClick={handleSubmit}
            >
              Confirmar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </ModalPadraoChakra>
  );
});
