import React, { useCallback, useRef, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Box, GridItem, Icon, Td, Tr, Text } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import ConstanteFuncionalidades from 'constants/permissoes';
import { shallowEqual } from 'helpers/validation/shallowEqual';
import { setDateMinHours, setDateMaxHours } from 'helpers/data/setHoursDate';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import auth from 'modules/auth';
import formatUTCToLocateDateTime from 'helpers/format/formatUTCToLocateDateTime';
import { getName } from 'helpers/enum/getName';
import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';
import StatusOperacaoEnum from 'constants/enum/statusOperacao';
import { useMovimentacaoEstoqueFiltrosContext } from 'store/MovimentacaoEstoque/MovimentacaoEstoqueFiltros';
import { formatDateHourMinute } from 'helpers/format/formatStringDate';
import ModelosFiscaisEnum from 'constants/enum/fiscal/modelosFiscais';

import InputDateRange from 'components/PDV/InputDateRange';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { SalvarInserirNovoIcon } from 'icons';
import { ButtonFuncionalidade } from 'components/update/Button/ButtonFuncionalidade';
import {
  PagedTable,
  PagedTableForwardRefData,
} from 'components/update/Table/PagedTable';
import {
  ActionsMenu,
  ActionMenuItem,
} from 'components/update/Table/ActionsMenu';
import { PaginationData } from 'components/update/Pagination';
import { BuscaAvancadaButton } from 'components/update/BuscaAvancadaButton';
import { Link } from 'components/update/Link';
import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import ModalMotivoCancelamento from 'components/PDV/Modal/ModalMotivoCancelamento';
import ModalConfirmacao from 'components/PDV/Modal/ModalConfirmacao';
import { ModalImpressaoEtiquetaPersonalizada } from 'components/Modal/ModalImpressaoEtiquetaPersonalizada';

import { formDefaultValues, FormData } from './validationForm';
import ModalBuscaAvancadaEstoque from './ModalBuscaAvancada';

interface ModeloNumeroFiscalProps {
  modeloFiscal: number;
  numeroFiscal: string;
}

type ListaRegistros = {
  operacaoId: string;
  dataEmissao: Date;
  identificacaoTipoOperacao: number;
  numeroOperacao: number | null;
  localEstoqueNome: string;
  usuarioNome: string;
  statusOperacao: number;
  modeloNumeroFiscais: ModeloNumeroFiscalProps[];
  quantidade: number;
};

interface CancelarOperacaoProps {
  operacaoId: string;
  motivoCancelamento?: string;
  motivoCancelamentoNotaFiscal?: string;
  dataEmissao: string;
}

interface ClienteProps {
  label: string;
  value: string;
  cliente: {
    id: string;
    descricao: string;
    descricaoEnderecoEntrega: string;
    descricaoEnderecoCobranca: string;
    estadoId: number;
    indicadorIe: number;
    regraLimiteCredito: number;
  };
}

interface BasesLegaisProps {
  id: string;
  descricao: string;
  ativo: boolean;
}

export interface InformacoesOperacaoProps {
  lojaId: string;
  operacaoId: string;
  clienteId: string;
  cliente: ClienteProps;
  valorProdutos: number;
  valorTotal: number;
  naturezaOperacao: string;
  basesLegais: BasesLegaisProps[];
  identificacaoTipoOperacao: number;
}

const MovimentacaoEstoqueListar = () => {
  const {
    currentFilters,
    setCurrentFilters,
  } = useMovimentacaoEstoqueFiltrosContext();

  const { id: lojaId } = auth.getLoja();
  const formMethods = useForm({
    defaultValues: currentFilters,
  });

  const history = useHistory();
  const emptyField = '---';

  const [idOperacao, setIdOperacao] = useState<string>();
  const [hasNotaFiscal, setHasNotaFiscal] = useState(false);
  const [hasFilters, setHasFilters] = useState(() => {
    const isEqual = shallowEqual(currentFilters, formDefaultValues(lojaId));

    return !isEqual;
  });
  const [itemsTotalCount, setItemsTotalCount] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [isModalBuscaAvancadaOpen, setIsModalBuscaAvancadaOpen] = useState(
    false
  );

  const [columnsData, setColumnsData] = useState<ListaRegistros[]>([]);

  const [
    modalConfirmacaoCancelarIsOpen,
    setModalConfirmacaoCancelarIsOpen,
  ] = useState(false);

  const [
    modalMotivoCancelamentoIsOpen,
    setModalMotivoCancelamentoIsOpen,
  ] = useState(false);

  const motivoCancelamento = useRef<string>();
  const motivoCancelamentoNotaFiscal = useRef<string>();
  const pagedTableRef = useRef<PagedTableForwardRefData>(null);

  const permissaoMovimentacaoEstoqueVisualizar = auth.possuiPermissao(
    ConstanteFuncionalidades.MOVIMENTACAO_ESTOQUE_VISUALIZAR
  );

  const permissaoMovimentacaoEstoqueAlterar = auth.possuiPermissao(
    ConstanteFuncionalidades.MOVIMENTACAO_ESTOQUE_ALTERAR
  );

  const permissaoMovimentacaoEstoqueCancelar = auth.possuiPermissao(
    ConstanteFuncionalidades.MOVIMENTACAO_ESTOQUE_CANCELAR
  );

  const permissaoMovimentacaoEstoqueDuplicar = auth.possuiPermissao(
    ConstanteFuncionalidades.MOVIMENTACAO_ESTOQUE_DUPLICAR
  );

  const handleReset = () => {
    setCurrentFilters(formDefaultValues(lojaId));
    setHasFilters(false);
    formMethods.reset(formDefaultValues(lojaId));
  };

  const filtersSubmit = formMethods.handleSubmit((data) => {
    const filtersIsDirty = !shallowEqual(data, currentFilters);

    if (filtersIsDirty) {
      setCurrentFilters(data);
      setHasFilters(true);
    }
  });

  function handleSubmitModalBuscaAvancada(data: FormData) {
    formMethods.reset(data);
    filtersSubmit();
  }

  const loadColumnsData = useCallback(
    async (paginationData: PaginationData) => {
      setIsLoading(true);

      const params = {
        lojaId: currentFilters.lojaId,
        localEstoqueId: currentFilters.localEstoqueId || '',
        status: currentFilters.status || '',
        identificacaoTipoOperacao:
          currentFilters.identificacaoTipoOperacao || '',
        numeroOperacao: currentFilters.numeroOperacao
          ? Number(currentFilters.numeroOperacao)
          : '',
        dataEmissaoInicio: setDateMinHours(
          currentFilters.dataEmissaoInicio
        )?.toISOString(),
        dataEmissaoFim: setDateMaxHours(
          currentFilters.dataEmissaoFim
        )?.toISOString(),
      };

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<ListaRegistros>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.MOVIMENTACAO_ESTOQUE_LISTAR_PAGINADO,
          paginationData,
          params
        )
      );

      if (response) {
        if (response.sucesso) {
          setColumnsData(response.dados.registros || []);

          setItemsTotalCount(response.dados.total || 0);
        }
      }

      setIsLoading(false);
    },
    [currentFilters]
  );

  function handlePushAlterar(operacaoId: string) {
    history.push(
      SubstituirParametroRota(
        ConstanteRotas.MOVIMENTACAO_ESTOQUE_ALTERAR,
        'id',
        operacaoId
      )
    );
  }

  const cancelarMovimentacaoEstoque = async (
    movimentacaoEstoqueCancelar: CancelarOperacaoProps
  ) => {
    const response = await api.post<void, ResponseApi>(
      ConstanteEnderecoWebservice.MOVIMENTACAO_ESTOQUE_CANCELAR,
      { ...movimentacaoEstoqueCancelar }
    );

    if (response?.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (response?.sucesso) {
      return true;
    }

    return false;
  };

  const handleCancelarMovimentacaoEstoque = async () => {
    setIsLoading(true);

    const cancelarSuccess = await cancelarMovimentacaoEstoque({
      operacaoId: idOperacao || '',
      motivoCancelamento: motivoCancelamento.current,
      motivoCancelamentoNotaFiscal: motivoCancelamentoNotaFiscal.current,
      dataEmissao: formatUTCToLocateDateTime(String(new Date())),
    });

    if (cancelarSuccess) {
      pagedTableRef.current?.reload();
    }

    setIsLoading(false);
  };

  const handleCancelar = () => {
    if (idOperacao && permissaoMovimentacaoEstoqueCancelar.permitido) {
      setModalMotivoCancelamentoIsOpen(true);
    }
  };

  const handleConfirmarMotivoCancelamento = (
    motivo: string,
    motivoNotaFiscal?: string
  ) => {
    motivoCancelamento.current = motivo;
    motivoCancelamentoNotaFiscal.current = motivoNotaFiscal;

    setModalMotivoCancelamentoIsOpen(false);
    handleCancelarMovimentacaoEstoque();
  };

  const duplicarMovimentacaoEstoque = async (
    id: string
  ): Promise<string | undefined> => {
    const response = await api.post<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.MOVIMENTACAO_ESTOQUE_DUPLICAR,
      null,
      { params: { operacaoId: id } }
    );

    if (response?.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (response?.sucesso) {
      return response.dados;
    }

    return undefined;
  };

  function getVisualizarHref(id: string, identificadorTipoOperacao: number) {
    let href = ConstanteRotas.MOVIMENTACAO_ESTOQUE;

    if (
      identificadorTipoOperacao ===
        IdentificacaoTipoOperacaoEnum.CONFERENCIA_ESTOQUE_ENTRADA ||
      identificadorTipoOperacao ===
        IdentificacaoTipoOperacaoEnum.CONFERENCIA_ESTOQUE_SAIDA
    ) {
      href = SubstituirParametroRota(
        ConstanteRotas.MOVIMENTACAO_ESTOQUE_VISUALIZAR,
        'id',
        id
      );
    } else if (permissaoMovimentacaoEstoqueAlterar.permitido) {
      href = SubstituirParametroRota(
        ConstanteRotas.MOVIMENTACAO_ESTOQUE_ALTERAR,
        'id',
        id
      );
    } else if (permissaoMovimentacaoEstoqueVisualizar.permitido) {
      href = SubstituirParametroRota(
        ConstanteRotas.MOVIMENTACAO_ESTOQUE_VISUALIZAR,
        'id',
        id
      );
    }

    return href;
  }

  const handleDuplicarMovimentacaoEstoque = async (id: string) => {
    setIsLoading(true);

    const retorno = await duplicarMovimentacaoEstoque(id || '');

    if (retorno && permissaoMovimentacaoEstoqueDuplicar.permitido) {
      history.push(
        SubstituirParametroRota(
          ConstanteRotas.MOVIMENTACAO_ESTOQUE_ALTERAR,
          'id',
          retorno
        )
      );

      return;
    }

    setIsLoading(false);
  };

  const handleEmitirNotaFiscal = async (operacaoId: string) => {
    const response = await api.get<void, ResponseApi<InformacoesOperacaoProps>>(
      ConstanteEnderecoWebservice.NOTA_FISCAL_OBTER_INFORMACOES_OPERACAO,
      {
        params: { id: operacaoId },
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
        return;
      }

      if (response.sucesso) {
        history.push(ConstanteRotas.NOTA_FISCAL_CADASTRAR, {
          dadosOperacaoEmitirNotaFiscal: response.dados,
        });
      }
    }
  };

  function handlePushCadastrarNovoMovimentacaoEstoque() {
    history.push(ConstanteRotas.MOVIMENTACAO_ESTOQUE_CADASTRAR);
  }

  return (
    <>
      <SimpleGridForm>
        <FormProvider {...formMethods}>
          <GridItem colSpan={{ base: 12, lg: 2 }}>
            <Box maxW={{ base: 'full' }}>
              <InputDateRange
                borderColor="gray.100"
                borderRadius="md"
                name="data"
                startDateName="dataEmissaoInicio"
                endDateName="dataEmissaoFim"
                maxDate={new Date()}
                onConfirm={() => filtersSubmit()}
              />
            </Box>
          </GridItem>
          <GridItem colSpan={{ base: 12, sm: 6, lg: 6 }}>
            <Box maxW={{ base: 'full', sm: '240px' }}>
              <BuscaAvancadaButton
                setIsModalBuscaAvancadaOpen={setIsModalBuscaAvancadaOpen}
                hasFilters={hasFilters}
              />
            </Box>
          </GridItem>
          <GridItem
            colSpan={{ base: 12, sm: 6, lg: 4 }}
            display="flex"
            justifyContent={{ base: 'flex-start', sm: 'flex-end' }}
          >
            <ButtonFuncionalidade
              funcionalidade={
                ConstanteFuncionalidades.MOVIMENTACAO_ESTOQUE_CADASTRAR
              }
              colorScheme="secondary"
              minWidth={{ base: 'full', sm: '220px' }}
              borderRadius="5px"
              style={{
                height: '42px',
              }}
              leftIcon={<Icon as={SalvarInserirNovoIcon} fontSize="lg" />}
              isDisabled={isLoading}
              onClick={handlePushCadastrarNovoMovimentacaoEstoque}
            >
              Cadastrar Novo
            </ButtonFuncionalidade>
          </GridItem>
        </FormProvider>
        <GridItem colSpan={12}>
          <PagedTable
            ref={pagedTableRef}
            isLoading={isLoading}
            loadColumnsData={loadColumnsData}
            itemsTotalCount={itemsTotalCount}
            defaultKeyOrdered="dataEmissao"
            defaultOrderDirection="desc"
            tableHeaders={[
              { key: 'dataEmissao', content: 'Data/Hora', w: '200px' },
              {
                key: 'operacao',
                content: 'Operação',
                isOrderable: false,
                w: '200px',
              },
              { key: 'NumeroOperacao', content: 'Identificador', w: '150px' },
              { key: 'estoque', content: 'Estoque', isOrderable: false },
              { key: 'usuario', content: 'Usuário', isOrderable: false },
              {
                key: 'situacao',
                content: 'Situação',
                isOrderable: false,
                w: '150px',
              },
              {
                key: 'quantidade',
                content: 'Total de itens',
                isOrderable: false,
                w: '150px',
              },
              { key: 'acoes', content: 'Ações', isOrderable: false, w: '1px' },
            ]}
            renderTableRows={
              columnsData &&
              columnsData.map((columnData) => {
                let dropDownItens: ActionMenuItem[];
                if (
                  (columnData.identificacaoTipoOperacao ===
                    IdentificacaoTipoOperacaoEnum.CONFERENCIA_ESTOQUE_ENTRADA ||
                    columnData.identificacaoTipoOperacao ===
                      IdentificacaoTipoOperacaoEnum.CONFERENCIA_ESTOQUE_SAIDA) &&
                  columnData.statusOperacao !== StatusOperacaoEnum.CANCELADA
                ) {
                  dropDownItens = [
                    {
                      content: 'Cancelar',
                      onClick: () => {
                        setModalConfirmacaoCancelarIsOpen(true);
                        setIdOperacao(columnData.operacaoId);
                        setHasNotaFiscal(
                          !!(
                            columnData.modeloNumeroFiscais &&
                            columnData.modeloNumeroFiscais?.length > 0
                          )
                        );
                      },
                      funcionalidade: [
                        ConstanteFuncionalidades.MOVIMENTACAO_ESTOQUE_CANCELAR,
                      ],
                    },
                  ];
                } else if (
                  (columnData.identificacaoTipoOperacao ===
                    IdentificacaoTipoOperacaoEnum.CONFERENCIA_ESTOQUE_ENTRADA ||
                    columnData.identificacaoTipoOperacao ===
                      IdentificacaoTipoOperacaoEnum.CONFERENCIA_ESTOQUE_SAIDA) &&
                  columnData.statusOperacao === StatusOperacaoEnum.CANCELADA
                ) {
                  dropDownItens = [];
                } else if (
                  (columnData.identificacaoTipoOperacao ===
                    IdentificacaoTipoOperacaoEnum.AJUSTE_ESTOQUE_ENTRADA ||
                    columnData.identificacaoTipoOperacao ===
                      IdentificacaoTipoOperacaoEnum.AJUSTE_ESTOQUE_SAIDA) &&
                  columnData.statusOperacao === StatusOperacaoEnum.CANCELADA
                ) {
                  dropDownItens = [
                    {
                      content: 'Duplicar',
                      onClick: () => {
                        handleDuplicarMovimentacaoEstoque(
                          columnData.operacaoId
                        );
                      },
                      funcionalidade:
                        ConstanteFuncionalidades.MOVIMENTACAO_ESTOQUE_DUPLICAR,
                    },
                  ];
                } else if (
                  columnData.identificacaoTipoOperacao ===
                    IdentificacaoTipoOperacaoEnum.DEVOLUCAO_COMPRA &&
                  columnData.statusOperacao === StatusOperacaoEnum.CANCELADA
                ) {
                  dropDownItens = [
                    {
                      content: 'Duplicar',
                      onClick: () => {
                        handleDuplicarMovimentacaoEstoque(
                          columnData.operacaoId
                        );
                      },
                      funcionalidade:
                        ConstanteFuncionalidades.MOVIMENTACAO_ESTOQUE_DUPLICAR,
                    },
                  ];
                } else if (
                  columnData.identificacaoTipoOperacao ===
                    IdentificacaoTipoOperacaoEnum.BRINDE &&
                  columnData.statusOperacao === StatusOperacaoEnum.CANCELADA
                ) {
                  dropDownItens = [
                    {
                      content: 'Duplicar',
                      onClick: () => {
                        handleDuplicarMovimentacaoEstoque(
                          columnData.operacaoId
                        );
                      },
                      funcionalidade:
                        ConstanteFuncionalidades.MOVIMENTACAO_ESTOQUE_DUPLICAR,
                    },
                  ];
                } else if (
                  columnData.identificacaoTipoOperacao ===
                    IdentificacaoTipoOperacaoEnum.PERDA &&
                  columnData.statusOperacao === StatusOperacaoEnum.CANCELADA
                ) {
                  dropDownItens = [
                    {
                      content: 'Duplicar',
                      onClick: () => {
                        handleDuplicarMovimentacaoEstoque(
                          columnData.operacaoId
                        );
                      },
                      funcionalidade:
                        ConstanteFuncionalidades.MOVIMENTACAO_ESTOQUE_DUPLICAR,
                    },
                  ];
                } else {
                  dropDownItens = [
                    {
                      content: 'Alterar',
                      onClick: () => {
                        handlePushAlterar(columnData.operacaoId);
                      },
                      funcionalidade:
                        ConstanteFuncionalidades.MOVIMENTACAO_ESTOQUE_ALTERAR,
                    },
                    {
                      content: 'Duplicar',
                      onClick: () => {
                        handleDuplicarMovimentacaoEstoque(
                          columnData.operacaoId
                        );
                      },
                      funcionalidade:
                        ConstanteFuncionalidades.MOVIMENTACAO_ESTOQUE_DUPLICAR,
                    },
                  ];

                  if (
                    columnData.statusOperacao !== StatusOperacaoEnum.CANCELADA
                  ) {
                    dropDownItens = [
                      ...dropDownItens,
                      {
                        content: 'Cancelar',
                        onClick: () => {
                          setModalConfirmacaoCancelarIsOpen(true);
                          setIdOperacao(columnData.operacaoId);
                          setHasNotaFiscal(
                            !!(
                              columnData.modeloNumeroFiscais &&
                              columnData.modeloNumeroFiscais?.length > 0
                            )
                          );
                        },
                        funcionalidade: [
                          ConstanteFuncionalidades.MOVIMENTACAO_ESTOQUE_CANCELAR,
                        ],
                      },
                    ];
                  }

                  if (
                    columnData.identificacaoTipoOperacao ===
                      IdentificacaoTipoOperacaoEnum.CONFERENCIA_ESTOQUE_ENTRADA ||
                    columnData.identificacaoTipoOperacao ===
                      IdentificacaoTipoOperacaoEnum.AJUSTE_ESTOQUE_ENTRADA
                  ) {
                    dropDownItens = [
                      ...dropDownItens,
                      {
                        content: 'Imprimir etiquetas',
                        onClick: () => {
                          ModalImpressaoEtiquetaPersonalizada({
                            operacaoId: columnData.operacaoId,
                          });
                        },
                      },
                    ];
                  }

                  if (
                    columnData.modeloNumeroFiscais.length === 0 &&
                    columnData.statusOperacao === StatusOperacaoEnum.EFETUADA &&
                    columnData.identificacaoTipoOperacao !==
                      IdentificacaoTipoOperacaoEnum.AJUSTE_ESTOQUE_ENTRADA
                  ) {
                    dropDownItens = [
                      ...dropDownItens,
                      {
                        content: 'Emitir Nota Fiscal',
                        onClick: () => {
                          handleEmitirNotaFiscal(columnData.operacaoId);
                        },
                      },
                    ];
                  }
                }

                const dataEmissao = formatDateHourMinute(
                  columnData.dataEmissao
                );

                return (
                  <Tr>
                    <Td fontSize="sm">{dataEmissao}</Td>
                    <Td fontSize="sm">
                      <Link
                        href={getVisualizarHref(
                          columnData.operacaoId,
                          columnData.identificacaoTipoOperacao
                        )}
                      >
                        {`${getName(
                          IdentificacaoTipoOperacaoEnum,
                          columnData.identificacaoTipoOperacao
                        )}`}
                      </Link>
                    </Td>
                    <Td fontSize="sm">
                      <Link
                        href={getVisualizarHref(
                          columnData.operacaoId,
                          columnData.identificacaoTipoOperacao
                        )}
                      >
                        {columnData.numeroOperacao}
                      </Link>
                    </Td>
                    <Td fontSize="sm">
                      {columnData.localEstoqueNome || 'Padrão'}
                    </Td>
                    <Td fontSize="sm">{columnData.usuarioNome}</Td>
                    <Td py="0" fontSize="sm">
                      {columnData.modeloNumeroFiscais.length > 0 ? (
                        columnData.modeloNumeroFiscais.map(
                          ({ modeloFiscal, numeroFiscal }) => (
                            <Text>
                              {getName(ModelosFiscaisEnum, modeloFiscal)} |{' '}
                              {numeroFiscal}
                            </Text>
                          )
                        )
                      ) : (
                        <Text
                          color={
                            columnData.statusOperacao ===
                            StatusOperacaoEnum.CANCELADA
                              ? 'red.500'
                              : ''
                          }
                        >
                          {emptyField}
                        </Text>
                      )}
                      {columnData.statusOperacao ===
                        StatusOperacaoEnum.CANCELADA && (
                        <Text fontSize="2xs" color="red.500">
                          Operação cancelada
                        </Text>
                      )}
                    </Td>
                    <Td>{columnData.quantidade}</Td>
                    <Td>
                      <ActionsMenu
                        items={dropDownItens}
                        isDisabled={dropDownItens.length <= 0}
                      />
                    </Td>
                  </Tr>
                );
              })
            }
          />
        </GridItem>
      </SimpleGridForm>
      <ModalBuscaAvancadaEstoque
        isOpen={isModalBuscaAvancadaOpen}
        setIsOpen={setIsModalBuscaAvancadaOpen}
        handleSubmitModalBuscaAvancada={handleSubmitModalBuscaAvancada}
        currentFilters={currentFilters}
        handleReset={handleReset}
        lojaId={lojaId}
      />
      <ModalConfirmacao
        isOpen={modalConfirmacaoCancelarIsOpen}
        setIsOpen={setModalConfirmacaoCancelarIsOpen}
        onConfirm={handleCancelar}
        textoCabecalho="Deseja cancelar esta operação?"
        textoMensagem="Se continuar cancelará toda a operação, e esta ação não poderá ser desfeita. Deseja continuar?"
        textoCancelar="Não, voltar!"
      />
      {modalMotivoCancelamentoIsOpen && (
        <ModalMotivoCancelamento
          isOpen={modalMotivoCancelamentoIsOpen}
          onClose={() => {
            setHasNotaFiscal(false);
            setModalMotivoCancelamentoIsOpen(false);
          }}
          onSubmit={handleConfirmarMotivoCancelamento}
          hasNotaFiscal={hasNotaFiscal}
        />
      )}
    </>
  );
};

export default MovimentacaoEstoqueListar;
