import PlanoContratacaoEnum from './planoContratacao';

export type TipoProduto = 1 | 2 | 3;

const TipoProdutoEnum = {
  PRODUTO_SIMPLES: 1,
  PRODUTO_VARIACAO: 2,
  PRODUTO_KIT: 3,

  properties: {
    1: { name: 'Produto simples', value: 1 },
    2: { name: 'Produto com variações', value: 2 },
    3: { name: 'Kit de produtos', value: 3 },
  },

  produto: [
    {
      label: 'Produto simples',
      value: 1,
      planosPermitidos: [
        PlanoContratacaoEnum.TESTE,
        PlanoContratacaoEnum.START,
        PlanoContratacaoEnum.PRO,
        PlanoContratacaoEnum.PRIME,
        PlanoContratacaoEnum.DEMONSTRACAO,
        PlanoContratacaoEnum.CORTESIA,
      ],
    },
    {
      label: 'Produto com variações',
      value: 2,
      planosPermitidos: [
        PlanoContratacaoEnum.TESTE,
        PlanoContratacaoEnum.PRO,
        PlanoContratacaoEnum.PRIME,
        PlanoContratacaoEnum.DEMONSTRACAO,
        PlanoContratacaoEnum.CORTESIA,
      ],
    },
    {
      label: 'Kit de produtos',
      value: 3,
      planosPermitidos: [
        PlanoContratacaoEnum.TESTE,
        PlanoContratacaoEnum.PRIME,
        PlanoContratacaoEnum.DEMONSTRACAO,
        PlanoContratacaoEnum.CORTESIA,
      ],
    },
  ],
};

export default TipoProdutoEnum;
