import { InputProps as ChakraInputProps } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';

import { WhatsappIcon } from 'icons';
import { CampoContainerProps } from 'components/PDV/Geral/CampoContainer';
import InputTelefone from 'components/PDV/InputTelefonePdv';

export type InputProps = Omit<ChakraInputProps, 'ref'> &
  CampoContainerProps & {
    whatsappFieldName: string;
  };

const InputTelefoneWhatsapp = ({
  id,
  name,
  actionLinkText,
  actionLinkOnClick,
  whatsappFieldName,
  isDisabled,
  ...rest
}: InputProps) => {
  return (
    <Controller
      render={({ field: { onChange: setIsWhatsapp, value: isWhatsapp } }) => (
        <InputTelefone
          id={id}
          name={name}
          actionLinkText={
            actionLinkText || (isWhatsapp ? 'Não é WhatsApp' : 'É WhatsApp')
          }
          actionLinkOnClick={() => setIsWhatsapp(!isWhatsapp)}
          inputRightElement={isWhatsapp}
          icon={WhatsappIcon}
          isDisabled={isDisabled}
          {...rest}
        />
      )}
      name={whatsappFieldName}
    />
  );
};

export default InputTelefoneWhatsapp;
