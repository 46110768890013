import { Box, Tooltip, Icon } from '@chakra-ui/react';

import { formatDate } from 'helpers/format/formatStringDate';

import { CalendarioDataCadastroIcon } from 'icons';

export const AntecipacaoTooltip = ({
  dataAntecipacao,
}: {
  dataAntecipacao: string | Date;
}) => (
  <Tooltip
    label={`Conta antecipada para ${formatDate(dataAntecipacao)} `}
    fontSize="xs"
    bg="gray.900"
    color="white"
    placement="right"
    hasArrow
  >
    <Box
      borderRadius="full"
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
    >
      <Icon
        as={CalendarioDataCadastroIcon}
        color="black"
        boxSize="4"
        display="flex"
      />
    </Box>
  </Tooltip>
);
