import { BrowserRouter, Route } from 'react-router-dom';
import { create, InstanceProps } from 'react-modal-promise';
import {
  ModalProps,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
} from '@chakra-ui/react';

import PadronizacaoProvider from 'store/Padronizacao/Padronizacao';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { FaturasDetalhes } from 'pages/Faturas/Detalhes';

type PromiseModal = {
  sucesso: boolean;
};

interface ModalVerFatura
  extends Omit<ModalProps, 'isOpen' | 'onClose' | 'children'>,
    InstanceProps<PromiseModal> {
  idFatura?: string;
}

function Modal({ idFatura, onResolve, ...rest }: ModalVerFatura) {
  function handleFecharModal() {
    onResolve({ sucesso: true });
  }
  return (
    <ModalPadraoChakra
      {...rest}
      size="full"
      onClose={handleFecharModal}
      isCentered
      scrollBehavior="inside"
    >
      <ModalContent
        mx={{ base: '0', lg: '20' }}
        my={{ base: '0', lg: '20' }}
        borderRadius={{ base: '0', lg: 'md' }}
      >
        <ModalCloseButton />
        <ModalHeader minH="12" />
        <ModalBody
          sx={{
            '&::-webkit-scrollbar': {
              width: '14px',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '999px',
              backgroundColor: 'gray.100',
              border: '5px solid white',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'transparent',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              border: '3px solid white',
              cursor: 'pointer !important',
            },
          }}
        >
          <BrowserRouter>
            <PadronizacaoProvider>
              <Route
                render={(props) => (
                  <FaturasDetalhes
                    {...props}
                    idFatura={idFatura}
                    fecharModal={handleFecharModal}
                  />
                )}
              />
            </PadronizacaoProvider>
          </BrowserRouter>
        </ModalBody>
        <ModalFooter minH="12" />
      </ModalContent>
    </ModalPadraoChakra>
  );
}

export const ModalVerFatura = create<ModalVerFatura, PromiseModal>(Modal);
