import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

const schema = yup.object().shape({
  transferencias: yup.array().of(
    yup.object().shape({
      quantidade: yup
        .number()
        .min(0.0000001)
        .nullable(false)
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
    })
  ),
});

export const yupResolver = yupResolverInstance(schema);
