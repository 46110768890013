import { Box, Button, Icon } from '@chakra-ui/react';

import { SalvarInserirNovoIcon } from 'icons';

import { useProdutos } from './hooks';
import { ProdutoItem } from './ProdutoItem';
import { ProdutoItemPaginado } from './ProdutoItemPaginado';

type ProdutosProps = {
  isAlterar?: boolean;
  isReadOnly?: boolean;
};

export const Produtos = ({ isAlterar, isReadOnly }: ProdutosProps) => {
  const { handleAdicionarProduto, buttonRef } = useProdutos(isAlterar);

  return (
    <Box
      mt="24px"
      borderRadius="md"
      bg="gray.50"
      border="1px"
      borderColor="gray.100"
      p={{ base: 4, sm: 6, md: 8 }}
    >
      <Button
        ref={buttonRef}
        borderRadius="md"
        isDisabled={isReadOnly}
        colorScheme="secondary"
        onClick={handleAdicionarProduto}
        leftIcon={<Icon as={SalvarInserirNovoIcon} fontSize="lg" />}
      >
        Adicionar produto
      </Button>

      {isAlterar ? <ProdutoItemPaginado /> : <ProdutoItem />}
    </Box>
  );
};
