import React from 'react';
import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import { ListarRecebimentoContas } from '../pages/RecebimentoContas/Listar';

export const RecebimentoContasRoutes = [
  <LayoutGuard
    key="recebimento-contas-listar"
    component={ListarRecebimentoContas}
    breadcrumb={[
      {
        title: 'Financeiro',
      },
      {
        title: 'Recebimento de Contas',
      },
    ]}
    path={ConstanteRotas.RECEBIMENTO_CONTAS}
    exact
    meta={{ auth: true }}
  />,
];
