import React, { useEffect, useRef, useState } from 'react';
import {
  GridItem,
  Button,
  Box,
  Text,
  useMediaQuery,
  Stack,
} from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import auth from 'modules/auth';
import ConstanteFuncionalidades from 'constants/permissoes';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { useEntradaMercadoriaDadosCadastroContext } from 'store/EntradaMercadoria/EntradaMercadoriaDadosCadastro';
import { useEntradaMercadoriaEtapasContext } from 'store/EntradaMercadoria/EntradaMercadoriaEtapas';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import { formatOptionsSelectClient } from 'helpers/format/formatSelectClient';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import AsyncCreatableSelect from 'components/PDV/Select/AsyncCreatableSelectPadrao';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import {
  Container,
  Body,
  Footer,
  StepDescriptionAccordion,
} from 'components/update/Steps/StepContent';

import { StatusPesquisaClientesFornecedor } from 'constants/enum/statusPesquisaClientesFornecedor';
import { ModalCadastrarFornecedor } from './ModalCadastrarFornecedor';
import { yupResolver, FormData } from './validationForm';

type FornecedorOptionResponse = {
  id: string;
  nome: string;
  endereco?: string;
  codigo?: number;
  cpfCnpj?: string;
};

type ObterFornecedorResponse = {
  id: string;
  nome: string;

  cpfCnpj: string;
  rgInscricaoEstadual: string;
  logradouro: string;
  numero: string;
  cidadeUf: string;
  cep: string;
  bairro: string;
};

type InformacoesAdicionaisFornecedor = {
  nome: string;
  cpfCnpj: string;
  rgInscricaoEstadual: string;
  logradouro: string;
  numero: string;
  cidadeUf: string;
  cep: string;
  bairro: string;
};

interface EscolherFornecedorProps {
  isImportacao?: boolean;
}

export function EscolherFornecedor({
  isImportacao = false,
}: EscolherFornecedorProps) {
  const history = useHistory();

  const { permitido: temPermissaoCadastrarFornecedor } = auth.possuiPermissao(
    ConstanteFuncionalidades.FORNECEDOR_CADASTRAR
  );

  const { nextStep } = useEntradaMercadoriaEtapasContext();
  const {
    entradaMercadoriaId,
    setEntradaMercadoriaId,
    descartarEntradaMercadoria,
    voltarParaListagem,
    temPermissaoExcluir,
    isReadOnly,
    IsCadastroExterno,
    isStatusLancamentosLoading,
    menuIsOpen,
  } = useEntradaMercadoriaDadosCadastroContext();
  const formMethods = useForm<FormData>({
    resolver: yupResolver,
  });
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const [isLoading, setIsLoading] = useState(false);
  const [
    informacoesAdicionaisFornecedor,
    setInformacoesAdicionaisFornecedor,
  ] = useState<InformacoesAdicionaisFornecedor>();

  const { watch } = formMethods;
  const watchFornecedor = watch('fornecedor');

  const savedFornecedor = useRef<FornecedorOptionResponse | null>(null);

  function handleDescartarEntradaMercadoria() {
    descartarEntradaMercadoria();
  }

  async function alterarFornecedor({ fornecedor }: FormData) {
    setIsLoading(true);

    const response = await api.put<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.ENTRADA_MERCADORIA_ALTERAR_FORNECEDOR,
      {
        id: entradaMercadoriaId,
        clienteFornecedorId: fornecedor.value,
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.map((aviso: string) => toast.warning(aviso));
      }

      if (response.sucesso) {
        return true;
      }
    }

    return false;
  }

  async function cadastrarEntradaMercadoria({ fornecedor }: FormData) {
    setIsLoading(true);

    const response = await api.post<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.ENTRADA_MERCADORIA_CADASTRAR_ENTRADA_MANUAL,
      {
        clienteFornecedorId: fornecedor.value,
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.map((aviso: string) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        return { sucesso: true, entradaMercadoriaId: response.dados };
      }
    }

    return { sucesso: false };
  }

  const handleSalvarRascunho = formMethods.handleSubmit(async (data) => {
    if (isImportacao) {
      voltarParaListagem();
      return;
    }

    if (entradaMercadoriaId) {
      if (
        savedFornecedor.current &&
        savedFornecedor.current.id !== data.fornecedor.value
      ) {
        await alterarFornecedor(data);
      }

      voltarParaListagem();
    } else {
      await cadastrarEntradaMercadoria(data);

      voltarParaListagem();
    }
  });

  const handleAvancar = formMethods.handleSubmit(async (data) => {
    if (isReadOnly || isImportacao) {
      nextStep();
      return;
    }

    if (entradaMercadoriaId) {
      if (
        savedFornecedor.current &&
        savedFornecedor.current.id !== data.fornecedor.value
      ) {
        const sucesso = await alterarFornecedor(data);

        if (sucesso) {
          savedFornecedor.current = {
            id: data.fornecedor.value,
            nome: data.fornecedor.label,
          };

          setIsLoading(false);

          nextStep();
        }
      } else {
        nextStep();
      }
    } else {
      const {
        sucesso,
        entradaMercadoriaId: newEntradaMercadoriaId,
      } = await cadastrarEntradaMercadoria(data);

      if (sucesso && newEntradaMercadoriaId) {
        setEntradaMercadoriaId(newEntradaMercadoriaId);

        setIsLoading(false);

        nextStep();

        history.push(
          SubstituirParametroRota(
            ConstanteRotas.ENTRADA_MERCADORIA_ENTRADA_MANUAL,
            'id?',
            newEntradaMercadoriaId
          )
        );
      }
    }
  });

  async function handleCadastrarFornecedor(inputValue: string) {
    if (temPermissaoCadastrarFornecedor) {
      const { fornecedor } = await ModalCadastrarFornecedor({ inputValue });

      return {
        value: fornecedor.id,
        label: fornecedor.nome,
      };
    }

    toast.warning(
      'Você não tem permissão para acessar essa função. Consulte o administrador da conta.'
    );

    return undefined;
  }

  async function getFornecedoresOptions(inputValue: string) {
    const response = await api.get<
      void,
      ResponseApi<FornecedorOptionResponse[]>
    >(ConstanteEnderecoWebservice.CLIENTE_FORNECEDOR_LISTAR_SELECT, {
      params: {
        cpfCnpjNomeApelidoCodigoExterno: inputValue,
        filtroTipoCadastroPessoa: StatusPesquisaClientesFornecedor.FORNECEDORES,
      },
    });

    if (response) {
      if (response.avisos) {
        response.avisos.map((aviso: string) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        return response.dados.map((fornecedor) => {
          const option = formatOptionsSelectClient(fornecedor, false);
          return option;
        });
      }
    }

    return [];
  }

  const latestProps = useRef({
    setValue: formMethods.setValue,
    voltarParaListagem,
  });
  useEffect(() => {
    latestProps.current = {
      setValue: formMethods.setValue,
      voltarParaListagem,
    };
  });

  useEffect(() => {
    async function obterFornecedor() {
      if (entradaMercadoriaId && !savedFornecedor.current) {
        setIsLoading(true);

        const response = await api.get<
          void,
          ResponseApi<ObterFornecedorResponse>
        >(ConstanteEnderecoWebservice.ENTRADA_MERCADORIA_OBTER_FORNECEDOR, {
          params: {
            id: entradaMercadoriaId,
          },
        });

        if (response) {
          if (response.avisos) {
            response.avisos.map((aviso: string) => toast.warning(aviso));
          }

          if (response.sucesso && response.dados) {
            const { id, nome } = response.dados;

            savedFornecedor.current = { id, nome };

            latestProps.current.setValue('fornecedor', {
              value: id,
              label: nome,
            });

            if (isImportacao) {
              const {
                cpfCnpj,
                rgInscricaoEstadual,
                logradouro,
                numero,
                cidadeUf,
                cep,
                bairro,
              } = response.dados;

              setInformacoesAdicionaisFornecedor({
                nome,
                cpfCnpj,
                rgInscricaoEstadual,
                logradouro,
                numero,
                cidadeUf,
                cep,
                bairro,
              });
            }
          } else {
            latestProps.current.voltarParaListagem();
          }
        }

        setIsLoading(false);
      }
    }

    obterFornecedor();
  }, [entradaMercadoriaId, isImportacao]);

  return (
    <>
      {(isLoading || isStatusLancamentosLoading) && <LoadingPadrao />}

      <Container mt="6px">
        <StepDescriptionAccordion
          stepNumber={1}
          title="Identificação do Fornecedor"
          description={
            isImportacao
              ? 'Caso seja um novo fornecedor ainda não cadastrado no sistema, o mesmo será incluído automaticamente após avançar para a próxima etapa.'
              : 'Caso seja um novo fornecedor ainda não cadastrado no sistema, digite o nome e clique em “cadastrar o fornecedor” para registrá-lo antes de seguir para a próxima etapa.'
          }
        />

        <FormProvider {...formMethods}>
          <Body minH="212px">
            <SimpleGridForm>
              <GridItem colSpan={{ base: 12, md: 8, lg: 7 }}>
                <AsyncCreatableSelect
                  id="fornecedor"
                  isClearable
                  name="fornecedor"
                  label="Fornecedor"
                  fontWeightLabel="semibold"
                  placeholder="Selecione o fornecedor ou digite o nome para cadastrar um novo"
                  handleGetOptions={getFornecedoresOptions}
                  creatableInputTextPreffix="Cadastrar o fornecedor"
                  handleCreateOption={handleCadastrarFornecedor}
                  required
                  asControlledByObject
                  autoFocus
                  isDisabled={isReadOnly || isImportacao}
                  shouldAppearTheAddress
                />
              </GridItem>
            </SimpleGridForm>

            {informacoesAdicionaisFornecedor && (
              <Box mt="8" fontSize="xs" color="gray.500">
                {informacoesAdicionaisFornecedor.nome && (
                  <Text>{informacoesAdicionaisFornecedor.nome}</Text>
                )}
                {informacoesAdicionaisFornecedor.cpfCnpj && (
                  <Text>{`CNPJ: ${informacoesAdicionaisFornecedor.cpfCnpj}`}</Text>
                )}
                {informacoesAdicionaisFornecedor.rgInscricaoEstadual && (
                  <Text>{`Insc. Estadual: ${informacoesAdicionaisFornecedor.rgInscricaoEstadual}`}</Text>
                )}
                {informacoesAdicionaisFornecedor.logradouro &&
                  informacoesAdicionaisFornecedor.numero &&
                  informacoesAdicionaisFornecedor.cidadeUf && (
                    <Text>{`${informacoesAdicionaisFornecedor.logradouro}, ${informacoesAdicionaisFornecedor.numero} | ${informacoesAdicionaisFornecedor.cidadeUf}`}</Text>
                  )}
                {informacoesAdicionaisFornecedor.cep &&
                  informacoesAdicionaisFornecedor.bairro && (
                    <Text>{`${informacoesAdicionaisFornecedor.cep} - ${informacoesAdicionaisFornecedor.bairro}`}</Text>
                  )}
              </Box>
            )}
          </Body>
        </FormProvider>
      </Container>
      <Footer
        justifyContent={isReadOnly ? 'space-between' : 'flex-end'}
        position={isLargerThan900 ? 'fixed' : 'relative'}
        bottom="0px"
        bg="gray.50"
        borderTop={isLargerThan900 ? '1px solid' : 'none'}
        borderColor="#5502B2"
        w={`calc(100% - ${menuIsOpen ? '210px' : '108px'})`}
        py="16px"
        px="48px"
      >
        <Stack
          w={isReadOnly ? 'full' : 'auto'}
          justifyContent="flex-end"
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: 2, sm: 4, md: 6 }}
        >
          {!IsCadastroExterno && (
            <>
              {isReadOnly ? (
                <Button
                  variant="outlineDefault"
                  borderRadius="full"
                  w="full"
                  maxW={{ base: 'full', md: '196px' }}
                  onClick={voltarParaListagem}
                >
                  Voltar para a listagem
                </Button>
              ) : (
                <Button
                  variant="outlineDefault"
                  borderRadius="full"
                  w="full"
                  maxW={{ base: 'full', md: '160px' }}
                  minW="160px"
                  onClick={handleDescartarEntradaMercadoria}
                  isDisabled={!temPermissaoExcluir && !!entradaMercadoriaId}
                >
                  Descartar
                </Button>
              )}
            </>
          )}

          {entradaMercadoriaId && !isReadOnly && (
            <Button
              variant="outlineDefault"
              borderRadius="full"
              w={['full', 'full', '160px']}
              minW="160px"
              maxW={{ base: 'full', md: '160px' }}
              onClick={handleSalvarRascunho}
            >
              Salvar e sair
            </Button>
          )}

          <Button
            colorScheme="purple"
            borderRadius="full"
            w={['full', 'full', '160px']}
            minW="160px"
            maxW={{ base: 'full', md: '160px' }}
            onClick={handleAvancar}
            isDisabled={!watchFornecedor}
          >
            Avançar
          </Button>
        </Stack>
      </Footer>
    </>
  );
}
