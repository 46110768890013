import React, { useCallback, useEffect, useState } from 'react';
import { NamedProps } from 'react-select';
import AsyncCreatableReactSelect from 'react-select/async-creatable';
import { useTheme, Text, Flex } from '@chakra-ui/react';

import OptionType from 'types/optionType';
import { isNullOrWhitespace } from 'helpers/validation/isNullOrWhitespace';

import { SalvarInserirNovoIcon } from 'icons';
import SelectHighlighter from 'components/PDV/SelectHighlighter';
import {
  ChakraReactSelectContainer,
  chakraComponents,
  chakraStyles,
} from '../../ReactSelectIntegracao';

let inputTypingTimeout: NodeJS.Timeout;

export type AsyncCreatableSelectInputProps = NamedProps<OptionType> & {
  handleGetOptions: (inputValue: string) => Promise<OptionType[]>;
  onCreateOption: (inputValue: string) => Promise<void>;
  isDisabled?: boolean;
  withoutDefaultOptions?: boolean;
  variant?: string;
  size?: string;
  creatableInputTextPreffix: string;
  components?: any;
  colorSelectedValue?: string;
  creatableButtonShow?: boolean;
  selectRef?: any;
  shouldAppearTheAddress?: boolean;
};

const AsyncCreatableSelectInput = ({
  handleGetOptions,
  onCreateOption,
  id,
  isClearable = false,
  placeholder = '',
  noOptionsMessage = (obj) =>
    obj.inputValue !== '' ? 'Não há valores disponíveis' : null,
  loadingMessage = () => 'Carregando...',
  withoutDefaultOptions,
  variant,
  size,
  creatableInputTextPreffix,
  components,
  colorSelectedValue,
  creatableButtonShow = true,
  selectRef,
  isDisabled,
  shouldAppearTheAddress,
  ...props
}: AsyncCreatableSelectInputProps) => {
  const { colors, radii } = useTheme();

  const placeholderColor = colors.gray[400];
  const blue600 = colors.blue[600];

  const [defaultOptions, setDefaultOptions] = useState<OptionType[]>([]);

  const loadOptions = useCallback(
    async (
      inputValue: string,
      callback: (options: OptionType[]) => void,
      forceSeach?: boolean
    ) => {
      const promise = new Promise(() => {
        clearTimeout(inputTypingTimeout);

        inputTypingTimeout = global.setTimeout(
          async () => {
            if (!isNullOrWhitespace(inputValue) || forceSeach) {
              const options = await handleGetOptions(inputValue);

              callback(options || []);
            } else {
              callback([]);
            }
          },
          forceSeach ? 0 : 500
        );
      });

      // eslint-disable-next-line consistent-return
      return promise;
    },
    [handleGetOptions]
  );

  useEffect(() => {
    if (!withoutDefaultOptions)
      loadOptions(
        '',
        (options) => {
          if (options.length > 0) setDefaultOptions(options);
        },
        true
      );
  }, [loadOptions, withoutDefaultOptions]);

  return (
    <ChakraReactSelectContainer
      variant={variant}
      size={size}
      isDisabled={isDisabled}
    >
      <AsyncCreatableReactSelect
        isDisabled={isDisabled}
        createOptionPosition="first"
        placeholder={placeholder}
        isClearable={isClearable}
        noOptionsMessage={noOptionsMessage}
        loadingMessage={loadingMessage}
        className="react-select-container"
        classNamePrefix="react-select"
        inputId={id}
        id={`select-container-${id}`}
        {...props}
        menuPlacement="auto"
        components={components || chakraComponents}
        loadOptions={loadOptions}
        onCreateOption={onCreateOption}
        defaultOptions={defaultOptions}
        onInputChange={(newValue: string) => {
          if (!newValue) {
            clearTimeout(inputTypingTimeout);
          }
        }}
        styles={chakraStyles(colorSelectedValue)}
        theme={(baseTheme) => ({
          ...baseTheme,
          borderRadius: radii.md,
          colors: {
            ...baseTheme.colors,
            neutral50: placeholderColor, // placeholder text color
            neutral40: placeholderColor, // noOptionsMessage color
          },
          spacing: {
            ...baseTheme.spacing,
          },
        })}
        formatOptionLabel={SelectHighlighter}
        formatCreateLabel={(userInput: string) =>
          creatableButtonShow ? (
            <Flex align="center" gap="6px">
              <SalvarInserirNovoIcon
                style={{
                  color: `${blue600}`,
                  marginBottom: '2px',
                  strokeWidth: '1.5px !important',
                }}
              />
              <Text color={blue600} fontSize="14px" fontStyle="italic">
                {creatableInputTextPreffix} &quot;
                <strong
                  style={{
                    color: `${blue600}`,
                    fontStyle: 'normal',
                  }}
                >
                  {userInput}
                </strong>
                &quot;
              </Text>
            </Flex>
          ) : undefined
        }
        isValidNewOption={(option) => option !== '' && creatableButtonShow}
        sele
        ref={selectRef}
        shouldAppearTheAddress={shouldAppearTheAddress}
      />
    </ChakraReactSelectContainer>
  );
};

export default AsyncCreatableSelectInput;
