import {
  Box,
  Text,
  Flex,
  Divider,
  Button,
  Icon,
  useMediaQuery,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { EnumFormaPagamentoPremiacaoMetas } from 'constants/enum/formaPagamentoPremiacaoMetas';
import { useMetasComissoesEtapasContext } from 'store/MetasComissoes/MetasComissoesEtapas';
import { moneyMask } from 'helpers/format/fieldsMasks';
import { IdentificacaoEtapasMetasComissoes } from 'constants/enum/identificacaoEtapasMetasComissoes';
import ConstanteRotas from 'constants/rotas';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import { NumberInput } from 'components/update/Input/NumberInput';
import { SalvarInserirNovoIcon } from 'icons';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { FooterMetasComissoes } from '../FooterMetasComissoes';
import { ListarFaixaValorFixo } from './Components/listarFaixaValorFixo';
import { ListarFaixaPercentual } from './Components/listarFaixaPercentual';

type FaixaPercentualBonificacaoVendedorProps = {
  percentualMeta: number;
  valorBonificacao: number;
  id?: string;
};

type FaixaValorFixoBonificacaoVendedorProps = {
  percentualMeta: number;
  valorBonificacao: number;
  id?: string;
};

type FormaPagamentoResponse = {
  id: string;
  formaPagamentoPremiacao: number;
};

export const PremiacaoPorEquipe = () => {
  const [
    listarFaixaPercentualBonificacaoVendedor,
    setListarFaixaPercentualBonificacaoVendedor,
  ] = useState<FaixaPercentualBonificacaoVendedorProps[]>([]);

  const [
    listarFaixaValorFixoBonificacaoVendedor,
    setListarFaixaValorFixoBonificacaoVendedor,
  ] = useState<FaixaValorFixoBonificacaoVendedorProps[]>([]);

  const [atualizarPremiacao, setAtualizarPremiacao] = useState(true);
  const [
    isLoadingPremiacaoPorEquipe,
    setIsLoadingPremiacaoPorEquipe,
  ] = useState(false);
  const [
    obterFormaPagamentoCadastrada,
    setObterFormaPagamentoCadastrada,
  ] = useState(0);

  const {
    setActiveStep,
    obterValorMetaLoja,
    isLoading,
    animacaoLoading,
  } = useMetasComissoesEtapasContext();
  const history = useHistory();
  const { watch, setValue } = useFormContext();

  const setValueRef = useRef(setValue);

  const loading = isLoadingPremiacaoPorEquipe || isLoading;

  useEffect(() => {
    setValueRef.current = setValue;
  }, [setValue]);

  const [
    formaPagamentoBonusWatch,
    valorBonificacaoWatch,
    percentualBonificacaoWatch,
  ] = watch([
    'formaPagamentoBonus',
    'valorBonificacao',
    'percentualBonificacao',
  ]);

  const formaPagamentoBonusWatchRef = useRef(formaPagamentoBonusWatch);

  const valorAlcancePremiacaoVendedoresWatch = useCallback(() => {
    return watch('valorAlcancePremiacaoVendedores') || [];
  }, [watch])();
  const valorPercentualBonificacaoVendedoresWatch = useCallback(() => {
    return watch('percentualBonificacaoVendedores') || [];
  }, [watch])();

  const valorAlcancePremiacaoValorFixoVendedoresWatch = useCallback(() => {
    return watch('valorAlcancePremiacaoValorFixoVendedores') || [];
  }, [watch])();
  const valorFixoBonificacaoVendedoresWatch = useCallback(() => {
    return watch('valorFixoBonificacaoVendedores') || [];
  }, [watch])();

  const formaPagamentoEscolhidaValorFixo =
    formaPagamentoBonusWatch === EnumFormaPagamentoPremiacaoMetas.VALOR_FIXO;

  const formaPagamentoEscolhidaPercentual =
    formaPagamentoBonusWatch === EnumFormaPagamentoPremiacaoMetas.PERCENTUAL;

  const [isLargerThan1200] = useMediaQuery('(min-width: 1200px)');
  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

  const cadastrarFormaPagamento = useCallback(async () => {
    const response = await api.patch<void, ResponseApi<FormaPagamentoResponse>>(
      ConstanteEnderecoWebservice.CADASTRAR_PREMIACAO_VENDEDOR,
      {
        id: obterValorMetaLoja?.id,
        formaPagamentoPremiacao: formaPagamentoBonusWatch,
      }
    );
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
        return false;
      }

      if (response.sucesso) {
        return true;
      }
    }
    return false;
  }, [formaPagamentoBonusWatch, obterValorMetaLoja?.id]);

  const atualizarFaixasComissaoParaEnviarApi = useCallback(() => {
    const faixaPremiacao = formaPagamentoEscolhidaValorFixo
      ? listarFaixaValorFixoBonificacaoVendedor
      : listarFaixaPercentualBonificacaoVendedor;

    const valorAlcanceMetas = formaPagamentoEscolhidaValorFixo
      ? valorAlcancePremiacaoValorFixoVendedoresWatch
      : valorAlcancePremiacaoVendedoresWatch;

    const valorPercentualMetas = formaPagamentoEscolhidaValorFixo
      ? valorFixoBonificacaoVendedoresWatch
      : valorPercentualBonificacaoVendedoresWatch;

    const valorPercentual = formaPagamentoEscolhidaValorFixo
      ? valorBonificacaoWatch
      : percentualBonificacaoWatch;

    const primeiraMetaCadastrada = [
      {
        percentualMeta: 100,
        valorBonificacao: valorPercentual,
      },
    ];

    return [
      ...primeiraMetaCadastrada,
      ...faixaPremiacao.map((_, index) => ({
        percentualMeta: valorAlcanceMetas[index],
        valorBonificacao: valorPercentualMetas[index],
      })),
    ];
  }, [
    formaPagamentoEscolhidaValorFixo,
    listarFaixaPercentualBonificacaoVendedor,
    listarFaixaValorFixoBonificacaoVendedor,
    percentualBonificacaoWatch,
    valorAlcancePremiacaoValorFixoVendedoresWatch,
    valorAlcancePremiacaoVendedoresWatch,
    valorBonificacaoWatch,
    valorFixoBonificacaoVendedoresWatch,
    valorPercentualBonificacaoVendedoresWatch,
  ])();

  const cadastrarFaixaPremiacao = useCallback(async () => {
    const response = await api.post<void, ResponseApi<FormaPagamentoResponse>>(
      ConstanteEnderecoWebservice.CADASTRAR_FAIXA_PREMIACAO_VENDEDOR,
      atualizarFaixasComissaoParaEnviarApi
    );
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
        return false;
      }

      if (response.sucesso) {
        return true;
      }
    }
    return false;
  }, [atualizarFaixasComissaoParaEnviarApi]);

  const handleAvancar = async () => {
    setIsLoadingPremiacaoPorEquipe(true);
    const formaPagamentoCadastrada = await cadastrarFormaPagamento();
    if (
      formaPagamentoBonusWatch !==
      EnumFormaPagamentoPremiacaoMetas.NAO_POSSUI_BONIFICACAO
    ) {
      const faixaCadastrada = await cadastrarFaixaPremiacao();
      if (formaPagamentoCadastrada && faixaCadastrada) {
        setIsLoadingPremiacaoPorEquipe(false);
        history.push(ConstanteRotas.METAS_E_COMISSOES);
      }
    } else if (formaPagamentoCadastrada === true) {
      setIsLoadingPremiacaoPorEquipe(false);
      history.push(ConstanteRotas.METAS_E_COMISSOES);
    }
    setIsLoadingPremiacaoPorEquipe(false);
  };

  const handleVoltar = () => {
    setAtualizarPremiacao(true);
    setActiveStep(IdentificacaoEtapasMetasComissoes.REGRA_PAGAMENTO);
  };

  const validandoExibicaoConfigPremiacao = () => {
    if (
      formaPagamentoBonusWatch ===
      EnumFormaPagamentoPremiacaoMetas.NAO_POSSUI_BONIFICACAO
    ) {
      return false;
    }

    if (!formaPagamentoBonusWatch) {
      return false;
    }
    return true;
  };

  const exibirConfigPremiacao = validandoExibicaoConfigPremiacao();

  const handleAdicionarFaixaPercentualBonificacaoVendedor = useCallback(async () => {
    await setListarFaixaPercentualBonificacaoVendedor((faixaJaAdicionada) => {
      const novaFaixa: FaixaPercentualBonificacaoVendedorProps = {
        percentualMeta: 0,
        valorBonificacao: 0,
      };
      return [...faixaJaAdicionada, novaFaixa];
    });
  }, []);

  const handleAdicionarFaixaValorFixoBonificacaoVendedor = useCallback(async () => {
    await setListarFaixaValorFixoBonificacaoVendedor((faixaJaAdicionada) => {
      const novaFaixa: FaixaValorFixoBonificacaoVendedorProps = {
        percentualMeta: 0,
        valorBonificacao: 0,
      };
      return [...faixaJaAdicionada, novaFaixa];
    });
  }, []);

  const handleExcluirFaixaBonificacaoPercent = useCallback(
    (index: number) => {
      const faixaBonificacaoPercentual = [
        ...listarFaixaPercentualBonificacaoVendedor,
      ];

      valorAlcancePremiacaoVendedoresWatch.splice(index, 1);
      valorPercentualBonificacaoVendedoresWatch.splice(index, 1);
      faixaBonificacaoPercentual.splice(index, 1);

      setListarFaixaPercentualBonificacaoVendedor(faixaBonificacaoPercentual);

      faixaBonificacaoPercentual.forEach((_, indexFaixa) => {
        setValue(
          `valorAlcancePremiacaoVendedores.${indexFaixa}`,
          valorAlcancePremiacaoVendedoresWatch[indexFaixa]
        );
        setValue(
          `percentualBonificacaoVendedores.${indexFaixa}`,
          valorPercentualBonificacaoVendedoresWatch[indexFaixa]
        );
      });
    },
    [
      listarFaixaPercentualBonificacaoVendedor,
      setValue,
      valorAlcancePremiacaoVendedoresWatch,
      valorPercentualBonificacaoVendedoresWatch,
    ]
  );

  const handleExcluirFaixaBonificacaoValorFixo = useCallback(
    (index: number) => {
      const faixaBonificacaoValorFixo = [
        ...listarFaixaValorFixoBonificacaoVendedor,
      ];

      valorAlcancePremiacaoValorFixoVendedoresWatch.splice(index, 1);
      valorFixoBonificacaoVendedoresWatch.splice(index, 1);

      faixaBonificacaoValorFixo.splice(index, 1);
      setListarFaixaValorFixoBonificacaoVendedor(faixaBonificacaoValorFixo);

      faixaBonificacaoValorFixo.forEach((_, indexFaixa) => {
        setValue(
          `valorAlcancePremiacaoValorFixoVendedores.${indexFaixa}`,
          valorAlcancePremiacaoValorFixoVendedoresWatch[indexFaixa]
        );
        setValue(
          `valorFixoBonificacaoVendedores.${indexFaixa}`,
          valorFixoBonificacaoVendedoresWatch[indexFaixa]
        );
      });
    },
    [
      listarFaixaValorFixoBonificacaoVendedor,
      setValue,
      valorAlcancePremiacaoValorFixoVendedoresWatch,
      valorFixoBonificacaoVendedoresWatch,
    ]
  );

  const handleAdicionarFaixa = useCallback(() => {
    if (formaPagamentoEscolhidaValorFixo) {
      handleAdicionarFaixaValorFixoBonificacaoVendedor();
    } else if (formaPagamentoEscolhidaPercentual) {
      handleAdicionarFaixaPercentualBonificacaoVendedor();
    }
  }, [
    formaPagamentoEscolhidaValorFixo,
    handleAdicionarFaixaValorFixoBonificacaoVendedor,
    formaPagamentoEscolhidaPercentual,
    handleAdicionarFaixaPercentualBonificacaoVendedor,
  ]);

  const atualizarSelectFormaPagamento = useCallback(async () => {
    const response = await api.get<void, ResponseApi<FormaPagamentoResponse>>(
      ConstanteEnderecoWebservice.OBTER_CADASTRO_PREMIACAO_VENDEDOR
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
        setIsLoadingPremiacaoPorEquipe(false);
      }

      if (response.dados.formaPagamentoPremiacao === null) {
        await setValue(
          'formaPagamentoBonus',
          EnumFormaPagamentoPremiacaoMetas.NAO_POSSUI_BONIFICACAO
        );
      }

      if (
        response.sucesso &&
        response.dados &&
        response.dados.formaPagamentoPremiacao !== null
      ) {
        setObterFormaPagamentoCadastrada(
          response.dados.formaPagamentoPremiacao
        );
        setIsLoadingPremiacaoPorEquipe(false);
        await setValue(
          'formaPagamentoBonus',
          response.dados.formaPagamentoPremiacao
        );
      }
    }
    setIsLoadingPremiacaoPorEquipe(false);
  }, [setValue]);

  const atualizarFaixa = useCallback(async () => {
    setIsLoadingPremiacaoPorEquipe(true);
    const response = await api.get<
      void,
      ResponseApi<FaixaValorFixoBonificacaoVendedorProps[]>
    >(ConstanteEnderecoWebservice.OBTER_FAIXA_PREMIACAO_VENDEDOR);
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
        setIsLoadingPremiacaoPorEquipe(false);
      }

      if (response.sucesso && response.dados) {
        const valoresApi = [...response.dados];
        await setValue(
          formaPagamentoBonusWatchRef.current ===
            EnumFormaPagamentoPremiacaoMetas.VALOR_FIXO
            ? 'valorBonificacao'
            : 'percentualBonificacao',
          valoresApi.length === 0 ? 0 : valoresApi[0].valorBonificacao
        );
        valoresApi.splice(0, 1);
        if (
          formaPagamentoBonusWatchRef.current ===
          EnumFormaPagamentoPremiacaoMetas.PERCENTUAL
        ) {
          await setListarFaixaPercentualBonificacaoVendedor(valoresApi);
          await valoresApi.forEach((item, index) => {
            setValue(
              `percentualBonificacaoVendedores.${index}`,
              item.valorBonificacao
            );
            setValue(
              `valorAlcancePremiacaoVendedores.${index}`,
              item.percentualMeta
            );
          });
        }
        if (
          formaPagamentoBonusWatchRef.current ===
          EnumFormaPagamentoPremiacaoMetas.VALOR_FIXO
        ) {
          await valoresApi.forEach((item, index) => {
            setValue(
              `valorAlcancePremiacaoValorFixoVendedores.${index}`,
              item.percentualMeta
            );

            setValue(
              `valorFixoBonificacaoVendedores.${index}`,
              item.valorBonificacao
            );
          });
          await setListarFaixaValorFixoBonificacaoVendedor(valoresApi);
        } else {
          setListarFaixaValorFixoBonificacaoVendedor([]);
          setListarFaixaPercentualBonificacaoVendedor([]);
        }
        setIsLoadingPremiacaoPorEquipe(false);
      }
    }
    setIsLoadingPremiacaoPorEquipe(false);
  }, [setValue]);

  useEffect(() => {
    let campoInformarBonificacao = '';

    if (formaPagamentoEscolhidaValorFixo) {
      setListarFaixaPercentualBonificacaoVendedor([]);
      campoInformarBonificacao = 'valorBonificacao';
    } else if (formaPagamentoEscolhidaPercentual) {
      setListarFaixaValorFixoBonificacaoVendedor([]);
      campoInformarBonificacao = 'percentualBonificacao';
    }

    if (obterFormaPagamentoCadastrada !== formaPagamentoBonusWatch) {
      setValue(campoInformarBonificacao, 0);
    }
  }, [
    formaPagamentoEscolhidaValorFixo,
    formaPagamentoEscolhidaPercentual,
    atualizarPremiacao,
    setValue,
    obterFormaPagamentoCadastrada,
    formaPagamentoBonusWatch,
  ]);

  useEffect(() => {
    setIsLoadingPremiacaoPorEquipe(true);

    async function getDadosPremiacao() {
      await atualizarSelectFormaPagamento();
      await atualizarFaixa();
    }

    getDadosPremiacao();
    setIsLoadingPremiacaoPorEquipe(false);
  }, [atualizarFaixa, atualizarSelectFormaPagamento]);

  useEffect(() => {
    animacaoLoading();
  }, [animacaoLoading]);

  useEffect(() => {
    formaPagamentoBonusWatchRef.current = formaPagamentoBonusWatch;
  }, [formaPagamentoBonusWatch]);

  return (
    <>
      {loading && <LoadingPadrao />}
      <Box
        borderRadius="md"
        boxShadow="0px 0px 6px #00000034"
        w="full"
        bg="white"
        pb="20px"
      >
        <Flex
          alignItems="center"
          borderRadius="5px 5px 0 0"
          bg="primary.500"
          h="48px"
        >
          <Text ml="24px" fontSize="16px" color="secondary.300">
            Premiação por meta da equipe
          </Text>
        </Flex>
        <Box
          mt="22px"
          pl={isLargerThan700 ? '24px' : '10px'}
          pr={isLargerThan700 ? '24px' : '10px'}
        >
          <Text mb="6px" color="gray.700" fontSize="14px">
            Configure uma bonificação extra para os vendedores quando a loja
            alcançar ou ultrapassar a meta geral estabelecida.
          </Text>
          <Flex
            w="full"
            h="110px"
            bg="gray.50"
            borderRadius="5px"
            alignItems="center"
            border="1px solid #CCCCCC"
            pl={isLargerThan700 ? '40px' : '10px'}
            pr={isLargerThan700 ? '40px' : '10px'}
          >
            <Box w="400px">
              <SelectPadrao
                label="Forma de pagamento do bônus"
                helperText={
                  <Box pt="18px" pl="25px" pr="25px">
                    <Text mb="18px" fontSize="12px">
                      Selecione{' '}
                      <strong>“VALOR FIXO PARA TODOS OS VENDEDORES”</strong>{' '}
                      para definir um valor único de premiação, sem variações e
                      independente do faturamento.
                    </Text>
                    <Text fontSize="12px">
                      Para a opção{' '}
                      <strong>
                        “PERCENTUAL SOBRE O VALOR FATURADO PELO VENDEDOR”
                      </strong>
                      , você informa o percentual extra e o cálculo do bônus
                      será processado proporcionalmente ao total de vendas de
                      cada vendedor.
                    </Text>
                  </Box>
                }
                bgHelperText="yellow.500"
                colorHelperText="black"
                color="primary.50"
                options={EnumFormaPagamentoPremiacaoMetas.properties.map(
                  (formaPagamento) => {
                    return (
                      {
                        label: formaPagamento.name,
                        value: formaPagamento.value,
                      } || []
                    );
                  }
                )}
                placeholder="Digite a forma de pagamento"
                name="formaPagamentoBonus"
              />
            </Box>
          </Flex>
          {exibirConfigPremiacao && (
            <Box
              overflowY="scroll"
              sx={{
                '&::-webkit-scrollbar': {
                  height: '0',
                  width: '0',
                },
                '& .virtualized_List::-webkit-scrollbar': {
                  height: '0',
                  width: '0',
                },
              }}
              mt="12px"
              w="full"
              bg="gray.50"
              borderRadius="5px"
              maxH="300px"
              h="full"
              border="1px solid #CCCCCC"
              pl={isLargerThan700 ? '40px' : '10px'}
              pr={isLargerThan700 ? '40px' : '10px'}
            >
              <Flex
                mt="25px"
                justifyContent="left"
                alignItems="center"
                w="full"
                direction={isLargerThan1200 ? 'row' : 'column'}
              >
                <Flex
                  w={isLargerThan1200 ? '' : 'full'}
                  mb={isLargerThan1200 ? '' : isLargerThan700 ? '10px' : '10px'}
                  justifyContent="left"
                  direction={isLargerThan700 ? 'row' : 'column'}
                  alignItems="center"
                >
                  <Flex
                    mb={isLargerThan700 ? '' : '10px'}
                    w={isLargerThan700 ? '' : 'full'}
                    justifyContent="left"
                    alignItems="center"
                  >
                    <Text fontSize="16px" mr="10px">
                      Quando a loja alcançar
                    </Text>
                  </Flex>
                  <Flex
                    justifyContent="left"
                    alignItems="center"
                    mr="10px"
                    w={isLargerThan700 ? '100px' : 'full'}
                  >
                    <NumberInput
                      w="full"
                      isDisabled
                      rightElementPorcentagem="%"
                      id="valorAlcanceMetaPremiacao"
                      bgLeftElement="gray.50"
                      name="valorAlcanceMetaPremiacao"
                      scale={2}
                    />
                  </Flex>
                  {!isLargerThan1200 && (
                    <Flex
                      justifyContent="left"
                      alignItems="center"
                      mt={isLargerThan700 ? '' : '10px'}
                      w={isLargerThan700 ? '' : 'full'}
                    >
                      <Text fontSize="16px" fontWeight="bold">
                        ({moneyMask(obterValorMetaLoja?.valorMetaGeral, true)}
                        ),
                      </Text>
                    </Flex>
                  )}
                </Flex>
                <Flex
                  w={isLargerThan1200 ? '' : 'full'}
                  direction={isLargerThan700 ? 'row' : 'column'}
                  justifyContent="left"
                  alignItems="center"
                >
                  <Flex
                    mb={isLargerThan700 ? '' : '10px'}
                    w={isLargerThan700 ? '' : 'full'}
                    justifyContent="left"
                    alignItems="center"
                  >
                    <Text mr="10px" fontSize="16px">
                      {isLargerThan1200 && (
                        <strong>
                          {moneyMask(obterValorMetaLoja?.valorMetaGeral, true)},
                        </strong>
                      )}{' '}
                      o{' '}
                      {formaPagamentoEscolhidaValorFixo
                        ? 'valor'
                        : 'percentual'}{' '}
                      da bonificação extra será de
                    </Text>
                  </Flex>
                  <Flex
                    w={isLargerThan700 ? '' : 'full'}
                    justifyContent="left"
                    alignItems="center"
                  >
                    <Box
                      mr="10px"
                      w={
                        formaPagamentoEscolhidaValorFixo
                          ? isLargerThan700
                            ? '160px'
                            : 'full'
                          : isLargerThan700
                          ? '100px'
                          : 'full'
                      }
                    >
                      {formaPagamentoEscolhidaValorFixo ? (
                        <NumberInput
                          w="full"
                          id="valorBonificacao"
                          editarFundoLeftElemento
                          bgLeftElement="gray.50"
                          leftElement="R$"
                          name="valorBonificacao"
                          scale={2}
                          colSpan={3}
                        />
                      ) : (
                        <NumberInput
                          w="full"
                          rightElementPorcentagem="%"
                          id="percentualBonificacao"
                          bgLeftElement="gray.50"
                          name="percentualBonificacao"
                          scale={2}
                        />
                      )}
                    </Box>
                  </Flex>
                  <Flex
                    justifyContent="left"
                    alignItems="center"
                    mr="10px"
                    w={isLargerThan700 ? '' : 'full'}
                    mt={isLargerThan700 ? '' : '10px'}
                  >
                    <Text fontSize="16px">para cada vendedor.</Text>
                  </Flex>
                </Flex>
              </Flex>
              <Divider mt="15px" />
              {formaPagamentoEscolhidaValorFixo ? (
                <>
                  {listarFaixaValorFixoBonificacaoVendedor.map((_, index) => (
                    <ListarFaixaValorFixo
                      index={index}
                      handleExcluirFaixaBonificacaoValorFixo={
                        handleExcluirFaixaBonificacaoValorFixo
                      }
                      valorAlcancePremiacaoValorFixoVendedoresWatch={
                        valorAlcancePremiacaoValorFixoVendedoresWatch
                      }
                      obterValorMetaLoja={
                        obterValorMetaLoja?.valorMetaGeral || 0
                      }
                    />
                  ))}
                </>
              ) : (
                <>
                  {listarFaixaPercentualBonificacaoVendedor.map((_, index) => (
                    <ListarFaixaPercentual
                      index={index}
                      handleExcluirFaixaBonificacaoPercent={
                        handleExcluirFaixaBonificacaoPercent
                      }
                      obterValorMetaLoja={
                        obterValorMetaLoja?.valorMetaGeral || 0
                      }
                      valorAlcancePremiacaoVendedoresWatch={
                        valorAlcancePremiacaoVendedoresWatch
                      }
                    />
                  ))}
                </>
              )}
              <Button
                color="blue.700"
                mt="15px"
                mb="20px"
                onClick={() => handleAdicionarFaixa()}
                leftIcon={<Icon as={SalvarInserirNovoIcon} fontSize="lg" />}
                variant="link"
              >
                Adicionar outra faixa
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      <FooterMetasComissoes
        handleAvancar={handleAvancar}
        handleVoltar={handleVoltar}
      />
    </>
  );
};
