import React, { useRef, memo, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone';
import {
  Box,
  Image as ImageChakra,
  Flex,
  Modal,
  Icon,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Input as InputFileChakra,
  Text,
  ImageProps,
} from '@chakra-ui/react';

import {
  isDataURI,
  dataURItoBase64,
  formatBytes,
  byteToSize,
  calcDataUriBytesSize,
  testIfIsUrl,
} from 'helpers/validation/imagesAttachment';

import CampoPrototipo from 'components/PDV/Geral/CampoPrototipo';
import { CameraIcon, IconType } from 'icons';

import WebcamModalPadrao from './WebcamModalPadrao';

interface ImagePickerPadraoProps {
  id: string;
  name: string;
  label?: string;
  required?: boolean;
  h?: ImageProps['height'];
  w?: ImageProps['width'];
  error?: string | undefined;
  readonly?: boolean;
  setImagemFoiAlterada?: React.Dispatch<React.SetStateAction<boolean>>;
  infoText?: string;
  icon?: IconType;
  background?: string;
  objectFit?: ImageProps['objectFit'];
  valueTamanhoImg?: number;
  fontWeightLabel?: string;
  isFormatSizeImage?: boolean;
  isAlterarImagem?: boolean;
  cursor?: string;
  scaleImg?: ImageProps['width'];
  heightBox?: ImageProps['height'];
}

const ImagePickerPadrao = ({
  id,
  name,
  label,
  required,
  cursor = 'pointer',
  isFormatSizeImage = true,
  isAlterarImagem = true,
  h,
  w,
  scaleImg,
  icon = CameraIcon,
  setImagemFoiAlterada,
  error = '',
  readonly,
  infoText,
  valueTamanhoImg = 500,
  objectFit = 'contain',
  background = 'gray.50',
  fontWeightLabel,
  heightBox,
}: ImagePickerPadraoProps) => {
  const inputFileRef = useRef<HTMLInputElement>(null);

  const [showImageIsOpen, setShowImageIsOpen] = useState(false);
  const [webcamScreenshotIsOpen, setWebcamScreenshotIsOpen] = useState(false);
  const [onMouseOver, setOnMouseOver] = useState(false);

  const openUpload = () => {
    if (inputFileRef.current) inputFileRef.current.click();
    setOnMouseOver(false);
  };

  const updatePreviewFormatSize = (file: File, onChange: (v: any) => void) => {
    const fileFormat = file.type.split('/')[1];

    if (fileFormat !== 'png' && fileFormat !== 'jpeg') {
      toast.warning(
        'A extensão do arquivo selecionado é inválida, este campo aceita somente arquivos .png e .jpg'
      );

      return;
    }

    const reader = new FileReader();
    reader.onload = (readerEvent) => {
      const image = new Image();
      image.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = valueTamanhoImg;
        canvas.height = valueTamanhoImg;

        const ctx = canvas.getContext('2d');

        let imageWidth = 0;
        let imageHeight = 0;

        let offsetX = 0;
        let offsetY = 0;

        if (image.height > image.width) {
          imageHeight = valueTamanhoImg;
          imageWidth = (valueTamanhoImg * image.width) / image.height;

          offsetX = (valueTamanhoImg - imageWidth) / 2;
        } else {
          imageWidth = valueTamanhoImg;
          imageHeight = (valueTamanhoImg * image.height) / image.width;

          offsetY = (valueTamanhoImg - imageHeight) / 2;
        }

        if (ctx) {
          ctx.fillStyle = 'white';
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(image, offsetX, offsetY, imageWidth, imageHeight);
        }

        const dataUrlImage = canvas.toDataURL('image/jpeg');

        if (isDataURI(dataUrlImage)) {
          const bytesSize = calcDataUriBytesSize(dataUrlImage);

          if ((byteToSize(bytesSize, 'MB') as number) > 1) {
            toast.warning(
              `O arquivo selecionado é muito grande ${formatBytes(
                bytesSize
              )}, este campo aceita somente imagens de até 1MB`
            );

            return;
          }

          onChange(dataURItoBase64(dataUrlImage));
        } else {
          onChange(null);
        }
        setOnMouseOver(false);
      };

      image.src = readerEvent.target?.result?.toString() || '';
    };

    reader.readAsDataURL(file);
  };

  const updatePreview = (file: File, onChange: (v: any) => void) => {
    const fileFormat = file.type.split('/')[1];

    if (fileFormat !== 'png' && fileFormat !== 'jpeg') {
      toast.warning(
        'A extensão do arquivo selecionado é inválida, este campo aceita somente arquivos .png e .jpg'
      );

      return;
    }

    const reader = new FileReader();
    reader.onload = (readerEvent) => {
      const image = new Image();
      image.onload = () => {
        const imageWidth = image.naturalWidth;
        const imageHeight = image.naturalHeight;

        const canvas = document.createElement('canvas');
        canvas.width = imageWidth;
        canvas.height = imageHeight;

        const ctx = canvas.getContext('2d');

        if (ctx) {
          ctx.fillStyle = 'white';
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(image, 0, 0, imageWidth, imageHeight);
        }

        const dataUrlImage = canvas.toDataURL('image/jpeg');

        if (isDataURI(dataUrlImage)) {
          const bytesSize = calcDataUriBytesSize(dataUrlImage) as number;

          if ((byteToSize(bytesSize, 'MB') as number) > 1) {
            toast.warning(
              `O arquivo selecionado é muito grande ${formatBytes(
                bytesSize
              )}, este campo aceita somente imagens de até 1MB`
            );

            return;
          }

          onChange(dataURItoBase64(dataUrlImage));
        } else {
          onChange(null);
        }
        setOnMouseOver(false);
      };

      image.src = readerEvent.target?.result?.toString() || '';
    };

    reader.readAsDataURL(file);
  };

  const formattedValue = useCallback((value: string) => {
    if (!testIfIsUrl(value)) return `data:image/jpeg;base64,${value}`;

    return value;
  }, []);

  const exibirImagem = (value: any) =>
    value ? (
      <ImageChakra
        borderRadius="4px"
        border={scaleImg ? 'unset' : '1px solid'}
        borderColor="gray.100"
        background={background}
        height={h}
        width={w || scaleImg}
        objectFit={objectFit}
        src={formattedValue(value)}
      />
    ) : (
      <Icon fontSize="34px" color="gray.700" as={icon} />
    );

  return (
    <CampoPrototipo
      id={id}
      label={label}
      errorText={error}
      isRequired={required}
      helperText={infoText}
      name={`${name}` as const}
      fontWeightLabel={fontWeightLabel}
    >
      {(_, { value, onChange, ref, ...fieldProps }) => (
        <Dropzone
          accept="image/jpeg, image/png"
          onDrop={(acceptedFiles: any[]) => {
            if (!value) {
              if (!acceptedFiles || acceptedFiles.length === 0) {
                return;
              }
              const file = acceptedFiles[0];

              if (file) {
                if (isFormatSizeImage) {
                  updatePreviewFormatSize(file, onChange);
                } else {
                  updatePreview(file, onChange);
                }
              }
            }
          }}
        >
          {({ getRootProps, isDragActive }) => (
            <Box
              {...getRootProps()}
              cursor={readonly ? undefined : cursor}
              filter={isDragActive && !value ? 'brightness(80%)' : undefined}
              height={h}
              borderStyle={value ? undefined : 'solid'}
              borderWidth={value ? undefined : '1px'}
              width={w}
              bg={background}
              borderRadius="5px"
              onMouseLeave={() => setOnMouseOver(false)}
              onClick={isAlterarImagem ? () => setOnMouseOver(true) : undefined}
            >
              {onMouseOver ? (
                <Flex
                  boxShadow="0px 0px 6px #00000034"
                  pt="5%"
                  alignItems="center"
                  w="full"
                  h={heightBox || h}
                  pb="5%"
                  bg="white"
                  borderRadius="5px"
                >
                  <Box w="full">
                    {value ? (
                      <>
                        <Text
                          textAlign="left"
                          pl="5%"
                          whiteSpace="nowrap"
                          fontSize="14px"
                          zIndex="9999"
                          py="2%"
                          _hover={{ background: 'gray.100' }}
                          onClick={() => {
                            setShowImageIsOpen(true);
                          }}
                        >
                          Ver foto
                        </Text>

                        <Text
                          textAlign="left"
                          pl="5%"
                          whiteSpace="nowrap"
                          fontSize="14px"
                          _hover={{ background: 'gray.100' }}
                          py="2%"
                          onClick={openUpload}
                        >
                          Escolher outra foto
                        </Text>
                        <Text
                          textAlign="left"
                          pl="5%"
                          whiteSpace="nowrap"
                          fontSize="14px"
                          py="2%"
                          _hover={{ background: 'gray.100' }}
                          onClick={() => {
                            setWebcamScreenshotIsOpen(true);
                            setOnMouseOver(false);
                          }}
                        >
                          Tirar outra foto
                        </Text>
                        <Text
                          textAlign="left"
                          pl="5%"
                          fontSize="14px"
                          whiteSpace="nowrap"
                          py="2%"
                          _hover={{ background: 'gray.100' }}
                          onClick={() => {
                            onChange(null);
                            setOnMouseOver(false);
                          }}
                        >
                          Remover
                        </Text>
                      </>
                    ) : (
                      <>
                        <Text
                          fontSize="14px"
                          textAlign="left"
                          pl="5%"
                          whiteSpace="nowrap"
                          pt="7px"
                          _hover={{ background: 'gray.100' }}
                          pb="7px"
                          onClick={openUpload}
                        >
                          Escolher foto
                        </Text>
                        <Text
                          fontSize="14px"
                          textAlign="left"
                          pl="5%"
                          whiteSpace="nowrap"
                          pt="7px"
                          _hover={{ background: 'gray.100' }}
                          pb="7px"
                          onClick={() => {
                            setWebcamScreenshotIsOpen(true);
                            setOnMouseOver(false);
                          }}
                        >
                          Tirar foto
                        </Text>
                      </>
                    )}
                  </Box>
                </Flex>
              ) : (
                <>
                  <Flex
                    flexDirection="column"
                    w="full"
                    position="relative"
                    alignItems="center"
                    h="full"
                    justifyContent="center"
                  >
                    {exibirImagem(value)}
                  </Flex>
                </>
              )}

              <WebcamModalPadrao
                show={webcamScreenshotIsOpen}
                setShow={setWebcamScreenshotIsOpen}
                onChange={onChange}
                onClose={() => setOnMouseOver(false)}
              />

              {value && (
                <Modal
                  isOpen={showImageIsOpen}
                  isCentered
                  onOverlayClick={() => setOnMouseOver(false)}
                  onClose={() => {
                    setShowImageIsOpen(false);
                    setOnMouseOver(false);
                  }}
                >
                  <ModalOverlay />
                  <ModalContent>
                    <ModalBody>
                      <Flex
                        w="full"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <ImageChakra
                          borderRadius="4px"
                          src={formattedValue(value)}
                        />
                      </Flex>
                    </ModalBody>
                  </ModalContent>
                </Modal>
              )}

              <InputFileChakra
                {...fieldProps}
                tabIndex={-1}
                onChange={(event) => {
                  const { files } = event.target;
                  if (!files || files.length === 0) {
                    return;
                  }
                  const file = files[0];
                  if (setImagemFoiAlterada) {
                    setImagemFoiAlterada(true);
                  }
                  if (file) {
                    if (isFormatSizeImage) {
                      updatePreviewFormatSize(file, onChange);
                    } else {
                      updatePreview(file, onChange);
                    }
                  }
                }}
                display="none"
                ref={inputFileRef}
                type="file"
                accept="image/jpeg, image/png"
              />
            </Box>
          )}
        </Dropzone>
      )}
    </CampoPrototipo>
  );
};

export default memo(ImagePickerPadrao);
