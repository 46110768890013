import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

import { ProdutoResponse } from './Components/ModalAdicionarProduto';

export type Produto = ProdutoResponse;

export type ProdutoPromocaoProps = {
  produtoCorTamanhoId: string;
  precoPromocao: number;
  quantidade: number;
  descontoAcimaQuantidade: boolean;
};

export type FormData = {
  nome: string;
  periodoVigenciaInicio: string;
  periodoVigenciaFim: string;
  horarioVigenciaInicio: string;
  horarioVigenciaFim: string;
  diasDaSemana: DiasSemanaProps[];
  telasUsoPromocao: boolean[];
  lojas: string[];
  produtos: Produto[];
  ativo: boolean;
};

export const listDiasSemana = [
  {
    label: 'Domingo',
    ativo: false,
    value: 0,
  },
  {
    label: 'Segunda',
    ativo: false,
    value: 1,
  },
  {
    label: 'Terça',
    ativo: false,
    value: 2,
  },
  {
    label: 'Quarta',
    ativo: false,
    value: 3,
  },
  {
    label: 'Quinta',
    ativo: false,
    value: 4,
  },
  {
    label: 'Sexta',
    ativo: false,
    value: 5,
  },
  {
    label: 'Sabado',
    ativo: false,
    value: 6,
  },
];

export type DiasSemanaProps = {
  label: string;
  ativo: boolean;
  value: number;
};

const schema = yup.object().shape({
  nome: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .max(50, ConstanteMensagemValidacao.MAX_LENGTH_40),

  periodoVigenciaInicio: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  periodoVigenciaFim: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  horarioVigenciaInicio: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  horarioVigenciaFim: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);

export const defaultValues = {
  nome: '',
  lojas: [],
  ativo: true,
  diasDaSemana: listDiasSemana,
  telasUsoPromocao: [],
};
