import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useCallback, useState, useEffect } from 'react';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { ImprimirPDF } from 'helpers/impressoes/imprimirPDF';
import { setDateMinHours } from 'helpers/data/setHoursDate';

import {
  defaultFormValue,
  yupResolver,
  ResponseCategoriaProps,
  CategoriaProps,
  FormData,
} from './validationForms';

export const useInformacoesCliente = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [listCategorias, setListCategorias] = useState<CategoriaProps[]>([]);

  const formMethods = useForm<FormData>({
    defaultValues: defaultFormValue,
    resolver: yupResolver,
  });

  const { handleSubmit, watch, reset } = formMethods;

  const watchTipoRelatorio = watch('tipoRelatorio');

  const handleGerarRelatorio = handleSubmit(async (data: FormData) => {
    setIsLoading(true);

    const dataUltimaCompra = data.dataUltimaCompra
      ? setDateMinHours(new Date(data.dataUltimaCompra))
      : undefined;
    dataUltimaCompra?.setDate(dataUltimaCompra.getDate() + 1);

    const filtros = {
      periodoInicio: data.periodoInicio?.toISOString(),
      periodoFim: data.periodoFim?.toISOString(),
      categoriaClienteId: data?.categoriaClienteId?.value,
      categoriaClienteNome: data?.categoriaClienteId?.label,
      cidadeId: data?.cidadeId?.value,
      cidadeNome: data?.cidadeId?.label,
      status: data.status,
      dataUltimaCompra: dataUltimaCompra?.toISOString(),
      ordenacao: data.ordenacao,
      mesAniversariante: data.mesAniversariante,
    };

    const response = await api.get<void, ResponseApi<string>>(
      `${ConstanteEnderecoWebservice.CLIENTES_INFORMACOES}/${data.tipoRelatorio}/pdf`,
      {
        params: { ...filtros },
      }
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }
      if (response?.sucesso && response?.dados) {
        ImprimirPDF(response.dados, 'Relatorio-informacoes-clientes');
      }
    }

    setIsLoading(false);
  });

  const getCategorias = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get<void, ResponseApi<ResponseCategoriaProps[]>>(
      ConstanteEnderecoWebservice.CATEGORIA_CLIENTE_SELECT
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response?.sucesso) {
        setListCategorias(
          response.dados.map((categoria) => {
            return {
              label: categoria.nome,
              value: categoria.id,
            };
          })
        );
      }
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    reset({ ...defaultFormValue, tipoRelatorio: watchTipoRelatorio });
  }, [reset, watchTipoRelatorio]);

  useEffect(() => {
    getCategorias();
  }, [getCategorias]);

  return {
    isLoading,
    formMethods,
    listCategorias,
    handleGerarRelatorio,
    watchTipoRelatorio,
  };
};
