import { Button, Stack, useMediaQuery } from '@chakra-ui/react';

import { ButtonDefault } from 'components/Button/ButtonChakra';
import { ModalAvisoGerarTroca } from 'components/PDV/Modal/ModalAvisoGerarTroca/index';

import { ClienteListarProps } from '../Types/validationForm';

type TrocasFooterProps = {
  handleAbrirModalDevolverDinheiro: () => void;
  existeProdutoTrocaSelecionado: boolean;
  handleGerarTroca: () => void;
  handleGerarVoucher: () => void;
  cliente: ClienteListarProps;
  valorTotal: number;
  isLoading: boolean;
};

export function TrocasFooter({
  handleGerarTroca,
  handleGerarVoucher,
  isLoading,
  existeProdutoTrocaSelecionado,
  handleAbrirModalDevolverDinheiro,
  cliente,
  valorTotal,
}: TrocasFooterProps) {
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const handleAbrirModalAvisoGerarTroca = () => {
    ModalAvisoGerarTroca({
      nomeCliente: cliente.nome,
      valorTotalTroca: valorTotal,
      handleGerarTroca,
    });
  };

  return (
    <Stack
      mr={isLargerThan900 ? '40px' : '0'}
      mt={isLargerThan900 ? '0' : '24px'}
      direction={isLargerThan900 ? 'row' : 'column-reverse'}
      align={isLargerThan900 ? 'right' : 'center'}
      justifyContent={isLargerThan900 ? 'right' : 'center'}
      h="100%"
      spacing={4}
    >
      <Button
        id="devolverDinheiro"
        name="devolverDinheiro"
        color="#CCCCCC"
        variant="outline"
        isDisabled={!existeProdutoTrocaSelecionado}
        borderRadius="20px"
        fontSize={isLargerThan900 ? 'sm' : 'md'}
        type="button"
        _hover={{ bg: 'gray.50' }}
        onClick={() => handleAbrirModalDevolverDinheiro()}
        h={isLargerThan900 ? '32px' : '40px'}
        width={isLargerThan900 ? '136px' : 'full'}
      >
        Devolver dinheiro
      </Button>
      <ButtonDefault
        id="gerarCredito"
        name="gerarCredito"
        isLoading={isLoading}
        color="#CCCCCC"
        variant="outline"
        isDisabled={!existeProdutoTrocaSelecionado}
        borderRadius="20px"
        fontSize={isLargerThan900 ? 'sm' : 'md'}
        type="button"
        _hover={{ bg: 'gray.50' }}
        onClick={() => handleGerarVoucher()}
        h={isLargerThan900 ? '32px' : '40px'}
        width={isLargerThan900 ? '160px' : 'full'}
      >
        Gerar crédito voucher
      </ButtonDefault>
      <Button
        id="trocarProduto"
        name="trocarProduto"
        color="black"
        backgroundColor="secondary.400"
        isDisabled={!existeProdutoTrocaSelecionado}
        variant="sucess"
        borderRadius="20px"
        onClick={() => handleAbrirModalAvisoGerarTroca()}
        fontSize={isLargerThan900 ? 'sm' : 'md'}
        type="button"
        _hover={{ bg: 'secondary.400' }}
        h={isLargerThan900 ? '32px' : '40px'}
        w={isLargerThan900 ? '140px' : 'full'}
      >
        Trocar produtos
      </Button>
    </Stack>
  );
}
