import React, { useState } from 'react';
import { Controller } from 'react-hook-form';

import { InputProps } from 'components/Input/InputPadrao';
import ContainerInputPadrao from 'components/Layout/CampoContainer/CampoContainerPadrao';
import { InputGroup } from 'react-bootstrap';
import { LabelInputFile, SpanFileName } from './styles';

interface InputFileProps extends InputProps {
  control: any;
  prependText?: any;
  setValues: any;
}

const InputFile = ({
  id,
  name,
  label,
  control,
  error,
  infoText,
  defaultValue,
  prependText,
  setValues,
  ...rest
}: InputFileProps) => {
  const [fileName, setFilename] = useState('Nenhum arquivo selecionado');

  return (
    <ContainerInputPadrao id={id} label="" error={error} infoText={infoText}>
      <InputGroup>
        <Controller
          defaultValue={defaultValue}
          render={({ field }) => (
            <>
              <LabelInputFile htmlFor={id}>{label}</LabelInputFile>
              <input
                ref={field.ref}
                id={id}
                name={name}
                type="file"
                style={{ display: 'none' }}
                onChange={(selected: React.ChangeEvent<HTMLInputElement>) => {
                  if (
                    selected.target.files &&
                    selected.target.files.length > 0
                  ) {
                    setFilename(selected.target.files[0].name);
                    field.onChange(selected.target.files[0]);
                  }
                }}
                {...rest}
              />
              <SpanFileName>{fileName ?? ''}</SpanFileName>
            </>
          )}
          name={`${name}` as const}
          // onFocus={() => {
          //   if (inputRef) inputRef.current.focus();
          //   else ref.current.focus();
          // }}
          control={control}
        />
      </InputGroup>
    </ContainerInputPadrao>
  );
};

export default InputFile;
