import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import ConstanteRotas from 'constants/rotas';
import auth from 'modules/auth';
import enumReferenciaServicoStargate from 'constants/enum/referenciaServicoStargate';
import { usePdvAutonomoContext } from 'store/PdvAutonomo';

import { Configuracao } from './Configuracao';
import { FormaRecebimento } from './FormaRecebimento';
import { Dados } from './Dados';
import { Dispositivo } from './Dispositivo';
import { TabelaPreco } from './TabelaPreco';

type TabsProps = {
  label: string;
  value: number;
  content: JSX.Element;
};

export const usePdvAutonomo = () => {
  const { infoIntegracao, isLoading } = usePdvAutonomoContext();

  const valueTabs = [
    {
      label: 'Dados',
      value: 0,
      content: <Dados />,
      isDisabled: false,
    },
    {
      label: 'Configuração',
      isDisabled: !infoIntegracao?.sincronizacaoHabilitada,
      value: 1,
      content: <Configuracao />,
    },
    {
      label: 'Forma recebimento',
      isDisabled: !infoIntegracao?.sincronizacaoHabilitada,
      value: 2,
      content: <FormaRecebimento />,
    },
    {
      label: 'Tabela de preço',
      isDisabled: !infoIntegracao?.sincronizacaoHabilitada,
      value: 3,
      content: <TabelaPreco />,
    },
    {
      label: 'Dispositivos',
      isDisabled: !infoIntegracao?.sincronizacaoHabilitada,
      value: 4,
      content: <Dispositivo />,
    },
  ];

  const possuiServicoPdvAutonomo = auth.possuiServico(
    enumReferenciaServicoStargate.DISPOSITIVO_PDV
  ).permitido;

  const [activeStep, setActiveStep] = useState<TabsProps>(valueTabs[0]);

  const history = useHistory();

  function handleVoltarPainelAdm() {
    history.push(ConstanteRotas.LOJA_APLICATIVOS);
  }

  const handleUpdateStep = (itemTab: TabsProps) => {
    setActiveStep(itemTab);
  };

  useEffect(() => {
    if (!possuiServicoPdvAutonomo) {
      history.push(ConstanteRotas.LOJA_APLICATIVOS);
    }
  }, [history, possuiServicoPdvAutonomo]);

  return {
    handleVoltarPainelAdm,
    infoIntegracao,
    handleUpdateStep,
    isLoading,
    activeStep,
    valueTabs,
    setActiveStep,
  };
};
