import { useListPermissoesValidar } from 'hooks/useListPermissoesValidar';
import ConstanteFuncionalidades from 'constants/permissoes';

const PermissaoRelatorioEstoque = (): string => {
  const listFuncionalidades = [
    ConstanteFuncionalidades.RELATORIO_ESTOQUE_SEM_PRECO,
    ConstanteFuncionalidades.RELATORIO_ESTOQUE_PRECO_VENDA,
    ConstanteFuncionalidades.RELATORIO_ESTOQUE_PRECO_CUSTO,
  ];

  const validacaoFuncionalidade = useListPermissoesValidar(listFuncionalidades);

  return validacaoFuncionalidade;
};

export default PermissaoRelatorioEstoque;
