import { useCallback, useEffect, useRef, useState } from 'react';
import {
  RiArrowDropDownLine,
  RiArrowDropUpLine,
  RiShareLine,
} from 'react-icons/ri';
import {
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  Text,
  Stack,
  Checkbox,
  useMediaQuery,
  useBreakpointValue,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  GridItem,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import TipoSituacaoBaixa from 'constants/enum/situacaoBaixa';
import { shallowEqual } from 'helpers/validation/shallowEqual';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';
import ConstanteFuncionalidades from 'constants/permissoes';
import { useContextFiltrosContasReceber } from 'store/Financeiro/ContasReceber';

import {
  LixeiraIcon,
  FinanceiroBaixarContas,
  OpcoesIcon,
  FinanceiroAReceber,
  SalvarInserirNovoIcon,
} from 'icons';
import InputDateRange from 'components/PDV/InputDateRange';
import { BuscaAvancadaButton } from 'components/update/BuscaAvancadaButton';
import { TextValor } from 'components/PDV/Text/TextValor';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import {
  PagedTable,
  PagedTableForwardRefData,
} from 'components/update/Table/PagedTable';
import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import { PaginationData } from 'components/update/Pagination';
import { ButtonFuncionalidade } from 'components/update/Button/ButtonFuncionalidade';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { ModalCompartilhar } from 'components/Modal/ModalCompartilhar';
import ImpressaoCupom56mm from 'components/Impressao/impressaoCupom56mm/ImpressaoCupom56mm';
import { ImpressaoCupom56mmRefElement } from 'components/Impressao/impressaoCupom56mm/types';
import ImpressaoCupom80mm from 'components/Impressao/impressaoCupom80mm/ImpressaoCupom80mm';
import { ImpressaoCupom80mmRefElement } from 'components/Impressao/impressaoCupom80mm/types';

import { ContasReceberItem } from './ContasReceberItem';
import { Conta, FormData, formDefaultValues } from './validationForm';
import { ModalBuscaAvancada } from './ModalBuscaAvancada';
import { ModalBaixarContas } from './ModalBaixarContasAReceber';
import {
  BaixarContaItem,
  BaixarContaList,
  compartilharRelatorio,
  enviarEmailReciboA4,
  imprimirPdfReciboA4,
} from './Compartilhar';

type Totais = {
  valorTotalDescontos: number;
  valorTotalMultasJuros: number;
  valorTotalReceber: number;
};

type InformacoesGeraisListagem = Totais & {
  quantidadeContas: number;
};

type GridPaginadaRetornoContasReceber = GridPaginadaRetorno<Conta> & Totais;

const pageSizeOptions = [
  { label: '5', value: 5 },
  { label: '10', value: 10 },
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

export function ListarContasReceber() {
  const history = useHistory();

  const {
    carregarDadosContexto,
    setLastFilters,
    lastFilters,
    fetchTotalPerPeriod,
    totalPerPeriod,
  } = useContextFiltrosContasReceber();

  const [isSmallerThan965] = useMediaQuery('(max-width: 965px)');
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const isResponsiveVersion = useBreakpointValue({
    base: true,
    md: true,
    lg: false,
  });

  const {
    casasDecimais: { casasDecimaisValor },
  } = usePadronizacaoContext();

  const formMethods = useForm<FormData>({
    defaultValues: carregarDadosContexto.current
      ? lastFilters || formDefaultValues
      : formDefaultValues,
  });

  const pagedTableRef = useRef<PagedTableForwardRefData>(null);

  const [hasFilters, setHasFilters] = useState(
    !!(carregarDadosContexto.current && lastFilters)
  );
  const [currentFilters, setCurrentFilters] = useState<FormData>(
    carregarDadosContexto.current
      ? lastFilters || formDefaultValues
      : formDefaultValues
  );

  const [isLoading, setIsLoading] = useState(false);
  const [columnsData, setColumnsData] = useState<Conta[]>([]);
  const [
    informacoesGeraisListagem,
    setInformacoesGeraisListagem,
  ] = useState<InformacoesGeraisListagem>({
    quantidadeContas: 0,
    valorTotalReceber: 0,
    valorTotalDescontos: 0,
    valorTotalMultasJuros: 0,
  });

  const [movimentacaoIdImpressao, setIdMovimentacaoImpressao] = useState<
    string[]
  >([]);

  // variável de controle para que não seja realizada duas vezes a consulta ao sujar o formulário
  const lastAmountQuantidadeContas = useRef<number | undefined>(0);
  // variável de controle,se for false significa que o ganço para executar a função "fetchTotalPeriod" vem sempre do total de registros da listagem,
  // caso contrario o estado currentFilters foi alterado
  const wasActivePerFormFetch = useRef<boolean | undefined>(false);

  const [deveSelecionarTodosItens, setDeveSelecionarTodosItens] = useState(
    false
  );

  const [isFirstRender, setIsFirstRender] = useState<boolean | undefined>();

  const cupom56mmComponentToPrintRef = useRef<ImpressaoCupom56mmRefElement>(
    null
  );
  const cupom80mmComponentToPrintRef = useRef<ImpressaoCupom80mmRefElement>(
    null
  );

  // função criada apenas para verificar se o filtro é o padrão, se for, será realizada a consulta dos novos totais.
  useEffect(() => {
    async function isFirstRenderization() {
      setIsFirstRender(false);
      const isEqual = shallowEqual(currentFilters, formDefaultValues);
      if (isEqual) {
        await fetchTotalPerPeriod(currentFilters);
      }
      setIsFirstRender(true);
    }
    isFirstRenderization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function reloadTotal() {
      if (
        informacoesGeraisListagem.quantidadeContas !==
          lastAmountQuantidadeContas.current &&
        !wasActivePerFormFetch.current
      ) {
        await fetchTotalPerPeriod(currentFilters);
        lastAmountQuantidadeContas.current =
          informacoesGeraisListagem.quantidadeContas;
      } else {
        wasActivePerFormFetch.current = false;
      }
    }
    reloadTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [informacoesGeraisListagem.quantidadeContas]);

  const contasPagasSelecionadas = columnsData?.filter(
    (item) => item?.selecionado && item?.movimentacaoFinanceiraBaixa?.length > 0
  );

  const qtdContasEmAbertoSelecionadas = columnsData?.filter(
    (item) =>
      item.selecionado && item?.movimentacaoFinanceiraBaixa?.length === 0
  )?.length;

  const qntContasPagasSelecionadas = contasPagasSelecionadas?.length;

  const hasContasPagasSelecionadasComOperacaoIdIgual =
    qntContasPagasSelecionadas > 0 &&
    contasPagasSelecionadas?.every(
      (item) =>
        item?.clienteFornecedorId ===
        contasPagasSelecionadas[0]?.clienteFornecedorId
    );

  const vlrTotalContasPagasSelecionadas = contasPagasSelecionadas?.reduce(
    (acc, item) =>
      acc +
      (item?.movimentacaoFinanceiraBaixa?.reduce(
        (ac, movimentacao) => ac + (movimentacao?.valor || 0),
        0
      ) || 0),
    0
  );

  const temContaEmAbertoSelecionada = qtdContasEmAbertoSelecionadas > 0;
  const temContaPagaSelecionada = qntContasPagasSelecionadas > 0;

  const showOpcoesFiltros =
    (!temContaPagaSelecionada && !temContaEmAbertoSelecionada) ||
    (temContaPagaSelecionada && temContaEmAbertoSelecionada) ||
    (temContaPagaSelecionada && !hasContasPagasSelecionadasComOperacaoIdIgual);

  function handleLimparItensSelecionados() {
    setColumnsData((valorAnterior) =>
      valorAnterior.map((item) => ({
        ...item,
        selecionado: false,
      }))
    );

    setDeveSelecionarTodosItens(false);
  }

  const getPrintFunctions56mm = async () => {
    if (cupom56mmComponentToPrintRef.current) {
      await cupom56mmComponentToPrintRef.current.imprimirCupom56mm();
    }
  };

  const getPrintFunctions80mm = async () => {
    if (cupom80mmComponentToPrintRef.current) {
      await cupom80mmComponentToPrintRef.current.imprimirCupom80mm();
    }
  };

  const compartilharContaRecebida = (
    dados: BaixarContaItem | BaixarContaList
  ) => {
    const idMovimentacaoFinanceira = Array.isArray(dados)
      ? dados?.map((conta) => conta.movimentacaoFinanceiraId)
      : [dados.movimentacaoFinanceiraId];
    setIdMovimentacaoImpressao(idMovimentacaoFinanceira);

    ModalCompartilhar({
      items: [
        {
          titulo: 'Recibo A4',
          onClickImpressao: async () => {
            await imprimirPdfReciboA4(idMovimentacaoFinanceira);
          },
          onClickEmail: async () => {
            await enviarEmailReciboA4(idMovimentacaoFinanceira);
          },
        },
        {
          titulo: 'Cupom 80mm',
          onClickImpressao: async () => {
            await getPrintFunctions80mm();
          },
        },
        {
          titulo: 'Cupom 56mm',
          onClickImpressao: async () => {
            await getPrintFunctions56mm();
          },
        },
      ],
    });
  };

  async function handleBaixarConta(indexes: number[]) {
    const {
      dataVencimento,
      clienteFornecedorRazaoSocial,
      clienteFornecedorNome,
      clienteFornecedorId,
      parcela,
      dataEmissao,
      valorOriginal,
    } = indexes.length === 1 ? columnsData[indexes[0]] : ({} as Conta);

    let multa = 0;
    let juros = 0;
    let desconto = 0;
    let valueTotalOfTheCountsReceive = 0;

    const { sucesso, reloadHeards } = await ModalBaixarContas({
      baixarContaItems: indexes.map((index) => {
        const item = columnsData[index];
        multa += item.multa;
        juros += item.juros;
        desconto = item.desconto;

        if (indexes.length > 1)
          valueTotalOfTheCountsReceive += item.valorOriginal;

        return {
          desconto: item.desconto,
          juros: item.juros,
          multa: item.multa,
          valor: item.valor,
          movimentacaoFinanceiraId: item.movimentacaoFinanceiraId,
        };
      }),
      dataVencimento,
      clienteFornecedorRazaoSocial,
      clienteFornecedorNome,
      clienteFornecedorId: clienteFornecedorId || '',
      casasDecimaisValor,
      parcela,
      dataEmissao,
      valorMultas: multa,
      valorJuros: juros,
      valorDesconto: desconto,
      valorOriginal:
        indexes.length > 1 ? valueTotalOfTheCountsReceive : valorOriginal,
      compartilharContaRecebida,
    });

    if (sucesso && pagedTableRef.current) {
      setDeveSelecionarTodosItens(false);
      pagedTableRef.current.reload();
      if (reloadHeards) {
        fetchTotalPerPeriod(currentFilters);
      }
    }
  }

  async function handleBaixarContasSelecionadas() {
    const indexes = columnsData
      .map(({ selecionado }, index) => ({ index, selecionado }))
      .filter((item) => item.selecionado)
      .map((item) => item.index);

    if (indexes.length > 0) {
      handleBaixarConta(indexes);
    }
  }

  async function handleExcluirContas(operacoes: string[]) {
    ModalConfirmacaoExcluir({
      title: 'Remover a conta?',
      text:
        'A conta será excluída da sua movimentação de contas a receber. Essa ação não poderá ser desfeita. Você tem certeza que deseja remover?',
      confirmButtonText: 'Sim, remover!',
      cancelButtonText: 'Cancelar',
      callback: async (ok: boolean) => {
        if (ok) {
          const response = await api.delete<void, ResponseApi>(
            ConstanteEnderecoWebservice.CONTAS_A_RECEBER_EXCLUIR,
            { data: { operacoes } }
          );

          if (response) {
            if (response.avisos) {
              response.avisos.forEach((aviso) => toast.warning(aviso));
            }

            if (response.sucesso && pagedTableRef.current) {
              setDeveSelecionarTodosItens(false);
            }

            if (pagedTableRef.current) {
              pagedTableRef.current.reload();
            }
          }
        }
      },
    });
  }

  async function handleExcluirContasSelecionadas() {
    const operacoes = columnsData
      .filter((item) => item.selecionado)
      .map((item) => item.operacaoId);

    if (operacoes.length > 0) {
      handleExcluirContas(operacoes);
    }
  }

  async function handleCancelarBaixa(movimentacaoFinanceiraId: string) {
    const valoresMovimentacao = columnsData?.filter(
      (item) => item?.movimentacaoFinanceiraId === movimentacaoFinanceiraId
    );
    const totalAmount =
      valoresMovimentacao[0].valor +
      valoresMovimentacao[0].juros +
      valoresMovimentacao[0].multa;

    ModalConfirmacaoExcluir({
      title: 'Cancelar a baixa?',
      text:
        'A baixa do recebimento será cancelada e a conta voltará a constar como um lançamento financeiro em aberto. Você tem certeza que deseja cancelar?',
      confirmButtonText: 'Sim, cancelar!',
      cancelButtonText: 'Cancelar',
      callback: async (ok: boolean) => {
        if (ok) {
          const response = await api.delete<void, ResponseApi>(
            ConstanteEnderecoWebservice.CONTAS_RECEBER_EXCLUIR_BAIXA,
            { data: { movimentacoesFinanceiras: [movimentacaoFinanceiraId] } }
          );

          if (response) {
            if (response.avisos) {
              response.avisos.forEach((aviso) => toast.warning(aviso));
            }

            if (response.sucesso && pagedTableRef.current) {
              setDeveSelecionarTodosItens(false);
              pagedTableRef.current.reload();

              if (valoresMovimentacao[0].valor !== totalAmount) {
                await fetchTotalPerPeriod(currentFilters);
              }
            }
          }
        }
      },
    });
  }

  function handleNovaConta() {
    const hasLastFilters = !shallowEqual(currentFilters, formDefaultValues);
    if (hasLastFilters) carregarDadosContexto.current = true;
    history.push(ConstanteRotas.CONTAS_RECEBER_CADASTRAR_LANCAMENTO);
  }

  function handleEditarConta(id: string) {
    carregarDadosContexto.current = true;
    history.push(
      SubstituirParametroRota(
        ConstanteRotas.CONTAS_RECEBER_ALTERAR_LANCAMENTO,
        'id',
        id
      )
    );
  }

  async function filtersSubmit(data: FormData) {
    const filtersIsDirty = !shallowEqual(data, currentFilters);
    const wasFiltered = !shallowEqual(data, formDefaultValues);

    setHasFilters(wasFiltered);

    if (filtersIsDirty) {
      wasActivePerFormFetch.current = true;
      formMethods.reset(data);
      setLastFilters(data);
      setCurrentFilters(data);
      fetchTotalPerPeriod(data);
    }
  }

  const handleSubmit = formMethods.handleSubmit((data) => filtersSubmit(data));

  const createFilters = useCallback(() => {
    let filters;
    if (lastFilters && carregarDadosContexto.current) {
      filters = {
        ...lastFilters,
        identificador: lastFilters.identificador || 0,
        dataVencimentoInicio:
          lastFilters?.dataVencimentoInicio?.toISOString() || '',
        dataVencimentoFim: lastFilters?.dataVencimentoFim?.toISOString() || '',
        dataEmissaoInicio: lastFilters.dataEmissaoInicio
          ? lastFilters.dataEmissaoInicio.toISOString()
          : '',
        dataEmissaoFim: lastFilters.dataEmissaoFim
          ? lastFilters.dataEmissaoFim.toISOString()
          : '',
        dataPagamentoInicio: lastFilters.dataPagamentoInicio
          ? lastFilters.dataPagamentoInicio.toISOString()
          : '',
        dataPagamentoFim: lastFilters?.dataPagamentoFim
          ? lastFilters.dataPagamentoFim?.toISOString()
          : '',
        clienteFornecedorId: lastFilters?.clienteFornecedorId?.value ?? '',
        planoContasId: lastFilters.planoContasId ?? '',
        situacao: lastFilters.situacao || '',
        dataCompensacaoFim: lastFilters.dataCompensacaoFim?.toISOString() ?? '',
        dataCompensacaoInicio:
          lastFilters.dataCompensacaoInicio?.toISOString() ?? '',
        formaPagamentoEmissaoId: lastFilters.formaPagamentoEmissaoId || '',
        formaPagamentoBaixaId: lastFilters.formaPagamentoBaixaId || '',
      };
      carregarDadosContexto.current = false;
      setCurrentFilters(lastFilters);
    } else {
      filters = {
        ...currentFilters,
        identificador: currentFilters.identificador || 0,
        dataVencimentoInicio:
          currentFilters?.dataVencimentoInicio?.toISOString() || '',
        dataVencimentoFim:
          currentFilters?.dataVencimentoFim?.toISOString() || '',
        dataEmissaoInicio: currentFilters.dataEmissaoInicio
          ? currentFilters.dataEmissaoInicio.toISOString()
          : '',
        dataEmissaoFim: currentFilters.dataEmissaoFim
          ? currentFilters.dataEmissaoFim.toISOString()
          : '',
        dataPagamentoInicio: currentFilters.dataPagamentoInicio
          ? currentFilters.dataPagamentoInicio.toISOString()
          : '',
        dataPagamentoFim: currentFilters?.dataPagamentoFim
          ? currentFilters.dataPagamentoFim?.toISOString()
          : '',
        clienteFornecedorId: currentFilters?.clienteFornecedorId?.value ?? '',
        planoContasId: currentFilters.planoContasId ?? '',
        situacao: currentFilters.situacao || '',
        dataCompensacaoFim:
          currentFilters.dataCompensacaoFim?.toISOString() ?? '',
        dataCompensacaoInicio:
          currentFilters.dataCompensacaoInicio?.toISOString() ?? '',
        formaPagamentoEmissaoId: currentFilters.formaPagamentoEmissaoId || '',
        formaPagamentoBaixaId: currentFilters.formaPagamentoBaixaId || '',
      };
    }

    return filters;
  }, [lastFilters, carregarDadosContexto, currentFilters]);

  const loadColumnsData = useCallback(
    async (paginationData: PaginationData) => {
      setIsLoading(true);

      const params = createFilters();

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetornoContasReceber>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.CONTAS_RECEBER_LISTAR_PAGINADO,
          paginationData,
          params
        )
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response.sucesso && response.dados) {
          setDeveSelecionarTodosItens(false);

          setColumnsData(
            (response.dados.registros || []).map((item) => ({
              ...item,
              selecionado: false,
            }))
          );

          setInformacoesGeraisListagem({
            quantidadeContas: response.dados.total || 0,
            valorTotalReceber: response.dados.valorTotalReceber || 0,
            valorTotalDescontos: response.dados.valorTotalDescontos || 0,
            valorTotalMultasJuros: response.dados.valorTotalMultasJuros || 0,
          });
        }
      }

      setIsLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentFilters]
  );

  async function handlePushModalBuscaAvancada() {
    const novosFiltros = await ModalBuscaAvancada({
      currentFilters,
    });

    filtersSubmit(novosFiltros);
  }

  function handleToggleItemSelecionado(index: number) {
    const itensSelecionados = [...columnsData];
    const itemSelecionado = itensSelecionados[index];

    itensSelecionados.splice(index, 1, {
      ...itemSelecionado,
      selecionado: !itemSelecionado.selecionado,
    });

    setColumnsData(itensSelecionados);
  }

  function handleToggleDeveSelecionarTodosItens() {
    setColumnsData((valorAnterior) =>
      valorAnterior.map((item) => {
        return {
          ...item,
          selecionado: !deveSelecionarTodosItens,
        };
      })
    );

    setDeveSelecionarTodosItens(!deveSelecionarTodosItens);
  }

  const handleCompartilharOperacao = useCallback(async () => {
    const dados = createFilters();

    compartilharRelatorio(dados);
  }, [createFilters]);

  async function handleCompartilharContasRecebidas() {
    const indexes = columnsData
      .map(({ selecionado }, index) => ({ index, selecionado }))
      .filter((item) => item.selecionado)
      .map((item) => item.index);

    const compartilharContaItens = indexes.map((index) => {
      const item = columnsData[index];

      return {
        desconto: item.desconto,
        juros: item.juros,
        multa: item.multa,
        valor: item.valor,
        movimentacaoFinanceiraId: item.movimentacaoFinanceiraId,
      };
    });

    if (indexes.length > 0) {
      compartilharContaRecebida(compartilharContaItens);
    }
  }

  return (
    <>
      <FormProvider {...formMethods}>
        {!temContaPagaSelecionada && temContaEmAbertoSelecionada && (
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing={{ base: 3, sm: 6, md: 8 }}
            w="full"
          >
            <ButtonFuncionalidade
              funcionalidade={ConstanteFuncionalidades.CONTAS_RECEBER_BAIXA}
              borderRadius="full"
              variant="outlineDefault"
              colorScheme="gray"
              height="32px"
              padding="10px 24px"
              minW="160px"
              leftIcon={<Icon as={FinanceiroBaixarContas} fontSize="md" />}
              onClick={handleBaixarContasSelecionadas}
            >
              Baixar contas
            </ButtonFuncionalidade>

            <ButtonFuncionalidade
              funcionalidade={ConstanteFuncionalidades.CONTAS_RECEBER_EXCLUIR}
              borderRadius="full"
              variant="outlineDefault"
              colorScheme="gray"
              height="32px"
              padding="10px 24px"
              minW="160px"
              leftIcon={<Icon as={LixeiraIcon} fontSize="md" />}
              onClick={() => {
                handleExcluirContasSelecionadas();
              }}
            >
              Excluir contas
            </ButtonFuncionalidade>

            <Flex
              alignItems="center"
              justifyContent={{ base: 'space-between', lg: 'flex-start' }}
            >
              <Text fontSize="sm" lineHeight="none">
                {qtdContasEmAbertoSelecionadas}
                {qtdContasEmAbertoSelecionadas === 1
                  ? ' item selecionado'
                  : ' itens selecionados'}
              </Text>

              <Button
                size="xs"
                variant="link"
                colorScheme="red"
                textDecoration="none"
                _hover={{ textDecoration: 'none' }}
                ml="4"
                onClick={handleLimparItensSelecionados}
              >
                Limpar seleção
              </Button>
            </Flex>
          </Stack>
        )}
        {hasContasPagasSelecionadasComOperacaoIdIgual &&
          !temContaEmAbertoSelecionada && (
            <Stack
              direction={{ base: 'column', lg: 'row' }}
              spacing={{ base: 3, sm: 6, md: 8 }}
              w="full"
            >
              <Box>
                <Button
                  borderRadius="full"
                  variant="solid"
                  colorScheme="primary"
                  height="32px"
                  padding="10px 24px"
                  minW="160px"
                  onClick={() => handleCompartilharContasRecebidas()}
                >
                  <Text mr="6px">Compartilhar </Text>
                  <TextValor
                    casasDecimais={2}
                    valor={vlrTotalContasPagasSelecionadas || 0}
                    color="secondary.300"
                    fontSize="md"
                    fontWeight="semibold"
                    symbolFontSize="xs"
                    symbolFontWeight="semibold"
                  />
                </Button>
              </Box>

              <Flex
                alignItems="center"
                justifyContent={{ base: 'space-between', lg: 'flex-start' }}
              >
                <Text fontSize="sm" lineHeight="none">
                  {qntContasPagasSelecionadas}
                  {qntContasPagasSelecionadas === 1
                    ? ' item selecionado'
                    : ' itens selecionados'}
                </Text>

                <Button
                  size="xs"
                  variant="link"
                  colorScheme="red"
                  textDecoration="none"
                  _hover={{ textDecoration: 'none' }}
                  ml="4"
                  onClick={handleLimparItensSelecionados}
                >
                  Limpar seleção
                </Button>
              </Flex>
            </Stack>
          )}

        {showOpcoesFiltros && (
          <>
            <SimpleGridForm gridGap={['12px', '12px', '12px', '24px']}>
              <GridItem
                colSpan={
                  isSmallerThan965 && isLargerThan900 ? 6 : [12, 12, 4, 4, 3]
                }
              >
                <Box>
                  <InputDateRange
                    name="dataVencimento"
                    placeholder="Selecione a data de vencimento"
                    startDateName="dataVencimentoInicio"
                    endDateName="dataVencimentoFim"
                    borderRadius="5px"
                    borderColor="gray.200"
                    size="sm"
                    isClearable
                    onConfirm={handleSubmit}
                  />
                </Box>
              </GridItem>
              <GridItem
                colSpan={isSmallerThan965 && isLargerThan900 ? 6 : [12, 12, 5]}
              >
                <Flex
                  flexDir={['column', 'row']}
                  justifyContent={['center', 'space-between', 'start']}
                  gap={['12px', '12px', '12px', '24px']}
                >
                  <BuscaAvancadaButton
                    borderRadius="full"
                    colorScheme={hasFilters ? 'blue' : 'gray'}
                    variant={hasFilters ? 'solid' : 'outlineDefault'}
                    bg="transparent"
                    setIsModalBuscaAvancadaOpen={handlePushModalBuscaAvancada}
                    hasFilters={hasFilters}
                    padding="10px 24px"
                    height="32px"
                    justifyContent="center"
                    borderColor="gray.200"
                    minW={['165px', '165px', '165px', '188px']}
                    width={['full', '50%', '165px', '188px']}
                  >
                    {hasFilters ? 'Filtro selecionado' : 'Busca avançada'}
                  </BuscaAvancadaButton>

                  <Menu>
                    {({ isOpen }) => (
                      <>
                        <MenuButton
                          variant="outlineDefault"
                          bg="transparent"
                          borderRadius="full"
                          padding="10px 16px"
                          height="32px"
                          justifyContent="center"
                          minW="175px"
                          width={['full', '50%', 'auto', '190px']}
                          borderWidth="1px"
                          borderColor="gray.200"
                          isActive={isOpen}
                          _active={{
                            color: 'white',
                            bg: 'gray.700',
                          }}
                          as={Button}
                          rightIcon={
                            isOpen ? (
                              <Icon as={RiArrowDropDownLine} h={7} w={7} />
                            ) : (
                              <Icon as={RiArrowDropUpLine} h={7} w={7} />
                            )
                          }
                          leftIcon={<OpcoesIcon />}
                          colorScheme="gray"
                          sx={{
                            '& span': {
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              flex: 'none',
                            },
                          }}
                        >
                          <Text
                            paddingLeft="2"
                            textAlign="center"
                            w="fit-content"
                          >
                            Opções gerais
                          </Text>
                        </MenuButton>
                        <MenuList>
                          <MenuItem
                            onClick={() => {
                              history.push(ConstanteRotas.RECEBIMENTO_CONTAS);
                            }}
                          >
                            <Icon as={FinanceiroAReceber} h={6} w={5} />
                            <Text ml="4" fontSize="sm">
                              Recebimento de contas
                            </Text>
                          </MenuItem>
                          <MenuItem
                            onClick={() => handleCompartilharOperacao()}
                          >
                            <Icon
                              as={RiShareLine}
                              h={6}
                              w={5}
                              color="gray.500"
                            />
                            <Text ml="4" fontSize="sm">
                              Compartilhar
                            </Text>
                          </MenuItem>
                        </MenuList>
                      </>
                    )}
                  </Menu>
                </Flex>
              </GridItem>
              <GridItem
                colSpan={
                  isSmallerThan965 && isLargerThan900 ? 12 : [12, 12, 3, 3, 4]
                }
                textAlign="end"
              >
                <ButtonFuncionalidade
                  funcionalidade={ConstanteFuncionalidades.LANCAMENTO_CADASTRAR}
                  colorScheme={isLoading ? 'gray' : 'secondary'}
                  width={
                    isSmallerThan965 && isLargerThan900
                      ? 'full'
                      : ['full', 'full', '150px', '160px']
                  }
                  borderRadius="full"
                  height="32px"
                  padding="10px 24px"
                  leftIcon={
                    <Icon
                      as={SalvarInserirNovoIcon}
                      fontSize="lg"
                      boxSize="14px"
                    />
                  }
                  isDisabled={isLoading}
                  onClick={handleNovaConta}
                >
                  Nova conta
                </ButtonFuncionalidade>
              </GridItem>
            </SimpleGridForm>
          </>
        )}
      </FormProvider>

      <Stack
        width="100%"
        spacing={{ base: '2', lg: '4' }}
        mt={{ base: 3, sm: 6, md: 8 }}
        direction={{ base: 'column', md: 'column', lg: 'row' }}
        justifyContent={{ base: 'center', xl: 'flex-start' }}
        alignItems="center"
        minH={{ base: '64', lg: '16' }}
        px="8"
        bg="teal.700"
        borderRadius="md"
      >
        <Flex
          w="full"
          maxW="400px"
          alignItems="center"
          justifyContent="center"
          flexDir="column"
          gap="4px"
        >
          <Text color="white" fontSize="sm">
            Quantidade de contas
          </Text>
          <Text color="white" fontSize="xl" lineHeight="none">
            {!isFirstRender ? 0 : totalPerPeriod.quantidadeContas}
          </Text>
        </Flex>

        <Divider
          color="white"
          orientation={!isResponsiveVersion ? 'vertical' : 'horizontal'}
          h={{ base: '1px', md: '1px', lg: '12' }}
          w={{ base: 'full', md: 'full', lg: '1px' }}
        />

        <Flex
          w="full"
          maxW="400px"
          alignItems="center"
          justifyContent="center"
          flexDir="column"
        >
          <Text color="white" fontSize="sm">
            Valor em desconto
          </Text>
          <TextValor
            casasDecimais={2}
            valor={!isFirstRender ? 0 : totalPerPeriod.valorTotalDescontos}
            color="white"
            fontSize="xl"
            fontWeight="semibold"
            symbolFontSize="xs"
            symbolFontWeight="semibold"
          />
        </Flex>

        <Divider
          color="white"
          orientation={!isResponsiveVersion ? 'vertical' : 'horizontal'}
          h={{ base: '1px', md: '1px', lg: '12' }}
          w={{ base: 'full', md: 'full', lg: '1px' }}
        />

        <Flex
          w="full"
          maxW="400px"
          alignItems="center"
          justifyContent="center"
          flexDir="column"
        >
          <Text color="white" fontSize="sm">
            Valor em multas e juros
          </Text>
          <TextValor
            casasDecimais={2}
            valor={!isFirstRender ? 0 : totalPerPeriod.valorTotalMultasJuros}
            color="white"
            fontSize="xl"
            fontWeight="semibold"
            symbolFontSize="xs"
            symbolFontWeight="semibold"
          />
        </Flex>

        <Divider
          color="white"
          orientation={!isResponsiveVersion ? 'vertical' : 'horizontal'}
          h={{ base: '1px', md: '1px', lg: '12' }}
          w={{ base: 'full', md: 'full', lg: '1px' }}
        />

        <Flex
          w="full"
          maxW="400px"
          alignItems="center"
          justifyContent="center"
          flexDir="column"
        >
          <Text color="white" fontSize="sm">
            Valor total no período
          </Text>
          <TextValor
            casasDecimais={2}
            valor={!isFirstRender ? 0 : totalPerPeriod.valorTotalReceber}
            color="secondary.300"
            fontSize="xl"
            fontWeight="semibold"
            symbolFontSize="xs"
            symbolFontWeight="semibold"
          />
        </Flex>
      </Stack>

      <PagedTable
        variant="simple-card"
        bg="transparent"
        boxShadow="none"
        size="sm"
        mt="3"
        pageSizesOptions={pageSizeOptions}
        itensPerPage={5}
        paginationHasDivider={false}
        ref={pagedTableRef}
        sx={{
          '& tr > th': { borderBottom: 'none' },
          '& td:only-child': { bg: 'white', h: '60px', border: 'none' },
          '& tr': { boxShadow: 'none' },
        }}
        isLoading={isLoading}
        loadColumnsData={loadColumnsData}
        itemsTotalCount={informacoesGeraisListagem.quantidadeContas}
        defaultKeyOrdered="dataVencimento"
        defaultOrderDirection="asc"
        tableHeaders={[
          {
            key: 'checkboxSelecionarTodos',
            content: (
              <Checkbox
                onChange={() => handleToggleDeveSelecionarTodosItens()}
                isChecked={deveSelecionarTodosItens}
                colorScheme="primary"
                boxShadow="inset 0px 3px 6px #00000029"
                mb="0"
                bg="white"
              />
            ),
            isOrderable: false,
            w: '1px',
          },
          { key: 'dataVencimento', content: 'Dia de vencimento' },
          { key: 'cliente', content: 'Cliente', isOrderable: false },
          {
            key: 'formaDeRecebimento',
            content: 'Forma de recebimento',
            isOrderable: false,
          },
          {
            key: 'valorTotal',
            content: 'Valor total',
            isOrderable: false,
            isNumeric: true,
          },
          {
            key: 'iconesStatusVencimento',
            content: '',
            isOrderable: false,
            w: '1px',
          },
          { key: 'acoes', content: 'Ações', isOrderable: false, w: '1px' },
        ]}
        renderTableRows={
          columnsData &&
          columnsData.map((item, index) => (
            <ContasReceberItem
              handleToggleLinhaProduto={handleToggleItemSelecionado}
              handleEditarConta={handleEditarConta}
              handleBaixarConta={() => handleBaixarConta([index])}
              handleExcluirConta={() => handleExcluirContas([item.operacaoId])}
              item={item}
              index={index}
              handleCancelarBaixa={handleCancelarBaixa}
              handleCompartilharContaBaixada={compartilharContaRecebida}
              showOriginalValue={
                currentFilters.situacao === TipoSituacaoBaixa.TODAS &&
                item.pagamentoParcial
              }
            />
          ))
        }
      />
      {movimentacaoIdImpressao && (
        <>
          <Box display="none">
            <ImpressaoCupom80mm
              ref={cupom80mmComponentToPrintRef}
              movimentacaoFinanceiaId={movimentacaoIdImpressao}
            />
          </Box>
          <Box display="none">
            <ImpressaoCupom56mm
              ref={cupom56mmComponentToPrintRef}
              movimentacaoFinanceiaId={movimentacaoIdImpressao}
              maxWidth="56mm"
            />
          </Box>
        </>
      )}
    </>
  );
}
