import { IdentificacaoEtapasTray } from 'constants/enum/IdentificacaoEtapasTray';

import { Autenticacao } from '../Autenticacao';
import { CanalVendas } from '../CanalVendas';
import { GuiaIntegracao } from '../GuiaIntegracao';
import { PortalCliente } from '../PortalCliente';
import { Vendedores } from '../Vendedores';
import { TabelaPreco } from '../TabelaPreco';
import { TipoCadastro } from '../TipoCadastro';
import { BuscarProduto } from '../BuscarProduto';
import { Importando } from '../Importando';
import { ProdutoSistema } from '../ProdutoSistema';
import { LocalEstoque } from '../LocalEstoque';
import { CadastrarTabelaPreco } from '../CadastrarTabelaPreco';

type StepsTrayProps = {
  activeStep: number;
};

export const StepsTray = ({ activeStep }: StepsTrayProps) => {
  switch (activeStep) {
    case IdentificacaoEtapasTray.GUIA_INTEGRACAO:
      return <GuiaIntegracao />;
    case IdentificacaoEtapasTray.PORTAL_CLIENTE:
      return <PortalCliente />;
    case IdentificacaoEtapasTray.AUTENTICACAO:
      return <Autenticacao />;
    case IdentificacaoEtapasTray.CANAL_VENDAS:
      return <CanalVendas />;
    case IdentificacaoEtapasTray.VENDEDOR:
      return <Vendedores />;
    case IdentificacaoEtapasTray.LOCAL_ESTOQUE:
      return <LocalEstoque />;
    case IdentificacaoEtapasTray.TABELA_PRECO:
      return <TabelaPreco />;
    case IdentificacaoEtapasTray.CADASTRAR_TABELA_PRECO:
      return <CadastrarTabelaPreco />;
    case IdentificacaoEtapasTray.TIPO_CADASTRO:
      return <TipoCadastro />;
    case IdentificacaoEtapasTray.BUSCAR_PRODUTO:
      return <BuscarProduto />;
    case IdentificacaoEtapasTray.IMPORTANDO:
      return <Importando />;
    case IdentificacaoEtapasTray.ENVIANDO_ZENDAR:
      return <Importando />;
    case IdentificacaoEtapasTray.LISTA_PRODUTO_SISTEMA:
      return <ProdutoSistema />;

    default:
      return null;
  }
};
