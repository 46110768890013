import usePersistedState from 'helpers/layout/usePersistedState';
import React, {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react';

export interface BreadcrumbInterface {
  title: string;
  path?: string;
}

interface LayoutContextProps {
  breadcrumb: BreadcrumbInterface[];
  setBreadcrumb: Dispatch<SetStateAction<BreadcrumbInterface[]>>;
  menuIsOpen: boolean;
  setMenuIsOpen: Dispatch<SetStateAction<boolean>>;
}

const LayoutContext = createContext<LayoutContextProps>(
  {} as LayoutContextProps
);

interface LayoutProviderProps {
  children: React.ReactNode;
}

export default function LayoutProvider({
  children,
}: LayoutProviderProps): JSX.Element {
  const [breadcrumb, setBreadcrumb] = useState<BreadcrumbInterface[]>([]);

  const [menuIsOpen, setMenuIsOpen] = usePersistedState<boolean>(
    'navMenuIsOpen',
    true
  );

  return (
    <LayoutContext.Provider
      value={{
        breadcrumb,
        setBreadcrumb,
        menuIsOpen,
        setMenuIsOpen,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
}

export function useLayoutContext(): LayoutContextProps {
  const context = useContext(LayoutContext);

  if (!context)
    throw new Error('useLayoutContext must be used within a LayoutProvider.');

  return context;
}
