import React, { useCallback, memo } from 'react';
import { Controller, FieldError } from 'react-hook-form';

import { cfopMask } from 'helpers/format/fieldsMasks';

import ContainerInputPadrao from 'components/Layout/CampoContainer/CampoContainerPadrao';
import { InputControl } from '../styles';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  name: string;
  label: string;
  control: any;
  error?: FieldError | null;
  infoText?: string;
}

const InputCfop = ({
  id,
  name,
  label,
  required,
  control,
  error = null,
  infoText,
  ...rest
}: InputProps) => {
  const maskValue = useCallback((value: string) => {
    const maskedValue = cfopMask(value);

    return maskedValue;
  }, []);

  return (
    <ContainerInputPadrao
      id={id}
      label={label}
      error={error}
      required={required}
      infoText={infoText}
    >
      <Controller
        defaultValue=""
        render={({ field }) => {
          return (
            <InputControl
              inputMode="numeric"
              id={id}
              ref={field.ref}
              value={maskValue(field.value)}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                if (e.currentTarget.value)
                  field.onChange(cfopMask(e.currentTarget.value));
                else field.onChange(null);
              }}
              maxLength={5}
              {...rest}
            />
          );
        }}
        name={`${name}` as const}
        control={control}
      />
    </ContainerInputPadrao>
  );
};

export default memo(InputCfop);
