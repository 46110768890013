import {
  Divider,
  useMediaQuery,
  Text,
  Flex,
  Circle,
  Box,
} from '@chakra-ui/react';

import { moneyMask } from 'helpers/format/fieldsMasks';

import { ClienteFornecedorInterface } from '../Types/validationForms';

type TabHistoricoProps = {
  cliente: ClienteFornecedorInterface;
};

export const TabHistorico = ({ cliente }: TabHistoricoProps) => {
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const emptyField = '---';
  return (
    <>
      {isLargerThan900 ? (
        <>
          {cliente?.ultimasCompras?.length === 0 ? (
            <>
              <Divider
                width="100%"
                color="gray.100"
                orientation="horizontal"
                mt={2}
              />

              <Text>{emptyField}</Text>
            </>
          ) : (
            <Flex
              position="relative"
              direction="column"
              alignItems="flex-start"
              mt="3"
              maxH="290px"
              _before={{
                content: `""`,
                position: 'absolute',
                top: '28px',
                width: '100%',
                height: '1px',
                backgroundColor: 'gray.100',
              }}
            >
              <Flex
                mt="15px"
                width="full"
                overflow="hidden"
                zIndex={1}
                justifyContent={
                  cliente?.ultimasCompras?.length <= 3
                    ? 'flex-start'
                    : 'space-between'
                }
              >
                {cliente?.ultimasCompras?.map((compra) => (
                  <Flex key={compra.id} direction="column" alignItems="center">
                    <Circle
                      background="primary.500"
                      size="8px"
                      mt="10px"
                      zIndex={2}
                    />
                    <Box pl="8px" width="full" textAlign="center">
                      <Text
                        color="primary.100"
                        fontSize="16px"
                        mt={1}
                        whiteSpace="nowrap"
                        fontWeight="bold"
                      >
                        {`${new Date(compra.data).toLocaleDateString()}`}
                      </Text>
                      <Text
                        color="gray.900"
                        fontSize="14px"
                        fontWeight="bold"
                        mt={1}
                        mb={1}
                      >
                        {moneyMask(compra.valor, true)}
                      </Text>
                      {compra.pagamentos.length === 0 ? (
                        <Text>{emptyField}</Text>
                      ) : (
                        <>
                          {compra.pagamentos.map((item) => (
                            <Flex justifyContent="center">
                              <Text
                                fontSize="2xs"
                                lineHeight="base"
                                color="gray.400"
                                maxW="25ch"
                                overflow="hidden"
                                textOverflow="ellipsis"
                                whiteSpace="nowrap"
                                mr={1}
                              >
                                {item.nome}{' '}
                              </Text>
                              <Text
                                fontSize="2xs"
                                lineHeight="base"
                                color="gray.400"
                              >
                                {item.numeroParcela}x
                              </Text>
                            </Flex>
                          ))}
                        </>
                      )}
                    </Box>
                  </Flex>
                ))}
              </Flex>
            </Flex>
          )}
        </>
      ) : (
        <>
          {cliente?.ultimasCompras?.length === 0 ? (
            <>
              <Text>{emptyField}</Text>
            </>
          ) : (
            <Flex
              position="relative"
              direction="column"
              alignItems="flex-start"
              mt="3"
              mb="3"
              h="full"
              _before={{
                content: `""`,
                position: 'absolute',
                top: '25px',
                left: '4px',
                width: '1px',
                height: '95%',
                backgroundColor: 'gray.100',
                float: 'left',
              }}
            >
              <Text fontSize="16px" color="primary.50" mb={2}>
                Últimas compras:
              </Text>
              <Flex
                width="full"
                h="full"
                direction="column"
                zIndex={1}
                justifyContent="space-between"
              >
                {cliente?.ultimasCompras?.map((compra) => (
                  <Flex
                    key={compra.id}
                    direction="row"
                    alignItems="center"
                    pt={4}
                    pb={4}
                  >
                    <Circle background="indigo.500" size="10px" zIndex={2} />
                    <Box pl="12px" width="full">
                      <Text
                        color="primary.100"
                        fontSize="16px"
                        textAlign="left"
                        mb="2px"
                        whiteSpace="nowrap"
                        fontWeight="bold"
                      >
                        {`${new Date(compra.data).toLocaleDateString()}`}
                      </Text>
                      <Flex w="full" direction="row" alignItems="center">
                        <Text
                          color="gray.900"
                          fontSize="14px"
                          fontWeight="bold"
                          mr={2}
                        >
                          {moneyMask(compra.valor, true)} -
                        </Text>
                        {compra.pagamentos.length === 0 ? (
                          <Text>{emptyField}</Text>
                        ) : (
                          <>
                            {compra.pagamentos.map((item) => (
                              <Flex>
                                <Text
                                  fontSize="2xs"
                                  lineHeight="base"
                                  color="gray.400"
                                  mr={1}
                                  maxW="25ch"
                                  overflow="hidden"
                                  textOverflow="ellipsis"
                                  whiteSpace="nowrap"
                                >
                                  {item.nome}
                                </Text>
                                {compra.pagamentos.length > 1 ? (
                                  <Text
                                    fontSize="2xs"
                                    lineHeight="base"
                                    color="gray.400"
                                    mr={1}
                                  >
                                    {item.numeroParcela}x |
                                  </Text>
                                ) : (
                                  <Text
                                    fontSize="2xs"
                                    lineHeight="base"
                                    color="gray.400"
                                    mr={1}
                                  >
                                    {item.numeroParcela}x
                                  </Text>
                                )}
                              </Flex>
                            ))}
                          </>
                        )}
                      </Flex>
                    </Box>
                  </Flex>
                ))}
              </Flex>
            </Flex>
          )}
        </>
      )}
    </>
  );
};
