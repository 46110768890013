export const telefoneMask = (value: string) => {
  if (!value) return '';

  const maskedValue = value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada.
    .replace(/^0+/, '') // substitui qualquer zero a esquerda por nada (DDD não pode começar com 0).
    .substr(0, 11); // limita os caracteres numéricos para 11 (com máscara o length vai pra 15).

  const hifenRegex = new RegExp(
    `(\\d{${maskedValue.length <= 10 ? 4 : 5}})(\\d)`
  );

  return maskedValue
    .replace(/^(\d{2})(\d)/g, '($1) $2') // coloca parênteses em volta dos dois primeiros números.
    .replace(hifenRegex, '$1-$2'); // coloca hífen antes do quarto ou quinto número após o DDD.
};

export const cestMask = (value: string) => {
  return value
    .replace(/\D/g, '')
    .substr(0, 7)
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
};

export const cfopMask = (value: string) => {
  if (!value) return '';

  return value
    .replace(/\D/g, '')
    .substr(0, 5)
    .replace(/(\d{1})(\d)/, '$1.$2');
};

export const cpfMask = (value: string) => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .substr(0, 11) // limita os caracteres numéricos para 11 (com máscara o length vai pra 14).
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
};

export const cnpjMask = (value: string) => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .substr(0, 14) // limita os caracteres numéricos para 14 (com máscara o length vai pra 18).
    .replace(/^(\d{2})(\d)/, '$1.$2') // coloca um hífen depois do bloco de quatro dígitos
    .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3') // coloca ponto entre o quinto e o sexto dígitos
    .replace(/\.(\d{3})(\d)/, '.$1/$2') // coloca uma barra entre o oitavo e o nono dígitos
    .replace(/(\d{4})(\d)/, '$1-$2'); // coloca um hífen depois do bloco de quatro dígitos
};

export const cepMask = (value: string) => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .substr(0, 8) // limita os caracteres numéricos para 8 (com máscara o length vai pra 9).
    .replace(/^(\d{5})(\d)/, '$1-$2'); // coloca um hífen depois do bloco de quatro dígitos
};

export const rgMask = (value: string) => {
  let unformattedValue = value.replace(/[^A-Z0-9]/g, '').substr(0, 9);

  if (value.replace(/\D/g, '').length < 8) {
    unformattedValue = unformattedValue.replace(/\D/g, '');
  }

  return unformattedValue
    .replace(/^(\d{1,2})(\d)/, '$1.$2')
    .replace(/^(\d{1,2})\.(\d{3})(\d)/, '$1.$2.$3')
    .replace(/^(\d{1,2})\.(\d{3})\.(\d{3})([A-Z0-9])/, '$1.$2.$3-$4');
};

export const ieMask = (value: string) => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .substr(0, 20); // limita os caracteres numéricos para 20.
};

export const moneyMask = (valor: any, exibirPrefixo: boolean): string => {
  let valorFormatado = Number(valor).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  if (!exibirPrefixo) {
    valorFormatado = valorFormatado.replace('R$ ', ''); // O espaço gerado pelo toLocaleString é um outro caracter
  }

  return valorFormatado;
};

export const DecimalMask = (
  valor: any,
  minimumFractionDigits: number,
  maximumFractionDigits?: number
): string => {
  const valorFormatado = Number(valor).toLocaleString('pt-BR', {
    minimumFractionDigits,
    maximumFractionDigits,
  });

  return valorFormatado;
};

export const porcentagemMask = (
  value: number,
  minimumFractionDigits: number,
  maximumFractionDigits?: number
) => {
  const valorFormatado = Number(value / 100).toLocaleString('pt-BR', {
    style: 'percent',
    minimumFractionDigits,
    maximumFractionDigits,
  });
  return valorFormatado;
};

export const chaveAcessoMask = (chaveAcesso: any): string => {
  if (!chaveAcesso) return '';

  let chaveAcessoFormatada = chaveAcesso.replace(/\D/g, ''); // substitui qualquer caracter que nao seja numero por nada

  for (let index = 0; index < 11; index += 1) {
    chaveAcessoFormatada = chaveAcessoFormatada.replace(/(\d{4})(\d)/, '$1 $2'); // coloca um espaço depois do bloco de quatro dígitos
  }

  return chaveAcessoFormatada;
};
