import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import PlanoContratacaoEnum from 'constants/enum/planoContratacao';
import ConstanteRotas from 'constants/rotas';
import JwtDecode from 'jwt-decode';
import api, { ResponseApi } from 'services/api';
import { getEnumByName, getValue } from 'helpers/enum/getName';
import TipoSistemaEnum from 'constants/enum/tipoSistema';

type TipoBloqueio = 'plano' | 'permissao';

export interface PossuiPermissaoResponse {
  permitido: boolean;
  bloqueio?: TipoBloqueio;
}

export interface PossuiServicoResponse {
  permitido: boolean;
  bloqueio?: 'servico';
}

interface ServicosPorLoja {
  LojaId: string;
  ReferenciaServico: number;
}

const TOKEN = 'token';
const REFRESH_TOKEN = 'refreshToken';
const LOJA = 'loja';
const QTD_LOJAS = 'qtd_lojas';
const PERMISSOES = 'permissoes';

export default {
  setToken(dados: any) {
    localStorage.setItem(TOKEN, dados.token);
    localStorage.setItem(REFRESH_TOKEN, dados.refreshToken);
    localStorage.setItem(LOJA, JSON.stringify(dados.loja));
  },

  setRefreshToken(dados: any) {
    localStorage.setItem(TOKEN, dados.token);
    localStorage.setItem(REFRESH_TOKEN, dados.refreshToken);
  },

  setLoja(loja: any) {
    localStorage.setItem(LOJA, JSON.stringify(loja));
  },

  setQtdLojas(qtd: number) {
    localStorage.setItem(QTD_LOJAS, JSON.stringify(qtd));
  },

  setPermissoes(permissoes: any) {
    localStorage.setItem(PERMISSOES, JSON.stringify(permissoes));
  },

  getDadosToken(): any {
    const token = this.getToken();

    if (token) return JwtDecode(token);

    return undefined;
  },

  getIsAdministrador() {
    const userData = this.getDadosToken();

    if (userData) {
      return (
        (userData.administrador || '').toLowerCase() === 'true' ||
        userData.administrador === true
      );
    }

    return false;
  },

  getToken(): any {
    const token = localStorage.getItem(TOKEN);
    return token;
  },

  getRefreshToken(): any {
    return localStorage.getItem(REFRESH_TOKEN);
  },

  getSecurityStamp(): string {
    const userData = this.getDadosToken();
    return userData.securitystamp;
  },

  getRevendaId(): string {
    const userData = this.getDadosToken();
    return userData?.revendaId;
  },

  getUsuario(): { id: string; nome: string; foto?: string } {
    const {
      unique_name: uniqueName = '',
      fotoUrl = undefined,
      userId = undefined,
    } = this.getDadosToken() || {};

    return { id: userId, nome: uniqueName, foto: fotoUrl };
  },

  getLoja(): { id: string; fantasia: string } {
    try {
      return JSON.parse(localStorage.getItem(LOJA) || '');
    } catch {
      return { id: '', fantasia: '' };
    }
  },

  getQtdLojas(): number {
    try {
      // eslint-disable-next-line radix
      return parseInt(localStorage.getItem(QTD_LOJAS) || '0');
    } catch {
      return 0;
    }
  },

  getPlano(): number {
    const userData = this.getDadosToken();
    if (userData) {
      return getValue(PlanoContratacaoEnum, userData.plano);
    }
    return PlanoContratacaoEnum.START;
  },

  getPermissoes(): Array<{
    funcionalidade: string;
    permissao: string;
    planos: Array<number>;
  }> {
    try {
      return JSON.parse(localStorage.getItem(PERMISSOES) || '');
    } catch {
      return [];
    }
  },

  getServicos(): Array<{
    lojaId: string;
    servico: number;
  }> {
    const userData = this.getDadosToken();

    if (!userData) return [];

    const servicosDisponiveis = JSON.parse(userData?.servicos);

    if (!servicosDisponiveis) return [];

    return (servicosDisponiveis || [])?.map((lojaServico: ServicosPorLoja) => {
      return {
        lojaId: lojaServico?.LojaId,
        servico: lojaServico?.ReferenciaServico,
      };
    });
  },

  possuiServico(servico: number): PossuiServicoResponse {
    const servicos = this.getServicos();
    const lojaId = this.getLoja()?.id;

    if (!lojaId || !servicos) return { permitido: true, bloqueio: 'servico' };

    const possuiServico = servicos.find(
      (s) => s.lojaId === lojaId && s.servico === servico
    );

    if (possuiServico) {
      return { permitido: true };
    }

    return { permitido: false, bloqueio: 'servico' };
  },

  possuiPermissao(funcionalidade: string): PossuiPermissaoResponse {
    const userData = this.getDadosToken();

    if (userData) {
      const validatePermissoes = this.getPermissoes();

      const permissoes = validatePermissoes.map((permissao) => ({
        ...permissao,
        planos: permissao.planos.map((plano) => {
          const newPlano = PlanoContratacaoEnum?.values?.find(
            (itemPlano) => itemPlano.value === plano
          );
          return newPlano;
        }),
      }));

      const permissao =
        permissoes.find((p) => p.funcionalidade === funcionalidade) ||
        undefined;

      const plano = userData.plano || '';

      if (permissao) {
        if (
          !plano ||
          (permissao.planos && !permissao.planos.some((p) => p?.nome === plano))
        ) {
          return { permitido: false, bloqueio: 'plano' };
        }

        if (permissao.permissao) {
          return { permitido: true };
        }
        return { permitido: false, bloqueio: 'permissao' };
      }
    }

    return { permitido: false, bloqueio: 'plano' };
  },

  getPlanoFuncionalidadeDisponivel(funcionalidade: string) {
    const userData = this.getDadosToken();

    if (userData) {
      const validatePermissoes =
        this.getPermissoes().find((p) => p.funcionalidade === funcionalidade) ||
        undefined;

      const permissao = {
        ...validatePermissoes,
        planos: validatePermissoes?.planos.map((plano) => {
          const newPlano = PlanoContratacaoEnum?.values?.find(
            (itemPlano) => itemPlano.value === plano
          );
          return (
            newPlano ||
            ({} as {
              nome: string;
              value: number;
            })
          );
        }),
      };

      if (permissao && permissao?.planos) {
        const planos = permissao?.planos.sort((a, b) => a?.value - b?.value);

        const plano = planos[0];

        if (plano) return plano.nome;
      }
    }

    return '';
  },

  autenticado(): boolean {
    return this.getToken() != null;
  },

  clearToken() {
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(LOJA);
    localStorage.removeItem(PERMISSOES);
    localStorage.removeItem(QTD_LOJAS);
  },

  clearTokenAndRedirect() {
    this.clearToken();
    window.location.reload();
  },

  async dominioExistente(path: string, next: () => void): Promise<void> {
    const urlApp = this.getUrlApp();

    let dominio = window.location.host.split('/')[0].toLowerCase();
    if (dominio.split('.')[0].toLowerCase() === 'app') {
      if (path === ConstanteRotas.HOME) {
        next();
      } else {
        window.location.href = urlApp;
      }

      return;
    }

    let novoDominio = dominio;
    const contacliente = window.location.host.split('.')[0].toLowerCase();
    let redirecionarLogin = false;

    if (window.location.host.includes('zendar.com')) {
      novoDominio = `${contacliente}.zendar.app`;
      redirecionarLogin = true;
    } else if (window.location.host.includes('powerstock.com')) {
      novoDominio = `${contacliente}.powerstock.app`;
      redirecionarLogin = true;
    } else if (window.location.host.includes('fomersistema.com')) {
      novoDominio = `${contacliente}.fomersistema.app`;
      redirecionarLogin = true;
    }

    dominio = novoDominio;

    const result = await api.get<void, ResponseApi<boolean>>(
      ConstanteEnderecoWebservice.CLIENTE_MULTIEMPRESA_VALIDAR_DOMINIO,
      {
        params: { dominio },
      }
    );

    if (result && result.sucesso) {
      if (redirecionarLogin) {
        this.redirectToLogin(dominio);
      } else if (path !== ConstanteRotas.LOGIN) {
        this.redirectToLogin(dominio);
      } else {
        next();
      }
    } else {
      window.location.href = urlApp;
    }
  },

  redirectToLogin(dominio: string): any {
    if (process.env.NODE_ENV === 'development') {
      window.location.href = `http://${dominio}/login`;
    } else {
      window.location.href = `https://${dominio}/login`;
    }
  },

  getSistema(): any {
    if (
      process.env.NODE_ENV === 'development' ||
      process.env.NODE_ENV === 'test' ||
      process.env.REACT_APP_APP_SISTEMA !== undefined
    ) {
      return getEnumByName(TipoSistemaEnum, process.env.REACT_APP_APP_SISTEMA);
    }

    const urlDevPublicada = 'https://zendar-dev-app.azurewebsites.net';
    const url = window.location.host;
    if (url === urlDevPublicada) {
      return getEnumByName(
        TipoSistemaEnum,
        process.env.REACT_APP_APP_SISTEMA ?? 'zendar'
      );
    }

    const sistema = window.location.host.split('.')[1].toLowerCase();
    return getEnumByName(TipoSistemaEnum, sistema);
  },

  getUrlApp(): any {
    if (
      process.env.NODE_ENV === 'development' ||
      process.env.NODE_ENV === 'test' ||
      process.env.REACT_APP_APP_SISTEMA !== undefined
    ) {
      const dominio = window.location.host.split('/')[0].toLowerCase();

      return `http://${dominio}/login`;
    }
    const sistema = this.getSistema();

    return `https://app.${sistema.name}.app/`;
  },
};
