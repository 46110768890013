import React, { useCallback, memo, useMemo } from 'react';
import { Controller, FieldError } from 'react-hook-form';
import { Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import OverlayTriggerPadrao from 'components/OverlayTriggerPadrao';

import { telefoneMask } from 'helpers/format/fieldsMasks';

import { InputControl, WhatsIcon } from '../styles';
import { AppendContainerWhats, WhatsButton } from './styles';
import {
  Container,
  LabelContainer,
  Label,
  MensagemValidacao,
  InfoIcon,
} from '../../Layout/CampoContainer/CampoContainerPadrao/styles';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  whatsappFieldName?: string;
  name: string;
  label: string;
  control: any;
  error?: FieldError | null;
  infoText?: string;
  setValue?: any;
  whatsApp?: boolean;
}

const InputTelefoneWhatsapp = ({
  id,
  whatsappFieldName: whatsappFieldNameProps,
  name,
  label,
  required,
  control,
  error = null,
  infoText,
  setValue,
  whatsApp,
  disabled,
  ...rest
}: InputProps) => {
  const { t } = useTranslation('validation');

  const whatsappFieldName = useMemo(
    () => whatsappFieldNameProps || `${name}Whats`,
    [name, whatsappFieldNameProps]
  );

  const maskValue = useCallback((value: string) => {
    const maskedValue = telefoneMask(value);
    return maskedValue;
  }, []);

  return (
    <Controller
      defaultValue=""
      render={({ field }) => {
        const { onChange: setIsWhats, value: isWhats } = field;
        return (
          <Container>
            {label && (
              <LabelContainer>
                <Label
                  id={`label-for-${id}`}
                  htmlFor={id}
                  style={{ width: '100%' }}
                >
                  {label}
                  {required && ' *'}
                  <WhatsButton
                    tabIndex="-1"
                    id={`button-whatsapp-for-${id}`}
                    disabled={disabled}
                    onClick={() => {
                      if (!disabled) {
                        setIsWhats(!isWhats);
                      }
                    }}
                  >
                    {isWhats ? t('Não é WhatsApp') : t('É WhatsApp')}
                  </WhatsButton>
                </Label>

                {infoText && (
                  <OverlayTriggerPadrao
                    placement="right"
                    delay={{ show: 200, hide: 300 }}
                    overlay={(props: any) => {
                      return <Tooltip {...props}>{infoText}</Tooltip>;
                    }}
                  >
                    <InfoIcon />
                  </OverlayTriggerPadrao>
                )}
              </LabelContainer>
            )}

            <Controller
              defaultValue=""
              render={({ field: fieldValues }) => {
                return (
                  <>
                    <InputControl
                      inputMode="numeric"
                      id={id}
                      ref={fieldValues.ref}
                      value={maskValue(fieldValues.value)}
                      onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        if (e.currentTarget.value) {
                          fieldValues.onChange(
                            telefoneMask(e.currentTarget.value)
                          );
                        } else fieldValues.onChange(null);
                      }}
                      maxLength={15}
                      disabled={disabled}
                      {...rest}
                    />

                    {isWhats && (
                      <AppendContainerWhats>
                        <WhatsIcon id={`icon-whatsapp-for-${id}`} />
                      </AppendContainerWhats>
                    )}
                  </>
                );
              }}
              // onFocus={() => {
              //   inputRef.current.focus();
              // }}
              name={`${name}` as const}
              control={control}
            />

            {error && (
              <MensagemValidacao id={`mensagem-validacao-${id}`}>
                {t(error.message as any)}
              </MensagemValidacao>
            )}
          </Container>
        );
      }}
      name={`${whatsappFieldName}` as const}
      control={control}
    />
  );
};

export default memo(InputTelefoneWhatsapp);
