import { Icon, Text, Flex, Box, useMediaQuery } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import RegraLimiteCreditoEnum from 'constants/enum/regraLimiteCredito';

import Input from 'components/PDV/Input';
import { NumberInput } from 'components/update/Input/NumberInput';
import CheckboxAtivoInativo from 'components/update/Checkbox/CheckboxAtivoInativo';
import Select from 'components/PDV/Select/SelectPadrao';
import { UsuariosPerfilUsuarioIcon, InfoIcon } from 'icons';

import { ExplicacaoRegraBloqueioModal } from './ExplicacaoRegraBloqueioModal';

interface UncontrolledFormInterface {
  readonly?: boolean;
}

export const UncontrolledForm = ({ readonly }: UncontrolledFormInterface) => {
  const [isLargerThan900] = useMediaQuery('(max-width: 900px)');

  const { watch } = useFormContext();

  const exibirDiasAtraso =
    watch('regraLimiteCredito')?.value ===
    (RegraLimiteCreditoEnum.BLOQUEIO_DIAS_ATRASO as number);

  return (
    <>
      <Input
        type="text"
        name="descricao"
        id="descricao"
        label="Descrição"
        autoFocus
        isRequired
        placeholder="Digite um nome para diferenciar a categoria"
        maxLength={30}
        isDisabled={readonly}
      />
      <Flex
        direction={isLargerThan900 ? 'column' : 'row'}
        w="full"
        mt={isLargerThan900 ? '15px ' : '35px'}
      >
        <Box
          w="full"
          mb={isLargerThan900 ? '15px ' : undefined}
          mr={isLargerThan900 ? undefined : '30px'}
        >
          <Flex justifyContent="space-between" alignItems="center">
            <Text fontWeight="bold" color="black">
              Regra para bloqueio do cliente
            </Text>
            <Icon
              cursor="pointer"
              onClick={() => ExplicacaoRegraBloqueioModal()}
              as={InfoIcon}
            />
          </Flex>
          <Select
            id="regraLimiteCredito"
            asControlledByObject
            name="regraLimiteCredito"
            required
            options={RegraLimiteCreditoEnum.properties.map((limiteCredito) => {
              return (
                {
                  label: limiteCredito.name,
                  value: limiteCredito.value,
                  color: limiteCredito.iconColor,
                } || {}
              );
            })}
            formatOptionLabel={(option: any) => {
              return (
                <Text as="span" fontSize="1em">
                  <Icon
                    fontSize="20px"
                    mr="15px"
                    as={UsuariosPerfilUsuarioIcon}
                    color={option.color}
                  />
                  <Text as="span" fontSize="1em">
                    {option.label}
                  </Text>
                </Text>
              );
            }}
            isDisabled={readonly}
          />
        </Box>

        {exibirDiasAtraso && (
          <Box mb={isLargerThan900 ? '15px ' : undefined} w="full" mr="30px">
            <NumberInput
              id="diasAtrasoBloqueio"
              name="diasAtrasoBloqueio"
              label="Dias de atraso"
              defaultValue={1}
              scale={0}
              required
              placeholder="Informe a quantidade de dias"
              disabled={readonly}
            />
          </Box>
        )}

        <CheckboxAtivoInativo
          isDisabled={readonly}
          id="ativo"
          name="ativo"
          label="Status"
        />
      </Flex>
    </>
  );
};
