import { toast } from 'react-toastify';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { ImprimirPDF } from 'helpers/impressoes/imprimirPDF';
import api, { ResponseApi } from 'services/api';

type FiltrosPaginacaoProps = {
  paginaAtual: number;
  tamanhoPagina: number;
  campoOrdenacao: string;
  direcaoOrdenacao: 'asc' | 'desc';
  mesAno: Date;
  vendedorId: string | undefined;
};

type ImprimirRelatorioHistoricoVendasProps = {
  filtrosPaginacao: FiltrosPaginacaoProps;
  nameFile: string;
};

export const handleImprimirRelatorioHistoricoVendas = async ({
  filtrosPaginacao,
  nameFile,
}: ImprimirRelatorioHistoricoVendasProps) => {
  const response = await api.get<void, ResponseApi<string>>(
    ConstanteEnderecoWebservice.IMPRIMIR_HISTORICO_VENDAS,
    {
      params: {
        ...filtrosPaginacao,
      },
    }
  );
  if (response) {
    if (response.avisos) {
      response.avisos.forEach((avisos) => toast.warning(avisos));
    }

    if (response.sucesso && response.dados) {
      ImprimirPDF(response.dados, nameFile);
    }
  }
};
