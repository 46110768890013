import { useFormContext } from 'react-hook-form';
import { Flex, VStack, HStack, Box, Text } from '@chakra-ui/react';

import getOptionsByEnum from 'helpers/format/getOptionsByEnum';
import TipoCampoPersonalizadoEnum from 'constants/enum/tipoCampoPersonalizado';
import TipoCadastroCampoPersonalizadoEnum from 'constants/enum/tipoCadastroCampoPersonalizado';

import Input from 'components/PDV/Input';
import { Switch } from 'components/update/Switch';
import TextareaField from 'components/PDV/Textarea';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import { DateInput } from 'components/update/Input/DateInput';
import { NumberInput } from 'components/update/Input/NumberInput';
import { InfoTooltip } from 'components/update/Tooltip/InfoTooltip';
import CheckboxAtivoInativo from 'components/update/Checkbox/CheckboxAtivoInativo';

interface FormCampoPersonalizadoInterface {
  readonly?: boolean;
  bloquearAlteracao?: boolean;
}

const FormCampoPersonalizado = ({
  readonly,
  bloquearAlteracao,
}: FormCampoPersonalizadoInterface) => {
  const { watch } = useFormContext();

  const [
    instrucao,
    tipoWatch,
    nomeWatch,
    placeholderWatch,
    tamanhoWatch,
    casaDecimalWatch,
    descricaoInstrucaoWatch,
  ] = watch([
    'instrucao',
    'tipo',
    'nome',
    'placeholder',
    'tamanho',
    'casaDecimal',
    'descricaoInstrucao',
  ]);

  function VisualizacaoCampo() {
    switch (tipoWatch) {
      case TipoCampoPersonalizadoEnum.CAIXATEXTO:
        return (
          <TextareaField
            name="visualizaCaixaTexto"
            id="visualizaCaixaTexto"
            maxH={['200px', '120px']}
            resize={['vertical', 'vertical', 'vertical', 'none']}
            label={nomeWatch}
            helperText={instrucao ? descricaoInstrucaoWatch : false}
            maxLength={tamanhoWatch}
            placeholder={placeholderWatch}
          />
        );
      case TipoCampoPersonalizadoEnum.NUMERAL:
        return (
          <NumberInput
            name="visualizaNumeral"
            label={nomeWatch}
            helperText={instrucao ? descricaoInstrucaoWatch : false}
            scale={0}
          />
        );
      case TipoCampoPersonalizadoEnum.DATA:
        return (
          <DateInput
            name="visualizaData"
            id="visualizaData"
            label={nomeWatch}
            helperText={instrucao ? descricaoInstrucaoWatch : false}
          />
        );
      case TipoCampoPersonalizadoEnum.CHECKBOX:
        return (
          <HStack
            p="15px"
            border="1px"
            borderColor="gray.200"
            borderRadius="5px"
            width="full"
            justify="space-between"
            shouldWrapChildren
          >
            <Flex alignItems="center" gap={1}>
              <Text color="black">{nomeWatch}</Text>
              {instrucao && descricaoInstrucaoWatch !== '' && (
                <InfoTooltip label={descricaoInstrucaoWatch} bgColor="black" />
              )}
            </Flex>
            <Switch size="md" id="visualizaSwitch" name="visualizaSwitch" />
          </HStack>
        );
      case TipoCampoPersonalizadoEnum.MOEDA:
      case TipoCampoPersonalizadoEnum.PORCENTAGEM:
        return (
          <>
            <Box w="full">
              <NumberInput
                name="visualizaNumero"
                id="visualizaNumero"
                label={nomeWatch}
                bgLeftElement="gray.50"
                editarFundoLeftElemento
                scale={casaDecimalWatch || 0}
                leftElement={
                  tipoWatch === TipoCampoPersonalizadoEnum.PORCENTAGEM
                    ? '%'
                    : 'R$'
                }
                helperText={instrucao ? descricaoInstrucaoWatch : false}
                precision={
                  tamanhoWatch > casaDecimalWatch
                    ? tamanhoWatch
                    : casaDecimalWatch || tamanhoWatch || 1
                }
              />
            </Box>
          </>
        );
      case TipoCampoPersonalizadoEnum.TEXTO:
      default:
        return (
          <>
            <Input
              name="visualizaDefault"
              label={nomeWatch}
              helperText={instrucao ? descricaoInstrucaoWatch : false}
              placeholder={placeholderWatch}
              maxLength={tamanhoWatch || 0}
            />
          </>
        );
    }
  }

  return (
    <>
      <Flex w="full" flexDirection={['column', 'column', 'row', 'row']} gap="6">
        <VStack w={['100%', '100%', '45%', '45%']} gap="8">
          <SelectPadrao
            required
            isSearchable={false}
            id="tipoCadastro"
            name="tipoCadastro"
            label="Página de exibição"
            placeholder="Selecione a página de Exibição"
            isDisabled={readonly || bloquearAlteracao}
            options={getOptionsByEnum(TipoCadastroCampoPersonalizadoEnum)}
          />
          <SelectPadrao
            required
            isSearchable={false}
            id="tipo"
            name="tipo"
            label="Tipo de campo"
            placeholder="Selecione o tipo de Campo"
            isDisabled={readonly || bloquearAlteracao}
            options={getOptionsByEnum(TipoCampoPersonalizadoEnum)}
          />
          <Input
            isRequired
            maxLength={50}
            placeholder="Exemplo: Número do WhatsApp"
            label="Nome do campo"
            name="nome"
            id="nome"
            isDisabled={readonly || bloquearAlteracao}
          />
          {(tipoWatch === TipoCampoPersonalizadoEnum.TEXTO ||
            tipoWatch === TipoCampoPersonalizadoEnum.CAIXATEXTO ||
            tipoWatch === undefined) && (
            <Input
              id="placeholder"
              name="placeholder"
              label="Mensagem interna"
              placeholder="Exemplo: Informe o nº com o DDD"
              maxLength={100}
              isDisabled={readonly || bloquearAlteracao}
              scale={0}
            />
          )}
          {(tipoWatch === TipoCampoPersonalizadoEnum.PORCENTAGEM ||
            tipoWatch === TipoCampoPersonalizadoEnum.MOEDA) && (
            <SelectPadrao
              isSearchable={false}
              id="casaDecimal"
              name="casaDecimal"
              label="Quantidade de casas decimais"
              placeholder="Selecione a quantidade de casas decimais"
              isDisabled={readonly || bloquearAlteracao}
              required
              options={[
                {
                  value: 0,
                  label: '0',
                },
                {
                  value: 1,
                  label: '1',
                },
                {
                  value: 2,
                  label: '2',
                },
                {
                  value: 3,
                  label: '3',
                },
              ]}
            />
          )}
          {tipoWatch !== TipoCampoPersonalizadoEnum.DATA &&
            tipoWatch !== TipoCampoPersonalizadoEnum.CHECKBOX && (
              <NumberInput
                id="tamanho"
                name="tamanho"
                label="Tamanho do campo"
                placeholder="Informe o limite do tamanho para o campo"
                defaultValue={1}
                max={1000}
                isDisabled={readonly || bloquearAlteracao}
                isRequired
                scale={0}
              />
            )}
          <Box w="full">
            <Text fontSize="sm" fontWeight="semibold" color="black">
              Configurações
            </Text>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              p="15px"
              w="full"
              border="1px"
              borderColor="gray.200"
              borderRadius="5px"
            >
              <Box>
                <Text fontSize="sm">
                  Incluir texto com instruções para o preenchimento do campo
                </Text>
              </Box>
              <Box>
                <Switch
                  size="md"
                  id="instrucao"
                  name="instrucao"
                  isDisabled={readonly || bloquearAlteracao}
                />
              </Box>
            </Flex>
          </Box>
          {instrucao && (
            <Box w="100%">
              <TextareaField
                name="descricaoInstrucao"
                id="descricaoInstrucao"
                label="Descrição das instruções"
                resize="none"
                maxLength={150}
                placeholder="Informe a mensagem que o usuário irá encontrar ao clicar no ícone de aviso"
                isDisabled={readonly || bloquearAlteracao}
              />
            </Box>
          )}
          <CheckboxAtivoInativo
            id="ativo"
            name="ativo"
            label="Status"
            isDisabled={readonly}
          />
        </VStack>
        <VStack w={['100%', '100%', '55%', '55%']} flexDirection="column">
          <VStack w="100%" align="left">
            <Text
              color="blue.400"
              fontSize="sm"
              fontWeight="semibold"
              mb="-5px"
            >
              Pré-Visualização
            </Text>
            <Box
              bgColor="gray.100"
              minH={['180px', '225px', '295px']}
              w="100%"
              p={[5, 6, 10]}
            >
              <VStack
                borderRadius="5px"
                bg="white"
                boxShadow="0px 0px 6px 0px rgba(0, 0, 0, 0.2)"
                p={[2, 4, 6]}
                py={[4, 8, 12]}
              >
                <VisualizacaoCampo />
              </VStack>
            </Box>
          </VStack>
        </VStack>
      </Flex>
    </>
  );
};

export default FormCampoPersonalizado;
