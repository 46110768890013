import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import TipoCampoPersonalizadoEnum from 'constants/enum/tipoCampoPersonalizado';

export interface CampoPersonalizadoInterface {
  id: string;
  nome: string;
  ativo: boolean;
  dataHoraCadastro: string;
  dataHoraUltimaAlteracao: string;
  tipoCadastro: number;
  tipo: number;
  tamanho: number;
  casaDecimal: number;
  descricaoInstrucao: string;
  placeHolder: string;
  bloquearAlteracao: boolean;
}

const schema = yup.object().shape({
  tipo: yup
    .number()
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  tipoCadastro: yup
    .number()
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  nome: yup
    .string()
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .max(50, ConstanteMensagemValidacao.MAX_LENGTH_50),
  tamanho: yup
    .number()
    .nullable()
    .when('tipo', (tipo: any) => {
      if (
        tipo === TipoCampoPersonalizadoEnum.DATA ||
        tipo === TipoCampoPersonalizadoEnum.CHECKBOX
      ) {
        return yup.string().nullable().notRequired();
      }
      return yup
        .string()
        .nullable()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO);
    }),
  casaDecimal: yup
    .number()
    .nullable()
    .when('tipo', (tipo: any) => {
      if (
        tipo !== TipoCampoPersonalizadoEnum.PORCENTAGEM &&
        tipo !== TipoCampoPersonalizadoEnum.MOEDA
      ) {
        return yup.string().nullable().notRequired();
      }
      return yup
        .string()
        .nullable()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO);
    }),
});

export const yupResolver = yupResolverInstance(schema);

export const useForm = useFormInstance;
