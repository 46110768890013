import {
  ModalBody,
  ModalProps,
  useDisclosure,
  ModalCloseButton,
  ModalContent,
  Flex,
  Button,
  useBreakpointValue,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import Input from 'components/PDV/Input';

import ModalPadraoChakra from '../ModalPadraoChakra';
import { ModalEncontreUmVoucher } from '../ModalEncontreUmVoucher';
import { yupResolver, FormData } from './ValidationForm';

type VoucherProps = {
  valeId: string;
  saldoVale: number;
  sequenciaOrdenacao: number;
};

type PromiseModal = {
  sucesso: boolean;
  saldoVale?: number;
  valeId?: string;
  vouchersSelecionados?: VoucherProps[];
};

interface ModalInserirVoucherProps
  extends Omit<ModalProps, 'children' | 'isOpen' | 'onClose'>,
    InstanceProps<PromiseModal> {
  cliente: {
    id?: string;
    name?: string;
  };
  value?: number;
  voucherUnico?: boolean;
}

type ResponseVale = {
  valeId: string;
  saldo: number;
};

function Modal({
  cliente,
  value,
  voucherUnico = false,
  onResolve,
  ...props
}: ModalInserirVoucherProps) {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const isResponsiveVersion = useBreakpointValue({
    base: false,
    md: true,
    lg: true,
  });

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
  });
  const {
    watch,
    formState: { errors },
  } = formMethods;

  const voucherValue = watch('vale');

  const handleSubmit = formMethods.handleSubmit(async ({ vale }) => {
    const response = await api.get<void, ResponseApi<ResponseVale>>(
      ConstanteEnderecoWebservice.OBTER_VALE,
      {
        params: { identificador: vale },
      }
    );

    if (response?.avisos) {
      response.avisos.forEach((avisos) => toast.warning(avisos));
    }

    if (response.sucesso && response.dados) {
      const { saldo, valeId } = response.dados;
      if (value && saldo) {
        onResolve({ sucesso: true, saldoVale: saldo, valeId });
        onClose();
      }
    }
  });

  async function handleOnOpenModalEncontrarUmVoucher() {
    onClose();
    setTimeout(async () => {
      const { sucesso, voucherSelecionados } = await ModalEncontreUmVoucher({
        currentClient: {
          id: cliente.id,
          name: cliente.name,
        },
        voucherUnico,
      });
      onResolve({ sucesso, vouchersSelecionados: voucherSelecionados });
    }, 220);
  }

  return (
    <FormProvider {...formMethods}>
      <ModalPadraoChakra
        {...props}
        size="4xl"
        isCentered={isResponsiveVersion}
        isOpen={isOpen}
        onClose={onClose}
        hasOverlay={isResponsiveVersion}
        scrollBehavior="inside"
      >
        <ModalContent
          margin="0"
          top={{ base: '106px', md: '0' }}
          borderRadius={{ base: '0', md: 'md' }}
          overflowX={{ base: 'hidden', md: 'auto' }}
          overflowY={{ base: 'hidden', md: 'auto' }}
          h={{ base: 'calc(100% - 50px)', md: 'auto' }}
        >
          <ModalBody>
            <ModalCloseButton />
            <Flex
              flexDirection="column"
              mt={{ base: '80px', md: '134px' }}
              ml={{ base: '0', md: '181px' }}
              mr={{ base: '0', md: '191px' }}
            >
              <Flex
                direction={!isResponsiveVersion ? 'column' : 'row'}
                alignItems="center"
              >
                <Input
                  id="vale"
                  name="vale"
                  label="Informe o código do voucher:"
                  errorText={errors?.vale?.message ?? ''}
                  isInvalid={!!errors?.vale?.message}
                  maxW={{ base: 'full', md: '480px' }}
                  autoFocus
                />

                <Button
                  size="sm"
                  mt="13px"
                  ml="20px"
                  bg={voucherValue ? 'secondary.400' : 'gray.100'}
                  sx={{
                    '&': {
                      transition: 'filter 0.2s',
                    },
                    '&:hover': {
                      backgroundColor: 'secondary.400',
                      filter: 'brightness(0.9)',
                    },
                  }}
                  maxW="96px"
                  w="full"
                  onClick={handleSubmit}
                >
                  Aplicar
                </Button>
              </Flex>
              <Flex justifyContent="center">
                <Button
                  variant="unstyled"
                  color="primary.500"
                  textDecoration="underline"
                  mt={{ base: '15.62rem', md: '10.375rem' }}
                  mb={{ base: '0', md: '13.81rem' }}
                  w="full"
                  minW="11.31rem"
                  size="sm"
                  _focus={{
                    borderColor: 'transparent',
                  }}
                  onClick={handleOnOpenModalEncontrarUmVoucher}
                >
                  Pesquisar voucher disponível
                </Button>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </ModalPadraoChakra>
    </FormProvider>
  );
}

export const ModalInserirVoucher = create<
  ModalInserirVoucherProps,
  PromiseModal
>(Modal);
