import { Flex, GridItem, Box, FormLabel } from '@chakra-ui/react';

import { Switch } from 'components/update/Switch';
import { TextArea } from 'components/update/TextArea';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';

export const OpcoesImpressao = () => {
  return (
    <SimpleGridForm w="full">
      <GridItem colSpan={[12, 12, 6]}>
        <Flex>
          <Box mr="10px">
            <Switch
              size="md"
              id="fechContaImprimirTotalizado"
              name="fechContaImprimirTotalizado"
            />
          </Box>
          <Flex>
            <FormLabel>
              Imprimir os itens totalizados no fechamento da conta
            </FormLabel>
          </Flex>
        </Flex>
      </GridItem>
      <GridItem colSpan={[12, 12, 6]}>
        <Flex>
          <Box mr="10px">
            <Switch
              size="md"
              id="imprimirComplementoComValor"
              name="imprimirComplementoComValor"
            />
          </Box>
          <Flex>
            <FormLabel>Imprimir complementos com valor</FormLabel>
          </Flex>
        </Flex>
      </GridItem>
      <GridItem colSpan={[12, 12, 6]}>
        <Flex>
          <Box mr="10px">
            <Switch
              size="md"
              id="mostrarLimitesRelVendas"
              name="mostrarLimitesRelVendas"
            />
          </Box>
          <Flex>
            <FormLabel>
              Imprimir limites de crédito do cliente na venda
            </FormLabel>
          </Flex>
        </Flex>
      </GridItem>
      <GridItem colSpan={[12, 12, 6]}>
        <Flex>
          <Box mr="10px">
            <Switch
              size="md"
              id="solicitarImpressaoEcologica"
              name="solicitarImpressaoEcologica"
            />
          </Box>
          <Flex>
            <FormLabel>Solicitar impressão ecológica da NFC-e</FormLabel>
          </Flex>
        </Flex>
      </GridItem>
      <GridItem colSpan={[12, 12, 6]}>
        <Flex>
          <Box mr="10px">
            <Switch
              size="md"
              id="impressaoPorItensPDV"
              name="impressaoPorItensPDV"
            />
          </Box>
          <Flex>
            <FormLabel>
              Imprimir fichas individuais dos itens da venda
            </FormLabel>
          </Flex>
        </Flex>
      </GridItem>
      <GridItem colSpan={[12, 12, 6]}>
        <Flex>
          <Box mr="10px">
            <Switch
              size="md"
              id="impressaoItensRemovidosVenda"
              name="impressaoItensRemovidosVenda"
            />
          </Box>
          <Flex>
            <FormLabel>Imprimir itens removidos da venda</FormLabel>
          </Flex>
        </Flex>
      </GridItem>
      <GridItem colSpan={[12, 12, 6]}>
        <Flex>
          <Box mr="10px">
            <Switch
              size="md"
              id="imprimirObservacaoDoItem"
              name="imprimirObservacaoDoItem"
            />
          </Box>
          <Flex>
            <FormLabel>Imprimir observação do item</FormLabel>
          </Flex>
        </Flex>
      </GridItem>
      <GridItem colSpan={[12, 12, 6]}>
        <Flex>
          <Box mr="10px">
            <Switch
              size="md"
              id="imprimirComposicaoProduto"
              name="imprimirComposicaoProduto"
            />
          </Box>
          <Flex>
            <FormLabel>Imprimir composição do produto</FormLabel>
          </Flex>
        </Flex>
      </GridItem>
      <GridItem colSpan={[12, 12, 6]}>
        <Flex>
          <Box mr="10px">
            <Switch
              size="md"
              id="imprimirViaCupomNaoFiscal"
              name="imprimirViaCupomNaoFiscal"
            />
          </Box>
          <Flex>
            <FormLabel>
              Imprimir via de cupom não fiscal após impressão do cupom fiscal
            </FormLabel>
          </Flex>
        </Flex>
      </GridItem>
      <GridItem colSpan={[12, 12, 6]}>
        <Flex>
          <Box mr="10px">
            <Switch
              size="md"
              id="imprimirIndividualmenteCozinha"
              name="imprimirIndividualmenteCozinha"
            />
          </Box>
          <Flex>
            <FormLabel>Imprimir individualmente na cozinha</FormLabel>
          </Flex>
        </Flex>
      </GridItem>
      <GridItem colSpan={12}>
        <Flex>
          <Box mr="10px">
            <Switch
              size="md"
              id="imprimirDadosCompletosCliente"
              name="imprimirDadosCompletosCliente"
            />
          </Box>
          <Flex>
            <FormLabel>Imprimir informações completas do cliente</FormLabel>
          </Flex>
        </Flex>
      </GridItem>
      <GridItem colSpan={[12, 12, 6]}>
        <TextArea
          name="cabecalhoCupom"
          id="cabecalhoCupom"
          placeholder=""
          label="Cabeçalho do cupom sem validade fiscal"
          w="full"
        />
      </GridItem>
      <GridItem colSpan={[12, 12, 6]}>
        <TextArea
          w="full"
          name="rodapeCupomParceiro"
          id="rodapeCupomParceiro"
          placeholder=""
          label="Informação de revenda para rodapé do cupom sem validade fiscal"
        />
      </GridItem>
      <GridItem colSpan={[12, 12, 6]}>
        <TextArea
          name="rodapeCupom"
          id="rodapeCupom"
          placeholder=""
          label="Mensagem para impressão no final do cupom sem validade fiscal"
          w="full"
        />
      </GridItem>
      <GridItem colSpan={[12, 12, 6]}>
        <TextArea
          w="full"
          name="rodapeFicha"
          id="rodapeFicha"
          placeholder=""
          label="Mensagem para impressão no final da ficha"
        />
      </GridItem>
    </SimpleGridForm>
  );
};
