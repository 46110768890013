import React, { useState } from 'react';
import {
  ModalContent,
  ModalBody,
  Flex,
  Button,
  Heading,
  Text,
  ModalFooter,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';

import { AtencaoAvisoIcon } from 'icons';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import Input from 'components/PDV/Input';

export type ModalDesistenciaProps = {
  textoMensagem?: string | React.ReactNode;
  isConfirmar?: boolean;
  showCancelButton?: boolean;
  title?: string;
  callback: () => void;
  callbackCancel?: () => void;
  colorButtonConfirm?: string;
  labelButtonCancelar?: string;
  labelButtonConfirmar?: string;
  widthButtonConfirmar?: string;
  textForConfirm?: string;
};

type ModalProps = Omit<
  ModalDesistenciaProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalDesistenciaProps>;

export const ModalDesistencia = create<ModalProps>(
  ({
    textoMensagem,
    labelButtonCancelar,
    labelButtonConfirmar,
    colorButtonConfirm = 'black',
    widthButtonConfirmar = 'fit-content',
    title,
    callbackCancel,
    isConfirmar = false,
    showCancelButton = true,
    callback,
    textForConfirm = 'CONFIRMAR',
  }) => {
    const [isOpen, setIsOpen] = useState(true);

    const formMethods = useForm();

    const campoConfirmarWatch = formMethods.watch('campoConfirmar');

    const confirmationFieldIsCorrect = campoConfirmarWatch === textForConfirm;

    const handleConfirm = () => {
      setIsOpen(false);
      callback();
    };

    const handleCancel = () => {
      setIsOpen(false);
      if (callbackCancel) {
        callbackCancel();
      }
    };

    return (
      <ModalPadraoChakra
        onClose={() => {
          setIsOpen(false);
        }}
        isOpen={isOpen}
        isCentered
        closeOnOverlayClick={false}
        autoFocus={false}
      >
        <ModalContent
          marginBottom={{ base: 0, sm: '3.75rem' }}
          marginTop={{ base: 0, sm: '3.75rem' }}
          bg="gray.50"
          maxW="560px"
          height={['full', '415px']}
        >
          <Flex
            justifyContent="center"
            minH="128px"
            bg="orange.400"
            style={{
              borderTopLeftRadius: '5px',
              borderTopRightRadius: '5px',
            }}
          >
            <AtencaoAvisoIcon
              style={{
                fontSize: '60px',
                fontWeight: 'bolder',
                marginTop: '32px',
              }}
            />
          </Flex>
          <ModalBody p={0}>
            <Flex flexDirection="column">
              <Flex
                justifyContent="flex-start"
                flexDirection="column"
                style={{
                  borderBottomLeftRadius: '5px',
                  borderBottomRightRadius: '5px',
                }}
              >
                <Heading
                  id="tituloModal"
                  as="h2"
                  fontSize="20px"
                  color="orange.500"
                  fontWeight="semibold"
                  textAlign="initial"
                  px="40px"
                  mt="24px"
                >
                  {title || 'Desistiu do cadastro?'}
                </Heading>
                <Text
                  fontSize="14px"
                  color="gray.700"
                  px="40px"
                  mt="15px"
                  lineHeight="1.8"
                  letterSpacing="0px"
                  whiteSpace="pre-line"
                >
                  {textoMensagem ||
                    'Todos os dados que você preencheu serão cancelados.'}
                </Text>
                {isConfirmar && (
                  <Flex mt="20px">
                    <FormProvider {...formMethods}>
                      <Input
                        maxLength={9}
                        id="campoConfirmar"
                        name="campoConfirmar"
                        label={`Digite ${textForConfirm} e inicie a ação`}
                        colSpan={12}
                        placeholder={textForConfirm}
                      />
                    </FormProvider>
                  </Flex>
                )}
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter
            display="flex"
            justifyContent="center"
            flexDir={{ base: 'column', sm: 'row' }}
            gap="24px"
            px="40px"
            mt="12px"
            mb="24px"
          >
            {showCancelButton && (
              <Button
                onClick={() => handleCancel()}
                variant="outlineDefault"
                colorScheme="gray"
                borderRadius="16px"
                minWidth="96px"
                width={['full', 'fit-content']}
                fontSize="14px"
                height="32px"
              >
                {labelButtonCancelar || 'Voltar ao cadastro'}
              </Button>
            )}

            <Button
              onClick={handleConfirm}
              variant="solid"
              isDisabled={!!(isConfirmar && !confirmationFieldIsCorrect)}
              color={colorButtonConfirm}
              bg="orange.400"
              _hover={{ bg: 'orange.300' }}
              colorScheme="orange"
              borderRadius="16px"
              height="32px"
              minWidth="160px"
              fontSize="14px"
              width={['full', widthButtonConfirmar]}
            >
              {labelButtonConfirmar || 'Sim, desisti'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
