import React from 'react';

import auth, { PossuiPermissaoResponse } from 'modules/auth';

import { SemPermissaoIcon, SemPermissaoPlanoIcon, IconType } from 'icons';

interface ChildWithFuncProps extends PossuiPermissaoResponse {
  icon?: IconType;
}

type ChildWithFuncType = (props: ChildWithFuncProps) => React.ReactNode;

interface ValidarPermissaoProps {
  funcionalidade?: string | string[];
  todasFuncionalidadesNecessarias?: boolean;
  children: ChildWithFuncType;
}

export const ValidarPermissao = ({
  funcionalidade = '',
  todasFuncionalidadesNecessarias = false,
  children,
}: ValidarPermissaoProps) => {
  const hasUserData = auth.getDadosToken();
  if (!hasUserData) {
    window.location.reload();
  }

  if (funcionalidade instanceof Array) {
    const { permitido, bloqueio } = funcionalidade.reduce(
      (prev, curr) => {
        const {
          permitido: currPermitido,
          bloqueio: currBloqueio,
        } = auth.possuiPermissao(curr);

        if (todasFuncionalidadesNecessarias) {
          return {
            permitido: prev.permitido && currPermitido,
            bloqueio: prev.bloqueio || currBloqueio,
          };
        }

        return {
          permitido: prev.permitido || currPermitido,
          bloqueio: prev.bloqueio || currBloqueio,
        };
      },
      {
        permitido: todasFuncionalidadesNecessarias,
        bloqueio: undefined,
      } as PossuiPermissaoResponse
    );

    return (
      <>
        {children({
          permitido,
          bloqueio,
          icon:
            bloqueio === 'permissao' ? SemPermissaoIcon : SemPermissaoPlanoIcon,
        })}
      </>
    );
  }

  const { permitido, bloqueio } = auth.possuiPermissao(funcionalidade);

  if (permitido || !funcionalidade) {
    return <>{children({ permitido: true })}</>;
  }

  return (
    <>
      {children({
        permitido,
        bloqueio,
        icon:
          bloqueio === 'permissao' ? SemPermissaoIcon : SemPermissaoPlanoIcon,
      })}
    </>
  );
};
