import { GridItem, Button, Icon } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import Input from 'components/PDV/Input';
import { SalvarInserirNovoIcon } from 'icons';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';

import { Listagem } from './components/List';
import { Printer } from './components/Printer';

const UncontrolledForm = () => {
  const { watch, setValue } = useFormContext();

  const impressoraWatch = watch('impressora');

  const resetValuesPrinter = () => {
    setValue('impressora', null);
    setValue('imprimirAutomatico', false);
    setValue('quantidadeCopias', 1);
    setValue('confirmarImpressao', false);
  };

  const handleAddPrinter = () => {
    const data = watch();
    const newPrinterId = data?.impressora?.value;
    if (
      data.impressoras.find(
        (impressora: { impressoraId: string }) =>
          impressora.impressoraId === newPrinterId
      )
    ) {
      toast.warning('Impressora já adicionada');
      setValue('impressora', null);
      return;
    }

    setValue(`impressoras.`, [
      ...data.impressoras,
      {
        impressoraId: newPrinterId,
        imprimirAutomatico: data.imprimirAutomatico,
        quantidadeCopias: data.quantidadeCopias,
        confirmarImpressao: data.confirmarImpressao,
        nome: data.impressora.label,
      },
    ]);
    resetValuesPrinter();
  };

  return (
    <SimpleGridForm gap="24px">
      <GridItem colSpan={{ base: 12 }}>
        <Input
          id="nome"
          name="nome"
          label="Nome"
          placeholder="Informe o nome do gerenciador de impressão"
        />
      </GridItem>
      <Printer />

      <GridItem colSpan={12}>
        <Button
          w="full"
          borderRadius="full"
          colorScheme="aquamarine"
          isDisabled={!impressoraWatch}
          leftIcon={<Icon as={SalvarInserirNovoIcon} fontSize="lg" />}
          onClick={handleAddPrinter}
        >
          Adicionar impressora
        </Button>
      </GridItem>
      <GridItem colSpan={12}>
        <Listagem />
      </GridItem>
    </SimpleGridForm>
  );
};

export { UncontrolledForm };
