import React from 'react';
import { Tooltip, TooltipProps, Box } from '@chakra-ui/react';

interface TooltipPermissaoProps extends TooltipProps {
  bloqueio?: 'plano' | 'permissao';
}

export const TooltipPermissao = ({
  children,
  bloqueio,
  ...rest
}: TooltipPermissaoProps) => {
  return (
    <Tooltip
      hasArrow
      label={
        bloqueio === 'plano'
          ? 'Esta funcionalidade não esta disponível neste plano, faça um upgrade.'
          : 'Você não tem permissão para acessar essa função. Consulte o administrador da conta.'
      }
      {...rest}
    >
      <Box tabIndex={0}>{children}</Box>
    </Tooltip>
  );
};
