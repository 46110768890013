import React from 'react';
import { Grid } from '@chakra-ui/react';

import Input from 'components/PDV/Input';
import { SimpleCard } from 'components/update/Form/SimpleCard';
import CheckboxAtivoInativo from 'components/update/Checkbox/CheckboxAtivoInativo';

interface FormularioProps {
  isReadonly?: boolean;
}

const Formulario = ({ isReadonly = false }: FormularioProps) => {
  return (
    <SimpleCard>
      <Grid
        gap={{ base: 3, sm: 6, md: 8 }}
        templateColumns={{ base: '1fr', sm: '1fr auto' }}
      >
        <Input
          id="nome"
          name="nome"
          placeholder="Digite um nome para o novo estoque"
          label="Descrição"
          maxLength={20}
          isRequired
          autoFocus
          isDisabled={isReadonly}
        />

        <CheckboxAtivoInativo
          id="ativo"
          name="ativo"
          label="Status"
          isRequired
          isDisabled={isReadonly}
        />
      </Grid>
    </SimpleCard>
  );
};

export default Formulario;
