import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import TipoConferenciaEnum from 'constants/enum/tipoConferencia';

type Filtro = { label: string; value: string; isFixed?: boolean };

export type FormData = {
  tipoConferencia: string;
  categorias: Filtro[] | null;
  marcas: Filtro[] | null;
};

const schema = yup.object().shape({
  tipoConferencia: yup
    .string()
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  categorias: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      })
    )
    .nullable()
    .default(null)
    .test(
      'oneFilterOrAnother',
      ConstanteMensagemValidacao.UM_DOS_FILTROS_OBRIGATORIO,
      function oneFilterOrAnother(categorias) {
        const { tipoConferencia, marcas } = this.parent;

        if (
          tipoConferencia === String(TipoConferenciaEnum.COM_FILTROS) &&
          (!categorias || (categorias && categorias.length === 0)) &&
          (!marcas || (marcas && marcas.length === 0))
        ) {
          return false;
        }

        return true;
      }
    ),
  marcas: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      })
    )
    .nullable()
    .default(null)
    .test(
      'oneFilterOrAnother',
      ConstanteMensagemValidacao.UM_DOS_FILTROS_OBRIGATORIO,
      function oneFilterOrAnother(marcas) {
        const { tipoConferencia, categorias } = this.parent;

        if (
          tipoConferencia === String(TipoConferenciaEnum.COM_FILTROS) &&
          (!marcas || (marcas && marcas.length === 0)) &&
          (!categorias || (categorias && categorias.length === 0))
        ) {
          return false;
        }

        return true;
      }
    ),
});

export const yupResolver = yupResolverInstance(schema);
