import { useCallback, useEffect, useState } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  ModalFooter,
  ModalHeader,
  useMediaQuery,
  GridItem,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { enumIdentificacaoIntegracao } from 'constants/enum/enumIdentificacaoIntegracao';

import InputCpfCnpj from 'components/PDV/InputCpfCnpj';
import Input from 'components/PDV/Input';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { ButtonDefault } from 'components/Button/ButtonChakra';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';

import { yupResolver } from './validationForms';

type FormData = {
  identificacaoSistema: string;
  cnpj: string;
};

type IntermediadorResponseProps = {
  id: string;
  descricao: string;
};

type ModalIntermediadorProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<FormData> & {
    id: string;
    atualizarPendencia: (id: string) => void;
    siteId: string;
    trayId: string;
  };

export const ModalIntermediador = create<ModalIntermediadorProps, FormData>(
  ({
    onResolve,
    onReject,
    id,
    siteId,
    trayId,
    atualizarPendencia,
    ...rest
  }) => {
    const [
      intermediadorSite,
      setIntermediadorSite,
    ] = useState<IntermediadorResponseProps>();
    const [isLoading, setIsLoading] = useState(false);

    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    const formMethods = useForm<FormData>({
      resolver: yupResolver,
    });

    const { handleSubmit: onSubmit } = formMethods;

    const handleSubmit = onSubmit(async (formData) => {
      setIsLoading(true);
      const data = {
        id: intermediadorSite?.id,
        descricao: formData.identificacaoSistema,
        cnpj: formData.cnpj,
        integracaoId: trayId,
      };

      const response = await api.put<void, ResponseApi>(
        ConstanteEnderecoWebservice.INTEGRACAO_ORIGEM_PEDIDO_ALTERAR,
        [data]
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }

        if (response.sucesso) {
          toast.success('Intermediador vinculado com sucesso');
          atualizarPendencia(id);
          onClose();
          setIsLoading(false);
        }
        setIsLoading(false);
      }
      setIsLoading(false);
    });

    const getIntermediador = useCallback(async () => {
      setIsLoading(true);
      const response = await api.get<
        void,
        ResponseApi<IntermediadorResponseProps[]>
      >(ConstanteEnderecoWebservice.INTEGRACAO_TRAY_ORIGEM, {
        params: {
          integracao: enumIdentificacaoIntegracao.TRAY,
        },
      });

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }

        if (response.sucesso && response.dados) {
          const newIntermediador = response.dados?.find((itemIntermediador) =>
            itemIntermediador?.descricao
              ?.toLowerCase()
              .includes(siteId.toLowerCase())
          );

          setIntermediadorSite(newIntermediador);

          setIsLoading(false);
        }
        setIsLoading(false);
      }
      setIsLoading(false);
    }, [siteId]);

    useEffect(() => {
      getIntermediador();
    }, [getIntermediador]);

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? '3xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
      >
        <ModalContent
          bg="gray.50"
          borderRadius={isLargerThan900 ? 'md' : '0'}
          marginBottom={isLargerThan900 ? '3.75rem' : '0'}
          marginTop={isLargerThan900 ? '3.75rem' : '0'}
          w={isLargerThan900 ? '700px' : 'full'}
          h={isLargerThan900 ? '356px' : 'full'}
        >
          <ModalHeader pt="16px" pb="0px" pl="24px">
            <Text
              pb="15px"
              color="primary.50"
              borderBottomWidth="1px"
              borderColor="gray.100"
              fontSize="16px"
            >
              Vincular o intermediador
            </Text>
          </ModalHeader>

          <FormProvider {...formMethods}>
            {isLoading && <LoadingPadrao />}
            <ModalBody
              px="24px"
              mb="20px"
              pt={['10px', '10px', '40px']}
              overflowY="auto"
              pb="0"
            >
              <FormProvider {...formMethods}>
                <SimpleGridForm>
                  <GridItem colSpan={[12, 12, 4]}>
                    <Flex display="grid" h="full" alignItems="center" mr="10px">
                      {' '}
                      • {siteId}
                    </Flex>
                  </GridItem>
                  <GridItem colSpan={[12, 12, 4]}>
                    <Input
                      id="identificacaoSistema"
                      label="Identificação no sistema:"
                      name="identificacaoSistema"
                      defaultValue={siteId}
                      placeholder="Informe a identificação"
                    />
                  </GridItem>
                  <GridItem colSpan={[12, 12, 4]}>
                    <InputCpfCnpj
                      name="cnpj"
                      label="Cnpj"
                      placeholder="00.000.000-0000/00"
                      id="cnpj"
                    />
                  </GridItem>
                </SimpleGridForm>
              </FormProvider>
            </ModalBody>
            <ModalFooter flexDirection="column">
              <Flex
                borderTopWidth="1px"
                borderColor="gray.100"
                w="full"
                pt="20px"
                h="full"
                justifyContent="center"
                alignItems="baseline"
              >
                <Button
                  variant="outlineDefault"
                  colorScheme="gray"
                  mr="24px"
                  onClick={() => onClose()}
                  w="140px"
                >
                  Cancelar
                </Button>
                <ButtonDefault
                  variant="solid"
                  colorScheme="secondary.400"
                  width="160px"
                  onClick={() => handleSubmit()}
                  isLoading={isLoading}
                >
                  Confirmar vínculo
                </ButtonDefault>
              </Flex>
            </ModalFooter>
          </FormProvider>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
