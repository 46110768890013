import React from 'react';
import { Box, Heading, Divider, Flex } from '@chakra-ui/react';

import { ImportarProvider, ImportarContext } from 'store/ImportarContext';

import { Steps, Step } from 'components/update/Steps';

import { PrimeiraEtapa } from './Etapas/PrimeiraEtapa';
import { SegundaEtapa } from './Etapas/SegundaEtapa';
import { TerceiraEtapa } from './Etapas/TerceiraEtapa';

interface ImportarProps {
  tipoImportacao: number;
}

export default function Importar({ tipoImportacao }: ImportarProps) {
  return (
    <ImportarProvider tipoImportacao={tipoImportacao}>
      <ImportarContext.Consumer>
        {({ activeStep, nomeTipoImportacao }) => {
          const isLastStep = activeStep >= 2;

          const steps: Step[] = [
            {
              label: 'Importar lista',
              content: <PrimeiraEtapa />,
            },
            {
              label: 'Combinar campos',
              content: <SegundaEtapa />,
            },
            {
              label: 'Importar',
              content: <TerceiraEtapa />,
              isCompletedStep: isLastStep,
            },
          ];

          return (
            <Box
              bg="white"
              borderRadius="md"
              boxShadow="md"
              maxW={activeStep === 1 ? '1820px' : '1166px'}
              px="6"
            >
              {!isLastStep && (
                <Box>
                  <Heading
                    as="h2"
                    lineHeight="tall"
                    fontWeight="normal"
                    size="xs"
                    py="6"
                  >
                    {activeStep === 0 &&
                      `Importe os ${nomeTipoImportacao.toLowerCase()} de um arquivo Excel ou .CSV separado por ;. Para isto arraste e solte o mesmo no retângulo cinza abaixo ou clique no botão para selecioná-lo em uma pasta de seu computador.`}
                    {activeStep === 1 && (
                      <>
                        Combine a primeira coluna, referente ao seu arquivo, com
                        a segunda coluna &quot;Campo no Sistema&quot; para
                        validar o campo. Ou clique no botão &quot;Ignorar&quot;
                        para desabilitar o item.
                        <br />
                        <u>
                          <strong>Importante:</strong> Serão importados apenas
                          os campos validados após a combinacao entra as
                          colunas.
                        </u>
                      </>
                    )}
                  </Heading>

                  <Divider />
                </Box>
              )}

              <Box py="8">
                <Flex w="full" flexDir="column" alignItems="center">
                  <Steps
                    w="100%"
                    maxW="470px"
                    colorScheme="primary"
                    activeStep={activeStep}
                    steps={steps}
                  />
                </Flex>
              </Box>
            </Box>
          );
        }}
      </ImportarContext.Consumer>
    </ImportarProvider>
  );
}
