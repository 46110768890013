import {
  ModalContent,
  ModalHeader,
  ModalProps,
  ModalBody,
} from '@chakra-ui/react';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';

import { MobileModalMenuHeader } from './MobileModalMenuHeader';

interface MobileModalMenuProps extends ModalProps {
  title: string;
  bg?: string;
  color?: string;
  voltarIconColor?: string;
  pt?: string;
  px?: string;
  py?: string;
  hasHeader?: boolean;
  appendToParentPortal?: boolean;
}

export function MobileModalMenu({
  isOpen,
  onClose,
  title,
  children,
  bg = 'gray.50',
  color = 'primary.50',
  voltarIconColor = 'black',
  pt = '4',
  px = '4',
  py = '4',
  hasHeader = true,
  appendToParentPortal,
  ...rest
}: MobileModalMenuProps) {
  return (
    <ModalPadraoChakra
      {...rest}
      motionPreset="none"
      size="full"
      appendToParentPortal={appendToParentPortal}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalContent
        margin="0"
        borderRadius="0"
        overflowX="hidden"
        overflowY="auto"
        h="full"
        bg={bg}
      >
        {hasHeader && (
          <ModalHeader p="0" bg={bg}>
            <MobileModalMenuHeader
              voltarIconColor={voltarIconColor}
              color={color}
              onClose={onClose}
              title={title}
            />
          </ModalHeader>
        )}
        <ModalBody py={py} px={px} pt={pt} h="full" bg={bg}>
          {children}
        </ModalBody>
      </ModalContent>
    </ModalPadraoChakra>
  );
}
