export type FormData = {
  endDate: Date;
  startDate: Date;
  dataDePesquisa: Date;

  tipoDeRelatorio: number;
  ordenacao: number | undefined;
};

export type FilterProps = {
  ordenacao: number | undefined;
  tipoDeRelatorio: number;

  endDate: Date;
  startDate: Date;
  dataDePesquisa: Date;
};

export type RegistrosCurvaABCProps = {
  nome: string;
  valorUnitario: number;
  custo: number;
  quantidadeVendida: number;
  quantidadeVendas: number;
  valorTotal: number;
  lucroValor: number;
  lucroPorcentagem: number;
  valorBase: number;
  porcentagem: number;
  porcentagemAcumulada: number;
  classe: 'A' | 'B' | 'C';
};

export type ListaCurvaABCProps = {
  registros: RegistrosCurvaABCProps[];
};

export const today = new Date();
export const ThreeMonthsAgo = new Date(
  today.getFullYear(),
  today.getMonth(),
  today.getDate() - 90
);

export const firstDayOfMonth = new Date(
  today.getFullYear(),
  today.getMonth(),
  1
);
