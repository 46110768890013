import styled from 'styled-components';
import { Modal as BootstrapModal } from 'react-bootstrap';
import { Label } from 'components/Layout/CampoContainer/CampoContainerPadrao/styles';
import ButtonPadrao from 'components/Button/ButtonPadrao';

export const Container = styled.div`
  display: flex;
`;

// export const ButtonPadrao = styled.button`
//   max-width: unset !important;
// `;

export const ButtonPadraoFechar = styled(ButtonPadrao)`
  width: 80px;

  @media (max-width: 700px) {
    width: 100% !important;
    max-width: 100% !important;
  }
`;

export const Modal = styled(BootstrapModal)`
  .modal-dialog {
    max-width: 760px !important;

    .modal-header {
      margin: 0 22px;
      padding: 16px 0;
    }

    .modal-body {
      max-height: calc(100vh - (1.75rem * 2) - 160px) !important;
      max-width: 100vh;
      /* margin-right: 20px; */

      padding: 15px 15px 15px 15px !important;

      overflow-y: none;
      ::-webkit-scrollbar {
        width: 9px;
      }

      ::-webkit-scrollbar-track {
        background: var(--sti-ck-colors-gray-100);
        border-radius: 5px;
      }

      ::-webkit-scrollbar-thumb {
        background: var(--white);
        border: 1px solid var(--sti-ck-colors-gray-100);
        border-radius: 5px;
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
      }

      ::-webkit-scrollbar-thumb:hover {
        background: var(--sti-ck-colors-gray-100);
      }
    }

    .modal-footer {
      /* margin: 0 22px; */
      padding: 20px 15px 20px 15px !important;
      justify-content: unset !important;
    }
  }

  /* div[class^='col'] .form-group {
    padding-left: 13px;
    padding-right: 13px;
  } */

  /* div.form-row .form-group,
  div.row .form-group {
    margin-bottom: 27px;
  } */
`;

export const LabelErroEndereco = styled(Label)`
  color: red !important;
  padding-left: 13px;
`;

export default Modal;
