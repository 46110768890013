import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export type ProdutoResponse = {
  id: string;
  nome: string;
  precoCusto: number;
  markup: number;
  precoVenda: number;
  valorUnitarioEntrada: number;
  freteDistribuido: number;
  outrasDespesasDistribuido: number;
  descontoDistribuido: number;
  custoAdicional: number;
  valorIcmsSt: number;
  valorIpi: number;
  valorFcpSt: number;
  custoTotal: number;
  custoMedioNovo: number;
  custoMedioSemDesconto: number;
  quantidadeTotal: number;
  descricaoCustoProduto: string;
  custoTotalItem: number;
};

export interface Produto extends ProdutoResponse {
  isOpen: boolean;
  markupNovo: number;
  precoVendaNovo: number;
  height: number;
}

export type LojaProps = {
  id: string;
  fantasia: string;
  endereco: string;
  logradouro: string;
  numeroBairro: string;
  cidade: string;
  bloqueada: boolean;
  selecionada: boolean;
  isLojaAtual: boolean;
};

export type FormData = {
  produtos: Produto[];
  manterPrecoVenda: boolean;
  manterCustoAtual: boolean;
  desconsiderarDescontos: boolean;
  selecionarTodasLojas: boolean;
};

const schema = yup.object().shape({
  produtos: yup.array().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);
