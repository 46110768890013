import { useCallback, useState } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  ModalFooter,
  Box,
  ModalHeader,
  useMediaQuery,
  Divider,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import TipoProdutoEnum from 'constants/enum/tipoProduto';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { ButtonDefault } from 'components/Button/ButtonChakra';
import { FilterAsyncSelect } from 'components/update/Select/FilterAsyncSelect';
import { PrincipalProdutoProps } from 'pages/Produtos/Formulario/TabsContent/Principal';

import { yupResolver } from './validationForms';

type FormData = {
  idProduto: string;
};

type FornecedorOptionResponse = {
  id: string;
  nome: string;
};

type ModalSelecionarProdutoProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<FormData> & {
    nameProduto: string;
    idVenda: string;
    atualizarPendencia: () => void;
    tipoProduto: number;
  };

export const ModalSelecionarProduto = create<
  ModalSelecionarProdutoProps,
  FormData
>(
  ({
    onResolve,
    onReject,
    nameProduto,
    atualizarPendencia,
    tipoProduto,
    idVenda,
    ...rest
  }) => {
    const [isLoading, setIsLoading] = useState(false);

    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    const formMethods = useForm<FormData>({
      resolver: yupResolver,
    });

    const { handleSubmit: onSubmit } = formMethods;

    const identificacaoTipoProduto = useCallback(() => {
      switch (tipoProduto) {
        case TipoProdutoEnum.PRODUTO_SIMPLES:
          return 'simples';
        case TipoProdutoEnum.PRODUTO_VARIACAO:
          return 'variação';
        case TipoProdutoEnum.PRODUTO_KIT:
          return 'kit';

        default:
          return '';
      }
    }, [tipoProduto])();

    const vincularProduto = useCallback(
      async (id) => {
        const response = await api.put<void, ResponseApi<number>>(
          ConstanteEnderecoWebservice.INTEGRACAO_VINCULAR_PRODUTO,
          [
            {
              zendarId: id,
              siteId: idVenda,
            },
          ]
        );
        if (response) {
          if (response.avisos) {
            response.avisos.forEach((avisos) => toast.warning(avisos));
          }

          if (response.sucesso) {
            onClose();
            toast.success('Produto vinculado com sucesso');
            atualizarPendencia();
          }
          setIsLoading(false);
        }
        setIsLoading(false);
      },
      [atualizarPendencia, idVenda, onClose]
    );

    const handleSubmit = onSubmit(async (data) => {
      setIsLoading(true);

      const response = await api.get<void, ResponseApi<PrincipalProdutoProps>>(
        `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${data.idProduto}`
      );
      if (response) {
        if (response.avisos) {
          response.avisos.forEach((avisos) => toast.warning(avisos));
        }

        if (response.sucesso && response?.dados?.tipoProduto === tipoProduto) {
          await vincularProduto(data.idProduto);
        } else {
          toast.warning(
            `Escolha um produto que seja do tipo ${identificacaoTipoProduto}`
          );
          setIsLoading(false);
        }
      }
      setIsLoading(false);
    });

    async function getProdutosOptions(inputValue: string) {
      if (!inputValue) {
        return [];
      }
      const response = await api.get<
        void,
        ResponseApi<FornecedorOptionResponse[]>
      >(ConstanteEnderecoWebservice.LISTAR_SELECT_PRODUTO, {
        params: { nomeSkuCodigoExternoBarrasGtinEan: inputValue },
      });

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response.sucesso && response.dados) {
          return response.dados.map((fornecedor) => ({
            label: fornecedor.nome,
            value: fornecedor.id,
          }));
        }
      }
      return [];
    }

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? '3xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
      >
        <ModalContent
          bg="gray.50"
          borderRadius={isLargerThan900 ? 'md' : '0'}
          marginBottom={isLargerThan900 ? '3.75rem' : '0'}
          marginTop={isLargerThan900 ? '3.75rem' : '0'}
          w={isLargerThan900 ? '800px' : 'full'}
          h={isLargerThan900 ? '286px' : 'full'}
        >
          <ModalHeader pt="16px" pb="38px" pl="24px">
            <Text color="primary.50" fontSize="16px">
              Vincular produto
            </Text>

            <Divider mt="16px" />
          </ModalHeader>

          <FormProvider {...formMethods}>
            {isLoading && <LoadingPadrao />}
            <ModalBody px="24px" mb="20px" py="0">
              <Flex h="full" w="full">
                <Box w="full">
                  <FilterAsyncSelect
                    id="idProduto"
                    hasLupaIcon={false}
                    name="idProduto"
                    label="Selecione o cadastro no sistema:"
                    required
                    placeholder="Digite o nome ou referência"
                    handleGetOptions={getProdutosOptions}
                  />
                </Box>
              </Flex>
            </ModalBody>
            <ModalFooter flexDirection="column">
              <Flex
                w="full"
                h="full"
                justifyContent="center"
                alignItems="baseline"
              >
                <Button
                  colorScheme="gray"
                  variant="outlineDefault"
                  borderRadius="20px"
                  fontSize="sm"
                  h="32px"
                  mr="24px"
                  onClick={() => onClose()}
                  w="96px"
                >
                  Cancelar
                </Button>
                <ButtonDefault
                  color="black"
                  variant="solid"
                  colorScheme="secondary.400"
                  borderRadius="20px"
                  fontSize="sm"
                  h="32px"
                  width="160px"
                  onClick={() => handleSubmit()}
                  isLoading={isLoading}
                >
                  Selecionar
                </ButtonDefault>
              </Flex>
            </ModalFooter>
          </FormProvider>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
