import { Box, Flex, Icon, IconButton } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { Switch } from 'components/update/Switch';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import { LixeiraIcon } from 'icons';

import { numeroCopiasOptions } from '../../validationForm';

type Impressora = {
  nome: string;
  impressoraId: string;
  imprimirAutomatico: boolean;
  quantidadeCopias: number;
  confirmarImpressao: boolean;
};

export const Listagem = () => {
  const { watch, setValue } = useFormContext();
  const impressoras = watch('impressoras');

  const removeImpressora = (indexToRemove: number) => {
    const newPrinters = impressoras.filter(
      (_: Impressora, index: number) => index !== indexToRemove
    );
    setValue('impressoras', newPrinters);
  };

  return (
    <Flex
      flexDir="column"
      maxH="240px"
      height="240px"
      bg="transparent"
      overflow="auto"
    >
      <Flex
        gap="24px"
        fontSize="sm"
        borderRadius="4px"
        bg="gray.200"
        py="12px"
        width="fit-content"
        fontWeight="semibold"
        px="24px"
        borderBottomRadius="0"
      >
        <Flex minW="252px">Impressora</Flex>
        <Flex minW="160px">Imprimir automático</Flex>
        <Flex minW="160px">Confirmar impressão</Flex>
        <Flex minW="90px">Cópias</Flex>
        <Flex minW="36px" />
      </Flex>

      {impressoras && impressoras?.length > 0 ? (
        impressoras?.map((impressora: Impressora, index: number) => {
          return (
            <Flex
              gap="24px"
              fontSize="sm"
              borderRadius="0px"
              bg="white"
              py="8px"
              width="fit-content"
              px="24px"
              borderBottom="1px solid #eee"
            >
              <Flex minW="252px" align="center">
                {impressora.nome}
              </Flex>
              <Flex minW="160px" align="center">
                <Box>
                  <Switch
                    mb="0"
                    size="md"
                    id={`impressoras.${index}.imprimirAutomatico`}
                    name={`impressoras.${index}.imprimirAutomatico`}
                  />
                </Box>
              </Flex>
              <Flex minW="160px" align="center">
                <Box>
                  <Switch
                    mb="0"
                    size="md"
                    id={`impressoras.${index}.confirmarImpressao`}
                    name={`impressoras.${index}.confirmarImpressao`}
                  />
                </Box>
              </Flex>
              <Flex minW="90px" align="center">
                <SelectPadrao
                  id={`impressoras.${index}.quantidadeCopias`}
                  name={`impressoras.${index}.quantidadeCopias`}
                  options={numeroCopiasOptions}
                  isSearchable={false}
                  placeholder="Selecione a quantidade de cópias"
                  size="sm"
                />
              </Flex>
              <Flex minW="36px">
                <IconButton
                  aria-label="editar"
                  onClick={() => removeImpressora(index)}
                  colorScheme="red"
                  borderRadius="md"
                  boxSize="32px"
                  minW="36px"
                  sx={{
                    g: {
                      strokeWidth: '1.2px !important',
                    },
                  }}
                  icon={
                    <Icon
                      as={LixeiraIcon}
                      color="white"
                      fontWeight="bold"
                      fontSize="lg"
                      boxSize="18px"
                    />
                  }
                />
              </Flex>
            </Flex>
          );
        })
      ) : (
        <Flex
          gap="24px"
          fontSize="sm"
          borderRadius="0px"
          bg="white"
          py="12px"
          width="calc(100% - 6px)"
          px="24px"
          minH="48px"
        >
          <Flex align="center">Nenhuma impressora adicionada</Flex>
        </Flex>
      )}
    </Flex>
  );
};
