import { useCallback } from 'react';
import {
  Box,
  Icon,
  Flex,
  Table,
  Text,
  Thead,
  Tr,
  Td,
  Th,
  Tbody,
} from '@chakra-ui/react';

import { EditarSituacaoIcon } from 'icons';

type SituacaoResponseProps = {
  id: string;
  nome: string;
  situacaoSistema: null | number;
  padraoSistema?: boolean;
  integracaoSituacaoId: string;
};

export const ListagemSituacao = ({
  situacoes,
  titulo,
  tipo,
  abrirModalAlterarSituacao,
}: {
  situacoes: SituacaoResponseProps[];
  titulo: string;
  tipo: string;
  abrirModalAlterarSituacao?: (situacao: SituacaoResponseProps) => void;
}) => {
  const coresSituacoes: Record<string, string> = {
    PEDIDO: 'violet.500',
    VENDA: 'teal.700',
    CANCELAMENTO: 'red.600',
    DEFAULT: 'gray.500',
  };

  const corSituacaoLista = coresSituacoes[tipo] || coresSituacoes.DEFAULT;

  const handleAlterarSituacao = useCallback(
    (situacao: SituacaoResponseProps) => {
      if (abrirModalAlterarSituacao) {
        abrirModalAlterarSituacao(situacao);
      }
    },
    [abrirModalAlterarSituacao]
  );

  return (
    <Box mt="19px" maxW={['714px, 714px, 714px, full']}>
      <Flex gap="4px">
        <Text fontSize="14px" color="gray.700">
          {titulo}
        </Text>
        <Text color={corSituacaoLista} fontSize="14px" fontWeight="bold">
          {tipo}:
        </Text>
      </Flex>
      <Box
        borderWidth="1px"
        borderRadius="5px"
        borderColor="gray.200"
        bg="gray.50"
      >
        <Box p="24px" mr="5px" borderRadius="5px" bg="gray.50" overflowY="auto">
          <Table variant="simple" size="sm">
            <Thead>
              <Tr
                sx={{
                  '& th': {
                    fontSize: '12px',
                    color: 'gray.500',
                    paddingBottom: '2px',
                    paddingLeft: '0',
                    fontWeight: '500',
                    borderColor: 'gray.200',
                  },
                }}
              >
                <Th>Situação utilizada no site</Th>
                <Th>Status operacional no sistema:</Th>
              </Tr>
            </Thead>
            <Tbody>
              {situacoes.map(
                (situacaoItem: {
                  id: string;
                  nome: string;
                  situacaoSistema: null | number;
                  integracaoSituacaoId: string;
                  padraoSistema?: boolean;
                }) => {
                  return (
                    <Tr
                      sx={{
                        '& td': {
                          fontSize: '14px',
                          borderColor: 'gray.200',
                        },
                      }}
                    >
                      <Td color="black" w="50%">
                        <li>
                          <Text ml="-6px" display="inline">
                            {situacaoItem.nome}
                          </Text>
                        </li>
                      </Td>
                      <Td px="0">
                        <Flex
                          gap="64px"
                          justify="flex-start"
                          align="center"
                          maxW="calc(256px + 64px)"
                          height="32px"
                        >
                          <Text
                            color="white"
                            minW="240px"
                            height="32px"
                            pl="12px"
                            pt="8px"
                            borderRadius="5px"
                            border="1px solid"
                            borderColor="gray.200"
                            bg={corSituacaoLista}
                            fontSize="14px"
                            textTransform="capitalize"
                            userSelect="none"
                          >
                            {(tipo as string)?.toLowerCase() || 'Não vinculado'}
                          </Text>
                          {!situacaoItem?.padraoSistema && (
                            <Icon
                              as={EditarSituacaoIcon}
                              fontSize="16px"
                              cursor="pointer"
                              onClick={() =>
                                handleAlterarSituacao(situacaoItem)
                              }
                            />
                          )}
                        </Flex>
                      </Td>
                    </Tr>
                  );
                }
              )}
            </Tbody>
          </Table>
        </Box>
      </Box>
    </Box>
  );
};
