import React, { ReactNode } from 'react';
import { BoxProps } from '@chakra-ui/react';

import { SimpleCard } from 'components/update/Form/SimpleCard';

interface ContainerProps extends BoxProps {
  children: ReactNode;
}

export function Container({ children, ...rest }: ContainerProps) {
  return (
    <SimpleCard
      position="relative"
      p={0}
      px={{ base: 4, sm: 8, md: 10, lg: 12 }}
      borderTopRadius={0}
      boxShadow="none"
      w="full"
      h="full"
      {...rest}
    >
      {children}
    </SimpleCard>
  );
}
