import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';
import { DadosTrayProps } from 'store/Tray/PainelControle';
import ConstanteRotas, { ConstanteRotasAlternativas } from 'constants/rotas';
import auth from 'modules/auth';
import enumReferenciaServicoStargate from 'constants/enum/referenciaServicoStargate';

import { ModalConfirmarAcao } from 'components/update/Modal/ModalConfirmarAcao';
import { ModalRemoverDadosIntegracao } from 'components/update/Modal/ModalRemoverDadosIntegracao';

export const useTrayData = () => {
  const [trayDateActivated, setTrayDateActivated] = useState<Date>(new Date());
  const [isLoading, setIsLoading] = useState(false);

  const possuiPermissao = auth.possuiServico(
    enumReferenciaServicoStargate.INTEGRACAO_TRAY
  ).permitido;

  const history = useHistory();

  const listItemsInfo = [
    'Para acessar o painel de configurações da integração, clique em: Integrações > Tray',
    'Para reset da configuração ou para excluir esta integração, clique em “cancelar integração” no canto superior direito da página.',
    'Para outras dúvidas ou problemas, acesse nossa central de ajuda ou entre em contato com nossa equipe técnica.',
  ];

  const cancelIntregation = async () => {
    ModalRemoverDadosIntegracao({
      callback: async () => {
        setIsLoading(true);
        const response = await api.post<void, ResponseApi>(
          ConstanteEnderecoWebservice.INTEGRACAO_TRAY_EXCLUIR
        );
        if (response) {
          if (response.avisos) {
            response.avisos.forEach((aviso: string) => toast.warning(aviso));
          }
          if (response.sucesso) {
            history.push(ConstanteRotas.LOJA_APLICATIVOS);
          }
          setIsLoading(false);
        }
        setIsLoading(false);
      },
    });
  };

  const confirmCancellation = () => {
    ModalConfirmarAcao({
      mainText: 'Excluir ou Reiniciar',
      submitButtonText: 'Continuar',
      secondaryText:
        'Todos os dados armazenados e todo o processo de configuração serão excluídos. Essa ação não poderá ser desfeita. Deseja continuar?',
      variant: 'danger',
      callbackSubmit: cancelIntregation,
    });
  };

  const getDataTray = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get<void, ResponseApi<DadosTrayProps>>(
      ConstanteEnderecoWebservice.INTEGRACAO_TRAY_OBTER
    );

    if (response) {
      if (response.avisos) {
        if (possuiPermissao) {
          history.push(ConstanteRotasAlternativas.TRAY_ETAPAS);
        } else {
          history.push(ConstanteRotas.LOJA_APLICATIVOS);
        }
        setIsLoading(false);
      }
      if (response.sucesso) {
        setTrayDateActivated(response.dados.dataAtivacao);
        setIsLoading(false);
      }
    }
  }, [history, possuiPermissao]);

  useEffect(() => {
    getDataTray();
  }, [getDataTray]);

  return {
    trayDateActivated,
    listItemsInfo,
    handleCancel: confirmCancellation,
    isLoading,
  };
};
