import React, { createContext, useContext } from 'react';

import { CategoriaPermissaoInterface } from 'components/Permissoes';

interface FormularioUsuariosContextProps {
  categorias: CategoriaPermissaoInterface[];
  setCategorias?: React.Dispatch<
    React.SetStateAction<CategoriaPermissaoInterface[]>
  >;
}

export const FormularioUsuariosContext = createContext<FormularioUsuariosContextProps>(
  {} as FormularioUsuariosContextProps
);

interface FormularioUsuariosProviderProps {
  children: React.ReactNode;
  categorias: CategoriaPermissaoInterface[];
  setCategorias?: React.Dispatch<
    React.SetStateAction<CategoriaPermissaoInterface[]>
  >;
}

export default function FormularioUsuariosProvider({
  children,
  categorias,
  setCategorias,
}: FormularioUsuariosProviderProps): JSX.Element {
  return (
    <FormularioUsuariosContext.Provider
      value={{
        categorias,
        setCategorias,
      }}
    >
      {children}
    </FormularioUsuariosContext.Provider>
  );
}

export function useFormularioUsuariosContext(): FormularioUsuariosContextProps {
  const context = useContext(FormularioUsuariosContext);

  if (!context)
    throw new Error(
      'useFormularioUsuariosContext must be used within a FormularioUsuariosProvider.'
    );

  return context;
}
