import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

export interface ObterInformacoesFinalizarVendaResponse {
  emitirNFe: boolean;
  emitirNFCe: boolean;
  nfCeExigirCNPJCPF: boolean;
  nfCeValorExigenciaCNPJCPF: number;
  paisId?: number;
}

const obterInformacoesFinalizarVenda = async (): Promise<
  ObterInformacoesFinalizarVendaResponse | undefined
> => {
  const response = await api.get<
    void,
    ResponseApi<ObterInformacoesFinalizarVendaResponse>
  >(ConstanteEnderecoWebservice.LOJA_OBTER_INFORMACOES_FINALIZAR_VENDA);

  if (response) {
    if (response?.avisos) {
      response.avisos.forEach((aviso) => toast.warning(aviso));
    }
    if (response?.sucesso && response?.dados) {
      return response.dados;
    }
  }

  return undefined;
};

export default obterInformacoesFinalizarVenda;
