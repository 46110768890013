const NivelCategoriaProdutoEnum = {
  PRIMEIRO: 1,
  SEGUNDO: 2,
  TERCEIRO: 3,
  QUARTO: 4,

  properties: [
    {
      value: 1,
      backgroundColor: 'var(--sti-ck-colors-gray-50)',
      foregroundColor: 'var(--sti-ck-colors-gray-700)',
      color: 'gray.700',
    },
    {
      value: 2,
      backgroundColor: 'var(--sti-ck-colors-aquamarine-100)',
      foregroundColor: 'var(--sti-ck-colors-teal-700)',
      color: 'teal.700',
    },
    {
      value: 3,
      backgroundColor: 'var(--sti-ck-colors-yellow-50)',
      foregroundColor: 'var(--sti-ck-colors-orange-600)',
      color: 'orange.600',
    },
    {
      value: 4,
      backgroundColor: 'var(--sti-ck-colors-pink-100)',
      foregroundColor: 'var(--sti-ck-colors-pink-600)',
      color: 'pink.600',
    },
  ],
};

export const corCategoriaProdutoEnum = {
  PRIMEIRO: 1,
  SEGUNDO: 2,
  TERCEIRO: 3,
  QUARTO: 4,

  properties: [
    {
      value: 1,
      backgroundColor: 'gray.50',
      foregroundColor: 'gray.700',
      color: 'gray.700',
    },
    {
      value: 2,
      backgroundColor: 'aquamarine.100',
      foregroundColor: 'teal.700',
      color: 'teal.700',
    },
    {
      value: 3,
      backgroundColor: 'yellow.50',
      foregroundColor: 'orange.600',
      color: 'orange.600',
    },
    {
      value: 4,
      backgroundColor: 'pink.50',
      foregroundColor: 'pink.600',
      color: 'pink.600',
    },
  ],
};

export default NivelCategoriaProdutoEnum;

export const NivelCoresTray = {
  PRIMEIRO: 1,
  SEGUNDO: 2,
  TERCEIRO: 3,
  QUARTO: 4,

  properties: [
    {
      value: 1,
      backgroundColor: 'white',
      foregroundColor: 'gray.700',
      color: 'gray.700',
    },
    {
      value: 2,
      backgroundColor: 'aquamarine.50',
      foregroundColor: 'aquamarine.700',
      color: 'aquamarine.700',
    },
    {
      value: 3,
      backgroundColor: 'orange.50',
      foregroundColor: 'orange.600',
      color: 'orange.600',
    },
  ],
};
