import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import { Loja, ProdutosProps } from './types';

export const useTabelaPreco = () => {
  const idRota = useParams<{ id: string }>();
  const idFromRouteParams = idRota.id;

  const getLojas = useCallback(async () => {
    const response = await api.get<void, ResponseApi<Loja[]>>(
      ConstanteEnderecoWebservice.OBTER_LOJAS_TABELA_PRECO,
      {
        params: { tabelaPrecoId: idFromRouteParams || null },
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }

      if (response.sucesso) {
        return response.dados;
      }
    }

    return [];
  }, []);

  const downloadExportedItensInCSVFormat = useCallback((itens) => {
    const dataHora = new Date();
    const dataFormatada = `${dataHora.getDate()}-${
      dataHora.getMonth() + 1
    }-${dataHora.getFullYear()}-0${dataHora.getHours()}-${dataHora.getMinutes()}`;

    const url = window.URL.createObjectURL(new Blob([itens]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${dataFormatada}-tabela-preco.csv`);
    link.click();
  }, []);

  const getProductsToExport = useCallback(
    async (
      shouldExportAll: boolean,
      tabelaPrecoId: string | null,
      tabelaPrecoSimples = false
    ) => {
      const url = tabelaPrecoSimples
        ? ConstanteEnderecoWebservice.TABELA_PRECO_OBTER_PRODUTOS_SIMPLES_PARA_EXPORTAR
        : ConstanteEnderecoWebservice.TABELA_PRECO_OBTER_PRODUTOS_COR_TAMANHO_PARA_EXPORTAR;

      const response = await api.get<void, ResponseApi<string>>(url, {
        params: { tabelaPrecoId: shouldExportAll ? null : tabelaPrecoId },
      });

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response.sucesso) {
          downloadExportedItensInCSVFormat(response.dados);
        }
      }

      return '';
    },
    [downloadExportedItensInCSVFormat]
  );

  const importProductInTabelaPreco = useCallback(
    async (produtos: ProdutosProps, tabelaPrecoSimples?: boolean) => {
      const url = tabelaPrecoSimples
        ? ConstanteEnderecoWebservice.TABELA_PRECO_IMPORTAR_PRODUTOS_LISTAGEM_PRODUTOS_SIMPLES
        : ConstanteEnderecoWebservice.TABELA_PRECO_IMPORTAR_PRODUTOS_LISTAGEM_PRODUTOS_COR_TAMANHO;
      const response = await api.post<void, ResponseApi<string>>(
        `${url}/${idRota.id}`,
        {
          produtos,
        }
      );
      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso: string) => toast.warning(aviso));
        }

        if (response.sucesso) {
          window.location.reload();
        }
      }
    },
    [idRota.id]
  );

  const readCSV = useCallback(
    async (file: File, tabelaPrecoSimples?: boolean) => {
      if (file.size === 0) {
        toast.warning('Não é possível importar um arquivo vazio');
        return;
      }
      const reader = new FileReader();

      reader.onload = () => {
        const fileContent = reader.result;

        if (fileContent) {
          const productsFromCSV = fileContent.toString().split('\r\n');
          const listProductsFromCSV = (productsFromCSV || []).map(
            (lineItem, index) => {
              let codigo = '';
              let precoVendaNovo = '';

              if (tabelaPrecoSimples) {
                [codigo, , , precoVendaNovo] = lineItem.split(';');
              } else {
                [codigo, , , , , precoVendaNovo] = lineItem.split(';');
              }

              if (index === 0 || !codigo) {
                return null;
              }

              if (!precoVendaNovo) {
                return null;
              }

              const precoVenda = Number(precoVendaNovo.replaceAll(',', '.'));
              const letterToReplace = tabelaPrecoSimples ? 'I' : 'C';
              const produto = {
                codigo: codigo.replace(letterToReplace, ''),
                precoVenda,
              };

              if (precoVenda > 0) {
                return produto;
              }

              return null;
            }
          );

          const validProductsList = listProductsFromCSV.filter(
            (item) => item !== null
          ) as ProdutosProps;

          const hasIncorrectItems = listProductsFromCSV
            .slice(1, listProductsFromCSV.length - 1)
            .some((item) => item === null);

          if (hasIncorrectItems) {
            toast.warning('Não foi possível identificar alguns produtos');
          }

          importProductInTabelaPreco(validProductsList, tabelaPrecoSimples);
        }
        return [];
      };

      reader.readAsText(file);
    },
    [importProductInTabelaPreco]
  );

  const validateCSV = useCallback((file: File) => {
    if (file.type !== 'text/csv') {
      toast.warning('O arquivo está em um formato inválido.');
      return false;
    }
    return true;
  }, []);

  return {
    getLojas,
    getProductsToExport,
    validateCSV,
    readCSV,
    idFromRouteParams,
  };
};
