import { Box, Divider, Flex, Icon, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';

import { LocaisLoja, TamanhoTodasLojas } from '../../types';

type DetalhesEstoqueGeralMobileProps = {
  itemTamanho: TamanhoTodasLojas;
};

export const DetalhesEstoqueGeralMobile = ({
  itemTamanho,
}: DetalhesEstoqueGeralMobileProps) => {
  const [renderizarLocalEstoque, setRenderizarLocalEstoque] = useState(false);

  function renderizarComponente() {
    setRenderizarLocalEstoque(!renderizarLocalEstoque);
  }

  const somandoValoresQuantidade = itemTamanho.locaisEstoque.reduce(
    (acc, curr) => acc + curr.quantidade,
    0
  );

  const filtrandoValores = itemTamanho.locaisLoja.reduce((acc, curr) => {
    const filtroExiste = acc.findIndex((valor) => valor.loja === curr.loja);

    if (filtroExiste > -1) {
      acc.splice(filtroExiste, 1, {
        ...acc[filtroExiste],
        loja: curr.loja,
        saldoLoja: acc[filtroExiste].saldoLoja + curr.saldoLoja,
      });
    } else {
      acc.push({
        saldoLoja: curr.saldoLoja,
        loja: curr.loja,
      });
    }

    return acc;
  }, [] as LocaisLoja[]);

  return (
    <Box>
      <Divider />
      <Flex padding="10px" justifyContent="space-between">
        <Text fontSize="16px">{itemTamanho.tamanho}</Text>

        <Text
          color={somandoValoresQuantidade === 0 ? 'red.500' : 'gray.500'}
          fontSize="14px"
        >
          Quantidade: {somandoValoresQuantidade}
          <Icon
            fontSize="12px"
            color="black"
            mt="-3px"
            onClick={() => renderizarComponente()}
            as={renderizarLocalEstoque ? FiArrowUp : FiArrowDown}
          />
        </Text>
      </Flex>
      {renderizarLocalEstoque && (
        <Flex padding="5px" mt="-10px" mb="5px">
          <Box padding="5px">
            <Text>
              {filtrandoValores.map((valorTodasLojas) => (
                <Flex>
                  <Text>
                    {valorTodasLojas.saldoLoja}
                    {valorTodasLojas.saldoLoja < 0 ? '' : ' -'}
                  </Text>
                  <Text ml="5px">{valorTodasLojas.loja}</Text>
                </Flex>
              ))}
            </Text>
          </Box>
        </Flex>
      )}
      <Divider />
    </Box>
  );
};
