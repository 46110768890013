import React from 'react';
import { Text, Flex, Stack, Button, useMediaQuery } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/* import { Container, Text404, Text, HomepageText } from './styles'; */

import Layout from '../../components/Autenticacao/Layout/index';

const PaginaNaoExiste = () => {
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Layout minW="100%">
      <Flex
        w="100%"
        minW={['280px', '280px', '424px']}
        direction="column"
        justifyContent="space-between"
        pt={3}
        pb={3}
        mt="5"
      >
        <Stack mb="6">
          <Text
            color="white"
            fontSize={['6xl', '6xl', '8xl']}
            fontWeight="black"
          >
            Oooops!
          </Text>
          <Flex direction="column">
            {isLargerThan900 ? (
              <Text
                color="purple.50"
                fontSize="md"
                w="450px"
                lineHeight="taller"
                fontWeight="normal"
              >
                A página que você aterrissou não foi encontrada. <br />
                Se você digitou o endereço corretamente, a página pode ter{' '}
                <br />
                sido movida ou renomeada. Sentimos muito.
              </Text>
            ) : (
              <Text
                color="purple.50"
                fontSize="md"
                lineHeight="taller"
                fontWeight="normal"
              >
                A página que você aterrissou não foi <br />
                encontrada. Se você digitou o endereço <br />
                corretamente, a página pode ter <br />
                sido movida ou renomeada. <br />
                Sentimos muito.
              </Text>
            )}
          </Flex>
        </Stack>
        <Stack mt="4">
          <Button
            variant="unstyled"
            w="64"
            bg="secondary.400"
            color="primary.800"
            fontSize="sm"
            fontWeight="normal"
            onClick={() => history.push('/')}
          >
            {t('Voltar para tela inicial')}
          </Button>
        </Stack>
      </Flex>
    </Layout>
  );
};

export default PaginaNaoExiste;
