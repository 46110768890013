import {
  ForwardRefRenderFunction,
  forwardRef,
  useState,
  useCallback,
  useImperativeHandle,
} from 'react';
import { toast } from 'react-toastify';
import { Container, LayoutProps, Flex, Text } from '@chakra-ui/react';

import api, { ResponseApi } from 'services/api';
import ContatoEmpresa from 'constants/contato';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { formatDateHourMinute } from 'helpers/format/formatStringDate';

import { CabecalhoCupomVoucher } from './divisoes/cabecalho';
import { ClienteCupomVoucher } from './divisoes/cliente';
import { InformacoesVoucher } from './divisoes/informacoesVoucher';
import { FooterCupomVoucher } from './divisoes/footer';

export type ImpressaoVouhcerRefElement = {
  obterInformacoesImpressao(): Promise<void>;
};

interface ImpressaoVouhcer {
  maxWidth?: LayoutProps['maxW'];
  containerRef?: React.RefObject<HTMLDivElement>;
  voucherId?: string;
}

type InformacoesVoucherProps = {
  cliente: string;
  saldo: number;
  identificador: string;
  lojaFantasia: string;
  dataHoraCadastro: Date;
};

const Impressao: ForwardRefRenderFunction<
  ImpressaoVouhcerRefElement,
  ImpressaoVouhcer
> = ({ containerRef, maxWidth, voucherId }: ImpressaoVouhcer, ref) => {
  const [
    informacoesVoucher,
    setInformacoesVoucher,
  ] = useState<InformacoesVoucherProps>({} as InformacoesVoucherProps);

  const obterInformacoesImpressao = useCallback(async () => {
    const response = await api.get<void, ResponseApi<InformacoesVoucherProps>>(
      ConstanteEnderecoWebservice.VALE_OBTER_PARA_COMPARTILAHR,
      {
        params: { operacaoId: voucherId },
      }
    );

    if (response.avisos) {
      response.avisos.forEach((avisos) => toast.warn(avisos));
    }

    if (response.sucesso && response.dados) {
      setInformacoesVoucher(response.dados);
    }
  }, [voucherId]);

  useImperativeHandle(ref, () => ({
    obterInformacoesImpressao,
  }));

  return (
    <Container
      sx={{
        '& div': {
          color: 'black',
          fontWeight: '900',
          fontSize: '10px',
          fontFamily: 'arial',
        },
        '& div, & hr': {
          opacity: 'unset',
          borderColor: 'black',
        },
      }}
      minW="100%"
      ref={containerRef}
    >
      <Flex
        minW="58mm"
        ml="-10px"
        maxW={maxWidth}
        direction="column"
        maxH="100vh"
        h="full"
        pt="3"
        mx="1"
      >
        <CabecalhoCupomVoucher nomeFantasia={informacoesVoucher.lojaFantasia} />
        <ClienteCupomVoucher nomeCliente={informacoesVoucher.cliente} />
        <InformacoesVoucher
          dataHoraCadastro={informacoesVoucher.dataHoraCadastro}
          identificador={informacoesVoucher.identificador}
          valorVoucher={informacoesVoucher.saldo}
        />
        <FooterCupomVoucher rodaPe={ContatoEmpresa.CONTATO} />

        <Text textAlign="right">
          Impresso em: {formatDateHourMinute(new Date())}
        </Text>
      </Flex>
    </Container>
  );
};

export const ImpressaoVoucherDeTroca = forwardRef(Impressao);
