import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Flex,
  Button,
  Grid,
  HStack,
  Text,
  Divider,
  SimpleGrid,
  Icon,
  useBreakpointValue,
  Tag,
  Stack,
  ModalCloseButton,
  useMediaQuery,
} from '@chakra-ui/react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';

import OperacaoIntermediadorEnum from 'constants/enum/fiscal/operacaoIntermediador';
import ModelosFiscaisEnum from 'constants/enum/fiscal/modelosFiscais';
import AmbienteFiscalEnum, {
  AmbienteFiscal,
} from 'constants/enum/fiscal/ambienteFiscal';
import StatusFiscaisEnum from 'constants/enum/fiscal/statusFiscal';
import TipoCertificadoEnum from 'constants/enum/fiscal/tipoCertificado';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import getOptionsByEnum from 'helpers/format/getOptionsByEnum';
import api, { ResponseApi } from 'services/api';
import { useSignalRContext } from 'store/SignalR';

import { NFCeIcon } from 'icons';
import Input from 'components/PDV/Input';
import InputCpfCnpj from 'components/PDV/InputCpfCnpj';
import Select from 'components/PDV/Select/SelectPadrao';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import ModalTransmissaoNota from 'pages/NotasFiscais/ModalTransmissaoNota';
import { ModalRejeicaoNotaFiscal } from 'components/Modal/ModalRejeicaoNotaFiscal';
import { ModalAtencao } from 'components/Modal/ModalAtencao';

import { BoxRejeicao, RejeicaoInterface } from '../BoxRejeicao';

import { useForm, yupResolver } from './validationForm';

interface NFCeHeaderProps {
  ambienteFiscal: AmbienteFiscal | undefined;
  isPdv?: boolean;
}

export const NFCeHeader = ({ ambienteFiscal, isPdv }: NFCeHeaderProps) => {
  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');
  return (
    <Flex
      px={{ base: 6, md: 10 }}
      py={2}
      flexDirection={{ base: 'column', md: 'row' }}
      h="96px"
      alignItems={{ base: 'flex-start', md: 'center' }}
      justifyContent={{ base: 'center', md: 'flex-start' }}
      overflow="hidden"
    >
      <Box>
        <Icon as={NFCeIcon} w="150px" h="auto" maxH="50px" />
        {(isMobile || isSmallerThan900) && isPdv && <ModalCloseButton />}
      </Box>
      {ambienteFiscal && ambienteFiscal === AmbienteFiscalEnum.Homologacao && (
        <Tag
          size="md"
          colorScheme="red"
          ml={{ base: 0, md: 6 }}
          mt={{ base: 2, md: 0 }}
        >
          HOMOLOGAÇÃO - SEM VALOR FISCAL
        </Tag>
      )}
    </Flex>
  );
};

export type EmitirNFCeData = {
  id?: string;
  cpfCnpj?: string;
  operacaoComIntermediador?: number;
  cnpjIntermediador?: string;
  identificacaoNoIntermediador?: string;
  dataSaida?: string;
};

interface EmitirNFCeProps {
  defaultValues?: EmitirNFCeData;
  onSubmit: SubmitHandler<EmitirNFCeData>;
  children?: ReactNode;
  rejeicaoNotaFiscal?: RejeicaoInterface;
  isLoading?: boolean;
  cpfCnpjIsRequired: boolean;
  hasNfeAutorizada?: boolean;
  statusNfce?: number;
  isDisabledSubmit?: boolean;
  ambienteFiscal: AmbienteFiscal | undefined;
  isPdv?: boolean;
  setRejeicaoNotaFiscal?: React.Dispatch<
    React.SetStateAction<RejeicaoInterface | undefined>
  >;
}

export function EmitirNFCe({
  defaultValues,
  cpfCnpjIsRequired,
  onSubmit,
  children,
  rejeicaoNotaFiscal,
  setRejeicaoNotaFiscal,
  isLoading,
  statusNfce,
  hasNfeAutorizada,
  ambienteFiscal,
  isDisabledSubmit = false,
  isPdv = false,
}: EmitirNFCeProps) {
  const [textStatusAlteracao, setTextStatusAlteracao] = useState(
    'Iniciando alteração de status da nota fiscal...'
  );
  const [titleModalTransmitirNota, setTitleModalTransmitirNota] = useState(
    'Alteração de status da nota fiscal'
  );
  const [showModalTransmissao, setShowModalTransmissao] = useState(false);
  const [isLoadingStatusChange, setIsLoadingChangeNfStatus] = useState(false);

  const { hubConnection, joinGroup } = useSignalRContext();

  const isWideVersion = useBreakpointValue({ base: false, md: true });

  const statusRetornoIndisponivel =
    statusNfce === StatusFiscaisEnum.RetornoIndisponivel;

  const formMethods = useForm({
    resolver: yupResolver(cpfCnpjIsRequired),
    defaultValues: {
      cpfCnpj: '',
      operacaoComIntermediador: OperacaoIntermediadorEnum.SemIntermediador,
      ...(defaultValues || {}),
    },
  });

  const operacaoComIntermediador = formMethods.watch(
    'operacaoComIntermediador',
    OperacaoIntermediadorEnum.SemIntermediador
  );

  const handleSubmit = formMethods.handleSubmit(onSubmit);

  const handleEmitirNFCe = async () => {
    if (!isDisabledSubmit) {
      handleSubmit();
    }
  };

  const latestProps = useRef({ setFocus: formMethods.setFocus });
  useEffect(() => {
    latestProps.current = { setFocus: formMethods.setFocus };
  });

  const reloadPage = useCallback(() => {
    window.location.reload();
  }, []);

  const tratarAviso = useCallback(
    (aviso: string) => {
      if (setRejeicaoNotaFiscal) {
        try {
          const newRejeicao = JSON.parse(aviso) as RejeicaoInterface;
          setRejeicaoNotaFiscal(newRejeicao);
        } catch {
          setRejeicaoNotaFiscal({ Mensagem: aviso });
        }
      }
    },
    [setRejeicaoNotaFiscal]
  );

  const alterarStatusTransmissao = useCallback((message: string) => {
    if (message) {
      setTextStatusAlteracao(message);
      setShowModalTransmissao(true);
      setTitleModalTransmitirNota('Transmitindo nota fiscal');
    }
  }, []);

  const tratarRejeicaoNfAlterarStatus = useCallback(
    (avisos: string[], statusNfAlterado: boolean, sucesso: boolean) => {
      if (!statusNfAlterado) {
        avisos.forEach((aviso: string) => toast.warning(aviso));
      } else if (!sucesso) {
        setShowModalTransmissao(false);
        tratarAviso(avisos[0]);
      }
    },
    [tratarAviso]
  );

  const transmissaoFinalizada = useCallback((timeout: number) => {
    window.clearTimeout(timeout);
    setShowModalTransmissao(false);
    toast.success('A nota fiscal foi transmitida com sucesso.');
  }, []);

  const rejeicaoTransmissaoA3 = useCallback(
    (message: string, timeout: number) => {
      window.clearTimeout(timeout);
      setShowModalTransmissao(false);
      tratarAviso(message);
    },
    [tratarAviso]
  );

  const handleNfAlterarStatusRetorno = useCallback(
    async (id: string) => {
      setIsLoadingChangeNfStatus(true);
      let statusNfAlterado = false;

      joinGroup(`${id}_transmitindo-nota`);

      hubConnection.on('alterar-status-transmissao', (message: string) => {
        if (message) {
          alterarStatusTransmissao(message);
          statusNfAlterado = true;
        }
      });

      const response = await api.put<void, ResponseApi>(
        ConstanteEnderecoWebservice.NOTA_FISCAL_ALTERAR_STATUS_RETORNO,
        null,
        { params: { id } }
      );

      if (response) {
        if (response?.avisos) {
          tratarRejeicaoNfAlterarStatus(
            response.avisos,
            statusNfAlterado,
            response.sucesso
          );
        }

        if (response?.sucesso) {
          if (
            statusNfAlterado &&
            response.dados &&
            response.dados === Number(TipoCertificadoEnum.A3)
          ) {
            const timeout = window.setTimeout(
              () => {
                setShowModalTransmissao(false);
                ModalAtencao({
                  title: 'A comunicação com o certificado digital A3 falhou',
                  text:
                    'Verifique se o certificado digital está conectado no computador e se o aplicativo Módulo desktop está sendo executado.',
                });

                reloadPage();
              },
              1000 * 60 // 1 minuto
            );

            hubConnection.off('sucesso-transmissao-a3');
            hubConnection.on('sucesso-transmissao-a3', () =>
              transmissaoFinalizada(timeout)
            );

            hubConnection.off('rejeicao-transmissao-a3');
            hubConnection.on('rejeicao-transmissao-a3', (message: string) =>
              rejeicaoTransmissaoA3(message, timeout)
            );
          }
          if (statusNfAlterado) {
            setShowModalTransmissao(false);
            toast.success('A nota fiscal foi transmitida com sucesso.');
          } else {
            toast.success('O status da nota fiscal foi alterada com sucesso.');
          }
          reloadPage();
        }
      }
      setShowModalTransmissao(false);
      setIsLoadingChangeNfStatus(false);
    },
    [
      reloadPage,
      hubConnection,
      joinGroup,
      alterarStatusTransmissao,
      tratarRejeicaoNfAlterarStatus,
      transmissaoFinalizada,
      rejeicaoTransmissaoA3,
    ]
  );

  useEffect(() => {
    if (!hasNfeAutorizada) {
      if (isWideVersion) {
        latestProps.current.setFocus('cpfCnpj');
      }
    }
  }, [isWideVersion, hasNfeAutorizada]);

  if (hasNfeAutorizada) {
    return (
      <>
        {isLoading && <LoadingPadrao />}

        <Box w="full">
          <NFCeHeader ambienteFiscal={ambienteFiscal} isPdv={isPdv} />
          <Box px={{ base: 6, md: 10 }}>
            <Divider orientation="horizontal" />
          </Box>
          <Box my={{ base: 6, md: 8 }} mx={{ base: 6, md: 10 }}>
            <Text fontSize="lg" fontWeight="bold">
              A venda já possui uma NF-e Autorizada.
            </Text>
          </Box>
          <Box px={{ base: 6, md: 10 }}>
            <Divider orientation="horizontal" />
          </Box>
          <HStack
            justifyContent="center"
            spacing={{ base: 3, md: 6 }}
            mt={{ base: 6, md: 8 }}
            my={{ base: 6, md: 8 }}
          >
            {children}
          </HStack>
        </Box>
      </>
    );
  }

  return (
    <>
      {(isLoading || isLoadingStatusChange) && <LoadingPadrao />}

      <Grid
        templateRows={`96px ${rejeicaoNotaFiscal ? '96px' : ''} 96px 1fr`}
        w="full"
      >
        <NFCeHeader ambienteFiscal={ambienteFiscal} isPdv={isPdv} />
        {rejeicaoNotaFiscal && (
          <BoxRejeicao
            rejeicao={rejeicaoNotaFiscal}
            modeloFiscal={ModelosFiscaisEnum.NFCe}
          />
        )}
        <FormProvider {...formMethods}>
          <HStack
            spacing={{ base: 2, md: 6 }}
            bg="gray.100"
            px={{ base: 6, md: 12 }}
          >
            {isWideVersion && (
              <Text
                as="label"
                htmlFor="cpfCnpj"
                mb="0"
                color="gray.500"
                fontSize="md"
                fontWeight="semibold"
              >
                CPF / CNPJ{cpfCnpjIsRequired && ' *'}
              </Text>
            )}
            <InputCpfCnpj
              id="cpfCnpj"
              name="cpfCnpj"
              label={isWideVersion ? undefined : 'CPF / CNPJ'}
              w="full"
              maxW="200px"
              placeholder="000.000.000-00"
              isRequired={cpfCnpjIsRequired}
            />
            <Button
              variant="link"
              colorScheme="primary"
              onClick={() => {
                formMethods.setValue('cpfCnpj', '');
              }}
              p={2}
              py={1}
              mt={isWideVersion ? 0 : '14px !important'}
            >
              Não informar
            </Button>
          </HStack>
          <Box
            px={{ base: 6, md: 10 }}
            py={{ base: 6, md: 8 }}
            pt={{ base: 6, md: 8 }}
          >
            <SimpleGrid columns={12} spacing={{ base: 6, md: 8 }} w="full">
              <Select
                id="operacaoComIntermediador"
                name="operacaoComIntermediador"
                label="Intermediador"
                required
                isSearchable={false}
                options={getOptionsByEnum(OperacaoIntermediadorEnum)}
                colSpan={{ base: 12, md: 7, lg: 6, xl: 5 }}
              />
              {operacaoComIntermediador ===
                OperacaoIntermediadorEnum.OperacaoEmSitePlataformaTerceiros && (
                <>
                  <InputCpfCnpj
                    asCnpj
                    id="cnpjIntermediador"
                    name="cnpjIntermediador"
                    label="CNPJ intermediador"
                    isRequired
                    placeholder="00.000.000-0000/00"
                    rowStart={2}
                    colSpan={{ base: 12, md: 7, lg: 4, xl: 3 }}
                  />
                  <Input
                    id="identificacaoNoIntermediador"
                    name="identificacaoNoIntermediador"
                    label="Identificação no intermediador"
                    maxLength={60}
                    isRequired
                    colSpan={{ base: 12, lg: 8, xl: 9 }}
                  />
                </>
              )}
            </SimpleGrid>
            <Divider orientation="horizontal" mt={{ base: 6, md: 8 }} />
            <Stack
              direction={{ base: 'column', sm: 'row' }}
              justifyContent="center"
              spacing={{ base: 3, md: 6 }}
              mt={{ base: 6, md: 8 }}
              my={{ base: 6, md: 8 }}
            >
              {children}
              {!statusRetornoIndisponivel ? (
                <Button
                  size="sm"
                  w="full"
                  minW="104px"
                  maxW={{ base: 'full', sm: '120px' }}
                  colorScheme="secondary"
                  onClick={handleEmitirNFCe}
                  isDisabled={isDisabledSubmit}
                >
                  Emitir NFC-e
                </Button>
              ) : (
                <Button
                  size="sm"
                  colorScheme="primary"
                  padding="12px"
                  onClick={() =>
                    handleNfAlterarStatusRetorno(defaultValues?.id || '')
                  }
                >
                  Alterar status de retorno
                </Button>
              )}
            </Stack>
            <ModalTransmissaoNota
              show={showModalTransmissao}
              message={textStatusAlteracao}
              title={titleModalTransmitirNota}
            />
          </Box>
        </FormProvider>
      </Grid>
    </>
  );
}
