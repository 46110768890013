import { useMultiStyleConfig } from '@chakra-ui/react';

import InputDate, { InputDateProps } from 'components/PDV/InputDate';
import CampoPrototipo, {
  CampoPrototipoProps,
} from 'components/PDV/Geral/CampoPrototipo';

export type DateInputProps = Omit<
  InputDateProps,
  'ref' | 'value' | 'onChange' | 'type'
> &
  CampoPrototipoProps & {
    onValueChange?: (value: Date) => void;
  };

export function DateInput({
  id,
  name,
  label,
  helperText,
  colSpan,
  colStart,
  colEnd,
  rowStart,
  rowEnd,
  rowSpan,
  isRequired,
  isDisabled,
  variant,
  size,
  colorScheme,
  actionLinkText,
  actionLinkOnClick,
  onValueChange,
  ...rest
}: DateInputProps) {
  const {
    field: { h: fieldH },
  } = useMultiStyleConfig('Input', { variant, size, colorScheme });

  return (
    <CampoPrototipo
      name={name}
      isRequired={isRequired}
      isDisabled={isDisabled}
      helperText={helperText}
      id={id}
      label={label}
      rowSpan={rowSpan}
      rowStart={rowStart}
      rowEnd={rowEnd}
      colSpan={colSpan}
      colStart={colStart}
      colEnd={colEnd}
      actionLinkText={actionLinkText}
      actionLinkOnClick={actionLinkOnClick}
      display="flex"
      flexDirection="column"
      h="full"
      sx={{ '& .chakra-input__group': { h: 'full' } }}
    >
      {(_, { value, onChange, ...fieldRest }) => (
        <InputDate
          placeholder="00/00/0000"
          value={value}
          onChange={(newValue) => {
            onChange(newValue);
            if (onValueChange) onValueChange(newValue);
          }}
          lineHeight="none"
          variant={variant}
          size={size}
          colorScheme={colorScheme}
          h={fieldH as any}
          isDisabled={isDisabled}
          {...fieldRest}
          {...rest}
        />
      )}
    </CampoPrototipo>
  );
}
