import React from 'react';
import { Route } from 'react-router-dom';

import { MetasComissoesRelatorioVendasProvider } from 'store/MetasComissoes/MetasComissoesRelatorioVendas';

import LayoutGuard from './LayoutGuard';
import { ConstanteRotasAlternativas } from '../constants/rotas';
import { RelatorioVendasMetasComissoes } from '../pages/Vendas/MetasComissoes/RelatorioVendas';

export const RelatorioVendasMetasComissoesRoutes = [
  <Route
    path={ConstanteRotasAlternativas.RELATORIO_VENDAS_METAS_COMISSOES}
    key="relatorio-vendas"
  >
    <MetasComissoesRelatorioVendasProvider>
      <LayoutGuard
        key="relatorio-vendas"
        component={RelatorioVendasMetasComissoes}
        path={ConstanteRotasAlternativas.RELATORIO_VENDAS_METAS_COMISSOES}
        exact
        meta={{ auth: true }}
      />
    </MetasComissoesRelatorioVendasProvider>
  </Route>,
];
