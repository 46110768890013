import { useCallback, useEffect, useState } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  ModalFooter,
  ModalHeader,
  useMediaQuery,
  Divider,
  VStack,
  HStack,
  Icon,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';
import { FiCrop, FiXCircle } from 'react-icons/fi';

import { CameraFotoIcon } from 'icons';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { ButtonDefault } from 'components/Button/ButtonChakra';
import ImageUploader from 'components/ImagePicker/ImageUploader';
import Select from 'components/PDV/Select/SelectPadrao';
import { getVariacoesCores } from 'pages/Produtos/Formulario/FunctionsCadastroProduto/ObterCoresCadastradas';

import { ModalTirarFoto } from '../ModalTirarFoto';
import { ModalRedimensionarImagem } from '../ModalRedimensionarImagem';

type ListImagensProps = {
  linkImagem: string;
  isForaPadrao: boolean;
  tamanhoImagem?: { widthImage: number; heightImage: number };
  isImagemRedimensionada?: boolean;
};

type Variacao = {
  value: string;
  produtoCorId: string;
  label: string;
  padraoSistema: boolean;
};

type FormData = {
  variacao: Variacao;
};

type ModalAdicionarImagemResolveProps = {
  produtoCorId: string;
  listImagens: {
    id: string;
    idVariacao: string;
    imagem: string;
    produtoCorId: string;
    sequenciaOrdenacao: number;
    notDrag: boolean;
    principal: boolean;
  }[];
};

type ModalAdicionarImagemProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalAdicionarImagemResolveProps> & {
    valueVariacao: string;
    idProduto: string;
    listIdVariacao: string[];
  };

export const ModalAdicionarImagem = create<
  ModalAdicionarImagemProps,
  ModalAdicionarImagemResolveProps
>(
  ({
    onResolve,
    onReject,
    valueVariacao,
    idProduto,
    listIdVariacao,
    children,

    ...rest
  }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [listVariacoes, setListVariacoes] = useState<Variacao[]>([]);
    const [listImagem, setListImagem] = useState<ListImagensProps[]>([]);
    const [onMouseOver, setOnMouseOver] = useState(-1);

    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
    const [isLargerThan880] = useMediaQuery('(min-height: 880px)');

    const formMethods = useForm<FormData>();

    const { handleSubmit: onSubmit, setValue, watch } = formMethods;

    const variacaoWatch = watch('variacao');

    const imagensForaPadrao = listImagem.filter(
      (imagemItem) => imagemItem.isForaPadrao
    ).length;

    const amountImage = listImagem.length;

    const quantidadeTotalImagens = listIdVariacao.filter(
      (item) => item === (variacaoWatch?.produtoCorId || '')
    ).length;

    const handleSalvarImagem = useCallback(
      (value: ListImagensProps) => {
        setListImagem((prev) => {
          if (prev.length + quantidadeTotalImagens > 14) {
            return [...prev];
          }

          return [...prev, value];
        });
      },
      [quantidadeTotalImagens]
    );

    const handleRemoverImagem = (index: number) => {
      const newImagem = [...listImagem];

      newImagem.splice(index, 1);

      setListImagem(newImagem);
    };

    const handleRedimensionarImagem = async (index: number) => {
      const imagemItem = listImagem[index];
      const linkImage = imagemItem.linkImagem;
      const response = await ModalRedimensionarImagem({
        valueImage: linkImage,
        widthImage: imagemItem?.tamanhoImagem?.widthImage,
        heightImage: imagemItem?.tamanhoImagem?.heightImage,
        isImagemRedimensionada: imagemItem?.isImagemRedimensionada,
      });

      setListImagem((prev) =>
        prev.map((itemPrev, prevIndex) => ({
          ...itemPrev,
          isImagemRedimensionada:
            index === prevIndex ? true : itemPrev.isImagemRedimensionada,
          linkImagem:
            prevIndex === index
              ? response.imagemRedimensionada
              : itemPrev.linkImagem,
          isForaPadrao: prevIndex === index ? false : itemPrev.isForaPadrao,
        }))
      );
    };

    const getCores = useCallback(async () => {
      const response = await getVariacoesCores(idProduto, true);
      const listCores =
        response.length > 1
          ? response.filter(
              (itemCores) => itemCores.cor.padraoSistema === false
            )
          : response;
      const newCores = listCores.map((itemCores) => ({
        value: itemCores.cor.id,
        produtoCorId: itemCores.id,
        label: itemCores.cor.descricao,
        padraoSistema: itemCores.cor.padraoSistema,
      }));
      setListVariacoes(newCores);
      setValue(
        'variacao',
        newCores.find((item) => item.value === valueVariacao) ||
          ({} as Variacao)
      );
    }, [idProduto, setValue, valueVariacao]);

    const handleTirarFoto = async () => {
      const response = await ModalTirarFoto();

      handleSalvarImagem({
        isForaPadrao: response.isForaPadrao,
        linkImagem: response.linkImage,
      });
    };

    const handleSubmit = onSubmit((data) => {
      setIsLoading(true);
      const newValue = data.variacao as Variacao;
      const listImagensSalvas = {
        listImagens: listImagem
          .filter((imageItem) => imageItem.isForaPadrao === false)
          .map((imageItem, index) => ({
            idVariacao: newValue.produtoCorId as string,
            produtoCorId: '',
            principal: false,
            imagem: imageItem.linkImagem,
            notDrag: false,
            sequenciaOrdenacao: index + 1,
          })),
        produtoCorId: newValue.value as string,
      } as ModalAdicionarImagemResolveProps;
      onResolve(listImagensSalvas);

      setIsLoading(false);
      onClose();
    });

    useEffect(() => {
      getCores();
    }, [getCores]);

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? '5xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
      >
        <ModalContent
          bg="gray.50"
          overflowY="auto"
          borderRadius={isLargerThan900 ? 'md' : '0'}
          marginBottom={isLargerThan900 ? '3.75rem' : '0'}
          marginTop={isLargerThan900 ? '3.75rem' : '0'}
          w={isLargerThan900 ? '960px' : 'full'}
          h={isLargerThan900 ? '560px' : 'full'}
        >
          <ModalHeader pt="16px" pb="0" pl="0" pr="0" color="primary.50">
            <Flex px="24px" justifyContent="space-between">
              <Text fontSize="16px">Adicionar imagem</Text>
              <HStack cursor="pointer" onClick={() => handleTirarFoto()}>
                <Icon as={CameraFotoIcon} />
                <Text fontSize="16px">Tirar foto</Text>
              </HStack>
            </Flex>

            <Divider color="gray.200" mt="16px" />
          </ModalHeader>

          <FormProvider {...formMethods}>
            {isLoading && <LoadingPadrao />}
            <ModalBody
              h={['undefined', 'undefined', '400px']}
              pl={['0', '0', '24px']}
              pr="0"
              py="0"
            >
              <Flex
                direction={['column', 'column', 'row']}
                w="full"
                h={['', '', 'full']}
              >
                <VStack
                  borderRightWidth={['undefined', 'undefined', '1px']}
                  borderColor="gray.200"
                  w={['full', 'full', '284px']}
                  pr="24px"
                  pl={['24px', '24px', '0', 'undefined']}
                  h="full"
                  pb={['20px', '20px', '0']}
                  alignItems={['left', 'left', 'undefined']}
                  pt={['20px', '20px', '40px']}
                  spacing={['15px', '20px', '25px']}
                >
                  <Text>→ Utilize imagens com extensão JPG, JPEG ou PNG</Text>
                  <Text>→ Tamanho máximo do arquivo de 350 kb</Text>
                  <Text>→ Resolução máxima de 1200 x 1200 px</Text>
                  {listVariacoes.every(
                    (itemCor) => itemCor.padraoSistema === false
                  ) && (
                    <Select
                      name="variacao"
                      id="variacao"
                      label="Variação selecionada"
                      options={listVariacoes}
                      asControlledByObject
                    />
                  )}
                </VStack>
                <Flex
                  pt={['20px', '20px', '40px']}
                  pb={['20px', '20px', 'undefined']}
                  pl="24px"
                  pr="10px"
                  bg="gray.100"
                  h="full"
                  w="full"
                >
                  {listImagem.length === 0 ? (
                    <ImageUploader
                      borderWidth="1px"
                      borderStyle="dashed"
                      borderColor="violet.500"
                      mr="6px"
                      handleSalvarImagem={handleSalvarImagem}
                      bg="white"
                      borderRadius="2px"
                      boxSize={['300px', '336px', '336px']}
                      cursor="pointer"
                      justifyContent="center"
                      alignItems="center"
                      color="gray.700"
                      sizeIcon="30px"
                    />
                  ) : (
                    <Flex
                      sx={{
                        '&::-webkit-scrollbar-track': {
                          background: 'gray.100',
                        },
                      }}
                      overflowX="hidden"
                      overflowY="auto"
                      w="full"
                      h="auto"
                      pb={['0', '0', '10px']}
                      pt="2px"
                      flexWrap="wrap"
                    >
                      {listImagem.map((itemImage, index) => (
                        <Flex
                          borderWidth={itemImage.isForaPadrao ? '1px' : '0'}
                          borderColor="red.500"
                          mb="10px"
                          onMouseOverCapture={() => setOnMouseOver(index)}
                          onMouseLeave={() => setOnMouseOver(-1)}
                          mr="8px"
                          w="116px"
                          boxShadow="0px 2px 6px #00000029"
                          h="116px"
                          borderRadius="5px"
                          backgroundRepeat="no-repeat"
                          backgroundSize="contain"
                          backgroundPosition="center"
                          _hover={{
                            boxShadow: 'hover',
                          }}
                          style={{
                            backgroundImage:
                              onMouseOver === index
                                ? `linear-gradient(180deg, rgba(0,0,0,0.6) 0%, rgba( 0, 0, 0, 0.0) 50%), url(${itemImage.linkImagem})`
                                : `url(${itemImage.linkImagem})`,
                          }}
                        >
                          {onMouseOver === index && (
                            <Flex
                              color="white"
                              h="full"
                              p="5px"
                              w="full"
                              justifyContent="space-between"
                            >
                              <Flex
                                cursor="pointer"
                                borderRadius="16px"
                                justifyContent="flex-end"
                                onClick={() => handleRedimensionarImagem(index)}
                              >
                                <FiCrop size={23} />
                              </Flex>
                              <Flex
                                cursor="pointer"
                                borderRadius="16px"
                                justifyContent="flex-end"
                                onClick={() => handleRemoverImagem(index)}
                              >
                                <FiXCircle size={20} />
                              </Flex>
                            </Flex>
                          )}
                        </Flex>
                      ))}

                      {quantidadeTotalImagens + listImagem.length < 15 && (
                        <ImageUploader
                          borderWidth="1px"
                          borderStyle="dashed"
                          borderColor="violet.500"
                          mr="6px"
                          mb="10px"
                          sizeIcon="25px"
                          handleSalvarImagem={handleSalvarImagem}
                          bg="white"
                          borderRadius="2px"
                          boxSize="116px"
                          cursor="pointer"
                          justifyContent="center"
                          alignItems="center"
                          color="gray.700"
                        />
                      )}
                    </Flex>
                  )}
                </Flex>
                {isLargerThan880 && !isLargerThan900 && (
                  <Flex pt="20px" pl="24px">
                    {amountImage > 0 && (
                      <Text
                        mr="40px"
                        color="violet.500"
                        fontSize="14px"
                        fontStyle="italic"
                      >
                        ** {amountImage}{' '}
                        {amountImage > 1 ? 'imagens' : 'imagem'} selecionadas **
                      </Text>
                    )}

                    {amountImage > 0 && imagensForaPadrao > 0 && (
                      <Flex alignItems="center" color="red.500" fontSize="14px">
                        <FiXCircle size={16} />

                        <Text ml="4px">
                          {imagensForaPadrao}{' '}
                          {imagensForaPadrao > 1 ? 'imagens' : 'imagem'} fora
                          das recomendações
                        </Text>
                      </Flex>
                    )}
                  </Flex>
                )}
              </Flex>
            </ModalBody>
            <ModalFooter
              pt={['5px', '5px', '0']}
              h="78px"
              px="0"
              flexDirection="column"
              mb={['', '', '20px']}
            >
              <Divider
                display={['none', 'none', 'flex']}
                mb="30px"
                color="gray.200"
                mt="16px"
              />
              <Flex
                w="full"
                h="full"
                px="24px"
                direction={['column', 'row']}
                justifyContent={
                  isLargerThan900
                    ? amountImage > 0
                      ? 'space-between'
                      : 'end'
                    : amountImage > 0 && !isLargerThan880
                    ? 'space-between'
                    : 'end'
                }
                alignItems={['left', 'baseline', 'baseline']}
              >
                {((isLargerThan900 && isLargerThan880) ||
                  (!isLargerThan900 && !isLargerThan880) ||
                  (isLargerThan900 && !isLargerThan880)) && (
                  <>
                    {amountImage > 0 && (
                      <Text
                        color="violet.500"
                        fontSize="14px"
                        fontStyle="italic"
                      >
                        ** {amountImage}{' '}
                        {amountImage > 1
                          ? 'imagens selecionadas'
                          : 'imagem selecionada'}{' '}
                        **
                      </Text>
                    )}

                    {amountImage > 0 && imagensForaPadrao > 0 && (
                      <Flex alignItems="center" color="red.500" fontSize="14px">
                        <FiXCircle size={16} />

                        <Text ml="4px">
                          {imagensForaPadrao}{' '}
                          {imagensForaPadrao > 1 ? 'imagens' : 'imagem'} fora
                          das recomendações
                        </Text>
                      </Flex>
                    )}
                  </>
                )}

                <Flex mt={['5px', '0']} direction={['column', 'row']}>
                  <Button
                    variant="outlineDefault"
                    borderRadius="20px"
                    colorScheme="gray"
                    h="32px"
                    mr={['', '24px', '24px']}
                    onClick={() => onClose()}
                    w={['full', '96px', '96px']}
                  >
                    Cancelar
                  </Button>
                  <ButtonDefault
                    color="white"
                    mt={['5px', '0']}
                    variant="solid"
                    colorScheme="aquamarine.600"
                    borderRadius="20px"
                    fontSize="sm"
                    h="32px"
                    w={['full', '120px', '120px']}
                    onClick={() => handleSubmit()}
                    isLoading={isLoading}
                  >
                    Confirmar
                  </ButtonDefault>
                </Flex>
              </Flex>
            </ModalFooter>
          </FormProvider>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
