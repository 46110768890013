import styled from 'styled-components';
import { Col as BootsTrapCol } from 'react-bootstrap';
import TableCampoFormularioPadrao from 'components/Table/TableCampoFormularioPadrao';
import { UploadLogoQuadradaIcon, UploadLogoRetangularIcon } from 'icons';

export const Container = styled.div``;

export const ButtonAdicionarCertificado = styled.button`
  padding: 4px;
  text-align: left;
  letter-spacing: 0px;
  color: white !important;
  min-width: 130px;
  text-align: center;
  margin-left: 20px;
  background: var(--sti-ck-colors-blue-500) 0% 0% no-repeat padding-box;
  border: 1px solid var(--sti-ck-colors-blue-500);
  border-radius: 5px;
  opacity: 1;
`;

export const SpanFileName = styled.span`
  padding: 7px;
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0px;
  color: var(--sti-ck-colors-gray-700);
  opacity: 1;
`;

export const SpanFileDetails = styled.span`
  padding: 7px;
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  letter-spacing: 0px;
  color: var(--sti-ck-colors-gray-700);
  opacity: 1;
`;

export const TableCampoFormularioPadraoStyle = styled(
  TableCampoFormularioPadrao
)`
  margin-top: 100px !important;
  background-color: var(--sti-ck-colors-gray-50);
`;

export const ColMargemQuadrada = styled(BootsTrapCol)`
  // Menor que 700px
  margin-left: 0px;
  display: block;
  // 700px até 899px
  @media (min-width: 700px) {
    margin-left: 30px;
  }

  // 900px até 1199px
  @media (min-width: 900px) {
    margin-left: 0px;
  }

  // 1200px até 1799px
  @media (min-width: 1200px) {
    margin-left: 40px;
  }

  // 1800px ou maior
  @media (min-width: 1800px) {
    margin-left: 12rem;
  }
`;

export const ColMargemRetangular = styled(BootsTrapCol)`
  // Menor que 700px
  margin-left: 0px;

  // 700px até 899px
  @media (min-width: 700px) {
    margin-left: 30px;
  }

  // 900px até 1199px
  @media (min-width: 900px) {
    margin-left: 50px;
  }

  // 1200px até 1799px
  @media (min-width: 1200px) {
    margin-left: 50px;
  }

  // 1800px ou maior
  @media (min-width: 1800px) {
    margin-left: 150px;
  }
`;

export const ColLogoQuadrada = styled(BootsTrapCol)`
  // Menor que 700px
  border-bottom: 1px solid var(--sti-ck-colors-gray-700);
  border-right: 0;

  // 700px até 899px
  @media (min-width: 700px) {
    border-bottom: 1px solid var(--sti-ck-colors-gray-700) !important;
    border-right: 0 !important;
  }

  // 900px até 1199px
  @media (min-width: 900px) {
    border-right: 1px solid var(--sti-ck-colors-gray-700) !important;
    border-bottom: 0 !important;
  }

  // 1200px até 1799px
  @media (min-width: 1200px) {
    border-right: 1px solid var(--sti-ck-colors-gray-700) !important;
    border-bottom: 0 !important;
  }

  // 1800px ou maior
  @media (min-width: 1800px) {
    border-right: 1px solid var(--sti-ck-colors-gray-700) !important;
    border-bottom: 0 !important;
  }
`;

export const IconLogoQuadrada = styled(UploadLogoQuadradaIcon)`
  // Menor que 700px
  font-size: 60px;

  // 700px até 899px
  @media (min-width: 700px) {
    font-size: 60px;
  }

  // 900px até 1199px
  @media (min-width: 900px) {
    font-size: 60px;
  }

  // 1200px até 1799px
  @media (min-width: 1200px) {
    font-size: 60px;
  }

  // 1800px ou maior
  @media (min-width: 1800px) {
    font-size: 60px;
  }
`;

export const IconLogoRetangular = styled(UploadLogoRetangularIcon)`
  // Menor que 700px
  font-size: 60px;

  // 700px até 899px
  @media (min-width: 700px) {
    font-size: 70px;
  }

  // 900px até 1199px
  @media (min-width: 900px) {
    font-size: 60px;
  }

  // 1200px até 1799px
  @media (min-width: 1200px) {
    font-size: 60px;
  }

  // 1800px ou maior
  @media (min-width: 1800px) {
    font-size: 60px;
  }
`;

export const DivDetalhesLogo = styled.div`
  display: flex;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  font-size: 12px;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
`;

export const DivDetalhesLogoQuadrada = styled.div`
  // Menor que 700px
  display: block;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  font-size: 12px;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;

  // 700px até 899px
  @media (min-width: 700px) {
    display: flex;
  }

  // 900px até 1199px
  @media (min-width: 900px) {
    display: flex;
  }

  // 1200px até 1799px
  @media (min-width: 1200px) {
    display: flex;
  }

  // 1800px ou maior
  @media (min-width: 1800px) {
    display: flex;
  }
`;

export const HeaderInformacoesFiscais = styled.div`
  width: 100%;
  background-color: var(--sti-ck-colors-gray-50);
  padding: 11px;
`;

export const SpanInformacoesFiscais = styled.span`
  text-align: left;
  font: normal normal 600 13px/16px Nunito;
  letter-spacing: 0px;
  color: var(--black);
  opacity: 1;
`;
