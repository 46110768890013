import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import enumTipoDataImpressaoEtiqueta from 'constants/enum/enumTipoDataImpressaoEtiqueta';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import { formatFullDate } from 'helpers/format/formatData';

export type ListaProdutosModalProps = {
  id: string;
  produto: string;
  produtoSelecionado?: boolean;
  quantidade: number;
  tamanho: string;
  precoVenda: number;
  padraoSistema?: boolean;
};

export type ListaProdutosImpressaoProps = Omit<
  ListaProdutosModalProps,
  'produtoSelecionado'
>;

export type FormData = {
  dataBusca: string;
  tipoData: number;
  nomeSkuCodigoExternoBarrasGtinEan: string;
};

export type ProdutosResponseProps = {
  id: string;
  nome: string;
  descricaoTamanho: string;
  saldo: number;
  precoVenda: number;
  padraoSistema: boolean;
};

export type ProdutoProps = {
  colunaInicial: number;
  infoProdutos: ProdutoResponse | null;
  linhaInicial: number;
  quantidade: number;
  quantidadePorProduto: { [key: string]: number };
  tamanho: string;
  valorEtiqueta: string;
};

export type ProdutoResponse = {
  corDescricao: string;
  label: string;
  produtoNome: string;
  value: string;
};

export const defaultValues = {
  tipoData: enumTipoDataImpressaoEtiqueta.CADASTRADOS,
  dataBusca: formatFullDate(new Date()),
  nomeSkuCodigoExternoBarrasGtinEan: '',
};

const schema = yup.object().shape({
  tipoData: yup.number().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  dataBusca: yup.date().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);
