import React from 'react';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

import PlanoContas from '../pages/PlanoContas';

const PlanoContasRoutes = [
  <LayoutGuard
    key="plano-contas"
    permissaoFuncionalidade={ConstanteFuncionalidades.PLANO_CONTA_LISTAR}
    component={PlanoContas}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Plano de Contas',
      },
    ]}
    path={ConstanteRotas.PLANO_CONTAS}
    exact
    meta={{ auth: true }}
  />,
];

export default PlanoContasRoutes;
