import React, { useState } from 'react';
import { Button, Form, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FieldErrors, useFieldArray, UseFormRegister } from 'react-hook-form';
import { useToken } from '@chakra-ui/react';

import EnderecoCompleto from 'components/EnderecoCompleto';
import ModalEndereco from 'components/Modal/ModalEndereco';
import AccordionPadrao from 'components/Accordion/AccordionPadrao';
import {
  Cobranca,
  ColEndereco,
  Entrega,
  SpanCep,
  SpanLogradouro,
  Container,
  ButtonComOpcoesCliente,
} from './styles';

interface EnderecosFormInterface {
  errors: FieldErrors;
  register: UseFormRegister<Record<string, any>>;
  readonly?: boolean;
  control: any;
  setValue: any;
  getValue: any;
  cadastroPdv?: boolean;
  setError?: any;
}

interface IEndereco {
  id: string;
  logradouro: string;
  complemento: string;
  cep: string;
  bairro: string;
  cidade: string;
  numero: string;
  cobranca: boolean;
  entrega: boolean;
  cidadeNome: string;
}

interface IFieldArrayType {
  enderecosAdicionais: IEndereco[];
}

const Enderecos = ({
  errors,
  register,
  readonly,
  control,
  setValue,
  getValue,
  setError,
  cadastroPdv,
}: EnderecosFormInterface) => {
  const enderecos = 'enderecosAdicionais';
  const blue500 = useToken('colors', 'blue.500');

  const { fields, append, remove } = useFieldArray<
    IFieldArrayType,
    'enderecosAdicionais',
    'id'
  >({
    control,
    name: enderecos,
    keyName: 'id',
  });

  const { t } = useTranslation();

  const [enderecoModalIsOpen, setEnderecoModalIsOpen] = useState(false);

  const [indexEditEndereco, setIndexEditEndereco] = useState(-1);

  const adicionarNovo = () => {
    setIndexEditEndereco(-1);
    setEnderecoModalIsOpen(true);
  };

  const handleSubmit = () => {
    const data = getValue();

    const enderecoAdicionado = {
      id: indexEditEndereco !== -1 ? data.idEnderecoModal : null,
      codigo: indexEditEndereco,
      principal: false,
      cobranca: data.cobrancaModal.selecionado || false,
      entrega: data.entregaModal.selecionado || false,
      cep: data.cepModal,
      logradouro: data.logradouroModal,
      numero: data.numeroModal,
      complemento: data.complementoModal,
      bairro: data.bairroModal,
      cidade: data.cidadeModal.value,
      cidadeNome: data.cidadeModal.label,
      pais: data.paisModal.value,
      estado: data.estadoModal,
      paisCampo: data.paisModal,
      cidadeCampo: data.cidadeModal,
    };

    append(enderecoAdicionado);
    setEnderecoModalIsOpen(false);
  };

  return (
    <>
      <ModalEndereco
        show={enderecoModalIsOpen}
        onHide={() => {
          setEnderecoModalIsOpen(false);
        }}
        onSubmit={handleSubmit}
        register={register}
        control={control}
        // errors={errors}
        getValues={getValue}
        setValues={setValue}
        readonly={readonly}
        indexEndereco={indexEditEndereco}
        cadastroPdv={cadastroPdv}
      />
      <AccordionPadrao title="Endereços" defaultOpen>
        <Container fluid>
          <Form.Row>
            <EnderecoCompleto
              errors={errors}
              setError={setError}
              register={register}
              control={control}
              readonly={readonly}
              setValue={setValue}
              getValue={getValue}
              cepCampoId="cep"
              logradouroCampoId="logradouro"
              numeroCampoId="numero"
              complementoCampoId="complemento"
              bairroCampoId="bairro"
              cidadeCampoId="cidade"
              estadoCampoId="estado"
              paisCampoId="pais"
              cidadeCampoRequired
              paisCampoRequired
            />
          </Form.Row>

          <Form.Row
            style={{
              paddingTop: '20px',
              paddingBottom: '20px',
            }}
          >
            {!readonly && (
              <Col xl={12} style={{ padding: '0px 0 0 0' }}>
                <Button
                  variant="link"
                  onClick={adicionarNovo}
                  id="adicionar-novo-endereco"
                  style={{
                    padding: '0px',
                    color: `${blue500}`,
                    letterSpacing: '0',
                    fontWeight: 600,
                    fontSize: '12px',
                  }}
                >
                  Adicionar novo endereço
                </Button>
              </Col>
            )}
          </Form.Row>
          <div id="enderecos-adicionados">
            {fields.map((item, index) => (
              <Form.Row>
                {index >= 0 && (
                  <>
                    {/* eslint-disable-next-line react/no-array-index-key */}
                    <Col xl={12} key={index} style={{ padding: '3px 0' }}>
                      <div
                        style={{
                          float: 'right',
                          padding: '25px 20px 20px 20px',
                        }}
                      >
                        {!readonly && (
                          <ButtonComOpcoesCliente
                            id="mostrarMaisEndereco"
                            dropdownItems={[
                              {
                                title: t('Editar'),
                                onClick: () => {
                                  setIndexEditEndereco(index);
                                  setEnderecoModalIsOpen(true);
                                },
                              },
                              {
                                title: t('Remover'),
                                onClick: () => {
                                  remove(index);
                                },
                              },
                            ]}
                          />
                        )}
                      </div>
                      <ColEndereco>
                        <SpanLogradouro>
                          {(item.logradouro && (
                            <span>
                              {item.logradouro},{' '}
                              {item.complemento && `${item.complemento} -`}{' '}
                              {item.numero}{' '}
                            </span>
                          )) || <span>&nbsp;</span>}

                          {item.cobranca && (
                            <Cobranca id="cobranca">COBRANÇA</Cobranca>
                          )}
                          {item.entrega && (
                            <Entrega id="entrega">ENTREGA</Entrega>
                          )}
                        </SpanLogradouro>

                        <SpanCep>
                          {item.cep && <span>{`${item.cep} | `}</span>}
                          {item.bairro && <span>{`${item.bairro} | `}</span>}

                          {item.cidadeNome}
                        </SpanCep>
                      </ColEndereco>
                    </Col>
                  </>
                )}
              </Form.Row>
            ))}
          </div>
        </Container>
      </AccordionPadrao>
    </>
  );
};

export default Enderecos;
