import React, {
  ForwardRefRenderFunction,
  useImperativeHandle,
  forwardRef,
  useState,
  useCallback,
  useRef,
} from 'react';
import { Text, Flex, Container } from '@chakra-ui/react';
import { useReactToPrint } from 'react-to-print';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ContatoEmpresa from 'constants/contato';

import Cabecalho from './Divisoes/cabecalho';
import TableResumo from './Divisoes/tableResumo';
import Header from './Divisoes/header';
import Footer from './Divisoes/footer';

import {
  ImpressaoProps,
  ImpressaoCupom56mmProps,
  ImpressaoCupom56mmRefElement,
} from './types';

const ImpressaoCupom56mm: ForwardRefRenderFunction<
  ImpressaoCupom56mmRefElement,
  ImpressaoCupom56mmProps
> = ({ maxWidth, movimentacaoFinanceiaId }, ref) => {
  const [
    impressaoCupom56mm,
    setIsImpressaoCupom56mm,
  ] = useState<ImpressaoProps>();

  const containerRef = useRef<HTMLDivElement>(null);

  const imprimirCupom56mm = useReactToPrint({
    content: () => containerRef.current,
  });

  const obterInformacoesImpressao = useCallback(async () => {
    const response = await api.post<
      void,
      ResponseApi<ImpressaoProps | undefined>
    >(
      ConstanteEnderecoWebservice.CONTAS_RECEBER_OBTER_PARA_IMPRIMIR,
      movimentacaoFinanceiaId
    );

    if (response?.sucesso) {
      setIsImpressaoCupom56mm(response.dados);
    }
  }, [movimentacaoFinanceiaId]);

  const getPrintFunctions56mm = async () => {
    if (imprimirCupom56mm) {
      await obterInformacoesImpressao();
      imprimirCupom56mm();
    }
  };

  useImperativeHandle(ref, () => ({
    imprimirCupom56mm: getPrintFunctions56mm,
  }));

  if (impressaoCupom56mm === undefined) return null;

  return (
    <Container
      sx={{
        '& div,': {
          color: 'black',
          fontWeight: '900',
          fontSize: '10px',
          fontFamily: 'arial',
        },
        '& div, & hr': {
          opacity: 'unset',
          borderColor: 'black',
        },
      }}
      minW="56mm"
      maxW={maxWidth}
      ref={containerRef}
    >
      <Flex direction="column" h="auto" mt="3">
        <Cabecalho
          nomeFantasia={impressaoCupom56mm?.loja?.fantasia}
          emissao={impressaoCupom56mm?.emissao}
          cliente={impressaoCupom56mm?.cliente}
          telefone={impressaoCupom56mm?.loja?.telefone}
        />

        {impressaoCupom56mm?.movimentacoes?.map((movimentacao) => {
          const { movimentacaoFinanceiraBaixa } = movimentacao || [];
          const valorTotalMultasJuros = movimentacaoFinanceiraBaixa?.reduce(
            (acc, curr) => acc + curr?.multaJuros,
            0
          );
          const valorTotalDescontos = movimentacaoFinanceiraBaixa?.reduce(
            (acc, curr) => acc + curr?.desconto,
            0
          );

          const valorTotalRecebido = movimentacaoFinanceiraBaixa?.reduce(
            (acc, curr) => acc + curr?.totalRecebido,
            0
          );
          return (
            <>
              <Header
                identificador={movimentacao.identificador}
                parcela={movimentacao.parcela}
              />
              <TableResumo
                key={movimentacao.identificador}
                movimentacaoFinanceiraBaixa={movimentacaoFinanceiraBaixa}
              />
              <Footer
                operador={movimentacao.operador}
                valorOriginal={movimentacao.valorOriginal}
                valorTotalMultasJuros={valorTotalMultasJuros}
                valorTotalDescontos={valorTotalDescontos}
                valorTotalRecebido={valorTotalRecebido}
              />
            </>
          );
        })}
        <Text
          textAlign="right"
          fontStyle="italic"
          fontSize="2xs"
          fontWeight="bold"
          color="gray.900"
          mt={2}
        >
          {ContatoEmpresa.CONTATO}
        </Text>
      </Flex>
    </Container>
  );
};

export default forwardRef(ImpressaoCupom56mm);
