import {
  ModalContent,
  ModalBody,
  Flex,
  ModalProps,
  Button,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';

type ModalReprocessandoVendaProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalProps> & {
    callback: () => void;
  };

export const ModalReprocessandoVenda = create<ModalReprocessandoVendaProps>(
  ({ callback }) => {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    return (
      <ModalPadraoChakra
        onClose={() => {
          callback();
          onClose();
        }}
        isOpen={isOpen}
        isCentered
        size="2xl"
        autoFocus={false}
      >
        <ModalContent
          background="gray.50"
          minH={['full', '360px']}
          width={['full', '560px']}
        >
          <ModalBody p={0}>
            <Flex flexDirection="column">
              <Text
                px="40px"
                pt="40px"
                pb="22px"
                color="primary.50"
                fontSize="20px"
                fontWeight="600"
              >
                Reprocessando...
              </Text>

              <Flex
                justifyContent="flex-start"
                flexDirection="column"
                pl="40px"
                pr="38px"
                pb="48px"
              >
                <Text
                  fontSize="14px"
                  color="black"
                  lineHeight="1.6"
                  letterSpacing="0px"
                  whiteSpace="pre-line"
                >
                  {
                    'A venda anterior foi removida da lista e um novo ítem será acrescentado. Assim que o processo estiver concluído, você receberá uma notificação com o “novo pedido” atualizado.\n\n Enquanto isso, utilize normalmente o sistema. O processamento dos dados está sendo feito em segundo plano.'
                  }
                </Text>
              </Flex>
            </Flex>
            <Flex display="flex" justifyContent="center" gap="24px" mt="8px">
              <Button
                onClick={() => {
                  callback();
                  onClose();
                }}
                variant="solid"
                color="white"
                colorScheme="purple"
                fontWeight="500"
                borderRadius="20px"
                height="40px"
                fontSize="16px"
                w={['full', '200px']}
              >
                Entendi
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
