import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { OptionTypeBase } from 'react-select';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import StatusConsultaEnum from 'constants/enum/statusConsulta';

import { getVariacoesCores } from '../../FunctionsCadastroProduto/ObterCoresCadastradas';
import { ModalInativarVariacao } from './components/ModalInativarVariacao';
import { ModalAtivarVariacao } from './components/ModalAtivarVariacao';

export const useVariacoesCor = (atualizarVariacoes: () => void) => {
  const buscarCores = useCallback(async () => {
    const response = await api.get<void, ResponseApi<OptionTypeBase[]>>(
      ConstanteEnderecoWebservice.COR_LISTAR_SELECT,
      { params: { statusConsulta: StatusConsultaEnum.ATIVOS } }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso && response.dados) {
        const cores = response.dados.map((item) => ({
          value: item.id,
          label: item.nome,
        }));
        return cores;
      }
    }
    return [];
  }, []);

  const buscarVariacoesDeCores = useCallback(async (idProduto: string) => {
    const response = await getVariacoesCores(idProduto);
    if (response?.length > 0) {
      const newCores = response?.map((itemCores) => ({
        value: itemCores.cor.id,
        label: itemCores.cor.descricao,
        ativo: itemCores.ativo,
      }));

      return newCores;
    }
    return [];
  }, []);

  const ativarInativarVariacaoCor = useCallback(
    async (idProduto: string, idCor: string, ativar: boolean) => {
      const response = await api.patch<void, ResponseApi>(
        `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/cores/${idCor}/ativo/${ativar}`
      );
      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
          return false;
        }

        if (response.sucesso) {
          atualizarVariacoes();
          return true;
        }
      }
      return false;
    },
    [atualizarVariacoes]
  );

  const ativarVariacaoCor = useCallback(
    async (idProduto: string, idCor: string) => {
      try {
        const success = await ModalAtivarVariacao();
        if (success) {
          const ativado = await ativarInativarVariacaoCor(
            idProduto,
            idCor,
            true
          );
          return ativado;
        }
      } catch (error) {
        return false;
      }
      return false;
    },
    [ativarInativarVariacaoCor]
  );

  const removerVariacaoCor = useCallback(
    async (data: OptionTypeBase, idProduto: string) => {
      const response = await api.delete<void, ResponseApi>(
        `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/cores/${data.value}`
      );
      if (response) {
        if (response.avisos) {
          try {
            const success = await ModalInativarVariacao();
            if (success) {
              const inativado = await ativarInativarVariacaoCor(
                idProduto,
                data.value,
                false
              );
              if (inativado) {
                return null;
              }
              return false;
            }
          } catch (error) {
            response.avisos.forEach((item: string) => toast.warning(item));
            return false;
          }
          return false;
        }

        if (response.sucesso) {
          atualizarVariacoes();
          return true;
        }

        return false;
      }

      return false;
    },
    [ativarInativarVariacaoCor, atualizarVariacoes]
  );

  const cadastrarVariacaoCor = useCallback(
    async (data: OptionTypeBase, idProduto: string) => {
      const response = await api.post<void, ResponseApi>(
        `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/cores`,
        {
          id: data.value,
          nome: data.label,
        }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));

          return false;
        }

        if (response.sucesso) {
          atualizarVariacoes();

          return true;
        }
        return false;
      }
      return false;
    },
    [atualizarVariacoes]
  );

  const cadastrarCor = useCallback(async (corDescricao: string) => {
    const response = await api.post<void, ResponseApi>(
      ConstanteEnderecoWebservice.COR_CADASTRAR,
      { descricao: corDescricao, ativo: true }
    );

    if (response?.sucesso) {
      const corId = response.dados;
      const newCor = {
        label: corDescricao,
        value: corId,
        ativo: true,
      } as OptionTypeBase;

      return newCor;
    }

    if (response?.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    return undefined;
  }, []);

  return {
    removerVariacaoCor,
    cadastrarVariacaoCor,
    cadastrarCor,
    ativarVariacaoCor,
    buscarVariacoesDeCores,
    buscarCores,
  };
};

export const useVariacoesTamanho = (atualizarVariacoes: () => void) => {
  const ativarInativarVariacaoTamanho = useCallback(
    async (idProduto: string, idTamanho: string, ativar: boolean) => {
      const response = await api.patch<void, ResponseApi>(
        `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/tamanhos/${idTamanho}/ativo/${ativar}`
      );
      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
          return false;
        }

        if (response.sucesso) {
          atualizarVariacoes();
          return true;
        }
      }
      return false;
    },
    [atualizarVariacoes]
  );

  const cadastrarVariacaoTamanho = useCallback(
    async (data: OptionTypeBase, idProduto: string) => {
      const response = await api.post<void, ResponseApi>(
        `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/tamanhos`,
        {
          id: data.value,
          nome: data.label,
        }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));

          return false;
        }

        if (response.sucesso) {
          atualizarVariacoes();
          return true;
        }

        return false;
      }

      return false;
    },
    [atualizarVariacoes]
  );

  const cadastrarTamanho = useCallback(async (tamanhoDescricao: string) => {
    const response = await api.post<void, ResponseApi>(
      ConstanteEnderecoWebservice.TAMANHO_CADASTRAR,
      { descricao: tamanhoDescricao, ativo: true }
    );

    if (response?.sucesso) {
      const tamanhoId = response.dados;
      const newTamanho = {
        label: tamanhoDescricao,
        value: tamanhoId,
        ativo: true,
      } as OptionTypeBase;

      return newTamanho;
    }

    if (response?.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    return undefined;
  }, []);

  const removerVariacaoTamanho = useCallback(
    async (data: OptionTypeBase, idProduto: string) => {
      const response = await api.delete<void, ResponseApi>(
        `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/tamanhos/${data.value}`
      );

      if (response) {
        if (response.avisos) {
          try {
            const success = await ModalInativarVariacao();
            if (success) {
              const inativado = await ativarInativarVariacaoTamanho(
                idProduto,
                data.value,
                false
              );
              if (inativado) {
                return null;
              }
              return false;
            }
          } catch (error) {
            response.avisos.forEach((item: string) => toast.warning(item));
            return false;
          }
          return false;
        }

        if (response.sucesso) {
          atualizarVariacoes();
          return true;
        }

        return false;
      }

      return false;
    },
    [ativarInativarVariacaoTamanho, atualizarVariacoes]
  );

  const buscarVariacoesDeTamanho = useCallback(async (idProduto: string) => {
    const response = await api.get<void, ResponseApi<any[]>>(
      `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/tamanhos`
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso) {
        const newTamanhos = response.dados
          .filter((item) => item.padraoSistema === false)
          .map((itemTamanho) => ({
            value: itemTamanho.id,
            label: itemTamanho.descricao,
            ativo: itemTamanho.ativo,
          }));

        return newTamanhos;
      }
    }
    return [];
  }, []);

  const buscarTamanhos = useCallback(async () => {
    const response = await api.get<void, ResponseApi<OptionTypeBase[]>>(
      ConstanteEnderecoWebservice.TAMANHO_LISTAR_SELECT,
      { params: { statusConsulta: StatusConsultaEnum.ATIVOS } }
    );

    if (response.avisos) {
      response.avisos.forEach((item: string) => toast.warning(item));
    }

    if (response) {
      if (response.sucesso && response.dados) {
        const tamanhos = response.dados.map((item) => ({
          value: item.id,
          label: item.nome,
        }));
        return tamanhos;
      }
    }
    return [];
  }, []);

  const ativarVariacaoTamanho = useCallback(
    async (idProduto: string, idTamanho: string) => {
      try {
        const success = await ModalAtivarVariacao();
        if (success) {
          const ativado = await ativarInativarVariacaoTamanho(
            idProduto,
            idTamanho,
            true
          );
          return ativado;
        }
      } catch (error) {
        return false;
      }
      return false;
    },
    [ativarInativarVariacaoTamanho]
  );

  return {
    removerVariacaoTamanho,
    ativarInativarVariacaoTamanho,
    buscarVariacoesDeTamanho,
    buscarTamanhos,
    cadastrarTamanho,
    cadastrarVariacaoTamanho,
    ativarVariacaoTamanho,
  };
};
