export type ClienteProps = {
  endereco: string;
  label: string;
  value: string;
};

export type ItensConsignados = {
  operacaoItemId: string;
  descricao: string;
  codigoBarrasInterno: string;
  valorUnitario: number;
  valorItemComDesconto: number;
  valorItemSemDesconto: number;
  quantidadeConsignada: number;
  numeroConsignacao: number;
  dataHoraConsignacao: Date;
  contaFinanceira: string;
  vendedor: string;
  tabelaPreco: string;
  isChecked: boolean;
  produtoSelecionado: boolean;
  codigoBarrasHexaDecimal: string;
  codigoGTINEAN: string;
  codigoExterno: string;
  volumeUnitario: number;
};

export type ProdutosConsignadosProps = {
  numeroConsignacao: number;
  dataHoraConsignacao: Date;
  contaFinanceira: string;
  vendedor: string;
  tabelaPreco: string;
  itensConsignados: ItensConsignados[];
};

export type QuantidadeProps = {
  id: string;
  valorQuantidade: number;
};

export const formDefaultValues = {
  quantidade: [1],
  codigoBarras: null,
};
