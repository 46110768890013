import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export type FormData = {
  juros: number;
  multa: number;
  desconto: number;
  totalBaixa: number;
  valor: number;
  dataPagamento: Date;
  formaRecebimento: {
    value: string;
    contaFinanceiraId: string;
    label: string;
    nome: string;
    parcelas: [
      {
        qtdParcelas: number;
        descricao: string;
        id: string;
      }
    ];
    regraMeioPagamento: number;
  };
  contaFinanceiraId: string;
  qtdParcelas?: { label: string; value: string; qtdParcelas: number };
  dataVencimentoCartao?: Date;
  valorRecebido: number;
  valeId: string;
  valorVoucher: number;
};

export interface ClientePadraoSistemaResponse {
  id: string;
  nome: string;
}

const schema = yup.object().shape({
  totalBaixa: yup
    .number()
    .positive(ConstanteMensagemValidacao.VALOR_MAIOR_QUE_0)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  dataPagamento: yup
    .date()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  contaFinanceiraId: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  formaRecebimento: yup
    .object()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);
