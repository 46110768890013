import { useCallback, useEffect } from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { FormProvider, useForm } from 'react-hook-form';

import {
  getCanalVendasTray,
  buscarDadosAutenticacao,
  autenticarDados,
  getCadastrarCanalVendasTray,
} from 'services/tray';
import { useTrayPainelControleContext } from 'store/Tray/PainelControle';

import { Switch } from 'components/update/Switch';
import Input from 'components/PDV/Input';
import Loading from 'components/Layout/Loading/LoadingPadrao';

export const DadosGerais = () => {
  const { isLoading, setIsLoading } = useTrayPainelControleContext();

  const formMethods = useForm({
    defaultValues: {
      nomeCanalVendas: '',
      sincronizacaoHabilitada: true,
      code: '',
      dominioLoja: '',
    },
  });

  const { setValue, handleSubmit: onSubmit } = formMethods;

  const getCanalVendas = useCallback(async () => {
    setIsLoading(true);

    const response = await getCanalVendasTray<{ nomeCanalVenda: string }>();

    if (response !== null) {
      setValue('nomeCanalVendas', response.nomeCanalVenda);
      setIsLoading(false);
    }

    setIsLoading(false);
  }, [setIsLoading, setValue]);

  const getAutenticacao = useCallback(async () => {
    setIsLoading(true);

    const response = await buscarDadosAutenticacao();

    if (response !== null) {
      setValue('code', response.code);
      setValue('dominioLoja', response.url);
      setValue(
        'sincronizacaoHabilitada',
        response.sincronizacaoHabilitada || true
      );
    }

    setIsLoading(false);
  }, [setIsLoading, setValue]);

  const atualizandoDadosGerais = useCallback(async () => {
    await getCanalVendas();
    await getAutenticacao();
  }, [getAutenticacao, getCanalVendas]);

  const handleSubmit = onSubmit(async (data) => {
    setIsLoading(true);

    const responseAutenticacao = await autenticarDados({
      code: data.code,
      url: data.dominioLoja,
      sincronizacaoHabilitada: data.sincronizacaoHabilitada,
    });

    const responseCanalVendas = await getCadastrarCanalVendasTray({
      nomeCanalVenda: data.nomeCanalVendas,
    });

    if (responseAutenticacao !== null && responseCanalVendas !== null) {
      toast.success('Dados gerais alterados com sucesso');
    }

    setIsLoading(false);
  });

  useEffect(() => {
    atualizandoDadosGerais();
  }, [atualizandoDadosGerais]);

  return (
    <FormProvider {...formMethods}>
      {isLoading && <Loading />}
      <Box
        w="full"
        minW="200px"
        pt={['24px', '24px', '40px']}
        px={['0', '0', '20px']}
      >
        <Input
          name="nomeCanalVendas"
          id="nomeCanalVendas"
          label="Nome do canal de venda"
          placeholder="Ex: Belíssima Calçados"
          mb="24px"
        />
        <Input
          name="dominioLoja"
          id="dominioLoja"
          label="Domínio da loja (URL)"
          placeholder="https://nomedaloja.com.br"
          mb="24px"
        />
        <Input
          name="code"
          id="code"
          label="Code"
          placeholder="Informe o identificador da integração"
          mb="24px"
        />
        <Flex
          px="40px"
          borderColor="gray.200"
          borderWidth="1px"
          borderRadius="5px"
          justifyContent="space-between"
          alignItems="center"
          py="24px"
          bg="white"
        >
          <Text color="black" fontWeight="semibold">
            Ativar sincronização de cadastros, vendas e estoque com a Tray
          </Text>
          <Box>
            <Switch
              size="md"
              id="sincronizacaoHabilitada"
              name="sincronizacaoHabilitada"
            />
          </Box>
        </Flex>
        <Flex mt="40px" w="full" justifyContent="center" alignItems="baseline">
          <Button
            variant="outlineDefault"
            borderRadius="16px"
            colorScheme="gray"
            mr="24px"
            w="96px"
            isLoading={isLoading}
            onClick={atualizandoDadosGerais}
          >
            Cancelar
          </Button>
          <Button
            color="black"
            isLoading={isLoading}
            variant="solid"
            colorScheme="secondary.400"
            borderRadius="16px"
            width="120px"
            onClick={handleSubmit}
          >
            Confirmar
          </Button>
        </Flex>
      </Box>
    </FormProvider>
  );
};
