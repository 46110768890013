import TipoSituacaoBaixa from 'constants/enum/situacaoBaixa';
import { setDateMaxHours, setDateMinHours } from 'helpers/data/setHoursDate';

type MovimentacaoFinanceiraBaixa = {
  dataPagamento?: Date;
  desconto?: number;
  juros?: number;
  multa?: number;
  valor?: number;
  formaRecebimento?: string;
};

export type Conta = {
  selecionado?: boolean;
  valorOriginal: number;
  competencia: Date;
  dataEmissao?: Date;
  dataVencimento: Date;
  entradaMercadoriaId?: string;
  clienteFornecedorRazaoSocial: string;
  clienteFornecedorNome: string;
  clienteFornecedorId: string;
  formaRecebimento: string;
  faturaId?: string;
  historico?: string;
  identificador?: number;
  movimentacaoFinanceiraId: string;
  numeroDocumento?: string;
  operacaoId: string;
  parcela: string;
  planoContas: string;
  possuiDesmembramento: boolean;
  tipoOperacao: number;
  multa: number;
  desconto: number;
  juros: number;
  valor: number;
  pagamentoParcial: boolean;
  movimentacaoFinanceiraBaixa: MovimentacaoFinanceiraBaixa[];
};

export type FormData = {
  clienteFornecedorId: {
    label: string;
    value: string;
  } | null;
  dataVencimentoInicio: Date;
  dataVencimentoFim: Date;
  dataEmissaoInicio?: Date;
  dataEmissaoFim?: Date;
  dataPagamentoInicio?: Date;
  dataPagamentoFim?: Date;
  dataCompensacaoInicio?: Date;
  dataCompensacaoFim?: Date;
  planoContasId: string | null;
  formaPagamentoEmissaoId?: string | null;
  formaPagamentoBaixaId?: string | null;
  situacao: number;
  identificador?: number | string;
  numeroDocumento?: string;
  historico?: string;
};

export const formDefaultValues: FormData = {
  clienteFornecedorId: null,
  dataVencimentoInicio: setDateMinHours(new Date()),
  dataVencimentoFim: setDateMaxHours(new Date()),
  dataEmissaoInicio: undefined,
  dataEmissaoFim: undefined,
  dataPagamentoInicio: undefined,
  dataPagamentoFim: undefined,
  dataCompensacaoInicio: undefined,
  dataCompensacaoFim: undefined,
  planoContasId: null,
  formaPagamentoEmissaoId: null,
  formaPagamentoBaixaId: null,
  situacao: TipoSituacaoBaixa.CONTAS_EM_ABERTO,
  identificador: '',
  numeroDocumento: '',
  historico: '',
};
