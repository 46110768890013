import React, { useMemo, useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  GridItem,
  Icon,
  Link,
  Tr,
  Td,
  Flex,
  Box,
  useMediaQuery,
} from '@chakra-ui/react';

import auth from 'modules/auth';
import ConstanteFuncionalidades from 'constants/permissoes';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import RegraLimiteCreditoEnum from 'constants/enum/regraLimiteCredito';

import { UsuariosPerfilUsuarioIcon } from 'icons';
import { SearchInput } from 'components/update/Input/SearchInput';
import ManterFoco from 'components/Geral/ManterFoco';
import { StatusCircle } from 'components/update/Table/StatusCircle';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { ModalConfirmacaoInativar } from 'components/Modal/ModalConfirmacaoInativar';
import {
  PagedTable,
  PagedTableForwardRefData,
} from 'components/update/Table/PagedTable';
import {
  GridPaginadaConsulta,
  GridPaginadaRetorno,
} from 'components/Grid/Paginacao';
import { FilterSelect } from 'components/update/Select/FilterSelect';
import { ButtonCadastrarNovo } from 'components/Layout/ButtonCadastrarNovo';

interface CategoriaClientesFiltros {
  ativo: boolean;
  descricao: string;
}

interface CategoriaCliente {
  id: string;
  descricao: string;
  regraLimiteCredito: number;
  diasAtrasoBloqueio: number;
  ativo: boolean;
}

interface CategoriaClienteVisualizacao {
  id: string;
  descricao: string;
  regraLimiteCredito: {
    name: string;
    value: number;
    iconColor: string;
    descricao: string;
    padrao: boolean;
  };
  diasAtrasoBloqueio: number;
  ativo: boolean;
}

const Listar = () => {
  const history = useHistory();
  const isMountedRef = useIsMountedRef();
  const filtrosAtuais = useRef<CategoriaClientesFiltros>({
    ativo: true,
  } as CategoriaClientesFiltros);

  const formMethods = useForm<CategoriaClientesFiltros>({
    defaultValues: filtrosAtuais.current,
  });
  const { getValues, setFocus } = formMethods;

  const [isLoading, setIsLoading] = useState(false);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [categoriasCliente, setcategoriasCliente] = useState<
    CategoriaClienteVisualizacao[]
  >([]);
  const [isLargerThan900] = useMediaQuery('(max-width: 900px)');
  const pageIsLoaded = useRef(false);
  const childRef = useRef<PagedTableForwardRefData>(null);

  const permissaoCategoriaClienteAlterar = useMemo(
    () =>
      auth.possuiPermissao(ConstanteFuncionalidades.CATEGORIA_CLIENTE_ALTERAR),
    []
  );

  const handleHistoryPush = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history]
  );

  const handleReset = useCallback(() => {
    const filtros = getValues();

    if (JSON.stringify(filtros) !== JSON.stringify(filtrosAtuais.current)) {
      return childRef.current?.reload();
    }

    return () => {};
  }, [getValues]);

  const handleRefresh = useCallback(() => {
    return childRef.current?.reload();
  }, []);

  const permissaoCategoriaClienteVisualizar = auth.possuiPermissao(
    ConstanteFuncionalidades.CATEGORIA_CLIENTE_VISUALIZAR
  );

  const handleVisualizarAlteracaoCadastro = useCallback(
    (id: string) => {
      let href = '';

      if (permissaoCategoriaClienteVisualizar.permitido) {
        if (permissaoCategoriaClienteAlterar.permitido) {
          href = SubstituirParametroRota(
            ConstanteRotas.CATEGORIA_CLIENTE_ALTERAR,
            'id',
            id
          );
        } else {
          href = SubstituirParametroRota(
            ConstanteRotas.CATEGORIA_CLIENTE_VISUALIZAR,
            'id',
            id
          );
        }
      }

      return href;
    },
    [permissaoCategoriaClienteVisualizar, permissaoCategoriaClienteAlterar]
  );

  const paginationHandle = useCallback(
    async (gridPaginadaConsulta: GridPaginadaConsulta) => {
      setIsLoading(true);

      const filtros = getValues();

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<CategoriaCliente>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.CATEGORIA_CLIENTE_LISTAR_PAGINADO,
          gridPaginadaConsulta
        ),
        { params: { descricao: filtros.descricao, ativo: filtros.ativo } }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((avisos) => toast.warn(avisos));
        }

        if (response.sucesso && isMountedRef.current) {
          setTotalRegistros(response.dados.total);
          setcategoriasCliente(
            response.dados.registros.map((categoria: CategoriaCliente) => {
              return {
                ...((categoria as unknown) as CategoriaClienteVisualizacao),
                regraLimiteCredito: RegraLimiteCreditoEnum.properties.find(
                  ({ value }) => value === categoria.regraLimiteCredito
                ),
              } as CategoriaClienteVisualizacao;
            })
          );

          filtrosAtuais.current = filtros;
        }
      }

      if (isMountedRef.current) {
        setIsLoading(false);

        if (!pageIsLoaded.current) {
          pageIsLoaded.current = true;

          setFocus('descricao');
        }
      }
    },
    [getValues, isMountedRef, setFocus]
  );

  const excluirHandle = useCallback(
    async (categoriaClienteId: string, ativo: boolean) => {
      ModalConfirmacaoExcluir({
        callback: async (ok: boolean) => {
          if (ok) {
            setIsLoading(true);

            const response = await api.delete<void, ResponseApi>(
              ConstanteEnderecoWebservice.CATEGORIA_CLIENTE_EXCLUIR,
              {
                params: { id: categoriaClienteId },
              }
            );

            if (response) {
              if (response.sucesso) {
                toast.success('O cadastro foi removido com sucesso.');
                handleRefresh();
              }

              ModalConfirmacaoInativar({
                response,
                rotaWebService:
                  ConstanteEnderecoWebservice.CATEGORIA_CLIENTE_INATIVAR,
                id: categoriaClienteId,
                ativo,
                callback: (okInativar: boolean) => {
                  if (okInativar) handleRefresh();
                },
              });
            }

            setIsLoading(false);
          }
        },
      });
    },
    [handleRefresh]
  );

  return (
    <Box>
      <FormProvider {...formMethods}>
        <ManterFoco>
          <Flex
            direction={isLargerThan900 ? 'column' : 'row'}
            justifyContent={isLargerThan900 ? undefined : 'space-between'}
            alignItems={isLargerThan900 ? undefined : 'center'}
          >
            <Flex
              direction={isLargerThan900 ? 'column' : 'row'}
              w={isLargerThan900 ? 'full' : '62%'}
              alignItems="center"
            >
              <Box w="100%" mr={isLargerThan900 ? undefined : '30px'}>
                <SearchInput
                  type="search"
                  placeholder="Buscar categoria por descrição"
                  onEnterKeyPress={() => handleReset()}
                  isDisabled={isLoading}
                  id="descricao"
                  name="descricao"
                />
              </Box>
              <Box
                mt={isLargerThan900 ? '15px' : undefined}
                mb={isLargerThan900 ? '15px' : undefined}
                w={isLargerThan900 ? 'full' : '250px'}
              >
                <FilterSelect
                  id="ativo"
                  name="ativo"
                  isDisabled={isLoading}
                  options={[
                    {
                      value: true,
                      label: 'Ativos',
                    },
                    {
                      value: false,
                      label: 'Inativos',
                    },
                    {
                      value: null,
                      label: 'Todos',
                    },
                  ]}
                  onSelect={() => {
                    handleReset();
                  }}
                />
              </Box>
            </Flex>

            <ButtonCadastrarNovo
              onClick={() =>
                handleHistoryPush(ConstanteRotas.CATEGORIA_CLIENTE_CADASTRAR)
              }
              funcionalidade={
                ConstanteFuncionalidades.CATEGORIA_CLIENTE_CADASTRAR
              }
            />
          </Flex>
        </ManterFoco>
        <GridItem mt="25px" colSpan={12}>
          <PagedTable
            ref={childRef}
            itemsTotalCount={totalRegistros}
            loadColumnsData={paginationHandle}
            isLoading={isLoading}
            defaultKeyOrdered="Descricao"
            defaultOrderDirection="asc"
            tableHeaders={[
              {
                content: <StatusCircle hasValue={false} />,
                key: 'Ativo',
                isOrderable: true,
                width: '37px',
              },
              {
                content: 'Descrição',
                key: 'Descricao',
                isOrderable: true,
                width: 'auto',
              },
              {
                content: 'Regras de bloqueio',
                key: 'RegraLimiteCredito',
                isOrderable: false,
                width: '150px',
              },
              {
                content: 'Dias de atraso',
                key: 'DiasAtrasoBloqueio',
                isOrderable: false,
                width: '150px',
              },
              {
                content: 'Ações',
                key: 'Acoes',
                isOrderable: false,
                width: '38px',
              },
            ]}
            renderTableRows={categoriasCliente.map((categoriaCliente) => (
              <Tr key={categoriaCliente.id}>
                <Td textAlign="center">
                  <StatusCircle isActive={categoriaCliente.ativo} />
                </Td>
                <Td>
                  <Link
                    id="link-visualizar"
                    href={handleVisualizarAlteracaoCadastro(
                      categoriaCliente.id
                    )}
                  >
                    {categoriaCliente.descricao}
                  </Link>
                </Td>
                <Td>
                  <Flex alignItems="center" w="full">
                    <Icon
                      fontSize="20px"
                      mr="15px"
                      as={UsuariosPerfilUsuarioIcon}
                      color={categoriaCliente.regraLimiteCredito.iconColor}
                    />
                    <Box mt="3px">
                      {categoriaCliente.regraLimiteCredito.name}
                    </Box>
                  </Flex>
                </Td>
                <Td>{categoriaCliente.diasAtrasoBloqueio}</Td>
                <Td>
                  <ActionsMenu
                    id="mostrarMais"
                    items={[
                      {
                        content: 'Editar',
                        onClick: () => {
                          handleHistoryPush(
                            SubstituirParametroRota(
                              ConstanteRotas.CATEGORIA_CLIENTE_ALTERAR,
                              'id',
                              categoriaCliente.id
                            )
                          );
                        },
                        funcionalidade:
                          ConstanteFuncionalidades.CATEGORIA_CLIENTE_ALTERAR,
                      },
                      {
                        content: 'Remover',
                        onClick: () => {
                          excluirHandle(
                            categoriaCliente.id,
                            categoriaCliente.ativo
                          );
                        },
                        funcionalidade:
                          ConstanteFuncionalidades.CATEGORIA_CLIENTE_EXCLUIR,
                      },
                    ]}
                  />
                </Td>
              </Tr>
            ))}
          />
        </GridItem>
      </FormProvider>
    </Box>
  );
};

export default Listar;
