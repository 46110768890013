export const IdentificacaoEtapasSmartPos = {
  GUIA_INTEGRACAO: 0,
  FORMULARIO_STONE: 1,
  FOMULARIO_STONE_SUCESSO: 2,
  AUTENTICACAO: 3,
  LOCAL_ESTOQUE: 4,
  FORMA_RECEBIMENTO: 5,
  CATEGORIA_PRODUTO: 6,
  TIPO_IMPRESSAO: 7,
  IMAGEM_PARA_IMPRESSAO: 8,
  TEXTO_PARA_IMPRESSAO: 9,
  SENHA_ADM: 10,
  CODIGO_ATIVACAO: 11,
};
