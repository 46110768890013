import React, {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
} from 'react';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { useConferenciaEstoqueContainerContext } from 'store/ConferenciaEstoque/ConferenciaEstoqueContainer';

type ConferenciaEstoqueObter = {
  localEstoqueId: string;

  tipoConferencia: number;
  listaMarcas: { id: string; nome: string }[];
  listaCategorias: { id: string; nome: string }[];
};

type ConferenciaEstoque = {
  localEstoque?: { label: string; value: string };

  tipoConferencia?: number;
  listaMarcas?: { label: string; value: string }[];
  listaCategorias?: { label: string; value: string }[];
};

interface ConferenciaEstoqueEtapasContextProps {
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  conferenciaEstoque?: ConferenciaEstoque;
  setConferenciaEstoque: Dispatch<
    SetStateAction<ConferenciaEstoque | undefined>
  >;
  corrigirApenasItensConferidos: boolean;
  setCorrigirApenasItensConferidos: Dispatch<SetStateAction<boolean>>;
  nextStep: () => void;
  nextStepRevisao: () => void;
  previousStep: () => void;
  setExisteProdutos: React.Dispatch<
    React.SetStateAction<ProdutoPaginadoRegistro[]>
  >;
  existeProdutos: ProdutoPaginadoRegistro[];
}

type ProdutoPaginadoRegistro = {
  produtoCorTamanhoId: string;
  codigoBarrasInterno: string;
  nome: string;
  cor?: string;
  tamanho?: string;
  quantidade: number;
  volumeUnitario: boolean;
};

export const ConferenciaEstoqueEtapasContext = createContext<ConferenciaEstoqueEtapasContextProps>(
  {} as ConferenciaEstoqueEtapasContextProps
);

interface ConferenciaEstoqueEtapasProviderProps {
  children: React.ReactNode;
}

export function ConferenciaEstoqueEtapasProvider({
  children,
}: ConferenciaEstoqueEtapasProviderProps): JSX.Element {
  const { conferenciaEstoqueId } = useConferenciaEstoqueContainerContext();
  const [activeStep, setActiveStep] = useState(conferenciaEstoqueId ? 2 : 0);
  const [existeProdutos, setExisteProdutos] = useState<
    ProdutoPaginadoRegistro[]
  >([]);

  const [
    conferenciaEstoque,
    setConferenciaEstoque,
  ] = useState<ConferenciaEstoque>();
  const [
    corrigirApenasItensConferidos,
    setCorrigirApenasItensConferidos,
  ] = useState(false);

  const handleAtualizarInformacoesConferenciaEstoque = useCallback(async () => {
    const response = await api.get<void, ResponseApi<ConferenciaEstoqueObter>>(
      ConstanteEnderecoWebservice.CONFERENCIA_ESTOQUE_OBTER_CONFERENCIA
    );

    if (response) {
      if (response.avisos) {
        response.avisos.map((aviso: string) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        setConferenciaEstoque({
          localEstoque: { label: '', value: response.dados.localEstoqueId },
          tipoConferencia: response.dados.tipoConferencia,
          listaMarcas: response.dados.listaMarcas.map((marca) => ({
            value: marca.id,
            label: marca.nome,
          })),
          listaCategorias: response.dados.listaCategorias.map((categoria) => ({
            value: categoria.id,
            label: categoria.nome,
          })),
        });
      }
    }
  }, []);

  const nextStepRevisao = useCallback(() => {
    setActiveStep(4);
  }, []);

  const nextStep = useCallback(() => {
    if (conferenciaEstoqueId && activeStep < 3) {
      handleAtualizarInformacoesConferenciaEstoque();
    }
    setActiveStep((prev) => prev + 1);
  }, [
    handleAtualizarInformacoesConferenciaEstoque,
    conferenciaEstoqueId,
    activeStep,
  ]);

  const previousStep = useCallback(() => {
    setActiveStep((prev) => prev - 1);
  }, []);

  useEffect(() => {
    if (conferenciaEstoqueId && !conferenciaEstoque) {
      handleAtualizarInformacoesConferenciaEstoque();
    }
  }, [
    conferenciaEstoque,
    activeStep,
    conferenciaEstoqueId,
    handleAtualizarInformacoesConferenciaEstoque,
  ]);

  return (
    <ConferenciaEstoqueEtapasContext.Provider
      value={{
        activeStep,
        setExisteProdutos,
        existeProdutos,
        setActiveStep,
        conferenciaEstoque,
        setConferenciaEstoque,
        nextStep,
        nextStepRevisao,
        previousStep,
        corrigirApenasItensConferidos,
        setCorrigirApenasItensConferidos,
      }}
    >
      {children}
    </ConferenciaEstoqueEtapasContext.Provider>
  );
}

export function useConferenciaEstoqueEtapasContext(): ConferenciaEstoqueEtapasContextProps {
  const context = useContext(ConferenciaEstoqueEtapasContext);

  if (!context)
    throw new Error(
      'useConferenciaEstoqueEtapasContext must be used within a ConferenciaEstoqueEtapasProvider.'
    );

  return context;
}
