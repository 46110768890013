import React, {
  ForwardRefRenderFunction,
  useImperativeHandle,
  forwardRef,
  useState,
  useCallback,
  useRef,
} from 'react';
import { toast } from 'react-toastify';
import { Box, Flex } from '@chakra-ui/react';
import { useReactToPrint } from 'react-to-print';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import Cabecalho from './Divisoes/cabecalho';
import Vendas from './Divisoes/vendas';
import { OutrasInformacoes } from './Divisoes/outrasInformacoes';

import {
  ImpressaoProps,
  ImpressaoCupomTrocaProps,
  ImpressaoCupomTrocaRefElement,
} from './types';

const ImpressaoCupomTroca: ForwardRefRenderFunction<
  ImpressaoCupomTrocaRefElement,
  ImpressaoCupomTrocaProps
> = ({ maxWidth, operacaoId }, ref) => {
  const [
    impressaoCupomTroca,
    setIsImpressaoCupomTroca,
  ] = useState<ImpressaoProps>();

  const containerRef = useRef<HTMLDivElement>(null);

  const imprimirCupom = useReactToPrint({
    content: () => containerRef.current,
  });

  const obterInformacoesImpressao = useCallback(async () => {
    const response = await api.get<void, ResponseApi<ImpressaoProps>>(
      ConstanteEnderecoWebservice.PEDIDOORCAMENTOVENDA_OBTER_PARA_IMPRIMIR,
      {
        params: { id: operacaoId },
      }
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }
      if (response?.sucesso && response.dados) {
        setIsImpressaoCupomTroca(response.dados);
      }
    }
  }, [operacaoId]);

  const getPrintFunctions = async () => {
    if (imprimirCupom) {
      await obterInformacoesImpressao();
      imprimirCupom();
    }
  };

  useImperativeHandle(ref, () => ({
    imprimirCupom: getPrintFunctions,
  }));

  if (impressaoCupomTroca === undefined) return null;

  return (
    <Box
      h="full"
      minH="100vh"
      minW="100%"
      pl="10px"
      pr="10px"
      sx={{
        '& div': {
          color: 'black',
          fontWeight: '900',
          fontSize: '10px',
          fontFamily: 'arial',
        },
        '& div, & hr': {
          opacity: 'unset',
          borderColor: 'black',
        },
      }}
      ref={containerRef}
    >
      <Flex minW="58mm" maxW={maxWidth} direction="column" h="auto" pt="3">
        <Cabecalho
          nomeFantasia={impressaoCupomTroca.nomeFantasia}
          cnpjTelefone={impressaoCupomTroca.cnpjTelefone}
          enderecoLoja={impressaoCupomTroca.enderecoLoja}
        />
        <Vendas
          numeroOperacao={impressaoCupomTroca.numeroOperacao}
          dataEmissao={impressaoCupomTroca.dataEmissao}
          status={impressaoCupomTroca.status}
          identificaoOperacao={impressaoCupomTroca.identificacaoTipoOperacao}
        />
        <OutrasInformacoes
          usuarioNome={impressaoCupomTroca.usuarioNome}
          vendedorNome={impressaoCupomTroca.vendedorNome}
        />
      </Flex>
    </Box>
  );
};

export default forwardRef(ImpressaoCupomTroca);
