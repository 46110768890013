import {
  ModalContent,
  ModalBody,
  ModalFooter,
  Flex,
  Heading,
  Text,
  ModalProps,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { create, InstanceProps } from 'react-modal-promise';

import auth from 'modules/auth';
import ConstanteFuncionalidades from 'constants/permissoes';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { ErroAvisoIcon } from 'icons';

type ModalErroInativarEstoqueProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalProps> & {
    callback?: (value: boolean) => Promise<void>;
  };

export const ModalErroInativarEstoque = create<
  ModalErroInativarEstoqueProps,
  ModalProps
>(
  ({
    callback,
    onReject,
    onResolve,
    ...rest
  }: ModalErroInativarEstoqueProps) => {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
    const permissaoTransferenciaEstoqueTransferirEntreEstoques = auth.possuiPermissao(
      ConstanteFuncionalidades.TRANSFERENCIA_ESTOQUE_TRANSFERIR_ENTRE_ESTOQUES
    ).permitido;

    return (
      <ModalPadraoChakra
        onClose={() => {
          onClose();
          if (callback) {
            callback(false);
          }
        }}
        {...rest}
        isOpen={isOpen}
        isCentered
        size="2xl"
        autoFocus={false}
      >
        <ModalContent
          marginBottom={{ base: 0, sm: '3.75rem' }}
          marginTop={{ base: 0, sm: '3.75rem' }}
          h="unset"
          bg="gray.50"
          maxW="600px"
        >
          <ModalBody p={0} h="100%">
            <Flex
              justifyContent="center"
              h="150px"
              bg="red.500"
              borderTopRadius="5px"
              alignItems="center"
            >
              <ErroAvisoIcon color="white" width="70px" height="70px" />
            </Flex>
            <Flex flexDirection="column" align="stretch" h="100%" px={10}>
              <Flex
                justifyContent="flex-start"
                flexDirection="column"
                bg="gray.50"
                pt={7}
                gap="24px"
                borderRadius="5px"
              >
                <Heading
                  as="h2"
                  fontSize="2xl"
                  color="red.500"
                  fontWeight="semibold"
                  textAlign="initial"
                >
                  Não é possível
                </Heading>
                <Text fontSize="md" letterSpacing="0px">
                  Não é permitido inativar um local de estoque que contenha
                  itens com saldo. Se preferir inativar, será necessário
                  primeiro transferir todos os itens desse para um novo local de
                  estoque.
                </Text>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter py="32px">
            <Flex
              w="100%"
              alignItems="center"
              justifyContent="center"
              gap="32px"
            >
              <Button
                id="cancelModal"
                variant="outlineDefault"
                color="gray.400"
                borderColor="gray.400"
                px="20px"
                onClick={() => {
                  onClose();
                  if (callback) {
                    callback(false);
                  }
                }}
              >
                Cancelar
              </Button>
              <Button
                id="transferirButton"
                colorScheme="red"
                px="24px"
                variant="solid"
                onClick={() => {
                  onClose();
                  if (callback) {
                    if (permissaoTransferenciaEstoqueTransferirEntreEstoques) {
                      callback(true);
                    } else {
                      toast.error(
                        'Você não tem permissão para realizar uma transferência de estoque.'
                      );
                      callback(false);
                    }
                  }
                }}
              >
                Transferir estoque
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
