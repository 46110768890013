import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import { LupaIcon } from 'icons';

export const Container = styled.div`
  color: var(--white);
`;

export const Icon = styled(LupaIcon)`
  position: absolute;

  color: var(--sti-ck-colors-gray-700);
  font-size: 1.375rem;

  margin-top: 9px;
  margin-left: 20px;
`;

export const Input = styled(Form.Control)`
  padding-left: 53px;

  font-size: 0.9375rem;
`;
