import { StyleFunctionProps } from '@chakra-ui/theme-tools';

type AccessibleColor = {
  bg?: string;
  hoverBg?: string;
  color?: string;
};

/** Accessible color overrides for less accessible colors. */
const accessibleColorMap: { [key: string]: AccessibleColor } = {
  white: {
    bg: 'white',
    hoverBg: 'white',
    color: 'black',
  },
  blue: {
    bg: 'blue.200',
    hoverBg: 'blue.200',
    color: 'blue.800',
  },
  red: { hoverBg: 'red.100' },
};

const Textarea = {
  baseStyle: {
    borderRadius: 'base',
  },

  variants: {
    outline: {
      bg: 'white',
      _disabled: {
        background: 'gray.100',
        borderColor: 'gray.100',
        color: 'gray.700',
        opacity: 1,
      },
      _invalid: { borderColor: 'inherit', boxShadow: 'none' },
    },
    'pill-outline': () => {
      return {
        bg: 'white',
        borderRadius: 'full',
      };
    },
    filled: (props: StyleFunctionProps) => {
      const {
        color = `${props.colorScheme}.500`,
        bg = `${props.colorScheme}.100`,
        hoverBg = `${props.colorScheme}.200`,
      } = accessibleColorMap[props.colorScheme ?? 'white'] || {};

      return {
        color,
        bg,
        _hover: { bg: hoverBg },
        _focus: { bg },
        _disabled: {
          opacity: 0.9,
          cursor: 'not-allowed',
        },
      };
    },
  },

  sizes: {
    sm: {
      borderRadius: 'md',
    },
    md: {
      h: 9,
    },
  },

  defaultProps: {
    size: 'md',
    variant: 'outline',
    focusBorderColor: 'primary.500',
  },
};

export default Textarea;
