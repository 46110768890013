import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export type FormData = {
  transportadora: { value: string; label: string } | null;
  finalidade: number;
  dataHoraEntrada: Date;
  observacao: string;
  numeroNFe: string;
  serieNFe: number;
  chaveAcessoNFe: string;
  dataHoraNFe?: Date;
};

const schema = yup.object().shape({
  finalidade: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  chaveAcessoNFe: yup.string().matches(/.{44,}/, {
    excludeEmptyString: true,
    message: ConstanteMensagemValidacao.MIN_LENGTH_44,
  }),
});

export const yupResolver = yupResolverInstance(schema);
