import React, { useState } from 'react';

import {
  ModalContent,
  ModalBody,
  Flex,
  Button,
  Heading,
  Text,
  AlertDialogFooter,
  ScaleFade,
  Box,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { AtencaoAvisoIcon } from 'icons';
import { create } from 'react-modal-promise';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import ManterFoco from 'components/Geral/ManterFoco';
import { ButtonDefault } from 'components/Button/ButtonChakra';
import i18n from 'i18n';
import { ButtonFechar } from './styles';

const ModalConfirmacaoExcluirConst = ({
  onResolve,
  callback,
  cancelButtonText = i18n.t('Não, cancelar!'),
  confirmButtonText = i18n.t('Sim, apagar!'),
  text = i18n.t('Não será possível desfazer esta ação.'),
  title = i18n.t('Você tem certeza?'),
  closeOnOverlayClick = true,
}: any) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);
  return (
    <ModalPadraoChakra
      onClose={() => {
        setIsOpen(false);
      }}
      closeOnOverlayClick={closeOnOverlayClick}
      isOpen={isOpen}
      isCentered
      size="xl"
      autoFocus={false}
    >
      <ModalContent
        marginBottom={{ base: 0, sm: '3.75rem' }}
        marginTop={{ base: 0, sm: '3.75rem' }}
        h="unset"
        maxW="600px"
      >
        <ManterFoco
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <ScaleFade initialScale={1.5} in={isOpen}>
            <ModalBody p={0} h="100%">
              <Flex flexDirection="column" align="stretch" h="100%">
                <Flex
                  justifyContent="center"
                  h="150px"
                  bg="orange.400"
                  style={{
                    borderTopLeftRadius: '5px',
                    borderTopRightRadius: '5px',
                  }}
                >
                  <AtencaoAvisoIcon
                    style={{
                      fontSize: '75px',
                      fontWeight: 'bolder',
                      marginTop: '35px',
                    }}
                  />
                </Flex>

                <Flex
                  justifyContent="space-between"
                  flexDirection="column"
                  bg="gray.50"
                  px={8}
                  py={7}
                  minHeight="225px"
                  style={{
                    borderBottomLeftRadius: '5px',
                    borderBottomRightRadius: '5px',
                  }}
                >
                  <Heading
                    as="h2"
                    fontSize="2xl"
                    color="orange.500"
                    fontWeight="semibold"
                    textAlign="initial"
                  >
                    {t(title)}
                  </Heading>

                  <Text
                    mt={15}
                    fontSize="14px"
                    color="gray.700"
                    id="conteudoModal"
                  >
                    {text
                      ? t(text)
                      : t(
                          'Os dados referentes a este registro serão excluídos.'
                        )}
                  </Text>

                  <Flex
                    pb="0"
                    justifyContent="center"
                    alignItems="center"
                    px="0"
                    w="full"
                  >
                    <Box>
                      <ButtonFechar
                        id="cancelModal"
                        variant="outlineDefault"
                        color="gray.300"
                        border="1px"
                        borderColor="gray.200"
                        onClick={() => {
                          setIsOpen(false);
                          if (callback) {
                            callback(false);
                          }
                          onResolve({ sucesso: false });
                        }}
                      >
                        {cancelButtonText}
                      </ButtonFechar>
                    </Box>
                    <Box>
                      <ButtonDefault
                        id="confirmModal"
                        bg="orange.400"
                        _hover={{
                          bg: 'orange.200',
                        }}
                        minWidth="auto"
                        _active={{
                          bg: 'orange.300',
                        }}
                        onClick={() => {
                          setIsOpen(false);
                          if (callback) {
                            callback(true);
                          }
                          onResolve({ sucesso: true });
                        }}
                        paddingRight="4"
                        paddingLeft="4"
                      >
                        {confirmButtonText}
                      </ButtonDefault>
                    </Box>
                  </Flex>
                </Flex>
              </Flex>
            </ModalBody>
          </ScaleFade>
        </ManterFoco>
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export const ModalConfirmacaoExcluir = create(ModalConfirmacaoExcluirConst);
