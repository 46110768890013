import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';

import { FiscalProps } from 'pages/Produtos/Formulario/validationForm';

export const getCadastroFiscalProdutos = async (idProduto: string) => {
  const response = await api.get<void, ResponseApi<FiscalProps>>(
    `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/fiscais`
  );

  return response;
};
