import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import isPrenvent from 'helpers/layout/isPrenvent';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import useReloadRegistration from 'helpers/layout/useReloadRegistration';

import { ContainerListagem } from 'components/Layout/Listagem/containerListagem';

import FormCampoPersonalizado from '..';
import { useForm, yupResolver } from '../validationForm';

const Cadastrar = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);

  const history = useHistory();
  const isMountedRef = useIsMountedRef();

  const formMethods = useForm({
    resolver: yupResolver,
    shouldFocusError: true,
    defaultValues: { ativo: true, pagina: '', nome: '', instrucao: false },
  });
  const { handleSubmit, reset, getValues, formState, setFocus } = formMethods;

  const historyReload = useReloadRegistration({ setFormIsDirty });

  isPrenvent(formIsDirty);

  async function handlePostCampoPersonalizado() {
    const data = getValues();

    const response = await api.post<void, ResponseApi>(
      ConstanteEnderecoWebservice.CAMPO_PERSONALIZADO_CADASTRAR,
      data
    );

    if (response) {
      if (response.sucesso) {
        return true;
      }

      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }
    }

    return false;
  }

  const onSubmit = handleSubmit(async () => {
    setIsLoading(true);

    const success = await handlePostCampoPersonalizado();

    if (success) {
      toast.success('O cadastro foi realizado com sucesso.');

      setFormIsDirty(false);

      history.push(ConstanteRotas.CAMPO_PERSONALIZADO);
    }

    setIsLoading(false);
  });

  const onSubmitReset = handleSubmit(async () => {
    setIsLoading(true);

    const success = await handlePostCampoPersonalizado();

    if (success) {
      toast.success('O cadastro foi realizado com sucesso.');
      if (isMountedRef.current) setFocus('nome');

      historyReload(ConstanteRotas.CAMPO_PERSONALIZADO_CADASTRAR);
    }

    setIsLoading(false);
  });

  useEffect(() => {
    reset(
      {
        pagina: '',
      },
      { keepValues: true }
    );
  }, [reset]);

  useEffect(() => {
    setFormIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  return (
    <ContainerListagem
      formMethods={formMethods}
      isLoading={isLoading}
      onSubmit={onSubmit}
      onSubmitReset={onSubmitReset}
      formIsDirty={formIsDirty}
      maxWidth="full"
    >
      <FormCampoPersonalizado />
    </ContainerListagem>
  );
};

export default Cadastrar;
