import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  ReactNode,
  MutableRefObject,
  useRef,
} from 'react';

type FiltrosType = {
  [x: string]: any;
};
interface VendasContextProps {
  contextFiltros?: FiltrosType;
  setContextFiltros: Dispatch<SetStateAction<FiltrosType | undefined>>;
  carregarDataContextRef: MutableRefObject<boolean>;
}

const VendasFiltrosContext = createContext<VendasContextProps>(
  {} as VendasContextProps
);

interface VendasFiltroProviderProps {
  children: ReactNode;
}

export default function VendasFiltroProvider({
  children,
}: VendasFiltroProviderProps): JSX.Element {
  const [contextFiltros, setContextFiltros] = useState<FiltrosType>();
  const carregarDataContextRef = useRef(true);

  return (
    <VendasFiltrosContext.Provider
      value={{
        contextFiltros,
        setContextFiltros,
        carregarDataContextRef,
      }}
    >
      {children}
    </VendasFiltrosContext.Provider>
  );
}

export function useVendasFiltroContext(): VendasContextProps {
  const context = useContext(VendasFiltrosContext);

  if (!context)
    throw new Error(
      'useLojaFiltroContext must be used within a LojaFiltroProvider.'
    );

  return context;
}
