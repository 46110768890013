import React, { useState } from 'react';
import {
  useDisclosure,
  useMediaQuery,
  ModalProps,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Button,
  Icon,
  ModalFooter,
  HStack,
  ModalHeader,
  VStack,
  Text,
  Tag,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';

import { SalvarConfirmarIcon } from 'icons';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import Textarea from 'components/PDV/Textarea';

import { yupResolver } from './validationForm';

export const baseLegalTags = [
  '<<ALIQUOTA ICMS>>',
  '<<BASE DE CALCULO ICMS>>',
  '<<VALOR ICMS>>',
  '<<PERMITE O APROVEITAMENTO DO CRÉDITO DE ICMS NO VALOR DE R$<<VALOR ICMS>> ; CORRESPONDENTE À ALÍQUOTA DE <<ALIQUOTA ICMS>> %, NOS TERMOS DO ART. 23 DA LC 123/2006>>',
];

type FormData = { descricao: string };

interface ModalAdicionarBaseLegalProps
  extends InstanceProps<FormData>,
    Omit<ModalProps, 'children' | 'isOpen' | 'onClose'> {
  defaultValues?: FormData;
}

const ModalAdicionarBaseLegal = ({
  onResolve,
  defaultValues,
  ...props
}: ModalAdicionarBaseLegalProps) => {
  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues,
  });

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = formMethods.handleSubmit(({ descricao }) => {
    setIsLoading(true);

    onResolve({ descricao });
  });

  function handleAddTag(tag: string) {
    const descricao = formMethods.getValues('descricao');

    const newDescricao = `${descricao} ${tag} `;

    formMethods.setValue('descricao', newDescricao);
  }

  const TagBaseLegal = ({ tag }: { tag: string }) => {
    return (
      <Tag
        variant="solid"
        colorScheme="blue"
        size="sm"
        borderRadius="full"
        cursor="pointer"
        onClick={() => handleAddTag(tag)}
      >
        {tag.length > 70 ? `${tag.substring(0, 70)}...` : tag}
      </Tag>
    );
  };
  return (
    <ModalPadraoChakra
      isCentered
      size={isLargerThan900 ? 'xl' : 'full'}
      {...props}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalContent
        marginBottom={{ base: 0, md: '3.75rem' }}
        marginTop={{ base: 0, md: '3.75rem' }}
        h="unset"
        maxW={{ base: '100%', md: '600px' }}
      >
        {isLoading && <LoadingPadrao />}
        <ModalHeader
          mt={isLargerThan900 ? undefined : 12}
          mb={isLargerThan900 ? undefined : 8}
          borderBottom="1px"
          borderColor="gray.100"
          px="0"
          mx={{ base: 6, md: 8 }}
        >
          <VStack alignItems="flex-start" spacing="1" lineHeight="1">
            <Text color="primary.500" fontSize={{ base: 'xl', md: 'md' }}>
              Base legal
            </Text>
            <Text color="gray.400" fontSize={{ base: 'sm', md: 'xs' }}>
              Para adicionar uma variável clique sobre a TAG com o nome da
              variável.
            </Text>
          </VStack>
        </ModalHeader>
        <ModalCloseButton
          mt={isLargerThan900 ? undefined : 8}
          mr={isLargerThan900 ? undefined : 6}
        />
        <ModalBody p={{ base: 6, md: 8 }} pt={{ base: 8, md: 10 }}>
          <FormProvider {...formMethods}>
            <VStack spacing="1">
              <Textarea
                id="descricao"
                name="descricao"
                label="Descrição"
                placeholder="Informe a descrição da base legal"
                size="sm"
                maxLength={255}
                isRequired
              />
              <HStack justifyContent="flex-start" w="full">
                <TagBaseLegal tag={baseLegalTags[0]} />
                <TagBaseLegal tag={baseLegalTags[1]} />
                <TagBaseLegal tag={baseLegalTags[2]} />
              </HStack>
              <HStack justifyContent="flex-start" w="full">
                <TagBaseLegal tag={baseLegalTags[3]} />
              </HStack>
            </VStack>
          </FormProvider>
        </ModalBody>
        <ModalFooter
          borderTop="1px"
          borderColor="gray.100"
          px="0"
          mx={{ base: 6, md: 8 }}
        >
          <HStack spacing={6}>
            <Button
              variant="outline"
              size="sm"
              borderRadius="md"
              minWidth="100px"
              color="gray.400"
              onClick={onClose}
            >
              Fechar
            </Button>

            <Button
              type="submit"
              colorScheme="aquamarine"
              size="sm"
              minWidth="130px"
              borderRadius="md"
              leftIcon={<Icon as={SalvarConfirmarIcon} fontSize="lg" />}
              onClick={handleSubmit}
            >
              Salvar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export default create<ModalAdicionarBaseLegalProps>(ModalAdicionarBaseLegal);
