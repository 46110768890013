import React from 'react';
import { Flex, useMediaQuery } from '@chakra-ui/react';

import { useEntradaMercadoriaEtapasContext } from 'store/EntradaMercadoria/EntradaMercadoriaEtapas';

import { Steps } from 'components/update/Steps';
import { SimpleCard } from 'components/update/Form/SimpleCard';

import { EscolherFornecedor } from '../../EtapasCompartilhadas/EscolherFornecedor';
import { VincularProdutos } from './VincularProdutos';
import { DefinirValores } from '../../EtapasCompartilhadas/DefinirValores';
import { LancamentoPagamentos } from '../../EtapasCompartilhadas/LancamentoPagamentos';
import { InformacoesGerais } from '../../EtapasCompartilhadas/InformacoesGerais';

export function ContinuarEntradaImportacao() {
  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

  const { activeStep } = useEntradaMercadoriaEtapasContext();

  return (
    <SimpleCard
      p={0}
      bg="gray.50"
      boxShadow="0px 0px 6px #00000034"
      position="relative"
    >
      <Flex
        w="full"
        flexDir="column"
        alignItems="center"
        pt={{ base: 4, sm: 6, md: 8 }}
      >
        <Steps
          w="100%"
          showLabel={isLargerThan700}
          maxW={{ base: '300px', sm: '500px', lg: '900px' }}
          colorScheme="primary"
          activeStep={activeStep}
          steps={[
            {
              label: 'Fornecedor',
              content: <EscolherFornecedor isImportacao />,
            },
            {
              label: 'Produtos',
              content: <VincularProdutos />,
            },
            {
              label: 'Valores',
              content: <DefinirValores isImportacao />,
            },
            {
              label: 'Pagamento',
              content: <LancamentoPagamentos isImportacao />,
            },
            {
              label: 'Informações',
              content: <InformacoesGerais isImportacao />,
            },
          ]}
        />
      </Flex>
    </SimpleCard>
  );
}
