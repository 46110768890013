import { Helmet, HelmetProvider } from 'react-helmet-async';

import TipoSistemaEnum from 'constants/enum/tipoSistema';
import auth from 'modules/auth';

export const HeaderSistema = () => {
  const { nomeExibicao, value: valueSistema } = auth.getSistema();

  let linkIcone = '';
  let themeColor = '';
  let manifest = '';

  switch (valueSistema) {
    case TipoSistemaEnum.ZENDAR:
      linkIcone = TipoSistemaEnum.properties[1].icone;
      themeColor = TipoSistemaEnum.properties[1].theme;
      manifest = TipoSistemaEnum.properties[1].manifest;
      break;
    case TipoSistemaEnum.POWERSTOCK:
      linkIcone = TipoSistemaEnum.properties[2].icone;
      themeColor = TipoSistemaEnum.properties[1].theme;
      manifest = TipoSistemaEnum.properties[2].manifest;
      break;
    case TipoSistemaEnum.FOMER:
      linkIcone = TipoSistemaEnum.properties[3].icone;
      themeColor = TipoSistemaEnum.properties[3].theme;
      manifest = TipoSistemaEnum.properties[3].manifest;
      break;

    case TipoSistemaEnum.FOMER_SISTEMA:
      linkIcone = TipoSistemaEnum.properties[4].icone;
      themeColor = TipoSistemaEnum.properties[4].theme;
      manifest = TipoSistemaEnum.properties[4].manifest;
      break;

    case TipoSistemaEnum.POWERCHEF:
      linkIcone = TipoSistemaEnum.properties[5].icone;
      themeColor = TipoSistemaEnum.properties[5].theme;
      manifest = TipoSistemaEnum.properties[5].manifest;
      break;

    default:
      linkIcone = '';
      themeColor = '';
      manifest = '';
  }

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="theme-color" content={themeColor} />
        <meta name="description" content={nomeExibicao} />

        <title>{nomeExibicao}</title>

        <link rel="apple-touch-icon" href={linkIcone} />
        <link rel="icon" href={linkIcone} />
        <link rel="manifest" href={manifest} />
      </Helmet>
    </HelmetProvider>
  );
};
