import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react';
import { Form, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { UseFormRegister } from 'react-hook-form';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import handleGetCidadeLojaLogada from 'helpers/api/getCidadeLojaLogada';

import { SalvarConfirmarIcon } from 'icons';
import ButtonPadrao from 'components/Button/ButtonPadrao';
import EnderecoCobranca from 'components/EnderecoCobranca';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';

import { Modal } from './styles';
import ModalPadrao from '../ModalPadrao';

const schemaCidade = yup.object().shape({
  cidadeModal: yup
    .string()
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

const schemaPais = yup.object().shape({
  paisModal: yup
    .string()
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

const schemaCep = yup.object().shape({
  cepModal: yup
    .string()
    .nullable()
    .notRequired()
    .test('cepModal', ConstanteMensagemValidacao.CEP_INVALIDO, (value) => {
      if (value) {
        const testSchema = yup.string().min(9).max(9);

        return testSchema.isValidSync(value);
      }

      return true;
    }),
});

interface ModalEnderecoProps {
  show: boolean;
  onHide: (willReset?: boolean) => void;
  onSubmit: () => void;
  register: UseFormRegister<Record<string, any>>;
  control: any;
  // errors: FieldErrors;
  getValues: any;
  setValues: any;
  readonly: any;
  indexEndereco: number;
  cadastroPdv?: boolean;
}

const ModalEndereco: React.FC<ModalEnderecoProps> = ({
  show,
  onHide,
  onSubmit,
  register,
  control,
  // errors,
  getValues,
  setValues,
  readonly,
  indexEndereco,
  cadastroPdv,
}) => {
  const { t } = useTranslation();

  const refEnderecoCobranca = useRef<any>();

  const [errors, setErrors] = useState<any>({});

  const handleSalvar = useCallback(async () => {
    const dataBkp = getValues();

    const formCidade = {
      cidadeModal: dataBkp.cidadeModal,
    };

    const formPais = {
      paisModal: dataBkp.paisModal,
    };

    const formCep = {
      cepModal: dataBkp.cepModal,
    };

    setErrors({
      cidadeModal: {
        message: !schemaCidade.isValidSync(formCidade)
          ? ConstanteMensagemValidacao.CAMPO_OBRIGATORIO
          : '',
      },
      cepModal: {
        message: !schemaCep.isValidSync(formCep)
          ? ConstanteMensagemValidacao.CEP_INVALIDO
          : '',
      },
      paisModal: {
        message: !schemaPais.isValidSync(formPais)
          ? ConstanteMensagemValidacao.CAMPO_OBRIGATORIO
          : '',
      },
    });

    if (
      !schemaCidade.isValidSync(formCidade) ||
      !schemaCep.isValidSync(formCep) ||
      !schemaPais.isValidSync(formPais)
    ) {
      return;
    }

    if (dataBkp.enderecosAdicionais?.length > 0) {
      const enderecoRemover = dataBkp.enderecosAdicionais[indexEndereco];

      setValues(
        'enderecosAdicionais',
        dataBkp.enderecosAdicionais.filter(
          (obj: any) => obj !== enderecoRemover
        )
      );

      const data = getValues();

      const cobranca = data.enderecosAdicionais.find(
        (element: any) => element.cobranca === true
      );
      const entrega = data.enderecosAdicionais.find(
        (element: any) => element.entrega === true
      );

      if (data.cobrancaModal.selecionado && cobranca !== undefined) {
        setValues(
          'enderecosAdicionais',
          data.enderecosAdicionais.map((endereco: any) => {
            if (endereco.cobranca) {
              // eslint-disable-next-line no-param-reassign
              endereco.cobranca = false;
            }
            return endereco;
          })
        );
      }

      if (data.entregaModal.selecionado && entrega !== undefined) {
        setValues(
          'enderecosAdicionais',
          data.enderecosAdicionais.map((endereco: any) => {
            if (endereco.entrega) {
              // eslint-disable-next-line no-param-reassign
              endereco.entrega = false;
            }
            return endereco;
          })
        );
      }
    }

    onSubmit();
    setValues('cepModal', '');
    setValues('logradouroModal', '');
    setValues('numeroModal', '');
    setValues('complementoModal', '');
    setValues('bairroModal', '');
    setValues('cidadeModal', '');
    setValues('estadoModal', '');
    setValues('paisModal', '');
    setValues('cobrancaModal', { id: 'cobranca', selecionado: false });
    setValues('entregaModal', { id: 'entrega', selecionado: false });
  }, [getValues, indexEndereco, onSubmit, setValues]);

  const FooterButtons = () => (
    <>
      <ButtonPadrao
        id="fechar"
        name="fechar"
        text={t('Fechar')}
        type="button"
        variant="outline-secondary"
        onClick={() => {
          onHide(true);

          setErrors({
            cidadeModal: { message: '' },
            estadoModal: { message: '' },
            paisModal: { message: '' },
          });
        }}
        style={{ height: '35px', width: '100%', maxWidth: '100px' }}
      />
      <ButtonPadrao
        id="salvar-endereco"
        name="salvar-endereco"
        text={t('Salvar')}
        icon={SalvarConfirmarIcon}
        type="button"
        variant="success"
        onClick={handleSalvar}
        style={{ height: '35px', width: '100%', maxWidth: '150px' }}
      />
    </>
  );

  const latestProps = useRef({ indexEndereco });
  useEffect(() => {
    latestProps.current = { indexEndereco };
  });

  useEffect(() => {
    const getCidadeLojaLogada = async () => {
      if (latestProps.current.indexEndereco < 0) {
        const cidadeLoja = await handleGetCidadeLojaLogada();

        await refEnderecoCobranca?.current?.setCidade(
          cidadeLoja?.id,
          cidadeLoja?.codigoIbge
        );
      }
    };

    if (show) {
      getCidadeLojaLogada();
    }
  }, [show]);

  return cadastroPdv ? (
    <ModalPadraoChakra isOpen={show} size="3xl" onClose={onHide}>
      <ModalContent>
        <ModalHeader>{t('Adicionar Endereço')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Container>
            <Form.Row>
              <EnderecoCobranca
                errors={errors}
                register={register}
                control={control}
                readonly={readonly}
                setValue={setValues}
                getValue={getValues}
                cepCampoId="cepModal"
                logradouroCampoId="logradouroModal"
                numeroCampoId="numeroModal"
                complementoCampoId="complementoModal"
                bairroCampoId="bairroModal"
                cidadeCampoId="cidadeModal"
                estadoCampoId="estadoModal"
                paisCampoId="paisModal"
                cobrancaCampoId="cobrancaModal"
                entregaCampoId="entregaModal"
                indexEndereco={indexEndereco}
                ref={refEnderecoCobranca}
              />
            </Form.Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <FooterButtons />
        </ModalFooter>
      </ModalContent>
    </ModalPadraoChakra>
  ) : (
    <ModalPadrao
      style={{ zIndex: 1430 }}
      isOpen={show}
      handleOnHide={onHide}
      maxWidth="760px"
      title={t('Adicionar Endereço')}
      subtitle={t(
        'É possível definir como endereço principal para entrega e cobrança'
      )}
    >
      <Form>
        <Modal.Body>
          <Container>
            <Form.Row>
              <EnderecoCobranca
                errors={errors}
                register={register}
                control={control}
                readonly={readonly}
                setValue={setValues}
                getValue={getValues}
                cepCampoId="cepModal"
                logradouroCampoId="logradouroModal"
                numeroCampoId="numeroModal"
                complementoCampoId="complementoModal"
                bairroCampoId="bairroModal"
                cidadeCampoId="cidadeModal"
                estadoCampoId="estadoModal"
                paisCampoId="paisModal"
                cobrancaCampoId="cobrancaModal"
                entregaCampoId="entregaModal"
                indexEndereco={indexEndereco}
                ref={refEnderecoCobranca}
              />
            </Form.Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <FooterButtons />
        </Modal.Footer>
      </Form>
    </ModalPadrao>
  );
};

export default ModalEndereco;
