export const ItensImpressao = {
  FICHAS_INDIVIDUAIS: 2,
  CUPOM_RESUMO_COMPRA: 3,
  FICHA_COMPRA: 4,
  NAO_UTILIZAR_IMPRESSAO: 1,

  properties: [
    {
      title: 'Fichas individuais',
      subTitle:
        'Impressão de fichas para cada produto contendo nome, preço, data e observações.',
      isChecked: false,
      value: 2,
    },
    {
      title: 'Cupom com resumo da compra',
      subTitle:
        'Impressão dos produtos adquiridos, forma de pagamento e senha para retirada.',
      isChecked: false,
      value: 3,
    },
    {
      title: 'Fichas + Cupom',
      subTitle: '',
      isChecked: false,
      value: 4,
    },
    {
      title: 'Não utilizar impressão',
      subTitle: '',
      isChecked: false,
      value: 1,
    },
  ],
};
