import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

const finalizarVenda = async (id: string) => {
  const response = await api.put<void, ResponseApi>(
    ConstanteEnderecoWebservice.PEDIDOORCAMENTOVENDA_FINALIZAR_VENDA,
    null,
    { params: { id } }
  );

  if (response?.avisos) {
    response.avisos.map((item: string) => toast.warning(item));
  }

  if (response?.sucesso) {
    return true;
  }

  return false;
};

export default finalizarVenda;
