import React, { useState, useEffect, useRef } from 'react';
import { Grid, useMediaQuery, HStack, Button } from '@chakra-ui/react';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import obterOperacaoParaCompartilhar, {
  OperacaoObterParaCompartilhar,
} from 'helpers/api/Operacao/obterOperacaoParaCompartilhar';
import ConstanteRotasPDV from 'constants/rotasPDV';

import { CompartilharMenuIcon } from 'icons';
import MenuLateral from 'components/PDV/Layout/MenuLateral';
import TabButton from 'components/PDV/Layout/MenuLateral/TabButton';
import Layout from 'components/PDV/Layout';
import CompartilharDocumentosFiscais from 'components/PDV/CompartilharDocumentosFiscais';

type MParams = { id: string };

const Compartilhar = ({
  match: {
    params: { id: operacaoId },
  },
}: RouteComponentProps<MParams>) => {
  const history = useHistory();
  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');

  const [isLoading, setIsLoading] = useState(false);
  const [operacao, setOperacao] = useState<OperacaoObterParaCompartilhar>();

  const handlePushVoltar = () => {
    history.push(ConstanteRotasPDV.PDV_CONSULTAR_OPERACOES);
  };

  const latestProps = useRef({ operacaoId });
  useEffect(() => {
    latestProps.current = { operacaoId };
  });

  useEffect(() => {
    async function handleObterOperacaoParaCompartilhar() {
      setIsLoading(true);

      const newOperacao = await obterOperacaoParaCompartilhar(
        latestProps.current.operacaoId
      );
      setOperacao(newOperacao);
      setIsLoading(false);
    }

    handleObterOperacaoParaCompartilhar();
  }, []);

  return (
    <Layout>
      <Grid
        templateColumns={{
          base: '1fr',
          md: '370px 1fr',
          lg: '450px 1fr',
        }}
        templateRows={{ base: 'auto 1fr', md: '1fr' }}
        h="full"
      >
        <MenuLateral
          isLoading={isLoading}
          operacao={
            operacao
              ? {
                  identificacaoTipoOperacao: operacao.identificacaoTipoOperacao,
                  nomeCliente: operacao.cliente.nome,
                  numeroOperacao: operacao.numeroOperacao,
                  valorTotal: operacao.valorTotal,
                  NFCe: operacao.NFCe
                    ? {
                        descricao: operacao.NFCe.descricao || '',
                        status: operacao.NFCe.status,
                      }
                    : undefined,
                  NFe: operacao.NFe
                    ? {
                        descricao: operacao.NFe.descricao || '',
                        status: operacao.NFe.status,
                      }
                    : undefined,
                }
              : undefined
          }
        >
          <TabButton
            icon={CompartilharMenuIcon}
            title="Compartilhar"
            subtitle="Salvar, imprimir ou enviar"
            isFirst
            isActive
          />
        </MenuLateral>
        <CompartilharDocumentosFiscais
          asAccordion={isSmallerThan900}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          tipoOperacao={operacao?.identificacaoTipoOperacao}
          operacao={
            operacao && operacaoId
              ? {
                  id: operacaoId,
                  numeroOperacao: operacao.numeroOperacao,
                  cliente: {
                    nome: operacao.cliente.nome,
                    email: operacao.cliente.email,
                    celular: operacao.cliente.celular,
                    telefone: operacao.cliente.telefone,
                    contatosAdicionais: operacao.cliente.contatosAdicionais,
                  },
                  NFCe: operacao.NFCe
                    ? {
                        id: operacao.NFCe.id,
                        numero: operacao.NFCe.numero,
                        status: operacao.NFCe.status,
                      }
                    : undefined,
                  NFe: operacao.NFe
                    ? {
                        id: operacao.NFe.id,
                        numero: operacao.NFe.numero,
                        status: operacao.NFe.status,
                      }
                    : undefined,
                }
              : undefined
          }
        >
          <HStack justifyContent="center" spacing={6} mt={10}>
            <Button
              size="sm"
              minW="120px"
              variant="outline"
              onClick={handlePushVoltar}
            >
              Voltar
            </Button>
          </HStack>
        </CompartilharDocumentosFiscais>
      </Grid>
    </Layout>
  );
};

export default Compartilhar;
