import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import UncontrolledForm from '..';
import useCustomReportForm from '../hooks';
import { FormData, defaultValues, yupResolver } from '../validationForm';

const RelatorioPersonalizadoCadastrar = () => {
  const {
    salvarRelatorioPersonalizado,
    redirecionarRelatorioPersonalizadoListar,
  } = useCustomReportForm();

  const formMethods = useForm<FormData>({
    defaultValues,
    resolver: yupResolver,
  });
  const { handleSubmit, reset } = formMethods;

  const [isLoading, setIsLoading] = useState(false);

  const handleSalvar = async (data: FormData) => {
    setIsLoading(true);

    const { success } = await salvarRelatorioPersonalizado(data);

    if (success) {
      toast.success('Relatório personalizado cadastrado com sucesso');
      redirecionarRelatorioPersonalizadoListar();
    }

    setIsLoading(false);
  };

  const handleSalvarInserirNovo = async (data: FormData) => {
    setIsLoading(true);

    const { success } = await salvarRelatorioPersonalizado(data);

    if (success) {
      toast.success('Relatório personalizado cadastrado com sucesso');
      reset();
    }

    setIsLoading(false);
  };

  return (
    <FormProvider {...formMethods}>
      <UncontrolledForm
        isLoading={isLoading}
        handleSalvar={handleSubmit(handleSalvar)}
        handleSalvarInserirNovo={handleSubmit(handleSalvarInserirNovo)}
      />
    </FormProvider>
  );
};

export default RelatorioPersonalizadoCadastrar;
