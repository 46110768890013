import React, { ReactNode } from 'react';
import { Stack, StackProps } from '@chakra-ui/react';

interface FooterProps extends StackProps {
  children: ReactNode;
}

export function Footer({ children, ...rest }: FooterProps) {
  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      spacing={{ base: 2, sm: 4, md: 8 }}
      borderTop="1px"
      borderColor="gray.100"
      justifyContent="flex-end"
      py={{ base: 7, md: 6 }}
      {...rest}
    >
      {children}
    </Stack>
  );
}
