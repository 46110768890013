import React from 'react';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

import TransferenciaEstoqueListar from '../pages/TransferenciaEstoque/Listar';
import TransferenciaEstoqueCadastrar from '../pages/TransferenciaEstoque/Formulario/Cadastrar';
import TransferenciaEstoqueAlterar from '../pages/TransferenciaEstoque/Formulario/Alterar';
import TransferenciaEstoqueVisualizar from '../pages/TransferenciaEstoque/Formulario/Visualizar';

export const TransferenciaEstoqueRoutes = [
  <LayoutGuard
    key="transferencia-estoque-listar"
    permissaoFuncionalidade={
      ConstanteFuncionalidades.TRANSFERENCIA_ESTOQUE_LISTAR
    }
    component={TransferenciaEstoqueListar}
    breadcrumb={[
      {
        title: 'Estoque',
      },
      {
        title: 'Transferência de Estoque',
      },
    ]}
    path={ConstanteRotas.TRANSFERENCIA_ESTOQUE}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="transferencia-estoque-cadastrar"
    permissaoFuncionalidade={
      ConstanteFuncionalidades.TRANSFERENCIA_ESTOQUE_CADASTRAR
    }
    component={TransferenciaEstoqueCadastrar}
    breadcrumb={[
      {
        title: 'Estoque',
      },
      {
        title: 'Transferência de Estoque',
        path: ConstanteRotas.TRANSFERENCIA_ESTOQUE,
      },
      {
        title: 'Novo',
      },
    ]}
    path={ConstanteRotas.TRANSFERENCIA_ESTOQUE_CADASTRAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="transferencia-estoque-alterar"
    permissaoFuncionalidade={
      ConstanteFuncionalidades.TRANSFERENCIA_ESTOQUE_ALTERAR
    }
    component={TransferenciaEstoqueAlterar}
    breadcrumb={[
      {
        title: 'Estoque',
      },
      {
        title: 'Transferência de Estoque',
        path: ConstanteRotas.TRANSFERENCIA_ESTOQUE,
      },
      {
        title: 'Editar',
      },
    ]}
    path={ConstanteRotas.TRANSFERENCIA_ESTOQUE_ALTERAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="transferencia-estoque-visualizar"
    permissaoFuncionalidade={
      ConstanteFuncionalidades.TRANSFERENCIA_ESTOQUE_VISUALIZAR
    }
    component={TransferenciaEstoqueVisualizar}
    breadcrumb={[
      {
        title: 'Estoque',
      },
      {
        title: 'Transferência de Estoque',
        path: ConstanteRotas.TRANSFERENCIA_ESTOQUE,
      },
      {
        title: 'Visualizar',
      },
    ]}
    path={ConstanteRotas.TRANSFERENCIA_ESTOQUE_VISUALIZAR}
    exact
    meta={{ auth: true }}
  />,
];
