import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import OptionType from 'types/optionType';

type Cor = {
  value: string;
  label: string;
};

type Tamanho = {
  value: string;
  label: string;
};

export type TamanhoQuantidade = {
  tamanho: Tamanho;
  quantidade: number;
};

export interface TamanhoProps extends Tamanho {
  quantidade: number;
}

export type ListaProdutoCorProps = {
  tamanho: TamanhoProps[];
  quantidade: number;
  cor: Cor | null;
};

export type ProdutoOptionProps = {
  id: string;
  nome: string;
  referencia: string;
  tipoProduto: number;
  volumeUnitario: boolean;
  coresOptions: OptionType<string>[];
  tamanhosOptions: OptionType<string>[];
};

export type FormData = {
  produto: OptionType<ProdutoOptionProps>;
  cor: Cor | null;
  listaTamanhoIdQuantidade?: TamanhoQuantidade[];
  valorUnitario: number;
  ipi: number;
  icmsSt: number;
  fcpSt: number;
  custoAdicional: number;
};

const selectShape = {
  value: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(null),
  label: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(null),
};

const schema = yup.object().shape({
  produto: yup
    .object()
    .shape({
      value: yup
        .object()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .default(null),
      label: yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .default(null),
    })
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(null),
  cor: yup
    .object()
    .nullable()
    .when('produto', {
      is: (produto) =>
        produto &&
        produto.value.coresOptions &&
        produto.value.coresOptions.length > 0,
      then: yup
        .object()
        .shape(selectShape)
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
      otherwise: yup.object().shape(selectShape).nullable().default(null),
    }),
  listaTamanhoIdQuantidade: yup.array().when('produto', {
    is: (produto) =>
      produto &&
      produto.value.tamanhosOptions &&
      produto.value.tamanhosOptions.length > 0,
    then: yup
      .array()
      .of(
        yup.object().shape({
          tamanho: yup
            .object()
            .shape(selectShape)
            .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
            .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
          quantidade: yup
            .number()
            .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
            .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
        })
      )
      .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
    otherwise: yup
      .array()
      .of(
        yup.object().shape({
          tamanho: yup.object().shape(selectShape).nullable().default(null),
          quantidade: yup
            .number()
            .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
            .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
        })
      )
      .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  }),
  valorUnitario: yup.number().nullable(),
  ipi: yup.number().nullable(),
  icmsSt: yup.number().nullable(),
  fcpSt: yup.number().nullable(),
  custoAdicional: yup.number().nullable(),
});

export const yupResolver = yupResolverInstance(schema);
