import { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import auth from 'modules/auth';
import { sendWhatsAppMessage } from 'services/whatsapp';
import api, { ResponseApi } from 'services/api';

interface CertificateStatus {
  isExpired: boolean;
  daysRemaining: number;
  hasCertificate: boolean;
}
const { id: lojaId } = auth.getLoja();

const useCertificateStatus = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [certificateStatus, setCertificateStatus] = useState<CertificateStatus>(
    {
      isExpired: false,
      daysRemaining: 0,
      hasCertificate: false,
    }
  );
  const [alertSeenAndClosed, setAlertSeenAndClosed] = useState<boolean>(
    sessionStorage.getItem(`certificate-alert-closed_${lojaId}`) === 'true'
  );

  const { daysRemaining, isExpired, hasCertificate } = certificateStatus;

  const oneDayInMs = 1000 * 3600 * 24;
  const daysOrDay = daysRemaining > 1 ? 'dias' : 'dia';
  const textWarning = `Seu certificado digital ${
    isExpired ? 'expirou.' : `vai expirar em ${daysRemaining} ${daysOrDay}.`
  } Garanta a renovação com desconto!`;
  const showWarningCertificate =
    hasCertificate && !alertSeenAndClosed && daysRemaining < 31;

  const sendCertificateMessage = () => {
    sendWhatsAppMessage({
      message:
        'Olá, estou interessado em mais informações sobre o certificado digital',
      phoneNumber: '8892419388',
    });
  };

  const closeAlertAndSaveInLocalStorage = () => {
    setAlertSeenAndClosed(true);
    sessionStorage.setItem(`certificate-alert-closed_${lojaId}`, 'true');
  };

  const updateStatusCertificate = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.LOJA_OBTER_VENCIMENTO_CERTIFICADO
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }

      if (response?.sucesso && response?.dados) {
        const expirationDateForCertificate = new Date(response.dados);
        const currentDate = new Date();

        const totalDaysRemaining = Math.ceil(
          (expirationDateForCertificate.getTime() - currentDate.getTime()) /
            oneDayInMs
        );

        if (totalDaysRemaining < 1) {
          setCertificateStatus({
            isExpired: true,
            daysRemaining: 0,
            hasCertificate: true,
          });
        } else {
          setCertificateStatus({
            isExpired: false,
            daysRemaining: totalDaysRemaining,
            hasCertificate: true,
          });
        }
      } else {
        setCertificateStatus({
          isExpired: false,
          daysRemaining: 0,
          hasCertificate: false,
        });
      }
    }

    setIsLoading(false);
  }, [oneDayInMs]);

  useEffect(() => {
    updateStatusCertificate();
  }, [updateStatusCertificate]);

  return {
    isExpired,
    isLoading,
    showWarningCertificate,
    textWarning,
    closeAlertAndSaveInLocalStorage,
    sendCertificateMessage,
  };
};

export default useCertificateStatus;
