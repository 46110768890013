const TipoFiltroProdutoEstoqueEnum = {
  TODOS: 1,
  POSITIVO: 2,
  NEGATIVO: 3,
  ZERADO: 4,
  ABAIXO_MINIMO: 5,

  properties: {
    1: { name: 'Todos', value: 1 },
    2: { name: 'Estoque positivo', value: 2 },
    3: { name: 'Estoque negativo', value: 3 },
    4: { name: 'Estoque zerado', value: 4 },
    5: { name: 'Estoque abaixo do mínimo', value: 5 },
  },

  produtos: [
    { label: 'Todos', value: 1 },
    { label: 'Estoque positivo', value: 2 },
    { label: 'Estoque negativo', value: 3 },
    { label: 'Estoque zerado', value: 4 },
    { label: 'Estoque abaixo do mínimo', value: 5 },
  ],
};

export default TipoFiltroProdutoEstoqueEnum;
