import { GridItem, Flex, Box, Icon, Text } from '@chakra-ui/react';

import { IconType } from 'icons';

type ItemCompartilharProps = {
  onClick: () => void;
  text: React.ReactNode;
  icon: IconType;
};

export const ItemCompartilhar = ({
  onClick,
  text,
  icon,
}: ItemCompartilharProps) => {
  return (
    <GridItem
      borderRadius="16px"
      w={['full', 'full', '128px']}
      _hover={{
        boxShadow: '0px 0px 4px rgba(85, 2, 178, 0.5)',
      }}
      h="144px"
      cursor="pointer"
      boxShadow="0px 0px 16px #00000029"
      onClick={onClick}
    >
      <Flex
        w="full"
        h="full"
        justifyContent="center"
        p="15px"
        alignItems="center"
      >
        <Box h="70px">
          <Flex w="full" justifyContent="center" alignItems="center">
            <Icon fontSize="40px" color="gray.700" as={icon} />
          </Flex>
          <Flex w="full" pt="5px" justifyContent="center" alignItems="center">
            <Text fontSize="16px" textAlign="center" color="gray.700">
              {text}
            </Text>
          </Flex>
        </Box>
      </Flex>
    </GridItem>
  );
};
