import { useZoopEtapasContext } from 'store/Zoop/ZoopEtapasContext';
import { enumEtapasZoop } from 'constants/enum/enumEtapasZoop';

import { CredenciamentoPendente } from './CredenciamentoPendente';
import { GuiaIntegracao } from './GuiaIntegracao';
import { ContaBancaria } from './ContaBancaria';

export const ZoopEtapas = () => {
  const { activeStep } = useZoopEtapasContext();

  switch (activeStep) {
    case enumEtapasZoop.GUIA_INTEGRAÇÃO:
      return <GuiaIntegracao />;
    case enumEtapasZoop.CONTA_BANCARIA:
      return <ContaBancaria />;
    case enumEtapasZoop.CREDENCIAMENTO_PENDENTE:
      return <CredenciamentoPendente />;
    default:
      return <CredenciamentoPendente />;
  }
};
