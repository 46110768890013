export const enumReferenciaServicoStargate = {
  PLANO_TESTE: 0,
  PLANO_START: 1,
  PLANO_PRO: 2,
  PLANO_PRIME: 3,
  PLANO_CORTESIA: 4,
  PLANO_DEMONSTRACAO: 5,
  INTEGRACAO_TRAY: 6,
  INTEGRACAO_MERCADO_LIVRE: 7,
  INTEGRACAO_SHOPEE: 8,
  INTEGRACAO_NUVEMSHOP: 9,
  USUARIO_LICENCA: 10,
  MODULO_DASHBOARD: 11,
  DISPOSITIVO_PDV: 12,
  DISPOSITIVO_SMART_POS: 13,
  AVULSO_INDIVIDUAL: 14,
  AVULSO_RECORRENTE: 15,
  INTEGRACAO_ZOOP: 99,
  DISPOSITIVO_FRENTE_CAIXA: 16,

  properties: [
    { label: 'Teste', value: 0 },
    { label: 'Start', value: 1 },
    { label: 'Pro', value: 2 },
    { label: 'Prime', value: 3 },
    { label: 'Cortesia', value: 4 },
    { label: 'Demonstração', value: 5 },
    { label: 'Tray', value: 6 },
    { label: 'Dashboard', value: 11 },
    { label: 'Zoop', value: 99 },
    { label: 'Frente caixa', value: 16 },
  ],
};

export default enumReferenciaServicoStargate;
