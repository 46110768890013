import { Flex, BoxProps } from '@chakra-ui/react';

interface CardHeaderProps extends BoxProps {
  children: React.ReactNode;
}

export const CardHeader = ({
  children,
  px = '24px',
  mb = '48px',
  ...rest
}: CardHeaderProps) => {
  return (
    <Flex
      borderTopRadius="6px"
      bg="white"
      px={px}
      h="80px"
      mb={mb}
      alignItems="center"
      justifyContent="space-between"
      {...rest}
    >
      {children}
    </Flex>
  );
};
