import { useCallback, useEffect, useState } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  ModalFooter,
  Box,
  Checkbox,
  ModalHeader,
  useMediaQuery,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { enumIdentificacaoIntegracao } from 'constants/enum/enumIdentificacaoIntegracao';
import { formattedValueCpfCnpj } from 'helpers/format/formattedValueCpfCnpj';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { ButtonDefault } from 'components/Button/ButtonChakra';

type ModalResponseProps = {
  clienteSistemaId: string;
};

export type SelectClienteProps = {
  id: string;
  nome: string;
  isChecked: boolean;
};

type ModalVincularCpfProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalResponseProps> & {
    id: string;
    valueCpf: string;
    atualizarPendencia: () => void;
    obterClienteFornecedorId: (id: string) => void;
  };

export const ModalVincularCpf = create<
  ModalVincularCpfProps,
  ModalResponseProps
>(
  ({
    onResolve,
    onReject,
    atualizarPendencia,
    id,
    obterClienteFornecedorId,
    valueCpf,
    ...rest
  }) => {
    const [listCpf, setListCpf] = useState<SelectClienteProps[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    const isCpfSelecionado = listCpf?.some((item) => item.isChecked);

    const handleSubmit = useCallback(() => {
      setIsLoading(true);
      const itemSelecionado = listCpf.find((item) => item.isChecked);
      obterClienteFornecedorId(itemSelecionado?.id || '');
      atualizarPendencia();
      onClose();
      setIsLoading(false);
    }, [atualizarPendencia, listCpf, onClose, obterClienteFornecedorId]);

    const handleToggleSelecionarCpf = (index: number) => {
      setListCpf((prev) =>
        prev.map((itemPrev, indexItem) => ({
          ...itemPrev,
          isChecked: indexItem === index ? !itemPrev.isChecked : false,
        }))
      );
    };

    const obterClientes = useCallback(async () => {
      setIsLoading(true);
      const response = await api.get<void, ResponseApi<SelectClienteProps[]>>(
        ConstanteEnderecoWebservice.OBTER_CLIENTE_PENDENCIA_INTEGRACAO,
        {
          params: {
            identificacaoIntegracao: enumIdentificacaoIntegracao.TRAY,
            integracaoPedidoId: id,
          },
        }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response.sucesso) {
          setListCpf(
            response?.dados?.map((item) => ({
              ...item,
              isChecked: false,
            }))
          );
        }
        setIsLoading(false);
      }
      setIsLoading(false);
    }, [id]);

    useEffect(() => {
      obterClientes();
    }, [obterClientes]);

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? '3xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
      >
        <ModalContent
          bg="gray.50"
          borderRadius={isLargerThan900 ? 'md' : '0'}
          marginBottom={isLargerThan900 ? '3.75rem' : '0'}
          marginTop={isLargerThan900 ? '3.75rem' : '0'}
          w={isLargerThan900 ? '700px' : 'full'}
          h={isLargerThan900 ? '356px' : 'full'}
        >
          <ModalHeader pt="16px" pb="24px" pl="24px">
            <Text color="primary.50" fontSize="16px">
              Vincular o CPF {formattedValueCpfCnpj(valueCpf)}
            </Text>
            <Text fontWeight="semibold" fontSize="14px" color="gray.700">
              Selecione o cliente que fez a compra
            </Text>
          </ModalHeader>

          {isLoading && <LoadingPadrao />}
          <ModalBody px="24px" mb="20px" pt="0" overflowY="auto" pb="0">
            <Box mt="5px">
              {listCpf.map((itemCpf, index) => (
                <Flex
                  transition="all 0.2s"
                  p="10px"
                  cursor="pointer"
                  boxShadow="0px 0px 4px #00000029"
                  _hover={{
                    boxShadow: '0px 0px 4px rgba(85, 2, 178, 0.5)',
                  }}
                  bg={itemCpf.isChecked ? 'purple.50' : 'white'}
                  alignItems="center"
                  mb="6px"
                  borderRadius="6px"
                  h="50px"
                >
                  <Box mr="10px">
                    <Checkbox
                      mb="0"
                      isChecked={itemCpf.isChecked}
                      onChange={() => handleToggleSelecionarCpf(index)}
                      colorScheme="primary"
                    />
                  </Box>
                  {itemCpf.nome}
                </Flex>
              ))}
            </Box>
          </ModalBody>
          <ModalFooter flexDirection="column">
            <Flex
              w="full"
              h="full"
              justifyContent="center"
              alignItems="baseline"
            >
              <Button
                variant="outlineDefault"
                colorScheme="gray"
                mr="24px"
                onClick={() => onClose()}
                w="140px"
              >
                Cancelar
              </Button>
              <ButtonDefault
                variant="solid"
                colorScheme="secondary.400"
                width="160px"
                isDisabled={!isCpfSelecionado}
                onClick={() => handleSubmit()}
                isLoading={isLoading}
              >
                Confirmar vínculo
              </ButtonDefault>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
