import { Flex, Text } from '@chakra-ui/react';

type CabecalhoCupomProps = {
  nomeFantasia: string;
};

export function CabecalhoCupomVoucher({ nomeFantasia }: CabecalhoCupomProps) {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      border="2px solid black"
      padding="2"
    >
      <Text fontWeight="extrabold" fontSize="md">
        {nomeFantasia}
      </Text>
    </Flex>
  );
}
