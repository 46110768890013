import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, GridItem, Icon, Td, Tr } from '@chakra-ui/react';

import { toast } from 'react-toastify';
import auth from 'modules/auth';
import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import { ButtonFuncionalidade } from 'components/update/Button/ButtonFuncionalidade';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { SearchInput } from 'components/update/Input/SearchInput';
import { PaginationData } from 'components/update/Pagination';
import { FilterSelect } from 'components/update/Select/FilterSelect';
import {
  PagedTable,
  PagedTableForwardRefData,
} from 'components/update/Table/PagedTable';
import { StatusCircle } from 'components/update/Table/StatusCircle';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { ModalConfirmacaoInativar } from 'components/Modal/ModalConfirmacaoInativar';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteFuncionalidades from 'constants/permissoes';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import StatusConsultaEnum from 'constants/enum/statusConsulta';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import getOptionsByEnum from 'helpers/format/getOptionsByEnum';
import { shallowEqual } from 'helpers/validation/shallowEqual';
import { SalvarInserirNovoIcon } from 'icons';
import { Link } from 'components/update/Link';

type TabelaPreco = {
  id: string;
  nome: string;
  padraoSistema: boolean;
  ativo: boolean;
};

type FormData = {
  descricao: string;
  ativo: number;
};

const formDefaultValues: FormData = {
  descricao: '',
  ativo: StatusConsultaEnum.ATIVOS,
};

export const TabelaPrecosListar = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [itemsTotalCount, setItemsTotalCount] = useState(0);
  const [columnsData, setColumnsData] = useState<TabelaPreco[]>([]);

  const formMethods = useForm({ defaultValues: formDefaultValues });
  const [currentFilters, setCurrentFilters] = useState<FormData>(
    formDefaultValues
  );

  const pagedTableRef = useRef<PagedTableForwardRefData>(null);

  const permissaoTabelaPrecosVisualizar = auth.possuiPermissao(
    ConstanteFuncionalidades.TABELA_PRECO_VISUALIZAR
  );

  const permissaoTabelaPrecosAlterar = auth.possuiPermissao(
    ConstanteFuncionalidades.TABELA_PRECO_ALTERAR
  );

  const filtersSubmit = formMethods.handleSubmit((data) => {
    const filtersIsDirty = !shallowEqual(data, currentFilters);

    if (filtersIsDirty) {
      setCurrentFilters(data);
    }
  });

  const loadColumnsData = useCallback(
    async (paginationData: PaginationData) => {
      setIsLoading(true);

      const params = {
        descricao: currentFilters.descricao || '',
        ativo:
          currentFilters?.ativo === StatusConsultaEnum.TODOS
            ? ''
            : currentFilters?.ativo === StatusConsultaEnum.ATIVOS,
      };

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<TabelaPreco>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.TABELA_PRECO_LISTAR_PAGINADO,
          paginationData,
          params
        )
      );

      if (response) {
        if (response.avisos) {
          response.avisos.map((item: string) => toast.warning(item));
        }

        if (response.sucesso) {
          setItemsTotalCount(response.dados.total || 0);

          setColumnsData(response.dados.registros || []);
        }
      }

      setIsLoading(false);
    },
    [currentFilters]
  );

  function handlePushCadastrarNovo() {
    history.push(ConstanteRotas.TABELA_PRECO_CADASTRAR);
  }

  async function handleExcluirTabelaDePreco(
    tabelaPrecoId: string,
    ativo: boolean
  ) {
    ModalConfirmacaoExcluir({
      callback: async (ok: boolean) => {
        if (ok) {
          setIsLoading(true);

          const response = await api.delete<void, ResponseApi>(
            ConstanteEnderecoWebservice.TABELA_PRECO_EXCLUIR,
            {
              params: { id: tabelaPrecoId },
            }
          );

          if (response) {
            if (response.avisos) {
              response.avisos.map((item: string) => toast.warning(item));
            }

            if (response?.sucesso) {
              toast.success('O cadastro foi removido com sucesso.');

              setItemsTotalCount((prev) => prev - 1);

              if (pagedTableRef.current) {
                pagedTableRef.current.reload();
              }
            }
          }

          if (response) {
            ModalConfirmacaoInativar({
              response,
              rotaWebService: ConstanteEnderecoWebservice.TABELA_PRECO_INATIVAR,
              id: tabelaPrecoId,
              ativo,
              callback: (okInativar: boolean) => {
                if (okInativar) {
                  setItemsTotalCount((prev) => prev - 1);

                  if (pagedTableRef.current) {
                    pagedTableRef.current.reload();
                  }
                }
              },
            });
          }

          setIsLoading(false);
        }
      },
    });
  }

  function getVisualizarHref(id: string) {
    let href = ConstanteRotas.TABELA_PRECOS;

    if (permissaoTabelaPrecosAlterar.permitido) {
      href = SubstituirParametroRota(
        ConstanteRotas.TABELA_PRECO_ALTERAR,
        'id',
        id
      );
    } else if (permissaoTabelaPrecosVisualizar.permitido) {
      href = SubstituirParametroRota(
        ConstanteRotas.TABELA_PRECO_VISUALIZAR,
        'id',
        id
      );
    }

    return href;
  }

  return (
    <SimpleGridForm>
      <FormProvider {...formMethods}>
        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <SearchInput
            name="descricao"
            placeholder="Buscar tabela de preços pelo nome"
            onEnterKeyPress={() => {
              filtersSubmit();
            }}
            isDisabled={isLoading}
            autoFocus
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, sm: 6, lg: 3 }}>
          <Box maxW={{ base: 'full', sm: '225px' }}>
            <FilterSelect
              id="ativo"
              name="ativo"
              options={getOptionsByEnum(StatusConsultaEnum)}
              onSelect={() => {
                filtersSubmit();
              }}
              isDisabled={isLoading}
            />
          </Box>
        </GridItem>
        <GridItem
          colSpan={{ base: 12, sm: 6, lg: 3 }}
          display="flex"
          justifyContent={{ base: 'flex-start', sm: 'flex-end' }}
        >
          <ButtonFuncionalidade
            funcionalidade={ConstanteFuncionalidades.TABELA_PRECO_CADASTRAR}
            colorScheme="secondary"
            minWidth={{ base: 'full', sm: '225px' }}
            borderRadius="md"
            leftIcon={<Icon as={SalvarInserirNovoIcon} fontSize="lg" />}
            isDisabled={isLoading}
            onClick={handlePushCadastrarNovo}
          >
            Cadastrar Novo
          </ButtonFuncionalidade>
        </GridItem>
      </FormProvider>

      <GridItem colSpan={12}>
        <PagedTable
          ref={pagedTableRef}
          isLoading={isLoading}
          loadColumnsData={loadColumnsData}
          itemsTotalCount={itemsTotalCount}
          defaultKeyOrdered="nome"
          tableHeaders={[
            {
              key: 'ativo',
              content: <StatusCircle hasValue={false} />,
              w: '1px',
            },
            { key: 'nome', content: 'Nome' },
            { key: 'acoes', content: 'Ações', isOrderable: false, w: '1px' },
          ]}
          renderTableRows={columnsData.map(
            ({ id, nome, ativo, padraoSistema }) => {
              const dropDownItens = [
                {
                  content: 'Alterar',
                  onClick: () => {
                    if (permissaoTabelaPrecosAlterar.permitido) {
                      history.push(
                        SubstituirParametroRota(
                          ConstanteRotas.TABELA_PRECO_ALTERAR,
                          'id',
                          id
                        )
                      );
                    }
                  },
                  funcionalidade: ConstanteFuncionalidades.TABELA_PRECO_ALTERAR,
                },
                ...(!padraoSistema
                  ? [
                      {
                        content: 'Remover',
                        onClick: () => {
                          handleExcluirTabelaDePreco(id, ativo);
                        },
                        funcionalidade:
                          ConstanteFuncionalidades.TABELA_PRECO_EXCLUIR,
                      },
                    ]
                  : []),
              ];
              return (
                <Tr>
                  <Td>
                    <StatusCircle hasValue isActive={ativo} />
                  </Td>
                  <Td>
                    <Link href={getVisualizarHref(id)}>{nome}</Link>
                  </Td>
                  <Td>
                    <ActionsMenu items={dropDownItens} />
                  </Td>
                </Tr>
              );
            }
          )}
        />
      </GridItem>
    </SimpleGridForm>
  );
};
