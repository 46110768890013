import React, { useRef, RefObject, useEffect, useState } from 'react';
import {
  useDisclosure,
  HStack,
  ModalContent,
  ModalBody,
  Box,
  Stack,
  Flex,
  Text,
  Button,
  CloseButton,
  Spinner,
  Grid,
} from '@chakra-ui/react';
import format from 'date-fns/format';
import ptBR from 'date-fns/locale/pt-BR';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { useInformacoesGeraisContext } from 'store/PDV/InformacoesGerais';
import { useAccessibleTabulation } from 'helpers/layout/useAccessibleTabulation';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { SubstituirParametroRota } from 'constants/rotas';
import ConstanteRotasPDV from 'constants/rotasPDV';

import { PedidosAbertosIcon } from 'icons';
import ShadowScrollbar, {
  ShadowScrollbarForwardRefProps,
} from 'components/PDV/Geral/ShadowScrollbar';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';

import { GlobalHotKeys } from 'react-hotkeys';
import ConstanteFuncionalidades from 'constants/permissoes';
import LogAuditoriaTelaEnum from 'constants/enum/logAuditoriaTela';
import ModalAutorizacaoFuncionalidade from 'components/Modal/ModalAutorizacaoFuncionalidade';
import auth from 'modules/auth';
import { formatDateHourMinute } from 'helpers/format/formatStringDate';
import handleSairDevolucao from 'store/PDV/SairDevolucao';
import { MobileMenuItem } from '../Mobile/MenuItem';
import InternalHeaderButton from '../Button';

type Operacao = {
  id: string;
  numeroOperacao: number;
  dataEmissao: string;
  horaEmissao: string;
  clienteFornecedorNome: string;
  valorTotal: number;
  valorTotalFormatado: string;
};

interface PedidosAbertosListagemProps {
  isOpen: boolean;
  asAccordion?: boolean;
  onClose?: () => void;
  shadowScrollbarRef: React.RefObject<ShadowScrollbarForwardRefProps>;
}

const PedidosAbertosListagem = ({
  isOpen,
  asAccordion,
  onClose,
  shadowScrollbarRef,
}: PedidosAbertosListagemProps) => {
  const history = useHistory();
  const {
    pedidosPendentesCount,
    detalhesTroca,
  } = useInformacoesGeraisContext();

  const [
    modalAutorizacaoAlterarOperacaoIsOpen,
    setModalAutorizacaoAlterarOperacaoIsOpen,
  ] = useState(false);

  const [operacaoSelecionadaId, setOperacaoSelecionadaId] = useState('');

  const containerRef = useRef<HTMLDivElement>(null);
  useAccessibleTabulation(containerRef as RefObject<HTMLElement>);

  const latestProps = useRef({ shadowScrollbarRef });
  useEffect(() => {
    latestProps.current = { shadowScrollbarRef };
  });

  const dataAtual = formatDateHourMinute(new Date());

  const [pedidosPendentes, setPedidosPendentes] = useState<Operacao[]>([]);
  const [pedidosPendentesIsLoading, setPedidosPendentesIsLoading] = useState(
    true
  );

  const handleOpenOperacao = (
    operacaoId: string,
    chavePermissaoTemporaria?: string
  ) => {
    const irParaRotaLancamentoPdv = () => {
      history.push(
        SubstituirParametroRota(
          ConstanteRotasPDV.PDV_LANCAMENTO_ID,
          'id?',
          operacaoId
        ),
        { chavePermissaoTemporaria }
      );
    };
    handleSairDevolucao({
      handleOperacao: irParaRotaLancamentoPdv,
      detalhesTroca,
    });
  };

  const handleVerificarPermissaoAlterar = (operacaoId: string) => {
    if (
      auth.possuiPermissao(ConstanteFuncionalidades.PDV_ALTERAR.codigo)
        .permitido
    ) {
      handleOpenOperacao(operacaoId);
    } else {
      setOperacaoSelecionadaId(operacaoId);
      setModalAutorizacaoAlterarOperacaoIsOpen(true);
    }
  };

  useEffect(() => {
    const handleGetPedidosPendentesDia = async () => {
      setPedidosPendentesIsLoading(true);

      const response = await api.get<void, ResponseApi<Operacao[]>>(
        ConstanteEnderecoWebservice.PEDIDOORCAMENTOVENDA_LISTAR_PEDIDOS_DIA
      );

      if (response) {
        if (response.avisos) {
          response.avisos.map((item: string) => toast.warning(item));
        }

        if (response.sucesso && response.dados) {
          setPedidosPendentesIsLoading(false);

          setPedidosPendentes(
            response.dados.map((pedidoPendente) => ({
              ...pedidoPendente,
              horaEmissao: format(
                new Date(pedidoPendente.dataEmissao),
                "HH:mm'h'",
                {
                  locale: ptBR,
                }
              ),
              valorTotalFormatado: pedidoPendente.valorTotal.toLocaleString(
                'pt-BR',
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 4,
                }
              ),
            }))
          );

          if (latestProps.current.shadowScrollbarRef.current)
            latestProps.current.shadowScrollbarRef.current.handleUpdateScrollbar();

          return;
        }
      }

      setPedidosPendentesIsLoading(false);
      setPedidosPendentes([]);

      if (latestProps.current.shadowScrollbarRef.current)
        latestProps.current.shadowScrollbarRef.current.handleUpdateScrollbar();
    };

    if (isOpen) {
      handleGetPedidosPendentesDia();
    }
    // A dependência pedidosPendentesCount serve somente para que a busca por pedidos abertos também ouça a alteração na notificação que o signalR causa
  }, [isOpen, pedidosPendentesCount]);

  return (
    <>
      <Stack
        direction="column"
        justifyContent={asAccordion ? 'flex-start' : 'center'}
        alignItems="center"
        flex={1}
        h="100%"
        px={asAccordion ? '2' : undefined}
        spacing={asAccordion ? 1 : 2}
        ref={containerRef}
      >
        <Box
          h={asAccordion ? 'auto' : '45px'}
          minH={asAccordion ? undefined : '45px'}
          w="100%"
          px={asAccordion ? 0 : 6}
          bg={asAccordion ? 'transparent' : 'secondary.500'}
          color={asAccordion ? 'secondary.500' : 'current'}
          borderRadius="base"
        >
          <Stack
            direction="row"
            spacing={6}
            alignItems="center"
            justifyContent="space-between"
            h="100%"
            w="100%"
            fontWeight="normal"
            fontSize="sm"
          >
            {!asAccordion && (
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                h="100%"
                mb={0}
              >
                <Text fontSize="xs">PEDIDOS ABERTOS:</Text>
                <Text fontWeight="bold" fontSize="md" lineHeight={0.1}>
                  {dataAtual}
                </Text>
              </Stack>
            )}

            {!asAccordion && !pedidosPendentesIsLoading && (
              <CloseButton onClick={onClose} size="sm" tabIndex={-1} />
            )}
            {pedidosPendentesIsLoading && (
              <Spinner
                size="sm"
                color="secondary.400"
                emptyColor="blue.900"
                speed="0.65s"
              />
            )}
          </Stack>
        </Box>
        {(!pedidosPendentes || pedidosPendentes.length === 0) &&
          !pedidosPendentesIsLoading && (
            <Box
              h={asAccordion ? '55px' : '45px'}
              minH={asAccordion ? '55px' : '45px'}
              w="100%"
              px={6}
              bg="gray.200"
              borderRadius="base"
            >
              <Flex h="100%" alignItems="center">
                <Text fontSize="xs">
                  Você não tem pedidos abertos para este dia.
                </Text>
              </Flex>
            </Box>
          )}
        {pedidosPendentes.map((pedidoPendente) => (
          <Button
            h={asAccordion ? 'auto' : '45px'}
            minH={asAccordion ? '55px' : '45px'}
            w="100%"
            px={6}
            py={asAccordion ? 4 : 0}
            bg={asAccordion ? 'gray.50' : 'gray.200'}
            _hover={{ bg: 'gray.50' }}
            _focus={{ bg: 'gray.50' }}
            borderRadius="base"
            justifyContent="flex-start"
            onClick={() => {
              handleVerificarPermissaoAlterar(pedidoPendente.id);
            }}
          >
            {asAccordion ? (
              <Stack
                direction="column"
                spacing={2}
                alignItems="center"
                justifyContent="center"
                h="100%"
                w="100%"
                fontWeight="normal"
                fontSize="md"
              >
                <HStack spacing={1} justifyContent="flex-start" w="100%">
                  <Text
                    fontSize={asAccordion ? 'xs' : '2xs'}
                    fontWeight="light"
                    color="gray.400"
                  >
                    Pedido:
                  </Text>
                  <Stack
                    direction="row"
                    spacing={4}
                    alignItems="center"
                    justifyContent="space-between"
                    w="100%"
                  >
                    <Text fontSize={asAccordion ? 'sm' : undefined}>
                      {pedidoPendente.numeroOperacao}
                    </Text>
                    <Text fontSize={asAccordion ? 'sm' : undefined}>
                      {pedidoPendente.horaEmissao}
                    </Text>
                    <Text
                      fontSize={asAccordion ? 'sm' : undefined}
                    >{`R$ ${pedidoPendente.valorTotalFormatado}`}</Text>
                  </Stack>
                </HStack>

                <HStack
                  spacing={1}
                  justifyContent="flex-start"
                  alignItems="center"
                  w="100%"
                >
                  <Text
                    fontSize={asAccordion ? 'xs' : '2xs'}
                    fontWeight="light"
                    color="gray.400"
                    mt={asAccordion ? 0 : 1}
                  >
                    Cliente:
                  </Text>
                  <Text
                    maxW="100%"
                    textAlign="left"
                    fontWeight={asAccordion ? 'normal' : 'bold'}
                    whiteSpace="normal"
                    fontSize={asAccordion ? 'sm' : undefined}
                  >
                    {pedidoPendente.clienteFornecedorNome}
                  </Text>
                </HStack>
              </Stack>
            ) : (
              <Grid
                fontSize="sm"
                templateColumns="auto auto 1fr auto"
                w="100%"
                gap={6}
              >
                <Text>{pedidoPendente.numeroOperacao}</Text>
                <Text>{pedidoPendente.horaEmissao}</Text>
                <Text isTruncated>{pedidoPendente.clienteFornecedorNome}</Text>

                <Text>{`R$ ${pedidoPendente.valorTotalFormatado}`}</Text>
              </Grid>
            )}
          </Button>
        ))}
      </Stack>
      {modalAutorizacaoAlterarOperacaoIsOpen && (
        <ModalAutorizacaoFuncionalidade
          isOpen={modalAutorizacaoAlterarOperacaoIsOpen}
          setIsOpen={setModalAutorizacaoAlterarOperacaoIsOpen}
          autorizado={(chave) => {
            handleOpenOperacao(operacaoSelecionadaId, chave);
          }}
          titulo={ConstanteFuncionalidades.PDV_ALTERAR.titulo}
          descricao={ConstanteFuncionalidades.PDV_ALTERAR.descricao}
          tela={LogAuditoriaTelaEnum.PDV}
          permissoes={[ConstanteFuncionalidades.PDV_ALTERAR.codigo]}
        />
      )}
    </>
  );
};

interface PedidosAbertosProps {
  asAccordion?: boolean;
}

const PedidosAbertos: React.FC<PedidosAbertosProps> = ({ asAccordion }) => {
  const { pedidosPendentesCount } = useInformacoesGeraisContext();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const shadowScrollbarRef = useRef<ShadowScrollbarForwardRefProps>(null);

  const keyMap = {
    F6: ['f6'],
  };

  const handlersHotKeys = {
    F6: (event: any) => {
      event.preventDefault();
      onOpen();
    },
  };

  if (asAccordion) {
    const dataAtual = formatDateHourMinute(new Date());

    return (
      <>
        <MobileMenuItem
          icon={PedidosAbertosIcon}
          title="Pedidos abertos"
          subtitle={
            pedidosPendentesCount > 0 ? `${pedidosPendentesCount}` : undefined
          }
          modalTitle={dataAtual}
        >
          {({ isOpen: isMobileMenuItemOpen }) => (
            <PedidosAbertosListagem
              isOpen={isMobileMenuItemOpen}
              asAccordion
              shadowScrollbarRef={shadowScrollbarRef}
            />
          )}
        </MobileMenuItem>
      </>
    );
  }

  return (
    <>
      <GlobalHotKeys keyMap={keyMap} handlers={handlersHotKeys} />
      <InternalHeaderButton
        icon={PedidosAbertosIcon}
        title="Pedidos"
        titleColor={isOpen ? 'white' : 'gray.200'}
        hasIndicator
        badgeCount={pedidosPendentesCount}
        onClick={onOpen}
      />

      <ModalPadraoChakra
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        scrollBehavior="inside"
        size="xl"
        autoFocus={false}
      >
        <ModalContent
          background="transparent"
          boxShadow="none"
          h="auto"
          maxH="100vh"
        >
          <ShadowScrollbar
            ref={shadowScrollbarRef}
            maxHeight={425}
            shadowTopStyle={{ margin: '0 1.5rem' }}
            shadowBottomStyle={{ margin: '0 1.5rem' }}
          >
            <ModalBody>
              <PedidosAbertosListagem
                isOpen={isOpen}
                onClose={onClose}
                shadowScrollbarRef={shadowScrollbarRef}
              />
            </ModalBody>
          </ShadowScrollbar>
        </ModalContent>
      </ModalPadraoChakra>
    </>
  );
};

export default PedidosAbertos;
