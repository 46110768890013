import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
  useRef,
  ReactNode,
} from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import api, { ResponseApi } from 'services/api';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import { setDateMaxHours, setDateMinHours } from 'helpers/data/setHoursDate';
import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';
import TipoContaFinanceiraEnum from 'constants/enum/tipoContaFinanceira';
import StatusOperacaoEnum from 'constants/enum/statusOperacao';

import {
  GridPaginadaRetorno,
  GridPaginadaConsulta,
} from 'components/Grid/Paginacao';
import { Operacao } from 'pages/PDV/ConsultarOperacoes/OperacaoItem';
import {
  defaultValues,
  FormData,
} from 'pages/PDV/ConsultarOperacoes/validationForm';

import { useInformacoesGeraisContext } from './InformacoesGerais';

interface ConsultarOperacoesContextProps {
  operacoes: Operacao[];
  handleGetOperacoes: () => void;
  gridPaginadaConsulta: GridPaginadaConsulta;
  handleChangePageSize: (newPageSize: number) => void;
  handleChangeCurrentPage: (newCurrentPage: number) => void;
  totalCount: number;
  lastPage: number;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConsultarOperacoesContext = createContext<ConsultarOperacoesContextProps>(
  {} as ConsultarOperacoesContextProps
);

interface ConsultarOperacoesProviderProps {
  children: ReactNode;
}

export default function ConsultarOperacoesProvider({
  children,
}: ConsultarOperacoesProviderProps): JSX.Element {
  const isMountedRef = useIsMountedRef();

  const {
    contaFinanceira,
    possuiCadastroOutrosCaixas,
  } = useInformacoesGeraisContext();

  const caixaFechadoEPossuiCadastroOutrosCaixas =
    contaFinanceira?.tipoContaFinanceira ===
      TipoContaFinanceiraEnum.CONTA_COFRE && possuiCadastroOutrosCaixas;

  const defaultValuesCaixaFechado = {
    numeroOperacao: '',
    clienteId: null,
    identificacaoTipoOperacao: caixaFechadoEPossuiCadastroOutrosCaixas
      ? IdentificacaoTipoOperacaoEnum.PEDIDO
      : null,
    filtrarPeloCaixaAtual: !caixaFechadoEPossuiCadastroOutrosCaixas,
    status: StatusOperacaoEnum.EFETUADA,
    dataEmissaoInicio: setDateMinHours(new Date()),
    dataEmissaoFim: setDateMaxHours(new Date()),
  };

  const formMethods = useForm<FormData>({
    defaultValues: caixaFechadoEPossuiCadastroOutrosCaixas
      ? defaultValuesCaixaFechado
      : defaultValues,
    shouldUnregister: false,
  });

  const [
    gridPaginadaConsulta,
    setGridPaginadaConsulta,
  ] = useState<GridPaginadaConsulta>({
    currentPage: 1,
    pageSize: 10,
    orderColumn: 'DataEmissao',
    orderDirection: 'desc',
  });
  const [operacoes, setOperacoes] = useState<Operacao[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [lastPage, setLastPage] = useState(1);

  const handleChangePageSize = useCallback(
    async (newPageSize: number) => {
      setGridPaginadaConsulta({
        ...gridPaginadaConsulta,
        pageSize: newPageSize,
      });
    },
    [gridPaginadaConsulta]
  );

  const handleChangeCurrentPage = useCallback(
    async (newCurrentPage: number) => {
      if (newCurrentPage < 1 || newCurrentPage > lastPage) return;

      setGridPaginadaConsulta({
        ...gridPaginadaConsulta,
        currentPage: newCurrentPage,
      });
    },
    [gridPaginadaConsulta, lastPage]
  );

  const latestProps = useRef({ getValues: formMethods.getValues });
  useEffect(() => {
    latestProps.current = { getValues: formMethods.getValues };
  });

  const handleGetOperacoes = useCallback(async () => {
    setIsLoading(true);

    const filtros = latestProps.current.getValues();
    const response = await api.get<
      void,
      ResponseApi<GridPaginadaRetorno<Operacao>>
    >(
      formatQueryPagegTable(
        ConstanteEnderecoWebservice.PEDIDOORCAMENTOVENDA_LISTAR_PAGINADO,
        gridPaginadaConsulta
      ),
      {
        params: {
          ...filtros,
          dataEmissaoFim: setDateMaxHours(new Date(filtros.dataEmissaoFim)),
        },
      }
    );

    if (response && isMountedRef.current) {
      if (response.sucesso && response.dados) {
        setOperacoes(response.dados.registros);

        const newTotal = response.dados.total;

        if (newTotal > 0) {
          setTotalCount(response.dados.total);
          setLastPage(
            Math.ceil(response.dados.total / gridPaginadaConsulta.pageSize)
          );
        } else {
          setTotalCount(0);
          setLastPage(1);
        }
      }
    }

    if (isMountedRef.current) {
      setIsLoading(false);
    }
  }, [gridPaginadaConsulta, isMountedRef]);

  useEffect(() => {
    handleGetOperacoes();
  }, [gridPaginadaConsulta, handleGetOperacoes]);

  return (
    <FormProvider {...formMethods}>
      <ConsultarOperacoesContext.Provider
        value={{
          operacoes,
          handleGetOperacoes,
          gridPaginadaConsulta,
          handleChangePageSize,
          handleChangeCurrentPage,
          totalCount,
          lastPage,
          isLoading,
          setIsLoading,
        }}
      >
        {children}
      </ConsultarOperacoesContext.Provider>
    </FormProvider>
  );
}

export function useConsultarOperacoesContext(): ConsultarOperacoesContextProps {
  const context = useContext(ConsultarOperacoesContext);

  if (!context)
    throw new Error(
      'useConsultarOperacoesContext must be used within a ConsultarOperacoesProvider.'
    );

  return context;
}
