/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-underscore-dangle */

import React from 'react';
import {
  components,
  components as selectComponents,
  StylesConfig,
} from 'react-select';
import {
  Tag,
  Tooltip,
  TagCloseButton,
  TagLabel,
  Box,
  StylesProvider,
  useMultiStyleConfig,
  useStyles,
  Icon,
  useMediaQuery,
  Flex,
  useToken,
  Text,
} from '@chakra-ui/react';
import { FiCheck, FiChevronDown } from 'react-icons/fi';

type DataProps = {
  data: {
    value: string;
    label: string;
    ativo?: boolean;
  };
  options: {
    value: string;
    label: string;
    ativo?: boolean;
  }[];
};

export const chakraStyles = (
  colorSelectedValue: any,
  multiValueBg?: string,
  multiValueColor?: string,
  isDisabledOption?: boolean
) => ({
  control: () => ({}),
  input: (provided: any) => ({
    ...provided,
    color: 'inherit',
    lineHeight: 1,
  }),
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 9999,
  }),
  menu: (provided: any) => ({
    ...provided,
    boxShadow: 'none',
    zIndex: 1500,
    marginTop: '1px',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: '0.125rem 1rem',
    fontSize: '1rem',
  }),

  // option: (provided: any, state: any) => ({
  //   ...provided,
  //   marginLeft: '40px',
  //   '&:after': {
  //     content: state.isSelected ? '""' : 'none',
  //     position: 'absolute',
  //     left: '18px',
  //     top: '50%',
  //     transform: 'translateY(-50%) rotate(45deg)',
  //     width: '5px',
  //     height: '10px',
  //     border: 'solid var(--black)',
  //     borderWidth: '0 1px 1px 0',
  //   },
  // }),
  singleValue: (provided: any) => ({
    ...provided,
    color: colorSelectedValue || 'inherit',
  }),
  // menuList: () => {},
  multiValue: (provided: any, value: DataProps) => {
    const dataStatus = value?.data?.ativo;

    return {
      ...provided,
      borderRadius: '9999px',
      backgroundColor: isDisabledOption
        ? dataStatus === true || dataStatus === undefined
          ? multiValueBg
          : '#909090'
        : multiValueBg,
      color: multiValueColor,
    };
  },
  multiValueLabel: (provided: any, value: DataProps) => {
    const dataStatus = value?.data?.ativo;
    return {
      ...provided,
      color: isDisabledOption
        ? dataStatus === true || dataStatus === undefined
          ? multiValueColor
          : 'white'
        : multiValueColor,
    };
  },
  multiValueRemove: (provided: any, value: DataProps) => {
    const dataStatus = value?.data?.ativo;
    return {
      ...provided,
      color: isDisabledOption
        ? dataStatus === true || dataStatus === undefined
          ? multiValueColor
          : 'white'
        : multiValueColor,
    };
  },
  // group: () => {},
});

export const styleMultiOptionsWithCheckbox: StylesConfig = {
  multiValueRemove: (base: any) => ({
    ...base,
    background: 'white',
    display: 'none',
  }),
  multiValue: (base) => ({
    ...base,
    background: 'none',
    padding: '0px',
  }),

  option: (base) => ({
    ...base,
    fontSize: '14px',
  }),

  multiValueLabel: (base) => ({
    ...base,
    background: 'white',
    color: 'black',
    padding: '0px',
    fontSize: '14px',
  }),
};

export const chakraComponents = {
  // Control components

  MultiValueContainer: ({
    children,
    innerRef,
    innerProps,
    data: { isFixed },
  }: any) => (
    <Tag
      ref={innerRef}
      {...innerProps}
      m="0.125rem"
      variant={isFixed ? 'solid' : 'subtle'}
    >
      {children}
    </Tag>
  ),
  IndicatorSeparator: () => null,
  DropdownIndicator: ({ children, ...rest }: any) => (
    <components.DropdownIndicator {...rest}>
      <Icon as={FiChevronDown} />
    </components.DropdownIndicator>
  ),
  MultiValueLabel: ({ children, innerRef, innerProps }: any) => (
    <TagLabel ref={innerRef} {...innerProps}>
      {children}
    </TagLabel>
  ),
  MultiValueRemove: ({
    children,
    innerRef,
    innerProps,
    data: { isFixed },
  }: any) => {
    if (isFixed) {
      return null;
    }

    return (
      <TagCloseButton ref={innerRef} {...innerProps}>
        {children}
      </TagCloseButton>
    );
  },
  // Menu components
  /* MenuPortal: ({ innerRef, innerProps, children }: any) => (
    <Portal ref={innerRef} {...innerProps}>
      {children}
    </Portal>
  ), */
  Menu: ({ children, ...props }: any) => {
    const menuStyles = useMultiStyleConfig('Menu', {});
    return (
      <selectComponents.Menu {...props}>
        <StylesProvider value={menuStyles}>{children}</StylesProvider>
      </selectComponents.Menu>
    );
  },
  MenuList: ({ innerRef, innerProps, children, maxHeight }: any) => {
    const { list } = useStyles();

    return (
      <Box
        sx={{
          ...list,
          maxH: `${maxHeight}px`,
          overflowY: 'auto',
        }}
        ref={innerRef}
        {...innerProps}
      >
        {children}
      </Box>
    );
  },
  GroupHeading: ({ innerProps, children }: any) => {
    const { groupTitle } = useStyles();
    return (
      <Box sx={groupTitle} {...innerProps}>
        {children}
      </Box>
    );
  },
  Option: ({
    innerRef,
    innerProps,
    children,
    isFocused,
    isDisabled,
    isSelected,
    data,
    selectProps,
  }: any) => {
    const { item } = useStyles();

    const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');

    const tamanhoIconeSelecionado = '3';

    const { shouldAppearTheAddress, renderOptionDetails } = selectProps;

    return (
      <Flex
        sx={{
          ...item,
          paddingRight: renderOptionDetails ? '8px' : item.paddingRight,
          w: '100%',
          textAlign: 'left',
          fontSize: shouldAppearTheAddress ? '14px' : item.fontSize,
          fontWeight: shouldAppearTheAddress ? '600' : item.fontWeight,
          paddingLeft: isSelected
            ? '0.8rem'
            : `calc(1.6rem + ${tamanhoIconeSelecionado})`,
          // @ts-ignore
          bg: isFocused ? item._focus.bg : 'transparent',
          // @ts-ignore
          ...(isDisabled && item._disabled),
        }}
        className="react-select__option"
        ref={innerRef}
        {...innerProps}
        {...(isDisabled && { disabled: true })}
        justifyContent="space-between"
        gap="12px"
        mb="4px"
      >
        <Tooltip
          label={data?.labelTooltip}
          placement="right-start"
          p="10px"
          borderRadius="5px"
          isDisabled={!data?.isTooltip}
        >
          <Box
            as="button"
            textAlign="left"
            fontWeight={shouldAppearTheAddress ? '600' : '500'}
            color="gray.700"
          >
            {isSelected && (
              <Icon
                as={FiCheck}
                marginRight="3"
                w={tamanhoIconeSelecionado}
                top={shouldAppearTheAddress ? '3' : ''}
              />
            )}

            {children}

            {shouldAppearTheAddress && (
              <Box
                fontSize="xs"
                display="flex"
                flexDirection="row"
                flex="1"
                sx={{
                  '::before': {
                    content: "' '",
                    w:
                      isSelected && !isSmallerThan900
                        ? tamanhoIconeSelecionado
                        : '0',
                    marginRight: isSelected && !isSmallerThan900 ? '3' : '0',
                  },
                }}
              >
                <Text fontSize="12px" fontWeight="normal" color="gray.500">
                  {data.endereco}
                </Text>
              </Box>
            )}
          </Box>
        </Tooltip>
        {renderOptionDetails && renderOptionDetails(data)}
      </Flex>
    );
  },
};

export const multiOptionsWithCheckboxComponent = {
  Option: ({ innerRef, innerProps, children, isDisabled, isSelected }: any) => {
    const { item } = useStyles();
    const primary50 = useToken('colors', 'primary.50');

    return (
      <Flex
        as="button"
        sx={{
          ...item,
          w: '100%',
          paddingLeft: '27px',
          textAlign: 'left',
          color: 'black',
        }}
        alignItems="center"
        justifyContent="left"
        className="react-select__option"
        ref={innerRef}
        {...innerProps}
        {...(isDisabled && { disabled: true })}
      >
        <input
          type="checkbox"
          style={{
            height: '14px',
            width: '14px',
            accentColor: primary50,
            marginRight: '12px',
          }}
          checked={isSelected}
          onChange={() => null}
        />

        <Box fontSize="16px">{children}</Box>
      </Flex>
    );
  },
};

interface ChakraReactSelectContainerProps {
  children: React.ReactNode;
  variant?: string;
  size?: string;
  colorScheme?: string;
  isDisabled?: boolean;
  isMulti?: boolean;
}

export const ChakraReactSelectContainer = ({
  children,
  variant,
  size,
  colorScheme,
  isDisabled,
  isMulti,
}: ChakraReactSelectContainerProps) => {
  const {
    // @ts-ignore
    field: { _hover, _focus, px, _disabled, ...rest },
  } = useMultiStyleConfig('Input', { variant, size, colorScheme });

  const disabledStyles = isDisabled ? _disabled : {};

  return (
    <Box
      className="react-select__custom-container"
      sx={{
        '& .react-select__control .react-select__value-container': {
          px, // O padding do eixo X é passado para o value-container pois o control armazena também os indicators, e estes não podem responder ao padding
        },
        '& .react-select__control': {
          h: 'auto',
          minH: '10px',
          display: 'flex', // Necessário para o funcionamento default do Control
          ...rest,
          ...disabledStyles,
          p: 0, // Suporte para o primeiro comentário
          ...(isMulti ? { h: 'auto' } : {}),
        },
        '& .react-select__control:not(.react-select__control--is-focused):not(.react-select__control--menu-is-open):hover': {
          ..._hover, // Estilos de hover são válidos para o select apenas quando este não está focado
        },
        '& .react-select__control--is-focused': {
          ..._focus, // Como o Control é uma div, não recebe evento de foco, por isso o react-select deixa essas duas classes de suporte para foco
        },
        '& .react-select__control--menu-is-open': {
          ..._focus, // Como o Control é uma div, não recebe evento de foco, por isso o react-select deixa essas duas classes de suporte para foco
        },
        ...(isMulti
          ? {
              '& .react-select__indicators': {
                maxH: rest.h,
                maxHeight: rest.height,
              },
            }
          : {}),
      }}
    >
      {children}
    </Box>
  );
};
