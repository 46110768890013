import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

type TransferenciaEstoque = {
  lojaOrigemFantasia: string;
  localEstoqueOrigemOpcaoSelect: { id: string; nome: string };
  lojaDestinoOpcaoSelect: { id: string; nome: string };
  localEstoqueDestinoOpcaoSelect: { id: string; nome: string };
  motivo: string;
  usuarioNome: string;
  status: number;
  dataHoraEmissao: string;
  dataHoraEnvio?: string;
  usuarioRecebimentoNome: string;
  dataHoraRecebimento?: string;
};

export async function obterTransferenciaEstoque(
  operacaoId: string,
  obterParaDuplicar = false
) {
  const response = await api.get<
    void,
    ResponseApi<TransferenciaEstoque | undefined>
  >(ConstanteEnderecoWebservice.TRANSFERENCIA_ESTOQUE_OBTER, {
    params: { id: operacaoId, obterParaDuplicar },
  });

  if (response) {
    if (response.avisos) {
      response.avisos.map((aviso: string) => toast.warning(aviso));
    }

    if (response.sucesso && response.dados) {
      return response.dados;
    }
  }

  return undefined;
}
