import { useForm as useFormInstance } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

const schema = yup.object().shape({
  email: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .email(ConstanteMensagemValidacao.EMAIL_INVALIDO),
});

export const yupResolver = yupResolverInstance(schema);

export const useForm = useFormInstance;
