import React, { useEffect, useState, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useHistory, RouteComponentProps, Prompt } from 'react-router-dom';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteRotas from 'constants/rotas';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import isPrenvent from 'helpers/layout/isPrenvent';
import { obterLocalEstoque as apiObterLocalEstoque } from 'helpers/api/LocalEstoque/obterLocalEstoque';
import EnumStatusErroLocalEstoque from 'constants/enum/enumStatusErroOperacao';

import ManterFoco from 'components/Geral/ManterFoco';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { FormFooter } from 'components/update/Form/FormFooter';
import { ModalErroInativarEstoque } from 'components/update/Modal/ModalErroInativarEstoque';
import { ModalTransferirEstoque } from 'components/update/Modal/ModalTransferirEstoque';

import Formulario from '..';
import {
  yupResolver,
  FormData,
  AlterarLocalEstoqueResponse,
} from '../validationForm';

type TParams = { id: string };

const Alterar = ({ match }: RouteComponentProps<TParams>) => {
  const history = useHistory();
  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues: {
      nome: '',
      ativo: true,
    },
  });
  const isMountedRef = useIsMountedRef();

  const { setValue } = formMethods;

  const { dirtyFields } = formMethods.formState;
  const isDirty =
    dirtyFields &&
    dirtyFields.constructor === Object &&
    Object.keys(dirtyFields).length > 0;

  const [isPrenvented, setIsPrenvented] = useState(false);
  isPrenvent(isPrenvented);

  const localEstoqueId = match.params.id;

  const [isLoading, setIsLoading] = useState(false);
  const [dataHoraCriacao, setDataHoraCriacao] = useState('');
  const [dataHoraUltimaAlteracao, setDataHoraUltimaAlteracao] = useState('');

  const hasProdutoComEstoque =
    EnumStatusErroLocalEstoque.POSSUI_PRODUTOS_ESTOQUE;

  function historyPush(path: string) {
    setIsPrenvented(false);

    history.push(path);
  }

  const handleSubmit = formMethods.handleSubmit(async (data: FormData) => {
    setIsLoading(true);

    const response = await api.put<
      void,
      ResponseApi<AlterarLocalEstoqueResponse>
    >(ConstanteEnderecoWebservice.LOCAL_ESTOQUE_ALTERAR, {
      ...data,
    });

    if (response) {
      if (response.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }

      if (response.sucesso && response.dados) {
        const { dadosSucesso, erro } = response.dados;
        if (dadosSucesso && !erro) {
          toast.success('O cadastro foi alterado com sucesso.');
          historyPush(ConstanteRotas.LOCAL_ESTOQUE);
        } else if (erro === hasProdutoComEstoque) {
          ModalErroInativarEstoque({
            callback: async (okInativar?: boolean) => {
              if (!okInativar) {
                setValue('ativo', true);
                setIsLoading(false);
              }
              if (okInativar) {
                const {
                  transferir,
                  localEstoqueIdDestino,
                } = await ModalTransferirEstoque({
                  localEstoqueIdOrigem: localEstoqueId,
                });
                if (!transferir) {
                  setValue('ativo', true);
                  setIsLoading(false);
                }
                if (transferir) {
                  if (localEstoqueIdDestino) {
                    setValue('localEstoqueDestinoId', localEstoqueIdDestino);
                    handleSubmit();
                  }
                }
              }
            },
          });
        }
      }
    }

    if (isMountedRef.current) setIsLoading(false);
  });

  const latestProps = useRef({
    reset: formMethods.reset,
    localEstoqueId,
    historyPush,
    setFocus: formMethods.setFocus,
  });
  useEffect(() => {
    latestProps.current = {
      reset: formMethods.reset,
      localEstoqueId,
      historyPush,
      setFocus: formMethods.setFocus,
    };
  });

  useEffect(() => {
    async function obterLocalEstoque() {
      setIsLoading(true);

      const responseData = await apiObterLocalEstoque(
        latestProps.current.localEstoqueId
      );

      if (responseData) {
        setDataHoraCriacao(responseData.dataHoraCadastro || '');
        setDataHoraUltimaAlteracao(responseData.dataHoraUltimaAlteracao || '');

        latestProps.current.reset(responseData);

        setIsLoading(false);

        latestProps.current.setFocus('nome');
      } else {
        setIsLoading(false);

        latestProps.current.historyPush(ConstanteRotas.LOCAL_ESTOQUE);
      }
    }

    obterLocalEstoque();
  }, []);

  useEffect(() => {
    setIsPrenvented(isDirty);
  }, [isDirty]);

  return (
    <Box maxW="1116px">
      <Prompt when={isPrenvented} message="" />
      <ManterFoco>
        {isLoading && <LoadingPadrao />}
        <FormProvider {...formMethods}>
          <Formulario />
        </FormProvider>

        <FormFooter
          onSubmit={handleSubmit}
          isDisabled={isLoading}
          dates={{ dataHoraCriacao, dataHoraUltimaAlteracao }}
        />
      </ManterFoco>
    </Box>
  );
};

export default Alterar;
