import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Flex,
  useMediaQuery,
  Text,
  ModalHeader,
  ModalFooter,
  Divider,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';

type ResponseModal = {
  success: boolean;
};

type ModalExplicacaoSenhaNumericaProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ResponseModal>;
export const ModalExplicacaoSenhaNumerica = create<ModalExplicacaoSenhaNumericaProps>(
  ({ onResolve, onReject, ...rest }) => {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    const handleCadastrarSenha = () => {
      onResolve({ success: true });
      onClose();
    };

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? 'xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={() => {
          onReject({ success: false });
          onClose();
        }}
        autoFocus={false}
      >
        <ModalContent
          bg="gray.50"
          w={['full', 'full', '560px']}
          h={['full', 'full', '400px']}
          pl="40px"
          pr="37px"
          pb="40px"
          pt="27px"
        >
          <ModalHeader padding="0px">
            <Text fontSize="20px" color="violet.500">
              Quando usar a Senha Numérica?
            </Text>
            <Divider mt="26px" mb="24px" />
          </ModalHeader>
          <ModalBody
            justifyContent="center"
            alignItems="center"
            h="full"
            w="full"
            padding="0px"
          >
            <Flex h="full" w="full" flexDir="column" gap="2px">
              <Text fontSize="14px" color="black" fontWeight="500">
                A senha numérica é específica para uso nos aplicativos:
              </Text>

              <Text fontSize="14px" color="violet.500" fontWeight="700">
                SMART POS - FRENTE DE CAIXA FOOD - PDV OFFLINE.
              </Text>

              <Text fontSize="14px" color="black" fontWeight="500">
                Para usuários desses produtos, a senha é utilizada no login do
                aplicativo.
              </Text>

              <Text
                fontSize="14px"
                color="black"
                fontWeight="700"
                letterSpacing="0px"
                mt="14px"
              >
                Se a sua conta não utiliza nenhum desses recursos, não cadastre
                a senha numérica.
                <Text
                  ml="4px"
                  as="span"
                  fontSize="14px"
                  color="black"
                  fontWeight="500"
                >
                  Não é possível utilizá-la para login do sistema online.
                </Text>
              </Text>
            </Flex>
          </ModalBody>
          <ModalFooter justifyContent="center" display="flex" padding="0px">
            <Button
              w="120px"
              mr="24px"
              colorScheme="gray"
              variant="outlineDefault"
              borderRadius="full"
              onClick={() => {
                onReject({ success: false });
                onClose();
              }}
              height="32px"
              fontWeight="500"
              fontSize="14px"
            >
              Cancelar
            </Button>
            <Button
              w="160px"
              colorScheme="violet"
              variant="solid"
              borderRadius="full"
              fontWeight="500"
              fontSize="14px"
              height="32px"
              onClick={handleCadastrarSenha}
            >
              Cadastrar Senha
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
