import React, { memo } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ContainerInputPadrao from 'components/Layout/CampoContainer/CampoContainerPadrao';
import {
  SwitchContainer,
  SwitchText,
  SwitchIcon,
  HiddenCheckbox,
} from './styles';

interface CheckBoxAtivoInativoProps {
  id: string;
  name: string;
  label: string;
  defaultValue?: any;
  control: any;
  readonly?: boolean;
}

const CheckBoxAtivoInativo = ({
  id,
  name,
  label,
  control,
  readonly,
  defaultValue,
}: CheckBoxAtivoInativoProps) => {
  const { t } = useTranslation();

  return (
    <ContainerInputPadrao id={id} label={label} error={undefined}>
      <Controller
        defaultValue={defaultValue || null}
        render={({ field }) => (
          <SwitchContainer
            id={id}
            value={field.value}
            style={{
              cursor: readonly ? '' : 'pointer',
            }}
            onClick={() => {
              if (readonly) return;
              field.onChange(!field.value);
            }}
          >
            <SwitchText>{field.value ? t('Ativo') : t('Inativo')}</SwitchText>
            <SwitchIcon />

            <HiddenCheckbox
              id={`hidden-checkbox-${id}`}
              checked={field.value}
              onChange={() => {
                if (readonly) return;
                field.onChange(!field.value);
              }}
            />
          </SwitchContainer>
        )}
        name={`${name}` as const}
        control={control}
      />
    </ContainerInputPadrao>
  );
};

export default memo(CheckBoxAtivoInativo);
