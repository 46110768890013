import React from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useMediaQuery,
  useDisclosure,
  ModalHeader,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { BrowserRouter, Route } from 'react-router-dom';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import CadastrarFornecedor from 'pages/ClientesFornecedores/Formulario/Cadastrar';

type ModalCadastrarFornecedorResponse = {
  fornecedor: any;
};

type ModalCadastrarFornecedorProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalCadastrarFornecedorResponse> & {
    inputValue: string;
  };

export const ModalCadastrarFornecedor = create<
  ModalCadastrarFornecedorProps,
  ModalCadastrarFornecedorResponse
>(({ onResolve, onReject, inputValue, ...rest }) => {
  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  return (
    <ModalPadraoChakra size="full" {...rest} isOpen={isOpen} onClose={onClose}>
      <ModalContent mx="6" my="6">
        <ModalHeader
          mt={isSmallerThan900 ? 12 : undefined}
          mb={isSmallerThan900 ? 8 : undefined}
          borderBottom="1px"
          borderColor="gray.100"
          px="0"
          mx={{ base: 6, md: 8 }}
        >
          Cadastrar Fornecedor
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p={{ base: 6, md: 8 }}>
          <BrowserRouter>
            <Route>
              <CadastrarFornecedor
                cadastroPdv
                nomeOuCpfCnpjCliente={inputValue}
                cadastroSucessoCallback={(fornecedor) => {
                  onResolve({ fornecedor });
                }}
              />
            </Route>
          </BrowserRouter>
        </ModalBody>
      </ModalContent>
    </ModalPadraoChakra>
  );
});
