import React, { useRef, memo } from 'react';

import ValidarPermissao from 'components/Validacao/ValidarPermissao';

import { Tooltip } from 'react-bootstrap';
import OverlayTriggerPadrao from 'components/OverlayTriggerPadrao';
import {
  Dropdown as StyledDropdown,
  DropdownToggle,
  DropdownItem,
  ShowMoreIcon,
  DropdownItemDisabled,
} from './styles';

interface DropdownItemInterface {
  title: string;
  onClick: () => void;
  funcionalidadePermissao?: string;
  disabled?: boolean;
  tooltipDisabled?: string;
  hidden?: boolean;
}

interface ButtonComOpcoesProps {
  id: string;
  dropdownItems?: DropdownItemInterface[];
  onSelect?: () => void;
  iconHeight?: string;
  buttonContent?: string | JSX.Element | React.ReactNode;
  drop?: 'top' | 'bottom' | 'left' | 'right';
  disabled?: boolean;
}

const ButtonComOpcoes = ({
  id,
  dropdownItems,
  onSelect,
  iconHeight,
  buttonContent,
  drop,
  disabled,
  ...rest
}: ButtonComOpcoesProps) => {
  const dropdownToggleRef = useRef() as React.MutableRefObject<HTMLElement>;

  return (
    <StyledDropdown onSelect={onSelect} drop={drop}>
      <DropdownToggle
        id={id}
        ref={dropdownToggleRef}
        {...rest}
        disabled={disabled}
      >
        {buttonContent || <ShowMoreIcon size={iconHeight} />}
      </DropdownToggle>
      {dropdownItems && (
        <StyledDropdown.Menu>
          {dropdownItems
            .filter((dropdownItem) => !dropdownItem.hidden)
            .map((dropdownItem) => {
              if (dropdownItem.disabled) {
                return (
                  <OverlayTriggerPadrao
                    overlay={(props: any) => {
                      return (
                        <Tooltip {...props}>
                          {dropdownItem.tooltipDisabled}
                        </Tooltip>
                      );
                    }}
                  >
                    <DropdownItemDisabled key={dropdownItem.title}>
                      {dropdownItem.title}
                    </DropdownItemDisabled>
                  </OverlayTriggerPadrao>
                );
              }

              if (!dropdownItem.funcionalidadePermissao) {
                return (
                  <DropdownItem
                    key={dropdownItem.title}
                    onClick={dropdownItem.onClick}
                  >
                    {dropdownItem.title}
                  </DropdownItem>
                );
              }

              return (
                <ValidarPermissao
                  key={dropdownItem.title}
                  funcionalidade={dropdownItem.funcionalidadePermissao}
                  tooltipWrap="hidden"
                >
                  {({ permitido, BloqueadoIcone }) => {
                    return (
                      <DropdownItem
                        bloqueado={permitido ? 0 : 1}
                        onClick={permitido ? dropdownItem.onClick : undefined}
                        id={dropdownItem.title}
                      >
                        {BloqueadoIcone && (
                          <BloqueadoIcone
                            bloqueioPermissaoIconeStyle={{
                              position: 'absolute',
                              left: '15px',
                              color: 'var(--sti-ck-colors-gray-100)',
                              fontSize: '0.9375rem',
                            }}
                            bloqueioPlanoIconeStyle={{
                              position: 'absolute',
                              left: '15px',
                              color: 'var(--sti-ck-colors-gray-100)',
                              fontSize: '0.9375rem',
                            }}
                          />
                        )}

                        {dropdownItem.title}
                      </DropdownItem>
                    );
                  }}
                </ValidarPermissao>
              );
            })}
        </StyledDropdown.Menu>
      )}
    </StyledDropdown>
  );
};

export default memo(ButtonComOpcoes);
