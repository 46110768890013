import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { ModelosFiscais } from 'constants/enum/fiscal/modelosFiscais';
import { StatusFiscais } from 'constants/enum/fiscal/statusFiscal';

export interface DocumentoFiscal {
  id: string;
  modeloFiscal: ModelosFiscais;
  status: StatusFiscais;
  numero: string;
  dataEmissao: Date;

  cpfCnpj?: string;
  transportadoraOpcaoSelect?: { id: string; nome: string };

  dadosAdicionais: string;
  dadosAdicionaisTributos?: string;
  numeroPedidoExterno: string;
  modalidadeFrete: number;
  placaTransportadora: string;
  ufVeiculoTransportadora: string;
  pesoLiquido: number;
  pesoBruto: number;
  quantidadeVolumeTransportadora: number;
  especieVolumeTransportadora: string;
  marcaVolumeTransportadora: string;
  numeracaoVolumeTransportadora: string;
  localEmbarque?: string;
  ufEmbarque?: string;

  operacaoComIntermediador: number;
  cnpjIntermediador: string;
  identificacaoNoIntermediador: string;
}

const obterNotaFiscalAlterar = async (
  operacaoId: string,
  modeloFiscal: number
): Promise<DocumentoFiscal | undefined> => {
  const response = await api.get<void, ResponseApi<DocumentoFiscal>>(
    ConstanteEnderecoWebservice.PEDIDOORCAMENTOVENDA_OBTER_NOTA_FISCAL_ALTERAR,
    { params: { operacaoId, modeloFiscal } }
  );

  if (response?.avisos) {
    response.avisos.map((item: string) => toast.warning(item));
  }

  if (response?.sucesso) {
    return response.dados;
  }

  return undefined;
};

export default obterNotaFiscalAlterar;
