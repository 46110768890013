import React from 'react';
import { OverlayTrigger, OverlayTriggerProps } from 'react-bootstrap';
import { useFullScreenContext } from 'store/FullScreen';

const OverlayTriggerPadrao = ({ children, ...props }: OverlayTriggerProps) => {
  const { handleFullScreen } = useFullScreenContext();

  return (
    <OverlayTrigger
      container={handleFullScreen.node}
      placement="auto"
      delay={{ show: 200, hide: 300 }}
      {...props}
    >
      {children}
    </OverlayTrigger>
  );
};

export default OverlayTriggerPadrao;
