import React from 'react';
import { useTranslation } from 'react-i18next';
import { SalvarInserirNovoIcon, SalvarConfirmarIcon } from 'icons';
import ButtonPadrao from 'components/Button/ButtonPadrao';
import { Col, Form, Modal } from 'react-bootstrap';
import SelectPadrao from 'components/Select/SelectPadrao';
import EstadosEnum from 'constants/enum/estados';
import InputCpfCnpj from 'components/Input/InputCpfCnpj';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import InputNumerico from 'components/Input/InputNumerico';
import ModalPadrao from 'components/Modal/ModalPadrao';
import { useForm, yupResolver } from './validationForm';

interface UncontrolledFormInterface {
  show: boolean;
  onHide: () => void;
}

const DocumentoFiscalModal: React.FC<UncontrolledFormInterface> = ({
  show,
  onHide,
}) => {
  const { t } = useTranslation();
  const { setValue, getValues: parentGetValues } = useFormContext();

  const {
    formState: { errors },
    control,
    handleSubmit,
    watch,
    reset,
    setFocus,
  } = useForm({
    resolver: yupResolver,
    defaultValues: {
      tipoDocumentoReferenciado: 0,
      modelo: '',
      uf: '',
      chaveAcesso: '',
      modeloFiscal: null,
      codEstado: '',
      emissaoMes: '',
      emissaoAno: '',
      cnpj: '',
      numero: '',
      serie: '',
    },
    shouldUnregister: true,
  });

  const watchTipoDocumentoReferenciado = watch('tipoDocumentoReferenciado', 0);

  const handleAddItem = (data: any) => {
    const itemsAdicionados = parentGetValues('documentoFiscalReferenciados');

    if (data.tipoDocumentoReferenciado === 0) {
      if (
        itemsAdicionados.some(
          (item: any) =>
            item.tipoDocumentoReferenciado === 0 &&
            item.chaveAcesso === data.chaveAcesso
        )
      ) {
        toast.warning(
          t('Já foi adicionado um item com a chave de acesso informada.')
        );
        return false;
      }
    }

    let anoMesEmissao = null;

    if (data.tipoDocumentoReferenciado === 1) {
      if (
        itemsAdicionados.some(
          (item: any) =>
            item.tipoDocumentoReferenciado === 1 &&
            item.numeroNotaFiscal === data.numeroNotaFiscal &&
            item.serie === data.serie
        )
      ) {
        toast.warning(t('Já foi adicionado um item com esse número e série.'));
        return false;
      }

      anoMesEmissao = `${data.emissaoMes}${data.emissaoAno.substr(2)}`;
    }

    setValue('documentoFiscalReferenciados', [
      ...itemsAdicionados,
      { ...data, anoMesEmissao },
    ]);

    return true;
  };

  const onSubmitReset = handleSubmit(async (data) => {
    const success = await handleAddItem(data);

    if (success) {
      reset({
        tipoDocumentoReferenciado: 0,
        modelo: '',
        uf: '',
        chaveAcesso: '',
      });
    }
  });

  const onSubmit = handleSubmit(async (data) => {
    const success = await handleAddItem(data);

    if (success) {
      onHide();
    }
  });

  const meses = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
  ];

  const anos = [
    new Date().getFullYear(),
    new Date().getFullYear() - 1,
    new Date().getFullYear() - 2,
  ];

  return (
    <>
      {show && (
        <ModalPadrao
          isOpen={show}
          handleOnHide={onHide}
          title={t('Adicionar documento fiscal')}
          subtitle={t('Informe os dados de documento fiscal da nota.')}
          onEntered={() => {
            setFocus('tipoDocumentoReferenciado');
            reset({
              tipoDocumentoReferenciado: 0,
              modelo: '',
              uf: '',
              chaveAcesso: '',
            });
          }}
          maxWidth={800}
          size="lg"
        >
          <Form>
            <Modal.Body>
              <Form.Row>
                <Col xl={6}>
                  <SelectPadrao
                    id="tipoDocumentoReferenciado"
                    name="tipoDocumentoReferenciado"
                    label={t('Tipo de documento referenciado')}
                    noSelectedText=""
                    defaultValue={0}
                    control={control}
                    error={errors.tipoDocumentoReferenciado}
                    options={[
                      {
                        label: 'NFe / NFCe / SAT',
                        value: 0,
                      },
                      {
                        label: 'NF (modelo 1 / 2)',
                        value: 1,
                      },
                    ]}
                    readonly={false}
                    required
                  />
                </Col>
              </Form.Row>

              {watchTipoDocumentoReferenciado !== 1 && (
                <Form.Row>
                  <Col xl={12}>
                    <InputNumerico
                      type="text"
                      id="chaveAcesso"
                      name="chaveAcesso"
                      label={t('Chave de acesso')}
                      maxLength={44}
                      control={control}
                      error={errors.chaveAcesso}
                      disabled={false}
                      required
                      convertValueToString
                    />
                  </Col>
                </Form.Row>
              )}

              {watchTipoDocumentoReferenciado === 1 && (
                <>
                  <Form.Row>
                    <Col md={3} xl={4}>
                      <SelectPadrao
                        id="modeloFiscal"
                        name="modeloFiscal"
                        label={t('Modelo')}
                        noSelectedText=""
                        placeholder="Selecione"
                        defaultValue={null}
                        control={control}
                        error={errors.modeloFiscal}
                        options={[
                          {
                            label: 'Modelo 1',
                            value: 1,
                          },
                          {
                            label: 'Modelo 2',
                            value: 2,
                          },
                        ]}
                        readonly={false}
                        required
                      />
                    </Col>
                    <Col md={3} xl={4}>
                      <SelectPadrao
                        id="codEstado"
                        name="codEstado"
                        label={t('UF')}
                        noSelectedText=""
                        defaultValue=""
                        placeholder="Selecione"
                        control={control}
                        error={errors.codEstado}
                        options={Object.entries(EstadosEnum.properties).map(
                          (value: any) => {
                            return (
                              {
                                label: t(value[1].sigla),
                                value: value[1].codigo,
                              } || {}
                            );
                          }
                        )}
                        readonly={false}
                        required
                      />
                    </Col>
                    <Col md={3} xl={4}>
                      <SelectPadrao
                        id="emissaoMes"
                        name="emissaoMes"
                        label={t('Mês da emissão')}
                        noSelectedText=""
                        defaultValue=""
                        placeholder="Selecione"
                        control={control}
                        error={errors.emissaoMes}
                        options={meses.map((value: any) => {
                          return (
                            {
                              label: value,
                              value,
                            } || {}
                          );
                        })}
                        readonly={false}
                        required
                      />
                    </Col>
                    <Col md={3} xl={4}>
                      <SelectPadrao
                        id="emissaoAno"
                        name="emissaoAno"
                        label={t('Ano da emissão')}
                        noSelectedText=""
                        defaultValue=""
                        placeholder="Selecione"
                        control={control}
                        error={errors.emissaoAno}
                        options={anos.map((value: any) => {
                          return (
                            {
                              label: value,
                              value,
                            } || {}
                          );
                        })}
                        readonly={false}
                        required
                      />
                    </Col>
                    <Col md={3} xl={4}>
                      <InputCpfCnpj
                        id="cnpj"
                        name="cnpj"
                        label={t('CNPJ emitente')}
                        control={control as any}
                        error={errors.cnpj}
                        disabled={false}
                        setError={() => {}}
                        onlyCnpj
                        showSearchIcon={false}
                        required
                      />
                    </Col>
                    <Col md={3} xl={4}>
                      <InputNumerico
                        type="text"
                        id="numero"
                        name="numero"
                        label={t('Número da nota fiscal')}
                        maxLength={9}
                        control={control}
                        error={errors.numero}
                        disabled={false}
                        required
                        convertValueToString
                      />
                    </Col>
                    <Col md={3} xl={4}>
                      <InputNumerico
                        type="text"
                        id="serie"
                        name="serie"
                        label={t('Série')}
                        required
                        placeholder=""
                        maxLength={3}
                        error={errors.serie}
                        disabled={false}
                        control={control}
                      />
                    </Col>
                  </Form.Row>
                </>
              )}
            </Modal.Body>

            <Modal.Footer>
              <ButtonPadrao
                id="adicionarInserirNovo"
                type="button"
                variant="outline-secondary"
                name="salvarInserirNovo"
                text={t('Adicionar e inserir novo')}
                icon={SalvarInserirNovoIcon}
                disabled={false}
                onClick={onSubmitReset}
                style={{ height: '35px', width: '100%', maxWidth: '225px' }}
              />
              <ButtonPadrao
                id="adicionarDocumentoReferenciado"
                name="adicionarDocumentoReferenciado"
                text={t('Adicionar')}
                icon={SalvarConfirmarIcon}
                type="button"
                variant="success"
                onClick={onSubmit}
                style={{ height: '35px', width: '100%', maxWidth: '120px' }}
              />
            </Modal.Footer>
          </Form>
        </ModalPadrao>
      )}
    </>
  );
};

export default DocumentoFiscalModal;
