import { TabPanels, TabPanel, ModalContent, ModalBody } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import auth from 'modules/auth';
import ConstanteFuncionalidades from 'constants/permissoes';
import { useFinalizarContext } from 'store/PDV/Finalizar';
import StatusFiscaisEnum from 'constants/enum/fiscal/statusFiscal';
import ModelosFiscaisEnum from 'constants/enum/fiscal/modelosFiscais';
import ConstanteRotasPDV from 'constants/rotasPDV';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import ConditionalWrapper from 'components/Geral/ConditionalWrapper';
import { MobileModalMenuHeader } from 'components/PDV/Layout/MobileModalMenu/MobileModalMenuHeader';

import CompartilharPanel from '../Compartilhar';
import NFCePanel from '../NFCe';
import NFePanel from '../NFe';
import EmProcessamentoPanel from '../EmProcessamento';
import { LinkPagamento } from '../LinkPagamento';

interface TabsContentProps {
  asModal?: boolean;
}

const TabsContent = ({ asModal = false }: TabsContentProps) => {
  const history = useHistory();

  const { operacao, tabIndex, handleTabsChange } = useFinalizarContext();

  function onModalCompartilharClose() {
    const hasPermissionNF = auth.possuiPermissao(
      ConstanteFuncionalidades.NOTA_FISCAL_EMITIR
    ).permitido;

    if (hasPermissionNF) {
      handleTabsChange(-1);
    } else {
      history.push(ConstanteRotasPDV.PDV_HOME);
    }
  }

  return (
    <ConditionalWrapper
      condition={asModal}
      wrapper={(wrappedChildren) => (
        <ModalPadraoChakra
          size="full"
          isOpen={tabIndex !== -1}
          onClose={onModalCompartilharClose}
        >
          <ModalContent
            marginBottom={0}
            marginTop={0}
            h="unset"
            bg="gray.50"
            borderRadius="none"
          >
            {tabIndex === 0 && (
              <MobileModalMenuHeader
                voltarIconColor="black"
                color="primary.50"
                onClose={onModalCompartilharClose}
                title="Compartilhar"
              />
            )}
            <ModalBody p={0} bg="primary.50">
              {wrappedChildren}
            </ModalBody>
          </ModalContent>
        </ModalPadraoChakra>
      )}
    >
      <TabPanels bg="gray.50" sx={{ '& > div': { p: 0 } }}>
        <TabPanel>
          <CompartilharPanel asModal={asModal} />
        </TabPanel>
        <TabPanel>
          {operacao &&
          operacao.NFCe &&
          operacao.NFCe.status === StatusFiscaisEnum.EmProcessamento ? (
            <EmProcessamentoPanel
              modeloFiscal={ModelosFiscaisEnum.NFCe}
              documentoFiscalId={operacao.NFCe.id}
            />
          ) : (
            <NFCePanel />
          )}
        </TabPanel>
        <TabPanel>
          {operacao &&
          operacao.NFe &&
          operacao.NFe.status === StatusFiscaisEnum.EmProcessamento ? (
            <EmProcessamentoPanel
              modeloFiscal={ModelosFiscaisEnum.NFe}
              documentoFiscalId={operacao.NFe.id}
            />
          ) : (
            <NFePanel />
          )}
        </TabPanel>
        <TabPanel>
          <LinkPagamento />
        </TabPanel>
      </TabPanels>
    </ConditionalWrapper>
  );
};

export default TabsContent;
