import {
  Flex,
  Icon,
  Text,
  Box,
  Button,
  Divider,
  useMediaQuery,
  useToken,
} from '@chakra-ui/react';
import { FiX } from 'react-icons/fi';
import { useCallback, useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import { DotLoader } from 'react-spinners';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteFuncionalidades from 'constants/permissoes';
import auth from 'modules/auth';
import { useMetasComissoesRelatorioVendasContext } from 'store/MetasComissoes/MetasComissoesRelatorioVendas';

import { CompartilharMenuIcon, IconSeta } from 'icons';
import AsyncSelect from 'components/PDV/Select/AsyncSelectPadrao';
import { MonthInput } from 'components/update/Input/MonthInput';

import { PerformanceVendedores } from './PerformanceVendedores';
import { CalculoComissaoVendedores } from './CalculoComissao';
import { CalculoComissaoIndividual } from '../CalculoComissaoIndividual/index';
import { ResumoVendedorIndividual } from '../ResumoVendedorIndividual/index';
import {
  ListaPerformanceVendedoresProps,
  CalculoComissao,
  ResumoLojaProps,
} from '../../Types/validationsForm';
import { HistoricoComissao } from '../HistoricoComissao';

type RelatorioVendedorComPermissaoProps = {
  handleVoltarParaMetasComissoes: () => void;
  handleImprimirOperacao: () => void;
};

type VendedorResponse = { id: string; nome: string };

export const RelatorioVendedorComPermissao = ({
  handleVoltarParaMetasComissoes,
  handleImprimirOperacao,
}: RelatorioVendedorComPermissaoProps) => {
  const [listaResumoLojas, setListaResumoLojas] = useState<ResumoLojaProps>();
  const [listaPerformanceVendedores, setListaPerformanceVendedores] = useState<
    ListaPerformanceVendedoresProps[]
  >([]);
  const [
    listarValoresCalculoComissao,
    setListarValoresCalculoComissa,
  ] = useState<CalculoComissao[]>([]);

  const { vendedorVinculado } = useMetasComissoesRelatorioVendasContext();

  const { watch } = useFormContext();

  const [isLargerThan1200] = useMediaQuery('(min-width: 1200px)');
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const primary50 = useToken('colors', 'primary.50');

  const [mesAnoWatch, vendedorWatch] = watch(['mesAno', 'todosVendedores']);

  const handleGetVendedores = useCallback(async () => {
    const response = await api.get<void, ResponseApi<VendedorResponse[]>>(
      ConstanteEnderecoWebservice.VENDEDOR_LISTAR_SELECT_POR_LOJA_POR_PERIODO,
      {
        params: { periodo: mesAnoWatch },
      }
    );

    if (response.avisos) {
      response.avisos.forEach((aviso) => toast.warning(aviso));
    }

    if (response.sucesso && response.dados) {
      return response.dados.map((vendedor) => ({
        label: vendedor.nome,
        value: vendedor.id,
      }));
    }
    return [];
  }, [mesAnoWatch]);

  const possuiPermissaoMetaComissao = auth.possuiPermissao(
    ConstanteFuncionalidades.METAS_COMISSAO
  ).permitido;

  const obterResumoDasLojas = useCallback(async () => {
    const response = await api.get<void, ResponseApi<ResumoLojaProps>>(
      ConstanteEnderecoWebservice.RELATORIO_METAS_RESUMO_DAS_LOJAS,
      {
        params: {
          mesAno: mesAnoWatch,
          vendedorId: vendedorWatch?.value,
        },
      }
    );

    if (response.avisos) {
      response.avisos.forEach((aviso) => toast.warning(aviso));
    }

    if (response.sucesso && response.dados) {
      setListaResumoLojas(response.dados);
    }
    return {};
  }, [mesAnoWatch, vendedorWatch]);

  const obterCalculoComissao = useCallback(async () => {
    const response = await api.get<void, ResponseApi<CalculoComissao[]>>(
      ConstanteEnderecoWebservice.RELATORIO_METAS_CALCULO_COMISSAO,
      {
        params: {
          mesAno: mesAnoWatch,
          vendedorId: vendedorWatch?.value,
        },
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        setListarValoresCalculoComissa(response.dados);
      }
    }
    return [];
  }, [mesAnoWatch, vendedorWatch]);

  const obterPerformanceVendedores = useCallback(async () => {
    const response = await api.get<
      void,
      ResponseApi<ListaPerformanceVendedoresProps[]>
    >(
      ConstanteEnderecoWebservice.RELATORIO_METAS_COMISSOES_OBTER_PERFORMANCE_VENDEDOR,
      {
        params: {
          mesAno: mesAnoWatch,
          vendedorId: vendedorWatch?.value,
        },
      }
    );
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        setListaPerformanceVendedores(response.dados);
      }
    }

    return [];
  }, [mesAnoWatch, vendedorWatch]);

  const listaIndividualCalculoComissao = useCallback(() => {
    const newCalculoComissao = [...listarValoresCalculoComissao];

    if (vendedorWatch?.value || vendedorVinculado?.id) {
      return newCalculoComissao[0];
    }
    return {} as CalculoComissao;
  }, [listarValoresCalculoComissao, vendedorWatch, vendedorVinculado])();

  useEffect(() => {
    obterPerformanceVendedores();
  }, [obterPerformanceVendedores]);

  useEffect(() => {
    obterCalculoComissao();
  }, [obterCalculoComissao]);

  useEffect(() => {
    obterResumoDasLojas();
  }, [obterResumoDasLojas]);

  return (
    <>
      <Flex
        mb="13px"
        w="full"
        direction={isLargerThan900 ? 'row' : 'column'}
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex
          direction={isLargerThan900 ? 'row' : 'column'}
          justifyContent="left"
          alignItems="center"
          w={isLargerThan900 ? '' : 'full'}
        >
          <Flex
            w={isLargerThan900 ? '' : 'full'}
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex justifyContent="left" alignItems="center">
              <Icon
                color="primary.50"
                w={4}
                mr={isLargerThan900 ? '23px' : '10px'}
                cursor="pointer"
                fontWeight="extrabold"
                onClick={() => handleVoltarParaMetasComissoes()}
                h={4}
                as={IconSeta}
              />
              <Text fontSize="18px" color="primary.50">
                Relatório de metas e comissões
              </Text>
            </Flex>
            {!isLargerThan900 && (
              <Icon
                as={FiX}
                w={4}
                h={4}
                cursor="pointer"
                onClick={() => handleVoltarParaMetasComissoes()}
              />
            )}
          </Flex>
          {!isLargerThan900 && (
            <Divider h="1px" mb="10px" mt="10px" bg="primary.50" />
          )}
          {possuiPermissaoMetaComissao && (
            <Box
              mb={isLargerThan900 ? '' : '10px'}
              ml={isLargerThan900 ? '40px' : ''}
              w={
                isLargerThan1200 ? '360px' : isLargerThan900 ? '300px' : 'full'
              }
            >
              <AsyncSelect
                id="todosVendedores"
                name="todosVendedores"
                placeholder="Informe o vendedor"
                required
                handleGetOptions={handleGetVendedores}
                asControlledByObject
                isClearable
              />
            </Box>
          )}

          <Box
            ml={isLargerThan900 ? '24px' : ''}
            mb={isLargerThan900 ? '' : '10px'}
            w={isLargerThan1200 ? '250px' : isLargerThan900 ? '200px' : 'full'}
          >
            <MonthInput
              name="mesAno"
              isRequired
              colSpan={{ base: 12, sm: 4, md: 4, lg: 3, xl: 2 }}
            />
          </Box>
        </Flex>
        <Flex
          w={isLargerThan900 ? '' : 'full'}
          justifyContent="right"
          alignItems="center"
        >
          <Button
            mr={isLargerThan1200 ? '32px' : isLargerThan900 ? '22px' : ''}
            w={isLargerThan900 ? '153px' : 'full'}
            variant="sucess"
            bg="primary.50"
            borderRadius="md"
            borderColor="primary.50"
            color="white"
            onClick={() => handleImprimirOperacao()}
            leftIcon={<Icon mr="5px" as={CompartilharMenuIcon} fontSize="md" />}
          >
            Compartilhar
          </Button>
          {isLargerThan900 && (
            <Icon
              as={FiX}
              w={4}
              h={4}
              cursor="pointer"
              onClick={() => handleVoltarParaMetasComissoes()}
            />
          )}
        </Flex>
      </Flex>
      <Divider h="1px" mb="30px" bg="primary.50" />
      <Box>
        <Text mb="4px" fontSize="18px" color="primary.50">
          Resumo da loja
        </Text>

        {listaResumoLojas !== undefined ? (
          <ResumoVendedorIndividual listaResumoVendedor={listaResumoLojas} />
        ) : (
          <Flex justifyContent="center" alignItems="center">
            <DotLoader size="30" color={primary50} />
          </Flex>
        )}
      </Box>
      {possuiPermissaoMetaComissao && (
        <Box mt="24px">
          <Text mb="4px" fontSize="18px" color="primary.50">
            Performance dos vendedores
          </Text>
          {listaPerformanceVendedores.length > 0 ? (
            <PerformanceVendedores
              listaPerformanceVendedores={listaPerformanceVendedores}
            />
          ) : (
            <Flex justifyContent="center" alignItems="center">
              <DotLoader size="30" color={primary50} />
            </Flex>
          )}
        </Box>
      )}

      <Box mt="24px">
        <Text mb="4px" fontSize="18px" color="primary.50">
          Cálculo de comissão
        </Text>
        {possuiPermissaoMetaComissao ? (
          <>
            {listarValoresCalculoComissao.length > 0 ? (
              <CalculoComissaoVendedores
                listarValoresCalculoComissao={listarValoresCalculoComissao}
              />
            ) : (
              <Flex justifyContent="center" alignItems="center">
                <DotLoader size="30" color={primary50} />
              </Flex>
            )}
          </>
        ) : (
          <>
            {listaIndividualCalculoComissao?.nome !== undefined ? (
              <CalculoComissaoIndividual
                listaIndividualCalculoComissao={listaIndividualCalculoComissao}
              />
            ) : (
              <Flex justifyContent="center" alignItems="center">
                <DotLoader size="30" color={primary50} />
              </Flex>
            )}
          </>
        )}
      </Box>

      <Box mt="24px">
        <Text mb="4px" fontSize="18px" color="primary.50">
          Histórico de comissão
        </Text>
        <HistoricoComissao />
      </Box>
    </>
  );
};
