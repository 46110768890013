import { Flex, GridItem, Text } from '@chakra-ui/react';

import ConstanteFuncionalidades from 'constants/permissoes';
import auth from 'modules/auth';

import { NumberInput } from 'components/update/Input/NumberInput';

import { ProdutoCor } from '../validationForm';

type CamposInformarValoresTabelaPrecoProps = {
  casasDecimaisValor: number;
  produtoSelecionado?: ProdutoCor;
  markup: number;
};

export const CamposInformarValoresTabelaPreco = ({
  casasDecimaisValor,
  produtoSelecionado,
  markup,
}: CamposInformarValoresTabelaPrecoProps) => {
  const possuiPermissaoVisualizarPrecoCusto = auth.possuiPermissao(
    ConstanteFuncionalidades.USUARIO_VISUALIZAR_PRECO_CUSTO
  ).permitido;

  return (
    <>
      <GridItem colSpan={{ base: 12, md: 3 }}>
        <NumberInput
          id="precoCusto"
          name="precoCusto"
          label="Custo"
          pattern="(^[\d-\)\(]+$)"
          bgLeftElement="gray.50"
          esconderValor={!possuiPermissaoVisualizarPrecoCusto}
          editarFundoLeftElemento
          leftElement="R$"
          placeholder={`0,${'0'.repeat(casasDecimaisValor)}`}
          scale={casasDecimaisValor}
          isDisabled
        />
      </GridItem>
      <GridItem colSpan={{ base: 12, md: 3 }}>
        <NumberInput
          id="preco"
          name="preco"
          label="Preço atual"
          pattern="(^[\d-\)\(]+$)"
          bgLeftElement="gray.50"
          editarFundoLeftElemento
          leftElement="R$"
          placeholder={`0,${'0'.repeat(casasDecimaisValor)}`}
          scale={casasDecimaisValor}
          isDisabled
        />
      </GridItem>
      <GridItem colSpan={{ base: 12, md: 4 }}>
        <NumberInput
          id="precoVenda"
          name="precoVenda"
          label="Preço novo"
          pattern="(^[\d-\)\(]+$)"
          bgLeftElement="gray.50"
          editarFundoLeftElemento
          leftElement="R$"
          placeholder={`0,${'0'.repeat(casasDecimaisValor)}`}
          scale={casasDecimaisValor}
          isDisabled={!produtoSelecionado}
        />
      </GridItem>

      <GridItem colSpan={{ base: 12, md: 1 }}>
        {produtoSelecionado && (
          <Flex justifyContent="flex-start" alignItems="end" flex="3" h="45px">
            <Text whiteSpace="nowrap" color="primary.100">
              {`Markup: ${markup.toLocaleString('pt-BR', {
                minimumFractionDigits: casasDecimaisValor,
                maximumFractionDigits: casasDecimaisValor,
              })}%`}
            </Text>
          </Flex>
        )}
      </GridItem>
    </>
  );
};
