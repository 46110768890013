import React from 'react';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

import NotasFiscaisListar from '../pages/NotasFiscais/Listar';
import NotasFiscaisCadastrar from '../pages/NotasFiscais/Formulario/Cadastrar';
import NotasFiscaisAlterar from '../pages/NotasFiscais/Formulario/Alterar';
import { NotasFiscaisVisualizar } from '../pages/NotasFiscais/Formulario/Visualizar';

const NotasFiscaisRoutes = [
  <LayoutGuard
    key="notas-fiscais-listar"
    permissaoFuncionalidade={ConstanteFuncionalidades.NOTA_FISCAL_EMITIR}
    component={NotasFiscaisListar}
    breadcrumb={[
      {
        title: 'Fiscal',
      },
      {
        title: 'Notas Fiscais',
      },
    ]}
    path={ConstanteRotas.NOTA_FISCAL}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="notas-fiscais-cadastrar"
    permissaoFuncionalidade={ConstanteFuncionalidades.NOTA_FISCAL_EMITIR}
    component={NotasFiscaisCadastrar}
    breadcrumb={[
      {
        title: 'Fiscal',
      },
      {
        title: 'Notas Fiscais',
        path: ConstanteRotas.NOTA_FISCAL,
      },
      {
        title: 'Nova',
      },
    ]}
    path={ConstanteRotas.NOTA_FISCAL_CADASTRAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="notas-fiscais-alterar"
    permissaoFuncionalidade={ConstanteFuncionalidades.NOTA_FISCAL_EMITIR}
    component={NotasFiscaisAlterar}
    breadcrumb={[
      {
        title: 'Fiscal',
      },
      {
        title: 'Notas Fiscais',
        path: ConstanteRotas.NOTA_FISCAL,
      },
      {
        title: 'Editar',
      },
    ]}
    path={ConstanteRotas.NOTA_FISCAL_ALTERAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="notas-fiscais-visualizar"
    component={NotasFiscaisVisualizar}
    breadcrumb={[
      {
        title: 'Fiscal',
      },
      {
        title: 'Notas Fiscais',
        path: ConstanteRotas.NOTA_FISCAL,
      },
      {
        title: 'Visualizar',
      },
    ]}
    path={ConstanteRotas.NOTA_FISCAL_VISUALIZAR}
    exact
    meta={{ auth: true }}
  />,
];

export default NotasFiscaisRoutes;
