import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  VStack,
  Flex,
  useMediaQuery,
  Text,
  Box,
  Button,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import {
  cadastrarImpressaoSmartPos,
  getImpressaoSmartPos,
} from 'services/smartPos';
import { ItensImpressao } from 'constants/tipoImpressaoSmartPos';
import { enumTipoImpressaoFichaSmartPos } from 'constants/enum/enumTipoImpressaoFichaSmartPos';
import {
  EnumEmissaoSenhas,
  EnumImprimirViaProducao,
} from 'constants/enum/enumsConfigSmartPos';
import ConstanteRotas from 'constants/rotas';
import { useSmartPosContext } from 'store/SmartPos/SmartPosContext';
import ImagemExemploCorreta from 'assets/images/integracoes/smart_pos/exemploImpressaoCorreta.png';

import { ExibirImpressao } from 'pages/Integracoes/LojaAplicativos/SmartPos/Etapas/TextoImpressao/Components/ExibirImpressao';
import { ModalImpressao } from 'pages/Integracoes/LojaAplicativos/SmartPos/Etapas/TextoImpressao/ModalImpressao';
import { ModalImagemExemplo } from 'pages/Integracoes/LojaAplicativos/SmartPos/Etapas/ImagemImpressao/ModalImagemExemplo';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import { ArrastarImagemIcon } from 'icons';
import TextareaField from 'components/PDV/Textarea';
import Input from 'components/PDV/Input';
import ImagePickerPadrao from 'components/ImagePicker/ImagePickerPadrao';
import Loading from 'components/Layout/Loading/LoadingPadrao';

import { Container } from '../Layout/Container';

type FormData = {
  mensagem: string;
  foto: string;
  tipoImpressao: number;
  mensagemCupom: string;
  emissaoSenhas: boolean;
  imprimirViaProducao: boolean | null;
  titulo: string;
  scaleImg?: number;
};

export const Impressao = () => {
  const [
    abrirModalVisualizarImpressao,
    setAbrirModalVisualizarImpressao,
  ] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { ref, setIsStepAlterada } = useSmartPosContext();

  const formMethods = useForm<FormData>();

  const history = useHistory();

  const {
    watch,
    reset,
    handleSubmit: submit,
    formState,
    setValue,
  } = formMethods;

  const [fotoWatch, tipoImpressao, emissaoSenhas, scaleImg] = watch([
    'foto',
    'tipoImpressao',
    'emissaoSenhas',
    'scaleImg',
  ]);

  const scaleImgEmPorcentagem = scaleImg ? `${Math.round(scaleImg)}%` : '100%';

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

  const isFichaImpressao =
    tipoImpressao === enumTipoImpressaoFichaSmartPos.FICHA.value;

  const isTodasImpressoes =
    tipoImpressao === enumTipoImpressaoFichaSmartPos.FICHA_CUPOM.value;

  const isCupomImpressao =
    tipoImpressao === enumTipoImpressaoFichaSmartPos.CUPOM.value;

  const isNenhumaImpressao =
    tipoImpressao === enumTipoImpressaoFichaSmartPos.NENHUM.value;

  const getImpressaoSelecionada = useCallback(async () => {
    setIsLoading(true);
    const response = await getImpressaoSmartPos();

    if (response !== null) {
      reset({
        emissaoSenhas: !!response?.imprimirSenhaIdentificacao,
        mensagem: response?.mensagemFichaImpressao,
        mensagemCupom: response?.mensagemCupomImpressao,
        titulo: response?.tituloImpressao,
        tipoImpressao: response.tipoImpressao,
        imprimirViaProducao: !!response?.imprimirViaProducao,
        foto: response.logo,
        scaleImg: response.escalaLogo,
      });
      setIsLoading(false);
      setIsStepAlterada(false);
    }
    setIsLoading(false);
  }, [reset, setIsStepAlterada]);

  const handleChangeScaleImgValue = (value: number) => {
    setValue('scaleImg', value);
  };

  const handleVoltarAoDashboard = () => {
    history.push(ConstanteRotas.DASHBOARD);
  };

  const handleDownloadImagemExemplo = () => {
    fetch(ImagemExemploCorreta)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Imagem_exemplo.png');
        link.click();
      })
      .catch(() => {
        toast('Erro ao baixar a imagem');
      });
  };

  const handleAbrirModalImagemExemplo = () => {
    ModalImagemExemplo({ handleDownloadImagemExemplo });
  };

  const handleSubmit = submit(async (data) => {
    setIsLoading(true);
    const heightImgLogo = document?.getElementById('imagemPreview')
      ?.clientHeight;
    const widthImgLogo = document?.getElementById('imagemPreview')?.clientWidth;

    const response = await cadastrarImpressaoSmartPos(
      data.tipoImpressao === enumTipoImpressaoFichaSmartPos.NENHUM.value
        ? {
            tipoImpressao: data.tipoImpressao,
          }
        : {
            mensagemFichaImpressao: data.mensagem,
            mensagemCupomImpressao: data.mensagemCupom,
            tituloImpressao: data.titulo,
            imprimirSenhaIdentificacao: data?.emissaoSenhas,
            imprimirViaProducao: isFichaImpressao
              ? false
              : !!data?.imprimirViaProducao,
            escalaLogo: data.scaleImg ? data.scaleImg : 100,
            alturaLogo: `${heightImgLogo}px`,
            larguraLogo: `${widthImgLogo}px`,
            logo: data.foto,
            tipoImpressao: data.tipoImpressao,
          }
    );

    if (!response) {
      setIsLoading(false);
    }
    if (response) {
      toast.success('Cadastrado com sucesso');
      setIsStepAlterada(false);
    }
    setIsLoading(false);
  });

  useEffect(() => {
    getImpressaoSelecionada();
  }, [getImpressaoSelecionada]);

  useImperativeHandle(ref, () => ({
    handleSalvar: () => handleSubmit(),
    handleCancelar: () => {
      handleVoltarAoDashboard();
    },
  }));

  useEffect(() => {
    setIsStepAlterada(formState.isDirty);
  }, [formState, setIsStepAlterada]);

  return (
    <FormProvider {...formMethods}>
      <Flex gap="24px">
        {isLoading && <Loading />}
        <Container
          w={isLargerThan900 ? '65%' : 'full'}
          bg="gray.100"
          boxShadow="none"
          display="flex"
          height="fit-content"
          flexWrap={isLargerThan700 ? 'nowrap' : 'wrap'}
          gap="24px"
        >
          <Box w={isLargerThan700 ? '85%' : 'full'}>
            <VStack spacing="28px">
              <SelectPadrao
                name="tipoImpressao"
                label="Tipo de impressão"
                options={ItensImpressao.properties.map((itemTipoImpressao) => ({
                  label: itemTipoImpressao.title,
                  value: itemTipoImpressao.value,
                }))}
              />

              {!isNenhumaImpressao && (
                <>
                  <Input
                    name="titulo"
                    label="Título"
                    maxLength={45}
                    placeholder={`Digite o cabeçalho ${
                      isTodasImpressoes
                        ? ''
                        : isFichaImpressao
                        ? 'da ficha'
                        : 'do Cupom'
                    }`}
                    colSpan={12}
                  />
                  {(isFichaImpressao || isTodasImpressoes) && (
                    <TextareaField
                      id="mensagem"
                      name="mensagem"
                      maxLength={160}
                      label={
                        isTodasImpressoes ? 'Mensagem' : 'Mensagem para a ficha'
                      }
                      h="70px"
                      colSpan={12}
                      maxH="120px"
                      placeholder="Adicione as informações necessárias"
                    />
                  )}
                  {!isFichaImpressao && (
                    <TextareaField
                      id="mensagemCupom"
                      maxLength={160}
                      name="mensagemCupom"
                      label="Mensagem para o cupom"
                      h="70px"
                      colSpan={12}
                      maxH="120px"
                      placeholder="Adicione as informações necessárias"
                    />
                  )}
                  {isCupomImpressao && (
                    <Flex w="100%" gap="12px">
                      <SelectPadrao
                        name="emissaoSenhas"
                        label="Emissão de senhas"
                        options={EnumEmissaoSenhas.properties}
                        colSpan={12}
                      />
                      <SelectPadrao
                        name="imprimirViaProducao"
                        label="Imprimir via de produção"
                        options={EnumImprimirViaProducao.properties}
                        colSpan={12}
                      />
                    </Flex>
                  )}
                </>
              )}
            </VStack>
          </Box>

          {!isNenhumaImpressao && (
            <Box minW="270px">
              <Flex flexDir="column">
                <Flex
                  h="208px"
                  align="center"
                  justify="flex-start"
                  bg="transparent"
                  gap="12px"
                  filter="grayscale(1)"
                >
                  <Box w="190px">
                    <ImagePickerPadrao
                      id="foto"
                      name="foto"
                      icon={ArrastarImagemIcon}
                      isFormatSizeImage
                      background="white"
                      required={false}
                      label="Imagem"
                      h="190px"
                      scaleImg={scaleImgEmPorcentagem}
                      valueTamanhoImg={190}
                    />
                  </Box>
                  {fotoWatch && (
                    <Flex mt="18px">
                      <Slider
                        aria-label="scale-logo"
                        defaultValue={scaleImg || 100}
                        orientation="vertical"
                        min={30}
                        height="180px"
                        onChange={(val) => handleChangeScaleImgValue(val)}
                      >
                        <SliderMark value={30} ml="12px" mb="-8px">
                          0%
                        </SliderMark>
                        <SliderMark value={100} ml="12px" mb="-16px">
                          100%
                        </SliderMark>
                        <SliderTrack bg="gray.300">
                          <SliderFilledTrack bg="gray.700" />
                        </SliderTrack>
                        <SliderThumb borderColor="gray.700" borderWidth="2px" />
                      </Slider>
                    </Flex>
                  )}
                </Flex>
                <Flex justifyContent="flex-start" mt="8px" flexDir="column">
                  <Text fontSize="12px">Resolução máxima: 190 x 190px</Text>
                  <Button
                    variant="link"
                    fontSize="12px"
                    w="fit-content"
                    colorScheme="blue"
                    onClick={() => handleAbrirModalImagemExemplo()}
                  >
                    Ver dicas e exemplo
                  </Button>
                </Flex>
              </Flex>

              {isTodasImpressoes && (
                <>
                  <Box
                    w={['full', 'full', 'full', '240px']}
                    mt={['28px', '28px', '40px']}
                  >
                    <SelectPadrao
                      name="emissaoSenhas"
                      label="Emissão de senhas"
                      options={EnumEmissaoSenhas.properties}
                      colSpan={12}
                    />
                  </Box>
                  <Box
                    w={['full', 'full', 'full', '240px']}
                    mt={['28px', '28px', '24px']}
                  >
                    <SelectPadrao
                      name="imprimirViaProducao"
                      label="Imprimir via de produção"
                      options={EnumImprimirViaProducao.properties}
                      colSpan={12}
                    />
                  </Box>
                </>
              )}
            </Box>
          )}
        </Container>

        {isLargerThan900 && !isNenhumaImpressao && (
          <Container w="30%" bg="gray.100" boxShadow="none">
            <Text
              cursor="pointer"
              textDecoration="underline"
              textAlign="center"
              color="primary.50"
              onClick={() => setAbrirModalVisualizarImpressao(true)}
            >
              Pré-visualização
            </Text>
            <ExibirImpressao
              isTodasImpressoes={isTodasImpressoes}
              isFichaImpressao={isFichaImpressao}
              imagem={fotoWatch}
              isImprimirSenha={emissaoSenhas}
            />
          </Container>
        )}
        <ModalImpressao
          isTodasImpressoes={isTodasImpressoes}
          isFichaImpressao={isFichaImpressao}
          imagem={fotoWatch}
          isOpen={abrirModalVisualizarImpressao}
          onClose={() => setAbrirModalVisualizarImpressao(false)}
        />
      </Flex>
    </FormProvider>
  );
};
