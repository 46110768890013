const LogAuditoriaOperacaoEnum = {
  TODAS: 0,
  CADASTRAR: 1,
  ALTERAR: 2,
  REMOVER: 3,
  INUTILIZAR_NOTAFISCAL: 4,
  CANCELAR_NOTAFISCAL: 5,
  CARTA_CORRECAO: 6,
  AUTORIZAR_NOTAFISCAL: 7,
  AUTORIZAR_ACAO: 8,
  CANCELAR: 9,
  GERAR_RELATORIO: 10,
  CONCEDER_PERMISSAO: 11,

  properties: {
    0: { name: 'Todas', value: 0 },
    1: { name: 'Cadastrar', value: 1 },
    2: { name: 'Alterar', value: 2 },
    3: { name: 'Remover', value: 3 },
    4: { name: 'Inutilizar nota fiscal', value: 4 },
    5: { name: 'Cancelar nota fiscal', value: 5 },
    6: { name: 'Carta de correção', value: 6 },
    7: { name: 'Autorizar nota fiscal', value: 7 },
    8: { name: 'Autorizar ação', value: 8 },
    9: { name: 'Cancelar', value: 9 },
    10: { name: 'Gerar relatório', value: 10 },
    11: { name: 'Conceder permissão', value: 11 },
  },
};

export default LogAuditoriaOperacaoEnum;
