import React from 'react';
import {
  Flex,
  Button,
  VStack,
  Text,
  HStack,
  useMediaQuery,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import ConstanteRotas from 'constants/rotas';
import ConstanteRotasPDV from 'constants/rotasPDV';
import { useInformacoesGeraisContext } from 'store/PDV/InformacoesGerais';
import ConstanteFuncionalidades from 'constants/permissoes';
import auth from 'modules/auth';

import ManterFoco from 'components/Geral/ManterFoco';
import Layout from 'components/PDV/Layout';
import { CaixaAbertoIcon, NaoSelecionadoIcon, OrcamentosIcon } from 'icons';

import ButtonAbrirPdv from './ButtonAbrirPdv';

const AbrirPdv = () => {
  const history = useHistory();

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const {
    abrirContaCofre: abrirContaCofreInformacoesGeraisContext,
  } = useInformacoesGeraisContext();

  const hasPermissaoAbrirCaixa = auth.possuiPermissao(
    ConstanteFuncionalidades.CONTROLE_CAIXA_ABRIR_FECHAR
  ).permitido;

  return (
    <Layout
      containerOverflow="auto"
      bodyOverflow="visible"
      isHeaderVisible={isLargerThan900}
    >
      <ManterFoco
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <Flex
          h="100%"
          w="100%"
          alignItems="center"
          justifyContent="center"
          p={{ base: 0, md: 6 }}
          bg="primary.800"
        >
          <VStack
            bg="gray.50"
            borderRadius={{ base: 'none', md: 'md' }}
            width={{ base: 'full', md: '640px' }}
            height={{ base: 'full', md: '355px' }}
            py="40px"
            px={{ base: '24px', md: '32px' }}
            pt={{ base: '16px', md: '32px' }}
            w="full"
          >
            <HStack w="full">
              <Text
                color="primary.50"
                fontSize={{ base: 'md', md: '18px' }}
                fontWeight="semibold"
              >
                Abrir PDV
              </Text>
            </HStack>
            <HStack pt={1}>
              <Text color="gray.700" fontSize="14px">
                Selecione o modo de utilização do PDV. Para iniciar suas vendas
                é necessário ter um caixa aberto.
              </Text>
            </HStack>
            <HStack spacing={10} justifyContent="center" pt={4} w="full">
              <Flex>
                <ButtonAbrirPdv
                  icon={
                    hasPermissaoAbrirCaixa
                      ? CaixaAbertoIcon
                      : NaoSelecionadoIcon
                  }
                  title="Abrir Caixa"
                  isDisabled={!hasPermissaoAbrirCaixa}
                  onClick={() => {
                    if (!hasPermissaoAbrirCaixa) {
                      toast.warning(
                        'Você não possui permissão para realizar essa ação'
                      );
                      return;
                    }
                    history.push(ConstanteRotasPDV.PDV_ABRIR_CAIXA);
                  }}
                />
              </Flex>

              <Flex>
                <ButtonAbrirPdv
                  icon={OrcamentosIcon}
                  title="Pedidos e Orçamentos"
                  onClick={async () => {
                    if (await abrirContaCofreInformacoesGeraisContext()) {
                      history.push(ConstanteRotasPDV.PDV_HOME);
                    }
                  }}
                />
              </Flex>
            </HStack>
            <Flex w="full" justifyContent="center" pt={10}>
              <Button
                variant="link"
                textDecoration="none"
                fontSize="small"
                onClick={() => {
                  history.push(ConstanteRotas.DASHBOARD);
                }}
              >
                Sair do PDV
              </Button>
            </Flex>
          </VStack>
        </Flex>
      </ManterFoco>
    </Layout>
  );
};

export default AbrirPdv;
