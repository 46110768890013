import React, {
  ReactNode,
  useMemo,
  useRef,
  useEffect,
  useState,
  useCallback,
} from 'react';
import {
  Flex,
  Button,
  Divider,
  SimpleGrid,
  GridItem,
  Icon,
  Box,
  Tag,
  Stack,
  useMediaQuery,
  ModalCloseButton,
} from '@chakra-ui/react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import OperacaoIntermediadorEnum from 'constants/enum/fiscal/operacaoIntermediador';
import ModelosFiscaisEnum from 'constants/enum/fiscal/modelosFiscais';
import ModalidadesFreteEnum from 'constants/enum/fiscal/modalidadeFrete';
import getOptionsByEnum from 'helpers/format/getOptionsByEnum';
import EstadosEnum from 'constants/enum/estados';
import AmbienteFiscalEnum, {
  AmbienteFiscal,
} from 'constants/enum/fiscal/ambienteFiscal';
import { getCurrentDateWithoutSeconds } from 'helpers/data/getCurrentDateWithoutSeconds';
import TipoCertificadoEnum from 'constants/enum/fiscal/tipoCertificado';
import StatusFiscaisEnum from 'constants/enum/fiscal/statusFiscal';
import { useSignalRContext } from 'store/SignalR';

import { NFeIcon } from 'icons';
import Input from 'components/PDV/Input';
import { NumberInput } from 'components/update/Input/NumberInput';
import InputCpfCnpj from 'components/PDV/InputCpfCnpj';
import Select from 'components/PDV/Select/SelectPadrao';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import TextArea from 'components/PDV/Textarea';
import AsyncSelect from 'components/PDV/Select/AsyncSelectPadrao';
import InputInteger from 'components/PDV/InputInteger';
import ModalTransmissaoNota from 'pages/NotasFiscais/ModalTransmissaoNota';
import { ModalAtencao } from 'components/Modal/ModalAtencao';
import { ModalRejeicaoNotaFiscal } from 'components/Modal/ModalRejeicaoNotaFiscal';

import { BoxRejeicao, RejeicaoInterface } from '../BoxRejeicao';

import { useForm, yupResolver } from './validationForm';

interface NFeHeaderProps {
  ambienteFiscal: AmbienteFiscal | undefined;
  isPdv?: boolean;
}

export const NFeHeader = ({ ambienteFiscal, isPdv }: NFeHeaderProps) => {
  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');

  return (
    <Flex
      px={{ base: 6, md: 10 }}
      py={2}
      flexDirection={{ base: 'column', md: 'row' }}
      h={24}
      alignItems={{ base: 'flex-start', md: 'center' }}
      justifyContent={{ base: 'center', md: 'flex-start' }}
      overflow="hidden"
    >
      {(isMobile || isSmallerThan900) && isPdv && <ModalCloseButton />}
      <Icon as={NFeIcon} w="130px" h="auto" maxH="50px" />
      {ambienteFiscal && ambienteFiscal === AmbienteFiscalEnum.Homologacao && (
        <Tag
          size="md"
          colorScheme="red"
          ml={{ base: 0, md: 6 }}
          mt={{ base: 2, md: 0 }}
        >
          HOMOLOGAÇÃO - SEM VALOR FISCAL
        </Tag>
      )}
    </Flex>
  );
};

export interface TransportadorInterface {
  id: string;
  nome: string;
}

export type EmitirNFeData = {
  id?: string;
  numeroPedidoExterno?: string;
  dataEmissao: string;
  dataSaida?: string | null;

  operacaoComIntermediador: number;
  cnpjIntermediador?: string;
  identificacaoNoIntermediador?: string;

  modalidadeFrete?: number;
  transportadora?: { value: string; label: string };
  placaTransportadora?: string;
  ufVeiculoTransportadora?: string;
  pesoLiquido?: number;
  pesoBruto?: number;
  quantidadeVolumeTransportadora?: number;
  especieVolumeTransportadora?: string;
  marcaVolumeTransportadora?: string;
  numeracaoVolumeTransportadora?: string;
  ufEmbarque?: string;
  localEmbarque?: string;
  dadosAdicionais?: string;
};

interface EmitirNFeProps {
  defaultValues?: EmitirNFeData;
  casasDecimaisQuantidade: number;
  onSubmit: SubmitHandler<EmitirNFeData>;
  children?: ReactNode;
  rejeicaoNotaFiscal?: RejeicaoInterface;
  isLoading?: boolean;
  isDisabledSubmit?: boolean;
  ambienteFiscal: AmbienteFiscal | undefined;
  isPdv?: boolean;
  isCustomerFromDifferentCountry?: boolean;
  pesoProdutos?: {
    pesoLiquido: number;
    pesoBruto: number;
  };
  statusNfe?: number;
  setRejeicaoNotaFiscal?: React.Dispatch<
    React.SetStateAction<RejeicaoInterface | undefined>
  >;
}

export function EmitirNFe({
  defaultValues,
  casasDecimaisQuantidade,
  onSubmit,
  children,
  rejeicaoNotaFiscal,
  isLoading,
  ambienteFiscal,
  statusNfe,
  isDisabledSubmit = false,
  isPdv = false,
  isCustomerFromDifferentCountry = false,
  pesoProdutos,
  setRejeicaoNotaFiscal,
}: EmitirNFeProps) {
  const [textStatusAlteracao, setTextStatusAlteracao] = useState(
    'Iniciando alteração de status da nota fiscal...'
  );
  const [titleModalTransmitirNota, setTitleModalTransmitirNota] = useState(
    'Alteração de status da nota fiscal'
  );
  const [showModalTransmissao, setShowModalTransmissao] = useState(false);
  const [isLoadingStatusChange, setIsLoadingChangeNfStatus] = useState(false);

  const { hubConnection, joinGroup } = useSignalRContext();

  const formDefaultValues = useMemo(
    () => ({
      operacaoComIntermediador: OperacaoIntermediadorEnum.SemIntermediador,
      modalidadeFrete: ModalidadesFreteEnum.SemFrete,
      dataEmissao: getCurrentDateWithoutSeconds(),
      pesoLiquido: pesoProdutos?.pesoLiquido,
      pesoBruto: pesoProdutos?.pesoBruto,
      ...(defaultValues || {}),
      dataSaida: getCurrentDateWithoutSeconds(),
    }),
    [defaultValues, pesoProdutos]
  );
  const formMethods = useForm({
    resolver: yupResolver,
    defaultValues: formDefaultValues,
  });

  const statusRetornoIndisponivel =
    statusNfe === StatusFiscaisEnum.RetornoIndisponivel;

  const [isSmallerThan1090] = useMediaQuery('(max-width: 1090px)');

  const operacaoComIntermediador = formMethods.watch(
    'operacaoComIntermediador',
    OperacaoIntermediadorEnum.SemIntermediador
  );
  const modalidadeFrete = formMethods.watch(
    'modalidadeFrete',
    ModalidadesFreteEnum.SemFrete
  );
  const cnpjIntermediador = formMethods.watch('cnpjIntermediador');

  const handleGetTransportadorasOptions = async (newInputValue: string) => {
    const response = await api.get<void, ResponseApi<TransportadorInterface[]>>(
      ConstanteEnderecoWebservice.TRANSPORTADORA_LISTAR_SELECT_NOTA_FISCAL,
      {
        params: { nome: newInputValue },
      }
    );

    if (response?.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (response?.sucesso) {
      return response?.dados.map((transportador: TransportadorInterface) => {
        return {
          label: transportador.nome,
          value: transportador.id,
        };
      });
    }

    return [];
  };

  const submit = formMethods.handleSubmit(onSubmit);

  const handleSubmit = () => {
    if (isDisabledSubmit) return;
    if (
      cnpjIntermediador === undefined ||
      operacaoComIntermediador === OperacaoIntermediadorEnum.SemIntermediador
    ) {
      submit();
    } else if (cnpjIntermediador?.length === 18) {
      submit();
    } else {
      toast.warning('CNPJ inválido');
    }
  };

  const reloadPage = useCallback(() => {
    window.location.reload();
  }, []);

  const tratarAviso = useCallback(
    (aviso: string) => {
      if (setRejeicaoNotaFiscal) {
        try {
          const newRejeicao = JSON.parse(aviso) as RejeicaoInterface;
          setRejeicaoNotaFiscal(newRejeicao);
        } catch {
          setRejeicaoNotaFiscal({ Mensagem: aviso });
        }
      }
    },
    [setRejeicaoNotaFiscal]
  );
  const alterarStatusTransmissao = useCallback((message: string) => {
    if (message) {
      setTextStatusAlteracao(message);
      setShowModalTransmissao(true);
      setTitleModalTransmitirNota('Transmitindo nota fiscal');
    }
  }, []);

  const tratarRejeicaoNfAlterarStatus = useCallback(
    (avisos: string[], statusNfAlterado: boolean, sucesso: boolean) => {
      if (!statusNfAlterado) {
        avisos.forEach((aviso: string) => toast.warning(aviso));
      } else if (!sucesso) {
        setShowModalTransmissao(false);
        tratarAviso(avisos[0]);
      }
    },
    [tratarAviso]
  );

  const transmissaoFinalizada = useCallback((timeout: number) => {
    window.clearTimeout(timeout);
    setShowModalTransmissao(false);
    toast.success('A nota fiscal foi transmitida com sucesso.');
  }, []);

  const rejeicaoTransmissaoA3 = useCallback(
    (message: string, timeout: number) => {
      window.clearTimeout(timeout);
      setShowModalTransmissao(false);
      tratarAviso(message);
    },
    [tratarAviso]
  );

  const handleNfAlterarStatusRetorno = useCallback(
    async (id: string) => {
      setIsLoadingChangeNfStatus(true);
      let statusNfAlterado = false;

      joinGroup(`${id}_transmitindo-nota`);

      hubConnection.on('alterar-status-transmissao', (message: string) => {
        if (message) {
          alterarStatusTransmissao(message);
          statusNfAlterado = true;
        }
      });

      const response = await api.put<void, ResponseApi>(
        ConstanteEnderecoWebservice.NOTA_FISCAL_ALTERAR_STATUS_RETORNO,
        null,
        { params: { id } }
      );

      if (response) {
        if (response?.avisos) {
          tratarRejeicaoNfAlterarStatus(
            response.avisos,
            statusNfAlterado,
            response.sucesso
          );
        }

        if (response?.sucesso) {
          if (
            statusNfAlterado &&
            response.dados &&
            response.dados === Number(TipoCertificadoEnum.A3)
          ) {
            const timeout = window.setTimeout(
              () => {
                setShowModalTransmissao(false);
                ModalAtencao({
                  title: 'A comunicação com o certificado digital A3 falhou',
                  text:
                    'Verifique se o certificado digital está conectado no computador e se o aplicativo Módulo desktop está sendo executado.',
                });

                reloadPage();
              },
              1000 * 60 // 1 minuto
            );

            hubConnection.off('sucesso-transmissao-a3');
            hubConnection.on('sucesso-transmissao-a3', () =>
              transmissaoFinalizada(timeout)
            );

            hubConnection.off('rejeicao-transmissao-a3');
            hubConnection.on('rejeicao-transmissao-a3', (message: string) =>
              rejeicaoTransmissaoA3(message, timeout)
            );
          }
          if (statusNfAlterado) {
            setShowModalTransmissao(false);
            toast.success('A nota fiscal foi transmitida com sucesso.');
          } else {
            toast.success('O status da nota fiscal foi alterada com sucesso.');
          }
          reloadPage();
        }
      }
      setShowModalTransmissao(false);
      setIsLoadingChangeNfStatus(false);
    },
    [
      reloadPage,
      hubConnection,
      joinGroup,
      alterarStatusTransmissao,
      tratarRejeicaoNfAlterarStatus,
      transmissaoFinalizada,
      rejeicaoTransmissaoA3,
    ]
  );

  const latestProps = useRef({ reset: formMethods.reset });
  useEffect(() => {
    latestProps.current = { reset: formMethods.reset };
  });

  useEffect(() => {
    const { dataSaida, ...FormDefaultValues } = formDefaultValues;
    latestProps.current.reset(FormDefaultValues);
  }, [formDefaultValues]);

  return (
    <>
      {(isLoading || isLoadingStatusChange) && <LoadingPadrao />}

      <Flex flexDirection="column" w="full">
        <NFeHeader ambienteFiscal={ambienteFiscal} isPdv={isPdv} />
        {rejeicaoNotaFiscal ? (
          <BoxRejeicao
            rejeicao={rejeicaoNotaFiscal}
            modeloFiscal={ModelosFiscaisEnum.NFe}
          />
        ) : (
          <Box px={{ base: 6, md: 8 }}>
            <Divider orientation="horizontal" />
          </Box>
        )}
        <>
          <FormProvider {...formMethods}>
            <SimpleGrid
              px={{ base: 6, md: 10 }}
              columns={12}
              spacing={{ base: 6, md: 8 }}
              py={8}
            >
              <Input
                id="numeroPedidoExterno"
                name="numeroPedidoExterno"
                label="Número do pedido externo"
                placeholder="Digite o número"
                colSpan={{ base: 12, md: 12, lg: isSmallerThan1090 ? 12 : 4 }}
                maxLength={15}
                autoFocus
              />
              <Input
                id="dataEmissao"
                name="dataEmissao"
                label="Data/Hora de emissão"
                type="datetime-local"
                max={new Date().toISOString().replace('Z', '')}
                isRequired
                colSpan={{ base: 12, md: 6, lg: isSmallerThan1090 ? 6 : 4 }}
              />
              <Input
                id="dataSaida"
                name="dataSaida"
                label="Data/Hora de saída"
                max={new Date().toISOString().replace('Z', '')}
                type="datetime-local"
                colSpan={{ base: 12, md: 6, lg: isSmallerThan1090 ? 6 : 4 }}
              />
              <Select
                id="operacaoComIntermediador"
                name="operacaoComIntermediador"
                label="Intermediador"
                required
                isSearchable={false}
                options={getOptionsByEnum(OperacaoIntermediadorEnum)}
                colSpan={{ base: 12, md: 7, lg: 6, xl: 5 }}
              />
              {operacaoComIntermediador ===
                OperacaoIntermediadorEnum.OperacaoEmSitePlataformaTerceiros && (
                <>
                  <InputCpfCnpj
                    asCnpj
                    id="cnpjIntermediador"
                    name="cnpjIntermediador"
                    label="CNPJ intermediador"
                    isRequired
                    placeholder="00.000.000-0000/00"
                    rowStart={3}
                    colSpan={{ base: 12, md: 7, lg: 4, xl: 3 }}
                  />
                  <Input
                    id="identificacaoNoIntermediador"
                    name="identificacaoNoIntermediador"
                    label="Identificação no intermediador"
                    maxLength={60}
                    isRequired
                    colSpan={{ base: 12, lg: 8, xl: 9 }}
                  />
                </>
              )}
            </SimpleGrid>
            <Box px={{ base: 6, md: 8 }}>
              <Divider orientation="horizontal" />
            </Box>
            <SimpleGrid
              px={{ base: 6, md: 10 }}
              columns={12}
              spacing={{ base: 6, md: 8 }}
              py={8}
            >
              <Select
                id="modalidadeFrete"
                name="modalidadeFrete"
                label="Modalidade do frete"
                required
                colSpan={{ base: 12, lg: 6 }}
                isSearchable={false}
                options={getOptionsByEnum(ModalidadesFreteEnum)}
              />
              {modalidadeFrete !== ModalidadesFreteEnum.SemFrete && (
                <>
                  <AsyncSelect
                    id="transportadora"
                    name="transportadora"
                    errorPropName="value"
                    label="Transportadora"
                    placeholder="Digite o nome para pesquisar a transportadora"
                    required
                    colSpan={{ base: 12, lg: 6 }}
                    handleGetOptions={handleGetTransportadorasOptions}
                    asControlledByObject
                    autoFocus={false}
                  />
                  <SimpleGrid
                    columns={10}
                    spacing={{ base: 6, md: 8 }}
                    as={GridItem}
                    colSpan={12}
                  >
                    <Input
                      id="placaTransportadora"
                      name="placaTransportadora"
                      label="Placa do veículo"
                      placeholder="XXX-0000"
                      maxLength={7}
                      colSpan={{ base: 10, md: 5, xl: 2 }}
                    />
                    <Select
                      id="ufVeiculoTransportadora"
                      name="ufVeiculoTransportadora"
                      label="UF do veículo"
                      placeholder="Selecione"
                      options={getOptionsByEnum(EstadosEnum, 'sigla', 'sigla')}
                      colSpan={{ base: 10, md: 5, xl: 2 }}
                    />
                    <SimpleGrid
                      columns={3}
                      as={GridItem}
                      spacing={{ base: 6, md: 8 }}
                      colSpan={{ base: 10, xl: 6 }}
                    >
                      <NumberInput
                        id="pesoLiquido"
                        name="pesoLiquido"
                        label="Peso líquido"
                        placeholder="0,000"
                        max={Number(
                          `999.${'9'.repeat(casasDecimaisQuantidade)}`
                        )}
                        scale={casasDecimaisQuantidade}
                        leftElement="KG"
                        isRequired
                        colSpan={{ base: 3, md: 1 }}
                      />
                      <NumberInput
                        id="pesoBruto"
                        name="pesoBruto"
                        label="Peso bruto"
                        defaultValue={0}
                        placeholder="0,000"
                        max={Number(
                          `999.${'9'.repeat(casasDecimaisQuantidade)}`
                        )}
                        scale={casasDecimaisQuantidade}
                        leftElement="KG"
                        isRequired
                        colSpan={{ base: 3, md: 1 }}
                      />
                      <InputInteger
                        id="quantidadeVolumeTransportadora"
                        name="quantidadeVolumeTransportadora"
                        label="Quantidade"
                        placeholder="0"
                        textAlign="right"
                        pr={4}
                        max={999999999999999} // maxLength={15}
                        isRequired
                        colSpan={{ base: 3, md: 1 }}
                      />
                    </SimpleGrid>
                  </SimpleGrid>
                  <SimpleGrid
                    columns={3}
                    spacing={{ base: 6, md: 8 }}
                    as={GridItem}
                    colSpan={12}
                  >
                    <Input
                      id="especieVolumeTransportadora"
                      name="especieVolumeTransportadora"
                      label="Espécie"
                      placeholder="Digite a espécie"
                      maxLength={60}
                      colSpan={{ base: 3, md: 1 }}
                    />
                    <Input
                      id="marcaVolumeTransportadora"
                      name="marcaVolumeTransportadora"
                      label="Marca"
                      placeholder="Digite a marca"
                      maxLength={60}
                      colSpan={{ base: 3, md: 1 }}
                    />
                    <Input
                      id="numeracaoVolumeTransportadora"
                      name="numeracaoVolumeTransportadora"
                      label="Número"
                      placeholder="Digite o número"
                      maxLength={60}
                      colSpan={{ base: 3, md: 1 }}
                    />
                  </SimpleGrid>
                  {isCustomerFromDifferentCountry && (
                    <SimpleGrid
                      columns={2}
                      spacing={{ base: 6, md: 8 }}
                      as={GridItem}
                      colSpan={12}
                    >
                      <Select
                        id="ufEmbarque"
                        name="ufEmbarque"
                        label="UF de embarque"
                        placeholder="Selecione"
                        options={getOptionsByEnum(
                          EstadosEnum,
                          'sigla',
                          'sigla'
                        )}
                      />
                      <Input
                        id="localEmbarque"
                        name="localEmbarque"
                        label="Local do embarque"
                        placeholder="Digite o local do embarque"
                        maxLength={60}
                        colSpan={{ base: 2, md: 1 }}
                      />
                    </SimpleGrid>
                  )}
                </>
              )}
            </SimpleGrid>
            <Box px={{ base: 6, md: 10 }}>
              <Divider orientation="horizontal" mb={{ base: 6, md: 8 }} />
            </Box>
            <Box mx={{ base: 6, md: 10 }}>
              <TextArea
                id="dadosAdicionais"
                name="dadosAdicionais"
                label="Dados adicionais"
              />
            </Box>
          </FormProvider>
          <Box px={{ base: 6, md: 10 }}>
            <Divider orientation="horizontal" mt={{ base: 6, md: 8 }} />
          </Box>
        </>
        <Stack
          direction={{ base: 'column', sm: 'row' }}
          justifyContent="center"
          spacing={{ base: 3, md: 6 }}
          my={{ base: 6, md: 8 }}
          mx={{ base: 6, md: 10 }}
        >
          {children}
          {!statusRetornoIndisponivel ? (
            <Button
              size="sm"
              w="full"
              minW="104px"
              maxW={{ base: 'full', sm: '120px' }}
              colorScheme="secondary"
              onClick={() => handleSubmit()}
              isDisabled={isDisabledSubmit}
            >
              Emitir NF-e
            </Button>
          ) : (
            <Button
              size="sm"
              colorScheme="primary"
              padding="12px"
              onClick={() =>
                handleNfAlterarStatusRetorno(defaultValues?.id || '')
              }
            >
              Alterar status de retorno
            </Button>
          )}
        </Stack>
        <ModalTransmissaoNota
          show={showModalTransmissao}
          message={textStatusAlteracao}
          title={titleModalTransmitirNota}
        />
      </Flex>
    </>
  );
}
