import { useCallback, useEffect, useState } from 'react';
import {
  Icon,
  Box,
  GridItem,
  FormLabel,
  Tooltip,
  useToken,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import { formatOptionsSelectClient } from 'helpers/format/formatSelectClient';
import { enumRelatorioProdutosPersonalizadoPadrao } from 'constants/enum/RelatoriosPadrao';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import StatusConsultaEnum from 'constants/enum/statusConsulta';
import TipoFiltroProdutoEstoqueEnum from 'constants/enum/tipoFiltroProdutoEstoque';
import TipoCadastroCampoPersonalizadoEnum from 'constants/enum/tipoCadastroCampoPersonalizado';
import { StatusPesquisaClientesFornecedor } from 'constants/enum/statusPesquisaClientesFornecedor';

import { InfoIcon } from 'icons';
import Input from 'components/PDV/Input';
import CreatableSelect from 'components/PDV/Select/CreatableSelect';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import InputDateRange from 'components/PDV/InputDateRange';
import AsyncSelect from 'components/PDV/Select/AsyncSelectPadrao';
import { CamposPersonalizados } from 'components/update/CamposPersonalizados';
import { SelectCategoria } from 'components/Select/SelectCategoria';

import CampoPersonalizadoInterface from 'types/campoPersonalizado';

import { validarTipoRelatorioProdutosVendidos } from './ValidarTipoRelatorioProdutosVendidos';

export type ClienteSelectProps = {
  label: string;
  value: string;
};

export type OptionsRelatorioProps = {
  label: string;
  value: string | number;
};

type ListaRelatorioPersonalizadoProps = {
  type: 'ProdutosComPreco' | 'ProdutosVendidos' | 'Personalizados';
  isLoading: boolean;
  optionsRelatorio?: OptionsRelatorioProps[];
};

type ListaDeRelatorios = {
  id: string;
  nome: string;
};

type Options = {
  label: string;
  value: string | number;
};

type OptionsValues = {
  id: string;
  nome: string;
};

type CamposPersonalizados = {
  campoPersonalizadoId: string;
  valor: string;
};

type SelectClienteProps = {
  id: string;
  nome: string;
  endereco: string;
  codigo: number;
  cpfCnpj: string;
};

export const CustomFormStandardComponent = ({
  type,
  isLoading,
  optionsRelatorio,
}: ListaRelatorioPersonalizadoProps) => {
  const [loading, setLoading] = useState(false);
  const [cores, setCores] = useState<Options[]>([]);
  const [coresIsLoading, setCoresIsLoading] = useState(false);
  const [tamanhos, setTamanhos] = useState<Options[]>([]);
  const [tamanhosIsLoading, setTamnhosIsLoading] = useState(false);
  const [marcas, setMarcas] = useState<Options[]>([]);
  const [marcasIsLoading, setMarcasIsLoading] = useState(false);
  const [tags, setTags] = useState<Options[]>([]);
  const [tagsIsLoading, setTagsIsLoading] = useState(false);
  const [tabelaPreco, setTabelaPreco] = useState<Options[]>([]);
  const [relatorios, setListRelatorio] = useState<Options[]>([]);
  const [relatoriosIsloading, setRelatoriosLoading] = useState(false);
  const [tabelaPrecoIsLoading, setTabelaPrecoIsLoading] = useState(false);
  const [camposPersonalizados, setCamposPersonalizados] = useState<
    CampoPersonalizadoInterface[]
  >([]);

  const [listaDeVendedores, setListaDeVendores] = useState<Options[]>([]);

  const { setValue, getValues, control } = useFormContext();
  const [white, gray400] = useToken('colors', ['white', 'gray.400']);

  const [
    isLoadingListaDeVendores,
    setIsLoadingListaDeVendores,
  ] = useState<boolean>(false);

  const relatoriosProdutosVendidos = enumRelatorioProdutosPersonalizadoPadrao.properties
    .filter(
      (item) =>
        validarTipoRelatorioProdutosVendidos(item) &&
        item.value !==
          enumRelatorioProdutosPersonalizadoPadrao.PRODUTO_COM_PRECO
    )
    .map((item) => {
      return {
        label: item.name,
        value: item.value,
      } as Options;
    });

  const statusConsulta = Object.entries(StatusConsultaEnum.properties).map(
    (value: any) => {
      return (
        {
          label: value[1].name,
          value: value[1].value,
        } || {}
      );
    }
  );

  const tipoEstoque = Object.entries(
    TipoFiltroProdutoEstoqueEnum.properties
  ).map((value: any) => {
    return (
      {
        label: value[1].name,
        value: value[1].value,
      } || {}
    );
  });

  const getCoresValues = useCallback(async () => {
    setCoresIsLoading(true);
    const response = await api.get<void, ResponseApi<OptionsValues[]>>(
      ConstanteEnderecoWebservice.COR_LISTAR_SELECT,
      {
        params: {
          statusConsulta: StatusConsultaEnum.TODOS,
          listarPadraoSistema: true,
        },
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }

      if (response.sucesso) {
        setCores(
          response.dados.map((cor) => {
            return {
              label: cor.nome,
              value: cor.id,
            } as Options;
          })
        );
      }
    }
    setCoresIsLoading(false);
  }, []);

  const getTamanhosValues = useCallback(async () => {
    setTamnhosIsLoading(true);
    const response = await api.get<void, ResponseApi<OptionsValues[]>>(
      ConstanteEnderecoWebservice.TAMANHO_LISTAR_SELECT,
      {
        params: {
          statusConsulta: StatusConsultaEnum.TODOS,
          listarPadraoSistema: true,
        },
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }

      if (response.sucesso) {
        setTamanhos(
          response.dados.map((tamanho) => {
            return {
              label: tamanho.nome,
              value: tamanho.id,
            } as Options;
          })
        );
      }
    }
    setTamnhosIsLoading(false);
  }, []);

  const getMarcasValues = useCallback(async () => {
    setMarcasIsLoading(true);
    const response = await api.get<void, ResponseApi<OptionsValues[]>>(
      ConstanteEnderecoWebservice.MARCA_LISTAR_SELECT,
      {
        params: { statusConsulta: StatusConsultaEnum.TODOS },
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }

      if (response.sucesso) {
        setMarcas(
          response.dados.map((marca) => {
            return {
              label: marca.nome,
              value: marca.id,
            } as Options;
          })
        );
      }
    }
    setMarcasIsLoading(false);
  }, []);

  const getTagsValues = useCallback(async () => {
    setTagsIsLoading(true);
    const response = await api.get<void, ResponseApi<OptionsValues[]>>(
      ConstanteEnderecoWebservice.TAG_LISTAR_SELECT
    );

    if (response) {
      if (response.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }

      if (response.sucesso) {
        setTags(
          response.dados.map((tag) => {
            return {
              label: tag.nome,
              value: tag.id,
            } as Options;
          })
        );
      }
    }
    setTagsIsLoading(false);
  }, []);

  const getTabelaPrecoValues = useCallback(async () => {
    setTabelaPrecoIsLoading(true);
    const response = await api.get<void, ResponseApi<OptionsValues[]>>(
      ConstanteEnderecoWebservice.TABELA_PRECO_LISTAR_TABELAS_PRECO
    );

    if (response) {
      if (response.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }

      if (response.sucesso) {
        setTabelaPreco(
          response.dados.map((tabelasPreco) => {
            return {
              label: tabelasPreco.nome,
              value: tabelasPreco.id,
            } as Options;
          })
        );
      }
    }

    setTabelaPrecoIsLoading(false);
  }, []);

  const getCamposPersonalizados = useCallback(async () => {
    setLoading(true);
    const response = await api.get<
      void,
      ResponseApi<CampoPersonalizadoInterface[]>
    >(ConstanteEnderecoWebservice.CAMPO_PERSONALIZADO_LISTARPORTIPOCADASTRO, {
      params: { tipoCadastro: TipoCadastroCampoPersonalizadoEnum.PRODUTO },
    });

    if (response) {
      if (response.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }

      if (response.sucesso && response.dados) {
        setCamposPersonalizados(response.dados);

        const currentCamposPersonalizados = getValues('camposPersonalizados');

        setValue(
          'camposPersonalizados',
          response.dados.map((campoPersonalizado: any) => {
            const currentCampoPersonalizado = currentCamposPersonalizados
              ? currentCamposPersonalizados.find(
                  (x: CamposPersonalizados) =>
                    x?.campoPersonalizadoId === campoPersonalizado.id
                )
              : undefined;

            return {
              campoPersonalizadoId: campoPersonalizado.id,
              valor: currentCampoPersonalizado
                ? currentCampoPersonalizado.valor
                : null,
            } as CamposPersonalizados;
          })
        );
      }
    }
    setLoading(false);
  }, [getValues, setValue]);

  const getListaRelatorios = useCallback(async () => {
    setRelatoriosLoading(true);
    const response = await api.get<void, ResponseApi<ListaDeRelatorios[]>>(
      ConstanteEnderecoWebservice.RELATORIOS_PERSONALIZADOS_LISTAR
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        const listRelatorios = response.dados.map((relatorioItem) => {
          return {
            label: relatorioItem.nome,
            value: relatorioItem.id,
          } as Options;
        });

        setListRelatorio(listRelatorios);
      }
    }
    setRelatoriosLoading(false);
  }, []);

  const getListaDeVendedores = async () => {
    setIsLoadingListaDeVendores(true);
    const response = await api.get<void, ResponseApi<OptionsValues[]>>(
      ConstanteEnderecoWebservice.VENDEDOR_LISTAR_SELECT_POR_LOJA
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item) => toast.warn(item));
      }

      if (response.sucesso && response.dados) {
        const listOfSellers = response.dados.map(
          (seller) =>
            ({
              label: seller.nome,
              value: seller.id,
            } as Options)
        );
        setListaDeVendores(listOfSellers);
      }
    }
    setIsLoadingListaDeVendores(false);
  };

  const handleGetClientes = useCallback(async (inputValue?: string) => {
    const response = await api.get<void, ResponseApi<SelectClienteProps[]>>(
      ConstanteEnderecoWebservice.CLIENTE_FORNECEDOR_LISTAR_SELECT,
      {
        params: {
          filtroTipoCadastroPessoa: StatusPesquisaClientesFornecedor.CLIENTES,
          cpfCnpjNomeApelidoCodigoExterno: inputValue,
        },
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        const dados = response.dados.map((client) => {
          const option = formatOptionsSelectClient(client);
          return option;
        });
        return dados;
      }
    }

    return [];
  }, []);

  useEffect(() => {
    getCoresValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getTamanhosValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getMarcasValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getTagsValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getTabelaPrecoValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCamposPersonalizados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getListaRelatorios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getListaDeVendedores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {(isLoading || loading) && <LoadingPadrao />}
      <Box bg="white" p="8" borderRadius="md" boxShadow="lg">
        <SimpleGridForm marginY="6">
          <GridItem colSpan={{ base: 12, sm: 6, lg: 4 }}>
            {type === 'ProdutosComPreco' ? (
              <SelectPadrao
                placeholder="Selecione uma tabela de preço"
                id="tabelaPrecoId"
                name="tabelaPrecoId"
                label="Tabela de preço"
                required
                isLoading={tabelaPrecoIsLoading}
                options={tabelaPreco}
              />
            ) : (
              <SelectPadrao
                placeholder="Selecione um relatório"
                id="tipoRelatorio"
                name="tipoRelatorio"
                label="Relatório "
                required
                isLoading={
                  type === 'Personalizados' ? relatoriosIsloading : false
                }
                options={
                  optionsRelatorio ||
                  (type === 'Personalizados'
                    ? relatorios
                    : relatoriosProdutosVendidos)
                }
              />
            )}
          </GridItem>
          {type === 'ProdutosVendidos' && (
            <>
              <GridItem colSpan={{ base: 12, sm: 6, lg: 4 }}>
                <FormLabel mb="0" fontSize="sm" color="black" lineHeight="1.2">
                  Período *
                </FormLabel>
                <InputDateRange
                  name="dataEmissao"
                  startDateName="dataEmissaoInicio"
                  placeholder="Selecione a data"
                  endDateName="dataEmissaoFim"
                  borderColor="gray.100"
                  borderRadius="base"
                  inputValueIsSmaller={false}
                  maxDate={new Date()}
                />
              </GridItem>
              <GridItem colSpan={{ base: 12, sm: 6, lg: 4 }}>
                <AsyncSelect
                  id="clienteFornecedorId"
                  name="clienteFornecedorId"
                  placeholder="Informe o cliente"
                  label="Cliente"
                  handleGetOptions={handleGetClientes}
                  asControlledByObject
                  isClearable
                  shouldAppearTheAddress
                />
              </GridItem>
              <GridItem colSpan={{ base: 12, sm: 6, lg: 4 }}>
                <SelectPadrao
                  id="vendedorId"
                  name="vendedorId"
                  label="Vendedor"
                  placeholder="Selecione um vendedor"
                  options={listaDeVendedores}
                  isLoading={isLoadingListaDeVendores}
                  isClearable
                />
              </GridItem>
            </>
          )}

          <GridItem position="relative" colSpan={{ base: 12, sm: 6, lg: 4 }}>
            <Box
              position="absolute"
              right="0"
              zIndex={1}
              mr={1}
              cursor="pointer"
            >
              <Tooltip
                label="A pesquisa de produto considera os campos nome, referência, SKU, código externo, código de barras e GTIN/EAN"
                hasArrow
                placement="auto"
                shouldWrapChildren
              >
                <Icon as={InfoIcon} />
              </Tooltip>
            </Box>
            <Input
              mb="0.5"
              label="Produto"
              placeholder="Digite o nome ou referência"
              id="nomeSkuCodigoExternoBarrasGtinEan"
              name="nomeSkuCodigoExternoBarrasGtinEan"
            />
          </GridItem>
          <GridItem colSpan={{ base: 12, sm: 6, lg: 4 }}>
            <SelectPadrao
              id="statusConsulta"
              name="statusConsulta"
              label="Status"
              placeholder="Selecione um status"
              options={statusConsulta}
              isClearable={false}
            />
          </GridItem>
          <GridItem colSpan={{ base: 12, sm: 6, lg: 4 }}>
            <SelectPadrao
              id="tipoEstoque"
              name="tipoEstoque"
              label="Estoque"
              placeholder="Selecione um tipo de estoque"
              isClearable={false}
              options={tipoEstoque}
            />
          </GridItem>
          <GridItem colSpan={{ base: 12, sm: 6, lg: 4 }}>
            <SelectCategoria name="categoriasProduto" label="Categoria" />
          </GridItem>
          <GridItem colSpan={{ base: 12, sm: 6, lg: 4 }}>
            <CreatableSelect
              id="marcas"
              name="marcas"
              label="Marca"
              isMulti
              placeholder="Selecione as opções desejadas"
              options={marcas}
              isLoading={marcasIsLoading}
              multiValueBg={gray400}
              multiValueColor={white}
              creatableButtonShow={false}
              creatableInputTextPreffix=""
              handleCreateOption={() => {}}
            />
          </GridItem>
          <GridItem colSpan={{ base: 12, sm: 6, lg: 4 }}>
            <CreatableSelect
              id="tags"
              name="tags"
              label="Tags"
              isMulti
              placeholder="Selecione as opções desejadas"
              options={tags}
              isLoading={tagsIsLoading}
              multiValueBg={gray400}
              multiValueColor={white}
              creatableButtonShow={false}
              creatableInputTextPreffix=""
              handleCreateOption={() => {}}
            />
          </GridItem>
          <GridItem colSpan={{ base: 12, sm: 6, lg: 4 }}>
            <CreatableSelect
              id="cores"
              name="cores"
              label="Cor"
              isMulti
              placeholder="Selecione as opções desejadas"
              options={cores}
              isLoading={coresIsLoading}
              multiValueBg={gray400}
              multiValueColor={white}
              creatableButtonShow={false}
              creatableInputTextPreffix=""
              handleCreateOption={() => {}}
            />
          </GridItem>
          <GridItem colSpan={{ base: 12, md: 6, lg: 4 }}>
            <CreatableSelect
              id="tamanhos"
              name="tamanhos"
              label="Tamanho"
              isMulti
              placeholder="Selecione as opções desejadas"
              options={tamanhos}
              isLoading={tamanhosIsLoading}
              multiValueBg={gray400}
              multiValueColor={white}
              creatableButtonShow={false}
              creatableInputTextPreffix=""
              handleCreateOption={() => {}}
            />
          </GridItem>
          <GridItem
            colSpan={{ base: 12, md: 12, lg: 12 }}
            sx={{ '& > div': { padding: '1rem 0 0  !Important' } }}
          >
            <CamposPersonalizados
              labelOfNull
              camposPersonalizados={camposPersonalizados}
            />
          </GridItem>
        </SimpleGridForm>
      </Box>
    </>
  );
};
