import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  useMediaQuery,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Grid,
  ModalFooter,
  Button,
  HStack,
  VStack,
  ModalProps,
  useDisclosure,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import ShadowScrollbar from 'components/PDV/Geral/ShadowScrollbar';
import ConditionalWrapper from 'components/Geral/ConditionalWrapper';
import { MobileModalMenu } from 'components/PDV/Layout/MobileModalMenu';
import ModalPadraoChakra from '../ModalPadraoChakra';

import EstoqueButton from './EstoqueButton';

type PromiseModal = {
  sucesso: boolean;
};

type Estoque = {
  id: string;
  nome: string;
  selecionado: boolean;
};

export type EstoqueSelecionado = {
  id: string;
  nome: string;
};

type ModalSelecionarLocalEstoque = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<PromiseModal> & {
    currentLocalEstoqueId: string;
    finalFocusRef?: any;
    asMobileView?: boolean;
    onSubmit: (novoLocalDeEstoque: Estoque) => void;
    listarEstoques?: Estoque[];
  };

const Modal = ({
  currentLocalEstoqueId,
  finalFocusRef,
  asMobileView = false,
  isOpen,
  onReject,
  onResolve,
  onSubmit,
  listarEstoques,
  ...props
}: ModalSelecionarLocalEstoque) => {
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const localEstoque = useRef<Estoque[]>(listarEstoques || []);

  const [isLoading, setIsLoading] = useState(true);
  const [localEstoquePadraoId, setLocalEstoquePadraoId] = useState<string>(
    currentLocalEstoqueId || ''
  );

  const handleSetLocalEstoqueSelecionado = useCallback(
    (newLocalTheStockSelected: string) => {
      if (isLargerThan900) {
        setLocalEstoquePadraoId(newLocalTheStockSelected);
      } else {
        const localStock = localEstoque.current.find(
          (estoque) => estoque.id === newLocalTheStockSelected
        );
        if (localStock) {
          onSubmit(localStock);
        }

        onReject();
      }
    },
    [isLargerThan900, localEstoque, onReject, onSubmit]
  );

  const handleSubmit = useCallback(() => {
    if (localEstoquePadraoId) {
      const theLocaleOfStock = localEstoque.current.find(
        (local) => local.id === localEstoquePadraoId
      );

      if (theLocaleOfStock) {
        onSubmit(theLocaleOfStock);
      }
    }

    onResolve({ sucesso: true });
  }, [onResolve, localEstoquePadraoId, localEstoque, onSubmit]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  if (asMobileView) {
    return (
      <MobileModalMenu
        isOpen={isOpen}
        onClose={onReject}
        title="Selecionar o local de estoque"
        returnFocusOnClose={false}
      >
        <VStack spacing={2} w="100%">
          {localEstoque.current.map((estoque) => (
            <EstoqueButton
              key={estoque.id}
              estoque={estoque}
              isSelected={localEstoquePadraoId === estoque.id}
              autoFocus={localEstoquePadraoId === estoque.id}
              onClick={() => {
                handleSetLocalEstoqueSelecionado(estoque.id);
              }}
            />
          ))}
        </VStack>
      </MobileModalMenu>
    );
  }

  return (
    <ModalPadraoChakra
      isOpen={isOpen}
      onClose={onResolve}
      size={isLargerThan900 ? 'xl' : 'full'}
      scrollBehavior={isLargerThan900 ? 'inside' : 'outside'}
      isCentered={isLargerThan900}
      finalFocusRef={finalFocusRef}
      motionPreset={isLargerThan900 ? 'scale' : 'none'}
      {...props}
    >
      <ModalContent
        margin={0}
        maxW={isLargerThan900 ? '600px' : undefined}
        borderRadius={isLargerThan900 ? 'md' : 0}
        bg="gray.50"
        overflowY="hidden"
      >
        <ModalHeader
          color="primary.500"
          mt={isLargerThan900 ? undefined : 8}
          mb={isLargerThan900 ? undefined : 2}
        >
          Selecionar o local de estoque
        </ModalHeader>
        <ModalCloseButton
          mt={isLargerThan900 ? undefined : 10}
          mr={isLargerThan900 ? undefined : 4}
        />
        <ConditionalWrapper
          condition={isLargerThan900}
          wrapper={(wrappedChildren: React.ReactNode) => (
            <ShadowScrollbar
              maxHeight={360}
              paddingHeight={300}
              shadowTopStyle={{
                background:
                  'linear-gradient(to bottom, rgba(245, 245, 245, 1) 0%, rgba(245, 245, 245, 0) 100%)',
                height: 30,
              }}
              shadowBottomStyle={{
                background:
                  'linear-gradient(to top, rgba(245, 245, 245, 1) 0%, rgba(245, 245, 245, 0) 100%)',
                height: 30,
              }}
            >
              {wrappedChildren}
            </ShadowScrollbar>
          )}
        >
          <ModalBody overflowY="hidden">
            {isLoading && <LoadingPadrao />}
            <Grid
              templateColumns={isLargerThan900 ? 'repeat(3, 1fr)' : '1fr'}
              gap={isLargerThan900 ? 8 : 2}
              w="100%"
              overflowY="hidden"
            >
              {localEstoque.current.map((estoque) => (
                <EstoqueButton
                  key={estoque.id}
                  estoque={estoque}
                  isSelected={localEstoquePadraoId === estoque.id}
                  autoFocus={localEstoquePadraoId === estoque.id}
                  onClick={() => {
                    handleSetLocalEstoqueSelecionado(estoque.id);
                  }}
                />
              ))}
            </Grid>
          </ModalBody>
        </ConditionalWrapper>
        {isLargerThan900 && (
          <ModalFooter py={8} justifyContent="center">
            <HStack spacing={6}>
              <Button
                variant="solid"
                colorScheme="secondary"
                size="sm"
                minW="100px"
                onClick={handleSubmit}
              >
                Confirmar
              </Button>
            </HStack>
          </ModalFooter>
        )}
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export const ModalSelecionarLocalEstoque = create<
  ModalSelecionarLocalEstoque,
  PromiseModal
>(Modal);
