import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  ModalFooter,
  Box,
  useMediaQuery,
  Icon,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';

import { AtencaoAvisoIcon } from 'icons';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';

interface ModalConfirmarExcluirStone
  extends InstanceProps<ModalProps>,
    Omit<ModalProps, 'children' | 'isOpen' | 'onClose'> {
  confirmaExclusao: () => void;
}

export const ModalConfirmarExcluirStone = create<ModalConfirmarExcluirStone>(
  ({ onResolve, onReject, confirmaExclusao, ...rest }) => {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? '5xl' : 'full'}
        {...rest}
        closeOnOverlayClick={false}
        isOpen={isOpen}
        closeOnEsc={false}
        onClose={onClose}
        autoFocus
      >
        <ModalContent
          bg="gray.50"
          borderRadius={isLargerThan900 ? '5px' : '0'}
          w={isLargerThan900 ? '550px' : 'full'}
          h={isLargerThan900 ? '420px' : 'full'}
        >
          <ModalBody p="0">
            <Box w="full" h="full">
              <Flex
                h="135px"
                w="full"
                bg="orange.400"
                borderTopRadius="5px"
                justifyContent="center"
                alignItems="center"
              >
                <Icon as={AtencaoAvisoIcon} boxSize="75px" />
              </Flex>
              <Box w="full" px="36px" pt="30px">
                <Text fontSize="lg" color="orange.400" fontWeight="semibold">
                  Excluir recebimento Stone ?
                </Text>
                <Flex alignItems="center" justifyContent="center" py="16px">
                  O sistema deleta a forma de recebimento mas não cancela a
                  transação feita na máquina Stone. Se o pagamento foi
                  equivocado, cancele a transação direto na maquininha para
                  estornar no cartão do cliente. Tem certeza que deseja excluir?
                </Flex>
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter h={isLargerThan900 ? '80px' : '800px'}>
            <Flex w="full" h="full" justifyContent="center" gap="16px">
              <Button
                variant="outline"
                colorScheme="gray"
                onClick={() => {
                  onClose();
                }}
              >
                Cancelar
              </Button>
              <Button
                colorScheme="orange"
                variant="solid"
                onClick={() => {
                  confirmaExclusao();
                  onClose();
                }}
                color="black"
              >
                Excluir recebimento
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
