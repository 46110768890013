import { toast } from 'react-toastify';

import { ImprimirPDF } from 'helpers/impressoes/imprimirPDF';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';

type ImpressaoVendaA4Props = {
  id: string | undefined;
};

export async function ImprimirVendaA4({ id }: ImpressaoVendaA4Props) {
  const response = await api.get<void, ResponseApi<string>>(
    ConstanteEnderecoWebservice.IMPRESSAO_A4_VENDA,
    {
      params: { id },
    }
  );

  if (response.avisos) {
    response.avisos.forEach((avisos) => toast.warn(avisos));
  }

  if (response.sucesso && response.dados) {
    ImprimirPDF(response.dados, 'relatorioDocFiscais');
  }
}
