import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export type CamposPersonalizados = {
  campoPersonalizadoId: string;
  valor: string;
};

type Cidade = {
  label: string;
  paisId: number;
  value: number;
};

export type FormData = {
  relatorio: string;
  nome: string;
  apelido: string;
  email: string;
  categoria: string;
  cidade: string | null;
  observacao: string;
  camposPersonalizados: CamposPersonalizados[];
  mesNascimento: string;
};

const schema = yup.object().shape({
  relatorio: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);
