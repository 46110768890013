import React from 'react';
import { Stack } from '@chakra-ui/react';

import { useContextConciliacaoContas } from 'store/Financeiro/ConciliacaoContas';

import { Totalizador } from './components/Totalizador';

export const TotalizadoresGerais = () => {
  const { totalizadoresListagem } = useContextConciliacaoContas();
  const {
    quantidadeContas,
    valorTotalBruto,
    totalTaxa,
    valorTotalLiquido,
  } = totalizadoresListagem;

  return (
    <Stack
      width="100%"
      direction={{ base: 'column', md: 'column', lg: 'row' }}
      justifyContent={{ base: 'center', xl: 'flex-start' }}
      alignItems="center"
      pb="12px"
      pt="10px"
      borderTopRadius="8px"
      bg="primary.700"
      color="white"
    >
      <Totalizador
        title="Total de contas:"
        valorTotal={quantidadeContas}
        displayFormattedValue={false}
        color="white"
      />
      <Totalizador
        title="Total bruto:"
        valorTotal={valorTotalBruto}
        color="white"
      />
      <Totalizador
        title="Total em taxas:"
        valorTotal={totalTaxa}
        color="red.300"
      />
      <Totalizador
        title="Total líquido:"
        valorTotal={valorTotalLiquido}
        color="secondary.300"
        hasDivider={false}
      />
    </Stack>
  );
};
