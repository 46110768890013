import { Avatar, Box, Flex, Icon, Text } from '@chakra-ui/react';

import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import { moneyMask } from 'helpers/format/fieldsMasks';

import { Link } from 'components/update/Link';
import { DetalheFatura } from 'icons';

interface ContaFinanceiraItemProps {
  id: string;
  nome: string;
  saldo: number;
  icone: string;
}

export const ContaFinanceiraItem = ({
  id,
  nome,
  saldo,
  icone,
}: ContaFinanceiraItemProps) => {
  return (
    <Flex
      bg="white"
      _hover={{
        boxShadow: '0px 0px 4px rgba(85, 2, 178, 0.5)',
      }}
      justifyContent="space-between"
      alignItems="center"
      boxShadow="0px 0px 6px #00000034;"
      borderRadius="md"
      p="6"
      h={['100px', '100px', '80px']}
    >
      <Flex alignItems="center" justifyContent="center">
        <Avatar src={icone} borderRadius="full" boxSize={['10', '10', '12']} />
        <Box ml="10px">
          <Text fontSize="18px" letterSpacing="0" fontWeight="bold">
            {nome}
          </Text>
          <Flex alignItems="baseline" gap="4px">
            <Text fontSize="14px" color="gray.700">
              Saldo disponível:
            </Text>
            <Flex
              alignItems="baseline"
              gap="4px"
              color={
                saldo === 0
                  ? 'gray.700'
                  : saldo > 0
                  ? 'aquamarine.600'
                  : 'red.500'
              }
            >
              <Text>R$ </Text>
              <Text fontWeight="bold" fontSize="md">
                {moneyMask(saldo, false)}
              </Text>
            </Flex>
          </Flex>
        </Box>
      </Flex>
      <Flex justifyContent="space-between" alignItems="center" mt="1">
        <Link
          href={SubstituirParametroRota(
            ConstanteRotas.EXTRATOS_DETALHES,
            'id',
            id
          )}
          fontSize="sm"
          fontWeight="light"
        >
          <Icon stroke="gray.700" as={DetalheFatura} boxSize="24px" />
        </Link>
      </Flex>
    </Flex>
  );
};
