import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Icon,
  Stack,
  Td,
  Text,
  Tr,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { useEntradaMercadoriaDadosCadastroContext } from 'store/EntradaMercadoria/EntradaMercadoriaDadosCadastro';
import { useEntradaMercadoriaEtapasContext } from 'store/EntradaMercadoria/EntradaMercadoriaEtapas';

import {
  Container,
  Body,
  Footer,
  StepDescriptionAccordion,
} from 'components/update/Steps/StepContent';
import { CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import {
  LoadMoreRowsParams,
  TableHeader,
  VirtualizedInfiniteTable,
} from 'components/update/Table/VirtualizedInfiniteTable';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';

type LancamentoResponse = {
  id: string;
  nome: string;
  precoCusto: number;
  markup: number;
  precoVenda: number;
  valorUnitarioEntrada: number;
  freteDistribuido: number;
  outrasDespesasDistribuido: number;
  descontoDistribuido: number;
  custoAdicional: number;
  valorIcmsSt: number;
  valorIpi: number;
  valorFcpSt: number;
  custoTotal: number;
  custoMedioNovo: number;
  markupNovo: number;
  precoVendaNovo: number;
};

type Lancamento = LancamentoResponse & { isOpen: boolean };

const cache = new CellMeasurerCache({
  defaultHeight: 136,
  minHeight: 56,
  fixedWidth: true,
});

const lancamentosTableHeaders: TableHeader[] = [
  {
    key: 'produtos',
    content: 'Produtos',
    lineHeight: 'none',
    verticalAlign: 'bottom',
    width: '100%',
  },
  {
    key: 'custoAtual',
    content: 'Custo atual',
    lineHeight: 'none',
    verticalAlign: 'bottom',
    isNumeric: true,
    minWidth: '90px',
  },
  {
    key: 'markupAtual',
    content: 'Markup atual',
    lineHeight: 'none',
    verticalAlign: 'bottom',
    isNumeric: true,
    minWidth: '90px',
  },
  {
    key: 'valorVendaAtual',
    content: 'Valor venda',
    lineHeight: 'none',
    verticalAlign: 'bottom',
    isNumeric: true,
    minWidth: '90px',
  },
  {
    key: 'custoMedioNovo',
    content: 'Custo méd. novo',
    lineHeight: 'none',
    verticalAlign: 'bottom',
    isNumeric: true,
    minWidth: '90px',
  },
  {
    key: 'markupNovo',
    content: 'Markup novo',
    lineHeight: 'none',
    verticalAlign: 'bottom',
    isNumeric: true,
    minWidth: '90px',
  },
  {
    key: 'precoVendaNovo',
    content: 'Preço de venda novo',
    lineHeight: 'none',
    verticalAlign: 'bottom',
    isNumeric: true,
    minWidth: '120px',
  },
];

export function Custos() {
  const [isLargerThan1200] = useMediaQuery('(min-width: 1200px)');
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const { previousStep } = useEntradaMercadoriaEtapasContext();
  const {
    entradaMercadoriaId,
    voltarParaListagem,
    isReadOnly,
    menuIsOpen,
  } = useEntradaMercadoriaDadosCadastroContext();
  const { casasDecimais } = usePadronizacaoContext();

  const [isLancamentosLoading, setIsLancamentosLoading] = useState(false);
  const [lancamentos, setLancamentos] = useState<Lancamento[]>([]);

  const heightTable = () => {
    const itemOpenInTable = lancamentos?.filter(
      (lancamento) => lancamento.isOpen
    );
    const numberOfItensOpenInList = itemOpenInTable.length;
    const heightOfAllItensOpen = numberOfItensOpenInList * 112;
    const heightOfAllItensClosed =
      (lancamentos?.length - numberOfItensOpenInList) * 64;
    return heightOfAllItensOpen + heightOfAllItensClosed;
  };

  function getDynamicHeight(index: number, marginSize: number) {
    const lancamento = lancamentos[index];

    const isLastItem = index === lancamentos.length - 1;

    const closedLancamentoHeight = 56 + (isLastItem ? 0 : marginSize);
    const openedLancamentoHeight =
      (isLargerThan1200 ? 136 : 200) + (isLastItem ? 0 : marginSize);

    const lancamentoHeight = lancamento?.isOpen
      ? openedLancamentoHeight
      : closedLancamentoHeight;

    return lancamentoHeight;
  }

  function handleToggleLinhaLancamento(index: number) {
    const lancamento = lancamentos[index];

    const novosLancamentos = [...lancamentos];

    novosLancamentos.splice(index, 1, {
      ...lancamento,
      isOpen: !lancamento.isOpen,
    });

    setLancamentos(novosLancamentos);
  }

  function handleVoltar() {
    previousStep();
  }

  function handleFechar() {
    voltarParaListagem();
  }

  const latestProps = useRef({ voltarParaListagem });
  useEffect(() => {
    latestProps.current = { voltarParaListagem };
  });

  const loadMoreRows = useCallback(
    async ({
      currentPage,
      pageSize,
      orderColumn,
      orderDirection,
    }: LoadMoreRowsParams) => {
      if (entradaMercadoriaId) {
        setIsLancamentosLoading(true);

        const paginationData = {
          currentPage,
          pageSize,
          orderColumn,
          orderDirection,
        };

        const response = await api.get<void, ResponseApi<LancamentoResponse[]>>(
          formatQueryPagegTable(
            ConstanteEnderecoWebservice.ENTRADA_MERCADORIA_OBTER_HISTORICO_LANCAMENTO_ESTOQUE,
            paginationData
          ),
          { params: { id: entradaMercadoriaId } }
        );

        if (response && response.sucesso && response.dados) {
          setLancamentos(
            response.dados.map((lancamento) => ({
              ...lancamento,
              isOpen: false,
            }))
          );
        } else {
          latestProps.current.voltarParaListagem();
        }
      }

      setIsLancamentosLoading(false);
    },
    [entradaMercadoriaId]
  );

  const handleSalvarSair = useCallback(() => {
    latestProps.current.voltarParaListagem();
  }, []);

  return (
    <>
      <Container mt="5">
        <StepDescriptionAccordion
          stepNumber={6}
          title="Custos"
          description="Veja o custo médio, markup e valor de venda atualizados. Clique no nome do produto para consultar detalhadamente todos os valores que compõem o custo obtido na última entrada."
        />

        <Body>
          <Box sx={{ '& th': { border: 'none', whiteSpace: 'nowrap' } }}>
            <VirtualizedInfiniteTable
              visibleItemsCount={lancamentos?.length}
              heightTable={heightTable()}
              variant="simple-card"
              size="sm"
              boxShadow="none"
              isUpdateWidthTable
              isLoading={isLancamentosLoading}
              withoutRowsMessage="Nenhum lancamento adicionado."
              orderColumn="nomeProduto"
              tableHeaders={lancamentosTableHeaders}
              dynamicHeight={({ index }) => getDynamicHeight(index, 5)}
              itemHeight={56}
              rowRenderer={({
                index,
                style: { height, ...restStyle },
                key,
                parent,
              }) => {
                const lancamento = lancamentos[index];

                if (!lancamento) {
                  return null;
                }

                return (
                  <CellMeasurer
                    cache={cache}
                    columnIndex={1}
                    key={key}
                    parent={parent}
                    rowIndex={index}
                  >
                    {({ registerChild, measure }) => (
                      <Tr
                        ref={(e) => {
                          if (e && registerChild) {
                            registerChild(e);
                          }
                        }}
                        style={restStyle}
                        h={`${getDynamicHeight(index, 0)}px !important`}
                        sx={{
                          '& > td': {
                            ...(lancamento.isOpen
                              ? { marginBottom: '5px' }
                              : {}),
                            boxShadow: 'none',
                            _before: { display: 'none' },
                          },
                        }}
                        borderRadius="md"
                        border="1px"
                        borderColor="gray.100"
                        boxShadow="none !important"
                      >
                        <Td
                          width={lancamentosTableHeaders[0].width}
                          cursor="pointer"
                          userSelect="none"
                          onClick={() => {
                            measure();
                            handleToggleLinhaLancamento(index);
                          }}
                        >
                          <Text
                            lineHeight="0"
                            whiteSpace="nowrap"
                            fontSize="2xs"
                            opacity={0}
                          >
                            {lancamentosTableHeaders[0].content}
                          </Text>

                          <Icon
                            as={lancamento.isOpen ? FiChevronUp : FiChevronDown}
                            mr="1"
                          />

                          {lancamento.nome}
                        </Td>
                        <Td
                          minWidth={lancamentosTableHeaders[1].minWidth}
                          isNumeric
                        >
                          <Text
                            lineHeight="0"
                            whiteSpace="nowrap"
                            fontSize="2xs"
                            opacity={0}
                          >
                            {lancamentosTableHeaders[1].content}
                          </Text>

                          {lancamento.precoCusto?.toLocaleString('pt-BR', {
                            minimumFractionDigits:
                              casasDecimais.casasDecimaisValor,
                            maximumFractionDigits:
                              casasDecimais.casasDecimaisValor,
                          })}
                        </Td>
                        <Td
                          minWidth={lancamentosTableHeaders[2].minWidth}
                          isNumeric
                        >
                          <Text
                            lineHeight="0"
                            whiteSpace="nowrap"
                            fontSize="2xs"
                            opacity={0}
                          >
                            {lancamentosTableHeaders[2].content}
                          </Text>
                          {`${lancamento.markup?.toLocaleString('pt-BR', {
                            minimumFractionDigits:
                              casasDecimais.casasDecimaisValor,
                            maximumFractionDigits:
                              casasDecimais.casasDecimaisValor,
                          })}%`}
                        </Td>
                        <Td
                          minWidth={lancamentosTableHeaders[3].minWidth}
                          isNumeric
                        >
                          <Text
                            lineHeight="0"
                            whiteSpace="nowrap"
                            fontSize="2xs"
                            opacity={0}
                          >
                            {lancamentosTableHeaders[3].content}
                          </Text>

                          {lancamento.precoVenda?.toLocaleString('pt-BR', {
                            minimumFractionDigits:
                              casasDecimais.casasDecimaisValor,
                            maximumFractionDigits:
                              casasDecimais.casasDecimaisValor,
                          })}
                        </Td>
                        <Td
                          minWidth={lancamentosTableHeaders[4].minWidth}
                          isNumeric
                        >
                          <Text
                            lineHeight="0"
                            whiteSpace="nowrap"
                            fontSize="2xs"
                            opacity={0}
                          >
                            {lancamentosTableHeaders[4].content}
                          </Text>

                          {lancamento.custoMedioNovo?.toLocaleString('pt-BR', {
                            minimumFractionDigits:
                              casasDecimais.casasDecimaisValor,
                            maximumFractionDigits:
                              casasDecimais.casasDecimaisValor,
                          })}
                        </Td>
                        <Td
                          minWidth={lancamentosTableHeaders[5].minWidth}
                          isNumeric
                        >
                          <Text
                            lineHeight="0"
                            whiteSpace="nowrap"
                            fontSize="2xs"
                            opacity={0}
                          >
                            {lancamentosTableHeaders[5].content}
                          </Text>

                          {`${lancamento.markupNovo?.toLocaleString('pt-BR', {
                            minimumFractionDigits:
                              casasDecimais.casasDecimaisValor,
                            maximumFractionDigits:
                              casasDecimais.casasDecimaisValor,
                          })}%`}
                        </Td>
                        <Td
                          minWidth={lancamentosTableHeaders[6].minWidth}
                          isNumeric
                        >
                          <Text
                            lineHeight="0"
                            whiteSpace="nowrap"
                            fontSize="2xs"
                            opacity={0}
                          >
                            {lancamentosTableHeaders[6].content}
                          </Text>

                          {lancamento.precoVendaNovo?.toLocaleString('pt-BR', {
                            minimumFractionDigits:
                              casasDecimais.casasDecimaisValor,
                            maximumFractionDigits:
                              casasDecimais.casasDecimaisValor,
                          })}
                        </Td>

                        {lancamento.isOpen && (
                          <Flex
                            flexDirection="column"
                            h="full"
                            borderBottomRadius="md"
                            px="5"
                          >
                            <Divider borderColor="gray.100" />

                            <Grid
                              templateColumns={{
                                base: '1fr auto 1fr auto 1fr',
                                lg: '1fr auto 1fr auto 1fr auto 1fr auto 1fr',
                              }}
                              templateRows={{ base: '1fr auto 1fr', md: '1fr' }}
                              columnGap={{ base: '2', md: '4', lg: '6' }}
                              rowGap={3}
                              px="1"
                              h="77px"
                              w="full"
                              lineHeight="none"
                              py="6"
                              fontSize="xs"
                              fontWeight="light"
                            >
                              <VStack w="full" alignItems="flex-start">
                                <Text>Custo total do lancamento</Text>
                                <Text>
                                  para esta entrada:{' '}
                                  <Text
                                    as="span"
                                    bg="yellow.300"
                                    fontWeight="bold"
                                  >
                                    {`R$ ${lancamento.custoTotal?.toLocaleString(
                                      'pt-BR',
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }
                                    )}`}
                                  </Text>
                                </Text>
                              </VStack>
                              <Divider
                                orientation="vertical"
                                h="10"
                                borderColor="gray.100"
                              />
                              <VStack w="full" alignItems="flex-start">
                                <Text>
                                  Valor do lancamento:{' '}
                                  <Text as="span" fontWeight="bold">
                                    {`R$ ${lancamento.valorUnitarioEntrada?.toLocaleString(
                                      'pt-BR',
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }
                                    )}`}
                                  </Text>
                                </Text>

                                <Text>
                                  Frete:{' '}
                                  <Text as="span" fontWeight="bold">
                                    {`R$ ${lancamento.freteDistribuido?.toLocaleString(
                                      'pt-BR',
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }
                                    )}`}
                                  </Text>
                                </Text>
                              </VStack>
                              <Divider
                                orientation="vertical"
                                h="10"
                                borderColor="gray.100"
                              />
                              <VStack w="full" alignItems="flex-start">
                                <Text>
                                  Outras despesas:{' '}
                                  <Text as="span" fontWeight="bold">
                                    {`R$ ${lancamento.outrasDespesasDistribuido?.toLocaleString(
                                      'pt-BR',
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }
                                    )}`}
                                  </Text>
                                </Text>

                                <Text>
                                  Descontos:{' '}
                                  <Text as="span" fontWeight="bold">
                                    {`R$ ${lancamento.descontoDistribuido?.toLocaleString(
                                      'pt-BR',
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }
                                    )}`}
                                  </Text>
                                </Text>
                              </VStack>

                              <GridItem colSpan={isLargerThan1200 ? 1 : 5}>
                                <Divider
                                  orientation={
                                    isLargerThan1200 ? 'vertical' : 'horizontal'
                                  }
                                  h={isLargerThan1200 ? '10' : '1px'}
                                  borderColor="gray.100"
                                />
                              </GridItem>

                              <VStack w="full" alignItems="flex-start">
                                <Text>
                                  Custo adicional:{' '}
                                  <Text as="span" fontWeight="bold">
                                    {`R$ ${lancamento.custoAdicional?.toLocaleString(
                                      'pt-BR',
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }
                                    )}`}
                                  </Text>
                                </Text>

                                <Text>
                                  ICMS ST:{' '}
                                  <Text as="span" fontWeight="bold">
                                    {`R$ ${lancamento.valorIcmsSt?.toLocaleString(
                                      'pt-BR',
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }
                                    )}`}
                                  </Text>
                                </Text>
                              </VStack>
                              <Divider
                                orientation="vertical"
                                h="10"
                                borderColor="gray.100"
                              />
                              <VStack w="full" alignItems="flex-start">
                                <Text>
                                  IPI:{' '}
                                  <Text as="span" fontWeight="bold">
                                    {`R$ ${lancamento.valorIpi?.toLocaleString(
                                      'pt-BR',
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }
                                    )}`}
                                  </Text>
                                </Text>

                                <Text>
                                  FCP ST:{' '}
                                  <Text as="span" fontWeight="bold">
                                    {`R$ ${lancamento.valorFcpSt?.toLocaleString(
                                      'pt-BR',
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }
                                    )}`}
                                  </Text>
                                </Text>
                              </VStack>
                            </Grid>
                          </Flex>
                        )}
                      </Tr>
                    )}
                  </CellMeasurer>
                );
              }}
              rowCount={lancamentos.length}
              isRowLoaded={({ index }) => !!lancamentos[index]}
              loadMoreRows={loadMoreRows}
              canRenderEmptyRows
            />
          </Box>
        </Body>
      </Container>
      <Footer
        justifyContent={isReadOnly ? 'space-between' : 'flex-end'}
        position={isLargerThan900 ? 'fixed' : 'relative'}
        bottom="0px"
        bg="gray.50"
        borderTop={isLargerThan900 ? '1px solid' : 'none'}
        borderColor="#5502B2"
        w={`calc(100% - ${menuIsOpen ? '210px' : '108px'})`}
        py="16px"
        px="48px"
      >
        <Button
          variant="outlineDefault"
          borderRadius="full"
          w="full"
          maxW={{ base: 'full', md: '160px' }}
          onClick={handleVoltar}
        >
          Voltar
        </Button>

        <Stack
          w="full"
          justifyContent="flex-end"
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: 2, sm: 4, md: 6 }}
        >
          {entradaMercadoriaId && !isReadOnly && (
            <Button
              variant="outlineDefault"
              borderRadius="full"
              w={['full', 'full', '160px']}
              maxW={{ base: 'full', md: '160px' }}
              onClick={handleSalvarSair}
            >
              Salvar e sair
            </Button>
          )}

          <Button
            colorScheme="purple"
            borderRadius="full"
            w="full"
            maxW={{ base: 'full', md: isReadOnly ? '196px' : '160px' }}
            onClick={handleFechar}
          >
            Fechar
          </Button>
        </Stack>
      </Footer>
    </>
  );
}
