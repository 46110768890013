import {
  Box,
  Checkbox,
  Flex,
  Text,
  Button,
  Grid,
  Divider,
  Icon,
  useMediaQuery,
  VStack,
  Circle,
  RadioGroup,
  Radio,
} from '@chakra-ui/react';
import { useState, useEffect, useRef, useCallback } from 'react';
import Draggable from 'react-draggable';
import { FormProvider, useForm } from 'react-hook-form';
import QRCode from 'qrcode.react';
import { toast } from 'react-toastify';
import { Prompt, useHistory } from 'react-router-dom';

import getOptionsByEnum from 'helpers/format/getOptionsByEnum';
import EtiquetaTamanhoLetra from 'constants/enum/etiquetaPersonalizadaFonte';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import auth from 'modules/auth';
import {
  enumTipoEtiqueta,
  propriedadesEtiquetas,
} from 'constants/enum/propriedadesEtiquetas';
import api, { ResponseApi } from 'services/api';
import ConstanteRotas from 'constants/rotas';
import useReloadRegistration from 'helpers/layout/useReloadRegistration';
import isPrenvent from 'helpers/layout/isPrenvent';
import { TipoImpressoraEnum } from 'constants/enum/fiscal/tipoImpressora';
import TipoTabelaPrecoEnum from 'constants/enum/tipoTabelaPrecoEnum';

import { NumberInput } from 'components/update/Input/NumberInput';
import { SalvarConfirmarIcon, SalvarInserirNovoIcon } from 'icons';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import Input from 'components/PDV/Input';
import CheckboxAtivoInativo from 'components/update/Checkbox/CheckboxAtivoInativo';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { ModalDetalhesEtiquetaDesktop } from 'components/Modal/ModalDetalhesEtiquetaDesktop';

import {
  EtiquetaPersonalizadaProps,
  formDefaultValues,
  PropertiesEtiquetas,
  PropriedadesItensEtiqueta,
  yupResolver,
} from './validationForm';

type InfomacoesRenderizacaoDraggable = {
  item: PropriedadesItensEtiqueta;
};

type PropriedadesVerificacaoEtiquetas = {
  isReadonly?: boolean;
  isAlterar?: boolean;
  obterIdEtiqueta?: string;
  obterNomeEtiqueta?: string;
  listaInformacoesVisualizar?: PropertiesEtiquetas;
};

type OptionsValues = {
  id: string;
  nome: string;
};

type Options = {
  label: string;
  value: string | number;
};

export function EtiquetasPersonalizadas({
  isReadonly,
  isAlterar,
  obterNomeEtiqueta,
  listaInformacoesVisualizar,
  obterIdEtiqueta,
}: PropriedadesVerificacaoEtiquetas) {
  const [
    informacoesEtiquetaPersonalizada,
    setInformacoesEtiquetaPersonalizada,
  ] = useState<PropertiesEtiquetas>(
    propriedadesEtiquetas as PropertiesEtiquetas
  );
  const [tabelaPreco, setTabelaPreco] = useState<Options[]>([]);

  const [obterIdPropriedades, setObterIdPropriedades] = useState(0);
  const [
    obterNomeItemSelecionadoEdicao,
    setObterNomeItemSelecionadoEdicao,
  ] = useState('');

  const [isLargerThan1370] = useMediaQuery('(max-width: 1610px)');
  const [isLargerThan900] = useMediaQuery('(max-width: 900px)');
  const [isLargerThan1309] = useMediaQuery('(max-width: 1309px)');

  const [checkedNegrito, setCheckedNegrito] = useState(false);
  const [checkedItalico, setCheckedItalico] = useState(false);
  const [checkedSublinhado, setCheckedSublinhado] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [formIsDirty, setFormIsDirty] = useState(false);

  const defaultValorLogo = enumTipoEtiqueta.LOGO.name;
  const defaultValorPreco = enumTipoEtiqueta.PRECO.name;
  const defaultValorCodigoBarras = enumTipoEtiqueta.CODIGO_BARRAS.name;
  const [logoSelecionado, setLogoSelecionado] = useState(defaultValorLogo);
  const [precoSelecionado, setPrecoSelecionado] = useState(defaultValorPreco);
  const [codigoBarrasSelecionado, setCodigoBarrasSelecionado] = useState(
    defaultValorCodigoBarras
  );
  const history = useHistory();
  const { id: lojaId } = auth.getLoja();
  const formMethods = useForm<EtiquetaPersonalizadaProps>({
    resolver: yupResolver,
    defaultValues: formDefaultValues,
  });

  const historyReload = useReloadRegistration({ setFormIsDirty });
  isPrenvent(formIsDirty);

  const latestProps = useRef({
    setValue: formMethods.setValue,
    setFocus: formMethods.setFocus,
  });
  const nodeRef = useRef(null);

  useEffect(() => {
    if (listaInformacoesVisualizar) {
      setInformacoesEtiquetaPersonalizada(listaInformacoesVisualizar);
    }
  }, [listaInformacoesVisualizar]);

  const [
    comprimentoEtiqueta,
    quantidadeParcela,
    tipoTabelaPrecoIdWatch,
    tabelaPrecoWatch,
    alturaEtiqueta,
    margemSuperior,
    margemEsquerda,
    numeroLinhas,
    numeroColunas,
    espacoColunas,
    espacoLinhas,
    alterarTamanhoFonte,
    nomeEtiqueta,
    etiquetaAtiva,
    textoFixo,
    textoFixo2,
    tamanhoCodigoBarras,
    tipoImpressora,
    quantidadeCaracteres,
  ] = formMethods.watch([
    'comprimentoEtiqueta',
    'quantidadeParcela',
    'tipoTabelaPrecoId',
    'tabelaPrecoId',
    'alturaEtiqueta',
    'margemSuperior',
    'margemEsquerda',
    'numeroLinhas',
    'numeroColunas',
    'espacoColunas',
    'espacoLinhas',
    'alterarTamanhoFonte',
    'nomeEtiqueta',
    'etiquetaAtiva',
    'textoFixo',
    'textoFixo2',
    'tamanhoCodigoBarras',
    'tipoImpressora',
    'quantidadeCaracteres',
  ]);
  const valorComprimentoCm =
    (96 * (formMethods.getValues().comprimentoEtiqueta || 0)) / 2.54;
  const valorAlturaCm = (96 * (alturaEtiqueta || 0)) / 2.54;
  const espacoEntreLinhasCm = (96 * (espacoLinhas || 0)) / 2.54;

  const existeItemSelecionado = informacoesEtiquetaPersonalizada?.propriedadesItensEtiqueta.some(
    (itemSelecionado) => itemSelecionado.isChecked === true
  );
  const ItemSelecionadoFiltrado = informacoesEtiquetaPersonalizada?.propriedadesItensEtiqueta.filter(
    (itemSelecionado) => itemSelecionado.isChecked === true
  );

  const existeItemSelecionadoVisualizar = (
    listaInformacoesVisualizar?.propriedadesItensEtiqueta || []
  ).some((itemSelecionado) => itemSelecionado.isChecked === true);

  const nomeEtiquetaComTipoImpressora = `${tipoImpressora} - ${nomeEtiqueta}`;

  const ArrayDeValoresParaEdicao = informacoesEtiquetaPersonalizada.propriedadesItensEtiqueta
    .filter((etiqueta) => {
      switch (etiqueta.id) {
        case enumTipoEtiqueta.LOGO.value:
          return null;
        case enumTipoEtiqueta.QRCODE.value:
          return null;
        case enumTipoEtiqueta.CODIGO_BARRAS.value:
          return null;
        default:
          return etiqueta;
      }
    })
    .map((item) => item.name);

  const itemSelecionadoEdicao = ArrayDeValoresParaEdicao.includes(
    obterNomeItemSelecionadoEdicao
  );

  const itemSelecionadoFiltradoEstaCheck = ItemSelecionadoFiltrado.some(
    (itemIndex) => itemIndex.name === obterNomeItemSelecionadoEdicao
  );

  const filtrandoEtiqueta = informacoesEtiquetaPersonalizada.propriedadesItensEtiqueta.filter(
    (valorId) => valorId.id === obterIdPropriedades
  );

  const renderizarAparecerAjudaImpressao = () => {
    ModalDetalhesEtiquetaDesktop({ lojaId });
  };

  function informacoesItensSelecionadosEtiqueta(index: number, name: string) {
    setObterIdPropriedades(index);
    setObterNomeItemSelecionadoEdicao(name);
  }

  function RenderizarValoresDraggable({
    item,
  }: InfomacoesRenderizacaoDraggable) {
    if (item.id === enumTipoEtiqueta.CODIGO_BARRAS.value) {
      return (
        <Text
          fontFamily="'Libre Barcode 39', cursive"
          fontSize="30px"
          lineHeight="10px"
        >
          0000000
        </Text>
      );
    }

    if (item.id === enumTipoEtiqueta.QRCODE.value) {
      return <QRCode value="" size={75} renderAs="canvas" />;
    }

    return (
      <Text
        h="0"
        lineHeight="5px"
        cursor="pointer"
        fontSize={item.tamanhoFonte}
        fontStyle={item.valorItalico}
        fontWeight={item.valorNegrito}
        textDecoration={item.valorSublinhado}
      >
        {item.name}
      </Text>
    );
  }

  const obterEixosDraggable = useCallback(
    (data: { x: number; y: number }) => {
      const novoArray = [
        ...informacoesEtiquetaPersonalizada?.propriedadesItensEtiqueta,
      ];
      const modificandoPosicaoXY = novoArray.map((item) => ({
        ...item,
        eixoX: item?.id === obterIdPropriedades ? data.x : item.eixoX,
        eixoY: item?.id === obterIdPropriedades ? data.y : item.eixoY,
      }));

      setInformacoesEtiquetaPersonalizada((valorAnterior) => ({
        ...valorAnterior,
        propriedadesItensEtiqueta: modificandoPosicaoXY,
      }));
    },
    [informacoesEtiquetaPersonalizada, obterIdPropriedades]
  );

  const valoresNovosEtiqueta = useCallback(() => {
    const novoArray = [
      ...informacoesEtiquetaPersonalizada?.propriedadesItensEtiqueta,
    ];
    const edicaoFonteAlterada = novoArray.map((item) => {
      const valorEixoY = (item.eixoY * valorAlturaCm) / alturaEtiqueta;
      const valorEixoX =
        (item.eixoX * valorComprimentoCm) / comprimentoEtiqueta;
      return {
        ...item,
        negrito: item.valorNegrito === 'black',
        sublinhado: item.valorSublinhado === 'underline',
        italico: item.valorItalico === 'italic',
        eixoYemCm: item.isChecked ? valorEixoY : item.eixoY,
        eixoXemCm: item.isChecked ? valorEixoX : item.eixoX,
        tipoLogo: logoSelecionado,
        tipoCodigoBarras: codigoBarrasSelecionado,
        quantidadeParcela:
          item.id === enumTipoEtiqueta.PARCELAMENTO.value
            ? quantidadeParcela
            : null,
        quantidadeCaracteres:
          item.id === enumTipoEtiqueta.DESCRICAO_PRODUTO.value
            ? quantidadeCaracteres
            : item.id === enumTipoEtiqueta.NUMERO_CODIGO_BARRAS.value
            ? 13
            : 0,
        tabelaPrecoId:
          enumTipoEtiqueta.PARCELAMENTO.value === item.id
            ? tipoTabelaPrecoIdWatch
            : tabelaPrecoWatch,
      };
    });
    const informacoesEtiquetas = {
      tipoImpressora,
      alturaEtiqueta: informacoesEtiquetaPersonalizada.alturaEtiqueta,
      etiquetaAtiva: informacoesEtiquetaPersonalizada.etiquetaAtiva,
      comprimentoEtiqueta: informacoesEtiquetaPersonalizada.comprimentoEtiqueta,
      margemSuperior: informacoesEtiquetaPersonalizada.margemSuperior || 0,
      margemEsquerda: informacoesEtiquetaPersonalizada.margemEsquerda || 0,
      numeroLinhas: informacoesEtiquetaPersonalizada.numeroLinhas || 1,
      numeroColunas: informacoesEtiquetaPersonalizada.numeroColunas || 1,
      espacoColunas: informacoesEtiquetaPersonalizada.espacoColunas || 0,
      espacoLinhas: informacoesEtiquetaPersonalizada.espacoLinhas || 0,
      propriedadesItensEtiqueta: edicaoFonteAlterada,
    };
    return informacoesEtiquetas;
  }, [
    informacoesEtiquetaPersonalizada,
    tipoImpressora,
    valorAlturaCm,
    alturaEtiqueta,
    valorComprimentoCm,
    comprimentoEtiqueta,
    logoSelecionado,
    codigoBarrasSelecionado,
    quantidadeParcela,
    quantidadeCaracteres,
    tipoTabelaPrecoIdWatch,
    tabelaPrecoWatch,
  ]);
  const informacoesEtiquetasAtualizadas = valoresNovosEtiqueta();

  const isValidateEtiqueta = informacoesEtiquetasAtualizadas.propriedadesItensEtiqueta
    .filter((item) => item.isChecked)
    .some(
      (itemEtiqueta) =>
        itemEtiqueta.eixoY > valorAlturaCm - 7 ||
        itemEtiqueta.eixoX > valorComprimentoCm - 7
    );

  const getTabelaPrecoValues = useCallback(async () => {
    const response = await api.get<void, ResponseApi<OptionsValues[]>>(
      `${ConstanteEnderecoWebservice.TABELA_PRECO_LISTAR_TABELAS_PRECO}?tipo=${TipoTabelaPrecoEnum.COMUM}`
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }

      if (response?.sucesso) {
        setTabelaPreco(
          response.dados.map((tabelasPreco) => {
            return {
              label: tabelasPreco.nome,
              value: tabelasPreco.id,
            } as Options;
          })
        );
      }
    }
  }, []);

  const handleSubmit = formMethods.handleSubmit(async () => {
    setFormIsDirty(true);

    if (isValidateEtiqueta) {
      toast.warning('A composição da etiqueta ultrapassou o tamanho informado');
      return;
    }

    const jsonArray = JSON.stringify(informacoesEtiquetasAtualizadas);

    if (existeItemSelecionado || existeItemSelecionadoVisualizar) {
      if (isAlterar) {
        const response = await api.put<
          void,
          ResponseApi<EtiquetaPersonalizadaProps[]>
        >(ConstanteEnderecoWebservice.ETIQUETA_PERSONALIZADA_ALTERAR, {
          id: obterIdEtiqueta,
          nome: nomeEtiquetaComTipoImpressora,
          configuracoes: jsonArray,
          ativo: etiquetaAtiva,
        });

        if (response.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response.sucesso) {
          toast.success('O cadastro foi alterado com sucesso.');
          setFormIsDirty(false);
          history.push(ConstanteRotas.ETIQUETAS_PERSONALIZADAS);
        }
      } else {
        const response = await api.post<
          void,
          ResponseApi<EtiquetaPersonalizadaProps[]>
        >(ConstanteEnderecoWebservice.ETIQUETAS_PERSONALIZADAS_CADASTRAR, {
          id: '',
          nome: nomeEtiquetaComTipoImpressora,
          configuracoes: jsonArray,
          ativo: etiquetaAtiva,
        });

        if (response.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response.sucesso) {
          setFormIsDirty(false);
          toast.success('O cadastro foi realizado com sucesso.');
          history.push(ConstanteRotas.ETIQUETAS_PERSONALIZADAS);
        }
      }
    } else {
      toast.warning('Informe ao menos uma composição de etiqueta');
    }
  });

  const onSubmitReset = formMethods.handleSubmit(async () => {
    setIsLoading(true);

    if (isValidateEtiqueta) {
      toast.warning('A composição da etiqueta ultrapassou o tamanho informado');
      setIsLoading(false);
      return;
    }
    const jsonArray = JSON.stringify(informacoesEtiquetasAtualizadas);

    if (existeItemSelecionado || existeItemSelecionadoVisualizar) {
      if (isAlterar) {
        const response = await api.put<
          void,
          ResponseApi<EtiquetaPersonalizadaProps[]>
        >(ConstanteEnderecoWebservice.ETIQUETA_PERSONALIZADA_ALTERAR, {
          id: obterIdEtiqueta,
          nome: nomeEtiquetaComTipoImpressora,
          configuracoes: jsonArray,
          ativo: etiquetaAtiva,
        });

        if (response.avisos) {
          setIsLoading(false);
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response.sucesso) {
          historyReload(ConstanteRotas.ETIQUETAS_PERSONALIZADAS_CADASTRAR);
          toast.success('O cadastro foi alterado com sucesso.');
          setIsLoading(false);
        }
      } else {
        const response = await api.post<
          void,
          ResponseApi<EtiquetaPersonalizadaProps[]>
        >(ConstanteEnderecoWebservice.ETIQUETAS_PERSONALIZADAS_CADASTRAR, {
          id: '',
          nome: nomeEtiquetaComTipoImpressora,
          configuracoes: jsonArray,
          ativo: etiquetaAtiva,
        });

        if (response.avisos) {
          setIsLoading(false);
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }

        if (response.sucesso) {
          toast.success('O cadastro foi realizado com sucesso.');
          historyReload(ConstanteRotas.ETIQUETAS_PERSONALIZADAS_CADASTRAR);
          setIsLoading(false);
        }
      }
    } else {
      toast.warning('Informe ao menos uma composição de etiqueta');
    }
    setIsLoading(false);
  });

  function handleToggleAdicionarDraggable(index: number) {
    if (informacoesEtiquetaPersonalizada) {
      const novoArray = [
        ...informacoesEtiquetaPersonalizada?.propriedadesItensEtiqueta,
      ];

      const item = novoArray[index];

      novoArray.splice(index, 1, {
        ...item,
        isChecked: !item.isChecked,
      });

      setInformacoesEtiquetaPersonalizada((valorAnterior) => ({
        ...valorAnterior,
        propriedadesItensEtiqueta: novoArray,
      }));

      if (item.isChecked) {
        setLogoSelecionado(defaultValorLogo);
        setInformacoesEtiquetaPersonalizada((valorAnterior) => ({
          ...valorAnterior,
          propriedadesItensEtiqueta: novoArray.map((renderizarAlteracoes) => ({
            ...renderizarAlteracoes,
            tipoLogo:
              renderizarAlteracoes.id === enumTipoEtiqueta.LOGO.value
                ? defaultValorLogo
                : renderizarAlteracoes.tipoLogo,
            valorNegrito:
              renderizarAlteracoes?.id === index
                ? ''
                : renderizarAlteracoes.valorNegrito,
            valorSublinhado:
              renderizarAlteracoes?.id === index
                ? ''
                : renderizarAlteracoes.valorSublinhado,
            valorItalico:
              renderizarAlteracoes?.id === index
                ? ''
                : renderizarAlteracoes.valorItalico,
            tamanhoFonte:
              renderizarAlteracoes?.id === index
                ? 9
                : renderizarAlteracoes.tamanhoFonte,
          })),
        }));
        latestProps.current.setValue(
          'textoFixo',
          listaInformacoesVisualizar?.propriedadesItensEtiqueta.filter(
            (itemEtiquetaFiltrado) => itemEtiquetaFiltrado.id === 13
          )[0].textoFixo || ''
        );
        latestProps.current.setValue(
          'textoFixo2',
          listaInformacoesVisualizar?.propriedadesItensEtiqueta.filter(
            (itemEtiquetaFiltrado) =>
              itemEtiquetaFiltrado.id === enumTipoEtiqueta.TEXTO_FIXO_2.value
          )[0].textoFixo2 || ''
        );
        latestProps.current.setValue(
          'tabelaPrecoId',
          listaInformacoesVisualizar?.propriedadesItensEtiqueta.filter(
            (itemEtiquetaFiltrado) =>
              itemEtiquetaFiltrado.id === enumTipoEtiqueta.PRECO_2.value
          )[0].tabelaPrecoId || ''
        );
      }
    }
  }

  const handleEscolhendoTipoLogo = useCallback(
    (logoEscolhido: string) => {
      setLogoSelecionado(logoEscolhido);
      const newListaPropriedadesEtiqueta = [
        ...informacoesEtiquetaPersonalizada?.propriedadesItensEtiqueta,
      ];
      const selecionandoTipoLogo = newListaPropriedadesEtiqueta.map((item) => ({
        ...item,
        tipoLogo: logoEscolhido,
      }));

      setInformacoesEtiquetaPersonalizada((valorAnterior) => ({
        ...valorAnterior,
        propriedadesItensEtiqueta: selecionandoTipoLogo,
      }));
    },
    [informacoesEtiquetaPersonalizada]
  );

  const handleSublinhado = useCallback(() => {
    const novoArray = [
      ...informacoesEtiquetaPersonalizada?.propriedadesItensEtiqueta,
    ];
    const modificandoAsFontes = novoArray.map((item) => ({
      ...item,
      valorSublinhado:
        item?.id === obterIdPropriedades
          ? checkedSublinhado === true
            ? 'none'
            : 'underline'
          : item?.valorSublinhado,
    }));

    setInformacoesEtiquetaPersonalizada((valorAnterior) => ({
      ...valorAnterior,
      propriedadesItensEtiqueta: modificandoAsFontes,
    }));
    setCheckedSublinhado(!checkedSublinhado);
  }, [
    informacoesEtiquetaPersonalizada,
    checkedSublinhado,
    obterIdPropriedades,
  ]);

  const handleItalico = useCallback(() => {
    const novoArray = [
      ...informacoesEtiquetaPersonalizada?.propriedadesItensEtiqueta,
    ];
    const modificandoAsFontes = novoArray.map((item) => ({
      ...item,
      valorItalico:
        item?.id === obterIdPropriedades
          ? checkedItalico === true
            ? 'none'
            : 'italic'
          : item?.valorItalico,
    }));

    setInformacoesEtiquetaPersonalizada((valorAnterior) => ({
      ...valorAnterior,
      propriedadesItensEtiqueta: modificandoAsFontes,
    }));
    setCheckedItalico(!checkedItalico);
  }, [informacoesEtiquetaPersonalizada, checkedItalico, obterIdPropriedades]);

  const handleNegrito = useCallback(() => {
    const novoArray = [
      ...informacoesEtiquetaPersonalizada?.propriedadesItensEtiqueta,
    ];
    const modificandoAsFontes = novoArray.map((item) => ({
      ...item,

      valorNegrito:
        item?.id === obterIdPropriedades
          ? checkedNegrito === true
            ? 'none'
            : 'black'
          : item?.valorNegrito,
    }));

    setInformacoesEtiquetaPersonalizada((valorAnterior) => ({
      ...valorAnterior,
      propriedadesItensEtiqueta: modificandoAsFontes,
    }));
    setCheckedNegrito(!checkedNegrito);
  }, [informacoesEtiquetaPersonalizada, checkedNegrito, obterIdPropriedades]);

  useEffect(() => {
    latestProps.current = {
      setValue: formMethods.setValue,
      setFocus: formMethods.setFocus,
    };
  }, [formMethods]);

  useEffect(() => {
    setInformacoesEtiquetaPersonalizada((valorAnterior) => ({
      ...valorAnterior,
      propriedadesItensEtiqueta: valorAnterior.propriedadesItensEtiqueta.map(
        (valorFonte) => ({
          ...valorFonte,

          tamanhoFonte:
            valorFonte?.id === obterIdPropriedades
              ? alterarTamanhoFonte
              : valorFonte?.tamanhoFonte,
        })
      ),
    }));
  }, [alterarTamanhoFonte, obterIdPropriedades]);

  useEffect(() => {
    const filtrandoId = informacoesEtiquetaPersonalizada.propriedadesItensEtiqueta.filter(
      (valorId) => valorId.id === obterIdPropriedades
    );
    const obterValorFonte = (filtrandoId[0] || []).tamanhoFonte;

    latestProps.current.setValue('alterarTamanhoFonte', obterValorFonte);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obterIdPropriedades]);

  useEffect(() => {
    const obterItemFiltradoNegrito = filtrandoEtiqueta.some(
      (existeItem) => existeItem.valorNegrito === 'black'
    );
    if (obterItemFiltradoNegrito) {
      setCheckedNegrito(true);
    } else {
      setCheckedNegrito(false);
    }
  }, [
    informacoesEtiquetaPersonalizada,
    obterIdPropriedades,
    filtrandoEtiqueta,
  ]);

  useEffect(() => {
    setInformacoesEtiquetaPersonalizada((valorAnterior) => {
      const newListaPropriedadesEtiqueta = [
        ...valorAnterior?.propriedadesItensEtiqueta,
      ];
      const selecionandoTipoPreco = newListaPropriedadesEtiqueta.map(
        (item) => ({
          ...item,
          tipoPreco: precoSelecionado,
        })
      );

      const newValues = {
        ...valorAnterior,
        propriedadesItensEtiqueta: selecionandoTipoPreco,
      };

      return newValues;
    });
  }, [precoSelecionado, tipoTabelaPrecoIdWatch]);

  useEffect(() => {
    const obterItemFiltradoItalico = filtrandoEtiqueta.some(
      (existeItem) => existeItem.valorItalico === 'italic'
    );

    if (obterItemFiltradoItalico) {
      setCheckedItalico(true);
    } else {
      setCheckedItalico(false);
    }
  }, [
    informacoesEtiquetaPersonalizada,
    obterIdPropriedades,
    filtrandoEtiqueta,
  ]);

  useEffect(() => {
    const obterItemFiltradoSublinhado = filtrandoEtiqueta.some(
      (existeItem) => existeItem.valorSublinhado === 'underline'
    );

    if (obterItemFiltradoSublinhado) {
      setCheckedSublinhado(true);
    } else {
      setCheckedSublinhado(false);
    }
  }, [
    informacoesEtiquetaPersonalizada,
    obterIdPropriedades,
    filtrandoEtiqueta,
  ]);

  useEffect(() => {
    if (isAlterar || isReadonly) {
      const valoresAtualizados =
        listaInformacoesVisualizar?.propriedadesItensEtiqueta || [];

      formMethods.reset({
        ...listaInformacoesVisualizar,
        nomeEtiqueta: obterNomeEtiqueta ? obterNomeEtiqueta.slice(8) : '',
        textoFixo:
          valoresAtualizados.find(
            (item) => item.id === enumTipoEtiqueta.TEXTO_FIXO.value
          )?.textoFixo || '',
        textoFixo2:
          valoresAtualizados.find(
            (item) => item.id === enumTipoEtiqueta.TEXTO_FIXO_2.value
          )?.textoFixo2 || '',

        tabelaPrecoId:
          valoresAtualizados.find(
            (item) => item.id === enumTipoEtiqueta.PRECO_2.value
          )?.tabelaPrecoId || '',
        tipoTabelaPrecoId:
          valoresAtualizados.find(
            (item) => item.id === enumTipoEtiqueta.PARCELAMENTO.value
          )?.tabelaPrecoId || '',
        quantidadeParcela:
          valoresAtualizados.find(
            (item) => item.id === enumTipoEtiqueta.PARCELAMENTO.value
          )?.quantidadeParcela || 1,
        tamanhoCodigoBarras:
          valoresAtualizados.find((item) => item.id === 9)
            ?.tamanhoCodigoBarras || 0,
        quantidadeCaracteres:
          valoresAtualizados.find(
            (item) => item.id === enumTipoEtiqueta.DESCRICAO_PRODUTO.value
          )?.quantidadeCaracteres || 0,
      });

      setLogoSelecionado(
        valoresAtualizados.find(
          (item) => item.id === enumTipoEtiqueta.LOGO.value
        )?.tipoLogo || defaultValorLogo
      );
      setCodigoBarrasSelecionado(
        valoresAtualizados.find(
          (item) => item.id === enumTipoEtiqueta.CODIGO_BARRAS.value
        )?.tipoCodigoBarras || defaultValorCodigoBarras
      );

      setPrecoSelecionado(
        valoresAtualizados.find(
          (item) => item.id === enumTipoEtiqueta.PARCELAMENTO.value
        )?.tipoPreco || defaultValorPreco
      );
    }
  }, [
    listaInformacoesVisualizar,
    isAlterar,
    formMethods,
    obterNomeEtiqueta,
    isReadonly,
    defaultValorPreco,
    defaultValorLogo,
    defaultValorCodigoBarras,
  ]);

  useEffect(() => {
    function salvarAlteracoesEtiqueta() {
      setInformacoesEtiquetaPersonalizada((valorAnterior) => ({
        ...valorAnterior,
        alturaEtiqueta,
        etiquetaAtiva,
        comprimentoEtiqueta,
        margemSuperior,
        margemEsquerda,
        numeroLinhas,
        numeroColunas,
        espacoColunas,
        espacoLinhas,
        propriedadesItensEtiqueta: valorAnterior.propriedadesItensEtiqueta.map(
          (valorPropriedades) => ({
            ...valorPropriedades,
            textoFixo,
            textoFixo2,
            tabelaPrecoId: tabelaPrecoWatch,
            tamanhoCodigoBarras,
          })
        ),
      }));
    }
    salvarAlteracoesEtiqueta();
  }, [
    alturaEtiqueta,
    etiquetaAtiva,
    margemSuperior,
    margemEsquerda,
    espacoEntreLinhasCm,
    espacoColunas,
    numeroLinhas,
    comprimentoEtiqueta,
    numeroColunas,
    textoFixo,
    tabelaPrecoWatch,
    textoFixo2,
    espacoLinhas,
    tamanhoCodigoBarras,
  ]);

  useEffect(() => {
    if (!existeItemSelecionado) {
      setObterNomeItemSelecionadoEdicao('');
      formMethods.setValue('alterarTamanhoFonte', 9);
    }
  }, [existeItemSelecionado, formMethods]);

  useEffect(() => {
    if (quantidadeParcela === 0) {
      formMethods.setValue('quantidadeParcela', 1);
    }
  }, [existeItemSelecionado, formMethods, quantidadeParcela]);

  useEffect(() => {
    if (!tabelaPrecoWatch) {
      formMethods.setValue(
        'tabelaPrecoId',
        tabelaPreco.length > 0 ? tabelaPreco[0].value : tabelaPrecoWatch
      );
    }
    if (!tipoTabelaPrecoIdWatch) {
      formMethods.setValue(
        'tipoTabelaPrecoId',
        tabelaPreco.length > 0 ? tabelaPreco[0].value : tabelaPrecoWatch
      );
    }
  }, [formMethods, tabelaPrecoWatch, tabelaPreco, tipoTabelaPrecoIdWatch]);

  return (
    <>
      {!isLargerThan900 ? (
        <>
          {isLoading && <LoadingPadrao />}
          <Box bg="white" borderRadius="10px" padding="20px">
            <VStack
              alignItems="left"
              spacing="3"
              py="3"
              px="6"
              mb="15px"
              borderRadius="md"
              bg="gray.50"
            >
              <Flex
                onClick={() => renderizarAparecerAjudaImpressao()}
                cursor="pointer"
                justifyContent="left"
                alignItems="center"
              >
                <Circle bg="blue.500" mr="10px" size="6" color="white">
                  ?
                </Circle>

                <Text fontSize="sm">
                  Precisa de ajuda para imprimir a etiqueta? Clique aqui e siga
                  os seguintes passos:
                </Text>
              </Flex>
            </VStack>
            <Prompt when={formIsDirty} message="" />
            <FormProvider {...formMethods}>
              <form onSubmit={handleSubmit}>
                <Flex w="full" mb="20px">
                  <Box mr="22px" w="23.8%">
                    <SelectPadrao
                      label="Tipo impressora"
                      required
                      options={TipoImpressoraEnum.properties.map(
                        (impressora) => {
                          return (
                            {
                              label: impressora.label,
                              value: impressora.value,
                            } || []
                          );
                        }
                      )}
                      isDisabled={isReadonly}
                      placeholder="Digite o tipo da sua impressora"
                      name="tipoImpressora"
                    />
                  </Box>
                  <Flex w="49%">
                    <Box w="82%" mr="25px">
                      <Input
                        label="Nome"
                        isRequired
                        maxLength={100}
                        isDisabled={isReadonly}
                        borderRadius="md"
                        placeholder="Digite o nome da sua etiqueta"
                        name="nomeEtiqueta"
                      />
                    </Box>
                    <Box>
                      <CheckboxAtivoInativo
                        id="etiquetaAtiva"
                        name="etiquetaAtiva"
                        label="Status"
                        defaultChecked={false}
                        isDisabled={isReadonly}
                        isRequired
                      />
                    </Box>
                  </Flex>
                </Flex>
                <Grid templateColumns="repeat(4, 1fr)" gap={6}>
                  <NumberInput
                    id="comprimentoEtiqueta"
                    name="comprimentoEtiqueta"
                    label="Comprimento"
                    max={isLargerThan1309 ? 14 : 20}
                    scale={2}
                    isDisabled={isReadonly}
                    colorScheme="secondary.300"
                    color="black"
                  />

                  <NumberInput
                    id="alturaEtiqueta"
                    name="alturaEtiqueta"
                    label="Altura"
                    scale={2}
                    isDisabled={isReadonly}
                    colorScheme="secondary.300"
                    color="black"
                  />

                  <NumberInput
                    id="margemSuperior"
                    name="margemSuperior"
                    label="Margem superior"
                    scale={2}
                    isDisabled={isReadonly}
                    colorScheme="secondary.300"
                    color="black"
                  />

                  <NumberInput
                    id="margemEsquerda"
                    name="margemEsquerda"
                    label="Margem esquerda"
                    scale={2}
                    isDisabled={isReadonly}
                    colorScheme="secondary.300"
                    color="black"
                  />
                  <NumberInput
                    id="numeroLinhas"
                    name="numeroLinhas"
                    label="Linhas"
                    scale={0}
                    isDisabled={isReadonly}
                    colorScheme="secondary.300"
                    color="black"
                  />

                  <NumberInput
                    id="espacoLinhas"
                    name="espacoLinhas"
                    label="Espaço entre linhas"
                    scale={2}
                    isDisabled={isReadonly}
                    colorScheme="secondary.300"
                    color="black"
                  />

                  <NumberInput
                    id="numeroColunas"
                    name="numeroColunas"
                    label="Colunas"
                    scale={0}
                    isDisabled={isReadonly}
                    colorScheme="secondary.300"
                    color="black"
                  />

                  <NumberInput
                    id="espacoColunas"
                    name="espacoColunas"
                    label="Espaço entre colunas"
                    scale={2}
                    isDisabled={isReadonly}
                    colorScheme="secondary.300"
                    color="black"
                  />
                </Grid>
              </form>

              <Flex mt="30px" direction={isLargerThan1370 ? 'column' : 'row'}>
                <Box
                  padding="10px"
                  mr="28px"
                  borderRadius="md"
                  bg="gray.50"
                  border="1px"
                  h={isLargerThan1370 ? '210px' : '370px'}
                  borderColor="gray.100"
                  w={isLargerThan1370 ? 'full' : '31.9%'}
                >
                  <Text fontSize="md">Composição da etiqueta</Text>
                  <Flex w="full" justifyContent="center" alignItems="center">
                    <Divider w="full" />
                  </Flex>
                  <Grid
                    mt="10px"
                    templateColumns={
                      isLargerThan1370 ? 'repeat(5, 1fr)' : 'repeat(2, 1fr)'
                    }
                  >
                    {informacoesEtiquetaPersonalizada.propriedadesItensEtiqueta.map(
                      (item, index) => (
                        <Flex
                          mt="10px"
                          justifyContent="left"
                          alignItems="center"
                        >
                          <Checkbox
                            mb="0"
                            onChange={() =>
                              handleToggleAdicionarDraggable(index)
                            }
                            isDisabled={isReadonly}
                            bg="white"
                            boxShadow="inset #00000029 0px 3px 6px"
                            colorScheme="primary"
                            isChecked={item.isChecked}
                          />
                          <Text ml="10px" fontSize="14px">
                            {item.name}
                          </Text>
                        </Flex>
                      )
                    )}
                  </Grid>
                </Box>
                <Flex mt={isLargerThan1370 ? '30px' : '0'} w="full">
                  <Flex
                    mr="28px"
                    borderRadius="md"
                    bg="gray.50"
                    border="1px"
                    h="370px"
                    borderColor="gray.100"
                    padding="20px"
                    w={isLargerThan1370 ? '74%' : '66%'}
                  >
                    <Box
                      height={alturaEtiqueta === 2.54 ? 96.1 : valorAlturaCm}
                      w={
                        comprimentoEtiqueta === 2.54 ? 96.1 : valorComprimentoCm
                      }
                      bg="white"
                      border="1px"
                      position="relative"
                      borderColor="gray.100"
                      borderRadius="10px"
                    >
                      {informacoesEtiquetaPersonalizada?.propriedadesItensEtiqueta.map(
                        (item) => {
                          if (!item.isChecked) {
                            return null;
                          }
                          return (
                            <Draggable
                              onStop={(_, data) => obterEixosDraggable(data)}
                              defaultPosition={{
                                x: item.eixoX,
                                y: item.eixoY,
                              }}
                              nodeRef={nodeRef}
                              disabled={isReadonly}
                              bounds="parent"
                              onStart={() => {
                                if (
                                  enumTipoEtiqueta.PRECO_2.value ||
                                  enumTipoEtiqueta.PARCELAMENTO.value
                                ) {
                                  getTabelaPrecoValues();
                                }
                                informacoesItensSelecionadosEtiqueta(
                                  item.id,
                                  item.name
                                );
                              }}
                            >
                              <Box position="absolute">
                                <RenderizarValoresDraggable item={item} />
                              </Box>
                            </Draggable>
                          );
                        }
                      )}
                    </Box>
                  </Flex>

                  {obterIdPropriedades ===
                    enumTipoEtiqueta.PARCELAMENTO.value && (
                    <Box
                      mb="10px"
                      padding="10px"
                      borderRadius="md"
                      bg="gray.50"
                      mr="28px"
                      border="1px"
                      borderColor="gray.100"
                      h="340px"
                    >
                      <Text fontSize="md">Escolha o preço</Text>

                      <Flex
                        w="full"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Divider w="full" />
                      </Flex>
                      <RadioGroup
                        mt="10px"
                        onChange={(precoEscolhido) =>
                          setPrecoSelecionado(precoEscolhido)
                        }
                        value={precoSelecionado}
                      >
                        <Radio value={enumTipoEtiqueta.PRECO.name}>Preço</Radio>
                        <Radio value={enumTipoEtiqueta.PRECO_2.name}>
                          Preço com tabela
                        </Radio>
                      </RadioGroup>

                      {precoSelecionado === enumTipoEtiqueta.PRECO_2.name && (
                        <Box mt="15px">
                          <SelectPadrao
                            label="Tabela de preço"
                            id="tipoTabelaPrecoId"
                            defaultValue={tabelaPreco[0]}
                            isDisabled={isReadonly}
                            placeholder="Tabela de preço"
                            name="tipoTabelaPrecoId"
                            options={tabelaPreco}
                          />
                        </Box>
                      )}

                      <Box mt="15px">
                        <NumberInput
                          id="quantidadeParcela"
                          name="quantidadeParcela"
                          label="Parcelas"
                          scale={0}
                          isDisabled={isReadonly}
                          colorScheme="secondary.300"
                          color="black"
                        />
                      </Box>
                    </Box>
                  )}

                  {obterIdPropriedades ===
                    enumTipoEtiqueta.CODIGO_BARRAS.value && (
                    <Box
                      mb="10px"
                      padding="10px"
                      borderRadius="md"
                      bg="gray.50"
                      mr="28px"
                      w={isLargerThan1370 ? '23.7%' : '31.9%'}
                      border="1px"
                      borderColor="gray.100"
                      h="340px"
                    >
                      <Text fontSize="md">Escolha o tamanho do código</Text>

                      <Flex
                        w="full"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Divider w="full" />
                      </Flex>
                      <RadioGroup
                        mt="10px"
                        onChange={(codigoEscolhido) =>
                          setCodigoBarrasSelecionado(codigoEscolhido)
                        }
                        value={codigoBarrasSelecionado}
                      >
                        <VStack
                          mt="10px"
                          justifyContent="left"
                          alignItems="left"
                        >
                          <Radio value={enumTipoEtiqueta.CODIGO_BARRAS.name}>
                            Padrão
                          </Radio>
                          <Radio
                            value={enumTipoEtiqueta.CODIGO_BARRAS_REDUZIDO.name}
                          >
                            Reduzido
                          </Radio>
                        </VStack>
                      </RadioGroup>
                    </Box>
                  )}

                  {itemSelecionadoEdicao && itemSelecionadoFiltradoEstaCheck && (
                    <Box h="full" w={isLargerThan1370 ? '23.7%' : '31.9%'}>
                      <Box
                        mb="10px"
                        padding="10px"
                        borderRadius="md"
                        bg="gray.50"
                        border="1px"
                        borderColor="gray.100"
                        w="full"
                        h="340px"
                      >
                        <Text fontSize="md">Edição da letra</Text>

                        <Text fontWeight="bold" fontSize="md">
                          Você está editando:{' '}
                          {obterNomeItemSelecionadoEdicao || ''}
                        </Text>
                        <Flex
                          w="full"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Divider w="full" />
                        </Flex>
                        <Flex
                          mt="15px"
                          justifyContent="left"
                          alignItems="center"
                        >
                          <Checkbox
                            isDisabled={isReadonly}
                            onChange={() => {
                              handleItalico();
                            }}
                            bg="white"
                            boxShadow="inset #00000029 0px 3px 6px"
                            colorScheme="primary"
                            isChecked={checkedItalico}
                            mb="0"
                          />
                          <Text fontSize="14px" ml="10px">
                            Italico
                          </Text>
                        </Flex>
                        <Flex
                          mt="15px"
                          justifyContent="left"
                          alignItems="center"
                        >
                          <Checkbox
                            isDisabled={isReadonly}
                            onChange={() => {
                              handleNegrito();
                            }}
                            bg="white"
                            colorScheme="primary"
                            boxShadow="inset #00000029 0px 3px 6px"
                            isChecked={checkedNegrito}
                            mb="0"
                          />
                          <Text fontSize="14px" ml="10px">
                            Negrito
                          </Text>
                        </Flex>
                        <Flex
                          mt="15px"
                          justifyContent="left"
                          alignItems="center"
                        >
                          <Checkbox
                            isDisabled={isReadonly}
                            onChange={() => {
                              handleSublinhado();
                            }}
                            colorScheme="primary"
                            bg="white"
                            boxShadow="inset #00000029 0px 3px 6px"
                            isChecked={checkedSublinhado}
                            mb="0"
                          />
                          <Text fontSize="14px" ml="10px">
                            Sublinhado
                          </Text>
                        </Flex>
                        <Box mt="15px">
                          <SelectPadrao
                            label="Escolha o tamanho"
                            id="alterarTamanhoFonte"
                            isDisabled={isReadonly}
                            placeholder="Selecione uma situação"
                            name="alterarTamanhoFonte"
                            options={getOptionsByEnum(EtiquetaTamanhoLetra)}
                          />
                        </Box>
                        {obterIdPropriedades ===
                          enumTipoEtiqueta.PRECO_2.value && (
                          <Box mt="15px">
                            <SelectPadrao
                              label="Tabela de preço"
                              id="tabelaPrecoId"
                              defaultValue={tabelaPreco[0]}
                              isDisabled={isReadonly}
                              placeholder="Selecione tabela de preço"
                              name="tabelaPrecoId"
                              options={tabelaPreco}
                            />
                          </Box>
                        )}

                        {obterNomeItemSelecionadoEdicao === 'Descrição' && (
                          <Box mt="10px">
                            <NumberInput
                              label="Qtd caracteres"
                              id="quantidadeCaracteres"
                              scale={0}
                              isDisabled={isReadonly}
                              placeholder="Informe a quantidade de caracteres"
                              name="quantidadeCaracteres"
                            />
                          </Box>
                        )}
                        {obterIdPropriedades ===
                          enumTipoEtiqueta.TEXTO_FIXO.value && (
                          <Box mt="20px">
                            <Input
                              isDisabled={isReadonly}
                              id="textoFixo"
                              placeholder="Adicione um texto fixo"
                              name="textoFixo"
                            />
                          </Box>
                        )}
                        {obterIdPropriedades ===
                          enumTipoEtiqueta.TEXTO_FIXO_2.value && (
                          <Box mt="20px">
                            <Input
                              isDisabled={isReadonly}
                              id="textoFixo2"
                              placeholder="Adicione um texto fixo"
                              name="textoFixo2"
                            />
                          </Box>
                        )}
                      </Box>
                    </Box>
                  )}
                  {obterNomeItemSelecionadoEdicao === 'Logo' &&
                    informacoesEtiquetaPersonalizada.propriedadesItensEtiqueta[
                      enumTipoEtiqueta.LOGO.value
                    ].isChecked && (
                      <Box h="full" w={isLargerThan1370 ? '23.7%' : '31.9%'}>
                        <Box
                          mb="10px"
                          padding="10px"
                          borderRadius="md"
                          bg="gray.50"
                          border="1px"
                          borderColor="gray.100"
                          w="full"
                          h="340px"
                        >
                          <Text fontSize="md">Escolha o tipo do logo</Text>

                          <Flex
                            w="full"
                            mt="6px"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Divider w="full" />
                          </Flex>
                          <RadioGroup
                            onChange={(valueLogoSelecionado) =>
                              handleEscolhendoTipoLogo(valueLogoSelecionado)
                            }
                            value={logoSelecionado}
                          >
                            <VStack
                              mt="10px"
                              justifyContent="left"
                              alignItems="left"
                            >
                              <Radio color="primary.50" value="LOGO">
                                Logo quadrado
                              </Radio>
                              <Radio value="LOGO_RETANGULAR">
                                Logo retangular
                              </Radio>
                            </VStack>
                          </RadioGroup>
                        </Box>
                      </Box>
                    )}
                </Flex>
              </Flex>
            </FormProvider>
          </Box>
          <Flex
            mt={isLargerThan1370 ? '20px' : '30px'}
            justifyContent="center"
            alignItems="center"
            w="full"
          >
            {!isReadonly && (
              <>
                {!isAlterar && (
                  <Button
                    leftIcon={<Icon as={SalvarInserirNovoIcon} fontSize="md" />}
                    ml="20px"
                    variant="outline"
                    borderRadius="5px"
                    isDisabled={isReadonly}
                    onClick={onSubmitReset}
                  >
                    Salvar e inserir novo
                  </Button>
                )}
              </>
            )}

            {!isReadonly && (
              <Button
                leftIcon={<Icon as={SalvarConfirmarIcon} fontSize="md" />}
                ml="20px"
                borderRadius="5px"
                w="180px"
                colorScheme="aquamarine"
                fontWeight="normal"
                isDisabled={isReadonly}
                onClick={handleSubmit}
              >
                Salvar
              </Button>
            )}
          </Flex>
        </>
      ) : (
        <Box bg="white" padding="20px">
          <Text color="primary.50" fontSize="16px">
            Resolução não compatível
          </Text>
          <Text>
            Para cadastrar uma etiqueta ou fazer alguma alteração acesse esta
            tela através de um dispositivo com resolução mínima de 900x1030
            pixels.
          </Text>
        </Box>
      )}
    </>
  );
}
