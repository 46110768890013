import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

type LocalEstoque = {
  id: string;
  nome: string;
  ativo: boolean;
  dataHoraCadastro: string;
  dataHoraUltimaAlteracao: string;
};

export async function obterLocalEstoque(id: string) {
  const response = await api.get<void, ResponseApi<LocalEstoque | undefined>>(
    ConstanteEnderecoWebservice.LOCAL_ESTOQUE_OBTER,
    {
      params: { id },
    }
  );

  if (response) {
    if (response.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (response.sucesso && response.dados) {
      return response.dados;
    }
  }

  return undefined;
}
