import { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Text,
  Box,
  Flex,
  VStack,
  Button,
  Image,
  Stack,
  useMediaQuery,
} from '@chakra-ui/react';

import ConstanteRotas from 'constants/rotas';
import { metasConferenciaImgs } from 'constants/enum/enumsImgSistemas';
import { getImageBySystem } from 'helpers/layout/getImageBySystem';
import auth from 'modules/auth';
import ConstanteFuncionalidades from 'constants/permissoes';
import { useMetasComissoesCadastroContext } from 'store/MetasComissoes/MetaComissoesCadastro';
import { obterMetaGeral } from 'helpers/data/getMetaGeral';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { MetasComissoesPossuemCadastro } from './metasComissoesPossuemCadastro';

export const MetasComissoes = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [existeEtapaCadastrada, setExisteEtapaCadastrada] = useState(false);
  const [possuiPermissao, setPossuiPermissao] = useState(true);

  const history = useHistory();
  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const {
    setValorMetaGeral,
    setUtilizaMetaGeral,
  } = useMetasComissoesCadastroContext();

  const handleConfigurarMetas = () => {
    history.push(ConstanteRotas.CADASTRAR_METAS_E_COMISSOES);
  };

  const possuiPermissaoMetaComissao = auth.possuiPermissao(
    ConstanteFuncionalidades.METAS_COMISSAO
  ).permitido;

  const obterValorMetaGeral = useCallback(async () => {
    setIsLoading(true);

    const response = await obterMetaGeral();

    if (response?.id) {
      setExisteEtapaCadastrada(true);
      setIsLoading(false);
      setUtilizaMetaGeral(response.utilizaMetaGeral);
      setValorMetaGeral(response.valorMetaGeral);
      return true;
    }

    setIsLoading(false);

    return false;
  }, [setUtilizaMetaGeral, setValorMetaGeral]);

  useEffect(() => {
    async function existeMetas() {
      const existeMetaCadastrada = await obterValorMetaGeral();

      if (!possuiPermissaoMetaComissao && existeMetaCadastrada === false) {
        setPossuiPermissao(false);
      }
    }
    existeMetas();
  }, [history, obterValorMetaGeral, possuiPermissaoMetaComissao]);

  return (
    <>
      {isLoading && <LoadingPadrao />}
      {existeEtapaCadastrada ? (
        <>
          <MetasComissoesPossuemCadastro />
        </>
      ) : (
        <Box
          px={{ base: 4, md: 10, lg: 16, xl: 24 }}
          py={{ base: 6, md: 8, lg: 20, xl: 24 }}
          bg="white"
          boxShadow="xl"
          borderRadius="md"
          position="relative"
        >
          <Stack
            alignItems="center"
            direction={{ base: 'column', md: 'row' }}
            justifyContent="space-evenly"
          >
            <VStack
              spacing={{ base: 4, lg: 6 }}
              pl={{
                base: '0',
                md: '0',
                lg: '30px',
                xl: '30px',
              }}
              alignItems={isLargerThan900 ? 'flex-start' : 'center'}
            >
              <Text
                lineHeight="30px"
                fontSize="32px"
                fontWeight="bold"
                color="primary.50"
              >
                Metas e performance de vendas
              </Text>
              <Text
                fontWeight="regular"
                fontSize="sm"
                w={{
                  base: 'full',
                  md: '390px',
                  lg: '505px',
                  xl: '790px',
                }}
              >
                Metas são essenciais para impulsionar e avaliar o desempenho dos
                vendedores e gestores. Desenvolver uma meta geral e uma meta
                individual é essencial para que os membros da equipe entendam o
                seu papel nos resultados, compreendam seus objetivos e se
                esforcem para alcançá-los.
              </Text>
              <Text
                fontWeight="regular"
                fontSize="sm"
                w={{
                  base: 'full',
                  md: '390px',
                  lg: '505px',
                  xl: '790px',
                }}
              >
                Para facilitar sua vida, elaboramos um formato guiado com apenas
                5 etapas para construir e configurar o seu programa de metas. Ao
                final, você terá todos os dados em um lindo dashboard e cada
                vendedor poderá acompanhar apenas as suas próprias conquistas.
                Vamos começar?
              </Text>

              <Button
                h="48px"
                w={isLargerThan700 ? '204px' : 'full'}
                color="white"
                variant="solid"
                bg="purple.500"
                _hover={{
                  background: 'purple.500',
                }}
                isDisabled={!possuiPermissao}
                onClick={() => handleConfigurarMetas()}
              >
                Iniciar configuração
              </Button>
            </VStack>
            {isLargerThan700 && (
              <Flex
                alignItems="center"
                justifyContent={{ base: 'center', md: 'flex-end' }}
                pt={{ base: 10, md: 0 }}
                px={{ base: 10, md: 0 }}
                h="full"
                w="full"
              >
                <Image
                  src={getImageBySystem(metasConferenciaImgs)}
                  alt="Cadastrar metas e comissão"
                  maxH={{ base: '350px', lg: '350px', xl: 'full' }}
                />
              </Flex>
            )}
          </Stack>
        </Box>
      )}
    </>
  );
};
