const ConstanteFuncionalidades = {
  VENDEDOR_CADASTRAR: 'vendedor_cadastrar',
  VENDEDOR_ALTERAR: 'vendedor_alterar',
  VENDEDOR_EXCLUIR: 'vendedor_excluir',
  VENDEDOR_LISTAR: 'vendedor_listar',
  VENDEDOR_VISUALIZAR: 'vendedor_visualizar',

  VENDASPEDIDOS_LISTAR: 'vendaspedidos_listar',
  VENDASPEDIDOS_VISUALIZAR: 'vendaspedidos_visualizar',
  LISTAR_VOUCHERS: 'voucher_listar',
  ORCAMENTO_LISTAR: 'orcamento_listar',
  ORCAMENTO_VISUALIZAR: 'orcamento_visualizar',
  CONSIGNACAO_LISTAR: 'consignacao_listar',
  CONSIGNACAO_VISUALIZAR: 'consignacao_visualizar',
  PDV_LANCAR_CONSIGNACAO: 'pdv_lancar_consignacao',
  PDV_VISUALIZAR_INFORMACOES_CLIENTE: 'pdv_visualizar_informacoes_cliente',

  CATEGORIA_CLIENTE_CADASTRAR: 'categoriacliente_cadastrar',
  CATEGORIA_CLIENTE_ALTERAR: 'categoriacliente_alterar',
  CATEGORIA_CLIENTE_EXCLUIR: 'categoriacliente_excluir',
  CATEGORIA_CLIENTE_LISTAR: 'categoriacliente_listar',
  CATEGORIA_CLIENTE_VISUALIZAR: 'categoriacliente_visualizar',

  PROMOCAO_CONFIGURAR: 'promocao_configurar',

  CAMPO_PERSONALIZADO_CADASTRAR: 'campopersonalizado_cadastrar',
  CAMPO_PERSONALIZADO_ALTERAR: 'campopersonalizado_alterar',
  CAMPO_PERSONALIZADO_EXCLUIR: 'campopersonalizado_excluir',
  CAMPO_PERSONALIZADO_LISTAR: 'campopersonalizado_listar',
  CAMPO_PERSONALIZADO_VISUALIZAR: 'campopersonalizado_visualizar',

  TRANSPORTADORA_CADASTRAR: 'transportadora_cadastrar',
  TRANSPORTADORA_ALTERAR: 'transportadora_alterar',
  TRANSPORTADORA_EXCLUIR: 'transportadora_excluir',
  TRANSPORTADORA_LISTAR: 'transportadora_listar',
  TRANSPORTADORA_VISUALIZAR: 'transportadora_visualizar',

  PRODUTO_CADASTRAR: 'produto_cadastrar',
  PRODUTO_CADASTRAR_TAG: 'produto_cadastrar_tag',
  PRODUTO_ALTERAR: 'produto_alterar',
  PRODUTO_EXCLUIR: 'produto_excluir',
  PRODUTO_LISTAR: 'produto_listar',
  PRODUTO_VISUALIZAR: 'produto_listar',
  PRODUTO_ALTERAR_PRECO_NA_VENDA: 'produto_alterar_preco_na_venda',

  MARCA_CADASTRAR: 'marca_cadastrar',
  MARCA_ALTERAR: 'marca_alterar',
  MARCA_EXCLUIR: 'marca_excluir',
  MARCA_LISTAR: 'marca_listar',
  MARCA_VISUALIZAR: 'marca_visualizar',

  CONTA_FINANCEIRA_CADASTRAR: 'contafinanceira_cadastrar',
  CONTA_FINANCEIRA_ALTERAR: 'contafinanceira_alterar',
  CONTA_FINANCEIRA_EXCLUIR: 'contafinanceira_excluir',
  CONTA_FINANCEIRA_LISTAR: 'contafinanceira_listar',
  CONTA_FINANCEIRA_VISUALIZAR: 'contafinanceira_visualizar',

  IMPORTACAO_CLIENTE_LISTAR: 'importacaocliente_listar',
  IMPORTACAO_CLIENTE_IMPORTAR: 'importacaocliente_importar',

  IMPORTACAO_FORNECEDOR_LISTAR: 'importacaofornecedor_listar',
  IMPORTACAO_FORNECEDOR_IMPORTAR: 'importacaofornecedor_importar',

  IMPORTACAO_PRODUTO_LISTAR: 'importacaoproduto_listar',
  IMPORTACAO_PRODUTO_IMPORTAR: 'importacaoproduto_importar',

  PLANO_CONTA_CADASTRAR: 'planoconta_cadastrar',
  PLANO_CONTA_ALTERAR: 'planoconta_alterar',
  PLANO_CONTA_EXCLUIR: 'planoconta_excluir',
  PLANO_CONTA_LISTAR: 'planoconta_listar',

  ETIQUETAS_PERSONALIZADAS_LISTAR: 'personalizaretiqueta_listar',
  ETIQUETAS_PERSONALIZADAS_CADASTRAR: 'personalizaretiqueta_cadastrar',
  ETIQUETAS_PERSONALIZADAS_EXCLUIR: 'personalizaretiqueta_excluir',
  ETIQUETAS_PERSONALIZADAS_ALTERAR: 'personalizaretiqueta_alterar',
  ETIQUETAS_PERSONALIZADAS_VISUALIZAR: 'personalizaretiqueta_visualizar',

  FORMA_PAGTO_CADASTRAR: 'formapagamento_cadastrar',
  FORMA_PAGTO_ALTERAR: 'formapagamento_alterar',
  FORMA_PAGTO_EXCLUIR: 'formapagamento_excluir',
  FORMA_PAGTO_LISTAR: 'formapagamento_listar',
  FORMA_PAGTO_VISUALIZAR: 'formapagamento_visualizar',

  FORMA_RECEB_CADASTRAR: 'formarecebimento_cadastrar',
  FORMA_RECEB_ALTERAR: 'formarecebimento_alterar',
  FORMA_RECEB_EXCLUIR: 'formarecebimento_excluir',
  FORMA_RECEB_LISTAR: 'formarecebimento_listar',
  FORMA_RECEB_VISUALIZAR: 'formarecebimento_visualizar',

  COR_CADASTRAR: 'cor_cadastrar',
  COR_ALTERAR: 'cor_alterar',
  COR_EXCLUIR: 'cor_excluir',
  COR_LISTAR: 'cor_listar',
  COR_VISUALIZAR: 'cor_visualizar',

  TAMANHO_CADASTRAR: 'tamanho_cadastrar',
  TAMANHO_ALTERAR: 'tamanho_alterar',
  TAMANHO_EXCLUIR: 'tamanho_excluir',
  TAMANHO_LISTAR: 'tamanho_listar',
  TAMANHO_VISUALIZAR: 'tamanho_visualizar',

  UNIDADE_MEDIDA_CADASTRAR: 'unidademedida_cadastrar',
  UNIDADE_MEDIDA_ALTERAR: 'unidademedida_alterar',
  UNIDADE_MEDIDA_EXCLUIR: 'unidademedida_excluir',
  UNIDADE_MEDIDA_LISTAR: 'unidademedida_listar',
  UNIDADE_MEDIDA_VISUALIZAR: 'unidademedida_visualizar',

  HISTORICO_ACOES_LISTAR: 'historicoacao_listar',
  HISTORICO_ACOES_VISUALIZAR: 'historicoacao_visualizar',

  CATEGORIA_PRODUTO_CADASTRAR: 'categoriaproduto_cadastrar',
  CATEGORIA_PRODUTO_ALTERAR: 'categoriaproduto_alterar',
  CATEGORIA_PRODUTO_EXCLUIR: 'categoriaproduto_excluir',
  CATEGORIA_PRODUTO_LISTAR: 'categoriaproduto_listar',

  CLIENTE_CADASTRAR: {
    codigo: 'cliente_cadastrar',
    titulo: 'Desbloquear Cadastro de Cliente',
    descricao:
      'Para cadastrar um cliente, insira os dados de um usuário com permissão.',
  },

  CLIENTE_ALTERAR: {
    codigo: 'cliente_alterar',
    titulo: 'Desbloquear Alteração de Cliente',
    descricao:
      'Para alterar um cliente, insira os dados de um usuário com permissão.',
  },
  CLIENTE_EXCLUIR: 'cliente_excluir',
  CLIENTE_LISTAR: 'cliente_listar',
  CLIENTE_VISUALIZAR: 'cliente_visualizar',

  FORNECEDOR_CADASTRAR: 'fornecedor_cadastrar',
  FORNECEDOR_ALTERAR: 'fornecedor_alterar',
  FORNECEDOR_EXCLUIR: 'fornecedor_excluir',
  FORNECEDOR_LISTAR: 'fornecedor_listar',
  FORNECEDOR_VISUALIZAR: 'fornecedor_visualizar',

  NOTA_FISCAL_EMITIR: 'notafiscal_emitir',

  MANIFESTACAO_DESTINATARIO_VISUALIZAR: 'manifestodestinatario_visualizar',

  PERFIL_USUARIO_CADASTRAR: 'perfilusuario_cadastrar',
  PERFIL_USUARIO_ALTERAR: 'perfilusuario_alterar',
  PERFIL_USUARIO_EXCLUIR: 'perfilusuario_excluir',
  PERFIL_USUARIO_LISTAR: 'perfilusuario_listar',
  PERFIL_USUARIO_VISUALIZAR: 'perfilusuario_visualizar',

  USUARIO_CADASTRAR: 'usuario_cadastrar',
  USUARIO_ALTERAR: 'usuario_alterar',
  USUARIO_ALTERAR_LOJAS: 'usuario_alterar_lojas',
  USUARIO_ALTERAR_PERMISSOES: 'usuario_alterar_permissoes',
  USUARIO_EXCLUIR: 'usuario_excluir',
  USUARIO_LISTAR: 'usuario_listar',
  USUARIO_VISUALIZAR: 'usuario_visualizar',
  USUARIO_VISUALIZAR_PRECO_CUSTO: 'usuario_visualizar_custo_produtos',

  LOJA_CADASTRAR: 'loja_cadastrar',
  LOJA_ALTERAR: 'loja_alterar',
  LOJA_EXCLUIR: 'loja_excluir',
  LOJA_LISTAR: 'loja_listar',
  LOJA_VISUALIZAR: 'loja_visualizar',

  PADRONIZACAO_ALTERAR: 'padronizacao_alterar',

  PDV_LIBERAR_CLIENTE_BLOQUEADO: {
    codigo: 'pdv_liberar_cliente_bloqueado',
    titulo: 'Desbloquear Cliente',
    descricao:
      'Para liberar o cliente exclusivamente para esta venda, insira os dados de um usuário com permissão.',
  },

  PDV_PERMISSAO_ABERTURA_CAIXA_ALTERAR_SALDO:
    'controlecaixa_alterarsaldoabertura',

  PDV_BAIXAR_CONSIGNACAO: 'pdv_baixar_consignacao',

  PDV_LIBERAR_lOCAL_ESTOQUE: {
    codigo: 'pdv_alterar_local_estoque',
    titulo: 'Alterar local de estoque',
    descricao:
      'Não é possível continuar a operação porque seu usuário não possui permissão para alterar o local de estoque. Insira os dados de um usuário com permissão para liberar a operação.',
  },

  PDV_CONCEDER_ACRESCIMOS_DESCONTOS: {
    codigo: 'pdv_conceder_acrescimos_descontos',
    titulo: 'Conceder Acréscimos e Descontos',
    descricao:
      'Para conceder acréscimos e descontos exclusivamente para este item, insira os dados de um usuário com permissão.',
  },

  PDV_EXCLUIR_OPERACAO_ITEM: {
    codigo: 'pdv_remover_item',
    titulo: 'Excluir Item da Operação',
    descricao:
      'Para excluir este item, insira os dados de um usuário com permissão.',
  },
  PDV_ALTERAR_ITEM: {
    codigo: 'pdv_alterar_item',
    titulo: 'Alterar Item da Operação',
    descricao:
      'Para conceder permissão de alterar um item exclusivamente para esta tentativa, insira os dados de um usuário com permissão.',
  },
  PDV_ALTERAR: {
    codigo: 'pdv_alterar',
    titulo: 'Alterar Operação',
    descricao:
      'Para conceder permissão de alterar uma operação exclusivamente para esta tentativa, insira os dados de um usuário com permissão.',
  },
  PDV_CANCELAR: {
    codigo: 'pdv_cancelar',
    titulo: 'Cancelar Operação',
    descricao:
      'Para conceder permissão de cancelar uma operação exclusivamente para esta tentativa, insira os dados de um usuário com permissão.',
  },
  PDV_SUPRIMENTO: 'pdv_suprimento',
  PDV_SANGRIA: 'pdv_sangria',
  PDV_REMOVER_ACRESCIMO: 'pdv_remover_acrescimo',
  PDV_ALTERAR_LOCAL_ESTOQUE: 'pdv_alterar_local_estoque',

  PDV_LANCAR_TROCA_DEVOLUCAO: 'pdv_lancar_troca_devolucao',

  LISTAR_TROCA_DEVOLUCAO: 'listar_devolucao',
  VISUALIZAR_TROCA_DEVOLUCAO: 'visualizar_devolucao',
  LANCAR_TROCA_DEVOLUCACAO: 'pdv_lancar_troca_devolucao',

  PERMISSAO_INEXISTENTE: 'permissao_inexistente',

  DOCUMENTO_FISCAL_EXPORTACAO_LISTAR: 'documentofiscalexportacao_listar',
  DASHBOARD_VISUALIZAR: 'dashboard_visualizar',

  REGRA_FISCAL_CADASTRAR: 'regrafiscal_cadastrar',
  REGRA_FISCAL_ALTERAR: 'regrafiscal_alterar',
  REGRA_FISCAL_EXCLUIR: 'regrafiscal_excluir',
  REGRA_FISCAL_LISTAR: 'regrafiscal_listar',
  REGRA_FISCAL_VISUALIZAR: 'regrafiscal_visualizar',

  LOCAL_ESTOQUE_CADASTRAR: 'localestoque_cadastrar',
  LOCAL_ESTOQUE_ALTERAR: 'localestoque_alterar',
  LOCAL_ESTOQUE_EXCLUIR: 'localestoque_excluir',
  LOCAL_ESTOQUE_LISTAR: 'localestoque_listar',
  LOCAL_ESTOQUE_VISUALIZAR: 'localestoque_visualizar',

  MOVIMENTACAO_ESTOQUE_CADASTRAR: 'movimentacaoestoque_cadastrar',
  MOVIMENTACAO_ESTOQUE_LISTAR: 'movimentacaoestoque_listar',
  MOVIMENTACAO_ESTOQUE_ALTERAR: 'movimentacaoestoque_alterar',
  MOVIMENTACAO_ESTOQUE_DUPLICAR: 'movimentacaoestoque_duplicar',
  MOVIMENTACAO_ESTOQUE_VISUALIZAR: 'movimentacaoestoque_visualizar',
  MOVIMENTACAO_ESTOQUE_CANCELAR: 'movimentacaoestoque_cancelar',

  METAS_COMISSAO: 'metacomissao_configurar',

  CONFERENCIA_ESTOQUE_INICIAR: 'conferenciaestoque_iniciar',
  CONFERENCIA_ESTOQUE_DESCARTAR: 'conferenciaestoque_descartar',
  CONFERENCIA_ESTOQUE_CONTINUAR: 'conferenciaestoque_continuar',
  CONFERENCIA_ESTOQUE_APLICAR_CORRECAO: 'conferenciaestoque_aplicarcorrecao',

  TRANSFERENCIA_ESTOQUE_CADASTRAR: 'transferenciaestoque_cadastrar',
  TRANSFERENCIA_ESTOQUE_LISTAR: 'transferenciaestoque_listar',
  TRANSFERENCIA_ESTOQUE_VISUALIZAR: 'transferenciaestoque_visualizar',
  TRANSFERENCIA_ESTOQUE_ALTERAR: 'transferenciaestoque_alterar',
  TRANSFERENCIA_ESTOQUE_REJEITAR: 'transferenciaestoque_rejeitar',
  TRANSFERENCIA_ESTOQUE_DUPLICAR: 'transferenciaestoque_duplicar',
  TRANSFERENCIA_ESTOQUE_CONFIRMAR: 'transferenciaestoque_confirmar',
  TRANSFERENCIA_ESTOQUE_CANCELAR: 'transferenciaestoque_cancelar',
  TRANSFERENCIA_ESTOQUE_TRANSFERIR_ENTRE_LOJAS:
    'transferenciaestoque_transferir_entre_lojas',
  TRANSFERENCIA_ESTOQUE_TRANSFERIR_ENTRE_ESTOQUES:
    'transferenciaestoque_transferir_entre_estoques',

  TABELA_PRECO_LISTAR: 'tabelapreco_listar',
  TABELA_PRECO_VISUALIZAR: 'tabelapreco_visualizar',
  TABELA_PRECO_CADASTRAR: 'tabelapreco_cadastrar',
  TABELA_PRECO_EXCLUIR: 'tabelapreco_excluir',
  TABELA_PRECO_ALTERAR: 'tabelapreco_alterar',
  TABELA_PRECO_ALTERAR_PDV: 'pdv_alterar_tabela_preco',

  HISTORICO_PRODUTO_LISTAR: 'historicoproduto_listar',
  HISTORICO_PRODUTO_VISUALIZAR: 'historicoproduto_visualizar',

  ENTRADA_MERCADORIA_CADASTRAR: 'entradamercadoria_cadastrar',
  ENTRADA_MERCADORIA_LISTAR: 'entradamercadoria_listar',
  ENTRADA_MERCADORIA_ALTERAR: 'entradamercadoria_alterar',
  ENTRADA_MERCADORIA_DUPLICAR: 'entradamercadoria_duplicar',
  ENTRADA_MERCADORIA_VISUALIZAR: 'entradamercadoria_visualizar',
  ENTRADA_MERCADORIA_CANCELAR: 'entradamercadoria_cancelar',
  ENTRADA_MERCADORIA_EXCLUIR: 'entradamercadoria_excluir',
  ENTRADA_MERCADORIA_LANCAR_ESTOQUE: 'entradamercadoria_lancarestoque',
  ENTRADA_MERCADORIA_ESTORNAR_ESTOQUE: 'entradamercadoria_estornarestoque',
  ENTRADA_MERCADORIA_LANCAR_FINANCEIRO: 'entradamercadoria_lancarfinanceiro',
  ENTRADA_MERCADORIA_ESTORNAR_FINANCEIRO:
    'entradamercadoria_estornarfinanceiro',

  CONTROLE_CAIXA_LISTAR: 'controlecaixa_listar',
  CONTROLE_CAIXA_VISUALIZAR: 'controlecaixa_visualizar',
  CONTROLE_CAIXA_ABRIR_FECHAR: 'controlecaixa_abrirfechar',
  CONTROLE_CAIXA_REABRIR: 'controlecaixa_reabrir',
  CONTROLE_CAIXA_IMPRIMIR_TODOS_CAIXAS: 'controlecaixa_imprimirtodoscaixas',
  CONTROLE_CAIXA_VER_TODOS_CAIXAS: 'controlecaixa_vertodoscaixas',

  EXTRATO_LISTAR: 'extrato_listar',
  EXTRATO_TRANSFERENCIA: 'extrato_transferencia',

  FATURA_LISTAR: 'fatura_listar',
  FATURA_VISUALIZAR: 'fatura_visualizar',

  CONTAS_PAGAR_CADASTRAR: 'contaspagar_cadastrar',
  CONTAS_PAGAR_LISTAR: 'contaspagar_listar',
  CONTAS_PAGAR_ALTERAR: 'contaspagar_alterar',
  CONTAS_PAGAR_EXCLUIR: 'contaspagar_excluir',
  CONTAS_PAGAR_BAIXA: 'contaspagar_baixa',
  CONTAS_PAGAR_CANCELARBAIXA: 'contaspagar_cancelarbaixa',

  CONTAS_RECEBER_CADASTRAR: 'contasreceber_cadastrar',
  CONTAS_RECEBER_ALTERAR: 'contasreceber_alterar',
  CONTAS_RECEBER_EXCLUIR: 'contasreceber_excluir',
  CONTAS_RECEBER_CANCELARBAIXA: 'contasreceber_cancelarbaixa',
  CONTAS_RECEBER_BAIXA: 'contasreceber_baixa',
  CONTAS_RECEBR_ZERAR_MULTAJUROS: 'contasreceber_zerarmultajuros',
  CONTAS_RECEBER_LISTAR: 'contasreceber_listar',

  RECEBIMENTO_CONTAS_LISTAR: 'recebimentoconta_listar',

  LANCAMENTO_CADASTRAR: 'lancamento_cadastrar',

  RELATORIO_PERSONALIZADO_PRODUTOS_CADASTRAR:
    'relatoriopersonalizadoproduto_cadastrar',
  RELATORIO_PERSONALIZADO_PRODUTOS_LISTAR:
    'relatoriopersonalizadoproduto_listar',
  RELATORIO_PERSONALIZADO_PRODUTOS_ALTERAR:
    'relatoriopersonalizadoproduto_alterar',
  RELATORIO_PERSONALIZADO_PRODUTOS_EXCLUIR:
    'relatoriopersonalizadoproduto_excluir',

  RELATORIO_PERSONALIZADO_CLIENTES_CADASTRAR:
    'relatoriopersonalizadocliente_cadastrar',
  RELATORIO_PERSONALIZADO_CLIENTES_LISTAR:
    'relatoriopersonalizadocliente_listar',
  RELATORIO_PERSONALIZADO_CLIENTES_ALTERAR:
    'relatoriopersonalizadocliente_alterar',
  RELATORIO_PERSONALIZADO_CLIENTES_EXCLUIR:
    'relatoriopersonalizadocliente_excluir',

  RELATORIO_INFORMACOES_CLIENTE: 'relatorio_informacoesclientes',

  RELATORIO_PRODUTO_POR_VENDA: 'relatorio_produto_por_venda',
  RELATORIO_LUCRO_POR_PRODUTO: 'relatorio_lucro_por_produto',

  RELATORIO_PRODUTOS_COM_PRECO: 'relatorio_produtoscompreco',
  RELATORIO_CATALOGO_PRODUTOS: 'relatorio_catalogoproduto',
  RELATORIO_PRODUTO_PERSONALIZADO: 'relatorio_produtopersonalizado',
  RELATORIO_INVENTARIO: 'relatorio_inventario',

  RELATORIO_ESTOQUE_SEM_PRECO: 'relatorio_estoque_sem_preco',
  RELATORIO_ESTOQUE_PRECO_VENDA: 'relatorio_estoque_preco_venda',
  RELATORIO_ESTOQUE_PRECO_CUSTO: 'relatorio_estoque_preco_custo',

  RELATORIO_CADASTRO_COMPLETO_CLIENTE: 'relatorio_cadastrocompletocliente',
  RELATORIO_CLIENTE_PERSONALIZADO: 'relatorio_clientepersonalizado',

  RELATORIO_CURVA_ABC: 'relatorio_curvaabc',

  RELATORIO_VENDAS_POR_FORMA_RECEBIMENTO:
    'relatorio_vendas_por_forma_recebimento',
  RELATORIO_VENDAS_POR_DIA: 'relatorio_vendas_por_dia',
  RELATORIO_VENDA_SIMPLIFICADAS: 'relatorio_venda_simplificadas',
  RELATORIO_VENDAS_TOTALIZADAS_VENDEDORES:
    'relatorio_vendas_totalizadas_vendedores',
  RELATORIO_VENDAS_TOTALIZADAS_PRODUTOS:
    'relatorio_vendas_totalizadas_produtos',

  MULTA_JUROS_ALTERAR: 'multajuros_alterar',

  RELATORIO_CONSIGNACAO: 'relatorio_consignacao',

  SMART_POS_HABILITAR: 'caixamovel_habilitar_dispositivo',

  VISUALIZAR_TRANSACOES_ZOOP: 'visualizartransacoes_pagamentointegrados',
  CREDENCIAR_ZOOP: 'credenciar_pagamentointegrados',

  FRENTE_CAIXA_CONFIGURAR: 'frentecaixa_configurar',
  FRENTE_CAIXA_EXCLUIR: 'frentecaixa_excluir',

  CREDENCIADORA_CARTAO_CADASTRAR: 'credenciadora_cartao_cadastrar',
  CREDENCIADORA_CARTAO_LISTAR: 'credenciadora_cartao_listar',
  CREDENCIADORA_CARTAO_ALTERAR: 'credenciadora_cartao_alterar',
  CREDENCIADORA_CARTAO_VISUALIZAR: 'credenciadora_cartao_visualizar',
  CREDENCIADORA_CARTAO_EXCLUIR: 'credenciadora_cartao_excluir',

  PDV_OFFLINE_CONFIGURAR: 'pdvoffline_configurar',
  PDV_OFFLINE_EXCLUIR: 'pdvoffline_excluir',
};

export default ConstanteFuncionalidades;
