import React from 'react';
import { HStack, StackProps, Text, TypographyProps } from '@chakra-ui/react';

import { DecimalMask } from 'helpers/format/fieldsMasks';

export interface TextValorProps extends StackProps {
  valor: number;
  casasDecimais?: number;
  symbolFontSize?: TypographyProps['fontSize'];
  symbolFontWeight?: TypographyProps['fontWeight'];
}

export const TextValor = ({
  valor,
  casasDecimais,
  symbolFontSize = '2xs',
  symbolFontWeight = 'normal',
  fontSize = '2xl',
  fontWeight = 'extrabold',
  ...rest
}: TextValorProps) => (
  <HStack
    spacing={1}
    alignItems="baseline"
    lineHeight="none"
    color="secondary.400"
    {...rest}
  >
    <Text fontSize={symbolFontSize} fontWeight={symbolFontWeight}>
      R$
    </Text>
    <Text fontSize={fontSize} fontWeight={fontWeight}>
      {DecimalMask(
        valor ?? 0,
        !casasDecimais ? 2 : casasDecimais,
        !casasDecimais ? 2 : casasDecimais
      )}
    </Text>
  </HStack>
);
