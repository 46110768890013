import { Flex, Text, VStack, Box } from '@chakra-ui/react';

import { DecimalMask } from 'helpers/format/fieldsMasks';

import { TextValor } from 'components/PDV/Text/TextValor';

interface FooterProps {
  valorOriginal: number;
  valorTotalMultasJuros: number;
  valorTotalDescontos: number;
  valorTotalRecebido: number;
  operador: string;
}

const Footer = ({
  valorOriginal,
  valorTotalRecebido,
  valorTotalDescontos,
  valorTotalMultasJuros,
  operador,
}: FooterProps) => {
  return (
    <VStack width="100%" justifyContent="flex-start">
      <Flex
        width="100%"
        justifyContent="space-between"
        borderBottom="2px solid"
        borderColor="gray.700"
        pb="12px"
        mt="12px"
      >
        <Box width="100%">
          <Text>Valor: R$ {DecimalMask(valorOriginal, 2)}</Text>
          <Text>Juros/Multa: R$ {DecimalMask(valorTotalMultasJuros, 2)}</Text>
          <Text>Descontos: R$ {DecimalMask(valorTotalDescontos, 2)}</Text>
          <Flex justifyContent="space-between" mt="6px" pt="12px">
            <Box>
              <Text>Operador(a):</Text>
              <Text>{operador} </Text>
            </Box>
            <Box>
              <Text textAlign="end">Total:</Text>
              <TextValor
                valor={valorTotalRecebido}
                casasDecimais={2}
                fontSize="2xs"
                fontWeight="black"
                symbolFontWeight="black"
                symbolFontSize="8px"
              />
            </Box>
          </Flex>
        </Box>
      </Flex>
    </VStack>
  );
};

export default Footer;
