import { toast } from 'react-toastify';

import auth from 'modules/auth';
import api, { ResponseApi } from 'services/api';
import { pushExternalUrl } from 'helpers/layout/pushExternalUrl';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import TipoSistemaEnum from 'constants/enum/tipoSistema';

const { value: sistemaValue } = auth.getSistema();

const isDevelopmentEnv = process.env.NODE_ENV === 'development';
const dominio =
  sistemaValue === TipoSistemaEnum.ZENDAR ||
  sistemaValue === TipoSistemaEnum.FOMER ||
  sistemaValue === TipoSistemaEnum.FOMER_SISTEMA
    ? 'zenflix.zendar'
    : 'videos.powerstock';

const baseURL = isDevelopmentEnv
  ? 'http://localhost:7000'
  : `https://${dominio}.app`;

export const generateAccessToken = async () => {
  const response = await api.get<void, ResponseApi<string>>(
    ConstanteEnderecoWebservice.ACCESS_TOKEN_OBTER
  );

  if (response) {
    if (response?.avisos) {
      response.avisos.forEach((aviso) => toast.warning(aviso));
    }
    if (response?.sucesso && response?.dados) {
      return response;
    }
  }

  return undefined;
};

export const pushExternalUrlTreinamentos = (
  accessToken: string,
  target = '_blank'
) => {
  const url = `${baseURL}/login/${accessToken}`;

  pushExternalUrl(url, target);
};
