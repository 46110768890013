import { Box, Button, GridItem, Icon, Text, Flex } from '@chakra-ui/react';
import { MdDragHandle } from 'react-icons/md';

import { getName } from 'helpers/enum/getName';
import enumTipoItemProdutoEtapa from 'constants/enum/enumTipoItemProdutoEtapa';

import { SalvarInserirNovoIcon } from 'icons';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { useProdutoEtapa } from './hooks';

const ProdutoEtapa = () => {
  const {
    handleRegisterStep,
    handleUpdateStep,
    handleDeleteStep,
    handleDragOver,
    handleDragStart,
    handleDrag,
    handleDragEnd,
    handleTouchStart,
    handleTouchMove,
    dragOverItemIndex,
    isDragging,
    isLoading,
    listSteps,
    readonly,
  } = useProdutoEtapa();

  return (
    <SimpleGridForm>
      <GridItem colSpan={12}>
        <Button
          type="button"
          variant="solid"
          colorScheme="blue.700"
          leftIcon={<Icon as={SalvarInserirNovoIcon} />}
          marginBottom="5px"
          maxWidth="225px"
          maxHeight="34px"
          w="100%"
          onClick={handleRegisterStep}
          fontWeight="normal"
        >
          Adicionar etapa
        </Button>
        <Box
          p={6}
          bg="gray.50"
          borderWidth="1px"
          borderColor="gray.200"
          borderRadius="5px"
          position="relative"
          overflowX="scroll"
          overflowY="hidden"
          onDrop={(e) => {
            e.preventDefault();
          }}
        >
          {isLoading && <LoadingPadrao />}
          {listSteps.length > 0 ? (
            <Box overflowX="scroll" pb="10px" px={1}>
              <Flex minW="420px" px={3} gap={3}>
                <Box w="min">
                  <MdDragHandle size="20px" color="transparent" />
                </Box>
                <Box w="full" whiteSpace="nowrap" textColor="gray.600">
                  Etapa
                </Box>
                <Box
                  w={{ base: '30%', md: '20%' }}
                  whiteSpace="nowrap"
                  textColor="gray.600"
                >
                  Itens para exibir
                </Box>
                <Box
                  w={{ base: '30%', md: '15%' }}
                  textAlign="right"
                  whiteSpace="nowrap"
                  textColor="gray.600"
                >
                  Quantidade
                </Box>
                <Box
                  w={{ base: '10%', md: '5%' }}
                  textAlign="center"
                  whiteSpace="nowrap"
                  textColor="gray.600"
                >
                  Ações
                </Box>
              </Flex>
              <Flex
                w="full"
                minW="420px"
                flexDir="column"
                gap="5px"
                pointerEvents={readonly ? 'none' : 'unset'}
              >
                {listSteps?.map((step, index) => {
                  const isDraggingOverItem =
                    isDragging && dragOverItemIndex === index;

                  return (
                    <Flex
                      key={step.id}
                      draggable
                      h="50px"
                      w="full"
                      px={3}
                      gap={3}
                      align="center"
                      cursor="grab"
                      borderWidth={2}
                      transitionProperty="background-color, border-color"
                      transitionDuration=".2s"
                      transitionTimingFunction="linear"
                      borderRadius="md"
                      borderStyle="dashed"
                      backgroundColor={
                        isDraggingOverItem ? '#ECE9FF !important' : 'white'
                      }
                      borderColor={
                        isDraggingOverItem ? 'purple.500' : 'transparent'
                      }
                      boxShadow={
                        isDraggingOverItem
                          ? 'none !important'
                          : '0px 0px 4px #00000029'
                      }
                      sx={{
                        '& > div': {
                          opacity: isDraggingOverItem ? 0.5 : 1,
                        },
                      }}
                      _hover={{
                        boxShadow: isDragging
                          ? '0px 0px 4px #00000029 !important'
                          : '0px 0px 4px rgba(85, 2, 178, 0.5)',
                      }}
                      onDragStart={handleDragStart}
                      onDrag={handleDrag}
                      onDragEnter={(event) => handleDragOver(event, index)}
                      onDragEnd={(event) => handleDragEnd(event, index)}
                      onTouchStart={handleTouchStart}
                      onTouchMove={(event) => handleTouchMove(event, index)}
                      onTouchEnd={(event) => handleDragEnd(event, index)}
                    >
                      <Box w="min">
                        <MdDragHandle size="20px" color="#303030" />
                      </Box>
                      <Box w="full">
                        <Text
                          width="min"
                          cursor="pointer"
                          whiteSpace="nowrap"
                          onClick={() => handleUpdateStep(step.id)}
                          _hover={{ textDecor: 'underline' }}
                        >
                          {step.categoria}
                        </Text>
                      </Box>
                      <Box w={{ base: '30%', md: '20%' }}>
                        {getName(enumTipoItemProdutoEtapa, step.tipoEtapa)}
                      </Box>
                      <Box w={{ base: '30%', md: '15%' }} textAlign="right">
                        {step.quantidade}
                      </Box>
                      <Flex w={{ base: '10%', md: '5%' }} justify="center">
                        <ActionsMenu
                          isDisabled={readonly}
                          items={[
                            {
                              content: 'Editar',
                              onClick: () => handleUpdateStep(step.id),
                            },
                            {
                              content: 'Remover',
                              onClick: () => handleDeleteStep(step.id),
                            },
                          ]}
                        />
                      </Flex>
                    </Flex>
                  );
                })}
              </Flex>
            </Box>
          ) : (
            <Box bg="white" p={3} mt={6}>
              Nenhum resultado
            </Box>
          )}
        </Box>
      </GridItem>
    </SimpleGridForm>
  );
};

export { ProdutoEtapa };
