const TipoCertificadoEnum = {
  A1: '0',
  A3: '1',

  properties: {
    0: { name: 'A1', value: '0' },
    1: { name: 'A3', value: '1' },
  },
};

export default TipoCertificadoEnum;
