import { useCallback, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { Button, Text, GridItem, Flex, Box } from '@chakra-ui/react';

import enumTipoImpressaoGerenciador from 'constants/enum/enumTipoImpressaoGerenciador';

import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { useManagerConfig } from './hooks';
import { PrintManagerProps } from '../../types';

type ManagerConfigProps = {
  printManagerList: PrintManagerProps[];
};

const ManagerConfig = ({ printManagerList }: ManagerConfigProps) => {
  const {
    formMethods,
    isLoading,
    isLargerThan700,
    updatePrintManagerConfig,
    printManagerConfigList,
  } = useManagerConfig();

  const { setValue, handleSubmit } = formMethods;

  const setManagerFields = useCallback(async () => {
    printManagerConfigList.forEach(
      ({ tipoImpressao, gerenciadorImpressao: { id, nome } }) => {
        const newOption = {
          label: nome,
          value: id,
          tipoImpressao,
        };

        setValue(`gerenciador.${tipoImpressao}`, newOption);
      }
    );
  }, [printManagerConfigList, setValue]);

  useEffect(() => {
    setManagerFields();
  }, [setManagerFields]);

  return (
    <FormProvider {...formMethods}>
      {isLoading && <LoadingPadrao />}
      <Box
        p={4}
        h="max"
        w="full"
        maxH="500px"
        overflowY="auto"
        borderWidth="1px"
        borderColor="gray.200"
        borderRadius="base"
      >
        <SimpleGridForm gap={2}>
          {isLargerThan700 && (
            <GridItem colSpan={12}>
              <SimpleGridForm>
                <GridItem colSpan={6}>
                  <Text>Tipo impressão</Text>
                </GridItem>
                <GridItem colSpan={6}>
                  <Text>Gerenciador</Text>
                </GridItem>
              </SimpleGridForm>
            </GridItem>
          )}
          {enumTipoImpressaoGerenciador.properties.map((printType) => {
            const newOptions = printManagerList.map(({ gerenciador }) => ({
              label: gerenciador.nome,
              value: gerenciador.id,
              tipoImpressao: printType.value,
            }));

            return (
              <GridItem
                colSpan={12}
                bg="white"
                py={2}
                px={4}
                borderRadius="md"
                borderWidth="1px"
                borderColor="gray.200"
              >
                <SimpleGridForm gap={{ base: 1 }}>
                  <GridItem
                    colSpan={{ base: 12, sm: 6 }}
                    display="flex"
                    alignItems="center"
                  >
                    <Text color="black" fontSize={{ base: 'sm', sm: 'md' }}>
                      {printType.name}
                    </Text>
                  </GridItem>
                  <GridItem colSpan={{ base: 12, sm: 6 }}>
                    <SelectPadrao
                      id={`gerenciador.${printType.value}`}
                      name={`gerenciador.${printType.value}`}
                      placeholder="Selecione"
                      options={newOptions}
                      isClearable
                      asControlledByObject
                    />
                  </GridItem>
                </SimpleGridForm>
              </GridItem>
            );
          })}
        </SimpleGridForm>
      </Box>
      <Flex justifyContent="center" flexWrap="wrap" gap={6} py="40px">
        <Button
          w={{ base: 'full', sm: 'min-content' }}
          minW="120px"
          colorScheme="secondary"
          onClick={handleSubmit(updatePrintManagerConfig)}
          isDisabled={isLoading}
        >
          Salvar
        </Button>
      </Flex>
    </FormProvider>
  );
};

export default ManagerConfig;
