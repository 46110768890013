import { FormProvider, useForm } from 'react-hook-form';
import React, { useCallback, useEffect, useState } from 'react';
import {
  ModalContent,
  ModalHeader,
  ModalProps as ModalChakraProps,
  useDisclosure,
  Text,
  VStack,
  ModalBody,
  ModalFooter,
  Button,
  GridItem,
  useMediaQuery,
  FormLabel,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { create, InstanceProps } from 'react-modal-promise';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { InputNumber } from 'components/update/Input/InputNumber';

import enumTipoItemProdutoEtapa from 'constants/enum/enumTipoItemProdutoEtapa';
import SelectField from 'components/PDV/Select/SelectPadrao';

import {
  FormData,
  yupResolver,
  defaultValues,
  CategoryProps,
  ProductProps,
} from './validationForm';
import ListProductsVirtualized from '../../listProductsVirtualized';

type StepItemProps = {
  etapaProdutoId: string;
  produtoItem: {
    id: string;
    nome: string;
    ativo: boolean;
    nomeEcommerce: string;
  };
};

type StepResponseProps = {
  id: string;
  produto: ProductProps;
  categoria: CategoryProps;
  tipoEtapa: number;
  quantidade: number;
  sequenciaOrdenacao: number;
  itens: StepItemProps[];
};

type ModalProps = {
  title?: string;
  stepId: string;
  productId: string;
  callback: (data: FormData) => Promise<{ success: boolean }>;
};

type ModalEtapaAlterarProps = Omit<
  ModalChakraProps,
  'isOpen' | 'onClose' | 'children'
> &
  InstanceProps<void> &
  ModalProps;

const ModalEtapaAlterar = create<ModalEtapaAlterarProps>(
  ({ title = 'Alterar etapa', stepId, productId, callback, ...rest }) => {
    const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const formMethods = useForm<FormData>({
      resolver: yupResolver,
      defaultValues: {
        ...defaultValues,
      },
    });

    const { handleSubmit, reset } = formMethods;

    const [isLoading, setIsLoading] = useState(false);
    const [listProducts, setListProducts] = useState<ProductProps[]>([]);
    const [step, setStep] = useState<StepResponseProps | null>(null);

    const getListProducts = useCallback(
      async (categoryId: string, items: StepItemProps[]) => {
        const response = await api.get<void, ResponseApi<ProductProps[]>>(
          ConstanteEnderecoWebservice.PRODUTO_ETAPA_PRODUTOS_CATEGORIA_OBTER.replace(
            'id',
            categoryId
          )
        );

        if (response) {
          if (response?.avisos) {
            response.avisos.forEach((aviso) => toast.warning(aviso));
          }

          if (response?.sucesso && response?.dados) {
            const newProducts = response.dados.map((product) => ({
              ...product,
              isSelected: items.some(
                (item) => item.produtoItem.id === product.id
              ),
            }));

            setListProducts(newProducts);
            return;
          }
        }

        setListProducts([]);
      },
      []
    );

    const getStepItem = useCallback(async () => {
      setIsLoading(true);

      const response = await api.get<void, ResponseApi<StepResponseProps>>(
        `${ConstanteEnderecoWebservice.PRODUTO_ETAPA.replace(
          'id',
          productId
        )}/${stepId}`
      );

      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }
        if (response?.sucesso && response?.dados) {
          const { categoria, quantidade, itens, tipoEtapa } = response.dados;
          const isProductType = tipoEtapa === enumTipoItemProdutoEtapa.PRODUTO;

          if (isProductType) {
            await getListProducts(categoria.id, itens);
          }

          setStep(response.dados);
          reset({
            quantidade,
            categoriaProdutoId: categoria.id || '',
            tipoEtapa,
          });
        }
      }

      setIsLoading(false);
    }, [getListProducts, productId, reset, stepId]);

    const updateStep = useCallback(
      async (data: FormData) => {
        const isProductType =
          data.tipoEtapa === enumTipoItemProdutoEtapa.PRODUTO;

        const itens = isProductType
          ? listProducts
              .filter(({ isSelected }) => isSelected)
              .map(({ id }) => id)
          : [];

        const { success } = await callback({
          ...data,
          sequenciaOrdenacao: step?.sequenciaOrdenacao || 0,
          itens,
        });

        return { success };
      },
      [callback, listProducts, step]
    );

    const handleConfirm = async (data: FormData) => {
      setIsLoading(true);

      const { success } = await updateStep(data);

      if (success) {
        onClose();
      }

      setIsLoading(false);
    };

    const handleSetListProducts = (products: ProductProps[]) => {
      setListProducts(products);
    };

    useEffect(() => {
      getStepItem();
    }, [getStepItem]);

    return (
      <ModalPadraoChakra
        isCentered={!isSmallerThan900}
        size={!isSmallerThan900 ? '5xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalContent px={{ base: '8px', md: '16px' }} bg="gray.50">
          {isLoading && <LoadingPadrao />}
          <ModalHeader pt={{ base: '12px', md: '24px' }}>
            <VStack alignItems="flex-start" spacing="1" lineHeight="1">
              <Text color="primary.500" mt={{ base: 4, md: 0 }}>
                {title}
              </Text>
            </VStack>
          </ModalHeader>
          <ModalBody p={{ base: 6, md: 8 }}>
            <FormProvider {...formMethods}>
              <SimpleGridForm w="full" gap={{ base: 6, md: 8 }}>
                <GridItem colSpan={{ base: 12, lg: 6 }}>
                  <SelectField
                    id="categoriaProdutoId"
                    name="categoriaProdutoId"
                    label="Categoria"
                    placeholder="Selecione uma categoria"
                    options={[
                      {
                        label: step?.categoria.nome || '',
                        value: step?.categoria.id || '',
                      },
                    ]}
                    isDisabled
                  />
                </GridItem>
                <GridItem colSpan={{ base: 12, md: 6, lg: 3 }}>
                  <SelectField
                    id="tipoEtapa"
                    name="tipoEtapa"
                    label="Itens que serão exibidos"
                    placeholder="Selecione"
                    options={enumTipoItemProdutoEtapa.options}
                    isDisabled
                  />
                </GridItem>
                <GridItem colSpan={{ base: 12, md: 6, lg: 3 }}>
                  <InputNumber
                    id="quantidade"
                    name="quantidade"
                    label="Quantidade de itens"
                    scale={0}
                    max={999}
                    isDisabled={isLoading}
                  />
                </GridItem>
                {listProducts?.length > 0 && (
                  <GridItem colSpan={12}>
                    <FormLabel>Produtos</FormLabel>
                    <ListProductsVirtualized
                      px={4}
                      listProducts={listProducts}
                      setListProducts={handleSetListProducts}
                    />
                  </GridItem>
                )}
              </SimpleGridForm>
            </FormProvider>
          </ModalBody>
          <ModalFooter
            justifyContent="center"
            flexWrap="wrap"
            gap={6}
            py="40px"
          >
            <Button
              w={{ base: 'full', sm: 'min-content' }}
              minW="160px"
              colorScheme="gray"
              variant="outlineDefault"
              onClick={onClose}
            >
              Cancelar
            </Button>
            <Button
              w={{ base: 'full', sm: 'min-content' }}
              minW="225px"
              colorScheme="secondary"
              onClick={handleSubmit(handleConfirm)}
              isDisabled={isLoading}
            >
              Confirmar
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);

export default ModalEtapaAlterar;
