export type TipoSistema = 1 | 2;

export type ListaItensSistema = {
  icone: string;
  manifest: string;
  name: string;
  nomeExibicao: string;
  theme: string;
  value: number;
};

const TipoSistemaEnum = {
  ZENDAR: 1,
  POWERSTOCK: 2,
  FOMER: 3,
  FOMER_SISTEMA: 4,
  POWERCHEF: 5,

  properties: {
    1: {
      name: 'zendar',
      nomeExibicao: 'Zendar',
      icone:
        'https://zendarappprod.blob.core.windows.net/icones/zendar/favicon.ico',
      theme: '#29004F',
      manifest: `${process.env.PUBLIC_URL}/manifest.json`,
      value: 1,
    },
    2: {
      name: 'powerstock',
      nomeExibicao: 'Powerstock',
      icone:
        'https://zendarappprod.blob.core.windows.net/icones/powerstock/favicon.svg',
      theme: '#00193E',
      manifest: `${process.env.PUBLIC_URL}/manifestPowerstock.json`,
      value: 2,
    },
    3: {
      name: 'fomersistema',
      nomeExibicao: 'Fomer',
      icone:
        'https://zendarappprod.blob.core.windows.net/icones/fomer/favicon.ico',
      theme: '#C30047',
      manifest: `${process.env.PUBLIC_URL}/manifestFomer.json`,
      value: 3,
    },
    4: {
      name: 'fomer',
      nomeExibicao: 'Fomer',
      icone:
        'https://zendarappprod.blob.core.windows.net/icones/fomer/favicon.ico',
      theme: '#C30047',
      manifest: `${process.env.PUBLIC_URL}/manifestFomer.json`,
      value: 4,
    },
    5: {
      name: 'powerchef',
      nomeExibicao: 'Powerstock',
      icone:
        'https://zendarappprod.blob.core.windows.net/icones/powerstock/favicon.svg',
      theme: '#00193E',
      manifest: `${process.env.PUBLIC_URL}/manifestPowerstock.json`,
      value: 5,
    },
  },
};

export default TipoSistemaEnum;
