import {
  ModalDesistencia,
  ModalDesistenciaProps,
} from 'components/Modal/ModalDesistencia';

const ModalDesistenciaOrdenacaoMenu = ({
  callback,
  ...rest
}: ModalDesistenciaProps) => {
  return ModalDesistencia({
    title: 'Sair sem salvar?',
    textoMensagem: (
      <span>
        Você alterou o conteúdo mas não clicou no botão salvar.{' '}
        <b>
          Para confirmar as alterações, cancele este aviso e clique no botão
          &quot;Salvar&quot;.
        </b>{' '}
        Se preferir, você pode sair sem salvar.
      </span>
    ),
    labelButtonConfirmar: 'Sair sem salvar',
    labelButtonCancelar: 'Cancelar',
    colorButtonConfirm: 'black',
    callback,
    ...rest,
  });
};

export { ModalDesistenciaOrdenacaoMenu };
