import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useCallback, useEffect, useState } from 'react';

import api, { ResponseApi } from 'services/api';
import MeioPagamentoEnum from 'constants/enum/fiscal/meioPagamento';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import {
  Option,
  FormasRecebimentoResponse,
  formDefaultValues,
  FormData,
  HookBuscaAvancadaModalProps,
} from './validationForms';

export const useBuscaAvancadaModal = ({
  onResolve,
  clearFilters,
  currentFilters,
}: HookBuscaAvancadaModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [listFormaRecebimento, setListFormaRecebimento] = useState<Option[]>(
    []
  );
  const formMethods = useForm({
    defaultValues: currentFilters,
  });
  const { handleSubmit, reset } = formMethods;

  const handlePesquisar = handleSubmit((data: FormData) => {
    onResolve(data);
  });

  const onClear = () => {
    reset(formDefaultValues);
    clearFilters();
  };

  const getFormasRecebimento = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get<
      void,
      ResponseApi<FormasRecebimentoResponse[]>
    >(ConstanteEnderecoWebservice.FORMA_RECEBIMENTO_LISTAR_SELECT_RECEBIMENTO);
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }
      if (response.sucesso && response.dados) {
        const newList = response.dados
          .filter(
            (formaRecebimento) =>
              formaRecebimento.regraMeioPagamento !== MeioPagamentoEnum.Dinheiro
          )
          .map((item) => ({
            value: item.id,
            label: item.nome,
          }));
        setListFormaRecebimento(newList);
      }
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getFormasRecebimento();
  }, [getFormasRecebimento]);

  return {
    formMethods,
    handlePesquisar,
    onClear,
    listFormaRecebimento,
    isLoading,
  };
};
