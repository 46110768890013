import { useHistory } from 'react-router-dom';
import {
  Text,
  Flex,
  Button,
  Box,
  Icon as IconChakra,
  List,
  ListItem,
} from '@chakra-ui/react';

import ConstanteRotas from 'constants/rotas';
import { formatDate } from 'helpers/format/formatStringDate';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { IconType, LogoutIcon } from 'icons';

type ContainerProps = {
  date?: Date;
  icon: IconType;
  widthIcon?: number;
  heightIcon?: number;
  isIntegrationCancel?: boolean;
  listItemsInfo: ListItemsInfoProps;
  handleCancel?: () => void;
  handleActive?: () => void;
  isLoading?: boolean;
  status?: string;
};

export type ListItemsInfoProps = string[];

export const Container = ({
  date,
  icon,
  widthIcon = 30,
  heightIcon = 30,
  isLoading = false,
  isIntegrationCancel = true,
  listItemsInfo,
  handleCancel,
  handleActive,
  status,
}: ContainerProps) => {
  const history = useHistory();

  const handleBack = () => {
    history.push(ConstanteRotas.LOJA_APLICATIVOS);
  };

  const showButtonActive = handleActive && !isIntegrationCancel;
  const showButtonCancel = handleCancel && isIntegrationCancel;

  return (
    <>
      {isLoading ? (
        <LoadingPadrao />
      ) : (
        <>
          <Flex justifyContent="space-between" alignItems="center">
            {date && isIntegrationCancel ? (
              <Text fontSize="16px" color="gray.700">
                Esta integração já está ativa no seu plano desde{' '}
                {formatDate(date)}
              </Text>
            ) : (
              <Text fontSize="16px" color="gray.700">
                Esta integração ainda não está ativa no momento.
              </Text>
            )}

            {!showButtonActive && showButtonCancel ? (
              <Button
                fontSize="14px"
                leftIcon={<LogoutIcon />}
                variant="linkDefault"
                onClick={handleCancel}
                colorScheme="red.500"
              >
                Cancelar integração
              </Button>
            ) : (
              showButtonActive && (
                <Button
                  fontSize="14px"
                  leftIcon={<LogoutIcon />}
                  variant="solid"
                  onClick={handleActive}
                  colorScheme="aquamarine.500"
                >
                  Ativar integração
                </Button>
              )
            )}
          </Flex>
          <Box>
            <IconChakra
              width={`${widthIcon}px`}
              height={`${heightIcon}px`}
              as={icon}
            />
          </Box>
          <Box mt="10px">
            <Text fontSize="16px" fontWeight="semibold">
              Informações:
            </Text>
            <List mt="10px" fontSize="14px" spacing={2}>
              {status && (
                <ListItem>
                  -{' '}
                  <Text as="span" fontWeight="bold">
                    {status}
                  </Text>
                </ListItem>
              )}
              {listItemsInfo.map((itemInformation) => (
                <ListItem>- {itemInformation}</ListItem>
              ))}
            </List>
          </Box>
          <Box mt="41px">
            <Button
              onClick={handleBack}
              w="160px"
              colorScheme="purple.500"
              variant="solid"
            >
              Voltar
            </Button>
          </Box>
        </>
      )}
    </>
  );
};
