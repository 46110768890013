import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
  ModalContent,
  ModalBody,
  Flex,
  Text,
  ScaleFade,
  ModalProps,
  useMediaQuery,
  HStack,
  GridItem,
  Button,
  ModalFooter,
  Icon,
  Box,
  IconButton,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { FiChevronLeft } from 'react-icons/fi';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider } from 'react-hook-form';
import { useReactToPrint } from 'react-to-print';

import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import TipoFormaPagamentoRecebimentoEnum from 'constants/enum/tipoFormaPagamentoRecebimento';
import auth from 'modules/auth';
import api, { ResponseApi } from 'services/api';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import ManterFoco from 'components/Geral/ManterFoco';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { NumberInput } from 'components/update/Input/NumberInput';
import Textarea from 'components/PDV/Textarea';
import { ModalCompartilhar } from 'components/Modal/ModalCompartilhar';
import ImpressaoSangriaSuprimento80mm from 'components/Impressao/impressaoSangriaSuprimento80mm/ImpressaoSangriaSuprimento80mm';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { useForm, yupResolver } from './validationForm';

type CaixaMovimentacaoProps = {
  id: string;
  usuarioAberturaId: string;
  usuarioAberturaNome: string;
};

type ContaFinanceiraProps = {
  id: string;
  nome: string;
  tipoContaFinanceira: number;
  caixaMovimentacao: CaixaMovimentacaoProps;
};

interface ConteudoImpressao {
  loja: string;
  forma: string;
  caixa: string;
  destinoOuOrigem: string;
  usuario: string;
  observacao: string;
  valor: number;
  tipoOperacao: number;
  dataCadastro: Date;
}

interface ModalSuprimentoSangriaProps
  extends InstanceProps<FormData>,
    Omit<ModalProps, 'children' | 'isOpen' | 'onClose'> {
  identificacaoTipoOperacaoEnum: number;
  contaFinanceira?: ContaFinanceiraProps;
  callback?: (value: boolean) => Promise<void>;
}

const ModalSuprimentoSangriaConst = ({
  identificacaoTipoOperacaoEnum,
  contaFinanceira,
  callback,
}: ModalSuprimentoSangriaProps) => {
  const formMethods = useForm({
    resolver: yupResolver,
  });

  const loja = auth.getLoja();
  const usuario = auth.getUsuario();

  const [isOpen, setIsOpen] = useState(true);
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const [isLoading, setIsLoading] = useState(false);
  const [contasFinanceiras, setContasFinanceiras] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const [conteudoImpressao, setConteudoImpressao] = useState<ConteudoImpressao>(
    {} as ConteudoImpressao
  );

  const [formaPagamentoRecebimento, setFormaPagamentoRecebimento] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  const sangriaSuprimentoComponentToPrintContainerRef = useRef<HTMLDivElement>(
    null
  );

  const imprimirSangriaSuprimento = useReactToPrint({
    content: () => sangriaSuprimentoComponentToPrintContainerRef.current,
  });

  const handleImprimirCupomSangriaSuprimento = useCallback(async () => {
    setIsLoading(true);

    if (imprimirSangriaSuprimento) {
      imprimirSangriaSuprimento();
    }

    setIsLoading(false);
  }, [imprimirSangriaSuprimento]);

  const compartilharCupomSangriaSuprimento = useCallback(async () => {
    ModalCompartilhar({
      items: [
        {
          titulo: 'Cupom 80mm',
          onClickImpressao: (onClose) => {
            handleImprimirCupomSangriaSuprimento();
            onClose();
            setIsOpen(false);
          },
        },
      ],
      onCloseCallback: () => {
        setIsOpen(false);
      },
    });
  }, [handleImprimirCupomSangriaSuprimento]);

  const suprimento =
    identificacaoTipoOperacaoEnum ===
    IdentificacaoTipoOperacaoEnum.TRANSFERENCIA_DINHEIRO_ENTRADA;
  const sangria =
    identificacaoTipoOperacaoEnum ===
    IdentificacaoTipoOperacaoEnum.TRANSFERENCIA_DINHEIRO_SAIDA;

  const getContasFinanceirasMenosCaixaAtual = useCallback(async () => {
    const response = await api.get<
      void,
      ResponseApi<{ id: string; nome: string }[]>
    >(
      ConstanteEnderecoWebservice.CONTA_FINANCEIRA_LISTAR_CONTAS_FINANCEIRAS_MENOS_CAIXAS
    );

    if (response?.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
      return;
    }

    setContasFinanceiras(
      response.dados.map((item) => ({ label: item.nome, value: item.id }))
    );

    if (response.dados.length === 1) {
      formMethods.setValue('contaFinanceiraId', response.dados[0].id);
    }
  }, [formMethods]);

  const getFormaPagamentoRecebimento = useCallback(async () => {
    const response = await api.get<
      void,
      ResponseApi<{ id: string; nome: string }[]>
    >(
      ConstanteEnderecoWebservice.FORMA_PAGAMENTO_RECEBIMENTO_LISTAR_FORMAS_RECEBIMENTO_FISICAS,
      {
        params: {
          tipoFormaPagamentoRecebimento: suprimento
            ? TipoFormaPagamentoRecebimentoEnum.Recebimento
            : TipoFormaPagamentoRecebimentoEnum.Pagamento,
        },
      }
    );

    if (response?.avisos) {
      response.avisos.forEach((item: string) => toast.warning(item));
      return;
    }

    setFormaPagamentoRecebimento(
      response.dados.map((item) => ({ label: item.nome, value: item.id }))
    );

    if (response.dados.length === 1) {
      formMethods.setValue('formaPagamentoRecebimentoId', response.dados[0].id);
    }
  }, [formMethods, suprimento]);

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    setIsLoading(true);
    const { valor, observacao } = data;
    if (valor === 0) {
      toast.warning('O valor não pode estar zerado.');
      setIsLoading(false);
      return;
    }

    const dataApi = {
      valor,
      observacao: observacao || '',
      formaPagamentoRecebimentoId: data?.formaPagamentoRecebimentoId?.value,
      identificacaoTipoOperacao: identificacaoTipoOperacaoEnum,
      contaFinanceiraId: data?.contaFinanceiraId?.value,
    };

    const response = await api.post<void, ResponseApi>(
      suprimento
        ? ConstanteEnderecoWebservice.SUPRIMENTOSANGRIA_CADASTRAR_SUPRIMENTO
        : ConstanteEnderecoWebservice.SUPRIMENTOSANGRIA_CADASTRAR_SANGRIA,
      {
        ...dataApi,
      }
    );

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
        setIsLoading(false);
        return;
      }

      if (response.sucesso) {
        toast.success('Operação realizada com sucesso.');
        setConteudoImpressao({
          loja: loja.fantasia || '',
          tipoOperacao: identificacaoTipoOperacaoEnum,
          caixa: contaFinanceira?.nome || '',
          dataCadastro: new Date(),
          forma: data?.formaPagamentoRecebimentoId?.label || '',
          destinoOuOrigem: data?.contaFinanceiraId?.label || '',
          usuario: usuario.nome,
          observacao: observacao || '',
          valor,
        });
        compartilharCupomSangriaSuprimento();
      }
    }

    setIsLoading(false);
  });

  useEffect(() => {
    getContasFinanceirasMenosCaixaAtual();
    getFormaPagamentoRecebimento();
  }, [getContasFinanceirasMenosCaixaAtual, getFormaPagamentoRecebimento]);

  return (
    <ModalPadraoChakra
      onClose={() => {
        setIsOpen(false);
        if (callback) {
          callback(false);
        }
      }}
      isOpen={isOpen}
      isCentered
      size={isLargerThan900 ? 'xl' : 'full'}
      autoFocus={false}
    >
      {isLoading && <LoadingPadrao />}
      <ModalContent
        marginBottom={{ base: 0, sm: '3.75rem' }}
        marginTop={{ base: 0, sm: '3.75rem' }}
        h={{ base: 'full', md: '451px' }}
        w={{ base: 'full', md: '550px' }}
        bg="gray.50"
      >
        <ManterFoco
          style={{
            width: '100%',
          }}
        >
          <ScaleFade initialScale={1.5} in={isOpen}>
            <FormProvider {...formMethods}>
              <ModalBody p={0} h="100%" borderRadius="md">
                <Flex flexDirection="column" align="stretch" h="100%">
                  {isLargerThan900 ? (
                    <Flex
                      px={{ base: '24px', md: '40px' }}
                      py={{ base: '40px', md: '24px' }}
                      h={{ base: '77px', md: '67px' }}
                    >
                      <Text
                        color={suprimento ? 'aquamarine.700' : 'red.500'}
                        fontSize={{ base: '18px', md: '20px' }}
                      >
                        {suprimento ? 'Suprimento' : 'Sangria / Retirada'}
                      </Text>
                    </Flex>
                  ) : (
                    <Flex
                      h="56px"
                      alignItems="center"
                      justifyContent="space-between"
                      pl="6"
                      pr="6"
                    >
                      <IconButton
                        aria-label="Voltar"
                        icon={<Icon as={FiChevronLeft} boxSize="6" />}
                        variant="link"
                        color="gray.700"
                        onClick={() => {
                          setIsOpen(false);
                          if (callback) {
                            callback(false);
                          }
                        }}
                        minW="8"
                      />

                      <Text
                        color={suprimento ? 'aquamarine.700' : 'red.500'}
                        fontSize="sm"
                        mr="2"
                      >
                        {suprimento ? 'Suprimento' : 'Sangria / Retirada'}
                      </Text>
                    </Flex>
                  )}

                  <Flex
                    justifyContent="center"
                    bg={suprimento ? 'aquamarine.700' : 'red.500'}
                    px={8}
                    py={7}
                    h="88px"
                  >
                    <Flex
                      sx={{
                        '.chakra-form__error-message': {
                          color: 'white',
                        },
                        '[aria-invalid=true]': {
                          borderColor: 'white !important',
                          boxShadow: '0px 1px 0px 0px white !important',
                        },
                      }}
                    >
                      <NumberInput
                        id="valor"
                        name="valor"
                        scale={2}
                        max={999999999}
                        w="250px"
                        leftElement="R$"
                        leftElementColor={
                          suprimento ? 'secondary.300' : 'white'
                        }
                        size="md"
                        variant="flushed"
                        textAlign="right"
                        fontSize="28px"
                        required
                        autoFocus
                        _placeholder={{
                          color: 'gray.200',
                        }}
                        color={suprimento ? 'secondary.300' : 'white'}
                        _focus={{
                          borderColor: suprimento ? 'secondary.300' : 'white',
                        }}
                      />
                    </Flex>
                  </Flex>

                  <SimpleGridForm
                    px={{ base: '24px', md: '40px' }}
                    h={{ base: 'full', md: '192px' }}
                    pt="28px"
                    gridRowGap="5"
                    gridGap="0"
                    columnGap="6"
                  >
                    <GridItem
                      colSpan={{ base: 12, md: 6 }}
                      sx={{
                        '& .chakra-form__label': {
                          color: 'gray.300',
                        },
                      }}
                    >
                      <SelectPadrao
                        id="contaFinanceiraId"
                        name="contaFinanceiraId"
                        options={contasFinanceiras}
                        asControlledByObject
                        label={suprimento ? 'Origem' : 'Destino'}
                        placeholder="Selecionar"
                        size="md"
                        isSearchable={false}
                      />
                    </GridItem>

                    <GridItem
                      colSpan={{ base: 12, md: 6 }}
                      sx={{
                        '& .chakra-form__label': {
                          color: 'gray.300',
                        },
                      }}
                    >
                      <SelectPadrao
                        id="formaPagamentoRecebimentoId"
                        name="formaPagamentoRecebimentoId"
                        asControlledByObject
                        options={formaPagamentoRecebimento}
                        label="Forma"
                        placeholder="Selecionar"
                        size="md"
                        isSearchable={false}
                      />
                    </GridItem>
                    <GridItem
                      colSpan={12}
                      sx={{
                        '& .chakra-form__label': {
                          color: 'gray.300',
                        },
                      }}
                    >
                      <Textarea
                        id="observacao"
                        maxHeight="100px"
                        h="90px"
                        minHeight="10px"
                        name="observacao"
                        label="Observação"
                        placeholder="Informe o motivo"
                        maxLength={255}
                        bg="white"
                      />
                    </GridItem>
                  </SimpleGridForm>
                </Flex>
              </ModalBody>
              <ModalFooter py={8} justifyContent="center">
                <HStack spacing={6}>
                  {isLargerThan900 && (
                    <Button
                      variant="outline"
                      size="sm"
                      color="gray.400"
                      onClick={() => setIsOpen(false)}
                      w="96px"
                    >
                      Cancelar
                    </Button>
                  )}
                  <Button
                    variant="solid"
                    colorScheme="secondary"
                    isLoading={isLoading}
                    size={isLargerThan900 ? 'sm' : 'lg'}
                    w={isLargerThan900 ? '96px' : '110px'}
                    onClick={handleSubmit}
                  >
                    Confirmar
                  </Button>
                </HStack>
              </ModalFooter>
            </FormProvider>
          </ScaleFade>
        </ManterFoco>
      </ModalContent>
      {conteudoImpressao && (sangria || suprimento) && (
        <Box display="none">
          <ImpressaoSangriaSuprimento80mm
            containerRef={sangriaSuprimentoComponentToPrintContainerRef}
            conteudoImpressao={conteudoImpressao}
          />
        </Box>
      )}
    </ModalPadraoChakra>
  );
};

export const ModalSuprimentoSangria = create<ModalSuprimentoSangriaProps>(
  ModalSuprimentoSangriaConst
);
