export const PeriodoEnum = {
  HOJE: 1,
  ULTIMOS_SETE_DIAS: 7,
  ULTIMOS_QUINZE_DIAS: 15,

  properties: {
    1: { name: 'Hoje', value: 1 },
    7: { name: 'Últimos 7 dias', value: 7 },
    15: { name: 'Últimos 15 dias', value: 15 },
    30: { name: 'Últimos 30 dias', value: 30 },
    60: { name: 'Últimos 60 dias', value: 60 },
    90: { name: 'Últimos 90 dias', value: 90 },
  },
};
