const ConstanteMensagemValidacao = {
  CAMPO_OBRIGATORIO: 'O campo é obrigatório',

  EMAIL_INVALIDO: 'O e-mail informado é inválido',

  CAMPO_SEM_ESPACO: 'O campo não pode possuir espaços.',

  SENHA_MINIMO_SEIS_CARACTERES: 'A senha deve possuir pelo menos 6 caracteres',

  SENHA_UM_CARACTER_NUMERICO:
    'A senha deve possuir pelo menos um caracter numérico',

  SENHA_UMA_LETRA_MAIUSCULA:
    'A senha deve possuir pelo menos uma letra maiúscula',

  SENHA_UMA_LETRA_MINUSCULA:
    'A senha deve possuir pelo menos uma letra minúscula',

  SENHA_UM_CARACTER_ESPECIAL:
    'A senha deve possuir pelo menos um caracter especial',

  SENHA_NAO_COINCIDEM: 'As senhas não coincidem',

  TELEFONE_INVALIDO: 'O telefone informado é inválido',

  DATA_INVALIDA: 'A data informada é inválida',

  DATA_ATUAL: 'A data informada não pode ser maior que a data atual.',

  IDADE_MINIMA_14: 'A idade mínima aceita é 14 anos',
  IDADE_MAXIMA_110: 'A idade máxima aceita é 110 anos',

  MIN_VALUE_1: 'O valor do campo deve ser maior ou igual a 1',

  DATA_LANCAMENTO_VENCIMENTO:
    'A data de emissão não pode ultrapassar o vencimento',

  MAX_LENGTH_1: 'Este campo não aceita mais que 1 caractere',
  MAX_LENGTH_5: 'Este campo não aceita mais que 5 caracteres',
  MAX_LENGTH_9: 'Este campo não aceita mais que 9 caracteres',
  MAX_LENGTH_10: 'Este campo não aceita mais que 10 caracteres',
  MAX_LENGTH_14: 'Este campo não aceita mais que 14 caracteres',
  MAX_LENGTH_18: 'Este campo não aceita mais que 18 caracteres',
  MAX_LENGTH_20: 'Este campo não aceita mais que 20 caracteres',
  MAX_LENGTH_21: 'Este campo não aceita mais que 21 caracteres',
  MAX_LENGTH_30: 'Este campo não aceita mais que 30 caracteres',
  MAX_LENGTH_40: 'Este campo não aceita mais que 40 caracteres',
  MAX_LENGTH_50: 'Este campo não aceita mais que 50 caracteres',
  MAX_LENGTH_60: 'Este campo não aceita mais que 60 caracteres',
  MAX_LENGTH_100: 'Este campo não aceita mais que 100 caracteres',
  MAX_LENGTH_120: 'Este campo não aceita mais que 120 caracteres',
  MAX_LENGTH_200: 'Este campo não aceita mais que 200 caracteres',
  MAX_LENGTH_255: 'Este campo não aceita mais que 255 caracteres',
  MAX_LENGTH_500: 'Este campo não aceita mais que 500 caracteres',

  IDENTIFICADOR_VALE: 'Código identificador inválido',
  MIN_LENGTH_15: 'Este campo não aceita menor que 15 caracteres',

  MIN_LENGTH_44: 'Este campo precisa ter 44 caracteres',

  DIAS_MAIOR_QUE_ZERO: 'A quantidade de dias deve ser maior que 0',

  CPF_INVALIDO: 'O CPF informado é inválido',
  CNPJ_ERRO_CONSULTA:
    'Não foi possível consultar o CNPJ, tente novamente em alguns instantes',
  CNPJ_INVALIDO: 'O CNPJ informado é inválido',

  CEP_INVALIDO: 'O CEP informado é inválido',
  CEP_ERRO_CONSULTA:
    'Não foi possível consultar o CEP, tente novamente em alguns instantes',

  VALOR_MINIMO_1: 'Valor mínimo permitido é 1',
  VALOR_MINIMO_2: 'Valor mínimo permitido é 2',
  VALOR_MAXIMO_999: 'Valor máximo permitido é 999',
  VALOR_MAXIMO_9999: 'Valor máximo permitido é 9999',
  VALOR_MINIMO_CADASTRO_KIT: 'Valor mín permitido é 0,01',

  VALOR_MAIOR_QUE_0: 'O valor deve ser maior que 0',

  GTIN_INVALIDO: 'O GTIN/EAN informado é inválido',

  DESCONTO_ITEM_MAIOR_QUE_TOTAL:
    'O desconto do item não pode ser maior que o seu valor total.',

  TAMANHO_BENEFICIO_FISCAL:
    'O Código do Benefício Fiscal deve conter 8 ou 10 caracteres.',

  UM_DOS_FILTROS_OBRIGATORIO: 'Um dos filtros deve ser informado',
  DIA_DENTRO_MES: 'O dia informado deve ser entre 1 e 28.',

  CAMPO_COM_CARACTERE_ESPECIAL:
    'Este campo não pode conter caracteres especiais ou números',
};

export default ConstanteMensagemValidacao;
