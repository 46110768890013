import {
  Accordion,
  AccordionPanel,
  Flex,
  Icon,
  Button,
  Text,
  Box,
  Divider,
} from '@chakra-ui/react';
import { Controller, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { FiInfo } from 'react-icons/fi';
import { useCallback } from 'react';

import { IoMdArrowDownRight, OutrasDespesasIcon } from 'icons';
import { ActionsMenu } from 'components/update/Table/ActionsMenu';

import Item from '../Item';
import { PrintersDepartmentProps } from '../../types';
import { useGerenciadorImpressao } from '../../hooks';

type DepartamentsProps = {
  listPrintersDepartments: PrintersDepartmentProps[];
  openModalNewPrintDepartaments: () => void;
  openModalUpdateDepartaments: (id: string) => void;
  removePrintDepartment: (id: string) => Promise<boolean>;
  getListPrintersDepartments: () => Promise<void>;
  hasPrinterDepartament: boolean;
};

const Departments = ({
  listPrintersDepartments,
  openModalNewPrintDepartaments,
  openModalUpdateDepartaments,
  removePrintDepartment,
  getListPrintersDepartments,
  hasPrinterDepartament,
}: DepartamentsProps) => {
  const { formMethods, isLargerThan700 } = useGerenciadorImpressao();

  const handleRemoveItem = useCallback(
    async (id: string) => {
      const success = await removePrintDepartment(id);

      if (success) {
        toast.success('Impressora removida com sucesso');
        await getListPrintersDepartments();
      }
    },
    [removePrintDepartment, getListPrintersDepartments]
  );

  return (
    <FormProvider {...formMethods}>
      <Box w="full" h="max">
        <Flex
          w="full"
          minW="min-content"
          align="center"
          flexDir="row"
          justify="space-between"
          gap={{ base: 2, md: 0 }}
          px={4}
          pb={4}
        >
          {isLargerThan700 && (
            <Text fontSize="lg" fontWeight="bold" textColor="black">
              Departamentos
            </Text>
          )}
          <Button
            aria-label="adicionar-departamento"
            colorScheme="secondary"
            w={{ base: 'full', sm: 'max' }}
            borderRadius="full"
            leftIcon={<Icon as={OutrasDespesasIcon} fontSize="xl" />}
            onClick={() => openModalNewPrintDepartaments()}
            isDisabled={false}
          >
            Adicionar novo
          </Button>
        </Flex>
        <Box w="full" overflowX="auto">
          {hasPrinterDepartament ? (
            <Box w="max" minW="full">
              <Accordion
                defaultIndex={listPrintersDepartments.map((_, index) => index)}
                allowMultiple
              >
                {listPrintersDepartments.map(
                  ({ departamento, impressoras }) => (
                    <Item title={departamento.nome}>
                      {impressoras.map(
                        ({
                          id,
                          impressora,
                          caminho,
                          corteGuilhotina,
                          utilizarLarguraFixa,
                          quantidadeLinhasFim,
                        }) => {
                          return (
                            <AccordionPanel m={0} p={3}>
                              <Controller
                                name={`printer.${id}`}
                                render={({
                                  field: { onChange, value: isOpen },
                                }) => (
                                  <Box
                                    w={{ base: 'full', md: '98%' }}
                                    gap={3}
                                    display="flex"
                                    alignItems="flex-start"
                                    marginLeft="auto"
                                  >
                                    <Icon
                                      as={IoMdArrowDownRight}
                                      mt={3}
                                      fontSize="16px"
                                    />
                                    <Box
                                      w="full"
                                      bg="white"
                                      boxShadow="base"
                                      borderRadius="md"
                                    >
                                      <Flex
                                        align="center"
                                        justify="space-between"
                                        px={5}
                                        py={2}
                                      >
                                        <Text
                                          cursor="pointer"
                                          whiteSpace="nowrap"
                                          fontSize="16px"
                                          onClick={() =>
                                            openModalUpdateDepartaments(id)
                                          }
                                          _hover={{ textDecor: 'underline' }}
                                        >
                                          {impressora.nome}
                                        </Text>
                                        <Flex align="center" gap={1}>
                                          <Flex
                                            h="30px"
                                            w="32px"
                                            justify="center"
                                            align="center"
                                            cursor="pointer"
                                            borderRadius="md"
                                            onClick={() => onChange(!isOpen)}
                                            _hover={{
                                              background: 'gray.100',
                                            }}
                                          >
                                            <Icon
                                              as={FiInfo}
                                              fontSize="20px"
                                              stroke="gray.600"
                                            />
                                          </Flex>
                                          <ActionsMenu
                                            items={[
                                              {
                                                content: 'Editar',
                                                onClick: () =>
                                                  openModalUpdateDepartaments(
                                                    id
                                                  ),
                                              },
                                              {
                                                content: 'Remover',
                                                onClick: () =>
                                                  handleRemoveItem(id),
                                              },
                                            ]}
                                          />
                                        </Flex>
                                      </Flex>
                                      {isOpen && (
                                        <Box
                                          w="full"
                                          pb={3}
                                          transition="all 0.3s"
                                          borderTopStyle="solid"
                                          borderTopWidth="1px"
                                          borderTopColor="gray.200"
                                        >
                                          <Flex
                                            px={{ base: 0, md: 5 }}
                                            w="full"
                                          >
                                            <Divider
                                              orientation="vertical"
                                              borderColor="gray.500"
                                              height="81px"
                                            />
                                            <Box
                                              pt={3}
                                              textColor="blue.500"
                                              width="full"
                                              sx={{
                                                '& > div > * > span': {
                                                  fontWeight: 'bold',
                                                  textTransform: 'uppercase',
                                                },
                                              }}
                                            >
                                              <Flex gap="6px" align="center">
                                                <Divider
                                                  orientation="horizontal"
                                                  borderColor="gray.500"
                                                  maxW="40px"
                                                />
                                                <Text pl={2}>
                                                  Caminho:{' '}
                                                  <span>{caminho}</span>
                                                </Text>
                                              </Flex>
                                              <Flex gap="6px" align="center">
                                                <Divider
                                                  orientation="horizontal"
                                                  borderColor="gray.500"
                                                  maxW="40px"
                                                />
                                                <Text pl={2}>
                                                  Corte guilhotina:{' '}
                                                  <span>{corteGuilhotina}</span>
                                                </Text>
                                              </Flex>
                                              <Flex gap="6px" align="center">
                                                <Divider
                                                  orientation="horizontal"
                                                  borderColor="gray.500"
                                                  maxW="40px"
                                                />
                                                <Text pl={2}>
                                                  Número de linhas ao final da
                                                  impressão:{' '}
                                                  <span>
                                                    {quantidadeLinhasFim}
                                                  </span>
                                                </Text>
                                              </Flex>
                                              <Flex gap="6px" align="center">
                                                <Divider
                                                  orientation="horizontal"
                                                  borderColor="gray.500"
                                                  maxW="40px"
                                                />
                                                <Text pl={2}>
                                                  Utilizar largura fixa:{' '}
                                                  <span>
                                                    {utilizarLarguraFixa
                                                      ? 'Sim'
                                                      : 'Não'}
                                                  </span>
                                                </Text>
                                              </Flex>
                                            </Box>
                                          </Flex>
                                        </Box>
                                      )}
                                    </Box>
                                  </Box>
                                )}
                              />
                            </AccordionPanel>
                          );
                        }
                      )}
                    </Item>
                  )
                )}
              </Accordion>
            </Box>
          ) : (
            <Box w="full" mt={2} p={4} fontSize="sm" borderTop="1px solid #ccc">
              <Box
                w="max"
                minW="full"
                p={3}
                bg="white"
                boxShadow="base"
                borderRadius="md"
              >
                Nenhum resultado foi encontrado
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </FormProvider>
  );
};

export default Departments;
