import React from 'react';
import { Button, Flex, Text } from '@chakra-ui/react';
import Lottie from 'lottie-react';

import PagamentoRecusado from 'assets/animations/acao_rejeitada.json';

type PaymentFailedOrCanceledProps = {
  messageTransacao: string;
  retryTransaction: () => Promise<void>;
  changePaymentMethod: () => Promise<void>;
};

export const PaymentFailedOrCanceled = ({
  messageTransacao,
  retryTransaction,
  changePaymentMethod,
}: PaymentFailedOrCanceledProps) => {
  return (
    <Flex
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      py="56px"
      w="full"
    >
      <Flex
        gap="8px"
        mb="72px"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
      >
        <Lottie
          animationData={PagamentoRecusado}
          loop
          style={{
            width: '90px',
            height: '90px',
          }}
        />

        <Text
          color="red.500"
          fontWeight="black"
          fontSize="md"
          mt="8px"
          textAlign={['center', 'center', 'start']}
        >
          {messageTransacao}
        </Text>
      </Flex>
      <Flex flexDir={['column', 'row']} gap="24px">
        <Button
          variant="outlineDefault"
          colorScheme="gray"
          onClick={changePaymentMethod}
        >
          Trocar forma de recebimento
        </Button>
        <Button
          variant="solid"
          colorScheme="violet.500"
          w="250px"
          onClick={retryTransaction}
        >
          Tentar novamente
        </Button>
      </Flex>
    </Flex>
  );
};
