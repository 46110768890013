import ConstanteFuncionalidades from 'constants/permissoes';
import auth from 'modules/auth';

interface EnumRelatorioEstoqueProperty {
  name: string;
  value: number;
  id: number;
}

interface EnumRelatorioEstoque {
  RELATORIO_PRODUTO_POR_VENDA: number;
  LUCRO_POR_PRODUTO: number;
  PRODUTO_COM_PRECO: number;
  properties: EnumRelatorioEstoqueProperty[];
}

const possuiPermissaoVisualizarPrecoCusto = auth.possuiPermissao(
  ConstanteFuncionalidades.USUARIO_VISUALIZAR_PRECO_CUSTO
).permitido;

export const enumRelatorioProdutos = {
  RELATORIO_PRODUTO_POR_VENDA: 1,
  LUCRO_POR_PRODUTO: 2,
  PRODUTO_COM_PRECO: 3,

  properties: [
    {
      id: 1,
      name: 'Produto por venda',
      value: 1,
    },
    {
      id: 2,
      name: 'Lucro por produto',
      value: 2,
    },
    {
      id: 3,
      name: 'Produto com preço',
      value: 3,
    },
  ],
};

function filtrarEnumRelatorioProdutosPorPermissao(
  enumRelatorio: EnumRelatorioEstoque
): EnumRelatorioEstoque {
  const enumFiltrado = {
    ...enumRelatorio,
    properties: [...enumRelatorio.properties],
  };

  if (!possuiPermissaoVisualizarPrecoCusto) {
    enumFiltrado.properties = enumFiltrado.properties.filter(
      (property) => property.value !== enumFiltrado.LUCRO_POR_PRODUTO
    );
  }

  return enumFiltrado;
}

export const enumRelatorioProdutosPersonalizadoPadrao = filtrarEnumRelatorioProdutosPorPermissao(
  enumRelatorioProdutos
);
