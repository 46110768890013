import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  ModalFooter,
  VStack,
  ModalHeader,
  useMediaQuery,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';

import { ModalInstrucao } from '../ModalInstruções';

type ModalAjudaProps = Omit<ModalProps, 'children' | 'isOpen' | 'onClose'> &
  InstanceProps<ModalProps>;

export const ModalAjuda = create<ModalAjudaProps, ModalProps>(
  ({ onResolve, onReject, ...rest }) => {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? '3xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
      >
        <ModalContent
          bg="gray.50"
          borderRadius={isLargerThan900 ? 'md' : '0'}
          marginBottom={isLargerThan900 ? '3.75rem' : '0'}
          marginTop={isLargerThan900 ? '3.75rem' : '0'}
          w={isLargerThan900 ? '640px' : 'full'}
          h={isLargerThan900 ? '320px' : 'full'}
        >
          <ModalHeader pt="16px" pb="28px" pl="24px">
            <Text color="primary.50" fontSize="20px">
              Atenção ao limite de produtos
            </Text>
          </ModalHeader>

          <ModalBody pl="24px" pr="24px" mb="20px" pt="0" pb="0">
            <VStack
              color="black"
              fontSize="14px"
              w="full"
              alignItems="left"
              spacing="24px"
            >
              <Text mb="4px" textAlign="left" letterSpacing="0px">
                Antes de selecionar os produtos no sistema, consulte o plano
                contratado na Tray para saber qual é o número limite de produtos
                que podem ser publicados.
              </Text>
              <Text mb="4px" textAlign="left" letterSpacing="0px">
                Os produtos selecionados no sistema serão enviados em ordem
                alfabética, portanto ao atingir o limite do plano,{' '}
                <span style={{ fontWeight: 'bold' }}>
                  os itens que estiverem além do limite não serão publicados.
                </span>
              </Text>
            </VStack>
          </ModalBody>
          <ModalFooter flexDirection="column" mb="10px">
            <Flex
              w="full"
              h="full"
              justifyContent="center"
              alignItems="baseline"
            >
              <Button
                variant="outlineDefault"
                colorScheme="gray"
                lineHeight="0"
                borderRadius="20px"
                fontSize="sm"
                h="40px"
                mr="20px"
                width="160px"
                onClick={() => {
                  onClose();
                  ModalInstrucao();
                }}
              >
                Ver instruções
              </Button>
              <Button
                color="white"
                variant="solid"
                colorScheme="purple.500"
                lineHeight="0"
                borderRadius="20px"
                fontSize="sm"
                h="40px"
                width="160px"
                onClick={() => onClose()}
              >
                Iniciar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
