const enumTipoItemProdutoEtapa = {
  PRODUTO: 1,
  OBSERVACAO: 2,

  properties: {
    0: { name: 'Produto', value: 1 },
    1: { name: 'Observação', value: 2 },
  },

  options: [
    { label: 'Produto', value: 1 },
    { label: 'Observação', value: 2 },
  ],
};

export default enumTipoItemProdutoEtapa;
