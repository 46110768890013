/* eslint-disable @typescript-eslint/ban-ts-comment */

import React from 'react';
import {
  Stack,
  Text,
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
  Icon,
  Box,
  Flex,
  useMediaQuery,
} from '@chakra-ui/react';
import { FiChevronDown, FiChevronRight } from 'react-icons/fi';
import { IconType as ReactIconsIconType } from 'react-icons/lib';
// @ts-ignore
import NotificationBadge from 'react-notification-badge';

import { IconType } from 'icons';

interface ButtonProps extends ChakraButtonProps {
  icon: IconType | ReactIconsIconType;
  title: string;
  titleColor?: string;
  subtitle?: string;
  subtitleColor?: string;
  hasIndicator?: boolean;
  badgeCount?: number;
  badgeColorScheme?: string;
  ml?: string;
}

const Button: React.FC<ButtonProps> = ({
  icon,
  title,
  ml = '0',
  titleColor = 'gray.300',
  subtitle,
  subtitleColor = 'teal.400',
  hasIndicator,
  badgeCount,
  badgeColorScheme = 'red',
  ...rest
}) => {
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  return (
    <ChakraButton
      position="relative"
      color={titleColor}
      variant="ghost"
      fontSize="xs"
      height={{ base: '54px', md: 'auto' }}
      width={{ base: '100%', md: 'auto' }}
      minW={{ base: undefined, md: 'min' }}
      py={{ base: 4, md: 0 }}
      px={0}
      borderRadius={{ base: 0, md: 'md' }}
      borderTopColor={{ base: 'pdv.divider', md: 'primary.900' }}
      borderTopStyle="solid"
      borderTopWidth={{ base: '1px', md: '0px' }}
      {...rest}
    >
      <Stack
        direction={{ base: 'row', md: 'column' }}
        align="center"
        justifyContent={{ base: 'flex-start', md: 'center' }}
        h="100%"
        w="100%"
        spacing={{ base: 3, md: 2 }}
      >
        <Box position="relative">
          <Icon as={icon} ml={ml} fontSize={{ base: 'xl', md: '3xl' }} />

          {badgeCount && badgeCount > 0 ? (
            <Box
              position="absolute"
              bottom={0}
              right={0}
              transform="translate(50%, 25%)"
              h="22px"
              w="22px"
              borderRadius="full"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <NotificationBadge
                count={badgeCount}
                fra
                effect={[null, null, { top: '-5px' }, { top: '0px' }]}
                style={{ background: badgeColorScheme }}
              />
            </Box>
          ) : null}
        </Box>

        <Flex
          position="relative"
          flexDirection="column"
          alignItems={{ base: 'flex-start', md: 'center' }}
          justifyContent="center"
        >
          <Flex alignItems="center" justifyContent="center">
            <Text
              fontWeight="normal"
              fontSize="sm"
              pr={hasIndicator ? 1 : undefined}
            >
              {title}
            </Text>

            {hasIndicator && isLargerThan900 && <Icon as={FiChevronDown} />}
          </Flex>

          {subtitle && (
            <Text
              position={{ base: undefined, md: 'absolute' }}
              bottom={0}
              fontWeight="normal"
              fontSize="2xs"
              color={subtitleColor}
              transform={{ base: undefined, md: 'translateY(100%)' }}
              pt={1}
            >
              {subtitle}
            </Text>
          )}
        </Flex>

        {hasIndicator && !isLargerThan900 && (
          <Flex w="full" alignItems="center" justifyContent="flex-end">
            <Icon as={FiChevronRight} boxSize="4" color="white" />
          </Flex>
        )}
      </Stack>
    </ChakraButton>
  );
};

export default Button;
