import React, { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Col, Container, Button } from 'react-bootstrap';
import InputPadrao from 'components/Input/InputPadrao';
import InputNumerico from 'components/Input/InputNumerico';
import SelectPadrao from 'components/Select/SelectPadrao';
import OperacoesFiscaisEnum from 'constants/enum/fiscal/operacaoFiscal';
import FinalidadesNotaFiscalEnum from 'constants/enum/fiscal/finalidadeNotaFiscal';
import OperacoesPresencaCompradorEnum from 'constants/enum/fiscal/operacaoPresencaComprador';
import ModalidadesFreteEnum from 'constants/enum/fiscal/modalidadeFrete';
import OperacaoIntermediadorEnum from 'constants/enum/fiscal/operacaoIntermediador';
import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';

import EstadosEnum from 'constants/enum/estados';
import AccordionPadrao from 'components/Accordion/AccordionPadrao';
import SelectTransportador from 'components/Select/SelectTransportador';
import SelectClienteNotaFiscal, {
  ClienteInterface,
} from 'components/Select/SelectClienteNotaFiscal';

import { BootstrapNumberInput } from 'components/update/Input/BootstrapNumberInput';
import { Label } from 'components/Layout/CampoContainer/CampoContainerPadrao/styles';
import ButtonComOpcoes from 'components/Button/ButtonComOpcoes';
import InputNull from 'components/Input/InputNull';
import TableCampoFormularioPadrao from 'components/Table/TableCampoFormularioPadrao';
import { useFormContext } from 'react-hook-form';
import ViasTransporteEnum from 'constants/enum/fiscal/viaTransporte';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import InputCpfCnpj from 'components/Input/InputCpfCnpj';
import { useNotaFiscalFormularioContext } from 'store/NotaFiscal/NotaFiscalFormulario';
import { getName } from 'helpers/enum/getName';
import ModelosFiscaisEnum from 'constants/enum/fiscal/modelosFiscais';
import DocumentoFiscalModal from './DocumentoFiscalModal';
import DocumentoImportacaoModal from './DocumentoImportacaoModal';

interface UncontrolledFormInterface {
  readonly?: boolean;
  isVisualizar?: boolean;
}

export const Principal = forwardRef(
  ({ readonly, isVisualizar }: UncontrolledFormInterface) => {
    const [
      isCustomerFromDifferentCountry,
      setCustomerFromDifferentCountry,
    ] = useState(false);

    const { t } = useTranslation();
    const {
      getValues,
      setValue,
      formState: { errors },
      watch,
      control,
      setFocus,
    } = useFormContext();

    const {
      alteracaoCarregada,
      lojaInformacoesFiscais,
    } = useNotaFiscalFormularioContext();

    const paisIdLoja = lojaInformacoesFiscais?.paisId;

    const modalidadeFreteWatch = watch(
      'modalidadeFrete',
      ModalidadesFreteEnum.SemFrete
    );

    const operacaoComIntermediadorWatch = watch(
      'operacaoComIntermediador',
      OperacaoIntermediadorEnum.SemIntermediador
    );

    const modeloFiscal = watch('modeloFiscal');

    const [showDocumentoFiscalModal, setShowDocumentoFiscalModal] = useState(
      false
    );

    const [
      showDocumentoImportacaoModal,
      setShowDocumentoImportacaoModal,
    ] = useState(false);

    const removerItemList = (index: number, propertyName: string) => {
      ModalConfirmacaoExcluir({
        callback: async (ok: boolean) => {
          if (ok) {
            const items = getValues(`${propertyName}` as const) as Array<any>;
            const documentoImportacaoItem = items[index];
            items.splice(index, 1);

            setValue(`${propertyName}` as const, [...items]);

            const documentoFiscalItens = getValues(
              'documentoFiscalItens'
            ) as Array<any>;

            for (
              let indexDoc = 0;
              indexDoc < documentoFiscalItens.length;
              indexDoc += 1
            ) {
              const item = documentoFiscalItens[indexDoc];

              item.adicoes = item.adicoes.filter(
                (adicao: any) =>
                  adicao.documentoImportacao !==
                  documentoImportacaoItem.numeroDocumento
              );
            }

            setValue('documentoFiscalItens', [...documentoFiscalItens]);
          }
        },
        text: '',
      });
    };

    const getSelectedCliente = async (cliente: ClienteInterface) => {
      if (cliente) {
        const paisIdCliente = cliente.paisId;

        setValue(
          'destinatarioEnderecoEntrega',
          cliente.descricaoEnderecoEntrega
        );
        setValue(
          'destinatarioEnderecoCobranca',
          cliente.descricaoEnderecoCobranca
        );

        if (paisIdCliente && paisIdLoja && paisIdLoja !== paisIdCliente) {
          setCustomerFromDifferentCountry(true);
        } else {
          setCustomerFromDifferentCountry(false);
        }
      } else {
        setValue('destinatarioEnderecoEntrega', '');
        setValue('destinatarioEnderecoCobranca', '');
      }
    };

    useEffect(() => {
      if (alteracaoCarregada) {
        setFocus('serie');
      } else {
        setFocus('naturezaOperacao');
      }
    }, [alteracaoCarregada, setFocus]);

    const optionsTipoOperacao = Object.entries(
      IdentificacaoTipoOperacaoEnum.properties
    )
      .filter(
        (tipoOperacao) =>
          tipoOperacao[1].value ===
            IdentificacaoTipoOperacaoEnum.AJUSTE_ESTOQUE_SAIDA ||
          tipoOperacao[1].value === IdentificacaoTipoOperacaoEnum.BRINDE ||
          tipoOperacao[1].value ===
            IdentificacaoTipoOperacaoEnum.DEVOLUCAO_COMPRA ||
          tipoOperacao[1].value === IdentificacaoTipoOperacaoEnum.DEVOLUCAO ||
          tipoOperacao[1].value === IdentificacaoTipoOperacaoEnum.PERDA ||
          tipoOperacao[1].value ===
            IdentificacaoTipoOperacaoEnum.TRANSFERENCIA_PRODUTO_SAIDA ||
          tipoOperacao[1].value === IdentificacaoTipoOperacaoEnum.VENDA
      )
      .map((item) => ({
        label: item[1].name,
        value: item[1].value,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    const [tipoOperacaoDoSistema, clienteWatch] = watch([
      'tipoDeOperacaoDoSistema',
      'cliente',
    ]);
    useEffect(() => {
      if (!tipoOperacaoDoSistema) {
        setValue('operacaoFiscalSelecionada', null);
      }
    }, [tipoOperacaoDoSistema, setValue]);

    useEffect(() => {
      const clienteExterior = () => {
        const paisIdCliente = clienteWatch?.obj?.paisId;

        if (paisIdCliente && paisIdLoja && paisIdLoja !== paisIdCliente) {
          setCustomerFromDifferentCountry(true);
        } else {
          setCustomerFromDifferentCountry(false);
        }
      };
      clienteExterior();
    }, [clienteWatch, paisIdLoja]);

    return (
      <>
        <Container fluid>
          <Form.Row>
            <Col md={3} xl={2}>
              <InputPadrao
                type="text"
                id="numero"
                label={t('Número')}
                maxLength={9}
                defaultValue=""
                error={errors.numero}
                disabled
                control={control}
              />
            </Col>
            <Col md={2} xl={2}>
              <InputNumerico
                type="text"
                autoFocus
                id="serie"
                name="serie"
                label={t('Série')}
                required
                maxLength={3}
                error={errors.serie}
                disabled={readonly || isVisualizar}
                control={control}
              />
            </Col>
            <Col md={7} xl={2}>
              <InputPadrao
                type="text"
                id="status"
                label={t('Status')}
                maxLength={9}
                error={errors.status}
                disabled
                control={control}
              />
            </Col>
            <Col md={6} xl={4}>
              <InputPadrao
                type="text"
                id="chaveAcesso"
                label={t('Chave de acesso')}
                defaultValue=""
                maxLength={44}
                error={errors.chaveAcesso}
                disabled
                control={control}
              />
            </Col>
            <Col md={6} xl={2}>
              <InputPadrao
                type="text"
                id="numeroPedidoExterno"
                label={t('Número de pedido externo')}
                defaultValue=""
                maxLength={15}
                error={errors.numeroPedidoExterno}
                control={control}
                disabled={isVisualizar}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xl={12}>
              <hr className="my-auto flex-grow-1" />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col md={3} xl={2}>
              <SelectPadrao
                id="tipoOperacao"
                name="tipoOperacao"
                label={t('Tipo de operação')}
                placeholder="Selecione"
                defaultValue={1}
                control={control}
                error={errors.tipoOperacao}
                options={Object.entries(OperacoesFiscaisEnum.properties).map(
                  (value: any) => {
                    return (
                      {
                        label: t(value[1].name),
                        value: value[1].value,
                      } || {}
                    );
                  }
                )}
                readonly={false}
                required
                isDisabled={isVisualizar}
              />
            </Col>
            <Col md={4} xl={2}>
              <SelectPadrao
                id="finalidade"
                name="finalidade"
                label={t('Finalidade')}
                placeholder="Selecione"
                defaultValue={1}
                control={control}
                error={errors.finalidade}
                options={Object.entries(
                  FinalidadesNotaFiscalEnum.properties
                ).map((value: any) => {
                  return (
                    {
                      label: t(value[1].name),
                      value: value[1].value,
                    } || {}
                  );
                })}
                readonly={false}
                required
                isDisabled={isVisualizar}
              />
            </Col>
            <Col md={5} xl={4}>
              <SelectPadrao
                id="tipoOperacaoPresencaComprador"
                name="tipoOperacaoPresencaComprador"
                label={t('Presença do comprador')}
                placeholder="Selecione"
                defaultValue={1}
                control={control}
                error={errors.tipoOperacaoPresencaComprador}
                options={Object.entries(
                  OperacoesPresencaCompradorEnum.properties
                ).map((value: any) => {
                  return (
                    {
                      label: t(value[1].name),
                      value: value[1].value,
                    } || {}
                  );
                })}
                readonly={false}
                required
                isDisabled={isVisualizar}
              />
            </Col>
            <Col md={4} xl={2}>
              <InputNull
                type="datetime-local"
                id="dataEmissao"
                name="dataEmissao"
                label="Data/Hora de emissão"
                control={control}
                max={new Date().toISOString().replace('Z', '')}
                error={errors.dataEmissao}
                required
                disabled={
                  modeloFiscal === ModelosFiscaisEnum.NFCe || isVisualizar
                }
              />
            </Col>
            <Col md={4} xl={2}>
              <InputNull
                type="datetime-local"
                id="dataSaida"
                name="dataSaida"
                label="Data/Hora de saída"
                control={control}
                error={null}
                required={false}
                disabled={isVisualizar}
              />
            </Col>
            <Col md={12} xl={4}>
              <InputPadrao
                type="text"
                id="naturezaOperacao"
                label={t('Natureza da operação')}
                defaultValue=""
                maxLength={60}
                error={errors.naturezaOperacao}
                required
                control={control}
                disabled={isVisualizar}
              />
            </Col>
            <Col md={12} xl={4}>
              <SelectPadrao
                id="tipoDeOperacaoDoSistema"
                name="tipoDeOperacaoDoSistema"
                label={t('Tipo de operação do sistema')}
                placeholder="Selecione"
                defaultValue=""
                onChange={() => {
                  const teste = getValues('operacaoFiscalSelecionada');
                  if (teste) setValue('operacaoFiscalSelecionada', null);
                }}
                control={control}
                error={errors.tipoDeOperacaoDoSistema}
                options={optionsTipoOperacao}
                readonly={false}
                isDisabled={isVisualizar}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <AccordionPadrao title="Informações do destinatário" defaultOpen>
                <Form.Row>
                  <Col xl={4}>
                    <SelectClienteNotaFiscal
                      type="text"
                      id="cliente"
                      name="cliente"
                      label={t('Nome / razão social')}
                      required
                      control={control}
                      error={errors.cliente}
                      isDisabled={readonly || isVisualizar}
                      getSelectedCliente={getSelectedCliente}
                      // getValue={getValue}
                    />
                  </Col>
                  <Col xl={4}>
                    <InputPadrao
                      type="text"
                      id="destinatarioEnderecoEntrega"
                      label={t('Endereço de entrega')}
                      defaultValue=""
                      error={null}
                      disabled
                      control={control}
                    />
                  </Col>
                  <Col xl={4}>
                    <InputPadrao
                      type="text"
                      id="destinatarioEnderecoCobranca"
                      label={t('Endereço de cobrança')}
                      defaultValue=""
                      error={null}
                      disabled
                      control={control}
                    />
                  </Col>
                </Form.Row>
              </AccordionPadrao>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <AccordionPadrao title="Informações de transporte" defaultOpen>
                <Form.Row>
                  <Col xl={4}>
                    <SelectPadrao
                      id="modalidadeFrete"
                      name="modalidadeFrete"
                      label={t('Modalidade do frete')}
                      placeholder="Selecione"
                      defaultValue={ModalidadesFreteEnum.SemFrete}
                      control={control}
                      error={errors.modalidadeFrete}
                      options={Object.entries(
                        ModalidadesFreteEnum.properties
                      ).map((value: any) => {
                        return (
                          {
                            label: t(value[1].name),
                            value: value[1].value,
                          } || {}
                        );
                      })}
                      readonly={false}
                      required
                      isDisabled={isVisualizar}
                    />
                  </Col>
                </Form.Row>

                {modalidadeFreteWatch !== ModalidadesFreteEnum.SemFrete && (
                  <>
                    <Form.Row>
                      <Col>
                        <SelectTransportador
                          type="text"
                          autoFocus
                          id="transportadora"
                          name="transportadora"
                          label={t('Transportadora')}
                          required
                          placeholder={t('Digite o transportador')}
                          control={control}
                          error={errors.transportadora}
                          isDisabled={readonly || isVisualizar}
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col md={3} xl={2}>
                        <InputPadrao
                          type="text"
                          id="placaTransportadora"
                          label={t('Placa do veículo')}
                          maxLength={7}
                          error={errors.placaTransportadora}
                          disabled={isVisualizar}
                          control={control}
                        />
                      </Col>
                      <Col md={3} xl={2}>
                        <SelectPadrao
                          id="ufVeiculoTransportadora"
                          name="ufVeiculoTransportadora"
                          label={t('UF do veículo')}
                          placeholder="Selecione"
                          defaultValue={undefined}
                          control={control}
                          error={errors.modalidadeFrete}
                          options={Object.entries(EstadosEnum.properties).map(
                            (value) => {
                              return (
                                {
                                  label: t(value[1].sigla),
                                  value: value[1].sigla,
                                } || {}
                              );
                            }
                          )}
                          disabled={readonly || isVisualizar}
                          required={false}
                        />
                      </Col>
                      <Col md={3} xl={2}>
                        <BootstrapNumberInput
                          id="pesoLiquido"
                          name="pesoLiquido"
                          label={t('Peso líquido')}
                          precision={12}
                          scale={3}
                          defaultValue={0}
                          leftElement="KG"
                          control={control}
                          error={errors.pesoBruto}
                          disabled={readonly || isVisualizar}
                        />
                      </Col>
                      <Col md={3} xl={2}>
                        <BootstrapNumberInput
                          id="pesoBruto"
                          name="pesoBruto"
                          label={t('Peso bruto')}
                          precision={12}
                          scale={3}
                          defaultValue={0}
                          leftElement="KG"
                          control={control}
                          error={errors.pesoBruto}
                          disabled={readonly || isVisualizar}
                        />
                      </Col>
                      <Col md={3} xl={2}>
                        <InputNumerico
                          type="text"
                          id="quantidadeVolumeTransportadora"
                          name="quantidadeVolumeTransportadora"
                          label={t('Quantidade')}
                          required
                          maxLength={15}
                          error={errors.quantidadeVolumeTransportadora}
                          disabled={readonly || isVisualizar}
                          control={control}
                        />
                      </Col>
                      <Col md={3} xl={3}>
                        <InputPadrao
                          type="text"
                          id="especieVolumeTransportadora"
                          label={t('Espécie')}
                          maxLength={60}
                          error={errors.especieVolumeTransportadora}
                          disabled={false}
                          control={control}
                        />
                      </Col>
                      <Col md={3} xl={3}>
                        <InputPadrao
                          type="text"
                          id="marcaVolumeTransportadora"
                          label={t('Marca')}
                          maxLength={60}
                          error={errors.marcaVolumeTransportadora}
                          disabled={isVisualizar}
                          control={control}
                        />
                      </Col>
                      <Col md={3} xl={3}>
                        <InputPadrao
                          type="text"
                          id="numeracaoVolumeTransportadora"
                          label={t('Número')}
                          maxLength={60}
                          error={errors.numeracaoVolumeTransportadora}
                          disabled={isVisualizar}
                          control={control}
                        />
                      </Col>
                    </Form.Row>
                    {isCustomerFromDifferentCountry && (
                      <>
                        <Form.Row>
                          <Col xl={12}>
                            <div className="d-flex mt-1">
                              <Label>
                                Informações de comércio exterior utilizadas para
                                exportação de mercadorias
                              </Label>
                            </div>
                            <hr className="my-auto flex-grow-1" />
                          </Col>
                        </Form.Row>
                        <Form.Row>
                          <Col md={3} xl={2}>
                            <SelectPadrao
                              id="ufEmbarque"
                              name="ufEmbarque"
                              label={t('UF de embarque')}
                              placeholder="Selecione"
                              defaultValue={undefined}
                              control={control}
                              error={errors.modalidadeFrete}
                              options={Object.entries(
                                EstadosEnum.properties
                              ).map((value) => {
                                return (
                                  {
                                    label: t(value[1].sigla),
                                    value: value[1].sigla,
                                  } || {}
                                );
                              })}
                              isDisabled={readonly || isVisualizar}
                              required={false}
                            />
                          </Col>
                          <Col md={9} xl={6}>
                            <InputPadrao
                              type="text"
                              id="localEmbarque"
                              label={t('Local do embarque')}
                              maxLength={60}
                              error={errors.localEmbarque}
                              disabled={isVisualizar}
                              control={control}
                            />
                          </Col>
                        </Form.Row>
                      </>
                    )}
                  </>
                )}
              </AccordionPadrao>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <AccordionPadrao title="Intermediador" defaultOpen>
                <Form.Row>
                  <Col xl={4}>
                    <SelectPadrao
                      id="operacaoComIntermediador"
                      name="operacaoComIntermediador"
                      label={t('Operação')}
                      placeholder="Selecione"
                      defaultValue={OperacaoIntermediadorEnum.SemIntermediador}
                      control={control}
                      error={errors.operacaoComIntermediador}
                      options={Object.entries(
                        OperacaoIntermediadorEnum.properties
                      ).map((value: any) => {
                        return (
                          {
                            label: t(value[1].name),
                            value: value[1].value,
                          } || {}
                        );
                      })}
                      readonly={false}
                      required
                      isDisabled={isVisualizar}
                    />
                  </Col>
                  {operacaoComIntermediadorWatch !==
                    OperacaoIntermediadorEnum.SemIntermediador && (
                    <>
                      <Col md={6} xl={2}>
                        <InputCpfCnpj
                          id="cnpjIntermediador"
                          name="cnpjIntermediador"
                          label={t('CNPJ intermediador')}
                          control={control}
                          autoFocus
                          error={errors.cnpjIntermediador}
                          disabled={isVisualizar}
                          setError={() => {}}
                          onlyCnpj
                          showSearchIcon={false}
                          required={false}
                        />
                      </Col>
                      <Col md={9} xl={6}>
                        <InputPadrao
                          type="text"
                          id="identificacaoNoIntermediador"
                          label={t('Identificação no intermediador')}
                          maxLength={60}
                          error={errors.identificacaoNoIntermediador}
                          disabled={isVisualizar}
                          control={control}
                        />
                      </Col>
                    </>
                  )}
                </Form.Row>
              </AccordionPadrao>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xl={6}>
              {!isVisualizar && (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    variant="link"
                    style={{ fontSize: '14px' }}
                    onClick={() => setShowDocumentoFiscalModal(true)}
                  >
                    Adicionar documento fiscal
                  </Button>
                </div>
              )}
              <TableCampoFormularioPadrao
                fieldName="documentoFiscalReferenciados"
                defaultColumnOrder=""
                headerColumns={[
                  {
                    name: 'documento',
                    displayName: 'Documento',
                    isOrderable: false,
                    width: '',
                  },
                  {
                    name: 'acoes',
                    displayName: isVisualizar ? '' : 'Ações',
                    isOrderable: false,
                    width: isVisualizar ? '0px' : '38px',
                  },
                ]}
                emptyValuesText="Nenhum documento fiscal adicionado para esta nota"
                control={control}
                isLoading={false}
              >
                {({ tableValues }) => (
                  <>
                    {tableValues &&
                      tableValues.map((tableValue, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <tr key={index}>
                          <td>
                            {tableValue.chaveAcesso
                              ? tableValue.chaveAcesso
                              : `NF: ${tableValue.numero} Serie: ${
                                  tableValue.serie
                                } Modelo: ${
                                  // eslint-disable-next-line radix
                                  parseInt(tableValue.modeloFiscal) + 1
                                } - ${tableValue.emissaoMes}/${
                                  tableValue.emissaoAno
                                } - ${tableValue.cnpj}`}
                          </td>
                          {!isVisualizar && (
                            <td>
                              <ButtonComOpcoes
                                id="mostrarMais"
                                dropdownItems={[
                                  {
                                    title: t('Remover'),
                                    disabled: isVisualizar,
                                    onClick: () =>
                                      removerItemList(
                                        index,
                                        'documentoFiscalReferenciados'
                                      ),
                                  },
                                ]}
                              />
                            </td>
                          )}
                        </tr>
                      ))}
                  </>
                )}
              </TableCampoFormularioPadrao>
            </Col>
            <Col xl={6}>
              {!isVisualizar && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    variant="link"
                    style={{ fontSize: '14px' }}
                    onClick={() => setShowDocumentoImportacaoModal(true)}
                  >
                    Adicionar documento de importação
                  </Button>
                </div>
              )}
              <TableCampoFormularioPadrao
                fieldName="documentoFiscalImportacoes"
                defaultColumnOrder=""
                headerColumns={[
                  {
                    name: 'documento',
                    displayName: 'DI',
                    isOrderable: false,
                    width: '',
                  },
                  {
                    name: 'codigoExportador',
                    displayName: 'Código do exportador',
                    isOrderable: false,
                    width: '',
                  },
                  {
                    name: 'dataRegistro',
                    displayName: 'Data de registro',
                    isOrderable: false,
                    width: '',
                  },
                  {
                    name: 'viaTransporte',
                    displayName: 'Via de transporte',
                    isOrderable: false,
                    width: '',
                  },
                  {
                    name: 'acoes',
                    displayName: isVisualizar ? '' : 'Ações',
                    isOrderable: false,
                    width: isVisualizar ? '0px' : '38px',
                  },
                ]}
                emptyValuesText="Nenhum documento de importação adicionado para esta nota"
                control={control}
                isLoading={false}
              >
                {({ tableValues }) => (
                  <>
                    {tableValues &&
                      tableValues.map((tableValue, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <tr key={index}>
                          <td>{tableValue.numeroDocumento}</td>
                          <td>{tableValue.codigoExportador}</td>
                          <td>
                            {tableValue.dataRegistro
                              ? new Date(
                                  tableValue.dataRegistro
                                ).toLocaleDateString()
                              : ''}
                          </td>
                          <td>
                            {getName(
                              ViasTransporteEnum,
                              tableValue.viaTransporte
                            )}
                          </td>
                          {!isVisualizar && (
                            <td>
                              <ButtonComOpcoes
                                id="mostrarMais"
                                dropdownItems={[
                                  {
                                    title: t('Remover'),
                                    onClick: () =>
                                      removerItemList(
                                        index,
                                        'documentoFiscalImportacoes'
                                      ),
                                  },
                                ]}
                              />
                            </td>
                          )}
                        </tr>
                      ))}
                  </>
                )}
              </TableCampoFormularioPadrao>
            </Col>
          </Form.Row>
        </Container>
        <DocumentoFiscalModal
          show={showDocumentoFiscalModal}
          onHide={() => setShowDocumentoFiscalModal(false)}
        />

        <DocumentoImportacaoModal
          show={showDocumentoImportacaoModal}
          onHide={() => setShowDocumentoImportacaoModal(false)}
        />
      </>
    );
  }
);
