import { Flex } from '@chakra-ui/react';
import { cnpjMask, cpfMask } from 'helpers/format/fieldsMasks';
import React from 'react';

import { DestinatarioInterface } from 'types/ImpressaoNFCe';

interface ImpressaoInterface {
  destinatario?: DestinatarioInterface;
}

const Consumidor = ({ destinatario }: ImpressaoInterface) => {
  let identificacaoConsumidor = 'CONSUMIDOR NÃO IDENTIFICADO';
  if (destinatario?.cnpj) {
    identificacaoConsumidor = `CONSUMIDOR CNPJ: ${cnpjMask(
      destinatario.cnpj
    )} ${destinatario.nome}`;
  } else if (destinatario?.cpf) {
    identificacaoConsumidor = `CONSUMIDOR CPF: ${cpfMask(destinatario.cpf)}  ${
      destinatario.nome
    }`;
  } else if (destinatario?.identificacaoEstrangeiro) {
    identificacaoConsumidor = `CONSUMIDOR Id. Estrangeiro: ${destinatario.identificacaoEstrangeiro} ${destinatario.nome}`;
  }

  let enderecoFormatado = '';
  if (destinatario?.enderecoDestinatario) {
    enderecoFormatado = destinatario?.enderecoDestinatario.logradouro;

    if (enderecoFormatado && destinatario?.enderecoDestinatario.numero) {
      enderecoFormatado += `, ${destinatario?.enderecoDestinatario.numero}`;
    }

    let bairroCidadeEstado = destinatario?.enderecoDestinatario.bairro;
    const complemento = destinatario?.enderecoDestinatario.complemento || '';
    if (destinatario?.enderecoDestinatario.nomeMunicipio) {
      if (bairroCidadeEstado) {
        bairroCidadeEstado += `, ${destinatario?.enderecoDestinatario.nomeMunicipio}`;
      } else {
        bairroCidadeEstado = destinatario?.enderecoDestinatario.nomeMunicipio;
      }
    }

    if (destinatario?.enderecoDestinatario.siglaUF) {
      if (bairroCidadeEstado) {
        bairroCidadeEstado += `- ${destinatario?.enderecoDestinatario.siglaUF}`;
      } else {
        bairroCidadeEstado = destinatario?.enderecoDestinatario.siglaUF;
      }
    }

    if (enderecoFormatado) {
      enderecoFormatado += `, ${bairroCidadeEstado} ${
        complemento !== '' ? `, ${complemento}` : ''
      }`;
    }
  }

  return (
    <Flex>
      {identificacaoConsumidor} {enderecoFormatado}
    </Flex>
  );
};

export default Consumidor;
