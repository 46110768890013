import React, { useEffect } from 'react';
import { Col, Container, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ButtonPadrao from 'components/Button/ButtonPadrao';
import { SalvarConfirmarIcon } from 'icons';
import ModalPadrao from 'components/Modal/ModalPadrao';
import InputPadrao from 'components/Input/InputPadrao';
import { Modal } from './styles';
import { yupResolver, useForm } from './validationForm';

interface CSCModalProps {
  show: boolean;
  onHide: (willReset?: boolean) => void;
  readonly: any;
  indexItem: number;
  getValuesLoja: any;
  setValuesLoja: any;
}

const CertificadoDigitalModal: React.FC<CSCModalProps> = ({
  show,
  onHide,
  setValuesLoja,
  readonly,
  indexItem,
  getValuesLoja,
}) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setFocus,
    control,
  } = useForm({
    resolver: yupResolver,
    shouldUnregister: true,
  });

  const { t } = useTranslation();

  const handleSalvar = handleSubmit((data: any) => {
    const { codigosSegurancaContribuinte } = getValuesLoja();

    if (indexItem === -1) {
      const codigo = {
        CSC: data.csc,
        Token: data.token,
        CSCHomologacao: data.cscHomologacao,
        TokenHomologacao: data.tokenHomologacao,
        Principal: !(codigosSegurancaContribuinte.length > 0),
      };
      codigosSegurancaContribuinte.push(codigo);
    } else {
      codigosSegurancaContribuinte[indexItem].CSC = data.csc;
      codigosSegurancaContribuinte[indexItem].Token = data.token;
      codigosSegurancaContribuinte[indexItem].CSCHomologacao =
        data.cscHomologacao;
      codigosSegurancaContribuinte[indexItem].TokenHomologacao =
        data.tokenHomologacao;
    }
    setValuesLoja('codigosSegurancaContribuinte', [
      ...codigosSegurancaContribuinte,
    ]);

    onHide();
  });

  useEffect(() => {
    if (show && indexItem !== -1) {
      const { codigosSegurancaContribuinte } = getValuesLoja();
      const codigo = codigosSegurancaContribuinte[indexItem];

      reset({
        csc: codigo.CSC,
        token: codigo.Token,
        cscHomologacao: codigo.CSCHomologacao,
        tokenHomologacao: codigo.TokenHomologacao,
      });
    }

    const firstInput = document.getElementById('CSC');
    if (firstInput) firstInput.focus();
  }, [getValuesLoja, indexItem, reset, show]);

  return (
    <ModalPadrao
      isOpen={show}
      handleOnHide={onHide}
      title={t('Código de segurança do contribuinte (CSC)')}
      maxWidth="450px"
      subtitle={t('Adicionar novo CSC')}
      onEntered={() => setFocus('csc')}
    >
      <Modal.Body>
        <Container fluid>
          <Form.Row>
            <Col xs={12}>
              <InputPadrao
                type="text"
                id="csc"
                defaultValue={undefined}
                required
                maxLength={36}
                label={t('CSC')}
                placeholder={t('Digite o CSC')}
                error={errors.csc}
                disabled={readonly}
                control={control}
              />
            </Col>
            <Col xs={12}>
              <InputPadrao
                type="text"
                id="token"
                defaultValue={undefined}
                required
                maxLength={8}
                label={t('Token')}
                placeholder={t('Digite o token')}
                error={errors.token}
                disabled={readonly}
                control={control}
              />
            </Col>
            <Col xs={12}>
              <InputPadrao
                type="text"
                id="cscHomologacao"
                defaultValue={undefined}
                required={false}
                maxLength={36}
                label={t('CSC homologação')}
                placeholder={t('Digite o CSC')}
                error={errors.cscHomologacao}
                disabled={readonly}
                control={control}
              />
            </Col>
            <Col xs={12}>
              <InputPadrao
                type="text"
                id="tokenHomologacao"
                defaultValue={undefined}
                required={false}
                maxLength={8}
                label={t('Token homologação')}
                placeholder={t('Digite o token')}
                error={errors.tokenHomologacao}
                disabled={readonly}
                control={control}
              />
            </Col>
          </Form.Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <ButtonPadrao
          id="fechar"
          name="fechar"
          text={t('Fechar')}
          type="button"
          variant="outline-secondary"
          onClick={() => {
            onHide(true);
          }}
          style={{ height: '35px', width: '100%', maxWidth: '100px' }}
        />
        {!readonly && (
          <ButtonPadrao
            id="salvar-csc"
            name="salvar"
            text={t('Salvar')}
            icon={SalvarConfirmarIcon}
            type="button"
            variant="success"
            onClick={handleSalvar}
            style={{ height: '35px', width: '100%', maxWidth: '150px' }}
          />
        )}
      </Modal.Footer>
    </ModalPadrao>
  );
};

export default CertificadoDigitalModal;
