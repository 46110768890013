import { useCallback, useEffect, useState, useRef } from 'react';
import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Skeleton,
  Stack,
  Text,
  useMediaQuery,
  useToken,
  VStack,
} from '@chakra-ui/react';
import { useParams, useHistory } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';
import { toast } from 'react-toastify';
import format from 'date-fns/format';
import { ptBR } from 'date-fns/locale';
import { renderToString } from 'react-dom/server';
import { AutoSizer, List } from 'react-virtualized';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';
import { DecimalMask } from 'helpers/format/fieldsMasks';
import { capitalize } from 'helpers/format/stringFormats';
import { ImprimirPDF } from 'helpers/impressoes/imprimirPDF';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';

import { SimpleCard } from 'components/update/Form/SimpleCard';
import { TextValor } from 'components/PDV/Text/TextValor';
import { ModalCompartilhar } from 'components/Modal/ModalCompartilhar';
import Loading from 'components/Layout/Loading/LoadingPadrao';
import { EmailModal } from 'components/PDV/CompartilharDocumentosFiscais/EmailModal';

import { ItemDesmembramentoFinanceiro } from './ItemDesmembramentoFinanceiro';

type RouteParams = {
  id: string;
};

type DesmembramentoFinanceiroItem = {
  planoContaNome: string;
  clienteFornecedorNome: string;
  competencia: Date;
  valor: number;
  historico: string;
  movimentacaoFinanceiraBaixaId: string;
  conciliado: boolean;
  parcela: string;

  visualmenteConciliado: boolean;
};

type DesmembramentoFinanceiro = {
  dataHoraCadastro: Date;
  listaMovimentacoesFinanceirasBaixas: DesmembramentoFinanceiroItem[];
};

type ResumoFatura = {
  faturaId: string;
  mesAno: string;
  valorTotal: number;
  faturaSelecionada: string;
};

type Fatura = {
  formaRecebimentoNome: string;
  formaRecebimentoIcone: string;

  totalFatura: number;
  totalConciliado: number;
  diaVencimento: number;
  resumoFaturas: ResumoFatura[];
  movimentacoesFinanceirasBaixa: DesmembramentoFinanceiro[];
};

type FaturasDetalhesProps = {
  idFatura?: string;
  fecharModal?(): void;
};

export function FaturasDetalhes({
  idFatura,
  fecharModal,
}: FaturasDetalhesProps) {
  const { id } = useParams<RouteParams>();
  const idInvoiceOfTheContasAPagar = useRef<string | undefined>(idFatura);
  const history = useHistory();

  const invoiceId = id ?? idInvoiceOfTheContasAPagar.current;

  const [
    purple500,
    gray700,
    gray300,
    gray200,
    secondary300,
  ] = useToken('colors', [
    'purple.500',
    'gray.700',
    'gray.300',
    'gray.200',
    'secondary.300',
  ]);
  const [size1, size2, size4] = useToken('sizes', ['1', '2', '4']);
  const [fontSize2XS, fontSizeXS] = useToken('fontSizes', ['2xs', 'xs']);

  const [isLoading, setIsLoading] = useState(false);
  const [fatura, setFatura] = useState<Fatura>();

  const [isLargerThan1223] = useMediaQuery('(max-width: 1223px)');

  function handleFechar() {
    if (fecharModal) {
      fecharModal();
    } else {
      history.push(ConstanteRotas.FATURAS);
    }
  }

  const toggleDesmembramentoConciliado = useCallback(
    (periodoIndex: number, movimentacaoFinanceiraIndex: number) => {
      setFatura((prev) => {
        if (prev) {
          const movimentacoesFinanceirasBaixa = [
            ...prev.movimentacoesFinanceirasBaixa,
          ];

          const movimentacaoFinanceiraBaixa =
            movimentacoesFinanceirasBaixa[periodoIndex];
          const movimentacaoFinanceiraBaixaItem =
            movimentacaoFinanceiraBaixa.listaMovimentacoesFinanceirasBaixas[
              movimentacaoFinanceiraIndex
            ];

          movimentacaoFinanceiraBaixa.listaMovimentacoesFinanceirasBaixas.splice(
            movimentacaoFinanceiraIndex,
            1,
            {
              ...movimentacaoFinanceiraBaixaItem,
              conciliado: !movimentacaoFinanceiraBaixaItem.conciliado,
              visualmenteConciliado: false,
            }
          );

          movimentacoesFinanceirasBaixa.splice(
            periodoIndex,
            1,
            movimentacaoFinanceiraBaixa
          );

          return {
            ...prev,
            totalConciliado:
              prev.totalConciliado +
              (movimentacaoFinanceiraBaixaItem.conciliado
                ? movimentacaoFinanceiraBaixaItem.valor * -1
                : movimentacaoFinanceiraBaixaItem.valor),
            movimentacoesFinanceirasBaixa,
          };
        }

        return undefined;
      });
    },
    []
  );

  const toggleDesmembramentoVisualmenteConciliado = useCallback(
    (periodoIndex: number, movimentacaoFinanceiraIndex: number) => {
      setFatura((prev) => {
        if (prev) {
          const movimentacoesFinanceirasBaixa = [
            ...prev.movimentacoesFinanceirasBaixa,
          ];

          const movimentacaoFinanceiraBaixa =
            movimentacoesFinanceirasBaixa[periodoIndex];
          const movimentacaoFinanceiraBaixaItem =
            movimentacaoFinanceiraBaixa.listaMovimentacoesFinanceirasBaixas[
              movimentacaoFinanceiraIndex
            ];

          movimentacaoFinanceiraBaixa.listaMovimentacoesFinanceirasBaixas.splice(
            movimentacaoFinanceiraIndex,
            1,
            {
              ...movimentacaoFinanceiraBaixaItem,
              visualmenteConciliado: !movimentacaoFinanceiraBaixaItem.visualmenteConciliado,
            }
          );

          movimentacoesFinanceirasBaixa.splice(
            periodoIndex,
            1,
            movimentacaoFinanceiraBaixa
          );

          return {
            ...prev,
            movimentacoesFinanceirasBaixa,
          };
        }

        return undefined;
      });
    },
    []
  );

  const obterFatura = useCallback(async (idFaturaa: string | undefined) => {
    setIsLoading(true);

    const response = await api.get<void, ResponseApi<Fatura>>(
      ConstanteEnderecoWebservice.FATURA_OBTER_FATURA_COMPLETA,
      {
        params: { id: idFaturaa },
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        setFatura(response.dados);
      }
    }

    setIsLoading(false);
  }, []);

  function handleReload(novaFaturaId: string) {
    history.replace(
      SubstituirParametroRota(
        ConstanteRotas.FATURAS_DETALHES,
        'id',
        novaFaturaId
      )
    );
    obterFatura(novaFaturaId);
  }

  useEffect(
    () => {
      obterFatura(invoiceId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleImprimirRelatorioFatura = useCallback(async () => {
    const response = await api.get<void, ResponseApi<any | undefined>>(
      ConstanteEnderecoWebservice.IMPRIMI_RELATORIO_DETALHES_FATURA,
      {
        params: { id },
      }
    );
    if (response.sucesso && response.dados) {
      ImprimirPDF(response.dados, 'relatorioFatura');
    }
    if (response?.avisos) {
      response.avisos.forEach((item: string) => toast.warning(item));
    }
    setIsLoading(false);
  }, [id]);

  const handleEnviarEmailFatura = useCallback(async () => {
    EmailModal({
      id,
    });
  }, [id]);

  const handleImprimirOperacao = useCallback(() => {
    ModalCompartilhar({
      items: [
        {
          titulo: 'Imprimir relatório das faturas',
          onClickImpressao: (onClose) => {
            handleImprimirRelatorioFatura();
            onClose();
          },
          onClickEmail: (onClose) => {
            handleEnviarEmailFatura();
            onClose();
          },
        },
      ],
    });
  }, [handleImprimirRelatorioFatura, handleEnviarEmailFatura]);

  return (
    <SimpleCard p="6" boxShadow="0px 0px 6px #00000034" overflow="auto">
      <Flex alignItems="center">
        <Skeleton isLoaded={!!fatura}>
          <Avatar
            src={fatura?.formaRecebimentoIcone}
            borderRadius="full"
            boxSize="12"
          />
        </Skeleton>

        <Box ml="2.5">
          <Skeleton isLoaded={!!fatura}>
            <Text fontSize="xl" fontWeight="bold">
              {fatura?.formaRecebimentoNome}
            </Text>
          </Skeleton>
        </Box>
      </Flex>

      <Box w="full" minW="700px" overflow="auto">
        <Stack
          direction={isLargerThan1223 ? 'column' : 'row'}
          spacing="6"
          mt="6"
        >
          <Box
            w="full"
            bg="gray.50"
            borderRadius="base"
            py="4"
            px="6"
            position="relative"
            minW={{ base: '650px', md: '700px' }}
            h="180px"
          >
            {isLoading ? (
              <Loading />
            ) : (
              <Box w="full">
                <ReactApexChart
                  options={{
                    chart: {
                      zoom: {
                        enabled: false,
                      },
                      type: 'bar',
                      id: 'faturas-meses',
                      toolbar: {
                        show: false,
                      },
                      foreColor: gray700,
                      fontFamily: 'Nunito, sans-serif',
                      width: 10,
                      events: {
                        click(event, chartContext, { dataPointIndex }) {
                          const novaFatura = (fatura?.resumoFaturas || [])[
                            dataPointIndex
                          ];

                          if (novaFatura && novaFatura.faturaId) {
                            if (!id)
                              idInvoiceOfTheContasAPagar.current =
                                novaFatura.faturaId;
                            handleReload(novaFatura.faturaId);
                          }
                        },
                        dataPointMouseEnter(event: any) {
                          if (event.path) {
                            // eslint-disable-next-line no-param-reassign
                            event.path[0].style.cursor = 'pointer';
                          }
                        },
                      },
                    },
                    colors: [
                      ({ dataPointIndex }: any) => {
                        if (fatura) {
                          const { faturaId } = fatura.resumoFaturas[
                            dataPointIndex
                          ];

                          if (faturaId === invoiceId) {
                            return purple500;
                          }
                        }

                        return gray200;
                      },
                    ],
                    dataLabels: {
                      enabled: false,
                    },
                    plotOptions: {
                      bar: {
                        horizontal: false,
                        columnWidth: '14%',
                        borderRadius: 6,
                      },
                    },
                    tooltip: {
                      enabled: true,
                      theme: 'dark',
                      marker: {
                        show: false,
                      },
                      custom({ dataPointIndex }: any) {
                        if (fatura) {
                          const { valorTotal } = fatura.resumoFaturas[
                            dataPointIndex
                          ];

                          return renderToString(
                            <Flex
                              borderRadius="base"
                              py={size2}
                              px={size4}
                              color={secondary300}
                              fontSize={fontSizeXS}
                              alignItems="baseline"
                            >
                              <Text fontSize={fontSize2XS}>R$</Text>
                              <Text ml={size1}>
                                {DecimalMask(valorTotal ?? 0, 2)}
                              </Text>
                            </Flex>
                          );
                        }

                        return undefined;
                      },
                    },
                    yaxis: {
                      show: false,
                    },
                    xaxis: {
                      axisTicks: { show: false },
                      axisBorder: { color: gray300 },
                      categories: (fatura?.resumoFaturas || []).map(
                        (resumoFatura) => resumoFatura.mesAno
                      ),
                    },
                    grid: {
                      yaxis: {
                        lines: {
                          show: false,
                        },
                      },
                    },
                  }}
                  series={[
                    {
                      name: 'Total',
                      data: (fatura?.resumoFaturas || []).map(
                        (resumoFatura) => resumoFatura.valorTotal
                      ),
                    },
                  ]}
                  type="bar"
                  height="100%"
                />
              </Box>
            )}
          </Box>

          <VStack
            alignItems="flex-start"
            justifyContent="space-between"
            spacing="4"
            h="180px"
            w="full"
            maxW={isLargerThan1223 ? 'full' : '290px'}
            bg="gray.50"
            p="6"
            borderRadius="base"
          >
            <Flex alignItems="center">
              <Text
                fontSize="sm"
                fontWeight="light"
                whiteSpace="nowrap"
                lineHeight="none"
              >
                Total da fatura:
              </Text>

              <TextValor
                valor={fatura?.totalFatura || 0}
                casasDecimais={2}
                color="purple.500"
                fontSize="sm"
                fontWeight="bold"
                symbolFontSize="2xs"
                ml="1.5"
              />
            </Flex>

            <Flex alignItems="center">
              <Text
                fontSize="sm"
                fontWeight="light"
                whiteSpace="nowrap"
                lineHeight="none"
              >
                Total conciliado:
              </Text>

              <TextValor
                valor={fatura?.totalConciliado || 0}
                casasDecimais={2}
                color="orange.500"
                fontSize="sm"
                fontWeight="bold"
                symbolFontSize="2xs"
                ml="1.5"
              />
            </Flex>

            <Flex alignItems="center">
              <Text
                fontSize="sm"
                fontWeight="light"
                whiteSpace="nowrap"
                lineHeight="none"
              >
                Diferença:
              </Text>

              <TextValor
                valor={
                  (fatura?.totalFatura || 0) - (fatura?.totalConciliado || 0)
                }
                casasDecimais={2}
                color="gray.700"
                fontSize="sm"
                fontWeight="bold"
                symbolFontSize="2xs"
                ml="1.5"
              />
            </Flex>

            <Flex alignItems="center">
              <Text
                fontSize="sm"
                fontWeight="light"
                whiteSpace="nowrap"
                lineHeight="none"
              >
                Dia de vencimento:
              </Text>

              <Text
                color="gray.700"
                fontSize="sm"
                fontWeight="bold"
                ml="1.5"
                lineHeight="none"
              >
                {fatura?.diaVencimento || 0}
              </Text>
            </Flex>
          </VStack>
        </Stack>

        <Box h="500px" mt="6" position="relative">
          {isLoading && <Loading />}
          {!isLoading &&
            (fatura?.movimentacoesFinanceirasBaixa || []).length === 0 && (
              <Text ml="3" fontSize="md">
                Não foram encontradas parcelas na fatura informada.
              </Text>
            )}
          {fatura && fatura.movimentacoesFinanceirasBaixa.length > 0 && (
            <AutoSizer>
              {({ width, height: autoSizerHeight }) => (
                <List
                  width={width}
                  height={autoSizerHeight}
                  rowCount={fatura.movimentacoesFinanceirasBaixa.length}
                  rowHeight={({ index }) => {
                    const desmembramento =
                      fatura.movimentacoesFinanceirasBaixa[index];

                    let alturaPeriodo = 0; // em pixels

                    if (index === 0) {
                      alturaPeriodo += 37; // Título do primeiro período (dd MMMM/yyyy)
                    } else {
                      alturaPeriodo += 53; // Outros títulos de periodo (dd MMMM/yyyy)
                    }

                    const alturaLancamentos =
                      desmembramento.listaMovimentacoesFinanceirasBaixas
                        .length * 72; // Linha de lancamento

                    alturaPeriodo += alturaLancamentos;

                    return alturaPeriodo;
                  }}
                  rowRenderer={({ index, key, style }) => {
                    const desmembramento =
                      fatura.movimentacoesFinanceirasBaixa[index];

                    const isFirst = index === 0;

                    return (
                      <Box key={key} style={style} ml="3">
                        <Text
                          fontSize="sm"
                          fontWeight="bold"
                          color="primary.50"
                          mb="4"
                          mt={isFirst ? '0' : '4'}
                        >
                          {capitalize(
                            format(
                              new Date(desmembramento.dataHoraCadastro),
                              'dd MMMM/yyyy',
                              {
                                locale: ptBR,
                              }
                            )
                          )}
                        </Text>

                        <Box borderLeft="1px" borderColor="gray.100">
                          {desmembramento.listaMovimentacoesFinanceirasBaixas.map(
                            (
                              desmembramentoFinanceiro,
                              desmembramentoFinanceiroIndex
                            ) => {
                              const isLast =
                                desmembramento
                                  .listaMovimentacoesFinanceirasBaixas.length -
                                  1 ===
                                desmembramentoFinanceiroIndex;

                              return (
                                <ItemDesmembramentoFinanceiro
                                  key={
                                    desmembramentoFinanceiro.movimentacaoFinanceiraBaixaId
                                  }
                                  desmembramentoFinanceiro={
                                    desmembramentoFinanceiro
                                  }
                                  isLast={isLast}
                                  faturaId={invoiceId}
                                  casasDecimaisValor={2}
                                  toggleDesmembramentoConciliado={() => {
                                    toggleDesmembramentoConciliado(
                                      index,
                                      desmembramentoFinanceiroIndex
                                    );
                                  }}
                                  toggleDesmembramentoVisualmenteConciliado={() => {
                                    toggleDesmembramentoVisualmenteConciliado(
                                      index,
                                      desmembramentoFinanceiroIndex
                                    );
                                  }}
                                  obterFatura={() => {
                                    obterFatura(invoiceId);
                                  }}
                                />
                              );
                            }
                          )}
                        </Box>
                      </Box>
                    );
                  }}
                />
              )}
            </AutoSizer>
          )}
        </Box>
      </Box>

      <HStack spacing="6" mt="6" justifyContent="center">
        <Button
          variant="outline"
          onClick={handleImprimirOperacao}
          size="sm"
          minW="130px"
        >
          Compartilhar
        </Button>
        <Button
          colorScheme="aquamarine"
          size="sm"
          minW="96px"
          onClick={handleFechar}
        >
          Fechar
        </Button>
      </HStack>
    </SimpleCard>
  );
}
