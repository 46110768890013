import { useCallback, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { toast } from 'react-toastify';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';
import { sistemaApiKey } from 'services/sistemaApiKey';
import { MeioPagamentosTEF } from 'constants/enum/fiscal/meioPagamento';

import {
  DefaultInfoTef,
  FormData,
  idConfigTefDefault,
  valueDefaultInfoTef,
  FormaRecebimentoProps,
} from './validationForms';

export const useFormaRecebimento = (formMethods: UseFormReturn<FormData>) => {
  const [listPagamentoCartao, setListPagamentoCartao] = useState<
    { label: string; value: string }[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  const { setValue, handleSubmit, reset, getValues } = formMethods;

  const alterarConfigTef = async (data: FormData) => {
    const dataConfiguracao = {
      loja: data.loja,
      endereco: data.endereco,
      parametros: data.parametros,
      senhaSupervisor: data.senhaSupervisor,
      usuarioLogin: data.usuarioLogin,
      usuarioSenha: data.usuarioSenha,
      porta: data.porta,
      versaoAc: data.versaoAc,
      estabelecimento: data.estabelecimento,
      tef: data.idConfigTef,
      listaFormaPagamentoRecebimento: listPagamentoCartao.map(
        (pagamentoCartaoItem, index) => ({
          id: pagamentoCartaoItem.value,
          tef: !!(data?.tef && data?.tef[index]),
          tipoCartaoTefId: data?.tipoCartao && data?.tipoCartao[index],
          operacaoTefId: data?.operacaoTEF && data?.operacaoTEF[index],
          parcelamentoTefId:
            data?.parcelamentoTEF && data?.parcelamentoTEF[index],
        })
      ),
    };

    const response = await sistemaApiKey().post<void, ResponseApi<FormData>>(
      ConstanteEnderecoWebservice.FRENTE_CAIXA_CONFIGURACAO_TEF,
      dataConfiguracao
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }
      if (response.sucesso) {
        toast.success('Configuração alterada com sucesso');
        setIsLoading(false);
      }
    }
    setIsLoading(false);
  };

  const handleUpdateFormaRecebimento = handleSubmit(async (data) => {
    setIsLoading(true);

    await alterarConfigTef(data);
  });

  const handleConfigHasTef = async (hasTef: boolean) => {
    if (!hasTef) {
      const data = getValues();
      valueDefaultInfoTef.forEach((itemInfo) => {
        Object.keys(itemInfo).forEach((name) => {
          if (itemInfo) {
            const value = itemInfo[name as keyof DefaultInfoTef];
            setValue(name as keyof DefaultInfoTef, value);
          }
        });
      });
      await alterarConfigTef(data);
    } else {
      setValue('idConfigTef', idConfigTefDefault);
    }
  };

  const getFormaRecebimento = useCallback(
    async (
      listPagamento: {
        label: string;
        value: string;
      }[]
    ) => {
      setIsLoading(true);
      const response = await sistemaApiKey().get<
        void,
        ResponseApi<FormaRecebimentoProps>
      >(ConstanteEnderecoWebservice.FRENTE_CAIXA_OBTER_FORMA_RECEBIMENTO);

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso: string) => toast.warning(aviso));
        }
        if (response.sucesso) {
          const { dados } = response;

          const listFormaRecebimentoVinculo = listPagamento
            .map((itemFormaPagamento) => {
              const valueItemAdicionado = dados?.listaFormaPagamentoRecebimento?.find(
                (item) => item.id === itemFormaPagamento.value
              );

              return valueItemAdicionado;
            })
            .filter(Boolean);

          const tef = listFormaRecebimentoVinculo?.map((item) => item?.tef);
          const operacaoTEF = listFormaRecebimentoVinculo?.map(
            (item) => item?.operacaoTefId
          );
          const parcelamentoTEF = listFormaRecebimentoVinculo?.map(
            (item) => item?.parcelamentoTefId
          );
          const tipoCartao = listFormaRecebimentoVinculo?.map(
            (item) => item?.tipoCartaoTefId
          );

          reset({
            loja: dados?.loja,
            endereco: dados?.endereco,
            parametros: dados?.parametros,
            senhaSupervisor: dados?.senhaSupervisor,
            usuarioLogin: dados?.usuarioLogin,
            usuarioSenha: dados?.usuarioSenha,
            idConfigTef: idConfigTefDefault,
            porta: dados?.porta,
            versaoAc: dados?.versaoAc,
            estabelecimento: dados?.estabelecimento,
            operacaoTEF,
            parcelamentoTEF,
            tipoCartao,
            tef,
          });
          setIsLoading(false);
        }
      }
      setIsLoading(false);
    },
    [reset]
  );

  const obterValoresFormaRecebimento = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get<
      void,
      ResponseApi<{ nome: string; id: string; regraMeioPagamento: number }[]>
    >(ConstanteEnderecoWebservice.FORMA_RECEBIMENTO_LISTAR_SELECT_RECEBIMENTO);

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        const data = response.dados
          .filter(
            (pagamentoItem) =>
              pagamentoItem.regraMeioPagamento ===
                MeioPagamentosTEF.CartaoCreditoLink ||
              pagamentoItem.regraMeioPagamento ===
                MeioPagamentosTEF.CartaoCreditoMaquina ||
              pagamentoItem.regraMeioPagamento ===
                MeioPagamentosTEF.CartaoDebito ||
              pagamentoItem.regraMeioPagamento === MeioPagamentosTEF.PixPorLink
          )
          .map((item) => ({
            value: item.id,
            label: item.nome,
          }));

        setListPagamentoCartao(data);
        getFormaRecebimento(data);
        setIsLoading(false);
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [getFormaRecebimento]);

  useEffect(() => {
    obterValoresFormaRecebimento();
  }, [obterValoresFormaRecebimento]);

  return {
    handleConfigHasTef,
    handleUpdateFormaRecebimento,
    listPagamentoCartao,
    isLoading,
  };
};
