import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Icon,
  Flex,
  Heading,
  Text,
  Button,
  VStack,
  useMediaQuery,
  HStack,
} from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { components, InputProps } from 'react-select';
import { create, InstanceProps } from 'react-modal-promise';

import { telefoneMask } from 'helpers/format/fieldsMasks';
import { sendWhatsAppMessage } from 'services/whatsapp';

import { CompartilharWhatsappIcon } from 'icons';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import CreatableSelect from 'components/PDV/Select/CreatableSelect';
import { chakraComponents } from 'components/PDV/Select/ReactSelectIntegracao';
import ConstanteRotas from 'constants/rotas';
import { isMobile } from 'react-device-detect';
import obterOperacaoParaCompartilhar, {
  OperacaoObterParaCompartilhar,
} from 'helpers/api/Operacao/obterOperacaoParaCompartilhar';
import { useForm, yupResolver, schema } from './validationForm';

const MaskedInput = (props: InputProps) => (
  <components.Input
    {...props}
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    onInput={(e: React.FormEvent<HTMLInputElement>) => {
      if (e.currentTarget.value)
        e.currentTarget.value = telefoneMask(e.currentTarget.value);
    }}
  />
);

type FormData = {
  telefone: string;
};

interface ContatoAdicional {
  nome: string;
  telefone: string;
}

interface Cliente {
  celular?: string;
  nome: string;
  telefone?: string;
  contatosAdicionais?: ContatoAdicional[];
}

interface WhatsAppModalProps
  extends Omit<ModalProps, 'children' | 'isOpen' | 'onClose'>,
    InstanceProps<any> {
  operacaoId?: string;
  cliente: Cliente;
  linkQRcode?: string;
  messageLinkZoop?: string;
}

const WhatsAppModalComponent = ({
  cliente,
  operacaoId,
  linkQRcode,
  messageLinkZoop,
  ...props
}: WhatsAppModalProps) => {
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const formMethods = useForm({ resolver: yupResolver });

  const [createdTelefoneOption, setCreatedTelefoneOption] = useState<{
    label: string;
    value: string;
  }>();
  const [
    operacaoCompartilhar,
    setOperacaoCompartilhar,
  ] = useState<OperacaoObterParaCompartilhar>();
  const [isOpen, setIsOpen] = useState(true);
  const dataToken = new Date(`${operacaoCompartilhar?.expiracaoLink}`);
  const dataTokenValidada = `${dataToken.getDate()}/${
    new Date(dataToken).getMonth() + 1 < 10 ? '0' : ''
  }${new Date(dataToken).getMonth() + 1}/${dataToken.getFullYear()}`;

  const telefoneOptions = useMemo(() => {
    const newOptions = [];

    if (cliente.celular) {
      newOptions.push({
        value: cliente.celular,
        label: cliente.celular,
      });
    }

    if (cliente.telefone) {
      newOptions.push({
        value: cliente.telefone,
        label: cliente.telefone,
      });
    }

    if (cliente.contatosAdicionais && cliente.contatosAdicionais.length > 0) {
      newOptions.push(
        ...cliente.contatosAdicionais
          .filter((contatoAdicional) => contatoAdicional.telefone)
          .map((contatoAdicional) => ({
            value: contatoAdicional.telefone,
            label: `${contatoAdicional.telefone}${
              contatoAdicional.nome ? ` - ${contatoAdicional.nome}` : ''
            }`,
          }))
      );
    }

    return newOptions;
  }, [cliente]);

  const handleSubmit = formMethods.handleSubmit<FormData>(
    async ({ telefone }) => {
      if (telefone) {
        if (messageLinkZoop) {
          sendWhatsAppMessage({
            message: messageLinkZoop,
            phoneNumber: telefone,
          });
          setIsOpen(false);
          return;
        }
        const { hostname } = window.location;
        const pathname = ConstanteRotas.COMPARTILHAR_EXTERNO.replace(
          ':id',
          operacaoId || ''
        );
        const compartilharUrl = `${hostname}${pathname}?token=${operacaoCompartilhar?.tokenAcessoTemporario}`;
        if (linkQRcode) {
          sendWhatsAppMessage({
            message: `Olá ${cliente.nome}!\nSegue o link do cupom fiscal: ${linkQRcode}`,
            phoneNumber: telefone,
          });
        } else {
          sendWhatsAppMessage({
            message: `Olá ${cliente.nome}!\nSegue o link que contém os dados da sua compra: https://${compartilharUrl}\nLink válido até ${dataTokenValidada}`,
            phoneNumber: telefone,
          });
        }

        setIsOpen(false);
      }
    }
  );

  const latestProps = useRef({
    reset: formMethods.reset,
  });
  useEffect(() => {
    latestProps.current = {
      reset: formMethods.reset,
    };
  });

  useEffect(() => {
    async function handleObterOperacaoParaCompartilhar() {
      if (operacaoId) {
        const newOperacao = await obterOperacaoParaCompartilhar(operacaoId);
        if (newOperacao) {
          setOperacaoCompartilhar(newOperacao);
        }
      }
    }

    handleObterOperacaoParaCompartilhar();
  }, [operacaoId]);

  useEffect(() => {
    if (telefoneOptions.length > 0 && isOpen) {
      latestProps.current.reset({ telefone: telefoneOptions[0].value });
    } else {
      latestProps.current.reset();
      setCreatedTelefoneOption(undefined);
    }
  }, [telefoneOptions, isOpen]);

  const trapFocusRef = useRef<HTMLInputElement>(null);

  return (
    <ModalPadraoChakra
      isCentered
      size={isLargerThan900 ? 'xl' : 'full'}
      {...props}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      initialFocusRef={trapFocusRef}
      autoFocus={false}
    >
      <ModalContent
        marginBottom={{ base: 0, md: '3.75rem' }}
        marginTop={{ base: 0, md: '3.75rem' }}
        h="unset"
        maxW={{ base: '100%', md: '600px' }}
      >
        <ModalBody p={0}>
          <Flex
            alignItems="center"
            justifyContent="center"
            bg="aquamarine.700"
            h="135px"
            borderTopRadius="md"
          >
            <Icon as={CompartilharWhatsappIcon} boxSize="50px" color="white" />
          </Flex>
          <Flex flexDirection="column" h="full" px={8} pt={7} pb={12}>
            <FormProvider {...formMethods}>
              <Heading
                as="h1"
                fontSize="xl"
                color="aquamarine.700"
                fontWeight="semibold"
                mb={4}
              >
                Compartilhar via WhatsApp
              </Heading>

              <Text color="gray.700" fontSize="sm">
                Digite o número ou selecione um contato existente. Informe o DDD
                antes do número, exemplo: (11) 97345-3411
              </Text>

              <VStack
                as="form"
                spacing={7}
                mt={9}
                onSubmit={handleSubmit}
                w="full"
                maxW="300px"
                alignSelf="center"
              >
                <CreatableSelect
                  id="telefone"
                  name="telefone"
                  label="Telefone"
                  autoFocus={!isMobile}
                  options={[
                    ...(createdTelefoneOption ? [createdTelefoneOption] : []),
                    ...telefoneOptions,
                  ]}
                  handleCreateOption={async (inputValue) => {
                    let success = false;

                    try {
                      await schema.fields?.telefone.validate(inputValue);

                      success = true;
                    } catch (e) {
                      success = false;
                    }

                    if (success) {
                      const createdOption = {
                        label: inputValue,
                        value: inputValue,
                      };

                      setCreatedTelefoneOption(createdOption);

                      return createdOption;
                    }

                    return undefined;
                  }}
                  creatableInputTextPreffix="Enviar para "
                  components={{ ...chakraComponents, Input: MaskedInput }}
                />

                <HStack spacing="6" w="full" justifyContent="center">
                  <Button
                    variant="outline"
                    w="full"
                    maxW={{ base: 'full', md: '130px' }}
                    onClick={() => setIsOpen(false)}
                  >
                    Cancelar
                  </Button>

                  <Button
                    type="submit"
                    colorScheme="secondary"
                    w="full"
                    maxW={{ base: 'full', md: '120px' }}
                  >
                    Enviar
                  </Button>
                </HStack>
              </VStack>
            </FormProvider>
          </Flex>
        </ModalBody>
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export const WhatsAppModal = create<WhatsAppModalProps>(WhatsAppModalComponent);
