import React, { useEffect, useRef, useState } from 'react';
import {
  ModalContent,
  ModalBody,
  Flex,
  Button,
  Heading,
  Text,
  AlertDialogFooter,
  ScaleFade,
} from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';
import { ConfirmaAvisoIcon } from 'icons';
import { create } from 'react-modal-promise';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import ManterFoco from 'components/Geral/ManterFoco';

const ModalSucessoConst = ({
  titulo = 'Sucesso.',
  textoMensagem,
  onConfirm,
  autoClose = true,
}: any) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(true);

  const latestProps = useRef({ onConfirm });
  useEffect(() => {
    latestProps.current = { onConfirm };
  });

  useEffect(() => {
    const handleCloseModal = (intervalo: number) => {
      setTimeout(() => {
        setIsOpen(false);

        if (latestProps.current.onConfirm) {
          latestProps.current.onConfirm();
        }
      }, intervalo);
    };

    if (autoClose) {
      handleCloseModal(1400);
    }
  }, [autoClose]);

  return (
    <ModalPadraoChakra
      onClose={() => {
        setIsOpen(false);

        if (onConfirm) {
          onConfirm();
        }
      }}
      isOpen={isOpen}
      isCentered
      size="xl"
    >
      <ModalContent
        marginBottom={{ base: 0, sm: '3.75rem' }}
        marginTop={{ base: 0, sm: '3.75rem' }}
        h="unset"
        maxW="600px"
      >
        <ManterFoco
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <ScaleFade initialScale={1.5} in={isOpen}>
            <ModalBody p={0} h="100%">
              <Flex flexDirection="column" align="stretch" h="100%">
                <Flex justifyContent="center" h="150px" bg="secondary.400">
                  <ConfirmaAvisoIcon
                    style={{
                      fontSize: '75px',
                      fontWeight: 'bolder',
                      marginTop: '35px',
                    }}
                  />
                </Flex>

                <Flex
                  justifyContent="flex-start"
                  flexDirection="column"
                  bg="gray.50"
                  px={8}
                  py={7}
                  h="225px"
                >
                  <Heading
                    as="h2"
                    fontSize="2xl"
                    color="secondary.700"
                    fontWeight="semibold"
                    textAlign="initial"
                  >
                    {t(titulo)}
                  </Heading>
                  <Text mt={15} fontSize="sm" color="gray.700">
                    {t(textoMensagem)}
                  </Text>
                  <AlertDialogFooter>
                    <Button
                      id="closeModalSucesso"
                      bg="secondary.400"
                      onClick={() => {
                        setIsOpen(false);
                        setTimeout(() => {
                          if (onConfirm) {
                            onConfirm();
                          }
                        }, 200);
                      }}
                      style={{ width: '100px', marginTop: '40px' }}
                      ml="auto"
                      mr="auto"
                    >
                      Ok
                    </Button>
                  </AlertDialogFooter>
                </Flex>
              </Flex>
            </ModalBody>
          </ScaleFade>
        </ManterFoco>
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export const ModalSucesso = create(ModalSucessoConst);
