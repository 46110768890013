import React from 'react';
import { Flex, Icon, Text, useMediaQuery } from '@chakra-ui/react';
import { IoMdClose } from 'react-icons/io';

import { CertificadoDigitalIcon } from 'icons';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import useCertificateStatus from './hooks';

export const ContainerAvisoCertificado = () => {
  const {
    isExpired,
    textWarning,
    isLoading,
    closeAlertAndSaveInLocalStorage,
    showWarningCertificate,
    sendCertificateMessage,
  } = useCertificateStatus();

  const [isSmallerThan700] = useMediaQuery('(max-width: 700px)');

  return (
    <>
      {isLoading && <LoadingPadrao />}
      {!isLoading && showWarningCertificate && (
        <>
          <Flex
            w="full"
            padding="8px"
            fontSize={['14px', '16px']}
            bg={isExpired ? 'red.400' : 'yellow.600'}
            color={isExpired ? 'white' : 'black'}
          >
            <Flex
              w="full"
              align={['start', 'center']}
              gap="12px"
              justifyContent="center"
              flexDir={['column', 'row']}
            >
              {!isSmallerThan700 && (
                <Icon
                  as={CertificadoDigitalIcon}
                  mr="8px"
                  boxSize="28px"
                  sx={{
                    '& > g': {
                      strokeWidth: '0.95px !important',
                    },
                  }}
                />
              )}
              <Text>{textWarning}</Text>
              <Text
                fontWeight="bold"
                textDecor="underline"
                cursor="pointer"
                onClick={sendCertificateMessage}
              >
                Fale conosco para saber mais.
              </Text>
            </Flex>

            <Flex
              w="26px"
              textAlign="end"
              align="center"
              cursor="pointer"
              onClick={closeAlertAndSaveInLocalStorage}
              pr="8px"
            >
              <Icon
                as={IoMdClose}
                boxSize="24px"
                _hover={{
                  boxSize: '26px',
                }}
                color={isExpired ? 'white' : 'black'}
              />
            </Flex>
          </Flex>
        </>
      )}
    </>
  );
};
