import React, { useState, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import isPrenvent from 'helpers/layout/isPrenvent';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import { ContainerListagem } from 'components/Layout/Listagem/containerListagem';

import { yupResolver, CampoPersonalizadoInterface } from '../validationForm';
import FormCampoPersonalizado from '..';

type TParams = {
  id: string;
};

const Alterar = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [bloquearAlteracao, setBloquearAlteracao] = useState(false);
  const [dataHoraCadastro, setDataHoraCadastro] = useState('');
  const [dataHoraUltimaAlteracao, setDataHoraUltimaAlteracao] = useState('');

  const history = useHistory();

  const { id: idRota } = useParams<TParams>();

  const formMethods = useForm({
    resolver: yupResolver,
    shouldUnregister: false,
  });
  const { handleSubmit, reset, getValues, formState } = formMethods;

  const isMountedRef = useIsMountedRef();

  isPrenvent();

  const handleGetCampoPersonalizado = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<
      void,
      ResponseApi<CampoPersonalizadoInterface>
    >(ConstanteEnderecoWebservice.CAMPO_PERSONALIZADO_OBTER, {
      params: {
        id: idRota,
      },
    });

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }

      if (response?.sucesso && isMountedRef.current) {
        setBloquearAlteracao(response.dados.bloquearAlteracao);

        reset({
          ...response.dados,
          instrucao: !!response.dados.descricaoInstrucao,
        });

        setDataHoraCadastro(response.dados.dataHoraCadastro);
        setDataHoraUltimaAlteracao(response.dados.dataHoraUltimaAlteracao);
      } else {
        history.push(ConstanteRotas.CAMPO_PERSONALIZADO);
      }
    }
    if (isMountedRef.current) setIsLoading(false);
  }, [idRota, isMountedRef, reset, history]);

  const onSubmit = handleSubmit(async () => {
    setIsLoading(true);

    let data = getValues();

    data = {
      id: idRota,
      ...data,
    };

    const response = await api.put<
      void,
      ResponseApi<CampoPersonalizadoInterface>
    >(ConstanteEnderecoWebservice.CAMPO_PERSONALIZADO_ALTERAR, data);

    if (response) {
      if (response.sucesso) {
        toast.success('Campo personalizado alterado com sucesso!');

        setFormIsDirty(false);
        history.push(ConstanteRotas.CAMPO_PERSONALIZADO);
      }
      if (response.avisos) {
        response.avisos.map((aviso: string) => toast.warning(aviso));
      }
    }
    if (isMountedRef.current) setIsLoading(false);
  });

  useEffect(() => {
    handleGetCampoPersonalizado();
  }, [handleGetCampoPersonalizado]);

  useEffect(() => {
    setFormIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  return (
    <>
      <ContainerListagem
        formMethods={formMethods}
        isLoading={isLoading}
        onSubmit={onSubmit}
        formIsDirty={formIsDirty}
        dataHoraCadastro={dataHoraCadastro}
        dataHoraUltimaAlteracao={dataHoraUltimaAlteracao}
        maxWidth="full"
      >
        <FormCampoPersonalizado bloquearAlteracao={bloquearAlteracao} />
      </ContainerListagem>
    </>
  );
};
export default Alterar;
