import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Tag, Box } from '@chakra-ui/react';
import { ActionMeta, ValueType } from 'react-select';

import { multiOptionsWithCheckboxComponent } from 'components/PDV/Select/ReactSelectIntegracao';
import { SelectFieldProps } from 'components/PDV/Select/SelectPadrao';
import SelectInput, {
  findOptions,
  getValue,
} from 'components/PDV/Select/SelectPadrao/Input';
import CampoContainer from 'components/PDV/Geral/CampoContainer';

interface SelectOptions {
  label: string;
  value: string | number;
}

interface SelectProps extends SelectFieldProps {
  changeBackgroundOption?: boolean;
  optionBackgroundColor?: string;
  optionColor?: string;
  isRequired?: boolean;
}

export const SelectMulti = ({
  name,
  label,
  helperText,
  id,
  options,
  onChangeEffect,
  onSelect,
  colSpan,
  handleChange,
  optionBackgroundColor = 'gray.600',
  optionColor = 'white',
  colStart,
  colEnd,
  rowSpan,
  changeBackgroundOption = false,
  rowStart,
  rowEnd,
  onClick,
  multiValueBg,
  asControlledByObject,
  topRightElement,
  errorPropName,
  errorText,
  isDisabled,
  textLabelSelectAll = 'Todas as operações',
  shouldAppearTheAddress,
  isRequired,
  ...rest
}: SelectProps) => {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const optionsSelecionadas =
    findOptions(getValue(watch(`${name}`), true), options) || [];

  const [selected, setSelected] = useState<SelectOptions[]>(
    optionsSelecionadas
  );

  const selectAllOption = {
    value: '<SELECT_ALL>',
    label: textLabelSelectAll,
  };

  const isSelectAllSelected = () =>
    optionsSelecionadas.length === options.length;

  const isOptionSelected = (option: SelectOptions) =>
    optionsSelecionadas.some(({ value }) => value === option.value) ||
    isSelectAllSelected();

  const getOptions = () => [selectAllOption, ...options];

  const getValues = () =>
    isSelectAllSelected() ? [selectAllOption] : optionsSelecionadas;

  const onChange = (
    newValue: ValueType<SelectOptions>,
    actionMeta: ActionMeta<SelectOptions>
  ) => {
    const { action, option, removedValue } = actionMeta;
    if (action === 'select-option' && option?.value === selectAllOption.value) {
      setSelected(options);
    } else if (
      (action === 'deselect-option' &&
        option?.value === selectAllOption.value) ||
      (action === 'remove-value' &&
        removedValue?.value === selectAllOption.value)
    ) {
      setSelected([]);
    } else if (
      actionMeta.action === 'deselect-option' &&
      isSelectAllSelected()
    ) {
      setSelected(options.filter(({ value }) => value !== option?.value));
    } else {
      setSelected((newValue as SelectOptions[]) || []);
    }
  };

  const multiValueContainer = {
    MultiValueContainer: ({ selectProps, data }: any) => {
      const values = selectProps.value;

      if (changeBackgroundOption) {
        if (values) {
          return (
            <Tag
              borderRadius="15px"
              pl="15px"
              pr="15px"
              bg={optionBackgroundColor}
              color={optionColor}
              m="0.125rem"
            >
              {data?.label}
            </Tag>
          );
        }

        return <></>;
      }
      if (values) {
        return (
          <Box>
            {values[values.length - 1].label === data?.label ? (
              <Box>{data?.label}</Box>
            ) : (
              <Box>{`${data?.label}, `}</Box>
            )}
          </Box>
        );
      }

      return <></>;
    },
  };

  useEffect(() => {
    setValue(
      `${name}`,
      selected.map((item) => item.value)
    );
  }, [name, selected, setValue]);

  return (
    <CampoContainer
      id={id}
      name={name}
      label={label}
      helperText={helperText}
      colSpan={colSpan}
      colStart={colStart}
      rowEnd={rowEnd}
      rowSpan={rowSpan}
      errorText={errors[name]?.message}
      isInvalid={errors[name]}
      isRequired={isRequired}
    >
      <SelectInput
        {...rest}
        isMulti
        closeMenuOnSelect={false}
        value={getValues()}
        isOptionSelected={isOptionSelected}
        onChange={onChange}
        hideSelectedOptions={false}
        styles={{
          valueContainer: (provided) => ({
            ...provided,
            minHeight: '32px',
            overflowY: 'auto',
            padding: '0 6px',
          }),
        }}
        isSearchable
        isDisabled={isDisabled}
        components={{
          ...multiOptionsWithCheckboxComponent,
          ...multiValueContainer,
        }}
        name={name}
        options={getOptions()}
      />
    </CampoContainer>
  );
};
