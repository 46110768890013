import { memo } from 'react';

import { usePlanoContasVisaoCaixaContext } from 'store/PlanoContas/PlanoContasVisaoCaixa';

import { ItemVisaoCaixa } from './Item';

interface ItemsContainerProps {
  tipoPlanoConta: number;
  paiId?: string;
  nivel?: number;
  paiIsAtivo?: boolean;
}

export const ItemsVisaoCaixaContainer = memo(
  ({
    paiId,
    nivel = 2,
    paiIsAtivo = true,
    tipoPlanoConta,
  }: ItemsContainerProps) => {
    const { obterPlanoContasPeloTipo } = usePlanoContasVisaoCaixaContext();

    const planosConta = obterPlanoContasPeloTipo(tipoPlanoConta, paiId, nivel);

    return (
      <>
        {planosConta.map((planoConta) => (
          <ItemVisaoCaixa
            key={planoConta.id}
            paiIsAtivo={paiIsAtivo}
            nivel={nivel}
            item={planoConta}
            tipoPlanoContaObrigatorio={tipoPlanoConta}
          >
            <ItemsVisaoCaixaContainer
              paiIsAtivo={planoConta.ativo}
              paiId={planoConta.id}
              nivel={nivel + 1}
              tipoPlanoConta={tipoPlanoConta}
            />
          </ItemVisaoCaixa>
        ))}
      </>
    );
  }
);
