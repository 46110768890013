import React, { ReactNode, useCallback, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useFormContext } from 'react-hook-form';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import OptionType, { OptionProps } from 'types/optionType';

import AsyncSelect from 'components/PDV/Select/AsyncSelectPadrao';

export interface ListaCidadesInterface {
  id: number;
  nome: string;
  codigoIBGE: string;
  estadoNome: string;
  estadoSigla: string;
  paisId?: number;
}

export interface ListaValoresSelect {
  label: string;
  value: number;
}

export type SelectFieldProps = {
  onChangeEffect?: (option: OptionType | Array<OptionType> | null) => void;
  onSelect?: (option: OptionType | Array<OptionType> | null) => void;
  onClick?: () => void;
  asControlledByObject?: boolean;
  topRightElement?: ReactNode;
  handleChange?: () => void;
  isClearable?: boolean;
  color?: string;
  getSelectedCidade?: (cidadeId: number) => void;
  bgHelperText?: string;
  colorHelperText?: string;
  paisKey?: string;
  isRequired?: boolean;
  label?: string;
  name: string;
  id: string;
  isDisabled?: boolean;
  placeholder?: string;
};

const SelectCidade = ({
  name,
  label,
  getSelectedCidade,
  id,
  onSelect,
  paisKey = 'pais',
  asControlledByObject = true,
  ...rest
}: SelectFieldProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    watch,
    formState: { errors },
  } = useFormContext();

  const errorText = errors[name]?.message;

  const valuePais = watch(paisKey);
  const contryId =
    valuePais?.value === undefined ? valuePais : valuePais?.value;

  const getOptionSelected = (event: OptionType) => {
    setIsLoading(true);
    const newEvent = event as OptionProps;
    if (getSelectedCidade) {
      getSelectedCidade(newEvent?.value as number);
    }
    setIsLoading(false);
  };

  const handleGetOptions = useCallback(
    async (newInputValue?: string) => {
      setIsLoading(true);
      const response = await api.get<
        void,
        ResponseApi<ListaCidadesInterface[]>
      >(ConstanteEnderecoWebservice.CIDADE_LISTAR_CACHE, {
        params: {
          nome: newInputValue,
          paisId: contryId,
        },
      });

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }

        if (response.sucesso) {
          if (
            response?.dados.length === 1 &&
            !newInputValue &&
            contryId &&
            getSelectedCidade
          ) {
            const firstCidade = response?.dados[0];
            getSelectedCidade(firstCidade.id);
          }
          setIsLoading(false);
          return response?.dados.map((item: ListaCidadesInterface) => {
            return {
              label: `${item.nome} - ${item.estadoSigla}`,
              value: item.id,
            };
          });
        }
        setIsLoading(false);
      }
      setIsLoading(false);

      return [];
    },
    [contryId, getSelectedCidade]
  );

  const contryHasUpdated = useCallback(() => {
    const isContryBrazil = contryId === 1;
    if (!isContryBrazil) {
      handleGetOptions();
    }
  }, [contryId, handleGetOptions]);

  useEffect(() => {
    contryHasUpdated();
  }, [contryHasUpdated]);

  return (
    <AsyncSelect
      {...rest}
      id={id}
      isLoading={isLoading}
      name={name}
      label={label}
      errorText={errorText}
      asControlledByObject={asControlledByObject}
      onOptionSelect={getOptionSelected}
      handleGetOptions={handleGetOptions}
    />
  );
};

export default SelectCidade;
