import { GridItem } from '@chakra-ui/react';

import Input from 'components/PDV/Input';
import CheckboxAtivoInativo from 'components/update/Checkbox/CheckboxAtivoInativo';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';

interface UncontrolledFormInterface {
  readonly?: boolean;
}

export const UncontrolledForm = ({ readonly }: UncontrolledFormInterface) => {
  return (
    <SimpleGridForm>
      <GridItem colSpan={{ base: 12, md: 10, lg: 10 }}>
        <Input
          id="descricao"
          name="descricao"
          label="Descrição"
          autoFocus
          isRequired
          placeholder="Digite um nome para diferenciar o tamanho"
          maxLength={15}
          isDisabled={readonly}
        />
      </GridItem>
      <GridItem colSpan={{ base: 12, md: 2, lg: 2 }}>
        <CheckboxAtivoInativo
          id="ativo"
          name="ativo"
          label="Status"
          isDisabled={readonly}
        />
      </GridItem>
    </SimpleGridForm>
  );
};
