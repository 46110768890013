import React, { useState, useMemo } from 'react';
import {
  useDisclosure,
  ModalProps,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Grid,
  Button,
  Flex,
  Icon,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';

import { getOptionByEnum } from 'helpers/format/getOptionsByEnum';
import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';

import { SalvarConfirmarIcon } from 'icons';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import Select from 'components/Select/SelectPadrao';

import { yupResolver } from './validationForm';

const defaultCoalescingValue = { value: null, label: '' };

export const possibleOptions = [
  getOptionByEnum(
    IdentificacaoTipoOperacaoEnum,
    IdentificacaoTipoOperacaoEnum.BRINDE
  ) ?? defaultCoalescingValue,
  getOptionByEnum(
    IdentificacaoTipoOperacaoEnum,
    IdentificacaoTipoOperacaoEnum.TRANSFERENCIA_PRODUTO_SAIDA
  ) ?? defaultCoalescingValue,
  getOptionByEnum(
    IdentificacaoTipoOperacaoEnum,
    IdentificacaoTipoOperacaoEnum.DEVOLUCAO_COMPRA
  ) ?? defaultCoalescingValue,
  getOptionByEnum(
    IdentificacaoTipoOperacaoEnum,
    IdentificacaoTipoOperacaoEnum.PERDA
  ) ?? defaultCoalescingValue,
  getOptionByEnum(
    IdentificacaoTipoOperacaoEnum,
    IdentificacaoTipoOperacaoEnum.AJUSTE_ESTOQUE_SAIDA
  ) ?? defaultCoalescingValue,
  getOptionByEnum(
    IdentificacaoTipoOperacaoEnum,
    IdentificacaoTipoOperacaoEnum.DEVOLUCAO
  ) ?? defaultCoalescingValue,
];

type FormData = { identificacaoTipoOperacao: number };

interface ModalAdicionarOperacaoProps
  extends InstanceProps<FormData>,
    Omit<ModalProps, 'children' | 'isOpen' | 'onClose'> {
  identificacoesTipoOperacaoAlreadyAdded?: number[];
}

const ModalAdicionarOperacao = ({
  onResolve,
  identificacoesTipoOperacaoAlreadyAdded,
  ...props
}: ModalAdicionarOperacaoProps) => {
  const formMethods = useForm<FormData>({ resolver: yupResolver });

  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = formMethods.handleSubmit(
    ({ identificacaoTipoOperacao }) => {
      setIsLoading(true);

      onResolve({ identificacaoTipoOperacao });
    }
  );

  const options = useMemo(() => {
    const newOptions = possibleOptions;

    return newOptions.filter(
      ({ value }) =>
        value &&
        (!identificacoesTipoOperacaoAlreadyAdded ||
          !identificacoesTipoOperacaoAlreadyAdded.includes(value))
    );
  }, [identificacoesTipoOperacaoAlreadyAdded]);

  return (
    <ModalPadraoChakra
      isCentered
      size="xl"
      {...props}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalContent h="unset" mx={{ base: 6, md: 8 }} w="full" maxW="600px">
        {isLoading && <LoadingPadrao />}
        <ModalCloseButton />
        <ModalBody p={{ base: 6, md: 8 }} pt="10">
          <FormProvider {...formMethods}>
            <Grid
              as="form"
              onSubmit={handleSubmit}
              templateColumns={{ base: '1fr', sm: '1fr auto' }}
              gap={{ base: 6, md: 8 }}
            >
              <Select
                id="identificacaoTipoOperacao"
                name="identificacaoTipoOperacao"
                defaultValue={null}
                required
                label="Tipo da operação"
                placeholder="Informe o tipo da operação"
                options={options}
                control={formMethods.control}
                error={formMethods.formState.errors.identificacaoTipoOperacao}
              />

              <Flex
                h="full"
                w="full"
                alignItems="flex-end"
                justifyContent="flex-end"
              >
                <Button
                  type="submit"
                  id="adicionarOperacao-confirmar"
                  colorScheme="aquamarine"
                  borderRadius="md"
                  leftIcon={<Icon as={SalvarConfirmarIcon} fontSize="lg" />}
                >
                  Confirmar
                </Button>
              </Flex>
            </Grid>
          </FormProvider>
        </ModalBody>
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export default create<ModalAdicionarOperacaoProps>(ModalAdicionarOperacao);
