import { useCallback } from 'react';
import { Text, Box, Flex, Image } from '@chakra-ui/react';

import { useSistemaAtual } from 'helpers/data/useSistemaAtual';
import { formatDateHourMinute } from 'helpers/format/formatStringDate';
import { testIfIsUrl } from 'helpers/validation/imagesAttachment';

type ImpressaoFichaProps = {
  tituloWatch: JSX.Element;
  mensagemWatch: JSX.Element;
  imagem?: string;
  isImprimirSenha?: boolean;
  scaleImg?: string;
};

export const ImpressaoCupomSmartPos = ({
  tituloWatch,
  mensagemWatch,
  isImprimirSenha,
  imagem = '',
  scaleImg,
}: ImpressaoFichaProps) => {
  const { nomeExibicao } = useSistemaAtual();

  const imagemFoiSelecionada = !!imagem;

  const formattedValue = useCallback((value: string) => {
    if (!testIfIsUrl(value)) return `data:image/jpeg;base64,${value}`;

    return value;
  }, []);

  return (
    <Flex
      bg="white"
      w="210px"
      fontFamily="'Monospac821 BT', sans-serif"
      flexDir="column"
      boxShadow="0px 0px 6px #00000029"
      pt={imagemFoiSelecionada ? '24px' : '32px'}
      pb="18px"
      px="10px"
      fontSize="10px"
    >
      {imagemFoiSelecionada && (
        <Flex w="190px" justify="center" mb="12px" filter="grayscale(1)">
          <Image
            src={formattedValue(imagem)}
            alt="logo impressão"
            id="imagemPreview"
            objectFit="cover"
            w={scaleImg}
          />
        </Flex>
      )}
      {tituloWatch && (
        <Text mb="6px" textAlign="center">
          {tituloWatch}
        </Text>
      )}
      <Text
        color="gray.700"
        borderWidth="1px"
        borderStyle="solid"
        borderColor="gray.700"
        borderRadius="4px"
        padding="6px"
        textAlign="center"
      >
        EMISSÃO: {formatDateHourMinute(new Date())}H
      </Text>
      <Text
        color="black"
        mt="8px"
        fontWeight="bold"
        borderBottomWidth="2px"
        borderStyle="solid"
        borderColor="gray.500"
      >
        ITENS
      </Text>

      <Text mt="8px" color="gray.700">
        ÁGUA COM GÁS - 500 ML
      </Text>
      <Flex justifyContent="space-between">
        <Text>2 x 7,00</Text>
        <Text>14,00</Text>
      </Flex>
      <Text>------------------------------</Text>
      <Text mt="4px" color="gray.700">
        HAMBÚRGUER ARTESANAL
      </Text>
      <Flex justifyContent="space-between">
        <Text>1 x 36,00</Text>
        <Text>36,00</Text>
      </Flex>
      <Text>------------------------------</Text>
      <Text textAlign="end" color="gray.700" fontWeight="bold">
        TOTAL: R$ 23,00
      </Text>
      <Text
        color="black"
        fontWeight="bold"
        borderBottomWidth="2px"
        borderStyle="solid"
        borderColor="gray.500"
      >
        PAGAMENTO
      </Text>

      <Box mt="10px">
        <Flex justifyContent="space-between" alignItems="center">
          <Text>DINHEIRO</Text>
          <Text>100,00</Text>
        </Flex>
        <Text>------------------------------</Text>
        <Text textAlign="end" color="gray.700" fontWeight="bold" mb="18px">
          TROCO: R$ 50,00
        </Text>
      </Box>

      {isImprimirSenha && (
        <Box
          h="fit-content"
          py="12px"
          borderWidth="1px"
          borderColor="gray.700"
          borderStyle="solid"
          borderRadius="4px"
        >
          <Text textAlign="center" fontSize="10px">
            SENHA:
          </Text>
          <Text textAlign="center" fontSize="32px" fontWeight="extrabold">
            001
          </Text>
        </Box>
      )}

      {mensagemWatch && (
        <Text
          textAlign="center"
          justifyContent="center"
          borderTopWidth={isImprimirSenha ? '0px' : '1px'}
          borderStyle="solid"
          borderColor="gray.500"
          pt="8px"
        >
          {mensagemWatch}
        </Text>
      )}
      <Flex
        flexDir="column"
        justify="flex-start"
        borderTopWidth="1px"
        borderBottomWidth="1px"
        borderStyle="solid"
        borderColor="gray.500"
        py="4px"
        mt="8px"
      >
        <Text>USUÁRIO: RODRIGO</Text>
        <Text>POS 1 - 000000000000000</Text>
      </Flex>
      <Text mb="18px" mt="6px" color="gray.700" textAlign="center">
        Sistema {nomeExibicao}
      </Text>
    </Flex>
  );
};
