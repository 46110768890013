import React from 'react';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

import { ListarCampoPersonalizado } from '../pages/CampoPersonalizado/Listar';
import CadastrarCampoPersonalizado from '../pages/CampoPersonalizado/Formulario/Cadastrar';
import AlterarCampoPersonalizado from '../pages/CampoPersonalizado/Formulario/Alterar';
import VisualizarCampoPersonalizado from '../pages/CampoPersonalizado/Formulario/Visualizar';

const CamposPersonalizadosRoutes = [
  <LayoutGuard
    key="campos-personalizados-listar"
    permissaoFuncionalidade={
      ConstanteFuncionalidades.CAMPO_PERSONALIZADO_LISTAR
    }
    component={ListarCampoPersonalizado}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Campos Personalizados',
      },
    ]}
    path={ConstanteRotas.CAMPO_PERSONALIZADO}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="campos-personalizados-cadastrar"
    permissaoFuncionalidade={
      ConstanteFuncionalidades.CAMPO_PERSONALIZADO_CADASTRAR
    }
    component={CadastrarCampoPersonalizado}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Campos Personalizados',
        path: ConstanteRotas.CAMPO_PERSONALIZADO,
      },
      {
        title: 'Novo',
      },
    ]}
    path={ConstanteRotas.CAMPO_PERSONALIZADO_CADASTRAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="campos-personalizados-alterar"
    permissaoFuncionalidade={
      ConstanteFuncionalidades.CAMPO_PERSONALIZADO_ALTERAR
    }
    component={AlterarCampoPersonalizado}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Campos Personalizados',
        path: ConstanteRotas.CAMPO_PERSONALIZADO,
      },
      {
        title: 'Editar',
      },
    ]}
    path={ConstanteRotas.CAMPO_PERSONALIZADO_ALTERAR}
    exact
    meta={{ auth: true }}
  />,
  <LayoutGuard
    key="campos-personalizados-visualizar"
    permissaoFuncionalidade={
      ConstanteFuncionalidades.CAMPO_PERSONALIZADO_VISUALIZAR
    }
    component={VisualizarCampoPersonalizado}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Campos Personalizados',
        path: ConstanteRotas.CAMPO_PERSONALIZADO,
      },
      {
        title: 'Visualizar',
      },
    ]}
    path={ConstanteRotas.CAMPO_PERSONALIZADO_VISUALIZAR}
    exact
    meta={{ auth: true }}
  />,
];

export default CamposPersonalizadosRoutes;
