import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export const configVisibilidade = {
  modalidadeIcms: [0, 10, 20, 51, 70, 90, 900],
  icmsBaseCalculo: [0, 10, 20, 51, 70, 90, 900],
  icmsReducaoBaseCalculo: [20, 51, 70, 90, 900],
  icmsAliquota: [0, 10, 20, 51, 70, 90, 900],
  icmsValor: [0, 10, 20, 51, 70, 90, 900],

  icmsValorOperacao: [51],
  icmsDiferidoPercentual: [51],
  icmsDiferidoValor: [51],

  fcpBaseCalculo: [0, 10, 20, 51, 70, 90],
  fcpAliquota: [0, 10, 20, 51, 70, 90],
  fcpValor: [0, 10, 20, 51, 70, 90],

  icmsEfetivoReducao: [60, 500],
  icmsEfetivoBaseCalculo: [60, 500],
  icmsEfetivoAliquota: [60, 500],
  icmsEfetivoValor: [60, 500],

  icmsDesoneradoValor: [20, 30, 40, 41, 50, 70, 90],
  icmsMotivoDesoneracao: [20, 30, 40, 41, 50, 70, 90],

  icmsAproveitamentoAliquota: [101, 201, 900],
  icmsAproveitamentoValor: [101, 201, 900],
} as any;

const schema = yup.object().shape({
  cfop: yup.string().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  cstCsosn: yup
    .number()
    .nullable()
    .default(null)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  produto: yup
    .object()
    .nullable()
    .default(null)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  quantidade: yup
    .number()
    .nullable()
    .default(0.01)
    .min(0.01, ConstanteMensagemValidacao.VALOR_MAIOR_QUE_0)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  enquadramentoLegal: yup.number().when('ipiCst', {
    is: (val) => val >= 0,
    then: yup
      .number()
      .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
      .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  }),
  pisCst: yup
    .number()
    .nullable()
    .default(null)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  cofinsCst: yup
    .number()
    .nullable()
    .default(null)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  valorSeguro: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  aliquotaConsumidorFinal: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  cideAliquota: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  cideBaseCalculo: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  cideValor: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  cofinsAliquota: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  cofinsValor: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  fcpStAliquota: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  fcpStRetidoAliquota: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  fcpUfDestinoBaseCalculo: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  fcpUfDestinoPercentual: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  fcpUfDestinoValor: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  icmsAliquotaInterestadual: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  icmsDiferidoPercentual: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  icmsDiferidoValor: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  icmsStAliquota: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  icmsStBaseCalculo: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  icmsStMva: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  icmsStReducaoBaseCalculo: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  icmsStValor: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  icmsSubstituto: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  icmsUfDestinoBaseCalculo: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  icmsUfDestinoPercentual: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  icmsUfDestinoValor: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  icmsValorOperacao: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  impostoImportacao: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  indicadorValorTotalNota: yup
    .number()
    .default(1)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  calcularTributosMunEstFed: yup.boolean().default(true),
  pisBaseCalculo: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  quantidadeFaturada: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  valorDesconto: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  valorOutrasDespesas: yup
    .number()
    .default(0)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  modalidadeIcmsSt: yup
    .number()
    .nullable()
    .default(4)
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);

export const useForm = useFormInstance;
