import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export interface ListaLocalEstoqueProps {
  id: string;
  nome: string;
  padraoSistema: boolean;
}

export type TransferirEstoqueProps = {
  loja: string | null;
  localEstoque: string | null;
};

export type LojasResponseProps = {
  id: string;
  fantasia: string;
  listaLocalEstoque: ListaLocalEstoqueProps[];
};

export type LojasProps = {
  value: string;
  label: string;
  listaLocalEstoque: ListaLocalEstoqueProps[];
};

const schema = yup.object().shape({
  localEstoque: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);
