import { useEffect } from 'react';

export function useTabObserver({
  tabIndex,
  onTabSelected,
  conditionToTrigger,
}: {
  tabIndex: string;
  onTabSelected: () => void;
  conditionToTrigger: boolean;
}) {
  useEffect(() => {
    const tabSituacao = document?.querySelector(`[data-index="${tabIndex}"]`);

    if (!tabSituacao) return undefined;

    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'aria-selected'
        ) {
          const tabEstaSelecionada =
            (mutation.target as HTMLElement).getAttribute('aria-selected') ===
            'true';

          if (conditionToTrigger && tabEstaSelecionada) {
            onTabSelected();
          }
        }
      });
    });

    observer.observe(tabSituacao, { attributes: true });

    return () => {
      observer.disconnect();
    };
  }, [tabIndex, onTabSelected, conditionToTrigger]);
}
