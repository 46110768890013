import React from 'react';
import { Route } from 'react-router-dom';

import ConstanteFuncionalidades from 'constants/permissoes';
import { MetasComissoesCadastroProvider } from 'store/MetasComissoes/MetaComissoesCadastro';

import { EtapasMetasComissoes } from 'pages/Vendas/MetasComissoes/Etapas';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import { MetasComissoes } from '../pages/Vendas/MetasComissoes';

export const MetasComissoesRoutes = [
  <Route path={ConstanteRotas.METAS_E_COMISSOES} key="metas-comissoes-listar">
    <MetasComissoesCadastroProvider>
      <LayoutGuard
        key="metas-comissoes-listar"
        component={MetasComissoes}
        breadcrumb={[
          {
            title: 'Vendas',
          },
          {
            title: 'Metas e comissões',
          },
        ]}
        path={ConstanteRotas.METAS_E_COMISSOES}
        exact
        permissaoFuncionalidade={ConstanteFuncionalidades.METAS_COMISSAO}
        meta={{ auth: true }}
      />
    </MetasComissoesCadastroProvider>
  </Route>,
  <LayoutGuard
    key="metas-comissoes-cadastrar"
    permissaoFuncionalidade={ConstanteFuncionalidades.METAS_COMISSAO}
    component={EtapasMetasComissoes}
    breadcrumb={[
      {
        title: 'Vendas',
      },
      {
        title: 'Metas e comissões',
        path: ConstanteRotas.METAS_E_COMISSOES,
      },
      {
        title: 'Configuração',
      },
    ]}
    path={ConstanteRotas.CADASTRAR_METAS_E_COMISSOES}
    exact
    meta={{ auth: true }}
  />,
];
