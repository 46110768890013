import { useState } from 'react';
import { VStack, HStack, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import api, { ResponseApi } from 'services/api';

import Input from 'components/Autenticacao/Input';
import ButtonSubmit from 'components/Autenticacao/Button/Submit';
import ButtonLink from 'components/Autenticacao/Button/Link';

import { useForm, yupResolver } from './validationForm';

type FormData = {
  nomeConta: string;
};

export const Dominio = () => {
  const [isLoading, setIsLoading] = useState(false);

  const isMountedRef = useIsMountedRef();

  const history = useHistory();

  const {
    handleSubmit,
    control,
    formState: { isValid, errors },
  } = useForm({
    resolver: yupResolver,
    mode: 'onChange',
  });

  const nomeContaErrorMessage =
    (errors?.nomeConta?.message as string | undefined) || undefined;
  const nomeContaIsInvalid = !!nomeContaErrorMessage;
  const formIsInvalid = !isValid;

  const onSubmit = handleSubmit<FormData>(async (data) => {
    setIsLoading(true);

    const dominio = `${window.location.host.replace('app', data.nomeConta)}`;

    const response = await api.get<void, ResponseApi<boolean>>(
      ConstanteEnderecoWebservice.CLIENTE_MULTIEMPRESA_VALIDAR_DOMINIO,
      {
        params: { dominio },
      }
    );
    if (response) {
      if (response.sucesso) {
        if (process.env.NODE_ENV !== 'development') {
          window.location.href = `https://${dominio}/login`;
        } else {
          history.push(ConstanteRotas.LOGIN);
        }

        return;
      }

      if (response.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }
    }
    if (isMountedRef.current) setIsLoading(false);
  });

  const handlePushRecuperarDominio = () => {
    setIsLoading(true);

    history.push(ConstanteRotas.RECUPERAR_DOMINIO);

    if (isMountedRef.current) setIsLoading(false);
  };

  return (
    <VStack spacing={9}>
      <VStack as="form" w="full" spacing={8} onSubmit={onSubmit}>
        <HStack
          w="full"
          spacing={2}
          alignItems="flex-end"
          _focusWithin={{
            '& > span': {
              color: nomeContaIsInvalid ? 'red.300' : 'aquamarine.400',
            },
          }}
        >
          <Input
            id="nomeConta"
            name="nomeConta"
            label="Nome da conta"
            defaultValue=""
            autoFocus
            control={control}
            errorText={nomeContaErrorMessage || ''}
            isInvalid={nomeContaIsInvalid}
            isDisabled={isLoading}
            maxLength={255}
          />
          <Text
            as="span"
            transition="all"
            fontSize={{ base: 'sm', xl: 'md' }}
            color={nomeContaIsInvalid ? 'red.300' : 'white'}
            lineHeight="none"
            userSelect="none"
          >
            {`${window.location.host.replace('app', '')}`}
          </Text>
        </HStack>

        <ButtonSubmit
          isLoading={isLoading}
          isDisabled={isLoading || formIsInvalid}
          isInvalid={formIsInvalid || isLoading}
        >
          Continuar
        </ButtonSubmit>
      </VStack>

      <ButtonLink onClick={handlePushRecuperarDominio} isDisabled={isLoading}>
        Não sei o nome da minha conta
      </ButtonLink>
    </VStack>
  );
};
