import { isIOS } from 'helpers/layout/isIOS';
import { isIOS as reactDeviceDetectIsIOS } from 'react-device-detect';

export async function blurIOS(elseFunc?: () => any) {
  if (isIOS() || reactDeviceDetectIsIOS) {
    await (() => new Promise((resolve) => setTimeout(resolve, 200)))();

    if (document.activeElement) {
      (document.activeElement as HTMLInputElement).blur();
    }
  } else if (elseFunc) {
    elseFunc();
  }
}
