import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteRotas from 'constants/rotas';
import isPrenvent from 'helpers/layout/isPrenvent';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { ModalAtencao } from 'components/Modal/ModalAtencao';
import { ContainerListagem } from 'components/Layout/Listagem/containerListagem';

import { useForm, yupResolver } from '../validationForm';
import { UncontrolledFormRefProps, UncontrolledForm } from '..';

interface VendedorProps {
  id: string;
  genero: number;
  nome: string;
  foto: string;
  email: string;
  telefone: string;
  comissao: number;
  metaMensal: number;
  dataNascimento: string;
  ativo: boolean;
  dataHoraCadastro: string;
  dataHoraUltimaAlteracao: string;
}

type TParams = { id: string };

const Alterar = () => {
  isPrenvent();

  const { id: idRota } = useParams<TParams>();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [vendedorId, setVendedorId] = useState<string>();
  const [lojasIsDirty, setLojasIsDirty] = useState(false);
  const [dataHoraCadastro, setDataHoraCadastro] = useState('');
  const [dataHoraUltimaAlteracao, setDataHoraUltimaAlteracao] = useState('');

  const UncontrolledFormRef = useRef<UncontrolledFormRefProps>();

  const formMethods = useForm({
    resolver: yupResolver,
  });

  const {
    reset,
    handleSubmit: onSubmit,
    formState: { isDirty },
  } = formMethods;

  const getVendedor = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<void, ResponseApi<VendedorProps>>(
      ConstanteEnderecoWebservice.VENDEDOR_OBTER,
      {
        params: { id: idRota },
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        const { dados } = response;

        reset({
          ...dados,
          dataNascimento: dados.dataNascimento?.split('T')[0] || null,
        });

        setVendedorId(dados.id);
        setDataHoraCadastro(dados.dataHoraCadastro);
        setDataHoraUltimaAlteracao(dados.dataHoraUltimaAlteracao);

        await UncontrolledFormRef.current?.getLojas(dados.id);
      } else {
        history.push(ConstanteRotas.VENDEDOR);
      }
    }

    setIsLoading(false);
  }, [history, idRota, reset]);

  const handleSubmit = onSubmit(async (data: VendedorProps) => {
    setIsLoading(true);

    const dadosVendedorAlteracao = {
      ...data,
      id: vendedorId,
      dataNascimento: data?.dataNascimento || null,
    };

    const listLojas = UncontrolledFormRef.current?.lojas;
    const lojasSelecionadasId =
      listLojas?.filter(({ selecionado }) => selecionado).map(({ id }) => id) ||
      [];

    const hasLojaSelecionada = lojasSelecionadasId.length > 0;

    if (!hasLojaSelecionada) {
      ModalAtencao({
        text: 'É obrigatório vincular o vendedor em pelo menos uma loja.',
        callback: () => {
          if (UncontrolledFormRef.current)
            UncontrolledFormRef.current?.abrirModalLojas();
        },
      });

      setIsLoading(false);
      return;
    }

    const response = await api.put<void, ResponseApi>(
      ConstanteEnderecoWebservice.VENDEDOR_ALTERAR,
      {
        lojasSelecionadas: lojasSelecionadasId,
        vendedorViewModel: dadosVendedorAlteracao,
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso) {
        toast.success('O cadastro foi alterado com sucesso.');

        setFormIsDirty(false);
        setLojasIsDirty(false);

        history.push(ConstanteRotas.VENDEDOR);
      }
    }

    setIsLoading(false);
  });

  useEffect(() => {
    getVendedor();
  }, [getVendedor]);

  useEffect(() => {
    setFormIsDirty(isDirty);
  }, [isDirty]);

  return (
    <ContainerListagem
      formIsDirty={formIsDirty || lojasIsDirty}
      formMethods={formMethods}
      maxWidth="full"
      isLoading={isLoading}
      onSubmit={handleSubmit}
      dataHoraUltimaAlteracao={dataHoraUltimaAlteracao}
      dataHoraCadastro={dataHoraCadastro}
    >
      {isLoading && <LoadingPadrao />}
      <UncontrolledForm setIsDirty={setFormIsDirty} ref={UncontrolledFormRef} />
    </ContainerListagem>
  );
};

export default Alterar;
