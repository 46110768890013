import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldErrors } from 'react-hook-form';
import { Col, Form } from 'react-bootstrap';

import SituacaoTributariaIpiEnum from 'constants/enum/fiscal/situacaoTributariaIpi';
import SituacaoTributariaPisCofinsEnum from 'constants/enum/fiscal/situacaoTributariaPisCofins';

import SelectPadrao from 'components/Select/SelectPadrao';
import { BootstrapNumberInput } from 'components/update/Input/BootstrapNumberInput';
import { Label } from 'components/Layout/CampoContainer/CampoContainerPadrao/styles';
import InputNumerico from 'components/Input/InputNumerico';

interface UncontrolledFormInterface {
  errors: FieldErrors;
  control: any;
  watch: any;
}

const OutrosTributos: React.FC<UncontrolledFormInterface> = ({
  errors,
  control,
  watch,
}) => {
  const { t } = useTranslation();
  const ipiCstWatch = watch('ipiCst');

  const enquadramentoLegalWatch = watch('enquadramentoLegal');

  return (
    <>
      <Form.Row>
        <Col xl={12}>
          <div className="d-flex mt-1">
            <Label>PIS</Label>
          </div>
          <hr className="my-auto flex-grow-1" />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col md={12} xl={6}>
          <SelectPadrao
            id="pisCst"
            name="pisCst"
            label={t('Situação tributária')}
            noSelectedText={t('Clique aqui para selecionar.')}
            defaultValue={null}
            control={control}
            error={errors.pisCst}
            options={Object.entries(
              SituacaoTributariaPisCofinsEnum.properties
            ).map((value: any) => {
              return (
                {
                  label: t(value[1].name),
                  value: value[1].value,
                } || {}
              );
            })}
            readonly={false}
            required
          />
        </Col>
        <Col md={3} xl={2}>
          <BootstrapNumberInput
            id="pisBaseCalculo"
            name="pisBaseCalculo"
            label={t('Base de cálculo')}
            precision={12}
            scale={2}
            defaultValue={0}
            leftElement="R$"
            control={control}
            error={errors.pisBaseCalculo}
          />
        </Col>
        <Col md={3} xl={2}>
          <BootstrapNumberInput
            id="pisAliquota"
            name="pisAliquota"
            label={t('Alíquota')}
            precision={12}
            scale={2}
            defaultValue={0}
            leftElement="%"
            control={control}
            error={errors.pisAliquota}
          />
        </Col>
        <Col md={3} xl={2}>
          <BootstrapNumberInput
            id="pisValor"
            name="pisValor"
            label={t('Valor')}
            precision={12}
            scale={2}
            defaultValue={0}
            leftElement="R$"
            control={control}
            error={errors.pisValor}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xl={12}>
          <div className="d-flex mt-1">
            <Label>Cofins</Label>
          </div>
          <hr className="my-auto flex-grow-1" />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col md={12} xl={6}>
          <SelectPadrao
            id="cofinsCst"
            name="cofinsCst"
            label={t('Situação tributária')}
            noSelectedText={t('Clique aqui para selecionar.')}
            defaultValue={null}
            control={control}
            error={errors.cofinsCst}
            options={Object.entries(
              SituacaoTributariaPisCofinsEnum.properties
            ).map((value: any) => {
              return (
                {
                  label: t(value[1].name),
                  value: value[1].value,
                } || {}
              );
            })}
            required
          />
        </Col>
        <Col md={3} xl={2}>
          <BootstrapNumberInput
            id="cofinsBaseCalculo"
            name="cofinsBaseCalculo"
            label={t('Base de cálculo')}
            precision={12}
            scale={2}
            defaultValue={0}
            leftElement="R$"
            control={control}
            error={errors.cofinsBaseCalculo}
          />
        </Col>
        <Col md={3} xl={2}>
          <BootstrapNumberInput
            id="cofinsAliquota"
            name="cofinsAliquota"
            label={t('Alíquota')}
            precision={12}
            scale={2}
            defaultValue={0}
            leftElement="%"
            control={control}
            error={errors.cofinsAliquota}
          />
        </Col>
        <Col md={3} xl={2}>
          <BootstrapNumberInput
            id="cofinsValor"
            name="cofinsValor"
            label={t('Valor')}
            precision={12}
            scale={2}
            defaultValue={0}
            leftElement="R$"
            control={control}
            error={errors.cofinsValor}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xl={12}>
          <div className="d-flex mt-1">
            <Label>IPI</Label>
          </div>
          <hr className="my-auto flex-grow-1" />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col md={12} xl={6}>
          <SelectPadrao
            id="ipiCst"
            name="ipiCst"
            label={t('Situação tributária')}
            defaultValue={null}
            placeholder="Selecione"
            control={control}
            error={errors.ipiCst}
            isClearable
            options={Object.entries(SituacaoTributariaIpiEnum.properties).map(
              (value: any) => {
                return (
                  {
                    label: t(value[1].name),
                    value: value[1].value,
                  } || {}
                );
              }
            )}
          />
        </Col>
        {(ipiCstWatch || ipiCstWatch === 0) && (
          <>
            <Col md={3} xl={2}>
              <BootstrapNumberInput
                id="ipiBaseCalculo"
                name="ipiBaseCalculo"
                label={t('Base de cálculo')}
                precision={12}
                scale={2}
                defaultValue={0}
                leftElement="R$"
                control={control}
                error={errors.ipiBaseCalculo}
              />
            </Col>
            <Col md={3} xl={2}>
              <BootstrapNumberInput
                id="ipiAliquota"
                name="ipiAliquota"
                label={t('Alíquota')}
                precision={12}
                scale={2}
                defaultValue={0}
                leftElement="%"
                control={control}
                error={errors.ipiAliquota}
              />
            </Col>
            <Col md={3} xl={2}>
              <BootstrapNumberInput
                id="ipiValor"
                name="ipiValor"
                label={t('Valor')}
                precision={12}
                scale={2}
                defaultValue={0}
                leftElement="R$"
                control={control}
                error={errors.ipiValor}
              />
            </Col>
            <Col md={3} xl={2}>
              <InputNumerico
                type="text"
                id="enquadramentoLegal"
                name="enquadramentoLegal"
                label={t('Código do enquadramento legal')}
                required
                maxLength={3}
                error={errors.enquadramentoLegal}
                control={control}
              />
            </Col>
            <Col md={3} xl={2}>
              <BootstrapNumberInput
                id="percentualDevolvido"
                name="percentualDevolvido"
                label={t('Percentual IPI devolvido')}
                precision={12}
                scale={2}
                defaultValue={0}
                leftElement="%"
                control={control}
                error={errors.percentualDevolvido}
              />
            </Col>
            <Col md={3} xl={2}>
              <BootstrapNumberInput
                id="ipiValorDevolvido"
                name="ipiValorDevolvido"
                label={t('Valor IPI devolvido')}
                precision={12}
                scale={2}
                defaultValue={0}
                leftElement="R$"
                control={control}
                error={errors.ipiValorDevolvido}
              />
            </Col>
          </>
        )}
      </Form.Row>
    </>
  );
};

export default OutrosTributos;
