import React, { useRef, useState } from 'react';
import {
  Input,
  InputProps,
  InputGroup,
  InputRightElement,
  IconButton,
  Icon,
  Tooltip,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { CopiarIcon } from 'icons';
import CampoContainer, {
  CampoContainerProps,
} from 'components/PDV/Geral/CampoContainer';

export type CopyInputProps = Omit<InputProps, 'ref' | 'onChange' | 'value'> &
  CampoContainerProps;

export function CopyInput({
  id,
  name,
  label,
  helperText,
  colSpan,
  colStart,
  colEnd,
  rowStart,
  rowEnd,
  rowSpan,
  size,
  w,
  maxW,
  minW,
  isRequired,
  isDisabled,
  ...rest
}: CopyInputProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const fieldProps = register(`${name}` as const);

  const inputRef = useRef<HTMLInputElement>();

  const [hasCopied, setHasCopied] = useState(false);

  function handleCopiar() {
    if (inputRef.current) {
      setHasCopied(true);

      navigator.clipboard.writeText(inputRef.current.value);

      setTimeout(() => {
        setHasCopied(false);
      }, 2000);
    }
  }

  return (
    <CampoContainer
      id={id}
      name={name}
      label={label}
      helperText={helperText}
      colSpan={colSpan}
      colStart={colStart}
      colEnd={colEnd}
      rowStart={rowStart}
      rowEnd={rowEnd}
      rowSpan={rowSpan}
      w={w}
      maxW={maxW}
      minW={minW}
      isRequired={isRequired}
      isDisabled={isDisabled}
      errorText={errors[name]?.message}
      isInvalid={!!errors[name]}
    >
      <InputGroup size={size} maxW={maxW}>
        <Input
          {...rest}
          id={id}
          {...fieldProps}
          ref={(e) => {
            inputRef.current = e || undefined;
            fieldProps.ref(e);
          }}
        />

        <InputRightElement top="50%" transform="translateY(-50%)">
          <Tooltip
            hasArrow
            label={hasCopied ? 'Copiado' : 'Copiar'}
            closeOnClick={false}
            placement="top"
          >
            <IconButton
              aria-label="Copiar"
              icon={<Icon as={CopiarIcon} fontSize="1.2em" />}
              borderRightRadius="md"
              borderLeftRadius="none"
              border="1px"
              borderColor={isDisabled ? 'gray.100' : 'gray.300'}
              borderLeft="none"
              bg={isDisabled ? 'gray.100' : 'white'}
              _disabled={{ opacity: '1 !important' }}
              _hover={{
                bg: isDisabled ? 'gray.100' : 'gray.50',
              }}
              _active={isDisabled ? { bg: 'gray.100' } : { bg: 'gray.300' }}
              onClick={handleCopiar}
            />
          </Tooltip>
        </InputRightElement>
      </InputGroup>
    </CampoContainer>
  );
}
