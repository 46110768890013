const BASE64_MARKER = ';base64,';
const k = 1024;
const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const dm = decimals < 0 ? 0 : decimals;

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const byteToSize = (
  sizeInBytes: number,
  outputSize: 'KB' | 'MB' | 'GB' | 'TB' | 'PB' | 'EB' | 'ZB' | 'YB',
  decimals = 2
) => {
  if (sizeInBytes === 0) return 0;

  const dm = decimals < 0 ? 0 : decimals;

  return (sizeInBytes / k ** sizes.indexOf(outputSize)).toFixed(dm);
};

export const isDataURI = (url: string) => {
  return url?.split(BASE64_MARKER)?.length === 2;
};

export const dataURItoBase64 = (dataURI: string) => {
  if (!isDataURI(dataURI)) {
    return dataURI;
  }

  return dataURI?.split(BASE64_MARKER)[1] || dataURI;
};

export const calcDataUriBytesSize = (dataURI: string) => {
  if (!isDataURI(dataURI)) {
    return 0;
  }

  return Math.round((dataURI.split(BASE64_MARKER)[1].length * 3) / 4) || 0;
};
export const testIfIsUrl = (value: string) => {
  const patternIsUrl = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i'
  );

  return patternIsUrl.test(value);
};
