export type TipoCampoPersonalizado = 1 | 2 | 3 | 4 | 5 | 6 | 7;

const TipoCampoPersonalizadoEnum = {
  TEXTO: 1,
  CAIXATEXTO: 2,
  NUMERAL: 3,
  DATA: 4,
  CHECKBOX: 5,
  PORCENTAGEM: 6,
  MOEDA: 7,

  properties: {
    1: { name: 'Texto simples', value: 1 },
    2: { name: 'Texto longo', value: 2 },
    3: { name: 'Data (dd/mm/aa)', value: 4 },
    4: { name: 'Campo de seleção - Sim ou Não', value: 5 },
    5: { name: 'Numeral - sem formatação', value: 3 },
    6: { name: 'Numeral - Percentual(%)', value: 6 },
    7: { name: 'Numeral - Moeda(R$)', value: 7 },
  },
};

export default TipoCampoPersonalizadoEnum;
