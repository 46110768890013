import React, { useCallback, useEffect, useState } from 'react';
import {
  Flex,
  Box,
  Text,
  Grid,
  GridItem,
  useMediaQuery,
  Icon,
  Circle,
  useToken,
  HStack,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { moneyMask } from 'helpers/format/fieldsMasks';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import {
  BandeiraIcon,
  ComissaoReceberIcons,
  DashboardFaturamento,
  DashboardProdutosAtendimento,
  DashboardProdutosVendidos,
  DashboardTicketMedio,
  DashboardTotalVendas,
  MetasIcon,
} from 'icons';

import { GraficoPerformanceVendasPessoal } from '../../GraficosMetasComissoes/graficoPerformanceVendasPessoal';
import { GraficoMetaPessoal } from '../../GraficosMetasComissoes/graficoMetaPessoal';
import {
  PerformanceVendasPessoalProps,
  Vendedor,
} from '../../Types/validationsForm';

type DesempenhoIndividualPorVendedorProps = {
  mesAno: string;
  vendedor: Vendedor;
  naoPossuiPermissao?: boolean;
};

export const DesempenhoIndividualPorVendedor = ({
  mesAno,
  vendedor,
  naoPossuiPermissao,
}: DesempenhoIndividualPorVendedorProps) => {
  const [
    performanceVendasPessoal,
    setPerformanceVendasPessoal,
  ] = useState<PerformanceVendasPessoalProps>(
    {} as PerformanceVendasPessoalProps
  );
  const [isLoading, setIsLoading] = useState(false);

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

  const [secondary300, aquamarine200] = useToken('colors', [
    'secondary.300',
    'aquamarine.200',
  ]);

  const validandoPosicaoDosCardsInformativos = isLargerThan900
    ? '4'
    : isLargerThan700
    ? '2'
    : '1';

  const validandoTamanhoDosCardsInformativos = isLargerThan900
    ? '23.9%'
    : isLargerThan700
    ? '49.1%'
    : 'full';

  const metaUltrapassada = performanceVendasPessoal.porcentagemAtingida > 100;

  const obterPerformanceVendas = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<
      void,
      ResponseApi<PerformanceVendasPessoalProps>
    >(ConstanteEnderecoWebservice.PERFORMANCE_VENDAS_PESSOAL, {
      params: {
        periodo: mesAno,
        vendedorId: naoPossuiPermissao ? vendedor?.id : vendedor?.value,
      },
    });

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
        setIsLoading(false);
      }

      if (response.sucesso && response.dados) {
        setPerformanceVendasPessoal(() => ({
          ...response.dados,
          faixasMetaComissao: response.dados.faixasMetaComissao.map(
            (faixa) => ({
              ...faixa,
              isValorMetadeOuTotalMeta: false,
            })
          ),
        }));
        setIsLoading(false);
      }
    }

    setIsLoading(false);
  }, [mesAno, naoPossuiPermissao, vendedor]);

  useEffect(() => {
    obterPerformanceVendas();
  }, [obterPerformanceVendas]);

  return (
    <>
      {performanceVendasPessoal.meta !== undefined && (
        <>
          <Flex direction={isLargerThan900 ? 'row' : 'column'}>
            <Box
              boxShadow="0px 0px 6px #00000034"
              borderRadius="5px"
              h="200px"
              w={isLargerThan900 ? '167px' : 'full'}
              mr="24px"
              bg="white"
            >
              <Text
                pt="24px"
                fontSize="16px"
                textAlign="center"
                color="primary.50"
              >
                Meta pessoal
              </Text>
              <Flex w={isLargerThan900 ? undefined : 'full'}>
                <GraficoMetaPessoal
                  valorMetaPessoal={
                    performanceVendasPessoal?.porcentagemAtingida
                  }
                />
              </Flex>
            </Box>
            <Box
              boxShadow="0px 0px 6px #00000034"
              borderRadius="5px"
              h={isLargerThan900 ? '200px' : '564px'}
              pl="24px"
              mt={isLargerThan900 ? undefined : '24px'}
              w={isLargerThan900 ? 'calc(100% - 191px)' : 'full'}
              maxWidth="100%"
              bg="white"
            >
              <Text
                pt="24px"
                fontSize="16px"
                textAlign="left"
                color="primary.50"
              >
                Performance de vendas pessoal
              </Text>

              <GraficoPerformanceVendasPessoal
                isLoading={isLoading}
                performanceVendasPessoal={performanceVendasPessoal}
              />
              {!isLargerThan900 && (
                <HStack
                  alignItems="center"
                  justifyContent="center"
                  w="full"
                  spacing="10px"
                >
                  <Flex alignItems="center" justifyContent="center">
                    <Circle
                      background={`transparent linear-gradient(90deg, ${aquamarine200} 0%, ${secondary300} 100%) 0% 0% no-repeat padding-box`}
                      size="8px"
                      mr="4px"
                    />
                    <Text color="secondary.700" fontSize="12px">
                      Faturamento
                    </Text>
                  </Flex>
                  <Flex alignItems="center" justifyContent="center">
                    <Circle background="primary.50" size="8px" mr="4px" />
                    <Text color="primary.50" fontSize="12px">
                      Meta
                    </Text>
                  </Flex>
                  <Flex alignItems="center" justifyContent="center">
                    <Circle background="orange.500" size="8px" mr="4px" />
                    <Text color="orange.500" fontSize="12px">
                      Comissão
                    </Text>
                  </Flex>
                </HStack>
              )}
            </Box>
          </Flex>
          <Grid
            templateColumns={`repeat(${validandoPosicaoDosCardsInformativos}, ${validandoTamanhoDosCardsInformativos})`}
            gap="1.5%"
            mt="24px"
          >
            <GridItem
              boxShadow="0px 0px 6px #00000034"
              borderRadius="5px"
              h="64px"
              bg="aquamarine.300"
            >
              <Flex
                pl="16px"
                justifyContent="left"
                h="full"
                alignItems="center"
              >
                <Icon mr="16px" as={MetasIcon} boxSize={[35, 35, 6, 35]} />
                <Box>
                  <Text fontSize="14px">
                    Meta -{' '}
                    {naoPossuiPermissao ? vendedor?.nome : vendedor?.label}
                  </Text>
                  <Text color="black" fontWeight="bold">
                    {moneyMask(performanceVendasPessoal.meta, true)}
                  </Text>
                </Box>
              </Flex>
            </GridItem>
            <GridItem
              boxShadow="0px 0px 6px #00000034"
              borderRadius="5px"
              mt={isLargerThan700 ? undefined : '5px'}
              h="64px"
              pr="16px"
              bg="secondary.300"
            >
              <Flex
                pl="16px"
                justifyContent="left"
                h="full"
                alignItems="center"
              >
                <Icon
                  mr="16px"
                  as={DashboardFaturamento}
                  boxSize={[35, 35, 6, 35]}
                />
                <Box w="full">
                  <Text fontSize="14px">Faturamento</Text>
                  <Flex
                    w="full"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Text color="black" fontWeight="bold">
                      {moneyMask(performanceVendasPessoal.faturamento, true)}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </GridItem>
            <GridItem
              boxShadow="0px 0px 6px #00000034"
              borderRadius="5px"
              h="64px"
              mt={isLargerThan900 ? undefined : '5px'}
              bg="orange.400"
            >
              <Flex
                pl="16px"
                justifyContent="left"
                h="full"
                alignItems="center"
              >
                <Icon mr="16px" as={BandeiraIcon} boxSize={[35, 35, 6, 35]} />
                <Box>
                  <Text fontSize="14px">
                    {!metaUltrapassada
                      ? 'Falta para 100%'
                      : `${(
                          performanceVendasPessoal.porcentagemAtingida - 100
                        ).toFixed(2)}% acima da meta`}
                  </Text>
                  <Text color="black" fontWeight="bold">
                    {moneyMask(
                      metaUltrapassada
                        ? Math.abs(performanceVendasPessoal.valorRestante)
                        : performanceVendasPessoal.valorRestante,
                      true
                    )}
                  </Text>
                </Box>
              </Flex>
            </GridItem>
            <GridItem
              boxShadow="0px 0px 6px #00000034"
              borderRadius="5px"
              mt={isLargerThan900 ? undefined : '5px'}
              h="64px"
              pr="16px"
              bg="yellow.300"
            >
              <Flex
                pl="16px"
                justifyContent="left"
                h="full"
                alignItems="center"
              >
                <Icon
                  mr="16px"
                  as={ComissaoReceberIcons}
                  boxSize={[35, 35, 6, 35]}
                />
                <Box w="full">
                  <Text fontSize="14px">Comissão a receber</Text>
                  <Flex
                    w="full"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Text color="black" fontWeight="bold">
                      {moneyMask(performanceVendasPessoal.comissao, true)}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </GridItem>
            <GridItem
              borderRadius="5px"
              mt={isLargerThan900 ? '20px' : '5px'}
              h="64px"
              bg="white"
            >
              <Flex
                pl="16px"
                justifyContent="left"
                h="full"
                alignItems="center"
              >
                <Icon
                  mr="16px"
                  as={DashboardTicketMedio}
                  boxSize={[35, 35, 6, 35]}
                />
                <Box>
                  <Text fontSize="14px">Ticket médio</Text>
                  <Text color="teal.600" fontWeight="bold">
                    {moneyMask(performanceVendasPessoal.ticketMedio, true)}
                  </Text>
                </Box>
              </Flex>
            </GridItem>
            <GridItem
              borderRadius="5px"
              mt={isLargerThan900 ? '20px' : '5px'}
              h="64px"
              bg="white"
            >
              <Flex
                pl="16px"
                justifyContent="left"
                h="full"
                alignItems="center"
              >
                <Icon
                  mr="16px"
                  as={DashboardTotalVendas}
                  boxSize={[35, 35, 6, 35]}
                />
                <Box>
                  <Text fontSize="14px">Número de vendas</Text>
                  <Text color="blue.700" fontWeight="bold">
                    {moneyMask(
                      performanceVendasPessoal.quantidadeVendas,
                      false
                    )}
                  </Text>
                </Box>
              </Flex>
            </GridItem>
            <GridItem
              borderRadius="5px"
              mt={isLargerThan900 ? '20px' : '5px'}
              h="64px"
              bg="white"
            >
              <Flex
                pl="16px"
                justifyContent="left"
                h="full"
                alignItems="center"
              >
                <Icon
                  mr="16px"
                  as={DashboardProdutosVendidos}
                  boxSize={[35, 35, 6, 35]}
                />
                <Box>
                  <Text fontSize="14px">Produtos vendidos</Text>
                  <Text color="indigo.600" fontWeight="bold">
                    {moneyMask(
                      performanceVendasPessoal.quantidadeVendida,
                      false
                    )}
                  </Text>
                </Box>
              </Flex>
            </GridItem>
            <GridItem
              borderRadius="5px"
              mt={isLargerThan900 ? '20px' : '5px'}
              h="64px"
              bg="white"
            >
              <Flex
                pl="16px"
                justifyContent="left"
                h="full"
                alignItems="center"
              >
                <Icon
                  mr="10px"
                  as={DashboardProdutosAtendimento}
                  boxSize={[35, 35, 6, 35]}
                />
                <Box>
                  <Text fontSize="14px">Prod. por atendimento</Text>
                  <Text color="pink.600" fontWeight="bold">
                    {moneyMask(
                      performanceVendasPessoal.quantidadePorAtendimento,
                      false
                    )}
                  </Text>
                </Box>
              </Flex>
            </GridItem>
          </Grid>
        </>
      )}
    </>
  );
};
