import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  ModalFooter,
  VStack,
  ModalHeader,
  useMediaQuery,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';

type ModalInstrucaoProps = Omit<ModalProps, 'children' | 'isOpen' | 'onClose'> &
  InstanceProps<ModalProps>;

export const ModalInstrucao = create<ModalInstrucaoProps, ModalProps>(
  ({ onResolve, onReject, ...rest }) => {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? '3xl' : 'full'}
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
      >
        <ModalContent
          bg="gray.50"
          borderRadius={isLargerThan900 ? 'md' : '0'}
          marginBottom={isLargerThan900 ? '3.75rem' : '0'}
          marginTop={isLargerThan900 ? '3.75rem' : '0'}
          w={isLargerThan900 ? '800px' : 'full'}
          h={isLargerThan900 ? '480px' : 'full'}
        >
          <ModalHeader pt="16px" pb="28px" pl="24px">
            <Text color="primary.50" fontSize="20px">
              Selecionando produtos para integração
            </Text>
          </ModalHeader>

          <ModalBody pl="24px" pr="24px" mb="20px" pt="0" pb="0">
            <VStack
              color="black"
              fontSize="14px"
              w="full"
              alignItems="left"
              spacing="24px"
            >
              <Text mb="4px" textAlign="left" letterSpacing="0px">
                Todos os produtos cadastrados no sistema estão listados em ordem
                alfabética. Se preferir, é possível pesquisar um produto pela
                descrição ou por diversas características clicando em “busca
                avançada”.
              </Text>
              <Text mb="4px" textAlign="left" letterSpacing="0px">
                Selecione os itens clicando na caixa de seleção localizada a
                frente do nome do produto. Para enviar os produtos clique no
                botão “confirmar produtos selecionados” que ficará localizado no
                rodapé da página assim que você selecionar o primeiro produto.
              </Text>
              <Text mb="4px" textAlign="left" letterSpacing="0px">
                <span
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  Produtos com grade de cor/tamanho:
                </span>{' '}
                Ao selecionar um produto com grade, todas as variações ativas no
                cadastro serão publicadas. Para selecionar as variações de forma
                individual, clique no ícone da grade, localizado a direita do
                nome do produto.
              </Text>
              <Text mb="4px" textAlign="left" letterSpacing="0px">
                Após selecionar os produtos, clique em “Avançar” para finalizar
                esta etapa. Para abrir estas informações novamente, clique no
                ícone da interrogação, localizado no canto superior direito da
                tela.
              </Text>
            </VStack>
          </ModalBody>
          <ModalFooter flexDirection="column" mb="10px">
            <Flex
              w="full"
              h="full"
              justifyContent="center"
              alignItems="baseline"
            >
              <Button
                color="white"
                variant="solid"
                colorScheme="purple.500"
                lineHeight="0"
                borderRadius="20px"
                fontSize="sm"
                h="40px"
                width="120px"
                onClick={() => onClose()}
              >
                OK
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
