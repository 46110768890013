import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';

import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import isPrenvent from 'helpers/layout/isPrenvent';
import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';

import { ContainerListagem } from 'components/Layout/Listagem/containerListagem';

import {
  useForm,
  yupResolver,
  FormData,
  formDefaultValue,
} from '../validationForm';
import UncontrolledForm, { UncontrolledFormRefInterface } from '..';

type TParams = { id: string };

type TransportadoraResponseProps = FormData & {
  cidadeId: number;
  paisId: number;
  dataHoraCadastro: string;
  dataHoraUltimaAlteracao: string;
};

const Alterar = ({ match }: RouteComponentProps<TParams>) => {
  const [isLoading, setIsLoading] = useState(true);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [dataHoraCadastro, setDataHoraCadastro] = useState('');
  const [dataHoraUltimaAlteracao, setDataHoraUltimaAlteracao] = useState('');

  const uncontrolledFormRef = useRef<UncontrolledFormRefInterface>();

  const history = useHistory();
  const isMountedRef = useIsMountedRef();
  isPrenvent();

  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues: formDefaultValue,
  });

  const { handleSubmit, reset, getValues, formState } = formMethods;

  const handleGetTransportadora = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<
      void,
      ResponseApi<TransportadoraResponseProps>
    >(ConstanteEnderecoWebservice.TRANSPORTADORA_OBTER, {
      params: { id: match.params.id },
    });

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso && isMountedRef.current) {
        reset({
          ...response.dados,
        });

        if (uncontrolledFormRef.current) {
          await uncontrolledFormRef.current.setCidade(response.dados.cidadeId);

          if (!getValues('pais')) {
            await uncontrolledFormRef.current.setPais(response.dados.paisId);
          }

          const unformattedCpfCnpj = response.dados.cpfCnpj?.replace(/\D/g, '');
          if (unformattedCpfCnpj?.length <= 11) {
            uncontrolledFormRef.current.setIsCpf(true);
          } else {
            uncontrolledFormRef.current.setIsCpf(false);
          }
        }

        setDataHoraCadastro(response.dados.dataHoraCadastro);
        setDataHoraUltimaAlteracao(response.dados.dataHoraUltimaAlteracao);
      } else {
        history.push(ConstanteRotas.TRANSPORTADORA);
      }
    }

    if (isMountedRef.current) setIsLoading(false);
  }, [getValues, history, isMountedRef, match.params.id, reset]);

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);

    const newdata = {
      ...data,
      id: match.params.id,
      cidadeId: data.cidade.value,
      paisId: data.pais.value,
    };

    const response = await api.put<void, ResponseApi>(
      ConstanteEnderecoWebservice.TRANSPORTADORA_ALTERAR,
      newdata
    );

    if (response) {
      if (response.sucesso) {
        toast.success('O cadastro foi alterado com sucesso.');

        setFormIsDirty(false);

        history.push(ConstanteRotas.TRANSPORTADORA);

        return;
      }

      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }
    }

    if (isMountedRef.current) setIsLoading(false);
  });

  useEffect(() => {
    setIsLoading(true);
    handleGetTransportadora();
  }, [handleGetTransportadora]);

  useEffect(() => {
    setFormIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  return (
    <ContainerListagem
      maxWidth="100%"
      formMethods={formMethods}
      isLoading={isLoading}
      onSubmit={onSubmit}
      dataHoraUltimaAlteracao={dataHoraUltimaAlteracao}
      dataHoraCadastro={dataHoraCadastro}
      formIsDirty={formIsDirty}
    >
      <UncontrolledForm ref={uncontrolledFormRef} />
    </ContainerListagem>
  );
};

export default Alterar;
