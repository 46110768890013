import {
  Table,
  Tbody,
  Tr,
  Thead,
  Th,
  Divider,
  Td,
  Box,
  Flex,
} from '@chakra-ui/react';

import { DecimalMask, moneyMask } from 'helpers/format/fieldsMasks';

import { ListaPerformanceVendedoresProps } from 'pages/Vendas/MetasComissoes/Types/validationsForm';

type PerformanceVendedoresProps = {
  listaPerformanceVendedores: ListaPerformanceVendedoresProps[];
};

export const PerformanceVendedores = ({
  listaPerformanceVendedores,
}: PerformanceVendedoresProps) => {
  return (
    <Box
      boxShadow="0px 0px 6px #00000034"
      p="6"
      rounded="md"
      bg="white"
      overflowX="scroll"
      sx={{
        '&::-webkit-scrollbar': {
          height: '0',
          width: '0',
        },
        '& .virtualized_List::-webkit-scrollbar': {
          height: '0',
          width: '0',
        },
      }}
    >
      <Table overflowX="scroll" ml="0" variant="simpleCardVariant">
        <Thead>
          <Tr
            sx={{
              '& > th': {
                paddingBottom: '0',
                paddingTop: '0',
              },
            }}
          >
            <Th w="30%">Vendedor</Th>
            <Th w="10%">
              <Flex justifyContent="right" alignItems="right">
                Meta (R$)
              </Flex>
            </Th>
            <Th w="10%">
              <Flex justifyContent="right" alignItems="right">
                Faturamento
              </Flex>
            </Th>
            <Th w="10%">
              <Flex justifyContent="right" alignItems="right">
                Meta (%)
              </Flex>
            </Th>
            <Th w="10%">
              <Flex justifyContent="right" alignItems="right">
                Ticket médio
              </Flex>
            </Th>
            <Th w="10%">
              <Flex justifyContent="right" alignItems="right">
                Vendas
              </Flex>
            </Th>
            <Th w="10%">
              <Flex justifyContent="right" alignItems="right">
                Prod. Vend.
              </Flex>
            </Th>
            <Th w="10%">
              <Flex justifyContent="right" alignItems="right">
                Prod. Atend.
              </Flex>
            </Th>
          </Tr>
          <Tr
            sx={{
              '& > th': {
                paddingBottom: '0',
                paddingTop: '5px',
              },
            }}
          >
            <Th pl="0" pr="0" pb="0" colSpan={8}>
              <Divider bg="gray.50" />
            </Th>
          </Tr>
        </Thead>
        <Tbody overflowX="scroll">
          {(listaPerformanceVendedores || []).map((performanceVendedores) => (
            <>
              <Tr
                sx={{
                  '& > td': {
                    paddingBottom: '0',
                    height: '30px',
                  },
                }}
              >
                <Td w="30%">{performanceVendedores.nome}</Td>
                <Td w="10%">
                  <Flex justifyContent="right" alignItems="right">
                    {moneyMask(performanceVendedores.meta, true)}
                  </Flex>
                </Td>
                <Td w="10%">
                  <Flex justifyContent="right" alignItems="right">
                    {moneyMask(performanceVendedores.faturamento, true)}
                  </Flex>
                </Td>
                <Td w="10%">
                  <Flex justifyContent="right" alignItems="right">
                    {`${performanceVendedores.porcentagemAtingida} %`}
                  </Flex>
                </Td>
                <Td w="10%">
                  <Flex justifyContent="right" alignItems="right">
                    {moneyMask(performanceVendedores.ticketMedio, true)}
                  </Flex>
                </Td>
                <Td w="10%">
                  <Flex justifyContent="right" alignItems="right">
                    {DecimalMask(performanceVendedores.quantidadeVendas, 0, 0)}
                  </Flex>
                </Td>
                <Td w="10%">
                  <Flex justifyContent="right" alignItems="right">
                    {DecimalMask(performanceVendedores.quantidadeVendida, 0, 0)}
                  </Flex>
                </Td>
                <Td w="10%">
                  <Flex justifyContent="right" alignItems="right">
                    {DecimalMask(
                      performanceVendedores.quantidadePorAtendimento,
                      2,
                      2
                    )}
                  </Flex>
                </Td>
              </Tr>
              <Tr
                sx={{
                  '& > th': {
                    paddingBottom: '0',
                    paddingTop: '5px',
                  },
                }}
              >
                <Td pb="0" pl="0" pr="0" colSpan={8}>
                  <Divider bg="gray.50" />
                </Td>
              </Tr>
            </>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};
