import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  AspectRatio,
  Image,
  Flex,
  Icon,
  Box,
  useMediaQuery,
  Text,
  GridItem,
} from '@chakra-ui/react';

import MeioPagamentoEnum from 'constants/enum/fiscal/meioPagamento';
import { FormaPagamentoEnum } from 'constants/enum/fiscal/formaPagamentoEnum';
import { useFormaPagamentoContext } from 'store/FormaPagamento/FormaPagamentoFormulario';
import auth from 'modules/auth';
import PlanoContratacaoEnum from 'constants/enum/planoContratacao';

import Input from 'components/PDV/Input';
import CheckboxAtivoInativo from 'components/update/Checkbox/CheckboxAtivoInativo';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import { ProdutoSemImagemIcon } from 'icons';
import { Switch } from 'components/update/Switch';
import { ModalEditarIcones } from 'components/Modal/ModalEditarIcones';
import { NumberInput } from 'components/update/Input/NumberInput';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';

export interface FormaPagamento {
  id: string;
  nome: string;
  ativo: boolean;
}

interface UncontrolledFormInterface {
  readonly?: boolean;
  onSubmit?: () => void;
}

export const UncontrolledForm = ({
  onSubmit,
  readonly,
}: UncontrolledFormInterface) => {
  const [isLargerThan1174] = useMediaQuery('(min-width: 1174px)');
  const [escolherImagem, setEscolherImagem] = useState(false);

  const {
    urlPreview,
    setUrlPreview,
    urlPreviewList,
  } = useFormaPagamentoContext();
  const { watch } = useFormContext();

  const planoAtual = auth.getPlano();
  const isPlanoStartPro =
    planoAtual === PlanoContratacaoEnum.START ||
    planoAtual === PlanoContratacaoEnum.PRO;

  const regraMeioPagamentoWatch = watch('regraMeioPagamento');

  async function handleAbrirModalEscolherIcone() {
    await ModalEditarIcones({
      urlPreview,
      urlPreviewList,
      setUrlPreview,
      onSubmit,
    });
  }

  const aparecerVencimento =
    regraMeioPagamentoWatch === MeioPagamentoEnum.CartaoCreditoLink ||
    regraMeioPagamentoWatch === MeioPagamentoEnum.CartaoCreditoMaquina;

  const aparecerEscolhaImg = {
    display: '',
  };

  if (escolherImagem) {
    aparecerEscolhaImg.display = 'block';
  } else {
    aparecerEscolhaImg.display = 'none';
  }

  return (
    <SimpleGridForm>
      <GridItem colSpan={{ base: 12, md: 12, lg: 5 }}>
        <Input
          id="nome"
          name="nome"
          label="Nome"
          autoFocus
          isRequired
          placeholder="Digite um nome"
          maxLength={40}
          isDisabled={readonly}
        />
      </GridItem>
      <GridItem colSpan={{ base: 12, md: 12, lg: 5 }}>
        <SelectPadrao
          id="regraMeioPagamento"
          name="regraMeioPagamento"
          placeholder="Clique aqui para selecionar"
          label="Meio de pagamento"
          required
          isDisabled={readonly}
          options={FormaPagamentoEnum.map((formaPagamentoItem) => ({
            label: formaPagamentoItem.name,
            value: formaPagamentoItem.value,
          }))}
        />
      </GridItem>
      <GridItem colSpan={{ base: 12, md: 12, lg: 2 }}>
        <CheckboxAtivoInativo
          id="ativo"
          name="ativo"
          label="Status"
          isDisabled={readonly}
          isRequired={false}
        />
      </GridItem>
      {aparecerVencimento && (
        <GridItem colSpan={12}>
          <Flex
            mt="10px"
            direction={isLargerThan1174 ? 'row' : 'column'}
            width="full"
            pl={isLargerThan1174 ? '22px' : '16px'}
          >
            <Flex mb="20px" mr="25px" w={isLargerThan1174 ? '130px' : '100px'}>
              <Flex
                justifyContent="center"
                alignItems="center"
                h="full"
                w="100%"
                borderRadius="100%"
                overflow="hidden"
              >
                <AspectRatio w="100%" ratio={1}>
                  {urlPreview ? (
                    <Flex
                      h="full"
                      w="full"
                      alignItems="center"
                      justifyContent="center"
                      p={6}
                      cursor="pointer"
                      bg={escolherImagem ? 'gray.600' : 'white'}
                      borderRadius="100px"
                      onMouseOver={() => setEscolherImagem(true)}
                      onMouseOut={() => setEscolherImagem(false)}
                    >
                      <Image
                        position="absolute"
                        h="full"
                        w="full"
                        objectFit="cover"
                        opacity={escolherImagem ? '0.2' : '1'}
                        src={urlPreview || ''}
                        cursor="pointer"
                      />

                      <Box
                        position="absolute"
                        style={aparecerEscolhaImg}
                        borderRadius="100px"
                      >
                        <Text
                          onClick={handleAbrirModalEscolherIcone}
                          mt="5px"
                          mb="5px"
                          fontSize="12px"
                          fontWeight="bold"
                          color="white"
                        >
                          Trocar
                        </Text>
                        <Text
                          onClick={() =>
                            setUrlPreview ? setUrlPreview('') : {}
                          }
                          fontWeight="bold"
                          fontSize="12px"
                          color="white"
                        >
                          Excluir
                        </Text>
                      </Box>
                    </Flex>
                  ) : (
                    <Flex
                      h="full"
                      w="full"
                      borderRadius="100%"
                      alignItems="center"
                      justifyContent="center"
                      borderStyle="dashed"
                      borderWidth="2px"
                      borderColor="#CCCCCC"
                      p={6}
                    >
                      <Box>
                        <Icon
                          as={ProdutoSemImagemIcon}
                          color="gray.700"
                          cursor="pointer"
                          fontSize="2.125rem"
                          ml="10px"
                          onClick={handleAbrirModalEscolherIcone}
                        />
                        <Text w="full" fontWeight="bold" fontSize="12px">
                          Adicionar
                        </Text>
                      </Box>
                    </Flex>
                  )}
                </AspectRatio>
              </Flex>
            </Flex>
            <Flex w="full">
              <Box mr={isLargerThan1174 ? '40px' : '20px'} w="40%">
                <NumberInput
                  id="diaVencimento"
                  name="diaVencimento"
                  label="Dia de vencimento"
                  placeholder="Informe o dia de vencimento"
                  maxLength={2}
                  scale={0}
                  isDisabled={readonly}
                />
              </Box>
              {!isPlanoStartPro && (
                <Flex alignItems="center" h="75px">
                  <Box>
                    <Switch
                      id="lancarFatura"
                      name="lancarFatura"
                      size="md"
                      isDisabled={readonly}
                    />
                  </Box>
                  <Box>
                    <Text mb="9px" ml="9px">
                      Lançar na fatura
                    </Text>
                  </Box>
                </Flex>
              )}
            </Flex>
          </Flex>
        </GridItem>
      )}
    </SimpleGridForm>
  );
};

export default UncontrolledForm;
