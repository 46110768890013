import styled from 'styled-components';

interface SwitchContainerInterface {
  value: boolean;
}

export const SwitchContainer = styled.div<SwitchContainerInterface>`
  position: relative;

  height: 35px;
  width: 120px;

  border-radius: 4px;

  background: var(--sti-ck-colors-primary-500);

  z-index: 1;

  &:before {
    position: absolute;
    content: '';
    border-radius: 4px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--sti-ck-colors-red-400);
    z-index: -1;
    transition: opacity 0.2s linear;
    opacity: 0;
  }

  ${({ value }: SwitchContainerInterface) =>
    !value &&
    `&:before {
    opacity: 1;
  }`}

  > span {
    transition: transform 0.2s linear;

    transform: ${({ value }) => `translateX(${value ? '88px' : '3px'})`};
  }

  > div {
    transition: transform 0.2s linear;

    transform: ${({ value }) => `translateX(${value ? '-13px' : '17px'})`};
  }
`;

export const SwitchText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;

  color: var(--white);
  font-size: 0.6875rem;

  user-select: none;
  text-transform: uppercase;
`;

export const SwitchIcon = styled.span`
  position: absolute;

  top: 4px;

  height: 28px;
  width: 28px;
  background: var(--white);
  border-radius: 3px;
  border: 1px solid var(--sti-ck-colors-gray-100);

  box-shadow: 0px 0px 6px var(--black-alpha-600);
`;

export const HiddenCheckbox = styled.input.attrs(() => ({
  type: 'checkbox',
}))`
  display: none;
  opacity: 0;
`;
