type AccessibleColor = {
  bg?: string;
  color?: string;
  hoverColor?: string;
  hoverBg?: string;
  activeBg?: string;
};

/** Accessible color overrides for less accessible colors. */
const accessibleColorMap: { [key: string]: AccessibleColor } = {
  secondary: {
    color: 'primary.700',
    hoverColor: 'primary.600',
  },
  aquamarine: {
    bg: 'aquamarine.600',
    hoverBg: 'aquamarine.500',
    activeBg: 'aquamarine.700',
  },
};

type Dict = Record<string, any>;

const Button = {
  baseStyle: {
    borderRadius: 'full',
    lineHeight: '1',
  },

  variants: {
    link: () => {
      return {
        textDecoration: 'underline',
      };
    },
    linkDefault: ({ colorScheme: scheme, fontSize }: Dict) => {
      const valueFontSize = fontSize?.split('p')[0];

      const valueLetterSpacing = fontSize
        ? (Number(valueFontSize) * 0.29) / 16
        : 0.29;
      const letterSpacing = valueLetterSpacing.toFixed(3);

      const valueColorScheme = scheme.split('.');

      const densidadeColor =
        valueColorScheme.length > 1 ? valueColorScheme[1] : null;
      const colorScheme = valueColorScheme[0];

      const isPrimary = colorScheme === 'primary';

      return {
        textDecoration: 'none',
        color: `${colorScheme}.${
          densidadeColor === null ? '600' : densidadeColor
        }`,
        p: '10px',
        fontWeight: 'normal',
        letterSpacing: `${letterSpacing}px`,
        _hover: {
          color: `${colorScheme}.600`,
          textDecoration: 'none',
          p: '10px',
          fontWeight: 'bold',
          letterSpacing: '0',
          bg: isPrimary ? 'purple.50' : `${colorScheme}.50`,
        },
        _focus: { border: 'none', boxShadow: 'none' },
      };
    },
    cancel: ({ colorScheme }: Dict) => {
      return {
        color: `${colorScheme}.500`,
        borderColor: `${colorScheme}.500`,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: '2xl',
        _focus: { boxShadow: 'none' },
        _hover: {
          bg: `red.500`,
          color: 'white',
          borderColor: 'red.500',
        },
        _active: {
          bg: `red.600`,
          borderColor: 'red.600',
        },
      };
    },
    cancelOutline: ({ colorScheme }: Dict) => {
      return {
        textDecoration: 'none',
        color: `${colorScheme}.500`,
        p: '10px',
        _hover: {
          color: 'red.500',
          p: '10px',
          bg: 'red.50',
        },
        _focus: { border: 'none', boxShadow: 'none' },
        _active: {
          bg: `red.100`,
        },
      };
    },
    ghost: ({ colorScheme }: Dict) => {
      if (colorScheme === 'gray') {
        return {
          color: `inherit`,
          bg: 'transparent',
          _hover: {
            color: `gray.100`,
            bg: 'transparent',
            _disabled: {
              color: `inherit`,
            },
          },
          _active: { color: `gray.200`, bg: 'transparent' },
        };
      }

      const {
        color = `${colorScheme}.500`,
        hoverColor = `${colorScheme}.400`,
      } = accessibleColorMap[colorScheme] || {};

      return {
        color,
        bg: 'transparent',
        _hover: {
          color: hoverColor,
          bg: 'transparent',
          _disabled: {
            color,
          },
        },
        _active: {
          color,
          bg: 'transparent',
        },
      };
    },
    menuButton: ({ colorScheme }: Dict) => {
      if (colorScheme === 'gray') {
        return {
          color: `inherit`,
          bg: 'transparent',
          _hover: {
            color: `gray.400`,
            bg: 'transparent',
            _disabled: {
              color: `inherit`,
            },
          },
          _active: { color: `gray.300`, bg: 'transparent' },
        };
      }

      const {
        color = `${colorScheme}.500`,
        hoverColor = `${colorScheme}.400`,
      } = accessibleColorMap[colorScheme] || {};

      return {
        color,
        bg: 'transparent',
        _hover: {
          color: hoverColor,
          bg: 'transparent',
          _disabled: {
            color,
          },
        },
        _active: {
          color,
          bg: 'transparent',
        },
      };
    },
    outline: ({ colorScheme }: Dict) => {
      if (colorScheme === 'gray') {
        return {
          color: 'gray.300',
          borderColor: 'gray.300',
        };
      }

      return {};
    },
    outlineDefault: ({ colorScheme: scheme }: Dict) => {
      const valueColorScheme = scheme.split('.');

      const densidadeColor =
        valueColorScheme.length > 1 ? valueColorScheme[1] : null;
      const colorScheme = valueColorScheme[0];

      return {
        color: `${colorScheme}.700`,
        borderColor: `${colorScheme}.700`,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: '2xl',
        _focus: {
          boxShadow: '0 0 0 1px #390073',
          border: '1px solid #390073',
        },
        _hover: {
          bg: `${colorScheme}.${
            densidadeColor === null ? '500' : densidadeColor - 100
          }`,
          color: 'white',
          borderColor: `${colorScheme}.600`,
          _disabled: {
            color: `${colorScheme}.700`,
          },
        },
        _active: {
          bg: `${colorScheme}.${
            densidadeColor === null ? '600' : densidadeColor
          }`,
        },
      };
    },
    solid: ({ colorScheme: scheme }: Dict) => {
      const valueColorScheme = scheme.split('.');

      const densidadeColor =
        valueColorScheme.length > 1 ? valueColorScheme[1] : null;
      const colorScheme = valueColorScheme[0];

      if (colorScheme === 'gray') {
        const bg = 'gray.200';

        return {
          bg,
          _focus: {
            boxShadow: 'none',
          },
          _hover: {
            bg: 'gray.200',
            _disabled: {
              bg,
            },
          },
          _active: { bg: 'gray.300' },
          _disabled: {
            bg,
            opacity: 1,
          },
        };
      }

      const {
        bg = `${colorScheme}.${
          densidadeColor === null ? '500' : densidadeColor
        }`,
        color = 'white',
        hoverBg = `${colorScheme}.${
          densidadeColor === null ? '400' : densidadeColor - 100
        }`,
        activeBg = `${colorScheme}.${
          densidadeColor === null ? '500' : densidadeColor
        }`,
      } = accessibleColorMap[colorScheme] || {};

      return {
        bg,
        color,
        _hover: {
          bg: hoverBg,
          _disabled: {
            bg,
          },
        },
        _focus: {
          boxShadow: 'none',
          borderWidth: '2px',
          borderColor: `${colorScheme}.900`,
        },
        _active: { bg: activeBg },
      };
    },
  },

  sizes: {
    md: {
      h: 9,
    },
    lg: {
      h: 10,
      fontSize: 'md',
    },
  },

  defaultProps: {
    size: 'md',
    variant: 'solid',
    colorScheme: 'gray',
  },
};

export default Button;
