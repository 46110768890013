import { useCallback, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  ModalFooter,
  Box,
  useMediaQuery,
  Icon,
} from '@chakra-ui/react';
import Lottie from 'lottie-react';
import { create, InstanceProps } from 'react-modal-promise';
import { HubConnection } from '@microsoft/signalr';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import PagamentoRecusado from 'assets/animations/pagamento_recusado.json';
import PagamentoAprovado from 'assets/animations/pagamento_aprovado.json';
import PagamentoEmAndamento from 'assets/animations/pagamento_em_andamento.json';
import {
  EnumStatusPagamentos,
  EnumTipoTransacao,
} from 'constants/enum/enumPagamentos';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { TextValor } from 'components/PDV/Text/TextValor';
import { IconStone } from 'icons';

type PromiseModal = {
  success?: boolean;
  cancel?: boolean;
  gerarVenda?: boolean;
};

interface ModalPagamentoStoneProps
  extends InstanceProps<PromiseModal>,
    Omit<ModalProps, 'children' | 'isOpen' | 'onClose'> {
  dadosTransacao: {
    valor: number;
    valorRestante: number;
    qtdeParcela: number;
    idOperacao: string;
    display: string;
    tipo: string;
    contaFinanceiraId: string | undefined;
  };
  hubConnection: HubConnection;
  joinGroup: (group: string) => Promise<unknown>;
  updateOrderId: (orderIdValue: string) => void;
  gerarVendaAoPagar: () => Promise<void>;
}

export const ModalPagamentoStone = create<ModalPagamentoStoneProps>(
  ({
    onResolve,
    onReject,
    dadosTransacao: {
      valor,
      valorRestante,
      qtdeParcela,
      idOperacao,
      display,
      tipo,
      contaFinanceiraId,
    },
    updateOrderId,
    hubConnection,
    joinGroup,
    gerarVendaAoPagar,
    ...rest
  }) => {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
    const [isLoading, setIsLoading] = useState(false);
    const [isGerandoVenda, setIsGerandoVenda] = useState(false);
    const [messageTransacao, setMessageTransacao] = useState(
      'Iniciando pagamento...'
    );
    const [statusTransacao, setStatusTransacao] = useState(
      EnumStatusPagamentos.PENDING
    );
    const [orderId, setOrderId] = useState('');

    const pagamentoPago =
      statusTransacao === EnumStatusPagamentos.PAID ||
      statusTransacao === EnumStatusPagamentos.SUCCEEDED;
    const pagamentoFalhou = statusTransacao === EnumStatusPagamentos.FAILED;
    const pagamentoAguardando =
      statusTransacao === EnumStatusPagamentos.PENDING;
    const pagamentoCancelado =
      statusTransacao === EnumStatusPagamentos.CANCELED;

    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

    const handlePagamento = useCallback(async () => {
      const data = {
        idContaFinanceira: contaFinanceiraId,
        idOperacao,
        display,
        valor,
        parcelas: qtdeParcela,
        tipo,
      };

      setIsLoading(true);
      const response = await api.post<void, ResponseApi<string>>(
        ConstanteEnderecoWebservice.GERAR_TRANSACAO_STONE,
        data
      );

      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((aviso: string) => toast.warning(aviso));
          setStatusTransacao(EnumStatusPagamentos.FAILED);
          setMessageTransacao(response.avisos[0]?.toUpperCase());
        }

        if (response?.sucesso && response?.dados) {
          updateOrderId(response.dados);
          setOrderId(response.dados);
        }
      } else {
        setStatusTransacao(EnumStatusPagamentos.FAILED);
        setMessageTransacao('OCORREU UM ERRO NO PAGAMENTO');
      }
      setIsLoading(false);
    }, [
      contaFinanceiraId,
      display,
      idOperacao,
      qtdeParcela,
      updateOrderId,
      tipo,
      valor,
    ]);

    useEffect(() => {
      handlePagamento();
    }, [handlePagamento]);

    const fecharTransacao = useCallback(async () => {
      setIsLoading(true);

      const response = await api.get<void, ResponseApi<string>>(
        ConstanteEnderecoWebservice.FECHAR_TRANSACAO_PEDIDO_STONE,
        {
          params: {
            idOperacao,
            orderId,
          },
        }
      );
      if (response) {
        if (response.avisos) {
          response.avisos.forEach((aviso: string) => toast.warning(aviso));
        }
        if (response.sucesso) {
          onResolve({ success: false, cancel: true });
          onClose();
        }
      }
      setIsLoading(false);
    }, [idOperacao, onClose, onResolve, orderId]);

    const cancelarAoAtualizarTela = useCallback(async () => {
      await fecharTransacao();
    }, [fecharTransacao]);

    useEffect(() => {
      window.addEventListener('beforeunload', cancelarAoAtualizarTela);
      return () => {
        window.removeEventListener('beforeunload', cancelarAoAtualizarTela);
      };
    }, [cancelarAoAtualizarTela]);

    useEffect(() => {
      joinGroup('stone');
      joinGroup(`${idOperacao}_aguardando_transacao`);

      hubConnection.on(
        'alterar-status-transacao',
        (status: string, mensagem: string) => {
          if (status) {
            setStatusTransacao(status);
          }
          if (mensagem) {
            setMessageTransacao(mensagem);
          }
        }
      );
      hubConnection.on(
        'finalizar-stone',
        (status: string, stoneId: string, mensagem: string) => {
          if (stoneId === orderId) {
            if (status) {
              setStatusTransacao(status);
            }
            if (mensagem) {
              setMessageTransacao(mensagem);
            }
          }
        }
      );
    }, [hubConnection, idOperacao, joinGroup, orderId]);

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? '5xl' : 'full'}
        {...rest}
        closeOnOverlayClick={false}
        isOpen={isOpen}
        closeOnEsc={false}
        onClose={onClose}
        autoFocus
      >
        <ModalContent
          bg="gray.100"
          borderRadius={isLargerThan900 ? '5px' : '0'}
          w={isLargerThan900 ? '720px' : 'full'}
          h={isLargerThan900 ? '480px' : 'full'}
        >
          {isLoading && <LoadingPadrao />}

          <ModalBody p="0">
            <Box w="full" position="relative" h="full">
              <Flex
                h="172px"
                w="full"
                bg="primary.50"
                borderTopRadius="5px"
                justifyContent="flex-end"
                pr="32px"
              >
                <Icon as={IconStone} boxSize="108px" />
              </Flex>
              <Box w="full" px="60px" position="absolute" top="108px">
                <Text fontSize="lg" color="white" fontWeight="light">
                  {`Cartão de ${
                    tipo === EnumTipoTransacao.CREDITO_STONE
                      ? 'crédito'
                      : 'débito'
                  }`}
                </Text>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  bg="white"
                  py="16px"
                  borderRadius="12px"
                >
                  <TextValor
                    fontSize="5xl"
                    fontWeight="bold"
                    symbolFontSize="lg"
                    symbolFontWeight="bold"
                    valor={valor || 0}
                    color={
                      pagamentoPago
                        ? 'green.500'
                        : pagamentoFalhou || pagamentoCancelado
                        ? 'red.500'
                        : 'violet.500'
                    }
                    casasDecimais={2}
                  />
                </Flex>
              </Box>
              <Flex
                w="full"
                justifyContent="center"
                alignItems="center"
                h="65%"
                fontSize="md"
                fontWeight="extrabold"
              >
                <Flex
                  flexDir="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  {pagamentoAguardando && (
                    <>
                      <Lottie
                        animationData={PagamentoEmAndamento}
                        loop
                        style={{
                          width: '150px',
                          height: '150px',
                        }}
                      />
                      <Text color="black" fontWeight="semibold">
                        {messageTransacao}
                      </Text>
                    </>
                  )}
                  {pagamentoPago && (
                    <>
                      <Lottie
                        animationData={PagamentoAprovado}
                        loop
                        style={{
                          width: '150px',
                          height: '150px',
                        }}
                        onLoopComplete={async () => {
                          if (!isGerandoVenda) {
                            if (valor === valorRestante) {
                              setIsGerandoVenda(true);
                              await gerarVendaAoPagar();
                              onResolve({
                                success: true,
                                cancel: false,
                                gerarVenda: true,
                              });
                              onClose();
                              return;
                            }
                            onResolve({ success: true, cancel: false });
                            onClose();
                          }
                        }}
                      />

                      <Text color="aquamarine.600">{messageTransacao}</Text>
                    </>
                  )}
                  {(pagamentoFalhou || pagamentoCancelado) && (
                    <>
                      <Lottie
                        animationData={PagamentoRecusado}
                        loop
                        style={{
                          width: '150px',
                          height: '150px',
                        }}
                      />

                      <Text color="red.500">{messageTransacao}</Text>
                    </>
                  )}
                </Flex>
              </Flex>
            </Box>
          </ModalBody>
          <ModalFooter h={isLargerThan900 ? '80px' : 'fit-content'}>
            <Flex w="full" h="full" justifyContent="center" gap="16px">
              {pagamentoAguardando && (
                <Button
                  variant="outline"
                  size="sm"
                  maxW={{ base: '75px', sm: '100px' }}
                  minW="75px"
                  w="full"
                  border="1px solid"
                  _hover={{
                    bg: 'red.500',
                    color: 'white',
                    border: '1px solid',
                    borderColor: 'red.500',
                  }}
                  _active={{
                    bg: 'red.600',
                    borderColor: 'red.600',
                  }}
                  onClick={() => {
                    fecharTransacao();
                  }}
                >
                  Cancelar
                </Button>
              )}
              {(pagamentoFalhou || pagamentoCancelado) && (
                <>
                  <Button
                    variant="outlineDefault"
                    colorScheme="gray"
                    onClick={() => {
                      onResolve({ success: false, cancel: false });
                    }}
                  >
                    Trocar forma de recebimento
                  </Button>
                  <Button
                    variant="solid"
                    colorScheme="primary"
                    onClick={handlePagamento}
                  >
                    Tentar novamente
                  </Button>
                </>
              )}
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
