import React from 'react';
import { Tooltip } from 'react-bootstrap';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import OverlayTriggerPadrao from 'components/OverlayTriggerPadrao';

import {
  Container,
  LabelContainer,
  Label,
  MensagemValidacao,
  InfoIcon,
  LinkAction,
} from './styles';

export interface LinkActionInterface {
  label?: string;
  onClick?: () => void;
}

export interface CampoContainerPadraoProps {
  id: string;
  label?: string;
  error?: FieldError | null;
  required?: boolean;
  infoText?: string;
  infoOnClick?: () => void;
  linkAction?: LinkActionInterface;
}

interface CampoContainerPadraoComponentProps extends CampoContainerPadraoProps {
  children: React.ReactNode;
}

const CampoContainerPadrao = ({
  id,
  label,
  error = null,
  required,
  infoText,
  infoOnClick,
  linkAction,
  children,
}: CampoContainerPadraoComponentProps) => {
  const { t } = useTranslation('validation');

  return (
    <Container>
      {label && (
        <LabelContainer>
          <Label id={`label-for-${id}`} htmlFor={id} style={{ width: '100%' }}>
            {label}
            {required && ' *'}
            {linkAction && (
              <LinkAction onClick={linkAction.onClick}>
                {linkAction.label}
              </LinkAction>
            )}
          </Label>
          {infoText && (
            <OverlayTriggerPadrao
              placement="left"
              delay={{ show: 200, hide: 300 }}
              overlay={(props: any) => {
                return <Tooltip {...props}>{infoText}</Tooltip>;
              }}
            >
              <InfoIcon
                style={infoOnClick ? { cursor: 'pointer' } : undefined}
                onClick={infoOnClick}
              />
            </OverlayTriggerPadrao>
          )}
          {!infoText && infoOnClick && (
            <InfoIcon style={{ cursor: 'pointer' }} onClick={infoOnClick} />
          )}
        </LabelContainer>
      )}

      {children}

      {error && error.message && (
        <MensagemValidacao id={`mensagem-validacao-${id}`}>
          {t(error.message)}
        </MensagemValidacao>
      )}
    </Container>
  );
};
export default CampoContainerPadrao;
