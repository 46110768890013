import { Route } from 'react-router-dom';

import { DashboardGerencial } from 'pages/Integracoes/LojaAplicativos/DashboardGerencial';
import { DetalhesIntegracaoDashboard } from 'pages/Integracoes/LojaAplicativos/DashboardGerencial/DetalhesIntegracaoDashboard';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';

export const DashboardGerencialRoutes = [
  <Route path={ConstanteRotas.DASHBOARD_GERENCIAL} key="dashboard-gerencial">
    <LayoutGuard
      key="dashboard-gerencial"
      component={DashboardGerencial}
      breadcrumb={[
        {
          title: 'Integrações',
        },
        {
          title: 'Loja de aplicativos',
          path: ConstanteRotas.LOJA_APLICATIVOS,
        },
        {
          title: 'Dashboard gerencial',
        },
      ]}
      path={ConstanteRotas.DASHBOARD_GERENCIAL}
      exact
      meta={{ auth: true }}
    />
  </Route>,
  <Route
    path={ConstanteRotas.DASHBOARD_GERENCIAL_DETALHES}
    key="dashboard-gerencial-detalhes"
  >
    <LayoutGuard
      key="dashboard-gerencial-detalhes"
      component={DetalhesIntegracaoDashboard}
      breadcrumb={[
        {
          title: 'Loja de aplicativos',
          path: ConstanteRotas.LOJA_APLICATIVOS,
        },
        {
          title: 'Dashboard gerencial',
        },
      ]}
      path={ConstanteRotas.DASHBOARD_GERENCIAL_DETALHES}
      exact
      meta={{ auth: true }}
    />
  </Route>,
];
