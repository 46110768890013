import React from 'react';

import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';

import CoresAlterar from '../pages/Padronizacao/Formulario/Alterar';

const PadronizacaoRoutes = [
  <LayoutGuard
    key="padronizacao-alterar"
    permissaoFuncionalidade={ConstanteFuncionalidades.PADRONIZACAO_ALTERAR}
    component={CoresAlterar}
    breadcrumb={[
      {
        title: 'Configurações',
      },
      {
        title: 'Padronização',
      },
    ]}
    path={ConstanteRotas.PADRONIZACAO}
    exact
    meta={{ auth: true }}
  />,
];

export default PadronizacaoRoutes;
