const EstadosEnum = {
  Acre: { codigo: 12, id: 1 },
  Alagoas: { codigo: 27, id: 2 },
  Amapa: { codigo: 16, id: 3 },
  Amazonas: { codigo: 13, id: 4 },
  Bahia: { codigo: 29, id: 5 },
  Ceara: { codigo: 23, id: 6 },
  DistritoFederal: { codigo: 53, id: 7 },
  EspiritoSanto: { codigo: 32, id: 8 },
  Goias: { codigo: 52, id: 9 },
  Maranhao: { codigo: 21, id: 10 },
  MatoGrosso: { codigo: 51, id: 11 },
  MatoGrossoSul: { codigo: 50, id: 12 },
  MinasGerais: { codigo: 31, id: 13 },
  Para: { codigo: 15, id: 14 },
  Paraiba: { codigo: 25, id: 15 },
  Parana: { codigo: 41, id: 16 },
  Pernambuco: { codigo: 26, id: 17 },
  Piaui: { codigo: 22, id: 18 },
  RioJaneiro: { codigo: 33, id: 19 },
  RioGrandeNorte: { codigo: 24, id: 20 },
  RioGrandeSul: { codigo: 43, id: 21 },
  Rondonia: { codigo: 11, id: 22 },
  Roraima: { codigo: 14, id: 23 },
  SantaCatarina: { codigo: 42, id: 24 },
  SaoPaulo: { codigo: 35, id: 25 },
  Sergipe: { codigo: 28, id: 26 },
  Tocantins: { codigo: 17, id: 27 },

  properties: {
    0: { sigla: 'AC', nome: 'ACRE', codigo: 12, id: 1 },
    1: { sigla: 'AL', nome: 'ALAGOAS', codigo: 27, id: 2 },
    2: { sigla: 'AP', nome: 'AMAPÁ', codigo: 16, id: 3 },
    3: { sigla: 'AM', nome: 'AMAZONAS', codigo: 13, id: 4 },
    4: { sigla: 'BA', nome: 'BAHIA', codigo: 29, id: 5 },
    5: { sigla: 'CE', nome: 'CEARÁ', codigo: 23, id: 6 },
    6: { sigla: 'DF', nome: 'DISTRITO FEDERAL', codigo: 53, id: 7 },
    7: { sigla: 'ES', nome: 'ESPÍRITO SANTO', codigo: 32, id: 8 },
    8: { sigla: 'GO', nome: 'GOIÁS', codigo: 52, id: 9 },
    9: { sigla: 'MA', nome: 'MARANHÃO', codigo: 21, id: 10 },
    10: { sigla: 'MT', nome: 'MATO GROSSO', codigo: 51, id: 11 },
    11: { sigla: 'MS', nome: 'MATO GROSSO DO SUL', codigo: 50, id: 12 },
    12: { sigla: 'MG', nome: 'MINAS GERAIS', codigo: 31, id: 13 },
    13: { sigla: 'PA', nome: 'PARÁ', codigo: 15, id: 14 },
    14: { sigla: 'PB', nome: 'PARAíBA', codigo: 25, id: 15 },
    15: { sigla: 'PR', nome: 'PARANÁ', codigo: 41, id: 16 },
    16: { sigla: 'PE', nome: 'PERNAMBUCO', codigo: 26, id: 17 },
    17: { sigla: 'PI', nome: 'PIAUÍ', codigo: 22, id: 18 },
    18: { sigla: 'RJ', nome: 'RIO DE JANEIRO', codigo: 33, id: 19 },
    19: { sigla: 'RN', nome: 'RIO GRANDE DO NORTE', codigo: 24, id: 20 },
    20: { sigla: 'RS', nome: 'RIO GRANDE DO SUL', codigo: 43, id: 21 },
    21: { sigla: 'RO', nome: 'RONDÔNIA', codigo: 11, id: 22 },
    22: { sigla: 'RR', nome: 'RORAIMA', codigo: 14, id: 23 },
    23: { sigla: 'SC', nome: 'SANTA CATARINA', codigo: 42, id: 24 },
    24: { sigla: 'SP', nome: 'SÃO PAULO', codigo: 35, id: 25 },
    25: { sigla: 'SE', nome: 'SERGIPE', codigo: 28, id: 26 },
    26: { sigla: 'TO', nome: 'TOCANTINS', codigo: 17, id: 27 },
  },
};

export default EstadosEnum;
