import React, { useEffect, useRef } from 'react';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';

import {
  Button,
  Flex,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Textarea,
  useMediaQuery,
} from '@chakra-ui/react';
import { useForm, useFormContext } from 'react-hook-form';

import { useOperacaoContext } from 'store/PDV/Operacao';
import { MobileModalMenu } from 'components/PDV/Layout/MobileModalMenu';

interface ModalCadastroProps {
  show: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const ModalCadastro = ({ show, setIsOpen }: ModalCadastroProps) => {
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const { handleChangeObservacao } = useOperacaoContext();

  const formMethodsContext = useFormContext();
  const formMethods = useForm({
    shouldUnregister: false,
  });

  const latestProps = useRef({
    formMethodsContext: { getValues: formMethodsContext.getValues },
    formMethods: { setValue: formMethods.setValue },
  });
  useEffect(() => {
    latestProps.current = {
      formMethodsContext: { getValues: formMethodsContext.getValues },
      formMethods: { setValue: formMethods.setValue },
    };
  });

  useEffect(() => {
    latestProps.current.formMethods.setValue(
      'observacao',
      latestProps.current.formMethodsContext.getValues('observacao')
    );
  }, []);

  const confirmarHandle = () => {
    handleChangeObservacao(formMethods.getValues('observacao'));
    setIsOpen(false);
  };

  if (!isLargerThan900) {
    return (
      <MobileModalMenu
        title="Observações"
        isOpen={show}
        onClose={() => setIsOpen(false)}
      >
        <Textarea
          id="observacao"
          height={isLargerThan900 ? '180px' : '250px'}
          bgColor="white"
          autoFocus
          resize="vertical"
          placeholder="Digite o texto."
          {...formMethods.register('observacao')}
        />

        <Flex my="6" alignItems="center" justifyContent="center">
          <Button
            variant="solid"
            colorScheme="secondary"
            size="lg"
            minW="100px"
            onClick={confirmarHandle}
          >
            Confirmar
          </Button>
        </Flex>
      </MobileModalMenu>
    );
  }

  return (
    <ModalPadraoChakra
      size={isLargerThan900 ? '2xl' : 'full'}
      isOpen={show}
      onClose={() => setIsOpen(false)}
      scrollBehavior={isLargerThan900 ? 'inside' : 'outside'}
      isCentered={isLargerThan900}
    >
      <ModalContent
        margin={0}
        maxW={isLargerThan900 ? '600px' : undefined}
        borderRadius={isLargerThan900 ? 'md' : 0}
        bg="gray.50"
      >
        <ModalHeader
          color="primary.500"
          mt={isLargerThan900 ? undefined : 12}
          mb={isLargerThan900 ? undefined : 8}
        >
          Observações
        </ModalHeader>
        <ModalCloseButton
          mt={isLargerThan900 ? undefined : 14}
          mr={isLargerThan900 ? undefined : 6}
        />
        <ModalBody>
          <Textarea
            id="observacao"
            height={isLargerThan900 ? '180px' : '250px'}
            bgColor="white"
            autoFocus
            resize="vertical"
            {...formMethods.register('observacao')}
          />
          <HStack py={6} spacing={6} justifyContent="center">
            {!isLargerThan900 && (
              <Button
                variant="outline"
                size="sm"
                minW="100px"
                color="gray.400"
                onClick={() => setIsOpen(false)}
              >
                Cancelar
              </Button>
            )}
            <Button
              variant="solid"
              colorScheme="secondary"
              size="sm"
              minW="100px"
              onClick={confirmarHandle}
            >
              Confirmar
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export default ModalCadastro;
