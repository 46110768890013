import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import isPrenvent from 'helpers/layout/isPrenvent';
import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { CategoriaPermissaoInterface } from 'components/Permissoes';
import { ContainerListagem } from 'components/Layout/Listagem/containerListagem';

import { useForm, yupResolver, PermissoesProps } from '../validationForm';
import { UncontrolledForm } from '..';

interface PerfilUsuarioInterface {
  id: string;
  descricao: string;
  ativo: boolean;
  dataHoraCadastro: string;
  dataHoraUltimaAlteracao: string;
}

type TParams = { id: string };

const Alterar = () => {
  const history = useHistory();
  const isMountedRef = useIsMountedRef();
  isPrenvent();

  const [isLoading, setIsLoading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [dataHoraCadastro, setDataHoraCadastro] = useState('');
  const [dataHoraUltimaAlteracao, setDataHoraUltimaAlteracao] = useState('');
  const [listaPermissoes, setListaPermissoes] = useState<PermissoesProps[]>([]);

  const [categorias, setCategorias] = useState(
    [] as Array<CategoriaPermissaoInterface>
  );

  const formMethods = useForm({
    resolver: yupResolver,
    shouldUnregister: false,
  });
  const { handleSubmit, reset, getValues, watch, formState } = formMethods;

  const { id: idRota } = useParams<TParams>();

  const permissoeswatch = watch('permissoes');

  const atualizarValoresPermissao = useCallback(() => {
    return listaPermissoes.map((permissao, index) => {
      return {
        id: permissao.id,
        selecionado: permissoeswatch[index],
      };
    });
  }, [listaPermissoes, permissoeswatch]);

  const onSubmit = handleSubmit(async () => {
    setIsLoading(true);
    const valoresPermissao = await atualizarValoresPermissao();
    let data = getValues();

    data = {
      id: idRota,
      ativo: data.ativo,
      dataHoraCadastro: data.dataHoraCadastro,
      dataHoraUltimaAlteracao: data.dataHoraUltimaAlteracao,
      descricao: data.descricao,
      permissoes: valoresPermissao,
    };

    const response = await api.put<void, ResponseApi>(
      ConstanteEnderecoWebservice.PERFIL_USUARIO_ALTERAR,
      data
    );

    if (response.sucesso) {
      toast.success('O cadastro foi alterado com sucesso.');

      setFormIsDirty(false);

      history.push(ConstanteRotas.PERFIL_USUARIO);

      return;
    }

    if (response.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (isMountedRef.current) setIsLoading(false);
  });

  const latestProps = useRef({
    getValues,
    history,
    isMountedRef,
    matchId: idRota,
    reset,
  });

  useEffect(() => {
    latestProps.current = {
      getValues,
      history,
      isMountedRef,
      matchId: idRota,
      reset,
    };
  });

  useEffect(() => {
    const handleGetCategoriaPermissao = async () => {
      setIsLoading(true);

      const response = await api.get<
        void,
        ResponseApi<Array<CategoriaPermissaoInterface>>
      >(
        ConstanteEnderecoWebservice.PERFIL_USUARIO_LISTAR_CATEGORIA_PERMISSOES,
        {
          params: { perfilId: latestProps.current.matchId },
        }
      );

      if (response?.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }

      if (response?.sucesso && latestProps.current.isMountedRef.current) {
        const defaultValues: { [key: string]: any } = {
          permissoes: [],
        };

        response.dados.forEach((categoria) => {
          categoria.grupos.forEach((grupo) => {
            let todoSelecionados = true;

            grupo.permissoes.forEach((permissao) => {
              defaultValues.permissoes.push(permissao);

              if (!permissao.selecionado) {
                todoSelecionados = false;
              }
            });

            defaultValues[`grupo${grupo.id}`] = {
              id: grupo.id,
              selecionado: todoSelecionados,
            };
          });
        });

        latestProps.current.reset({
          ...latestProps.current.getValues(),
          ...defaultValues,
        });

        setCategorias(response.dados);
      }

      if (latestProps.current.isMountedRef.current) setIsLoading(false);
    };

    const handleGetPerfilUsuario = async () => {
      setIsLoading(true);

      const response = await api.get<void, ResponseApi<PerfilUsuarioInterface>>(
        ConstanteEnderecoWebservice.PERFIL_USUARIO_OBTER,
        {
          params: { id: latestProps.current.matchId },
        }
      );

      if (response?.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }

      if (response?.sucesso && latestProps.current.isMountedRef.current) {
        latestProps.current.reset({
          ...response.dados,
        });

        setDataHoraCadastro(response.dados.dataHoraCadastro);
        setDataHoraUltimaAlteracao(response.dados.dataHoraUltimaAlteracao);
      } else {
        latestProps.current.history.push(ConstanteRotas.PERFIL_USUARIO);
      }

      if (latestProps.current.isMountedRef.current) setIsLoading(false);
    };

    setIsLoading(true);

    handleGetPerfilUsuario();
    handleGetCategoriaPermissao();
  }, []);

  useEffect(() => {
    categorias.forEach((categoria) => {
      categoria.grupos.forEach((grupo) => {
        grupo.permissoes.forEach((permissao) => {
          setListaPermissoes((valorAnterior) => [
            ...valorAnterior,
            { id: permissao.id },
          ]);
        });
      });
    });
  }, [categorias]);

  useEffect(() => {
    setFormIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  return (
    <ContainerListagem
      maxWidth="full"
      formIsDirty={formIsDirty}
      formMethods={formMethods}
      isLoading={isLoading}
      onSubmit={onSubmit}
      dataHoraUltimaAlteracao={dataHoraUltimaAlteracao}
      dataHoraCadastro={dataHoraCadastro}
    >
      <UncontrolledForm categorias={categorias} />
    </ContainerListagem>
  );
};

export default Alterar;
