import { useState, useEffect, useRef, useCallback } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useMediaQuery,
  Text,
  useDisclosure,
  ModalHeader,
  Box,
  Flex,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  ModalFooter,
  Button,
  Divider,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { create, InstanceProps } from 'react-modal-promise';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import auth from 'modules/auth';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import PlanoContratacaoEnum from 'constants/enum/planoContratacao';

import { FormaPagamentoRecebimentoLojas } from '../Formulario/Types';
import { yupResolver } from './Forms/validationForm';

type ContasFinanceiras = {
  id: string;
  nome: string;
};

type LojasProps = {
  id: string;
  fantasia: string;
  plano: number;
  endereco: string;
  cidade: string;
  contaFinanceiras: ContasFinanceiras[];
};

type FormData = {
  contaFinanceiraPorLoja: string[];
};

type ModalContaFinanceiraPorLojaResponse = {
  detalhe: string;
  conferenciaEstoqueId: string;
};

type ModalContaFinanceiraPorLojaProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<ModalContaFinanceiraPorLojaResponse> & {
    listaLojasContaFinanceira: FormaPagamentoRecebimentoLojas[];
    formaPagamentoRecebimentoLoja?: FormaPagamentoRecebimentoLojas[];
    idRota?: string;
    salvandoValoresContaFinanceraPorLoja: (
      valoresContaFinanceiraSelecionada: FormaPagamentoRecebimentoLojas[]
    ) => void;
  };

export const ModalContaFinanceiraPorLoja = create<
  ModalContaFinanceiraPorLojaProps,
  ModalContaFinanceiraPorLojaResponse
>(
  ({
    onResolve,
    onReject,
    salvandoValoresContaFinanceraPorLoja,
    listaLojasContaFinanceira,
    idRota,
    formaPagamentoRecebimentoLoja,
    ...rest
  }) => {
    const [lojas, setLojas] = useState<LojasProps[]>([]);

    const [isLoading, setIsLoading] = useState(false);

    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
    const [isMobile] = useMediaQuery('(max-width: 900px)');

    const formMethods = useForm<FormData>({
      resolver: yupResolver,
    });
    const planoStart = PlanoContratacaoEnum.START;
    const {
      watch,
      setValue,
      handleSubmit,
      formState: { errors },
    } = formMethods;

    const contaFinanceiraWatch = watch('contaFinanceiraPorLoja');

    const { id: idLojaAtual } = auth.getLoja();

    const handlePesquisar = handleSubmit(async () => {
      const newLojas = [...lojas];

      const valoresContaFinanceiraSelecionada = await newLojas.map(
        (loja, index) => {
          const idContaFinanceira = contaFinanceiraWatch[index];
          return {
            contaFinanceiraId: idContaFinanceira,
            lojaId: loja.id,
          };
        }
      );

      salvandoValoresContaFinanceraPorLoja(valoresContaFinanceiraSelecionada);
      onClose();
    });

    const latestProps = useRef({
      setValue,
    });

    const atualizarValoresPlanoStart = useCallback(() => {
      lojas.forEach((listaLoja, indexLoja) => {
        if (listaLoja.plano === planoStart) {
          if (
            listaLoja.contaFinanceiras[0] &&
            listaLoja.contaFinanceiras[0].id &&
            listaLoja.contaFinanceiras
          ) {
            latestProps.current.setValue(
              `contaFinanceiraPorLoja.${indexLoja}`,
              listaLoja.contaFinanceiras[0].id
            );
          }
        }
      });
    }, [lojas, planoStart]);

    useEffect(() => {
      latestProps.current = {
        setValue,
      };
    }, [setValue]);

    useEffect(() => {
      async function getLojas() {
        setIsLoading(true);
        const response = await api.get<void, ResponseApi<LojasProps[]>>(
          ConstanteEnderecoWebservice.LISTAR_LOJA_COM_CONTAS_FINANCEIRAS
        );

        if (response) {
          if (response.avisos) {
            response.avisos.forEach((avisos) => toast.warning(avisos));
            setIsLoading(false);
          }

          if (response.dados && response.sucesso) {
            const newDadosLoja = response.dados.filter(
              (loja) => loja.id !== idLojaAtual
            );
            setLojas(newDadosLoja);
            setIsLoading(false);
          }
        }
        setIsLoading(false);
        return [];
      }
      getLojas();
    }, [idLojaAtual]);

    useEffect(() => {
      async function alterarValoresContaFinanceira() {
        if (
          listaLojasContaFinanceira.length === 0 &&
          !formaPagamentoRecebimentoLoja
        ) {
          atualizarValoresPlanoStart();
        } else if (listaLojasContaFinanceira.length > 0) {
          listaLojasContaFinanceira.forEach((contaFinanceira, index) => {
            const valorDaLoja = lojas[index];
            if (valorDaLoja && valorDaLoja.contaFinanceiras) {
              const filtrandoContaFinanceira = valorDaLoja.contaFinanceiras.filter(
                (valorConta) =>
                  valorConta.id === contaFinanceira.contaFinanceiraId
              );
              if (filtrandoContaFinanceira && filtrandoContaFinanceira[0]) {
                latestProps.current.setValue(
                  `contaFinanceiraPorLoja.${index}`,
                  filtrandoContaFinanceira[0]?.id
                );
              }
            }
          });
        } else if (formaPagamentoRecebimentoLoja) {
          if (formaPagamentoRecebimentoLoja.length > 0) {
            lojas.forEach((contaFinanceira, index) => {
              const indexLoja = formaPagamentoRecebimentoLoja.findIndex(
                (buscarLoja) => buscarLoja.lojaId === contaFinanceira.id
              );
              const valorDaLoja = formaPagamentoRecebimentoLoja[indexLoja];

              if (contaFinanceira.plano === planoStart) {
                if (
                  contaFinanceira.contaFinanceiras[0] &&
                  contaFinanceira.contaFinanceiras[0].id &&
                  contaFinanceira.contaFinanceiras
                ) {
                  latestProps.current.setValue(
                    `contaFinanceiraPorLoja.${index}`,
                    contaFinanceira.contaFinanceiras[0].id
                  );
                }
              } else if (valorDaLoja && valorDaLoja.contaFinanceiraId) {
                latestProps.current.setValue(
                  `contaFinanceiraPorLoja.${index}`,
                  valorDaLoja.contaFinanceiraId
                );
              }
            });
          } else {
            atualizarValoresPlanoStart();
          }
        }
      }
      alterarValoresContaFinanceira();
    }, [
      atualizarValoresPlanoStart,
      formaPagamentoRecebimentoLoja,
      listaLojasContaFinanceira,
      lojas,
      planoStart,
    ]);

    return (
      <ModalPadraoChakra
        {...rest}
        onClose={onClose}
        isOpen={isOpen}
        isCentered={!isMobile}
        size="5xl"
      >
        <ModalContent
          borderRadius={{ base: '0', md: 'md' }}
          h={isMobile ? 'full' : '480px'}
          bg="gray.50"
          mt={isMobile ? '0' : undefined}
          mb={isMobile ? '0' : undefined}
          w={isMobile ? 'full' : '900px'}
        >
          {isLoading && <LoadingPadrao />}
          <ModalCloseButton />
          <ModalHeader pl={isMobile ? '10px' : undefined} pb="0">
            <Text
              fontSize={isMobile ? '14px' : undefined}
              color="primary.400"
              fontWeight="semibold"
            >
              Conta financeira por loja
            </Text>
          </ModalHeader>
          <FormProvider {...formMethods}>
            <ModalBody
              pt="0"
              pl={isMobile ? '0' : undefined}
              pr={isMobile ? '0' : undefined}
              overflowY="auto"
            >
              {isMobile ? (
                <Box mt="12px" w="full">
                  <Flex
                    alignItems="center"
                    pl="10px"
                    pr="10px"
                    justifyContent="space-between"
                    fontSize="sm"
                  >
                    <Text>Loja</Text>
                    <Text>Conta financeira</Text>
                  </Flex>
                  <Divider />

                  {lojas.map((loja, index) => {
                    return (
                      <Box w="full" key={loja.id}>
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                          w="full"
                          pl="10px"
                          pr="10px"
                          pt="10px"
                          pb="10px"
                        >
                          <Text
                            w={['calc(100% - 150px)', 'calc(100% - 350px)']}
                            textAlign="left"
                          >
                            {loja.fantasia}
                          </Text>

                          <Box w={['200px', '350px']}>
                            <SelectPadrao
                              required
                              isDisabled={loja.plano === planoStart}
                              errorText={
                                errors.contaFinanceiraPorLoja
                                  ? errors.contaFinanceiraPorLoja[index].message
                                  : ''
                              }
                              options={(loja.contaFinanceiras || []).map(
                                (contaFinanceira) => ({
                                  label: contaFinanceira.nome,
                                  value: contaFinanceira.id,
                                })
                              )}
                              placeholder="Selecione a conta financeira"
                              name={`contaFinanceiraPorLoja.${index}`}
                            />
                          </Box>
                        </Flex>
                        <Divider />
                      </Box>
                    );
                  })}
                </Box>
              ) : (
                <Table variant="outline">
                  <Thead>
                    <Tr
                      sx={{
                        '& > th': {
                          color: 'primary.400',
                          fontWeight: 'bold',
                          fontSize: 'sm',
                        },
                      }}
                    >
                      <Th w="60%" />
                      <Th isNumeric>Conta financeira</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {lojas.map((loja, index) => {
                      const existeMensagemErro = errors.contaFinanceiraPorLoja
                        ? !!errors.contaFinanceiraPorLoja[index]
                        : false;
                      return (
                        <Tr
                          sx={{
                            '& > td': {
                              height: '40px',
                            },
                          }}
                          key={loja.id}
                        >
                          <Td w="60%">
                            <Text textAlign="left">{loja.fantasia}</Text>
                          </Td>
                          <Td pb={existeMensagemErro ? '20px' : undefined}>
                            <SelectPadrao
                              required
                              isDisabled={loja.plano === planoStart}
                              errorText={
                                errors.contaFinanceiraPorLoja
                                  ? errors.contaFinanceiraPorLoja[index]
                                    ? errors.contaFinanceiraPorLoja[index]
                                        .message
                                    : ''
                                  : ''
                              }
                              options={(loja.contaFinanceiras || []).map(
                                (contaFinanceira) => ({
                                  label: contaFinanceira.nome,
                                  value: contaFinanceira.id,
                                })
                              )}
                              placeholder="Selecione a conta financeira"
                              name={`contaFinanceiraPorLoja.${index}`}
                            />
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              )}
            </ModalBody>

            <ModalFooter>
              <Flex
                w="full"
                h="full"
                justifyContent="center"
                alignItems="baseline"
              >
                <Button
                  color="gray.300"
                  variant="outline"
                  borderRadius="20px"
                  fontSize="sm"
                  type="button"
                  h="32px"
                  mr="24px"
                  _hover={{ bg: 'gray.50' }}
                  w="96px"
                  onClick={() => onClose()}
                >
                  Cancelar
                </Button>
                <Button
                  color="white"
                  variant=""
                  bg="aquamarine.600"
                  borderRadius="20px"
                  fontSize="sm"
                  type="button"
                  h="32px"
                  onClick={() => handlePesquisar()}
                  w="96px"
                >
                  Confirmar
                </Button>
              </Flex>
            </ModalFooter>
          </FormProvider>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
