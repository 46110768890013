import { useCallback, useEffect, useImperativeHandle } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useProdutosFormularioContext } from 'store/Produtos/ProdutosFormulario';
import api, { ResponseApi } from 'services/api';
import { useValidationFormDirtyFields } from 'hooks/useValidationFormDirtyFields';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import { ItemEcommerceMobile } from './TabPanels/components/ItemEcommerceMobile';
import { ItemEcommerce } from './TabPanels/components/ItemEcommerce';
import { valueTabs } from './TabPanels/validationForms';
import { useProdutoEcommerce, ValoresEcommerceProps } from './hooks';

export const Ecommerce = () => {
  const {
    idProduto,
    setIsLoading,
    ref,
    onChangeFormIsDirty,
  } = useProdutosFormularioContext();

  const {
    isLargerThan900,
    salvarDadosEcommerces,
    obterDadosEcommerces,
    obterDadosFormularioEcommerces,
  } = useProdutoEcommerce();

  const formMethods = useForm();

  const {
    getValues,
    formState: { dirtyFields },
    reset,
  } = formMethods;

  const isDirty = useValidationFormDirtyFields(dirtyFields);

  const resetarForm = useCallback(async () => {
    setIsLoading(true);

    const response = await obterDadosEcommerces(idProduto);

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response?.sucesso && response?.dados.length > 0) {
        const element = {} as { [key: string]: string | number | boolean };
        // para manter uma lógica mais simples e fácil, quando for passar o "name" nos campos do formulário do ecommerce
        // passar sempre o nome que vem da api e a identificação da integração, assim nessa função já altera todos os valores corretamente.
        // ex: name={`anunciado-${identificacao}`} olhar também no componente -> import { TrayEcommerce } from './TabPanels/Tray' para entender a estrutura também
        response.dados.forEach((dataEcommerce) => {
          const identificacao = dataEcommerce.identificacaoIntegracao;
          Object.keys(dataEcommerce).forEach((key) => {
            if (dataEcommerce && dataEcommerce[key]) {
              const valor = dataEcommerce[key];
              element[`${key}-${identificacao}`] = valor;
            }
          });
        });

        reset(element);
        setIsLoading(false);
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [setIsLoading, obterDadosEcommerces, idProduto, reset]);

  const salvarDados = useCallback(async () => {
    if (isDirty === false) {
      return true;
    }

    setIsLoading(true);

    const dados = obterDadosFormularioEcommerces(idProduto, getValues);
    const response = await salvarDadosEcommerces(idProduto, dados);

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
        setIsLoading(false);
        return false;
      }

      if (response.sucesso) {
        setIsLoading(false);
        return true;
      }
      setIsLoading(false);
      return false;
    }
    setIsLoading(false);
    return true;
  }, [
    getValues,
    idProduto,
    isDirty,
    obterDadosFormularioEcommerces,
    salvarDadosEcommerces,
    setIsLoading,
  ]);

  useImperativeHandle(ref, () => ({
    onClick: () => salvarDados(),
  }));

  useEffect(() => {
    resetarForm();
  }, [resetarForm]);

  useEffect(() => {
    onChangeFormIsDirty(isDirty);
  }, [isDirty, onChangeFormIsDirty]);

  return (
    <FormProvider {...formMethods}>
      {isLargerThan900 ? <ItemEcommerce /> : <ItemEcommerceMobile />}
    </FormProvider>
  );
};
