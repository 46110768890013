import auth from 'modules/auth';
import ConstanteFuncionalidades from 'constants/permissoes';
import { EnumRelatorioVendas } from 'constants/enum/enumRelatorioVendas';

export const validarTipoRelatorioVendas = (item: {
  value: number;
}): boolean => {
  let tipoRelatorio = '';
  const enumRelatorio = EnumRelatorioVendas;

  switch (item.value) {
    case enumRelatorio.VENDAS_FORMA_RECEBIMENTO:
      tipoRelatorio =
        ConstanteFuncionalidades.RELATORIO_VENDAS_POR_FORMA_RECEBIMENTO;
      break;
    case enumRelatorio.VENDAS_POR_DIA:
      tipoRelatorio = ConstanteFuncionalidades.RELATORIO_VENDAS_POR_DIA;
      break;
    case enumRelatorio.VENDAS_SIMPLIFICADAS:
      tipoRelatorio = ConstanteFuncionalidades.RELATORIO_VENDA_SIMPLIFICADAS;
      break;
    case enumRelatorio.VENDAS_TOTALIZADAS_VENDEDORES:
      tipoRelatorio =
        ConstanteFuncionalidades.RELATORIO_VENDAS_TOTALIZADAS_VENDEDORES;
      break;
    case enumRelatorio.VENDAS_TOTALIZADAS_PRODUTOS:
      tipoRelatorio =
        ConstanteFuncionalidades.RELATORIO_VENDAS_TOTALIZADAS_PRODUTOS;
      break;
    default:
      break;
  }

  const possuiFuncionalidade = auth.possuiPermissao(tipoRelatorio).permitido;

  return possuiFuncionalidade;
};
