import { Box, Flex, Icon, Text } from '@chakra-ui/react';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';

import { TodosTamanhos, VariacaoLinha } from '../types';
import { DetalhesEstoqueLojaAtual } from './DetalhesEstoque/detalhesLojaAtual';

type DadosLojaAtualPdvMobileProps = {
  variacoesEstoqueAgrupado: VariacaoLinha[];
  handleToggleItemSelecionado(index: number): void;
};

export const DadosLojaAtualPdvMobile = ({
  variacoesEstoqueAgrupado,
  handleToggleItemSelecionado,
}: DadosLojaAtualPdvMobileProps) => {
  return (
    <Box w="full" mt="-10px">
      {variacoesEstoqueAgrupado.length > 0 ? (
        <>
          {variacoesEstoqueAgrupado.map(
            ({ cor, tamanhos, valorTamanho }, index) => {
              const totalValores = tamanhos.reduce(
                (acc, curr) => acc + (curr.quantidade || 0),
                0
              );

              const agruparTodosTamanhos = tamanhos.reduce((acc, curr) => {
                const itemAlreadyAddedIndex = acc.findIndex(
                  (item) => item.tamanho === curr.tamanho
                );

                if (itemAlreadyAddedIndex > -1) {
                  acc.splice(itemAlreadyAddedIndex, 1, {
                    ...acc[itemAlreadyAddedIndex],
                    quantidade:
                      acc[itemAlreadyAddedIndex].quantidade ||
                      0 + curr.quantidade,
                    locaisEstoque: [
                      ...acc[itemAlreadyAddedIndex].locaisEstoque,
                      {
                        quantidade: curr.quantidade,
                        localEstoque: curr.localEstoque,
                      },
                    ],
                  });
                } else {
                  acc.push({
                    tamanho: curr.tamanho,
                    selecionado: curr.selecionado,
                    quantidade: curr.quantidade,
                    locaisEstoque: [
                      {
                        quantidade: curr.quantidade,
                        localEstoque: curr.localEstoque,
                      },
                    ],
                  });
                }

                return acc;
              }, [] as TodosTamanhos[]);
              const existeItemSelecionado =
                variacoesEstoqueAgrupado[index].tamanhos[index]?.selecionado;

              return (
                <Box
                  mt="10px"
                  w="full"
                  borderRadius="4px"
                  padding="10px"
                  border="1px solid gray"
                  key={`${cor}`}
                >
                  <Box>
                    <Text color="primary.50" fontSize="16px">
                      {cor || ''}
                    </Text>
                    <Box>
                      <Flex justifyContent="space-between">
                        <Text fontSize="12px">
                          {valorTamanho
                            ? agruparTodosTamanhos.length > 1
                              ? 'Ver tamanhos'
                              : 'Ver tamanho'
                            : 'Ver quantidade'}
                          <Icon
                            color="black"
                            mt="-3px"
                            ml="3px"
                            onClick={() => handleToggleItemSelecionado(index)}
                            as={existeItemSelecionado ? FiArrowUp : FiArrowDown}
                          />
                        </Text>
                        <Text fontSize="12px">Total: {totalValores}</Text>
                      </Flex>

                      {agruparTodosTamanhos
                        .filter(
                          (tamanhoSelecionado) => tamanhoSelecionado.selecionado
                        )
                        .map((itemTamanho) => {
                          const somandoValoresQuantidade = itemTamanho.locaisEstoque.reduce(
                            (acc, curr) => acc + curr.quantidade,
                            0
                          );

                          return (
                            <DetalhesEstoqueLojaAtual
                              itemTamanho={itemTamanho}
                              somandoValoresQuantidade={
                                somandoValoresQuantidade
                              }
                            />
                          );
                        })}
                    </Box>
                  </Box>
                </Box>
              );
            }
          )}
        </>
      ) : (
        <Box>Não existem registros para serem mostrados</Box>
      )}
    </Box>
  );
};
