import { useCallback, useEffect, useState } from 'react';
import {
  DrawerCloseButton,
  useMediaQuery,
  GridItem,
  Text,
  FormLabel,
  Box,
  Button,
  Icon,
  Flex,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Divider,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { FiXCircle } from 'react-icons/fi';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import StatusConsultaEnum from 'constants/enum/statusConsulta';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import TipoFiltroProdutoEstoqueEnum from 'constants/enum/tipoFiltroProdutoEstoque';
import TipoCadastroCampoPersonalizadoEnum from 'constants/enum/tipoCadastroCampoPersonalizado';

import { LupaIcon } from 'icons';
import Input from 'components/PDV/Input';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import { SelectMulti } from 'components/Select/SelectMultiCheckbox';
import { SelectCategoria } from 'components/Select/SelectCategoria';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import DrawerPadraoChakra from 'components/PDV/Drawer/DrawerPadraoChakra';
import { CamposPersonalizados } from 'components/update/CamposPersonalizados';

import CampoPersonalizadoInterface from 'types/campoPersonalizado';

import {
  FiltrosProps,
  OptionsResponseProps,
  OptionsProps,
} from '../validationForm';

type BuscaAvancadaProps = {
  currentFilters: FiltrosProps;
  filtersSubmit: (filters: FiltrosProps) => void;
  filtrosReset: FiltrosProps;
  isOpen: boolean;
  onClose: () => void;
};

export const DrawerBuscaAvancada = ({
  filtersSubmit,
  isOpen,
  onClose,
  filtrosReset,
  currentFilters,
}: BuscaAvancadaProps) => {
  const [cores, setCores] = useState<OptionsProps[]>([]);
  const [coresIsLoading, setCoresIsLoading] = useState(true);
  const [tamanhos, setTamanhos] = useState<OptionsProps[]>([]);
  const [tamanhosIsLoading, setTamanhosIsLoading] = useState(true);
  const [marcas, setMarcas] = useState<OptionsProps[]>([]);
  const [marcasIsLoading, setMarcasIsLoading] = useState(true);
  const [tags, setTags] = useState<OptionsProps[]>([]);
  const [tagsIsLoading, setTagsIsLoading] = useState(true);
  const [camposPersonalizados, setCamposPersonalizados] = useState<
    CampoPersonalizadoInterface[]
  >([]);
  const [
    camposPersonalizadosIsLoading,
    setCamposPersonalizadosIsLoading,
  ] = useState(true);

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const formMethods = useForm<FiltrosProps>({
    defaultValues: currentFilters,
  });

  const { setValue, getValues, handleSubmit: submit, reset } = formMethods;

  const handleSubmit = submit((data) => {
    filtersSubmit(data);

    onClose();
  });

  const handleLimparPesquisa = () => {
    reset(filtrosReset);
    filtersSubmit(filtrosReset);
    onClose();
  };

  const dadosAPi = useCallback(
    (
      response: ResponseApi<OptionsResponseProps[]>,
      setStateValue: (value: React.SetStateAction<OptionsProps[]>) => void
    ) => {
      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }

        if (response.sucesso) {
          setStateValue(
            response.dados.map((tamanho) => {
              return {
                label: tamanho.nome,
                value: tamanho.id,
              };
            })
          );

          setValue('cores', currentFilters.cores);
          setValue('tamanhos', currentFilters.tamanhos);
          setValue('categoriasProduto', currentFilters.categoriasProduto);
          setValue('marcas', currentFilters.marcas);
          setValue('tags', currentFilters.tags);
        }
      }
    },
    [currentFilters, setValue]
  );

  const getCores = useCallback(async () => {
    setCoresIsLoading(true);
    const response = await api.get<void, ResponseApi<OptionsResponseProps[]>>(
      ConstanteEnderecoWebservice.COR_LISTAR_SELECT,
      {
        params: {
          statusConsulta: StatusConsultaEnum.TODOS,
          listarPadraoSistema: true,
        },
      }
    );

    await dadosAPi(response, setCores);
    setCoresIsLoading(false);
  }, [dadosAPi]);

  const getTamanhos = useCallback(async () => {
    setTamanhosIsLoading(true);
    const response = await api.get<void, ResponseApi<OptionsResponseProps[]>>(
      ConstanteEnderecoWebservice.TAMANHO_LISTAR_SELECT,
      {
        params: {
          statusConsulta: StatusConsultaEnum.TODOS,
          listarPadraoSistema: true,
        },
      }
    );

    await dadosAPi(response, setTamanhos);
    setTamanhosIsLoading(false);
  }, [dadosAPi]);

  const getMarcas = useCallback(async () => {
    setMarcasIsLoading(true);
    const response = await api.get<void, ResponseApi<OptionsResponseProps[]>>(
      ConstanteEnderecoWebservice.MARCA_LISTAR_SELECT,
      {
        params: {
          statusconsulta: 1,
        },
      }
    );
    await dadosAPi(response, setMarcas);
    setMarcasIsLoading(false);
  }, [dadosAPi]);

  const getTags = useCallback(async () => {
    setTagsIsLoading(true);
    const response = await api.get<void, ResponseApi<OptionsResponseProps[]>>(
      ConstanteEnderecoWebservice.TAG_LISTAR_SELECT
    );
    await dadosAPi(response, setTags);
    setTagsIsLoading(false);
  }, [dadosAPi]);

  const getCamposPersonalizados = useCallback(async () => {
    setCamposPersonalizadosIsLoading(true);
    const response = await api.get<
      void,
      ResponseApi<CampoPersonalizadoInterface[]>
    >(ConstanteEnderecoWebservice.CAMPO_PERSONALIZADO_LISTARPORTIPOCADASTRO, {
      params: { tipoCadastro: TipoCadastroCampoPersonalizadoEnum.PRODUTO },
    });

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso) {
        setCamposPersonalizados(response.dados);

        const currentCamposPersonalizados = getValues('camposPersonalizados');

        const valueCampoPersonalizado = response.dados.map(
          (campoPersonalizado) => {
            const currentCampoPersonalizado = currentCamposPersonalizados
              ? (currentCamposPersonalizados || []).find(
                  (valueCampo) =>
                    valueCampo?.campoPersonalizadoId === campoPersonalizado.id
                )
              : undefined;

            return {
              campoPersonalizadoId: campoPersonalizado.id,
              valor: currentCampoPersonalizado
                ? currentCampoPersonalizado.valor
                : null,
            };
          }
        );

        setValue('camposPersonalizados', valueCampoPersonalizado);
      }
    }
    setCamposPersonalizadosIsLoading(false);
  }, [getValues, setValue]);

  useEffect(() => {
    getCores();
  }, [getCores]);

  useEffect(() => {
    getCamposPersonalizados();
  }, [getCamposPersonalizados]);

  useEffect(() => {
    getTags();
  }, [getTags]);

  useEffect(() => {
    getMarcas();
  }, [getMarcas]);

  useEffect(() => {
    getTamanhos();
  }, [getTamanhos]);

  useEffect(() => {
    setValue('nomeReferencia', currentFilters?.nomeReferencia);
  }, [currentFilters, getTamanhos, setValue]);

  return (
    <DrawerPadraoChakra
      isOverlay={false}
      size={isLargerThan900 ? ' lg' : 'full'}
      onClose={onClose}
      isOpen={isOpen}
    >
      <DrawerContent
        style={{
          width: isLargerThan900 ? '620px' : '',
        }}
        bg="gray.50"
        pr="5px"
        overflow="scroll"
        boxShadow="-6px 0px 20px #00000029"
      >
        <DrawerHeader>
          <Text color="primary.50" fontSize="md">
            Busca avançada
          </Text>
          <Text color="gray.700" fontSize="xs">
            Utilize os filtros de busca para uma pesquisa específica. Todos os
            campos são acumulativos.
          </Text>
          <DrawerCloseButton />
          <Divider size="1px" mt="20px" bgColor="gray.600" />
        </DrawerHeader>
        <DrawerBody>
          <FormProvider {...formMethods}>
            <SimpleGridForm>
              <GridItem colSpan={12}>
                <Input
                  id="nomeReferencia"
                  placeholder="Digite o nome ou a referência"
                  autoFocus
                  maxLength={50}
                  label="Descrição do produto"
                  name="nomeReferencia"
                />
              </GridItem>
              <GridItem colSpan={{ base: 12, md: 6, lg: 6 }}>
                <Input
                  id="sku"
                  placeholder="Digite o código"
                  maxLength={50}
                  label="Código SKU"
                  name="sku"
                />
              </GridItem>
              <GridItem colSpan={{ base: 12, md: 6, lg: 6 }}>
                <Input
                  id="codigoBarras"
                  placeholder="Digite o código da etiqueta"
                  maxLength={50}
                  label="Código de barras"
                  name="codigoBarras"
                />
              </GridItem>
              <GridItem colSpan={{ base: 12, md: 6, lg: 6 }}>
                <SelectPadrao
                  label="Estoque"
                  required
                  id="tipoEstoque"
                  placeholder="Clique aqui para selecionar."
                  defaultValue={TipoFiltroProdutoEstoqueEnum.produtos[0]}
                  name="tipoEstoque"
                  options={TipoFiltroProdutoEstoqueEnum.produtos}
                />
              </GridItem>
              <GridItem colSpan={{ base: 12, md: 6, lg: 6 }}>
                <SelectPadrao
                  label="Status"
                  required
                  id="statusConsulta"
                  placeholder="Clique aqui para selecionar."
                  defaultValue={StatusConsultaEnum.status[0]}
                  name="statusConsulta"
                  options={StatusConsultaEnum.status}
                />
              </GridItem>
              <GridItem colSpan={12}>
                <FormLabel
                  mb="1px"
                  fontSize="sm"
                  color="black"
                  lineHeight="1.2"
                >
                  Cores
                </FormLabel>
                <SelectMulti
                  name="cores"
                  changeBackgroundOption
                  optionBackgroundColor="teal.600"
                  optionColor="yellow.400"
                  textLabelSelectAll="Todas as cores"
                  id="cores"
                  isLoading={coresIsLoading}
                  placeholder="Digite o nome da cor e clique para selecionar"
                  variant="outline"
                  options={cores}
                  isMulti
                  isSearchable={false}
                  closeMenuOnSelect={false}
                />
              </GridItem>
              <GridItem colSpan={12}>
                <FormLabel
                  mb="1px"
                  fontSize="sm"
                  color="black"
                  lineHeight="1.2"
                >
                  Tamanhos
                </FormLabel>
                <SelectMulti
                  name="tamanhos"
                  textLabelSelectAll="Todos os tamanhos"
                  isLoading={tamanhosIsLoading}
                  id="tamanhos"
                  changeBackgroundOption
                  optionBackgroundColor="pink.700"
                  optionColor="yellow.400"
                  placeholder="Digite o tamanho e clique para selecionar"
                  variant="outline"
                  options={tamanhos}
                  isMulti
                  isSearchable={false}
                  closeMenuOnSelect={false}
                />
              </GridItem>
              <GridItem colSpan={12}>
                <FormLabel
                  mb="1px"
                  fontSize="sm"
                  color="black"
                  lineHeight="1.2"
                >
                  Categoria
                </FormLabel>
                <SelectCategoria
                  name="categoriasProduto"
                  placeholder="Digite o nome da categoria e clique para selecionar"
                />
              </GridItem>
              <GridItem colSpan={12}>
                <FormLabel
                  mb="1px"
                  fontSize="sm"
                  color="black"
                  lineHeight="1.2"
                >
                  Marca
                </FormLabel>
                <SelectMulti
                  name="marcas"
                  textLabelSelectAll="Todas as marcas"
                  id="marcas"
                  isLoading={marcasIsLoading}
                  placeholder="Digite o nome da marca e clique para selecionar"
                  variant="outline"
                  options={marcas}
                  isMulti
                  isSearchable={false}
                  closeMenuOnSelect={false}
                />
              </GridItem>
              <GridItem colSpan={12}>
                <FormLabel
                  mb="1px"
                  fontSize="sm"
                  color="black"
                  lineHeight="1.2"
                >
                  Tags
                </FormLabel>
                <SelectMulti
                  name="tags"
                  isLoading={tagsIsLoading}
                  textLabelSelectAll="Todas as tags"
                  id="tags"
                  placeholder="Digite o nome da TAG e clique para selecionar"
                  variant="outline"
                  options={tags}
                  isMulti
                  isSearchable={false}
                  closeMenuOnSelect={false}
                />
              </GridItem>
            </SimpleGridForm>
            {camposPersonalizadosIsLoading && <LoadingPadrao />}
            <Box
              mt="24px"
              borderRadius="5px"
              px="32px"
              py="16px"
              borderColor="gray.200"
              bg="gray.100"
              borderWidth="1px"
            >
              <CamposPersonalizados
                borderColor="gray.300"
                camposPersonalizados={camposPersonalizados}
              />
            </Box>
          </FormProvider>
          <Flex
            mt="25px"
            mb="10px"
            w="full"
            h={['230px', '200px', 'auto']}
            justifyContent={['start', 'start', 'center']}
            direction={['column', 'column', 'row']}
          >
            <Button
              w={['full', 'full', '150px']}
              variant="outlineDefault"
              colorScheme="gray"
              onClick={() => handleLimparPesquisa()}
              leftIcon={<Icon as={FiXCircle} />}
            >
              Limpar filtros
            </Button>
            <Button
              ml={['0', '0', '20px']}
              mt={['10px', '10px', '0']}
              w={['full', 'full', '132px']}
              variant="solid"
              color="violet.900"
              colorScheme="secondary.400"
              onClick={() => handleSubmit()}
              leftIcon={<Icon as={LupaIcon} />}
            >
              Pesquisar
            </Button>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </DrawerPadraoChakra>
  );
};
