import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory, Prompt, RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import isPrenvent from 'helpers/layout/isPrenvent';
import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import RodapeFormulario from 'components/Geral/RodapeFormulario';
import ManterFoco from 'components/Geral/ManterFoco';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { Card } from 'styles';
import StatusFiscaisEnum from 'constants/enum/fiscal/statusFiscal';
import { TipoRejeicaoNotaFiscal } from 'constants/enum/fiscal/tipoRejeicaoNotaFiscal';
import { removeMaskCNPJ } from 'helpers/format/removeMask';
import { FormProvider } from 'react-hook-form';
import NotaFiscalFormularioProvider from 'store/NotaFiscal/NotaFiscalFormulario';
import auth from 'modules/auth';
import PlanoContratacaoEnum from 'constants/enum/planoContratacao';
import formatUTCToLocateDateTimeInput from 'helpers/format/formatUTCToLocateDateTimeInput';
import { SalvarConfirmarIcon } from 'icons';
import ModalTransmissaoNota from 'pages/NotasFiscais/ModalTransmissaoNota';
import { ModalAtencao } from 'components/Modal/ModalAtencao';
import { useSignalRContext } from 'store/SignalR';
import { cnpjMask } from 'helpers/format/fieldsMasks';
import { getName } from 'helpers/enum/getName';
import { ModalRejeicaoNotaFiscal } from 'components/Modal/ModalRejeicaoNotaFiscal';
import TipoCertificadoEnum from 'constants/enum/fiscal/tipoCertificado';
import ModelosFiscaisEnum from 'constants/enum/fiscal/modelosFiscais';
import { getCurrentDateWithoutSeconds } from 'helpers/data/getCurrentDateWithoutSeconds';
import { useForm, yupResolver } from '../validationForm';
import { Container } from '../styles';

import { UncontrolledForm, validarPagamentoIgualValorTotal } from '..';

type TParams = { id: string };

interface RejeicaoInterface {
  Mensagem: string;
  LinkBaseConhecimento: string;
  TipoRejeicao: TipoRejeicaoNotaFiscal;
}

const Alterar: React.FC<RouteComponentProps<TParams>> = ({ match }) => {
  const history = useHistory();
  const isMountedRef = useIsMountedRef();
  isPrenvent();

  const [isLoading, setIsLoading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);

  const [dataHoraCadastro, setDataHoraCadastro] = useState('');
  const [dataHoraUltimaAlteracao, setDataHoraUltimaAlteracao] = useState('');
  const [alteracaoCarregada, setAlteracaoCarregada] = useState(false);
  const [showTransmitindoNota, setShowTransmitindoNota] = useState(false);
  const [labelStatusTransmisssao, setLabelStatusTransmisssao] = useState('');
  const { hubConnection, joinGroup, exitGroup } = useSignalRContext();

  const refTabInicial = useRef() as React.MutableRefObject<HTMLAnchorElement>;

  const formMethods = useForm({
    resolver: yupResolver,
    shouldUnregister: true,
  });

  const planoAtual = auth.getPlano();
  const isPlanoTeste = PlanoContratacaoEnum.TESTE;

  const handleGetNotaFiscal = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<void, ResponseApi<any>>(
      ConstanteEnderecoWebservice.NOTA_FISCAL_OBTER,
      {
        params: { id: match.params.id },
      }
    );

    if (response?.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (response?.sucesso && isMountedRef.current) {
      const { dados } = response;

      formMethods.reset({
        ...dados,
        dataEmissao: formatUTCToLocateDateTimeInput(dados.dataEmissao),
        dataSaida: dados.dataSaida
          ? formatUTCToLocateDateTimeInput(dados.dataSaida)
          : null,
        documentoFiscalPagamentos: dados.documentoFiscalPagamentos.map(
          (item: any) => {
            return {
              ...item,
              vencimento: formatUTCToLocateDateTimeInput(item.vencimento),
            };
          }
        ),
        cnpjIntermediador: dados.cnpjIntermediador
          ? cnpjMask(dados.cnpjIntermediador)
          : null,
      });
      formMethods.setValue('modeloFiscal', dados.modeloFiscal);

      setDataHoraCadastro(response.dados.dataHoraCadastro);
      setDataHoraUltimaAlteracao(response.dados.dataHoraUltimaAlteracao);

      const cliente = response.dados.cliente.obj;

      formMethods.setValue(
        'destinatarioEnderecoEntrega',
        cliente.descricaoEnderecoEntrega
      );

      formMethods.setValue(
        'destinatarioEnderecoCobranca',
        cliente.descricaoEnderecoCobranca
      );

      const statusDescricao = getName(StatusFiscaisEnum, response.dados.status);

      formMethods.setValue('status', statusDescricao);
    } else {
      history.push(ConstanteRotas.NOTA_FISCAL);
    }

    setAlteracaoCarregada(true);
    if (isMountedRef.current) setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id, isMountedRef]);

  const handleSalvar = async (): Promise<boolean> => {
    const {
      identificacaoNoIntermediador,
      cnpjIntermediador,
      ...data
    } = formMethods.getValues();
    const valorTotalParcela = formMethods.watch('valorTotal', 0);
    if (!validarPagamentoIgualValorTotal(data, valorTotalParcela)) return false;
    const dataPut = {
      id: match.params.id,
      ...data,
      dataEmissao:
        data.modeloFiscal === ModelosFiscaisEnum.NFCe
          ? getCurrentDateWithoutSeconds()
          : data.dataEmissao,
      status: StatusFiscaisEnum.EmDigitacao,
      clienteId: data.cliente.obj.id,
      transportadoraId: data.transportadora?.value,
      documentoFiscalReferenciados: data.documentoFiscalReferenciados.map(
        (item: any) => {
          return { ...item, cnpj: removeMaskCNPJ(item.cnpj) };
        }
      ),
      documentoFiscalImportacoes: data.documentoFiscalImportacoes.map(
        (item: any) => {
          return {
            ...item,
            cnpjAdquirente: removeMaskCNPJ(item.cnpjAdquirente),
          };
        }
      ),
      documentoFiscalItens: data.documentoFiscalItens.map((item: any) => {
        return {
          ...item,
          regraFiscalItemId: item?.regraFiscal
            ? item.regraFiscal.obj?.id
            : null,
          regraFiscal: item?.regraFiscal ? item.regraFiscal : null,
        };
      }),
      ...(data.operacaoComIntermediador && {
        identificacaoNoIntermediador,
      }),
      ...(data.operacaoComIntermediador && {
        cnpjIntermediador,
      }),
    };

    const response = await api.put<void, ResponseApi>(
      ConstanteEnderecoWebservice.NOTA_FISCAL_ALTERAR,
      dataPut
    );

    if (response.sucesso) {
      return true;
    }

    if (response.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    return false;
  };

  const tratarAviso = (aviso: string, statusNota?: number) => {
    try {
      const rejeicao = JSON.parse(aviso) as RejeicaoInterface;

      ModalRejeicaoNotaFiscal({
        ...rejeicao,
        callback: () => {
          if (statusNota === StatusFiscaisEnum.RetornoIndisponivel) {
            history.push(ConstanteRotas.NOTA_FISCAL);
          }
        },
      });
    } catch {
      ModalAtencao({
        text: aviso,
        width: 800,
        callback: () => {
          if (statusNota === StatusFiscaisEnum.RetornoIndisponivel) {
            history.push(ConstanteRotas.NOTA_FISCAL);
          }
        },
      });
    }
  };

  const obterStatusNotaFiscal = useCallback(async (id: string) => {
    const response = await api.get<void, ResponseApi<number>>(
      ConstanteEnderecoWebservice.NOTA_FISCAL_OBTER_STATUS,
      {
        params: { id },
      }
    );
    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }
      if (response?.sucesso) {
        return response?.dados;
      }
    }
    return undefined;
  }, []);

  const onSubmitTransmitir = formMethods.handleSubmit(
    async () => {
      const groupMessage = `${match.params.id}_transmitindo-nota`;

      setLabelStatusTransmisssao('Salvando...');
      setShowTransmitindoNota(true);

      joinGroup(groupMessage);

      hubConnection.on('alterar-status-transmissao', (message: string) => {
        setLabelStatusTransmisssao(message);
      });

      if (await handleSalvar()) {
        setLabelStatusTransmisssao('Enviando a nota fiscal para a SEFAZ...');

        const retorno = await api.post<void, ResponseApi>(
          `${ConstanteEnderecoWebservice.NOTA_FISCAL_TRANSMITIR}/${match.params.id}`
        );

        if (retorno.sucesso) {
          if (
            retorno.dados &&
            retorno.dados === Number(TipoCertificadoEnum.A3)
          ) {
            const timeout = window.setTimeout(
              () => {
                setShowTransmitindoNota(false);
                exitGroup(groupMessage);

                ModalAtencao({
                  title: 'A comunicação com o certificado digital A3 falhou',
                  text:
                    'Verifique se o certificado digital está conectado no computador e se o aplicativo Módulo desktop está sendo executado.',
                });
              },
              1000 * 60 // 1 minuto
            );

            hubConnection.off('sucesso-transmissao-a3');
            hubConnection.on('sucesso-transmissao-a3', () => {
              window.clearTimeout(timeout);

              setShowTransmitindoNota(false);
              toast.success('A nota fiscal foi transmitida com sucesso.');
              setFormIsDirty(false);
              history.push(ConstanteRotas.NOTA_FISCAL);
            });

            hubConnection.off('rejeicao-transmissao-a3');
            hubConnection.on(
              'rejeicao-transmissao-a3',
              async (message: string) => {
                window.clearTimeout(timeout);
                setFormIsDirty(false);
                setShowTransmitindoNota(false);
                const statusNota = await obterStatusNotaFiscal(match.params.id);
                tratarAviso(message, statusNota);
                exitGroup(groupMessage);
              }
            );

            return;
          }

          exitGroup(match.params.id);
          toast.success('A nota fiscal foi transmitida com sucesso.');
          setFormIsDirty(false);

          history.push(ConstanteRotas.NOTA_FISCAL);
          return;
        }

        setShowTransmitindoNota(false);

        if (retorno.avisos) {
          setFormIsDirty(false);
          const statusNota = await obterStatusNotaFiscal(match.params.id);
          tratarAviso(retorno.avisos[0], statusNota);
        }
      } else {
        setShowTransmitindoNota(false);
      }
    },
    () => {
      refTabInicial.current.click();
    }
  );

  const onSubmit = formMethods.handleSubmit(
    async () => {
      setIsLoading(true);

      if (await handleSalvar()) {
        toast.success('O cadastro foi alterado com sucesso.');

        setFormIsDirty(false);

        history.push(ConstanteRotas.NOTA_FISCAL);
      }

      setIsLoading(false);
    },
    () => {
      refTabInicial.current.click();
    }
  );

  useEffect(() => {
    setIsLoading(true);

    handleGetNotaFiscal();
  }, [handleGetNotaFiscal]);

  useEffect(() => {
    setFormIsDirty(formMethods.formState.isDirty);
  }, [formMethods.formState.isDirty]);

  return (
    <>
      <Container>
        <Prompt when={formIsDirty} message="" />
        <FormProvider {...formMethods}>
          <Form>
            <ManterFoco blockTab={isLoading}>
              <Card>
                {isLoading && <LoadingPadrao />}
                <NotaFiscalFormularioProvider
                  readonly={false}
                  alteracaoCarregada={alteracaoCarregada}
                >
                  <UncontrolledForm refTabInicial={refTabInicial} />
                </NotaFiscalFormularioProvider>
              </Card>
              <RodapeFormulario
                dataHoraCriacao={dataHoraCadastro}
                dataHoraUltimaAlteracao={dataHoraUltimaAlteracao}
                onSubmit={onSubmit}
                disabled={isLoading}
                onSubmitReset={onSubmitTransmitir}
                isDisabledReset={isPlanoTeste === planoAtual}
                textSubmitReset="Salvar e transmitir"
                iconSubmitReset={SalvarConfirmarIcon}
              />
            </ManterFoco>
          </Form>
        </FormProvider>
      </Container>
      <ModalTransmissaoNota
        show={showTransmitindoNota}
        message={labelStatusTransmisssao}
      />
    </>
  );
};

export default Alterar;
