import {
  Accordion,
  AccordionPanel,
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  Text,
} from '@chakra-ui/react';
import { Controller, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { FiInfo } from 'react-icons/fi';
import { useCallback } from 'react';

import { IoMdArrowDownRight, OutrasDespesasIcon } from 'icons';

import Item from '../Item';
import { PrintManagerProps } from '../../types';
import { useGerenciadorImpressao } from '../../hooks';

type ManagersProps = {
  openModalNewPrintManagers: () => void;
  openModalUpdateManager: (id: string) => void;
  removePrintManager: (id: string) => Promise<boolean>;
  getListPrintManager: () => Promise<void>;
  printManagerList: PrintManagerProps[];
  hasPrintManager: boolean;
};

const Managers = ({
  openModalNewPrintManagers,
  openModalUpdateManager,
  removePrintManager,
  getListPrintManager,
  printManagerList,
  hasPrintManager,
}: ManagersProps) => {
  const { formMethods, isLargerThan700 } = useGerenciadorImpressao();

  const handleRemoveItem = useCallback(
    async (id: string) => {
      const success = await removePrintManager(id);

      if (success) {
        toast.success('Gerenciador removido com sucesso');
        await getListPrintManager();
      }
    },
    [getListPrintManager, removePrintManager]
  );

  return (
    <FormProvider {...formMethods}>
      <Box overflowX="auto" h="max">
        <Flex
          align="center"
          flexDir="row"
          justify="space-between"
          gap={{ base: 2, sm: 0 }}
          px={4}
          pb={4}
        >
          {isLargerThan700 && (
            <Text fontSize="lg" fontWeight="bold" textColor="black">
              Gerenciadores
            </Text>
          )}
          <Button
            aria-label="adicionar-departamento"
            colorScheme="secondary"
            w={{ base: 'full', sm: 'max' }}
            borderRadius="full"
            leftIcon={<Icon as={OutrasDespesasIcon} fontSize="xl" />}
            onClick={() => openModalNewPrintManagers()}
            isDisabled={false}
          >
            Adicionar novo
          </Button>
        </Flex>

        <Box w="full" overflowX="auto">
          {hasPrintManager ? (
            <Box w="max" minW="full">
              <Accordion
                defaultIndex={printManagerList?.map((_, index) => index)}
                allowMultiple
              >
                {printManagerList?.map(({ gerenciador, impressoras }) => {
                  const actionsMenuItems = [
                    {
                      content: 'Editar',
                      onClick: () => openModalUpdateManager(gerenciador.id),
                    },
                    {
                      content: 'Remover',
                      onClick: () => handleRemoveItem(gerenciador.id),
                    },
                  ];

                  return (
                    <Item
                      title={gerenciador.nome}
                      actionsMenuItems={actionsMenuItems}
                    >
                      {impressoras.map(
                        ({
                          impressora: { nome, id },
                          confirmarImpressao,
                          imprimirAutomatico,
                          quantidadeCopias,
                        }) => (
                          <AccordionPanel m={0} p={3}>
                            <Controller
                              name={`printer.${id}`}
                              render={({
                                field: { onChange, value: isOpen },
                              }) => (
                                <Box
                                  w={{ base: 'full', md: '98%' }}
                                  gap={3}
                                  display="flex"
                                  alignItems="flex-start"
                                  marginLeft="auto"
                                >
                                  <Icon
                                    as={IoMdArrowDownRight}
                                    mt={3}
                                    fontSize="16px"
                                  />
                                  <Box
                                    w="full"
                                    bg="white"
                                    boxShadow="base"
                                    borderRadius="md"
                                  >
                                    <Flex
                                      align="center"
                                      justify="space-between"
                                      px={5}
                                      py={2}
                                    >
                                      <Text
                                        whiteSpace="nowrap"
                                        fontSize="16px"
                                        cursor="pointer"
                                        onClick={() =>
                                          openModalUpdateManager(gerenciador.id)
                                        }
                                        _hover={{ textDecor: 'underline' }}
                                      >
                                        {nome}
                                      </Text>
                                      <Flex
                                        h="30px"
                                        w="32px"
                                        justify="center"
                                        align="center"
                                        cursor="pointer"
                                        borderRadius="md"
                                        onClick={() => onChange(!isOpen)}
                                        _hover={{
                                          background: 'gray.100',
                                        }}
                                      >
                                        <Icon
                                          as={FiInfo}
                                          fontSize="20px"
                                          stroke="gray.600"
                                        />
                                      </Flex>
                                    </Flex>

                                    {isOpen && (
                                      <Box
                                        w="full"
                                        pb={3}
                                        transition="all 0.3s"
                                        borderTopStyle="solid"
                                        borderTopWidth="1px"
                                        borderTopColor="gray.200"
                                      >
                                        <Flex px={{ base: 0, md: 5 }} w="full">
                                          <Divider
                                            orientation="vertical"
                                            borderColor="gray.500"
                                            height="61px"
                                          />
                                          <Box
                                            pt={3}
                                            textColor="blue.500"
                                            width="full"
                                            sx={{
                                              '& > div > * > span': {
                                                fontWeight: 'bold',
                                                textTransform: 'uppercase',
                                              },
                                            }}
                                          >
                                            <Flex gap="6px" align="center">
                                              <Divider
                                                orientation="horizontal"
                                                borderColor="gray.500"
                                                maxW="40px"
                                              />
                                              <Text pl={2}>
                                                Imprimir automático:{' '}
                                                <span>
                                                  {imprimirAutomatico
                                                    ? 'Sim'
                                                    : 'Não'}
                                                </span>
                                              </Text>
                                            </Flex>
                                            <Flex gap="6px" align="center">
                                              <Divider
                                                orientation="horizontal"
                                                borderColor="gray.500"
                                                maxW="40px"
                                              />
                                              <Text pl={2}>
                                                Confirmar impressão:{' '}
                                                <span>
                                                  {confirmarImpressao
                                                    ? 'Sim'
                                                    : 'Não'}
                                                </span>
                                              </Text>
                                            </Flex>
                                            <Flex gap="6px" align="center">
                                              <Divider
                                                orientation="horizontal"
                                                borderColor="gray.500"
                                                maxW="40px"
                                              />
                                              <Text pl={2}>
                                                Número de cópias:{' '}
                                                <span>{quantidadeCopias}</span>
                                              </Text>
                                            </Flex>
                                          </Box>
                                        </Flex>
                                      </Box>
                                    )}
                                  </Box>
                                </Box>
                              )}
                            />
                          </AccordionPanel>
                        )
                      )}
                    </Item>
                  );
                })}
              </Accordion>
            </Box>
          ) : (
            <Box w="full" mt={2} p={4} fontSize="sm" borderTop="1px solid #ccc">
              <Box
                w="max"
                minW="full"
                p={3}
                bg="white"
                boxShadow="base"
                borderRadius="md"
              >
                Nenhum resultado foi encontrado
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </FormProvider>
  );
};

export default Managers;
