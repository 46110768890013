import { useState, useLayoutEffect, useRef, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { VStack, Text, Box } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import api, { ResponseApi } from 'services/api';

import Input from 'components/Autenticacao/Input';
import ButtonSubmit from 'components/Autenticacao/Button/Submit';

import { useForm, yupResolver } from './validationForm';

type FormData = {
  senha: string;
  confirmarSenha: string;
};

export const RedefinirSenha = () => {
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const isMountedRef = useIsMountedRef();

  const {
    handleSubmit,
    control,
    formState: { isValid, errors },
  } = useForm({
    resolver: yupResolver,
    mode: 'onChange',
  });

  const formIsInvalid = !isValid;

  const query = new URLSearchParams(location.search);

  const encodedUsuario = query.get('usuario');
  const encodedToken = query.get('token');

  const usuario = encodedUsuario ? decodeURIComponent(encodedUsuario) : '';
  const token = encodedToken ? decodeURIComponent(encodedToken) : '';

  const latestProps = useRef({ token, usuario, history });

  const onSubmit = handleSubmit<FormData>(async (data) => {
    setIsLoading(true);

    const dataPut = {
      token,
      usuario,
      novaSenha: data.senha,
    };

    const response = await api.put<void, ResponseApi>(
      ConstanteEnderecoWebservice.AUTENTICACAO_ALTERAR_SENHA,
      dataPut
    );
    if (response) {
      if (response.sucesso) {
        history.push(ConstanteRotas.SENHA_REDEFINIDA_COM_SUCESSO);
      }

      if (response.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }
    }
    if (isMountedRef.current) setIsLoading(false);
  });

  useEffect(() => {
    latestProps.current = { token, usuario, history };
  });

  useLayoutEffect(() => {
    if (!latestProps.current.token || !latestProps.current.usuario) {
      latestProps.current.history.push(ConstanteRotas.LOGIN);
    }
  }, []);

  return (
    <VStack spacing={9}>
      <VStack as="form" w="full" spacing={8} onSubmit={onSubmit}>
        <Box
          w="full"
          bg={{ base: 'transparent', md: 'blackAlpha.200' }}
          py={3.5}
          px={6}
          alignItems="flex-start"
        >
          <Text fontSize="sm" color="secondary.300" fontWeight="semibold">
            {usuario || 'Usuário não identificado.'}
          </Text>
        </Box>

        <VStack spacing={7} w="full">
          <Input
            id="senha"
            name="senha"
            type="password"
            label="Nova senha"
            defaultValue=""
            control={control}
            tooltipErrorProps={{
              'aria-label': 'Senha inválida',
              whiteSpace: 'pre-wrap',
              label: (
                <>
                  A senha deve conter pelo menos <strong>6 caracteres</strong>
                  , incluindo:
                  <br />
                  <br />
                  &nbsp;&nbsp;&nbsp;- Uma letra <strong>minúscula</strong>;
                  <br />
                  &nbsp;&nbsp;&nbsp;- Uma letra <strong>maiúscula</strong>;
                  <br />
                  &nbsp;&nbsp;&nbsp;- Um <strong>número</strong>;<br />
                  &nbsp;&nbsp;&nbsp;- Um <strong>caracter especial</strong>.
                </>
              ),
              lineHeight: 'tall',
            }}
            isInvalid={!!errors?.senha?.message}
            isDisabled={isLoading}
            maxLength={50}
            autoFocus
          />
          <Input
            id="confirmarSenha"
            name="confirmarSenha"
            type="password"
            label="Confirme a nova senha"
            defaultValue=""
            control={control}
            errorText={errors?.confirmarSenha?.message ?? ''}
            isInvalid={!!errors?.confirmarSenha?.message}
            isDisabled={isLoading}
            maxLength={50}
          />
        </VStack>

        <ButtonSubmit
          id="btnRedefinirSenha"
          isLoading={isLoading}
          isDisabled={isLoading || formIsInvalid}
          isInvalid={formIsInvalid}
        >
          Confirmar
        </ButtonSubmit>
      </VStack>
    </VStack>
  );
};
