import { useCallback, useEffect, useState } from 'react';
import { Control, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  Text,
  Icon,
  useBreakpointValue,
  useMediaQuery,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';

import MenuContextProvider from 'store/PDV/Menu';
import ConstanteRotasPDV from 'constants/rotasPDV';
import { useInformacoesGeraisContext } from 'store/PDV/InformacoesGerais';
import { SubstituirParametroRota } from 'constants/rotas';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';
import auth from 'modules/auth';
import PlanoContratacaoEnum from 'constants/enum/planoContratacao';
import { moneyMask } from 'helpers/format/fieldsMasks';

import Layout from 'components/PDV/Layout';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { SalvarInserirNovoIcon } from 'icons';
import { ModalTrocarProdutos } from 'components/PDV/Modal/ModalTrocarProdutos';
import { ResolucaoMobileTablet } from 'components/PDV/TrocarProdutosComponents/resolucaoMobileTablet';
import { ModalDevolverDinheiro } from 'components/PDV/Modal/ModalDevolverDinheiro';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import { InternalHeaderTrocasProduto } from './InternalHeader';
import { TrocasFooter } from './Footer';
import { ListarItensTrocasProduto } from './ListarItens';
import {
  FormData,
  Quantidade,
  formDefaultValues,
  ListaTrocaProps,
  ClienteListarProps,
  OperacaoProps,
  ProdutoSelecionadoProps,
  InformacaoAdicionalDoItemProps,
} from './Types/validationForm';

type ValidationTipoDevolucao =
  | 'devolucaoDeDinheiro'
  | 'gerarVoucher'
  | 'trocarProduto'
  | null;

export function TrocarProdutos() {
  const formMethods = useForm<FormData>({
    defaultValues: formDefaultValues,
  });

  const cliente = formMethods.watch('cliente');
  const valorQuantidadeProduto = useCallback(() => {
    return formMethods.watch('quantidade') || [];
  }, [formMethods]);
  const quantidade = valorQuantidadeProduto();
  const [listaQuantidadeFields, setListaQuantidadeFields] = useState<
    Quantidade[]
  >([]);
  const [valorQuantidade, setValorQuantidade] = useState<number[]>(quantidade);

  const [produtosTroca, setProdutosTroca] = useState<ProdutoSelecionadoProps[]>(
    []
  );
  const [
    infoComplementaresVenda,
    setInfoComplementaresVenda,
  ] = useState<ListaTrocaProps>();
  const [
    clientePadraoSistema,
    setClientePadraoSistema,
  ] = useState<ClienteListarProps>({} as ClienteListarProps);
  const [
    informacaoAdicionalDoItem,
    setInformacaoAdicionalDoItem,
  ] = useState<InformacaoAdicionalDoItemProps>();
  const [Operacao, setOperacao] = useState<OperacaoProps>({} as OperacaoProps);

  const [exibirVendasModal] = useState(true);
  const [atualizarQuantidade, setAtualizarQuantidade] = useState(true);
  const [abrirModalAutomaticamente, setAbrirModalAutomaticamente] = useState(
    true
  );
  const [existeQuantidadeFields, setExisteQuantidadeFields] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const {
    contaFinanceira,
    setDetalhesTroca,
    vendedorPdv,
  } = useInformacoesGeraisContext();

  const isInternalHeaderVisible = useBreakpointValue({ base: false, md: true });
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const [isLargerThan1400] = useMediaQuery('(min-width: 1400px)');

  const { fields, append, remove } = useFieldArray<
    FormData,
    'quantidadeFields'
  >({
    control: formMethods.control as Control<any>,
    name: 'quantidadeFields',
  });

  const existeProdutoTrocaSelecionado = produtosTroca.length > 0;
  const planoAtual = auth.getPlano();
  const planoStart = PlanoContratacaoEnum.START;

  const handleRedirectHome = useCallback(() => {
    if (existeProdutoTrocaSelecionado) {
      ModalConfirmacaoExcluir({
        title: 'Você deseja sair?',
        text: `Se você prosseguir com essa ação, a troca vai ser cancelada`,
        confirmButtonText: 'Sim, quero sair!',
        cancelButtonText: 'Cancelar',
        callback: async (ok: boolean) => {
          if (ok) {
            history.push(ConstanteRotasPDV.PDV_HOME);
          }
        },
      });
    } else {
      history.push(ConstanteRotasPDV.PDV_HOME);
    }
  }, [existeProdutoTrocaSelecionado, history]);

  const handleAtualizarQuantidade = useCallback(() => {
    if (atualizarQuantidade) {
      produtosTroca.forEach((produto, index) => {
        const quantidadePossuiDecimal = produto.quantidade % 1 !== 0;
        let qtd = 0;

        if (
          existeQuantidadeFields &&
          produtosTroca.length === listaQuantidadeFields.length
        ) {
          qtd = listaQuantidadeFields[index].quantidade;
        } else {
          qtd = quantidade[index];
        }

        formMethods.setValue(
          `quantidade.${index}`,
          qtd > 1 ? qtd : quantidadePossuiDecimal ? produto.quantidade : 1
        );
      });
      setValorQuantidade(quantidade);
    }
  }, [
    formMethods,
    quantidade,
    atualizarQuantidade,
    produtosTroca,
    existeQuantidadeFields,
    listaQuantidadeFields,
  ]);

  function handleIncrementarQuantidade(index: number) {
    const novaQuantidade = quantidade[index];
    formMethods.setValue(`quantidade.${index}`, novaQuantidade + 1);
  }

  function handleDecrementarQuantidade(index: number) {
    const novaQuantidade = quantidade[index] - 1;

    formMethods.setValue(
      `quantidade.${index}`,
      novaQuantidade >= 1 ? novaQuantidade : 1
    );
  }

  const armazenarQuantidadeFields = useCallback(() => {
    const listaFields = fields.map((item, index) => {
      return {
        ...item,
        quantidade: quantidade[index],
      };
    });
    setListaQuantidadeFields(listaFields);
  }, [fields, quantidade]);

  const obterTotalQuantidade = () => {
    const quantidadeItensLista = produtosTroca.length;
    if (valorQuantidade.length > quantidadeItensLista) {
      const novoArray = [...valorQuantidade];
      novoArray.splice(quantidadeItensLista);
      return novoArray;
    }
    return valorQuantidade;
  };
  const valorTotalQuantidade = obterTotalQuantidade();

  const limparForm = useCallback(() => {
    formMethods.reset({
      quantidade: [1],
    });
  }, [formMethods]);

  const paddingListaItem = () => {
    if (!isLargerThan900) {
      return '2%';
    }
    if (!isLargerThan1400) {
      return '5%';
    }

    return '10%';
  };

  const valorPadding = paddingListaItem();

  const valorTotal =
    Math.round(
      (produtosTroca.reduce((acc, curr, index) => {
        const valorCadaProduto =
          (curr.valor - curr.desconto) * quantidade[index];
        return valorCadaProduto + acc;
      }, 0) +
        Number.EPSILON) *
        100
    ) / 100;

  const enviarDadosDevolucao = useCallback(() => {
    if (cliente) {
      const dados = {
        numeroOperacaoOrigem: infoComplementaresVenda?.numeroVenda || 1,
        valorDevolucao: valorTotal,
        clienteFornecedorId: cliente.id,
        caixaMovimentacaoId: contaFinanceira?.caixaMovimentacao?.id,
        devolucaoItens: produtosTroca.map((devolucao, index) => {
          const valorTotalTroca =
            devolucao.valor * valorTotalQuantidade[index] - devolucao.desconto;
          const produtosParaDevolucao = {
            produtoCorTamanhoId: devolucao.produtoCorTamanhoId,
            quantidade: valorTotalQuantidade[index],
            operacaoItemOrigemId: devolucao.operacaoItemId,
            valorDesconto: devolucao.desconto,
            valorTotal: valorTotalTroca.toFixed(2),
            valorUnitario: devolucao.valor,
          };
          return produtosParaDevolucao;
        }),
      };
      return dados;
    }
    return {};
  }, [
    cliente,
    contaFinanceira,
    infoComplementaresVenda,
    produtosTroca,
    valorTotalQuantidade,
    valorTotal,
  ]);
  const dadosEnviarApi = enviarDadosDevolucao();

  const resultadoValorTotal = produtosTroca.reduce((acc, curr, index) => {
    const valorCadaProduto = (curr.valor - curr.desconto) * quantidade[index];
    return valorCadaProduto + acc;
  }, 0);

  const valorTotalAcrescimo = produtosTroca.reduce((acc, curr, index) => {
    const valorCadaProduto = curr.acrescimo * quantidade[index];
    return valorCadaProduto + acc;
  }, 0);

  const valorTotalDesconto = produtosTroca.reduce((acc, curr, index) => {
    const valorCadaProduto = curr.desconto * quantidade[index];
    return valorCadaProduto + acc;
  }, 0);

  function handleExibirModalParaSelecionarProdutosParaTroca() {
    if (existeProdutoTrocaSelecionado) {
      armazenarQuantidadeFields();
      ModalTrocarProdutos({
        setProdutosTroca,
        setExisteQuantidadeFields,
        limparForm,
        setAtualizarQuantidade,
        setInfoComplementaresVenda,
        infoComplementaresVenda,
        produtosTroca,
        setInformacaoAdicionalDoItem,
        setAbrirModalAutomaticamente,
        exibirVendasModal,
      });
    } else {
      ModalTrocarProdutos({
        setProdutosTroca,
        limparForm,
        setAtualizarQuantidade,
        setInfoComplementaresVenda,
        exibirVendasModal,
        setInformacaoAdicionalDoItem,
        setAbrirModalAutomaticamente,
      });
    }
  }

  const apiDevolucaoGerarVoucher = useCallback(
    async (devolucao: ValidationTipoDevolucao) => {
      setIsLoading(true);
      const response = await api.post<void, ResponseApi<string>>(
        ConstanteEnderecoWebservice.TROCA_DEVOLUCAO_GERAR_VOUCHER,
        dadosEnviarApi
      );

      if (response?.avisos) {
        setIsLoading(false);
        response?.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response?.sucesso && response?.dados) {
        await setOperacao({ id: response.dados, type: devolucao });
        setIsLoading(false);
      }
      setIsLoading(false);
    },
    [setOperacao, dadosEnviarApi]
  );

  const handleGerarTroca = useCallback(async () => {
    apiDevolucaoGerarVoucher('trocarProduto');
  }, [apiDevolucaoGerarVoucher]);

  const handleGerarVoucher = useCallback(async () => {
    apiDevolucaoGerarVoucher('gerarVoucher');
  }, [apiDevolucaoGerarVoucher]);

  function handleExcluirProduto(index: number) {
    quantidade.splice(index, 1);
    setValorQuantidade(quantidade);

    if (produtosTroca) {
      const novoArray = [...produtosTroca];
      novoArray.splice(index, 1);
      setProdutosTroca(novoArray);
    }

    setAtualizarQuantidade(false);
    valorQuantidade.forEach((item, indexQuantidade) => {
      formMethods.setValue(`quantidade.${indexQuantidade}`, item);
    });
  }

  async function handleAbrirModalDevolverDinheiro() {
    await ModalDevolverDinheiro({
      valorTotal,
      contaFinanceira,
      setOperacao,
      produtosTroca,
      valorTotalQuantidade,
      infoComplementaresVenda,
      cliente,
    });
  }

  useEffect(() => {
    // removendo itens que foram deselecionados no modal
    fields.forEach((item) => {
      if (
        !produtosTroca.some(
          (itemTroca) => itemTroca.operacaoItemId === item.operacaoItemId
        )
      ) {
        const novoArray = [...listaQuantidadeFields];
        novoArray.splice(fields.indexOf(item), 1);

        setListaQuantidadeFields(novoArray);
        remove(fields.indexOf(item));
      }
    });

    const novoValor = [...(listaQuantidadeFields || [])].filter((item) =>
      produtosTroca.some(
        (itemNovaLista) => itemNovaLista.operacaoItemId === item.operacaoItemId
      )
    );
    setListaQuantidadeFields(novoValor);

    // adicionado itens q foram selecionados no modal
    produtosTroca.forEach((itemTroca) => {
      if (
        !fields.some((item) => item.operacaoItemId === itemTroca.operacaoItemId)
      ) {
        const novoProduto = [
          {
            operacaoItemId: itemTroca.operacaoItemId,
            quantidade: 1,
          },
        ];
        setListaQuantidadeFields((valorAnterior) => [
          ...valorAnterior,
          ...novoProduto,
        ]);
        append({ operacaoItemId: itemTroca.operacaoItemId, quantidade: 1 });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [produtosTroca]);

  useEffect(() => {
    if (Operacao.id) {
      let rotaTroca = '';

      const infoDevolucao = {
        valorTotalTroca: Number(valorTotal),
        idOperacao: Operacao.id,
      };

      if (Operacao.type === 'trocarProduto') {
        setDetalhesTroca(infoDevolucao);
        const identificacaoTipoOperacao = IdentificacaoTipoOperacaoEnum.PEDIDO;
        const vendedor = vendedorPdv;

        history.push(ConstanteRotasPDV.PDV_LANCAMENTO, {
          cliente,
          identificacaoTipoOperacao,
          vendedor,
        });
      } else {
        if (Operacao.type === 'devolucaoDeDinheiro') {
          rotaTroca = ConstanteRotasPDV.PDV_FINALIZAR_DEVOLUCAO_DINHEIRO;
        } else if (Operacao.type === 'gerarVoucher') {
          rotaTroca = ConstanteRotasPDV.PDV_FINALIZAR_GERAR_VOUCHER;
        }

        history.push(SubstituirParametroRota(rotaTroca, 'id', Operacao.id));
      }
    }
  }, [Operacao, history, cliente, valorTotal, setDetalhesTroca, vendedorPdv]);

  useEffect(() => {
    if (cliente === undefined) {
      formMethods.setValue('cliente', clientePadraoSistema);
    }
  }, [cliente, formMethods, clientePadraoSistema]);

  useEffect(() => {
    const { pathname } = window.location;
    if (
      pathname === ConstanteRotasPDV.PDV_TROCAR_PRODUTOS &&
      abrirModalAutomaticamente &&
      planoAtual !== planoStart
    ) {
      ModalTrocarProdutos({
        setProdutosTroca,
        setInformacaoAdicionalDoItem,
        setInfoComplementaresVenda,
        exibirVendasModal,
        setAbrirModalAutomaticamente,
      });
    }
  }, [abrirModalAutomaticamente, exibirVendasModal, planoAtual, planoStart]);

  useEffect(() => {
    handleAtualizarQuantidade();
  }, [handleAtualizarQuantidade]);

  useEffect(() => {
    if (planoAtual === planoStart) {
      history.push(ConstanteRotasPDV.PDV_HOME);
    }
  }, [history, planoAtual, planoStart]);

  return (
    <FormProvider {...formMethods}>
      <MenuContextProvider asAccordion={!isInternalHeaderVisible}>
        <Layout
          isHeaderVisible={isLargerThan900}
          FooterContent={
            isLargerThan900 && (
              <TrocasFooter
                cliente={cliente}
                isLoading={isLoading}
                valorTotal={resultadoValorTotal}
                handleGerarVoucher={handleGerarVoucher}
                existeProdutoTrocaSelecionado={existeProdutoTrocaSelecionado}
                handleAbrirModalDevolverDinheiro={
                  handleAbrirModalDevolverDinheiro
                }
                handleGerarTroca={handleGerarTroca}
              />
            )
          }
        >
          <InternalHeaderTrocasProduto
            tamanhoIcone={valorPadding}
            existeProdutoTrocaSelecionado={existeProdutoTrocaSelecionado}
            informacaoAdicionalDoItem={informacaoAdicionalDoItem}
            setClientePadraoSistema={setClientePadraoSistema}
            infoComplementaresVenda={infoComplementaresVenda}
            isLargerThan900={isLargerThan900}
            handleRedirectHome={handleRedirectHome}
          />

          <Box
            overflow="hidden"
            paddingRight={valorPadding}
            paddingLeft={valorPadding}
          >
            <Box>
              {isLoading && <LoadingPadrao />}
              <Flex
                direction={isLargerThan900 ? 'row' : 'column'}
                mt={isLargerThan900 ? '24px' : '5px'}
              >
                <Flex
                  justifyContent="left"
                  alignItems="left"
                  w={isLargerThan900 ? '' : 'full'}
                >
                  <Button
                    leftIcon={<Icon as={SalvarInserirNovoIcon} fontSize="lg" />}
                    variant="link"
                    color="purple.500"
                    onClick={() =>
                      handleExibirModalParaSelecionarProdutosParaTroca()
                    }
                  >
                    Devolver produto
                  </Button>
                </Flex>
                {existeProdutoTrocaSelecionado && (
                  <Text
                    ml={isLargerThan900 ? '20px' : ''}
                    mt="4px"
                    fontWeight="bold"
                    color="red.500"
                    textDecoration="underline"
                  >
                    Os produtos devolvidos possuem{' '}
                    {moneyMask(valorTotalAcrescimo, true)} em acréscimo e{' '}
                    {moneyMask(valorTotalDesconto, true)} em desconto
                  </Text>
                )}
              </Flex>
              {!existeProdutoTrocaSelecionado ? (
                <Flex
                  mt="8px"
                  h="50px"
                  alignItems="center"
                  fontSize="14px"
                  padding="15px"
                  bg="white"
                >
                  Adicione um produto para troca
                </Flex>
              ) : (
                <Box
                  mt="10px"
                  boxShadow="lg"
                  border="1px"
                  overflow="hidden"
                  borderColor="gray.100"
                  borderRadius="md"
                >
                  {isLargerThan900 ? (
                    <ListarItensTrocasProduto
                      handleDecrementarQuantidade={handleDecrementarQuantidade}
                      handleIncrementarQuantidade={handleIncrementarQuantidade}
                      quantidade={quantidade}
                      handleExcluirProduto={handleExcluirProduto}
                      produtosTroca={produtosTroca}
                      valorTotalQuantidade={valorTotalQuantidade}
                    />
                  ) : (
                    <ResolucaoMobileTablet
                      handleIncrementarQuantidade={handleIncrementarQuantidade}
                      handleDecrementarQuantidade={handleDecrementarQuantidade}
                      produtosTroca={produtosTroca}
                      handleExcluirProduto={handleExcluirProduto}
                      quantidade={quantidade}
                      valorTotalQuantidade={valorTotalQuantidade}
                    />
                  )}
                </Box>
              )}
            </Box>
            {!isLargerThan900 && (
              <TrocasFooter
                isLoading={isLoading}
                cliente={cliente}
                valorTotal={resultadoValorTotal}
                handleGerarVoucher={handleGerarVoucher}
                existeProdutoTrocaSelecionado={existeProdutoTrocaSelecionado}
                handleAbrirModalDevolverDinheiro={
                  handleAbrirModalDevolverDinheiro
                }
                handleGerarTroca={handleGerarTroca}
              />
            )}
          </Box>
        </Layout>
      </MenuContextProvider>
    </FormProvider>
  );
}
