import React, { useState } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  useMediaQuery,
  useDisclosure,
  ModalHeader,
  ModalFooter,
  HStack,
  Button,
} from '@chakra-ui/react';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import Select from 'components/PDV/Select/SelectPadrao';
import { NumberInput } from 'components/update/Input/NumberInput';
import { SimpleCard } from 'components/update/Form/SimpleCard';

import { yupResolver, FormData } from './validationForm';

type FormaPagamentoOption = {
  value: string;
  label: string;
};

type AdicionarPagamentoModalResponse = {
  formaPagamentoId: string;
  numeroParcelas: number;
};

type AdicionarPagamentoModalProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<AdicionarPagamentoModalResponse> & {
    formasPagamentoOptions: FormaPagamentoOption[];
  };

export const AdicionarPagamentoModal = create<
  AdicionarPagamentoModalProps,
  AdicionarPagamentoModalResponse
>(({ onResolve, onReject, formasPagamentoOptions, ...rest }) => {
  const formMethods = useForm<FormData>({
    resolver: yupResolver,
    defaultValues: {
      numeroParcelas: 1,
      formaPagamentoId: formasPagamentoOptions[0]?.value,
    },
  });
  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    setIsLoading(true);

    onResolve(data);
  });

  function handleCancelar() {
    onReject();
  }

  return (
    <ModalPadraoChakra
      isCentered
      size={!isSmallerThan900 ? 'xl' : 'full'}
      {...rest}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalContent
        marginBottom={{ base: 0, md: '3.75rem' }}
        marginTop={{ base: 0, md: '3.75rem' }}
        h="unset"
        maxW={{ base: '100%', md: '800px' }}
      >
        {isLoading && <LoadingPadrao />}
        <ModalHeader
          mt={isSmallerThan900 ? 12 : undefined}
          mb={isSmallerThan900 ? 8 : undefined}
          px="0"
          mx={{ base: 6, md: 8 }}
          fontSize="sm"
        >
          Para gerar uma nova forma de pagamento selecione o tipo, informe a
          quantidade de parcelas e clique em “adicionar”. A data de vencimento e
          o valor de cada parcela poderão ser alterados na lista principal.
        </ModalHeader>
        <ModalBody
          p={{ base: 6, md: 8 }}
          mb={{ base: 6, md: 8 }}
          py="0px !important"
        >
          <FormProvider {...formMethods}>
            <SimpleCard
              bg="gray.50"
              border="1px"
              borderColor="gray.100"
              boxShadow="none"
            >
              <SimpleGridForm>
                <Select
                  id="formaPagamentoId"
                  name="formaPagamentoId"
                  label="Selecione a forma de pagamento"
                  placeholder="Selecionar"
                  options={formasPagamentoOptions}
                  colSpan={8}
                />
                <NumberInput
                  id="numeroParcelas"
                  name="numeroParcelas"
                  label="Número de parcelas"
                  scale={0}
                  placeholder="0"
                  colSpan={4}
                />
              </SimpleGridForm>
            </SimpleCard>
          </FormProvider>
        </ModalBody>
        <ModalFooter
          justifyContent="flex-end"
          borderTop="1px"
          borderColor="gray.100"
          mx={{ base: 6, md: 8 }}
          px="0"
        >
          <HStack spacing={6}>
            <Button
              borderRadius="md"
              color="gray.400"
              variant="outline"
              minW="96px"
              onClick={handleCancelar}
            >
              Cancelar
            </Button>
            <Button
              colorScheme="aquamarine"
              borderRadius="md"
              minW="120px"
              onClick={handleSubmit}
            >
              Adicionar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </ModalPadraoChakra>
  );
});
