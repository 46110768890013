import React from 'react';
import { HStack, Icon, Text, Button } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { FiChevronDown } from 'react-icons/fi';

import { useMenuContext } from 'store/PDV/Menu';
import auth from 'modules/auth';
import ConstanteFuncionalidades from 'constants/permissoes';

import { EstoqueIcon } from 'icons';

import NavHeaderButton from 'components/PDV/Layout/NavHeader/Button';
import { ModalSelecionarLocalEstoque } from 'components/PDV/Modal/ModalSelecionarLocalEstoque';

type Estoque = {
  id: string;
  nome: string;
  selecionado: boolean;
};

interface SelecionarLocalDeEstoque {
  localDeEstoqueSelecionado: Estoque;
  onSubmit?: (newVendedor: Estoque) => Promise<void>;
  asNavHeaderButton?: boolean;
  listarEstoques: Estoque[];
  isDisabled: boolean;
}

export function SelecionarLocalEstoque({
  onSubmit,
  localDeEstoqueSelecionado,
  asNavHeaderButton,
  listarEstoques,
  isDisabled,
}: SelecionarLocalDeEstoque) {
  const { setModalAuthIsOpen, chavePermissaoTemporaria } = useMenuContext();

  const possuiPermissaoParaAlterarLocalDeEstoque = auth.possuiPermissao(
    ConstanteFuncionalidades.PDV_ALTERAR_LOCAL_ESTOQUE
  ).permitido;

  async function onOpenModalSelectLocalStock() {
    if (
      !possuiPermissaoParaAlterarLocalDeEstoque &&
      !chavePermissaoTemporaria
    ) {
      setModalAuthIsOpen(true);
    } else {
      await ModalSelecionarLocalEstoque({
        currentLocalEstoqueId: localDeEstoqueSelecionado.id,
        listarEstoques,
        onSubmit: (newLocalEstoque) => {
          if (onSubmit) onSubmit(newLocalEstoque);
        },
      });
    }
  }

  return (
    <>
      {asNavHeaderButton ? (
        <NavHeaderButton
          icon={EstoqueIcon}
          title={localDeEstoqueSelecionado?.nome || ''}
          titleColor="orange.500"
          colorScheme="orange"
          hasIndicator
          onClick={onOpenModalSelectLocalStock}
        />
      ) : (
        <Button
          isDisabled={isDisabled}
          variant="ghost"
          colorScheme="orange"
          onClick={onOpenModalSelectLocalStock}
        >
          <HStack spacing={2}>
            <Icon as={EstoqueIcon} fontSize="2xl" />

            <HStack spacing={1}>
              {localDeEstoqueSelecionado && (
                <Text fontSize="sm" lineHeight="0.1">
                  {localDeEstoqueSelecionado?.nome}
                </Text>
              )}
              <Icon as={FiChevronDown} />
            </HStack>
          </HStack>
        </Button>
      )}
    </>
  );
}
