import React from 'react';
import {
  ModalContent,
  ModalBody,
  Flex,
  Button,
  Heading,
  Text,
  AlertDialogFooter,
  ScaleFade,
  useMediaQuery,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { AtencaoAvisoIcon } from 'icons';
import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import ManterFoco from 'components/Geral/ManterFoco';

import { ButtonFechar } from './styles';

const ModalConfirmacao = ({
  textoConfirmar = 'Sim, continuar!',
  textoCancelar = 'Não, cancelar!',
  textoCabecalho,
  textoMensagem,
  onConfirm,
  isOpen,
  setIsOpen,
  onCancel,
}: any) => {
  const { t } = useTranslation();

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  return (
    <ModalPadraoChakra
      onClose={() => {
        setIsOpen(false);
      }}
      isOpen={isOpen}
      isCentered
      size={isLargerThan900 ? 'xl' : 'full'}
      autoFocus={false}
    >
      <ModalContent
        marginBottom={{ base: 0, sm: '3.75rem' }}
        marginTop={{ base: 0, sm: '3.75rem' }}
        h="unset"
        maxW="600px"
        bg="gray.50"
      >
        <ManterFoco
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <ScaleFade initialScale={1.5} in={isOpen}>
            <ModalBody p={0} h="100%">
              <Flex flexDirection="column" align="stretch" h="100%">
                <Flex
                  justifyContent="center"
                  h="150px"
                  bg="orange.400"
                  style={{
                    borderTopLeftRadius: '5px',
                    borderTopRightRadius: '5px',
                  }}
                >
                  <AtencaoAvisoIcon
                    style={{
                      fontSize: '75px',
                      fontWeight: 'bolder',
                      marginTop: '35px',
                    }}
                  />
                </Flex>

                <Flex
                  justifyContent="flex-start"
                  flexDirection="column"
                  bg="gray.50"
                  px={8}
                  py={7}
                  h="100%"
                  style={{
                    borderBottomLeftRadius: '5px',
                    borderBottomRightRadius: '5px',
                  }}
                >
                  <Heading
                    as="h2"
                    fontSize="2xl"
                    color="orange.500"
                    fontWeight="semibold"
                    textAlign="initial"
                  >
                    {t(textoCabecalho)}
                  </Heading>

                  <Text mt={15} fontSize="14px" color="gray.700">
                    {textoMensagem
                      ? t(textoMensagem)
                      : t(
                          'Os dados referentes a este registro serão inativados.'
                        )}
                  </Text>

                  <AlertDialogFooter>
                    <ButtonFechar
                      id="cancelModal"
                      variant="ghost"
                      color="gray.300"
                      border="1px"
                      borderColor="gray.200"
                      maxW="120px"
                      _hover={{
                        borderColor: 'gray.700',
                        color: 'gray.700',
                      }}
                      onClick={() => {
                        setIsOpen(false);
                        if (onCancel) {
                          onCancel();
                        }
                      }}
                    >
                      {textoCancelar}
                    </ButtonFechar>
                    <Button
                      id="confirmModal"
                      bg="orange.400"
                      maxW="180px"
                      _hover={{
                        bg: 'orange.200',
                      }}
                      _active={{
                        bg: 'orange.300',
                      }}
                      onClick={() => {
                        setIsOpen(false);

                        if (onConfirm) {
                          onConfirm();
                        }
                      }}
                      style={{
                        marginTop: '40px',
                        marginRight: 'auto',
                      }}
                    >
                      {textoConfirmar}
                    </Button>
                  </AlertDialogFooter>
                </Flex>
              </Flex>
            </ModalBody>
          </ScaleFade>
        </ManterFoco>
      </ModalContent>
    </ModalPadraoChakra>
  );
};

export default ModalConfirmacao;
