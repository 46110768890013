import { useState } from 'react';
import { Flex, Checkbox, Text, Box, Icon } from '@chakra-ui/react';
import { RiShareLine } from 'react-icons/ri';

import { DecimalMask } from 'helpers/format/fieldsMasks';
import { formatDate } from 'helpers/format/formatStringDate';

import { MovimentacaoFinanceiraBaixa } from 'components/RecebimentoContasComponents/validationForm';

type ListagemRecebimentoContasMobileProps = {
  movimentacaoFinanceira: MovimentacaoFinanceiraBaixa;
  index: number;
  handleToggleItemSelecionado(index: number): void;
  printCarne: (movimentacaoFinanceira: MovimentacaoFinanceiraBaixa) => void;
};
export const ListagemRecebimentoContasMobile = ({
  movimentacaoFinanceira,
  index,
  handleToggleItemSelecionado,
  printCarne,
}: ListagemRecebimentoContasMobileProps) => {
  const [exibirDetalhesContas, setExibirDetalhesContas] = useState(false);

  const renderizarExibicaoDetalhesContas = () => {
    setExibirDetalhesContas(!exibirDetalhesContas);
  };

  return (
    <Box
      boxShadow="md"
      rounded="md"
      cursor="pointer"
      onClick={() => renderizarExibicaoDetalhesContas()}
      w="full"
      mb="4"
      padding="4"
      bg="white"
      borderRadius="md"
    >
      <Flex justifyContent="space-between" w="full">
        <Flex>
          <Flex h="20px" mr="4" justifyContent="center" alignItems="center">
            <Checkbox
              isChecked={movimentacaoFinanceira?.selecionado}
              onChange={() => handleToggleItemSelecionado(index)}
              mb="0"
            />
          </Flex>
          <Box>
            <Text>
              {movimentacaoFinanceira.numeroOperacao} - Parcela{' '}
              {movimentacaoFinanceira.parcela}
            </Text>
            <Text fontSize="sm">
              {formatDate(movimentacaoFinanceira?.dataVencimento)}
            </Text>
          </Box>
        </Flex>
        <Flex>
          <Box>
            <Text>Valor total</Text>
            <Text fontSize="sm">
              {`R$ ${DecimalMask(
                (movimentacaoFinanceira?.valorOriginal || 0) +
                  movimentacaoFinanceira.juros +
                  movimentacaoFinanceira.multa,
                2,
                2
              )}`}
            </Text>
          </Box>
          <Flex justify="flex-end" ml="32px">
            <Icon
              as={RiShareLine}
              padding="6px"
              borderRadius="md"
              onClick={() => {
                printCarne(movimentacaoFinanceira);
              }}
              _hover={{
                cursor: 'pointer',
                backgroundColor: 'gray.100',
              }}
              fontSize="32px"
            />
          </Flex>
        </Flex>
      </Flex>
      {exibirDetalhesContas && (
        <Box>
          <Flex alignItems="center">
            <Text mr="2">Valor original:</Text>
            <Text fontSize="xs">
              {DecimalMask(movimentacaoFinanceira?.valorOriginal, 2)}
            </Text>
          </Flex>
          <Flex mt="2px" alignItems="center">
            <Text mr="2">Multa:</Text>
            <Text fontSize="xs" color="red.500">
              {DecimalMask(movimentacaoFinanceira?.multa, 2, 2)}
            </Text>
          </Flex>
          <Flex mt="2px" alignItems="center">
            <Text mr="2">Juros:</Text>
            <Text fontSize="xs" color="red.500">
              {DecimalMask(movimentacaoFinanceira?.juros, 2, 2)}
            </Text>
          </Flex>
        </Box>
      )}
    </Box>
  );
};
