import React, {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';

interface EntradaMercadoriaEtapasContextProps {
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  nextStep: () => void;
  previousStep: () => void;
}

export const EntradaMercadoriaEtapasContext = createContext<EntradaMercadoriaEtapasContextProps>(
  {} as EntradaMercadoriaEtapasContextProps
);

interface EntradaMercadoriaEtapasProviderProps {
  children: React.ReactNode;
}

export function EntradaMercadoriaEtapasProvider({
  children,
}: EntradaMercadoriaEtapasProviderProps): JSX.Element {
  const [activeStep, setActiveStep] = useState(0);

  const nextStep = useCallback(() => {
    setActiveStep((prev) => prev + 1);
  }, []);

  const previousStep = useCallback(() => {
    setActiveStep((prev) => prev - 1);
  }, []);

  return (
    <EntradaMercadoriaEtapasContext.Provider
      value={{
        activeStep,
        setActiveStep,
        nextStep,
        previousStep,
      }}
    >
      {children}
    </EntradaMercadoriaEtapasContext.Provider>
  );
}

export function useEntradaMercadoriaEtapasContext(): EntradaMercadoriaEtapasContextProps {
  const context = useContext(EntradaMercadoriaEtapasContext);

  if (!context)
    throw new Error(
      'useEntradaMercadoriaEtapasContext must be used within a EntradaMercadoriaEtapasProvider.'
    );

  return context;
}
