import { Grid, Flex } from '@chakra-ui/react';
import React from 'react';

interface ImpressaoInterface {
  informacoesComplementares?: string;
}

const MensagemInteresseContribuinte = ({
  informacoesComplementares,
}: ImpressaoInterface) => {
  return (
    <Grid className="textCenter" minHeight="45px">
      {informacoesComplementares && (
        <Flex className="textCenter">{informacoesComplementares}</Flex>
      )}
      <Flex>.</Flex>
    </Grid>
  );
};

export default MensagemInteresseContribuinte;
