import { useState, useRef, useCallback } from 'react';
import { GridItem } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { StatusPesquisaClientesFornecedor } from 'constants/enum/statusPesquisaClientesFornecedor';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { setDateMaxHours, setDateMinHours } from 'helpers/data/setHoursDate';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import api, { ResponseApi } from 'services/api';
import { formatOptionsSelectClient } from 'helpers/format/formatSelectClient';

import {
  PagedTable,
  PagedTableForwardRefData,
} from 'components/update/Table/PagedTable';
import { PaginationData } from 'components/update/Pagination';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import AsyncSelect from 'components/PDV/Select/AsyncSelectPadrao';
import InputDateRange from 'components/PDV/InputDateRange';
import { GridPaginadaRetorno } from 'components/Grid/Paginacao';

import { ListarVendasVoucher } from './ListarVendasVoucher';

import {
  FornecedorOptionResponse,
  FormData,
  VoucherProps,
} from './validationForms';

type ClienteSelectProps = {
  label: string;
  value: string;
};

export function ListarVouchers() {
  const pagedTableRef = useRef<PagedTableForwardRefData>(null);

  const [
    clienteSelecionado,
    setClienteSelecionado,
  ] = useState<ClienteSelectProps>();
  const [totalRegistros, setTotalRegistros] = useState<number>(0);
  const [listaVouchers, setListaVouchers] = useState<VoucherProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const dataAtual = new Date();
  const backdate = new Date(dataAtual.setDate(dataAtual.getDate() - 30));

  const formMethods = useForm<FormData>({
    defaultValues: {
      dataInicioPesquisa: setDateMinHours(backdate),
      dataFimPesquisa: setDateMaxHours(new Date()),
    },
  });

  const [dataInicioPesquisaWatch, dataFimPesquisaWatch] = formMethods.watch([
    'dataInicioPesquisa',
    'dataFimPesquisa',
  ]);

  const handleGetClientes = useCallback(async (inputValue?: string) => {
    const response = await api.get<
      void,
      ResponseApi<FornecedorOptionResponse[]>
    >(ConstanteEnderecoWebservice.CLIENTE_FORNECEDOR_LISTAR_SELECT, {
      params: {
        filtroTipoCadastroPessoa: StatusPesquisaClientesFornecedor.CLIENTES,
        cpfCnpjNomeApelidoCodigoExterno: inputValue,
      },
    });

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        const dados = response.dados.map((client) => {
          const option = formatOptionsSelectClient(client);
          return option;
        });
        return dados;
      }
    }

    return [];
  }, []);

  const paginationHandle = useCallback(
    async (gridPaginadaConsulta: PaginationData) => {
      setIsLoading(true);

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<VoucherProps>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.LISTAR_PAGINAD0_VOUCHER,
          gridPaginadaConsulta
        ),
        {
          params: {
            clienteId: clienteSelecionado?.value || '',
            dataEmissaoInicio: dataInicioPesquisaWatch.toISOString(),
            dataEmissaoFim: setDateMaxHours(
              new Date(dataFimPesquisaWatch)
            ).toISOString(),
          },
        }
      );

      if (response?.sucesso) {
        setTotalRegistros(response.dados.total);
        setListaVouchers(response.dados.registros);
        setIsLoading(false);
      }

      setIsLoading(false);
    },
    [clienteSelecionado, dataInicioPesquisaWatch, dataFimPesquisaWatch]
  );

  return (
    <SimpleGridForm>
      <FormProvider {...formMethods}>
        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <AsyncSelect
            id="clienteFornecedorId"
            name="clienteFornecedorId"
            placeholder="Informe o cliente"
            required
            setValor={setClienteSelecionado}
            colSpan={{ base: 12, lg: 6 }}
            handleGetOptions={handleGetClientes}
            asControlledByObject
            isClearable
            shouldAppearTheAddress
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 3 }}>
          <InputDateRange
            borderColor="gray.100"
            borderRadius="md"
            placeholder="Selecione um período"
            startDateName="dataInicioPesquisa"
            endDateName="dataFimPesquisa"
            maxDate={new Date()}
            id="dataPesquisa"
            name="dataPesquisa"
          />
        </GridItem>
        <GridItem colSpan={12}>
          <PagedTable
            ref={pagedTableRef}
            variant="simple-card"
            bg="transparent"
            border="1px solid"
            borderColor="gray.100"
            rounded="md"
            boxShadow="none"
            size="md"
            mt="3"
            paginationHasDivider={false}
            sx={{
              '& tr > th': { borderBottom: 'none' },
              '& td:only-child': {
                bg: 'transparent',
                h: '20px',
                border: 'none',
              },
              '& tr': {
                boxShadow: 'none !important',
                borderLeft: 'none',
                borderRight: 'none',
              },
            }}
            editarCorBorda="none"
            isLoading={isLoading}
            loadColumnsData={paginationHandle}
            itemsTotalCount={totalRegistros}
            defaultKeyOrdered="dataDevolucao"
            tableHeaders={[
              {
                key: 'dataDevolucao',
                content: 'Data de Emissão',
                isOrderable: false,
              },
              {
                key: 'nomeCliente',
                content: 'Cliente',
                isOrderable: false,
              },
              {
                key: 'numeroDevolucao',
                content: 'Número devolução',
                isOrderable: false,
              },
              {
                key: 'identificador',
                content: 'Identificador',
                isOrderable: false,
                textAlign: 'center',
              },
              {
                key: 'valorOriginal',
                content: 'Valor original',
                isOrderable: false,
                isNumeric: true,
              },
              {
                key: 'saldo',
                content: 'Saldo restante',
                isOrderable: false,
                isNumeric: true,
              },

              { key: 'acoes', content: 'Ações', isOrderable: false, w: '1px' },
            ]}
            renderTableRows={listaVouchers.map((vouchers) => (
              <ListarVendasVoucher vouchers={vouchers} />
            ))}
          />
        </GridItem>
      </FormProvider>
    </SimpleGridForm>
  );
}
