import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import api, { ResponseApi } from 'services/api';
import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import { ContainerListagem } from 'components/Layout/Listagem/containerListagem';

import { useForm, yupResolver } from '../validationForm';
import { UncontrolledForm } from '..';

interface CorInterface {
  id: string;
  descricao: string;
  ativo: boolean;
  dataHoraCadastro: string;
  dataHoraUltimaAlteracao: string;
}

type TParams = { id: string };

const Visualizar = () => {
  const history = useHistory();
  const isMountedRef = useIsMountedRef();

  const [isLoading, setIsLoading] = useState(true);

  const formMethods = useForm({
    resolver: yupResolver,
  });

  const { id: idRota } = useParams<TParams>();

  const { reset } = formMethods;

  const [dataHoraCadastro, setDataHoraCadastro] = useState('');
  const [dataHoraUltimaAlteracao, setDataHoraUltimaAlteracao] = useState('');

  const handleGetCor = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<void, ResponseApi<CorInterface>>(
      ConstanteEnderecoWebservice.COR_OBTER,
      {
        params: { id: idRota },
      }
    );

    if (response?.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (response?.sucesso && isMountedRef.current) {
      reset({
        ...response.dados,
      });

      setDataHoraCadastro(response.dados.dataHoraCadastro);
      setDataHoraUltimaAlteracao(response.dados.dataHoraUltimaAlteracao);
    } else {
      history.push(ConstanteRotas.COR);
    }

    if (isMountedRef.current) setIsLoading(false);
  }, [history, isMountedRef, idRota, reset]);

  useEffect(() => {
    setIsLoading(true);

    handleGetCor();
  }, [handleGetCor]);

  return (
    <ContainerListagem
      formMethods={formMethods}
      isLoading={isLoading}
      dataHoraUltimaAlteracao={dataHoraUltimaAlteracao}
      dataHoraCadastro={dataHoraCadastro}
      isVisualizar
    >
      <UncontrolledForm readonly />
    </ContainerListagem>
  );
};

export default Visualizar;
