import { Flex, HStack, Text, VStack } from '@chakra-ui/react';

import { formatDateHourMinuteSecond } from 'helpers/format/formatStringDate';
import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';

interface InformacoesProps {
  forma: string;
  caixa: string;
  destinoOuOrigem: string;
  usuario: string;
  observacao: string;
  dataCadastro: Date;
  tipoOperacao: number;
}

export const Informacoes = ({
  caixa,
  dataCadastro,
  destinoOuOrigem,
  forma,
  usuario,
  observacao,
  tipoOperacao,
}: InformacoesProps) => {
  const isSuprimento =
    tipoOperacao ===
    IdentificacaoTipoOperacaoEnum.TRANSFERENCIA_DINHEIRO_ENTRADA;
  return (
    <Flex
      direction="column"
      alignItems="flex-start"
      pt={2}
      pb={2}
      borderBottom="1px solid"
      borderColor="gray.700"
      gap={1}
      fontSize="2xs"
    >
      <HStack justifyContent="space-between" w="full">
        <Text>CAIXA: {caixa}</Text>
        <Text>{formatDateHourMinuteSecond(dataCadastro || new Date())}</Text>
      </HStack>
      <VStack>
        <Text>FORMA: {forma}</Text>
      </VStack>
      <VStack>
        <Text>
          {isSuprimento ? 'ORIGEM' : 'DESTINO'} {destinoOuOrigem}
        </Text>
      </VStack>
      <VStack>
        <Text>USUÁRIO: {usuario}</Text>
      </VStack>
      <VStack>
        <Text>OBS: {observacao}</Text>
      </VStack>
    </Flex>
  );
};
