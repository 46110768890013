import React, {
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import { GridItem } from '@chakra-ui/react';

import api, { ResponseApi } from 'services/api';
import consultarViaCep, { CepResponse } from 'services/viacep';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { handleGetCidade } from 'helpers/data/getCidadeObterCache';
import { cepMask } from 'helpers/format/fieldsMasks';
import { CnpjResponse } from 'services/receitaws';

import CheckboxAtivoInativo from 'components/update/Checkbox/CheckboxAtivoInativo';
import Input from 'components/PDV/Input';
import InputCpfCnpj from 'components/PDV/InputCpfCnpj';
import InputTelefone from 'components/PDV/InputTelefonePdv';
import { InputRgIe } from 'components/update/Input/InputRgIe';
import { InputCep } from 'components/update/Input/InputCep';
import SelectCidade from 'components/PDV/Select/SelectCidade';
import SelectPais, { PaisInterface } from 'components/PDV/Select/SelectPais';
import TextareaField from 'components/PDV/Textarea';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';

export interface UncontrolledFormRefInterface {
  setCidade: (cidadeId?: number, codigoIBGE?: string) => Promise<void>;
  setPais: (paisId: number) => Promise<void>;
  setIsCpf: React.Dispatch<React.SetStateAction<boolean>>;
  firstInputFocus: () => void;
}

interface UncontrolledFormInterface {
  readonly?: boolean;
}

const UncontrolledForm = forwardRef(
  ({ readonly }: UncontrolledFormInterface, ref) => {
    const [isCpf, setIsCpf] = useState(true);

    const { getValues, setValue, setError } = useFormContext();

    const handleGetPais = useCallback(async (paisId: number) => {
      const response = await api.get<void, ResponseApi<PaisInterface>>(
        ConstanteEnderecoWebservice.PAIS_OBTER_CACHE,
        { params: { id: paisId } }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item: string) => toast.warning(item));
        }

        if (response.sucesso) {
          return response?.dados;
        }
      }

      return {} as PaisInterface;
    }, []);

    const setPais = useCallback(
      async (paisId: number) => {
        const pais = paisId ? await handleGetPais(paisId) : undefined;

        if (pais)
          setValue('pais', {
            label: pais.nome,
            value: pais.id,
          });
      },
      [handleGetPais, setValue]
    );

    const setCidade = useCallback(
      async (cidadeId?: number, codigoIBGE?: string, nomeCidade?: string) => {
        const cidade =
          cidadeId || codigoIBGE
            ? await handleGetCidade(cidadeId, codigoIBGE, nomeCidade)
            : undefined;

        if (cidade) {
          setValue('cidade', {
            label: `${cidade.nome} - ${cidade.estadoSigla}`,
            value: cidade.id,
            paisId: cidade.paisId,
          });
          setValue('estado', cidade.estadoNome);

          if (cidade.paisId) setPais(cidade.paisId);
        } else {
          setValue('cidade', null);
          setValue('estado', null);
        }
      },
      [setPais, setValue]
    );

    const getSelectedCidade = useCallback(
      (cidadeId: number) => {
        setCidade(cidadeId);
      },
      [setCidade]
    );

    const getCepData = useCallback(
      (data: CepResponse) => {
        if (data.bairro) setValue('bairro', data.bairro);

        if (data.complemento) setValue('complemento', data.complemento);

        if (data.ibge) {
          setCidade(undefined, data.ibge.toString(), data.localidade);
        }

        if (data.logradouro) setValue('logradouro', data.logradouro);
      },
      [setCidade, setValue]
    );

    const getData = useCallback(
      (sucesso: boolean, dados: any) => {
        if (!sucesso) setError('cep', { message: dados });
        else {
          setError('cep', { message: '' });
          getCepData(dados);
        }
      },
      [getCepData, setError]
    );

    const getCnpjData = useCallback(
      (data: CnpjResponse) => {
        if (data.nome) setValue('nome', data.nome);
        else setValue('nome', null);

        if (data.fantasia) setValue('apelido', data.fantasia);
        else setValue('apelido', null);

        if (data.bairro) setValue('bairro', data.bairro);

        if (data.cep) setValue('cep', cepMask(data.cep));

        if (data.complemento) setValue('complemento', data.complemento);
        else setValue('complemento', null);

        if (data.email) setValue('email', data.email);

        if (data.logradouro) setValue('logradouro', data.logradouro);

        if (data.municipio) {
          consultarViaCep(data.cep, getData);
        }

        if (data.numero) setValue('numero', data.numero);

        if (data.telefone) setValue('telefone', data.telefone);
        else setValue('telefone', null);
      },
      [getData, setValue]
    );

    const getSelectedPais = useCallback(
      (paisId?: number) => {
        const cidade = getValues('cidade');

        if (
          (cidade &&
            (cidade.paisId || paisId === 1) &&
            cidade.paisId !== paisId) ||
          !paisId
        ) {
          setValue('cidade', null);
          setValue('estado', null);
        }
      },
      [getValues, setValue]
    );

    const firstInputFocus = useCallback(() => {
      const firstInput = document.getElementById('cpfCnpj');
      if (firstInput) firstInput.focus();
    }, []);

    useImperativeHandle(ref, () => ({
      setIsCpf,
      setCidade,
      setPais,
      firstInputFocus,
    }));

    return (
      <SimpleGridForm>
        <GridItem colSpan={[12, 12, 6, 6, 4]}>
          <InputCpfCnpj
            id="cpfCnpj"
            name="cpfCnpj"
            label="CPF / CNPJ"
            placeholder="Digite o CPF ou o CNPJ"
            maskTypeChange={(isCpfResponse: boolean) => {
              setIsCpf(isCpfResponse);
            }}
            getCnpjData={getCnpjData}
            isDisabled={readonly}
            autoFocus
          />
        </GridItem>
        <GridItem colSpan={[12, 12, 6, 6, 4]}>
          <Input
            id="nome"
            name="nome"
            label={isCpf ? 'Nome' : 'Razão social'}
            isRequired
            placeholder={
              isCpf ? 'Digite o nome completo' : 'Digite a razão social'
            }
            maxLength={60}
            isDisabled={readonly}
          />
        </GridItem>
        <GridItem colSpan={[12, 12, 6, 6, 4]}>
          <Input
            id="apelido"
            name="apelido"
            label={isCpf ? 'Apelido' : 'Nome fantasia'}
            placeholder={isCpf ? 'Digite o apelido' : 'Digite o nome fantasia'}
            maxLength={40}
            isDisabled={readonly}
          />
        </GridItem>
        <GridItem colSpan={[12, 12, 6, 6, 4]}>
          <Input
            id="email"
            name="email"
            label="E-mail"
            placeholder="Digite o endereço de e-mail"
            maxLength={60}
            isDisabled={readonly}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 3, 3, 2]}>
          <Input
            id="contato"
            name="contato"
            label="Contato"
            placeholder="Digite um contato"
            maxLength={30}
            isDisabled={readonly}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 3, 3, 2]}>
          <InputTelefone
            id="telefone"
            name="telefone"
            label="Telefone"
            placeholder="(00) 0000-0000"
            isDisabled={readonly}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 3, 3, 2]}>
          <InputRgIe
            isRg={isCpf}
            id="rgie"
            name="rgie"
            label={isCpf ? 'RG' : 'Inscrição estadual'}
            placeholder={isCpf ? 'Digite o RG' : 'Digite a inscrição estadual'}
            isDisabled={readonly}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 3, 3, 2]}>
          <Input
            id="rntc"
            name="rntc"
            label="RNTC"
            placeholder="Digite o RNTC"
            maxLength={20}
            isDisabled={readonly}
          />
        </GridItem>
        <GridItem colSpan={[12, 12, 6, 6, 4]}>
          <InputCep
            id="cep"
            name="cep"
            label="CEP"
            placeholder="Digite o CEP"
            getCepData={getCepData}
            isDisabled={readonly}
          />
        </GridItem>
        <GridItem colSpan={[12, 12, 6, 6, 4]}>
          <Input
            type="text"
            id="logradouro"
            name="logradouro"
            label="Logradouro"
            placeholder="Rua, avenida, etc"
            maxLength={60}
            isDisabled={readonly}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 3, 3, 2]}>
          <Input
            id="numero"
            name="numero"
            label="Número"
            placeholder="Digite o número"
            maxLength={60}
            isDisabled={readonly}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 3, 3, 2]}>
          <Input
            id="complemento"
            name="complemento"
            label="Complemento"
            placeholder="Digite o complemento"
            maxLength={60}
            isDisabled={readonly}
          />
        </GridItem>
        <GridItem colSpan={[12, 12, 6, 6, 4]}>
          <Input
            id="bairro"
            name="bairro"
            label="Bairro"
            placeholder="Digite o bairro"
            maxLength={60}
            isDisabled={readonly}
          />
        </GridItem>
        <GridItem colSpan={[12, 12, 6, 6, 4]}>
          <SelectCidade
            id="cidade"
            name="cidade"
            label="Cidade"
            isRequired
            paisKey="pais"
            placeholder="Digite a cidade"
            isDisabled={readonly}
            getSelectedCidade={getSelectedCidade}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 3, 3, 4]}>
          <Input
            id="estado"
            name="estado"
            label="Estado"
            isRequired
            maxLength={50}
            isDisabled
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 3, 3, 4]}>
          <SelectPais
            id="pais"
            name="pais"
            label="País"
            isRequired
            isClearable
            placeholder="Digite o país"
            isDisabled={readonly}
            getSelectedPais={getSelectedPais}
          />
        </GridItem>
        <GridItem colSpan={[12, 9, 10]}>
          <TextareaField
            id="observacao"
            name="observacao"
            label="Observação"
            placeholder="Digite sua observação"
            isDisabled={readonly}
          />
        </GridItem>
        <GridItem colSpan={[12, 3, 2]}>
          <CheckboxAtivoInativo
            id="ativo"
            name="ativo"
            label="Status"
            isDisabled={readonly}
          />
        </GridItem>
      </SimpleGridForm>
    );
  }
);

export default UncontrolledForm;
