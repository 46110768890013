import styled, { css } from 'styled-components';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';

export const TableHead = styled.thead`
  position: relative;

  min-height: 40px;
  height: 100%;
  max-height: 40px;

  & > tr > th:first-child {
    padding-left: 30px;
  }

  & > tr > th:last-child {
    padding-right: 30px;
  }

  & > tr {
    min-height: 40px;
    height: 100%;
    max-height: 40px;

    background-color: var(--sti-ck-colors-gray-50);

    > th {
      min-height: 40px;
      height: 100%;
      max-height: 40px;

      padding: 10px 10px;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;

  text-overflow: ellipsis;
  overflow: unset;
  white-space: nowrap;

  width: 100%;
`;

const OrderIconStyle = css`
  font-size: 0.75rem;
  min-width: 0.75rem;
  min-height: 0.75rem;
  margin-left: 5px;
`;

export const AscIcon = styled(FiArrowDown)`
  ${OrderIconStyle}
`;

export const DescIcon = styled(FiArrowUp)`
  ${OrderIconStyle}
`;
