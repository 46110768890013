import { Box, Button, Divider, Flex, useMediaQuery } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { useMetasComissoesEtapasContext } from 'store/MetasComissoes/MetasComissoesEtapas';
import { IdentificacaoEtapasMetasComissoes } from 'constants/enum/identificacaoEtapasMetasComissoes';
import ConstanteRotas from 'constants/rotas';

import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import DataCriacaoAlteracao from 'components/Geral/DataCriacaoAlteracao';

type FooterMetasComissoesProps = {
  handleVoltar?: () => void;
  handleAvancar: () => void;
  isDisabledAvancar?: boolean;
};

export const FooterMetasComissoes = ({
  handleVoltar,
  handleAvancar,
  isDisabledAvancar,
}: FooterMetasComissoesProps) => {
  const history = useHistory();
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const {
    activeStep,
    metaGeral,
    obterValorMetaLoja,
    handleExcluirConfiguracaoMetasComissoes,
  } = useMetasComissoesEtapasContext();

  const existeItemCadastrado =
    obterValorMetaLoja !== undefined &&
    activeStep === IdentificacaoEtapasMetasComissoes.ESCOLHER_VALOR_META;

  const existeHoraCadastrada = !!(
    metaGeral?.dataHoraCadastro && metaGeral.dataHoraUltimaAlteracao
  );

  const naoExisteValorMetaGeral =
    activeStep === IdentificacaoEtapasMetasComissoes.REGRA_PAGAMENTO &&
    metaGeral?.valorMetaGeral === 0;

  const handleCancelarOuExcluir = () => {
    if (!existeItemCadastrado) {
      history.push(ConstanteRotas.METAS_E_COMISSOES);
    } else {
      ModalConfirmacaoExcluir({
        title: 'Você tem certeza que quer excluir as configurações?',
        text: `Se você prosseguir com essa ação, os passos que você cadastrou serão apagados`,
        confirmButtonText: 'Sim, continuar!',
        cancelButtonText: 'Cancelar',
        callback: async (ok: boolean) => {
          if (ok) {
            handleExcluirConfiguracaoMetasComissoes();
          }
        },
      });
    }
  };

  return (
    <>
      {existeHoraCadastrada && (
        <Flex justifyContent="flex-end" w="full" alignItems="center">
          <DataCriacaoAlteracao
            dataHoraCriacao={String(metaGeral?.dataHoraCadastro)}
            dataHoraUltimaAlteracao={String(metaGeral?.dataHoraUltimaAlteracao)}
          />
        </Flex>
      )}
      <Box mt={existeHoraCadastrada ? '15px' : '40px'} w="full">
        <Divider />
        <Flex
          mt="16px"
          direction={isLargerThan900 ? 'row' : 'column'}
          justifyContent="space-between"
        >
          <Button
            border="1px"
            borderColor="gray.700"
            variant="sucess"
            w={
              isLargerThan900
                ? existeItemCadastrado
                  ? '223px'
                  : '96px'
                : 'full'
            }
            h="40px"
            onClick={() => handleCancelarOuExcluir()}
          >
            {existeItemCadastrado ? 'Excluir metas e comissões' : 'Cancelar'}
          </Button>
          <Flex direction={isLargerThan900 ? 'row' : 'column'}>
            <Button
              border="1px"
              mt={isLargerThan900 ? '' : '16px'}
              mb={isLargerThan900 ? '' : '16px'}
              mr={isLargerThan900 ? '24px' : '0'}
              borderColor="gray.700"
              variant="sucess"
              h="40px"
              isDisabled={activeStep === 0}
              w={isLargerThan900 ? '96px' : 'full'}
              onClick={() => (handleVoltar ? handleVoltar() : {})}
            >
              Voltar
            </Button>
            <Button
              color="white"
              bg="primary.500"
              variant="sucess"
              h="40px"
              w={
                isLargerThan900
                  ? activeStep ===
                      IdentificacaoEtapasMetasComissoes.PREMIACAO_POR_EQUIPE ||
                    naoExisteValorMetaGeral
                    ? '190px'
                    : '160px'
                  : 'full'
              }
              _hover={{
                background: 'primary.500',
              }}
              isDisabled={isDisabledAvancar}
              onClick={() => handleAvancar()}
            >
              {activeStep ===
                IdentificacaoEtapasMetasComissoes.PREMIACAO_POR_EQUIPE ||
              naoExisteValorMetaGeral
                ? 'Finalizar configuração'
                : 'Avançar'}
            </Button>
          </Flex>
        </Flex>
      </Box>
    </>
  );
};
