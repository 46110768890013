import { Text, VStack, Box, Checkbox, Flex } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';

import { useSmartPosEtapasContext } from 'store/SmartPos/SmartPosEtapasContext';
import { IdentificacaoEtapasSmartPos } from 'constants/enum/IdentificacaoEtapasSmartPos';
import {
  cadastrarFormaRecebimentoSmartPos,
  getFormaRecebimentoSmartPos,
} from 'services/smartPos';

import { FinanceiroAReceber } from 'icons';
import { ModalCadastrarFormaRecebimento } from 'components/Modal/ModalCadastrarFormaRecebimento';

import { HeaderSmartPos } from '../Layout/Header';
import { FooterSmartPos } from '../Layout/FooterSmartPos';

type FormaRecebimentoProps = {
  nome: string;
  id: string;
  selecionado: boolean;
};

export const FormaRecebimento = () => {
  const [listFormaRecebimento, setListFormaRecebimento] = useState<
    FormaRecebimentoProps[]
  >([]);

  const { setActiveStep, setIsLoading } = useSmartPosEtapasContext();

  const formasRecebimentoSelecionadas = listFormaRecebimento
    .filter((formaRecebimento) => formaRecebimento.selecionado)
    .map((formaRecebimento) => formaRecebimento.id);

  const handleAvancar = useCallback(async () => {
    setIsLoading(true);

    const response = await cadastrarFormaRecebimentoSmartPos(
      formasRecebimentoSelecionadas
    );
    if (response) {
      setActiveStep(IdentificacaoEtapasSmartPos.CATEGORIA_PRODUTO);
    }
    setIsLoading(false);
  }, [formasRecebimentoSelecionadas, setIsLoading, setActiveStep]);

  const handleVoltar = () => {
    setActiveStep(IdentificacaoEtapasSmartPos.LOCAL_ESTOQUE);
  };

  const getFormasRecebimento = useCallback(async () => {
    setIsLoading(true);

    const response = await getFormaRecebimentoSmartPos();

    const allFormasNaoSelecionadas = response.every(
      (formaRecebimentoItem) => formaRecebimentoItem.selecionado === false
    );
    if (allFormasNaoSelecionadas) {
      setListFormaRecebimento(
        response.map((formaRecebimentoItem) => ({
          ...formaRecebimentoItem,
          selecionado: true,
        }))
      );
    } else {
      setListFormaRecebimento(response);
    }

    setIsLoading(false);
  }, [setIsLoading]);

  const handleAbrirModalCadastrarFormaRecebimento = async () => {
    const { formaRecebimentoCriada } = await ModalCadastrarFormaRecebimento();
    if (formaRecebimentoCriada) {
      await getFormasRecebimento();
    }
  };

  const handleAlternarStatusSelecionadoFormaRecebimento = useCallback(
    (index: number) => {
      setListFormaRecebimento((prev) =>
        prev.map((valueItem, indexItem) => ({
          ...valueItem,
          selecionado:
            index === indexItem
              ? !valueItem.selecionado
              : valueItem.selecionado,
        }))
      );
    },
    []
  );

  useEffect(() => {
    getFormasRecebimento();
  }, [getFormasRecebimento]);

  return (
    <>
      <Box mb="28px">
        <HeaderSmartPos
          title="Formas de recebimento"
          icon={FinanceiroAReceber}
          handleVoltar={handleVoltar}
        />
        <VStack alignItems="center" w="full" gap="24px" px="20px">
          <Box color="primary.50" fontSize="14px" letterSpacing="0px" w="full">
            <Text>
              Todas as formas de recebimento cadastradas no sistema podem ser
              utilizadas no aplicativo. Caso NÃO queira utilizar alguma,
              desmarque a caixa de seleção. Se houver necessidade, você pode
              cadastrar uma nova forma.
            </Text>
          </Box>

          <Box w="full" textAlign="end">
            <VStack bg="gray.50">
              {listFormaRecebimento.map((formaRecebimento, index) => (
                <Flex
                  p="16px 20px"
                  gap="12px"
                  h="48px"
                  w="full"
                  transition="all 0.2s"
                  bg={formaRecebimento.selecionado ? 'purple.50' : 'white'}
                  borderWidth="1px"
                  borderStyle="solid"
                  borderColor={
                    formaRecebimento.selecionado ? 'purple.50' : 'white'
                  }
                  _hover={{
                    borderColor: 'rgba(85, 2, 178, 0.5)',
                    transition: 'all 0.2s',
                  }}
                  _active={{ bg: 'white' }}
                  borderRadius="5px"
                  boxShadow="0px 0px 4px #00000029"
                  alignItems="center"
                  onClick={() =>
                    handleAlternarStatusSelecionadoFormaRecebimento(index)
                  }
                  cursor="pointer"
                >
                  <Checkbox
                    colorScheme="primary"
                    mb="0px"
                    onChange={() =>
                      handleAlternarStatusSelecionadoFormaRecebimento(index)
                    }
                    isChecked={formaRecebimento.selecionado}
                  />
                  <Text fontSize="md">{formaRecebimento.nome}</Text>
                </Flex>
              ))}
            </VStack>
            <Text
              textDecoration="underline"
              color="blue.700"
              fontSize="sm"
              margin="8px 0 0 auto"
              w="fit-content"
              letterSpacing="0px"
              cursor="pointer"
              onClick={() => handleAbrirModalCadastrarFormaRecebimento()}
            >
              Criar nova forma de recebimento
            </Text>
          </Box>
        </VStack>
      </Box>
      <FooterSmartPos
        mb="20px"
        handleAvancar={handleAvancar}
        isDisabledAvancar={formasRecebimentoSelecionadas?.length === 0}
      />
    </>
  );
};
