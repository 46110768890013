import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export const NotaFiscalExibirTela = {
  TELA_INICIAL: 0,
  DADOS_TRANSPORTE: 1,
  DADOS_NOTA: 2,
  EMITINDO_NOTA_FISCAL: 3,
  NOTA_APROVADA: 4,
  NOTA_POSSUI_REJEICAO: 5,
  NOTA_REJEITADA: 6,
};

export type FormData = {
  transportadoraId: { value: string; label: string } | null;
  modalidadeFrete: number | null;
  dataSaida: Date;
  dataEmissao: Date;
  numeracaoVolumeTransportadora: number;
  quantidadeVolumeTransportadora: number;
  pesoLiquido?: number;
  pesoBruto?: number;
  identificacaoIntermediador?: string;
};

const schema = yup.object().shape({
  transportadoraId: yup
    .object()
    .shape({
      value: yup.string().nullable().default(null),
      label: yup.string().nullable().default(null),
    })
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  modalidadeFrete: yup
    .number()
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  dataSaida: yup.date().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  numeracaoVolumeTransportadora: yup
    .number()
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  quantidadeVolumeTransportadora: yup
    .number()
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);
