import { useCallback, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteRotas from 'constants/rotas';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import api, { ResponseApi } from 'services/api';
import { propriedadesEtiquetas } from 'constants/enum/propriedadesEtiquetas';

import { EtiquetasPersonalizadas } from '../index';
import { PropertiesEtiquetas } from '../validationForm';

type AlterarEtiquetasProps = {
  id: string;
  nome: string;
  configuracoes: string;
  ativo: boolean;
};

type RouteParams = {
  id: string;
};

export function AlterarEtiquetas() {
  const [
    obterInformacoesEtiqueta,
    setObterInformacoesEtiqueta,
  ] = useState<PropertiesEtiquetas>();
  const [obterNomeEtiqueta, setObterNomeEtiqueta] = useState<string>('');
  const [obterIdEtiqueta, setObterIdEtiqueta] = useState<string>('');

  const history = useHistory();
  const isMountedRef = useIsMountedRef();
  const { id } = useParams<RouteParams>();

  const handleGetContaFinanceira = useCallback(async () => {
    const response = await api.get<void, ResponseApi<AlterarEtiquetasProps>>(
      ConstanteEnderecoWebservice.ETIQUETA_PERSONALIZADA_OBTER,
      {
        params: { id },
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso && isMountedRef.current && response.dados) {
        const listaInformacoesVisualizar: PropertiesEtiquetas = JSON.parse(
          response.dados.configuracoes.toString() || ''
        );

        propriedadesEtiquetas?.propriedadesItensEtiqueta?.forEach(
          async (propriedadeEtiqueta) => {
            if (
              listaInformacoesVisualizar?.propriedadesItensEtiqueta?.some(
                (infoEtiquetaJaCadastrada) =>
                  infoEtiquetaJaCadastrada?.id === propriedadeEtiqueta?.id
              )
            ) {
              setObterInformacoesEtiqueta(listaInformacoesVisualizar);
            } else {
              await listaInformacoesVisualizar?.propriedadesItensEtiqueta?.push(
                propriedadeEtiqueta
              );
              setObterInformacoesEtiqueta(listaInformacoesVisualizar);
            }
          }
        );

        setObterNomeEtiqueta(response.dados.nome);
        setObterIdEtiqueta(response?.dados.id);
      } else {
        history.push(ConstanteRotas.ETIQUETAS_PERSONALIZADAS);
      }
    }
  }, [history, isMountedRef, id]);
  useEffect(() => {
    handleGetContaFinanceira();
  }, [handleGetContaFinanceira]);

  return (
    <EtiquetasPersonalizadas
      isAlterar
      obterNomeEtiqueta={obterNomeEtiqueta}
      obterIdEtiqueta={obterIdEtiqueta}
      listaInformacoesVisualizar={obterInformacoesEtiqueta}
    />
  );
}
