import { Flex, GridItem, Text } from '@chakra-ui/react';

import { LabelSelect } from '../components/LabelSelect';
import { Cor } from '../components/Cor';

export const Menu = () => {
  return (
    <GridItem
      colSpan={[12, 12, 6, 4, 4]}
      minW={['100%', '100%', '50%', '373px']}
    >
      <Text fontWeight="bold">Menu</Text>
      <Flex
        bg="#E8E8E8"
        minH={['inherit', 'inherit', '493px']}
        padding="24px"
        pt="16px"
        borderRadius="5px"
        border="1px solid #CCCCCC"
        flexDir="column"
        gap="16px"
        height={['fit-content', 'full', 'full', 'full', 'fit-content']}
      >
        <Cor name="menuBackgroundColor" label="Cor de fundo" />
        <LabelSelect
          options={[
            {
              label: 'Branco',
              value: '#FFFFFF',
            },
            {
              label: 'Preto',
              value: '#000000',
            },
          ]}
          name="menuTextColor"
          label="Cor do texto"
        />
        <LabelSelect
          options={[
            {
              label: 'Claro',
              value: '#FFFFFF80',
            },
            {
              label: 'Escuro',
              value: '#00000080',
            },
          ]}
          name="menuSeparatorColor"
          label="Cor da linha de separação"
        />
        <LabelSelect
          name="menuActionColor"
          label="Ação de clique no menu"
          options={[
            {
              label: 'Claro',
              value: '#FFFFFF80',
            },
            {
              label: 'Escuro',
              value: '#00000080',
            },
          ]}
        />
        <LabelSelect
          name="fontFamily"
          showBorder={false}
          label="Fonte"
          options={[
            {
              label: 'Clássica',
              value: 'HelveticaNeue',
            },
            {
              label: 'Moderna',
              value: 'KoHo',
            },
            {
              label: 'Básica',
              value: 'Barlow',
            },

            {
              label: 'Divertida',
              value: 'GochiHand',
            },
            {
              label: 'Luxuosa',
              value: 'Aleo',
            },
          ]}
        />
      </Flex>
    </GridItem>
  );
};
