import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';

export type CamposPersonalizados = {
  campoPersonalizadoId: string;
  valor: string;
};

export type FormData = {
  tabelaPrecoId: string;
  nomeSkuCodigoExternoBarrasGtinEan: string;
  tipoRelatorio: number;
  tipoEstoque: number;
  statusConsulta: number;
  cores: string;
  tags: string;
  tamanhos: string;
  categoriasProduto: string;
  marcas: string;
  vendedorId: string;
  camposPersonalizados: CamposPersonalizados[];
};

const schema = yup.object().shape({
  tabelaPrecoId: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

export const yupResolver = yupResolverInstance(schema);
