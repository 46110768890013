import { useCallback } from 'react';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
  ListRowProps,
} from 'react-virtualized';
import { Box } from '@chakra-ui/react';
import { components, MenuListComponentProps } from 'react-select';

import OptionType from 'types/optionType';

type MenuListVirtualizedProps = {
  search: string;
  isLoading: boolean;
  listOptions: OptionType<string>[];
  loadOptions: (param: string) => Promise<void>;
  hasMore: boolean;
} & MenuListComponentProps<OptionType>;

export const MenuListVirtualized = ({
  children,
  isLoading,
  search,
  hasMore,
  listOptions,
  loadOptions,
  ...props
}: MenuListVirtualizedProps) => {
  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 32,
  });

  const getMoreOptions = useCallback(
    async (isObserver: boolean) => {
      if (isObserver && hasMore) {
        await loadOptions(search);
      }
    },
    [hasMore, search, loadOptions]
  );

  if (!Array.isArray(children)) {
    return children;
  }

  const options = children.filter(Boolean);

  return (
    <Box
      sx={{
        borderRadius: 'md',
        borderWidth: '1px',
        borderColor: 'gray.100',
        boxShadow: '0px 0px 6px #00000034',
      }}
    >
      <components.MenuList {...props}>
        <Box w="full" h="290px" opacity={isLoading ? 0.5 : 1}>
          <AutoSizer>
            {({ width, height }) => (
              <List
                width={width}
                height={height}
                rowCount={options.length}
                rowHeight={cache.rowHeight}
                deferredMeasurementCache={cache}
                onScroll={(e) => {
                  const lastItemIsObserved =
                    e.scrollTop >= e.scrollHeight - e.clientHeight;

                  getMoreOptions(lastItemIsObserved);
                }}
                rowRenderer={({ key, parent, index, style }: ListRowProps) => (
                  <CellMeasurer
                    cache={cache}
                    key={key}
                    columnIndex={0}
                    rowIndex={index}
                    parent={parent}
                  >
                    <Box key={key} style={style}>
                      {options[index]}
                    </Box>
                  </CellMeasurer>
                )}
              />
            )}
          </AutoSizer>
        </Box>
      </components.MenuList>
    </Box>
  );
};
