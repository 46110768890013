import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import { isValidCnpj } from 'helpers/validation/IsValidCpfCnpj';

export type FormData = {
  identificador: string;
  cnpj: string;
  identificacaoVendedor: string | null;
  ativo: boolean;
};

const defaultValues = {
  identificador: '',
  cnpj: '',
  identificacaoVendedor: null,
  ativo: true,
} as FormData;

const schema = yup.object().shape({
  identificador: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  cnpj: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .test('cnpj', ConstanteMensagemValidacao.CNPJ_INVALIDO, (value) => {
      if (value) {
        return isValidCnpj(value);
      }

      return false;
    }),
  identificacaoVendedor: yup.string().nullable(),
  ativo: yup.boolean(),
});

const yupResolver = yupResolverInstance(schema);

export { yupResolver, defaultValues };
