import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import OperacaoItem from 'types/operacaoItem';

export interface OperacaoItemCadastrar extends OperacaoItem {
  tela: number;
  operacaoId?: string;
}

const cadastrarOperacaoItem = async (
  operacaoItemViewModel: OperacaoItemCadastrar
): Promise<string | undefined> => {
  const response = await api.post<void, ResponseApi<string>>(
    ConstanteEnderecoWebservice.PEDIDOORCAMENTOVENDA_ITEM_CADASTRAR,
    {
      ...operacaoItemViewModel,
    }
  );

  if (response) {
    if (response.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (response.sucesso && response.dados) {
      return response.dados;
    }
  }

  return undefined;
};

export default cadastrarOperacaoItem;
