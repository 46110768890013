import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Modal } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';

import ModelosFiscaisEnum from 'constants/enum/fiscal/modelosFiscais';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import formatUTCToLocateDateTime from 'helpers/format/formatUTCToLocateDateTime';
import { setFocusFieldError } from 'helpers/validation/focusFieldError';
import { useSignalRContext } from 'store/SignalR';
import TipoCertificadoEnum from 'constants/enum/fiscal/tipoCertificado';

import { SalvarConfirmarIcon } from 'icons';
import ButtonPadrao from 'components/Button/ButtonPadrao';
import InputNumerico from 'components/Input/InputNumerico';
import TextAreaPadrao from 'components/TextArea/TextAreaPadrao';
import SelectPadrao from 'components/Select/SelectPadrao';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import ModalPadrao from 'components/Modal/ModalPadrao';
import ManterFoco from 'components/Geral/ManterFoco';

import { ModalAtencao } from 'components/Modal/ModalAtencao';
import { useForm, yupResolver } from './validationForm';

interface InutilizarNotaModalPrps {
  show: boolean;
  onHide: () => void;
}

interface LojaNumerosSerieInterface {
  nFeNumeroSerie: number;
  nfCeNumeroSerie: number;
}

const InutilizarModal: React.FC<InutilizarNotaModalPrps> = ({
  show,
  onHide,
}) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    register,
    setValue,
    setFocus,
  } = useForm({
    resolver: yupResolver,
    shouldFocusError: false,
    shouldUnregister: true,
  });

  const { hubConnection, joinGroup } = useSignalRContext();
  const [isLoading, setIsLoading] = useState(false);

  const [numerosSerieLoja, setNumerosSerieLoja] = useState<
    LojaNumerosSerieInterface | undefined
  >(undefined);

  const handlePostInutilizar = async () => {
    const data = getValues();

    const identificador = uuidv4();

    joinGroup(`${identificador}_transmitindo-inutilizacao`);

    setIsLoading(true);

    const response = await api.post<void, ResponseApi<number | undefined>>(
      ConstanteEnderecoWebservice.NOTA_FISCAL_INUTILIZAR,
      {
        ...data,
        identificador,
        dataEmissao: formatUTCToLocateDateTime(new Date().toString()),
      }
    );

    if (response.sucesso) {
      if (response.dados && response.dados === Number(TipoCertificadoEnum.A3)) {
        const timeout = window.setTimeout(
          () => {
            ModalAtencao({
              title: 'A comunicação com o certificado digital A3 falhou',
              text:
                'Verifique se o certificado digital está conectado no computador e se o aplicativo Módulo desktop está sendo executado.',
            });
            onHide();
          },
          1000 * 60 // 1 minuto
        );

        hubConnection.off('sucesso-transmissao-a3');
        hubConnection.on('sucesso-transmissao-a3', () => {
          window.clearTimeout(timeout);

          setIsLoading(false);
          toast.success(t('A inutilização foi realizada com sucesso.'));
          onHide();
        });

        hubConnection.off('rejeicao-transmissao-a3');
        hubConnection.on('rejeicao-transmissao-a3', (message: string) => {
          window.clearTimeout(timeout);

          setIsLoading(false);
          toast.warning(message);
        });

        return;
      }

      toast.success(t('A inutilização foi realizada com sucesso.'));
      onHide();
    }

    setIsLoading(false);

    if (response.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }
  };

  const errorHandle = (errorList: any) => {
    setFocusFieldError(
      ['numeroSerie', 'numeroInicial', 'numeroFinal', 'motivo'],
      errorList
    );
  };

  const onSubmit = handleSubmit(async () => {
    await handlePostInutilizar();
  }, errorHandle);

  const handleGetNumeroSerie = useCallback(async () => {
    if (show) {
      setIsLoading(true);

      const response = await api.get<
        void,
        ResponseApi<LojaNumerosSerieInterface>
      >(ConstanteEnderecoWebservice.LOJA_OBTER_NUMEROS_SERIE);

      if (response.sucesso) {
        setNumerosSerieLoja(response.dados);
        setValue('numeroSerie', response.dados.nFeNumeroSerie);
      }

      if (response.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }

      setIsLoading(false);
    }
  }, [setValue, show]);

  const onSelectModeloFiscal = async () => {
    const { modeloFiscal } = getValues();

    if (modeloFiscal === ModelosFiscaisEnum.NFCe) {
      setValue('numeroSerie', numerosSerieLoja?.nfCeNumeroSerie);
    } else {
      setValue('numeroSerie', numerosSerieLoja?.nFeNumeroSerie);
    }
  };

  useEffect(() => {
    handleGetNumeroSerie();
  }, [handleGetNumeroSerie]);

  return (
    <>
      {show && (
        <ModalPadrao
          isOpen={show}
          handleOnHide={onHide}
          title={t('Inutilizar')}
          subtitle={t(
            'Informe o intervalo de números de notas que deseja inutilizar.'
          )}
          onEntered={() => setFocus('modeloFiscal')}
          size="lg"
          minWidth="40%"
        >
          <ManterFoco
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <Form>
              {isLoading && <LoadingPadrao />}
              <Modal.Body>
                <Form.Row>
                  <Col>
                    <SelectPadrao
                      id="modeloFiscal"
                      name="modeloFiscal"
                      label={t('Modelo fiscal')}
                      noSelectedText={t('Clique aqui para selecionar.')}
                      defaultValue={55}
                      control={control}
                      error={errors.modeloFiscal}
                      options={Object.entries(
                        ModelosFiscaisEnum.properties
                      ).map((value: any) => {
                        return (
                          {
                            label: t(value[1].name),
                            value: value[1].value,
                          } || {}
                        );
                      })}
                      readonly={false}
                      required
                      onSelect={() => {
                        onSelectModeloFiscal();
                      }}
                    />
                  </Col>
                  <Col xs={0}>
                    <InputNumerico
                      type="text"
                      id="numeroSerie"
                      name="numeroSerie"
                      label={t('Número de série')}
                      required
                      placeholder={t('Informe o número de série')}
                      maxLength={3}
                      error={errors.numeroSerie}
                      disabled={false}
                      control={control}
                      defaultValue={numerosSerieLoja?.nFeNumeroSerie}
                    />
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col xs={0}>
                    <InputNumerico
                      type="text"
                      id="numeroInicial"
                      name="numeroInicial"
                      label={t('Número inicial')}
                      required
                      placeholder={t('Informe o número inicial')}
                      maxLength={9}
                      error={errors.numeroInicial}
                      disabled={false}
                      control={control}
                    />
                  </Col>
                  <Col xs={0}>
                    <InputNumerico
                      type="text"
                      id="numeroFinal"
                      name="numeroFinal"
                      label={t('Número final')}
                      required
                      placeholder={t('Informe o número final')}
                      maxLength={9}
                      error={errors.numeroFinal}
                      disabled={false}
                      control={control}
                    />
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col xs={0}>
                    <TextAreaPadrao
                      id="motivo"
                      label={t('Motivo')}
                      placeholder={t('Digite o motivo')}
                      error={errors.motivo}
                      disabled={false}
                      maxLength={255}
                      required
                      {...register('motivo')}
                    />
                  </Col>
                </Form.Row>
              </Modal.Body>

              <Modal.Footer>
                <ButtonPadrao
                  id="fechar"
                  name="fechar"
                  text={t('Fechar')}
                  type="button"
                  variant="outline-secondary"
                  onClick={onHide}
                  style={{ height: '35px', width: '100%', maxWidth: '100px' }}
                />
                <ButtonPadrao
                  id="salvarInutilizar"
                  name="salvar"
                  text={t('Salvar')}
                  icon={SalvarConfirmarIcon}
                  type="button"
                  variant="success"
                  onClick={() => {
                    onSubmit();
                  }}
                  style={{ height: '35px', width: '100%', maxWidth: '120px' }}
                />
              </Modal.Footer>
            </Form>
          </ManterFoco>
        </ModalPadrao>
      )}
    </>
  );
};

export default InutilizarModal;
