import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import ConstanteRotas from 'constants/rotas';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { ContainerListagem } from 'components/Layout/Listagem/containerListagem';

import { useForm, yupResolver } from '../validationForm';
import { UncontrolledFormRefProps, UncontrolledForm } from '..';

interface VendedorInterface {
  id: string;
  genero: number;
  nome: string;
  foto: string;
  email: string;
  telefone: string;
  comissao: number;
  metaMensal: number;
  dataNascimento: string;
  ativo: boolean;
  dataHoraCadastro: string;
  dataHoraUltimaAlteracao: string;
}

type TParams = { id: string };

const Visualizar = () => {
  const { id: idRota } = useParams<TParams>();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [dataHoraCadastro, setDataHoraCadastro] = useState('');
  const [dataHoraUltimaAlteracao, setDataHoraUltimaAlteracao] = useState('');

  const UncontrolledFormRef = useRef<UncontrolledFormRefProps>();

  const formMethods = useForm({
    resolver: yupResolver,
  });

  const { reset } = formMethods;

  const getVendedor = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<void, ResponseApi<VendedorInterface>>(
      ConstanteEnderecoWebservice.VENDEDOR_OBTER,
      {
        params: { id: idRota },
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso) => toast.warning(aviso));
      }

      if (response.sucesso && response.dados) {
        const { dados } = response;

        reset({
          ...dados,
          dataNascimento: dados.dataNascimento?.split('T')[0],
        });

        setDataHoraCadastro(dados.dataHoraCadastro);
        setDataHoraUltimaAlteracao(dados.dataHoraUltimaAlteracao);

        await UncontrolledFormRef.current?.getLojas(dados.id);
      } else {
        history.push(ConstanteRotas.VENDEDOR);
      }
    }

    setIsLoading(false);
  }, [history, idRota, reset]);

  useEffect(() => {
    setIsLoading(true);

    getVendedor();
  }, [getVendedor]);

  return (
    <ContainerListagem
      formMethods={formMethods}
      isLoading={isLoading}
      maxWidth="full"
      dataHoraUltimaAlteracao={dataHoraUltimaAlteracao}
      dataHoraCadastro={dataHoraCadastro}
      isVisualizar
    >
      {isLoading && <LoadingPadrao />}
      <UncontrolledForm ref={UncontrolledFormRef} readonly />
    </ContainerListagem>
  );
};

export default Visualizar;
