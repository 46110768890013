import StatusOperacaoEnum from 'constants/enum/statusOperacao';
import { setDateMaxHours, setDateMinHours } from 'helpers/data/setHoursDate';

export type FormData = {
  lojaId: string;
  status: number;
  localEstoqueId: string | null;
  numeroOperacao: string;
  identificacaoTipoOperacao: number | null;
  dataEmissaoInicio: Date;
  dataEmissaoFim: Date;
};

export const formDefaultValues = (lojaId: string): FormData => ({
  lojaId,
  localEstoqueId: null,
  status: StatusOperacaoEnum.EFETUADA,
  numeroOperacao: '',
  identificacaoTipoOperacao: null,
  dataEmissaoInicio: setDateMinHours(new Date()),
  dataEmissaoFim: setDateMaxHours(new Date()),
});
