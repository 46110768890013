import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Col, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { useNotaFiscalFormularioContext } from 'store/NotaFiscal/NotaFiscalFormulario';
import api, { ResponseApi } from 'services/api';
import IcmsCstCsosnEnum from 'constants/enum/fiscal/icmsCstCsosn';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { CasasDecimais } from 'store/Padronizacao/Padronizacao';

import { SalvarInserirNovoIcon, SalvarConfirmarIcon } from 'icons';
import SelectPadrao from 'components/Select/SelectPadrao';
import { BootstrapNumberInput } from 'components/update/Input/BootstrapNumberInput';
import SelectProduto, {
  ProdutoInterface,
} from 'components/Select/SelectProduto';
import SelectRegraFiscal from 'components/Select/SelectRegraFiscal';
import TabsPadrao from 'components/Tabs/TabsPadrao';
import ButtonPadrao from 'components/Button/ButtonPadrao';
import ModalPadrao from 'components/Modal/ModalPadrao';
import InputNumerico from 'components/Input/InputNumerico';

import { ContainerTab } from './styles';
import { configVisibilidade, useForm, yupResolver } from './validationForm';
import InformacoesGerais from './InformacoesGerais';
import ICMS from './ICMS';
import ICMSST from './ICMSST';
import OutrosTributos from './OutrosTributos';
import DadosImportacao from './DadosImportacao';
import OutrosValores from './OutrosValores';

type OperacaoFiscalSelecionada = {
  cfop: string;
  cstCsosn: number;
  id: string;
  nome: string;
  pisConfins: number;
  tipoOperacao: number;
};
interface UncontrolledFormInterface {
  indexProduto: number;
  show: boolean;
  onHide: (deveReiniciar: boolean) => void;
  operacaoFiscalSelecionada: OperacaoFiscalSelecionada;

  casasDecimais: CasasDecimais;
}

interface CalculoValoresItemNotaFiscalInterface {
  icmsReducaoBaseCalculo: number;
  icmsBaseCalculo: number;
  icmsAliquota: number;
  icmsValor: number;
  fcpBaseCalculo: number;
  fcpAliquota: number;
  fcpValor: number;
  icmsAproveitamentoAliquota: number;
  icmsAproveitamentoValor: number;
  icmsAliquotaInterestadual: number;
}

const ProdutosModal: React.FC<UncontrolledFormInterface> = ({
  indexProduto,
  show,
  onHide,
  operacaoFiscalSelecionada,
  casasDecimais,
}) => {
  const { t } = useTranslation();

  const [isSmallerThan420] = useMediaQuery('(max-width: 420px)');

  const {
    setValue: parentSetValue,
    getValues: parentGetValues,
    watch: parentWatch,
  } = useFormContext();

  const tipoDeOperacaoSelecionada = parentWatch('tipoDeOperacaoDoSistema');

  const {
    lojaInformacoesFiscais,
    calcularTotais,
  } = useNotaFiscalFormularioContext();

  const {
    formState: { errors },
    control,
    handleSubmit,
    reset,
    register,
    watch,
    setValue,
    getValues,
    setFocus,
  } = useForm<any>({
    resolver: yupResolver,
  });

  const tabInformacoesAdicionaisRef = useRef() as React.MutableRefObject<HTMLAnchorElement>;
  const tabPisCofinsIpiRef = useRef() as React.MutableRefObject<HTMLAnchorElement>;
  const tabIcmsSTRef = useRef() as React.MutableRefObject<HTMLAnchorElement>;

  const [cstCsosnItens, setCstCsosnItens] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);
  const [
    AlterarCampoCalcularTributos,
    setAlterarCampoCalcularTributos,
  ] = useState(false);

  const alteracao = indexProduto >= 0;

  const produtoWatch = watch('produto', null);
  const regraFiscalWatch = watch('regraFiscal');
  const cstCsosnWatch = watch('cstCsosn', null);
  const clienteSelecionado = parentGetValues('cliente')?.obj;
  const [valorTotalOld, setValortotalOld] = useState(0);

  const valueCalcularTributo = regraFiscalWatch?.obj?.calcularTributosMunEstFed;

  const getSelectedRegraFiscal = (regraFiscal: any) => {
    if (regraFiscal) {
      setValue('cstCsosn', regraFiscal.cstCsosn, { shouldValidate: true });
      parentSetValue('operacaoFiscalSelecionada', regraFiscal);
      parentSetValue('tipoDeOperacaoDoSistema', regraFiscal.tipoOperacao);

      if (regraFiscal.cfop) {
        setValue('cfop', String(regraFiscal.cfop), { shouldValidate: true });
      }

      if (regraFiscal.pisConfins) {
        setValue('pisCst', regraFiscal.pisConfins, { shouldValidate: true });
        setValue('cofinsCst', regraFiscal.pisConfins, { shouldValidate: true });
      }
    }
  };

  const adicionarBaseLegal = (regraFiscal: any) => {
    const basesLegais = regraFiscal?.listaBaseLegal as Array<
      { id: string; nome: string } | undefined
    >;

    if (basesLegais) {
      const basesLegaisParent = parentGetValues('basesLegais');

      const basesLegaisAdicionarList = [] as Array<any>;

      basesLegais.forEach((baseLegalAdicionar) => {
        if (
          !basesLegaisParent.some(
            (baseLegalAdicionada: any) =>
              baseLegalAdicionada?.id === baseLegalAdicionar?.id
          )
        ) {
          basesLegaisAdicionarList.push({
            id: baseLegalAdicionar?.id,
            descricao: baseLegalAdicionar?.nome,
          });
        }
      });

      parentSetValue('basesLegais', [
        ...basesLegaisParent,
        ...basesLegaisAdicionarList,
      ]);
    }
  };

  const handleAddItem = (data: any) => {
    const itemsAdicionados = parentGetValues('documentoFiscalItens');

    if (!alteracao) {
      parentSetValue('documentoFiscalItens', [
        ...itemsAdicionados,
        {
          ...data,
          produtoCorTamanhoId: data.produto.value,
          numeroItem: itemsAdicionados.length + 1,
        },
      ]);
    } else {
      itemsAdicionados[indexProduto] = {
        ...data,
        numeroItem: itemsAdicionados[indexProduto].numeroItem,
        produtoCorTamanhoId: data.produto.value,
      };

      parentSetValue('documentoFiscalItens', [...itemsAdicionados]);
    }

    if (data.regraFiscal) {
      adicionarBaseLegal(data.regraFiscal.obj);
    }

    calcularTotais();
    return true;
  };

  const handleOnInvalid = (errorList: any) => {
    if (
      errorList.enquadramentoLegal ||
      errorList.pisCst ||
      errorList.cofinsCst
    ) {
      tabPisCofinsIpiRef.current?.click();
    }
  };

  const onSubmitReset = handleSubmit(async (data) => {
    const success = await handleAddItem(data);

    if (success) {
      onHide(true);
    }
  }, handleOnInvalid);

  const exibirCampo = (
    name: string,
    value: number,
    cst: number | null | undefined
  ) => {
    const exibir = configVisibilidade[name]?.includes(cst);

    if (!exibir) {
      setValue(name, 0);
    } else {
      setValue(name, value);
    }

    return cstCsosnWatch !== undefined && exibir;
  };

  const onSubmit = handleSubmit(async (data) => {
    const success = await handleAddItem(data);

    if (success) {
      onHide(false);
    }
  }, handleOnInvalid);

  const calcularValoresItem = useCallback(
    async (valueCst?: number | null | undefined) => {
      const { quantidade, valorUnitario } = getValues();

      const valorTotal = (quantidade * valorUnitario).toFixed(2);

      setValue('valorTotal', +valorTotal);

      const data = getValues();
      const items = parentGetValues('documentoFiscalItens');
      const produto = items[indexProduto];

      if (data.produto) {
        const { valorFrete, valorProdutos } = parentGetValues();

        let valorFreteCalculado = 0;

        if (!alteracao) {
          valorFreteCalculado =
            valorProdutos + data.valorTotal > 0
              ? (valorFrete * data.valorTotal) /
                (valorProdutos + data.valorTotal)
              : 0;
        } else {
          // alteração
          let diferencaNovoValor = 0;

          if (data.valorTotal < valorTotalOld) {
            diferencaNovoValor = valorTotalOld - data.valorTotal;

            valorFreteCalculado =
              (valorFrete * data.valorTotal) /
              (valorProdutos - diferencaNovoValor);
          } else if (data.valorTotal > valorTotalOld) {
            diferencaNovoValor = data.valorTotal - valorTotalOld;

            valorFreteCalculado =
              (valorFrete * data.valorTotal) /
              (valorProdutos + diferencaNovoValor);
          } else {
            valorFreteCalculado = data.valorFrete;
          }
        }

        const response = await api.post<
          void,
          ResponseApi<CalculoValoresItemNotaFiscalInterface>
        >(
          ConstanteEnderecoWebservice.NOTA_FISCAL_CALCULAR_VALORES_ITENS,
          {
            produtoCorTamanhoId: data.produto.obj.id,
            valorTotal: data?.valorTotal || 0,
            valorFrete: valorFreteCalculado,
            valorSeguro: data.valorSeguro,
            valorDesconto: data.valorDesconto,
            valorOutrasDespesas: data.valorOutrasDespesas,
            ipiValor: data.ipiValor ? data.ipiValor : 0,
            icmsAproveitamentoAliquota: data.icmsAproveitamentoAliquota
              ? data.icmsAproveitamentoAliquota
              : 0,
            regimeTributarioLoja: lojaInformacoesFiscais?.regimeTributario,
          },
          {
            params: {
              estadoClienteId: clienteSelecionado?.estadoId,
              estadoLojaId: lojaInformacoesFiscais?.estadoId,
            },
          }
        );

        if (response.sucesso) {
          const {
            fcpBaseCalculo,
            fcpAliquota,
            fcpValor,
            icmsAproveitamentoAliquota,
            icmsAproveitamentoValor,
            icmsAliquotaInterestadual,
          } = response.dados;

          exibirCampo(
            'icmsReducaoBaseCalculo',
            produto?.icmsReducaoBaseCalculo,
            valueCst
          );
          exibirCampo('icmsBaseCalculo', produto?.icmsBaseCalculo, valueCst);
          exibirCampo('icmsAliquota', produto?.icmsAliquota, valueCst);
          exibirCampo('icmsValor', produto?.icmsValor, valueCst);
          exibirCampo('fcpBaseCalculo', fcpBaseCalculo, valueCst);
          exibirCampo('fcpAliquota', fcpAliquota, valueCst);
          exibirCampo('fcpValor', fcpValor, valueCst);
          exibirCampo(
            'icmsAproveitamentoAliquota',
            icmsAproveitamentoAliquota,
            valueCst
          );
          exibirCampo(
            'icmsAproveitamentoValor',
            icmsAproveitamentoValor,
            valueCst
          );
          setValue('icmsAliquotaInterestadual', icmsAliquotaInterestadual);

          setValue('valorFrete', valorFreteCalculado);
        }
      } else {
        setValue('valorTotal', 0);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [alteracao, valorTotalOld, exibirCampo]
  );

  const alterandoValorDosProdutos = (produto: ProdutoInterface) => {
    if (produto) {
      setValue('valorUnitario', produto?.preco);
      setValue('icmsStRetidoBaseCalculo', produto?.icmsStRetidoBaseCalculo);
      setValue('icmsStRetidoValor', produto?.icmsStRetidoValor);
      setValue('fcpStRetidoBaseCalculo', produto?.fcpStRetidoBaseCalculo);
      setValue('fcpStRetidoValor', produto?.fcpStRetidoValor);
      setValue('icmsAliquota', produto?.icmsAliquota);
      setValue('pisAliquota', produto?.pisAliquota);
      setValue('cofinsAliquota', produto?.cofinsAliquota);
      setValue('fcpAliquota', produto?.fcpAliquota);
      setValue('pesoLiquido', produto?.pesoLiquido);
      setValue('pesoBruto', produto?.pesoBruto);
      setValue(
        'produto.obj.informacoesAdicionais',
        produto?.informacoesAdicionais
      );
      setValue('icmsReducaoBaseCalculo', produto?.icmsReducaoBaseCalculo);

      calcularValoresItem(cstCsosnWatch);
    } else {
      setValue('valorUnitario', 0);
      setValue('valorTotal', 0);
    }
  };

  const getObterInformacoesNotaFiscal = async (
    produtoPesquisado: ProdutoInterface
  ) => {
    if (produtoPesquisado) {
      setIsLoading(true);
      const response = await api.get<void, ResponseApi<ProdutoInterface>>(
        ConstanteEnderecoWebservice.PRODUTO_COR_TAMANHO_OBTER_INFORMACOES_NOTA_FISCAL,
        {
          params: { produtoCorTamanhoId: produtoPesquisado.id },
        }
      );
      if (response) {
        if (response?.avisos) {
          response.avisos.forEach((aviso: string) => toast.warning(aviso));
        }
        if (response.sucesso && response.dados) {
          alterandoValorDosProdutos(response.dados);
        }
      }
      setIsLoading(false);
    }
  };
  useEffect(() => {
    reset({ quantidade: 1 });

    if (alteracao && show) {
      const items = parentGetValues('documentoFiscalItens') as Array<any>;
      const produto = items[indexProduto];

      setValortotalOld(produto.valorTotal);
      setTimeout(() => {
        reset(produto);
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alteracao, show]);

  useEffect(() => {
    function shouldFocusInitialTab() {
      return (
        cstCsosnWatch === null ||
        cstCsosnWatch === 102 ||
        cstCsosnWatch === 103 ||
        cstCsosnWatch === 202 ||
        cstCsosnWatch === 203 ||
        cstCsosnWatch === 300 ||
        cstCsosnWatch === 400 ||
        cstCsosnWatch === 0 ||
        cstCsosnWatch === 20 ||
        cstCsosnWatch === 40 ||
        cstCsosnWatch === 41 ||
        cstCsosnWatch === 50 ||
        cstCsosnWatch === 51 ||
        cstCsosnWatch === 101
      );
    }
    if (shouldFocusInitialTab()) {
      tabInformacoesAdicionaisRef?.current?.click();
    }
  }, [cstCsosnWatch]);

  useEffect(() => {
    const icmsCstCsosnEnumList = Object.entries(
      IcmsCstCsosnEnum.properties
    ).map((value: any) => {
      return (
        {
          label: t(value[1].name),
          value: value[1].value,
        } || {}
      );
    });

    if (
      lojaInformacoesFiscais?.regimeTributario === 1 ||
      lojaInformacoesFiscais?.regimeTributario === 2 ||
      lojaInformacoesFiscais?.regimeTributario === 4
    ) {
      setCstCsosnItens(icmsCstCsosnEnumList.filter((item) => item.value > 100));
    } else {
      setCstCsosnItens(icmsCstCsosnEnumList.filter((item) => item.value < 100));
    }
  }, [lojaInformacoesFiscais?.regimeTributario, t]);

  useEffect(() => {
    function lastValueSelectedForRegraFiscal() {
      setTimeout(() => {
        if (operacaoFiscalSelecionada && !alteracao) {
          setValue('cstCsosn', operacaoFiscalSelecionada.cstCsosn, {
            shouldValidate: true,
          });

          setValue('cfop', String(operacaoFiscalSelecionada.cfop || ''), {
            shouldValidate: true,
          });

          setValue('pisCst', operacaoFiscalSelecionada.pisConfins, {
            shouldValidate: true,
          });
          setValue('cofinsCst', operacaoFiscalSelecionada.pisConfins, {
            shouldValidate: true,
          });

          setValue('regraFiscal', {
            label: operacaoFiscalSelecionada.nome,
            value: operacaoFiscalSelecionada.id,
          });
        }
      }, 80);
    }
    lastValueSelectedForRegraFiscal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const baseCalculo = watch('icmsEfetivoBaseCalculo');
  const aliquotaICMS = watch('icmsEfetivoAliquota');

  const cliente = parentWatch('cliente');

  if (
    cliente &&
    cliente?.obj?.estadoId !== lojaInformacoesFiscais.estadoId &&
    cliente?.obj?.consumidorFinal &&
    cliente?.obj?.indicadorIe === 9
  ) {
    setValue('icmsUfDestinoBaseCalculo', baseCalculo);
    setValue('icmsAliquotaInterestadual', aliquotaICMS);
  }

  useEffect(() => {
    if (AlterarCampoCalcularTributos) {
      setValue('calcularTributosMunEstFed', valueCalcularTributo);
    }
  }, [AlterarCampoCalcularTributos, setValue, valueCalcularTributo]);

  useEffect(() => {
    calcularValoresItem(cstCsosnWatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cstCsosnWatch]);

  return (
    <>
      {show && (
        <ModalPadrao
          isOpen={show}
          handleOnHide={() => onHide(false)}
          title={t(`${alteracao ? 'Alterar' : 'Adicionar'} produto`)}
          subtitle={t('Informe os dados do produto da nota.')}
          onEntered={() =>
            clienteSelecionado ? setFocus('regraFiscal') : setFocus('cfop')
          }
          size="lg"
          minWidth="90%"
          style={{ zIndex: 1040 }}
        >
          <Form>
            <Modal.Body>
              <Form.Row>
                <Col md={12} xl={4}>
                  <SelectRegraFiscal
                    type="text"
                    onSelectChange={() => {
                      setAlterarCampoCalcularTributos(true);
                    }}
                    id="regraFiscal"
                    name="regraFiscal"
                    label={t('Regra fiscal')}
                    required={false}
                    placeholder={t('Digite a regra fiscal')}
                    control={control}
                    error={errors.regraFiscal}
                    isDisabled={!clienteSelecionado}
                    indicadorIe={clienteSelecionado?.indicadorIe}
                    clienteEstadoId={clienteSelecionado?.estadoId}
                    clientePaisId={clienteSelecionado?.paisId}
                    lojaRegimeTributario={
                      lojaInformacoesFiscais?.regimeTributario
                    }
                    tipoOperacao={tipoDeOperacaoSelecionada}
                    lojaEstadoId={lojaInformacoesFiscais?.estadoId}
                    lojaPaisId={lojaInformacoesFiscais?.paisId}
                    getSelectedRegraFiscal={getSelectedRegraFiscal}
                  />
                </Col>
                <Col md={2} xl={1}>
                  <InputNumerico
                    type="text"
                    id="cfop"
                    name="cfop"
                    label={t('CFOP')}
                    required
                    maxLength={4}
                    error={errors.cfop}
                    control={control}
                    convertValueToString
                  />
                </Col>

                <Col md={4} xl={3}>
                  <SelectPadrao
                    id="cstCsosn"
                    name="cstCsosn"
                    label={t('CST / CSOSN')}
                    noSelectedText=""
                    defaultValue={null}
                    placeholder="Selecione"
                    control={control}
                    error={errors.cstCsosn}
                    options={cstCsosnItens}
                    required
                  />
                </Col>
                <Col md={2} xl={2}>
                  <SelectPadrao
                    id="indicadorValorTotalNota"
                    name="indicadorValorTotalNota"
                    label={t('Somar ao total da nota')}
                    placeholder="Selecione"
                    defaultValue={1}
                    control={control}
                    error={errors.indicadorValorTotalNota}
                    options={[
                      { label: 'Sim', value: 1 },
                      { label: 'Não', value: 0 },
                    ]}
                    disabled={false}
                    required
                  />
                </Col>
                <Col md={4} xl={2}>
                  <SelectPadrao
                    id="calcularTributosMunEstFed"
                    name="calcularTributosMunEstFed"
                    label={t('Calcular tributos mun., est. e fed.')}
                    placeholder="Selecione"
                    defaultValue
                    isClearable={false}
                    exibirDefaultValue
                    control={control}
                    error={errors.calcularTributosMunEstFed}
                    options={[
                      { label: 'Sim', value: true },
                      { label: 'Não', value: false },
                    ]}
                    disabled={false}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Col md={12} xl={6}>
                  <SelectProduto
                    type="text"
                    id="produto"
                    name="produto"
                    label={t('Produto')}
                    required
                    placeholder={t('Digite o produto')}
                    control={control}
                    error={errors.produto}
                    isLoading={isLoading}
                    isDisabled={false}
                    getSelectedProduto={getObterInformacoesNotaFiscal}
                    infoText="A pesquisa de produto considera os campos Status, Descrição, Código SKU, GTIN/EAN, Código externo e Código de barras interno."
                  />
                </Col>
                <Col md={2} xl={2}>
                  <BootstrapNumberInput
                    id="quantidade"
                    name="quantidade"
                    label={t('Quantidade')}
                    precision={12}
                    scale={casasDecimais.casasDecimaisQuantidade}
                    defaultValue={1}
                    error={errors.quantidade}
                    control={control}
                    onBlur={() => calcularValoresItem(cstCsosnWatch)}
                    required
                  />
                </Col>
                <Col md={2} xl={2}>
                  <BootstrapNumberInput
                    id="valorUnitario"
                    name="valorUnitario"
                    label={t('Valor')}
                    precision={12}
                    scale={casasDecimais.casasDecimaisValor}
                    defaultValue={0}
                    leftElement="R$"
                    control={control}
                    error={errors.valorUnitario}
                    disabled={false}
                    onBlur={() => calcularValoresItem(cstCsosnWatch)}
                    required
                  />
                </Col>
                <Col md={2} xl={2}>
                  <BootstrapNumberInput
                    id="valorTotal"
                    name="valorTotal"
                    label={t('Total')}
                    precision={12}
                    scale={2}
                    defaultValue={0}
                    leftElement="R$"
                    control={control}
                    error={errors.valorTotal}
                    disabled
                  />
                </Col>
              </Form.Row>
              {produtoWatch && (
                <Form.Row>
                  <Col>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        background: 'var(--sti-ck-colors-gray-100)',
                        height: '35px',
                        alignItems: 'center',
                      }}
                    >
                      {produtoWatch.obj.informacoesAdicionais}
                    </div>
                  </Col>
                </Form.Row>
              )}
              <Form.Row>
                <Col>
                  <TabsPadrao
                    id="adicionar-produto-tabs"
                    isLoading={false}
                    tabs={[
                      {
                        eventKey: 'inicial',
                        title: 'Informações gerais',
                        refNavLink: tabInformacoesAdicionaisRef,
                        content: (
                          <ContainerTab>
                            <InformacoesGerais
                              control={control}
                              errors={errors}
                              register={register}
                            />
                          </ContainerTab>
                        ),
                      },
                      {
                        hidden:
                          cstCsosnWatch === null ||
                          cstCsosnWatch === 102 ||
                          cstCsosnWatch === 103 ||
                          cstCsosnWatch === 202 ||
                          cstCsosnWatch === 203 ||
                          cstCsosnWatch === 300 ||
                          cstCsosnWatch === 400,
                        eventKey: 'icms',
                        title: 'ICMS',
                        content: (
                          <ContainerTab>
                            <ICMS
                              control={control}
                              errors={errors}
                              watch={watch}
                              setValue={setValue}
                              alteracao={alteracao}
                            />
                          </ContainerTab>
                        ),
                      },
                      {
                        hidden:
                          cstCsosnWatch === null ||
                          cstCsosnWatch === 0 ||
                          cstCsosnWatch === 20 ||
                          cstCsosnWatch === 40 ||
                          cstCsosnWatch === 41 ||
                          cstCsosnWatch === 50 ||
                          cstCsosnWatch === 51 ||
                          cstCsosnWatch === 101 ||
                          cstCsosnWatch === 102 ||
                          cstCsosnWatch === 103 ||
                          cstCsosnWatch === 300 ||
                          cstCsosnWatch === 400,
                        eventKey: 'icms-st',
                        title: 'ICMS ST',
                        refNavLink: tabIcmsSTRef,
                        content: (
                          <ContainerTab>
                            <ICMSST
                              control={control}
                              errors={errors}
                              watch={watch}
                              setValue={setValue}
                            />
                          </ContainerTab>
                        ),
                      },
                      {
                        eventKey: 'pis-cofins-ipi',
                        title: 'Pis/Cofins/Ipi',
                        refNavLink: tabPisCofinsIpiRef,
                        content: (
                          <ContainerTab>
                            <OutrosTributos
                              control={control}
                              errors={errors}
                              watch={watch}
                            />
                          </ContainerTab>
                        ),
                      },
                      {
                        eventKey: 'dados-importacao',
                        title: 'Dados para importação',
                        content: (
                          <ContainerTab>
                            <DadosImportacao
                              control={control}
                              errors={errors}
                              register={register}
                              getValues={getValues}
                              setValue={setValue}
                            />
                          </ContainerTab>
                        ),
                        tabWidth: 180,
                      },
                      {
                        eventKey: 'outros-valores',
                        title: 'Outros valores',
                        content: (
                          <ContainerTab>
                            <OutrosValores control={control} errors={errors} />
                          </ContainerTab>
                        ),
                        tabWidth: 180,
                      },
                    ]}
                  />
                </Col>
              </Form.Row>
            </Modal.Body>

            <Modal.Footer
              style={{
                flexDirection: isSmallerThan420 ? 'column' : 'row',
                gap: isSmallerThan420 ? '1rem' : '0',
              }}
            >
              {!alteracao && (
                <ButtonPadrao
                  id="adicionarInserirNovo"
                  type="button"
                  variant="outline-secondary"
                  name="salvarInserirNovo"
                  text={t('Adicionar e inserir novo')}
                  icon={SalvarInserirNovoIcon}
                  disabled={false}
                  onClick={onSubmitReset}
                  style={{
                    height: '35px',
                    width: '100%',
                    maxWidth: isSmallerThan420 ? '100%' : '225px',
                    flex: isSmallerThan420 ? '1' : '0',
                  }}
                />
              )}
              <ButtonPadrao
                id="adicionarDocumentoReferenciado"
                name="adicionarDocumentoReferenciado"
                text={t(`${alteracao ? 'Alterar' : 'Adicionar'}`)}
                icon={SalvarConfirmarIcon}
                type="button"
                variant="success"
                onClick={onSubmit}
                style={{
                  height: '35px',
                  width: '100%',
                  maxWidth: isSmallerThan420 ? '100%' : '120px',
                  flex: isSmallerThan420 ? '1' : '0',
                }}
              />
            </Modal.Footer>
          </Form>
        </ModalPadrao>
      )}
    </>
  );
};

export default ProdutosModal;
