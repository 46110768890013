const PlanoContratacaoEnum = {
  TESTE: 0,
  START: 1,
  PRO: 2,
  PRIME: 3,
  CORTESIA: 4,
  DEMONSTRACAO: 5,

  properties: {
    1: { name: 'Teste', value: 0 },
    2: { name: 'Start', value: 1 },
    3: { name: 'Pro', value: 2 },
    4: { name: 'Prime', value: 3 },
    5: { name: 'Cortesia', value: 4 },
    6: { name: 'Demonstração', value: 5 },
  },

  values: [
    { nome: 'Teste', value: 0 },
    { nome: 'Start', value: 1 },
    { nome: 'Pro', value: 2 },
    { nome: 'Prime', value: 3 },
    { nome: 'Cortesia', value: 4 },
    { nome: 'Demonstração', value: 5 },
  ],
};

export default PlanoContratacaoEnum;
