export const useDashboardData = () => {
  const listItemsInfo = [
    'Para acessar o painel de configurações da integração, clique em: Integrações > Dashboard gerencial',
    'Para reset da configuração ou para excluir esta integração, entre em contato a nossa equipe técnica.',
    'Para outras dúvidas ou problemas, acesse nossa central de ajuda ou entre em contato com nossa equipe técnica.',
  ];

  return {
    listItemsInfo,
  };
};
