import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import { AspectRatio, Flex, Icon, Image, Text, Box } from '@chakra-ui/react';

import TipoContaEnum from 'constants/enum/tipoContaFinanceira';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';

import CheckboxAtivoInativo from 'components/update/Checkbox/CheckboxAtivoInativo';
import { ProdutoSemImagemIcon } from 'icons';
import Input from 'components/PDV/Input';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import { ModalEditarIcones } from 'components/Modal/ModalEditarIcones';
import { NumberInput } from 'components/update/Input/NumberInput';
import { DateInput } from 'components/update/Input/DateInput';
import { Switch } from 'components/update/Switch';

interface UncontrolledFormInterface {
  readonly?: boolean;
  readonlyTipo?: boolean;
  bancoList: Array<any>;
  descricaoIcone?: string;
  setDescricaoIcone?: React.Dispatch<React.SetStateAction<string>>;
}

interface IconesStorage {
  nome: string;
  url: string;
}

export const UncontrolledForm = ({
  readonly,
  bancoList,
  setDescricaoIcone,
  readonlyTipo,
  descricaoIcone,
}: UncontrolledFormInterface) => {
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const [iconesStorage, setIconesStorage] = useState<Array<IconesStorage>>([]);
  const [
    icone,
    tipoContaFinanceiraWatch,
    codigoBanco,
    saldo,
    dataLancamentoSaldo,
    exibirSaldoInicialWatch,
  ] = watch([
    'icone',
    'tipoContaFinanceira',
    'codigoBanco',
    'saldo',
    'dataLancamentoSaldo',
    'exibirSaldoInicial',
  ]);

  const [iconeSelecionado, setIconeSelecionado] = useState<string>(
    icone as string
  );
  const [escolherImagem, setEscolherImagem] = useState(false);

  const latestProps = useRef({ setValue, icone, bancoList, iconesStorage });
  useEffect(() => {
    latestProps.current = { setValue, icone, bancoList, iconesStorage };
  });

  const exibirSaldo =
    tipoContaFinanceiraWatch === TipoContaEnum.CONTA_COFRE &&
    exibirSaldoInicialWatch;

  const tiposContasFinanceiras = Object.entries(TipoContaEnum.properties)?.map(
    (conta) => {
      return {
        label: conta[1]?.name,
        value: conta[1]?.value,
      };
    }
  );
  const contaFinanceiraDoTipoCaixaMovel =
    tipoContaFinanceiraWatch === TipoContaEnum.CAIXA_MOVEL;

  const buscarIcones = useCallback(async () => {
    const response = await api.get<void, ResponseApi<IconesStorage[]>>(
      ConstanteEnderecoWebservice.CONTA_FINANCEIRA_LISTAR_ICONES,
      {
        params: {
          tipoConta: tipoContaFinanceiraWatch,
        },
      }
    );

    if (response?.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (response?.sucesso) {
      const { dados } = response;
      const iconeExistente = dados.find(
        (iconeStorage) => iconeStorage.nome === latestProps.current.icone
      );

      if (!iconeExistente) {
        latestProps.current.setValue('icone', undefined);
      }

      if (descricaoIcone === undefined && dados[0] && dados[0].url) {
        setIconeSelecionado(dados[0].url);
      }

      setIconesStorage(dados);
    }
  }, [tipoContaFinanceiraWatch, descricaoIcone]);

  const filtrandoValorUrlReadonlyTipo = iconesStorage.filter(
    (valorFiltrado) => valorFiltrado.url === descricaoIcone
  );
  const obterValorNomeIconeReadonlyTipo = filtrandoValorUrlReadonlyTipo.map(
    (obterNomeIcone) => obterNomeIcone.nome
  );
  const indiceIconeSelecionadoReadonlyTipo = obterValorNomeIconeReadonlyTipo[0];

  const filtrandoValorUrl = iconesStorage.filter(
    (valorFiltrado) => valorFiltrado.url === iconeSelecionado
  );
  const obterValorNomeIcone = filtrandoValorUrl.map(
    (obterNomeIcone) => obterNomeIcone.nome
  );
  const indiceIconeSelecionado = obterValorNomeIcone[0];

  const aparecerEscolhaImg = {
    display: '',
  };

  if (escolherImagem) {
    aparecerEscolhaImg.display = 'block';
  } else {
    aparecerEscolhaImg.display = 'none';
  }

  useEffect(() => {
    buscarIcones();
  }, [buscarIcones]);

  useEffect(() => {
    const iconeBanco = latestProps.current.bancoList.find(
      (banco) => codigoBanco === banco.codigo
    );

    if (iconeBanco) {
      const iconeStorageBanco = latestProps.current.iconesStorage.find(
        (iconeStorage) => iconeStorage.nome === iconeBanco.icone
      );

      if (iconeStorageBanco) {
        latestProps.current.setValue('icone', iconeStorageBanco.nome);
      }
    }
  }, [codigoBanco]);

  async function handleAbrirModalEscolherIcone() {
    if (readonlyTipo === undefined) {
      await ModalEditarIcones({
        urlPreview: iconeSelecionado || '',
        urlPreviewList: iconesStorage,
        setUrlPreview: setIconeSelecionado,
      });
    } else {
      await ModalEditarIcones({
        urlPreview: descricaoIcone || '',
        urlPreviewList: iconesStorage,
        setUrlPreview: setDescricaoIcone,
      });
    }
  }

  useEffect(() => {
    if (readonlyTipo === undefined) {
      latestProps.current.setValue('icone', indiceIconeSelecionado);
    } else {
      latestProps.current.setValue('icone', indiceIconeSelecionadoReadonlyTipo);
    }
    if (!saldo) {
      latestProps.current.setValue('saldoInicial', 0);
    }
  }, [
    readonlyTipo,
    saldo,
    dataLancamentoSaldo,
    indiceIconeSelecionado,
    indiceIconeSelecionadoReadonlyTipo,
  ]);

  useEffect(() => {
    if (tipoContaFinanceiraWatch === TipoContaEnum.CAIXA) {
      latestProps.current.setValue('exibirSaldoInicial', false);
    }
  }, [tipoContaFinanceiraWatch]);

  return (
    <>
      <Flex
        direction={{ base: 'column', sm: 'row', md: 'row', xl: 'row' }}
        mt="18px"
        w="full"
        pl="15px"
        pr="15px"
      >
        <Box
          mb={{ base: '20px', sm: '0', md: '0', xl: '0' }}
          mr="20px"
          h="40%"
          w="14%"
        >
          <Box w="50px">
            <Text fontWeight="bold" fontSize="14px">
              Ícone
            </Text>
          </Box>

          <Flex
            justifyContent="center"
            alignItems="center"
            minH="100%"
            w="90%"
            minW="84px"
            maxW={{ base: '190px', md: '215px', xl: '315px' }}
            overflow="hidden"
          >
            <AspectRatio w="100%" ratio={1} borderColor="gray.200">
              {readonlyTipo === undefined ? (
                <>
                  {iconeSelecionado ? (
                    <Flex
                      h="full"
                      w="full"
                      alignItems="center"
                      justifyContent="center"
                      p={6}
                      cursor="pointer"
                      bg={escolherImagem ? 'gray.600' : 'white'}
                      borderRadius="100px"
                      onMouseOver={() => setEscolherImagem(true)}
                      onMouseOut={() => setEscolherImagem(false)}
                    >
                      <Image
                        position="absolute"
                        h="full"
                        w="full"
                        objectFit="cover"
                        opacity={escolherImagem ? '0.2' : '1'}
                        src={iconeSelecionado}
                        cursor="pointer"
                      />

                      <Box
                        position="absolute"
                        style={aparecerEscolhaImg}
                        borderRadius="100px"
                      >
                        <Text
                          onClick={handleAbrirModalEscolherIcone}
                          mt="5px"
                          mb="5px"
                          fontSize="12px"
                          fontWeight="bold"
                          color="white"
                        >
                          Trocar
                        </Text>
                        <Text
                          onClick={() =>
                            setIconeSelecionado ? setIconeSelecionado('') : {}
                          }
                          fontWeight="bold"
                          fontSize="12px"
                          color="white"
                        >
                          Excluir
                        </Text>
                      </Box>
                    </Flex>
                  ) : (
                    <Flex
                      h="full"
                      w="full"
                      alignItems="center"
                      justifyContent="center"
                      p={6}
                      borderStyle="dashed"
                      borderRadius="100px"
                      borderWidth="2px"
                      borderColor="#CCCCCC"
                    >
                      <Box>
                        <Icon
                          as={ProdutoSemImagemIcon}
                          color="gray.700"
                          cursor="pointer"
                          fontSize="2.125rem"
                          ml="10px"
                          onClick={handleAbrirModalEscolherIcone}
                        />
                        <Text w="full" fontWeight="bold" fontSize="12px">
                          Adicionar
                        </Text>
                      </Box>
                    </Flex>
                  )}
                </>
              ) : (
                <>
                  {tipoContaFinanceiraWatch === TipoContaEnum.CONTA_COFRE ? (
                    <>
                      {descricaoIcone ? (
                        <Flex
                          h="full"
                          w="full"
                          alignItems="center"
                          justifyContent="center"
                          p={6}
                          cursor="pointer"
                          bg={escolherImagem ? 'gray.600' : 'white'}
                          borderRadius="100px"
                        >
                          <Image
                            position="absolute"
                            h="full"
                            w="full"
                            objectFit="cover"
                            opacity={escolherImagem ? '0.2' : '1'}
                            src={descricaoIcone}
                            cursor="pointer"
                          />
                        </Flex>
                      ) : (
                        <Flex
                          h="full"
                          alignItems="center"
                          justifyContent="center"
                          borderStyle="dashed"
                          borderRadius="100px"
                          borderWidth="2px"
                          borderColor="#CCCCCC"
                        >
                          <Icon
                            as={ProdutoSemImagemIcon}
                            color="gray.700"
                            fontSize="2.125rem"
                          />
                        </Flex>
                      )}
                    </>
                  ) : (
                    <>
                      {descricaoIcone ? (
                        <Flex
                          h="full"
                          w="full"
                          alignItems="center"
                          justifyContent="center"
                          p={6}
                          cursor="pointer"
                          bg={escolherImagem ? 'gray.600' : 'white'}
                          borderRadius="100px"
                          onMouseOver={() => setEscolherImagem(true)}
                          onMouseOut={() => setEscolherImagem(false)}
                        >
                          <Image
                            position="absolute"
                            h="full"
                            w="full"
                            objectFit="cover"
                            opacity={escolherImagem ? '0.2' : '1'}
                            src={descricaoIcone}
                            cursor="pointer"
                          />

                          <Box
                            position="absolute"
                            style={aparecerEscolhaImg}
                            borderRadius="100px"
                          >
                            <Text
                              onClick={handleAbrirModalEscolherIcone}
                              mt="5px"
                              mb="5px"
                              fontSize="15px"
                              fontWeight="bold"
                              color="white"
                            >
                              Trocar
                            </Text>
                            <Text
                              onClick={() =>
                                setDescricaoIcone ? setDescricaoIcone('') : {}
                              }
                              fontSize="15px"
                              fontWeight="bold"
                              color="white"
                            >
                              Excluir
                            </Text>
                          </Box>
                        </Flex>
                      ) : (
                        <Flex
                          h="full"
                          alignItems="center"
                          justifyContent="center"
                          p={6}
                          borderStyle="dashed"
                          borderRadius="100px"
                          borderWidth="2px"
                          borderColor="#CCCCCC"
                        >
                          <Box>
                            <Icon
                              as={ProdutoSemImagemIcon}
                              color="gray.700"
                              cursor="pointer"
                              fontSize="2.125rem"
                              ml="10px"
                              onClick={handleAbrirModalEscolherIcone}
                            />
                            <Text w="full" fontWeight="bold" fontSize="12px">
                              Adicionar
                            </Text>
                          </Box>
                        </Flex>
                      )}
                    </>
                  )}
                </>
              )}
            </AspectRatio>
          </Flex>
        </Box>
        <Box w="full">
          <Flex
            direction={{ base: 'column', sm: 'row', md: 'row', xl: 'row' }}
            w="full"
            display={
              tipoContaFinanceiraWatch === TipoContaEnum.CONTA_COFRE
                ? 'none'
                : 'flex'
            }
          >
            <Box
              w={{ base: 'full', sm: '30%', md: '30%', xl: '30%' }}
              mr="35px"
              mb={{ base: '10px', sm: '0', md: '0', xl: '0' }}
            >
              <SelectPadrao
                id="tipoContaFinanceira"
                name="tipoContaFinanceira"
                label="Tipo da conta"
                placeholder="Selecione"
                required
                isDisabled={
                  contaFinanceiraDoTipoCaixaMovel ||
                  (readonly === undefined ? readonlyTipo : false)
                }
                errorText={errors?.genero?.message}
                options={
                  contaFinanceiraDoTipoCaixaMovel
                    ? tiposContasFinanceiras
                    : tiposContasFinanceiras?.filter(
                        (tipo) => tipo.value !== TipoContaEnum.CAIXA_MOVEL
                      )
                }
              />
            </Box>
            <Box mr="35px">
              <CheckboxAtivoInativo
                id="ativo"
                name="ativo"
                label="Status"
                isDisabled={readonly || contaFinanceiraDoTipoCaixaMovel}
              />
            </Box>
            {tipoContaFinanceiraWatch === TipoContaEnum.BANCO && (
              <Box>
                <Switch
                  size="md"
                  isRequired={false}
                  label="Alterar saldo inicial?"
                  name="exibirSaldoInicial"
                  id="exibirSaldoInicial"
                />
              </Box>
            )}
          </Flex>
          <Box
            mt={
              tipoContaFinanceiraWatch === TipoContaEnum.CONTA_COFRE
                ? 0
                : { base: '10px', sm: '30px', md: '30px', xl: '28px' }
            }
            w="full"
          >
            <Flex direction={['column', 'column', 'row']} w="full">
              <Box w="full" mr={['0', '0', '35px']}>
                <Input
                  name="nome"
                  id="nome"
                  label="Nome"
                  autoFocus
                  isRequired
                  placeholder="Digite um nome para conta financeira"
                  maxLength={30}
                  isDisabled={
                    readonly ||
                    tipoContaFinanceiraWatch === TipoContaEnum.CONTA_COFRE
                  }
                />
              </Box>
              {tipoContaFinanceiraWatch === TipoContaEnum.CONTA_COFRE && (
                <Box mt={['10px', '10px', '0']} w={['full', 'full', '190px']}>
                  <Switch
                    size="md"
                    isRequired={false}
                    label="Alterar saldo inicial?"
                    name="exibirSaldoInicial"
                    id="exibirSaldoInicial"
                  />
                </Box>
              )}
            </Flex>
            {exibirSaldo && (
              <Box mt="28px" w="full">
                <Flex mb="28px" w="full">
                  {readonlyTipo && (
                    <Box mr="35px" w="full">
                      <DateInput
                        name="dataLancamentoSaldo"
                        id="dataLancamentoSaldo"
                        isDisabled={readonly}
                        placeholder="Digite a data do saldo inicial"
                        helperText="Informe a data para definir o saldo inicial. Um lançamento de ajuste de saldo será realizado no dia anterior ao escolhido, para que o saldo inicial seja ajustado."
                        label="Data saldo inicial"
                      />
                    </Box>
                  )}

                  <Box w="full" mr={readonlyTipo ? '35px' : 0}>
                    <NumberInput
                      id="saldoInicial"
                      name="saldoInicial"
                      scale={2}
                      precision={10}
                      defaultValue={0}
                      disabled={
                        readonly || readonlyTipo ? !dataLancamentoSaldo : false
                      }
                      label="Saldo inicial"
                      errorText={errors?.saldoInicial?.message}
                    />
                  </Box>
                </Flex>
              </Box>
            )}
          </Box>
        </Box>
      </Flex>
      <Flex
        direction={{ base: 'column', sm: 'row', md: 'row', xl: 'row' }}
        w="full"
        mt="28px"
        mb="15px"
        pl="15px"
        pr="15px"
      >
        {tipoContaFinanceiraWatch === TipoContaEnum.BANCO && (
          <Box w="full">
            <Flex mb="28px" w="full">
              <Box w="full" mr="35px">
                <SelectPadrao
                  id="codigoBanco"
                  name="codigoBanco"
                  label="Banco"
                  placeholder="Selecione o banco"
                  isDisabled={readonly}
                  errorText={errors?.codigoBanco?.message}
                  options={bancoList.map((banco) => ({
                    value: banco.codigo,
                    label: banco.nome,
                  }))}
                  required
                />
              </Box>
              <Box w="full">
                <Input
                  name="agencia"
                  id="agencia"
                  label="Agência bancária"
                  placeholder="Digite o nome da agência bancária"
                  maxLength={10}
                  errorText={errors?.agencia?.message}
                  isDisabled={readonly}
                  isRequired
                />
              </Box>
            </Flex>
            <Flex w="full">
              <Box mr={exibirSaldoInicialWatch ? '35px' : undefined} w="full">
                <NumberInput
                  id="numeroConta"
                  name="numeroConta"
                  placeholder="Digite o número da conta bancária"
                  disabled={
                    readonly || readonlyTipo ? !dataLancamentoSaldo : false
                  }
                  label="Número de conta"
                  maxLength={40}
                  scale={0}
                  errorText={errors?.numeroConta?.message}
                  isRequired
                />
              </Box>

              {readonlyTipo && exibirSaldoInicialWatch && (
                <Box mr="35px" w="full">
                  <DateInput
                    name="dataLancamentoSaldo"
                    id="dataLancamentoSaldo"
                    isDisabled={readonly}
                    placeholder="Digite a data do saldo inicial"
                    helperText="Informe a data para definir o saldo inicial. Um lançamento de ajuste de saldo será realizado no dia anterior ao escolhido, para que o saldo inicial seja ajustado."
                    label="Data saldo inicial"
                  />
                </Box>
              )}
              {exibirSaldoInicialWatch && (
                <Box w="full">
                  <NumberInput
                    id="saldoInicial"
                    name="saldoInicial"
                    scale={2}
                    precision={10}
                    defaultValue={0}
                    isDisabled={
                      readonly || readonlyTipo ? !dataLancamentoSaldo : false
                    }
                    label="Saldo inicial"
                  />
                </Box>
              )}
            </Flex>
          </Box>
        )}
      </Flex>
    </>
  );
};
