import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import auth from 'modules/auth';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import { EnumNumeracaoContas } from 'constants/enum/enumNumeracaoContas';
import { EnumExibicaoContasDepartamento } from 'constants/enum/enumExibicaoContasDepartamento';

import OptionType from 'types/optionType';

const { id: lojaId } = auth.getLoja();

export type ContaProps = {
  descricao: string;
  numeracaoInicial: number;
  numeracaoFinal: number;
};

export type ProdutoProps = {
  ativo: boolean;
  id: string;
  nome: string;
  nomeEcommerce: null | string;
};

type LojaProps = ProdutoProps;

type InformacoesBasicasProps = {
  ativo: boolean;
  dataHoraCadastro: string;
  dataHoraUltimaAlteracao: string;
};

export type FormData = {
  ativo: boolean;
  descricao: string;
  descricaoContaPrincipal: string;
  descricaoSubConta: string;
  numeracaoFinalContaPrincipal: number;
  numeracaoFinalSubConta: number;
  numeracaoInicialContaPrincipal: number;
  numeracaoInicialSubConta: number;
  produtosFavoritos: string[];
  contasBloqueadas: OptionType<number>[];
  exibicaoContas: number;
  numeracaoContas: number;
  utilizarSubConta: boolean;
  valorCouver: number;
  productItem: OptionType<string> | null;
  listProducts: Pick<ProdutoProps, 'id' | 'nome'>[];
  lojaId: string;
};

export type DepartamentoResponse = {
  id: string;
  contaPrincipal: ContaProps;
  contasBloqueadas: { id: string; numeroConta: number }[];
  descricao: string;
  exibicaoContas: number;
  informacoesBasicas: InformacoesBasicasProps;
  loja: LojaProps;
  numeracaoContas: number;
  produtosFavoritos: {
    id: string;
    produto: ProdutoProps;
  }[];
  subConta: ContaProps | null;
  valorCouver: number;
};

export const defaultValues = {
  ativo: true,
  descricao: '',
  descricaoContaPrincipal: '',
  descricaoSubConta: '',
  numeracaoFinalContaPrincipal: 0,
  numeracaoFinalSubConta: 0,
  numeracaoInicialContaPrincipal: 0,
  numeracaoInicialSubConta: 0,
  produtosFavoritos: [],
  contasBloqueadas: [],
  numeracaoContas: EnumNumeracaoContas.AUTOMATICA,
  exibicaoContas: EnumExibicaoContasDepartamento.SOMENTE_ABERTAS,
  utilizarSubConta: false,
  valorCouver: 0,
  lojaId,
  listProducts: [],
  productItem: null,
};

const schema = yup.object({
  descricao: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  valor: yup.number(),
  ativo: yup.boolean(),
  descricaoContaPrincipal: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  descricaoSubConta: yup.string().when('utilizarSubConta', {
    is: true,
    then: (obj: any) =>
      obj.required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
    otherwise: (obj: any) => obj.transform(() => undefined),
  }),
  numeracaoInicialContaPrincipal: yup
    .number()
    .max(9999, ConstanteMensagemValidacao.VALOR_MAXIMO_9999),
  numeracaoFinalContaPrincipal: yup
    .number()
    .max(9999, ConstanteMensagemValidacao.VALOR_MAXIMO_9999),
  numeracaoInicialSubConta: yup
    .number()
    .max(9999, ConstanteMensagemValidacao.VALOR_MAXIMO_9999)
    .when('utilizarSubConta', {
      is: false,
      then: (obj: any) => obj.transform(() => undefined),
    }),
  numeracaoFinalSubConta: yup
    .number()
    .max(9999, ConstanteMensagemValidacao.VALOR_MAXIMO_9999)
    .when('utilizarSubConta', {
      is: false,
      then: yup.number().transform(() => undefined),
    }),
  contasBloqueadas: yup.array().of(
    yup.object().shape({
      label: yup.string(),
      value: yup.number().positive().integer(),
    })
  ),
});

export const yupResolver = yupResolverInstance(schema);
