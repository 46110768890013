import React, { useEffect, useState } from 'react';
import { Alert, AlertIcon, Button, Stack } from '@chakra-ui/react';
import auth from 'modules/auth';

const ServiceWorkerWrapper: React.FC = () => {
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    window.addEventListener('pwaUpdated', () => {
      setShowMessage(true);
    });
  }, []);

  return showMessage ? (
    <Stack spacing={3} w="100%">
      <Alert status="warning">
        <AlertIcon />
        Seu aplicativo foi atualizado e precisa ser reiniciado.
        <Button
          variant="solid"
          colorScheme="orange"
          size="sm"
          marginLeft="5"
          onClick={() => auth.clearTokenAndRedirect()}
        >
          Reiniciar
        </Button>
      </Alert>
    </Stack>
  ) : (
    <></>
  );
};

export default ServiceWorkerWrapper;
