import { GridItem, Text, Box, Flex } from '@chakra-ui/react';
import { InputNumber } from 'components/update/Input/InputNumber';

export type TamanhoProps = {
  id: string;
  nome: string;
};

type VariationsProps = {
  listSizes: TamanhoProps[];
  decimalPlaces: number;
  isDefaultSize: boolean;
};

export const Variations = ({
  listSizes,
  decimalPlaces,
  isDefaultSize,
}: VariationsProps) => {
  const isSizeUnique = listSizes.length === 1;

  return (
    <GridItem w="full" colSpan={12}>
      <Text color="gray.700" fontSize="14px">
        {`Informe a quantidade ${isSizeUnique ? `do tamanho` : 'dos tamanhos'}`}
      </Text>
      <Box
        borderWidth="1px"
        borderRadius="5px"
        borderColor="gray.200"
        borderStyle="solid"
        p="16px"
        maxH="300px"
        overflowY="scroll"
      >
        {isDefaultSize ? (
          <Box>
            <InputNumber
              id="quantidade.0"
              name="quantidade.0"
              scale={decimalPlaces}
            />
          </Box>
        ) : (
          <Box minW="max-content" w="full" overflowX="auto">
            <Flex px="20px" justifyContent="space-between">
              <Text>Tamanho</Text>
              <Text>Quantidade</Text>
            </Flex>
            {listSizes.map(({ nome }, index) => {
              return (
                <Flex
                  bg="white"
                  mb="5px"
                  borderWidth="1px"
                  borderColor="gray.200"
                  borderStyle="solid"
                  alignItems="center"
                  justifyContent="space-between"
                  p="8px"
                  borderRadius="5px"
                >
                  <Box
                    pl="16px"
                    as="div"
                    h="28px"
                    pt="4px"
                    pr="16px"
                    alignItems="baseline"
                    display="flex"
                    justifyContent="flex-start"
                  >
                    <Flex>
                      <Box>
                        <Text
                          whiteSpace="nowrap"
                          color="gray.700"
                          fontSize="14px"
                        >
                          {nome}
                        </Text>
                      </Box>
                    </Flex>
                  </Box>
                  <Box w={{ base: '40%', sm: '30%', md: '20%' }}>
                    <InputNumber
                      id={`quantidade.${index}`}
                      name={`quantidade.${index}`}
                      scale={decimalPlaces}
                    />
                  </Box>
                </Flex>
              );
            })}
          </Box>
        )}
      </Box>
    </GridItem>
  );
};
