import React, { useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import {
  ConferenciaEstoqueContainerProvider,
  ConferenciaEstoqueContainerContext,
} from 'store/ConferenciaEstoque/ConferenciaEstoqueContainer';
import auth from 'modules/auth';
import PlanoContratacaoEnum from 'constants/enum/planoContratacao';
import ConstanteRotas from 'constants/rotas';

import { IniciarConferencia } from './IniciarConferencia';
import { EtapasConferencia } from './Etapas';

type TParams = { id?: string };

type SParams = {
  isWritting?: boolean;
};

export default function ConferenciaEstoque({
  match,
  location: { state },
}: RouteComponentProps<TParams, any, SParams>) {
  const history = useHistory();
  const conferenciaEstoqueId = match.params.id;

  const planoAtual = auth.getPlano();
  const planoStart = PlanoContratacaoEnum.START;
  const planoPro = PlanoContratacaoEnum.PRO;

  useEffect(() => {
    if (planoAtual === planoStart || planoAtual === planoPro) {
      history.push(ConstanteRotas.DASHBOARD);
    }
  }, [history, planoAtual, planoStart, planoPro]);

  useEffect(() => {
    history.replace({ ...history.location, state: {} });
  }, [history]);

  return (
    <ConferenciaEstoqueContainerProvider
      conferenciaEstoqueId={conferenciaEstoqueId}
      defaultIsWritting={state?.isWritting}
    >
      <ConferenciaEstoqueContainerContext.Consumer>
        {({ isWritting }) =>
          isWritting ? <EtapasConferencia /> : <IniciarConferencia />
        }
      </ConferenciaEstoqueContainerContext.Consumer>
    </ConferenciaEstoqueContainerProvider>
  );
}
