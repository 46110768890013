import React from 'react';
import LayoutGuard from './LayoutGuard';
import ConstanteRotas from '../constants/rotas';
import ConstanteFuncionalidades from '../constants/permissoes';
import { RelatorioConsignacao } from '../pages/Relatorio/Consignacao';

export const RelatorioOperacoesRoutes = [
  <LayoutGuard
    key="relatorio-consignacao-listar"
    component={RelatorioConsignacao}
    permissaoFuncionalidade={ConstanteFuncionalidades.RELATORIO_CONSIGNACAO}
    breadcrumb={[
      {
        title: 'Relatórios',
      },
      {
        title: 'Consignação',
      },
    ]}
    path={ConstanteRotas.RELATORIO_CONSIGNACAO}
    exact
    meta={{ auth: true }}
  />,
];
