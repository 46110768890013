import React, { createContext, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteRotas from 'constants/rotas';

import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';

interface ConferenciaEstoqueContainerContextProps {
  conferenciaEstoqueId?: string;
  isWritting: boolean;
  descartarConferencia: () => void;
  iniciarConferencia: () => void;
}

export const ConferenciaEstoqueContainerContext = createContext<ConferenciaEstoqueContainerContextProps>(
  {} as ConferenciaEstoqueContainerContextProps
);

interface ConferenciaEstoqueContainerProviderProps {
  children: React.ReactNode;
  conferenciaEstoqueId?: string;
  defaultIsWritting?: boolean;
}

export function ConferenciaEstoqueContainerProvider({
  children,
  conferenciaEstoqueId,
  defaultIsWritting,
}: ConferenciaEstoqueContainerProviderProps): JSX.Element {
  const history = useHistory();

  const [isWritting, setIsWritting] = useState(
    !!conferenciaEstoqueId || !!defaultIsWritting
  );

  async function descartarConferencia() {
    if (conferenciaEstoqueId) {
      ModalConfirmacaoExcluir({
        title: 'Descartar conferência',
        text:
          'Todos os filtros selecionados e produtos lançados serão perdidos. Você tem certeza que deseja descartar esta conferência?',
        confirmButtonText: 'Sim, descartar!',
        callback: async (ok: boolean) => {
          if (ok) {
            const response = await api.delete<void, ResponseApi>(
              ConstanteEnderecoWebservice.CONFERENCIA_ESTOQUE_EXCLUIR,
              { params: { id: conferenciaEstoqueId } }
            );

            if (response) {
              if (response.avisos) {
                response.avisos.map((aviso: string) => toast.warning(aviso));
              }

              if (response.sucesso) {
                history.push(ConstanteRotas.CONFERENCIA_ESTOQUE);
              }
            }
          }
        },
      });
    } else {
      setIsWritting(false);
    }
  }

  function iniciarConferencia() {
    setIsWritting(true);
  }

  return (
    <ConferenciaEstoqueContainerContext.Provider
      value={{
        isWritting,
        descartarConferencia,
        iniciarConferencia,
        conferenciaEstoqueId,
      }}
    >
      {children}
    </ConferenciaEstoqueContainerContext.Provider>
  );
}

export function useConferenciaEstoqueContainerContext(): ConferenciaEstoqueContainerContextProps {
  const context = useContext(ConferenciaEstoqueContainerContext);

  if (!context)
    throw new Error(
      'useConferenciaEstoqueContainerContext must be used within a ConferenciaEstoqueContainerProvider.'
    );

  return context;
}
