import { Flex, Icon, Text, Box, useMediaQuery } from '@chakra-ui/react';
import { FiChevronLeft } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { useCallback, useEffect, useRef, useState } from 'react';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';

import { MobileModalMenuHeader } from 'components/PDV/Layout/MobileModalMenu/MobileModalMenuHeader';
import SelectCliente, {
  SelectClienteRefInterface,
} from 'components/PDV/Select/SelectCliente';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';

import {
  InformacaoAdicionalDoItemProps,
  ListaTrocaProps,
  EditarItens,
} from '../Types/validationForm';
import { InformacoesItensVenda } from './informacoesItensVenda';

type ClienteListarProps = {
  id: string;
  nome: string;
  codigo: number;
  cpfCnpj: string;
  regraLimiteCredito: number;
  limiteCredito: number;
  regraLimiteCreditoDescricao: string;
  tabelaPrecoId: string;
  tabelaPrecoNome: string;
};

type InternalHeaderTrocasProdutoProps = {
  handleRedirectHome: () => void;
  tamanhoIcone: string;
  setClientePadraoSistema: React.Dispatch<
    React.SetStateAction<ClienteListarProps>
  >;
  isLargerThan900: boolean;
  existeProdutoTrocaSelecionado: boolean;
  infoComplementaresVenda?: ListaTrocaProps;
  informacaoAdicionalDoItem?: InformacaoAdicionalDoItemProps;
};

export function InternalHeaderTrocasProduto({
  handleRedirectHome,
  infoComplementaresVenda,
  existeProdutoTrocaSelecionado,
  isLargerThan900,
  setClientePadraoSistema,
  tamanhoIcone,
  informacaoAdicionalDoItem,
}: InternalHeaderTrocasProdutoProps) {
  const [showTrocarCliente, setShowTrocarCliente] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isLargerThan1400] = useMediaQuery('(min-width: 1400px)');
  const [isLargerThan600] = useMediaQuery('(min-width: 600px)');

  const selectClienteRef = useRef<SelectClienteRefInterface>(
    {} as SelectClienteRefInterface
  );

  const obterClientePadraoSistema = useCallback(async () => {
    const response = await api.get<void, ResponseApi<any>>(
      ConstanteEnderecoWebservice.CLIENTE_FORNECEDOR_OBTER_PADRAO_SISTEMA
    );

    if (response?.avisos) {
      response.avisos.forEach((item: string) => toast.warning(item));
    }

    if (response?.sucesso) {
      selectClienteRef?.current?.setCliente(response.dados);
      setClientePadraoSistema(response.dados);
    }
  }, [setClientePadraoSistema]);

  const edicaoItem = () => {
    let valoresEdicao: EditarItens;

    if (isLargerThan900) {
      valoresEdicao = {
        display: 'column',
        tamanho: '140px',
        aparecerItem: false,
      };
      return valoresEdicao;
    }
    if (!isLargerThan600) {
      valoresEdicao = {
        display: 'column',
        tamanho: '140px',
        aparecerItem: false,
      };
      return valoresEdicao;
    }
    valoresEdicao = {
      display: 'flex',
      tamanho: '105px',
      aparecerItem: true,
    };
    return valoresEdicao;
  };
  const valorEdicao = edicaoItem();

  useEffect(() => {
    setIsLoading(true);
    if (infoComplementaresVenda) {
      const handleGetClientesOptions = async () => {
        const response = await api.get<void, ResponseApi<ClienteListarProps[]>>(
          ConstanteEnderecoWebservice.CLIENTE_FORNECEDOR_LISTAR_SELECT_PDV,
          {
            params: { clienteFornecedorId: infoComplementaresVenda.idCliente },
          }
        );

        if (response) {
          if (response.avisos) {
            response.avisos.map((item: string) => toast.warning(item));
            setIsLoading(false);
          }

          if (response.sucesso && response.dados) {
            const dados = await response.dados.map((infoCliente) =>
              selectClienteRef?.current.setCliente(infoCliente)
            );
            setShowTrocarCliente(true);
            setIsLoading(false);
            return dados;
          }
        }

        return [];
      };
      handleGetClientesOptions();
    } else {
      setShowTrocarCliente(true);
      obterClientePadraoSistema();
      setIsLoading(false);
    }
  }, [infoComplementaresVenda, obterClientePadraoSistema]);

  return (
    <>
      {isLargerThan900 ? (
        <Box h="140px" bg="primary.800">
          {isLoading && <LoadingPadrao />}
          <Flex w="full">
            <Box pl={isLargerThan1400 ? '3%' : '1.5%'} w="73%">
              <Flex pt="25px">
                <Flex
                  alignItems="center"
                  w={tamanhoIcone}
                  justifyContent="left"
                  position="relative"
                >
                  <Icon
                    color="white"
                    w={6}
                    ml={isLargerThan1400 ? '25px' : ''}
                    cursor="pointer"
                    h={6}
                    onClick={handleRedirectHome}
                    as={FiChevronLeft}
                  />
                </Flex>

                <Flex alignItems="center" justifyContent="center">
                  <Text color="red.200" fontSize="16px">
                    Devolução de produtos
                  </Text>
                </Flex>
              </Flex>
              <Flex>
                <Box w={tamanhoIcone} />
                <Box
                  mt="20px"
                  w={isLargerThan900 ? '600px' : '90%'}
                  color="black"
                >
                  <SelectCliente
                    id="cliente"
                    name="cliente"
                    show={showTrocarCliente}
                    placeholder="Digite o código, nome ou CPF/CNPJ do cliente"
                    required
                    ref={selectClienteRef}
                    shouldAppearTheAddress
                  />
                </Box>
              </Flex>
            </Box>
            {existeProdutoTrocaSelecionado && (
              <InformacoesItensVenda
                valorEdicao={valorEdicao}
                informacaoAdicionalDoItem={informacaoAdicionalDoItem}
                jc="center"
                align="center"
                h="140px"
              />
            )}
          </Flex>
        </Box>
      ) : (
        <Box>
          <MobileModalMenuHeader
            voltarIconColor="black"
            color="primary.800"
            isTrocas
            onClose={handleRedirectHome}
            title="Trocrar produtos"
          />
          <Box
            overflow="hidden"
            h={existeProdutoTrocaSelecionado ? valorEdicao.tamanho : '76px'}
            bg="primary.800"
          >
            <Box color="black" pl="2%" pr="2%" pt="5">
              <SelectCliente
                id="cliente"
                name="cliente"
                show={showTrocarCliente}
                placeholder="Digite o código, nome ou CPF/CNPJ do cliente"
                required
                ref={selectClienteRef}
                shouldAppearTheAddress
              />
            </Box>
            <Box color="black" pl="2%" pr="2%" pt="3">
              {existeProdutoTrocaSelecionado && (
                <InformacoesItensVenda
                  valorEdicao={valorEdicao}
                  informacaoAdicionalDoItem={informacaoAdicionalDoItem}
                  jc="left"
                  align="left"
                  h="70px"
                />
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
