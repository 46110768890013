import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { VStack, useMediaQuery } from '@chakra-ui/react';

import ConstanteRotas from 'constants/rotas';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';

import Title from 'components/Autenticacao/Text/Title';
import Paragraph from 'components/Autenticacao/Text/Paragraph';
import ButtonSubmit from 'components/Autenticacao/Button/Submit';

type RouteParams = {
  email: string;
};

export const SenhaEnviadaSucesso = () => {
  const [isLoading, setIsLoading] = useState(false);

  const isMountedRef = useIsMountedRef();
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const history = useHistory();
  const {
    state: { email },
  } = useLocation<RouteParams>();

  const latestProps = useRef({ email, history });

  const handlePushLogin = () => {
    setIsLoading(true);

    history.push(ConstanteRotas.LOGIN);

    if (isMountedRef.current) setIsLoading(false);
  };

  useEffect(() => {
    latestProps.current = { email, history };
  });

  useLayoutEffect(() => {
    if (!latestProps.current.email) {
      latestProps.current.history.push(ConstanteRotas.LOGIN);
    }
  }, []);

  return (
    <VStack spacing={9}>
      <VStack spacing={2} alignItems="flex-start" w="full">
        <Title>Email enviado!</Title>
        <Paragraph>
          Verifique a caixa de entrada da conta de {!isLargerThan900 && <br />}{' '}
          e-mail:
          {!isLargerThan900 && ' '}
          {isLargerThan900 && <br />}
          <strong>{email}</strong>
          <br />
          <br />
          Siga as instruções para gerar uma nova senha.
        </Paragraph>
      </VStack>

      <ButtonSubmit
        type="button"
        isLoading={isLoading}
        isDisabled={isLoading}
        onClick={handlePushLogin}
      >
        Voltar para tela de acesso
      </ButtonSubmit>
    </VStack>
  );
};
